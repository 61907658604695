import React from 'react'; 
import {Router, navigate} from "@reach/router"
import {PokemonInventory } from "./pages/PokemonInventory"
import {Pokeman} from "./pages/Pokeman"
import {Counters} from "./pages/Counters"
import {Sidebar} from "./components/sidebar"
import './App.css'; 
import { NavigationRoutes } from './components/NavigationRoutes';

const NotFound = () =>
 <div>Uh oh! Page not found<button onClick={()=>navigate("/inventory")}>Return Home</button></div>

const renderPaths = () => [
  <PokemonInventory key={"/inventory"} default/>,
  <Counters key={"/counters"} path="/counters"/>,
  <NotFound key={"/notfound"} path="/notfound" />,
  <Pokeman key={"/pokemon/:identifier"} path="/pokemon/:identifier"/>
]


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
         Ultimate best pokeman database
        </p>
        </header>
        <div className="App-body">
          <div className="App-sidebar">
            <Sidebar options={NavigationRoutes} onClick={(label, route)=>navigate(route)}></Sidebar>  
          </div>
          <div className="App-inner">
          <Router>
            {renderPaths()}
            </Router>
          </div>
          </div>
    </div>
  );
}

export default App;
