import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTags,
  faUniversity,
  faChartBar,
  faCog,
  faUserFriends,
  faWrench,
  faUserCircle,
  faQuestionCircle,
  faArrowRight,
  faChevronRight,
  faChevronLeft,
  faDesktop
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faUserFriends,
  faTags,
  faUniversity,
  faChartBar,
  faCog,
  faWrench,
  faUserCircle,
  faArrowRight,
  faQuestionCircle,
  faChevronRight,
  faChevronLeft,
  faDesktop
);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
