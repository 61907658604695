export const pokemonLearnset = 
`pokemon_id,version_group_id,move_id,pokemon_move_method_id,level,order
1,1,14,4,0,
1,1,15,4,0,
1,1,22,1,13,
1,1,33,1,1,1
1,1,34,4,0,
1,1,36,4,0,
1,1,38,4,0,
1,1,45,1,1,2
1,1,72,4,0,
1,1,73,1,7,
1,1,74,1,34,
1,1,75,1,27,
1,1,76,1,48,
1,1,76,4,0,
1,1,77,1,20,
1,1,79,1,41,
1,1,92,4,0,
1,1,99,4,0,
1,1,102,4,0,
1,1,104,4,0,
1,1,115,4,0,
1,1,117,4,0,
1,1,156,4,0,
1,1,164,4,0,
1,2,14,4,0,
1,2,15,4,0,
1,2,22,1,13,
1,2,33,1,1,1
1,2,34,4,0,
1,2,36,4,0,
1,2,38,4,0,
1,2,45,1,1,2
1,2,72,4,0,
1,2,73,1,7,
1,2,74,1,34,
1,2,75,1,27,
1,2,76,1,48,
1,2,76,4,0,
1,2,77,1,20,
1,2,79,1,41,
1,2,92,4,0,
1,2,99,4,0,
1,2,102,4,0,
1,2,104,4,0,
1,2,115,4,0,
1,2,117,4,0,
1,2,156,4,0,
1,2,164,4,0,
1,3,13,2,0,
1,3,15,4,0,
1,3,22,1,10,
1,3,29,4,0,
1,3,33,1,1,
1,3,45,1,4,
1,3,73,1,7,
1,3,74,1,32,
1,3,75,1,20,
1,3,76,1,46,
1,3,76,4,0,
1,3,77,1,15,1
1,3,79,1,15,2
1,3,80,2,0,
1,3,92,4,0,
1,3,104,4,0,
1,3,111,4,0,
1,3,113,2,0,
1,3,130,2,0,
1,3,148,4,0,
1,3,156,4,0,
1,3,173,4,0,
1,3,174,4,0,
1,3,182,4,0,
1,3,189,4,0,
1,3,202,4,0,
1,3,203,4,0,
1,3,204,2,0,
1,3,207,4,0,
1,3,210,4,0,
1,3,213,4,0,
1,3,214,4,0,
1,3,216,4,0,
1,3,218,4,0,
1,3,219,2,0,
1,3,230,1,25,
1,3,230,4,0,
1,3,235,1,39,
1,3,237,4,0,
1,3,241,4,0,
1,4,13,2,0,
1,4,15,4,0,
1,4,22,1,10,
1,4,29,4,0,
1,4,33,1,1,
1,4,45,1,4,
1,4,73,1,7,
1,4,74,1,32,
1,4,75,1,20,
1,4,76,1,46,
1,4,76,4,0,
1,4,77,1,15,1
1,4,79,1,15,2
1,4,80,2,0,
1,4,92,4,0,
1,4,104,4,0,
1,4,111,4,0,
1,4,113,2,0,
1,4,130,2,0,
1,4,148,4,0,
1,4,156,4,0,
1,4,173,4,0,
1,4,174,4,0,
1,4,182,4,0,
1,4,189,4,0,
1,4,202,4,0,
1,4,203,4,0,
1,4,207,4,0,
1,4,210,4,0,
1,4,213,4,0,
1,4,214,4,0,
1,4,216,4,0,
1,4,218,4,0,
1,4,219,2,0,
1,4,230,1,25,
1,4,230,4,0,
1,4,235,1,39,
1,4,237,4,0,
1,4,241,4,0,
1,5,15,4,0,
1,5,22,1,10,
1,5,33,1,1,
1,5,45,1,4,
1,5,70,4,0,
1,5,73,1,7,
1,5,74,1,32,
1,5,75,1,20,
1,5,76,1,46,
1,5,76,4,0,
1,5,77,1,15,1
1,5,79,1,15,2
1,5,80,2,0,
1,5,92,4,0,
1,5,104,4,0,
1,5,113,2,0,
1,5,130,2,0,
1,5,148,4,0,
1,5,156,4,0,
1,5,174,2,0,
1,5,182,4,0,
1,5,188,4,0,
1,5,202,4,0,
1,5,204,2,0,
1,5,213,4,0,
1,5,216,4,0,
1,5,218,4,0,
1,5,219,2,0,
1,5,230,1,25,
1,5,235,1,39,
1,5,237,4,0,
1,5,241,4,0,
1,5,249,4,0,
1,5,263,4,0,
1,5,290,4,0,
1,5,320,2,0,
1,5,331,4,0,
1,5,345,2,0,
1,6,14,3,0,
1,6,15,4,0,
1,6,22,1,10,
1,6,33,1,1,
1,6,34,3,0,
1,6,38,3,0,
1,6,45,1,4,
1,6,70,4,0,
1,6,73,1,7,
1,6,74,1,32,
1,6,75,1,20,
1,6,76,1,46,
1,6,76,4,0,
1,6,77,1,15,1
1,6,79,1,15,2
1,6,80,2,0,
1,6,92,4,0,
1,6,102,3,0,
1,6,104,4,0,
1,6,111,3,0,
1,6,113,2,0,
1,6,130,2,0,
1,6,148,4,0,
1,6,156,4,0,
1,6,164,3,0,
1,6,173,3,0,
1,6,174,2,0,
1,6,182,4,0,
1,6,188,4,0,
1,6,189,3,0,
1,6,202,4,0,
1,6,203,3,0,
1,6,204,2,0,
1,6,207,3,0,
1,6,210,3,0,
1,6,213,4,0,
1,6,214,3,0,
1,6,216,4,0,
1,6,218,4,0,
1,6,219,2,0,
1,6,230,1,25,
1,6,235,1,39,
1,6,237,4,0,
1,6,241,4,0,
1,6,249,4,0,
1,6,263,4,0,
1,6,290,4,0,
1,6,320,2,0,
1,6,331,4,0,
1,6,345,2,0,
1,7,14,3,0,
1,7,15,4,0,
1,7,22,1,10,
1,7,33,1,1,
1,7,34,3,0,
1,7,38,3,0,
1,7,45,1,4,
1,7,70,4,0,
1,7,73,1,7,
1,7,74,1,32,
1,7,75,1,20,
1,7,76,1,46,
1,7,76,4,0,
1,7,77,1,15,1
1,7,79,1,15,2
1,7,80,2,0,
1,7,92,4,0,
1,7,102,3,0,
1,7,104,4,0,
1,7,113,2,0,
1,7,130,2,0,
1,7,148,4,0,
1,7,156,4,0,
1,7,164,3,0,
1,7,174,2,0,
1,7,182,4,0,
1,7,188,4,0,
1,7,202,4,0,
1,7,204,2,0,
1,7,213,4,0,
1,7,216,4,0,
1,7,218,4,0,
1,7,219,2,0,
1,7,230,1,25,
1,7,235,1,39,
1,7,237,4,0,
1,7,241,4,0,
1,7,249,4,0,
1,7,263,4,0,
1,7,290,4,0,
1,7,320,2,0,
1,7,331,4,0,
1,7,345,2,0,
1,8,14,4,0,
1,8,15,4,0,
1,8,22,1,9,
1,8,33,1,1,
1,8,36,1,15,
1,8,38,1,27,
1,8,45,1,3,
1,8,70,4,0,
1,8,73,1,7,
1,8,74,1,25,
1,8,75,1,19,
1,8,76,4,0,
1,8,77,1,13,1
1,8,79,1,13,2
1,8,80,2,0,
1,8,92,4,0,
1,8,104,4,0,
1,8,113,2,0,
1,8,130,2,0,
1,8,133,2,0,
1,8,148,4,0,
1,8,156,4,0,
1,8,164,4,0,
1,8,174,2,0,
1,8,182,4,0,
1,8,188,4,0,
1,8,202,4,0,
1,8,203,4,0,
1,8,204,2,0,
1,8,207,4,0,
1,8,213,4,0,
1,8,214,4,0,
1,8,216,4,0,
1,8,218,4,0,
1,8,219,2,0,
1,8,230,1,21,
1,8,235,1,33,
1,8,237,4,0,
1,8,241,4,0,
1,8,249,4,0,
1,8,263,4,0,
1,8,267,2,0,
1,8,275,2,0,
1,8,290,4,0,
1,8,320,2,0,
1,8,331,4,0,
1,8,345,2,0,
1,8,363,4,0,
1,8,388,1,31,
1,8,402,1,37,
1,8,412,4,0,
1,8,437,2,0,
1,8,445,4,0,
1,8,447,4,0,
1,9,14,4,0,
1,9,15,4,0,
1,9,22,1,9,
1,9,33,1,1,
1,9,36,1,15,
1,9,38,1,27,
1,9,45,1,3,
1,9,70,4,0,
1,9,73,1,7,
1,9,74,1,25,
1,9,75,1,19,
1,9,76,4,0,
1,9,77,1,13,1
1,9,79,1,13,2
1,9,80,2,0,
1,9,92,4,0,
1,9,104,4,0,
1,9,113,2,0,
1,9,130,2,0,
1,9,133,2,0,
1,9,148,4,0,
1,9,156,4,0,
1,9,164,4,0,
1,9,173,3,0,
1,9,174,2,0,
1,9,182,4,0,
1,9,188,4,0,
1,9,189,3,0,
1,9,202,4,0,
1,9,203,4,0,
1,9,204,2,0,
1,9,207,4,0,
1,9,210,3,0,
1,9,213,4,0,
1,9,214,4,0,
1,9,216,4,0,
1,9,218,4,0,
1,9,219,2,0,
1,9,230,1,21,
1,9,235,1,33,
1,9,235,3,0,
1,9,237,4,0,
1,9,241,4,0,
1,9,249,4,0,
1,9,263,4,0,
1,9,267,2,0,
1,9,275,2,0,
1,9,282,3,0,
1,9,290,4,0,
1,9,320,2,0,
1,9,331,4,0,
1,9,345,2,0,
1,9,363,4,0,
1,9,388,1,31,
1,9,402,1,37,
1,9,402,3,0,
1,9,412,4,0,
1,9,437,2,0,
1,9,445,4,0,
1,9,447,4,0,
1,10,14,4,0,
1,10,15,4,0,
1,10,22,1,9,
1,10,29,3,0,
1,10,33,1,1,
1,10,36,1,15,
1,10,38,1,27,
1,10,45,1,3,
1,10,70,4,0,
1,10,73,1,7,
1,10,74,1,25,
1,10,75,1,19,
1,10,76,4,0,
1,10,77,1,13,1
1,10,79,1,13,2
1,10,80,2,0,
1,10,81,3,0,
1,10,92,4,0,
1,10,104,4,0,
1,10,113,2,0,
1,10,124,2,0,
1,10,130,2,0,
1,10,133,2,0,
1,10,148,4,0,
1,10,156,4,0,
1,10,164,4,0,
1,10,173,3,0,
1,10,174,2,0,
1,10,182,4,0,
1,10,188,4,0,
1,10,189,3,0,
1,10,202,4,0,
1,10,203,4,0,
1,10,204,2,0,
1,10,207,4,0,
1,10,210,3,0,
1,10,213,4,0,
1,10,214,4,0,
1,10,216,4,0,
1,10,218,4,0,
1,10,219,2,0,
1,10,230,1,21,
1,10,235,1,33,
1,10,235,3,0,
1,10,237,4,0,
1,10,241,4,0,
1,10,249,4,0,
1,10,263,4,0,
1,10,267,2,0,
1,10,275,2,0,
1,10,282,3,0,
1,10,290,4,0,
1,10,320,2,0,
1,10,331,4,0,
1,10,345,2,0,
1,10,363,4,0,
1,10,388,1,31,
1,10,388,3,0,
1,10,402,1,37,
1,10,402,3,0,
1,10,412,4,0,
1,10,437,2,0,
1,10,438,2,0,
1,10,445,4,0,
1,10,447,4,0,
1,11,14,4,0,
1,11,15,4,0,
1,11,22,1,9,
1,11,33,1,1,
1,11,36,1,15,
1,11,38,1,27,
1,11,45,1,3,
1,11,70,4,0,
1,11,73,1,7,
1,11,74,1,25,
1,11,75,1,19,
1,11,76,4,0,
1,11,77,1,13,1
1,11,79,1,13,2
1,11,80,2,0,
1,11,92,4,0,
1,11,104,4,0,
1,11,113,4,0,
1,11,124,2,0,
1,11,130,2,0,
1,11,133,2,0,
1,11,148,4,0,
1,11,156,4,0,
1,11,164,4,0,
1,11,174,2,0,
1,11,182,4,0,
1,11,188,4,0,
1,11,202,2,0,
1,11,203,2,0,
1,11,204,2,0,
1,11,207,4,0,
1,11,213,4,0,
1,11,216,4,0,
1,11,218,4,0,
1,11,219,4,0,
1,11,230,1,21,
1,11,235,1,33,
1,11,237,4,0,
1,11,241,4,0,
1,11,249,4,0,
1,11,263,4,0,
1,11,267,2,0,
1,11,275,2,0,
1,11,320,2,0,
1,11,345,2,0,
1,11,388,1,31,
1,11,402,1,37,
1,11,412,4,0,
1,11,437,2,0,
1,11,438,2,0,
1,11,447,4,0,
1,11,474,4,0,
1,11,496,4,0,
1,11,497,4,0,
1,11,520,3,0,
1,12,15,4,0,
1,12,22,1,10,
1,12,33,1,1,
1,12,45,1,4,
1,12,70,4,0,
1,12,73,1,7,
1,12,74,1,32,
1,12,75,1,20,
1,12,76,1,46,
1,12,76,4,0,
1,12,77,1,15,1
1,12,79,1,15,2
1,12,92,4,0,
1,12,104,4,0,
1,12,148,4,0,
1,12,156,4,0,
1,12,182,4,0,
1,12,188,4,0,
1,12,202,4,0,
1,12,213,4,0,
1,12,216,4,0,
1,12,218,4,0,
1,12,230,1,25,
1,12,235,1,39,
1,12,237,4,0,
1,12,241,4,0,
1,12,249,4,0,
1,12,263,4,0,
1,12,290,4,0,
1,12,331,4,0,
1,13,15,4,0,
1,13,22,1,10,
1,13,33,1,1,
1,13,34,3,0,
1,13,38,3,0,
1,13,45,1,4,
1,13,70,4,0,
1,13,73,1,7,
1,13,74,1,32,
1,13,75,1,20,
1,13,76,1,46,
1,13,76,4,0,
1,13,77,1,15,1
1,13,79,1,15,2
1,13,92,4,0,
1,13,102,3,0,
1,13,104,4,0,
1,13,148,4,0,
1,13,156,4,0,
1,13,164,3,0,
1,13,182,4,0,
1,13,188,4,0,
1,13,202,4,0,
1,13,207,3,0,
1,13,213,4,0,
1,13,216,4,0,
1,13,218,4,0,
1,13,230,1,25,
1,13,235,1,39,
1,13,237,4,0,
1,13,241,4,0,
1,13,249,4,0,
1,13,263,4,0,
1,13,290,4,0,
1,13,331,4,0,
1,14,14,4,0,
1,14,15,4,0,
1,14,20,3,0,
1,14,22,1,9,
1,14,33,1,1,
1,14,36,1,15,
1,14,38,1,27,
1,14,45,1,3,
1,14,70,4,0,
1,14,73,1,7,
1,14,74,1,25,
1,14,75,1,19,
1,14,76,4,0,
1,14,77,1,13,1
1,14,79,1,13,2
1,14,80,2,0,
1,14,92,4,0,
1,14,104,4,0,
1,14,113,4,0,
1,14,124,2,0,
1,14,130,2,0,
1,14,133,2,0,
1,14,148,4,0,
1,14,156,4,0,
1,14,164,4,0,
1,14,173,3,0,
1,14,174,2,0,
1,14,182,4,0,
1,14,188,4,0,
1,14,202,2,0,
1,14,202,3,0,
1,14,203,2,0,
1,14,204,2,0,
1,14,207,4,0,
1,14,213,4,0,
1,14,214,3,0,
1,14,216,4,0,
1,14,218,4,0,
1,14,219,4,0,
1,14,230,1,21,
1,14,235,1,33,
1,14,235,3,0,
1,14,237,4,0,
1,14,241,4,0,
1,14,249,4,0,
1,14,263,4,0,
1,14,267,2,0,
1,14,275,2,0,
1,14,282,3,0,
1,14,320,2,0,
1,14,345,2,0,
1,14,388,1,31,
1,14,388,3,0,
1,14,402,1,37,
1,14,402,3,0,
1,14,412,4,0,
1,14,437,2,0,
1,14,438,2,0,
1,14,447,4,0,
1,14,474,4,0,
1,14,496,4,0,
1,14,497,4,0,
1,14,520,3,0,
1,15,14,4,0,
1,15,15,4,0,
1,15,22,1,9,
1,15,33,1,1,
1,15,36,1,15,
1,15,38,1,27,
1,15,45,1,3,
1,15,70,4,0,
1,15,73,1,7,
1,15,74,1,25,
1,15,75,1,19,
1,15,76,4,0,
1,15,77,1,13,1
1,15,79,1,13,2
1,15,80,2,0,
1,15,92,4,0,
1,15,104,4,0,
1,15,113,4,0,
1,15,124,2,0,
1,15,130,2,0,
1,15,133,2,0,
1,15,148,4,0,
1,15,156,4,0,
1,15,164,4,0,
1,15,174,2,0,
1,15,182,4,0,
1,15,188,4,0,
1,15,202,2,0,
1,15,203,2,0,
1,15,204,2,0,
1,15,207,4,0,
1,15,213,4,0,
1,15,214,4,0,
1,15,216,4,0,
1,15,218,4,0,
1,15,219,4,0,
1,15,230,1,21,
1,15,235,1,33,
1,15,237,4,0,
1,15,241,4,0,
1,15,249,4,0,
1,15,263,4,0,
1,15,267,2,0,
1,15,267,4,0,
1,15,275,2,0,
1,15,320,2,0,
1,15,345,2,0,
1,15,388,1,31,
1,15,402,1,37,
1,15,412,4,0,
1,15,437,2,0,
1,15,438,2,0,
1,15,447,4,0,
1,15,474,4,0,
1,15,496,4,0,
1,15,497,4,0,
1,15,520,3,0,
1,15,580,2,0,
1,15,590,4,0,
1,16,14,4,0,
1,16,15,4,0,
1,16,20,3,0,
1,16,22,1,9,1
1,16,33,1,1,1
1,16,36,1,15,1
1,16,38,1,27,1
1,16,45,1,3,1
1,16,70,4,0,
1,16,73,1,7,1
1,16,74,1,25,1
1,16,75,1,19,1
1,16,76,4,0,
1,16,77,1,13,1
1,16,79,1,13,2
1,16,80,2,0,
1,16,92,4,0,
1,16,104,4,0,
1,16,113,4,0,
1,16,124,2,0,
1,16,130,2,0,
1,16,133,2,0,
1,16,148,4,0,
1,16,156,4,0,
1,16,164,4,0,
1,16,173,3,0,
1,16,174,2,0,
1,16,182,4,0,
1,16,188,4,0,
1,16,202,2,0,
1,16,202,3,0,
1,16,203,2,0,
1,16,204,2,0,
1,16,207,4,0,
1,16,213,4,0,
1,16,214,4,0,
1,16,216,4,0,
1,16,218,4,0,
1,16,219,4,0,
1,16,230,1,21,1
1,16,235,1,33,1
1,16,235,3,0,
1,16,237,4,0,
1,16,241,4,0,
1,16,249,4,0,
1,16,263,4,0,
1,16,267,2,0,
1,16,267,4,0,
1,16,275,2,0,
1,16,282,3,0,
1,16,290,4,0,
1,16,320,2,0,
1,16,345,2,0,
1,16,388,1,31,1
1,16,388,3,0,
1,16,402,1,37,1
1,16,402,3,0,
1,16,412,4,0,
1,16,437,2,0,
1,16,438,2,0,
1,16,447,4,0,
1,16,474,4,0,
1,16,496,4,0,
1,16,497,4,0,
1,16,520,3,0,
1,16,580,2,0,
1,16,590,4,0,
1,17,14,4,0,
1,17,22,1,7,
1,17,22,1,9,
1,17,33,1,1,
1,17,36,1,15,
1,17,38,1,27,
1,17,45,1,3,
1,17,74,1,25,
1,17,75,1,19,
1,17,76,4,0,
1,17,77,1,13,1
1,17,79,1,13,2
1,17,80,2,0,
1,17,92,4,0,
1,17,104,4,0,
1,17,113,4,0,
1,17,124,2,0,
1,17,130,2,0,
1,17,133,2,0,
1,17,156,4,0,
1,17,164,4,0,
1,17,174,2,0,
1,17,182,4,0,
1,17,188,4,0,
1,17,202,2,0,
1,17,203,2,0,
1,17,204,2,0,
1,17,207,4,0,
1,17,213,4,0,
1,17,214,4,0,
1,17,216,4,0,
1,17,218,4,0,
1,17,219,4,0,
1,17,230,1,21,
1,17,235,1,33,
1,17,237,4,0,
1,17,241,4,0,
1,17,263,4,0,
1,17,267,2,0,
1,17,267,4,0,
1,17,275,2,0,
1,17,320,2,0,
1,17,345,2,0,
1,17,388,1,31,
1,17,402,1,37,
1,17,412,4,0,
1,17,437,2,0,
1,17,438,2,0,
1,17,447,4,0,
1,17,474,4,0,
1,17,496,4,0,
1,17,497,4,0,
1,17,520,3,0,
1,17,526,4,0,
1,17,580,2,0,
1,17,590,4,0,
1,18,14,4,0,
1,18,22,1,9,
1,18,33,1,1,
1,18,36,1,15,
1,18,38,1,27,
1,18,45,1,3,
1,18,73,1,7,
1,18,74,1,25,
1,18,75,1,19,
1,18,76,4,0,
1,18,77,1,13,1
1,18,79,1,13,2
1,18,80,2,0,
1,18,92,4,0,
1,18,104,4,0,
1,18,113,4,0,
1,18,124,2,0,
1,18,130,2,0,
1,18,133,2,0,
1,18,156,4,0,
1,18,164,4,0,
1,18,174,2,0,
1,18,182,4,0,
1,18,188,4,0,
1,18,202,2,0,
1,18,203,2,0,
1,18,204,2,0,
1,18,207,4,0,
1,18,213,4,0,
1,18,214,4,0,
1,18,216,4,0,
1,18,218,4,0,
1,18,219,4,0,
1,18,230,1,21,
1,18,235,1,33,
1,18,237,4,0,
1,18,241,4,0,
1,18,263,4,0,
1,18,267,2,0,
1,18,267,4,0,
1,18,275,2,0,
1,18,320,2,0,
1,18,345,2,0,
1,18,388,1,31,
1,18,402,1,37,
1,18,412,4,0,
1,18,437,2,0,
1,18,438,2,0,
1,18,447,4,0,
1,18,474,4,0,
1,18,496,4,0,
1,18,497,4,0,
1,18,520,3,0,
1,18,526,4,0,
1,18,580,2,0,
1,18,590,4,0,
2,1,14,4,0,
2,1,15,4,0,
2,1,22,1,13,
2,1,33,1,1,1
2,1,34,4,0,
2,1,36,4,0,
2,1,38,4,0,
2,1,45,1,1,2
2,1,72,4,0,
2,1,73,1,1,3
2,1,73,1,7,
2,1,74,1,38,
2,1,75,1,30,
2,1,76,1,54,
2,1,76,4,0,
2,1,77,1,22,
2,1,79,1,46,
2,1,92,4,0,
2,1,99,4,0,
2,1,102,4,0,
2,1,104,4,0,
2,1,115,4,0,
2,1,117,4,0,
2,1,156,4,0,
2,1,164,4,0,
2,2,14,4,0,
2,2,15,4,0,
2,2,22,1,13,
2,2,33,1,1,1
2,2,34,4,0,
2,2,36,4,0,
2,2,38,4,0,
2,2,45,1,1,2
2,2,72,4,0,
2,2,73,1,1,3
2,2,73,1,7,
2,2,74,1,38,
2,2,75,1,30,
2,2,76,1,54,
2,2,76,4,0,
2,2,77,1,22,
2,2,79,1,46,
2,2,92,4,0,
2,2,99,4,0,
2,2,102,4,0,
2,2,104,4,0,
2,2,115,4,0,
2,2,117,4,0,
2,2,156,4,0,
2,2,164,4,0,
2,3,15,4,0,
2,3,22,1,10,
2,3,29,4,0,
2,3,33,1,1,1
2,3,45,1,1,2
2,3,45,1,4,
2,3,73,1,1,3
2,3,73,1,7,
2,3,74,1,38,
2,3,75,1,22,
2,3,76,1,56,
2,3,76,4,0,
2,3,77,1,15,
2,3,79,1,15,4
2,3,92,4,0,
2,3,104,4,0,
2,3,111,4,0,
2,3,148,4,0,
2,3,156,4,0,
2,3,173,4,0,
2,3,174,4,0,
2,3,182,4,0,
2,3,189,4,0,
2,3,202,4,0,
2,3,203,4,0,
2,3,207,4,0,
2,3,210,4,0,
2,3,213,4,0,
2,3,214,4,0,
2,3,216,4,0,
2,3,218,4,0,
2,3,230,1,29,
2,3,230,4,0,
2,3,235,1,47,
2,3,237,4,0,
2,3,241,4,0,
2,4,15,4,0,
2,4,22,1,10,
2,4,29,4,0,
2,4,33,1,1,1
2,4,45,1,1,2
2,4,45,1,4,
2,4,73,1,1,3
2,4,73,1,7,
2,4,74,1,38,
2,4,75,1,22,
2,4,76,1,56,
2,4,76,4,0,
2,4,77,1,15,
2,4,79,1,15,4
2,4,92,4,0,
2,4,104,4,0,
2,4,111,4,0,
2,4,148,4,0,
2,4,156,4,0,
2,4,173,4,0,
2,4,174,4,0,
2,4,182,4,0,
2,4,189,4,0,
2,4,202,4,0,
2,4,203,4,0,
2,4,207,4,0,
2,4,210,4,0,
2,4,213,4,0,
2,4,214,4,0,
2,4,216,4,0,
2,4,218,4,0,
2,4,230,1,29,
2,4,230,4,0,
2,4,235,1,47,
2,4,237,4,0,
2,4,241,4,0,
2,5,15,4,0,
2,5,22,1,10,
2,5,33,1,1,1
2,5,45,1,1,2
2,5,45,1,4,
2,5,70,4,0,
2,5,73,1,1,3
2,5,73,1,7,
2,5,74,1,38,
2,5,75,1,22,
2,5,76,1,56,
2,5,76,4,0,
2,5,77,1,15,
2,5,79,1,15,4
2,5,92,4,0,
2,5,104,4,0,
2,5,148,4,0,
2,5,156,4,0,
2,5,182,4,0,
2,5,188,4,0,
2,5,202,4,0,
2,5,213,4,0,
2,5,216,4,0,
2,5,218,4,0,
2,5,230,1,29,
2,5,235,1,47,
2,5,237,4,0,
2,5,241,4,0,
2,5,249,4,0,
2,5,263,4,0,
2,5,290,4,0,
2,5,331,4,0,
2,6,14,3,0,
2,6,15,4,0,
2,6,22,1,10,
2,6,33,1,1,1
2,6,34,3,0,
2,6,38,3,0,
2,6,45,1,1,2
2,6,45,1,4,
2,6,70,4,0,
2,6,73,1,1,3
2,6,73,1,7,
2,6,74,1,38,
2,6,75,1,22,
2,6,76,1,56,
2,6,76,4,0,
2,6,77,1,15,
2,6,79,1,15,4
2,6,92,4,0,
2,6,102,3,0,
2,6,104,4,0,
2,6,111,3,0,
2,6,148,4,0,
2,6,156,4,0,
2,6,164,3,0,
2,6,173,3,0,
2,6,182,4,0,
2,6,188,4,0,
2,6,189,3,0,
2,6,202,4,0,
2,6,203,3,0,
2,6,207,3,0,
2,6,210,3,0,
2,6,213,4,0,
2,6,214,3,0,
2,6,216,4,0,
2,6,218,4,0,
2,6,230,1,29,
2,6,235,1,47,
2,6,237,4,0,
2,6,241,4,0,
2,6,249,4,0,
2,6,263,4,0,
2,6,290,4,0,
2,6,331,4,0,
2,7,14,3,0,
2,7,15,4,0,
2,7,22,1,10,
2,7,33,1,1,1
2,7,34,3,0,
2,7,38,3,0,
2,7,45,1,1,2
2,7,45,1,4,
2,7,70,4,0,
2,7,73,1,1,3
2,7,73,1,7,
2,7,74,1,38,
2,7,75,1,22,
2,7,76,1,56,
2,7,76,4,0,
2,7,77,1,15,
2,7,79,1,15,4
2,7,92,4,0,
2,7,102,3,0,
2,7,104,4,0,
2,7,148,4,0,
2,7,156,4,0,
2,7,164,3,0,
2,7,182,4,0,
2,7,188,4,0,
2,7,202,4,0,
2,7,213,4,0,
2,7,216,4,0,
2,7,218,4,0,
2,7,230,1,29,
2,7,235,1,47,
2,7,237,4,0,
2,7,241,4,0,
2,7,249,4,0,
2,7,263,4,0,
2,7,290,4,0,
2,7,331,4,0,
2,8,14,4,0,
2,8,15,4,0,
2,8,22,1,9,
2,8,33,1,1,1
2,8,36,1,15,
2,8,38,1,31,
2,8,45,1,1,2
2,8,45,1,3,
2,8,70,4,0,
2,8,73,1,1,3
2,8,73,1,7,
2,8,74,1,28,
2,8,75,1,20,
2,8,76,1,44,
2,8,76,4,0,
2,8,77,1,13,1
2,8,79,1,13,2
2,8,92,4,0,
2,8,104,4,0,
2,8,148,4,0,
2,8,156,4,0,
2,8,164,4,0,
2,8,182,4,0,
2,8,188,4,0,
2,8,202,4,0,
2,8,203,4,0,
2,8,207,4,0,
2,8,213,4,0,
2,8,214,4,0,
2,8,216,4,0,
2,8,218,4,0,
2,8,230,1,23,
2,8,235,1,39,
2,8,237,4,0,
2,8,241,4,0,
2,8,249,4,0,
2,8,263,4,0,
2,8,290,4,0,
2,8,331,4,0,
2,8,363,4,0,
2,8,388,1,36,
2,8,412,4,0,
2,8,445,4,0,
2,8,447,4,0,
2,9,14,4,0,
2,9,15,4,0,
2,9,22,1,9,
2,9,33,1,1,1
2,9,36,1,15,
2,9,38,1,31,
2,9,45,1,1,2
2,9,45,1,3,
2,9,70,4,0,
2,9,73,1,1,3
2,9,73,1,7,
2,9,74,1,28,
2,9,75,1,20,
2,9,76,1,44,
2,9,76,4,0,
2,9,77,1,13,1
2,9,79,1,13,2
2,9,92,4,0,
2,9,104,4,0,
2,9,148,4,0,
2,9,156,4,0,
2,9,164,4,0,
2,9,173,3,0,
2,9,182,4,0,
2,9,188,4,0,
2,9,189,3,0,
2,9,202,4,0,
2,9,203,4,0,
2,9,207,4,0,
2,9,210,3,0,
2,9,213,4,0,
2,9,214,4,0,
2,9,216,4,0,
2,9,218,4,0,
2,9,230,1,23,
2,9,235,1,39,
2,9,235,3,0,
2,9,237,4,0,
2,9,241,4,0,
2,9,249,4,0,
2,9,263,4,0,
2,9,282,3,0,
2,9,290,4,0,
2,9,331,4,0,
2,9,363,4,0,
2,9,388,1,36,
2,9,402,3,0,
2,9,412,4,0,
2,9,445,4,0,
2,9,447,4,0,
2,10,14,4,0,
2,10,15,4,0,
2,10,22,1,9,
2,10,29,3,0,
2,10,33,1,1,1
2,10,36,1,15,
2,10,38,1,31,
2,10,45,1,1,2
2,10,45,1,3,
2,10,70,4,0,
2,10,73,1,1,3
2,10,73,1,7,
2,10,74,1,28,
2,10,75,1,20,
2,10,76,1,44,
2,10,76,4,0,
2,10,77,1,13,1
2,10,79,1,13,2
2,10,81,3,0,
2,10,92,4,0,
2,10,104,4,0,
2,10,148,4,0,
2,10,156,4,0,
2,10,164,4,0,
2,10,173,3,0,
2,10,182,4,0,
2,10,188,4,0,
2,10,189,3,0,
2,10,202,4,0,
2,10,203,4,0,
2,10,207,4,0,
2,10,210,3,0,
2,10,213,4,0,
2,10,214,4,0,
2,10,216,4,0,
2,10,218,4,0,
2,10,230,1,23,
2,10,235,1,39,
2,10,235,3,0,
2,10,237,4,0,
2,10,241,4,0,
2,10,249,4,0,
2,10,263,4,0,
2,10,282,3,0,
2,10,290,4,0,
2,10,331,4,0,
2,10,363,4,0,
2,10,388,1,36,
2,10,388,3,0,
2,10,402,3,0,
2,10,412,4,0,
2,10,445,4,0,
2,10,447,4,0,
2,11,14,4,0,
2,11,15,4,0,
2,11,22,1,9,
2,11,33,1,1,1
2,11,36,1,15,
2,11,38,1,31,
2,11,45,1,1,2
2,11,45,1,3,
2,11,70,4,0,
2,11,73,1,1,3
2,11,73,1,7,
2,11,74,1,28,
2,11,75,1,20,
2,11,76,1,44,
2,11,76,4,0,
2,11,77,1,13,1
2,11,79,1,13,2
2,11,92,4,0,
2,11,104,4,0,
2,11,113,4,0,
2,11,148,4,0,
2,11,156,4,0,
2,11,164,4,0,
2,11,182,4,0,
2,11,188,4,0,
2,11,207,4,0,
2,11,213,4,0,
2,11,216,4,0,
2,11,218,4,0,
2,11,219,4,0,
2,11,230,1,23,
2,11,235,1,39,
2,11,237,4,0,
2,11,241,4,0,
2,11,249,4,0,
2,11,263,4,0,
2,11,388,1,36,
2,11,412,4,0,
2,11,447,4,0,
2,11,474,4,0,
2,11,496,4,0,
2,11,497,4,0,
2,11,520,3,0,
2,12,15,4,0,
2,12,22,1,10,
2,12,33,1,1,1
2,12,45,1,1,2
2,12,45,1,4,
2,12,70,4,0,
2,12,73,1,1,3
2,12,73,1,7,
2,12,74,1,38,
2,12,75,1,22,
2,12,76,1,56,
2,12,76,4,0,
2,12,77,1,15,
2,12,79,1,15,4
2,12,92,4,0,
2,12,104,4,0,
2,12,148,4,0,
2,12,156,4,0,
2,12,182,4,0,
2,12,188,4,0,
2,12,202,4,0,
2,12,213,4,0,
2,12,216,4,0,
2,12,218,4,0,
2,12,230,1,29,
2,12,235,1,47,
2,12,237,4,0,
2,12,241,4,0,
2,12,249,4,0,
2,12,263,4,0,
2,12,290,4,0,
2,12,331,4,0,
2,13,15,4,0,
2,13,22,1,10,
2,13,33,1,1,1
2,13,34,3,0,
2,13,38,3,0,
2,13,45,1,1,2
2,13,45,1,4,
2,13,70,4,0,
2,13,73,1,1,3
2,13,73,1,7,
2,13,74,1,38,
2,13,75,1,22,
2,13,76,1,56,
2,13,76,4,0,
2,13,77,1,15,
2,13,79,1,15,4
2,13,92,4,0,
2,13,102,3,0,
2,13,104,4,0,
2,13,148,4,0,
2,13,156,4,0,
2,13,164,3,0,
2,13,182,4,0,
2,13,188,4,0,
2,13,202,4,0,
2,13,207,3,0,
2,13,213,4,0,
2,13,216,4,0,
2,13,218,4,0,
2,13,230,1,29,
2,13,235,1,47,
2,13,237,4,0,
2,13,241,4,0,
2,13,249,4,0,
2,13,263,4,0,
2,13,290,4,0,
2,13,331,4,0,
2,14,14,4,0,
2,14,15,4,0,
2,14,20,3,0,
2,14,22,1,9,
2,14,33,1,1,1
2,14,36,1,15,
2,14,38,1,31,
2,14,45,1,1,2
2,14,45,1,3,
2,14,70,4,0,
2,14,73,1,1,3
2,14,73,1,7,
2,14,74,1,28,
2,14,75,1,20,
2,14,76,1,44,
2,14,76,4,0,
2,14,77,1,13,1
2,14,79,1,13,2
2,14,92,4,0,
2,14,104,4,0,
2,14,113,4,0,
2,14,148,4,0,
2,14,156,4,0,
2,14,164,4,0,
2,14,173,3,0,
2,14,182,4,0,
2,14,188,4,0,
2,14,202,3,0,
2,14,207,4,0,
2,14,213,4,0,
2,14,214,3,0,
2,14,216,4,0,
2,14,218,4,0,
2,14,219,4,0,
2,14,230,1,23,
2,14,235,1,39,
2,14,235,3,0,
2,14,237,4,0,
2,14,241,4,0,
2,14,249,4,0,
2,14,263,4,0,
2,14,282,3,0,
2,14,388,1,36,
2,14,388,3,0,
2,14,402,3,0,
2,14,412,4,0,
2,14,447,4,0,
2,14,474,4,0,
2,14,496,4,0,
2,14,497,4,0,
2,14,520,3,0,
2,15,14,4,0,
2,15,15,4,0,
2,15,22,1,9,
2,15,33,1,1,1
2,15,36,1,15,
2,15,38,1,31,
2,15,45,1,1,2
2,15,45,1,3,
2,15,70,4,0,
2,15,73,1,1,3
2,15,73,1,7,
2,15,74,1,28,
2,15,75,1,20,
2,15,76,1,44,
2,15,76,4,0,
2,15,77,1,13,1
2,15,79,1,13,2
2,15,92,4,0,
2,15,104,4,0,
2,15,113,4,0,
2,15,148,4,0,
2,15,156,4,0,
2,15,164,4,0,
2,15,182,4,0,
2,15,188,4,0,
2,15,207,4,0,
2,15,213,4,0,
2,15,214,4,0,
2,15,216,4,0,
2,15,218,4,0,
2,15,219,4,0,
2,15,230,1,23,
2,15,235,1,39,
2,15,237,4,0,
2,15,241,4,0,
2,15,249,4,0,
2,15,263,4,0,
2,15,267,4,0,
2,15,388,1,36,
2,15,412,4,0,
2,15,447,4,0,
2,15,474,4,0,
2,15,496,4,0,
2,15,497,4,0,
2,15,520,3,0,
2,15,590,4,0,
2,16,14,4,0,
2,16,15,4,0,
2,16,20,3,0,
2,16,22,1,9,1
2,16,33,1,1,1
2,16,36,1,15,1
2,16,38,1,31,1
2,16,45,1,1,2
2,16,45,1,3,1
2,16,70,4,0,
2,16,73,1,1,3
2,16,73,1,7,1
2,16,74,1,28,1
2,16,75,1,20,1
2,16,76,1,44,1
2,16,76,4,0,
2,16,77,1,13,1
2,16,79,1,13,2
2,16,92,4,0,
2,16,104,4,0,
2,16,113,4,0,
2,16,148,4,0,
2,16,156,4,0,
2,16,164,4,0,
2,16,173,3,0,
2,16,182,4,0,
2,16,188,4,0,
2,16,202,3,0,
2,16,207,4,0,
2,16,213,4,0,
2,16,214,4,0,
2,16,216,4,0,
2,16,218,4,0,
2,16,219,4,0,
2,16,230,1,23,1
2,16,235,1,39,1
2,16,235,3,0,
2,16,237,4,0,
2,16,241,4,0,
2,16,249,4,0,
2,16,263,4,0,
2,16,267,4,0,
2,16,282,3,0,
2,16,290,4,0,
2,16,388,1,36,1
2,16,388,3,0,
2,16,402,3,0,
2,16,412,4,0,
2,16,447,4,0,
2,16,474,4,0,
2,16,496,4,0,
2,16,497,4,0,
2,16,520,3,0,
2,16,590,4,0,
2,17,14,4,0,
2,17,22,1,9,
2,17,33,1,1,1
2,17,36,1,15,
2,17,38,1,31,
2,17,45,1,1,2
2,17,45,1,3,
2,17,73,1,1,3
2,17,73,1,7,
2,17,74,1,28,
2,17,75,1,20,
2,17,76,1,44,
2,17,76,4,0,
2,17,77,1,13,1
2,17,79,1,13,2
2,17,92,4,0,
2,17,104,4,0,
2,17,113,4,0,
2,17,156,4,0,
2,17,164,4,0,
2,17,182,4,0,
2,17,188,4,0,
2,17,207,4,0,
2,17,213,4,0,
2,17,214,4,0,
2,17,216,4,0,
2,17,218,4,0,
2,17,219,4,0,
2,17,230,1,23,
2,17,235,1,39,
2,17,237,4,0,
2,17,241,4,0,
2,17,263,4,0,
2,17,267,4,0,
2,17,388,1,36,
2,17,412,4,0,
2,17,447,4,0,
2,17,474,4,0,
2,17,496,4,0,
2,17,497,4,0,
2,17,520,3,0,
2,17,526,4,0,
2,17,590,4,0,
2,18,14,4,0,
2,18,22,1,9,
2,18,33,1,1,1
2,18,36,1,15,
2,18,38,1,31,
2,18,45,1,1,2
2,18,45,1,3,
2,18,73,1,1,3
2,18,73,1,7,
2,18,74,1,28,
2,18,75,1,20,
2,18,76,1,44,
2,18,76,4,0,
2,18,77,1,13,1
2,18,79,1,13,2
2,18,92,4,0,
2,18,104,4,0,
2,18,113,4,0,
2,18,156,4,0,
2,18,164,4,0,
2,18,182,4,0,
2,18,188,4,0,
2,18,207,4,0,
2,18,213,4,0,
2,18,214,4,0,
2,18,216,4,0,
2,18,218,4,0,
2,18,219,4,0,
2,18,230,1,23,
2,18,235,1,39,
2,18,237,4,0,
2,18,241,4,0,
2,18,263,4,0,
2,18,267,4,0,
2,18,388,1,36,
2,18,412,4,0,
2,18,447,4,0,
2,18,474,4,0,
2,18,496,4,0,
2,18,497,4,0,
2,18,520,3,0,
2,18,526,4,0,
2,18,590,4,0,
3,1,14,4,0,
3,1,15,4,0,
3,1,22,1,1,4
3,1,22,1,13,
3,1,33,1,1,1
3,1,34,4,0,
3,1,36,4,0,
3,1,38,4,0,
3,1,45,1,1,2
3,1,63,4,0,
3,1,72,4,0,
3,1,73,1,1,3
3,1,73,1,7,
3,1,74,1,43,
3,1,75,1,30,
3,1,76,1,65,
3,1,76,4,0,
3,1,77,1,22,
3,1,79,1,55,
3,1,92,4,0,
3,1,99,4,0,
3,1,102,4,0,
3,1,104,4,0,
3,1,115,4,0,
3,1,117,4,0,
3,1,156,4,0,
3,1,164,4,0,
3,2,14,4,0,
3,2,15,4,0,
3,2,22,1,1,4
3,2,22,1,13,
3,2,33,1,1,1
3,2,34,4,0,
3,2,36,4,0,
3,2,38,4,0,
3,2,45,1,1,2
3,2,63,4,0,
3,2,72,4,0,
3,2,73,1,1,3
3,2,73,1,7,
3,2,74,1,43,
3,2,75,1,30,
3,2,76,1,65,
3,2,76,4,0,
3,2,77,1,22,
3,2,79,1,55,
3,2,92,4,0,
3,2,99,4,0,
3,2,102,4,0,
3,2,104,4,0,
3,2,115,4,0,
3,2,117,4,0,
3,2,156,4,0,
3,2,164,4,0,
3,3,15,4,0,
3,3,22,1,1,4
3,3,22,1,10,
3,3,29,4,0,
3,3,33,1,1,1
3,3,45,1,1,2
3,3,45,1,4,
3,3,46,4,0,
3,3,63,4,0,
3,3,73,1,1,3
3,3,73,1,7,
3,3,74,1,41,
3,3,75,1,22,
3,3,76,1,65,
3,3,76,4,0,
3,3,77,1,15,
3,3,79,1,15,5
3,3,92,4,0,
3,3,104,4,0,
3,3,111,4,0,
3,3,148,4,0,
3,3,156,4,0,
3,3,173,4,0,
3,3,174,4,0,
3,3,182,4,0,
3,3,189,4,0,
3,3,202,4,0,
3,3,203,4,0,
3,3,207,4,0,
3,3,210,4,0,
3,3,213,4,0,
3,3,214,4,0,
3,3,216,4,0,
3,3,218,4,0,
3,3,230,1,29,
3,3,230,4,0,
3,3,235,1,53,
3,3,237,4,0,
3,3,241,4,0,
3,4,15,4,0,
3,4,22,1,1,4
3,4,22,1,10,
3,4,29,4,0,
3,4,33,1,1,1
3,4,45,1,1,2
3,4,45,1,4,
3,4,46,4,0,
3,4,63,4,0,
3,4,73,1,1,3
3,4,73,1,7,
3,4,74,1,41,
3,4,75,1,22,
3,4,76,1,65,
3,4,76,4,0,
3,4,77,1,15,
3,4,79,1,15,5
3,4,92,4,0,
3,4,104,4,0,
3,4,111,4,0,
3,4,148,4,0,
3,4,156,4,0,
3,4,173,4,0,
3,4,174,4,0,
3,4,182,4,0,
3,4,189,4,0,
3,4,202,4,0,
3,4,203,4,0,
3,4,207,4,0,
3,4,210,4,0,
3,4,213,4,0,
3,4,214,4,0,
3,4,216,4,0,
3,4,218,4,0,
3,4,230,1,29,
3,4,230,4,0,
3,4,235,1,53,
3,4,237,4,0,
3,4,241,4,0,
3,5,15,4,0,
3,5,22,1,1,4
3,5,22,1,10,
3,5,33,1,1,1
3,5,45,1,1,2
3,5,45,1,4,
3,5,46,4,0,
3,5,63,4,0,
3,5,70,4,0,
3,5,73,1,1,3
3,5,73,1,7,
3,5,74,1,41,
3,5,75,1,22,
3,5,76,1,65,
3,5,76,4,0,
3,5,77,1,15,
3,5,79,1,15,5
3,5,89,4,0,
3,5,92,4,0,
3,5,104,4,0,
3,5,148,4,0,
3,5,156,4,0,
3,5,182,4,0,
3,5,188,4,0,
3,5,202,4,0,
3,5,213,4,0,
3,5,216,4,0,
3,5,218,4,0,
3,5,230,1,29,
3,5,235,1,53,
3,5,237,4,0,
3,5,241,4,0,
3,5,249,4,0,
3,5,263,4,0,
3,5,290,4,0,
3,5,331,4,0,
3,6,14,3,0,
3,6,15,4,0,
3,6,22,1,1,4
3,6,22,1,10,
3,6,33,1,1,1
3,6,34,3,0,
3,6,38,3,0,
3,6,45,1,1,2
3,6,45,1,4,
3,6,46,4,0,
3,6,63,4,0,
3,6,70,4,0,
3,6,73,1,1,3
3,6,73,1,7,
3,6,74,1,41,
3,6,75,1,22,
3,6,76,1,65,
3,6,76,4,0,
3,6,77,1,15,
3,6,79,1,15,5
3,6,89,4,0,
3,6,92,4,0,
3,6,102,3,0,
3,6,104,4,0,
3,6,111,3,0,
3,6,148,4,0,
3,6,156,4,0,
3,6,164,3,0,
3,6,173,3,0,
3,6,182,4,0,
3,6,188,4,0,
3,6,189,3,0,
3,6,202,4,0,
3,6,203,3,0,
3,6,207,3,0,
3,6,210,3,0,
3,6,213,4,0,
3,6,214,3,0,
3,6,216,4,0,
3,6,218,4,0,
3,6,230,1,29,
3,6,235,1,53,
3,6,237,4,0,
3,6,241,4,0,
3,6,249,4,0,
3,6,263,4,0,
3,6,290,4,0,
3,6,331,4,0,
3,7,14,3,0,
3,7,15,4,0,
3,7,22,1,1,4
3,7,22,1,10,
3,7,33,1,1,1
3,7,34,3,0,
3,7,38,3,0,
3,7,45,1,1,2
3,7,45,1,4,
3,7,46,4,0,
3,7,63,4,0,
3,7,70,4,0,
3,7,73,1,1,3
3,7,73,1,7,
3,7,74,1,41,
3,7,75,1,22,
3,7,76,1,65,
3,7,76,4,0,
3,7,77,1,15,
3,7,79,1,15,5
3,7,89,4,0,
3,7,92,4,0,
3,7,102,3,0,
3,7,104,4,0,
3,7,148,4,0,
3,7,156,4,0,
3,7,164,3,0,
3,7,182,4,0,
3,7,188,4,0,
3,7,202,4,0,
3,7,213,4,0,
3,7,216,4,0,
3,7,218,4,0,
3,7,230,1,29,
3,7,235,1,53,
3,7,237,4,0,
3,7,241,4,0,
3,7,249,4,0,
3,7,263,4,0,
3,7,290,4,0,
3,7,331,4,0,
3,7,338,3,0,
3,8,14,4,0,
3,8,15,4,0,
3,8,22,1,1,4
3,8,22,1,9,
3,8,33,1,1,1
3,8,36,1,15,
3,8,38,1,31,
3,8,45,1,1,2
3,8,45,1,3,
3,8,46,4,0,
3,8,63,4,0,
3,8,70,4,0,
3,8,73,1,1,3
3,8,73,1,7,
3,8,74,1,28,
3,8,75,1,20,
3,8,76,1,53,
3,8,76,4,0,
3,8,77,1,13,1
3,8,79,1,13,2
3,8,80,1,32,
3,8,89,4,0,
3,8,92,4,0,
3,8,104,4,0,
3,8,148,4,0,
3,8,156,4,0,
3,8,164,4,0,
3,8,182,4,0,
3,8,188,4,0,
3,8,202,4,0,
3,8,203,4,0,
3,8,207,4,0,
3,8,213,4,0,
3,8,214,4,0,
3,8,216,4,0,
3,8,218,4,0,
3,8,230,1,23,
3,8,235,1,45,
3,8,237,4,0,
3,8,241,4,0,
3,8,249,4,0,
3,8,263,4,0,
3,8,290,4,0,
3,8,331,4,0,
3,8,338,3,0,
3,8,363,4,0,
3,8,388,1,39,
3,8,412,4,0,
3,8,416,4,0,
3,8,431,4,0,
3,8,445,4,0,
3,8,447,4,0,
3,9,14,4,0,
3,9,15,4,0,
3,9,22,1,1,4
3,9,22,1,9,
3,9,33,1,1,1
3,9,36,1,15,
3,9,38,1,31,
3,9,45,1,1,2
3,9,45,1,3,
3,9,46,4,0,
3,9,63,4,0,
3,9,70,4,0,
3,9,73,1,1,3
3,9,73,1,7,
3,9,74,1,28,
3,9,75,1,20,
3,9,76,1,53,
3,9,76,4,0,
3,9,77,1,13,1
3,9,79,1,13,2
3,9,80,1,32,
3,9,89,4,0,
3,9,92,4,0,
3,9,104,4,0,
3,9,148,4,0,
3,9,156,4,0,
3,9,164,4,0,
3,9,173,3,0,
3,9,182,4,0,
3,9,188,4,0,
3,9,189,3,0,
3,9,200,3,0,
3,9,202,4,0,
3,9,203,4,0,
3,9,207,4,0,
3,9,210,3,0,
3,9,213,4,0,
3,9,214,4,0,
3,9,216,4,0,
3,9,218,4,0,
3,9,230,1,23,
3,9,235,1,45,
3,9,235,3,0,
3,9,237,4,0,
3,9,241,4,0,
3,9,249,4,0,
3,9,263,4,0,
3,9,282,3,0,
3,9,290,4,0,
3,9,331,4,0,
3,9,338,3,0,
3,9,363,4,0,
3,9,388,1,39,
3,9,402,3,0,
3,9,412,4,0,
3,9,416,4,0,
3,9,431,4,0,
3,9,445,4,0,
3,9,447,4,0,
3,10,14,4,0,
3,10,15,4,0,
3,10,22,1,1,4
3,10,22,1,9,
3,10,29,3,0,
3,10,33,1,1,1
3,10,36,1,15,
3,10,38,1,31,
3,10,45,1,1,2
3,10,45,1,3,
3,10,46,4,0,
3,10,63,4,0,
3,10,70,4,0,
3,10,73,1,1,3
3,10,73,1,7,
3,10,74,1,28,
3,10,75,1,20,
3,10,76,1,53,
3,10,76,4,0,
3,10,77,1,13,1
3,10,79,1,13,2
3,10,80,1,32,
3,10,81,3,0,
3,10,89,4,0,
3,10,92,4,0,
3,10,104,4,0,
3,10,148,4,0,
3,10,156,4,0,
3,10,164,4,0,
3,10,173,3,0,
3,10,182,4,0,
3,10,188,4,0,
3,10,189,3,0,
3,10,200,3,0,
3,10,202,4,0,
3,10,203,4,0,
3,10,207,4,0,
3,10,210,3,0,
3,10,213,4,0,
3,10,214,4,0,
3,10,216,4,0,
3,10,218,4,0,
3,10,230,1,23,
3,10,235,1,45,
3,10,235,3,0,
3,10,237,4,0,
3,10,241,4,0,
3,10,249,4,0,
3,10,263,4,0,
3,10,282,3,0,
3,10,290,4,0,
3,10,331,4,0,
3,10,335,3,0,
3,10,338,3,0,
3,10,363,4,0,
3,10,388,1,39,
3,10,388,3,0,
3,10,402,3,0,
3,10,412,4,0,
3,10,416,4,0,
3,10,431,4,0,
3,10,445,4,0,
3,10,447,4,0,
3,11,14,4,0,
3,11,15,4,0,
3,11,22,1,1,4
3,11,22,1,9,
3,11,33,1,1,1
3,11,36,1,15,
3,11,38,1,31,
3,11,45,1,1,2
3,11,45,1,3,
3,11,46,4,0,
3,11,63,4,0,
3,11,70,4,0,
3,11,73,1,1,3
3,11,73,1,7,
3,11,74,1,28,
3,11,75,1,20,
3,11,76,1,53,
3,11,76,4,0,
3,11,77,1,13,1
3,11,79,1,13,2
3,11,80,1,32,
3,11,89,4,0,
3,11,92,4,0,
3,11,104,4,0,
3,11,113,4,0,
3,11,148,4,0,
3,11,156,4,0,
3,11,164,4,0,
3,11,182,4,0,
3,11,188,4,0,
3,11,207,4,0,
3,11,213,4,0,
3,11,216,4,0,
3,11,218,4,0,
3,11,219,4,0,
3,11,230,1,23,
3,11,235,1,45,
3,11,237,4,0,
3,11,241,4,0,
3,11,249,4,0,
3,11,263,4,0,
3,11,338,3,0,
3,11,388,1,39,
3,11,412,4,0,
3,11,416,4,0,
3,11,447,4,0,
3,11,474,4,0,
3,11,496,4,0,
3,11,497,4,0,
3,11,520,3,0,
3,11,523,4,0,
3,12,15,4,0,
3,12,22,1,1,4
3,12,22,1,10,
3,12,33,1,1,1
3,12,45,1,1,2
3,12,45,1,4,
3,12,46,4,0,
3,12,63,4,0,
3,12,70,4,0,
3,12,73,1,1,3
3,12,73,1,7,
3,12,74,1,41,
3,12,75,1,22,
3,12,76,1,65,
3,12,76,4,0,
3,12,77,1,15,
3,12,79,1,15,5
3,12,89,4,0,
3,12,92,4,0,
3,12,104,4,0,
3,12,148,4,0,
3,12,156,4,0,
3,12,182,4,0,
3,12,188,4,0,
3,12,202,4,0,
3,12,213,4,0,
3,12,216,4,0,
3,12,218,4,0,
3,12,230,1,29,
3,12,235,1,53,
3,12,237,4,0,
3,12,241,4,0,
3,12,249,4,0,
3,12,263,4,0,
3,12,290,4,0,
3,12,331,4,0,
3,13,15,4,0,
3,13,22,1,1,4
3,13,22,1,10,
3,13,33,1,1,1
3,13,34,3,0,
3,13,38,3,0,
3,13,45,1,1,2
3,13,45,1,4,
3,13,46,4,0,
3,13,63,4,0,
3,13,70,4,0,
3,13,73,1,1,3
3,13,73,1,7,
3,13,74,1,41,
3,13,75,1,22,
3,13,76,1,65,
3,13,76,4,0,
3,13,77,1,15,
3,13,79,1,15,5
3,13,89,4,0,
3,13,92,4,0,
3,13,102,3,0,
3,13,104,4,0,
3,13,148,4,0,
3,13,156,4,0,
3,13,164,3,0,
3,13,182,4,0,
3,13,188,4,0,
3,13,202,4,0,
3,13,207,3,0,
3,13,213,4,0,
3,13,216,4,0,
3,13,218,4,0,
3,13,230,1,29,
3,13,235,1,53,
3,13,237,4,0,
3,13,241,4,0,
3,13,249,4,0,
3,13,263,4,0,
3,13,290,4,0,
3,13,331,4,0,
3,14,14,4,0,
3,14,15,4,0,
3,14,20,3,0,
3,14,22,1,1,4
3,14,22,1,9,
3,14,33,1,1,1
3,14,36,1,15,
3,14,38,1,31,
3,14,45,1,1,2
3,14,45,1,3,
3,14,46,4,0,
3,14,63,4,0,
3,14,70,4,0,
3,14,73,1,1,3
3,14,73,1,7,
3,14,74,1,28,
3,14,75,1,20,
3,14,76,1,53,
3,14,76,4,0,
3,14,77,1,13,1
3,14,79,1,13,2
3,14,80,1,32,
3,14,89,4,0,
3,14,92,4,0,
3,14,104,4,0,
3,14,113,4,0,
3,14,148,4,0,
3,14,156,4,0,
3,14,164,4,0,
3,14,173,3,0,
3,14,182,4,0,
3,14,188,4,0,
3,14,200,3,0,
3,14,202,3,0,
3,14,207,4,0,
3,14,213,4,0,
3,14,214,3,0,
3,14,216,4,0,
3,14,218,4,0,
3,14,219,4,0,
3,14,230,1,23,
3,14,235,1,45,
3,14,235,3,0,
3,14,237,4,0,
3,14,241,4,0,
3,14,249,4,0,
3,14,263,4,0,
3,14,282,3,0,
3,14,335,3,0,
3,14,338,3,0,
3,14,388,1,39,
3,14,388,3,0,
3,14,402,3,0,
3,14,412,4,0,
3,14,416,4,0,
3,14,447,4,0,
3,14,474,4,0,
3,14,496,4,0,
3,14,497,4,0,
3,14,520,3,0,
3,14,523,4,0,
3,15,14,4,0,
3,15,15,4,0,
3,15,22,1,1,4
3,15,22,1,9,
3,15,33,1,1,1
3,15,36,1,15,
3,15,38,1,31,
3,15,45,1,1,2
3,15,45,1,3,
3,15,46,4,0,
3,15,63,4,0,
3,15,70,4,0,
3,15,73,1,1,3
3,15,73,1,7,
3,15,74,1,28,
3,15,75,1,20,
3,15,76,1,53,
3,15,76,4,0,
3,15,77,1,13,1
3,15,79,1,13,2
3,15,80,1,32,
3,15,89,4,0,
3,15,92,4,0,
3,15,104,4,0,
3,15,113,4,0,
3,15,148,4,0,
3,15,156,4,0,
3,15,164,4,0,
3,15,182,4,0,
3,15,188,4,0,
3,15,207,4,0,
3,15,213,4,0,
3,15,214,4,0,
3,15,216,4,0,
3,15,218,4,0,
3,15,219,4,0,
3,15,230,1,23,
3,15,235,1,45,
3,15,237,4,0,
3,15,241,4,0,
3,15,249,4,0,
3,15,263,4,0,
3,15,267,4,0,
3,15,338,3,0,
3,15,388,1,39,
3,15,412,4,0,
3,15,416,4,0,
3,15,447,4,0,
3,15,474,4,0,
3,15,496,4,0,
3,15,497,4,0,
3,15,520,3,0,
3,15,523,4,0,
3,15,572,1,50,
3,15,590,4,0,
3,16,14,4,0,
3,16,15,4,0,
3,16,20,3,0,
3,16,22,1,1,4
3,16,22,1,9,1
3,16,33,1,1,1
3,16,36,1,15,1
3,16,38,1,31,1
3,16,45,1,1,2
3,16,45,1,3,1
3,16,46,4,0,
3,16,63,4,0,
3,16,70,4,0,
3,16,73,1,1,3
3,16,73,1,7,1
3,16,74,1,28,1
3,16,75,1,20,1
3,16,76,1,53,1
3,16,76,4,0,
3,16,77,1,13,1
3,16,79,1,13,2
3,16,80,1,32,1
3,16,89,4,0,
3,16,92,4,0,
3,16,104,4,0,
3,16,113,4,0,
3,16,148,4,0,
3,16,156,4,0,
3,16,164,4,0,
3,16,173,3,0,
3,16,182,4,0,
3,16,188,4,0,
3,16,200,3,0,
3,16,202,3,0,
3,16,207,4,0,
3,16,213,4,0,
3,16,214,4,0,
3,16,216,4,0,
3,16,218,4,0,
3,16,219,4,0,
3,16,230,1,23,1
3,16,235,1,45,1
3,16,235,3,0,
3,16,237,4,0,
3,16,241,4,0,
3,16,249,4,0,
3,16,263,4,0,
3,16,267,4,0,
3,16,282,3,0,
3,16,290,4,0,
3,16,335,3,0,
3,16,338,3,0,
3,16,388,1,39,1
3,16,388,3,0,
3,16,402,3,0,
3,16,412,4,0,
3,16,416,4,0,
3,16,447,4,0,
3,16,474,4,0,
3,16,496,4,0,
3,16,497,4,0,
3,16,520,3,0,
3,16,523,4,0,
3,16,572,1,50,1
3,16,590,4,0,
3,17,14,4,0,
3,17,22,1,1,5
3,17,22,1,9,
3,17,33,1,1,2
3,17,36,1,15,
3,17,38,1,31,
3,17,45,1,1,3
3,17,45,1,3,
3,17,46,4,0,
3,17,63,4,0,
3,17,73,1,1,4
3,17,73,1,7,
3,17,74,1,28,
3,17,75,1,20,
3,17,76,1,53,
3,17,76,4,0,
3,17,77,1,13,1
3,17,79,1,13,2
3,17,80,1,0,
3,17,80,1,1,1
3,17,89,4,0,
3,17,92,4,0,
3,17,104,4,0,
3,17,113,4,0,
3,17,156,4,0,
3,17,164,4,0,
3,17,182,4,0,
3,17,188,4,0,
3,17,207,4,0,
3,17,213,4,0,
3,17,214,4,0,
3,17,216,4,0,
3,17,218,4,0,
3,17,219,4,0,
3,17,230,1,23,
3,17,235,1,45,
3,17,237,4,0,
3,17,241,4,0,
3,17,263,4,0,
3,17,267,4,0,
3,17,338,3,0,
3,17,388,1,39,
3,17,412,4,0,
3,17,416,4,0,
3,17,447,4,0,
3,17,474,4,0,
3,17,496,4,0,
3,17,497,4,0,
3,17,520,3,0,
3,17,523,4,0,
3,17,526,4,0,
3,17,572,1,50,
3,17,590,4,0,
3,18,14,4,0,
3,18,22,1,1,5
3,18,22,1,9,
3,18,33,1,1,2
3,18,36,1,15,
3,18,38,1,31,
3,18,45,1,1,3
3,18,45,1,3,
3,18,46,4,0,
3,18,63,4,0,
3,18,73,1,1,4
3,18,73,1,7,
3,18,74,1,28,
3,18,75,1,20,
3,18,76,1,53,
3,18,76,4,0,
3,18,77,1,13,1
3,18,79,1,13,2
3,18,80,1,0,
3,18,80,1,1,1
3,18,89,4,0,
3,18,92,4,0,
3,18,104,4,0,
3,18,113,4,0,
3,18,156,4,0,
3,18,164,4,0,
3,18,182,4,0,
3,18,188,4,0,
3,18,207,4,0,
3,18,213,4,0,
3,18,214,4,0,
3,18,216,4,0,
3,18,218,4,0,
3,18,219,4,0,
3,18,230,1,23,
3,18,235,1,45,
3,18,237,4,0,
3,18,241,4,0,
3,18,263,4,0,
3,18,267,4,0,
3,18,338,3,0,
3,18,388,1,39,
3,18,412,4,0,
3,18,416,4,0,
3,18,447,4,0,
3,18,474,4,0,
3,18,496,4,0,
3,18,497,4,0,
3,18,520,3,0,
3,18,523,4,0,
3,18,526,4,0,
3,18,572,1,50,
3,18,590,4,0,
4,1,5,4,0,
4,1,10,1,1,1
4,1,14,4,0,
4,1,15,4,0,
4,1,25,4,0,
4,1,34,4,0,
4,1,36,4,0,
4,1,38,4,0,
4,1,43,1,15,
4,1,45,1,1,2
4,1,52,1,9,
4,1,53,1,38,
4,1,66,4,0,
4,1,68,4,0,
4,1,69,4,0,
4,1,70,4,0,
4,1,82,4,0,
4,1,83,1,46,
4,1,91,4,0,
4,1,92,4,0,
4,1,99,1,22,
4,1,99,4,0,
4,1,102,4,0,
4,1,104,4,0,
4,1,115,4,0,
4,1,117,4,0,
4,1,126,4,0,
4,1,129,4,0,
4,1,130,4,0,
4,1,156,4,0,
4,1,163,1,30,
4,1,164,4,0,
4,2,5,4,0,
4,2,10,1,1,1
4,2,14,4,0,
4,2,15,4,0,
4,2,25,4,0,
4,2,34,4,0,
4,2,36,4,0,
4,2,38,4,0,
4,2,43,1,15,
4,2,45,1,1,2
4,2,52,1,9,
4,2,53,1,38,
4,2,66,4,0,
4,2,68,4,0,
4,2,69,4,0,
4,2,70,4,0,
4,2,82,4,0,
4,2,83,1,46,
4,2,91,4,0,
4,2,92,4,0,
4,2,99,1,22,
4,2,99,4,0,
4,2,102,4,0,
4,2,104,4,0,
4,2,115,4,0,
4,2,117,4,0,
4,2,126,4,0,
4,2,129,4,0,
4,2,130,4,0,
4,2,156,4,0,
4,2,163,1,30,
4,2,164,4,0,
4,3,7,4,0,
4,3,10,1,1,1
4,3,15,4,0,
4,3,29,4,0,
4,3,44,2,0,
4,3,45,1,1,2
4,3,52,1,7,
4,3,53,1,31,
4,3,70,4,0,
4,3,82,1,43,
4,3,83,1,49,
4,3,91,4,0,
4,3,92,4,0,
4,3,99,1,19,
4,3,104,4,0,
4,3,108,1,13,
4,3,111,4,0,
4,3,126,4,0,
4,3,129,4,0,
4,3,156,4,0,
4,3,157,2,0,
4,3,163,1,37,
4,3,173,4,0,
4,3,174,4,0,
4,3,182,4,0,
4,3,184,1,25,
4,3,187,2,0,
4,3,189,4,0,
4,3,200,2,0,
4,3,203,4,0,
4,3,207,4,0,
4,3,210,4,0,
4,3,213,4,0,
4,3,214,4,0,
4,3,216,4,0,
4,3,218,4,0,
4,3,223,4,0,
4,3,225,4,0,
4,3,231,4,0,
4,3,237,4,0,
4,3,241,4,0,
4,3,246,2,0,
4,3,249,4,0,
4,3,251,2,0,
4,4,7,4,0,
4,4,10,1,1,1
4,4,15,4,0,
4,4,29,4,0,
4,4,44,2,0,
4,4,45,1,1,2
4,4,52,1,7,
4,4,53,1,31,
4,4,53,3,0,
4,4,70,4,0,
4,4,82,1,43,
4,4,83,1,49,
4,4,91,4,0,
4,4,92,4,0,
4,4,99,1,19,
4,4,104,4,0,
4,4,108,1,13,
4,4,111,4,0,
4,4,126,4,0,
4,4,129,4,0,
4,4,156,4,0,
4,4,157,2,0,
4,4,163,1,37,
4,4,173,4,0,
4,4,174,4,0,
4,4,182,4,0,
4,4,184,1,25,
4,4,187,2,0,
4,4,189,4,0,
4,4,200,2,0,
4,4,203,4,0,
4,4,207,4,0,
4,4,210,4,0,
4,4,213,4,0,
4,4,214,4,0,
4,4,216,4,0,
4,4,218,4,0,
4,4,223,4,0,
4,4,225,4,0,
4,4,231,4,0,
4,4,237,4,0,
4,4,241,4,0,
4,4,246,2,0,
4,4,249,4,0,
4,4,251,2,0,
4,5,10,1,1,1
4,5,14,2,0,
4,5,15,4,0,
4,5,44,2,0,
4,5,45,1,1,2
4,5,52,1,7,
4,5,53,1,31,
4,5,53,4,0,
4,5,70,4,0,
4,5,82,1,43,
4,5,83,1,49,
4,5,91,4,0,
4,5,92,4,0,
4,5,99,1,19,
4,5,104,4,0,
4,5,108,1,13,
4,5,126,4,0,
4,5,156,4,0,
4,5,157,2,0,
4,5,163,1,37,
4,5,182,4,0,
4,5,184,1,25,
4,5,187,2,0,
4,5,200,2,0,
4,5,213,4,0,
4,5,216,4,0,
4,5,218,4,0,
4,5,231,4,0,
4,5,237,4,0,
4,5,241,4,0,
4,5,246,2,0,
4,5,249,4,0,
4,5,251,2,0,
4,5,263,4,0,
4,5,264,4,0,
4,5,280,4,0,
4,5,290,4,0,
4,5,315,4,0,
4,5,332,4,0,
4,5,337,4,0,
4,5,349,2,0,
4,6,5,3,0,
4,6,7,3,0,
4,6,10,1,1,1
4,6,14,2,0,
4,6,14,3,0,
4,6,15,4,0,
4,6,25,3,0,
4,6,34,3,0,
4,6,38,3,0,
4,6,44,2,0,
4,6,45,1,1,2
4,6,52,1,7,
4,6,53,1,31,
4,6,53,4,0,
4,6,68,3,0,
4,6,69,3,0,
4,6,70,4,0,
4,6,82,1,43,
4,6,83,1,49,
4,6,91,4,0,
4,6,92,4,0,
4,6,99,1,19,
4,6,102,3,0,
4,6,104,4,0,
4,6,108,1,13,
4,6,111,3,0,
4,6,126,4,0,
4,6,129,3,0,
4,6,156,4,0,
4,6,157,2,0,
4,6,157,3,0,
4,6,163,1,37,
4,6,164,3,0,
4,6,173,3,0,
4,6,182,4,0,
4,6,184,1,25,
4,6,187,2,0,
4,6,189,3,0,
4,6,200,2,0,
4,6,203,3,0,
4,6,207,3,0,
4,6,210,3,0,
4,6,213,4,0,
4,6,214,3,0,
4,6,216,4,0,
4,6,218,4,0,
4,6,223,3,0,
4,6,231,4,0,
4,6,237,4,0,
4,6,241,4,0,
4,6,246,2,0,
4,6,249,4,0,
4,6,251,2,0,
4,6,263,4,0,
4,6,264,4,0,
4,6,280,4,0,
4,6,290,4,0,
4,6,315,4,0,
4,6,332,4,0,
4,6,337,4,0,
4,6,349,2,0,
4,7,5,3,0,
4,7,10,1,1,1
4,7,14,2,0,
4,7,14,3,0,
4,7,15,4,0,
4,7,25,3,0,
4,7,34,3,0,
4,7,38,3,0,
4,7,44,2,0,
4,7,45,1,1,2
4,7,52,1,7,
4,7,53,1,31,
4,7,53,4,0,
4,7,68,3,0,
4,7,69,3,0,
4,7,70,4,0,
4,7,82,1,43,
4,7,83,1,49,
4,7,91,4,0,
4,7,92,4,0,
4,7,102,3,0,
4,7,104,4,0,
4,7,108,1,19,
4,7,126,4,0,
4,7,156,4,0,
4,7,157,2,0,
4,7,157,3,0,
4,7,163,1,37,
4,7,164,3,0,
4,7,182,4,0,
4,7,184,1,25,
4,7,187,2,0,
4,7,200,2,0,
4,7,213,4,0,
4,7,216,4,0,
4,7,218,4,0,
4,7,231,4,0,
4,7,232,1,13,
4,7,237,4,0,
4,7,241,4,0,
4,7,246,2,0,
4,7,249,4,0,
4,7,251,2,0,
4,7,263,4,0,
4,7,264,4,0,
4,7,280,4,0,
4,7,290,4,0,
4,7,315,4,0,
4,7,332,4,0,
4,7,337,4,0,
4,7,349,2,0,
4,8,10,1,1,1
4,8,14,2,0,
4,8,14,4,0,
4,8,15,4,0,
4,8,44,2,0,
4,8,45,1,1,2
4,8,52,1,7,
4,8,53,1,34,
4,8,53,4,0,
4,8,70,4,0,
4,8,82,1,16,
4,8,83,1,37,
4,8,91,4,0,
4,8,92,4,0,
4,8,104,4,0,
4,8,108,1,10,
4,8,126,4,0,
4,8,156,4,0,
4,8,157,2,0,
4,8,157,4,0,
4,8,163,1,28,
4,8,164,4,0,
4,8,182,4,0,
4,8,184,1,19,
4,8,187,2,0,
4,8,200,2,0,
4,8,203,4,0,
4,8,207,4,0,
4,8,213,4,0,
4,8,214,4,0,
4,8,216,4,0,
4,8,218,4,0,
4,8,231,4,0,
4,8,232,2,0,
4,8,237,4,0,
4,8,241,4,0,
4,8,242,2,0,
4,8,246,2,0,
4,8,249,4,0,
4,8,251,2,0,
4,8,261,4,0,
4,8,263,4,0,
4,8,264,4,0,
4,8,280,4,0,
4,8,290,4,0,
4,8,315,4,0,
4,8,317,4,0,
4,8,332,4,0,
4,8,337,4,0,
4,8,349,2,0,
4,8,363,4,0,
4,8,374,4,0,
4,8,394,2,0,
4,8,407,2,0,
4,8,421,4,0,
4,8,424,1,25,
4,8,445,4,0,
4,9,7,3,0,
4,9,9,3,0,
4,9,10,1,1,1
4,9,14,2,0,
4,9,14,4,0,
4,9,15,4,0,
4,9,44,2,0,
4,9,45,1,1,2
4,9,52,1,7,
4,9,53,1,34,
4,9,53,4,0,
4,9,70,4,0,
4,9,82,1,16,
4,9,83,1,37,
4,9,91,4,0,
4,9,92,4,0,
4,9,104,4,0,
4,9,108,1,10,
4,9,126,4,0,
4,9,129,3,0,
4,9,156,4,0,
4,9,157,2,0,
4,9,157,4,0,
4,9,163,1,28,
4,9,164,4,0,
4,9,173,3,0,
4,9,182,4,0,
4,9,184,1,19,
4,9,187,2,0,
4,9,189,3,0,
4,9,200,2,0,
4,9,203,4,0,
4,9,207,4,0,
4,9,210,3,0,
4,9,213,4,0,
4,9,214,4,0,
4,9,216,4,0,
4,9,218,4,0,
4,9,231,4,0,
4,9,232,2,0,
4,9,237,4,0,
4,9,241,4,0,
4,9,242,2,0,
4,9,246,2,0,
4,9,249,4,0,
4,9,251,2,0,
4,9,257,3,0,
4,9,261,4,0,
4,9,263,4,0,
4,9,264,4,0,
4,9,280,4,0,
4,9,290,4,0,
4,9,315,4,0,
4,9,317,4,0,
4,9,332,4,0,
4,9,337,4,0,
4,9,349,2,0,
4,9,363,4,0,
4,9,374,4,0,
4,9,394,2,0,
4,9,407,2,0,
4,9,421,4,0,
4,9,424,1,25,
4,9,445,4,0,
4,10,7,3,0,
4,10,9,3,0,
4,10,10,1,1,1
4,10,14,2,0,
4,10,14,4,0,
4,10,15,4,0,
4,10,29,3,0,
4,10,44,2,0,
4,10,45,1,1,2
4,10,52,1,7,
4,10,53,1,34,
4,10,53,4,0,
4,10,68,2,0,
4,10,70,4,0,
4,10,82,1,16,
4,10,83,1,37,
4,10,91,4,0,
4,10,92,4,0,
4,10,104,4,0,
4,10,108,1,10,
4,10,126,4,0,
4,10,129,3,0,
4,10,156,4,0,
4,10,157,2,0,
4,10,157,4,0,
4,10,163,1,28,
4,10,164,4,0,
4,10,173,3,0,
4,10,182,4,0,
4,10,184,1,19,
4,10,187,2,0,
4,10,189,3,0,
4,10,200,2,0,
4,10,203,4,0,
4,10,207,4,0,
4,10,210,3,0,
4,10,213,4,0,
4,10,214,4,0,
4,10,216,4,0,
4,10,218,4,0,
4,10,231,4,0,
4,10,232,2,0,
4,10,237,4,0,
4,10,241,4,0,
4,10,242,2,0,
4,10,246,2,0,
4,10,249,4,0,
4,10,251,2,0,
4,10,257,3,0,
4,10,261,4,0,
4,10,263,4,0,
4,10,264,4,0,
4,10,280,4,0,
4,10,290,4,0,
4,10,315,4,0,
4,10,317,4,0,
4,10,332,4,0,
4,10,337,4,0,
4,10,349,2,0,
4,10,363,4,0,
4,10,374,4,0,
4,10,394,2,0,
4,10,407,2,0,
4,10,421,4,0,
4,10,424,1,25,
4,10,445,4,0,
4,11,10,1,1,1
4,11,14,4,0,
4,11,15,4,0,
4,11,44,2,0,
4,11,45,1,1,2
4,11,52,1,7,
4,11,53,1,37,
4,11,53,4,0,
4,11,68,2,0,
4,11,70,4,0,
4,11,82,1,16,
4,11,83,1,43,
4,11,91,4,0,
4,11,92,4,0,
4,11,104,4,0,
4,11,108,1,10,
4,11,126,4,0,
4,11,156,4,0,
4,11,157,4,0,
4,11,163,1,34,
4,11,164,4,0,
4,11,182,4,0,
4,11,184,1,19,
4,11,187,2,0,
4,11,200,2,0,
4,11,207,4,0,
4,11,213,4,0,
4,11,216,4,0,
4,11,218,4,0,
4,11,232,2,0,
4,11,237,4,0,
4,11,241,4,0,
4,11,242,2,0,
4,11,246,2,0,
4,11,249,4,0,
4,11,251,2,0,
4,11,261,4,0,
4,11,263,4,0,
4,11,264,2,0,
4,11,280,4,0,
4,11,315,4,0,
4,11,317,4,0,
4,11,332,4,0,
4,11,337,4,0,
4,11,349,2,0,
4,11,374,4,0,
4,11,394,2,0,
4,11,406,2,0,
4,11,407,2,0,
4,11,421,4,0,
4,11,424,1,25,
4,11,468,4,0,
4,11,481,1,28,
4,11,488,4,0,
4,11,496,4,0,
4,11,497,4,0,
4,11,510,4,0,
4,11,517,1,46,
4,11,519,3,0,
4,12,10,1,1,1
4,12,15,4,0,
4,12,45,1,1,2
4,12,52,1,7,
4,12,53,1,31,
4,12,53,4,0,
4,12,70,4,0,
4,12,82,1,43,
4,12,83,1,49,
4,12,91,4,0,
4,12,92,4,0,
4,12,99,1,19,
4,12,104,4,0,
4,12,108,1,13,
4,12,126,4,0,
4,12,156,4,0,
4,12,163,1,37,
4,12,182,4,0,
4,12,184,1,25,
4,12,213,4,0,
4,12,216,4,0,
4,12,218,4,0,
4,12,231,4,0,
4,12,237,4,0,
4,12,241,4,0,
4,12,249,4,0,
4,12,263,4,0,
4,12,264,4,0,
4,12,280,4,0,
4,12,290,4,0,
4,12,315,4,0,
4,12,332,4,0,
4,12,337,4,0,
4,13,10,1,1,1
4,13,15,4,0,
4,13,34,3,0,
4,13,38,3,0,
4,13,45,1,1,2
4,13,52,1,7,
4,13,53,1,31,
4,13,53,4,0,
4,13,69,3,0,
4,13,70,4,0,
4,13,82,1,43,
4,13,83,1,49,
4,13,91,4,0,
4,13,92,4,0,
4,13,99,1,19,
4,13,102,3,0,
4,13,104,4,0,
4,13,108,1,13,
4,13,126,4,0,
4,13,156,4,0,
4,13,163,1,37,
4,13,164,3,0,
4,13,182,4,0,
4,13,184,1,25,
4,13,207,3,0,
4,13,213,4,0,
4,13,216,4,0,
4,13,218,4,0,
4,13,231,4,0,
4,13,237,4,0,
4,13,241,4,0,
4,13,249,4,0,
4,13,263,4,0,
4,13,264,4,0,
4,13,280,4,0,
4,13,290,4,0,
4,13,315,4,0,
4,13,332,4,0,
4,13,337,4,0,
4,14,7,3,0,
4,14,9,3,0,
4,14,10,1,1,1
4,14,14,4,0,
4,14,15,4,0,
4,14,44,2,0,
4,14,45,1,1,2
4,14,52,1,7,
4,14,53,1,37,
4,14,53,4,0,
4,14,68,2,0,
4,14,70,4,0,
4,14,82,1,16,
4,14,83,1,43,
4,14,91,4,0,
4,14,92,4,0,
4,14,104,4,0,
4,14,108,1,10,
4,14,126,4,0,
4,14,156,4,0,
4,14,157,4,0,
4,14,163,1,34,
4,14,164,4,0,
4,14,173,3,0,
4,14,182,4,0,
4,14,184,1,19,
4,14,187,2,0,
4,14,200,2,0,
4,14,200,3,0,
4,14,207,4,0,
4,14,213,4,0,
4,14,214,3,0,
4,14,216,4,0,
4,14,218,4,0,
4,14,231,3,0,
4,14,232,2,0,
4,14,237,4,0,
4,14,241,4,0,
4,14,242,2,0,
4,14,246,2,0,
4,14,249,4,0,
4,14,251,2,0,
4,14,257,3,0,
4,14,261,4,0,
4,14,263,4,0,
4,14,264,2,0,
4,14,280,4,0,
4,14,315,4,0,
4,14,317,4,0,
4,14,332,4,0,
4,14,337,4,0,
4,14,349,2,0,
4,14,374,4,0,
4,14,394,2,0,
4,14,406,2,0,
4,14,407,2,0,
4,14,421,4,0,
4,14,424,1,25,
4,14,468,4,0,
4,14,481,1,28,
4,14,488,4,0,
4,14,496,4,0,
4,14,497,4,0,
4,14,510,4,0,
4,14,517,1,46,
4,14,519,3,0,
4,15,10,1,1,1
4,15,14,4,0,
4,15,15,4,0,
4,15,44,2,0,
4,15,45,1,1,2
4,15,52,1,7,
4,15,53,1,37,
4,15,53,4,0,
4,15,68,2,0,
4,15,70,4,0,
4,15,82,1,16,
4,15,83,1,43,
4,15,91,4,0,
4,15,92,4,0,
4,15,104,4,0,
4,15,108,1,10,
4,15,126,4,0,
4,15,156,4,0,
4,15,157,4,0,
4,15,163,1,34,
4,15,164,4,0,
4,15,182,4,0,
4,15,184,1,19,
4,15,187,2,0,
4,15,200,2,0,
4,15,207,4,0,
4,15,213,4,0,
4,15,214,4,0,
4,15,216,4,0,
4,15,218,4,0,
4,15,232,2,0,
4,15,237,4,0,
4,15,241,4,0,
4,15,242,2,0,
4,15,246,2,0,
4,15,249,4,0,
4,15,251,2,0,
4,15,261,4,0,
4,15,263,4,0,
4,15,264,2,0,
4,15,280,4,0,
4,15,314,2,0,
4,15,315,4,0,
4,15,317,4,0,
4,15,332,4,0,
4,15,337,4,0,
4,15,349,2,0,
4,15,374,4,0,
4,15,394,2,0,
4,15,406,2,0,
4,15,407,2,0,
4,15,421,4,0,
4,15,424,1,25,
4,15,468,4,0,
4,15,481,1,28,
4,15,488,4,0,
4,15,496,4,0,
4,15,497,4,0,
4,15,510,4,0,
4,15,517,1,46,
4,15,519,3,0,
4,15,590,4,0,
4,15,612,4,0,
4,16,7,3,0,
4,16,9,3,0,
4,16,10,1,1,1
4,16,14,4,0,
4,16,15,4,0,
4,16,44,2,0,
4,16,45,1,1,2
4,16,52,1,7,1
4,16,53,1,37,1
4,16,53,4,0,
4,16,68,2,0,
4,16,70,4,0,
4,16,82,1,16,1
4,16,83,1,43,1
4,16,91,4,0,
4,16,92,4,0,
4,16,104,4,0,
4,16,108,1,10,1
4,16,126,4,0,
4,16,156,4,0,
4,16,157,4,0,
4,16,163,1,34,1
4,16,164,4,0,
4,16,173,3,0,
4,16,182,4,0,
4,16,184,1,19,1
4,16,187,2,0,
4,16,200,2,0,
4,16,207,4,0,
4,16,213,4,0,
4,16,214,4,0,
4,16,216,4,0,
4,16,218,4,0,
4,16,231,3,0,
4,16,232,2,0,
4,16,237,4,0,
4,16,241,4,0,
4,16,242,2,0,
4,16,246,2,0,
4,16,249,4,0,
4,16,251,2,0,
4,16,257,3,0,
4,16,261,4,0,
4,16,263,4,0,
4,16,264,2,0,
4,16,264,3,0,
4,16,280,4,0,
4,16,290,4,0,
4,16,314,2,0,
4,16,315,4,0,
4,16,317,4,0,
4,16,332,4,0,
4,16,337,4,0,
4,16,349,2,0,
4,16,374,4,0,
4,16,394,2,0,
4,16,406,2,0,
4,16,406,3,0,
4,16,407,2,0,
4,16,421,4,0,
4,16,424,1,25,1
4,16,468,4,0,
4,16,481,1,28,1
4,16,488,4,0,
4,16,496,4,0,
4,16,497,4,0,
4,16,510,4,0,
4,16,517,1,46,1
4,16,519,3,0,
4,16,590,4,0,
4,16,612,4,0,
4,17,10,1,1,1
4,17,14,4,0,
4,17,44,2,0,
4,17,45,1,1,2
4,17,52,1,7,
4,17,53,1,37,
4,17,53,4,0,
4,17,68,2,0,
4,17,82,1,16,
4,17,83,1,43,
4,17,92,4,0,
4,17,104,4,0,
4,17,108,1,10,
4,17,126,4,0,
4,17,156,4,0,
4,17,157,4,0,
4,17,163,1,34,
4,17,164,4,0,
4,17,182,4,0,
4,17,184,1,19,
4,17,187,2,0,
4,17,200,2,0,
4,17,207,4,0,
4,17,213,4,0,
4,17,214,4,0,
4,17,216,4,0,
4,17,218,4,0,
4,17,232,2,0,
4,17,237,4,0,
4,17,241,4,0,
4,17,242,2,0,
4,17,246,2,0,
4,17,251,2,0,
4,17,261,4,0,
4,17,263,4,0,
4,17,264,2,0,
4,17,280,4,0,
4,17,314,2,0,
4,17,315,4,0,
4,17,317,4,0,
4,17,332,4,0,
4,17,337,4,0,
4,17,349,2,0,
4,17,374,4,0,
4,17,394,2,0,
4,17,406,2,0,
4,17,407,2,0,
4,17,421,4,0,
4,17,424,1,25,
4,17,481,1,28,
4,17,488,4,0,
4,17,496,4,0,
4,17,497,4,0,
4,17,517,1,46,
4,17,519,3,0,
4,17,526,4,0,
4,17,590,4,0,
4,18,10,1,1,1
4,18,14,4,0,
4,18,44,2,0,
4,18,45,1,1,2
4,18,52,1,7,
4,18,53,1,37,
4,18,53,4,0,
4,18,68,2,0,
4,18,82,1,16,
4,18,83,1,43,
4,18,92,4,0,
4,18,104,4,0,
4,18,108,1,10,
4,18,126,4,0,
4,18,156,4,0,
4,18,157,4,0,
4,18,163,1,34,
4,18,164,4,0,
4,18,182,4,0,
4,18,184,1,19,
4,18,187,2,0,
4,18,200,2,0,
4,18,207,4,0,
4,18,213,4,0,
4,18,214,4,0,
4,18,216,4,0,
4,18,218,4,0,
4,18,232,2,0,
4,18,237,4,0,
4,18,241,4,0,
4,18,242,2,0,
4,18,246,2,0,
4,18,251,2,0,
4,18,261,4,0,
4,18,263,4,0,
4,18,264,2,0,
4,18,280,4,0,
4,18,314,2,0,
4,18,315,4,0,
4,18,317,4,0,
4,18,332,4,0,
4,18,337,4,0,
4,18,349,2,0,
4,18,374,4,0,
4,18,394,2,0,
4,18,406,2,0,
4,18,407,2,0,
4,18,421,4,0,
4,18,424,1,25,
4,18,481,1,28,
4,18,488,4,0,
4,18,496,4,0,
4,18,497,4,0,
4,18,517,1,46,
4,18,519,3,0,
4,18,526,4,0,
4,18,590,4,0,
5,1,5,4,0,
5,1,10,1,1,1
5,1,14,4,0,
5,1,15,4,0,
5,1,25,4,0,
5,1,34,4,0,
5,1,36,4,0,
5,1,38,4,0,
5,1,43,1,15,
5,1,45,1,1,2
5,1,52,1,1,3
5,1,52,1,9,
5,1,53,1,42,
5,1,66,4,0,
5,1,68,4,0,
5,1,69,4,0,
5,1,70,4,0,
5,1,82,4,0,
5,1,83,1,56,
5,1,91,4,0,
5,1,92,4,0,
5,1,99,1,24,
5,1,99,4,0,
5,1,102,4,0,
5,1,104,4,0,
5,1,115,4,0,
5,1,117,4,0,
5,1,126,4,0,
5,1,129,4,0,
5,1,130,4,0,
5,1,156,4,0,
5,1,163,1,33,
5,1,164,4,0,
5,2,5,4,0,
5,2,10,1,1,1
5,2,14,4,0,
5,2,15,4,0,
5,2,25,4,0,
5,2,34,4,0,
5,2,36,4,0,
5,2,38,4,0,
5,2,43,1,15,
5,2,45,1,1,2
5,2,52,1,1,3
5,2,52,1,9,
5,2,53,1,42,
5,2,66,4,0,
5,2,68,4,0,
5,2,69,4,0,
5,2,70,4,0,
5,2,82,4,0,
5,2,83,1,56,
5,2,91,4,0,
5,2,92,4,0,
5,2,99,1,24,
5,2,99,4,0,
5,2,102,4,0,
5,2,104,4,0,
5,2,115,4,0,
5,2,117,4,0,
5,2,126,4,0,
5,2,129,4,0,
5,2,130,4,0,
5,2,156,4,0,
5,2,163,1,33,
5,2,164,4,0,
5,3,7,4,0,
5,3,10,1,1,1
5,3,15,4,0,
5,3,29,4,0,
5,3,45,1,1,2
5,3,52,1,1,3
5,3,52,1,7,
5,3,53,1,34,
5,3,70,4,0,
5,3,82,1,48,
5,3,83,1,55,
5,3,91,4,0,
5,3,92,4,0,
5,3,99,1,20,
5,3,104,4,0,
5,3,108,1,13,
5,3,111,4,0,
5,3,126,4,0,
5,3,129,4,0,
5,3,156,4,0,
5,3,163,1,41,
5,3,173,4,0,
5,3,174,4,0,
5,3,182,4,0,
5,3,184,1,27,
5,3,189,4,0,
5,3,203,4,0,
5,3,207,4,0,
5,3,210,4,0,
5,3,213,4,0,
5,3,214,4,0,
5,3,216,4,0,
5,3,218,4,0,
5,3,223,4,0,
5,3,225,4,0,
5,3,231,4,0,
5,3,237,4,0,
5,3,241,4,0,
5,3,249,4,0,
5,4,7,4,0,
5,4,10,1,1,1
5,4,15,4,0,
5,4,29,4,0,
5,4,45,1,1,2
5,4,52,1,1,3
5,4,52,1,7,
5,4,53,1,34,
5,4,53,3,0,
5,4,70,4,0,
5,4,82,1,48,
5,4,83,1,55,
5,4,91,4,0,
5,4,92,4,0,
5,4,99,1,20,
5,4,104,4,0,
5,4,108,1,13,
5,4,111,4,0,
5,4,126,4,0,
5,4,129,4,0,
5,4,156,4,0,
5,4,163,1,41,
5,4,173,4,0,
5,4,174,4,0,
5,4,182,4,0,
5,4,184,1,27,
5,4,189,4,0,
5,4,203,4,0,
5,4,207,4,0,
5,4,210,4,0,
5,4,213,4,0,
5,4,214,4,0,
5,4,216,4,0,
5,4,218,4,0,
5,4,223,4,0,
5,4,225,4,0,
5,4,231,4,0,
5,4,237,4,0,
5,4,241,4,0,
5,4,249,4,0,
5,5,10,1,1,1
5,5,15,4,0,
5,5,45,1,1,2
5,5,52,1,1,3
5,5,52,1,7,
5,5,53,1,34,
5,5,53,4,0,
5,5,70,4,0,
5,5,82,1,48,
5,5,83,1,55,
5,5,91,4,0,
5,5,92,4,0,
5,5,99,1,20,
5,5,104,4,0,
5,5,108,1,13,
5,5,126,4,0,
5,5,156,4,0,
5,5,163,1,41,
5,5,182,4,0,
5,5,184,1,27,
5,5,213,4,0,
5,5,216,4,0,
5,5,218,4,0,
5,5,231,4,0,
5,5,237,4,0,
5,5,241,4,0,
5,5,249,4,0,
5,5,263,4,0,
5,5,264,4,0,
5,5,280,4,0,
5,5,290,4,0,
5,5,315,4,0,
5,5,332,4,0,
5,5,337,4,0,
5,6,5,3,0,
5,6,7,3,0,
5,6,10,1,1,1
5,6,14,3,0,
5,6,15,4,0,
5,6,25,3,0,
5,6,34,3,0,
5,6,38,3,0,
5,6,45,1,1,2
5,6,52,1,1,3
5,6,52,1,7,
5,6,53,1,34,
5,6,53,4,0,
5,6,68,3,0,
5,6,69,3,0,
5,6,70,4,0,
5,6,82,1,48,
5,6,83,1,55,
5,6,91,4,0,
5,6,92,4,0,
5,6,99,1,20,
5,6,102,3,0,
5,6,104,4,0,
5,6,108,1,13,
5,6,111,3,0,
5,6,126,4,0,
5,6,129,3,0,
5,6,156,4,0,
5,6,157,3,0,
5,6,163,1,41,
5,6,164,3,0,
5,6,173,3,0,
5,6,182,4,0,
5,6,184,1,27,
5,6,189,3,0,
5,6,203,3,0,
5,6,207,3,0,
5,6,210,3,0,
5,6,213,4,0,
5,6,214,3,0,
5,6,216,4,0,
5,6,218,4,0,
5,6,223,3,0,
5,6,231,4,0,
5,6,237,4,0,
5,6,241,4,0,
5,6,249,4,0,
5,6,263,4,0,
5,6,264,4,0,
5,6,280,4,0,
5,6,290,4,0,
5,6,315,4,0,
5,6,332,4,0,
5,6,337,4,0,
5,7,5,3,0,
5,7,10,1,1,1
5,7,14,3,0,
5,7,15,4,0,
5,7,25,3,0,
5,7,34,3,0,
5,7,38,3,0,
5,7,45,1,1,2
5,7,52,1,1,3
5,7,52,1,7,
5,7,53,1,34,
5,7,53,4,0,
5,7,68,3,0,
5,7,69,3,0,
5,7,70,4,0,
5,7,82,1,48,
5,7,83,1,55,
5,7,91,4,0,
5,7,92,4,0,
5,7,102,3,0,
5,7,104,4,0,
5,7,108,1,20,
5,7,126,4,0,
5,7,156,4,0,
5,7,157,3,0,
5,7,163,1,41,
5,7,164,3,0,
5,7,182,4,0,
5,7,184,1,27,
5,7,213,4,0,
5,7,216,4,0,
5,7,218,4,0,
5,7,231,4,0,
5,7,232,1,13,
5,7,237,4,0,
5,7,241,4,0,
5,7,249,4,0,
5,7,263,4,0,
5,7,264,4,0,
5,7,280,4,0,
5,7,290,4,0,
5,7,315,4,0,
5,7,332,4,0,
5,7,337,4,0,
5,8,10,1,1,1
5,8,14,4,0,
5,8,15,4,0,
5,8,45,1,1,2
5,8,52,1,1,3
5,8,52,1,7,
5,8,53,1,39,
5,8,53,4,0,
5,8,70,4,0,
5,8,82,1,17,
5,8,83,1,43,
5,8,91,4,0,
5,8,92,4,0,
5,8,104,4,0,
5,8,108,1,10,
5,8,126,4,0,
5,8,156,4,0,
5,8,157,4,0,
5,8,163,1,32,
5,8,164,4,0,
5,8,182,4,0,
5,8,184,1,21,
5,8,203,4,0,
5,8,207,4,0,
5,8,213,4,0,
5,8,214,4,0,
5,8,216,4,0,
5,8,218,4,0,
5,8,231,4,0,
5,8,237,4,0,
5,8,241,4,0,
5,8,249,4,0,
5,8,261,4,0,
5,8,263,4,0,
5,8,264,4,0,
5,8,280,4,0,
5,8,290,4,0,
5,8,315,4,0,
5,8,317,4,0,
5,8,332,4,0,
5,8,337,4,0,
5,8,363,4,0,
5,8,374,4,0,
5,8,421,4,0,
5,8,424,1,28,
5,8,445,4,0,
5,9,7,3,0,
5,9,9,3,0,
5,9,10,1,1,1
5,9,14,4,0,
5,9,15,4,0,
5,9,45,1,1,2
5,9,52,1,1,3
5,9,52,1,7,
5,9,53,1,39,
5,9,53,4,0,
5,9,70,4,0,
5,9,82,1,17,
5,9,83,1,43,
5,9,91,4,0,
5,9,92,4,0,
5,9,104,4,0,
5,9,108,1,10,
5,9,126,4,0,
5,9,129,3,0,
5,9,156,4,0,
5,9,157,4,0,
5,9,163,1,32,
5,9,164,4,0,
5,9,173,3,0,
5,9,182,4,0,
5,9,184,1,21,
5,9,189,3,0,
5,9,203,4,0,
5,9,207,4,0,
5,9,210,3,0,
5,9,213,4,0,
5,9,214,4,0,
5,9,216,4,0,
5,9,218,4,0,
5,9,231,4,0,
5,9,237,4,0,
5,9,241,4,0,
5,9,249,4,0,
5,9,257,3,0,
5,9,261,4,0,
5,9,263,4,0,
5,9,264,4,0,
5,9,280,4,0,
5,9,290,4,0,
5,9,315,4,0,
5,9,317,4,0,
5,9,332,4,0,
5,9,337,4,0,
5,9,363,4,0,
5,9,374,4,0,
5,9,421,4,0,
5,9,424,1,28,
5,9,445,4,0,
5,10,7,3,0,
5,10,9,3,0,
5,10,10,1,1,1
5,10,14,4,0,
5,10,15,4,0,
5,10,29,3,0,
5,10,45,1,1,2
5,10,52,1,1,3
5,10,52,1,7,
5,10,53,1,39,
5,10,53,4,0,
5,10,70,4,0,
5,10,82,1,17,
5,10,83,1,43,
5,10,91,4,0,
5,10,92,4,0,
5,10,104,4,0,
5,10,108,1,10,
5,10,126,4,0,
5,10,129,3,0,
5,10,156,4,0,
5,10,157,4,0,
5,10,163,1,32,
5,10,164,4,0,
5,10,173,3,0,
5,10,182,4,0,
5,10,184,1,21,
5,10,189,3,0,
5,10,203,4,0,
5,10,207,4,0,
5,10,210,3,0,
5,10,213,4,0,
5,10,214,4,0,
5,10,216,4,0,
5,10,218,4,0,
5,10,231,4,0,
5,10,237,4,0,
5,10,241,4,0,
5,10,249,4,0,
5,10,257,3,0,
5,10,261,4,0,
5,10,263,4,0,
5,10,264,4,0,
5,10,280,4,0,
5,10,290,4,0,
5,10,315,4,0,
5,10,317,4,0,
5,10,332,4,0,
5,10,337,4,0,
5,10,363,4,0,
5,10,374,4,0,
5,10,421,4,0,
5,10,424,1,28,
5,10,445,4,0,
5,11,10,1,1,1
5,11,14,4,0,
5,11,15,4,0,
5,11,45,1,1,2
5,11,52,1,1,3
5,11,52,1,7,
5,11,53,1,43,
5,11,53,4,0,
5,11,70,4,0,
5,11,82,1,17,
5,11,83,1,50,
5,11,91,4,0,
5,11,92,4,0,
5,11,104,4,0,
5,11,108,1,10,
5,11,126,4,0,
5,11,156,4,0,
5,11,157,4,0,
5,11,163,1,39,
5,11,164,4,0,
5,11,182,4,0,
5,11,184,1,21,
5,11,207,4,0,
5,11,213,4,0,
5,11,216,4,0,
5,11,218,4,0,
5,11,237,4,0,
5,11,241,4,0,
5,11,249,4,0,
5,11,261,4,0,
5,11,263,4,0,
5,11,280,4,0,
5,11,315,4,0,
5,11,317,4,0,
5,11,332,4,0,
5,11,337,4,0,
5,11,374,4,0,
5,11,421,4,0,
5,11,424,1,28,
5,11,468,4,0,
5,11,481,1,32,
5,11,488,4,0,
5,11,496,4,0,
5,11,497,4,0,
5,11,510,4,0,
5,11,517,1,54,
5,11,519,3,0,
5,12,10,1,1,1
5,12,15,4,0,
5,12,45,1,1,2
5,12,52,1,1,3
5,12,52,1,7,
5,12,53,1,34,
5,12,53,4,0,
5,12,70,4,0,
5,12,82,1,48,
5,12,83,1,55,
5,12,91,4,0,
5,12,92,4,0,
5,12,99,1,20,
5,12,104,4,0,
5,12,108,1,13,
5,12,126,4,0,
5,12,156,4,0,
5,12,163,1,41,
5,12,182,4,0,
5,12,184,1,27,
5,12,213,4,0,
5,12,216,4,0,
5,12,218,4,0,
5,12,231,4,0,
5,12,237,4,0,
5,12,241,4,0,
5,12,249,4,0,
5,12,263,4,0,
5,12,264,4,0,
5,12,280,4,0,
5,12,290,4,0,
5,12,315,4,0,
5,12,332,4,0,
5,12,337,4,0,
5,13,10,1,1,1
5,13,15,4,0,
5,13,34,3,0,
5,13,38,3,0,
5,13,45,1,1,2
5,13,52,1,1,3
5,13,52,1,7,
5,13,53,1,34,
5,13,53,4,0,
5,13,69,3,0,
5,13,70,4,0,
5,13,82,1,48,
5,13,83,1,55,
5,13,91,4,0,
5,13,92,4,0,
5,13,99,1,20,
5,13,102,3,0,
5,13,104,4,0,
5,13,108,1,13,
5,13,126,4,0,
5,13,156,4,0,
5,13,163,1,41,
5,13,164,3,0,
5,13,182,4,0,
5,13,184,1,27,
5,13,207,3,0,
5,13,213,4,0,
5,13,216,4,0,
5,13,218,4,0,
5,13,231,4,0,
5,13,237,4,0,
5,13,241,4,0,
5,13,249,4,0,
5,13,263,4,0,
5,13,264,4,0,
5,13,280,4,0,
5,13,290,4,0,
5,13,315,4,0,
5,13,332,4,0,
5,13,337,4,0,
5,14,7,3,0,
5,14,9,3,0,
5,14,10,1,1,1
5,14,14,4,0,
5,14,15,4,0,
5,14,45,1,1,2
5,14,52,1,1,3
5,14,52,1,7,
5,14,53,1,43,
5,14,53,4,0,
5,14,70,4,0,
5,14,82,1,17,
5,14,83,1,50,
5,14,91,4,0,
5,14,92,4,0,
5,14,104,4,0,
5,14,108,1,10,
5,14,126,4,0,
5,14,156,4,0,
5,14,157,4,0,
5,14,163,1,39,
5,14,164,4,0,
5,14,173,3,0,
5,14,182,4,0,
5,14,184,1,21,
5,14,200,3,0,
5,14,207,4,0,
5,14,213,4,0,
5,14,214,3,0,
5,14,216,4,0,
5,14,218,4,0,
5,14,231,3,0,
5,14,237,4,0,
5,14,241,4,0,
5,14,249,4,0,
5,14,257,3,0,
5,14,261,4,0,
5,14,263,4,0,
5,14,280,4,0,
5,14,315,4,0,
5,14,317,4,0,
5,14,332,4,0,
5,14,337,4,0,
5,14,374,4,0,
5,14,421,4,0,
5,14,424,1,28,
5,14,468,4,0,
5,14,481,1,32,
5,14,488,4,0,
5,14,496,4,0,
5,14,497,4,0,
5,14,510,4,0,
5,14,517,1,54,
5,14,519,3,0,
5,15,10,1,1,1
5,15,14,4,0,
5,15,15,4,0,
5,15,45,1,1,2
5,15,52,1,1,3
5,15,52,1,7,
5,15,53,1,43,
5,15,53,4,0,
5,15,70,4,0,
5,15,82,1,17,
5,15,83,1,50,
5,15,91,4,0,
5,15,92,4,0,
5,15,104,4,0,
5,15,108,1,10,
5,15,126,4,0,
5,15,156,4,0,
5,15,157,4,0,
5,15,163,1,39,
5,15,164,4,0,
5,15,182,4,0,
5,15,184,1,21,
5,15,207,4,0,
5,15,213,4,0,
5,15,214,4,0,
5,15,216,4,0,
5,15,218,4,0,
5,15,237,4,0,
5,15,241,4,0,
5,15,249,4,0,
5,15,261,4,0,
5,15,263,4,0,
5,15,280,4,0,
5,15,315,4,0,
5,15,317,4,0,
5,15,332,4,0,
5,15,337,4,0,
5,15,374,4,0,
5,15,421,4,0,
5,15,424,1,28,
5,15,468,4,0,
5,15,481,1,32,
5,15,488,4,0,
5,15,496,4,0,
5,15,497,4,0,
5,15,510,4,0,
5,15,517,1,54,
5,15,519,3,0,
5,15,590,4,0,
5,15,612,4,0,
5,16,7,3,0,
5,16,9,3,0,
5,16,10,1,1,1
5,16,14,4,0,
5,16,15,4,0,
5,16,45,1,1,2
5,16,52,1,1,3
5,16,52,1,7,1
5,16,53,1,43,1
5,16,53,4,0,
5,16,70,4,0,
5,16,82,1,17,1
5,16,83,1,50,1
5,16,91,4,0,
5,16,92,4,0,
5,16,104,4,0,
5,16,108,1,10,1
5,16,126,4,0,
5,16,156,4,0,
5,16,157,4,0,
5,16,163,1,39,1
5,16,164,4,0,
5,16,173,3,0,
5,16,182,4,0,
5,16,184,1,21,1
5,16,207,4,0,
5,16,213,4,0,
5,16,214,4,0,
5,16,216,4,0,
5,16,218,4,0,
5,16,231,3,0,
5,16,237,4,0,
5,16,241,4,0,
5,16,249,4,0,
5,16,257,3,0,
5,16,261,4,0,
5,16,263,4,0,
5,16,264,3,0,
5,16,280,4,0,
5,16,290,4,0,
5,16,315,4,0,
5,16,317,4,0,
5,16,332,4,0,
5,16,337,4,0,
5,16,374,4,0,
5,16,406,3,0,
5,16,421,4,0,
5,16,424,1,28,1
5,16,468,4,0,
5,16,481,1,32,1
5,16,488,4,0,
5,16,496,4,0,
5,16,497,4,0,
5,16,510,4,0,
5,16,517,1,54,1
5,16,519,3,0,
5,16,590,4,0,
5,16,612,4,0,
5,17,10,1,1,1
5,17,14,4,0,
5,17,45,1,1,2
5,17,52,1,1,3
5,17,52,1,7,
5,17,53,1,43,
5,17,53,4,0,
5,17,82,1,17,
5,17,83,1,50,
5,17,92,4,0,
5,17,104,4,0,
5,17,108,1,10,
5,17,126,4,0,
5,17,156,4,0,
5,17,157,4,0,
5,17,163,1,39,
5,17,164,4,0,
5,17,182,4,0,
5,17,184,1,21,
5,17,207,4,0,
5,17,213,4,0,
5,17,214,4,0,
5,17,216,4,0,
5,17,218,4,0,
5,17,237,4,0,
5,17,241,4,0,
5,17,261,4,0,
5,17,263,4,0,
5,17,280,4,0,
5,17,315,4,0,
5,17,317,4,0,
5,17,332,4,0,
5,17,337,4,0,
5,17,374,4,0,
5,17,421,4,0,
5,17,424,1,28,
5,17,481,1,32,
5,17,488,4,0,
5,17,496,4,0,
5,17,497,4,0,
5,17,517,1,54,
5,17,519,3,0,
5,17,526,4,0,
5,17,590,4,0,
5,18,10,1,1,1
5,18,14,4,0,
5,18,45,1,1,2
5,18,52,1,1,3
5,18,52,1,7,
5,18,53,1,43,
5,18,53,4,0,
5,18,82,1,17,
5,18,83,1,50,
5,18,92,4,0,
5,18,104,4,0,
5,18,108,1,10,
5,18,126,4,0,
5,18,156,4,0,
5,18,157,4,0,
5,18,163,1,39,
5,18,164,4,0,
5,18,182,4,0,
5,18,184,1,21,
5,18,207,4,0,
5,18,213,4,0,
5,18,214,4,0,
5,18,216,4,0,
5,18,218,4,0,
5,18,237,4,0,
5,18,241,4,0,
5,18,261,4,0,
5,18,263,4,0,
5,18,280,4,0,
5,18,315,4,0,
5,18,317,4,0,
5,18,332,4,0,
5,18,337,4,0,
5,18,374,4,0,
5,18,421,4,0,
5,18,424,1,28,
5,18,481,1,32,
5,18,488,4,0,
5,18,496,4,0,
5,18,497,4,0,
5,18,517,1,54,
5,18,519,3,0,
5,18,526,4,0,
5,18,590,4,0,
6,1,5,4,0,
6,1,10,1,1,1
6,1,14,4,0,
6,1,15,4,0,
6,1,25,4,0,
6,1,34,4,0,
6,1,36,4,0,
6,1,38,4,0,
6,1,43,1,1,4
6,1,43,1,15,
6,1,45,1,1,2
6,1,52,1,1,3
6,1,52,1,9,
6,1,53,1,46,
6,1,63,4,0,
6,1,66,4,0,
6,1,68,4,0,
6,1,69,4,0,
6,1,70,4,0,
6,1,82,4,0,
6,1,83,1,55,
6,1,89,4,0,
6,1,90,4,0,
6,1,91,4,0,
6,1,92,4,0,
6,1,99,1,24,
6,1,99,4,0,
6,1,102,4,0,
6,1,104,4,0,
6,1,115,4,0,
6,1,117,4,0,
6,1,126,4,0,
6,1,129,4,0,
6,1,130,4,0,
6,1,156,4,0,
6,1,163,1,36,
6,1,164,4,0,
6,2,5,4,0,
6,2,10,1,1,1
6,2,14,4,0,
6,2,15,4,0,
6,2,19,4,0,
6,2,25,4,0,
6,2,34,4,0,
6,2,36,4,0,
6,2,38,4,0,
6,2,43,1,1,4
6,2,43,1,15,
6,2,45,1,1,2
6,2,52,1,1,3
6,2,52,1,9,
6,2,53,1,46,
6,2,63,4,0,
6,2,66,4,0,
6,2,68,4,0,
6,2,69,4,0,
6,2,70,4,0,
6,2,82,4,0,
6,2,83,1,55,
6,2,89,4,0,
6,2,90,4,0,
6,2,91,4,0,
6,2,92,4,0,
6,2,99,1,24,
6,2,99,4,0,
6,2,102,4,0,
6,2,104,4,0,
6,2,115,4,0,
6,2,117,4,0,
6,2,126,4,0,
6,2,129,4,0,
6,2,130,4,0,
6,2,156,4,0,
6,2,163,1,36,
6,2,164,4,0,
6,3,7,4,0,
6,3,10,1,1,1
6,3,15,4,0,
6,3,17,1,36,
6,3,19,4,0,
6,3,29,4,0,
6,3,45,1,1,2
6,3,46,4,0,
6,3,52,1,1,3
6,3,52,1,7,
6,3,53,1,34,
6,3,63,4,0,
6,3,70,4,0,
6,3,82,1,54,
6,3,83,1,64,
6,3,89,4,0,
6,3,91,4,0,
6,3,92,4,0,
6,3,99,1,20,
6,3,104,4,0,
6,3,108,1,1,4
6,3,108,1,13,
6,3,111,4,0,
6,3,126,4,0,
6,3,129,4,0,
6,3,156,4,0,
6,3,163,1,44,
6,3,173,4,0,
6,3,174,4,0,
6,3,182,4,0,
6,3,184,1,27,
6,3,189,4,0,
6,3,201,4,0,
6,3,203,4,0,
6,3,207,4,0,
6,3,210,4,0,
6,3,211,4,0,
6,3,213,4,0,
6,3,214,4,0,
6,3,216,4,0,
6,3,218,4,0,
6,3,223,4,0,
6,3,225,4,0,
6,3,231,4,0,
6,3,237,4,0,
6,3,241,4,0,
6,3,249,4,0,
6,4,7,4,0,
6,4,10,1,1,1
6,4,15,4,0,
6,4,17,1,36,
6,4,19,4,0,
6,4,29,4,0,
6,4,45,1,1,2
6,4,46,4,0,
6,4,52,1,1,3
6,4,52,1,7,
6,4,53,1,34,
6,4,53,3,0,
6,4,63,4,0,
6,4,70,4,0,
6,4,82,1,54,
6,4,83,1,64,
6,4,89,4,0,
6,4,91,4,0,
6,4,92,4,0,
6,4,99,1,20,
6,4,104,4,0,
6,4,108,1,1,4
6,4,108,1,13,
6,4,111,4,0,
6,4,126,4,0,
6,4,129,4,0,
6,4,156,4,0,
6,4,163,1,44,
6,4,173,4,0,
6,4,174,4,0,
6,4,182,4,0,
6,4,184,1,27,
6,4,189,4,0,
6,4,201,4,0,
6,4,203,4,0,
6,4,207,4,0,
6,4,210,4,0,
6,4,211,4,0,
6,4,213,4,0,
6,4,214,4,0,
6,4,216,4,0,
6,4,218,4,0,
6,4,223,4,0,
6,4,225,4,0,
6,4,231,4,0,
6,4,237,4,0,
6,4,241,4,0,
6,4,249,4,0,
6,5,10,1,1,1
6,5,15,4,0,
6,5,17,1,36,
6,5,19,4,0,
6,5,45,1,1,2
6,5,46,4,0,
6,5,52,1,1,3
6,5,52,1,7,
6,5,53,1,34,
6,5,53,4,0,
6,5,63,4,0,
6,5,70,4,0,
6,5,82,1,54,
6,5,83,1,64,
6,5,89,4,0,
6,5,91,4,0,
6,5,92,4,0,
6,5,99,1,20,
6,5,104,4,0,
6,5,108,1,1,4
6,5,108,1,13,
6,5,126,4,0,
6,5,156,4,0,
6,5,163,1,44,
6,5,182,4,0,
6,5,184,1,27,
6,5,211,4,0,
6,5,213,4,0,
6,5,216,4,0,
6,5,218,4,0,
6,5,231,4,0,
6,5,237,4,0,
6,5,241,4,0,
6,5,249,4,0,
6,5,263,4,0,
6,5,264,4,0,
6,5,280,4,0,
6,5,290,4,0,
6,5,315,4,0,
6,5,332,4,0,
6,5,337,4,0,
6,6,5,3,0,
6,6,7,3,0,
6,6,10,1,1,1
6,6,14,3,0,
6,6,15,4,0,
6,6,17,1,36,
6,6,19,4,0,
6,6,25,3,0,
6,6,34,3,0,
6,6,38,3,0,
6,6,45,1,1,2
6,6,46,4,0,
6,6,52,1,1,3
6,6,52,1,7,
6,6,53,1,34,
6,6,53,4,0,
6,6,63,4,0,
6,6,68,3,0,
6,6,69,3,0,
6,6,70,4,0,
6,6,82,1,54,
6,6,83,1,64,
6,6,89,4,0,
6,6,91,4,0,
6,6,92,4,0,
6,6,99,1,20,
6,6,102,3,0,
6,6,104,4,0,
6,6,108,1,1,4
6,6,108,1,13,
6,6,111,3,0,
6,6,126,4,0,
6,6,129,3,0,
6,6,156,4,0,
6,6,157,3,0,
6,6,163,1,44,
6,6,164,3,0,
6,6,173,3,0,
6,6,182,4,0,
6,6,184,1,27,
6,6,189,3,0,
6,6,203,3,0,
6,6,207,3,0,
6,6,210,3,0,
6,6,211,4,0,
6,6,213,4,0,
6,6,214,3,0,
6,6,216,4,0,
6,6,218,4,0,
6,6,223,3,0,
6,6,231,4,0,
6,6,237,4,0,
6,6,241,4,0,
6,6,249,4,0,
6,6,263,4,0,
6,6,264,4,0,
6,6,280,4,0,
6,6,290,4,0,
6,6,315,4,0,
6,6,332,4,0,
6,6,337,4,0,
6,7,5,3,0,
6,7,10,1,1,2
6,7,14,3,0,
6,7,15,4,0,
6,7,17,1,36,
6,7,19,4,0,
6,7,25,3,0,
6,7,34,3,0,
6,7,38,3,0,
6,7,45,1,1,3
6,7,46,4,0,
6,7,52,1,1,4
6,7,52,1,7,
6,7,53,1,34,
6,7,53,4,0,
6,7,63,4,0,
6,7,68,3,0,
6,7,69,3,0,
6,7,70,4,0,
6,7,82,1,54,
6,7,83,1,64,
6,7,89,4,0,
6,7,91,4,0,
6,7,92,4,0,
6,7,102,3,0,
6,7,104,4,0,
6,7,108,1,20,
6,7,126,4,0,
6,7,156,4,0,
6,7,157,3,0,
6,7,163,1,44,
6,7,164,3,0,
6,7,182,4,0,
6,7,184,1,27,
6,7,211,4,0,
6,7,213,4,0,
6,7,216,4,0,
6,7,218,4,0,
6,7,231,4,0,
6,7,232,1,1,5
6,7,232,1,13,
6,7,237,4,0,
6,7,241,4,0,
6,7,249,4,0,
6,7,257,1,1,1
6,7,263,4,0,
6,7,264,4,0,
6,7,280,4,0,
6,7,290,4,0,
6,7,307,3,0,
6,7,315,4,0,
6,7,332,4,0,
6,7,337,4,0,
6,8,10,1,1,4
6,8,14,4,0,
6,8,15,4,0,
6,8,17,1,36,
6,8,19,4,0,
6,8,45,1,1,5
6,8,46,4,0,
6,8,52,1,1,6
6,8,52,1,7,
6,8,53,1,42,
6,8,53,4,0,
6,8,63,4,0,
6,8,70,4,0,
6,8,76,4,0,
6,8,82,1,17,
6,8,83,1,49,
6,8,89,4,0,
6,8,91,4,0,
6,8,92,4,0,
6,8,104,4,0,
6,8,108,1,1,7
6,8,108,1,10,
6,8,126,4,0,
6,8,156,4,0,
6,8,157,4,0,
6,8,163,1,32,
6,8,164,4,0,
6,8,182,4,0,
6,8,184,1,21,
6,8,203,4,0,
6,8,207,4,0,
6,8,211,4,0,
6,8,213,4,0,
6,8,214,4,0,
6,8,216,4,0,
6,8,218,4,0,
6,8,231,4,0,
6,8,237,4,0,
6,8,241,4,0,
6,8,249,4,0,
6,8,257,1,59,
6,8,261,4,0,
6,8,263,4,0,
6,8,264,4,0,
6,8,280,4,0,
6,8,290,4,0,
6,8,307,3,0,
6,8,315,4,0,
6,8,317,4,0,
6,8,332,4,0,
6,8,337,1,1,1
6,8,337,4,0,
6,8,355,4,0,
6,8,363,4,0,
6,8,374,4,0,
6,8,394,1,66,
6,8,403,1,1,3
6,8,406,4,0,
6,8,411,4,0,
6,8,416,4,0,
6,8,421,1,1,2
6,8,421,4,0,
6,8,424,1,28,
6,8,432,4,0,
6,8,445,4,0,
6,9,7,3,0,
6,9,9,3,0,
6,9,10,1,1,4
6,9,14,4,0,
6,9,15,4,0,
6,9,17,1,36,
6,9,19,4,0,
6,9,45,1,1,5
6,9,46,4,0,
6,9,52,1,1,6
6,9,52,1,7,
6,9,53,1,42,
6,9,53,4,0,
6,9,63,4,0,
6,9,70,4,0,
6,9,76,4,0,
6,9,82,1,17,
6,9,83,1,49,
6,9,89,4,0,
6,9,91,4,0,
6,9,92,4,0,
6,9,104,4,0,
6,9,108,1,1,7
6,9,108,1,10,
6,9,126,4,0,
6,9,129,3,0,
6,9,156,4,0,
6,9,157,4,0,
6,9,163,1,32,
6,9,164,4,0,
6,9,173,3,0,
6,9,182,4,0,
6,9,184,1,21,
6,9,189,3,0,
6,9,200,3,0,
6,9,203,4,0,
6,9,207,4,0,
6,9,210,3,0,
6,9,211,4,0,
6,9,213,4,0,
6,9,214,4,0,
6,9,216,4,0,
6,9,218,4,0,
6,9,231,4,0,
6,9,237,4,0,
6,9,239,3,0,
6,9,241,4,0,
6,9,249,4,0,
6,9,257,1,59,
6,9,257,3,0,
6,9,261,4,0,
6,9,263,4,0,
6,9,264,4,0,
6,9,280,4,0,
6,9,290,4,0,
6,9,307,3,0,
6,9,314,3,0,
6,9,315,4,0,
6,9,317,4,0,
6,9,332,4,0,
6,9,337,1,1,1
6,9,337,4,0,
6,9,355,4,0,
6,9,363,4,0,
6,9,374,4,0,
6,9,394,1,66,
6,9,403,1,1,3
6,9,406,4,0,
6,9,411,4,0,
6,9,416,4,0,
6,9,421,1,1,2
6,9,421,4,0,
6,9,424,1,28,
6,9,432,4,0,
6,9,445,4,0,
6,9,466,3,0,
6,10,7,3,0,
6,10,9,3,0,
6,10,10,1,1,4
6,10,14,4,0,
6,10,15,4,0,
6,10,17,1,36,
6,10,19,4,0,
6,10,29,3,0,
6,10,45,1,1,5
6,10,46,4,0,
6,10,52,1,1,6
6,10,52,1,7,
6,10,53,1,42,
6,10,53,4,0,
6,10,63,4,0,
6,10,70,4,0,
6,10,76,4,0,
6,10,82,1,17,
6,10,83,1,49,
6,10,89,4,0,
6,10,91,4,0,
6,10,92,4,0,
6,10,104,4,0,
6,10,108,1,1,7
6,10,108,1,10,
6,10,126,4,0,
6,10,129,3,0,
6,10,156,4,0,
6,10,157,4,0,
6,10,163,1,32,
6,10,164,4,0,
6,10,173,3,0,
6,10,182,4,0,
6,10,184,1,21,
6,10,189,3,0,
6,10,200,3,0,
6,10,203,4,0,
6,10,207,4,0,
6,10,210,3,0,
6,10,211,4,0,
6,10,213,4,0,
6,10,214,4,0,
6,10,216,4,0,
6,10,218,4,0,
6,10,231,4,0,
6,10,237,4,0,
6,10,239,3,0,
6,10,241,4,0,
6,10,249,4,0,
6,10,257,1,59,
6,10,257,3,0,
6,10,261,4,0,
6,10,263,4,0,
6,10,264,4,0,
6,10,280,4,0,
6,10,290,4,0,
6,10,307,3,0,
6,10,314,3,0,
6,10,315,4,0,
6,10,317,4,0,
6,10,332,4,0,
6,10,337,1,1,1
6,10,337,4,0,
6,10,355,4,0,
6,10,363,4,0,
6,10,366,3,0,
6,10,374,4,0,
6,10,394,1,66,
6,10,403,1,1,3
6,10,406,4,0,
6,10,411,4,0,
6,10,416,4,0,
6,10,421,1,1,2
6,10,421,4,0,
6,10,424,1,28,
6,10,445,4,0,
6,10,466,3,0,
6,11,10,1,1,4
6,11,14,4,0,
6,11,15,4,0,
6,11,17,1,36,
6,11,19,4,0,
6,11,45,1,1,5
6,11,46,4,0,
6,11,52,1,1,6
6,11,52,1,7,
6,11,53,1,47,
6,11,53,4,0,
6,11,63,4,0,
6,11,70,4,0,
6,11,76,4,0,
6,11,82,1,17,
6,11,83,1,56,
6,11,89,4,0,
6,11,91,4,0,
6,11,92,4,0,
6,11,104,4,0,
6,11,108,1,1,7
6,11,108,1,10,
6,11,126,4,0,
6,11,156,4,0,
6,11,157,4,0,
6,11,163,1,41,
6,11,164,4,0,
6,11,182,4,0,
6,11,184,1,21,
6,11,207,4,0,
6,11,213,4,0,
6,11,216,4,0,
6,11,218,4,0,
6,11,237,4,0,
6,11,241,4,0,
6,11,249,4,0,
6,11,257,1,71,
6,11,261,4,0,
6,11,263,4,0,
6,11,280,4,0,
6,11,307,3,0,
6,11,315,4,0,
6,11,317,4,0,
6,11,332,4,0,
6,11,337,1,1,1
6,11,337,4,0,
6,11,374,4,0,
6,11,394,1,77,
6,11,403,1,1,3
6,11,411,4,0,
6,11,416,4,0,
6,11,421,1,1,2
6,11,421,4,0,
6,11,424,1,28,
6,11,468,4,0,
6,11,481,1,32,
6,11,488,4,0,
6,11,496,4,0,
6,11,497,4,0,
6,11,507,4,0,
6,11,510,4,0,
6,11,517,1,62,
6,11,519,3,0,
6,11,523,4,0,
6,11,525,4,0,
6,12,10,1,1,1
6,12,15,4,0,
6,12,17,1,36,
6,12,19,4,0,
6,12,45,1,1,2
6,12,46,4,0,
6,12,52,1,1,3
6,12,52,1,7,
6,12,53,1,34,
6,12,53,4,0,
6,12,63,4,0,
6,12,70,4,0,
6,12,82,1,54,
6,12,83,1,64,
6,12,89,4,0,
6,12,91,4,0,
6,12,92,4,0,
6,12,99,1,20,
6,12,104,4,0,
6,12,108,1,1,4
6,12,108,1,13,
6,12,126,4,0,
6,12,156,4,0,
6,12,163,1,44,
6,12,182,4,0,
6,12,184,1,27,
6,12,211,4,0,
6,12,213,4,0,
6,12,216,4,0,
6,12,218,4,0,
6,12,231,4,0,
6,12,237,4,0,
6,12,241,4,0,
6,12,249,4,0,
6,12,263,4,0,
6,12,264,4,0,
6,12,280,4,0,
6,12,290,4,0,
6,12,315,4,0,
6,12,332,4,0,
6,12,337,4,0,
6,13,10,1,1,1
6,13,15,4,0,
6,13,17,1,36,
6,13,19,4,0,
6,13,34,3,0,
6,13,38,3,0,
6,13,45,1,1,2
6,13,46,4,0,
6,13,52,1,1,3
6,13,52,1,7,
6,13,53,1,34,
6,13,53,4,0,
6,13,63,4,0,
6,13,69,3,0,
6,13,70,4,0,
6,13,82,1,54,
6,13,83,1,64,
6,13,89,4,0,
6,13,91,4,0,
6,13,92,4,0,
6,13,99,1,20,
6,13,102,3,0,
6,13,104,4,0,
6,13,108,1,1,4
6,13,108,1,13,
6,13,126,4,0,
6,13,156,4,0,
6,13,163,1,44,
6,13,164,3,0,
6,13,182,4,0,
6,13,184,1,27,
6,13,207,3,0,
6,13,211,4,0,
6,13,213,4,0,
6,13,216,4,0,
6,13,218,4,0,
6,13,231,4,0,
6,13,237,4,0,
6,13,241,4,0,
6,13,249,4,0,
6,13,263,4,0,
6,13,264,4,0,
6,13,280,4,0,
6,13,290,4,0,
6,13,315,4,0,
6,13,332,4,0,
6,13,337,4,0,
6,14,7,3,0,
6,14,9,3,0,
6,14,10,1,1,4
6,14,14,4,0,
6,14,15,4,0,
6,14,17,1,36,
6,14,19,4,0,
6,14,45,1,1,5
6,14,46,4,0,
6,14,52,1,1,6
6,14,52,1,7,
6,14,53,1,47,
6,14,53,4,0,
6,14,63,4,0,
6,14,70,4,0,
6,14,76,4,0,
6,14,82,1,17,
6,14,83,1,56,
6,14,89,4,0,
6,14,91,4,0,
6,14,92,4,0,
6,14,104,4,0,
6,14,108,1,1,7
6,14,108,1,10,
6,14,126,4,0,
6,14,156,4,0,
6,14,157,4,0,
6,14,163,1,41,
6,14,164,4,0,
6,14,173,3,0,
6,14,182,4,0,
6,14,184,1,21,
6,14,200,3,0,
6,14,207,4,0,
6,14,213,4,0,
6,14,214,3,0,
6,14,216,4,0,
6,14,218,4,0,
6,14,231,3,0,
6,14,237,4,0,
6,14,241,4,0,
6,14,249,4,0,
6,14,257,1,71,
6,14,257,3,0,
6,14,261,4,0,
6,14,263,4,0,
6,14,280,4,0,
6,14,307,3,0,
6,14,315,4,0,
6,14,317,4,0,
6,14,332,4,0,
6,14,337,1,1,1
6,14,337,4,0,
6,14,355,3,0,
6,14,366,3,0,
6,14,374,4,0,
6,14,394,1,77,
6,14,403,1,1,3
6,14,406,3,0,
6,14,411,4,0,
6,14,416,4,0,
6,14,421,1,1,2
6,14,421,4,0,
6,14,424,1,28,
6,14,468,4,0,
6,14,481,1,32,
6,14,488,4,0,
6,14,496,4,0,
6,14,497,4,0,
6,14,507,4,0,
6,14,510,4,0,
6,14,517,1,62,
6,14,519,3,0,
6,14,523,4,0,
6,14,525,4,0,
6,15,10,1,1,6
6,15,14,4,0,
6,15,15,4,0,
6,15,17,1,36,
6,15,19,4,0,
6,15,45,1,1,7
6,15,46,4,0,
6,15,52,1,1,8
6,15,52,1,7,
6,15,53,1,47,
6,15,53,4,0,
6,15,63,4,0,
6,15,70,4,0,
6,15,76,4,0,
6,15,82,1,17,
6,15,83,1,56,
6,15,89,4,0,
6,15,91,4,0,
6,15,92,4,0,
6,15,104,4,0,
6,15,108,1,1,9
6,15,108,1,10,
6,15,126,4,0,
6,15,156,4,0,
6,15,157,4,0,
6,15,163,1,41,
6,15,164,4,0,
6,15,182,4,0,
6,15,184,1,21,
6,15,207,4,0,
6,15,211,4,0,
6,15,213,4,0,
6,15,214,4,0,
6,15,216,4,0,
6,15,218,4,0,
6,15,237,4,0,
6,15,241,4,0,
6,15,249,4,0,
6,15,257,1,1,2
6,15,257,1,71,
6,15,261,4,0,
6,15,263,4,0,
6,15,280,4,0,
6,15,307,3,0,
6,15,315,4,0,
6,15,317,4,0,
6,15,332,4,0,
6,15,337,1,1,3
6,15,337,4,0,
6,15,355,4,0,
6,15,374,4,0,
6,15,394,1,1,1
6,15,394,1,77,
6,15,403,1,1,5
6,15,411,4,0,
6,15,416,4,0,
6,15,421,1,1,4
6,15,421,4,0,
6,15,424,1,28,
6,15,468,4,0,
6,15,481,1,32,
6,15,488,4,0,
6,15,496,4,0,
6,15,497,4,0,
6,15,507,4,0,
6,15,510,4,0,
6,15,517,1,62,
6,15,519,3,0,
6,15,523,4,0,
6,15,525,4,0,
6,15,590,4,0,
6,15,612,4,0,
6,16,7,3,0,
6,16,9,3,0,
6,16,10,1,1,6
6,16,14,4,0,
6,16,15,4,0,
6,16,17,1,36,1
6,16,19,4,0,
6,16,45,1,1,7
6,16,46,4,0,
6,16,52,1,1,8
6,16,52,1,7,1
6,16,53,1,47,1
6,16,53,4,0,
6,16,63,4,0,
6,16,70,4,0,
6,16,76,4,0,
6,16,82,1,17,1
6,16,83,1,56,1
6,16,89,4,0,
6,16,91,4,0,
6,16,92,4,0,
6,16,104,4,0,
6,16,108,1,1,9
6,16,108,1,10,1
6,16,126,4,0,
6,16,156,4,0,
6,16,157,4,0,
6,16,163,1,41,1
6,16,164,4,0,
6,16,173,3,0,
6,16,182,4,0,
6,16,184,1,21,1
6,16,200,3,0,
6,16,207,4,0,
6,16,211,4,0,
6,16,213,4,0,
6,16,214,4,0,
6,16,216,4,0,
6,16,218,4,0,
6,16,231,3,0,
6,16,237,4,0,
6,16,241,4,0,
6,16,249,4,0,
6,16,257,1,1,2
6,16,257,1,71,1
6,16,257,3,0,
6,16,261,4,0,
6,16,263,4,0,
6,16,264,3,0,
6,16,280,4,0,
6,16,290,4,0,
6,16,307,3,0,
6,16,315,4,0,
6,16,317,4,0,
6,16,332,4,0,
6,16,337,1,1,3
6,16,337,4,0,
6,16,355,4,0,
6,16,366,3,0,
6,16,374,4,0,
6,16,394,1,1,1
6,16,394,1,77,1
6,16,403,1,1,5
6,16,406,3,0,
6,16,411,4,0,
6,16,416,4,0,
6,16,421,1,1,4
6,16,421,4,0,
6,16,424,1,28,1
6,16,468,4,0,
6,16,481,1,32,1
6,16,488,4,0,
6,16,496,4,0,
6,16,497,4,0,
6,16,507,4,0,
6,16,510,4,0,
6,16,517,1,62,1
6,16,519,3,0,
6,16,523,4,0,
6,16,525,4,0,
6,16,590,4,0,
6,16,612,4,0,
6,17,10,1,1,7
6,17,14,4,0,
6,17,17,1,0,
6,17,17,1,1,1
6,17,19,4,0,
6,17,45,1,1,8
6,17,46,4,0,
6,17,52,1,1,9
6,17,52,1,7,
6,17,53,1,47,
6,17,53,4,0,
6,17,63,4,0,
6,17,76,4,0,
6,17,82,1,17,
6,17,83,1,56,
6,17,89,4,0,
6,17,92,4,0,
6,17,104,4,0,
6,17,108,1,10,
6,17,126,4,0,
6,17,156,4,0,
6,17,157,4,0,
6,17,163,1,41,
6,17,164,4,0,
6,17,182,4,0,
6,17,184,1,21,
6,17,207,4,0,
6,17,211,4,0,
6,17,213,4,0,
6,17,214,4,0,
6,17,216,4,0,
6,17,218,4,0,
6,17,237,4,0,
6,17,241,4,0,
6,17,257,1,1,3
6,17,257,1,71,
6,17,261,4,0,
6,17,263,4,0,
6,17,280,4,0,
6,17,307,3,0,
6,17,315,4,0,
6,17,317,4,0,
6,17,332,4,0,
6,17,337,1,1,4
6,17,337,4,0,
6,17,355,4,0,
6,17,374,4,0,
6,17,394,1,1,2
6,17,394,1,77,
6,17,403,1,1,6
6,17,411,4,0,
6,17,416,4,0,
6,17,421,1,1,5
6,17,421,4,0,
6,17,424,1,28,
6,17,481,1,32,
6,17,488,4,0,
6,17,496,4,0,
6,17,497,4,0,
6,17,507,4,0,
6,17,517,1,62,
6,17,519,3,0,
6,17,523,4,0,
6,17,525,4,0,
6,17,526,4,0,
6,17,590,4,0,
6,17,693,4,0,
6,18,10,1,1,7
6,18,14,4,0,
6,18,17,1,0,
6,18,17,1,1,1
6,18,19,4,0,
6,18,45,1,1,8
6,18,46,4,0,
6,18,52,1,1,9
6,18,52,1,7,
6,18,53,1,47,
6,18,53,4,0,
6,18,63,4,0,
6,18,76,4,0,
6,18,82,1,17,
6,18,83,1,56,
6,18,89,4,0,
6,18,92,4,0,
6,18,104,4,0,
6,18,108,1,10,
6,18,126,4,0,
6,18,156,4,0,
6,18,157,4,0,
6,18,163,1,41,
6,18,164,4,0,
6,18,182,4,0,
6,18,184,1,21,
6,18,207,4,0,
6,18,211,4,0,
6,18,213,4,0,
6,18,214,4,0,
6,18,216,4,0,
6,18,218,4,0,
6,18,237,4,0,
6,18,241,4,0,
6,18,257,1,1,3
6,18,257,1,71,
6,18,261,4,0,
6,18,263,4,0,
6,18,280,4,0,
6,18,307,3,0,
6,18,315,4,0,
6,18,317,4,0,
6,18,332,4,0,
6,18,337,1,1,4
6,18,337,4,0,
6,18,355,4,0,
6,18,374,4,0,
6,18,394,1,1,2
6,18,394,1,77,
6,18,403,1,1,6
6,18,411,4,0,
6,18,416,4,0,
6,18,421,1,1,5
6,18,421,4,0,
6,18,424,1,28,
6,18,481,1,32,
6,18,488,4,0,
6,18,496,4,0,
6,18,497,4,0,
6,18,507,4,0,
6,18,517,1,62,
6,18,519,3,0,
6,18,523,4,0,
6,18,525,4,0,
6,18,526,4,0,
6,18,590,4,0,
6,18,693,4,0,
7,1,5,4,0,
7,1,25,4,0,
7,1,33,1,1,1
7,1,34,4,0,
7,1,36,4,0,
7,1,38,4,0,
7,1,39,1,1,2
7,1,44,1,22,
7,1,55,1,15,
7,1,55,4,0,
7,1,56,1,42,
7,1,57,4,0,
7,1,58,4,0,
7,1,59,4,0,
7,1,61,4,0,
7,1,66,4,0,
7,1,68,4,0,
7,1,69,4,0,
7,1,70,4,0,
7,1,91,4,0,
7,1,92,4,0,
7,1,99,4,0,
7,1,102,4,0,
7,1,104,4,0,
7,1,110,1,28,
7,1,115,4,0,
7,1,117,4,0,
7,1,130,1,35,
7,1,130,4,0,
7,1,145,1,8,
7,1,156,4,0,
7,1,164,4,0,
7,2,5,4,0,
7,2,25,4,0,
7,2,33,1,1,1
7,2,34,4,0,
7,2,36,4,0,
7,2,38,4,0,
7,2,39,1,1,2
7,2,44,1,22,
7,2,55,1,15,
7,2,55,4,0,
7,2,56,1,42,
7,2,57,4,0,
7,2,58,4,0,
7,2,59,4,0,
7,2,61,4,0,
7,2,66,4,0,
7,2,68,4,0,
7,2,69,4,0,
7,2,70,4,0,
7,2,91,4,0,
7,2,92,4,0,
7,2,99,4,0,
7,2,102,4,0,
7,2,104,4,0,
7,2,110,1,28,
7,2,115,4,0,
7,2,117,4,0,
7,2,130,1,35,
7,2,130,4,0,
7,2,145,1,8,
7,2,156,4,0,
7,2,164,4,0,
7,3,8,4,0,
7,3,29,4,0,
7,3,33,1,1,
7,3,39,1,4,
7,3,44,1,18,
7,3,54,2,0,
7,3,55,1,13,
7,3,56,1,47,
7,3,57,4,0,
7,3,59,4,0,
7,3,70,4,0,
7,3,91,4,0,
7,3,92,4,0,
7,3,93,2,0,
7,3,104,4,0,
7,3,110,1,10,
7,3,111,4,0,
7,3,114,2,0,
7,3,127,4,0,
7,3,130,1,40,
7,3,145,1,7,
7,3,156,4,0,
7,3,173,4,0,
7,3,174,4,0,
7,3,175,2,0,
7,3,182,1,28,
7,3,182,4,0,
7,3,189,4,0,
7,3,193,2,0,
7,3,196,4,0,
7,3,203,4,0,
7,3,205,4,0,
7,3,207,4,0,
7,3,213,4,0,
7,3,214,4,0,
7,3,216,4,0,
7,3,218,4,0,
7,3,223,4,0,
7,3,229,1,23,
7,3,231,4,0,
7,3,237,4,0,
7,3,240,1,33,
7,3,240,4,0,
7,3,243,2,0,
7,3,249,4,0,
7,3,250,4,0,
7,4,8,4,0,
7,4,29,4,0,
7,4,33,1,1,
7,4,39,1,4,
7,4,44,1,18,
7,4,54,2,0,
7,4,55,1,13,
7,4,56,1,47,
7,4,57,4,0,
7,4,58,3,0,
7,4,59,4,0,
7,4,70,4,0,
7,4,91,4,0,
7,4,92,4,0,
7,4,93,2,0,
7,4,104,4,0,
7,4,110,1,10,
7,4,111,4,0,
7,4,114,2,0,
7,4,127,4,0,
7,4,130,1,40,
7,4,145,1,7,
7,4,156,4,0,
7,4,173,4,0,
7,4,174,4,0,
7,4,175,2,0,
7,4,182,1,28,
7,4,182,4,0,
7,4,189,4,0,
7,4,193,2,0,
7,4,196,4,0,
7,4,203,4,0,
7,4,205,4,0,
7,4,207,4,0,
7,4,213,4,0,
7,4,214,4,0,
7,4,216,4,0,
7,4,218,4,0,
7,4,223,4,0,
7,4,229,1,23,
7,4,231,4,0,
7,4,237,4,0,
7,4,240,1,33,
7,4,240,4,0,
7,4,243,2,0,
7,4,249,4,0,
7,4,250,4,0,
7,5,33,1,1,
7,5,39,1,4,
7,5,44,1,18,
7,5,54,2,0,
7,5,55,1,13,
7,5,56,1,47,
7,5,57,4,0,
7,5,58,4,0,
7,5,59,4,0,
7,5,70,4,0,
7,5,91,4,0,
7,5,92,4,0,
7,5,104,4,0,
7,5,110,1,10,
7,5,114,2,0,
7,5,127,4,0,
7,5,130,1,40,
7,5,145,1,7,
7,5,156,4,0,
7,5,175,2,0,
7,5,182,1,28,
7,5,182,4,0,
7,5,193,2,0,
7,5,213,4,0,
7,5,216,4,0,
7,5,218,4,0,
7,5,229,1,23,
7,5,231,4,0,
7,5,237,4,0,
7,5,240,1,33,
7,5,240,4,0,
7,5,243,2,0,
7,5,249,4,0,
7,5,258,4,0,
7,5,263,4,0,
7,5,264,4,0,
7,5,280,4,0,
7,5,281,2,0,
7,5,287,2,0,
7,5,290,4,0,
7,5,291,4,0,
7,5,300,2,0,
7,5,352,4,0,
7,6,5,3,0,
7,6,8,3,0,
7,6,25,3,0,
7,6,33,1,1,
7,6,34,3,0,
7,6,38,3,0,
7,6,39,1,4,
7,6,44,1,18,
7,6,54,2,0,
7,6,55,1,13,
7,6,56,1,47,
7,6,57,4,0,
7,6,58,4,0,
7,6,59,4,0,
7,6,68,3,0,
7,6,69,3,0,
7,6,70,4,0,
7,6,91,4,0,
7,6,92,4,0,
7,6,102,3,0,
7,6,104,4,0,
7,6,110,1,10,
7,6,111,3,0,
7,6,114,2,0,
7,6,127,4,0,
7,6,130,1,40,
7,6,145,1,7,
7,6,156,4,0,
7,6,164,3,0,
7,6,173,3,0,
7,6,175,2,0,
7,6,182,1,28,
7,6,182,4,0,
7,6,189,3,0,
7,6,193,2,0,
7,6,196,3,0,
7,6,203,3,0,
7,6,205,3,0,
7,6,207,3,0,
7,6,213,4,0,
7,6,214,3,0,
7,6,216,4,0,
7,6,218,4,0,
7,6,223,3,0,
7,6,229,1,23,
7,6,231,4,0,
7,6,237,4,0,
7,6,240,1,33,
7,6,240,4,0,
7,6,243,2,0,
7,6,249,4,0,
7,6,258,4,0,
7,6,263,4,0,
7,6,264,4,0,
7,6,280,4,0,
7,6,281,2,0,
7,6,287,2,0,
7,6,290,4,0,
7,6,291,4,0,
7,6,300,2,0,
7,6,352,4,0,
7,7,5,3,0,
7,7,25,3,0,
7,7,33,1,1,
7,7,34,3,0,
7,7,38,3,0,
7,7,39,1,4,
7,7,44,1,18,
7,7,54,2,0,
7,7,55,1,13,
7,7,56,1,47,
7,7,57,4,0,
7,7,58,4,0,
7,7,59,4,0,
7,7,68,3,0,
7,7,69,3,0,
7,7,70,4,0,
7,7,91,4,0,
7,7,92,4,0,
7,7,102,3,0,
7,7,104,4,0,
7,7,110,1,10,
7,7,114,2,0,
7,7,127,4,0,
7,7,130,1,40,
7,7,145,1,7,
7,7,156,4,0,
7,7,164,3,0,
7,7,175,2,0,
7,7,182,1,28,
7,7,182,4,0,
7,7,193,2,0,
7,7,213,4,0,
7,7,216,4,0,
7,7,218,4,0,
7,7,229,1,23,
7,7,231,4,0,
7,7,237,4,0,
7,7,240,1,33,
7,7,240,4,0,
7,7,243,2,0,
7,7,249,4,0,
7,7,258,4,0,
7,7,263,4,0,
7,7,264,4,0,
7,7,280,4,0,
7,7,281,2,0,
7,7,287,2,0,
7,7,290,4,0,
7,7,291,4,0,
7,7,300,2,0,
7,7,352,4,0,
7,8,33,1,1,
7,8,39,1,4,
7,8,44,1,16,
7,8,54,2,0,
7,8,55,1,13,
7,8,56,1,37,
7,8,57,4,0,
7,8,58,4,0,
7,8,59,4,0,
7,8,70,4,0,
7,8,91,4,0,
7,8,92,4,0,
7,8,104,4,0,
7,8,110,1,10,
7,8,114,2,0,
7,8,127,4,0,
7,8,130,1,31,
7,8,145,1,7,
7,8,156,4,0,
7,8,164,4,0,
7,8,175,2,0,
7,8,182,1,22,
7,8,182,4,0,
7,8,193,2,0,
7,8,203,4,0,
7,8,207,4,0,
7,8,213,4,0,
7,8,214,4,0,
7,8,216,4,0,
7,8,218,4,0,
7,8,229,1,19,
7,8,231,4,0,
7,8,237,4,0,
7,8,240,1,34,
7,8,240,4,0,
7,8,243,2,0,
7,8,249,4,0,
7,8,252,2,0,
7,8,258,4,0,
7,8,263,4,0,
7,8,264,4,0,
7,8,280,4,0,
7,8,281,2,0,
7,8,287,2,0,
7,8,290,4,0,
7,8,300,2,0,
7,8,317,4,0,
7,8,330,2,0,
7,8,352,1,25,
7,8,352,4,0,
7,8,360,4,0,
7,8,362,4,0,
7,8,363,4,0,
7,8,374,4,0,
7,8,392,2,0,
7,8,401,1,28,
7,8,445,4,0,
7,8,453,2,0,
7,9,8,3,0,
7,9,33,1,1,
7,9,39,1,4,
7,9,44,1,16,
7,9,54,2,0,
7,9,55,1,13,
7,9,56,1,40,
7,9,57,4,0,
7,9,58,4,0,
7,9,59,4,0,
7,9,70,4,0,
7,9,91,4,0,
7,9,92,4,0,
7,9,104,4,0,
7,9,110,1,10,
7,9,114,2,0,
7,9,127,4,0,
7,9,130,1,31,
7,9,145,1,7,
7,9,156,4,0,
7,9,164,4,0,
7,9,173,3,0,
7,9,175,2,0,
7,9,182,1,22,
7,9,182,4,0,
7,9,189,3,0,
7,9,193,2,0,
7,9,196,3,0,
7,9,203,4,0,
7,9,205,3,0,
7,9,207,4,0,
7,9,213,4,0,
7,9,214,4,0,
7,9,216,4,0,
7,9,218,4,0,
7,9,229,1,19,
7,9,231,4,0,
7,9,237,4,0,
7,9,240,1,37,
7,9,240,4,0,
7,9,243,2,0,
7,9,249,4,0,
7,9,252,2,0,
7,9,258,4,0,
7,9,263,4,0,
7,9,264,4,0,
7,9,280,4,0,
7,9,281,2,0,
7,9,287,2,0,
7,9,290,4,0,
7,9,291,3,0,
7,9,300,2,0,
7,9,317,4,0,
7,9,330,2,0,
7,9,334,1,34,
7,9,334,3,0,
7,9,352,1,25,
7,9,352,4,0,
7,9,360,4,0,
7,9,362,4,0,
7,9,363,4,0,
7,9,374,4,0,
7,9,392,2,0,
7,9,401,1,28,
7,9,401,3,0,
7,9,428,3,0,
7,9,445,4,0,
7,9,453,2,0,
7,10,8,3,0,
7,10,29,3,0,
7,10,33,1,1,
7,10,39,1,4,
7,10,44,1,16,
7,10,54,2,0,
7,10,55,1,13,
7,10,56,1,40,
7,10,57,4,0,
7,10,58,4,0,
7,10,59,4,0,
7,10,70,4,0,
7,10,91,4,0,
7,10,92,4,0,
7,10,104,4,0,
7,10,110,1,10,
7,10,114,2,0,
7,10,127,4,0,
7,10,130,1,31,
7,10,145,1,7,
7,10,156,4,0,
7,10,164,4,0,
7,10,173,3,0,
7,10,175,2,0,
7,10,182,1,22,
7,10,182,4,0,
7,10,189,3,0,
7,10,193,2,0,
7,10,196,3,0,
7,10,203,4,0,
7,10,205,3,0,
7,10,207,4,0,
7,10,213,4,0,
7,10,214,4,0,
7,10,216,4,0,
7,10,218,4,0,
7,10,229,1,19,
7,10,231,4,0,
7,10,237,4,0,
7,10,240,1,37,
7,10,240,4,0,
7,10,243,2,0,
7,10,249,4,0,
7,10,250,4,0,
7,10,252,2,0,
7,10,258,4,0,
7,10,263,4,0,
7,10,264,4,0,
7,10,280,4,0,
7,10,281,2,0,
7,10,287,2,0,
7,10,290,4,0,
7,10,291,3,0,
7,10,300,2,0,
7,10,317,4,0,
7,10,323,2,0,
7,10,330,2,0,
7,10,334,1,34,
7,10,334,3,0,
7,10,352,1,25,
7,10,352,4,0,
7,10,360,4,0,
7,10,362,4,0,
7,10,363,4,0,
7,10,374,4,0,
7,10,392,2,0,
7,10,401,1,28,
7,10,401,3,0,
7,10,428,3,0,
7,10,445,4,0,
7,10,453,2,0,
7,11,33,1,1,
7,11,39,1,4,
7,11,44,1,16,
7,11,54,2,0,
7,11,55,1,13,
7,11,56,1,40,
7,11,57,4,0,
7,11,58,4,0,
7,11,59,4,0,
7,11,70,4,0,
7,11,91,4,0,
7,11,92,4,0,
7,11,104,4,0,
7,11,110,1,10,
7,11,114,2,0,
7,11,127,4,0,
7,11,130,1,31,
7,11,145,1,7,
7,11,156,4,0,
7,11,164,4,0,
7,11,175,2,0,
7,11,182,1,22,
7,11,182,4,0,
7,11,193,2,0,
7,11,207,4,0,
7,11,213,4,0,
7,11,216,4,0,
7,11,218,4,0,
7,11,229,1,19,
7,11,237,4,0,
7,11,240,1,37,
7,11,240,4,0,
7,11,243,2,0,
7,11,249,4,0,
7,11,252,2,0,
7,11,258,4,0,
7,11,263,4,0,
7,11,280,4,0,
7,11,281,2,0,
7,11,287,2,0,
7,11,291,4,0,
7,11,300,2,0,
7,11,317,4,0,
7,11,323,2,0,
7,11,330,2,0,
7,11,334,1,34,
7,11,352,1,25,
7,11,360,4,0,
7,11,362,2,0,
7,11,374,4,0,
7,11,392,2,0,
7,11,401,1,28,
7,11,453,2,0,
7,11,496,4,0,
7,11,503,4,0,
7,11,518,3,0,
7,12,33,1,1,
7,12,39,1,4,
7,12,44,1,18,
7,12,55,1,13,
7,12,56,1,47,
7,12,57,4,0,
7,12,58,4,0,
7,12,59,4,0,
7,12,70,4,0,
7,12,91,4,0,
7,12,92,4,0,
7,12,104,4,0,
7,12,110,1,10,
7,12,127,4,0,
7,12,130,1,40,
7,12,145,1,7,
7,12,156,4,0,
7,12,182,1,28,
7,12,182,4,0,
7,12,213,4,0,
7,12,216,4,0,
7,12,218,4,0,
7,12,229,1,23,
7,12,231,4,0,
7,12,237,4,0,
7,12,240,1,33,
7,12,240,4,0,
7,12,249,4,0,
7,12,258,4,0,
7,12,263,4,0,
7,12,264,4,0,
7,12,280,4,0,
7,12,290,4,0,
7,12,291,4,0,
7,12,352,4,0,
7,13,33,1,1,
7,13,34,3,0,
7,13,38,3,0,
7,13,39,1,4,
7,13,44,1,18,
7,13,55,1,13,
7,13,56,1,47,
7,13,57,4,0,
7,13,58,4,0,
7,13,59,4,0,
7,13,69,3,0,
7,13,70,4,0,
7,13,91,4,0,
7,13,92,4,0,
7,13,102,3,0,
7,13,104,4,0,
7,13,110,1,10,
7,13,127,4,0,
7,13,130,1,40,
7,13,145,1,7,
7,13,156,4,0,
7,13,164,3,0,
7,13,182,1,28,
7,13,182,4,0,
7,13,196,3,0,
7,13,207,3,0,
7,13,213,4,0,
7,13,216,4,0,
7,13,218,4,0,
7,13,229,1,23,
7,13,231,4,0,
7,13,237,4,0,
7,13,240,1,33,
7,13,240,4,0,
7,13,249,4,0,
7,13,258,4,0,
7,13,263,4,0,
7,13,264,4,0,
7,13,280,4,0,
7,13,290,4,0,
7,13,291,4,0,
7,13,352,4,0,
7,14,8,3,0,
7,14,33,1,1,
7,14,39,1,4,
7,14,44,1,16,
7,14,54,2,0,
7,14,55,1,13,
7,14,56,1,40,
7,14,57,4,0,
7,14,58,4,0,
7,14,59,4,0,
7,14,70,4,0,
7,14,91,4,0,
7,14,92,4,0,
7,14,104,4,0,
7,14,110,1,10,
7,14,114,2,0,
7,14,127,4,0,
7,14,130,1,31,
7,14,145,1,7,
7,14,156,4,0,
7,14,164,4,0,
7,14,173,3,0,
7,14,175,2,0,
7,14,182,1,22,
7,14,182,4,0,
7,14,193,2,0,
7,14,196,3,0,
7,14,207,4,0,
7,14,213,4,0,
7,14,214,3,0,
7,14,216,4,0,
7,14,218,4,0,
7,14,229,1,19,
7,14,231,3,0,
7,14,237,4,0,
7,14,240,1,37,
7,14,240,4,0,
7,14,243,2,0,
7,14,249,4,0,
7,14,252,2,0,
7,14,258,4,0,
7,14,263,4,0,
7,14,280,4,0,
7,14,281,2,0,
7,14,287,2,0,
7,14,291,4,0,
7,14,300,2,0,
7,14,317,4,0,
7,14,323,2,0,
7,14,330,2,0,
7,14,334,1,34,
7,14,334,3,0,
7,14,352,1,25,
7,14,360,4,0,
7,14,362,2,0,
7,14,374,4,0,
7,14,392,2,0,
7,14,401,1,28,
7,14,401,3,0,
7,14,428,3,0,
7,14,453,2,0,
7,14,496,4,0,
7,14,503,4,0,
7,14,518,3,0,
7,15,33,1,1,
7,15,39,1,4,
7,15,44,1,16,
7,15,54,2,0,
7,15,55,1,7,
7,15,56,1,40,
7,15,57,4,0,
7,15,58,4,0,
7,15,59,4,0,
7,15,70,4,0,
7,15,91,4,0,
7,15,92,4,0,
7,15,104,4,0,
7,15,110,1,10,
7,15,114,2,0,
7,15,127,4,0,
7,15,130,1,31,
7,15,145,1,13,
7,15,156,4,0,
7,15,164,4,0,
7,15,175,2,0,
7,15,182,1,22,
7,15,182,4,0,
7,15,193,2,0,
7,15,207,4,0,
7,15,213,4,0,
7,15,214,4,0,
7,15,216,4,0,
7,15,218,4,0,
7,15,229,1,19,
7,15,237,4,0,
7,15,240,1,37,
7,15,240,4,0,
7,15,243,2,0,
7,15,249,4,0,
7,15,252,2,0,
7,15,258,4,0,
7,15,263,4,0,
7,15,280,4,0,
7,15,281,2,0,
7,15,287,2,0,
7,15,300,2,0,
7,15,317,4,0,
7,15,323,2,0,
7,15,330,2,0,
7,15,334,1,34,
7,15,352,1,25,
7,15,360,4,0,
7,15,362,2,0,
7,15,374,4,0,
7,15,392,2,0,
7,15,396,2,0,
7,15,401,1,28,
7,15,406,2,0,
7,15,453,2,0,
7,15,496,4,0,
7,15,503,4,0,
7,15,518,3,0,
7,15,590,4,0,
7,15,612,4,0,
7,16,8,3,0,
7,16,33,1,1,1
7,16,39,1,4,1
7,16,44,1,16,1
7,16,54,2,0,
7,16,55,1,7,1
7,16,56,1,40,1
7,16,57,4,0,
7,16,58,4,0,
7,16,59,4,0,
7,16,70,4,0,
7,16,91,4,0,
7,16,92,4,0,
7,16,104,4,0,
7,16,110,1,10,1
7,16,114,2,0,
7,16,127,4,0,
7,16,130,1,31,1
7,16,145,1,13,1
7,16,156,4,0,
7,16,164,4,0,
7,16,173,3,0,
7,16,175,2,0,
7,16,182,1,22,1
7,16,182,4,0,
7,16,193,2,0,
7,16,196,3,0,
7,16,207,4,0,
7,16,213,4,0,
7,16,214,4,0,
7,16,216,4,0,
7,16,218,4,0,
7,16,229,1,19,1
7,16,231,3,0,
7,16,237,4,0,
7,16,240,1,37,1
7,16,240,4,0,
7,16,243,2,0,
7,16,249,4,0,
7,16,252,2,0,
7,16,258,4,0,
7,16,263,4,0,
7,16,264,3,0,
7,16,280,4,0,
7,16,281,2,0,
7,16,287,2,0,
7,16,290,4,0,
7,16,291,4,0,
7,16,300,2,0,
7,16,317,4,0,
7,16,323,2,0,
7,16,330,2,0,
7,16,334,1,34,1
7,16,334,3,0,
7,16,352,1,25,1
7,16,352,3,0,
7,16,360,4,0,
7,16,362,2,0,
7,16,374,4,0,
7,16,392,2,0,
7,16,396,2,0,
7,16,401,1,28,1
7,16,401,3,0,
7,16,406,2,0,
7,16,406,3,0,
7,16,428,3,0,
7,16,453,2,0,
7,16,496,4,0,
7,16,503,4,0,
7,16,518,3,0,
7,16,590,4,0,
7,16,612,4,0,
7,17,33,1,1,
7,17,39,1,4,
7,17,44,1,16,
7,17,54,2,0,
7,17,55,1,7,
7,17,56,1,40,
7,17,57,4,0,
7,17,58,4,0,
7,17,59,4,0,
7,17,92,4,0,
7,17,104,4,0,
7,17,110,1,10,
7,17,114,2,0,
7,17,127,4,0,
7,17,130,1,31,
7,17,145,1,13,
7,17,156,4,0,
7,17,164,4,0,
7,17,175,2,0,
7,17,182,1,22,
7,17,182,4,0,
7,17,193,2,0,
7,17,207,4,0,
7,17,213,4,0,
7,17,214,4,0,
7,17,216,4,0,
7,17,218,4,0,
7,17,229,1,19,
7,17,237,4,0,
7,17,240,1,37,
7,17,240,4,0,
7,17,243,2,0,
7,17,252,2,0,
7,17,258,4,0,
7,17,263,4,0,
7,17,280,4,0,
7,17,281,2,0,
7,17,287,2,0,
7,17,300,2,0,
7,17,317,4,0,
7,17,323,2,0,
7,17,330,2,0,
7,17,334,1,34,
7,17,352,1,25,
7,17,360,4,0,
7,17,362,2,0,
7,17,374,4,0,
7,17,392,2,0,
7,17,396,2,0,
7,17,401,1,28,
7,17,406,2,0,
7,17,453,2,0,
7,17,496,4,0,
7,17,503,4,0,
7,17,518,3,0,
7,17,526,4,0,
7,17,590,4,0,
7,18,33,1,1,
7,18,39,1,4,
7,18,44,1,16,
7,18,54,2,0,
7,18,55,1,7,
7,18,56,1,40,
7,18,57,4,0,
7,18,58,4,0,
7,18,59,4,0,
7,18,92,4,0,
7,18,104,4,0,
7,18,110,1,10,
7,18,114,2,0,
7,18,127,4,0,
7,18,130,1,31,
7,18,145,1,13,
7,18,156,4,0,
7,18,164,4,0,
7,18,175,2,0,
7,18,182,1,22,
7,18,182,4,0,
7,18,193,2,0,
7,18,207,4,0,
7,18,213,4,0,
7,18,214,4,0,
7,18,216,4,0,
7,18,218,4,0,
7,18,229,1,19,
7,18,237,4,0,
7,18,240,1,37,
7,18,240,4,0,
7,18,243,2,0,
7,18,252,2,0,
7,18,258,4,0,
7,18,263,4,0,
7,18,280,4,0,
7,18,281,2,0,
7,18,287,2,0,
7,18,300,2,0,
7,18,317,4,0,
7,18,323,2,0,
7,18,330,2,0,
7,18,334,1,34,
7,18,352,1,25,
7,18,360,4,0,
7,18,362,2,0,
7,18,374,4,0,
7,18,392,2,0,
7,18,396,2,0,
7,18,401,1,28,
7,18,406,2,0,
7,18,453,2,0,
7,18,496,4,0,
7,18,503,4,0,
7,18,518,3,0,
7,18,526,4,0,
7,18,590,4,0,
8,1,5,4,0,
8,1,25,4,0,
8,1,33,1,1,1
8,1,34,4,0,
8,1,36,4,0,
8,1,38,4,0,
8,1,39,1,1,2
8,1,44,1,24,
8,1,55,1,15,
8,1,55,4,0,
8,1,56,1,47,
8,1,57,4,0,
8,1,58,4,0,
8,1,59,4,0,
8,1,61,4,0,
8,1,66,4,0,
8,1,68,4,0,
8,1,69,4,0,
8,1,70,4,0,
8,1,91,4,0,
8,1,92,4,0,
8,1,99,4,0,
8,1,102,4,0,
8,1,104,4,0,
8,1,110,1,31,
8,1,115,4,0,
8,1,117,4,0,
8,1,130,1,39,
8,1,130,4,0,
8,1,145,1,1,3
8,1,145,1,8,
8,1,156,4,0,
8,1,164,4,0,
8,2,5,4,0,
8,2,25,4,0,
8,2,33,1,1,1
8,2,34,4,0,
8,2,36,4,0,
8,2,38,4,0,
8,2,39,1,1,2
8,2,44,1,24,
8,2,55,1,15,
8,2,55,4,0,
8,2,56,1,47,
8,2,57,4,0,
8,2,58,4,0,
8,2,59,4,0,
8,2,61,4,0,
8,2,66,4,0,
8,2,68,4,0,
8,2,69,4,0,
8,2,70,4,0,
8,2,91,4,0,
8,2,92,4,0,
8,2,99,4,0,
8,2,102,4,0,
8,2,104,4,0,
8,2,110,1,31,
8,2,115,4,0,
8,2,117,4,0,
8,2,130,1,39,
8,2,130,4,0,
8,2,145,1,1,3
8,2,145,1,8,
8,2,156,4,0,
8,2,164,4,0,
8,3,8,4,0,
8,3,29,4,0,
8,3,33,1,1,1
8,3,39,1,1,2
8,3,39,1,4,
8,3,44,1,19,
8,3,55,1,13,
8,3,56,1,53,
8,3,57,4,0,
8,3,59,4,0,
8,3,70,4,0,
8,3,91,4,0,
8,3,92,4,0,
8,3,104,4,0,
8,3,110,1,10,
8,3,111,4,0,
8,3,127,4,0,
8,3,130,1,45,
8,3,145,1,1,3
8,3,145,1,7,
8,3,156,4,0,
8,3,173,4,0,
8,3,174,4,0,
8,3,182,1,31,
8,3,182,4,0,
8,3,189,4,0,
8,3,196,4,0,
8,3,203,4,0,
8,3,205,4,0,
8,3,207,4,0,
8,3,213,4,0,
8,3,214,4,0,
8,3,216,4,0,
8,3,218,4,0,
8,3,223,4,0,
8,3,229,1,25,
8,3,231,4,0,
8,3,237,4,0,
8,3,240,1,37,
8,3,240,4,0,
8,3,249,4,0,
8,3,250,4,0,
8,4,8,4,0,
8,4,29,4,0,
8,4,33,1,1,1
8,4,39,1,1,2
8,4,39,1,4,
8,4,44,1,19,
8,4,55,1,13,
8,4,56,1,53,
8,4,57,4,0,
8,4,58,3,0,
8,4,59,4,0,
8,4,70,4,0,
8,4,91,4,0,
8,4,92,4,0,
8,4,104,4,0,
8,4,110,1,10,
8,4,111,4,0,
8,4,127,4,0,
8,4,130,1,45,
8,4,145,1,1,3
8,4,145,1,7,
8,4,156,4,0,
8,4,173,4,0,
8,4,174,4,0,
8,4,182,1,31,
8,4,182,4,0,
8,4,189,4,0,
8,4,196,4,0,
8,4,203,4,0,
8,4,205,4,0,
8,4,207,4,0,
8,4,213,4,0,
8,4,214,4,0,
8,4,216,4,0,
8,4,218,4,0,
8,4,223,4,0,
8,4,229,1,25,
8,4,231,4,0,
8,4,237,4,0,
8,4,240,1,37,
8,4,240,4,0,
8,4,249,4,0,
8,4,250,4,0,
8,5,33,1,1,1
8,5,39,1,1,2
8,5,39,1,4,
8,5,44,1,19,
8,5,55,1,13,
8,5,56,1,53,
8,5,57,4,0,
8,5,58,4,0,
8,5,59,4,0,
8,5,70,4,0,
8,5,91,4,0,
8,5,92,4,0,
8,5,104,4,0,
8,5,110,1,10,
8,5,127,4,0,
8,5,130,1,45,
8,5,145,1,1,3
8,5,145,1,7,
8,5,156,4,0,
8,5,182,1,31,
8,5,182,4,0,
8,5,213,4,0,
8,5,216,4,0,
8,5,218,4,0,
8,5,229,1,25,
8,5,231,4,0,
8,5,237,4,0,
8,5,240,1,37,
8,5,240,4,0,
8,5,249,4,0,
8,5,258,4,0,
8,5,263,4,0,
8,5,264,4,0,
8,5,280,4,0,
8,5,290,4,0,
8,5,291,4,0,
8,5,352,4,0,
8,6,5,3,0,
8,6,8,3,0,
8,6,25,3,0,
8,6,33,1,1,1
8,6,34,3,0,
8,6,38,3,0,
8,6,39,1,1,2
8,6,39,1,4,
8,6,44,1,19,
8,6,55,1,13,
8,6,56,1,53,
8,6,57,4,0,
8,6,58,4,0,
8,6,59,4,0,
8,6,68,3,0,
8,6,69,3,0,
8,6,70,4,0,
8,6,91,4,0,
8,6,92,4,0,
8,6,102,3,0,
8,6,104,4,0,
8,6,110,1,10,
8,6,111,3,0,
8,6,127,4,0,
8,6,130,1,45,
8,6,145,1,1,3
8,6,145,1,7,
8,6,156,4,0,
8,6,164,3,0,
8,6,173,3,0,
8,6,182,1,31,
8,6,182,4,0,
8,6,189,3,0,
8,6,196,3,0,
8,6,203,3,0,
8,6,205,3,0,
8,6,207,3,0,
8,6,213,4,0,
8,6,214,3,0,
8,6,216,4,0,
8,6,218,4,0,
8,6,223,3,0,
8,6,229,1,25,
8,6,231,4,0,
8,6,237,4,0,
8,6,240,1,37,
8,6,240,4,0,
8,6,249,4,0,
8,6,258,4,0,
8,6,263,4,0,
8,6,264,4,0,
8,6,280,4,0,
8,6,290,4,0,
8,6,291,4,0,
8,6,352,4,0,
8,7,5,3,0,
8,7,25,3,0,
8,7,33,1,1,1
8,7,34,3,0,
8,7,38,3,0,
8,7,39,1,1,2
8,7,39,1,4,
8,7,44,1,19,
8,7,55,1,13,
8,7,56,1,53,
8,7,57,4,0,
8,7,58,4,0,
8,7,59,4,0,
8,7,68,3,0,
8,7,69,3,0,
8,7,70,4,0,
8,7,91,4,0,
8,7,92,4,0,
8,7,102,3,0,
8,7,104,4,0,
8,7,110,1,10,
8,7,127,4,0,
8,7,130,1,45,
8,7,145,1,1,3
8,7,145,1,7,
8,7,156,4,0,
8,7,164,3,0,
8,7,182,1,31,
8,7,182,4,0,
8,7,213,4,0,
8,7,216,4,0,
8,7,218,4,0,
8,7,229,1,25,
8,7,231,4,0,
8,7,237,4,0,
8,7,240,1,37,
8,7,240,4,0,
8,7,249,4,0,
8,7,258,4,0,
8,7,263,4,0,
8,7,264,4,0,
8,7,280,4,0,
8,7,290,4,0,
8,7,291,4,0,
8,7,352,4,0,
8,8,33,1,1,1
8,8,39,1,1,2
8,8,39,1,4,
8,8,44,1,16,
8,8,55,1,13,
8,8,56,1,44,
8,8,57,4,0,
8,8,58,4,0,
8,8,59,4,0,
8,8,70,4,0,
8,8,91,4,0,
8,8,92,4,0,
8,8,104,4,0,
8,8,110,1,10,
8,8,127,4,0,
8,8,130,1,36,
8,8,145,1,1,3
8,8,145,1,7,
8,8,156,4,0,
8,8,164,4,0,
8,8,182,1,24,
8,8,182,4,0,
8,8,203,4,0,
8,8,207,4,0,
8,8,213,4,0,
8,8,214,4,0,
8,8,216,4,0,
8,8,218,4,0,
8,8,229,1,20,
8,8,231,4,0,
8,8,237,4,0,
8,8,240,1,40,
8,8,240,4,0,
8,8,249,4,0,
8,8,258,4,0,
8,8,263,4,0,
8,8,264,4,0,
8,8,280,4,0,
8,8,290,4,0,
8,8,317,4,0,
8,8,352,1,28,
8,8,352,4,0,
8,8,360,4,0,
8,8,362,4,0,
8,8,363,4,0,
8,8,374,4,0,
8,8,401,1,32,
8,8,445,4,0,
8,9,8,3,0,
8,9,33,1,1,1
8,9,39,1,1,2
8,9,39,1,4,
8,9,44,1,16,
8,9,55,1,13,
8,9,56,1,48,
8,9,57,4,0,
8,9,58,4,0,
8,9,59,4,0,
8,9,70,4,0,
8,9,91,4,0,
8,9,92,4,0,
8,9,104,4,0,
8,9,110,1,10,
8,9,127,4,0,
8,9,130,1,36,
8,9,145,1,1,3
8,9,145,1,7,
8,9,156,4,0,
8,9,164,4,0,
8,9,173,3,0,
8,9,182,1,24,
8,9,182,4,0,
8,9,189,3,0,
8,9,196,3,0,
8,9,203,4,0,
8,9,205,3,0,
8,9,207,4,0,
8,9,213,4,0,
8,9,214,4,0,
8,9,216,4,0,
8,9,218,4,0,
8,9,229,1,20,
8,9,231,4,0,
8,9,237,4,0,
8,9,240,1,44,
8,9,240,4,0,
8,9,249,4,0,
8,9,258,4,0,
8,9,263,4,0,
8,9,264,4,0,
8,9,280,4,0,
8,9,290,4,0,
8,9,291,3,0,
8,9,317,4,0,
8,9,334,1,40,
8,9,334,3,0,
8,9,352,1,28,
8,9,352,4,0,
8,9,360,4,0,
8,9,362,4,0,
8,9,363,4,0,
8,9,374,4,0,
8,9,401,1,32,
8,9,401,3,0,
8,9,428,3,0,
8,9,445,4,0,
8,10,8,3,0,
8,10,29,3,0,
8,10,33,1,1,1
8,10,39,1,1,2
8,10,39,1,4,
8,10,44,1,16,
8,10,55,1,13,
8,10,56,1,48,
8,10,57,4,0,
8,10,58,4,0,
8,10,59,4,0,
8,10,70,4,0,
8,10,91,4,0,
8,10,92,4,0,
8,10,104,4,0,
8,10,110,1,10,
8,10,127,4,0,
8,10,130,1,36,
8,10,145,1,1,3
8,10,145,1,7,
8,10,156,4,0,
8,10,164,4,0,
8,10,173,3,0,
8,10,182,1,24,
8,10,182,4,0,
8,10,189,3,0,
8,10,196,3,0,
8,10,203,4,0,
8,10,205,3,0,
8,10,207,4,0,
8,10,213,4,0,
8,10,214,4,0,
8,10,216,4,0,
8,10,218,4,0,
8,10,229,1,20,
8,10,231,4,0,
8,10,237,4,0,
8,10,240,1,44,
8,10,240,4,0,
8,10,249,4,0,
8,10,250,4,0,
8,10,258,4,0,
8,10,263,4,0,
8,10,264,4,0,
8,10,280,4,0,
8,10,290,4,0,
8,10,291,3,0,
8,10,317,4,0,
8,10,334,1,40,
8,10,334,3,0,
8,10,352,1,28,
8,10,352,4,0,
8,10,360,4,0,
8,10,362,4,0,
8,10,363,4,0,
8,10,374,4,0,
8,10,401,1,32,
8,10,401,3,0,
8,10,428,3,0,
8,10,445,4,0,
8,11,33,1,1,1
8,11,39,1,1,2
8,11,39,1,4,
8,11,44,1,16,
8,11,55,1,13,
8,11,56,1,48,
8,11,57,4,0,
8,11,58,4,0,
8,11,59,4,0,
8,11,70,4,0,
8,11,91,4,0,
8,11,92,4,0,
8,11,104,4,0,
8,11,110,1,10,
8,11,127,4,0,
8,11,130,1,36,
8,11,145,1,1,3
8,11,145,1,7,
8,11,156,4,0,
8,11,164,4,0,
8,11,182,1,24,
8,11,182,4,0,
8,11,207,4,0,
8,11,213,4,0,
8,11,216,4,0,
8,11,218,4,0,
8,11,229,1,20,
8,11,237,4,0,
8,11,240,1,44,
8,11,240,4,0,
8,11,249,4,0,
8,11,258,4,0,
8,11,263,4,0,
8,11,280,4,0,
8,11,291,4,0,
8,11,317,4,0,
8,11,334,1,40,
8,11,352,1,28,
8,11,360,4,0,
8,11,374,4,0,
8,11,401,1,32,
8,11,496,4,0,
8,11,503,4,0,
8,11,518,3,0,
8,12,33,1,1,1
8,12,39,1,1,2
8,12,39,1,4,
8,12,44,1,19,
8,12,55,1,13,
8,12,56,1,53,
8,12,57,4,0,
8,12,58,4,0,
8,12,59,4,0,
8,12,70,4,0,
8,12,91,4,0,
8,12,92,4,0,
8,12,104,4,0,
8,12,110,1,10,
8,12,127,4,0,
8,12,130,1,45,
8,12,145,1,1,3
8,12,145,1,7,
8,12,156,4,0,
8,12,182,1,31,
8,12,182,4,0,
8,12,213,4,0,
8,12,216,4,0,
8,12,218,4,0,
8,12,229,1,25,
8,12,231,4,0,
8,12,237,4,0,
8,12,240,1,37,
8,12,240,4,0,
8,12,249,4,0,
8,12,258,4,0,
8,12,263,4,0,
8,12,264,4,0,
8,12,280,4,0,
8,12,290,4,0,
8,12,291,4,0,
8,12,352,4,0,
8,13,33,1,1,1
8,13,34,3,0,
8,13,38,3,0,
8,13,39,1,1,2
8,13,39,1,4,
8,13,44,1,19,
8,13,55,1,13,
8,13,56,1,53,
8,13,57,4,0,
8,13,58,4,0,
8,13,59,4,0,
8,13,69,3,0,
8,13,70,4,0,
8,13,91,4,0,
8,13,92,4,0,
8,13,102,3,0,
8,13,104,4,0,
8,13,110,1,10,
8,13,127,4,0,
8,13,130,1,45,
8,13,145,1,1,3
8,13,145,1,7,
8,13,156,4,0,
8,13,164,3,0,
8,13,182,1,31,
8,13,182,4,0,
8,13,196,3,0,
8,13,207,3,0,
8,13,213,4,0,
8,13,216,4,0,
8,13,218,4,0,
8,13,229,1,25,
8,13,231,4,0,
8,13,237,4,0,
8,13,240,1,37,
8,13,240,4,0,
8,13,249,4,0,
8,13,258,4,0,
8,13,263,4,0,
8,13,264,4,0,
8,13,280,4,0,
8,13,290,4,0,
8,13,291,4,0,
8,13,352,4,0,
8,14,8,3,0,
8,14,33,1,1,1
8,14,39,1,1,2
8,14,39,1,4,
8,14,44,1,16,
8,14,55,1,13,
8,14,56,1,48,
8,14,57,4,0,
8,14,58,4,0,
8,14,59,4,0,
8,14,70,4,0,
8,14,91,4,0,
8,14,92,4,0,
8,14,104,4,0,
8,14,110,1,10,
8,14,127,4,0,
8,14,130,1,36,
8,14,145,1,1,3
8,14,145,1,7,
8,14,156,4,0,
8,14,164,4,0,
8,14,173,3,0,
8,14,182,1,24,
8,14,182,4,0,
8,14,196,3,0,
8,14,207,4,0,
8,14,213,4,0,
8,14,214,3,0,
8,14,216,4,0,
8,14,218,4,0,
8,14,229,1,20,
8,14,231,3,0,
8,14,237,4,0,
8,14,240,1,44,
8,14,240,4,0,
8,14,249,4,0,
8,14,258,4,0,
8,14,263,4,0,
8,14,280,4,0,
8,14,291,4,0,
8,14,317,4,0,
8,14,334,1,40,
8,14,334,3,0,
8,14,352,1,28,
8,14,360,4,0,
8,14,374,4,0,
8,14,401,1,32,
8,14,401,3,0,
8,14,428,3,0,
8,14,496,4,0,
8,14,503,4,0,
8,14,518,3,0,
8,15,33,1,1,1
8,15,39,1,1,2
8,15,39,1,4,
8,15,44,1,16,
8,15,55,1,1,3
8,15,55,1,7,
8,15,56,1,48,
8,15,57,4,0,
8,15,58,4,0,
8,15,59,4,0,
8,15,70,4,0,
8,15,91,4,0,
8,15,92,4,0,
8,15,104,4,0,
8,15,110,1,10,
8,15,127,4,0,
8,15,130,1,36,
8,15,145,1,13,
8,15,156,4,0,
8,15,164,4,0,
8,15,182,1,24,
8,15,182,4,0,
8,15,207,4,0,
8,15,213,4,0,
8,15,214,4,0,
8,15,216,4,0,
8,15,218,4,0,
8,15,229,1,20,
8,15,237,4,0,
8,15,240,1,44,
8,15,240,4,0,
8,15,249,4,0,
8,15,258,4,0,
8,15,263,4,0,
8,15,280,4,0,
8,15,317,4,0,
8,15,334,1,40,
8,15,352,1,28,
8,15,360,4,0,
8,15,374,4,0,
8,15,401,1,32,
8,15,496,4,0,
8,15,503,4,0,
8,15,518,3,0,
8,15,590,4,0,
8,15,612,4,0,
8,16,8,3,0,
8,16,33,1,1,1
8,16,39,1,1,2
8,16,39,1,4,1
8,16,44,1,16,1
8,16,55,1,1,3
8,16,55,1,7,1
8,16,56,1,48,1
8,16,57,4,0,
8,16,58,4,0,
8,16,59,4,0,
8,16,70,4,0,
8,16,91,4,0,
8,16,92,4,0,
8,16,104,4,0,
8,16,110,1,10,1
8,16,127,4,0,
8,16,130,1,36,1
8,16,145,1,13,1
8,16,156,4,0,
8,16,164,4,0,
8,16,173,3,0,
8,16,182,1,24,1
8,16,182,4,0,
8,16,196,3,0,
8,16,207,4,0,
8,16,213,4,0,
8,16,214,4,0,
8,16,216,4,0,
8,16,218,4,0,
8,16,229,1,20,1
8,16,231,3,0,
8,16,237,4,0,
8,16,240,1,44,1
8,16,240,4,0,
8,16,249,4,0,
8,16,258,4,0,
8,16,263,4,0,
8,16,264,3,0,
8,16,280,4,0,
8,16,290,4,0,
8,16,291,4,0,
8,16,317,4,0,
8,16,334,1,40,1
8,16,334,3,0,
8,16,352,1,28,1
8,16,352,3,0,
8,16,360,4,0,
8,16,374,4,0,
8,16,401,1,32,1
8,16,401,3,0,
8,16,406,3,0,
8,16,428,3,0,
8,16,496,4,0,
8,16,503,4,0,
8,16,518,3,0,
8,16,590,4,0,
8,16,612,4,0,
8,17,33,1,1,1
8,17,39,1,1,2
8,17,39,1,4,
8,17,44,1,17,
8,17,55,1,1,3
8,17,55,1,7,
8,17,56,1,49,
8,17,57,4,0,
8,17,58,4,0,
8,17,59,4,0,
8,17,92,4,0,
8,17,104,4,0,
8,17,110,1,10,
8,17,127,4,0,
8,17,130,1,37,
8,17,145,1,13,
8,17,156,4,0,
8,17,164,4,0,
8,17,182,1,25,
8,17,182,4,0,
8,17,207,4,0,
8,17,213,4,0,
8,17,214,4,0,
8,17,216,4,0,
8,17,218,4,0,
8,17,229,1,21,
8,17,237,4,0,
8,17,240,1,45,
8,17,240,4,0,
8,17,258,4,0,
8,17,263,4,0,
8,17,280,4,0,
8,17,317,4,0,
8,17,334,1,41,
8,17,352,1,29,
8,17,360,4,0,
8,17,374,4,0,
8,17,401,1,33,
8,17,496,4,0,
8,17,503,4,0,
8,17,518,3,0,
8,17,526,4,0,
8,17,590,4,0,
8,18,33,1,1,1
8,18,39,1,1,2
8,18,39,1,4,
8,18,44,1,17,
8,18,55,1,1,3
8,18,55,1,7,
8,18,56,1,49,
8,18,57,4,0,
8,18,58,4,0,
8,18,59,4,0,
8,18,92,4,0,
8,18,104,4,0,
8,18,110,1,10,
8,18,127,4,0,
8,18,130,1,37,
8,18,145,1,13,
8,18,156,4,0,
8,18,164,4,0,
8,18,182,1,25,
8,18,182,4,0,
8,18,207,4,0,
8,18,213,4,0,
8,18,214,4,0,
8,18,216,4,0,
8,18,218,4,0,
8,18,229,1,21,
8,18,237,4,0,
8,18,240,1,45,
8,18,240,4,0,
8,18,258,4,0,
8,18,263,4,0,
8,18,280,4,0,
8,18,317,4,0,
8,18,334,1,41,
8,18,352,1,29,
8,18,360,4,0,
8,18,374,4,0,
8,18,401,1,33,
8,18,496,4,0,
8,18,503,4,0,
8,18,518,3,0,
8,18,526,4,0,
8,18,590,4,0,
9,1,5,4,0,
9,1,25,4,0,
9,1,33,1,1,1
9,1,34,4,0,
9,1,36,4,0,
9,1,38,4,0,
9,1,39,1,1,2
9,1,44,1,24,
9,1,55,1,1,4
9,1,55,1,15,
9,1,55,4,0,
9,1,56,1,52,
9,1,57,4,0,
9,1,58,4,0,
9,1,59,4,0,
9,1,61,4,0,
9,1,63,4,0,
9,1,66,4,0,
9,1,68,4,0,
9,1,69,4,0,
9,1,70,4,0,
9,1,89,4,0,
9,1,90,4,0,
9,1,91,4,0,
9,1,92,4,0,
9,1,99,4,0,
9,1,102,4,0,
9,1,104,4,0,
9,1,110,1,31,
9,1,115,4,0,
9,1,117,4,0,
9,1,130,1,42,
9,1,130,4,0,
9,1,145,1,1,3
9,1,145,1,8,
9,1,156,4,0,
9,1,164,4,0,
9,2,5,4,0,
9,2,25,4,0,
9,2,33,1,1,1
9,2,34,4,0,
9,2,36,4,0,
9,2,38,4,0,
9,2,39,1,1,2
9,2,44,1,24,
9,2,55,1,1,4
9,2,55,1,15,
9,2,55,4,0,
9,2,56,1,52,
9,2,57,4,0,
9,2,58,4,0,
9,2,59,4,0,
9,2,61,4,0,
9,2,63,4,0,
9,2,66,4,0,
9,2,68,4,0,
9,2,69,4,0,
9,2,70,4,0,
9,2,89,4,0,
9,2,90,4,0,
9,2,91,4,0,
9,2,92,4,0,
9,2,99,4,0,
9,2,102,4,0,
9,2,104,4,0,
9,2,110,1,31,
9,2,115,4,0,
9,2,117,4,0,
9,2,130,1,42,
9,2,130,4,0,
9,2,145,1,1,3
9,2,145,1,8,
9,2,156,4,0,
9,2,164,4,0,
9,3,8,4,0,
9,3,29,4,0,
9,3,33,1,1,1
9,3,39,1,1,2
9,3,39,1,4,
9,3,44,1,19,
9,3,46,4,0,
9,3,55,1,13,
9,3,56,1,68,
9,3,57,4,0,
9,3,59,4,0,
9,3,63,4,0,
9,3,70,4,0,
9,3,89,4,0,
9,3,91,4,0,
9,3,92,4,0,
9,3,104,4,0,
9,3,110,1,1,4
9,3,110,1,10,
9,3,111,4,0,
9,3,127,4,0,
9,3,130,1,55,
9,3,145,1,1,3
9,3,145,1,7,
9,3,156,4,0,
9,3,173,4,0,
9,3,174,4,0,
9,3,182,1,31,
9,3,182,4,0,
9,3,189,4,0,
9,3,196,4,0,
9,3,203,4,0,
9,3,205,4,0,
9,3,207,4,0,
9,3,213,4,0,
9,3,214,4,0,
9,3,216,4,0,
9,3,218,4,0,
9,3,223,4,0,
9,3,229,1,25,
9,3,231,4,0,
9,3,237,4,0,
9,3,240,1,42,
9,3,240,4,0,
9,3,249,4,0,
9,3,250,4,0,
9,4,8,4,0,
9,4,29,4,0,
9,4,33,1,1,1
9,4,39,1,1,2
9,4,39,1,4,
9,4,44,1,19,
9,4,46,4,0,
9,4,55,1,13,
9,4,56,1,68,
9,4,57,4,0,
9,4,58,3,0,
9,4,59,4,0,
9,4,63,4,0,
9,4,70,4,0,
9,4,89,4,0,
9,4,91,4,0,
9,4,92,4,0,
9,4,104,4,0,
9,4,110,1,1,4
9,4,110,1,10,
9,4,111,4,0,
9,4,127,4,0,
9,4,130,1,55,
9,4,145,1,1,3
9,4,145,1,7,
9,4,156,4,0,
9,4,173,4,0,
9,4,174,4,0,
9,4,182,1,31,
9,4,182,4,0,
9,4,189,4,0,
9,4,196,4,0,
9,4,203,4,0,
9,4,205,4,0,
9,4,207,4,0,
9,4,213,4,0,
9,4,214,4,0,
9,4,216,4,0,
9,4,218,4,0,
9,4,223,4,0,
9,4,229,1,25,
9,4,231,4,0,
9,4,237,4,0,
9,4,240,1,42,
9,4,240,4,0,
9,4,249,4,0,
9,4,250,4,0,
9,5,33,1,1,1
9,5,39,1,1,2
9,5,39,1,4,
9,5,44,1,19,
9,5,46,4,0,
9,5,55,1,13,
9,5,56,1,68,
9,5,57,4,0,
9,5,58,4,0,
9,5,59,4,0,
9,5,63,4,0,
9,5,70,4,0,
9,5,89,4,0,
9,5,91,4,0,
9,5,92,4,0,
9,5,104,4,0,
9,5,110,1,1,4
9,5,110,1,10,
9,5,127,4,0,
9,5,130,1,55,
9,5,145,1,1,3
9,5,145,1,7,
9,5,156,4,0,
9,5,182,1,31,
9,5,182,4,0,
9,5,213,4,0,
9,5,216,4,0,
9,5,218,4,0,
9,5,229,1,25,
9,5,231,4,0,
9,5,237,4,0,
9,5,240,1,42,
9,5,240,4,0,
9,5,249,4,0,
9,5,258,4,0,
9,5,263,4,0,
9,5,264,4,0,
9,5,280,4,0,
9,5,290,4,0,
9,5,291,4,0,
9,5,352,4,0,
9,6,5,3,0,
9,6,8,3,0,
9,6,25,3,0,
9,6,33,1,1,1
9,6,34,3,0,
9,6,38,3,0,
9,6,39,1,1,2
9,6,39,1,4,
9,6,44,1,19,
9,6,46,4,0,
9,6,55,1,13,
9,6,56,1,68,
9,6,57,4,0,
9,6,58,4,0,
9,6,59,4,0,
9,6,63,4,0,
9,6,68,3,0,
9,6,69,3,0,
9,6,70,4,0,
9,6,89,4,0,
9,6,91,4,0,
9,6,92,4,0,
9,6,102,3,0,
9,6,104,4,0,
9,6,110,1,1,4
9,6,110,1,10,
9,6,111,3,0,
9,6,127,4,0,
9,6,130,1,55,
9,6,145,1,1,3
9,6,145,1,7,
9,6,156,4,0,
9,6,164,3,0,
9,6,173,3,0,
9,6,182,1,31,
9,6,182,4,0,
9,6,189,3,0,
9,6,196,3,0,
9,6,203,3,0,
9,6,205,3,0,
9,6,207,3,0,
9,6,213,4,0,
9,6,214,3,0,
9,6,216,4,0,
9,6,218,4,0,
9,6,223,3,0,
9,6,229,1,25,
9,6,231,4,0,
9,6,237,4,0,
9,6,240,1,42,
9,6,240,4,0,
9,6,249,4,0,
9,6,258,4,0,
9,6,263,4,0,
9,6,264,4,0,
9,6,280,4,0,
9,6,290,4,0,
9,6,291,4,0,
9,6,352,4,0,
9,7,5,3,0,
9,7,25,3,0,
9,7,33,1,1,1
9,7,34,3,0,
9,7,38,3,0,
9,7,39,1,1,2
9,7,39,1,4,
9,7,44,1,19,
9,7,46,4,0,
9,7,55,1,13,
9,7,56,1,68,
9,7,57,4,0,
9,7,58,4,0,
9,7,59,4,0,
9,7,63,4,0,
9,7,68,3,0,
9,7,69,3,0,
9,7,70,4,0,
9,7,89,4,0,
9,7,91,4,0,
9,7,92,4,0,
9,7,102,3,0,
9,7,104,4,0,
9,7,110,1,1,4
9,7,110,1,10,
9,7,127,4,0,
9,7,130,1,55,
9,7,145,1,1,3
9,7,145,1,7,
9,7,156,4,0,
9,7,164,3,0,
9,7,182,1,31,
9,7,182,4,0,
9,7,213,4,0,
9,7,216,4,0,
9,7,218,4,0,
9,7,229,1,25,
9,7,231,4,0,
9,7,237,4,0,
9,7,240,1,42,
9,7,240,4,0,
9,7,249,4,0,
9,7,258,4,0,
9,7,263,4,0,
9,7,264,4,0,
9,7,280,4,0,
9,7,290,4,0,
9,7,291,4,0,
9,7,308,3,0,
9,7,352,4,0,
9,8,33,1,1,2
9,8,39,1,1,3
9,8,39,1,4,
9,8,44,1,16,
9,8,46,4,0,
9,8,55,1,13,
9,8,56,1,53,
9,8,57,4,0,
9,8,58,4,0,
9,8,59,4,0,
9,8,63,4,0,
9,8,70,4,0,
9,8,89,4,0,
9,8,91,4,0,
9,8,92,4,0,
9,8,104,4,0,
9,8,110,1,1,5
9,8,110,1,10,
9,8,127,4,0,
9,8,130,1,39,
9,8,145,1,1,4
9,8,145,1,7,
9,8,156,4,0,
9,8,157,4,0,
9,8,164,4,0,
9,8,182,1,24,
9,8,182,4,0,
9,8,203,4,0,
9,8,207,4,0,
9,8,213,4,0,
9,8,214,4,0,
9,8,216,4,0,
9,8,218,4,0,
9,8,229,1,20,
9,8,231,4,0,
9,8,237,4,0,
9,8,240,1,46,
9,8,240,4,0,
9,8,249,4,0,
9,8,258,4,0,
9,8,263,4,0,
9,8,264,4,0,
9,8,280,4,0,
9,8,290,4,0,
9,8,308,3,0,
9,8,317,4,0,
9,8,352,1,28,
9,8,352,4,0,
9,8,360,4,0,
9,8,362,4,0,
9,8,363,4,0,
9,8,374,4,0,
9,8,401,1,32,
9,8,411,4,0,
9,8,416,4,0,
9,8,419,4,0,
9,8,430,1,1,1
9,8,430,4,0,
9,8,431,4,0,
9,8,445,4,0,
9,9,8,3,0,
9,9,33,1,1,2
9,9,39,1,1,3
9,9,39,1,4,
9,9,44,1,16,
9,9,46,4,0,
9,9,55,1,13,
9,9,56,1,60,
9,9,57,4,0,
9,9,58,4,0,
9,9,59,4,0,
9,9,63,4,0,
9,9,70,4,0,
9,9,89,4,0,
9,9,91,4,0,
9,9,92,4,0,
9,9,104,4,0,
9,9,110,1,1,5
9,9,110,1,10,
9,9,127,4,0,
9,9,130,1,39,
9,9,145,1,1,4
9,9,145,1,7,
9,9,156,4,0,
9,9,157,4,0,
9,9,164,4,0,
9,9,173,3,0,
9,9,182,1,24,
9,9,182,4,0,
9,9,189,3,0,
9,9,196,3,0,
9,9,200,3,0,
9,9,203,4,0,
9,9,205,3,0,
9,9,207,4,0,
9,9,213,4,0,
9,9,214,4,0,
9,9,216,4,0,
9,9,218,4,0,
9,9,229,1,20,
9,9,231,4,0,
9,9,237,4,0,
9,9,240,1,53,
9,9,240,4,0,
9,9,249,4,0,
9,9,258,4,0,
9,9,263,4,0,
9,9,264,4,0,
9,9,280,4,0,
9,9,290,4,0,
9,9,291,3,0,
9,9,308,3,0,
9,9,317,4,0,
9,9,324,3,0,
9,9,334,1,46,
9,9,334,3,0,
9,9,352,1,28,
9,9,352,4,0,
9,9,360,4,0,
9,9,362,4,0,
9,9,363,4,0,
9,9,374,4,0,
9,9,401,1,32,
9,9,401,3,0,
9,9,411,4,0,
9,9,416,4,0,
9,9,419,4,0,
9,9,428,3,0,
9,9,430,1,1,1
9,9,430,4,0,
9,9,431,4,0,
9,9,445,4,0,
9,10,8,3,0,
9,10,29,3,0,
9,10,33,1,1,2
9,10,39,1,1,3
9,10,39,1,4,
9,10,44,1,16,
9,10,46,4,0,
9,10,55,1,13,
9,10,56,1,60,
9,10,57,4,0,
9,10,58,4,0,
9,10,59,4,0,
9,10,63,4,0,
9,10,70,4,0,
9,10,89,4,0,
9,10,91,4,0,
9,10,92,4,0,
9,10,104,4,0,
9,10,110,1,1,5
9,10,110,1,10,
9,10,127,4,0,
9,10,130,1,39,
9,10,145,1,1,4
9,10,145,1,7,
9,10,156,4,0,
9,10,157,4,0,
9,10,164,4,0,
9,10,173,3,0,
9,10,182,1,24,
9,10,182,4,0,
9,10,189,3,0,
9,10,196,3,0,
9,10,200,3,0,
9,10,203,4,0,
9,10,205,3,0,
9,10,207,4,0,
9,10,213,4,0,
9,10,214,4,0,
9,10,216,4,0,
9,10,218,4,0,
9,10,229,1,20,
9,10,231,4,0,
9,10,237,4,0,
9,10,240,1,53,
9,10,240,4,0,
9,10,249,4,0,
9,10,250,4,0,
9,10,258,4,0,
9,10,263,4,0,
9,10,264,4,0,
9,10,280,4,0,
9,10,290,4,0,
9,10,291,3,0,
9,10,308,3,0,
9,10,317,4,0,
9,10,324,3,0,
9,10,334,1,46,
9,10,334,3,0,
9,10,352,1,28,
9,10,352,4,0,
9,10,360,4,0,
9,10,362,4,0,
9,10,363,4,0,
9,10,374,4,0,
9,10,401,1,32,
9,10,401,3,0,
9,10,411,4,0,
9,10,416,4,0,
9,10,419,4,0,
9,10,428,3,0,
9,10,430,1,1,1
9,10,430,4,0,
9,10,431,4,0,
9,10,445,4,0,
9,11,33,1,1,2
9,11,39,1,1,3
9,11,39,1,4,
9,11,44,1,16,
9,11,46,4,0,
9,11,55,1,13,
9,11,56,1,60,
9,11,57,4,0,
9,11,58,4,0,
9,11,59,4,0,
9,11,63,4,0,
9,11,70,4,0,
9,11,89,4,0,
9,11,91,4,0,
9,11,92,4,0,
9,11,104,4,0,
9,11,110,1,1,5
9,11,110,1,10,
9,11,127,4,0,
9,11,130,1,39,
9,11,145,1,1,4
9,11,145,1,7,
9,11,156,4,0,
9,11,157,4,0,
9,11,164,4,0,
9,11,182,1,24,
9,11,182,4,0,
9,11,207,4,0,
9,11,213,4,0,
9,11,216,4,0,
9,11,218,4,0,
9,11,229,1,20,
9,11,237,4,0,
9,11,240,1,53,
9,11,240,4,0,
9,11,249,4,0,
9,11,258,4,0,
9,11,263,4,0,
9,11,280,4,0,
9,11,291,4,0,
9,11,308,3,0,
9,11,317,4,0,
9,11,334,1,46,
9,11,352,1,28,
9,11,360,4,0,
9,11,374,4,0,
9,11,401,1,32,
9,11,411,4,0,
9,11,416,4,0,
9,11,430,1,1,1
9,11,430,4,0,
9,11,479,4,0,
9,11,496,4,0,
9,11,503,4,0,
9,11,518,3,0,
9,11,523,4,0,
9,11,525,4,0,
9,12,33,1,1,1
9,12,39,1,1,2
9,12,39,1,4,
9,12,44,1,19,
9,12,46,4,0,
9,12,55,1,13,
9,12,56,1,68,
9,12,57,4,0,
9,12,58,4,0,
9,12,59,4,0,
9,12,63,4,0,
9,12,70,4,0,
9,12,89,4,0,
9,12,91,4,0,
9,12,92,4,0,
9,12,104,4,0,
9,12,110,1,1,4
9,12,110,1,10,
9,12,127,4,0,
9,12,130,1,55,
9,12,145,1,1,3
9,12,145,1,7,
9,12,156,4,0,
9,12,182,1,31,
9,12,182,4,0,
9,12,213,4,0,
9,12,216,4,0,
9,12,218,4,0,
9,12,229,1,25,
9,12,231,4,0,
9,12,237,4,0,
9,12,240,1,42,
9,12,240,4,0,
9,12,249,4,0,
9,12,258,4,0,
9,12,263,4,0,
9,12,264,4,0,
9,12,280,4,0,
9,12,290,4,0,
9,12,291,4,0,
9,12,352,4,0,
9,13,33,1,1,1
9,13,34,3,0,
9,13,38,3,0,
9,13,39,1,1,2
9,13,39,1,4,
9,13,44,1,19,
9,13,46,4,0,
9,13,55,1,13,
9,13,56,1,68,
9,13,57,4,0,
9,13,58,4,0,
9,13,59,4,0,
9,13,63,4,0,
9,13,69,3,0,
9,13,70,4,0,
9,13,89,4,0,
9,13,91,4,0,
9,13,92,4,0,
9,13,102,3,0,
9,13,104,4,0,
9,13,110,1,1,4
9,13,110,1,10,
9,13,127,4,0,
9,13,130,1,55,
9,13,145,1,1,3
9,13,145,1,7,
9,13,156,4,0,
9,13,164,3,0,
9,13,182,1,31,
9,13,182,4,0,
9,13,196,3,0,
9,13,207,3,0,
9,13,213,4,0,
9,13,216,4,0,
9,13,218,4,0,
9,13,229,1,25,
9,13,231,4,0,
9,13,237,4,0,
9,13,240,1,42,
9,13,240,4,0,
9,13,249,4,0,
9,13,258,4,0,
9,13,263,4,0,
9,13,264,4,0,
9,13,280,4,0,
9,13,290,4,0,
9,13,291,4,0,
9,13,352,4,0,
9,14,8,3,0,
9,14,33,1,1,2
9,14,39,1,1,3
9,14,39,1,4,
9,14,44,1,16,
9,14,46,4,0,
9,14,55,1,13,
9,14,56,1,60,
9,14,57,4,0,
9,14,58,4,0,
9,14,59,4,0,
9,14,63,4,0,
9,14,70,4,0,
9,14,89,4,0,
9,14,91,4,0,
9,14,92,4,0,
9,14,104,4,0,
9,14,110,1,1,5
9,14,110,1,10,
9,14,127,4,0,
9,14,130,1,39,
9,14,145,1,1,4
9,14,145,1,7,
9,14,156,4,0,
9,14,157,4,0,
9,14,164,4,0,
9,14,173,3,0,
9,14,182,1,24,
9,14,182,4,0,
9,14,196,3,0,
9,14,200,3,0,
9,14,207,4,0,
9,14,213,4,0,
9,14,214,3,0,
9,14,216,4,0,
9,14,218,4,0,
9,14,229,1,20,
9,14,231,3,0,
9,14,237,4,0,
9,14,240,1,53,
9,14,240,4,0,
9,14,249,4,0,
9,14,258,4,0,
9,14,263,4,0,
9,14,280,4,0,
9,14,291,4,0,
9,14,308,3,0,
9,14,317,4,0,
9,14,324,3,0,
9,14,334,1,46,
9,14,334,3,0,
9,14,352,1,28,
9,14,360,4,0,
9,14,374,4,0,
9,14,401,1,32,
9,14,401,3,0,
9,14,411,4,0,
9,14,416,4,0,
9,14,428,3,0,
9,14,430,1,1,1
9,14,430,4,0,
9,14,479,4,0,
9,14,496,4,0,
9,14,503,4,0,
9,14,518,3,0,
9,14,523,4,0,
9,14,525,4,0,
9,15,33,1,1,2
9,15,39,1,1,3
9,15,39,1,4,
9,15,44,1,16,
9,15,46,4,0,
9,15,55,1,1,4
9,15,55,1,7,
9,15,56,1,60,
9,15,57,4,0,
9,15,58,4,0,
9,15,59,4,0,
9,15,63,4,0,
9,15,70,4,0,
9,15,89,4,0,
9,15,91,4,0,
9,15,92,4,0,
9,15,104,4,0,
9,15,110,1,1,5
9,15,110,1,10,
9,15,127,4,0,
9,15,130,1,39,
9,15,145,1,13,
9,15,156,4,0,
9,15,157,4,0,
9,15,164,4,0,
9,15,182,1,24,
9,15,182,4,0,
9,15,207,4,0,
9,15,213,4,0,
9,15,214,4,0,
9,15,216,4,0,
9,15,218,4,0,
9,15,229,1,20,
9,15,237,4,0,
9,15,240,1,53,
9,15,240,4,0,
9,15,249,4,0,
9,15,258,4,0,
9,15,263,4,0,
9,15,280,4,0,
9,15,308,3,0,
9,15,317,4,0,
9,15,334,1,46,
9,15,352,1,28,
9,15,360,4,0,
9,15,374,4,0,
9,15,399,4,0,
9,15,401,1,32,
9,15,411,4,0,
9,15,416,4,0,
9,15,430,1,1,1
9,15,430,4,0,
9,15,479,4,0,
9,15,496,4,0,
9,15,503,4,0,
9,15,518,3,0,
9,15,523,4,0,
9,15,525,4,0,
9,15,590,4,0,
9,15,612,4,0,
9,16,8,3,0,
9,16,33,1,1,2
9,16,39,1,1,3
9,16,39,1,4,1
9,16,44,1,16,1
9,16,46,4,0,
9,16,55,1,1,4
9,16,55,1,7,1
9,16,56,1,60,1
9,16,57,4,0,
9,16,58,4,0,
9,16,59,4,0,
9,16,63,4,0,
9,16,70,4,0,
9,16,89,4,0,
9,16,91,4,0,
9,16,92,4,0,
9,16,104,4,0,
9,16,110,1,1,5
9,16,110,1,10,1
9,16,127,4,0,
9,16,130,1,39,1
9,16,145,1,13,1
9,16,156,4,0,
9,16,157,4,0,
9,16,164,4,0,
9,16,173,3,0,
9,16,182,1,24,1
9,16,182,4,0,
9,16,196,3,0,
9,16,200,3,0,
9,16,207,4,0,
9,16,213,4,0,
9,16,214,4,0,
9,16,216,4,0,
9,16,218,4,0,
9,16,229,1,20,1
9,16,231,3,0,
9,16,237,4,0,
9,16,240,1,53,1
9,16,240,4,0,
9,16,249,4,0,
9,16,258,4,0,
9,16,263,4,0,
9,16,264,3,0,
9,16,280,4,0,
9,16,290,4,0,
9,16,291,4,0,
9,16,308,3,0,
9,16,317,4,0,
9,16,324,3,0,
9,16,334,1,46,1
9,16,334,3,0,
9,16,352,1,28,1
9,16,352,3,0,
9,16,360,4,0,
9,16,374,4,0,
9,16,399,4,0,
9,16,401,1,32,1
9,16,401,3,0,
9,16,406,3,0,
9,16,411,4,0,
9,16,416,4,0,
9,16,428,3,0,
9,16,430,1,1,1
9,16,430,4,0,
9,16,479,4,0,
9,16,496,4,0,
9,16,503,4,0,
9,16,518,3,0,
9,16,523,4,0,
9,16,525,4,0,
9,16,590,4,0,
9,16,612,4,0,
9,17,33,1,1,2
9,17,39,1,1,3
9,17,39,1,4,
9,17,44,1,17,
9,17,46,4,0,
9,17,55,1,1,4
9,17,55,1,7,
9,17,56,1,60,
9,17,57,4,0,
9,17,58,4,0,
9,17,59,4,0,
9,17,63,4,0,
9,17,89,4,0,
9,17,92,4,0,
9,17,104,4,0,
9,17,110,1,1,5
9,17,110,1,10,
9,17,127,4,0,
9,17,130,1,40,
9,17,145,1,13,
9,17,156,4,0,
9,17,157,4,0,
9,17,164,4,0,
9,17,182,1,25,
9,17,182,4,0,
9,17,207,4,0,
9,17,213,4,0,
9,17,214,4,0,
9,17,216,4,0,
9,17,218,4,0,
9,17,229,1,21,
9,17,237,4,0,
9,17,240,1,54,
9,17,240,4,0,
9,17,258,4,0,
9,17,263,4,0,
9,17,280,4,0,
9,17,308,3,0,
9,17,317,4,0,
9,17,334,1,47,
9,17,352,1,29,
9,17,360,4,0,
9,17,374,4,0,
9,17,399,4,0,
9,17,401,1,33,
9,17,411,4,0,
9,17,416,4,0,
9,17,430,1,1,1
9,17,430,4,0,
9,17,479,4,0,
9,17,496,4,0,
9,17,503,4,0,
9,17,518,3,0,
9,17,523,4,0,
9,17,525,4,0,
9,17,526,4,0,
9,17,590,4,0,
9,18,33,1,1,2
9,18,39,1,1,3
9,18,39,1,4,
9,18,44,1,17,
9,18,46,4,0,
9,18,55,1,1,4
9,18,55,1,7,
9,18,56,1,60,
9,18,57,4,0,
9,18,58,4,0,
9,18,59,4,0,
9,18,63,4,0,
9,18,89,4,0,
9,18,92,4,0,
9,18,104,4,0,
9,18,110,1,1,5
9,18,110,1,10,
9,18,127,4,0,
9,18,130,1,40,
9,18,145,1,13,
9,18,156,4,0,
9,18,157,4,0,
9,18,164,4,0,
9,18,182,1,25,
9,18,182,4,0,
9,18,207,4,0,
9,18,213,4,0,
9,18,214,4,0,
9,18,216,4,0,
9,18,218,4,0,
9,18,229,1,21,
9,18,237,4,0,
9,18,240,1,54,
9,18,240,4,0,
9,18,258,4,0,
9,18,263,4,0,
9,18,280,4,0,
9,18,308,3,0,
9,18,317,4,0,
9,18,334,1,47,
9,18,352,1,29,
9,18,360,4,0,
9,18,374,4,0,
9,18,399,4,0,
9,18,401,1,33,
9,18,411,4,0,
9,18,416,4,0,
9,18,430,1,1,1
9,18,430,4,0,
9,18,479,4,0,
9,18,496,4,0,
9,18,503,4,0,
9,18,518,3,0,
9,18,523,4,0,
9,18,525,4,0,
9,18,526,4,0,
9,18,590,4,0,
10,1,33,1,1,1
10,1,81,1,1,2
10,2,33,1,1,1
10,2,81,1,1,2
10,3,33,1,1,1
10,3,81,1,1,2
10,4,33,1,1,1
10,4,81,1,1,2
10,5,33,1,1,1
10,5,81,1,1,2
10,6,33,1,1,1
10,6,81,1,1,2
10,7,33,1,1,1
10,7,81,1,1,2
10,8,33,1,1,1
10,8,81,1,1,2
10,9,33,1,1,1
10,9,81,1,1,2
10,9,173,3,0,
10,9,450,1,15,
10,10,33,1,1,1
10,10,81,1,1,2
10,10,81,3,0,
10,10,173,3,0,
10,10,450,1,15,
10,10,450,3,0,
10,11,33,1,1,1
10,11,81,1,1,2
10,11,450,1,15,
10,12,33,1,1,1
10,12,81,1,1,2
10,13,33,1,1,1
10,13,81,1,1,2
10,14,33,1,1,1
10,14,81,1,1,2
10,14,173,3,0,
10,14,450,1,15,
10,14,450,3,0,
10,14,527,3,0,
10,15,33,1,1,1
10,15,81,1,1,2
10,15,450,1,15,
10,16,33,1,1,1
10,16,81,1,1,2
10,16,173,3,0,
10,16,450,1,15,1
10,16,450,3,0,
10,16,527,3,0,
10,17,33,1,1,1
10,17,81,1,1,2
10,17,450,1,9,
10,18,33,1,1,1
10,18,81,1,1,2
10,18,450,1,9,
11,1,106,1,1,
11,2,106,1,1,
11,2,106,1,7,
11,3,106,1,1,
11,3,106,1,7,
11,4,106,1,1,
11,4,106,1,7,
11,5,106,1,1,
11,5,106,1,7,
11,6,106,1,1,
11,6,106,1,7,
11,7,106,1,1,
11,7,106,1,7,
11,8,106,1,1,
11,8,106,1,7,
11,9,106,1,1,
11,9,106,1,7,
11,9,334,3,0,
11,10,81,3,0,
11,10,106,1,1,
11,10,106,1,7,
11,10,334,3,0,
11,10,450,3,0,
11,11,106,1,1,
11,11,106,1,7,
11,12,106,1,1,
11,12,106,1,7,
11,13,106,1,1,
11,13,106,1,7,
11,14,106,1,1,
11,14,106,1,7,
11,14,334,3,0,
11,14,450,3,0,
11,14,527,3,0,
11,15,106,1,1,
11,15,106,1,7,
11,16,106,1,1,1
11,16,106,1,7,1
11,16,334,3,0,
11,16,450,3,0,
11,16,527,3,0,
11,17,106,1,0,
11,17,106,1,1,
11,18,106,1,0,
11,18,106,1,1,
12,1,13,4,0,
12,1,18,1,26,
12,1,18,4,0,
12,1,36,4,0,
12,1,38,4,0,
12,1,48,1,21,
12,1,60,1,32,
12,1,63,4,0,
12,1,72,4,0,
12,1,76,4,0,
12,1,77,1,15,
12,1,78,1,16,
12,1,79,1,17,
12,1,92,4,0,
12,1,93,1,1,
12,1,93,1,12,
12,1,94,4,0,
12,1,99,4,0,
12,1,100,4,0,
12,1,102,4,0,
12,1,104,4,0,
12,1,115,4,0,
12,1,117,4,0,
12,1,129,4,0,
12,1,149,4,0,
12,1,156,4,0,
12,1,164,4,0,
12,2,13,4,0,
12,2,16,1,28,
12,2,18,1,23,
12,2,18,4,0,
12,2,36,4,0,
12,2,38,4,0,
12,2,48,1,18,
12,2,60,1,34,
12,2,63,4,0,
12,2,72,4,0,
12,2,76,4,0,
12,2,77,1,13,
12,2,78,1,14,
12,2,79,1,15,
12,2,92,4,0,
12,2,93,1,1,
12,2,93,1,10,
12,2,94,4,0,
12,2,99,4,0,
12,2,100,4,0,
12,2,102,4,0,
12,2,104,4,0,
12,2,115,4,0,
12,2,117,4,0,
12,2,129,4,0,
12,2,148,4,0,
12,2,149,4,0,
12,2,156,4,0,
12,2,164,4,0,
12,3,16,1,28,
12,3,18,1,23,
12,3,48,1,18,
12,3,60,1,34,
12,3,63,4,0,
12,3,76,4,0,
12,3,77,1,13,
12,3,78,1,14,
12,3,79,1,15,
12,3,92,4,0,
12,3,93,1,1,
12,3,93,1,10,
12,3,94,4,0,
12,3,104,4,0,
12,3,129,4,0,
12,3,148,4,0,
12,3,156,4,0,
12,3,171,4,0,
12,3,173,4,0,
12,3,174,4,0,
12,3,182,4,0,
12,3,202,4,0,
12,3,203,4,0,
12,3,207,4,0,
12,3,213,4,0,
12,3,214,4,0,
12,3,216,4,0,
12,3,218,4,0,
12,3,219,1,40,
12,3,230,4,0,
12,3,237,4,0,
12,3,241,4,0,
12,4,16,1,28,
12,4,18,1,23,
12,4,48,1,18,
12,4,60,1,34,
12,4,63,4,0,
12,4,76,4,0,
12,4,77,1,13,
12,4,78,1,14,
12,4,79,1,15,
12,4,92,4,0,
12,4,93,1,1,
12,4,93,1,10,
12,4,94,4,0,
12,4,104,4,0,
12,4,129,4,0,
12,4,148,4,0,
12,4,156,4,0,
12,4,171,4,0,
12,4,173,4,0,
12,4,174,4,0,
12,4,182,4,0,
12,4,202,4,0,
12,4,203,4,0,
12,4,207,4,0,
12,4,213,4,0,
12,4,214,4,0,
12,4,216,4,0,
12,4,218,4,0,
12,4,219,1,40,
12,4,230,4,0,
12,4,237,4,0,
12,4,241,4,0,
12,5,16,1,28,
12,5,18,1,23,
12,5,48,1,18,
12,5,60,1,34,
12,5,63,4,0,
12,5,76,4,0,
12,5,77,1,13,
12,5,78,1,14,
12,5,79,1,15,
12,5,92,4,0,
12,5,93,1,1,
12,5,93,1,10,
12,5,94,4,0,
12,5,104,4,0,
12,5,148,4,0,
12,5,156,4,0,
12,5,168,4,0,
12,5,182,4,0,
12,5,202,4,0,
12,5,213,4,0,
12,5,216,4,0,
12,5,218,4,0,
12,5,219,1,40,
12,5,219,4,0,
12,5,237,4,0,
12,5,240,4,0,
12,5,241,4,0,
12,5,247,4,0,
12,5,263,4,0,
12,5,285,4,0,
12,5,290,4,0,
12,5,318,1,47,
12,5,332,4,0,
12,6,16,1,28,
12,6,18,1,23,
12,6,38,3,0,
12,6,48,1,18,
12,6,60,1,34,
12,6,63,4,0,
12,6,76,4,0,
12,6,77,1,13,
12,6,78,1,14,
12,6,79,1,15,
12,6,92,4,0,
12,6,93,1,1,
12,6,93,1,10,
12,6,94,4,0,
12,6,102,3,0,
12,6,104,4,0,
12,6,129,3,0,
12,6,138,3,0,
12,6,148,4,0,
12,6,156,4,0,
12,6,164,3,0,
12,6,168,4,0,
12,6,173,3,0,
12,6,182,4,0,
12,6,202,4,0,
12,6,203,3,0,
12,6,207,3,0,
12,6,213,4,0,
12,6,214,3,0,
12,6,216,4,0,
12,6,218,4,0,
12,6,219,1,40,
12,6,219,4,0,
12,6,237,4,0,
12,6,240,4,0,
12,6,241,4,0,
12,6,247,4,0,
12,6,263,4,0,
12,6,285,4,0,
12,6,290,4,0,
12,6,318,1,47,
12,6,332,4,0,
12,7,16,1,28,
12,7,18,1,23,
12,7,38,3,0,
12,7,48,1,18,
12,7,60,1,34,
12,7,63,4,0,
12,7,76,4,0,
12,7,77,1,13,
12,7,78,1,14,
12,7,79,1,15,
12,7,92,4,0,
12,7,93,1,1,
12,7,93,1,10,
12,7,94,4,0,
12,7,102,3,0,
12,7,104,4,0,
12,7,138,3,0,
12,7,148,4,0,
12,7,156,4,0,
12,7,164,3,0,
12,7,168,4,0,
12,7,182,4,0,
12,7,202,4,0,
12,7,213,4,0,
12,7,216,4,0,
12,7,218,4,0,
12,7,219,1,40,
12,7,219,4,0,
12,7,237,4,0,
12,7,240,4,0,
12,7,241,4,0,
12,7,247,4,0,
12,7,263,4,0,
12,7,285,4,0,
12,7,290,4,0,
12,7,318,1,47,
12,7,332,4,0,
12,8,16,1,16,
12,8,18,1,22,
12,8,48,1,18,
12,8,60,1,24,
12,8,63,4,0,
12,8,76,4,0,
12,8,77,1,12,1
12,8,78,1,12,2
12,8,79,1,12,3
12,8,92,4,0,
12,8,93,1,1,
12,8,93,1,10,
12,8,94,4,0,
12,8,104,4,0,
12,8,138,4,0,
12,8,148,4,0,
12,8,156,4,0,
12,8,164,4,0,
12,8,168,4,0,
12,8,182,4,0,
12,8,202,4,0,
12,8,203,4,0,
12,8,207,4,0,
12,8,213,4,0,
12,8,214,4,0,
12,8,216,4,0,
12,8,218,4,0,
12,8,219,1,34,
12,8,219,4,0,
12,8,237,4,0,
12,8,240,4,0,
12,8,241,4,0,
12,8,244,4,0,
12,8,247,4,0,
12,8,263,4,0,
12,8,285,4,0,
12,8,290,4,0,
12,8,318,1,28,
12,8,318,4,0,
12,8,332,4,0,
12,8,355,4,0,
12,8,363,4,0,
12,8,366,1,30,
12,8,369,4,0,
12,8,405,1,40,
12,8,412,4,0,
12,8,416,4,0,
12,8,432,4,0,
12,8,445,1,36,
12,8,445,4,0,
12,9,16,1,16,
12,9,18,1,22,
12,9,48,1,18,
12,9,60,1,24,
12,9,63,4,0,
12,9,76,4,0,
12,9,77,1,12,1
12,9,78,1,12,2
12,9,79,1,12,3
12,9,92,4,0,
12,9,93,1,1,
12,9,93,1,10,
12,9,94,4,0,
12,9,104,4,0,
12,9,129,3,0,
12,9,138,4,0,
12,9,148,4,0,
12,9,156,4,0,
12,9,164,4,0,
12,9,168,4,0,
12,9,173,3,0,
12,9,182,4,0,
12,9,202,4,0,
12,9,203,4,0,
12,9,207,4,0,
12,9,213,4,0,
12,9,214,4,0,
12,9,216,4,0,
12,9,218,4,0,
12,9,219,1,34,
12,9,219,4,0,
12,9,237,4,0,
12,9,239,3,0,
12,9,240,4,0,
12,9,241,4,0,
12,9,244,4,0,
12,9,247,4,0,
12,9,263,4,0,
12,9,285,4,0,
12,9,290,4,0,
12,9,314,3,0,
12,9,318,1,28,
12,9,318,4,0,
12,9,324,3,0,
12,9,332,4,0,
12,9,355,4,0,
12,9,363,4,0,
12,9,366,1,30,
12,9,369,4,0,
12,9,405,1,40,
12,9,412,4,0,
12,9,416,4,0,
12,9,432,4,0,
12,9,445,1,36,
12,9,445,4,0,
12,9,466,3,0,
12,10,16,1,16,
12,10,18,1,22,
12,10,48,1,18,
12,10,60,1,24,
12,10,63,4,0,
12,10,76,4,0,
12,10,77,1,12,1
12,10,78,1,12,2
12,10,79,1,12,3
12,10,81,3,0,
12,10,92,4,0,
12,10,93,1,1,
12,10,93,1,10,
12,10,94,4,0,
12,10,104,4,0,
12,10,129,3,0,
12,10,138,4,0,
12,10,148,4,0,
12,10,156,4,0,
12,10,164,4,0,
12,10,168,4,0,
12,10,173,3,0,
12,10,182,4,0,
12,10,202,4,0,
12,10,203,4,0,
12,10,207,4,0,
12,10,213,4,0,
12,10,214,4,0,
12,10,216,4,0,
12,10,218,4,0,
12,10,219,1,34,
12,10,219,4,0,
12,10,237,4,0,
12,10,239,3,0,
12,10,240,4,0,
12,10,241,4,0,
12,10,244,4,0,
12,10,247,4,0,
12,10,263,4,0,
12,10,285,4,0,
12,10,290,4,0,
12,10,314,3,0,
12,10,318,1,28,
12,10,318,4,0,
12,10,324,3,0,
12,10,332,4,0,
12,10,355,4,0,
12,10,363,4,0,
12,10,366,1,30,
12,10,366,3,0,
12,10,369,4,0,
12,10,405,1,40,
12,10,412,4,0,
12,10,416,4,0,
12,10,445,1,36,
12,10,445,4,0,
12,10,450,3,0,
12,10,466,3,0,
12,11,16,1,16,
12,11,18,1,22,
12,11,48,1,18,
12,11,60,1,24,
12,11,63,4,0,
12,11,76,4,0,
12,11,77,1,12,1
12,11,78,1,12,2
12,11,79,1,12,3
12,11,92,4,0,
12,11,93,1,1,
12,11,93,1,10,
12,11,94,4,0,
12,11,104,4,0,
12,11,138,4,0,
12,11,148,4,0,
12,11,156,4,0,
12,11,164,4,0,
12,11,168,4,0,
12,11,182,4,0,
12,11,207,4,0,
12,11,213,4,0,
12,11,216,4,0,
12,11,218,4,0,
12,11,219,1,36,
12,11,219,4,0,
12,11,237,4,0,
12,11,240,4,0,
12,11,241,4,0,
12,11,244,4,0,
12,11,247,4,0,
12,11,263,4,0,
12,11,318,1,28,
12,11,332,4,0,
12,11,366,1,30,
12,11,369,4,0,
12,11,405,1,42,
12,11,412,4,0,
12,11,416,4,0,
12,11,445,1,40,
12,11,474,4,0,
12,11,476,1,34,
12,11,483,1,46,
12,11,496,4,0,
12,11,512,4,0,
12,11,522,4,0,
12,12,16,1,28,
12,12,18,1,23,
12,12,48,1,18,
12,12,60,1,34,
12,12,63,4,0,
12,12,76,4,0,
12,12,77,1,13,
12,12,78,1,14,
12,12,79,1,15,
12,12,92,4,0,
12,12,93,1,1,
12,12,93,1,10,
12,12,94,4,0,
12,12,104,4,0,
12,12,148,4,0,
12,12,156,4,0,
12,12,168,4,0,
12,12,182,4,0,
12,12,202,4,0,
12,12,213,4,0,
12,12,216,4,0,
12,12,218,4,0,
12,12,219,1,40,
12,12,219,4,0,
12,12,237,4,0,
12,12,240,4,0,
12,12,241,4,0,
12,12,247,4,0,
12,12,263,4,0,
12,12,285,4,0,
12,12,290,4,0,
12,12,318,1,47,
12,12,332,4,0,
12,13,16,1,28,
12,13,18,1,23,
12,13,38,3,0,
12,13,48,1,18,
12,13,60,1,34,
12,13,63,4,0,
12,13,76,4,0,
12,13,77,1,13,
12,13,78,1,14,
12,13,79,1,15,
12,13,92,4,0,
12,13,93,1,1,
12,13,93,1,10,
12,13,94,4,0,
12,13,102,3,0,
12,13,104,4,0,
12,13,138,3,0,
12,13,148,4,0,
12,13,156,4,0,
12,13,164,3,0,
12,13,168,4,0,
12,13,171,3,0,
12,13,182,4,0,
12,13,202,4,0,
12,13,207,3,0,
12,13,213,4,0,
12,13,216,4,0,
12,13,218,4,0,
12,13,219,1,40,
12,13,219,4,0,
12,13,237,4,0,
12,13,240,4,0,
12,13,241,4,0,
12,13,247,4,0,
12,13,263,4,0,
12,13,285,4,0,
12,13,290,4,0,
12,13,318,1,47,
12,13,332,4,0,
12,14,16,1,16,
12,14,18,1,22,
12,14,48,1,18,
12,14,60,1,24,
12,14,63,4,0,
12,14,76,4,0,
12,14,77,1,12,1
12,14,78,1,12,2
12,14,79,1,12,3
12,14,92,4,0,
12,14,93,1,1,
12,14,93,1,10,
12,14,94,4,0,
12,14,104,4,0,
12,14,138,4,0,
12,14,148,4,0,
12,14,156,4,0,
12,14,164,4,0,
12,14,168,4,0,
12,14,173,3,0,
12,14,182,4,0,
12,14,202,3,0,
12,14,207,4,0,
12,14,213,4,0,
12,14,214,3,0,
12,14,216,4,0,
12,14,218,4,0,
12,14,219,1,36,
12,14,219,4,0,
12,14,237,4,0,
12,14,240,4,0,
12,14,241,4,0,
12,14,244,4,0,
12,14,247,4,0,
12,14,263,4,0,
12,14,285,3,0,
12,14,318,1,28,
12,14,324,3,0,
12,14,332,4,0,
12,14,355,3,0,
12,14,366,1,30,
12,14,366,3,0,
12,14,369,4,0,
12,14,405,1,42,
12,14,412,4,0,
12,14,416,4,0,
12,14,445,1,40,
12,14,450,3,0,
12,14,474,4,0,
12,14,476,1,34,
12,14,483,1,46,
12,14,496,4,0,
12,14,512,4,0,
12,14,522,4,0,
12,14,527,3,0,
12,15,16,1,16,
12,15,18,1,22,
12,15,48,1,18,
12,15,60,1,24,
12,15,63,4,0,
12,15,76,4,0,
12,15,77,1,12,1
12,15,78,1,12,2
12,15,79,1,12,3
12,15,92,4,0,
12,15,93,1,1,
12,15,93,1,10,
12,15,94,4,0,
12,15,104,4,0,
12,15,138,4,0,
12,15,148,4,0,
12,15,156,4,0,
12,15,164,4,0,
12,15,168,4,0,
12,15,182,4,0,
12,15,207,4,0,
12,15,213,4,0,
12,15,214,4,0,
12,15,216,4,0,
12,15,218,4,0,
12,15,219,1,36,
12,15,219,4,0,
12,15,237,4,0,
12,15,240,4,0,
12,15,241,4,0,
12,15,244,4,0,
12,15,247,4,0,
12,15,263,4,0,
12,15,318,1,28,
12,15,332,4,0,
12,15,355,4,0,
12,15,366,1,30,
12,15,369,4,0,
12,15,405,1,42,
12,15,412,4,0,
12,15,416,4,0,
12,15,445,1,40,
12,15,474,4,0,
12,15,476,1,34,
12,15,483,1,46,
12,15,496,4,0,
12,15,512,4,0,
12,15,522,4,0,
12,15,590,4,0,
12,15,611,4,0,
12,16,16,1,16,1
12,16,18,1,22,1
12,16,48,1,18,1
12,16,60,1,24,1
12,16,63,4,0,
12,16,76,4,0,
12,16,77,1,12,1
12,16,78,1,12,2
12,16,79,1,12,3
12,16,92,4,0,
12,16,93,1,1,1
12,16,93,1,10,1
12,16,94,4,0,
12,16,104,4,0,
12,16,138,4,0,
12,16,148,4,0,
12,16,156,4,0,
12,16,164,4,0,
12,16,168,4,0,
12,16,173,3,0,
12,16,182,4,0,
12,16,202,3,0,
12,16,207,4,0,
12,16,213,4,0,
12,16,214,4,0,
12,16,216,4,0,
12,16,218,4,0,
12,16,219,1,36,1
12,16,219,4,0,
12,16,237,4,0,
12,16,240,4,0,
12,16,241,4,0,
12,16,244,4,0,
12,16,247,4,0,
12,16,263,4,0,
12,16,285,3,0,
12,16,290,4,0,
12,16,318,1,28,1
12,16,324,3,0,
12,16,332,4,0,
12,16,355,4,0,
12,16,366,1,30,1
12,16,366,3,0,
12,16,369,4,0,
12,16,405,1,42,1
12,16,412,4,0,
12,16,416,4,0,
12,16,445,1,40,1
12,16,450,3,0,
12,16,474,4,0,
12,16,476,1,34,1
12,16,483,1,46,1
12,16,496,4,0,
12,16,512,4,0,
12,16,522,4,0,
12,16,527,3,0,
12,16,590,4,0,
12,16,611,4,0,
12,17,16,1,0,
12,17,16,1,1,1
12,17,18,1,29,
12,17,48,1,23,
12,17,60,1,17,
12,17,63,4,0,
12,17,76,4,0,
12,17,77,1,13,1
12,17,78,1,13,2
12,17,79,1,13,3
12,17,92,4,0,
12,17,93,1,1,2
12,17,93,1,11,
12,17,94,4,0,
12,17,104,4,0,
12,17,138,4,0,
12,17,156,4,0,
12,17,164,4,0,
12,17,168,4,0,
12,17,182,4,0,
12,17,207,4,0,
12,17,213,4,0,
12,17,214,4,0,
12,17,216,4,0,
12,17,218,4,0,
12,17,219,1,25,
12,17,219,4,0,
12,17,237,4,0,
12,17,240,4,0,
12,17,241,4,0,
12,17,244,4,0,
12,17,247,4,0,
12,17,263,4,0,
12,17,318,1,19,
12,17,332,4,0,
12,17,355,4,0,
12,17,366,1,41,
12,17,369,4,0,
12,17,403,1,43,
12,17,405,1,31,
12,17,412,4,0,
12,17,416,4,0,
12,17,445,1,37,
12,17,474,4,0,
12,17,476,1,35,
12,17,483,1,47,
12,17,496,4,0,
12,17,512,4,0,
12,17,590,4,0,
12,17,611,4,0,
12,18,16,1,0,
12,18,16,1,1,1
12,18,18,1,29,
12,18,48,1,23,
12,18,60,1,17,
12,18,63,4,0,
12,18,76,4,0,
12,18,77,1,13,1
12,18,78,1,13,2
12,18,79,1,13,3
12,18,92,4,0,
12,18,93,1,1,2
12,18,93,1,11,
12,18,94,4,0,
12,18,104,4,0,
12,18,138,4,0,
12,18,156,4,0,
12,18,164,4,0,
12,18,168,4,0,
12,18,182,4,0,
12,18,207,4,0,
12,18,213,4,0,
12,18,214,4,0,
12,18,216,4,0,
12,18,218,4,0,
12,18,219,1,25,
12,18,219,4,0,
12,18,237,4,0,
12,18,240,4,0,
12,18,241,4,0,
12,18,244,4,0,
12,18,247,4,0,
12,18,263,4,0,
12,18,318,1,19,
12,18,332,4,0,
12,18,355,4,0,
12,18,366,1,41,
12,18,369,4,0,
12,18,403,1,43,
12,18,405,1,31,
12,18,412,4,0,
12,18,416,4,0,
12,18,445,1,37,
12,18,474,4,0,
12,18,476,1,35,
12,18,483,1,47,
12,18,496,4,0,
12,18,512,4,0,
12,18,590,4,0,
12,18,611,4,0,
13,1,40,1,1,1
13,1,81,1,1,2
13,2,40,1,1,1
13,2,81,1,1,2
13,3,40,1,1,1
13,3,81,1,1,2
13,4,40,1,1,1
13,4,81,1,1,2
13,5,40,1,1,1
13,5,81,1,1,2
13,6,40,1,1,1
13,6,81,1,1,2
13,7,40,1,1,1
13,7,81,1,1,2
13,8,40,1,1,1
13,8,81,1,1,2
13,9,40,1,1,1
13,9,81,1,1,2
13,9,450,1,15,
13,10,40,1,1,1
13,10,81,1,1,2
13,10,81,3,0,
13,10,450,1,15,
13,10,450,3,0,
13,11,40,1,1,1
13,11,81,1,1,2
13,11,450,1,15,
13,12,40,1,1,1
13,12,81,1,1,2
13,13,40,1,1,1
13,13,81,1,1,2
13,14,40,1,1,1
13,14,81,1,1,2
13,14,450,1,15,
13,14,450,3,0,
13,14,527,3,0,
13,15,40,1,1,1
13,15,81,1,1,2
13,15,450,1,15,
13,16,40,1,1,1
13,16,81,1,1,2
13,16,450,1,15,1
13,16,450,3,0,
13,16,527,3,0,
13,17,40,1,1,1
13,17,81,1,1,2
13,17,450,1,9,
13,18,40,1,1,1
13,18,81,1,1,2
13,18,450,1,9,
14,1,106,1,1,
14,2,106,1,1,
14,3,106,1,1,
14,3,106,1,7,
14,4,106,1,1,
14,4,106,1,7,
14,5,106,1,1,
14,5,106,1,7,
14,6,106,1,1,
14,6,106,1,7,
14,7,106,1,1,
14,7,106,1,7,
14,8,106,1,1,
14,8,106,1,7,
14,9,106,1,1,
14,9,106,1,7,
14,9,334,3,0,
14,10,81,3,0,
14,10,106,1,1,
14,10,106,1,7,
14,10,334,3,0,
14,10,450,3,0,
14,11,106,1,1,
14,11,106,1,7,
14,12,106,1,1,
14,12,106,1,7,
14,13,106,1,1,
14,13,106,1,7,
14,14,106,1,1,
14,14,106,1,7,
14,14,334,3,0,
14,14,450,3,0,
14,14,527,3,0,
14,15,106,1,1,
14,15,106,1,7,
14,16,106,1,1,1
14,16,106,1,7,1
14,16,334,3,0,
14,16,450,3,0,
14,16,527,3,0,
14,17,106,1,0,
14,17,106,1,1,
14,18,106,1,0,
14,18,106,1,1,
15,1,14,4,0,
15,1,15,4,0,
15,1,31,1,1,
15,1,31,1,12,
15,1,36,4,0,
15,1,38,4,0,
15,1,41,1,20,
15,1,42,1,30,
15,1,63,4,0,
15,1,72,4,0,
15,1,92,4,0,
15,1,97,1,35,
15,1,99,1,25,
15,1,99,4,0,
15,1,102,4,0,
15,1,104,4,0,
15,1,115,4,0,
15,1,116,1,16,
15,1,117,4,0,
15,1,129,4,0,
15,1,130,4,0,
15,1,156,4,0,
15,1,164,4,0,
15,2,14,4,0,
15,2,15,4,0,
15,2,31,1,1,
15,2,31,1,12,
15,2,36,4,0,
15,2,38,4,0,
15,2,41,1,20,
15,2,42,1,30,
15,2,63,4,0,
15,2,72,4,0,
15,2,92,4,0,
15,2,97,1,35,
15,2,99,1,25,
15,2,99,4,0,
15,2,102,4,0,
15,2,104,4,0,
15,2,115,4,0,
15,2,116,1,16,
15,2,117,4,0,
15,2,129,4,0,
15,2,130,4,0,
15,2,156,4,0,
15,2,164,4,0,
15,3,15,4,0,
15,3,31,1,1,
15,3,31,1,10,
15,3,41,1,20,
15,3,42,1,35,
15,3,63,4,0,
15,3,92,4,0,
15,3,97,1,40,
15,3,99,1,25,
15,3,104,4,0,
15,3,116,1,15,
15,3,129,4,0,
15,3,156,4,0,
15,3,173,4,0,
15,3,174,4,0,
15,3,182,4,0,
15,3,188,4,0,
15,3,202,4,0,
15,3,203,4,0,
15,3,207,4,0,
15,3,210,4,0,
15,3,213,4,0,
15,3,214,4,0,
15,3,216,4,0,
15,3,218,4,0,
15,3,228,1,30,
15,3,230,4,0,
15,3,237,4,0,
15,3,241,4,0,
15,4,15,4,0,
15,4,31,1,1,
15,4,31,1,10,
15,4,41,1,20,
15,4,42,1,35,
15,4,63,4,0,
15,4,92,4,0,
15,4,97,1,40,
15,4,99,1,25,
15,4,104,4,0,
15,4,116,1,15,
15,4,129,4,0,
15,4,156,4,0,
15,4,173,4,0,
15,4,174,4,0,
15,4,182,4,0,
15,4,188,4,0,
15,4,202,4,0,
15,4,203,4,0,
15,4,207,4,0,
15,4,210,4,0,
15,4,213,4,0,
15,4,214,4,0,
15,4,216,4,0,
15,4,218,4,0,
15,4,228,1,30,
15,4,230,4,0,
15,4,237,4,0,
15,4,241,4,0,
15,5,15,4,0,
15,5,31,1,1,
15,5,31,1,10,
15,5,41,1,20,
15,5,42,1,35,
15,5,63,4,0,
15,5,76,4,0,
15,5,92,4,0,
15,5,97,1,40,
15,5,99,1,25,
15,5,104,4,0,
15,5,116,1,15,
15,5,156,4,0,
15,5,168,4,0,
15,5,182,4,0,
15,5,188,4,0,
15,5,202,4,0,
15,5,213,4,0,
15,5,216,4,0,
15,5,218,4,0,
15,5,228,1,30,
15,5,237,4,0,
15,5,241,4,0,
15,5,249,4,0,
15,5,263,4,0,
15,5,280,4,0,
15,5,283,1,45,
15,5,290,4,0,
15,5,332,4,0,
15,6,14,3,0,
15,6,15,4,0,
15,6,31,1,1,
15,6,31,1,10,
15,6,38,3,0,
15,6,41,1,20,
15,6,42,1,35,
15,6,63,4,0,
15,6,76,4,0,
15,6,92,4,0,
15,6,97,1,40,
15,6,99,1,25,
15,6,102,3,0,
15,6,104,4,0,
15,6,116,1,15,
15,6,129,3,0,
15,6,156,4,0,
15,6,164,3,0,
15,6,168,4,0,
15,6,173,3,0,
15,6,182,4,0,
15,6,188,4,0,
15,6,202,4,0,
15,6,203,3,0,
15,6,207,3,0,
15,6,210,3,0,
15,6,213,4,0,
15,6,214,3,0,
15,6,216,4,0,
15,6,218,4,0,
15,6,228,1,30,
15,6,237,4,0,
15,6,241,4,0,
15,6,249,4,0,
15,6,263,4,0,
15,6,280,4,0,
15,6,283,1,45,
15,6,290,4,0,
15,6,332,4,0,
15,7,14,3,0,
15,7,15,4,0,
15,7,31,1,1,
15,7,31,1,10,
15,7,38,3,0,
15,7,41,1,20,
15,7,42,1,35,
15,7,63,4,0,
15,7,76,4,0,
15,7,92,4,0,
15,7,97,1,40,
15,7,99,1,25,
15,7,102,3,0,
15,7,104,4,0,
15,7,116,1,15,
15,7,156,4,0,
15,7,164,3,0,
15,7,168,4,0,
15,7,182,4,0,
15,7,188,4,0,
15,7,202,4,0,
15,7,213,4,0,
15,7,216,4,0,
15,7,218,4,0,
15,7,228,1,30,
15,7,237,4,0,
15,7,241,4,0,
15,7,249,4,0,
15,7,263,4,0,
15,7,280,4,0,
15,7,283,1,45,
15,7,290,4,0,
15,7,332,4,0,
15,8,14,4,0,
15,8,15,4,0,
15,8,31,1,1,
15,8,31,1,10,
15,8,41,1,16,
15,8,42,1,28,
15,8,63,4,0,
15,8,76,4,0,
15,8,92,4,0,
15,8,97,1,31,
15,8,99,1,19,
15,8,104,4,0,
15,8,116,1,13,
15,8,148,4,0,
15,8,156,4,0,
15,8,164,4,0,
15,8,168,4,0,
15,8,182,4,0,
15,8,188,4,0,
15,8,202,4,0,
15,8,203,4,0,
15,8,206,4,0,
15,8,207,4,0,
15,8,213,4,0,
15,8,214,4,0,
15,8,216,4,0,
15,8,218,4,0,
15,8,228,1,22,
15,8,237,4,0,
15,8,241,4,0,
15,8,249,4,0,
15,8,263,4,0,
15,8,280,4,0,
15,8,283,1,40,
15,8,290,4,0,
15,8,318,4,0,
15,8,332,4,0,
15,8,355,4,0,
15,8,363,4,0,
15,8,369,4,0,
15,8,371,4,0,
15,8,372,1,34,
15,8,390,1,25,
15,8,398,1,37,
15,8,398,4,0,
15,8,404,4,0,
15,8,416,4,0,
15,8,432,4,0,
15,8,445,4,0,
15,9,14,4,0,
15,9,15,4,0,
15,9,31,1,1,
15,9,31,1,10,
15,9,41,1,16,
15,9,42,1,28,
15,9,63,4,0,
15,9,76,4,0,
15,9,92,4,0,
15,9,97,1,31,
15,9,99,1,19,
15,9,104,4,0,
15,9,116,1,13,
15,9,129,3,0,
15,9,148,4,0,
15,9,156,4,0,
15,9,164,4,0,
15,9,168,4,0,
15,9,173,3,0,
15,9,182,4,0,
15,9,188,4,0,
15,9,202,4,0,
15,9,203,4,0,
15,9,206,4,0,
15,9,207,4,0,
15,9,210,3,0,
15,9,213,4,0,
15,9,214,4,0,
15,9,216,4,0,
15,9,218,4,0,
15,9,228,1,22,
15,9,237,4,0,
15,9,241,4,0,
15,9,249,4,0,
15,9,263,4,0,
15,9,280,4,0,
15,9,282,3,0,
15,9,283,1,40,
15,9,283,3,0,
15,9,290,4,0,
15,9,314,3,0,
15,9,318,4,0,
15,9,332,4,0,
15,9,355,4,0,
15,9,363,4,0,
15,9,369,4,0,
15,9,371,4,0,
15,9,372,1,34,
15,9,390,1,25,
15,9,398,1,37,
15,9,398,4,0,
15,9,404,4,0,
15,9,416,4,0,
15,9,432,4,0,
15,9,445,4,0,
15,9,466,3,0,
15,10,14,4,0,
15,10,15,4,0,
15,10,31,1,1,
15,10,31,1,10,
15,10,41,1,16,
15,10,42,1,28,
15,10,63,4,0,
15,10,76,4,0,
15,10,81,3,0,
15,10,92,4,0,
15,10,97,1,31,
15,10,99,1,19,
15,10,104,4,0,
15,10,116,1,13,
15,10,129,3,0,
15,10,148,4,0,
15,10,156,4,0,
15,10,164,4,0,
15,10,168,4,0,
15,10,173,3,0,
15,10,182,4,0,
15,10,188,4,0,
15,10,202,4,0,
15,10,203,4,0,
15,10,206,4,0,
15,10,207,4,0,
15,10,210,3,0,
15,10,213,4,0,
15,10,214,4,0,
15,10,216,4,0,
15,10,218,4,0,
15,10,228,1,22,
15,10,237,4,0,
15,10,241,4,0,
15,10,249,4,0,
15,10,263,4,0,
15,10,280,4,0,
15,10,282,3,0,
15,10,283,1,40,
15,10,283,3,0,
15,10,290,4,0,
15,10,314,3,0,
15,10,318,4,0,
15,10,332,4,0,
15,10,355,4,0,
15,10,363,4,0,
15,10,366,3,0,
15,10,369,4,0,
15,10,371,4,0,
15,10,372,1,34,
15,10,390,1,25,
15,10,398,1,37,
15,10,398,4,0,
15,10,404,4,0,
15,10,416,4,0,
15,10,445,4,0,
15,10,450,3,0,
15,10,466,3,0,
15,11,14,4,0,
15,11,15,4,0,
15,11,31,1,1,
15,11,31,1,10,
15,11,41,1,16,
15,11,42,1,28,
15,11,63,4,0,
15,11,76,4,0,
15,11,92,4,0,
15,11,97,1,31,
15,11,99,1,19,
15,11,104,4,0,
15,11,116,1,13,
15,11,148,4,0,
15,11,156,4,0,
15,11,164,4,0,
15,11,168,4,0,
15,11,182,4,0,
15,11,188,4,0,
15,11,206,4,0,
15,11,207,4,0,
15,11,213,4,0,
15,11,216,4,0,
15,11,218,4,0,
15,11,228,1,22,
15,11,237,4,0,
15,11,241,4,0,
15,11,249,4,0,
15,11,263,4,0,
15,11,280,4,0,
15,11,283,1,40,
15,11,332,4,0,
15,11,369,4,0,
15,11,371,4,0,
15,11,372,1,34,
15,11,390,1,25,
15,11,398,1,37,
15,11,398,4,0,
15,11,404,4,0,
15,11,416,4,0,
15,11,474,4,0,
15,11,496,4,0,
15,11,512,4,0,
15,11,522,4,0,
15,12,15,4,0,
15,12,31,1,1,
15,12,31,1,10,
15,12,41,1,20,
15,12,42,1,35,
15,12,63,4,0,
15,12,76,4,0,
15,12,92,4,0,
15,12,97,1,40,
15,12,99,1,25,
15,12,104,4,0,
15,12,116,1,15,
15,12,156,4,0,
15,12,168,4,0,
15,12,182,4,0,
15,12,188,4,0,
15,12,202,4,0,
15,12,213,4,0,
15,12,216,4,0,
15,12,218,4,0,
15,12,228,1,30,
15,12,237,4,0,
15,12,241,4,0,
15,12,249,4,0,
15,12,263,4,0,
15,12,280,4,0,
15,12,283,1,45,
15,12,290,4,0,
15,12,332,4,0,
15,13,15,4,0,
15,13,31,1,1,
15,13,31,1,10,
15,13,38,3,0,
15,13,41,1,20,
15,13,42,1,35,
15,13,63,4,0,
15,13,76,4,0,
15,13,92,4,0,
15,13,97,1,40,
15,13,99,1,25,
15,13,102,3,0,
15,13,104,4,0,
15,13,116,1,15,
15,13,156,4,0,
15,13,164,3,0,
15,13,168,4,0,
15,13,182,4,0,
15,13,188,4,0,
15,13,202,4,0,
15,13,207,3,0,
15,13,213,4,0,
15,13,216,4,0,
15,13,218,4,0,
15,13,228,1,30,
15,13,237,4,0,
15,13,241,4,0,
15,13,249,4,0,
15,13,263,4,0,
15,13,280,4,0,
15,13,283,1,45,
15,13,290,4,0,
15,13,332,4,0,
15,14,14,4,0,
15,14,15,4,0,
15,14,31,1,1,
15,14,31,1,10,
15,14,41,1,16,
15,14,42,1,28,
15,14,63,4,0,
15,14,76,4,0,
15,14,92,4,0,
15,14,97,1,31,
15,14,99,1,19,
15,14,104,4,0,
15,14,116,1,13,
15,14,148,4,0,
15,14,156,4,0,
15,14,164,4,0,
15,14,168,4,0,
15,14,173,3,0,
15,14,182,4,0,
15,14,188,4,0,
15,14,202,3,0,
15,14,206,4,0,
15,14,207,4,0,
15,14,213,4,0,
15,14,214,3,0,
15,14,216,4,0,
15,14,218,4,0,
15,14,228,1,22,
15,14,237,4,0,
15,14,241,4,0,
15,14,249,4,0,
15,14,263,4,0,
15,14,280,4,0,
15,14,282,3,0,
15,14,283,1,40,
15,14,283,3,0,
15,14,332,4,0,
15,14,355,3,0,
15,14,366,3,0,
15,14,369,4,0,
15,14,371,4,0,
15,14,372,1,34,
15,14,390,1,25,
15,14,398,1,37,
15,14,398,4,0,
15,14,404,4,0,
15,14,416,4,0,
15,14,450,3,0,
15,14,474,4,0,
15,14,496,4,0,
15,14,512,4,0,
15,14,522,4,0,
15,14,527,3,0,
15,14,529,3,0,
15,15,14,4,0,
15,15,15,4,0,
15,15,31,1,1,
15,15,31,1,10,
15,15,41,1,16,
15,15,42,1,28,
15,15,63,4,0,
15,15,76,4,0,
15,15,92,4,0,
15,15,97,1,31,
15,15,99,1,19,
15,15,104,4,0,
15,15,116,1,13,
15,15,148,4,0,
15,15,156,4,0,
15,15,164,4,0,
15,15,168,4,0,
15,15,182,4,0,
15,15,188,4,0,
15,15,206,4,0,
15,15,207,4,0,
15,15,213,4,0,
15,15,214,4,0,
15,15,216,4,0,
15,15,218,4,0,
15,15,228,1,22,
15,15,237,4,0,
15,15,241,4,0,
15,15,249,4,0,
15,15,263,4,0,
15,15,280,4,0,
15,15,283,1,40,
15,15,332,4,0,
15,15,355,4,0,
15,15,369,4,0,
15,15,371,4,0,
15,15,372,1,34,
15,15,390,1,25,
15,15,398,1,37,
15,15,398,4,0,
15,15,404,4,0,
15,15,416,4,0,
15,15,474,4,0,
15,15,496,4,0,
15,15,512,4,0,
15,15,522,4,0,
15,15,565,1,45,
15,15,590,4,0,
15,15,611,4,0,
15,16,14,4,0,
15,16,15,4,0,
15,16,31,1,1,1
15,16,31,1,10,1
15,16,41,1,16,1
15,16,42,1,28,1
15,16,63,4,0,
15,16,76,4,0,
15,16,92,4,0,
15,16,97,1,31,1
15,16,99,1,19,1
15,16,104,4,0,
15,16,116,1,13,1
15,16,148,4,0,
15,16,156,4,0,
15,16,164,4,0,
15,16,168,4,0,
15,16,173,3,0,
15,16,182,4,0,
15,16,188,4,0,
15,16,202,3,0,
15,16,206,4,0,
15,16,207,4,0,
15,16,213,4,0,
15,16,214,4,0,
15,16,216,4,0,
15,16,218,4,0,
15,16,228,1,22,1
15,16,237,4,0,
15,16,241,4,0,
15,16,249,4,0,
15,16,263,4,0,
15,16,280,4,0,
15,16,282,3,0,
15,16,283,1,40,1
15,16,283,3,0,
15,16,290,4,0,
15,16,332,4,0,
15,16,355,4,0,
15,16,366,3,0,
15,16,369,4,0,
15,16,371,4,0,
15,16,372,1,34,1
15,16,390,1,25,1
15,16,398,1,37,1
15,16,398,4,0,
15,16,404,4,0,
15,16,416,4,0,
15,16,450,3,0,
15,16,474,4,0,
15,16,496,4,0,
15,16,512,4,0,
15,16,522,4,0,
15,16,527,3,0,
15,16,529,3,0,
15,16,565,1,45,1
15,16,590,4,0,
15,16,611,4,0,
15,17,14,4,0,
15,17,31,1,1,2
15,17,31,1,11,
15,17,41,1,0,
15,17,41,1,1,1
15,17,42,1,32,
15,17,63,4,0,
15,17,76,4,0,
15,17,92,4,0,
15,17,97,1,38,
15,17,99,1,14,
15,17,104,4,0,
15,17,116,1,20,
15,17,156,4,0,
15,17,164,4,0,
15,17,168,4,0,
15,17,182,4,0,
15,17,188,4,0,
15,17,206,4,0,
15,17,207,4,0,
15,17,213,4,0,
15,17,214,4,0,
15,17,216,4,0,
15,17,218,4,0,
15,17,228,1,17,
15,17,237,4,0,
15,17,241,4,0,
15,17,263,4,0,
15,17,280,4,0,
15,17,283,1,41,
15,17,332,4,0,
15,17,355,4,0,
15,17,369,4,0,
15,17,371,4,0,
15,17,372,1,26,
15,17,390,1,29,
15,17,398,1,35,
15,17,398,4,0,
15,17,404,4,0,
15,17,416,4,0,
15,17,474,1,23,
15,17,474,4,0,
15,17,496,4,0,
15,17,512,4,0,
15,17,565,1,44,
15,17,590,4,0,
15,17,611,4,0,
15,17,693,4,0,
15,18,14,4,0,
15,18,31,1,1,2
15,18,31,1,11,
15,18,41,1,0,
15,18,41,1,1,1
15,18,42,1,32,
15,18,63,4,0,
15,18,76,4,0,
15,18,92,4,0,
15,18,97,1,38,
15,18,99,1,14,
15,18,104,4,0,
15,18,116,1,20,
15,18,156,4,0,
15,18,164,4,0,
15,18,168,4,0,
15,18,182,4,0,
15,18,188,4,0,
15,18,206,4,0,
15,18,207,4,0,
15,18,213,4,0,
15,18,214,4,0,
15,18,216,4,0,
15,18,218,4,0,
15,18,228,1,17,
15,18,237,4,0,
15,18,241,4,0,
15,18,263,4,0,
15,18,280,4,0,
15,18,283,1,41,
15,18,332,4,0,
15,18,355,4,0,
15,18,369,4,0,
15,18,371,4,0,
15,18,372,1,26,
15,18,390,1,29,
15,18,398,1,35,
15,18,398,4,0,
15,18,404,4,0,
15,18,416,4,0,
15,18,474,1,23,
15,18,474,4,0,
15,18,496,4,0,
15,18,512,4,0,
15,18,565,1,44,
15,18,590,4,0,
15,18,611,4,0,
15,18,693,4,0,
16,1,13,4,0,
16,1,16,1,1,
16,1,17,1,28,
16,1,18,1,19,
16,1,18,4,0,
16,1,19,4,0,
16,1,28,1,5,
16,1,36,4,0,
16,1,38,4,0,
16,1,92,4,0,
16,1,97,1,36,
16,1,98,1,12,
16,1,99,4,0,
16,1,102,4,0,
16,1,104,4,0,
16,1,115,4,0,
16,1,117,4,0,
16,1,119,1,44,
16,1,129,4,0,
16,1,143,4,0,
16,1,156,4,0,
16,1,164,4,0,
16,2,13,4,0,
16,2,16,1,1,
16,2,17,1,28,
16,2,18,1,19,
16,2,18,4,0,
16,2,19,4,0,
16,2,28,1,5,
16,2,36,4,0,
16,2,38,4,0,
16,2,92,4,0,
16,2,97,1,36,
16,2,98,1,12,
16,2,99,4,0,
16,2,102,4,0,
16,2,104,4,0,
16,2,115,4,0,
16,2,117,4,0,
16,2,119,1,44,
16,2,129,4,0,
16,2,143,4,0,
16,2,156,4,0,
16,2,164,4,0,
16,3,16,1,9,
16,3,17,1,29,
16,3,18,1,21,
16,3,19,4,0,
16,3,28,1,5,
16,3,33,1,1,
16,3,92,4,0,
16,3,97,1,37,
16,3,98,1,15,
16,3,104,4,0,
16,3,119,1,47,
16,3,129,4,0,
16,3,156,4,0,
16,3,168,4,0,
16,3,173,4,0,
16,3,174,4,0,
16,3,182,4,0,
16,3,185,2,0,
16,3,189,4,0,
16,3,193,2,0,
16,3,197,4,0,
16,3,203,4,0,
16,3,207,4,0,
16,3,211,2,0,
16,3,211,4,0,
16,3,213,4,0,
16,3,214,4,0,
16,3,216,4,0,
16,3,218,4,0,
16,3,228,2,0,
16,3,237,4,0,
16,3,241,4,0,
16,4,16,1,9,
16,4,17,1,29,
16,4,18,1,21,
16,4,19,4,0,
16,4,28,1,5,
16,4,33,1,1,
16,4,92,4,0,
16,4,97,1,37,
16,4,98,1,15,
16,4,104,4,0,
16,4,119,1,47,
16,4,129,4,0,
16,4,156,4,0,
16,4,168,4,0,
16,4,173,4,0,
16,4,174,4,0,
16,4,182,4,0,
16,4,185,2,0,
16,4,189,4,0,
16,4,193,2,0,
16,4,197,4,0,
16,4,203,4,0,
16,4,207,4,0,
16,4,211,4,0,
16,4,213,4,0,
16,4,214,4,0,
16,4,216,4,0,
16,4,218,4,0,
16,4,228,2,0,
16,4,237,4,0,
16,4,241,4,0,
16,5,16,1,9,
16,5,17,1,25,
16,5,18,1,19,
16,5,19,4,0,
16,5,28,1,5,
16,5,33,1,1,
16,5,92,4,0,
16,5,97,1,39,
16,5,98,1,13,
16,5,104,4,0,
16,5,119,1,47,
16,5,156,4,0,
16,5,168,4,0,
16,5,182,4,0,
16,5,185,2,0,
16,5,193,2,0,
16,5,211,2,0,
16,5,211,4,0,
16,5,213,4,0,
16,5,216,4,0,
16,5,218,4,0,
16,5,228,2,0,
16,5,237,4,0,
16,5,240,4,0,
16,5,241,4,0,
16,5,263,4,0,
16,5,290,4,0,
16,5,297,1,31,
16,5,314,2,0,
16,5,332,4,0,
16,6,16,1,9,
16,6,17,1,25,
16,6,18,1,19,
16,6,19,4,0,
16,6,28,1,5,
16,6,33,1,1,
16,6,38,3,0,
16,6,92,4,0,
16,6,97,1,39,
16,6,98,1,13,
16,6,102,3,0,
16,6,104,4,0,
16,6,119,1,47,
16,6,129,3,0,
16,6,156,4,0,
16,6,164,3,0,
16,6,168,4,0,
16,6,173,3,0,
16,6,182,4,0,
16,6,185,2,0,
16,6,189,3,0,
16,6,193,2,0,
16,6,203,3,0,
16,6,207,3,0,
16,6,211,2,0,
16,6,211,4,0,
16,6,213,4,0,
16,6,214,3,0,
16,6,216,4,0,
16,6,218,4,0,
16,6,228,2,0,
16,6,237,4,0,
16,6,240,4,0,
16,6,241,4,0,
16,6,263,4,0,
16,6,290,4,0,
16,6,297,1,31,
16,6,314,2,0,
16,6,332,4,0,
16,7,16,1,9,
16,7,17,1,25,
16,7,18,1,19,
16,7,19,4,0,
16,7,28,1,5,
16,7,33,1,1,
16,7,38,3,0,
16,7,92,4,0,
16,7,97,1,39,
16,7,98,1,13,
16,7,102,3,0,
16,7,104,4,0,
16,7,119,1,47,
16,7,156,4,0,
16,7,164,3,0,
16,7,168,4,0,
16,7,182,4,0,
16,7,185,2,0,
16,7,193,2,0,
16,7,211,2,0,
16,7,211,4,0,
16,7,213,4,0,
16,7,216,4,0,
16,7,218,4,0,
16,7,228,2,0,
16,7,237,4,0,
16,7,240,4,0,
16,7,241,4,0,
16,7,263,4,0,
16,7,290,4,0,
16,7,297,1,31,
16,7,314,2,0,
16,7,332,4,0,
16,8,16,1,9,
16,8,17,1,33,
16,8,18,1,17,
16,8,19,4,0,
16,8,28,1,5,
16,8,33,1,1,
16,8,92,4,0,
16,8,97,1,29,
16,8,98,1,13,
16,8,104,4,0,
16,8,119,1,45,
16,8,156,4,0,
16,8,164,4,0,
16,8,168,4,0,
16,8,182,4,0,
16,8,185,2,0,
16,8,193,2,0,
16,8,203,4,0,
16,8,207,4,0,
16,8,211,2,0,
16,8,211,4,0,
16,8,213,4,0,
16,8,214,4,0,
16,8,216,4,0,
16,8,218,4,0,
16,8,228,2,0,
16,8,237,4,0,
16,8,239,1,21,
16,8,240,4,0,
16,8,241,4,0,
16,8,253,2,0,
16,8,263,4,0,
16,8,290,4,0,
16,8,297,1,25,
16,8,314,2,0,
16,8,332,4,0,
16,8,355,1,37,
16,8,355,4,0,
16,8,363,4,0,
16,8,365,4,0,
16,8,366,1,41,
16,8,369,4,0,
16,8,403,1,49,
16,8,403,2,0,
16,8,413,2,0,
16,8,432,4,0,
16,8,445,4,0,
16,9,16,1,9,
16,9,17,1,33,
16,9,18,1,17,
16,9,19,4,0,
16,9,28,1,5,
16,9,33,1,1,
16,9,92,4,0,
16,9,97,1,29,
16,9,98,1,13,
16,9,104,4,0,
16,9,119,1,45,
16,9,129,3,0,
16,9,156,4,0,
16,9,164,4,0,
16,9,168,4,0,
16,9,173,3,0,
16,9,182,4,0,
16,9,185,2,0,
16,9,189,3,0,
16,9,193,2,0,
16,9,203,4,0,
16,9,207,4,0,
16,9,211,2,0,
16,9,211,4,0,
16,9,213,4,0,
16,9,214,4,0,
16,9,216,4,0,
16,9,218,4,0,
16,9,228,2,0,
16,9,237,4,0,
16,9,239,1,21,
16,9,239,3,0,
16,9,240,4,0,
16,9,241,4,0,
16,9,253,2,0,
16,9,253,3,0,
16,9,257,3,0,
16,9,263,4,0,
16,9,290,4,0,
16,9,297,1,25,
16,9,314,2,0,
16,9,314,3,0,
16,9,332,4,0,
16,9,355,1,37,
16,9,355,4,0,
16,9,363,4,0,
16,9,365,4,0,
16,9,366,1,41,
16,9,369,4,0,
16,9,403,1,49,
16,9,403,2,0,
16,9,413,2,0,
16,9,432,4,0,
16,9,445,4,0,
16,9,466,3,0,
16,10,16,1,9,
16,10,17,1,33,
16,10,18,1,17,
16,10,19,4,0,
16,10,28,1,5,
16,10,33,1,1,
16,10,92,4,0,
16,10,97,1,29,
16,10,98,1,13,
16,10,104,4,0,
16,10,119,1,45,
16,10,129,3,0,
16,10,156,4,0,
16,10,164,4,0,
16,10,168,4,0,
16,10,173,3,0,
16,10,182,4,0,
16,10,185,2,0,
16,10,189,3,0,
16,10,193,2,0,
16,10,203,4,0,
16,10,207,4,0,
16,10,211,2,0,
16,10,211,4,0,
16,10,213,4,0,
16,10,214,4,0,
16,10,216,4,0,
16,10,218,4,0,
16,10,228,2,0,
16,10,237,4,0,
16,10,239,1,21,
16,10,239,3,0,
16,10,240,4,0,
16,10,241,4,0,
16,10,253,2,0,
16,10,253,3,0,
16,10,257,3,0,
16,10,263,4,0,
16,10,290,4,0,
16,10,297,1,25,
16,10,314,2,0,
16,10,314,3,0,
16,10,332,4,0,
16,10,355,1,37,
16,10,355,4,0,
16,10,363,4,0,
16,10,365,4,0,
16,10,366,1,41,
16,10,366,3,0,
16,10,369,4,0,
16,10,403,1,49,
16,10,403,2,0,
16,10,413,2,0,
16,10,445,4,0,
16,10,466,3,0,
16,11,16,1,9,
16,11,17,1,33,
16,11,18,1,17,
16,11,19,4,0,
16,11,28,1,5,
16,11,33,1,1,
16,11,92,4,0,
16,11,97,1,29,
16,11,98,1,13,
16,11,104,4,0,
16,11,119,1,45,
16,11,156,4,0,
16,11,164,4,0,
16,11,168,4,0,
16,11,182,4,0,
16,11,185,2,0,
16,11,193,2,0,
16,11,207,4,0,
16,11,211,2,0,
16,11,213,4,0,
16,11,216,4,0,
16,11,218,4,0,
16,11,228,2,0,
16,11,237,4,0,
16,11,239,1,21,
16,11,240,4,0,
16,11,241,4,0,
16,11,253,2,0,
16,11,263,4,0,
16,11,297,1,25,
16,11,314,2,0,
16,11,332,4,0,
16,11,355,1,37,
16,11,365,4,0,
16,11,366,1,41,
16,11,369,4,0,
16,11,403,1,49,
16,11,403,2,0,
16,11,413,2,0,
16,11,432,2,0,
16,11,496,4,0,
16,11,526,4,0,
16,11,542,1,53,
16,12,16,1,9,
16,12,17,1,25,
16,12,18,1,19,
16,12,19,4,0,
16,12,28,1,5,
16,12,33,1,1,
16,12,92,4,0,
16,12,97,1,39,
16,12,98,1,13,
16,12,104,4,0,
16,12,119,1,47,
16,12,156,4,0,
16,12,168,4,0,
16,12,182,4,0,
16,12,211,4,0,
16,12,213,4,0,
16,12,216,4,0,
16,12,218,4,0,
16,12,237,4,0,
16,12,240,4,0,
16,12,241,4,0,
16,12,263,4,0,
16,12,290,4,0,
16,12,297,1,31,
16,12,332,4,0,
16,13,16,1,9,
16,13,17,1,25,
16,13,18,1,19,
16,13,19,4,0,
16,13,28,1,5,
16,13,33,1,1,
16,13,38,3,0,
16,13,92,4,0,
16,13,97,1,39,
16,13,98,1,13,
16,13,102,3,0,
16,13,104,4,0,
16,13,119,1,47,
16,13,143,3,0,
16,13,156,4,0,
16,13,164,3,0,
16,13,168,4,0,
16,13,182,4,0,
16,13,207,3,0,
16,13,211,4,0,
16,13,213,4,0,
16,13,216,4,0,
16,13,218,4,0,
16,13,237,4,0,
16,13,240,4,0,
16,13,241,4,0,
16,13,263,4,0,
16,13,290,4,0,
16,13,297,1,31,
16,13,332,4,0,
16,14,16,1,9,
16,14,17,1,33,
16,14,18,1,17,
16,14,19,4,0,
16,14,28,1,5,
16,14,33,1,1,
16,14,92,4,0,
16,14,97,1,29,
16,14,98,1,13,
16,14,104,4,0,
16,14,119,1,45,
16,14,156,4,0,
16,14,164,4,0,
16,14,168,4,0,
16,14,173,3,0,
16,14,182,4,0,
16,14,185,2,0,
16,14,193,2,0,
16,14,207,4,0,
16,14,211,2,0,
16,14,213,4,0,
16,14,214,3,0,
16,14,216,4,0,
16,14,218,4,0,
16,14,228,2,0,
16,14,237,4,0,
16,14,239,1,21,
16,14,240,4,0,
16,14,241,4,0,
16,14,253,2,0,
16,14,253,3,0,
16,14,257,3,0,
16,14,263,4,0,
16,14,297,1,25,
16,14,314,2,0,
16,14,332,4,0,
16,14,355,1,37,
16,14,355,3,0,
16,14,365,4,0,
16,14,366,1,41,
16,14,366,3,0,
16,14,369,4,0,
16,14,403,1,49,
16,14,403,2,0,
16,14,413,2,0,
16,14,432,2,0,
16,14,496,4,0,
16,14,526,4,0,
16,14,542,1,53,
16,15,16,1,9,
16,15,17,1,33,
16,15,18,1,17,
16,15,19,4,0,
16,15,28,1,5,
16,15,33,1,1,
16,15,92,4,0,
16,15,97,1,29,
16,15,98,1,13,
16,15,104,4,0,
16,15,119,1,45,
16,15,156,4,0,
16,15,164,4,0,
16,15,168,4,0,
16,15,182,4,0,
16,15,185,2,0,
16,15,193,2,0,
16,15,207,4,0,
16,15,211,2,0,
16,15,211,4,0,
16,15,213,4,0,
16,15,214,4,0,
16,15,216,4,0,
16,15,218,4,0,
16,15,228,2,0,
16,15,237,4,0,
16,15,239,1,21,
16,15,240,4,0,
16,15,241,4,0,
16,15,253,2,0,
16,15,263,4,0,
16,15,297,1,25,
16,15,314,2,0,
16,15,332,4,0,
16,15,355,1,37,
16,15,355,4,0,
16,15,366,1,41,
16,15,369,4,0,
16,15,403,1,49,
16,15,403,2,0,
16,15,413,2,0,
16,15,432,2,0,
16,15,496,4,0,
16,15,542,1,53,
16,15,590,4,0,
16,16,16,1,9,1
16,16,17,1,33,1
16,16,18,1,17,1
16,16,19,4,0,
16,16,28,1,5,1
16,16,33,1,1,1
16,16,92,4,0,
16,16,97,1,29,1
16,16,98,1,13,1
16,16,104,4,0,
16,16,119,1,45,1
16,16,143,3,0,
16,16,156,4,0,
16,16,164,4,0,
16,16,168,4,0,
16,16,173,3,0,
16,16,182,4,0,
16,16,185,2,0,
16,16,193,2,0,
16,16,207,4,0,
16,16,211,2,0,
16,16,211,4,0,
16,16,213,4,0,
16,16,214,4,0,
16,16,216,4,0,
16,16,218,4,0,
16,16,228,2,0,
16,16,237,4,0,
16,16,239,1,21,1
16,16,240,4,0,
16,16,241,4,0,
16,16,253,2,0,
16,16,253,3,0,
16,16,257,3,0,
16,16,263,4,0,
16,16,290,4,0,
16,16,297,1,25,1
16,16,314,2,0,
16,16,332,4,0,
16,16,355,1,37,1
16,16,355,4,0,
16,16,366,1,41,1
16,16,366,3,0,
16,16,369,4,0,
16,16,403,1,49,1
16,16,403,2,0,
16,16,413,2,0,
16,16,432,2,0,
16,16,496,4,0,
16,16,542,1,53,1
16,16,590,4,0,
16,17,16,1,9,
16,17,17,1,33,
16,17,18,1,17,
16,17,19,4,0,
16,17,28,1,5,
16,17,33,1,1,
16,17,92,4,0,
16,17,97,1,29,
16,17,98,1,13,
16,17,104,4,0,
16,17,119,1,45,
16,17,156,4,0,
16,17,164,4,0,
16,17,168,4,0,
16,17,182,4,0,
16,17,185,2,0,
16,17,193,2,0,
16,17,207,4,0,
16,17,211,2,0,
16,17,211,4,0,
16,17,213,4,0,
16,17,214,4,0,
16,17,216,4,0,
16,17,218,4,0,
16,17,228,2,0,
16,17,237,4,0,
16,17,239,1,21,
16,17,240,4,0,
16,17,241,4,0,
16,17,253,2,0,
16,17,263,4,0,
16,17,297,1,25,
16,17,314,2,0,
16,17,332,4,0,
16,17,355,1,37,
16,17,355,4,0,
16,17,366,1,41,
16,17,369,4,0,
16,17,403,1,49,
16,17,403,2,0,
16,17,413,2,0,
16,17,432,2,0,
16,17,496,4,0,
16,17,526,4,0,
16,17,542,1,53,
16,17,590,4,0,
16,18,16,1,9,
16,18,17,1,33,
16,18,18,1,17,
16,18,19,4,0,
16,18,28,1,5,
16,18,33,1,1,
16,18,92,4,0,
16,18,97,1,29,
16,18,98,1,13,
16,18,104,4,0,
16,18,119,1,45,
16,18,156,4,0,
16,18,164,4,0,
16,18,168,4,0,
16,18,182,4,0,
16,18,185,2,0,
16,18,193,2,0,
16,18,207,4,0,
16,18,211,2,0,
16,18,211,4,0,
16,18,213,4,0,
16,18,214,4,0,
16,18,216,4,0,
16,18,218,4,0,
16,18,228,2,0,
16,18,237,4,0,
16,18,239,1,21,
16,18,240,4,0,
16,18,241,4,0,
16,18,253,2,0,
16,18,263,4,0,
16,18,297,1,25,
16,18,314,2,0,
16,18,332,4,0,
16,18,355,1,37,
16,18,355,4,0,
16,18,366,1,41,
16,18,369,4,0,
16,18,403,1,49,
16,18,403,2,0,
16,18,413,2,0,
16,18,432,2,0,
16,18,496,4,0,
16,18,526,4,0,
16,18,542,1,53,
16,18,590,4,0,
17,1,13,4,0,
17,1,16,1,1,1
17,1,17,1,31,
17,1,18,1,21,
17,1,18,4,0,
17,1,19,4,0,
17,1,28,1,1,2
17,1,28,1,5,
17,1,36,4,0,
17,1,38,4,0,
17,1,92,4,0,
17,1,97,1,40,
17,1,98,1,12,
17,1,99,4,0,
17,1,102,4,0,
17,1,104,4,0,
17,1,115,4,0,
17,1,117,4,0,
17,1,119,1,49,
17,1,129,4,0,
17,1,143,4,0,
17,1,156,4,0,
17,1,164,4,0,
17,2,13,4,0,
17,2,16,1,1,1
17,2,17,1,31,
17,2,18,1,21,
17,2,18,4,0,
17,2,19,4,0,
17,2,28,1,1,2
17,2,28,1,5,
17,2,36,4,0,
17,2,38,4,0,
17,2,92,4,0,
17,2,97,1,40,
17,2,98,1,12,
17,2,99,4,0,
17,2,102,4,0,
17,2,104,4,0,
17,2,115,4,0,
17,2,117,4,0,
17,2,119,1,49,
17,2,129,4,0,
17,2,143,4,0,
17,2,156,4,0,
17,2,164,4,0,
17,3,16,1,1,3
17,3,16,1,9,
17,3,17,1,33,
17,3,18,1,23,
17,3,19,4,0,
17,3,28,1,1,2
17,3,28,1,5,
17,3,33,1,1,1
17,3,92,4,0,
17,3,97,1,43,
17,3,98,1,15,
17,3,104,4,0,
17,3,119,1,55,
17,3,129,4,0,
17,3,156,4,0,
17,3,168,4,0,
17,3,173,4,0,
17,3,174,4,0,
17,3,182,4,0,
17,3,189,4,0,
17,3,197,4,0,
17,3,203,4,0,
17,3,207,4,0,
17,3,211,4,0,
17,3,213,4,0,
17,3,214,4,0,
17,3,216,4,0,
17,3,218,4,0,
17,3,237,4,0,
17,3,241,4,0,
17,4,16,1,1,3
17,4,16,1,9,
17,4,17,1,33,
17,4,18,1,23,
17,4,19,4,0,
17,4,28,1,1,2
17,4,28,1,5,
17,4,33,1,1,1
17,4,92,4,0,
17,4,97,1,43,
17,4,98,1,15,
17,4,104,4,0,
17,4,119,1,55,
17,4,129,4,0,
17,4,156,4,0,
17,4,168,4,0,
17,4,173,4,0,
17,4,174,4,0,
17,4,182,4,0,
17,4,189,4,0,
17,4,197,4,0,
17,4,203,4,0,
17,4,207,4,0,
17,4,211,4,0,
17,4,213,4,0,
17,4,214,4,0,
17,4,216,4,0,
17,4,218,4,0,
17,4,237,4,0,
17,4,241,4,0,
17,5,16,1,1,3
17,5,16,1,9,
17,5,17,1,27,
17,5,18,1,20,
17,5,19,4,0,
17,5,28,1,1,2
17,5,28,1,5,
17,5,33,1,1,1
17,5,92,4,0,
17,5,97,1,43,
17,5,98,1,13,
17,5,104,4,0,
17,5,119,1,52,
17,5,156,4,0,
17,5,168,4,0,
17,5,182,4,0,
17,5,211,4,0,
17,5,213,4,0,
17,5,216,4,0,
17,5,218,4,0,
17,5,237,4,0,
17,5,240,4,0,
17,5,241,4,0,
17,5,263,4,0,
17,5,290,4,0,
17,5,297,1,34,
17,5,332,4,0,
17,6,16,1,1,3
17,6,16,1,9,
17,6,17,1,27,
17,6,18,1,20,
17,6,19,4,0,
17,6,28,1,1,2
17,6,28,1,5,
17,6,33,1,1,1
17,6,38,3,0,
17,6,92,4,0,
17,6,97,1,43,
17,6,98,1,13,
17,6,102,3,0,
17,6,104,4,0,
17,6,119,1,52,
17,6,129,3,0,
17,6,156,4,0,
17,6,164,3,0,
17,6,168,4,0,
17,6,173,3,0,
17,6,182,4,0,
17,6,189,3,0,
17,6,203,3,0,
17,6,207,3,0,
17,6,211,4,0,
17,6,213,4,0,
17,6,214,3,0,
17,6,216,4,0,
17,6,218,4,0,
17,6,237,4,0,
17,6,240,4,0,
17,6,241,4,0,
17,6,263,4,0,
17,6,290,4,0,
17,6,297,1,34,
17,6,332,4,0,
17,7,16,1,1,3
17,7,16,1,9,
17,7,17,1,27,
17,7,18,1,20,
17,7,19,4,0,
17,7,28,1,1,2
17,7,28,1,5,
17,7,33,1,1,1
17,7,38,3,0,
17,7,92,4,0,
17,7,97,1,43,
17,7,98,1,13,
17,7,102,3,0,
17,7,104,4,0,
17,7,119,1,52,
17,7,156,4,0,
17,7,164,3,0,
17,7,168,4,0,
17,7,182,4,0,
17,7,211,4,0,
17,7,213,4,0,
17,7,216,4,0,
17,7,218,4,0,
17,7,237,4,0,
17,7,240,4,0,
17,7,241,4,0,
17,7,263,4,0,
17,7,290,4,0,
17,7,297,1,34,
17,7,332,4,0,
17,8,16,1,1,3
17,8,16,1,9,
17,8,17,1,37,
17,8,18,1,17,
17,8,19,4,0,
17,8,28,1,1,2
17,8,28,1,5,
17,8,33,1,1,1
17,8,92,4,0,
17,8,97,1,32,
17,8,98,1,13,
17,8,104,4,0,
17,8,119,1,52,
17,8,156,4,0,
17,8,164,4,0,
17,8,168,4,0,
17,8,182,4,0,
17,8,203,4,0,
17,8,207,4,0,
17,8,211,4,0,
17,8,213,4,0,
17,8,214,4,0,
17,8,216,4,0,
17,8,218,4,0,
17,8,237,4,0,
17,8,239,1,22,
17,8,240,4,0,
17,8,241,4,0,
17,8,263,4,0,
17,8,290,4,0,
17,8,297,1,27,
17,8,332,4,0,
17,8,355,1,42,
17,8,355,4,0,
17,8,363,4,0,
17,8,365,4,0,
17,8,366,1,47,
17,8,369,4,0,
17,8,403,1,57,
17,8,432,4,0,
17,8,445,4,0,
17,9,16,1,1,3
17,9,16,1,9,
17,9,17,1,37,
17,9,18,1,17,
17,9,19,4,0,
17,9,28,1,1,2
17,9,28,1,5,
17,9,33,1,1,1
17,9,92,4,0,
17,9,97,1,32,
17,9,98,1,13,
17,9,104,4,0,
17,9,119,1,52,
17,9,129,3,0,
17,9,156,4,0,
17,9,164,4,0,
17,9,168,4,0,
17,9,173,3,0,
17,9,182,4,0,
17,9,189,3,0,
17,9,203,4,0,
17,9,207,4,0,
17,9,211,4,0,
17,9,213,4,0,
17,9,214,4,0,
17,9,216,4,0,
17,9,218,4,0,
17,9,237,4,0,
17,9,239,1,22,
17,9,239,3,0,
17,9,240,4,0,
17,9,241,4,0,
17,9,253,3,0,
17,9,257,3,0,
17,9,263,4,0,
17,9,290,4,0,
17,9,297,1,27,
17,9,314,3,0,
17,9,332,4,0,
17,9,355,1,42,
17,9,355,4,0,
17,9,363,4,0,
17,9,365,4,0,
17,9,366,1,47,
17,9,369,4,0,
17,9,403,1,57,
17,9,432,4,0,
17,9,445,4,0,
17,9,466,3,0,
17,10,16,1,1,3
17,10,16,1,9,
17,10,17,1,37,
17,10,18,1,17,
17,10,19,4,0,
17,10,28,1,1,2
17,10,28,1,5,
17,10,33,1,1,1
17,10,92,4,0,
17,10,97,1,32,
17,10,98,1,13,
17,10,104,4,0,
17,10,119,1,52,
17,10,129,3,0,
17,10,156,4,0,
17,10,164,4,0,
17,10,168,4,0,
17,10,173,3,0,
17,10,182,4,0,
17,10,189,3,0,
17,10,203,4,0,
17,10,207,4,0,
17,10,211,4,0,
17,10,213,4,0,
17,10,214,4,0,
17,10,216,4,0,
17,10,218,4,0,
17,10,237,4,0,
17,10,239,1,22,
17,10,239,3,0,
17,10,240,4,0,
17,10,241,4,0,
17,10,253,3,0,
17,10,257,3,0,
17,10,263,4,0,
17,10,290,4,0,
17,10,297,1,27,
17,10,314,3,0,
17,10,332,4,0,
17,10,355,1,42,
17,10,355,4,0,
17,10,363,4,0,
17,10,365,4,0,
17,10,366,1,47,
17,10,366,3,0,
17,10,369,4,0,
17,10,403,1,57,
17,10,445,4,0,
17,10,466,3,0,
17,11,16,1,1,3
17,11,16,1,9,
17,11,17,1,37,
17,11,18,1,17,
17,11,19,4,0,
17,11,28,1,1,2
17,11,28,1,5,
17,11,33,1,1,1
17,11,92,4,0,
17,11,97,1,32,
17,11,98,1,13,
17,11,104,4,0,
17,11,119,1,52,
17,11,156,4,0,
17,11,164,4,0,
17,11,168,4,0,
17,11,182,4,0,
17,11,207,4,0,
17,11,213,4,0,
17,11,216,4,0,
17,11,218,4,0,
17,11,237,4,0,
17,11,239,1,22,
17,11,240,4,0,
17,11,241,4,0,
17,11,263,4,0,
17,11,297,1,27,
17,11,332,4,0,
17,11,355,1,42,
17,11,365,4,0,
17,11,366,1,47,
17,11,369,4,0,
17,11,403,1,57,
17,11,496,4,0,
17,11,526,4,0,
17,11,542,1,62,
17,12,16,1,1,3
17,12,16,1,9,
17,12,17,1,27,
17,12,18,1,20,
17,12,19,4,0,
17,12,28,1,1,2
17,12,28,1,5,
17,12,33,1,1,1
17,12,92,4,0,
17,12,97,1,43,
17,12,98,1,13,
17,12,104,4,0,
17,12,119,1,52,
17,12,156,4,0,
17,12,168,4,0,
17,12,182,4,0,
17,12,211,4,0,
17,12,213,4,0,
17,12,216,4,0,
17,12,218,4,0,
17,12,237,4,0,
17,12,240,4,0,
17,12,241,4,0,
17,12,263,4,0,
17,12,290,4,0,
17,12,297,1,34,
17,12,332,4,0,
17,13,16,1,1,3
17,13,16,1,9,
17,13,17,1,27,
17,13,18,1,20,
17,13,19,4,0,
17,13,28,1,1,2
17,13,28,1,5,
17,13,33,1,1,1
17,13,38,3,0,
17,13,92,4,0,
17,13,97,1,43,
17,13,98,1,13,
17,13,102,3,0,
17,13,104,4,0,
17,13,119,1,52,
17,13,143,3,0,
17,13,156,4,0,
17,13,164,3,0,
17,13,168,4,0,
17,13,182,4,0,
17,13,207,3,0,
17,13,211,4,0,
17,13,213,4,0,
17,13,216,4,0,
17,13,218,4,0,
17,13,237,4,0,
17,13,240,4,0,
17,13,241,4,0,
17,13,263,4,0,
17,13,290,4,0,
17,13,297,1,34,
17,13,332,4,0,
17,14,16,1,1,3
17,14,16,1,9,
17,14,17,1,37,
17,14,18,1,17,
17,14,19,4,0,
17,14,28,1,1,2
17,14,28,1,5,
17,14,33,1,1,1
17,14,92,4,0,
17,14,97,1,32,
17,14,98,1,13,
17,14,104,4,0,
17,14,119,1,52,
17,14,156,4,0,
17,14,164,4,0,
17,14,168,4,0,
17,14,173,3,0,
17,14,182,4,0,
17,14,207,4,0,
17,14,213,4,0,
17,14,214,3,0,
17,14,216,4,0,
17,14,218,4,0,
17,14,237,4,0,
17,14,239,1,22,
17,14,240,4,0,
17,14,241,4,0,
17,14,253,3,0,
17,14,257,3,0,
17,14,263,4,0,
17,14,297,1,27,
17,14,332,4,0,
17,14,355,1,42,
17,14,355,3,0,
17,14,365,4,0,
17,14,366,1,47,
17,14,366,3,0,
17,14,369,4,0,
17,14,403,1,57,
17,14,496,4,0,
17,14,526,4,0,
17,14,542,1,62,
17,15,16,1,1,3
17,15,16,1,9,
17,15,17,1,37,
17,15,18,1,17,
17,15,19,4,0,
17,15,28,1,1,2
17,15,28,1,5,
17,15,33,1,1,1
17,15,92,4,0,
17,15,97,1,32,
17,15,98,1,13,
17,15,104,4,0,
17,15,119,1,52,
17,15,156,4,0,
17,15,164,4,0,
17,15,168,4,0,
17,15,182,4,0,
17,15,207,4,0,
17,15,211,4,0,
17,15,213,4,0,
17,15,214,4,0,
17,15,216,4,0,
17,15,218,4,0,
17,15,237,4,0,
17,15,239,1,22,
17,15,240,4,0,
17,15,241,4,0,
17,15,263,4,0,
17,15,297,1,27,
17,15,332,4,0,
17,15,355,1,42,
17,15,355,4,0,
17,15,366,1,47,
17,15,369,4,0,
17,15,403,1,57,
17,15,496,4,0,
17,15,542,1,62,
17,15,590,4,0,
17,16,16,1,1,3
17,16,16,1,9,1
17,16,17,1,37,1
17,16,18,1,17,1
17,16,19,4,0,
17,16,28,1,1,2
17,16,28,1,5,1
17,16,33,1,1,1
17,16,92,4,0,
17,16,97,1,32,1
17,16,98,1,13,1
17,16,104,4,0,
17,16,119,1,52,1
17,16,143,3,0,
17,16,156,4,0,
17,16,164,4,0,
17,16,168,4,0,
17,16,173,3,0,
17,16,182,4,0,
17,16,207,4,0,
17,16,211,4,0,
17,16,213,4,0,
17,16,214,4,0,
17,16,216,4,0,
17,16,218,4,0,
17,16,237,4,0,
17,16,239,1,22,1
17,16,240,4,0,
17,16,241,4,0,
17,16,253,3,0,
17,16,257,3,0,
17,16,263,4,0,
17,16,290,4,0,
17,16,297,1,27,1
17,16,332,4,0,
17,16,355,1,42,1
17,16,355,4,0,
17,16,366,1,47,1
17,16,366,3,0,
17,16,369,4,0,
17,16,403,1,57,1
17,16,496,4,0,
17,16,542,1,62,1
17,16,590,4,0,
17,17,16,1,1,3
17,17,16,1,9,
17,17,17,1,37,
17,17,18,1,17,
17,17,19,4,0,
17,17,28,1,1,2
17,17,28,1,5,
17,17,33,1,1,1
17,17,92,4,0,
17,17,97,1,32,
17,17,98,1,13,
17,17,104,4,0,
17,17,119,1,52,
17,17,156,4,0,
17,17,164,4,0,
17,17,168,4,0,
17,17,182,4,0,
17,17,207,4,0,
17,17,211,4,0,
17,17,213,4,0,
17,17,214,4,0,
17,17,216,4,0,
17,17,218,4,0,
17,17,237,4,0,
17,17,239,1,22,
17,17,240,4,0,
17,17,241,4,0,
17,17,263,4,0,
17,17,297,1,27,
17,17,332,4,0,
17,17,355,1,42,
17,17,355,4,0,
17,17,366,1,47,
17,17,369,4,0,
17,17,403,1,57,
17,17,496,4,0,
17,17,526,4,0,
17,17,542,1,62,
17,17,590,4,0,
17,18,16,1,1,3
17,18,16,1,9,
17,18,17,1,37,
17,18,18,1,17,
17,18,19,4,0,
17,18,28,1,1,2
17,18,28,1,5,
17,18,33,1,1,1
17,18,92,4,0,
17,18,97,1,32,
17,18,98,1,13,
17,18,104,4,0,
17,18,119,1,52,
17,18,156,4,0,
17,18,164,4,0,
17,18,168,4,0,
17,18,182,4,0,
17,18,207,4,0,
17,18,211,4,0,
17,18,213,4,0,
17,18,214,4,0,
17,18,216,4,0,
17,18,218,4,0,
17,18,237,4,0,
17,18,239,1,22,
17,18,240,4,0,
17,18,241,4,0,
17,18,263,4,0,
17,18,297,1,27,
17,18,332,4,0,
17,18,355,1,42,
17,18,355,4,0,
17,18,366,1,47,
17,18,369,4,0,
17,18,403,1,57,
17,18,496,4,0,
17,18,526,4,0,
17,18,542,1,62,
17,18,590,4,0,
18,1,13,4,0,
18,1,16,1,1,1
18,1,17,1,31,
18,1,18,1,21,
18,1,18,4,0,
18,1,19,4,0,
18,1,28,1,1,2
18,1,28,1,5,
18,1,36,4,0,
18,1,38,4,0,
18,1,63,4,0,
18,1,92,4,0,
18,1,97,1,44,
18,1,98,1,1,3
18,1,98,1,12,
18,1,99,4,0,
18,1,102,4,0,
18,1,104,4,0,
18,1,115,4,0,
18,1,117,4,0,
18,1,119,1,54,
18,1,129,4,0,
18,1,143,4,0,
18,1,156,4,0,
18,1,164,4,0,
18,2,13,4,0,
18,2,16,1,1,1
18,2,17,1,31,
18,2,18,1,21,
18,2,18,4,0,
18,2,19,4,0,
18,2,28,1,1,2
18,2,28,1,5,
18,2,36,4,0,
18,2,38,4,0,
18,2,63,4,0,
18,2,92,4,0,
18,2,97,1,44,
18,2,98,1,1,3
18,2,98,1,12,
18,2,99,4,0,
18,2,102,4,0,
18,2,104,4,0,
18,2,115,4,0,
18,2,117,4,0,
18,2,119,1,54,
18,2,129,4,0,
18,2,143,4,0,
18,2,156,4,0,
18,2,164,4,0,
18,3,16,1,1,3
18,3,16,1,9,
18,3,17,1,33,
18,3,18,1,23,
18,3,19,4,0,
18,3,28,1,1,2
18,3,28,1,5,
18,3,33,1,1,1
18,3,63,4,0,
18,3,92,4,0,
18,3,97,1,46,
18,3,98,1,1,4
18,3,98,1,15,
18,3,104,4,0,
18,3,119,1,61,
18,3,129,4,0,
18,3,156,4,0,
18,3,168,4,0,
18,3,173,4,0,
18,3,174,4,0,
18,3,182,4,0,
18,3,189,4,0,
18,3,197,4,0,
18,3,203,4,0,
18,3,207,4,0,
18,3,211,4,0,
18,3,213,4,0,
18,3,214,4,0,
18,3,216,4,0,
18,3,218,4,0,
18,3,237,4,0,
18,3,241,4,0,
18,4,16,1,1,3
18,4,16,1,9,
18,4,17,1,33,
18,4,18,1,23,
18,4,19,4,0,
18,4,28,1,1,2
18,4,28,1,5,
18,4,33,1,1,1
18,4,63,4,0,
18,4,92,4,0,
18,4,97,1,46,
18,4,98,1,1,4
18,4,98,1,15,
18,4,104,4,0,
18,4,119,1,61,
18,4,129,4,0,
18,4,156,4,0,
18,4,168,4,0,
18,4,173,4,0,
18,4,174,4,0,
18,4,182,4,0,
18,4,189,4,0,
18,4,197,4,0,
18,4,203,4,0,
18,4,207,4,0,
18,4,211,4,0,
18,4,213,4,0,
18,4,214,4,0,
18,4,216,4,0,
18,4,218,4,0,
18,4,237,4,0,
18,4,241,4,0,
18,5,16,1,1,3
18,5,16,1,9,
18,5,17,1,27,
18,5,18,1,20,
18,5,19,4,0,
18,5,28,1,1,2
18,5,28,1,5,
18,5,33,1,1,1
18,5,63,4,0,
18,5,92,4,0,
18,5,97,1,48,
18,5,98,1,1,4
18,5,98,1,13,
18,5,104,4,0,
18,5,119,1,62,
18,5,156,4,0,
18,5,168,4,0,
18,5,182,4,0,
18,5,211,4,0,
18,5,213,4,0,
18,5,216,4,0,
18,5,218,4,0,
18,5,237,4,0,
18,5,240,4,0,
18,5,241,4,0,
18,5,263,4,0,
18,5,290,4,0,
18,5,297,1,34,
18,5,332,4,0,
18,6,16,1,1,3
18,6,16,1,9,
18,6,17,1,27,
18,6,18,1,20,
18,6,19,4,0,
18,6,28,1,1,2
18,6,28,1,5,
18,6,33,1,1,1
18,6,38,3,0,
18,6,63,4,0,
18,6,92,4,0,
18,6,97,1,48,
18,6,98,1,1,4
18,6,98,1,13,
18,6,102,3,0,
18,6,104,4,0,
18,6,119,1,62,
18,6,129,3,0,
18,6,156,4,0,
18,6,164,3,0,
18,6,168,4,0,
18,6,173,3,0,
18,6,182,4,0,
18,6,189,3,0,
18,6,203,3,0,
18,6,207,3,0,
18,6,211,4,0,
18,6,213,4,0,
18,6,214,3,0,
18,6,216,4,0,
18,6,218,4,0,
18,6,237,4,0,
18,6,240,4,0,
18,6,241,4,0,
18,6,263,4,0,
18,6,290,4,0,
18,6,297,1,34,
18,6,332,4,0,
18,7,16,1,1,3
18,7,16,1,9,
18,7,17,1,27,
18,7,18,1,20,
18,7,19,4,0,
18,7,28,1,1,2
18,7,28,1,5,
18,7,33,1,1,1
18,7,38,3,0,
18,7,63,4,0,
18,7,92,4,0,
18,7,97,1,48,
18,7,98,1,1,4
18,7,98,1,13,
18,7,102,3,0,
18,7,104,4,0,
18,7,119,1,62,
18,7,156,4,0,
18,7,164,3,0,
18,7,168,4,0,
18,7,182,4,0,
18,7,211,4,0,
18,7,213,4,0,
18,7,216,4,0,
18,7,218,4,0,
18,7,237,4,0,
18,7,240,4,0,
18,7,241,4,0,
18,7,263,4,0,
18,7,290,4,0,
18,7,297,1,34,
18,7,332,4,0,
18,8,16,1,1,3
18,8,16,1,9,
18,8,17,1,38,
18,8,18,1,17,
18,8,19,4,0,
18,8,28,1,1,2
18,8,28,1,5,
18,8,33,1,1,1
18,8,63,4,0,
18,8,92,4,0,
18,8,97,1,32,
18,8,98,1,1,4
18,8,98,1,13,
18,8,104,4,0,
18,8,119,1,56,
18,8,156,4,0,
18,8,164,4,0,
18,8,168,4,0,
18,8,182,4,0,
18,8,203,4,0,
18,8,207,4,0,
18,8,211,4,0,
18,8,213,4,0,
18,8,214,4,0,
18,8,216,4,0,
18,8,218,4,0,
18,8,237,4,0,
18,8,239,1,22,
18,8,240,4,0,
18,8,241,4,0,
18,8,263,4,0,
18,8,290,4,0,
18,8,297,1,27,
18,8,332,4,0,
18,8,355,1,44,
18,8,355,4,0,
18,8,363,4,0,
18,8,365,4,0,
18,8,366,1,50,
18,8,369,4,0,
18,8,403,1,62,
18,8,416,4,0,
18,8,432,4,0,
18,8,445,4,0,
18,9,16,1,1,3
18,9,16,1,9,
18,9,17,1,38,
18,9,18,1,17,
18,9,19,4,0,
18,9,28,1,1,2
18,9,28,1,5,
18,9,33,1,1,1
18,9,63,4,0,
18,9,92,4,0,
18,9,97,1,32,
18,9,98,1,1,4
18,9,98,1,13,
18,9,104,4,0,
18,9,119,1,56,
18,9,129,3,0,
18,9,156,4,0,
18,9,164,4,0,
18,9,168,4,0,
18,9,173,3,0,
18,9,182,4,0,
18,9,189,3,0,
18,9,203,4,0,
18,9,207,4,0,
18,9,211,4,0,
18,9,213,4,0,
18,9,214,4,0,
18,9,216,4,0,
18,9,218,4,0,
18,9,237,4,0,
18,9,239,1,22,
18,9,239,3,0,
18,9,240,4,0,
18,9,241,4,0,
18,9,253,3,0,
18,9,257,3,0,
18,9,263,4,0,
18,9,290,4,0,
18,9,297,1,27,
18,9,314,3,0,
18,9,332,4,0,
18,9,355,1,44,
18,9,355,4,0,
18,9,363,4,0,
18,9,365,4,0,
18,9,366,1,50,
18,9,369,4,0,
18,9,403,1,62,
18,9,416,4,0,
18,9,432,4,0,
18,9,445,4,0,
18,9,466,3,0,
18,10,16,1,1,3
18,10,16,1,9,
18,10,17,1,38,
18,10,18,1,17,
18,10,19,4,0,
18,10,28,1,1,2
18,10,28,1,5,
18,10,33,1,1,1
18,10,63,4,0,
18,10,92,4,0,
18,10,97,1,32,
18,10,98,1,1,4
18,10,98,1,13,
18,10,104,4,0,
18,10,119,1,56,
18,10,129,3,0,
18,10,143,3,0,
18,10,156,4,0,
18,10,164,4,0,
18,10,168,4,0,
18,10,173,3,0,
18,10,182,4,0,
18,10,189,3,0,
18,10,203,4,0,
18,10,207,4,0,
18,10,211,4,0,
18,10,213,4,0,
18,10,214,4,0,
18,10,216,4,0,
18,10,218,4,0,
18,10,237,4,0,
18,10,239,1,22,
18,10,239,3,0,
18,10,240,4,0,
18,10,241,4,0,
18,10,253,3,0,
18,10,257,3,0,
18,10,263,4,0,
18,10,290,4,0,
18,10,297,1,27,
18,10,314,3,0,
18,10,332,4,0,
18,10,355,1,44,
18,10,355,4,0,
18,10,363,4,0,
18,10,365,4,0,
18,10,366,1,50,
18,10,366,3,0,
18,10,369,4,0,
18,10,403,1,62,
18,10,416,4,0,
18,10,445,4,0,
18,10,466,3,0,
18,11,16,1,1,3
18,11,16,1,9,
18,11,17,1,38,
18,11,18,1,17,
18,11,19,4,0,
18,11,28,1,1,2
18,11,28,1,5,
18,11,33,1,1,1
18,11,63,4,0,
18,11,92,4,0,
18,11,97,1,32,
18,11,98,1,1,4
18,11,98,1,13,
18,11,104,4,0,
18,11,119,1,56,
18,11,156,4,0,
18,11,164,4,0,
18,11,168,4,0,
18,11,182,4,0,
18,11,207,4,0,
18,11,213,4,0,
18,11,216,4,0,
18,11,218,4,0,
18,11,237,4,0,
18,11,239,1,22,
18,11,240,4,0,
18,11,241,4,0,
18,11,263,4,0,
18,11,297,1,27,
18,11,332,4,0,
18,11,355,1,44,
18,11,365,4,0,
18,11,366,1,50,
18,11,369,4,0,
18,11,403,1,62,
18,11,416,4,0,
18,11,496,4,0,
18,11,526,4,0,
18,11,542,1,68,
18,12,16,1,1,3
18,12,16,1,9,
18,12,17,1,27,
18,12,18,1,20,
18,12,19,4,0,
18,12,28,1,1,2
18,12,28,1,5,
18,12,33,1,1,1
18,12,63,4,0,
18,12,92,4,0,
18,12,97,1,48,
18,12,98,1,1,4
18,12,98,1,13,
18,12,104,4,0,
18,12,119,1,62,
18,12,156,4,0,
18,12,168,4,0,
18,12,182,4,0,
18,12,211,4,0,
18,12,213,4,0,
18,12,216,4,0,
18,12,218,4,0,
18,12,237,4,0,
18,12,240,4,0,
18,12,241,4,0,
18,12,263,4,0,
18,12,290,4,0,
18,12,297,1,34,
18,12,332,4,0,
18,13,16,1,1,3
18,13,16,1,9,
18,13,17,1,27,
18,13,18,1,20,
18,13,19,4,0,
18,13,28,1,1,2
18,13,28,1,5,
18,13,33,1,1,1
18,13,38,3,0,
18,13,63,4,0,
18,13,92,4,0,
18,13,97,1,48,
18,13,98,1,1,4
18,13,98,1,13,
18,13,102,3,0,
18,13,104,4,0,
18,13,119,1,62,
18,13,143,3,0,
18,13,156,4,0,
18,13,164,3,0,
18,13,168,4,0,
18,13,182,4,0,
18,13,207,3,0,
18,13,211,4,0,
18,13,213,4,0,
18,13,216,4,0,
18,13,218,4,0,
18,13,237,4,0,
18,13,240,4,0,
18,13,241,4,0,
18,13,263,4,0,
18,13,290,4,0,
18,13,297,1,34,
18,13,332,4,0,
18,14,16,1,1,3
18,14,16,1,9,
18,14,17,1,38,
18,14,18,1,17,
18,14,19,4,0,
18,14,28,1,1,2
18,14,28,1,5,
18,14,33,1,1,1
18,14,63,4,0,
18,14,92,4,0,
18,14,97,1,32,
18,14,98,1,1,4
18,14,98,1,13,
18,14,104,4,0,
18,14,119,1,56,
18,14,143,3,0,
18,14,156,4,0,
18,14,164,4,0,
18,14,168,4,0,
18,14,173,3,0,
18,14,182,4,0,
18,14,207,4,0,
18,14,213,4,0,
18,14,214,3,0,
18,14,216,4,0,
18,14,218,4,0,
18,14,237,4,0,
18,14,239,1,22,
18,14,240,4,0,
18,14,241,4,0,
18,14,253,3,0,
18,14,257,3,0,
18,14,263,4,0,
18,14,297,1,27,
18,14,332,4,0,
18,14,355,1,44,
18,14,355,3,0,
18,14,365,4,0,
18,14,366,1,50,
18,14,366,3,0,
18,14,369,4,0,
18,14,403,1,62,
18,14,416,4,0,
18,14,496,4,0,
18,14,526,4,0,
18,14,542,1,68,
18,15,16,1,1,4
18,15,16,1,9,
18,15,17,1,38,
18,15,18,1,17,
18,15,19,4,0,
18,15,28,1,1,3
18,15,28,1,5,
18,15,33,1,1,2
18,15,63,4,0,
18,15,92,4,0,
18,15,97,1,32,
18,15,98,1,1,5
18,15,98,1,13,
18,15,104,4,0,
18,15,119,1,56,
18,15,156,4,0,
18,15,164,4,0,
18,15,168,4,0,
18,15,182,4,0,
18,15,207,4,0,
18,15,211,4,0,
18,15,213,4,0,
18,15,214,4,0,
18,15,216,4,0,
18,15,218,4,0,
18,15,237,4,0,
18,15,239,1,22,
18,15,240,4,0,
18,15,241,4,0,
18,15,263,4,0,
18,15,297,1,27,
18,15,332,4,0,
18,15,355,1,44,
18,15,355,4,0,
18,15,366,1,50,
18,15,369,4,0,
18,15,403,1,62,
18,15,416,4,0,
18,15,496,4,0,
18,15,542,1,1,1
18,15,542,1,68,
18,15,590,4,0,
18,16,16,1,1,4
18,16,16,1,9,1
18,16,17,1,38,1
18,16,18,1,17,1
18,16,19,4,0,
18,16,28,1,1,3
18,16,28,1,5,1
18,16,33,1,1,2
18,16,63,4,0,
18,16,92,4,0,
18,16,97,1,32,1
18,16,98,1,1,5
18,16,98,1,13,1
18,16,104,4,0,
18,16,119,1,56,1
18,16,143,3,0,
18,16,156,4,0,
18,16,164,4,0,
18,16,168,4,0,
18,16,173,3,0,
18,16,182,4,0,
18,16,207,4,0,
18,16,211,4,0,
18,16,213,4,0,
18,16,214,4,0,
18,16,216,4,0,
18,16,218,4,0,
18,16,237,4,0,
18,16,239,1,22,1
18,16,240,4,0,
18,16,241,4,0,
18,16,253,3,0,
18,16,257,3,0,
18,16,263,4,0,
18,16,290,4,0,
18,16,297,1,27,1
18,16,332,4,0,
18,16,355,1,44,1
18,16,355,4,0,
18,16,366,1,50,1
18,16,366,3,0,
18,16,369,4,0,
18,16,403,1,62,1
18,16,416,4,0,
18,16,496,4,0,
18,16,542,1,1,1
18,16,542,1,68,1
18,16,590,4,0,
18,17,16,1,1,4
18,17,16,1,9,
18,17,17,1,38,
18,17,18,1,17,
18,17,19,4,0,
18,17,28,1,1,3
18,17,28,1,5,
18,17,33,1,1,2
18,17,63,4,0,
18,17,92,4,0,
18,17,97,1,32,
18,17,98,1,1,5
18,17,98,1,13,
18,17,104,4,0,
18,17,119,1,56,
18,17,156,4,0,
18,17,164,4,0,
18,17,168,4,0,
18,17,182,4,0,
18,17,207,4,0,
18,17,211,4,0,
18,17,213,4,0,
18,17,214,4,0,
18,17,216,4,0,
18,17,218,4,0,
18,17,237,4,0,
18,17,239,1,22,
18,17,240,4,0,
18,17,241,4,0,
18,17,263,4,0,
18,17,297,1,27,
18,17,332,4,0,
18,17,355,1,44,
18,17,355,4,0,
18,17,366,1,50,
18,17,369,4,0,
18,17,403,1,62,
18,17,416,4,0,
18,17,496,4,0,
18,17,526,4,0,
18,17,542,1,1,1
18,17,542,1,68,
18,17,590,4,0,
18,18,16,1,1,4
18,18,16,1,9,
18,18,17,1,38,
18,18,18,1,17,
18,18,19,4,0,
18,18,28,1,1,3
18,18,28,1,5,
18,18,33,1,1,2
18,18,63,4,0,
18,18,92,4,0,
18,18,97,1,32,
18,18,98,1,1,5
18,18,98,1,13,
18,18,104,4,0,
18,18,119,1,56,
18,18,156,4,0,
18,18,164,4,0,
18,18,168,4,0,
18,18,182,4,0,
18,18,207,4,0,
18,18,211,4,0,
18,18,213,4,0,
18,18,214,4,0,
18,18,216,4,0,
18,18,218,4,0,
18,18,237,4,0,
18,18,239,1,22,
18,18,240,4,0,
18,18,241,4,0,
18,18,263,4,0,
18,18,297,1,27,
18,18,332,4,0,
18,18,355,1,44,
18,18,355,4,0,
18,18,366,1,50,
18,18,369,4,0,
18,18,403,1,62,
18,18,416,4,0,
18,18,496,4,0,
18,18,526,4,0,
18,18,542,1,1,1
18,18,542,1,68,
18,18,590,4,0,
19,1,33,1,1,1
19,1,34,4,0,
19,1,36,4,0,
19,1,38,4,0,
19,1,39,1,1,2
19,1,55,4,0,
19,1,59,4,0,
19,1,61,4,0,
19,1,85,4,0,
19,1,87,4,0,
19,1,91,4,0,
19,1,92,4,0,
19,1,98,1,7,
19,1,99,4,0,
19,1,102,4,0,
19,1,104,4,0,
19,1,116,1,23,
19,1,117,4,0,
19,1,129,4,0,
19,1,130,4,0,
19,1,156,4,0,
19,1,158,1,14,
19,1,162,1,34,
19,1,164,4,0,
19,2,33,1,1,1
19,2,34,4,0,
19,2,36,4,0,
19,2,38,4,0,
19,2,39,1,1,2
19,2,55,4,0,
19,2,59,4,0,
19,2,61,4,0,
19,2,85,4,0,
19,2,87,4,0,
19,2,91,4,0,
19,2,92,4,0,
19,2,98,1,7,
19,2,99,4,0,
19,2,102,4,0,
19,2,104,4,0,
19,2,116,1,23,
19,2,117,4,0,
19,2,129,4,0,
19,2,130,4,0,
19,2,156,4,0,
19,2,158,1,14,
19,2,162,1,34,
19,2,164,4,0,
19,3,29,4,0,
19,3,33,1,1,1
19,3,39,1,1,2
19,3,44,2,0,
19,3,59,4,0,
19,3,68,2,0,
19,3,87,4,0,
19,3,91,4,0,
19,3,92,4,0,
19,3,98,1,7,
19,3,103,2,0,
19,3,104,4,0,
19,3,111,4,0,
19,3,116,1,20,
19,3,129,4,0,
19,3,154,2,0,
19,3,156,4,0,
19,3,158,1,13,
19,3,162,1,34,
19,3,168,4,0,
19,3,172,2,0,
19,3,173,4,0,
19,3,174,4,0,
19,3,179,2,0,
19,3,182,4,0,
19,3,189,4,0,
19,3,196,4,0,
19,3,203,4,0,
19,3,207,4,0,
19,3,213,4,0,
19,3,214,4,0,
19,3,216,4,0,
19,3,218,4,0,
19,3,228,1,27,
19,3,231,4,0,
19,3,237,4,0,
19,3,241,4,0,
19,3,247,4,0,
19,3,249,4,0,
19,4,29,4,0,
19,4,33,1,1,1
19,4,39,1,1,2
19,4,44,2,0,
19,4,59,4,0,
19,4,68,2,0,
19,4,87,4,0,
19,4,91,4,0,
19,4,92,4,0,
19,4,98,1,7,
19,4,103,2,0,
19,4,104,4,0,
19,4,111,4,0,
19,4,116,1,20,
19,4,129,4,0,
19,4,154,2,0,
19,4,156,4,0,
19,4,158,1,13,
19,4,162,1,34,
19,4,168,4,0,
19,4,172,2,0,
19,4,173,4,0,
19,4,174,4,0,
19,4,179,2,0,
19,4,182,4,0,
19,4,189,4,0,
19,4,196,4,0,
19,4,203,4,0,
19,4,207,4,0,
19,4,213,4,0,
19,4,214,4,0,
19,4,216,4,0,
19,4,218,4,0,
19,4,228,1,27,
19,4,231,4,0,
19,4,237,4,0,
19,4,241,4,0,
19,4,247,4,0,
19,4,249,4,0,
19,5,15,4,0,
19,5,33,1,1,1
19,5,39,1,1,2
19,5,44,2,0,
19,5,58,4,0,
19,5,59,4,0,
19,5,68,2,0,
19,5,85,4,0,
19,5,87,4,0,
19,5,91,4,0,
19,5,92,4,0,
19,5,98,1,7,
19,5,103,2,0,
19,5,104,4,0,
19,5,116,1,20,
19,5,154,2,0,
19,5,156,4,0,
19,5,158,1,13,
19,5,162,1,34,
19,5,168,4,0,
19,5,172,2,0,
19,5,179,2,0,
19,5,182,4,0,
19,5,207,2,0,
19,5,213,4,0,
19,5,216,4,0,
19,5,218,4,0,
19,5,228,1,27,
19,5,231,4,0,
19,5,237,4,0,
19,5,240,4,0,
19,5,241,4,0,
19,5,247,4,0,
19,5,249,4,0,
19,5,253,2,0,
19,5,263,4,0,
19,5,269,4,0,
19,5,283,1,41,
19,5,290,4,0,
19,5,351,4,0,
19,6,15,4,0,
19,6,33,1,1,1
19,6,34,3,0,
19,6,38,3,0,
19,6,39,1,1,2
19,6,44,2,0,
19,6,58,4,0,
19,6,59,4,0,
19,6,68,2,0,
19,6,68,3,0,
19,6,85,4,0,
19,6,86,3,0,
19,6,87,4,0,
19,6,91,4,0,
19,6,92,4,0,
19,6,98,1,7,
19,6,102,3,0,
19,6,103,2,0,
19,6,104,4,0,
19,6,111,3,0,
19,6,116,1,20,
19,6,129,3,0,
19,6,154,2,0,
19,6,156,4,0,
19,6,158,1,13,
19,6,162,1,34,
19,6,164,3,0,
19,6,168,4,0,
19,6,172,2,0,
19,6,173,3,0,
19,6,179,2,0,
19,6,182,4,0,
19,6,189,3,0,
19,6,196,3,0,
19,6,203,3,0,
19,6,207,2,0,
19,6,207,3,0,
19,6,213,4,0,
19,6,214,3,0,
19,6,216,4,0,
19,6,218,4,0,
19,6,228,1,27,
19,6,231,4,0,
19,6,237,4,0,
19,6,240,4,0,
19,6,241,4,0,
19,6,247,4,0,
19,6,249,4,0,
19,6,253,2,0,
19,6,263,4,0,
19,6,269,4,0,
19,6,283,1,41,
19,6,290,4,0,
19,6,351,4,0,
19,7,15,4,0,
19,7,33,1,1,1
19,7,34,3,0,
19,7,38,3,0,
19,7,39,1,1,2
19,7,44,2,0,
19,7,58,4,0,
19,7,59,4,0,
19,7,68,2,0,
19,7,68,3,0,
19,7,85,4,0,
19,7,86,3,0,
19,7,87,4,0,
19,7,91,4,0,
19,7,92,4,0,
19,7,98,1,7,
19,7,102,3,0,
19,7,103,2,0,
19,7,104,4,0,
19,7,116,1,20,
19,7,154,2,0,
19,7,156,4,0,
19,7,158,1,13,
19,7,162,1,34,
19,7,164,3,0,
19,7,168,4,0,
19,7,172,2,0,
19,7,179,2,0,
19,7,182,4,0,
19,7,207,2,0,
19,7,213,4,0,
19,7,216,4,0,
19,7,218,4,0,
19,7,228,1,27,
19,7,231,4,0,
19,7,237,4,0,
19,7,240,4,0,
19,7,241,4,0,
19,7,247,4,0,
19,7,249,4,0,
19,7,253,2,0,
19,7,263,4,0,
19,7,269,4,0,
19,7,283,1,41,
19,7,290,4,0,
19,7,351,4,0,
19,8,15,4,0,
19,8,33,1,1,1
19,8,38,1,31,
19,8,39,1,1,2
19,8,44,1,10,
19,8,44,2,0,
19,8,58,4,0,
19,8,59,4,0,
19,8,68,2,0,
19,8,85,4,0,
19,8,86,4,0,
19,8,87,4,0,
19,8,91,4,0,
19,8,92,4,0,
19,8,98,1,4,
19,8,103,2,0,
19,8,104,4,0,
19,8,116,1,7,
19,8,154,2,0,
19,8,156,4,0,
19,8,158,1,16,
19,8,162,1,28,
19,8,164,4,0,
19,8,168,4,0,
19,8,172,2,0,
19,8,179,2,0,
19,8,182,4,0,
19,8,203,4,0,
19,8,207,2,0,
19,8,207,4,0,
19,8,213,4,0,
19,8,214,4,0,
19,8,216,4,0,
19,8,218,4,0,
19,8,228,1,13,
19,8,231,4,0,
19,8,237,4,0,
19,8,240,4,0,
19,8,241,4,0,
19,8,242,1,22,
19,8,247,4,0,
19,8,249,4,0,
19,8,253,2,0,
19,8,263,4,0,
19,8,269,4,0,
19,8,283,1,34,
19,8,290,4,0,
19,8,351,4,0,
19,8,363,4,0,
19,8,365,4,0,
19,8,369,4,0,
19,8,372,1,25,
19,8,382,2,0,
19,8,387,2,0,
19,8,389,1,19,
19,8,445,4,0,
19,8,447,4,0,
19,8,451,4,0,
19,9,15,4,0,
19,9,33,1,1,1
19,9,38,1,31,
19,9,39,1,1,2
19,9,44,1,10,
19,9,44,2,0,
19,9,58,4,0,
19,9,59,4,0,
19,9,68,2,0,
19,9,85,4,0,
19,9,86,4,0,
19,9,87,4,0,
19,9,91,4,0,
19,9,92,4,0,
19,9,98,1,4,
19,9,103,2,0,
19,9,104,4,0,
19,9,116,1,7,
19,9,129,3,0,
19,9,154,2,0,
19,9,156,4,0,
19,9,158,1,16,
19,9,162,1,28,
19,9,164,4,0,
19,9,168,4,0,
19,9,172,2,0,
19,9,173,3,0,
19,9,179,2,0,
19,9,182,4,0,
19,9,189,3,0,
19,9,196,3,0,
19,9,203,4,0,
19,9,207,2,0,
19,9,207,4,0,
19,9,213,4,0,
19,9,214,4,0,
19,9,216,4,0,
19,9,218,4,0,
19,9,228,1,13,
19,9,231,4,0,
19,9,237,4,0,
19,9,240,4,0,
19,9,241,4,0,
19,9,242,1,22,
19,9,247,4,0,
19,9,249,4,0,
19,9,253,2,0,
19,9,263,4,0,
19,9,269,4,0,
19,9,283,1,34,
19,9,283,3,0,
19,9,290,4,0,
19,9,351,4,0,
19,9,363,4,0,
19,9,365,4,0,
19,9,369,4,0,
19,9,372,1,25,
19,9,382,2,0,
19,9,387,2,0,
19,9,387,3,0,
19,9,389,1,19,
19,9,389,3,0,
19,9,428,3,0,
19,9,445,4,0,
19,9,447,4,0,
19,9,451,4,0,
19,10,15,4,0,
19,10,29,3,0,
19,10,33,1,1,1
19,10,38,1,31,
19,10,39,1,1,2
19,10,44,1,10,
19,10,44,2,0,
19,10,58,4,0,
19,10,59,4,0,
19,10,68,2,0,
19,10,85,4,0,
19,10,86,4,0,
19,10,87,4,0,
19,10,91,4,0,
19,10,92,4,0,
19,10,98,1,4,
19,10,103,2,0,
19,10,104,4,0,
19,10,116,1,7,
19,10,129,3,0,
19,10,154,2,0,
19,10,156,4,0,
19,10,158,1,16,
19,10,162,1,28,
19,10,162,3,0,
19,10,164,4,0,
19,10,168,4,0,
19,10,172,2,0,
19,10,173,3,0,
19,10,179,2,0,
19,10,182,4,0,
19,10,189,3,0,
19,10,196,3,0,
19,10,203,4,0,
19,10,207,2,0,
19,10,207,4,0,
19,10,213,4,0,
19,10,214,4,0,
19,10,216,4,0,
19,10,218,4,0,
19,10,228,1,13,
19,10,231,4,0,
19,10,237,4,0,
19,10,240,4,0,
19,10,241,4,0,
19,10,242,1,22,
19,10,247,4,0,
19,10,249,4,0,
19,10,253,2,0,
19,10,263,4,0,
19,10,269,4,0,
19,10,283,1,34,
19,10,283,3,0,
19,10,290,4,0,
19,10,351,4,0,
19,10,363,4,0,
19,10,365,4,0,
19,10,369,4,0,
19,10,372,1,25,
19,10,382,2,0,
19,10,387,2,0,
19,10,387,3,0,
19,10,389,1,19,
19,10,389,3,0,
19,10,428,3,0,
19,10,445,4,0,
19,10,447,4,0,
19,10,451,4,0,
19,11,15,4,0,
19,11,33,1,1,1
19,11,38,1,31,
19,11,39,1,1,2
19,11,44,1,10,
19,11,44,2,0,
19,11,58,4,0,
19,11,59,4,0,
19,11,68,2,0,
19,11,85,4,0,
19,11,86,4,0,
19,11,87,4,0,
19,11,91,4,0,
19,11,92,4,0,
19,11,98,1,4,
19,11,103,2,0,
19,11,104,4,0,
19,11,116,1,7,
19,11,154,2,0,
19,11,156,4,0,
19,11,158,1,16,
19,11,162,1,28,
19,11,164,4,0,
19,11,168,4,0,
19,11,172,2,0,
19,11,179,2,0,
19,11,182,4,0,
19,11,207,4,0,
19,11,213,4,0,
19,11,216,4,0,
19,11,218,4,0,
19,11,228,1,13,
19,11,237,4,0,
19,11,240,4,0,
19,11,241,4,0,
19,11,242,1,22,
19,11,247,4,0,
19,11,249,4,0,
19,11,253,2,0,
19,11,263,4,0,
19,11,269,4,0,
19,11,279,2,0,
19,11,283,1,34,
19,11,365,4,0,
19,11,369,4,0,
19,11,372,1,25,
19,11,382,2,0,
19,11,387,2,0,
19,11,389,1,19,
19,11,447,4,0,
19,11,451,4,0,
19,11,496,4,0,
19,11,514,4,0,
19,11,515,2,0,
19,11,526,4,0,
19,11,528,4,0,
19,12,15,4,0,
19,12,33,1,1,1
19,12,39,1,1,2
19,12,58,4,0,
19,12,59,4,0,
19,12,85,4,0,
19,12,87,4,0,
19,12,91,4,0,
19,12,92,4,0,
19,12,98,1,7,
19,12,104,4,0,
19,12,116,1,20,
19,12,156,4,0,
19,12,158,1,13,
19,12,162,1,34,
19,12,168,4,0,
19,12,182,4,0,
19,12,213,4,0,
19,12,216,4,0,
19,12,218,4,0,
19,12,228,1,27,
19,12,231,4,0,
19,12,237,4,0,
19,12,240,4,0,
19,12,241,4,0,
19,12,247,4,0,
19,12,249,4,0,
19,12,263,4,0,
19,12,269,4,0,
19,12,283,1,41,
19,12,290,4,0,
19,12,351,4,0,
19,13,15,4,0,
19,13,33,1,1,1
19,13,34,3,0,
19,13,38,3,0,
19,13,39,1,1,2
19,13,58,4,0,
19,13,59,4,0,
19,13,85,4,0,
19,13,86,3,0,
19,13,87,4,0,
19,13,91,4,0,
19,13,92,4,0,
19,13,98,1,7,
19,13,102,3,0,
19,13,104,4,0,
19,13,116,1,20,
19,13,156,4,0,
19,13,158,1,13,
19,13,162,1,34,
19,13,164,3,0,
19,13,168,4,0,
19,13,182,4,0,
19,13,196,3,0,
19,13,207,3,0,
19,13,213,4,0,
19,13,216,4,0,
19,13,218,4,0,
19,13,228,1,27,
19,13,231,4,0,
19,13,237,4,0,
19,13,240,4,0,
19,13,241,4,0,
19,13,247,4,0,
19,13,249,4,0,
19,13,263,4,0,
19,13,269,4,0,
19,13,283,1,41,
19,13,290,4,0,
19,13,351,4,0,
19,14,15,4,0,
19,14,33,1,1,1
19,14,38,1,31,
19,14,39,1,1,2
19,14,44,1,10,
19,14,44,2,0,
19,14,58,4,0,
19,14,59,4,0,
19,14,68,2,0,
19,14,85,4,0,
19,14,86,4,0,
19,14,87,4,0,
19,14,91,4,0,
19,14,92,4,0,
19,14,98,1,4,
19,14,103,2,0,
19,14,104,4,0,
19,14,116,1,7,
19,14,154,2,0,
19,14,156,4,0,
19,14,158,1,16,
19,14,162,1,28,
19,14,162,3,0,
19,14,164,4,0,
19,14,168,4,0,
19,14,172,2,0,
19,14,173,3,0,
19,14,179,2,0,
19,14,182,4,0,
19,14,196,3,0,
19,14,207,4,0,
19,14,213,4,0,
19,14,214,3,0,
19,14,216,4,0,
19,14,218,4,0,
19,14,228,1,13,
19,14,231,3,0,
19,14,237,4,0,
19,14,240,4,0,
19,14,241,4,0,
19,14,242,1,22,
19,14,247,4,0,
19,14,249,4,0,
19,14,253,2,0,
19,14,253,3,0,
19,14,263,4,0,
19,14,269,4,0,
19,14,279,2,0,
19,14,283,1,34,
19,14,283,3,0,
19,14,343,3,0,
19,14,365,4,0,
19,14,369,4,0,
19,14,372,1,25,
19,14,382,2,0,
19,14,387,2,0,
19,14,387,3,0,
19,14,389,1,19,
19,14,428,3,0,
19,14,447,4,0,
19,14,451,4,0,
19,14,496,4,0,
19,14,514,4,0,
19,14,515,2,0,
19,14,526,4,0,
19,14,528,4,0,
19,15,15,4,0,
19,15,33,1,1,1
19,15,38,1,31,
19,15,39,1,1,2
19,15,44,1,10,
19,15,44,2,0,
19,15,58,4,0,
19,15,59,4,0,
19,15,68,2,0,
19,15,85,4,0,
19,15,86,4,0,
19,15,87,4,0,
19,15,91,4,0,
19,15,92,4,0,
19,15,98,1,4,
19,15,103,2,0,
19,15,104,4,0,
19,15,116,1,7,
19,15,154,2,0,
19,15,156,4,0,
19,15,158,1,16,
19,15,162,1,28,
19,15,164,4,0,
19,15,168,4,0,
19,15,172,2,0,
19,15,179,2,0,
19,15,182,4,0,
19,15,207,4,0,
19,15,213,4,0,
19,15,214,4,0,
19,15,216,4,0,
19,15,218,4,0,
19,15,228,1,13,
19,15,237,4,0,
19,15,240,4,0,
19,15,241,4,0,
19,15,242,1,22,
19,15,247,4,0,
19,15,249,4,0,
19,15,253,2,0,
19,15,263,4,0,
19,15,269,4,0,
19,15,279,2,0,
19,15,283,1,34,
19,15,369,4,0,
19,15,372,1,25,
19,15,382,2,0,
19,15,387,2,0,
19,15,389,1,19,
19,15,447,4,0,
19,15,451,4,0,
19,15,496,4,0,
19,15,514,4,0,
19,15,515,2,0,
19,15,528,4,0,
19,15,590,4,0,
19,16,15,4,0,
19,16,33,1,1,1
19,16,38,1,31,1
19,16,39,1,1,2
19,16,44,1,10,1
19,16,44,2,0,
19,16,58,4,0,
19,16,59,4,0,
19,16,68,2,0,
19,16,85,4,0,
19,16,86,4,0,
19,16,87,4,0,
19,16,91,4,0,
19,16,92,4,0,
19,16,98,1,4,1
19,16,103,2,0,
19,16,104,4,0,
19,16,116,1,7,1
19,16,154,2,0,
19,16,156,4,0,
19,16,158,1,16,1
19,16,162,1,28,1
19,16,162,3,0,
19,16,164,4,0,
19,16,168,4,0,
19,16,172,2,0,
19,16,173,3,0,
19,16,179,2,0,
19,16,182,4,0,
19,16,196,3,0,
19,16,207,4,0,
19,16,213,4,0,
19,16,214,4,0,
19,16,216,4,0,
19,16,218,4,0,
19,16,228,1,13,1
19,16,231,3,0,
19,16,237,4,0,
19,16,240,4,0,
19,16,241,4,0,
19,16,242,1,22,1
19,16,247,4,0,
19,16,249,4,0,
19,16,253,2,0,
19,16,253,3,0,
19,16,263,4,0,
19,16,269,4,0,
19,16,279,2,0,
19,16,283,1,34,1
19,16,283,3,0,
19,16,290,4,0,
19,16,343,3,0,
19,16,351,3,0,
19,16,369,4,0,
19,16,372,1,25,1
19,16,382,2,0,
19,16,387,2,0,
19,16,387,3,0,
19,16,389,1,19,1
19,16,428,3,0,
19,16,447,4,0,
19,16,451,4,0,
19,16,496,4,0,
19,16,514,4,0,
19,16,515,2,0,
19,16,528,4,0,
19,16,590,4,0,
19,17,33,1,1,1
19,17,38,1,31,
19,17,39,1,1,2
19,17,44,1,10,
19,17,44,2,0,
19,17,58,4,0,
19,17,59,4,0,
19,17,68,2,0,
19,17,85,4,0,
19,17,86,4,0,
19,17,87,4,0,
19,17,92,4,0,
19,17,98,1,4,
19,17,103,2,0,
19,17,104,4,0,
19,17,116,1,7,
19,17,154,2,0,
19,17,156,4,0,
19,17,158,1,16,
19,17,162,1,28,
19,17,164,4,0,
19,17,168,4,0,
19,17,172,2,0,
19,17,179,2,0,
19,17,182,4,0,
19,17,207,4,0,
19,17,213,4,0,
19,17,214,4,0,
19,17,216,4,0,
19,17,218,4,0,
19,17,228,1,13,
19,17,237,4,0,
19,17,240,4,0,
19,17,241,4,0,
19,17,242,1,22,
19,17,247,4,0,
19,17,253,2,0,
19,17,263,4,0,
19,17,269,4,0,
19,17,279,2,0,
19,17,283,1,34,
19,17,369,4,0,
19,17,372,1,19,
19,17,382,2,0,
19,17,387,2,0,
19,17,389,1,25,
19,17,447,4,0,
19,17,451,4,0,
19,17,496,4,0,
19,17,515,2,0,
19,17,526,4,0,
19,17,528,4,0,
19,17,590,4,0,
19,18,33,1,1,1
19,18,38,1,31,
19,18,39,1,1,2
19,18,44,1,10,
19,18,44,2,0,
19,18,58,4,0,
19,18,59,4,0,
19,18,68,2,0,
19,18,85,4,0,
19,18,86,4,0,
19,18,87,4,0,
19,18,92,4,0,
19,18,98,1,4,
19,18,103,2,0,
19,18,104,4,0,
19,18,116,1,7,
19,18,154,2,0,
19,18,156,4,0,
19,18,158,1,16,
19,18,162,1,28,
19,18,164,4,0,
19,18,168,4,0,
19,18,172,2,0,
19,18,179,2,0,
19,18,182,4,0,
19,18,207,4,0,
19,18,213,4,0,
19,18,214,4,0,
19,18,216,4,0,
19,18,218,4,0,
19,18,228,1,13,
19,18,237,4,0,
19,18,240,4,0,
19,18,241,4,0,
19,18,242,1,22,
19,18,247,4,0,
19,18,253,2,0,
19,18,263,4,0,
19,18,269,4,0,
19,18,279,2,0,
19,18,283,1,34,
19,18,369,4,0,
19,18,372,1,19,
19,18,382,2,0,
19,18,387,2,0,
19,18,389,1,25,
19,18,447,4,0,
19,18,451,4,0,
19,18,496,4,0,
19,18,515,2,0,
19,18,526,4,0,
19,18,528,4,0,
19,18,590,4,0,
20,1,33,1,1,1
20,1,34,4,0,
20,1,36,4,0,
20,1,38,4,0,
20,1,39,1,1,2
20,1,55,4,0,
20,1,58,4,0,
20,1,59,4,0,
20,1,61,4,0,
20,1,63,4,0,
20,1,85,4,0,
20,1,87,4,0,
20,1,91,4,0,
20,1,92,4,0,
20,1,98,1,1,3
20,1,98,1,7,
20,1,99,4,0,
20,1,102,4,0,
20,1,104,4,0,
20,1,116,1,27,
20,1,117,4,0,
20,1,129,4,0,
20,1,130,4,0,
20,1,156,4,0,
20,1,158,1,14,
20,1,162,1,41,
20,1,164,4,0,
20,2,33,1,1,1
20,2,34,4,0,
20,2,36,4,0,
20,2,38,4,0,
20,2,39,1,1,2
20,2,55,4,0,
20,2,58,4,0,
20,2,59,4,0,
20,2,61,4,0,
20,2,63,4,0,
20,2,85,4,0,
20,2,87,4,0,
20,2,91,4,0,
20,2,92,4,0,
20,2,98,1,1,3
20,2,98,1,7,
20,2,99,4,0,
20,2,102,4,0,
20,2,104,4,0,
20,2,116,1,27,
20,2,117,4,0,
20,2,129,4,0,
20,2,130,4,0,
20,2,156,4,0,
20,2,158,1,14,
20,2,162,1,41,
20,2,164,4,0,
20,3,15,4,0,
20,3,29,4,0,
20,3,33,1,1,1
20,3,39,1,1,2
20,3,46,4,0,
20,3,59,4,0,
20,3,63,4,0,
20,3,70,4,0,
20,3,87,4,0,
20,3,91,4,0,
20,3,92,4,0,
20,3,98,1,1,3
20,3,98,1,7,
20,3,104,4,0,
20,3,111,4,0,
20,3,129,4,0,
20,3,156,4,0,
20,3,158,1,13,
20,3,162,1,40,
20,3,168,4,0,
20,3,173,4,0,
20,3,174,4,0,
20,3,182,4,0,
20,3,184,1,20,
20,3,189,4,0,
20,3,196,4,0,
20,3,203,4,0,
20,3,207,4,0,
20,3,213,4,0,
20,3,214,4,0,
20,3,216,4,0,
20,3,218,4,0,
20,3,228,1,30,
20,3,231,4,0,
20,3,237,4,0,
20,3,241,4,0,
20,3,247,4,0,
20,3,249,4,0,
20,4,15,4,0,
20,4,29,4,0,
20,4,33,1,1,1
20,4,39,1,1,2
20,4,46,4,0,
20,4,58,3,0,
20,4,59,4,0,
20,4,63,4,0,
20,4,70,4,0,
20,4,85,3,0,
20,4,87,4,0,
20,4,91,4,0,
20,4,92,4,0,
20,4,98,1,1,3
20,4,98,1,7,
20,4,104,4,0,
20,4,111,4,0,
20,4,129,4,0,
20,4,156,4,0,
20,4,158,1,13,
20,4,162,1,40,
20,4,168,4,0,
20,4,173,4,0,
20,4,174,4,0,
20,4,182,4,0,
20,4,184,1,20,
20,4,189,4,0,
20,4,196,4,0,
20,4,203,4,0,
20,4,207,4,0,
20,4,213,4,0,
20,4,214,4,0,
20,4,216,4,0,
20,4,218,4,0,
20,4,228,1,30,
20,4,231,4,0,
20,4,237,4,0,
20,4,241,4,0,
20,4,247,4,0,
20,4,249,4,0,
20,5,15,4,0,
20,5,33,1,1,1
20,5,39,1,1,2
20,5,46,4,0,
20,5,58,4,0,
20,5,59,4,0,
20,5,63,4,0,
20,5,70,4,0,
20,5,85,4,0,
20,5,87,4,0,
20,5,91,4,0,
20,5,92,4,0,
20,5,98,1,1,3
20,5,98,1,7,
20,5,104,4,0,
20,5,156,4,0,
20,5,158,1,13,
20,5,162,1,40,
20,5,168,4,0,
20,5,182,4,0,
20,5,184,1,20,
20,5,213,4,0,
20,5,216,4,0,
20,5,218,4,0,
20,5,228,1,30,
20,5,231,4,0,
20,5,237,4,0,
20,5,240,4,0,
20,5,241,4,0,
20,5,247,4,0,
20,5,249,4,0,
20,5,263,4,0,
20,5,269,4,0,
20,5,283,1,50,
20,5,290,4,0,
20,5,351,4,0,
20,6,15,4,0,
20,6,33,1,1,1
20,6,34,3,0,
20,6,38,3,0,
20,6,39,1,1,2
20,6,46,4,0,
20,6,58,4,0,
20,6,59,4,0,
20,6,63,4,0,
20,6,68,3,0,
20,6,70,4,0,
20,6,85,4,0,
20,6,86,3,0,
20,6,87,4,0,
20,6,91,4,0,
20,6,92,4,0,
20,6,98,1,1,3
20,6,98,1,7,
20,6,102,3,0,
20,6,104,4,0,
20,6,111,3,0,
20,6,129,3,0,
20,6,156,4,0,
20,6,158,1,13,
20,6,162,1,40,
20,6,164,3,0,
20,6,168,4,0,
20,6,173,3,0,
20,6,182,4,0,
20,6,184,1,20,
20,6,189,3,0,
20,6,196,3,0,
20,6,203,3,0,
20,6,207,3,0,
20,6,213,4,0,
20,6,214,3,0,
20,6,216,4,0,
20,6,218,4,0,
20,6,228,1,30,
20,6,231,4,0,
20,6,237,4,0,
20,6,240,4,0,
20,6,241,4,0,
20,6,247,4,0,
20,6,249,4,0,
20,6,263,4,0,
20,6,269,4,0,
20,6,283,1,50,
20,6,290,4,0,
20,6,351,4,0,
20,7,15,4,0,
20,7,33,1,1,1
20,7,34,3,0,
20,7,38,3,0,
20,7,39,1,1,2
20,7,46,4,0,
20,7,58,4,0,
20,7,59,4,0,
20,7,63,4,0,
20,7,68,3,0,
20,7,70,4,0,
20,7,85,4,0,
20,7,86,3,0,
20,7,87,4,0,
20,7,91,4,0,
20,7,92,4,0,
20,7,98,1,1,3
20,7,98,1,7,
20,7,102,3,0,
20,7,104,4,0,
20,7,156,4,0,
20,7,158,1,13,
20,7,162,1,40,
20,7,164,3,0,
20,7,168,4,0,
20,7,182,4,0,
20,7,184,1,20,
20,7,213,4,0,
20,7,216,4,0,
20,7,218,4,0,
20,7,228,1,30,
20,7,231,4,0,
20,7,237,4,0,
20,7,240,4,0,
20,7,241,4,0,
20,7,247,4,0,
20,7,249,4,0,
20,7,263,4,0,
20,7,269,4,0,
20,7,283,1,50,
20,7,290,4,0,
20,7,351,4,0,
20,8,14,1,1,1
20,8,14,4,0,
20,8,15,4,0,
20,8,33,1,1,2
20,8,38,1,39,
20,8,39,1,1,3
20,8,44,1,10,
20,8,46,4,0,
20,8,58,4,0,
20,8,59,4,0,
20,8,63,4,0,
20,8,70,4,0,
20,8,85,4,0,
20,8,86,4,0,
20,8,87,4,0,
20,8,91,4,0,
20,8,92,4,0,
20,8,98,1,1,4
20,8,98,1,4,
20,8,104,4,0,
20,8,116,1,1,5
20,8,116,1,7,
20,8,156,4,0,
20,8,158,1,16,
20,8,162,1,34,
20,8,164,4,0,
20,8,168,4,0,
20,8,182,4,0,
20,8,184,1,20,
20,8,203,4,0,
20,8,207,4,0,
20,8,213,4,0,
20,8,214,4,0,
20,8,216,4,0,
20,8,218,4,0,
20,8,228,1,13,
20,8,231,4,0,
20,8,237,4,0,
20,8,240,4,0,
20,8,241,4,0,
20,8,242,1,24,
20,8,247,4,0,
20,8,249,4,0,
20,8,263,4,0,
20,8,269,4,0,
20,8,283,1,44,
20,8,290,4,0,
20,8,351,4,0,
20,8,363,4,0,
20,8,365,4,0,
20,8,369,4,0,
20,8,372,1,29,
20,8,389,1,19,
20,8,416,4,0,
20,8,445,4,0,
20,8,447,4,0,
20,8,451,4,0,
20,9,14,1,1,1
20,9,14,4,0,
20,9,15,4,0,
20,9,33,1,1,2
20,9,38,1,39,
20,9,39,1,1,3
20,9,44,1,10,
20,9,46,4,0,
20,9,58,4,0,
20,9,59,4,0,
20,9,63,4,0,
20,9,70,4,0,
20,9,85,4,0,
20,9,86,4,0,
20,9,87,4,0,
20,9,91,4,0,
20,9,92,4,0,
20,9,98,1,1,4
20,9,98,1,4,
20,9,104,4,0,
20,9,116,1,1,5
20,9,116,1,7,
20,9,129,3,0,
20,9,156,4,0,
20,9,158,1,16,
20,9,162,1,34,
20,9,164,4,0,
20,9,168,4,0,
20,9,173,3,0,
20,9,182,4,0,
20,9,184,1,20,
20,9,189,3,0,
20,9,196,3,0,
20,9,203,4,0,
20,9,207,4,0,
20,9,213,4,0,
20,9,214,4,0,
20,9,216,4,0,
20,9,218,4,0,
20,9,228,1,13,
20,9,231,4,0,
20,9,237,4,0,
20,9,240,4,0,
20,9,241,4,0,
20,9,242,1,24,
20,9,247,4,0,
20,9,249,4,0,
20,9,263,4,0,
20,9,269,4,0,
20,9,283,1,44,
20,9,283,3,0,
20,9,290,4,0,
20,9,351,4,0,
20,9,363,4,0,
20,9,365,4,0,
20,9,369,4,0,
20,9,372,1,29,
20,9,387,3,0,
20,9,389,1,19,
20,9,389,3,0,
20,9,416,4,0,
20,9,428,3,0,
20,9,445,4,0,
20,9,447,4,0,
20,9,451,4,0,
20,10,14,1,1,1
20,10,14,4,0,
20,10,15,4,0,
20,10,29,3,0,
20,10,33,1,1,2
20,10,38,1,39,
20,10,39,1,1,3
20,10,44,1,10,
20,10,46,4,0,
20,10,58,4,0,
20,10,59,4,0,
20,10,63,4,0,
20,10,70,4,0,
20,10,85,4,0,
20,10,86,4,0,
20,10,87,4,0,
20,10,91,4,0,
20,10,92,4,0,
20,10,98,1,1,4
20,10,98,1,4,
20,10,104,4,0,
20,10,116,1,1,5
20,10,116,1,7,
20,10,129,3,0,
20,10,156,4,0,
20,10,158,1,16,
20,10,162,1,34,
20,10,162,3,0,
20,10,164,4,0,
20,10,168,4,0,
20,10,173,3,0,
20,10,182,4,0,
20,10,184,1,20,
20,10,189,3,0,
20,10,196,3,0,
20,10,203,4,0,
20,10,207,4,0,
20,10,213,4,0,
20,10,214,4,0,
20,10,216,4,0,
20,10,218,4,0,
20,10,228,1,13,
20,10,231,4,0,
20,10,237,4,0,
20,10,240,4,0,
20,10,241,4,0,
20,10,242,1,24,
20,10,247,4,0,
20,10,249,4,0,
20,10,263,4,0,
20,10,269,4,0,
20,10,283,1,44,
20,10,283,3,0,
20,10,290,4,0,
20,10,351,4,0,
20,10,363,4,0,
20,10,365,4,0,
20,10,369,4,0,
20,10,372,1,29,
20,10,387,3,0,
20,10,389,1,19,
20,10,389,3,0,
20,10,416,4,0,
20,10,428,3,0,
20,10,445,4,0,
20,10,447,4,0,
20,10,451,4,0,
20,11,14,1,1,1
20,11,14,4,0,
20,11,15,4,0,
20,11,33,1,1,2
20,11,38,1,39,
20,11,39,1,1,3
20,11,44,1,10,
20,11,46,4,0,
20,11,58,4,0,
20,11,59,4,0,
20,11,63,4,0,
20,11,70,4,0,
20,11,85,4,0,
20,11,86,4,0,
20,11,87,4,0,
20,11,91,4,0,
20,11,92,4,0,
20,11,98,1,1,4
20,11,98,1,4,
20,11,104,4,0,
20,11,116,1,1,5
20,11,116,1,7,
20,11,156,4,0,
20,11,158,1,16,
20,11,162,1,34,
20,11,164,4,0,
20,11,168,4,0,
20,11,182,4,0,
20,11,184,1,20,
20,11,207,4,0,
20,11,213,4,0,
20,11,216,4,0,
20,11,218,4,0,
20,11,228,1,13,
20,11,237,4,0,
20,11,240,4,0,
20,11,241,4,0,
20,11,242,1,24,
20,11,247,4,0,
20,11,249,4,0,
20,11,263,4,0,
20,11,269,4,0,
20,11,283,1,44,
20,11,365,4,0,
20,11,369,4,0,
20,11,372,1,29,
20,11,389,1,19,
20,11,416,4,0,
20,11,447,4,0,
20,11,451,4,0,
20,11,496,4,0,
20,11,514,4,0,
20,11,526,4,0,
20,11,528,4,0,
20,12,15,4,0,
20,12,33,1,1,1
20,12,39,1,1,2
20,12,46,4,0,
20,12,58,4,0,
20,12,59,4,0,
20,12,63,4,0,
20,12,70,4,0,
20,12,85,4,0,
20,12,87,4,0,
20,12,91,4,0,
20,12,92,4,0,
20,12,98,1,1,3
20,12,98,1,7,
20,12,104,4,0,
20,12,156,4,0,
20,12,158,1,13,
20,12,162,1,40,
20,12,168,4,0,
20,12,182,4,0,
20,12,184,1,20,
20,12,213,4,0,
20,12,216,4,0,
20,12,218,4,0,
20,12,228,1,30,
20,12,231,4,0,
20,12,237,4,0,
20,12,240,4,0,
20,12,241,4,0,
20,12,247,4,0,
20,12,249,4,0,
20,12,263,4,0,
20,12,269,4,0,
20,12,283,1,50,
20,12,290,4,0,
20,12,351,4,0,
20,13,15,4,0,
20,13,33,1,1,1
20,13,34,3,0,
20,13,38,3,0,
20,13,39,1,1,2
20,13,46,4,0,
20,13,58,4,0,
20,13,59,4,0,
20,13,63,4,0,
20,13,70,4,0,
20,13,85,4,0,
20,13,86,3,0,
20,13,87,4,0,
20,13,91,4,0,
20,13,92,4,0,
20,13,98,1,1,3
20,13,98,1,7,
20,13,102,3,0,
20,13,104,4,0,
20,13,156,4,0,
20,13,158,1,13,
20,13,162,1,40,
20,13,164,3,0,
20,13,168,4,0,
20,13,182,4,0,
20,13,184,1,20,
20,13,196,3,0,
20,13,207,3,0,
20,13,213,4,0,
20,13,216,4,0,
20,13,218,4,0,
20,13,228,1,30,
20,13,231,4,0,
20,13,237,4,0,
20,13,240,4,0,
20,13,241,4,0,
20,13,247,4,0,
20,13,249,4,0,
20,13,263,4,0,
20,13,269,4,0,
20,13,283,1,50,
20,13,290,4,0,
20,13,351,4,0,
20,14,14,1,1,1
20,14,14,4,0,
20,14,15,4,0,
20,14,33,1,1,2
20,14,38,1,39,
20,14,39,1,1,3
20,14,44,1,10,
20,14,46,4,0,
20,14,58,4,0,
20,14,59,4,0,
20,14,63,4,0,
20,14,70,4,0,
20,14,85,4,0,
20,14,86,4,0,
20,14,87,4,0,
20,14,91,4,0,
20,14,92,4,0,
20,14,98,1,1,4
20,14,98,1,4,
20,14,104,4,0,
20,14,116,1,1,5
20,14,116,1,7,
20,14,156,4,0,
20,14,158,1,16,
20,14,162,1,34,
20,14,162,3,0,
20,14,164,4,0,
20,14,168,4,0,
20,14,173,3,0,
20,14,182,4,0,
20,14,184,1,20,
20,14,196,3,0,
20,14,207,4,0,
20,14,213,4,0,
20,14,214,3,0,
20,14,216,4,0,
20,14,218,4,0,
20,14,228,1,13,
20,14,231,3,0,
20,14,237,4,0,
20,14,240,4,0,
20,14,241,4,0,
20,14,242,1,24,
20,14,247,4,0,
20,14,249,4,0,
20,14,253,3,0,
20,14,263,4,0,
20,14,269,4,0,
20,14,283,1,44,
20,14,283,3,0,
20,14,343,3,0,
20,14,365,4,0,
20,14,369,4,0,
20,14,372,1,29,
20,14,387,3,0,
20,14,389,1,19,
20,14,416,4,0,
20,14,428,3,0,
20,14,447,4,0,
20,14,451,4,0,
20,14,496,4,0,
20,14,514,4,0,
20,14,526,4,0,
20,14,528,4,0,
20,15,14,1,1,1
20,15,14,4,0,
20,15,15,4,0,
20,15,33,1,1,2
20,15,38,1,39,
20,15,39,1,1,3
20,15,44,1,10,
20,15,46,4,0,
20,15,58,4,0,
20,15,59,4,0,
20,15,63,4,0,
20,15,70,4,0,
20,15,85,4,0,
20,15,86,4,0,
20,15,87,4,0,
20,15,91,4,0,
20,15,92,4,0,
20,15,98,1,1,4
20,15,98,1,4,
20,15,104,4,0,
20,15,116,1,1,5
20,15,116,1,7,
20,15,156,4,0,
20,15,158,1,16,
20,15,162,1,34,
20,15,164,4,0,
20,15,168,4,0,
20,15,182,4,0,
20,15,184,1,20,
20,15,207,4,0,
20,15,213,4,0,
20,15,214,4,0,
20,15,216,4,0,
20,15,218,4,0,
20,15,228,1,13,
20,15,237,4,0,
20,15,240,4,0,
20,15,241,4,0,
20,15,242,1,24,
20,15,247,4,0,
20,15,249,4,0,
20,15,263,4,0,
20,15,269,4,0,
20,15,283,1,44,
20,15,369,4,0,
20,15,372,1,29,
20,15,389,1,19,
20,15,416,4,0,
20,15,447,4,0,
20,15,451,4,0,
20,15,496,4,0,
20,15,514,4,0,
20,15,528,4,0,
20,15,590,4,0,
20,16,14,1,1,1
20,16,14,4,0,
20,16,15,4,0,
20,16,33,1,1,2
20,16,38,1,39,1
20,16,39,1,1,3
20,16,44,1,10,1
20,16,46,4,0,
20,16,58,4,0,
20,16,59,4,0,
20,16,63,4,0,
20,16,70,4,0,
20,16,85,4,0,
20,16,86,4,0,
20,16,87,4,0,
20,16,91,4,0,
20,16,92,4,0,
20,16,98,1,1,4
20,16,98,1,4,1
20,16,104,4,0,
20,16,116,1,1,5
20,16,116,1,7,1
20,16,156,4,0,
20,16,158,1,16,1
20,16,162,1,34,1
20,16,162,3,0,
20,16,164,4,0,
20,16,168,4,0,
20,16,173,3,0,
20,16,182,4,0,
20,16,184,1,20,1
20,16,196,3,0,
20,16,207,4,0,
20,16,213,4,0,
20,16,214,4,0,
20,16,216,4,0,
20,16,218,4,0,
20,16,228,1,13,1
20,16,231,3,0,
20,16,237,4,0,
20,16,240,4,0,
20,16,241,4,0,
20,16,242,1,24,1
20,16,247,4,0,
20,16,249,4,0,
20,16,253,3,0,
20,16,263,4,0,
20,16,269,4,0,
20,16,283,1,44,1
20,16,283,3,0,
20,16,290,4,0,
20,16,343,3,0,
20,16,351,3,0,
20,16,369,4,0,
20,16,372,1,29,1
20,16,387,3,0,
20,16,389,1,19,1
20,16,416,4,0,
20,16,428,3,0,
20,16,447,4,0,
20,16,451,4,0,
20,16,496,4,0,
20,16,514,4,0,
20,16,528,4,0,
20,16,590,4,0,
20,17,14,1,1,2
20,17,14,4,0,
20,17,33,1,1,3
20,17,38,1,39,
20,17,39,1,1,4
20,17,44,1,10,
20,17,46,4,0,
20,17,58,4,0,
20,17,59,4,0,
20,17,63,4,0,
20,17,85,4,0,
20,17,86,4,0,
20,17,87,4,0,
20,17,92,4,0,
20,17,98,1,1,5
20,17,98,1,4,
20,17,104,4,0,
20,17,116,1,1,6
20,17,116,1,7,
20,17,156,4,0,
20,17,158,1,16,
20,17,162,1,34,
20,17,164,4,0,
20,17,168,4,0,
20,17,182,4,0,
20,17,184,1,0,
20,17,184,1,1,1
20,17,207,4,0,
20,17,213,4,0,
20,17,214,4,0,
20,17,216,4,0,
20,17,218,4,0,
20,17,228,1,13,
20,17,237,4,0,
20,17,240,4,0,
20,17,241,4,0,
20,17,242,1,24,
20,17,247,4,0,
20,17,263,4,0,
20,17,269,4,0,
20,17,283,1,44,
20,17,369,4,0,
20,17,372,1,19,
20,17,389,1,29,
20,17,416,4,0,
20,17,447,4,0,
20,17,451,4,0,
20,17,496,4,0,
20,17,526,4,0,
20,17,528,4,0,
20,17,590,4,0,
20,18,14,1,1,2
20,18,14,4,0,
20,18,33,1,1,3
20,18,38,1,39,
20,18,39,1,1,4
20,18,44,1,10,
20,18,46,4,0,
20,18,58,4,0,
20,18,59,4,0,
20,18,63,4,0,
20,18,85,4,0,
20,18,86,4,0,
20,18,87,4,0,
20,18,92,4,0,
20,18,98,1,1,5
20,18,98,1,4,
20,18,104,4,0,
20,18,116,1,1,6
20,18,116,1,7,
20,18,156,4,0,
20,18,158,1,16,
20,18,162,1,34,
20,18,164,4,0,
20,18,168,4,0,
20,18,182,4,0,
20,18,184,1,0,
20,18,184,1,1,1
20,18,207,4,0,
20,18,213,4,0,
20,18,214,4,0,
20,18,216,4,0,
20,18,218,4,0,
20,18,228,1,13,
20,18,237,4,0,
20,18,240,4,0,
20,18,241,4,0,
20,18,242,1,24,
20,18,247,4,0,
20,18,263,4,0,
20,18,269,4,0,
20,18,283,1,44,
20,18,369,4,0,
20,18,372,1,19,
20,18,389,1,29,
20,18,416,4,0,
20,18,447,4,0,
20,18,451,4,0,
20,18,496,4,0,
20,18,526,4,0,
20,18,528,4,0,
20,18,590,4,0,
21,1,13,4,0,
21,1,18,4,0,
21,1,19,4,0,
21,1,31,1,15,
21,1,36,4,0,
21,1,38,4,0,
21,1,43,1,9,
21,1,45,1,1,2
21,1,64,1,1,1
21,1,65,1,29,
21,1,92,4,0,
21,1,97,1,36,
21,1,99,4,0,
21,1,102,4,0,
21,1,104,4,0,
21,1,117,4,0,
21,1,119,1,22,
21,1,129,4,0,
21,1,143,4,0,
21,1,156,4,0,
21,1,164,4,0,
21,2,13,4,0,
21,2,18,4,0,
21,2,19,4,0,
21,2,31,1,15,
21,2,36,4,0,
21,2,38,4,0,
21,2,43,1,9,
21,2,45,1,1,2
21,2,64,1,1,1
21,2,65,1,29,
21,2,92,4,0,
21,2,97,1,36,
21,2,99,4,0,
21,2,102,4,0,
21,2,104,4,0,
21,2,117,4,0,
21,2,119,1,22,
21,2,129,4,0,
21,2,143,4,0,
21,2,156,4,0,
21,2,164,4,0,
21,3,19,4,0,
21,3,31,1,13,
21,3,43,1,7,
21,3,45,1,1,2
21,3,64,1,1,1
21,3,65,1,37,
21,3,92,4,0,
21,3,97,1,43,
21,3,98,2,0,
21,3,104,4,0,
21,3,119,1,31,
21,3,129,4,0,
21,3,156,4,0,
21,3,161,2,0,
21,3,168,4,0,
21,3,173,4,0,
21,3,174,4,0,
21,3,182,4,0,
21,3,184,2,0,
21,3,185,2,0,
21,3,189,4,0,
21,3,197,4,0,
21,3,203,4,0,
21,3,206,2,0,
21,3,207,4,0,
21,3,211,4,0,
21,3,213,4,0,
21,3,214,4,0,
21,3,216,4,0,
21,3,218,4,0,
21,3,228,1,25,
21,3,237,4,0,
21,3,241,4,0,
21,4,19,4,0,
21,4,31,1,13,
21,4,43,1,7,
21,4,45,1,1,2
21,4,64,1,1,1
21,4,65,1,37,
21,4,92,4,0,
21,4,97,1,43,
21,4,98,2,0,
21,4,104,4,0,
21,4,119,1,31,
21,4,129,4,0,
21,4,156,4,0,
21,4,161,2,0,
21,4,168,4,0,
21,4,173,4,0,
21,4,174,4,0,
21,4,182,4,0,
21,4,184,2,0,
21,4,185,2,0,
21,4,189,4,0,
21,4,197,4,0,
21,4,203,4,0,
21,4,206,2,0,
21,4,207,4,0,
21,4,211,4,0,
21,4,213,4,0,
21,4,214,4,0,
21,4,216,4,0,
21,4,218,4,0,
21,4,228,1,25,
21,4,237,4,0,
21,4,241,4,0,
21,5,19,4,0,
21,5,31,1,13,
21,5,43,1,7,
21,5,45,1,1,2
21,5,64,1,1,1
21,5,65,1,37,
21,5,92,4,0,
21,5,97,1,43,
21,5,98,2,0,
21,5,104,4,0,
21,5,119,1,31,
21,5,143,2,0,
21,5,156,4,0,
21,5,161,2,0,
21,5,168,4,0,
21,5,182,4,0,
21,5,184,2,0,
21,5,185,2,0,
21,5,206,2,0,
21,5,211,4,0,
21,5,213,4,0,
21,5,216,4,0,
21,5,218,4,0,
21,5,228,1,19,
21,5,237,4,0,
21,5,240,4,0,
21,5,241,4,0,
21,5,263,4,0,
21,5,290,4,0,
21,5,310,2,0,
21,5,332,1,25,
21,5,332,4,0,
21,6,19,4,0,
21,6,31,1,13,
21,6,38,3,0,
21,6,43,1,7,
21,6,45,1,1,2
21,6,64,1,1,1
21,6,65,1,37,
21,6,92,4,0,
21,6,97,1,43,
21,6,98,2,0,
21,6,102,3,0,
21,6,104,4,0,
21,6,119,1,31,
21,6,129,3,0,
21,6,143,2,0,
21,6,156,4,0,
21,6,161,2,0,
21,6,164,3,0,
21,6,168,4,0,
21,6,173,3,0,
21,6,182,4,0,
21,6,184,2,0,
21,6,185,2,0,
21,6,189,3,0,
21,6,203,3,0,
21,6,206,2,0,
21,6,207,3,0,
21,6,211,4,0,
21,6,213,4,0,
21,6,214,3,0,
21,6,216,4,0,
21,6,218,4,0,
21,6,228,1,19,
21,6,237,4,0,
21,6,240,4,0,
21,6,241,4,0,
21,6,263,4,0,
21,6,290,4,0,
21,6,310,2,0,
21,6,332,1,25,
21,6,332,4,0,
21,7,19,4,0,
21,7,31,1,13,
21,7,38,3,0,
21,7,43,1,7,
21,7,45,1,1,2
21,7,64,1,1,1
21,7,65,1,37,
21,7,92,4,0,
21,7,97,1,43,
21,7,98,2,0,
21,7,102,3,0,
21,7,104,4,0,
21,7,119,1,31,
21,7,143,2,0,
21,7,156,4,0,
21,7,161,2,0,
21,7,164,3,0,
21,7,168,4,0,
21,7,182,4,0,
21,7,184,2,0,
21,7,185,2,0,
21,7,206,2,0,
21,7,211,4,0,
21,7,213,4,0,
21,7,216,4,0,
21,7,218,4,0,
21,7,228,1,19,
21,7,237,4,0,
21,7,240,4,0,
21,7,241,4,0,
21,7,263,4,0,
21,7,290,4,0,
21,7,310,2,0,
21,7,332,1,25,
21,7,332,4,0,
21,8,18,2,0,
21,8,19,4,0,
21,8,31,1,9,
21,8,43,1,5,
21,8,45,1,1,2
21,8,64,1,1,1
21,8,65,1,37,
21,8,92,4,0,
21,8,97,1,25,
21,8,98,2,0,
21,8,104,4,0,
21,8,119,1,21,
21,8,143,2,0,
21,8,156,4,0,
21,8,161,2,0,
21,8,164,4,0,
21,8,168,4,0,
21,8,182,4,0,
21,8,184,2,0,
21,8,185,2,0,
21,8,203,4,0,
21,8,206,2,0,
21,8,207,4,0,
21,8,211,4,0,
21,8,213,4,0,
21,8,214,4,0,
21,8,216,4,0,
21,8,218,4,0,
21,8,228,1,13,
21,8,237,4,0,
21,8,240,4,0,
21,8,241,4,0,
21,8,253,2,0,
21,8,263,4,0,
21,8,290,4,0,
21,8,310,2,0,
21,8,332,1,17,
21,8,332,4,0,
21,8,355,1,33,
21,8,355,4,0,
21,8,363,4,0,
21,8,365,4,0,
21,8,369,4,0,
21,8,372,1,29,
21,8,432,4,0,
21,8,445,4,0,
21,9,18,2,0,
21,9,19,4,0,
21,9,31,1,9,
21,9,43,1,5,
21,9,45,1,1,2
21,9,64,1,1,1
21,9,65,1,37,
21,9,92,4,0,
21,9,97,1,25,
21,9,98,2,0,
21,9,104,4,0,
21,9,119,1,21,
21,9,129,3,0,
21,9,143,2,0,
21,9,156,4,0,
21,9,161,2,0,
21,9,164,4,0,
21,9,168,4,0,
21,9,173,3,0,
21,9,182,4,0,
21,9,184,2,0,
21,9,185,2,0,
21,9,189,3,0,
21,9,203,4,0,
21,9,206,2,0,
21,9,207,4,0,
21,9,211,4,0,
21,9,213,4,0,
21,9,214,4,0,
21,9,216,4,0,
21,9,218,4,0,
21,9,228,1,13,
21,9,237,4,0,
21,9,239,3,0,
21,9,240,4,0,
21,9,241,4,0,
21,9,253,2,0,
21,9,257,3,0,
21,9,263,4,0,
21,9,290,4,0,
21,9,310,2,0,
21,9,314,3,0,
21,9,332,1,17,
21,9,332,4,0,
21,9,355,1,33,
21,9,355,4,0,
21,9,363,4,0,
21,9,365,4,0,
21,9,369,4,0,
21,9,372,1,29,
21,9,432,4,0,
21,9,445,4,0,
21,9,466,3,0,
21,10,18,2,0,
21,10,19,4,0,
21,10,31,1,9,
21,10,43,1,5,
21,10,45,1,1,2
21,10,64,1,1,1
21,10,65,1,37,
21,10,92,4,0,
21,10,97,1,25,
21,10,98,2,0,
21,10,104,4,0,
21,10,119,1,21,
21,10,129,3,0,
21,10,143,2,0,
21,10,156,4,0,
21,10,161,2,0,
21,10,164,4,0,
21,10,168,4,0,
21,10,173,3,0,
21,10,182,4,0,
21,10,184,2,0,
21,10,185,2,0,
21,10,189,3,0,
21,10,203,4,0,
21,10,206,2,0,
21,10,207,4,0,
21,10,211,4,0,
21,10,213,4,0,
21,10,214,4,0,
21,10,216,4,0,
21,10,218,4,0,
21,10,228,1,13,
21,10,237,4,0,
21,10,239,3,0,
21,10,240,4,0,
21,10,241,4,0,
21,10,253,2,0,
21,10,257,3,0,
21,10,263,4,0,
21,10,290,4,0,
21,10,297,2,0,
21,10,310,2,0,
21,10,314,3,0,
21,10,332,1,17,
21,10,332,4,0,
21,10,355,1,33,
21,10,355,4,0,
21,10,363,4,0,
21,10,365,4,0,
21,10,366,3,0,
21,10,369,4,0,
21,10,372,1,29,
21,10,445,4,0,
21,10,466,3,0,
21,11,13,2,0,
21,11,18,2,0,
21,11,19,4,0,
21,11,31,1,9,
21,11,43,1,5,
21,11,45,1,1,2
21,11,64,1,1,1
21,11,65,1,37,
21,11,92,4,0,
21,11,97,1,25,
21,11,98,2,0,
21,11,104,4,0,
21,11,119,1,21,
21,11,143,2,0,
21,11,156,4,0,
21,11,161,2,0,
21,11,164,4,0,
21,11,168,4,0,
21,11,182,4,0,
21,11,184,2,0,
21,11,185,2,0,
21,11,206,4,0,
21,11,207,4,0,
21,11,211,2,0,
21,11,213,4,0,
21,11,216,4,0,
21,11,218,4,0,
21,11,228,1,13,
21,11,237,4,0,
21,11,240,4,0,
21,11,241,4,0,
21,11,253,2,0,
21,11,263,4,0,
21,11,297,2,0,
21,11,310,2,0,
21,11,332,1,17,
21,11,332,4,0,
21,11,355,1,33,
21,11,365,4,0,
21,11,369,4,0,
21,11,372,1,29,
21,11,496,4,0,
21,11,497,4,0,
21,11,526,4,0,
21,12,19,4,0,
21,12,31,1,13,
21,12,43,1,7,
21,12,45,1,1,2
21,12,64,1,1,1
21,12,65,1,37,
21,12,92,4,0,
21,12,97,1,43,
21,12,104,4,0,
21,12,119,1,31,
21,12,156,4,0,
21,12,168,4,0,
21,12,182,4,0,
21,12,211,4,0,
21,12,213,4,0,
21,12,216,4,0,
21,12,218,4,0,
21,12,228,1,19,
21,12,237,4,0,
21,12,240,4,0,
21,12,241,4,0,
21,12,263,4,0,
21,12,290,4,0,
21,12,332,1,25,
21,12,332,4,0,
21,13,19,4,0,
21,13,31,1,13,
21,13,38,3,0,
21,13,43,1,7,
21,13,45,1,1,2
21,13,64,1,1,1
21,13,65,1,37,
21,13,92,4,0,
21,13,97,1,43,
21,13,102,3,0,
21,13,104,4,0,
21,13,119,1,31,
21,13,143,3,0,
21,13,156,4,0,
21,13,164,3,0,
21,13,168,4,0,
21,13,182,4,0,
21,13,207,3,0,
21,13,211,4,0,
21,13,213,4,0,
21,13,216,4,0,
21,13,218,4,0,
21,13,228,1,19,
21,13,237,4,0,
21,13,240,4,0,
21,13,241,4,0,
21,13,263,4,0,
21,13,290,4,0,
21,13,332,1,25,
21,13,332,4,0,
21,14,13,2,0,
21,14,18,2,0,
21,14,19,4,0,
21,14,31,1,9,
21,14,43,1,5,
21,14,45,1,1,2
21,14,64,1,1,1
21,14,65,1,37,
21,14,92,4,0,
21,14,97,1,25,
21,14,98,2,0,
21,14,104,4,0,
21,14,119,1,21,
21,14,143,2,0,
21,14,143,3,0,
21,14,156,4,0,
21,14,161,2,0,
21,14,164,4,0,
21,14,168,4,0,
21,14,173,3,0,
21,14,182,4,0,
21,14,184,2,0,
21,14,185,2,0,
21,14,206,4,0,
21,14,207,4,0,
21,14,211,2,0,
21,14,213,4,0,
21,14,214,3,0,
21,14,216,4,0,
21,14,218,4,0,
21,14,228,1,13,
21,14,237,4,0,
21,14,240,4,0,
21,14,241,4,0,
21,14,253,2,0,
21,14,253,3,0,
21,14,257,3,0,
21,14,263,4,0,
21,14,297,2,0,
21,14,310,2,0,
21,14,332,1,17,
21,14,332,4,0,
21,14,355,1,33,
21,14,355,3,0,
21,14,365,4,0,
21,14,366,3,0,
21,14,369,4,0,
21,14,372,1,29,
21,14,496,4,0,
21,14,497,4,0,
21,14,526,4,0,
21,14,529,3,0,
21,15,13,2,0,
21,15,18,2,0,
21,15,19,4,0,
21,15,31,1,9,
21,15,43,1,5,
21,15,45,1,1,2
21,15,64,1,1,1
21,15,65,1,37,
21,15,92,4,0,
21,15,97,1,25,
21,15,98,2,0,
21,15,104,4,0,
21,15,119,1,21,
21,15,143,2,0,
21,15,156,4,0,
21,15,161,2,0,
21,15,164,4,0,
21,15,168,4,0,
21,15,182,4,0,
21,15,184,2,0,
21,15,185,2,0,
21,15,206,4,0,
21,15,207,4,0,
21,15,211,2,0,
21,15,211,4,0,
21,15,213,4,0,
21,15,214,4,0,
21,15,216,4,0,
21,15,218,4,0,
21,15,228,1,13,
21,15,237,4,0,
21,15,240,4,0,
21,15,241,4,0,
21,15,253,2,0,
21,15,263,4,0,
21,15,297,2,0,
21,15,310,2,0,
21,15,332,1,17,
21,15,332,4,0,
21,15,355,1,33,
21,15,355,4,0,
21,15,369,4,0,
21,15,372,1,29,
21,15,496,4,0,
21,15,497,4,0,
21,15,590,4,0,
21,16,13,2,0,
21,16,18,2,0,
21,16,19,4,0,
21,16,31,1,9,1
21,16,43,1,5,1
21,16,45,1,1,2
21,16,64,1,1,1
21,16,65,1,37,1
21,16,92,4,0,
21,16,97,1,25,1
21,16,98,2,0,
21,16,104,4,0,
21,16,119,1,21,1
21,16,143,2,0,
21,16,143,3,0,
21,16,156,4,0,
21,16,161,2,0,
21,16,164,4,0,
21,16,168,4,0,
21,16,173,3,0,
21,16,182,4,0,
21,16,184,2,0,
21,16,185,2,0,
21,16,206,4,0,
21,16,207,4,0,
21,16,211,2,0,
21,16,211,4,0,
21,16,213,4,0,
21,16,214,4,0,
21,16,216,4,0,
21,16,218,4,0,
21,16,228,1,13,1
21,16,237,4,0,
21,16,240,4,0,
21,16,241,4,0,
21,16,253,2,0,
21,16,253,3,0,
21,16,257,3,0,
21,16,263,4,0,
21,16,290,4,0,
21,16,297,2,0,
21,16,310,2,0,
21,16,332,1,17,1
21,16,332,4,0,
21,16,355,1,33,1
21,16,355,4,0,
21,16,366,3,0,
21,16,369,4,0,
21,16,372,1,29,1
21,16,496,4,0,
21,16,497,4,0,
21,16,529,3,0,
21,16,590,4,0,
21,17,13,2,0,
21,17,18,2,0,
21,17,19,4,0,
21,17,31,1,11,
21,17,43,1,4,
21,17,45,1,1,2
21,17,64,1,1,1
21,17,65,1,36,
21,17,92,4,0,
21,17,97,1,25,
21,17,98,2,0,
21,17,104,4,0,
21,17,116,1,29,
21,17,119,1,18,
21,17,143,2,0,
21,17,156,4,0,
21,17,161,2,0,
21,17,164,4,0,
21,17,168,4,0,
21,17,182,4,0,
21,17,184,2,0,
21,17,185,2,0,
21,17,206,4,0,
21,17,207,4,0,
21,17,211,2,0,
21,17,211,4,0,
21,17,213,4,0,
21,17,214,4,0,
21,17,216,4,0,
21,17,218,4,0,
21,17,228,1,8,
21,17,237,4,0,
21,17,240,4,0,
21,17,241,4,0,
21,17,253,2,0,
21,17,263,4,0,
21,17,297,2,0,
21,17,310,2,0,
21,17,332,1,15,
21,17,332,4,0,
21,17,355,1,32,
21,17,355,4,0,
21,17,369,4,0,
21,17,372,1,22,
21,17,496,4,0,
21,17,497,4,0,
21,17,526,4,0,
21,17,590,4,0,
21,18,13,2,0,
21,18,18,2,0,
21,18,19,4,0,
21,18,31,1,11,
21,18,43,1,4,
21,18,45,1,1,2
21,18,64,1,1,1
21,18,65,1,36,
21,18,92,4,0,
21,18,97,1,25,
21,18,98,2,0,
21,18,104,4,0,
21,18,116,1,29,
21,18,119,1,18,
21,18,143,2,0,
21,18,156,4,0,
21,18,161,2,0,
21,18,164,4,0,
21,18,168,4,0,
21,18,182,4,0,
21,18,184,2,0,
21,18,185,2,0,
21,18,206,4,0,
21,18,207,4,0,
21,18,211,2,0,
21,18,211,4,0,
21,18,213,4,0,
21,18,214,4,0,
21,18,216,4,0,
21,18,218,4,0,
21,18,228,1,8,
21,18,237,4,0,
21,18,240,4,0,
21,18,241,4,0,
21,18,253,2,0,
21,18,263,4,0,
21,18,297,2,0,
21,18,310,2,0,
21,18,332,1,15,
21,18,332,4,0,
21,18,355,1,32,
21,18,355,4,0,
21,18,369,4,0,
21,18,372,1,22,
21,18,496,4,0,
21,18,497,4,0,
21,18,526,4,0,
21,18,590,4,0,
22,1,13,4,0,
22,1,18,4,0,
22,1,19,4,0,
22,1,31,1,15,
22,1,36,4,0,
22,1,38,4,0,
22,1,43,1,1,3
22,1,43,1,9,
22,1,45,1,1,2
22,1,63,4,0,
22,1,64,1,1,1
22,1,65,1,34,
22,1,92,4,0,
22,1,97,1,43,
22,1,99,4,0,
22,1,102,4,0,
22,1,104,4,0,
22,1,117,4,0,
22,1,119,1,25,
22,1,129,4,0,
22,1,143,4,0,
22,1,156,4,0,
22,1,164,4,0,
22,2,13,4,0,
22,2,18,4,0,
22,2,19,4,0,
22,2,31,1,15,
22,2,36,4,0,
22,2,38,4,0,
22,2,43,1,1,3
22,2,43,1,9,
22,2,45,1,1,2
22,2,63,4,0,
22,2,64,1,1,1
22,2,65,1,34,
22,2,92,4,0,
22,2,97,1,43,
22,2,99,4,0,
22,2,102,4,0,
22,2,104,4,0,
22,2,117,4,0,
22,2,119,1,25,
22,2,129,4,0,
22,2,143,4,0,
22,2,156,4,0,
22,2,164,4,0,
22,3,19,4,0,
22,3,31,1,1,4
22,3,31,1,13,
22,3,43,1,1,3
22,3,43,1,7,
22,3,45,1,1,2
22,3,63,4,0,
22,3,64,1,1,1
22,3,65,1,40,
22,3,92,4,0,
22,3,97,1,47,
22,3,104,4,0,
22,3,119,1,32,
22,3,129,4,0,
22,3,156,4,0,
22,3,168,4,0,
22,3,173,4,0,
22,3,174,4,0,
22,3,182,4,0,
22,3,189,4,0,
22,3,197,4,0,
22,3,203,4,0,
22,3,207,4,0,
22,3,211,4,0,
22,3,213,4,0,
22,3,214,4,0,
22,3,216,4,0,
22,3,218,4,0,
22,3,228,1,26,
22,3,237,4,0,
22,3,241,4,0,
22,4,19,4,0,
22,4,31,1,1,4
22,4,31,1,13,
22,4,43,1,1,3
22,4,43,1,7,
22,4,45,1,1,2
22,4,63,4,0,
22,4,64,1,1,1
22,4,65,1,40,
22,4,92,4,0,
22,4,97,1,47,
22,4,104,4,0,
22,4,119,1,32,
22,4,129,4,0,
22,4,156,4,0,
22,4,168,4,0,
22,4,173,4,0,
22,4,174,4,0,
22,4,182,4,0,
22,4,189,4,0,
22,4,197,4,0,
22,4,203,4,0,
22,4,207,4,0,
22,4,211,4,0,
22,4,213,4,0,
22,4,214,4,0,
22,4,216,4,0,
22,4,218,4,0,
22,4,228,1,26,
22,4,237,4,0,
22,4,241,4,0,
22,5,19,4,0,
22,5,31,1,1,4
22,5,31,1,13,
22,5,43,1,1,3
22,5,43,1,7,
22,5,45,1,1,2
22,5,63,4,0,
22,5,64,1,1,1
22,5,65,1,40,
22,5,92,4,0,
22,5,97,1,47,
22,5,104,4,0,
22,5,119,1,32,
22,5,156,4,0,
22,5,168,4,0,
22,5,182,4,0,
22,5,211,4,0,
22,5,213,4,0,
22,5,216,4,0,
22,5,218,4,0,
22,5,228,1,26,
22,5,237,4,0,
22,5,240,4,0,
22,5,241,4,0,
22,5,263,4,0,
22,5,290,4,0,
22,5,332,4,0,
22,6,19,4,0,
22,6,31,1,1,4
22,6,31,1,13,
22,6,38,3,0,
22,6,43,1,1,3
22,6,43,1,7,
22,6,45,1,1,2
22,6,63,4,0,
22,6,64,1,1,1
22,6,65,1,40,
22,6,92,4,0,
22,6,97,1,47,
22,6,102,3,0,
22,6,104,4,0,
22,6,119,1,32,
22,6,129,3,0,
22,6,156,4,0,
22,6,164,3,0,
22,6,168,4,0,
22,6,173,3,0,
22,6,182,4,0,
22,6,189,3,0,
22,6,203,3,0,
22,6,207,3,0,
22,6,211,4,0,
22,6,213,4,0,
22,6,214,3,0,
22,6,216,4,0,
22,6,218,4,0,
22,6,228,1,26,
22,6,237,4,0,
22,6,240,4,0,
22,6,241,4,0,
22,6,263,4,0,
22,6,290,4,0,
22,6,332,4,0,
22,7,19,4,0,
22,7,31,1,1,4
22,7,31,1,13,
22,7,38,3,0,
22,7,43,1,1,3
22,7,43,1,7,
22,7,45,1,1,2
22,7,63,4,0,
22,7,64,1,1,1
22,7,65,1,40,
22,7,92,4,0,
22,7,97,1,47,
22,7,102,3,0,
22,7,104,4,0,
22,7,119,1,32,
22,7,156,4,0,
22,7,164,3,0,
22,7,168,4,0,
22,7,182,4,0,
22,7,211,4,0,
22,7,213,4,0,
22,7,216,4,0,
22,7,218,4,0,
22,7,228,1,26,
22,7,237,4,0,
22,7,240,4,0,
22,7,241,4,0,
22,7,263,4,0,
22,7,290,4,0,
22,7,332,4,0,
22,8,19,4,0,
22,8,31,1,1,5
22,8,31,1,9,
22,8,43,1,1,4
22,8,43,1,5,
22,8,45,1,1,3
22,8,63,4,0,
22,8,64,1,1,2
22,8,65,1,47,
22,8,92,4,0,
22,8,97,1,29,
22,8,104,4,0,
22,8,119,1,23,
22,8,156,4,0,
22,8,164,4,0,
22,8,168,4,0,
22,8,182,4,0,
22,8,203,4,0,
22,8,207,4,0,
22,8,211,4,0,
22,8,213,4,0,
22,8,214,4,0,
22,8,216,4,0,
22,8,218,4,0,
22,8,228,1,13,
22,8,237,4,0,
22,8,240,4,0,
22,8,241,4,0,
22,8,263,4,0,
22,8,290,4,0,
22,8,332,1,17,
22,8,332,4,0,
22,8,355,1,41,
22,8,355,4,0,
22,8,363,4,0,
22,8,365,1,1,1
22,8,365,4,0,
22,8,369,4,0,
22,8,372,1,35,
22,8,416,4,0,
22,8,432,4,0,
22,8,445,4,0,
22,9,19,4,0,
22,9,31,1,1,5
22,9,31,1,9,
22,9,43,1,1,4
22,9,43,1,5,
22,9,45,1,1,3
22,9,63,4,0,
22,9,64,1,1,2
22,9,65,1,47,
22,9,92,4,0,
22,9,97,1,29,
22,9,104,4,0,
22,9,119,1,23,
22,9,129,3,0,
22,9,156,4,0,
22,9,164,4,0,
22,9,168,4,0,
22,9,173,3,0,
22,9,182,4,0,
22,9,189,3,0,
22,9,203,4,0,
22,9,207,4,0,
22,9,211,4,0,
22,9,213,4,0,
22,9,214,4,0,
22,9,216,4,0,
22,9,218,4,0,
22,9,228,1,13,
22,9,237,4,0,
22,9,239,3,0,
22,9,240,4,0,
22,9,241,4,0,
22,9,257,3,0,
22,9,263,4,0,
22,9,290,4,0,
22,9,314,3,0,
22,9,332,1,17,
22,9,332,4,0,
22,9,355,1,41,
22,9,355,4,0,
22,9,363,4,0,
22,9,365,1,1,1
22,9,365,4,0,
22,9,369,4,0,
22,9,372,1,35,
22,9,416,4,0,
22,9,432,4,0,
22,9,445,4,0,
22,9,466,3,0,
22,10,19,4,0,
22,10,31,1,1,5
22,10,31,1,9,
22,10,43,1,1,4
22,10,43,1,5,
22,10,45,1,1,3
22,10,63,4,0,
22,10,64,1,1,2
22,10,65,1,47,
22,10,92,4,0,
22,10,97,1,29,
22,10,104,4,0,
22,10,119,1,23,
22,10,129,3,0,
22,10,143,3,0,
22,10,156,4,0,
22,10,164,4,0,
22,10,168,4,0,
22,10,173,3,0,
22,10,182,4,0,
22,10,189,3,0,
22,10,203,4,0,
22,10,207,4,0,
22,10,211,4,0,
22,10,213,4,0,
22,10,214,4,0,
22,10,216,4,0,
22,10,218,4,0,
22,10,228,1,13,
22,10,237,4,0,
22,10,239,3,0,
22,10,240,4,0,
22,10,241,4,0,
22,10,257,3,0,
22,10,263,4,0,
22,10,290,4,0,
22,10,314,3,0,
22,10,332,1,17,
22,10,332,4,0,
22,10,355,1,41,
22,10,355,4,0,
22,10,363,4,0,
22,10,365,1,1,1
22,10,365,4,0,
22,10,366,3,0,
22,10,369,4,0,
22,10,372,1,35,
22,10,416,4,0,
22,10,445,4,0,
22,10,466,3,0,
22,11,19,4,0,
22,11,31,1,1,5
22,11,31,1,9,
22,11,43,1,1,4
22,11,43,1,5,
22,11,45,1,1,3
22,11,63,4,0,
22,11,64,1,1,2
22,11,65,1,47,
22,11,92,4,0,
22,11,97,1,29,
22,11,104,4,0,
22,11,119,1,23,
22,11,156,4,0,
22,11,164,4,0,
22,11,168,4,0,
22,11,182,4,0,
22,11,206,4,0,
22,11,207,4,0,
22,11,213,4,0,
22,11,216,4,0,
22,11,218,4,0,
22,11,228,1,13,
22,11,237,4,0,
22,11,240,4,0,
22,11,241,4,0,
22,11,263,4,0,
22,11,332,1,17,
22,11,332,4,0,
22,11,355,1,41,
22,11,365,1,1,1
22,11,365,4,0,
22,11,369,4,0,
22,11,372,1,35,
22,11,416,4,0,
22,11,496,4,0,
22,11,497,4,0,
22,11,526,4,0,
22,11,529,1,53,
22,12,19,4,0,
22,12,31,1,1,4
22,12,31,1,13,
22,12,43,1,1,3
22,12,43,1,7,
22,12,45,1,1,2
22,12,63,4,0,
22,12,64,1,1,1
22,12,65,1,40,
22,12,92,4,0,
22,12,97,1,47,
22,12,104,4,0,
22,12,119,1,32,
22,12,156,4,0,
22,12,168,4,0,
22,12,182,4,0,
22,12,211,4,0,
22,12,213,4,0,
22,12,216,4,0,
22,12,218,4,0,
22,12,228,1,26,
22,12,237,4,0,
22,12,240,4,0,
22,12,241,4,0,
22,12,263,4,0,
22,12,290,4,0,
22,12,332,4,0,
22,13,19,4,0,
22,13,31,1,1,4
22,13,31,1,13,
22,13,38,3,0,
22,13,43,1,1,3
22,13,43,1,7,
22,13,45,1,1,2
22,13,63,4,0,
22,13,64,1,1,1
22,13,65,1,40,
22,13,92,4,0,
22,13,97,1,47,
22,13,102,3,0,
22,13,104,4,0,
22,13,119,1,32,
22,13,143,3,0,
22,13,156,4,0,
22,13,164,3,0,
22,13,168,4,0,
22,13,182,4,0,
22,13,207,3,0,
22,13,211,4,0,
22,13,213,4,0,
22,13,216,4,0,
22,13,218,4,0,
22,13,228,1,26,
22,13,237,4,0,
22,13,240,4,0,
22,13,241,4,0,
22,13,263,4,0,
22,13,290,4,0,
22,13,332,4,0,
22,14,19,4,0,
22,14,31,1,1,5
22,14,31,1,9,
22,14,43,1,1,4
22,14,43,1,5,
22,14,45,1,1,3
22,14,63,4,0,
22,14,64,1,1,2
22,14,65,1,47,
22,14,92,4,0,
22,14,97,1,29,
22,14,104,4,0,
22,14,119,1,23,
22,14,143,3,0,
22,14,156,4,0,
22,14,164,4,0,
22,14,168,4,0,
22,14,173,3,0,
22,14,182,4,0,
22,14,206,4,0,
22,14,207,4,0,
22,14,213,4,0,
22,14,214,3,0,
22,14,216,4,0,
22,14,218,4,0,
22,14,228,1,13,
22,14,237,4,0,
22,14,240,4,0,
22,14,241,4,0,
22,14,253,3,0,
22,14,257,3,0,
22,14,263,4,0,
22,14,332,1,17,
22,14,332,4,0,
22,14,355,1,41,
22,14,355,3,0,
22,14,365,1,1,1
22,14,365,4,0,
22,14,366,3,0,
22,14,369,4,0,
22,14,372,1,35,
22,14,416,4,0,
22,14,496,4,0,
22,14,497,4,0,
22,14,526,4,0,
22,14,529,1,53,
22,14,529,3,0,
22,15,19,4,0,
22,15,31,1,1,6
22,15,31,1,9,
22,15,43,1,1,5
22,15,43,1,5,
22,15,45,1,1,4
22,15,63,4,0,
22,15,64,1,1,3
22,15,65,1,47,
22,15,92,4,0,
22,15,97,1,29,
22,15,104,4,0,
22,15,119,1,23,
22,15,156,4,0,
22,15,164,4,0,
22,15,168,4,0,
22,15,182,4,0,
22,15,206,4,0,
22,15,207,4,0,
22,15,211,4,0,
22,15,213,4,0,
22,15,214,4,0,
22,15,216,4,0,
22,15,218,4,0,
22,15,228,1,13,
22,15,237,4,0,
22,15,240,4,0,
22,15,241,4,0,
22,15,263,4,0,
22,15,332,1,17,
22,15,332,4,0,
22,15,355,1,41,
22,15,355,4,0,
22,15,365,1,1,2
22,15,369,4,0,
22,15,372,1,35,
22,15,416,4,0,
22,15,496,4,0,
22,15,497,4,0,
22,15,529,1,1,1
22,15,529,1,53,
22,15,590,4,0,
22,16,19,4,0,
22,16,31,1,1,6
22,16,31,1,9,1
22,16,43,1,1,5
22,16,43,1,5,1
22,16,45,1,1,4
22,16,63,4,0,
22,16,64,1,1,3
22,16,65,1,47,1
22,16,92,4,0,
22,16,97,1,29,1
22,16,104,4,0,
22,16,119,1,23,1
22,16,143,3,0,
22,16,156,4,0,
22,16,164,4,0,
22,16,168,4,0,
22,16,173,3,0,
22,16,182,4,0,
22,16,206,4,0,
22,16,207,4,0,
22,16,211,4,0,
22,16,213,4,0,
22,16,214,4,0,
22,16,216,4,0,
22,16,218,4,0,
22,16,228,1,13,1
22,16,237,4,0,
22,16,240,4,0,
22,16,241,4,0,
22,16,253,3,0,
22,16,257,3,0,
22,16,263,4,0,
22,16,290,4,0,
22,16,332,1,17,1
22,16,332,4,0,
22,16,355,1,41,1
22,16,355,4,0,
22,16,365,1,1,2
22,16,366,3,0,
22,16,369,4,0,
22,16,372,1,35,1
22,16,416,4,0,
22,16,496,4,0,
22,16,497,4,0,
22,16,529,1,1,1
22,16,529,1,53,1
22,16,529,3,0,
22,16,590,4,0,
22,17,19,4,0,
22,17,31,1,11,
22,17,43,1,1,5
22,17,43,1,4,
22,17,45,1,1,4
22,17,63,4,0,
22,17,64,1,1,3
22,17,65,1,41,
22,17,92,4,0,
22,17,97,1,27,
22,17,104,4,0,
22,17,116,1,32,
22,17,119,1,18,
22,17,156,4,0,
22,17,164,4,0,
22,17,168,4,0,
22,17,182,4,0,
22,17,206,4,0,
22,17,207,4,0,
22,17,211,4,0,
22,17,213,4,0,
22,17,214,4,0,
22,17,216,4,0,
22,17,218,4,0,
22,17,228,1,1,6
22,17,228,1,8,
22,17,237,4,0,
22,17,240,4,0,
22,17,241,4,0,
22,17,263,4,0,
22,17,332,1,15,
22,17,332,4,0,
22,17,355,1,36,
22,17,355,4,0,
22,17,365,1,1,2
22,17,369,4,0,
22,17,372,1,23,
22,17,416,4,0,
22,17,496,4,0,
22,17,497,4,0,
22,17,526,4,0,
22,17,529,1,1,1
22,17,529,1,45,
22,17,590,4,0,
22,18,19,4,0,
22,18,31,1,11,
22,18,43,1,1,5
22,18,43,1,4,
22,18,45,1,1,4
22,18,63,4,0,
22,18,64,1,1,3
22,18,65,1,41,
22,18,92,4,0,
22,18,97,1,27,
22,18,104,4,0,
22,18,116,1,32,
22,18,119,1,18,
22,18,156,4,0,
22,18,164,4,0,
22,18,168,4,0,
22,18,182,4,0,
22,18,206,4,0,
22,18,207,4,0,
22,18,211,4,0,
22,18,213,4,0,
22,18,214,4,0,
22,18,216,4,0,
22,18,218,4,0,
22,18,228,1,1,6
22,18,228,1,8,
22,18,237,4,0,
22,18,240,4,0,
22,18,241,4,0,
22,18,263,4,0,
22,18,332,1,15,
22,18,332,4,0,
22,18,355,1,36,
22,18,355,4,0,
22,18,365,1,1,2
22,18,369,4,0,
22,18,372,1,23,
22,18,416,4,0,
22,18,496,4,0,
22,18,497,4,0,
22,18,526,4,0,
22,18,529,1,1,1
22,18,529,1,45,
22,18,590,4,0,
23,1,34,4,0,
23,1,35,1,1,1
23,1,36,4,0,
23,1,38,4,0,
23,1,40,1,10,
23,1,43,1,1,2
23,1,44,1,17,
23,1,51,1,38,
23,1,70,4,0,
23,1,72,4,0,
23,1,89,4,0,
23,1,90,4,0,
23,1,91,4,0,
23,1,92,4,0,
23,1,99,4,0,
23,1,102,4,0,
23,1,103,1,31,
23,1,104,4,0,
23,1,117,4,0,
23,1,130,4,0,
23,1,137,1,24,
23,1,156,4,0,
23,1,157,4,0,
23,1,164,4,0,
23,2,34,4,0,
23,2,35,1,1,1
23,2,36,4,0,
23,2,38,4,0,
23,2,40,1,10,
23,2,43,1,1,2
23,2,44,1,17,
23,2,51,1,38,
23,2,70,4,0,
23,2,72,4,0,
23,2,89,4,0,
23,2,90,4,0,
23,2,91,4,0,
23,2,92,4,0,
23,2,99,4,0,
23,2,102,4,0,
23,2,103,1,31,
23,2,104,4,0,
23,2,117,4,0,
23,2,130,4,0,
23,2,137,1,24,
23,2,156,4,0,
23,2,157,4,0,
23,2,164,4,0,
23,3,21,2,0,
23,3,29,4,0,
23,3,35,1,1,1
23,3,40,1,9,
23,3,43,1,1,2
23,3,44,1,15,
23,3,51,1,37,
23,3,70,4,0,
23,3,89,4,0,
23,3,91,4,0,
23,3,92,4,0,
23,3,103,1,29,
23,3,104,4,0,
23,3,114,1,43,
23,3,137,1,23,
23,3,156,4,0,
23,3,168,4,0,
23,3,173,4,0,
23,3,174,4,0,
23,3,180,2,0,
23,3,182,4,0,
23,3,188,4,0,
23,3,202,4,0,
23,3,203,4,0,
23,3,207,4,0,
23,3,213,4,0,
23,3,214,4,0,
23,3,216,4,0,
23,3,218,4,0,
23,3,228,2,0,
23,3,237,4,0,
23,3,241,4,0,
23,3,251,2,0,
23,4,21,2,0,
23,4,29,4,0,
23,4,35,1,1,1
23,4,40,1,9,
23,4,43,1,1,2
23,4,44,1,15,
23,4,51,1,37,
23,4,70,4,0,
23,4,89,4,0,
23,4,91,4,0,
23,4,92,4,0,
23,4,103,1,29,
23,4,104,4,0,
23,4,114,1,43,
23,4,137,1,23,
23,4,156,4,0,
23,4,168,4,0,
23,4,173,4,0,
23,4,174,4,0,
23,4,180,2,0,
23,4,182,4,0,
23,4,188,4,0,
23,4,202,4,0,
23,4,203,4,0,
23,4,207,4,0,
23,4,213,4,0,
23,4,214,4,0,
23,4,216,4,0,
23,4,218,4,0,
23,4,228,2,0,
23,4,237,4,0,
23,4,241,4,0,
23,4,242,2,0,
23,4,251,2,0,
23,5,21,2,0,
23,5,35,1,1,1
23,5,40,1,8,
23,5,43,1,1,2
23,5,44,1,13,
23,5,51,1,32,
23,5,70,4,0,
23,5,89,4,0,
23,5,91,4,0,
23,5,92,4,0,
23,5,103,1,25,
23,5,104,4,0,
23,5,114,1,44,
23,5,137,1,20,
23,5,156,4,0,
23,5,168,4,0,
23,5,180,2,0,
23,5,182,4,0,
23,5,188,4,0,
23,5,202,4,0,
23,5,213,4,0,
23,5,216,4,0,
23,5,218,4,0,
23,5,228,2,0,
23,5,231,4,0,
23,5,237,4,0,
23,5,240,4,0,
23,5,241,4,0,
23,5,251,2,0,
23,5,254,1,37,
23,5,255,1,37,4
23,5,256,1,37,3
23,5,259,4,0,
23,5,263,4,0,
23,5,289,4,0,
23,5,290,4,0,
23,5,305,2,0,
23,6,21,2,0,
23,6,34,3,0,
23,6,35,1,1,1
23,6,38,3,0,
23,6,40,1,8,
23,6,43,1,1,2
23,6,44,1,13,
23,6,51,1,32,
23,6,70,4,0,
23,6,89,4,0,
23,6,91,4,0,
23,6,92,4,0,
23,6,102,3,0,
23,6,103,1,25,
23,6,104,4,0,
23,6,114,1,44,
23,6,137,1,20,
23,6,156,4,0,
23,6,157,3,0,
23,6,164,3,0,
23,6,168,4,0,
23,6,173,3,0,
23,6,180,2,0,
23,6,182,4,0,
23,6,188,4,0,
23,6,202,4,0,
23,6,203,3,0,
23,6,207,3,0,
23,6,213,4,0,
23,6,214,3,0,
23,6,216,4,0,
23,6,218,4,0,
23,6,228,2,0,
23,6,231,4,0,
23,6,237,4,0,
23,6,240,4,0,
23,6,241,4,0,
23,6,251,2,0,
23,6,254,1,37,
23,6,255,1,37,4
23,6,256,1,37,3
23,6,259,4,0,
23,6,263,4,0,
23,6,289,4,0,
23,6,290,4,0,
23,6,305,2,0,
23,7,21,2,0,
23,7,34,3,0,
23,7,35,1,1,1
23,7,38,3,0,
23,7,40,1,8,
23,7,43,1,1,2
23,7,44,1,13,
23,7,51,1,32,
23,7,70,4,0,
23,7,89,4,0,
23,7,91,4,0,
23,7,92,4,0,
23,7,102,3,0,
23,7,103,1,25,
23,7,104,4,0,
23,7,114,1,44,
23,7,137,1,20,
23,7,156,4,0,
23,7,157,3,0,
23,7,164,3,0,
23,7,168,4,0,
23,7,180,2,0,
23,7,182,4,0,
23,7,188,4,0,
23,7,202,4,0,
23,7,213,4,0,
23,7,216,4,0,
23,7,218,4,0,
23,7,228,2,0,
23,7,231,4,0,
23,7,237,4,0,
23,7,240,4,0,
23,7,241,4,0,
23,7,251,2,0,
23,7,254,1,37,
23,7,255,1,37,4
23,7,256,1,37,3
23,7,259,4,0,
23,7,263,4,0,
23,7,289,4,0,
23,7,290,4,0,
23,7,305,2,0,
23,8,21,2,0,
23,8,35,1,1,1
23,8,40,1,4,
23,8,43,1,1,2
23,8,44,1,9,
23,8,50,2,0,
23,8,51,1,20,
23,8,70,4,0,
23,8,89,4,0,
23,8,91,4,0,
23,8,92,4,0,
23,8,103,1,17,
23,8,104,4,0,
23,8,114,1,36,
23,8,137,1,12,
23,8,156,4,0,
23,8,157,4,0,
23,8,164,4,0,
23,8,168,4,0,
23,8,180,2,0,
23,8,182,4,0,
23,8,184,2,0,
23,8,188,4,0,
23,8,202,4,0,
23,8,203,4,0,
23,8,207,4,0,
23,8,213,4,0,
23,8,214,4,0,
23,8,216,4,0,
23,8,218,4,0,
23,8,228,2,0,
23,8,231,4,0,
23,8,237,4,0,
23,8,240,4,0,
23,8,241,4,0,
23,8,251,2,0,
23,8,254,1,25,1
23,8,255,1,25,3
23,8,256,1,25,2
23,8,259,4,0,
23,8,263,4,0,
23,8,289,4,0,
23,8,290,4,0,
23,8,305,2,0,
23,8,317,4,0,
23,8,342,2,0,
23,8,363,4,0,
23,8,371,4,0,
23,8,380,1,33,
23,8,398,4,0,
23,8,399,4,0,
23,8,426,1,28,
23,8,441,1,41,
23,8,445,4,0,
23,9,21,2,0,
23,9,35,1,1,1
23,9,40,1,4,
23,9,43,1,1,2
23,9,44,1,9,
23,9,50,2,0,
23,9,51,1,20,
23,9,70,4,0,
23,9,89,4,0,
23,9,91,4,0,
23,9,92,4,0,
23,9,103,1,17,
23,9,104,4,0,
23,9,114,1,36,
23,9,137,1,12,
23,9,156,4,0,
23,9,157,4,0,
23,9,164,4,0,
23,9,168,4,0,
23,9,173,3,0,
23,9,180,2,0,
23,9,180,3,0,
23,9,182,4,0,
23,9,184,2,0,
23,9,188,4,0,
23,9,202,4,0,
23,9,203,4,0,
23,9,207,4,0,
23,9,213,4,0,
23,9,214,4,0,
23,9,216,4,0,
23,9,218,4,0,
23,9,228,2,0,
23,9,231,4,0,
23,9,237,4,0,
23,9,240,4,0,
23,9,241,4,0,
23,9,251,2,0,
23,9,254,1,25,1
23,9,255,1,25,3
23,9,256,1,25,2
23,9,259,4,0,
23,9,263,4,0,
23,9,289,4,0,
23,9,290,4,0,
23,9,305,2,0,
23,9,317,4,0,
23,9,342,2,0,
23,9,363,4,0,
23,9,371,4,0,
23,9,380,1,33,
23,9,380,3,0,
23,9,398,4,0,
23,9,399,4,0,
23,9,401,3,0,
23,9,402,3,0,
23,9,426,1,28,
23,9,441,1,41,
23,9,441,3,0,
23,9,445,4,0,
23,10,21,2,0,
23,10,29,3,0,
23,10,35,1,1,1
23,10,40,1,4,
23,10,43,1,1,2
23,10,44,1,9,
23,10,50,2,0,
23,10,51,1,20,
23,10,70,4,0,
23,10,89,4,0,
23,10,91,4,0,
23,10,92,4,0,
23,10,103,1,17,
23,10,104,4,0,
23,10,114,1,36,
23,10,137,1,12,
23,10,156,4,0,
23,10,157,4,0,
23,10,164,4,0,
23,10,168,4,0,
23,10,173,3,0,
23,10,180,2,0,
23,10,180,3,0,
23,10,182,4,0,
23,10,184,2,0,
23,10,188,4,0,
23,10,202,4,0,
23,10,203,4,0,
23,10,207,4,0,
23,10,213,4,0,
23,10,214,4,0,
23,10,216,4,0,
23,10,218,4,0,
23,10,228,2,0,
23,10,231,4,0,
23,10,237,4,0,
23,10,240,4,0,
23,10,241,4,0,
23,10,251,2,0,
23,10,254,1,25,1
23,10,255,1,25,3
23,10,256,1,25,2
23,10,259,4,0,
23,10,263,4,0,
23,10,289,4,0,
23,10,290,4,0,
23,10,305,2,0,
23,10,317,4,0,
23,10,342,2,0,
23,10,363,4,0,
23,10,371,4,0,
23,10,380,1,33,
23,10,380,3,0,
23,10,398,4,0,
23,10,399,4,0,
23,10,401,3,0,
23,10,402,3,0,
23,10,415,2,0,
23,10,426,1,28,
23,10,441,1,41,
23,10,441,3,0,
23,10,445,4,0,
23,11,21,2,0,
23,11,35,1,1,1
23,11,40,1,4,
23,11,43,1,1,2
23,11,44,1,9,
23,11,50,2,0,
23,11,51,1,20,
23,11,70,4,0,
23,11,89,4,0,
23,11,91,4,0,
23,11,92,4,0,
23,11,103,1,17,
23,11,104,4,0,
23,11,114,1,41,
23,11,137,1,12,
23,11,156,4,0,
23,11,157,4,0,
23,11,164,4,0,
23,11,168,4,0,
23,11,180,2,0,
23,11,182,4,0,
23,11,184,2,0,
23,11,188,4,0,
23,11,207,4,0,
23,11,213,4,0,
23,11,216,4,0,
23,11,218,4,0,
23,11,228,2,0,
23,11,231,2,0,
23,11,237,4,0,
23,11,240,4,0,
23,11,241,4,0,
23,11,251,2,0,
23,11,254,1,25,1
23,11,255,1,25,3
23,11,256,1,25,2
23,11,259,4,0,
23,11,263,4,0,
23,11,289,2,0,
23,11,305,2,0,
23,11,317,4,0,
23,11,342,2,0,
23,11,371,4,0,
23,11,380,1,36,
23,11,389,2,0,
23,11,398,4,0,
23,11,415,2,0,
23,11,426,1,33,
23,11,441,1,49,
23,11,474,4,0,
23,11,482,4,0,
23,11,489,1,44,
23,11,491,1,28,
23,11,496,4,0,
23,11,523,4,0,
23,12,35,1,1,1
23,12,40,1,8,
23,12,43,1,1,2
23,12,44,1,13,
23,12,51,1,32,
23,12,70,4,0,
23,12,89,4,0,
23,12,91,4,0,
23,12,92,4,0,
23,12,103,1,25,
23,12,104,4,0,
23,12,114,1,44,
23,12,137,1,20,
23,12,156,4,0,
23,12,168,4,0,
23,12,182,4,0,
23,12,188,4,0,
23,12,202,4,0,
23,12,213,4,0,
23,12,216,4,0,
23,12,218,4,0,
23,12,231,4,0,
23,12,237,4,0,
23,12,240,4,0,
23,12,241,4,0,
23,12,254,1,37,
23,12,255,1,37,4
23,12,256,1,37,3
23,12,259,4,0,
23,12,263,4,0,
23,12,289,4,0,
23,12,290,4,0,
23,13,34,3,0,
23,13,35,1,1,1
23,13,38,3,0,
23,13,40,1,8,
23,13,43,1,1,2
23,13,44,1,13,
23,13,51,1,32,
23,13,70,4,0,
23,13,89,4,0,
23,13,91,4,0,
23,13,92,4,0,
23,13,102,3,0,
23,13,103,1,25,
23,13,104,4,0,
23,13,114,1,44,
23,13,137,1,20,
23,13,156,4,0,
23,13,164,3,0,
23,13,168,4,0,
23,13,182,4,0,
23,13,188,4,0,
23,13,202,4,0,
23,13,207,3,0,
23,13,213,4,0,
23,13,216,4,0,
23,13,218,4,0,
23,13,231,4,0,
23,13,237,4,0,
23,13,240,4,0,
23,13,241,4,0,
23,13,254,1,37,
23,13,255,1,37,4
23,13,256,1,37,3
23,13,259,4,0,
23,13,263,4,0,
23,13,289,4,0,
23,13,290,4,0,
23,14,20,3,0,
23,14,21,2,0,
23,14,35,1,1,1
23,14,40,1,4,
23,14,43,1,1,2
23,14,44,1,9,
23,14,50,2,0,
23,14,51,1,20,
23,14,70,4,0,
23,14,89,4,0,
23,14,91,4,0,
23,14,92,4,0,
23,14,103,1,17,
23,14,104,4,0,
23,14,114,1,41,
23,14,137,1,12,
23,14,156,4,0,
23,14,157,4,0,
23,14,164,4,0,
23,14,168,4,0,
23,14,173,3,0,
23,14,180,2,0,
23,14,180,3,0,
23,14,182,4,0,
23,14,184,2,0,
23,14,188,4,0,
23,14,202,3,0,
23,14,207,4,0,
23,14,213,4,0,
23,14,214,3,0,
23,14,216,4,0,
23,14,218,4,0,
23,14,228,2,0,
23,14,231,2,0,
23,14,231,3,0,
23,14,237,4,0,
23,14,240,4,0,
23,14,241,4,0,
23,14,251,2,0,
23,14,254,1,25,1
23,14,255,1,25,3
23,14,256,1,25,2
23,14,259,4,0,
23,14,263,4,0,
23,14,289,2,0,
23,14,289,3,0,
23,14,305,2,0,
23,14,317,4,0,
23,14,342,2,0,
23,14,371,4,0,
23,14,380,1,36,
23,14,380,3,0,
23,14,389,2,0,
23,14,398,4,0,
23,14,399,3,0,
23,14,401,3,0,
23,14,402,3,0,
23,14,415,2,0,
23,14,426,1,33,
23,14,441,1,49,
23,14,441,3,0,
23,14,474,4,0,
23,14,482,4,0,
23,14,489,1,44,
23,14,491,1,28,
23,14,496,4,0,
23,14,523,4,0,
23,15,21,2,0,
23,15,35,1,1,1
23,15,40,1,4,
23,15,43,1,1,2
23,15,44,1,9,
23,15,50,2,0,
23,15,51,1,20,
23,15,70,4,0,
23,15,89,4,0,
23,15,91,4,0,
23,15,92,4,0,
23,15,103,1,17,
23,15,104,4,0,
23,15,114,1,41,
23,15,137,1,12,
23,15,156,4,0,
23,15,157,4,0,
23,15,164,4,0,
23,15,168,4,0,
23,15,180,2,0,
23,15,182,4,0,
23,15,184,2,0,
23,15,188,4,0,
23,15,207,4,0,
23,15,213,4,0,
23,15,214,4,0,
23,15,216,4,0,
23,15,218,4,0,
23,15,228,2,0,
23,15,231,2,0,
23,15,237,4,0,
23,15,240,4,0,
23,15,241,4,0,
23,15,251,2,0,
23,15,254,1,25,1
23,15,255,1,25,3
23,15,256,1,25,2
23,15,259,4,0,
23,15,263,4,0,
23,15,289,2,0,
23,15,305,2,0,
23,15,317,4,0,
23,15,342,2,0,
23,15,371,4,0,
23,15,380,1,36,
23,15,389,2,0,
23,15,398,4,0,
23,15,399,4,0,
23,15,415,2,0,
23,15,426,1,33,
23,15,441,1,49,
23,15,474,4,0,
23,15,482,4,0,
23,15,489,1,44,
23,15,491,1,28,
23,15,496,4,0,
23,15,523,4,0,
23,15,562,1,38,
23,15,590,4,0,
23,15,611,4,0,
23,16,20,3,0,
23,16,21,2,0,
23,16,35,1,1,1
23,16,40,1,4,1
23,16,43,1,1,2
23,16,44,1,9,1
23,16,50,2,0,
23,16,51,1,20,1
23,16,70,4,0,
23,16,89,4,0,
23,16,91,4,0,
23,16,92,4,0,
23,16,103,1,17,1
23,16,104,4,0,
23,16,114,1,41,1
23,16,137,1,12,1
23,16,156,4,0,
23,16,157,4,0,
23,16,164,4,0,
23,16,168,4,0,
23,16,173,3,0,
23,16,180,2,0,
23,16,180,3,0,
23,16,182,4,0,
23,16,184,2,0,
23,16,188,4,0,
23,16,202,3,0,
23,16,207,4,0,
23,16,213,4,0,
23,16,214,4,0,
23,16,216,4,0,
23,16,218,4,0,
23,16,228,2,0,
23,16,231,2,0,
23,16,231,3,0,
23,16,237,4,0,
23,16,240,4,0,
23,16,241,4,0,
23,16,251,2,0,
23,16,254,1,25,1
23,16,255,1,25,3
23,16,256,1,25,2
23,16,259,4,0,
23,16,263,4,0,
23,16,289,2,0,
23,16,289,3,0,
23,16,290,4,0,
23,16,305,2,0,
23,16,317,4,0,
23,16,342,2,0,
23,16,371,4,0,
23,16,380,1,36,1
23,16,380,3,0,
23,16,389,2,0,
23,16,398,4,0,
23,16,399,4,0,
23,16,401,3,0,
23,16,402,3,0,
23,16,415,2,0,
23,16,426,1,33,1
23,16,441,1,49,1
23,16,441,3,0,
23,16,474,4,0,
23,16,482,4,0,
23,16,489,1,44,1
23,16,491,1,28,1
23,16,496,4,0,
23,16,523,4,0,
23,16,562,1,38,1
23,16,590,4,0,
23,16,611,4,0,
23,17,21,2,0,
23,17,35,1,1,1
23,17,40,1,4,
23,17,43,1,1,2
23,17,44,1,9,
23,17,50,2,0,
23,17,51,1,20,
23,17,89,4,0,
23,17,92,4,0,
23,17,103,1,17,
23,17,104,4,0,
23,17,114,1,41,
23,17,137,1,12,
23,17,156,4,0,
23,17,157,4,0,
23,17,164,4,0,
23,17,168,4,0,
23,17,180,2,0,
23,17,182,4,0,
23,17,184,2,0,
23,17,188,4,0,
23,17,207,4,0,
23,17,213,4,0,
23,17,214,4,0,
23,17,216,4,0,
23,17,218,4,0,
23,17,228,2,0,
23,17,231,2,0,
23,17,237,4,0,
23,17,240,4,0,
23,17,241,4,0,
23,17,251,2,0,
23,17,254,1,25,1
23,17,255,1,25,3
23,17,256,1,25,2
23,17,259,4,0,
23,17,263,4,0,
23,17,289,2,0,
23,17,305,2,0,
23,17,317,4,0,
23,17,342,2,0,
23,17,371,4,0,
23,17,380,1,36,
23,17,389,2,0,
23,17,398,4,0,
23,17,399,4,0,
23,17,415,2,0,
23,17,426,1,33,
23,17,441,1,49,
23,17,474,4,0,
23,17,482,4,0,
23,17,489,1,44,
23,17,491,1,28,
23,17,496,4,0,
23,17,523,4,0,
23,17,562,1,38,
23,17,590,4,0,
23,17,611,4,0,
23,17,693,4,0,
23,18,21,2,0,
23,18,35,1,1,1
23,18,40,1,4,
23,18,43,1,1,2
23,18,44,1,9,
23,18,50,2,0,
23,18,51,1,20,
23,18,89,4,0,
23,18,92,4,0,
23,18,103,1,17,
23,18,104,4,0,
23,18,114,1,41,
23,18,137,1,12,
23,18,156,4,0,
23,18,157,4,0,
23,18,164,4,0,
23,18,168,4,0,
23,18,180,2,0,
23,18,182,4,0,
23,18,184,2,0,
23,18,188,4,0,
23,18,207,4,0,
23,18,213,4,0,
23,18,214,4,0,
23,18,216,4,0,
23,18,218,4,0,
23,18,228,2,0,
23,18,231,2,0,
23,18,237,4,0,
23,18,240,4,0,
23,18,241,4,0,
23,18,251,2,0,
23,18,254,1,25,1
23,18,255,1,25,3
23,18,256,1,25,2
23,18,259,4,0,
23,18,263,4,0,
23,18,289,2,0,
23,18,305,2,0,
23,18,317,4,0,
23,18,342,2,0,
23,18,371,4,0,
23,18,380,1,36,
23,18,389,2,0,
23,18,398,4,0,
23,18,399,4,0,
23,18,415,2,0,
23,18,426,1,33,
23,18,441,1,49,
23,18,474,4,0,
23,18,482,4,0,
23,18,489,1,44,
23,18,491,1,28,
23,18,496,4,0,
23,18,523,4,0,
23,18,562,1,38,
23,18,590,4,0,
23,18,611,4,0,
23,18,693,4,0,
24,1,34,4,0,
24,1,35,1,1,1
24,1,36,4,0,
24,1,38,4,0,
24,1,40,1,1,3
24,1,40,1,10,
24,1,43,1,1,2
24,1,44,1,17,
24,1,51,1,47,
24,1,63,4,0,
24,1,70,4,0,
24,1,72,4,0,
24,1,89,4,0,
24,1,90,4,0,
24,1,91,4,0,
24,1,92,4,0,
24,1,99,4,0,
24,1,102,4,0,
24,1,103,1,36,
24,1,104,4,0,
24,1,117,4,0,
24,1,130,4,0,
24,1,137,1,27,
24,1,156,4,0,
24,1,157,4,0,
24,1,164,4,0,
24,2,34,4,0,
24,2,35,1,1,1
24,2,36,4,0,
24,2,38,4,0,
24,2,40,1,1,3
24,2,40,1,10,
24,2,43,1,1,2
24,2,44,1,17,
24,2,51,1,47,
24,2,63,4,0,
24,2,70,4,0,
24,2,72,4,0,
24,2,89,4,0,
24,2,90,4,0,
24,2,91,4,0,
24,2,92,4,0,
24,2,99,4,0,
24,2,102,4,0,
24,2,103,1,36,
24,2,104,4,0,
24,2,117,4,0,
24,2,130,4,0,
24,2,137,1,27,
24,2,156,4,0,
24,2,157,4,0,
24,2,164,4,0,
24,3,29,4,0,
24,3,35,1,1,1
24,3,40,1,1,3
24,3,40,1,9,
24,3,43,1,1,2
24,3,44,1,1,4
24,3,44,1,15,
24,3,51,1,43,
24,3,63,4,0,
24,3,70,4,0,
24,3,89,4,0,
24,3,91,4,0,
24,3,92,4,0,
24,3,103,1,33,
24,3,104,4,0,
24,3,114,1,51,
24,3,137,1,25,
24,3,156,4,0,
24,3,168,4,0,
24,3,173,4,0,
24,3,174,4,0,
24,3,182,4,0,
24,3,188,4,0,
24,3,202,4,0,
24,3,203,4,0,
24,3,207,4,0,
24,3,213,4,0,
24,3,214,4,0,
24,3,216,4,0,
24,3,218,4,0,
24,3,237,4,0,
24,3,241,4,0,
24,4,29,4,0,
24,4,35,1,1,1
24,4,40,1,1,3
24,4,40,1,9,
24,4,43,1,1,2
24,4,44,1,1,4
24,4,44,1,15,
24,4,51,1,43,
24,4,63,4,0,
24,4,70,4,0,
24,4,89,4,0,
24,4,91,4,0,
24,4,92,4,0,
24,4,103,1,33,
24,4,104,4,0,
24,4,114,1,51,
24,4,137,1,25,
24,4,156,4,0,
24,4,168,4,0,
24,4,173,4,0,
24,4,174,4,0,
24,4,182,4,0,
24,4,188,4,0,
24,4,202,4,0,
24,4,203,4,0,
24,4,207,4,0,
24,4,213,4,0,
24,4,214,4,0,
24,4,216,4,0,
24,4,218,4,0,
24,4,237,4,0,
24,4,241,4,0,
24,5,35,1,1,1
24,5,40,1,1,3
24,5,40,1,8,
24,5,43,1,1,2
24,5,44,1,1,4
24,5,44,1,13,
24,5,51,1,38,
24,5,63,4,0,
24,5,70,4,0,
24,5,89,4,0,
24,5,91,4,0,
24,5,92,4,0,
24,5,103,1,28,
24,5,104,4,0,
24,5,114,1,56,
24,5,137,1,20,
24,5,156,4,0,
24,5,168,4,0,
24,5,182,4,0,
24,5,188,4,0,
24,5,202,4,0,
24,5,213,4,0,
24,5,216,4,0,
24,5,218,4,0,
24,5,231,4,0,
24,5,237,4,0,
24,5,240,4,0,
24,5,241,4,0,
24,5,254,1,46,
24,5,255,1,46,6
24,5,256,1,46,5
24,5,259,4,0,
24,5,263,4,0,
24,5,289,4,0,
24,5,290,4,0,
24,6,34,3,0,
24,6,35,1,1,1
24,6,38,3,0,
24,6,40,1,1,3
24,6,40,1,8,
24,6,43,1,1,2
24,6,44,1,1,4
24,6,44,1,13,
24,6,51,1,38,
24,6,63,4,0,
24,6,70,4,0,
24,6,89,4,0,
24,6,91,4,0,
24,6,92,4,0,
24,6,102,3,0,
24,6,103,1,28,
24,6,104,4,0,
24,6,114,1,56,
24,6,137,1,20,
24,6,156,4,0,
24,6,157,3,0,
24,6,164,3,0,
24,6,168,4,0,
24,6,173,3,0,
24,6,182,4,0,
24,6,188,4,0,
24,6,202,4,0,
24,6,203,3,0,
24,6,207,3,0,
24,6,213,4,0,
24,6,214,3,0,
24,6,216,4,0,
24,6,218,4,0,
24,6,231,4,0,
24,6,237,4,0,
24,6,240,4,0,
24,6,241,4,0,
24,6,254,1,46,
24,6,255,1,46,6
24,6,256,1,46,5
24,6,259,4,0,
24,6,263,4,0,
24,6,289,4,0,
24,6,290,4,0,
24,7,34,3,0,
24,7,35,1,1,1
24,7,38,3,0,
24,7,40,1,1,3
24,7,40,1,8,
24,7,43,1,1,2
24,7,44,1,1,4
24,7,44,1,13,
24,7,51,1,38,
24,7,63,4,0,
24,7,70,4,0,
24,7,89,4,0,
24,7,91,4,0,
24,7,92,4,0,
24,7,102,3,0,
24,7,103,1,28,
24,7,104,4,0,
24,7,114,1,56,
24,7,137,1,20,
24,7,156,4,0,
24,7,157,3,0,
24,7,164,3,0,
24,7,168,4,0,
24,7,182,4,0,
24,7,188,4,0,
24,7,202,4,0,
24,7,213,4,0,
24,7,216,4,0,
24,7,218,4,0,
24,7,231,4,0,
24,7,237,4,0,
24,7,240,4,0,
24,7,241,4,0,
24,7,254,1,46,
24,7,255,1,46,6
24,7,256,1,46,5
24,7,259,4,0,
24,7,263,4,0,
24,7,289,4,0,
24,7,290,4,0,
24,8,35,1,1,4
24,8,40,1,1,6
24,8,40,1,4,
24,8,43,1,1,5
24,8,44,1,1,7
24,8,44,1,9,
24,8,51,1,20,
24,8,63,4,0,
24,8,70,4,0,
24,8,89,4,0,
24,8,91,4,0,
24,8,92,4,0,
24,8,103,1,17,
24,8,104,4,0,
24,8,114,1,48,
24,8,137,1,12,
24,8,156,4,0,
24,8,157,4,0,
24,8,164,4,0,
24,8,168,4,0,
24,8,182,4,0,
24,8,188,4,0,
24,8,202,4,0,
24,8,203,4,0,
24,8,207,4,0,
24,8,213,4,0,
24,8,214,4,0,
24,8,216,4,0,
24,8,218,4,0,
24,8,231,4,0,
24,8,237,4,0,
24,8,240,4,0,
24,8,241,4,0,
24,8,242,1,22,
24,8,254,1,28,1
24,8,255,1,28,3
24,8,256,1,28,2
24,8,259,4,0,
24,8,263,4,0,
24,8,289,4,0,
24,8,290,4,0,
24,8,317,4,0,
24,8,363,4,0,
24,8,371,4,0,
24,8,380,1,42,
24,8,398,4,0,
24,8,399,4,0,
24,8,416,4,0,
24,8,422,1,1,2
24,8,423,1,1,1
24,8,424,1,1,3
24,8,426,1,34,
24,8,441,1,56,
24,8,445,4,0,
24,9,35,1,1,4
24,9,40,1,1,6
24,9,40,1,4,
24,9,43,1,1,5
24,9,44,1,1,7
24,9,44,1,9,
24,9,51,1,20,
24,9,63,4,0,
24,9,70,4,0,
24,9,89,4,0,
24,9,91,4,0,
24,9,92,4,0,
24,9,103,1,17,
24,9,104,4,0,
24,9,114,1,48,
24,9,137,1,12,
24,9,156,4,0,
24,9,157,4,0,
24,9,164,4,0,
24,9,168,4,0,
24,9,173,3,0,
24,9,180,3,0,
24,9,182,4,0,
24,9,188,4,0,
24,9,202,4,0,
24,9,203,4,0,
24,9,207,4,0,
24,9,213,4,0,
24,9,214,4,0,
24,9,216,4,0,
24,9,218,4,0,
24,9,231,4,0,
24,9,237,4,0,
24,9,240,4,0,
24,9,241,4,0,
24,9,242,1,22,
24,9,254,1,28,1
24,9,255,1,28,3
24,9,256,1,28,2
24,9,259,4,0,
24,9,263,4,0,
24,9,289,4,0,
24,9,290,4,0,
24,9,317,4,0,
24,9,363,4,0,
24,9,371,4,0,
24,9,380,1,42,
24,9,380,3,0,
24,9,398,4,0,
24,9,399,4,0,
24,9,401,3,0,
24,9,402,3,0,
24,9,416,4,0,
24,9,422,1,1,2
24,9,423,1,1,1
24,9,424,1,1,3
24,9,426,1,34,
24,9,441,1,56,
24,9,441,3,0,
24,9,445,4,0,
24,10,29,3,0,
24,10,35,1,1,4
24,10,40,1,1,6
24,10,40,1,4,
24,10,43,1,1,5
24,10,44,1,1,7
24,10,44,1,9,
24,10,51,1,20,
24,10,63,4,0,
24,10,70,4,0,
24,10,89,4,0,
24,10,91,4,0,
24,10,92,4,0,
24,10,103,1,17,
24,10,104,4,0,
24,10,114,1,48,
24,10,137,1,12,
24,10,156,4,0,
24,10,157,4,0,
24,10,164,4,0,
24,10,168,4,0,
24,10,173,3,0,
24,10,180,3,0,
24,10,182,4,0,
24,10,188,4,0,
24,10,202,4,0,
24,10,203,4,0,
24,10,207,4,0,
24,10,213,4,0,
24,10,214,4,0,
24,10,216,4,0,
24,10,218,4,0,
24,10,231,4,0,
24,10,237,4,0,
24,10,240,4,0,
24,10,241,4,0,
24,10,242,1,22,
24,10,254,1,28,1
24,10,255,1,28,3
24,10,256,1,28,2
24,10,259,4,0,
24,10,263,4,0,
24,10,289,4,0,
24,10,290,4,0,
24,10,317,4,0,
24,10,363,4,0,
24,10,371,4,0,
24,10,380,1,42,
24,10,380,3,0,
24,10,398,4,0,
24,10,399,4,0,
24,10,401,3,0,
24,10,402,3,0,
24,10,416,4,0,
24,10,422,1,1,2
24,10,423,1,1,1
24,10,424,1,1,3
24,10,426,1,34,
24,10,441,1,56,
24,10,441,3,0,
24,10,445,4,0,
24,11,35,1,1,4
24,11,40,1,1,6
24,11,40,1,4,
24,11,43,1,1,5
24,11,44,1,1,7
24,11,44,1,9,
24,11,51,1,20,
24,11,63,4,0,
24,11,70,4,0,
24,11,89,4,0,
24,11,91,4,0,
24,11,92,4,0,
24,11,103,1,17,
24,11,104,4,0,
24,11,114,1,51,
24,11,137,1,12,
24,11,156,4,0,
24,11,157,4,0,
24,11,164,4,0,
24,11,168,4,0,
24,11,182,4,0,
24,11,188,4,0,
24,11,207,4,0,
24,11,213,4,0,
24,11,216,4,0,
24,11,218,4,0,
24,11,237,4,0,
24,11,240,4,0,
24,11,241,4,0,
24,11,242,1,22,
24,11,254,1,27,1
24,11,255,1,27,3
24,11,256,1,27,2
24,11,259,4,0,
24,11,263,4,0,
24,11,317,4,0,
24,11,371,4,0,
24,11,380,1,44,
24,11,398,4,0,
24,11,416,4,0,
24,11,422,1,1,2
24,11,423,1,1,1
24,11,424,1,1,3
24,11,426,1,39,
24,11,441,1,63,
24,11,474,4,0,
24,11,482,4,0,
24,11,489,1,56,
24,11,491,1,32,
24,11,496,4,0,
24,11,523,4,0,
24,11,525,4,0,
24,12,35,1,1,1
24,12,40,1,1,3
24,12,40,1,8,
24,12,43,1,1,2
24,12,44,1,1,4
24,12,44,1,13,
24,12,51,1,38,
24,12,63,4,0,
24,12,70,4,0,
24,12,89,4,0,
24,12,91,4,0,
24,12,92,4,0,
24,12,103,1,28,
24,12,104,4,0,
24,12,114,1,56,
24,12,137,1,20,
24,12,156,4,0,
24,12,168,4,0,
24,12,182,4,0,
24,12,188,4,0,
24,12,202,4,0,
24,12,213,4,0,
24,12,216,4,0,
24,12,218,4,0,
24,12,231,4,0,
24,12,237,4,0,
24,12,240,4,0,
24,12,241,4,0,
24,12,254,1,46,
24,12,255,1,46,6
24,12,256,1,46,5
24,12,259,4,0,
24,12,263,4,0,
24,12,289,4,0,
24,12,290,4,0,
24,13,34,3,0,
24,13,35,1,1,1
24,13,38,3,0,
24,13,40,1,1,3
24,13,40,1,8,
24,13,43,1,1,2
24,13,44,1,1,4
24,13,44,1,13,
24,13,51,1,38,
24,13,63,4,0,
24,13,70,4,0,
24,13,89,4,0,
24,13,91,4,0,
24,13,92,4,0,
24,13,102,3,0,
24,13,103,1,28,
24,13,104,4,0,
24,13,114,1,56,
24,13,137,1,20,
24,13,156,4,0,
24,13,164,3,0,
24,13,168,4,0,
24,13,182,4,0,
24,13,188,4,0,
24,13,202,4,0,
24,13,207,3,0,
24,13,213,4,0,
24,13,216,4,0,
24,13,218,4,0,
24,13,231,4,0,
24,13,237,4,0,
24,13,240,4,0,
24,13,241,4,0,
24,13,254,1,46,
24,13,255,1,46,6
24,13,256,1,46,5
24,13,259,4,0,
24,13,263,4,0,
24,13,289,4,0,
24,13,290,4,0,
24,14,20,3,0,
24,14,35,1,1,4
24,14,40,1,1,6
24,14,40,1,4,
24,14,43,1,1,5
24,14,44,1,1,7
24,14,44,1,9,
24,14,51,1,20,
24,14,63,4,0,
24,14,70,4,0,
24,14,89,4,0,
24,14,91,4,0,
24,14,92,4,0,
24,14,103,1,17,
24,14,104,4,0,
24,14,114,1,51,
24,14,137,1,12,
24,14,156,4,0,
24,14,157,4,0,
24,14,164,4,0,
24,14,168,4,0,
24,14,173,3,0,
24,14,180,3,0,
24,14,182,4,0,
24,14,188,4,0,
24,14,202,3,0,
24,14,207,4,0,
24,14,213,4,0,
24,14,214,3,0,
24,14,216,4,0,
24,14,218,4,0,
24,14,231,3,0,
24,14,237,4,0,
24,14,240,4,0,
24,14,241,4,0,
24,14,242,1,22,
24,14,254,1,27,1
24,14,255,1,27,3
24,14,256,1,27,2
24,14,259,4,0,
24,14,263,4,0,
24,14,289,3,0,
24,14,317,4,0,
24,14,371,4,0,
24,14,380,1,44,
24,14,380,3,0,
24,14,398,4,0,
24,14,399,3,0,
24,14,401,3,0,
24,14,402,3,0,
24,14,416,4,0,
24,14,422,1,1,2
24,14,423,1,1,1
24,14,424,1,1,3
24,14,426,1,39,
24,14,441,1,63,
24,14,441,3,0,
24,14,474,4,0,
24,14,482,4,0,
24,14,489,1,56,
24,14,491,1,32,
24,14,496,4,0,
24,14,523,4,0,
24,14,525,4,0,
24,15,35,1,1,4
24,15,40,1,1,6
24,15,40,1,4,
24,15,43,1,1,5
24,15,44,1,1,7
24,15,44,1,9,
24,15,51,1,20,
24,15,63,4,0,
24,15,70,4,0,
24,15,89,4,0,
24,15,91,4,0,
24,15,92,4,0,
24,15,103,1,17,
24,15,104,4,0,
24,15,114,1,51,
24,15,137,1,12,
24,15,156,4,0,
24,15,157,4,0,
24,15,164,4,0,
24,15,168,4,0,
24,15,182,4,0,
24,15,188,4,0,
24,15,207,4,0,
24,15,213,4,0,
24,15,214,4,0,
24,15,216,4,0,
24,15,218,4,0,
24,15,237,4,0,
24,15,240,4,0,
24,15,241,4,0,
24,15,242,1,22,
24,15,254,1,27,1
24,15,255,1,27,3
24,15,256,1,27,2
24,15,259,4,0,
24,15,263,4,0,
24,15,317,4,0,
24,15,371,4,0,
24,15,380,1,44,
24,15,398,4,0,
24,15,399,4,0,
24,15,416,4,0,
24,15,422,1,1,2
24,15,423,1,1,1
24,15,424,1,1,3
24,15,426,1,39,
24,15,441,1,63,
24,15,474,4,0,
24,15,482,4,0,
24,15,489,1,56,
24,15,491,1,32,
24,15,496,4,0,
24,15,523,4,0,
24,15,525,4,0,
24,15,562,1,48,
24,15,590,4,0,
24,15,611,4,0,
24,16,20,3,0,
24,16,35,1,1,4
24,16,40,1,1,6
24,16,40,1,4,1
24,16,43,1,1,5
24,16,44,1,1,7
24,16,44,1,9,1
24,16,51,1,20,1
24,16,63,4,0,
24,16,70,4,0,
24,16,89,4,0,
24,16,91,4,0,
24,16,92,4,0,
24,16,103,1,17,1
24,16,104,4,0,
24,16,114,1,51,1
24,16,137,1,12,1
24,16,156,4,0,
24,16,157,4,0,
24,16,164,4,0,
24,16,168,4,0,
24,16,173,3,0,
24,16,180,3,0,
24,16,182,4,0,
24,16,188,4,0,
24,16,202,3,0,
24,16,207,4,0,
24,16,213,4,0,
24,16,214,4,0,
24,16,216,4,0,
24,16,218,4,0,
24,16,231,3,0,
24,16,237,4,0,
24,16,240,4,0,
24,16,241,4,0,
24,16,242,1,22,1
24,16,254,1,27,1
24,16,255,1,27,3
24,16,256,1,27,2
24,16,259,4,0,
24,16,263,4,0,
24,16,289,3,0,
24,16,290,4,0,
24,16,317,4,0,
24,16,371,4,0,
24,16,380,1,44,1
24,16,380,3,0,
24,16,398,4,0,
24,16,399,4,0,
24,16,401,3,0,
24,16,402,3,0,
24,16,416,4,0,
24,16,422,1,1,2
24,16,423,1,1,1
24,16,424,1,1,3
24,16,426,1,39,1
24,16,441,1,63,1
24,16,441,3,0,
24,16,474,4,0,
24,16,482,4,0,
24,16,489,1,56,1
24,16,491,1,32,1
24,16,496,4,0,
24,16,523,4,0,
24,16,525,4,0,
24,16,562,1,48,1
24,16,590,4,0,
24,16,611,4,0,
24,17,35,1,1,5
24,17,40,1,1,7
24,17,40,1,4,
24,17,43,1,1,6
24,17,44,1,1,8
24,17,44,1,9,
24,17,51,1,20,
24,17,63,4,0,
24,17,89,4,0,
24,17,92,4,0,
24,17,103,1,17,
24,17,104,4,0,
24,17,114,1,51,
24,17,137,1,12,
24,17,156,4,0,
24,17,157,4,0,
24,17,164,4,0,
24,17,168,4,0,
24,17,182,4,0,
24,17,188,4,0,
24,17,207,4,0,
24,17,213,4,0,
24,17,214,4,0,
24,17,216,4,0,
24,17,218,4,0,
24,17,237,4,0,
24,17,240,4,0,
24,17,241,4,0,
24,17,242,1,0,
24,17,242,1,1,1
24,17,254,1,27,1
24,17,255,1,27,3
24,17,256,1,27,2
24,17,259,4,0,
24,17,263,4,0,
24,17,317,4,0,
24,17,371,4,0,
24,17,380,1,44,
24,17,398,4,0,
24,17,399,4,0,
24,17,416,4,0,
24,17,422,1,1,3
24,17,423,1,1,2
24,17,424,1,1,4
24,17,426,1,39,
24,17,441,1,63,
24,17,474,4,0,
24,17,482,4,0,
24,17,489,1,56,
24,17,491,1,32,
24,17,496,4,0,
24,17,523,4,0,
24,17,525,4,0,
24,17,562,1,48,
24,17,590,4,0,
24,17,611,4,0,
24,17,693,4,0,
24,18,35,1,1,5
24,18,40,1,1,7
24,18,40,1,4,
24,18,43,1,1,6
24,18,44,1,1,8
24,18,44,1,9,
24,18,51,1,20,
24,18,63,4,0,
24,18,89,4,0,
24,18,92,4,0,
24,18,103,1,17,
24,18,104,4,0,
24,18,114,1,51,
24,18,137,1,12,
24,18,156,4,0,
24,18,157,4,0,
24,18,164,4,0,
24,18,168,4,0,
24,18,182,4,0,
24,18,188,4,0,
24,18,207,4,0,
24,18,213,4,0,
24,18,214,4,0,
24,18,216,4,0,
24,18,218,4,0,
24,18,237,4,0,
24,18,240,4,0,
24,18,241,4,0,
24,18,242,1,0,
24,18,242,1,1,1
24,18,254,1,27,1
24,18,255,1,27,3
24,18,256,1,27,2
24,18,259,4,0,
24,18,263,4,0,
24,18,317,4,0,
24,18,371,4,0,
24,18,380,1,44,
24,18,398,4,0,
24,18,399,4,0,
24,18,416,4,0,
24,18,422,1,1,3
24,18,423,1,1,2
24,18,424,1,1,4
24,18,426,1,39,
24,18,441,1,63,
24,18,474,4,0,
24,18,482,4,0,
24,18,489,1,56,
24,18,491,1,32,
24,18,496,4,0,
24,18,523,4,0,
24,18,525,4,0,
24,18,562,1,48,
24,18,590,4,0,
24,18,611,4,0,
24,18,693,4,0,
25,1,5,4,0,
25,1,6,4,0,
25,1,25,4,0,
25,1,34,4,0,
25,1,36,4,0,
25,1,38,4,0,
25,1,45,1,1,2
25,1,57,5,0,
25,1,66,4,0,
25,1,69,4,0,
25,1,84,1,1,1
25,1,85,4,0,
25,1,86,1,9,
25,1,86,4,0,
25,1,87,1,43,
25,1,87,4,0,
25,1,92,4,0,
25,1,97,1,33,
25,1,98,1,16,
25,1,99,4,0,
25,1,102,4,0,
25,1,104,4,0,
25,1,115,4,0,
25,1,117,4,0,
25,1,129,1,26,
25,1,129,4,0,
25,1,130,4,0,
25,1,148,4,0,
25,1,156,4,0,
25,1,164,4,0,
25,2,5,4,0,
25,2,6,4,0,
25,2,21,1,20,
25,2,25,4,0,
25,2,34,4,0,
25,2,36,4,0,
25,2,38,4,0,
25,2,39,1,6,
25,2,45,1,1,2
25,2,57,5,0,
25,2,66,4,0,
25,2,69,4,0,
25,2,84,1,1,1
25,2,85,1,26,
25,2,85,4,0,
25,2,86,1,8,
25,2,86,4,0,
25,2,87,1,41,
25,2,87,4,0,
25,2,92,4,0,
25,2,97,1,33,
25,2,98,1,11,
25,2,99,4,0,
25,2,102,4,0,
25,2,104,1,15,
25,2,104,4,0,
25,2,113,1,50,
25,2,115,4,0,
25,2,117,4,0,
25,2,129,4,0,
25,2,130,4,0,
25,2,148,4,0,
25,2,156,4,0,
25,2,164,4,0,
25,3,9,4,0,
25,3,21,1,20,
25,3,29,4,0,
25,3,39,1,6,
25,3,45,1,1,2
25,3,70,4,0,
25,3,84,1,1,1
25,3,85,1,26,
25,3,86,1,8,
25,3,87,1,41,
25,3,87,4,0,
25,3,92,4,0,
25,3,97,1,33,
25,3,98,1,11,
25,3,104,1,15,
25,3,104,4,0,
25,3,111,4,0,
25,3,113,1,50,
25,3,129,4,0,
25,3,148,4,0,
25,3,156,4,0,
25,3,173,4,0,
25,3,174,4,0,
25,3,182,4,0,
25,3,189,4,0,
25,3,192,4,0,
25,3,197,4,0,
25,3,203,4,0,
25,3,205,4,0,
25,3,207,4,0,
25,3,213,4,0,
25,3,214,4,0,
25,3,216,4,0,
25,3,218,4,0,
25,3,223,4,0,
25,3,231,4,0,
25,3,237,4,0,
25,3,240,4,0,
25,4,9,4,0,
25,4,21,1,20,
25,4,29,4,0,
25,4,39,1,6,
25,4,45,1,1,2
25,4,70,4,0,
25,4,84,1,1,1
25,4,85,1,26,
25,4,85,3,0,
25,4,86,1,8,
25,4,87,1,41,
25,4,87,4,0,
25,4,92,4,0,
25,4,97,1,33,
25,4,98,1,11,
25,4,104,1,15,
25,4,104,4,0,
25,4,111,4,0,
25,4,113,1,50,
25,4,129,4,0,
25,4,148,4,0,
25,4,156,4,0,
25,4,173,4,0,
25,4,174,4,0,
25,4,182,4,0,
25,4,189,4,0,
25,4,192,4,0,
25,4,197,4,0,
25,4,203,4,0,
25,4,205,4,0,
25,4,207,4,0,
25,4,213,4,0,
25,4,214,4,0,
25,4,216,4,0,
25,4,218,4,0,
25,4,223,4,0,
25,4,231,4,0,
25,4,237,4,0,
25,4,240,4,0,
25,5,21,1,20,
25,5,39,1,6,
25,5,45,1,1,2
25,5,70,4,0,
25,5,84,1,1,1
25,5,85,1,26,
25,5,85,4,0,
25,5,86,1,8,
25,5,87,1,41,
25,5,87,4,0,
25,5,91,4,0,
25,5,92,4,0,
25,5,97,1,33,
25,5,98,1,11,
25,5,104,1,15,
25,5,104,4,0,
25,5,113,1,50,
25,5,113,4,0,
25,5,148,4,0,
25,5,156,4,0,
25,5,182,4,0,
25,5,213,4,0,
25,5,216,4,0,
25,5,218,4,0,
25,5,231,4,0,
25,5,237,4,0,
25,5,240,4,0,
25,5,249,4,0,
25,5,263,4,0,
25,5,264,4,0,
25,5,280,4,0,
25,5,290,4,0,
25,5,351,4,0,
25,6,5,3,0,
25,6,9,3,0,
25,6,21,1,20,
25,6,25,3,0,
25,6,34,3,0,
25,6,38,3,0,
25,6,39,1,6,
25,6,45,1,1,2
25,6,68,3,0,
25,6,69,3,0,
25,6,70,4,0,
25,6,84,1,1,1
25,6,85,1,26,
25,6,85,4,0,
25,6,86,1,8,
25,6,86,3,0,
25,6,87,1,41,
25,6,87,4,0,
25,6,91,4,0,
25,6,92,4,0,
25,6,97,1,33,
25,6,98,1,11,
25,6,102,3,0,
25,6,104,1,15,
25,6,104,4,0,
25,6,111,3,0,
25,6,113,1,50,
25,6,113,4,0,
25,6,129,3,0,
25,6,148,4,0,
25,6,156,4,0,
25,6,164,3,0,
25,6,173,3,0,
25,6,182,4,0,
25,6,189,3,0,
25,6,203,3,0,
25,6,205,3,0,
25,6,207,3,0,
25,6,213,4,0,
25,6,214,3,0,
25,6,216,4,0,
25,6,218,4,0,
25,6,223,3,0,
25,6,231,4,0,
25,6,237,4,0,
25,6,240,4,0,
25,6,249,4,0,
25,6,263,4,0,
25,6,264,4,0,
25,6,280,4,0,
25,6,290,4,0,
25,6,351,4,0,
25,7,5,3,0,
25,7,21,1,20,
25,7,25,3,0,
25,7,34,3,0,
25,7,38,3,0,
25,7,39,1,6,
25,7,45,1,1,2
25,7,68,3,0,
25,7,69,3,0,
25,7,70,4,0,
25,7,84,1,1,1
25,7,85,1,26,
25,7,85,4,0,
25,7,86,1,8,
25,7,86,3,0,
25,7,87,1,41,
25,7,87,4,0,
25,7,91,4,0,
25,7,92,4,0,
25,7,97,1,33,
25,7,98,1,11,
25,7,102,3,0,
25,7,104,1,15,
25,7,104,4,0,
25,7,113,1,50,
25,7,113,4,0,
25,7,148,4,0,
25,7,156,4,0,
25,7,164,3,0,
25,7,182,4,0,
25,7,213,4,0,
25,7,216,4,0,
25,7,218,4,0,
25,7,231,4,0,
25,7,237,4,0,
25,7,240,4,0,
25,7,249,4,0,
25,7,263,4,0,
25,7,264,4,0,
25,7,280,4,0,
25,7,290,4,0,
25,7,351,4,0,
25,8,21,1,21,
25,8,39,1,5,
25,8,45,1,1,2
25,8,70,4,0,
25,8,84,1,1,1
25,8,85,1,26,
25,8,85,4,0,
25,8,86,1,10,
25,8,86,4,0,
25,8,87,1,45,
25,8,87,4,0,
25,8,91,4,0,
25,8,92,4,0,
25,8,97,1,34,
25,8,98,1,13,
25,8,104,1,18,
25,8,104,4,0,
25,8,113,1,42,
25,8,113,4,0,
25,8,148,4,0,
25,8,156,4,0,
25,8,164,4,0,
25,8,182,4,0,
25,8,203,4,0,
25,8,207,4,0,
25,8,213,4,0,
25,8,214,4,0,
25,8,216,4,0,
25,8,218,4,0,
25,8,231,4,0,
25,8,237,4,0,
25,8,240,4,0,
25,8,249,4,0,
25,8,263,4,0,
25,8,264,4,0,
25,8,280,4,0,
25,8,290,4,0,
25,8,351,4,0,
25,8,363,4,0,
25,8,364,1,29,
25,8,374,4,0,
25,8,435,1,37,
25,8,445,4,0,
25,8,447,4,0,
25,8,451,4,0,
25,9,9,3,0,
25,9,21,1,21,
25,9,39,1,5,
25,9,45,1,1,2
25,9,70,4,0,
25,9,84,1,1,1
25,9,85,1,26,
25,9,85,4,0,
25,9,86,1,10,
25,9,86,4,0,
25,9,87,1,45,
25,9,87,4,0,
25,9,91,4,0,
25,9,92,4,0,
25,9,97,1,34,
25,9,98,1,13,
25,9,104,1,18,
25,9,104,4,0,
25,9,113,1,42,
25,9,113,4,0,
25,9,129,3,0,
25,9,148,4,0,
25,9,156,4,0,
25,9,164,4,0,
25,9,173,3,0,
25,9,182,4,0,
25,9,189,3,0,
25,9,203,4,0,
25,9,205,3,0,
25,9,207,4,0,
25,9,213,4,0,
25,9,214,4,0,
25,9,216,4,0,
25,9,218,4,0,
25,9,231,4,0,
25,9,237,4,0,
25,9,240,4,0,
25,9,249,4,0,
25,9,263,4,0,
25,9,264,4,0,
25,9,270,3,0,
25,9,280,4,0,
25,9,282,3,0,
25,9,290,4,0,
25,9,324,3,0,
25,9,351,4,0,
25,9,363,4,0,
25,9,364,1,29,
25,9,374,4,0,
25,9,393,3,0,
25,9,435,1,37,
25,9,445,4,0,
25,9,447,4,0,
25,9,451,4,0,
25,10,9,3,0,
25,10,21,1,21,
25,10,29,3,0,
25,10,39,1,5,
25,10,45,1,1,2
25,10,70,4,0,
25,10,84,1,1,1
25,10,85,1,26,
25,10,85,4,0,
25,10,86,1,10,
25,10,86,4,0,
25,10,87,1,45,
25,10,87,4,0,
25,10,91,4,0,
25,10,92,4,0,
25,10,97,1,34,
25,10,98,1,13,
25,10,104,1,18,
25,10,104,4,0,
25,10,113,1,42,
25,10,113,4,0,
25,10,129,3,0,
25,10,148,4,0,
25,10,156,4,0,
25,10,164,4,0,
25,10,173,3,0,
25,10,182,4,0,
25,10,189,3,0,
25,10,203,4,0,
25,10,205,3,0,
25,10,207,4,0,
25,10,213,4,0,
25,10,214,4,0,
25,10,216,4,0,
25,10,218,4,0,
25,10,231,4,0,
25,10,237,4,0,
25,10,240,4,0,
25,10,249,4,0,
25,10,263,4,0,
25,10,264,4,0,
25,10,270,3,0,
25,10,280,4,0,
25,10,282,3,0,
25,10,290,4,0,
25,10,324,3,0,
25,10,351,4,0,
25,10,363,4,0,
25,10,364,1,29,
25,10,374,4,0,
25,10,393,3,0,
25,10,435,1,37,
25,10,445,4,0,
25,10,447,4,0,
25,10,451,4,0,
25,11,21,1,26,
25,11,39,1,5,
25,11,45,1,1,1
25,11,70,4,0,
25,11,84,1,1,2
25,11,85,1,29,
25,11,85,4,0,
25,11,86,1,10,
25,11,86,4,0,
25,11,87,1,50,
25,11,87,4,0,
25,11,91,4,0,
25,11,92,4,0,
25,11,97,1,37,
25,11,98,1,13,
25,11,104,1,21,
25,11,104,4,0,
25,11,113,1,45,
25,11,113,4,0,
25,11,148,4,0,
25,11,156,4,0,
25,11,164,4,0,
25,11,182,4,0,
25,11,207,4,0,
25,11,213,4,0,
25,11,216,4,0,
25,11,218,4,0,
25,11,237,4,0,
25,11,240,4,0,
25,11,249,4,0,
25,11,263,4,0,
25,11,280,4,0,
25,11,364,1,34,
25,11,374,4,0,
25,11,435,1,42,
25,11,447,4,0,
25,11,451,4,0,
25,11,486,1,18,
25,11,496,4,0,
25,11,497,4,0,
25,11,521,4,0,
25,11,528,4,0,
25,12,21,1,20,
25,12,39,1,6,
25,12,45,1,1,2
25,12,70,4,0,
25,12,84,1,1,1
25,12,85,1,26,
25,12,85,4,0,
25,12,86,1,8,
25,12,87,1,41,
25,12,87,4,0,
25,12,91,4,0,
25,12,92,4,0,
25,12,97,1,33,
25,12,98,1,11,
25,12,104,1,15,
25,12,104,4,0,
25,12,113,1,50,
25,12,113,4,0,
25,12,148,4,0,
25,12,156,4,0,
25,12,182,4,0,
25,12,213,4,0,
25,12,216,4,0,
25,12,218,4,0,
25,12,231,4,0,
25,12,237,4,0,
25,12,240,4,0,
25,12,249,4,0,
25,12,263,4,0,
25,12,264,4,0,
25,12,280,4,0,
25,12,290,4,0,
25,12,351,4,0,
25,13,21,1,20,
25,13,34,3,0,
25,13,38,3,0,
25,13,39,1,6,
25,13,45,1,1,2
25,13,69,3,0,
25,13,70,4,0,
25,13,84,1,1,1
25,13,85,1,26,
25,13,85,4,0,
25,13,86,1,8,
25,13,86,3,0,
25,13,87,1,41,
25,13,87,4,0,
25,13,91,4,0,
25,13,92,4,0,
25,13,97,1,33,
25,13,98,1,11,
25,13,102,3,0,
25,13,104,1,15,
25,13,104,4,0,
25,13,113,1,50,
25,13,113,4,0,
25,13,148,4,0,
25,13,156,4,0,
25,13,164,3,0,
25,13,182,4,0,
25,13,207,3,0,
25,13,213,4,0,
25,13,216,4,0,
25,13,218,4,0,
25,13,231,4,0,
25,13,237,4,0,
25,13,240,4,0,
25,13,249,4,0,
25,13,263,4,0,
25,13,264,4,0,
25,13,280,4,0,
25,13,290,4,0,
25,13,351,4,0,
25,14,9,3,0,
25,14,21,1,26,
25,14,39,1,5,
25,14,45,1,1,1
25,14,70,4,0,
25,14,84,1,1,2
25,14,85,1,29,
25,14,85,4,0,
25,14,86,1,10,
25,14,86,4,0,
25,14,87,1,50,
25,14,87,4,0,
25,14,91,4,0,
25,14,92,4,0,
25,14,97,1,37,
25,14,98,1,13,
25,14,104,1,21,
25,14,104,4,0,
25,14,113,1,45,
25,14,113,4,0,
25,14,148,4,0,
25,14,156,4,0,
25,14,164,4,0,
25,14,173,3,0,
25,14,182,4,0,
25,14,207,4,0,
25,14,213,4,0,
25,14,214,3,0,
25,14,216,4,0,
25,14,218,4,0,
25,14,231,3,0,
25,14,237,4,0,
25,14,240,4,0,
25,14,249,4,0,
25,14,263,4,0,
25,14,270,3,0,
25,14,280,4,0,
25,14,282,3,0,
25,14,324,3,0,
25,14,343,3,0,
25,14,364,1,34,
25,14,374,4,0,
25,14,393,3,0,
25,14,435,1,42,
25,14,447,4,0,
25,14,451,4,0,
25,14,486,1,18,
25,14,496,4,0,
25,14,497,4,0,
25,14,521,4,0,
25,14,528,4,0,
25,15,21,1,26,
25,15,39,1,1,1
25,15,45,1,5,
25,15,70,4,0,
25,15,84,1,1,2
25,15,85,1,29,
25,15,85,4,0,
25,15,86,1,13,
25,15,86,4,0,
25,15,87,1,50,
25,15,87,4,0,
25,15,91,4,0,
25,15,92,4,0,
25,15,97,1,37,
25,15,98,1,10,
25,15,104,1,21,
25,15,104,4,0,
25,15,113,1,45,
25,15,113,4,0,
25,15,148,4,0,
25,15,156,4,0,
25,15,164,4,0,
25,15,182,4,0,
25,15,207,4,0,
25,15,213,4,0,
25,15,214,4,0,
25,15,216,4,0,
25,15,218,4,0,
25,15,237,4,0,
25,15,240,4,0,
25,15,249,4,0,
25,15,263,4,0,
25,15,280,4,0,
25,15,364,1,34,
25,15,374,4,0,
25,15,435,1,42,
25,15,447,4,0,
25,15,451,4,0,
25,15,486,1,18,
25,15,496,4,0,
25,15,497,4,0,
25,15,521,4,0,
25,15,528,4,0,
25,15,589,1,7,
25,15,590,4,0,
25,15,609,1,23,
25,16,9,3,0,
25,16,21,1,37,1
25,16,39,1,1,1
25,16,45,1,5,1
25,16,70,4,0,
25,16,84,1,1,2
25,16,85,1,42,1
25,16,85,4,0,
25,16,86,1,18,1
25,16,86,4,0,
25,16,87,1,58,1
25,16,87,4,0,
25,16,91,4,0,
25,16,92,4,0,
25,16,97,1,45,1
25,16,98,1,10,1
25,16,104,1,23,1
25,16,104,4,0,
25,16,113,1,53,1
25,16,113,4,0,
25,16,148,4,0,
25,16,156,4,0,
25,16,164,4,0,
25,16,173,3,0,
25,16,182,4,0,
25,16,207,4,0,
25,16,209,1,26,1
25,16,213,4,0,
25,16,214,4,0,
25,16,216,4,0,
25,16,218,4,0,
25,16,231,3,0,
25,16,237,4,0,
25,16,240,4,0,
25,16,249,4,0,
25,16,263,4,0,
25,16,264,3,0,
25,16,270,3,0,
25,16,280,4,0,
25,16,282,3,0,
25,16,290,4,0,
25,16,324,3,0,
25,16,343,3,0,
25,16,351,3,0,
25,16,364,1,21,1
25,16,374,4,0,
25,16,393,3,0,
25,16,435,1,34,1
25,16,447,4,0,
25,16,451,4,0,
25,16,486,1,13,1
25,16,496,4,0,
25,16,497,4,0,
25,16,521,4,0,
25,16,527,3,0,
25,16,528,1,50,1
25,16,528,4,0,
25,16,589,1,7,1
25,16,590,4,0,
25,16,609,1,29,1
25,17,21,1,37,
25,17,39,1,1,1
25,17,45,1,5,
25,17,84,1,1,2
25,17,85,1,42,
25,17,85,4,0,
25,17,86,1,18,
25,17,86,4,0,
25,17,87,1,58,
25,17,87,4,0,
25,17,92,4,0,
25,17,97,1,45,
25,17,98,1,10,
25,17,104,1,23,
25,17,104,4,0,
25,17,113,1,53,
25,17,113,4,0,
25,17,156,4,0,
25,17,164,4,0,
25,17,182,4,0,
25,17,207,4,0,
25,17,209,1,26,
25,17,213,4,0,
25,17,214,4,0,
25,17,216,4,0,
25,17,218,4,0,
25,17,237,4,0,
25,17,240,4,0,
25,17,263,4,0,
25,17,280,4,0,
25,17,364,1,21,
25,17,374,4,0,
25,17,435,1,34,
25,17,447,4,0,
25,17,451,4,0,
25,17,486,1,13,
25,17,496,4,0,
25,17,497,4,0,
25,17,521,4,0,
25,17,528,1,50,
25,17,528,4,0,
25,17,589,1,7,
25,17,590,4,0,
25,17,609,1,29,
25,18,21,1,37,
25,18,39,1,1,1
25,18,45,1,5,
25,18,84,1,1,2
25,18,85,1,42,
25,18,85,4,0,
25,18,86,1,18,
25,18,86,4,0,
25,18,87,1,58,
25,18,87,4,0,
25,18,92,4,0,
25,18,97,1,45,
25,18,98,1,10,
25,18,104,1,23,
25,18,104,4,0,
25,18,113,1,53,
25,18,113,4,0,
25,18,156,4,0,
25,18,164,4,0,
25,18,182,4,0,
25,18,207,4,0,
25,18,209,1,26,
25,18,213,4,0,
25,18,214,4,0,
25,18,216,4,0,
25,18,218,4,0,
25,18,237,4,0,
25,18,240,4,0,
25,18,263,4,0,
25,18,280,4,0,
25,18,364,1,21,
25,18,374,4,0,
25,18,435,1,34,
25,18,447,4,0,
25,18,451,4,0,
25,18,486,1,13,
25,18,496,4,0,
25,18,497,4,0,
25,18,521,4,0,
25,18,528,1,50,
25,18,528,4,0,
25,18,589,1,7,
25,18,590,4,0,
25,18,609,1,29,
26,1,5,4,0,
26,1,6,4,0,
26,1,25,4,0,
26,1,34,4,0,
26,1,36,4,0,
26,1,38,4,0,
26,1,45,1,1,2
26,1,63,4,0,
26,1,66,4,0,
26,1,69,4,0,
26,1,84,1,1,1
26,1,85,4,0,
26,1,86,1,1,3
26,1,86,4,0,
26,1,87,4,0,
26,1,92,4,0,
26,1,99,4,0,
26,1,102,4,0,
26,1,104,4,0,
26,1,115,4,0,
26,1,117,4,0,
26,1,129,4,0,
26,1,130,4,0,
26,1,148,4,0,
26,1,156,4,0,
26,1,164,4,0,
26,2,5,4,0,
26,2,6,4,0,
26,2,25,4,0,
26,2,34,4,0,
26,2,36,4,0,
26,2,38,4,0,
26,2,45,1,1,2
26,2,63,4,0,
26,2,66,4,0,
26,2,69,4,0,
26,2,84,1,1,1
26,2,85,4,0,
26,2,86,1,1,3
26,2,86,4,0,
26,2,87,4,0,
26,2,92,4,0,
26,2,99,4,0,
26,2,102,4,0,
26,2,104,4,0,
26,2,115,4,0,
26,2,117,4,0,
26,2,129,4,0,
26,2,130,4,0,
26,2,148,4,0,
26,2,156,4,0,
26,2,164,4,0,
26,3,9,4,0,
26,3,29,4,0,
26,3,39,1,1,2
26,3,63,4,0,
26,3,70,4,0,
26,3,84,1,1,1
26,3,85,1,1,4
26,3,87,4,0,
26,3,92,4,0,
26,3,98,1,1,3
26,3,104,4,0,
26,3,111,4,0,
26,3,129,4,0,
26,3,148,4,0,
26,3,156,4,0,
26,3,168,4,0,
26,3,173,4,0,
26,3,174,4,0,
26,3,182,4,0,
26,3,189,4,0,
26,3,192,4,0,
26,3,197,4,0,
26,3,203,4,0,
26,3,205,4,0,
26,3,207,4,0,
26,3,213,4,0,
26,3,214,4,0,
26,3,216,4,0,
26,3,218,4,0,
26,3,223,4,0,
26,3,231,4,0,
26,3,237,4,0,
26,3,240,4,0,
26,4,9,4,0,
26,4,29,4,0,
26,4,39,1,1,2
26,4,63,4,0,
26,4,70,4,0,
26,4,84,1,1,1
26,4,85,1,1,4
26,4,85,3,0,
26,4,87,4,0,
26,4,92,4,0,
26,4,98,1,1,3
26,4,104,4,0,
26,4,111,4,0,
26,4,129,4,0,
26,4,148,4,0,
26,4,156,4,0,
26,4,168,4,0,
26,4,173,4,0,
26,4,174,4,0,
26,4,182,4,0,
26,4,189,4,0,
26,4,192,4,0,
26,4,197,4,0,
26,4,203,4,0,
26,4,205,4,0,
26,4,207,4,0,
26,4,213,4,0,
26,4,214,4,0,
26,4,216,4,0,
26,4,218,4,0,
26,4,223,4,0,
26,4,231,4,0,
26,4,237,4,0,
26,4,240,4,0,
26,5,39,1,1,2
26,5,63,4,0,
26,5,70,4,0,
26,5,84,1,1,1
26,5,85,1,1,4
26,5,85,4,0,
26,5,87,4,0,
26,5,91,4,0,
26,5,92,4,0,
26,5,98,1,1,3
26,5,104,4,0,
26,5,113,4,0,
26,5,148,4,0,
26,5,156,4,0,
26,5,168,4,0,
26,5,182,4,0,
26,5,213,4,0,
26,5,216,4,0,
26,5,218,4,0,
26,5,231,4,0,
26,5,237,4,0,
26,5,240,4,0,
26,5,249,4,0,
26,5,263,4,0,
26,5,264,4,0,
26,5,280,4,0,
26,5,290,4,0,
26,5,351,4,0,
26,6,5,3,0,
26,6,9,3,0,
26,6,25,3,0,
26,6,34,3,0,
26,6,38,3,0,
26,6,39,1,1,2
26,6,63,4,0,
26,6,68,3,0,
26,6,69,3,0,
26,6,70,4,0,
26,6,84,1,1,1
26,6,85,1,1,4
26,6,85,4,0,
26,6,86,3,0,
26,6,87,4,0,
26,6,91,4,0,
26,6,92,4,0,
26,6,98,1,1,3
26,6,102,3,0,
26,6,104,4,0,
26,6,111,3,0,
26,6,113,4,0,
26,6,129,3,0,
26,6,148,4,0,
26,6,156,4,0,
26,6,164,3,0,
26,6,168,4,0,
26,6,173,3,0,
26,6,182,4,0,
26,6,189,3,0,
26,6,203,3,0,
26,6,205,3,0,
26,6,207,3,0,
26,6,213,4,0,
26,6,214,3,0,
26,6,216,4,0,
26,6,218,4,0,
26,6,223,3,0,
26,6,231,4,0,
26,6,237,4,0,
26,6,240,4,0,
26,6,249,4,0,
26,6,263,4,0,
26,6,264,4,0,
26,6,280,4,0,
26,6,290,4,0,
26,6,351,4,0,
26,7,5,3,0,
26,7,25,3,0,
26,7,34,3,0,
26,7,38,3,0,
26,7,39,1,1,2
26,7,63,4,0,
26,7,68,3,0,
26,7,69,3,0,
26,7,70,4,0,
26,7,84,1,1,1
26,7,85,1,1,4
26,7,85,4,0,
26,7,86,3,0,
26,7,87,4,0,
26,7,91,4,0,
26,7,92,4,0,
26,7,98,1,1,3
26,7,102,3,0,
26,7,104,4,0,
26,7,113,4,0,
26,7,148,4,0,
26,7,156,4,0,
26,7,164,3,0,
26,7,168,4,0,
26,7,182,4,0,
26,7,213,4,0,
26,7,216,4,0,
26,7,218,4,0,
26,7,231,4,0,
26,7,237,4,0,
26,7,240,4,0,
26,7,249,4,0,
26,7,263,4,0,
26,7,264,4,0,
26,7,280,4,0,
26,7,290,4,0,
26,7,351,4,0,
26,8,39,1,1,2
26,8,63,4,0,
26,8,70,4,0,
26,8,84,1,1,1
26,8,85,1,1,4
26,8,85,4,0,
26,8,86,4,0,
26,8,87,4,0,
26,8,91,4,0,
26,8,92,4,0,
26,8,98,1,1,3
26,8,104,4,0,
26,8,113,4,0,
26,8,148,4,0,
26,8,156,4,0,
26,8,164,4,0,
26,8,168,4,0,
26,8,182,4,0,
26,8,203,4,0,
26,8,207,4,0,
26,8,213,4,0,
26,8,214,4,0,
26,8,216,4,0,
26,8,218,4,0,
26,8,231,4,0,
26,8,237,4,0,
26,8,240,4,0,
26,8,249,4,0,
26,8,263,4,0,
26,8,264,4,0,
26,8,280,4,0,
26,8,290,4,0,
26,8,351,4,0,
26,8,363,4,0,
26,8,374,4,0,
26,8,411,4,0,
26,8,416,4,0,
26,8,445,4,0,
26,8,447,4,0,
26,8,451,4,0,
26,9,9,3,0,
26,9,39,1,1,2
26,9,63,4,0,
26,9,70,4,0,
26,9,84,1,1,1
26,9,85,1,1,4
26,9,85,4,0,
26,9,86,4,0,
26,9,87,4,0,
26,9,91,4,0,
26,9,92,4,0,
26,9,98,1,1,3
26,9,104,4,0,
26,9,113,4,0,
26,9,129,3,0,
26,9,148,4,0,
26,9,156,4,0,
26,9,164,4,0,
26,9,168,4,0,
26,9,173,3,0,
26,9,182,4,0,
26,9,189,3,0,
26,9,203,4,0,
26,9,205,3,0,
26,9,207,4,0,
26,9,213,4,0,
26,9,214,4,0,
26,9,216,4,0,
26,9,218,4,0,
26,9,231,4,0,
26,9,237,4,0,
26,9,240,4,0,
26,9,249,4,0,
26,9,263,4,0,
26,9,264,4,0,
26,9,270,3,0,
26,9,280,4,0,
26,9,282,3,0,
26,9,290,4,0,
26,9,324,3,0,
26,9,351,4,0,
26,9,363,4,0,
26,9,374,4,0,
26,9,393,3,0,
26,9,411,4,0,
26,9,416,4,0,
26,9,445,4,0,
26,9,447,4,0,
26,9,451,4,0,
26,10,9,3,0,
26,10,29,3,0,
26,10,39,1,1,2
26,10,63,4,0,
26,10,70,4,0,
26,10,84,1,1,1
26,10,85,1,1,4
26,10,85,4,0,
26,10,86,4,0,
26,10,87,4,0,
26,10,91,4,0,
26,10,92,4,0,
26,10,98,1,1,3
26,10,104,4,0,
26,10,113,4,0,
26,10,129,3,0,
26,10,148,4,0,
26,10,156,4,0,
26,10,164,4,0,
26,10,168,4,0,
26,10,173,3,0,
26,10,182,4,0,
26,10,189,3,0,
26,10,203,4,0,
26,10,205,3,0,
26,10,207,4,0,
26,10,213,4,0,
26,10,214,4,0,
26,10,216,4,0,
26,10,218,4,0,
26,10,231,4,0,
26,10,237,4,0,
26,10,240,4,0,
26,10,249,4,0,
26,10,263,4,0,
26,10,264,4,0,
26,10,270,3,0,
26,10,280,4,0,
26,10,282,3,0,
26,10,290,4,0,
26,10,324,3,0,
26,10,351,4,0,
26,10,363,4,0,
26,10,374,4,0,
26,10,393,3,0,
26,10,411,4,0,
26,10,416,4,0,
26,10,445,4,0,
26,10,447,4,0,
26,10,451,4,0,
26,11,39,1,1,2
26,11,63,4,0,
26,11,70,4,0,
26,11,84,1,1,1
26,11,85,1,1,4
26,11,85,4,0,
26,11,86,4,0,
26,11,87,4,0,
26,11,91,4,0,
26,11,92,4,0,
26,11,98,1,1,3
26,11,104,4,0,
26,11,113,4,0,
26,11,148,4,0,
26,11,156,4,0,
26,11,164,4,0,
26,11,168,4,0,
26,11,182,4,0,
26,11,207,4,0,
26,11,213,4,0,
26,11,216,4,0,
26,11,218,4,0,
26,11,237,4,0,
26,11,240,4,0,
26,11,249,4,0,
26,11,263,4,0,
26,11,280,4,0,
26,11,374,4,0,
26,11,411,4,0,
26,11,416,4,0,
26,11,447,4,0,
26,11,451,4,0,
26,11,496,4,0,
26,11,497,4,0,
26,11,521,4,0,
26,11,528,4,0,
26,12,39,1,1,2
26,12,63,4,0,
26,12,70,4,0,
26,12,84,1,1,1
26,12,85,1,1,4
26,12,85,4,0,
26,12,87,4,0,
26,12,91,4,0,
26,12,92,4,0,
26,12,98,1,1,3
26,12,104,4,0,
26,12,113,4,0,
26,12,148,4,0,
26,12,156,4,0,
26,12,168,4,0,
26,12,182,4,0,
26,12,213,4,0,
26,12,216,4,0,
26,12,218,4,0,
26,12,231,4,0,
26,12,237,4,0,
26,12,240,4,0,
26,12,249,4,0,
26,12,263,4,0,
26,12,264,4,0,
26,12,280,4,0,
26,12,290,4,0,
26,12,351,4,0,
26,13,34,3,0,
26,13,38,3,0,
26,13,39,1,1,2
26,13,63,4,0,
26,13,69,3,0,
26,13,70,4,0,
26,13,84,1,1,1
26,13,85,1,1,4
26,13,85,4,0,
26,13,86,3,0,
26,13,87,4,0,
26,13,91,4,0,
26,13,92,4,0,
26,13,98,1,1,3
26,13,102,3,0,
26,13,104,4,0,
26,13,113,4,0,
26,13,148,4,0,
26,13,156,4,0,
26,13,164,3,0,
26,13,168,4,0,
26,13,182,4,0,
26,13,207,3,0,
26,13,213,4,0,
26,13,216,4,0,
26,13,218,4,0,
26,13,231,4,0,
26,13,237,4,0,
26,13,240,4,0,
26,13,249,4,0,
26,13,263,4,0,
26,13,264,4,0,
26,13,280,4,0,
26,13,290,4,0,
26,13,351,4,0,
26,14,9,3,0,
26,14,39,1,1,2
26,14,63,4,0,
26,14,70,4,0,
26,14,84,1,1,1
26,14,85,1,1,4
26,14,85,4,0,
26,14,86,4,0,
26,14,87,4,0,
26,14,91,4,0,
26,14,92,4,0,
26,14,98,1,1,3
26,14,104,4,0,
26,14,113,4,0,
26,14,148,4,0,
26,14,156,4,0,
26,14,164,4,0,
26,14,168,4,0,
26,14,173,3,0,
26,14,182,4,0,
26,14,207,4,0,
26,14,213,4,0,
26,14,214,3,0,
26,14,216,4,0,
26,14,218,4,0,
26,14,231,3,0,
26,14,237,4,0,
26,14,240,4,0,
26,14,249,4,0,
26,14,263,4,0,
26,14,270,3,0,
26,14,280,4,0,
26,14,282,3,0,
26,14,324,3,0,
26,14,343,3,0,
26,14,374,4,0,
26,14,393,3,0,
26,14,411,4,0,
26,14,416,4,0,
26,14,447,4,0,
26,14,451,4,0,
26,14,496,4,0,
26,14,497,4,0,
26,14,521,4,0,
26,14,528,4,0,
26,15,39,1,1,2
26,15,63,4,0,
26,15,70,4,0,
26,15,84,1,1,1
26,15,85,1,1,4
26,15,85,4,0,
26,15,86,4,0,
26,15,87,4,0,
26,15,91,4,0,
26,15,92,4,0,
26,15,98,1,1,3
26,15,104,4,0,
26,15,113,4,0,
26,15,148,4,0,
26,15,156,4,0,
26,15,164,4,0,
26,15,168,4,0,
26,15,182,4,0,
26,15,207,4,0,
26,15,213,4,0,
26,15,214,4,0,
26,15,216,4,0,
26,15,218,4,0,
26,15,237,4,0,
26,15,240,4,0,
26,15,249,4,0,
26,15,263,4,0,
26,15,280,4,0,
26,15,374,4,0,
26,15,411,4,0,
26,15,416,4,0,
26,15,447,4,0,
26,15,451,4,0,
26,15,496,4,0,
26,15,497,4,0,
26,15,521,4,0,
26,15,528,4,0,
26,15,590,4,0,
26,16,9,3,0,
26,16,39,1,1,2
26,16,63,4,0,
26,16,70,4,0,
26,16,84,1,1,1
26,16,85,1,1,4
26,16,85,4,0,
26,16,86,4,0,
26,16,87,4,0,
26,16,91,4,0,
26,16,92,4,0,
26,16,98,1,1,3
26,16,104,4,0,
26,16,113,4,0,
26,16,148,4,0,
26,16,156,4,0,
26,16,164,4,0,
26,16,168,4,0,
26,16,173,3,0,
26,16,182,4,0,
26,16,207,4,0,
26,16,213,4,0,
26,16,214,4,0,
26,16,216,4,0,
26,16,218,4,0,
26,16,231,3,0,
26,16,237,4,0,
26,16,240,4,0,
26,16,249,4,0,
26,16,263,4,0,
26,16,264,3,0,
26,16,270,3,0,
26,16,280,4,0,
26,16,282,3,0,
26,16,290,4,0,
26,16,324,3,0,
26,16,343,3,0,
26,16,351,3,0,
26,16,374,4,0,
26,16,393,3,0,
26,16,411,4,0,
26,16,416,4,0,
26,16,447,4,0,
26,16,451,4,0,
26,16,496,4,0,
26,16,497,4,0,
26,16,521,4,0,
26,16,527,3,0,
26,16,528,4,0,
26,16,590,4,0,
26,17,39,1,1,2
26,17,63,4,0,
26,17,84,1,1,1
26,17,85,1,1,4
26,17,85,4,0,
26,17,86,4,0,
26,17,87,4,0,
26,17,92,4,0,
26,17,98,1,1,3
26,17,104,4,0,
26,17,113,4,0,
26,17,156,4,0,
26,17,164,4,0,
26,17,168,4,0,
26,17,182,4,0,
26,17,207,4,0,
26,17,213,4,0,
26,17,214,4,0,
26,17,216,4,0,
26,17,218,4,0,
26,17,237,4,0,
26,17,240,4,0,
26,17,263,4,0,
26,17,280,4,0,
26,17,374,4,0,
26,17,411,4,0,
26,17,416,4,0,
26,17,447,4,0,
26,17,451,4,0,
26,17,496,4,0,
26,17,497,4,0,
26,17,521,4,0,
26,17,528,4,0,
26,17,590,4,0,
26,18,39,1,1,2
26,18,63,4,0,
26,18,84,1,1,1
26,18,85,1,1,4
26,18,85,4,0,
26,18,86,4,0,
26,18,87,4,0,
26,18,92,4,0,
26,18,98,1,1,3
26,18,104,4,0,
26,18,113,4,0,
26,18,156,4,0,
26,18,164,4,0,
26,18,168,4,0,
26,18,182,4,0,
26,18,207,4,0,
26,18,213,4,0,
26,18,214,4,0,
26,18,216,4,0,
26,18,218,4,0,
26,18,237,4,0,
26,18,240,4,0,
26,18,263,4,0,
26,18,280,4,0,
26,18,374,4,0,
26,18,411,4,0,
26,18,416,4,0,
26,18,447,4,0,
26,18,451,4,0,
26,18,496,4,0,
26,18,497,4,0,
26,18,521,4,0,
26,18,528,4,0,
26,18,590,4,0,
27,1,10,1,1,
27,1,14,4,0,
27,1,15,4,0,
27,1,28,1,10,
27,1,34,4,0,
27,1,36,4,0,
27,1,38,4,0,
27,1,40,1,24,
27,1,66,4,0,
27,1,69,4,0,
27,1,70,4,0,
27,1,89,4,0,
27,1,90,4,0,
27,1,91,4,0,
27,1,92,4,0,
27,1,99,4,0,
27,1,102,4,0,
27,1,104,4,0,
27,1,117,4,0,
27,1,129,1,31,
27,1,129,4,0,
27,1,130,4,0,
27,1,154,1,38,
27,1,156,4,0,
27,1,157,4,0,
27,1,163,1,17,
27,1,164,4,0,
27,2,10,1,1,
27,2,14,4,0,
27,2,15,4,0,
27,2,28,1,10,
27,2,34,4,0,
27,2,36,4,0,
27,2,38,4,0,
27,2,40,1,24,
27,2,66,4,0,
27,2,69,4,0,
27,2,70,4,0,
27,2,89,4,0,
27,2,90,4,0,
27,2,91,4,0,
27,2,92,4,0,
27,2,99,4,0,
27,2,102,4,0,
27,2,104,4,0,
27,2,117,4,0,
27,2,129,1,31,
27,2,129,4,0,
27,2,130,4,0,
27,2,154,1,38,
27,2,156,4,0,
27,2,157,4,0,
27,2,163,1,17,
27,2,164,4,0,
27,3,10,1,1,
27,3,15,4,0,
27,3,28,1,11,
27,3,29,4,0,
27,3,40,1,17,
27,3,68,2,0,
27,3,70,4,0,
27,3,89,4,0,
27,3,91,4,0,
27,3,92,4,0,
27,3,104,4,0,
27,3,111,1,6,
27,3,111,4,0,
27,3,129,1,30,
27,3,129,4,0,
27,3,154,1,37,
27,3,156,4,0,
27,3,163,1,23,
27,3,168,4,0,
27,3,173,4,0,
27,3,174,4,0,
27,3,175,2,0,
27,3,182,4,0,
27,3,189,4,0,
27,3,197,4,0,
27,3,201,1,45,
27,3,201,4,0,
27,3,203,4,0,
27,3,205,4,0,
27,3,207,4,0,
27,3,210,4,0,
27,3,213,4,0,
27,3,214,4,0,
27,3,216,4,0,
27,3,218,4,0,
27,3,219,2,0,
27,3,223,4,0,
27,3,229,2,0,
27,3,231,4,0,
27,3,237,4,0,
27,3,241,4,0,
27,3,249,4,0,
27,4,10,1,1,
27,4,15,4,0,
27,4,28,1,11,
27,4,29,4,0,
27,4,40,1,17,
27,4,68,2,0,
27,4,70,4,0,
27,4,89,4,0,
27,4,91,4,0,
27,4,92,4,0,
27,4,104,4,0,
27,4,111,1,6,
27,4,111,4,0,
27,4,129,1,30,
27,4,129,4,0,
27,4,154,1,37,
27,4,156,4,0,
27,4,163,1,23,
27,4,168,4,0,
27,4,173,4,0,
27,4,174,4,0,
27,4,175,2,0,
27,4,182,4,0,
27,4,189,4,0,
27,4,197,4,0,
27,4,201,1,45,
27,4,201,4,0,
27,4,203,4,0,
27,4,205,4,0,
27,4,207,4,0,
27,4,210,4,0,
27,4,213,4,0,
27,4,214,4,0,
27,4,216,4,0,
27,4,218,4,0,
27,4,219,2,0,
27,4,223,4,0,
27,4,229,2,0,
27,4,231,4,0,
27,4,232,2,0,
27,4,237,4,0,
27,4,241,4,0,
27,4,249,4,0,
27,5,10,1,1,
27,5,14,2,0,
27,5,15,4,0,
27,5,28,1,11,
27,5,40,1,17,
27,5,68,2,0,
27,5,70,4,0,
27,5,89,4,0,
27,5,91,4,0,
27,5,92,4,0,
27,5,104,4,0,
27,5,111,1,6,
27,5,129,1,30,
27,5,154,1,37,
27,5,156,4,0,
27,5,157,2,0,
27,5,163,1,23,
27,5,168,4,0,
27,5,175,2,0,
27,5,182,4,0,
27,5,201,1,53,
27,5,201,4,0,
27,5,213,4,0,
27,5,216,4,0,
27,5,218,4,0,
27,5,219,2,0,
27,5,229,2,0,
27,5,231,4,0,
27,5,232,2,0,
27,5,237,4,0,
27,5,241,4,0,
27,5,249,4,0,
27,5,263,4,0,
27,5,264,4,0,
27,5,280,4,0,
27,5,290,4,0,
27,5,306,2,0,
27,5,317,4,0,
27,5,328,1,45,
27,5,332,4,0,
27,6,10,1,1,
27,6,14,2,0,
27,6,14,3,0,
27,6,15,4,0,
27,6,28,1,11,
27,6,34,3,0,
27,6,38,3,0,
27,6,40,1,17,
27,6,68,2,0,
27,6,68,3,0,
27,6,69,3,0,
27,6,70,4,0,
27,6,89,4,0,
27,6,91,4,0,
27,6,92,4,0,
27,6,102,3,0,
27,6,104,4,0,
27,6,111,1,6,
27,6,111,3,0,
27,6,129,1,30,
27,6,129,3,0,
27,6,154,1,37,
27,6,156,4,0,
27,6,157,2,0,
27,6,157,3,0,
27,6,163,1,23,
27,6,164,3,0,
27,6,168,4,0,
27,6,173,3,0,
27,6,175,2,0,
27,6,182,4,0,
27,6,189,3,0,
27,6,201,1,53,
27,6,201,4,0,
27,6,203,3,0,
27,6,205,3,0,
27,6,207,3,0,
27,6,210,3,0,
27,6,213,4,0,
27,6,214,3,0,
27,6,216,4,0,
27,6,218,4,0,
27,6,219,2,0,
27,6,223,3,0,
27,6,229,2,0,
27,6,231,4,0,
27,6,232,2,0,
27,6,237,4,0,
27,6,241,4,0,
27,6,249,4,0,
27,6,263,4,0,
27,6,264,4,0,
27,6,280,4,0,
27,6,290,4,0,
27,6,306,2,0,
27,6,317,4,0,
27,6,328,1,45,
27,6,332,4,0,
27,7,10,1,1,
27,7,14,2,0,
27,7,14,3,0,
27,7,15,4,0,
27,7,28,1,11,
27,7,34,3,0,
27,7,38,3,0,
27,7,40,1,17,
27,7,68,2,0,
27,7,68,3,0,
27,7,69,3,0,
27,7,70,4,0,
27,7,89,4,0,
27,7,91,4,0,
27,7,92,4,0,
27,7,102,3,0,
27,7,104,4,0,
27,7,111,1,6,
27,7,129,1,30,
27,7,154,1,37,
27,7,156,4,0,
27,7,157,2,0,
27,7,157,3,0,
27,7,163,1,23,
27,7,164,3,0,
27,7,168,4,0,
27,7,175,2,0,
27,7,182,4,0,
27,7,201,1,53,
27,7,201,4,0,
27,7,213,4,0,
27,7,216,4,0,
27,7,218,4,0,
27,7,219,2,0,
27,7,229,2,0,
27,7,231,4,0,
27,7,232,2,0,
27,7,237,4,0,
27,7,241,4,0,
27,7,249,4,0,
27,7,263,4,0,
27,7,264,4,0,
27,7,280,4,0,
27,7,290,4,0,
27,7,306,2,0,
27,7,317,4,0,
27,7,328,1,45,
27,7,332,4,0,
27,8,10,1,1,
27,8,14,2,0,
27,8,14,4,0,
27,8,15,4,0,
27,8,28,1,7,
27,8,40,1,9,
27,8,68,2,0,
27,8,70,4,0,
27,8,89,4,0,
27,8,91,4,0,
27,8,92,4,0,
27,8,104,4,0,
27,8,111,1,3,
27,8,129,1,15,
27,8,154,1,19,
27,8,156,4,0,
27,8,157,2,0,
27,8,157,4,0,
27,8,163,1,31,
27,8,164,4,0,
27,8,168,4,0,
27,8,175,2,0,
27,8,182,4,0,
27,8,201,1,37,
27,8,201,4,0,
27,8,203,4,0,
27,8,205,1,21,
27,8,207,4,0,
27,8,210,1,25,
27,8,213,4,0,
27,8,214,4,0,
27,8,216,4,0,
27,8,218,4,0,
27,8,219,2,0,
27,8,229,1,13,
27,8,229,2,0,
27,8,231,4,0,
27,8,232,2,0,
27,8,237,4,0,
27,8,241,4,0,
27,8,249,4,0,
27,8,263,4,0,
27,8,264,4,0,
27,8,280,4,0,
27,8,290,4,0,
27,8,306,2,0,
27,8,317,4,0,
27,8,328,1,27,
27,8,332,4,0,
27,8,360,1,33,
27,8,360,4,0,
27,8,363,4,0,
27,8,374,4,0,
27,8,398,4,0,
27,8,400,2,0,
27,8,404,4,0,
27,8,421,4,0,
27,8,431,4,0,
27,8,445,4,0,
27,8,446,4,0,
27,9,10,1,1,
27,9,14,2,0,
27,9,14,4,0,
27,9,15,4,0,
27,9,28,1,7,
27,9,40,1,9,
27,9,68,2,0,
27,9,70,4,0,
27,9,89,4,0,
27,9,91,4,0,
27,9,92,4,0,
27,9,104,4,0,
27,9,111,1,3,
27,9,129,1,15,
27,9,129,3,0,
27,9,154,1,19,
27,9,156,4,0,
27,9,157,2,0,
27,9,157,4,0,
27,9,163,1,31,
27,9,164,4,0,
27,9,168,4,0,
27,9,173,3,0,
27,9,175,2,0,
27,9,182,4,0,
27,9,189,3,0,
27,9,201,1,37,
27,9,201,4,0,
27,9,203,4,0,
27,9,205,1,21,
27,9,205,3,0,
27,9,207,4,0,
27,9,210,1,25,
27,9,210,3,0,
27,9,213,4,0,
27,9,214,4,0,
27,9,216,4,0,
27,9,218,4,0,
27,9,219,2,0,
27,9,229,1,13,
27,9,229,2,0,
27,9,231,4,0,
27,9,232,2,0,
27,9,237,4,0,
27,9,241,4,0,
27,9,249,4,0,
27,9,263,4,0,
27,9,264,4,0,
27,9,280,4,0,
27,9,282,3,0,
27,9,290,4,0,
27,9,306,2,0,
27,9,317,4,0,
27,9,328,1,27,
27,9,332,4,0,
27,9,360,1,33,
27,9,360,4,0,
27,9,363,4,0,
27,9,374,4,0,
27,9,398,4,0,
27,9,400,2,0,
27,9,404,4,0,
27,9,414,3,0,
27,9,421,4,0,
27,9,431,4,0,
27,9,445,4,0,
27,9,446,4,0,
27,10,10,1,1,
27,10,14,2,0,
27,10,14,4,0,
27,10,15,4,0,
27,10,28,1,7,
27,10,29,3,0,
27,10,40,1,9,
27,10,68,2,0,
27,10,70,4,0,
27,10,89,4,0,
27,10,91,4,0,
27,10,92,4,0,
27,10,104,4,0,
27,10,111,1,3,
27,10,129,1,15,
27,10,129,3,0,
27,10,154,1,19,
27,10,156,4,0,
27,10,157,2,0,
27,10,157,4,0,
27,10,162,3,0,
27,10,163,1,31,
27,10,164,4,0,
27,10,168,4,0,
27,10,173,3,0,
27,10,175,2,0,
27,10,182,4,0,
27,10,189,3,0,
27,10,201,1,37,
27,10,201,4,0,
27,10,203,4,0,
27,10,205,1,21,
27,10,205,3,0,
27,10,207,4,0,
27,10,210,1,25,
27,10,210,3,0,
27,10,213,4,0,
27,10,214,4,0,
27,10,216,4,0,
27,10,218,4,0,
27,10,219,2,0,
27,10,229,1,13,
27,10,229,2,0,
27,10,231,4,0,
27,10,232,2,0,
27,10,237,4,0,
27,10,241,4,0,
27,10,249,4,0,
27,10,263,4,0,
27,10,264,4,0,
27,10,280,4,0,
27,10,282,3,0,
27,10,290,4,0,
27,10,306,2,0,
27,10,317,4,0,
27,10,328,1,27,
27,10,332,4,0,
27,10,341,2,0,
27,10,360,1,33,
27,10,360,4,0,
27,10,363,4,0,
27,10,374,4,0,
27,10,398,4,0,
27,10,400,2,0,
27,10,404,4,0,
27,10,414,3,0,
27,10,421,4,0,
27,10,431,4,0,
27,10,445,4,0,
27,10,446,4,0,
27,11,10,1,1,
27,11,14,4,0,
27,11,15,4,0,
27,11,28,1,7,
27,11,40,1,9,
27,11,68,2,0,
27,11,70,4,0,
27,11,89,4,0,
27,11,91,4,0,
27,11,92,4,0,
27,11,104,4,0,
27,11,111,1,3,
27,11,129,1,15,
27,11,154,1,19,
27,11,156,4,0,
27,11,157,4,0,
27,11,163,1,31,
27,11,164,4,0,
27,11,168,4,0,
27,11,175,2,0,
27,11,182,4,0,
27,11,201,1,37,
27,11,201,4,0,
27,11,203,2,0,
27,11,205,1,21,
27,11,207,4,0,
27,11,210,1,25,
27,11,213,4,0,
27,11,216,4,0,
27,11,218,4,0,
27,11,219,4,0,
27,11,229,1,13,
27,11,229,2,0,
27,11,232,2,0,
27,11,237,4,0,
27,11,241,4,0,
27,11,249,4,0,
27,11,263,4,0,
27,11,280,4,0,
27,11,306,2,0,
27,11,317,4,0,
27,11,328,1,27,
27,11,332,4,0,
27,11,341,2,0,
27,11,360,1,33,
27,11,360,4,0,
27,11,374,4,0,
27,11,398,4,0,
27,11,400,2,0,
27,11,404,4,0,
27,11,421,4,0,
27,11,431,2,0,
27,11,468,4,0,
27,11,496,4,0,
27,11,498,2,0,
27,11,523,4,0,
27,12,10,1,1,
27,12,15,4,0,
27,12,28,1,11,
27,12,40,1,17,
27,12,70,4,0,
27,12,89,4,0,
27,12,91,4,0,
27,12,92,4,0,
27,12,104,4,0,
27,12,111,1,6,
27,12,129,1,30,
27,12,154,1,37,
27,12,156,4,0,
27,12,163,1,23,
27,12,168,4,0,
27,12,182,4,0,
27,12,201,1,53,
27,12,201,4,0,
27,12,213,4,0,
27,12,216,4,0,
27,12,218,4,0,
27,12,231,4,0,
27,12,237,4,0,
27,12,241,4,0,
27,12,249,4,0,
27,12,263,4,0,
27,12,264,4,0,
27,12,280,4,0,
27,12,290,4,0,
27,12,317,4,0,
27,12,328,1,45,
27,12,332,4,0,
27,13,10,1,1,
27,13,15,4,0,
27,13,28,1,11,
27,13,34,3,0,
27,13,38,3,0,
27,13,40,1,17,
27,13,69,3,0,
27,13,70,4,0,
27,13,89,4,0,
27,13,91,4,0,
27,13,92,4,0,
27,13,102,3,0,
27,13,104,4,0,
27,13,111,1,6,
27,13,129,1,30,
27,13,154,1,37,
27,13,156,4,0,
27,13,163,1,23,
27,13,164,3,0,
27,13,168,4,0,
27,13,182,4,0,
27,13,201,1,53,
27,13,201,4,0,
27,13,207,3,0,
27,13,213,4,0,
27,13,216,4,0,
27,13,218,4,0,
27,13,231,4,0,
27,13,237,4,0,
27,13,241,4,0,
27,13,249,4,0,
27,13,263,4,0,
27,13,264,4,0,
27,13,280,4,0,
27,13,290,4,0,
27,13,317,4,0,
27,13,328,1,45,
27,13,332,4,0,
27,14,10,1,1,1
27,14,14,1,38,
27,14,14,4,0,
27,14,15,4,0,
27,14,28,1,3,
27,14,40,1,5,
27,14,68,2,0,
27,14,70,4,0,
27,14,89,1,46,
27,14,89,4,0,
27,14,91,1,30,
27,14,91,4,0,
27,14,92,4,0,
27,14,104,4,0,
27,14,111,1,1,2
27,14,129,1,11,
27,14,154,1,20,
27,14,156,4,0,
27,14,157,4,0,
27,14,162,3,0,
27,14,163,1,26,
27,14,164,4,0,
27,14,168,4,0,
27,14,173,3,0,
27,14,175,2,0,
27,14,182,4,0,
27,14,201,1,42,
27,14,201,4,0,
27,14,203,2,0,
27,14,205,1,7,
27,14,207,4,0,
27,14,210,1,14,
27,14,213,4,0,
27,14,214,3,0,
27,14,216,4,0,
27,14,218,4,0,
27,14,219,4,0,
27,14,222,1,17,
27,14,229,1,9,
27,14,229,2,0,
27,14,231,3,0,
27,14,232,2,0,
27,14,237,4,0,
27,14,241,4,0,
27,14,249,4,0,
27,14,263,4,0,
27,14,280,4,0,
27,14,282,3,0,
27,14,306,2,0,
27,14,317,4,0,
27,14,328,1,23,
27,14,332,4,0,
27,14,341,2,0,
27,14,343,3,0,
27,14,360,1,34,
27,14,360,4,0,
27,14,374,4,0,
27,14,398,4,0,
27,14,400,2,0,
27,14,404,4,0,
27,14,414,3,0,
27,14,421,4,0,
27,14,431,2,0,
27,14,446,3,0,
27,14,468,4,0,
27,14,496,4,0,
27,14,498,2,0,
27,14,523,4,0,
27,15,10,1,1,1
27,15,14,1,38,
27,15,14,4,0,
27,15,15,4,0,
27,15,28,1,3,
27,15,40,1,5,
27,15,68,2,0,
27,15,70,4,0,
27,15,89,1,46,
27,15,89,4,0,
27,15,91,1,30,
27,15,91,4,0,
27,15,92,4,0,
27,15,104,4,0,
27,15,111,1,1,2
27,15,129,1,11,
27,15,154,1,20,
27,15,156,4,0,
27,15,157,4,0,
27,15,163,1,26,
27,15,164,4,0,
27,15,168,4,0,
27,15,175,2,0,
27,15,182,4,0,
27,15,201,1,42,
27,15,201,4,0,
27,15,203,2,0,
27,15,205,1,7,
27,15,207,4,0,
27,15,210,1,14,
27,15,213,4,0,
27,15,214,4,0,
27,15,216,4,0,
27,15,218,4,0,
27,15,219,4,0,
27,15,222,1,17,
27,15,229,1,9,
27,15,229,2,0,
27,15,232,2,0,
27,15,237,4,0,
27,15,241,4,0,
27,15,249,4,0,
27,15,263,4,0,
27,15,280,4,0,
27,15,306,2,0,
27,15,317,4,0,
27,15,328,1,23,
27,15,332,4,0,
27,15,341,2,0,
27,15,360,1,34,
27,15,360,4,0,
27,15,374,4,0,
27,15,398,4,0,
27,15,400,2,0,
27,15,404,4,0,
27,15,421,4,0,
27,15,431,2,0,
27,15,468,4,0,
27,15,496,4,0,
27,15,498,2,0,
27,15,523,4,0,
27,15,563,2,0,
27,15,590,4,0,
27,16,10,1,1,1
27,16,14,1,38,1
27,16,14,4,0,
27,16,15,4,0,
27,16,28,1,3,1
27,16,40,1,5,1
27,16,68,2,0,
27,16,70,4,0,
27,16,89,1,46,1
27,16,89,4,0,
27,16,91,1,30,1
27,16,91,4,0,
27,16,92,4,0,
27,16,104,4,0,
27,16,111,1,1,2
27,16,129,1,17,1
27,16,154,1,20,1
27,16,156,4,0,
27,16,157,4,0,
27,16,162,3,0,
27,16,163,1,26,1
27,16,164,4,0,
27,16,168,4,0,
27,16,173,3,0,
27,16,175,2,0,
27,16,182,4,0,
27,16,201,1,42,1
27,16,201,4,0,
27,16,203,2,0,
27,16,205,1,7,1
27,16,207,4,0,
27,16,210,1,11,1
27,16,213,4,0,
27,16,214,4,0,
27,16,216,4,0,
27,16,218,4,0,
27,16,219,4,0,
27,16,222,1,14,1
27,16,229,1,9,1
27,16,229,2,0,
27,16,231,3,0,
27,16,232,2,0,
27,16,237,4,0,
27,16,241,4,0,
27,16,249,4,0,
27,16,263,4,0,
27,16,264,3,0,
27,16,280,4,0,
27,16,282,3,0,
27,16,290,4,0,
27,16,306,2,0,
27,16,317,4,0,
27,16,328,1,23,1
27,16,332,4,0,
27,16,341,2,0,
27,16,343,3,0,
27,16,360,1,34,1
27,16,360,4,0,
27,16,374,4,0,
27,16,398,4,0,
27,16,400,2,0,
27,16,404,4,0,
27,16,414,3,0,
27,16,421,4,0,
27,16,431,2,0,
27,16,446,3,0,
27,16,468,4,0,
27,16,496,4,0,
27,16,498,2,0,
27,16,523,4,0,
27,16,563,2,0,
27,16,590,4,0,
27,17,10,1,1,1
27,17,14,1,38,
27,17,14,4,0,
27,17,28,1,3,
27,17,40,1,5,
27,17,68,2,0,
27,17,89,1,46,
27,17,89,4,0,
27,17,91,1,30,
27,17,92,4,0,
27,17,104,4,0,
27,17,111,1,1,2
27,17,129,1,17,
27,17,154,1,20,
27,17,156,4,0,
27,17,157,4,0,
27,17,163,1,26,
27,17,164,4,0,
27,17,168,4,0,
27,17,175,2,0,
27,17,182,4,0,
27,17,201,1,42,
27,17,201,4,0,
27,17,203,2,0,
27,17,205,1,7,
27,17,207,4,0,
27,17,210,1,11,
27,17,213,4,0,
27,17,214,4,0,
27,17,216,4,0,
27,17,218,4,0,
27,17,219,4,0,
27,17,222,1,14,
27,17,229,1,9,
27,17,229,2,0,
27,17,232,2,0,
27,17,237,4,0,
27,17,241,4,0,
27,17,263,4,0,
27,17,280,4,0,
27,17,306,2,0,
27,17,317,4,0,
27,17,328,1,23,
27,17,332,4,0,
27,17,341,2,0,
27,17,360,1,34,
27,17,360,4,0,
27,17,374,4,0,
27,17,398,4,0,
27,17,400,2,0,
27,17,404,4,0,
27,17,421,4,0,
27,17,431,2,0,
27,17,496,4,0,
27,17,498,2,0,
27,17,523,4,0,
27,17,563,2,0,
27,17,590,4,0,
27,18,10,1,1,1
27,18,14,1,38,
27,18,14,4,0,
27,18,28,1,3,
27,18,40,1,5,
27,18,68,2,0,
27,18,89,1,46,
27,18,89,4,0,
27,18,91,1,30,
27,18,92,4,0,
27,18,104,4,0,
27,18,111,1,1,2
27,18,129,1,17,
27,18,154,1,20,
27,18,156,4,0,
27,18,157,4,0,
27,18,163,1,26,
27,18,164,4,0,
27,18,168,4,0,
27,18,175,2,0,
27,18,182,4,0,
27,18,201,1,42,
27,18,201,4,0,
27,18,203,2,0,
27,18,205,1,7,
27,18,207,4,0,
27,18,210,1,11,
27,18,213,4,0,
27,18,214,4,0,
27,18,216,4,0,
27,18,218,4,0,
27,18,219,4,0,
27,18,222,1,14,
27,18,229,1,9,
27,18,229,2,0,
27,18,232,2,0,
27,18,237,4,0,
27,18,241,4,0,
27,18,263,4,0,
27,18,280,4,0,
27,18,306,2,0,
27,18,317,4,0,
27,18,328,1,23,
27,18,332,4,0,
27,18,341,2,0,
27,18,360,1,34,
27,18,360,4,0,
27,18,374,4,0,
27,18,398,4,0,
27,18,400,2,0,
27,18,404,4,0,
27,18,421,4,0,
27,18,431,2,0,
27,18,468,2,0,
27,18,496,4,0,
27,18,498,2,0,
27,18,523,4,0,
27,18,563,2,0,
27,18,590,4,0,
28,1,10,1,1,1
28,1,14,4,0,
28,1,15,4,0,
28,1,28,1,1,2
28,1,28,1,10,
28,1,34,4,0,
28,1,36,4,0,
28,1,38,4,0,
28,1,40,1,27,
28,1,63,4,0,
28,1,66,4,0,
28,1,69,4,0,
28,1,70,4,0,
28,1,89,4,0,
28,1,90,4,0,
28,1,91,4,0,
28,1,92,4,0,
28,1,99,4,0,
28,1,102,4,0,
28,1,104,4,0,
28,1,117,4,0,
28,1,129,1,36,
28,1,129,4,0,
28,1,130,4,0,
28,1,154,1,47,
28,1,156,4,0,
28,1,157,4,0,
28,1,163,1,17,
28,1,164,4,0,
28,2,10,1,1,1
28,2,14,4,0,
28,2,15,4,0,
28,2,28,1,1,2
28,2,28,1,10,
28,2,34,4,0,
28,2,36,4,0,
28,2,38,4,0,
28,2,40,1,27,
28,2,63,4,0,
28,2,66,4,0,
28,2,69,4,0,
28,2,70,4,0,
28,2,89,4,0,
28,2,90,4,0,
28,2,91,4,0,
28,2,92,4,0,
28,2,99,4,0,
28,2,102,4,0,
28,2,104,4,0,
28,2,117,4,0,
28,2,129,1,36,
28,2,129,4,0,
28,2,130,4,0,
28,2,154,1,47,
28,2,156,4,0,
28,2,157,4,0,
28,2,163,1,17,
28,2,164,4,0,
28,3,10,1,1,1
28,3,15,4,0,
28,3,28,1,1,3
28,3,28,1,11,
28,3,29,4,0,
28,3,40,1,17,
28,3,63,4,0,
28,3,70,4,0,
28,3,89,4,0,
28,3,91,4,0,
28,3,92,4,0,
28,3,104,4,0,
28,3,111,1,1,2
28,3,111,1,6,
28,3,111,4,0,
28,3,129,1,33,
28,3,129,4,0,
28,3,154,1,42,
28,3,156,4,0,
28,3,163,1,24,
28,3,168,4,0,
28,3,173,4,0,
28,3,174,4,0,
28,3,182,4,0,
28,3,189,4,0,
28,3,197,4,0,
28,3,201,1,52,
28,3,201,4,0,
28,3,203,4,0,
28,3,205,4,0,
28,3,207,4,0,
28,3,210,4,0,
28,3,213,4,0,
28,3,214,4,0,
28,3,216,4,0,
28,3,218,4,0,
28,3,223,4,0,
28,3,231,4,0,
28,3,237,4,0,
28,3,241,4,0,
28,3,249,4,0,
28,4,10,1,1,1
28,4,15,4,0,
28,4,28,1,1,3
28,4,28,1,11,
28,4,29,4,0,
28,4,40,1,17,
28,4,63,4,0,
28,4,70,4,0,
28,4,89,4,0,
28,4,91,4,0,
28,4,92,4,0,
28,4,104,4,0,
28,4,111,1,1,2
28,4,111,1,6,
28,4,111,4,0,
28,4,129,1,33,
28,4,129,4,0,
28,4,154,1,42,
28,4,156,4,0,
28,4,163,1,24,
28,4,168,4,0,
28,4,173,4,0,
28,4,174,4,0,
28,4,182,4,0,
28,4,189,4,0,
28,4,197,4,0,
28,4,201,1,52,
28,4,201,4,0,
28,4,203,4,0,
28,4,205,4,0,
28,4,207,4,0,
28,4,210,4,0,
28,4,213,4,0,
28,4,214,4,0,
28,4,216,4,0,
28,4,218,4,0,
28,4,223,4,0,
28,4,231,4,0,
28,4,237,4,0,
28,4,241,4,0,
28,4,249,4,0,
28,5,10,1,1,1
28,5,15,4,0,
28,5,28,1,1,3
28,5,28,1,11,
28,5,40,1,17,
28,5,63,4,0,
28,5,70,4,0,
28,5,89,4,0,
28,5,91,4,0,
28,5,92,4,0,
28,5,104,4,0,
28,5,111,1,1,2
28,5,111,1,6,
28,5,129,1,33,
28,5,154,1,42,
28,5,156,4,0,
28,5,163,1,24,
28,5,168,4,0,
28,5,182,4,0,
28,5,201,1,62,
28,5,201,4,0,
28,5,213,4,0,
28,5,216,4,0,
28,5,218,4,0,
28,5,231,4,0,
28,5,237,4,0,
28,5,241,4,0,
28,5,249,4,0,
28,5,263,4,0,
28,5,264,4,0,
28,5,280,4,0,
28,5,290,4,0,
28,5,317,4,0,
28,5,328,1,52,
28,5,332,4,0,
28,6,10,1,1,1
28,6,14,3,0,
28,6,15,4,0,
28,6,28,1,1,3
28,6,28,1,11,
28,6,34,3,0,
28,6,38,3,0,
28,6,40,1,17,
28,6,63,4,0,
28,6,68,3,0,
28,6,69,3,0,
28,6,70,4,0,
28,6,89,4,0,
28,6,91,4,0,
28,6,92,4,0,
28,6,102,3,0,
28,6,104,4,0,
28,6,111,1,1,2
28,6,111,1,6,
28,6,111,3,0,
28,6,129,1,33,
28,6,129,3,0,
28,6,154,1,42,
28,6,156,4,0,
28,6,157,3,0,
28,6,163,1,24,
28,6,164,3,0,
28,6,168,4,0,
28,6,173,3,0,
28,6,182,4,0,
28,6,189,3,0,
28,6,201,1,62,
28,6,201,4,0,
28,6,203,3,0,
28,6,205,3,0,
28,6,207,3,0,
28,6,210,3,0,
28,6,213,4,0,
28,6,214,3,0,
28,6,216,4,0,
28,6,218,4,0,
28,6,223,3,0,
28,6,231,4,0,
28,6,237,4,0,
28,6,241,4,0,
28,6,249,4,0,
28,6,263,4,0,
28,6,264,4,0,
28,6,280,4,0,
28,6,290,4,0,
28,6,317,4,0,
28,6,328,1,52,
28,6,332,4,0,
28,7,10,1,1,1
28,7,14,3,0,
28,7,15,4,0,
28,7,28,1,1,3
28,7,28,1,11,
28,7,34,3,0,
28,7,38,3,0,
28,7,40,1,17,
28,7,63,4,0,
28,7,68,3,0,
28,7,69,3,0,
28,7,70,4,0,
28,7,89,4,0,
28,7,91,4,0,
28,7,92,4,0,
28,7,102,3,0,
28,7,104,4,0,
28,7,111,1,1,2
28,7,111,1,6,
28,7,129,1,33,
28,7,154,1,42,
28,7,156,4,0,
28,7,157,3,0,
28,7,163,1,24,
28,7,164,3,0,
28,7,168,4,0,
28,7,182,4,0,
28,7,201,1,62,
28,7,201,4,0,
28,7,213,4,0,
28,7,216,4,0,
28,7,218,4,0,
28,7,231,4,0,
28,7,237,4,0,
28,7,241,4,0,
28,7,249,4,0,
28,7,263,4,0,
28,7,264,4,0,
28,7,280,4,0,
28,7,290,4,0,
28,7,317,4,0,
28,7,328,1,52,
28,7,332,4,0,
28,8,10,1,1,1
28,8,14,4,0,
28,8,15,4,0,
28,8,28,1,1,3
28,8,28,1,7,
28,8,40,1,9,
28,8,63,4,0,
28,8,70,4,0,
28,8,89,4,0,
28,8,91,4,0,
28,8,92,4,0,
28,8,104,4,0,
28,8,111,1,1,2
28,8,111,1,3,
28,8,129,1,15,
28,8,154,1,19,
28,8,156,4,0,
28,8,157,4,0,
28,8,163,1,40,
28,8,164,4,0,
28,8,168,4,0,
28,8,182,4,0,
28,8,201,1,52,
28,8,201,4,0,
28,8,203,4,0,
28,8,205,1,21,
28,8,207,4,0,
28,8,210,1,28,
28,8,213,4,0,
28,8,214,4,0,
28,8,216,4,0,
28,8,218,4,0,
28,8,229,1,13,
28,8,231,4,0,
28,8,237,4,0,
28,8,241,4,0,
28,8,249,4,0,
28,8,263,4,0,
28,8,264,4,0,
28,8,280,4,0,
28,8,290,4,0,
28,8,306,1,22,
28,8,317,4,0,
28,8,328,1,33,
28,8,332,4,0,
28,8,360,1,45,
28,8,360,4,0,
28,8,363,4,0,
28,8,374,4,0,
28,8,398,4,0,
28,8,404,4,0,
28,8,411,4,0,
28,8,416,4,0,
28,8,421,4,0,
28,8,431,4,0,
28,8,444,4,0,
28,8,445,4,0,
28,8,446,4,0,
28,9,10,1,1,1
28,9,14,4,0,
28,9,15,4,0,
28,9,28,1,1,3
28,9,28,1,7,
28,9,40,1,9,
28,9,63,4,0,
28,9,70,4,0,
28,9,89,4,0,
28,9,91,4,0,
28,9,92,4,0,
28,9,104,4,0,
28,9,111,1,1,2
28,9,111,1,3,
28,9,129,1,15,
28,9,129,3,0,
28,9,154,1,19,
28,9,156,4,0,
28,9,157,4,0,
28,9,163,1,40,
28,9,164,4,0,
28,9,168,4,0,
28,9,173,3,0,
28,9,182,4,0,
28,9,189,3,0,
28,9,201,1,52,
28,9,201,4,0,
28,9,203,4,0,
28,9,205,1,21,
28,9,205,3,0,
28,9,207,4,0,
28,9,210,1,28,
28,9,210,3,0,
28,9,213,4,0,
28,9,214,4,0,
28,9,216,4,0,
28,9,218,4,0,
28,9,229,1,13,
28,9,231,4,0,
28,9,237,4,0,
28,9,241,4,0,
28,9,249,4,0,
28,9,263,4,0,
28,9,264,4,0,
28,9,280,4,0,
28,9,282,3,0,
28,9,290,4,0,
28,9,306,1,22,
28,9,317,4,0,
28,9,328,1,33,
28,9,332,4,0,
28,9,360,1,45,
28,9,360,4,0,
28,9,363,4,0,
28,9,374,4,0,
28,9,398,4,0,
28,9,404,4,0,
28,9,411,4,0,
28,9,414,3,0,
28,9,416,4,0,
28,9,421,4,0,
28,9,431,4,0,
28,9,444,4,0,
28,9,445,4,0,
28,9,446,4,0,
28,10,10,1,1,1
28,10,14,4,0,
28,10,15,4,0,
28,10,28,1,1,3
28,10,28,1,7,
28,10,29,3,0,
28,10,40,1,9,
28,10,63,4,0,
28,10,70,4,0,
28,10,89,4,0,
28,10,91,4,0,
28,10,92,4,0,
28,10,104,4,0,
28,10,111,1,1,2
28,10,111,1,3,
28,10,129,1,15,
28,10,129,3,0,
28,10,154,1,19,
28,10,156,4,0,
28,10,157,4,0,
28,10,162,3,0,
28,10,163,1,40,
28,10,164,4,0,
28,10,168,4,0,
28,10,173,3,0,
28,10,182,4,0,
28,10,189,3,0,
28,10,201,1,52,
28,10,201,4,0,
28,10,203,4,0,
28,10,205,1,21,
28,10,205,3,0,
28,10,207,4,0,
28,10,210,1,28,
28,10,210,3,0,
28,10,213,4,0,
28,10,214,4,0,
28,10,216,4,0,
28,10,218,4,0,
28,10,229,1,13,
28,10,231,4,0,
28,10,237,4,0,
28,10,241,4,0,
28,10,249,4,0,
28,10,263,4,0,
28,10,264,4,0,
28,10,280,4,0,
28,10,282,3,0,
28,10,290,4,0,
28,10,306,1,22,
28,10,317,4,0,
28,10,328,1,33,
28,10,332,4,0,
28,10,360,1,45,
28,10,360,4,0,
28,10,363,4,0,
28,10,374,4,0,
28,10,398,4,0,
28,10,404,4,0,
28,10,411,4,0,
28,10,414,3,0,
28,10,416,4,0,
28,10,421,4,0,
28,10,431,4,0,
28,10,444,4,0,
28,10,445,4,0,
28,10,446,4,0,
28,11,10,1,1,1
28,11,14,4,0,
28,11,15,4,0,
28,11,28,1,1,3
28,11,28,1,7,
28,11,40,1,9,
28,11,63,4,0,
28,11,70,4,0,
28,11,89,4,0,
28,11,91,4,0,
28,11,92,4,0,
28,11,104,4,0,
28,11,111,1,1,2
28,11,111,1,3,
28,11,129,1,15,
28,11,154,1,19,
28,11,156,4,0,
28,11,157,4,0,
28,11,163,1,40,
28,11,164,4,0,
28,11,168,4,0,
28,11,182,4,0,
28,11,201,1,52,
28,11,201,4,0,
28,11,205,1,21,
28,11,207,4,0,
28,11,210,1,28,
28,11,213,4,0,
28,11,216,4,0,
28,11,218,4,0,
28,11,219,4,0,
28,11,229,1,13,
28,11,237,4,0,
28,11,241,4,0,
28,11,249,4,0,
28,11,263,4,0,
28,11,280,4,0,
28,11,306,1,22,
28,11,317,4,0,
28,11,328,1,33,
28,11,332,4,0,
28,11,360,1,45,
28,11,360,4,0,
28,11,374,4,0,
28,11,398,4,0,
28,11,404,4,0,
28,11,411,4,0,
28,11,416,4,0,
28,11,421,4,0,
28,11,444,4,0,
28,11,468,4,0,
28,11,496,4,0,
28,11,523,4,0,
28,12,10,1,1,1
28,12,15,4,0,
28,12,28,1,1,3
28,12,28,1,11,
28,12,40,1,17,
28,12,63,4,0,
28,12,70,4,0,
28,12,89,4,0,
28,12,91,4,0,
28,12,92,4,0,
28,12,104,4,0,
28,12,111,1,1,2
28,12,111,1,6,
28,12,129,1,33,
28,12,154,1,42,
28,12,156,4,0,
28,12,163,1,24,
28,12,168,4,0,
28,12,182,4,0,
28,12,201,1,62,
28,12,201,4,0,
28,12,213,4,0,
28,12,216,4,0,
28,12,218,4,0,
28,12,231,4,0,
28,12,237,4,0,
28,12,241,4,0,
28,12,249,4,0,
28,12,263,4,0,
28,12,264,4,0,
28,12,280,4,0,
28,12,290,4,0,
28,12,317,4,0,
28,12,328,1,52,
28,12,332,4,0,
28,13,10,1,1,1
28,13,15,4,0,
28,13,28,1,1,3
28,13,28,1,11,
28,13,34,3,0,
28,13,38,3,0,
28,13,40,1,17,
28,13,63,4,0,
28,13,69,3,0,
28,13,70,4,0,
28,13,89,4,0,
28,13,91,4,0,
28,13,92,4,0,
28,13,102,3,0,
28,13,104,4,0,
28,13,111,1,1,2
28,13,111,1,6,
28,13,129,1,33,
28,13,154,1,42,
28,13,156,4,0,
28,13,163,1,24,
28,13,164,3,0,
28,13,168,4,0,
28,13,182,4,0,
28,13,201,1,62,
28,13,201,4,0,
28,13,207,3,0,
28,13,213,4,0,
28,13,216,4,0,
28,13,218,4,0,
28,13,231,4,0,
28,13,237,4,0,
28,13,241,4,0,
28,13,249,4,0,
28,13,263,4,0,
28,13,264,4,0,
28,13,280,4,0,
28,13,290,4,0,
28,13,317,4,0,
28,13,328,1,52,
28,13,332,4,0,
28,14,10,1,1,1
28,14,14,1,38,
28,14,14,4,0,
28,14,15,4,0,
28,14,28,1,1,3
28,14,28,1,3,
28,14,40,1,1,4
28,14,40,1,5,
28,14,63,4,0,
28,14,70,4,0,
28,14,89,1,46,
28,14,89,4,0,
28,14,91,1,30,
28,14,91,4,0,
28,14,92,4,0,
28,14,104,4,0,
28,14,111,1,1,2
28,14,129,1,11,
28,14,154,1,20,
28,14,156,4,0,
28,14,157,4,0,
28,14,162,3,0,
28,14,163,1,26,
28,14,164,4,0,
28,14,168,4,0,
28,14,173,3,0,
28,14,182,4,0,
28,14,201,1,42,
28,14,201,4,0,
28,14,205,1,7,
28,14,207,4,0,
28,14,210,1,14,
28,14,213,4,0,
28,14,214,3,0,
28,14,216,4,0,
28,14,218,4,0,
28,14,219,4,0,
28,14,222,1,17,
28,14,229,1,9,
28,14,231,3,0,
28,14,237,4,0,
28,14,241,4,0,
28,14,249,4,0,
28,14,263,4,0,
28,14,280,4,0,
28,14,282,3,0,
28,14,306,1,22,
28,14,317,4,0,
28,14,328,1,23,
28,14,332,4,0,
28,14,343,3,0,
28,14,360,1,34,
28,14,360,4,0,
28,14,374,4,0,
28,14,398,4,0,
28,14,404,4,0,
28,14,411,4,0,
28,14,414,3,0,
28,14,416,4,0,
28,14,421,4,0,
28,14,444,4,0,
28,14,446,3,0,
28,14,468,4,0,
28,14,496,4,0,
28,14,523,4,0,
28,15,10,1,1,1
28,15,14,1,38,
28,15,14,4,0,
28,15,15,4,0,
28,15,28,1,1,3
28,15,28,1,3,
28,15,40,1,1,4
28,15,40,1,5,
28,15,63,4,0,
28,15,70,4,0,
28,15,89,1,46,
28,15,89,4,0,
28,15,91,1,30,
28,15,91,4,0,
28,15,92,4,0,
28,15,104,4,0,
28,15,111,1,1,2
28,15,129,1,11,
28,15,154,1,20,
28,15,156,4,0,
28,15,157,4,0,
28,15,163,1,26,
28,15,164,4,0,
28,15,168,4,0,
28,15,182,4,0,
28,15,201,1,42,
28,15,201,4,0,
28,15,205,1,7,
28,15,207,4,0,
28,15,210,1,14,
28,15,213,4,0,
28,15,214,4,0,
28,15,216,4,0,
28,15,218,4,0,
28,15,219,4,0,
28,15,222,1,17,
28,15,229,1,9,
28,15,237,4,0,
28,15,241,4,0,
28,15,249,4,0,
28,15,263,4,0,
28,15,280,4,0,
28,15,306,1,22,
28,15,317,4,0,
28,15,328,1,23,
28,15,332,4,0,
28,15,360,1,34,
28,15,360,4,0,
28,15,374,4,0,
28,15,398,4,0,
28,15,404,4,0,
28,15,411,4,0,
28,15,416,4,0,
28,15,421,4,0,
28,15,444,4,0,
28,15,468,4,0,
28,15,496,4,0,
28,15,523,4,0,
28,15,590,4,0,
28,16,10,1,1,1
28,16,14,1,43,1
28,16,14,4,0,
28,16,15,4,0,
28,16,28,1,1,3
28,16,28,1,3,1
28,16,40,1,1,4
28,16,40,1,5,1
28,16,63,4,0,
28,16,70,4,0,
28,16,89,1,53,1
28,16,89,4,0,
28,16,91,1,33,1
28,16,91,4,0,
28,16,92,4,0,
28,16,104,4,0,
28,16,111,1,1,2
28,16,129,1,17,1
28,16,154,1,20,1
28,16,156,4,0,
28,16,157,4,0,
28,16,162,3,0,
28,16,163,1,28,1
28,16,164,4,0,
28,16,168,4,0,
28,16,173,3,0,
28,16,182,4,0,
28,16,201,1,48,1
28,16,201,4,0,
28,16,205,1,7,1
28,16,207,4,0,
28,16,210,1,11,1
28,16,213,4,0,
28,16,214,4,0,
28,16,216,4,0,
28,16,218,4,0,
28,16,219,4,0,
28,16,222,1,14,1
28,16,229,1,9,1
28,16,231,3,0,
28,16,237,4,0,
28,16,241,4,0,
28,16,249,4,0,
28,16,263,4,0,
28,16,264,3,0,
28,16,280,4,0,
28,16,282,3,0,
28,16,290,4,0,
28,16,306,1,22,1
28,16,317,4,0,
28,16,328,1,24,1
28,16,332,4,0,
28,16,343,3,0,
28,16,360,1,38,1
28,16,360,4,0,
28,16,374,4,0,
28,16,398,4,0,
28,16,404,4,0,
28,16,411,4,0,
28,16,414,3,0,
28,16,416,4,0,
28,16,421,4,0,
28,16,444,4,0,
28,16,446,3,0,
28,16,468,4,0,
28,16,496,4,0,
28,16,523,4,0,
28,16,590,4,0,
28,17,10,1,1,2
28,17,14,1,43,
28,17,14,4,0,
28,17,28,1,1,4
28,17,28,1,3,
28,17,40,1,1,5
28,17,40,1,5,
28,17,63,4,0,
28,17,89,1,53,
28,17,89,4,0,
28,17,91,1,33,
28,17,92,4,0,
28,17,104,4,0,
28,17,111,1,1,3
28,17,129,1,17,
28,17,154,1,20,
28,17,156,4,0,
28,17,157,4,0,
28,17,163,1,28,
28,17,164,4,0,
28,17,168,4,0,
28,17,182,4,0,
28,17,201,1,48,
28,17,201,4,0,
28,17,205,1,7,
28,17,207,4,0,
28,17,210,1,11,
28,17,213,4,0,
28,17,214,4,0,
28,17,216,4,0,
28,17,218,4,0,
28,17,219,4,0,
28,17,222,1,14,
28,17,229,1,9,
28,17,237,4,0,
28,17,241,4,0,
28,17,263,4,0,
28,17,280,4,0,
28,17,306,1,0,
28,17,306,1,1,1
28,17,317,4,0,
28,17,328,1,24,
28,17,332,4,0,
28,17,360,1,38,
28,17,360,4,0,
28,17,374,4,0,
28,17,398,4,0,
28,17,404,4,0,
28,17,411,4,0,
28,17,416,4,0,
28,17,421,4,0,
28,17,444,4,0,
28,17,496,4,0,
28,17,523,4,0,
28,17,590,4,0,
28,18,10,1,1,2
28,18,14,1,43,
28,18,14,4,0,
28,18,28,1,1,4
28,18,28,1,3,
28,18,40,1,1,5
28,18,40,1,5,
28,18,63,4,0,
28,18,89,1,53,
28,18,89,4,0,
28,18,91,1,33,
28,18,92,4,0,
28,18,104,4,0,
28,18,111,1,1,3
28,18,129,1,17,
28,18,154,1,20,
28,18,156,4,0,
28,18,157,4,0,
28,18,163,1,28,
28,18,164,4,0,
28,18,168,4,0,
28,18,182,4,0,
28,18,201,1,48,
28,18,201,4,0,
28,18,205,1,7,
28,18,207,4,0,
28,18,210,1,11,
28,18,213,4,0,
28,18,214,4,0,
28,18,216,4,0,
28,18,218,4,0,
28,18,219,4,0,
28,18,222,1,14,
28,18,229,1,9,
28,18,237,4,0,
28,18,241,4,0,
28,18,263,4,0,
28,18,280,4,0,
28,18,306,1,0,
28,18,306,1,1,1
28,18,317,4,0,
28,18,328,1,24,
28,18,332,4,0,
28,18,360,1,38,
28,18,360,4,0,
28,18,374,4,0,
28,18,398,4,0,
28,18,404,4,0,
28,18,411,4,0,
28,18,416,4,0,
28,18,421,4,0,
28,18,444,4,0,
28,18,496,4,0,
28,18,523,4,0,
28,18,590,4,0,
29,1,10,1,8,
29,1,24,1,43,
29,1,33,1,1,2
29,1,34,4,0,
29,1,36,4,0,
29,1,38,4,0,
29,1,39,1,21,
29,1,40,1,14,
29,1,44,1,29,
29,1,45,1,1,1
29,1,59,4,0,
29,1,85,4,0,
29,1,87,4,0,
29,1,92,4,0,
29,1,99,4,0,
29,1,102,4,0,
29,1,104,4,0,
29,1,115,4,0,
29,1,117,4,0,
29,1,130,4,0,
29,1,154,1,36,
29,1,156,4,0,
29,1,164,4,0,
29,2,10,1,8,
29,2,24,1,12,
29,2,33,1,1,2
29,2,34,4,0,
29,2,36,4,0,
29,2,38,4,0,
29,2,39,1,23,
29,2,40,1,17,
29,2,44,1,30,
29,2,45,1,1,1
29,2,59,4,0,
29,2,85,4,0,
29,2,87,4,0,
29,2,92,4,0,
29,2,99,4,0,
29,2,102,4,0,
29,2,104,4,0,
29,2,115,4,0,
29,2,117,4,0,
29,2,130,4,0,
29,2,154,1,38,
29,2,156,4,0,
29,2,164,4,0,
29,3,10,1,8,
29,3,24,1,12,
29,3,29,4,0,
29,3,33,1,1,2
29,3,36,2,0,
29,3,39,1,23,
29,3,40,1,17,
29,3,44,1,30,
29,3,45,1,1,1
29,3,48,2,0,
29,3,50,2,0,
29,3,59,4,0,
29,3,68,2,0,
29,3,87,4,0,
29,3,92,4,0,
29,3,104,4,0,
29,3,111,4,0,
29,3,116,2,0,
29,3,154,1,38,
29,3,156,4,0,
29,3,168,4,0,
29,3,173,4,0,
29,3,174,4,0,
29,3,182,4,0,
29,3,189,4,0,
29,3,197,4,0,
29,3,203,4,0,
29,3,204,2,0,
29,3,207,4,0,
29,3,213,4,0,
29,3,214,4,0,
29,3,216,4,0,
29,3,218,4,0,
29,3,231,4,0,
29,3,237,4,0,
29,3,240,4,0,
29,3,241,4,0,
29,3,251,2,0,
29,4,10,1,8,
29,4,24,1,12,
29,4,29,4,0,
29,4,33,1,1,2
29,4,36,2,0,
29,4,39,1,23,
29,4,40,1,17,
29,4,44,1,30,
29,4,45,1,1,1
29,4,48,2,0,
29,4,50,2,0,
29,4,59,4,0,
29,4,68,2,0,
29,4,85,3,0,
29,4,87,4,0,
29,4,92,4,0,
29,4,104,4,0,
29,4,111,4,0,
29,4,116,2,0,
29,4,154,1,38,
29,4,156,4,0,
29,4,168,4,0,
29,4,173,4,0,
29,4,174,4,0,
29,4,182,4,0,
29,4,189,4,0,
29,4,197,4,0,
29,4,203,4,0,
29,4,204,2,0,
29,4,207,4,0,
29,4,213,4,0,
29,4,214,4,0,
29,4,216,4,0,
29,4,218,4,0,
29,4,231,4,0,
29,4,237,4,0,
29,4,240,4,0,
29,4,241,4,0,
29,4,251,2,0,
29,5,10,1,1,2
29,5,15,4,0,
29,5,24,1,12,
29,5,36,2,0,
29,5,39,1,8,
29,5,40,1,17,
29,5,44,1,20,
29,5,45,1,1,1
29,5,48,2,0,
29,5,50,2,0,
29,5,58,4,0,
29,5,59,4,0,
29,5,68,2,0,
29,5,70,4,0,
29,5,85,4,0,
29,5,87,4,0,
29,5,91,4,0,
29,5,92,4,0,
29,5,104,4,0,
29,5,116,2,0,
29,5,154,1,30,
29,5,156,4,0,
29,5,168,4,0,
29,5,182,4,0,
29,5,188,4,0,
29,5,204,2,0,
29,5,213,4,0,
29,5,216,4,0,
29,5,218,4,0,
29,5,231,4,0,
29,5,237,4,0,
29,5,240,4,0,
29,5,241,4,0,
29,5,242,1,47,
29,5,249,4,0,
29,5,251,2,0,
29,5,260,1,38,
29,5,263,4,0,
29,5,270,1,23,
29,5,290,4,0,
29,5,332,4,0,
29,5,351,4,0,
29,5,352,4,0,
29,6,10,1,1,2
29,6,15,4,0,
29,6,24,1,12,
29,6,34,3,0,
29,6,36,2,0,
29,6,38,3,0,
29,6,39,1,8,
29,6,40,1,17,
29,6,44,1,20,
29,6,45,1,1,1
29,6,48,2,0,
29,6,50,2,0,
29,6,58,4,0,
29,6,59,4,0,
29,6,68,2,0,
29,6,68,3,0,
29,6,70,4,0,
29,6,85,4,0,
29,6,87,4,0,
29,6,91,4,0,
29,6,92,4,0,
29,6,102,3,0,
29,6,104,4,0,
29,6,111,3,0,
29,6,116,2,0,
29,6,154,1,30,
29,6,156,4,0,
29,6,164,3,0,
29,6,168,4,0,
29,6,173,3,0,
29,6,182,4,0,
29,6,188,4,0,
29,6,189,3,0,
29,6,203,3,0,
29,6,204,2,0,
29,6,207,3,0,
29,6,213,4,0,
29,6,214,3,0,
29,6,216,4,0,
29,6,218,4,0,
29,6,231,4,0,
29,6,237,4,0,
29,6,240,4,0,
29,6,241,4,0,
29,6,242,1,47,
29,6,249,4,0,
29,6,251,2,0,
29,6,260,1,38,
29,6,263,4,0,
29,6,270,1,23,
29,6,290,4,0,
29,6,332,4,0,
29,6,351,4,0,
29,6,352,4,0,
29,7,10,1,1,2
29,7,15,4,0,
29,7,24,1,12,
29,7,34,3,0,
29,7,36,2,0,
29,7,38,3,0,
29,7,39,1,8,
29,7,40,1,17,
29,7,44,1,20,
29,7,45,1,1,1
29,7,48,2,0,
29,7,50,2,0,
29,7,58,4,0,
29,7,59,4,0,
29,7,68,2,0,
29,7,68,3,0,
29,7,70,4,0,
29,7,85,4,0,
29,7,87,4,0,
29,7,91,4,0,
29,7,92,4,0,
29,7,102,3,0,
29,7,104,4,0,
29,7,116,2,0,
29,7,154,1,30,
29,7,156,4,0,
29,7,164,3,0,
29,7,168,4,0,
29,7,182,4,0,
29,7,188,4,0,
29,7,204,2,0,
29,7,213,4,0,
29,7,216,4,0,
29,7,218,4,0,
29,7,231,4,0,
29,7,237,4,0,
29,7,240,4,0,
29,7,241,4,0,
29,7,242,1,47,
29,7,249,4,0,
29,7,251,2,0,
29,7,260,1,38,
29,7,263,4,0,
29,7,270,1,23,
29,7,290,4,0,
29,7,332,4,0,
29,7,351,4,0,
29,7,352,4,0,
29,8,10,1,1,2
29,8,15,4,0,
29,8,24,1,9,
29,8,36,2,0,
29,8,39,1,7,
29,8,40,1,13,
29,8,44,1,21,
29,8,45,1,1,1
29,8,48,2,0,
29,8,50,2,0,
29,8,58,4,0,
29,8,59,4,0,
29,8,68,2,0,
29,8,70,4,0,
29,8,85,4,0,
29,8,87,4,0,
29,8,91,4,0,
29,8,92,4,0,
29,8,104,4,0,
29,8,116,2,0,
29,8,154,1,19,
29,8,156,4,0,
29,8,164,4,0,
29,8,168,4,0,
29,8,182,4,0,
29,8,188,4,0,
29,8,203,4,0,
29,8,204,2,0,
29,8,207,4,0,
29,8,213,4,0,
29,8,214,4,0,
29,8,216,4,0,
29,8,218,4,0,
29,8,228,2,0,
29,8,231,4,0,
29,8,237,4,0,
29,8,240,4,0,
29,8,241,4,0,
29,8,242,1,37,
29,8,249,4,0,
29,8,251,2,0,
29,8,260,1,33,
29,8,263,4,0,
29,8,270,1,25,
29,8,290,4,0,
29,8,305,1,45,
29,8,332,4,0,
29,8,351,4,0,
29,8,352,4,0,
29,8,363,4,0,
29,8,390,1,31,
29,8,398,4,0,
29,8,421,4,0,
29,8,445,1,43,
29,8,445,4,0,
29,9,10,1,1,2
29,9,15,4,0,
29,9,24,1,9,
29,9,36,2,0,
29,9,39,1,7,
29,9,40,1,13,
29,9,44,1,21,
29,9,45,1,1,1
29,9,48,2,0,
29,9,50,2,0,
29,9,58,4,0,
29,9,59,4,0,
29,9,68,2,0,
29,9,70,4,0,
29,9,85,4,0,
29,9,87,4,0,
29,9,91,4,0,
29,9,92,4,0,
29,9,104,4,0,
29,9,116,2,0,
29,9,154,1,19,
29,9,156,4,0,
29,9,164,4,0,
29,9,168,4,0,
29,9,173,3,0,
29,9,182,4,0,
29,9,188,4,0,
29,9,189,3,0,
29,9,203,4,0,
29,9,204,2,0,
29,9,207,4,0,
29,9,213,4,0,
29,9,214,4,0,
29,9,216,4,0,
29,9,218,4,0,
29,9,228,2,0,
29,9,231,4,0,
29,9,237,4,0,
29,9,240,4,0,
29,9,241,4,0,
29,9,242,1,37,
29,9,249,4,0,
29,9,251,2,0,
29,9,260,1,33,
29,9,263,4,0,
29,9,270,1,25,
29,9,270,3,0,
29,9,290,4,0,
29,9,305,1,45,
29,9,332,4,0,
29,9,351,4,0,
29,9,352,4,0,
29,9,363,4,0,
29,9,390,1,31,
29,9,398,4,0,
29,9,421,4,0,
29,9,445,1,43,
29,9,445,4,0,
29,10,10,1,1,2
29,10,15,4,0,
29,10,24,1,9,
29,10,29,3,0,
29,10,36,2,0,
29,10,39,1,7,
29,10,40,1,13,
29,10,44,1,21,
29,10,45,1,1,1
29,10,48,2,0,
29,10,50,2,0,
29,10,58,4,0,
29,10,59,4,0,
29,10,68,2,0,
29,10,70,4,0,
29,10,85,4,0,
29,10,87,4,0,
29,10,91,4,0,
29,10,92,4,0,
29,10,104,4,0,
29,10,116,2,0,
29,10,130,2,0,
29,10,154,1,19,
29,10,156,4,0,
29,10,162,3,0,
29,10,164,4,0,
29,10,168,4,0,
29,10,173,3,0,
29,10,182,4,0,
29,10,188,4,0,
29,10,189,3,0,
29,10,203,4,0,
29,10,204,2,0,
29,10,207,4,0,
29,10,213,4,0,
29,10,214,4,0,
29,10,216,4,0,
29,10,218,4,0,
29,10,228,2,0,
29,10,231,4,0,
29,10,237,4,0,
29,10,240,4,0,
29,10,241,4,0,
29,10,242,1,37,
29,10,249,4,0,
29,10,251,2,0,
29,10,260,1,33,
29,10,263,4,0,
29,10,270,1,25,
29,10,270,3,0,
29,10,290,4,0,
29,10,305,1,45,
29,10,332,4,0,
29,10,351,4,0,
29,10,352,4,0,
29,10,363,4,0,
29,10,390,1,31,
29,10,398,4,0,
29,10,421,4,0,
29,10,445,1,43,
29,10,445,4,0,
29,11,10,1,1,2
29,11,15,4,0,
29,11,24,1,9,
29,11,36,2,0,
29,11,39,1,7,
29,11,40,1,13,
29,11,44,1,21,
29,11,45,1,1,1
29,11,48,2,0,
29,11,50,2,0,
29,11,58,4,0,
29,11,59,4,0,
29,11,68,2,0,
29,11,70,4,0,
29,11,85,4,0,
29,11,87,4,0,
29,11,91,4,0,
29,11,92,4,0,
29,11,104,4,0,
29,11,116,2,0,
29,11,130,2,0,
29,11,154,1,19,
29,11,156,4,0,
29,11,164,4,0,
29,11,168,4,0,
29,11,182,4,0,
29,11,188,4,0,
29,11,203,2,0,
29,11,204,2,0,
29,11,207,4,0,
29,11,213,4,0,
29,11,216,4,0,
29,11,218,4,0,
29,11,228,2,0,
29,11,231,2,0,
29,11,237,4,0,
29,11,240,4,0,
29,11,241,4,0,
29,11,242,1,37,
29,11,249,4,0,
29,11,251,2,0,
29,11,260,1,33,
29,11,263,4,0,
29,11,270,1,25,
29,11,305,1,45,
29,11,332,4,0,
29,11,342,2,0,
29,11,390,1,31,
29,11,398,4,0,
29,11,421,4,0,
29,11,445,1,43,
29,11,468,4,0,
29,11,474,4,0,
29,11,496,4,0,
29,11,498,2,0,
29,12,10,1,1,2
29,12,15,4,0,
29,12,24,1,12,
29,12,39,1,8,
29,12,40,1,17,
29,12,44,1,20,
29,12,45,1,1,1
29,12,58,4,0,
29,12,59,4,0,
29,12,70,4,0,
29,12,85,4,0,
29,12,87,4,0,
29,12,91,4,0,
29,12,92,4,0,
29,12,104,4,0,
29,12,154,1,30,
29,12,156,4,0,
29,12,168,4,0,
29,12,182,4,0,
29,12,188,4,0,
29,12,213,4,0,
29,12,216,4,0,
29,12,218,4,0,
29,12,231,4,0,
29,12,237,4,0,
29,12,240,4,0,
29,12,241,4,0,
29,12,242,1,47,
29,12,249,4,0,
29,12,260,1,38,
29,12,263,4,0,
29,12,270,1,23,
29,12,290,4,0,
29,12,332,4,0,
29,12,351,4,0,
29,12,352,4,0,
29,13,10,1,1,2
29,13,15,4,0,
29,13,24,1,12,
29,13,34,3,0,
29,13,38,3,0,
29,13,39,1,8,
29,13,40,1,17,
29,13,44,1,20,
29,13,45,1,1,1
29,13,58,4,0,
29,13,59,4,0,
29,13,70,4,0,
29,13,85,4,0,
29,13,87,4,0,
29,13,91,4,0,
29,13,92,4,0,
29,13,102,3,0,
29,13,104,4,0,
29,13,154,1,30,
29,13,156,4,0,
29,13,164,3,0,
29,13,168,4,0,
29,13,182,4,0,
29,13,188,4,0,
29,13,207,3,0,
29,13,213,4,0,
29,13,216,4,0,
29,13,218,4,0,
29,13,231,4,0,
29,13,237,4,0,
29,13,240,4,0,
29,13,241,4,0,
29,13,242,1,47,
29,13,249,4,0,
29,13,260,1,38,
29,13,263,4,0,
29,13,270,1,23,
29,13,290,4,0,
29,13,332,4,0,
29,13,351,4,0,
29,13,352,4,0,
29,14,10,1,1,2
29,14,15,4,0,
29,14,24,1,9,
29,14,36,2,0,
29,14,39,1,7,
29,14,40,1,13,
29,14,44,1,21,
29,14,45,1,1,1
29,14,48,2,0,
29,14,50,2,0,
29,14,58,4,0,
29,14,59,4,0,
29,14,68,2,0,
29,14,70,4,0,
29,14,85,4,0,
29,14,87,4,0,
29,14,91,4,0,
29,14,92,4,0,
29,14,104,4,0,
29,14,116,2,0,
29,14,130,2,0,
29,14,154,1,19,
29,14,156,4,0,
29,14,162,3,0,
29,14,164,4,0,
29,14,168,4,0,
29,14,173,3,0,
29,14,182,4,0,
29,14,188,4,0,
29,14,203,2,0,
29,14,204,2,0,
29,14,207,4,0,
29,14,213,4,0,
29,14,214,3,0,
29,14,216,4,0,
29,14,218,4,0,
29,14,228,2,0,
29,14,231,2,0,
29,14,231,3,0,
29,14,237,4,0,
29,14,240,4,0,
29,14,241,4,0,
29,14,242,1,37,
29,14,249,4,0,
29,14,251,2,0,
29,14,260,1,33,
29,14,263,4,0,
29,14,270,1,25,
29,14,270,3,0,
29,14,305,1,45,
29,14,332,4,0,
29,14,342,2,0,
29,14,390,1,31,
29,14,398,4,0,
29,14,421,4,0,
29,14,445,1,43,
29,14,468,4,0,
29,14,474,4,0,
29,14,496,4,0,
29,14,498,2,0,
29,15,10,1,1,2
29,15,15,4,0,
29,15,24,1,9,
29,15,36,2,0,
29,15,39,1,7,
29,15,40,1,13,
29,15,44,1,21,
29,15,45,1,1,1
29,15,48,2,0,
29,15,50,2,0,
29,15,58,4,0,
29,15,59,4,0,
29,15,68,2,0,
29,15,70,4,0,
29,15,85,4,0,
29,15,87,4,0,
29,15,91,4,0,
29,15,92,4,0,
29,15,104,4,0,
29,15,116,2,0,
29,15,130,2,0,
29,15,154,1,19,
29,15,156,4,0,
29,15,164,4,0,
29,15,168,4,0,
29,15,182,4,0,
29,15,188,4,0,
29,15,203,2,0,
29,15,204,2,0,
29,15,207,4,0,
29,15,213,4,0,
29,15,214,4,0,
29,15,216,4,0,
29,15,218,4,0,
29,15,228,2,0,
29,15,231,2,0,
29,15,237,4,0,
29,15,240,4,0,
29,15,241,4,0,
29,15,242,1,37,
29,15,249,4,0,
29,15,251,2,0,
29,15,260,1,33,
29,15,263,4,0,
29,15,270,1,25,
29,15,305,1,45,
29,15,332,4,0,
29,15,342,2,0,
29,15,390,1,31,
29,15,398,4,0,
29,15,421,4,0,
29,15,445,1,43,
29,15,468,4,0,
29,15,474,4,0,
29,15,496,4,0,
29,15,497,4,0,
29,15,498,2,0,
29,15,590,4,0,
29,15,599,2,0,
29,16,10,1,1,2
29,16,15,4,0,
29,16,24,1,9,1
29,16,36,2,0,
29,16,39,1,7,1
29,16,40,1,13,1
29,16,44,1,21,1
29,16,45,1,1,1
29,16,48,2,0,
29,16,50,2,0,
29,16,58,4,0,
29,16,59,4,0,
29,16,68,2,0,
29,16,70,4,0,
29,16,85,4,0,
29,16,87,4,0,
29,16,91,4,0,
29,16,92,4,0,
29,16,104,4,0,
29,16,116,2,0,
29,16,130,2,0,
29,16,154,1,19,1
29,16,156,4,0,
29,16,162,3,0,
29,16,164,4,0,
29,16,168,4,0,
29,16,173,3,0,
29,16,182,4,0,
29,16,188,4,0,
29,16,203,2,0,
29,16,204,2,0,
29,16,207,4,0,
29,16,213,4,0,
29,16,214,4,0,
29,16,216,4,0,
29,16,218,4,0,
29,16,228,2,0,
29,16,231,2,0,
29,16,231,3,0,
29,16,237,4,0,
29,16,240,4,0,
29,16,241,4,0,
29,16,242,1,37,1
29,16,249,4,0,
29,16,251,2,0,
29,16,260,1,33,1
29,16,263,4,0,
29,16,270,1,25,1
29,16,270,3,0,
29,16,290,4,0,
29,16,305,1,45,1
29,16,332,4,0,
29,16,342,2,0,
29,16,351,3,0,
29,16,352,3,0,
29,16,390,1,31,1
29,16,398,4,0,
29,16,421,4,0,
29,16,445,1,43,1
29,16,468,4,0,
29,16,474,4,0,
29,16,496,4,0,
29,16,497,4,0,
29,16,498,2,0,
29,16,590,4,0,
29,16,599,2,0,
29,17,10,1,1,2
29,17,24,1,9,
29,17,36,2,0,
29,17,39,1,7,
29,17,40,1,13,
29,17,44,1,21,
29,17,45,1,1,1
29,17,48,2,0,
29,17,50,2,0,
29,17,58,4,0,
29,17,59,4,0,
29,17,68,2,0,
29,17,85,4,0,
29,17,87,4,0,
29,17,92,4,0,
29,17,104,4,0,
29,17,116,2,0,
29,17,130,2,0,
29,17,154,1,19,
29,17,156,4,0,
29,17,164,4,0,
29,17,168,4,0,
29,17,182,4,0,
29,17,188,4,0,
29,17,203,2,0,
29,17,204,2,0,
29,17,207,4,0,
29,17,213,4,0,
29,17,214,4,0,
29,17,216,4,0,
29,17,218,4,0,
29,17,228,2,0,
29,17,231,2,0,
29,17,237,4,0,
29,17,240,4,0,
29,17,241,4,0,
29,17,242,1,37,
29,17,251,2,0,
29,17,260,1,33,
29,17,263,4,0,
29,17,270,1,25,
29,17,305,1,45,
29,17,332,4,0,
29,17,342,2,0,
29,17,390,1,31,
29,17,398,4,0,
29,17,421,4,0,
29,17,445,1,43,
29,17,474,4,0,
29,17,496,4,0,
29,17,497,4,0,
29,17,498,2,0,
29,17,590,4,0,
29,17,599,2,0,
29,18,10,1,1,2
29,18,24,1,9,
29,18,36,2,0,
29,18,39,1,7,
29,18,40,1,13,
29,18,44,1,21,
29,18,45,1,1,1
29,18,48,2,0,
29,18,50,2,0,
29,18,58,4,0,
29,18,59,4,0,
29,18,68,2,0,
29,18,85,4,0,
29,18,87,4,0,
29,18,92,4,0,
29,18,104,4,0,
29,18,116,2,0,
29,18,130,2,0,
29,18,154,1,19,
29,18,156,4,0,
29,18,164,4,0,
29,18,168,4,0,
29,18,182,4,0,
29,18,188,4,0,
29,18,203,2,0,
29,18,204,2,0,
29,18,207,4,0,
29,18,213,4,0,
29,18,214,4,0,
29,18,216,4,0,
29,18,218,4,0,
29,18,228,2,0,
29,18,231,2,0,
29,18,237,4,0,
29,18,240,4,0,
29,18,241,4,0,
29,18,242,1,37,
29,18,251,2,0,
29,18,260,1,33,
29,18,263,4,0,
29,18,270,1,25,
29,18,305,1,45,
29,18,332,4,0,
29,18,342,2,0,
29,18,390,1,31,
29,18,398,4,0,
29,18,421,4,0,
29,18,445,1,43,
29,18,474,4,0,
29,18,496,4,0,
29,18,497,4,0,
29,18,498,2,0,
29,18,590,4,0,
29,18,599,2,0,
30,1,10,1,1,3
30,1,10,1,8,
30,1,24,1,50,
30,1,32,4,0,
30,1,33,1,1,2
30,1,34,4,0,
30,1,36,4,0,
30,1,38,4,0,
30,1,39,1,23,
30,1,40,1,14,
30,1,44,1,32,
30,1,45,1,1,1
30,1,55,4,0,
30,1,58,4,0,
30,1,59,4,0,
30,1,61,4,0,
30,1,85,4,0,
30,1,87,4,0,
30,1,92,4,0,
30,1,99,4,0,
30,1,102,4,0,
30,1,104,4,0,
30,1,115,4,0,
30,1,117,4,0,
30,1,130,4,0,
30,1,154,1,41,
30,1,156,4,0,
30,1,164,4,0,
30,2,10,1,1,3
30,2,10,1,8,
30,2,24,1,12,
30,2,32,4,0,
30,2,33,1,1,2
30,2,34,4,0,
30,2,36,4,0,
30,2,38,4,0,
30,2,39,1,27,
30,2,40,1,19,
30,2,44,1,36,
30,2,45,1,1,1
30,2,55,4,0,
30,2,58,4,0,
30,2,59,4,0,
30,2,61,4,0,
30,2,85,4,0,
30,2,87,4,0,
30,2,92,4,0,
30,2,99,4,0,
30,2,102,4,0,
30,2,104,4,0,
30,2,115,4,0,
30,2,117,4,0,
30,2,130,4,0,
30,2,154,1,46,
30,2,156,4,0,
30,2,164,4,0,
30,3,10,1,8,
30,3,24,1,12,
30,3,29,4,0,
30,3,33,1,1,2
30,3,39,1,27,
30,3,40,1,19,
30,3,44,1,36,
30,3,45,1,1,1
30,3,59,4,0,
30,3,70,4,0,
30,3,87,4,0,
30,3,92,4,0,
30,3,104,4,0,
30,3,111,4,0,
30,3,154,1,46,
30,3,156,4,0,
30,3,168,4,0,
30,3,173,4,0,
30,3,174,4,0,
30,3,182,4,0,
30,3,189,4,0,
30,3,197,4,0,
30,3,203,4,0,
30,3,207,4,0,
30,3,213,4,0,
30,3,214,4,0,
30,3,216,4,0,
30,3,218,4,0,
30,3,231,4,0,
30,3,237,4,0,
30,3,240,4,0,
30,3,241,4,0,
30,3,249,4,0,
30,4,10,1,8,
30,4,24,1,12,
30,4,29,4,0,
30,4,33,1,1,2
30,4,39,1,27,
30,4,40,1,19,
30,4,44,1,36,
30,4,45,1,1,1
30,4,58,3,0,
30,4,59,4,0,
30,4,70,4,0,
30,4,85,3,0,
30,4,87,4,0,
30,4,92,4,0,
30,4,104,4,0,
30,4,111,4,0,
30,4,154,1,46,
30,4,156,4,0,
30,4,168,4,0,
30,4,173,4,0,
30,4,174,4,0,
30,4,182,4,0,
30,4,189,4,0,
30,4,197,4,0,
30,4,203,4,0,
30,4,207,4,0,
30,4,213,4,0,
30,4,214,4,0,
30,4,216,4,0,
30,4,218,4,0,
30,4,231,4,0,
30,4,237,4,0,
30,4,240,4,0,
30,4,241,4,0,
30,4,249,4,0,
30,5,10,1,1,2
30,5,15,4,0,
30,5,24,1,12,
30,5,39,1,8,
30,5,40,1,18,
30,5,44,1,22,
30,5,45,1,1,1
30,5,58,4,0,
30,5,59,4,0,
30,5,70,4,0,
30,5,85,4,0,
30,5,87,4,0,
30,5,91,4,0,
30,5,92,4,0,
30,5,104,4,0,
30,5,154,1,34,
30,5,156,4,0,
30,5,168,4,0,
30,5,182,4,0,
30,5,188,4,0,
30,5,213,4,0,
30,5,216,4,0,
30,5,218,4,0,
30,5,231,4,0,
30,5,237,4,0,
30,5,240,4,0,
30,5,241,4,0,
30,5,242,1,53,
30,5,249,4,0,
30,5,260,1,43,
30,5,263,4,0,
30,5,270,1,26,
30,5,290,4,0,
30,5,332,4,0,
30,5,351,4,0,
30,5,352,4,0,
30,6,10,1,1,2
30,6,15,4,0,
30,6,24,1,12,
30,6,34,3,0,
30,6,38,3,0,
30,6,39,1,8,
30,6,40,1,18,
30,6,44,1,22,
30,6,45,1,1,1
30,6,58,4,0,
30,6,59,4,0,
30,6,68,3,0,
30,6,70,4,0,
30,6,85,4,0,
30,6,87,4,0,
30,6,91,4,0,
30,6,92,4,0,
30,6,102,3,0,
30,6,104,4,0,
30,6,111,3,0,
30,6,154,1,34,
30,6,156,4,0,
30,6,164,3,0,
30,6,168,4,0,
30,6,173,3,0,
30,6,182,4,0,
30,6,188,4,0,
30,6,189,3,0,
30,6,203,3,0,
30,6,207,3,0,
30,6,213,4,0,
30,6,214,3,0,
30,6,216,4,0,
30,6,218,4,0,
30,6,231,4,0,
30,6,237,4,0,
30,6,240,4,0,
30,6,241,4,0,
30,6,242,1,53,
30,6,249,4,0,
30,6,260,1,43,
30,6,263,4,0,
30,6,270,1,26,
30,6,290,4,0,
30,6,332,4,0,
30,6,351,4,0,
30,6,352,4,0,
30,7,10,1,1,2
30,7,15,4,0,
30,7,24,1,12,
30,7,34,3,0,
30,7,38,3,0,
30,7,39,1,8,
30,7,40,1,18,
30,7,44,1,22,
30,7,45,1,1,1
30,7,58,4,0,
30,7,59,4,0,
30,7,68,3,0,
30,7,70,4,0,
30,7,85,4,0,
30,7,87,4,0,
30,7,91,4,0,
30,7,92,4,0,
30,7,102,3,0,
30,7,104,4,0,
30,7,154,1,34,
30,7,156,4,0,
30,7,164,3,0,
30,7,168,4,0,
30,7,182,4,0,
30,7,188,4,0,
30,7,213,4,0,
30,7,216,4,0,
30,7,218,4,0,
30,7,231,4,0,
30,7,237,4,0,
30,7,240,4,0,
30,7,241,4,0,
30,7,242,1,53,
30,7,249,4,0,
30,7,260,1,43,
30,7,263,4,0,
30,7,270,1,26,
30,7,290,4,0,
30,7,332,4,0,
30,7,351,4,0,
30,7,352,4,0,
30,8,10,1,1,2
30,8,15,4,0,
30,8,24,1,9,
30,8,39,1,7,
30,8,40,1,13,
30,8,44,1,23,
30,8,45,1,1,1
30,8,58,4,0,
30,8,59,4,0,
30,8,70,4,0,
30,8,85,4,0,
30,8,87,4,0,
30,8,91,4,0,
30,8,92,4,0,
30,8,104,4,0,
30,8,154,1,20,
30,8,156,4,0,
30,8,164,4,0,
30,8,168,4,0,
30,8,182,4,0,
30,8,188,4,0,
30,8,203,4,0,
30,8,207,4,0,
30,8,213,4,0,
30,8,214,4,0,
30,8,216,4,0,
30,8,218,4,0,
30,8,231,4,0,
30,8,237,4,0,
30,8,240,4,0,
30,8,241,4,0,
30,8,242,1,43,
30,8,249,4,0,
30,8,260,1,38,
30,8,263,4,0,
30,8,270,1,28,
30,8,290,4,0,
30,8,305,1,58,
30,8,332,4,0,
30,8,351,4,0,
30,8,352,4,0,
30,8,363,4,0,
30,8,390,1,35,
30,8,398,4,0,
30,8,421,4,0,
30,8,445,1,50,
30,8,445,4,0,
30,9,10,1,1,2
30,9,15,4,0,
30,9,24,1,9,
30,9,39,1,7,
30,9,40,1,13,
30,9,44,1,23,
30,9,45,1,1,1
30,9,58,4,0,
30,9,59,4,0,
30,9,70,4,0,
30,9,85,4,0,
30,9,87,4,0,
30,9,91,4,0,
30,9,92,4,0,
30,9,104,4,0,
30,9,154,1,20,
30,9,156,4,0,
30,9,164,4,0,
30,9,168,4,0,
30,9,173,3,0,
30,9,182,4,0,
30,9,188,4,0,
30,9,189,3,0,
30,9,203,4,0,
30,9,207,4,0,
30,9,213,4,0,
30,9,214,4,0,
30,9,216,4,0,
30,9,218,4,0,
30,9,231,4,0,
30,9,237,4,0,
30,9,240,4,0,
30,9,241,4,0,
30,9,242,1,43,
30,9,249,4,0,
30,9,260,1,38,
30,9,263,4,0,
30,9,270,1,28,
30,9,270,3,0,
30,9,290,4,0,
30,9,305,1,58,
30,9,332,4,0,
30,9,351,4,0,
30,9,352,4,0,
30,9,363,4,0,
30,9,390,1,35,
30,9,398,4,0,
30,9,421,4,0,
30,9,445,1,50,
30,9,445,4,0,
30,10,10,1,1,2
30,10,15,4,0,
30,10,24,1,9,
30,10,29,3,0,
30,10,39,1,7,
30,10,40,1,13,
30,10,44,1,23,
30,10,45,1,1,1
30,10,58,4,0,
30,10,59,4,0,
30,10,70,4,0,
30,10,85,4,0,
30,10,87,4,0,
30,10,91,4,0,
30,10,92,4,0,
30,10,104,4,0,
30,10,154,1,20,
30,10,156,4,0,
30,10,162,3,0,
30,10,164,4,0,
30,10,168,4,0,
30,10,173,3,0,
30,10,182,4,0,
30,10,188,4,0,
30,10,189,3,0,
30,10,203,4,0,
30,10,207,4,0,
30,10,213,4,0,
30,10,214,4,0,
30,10,216,4,0,
30,10,218,4,0,
30,10,231,4,0,
30,10,237,4,0,
30,10,240,4,0,
30,10,241,4,0,
30,10,242,1,43,
30,10,249,4,0,
30,10,260,1,38,
30,10,263,4,0,
30,10,270,1,28,
30,10,270,3,0,
30,10,290,4,0,
30,10,305,1,58,
30,10,332,4,0,
30,10,351,4,0,
30,10,352,4,0,
30,10,363,4,0,
30,10,390,1,35,
30,10,398,4,0,
30,10,421,4,0,
30,10,445,1,50,
30,10,445,4,0,
30,11,10,1,1,2
30,11,15,4,0,
30,11,24,1,9,
30,11,39,1,7,
30,11,40,1,13,
30,11,44,1,23,
30,11,45,1,1,1
30,11,58,4,0,
30,11,59,4,0,
30,11,70,4,0,
30,11,85,4,0,
30,11,87,4,0,
30,11,91,4,0,
30,11,92,4,0,
30,11,104,4,0,
30,11,154,1,20,
30,11,156,4,0,
30,11,164,4,0,
30,11,168,4,0,
30,11,182,4,0,
30,11,188,4,0,
30,11,207,4,0,
30,11,213,4,0,
30,11,216,4,0,
30,11,218,4,0,
30,11,237,4,0,
30,11,240,4,0,
30,11,241,4,0,
30,11,242,1,43,
30,11,249,4,0,
30,11,260,1,38,
30,11,263,4,0,
30,11,270,1,28,
30,11,305,1,58,
30,11,332,4,0,
30,11,390,1,35,
30,11,398,4,0,
30,11,421,4,0,
30,11,445,1,50,
30,11,468,4,0,
30,11,474,4,0,
30,11,496,4,0,
30,11,497,4,0,
30,12,10,1,1,2
30,12,15,4,0,
30,12,24,1,12,
30,12,39,1,8,
30,12,40,1,18,
30,12,44,1,22,
30,12,45,1,1,1
30,12,58,4,0,
30,12,59,4,0,
30,12,70,4,0,
30,12,85,4,0,
30,12,87,4,0,
30,12,91,4,0,
30,12,92,4,0,
30,12,104,4,0,
30,12,154,1,34,
30,12,156,4,0,
30,12,168,4,0,
30,12,182,4,0,
30,12,188,4,0,
30,12,213,4,0,
30,12,216,4,0,
30,12,218,4,0,
30,12,231,4,0,
30,12,237,4,0,
30,12,240,4,0,
30,12,241,4,0,
30,12,242,1,53,
30,12,249,4,0,
30,12,260,1,43,
30,12,263,4,0,
30,12,270,1,26,
30,12,290,4,0,
30,12,332,4,0,
30,12,351,4,0,
30,12,352,4,0,
30,13,10,1,1,2
30,13,15,4,0,
30,13,24,1,12,
30,13,34,3,0,
30,13,38,3,0,
30,13,39,1,8,
30,13,40,1,18,
30,13,44,1,22,
30,13,45,1,1,1
30,13,58,4,0,
30,13,59,4,0,
30,13,70,4,0,
30,13,85,4,0,
30,13,87,4,0,
30,13,91,4,0,
30,13,92,4,0,
30,13,102,3,0,
30,13,104,4,0,
30,13,154,1,34,
30,13,156,4,0,
30,13,164,3,0,
30,13,168,4,0,
30,13,182,4,0,
30,13,188,4,0,
30,13,207,3,0,
30,13,213,4,0,
30,13,216,4,0,
30,13,218,4,0,
30,13,231,4,0,
30,13,237,4,0,
30,13,240,4,0,
30,13,241,4,0,
30,13,242,1,53,
30,13,249,4,0,
30,13,260,1,43,
30,13,263,4,0,
30,13,270,1,26,
30,13,290,4,0,
30,13,332,4,0,
30,13,351,4,0,
30,13,352,4,0,
30,14,10,1,1,2
30,14,15,4,0,
30,14,24,1,9,
30,14,39,1,7,
30,14,40,1,13,
30,14,44,1,23,
30,14,45,1,1,1
30,14,58,4,0,
30,14,59,4,0,
30,14,70,4,0,
30,14,85,4,0,
30,14,87,4,0,
30,14,91,4,0,
30,14,92,4,0,
30,14,104,4,0,
30,14,154,1,20,
30,14,156,4,0,
30,14,162,3,0,
30,14,164,4,0,
30,14,168,4,0,
30,14,173,3,0,
30,14,182,4,0,
30,14,188,4,0,
30,14,207,4,0,
30,14,213,4,0,
30,14,214,3,0,
30,14,216,4,0,
30,14,218,4,0,
30,14,231,3,0,
30,14,237,4,0,
30,14,240,4,0,
30,14,241,4,0,
30,14,242,1,43,
30,14,249,4,0,
30,14,260,1,38,
30,14,263,4,0,
30,14,270,1,28,
30,14,270,3,0,
30,14,305,1,58,
30,14,332,4,0,
30,14,390,1,35,
30,14,398,4,0,
30,14,421,4,0,
30,14,445,1,50,
30,14,468,4,0,
30,14,474,4,0,
30,14,496,4,0,
30,14,497,4,0,
30,15,10,1,1,2
30,15,15,4,0,
30,15,24,1,9,
30,15,39,1,7,
30,15,40,1,13,
30,15,44,1,23,
30,15,45,1,1,1
30,15,58,4,0,
30,15,59,4,0,
30,15,70,4,0,
30,15,85,4,0,
30,15,87,4,0,
30,15,91,4,0,
30,15,92,4,0,
30,15,104,4,0,
30,15,154,1,20,
30,15,156,4,0,
30,15,164,4,0,
30,15,168,4,0,
30,15,182,4,0,
30,15,188,4,0,
30,15,207,4,0,
30,15,213,4,0,
30,15,214,4,0,
30,15,216,4,0,
30,15,218,4,0,
30,15,237,4,0,
30,15,240,4,0,
30,15,241,4,0,
30,15,242,1,43,
30,15,249,4,0,
30,15,260,1,38,
30,15,263,4,0,
30,15,270,1,28,
30,15,305,1,58,
30,15,332,4,0,
30,15,390,1,35,
30,15,398,4,0,
30,15,421,4,0,
30,15,445,1,50,
30,15,468,4,0,
30,15,474,4,0,
30,15,496,4,0,
30,15,497,4,0,
30,15,590,4,0,
30,16,10,1,1,2
30,16,15,4,0,
30,16,24,1,9,1
30,16,39,1,7,1
30,16,40,1,13,1
30,16,44,1,23,1
30,16,45,1,1,1
30,16,58,4,0,
30,16,59,4,0,
30,16,70,4,0,
30,16,85,4,0,
30,16,87,4,0,
30,16,91,4,0,
30,16,92,4,0,
30,16,104,4,0,
30,16,154,1,20,1
30,16,156,4,0,
30,16,162,3,0,
30,16,164,4,0,
30,16,168,4,0,
30,16,173,3,0,
30,16,182,4,0,
30,16,188,4,0,
30,16,207,4,0,
30,16,213,4,0,
30,16,214,4,0,
30,16,216,4,0,
30,16,218,4,0,
30,16,231,3,0,
30,16,237,4,0,
30,16,240,4,0,
30,16,241,4,0,
30,16,242,1,43,1
30,16,249,4,0,
30,16,260,1,38,1
30,16,263,4,0,
30,16,270,1,28,1
30,16,270,3,0,
30,16,290,4,0,
30,16,305,1,58,1
30,16,332,4,0,
30,16,351,3,0,
30,16,352,3,0,
30,16,390,1,35,1
30,16,398,4,0,
30,16,421,4,0,
30,16,445,1,50,1
30,16,468,4,0,
30,16,474,4,0,
30,16,496,4,0,
30,16,497,4,0,
30,16,590,4,0,
30,17,10,1,1,2
30,17,24,1,9,
30,17,39,1,7,
30,17,40,1,13,
30,17,44,1,23,
30,17,45,1,1,1
30,17,58,4,0,
30,17,59,4,0,
30,17,85,4,0,
30,17,87,4,0,
30,17,92,4,0,
30,17,104,4,0,
30,17,154,1,20,
30,17,156,4,0,
30,17,164,4,0,
30,17,168,4,0,
30,17,182,4,0,
30,17,188,4,0,
30,17,207,4,0,
30,17,213,4,0,
30,17,214,4,0,
30,17,216,4,0,
30,17,218,4,0,
30,17,237,4,0,
30,17,240,4,0,
30,17,241,4,0,
30,17,242,1,43,
30,17,260,1,38,
30,17,263,4,0,
30,17,270,1,28,
30,17,305,1,58,
30,17,332,4,0,
30,17,390,1,35,
30,17,398,4,0,
30,17,421,4,0,
30,17,445,1,50,
30,17,474,4,0,
30,17,496,4,0,
30,17,497,4,0,
30,17,590,4,0,
30,18,10,1,1,2
30,18,24,1,9,
30,18,39,1,7,
30,18,40,1,13,
30,18,44,1,23,
30,18,45,1,1,1
30,18,58,4,0,
30,18,59,4,0,
30,18,85,4,0,
30,18,87,4,0,
30,18,92,4,0,
30,18,104,4,0,
30,18,154,1,20,
30,18,156,4,0,
30,18,164,4,0,
30,18,168,4,0,
30,18,182,4,0,
30,18,188,4,0,
30,18,207,4,0,
30,18,213,4,0,
30,18,214,4,0,
30,18,216,4,0,
30,18,218,4,0,
30,18,237,4,0,
30,18,240,4,0,
30,18,241,4,0,
30,18,242,1,43,
30,18,260,1,38,
30,18,263,4,0,
30,18,270,1,28,
30,18,305,1,58,
30,18,332,4,0,
30,18,390,1,35,
30,18,398,4,0,
30,18,421,4,0,
30,18,445,1,50,
30,18,474,4,0,
30,18,496,4,0,
30,18,497,4,0,
30,18,590,4,0,
31,1,5,4,0,
31,1,6,4,0,
31,1,10,1,1,2
31,1,10,1,8,
31,1,25,4,0,
31,1,32,4,0,
31,1,33,1,1,1
31,1,34,1,1,4
31,1,34,1,23,
31,1,34,4,0,
31,1,36,4,0,
31,1,38,4,0,
31,1,39,1,1,3
31,1,40,1,14,
31,1,55,4,0,
31,1,57,4,0,
31,1,58,4,0,
31,1,59,4,0,
31,1,61,4,0,
31,1,63,4,0,
31,1,66,4,0,
31,1,68,4,0,
31,1,69,4,0,
31,1,70,4,0,
31,1,85,4,0,
31,1,87,4,0,
31,1,89,4,0,
31,1,90,4,0,
31,1,92,4,0,
31,1,99,4,0,
31,1,102,4,0,
31,1,104,4,0,
31,1,115,4,0,
31,1,117,4,0,
31,1,126,4,0,
31,1,130,4,0,
31,1,156,4,0,
31,1,157,4,0,
31,1,164,4,0,
31,2,5,4,0,
31,2,6,4,0,
31,2,10,1,1,2
31,2,10,1,8,
31,2,24,1,12,
31,2,25,4,0,
31,2,32,4,0,
31,2,33,1,1,1
31,2,34,1,1,4
31,2,34,1,23,
31,2,34,4,0,
31,2,36,4,0,
31,2,38,4,0,
31,2,39,1,1,3
31,2,55,4,0,
31,2,57,4,0,
31,2,58,4,0,
31,2,59,4,0,
31,2,61,4,0,
31,2,63,4,0,
31,2,66,4,0,
31,2,68,4,0,
31,2,69,4,0,
31,2,70,4,0,
31,2,85,4,0,
31,2,87,4,0,
31,2,89,4,0,
31,2,90,4,0,
31,2,92,4,0,
31,2,99,4,0,
31,2,102,4,0,
31,2,104,4,0,
31,2,115,4,0,
31,2,117,4,0,
31,2,126,4,0,
31,2,130,4,0,
31,2,156,4,0,
31,2,157,4,0,
31,2,164,4,0,
31,3,7,4,0,
31,3,8,4,0,
31,3,9,4,0,
31,3,10,1,1,2
31,3,24,1,1,3
31,3,29,4,0,
31,3,33,1,1,1
31,3,34,1,23,
31,3,39,1,1,4
31,3,46,4,0,
31,3,57,4,0,
31,3,59,4,0,
31,3,63,4,0,
31,3,70,4,0,
31,3,87,4,0,
31,3,89,4,0,
31,3,92,4,0,
31,3,104,4,0,
31,3,111,4,0,
31,3,126,4,0,
31,3,156,4,0,
31,3,168,4,0,
31,3,173,4,0,
31,3,174,4,0,
31,3,182,4,0,
31,3,189,4,0,
31,3,196,4,0,
31,3,197,4,0,
31,3,201,4,0,
31,3,203,4,0,
31,3,207,4,0,
31,3,210,4,0,
31,3,213,4,0,
31,3,214,4,0,
31,3,216,4,0,
31,3,218,4,0,
31,3,223,4,0,
31,3,231,4,0,
31,3,237,4,0,
31,3,240,4,0,
31,3,241,4,0,
31,3,247,4,0,
31,3,249,4,0,
31,4,7,4,0,
31,4,8,4,0,
31,4,9,4,0,
31,4,10,1,1,2
31,4,24,1,1,3
31,4,29,4,0,
31,4,33,1,1,1
31,4,34,1,23,
31,4,39,1,1,4
31,4,46,4,0,
31,4,53,3,0,
31,4,57,4,0,
31,4,58,3,0,
31,4,59,4,0,
31,4,63,4,0,
31,4,70,4,0,
31,4,85,3,0,
31,4,87,4,0,
31,4,89,4,0,
31,4,92,4,0,
31,4,104,4,0,
31,4,111,4,0,
31,4,126,4,0,
31,4,156,4,0,
31,4,168,4,0,
31,4,173,4,0,
31,4,174,4,0,
31,4,182,4,0,
31,4,189,4,0,
31,4,196,4,0,
31,4,197,4,0,
31,4,201,4,0,
31,4,203,4,0,
31,4,207,4,0,
31,4,210,4,0,
31,4,213,4,0,
31,4,214,4,0,
31,4,216,4,0,
31,4,218,4,0,
31,4,223,4,0,
31,4,231,4,0,
31,4,237,4,0,
31,4,240,4,0,
31,4,241,4,0,
31,4,247,4,0,
31,4,249,4,0,
31,5,10,1,1,1
31,5,15,4,0,
31,5,24,1,1,3
31,5,34,1,23,
31,5,39,1,1,2
31,5,40,1,1,4
31,5,46,4,0,
31,5,53,4,0,
31,5,57,4,0,
31,5,58,4,0,
31,5,59,4,0,
31,5,63,4,0,
31,5,70,4,0,
31,5,85,4,0,
31,5,87,4,0,
31,5,89,4,0,
31,5,91,4,0,
31,5,92,4,0,
31,5,104,4,0,
31,5,126,4,0,
31,5,156,4,0,
31,5,168,4,0,
31,5,182,4,0,
31,5,188,4,0,
31,5,201,4,0,
31,5,213,4,0,
31,5,216,4,0,
31,5,218,4,0,
31,5,231,4,0,
31,5,237,4,0,
31,5,240,4,0,
31,5,241,4,0,
31,5,247,4,0,
31,5,249,4,0,
31,5,259,4,0,
31,5,263,4,0,
31,5,264,4,0,
31,5,269,4,0,
31,5,280,4,0,
31,5,290,4,0,
31,5,317,4,0,
31,5,332,4,0,
31,5,351,4,0,
31,5,352,4,0,
31,6,5,3,0,
31,6,7,3,0,
31,6,8,3,0,
31,6,9,3,0,
31,6,10,1,1,1
31,6,15,4,0,
31,6,24,1,1,3
31,6,25,3,0,
31,6,34,1,23,
31,6,34,3,0,
31,6,38,3,0,
31,6,39,1,1,2
31,6,40,1,1,4
31,6,46,4,0,
31,6,53,4,0,
31,6,57,4,0,
31,6,58,4,0,
31,6,59,4,0,
31,6,63,4,0,
31,6,68,3,0,
31,6,69,3,0,
31,6,70,4,0,
31,6,85,4,0,
31,6,87,4,0,
31,6,89,4,0,
31,6,91,4,0,
31,6,92,4,0,
31,6,102,3,0,
31,6,104,4,0,
31,6,111,3,0,
31,6,126,4,0,
31,6,156,4,0,
31,6,157,3,0,
31,6,164,3,0,
31,6,168,4,0,
31,6,173,3,0,
31,6,182,4,0,
31,6,188,4,0,
31,6,189,3,0,
31,6,196,3,0,
31,6,201,4,0,
31,6,203,3,0,
31,6,207,3,0,
31,6,210,3,0,
31,6,213,4,0,
31,6,214,3,0,
31,6,216,4,0,
31,6,218,4,0,
31,6,223,3,0,
31,6,231,4,0,
31,6,237,4,0,
31,6,240,4,0,
31,6,241,4,0,
31,6,247,4,0,
31,6,249,4,0,
31,6,259,4,0,
31,6,263,4,0,
31,6,264,4,0,
31,6,269,4,0,
31,6,280,4,0,
31,6,290,4,0,
31,6,317,4,0,
31,6,332,4,0,
31,6,351,4,0,
31,6,352,4,0,
31,7,5,3,0,
31,7,10,1,1,1
31,7,15,4,0,
31,7,24,1,1,3
31,7,25,3,0,
31,7,34,1,22,
31,7,34,3,0,
31,7,38,3,0,
31,7,39,1,1,2
31,7,40,1,1,4
31,7,46,4,0,
31,7,53,4,0,
31,7,57,4,0,
31,7,58,4,0,
31,7,59,4,0,
31,7,63,4,0,
31,7,68,3,0,
31,7,69,3,0,
31,7,70,4,0,
31,7,85,4,0,
31,7,87,4,0,
31,7,89,4,0,
31,7,91,4,0,
31,7,92,4,0,
31,7,102,3,0,
31,7,104,4,0,
31,7,126,4,0,
31,7,156,4,0,
31,7,157,3,0,
31,7,164,3,0,
31,7,168,4,0,
31,7,182,4,0,
31,7,188,4,0,
31,7,201,4,0,
31,7,213,4,0,
31,7,216,4,0,
31,7,218,4,0,
31,7,231,4,0,
31,7,237,4,0,
31,7,240,4,0,
31,7,241,4,0,
31,7,247,4,0,
31,7,249,4,0,
31,7,259,4,0,
31,7,263,4,0,
31,7,264,4,0,
31,7,269,4,0,
31,7,276,1,43,
31,7,280,4,0,
31,7,290,4,0,
31,7,317,4,0,
31,7,332,4,0,
31,7,351,4,0,
31,7,352,4,0,
31,8,10,1,1,1
31,8,15,4,0,
31,8,24,1,1,3
31,8,34,1,23,
31,8,39,1,1,2
31,8,40,1,1,4
31,8,46,4,0,
31,8,53,4,0,
31,8,57,4,0,
31,8,58,4,0,
31,8,59,4,0,
31,8,63,4,0,
31,8,70,4,0,
31,8,85,4,0,
31,8,87,4,0,
31,8,89,4,0,
31,8,91,4,0,
31,8,92,4,0,
31,8,104,4,0,
31,8,126,4,0,
31,8,156,4,0,
31,8,157,4,0,
31,8,164,4,0,
31,8,168,4,0,
31,8,182,4,0,
31,8,188,4,0,
31,8,201,4,0,
31,8,203,4,0,
31,8,207,4,0,
31,8,213,4,0,
31,8,214,4,0,
31,8,216,4,0,
31,8,218,4,0,
31,8,231,4,0,
31,8,237,4,0,
31,8,240,4,0,
31,8,241,4,0,
31,8,247,4,0,
31,8,249,4,0,
31,8,259,4,0,
31,8,263,4,0,
31,8,264,4,0,
31,8,269,4,0,
31,8,276,1,58,
31,8,280,4,0,
31,8,290,4,0,
31,8,317,4,0,
31,8,332,4,0,
31,8,351,4,0,
31,8,352,4,0,
31,8,363,4,0,
31,8,374,4,0,
31,8,398,4,0,
31,8,406,4,0,
31,8,411,4,0,
31,8,414,1,43,
31,8,416,4,0,
31,8,419,4,0,
31,8,421,4,0,
31,8,431,4,0,
31,8,444,4,0,
31,8,445,4,0,
31,8,446,4,0,
31,9,7,3,0,
31,9,8,3,0,
31,9,9,3,0,
31,9,10,1,1,1
31,9,15,4,0,
31,9,24,1,1,3
31,9,34,1,23,
31,9,39,1,1,2
31,9,40,1,1,4
31,9,46,4,0,
31,9,53,4,0,
31,9,57,4,0,
31,9,58,4,0,
31,9,59,4,0,
31,9,63,4,0,
31,9,70,4,0,
31,9,85,4,0,
31,9,87,4,0,
31,9,89,4,0,
31,9,91,4,0,
31,9,92,4,0,
31,9,104,4,0,
31,9,126,4,0,
31,9,156,4,0,
31,9,157,4,0,
31,9,164,4,0,
31,9,168,4,0,
31,9,173,3,0,
31,9,182,4,0,
31,9,188,4,0,
31,9,189,3,0,
31,9,196,3,0,
31,9,200,3,0,
31,9,201,4,0,
31,9,203,4,0,
31,9,207,4,0,
31,9,210,3,0,
31,9,213,4,0,
31,9,214,4,0,
31,9,216,4,0,
31,9,218,4,0,
31,9,231,4,0,
31,9,237,4,0,
31,9,240,4,0,
31,9,241,4,0,
31,9,247,4,0,
31,9,249,4,0,
31,9,253,3,0,
31,9,259,4,0,
31,9,263,4,0,
31,9,264,4,0,
31,9,269,4,0,
31,9,270,3,0,
31,9,276,1,58,
31,9,276,3,0,
31,9,280,4,0,
31,9,290,4,0,
31,9,317,4,0,
31,9,332,4,0,
31,9,351,4,0,
31,9,352,4,0,
31,9,363,4,0,
31,9,374,4,0,
31,9,398,4,0,
31,9,401,3,0,
31,9,406,4,0,
31,9,411,4,0,
31,9,414,1,43,
31,9,414,3,0,
31,9,416,4,0,
31,9,419,4,0,
31,9,421,4,0,
31,9,431,4,0,
31,9,444,4,0,
31,9,445,4,0,
31,9,446,4,0,
31,10,7,3,0,
31,10,8,3,0,
31,10,9,3,0,
31,10,10,1,1,1
31,10,15,4,0,
31,10,24,1,1,3
31,10,29,3,0,
31,10,34,1,23,
31,10,39,1,1,2
31,10,40,1,1,4
31,10,46,4,0,
31,10,53,4,0,
31,10,57,4,0,
31,10,58,4,0,
31,10,59,4,0,
31,10,63,4,0,
31,10,70,4,0,
31,10,85,4,0,
31,10,87,4,0,
31,10,89,4,0,
31,10,91,4,0,
31,10,92,4,0,
31,10,104,4,0,
31,10,126,4,0,
31,10,156,4,0,
31,10,157,4,0,
31,10,162,3,0,
31,10,164,4,0,
31,10,168,4,0,
31,10,173,3,0,
31,10,182,4,0,
31,10,188,4,0,
31,10,189,3,0,
31,10,196,3,0,
31,10,200,3,0,
31,10,201,4,0,
31,10,203,4,0,
31,10,207,4,0,
31,10,210,3,0,
31,10,213,4,0,
31,10,214,4,0,
31,10,216,4,0,
31,10,218,4,0,
31,10,231,4,0,
31,10,237,4,0,
31,10,240,4,0,
31,10,241,4,0,
31,10,247,4,0,
31,10,249,4,0,
31,10,250,4,0,
31,10,253,3,0,
31,10,259,4,0,
31,10,263,4,0,
31,10,264,4,0,
31,10,269,4,0,
31,10,270,3,0,
31,10,276,1,58,
31,10,276,3,0,
31,10,280,4,0,
31,10,290,4,0,
31,10,317,4,0,
31,10,332,4,0,
31,10,351,4,0,
31,10,352,4,0,
31,10,363,4,0,
31,10,374,4,0,
31,10,398,4,0,
31,10,401,3,0,
31,10,406,4,0,
31,10,411,4,0,
31,10,414,1,43,
31,10,414,3,0,
31,10,416,4,0,
31,10,419,4,0,
31,10,421,4,0,
31,10,431,4,0,
31,10,444,4,0,
31,10,445,4,0,
31,10,446,4,0,
31,11,10,1,1,1
31,11,15,4,0,
31,11,24,1,1,3
31,11,34,1,35,
31,11,39,1,1,2
31,11,40,1,1,4
31,11,46,4,0,
31,11,53,4,0,
31,11,57,4,0,
31,11,58,4,0,
31,11,59,4,0,
31,11,63,4,0,
31,11,70,4,0,
31,11,85,4,0,
31,11,87,4,0,
31,11,89,4,0,
31,11,91,4,0,
31,11,92,4,0,
31,11,104,4,0,
31,11,126,4,0,
31,11,156,4,0,
31,11,157,4,0,
31,11,164,4,0,
31,11,168,4,0,
31,11,182,4,0,
31,11,188,4,0,
31,11,201,4,0,
31,11,207,4,0,
31,11,213,4,0,
31,11,216,4,0,
31,11,218,4,0,
31,11,237,4,0,
31,11,240,4,0,
31,11,241,4,0,
31,11,247,4,0,
31,11,249,4,0,
31,11,259,4,0,
31,11,263,4,0,
31,11,269,4,0,
31,11,276,1,58,
31,11,280,4,0,
31,11,317,4,0,
31,11,332,4,0,
31,11,374,4,0,
31,11,398,4,0,
31,11,411,4,0,
31,11,414,1,43,
31,11,416,4,0,
31,11,421,4,0,
31,11,444,4,0,
31,11,468,4,0,
31,11,474,4,0,
31,11,479,4,0,
31,11,482,4,0,
31,11,496,4,0,
31,11,497,4,0,
31,11,498,1,23,
31,11,510,4,0,
31,11,511,4,0,
31,11,523,4,0,
31,11,525,4,0,
31,12,10,1,1,1
31,12,15,4,0,
31,12,24,1,1,3
31,12,34,1,23,
31,12,39,1,1,2
31,12,40,1,1,4
31,12,46,4,0,
31,12,53,4,0,
31,12,57,4,0,
31,12,58,4,0,
31,12,59,4,0,
31,12,63,4,0,
31,12,70,4,0,
31,12,85,4,0,
31,12,87,4,0,
31,12,89,4,0,
31,12,91,4,0,
31,12,92,4,0,
31,12,104,4,0,
31,12,126,4,0,
31,12,156,4,0,
31,12,168,4,0,
31,12,182,4,0,
31,12,188,4,0,
31,12,201,4,0,
31,12,213,4,0,
31,12,216,4,0,
31,12,218,4,0,
31,12,231,4,0,
31,12,237,4,0,
31,12,240,4,0,
31,12,241,4,0,
31,12,247,4,0,
31,12,249,4,0,
31,12,259,4,0,
31,12,263,4,0,
31,12,264,4,0,
31,12,269,4,0,
31,12,280,4,0,
31,12,290,4,0,
31,12,317,4,0,
31,12,332,4,0,
31,12,351,4,0,
31,12,352,4,0,
31,13,10,1,1,1
31,13,15,4,0,
31,13,24,1,1,3
31,13,34,1,23,
31,13,34,3,0,
31,13,38,3,0,
31,13,39,1,1,2
31,13,40,1,1,4
31,13,46,4,0,
31,13,53,4,0,
31,13,57,4,0,
31,13,58,4,0,
31,13,59,4,0,
31,13,63,4,0,
31,13,69,3,0,
31,13,70,4,0,
31,13,85,4,0,
31,13,87,4,0,
31,13,89,4,0,
31,13,91,4,0,
31,13,92,4,0,
31,13,102,3,0,
31,13,104,4,0,
31,13,126,4,0,
31,13,156,4,0,
31,13,164,3,0,
31,13,168,4,0,
31,13,182,4,0,
31,13,188,4,0,
31,13,196,3,0,
31,13,201,4,0,
31,13,207,3,0,
31,13,213,4,0,
31,13,216,4,0,
31,13,218,4,0,
31,13,231,4,0,
31,13,237,4,0,
31,13,240,4,0,
31,13,241,4,0,
31,13,247,4,0,
31,13,249,4,0,
31,13,259,4,0,
31,13,263,4,0,
31,13,264,4,0,
31,13,269,4,0,
31,13,280,4,0,
31,13,290,4,0,
31,13,317,4,0,
31,13,332,4,0,
31,13,351,4,0,
31,13,352,4,0,
31,14,7,3,0,
31,14,8,3,0,
31,14,9,3,0,
31,14,10,1,1,1
31,14,15,4,0,
31,14,24,1,1,3
31,14,34,1,35,
31,14,39,1,1,2
31,14,40,1,1,4
31,14,46,4,0,
31,14,53,4,0,
31,14,57,4,0,
31,14,58,4,0,
31,14,59,4,0,
31,14,63,4,0,
31,14,70,4,0,
31,14,85,4,0,
31,14,87,4,0,
31,14,89,4,0,
31,14,91,4,0,
31,14,92,4,0,
31,14,104,4,0,
31,14,126,4,0,
31,14,156,4,0,
31,14,157,4,0,
31,14,162,3,0,
31,14,164,4,0,
31,14,168,4,0,
31,14,173,3,0,
31,14,182,4,0,
31,14,188,4,0,
31,14,196,3,0,
31,14,200,3,0,
31,14,201,4,0,
31,14,207,4,0,
31,14,213,4,0,
31,14,214,3,0,
31,14,216,4,0,
31,14,218,4,0,
31,14,231,3,0,
31,14,237,4,0,
31,14,240,4,0,
31,14,241,4,0,
31,14,247,4,0,
31,14,249,4,0,
31,14,253,3,0,
31,14,259,4,0,
31,14,263,4,0,
31,14,269,4,0,
31,14,270,3,0,
31,14,276,1,58,
31,14,276,3,0,
31,14,280,4,0,
31,14,317,4,0,
31,14,332,4,0,
31,14,374,4,0,
31,14,398,4,0,
31,14,401,3,0,
31,14,406,3,0,
31,14,411,4,0,
31,14,414,1,43,
31,14,414,3,0,
31,14,416,4,0,
31,14,421,4,0,
31,14,444,4,0,
31,14,446,3,0,
31,14,468,4,0,
31,14,474,4,0,
31,14,479,4,0,
31,14,482,4,0,
31,14,496,4,0,
31,14,497,4,0,
31,14,498,1,23,
31,14,510,4,0,
31,14,511,4,0,
31,14,523,4,0,
31,14,525,4,0,
31,15,10,1,1,2
31,15,15,4,0,
31,15,24,1,1,4
31,15,34,1,35,
31,15,39,1,1,3
31,15,40,1,1,5
31,15,46,4,0,
31,15,53,4,0,
31,15,57,4,0,
31,15,58,4,0,
31,15,59,4,0,
31,15,63,4,0,
31,15,70,4,0,
31,15,85,4,0,
31,15,87,4,0,
31,15,89,4,0,
31,15,91,4,0,
31,15,92,4,0,
31,15,104,4,0,
31,15,126,4,0,
31,15,156,4,0,
31,15,157,4,0,
31,15,164,4,0,
31,15,168,4,0,
31,15,182,4,0,
31,15,188,4,0,
31,15,201,4,0,
31,15,207,4,0,
31,15,213,4,0,
31,15,214,4,0,
31,15,216,4,0,
31,15,218,4,0,
31,15,237,4,0,
31,15,240,4,0,
31,15,241,4,0,
31,15,247,4,0,
31,15,249,4,0,
31,15,259,4,0,
31,15,263,4,0,
31,15,269,4,0,
31,15,276,1,1,1
31,15,276,1,58,
31,15,280,4,0,
31,15,317,4,0,
31,15,332,4,0,
31,15,374,4,0,
31,15,398,4,0,
31,15,411,4,0,
31,15,414,1,43,
31,15,416,4,0,
31,15,421,4,0,
31,15,444,4,0,
31,15,468,4,0,
31,15,474,4,0,
31,15,479,4,0,
31,15,482,4,0,
31,15,496,4,0,
31,15,497,4,0,
31,15,498,1,23,
31,15,510,4,0,
31,15,511,4,0,
31,15,523,4,0,
31,15,525,4,0,
31,15,590,4,0,
31,15,612,4,0,
31,16,7,3,0,
31,16,8,3,0,
31,16,9,3,0,
31,16,10,1,1,2
31,16,15,4,0,
31,16,24,1,1,4
31,16,34,1,35,1
31,16,39,1,1,3
31,16,40,1,1,5
31,16,46,4,0,
31,16,53,4,0,
31,16,57,4,0,
31,16,58,4,0,
31,16,59,4,0,
31,16,63,4,0,
31,16,70,4,0,
31,16,85,4,0,
31,16,87,4,0,
31,16,89,4,0,
31,16,91,4,0,
31,16,92,4,0,
31,16,104,4,0,
31,16,126,4,0,
31,16,156,4,0,
31,16,157,4,0,
31,16,162,3,0,
31,16,164,4,0,
31,16,168,4,0,
31,16,173,3,0,
31,16,182,4,0,
31,16,188,4,0,
31,16,196,3,0,
31,16,200,3,0,
31,16,201,4,0,
31,16,207,4,0,
31,16,213,4,0,
31,16,214,4,0,
31,16,216,4,0,
31,16,218,4,0,
31,16,231,3,0,
31,16,237,4,0,
31,16,240,4,0,
31,16,241,4,0,
31,16,247,4,0,
31,16,249,4,0,
31,16,253,3,0,
31,16,259,4,0,
31,16,263,4,0,
31,16,264,3,0,
31,16,269,4,0,
31,16,270,3,0,
31,16,276,1,1,1
31,16,276,1,58,1
31,16,276,3,0,
31,16,280,4,0,
31,16,290,4,0,
31,16,317,4,0,
31,16,332,4,0,
31,16,351,3,0,
31,16,352,3,0,
31,16,374,4,0,
31,16,398,4,0,
31,16,401,3,0,
31,16,406,3,0,
31,16,411,4,0,
31,16,414,1,43,1
31,16,414,3,0,
31,16,416,4,0,
31,16,421,4,0,
31,16,444,4,0,
31,16,446,3,0,
31,16,468,4,0,
31,16,474,4,0,
31,16,479,4,0,
31,16,482,4,0,
31,16,496,4,0,
31,16,497,4,0,
31,16,498,1,23,1
31,16,510,4,0,
31,16,511,4,0,
31,16,523,4,0,
31,16,525,4,0,
31,16,529,3,0,
31,16,590,4,0,
31,16,612,4,0,
31,17,10,1,1,2
31,17,24,1,1,4
31,17,34,1,35,
31,17,39,1,1,3
31,17,40,1,1,5
31,17,46,4,0,
31,17,53,4,0,
31,17,57,4,0,
31,17,58,4,0,
31,17,59,4,0,
31,17,63,4,0,
31,17,85,4,0,
31,17,87,4,0,
31,17,89,4,0,
31,17,92,4,0,
31,17,104,4,0,
31,17,126,4,0,
31,17,156,4,0,
31,17,157,4,0,
31,17,164,4,0,
31,17,168,4,0,
31,17,182,4,0,
31,17,188,4,0,
31,17,201,4,0,
31,17,207,4,0,
31,17,213,4,0,
31,17,214,4,0,
31,17,216,4,0,
31,17,218,4,0,
31,17,237,4,0,
31,17,240,4,0,
31,17,241,4,0,
31,17,247,4,0,
31,17,259,4,0,
31,17,263,4,0,
31,17,269,4,0,
31,17,276,1,1,1
31,17,276,1,58,
31,17,280,4,0,
31,17,317,4,0,
31,17,332,4,0,
31,17,374,4,0,
31,17,398,4,0,
31,17,411,4,0,
31,17,414,1,43,
31,17,416,4,0,
31,17,421,4,0,
31,17,444,4,0,
31,17,474,4,0,
31,17,479,4,0,
31,17,482,4,0,
31,17,496,4,0,
31,17,497,4,0,
31,17,498,1,23,
31,17,511,4,0,
31,17,523,4,0,
31,17,525,4,0,
31,17,590,4,0,
31,18,10,1,1,2
31,18,24,1,1,4
31,18,34,1,35,
31,18,39,1,1,3
31,18,40,1,1,5
31,18,46,4,0,
31,18,53,4,0,
31,18,57,4,0,
31,18,58,4,0,
31,18,59,4,0,
31,18,63,4,0,
31,18,85,4,0,
31,18,87,4,0,
31,18,89,4,0,
31,18,92,4,0,
31,18,104,4,0,
31,18,126,4,0,
31,18,156,4,0,
31,18,157,4,0,
31,18,164,4,0,
31,18,168,4,0,
31,18,182,4,0,
31,18,188,4,0,
31,18,201,4,0,
31,18,207,4,0,
31,18,213,4,0,
31,18,214,4,0,
31,18,216,4,0,
31,18,218,4,0,
31,18,237,4,0,
31,18,240,4,0,
31,18,241,4,0,
31,18,247,4,0,
31,18,259,4,0,
31,18,263,4,0,
31,18,269,4,0,
31,18,276,1,1,1
31,18,276,1,58,
31,18,280,4,0,
31,18,317,4,0,
31,18,332,4,0,
31,18,374,4,0,
31,18,398,4,0,
31,18,411,4,0,
31,18,414,1,43,
31,18,416,4,0,
31,18,421,4,0,
31,18,444,4,0,
31,18,474,4,0,
31,18,479,4,0,
31,18,482,4,0,
31,18,496,4,0,
31,18,497,4,0,
31,18,498,1,23,
31,18,511,4,0,
31,18,523,4,0,
31,18,525,4,0,
31,18,590,4,0,
32,1,24,1,43,
32,1,30,1,8,
32,1,31,1,29,
32,1,32,1,36,
32,1,32,4,0,
32,1,33,1,1,2
32,1,34,4,0,
32,1,36,4,0,
32,1,38,4,0,
32,1,40,1,14,
32,1,43,1,1,1
32,1,59,4,0,
32,1,85,4,0,
32,1,87,4,0,
32,1,92,4,0,
32,1,99,4,0,
32,1,102,4,0,
32,1,104,4,0,
32,1,115,4,0,
32,1,116,1,21,
32,1,117,4,0,
32,1,130,4,0,
32,1,156,4,0,
32,1,164,4,0,
32,2,24,1,12,
32,2,30,1,8,
32,2,31,1,30,
32,2,32,1,38,
32,2,32,4,0,
32,2,33,1,1,2
32,2,34,4,0,
32,2,36,4,0,
32,2,38,4,0,
32,2,40,1,17,
32,2,43,1,1,1
32,2,59,4,0,
32,2,85,4,0,
32,2,87,4,0,
32,2,92,4,0,
32,2,99,4,0,
32,2,102,4,0,
32,2,104,4,0,
32,2,115,4,0,
32,2,116,1,23,
32,2,117,4,0,
32,2,130,4,0,
32,2,156,4,0,
32,2,164,4,0,
32,3,24,1,12,
32,3,29,4,0,
32,3,30,1,8,
32,3,31,1,30,
32,3,32,1,38,
32,3,33,1,1,2
32,3,36,2,0,
32,3,40,1,17,
32,3,43,1,1,1
32,3,48,2,0,
32,3,50,2,0,
32,3,59,4,0,
32,3,68,2,0,
32,3,87,4,0,
32,3,92,4,0,
32,3,93,2,0,
32,3,104,4,0,
32,3,111,4,0,
32,3,116,1,23,
32,3,133,2,0,
32,3,156,4,0,
32,3,168,4,0,
32,3,173,4,0,
32,3,174,4,0,
32,3,182,4,0,
32,3,189,4,0,
32,3,197,4,0,
32,3,203,4,0,
32,3,207,4,0,
32,3,213,4,0,
32,3,214,4,0,
32,3,216,4,0,
32,3,218,4,0,
32,3,231,4,0,
32,3,237,4,0,
32,3,240,4,0,
32,3,241,4,0,
32,3,251,2,0,
32,4,24,1,12,
32,4,29,4,0,
32,4,30,1,8,
32,4,31,1,30,
32,4,32,1,38,
32,4,33,1,1,2
32,4,36,2,0,
32,4,40,1,17,
32,4,43,1,1,1
32,4,48,2,0,
32,4,50,2,0,
32,4,59,4,0,
32,4,68,2,0,
32,4,85,3,0,
32,4,87,4,0,
32,4,92,4,0,
32,4,93,2,0,
32,4,104,4,0,
32,4,111,4,0,
32,4,116,1,23,
32,4,133,2,0,
32,4,156,4,0,
32,4,168,4,0,
32,4,173,4,0,
32,4,174,4,0,
32,4,182,4,0,
32,4,189,4,0,
32,4,197,4,0,
32,4,203,4,0,
32,4,207,4,0,
32,4,213,4,0,
32,4,214,4,0,
32,4,216,4,0,
32,4,218,4,0,
32,4,231,4,0,
32,4,237,4,0,
32,4,240,4,0,
32,4,241,4,0,
32,4,251,2,0,
32,5,15,4,0,
32,5,24,1,12,
32,5,30,1,20,
32,5,31,1,30,
32,5,32,1,47,
32,5,36,2,0,
32,5,40,1,17,
32,5,43,1,1,1
32,5,48,2,0,
32,5,50,2,0,
32,5,58,4,0,
32,5,59,4,0,
32,5,64,1,1,2
32,5,68,2,0,
32,5,70,4,0,
32,5,85,4,0,
32,5,87,4,0,
32,5,91,4,0,
32,5,92,4,0,
32,5,93,2,0,
32,5,104,4,0,
32,5,116,1,8,
32,5,133,2,0,
32,5,156,4,0,
32,5,168,4,0,
32,5,182,4,0,
32,5,188,4,0,
32,5,213,4,0,
32,5,216,4,0,
32,5,218,4,0,
32,5,231,4,0,
32,5,237,4,0,
32,5,240,4,0,
32,5,241,4,0,
32,5,249,4,0,
32,5,251,2,0,
32,5,260,1,38,
32,5,263,4,0,
32,5,270,1,23,
32,5,290,4,0,
32,5,351,4,0,
32,5,352,4,0,
32,6,15,4,0,
32,6,24,1,12,
32,6,30,1,20,
32,6,31,1,30,
32,6,32,1,47,
32,6,34,3,0,
32,6,36,2,0,
32,6,38,3,0,
32,6,40,1,17,
32,6,43,1,1,1
32,6,48,2,0,
32,6,50,2,0,
32,6,58,4,0,
32,6,59,4,0,
32,6,64,1,1,2
32,6,68,2,0,
32,6,68,3,0,
32,6,70,4,0,
32,6,85,4,0,
32,6,87,4,0,
32,6,91,4,0,
32,6,92,4,0,
32,6,93,2,0,
32,6,102,3,0,
32,6,104,4,0,
32,6,111,3,0,
32,6,116,1,8,
32,6,133,2,0,
32,6,156,4,0,
32,6,164,3,0,
32,6,168,4,0,
32,6,173,3,0,
32,6,182,4,0,
32,6,188,4,0,
32,6,189,3,0,
32,6,203,3,0,
32,6,207,3,0,
32,6,213,4,0,
32,6,214,3,0,
32,6,216,4,0,
32,6,218,4,0,
32,6,231,4,0,
32,6,237,4,0,
32,6,240,4,0,
32,6,241,4,0,
32,6,249,4,0,
32,6,251,2,0,
32,6,260,1,38,
32,6,263,4,0,
32,6,270,1,23,
32,6,290,4,0,
32,6,351,4,0,
32,6,352,4,0,
32,7,15,4,0,
32,7,24,1,12,
32,7,30,1,20,
32,7,31,1,30,
32,7,32,1,47,
32,7,34,3,0,
32,7,36,2,0,
32,7,38,3,0,
32,7,40,1,17,
32,7,43,1,1,1
32,7,48,2,0,
32,7,50,2,0,
32,7,58,4,0,
32,7,59,4,0,
32,7,64,1,1,2
32,7,68,2,0,
32,7,68,3,0,
32,7,70,4,0,
32,7,85,4,0,
32,7,87,4,0,
32,7,91,4,0,
32,7,92,4,0,
32,7,93,2,0,
32,7,102,3,0,
32,7,104,4,0,
32,7,116,1,8,
32,7,133,2,0,
32,7,156,4,0,
32,7,164,3,0,
32,7,168,4,0,
32,7,182,4,0,
32,7,188,4,0,
32,7,213,4,0,
32,7,216,4,0,
32,7,218,4,0,
32,7,231,4,0,
32,7,237,4,0,
32,7,240,4,0,
32,7,241,4,0,
32,7,249,4,0,
32,7,251,2,0,
32,7,260,1,38,
32,7,263,4,0,
32,7,270,1,23,
32,7,290,4,0,
32,7,351,4,0,
32,7,352,4,0,
32,8,15,4,0,
32,8,24,1,9,
32,8,30,1,21,
32,8,31,1,19,
32,8,32,1,45,
32,8,36,2,0,
32,8,40,1,13,
32,8,43,1,1,1
32,8,48,2,0,
32,8,50,2,0,
32,8,58,4,0,
32,8,59,4,0,
32,8,64,1,1,2
32,8,68,2,0,
32,8,70,4,0,
32,8,85,4,0,
32,8,87,4,0,
32,8,91,4,0,
32,8,92,4,0,
32,8,93,2,0,
32,8,104,4,0,
32,8,116,1,7,
32,8,133,2,0,
32,8,156,4,0,
32,8,164,4,0,
32,8,168,4,0,
32,8,182,4,0,
32,8,188,4,0,
32,8,203,4,0,
32,8,207,4,0,
32,8,213,4,0,
32,8,214,4,0,
32,8,216,4,0,
32,8,218,4,0,
32,8,231,4,0,
32,8,237,4,0,
32,8,240,4,0,
32,8,241,4,0,
32,8,249,4,0,
32,8,251,2,0,
32,8,260,1,33,
32,8,263,4,0,
32,8,270,1,25,
32,8,290,4,0,
32,8,351,4,0,
32,8,352,4,0,
32,8,363,4,0,
32,8,389,2,0,
32,8,390,1,31,
32,8,398,1,37,
32,8,398,4,0,
32,8,421,4,0,
32,8,445,1,43,
32,8,445,4,0,
32,9,15,4,0,
32,9,24,1,9,
32,9,30,1,21,
32,9,31,1,19,
32,9,32,1,45,
32,9,36,2,0,
32,9,40,1,13,
32,9,43,1,1,1
32,9,48,2,0,
32,9,50,2,0,
32,9,58,4,0,
32,9,59,4,0,
32,9,64,1,1,2
32,9,68,2,0,
32,9,70,4,0,
32,9,85,4,0,
32,9,87,4,0,
32,9,91,4,0,
32,9,92,4,0,
32,9,93,2,0,
32,9,104,4,0,
32,9,116,1,7,
32,9,133,2,0,
32,9,156,4,0,
32,9,164,4,0,
32,9,168,4,0,
32,9,173,3,0,
32,9,182,4,0,
32,9,188,4,0,
32,9,189,3,0,
32,9,203,4,0,
32,9,207,4,0,
32,9,213,4,0,
32,9,214,4,0,
32,9,216,4,0,
32,9,218,4,0,
32,9,231,4,0,
32,9,237,4,0,
32,9,240,4,0,
32,9,241,4,0,
32,9,249,4,0,
32,9,251,2,0,
32,9,260,1,33,
32,9,263,4,0,
32,9,270,1,25,
32,9,270,3,0,
32,9,290,4,0,
32,9,351,4,0,
32,9,352,4,0,
32,9,363,4,0,
32,9,389,2,0,
32,9,389,3,0,
32,9,390,1,31,
32,9,398,1,37,
32,9,398,4,0,
32,9,421,4,0,
32,9,445,1,43,
32,9,445,4,0,
32,10,15,4,0,
32,10,24,1,9,
32,10,29,3,0,
32,10,30,1,21,
32,10,31,1,19,
32,10,32,1,45,
32,10,36,2,0,
32,10,40,1,13,
32,10,43,1,1,1
32,10,48,2,0,
32,10,50,2,0,
32,10,58,4,0,
32,10,59,4,0,
32,10,64,1,1,2
32,10,68,2,0,
32,10,70,4,0,
32,10,85,4,0,
32,10,87,4,0,
32,10,91,4,0,
32,10,92,4,0,
32,10,93,2,0,
32,10,104,4,0,
32,10,116,1,7,
32,10,133,2,0,
32,10,156,4,0,
32,10,162,3,0,
32,10,164,4,0,
32,10,168,4,0,
32,10,173,3,0,
32,10,182,4,0,
32,10,188,4,0,
32,10,189,3,0,
32,10,203,4,0,
32,10,207,4,0,
32,10,213,4,0,
32,10,214,4,0,
32,10,216,4,0,
32,10,218,4,0,
32,10,231,4,0,
32,10,237,4,0,
32,10,240,4,0,
32,10,241,4,0,
32,10,249,4,0,
32,10,251,2,0,
32,10,260,1,33,
32,10,263,4,0,
32,10,270,1,25,
32,10,270,3,0,
32,10,290,4,0,
32,10,351,4,0,
32,10,352,4,0,
32,10,363,4,0,
32,10,389,2,0,
32,10,389,3,0,
32,10,390,1,31,
32,10,398,1,37,
32,10,398,4,0,
32,10,421,4,0,
32,10,445,1,43,
32,10,445,4,0,
32,10,457,2,0,
32,11,15,4,0,
32,11,24,1,9,
32,11,30,1,21,
32,11,31,1,19,
32,11,32,1,45,
32,11,36,2,0,
32,11,40,1,13,
32,11,43,1,1,1
32,11,48,2,0,
32,11,50,2,0,
32,11,58,4,0,
32,11,59,4,0,
32,11,64,1,1,2
32,11,68,2,0,
32,11,70,4,0,
32,11,85,4,0,
32,11,87,4,0,
32,11,91,4,0,
32,11,92,4,0,
32,11,93,2,0,
32,11,104,4,0,
32,11,116,1,7,
32,11,133,2,0,
32,11,156,4,0,
32,11,164,4,0,
32,11,168,4,0,
32,11,182,4,0,
32,11,188,4,0,
32,11,203,2,0,
32,11,207,4,0,
32,11,213,4,0,
32,11,216,4,0,
32,11,218,4,0,
32,11,231,2,0,
32,11,237,4,0,
32,11,240,4,0,
32,11,241,4,0,
32,11,249,4,0,
32,11,251,2,0,
32,11,260,1,33,
32,11,263,4,0,
32,11,270,1,25,
32,11,342,2,0,
32,11,389,2,0,
32,11,390,1,31,
32,11,398,1,37,
32,11,398,4,0,
32,11,421,4,0,
32,11,445,1,43,
32,11,457,2,0,
32,11,468,4,0,
32,11,474,4,0,
32,11,496,4,0,
32,11,497,4,0,
32,11,498,2,0,
32,12,15,4,0,
32,12,24,1,12,
32,12,30,1,20,
32,12,31,1,30,
32,12,32,1,47,
32,12,40,1,17,
32,12,43,1,1,1
32,12,58,4,0,
32,12,59,4,0,
32,12,64,1,1,2
32,12,70,4,0,
32,12,85,4,0,
32,12,87,4,0,
32,12,91,4,0,
32,12,92,4,0,
32,12,104,4,0,
32,12,116,1,8,
32,12,156,4,0,
32,12,168,4,0,
32,12,182,4,0,
32,12,188,4,0,
32,12,213,4,0,
32,12,216,4,0,
32,12,218,4,0,
32,12,231,4,0,
32,12,237,4,0,
32,12,240,4,0,
32,12,241,4,0,
32,12,249,4,0,
32,12,260,1,38,
32,12,263,4,0,
32,12,270,1,23,
32,12,290,4,0,
32,12,351,4,0,
32,12,352,4,0,
32,13,15,4,0,
32,13,24,1,12,
32,13,30,1,20,
32,13,31,1,30,
32,13,32,1,47,
32,13,34,3,0,
32,13,38,3,0,
32,13,40,1,17,
32,13,43,1,1,1
32,13,58,4,0,
32,13,59,4,0,
32,13,64,1,1,2
32,13,70,4,0,
32,13,85,4,0,
32,13,87,4,0,
32,13,91,4,0,
32,13,92,4,0,
32,13,102,3,0,
32,13,104,4,0,
32,13,116,1,8,
32,13,156,4,0,
32,13,164,3,0,
32,13,168,4,0,
32,13,182,4,0,
32,13,188,4,0,
32,13,207,3,0,
32,13,213,4,0,
32,13,216,4,0,
32,13,218,4,0,
32,13,231,4,0,
32,13,237,4,0,
32,13,240,4,0,
32,13,241,4,0,
32,13,249,4,0,
32,13,260,1,38,
32,13,263,4,0,
32,13,270,1,23,
32,13,290,4,0,
32,13,351,4,0,
32,13,352,4,0,
32,14,15,4,0,
32,14,24,1,9,
32,14,30,1,21,
32,14,31,1,19,
32,14,32,1,45,
32,14,36,2,0,
32,14,40,1,13,
32,14,43,1,1,1
32,14,48,2,0,
32,14,50,2,0,
32,14,58,4,0,
32,14,59,4,0,
32,14,64,1,1,2
32,14,68,2,0,
32,14,70,4,0,
32,14,85,4,0,
32,14,87,4,0,
32,14,91,4,0,
32,14,92,4,0,
32,14,93,2,0,
32,14,104,4,0,
32,14,116,1,7,
32,14,133,2,0,
32,14,156,4,0,
32,14,162,3,0,
32,14,164,4,0,
32,14,168,4,0,
32,14,173,3,0,
32,14,182,4,0,
32,14,188,4,0,
32,14,203,2,0,
32,14,207,4,0,
32,14,213,4,0,
32,14,214,3,0,
32,14,216,4,0,
32,14,218,4,0,
32,14,231,2,0,
32,14,231,3,0,
32,14,237,4,0,
32,14,240,4,0,
32,14,241,4,0,
32,14,249,4,0,
32,14,251,2,0,
32,14,260,1,33,
32,14,263,4,0,
32,14,270,1,25,
32,14,270,3,0,
32,14,342,2,0,
32,14,389,2,0,
32,14,390,1,31,
32,14,398,1,37,
32,14,398,4,0,
32,14,421,4,0,
32,14,445,1,43,
32,14,457,2,0,
32,14,468,4,0,
32,14,474,4,0,
32,14,496,4,0,
32,14,497,4,0,
32,14,498,2,0,
32,14,529,3,0,
32,15,15,4,0,
32,15,24,1,9,
32,15,30,1,21,
32,15,31,1,19,
32,15,32,1,45,
32,15,36,2,0,
32,15,40,1,13,
32,15,43,1,1,1
32,15,48,2,0,
32,15,50,2,0,
32,15,58,4,0,
32,15,59,4,0,
32,15,64,1,1,2
32,15,68,2,0,
32,15,70,4,0,
32,15,85,4,0,
32,15,87,4,0,
32,15,91,4,0,
32,15,92,4,0,
32,15,93,2,0,
32,15,104,4,0,
32,15,116,1,7,
32,15,133,2,0,
32,15,156,4,0,
32,15,164,4,0,
32,15,168,4,0,
32,15,182,4,0,
32,15,188,4,0,
32,15,203,2,0,
32,15,207,4,0,
32,15,213,4,0,
32,15,214,4,0,
32,15,216,4,0,
32,15,218,4,0,
32,15,231,2,0,
32,15,237,4,0,
32,15,240,4,0,
32,15,241,4,0,
32,15,249,4,0,
32,15,251,2,0,
32,15,260,1,33,
32,15,263,4,0,
32,15,270,1,25,
32,15,342,2,0,
32,15,389,2,0,
32,15,390,1,31,
32,15,398,1,37,
32,15,398,4,0,
32,15,421,4,0,
32,15,445,1,43,
32,15,457,2,0,
32,15,468,4,0,
32,15,474,4,0,
32,15,496,4,0,
32,15,497,4,0,
32,15,498,2,0,
32,15,590,4,0,
32,15,599,2,0,
32,16,15,4,0,
32,16,24,1,9,1
32,16,30,1,21,1
32,16,31,1,19,1
32,16,32,1,45,1
32,16,36,2,0,
32,16,40,1,13,1
32,16,43,1,1,1
32,16,48,2,0,
32,16,50,2,0,
32,16,58,4,0,
32,16,59,4,0,
32,16,64,1,1,2
32,16,68,2,0,
32,16,70,4,0,
32,16,85,4,0,
32,16,87,4,0,
32,16,91,4,0,
32,16,92,4,0,
32,16,93,2,0,
32,16,104,4,0,
32,16,116,1,7,1
32,16,133,2,0,
32,16,156,4,0,
32,16,162,3,0,
32,16,164,4,0,
32,16,168,4,0,
32,16,173,3,0,
32,16,182,4,0,
32,16,188,4,0,
32,16,203,2,0,
32,16,207,4,0,
32,16,213,4,0,
32,16,214,4,0,
32,16,216,4,0,
32,16,218,4,0,
32,16,231,2,0,
32,16,231,3,0,
32,16,237,4,0,
32,16,240,4,0,
32,16,241,4,0,
32,16,249,4,0,
32,16,251,2,0,
32,16,260,1,33,1
32,16,263,4,0,
32,16,270,1,25,1
32,16,270,3,0,
32,16,290,4,0,
32,16,342,2,0,
32,16,351,3,0,
32,16,352,3,0,
32,16,389,2,0,
32,16,390,1,31,1
32,16,398,1,37,1
32,16,398,4,0,
32,16,421,4,0,
32,16,445,1,43,1
32,16,457,2,0,
32,16,468,4,0,
32,16,474,4,0,
32,16,496,4,0,
32,16,497,4,0,
32,16,498,2,0,
32,16,529,3,0,
32,16,590,4,0,
32,16,599,2,0,
32,17,24,1,9,
32,17,30,1,21,
32,17,31,1,19,
32,17,32,1,45,
32,17,36,2,0,
32,17,40,1,13,
32,17,43,1,1,1
32,17,48,2,0,
32,17,50,2,0,
32,17,58,4,0,
32,17,59,4,0,
32,17,64,1,1,2
32,17,68,2,0,
32,17,85,4,0,
32,17,87,4,0,
32,17,92,4,0,
32,17,93,2,0,
32,17,104,4,0,
32,17,116,1,7,
32,17,133,2,0,
32,17,156,4,0,
32,17,164,4,0,
32,17,168,4,0,
32,17,182,4,0,
32,17,188,4,0,
32,17,203,2,0,
32,17,207,4,0,
32,17,213,4,0,
32,17,214,4,0,
32,17,216,4,0,
32,17,218,4,0,
32,17,231,2,0,
32,17,237,4,0,
32,17,240,4,0,
32,17,241,4,0,
32,17,251,2,0,
32,17,260,1,33,
32,17,263,4,0,
32,17,270,1,25,
32,17,342,2,0,
32,17,389,2,0,
32,17,390,1,31,
32,17,398,1,37,
32,17,398,4,0,
32,17,421,4,0,
32,17,445,1,43,
32,17,457,2,0,
32,17,474,4,0,
32,17,496,4,0,
32,17,497,4,0,
32,17,498,2,0,
32,17,590,4,0,
32,17,599,2,0,
32,17,684,4,0,
32,18,24,1,9,
32,18,30,1,21,
32,18,31,1,19,
32,18,32,1,45,
32,18,36,2,0,
32,18,40,1,13,
32,18,43,1,1,1
32,18,48,2,0,
32,18,50,2,0,
32,18,58,4,0,
32,18,59,4,0,
32,18,64,1,1,2
32,18,68,2,0,
32,18,85,4,0,
32,18,87,4,0,
32,18,92,4,0,
32,18,93,2,0,
32,18,104,4,0,
32,18,116,1,7,
32,18,133,2,0,
32,18,156,4,0,
32,18,164,4,0,
32,18,168,4,0,
32,18,182,4,0,
32,18,188,4,0,
32,18,203,2,0,
32,18,207,4,0,
32,18,213,4,0,
32,18,214,4,0,
32,18,216,4,0,
32,18,218,4,0,
32,18,231,2,0,
32,18,237,4,0,
32,18,240,4,0,
32,18,241,4,0,
32,18,251,2,0,
32,18,260,1,33,
32,18,263,4,0,
32,18,270,1,25,
32,18,342,2,0,
32,18,389,2,0,
32,18,390,1,31,
32,18,398,1,37,
32,18,398,4,0,
32,18,421,4,0,
32,18,445,1,43,
32,18,457,2,0,
32,18,474,4,0,
32,18,496,4,0,
32,18,497,4,0,
32,18,498,2,0,
32,18,590,4,0,
32,18,599,2,0,
32,18,684,4,0,
33,1,24,1,50,
33,1,30,1,1,3
33,1,30,1,8,
33,1,31,1,32,
33,1,32,1,41,
33,1,32,4,0,
33,1,33,1,1,2
33,1,34,4,0,
33,1,36,4,0,
33,1,38,4,0,
33,1,40,1,14,
33,1,43,1,1,1
33,1,55,4,0,
33,1,58,4,0,
33,1,59,4,0,
33,1,61,4,0,
33,1,85,4,0,
33,1,87,4,0,
33,1,92,4,0,
33,1,99,4,0,
33,1,102,4,0,
33,1,104,4,0,
33,1,115,4,0,
33,1,116,1,23,
33,1,117,4,0,
33,1,130,4,0,
33,1,156,4,0,
33,1,164,4,0,
33,2,24,1,12,
33,2,30,1,1,3
33,2,30,1,8,
33,2,31,1,36,
33,2,32,1,46,
33,2,32,4,0,
33,2,33,1,1,2
33,2,34,4,0,
33,2,36,4,0,
33,2,38,4,0,
33,2,40,1,19,
33,2,43,1,1,1
33,2,55,4,0,
33,2,58,4,0,
33,2,59,4,0,
33,2,61,4,0,
33,2,85,4,0,
33,2,87,4,0,
33,2,92,4,0,
33,2,99,4,0,
33,2,102,4,0,
33,2,104,4,0,
33,2,115,4,0,
33,2,116,1,27,
33,2,117,4,0,
33,2,130,4,0,
33,2,156,4,0,
33,2,164,4,0,
33,3,24,1,12,
33,3,29,4,0,
33,3,30,1,8,
33,3,31,1,36,
33,3,32,1,46,
33,3,33,1,1,2
33,3,40,1,19,
33,3,43,1,1,1
33,3,59,4,0,
33,3,70,4,0,
33,3,87,4,0,
33,3,92,4,0,
33,3,104,4,0,
33,3,111,4,0,
33,3,116,1,27,
33,3,156,4,0,
33,3,168,4,0,
33,3,173,4,0,
33,3,174,4,0,
33,3,182,4,0,
33,3,189,4,0,
33,3,197,4,0,
33,3,203,4,0,
33,3,207,4,0,
33,3,213,4,0,
33,3,214,4,0,
33,3,216,4,0,
33,3,218,4,0,
33,3,231,4,0,
33,3,237,4,0,
33,3,240,4,0,
33,3,241,4,0,
33,3,249,4,0,
33,4,24,1,12,
33,4,29,4,0,
33,4,30,1,8,
33,4,31,1,36,
33,4,32,1,46,
33,4,33,1,1,2
33,4,40,1,19,
33,4,43,1,1,1
33,4,58,3,0,
33,4,59,4,0,
33,4,70,4,0,
33,4,85,3,0,
33,4,87,4,0,
33,4,92,4,0,
33,4,104,4,0,
33,4,111,4,0,
33,4,116,1,27,
33,4,156,4,0,
33,4,168,4,0,
33,4,173,4,0,
33,4,174,4,0,
33,4,182,4,0,
33,4,189,4,0,
33,4,197,4,0,
33,4,203,4,0,
33,4,207,4,0,
33,4,213,4,0,
33,4,214,4,0,
33,4,216,4,0,
33,4,218,4,0,
33,4,231,4,0,
33,4,237,4,0,
33,4,240,4,0,
33,4,241,4,0,
33,4,249,4,0,
33,5,15,4,0,
33,5,24,1,12,
33,5,30,1,22,
33,5,31,1,34,
33,5,32,1,53,
33,5,40,1,18,
33,5,43,1,1,1
33,5,58,4,0,
33,5,59,4,0,
33,5,64,1,1,2
33,5,70,4,0,
33,5,85,4,0,
33,5,87,4,0,
33,5,91,4,0,
33,5,92,4,0,
33,5,104,4,0,
33,5,116,1,8,
33,5,156,4,0,
33,5,168,4,0,
33,5,182,4,0,
33,5,188,4,0,
33,5,213,4,0,
33,5,216,4,0,
33,5,218,4,0,
33,5,231,4,0,
33,5,237,4,0,
33,5,240,4,0,
33,5,241,4,0,
33,5,249,4,0,
33,5,260,1,43,
33,5,263,4,0,
33,5,270,1,26,
33,5,290,4,0,
33,5,351,4,0,
33,5,352,4,0,
33,6,15,4,0,
33,6,24,1,12,
33,6,30,1,22,
33,6,31,1,34,
33,6,32,1,53,
33,6,34,3,0,
33,6,38,3,0,
33,6,40,1,18,
33,6,43,1,1,1
33,6,58,4,0,
33,6,59,4,0,
33,6,64,1,1,2
33,6,68,3,0,
33,6,70,4,0,
33,6,85,4,0,
33,6,87,4,0,
33,6,91,4,0,
33,6,92,4,0,
33,6,102,3,0,
33,6,104,4,0,
33,6,111,3,0,
33,6,116,1,8,
33,6,156,4,0,
33,6,164,3,0,
33,6,168,4,0,
33,6,173,3,0,
33,6,182,4,0,
33,6,188,4,0,
33,6,189,3,0,
33,6,203,3,0,
33,6,207,3,0,
33,6,213,4,0,
33,6,214,3,0,
33,6,216,4,0,
33,6,218,4,0,
33,6,231,4,0,
33,6,237,4,0,
33,6,240,4,0,
33,6,241,4,0,
33,6,249,4,0,
33,6,260,1,43,
33,6,263,4,0,
33,6,270,1,26,
33,6,290,4,0,
33,6,351,4,0,
33,6,352,4,0,
33,7,15,4,0,
33,7,24,1,12,
33,7,30,1,22,
33,7,31,1,34,
33,7,32,1,53,
33,7,34,3,0,
33,7,38,3,0,
33,7,40,1,18,
33,7,43,1,1,1
33,7,58,4,0,
33,7,59,4,0,
33,7,64,1,1,2
33,7,68,3,0,
33,7,70,4,0,
33,7,85,4,0,
33,7,87,4,0,
33,7,91,4,0,
33,7,92,4,0,
33,7,102,3,0,
33,7,104,4,0,
33,7,116,1,8,
33,7,156,4,0,
33,7,164,3,0,
33,7,168,4,0,
33,7,182,4,0,
33,7,188,4,0,
33,7,213,4,0,
33,7,216,4,0,
33,7,218,4,0,
33,7,231,4,0,
33,7,237,4,0,
33,7,240,4,0,
33,7,241,4,0,
33,7,249,4,0,
33,7,260,1,43,
33,7,263,4,0,
33,7,270,1,26,
33,7,290,4,0,
33,7,351,4,0,
33,7,352,4,0,
33,8,15,4,0,
33,8,24,1,9,
33,8,30,1,23,
33,8,31,1,20,
33,8,32,1,58,
33,8,40,1,13,
33,8,43,1,1,1
33,8,58,4,0,
33,8,59,4,0,
33,8,64,1,1,2
33,8,70,4,0,
33,8,85,4,0,
33,8,87,4,0,
33,8,91,4,0,
33,8,92,4,0,
33,8,104,4,0,
33,8,116,1,7,
33,8,156,4,0,
33,8,164,4,0,
33,8,168,4,0,
33,8,182,4,0,
33,8,188,4,0,
33,8,203,4,0,
33,8,207,4,0,
33,8,213,4,0,
33,8,214,4,0,
33,8,216,4,0,
33,8,218,4,0,
33,8,231,4,0,
33,8,237,4,0,
33,8,240,4,0,
33,8,241,4,0,
33,8,249,4,0,
33,8,260,1,38,
33,8,263,4,0,
33,8,270,1,28,
33,8,290,4,0,
33,8,351,4,0,
33,8,352,4,0,
33,8,363,4,0,
33,8,390,1,35,
33,8,398,1,43,
33,8,398,4,0,
33,8,421,4,0,
33,8,445,1,50,
33,8,445,4,0,
33,9,15,4,0,
33,9,24,1,9,
33,9,30,1,23,
33,9,31,1,20,
33,9,32,1,58,
33,9,40,1,13,
33,9,43,1,1,1
33,9,58,4,0,
33,9,59,4,0,
33,9,64,1,1,2
33,9,70,4,0,
33,9,85,4,0,
33,9,87,4,0,
33,9,91,4,0,
33,9,92,4,0,
33,9,104,4,0,
33,9,116,1,7,
33,9,156,4,0,
33,9,164,4,0,
33,9,168,4,0,
33,9,173,3,0,
33,9,182,4,0,
33,9,188,4,0,
33,9,189,3,0,
33,9,203,4,0,
33,9,207,4,0,
33,9,213,4,0,
33,9,214,4,0,
33,9,216,4,0,
33,9,218,4,0,
33,9,231,4,0,
33,9,237,4,0,
33,9,240,4,0,
33,9,241,4,0,
33,9,249,4,0,
33,9,260,1,38,
33,9,263,4,0,
33,9,270,1,28,
33,9,270,3,0,
33,9,290,4,0,
33,9,351,4,0,
33,9,352,4,0,
33,9,363,4,0,
33,9,389,3,0,
33,9,390,1,35,
33,9,398,1,43,
33,9,398,4,0,
33,9,421,4,0,
33,9,445,1,50,
33,9,445,4,0,
33,10,15,4,0,
33,10,24,1,9,
33,10,29,3,0,
33,10,30,1,23,
33,10,31,1,20,
33,10,32,1,58,
33,10,40,1,13,
33,10,43,1,1,1
33,10,58,4,0,
33,10,59,4,0,
33,10,64,1,1,2
33,10,70,4,0,
33,10,85,4,0,
33,10,87,4,0,
33,10,91,4,0,
33,10,92,4,0,
33,10,104,4,0,
33,10,116,1,7,
33,10,156,4,0,
33,10,162,3,0,
33,10,164,4,0,
33,10,168,4,0,
33,10,173,3,0,
33,10,182,4,0,
33,10,188,4,0,
33,10,189,3,0,
33,10,203,4,0,
33,10,207,4,0,
33,10,213,4,0,
33,10,214,4,0,
33,10,216,4,0,
33,10,218,4,0,
33,10,231,4,0,
33,10,237,4,0,
33,10,240,4,0,
33,10,241,4,0,
33,10,249,4,0,
33,10,260,1,38,
33,10,263,4,0,
33,10,270,1,28,
33,10,270,3,0,
33,10,290,4,0,
33,10,351,4,0,
33,10,352,4,0,
33,10,363,4,0,
33,10,389,3,0,
33,10,390,1,35,
33,10,398,1,43,
33,10,398,4,0,
33,10,421,4,0,
33,10,445,1,50,
33,10,445,4,0,
33,11,15,4,0,
33,11,24,1,9,
33,11,30,1,23,
33,11,31,1,20,
33,11,32,1,58,
33,11,40,1,13,
33,11,43,1,1,1
33,11,58,4,0,
33,11,59,4,0,
33,11,64,1,1,2
33,11,70,4,0,
33,11,85,4,0,
33,11,87,4,0,
33,11,91,4,0,
33,11,92,4,0,
33,11,104,4,0,
33,11,116,1,7,
33,11,156,4,0,
33,11,164,4,0,
33,11,168,4,0,
33,11,182,4,0,
33,11,188,4,0,
33,11,207,4,0,
33,11,213,4,0,
33,11,216,4,0,
33,11,218,4,0,
33,11,237,4,0,
33,11,240,4,0,
33,11,241,4,0,
33,11,249,4,0,
33,11,260,1,38,
33,11,263,4,0,
33,11,270,1,28,
33,11,390,1,35,
33,11,398,1,43,
33,11,398,4,0,
33,11,421,4,0,
33,11,445,1,50,
33,11,468,4,0,
33,11,474,4,0,
33,11,496,4,0,
33,11,497,4,0,
33,12,15,4,0,
33,12,24,1,12,
33,12,30,1,22,
33,12,31,1,34,
33,12,32,1,53,
33,12,40,1,18,
33,12,43,1,1,1
33,12,58,4,0,
33,12,59,4,0,
33,12,64,1,1,2
33,12,70,4,0,
33,12,85,4,0,
33,12,87,4,0,
33,12,91,4,0,
33,12,92,4,0,
33,12,104,4,0,
33,12,116,1,8,
33,12,156,4,0,
33,12,168,4,0,
33,12,182,4,0,
33,12,188,4,0,
33,12,213,4,0,
33,12,216,4,0,
33,12,218,4,0,
33,12,231,4,0,
33,12,237,4,0,
33,12,240,4,0,
33,12,241,4,0,
33,12,249,4,0,
33,12,260,1,43,
33,12,263,4,0,
33,12,270,1,26,
33,12,290,4,0,
33,12,351,4,0,
33,12,352,4,0,
33,13,15,4,0,
33,13,24,1,12,
33,13,30,1,22,
33,13,31,1,34,
33,13,32,1,53,
33,13,34,3,0,
33,13,38,3,0,
33,13,40,1,18,
33,13,43,1,1,1
33,13,58,4,0,
33,13,59,4,0,
33,13,64,1,1,2
33,13,70,4,0,
33,13,85,4,0,
33,13,87,4,0,
33,13,91,4,0,
33,13,92,4,0,
33,13,102,3,0,
33,13,104,4,0,
33,13,116,1,8,
33,13,156,4,0,
33,13,164,3,0,
33,13,168,4,0,
33,13,182,4,0,
33,13,188,4,0,
33,13,207,3,0,
33,13,213,4,0,
33,13,216,4,0,
33,13,218,4,0,
33,13,231,4,0,
33,13,237,4,0,
33,13,240,4,0,
33,13,241,4,0,
33,13,249,4,0,
33,13,260,1,43,
33,13,263,4,0,
33,13,270,1,26,
33,13,290,4,0,
33,13,351,4,0,
33,13,352,4,0,
33,14,15,4,0,
33,14,24,1,9,
33,14,30,1,23,
33,14,31,1,20,
33,14,32,1,58,
33,14,40,1,13,
33,14,43,1,1,1
33,14,58,4,0,
33,14,59,4,0,
33,14,64,1,1,2
33,14,70,4,0,
33,14,85,4,0,
33,14,87,4,0,
33,14,91,4,0,
33,14,92,4,0,
33,14,104,4,0,
33,14,116,1,7,
33,14,156,4,0,
33,14,162,3,0,
33,14,164,4,0,
33,14,168,4,0,
33,14,173,3,0,
33,14,182,4,0,
33,14,188,4,0,
33,14,207,4,0,
33,14,213,4,0,
33,14,214,3,0,
33,14,216,4,0,
33,14,218,4,0,
33,14,231,3,0,
33,14,237,4,0,
33,14,240,4,0,
33,14,241,4,0,
33,14,249,4,0,
33,14,260,1,38,
33,14,263,4,0,
33,14,270,1,28,
33,14,270,3,0,
33,14,390,1,35,
33,14,398,1,43,
33,14,398,4,0,
33,14,421,4,0,
33,14,445,1,50,
33,14,468,4,0,
33,14,474,4,0,
33,14,496,4,0,
33,14,497,4,0,
33,14,529,3,0,
33,15,15,4,0,
33,15,24,1,9,
33,15,30,1,23,
33,15,31,1,20,
33,15,32,1,58,
33,15,40,1,13,
33,15,43,1,1,1
33,15,58,4,0,
33,15,59,4,0,
33,15,64,1,1,2
33,15,70,4,0,
33,15,85,4,0,
33,15,87,4,0,
33,15,91,4,0,
33,15,92,4,0,
33,15,104,4,0,
33,15,116,1,7,
33,15,156,4,0,
33,15,164,4,0,
33,15,168,4,0,
33,15,182,4,0,
33,15,188,4,0,
33,15,207,4,0,
33,15,213,4,0,
33,15,214,4,0,
33,15,216,4,0,
33,15,218,4,0,
33,15,237,4,0,
33,15,240,4,0,
33,15,241,4,0,
33,15,249,4,0,
33,15,260,1,38,
33,15,263,4,0,
33,15,270,1,28,
33,15,390,1,35,
33,15,398,1,43,
33,15,398,4,0,
33,15,421,4,0,
33,15,445,1,50,
33,15,468,4,0,
33,15,474,4,0,
33,15,496,4,0,
33,15,497,4,0,
33,15,590,4,0,
33,16,15,4,0,
33,16,24,1,9,1
33,16,30,1,23,1
33,16,31,1,20,1
33,16,32,1,58,1
33,16,40,1,13,1
33,16,43,1,1,1
33,16,58,4,0,
33,16,59,4,0,
33,16,64,1,1,2
33,16,70,4,0,
33,16,85,4,0,
33,16,87,4,0,
33,16,91,4,0,
33,16,92,4,0,
33,16,104,4,0,
33,16,116,1,7,1
33,16,156,4,0,
33,16,162,3,0,
33,16,164,4,0,
33,16,168,4,0,
33,16,173,3,0,
33,16,182,4,0,
33,16,188,4,0,
33,16,207,4,0,
33,16,213,4,0,
33,16,214,4,0,
33,16,216,4,0,
33,16,218,4,0,
33,16,231,3,0,
33,16,237,4,0,
33,16,240,4,0,
33,16,241,4,0,
33,16,249,4,0,
33,16,260,1,38,1
33,16,263,4,0,
33,16,270,1,28,1
33,16,270,3,0,
33,16,290,4,0,
33,16,351,3,0,
33,16,352,3,0,
33,16,390,1,35,1
33,16,398,1,43,1
33,16,398,4,0,
33,16,421,4,0,
33,16,445,1,50,1
33,16,468,4,0,
33,16,474,4,0,
33,16,496,4,0,
33,16,497,4,0,
33,16,529,3,0,
33,16,590,4,0,
33,17,24,1,9,
33,17,30,1,23,
33,17,31,1,20,
33,17,32,1,58,
33,17,40,1,13,
33,17,43,1,1,1
33,17,58,4,0,
33,17,59,4,0,
33,17,64,1,1,2
33,17,85,4,0,
33,17,87,4,0,
33,17,92,4,0,
33,17,104,4,0,
33,17,116,1,7,
33,17,156,4,0,
33,17,164,4,0,
33,17,168,4,0,
33,17,182,4,0,
33,17,188,4,0,
33,17,207,4,0,
33,17,213,4,0,
33,17,214,4,0,
33,17,216,4,0,
33,17,218,4,0,
33,17,237,4,0,
33,17,240,4,0,
33,17,241,4,0,
33,17,260,1,38,
33,17,263,4,0,
33,17,270,1,28,
33,17,390,1,35,
33,17,398,1,43,
33,17,398,4,0,
33,17,421,4,0,
33,17,445,1,50,
33,17,474,4,0,
33,17,496,4,0,
33,17,497,4,0,
33,17,590,4,0,
33,17,684,4,0,
33,18,24,1,9,
33,18,30,1,23,
33,18,31,1,20,
33,18,32,1,58,
33,18,40,1,13,
33,18,43,1,1,1
33,18,58,4,0,
33,18,59,4,0,
33,18,64,1,1,2
33,18,85,4,0,
33,18,87,4,0,
33,18,92,4,0,
33,18,104,4,0,
33,18,116,1,7,
33,18,156,4,0,
33,18,164,4,0,
33,18,168,4,0,
33,18,182,4,0,
33,18,188,4,0,
33,18,207,4,0,
33,18,213,4,0,
33,18,214,4,0,
33,18,216,4,0,
33,18,218,4,0,
33,18,237,4,0,
33,18,240,4,0,
33,18,241,4,0,
33,18,260,1,38,
33,18,263,4,0,
33,18,270,1,28,
33,18,390,1,35,
33,18,398,1,43,
33,18,398,4,0,
33,18,421,4,0,
33,18,445,1,50,
33,18,474,4,0,
33,18,496,4,0,
33,18,497,4,0,
33,18,590,4,0,
33,18,684,4,0,
34,1,5,4,0,
34,1,6,4,0,
34,1,25,4,0,
34,1,30,1,1,2
34,1,30,1,8,
34,1,32,4,0,
34,1,33,1,1,1
34,1,34,4,0,
34,1,36,4,0,
34,1,37,1,1,4
34,1,37,1,23,
34,1,38,4,0,
34,1,40,1,1,3
34,1,40,1,14,
34,1,55,4,0,
34,1,57,4,0,
34,1,58,4,0,
34,1,59,4,0,
34,1,61,4,0,
34,1,63,4,0,
34,1,66,4,0,
34,1,68,4,0,
34,1,69,4,0,
34,1,70,4,0,
34,1,85,4,0,
34,1,87,4,0,
34,1,89,4,0,
34,1,90,4,0,
34,1,92,4,0,
34,1,99,4,0,
34,1,102,4,0,
34,1,104,4,0,
34,1,115,4,0,
34,1,117,4,0,
34,1,126,4,0,
34,1,130,4,0,
34,1,156,4,0,
34,1,157,4,0,
34,1,164,4,0,
34,2,5,4,0,
34,2,6,4,0,
34,2,24,1,12,
34,2,25,4,0,
34,2,30,1,1,2
34,2,30,1,8,
34,2,32,4,0,
34,2,33,1,1,1
34,2,34,4,0,
34,2,36,4,0,
34,2,37,1,1,4
34,2,37,1,23,
34,2,38,4,0,
34,2,40,1,1,3
34,2,55,4,0,
34,2,57,4,0,
34,2,58,4,0,
34,2,59,4,0,
34,2,61,4,0,
34,2,63,4,0,
34,2,66,4,0,
34,2,68,4,0,
34,2,69,4,0,
34,2,70,4,0,
34,2,85,4,0,
34,2,87,4,0,
34,2,89,4,0,
34,2,90,4,0,
34,2,92,4,0,
34,2,99,4,0,
34,2,102,4,0,
34,2,104,4,0,
34,2,115,4,0,
34,2,117,4,0,
34,2,126,4,0,
34,2,130,4,0,
34,2,156,4,0,
34,2,157,4,0,
34,2,164,4,0,
34,3,7,4,0,
34,3,8,4,0,
34,3,9,4,0,
34,3,24,1,1,3
34,3,29,4,0,
34,3,30,1,1,2
34,3,33,1,1,1
34,3,37,1,23,
34,3,40,1,1,4
34,3,46,4,0,
34,3,57,4,0,
34,3,59,4,0,
34,3,63,4,0,
34,3,70,4,0,
34,3,87,4,0,
34,3,89,4,0,
34,3,92,4,0,
34,3,104,4,0,
34,3,111,4,0,
34,3,126,4,0,
34,3,156,4,0,
34,3,168,4,0,
34,3,173,4,0,
34,3,174,4,0,
34,3,182,4,0,
34,3,189,4,0,
34,3,196,4,0,
34,3,197,4,0,
34,3,201,4,0,
34,3,203,4,0,
34,3,207,4,0,
34,3,210,4,0,
34,3,213,4,0,
34,3,214,4,0,
34,3,216,4,0,
34,3,218,4,0,
34,3,223,4,0,
34,3,231,4,0,
34,3,237,4,0,
34,3,240,4,0,
34,3,241,4,0,
34,3,247,4,0,
34,3,249,4,0,
34,4,7,4,0,
34,4,8,4,0,
34,4,9,4,0,
34,4,24,1,1,3
34,4,29,4,0,
34,4,30,1,1,2
34,4,33,1,1,1
34,4,37,1,23,
34,4,40,1,1,4
34,4,46,4,0,
34,4,53,3,0,
34,4,57,4,0,
34,4,58,3,0,
34,4,59,4,0,
34,4,63,4,0,
34,4,70,4,0,
34,4,85,3,0,
34,4,87,4,0,
34,4,89,4,0,
34,4,92,4,0,
34,4,104,4,0,
34,4,111,4,0,
34,4,126,4,0,
34,4,156,4,0,
34,4,168,4,0,
34,4,173,4,0,
34,4,174,4,0,
34,4,182,4,0,
34,4,189,4,0,
34,4,196,4,0,
34,4,197,4,0,
34,4,201,4,0,
34,4,203,4,0,
34,4,207,4,0,
34,4,210,4,0,
34,4,213,4,0,
34,4,214,4,0,
34,4,216,4,0,
34,4,218,4,0,
34,4,223,4,0,
34,4,231,4,0,
34,4,237,4,0,
34,4,240,4,0,
34,4,241,4,0,
34,4,247,4,0,
34,4,249,4,0,
34,5,15,4,0,
34,5,24,1,1,3
34,5,37,1,23,
34,5,40,1,1,4
34,5,46,4,0,
34,5,53,4,0,
34,5,57,4,0,
34,5,58,4,0,
34,5,59,4,0,
34,5,63,4,0,
34,5,64,1,1,1
34,5,70,4,0,
34,5,85,4,0,
34,5,87,4,0,
34,5,89,4,0,
34,5,91,4,0,
34,5,92,4,0,
34,5,104,4,0,
34,5,116,1,1,2
34,5,126,4,0,
34,5,156,4,0,
34,5,168,4,0,
34,5,182,4,0,
34,5,188,4,0,
34,5,201,4,0,
34,5,213,4,0,
34,5,216,4,0,
34,5,218,4,0,
34,5,231,4,0,
34,5,237,4,0,
34,5,240,4,0,
34,5,241,4,0,
34,5,247,4,0,
34,5,249,4,0,
34,5,259,4,0,
34,5,263,4,0,
34,5,264,4,0,
34,5,269,4,0,
34,5,280,4,0,
34,5,290,4,0,
34,5,317,4,0,
34,5,351,4,0,
34,5,352,4,0,
34,6,5,3,0,
34,6,7,3,0,
34,6,8,3,0,
34,6,9,3,0,
34,6,15,4,0,
34,6,24,1,1,3
34,6,25,3,0,
34,6,34,3,0,
34,6,37,1,23,
34,6,38,3,0,
34,6,40,1,1,4
34,6,46,4,0,
34,6,53,4,0,
34,6,57,4,0,
34,6,58,4,0,
34,6,59,4,0,
34,6,63,4,0,
34,6,64,1,1,1
34,6,68,3,0,
34,6,69,3,0,
34,6,70,4,0,
34,6,85,4,0,
34,6,87,4,0,
34,6,89,4,0,
34,6,91,4,0,
34,6,92,4,0,
34,6,102,3,0,
34,6,104,4,0,
34,6,111,3,0,
34,6,116,1,1,2
34,6,126,4,0,
34,6,156,4,0,
34,6,157,3,0,
34,6,164,3,0,
34,6,168,4,0,
34,6,173,3,0,
34,6,182,4,0,
34,6,188,4,0,
34,6,189,3,0,
34,6,196,3,0,
34,6,201,4,0,
34,6,203,3,0,
34,6,207,3,0,
34,6,210,3,0,
34,6,213,4,0,
34,6,214,3,0,
34,6,216,4,0,
34,6,218,4,0,
34,6,223,3,0,
34,6,231,4,0,
34,6,237,4,0,
34,6,240,4,0,
34,6,241,4,0,
34,6,247,4,0,
34,6,249,4,0,
34,6,259,4,0,
34,6,263,4,0,
34,6,264,4,0,
34,6,269,4,0,
34,6,280,4,0,
34,6,290,4,0,
34,6,317,4,0,
34,6,351,4,0,
34,6,352,4,0,
34,7,5,3,0,
34,7,15,4,0,
34,7,24,1,1,3
34,7,25,3,0,
34,7,34,3,0,
34,7,37,1,22,
34,7,38,3,0,
34,7,40,1,1,4
34,7,46,4,0,
34,7,53,4,0,
34,7,57,4,0,
34,7,58,4,0,
34,7,59,4,0,
34,7,63,4,0,
34,7,64,1,1,1
34,7,68,3,0,
34,7,69,3,0,
34,7,70,4,0,
34,7,85,4,0,
34,7,87,4,0,
34,7,89,4,0,
34,7,91,4,0,
34,7,92,4,0,
34,7,102,3,0,
34,7,104,4,0,
34,7,116,1,1,2
34,7,126,4,0,
34,7,156,4,0,
34,7,157,3,0,
34,7,164,3,0,
34,7,168,4,0,
34,7,182,4,0,
34,7,188,4,0,
34,7,201,4,0,
34,7,213,4,0,
34,7,216,4,0,
34,7,218,4,0,
34,7,224,1,43,
34,7,231,4,0,
34,7,237,4,0,
34,7,240,4,0,
34,7,241,4,0,
34,7,247,4,0,
34,7,249,4,0,
34,7,259,4,0,
34,7,263,4,0,
34,7,264,4,0,
34,7,269,4,0,
34,7,280,4,0,
34,7,290,4,0,
34,7,317,4,0,
34,7,351,4,0,
34,7,352,4,0,
34,8,15,4,0,
34,8,24,1,1,3
34,8,37,1,23,
34,8,40,1,1,4
34,8,46,4,0,
34,8,53,4,0,
34,8,57,4,0,
34,8,58,4,0,
34,8,59,4,0,
34,8,63,4,0,
34,8,64,1,1,1
34,8,70,4,0,
34,8,85,4,0,
34,8,87,4,0,
34,8,89,4,0,
34,8,91,4,0,
34,8,92,4,0,
34,8,104,4,0,
34,8,116,1,1,2
34,8,126,4,0,
34,8,156,4,0,
34,8,157,4,0,
34,8,164,4,0,
34,8,168,4,0,
34,8,182,4,0,
34,8,188,4,0,
34,8,201,4,0,
34,8,203,4,0,
34,8,207,4,0,
34,8,213,4,0,
34,8,214,4,0,
34,8,216,4,0,
34,8,218,4,0,
34,8,224,1,58,
34,8,231,4,0,
34,8,237,4,0,
34,8,240,4,0,
34,8,241,4,0,
34,8,247,4,0,
34,8,249,4,0,
34,8,259,4,0,
34,8,263,4,0,
34,8,264,4,0,
34,8,269,4,0,
34,8,280,4,0,
34,8,290,4,0,
34,8,317,4,0,
34,8,351,4,0,
34,8,352,4,0,
34,8,363,4,0,
34,8,374,4,0,
34,8,398,4,0,
34,8,406,4,0,
34,8,411,4,0,
34,8,414,1,43,
34,8,416,4,0,
34,8,419,4,0,
34,8,421,4,0,
34,8,431,4,0,
34,8,444,4,0,
34,8,445,4,0,
34,8,446,4,0,
34,9,7,3,0,
34,9,8,3,0,
34,9,9,3,0,
34,9,15,4,0,
34,9,24,1,1,3
34,9,37,1,23,
34,9,40,1,1,4
34,9,46,4,0,
34,9,53,4,0,
34,9,57,4,0,
34,9,58,4,0,
34,9,59,4,0,
34,9,63,4,0,
34,9,64,1,1,1
34,9,70,4,0,
34,9,85,4,0,
34,9,87,4,0,
34,9,89,4,0,
34,9,91,4,0,
34,9,92,4,0,
34,9,104,4,0,
34,9,116,1,1,2
34,9,126,4,0,
34,9,156,4,0,
34,9,157,4,0,
34,9,164,4,0,
34,9,168,4,0,
34,9,173,3,0,
34,9,182,4,0,
34,9,188,4,0,
34,9,189,3,0,
34,9,196,3,0,
34,9,200,3,0,
34,9,201,4,0,
34,9,203,4,0,
34,9,207,4,0,
34,9,210,3,0,
34,9,213,4,0,
34,9,214,4,0,
34,9,216,4,0,
34,9,218,4,0,
34,9,224,1,58,
34,9,231,4,0,
34,9,237,4,0,
34,9,240,4,0,
34,9,241,4,0,
34,9,247,4,0,
34,9,249,4,0,
34,9,253,3,0,
34,9,259,4,0,
34,9,263,4,0,
34,9,264,4,0,
34,9,269,4,0,
34,9,270,3,0,
34,9,276,3,0,
34,9,280,4,0,
34,9,290,4,0,
34,9,317,4,0,
34,9,351,4,0,
34,9,352,4,0,
34,9,363,4,0,
34,9,374,4,0,
34,9,389,3,0,
34,9,398,4,0,
34,9,401,3,0,
34,9,406,4,0,
34,9,411,4,0,
34,9,414,1,43,
34,9,414,3,0,
34,9,416,4,0,
34,9,419,4,0,
34,9,421,4,0,
34,9,431,4,0,
34,9,444,4,0,
34,9,445,4,0,
34,9,446,4,0,
34,10,7,3,0,
34,10,8,3,0,
34,10,9,3,0,
34,10,15,4,0,
34,10,24,1,1,3
34,10,29,3,0,
34,10,37,1,23,
34,10,40,1,1,4
34,10,46,4,0,
34,10,53,4,0,
34,10,57,4,0,
34,10,58,4,0,
34,10,59,4,0,
34,10,63,4,0,
34,10,64,1,1,1
34,10,70,4,0,
34,10,85,4,0,
34,10,87,4,0,
34,10,89,4,0,
34,10,91,4,0,
34,10,92,4,0,
34,10,104,4,0,
34,10,116,1,1,2
34,10,126,4,0,
34,10,156,4,0,
34,10,157,4,0,
34,10,162,3,0,
34,10,164,4,0,
34,10,168,4,0,
34,10,173,3,0,
34,10,182,4,0,
34,10,188,4,0,
34,10,189,3,0,
34,10,196,3,0,
34,10,200,3,0,
34,10,201,4,0,
34,10,203,4,0,
34,10,207,4,0,
34,10,210,3,0,
34,10,213,4,0,
34,10,214,4,0,
34,10,216,4,0,
34,10,218,4,0,
34,10,224,1,58,
34,10,231,4,0,
34,10,237,4,0,
34,10,240,4,0,
34,10,241,4,0,
34,10,247,4,0,
34,10,249,4,0,
34,10,250,4,0,
34,10,253,3,0,
34,10,259,4,0,
34,10,263,4,0,
34,10,264,4,0,
34,10,269,4,0,
34,10,270,3,0,
34,10,276,3,0,
34,10,280,4,0,
34,10,290,4,0,
34,10,317,4,0,
34,10,351,4,0,
34,10,352,4,0,
34,10,363,4,0,
34,10,374,4,0,
34,10,389,3,0,
34,10,398,4,0,
34,10,401,3,0,
34,10,406,4,0,
34,10,411,4,0,
34,10,414,1,43,
34,10,414,3,0,
34,10,416,4,0,
34,10,419,4,0,
34,10,421,4,0,
34,10,431,4,0,
34,10,444,4,0,
34,10,445,4,0,
34,10,446,4,0,
34,11,15,4,0,
34,11,24,1,1,3
34,11,37,1,35,
34,11,40,1,1,4
34,11,46,4,0,
34,11,53,4,0,
34,11,57,4,0,
34,11,58,4,0,
34,11,59,4,0,
34,11,63,4,0,
34,11,64,1,1,1
34,11,70,4,0,
34,11,85,4,0,
34,11,87,4,0,
34,11,89,4,0,
34,11,91,4,0,
34,11,92,4,0,
34,11,104,4,0,
34,11,116,1,1,2
34,11,126,4,0,
34,11,156,4,0,
34,11,157,4,0,
34,11,164,4,0,
34,11,168,4,0,
34,11,182,4,0,
34,11,188,4,0,
34,11,201,4,0,
34,11,207,4,0,
34,11,213,4,0,
34,11,216,4,0,
34,11,218,4,0,
34,11,224,1,58,
34,11,237,4,0,
34,11,240,4,0,
34,11,241,4,0,
34,11,247,4,0,
34,11,249,4,0,
34,11,259,4,0,
34,11,263,4,0,
34,11,269,4,0,
34,11,280,4,0,
34,11,317,4,0,
34,11,374,4,0,
34,11,398,4,0,
34,11,411,4,0,
34,11,414,1,43,
34,11,416,4,0,
34,11,421,4,0,
34,11,444,4,0,
34,11,468,4,0,
34,11,474,4,0,
34,11,479,4,0,
34,11,482,4,0,
34,11,496,4,0,
34,11,497,4,0,
34,11,498,1,23,
34,11,510,4,0,
34,11,511,4,0,
34,11,523,4,0,
34,11,525,4,0,
34,12,15,4,0,
34,12,24,1,1,3
34,12,37,1,23,
34,12,40,1,1,4
34,12,46,4,0,
34,12,53,4,0,
34,12,57,4,0,
34,12,58,4,0,
34,12,59,4,0,
34,12,63,4,0,
34,12,64,1,1,1
34,12,70,4,0,
34,12,85,4,0,
34,12,87,4,0,
34,12,89,4,0,
34,12,91,4,0,
34,12,92,4,0,
34,12,104,4,0,
34,12,116,1,1,2
34,12,126,4,0,
34,12,156,4,0,
34,12,168,4,0,
34,12,182,4,0,
34,12,188,4,0,
34,12,201,4,0,
34,12,213,4,0,
34,12,216,4,0,
34,12,218,4,0,
34,12,231,4,0,
34,12,237,4,0,
34,12,240,4,0,
34,12,241,4,0,
34,12,247,4,0,
34,12,249,4,0,
34,12,259,4,0,
34,12,263,4,0,
34,12,264,4,0,
34,12,269,4,0,
34,12,280,4,0,
34,12,290,4,0,
34,12,317,4,0,
34,12,351,4,0,
34,12,352,4,0,
34,13,15,4,0,
34,13,24,1,1,3
34,13,34,3,0,
34,13,37,1,23,
34,13,38,3,0,
34,13,40,1,1,4
34,13,46,4,0,
34,13,53,4,0,
34,13,57,4,0,
34,13,58,4,0,
34,13,59,4,0,
34,13,63,4,0,
34,13,64,1,1,1
34,13,69,3,0,
34,13,70,4,0,
34,13,85,4,0,
34,13,87,4,0,
34,13,89,4,0,
34,13,91,4,0,
34,13,92,4,0,
34,13,102,3,0,
34,13,104,4,0,
34,13,116,1,1,2
34,13,126,4,0,
34,13,156,4,0,
34,13,164,3,0,
34,13,168,4,0,
34,13,182,4,0,
34,13,188,4,0,
34,13,196,3,0,
34,13,201,4,0,
34,13,207,3,0,
34,13,213,4,0,
34,13,216,4,0,
34,13,218,4,0,
34,13,231,4,0,
34,13,237,4,0,
34,13,240,4,0,
34,13,241,4,0,
34,13,247,4,0,
34,13,249,4,0,
34,13,259,4,0,
34,13,263,4,0,
34,13,264,4,0,
34,13,269,4,0,
34,13,280,4,0,
34,13,290,4,0,
34,13,317,4,0,
34,13,351,4,0,
34,13,352,4,0,
34,14,7,3,0,
34,14,8,3,0,
34,14,9,3,0,
34,14,15,4,0,
34,14,24,1,1,3
34,14,37,1,35,
34,14,40,1,1,4
34,14,46,4,0,
34,14,53,4,0,
34,14,57,4,0,
34,14,58,4,0,
34,14,59,4,0,
34,14,63,4,0,
34,14,64,1,1,1
34,14,70,4,0,
34,14,85,4,0,
34,14,87,4,0,
34,14,89,4,0,
34,14,91,4,0,
34,14,92,4,0,
34,14,104,4,0,
34,14,116,1,1,2
34,14,126,4,0,
34,14,156,4,0,
34,14,157,4,0,
34,14,162,3,0,
34,14,164,4,0,
34,14,168,4,0,
34,14,173,3,0,
34,14,182,4,0,
34,14,188,4,0,
34,14,196,3,0,
34,14,200,3,0,
34,14,201,4,0,
34,14,207,4,0,
34,14,213,4,0,
34,14,214,3,0,
34,14,216,4,0,
34,14,218,4,0,
34,14,224,1,58,
34,14,231,3,0,
34,14,237,4,0,
34,14,240,4,0,
34,14,241,4,0,
34,14,247,4,0,
34,14,249,4,0,
34,14,253,3,0,
34,14,259,4,0,
34,14,263,4,0,
34,14,269,4,0,
34,14,270,3,0,
34,14,276,3,0,
34,14,280,4,0,
34,14,317,4,0,
34,14,374,4,0,
34,14,398,4,0,
34,14,401,3,0,
34,14,406,3,0,
34,14,411,4,0,
34,14,414,1,43,
34,14,414,3,0,
34,14,416,4,0,
34,14,421,4,0,
34,14,444,4,0,
34,14,446,3,0,
34,14,468,4,0,
34,14,474,4,0,
34,14,479,4,0,
34,14,482,4,0,
34,14,496,4,0,
34,14,497,4,0,
34,14,498,1,23,
34,14,510,4,0,
34,14,511,4,0,
34,14,523,4,0,
34,14,525,4,0,
34,14,529,3,0,
34,15,15,4,0,
34,15,24,1,1,4
34,15,37,1,35,
34,15,40,1,1,5
34,15,46,4,0,
34,15,53,4,0,
34,15,57,4,0,
34,15,58,4,0,
34,15,59,4,0,
34,15,63,4,0,
34,15,64,1,1,2
34,15,70,4,0,
34,15,85,4,0,
34,15,87,4,0,
34,15,89,4,0,
34,15,91,4,0,
34,15,92,4,0,
34,15,104,4,0,
34,15,116,1,1,3
34,15,126,4,0,
34,15,156,4,0,
34,15,157,4,0,
34,15,164,4,0,
34,15,168,4,0,
34,15,182,4,0,
34,15,188,4,0,
34,15,201,4,0,
34,15,207,4,0,
34,15,213,4,0,
34,15,214,4,0,
34,15,216,4,0,
34,15,218,4,0,
34,15,224,1,1,1
34,15,224,1,58,
34,15,237,4,0,
34,15,240,4,0,
34,15,241,4,0,
34,15,247,4,0,
34,15,249,4,0,
34,15,259,4,0,
34,15,263,4,0,
34,15,269,4,0,
34,15,280,4,0,
34,15,317,4,0,
34,15,374,4,0,
34,15,398,4,0,
34,15,411,4,0,
34,15,414,1,43,
34,15,416,4,0,
34,15,421,4,0,
34,15,444,4,0,
34,15,468,4,0,
34,15,474,4,0,
34,15,479,4,0,
34,15,482,4,0,
34,15,496,4,0,
34,15,497,4,0,
34,15,498,1,23,
34,15,510,4,0,
34,15,511,4,0,
34,15,523,4,0,
34,15,525,4,0,
34,15,590,4,0,
34,15,612,4,0,
34,16,7,3,0,
34,16,8,3,0,
34,16,9,3,0,
34,16,15,4,0,
34,16,24,1,1,4
34,16,37,1,35,1
34,16,40,1,1,5
34,16,46,4,0,
34,16,53,4,0,
34,16,57,4,0,
34,16,58,4,0,
34,16,59,4,0,
34,16,63,4,0,
34,16,64,1,1,2
34,16,70,4,0,
34,16,85,4,0,
34,16,87,4,0,
34,16,89,4,0,
34,16,91,4,0,
34,16,92,4,0,
34,16,104,4,0,
34,16,116,1,1,3
34,16,126,4,0,
34,16,156,4,0,
34,16,157,4,0,
34,16,162,3,0,
34,16,164,4,0,
34,16,168,4,0,
34,16,173,3,0,
34,16,182,4,0,
34,16,188,4,0,
34,16,196,3,0,
34,16,200,3,0,
34,16,201,4,0,
34,16,207,4,0,
34,16,213,4,0,
34,16,214,4,0,
34,16,216,4,0,
34,16,218,4,0,
34,16,224,1,1,1
34,16,224,1,58,1
34,16,231,3,0,
34,16,237,4,0,
34,16,240,4,0,
34,16,241,4,0,
34,16,247,4,0,
34,16,249,4,0,
34,16,253,3,0,
34,16,259,4,0,
34,16,263,4,0,
34,16,264,3,0,
34,16,269,4,0,
34,16,270,3,0,
34,16,276,3,0,
34,16,280,4,0,
34,16,290,4,0,
34,16,317,4,0,
34,16,351,3,0,
34,16,352,3,0,
34,16,374,4,0,
34,16,398,4,0,
34,16,401,3,0,
34,16,406,3,0,
34,16,411,4,0,
34,16,414,1,43,1
34,16,414,3,0,
34,16,416,4,0,
34,16,421,4,0,
34,16,444,4,0,
34,16,446,3,0,
34,16,468,4,0,
34,16,474,4,0,
34,16,479,4,0,
34,16,482,4,0,
34,16,496,4,0,
34,16,497,4,0,
34,16,498,1,23,1
34,16,510,4,0,
34,16,511,4,0,
34,16,523,4,0,
34,16,525,4,0,
34,16,529,3,0,
34,16,590,4,0,
34,16,612,4,0,
34,17,24,1,1,4
34,17,37,1,35,
34,17,40,1,1,5
34,17,46,4,0,
34,17,53,4,0,
34,17,57,4,0,
34,17,58,4,0,
34,17,59,4,0,
34,17,63,4,0,
34,17,64,1,1,2
34,17,85,4,0,
34,17,87,4,0,
34,17,89,4,0,
34,17,92,4,0,
34,17,104,4,0,
34,17,116,1,1,3
34,17,126,4,0,
34,17,156,4,0,
34,17,157,4,0,
34,17,164,4,0,
34,17,168,4,0,
34,17,182,4,0,
34,17,188,4,0,
34,17,201,4,0,
34,17,207,4,0,
34,17,213,4,0,
34,17,214,4,0,
34,17,216,4,0,
34,17,218,4,0,
34,17,224,1,1,1
34,17,224,1,58,
34,17,237,4,0,
34,17,240,4,0,
34,17,241,4,0,
34,17,247,4,0,
34,17,259,4,0,
34,17,263,4,0,
34,17,269,4,0,
34,17,280,4,0,
34,17,317,4,0,
34,17,374,4,0,
34,17,398,4,0,
34,17,411,4,0,
34,17,414,1,43,
34,17,416,4,0,
34,17,421,4,0,
34,17,444,4,0,
34,17,474,4,0,
34,17,479,4,0,
34,17,482,4,0,
34,17,496,4,0,
34,17,497,4,0,
34,17,498,1,23,
34,17,511,4,0,
34,17,523,4,0,
34,17,525,4,0,
34,17,590,4,0,
34,17,684,4,0,
34,18,24,1,1,4
34,18,37,1,35,
34,18,40,1,1,5
34,18,46,4,0,
34,18,53,4,0,
34,18,57,4,0,
34,18,58,4,0,
34,18,59,4,0,
34,18,63,4,0,
34,18,64,1,1,2
34,18,85,4,0,
34,18,87,4,0,
34,18,89,4,0,
34,18,92,4,0,
34,18,104,4,0,
34,18,116,1,1,3
34,18,126,4,0,
34,18,156,4,0,
34,18,157,4,0,
34,18,164,4,0,
34,18,168,4,0,
34,18,182,4,0,
34,18,188,4,0,
34,18,201,4,0,
34,18,207,4,0,
34,18,213,4,0,
34,18,214,4,0,
34,18,216,4,0,
34,18,218,4,0,
34,18,224,1,1,1
34,18,224,1,58,
34,18,237,4,0,
34,18,240,4,0,
34,18,241,4,0,
34,18,247,4,0,
34,18,259,4,0,
34,18,263,4,0,
34,18,269,4,0,
34,18,280,4,0,
34,18,317,4,0,
34,18,374,4,0,
34,18,398,4,0,
34,18,411,4,0,
34,18,414,1,43,
34,18,416,4,0,
34,18,421,4,0,
34,18,444,4,0,
34,18,474,4,0,
34,18,479,4,0,
34,18,482,4,0,
34,18,496,4,0,
34,18,497,4,0,
34,18,498,1,23,
34,18,511,4,0,
34,18,523,4,0,
34,18,525,4,0,
34,18,590,4,0,
34,18,684,4,0,
35,1,1,1,1,1
35,1,3,1,18,
35,1,5,4,0,
35,1,25,4,0,
35,1,34,4,0,
35,1,36,4,0,
35,1,38,4,0,
35,1,45,1,1,2
35,1,47,1,13,
35,1,55,4,0,
35,1,58,4,0,
35,1,59,4,0,
35,1,61,4,0,
35,1,66,4,0,
35,1,68,4,0,
35,1,69,4,0,
35,1,70,4,0,
35,1,76,4,0,
35,1,85,4,0,
35,1,86,4,0,
35,1,87,4,0,
35,1,92,4,0,
35,1,94,4,0,
35,1,99,4,0,
35,1,100,4,0,
35,1,102,4,0,
35,1,104,4,0,
35,1,107,1,24,
35,1,111,1,39,
35,1,113,1,48,
35,1,115,4,0,
35,1,117,4,0,
35,1,118,1,31,
35,1,118,4,0,
35,1,126,4,0,
35,1,130,4,0,
35,1,148,4,0,
35,1,149,4,0,
35,1,156,4,0,
35,1,161,4,0,
35,1,164,4,0,
35,2,1,1,1,1
35,2,3,1,18,
35,2,5,4,0,
35,2,25,4,0,
35,2,34,4,0,
35,2,36,4,0,
35,2,38,4,0,
35,2,45,1,1,2
35,2,47,1,13,
35,2,55,4,0,
35,2,58,4,0,
35,2,59,4,0,
35,2,61,4,0,
35,2,66,4,0,
35,2,68,4,0,
35,2,69,4,0,
35,2,70,4,0,
35,2,76,4,0,
35,2,85,4,0,
35,2,86,4,0,
35,2,87,4,0,
35,2,92,4,0,
35,2,94,4,0,
35,2,99,4,0,
35,2,100,4,0,
35,2,102,4,0,
35,2,104,4,0,
35,2,107,1,24,
35,2,111,1,39,
35,2,113,1,48,
35,2,115,4,0,
35,2,117,4,0,
35,2,118,1,31,
35,2,118,4,0,
35,2,126,4,0,
35,2,130,4,0,
35,2,148,4,0,
35,2,149,4,0,
35,2,156,4,0,
35,2,161,4,0,
35,2,164,4,0,
35,3,1,1,1,1
35,3,3,1,13,
35,3,7,4,0,
35,3,8,4,0,
35,3,9,4,0,
35,3,29,4,0,
35,3,45,1,1,2
35,3,47,1,8,
35,3,59,4,0,
35,3,70,4,0,
35,3,76,4,0,
35,3,87,4,0,
35,3,92,4,0,
35,3,94,4,0,
35,3,104,4,0,
35,3,107,1,19,
35,3,111,1,26,
35,3,111,4,0,
35,3,113,1,53,
35,3,118,1,34,
35,3,126,4,0,
35,3,138,4,0,
35,3,148,4,0,
35,3,156,4,0,
35,3,171,4,0,
35,3,173,4,0,
35,3,174,4,0,
35,3,182,4,0,
35,3,189,4,0,
35,3,192,4,0,
35,3,197,4,0,
35,3,203,4,0,
35,3,205,4,0,
35,3,207,4,0,
35,3,213,4,0,
35,3,214,4,0,
35,3,216,4,0,
35,3,218,4,0,
35,3,223,4,0,
35,3,227,1,4,
35,3,231,4,0,
35,3,236,1,43,
35,3,237,4,0,
35,3,240,4,0,
35,3,241,4,0,
35,3,244,4,0,
35,3,247,4,0,
35,4,1,1,1,1
35,4,3,1,13,
35,4,7,4,0,
35,4,8,4,0,
35,4,9,4,0,
35,4,29,4,0,
35,4,45,1,1,2
35,4,47,1,8,
35,4,53,3,0,
35,4,58,3,0,
35,4,59,4,0,
35,4,70,4,0,
35,4,76,4,0,
35,4,85,3,0,
35,4,87,4,0,
35,4,92,4,0,
35,4,94,4,0,
35,4,104,4,0,
35,4,107,1,19,
35,4,111,1,26,
35,4,111,4,0,
35,4,113,1,53,
35,4,118,1,34,
35,4,126,4,0,
35,4,138,4,0,
35,4,148,4,0,
35,4,156,4,0,
35,4,171,4,0,
35,4,173,4,0,
35,4,174,4,0,
35,4,182,4,0,
35,4,189,4,0,
35,4,192,4,0,
35,4,197,4,0,
35,4,203,4,0,
35,4,205,4,0,
35,4,207,4,0,
35,4,213,4,0,
35,4,214,4,0,
35,4,216,4,0,
35,4,218,4,0,
35,4,223,4,0,
35,4,227,1,4,
35,4,231,4,0,
35,4,236,1,43,
35,4,237,4,0,
35,4,240,4,0,
35,4,241,4,0,
35,4,244,4,0,
35,4,247,4,0,
35,5,1,1,1,1
35,5,3,1,13,
35,5,45,1,1,2
35,5,47,1,9,
35,5,53,4,0,
35,5,58,4,0,
35,5,59,4,0,
35,5,70,4,0,
35,5,76,4,0,
35,5,85,4,0,
35,5,87,4,0,
35,5,91,4,0,
35,5,92,4,0,
35,5,94,4,0,
35,5,104,4,0,
35,5,107,1,21,
35,5,111,1,25,
35,5,113,1,41,
35,5,113,4,0,
35,5,115,4,0,
35,5,118,1,29,
35,5,126,4,0,
35,5,148,4,0,
35,5,156,4,0,
35,5,182,4,0,
35,5,213,4,0,
35,5,216,4,0,
35,5,218,4,0,
35,5,219,4,0,
35,5,227,1,5,
35,5,231,4,0,
35,5,236,1,37,
35,5,237,4,0,
35,5,240,4,0,
35,5,241,4,0,
35,5,247,4,0,
35,5,263,4,0,
35,5,264,4,0,
35,5,266,1,17,
35,5,280,4,0,
35,5,289,4,0,
35,5,290,4,0,
35,5,309,1,45,
35,5,322,1,33,
35,5,347,4,0,
35,5,351,4,0,
35,5,352,4,0,
35,6,1,1,1,1
35,6,3,1,13,
35,6,5,3,0,
35,6,7,3,0,
35,6,8,3,0,
35,6,9,3,0,
35,6,25,3,0,
35,6,34,3,0,
35,6,38,3,0,
35,6,45,1,1,2
35,6,47,1,9,
35,6,53,4,0,
35,6,58,4,0,
35,6,59,4,0,
35,6,68,3,0,
35,6,69,3,0,
35,6,70,4,0,
35,6,76,4,0,
35,6,85,4,0,
35,6,86,3,0,
35,6,87,4,0,
35,6,91,4,0,
35,6,92,4,0,
35,6,94,4,0,
35,6,102,3,0,
35,6,104,4,0,
35,6,107,1,21,
35,6,111,1,25,
35,6,111,3,0,
35,6,113,1,41,
35,6,113,4,0,
35,6,115,4,0,
35,6,118,1,29,
35,6,118,3,0,
35,6,126,4,0,
35,6,135,3,0,
35,6,138,3,0,
35,6,148,4,0,
35,6,156,4,0,
35,6,164,3,0,
35,6,173,3,0,
35,6,182,4,0,
35,6,189,3,0,
35,6,203,3,0,
35,6,205,3,0,
35,6,207,3,0,
35,6,213,4,0,
35,6,214,3,0,
35,6,216,4,0,
35,6,218,4,0,
35,6,219,4,0,
35,6,223,3,0,
35,6,227,1,5,
35,6,231,4,0,
35,6,236,1,37,
35,6,237,4,0,
35,6,240,4,0,
35,6,241,4,0,
35,6,244,3,0,
35,6,247,4,0,
35,6,263,4,0,
35,6,264,4,0,
35,6,266,1,17,
35,6,280,4,0,
35,6,289,4,0,
35,6,290,4,0,
35,6,309,1,45,
35,6,322,1,33,
35,6,347,4,0,
35,6,351,4,0,
35,6,352,4,0,
35,7,1,1,1,1
35,7,3,1,13,
35,7,5,3,0,
35,7,25,3,0,
35,7,34,3,0,
35,7,38,3,0,
35,7,45,1,1,2
35,7,47,1,9,
35,7,53,4,0,
35,7,58,4,0,
35,7,59,4,0,
35,7,68,3,0,
35,7,69,3,0,
35,7,70,4,0,
35,7,76,4,0,
35,7,85,4,0,
35,7,86,3,0,
35,7,87,4,0,
35,7,91,4,0,
35,7,92,4,0,
35,7,94,4,0,
35,7,102,3,0,
35,7,104,4,0,
35,7,107,1,21,
35,7,111,1,25,
35,7,113,1,41,
35,7,113,4,0,
35,7,115,4,0,
35,7,118,1,29,
35,7,118,3,0,
35,7,126,4,0,
35,7,135,3,0,
35,7,138,3,0,
35,7,148,4,0,
35,7,156,4,0,
35,7,164,3,0,
35,7,182,4,0,
35,7,213,4,0,
35,7,216,4,0,
35,7,218,4,0,
35,7,219,4,0,
35,7,227,1,5,
35,7,231,4,0,
35,7,236,1,37,
35,7,237,4,0,
35,7,240,4,0,
35,7,241,4,0,
35,7,247,4,0,
35,7,263,4,0,
35,7,264,4,0,
35,7,266,1,17,
35,7,280,4,0,
35,7,289,4,0,
35,7,290,4,0,
35,7,309,1,45,
35,7,322,1,33,
35,7,347,4,0,
35,7,351,4,0,
35,7,352,4,0,
35,8,1,1,1,1
35,8,3,1,10,
35,8,45,1,1,2
35,8,47,1,7,
35,8,53,4,0,
35,8,58,4,0,
35,8,59,4,0,
35,8,70,4,0,
35,8,76,4,0,
35,8,85,4,0,
35,8,86,4,0,
35,8,87,4,0,
35,8,91,4,0,
35,8,92,4,0,
35,8,94,4,0,
35,8,104,4,0,
35,8,107,1,19,
35,8,111,1,13,
35,8,113,1,40,
35,8,113,4,0,
35,8,115,4,0,
35,8,118,1,31,
35,8,126,4,0,
35,8,138,4,0,
35,8,148,4,0,
35,8,156,4,0,
35,8,164,4,0,
35,8,182,4,0,
35,8,203,4,0,
35,8,207,4,0,
35,8,213,4,0,
35,8,214,4,0,
35,8,216,4,0,
35,8,218,4,0,
35,8,219,4,0,
35,8,227,1,4,
35,8,231,4,0,
35,8,236,1,37,
35,8,237,4,0,
35,8,240,4,0,
35,8,241,4,0,
35,8,244,4,0,
35,8,247,4,0,
35,8,249,4,0,
35,8,263,4,0,
35,8,264,4,0,
35,8,266,1,16,
35,8,278,4,0,
35,8,280,4,0,
35,8,289,4,0,
35,8,290,4,0,
35,8,309,1,43,
35,8,322,1,25,
35,8,347,4,0,
35,8,351,4,0,
35,8,352,4,0,
35,8,356,1,34,
35,8,358,1,22,
35,8,361,1,46,
35,8,363,4,0,
35,8,374,4,0,
35,8,381,1,28,
35,8,409,4,0,
35,8,445,4,0,
35,8,446,4,0,
35,8,447,4,0,
35,8,451,4,0,
35,9,1,1,1,1
35,9,3,1,10,
35,9,7,3,0,
35,9,8,3,0,
35,9,9,3,0,
35,9,45,1,1,2
35,9,47,1,7,
35,9,53,4,0,
35,9,58,4,0,
35,9,59,4,0,
35,9,70,4,0,
35,9,76,4,0,
35,9,85,4,0,
35,9,86,4,0,
35,9,87,4,0,
35,9,91,4,0,
35,9,92,4,0,
35,9,94,4,0,
35,9,104,4,0,
35,9,107,1,19,
35,9,111,1,13,
35,9,113,1,40,
35,9,113,4,0,
35,9,115,4,0,
35,9,118,1,31,
35,9,126,4,0,
35,9,138,4,0,
35,9,148,4,0,
35,9,156,4,0,
35,9,164,4,0,
35,9,173,3,0,
35,9,182,4,0,
35,9,189,3,0,
35,9,196,3,0,
35,9,203,4,0,
35,9,205,3,0,
35,9,207,4,0,
35,9,213,4,0,
35,9,214,4,0,
35,9,216,4,0,
35,9,218,4,0,
35,9,219,4,0,
35,9,227,1,4,
35,9,231,4,0,
35,9,236,1,37,
35,9,237,4,0,
35,9,240,4,0,
35,9,241,4,0,
35,9,244,4,0,
35,9,247,4,0,
35,9,249,4,0,
35,9,263,4,0,
35,9,264,4,0,
35,9,266,1,16,
35,9,270,3,0,
35,9,271,3,0,
35,9,278,4,0,
35,9,280,4,0,
35,9,282,3,0,
35,9,283,3,0,
35,9,289,4,0,
35,9,290,4,0,
35,9,309,1,43,
35,9,322,1,25,
35,9,324,3,0,
35,9,340,3,0,
35,9,347,4,0,
35,9,351,4,0,
35,9,352,4,0,
35,9,356,1,34,
35,9,358,1,22,
35,9,361,1,46,
35,9,363,4,0,
35,9,374,4,0,
35,9,381,1,28,
35,9,387,3,0,
35,9,409,4,0,
35,9,428,3,0,
35,9,445,4,0,
35,9,446,4,0,
35,9,447,4,0,
35,9,451,4,0,
35,10,1,1,1,1
35,10,3,1,10,
35,10,7,3,0,
35,10,8,3,0,
35,10,9,3,0,
35,10,29,3,0,
35,10,45,1,1,2
35,10,47,1,7,
35,10,53,4,0,
35,10,58,4,0,
35,10,59,4,0,
35,10,70,4,0,
35,10,76,4,0,
35,10,85,4,0,
35,10,86,4,0,
35,10,87,4,0,
35,10,91,4,0,
35,10,92,4,0,
35,10,94,4,0,
35,10,104,4,0,
35,10,107,1,19,
35,10,111,1,13,
35,10,113,1,40,
35,10,113,4,0,
35,10,115,4,0,
35,10,118,1,31,
35,10,126,4,0,
35,10,138,4,0,
35,10,148,4,0,
35,10,156,4,0,
35,10,164,4,0,
35,10,173,3,0,
35,10,182,4,0,
35,10,189,3,0,
35,10,196,3,0,
35,10,203,4,0,
35,10,205,3,0,
35,10,207,4,0,
35,10,213,4,0,
35,10,214,4,0,
35,10,215,3,0,
35,10,216,4,0,
35,10,218,4,0,
35,10,219,4,0,
35,10,227,1,4,
35,10,231,4,0,
35,10,236,1,37,
35,10,237,4,0,
35,10,240,4,0,
35,10,241,4,0,
35,10,244,4,0,
35,10,247,4,0,
35,10,249,4,0,
35,10,263,4,0,
35,10,264,4,0,
35,10,266,1,16,
35,10,270,3,0,
35,10,271,3,0,
35,10,272,3,0,
35,10,277,3,0,
35,10,278,4,0,
35,10,280,4,0,
35,10,282,3,0,
35,10,283,3,0,
35,10,289,4,0,
35,10,290,4,0,
35,10,309,1,43,
35,10,322,1,25,
35,10,324,3,0,
35,10,340,3,0,
35,10,347,4,0,
35,10,351,4,0,
35,10,352,4,0,
35,10,356,1,34,
35,10,356,3,0,
35,10,358,1,22,
35,10,361,1,46,
35,10,363,4,0,
35,10,374,4,0,
35,10,381,1,28,
35,10,387,3,0,
35,10,409,4,0,
35,10,428,3,0,
35,10,445,4,0,
35,10,446,4,0,
35,10,447,4,0,
35,10,451,4,0,
35,11,1,1,1,1
35,11,3,1,10,
35,11,45,1,1,2
35,11,47,1,7,
35,11,53,4,0,
35,11,58,4,0,
35,11,59,4,0,
35,11,70,4,0,
35,11,76,4,0,
35,11,85,4,0,
35,11,86,4,0,
35,11,87,4,0,
35,11,91,4,0,
35,11,92,4,0,
35,11,94,4,0,
35,11,104,4,0,
35,11,107,1,19,
35,11,111,1,13,
35,11,113,1,46,
35,11,113,4,0,
35,11,115,4,0,
35,11,118,1,34,
35,11,126,4,0,
35,11,138,4,0,
35,11,148,4,0,
35,11,156,4,0,
35,11,164,4,0,
35,11,182,4,0,
35,11,207,4,0,
35,11,213,4,0,
35,11,216,4,0,
35,11,218,4,0,
35,11,219,4,0,
35,11,227,1,4,
35,11,236,1,40,
35,11,237,4,0,
35,11,240,4,0,
35,11,241,4,0,
35,11,244,4,0,
35,11,247,4,0,
35,11,249,4,0,
35,11,263,4,0,
35,11,266,1,16,
35,11,280,4,0,
35,11,309,1,55,
35,11,322,1,28,
35,11,347,4,0,
35,11,356,1,37,
35,11,358,1,22,
35,11,361,1,49,
35,11,374,4,0,
35,11,381,1,31,
35,11,447,4,0,
35,11,451,4,0,
35,11,473,4,0,
35,11,477,4,0,
35,11,495,1,52,
35,11,496,4,0,
35,11,497,4,0,
35,11,500,1,43,
35,11,510,4,0,
35,11,514,4,0,
35,11,516,1,25,
35,11,526,4,0,
35,12,1,1,1,1
35,12,3,1,13,
35,12,45,1,1,2
35,12,47,1,9,
35,12,53,4,0,
35,12,58,4,0,
35,12,59,4,0,
35,12,70,4,0,
35,12,76,4,0,
35,12,85,4,0,
35,12,87,4,0,
35,12,91,4,0,
35,12,92,4,0,
35,12,94,4,0,
35,12,104,4,0,
35,12,107,1,21,
35,12,111,1,25,
35,12,113,1,41,
35,12,113,4,0,
35,12,115,4,0,
35,12,118,1,29,
35,12,126,4,0,
35,12,148,4,0,
35,12,156,4,0,
35,12,182,4,0,
35,12,213,4,0,
35,12,216,4,0,
35,12,218,4,0,
35,12,219,4,0,
35,12,227,1,5,
35,12,231,4,0,
35,12,236,1,37,
35,12,237,4,0,
35,12,240,4,0,
35,12,241,4,0,
35,12,247,4,0,
35,12,263,4,0,
35,12,264,4,0,
35,12,266,1,17,
35,12,280,4,0,
35,12,289,4,0,
35,12,290,4,0,
35,12,309,1,45,
35,12,322,1,33,
35,12,347,4,0,
35,12,351,4,0,
35,12,352,4,0,
35,13,1,1,1,1
35,13,3,1,13,
35,13,34,3,0,
35,13,38,3,0,
35,13,45,1,1,2
35,13,47,1,9,
35,13,53,4,0,
35,13,58,4,0,
35,13,59,4,0,
35,13,69,3,0,
35,13,70,4,0,
35,13,76,4,0,
35,13,85,4,0,
35,13,86,3,0,
35,13,87,4,0,
35,13,91,4,0,
35,13,92,4,0,
35,13,94,4,0,
35,13,102,3,0,
35,13,104,4,0,
35,13,107,1,21,
35,13,111,1,25,
35,13,113,1,41,
35,13,113,4,0,
35,13,115,4,0,
35,13,118,1,29,
35,13,126,4,0,
35,13,138,3,0,
35,13,148,4,0,
35,13,156,4,0,
35,13,164,3,0,
35,13,171,3,0,
35,13,182,4,0,
35,13,207,3,0,
35,13,213,4,0,
35,13,216,4,0,
35,13,218,4,0,
35,13,219,4,0,
35,13,227,1,5,
35,13,231,4,0,
35,13,236,1,37,
35,13,237,4,0,
35,13,240,4,0,
35,13,241,4,0,
35,13,247,4,0,
35,13,263,4,0,
35,13,264,4,0,
35,13,266,1,17,
35,13,280,4,0,
35,13,289,4,0,
35,13,290,4,0,
35,13,309,1,45,
35,13,322,1,33,
35,13,347,4,0,
35,13,351,4,0,
35,13,352,4,0,
35,14,1,1,1,1
35,14,3,1,10,
35,14,7,3,0,
35,14,8,3,0,
35,14,9,3,0,
35,14,34,1,40,
35,14,45,1,1,2
35,14,47,1,7,
35,14,53,4,0,
35,14,58,4,0,
35,14,59,4,0,
35,14,70,4,0,
35,14,76,4,0,
35,14,85,4,0,
35,14,86,4,0,
35,14,87,4,0,
35,14,91,4,0,
35,14,92,4,0,
35,14,94,4,0,
35,14,104,4,0,
35,14,107,1,25,
35,14,111,1,13,
35,14,113,1,46,
35,14,113,4,0,
35,14,115,4,0,
35,14,118,1,31,
35,14,126,4,0,
35,14,138,4,0,
35,14,148,4,0,
35,14,156,4,0,
35,14,164,4,0,
35,14,173,3,0,
35,14,182,4,0,
35,14,196,3,0,
35,14,207,4,0,
35,14,213,4,0,
35,14,214,3,0,
35,14,215,3,0,
35,14,216,4,0,
35,14,218,4,0,
35,14,219,4,0,
35,14,227,1,4,
35,14,231,3,0,
35,14,236,1,43,
35,14,237,4,0,
35,14,240,4,0,
35,14,241,4,0,
35,14,244,4,0,
35,14,247,4,0,
35,14,249,4,0,
35,14,263,4,0,
35,14,266,1,16,
35,14,270,3,0,
35,14,271,3,0,
35,14,272,3,0,
35,14,277,3,0,
35,14,278,3,0,
35,14,280,4,0,
35,14,282,3,0,
35,14,283,3,0,
35,14,289,3,0,
35,14,304,3,0,
35,14,309,1,52,
35,14,322,1,34,
35,14,324,3,0,
35,14,340,3,0,
35,14,343,3,0,
35,14,347,4,0,
35,14,356,1,49,
35,14,356,3,0,
35,14,358,1,22,
35,14,361,1,55,
35,14,374,4,0,
35,14,381,1,37,
35,14,387,3,0,
35,14,409,3,0,
35,14,428,3,0,
35,14,446,3,0,
35,14,447,4,0,
35,14,451,4,0,
35,14,472,3,0,
35,14,473,4,0,
35,14,477,4,0,
35,14,495,1,58,
35,14,495,3,0,
35,14,496,4,0,
35,14,497,4,0,
35,14,500,1,28,
35,14,510,4,0,
35,14,514,4,0,
35,14,516,1,19,
35,14,526,4,0,
35,15,1,1,1,4
35,15,3,1,10,
35,15,34,1,40,
35,15,45,1,1,5
35,15,47,1,7,
35,15,53,4,0,
35,15,58,4,0,
35,15,59,4,0,
35,15,70,4,0,
35,15,76,4,0,
35,15,85,4,0,
35,15,86,4,0,
35,15,87,4,0,
35,15,91,4,0,
35,15,92,4,0,
35,15,94,4,0,
35,15,104,4,0,
35,15,107,1,25,
35,15,111,1,13,
35,15,113,4,0,
35,15,115,4,0,
35,15,118,1,31,
35,15,126,4,0,
35,15,138,4,0,
35,15,148,4,0,
35,15,156,4,0,
35,15,164,4,0,
35,15,182,4,0,
35,15,207,4,0,
35,15,213,4,0,
35,15,214,4,0,
35,15,216,4,0,
35,15,218,4,0,
35,15,219,4,0,
35,15,227,1,1,6
35,15,236,1,43,
35,15,237,4,0,
35,15,240,4,0,
35,15,241,4,0,
35,15,244,4,0,
35,15,247,4,0,
35,15,249,4,0,
35,15,263,4,0,
35,15,266,1,16,
35,15,280,4,0,
35,15,309,1,50,
35,15,322,1,34,
35,15,347,4,0,
35,15,356,1,49,
35,15,358,1,22,
35,15,361,1,1,2
35,15,361,1,55,
35,15,374,4,0,
35,15,381,1,37,
35,15,447,4,0,
35,15,451,4,0,
35,15,473,4,0,
35,15,495,1,1,1
35,15,495,1,58,
35,15,496,4,0,
35,15,497,4,0,
35,15,500,1,28,
35,15,510,4,0,
35,15,514,4,0,
35,15,516,1,19,
35,15,574,1,1,3
35,15,585,1,46,
35,15,590,4,0,
35,15,605,4,0,
35,15,612,4,0,
35,16,1,1,1,4
35,16,3,1,10,1
35,16,7,3,0,
35,16,8,3,0,
35,16,9,3,0,
35,16,34,1,40,1
35,16,45,1,1,5
35,16,47,1,7,1
35,16,53,4,0,
35,16,58,4,0,
35,16,59,4,0,
35,16,70,4,0,
35,16,76,4,0,
35,16,85,4,0,
35,16,86,4,0,
35,16,87,4,0,
35,16,91,4,0,
35,16,92,4,0,
35,16,94,4,0,
35,16,104,4,0,
35,16,107,1,25,1
35,16,111,1,13,1
35,16,113,4,0,
35,16,115,4,0,
35,16,118,1,31,1
35,16,126,4,0,
35,16,138,4,0,
35,16,148,4,0,
35,16,156,4,0,
35,16,164,4,0,
35,16,173,3,0,
35,16,182,4,0,
35,16,196,3,0,
35,16,207,4,0,
35,16,213,4,0,
35,16,214,4,0,
35,16,215,3,0,
35,16,216,4,0,
35,16,218,4,0,
35,16,219,4,0,
35,16,227,1,1,6
35,16,231,3,0,
35,16,236,1,43,1
35,16,237,4,0,
35,16,240,4,0,
35,16,241,4,0,
35,16,244,4,0,
35,16,247,4,0,
35,16,249,4,0,
35,16,263,4,0,
35,16,264,3,0,
35,16,266,1,16,1
35,16,270,3,0,
35,16,271,3,0,
35,16,272,3,0,
35,16,277,3,0,
35,16,278,3,0,
35,16,280,4,0,
35,16,282,3,0,
35,16,283,3,0,
35,16,289,3,0,
35,16,290,4,0,
35,16,304,3,0,
35,16,309,1,50,1
35,16,322,1,34,1
35,16,324,3,0,
35,16,340,3,0,
35,16,343,3,0,
35,16,347,4,0,
35,16,351,3,0,
35,16,352,3,0,
35,16,356,1,49,1
35,16,356,3,0,
35,16,358,1,22,1
35,16,361,1,1,2
35,16,361,1,55,1
35,16,374,4,0,
35,16,381,1,37,1
35,16,387,3,0,
35,16,409,3,0,
35,16,428,3,0,
35,16,446,3,0,
35,16,447,4,0,
35,16,451,4,0,
35,16,472,3,0,
35,16,473,4,0,
35,16,495,1,1,1
35,16,495,1,58,1
35,16,495,3,0,
35,16,496,4,0,
35,16,497,4,0,
35,16,500,1,28,1
35,16,510,4,0,
35,16,514,4,0,
35,16,516,1,19,1
35,16,574,1,1,3
35,16,585,1,46,1
35,16,590,4,0,
35,16,605,4,0,
35,16,612,4,0,
35,17,1,1,1,3
35,17,3,1,10,
35,17,34,1,40,
35,17,45,1,1,4
35,17,47,1,7,
35,17,53,4,0,
35,17,58,4,0,
35,17,59,4,0,
35,17,76,4,0,
35,17,85,4,0,
35,17,86,4,0,
35,17,87,4,0,
35,17,92,4,0,
35,17,94,4,0,
35,17,104,4,0,
35,17,107,1,25,
35,17,111,1,13,
35,17,113,4,0,
35,17,115,4,0,
35,17,118,1,31,
35,17,126,4,0,
35,17,138,4,0,
35,17,156,4,0,
35,17,164,4,0,
35,17,182,4,0,
35,17,207,4,0,
35,17,213,4,0,
35,17,214,4,0,
35,17,216,4,0,
35,17,218,4,0,
35,17,219,4,0,
35,17,227,1,1,5
35,17,236,1,43,
35,17,237,4,0,
35,17,240,4,0,
35,17,241,4,0,
35,17,244,4,0,
35,17,247,4,0,
35,17,263,4,0,
35,17,266,1,16,
35,17,280,4,0,
35,17,309,1,50,
35,17,322,1,34,
35,17,347,4,0,
35,17,356,1,49,
35,17,358,1,22,
35,17,361,1,55,
35,17,374,4,0,
35,17,381,1,37,
35,17,447,4,0,
35,17,451,4,0,
35,17,473,4,0,
35,17,495,1,58,
35,17,496,4,0,
35,17,497,4,0,
35,17,500,1,28,
35,17,516,1,19,
35,17,526,4,0,
35,17,574,1,1,2
35,17,585,1,46,
35,17,590,4,0,
35,17,605,4,0,
35,17,671,1,1,1
35,18,1,1,1,3
35,18,3,1,10,
35,18,34,1,40,
35,18,45,1,1,4
35,18,47,1,7,
35,18,53,4,0,
35,18,58,4,0,
35,18,59,4,0,
35,18,76,4,0,
35,18,85,4,0,
35,18,86,4,0,
35,18,87,4,0,
35,18,92,4,0,
35,18,94,4,0,
35,18,104,4,0,
35,18,107,1,25,
35,18,111,1,13,
35,18,113,4,0,
35,18,115,4,0,
35,18,118,1,31,
35,18,126,4,0,
35,18,138,4,0,
35,18,156,4,0,
35,18,164,4,0,
35,18,182,4,0,
35,18,207,4,0,
35,18,213,4,0,
35,18,214,4,0,
35,18,216,4,0,
35,18,218,4,0,
35,18,219,4,0,
35,18,227,1,1,5
35,18,236,1,43,
35,18,237,4,0,
35,18,240,4,0,
35,18,241,4,0,
35,18,244,4,0,
35,18,247,4,0,
35,18,263,4,0,
35,18,266,1,16,
35,18,280,4,0,
35,18,309,1,50,
35,18,322,1,34,
35,18,347,4,0,
35,18,356,1,49,
35,18,358,1,22,
35,18,361,1,55,
35,18,374,4,0,
35,18,381,1,37,
35,18,447,4,0,
35,18,451,4,0,
35,18,473,4,0,
35,18,495,1,58,
35,18,496,4,0,
35,18,497,4,0,
35,18,500,1,28,
35,18,516,1,19,
35,18,526,4,0,
35,18,574,1,1,2
35,18,585,1,46,
35,18,590,4,0,
35,18,605,4,0,
35,18,671,1,1,1
36,1,3,1,1,2
36,1,5,4,0,
36,1,25,4,0,
36,1,34,4,0,
36,1,36,4,0,
36,1,38,4,0,
36,1,47,1,1,1
36,1,55,4,0,
36,1,58,4,0,
36,1,59,4,0,
36,1,61,4,0,
36,1,63,4,0,
36,1,66,4,0,
36,1,68,4,0,
36,1,69,4,0,
36,1,70,4,0,
36,1,76,4,0,
36,1,85,4,0,
36,1,86,4,0,
36,1,87,4,0,
36,1,92,4,0,
36,1,94,4,0,
36,1,99,4,0,
36,1,100,4,0,
36,1,102,4,0,
36,1,104,4,0,
36,1,107,1,1,3
36,1,115,4,0,
36,1,117,4,0,
36,1,118,1,1,4
36,1,118,4,0,
36,1,126,4,0,
36,1,130,4,0,
36,1,148,4,0,
36,1,149,4,0,
36,1,156,4,0,
36,1,161,4,0,
36,1,164,4,0,
36,2,3,1,1,2
36,2,5,4,0,
36,2,25,4,0,
36,2,34,4,0,
36,2,36,4,0,
36,2,38,4,0,
36,2,47,1,1,1
36,2,55,4,0,
36,2,58,4,0,
36,2,59,4,0,
36,2,61,4,0,
36,2,63,4,0,
36,2,66,4,0,
36,2,68,4,0,
36,2,69,4,0,
36,2,70,4,0,
36,2,76,4,0,
36,2,85,4,0,
36,2,86,4,0,
36,2,87,4,0,
36,2,92,4,0,
36,2,94,4,0,
36,2,99,4,0,
36,2,100,4,0,
36,2,102,4,0,
36,2,104,4,0,
36,2,107,1,1,3
36,2,115,4,0,
36,2,117,4,0,
36,2,118,1,1,4
36,2,118,4,0,
36,2,126,4,0,
36,2,130,4,0,
36,2,148,4,0,
36,2,149,4,0,
36,2,156,4,0,
36,2,161,4,0,
36,2,164,4,0,
36,3,3,1,1,2
36,3,7,4,0,
36,3,8,4,0,
36,3,9,4,0,
36,3,29,4,0,
36,3,47,1,1,1
36,3,59,4,0,
36,3,63,4,0,
36,3,70,4,0,
36,3,76,4,0,
36,3,87,4,0,
36,3,92,4,0,
36,3,94,4,0,
36,3,104,4,0,
36,3,111,4,0,
36,3,118,1,1,3
36,3,126,4,0,
36,3,138,4,0,
36,3,148,4,0,
36,3,156,4,0,
36,3,171,4,0,
36,3,173,4,0,
36,3,174,4,0,
36,3,182,4,0,
36,3,189,4,0,
36,3,192,4,0,
36,3,197,4,0,
36,3,203,4,0,
36,3,205,4,0,
36,3,207,4,0,
36,3,213,4,0,
36,3,214,4,0,
36,3,216,4,0,
36,3,218,4,0,
36,3,223,4,0,
36,3,231,4,0,
36,3,236,1,1,4
36,3,237,4,0,
36,3,240,4,0,
36,3,241,4,0,
36,3,244,4,0,
36,3,247,4,0,
36,4,3,1,1,2
36,4,7,4,0,
36,4,8,4,0,
36,4,9,4,0,
36,4,29,4,0,
36,4,47,1,1,1
36,4,53,3,0,
36,4,58,3,0,
36,4,59,4,0,
36,4,63,4,0,
36,4,70,4,0,
36,4,76,4,0,
36,4,85,3,0,
36,4,87,4,0,
36,4,92,4,0,
36,4,94,4,0,
36,4,104,4,0,
36,4,111,4,0,
36,4,118,1,1,3
36,4,126,4,0,
36,4,138,4,0,
36,4,148,4,0,
36,4,156,4,0,
36,4,171,4,0,
36,4,173,4,0,
36,4,174,4,0,
36,4,182,4,0,
36,4,189,4,0,
36,4,192,4,0,
36,4,197,4,0,
36,4,203,4,0,
36,4,205,4,0,
36,4,207,4,0,
36,4,213,4,0,
36,4,214,4,0,
36,4,216,4,0,
36,4,218,4,0,
36,4,223,4,0,
36,4,231,4,0,
36,4,236,1,1,4
36,4,237,4,0,
36,4,240,4,0,
36,4,241,4,0,
36,4,244,4,0,
36,4,247,4,0,
36,5,3,1,1,2
36,5,47,1,1,1
36,5,53,4,0,
36,5,58,4,0,
36,5,59,4,0,
36,5,63,4,0,
36,5,70,4,0,
36,5,76,4,0,
36,5,85,4,0,
36,5,87,4,0,
36,5,91,4,0,
36,5,92,4,0,
36,5,94,4,0,
36,5,104,4,0,
36,5,107,1,1,3
36,5,113,4,0,
36,5,115,4,0,
36,5,118,1,1,4
36,5,126,4,0,
36,5,148,4,0,
36,5,156,4,0,
36,5,182,4,0,
36,5,213,4,0,
36,5,216,4,0,
36,5,218,4,0,
36,5,219,4,0,
36,5,231,4,0,
36,5,237,4,0,
36,5,240,4,0,
36,5,241,4,0,
36,5,247,4,0,
36,5,263,4,0,
36,5,264,4,0,
36,5,280,4,0,
36,5,289,4,0,
36,5,290,4,0,
36,5,347,4,0,
36,5,351,4,0,
36,5,352,4,0,
36,6,3,1,1,2
36,6,5,3,0,
36,6,7,3,0,
36,6,8,3,0,
36,6,9,3,0,
36,6,25,3,0,
36,6,34,3,0,
36,6,38,3,0,
36,6,47,1,1,1
36,6,53,4,0,
36,6,58,4,0,
36,6,59,4,0,
36,6,63,4,0,
36,6,68,3,0,
36,6,69,3,0,
36,6,70,4,0,
36,6,76,4,0,
36,6,85,4,0,
36,6,86,3,0,
36,6,87,4,0,
36,6,91,4,0,
36,6,92,4,0,
36,6,94,4,0,
36,6,102,3,0,
36,6,104,4,0,
36,6,107,1,1,3
36,6,111,3,0,
36,6,113,4,0,
36,6,115,4,0,
36,6,118,1,1,4
36,6,118,3,0,
36,6,126,4,0,
36,6,135,3,0,
36,6,138,3,0,
36,6,148,4,0,
36,6,156,4,0,
36,6,164,3,0,
36,6,173,3,0,
36,6,182,4,0,
36,6,189,3,0,
36,6,203,3,0,
36,6,205,3,0,
36,6,207,3,0,
36,6,213,4,0,
36,6,214,3,0,
36,6,216,4,0,
36,6,218,4,0,
36,6,219,4,0,
36,6,223,3,0,
36,6,231,4,0,
36,6,237,4,0,
36,6,240,4,0,
36,6,241,4,0,
36,6,244,3,0,
36,6,247,4,0,
36,6,263,4,0,
36,6,264,4,0,
36,6,280,4,0,
36,6,289,4,0,
36,6,290,4,0,
36,6,347,4,0,
36,6,351,4,0,
36,6,352,4,0,
36,7,3,1,1,2
36,7,5,3,0,
36,7,25,3,0,
36,7,34,3,0,
36,7,38,3,0,
36,7,47,1,1,1
36,7,53,4,0,
36,7,58,4,0,
36,7,59,4,0,
36,7,63,4,0,
36,7,68,3,0,
36,7,69,3,0,
36,7,70,4,0,
36,7,76,4,0,
36,7,85,4,0,
36,7,86,3,0,
36,7,87,4,0,
36,7,91,4,0,
36,7,92,4,0,
36,7,94,4,0,
36,7,102,3,0,
36,7,104,4,0,
36,7,107,1,1,3
36,7,113,4,0,
36,7,115,4,0,
36,7,118,1,1,4
36,7,118,3,0,
36,7,126,4,0,
36,7,135,3,0,
36,7,138,3,0,
36,7,148,4,0,
36,7,156,4,0,
36,7,164,3,0,
36,7,182,4,0,
36,7,213,4,0,
36,7,216,4,0,
36,7,218,4,0,
36,7,219,4,0,
36,7,231,4,0,
36,7,237,4,0,
36,7,240,4,0,
36,7,241,4,0,
36,7,247,4,0,
36,7,263,4,0,
36,7,264,4,0,
36,7,280,4,0,
36,7,289,4,0,
36,7,290,4,0,
36,7,347,4,0,
36,7,351,4,0,
36,7,352,4,0,
36,8,3,1,1,2
36,8,47,1,1,1
36,8,53,4,0,
36,8,58,4,0,
36,8,59,4,0,
36,8,63,4,0,
36,8,70,4,0,
36,8,76,4,0,
36,8,85,4,0,
36,8,86,4,0,
36,8,87,4,0,
36,8,91,4,0,
36,8,92,4,0,
36,8,94,4,0,
36,8,104,4,0,
36,8,107,1,1,3
36,8,113,4,0,
36,8,115,4,0,
36,8,118,1,1,4
36,8,126,4,0,
36,8,138,4,0,
36,8,148,4,0,
36,8,156,4,0,
36,8,164,4,0,
36,8,182,4,0,
36,8,203,4,0,
36,8,207,4,0,
36,8,213,4,0,
36,8,214,4,0,
36,8,216,4,0,
36,8,218,4,0,
36,8,219,4,0,
36,8,231,4,0,
36,8,237,4,0,
36,8,240,4,0,
36,8,241,4,0,
36,8,244,4,0,
36,8,247,4,0,
36,8,249,4,0,
36,8,263,4,0,
36,8,264,4,0,
36,8,278,4,0,
36,8,280,4,0,
36,8,289,4,0,
36,8,290,4,0,
36,8,347,4,0,
36,8,351,4,0,
36,8,352,4,0,
36,8,363,4,0,
36,8,374,4,0,
36,8,409,4,0,
36,8,411,4,0,
36,8,416,4,0,
36,8,445,4,0,
36,8,446,4,0,
36,8,447,4,0,
36,8,451,4,0,
36,9,3,1,1,2
36,9,7,3,0,
36,9,8,3,0,
36,9,9,3,0,
36,9,47,1,1,1
36,9,53,4,0,
36,9,58,4,0,
36,9,59,4,0,
36,9,63,4,0,
36,9,70,4,0,
36,9,76,4,0,
36,9,85,4,0,
36,9,86,4,0,
36,9,87,4,0,
36,9,91,4,0,
36,9,92,4,0,
36,9,94,4,0,
36,9,104,4,0,
36,9,107,1,1,3
36,9,113,4,0,
36,9,115,4,0,
36,9,118,1,1,4
36,9,126,4,0,
36,9,138,4,0,
36,9,148,4,0,
36,9,156,4,0,
36,9,164,4,0,
36,9,173,3,0,
36,9,182,4,0,
36,9,189,3,0,
36,9,196,3,0,
36,9,203,4,0,
36,9,205,3,0,
36,9,207,4,0,
36,9,213,4,0,
36,9,214,4,0,
36,9,216,4,0,
36,9,218,4,0,
36,9,219,4,0,
36,9,231,4,0,
36,9,237,4,0,
36,9,240,4,0,
36,9,241,4,0,
36,9,244,4,0,
36,9,247,4,0,
36,9,249,4,0,
36,9,263,4,0,
36,9,264,4,0,
36,9,270,3,0,
36,9,271,3,0,
36,9,278,4,0,
36,9,280,4,0,
36,9,282,3,0,
36,9,283,3,0,
36,9,289,4,0,
36,9,290,4,0,
36,9,324,3,0,
36,9,340,3,0,
36,9,347,4,0,
36,9,351,4,0,
36,9,352,4,0,
36,9,363,4,0,
36,9,374,4,0,
36,9,387,3,0,
36,9,409,4,0,
36,9,411,4,0,
36,9,416,4,0,
36,9,428,3,0,
36,9,445,4,0,
36,9,446,4,0,
36,9,447,4,0,
36,9,451,4,0,
36,10,3,1,1,2
36,10,7,3,0,
36,10,8,3,0,
36,10,9,3,0,
36,10,29,3,0,
36,10,47,1,1,1
36,10,53,4,0,
36,10,58,4,0,
36,10,59,4,0,
36,10,63,4,0,
36,10,70,4,0,
36,10,76,4,0,
36,10,85,4,0,
36,10,86,4,0,
36,10,87,4,0,
36,10,91,4,0,
36,10,92,4,0,
36,10,94,4,0,
36,10,104,4,0,
36,10,107,1,1,3
36,10,113,4,0,
36,10,115,4,0,
36,10,118,1,1,4
36,10,126,4,0,
36,10,138,4,0,
36,10,148,4,0,
36,10,156,4,0,
36,10,164,4,0,
36,10,173,3,0,
36,10,182,4,0,
36,10,189,3,0,
36,10,196,3,0,
36,10,203,4,0,
36,10,205,3,0,
36,10,207,4,0,
36,10,213,4,0,
36,10,214,4,0,
36,10,215,3,0,
36,10,216,4,0,
36,10,218,4,0,
36,10,219,4,0,
36,10,231,4,0,
36,10,237,4,0,
36,10,240,4,0,
36,10,241,4,0,
36,10,244,4,0,
36,10,247,4,0,
36,10,249,4,0,
36,10,263,4,0,
36,10,264,4,0,
36,10,270,3,0,
36,10,271,3,0,
36,10,272,3,0,
36,10,277,3,0,
36,10,278,4,0,
36,10,280,4,0,
36,10,282,3,0,
36,10,283,3,0,
36,10,289,4,0,
36,10,290,4,0,
36,10,324,3,0,
36,10,340,3,0,
36,10,347,4,0,
36,10,351,4,0,
36,10,352,4,0,
36,10,356,3,0,
36,10,363,4,0,
36,10,374,4,0,
36,10,387,3,0,
36,10,409,4,0,
36,10,411,4,0,
36,10,416,4,0,
36,10,428,3,0,
36,10,445,4,0,
36,10,446,4,0,
36,10,447,4,0,
36,10,451,4,0,
36,11,3,1,1,2
36,11,47,1,1,1
36,11,53,4,0,
36,11,58,4,0,
36,11,59,4,0,
36,11,63,4,0,
36,11,70,4,0,
36,11,76,4,0,
36,11,85,4,0,
36,11,86,4,0,
36,11,87,4,0,
36,11,91,4,0,
36,11,92,4,0,
36,11,94,4,0,
36,11,104,4,0,
36,11,107,1,1,3
36,11,113,4,0,
36,11,115,4,0,
36,11,118,1,1,4
36,11,126,4,0,
36,11,138,4,0,
36,11,148,4,0,
36,11,156,4,0,
36,11,164,4,0,
36,11,182,4,0,
36,11,207,4,0,
36,11,213,4,0,
36,11,216,4,0,
36,11,218,4,0,
36,11,219,4,0,
36,11,237,4,0,
36,11,240,4,0,
36,11,241,4,0,
36,11,244,4,0,
36,11,247,4,0,
36,11,249,4,0,
36,11,263,4,0,
36,11,280,4,0,
36,11,347,4,0,
36,11,374,4,0,
36,11,411,4,0,
36,11,416,4,0,
36,11,447,4,0,
36,11,451,4,0,
36,11,473,4,0,
36,11,477,4,0,
36,11,496,4,0,
36,11,497,4,0,
36,11,510,4,0,
36,11,514,4,0,
36,11,526,4,0,
36,12,3,1,1,2
36,12,47,1,1,1
36,12,53,4,0,
36,12,58,4,0,
36,12,59,4,0,
36,12,63,4,0,
36,12,70,4,0,
36,12,76,4,0,
36,12,85,4,0,
36,12,87,4,0,
36,12,91,4,0,
36,12,92,4,0,
36,12,94,4,0,
36,12,104,4,0,
36,12,107,1,1,3
36,12,113,4,0,
36,12,115,4,0,
36,12,118,1,1,4
36,12,126,4,0,
36,12,148,4,0,
36,12,156,4,0,
36,12,182,4,0,
36,12,213,4,0,
36,12,216,4,0,
36,12,218,4,0,
36,12,219,4,0,
36,12,231,4,0,
36,12,237,4,0,
36,12,240,4,0,
36,12,241,4,0,
36,12,247,4,0,
36,12,263,4,0,
36,12,264,4,0,
36,12,280,4,0,
36,12,289,4,0,
36,12,290,4,0,
36,12,347,4,0,
36,12,351,4,0,
36,12,352,4,0,
36,13,3,1,1,2
36,13,34,3,0,
36,13,38,3,0,
36,13,47,1,1,1
36,13,53,4,0,
36,13,58,4,0,
36,13,59,4,0,
36,13,63,4,0,
36,13,69,3,0,
36,13,70,4,0,
36,13,76,4,0,
36,13,85,4,0,
36,13,86,3,0,
36,13,87,4,0,
36,13,91,4,0,
36,13,92,4,0,
36,13,94,4,0,
36,13,102,3,0,
36,13,104,4,0,
36,13,107,1,1,3
36,13,113,4,0,
36,13,115,4,0,
36,13,118,1,1,4
36,13,126,4,0,
36,13,138,3,0,
36,13,148,4,0,
36,13,156,4,0,
36,13,164,3,0,
36,13,171,3,0,
36,13,182,4,0,
36,13,207,3,0,
36,13,213,4,0,
36,13,216,4,0,
36,13,218,4,0,
36,13,219,4,0,
36,13,231,4,0,
36,13,237,4,0,
36,13,240,4,0,
36,13,241,4,0,
36,13,247,4,0,
36,13,263,4,0,
36,13,264,4,0,
36,13,280,4,0,
36,13,289,4,0,
36,13,290,4,0,
36,13,347,4,0,
36,13,351,4,0,
36,13,352,4,0,
36,14,3,1,1,2
36,14,7,3,0,
36,14,8,3,0,
36,14,9,3,0,
36,14,47,1,1,1
36,14,53,4,0,
36,14,58,4,0,
36,14,59,4,0,
36,14,63,4,0,
36,14,70,4,0,
36,14,76,4,0,
36,14,85,4,0,
36,14,86,4,0,
36,14,87,4,0,
36,14,91,4,0,
36,14,92,4,0,
36,14,94,4,0,
36,14,104,4,0,
36,14,107,1,1,3
36,14,113,4,0,
36,14,115,4,0,
36,14,118,1,1,4
36,14,126,4,0,
36,14,138,4,0,
36,14,148,4,0,
36,14,156,4,0,
36,14,164,4,0,
36,14,173,3,0,
36,14,182,4,0,
36,14,196,3,0,
36,14,207,4,0,
36,14,213,4,0,
36,14,214,3,0,
36,14,215,3,0,
36,14,216,4,0,
36,14,218,4,0,
36,14,219,4,0,
36,14,231,3,0,
36,14,237,4,0,
36,14,240,4,0,
36,14,241,4,0,
36,14,244,4,0,
36,14,247,4,0,
36,14,249,4,0,
36,14,263,4,0,
36,14,270,3,0,
36,14,271,3,0,
36,14,272,3,0,
36,14,277,3,0,
36,14,278,3,0,
36,14,280,4,0,
36,14,282,3,0,
36,14,283,3,0,
36,14,289,3,0,
36,14,304,3,0,
36,14,324,3,0,
36,14,340,3,0,
36,14,343,3,0,
36,14,347,4,0,
36,14,356,3,0,
36,14,374,4,0,
36,14,387,3,0,
36,14,409,3,0,
36,14,411,4,0,
36,14,416,4,0,
36,14,428,3,0,
36,14,446,3,0,
36,14,447,4,0,
36,14,451,4,0,
36,14,472,3,0,
36,14,473,4,0,
36,14,477,4,0,
36,14,495,3,0,
36,14,496,4,0,
36,14,497,4,0,
36,14,510,4,0,
36,14,514,4,0,
36,14,526,4,0,
36,15,3,1,1,3
36,15,47,1,1,2
36,15,53,4,0,
36,15,58,4,0,
36,15,59,4,0,
36,15,63,4,0,
36,15,70,4,0,
36,15,76,4,0,
36,15,85,4,0,
36,15,86,4,0,
36,15,87,4,0,
36,15,91,4,0,
36,15,92,4,0,
36,15,94,4,0,
36,15,104,4,0,
36,15,107,1,1,4
36,15,113,4,0,
36,15,115,4,0,
36,15,118,1,1,5
36,15,126,4,0,
36,15,138,4,0,
36,15,148,4,0,
36,15,156,4,0,
36,15,164,4,0,
36,15,182,4,0,
36,15,207,4,0,
36,15,213,4,0,
36,15,214,4,0,
36,15,216,4,0,
36,15,218,4,0,
36,15,219,4,0,
36,15,237,4,0,
36,15,240,4,0,
36,15,241,4,0,
36,15,244,4,0,
36,15,247,4,0,
36,15,249,4,0,
36,15,263,4,0,
36,15,280,4,0,
36,15,347,4,0,
36,15,374,4,0,
36,15,411,4,0,
36,15,416,4,0,
36,15,447,4,0,
36,15,451,4,0,
36,15,473,4,0,
36,15,496,4,0,
36,15,497,4,0,
36,15,510,4,0,
36,15,514,4,0,
36,15,574,1,1,1
36,15,590,4,0,
36,15,605,4,0,
36,15,612,4,0,
36,16,3,1,1,3
36,16,7,3,0,
36,16,8,3,0,
36,16,9,3,0,
36,16,47,1,1,2
36,16,53,4,0,
36,16,58,4,0,
36,16,59,4,0,
36,16,63,4,0,
36,16,70,4,0,
36,16,76,4,0,
36,16,85,4,0,
36,16,86,4,0,
36,16,87,4,0,
36,16,91,4,0,
36,16,92,4,0,
36,16,94,4,0,
36,16,104,4,0,
36,16,107,1,1,4
36,16,113,4,0,
36,16,115,4,0,
36,16,118,1,1,5
36,16,126,4,0,
36,16,138,4,0,
36,16,148,4,0,
36,16,156,4,0,
36,16,164,4,0,
36,16,173,3,0,
36,16,182,4,0,
36,16,196,3,0,
36,16,207,4,0,
36,16,213,4,0,
36,16,214,4,0,
36,16,215,3,0,
36,16,216,4,0,
36,16,218,4,0,
36,16,219,4,0,
36,16,231,3,0,
36,16,237,4,0,
36,16,240,4,0,
36,16,241,4,0,
36,16,244,4,0,
36,16,247,4,0,
36,16,249,4,0,
36,16,263,4,0,
36,16,264,3,0,
36,16,270,3,0,
36,16,271,3,0,
36,16,272,3,0,
36,16,277,3,0,
36,16,278,3,0,
36,16,280,4,0,
36,16,282,3,0,
36,16,283,3,0,
36,16,289,3,0,
36,16,290,4,0,
36,16,304,3,0,
36,16,324,3,0,
36,16,340,3,0,
36,16,343,3,0,
36,16,347,4,0,
36,16,351,3,0,
36,16,352,3,0,
36,16,356,3,0,
36,16,374,4,0,
36,16,387,3,0,
36,16,409,3,0,
36,16,411,4,0,
36,16,416,4,0,
36,16,428,3,0,
36,16,446,3,0,
36,16,447,4,0,
36,16,451,4,0,
36,16,472,3,0,
36,16,473,4,0,
36,16,495,3,0,
36,16,496,4,0,
36,16,497,4,0,
36,16,510,4,0,
36,16,514,4,0,
36,16,574,1,1,1
36,16,590,4,0,
36,16,605,4,0,
36,16,612,4,0,
36,17,3,1,1,4
36,17,47,1,1,3
36,17,53,4,0,
36,17,58,4,0,
36,17,59,4,0,
36,17,63,4,0,
36,17,76,4,0,
36,17,85,4,0,
36,17,86,4,0,
36,17,87,4,0,
36,17,92,4,0,
36,17,94,4,0,
36,17,104,4,0,
36,17,107,1,1,5
36,17,113,4,0,
36,17,115,4,0,
36,17,118,1,1,6
36,17,126,4,0,
36,17,138,4,0,
36,17,156,4,0,
36,17,164,4,0,
36,17,182,4,0,
36,17,207,4,0,
36,17,213,4,0,
36,17,214,4,0,
36,17,216,4,0,
36,17,218,4,0,
36,17,219,4,0,
36,17,237,4,0,
36,17,240,4,0,
36,17,241,4,0,
36,17,244,4,0,
36,17,247,4,0,
36,17,263,4,0,
36,17,280,4,0,
36,17,347,4,0,
36,17,374,4,0,
36,17,411,4,0,
36,17,416,4,0,
36,17,447,4,0,
36,17,451,4,0,
36,17,473,4,0,
36,17,496,4,0,
36,17,497,4,0,
36,17,526,4,0,
36,17,574,1,1,2
36,17,590,4,0,
36,17,605,4,0,
36,17,671,1,1,1
36,18,3,1,1,4
36,18,47,1,1,3
36,18,53,4,0,
36,18,58,4,0,
36,18,59,4,0,
36,18,63,4,0,
36,18,76,4,0,
36,18,85,4,0,
36,18,86,4,0,
36,18,87,4,0,
36,18,92,4,0,
36,18,94,4,0,
36,18,104,4,0,
36,18,107,1,1,5
36,18,113,4,0,
36,18,115,4,0,
36,18,118,1,1,6
36,18,126,4,0,
36,18,138,4,0,
36,18,156,4,0,
36,18,164,4,0,
36,18,182,4,0,
36,18,207,4,0,
36,18,213,4,0,
36,18,214,4,0,
36,18,216,4,0,
36,18,218,4,0,
36,18,219,4,0,
36,18,237,4,0,
36,18,240,4,0,
36,18,241,4,0,
36,18,244,4,0,
36,18,247,4,0,
36,18,263,4,0,
36,18,280,4,0,
36,18,347,4,0,
36,18,374,4,0,
36,18,411,4,0,
36,18,416,4,0,
36,18,447,4,0,
36,18,451,4,0,
36,18,473,4,0,
36,18,496,4,0,
36,18,497,4,0,
36,18,526,4,0,
36,18,574,1,1,2
36,18,590,4,0,
36,18,605,4,0,
36,18,671,1,1,1
37,1,34,4,0,
37,1,36,4,0,
37,1,38,4,0,
37,1,39,1,1,2
37,1,46,1,21,
37,1,52,1,1,1
37,1,53,1,35,
37,1,83,1,42,
37,1,91,4,0,
37,1,92,4,0,
37,1,98,1,16,
37,1,99,4,0,
37,1,102,4,0,
37,1,104,4,0,
37,1,109,1,28,
37,1,115,4,0,
37,1,117,4,0,
37,1,126,4,0,
37,1,129,4,0,
37,1,130,4,0,
37,1,156,4,0,
37,1,164,4,0,
37,2,34,4,0,
37,2,36,4,0,
37,2,38,4,0,
37,2,39,1,1,2
37,2,46,1,21,
37,2,52,1,1,1
37,2,53,1,35,
37,2,83,1,42,
37,2,91,4,0,
37,2,92,4,0,
37,2,98,1,16,
37,2,99,4,0,
37,2,102,4,0,
37,2,104,4,0,
37,2,109,1,28,
37,2,115,4,0,
37,2,117,4,0,
37,2,126,4,0,
37,2,129,4,0,
37,2,130,4,0,
37,2,156,4,0,
37,2,164,4,0,
37,3,29,4,0,
37,3,39,1,1,2
37,3,46,1,13,
37,3,50,2,0,
37,3,52,1,1,1
37,3,53,1,31,
37,3,83,1,37,
37,3,91,4,0,
37,3,92,4,0,
37,3,95,2,0,
37,3,98,1,7,
37,3,104,4,0,
37,3,109,1,19,
37,3,126,4,0,
37,3,129,4,0,
37,3,156,4,0,
37,3,173,4,0,
37,3,174,4,0,
37,3,175,2,0,
37,3,180,2,0,
37,3,182,4,0,
37,3,185,2,0,
37,3,203,4,0,
37,3,207,4,0,
37,3,213,4,0,
37,3,214,4,0,
37,3,216,4,0,
37,3,218,4,0,
37,3,219,1,25,
37,3,231,4,0,
37,3,237,4,0,
37,3,241,4,0,
37,4,29,4,0,
37,4,39,1,1,2
37,4,46,1,13,
37,4,50,2,0,
37,4,52,1,1,1
37,4,53,1,31,
37,4,53,3,0,
37,4,83,1,37,
37,4,91,4,0,
37,4,92,4,0,
37,4,95,2,0,
37,4,98,1,7,
37,4,104,4,0,
37,4,109,1,19,
37,4,126,4,0,
37,4,129,4,0,
37,4,156,4,0,
37,4,173,4,0,
37,4,174,4,0,
37,4,175,2,0,
37,4,180,2,0,
37,4,182,4,0,
37,4,185,2,0,
37,4,203,4,0,
37,4,207,4,0,
37,4,213,4,0,
37,4,214,4,0,
37,4,216,4,0,
37,4,218,4,0,
37,4,219,1,25,
37,4,231,4,0,
37,4,237,4,0,
37,4,241,4,0,
37,5,39,1,5,
37,5,46,1,9,
37,5,46,4,0,
37,5,50,2,0,
37,5,52,1,1,
37,5,53,1,29,
37,5,53,4,0,
37,5,83,1,41,
37,5,91,4,0,
37,5,92,4,0,
37,5,95,2,0,
37,5,98,1,13,
37,5,104,4,0,
37,5,109,1,21,
37,5,126,4,0,
37,5,156,4,0,
37,5,175,2,0,
37,5,180,2,0,
37,5,182,4,0,
37,5,185,2,0,
37,5,213,4,0,
37,5,216,4,0,
37,5,218,4,0,
37,5,219,1,33,
37,5,219,4,0,
37,5,231,4,0,
37,5,237,4,0,
37,5,241,4,0,
37,5,244,2,0,
37,5,257,2,0,
37,5,261,1,17,
37,5,263,4,0,
37,5,286,1,25,
37,5,288,1,37,
37,5,290,4,0,
37,5,315,4,0,
37,5,336,2,0,
37,6,34,3,0,
37,6,38,3,0,
37,6,39,1,5,
37,6,46,1,9,
37,6,46,4,0,
37,6,50,2,0,
37,6,52,1,1,
37,6,53,1,29,
37,6,53,4,0,
37,6,83,1,41,
37,6,91,4,0,
37,6,92,4,0,
37,6,95,2,0,
37,6,98,1,13,
37,6,102,3,0,
37,6,104,4,0,
37,6,109,1,21,
37,6,126,4,0,
37,6,129,3,0,
37,6,156,4,0,
37,6,164,3,0,
37,6,173,3,0,
37,6,175,2,0,
37,6,180,2,0,
37,6,182,4,0,
37,6,185,2,0,
37,6,203,3,0,
37,6,207,3,0,
37,6,213,4,0,
37,6,214,3,0,
37,6,216,4,0,
37,6,218,4,0,
37,6,219,1,33,
37,6,219,4,0,
37,6,231,4,0,
37,6,237,4,0,
37,6,241,4,0,
37,6,244,2,0,
37,6,257,2,0,
37,6,261,1,17,
37,6,263,4,0,
37,6,286,1,25,
37,6,288,1,37,
37,6,290,4,0,
37,6,315,4,0,
37,6,336,2,0,
37,7,34,3,0,
37,7,38,3,0,
37,7,39,1,5,
37,7,46,1,9,
37,7,46,4,0,
37,7,50,2,0,
37,7,52,1,1,
37,7,53,1,29,
37,7,53,4,0,
37,7,83,1,41,
37,7,91,4,0,
37,7,92,4,0,
37,7,95,2,0,
37,7,98,1,13,
37,7,102,3,0,
37,7,104,4,0,
37,7,109,1,21,
37,7,126,4,0,
37,7,156,4,0,
37,7,164,3,0,
37,7,175,2,0,
37,7,180,2,0,
37,7,182,4,0,
37,7,185,2,0,
37,7,213,4,0,
37,7,216,4,0,
37,7,218,4,0,
37,7,219,1,33,
37,7,219,4,0,
37,7,231,4,0,
37,7,237,4,0,
37,7,241,4,0,
37,7,244,2,0,
37,7,257,2,0,
37,7,261,1,17,
37,7,263,4,0,
37,7,286,1,25,
37,7,288,1,37,
37,7,290,4,0,
37,7,315,4,0,
37,7,336,2,0,
37,8,39,1,4,
37,8,46,1,7,
37,8,46,4,0,
37,8,50,2,0,
37,8,52,1,1,
37,8,53,1,24,
37,8,53,4,0,
37,8,83,1,34,
37,8,91,4,0,
37,8,92,4,0,
37,8,95,2,0,
37,8,98,1,11,
37,8,104,4,0,
37,8,109,1,17,
37,8,126,1,47,
37,8,126,4,0,
37,8,156,4,0,
37,8,164,4,0,
37,8,175,2,0,
37,8,180,2,0,
37,8,182,4,0,
37,8,185,2,0,
37,8,203,4,0,
37,8,207,4,0,
37,8,213,4,0,
37,8,214,4,0,
37,8,216,4,0,
37,8,218,4,0,
37,8,219,1,27,
37,8,219,4,0,
37,8,231,4,0,
37,8,237,4,0,
37,8,241,4,0,
37,8,244,2,0,
37,8,244,4,0,
37,8,257,2,0,
37,8,261,1,14,
37,8,261,4,0,
37,8,263,4,0,
37,8,286,1,21,
37,8,288,1,41,
37,8,290,4,0,
37,8,315,4,0,
37,8,326,1,44,
37,8,326,2,0,
37,8,336,2,0,
37,8,363,4,0,
37,8,371,1,31,
37,8,371,4,0,
37,8,394,2,0,
37,8,399,4,0,
37,8,412,2,0,
37,8,445,1,37,
37,8,445,4,0,
37,9,39,1,4,
37,9,46,1,7,
37,9,46,4,0,
37,9,50,2,0,
37,9,52,1,1,
37,9,53,1,24,
37,9,53,4,0,
37,9,83,1,34,
37,9,91,4,0,
37,9,92,4,0,
37,9,95,2,0,
37,9,98,1,11,
37,9,104,4,0,
37,9,109,1,17,
37,9,126,1,47,
37,9,126,4,0,
37,9,129,3,0,
37,9,156,4,0,
37,9,164,4,0,
37,9,173,3,0,
37,9,175,2,0,
37,9,180,2,0,
37,9,180,3,0,
37,9,182,4,0,
37,9,185,2,0,
37,9,203,4,0,
37,9,207,4,0,
37,9,213,4,0,
37,9,214,4,0,
37,9,216,4,0,
37,9,218,4,0,
37,9,219,1,27,
37,9,219,4,0,
37,9,231,4,0,
37,9,237,4,0,
37,9,241,4,0,
37,9,244,2,0,
37,9,244,4,0,
37,9,257,2,0,
37,9,257,3,0,
37,9,261,1,14,
37,9,261,4,0,
37,9,263,4,0,
37,9,286,1,21,
37,9,288,1,41,
37,9,290,4,0,
37,9,315,4,0,
37,9,326,1,44,
37,9,326,2,0,
37,9,336,2,0,
37,9,363,4,0,
37,9,371,1,31,
37,9,371,4,0,
37,9,394,2,0,
37,9,399,4,0,
37,9,412,2,0,
37,9,428,3,0,
37,9,445,1,37,
37,9,445,4,0,
37,9,466,3,0,
37,10,29,3,0,
37,10,39,1,4,
37,10,46,1,7,
37,10,46,4,0,
37,10,50,2,0,
37,10,52,1,1,
37,10,53,1,24,
37,10,53,4,0,
37,10,83,1,34,
37,10,91,4,0,
37,10,92,4,0,
37,10,95,2,0,
37,10,98,1,11,
37,10,104,4,0,
37,10,109,1,17,
37,10,126,1,47,
37,10,126,4,0,
37,10,129,3,0,
37,10,156,4,0,
37,10,164,4,0,
37,10,173,3,0,
37,10,175,2,0,
37,10,180,2,0,
37,10,180,3,0,
37,10,182,4,0,
37,10,185,2,0,
37,10,203,4,0,
37,10,207,4,0,
37,10,213,4,0,
37,10,214,4,0,
37,10,216,4,0,
37,10,218,4,0,
37,10,219,1,27,
37,10,219,4,0,
37,10,220,3,0,
37,10,231,4,0,
37,10,237,4,0,
37,10,241,4,0,
37,10,244,2,0,
37,10,244,4,0,
37,10,257,2,0,
37,10,257,3,0,
37,10,261,1,14,
37,10,261,4,0,
37,10,263,4,0,
37,10,272,3,0,
37,10,286,1,21,
37,10,288,1,41,
37,10,290,4,0,
37,10,315,4,0,
37,10,326,1,44,
37,10,326,2,0,
37,10,336,2,0,
37,10,363,4,0,
37,10,371,1,31,
37,10,371,4,0,
37,10,384,2,0,
37,10,394,2,0,
37,10,399,4,0,
37,10,412,2,0,
37,10,428,3,0,
37,10,445,1,37,
37,10,445,4,0,
37,10,466,3,0,
37,11,39,1,4,
37,11,46,1,7,
37,11,46,4,0,
37,11,50,2,0,
37,11,52,1,1,
37,11,53,1,37,
37,11,53,4,0,
37,11,83,1,14,
37,11,91,4,0,
37,11,92,4,0,
37,11,95,2,0,
37,11,98,1,11,
37,11,104,4,0,
37,11,109,1,17,
37,11,126,1,54,
37,11,126,4,0,
37,11,156,4,0,
37,11,164,4,0,
37,11,175,2,0,
37,11,180,2,0,
37,11,182,4,0,
37,11,185,2,0,
37,11,207,4,0,
37,11,213,4,0,
37,11,216,4,0,
37,11,218,4,0,
37,11,219,1,27,
37,11,219,4,0,
37,11,237,4,0,
37,11,241,4,0,
37,11,244,4,0,
37,11,257,2,0,
37,11,261,1,31,
37,11,261,4,0,
37,11,263,4,0,
37,11,286,1,21,
37,11,288,1,47,
37,11,290,2,0,
37,11,315,4,0,
37,11,326,1,51,
37,11,326,2,0,
37,11,336,2,0,
37,11,371,1,34,
37,11,371,4,0,
37,11,384,2,0,
37,11,394,2,0,
37,11,412,4,0,
37,11,445,1,41,
37,11,481,1,24,
37,11,488,4,0,
37,11,496,4,0,
37,11,506,2,0,
37,11,510,4,0,
37,11,517,1,44,
37,11,541,2,0,
37,12,39,1,5,
37,12,46,1,9,
37,12,46,4,0,
37,12,52,1,1,
37,12,53,1,29,
37,12,53,4,0,
37,12,83,1,41,
37,12,91,4,0,
37,12,92,4,0,
37,12,98,1,13,
37,12,104,4,0,
37,12,109,1,21,
37,12,126,4,0,
37,12,156,4,0,
37,12,182,4,0,
37,12,213,4,0,
37,12,216,4,0,
37,12,218,4,0,
37,12,219,1,33,
37,12,219,4,0,
37,12,231,4,0,
37,12,237,4,0,
37,12,241,4,0,
37,12,261,1,17,
37,12,263,4,0,
37,12,286,1,25,
37,12,288,1,37,
37,12,290,4,0,
37,12,315,4,0,
37,13,34,3,0,
37,13,38,3,0,
37,13,39,1,5,
37,13,46,1,9,
37,13,46,4,0,
37,13,52,1,1,
37,13,53,1,29,
37,13,53,4,0,
37,13,83,1,41,
37,13,91,4,0,
37,13,92,4,0,
37,13,98,1,13,
37,13,102,3,0,
37,13,104,4,0,
37,13,109,1,21,
37,13,126,4,0,
37,13,156,4,0,
37,13,164,3,0,
37,13,182,4,0,
37,13,207,3,0,
37,13,213,4,0,
37,13,216,4,0,
37,13,218,4,0,
37,13,219,1,33,
37,13,219,4,0,
37,13,231,4,0,
37,13,237,4,0,
37,13,241,4,0,
37,13,261,1,17,
37,13,263,4,0,
37,13,286,1,25,
37,13,288,1,37,
37,13,290,4,0,
37,13,315,4,0,
37,14,39,1,4,
37,14,46,1,7,
37,14,46,4,0,
37,14,50,2,0,
37,14,52,1,1,
37,14,53,1,34,
37,14,53,4,0,
37,14,83,1,12,
37,14,91,4,0,
37,14,92,4,0,
37,14,95,2,0,
37,14,98,1,10,
37,14,104,4,0,
37,14,109,1,15,
37,14,126,1,42,
37,14,126,4,0,
37,14,156,4,0,
37,14,164,4,0,
37,14,173,3,0,
37,14,175,2,0,
37,14,180,2,0,
37,14,180,3,0,
37,14,182,4,0,
37,14,185,1,20,
37,14,185,2,0,
37,14,207,4,0,
37,14,213,4,0,
37,14,214,3,0,
37,14,216,4,0,
37,14,218,4,0,
37,14,219,1,36,
37,14,219,4,0,
37,14,220,3,0,
37,14,231,3,0,
37,14,237,4,0,
37,14,241,4,0,
37,14,244,4,0,
37,14,257,2,0,
37,14,257,3,0,
37,14,261,1,26,
37,14,261,4,0,
37,14,263,4,0,
37,14,272,3,0,
37,14,286,1,18,
37,14,288,1,44,
37,14,290,2,0,
37,14,315,4,0,
37,14,326,1,39,
37,14,326,2,0,
37,14,336,2,0,
37,14,343,3,0,
37,14,371,1,31,
37,14,371,4,0,
37,14,384,2,0,
37,14,394,2,0,
37,14,399,3,0,
37,14,412,4,0,
37,14,428,3,0,
37,14,445,1,47,
37,14,481,1,23,
37,14,488,4,0,
37,14,492,3,0,
37,14,496,4,0,
37,14,506,1,28,
37,14,506,2,0,
37,14,510,4,0,
37,14,517,1,50,
37,14,541,2,0,
37,15,39,1,4,
37,15,46,1,7,
37,15,46,4,0,
37,15,50,2,0,
37,15,52,1,1,
37,15,53,1,34,
37,15,53,4,0,
37,15,83,1,12,
37,15,91,4,0,
37,15,92,4,0,
37,15,95,2,0,
37,15,98,1,10,
37,15,104,4,0,
37,15,109,1,15,
37,15,126,1,42,
37,15,126,4,0,
37,15,156,4,0,
37,15,164,4,0,
37,15,175,2,0,
37,15,180,2,0,
37,15,182,4,0,
37,15,185,1,20,
37,15,185,2,0,
37,15,207,4,0,
37,15,213,4,0,
37,15,214,4,0,
37,15,216,4,0,
37,15,218,4,0,
37,15,219,1,36,
37,15,219,4,0,
37,15,237,4,0,
37,15,241,4,0,
37,15,244,4,0,
37,15,257,2,0,
37,15,261,1,26,
37,15,261,4,0,
37,15,263,4,0,
37,15,286,1,18,
37,15,288,1,44,
37,15,290,2,0,
37,15,315,4,0,
37,15,326,1,39,
37,15,326,2,0,
37,15,336,2,0,
37,15,371,1,31,
37,15,371,4,0,
37,15,384,2,0,
37,15,394,2,0,
37,15,399,4,0,
37,15,412,4,0,
37,15,445,1,47,
37,15,445,2,0,
37,15,481,1,23,
37,15,488,4,0,
37,15,496,4,0,
37,15,506,1,28,
37,15,506,2,0,
37,15,510,4,0,
37,15,517,1,50,
37,15,541,2,0,
37,15,590,4,0,
37,15,608,1,9,
37,16,39,1,4,1
37,16,46,1,7,1
37,16,46,4,0,
37,16,50,2,0,
37,16,52,1,1,1
37,16,53,1,36,1
37,16,53,4,0,
37,16,83,1,15,1
37,16,91,4,0,
37,16,92,4,0,
37,16,95,2,0,
37,16,98,1,10,1
37,16,104,4,0,
37,16,109,1,12,1
37,16,126,1,42,1
37,16,126,4,0,
37,16,156,4,0,
37,16,164,4,0,
37,16,173,3,0,
37,16,175,2,0,
37,16,180,2,0,
37,16,180,3,0,
37,16,182,4,0,
37,16,185,1,23,1
37,16,185,2,0,
37,16,207,4,0,
37,16,213,4,0,
37,16,214,4,0,
37,16,216,4,0,
37,16,218,4,0,
37,16,219,1,34,1
37,16,219,4,0,
37,16,220,3,0,
37,16,231,3,0,
37,16,237,4,0,
37,16,241,4,0,
37,16,244,4,0,
37,16,257,2,0,
37,16,257,3,0,
37,16,261,1,20,1
37,16,261,4,0,
37,16,263,4,0,
37,16,272,3,0,
37,16,286,1,39,1
37,16,288,1,44,1
37,16,290,2,0,
37,16,290,4,0,
37,16,315,4,0,
37,16,326,1,31,1
37,16,326,2,0,
37,16,336,2,0,
37,16,343,3,0,
37,16,371,1,18,1
37,16,371,4,0,
37,16,384,2,0,
37,16,394,2,0,
37,16,399,4,0,
37,16,412,4,0,
37,16,428,3,0,
37,16,445,1,47,1
37,16,445,2,0,
37,16,481,1,28,1
37,16,488,4,0,
37,16,492,3,0,
37,16,496,4,0,
37,16,506,1,26,1
37,16,506,2,0,
37,16,510,4,0,
37,16,517,1,50,1
37,16,541,2,0,
37,16,590,4,0,
37,16,608,1,9,1
37,17,39,1,4,
37,17,46,1,7,
37,17,46,4,0,
37,17,50,2,0,
37,17,52,1,1,
37,17,53,1,36,
37,17,53,4,0,
37,17,83,1,15,
37,17,92,4,0,
37,17,95,2,0,
37,17,98,1,10,
37,17,104,4,0,
37,17,109,1,12,
37,17,126,1,42,
37,17,126,4,0,
37,17,156,4,0,
37,17,164,4,0,
37,17,175,2,0,
37,17,180,2,0,
37,17,182,4,0,
37,17,185,1,23,
37,17,185,2,0,
37,17,207,4,0,
37,17,213,4,0,
37,17,214,4,0,
37,17,216,4,0,
37,17,218,4,0,
37,17,219,1,34,
37,17,219,4,0,
37,17,237,4,0,
37,17,241,4,0,
37,17,244,4,0,
37,17,257,2,0,
37,17,261,1,20,
37,17,261,4,0,
37,17,263,4,0,
37,17,286,1,39,
37,17,288,1,44,
37,17,290,2,0,
37,17,315,4,0,
37,17,326,1,31,
37,17,326,2,0,
37,17,336,2,0,
37,17,371,1,18,
37,17,371,4,0,
37,17,384,2,0,
37,17,394,2,0,
37,17,399,4,0,
37,17,412,4,0,
37,17,445,1,47,
37,17,445,2,0,
37,17,481,1,28,
37,17,488,4,0,
37,17,496,4,0,
37,17,506,1,26,
37,17,506,2,0,
37,17,517,1,50,
37,17,541,2,0,
37,17,590,4,0,
37,17,608,1,9,
37,18,39,1,4,
37,18,46,1,7,
37,18,46,4,0,
37,18,50,2,0,
37,18,52,1,1,
37,18,53,1,36,
37,18,53,4,0,
37,18,83,1,15,
37,18,92,4,0,
37,18,95,2,0,
37,18,98,1,10,
37,18,104,4,0,
37,18,109,1,12,
37,18,126,1,42,
37,18,126,4,0,
37,18,156,4,0,
37,18,164,4,0,
37,18,175,2,0,
37,18,180,2,0,
37,18,182,4,0,
37,18,185,1,23,
37,18,185,2,0,
37,18,207,4,0,
37,18,213,4,0,
37,18,214,4,0,
37,18,216,4,0,
37,18,218,4,0,
37,18,219,1,34,
37,18,219,4,0,
37,18,237,4,0,
37,18,241,4,0,
37,18,244,4,0,
37,18,257,2,0,
37,18,261,1,20,
37,18,261,4,0,
37,18,263,4,0,
37,18,286,1,39,
37,18,288,1,44,
37,18,290,2,0,
37,18,315,4,0,
37,18,326,1,31,
37,18,326,2,0,
37,18,336,2,0,
37,18,371,1,18,
37,18,371,4,0,
37,18,384,2,0,
37,18,394,2,0,
37,18,399,4,0,
37,18,412,4,0,
37,18,445,1,47,
37,18,445,2,0,
37,18,481,1,28,
37,18,488,4,0,
37,18,496,4,0,
37,18,506,1,26,
37,18,506,2,0,
37,18,517,1,50,
37,18,541,2,0,
37,18,590,4,0,
37,18,608,1,9,
38,1,34,4,0,
38,1,36,4,0,
38,1,38,4,0,
38,1,39,1,1,2
38,1,46,1,1,4
38,1,52,1,1,1
38,1,63,4,0,
38,1,91,4,0,
38,1,92,4,0,
38,1,98,1,1,3
38,1,99,4,0,
38,1,102,4,0,
38,1,104,4,0,
38,1,115,4,0,
38,1,117,4,0,
38,1,126,4,0,
38,1,129,4,0,
38,1,130,4,0,
38,1,156,4,0,
38,1,164,4,0,
38,2,34,4,0,
38,2,36,4,0,
38,2,38,4,0,
38,2,39,1,1,2
38,2,46,1,1,4
38,2,52,1,1,1
38,2,63,4,0,
38,2,91,4,0,
38,2,92,4,0,
38,2,98,1,1,3
38,2,99,4,0,
38,2,102,4,0,
38,2,104,4,0,
38,2,115,4,0,
38,2,117,4,0,
38,2,126,4,0,
38,2,129,4,0,
38,2,130,4,0,
38,2,156,4,0,
38,2,164,4,0,
38,3,29,4,0,
38,3,46,4,0,
38,3,52,1,1,1
38,3,63,4,0,
38,3,83,1,43,
38,3,91,4,0,
38,3,92,4,0,
38,3,98,1,1,2
38,3,104,4,0,
38,3,109,1,1,3
38,3,126,4,0,
38,3,129,4,0,
38,3,156,4,0,
38,3,173,4,0,
38,3,174,4,0,
38,3,182,4,0,
38,3,203,4,0,
38,3,207,4,0,
38,3,213,4,0,
38,3,214,4,0,
38,3,216,4,0,
38,3,218,4,0,
38,3,219,1,1,4
38,3,231,4,0,
38,3,237,4,0,
38,3,241,4,0,
38,4,29,4,0,
38,4,46,4,0,
38,4,52,1,1,1
38,4,53,3,0,
38,4,63,4,0,
38,4,83,1,43,
38,4,91,4,0,
38,4,92,4,0,
38,4,98,1,1,2
38,4,104,4,0,
38,4,109,1,1,3
38,4,126,4,0,
38,4,129,4,0,
38,4,156,4,0,
38,4,173,4,0,
38,4,174,4,0,
38,4,182,4,0,
38,4,203,4,0,
38,4,207,4,0,
38,4,213,4,0,
38,4,214,4,0,
38,4,216,4,0,
38,4,218,4,0,
38,4,219,1,1,4
38,4,231,4,0,
38,4,237,4,0,
38,4,241,4,0,
38,5,46,4,0,
38,5,52,1,1,1
38,5,53,4,0,
38,5,63,4,0,
38,5,83,1,45,
38,5,91,4,0,
38,5,92,4,0,
38,5,98,1,1,2
38,5,104,4,0,
38,5,109,1,1,3
38,5,126,4,0,
38,5,156,4,0,
38,5,182,4,0,
38,5,213,4,0,
38,5,216,4,0,
38,5,218,4,0,
38,5,219,1,1,4
38,5,219,4,0,
38,5,231,4,0,
38,5,237,4,0,
38,5,241,4,0,
38,5,263,4,0,
38,5,290,4,0,
38,5,315,4,0,
38,6,34,3,0,
38,6,38,3,0,
38,6,46,4,0,
38,6,52,1,1,1
38,6,53,4,0,
38,6,63,4,0,
38,6,83,1,45,
38,6,91,4,0,
38,6,92,4,0,
38,6,98,1,1,2
38,6,102,3,0,
38,6,104,4,0,
38,6,109,1,1,3
38,6,126,4,0,
38,6,129,3,0,
38,6,156,4,0,
38,6,164,3,0,
38,6,173,3,0,
38,6,182,4,0,
38,6,203,3,0,
38,6,207,3,0,
38,6,213,4,0,
38,6,214,3,0,
38,6,216,4,0,
38,6,218,4,0,
38,6,219,1,1,4
38,6,219,4,0,
38,6,231,4,0,
38,6,237,4,0,
38,6,241,4,0,
38,6,263,4,0,
38,6,290,4,0,
38,6,315,4,0,
38,7,34,3,0,
38,7,38,3,0,
38,7,46,4,0,
38,7,52,1,1,1
38,7,53,4,0,
38,7,63,4,0,
38,7,83,1,45,
38,7,91,4,0,
38,7,92,4,0,
38,7,98,1,1,2
38,7,102,3,0,
38,7,104,4,0,
38,7,109,1,1,3
38,7,126,4,0,
38,7,156,4,0,
38,7,164,3,0,
38,7,182,4,0,
38,7,213,4,0,
38,7,216,4,0,
38,7,218,4,0,
38,7,219,1,1,4
38,7,219,4,0,
38,7,231,4,0,
38,7,237,4,0,
38,7,241,4,0,
38,7,263,4,0,
38,7,290,4,0,
38,7,315,4,0,
38,8,46,4,0,
38,8,52,1,1,2
38,8,53,4,0,
38,8,63,4,0,
38,8,76,4,0,
38,8,91,4,0,
38,8,92,4,0,
38,8,98,1,1,3
38,8,104,4,0,
38,8,109,1,1,4
38,8,126,4,0,
38,8,138,4,0,
38,8,156,4,0,
38,8,164,4,0,
38,8,182,4,0,
38,8,203,4,0,
38,8,207,4,0,
38,8,213,4,0,
38,8,214,4,0,
38,8,216,4,0,
38,8,218,4,0,
38,8,219,1,1,5
38,8,219,4,0,
38,8,231,4,0,
38,8,237,4,0,
38,8,241,4,0,
38,8,244,4,0,
38,8,261,4,0,
38,8,263,4,0,
38,8,290,4,0,
38,8,315,4,0,
38,8,347,4,0,
38,8,363,4,0,
38,8,371,4,0,
38,8,399,4,0,
38,8,416,4,0,
38,8,417,1,1,1
38,8,445,4,0,
38,9,46,4,0,
38,9,52,1,1,2
38,9,53,4,0,
38,9,63,4,0,
38,9,76,4,0,
38,9,91,4,0,
38,9,92,4,0,
38,9,98,1,1,3
38,9,104,4,0,
38,9,109,1,1,4
38,9,126,4,0,
38,9,129,3,0,
38,9,138,4,0,
38,9,156,4,0,
38,9,164,4,0,
38,9,173,3,0,
38,9,180,3,0,
38,9,182,4,0,
38,9,203,4,0,
38,9,207,4,0,
38,9,213,4,0,
38,9,214,4,0,
38,9,216,4,0,
38,9,218,4,0,
38,9,219,1,1,5
38,9,219,4,0,
38,9,231,4,0,
38,9,237,4,0,
38,9,241,4,0,
38,9,244,4,0,
38,9,257,3,0,
38,9,261,4,0,
38,9,263,4,0,
38,9,290,4,0,
38,9,315,4,0,
38,9,347,4,0,
38,9,363,4,0,
38,9,371,4,0,
38,9,399,4,0,
38,9,416,4,0,
38,9,417,1,1,1
38,9,428,3,0,
38,9,445,4,0,
38,9,466,3,0,
38,10,29,3,0,
38,10,46,4,0,
38,10,52,1,1,2
38,10,53,4,0,
38,10,63,4,0,
38,10,76,4,0,
38,10,91,4,0,
38,10,92,4,0,
38,10,98,1,1,3
38,10,104,4,0,
38,10,109,1,1,4
38,10,126,4,0,
38,10,129,3,0,
38,10,138,4,0,
38,10,156,4,0,
38,10,164,4,0,
38,10,173,3,0,
38,10,180,3,0,
38,10,182,4,0,
38,10,203,4,0,
38,10,207,4,0,
38,10,213,4,0,
38,10,214,4,0,
38,10,216,4,0,
38,10,218,4,0,
38,10,219,1,1,5
38,10,219,4,0,
38,10,220,3,0,
38,10,231,4,0,
38,10,237,4,0,
38,10,241,4,0,
38,10,244,4,0,
38,10,257,3,0,
38,10,261,4,0,
38,10,263,4,0,
38,10,272,3,0,
38,10,290,4,0,
38,10,315,4,0,
38,10,347,4,0,
38,10,363,4,0,
38,10,371,4,0,
38,10,399,4,0,
38,10,416,4,0,
38,10,417,1,1,1
38,10,428,3,0,
38,10,445,4,0,
38,10,466,3,0,
38,11,46,4,0,
38,11,52,1,1,2
38,11,53,4,0,
38,11,63,4,0,
38,11,76,4,0,
38,11,91,4,0,
38,11,92,4,0,
38,11,98,1,1,3
38,11,104,4,0,
38,11,109,1,1,4
38,11,126,4,0,
38,11,138,4,0,
38,11,156,4,0,
38,11,164,4,0,
38,11,182,4,0,
38,11,207,4,0,
38,11,213,4,0,
38,11,216,4,0,
38,11,218,4,0,
38,11,219,1,1,5
38,11,219,4,0,
38,11,237,4,0,
38,11,241,4,0,
38,11,244,4,0,
38,11,261,4,0,
38,11,263,4,0,
38,11,315,4,0,
38,11,347,4,0,
38,11,371,4,0,
38,11,412,4,0,
38,11,416,4,0,
38,11,417,1,1,1
38,11,473,4,0,
38,11,488,4,0,
38,11,496,4,0,
38,11,510,4,0,
38,12,46,4,0,
38,12,52,1,1,1
38,12,53,4,0,
38,12,63,4,0,
38,12,83,1,45,
38,12,91,4,0,
38,12,92,4,0,
38,12,98,1,1,2
38,12,104,4,0,
38,12,109,1,1,3
38,12,126,4,0,
38,12,156,4,0,
38,12,182,4,0,
38,12,213,4,0,
38,12,216,4,0,
38,12,218,4,0,
38,12,219,1,1,4
38,12,219,4,0,
38,12,231,4,0,
38,12,237,4,0,
38,12,241,4,0,
38,12,263,4,0,
38,12,290,4,0,
38,12,315,4,0,
38,13,34,3,0,
38,13,38,3,0,
38,13,46,4,0,
38,13,52,1,1,1
38,13,53,4,0,
38,13,63,4,0,
38,13,83,1,45,
38,13,91,4,0,
38,13,92,4,0,
38,13,98,1,1,2
38,13,102,3,0,
38,13,104,4,0,
38,13,109,1,1,3
38,13,126,4,0,
38,13,156,4,0,
38,13,164,3,0,
38,13,182,4,0,
38,13,207,3,0,
38,13,213,4,0,
38,13,216,4,0,
38,13,218,4,0,
38,13,219,1,1,4
38,13,219,4,0,
38,13,231,4,0,
38,13,237,4,0,
38,13,241,4,0,
38,13,263,4,0,
38,13,290,4,0,
38,13,315,4,0,
38,14,46,4,0,
38,14,52,1,1,2
38,14,53,4,0,
38,14,63,4,0,
38,14,76,4,0,
38,14,91,4,0,
38,14,92,4,0,
38,14,98,1,1,3
38,14,104,4,0,
38,14,109,1,1,4
38,14,126,4,0,
38,14,138,4,0,
38,14,156,4,0,
38,14,164,4,0,
38,14,173,3,0,
38,14,180,3,0,
38,14,182,4,0,
38,14,207,4,0,
38,14,213,4,0,
38,14,214,3,0,
38,14,216,4,0,
38,14,218,4,0,
38,14,219,1,1,5
38,14,219,4,0,
38,14,220,3,0,
38,14,231,3,0,
38,14,237,4,0,
38,14,241,4,0,
38,14,244,4,0,
38,14,257,3,0,
38,14,261,4,0,
38,14,263,4,0,
38,14,272,3,0,
38,14,315,4,0,
38,14,343,3,0,
38,14,347,4,0,
38,14,371,4,0,
38,14,399,3,0,
38,14,412,4,0,
38,14,416,4,0,
38,14,417,1,1,1
38,14,428,3,0,
38,14,473,4,0,
38,14,488,4,0,
38,14,492,3,0,
38,14,496,4,0,
38,14,510,4,0,
38,15,46,4,0,
38,15,53,1,1,3
38,15,53,4,0,
38,15,63,4,0,
38,15,76,4,0,
38,15,91,4,0,
38,15,92,4,0,
38,15,98,1,1,4
38,15,104,4,0,
38,15,109,1,1,5
38,15,126,4,0,
38,15,138,4,0,
38,15,156,4,0,
38,15,164,4,0,
38,15,182,4,0,
38,15,207,4,0,
38,15,213,4,0,
38,15,214,4,0,
38,15,216,4,0,
38,15,218,4,0,
38,15,219,1,1,6
38,15,219,4,0,
38,15,237,4,0,
38,15,241,4,0,
38,15,244,4,0,
38,15,261,4,0,
38,15,263,4,0,
38,15,286,1,1,1
38,15,315,4,0,
38,15,347,4,0,
38,15,371,4,0,
38,15,399,4,0,
38,15,412,4,0,
38,15,416,4,0,
38,15,417,1,1,2
38,15,473,4,0,
38,15,488,4,0,
38,15,496,4,0,
38,15,510,4,0,
38,15,590,4,0,
38,16,46,4,0,
38,16,53,1,1,3
38,16,53,4,0,
38,16,63,4,0,
38,16,76,4,0,
38,16,91,4,0,
38,16,92,4,0,
38,16,98,1,1,4
38,16,104,4,0,
38,16,109,1,1,5
38,16,126,4,0,
38,16,138,4,0,
38,16,156,4,0,
38,16,164,4,0,
38,16,173,3,0,
38,16,180,3,0,
38,16,182,4,0,
38,16,207,4,0,
38,16,213,4,0,
38,16,214,4,0,
38,16,216,4,0,
38,16,218,4,0,
38,16,219,1,1,6
38,16,219,4,0,
38,16,220,3,0,
38,16,231,3,0,
38,16,237,4,0,
38,16,241,4,0,
38,16,244,4,0,
38,16,257,3,0,
38,16,261,4,0,
38,16,263,4,0,
38,16,272,3,0,
38,16,286,1,1,1
38,16,290,4,0,
38,16,315,4,0,
38,16,343,3,0,
38,16,347,4,0,
38,16,371,4,0,
38,16,399,4,0,
38,16,412,4,0,
38,16,416,4,0,
38,16,417,1,1,2
38,16,428,3,0,
38,16,473,4,0,
38,16,488,4,0,
38,16,492,3,0,
38,16,496,4,0,
38,16,510,4,0,
38,16,590,4,0,
38,17,46,4,0,
38,17,53,1,1,3
38,17,53,4,0,
38,17,63,4,0,
38,17,76,4,0,
38,17,92,4,0,
38,17,98,1,1,4
38,17,104,4,0,
38,17,109,1,1,5
38,17,126,4,0,
38,17,138,4,0,
38,17,156,4,0,
38,17,164,4,0,
38,17,182,4,0,
38,17,207,4,0,
38,17,213,4,0,
38,17,214,4,0,
38,17,216,4,0,
38,17,218,4,0,
38,17,219,1,1,6
38,17,219,4,0,
38,17,237,4,0,
38,17,241,4,0,
38,17,244,4,0,
38,17,261,4,0,
38,17,263,4,0,
38,17,286,1,1,1
38,17,315,4,0,
38,17,347,4,0,
38,17,371,4,0,
38,17,399,4,0,
38,17,412,4,0,
38,17,416,4,0,
38,17,417,1,1,2
38,17,473,4,0,
38,17,488,4,0,
38,17,496,4,0,
38,17,590,4,0,
38,18,46,4,0,
38,18,53,1,1,3
38,18,53,4,0,
38,18,63,4,0,
38,18,76,4,0,
38,18,92,4,0,
38,18,98,1,1,4
38,18,104,4,0,
38,18,109,1,1,5
38,18,126,4,0,
38,18,138,4,0,
38,18,156,4,0,
38,18,164,4,0,
38,18,182,4,0,
38,18,207,4,0,
38,18,213,4,0,
38,18,214,4,0,
38,18,216,4,0,
38,18,218,4,0,
38,18,219,1,1,6
38,18,219,4,0,
38,18,237,4,0,
38,18,241,4,0,
38,18,244,4,0,
38,18,261,4,0,
38,18,263,4,0,
38,18,286,1,1,1
38,18,315,4,0,
38,18,347,4,0,
38,18,371,4,0,
38,18,399,4,0,
38,18,412,4,0,
38,18,416,4,0,
38,18,417,1,1,2
38,18,473,4,0,
38,18,488,4,0,
38,18,496,4,0,
38,18,590,4,0,
39,1,1,1,9,
39,1,3,1,24,
39,1,5,4,0,
39,1,25,4,0,
39,1,34,1,34,
39,1,34,4,0,
39,1,36,4,0,
39,1,38,1,39,
39,1,38,4,0,
39,1,47,1,1,
39,1,50,1,14,
39,1,55,4,0,
39,1,58,4,0,
39,1,59,4,0,
39,1,61,4,0,
39,1,66,4,0,
39,1,68,4,0,
39,1,69,4,0,
39,1,70,4,0,
39,1,76,4,0,
39,1,85,4,0,
39,1,86,4,0,
39,1,87,4,0,
39,1,92,4,0,
39,1,94,4,0,
39,1,99,4,0,
39,1,100,4,0,
39,1,102,4,0,
39,1,104,4,0,
39,1,111,1,19,
39,1,115,4,0,
39,1,117,4,0,
39,1,126,4,0,
39,1,130,4,0,
39,1,148,4,0,
39,1,149,4,0,
39,1,156,1,29,
39,1,156,4,0,
39,1,161,4,0,
39,1,164,4,0,
39,2,1,1,9,
39,2,3,1,24,
39,2,5,4,0,
39,2,25,4,0,
39,2,34,1,34,
39,2,34,4,0,
39,2,36,4,0,
39,2,38,1,39,
39,2,38,4,0,
39,2,47,1,1,
39,2,50,1,14,
39,2,55,4,0,
39,2,58,4,0,
39,2,59,4,0,
39,2,61,4,0,
39,2,66,4,0,
39,2,68,4,0,
39,2,69,4,0,
39,2,70,4,0,
39,2,76,4,0,
39,2,85,4,0,
39,2,86,4,0,
39,2,87,4,0,
39,2,92,4,0,
39,2,94,4,0,
39,2,99,4,0,
39,2,100,4,0,
39,2,102,4,0,
39,2,104,4,0,
39,2,111,1,19,
39,2,115,4,0,
39,2,117,4,0,
39,2,126,4,0,
39,2,130,4,0,
39,2,148,4,0,
39,2,149,4,0,
39,2,156,1,29,
39,2,156,4,0,
39,2,161,4,0,
39,2,164,4,0,
39,3,1,1,9,
39,3,3,1,24,
39,3,7,4,0,
39,3,8,4,0,
39,3,9,4,0,
39,3,29,4,0,
39,3,34,1,34,
39,3,38,1,39,
39,3,47,1,1,
39,3,50,1,14,
39,3,59,4,0,
39,3,70,4,0,
39,3,76,4,0,
39,3,87,4,0,
39,3,92,4,0,
39,3,94,4,0,
39,3,104,4,0,
39,3,111,1,4,
39,3,111,4,0,
39,3,126,4,0,
39,3,138,4,0,
39,3,148,4,0,
39,3,156,1,29,
39,3,156,4,0,
39,3,171,4,0,
39,3,173,4,0,
39,3,174,4,0,
39,3,182,4,0,
39,3,189,4,0,
39,3,192,4,0,
39,3,197,4,0,
39,3,203,4,0,
39,3,205,1,19,
39,3,205,4,0,
39,3,207,4,0,
39,3,213,4,0,
39,3,214,4,0,
39,3,216,4,0,
39,3,218,4,0,
39,3,223,4,0,
39,3,237,4,0,
39,3,240,4,0,
39,3,241,4,0,
39,3,244,4,0,
39,3,247,4,0,
39,4,1,1,9,
39,4,3,1,24,
39,4,7,4,0,
39,4,8,4,0,
39,4,9,4,0,
39,4,29,4,0,
39,4,34,1,34,
39,4,38,1,39,
39,4,47,1,1,
39,4,50,1,14,
39,4,53,3,0,
39,4,58,3,0,
39,4,59,4,0,
39,4,70,4,0,
39,4,76,4,0,
39,4,85,3,0,
39,4,87,4,0,
39,4,92,4,0,
39,4,94,4,0,
39,4,104,4,0,
39,4,111,1,4,
39,4,111,4,0,
39,4,126,4,0,
39,4,138,4,0,
39,4,148,4,0,
39,4,156,1,29,
39,4,156,4,0,
39,4,171,4,0,
39,4,173,4,0,
39,4,174,4,0,
39,4,182,4,0,
39,4,189,4,0,
39,4,192,4,0,
39,4,197,4,0,
39,4,203,4,0,
39,4,205,1,19,
39,4,205,4,0,
39,4,207,4,0,
39,4,213,4,0,
39,4,214,4,0,
39,4,216,4,0,
39,4,218,4,0,
39,4,223,4,0,
39,4,237,4,0,
39,4,240,4,0,
39,4,241,4,0,
39,4,244,4,0,
39,4,247,4,0,
39,5,1,1,9,
39,5,3,1,24,
39,5,34,1,34,
39,5,38,1,49,
39,5,47,1,1,
39,5,50,1,14,
39,5,53,4,0,
39,5,58,4,0,
39,5,59,4,0,
39,5,70,4,0,
39,5,76,4,0,
39,5,85,4,0,
39,5,87,4,0,
39,5,91,4,0,
39,5,92,4,0,
39,5,94,4,0,
39,5,102,1,39,
39,5,104,4,0,
39,5,111,1,4,
39,5,113,4,0,
39,5,115,4,0,
39,5,126,4,0,
39,5,148,4,0,
39,5,156,1,29,
39,5,156,4,0,
39,5,182,4,0,
39,5,205,1,19,
39,5,213,4,0,
39,5,216,4,0,
39,5,218,4,0,
39,5,219,4,0,
39,5,237,4,0,
39,5,240,4,0,
39,5,241,4,0,
39,5,247,4,0,
39,5,263,4,0,
39,5,264,4,0,
39,5,280,4,0,
39,5,289,4,0,
39,5,290,4,0,
39,5,304,1,44,
39,5,351,4,0,
39,5,352,4,0,
39,6,1,1,9,
39,6,3,1,24,
39,6,5,3,0,
39,6,7,3,0,
39,6,8,3,0,
39,6,9,3,0,
39,6,25,3,0,
39,6,34,1,34,
39,6,34,3,0,
39,6,38,1,49,
39,6,38,3,0,
39,6,47,1,1,
39,6,50,1,14,
39,6,53,4,0,
39,6,58,4,0,
39,6,59,4,0,
39,6,68,3,0,
39,6,69,3,0,
39,6,70,4,0,
39,6,76,4,0,
39,6,85,4,0,
39,6,86,3,0,
39,6,87,4,0,
39,6,91,4,0,
39,6,92,4,0,
39,6,94,4,0,
39,6,102,1,39,
39,6,102,3,0,
39,6,104,4,0,
39,6,111,1,4,
39,6,111,3,0,
39,6,113,4,0,
39,6,115,4,0,
39,6,126,4,0,
39,6,138,3,0,
39,6,148,4,0,
39,6,156,1,29,
39,6,156,4,0,
39,6,164,3,0,
39,6,173,3,0,
39,6,182,4,0,
39,6,189,3,0,
39,6,203,3,0,
39,6,205,1,19,
39,6,205,3,0,
39,6,207,3,0,
39,6,213,4,0,
39,6,214,3,0,
39,6,216,4,0,
39,6,218,4,0,
39,6,219,4,0,
39,6,223,3,0,
39,6,237,4,0,
39,6,240,4,0,
39,6,241,4,0,
39,6,244,3,0,
39,6,247,4,0,
39,6,263,4,0,
39,6,264,4,0,
39,6,280,4,0,
39,6,289,4,0,
39,6,290,4,0,
39,6,304,1,44,
39,6,351,4,0,
39,6,352,4,0,
39,7,1,1,9,
39,7,3,1,24,
39,7,5,3,0,
39,7,25,3,0,
39,7,34,1,34,
39,7,34,3,0,
39,7,38,1,49,
39,7,38,3,0,
39,7,47,1,1,
39,7,50,1,14,
39,7,53,4,0,
39,7,58,4,0,
39,7,59,4,0,
39,7,68,3,0,
39,7,69,3,0,
39,7,70,4,0,
39,7,76,4,0,
39,7,85,4,0,
39,7,86,3,0,
39,7,87,4,0,
39,7,91,4,0,
39,7,92,4,0,
39,7,94,4,0,
39,7,102,1,39,
39,7,102,3,0,
39,7,104,4,0,
39,7,111,1,4,
39,7,113,4,0,
39,7,115,4,0,
39,7,126,4,0,
39,7,138,3,0,
39,7,148,4,0,
39,7,156,1,29,
39,7,156,4,0,
39,7,164,3,0,
39,7,182,4,0,
39,7,205,1,19,
39,7,213,4,0,
39,7,216,4,0,
39,7,218,4,0,
39,7,219,4,0,
39,7,237,4,0,
39,7,240,4,0,
39,7,241,4,0,
39,7,247,4,0,
39,7,263,4,0,
39,7,264,4,0,
39,7,280,4,0,
39,7,289,4,0,
39,7,290,4,0,
39,7,304,1,44,
39,7,351,4,0,
39,7,352,4,0,
39,8,1,1,9,
39,8,3,1,21,
39,8,34,1,29,
39,8,38,1,49,
39,8,47,1,1,
39,8,50,1,13,
39,8,53,4,0,
39,8,58,4,0,
39,8,59,4,0,
39,8,70,4,0,
39,8,76,4,0,
39,8,85,4,0,
39,8,86,4,0,
39,8,87,4,0,
39,8,91,4,0,
39,8,92,4,0,
39,8,94,4,0,
39,8,102,1,41,
39,8,104,4,0,
39,8,111,1,5,
39,8,113,4,0,
39,8,115,4,0,
39,8,126,4,0,
39,8,138,4,0,
39,8,148,4,0,
39,8,156,1,25,
39,8,156,4,0,
39,8,164,4,0,
39,8,182,4,0,
39,8,203,4,0,
39,8,205,1,17,
39,8,207,4,0,
39,8,213,4,0,
39,8,214,4,0,
39,8,216,4,0,
39,8,218,4,0,
39,8,219,4,0,
39,8,237,4,0,
39,8,240,4,0,
39,8,241,4,0,
39,8,244,4,0,
39,8,247,4,0,
39,8,263,4,0,
39,8,264,4,0,
39,8,278,4,0,
39,8,280,4,0,
39,8,289,4,0,
39,8,290,4,0,
39,8,304,1,45,
39,8,351,4,0,
39,8,352,4,0,
39,8,358,1,37,
39,8,360,1,33,
39,8,360,4,0,
39,8,363,4,0,
39,8,374,4,0,
39,8,409,4,0,
39,8,445,4,0,
39,8,446,4,0,
39,8,447,4,0,
39,8,451,4,0,
39,9,1,1,9,
39,9,3,1,21,
39,9,7,3,0,
39,9,8,3,0,
39,9,9,3,0,
39,9,34,1,29,
39,9,38,1,49,
39,9,47,1,1,
39,9,50,1,13,
39,9,53,4,0,
39,9,58,4,0,
39,9,59,4,0,
39,9,70,4,0,
39,9,76,4,0,
39,9,85,4,0,
39,9,86,4,0,
39,9,87,4,0,
39,9,91,4,0,
39,9,92,4,0,
39,9,94,4,0,
39,9,102,1,41,
39,9,104,4,0,
39,9,111,1,5,
39,9,113,4,0,
39,9,115,4,0,
39,9,126,4,0,
39,9,138,4,0,
39,9,148,4,0,
39,9,156,1,25,
39,9,156,4,0,
39,9,164,4,0,
39,9,173,3,0,
39,9,182,4,0,
39,9,189,3,0,
39,9,196,3,0,
39,9,203,4,0,
39,9,205,1,17,
39,9,205,3,0,
39,9,207,4,0,
39,9,213,4,0,
39,9,214,4,0,
39,9,216,4,0,
39,9,218,4,0,
39,9,219,4,0,
39,9,237,4,0,
39,9,240,4,0,
39,9,241,4,0,
39,9,244,4,0,
39,9,247,4,0,
39,9,263,4,0,
39,9,264,4,0,
39,9,270,3,0,
39,9,278,4,0,
39,9,280,4,0,
39,9,282,3,0,
39,9,283,3,0,
39,9,289,4,0,
39,9,290,4,0,
39,9,304,1,45,
39,9,340,3,0,
39,9,351,4,0,
39,9,352,4,0,
39,9,358,1,37,
39,9,360,1,33,
39,9,360,4,0,
39,9,363,4,0,
39,9,374,4,0,
39,9,387,3,0,
39,9,409,4,0,
39,9,445,4,0,
39,9,446,4,0,
39,9,447,4,0,
39,9,451,4,0,
39,10,1,1,9,
39,10,3,1,21,
39,10,7,3,0,
39,10,8,3,0,
39,10,9,3,0,
39,10,29,3,0,
39,10,34,1,29,
39,10,38,1,49,
39,10,47,1,1,
39,10,50,1,13,
39,10,53,4,0,
39,10,58,4,0,
39,10,59,4,0,
39,10,70,4,0,
39,10,76,4,0,
39,10,85,4,0,
39,10,86,4,0,
39,10,87,4,0,
39,10,91,4,0,
39,10,92,4,0,
39,10,94,4,0,
39,10,102,1,41,
39,10,104,4,0,
39,10,111,1,5,
39,10,113,4,0,
39,10,115,4,0,
39,10,126,4,0,
39,10,138,4,0,
39,10,148,4,0,
39,10,156,1,25,
39,10,156,4,0,
39,10,164,4,0,
39,10,173,3,0,
39,10,182,4,0,
39,10,189,3,0,
39,10,196,3,0,
39,10,203,4,0,
39,10,205,1,17,
39,10,205,3,0,
39,10,207,4,0,
39,10,213,4,0,
39,10,214,4,0,
39,10,215,3,0,
39,10,216,4,0,
39,10,218,4,0,
39,10,219,4,0,
39,10,220,3,0,
39,10,237,4,0,
39,10,240,4,0,
39,10,241,4,0,
39,10,244,4,0,
39,10,247,4,0,
39,10,263,4,0,
39,10,264,4,0,
39,10,270,3,0,
39,10,272,3,0,
39,10,277,3,0,
39,10,278,4,0,
39,10,280,4,0,
39,10,282,3,0,
39,10,283,3,0,
39,10,289,4,0,
39,10,290,4,0,
39,10,304,1,45,
39,10,340,3,0,
39,10,351,4,0,
39,10,352,4,0,
39,10,356,3,0,
39,10,358,1,37,
39,10,360,1,33,
39,10,360,4,0,
39,10,363,4,0,
39,10,374,4,0,
39,10,387,3,0,
39,10,409,4,0,
39,10,445,4,0,
39,10,446,4,0,
39,10,447,4,0,
39,10,451,4,0,
39,11,1,1,9,
39,11,3,1,25,
39,11,34,1,33,
39,11,38,1,53,
39,11,47,1,1,
39,11,50,1,13,
39,11,53,4,0,
39,11,58,4,0,
39,11,59,4,0,
39,11,70,4,0,
39,11,76,4,0,
39,11,85,4,0,
39,11,86,4,0,
39,11,87,4,0,
39,11,91,4,0,
39,11,92,4,0,
39,11,94,4,0,
39,11,102,1,45,
39,11,104,4,0,
39,11,111,1,5,
39,11,113,4,0,
39,11,115,4,0,
39,11,126,4,0,
39,11,138,4,0,
39,11,148,4,0,
39,11,156,1,29,
39,11,156,4,0,
39,11,164,4,0,
39,11,182,4,0,
39,11,205,1,21,
39,11,207,4,0,
39,11,213,4,0,
39,11,216,4,0,
39,11,218,4,0,
39,11,219,4,0,
39,11,237,4,0,
39,11,240,4,0,
39,11,241,4,0,
39,11,244,4,0,
39,11,247,4,0,
39,11,263,4,0,
39,11,280,4,0,
39,11,304,1,49,
39,11,358,1,41,
39,11,360,1,37,
39,11,360,4,0,
39,11,374,4,0,
39,11,447,4,0,
39,11,451,4,0,
39,11,496,1,17,
39,11,496,4,0,
39,11,497,4,0,
39,11,510,4,0,
39,11,514,4,0,
39,11,526,4,0,
39,11,528,4,0,
39,12,1,1,9,
39,12,3,1,24,
39,12,34,1,34,
39,12,38,1,49,
39,12,47,1,1,
39,12,50,1,14,
39,12,53,4,0,
39,12,58,4,0,
39,12,59,4,0,
39,12,70,4,0,
39,12,76,4,0,
39,12,85,4,0,
39,12,87,4,0,
39,12,91,4,0,
39,12,92,4,0,
39,12,94,4,0,
39,12,102,1,39,
39,12,104,4,0,
39,12,111,1,4,
39,12,113,4,0,
39,12,115,4,0,
39,12,126,4,0,
39,12,148,4,0,
39,12,156,1,29,
39,12,156,4,0,
39,12,182,4,0,
39,12,205,1,19,
39,12,213,4,0,
39,12,216,4,0,
39,12,218,4,0,
39,12,219,4,0,
39,12,237,4,0,
39,12,240,4,0,
39,12,241,4,0,
39,12,247,4,0,
39,12,263,4,0,
39,12,264,4,0,
39,12,280,4,0,
39,12,289,4,0,
39,12,290,4,0,
39,12,304,1,44,
39,12,351,4,0,
39,12,352,4,0,
39,13,1,1,9,
39,13,3,1,24,
39,13,34,1,34,
39,13,34,3,0,
39,13,38,1,49,
39,13,38,3,0,
39,13,47,1,1,
39,13,50,1,14,
39,13,53,4,0,
39,13,58,4,0,
39,13,59,4,0,
39,13,69,3,0,
39,13,70,4,0,
39,13,76,4,0,
39,13,85,4,0,
39,13,86,3,0,
39,13,87,4,0,
39,13,91,4,0,
39,13,92,4,0,
39,13,94,4,0,
39,13,102,1,39,
39,13,102,3,0,
39,13,104,4,0,
39,13,111,1,4,
39,13,113,4,0,
39,13,115,4,0,
39,13,126,4,0,
39,13,138,3,0,
39,13,148,4,0,
39,13,156,1,29,
39,13,156,4,0,
39,13,164,3,0,
39,13,171,3,0,
39,13,182,4,0,
39,13,205,1,19,
39,13,207,3,0,
39,13,213,4,0,
39,13,216,4,0,
39,13,218,4,0,
39,13,219,4,0,
39,13,237,4,0,
39,13,240,4,0,
39,13,241,4,0,
39,13,247,4,0,
39,13,263,4,0,
39,13,264,4,0,
39,13,280,4,0,
39,13,289,4,0,
39,13,290,4,0,
39,13,304,1,44,
39,13,351,4,0,
39,13,352,4,0,
39,14,1,1,9,
39,14,3,1,25,
39,14,7,3,0,
39,14,8,3,0,
39,14,9,3,0,
39,14,34,1,33,
39,14,38,1,53,
39,14,47,1,1,
39,14,50,1,13,
39,14,53,4,0,
39,14,58,4,0,
39,14,59,4,0,
39,14,70,4,0,
39,14,76,4,0,
39,14,85,4,0,
39,14,86,4,0,
39,14,87,4,0,
39,14,91,4,0,
39,14,92,4,0,
39,14,94,4,0,
39,14,102,1,45,
39,14,104,4,0,
39,14,111,1,5,
39,14,113,4,0,
39,14,115,4,0,
39,14,126,4,0,
39,14,138,4,0,
39,14,148,4,0,
39,14,156,1,29,
39,14,156,4,0,
39,14,164,4,0,
39,14,173,3,0,
39,14,182,4,0,
39,14,196,3,0,
39,14,205,1,21,
39,14,207,4,0,
39,14,213,4,0,
39,14,214,3,0,
39,14,215,3,0,
39,14,216,4,0,
39,14,218,4,0,
39,14,219,4,0,
39,14,220,3,0,
39,14,237,4,0,
39,14,240,4,0,
39,14,241,4,0,
39,14,244,4,0,
39,14,247,4,0,
39,14,263,4,0,
39,14,270,3,0,
39,14,272,3,0,
39,14,277,3,0,
39,14,278,3,0,
39,14,280,4,0,
39,14,282,3,0,
39,14,283,3,0,
39,14,289,3,0,
39,14,304,1,49,
39,14,304,3,0,
39,14,340,3,0,
39,14,343,3,0,
39,14,356,3,0,
39,14,358,1,41,
39,14,360,1,37,
39,14,360,4,0,
39,14,374,4,0,
39,14,387,3,0,
39,14,409,3,0,
39,14,446,3,0,
39,14,447,4,0,
39,14,451,4,0,
39,14,496,1,17,
39,14,496,4,0,
39,14,497,4,0,
39,14,510,4,0,
39,14,514,4,0,
39,14,526,4,0,
39,14,528,4,0,
39,15,1,1,7,
39,15,3,1,25,
39,15,34,1,33,
39,15,38,1,53,
39,15,47,1,1,
39,15,50,1,13,
39,15,53,4,0,
39,15,58,4,0,
39,15,59,4,0,
39,15,70,4,0,
39,15,76,4,0,
39,15,85,4,0,
39,15,86,4,0,
39,15,87,4,0,
39,15,91,4,0,
39,15,92,4,0,
39,15,94,4,0,
39,15,102,1,45,
39,15,104,4,0,
39,15,111,1,5,
39,15,113,4,0,
39,15,115,4,0,
39,15,126,4,0,
39,15,138,4,0,
39,15,148,4,0,
39,15,156,1,29,
39,15,156,4,0,
39,15,164,4,0,
39,15,182,4,0,
39,15,205,1,21,
39,15,207,4,0,
39,15,213,4,0,
39,15,214,4,0,
39,15,216,4,0,
39,15,218,4,0,
39,15,219,4,0,
39,15,237,4,0,
39,15,240,4,0,
39,15,241,4,0,
39,15,244,4,0,
39,15,247,4,0,
39,15,263,4,0,
39,15,280,4,0,
39,15,304,1,48,
39,15,358,1,41,
39,15,360,1,37,
39,15,360,4,0,
39,15,374,4,0,
39,15,447,4,0,
39,15,451,4,0,
39,15,496,1,17,
39,15,496,4,0,
39,15,497,4,0,
39,15,510,4,0,
39,15,514,4,0,
39,15,528,4,0,
39,15,574,1,50,
39,15,589,1,10,
39,15,590,4,0,
39,15,605,4,0,
39,15,612,4,0,
39,16,1,1,5,1
39,16,3,1,18,1
39,16,7,3,0,
39,16,8,3,0,
39,16,9,3,0,
39,16,34,1,35,1
39,16,38,1,49,1
39,16,47,1,1,1
39,16,50,1,15,1
39,16,53,4,0,
39,16,58,4,0,
39,16,59,4,0,
39,16,70,4,0,
39,16,76,4,0,
39,16,85,4,0,
39,16,86,4,0,
39,16,87,4,0,
39,16,91,4,0,
39,16,92,4,0,
39,16,94,4,0,
39,16,102,1,37,1
39,16,104,4,0,
39,16,111,1,3,1
39,16,113,4,0,
39,16,115,4,0,
39,16,126,4,0,
39,16,138,4,0,
39,16,148,4,0,
39,16,156,1,32,1
39,16,156,4,0,
39,16,164,4,0,
39,16,173,3,0,
39,16,182,4,0,
39,16,196,3,0,
39,16,205,1,21,1
39,16,207,4,0,
39,16,213,4,0,
39,16,214,4,0,
39,16,215,3,0,
39,16,216,4,0,
39,16,218,4,0,
39,16,219,4,0,
39,16,220,3,0,
39,16,237,4,0,
39,16,240,4,0,
39,16,241,4,0,
39,16,244,4,0,
39,16,247,4,0,
39,16,263,4,0,
39,16,264,3,0,
39,16,270,3,0,
39,16,272,3,0,
39,16,277,3,0,
39,16,278,3,0,
39,16,280,4,0,
39,16,282,3,0,
39,16,283,3,0,
39,16,289,3,0,
39,16,290,4,0,
39,16,304,1,44,1
39,16,304,3,0,
39,16,340,3,0,
39,16,343,3,0,
39,16,351,3,0,
39,16,352,3,0,
39,16,356,3,0,
39,16,358,1,28,1
39,16,360,1,40,1
39,16,360,4,0,
39,16,374,4,0,
39,16,387,3,0,
39,16,409,3,0,
39,16,446,3,0,
39,16,447,4,0,
39,16,451,4,0,
39,16,496,1,24,1
39,16,496,4,0,
39,16,497,4,0,
39,16,510,4,0,
39,16,514,4,0,
39,16,528,4,0,
39,16,574,1,11,1
39,16,589,1,8,1
39,16,590,4,0,
39,16,605,4,0,
39,16,612,4,0,
39,17,1,1,5,
39,17,3,1,17,
39,17,34,1,32,
39,17,38,1,45,
39,17,47,1,1,
39,17,50,1,14,
39,17,53,4,0,
39,17,58,4,0,
39,17,59,4,0,
39,17,76,4,0,
39,17,85,4,0,
39,17,86,4,0,
39,17,87,4,0,
39,17,92,4,0,
39,17,94,4,0,
39,17,102,1,38,
39,17,104,4,0,
39,17,111,1,3,
39,17,113,4,0,
39,17,115,4,0,
39,17,126,4,0,
39,17,138,4,0,
39,17,156,1,30,
39,17,156,4,0,
39,17,164,4,0,
39,17,182,4,0,
39,17,205,1,20,
39,17,207,4,0,
39,17,213,4,0,
39,17,214,4,0,
39,17,216,4,0,
39,17,218,4,0,
39,17,219,4,0,
39,17,237,4,0,
39,17,240,4,0,
39,17,241,4,0,
39,17,244,4,0,
39,17,247,4,0,
39,17,254,1,25,1
39,17,255,1,25,3
39,17,256,1,25,2
39,17,263,4,0,
39,17,280,4,0,
39,17,304,1,41,
39,17,358,1,27,
39,17,360,1,35,
39,17,360,4,0,
39,17,374,4,0,
39,17,447,4,0,
39,17,451,4,0,
39,17,496,1,22,
39,17,496,4,0,
39,17,497,4,0,
39,17,526,4,0,
39,17,528,4,0,
39,17,574,1,11,
39,17,589,1,9,
39,17,590,4,0,
39,17,605,4,0,
39,18,1,1,5,
39,18,3,1,17,
39,18,34,1,32,
39,18,38,1,45,
39,18,47,1,1,
39,18,50,1,14,
39,18,53,4,0,
39,18,58,4,0,
39,18,59,4,0,
39,18,76,4,0,
39,18,85,4,0,
39,18,86,4,0,
39,18,87,4,0,
39,18,92,4,0,
39,18,94,4,0,
39,18,102,1,38,
39,18,104,4,0,
39,18,111,1,3,
39,18,113,4,0,
39,18,115,4,0,
39,18,126,4,0,
39,18,138,4,0,
39,18,156,1,30,
39,18,156,4,0,
39,18,164,4,0,
39,18,182,4,0,
39,18,205,1,20,
39,18,207,4,0,
39,18,213,4,0,
39,18,214,4,0,
39,18,216,4,0,
39,18,218,4,0,
39,18,219,4,0,
39,18,237,4,0,
39,18,240,4,0,
39,18,241,4,0,
39,18,244,4,0,
39,18,247,4,0,
39,18,254,1,25,1
39,18,255,1,25,3
39,18,256,1,25,2
39,18,263,4,0,
39,18,280,4,0,
39,18,304,1,41,
39,18,358,1,27,
39,18,360,1,35,
39,18,360,4,0,
39,18,374,4,0,
39,18,447,4,0,
39,18,451,4,0,
39,18,496,1,22,
39,18,496,4,0,
39,18,497,4,0,
39,18,526,4,0,
39,18,528,4,0,
39,18,574,1,11,
39,18,589,1,9,
39,18,590,4,0,
39,18,605,4,0,
40,1,3,1,1,4
40,1,5,4,0,
40,1,25,4,0,
40,1,34,4,0,
40,1,36,4,0,
40,1,38,4,0,
40,1,47,1,1,1
40,1,50,1,1,2
40,1,55,4,0,
40,1,58,4,0,
40,1,59,4,0,
40,1,61,4,0,
40,1,63,4,0,
40,1,66,4,0,
40,1,68,4,0,
40,1,69,4,0,
40,1,70,4,0,
40,1,76,4,0,
40,1,85,4,0,
40,1,86,4,0,
40,1,87,4,0,
40,1,92,4,0,
40,1,94,4,0,
40,1,99,4,0,
40,1,100,4,0,
40,1,102,4,0,
40,1,104,4,0,
40,1,111,1,1,3
40,1,115,4,0,
40,1,117,4,0,
40,1,126,4,0,
40,1,130,4,0,
40,1,148,4,0,
40,1,149,4,0,
40,1,156,4,0,
40,1,161,4,0,
40,1,164,4,0,
40,2,3,1,1,4
40,2,5,4,0,
40,2,25,4,0,
40,2,34,4,0,
40,2,36,4,0,
40,2,38,4,0,
40,2,47,1,1,1
40,2,50,1,1,2
40,2,55,4,0,
40,2,58,4,0,
40,2,59,4,0,
40,2,61,4,0,
40,2,63,4,0,
40,2,66,4,0,
40,2,68,4,0,
40,2,69,4,0,
40,2,70,4,0,
40,2,76,4,0,
40,2,85,4,0,
40,2,86,4,0,
40,2,87,4,0,
40,2,92,4,0,
40,2,94,4,0,
40,2,99,4,0,
40,2,100,4,0,
40,2,102,4,0,
40,2,104,4,0,
40,2,111,1,1,3
40,2,115,4,0,
40,2,117,4,0,
40,2,126,4,0,
40,2,130,4,0,
40,2,148,4,0,
40,2,149,4,0,
40,2,156,4,0,
40,2,161,4,0,
40,2,164,4,0,
40,3,3,1,1,4
40,3,7,4,0,
40,3,8,4,0,
40,3,9,4,0,
40,3,29,4,0,
40,3,47,1,1,1
40,3,50,1,1,2
40,3,59,4,0,
40,3,63,4,0,
40,3,70,4,0,
40,3,76,4,0,
40,3,87,4,0,
40,3,92,4,0,
40,3,94,4,0,
40,3,104,4,0,
40,3,111,1,1,3
40,3,111,4,0,
40,3,126,4,0,
40,3,138,4,0,
40,3,148,4,0,
40,3,156,4,0,
40,3,171,4,0,
40,3,173,4,0,
40,3,174,4,0,
40,3,182,4,0,
40,3,189,4,0,
40,3,192,4,0,
40,3,197,4,0,
40,3,203,4,0,
40,3,205,4,0,
40,3,207,4,0,
40,3,213,4,0,
40,3,214,4,0,
40,3,216,4,0,
40,3,218,4,0,
40,3,223,4,0,
40,3,237,4,0,
40,3,240,4,0,
40,3,241,4,0,
40,3,244,4,0,
40,3,247,4,0,
40,4,3,1,1,4
40,4,7,4,0,
40,4,8,4,0,
40,4,9,4,0,
40,4,29,4,0,
40,4,47,1,1,1
40,4,50,1,1,2
40,4,53,3,0,
40,4,58,3,0,
40,4,59,4,0,
40,4,63,4,0,
40,4,70,4,0,
40,4,76,4,0,
40,4,85,3,0,
40,4,87,4,0,
40,4,92,4,0,
40,4,94,4,0,
40,4,104,4,0,
40,4,111,1,1,3
40,4,111,4,0,
40,4,126,4,0,
40,4,138,4,0,
40,4,148,4,0,
40,4,156,4,0,
40,4,171,4,0,
40,4,173,4,0,
40,4,174,4,0,
40,4,182,4,0,
40,4,189,4,0,
40,4,192,4,0,
40,4,197,4,0,
40,4,203,4,0,
40,4,205,4,0,
40,4,207,4,0,
40,4,213,4,0,
40,4,214,4,0,
40,4,216,4,0,
40,4,218,4,0,
40,4,223,4,0,
40,4,237,4,0,
40,4,240,4,0,
40,4,241,4,0,
40,4,244,4,0,
40,4,247,4,0,
40,5,3,1,1,4
40,5,47,1,1,1
40,5,50,1,1,2
40,5,53,4,0,
40,5,58,4,0,
40,5,59,4,0,
40,5,63,4,0,
40,5,70,4,0,
40,5,76,4,0,
40,5,85,4,0,
40,5,87,4,0,
40,5,91,4,0,
40,5,92,4,0,
40,5,94,4,0,
40,5,104,4,0,
40,5,111,1,1,3
40,5,113,4,0,
40,5,115,4,0,
40,5,126,4,0,
40,5,148,4,0,
40,5,156,4,0,
40,5,182,4,0,
40,5,213,4,0,
40,5,216,4,0,
40,5,218,4,0,
40,5,219,4,0,
40,5,237,4,0,
40,5,240,4,0,
40,5,241,4,0,
40,5,247,4,0,
40,5,263,4,0,
40,5,264,4,0,
40,5,280,4,0,
40,5,289,4,0,
40,5,290,4,0,
40,5,351,4,0,
40,5,352,4,0,
40,6,3,1,1,4
40,6,5,3,0,
40,6,7,3,0,
40,6,8,3,0,
40,6,9,3,0,
40,6,25,3,0,
40,6,34,3,0,
40,6,38,3,0,
40,6,47,1,1,1
40,6,50,1,1,2
40,6,53,4,0,
40,6,58,4,0,
40,6,59,4,0,
40,6,63,4,0,
40,6,68,3,0,
40,6,69,3,0,
40,6,70,4,0,
40,6,76,4,0,
40,6,85,4,0,
40,6,86,3,0,
40,6,87,4,0,
40,6,91,4,0,
40,6,92,4,0,
40,6,94,4,0,
40,6,102,3,0,
40,6,104,4,0,
40,6,111,1,1,3
40,6,111,3,0,
40,6,113,4,0,
40,6,115,4,0,
40,6,126,4,0,
40,6,138,3,0,
40,6,148,4,0,
40,6,156,4,0,
40,6,164,3,0,
40,6,173,3,0,
40,6,182,4,0,
40,6,189,3,0,
40,6,203,3,0,
40,6,205,3,0,
40,6,207,3,0,
40,6,213,4,0,
40,6,214,3,0,
40,6,216,4,0,
40,6,218,4,0,
40,6,219,4,0,
40,6,223,3,0,
40,6,237,4,0,
40,6,240,4,0,
40,6,241,4,0,
40,6,244,3,0,
40,6,247,4,0,
40,6,263,4,0,
40,6,264,4,0,
40,6,280,4,0,
40,6,289,4,0,
40,6,290,4,0,
40,6,351,4,0,
40,6,352,4,0,
40,7,3,1,1,4
40,7,5,3,0,
40,7,25,3,0,
40,7,34,3,0,
40,7,38,3,0,
40,7,47,1,1,1
40,7,50,1,1,2
40,7,53,4,0,
40,7,58,4,0,
40,7,59,4,0,
40,7,63,4,0,
40,7,68,3,0,
40,7,69,3,0,
40,7,70,4,0,
40,7,76,4,0,
40,7,85,4,0,
40,7,86,3,0,
40,7,87,4,0,
40,7,91,4,0,
40,7,92,4,0,
40,7,94,4,0,
40,7,102,3,0,
40,7,104,4,0,
40,7,111,1,1,3
40,7,113,4,0,
40,7,115,4,0,
40,7,126,4,0,
40,7,138,3,0,
40,7,148,4,0,
40,7,156,4,0,
40,7,164,3,0,
40,7,182,4,0,
40,7,213,4,0,
40,7,216,4,0,
40,7,218,4,0,
40,7,219,4,0,
40,7,237,4,0,
40,7,240,4,0,
40,7,241,4,0,
40,7,247,4,0,
40,7,263,4,0,
40,7,264,4,0,
40,7,280,4,0,
40,7,289,4,0,
40,7,290,4,0,
40,7,351,4,0,
40,7,352,4,0,
40,8,3,1,1,4
40,8,47,1,1,1
40,8,50,1,1,2
40,8,53,4,0,
40,8,58,4,0,
40,8,59,4,0,
40,8,63,4,0,
40,8,70,4,0,
40,8,76,4,0,
40,8,85,4,0,
40,8,86,4,0,
40,8,87,4,0,
40,8,91,4,0,
40,8,92,4,0,
40,8,94,4,0,
40,8,104,4,0,
40,8,111,1,1,3
40,8,113,4,0,
40,8,115,4,0,
40,8,126,4,0,
40,8,138,4,0,
40,8,148,4,0,
40,8,156,4,0,
40,8,164,4,0,
40,8,182,4,0,
40,8,203,4,0,
40,8,207,4,0,
40,8,213,4,0,
40,8,214,4,0,
40,8,216,4,0,
40,8,218,4,0,
40,8,219,4,0,
40,8,237,4,0,
40,8,240,4,0,
40,8,241,4,0,
40,8,244,4,0,
40,8,247,4,0,
40,8,263,4,0,
40,8,264,4,0,
40,8,278,4,0,
40,8,280,4,0,
40,8,289,4,0,
40,8,290,4,0,
40,8,351,4,0,
40,8,352,4,0,
40,8,360,4,0,
40,8,363,4,0,
40,8,374,4,0,
40,8,409,4,0,
40,8,411,4,0,
40,8,416,4,0,
40,8,445,4,0,
40,8,446,4,0,
40,8,447,4,0,
40,8,451,4,0,
40,9,3,1,1,4
40,9,7,3,0,
40,9,8,3,0,
40,9,9,3,0,
40,9,47,1,1,1
40,9,50,1,1,2
40,9,53,4,0,
40,9,58,4,0,
40,9,59,4,0,
40,9,63,4,0,
40,9,70,4,0,
40,9,76,4,0,
40,9,85,4,0,
40,9,86,4,0,
40,9,87,4,0,
40,9,91,4,0,
40,9,92,4,0,
40,9,94,4,0,
40,9,104,4,0,
40,9,111,1,1,3
40,9,113,4,0,
40,9,115,4,0,
40,9,126,4,0,
40,9,138,4,0,
40,9,148,4,0,
40,9,156,4,0,
40,9,164,4,0,
40,9,173,3,0,
40,9,182,4,0,
40,9,189,3,0,
40,9,196,3,0,
40,9,203,4,0,
40,9,205,3,0,
40,9,207,4,0,
40,9,213,4,0,
40,9,214,4,0,
40,9,216,4,0,
40,9,218,4,0,
40,9,219,4,0,
40,9,237,4,0,
40,9,240,4,0,
40,9,241,4,0,
40,9,244,4,0,
40,9,247,4,0,
40,9,263,4,0,
40,9,264,4,0,
40,9,270,3,0,
40,9,278,4,0,
40,9,280,4,0,
40,9,282,3,0,
40,9,283,3,0,
40,9,289,4,0,
40,9,290,4,0,
40,9,340,3,0,
40,9,351,4,0,
40,9,352,4,0,
40,9,360,4,0,
40,9,363,4,0,
40,9,374,4,0,
40,9,387,3,0,
40,9,409,4,0,
40,9,411,4,0,
40,9,416,4,0,
40,9,445,4,0,
40,9,446,4,0,
40,9,447,4,0,
40,9,451,4,0,
40,10,3,1,1,4
40,10,7,3,0,
40,10,8,3,0,
40,10,9,3,0,
40,10,29,3,0,
40,10,47,1,1,1
40,10,50,1,1,2
40,10,53,4,0,
40,10,58,4,0,
40,10,59,4,0,
40,10,63,4,0,
40,10,70,4,0,
40,10,76,4,0,
40,10,85,4,0,
40,10,86,4,0,
40,10,87,4,0,
40,10,91,4,0,
40,10,92,4,0,
40,10,94,4,0,
40,10,104,4,0,
40,10,111,1,1,3
40,10,113,4,0,
40,10,115,4,0,
40,10,126,4,0,
40,10,138,4,0,
40,10,148,4,0,
40,10,156,4,0,
40,10,164,4,0,
40,10,173,3,0,
40,10,182,4,0,
40,10,189,3,0,
40,10,196,3,0,
40,10,203,4,0,
40,10,205,3,0,
40,10,207,4,0,
40,10,213,4,0,
40,10,214,4,0,
40,10,215,3,0,
40,10,216,4,0,
40,10,218,4,0,
40,10,219,4,0,
40,10,220,3,0,
40,10,237,4,0,
40,10,240,4,0,
40,10,241,4,0,
40,10,244,4,0,
40,10,247,4,0,
40,10,263,4,0,
40,10,264,4,0,
40,10,270,3,0,
40,10,272,3,0,
40,10,277,3,0,
40,10,278,4,0,
40,10,280,4,0,
40,10,282,3,0,
40,10,283,3,0,
40,10,289,4,0,
40,10,290,4,0,
40,10,340,3,0,
40,10,351,4,0,
40,10,352,4,0,
40,10,356,3,0,
40,10,360,4,0,
40,10,363,4,0,
40,10,374,4,0,
40,10,387,3,0,
40,10,409,4,0,
40,10,411,4,0,
40,10,416,4,0,
40,10,445,4,0,
40,10,446,4,0,
40,10,447,4,0,
40,10,451,4,0,
40,11,3,1,1,4
40,11,47,1,1,1
40,11,50,1,1,2
40,11,53,4,0,
40,11,58,4,0,
40,11,59,4,0,
40,11,63,4,0,
40,11,70,4,0,
40,11,76,4,0,
40,11,85,4,0,
40,11,86,4,0,
40,11,87,4,0,
40,11,91,4,0,
40,11,92,4,0,
40,11,94,4,0,
40,11,104,4,0,
40,11,111,1,1,3
40,11,113,4,0,
40,11,115,4,0,
40,11,126,4,0,
40,11,138,4,0,
40,11,148,4,0,
40,11,156,4,0,
40,11,164,4,0,
40,11,182,4,0,
40,11,207,4,0,
40,11,213,4,0,
40,11,216,4,0,
40,11,218,4,0,
40,11,219,4,0,
40,11,237,4,0,
40,11,240,4,0,
40,11,241,4,0,
40,11,244,4,0,
40,11,247,4,0,
40,11,263,4,0,
40,11,280,4,0,
40,11,360,4,0,
40,11,374,4,0,
40,11,411,4,0,
40,11,416,4,0,
40,11,447,4,0,
40,11,451,4,0,
40,11,496,4,0,
40,11,497,4,0,
40,11,510,4,0,
40,11,514,4,0,
40,11,526,4,0,
40,11,528,4,0,
40,12,3,1,1,4
40,12,47,1,1,1
40,12,50,1,1,2
40,12,53,4,0,
40,12,58,4,0,
40,12,59,4,0,
40,12,63,4,0,
40,12,70,4,0,
40,12,76,4,0,
40,12,85,4,0,
40,12,87,4,0,
40,12,91,4,0,
40,12,92,4,0,
40,12,94,4,0,
40,12,104,4,0,
40,12,111,1,1,3
40,12,113,4,0,
40,12,115,4,0,
40,12,126,4,0,
40,12,148,4,0,
40,12,156,4,0,
40,12,182,4,0,
40,12,213,4,0,
40,12,216,4,0,
40,12,218,4,0,
40,12,219,4,0,
40,12,237,4,0,
40,12,240,4,0,
40,12,241,4,0,
40,12,247,4,0,
40,12,263,4,0,
40,12,264,4,0,
40,12,280,4,0,
40,12,289,4,0,
40,12,290,4,0,
40,12,351,4,0,
40,12,352,4,0,
40,13,3,1,1,4
40,13,34,3,0,
40,13,38,3,0,
40,13,47,1,1,1
40,13,50,1,1,2
40,13,53,4,0,
40,13,58,4,0,
40,13,59,4,0,
40,13,63,4,0,
40,13,69,3,0,
40,13,70,4,0,
40,13,76,4,0,
40,13,85,4,0,
40,13,86,3,0,
40,13,87,4,0,
40,13,91,4,0,
40,13,92,4,0,
40,13,94,4,0,
40,13,102,3,0,
40,13,104,4,0,
40,13,111,1,1,3
40,13,113,4,0,
40,13,115,4,0,
40,13,126,4,0,
40,13,138,3,0,
40,13,148,4,0,
40,13,156,4,0,
40,13,164,3,0,
40,13,171,3,0,
40,13,182,4,0,
40,13,207,3,0,
40,13,213,4,0,
40,13,216,4,0,
40,13,218,4,0,
40,13,219,4,0,
40,13,237,4,0,
40,13,240,4,0,
40,13,241,4,0,
40,13,247,4,0,
40,13,263,4,0,
40,13,264,4,0,
40,13,280,4,0,
40,13,289,4,0,
40,13,290,4,0,
40,13,351,4,0,
40,13,352,4,0,
40,14,3,1,1,4
40,14,7,3,0,
40,14,8,3,0,
40,14,9,3,0,
40,14,47,1,1,1
40,14,50,1,1,2
40,14,53,4,0,
40,14,58,4,0,
40,14,59,4,0,
40,14,63,4,0,
40,14,70,4,0,
40,14,76,4,0,
40,14,85,4,0,
40,14,86,4,0,
40,14,87,4,0,
40,14,91,4,0,
40,14,92,4,0,
40,14,94,4,0,
40,14,104,4,0,
40,14,111,1,1,3
40,14,113,4,0,
40,14,115,4,0,
40,14,126,4,0,
40,14,138,4,0,
40,14,148,4,0,
40,14,156,4,0,
40,14,164,4,0,
40,14,173,3,0,
40,14,182,4,0,
40,14,196,3,0,
40,14,207,4,0,
40,14,213,4,0,
40,14,214,3,0,
40,14,215,3,0,
40,14,216,4,0,
40,14,218,4,0,
40,14,219,4,0,
40,14,220,3,0,
40,14,237,4,0,
40,14,240,4,0,
40,14,241,4,0,
40,14,244,4,0,
40,14,247,4,0,
40,14,263,4,0,
40,14,270,3,0,
40,14,272,3,0,
40,14,277,3,0,
40,14,278,3,0,
40,14,280,4,0,
40,14,282,3,0,
40,14,283,3,0,
40,14,289,3,0,
40,14,304,3,0,
40,14,340,3,0,
40,14,343,3,0,
40,14,356,3,0,
40,14,360,4,0,
40,14,374,4,0,
40,14,387,3,0,
40,14,409,3,0,
40,14,411,4,0,
40,14,416,4,0,
40,14,446,3,0,
40,14,447,4,0,
40,14,451,4,0,
40,14,478,3,0,
40,14,496,4,0,
40,14,497,4,0,
40,14,510,4,0,
40,14,514,4,0,
40,14,526,4,0,
40,14,528,4,0,
40,15,3,1,1,6
40,15,38,1,1,1
40,15,47,1,1,3
40,15,50,1,1,4
40,15,53,4,0,
40,15,58,4,0,
40,15,59,4,0,
40,15,63,4,0,
40,15,70,4,0,
40,15,76,4,0,
40,15,85,4,0,
40,15,86,4,0,
40,15,87,4,0,
40,15,91,4,0,
40,15,92,4,0,
40,15,94,4,0,
40,15,104,4,0,
40,15,111,1,1,5
40,15,113,4,0,
40,15,115,4,0,
40,15,126,4,0,
40,15,138,4,0,
40,15,148,4,0,
40,15,156,4,0,
40,15,164,4,0,
40,15,182,4,0,
40,15,207,4,0,
40,15,213,4,0,
40,15,214,4,0,
40,15,216,4,0,
40,15,218,4,0,
40,15,219,4,0,
40,15,237,4,0,
40,15,240,4,0,
40,15,241,4,0,
40,15,244,4,0,
40,15,247,4,0,
40,15,263,4,0,
40,15,280,4,0,
40,15,360,4,0,
40,15,374,4,0,
40,15,411,4,0,
40,15,416,4,0,
40,15,447,4,0,
40,15,451,4,0,
40,15,496,4,0,
40,15,497,4,0,
40,15,510,4,0,
40,15,514,4,0,
40,15,528,4,0,
40,15,583,1,1,2
40,15,590,4,0,
40,15,605,4,0,
40,15,612,4,0,
40,16,3,1,1,6
40,16,7,3,0,
40,16,8,3,0,
40,16,9,3,0,
40,16,38,1,1,1
40,16,47,1,1,3
40,16,50,1,1,5
40,16,53,4,0,
40,16,58,4,0,
40,16,59,4,0,
40,16,63,4,0,
40,16,70,4,0,
40,16,76,4,0,
40,16,85,4,0,
40,16,86,4,0,
40,16,87,4,0,
40,16,91,4,0,
40,16,92,4,0,
40,16,94,4,0,
40,16,104,4,0,
40,16,111,1,1,4
40,16,113,4,0,
40,16,115,4,0,
40,16,126,4,0,
40,16,138,4,0,
40,16,148,4,0,
40,16,156,4,0,
40,16,164,4,0,
40,16,173,3,0,
40,16,182,4,0,
40,16,196,3,0,
40,16,207,4,0,
40,16,213,4,0,
40,16,214,4,0,
40,16,215,3,0,
40,16,216,4,0,
40,16,218,4,0,
40,16,219,4,0,
40,16,220,3,0,
40,16,237,4,0,
40,16,240,4,0,
40,16,241,4,0,
40,16,244,4,0,
40,16,247,4,0,
40,16,263,4,0,
40,16,264,3,0,
40,16,270,3,0,
40,16,272,3,0,
40,16,277,3,0,
40,16,278,3,0,
40,16,280,4,0,
40,16,282,3,0,
40,16,283,3,0,
40,16,289,3,0,
40,16,290,4,0,
40,16,304,3,0,
40,16,340,3,0,
40,16,343,3,0,
40,16,351,3,0,
40,16,352,3,0,
40,16,356,3,0,
40,16,360,4,0,
40,16,374,4,0,
40,16,387,3,0,
40,16,409,3,0,
40,16,411,4,0,
40,16,416,4,0,
40,16,446,3,0,
40,16,447,4,0,
40,16,451,4,0,
40,16,478,3,0,
40,16,496,4,0,
40,16,497,4,0,
40,16,510,4,0,
40,16,514,4,0,
40,16,528,4,0,
40,16,583,1,1,2
40,16,590,4,0,
40,16,605,4,0,
40,16,612,4,0,
40,17,3,1,1,6
40,17,38,1,1,1
40,17,47,1,1,3
40,17,50,1,1,5
40,17,53,4,0,
40,17,58,4,0,
40,17,59,4,0,
40,17,63,4,0,
40,17,76,4,0,
40,17,85,4,0,
40,17,86,4,0,
40,17,87,4,0,
40,17,92,4,0,
40,17,94,4,0,
40,17,104,4,0,
40,17,111,1,1,4
40,17,113,4,0,
40,17,115,4,0,
40,17,126,4,0,
40,17,138,4,0,
40,17,156,4,0,
40,17,164,4,0,
40,17,182,4,0,
40,17,207,4,0,
40,17,213,4,0,
40,17,214,4,0,
40,17,216,4,0,
40,17,218,4,0,
40,17,219,4,0,
40,17,237,4,0,
40,17,240,4,0,
40,17,241,4,0,
40,17,244,4,0,
40,17,247,4,0,
40,17,263,4,0,
40,17,280,4,0,
40,17,360,4,0,
40,17,374,4,0,
40,17,411,4,0,
40,17,416,4,0,
40,17,447,4,0,
40,17,451,4,0,
40,17,496,4,0,
40,17,497,4,0,
40,17,526,4,0,
40,17,528,4,0,
40,17,583,1,1,2
40,17,590,4,0,
40,17,605,4,0,
40,18,3,1,1,6
40,18,38,1,1,1
40,18,47,1,1,3
40,18,50,1,1,5
40,18,53,4,0,
40,18,58,4,0,
40,18,59,4,0,
40,18,63,4,0,
40,18,76,4,0,
40,18,85,4,0,
40,18,86,4,0,
40,18,87,4,0,
40,18,92,4,0,
40,18,94,4,0,
40,18,104,4,0,
40,18,111,1,1,4
40,18,113,4,0,
40,18,115,4,0,
40,18,126,4,0,
40,18,138,4,0,
40,18,156,4,0,
40,18,164,4,0,
40,18,182,4,0,
40,18,207,4,0,
40,18,213,4,0,
40,18,214,4,0,
40,18,216,4,0,
40,18,218,4,0,
40,18,219,4,0,
40,18,237,4,0,
40,18,240,4,0,
40,18,241,4,0,
40,18,244,4,0,
40,18,247,4,0,
40,18,263,4,0,
40,18,280,4,0,
40,18,360,4,0,
40,18,374,4,0,
40,18,411,4,0,
40,18,416,4,0,
40,18,447,4,0,
40,18,451,4,0,
40,18,496,4,0,
40,18,497,4,0,
40,18,526,4,0,
40,18,528,4,0,
40,18,583,1,1,2
40,18,590,4,0,
40,18,605,4,0,
41,1,13,4,0,
41,1,17,1,28,
41,1,18,4,0,
41,1,36,4,0,
41,1,38,4,0,
41,1,44,1,15,
41,1,48,1,10,
41,1,72,4,0,
41,1,92,4,0,
41,1,99,4,0,
41,1,102,4,0,
41,1,104,4,0,
41,1,109,1,21,
41,1,114,1,36,
41,1,117,4,0,
41,1,129,4,0,
41,1,141,1,1,
41,1,156,4,0,
41,1,164,4,0,
41,2,13,4,0,
41,2,17,1,28,
41,2,18,4,0,
41,2,36,4,0,
41,2,38,4,0,
41,2,44,1,15,
41,2,48,1,10,
41,2,72,4,0,
41,2,92,4,0,
41,2,99,4,0,
41,2,102,4,0,
41,2,104,4,0,
41,2,109,1,21,
41,2,114,1,36,
41,2,117,4,0,
41,2,129,4,0,
41,2,141,1,1,
41,2,156,4,0,
41,2,164,4,0,
41,3,16,2,0,
41,3,17,1,27,
41,3,18,2,0,
41,3,44,1,12,
41,3,48,1,6,
41,3,92,4,0,
41,3,98,2,0,
41,3,104,4,0,
41,3,109,1,19,
41,3,114,1,46,
41,3,129,4,0,
41,3,141,1,1,
41,3,156,4,0,
41,3,168,4,0,
41,3,173,4,0,
41,3,174,4,0,
41,3,182,4,0,
41,3,185,2,0,
41,3,197,4,0,
41,3,202,4,0,
41,3,203,4,0,
41,3,207,4,0,
41,3,211,4,0,
41,3,212,1,36,
41,3,213,4,0,
41,3,214,4,0,
41,3,216,4,0,
41,3,218,4,0,
41,3,228,2,0,
41,3,237,4,0,
41,3,241,4,0,
41,4,16,2,0,
41,4,17,1,27,
41,4,18,2,0,
41,4,44,1,12,
41,4,48,1,6,
41,4,92,4,0,
41,4,98,2,0,
41,4,104,4,0,
41,4,109,1,19,
41,4,114,1,46,
41,4,129,4,0,
41,4,141,1,1,
41,4,156,4,0,
41,4,168,4,0,
41,4,173,4,0,
41,4,174,4,0,
41,4,182,4,0,
41,4,185,2,0,
41,4,197,4,0,
41,4,202,4,0,
41,4,203,4,0,
41,4,207,4,0,
41,4,211,4,0,
41,4,212,1,36,
41,4,213,4,0,
41,4,214,4,0,
41,4,216,4,0,
41,4,218,4,0,
41,4,228,2,0,
41,4,237,4,0,
41,4,241,4,0,
41,5,16,2,0,
41,5,17,1,21,
41,5,18,2,0,
41,5,44,1,16,
41,5,48,1,6,
41,5,92,4,0,
41,5,98,2,0,
41,5,104,4,0,
41,5,109,1,26,
41,5,114,1,46,
41,5,141,1,1,
41,5,156,4,0,
41,5,168,4,0,
41,5,174,2,0,
41,5,182,4,0,
41,5,185,2,0,
41,5,188,4,0,
41,5,202,4,0,
41,5,211,4,0,
41,5,212,1,36,
41,5,213,4,0,
41,5,216,4,0,
41,5,218,4,0,
41,5,228,2,0,
41,5,237,4,0,
41,5,240,4,0,
41,5,241,4,0,
41,5,247,4,0,
41,5,259,4,0,
41,5,263,4,0,
41,5,269,4,0,
41,5,289,4,0,
41,5,290,4,0,
41,5,305,1,41,
41,5,310,1,11,
41,5,314,1,31,
41,5,332,4,0,
41,6,16,2,0,
41,6,17,1,21,
41,6,18,2,0,
41,6,38,3,0,
41,6,44,1,16,
41,6,48,1,6,
41,6,92,4,0,
41,6,98,2,0,
41,6,102,3,0,
41,6,104,4,0,
41,6,109,1,26,
41,6,114,1,46,
41,6,129,3,0,
41,6,141,1,1,
41,6,156,4,0,
41,6,164,3,0,
41,6,168,4,0,
41,6,173,3,0,
41,6,174,2,0,
41,6,182,4,0,
41,6,185,2,0,
41,6,188,4,0,
41,6,202,4,0,
41,6,203,3,0,
41,6,207,3,0,
41,6,211,4,0,
41,6,212,1,36,
41,6,213,4,0,
41,6,214,3,0,
41,6,216,4,0,
41,6,218,4,0,
41,6,228,2,0,
41,6,237,4,0,
41,6,240,4,0,
41,6,241,4,0,
41,6,247,4,0,
41,6,259,4,0,
41,6,263,4,0,
41,6,269,4,0,
41,6,289,4,0,
41,6,290,4,0,
41,6,305,1,41,
41,6,310,1,11,
41,6,314,1,31,
41,6,332,4,0,
41,7,16,2,0,
41,7,17,1,21,
41,7,18,2,0,
41,7,38,3,0,
41,7,44,1,16,
41,7,48,1,11,
41,7,92,4,0,
41,7,98,2,0,
41,7,102,3,0,
41,7,104,4,0,
41,7,109,1,26,
41,7,114,1,46,
41,7,141,1,1,
41,7,156,4,0,
41,7,164,3,0,
41,7,168,4,0,
41,7,174,2,0,
41,7,182,4,0,
41,7,185,2,0,
41,7,188,4,0,
41,7,202,4,0,
41,7,211,4,0,
41,7,212,1,36,
41,7,213,4,0,
41,7,216,4,0,
41,7,218,4,0,
41,7,228,2,0,
41,7,237,4,0,
41,7,240,4,0,
41,7,241,4,0,
41,7,247,4,0,
41,7,259,4,0,
41,7,263,4,0,
41,7,269,4,0,
41,7,289,4,0,
41,7,290,4,0,
41,7,305,1,41,
41,7,310,1,6,
41,7,314,1,31,
41,7,332,4,0,
41,8,16,2,0,
41,8,17,1,17,
41,8,18,2,0,
41,8,19,4,0,
41,8,44,1,13,
41,8,48,1,5,
41,8,92,4,0,
41,8,95,2,0,
41,8,98,2,0,
41,8,104,4,0,
41,8,109,1,21,
41,8,114,1,37,
41,8,141,1,1,
41,8,156,4,0,
41,8,164,4,0,
41,8,168,4,0,
41,8,174,2,0,
41,8,182,4,0,
41,8,185,2,0,
41,8,188,4,0,
41,8,202,4,0,
41,8,203,4,0,
41,8,207,4,0,
41,8,211,4,0,
41,8,212,1,29,
41,8,213,4,0,
41,8,214,4,0,
41,8,216,4,0,
41,8,218,4,0,
41,8,228,2,0,
41,8,237,4,0,
41,8,240,4,0,
41,8,241,4,0,
41,8,247,4,0,
41,8,259,4,0,
41,8,263,4,0,
41,8,269,4,0,
41,8,289,4,0,
41,8,290,4,0,
41,8,305,1,33,
41,8,310,1,9,
41,8,314,1,25,
41,8,332,4,0,
41,8,355,4,0,
41,8,363,4,0,
41,8,365,4,0,
41,8,369,4,0,
41,8,371,4,0,
41,8,403,1,41,
41,8,413,2,0,
41,8,417,2,0,
41,8,428,2,0,
41,8,432,4,0,
41,8,445,4,0,
41,9,16,2,0,
41,9,17,1,17,
41,9,18,2,0,
41,9,19,4,0,
41,9,44,1,13,
41,9,48,1,5,
41,9,92,4,0,
41,9,95,2,0,
41,9,98,2,0,
41,9,104,4,0,
41,9,109,1,21,
41,9,114,1,37,
41,9,129,3,0,
41,9,141,1,1,
41,9,156,4,0,
41,9,164,4,0,
41,9,168,4,0,
41,9,173,3,0,
41,9,174,2,0,
41,9,182,4,0,
41,9,185,2,0,
41,9,188,4,0,
41,9,202,4,0,
41,9,203,4,0,
41,9,207,4,0,
41,9,211,4,0,
41,9,212,1,29,
41,9,213,4,0,
41,9,214,4,0,
41,9,216,4,0,
41,9,218,4,0,
41,9,228,2,0,
41,9,237,4,0,
41,9,239,3,0,
41,9,240,4,0,
41,9,241,4,0,
41,9,247,4,0,
41,9,253,3,0,
41,9,257,3,0,
41,9,259,4,0,
41,9,263,4,0,
41,9,269,4,0,
41,9,289,4,0,
41,9,290,4,0,
41,9,305,1,33,
41,9,310,1,9,
41,9,314,1,25,
41,9,314,3,0,
41,9,332,4,0,
41,9,355,4,0,
41,9,363,4,0,
41,9,365,4,0,
41,9,369,4,0,
41,9,371,4,0,
41,9,403,1,41,
41,9,413,2,0,
41,9,417,2,0,
41,9,428,2,0,
41,9,428,3,0,
41,9,432,4,0,
41,9,445,4,0,
41,9,466,3,0,
41,10,16,2,0,
41,10,17,1,17,
41,10,18,2,0,
41,10,19,4,0,
41,10,44,1,13,
41,10,48,1,5,
41,10,92,4,0,
41,10,95,2,0,
41,10,98,2,0,
41,10,104,4,0,
41,10,109,1,21,
41,10,114,1,37,
41,10,129,3,0,
41,10,141,1,1,
41,10,156,4,0,
41,10,162,3,0,
41,10,164,4,0,
41,10,168,4,0,
41,10,173,3,0,
41,10,174,2,0,
41,10,182,4,0,
41,10,185,2,0,
41,10,188,4,0,
41,10,202,4,0,
41,10,203,4,0,
41,10,207,4,0,
41,10,211,4,0,
41,10,212,1,29,
41,10,213,4,0,
41,10,214,4,0,
41,10,216,4,0,
41,10,218,4,0,
41,10,228,2,0,
41,10,237,4,0,
41,10,239,3,0,
41,10,240,4,0,
41,10,241,4,0,
41,10,247,4,0,
41,10,253,3,0,
41,10,257,3,0,
41,10,259,4,0,
41,10,263,4,0,
41,10,269,4,0,
41,10,289,4,0,
41,10,290,4,0,
41,10,305,1,33,
41,10,310,1,9,
41,10,314,1,25,
41,10,314,3,0,
41,10,332,4,0,
41,10,355,4,0,
41,10,363,4,0,
41,10,365,4,0,
41,10,366,3,0,
41,10,369,4,0,
41,10,371,4,0,
41,10,403,1,41,
41,10,413,2,0,
41,10,417,2,0,
41,10,428,2,0,
41,10,428,3,0,
41,10,445,4,0,
41,10,466,3,0,
41,11,16,2,0,
41,11,17,1,17,
41,11,18,2,0,
41,11,19,4,0,
41,11,44,1,13,
41,11,48,1,5,
41,11,92,4,0,
41,11,95,2,0,
41,11,98,2,0,
41,11,104,4,0,
41,11,109,1,21,
41,11,114,1,41,
41,11,141,1,1,
41,11,156,4,0,
41,11,164,4,0,
41,11,168,4,0,
41,11,174,2,0,
41,11,182,4,0,
41,11,185,2,0,
41,11,188,4,0,
41,11,202,2,0,
41,11,207,4,0,
41,11,211,2,0,
41,11,212,1,29,
41,11,213,4,0,
41,11,216,4,0,
41,11,218,4,0,
41,11,228,2,0,
41,11,237,4,0,
41,11,240,4,0,
41,11,241,4,0,
41,11,247,4,0,
41,11,259,4,0,
41,11,263,4,0,
41,11,269,4,0,
41,11,305,1,37,
41,11,310,1,9,
41,11,314,1,25,
41,11,332,4,0,
41,11,365,4,0,
41,11,369,4,0,
41,11,371,4,0,
41,11,403,1,45,
41,11,413,2,0,
41,11,417,2,0,
41,11,428,2,0,
41,11,432,2,0,
41,11,474,4,0,
41,11,496,4,0,
41,11,512,1,33,
41,11,512,4,0,
41,12,17,1,21,
41,12,44,1,16,
41,12,48,1,6,
41,12,92,4,0,
41,12,104,4,0,
41,12,109,1,26,
41,12,114,1,46,
41,12,141,1,1,
41,12,156,4,0,
41,12,168,4,0,
41,12,182,4,0,
41,12,188,4,0,
41,12,202,4,0,
41,12,211,4,0,
41,12,212,1,36,
41,12,213,4,0,
41,12,216,4,0,
41,12,218,4,0,
41,12,237,4,0,
41,12,240,4,0,
41,12,241,4,0,
41,12,247,4,0,
41,12,259,4,0,
41,12,263,4,0,
41,12,269,4,0,
41,12,289,4,0,
41,12,290,4,0,
41,12,305,1,41,
41,12,310,1,11,
41,12,314,1,31,
41,12,332,4,0,
41,13,17,1,21,
41,13,38,3,0,
41,13,44,1,16,
41,13,48,1,6,
41,13,92,4,0,
41,13,102,3,0,
41,13,104,4,0,
41,13,109,1,26,
41,13,114,1,46,
41,13,141,1,1,
41,13,156,4,0,
41,13,164,3,0,
41,13,168,4,0,
41,13,182,4,0,
41,13,188,4,0,
41,13,202,4,0,
41,13,207,3,0,
41,13,211,4,0,
41,13,212,1,36,
41,13,213,4,0,
41,13,216,4,0,
41,13,218,4,0,
41,13,237,4,0,
41,13,240,4,0,
41,13,241,4,0,
41,13,247,4,0,
41,13,259,4,0,
41,13,263,4,0,
41,13,269,4,0,
41,13,289,4,0,
41,13,290,4,0,
41,13,305,1,41,
41,13,310,1,11,
41,13,314,1,31,
41,13,332,4,0,
41,14,16,2,0,
41,14,17,1,15,
41,14,18,2,0,
41,14,19,4,0,
41,14,44,1,12,
41,14,48,1,4,
41,14,92,4,0,
41,14,95,2,0,
41,14,98,2,0,
41,14,104,4,0,
41,14,109,1,19,
41,14,114,1,41,
41,14,129,1,23,
41,14,141,1,1,
41,14,156,4,0,
41,14,162,3,0,
41,14,164,4,0,
41,14,168,4,0,
41,14,173,3,0,
41,14,174,2,0,
41,14,182,4,0,
41,14,185,2,0,
41,14,188,4,0,
41,14,202,2,0,
41,14,202,3,0,
41,14,207,4,0,
41,14,211,2,0,
41,14,212,1,34,
41,14,213,4,0,
41,14,214,3,0,
41,14,216,4,0,
41,14,218,4,0,
41,14,228,2,0,
41,14,237,4,0,
41,14,240,4,0,
41,14,241,4,0,
41,14,247,4,0,
41,14,253,3,0,
41,14,257,3,0,
41,14,259,4,0,
41,14,263,4,0,
41,14,269,4,0,
41,14,289,3,0,
41,14,305,1,37,
41,14,310,1,8,
41,14,314,1,26,
41,14,332,4,0,
41,14,355,3,0,
41,14,365,4,0,
41,14,366,3,0,
41,14,369,4,0,
41,14,371,4,0,
41,14,403,1,45,
41,14,413,2,0,
41,14,417,2,0,
41,14,428,2,0,
41,14,428,3,0,
41,14,432,2,0,
41,14,474,4,0,
41,14,496,4,0,
41,14,512,1,30,
41,14,512,4,0,
41,15,16,2,0,
41,15,17,1,15,
41,15,18,2,0,
41,15,19,4,0,
41,15,44,1,12,
41,15,48,1,4,
41,15,92,4,0,
41,15,95,2,0,
41,15,98,2,0,
41,15,104,4,0,
41,15,109,1,19,
41,15,114,1,41,
41,15,129,1,23,
41,15,141,1,1,
41,15,156,4,0,
41,15,164,4,0,
41,15,168,4,0,
41,15,174,2,0,
41,15,182,4,0,
41,15,185,2,0,
41,15,188,4,0,
41,15,202,2,0,
41,15,207,4,0,
41,15,211,2,0,
41,15,211,4,0,
41,15,212,1,34,
41,15,213,4,0,
41,15,214,4,0,
41,15,216,4,0,
41,15,218,4,0,
41,15,228,2,0,
41,15,237,4,0,
41,15,240,4,0,
41,15,241,4,0,
41,15,247,4,0,
41,15,259,4,0,
41,15,263,4,0,
41,15,269,4,0,
41,15,305,1,37,
41,15,310,1,8,
41,15,314,1,26,
41,15,332,4,0,
41,15,355,4,0,
41,15,369,4,0,
41,15,371,4,0,
41,15,403,1,45,
41,15,413,2,0,
41,15,417,2,0,
41,15,428,2,0,
41,15,432,2,0,
41,15,474,4,0,
41,15,496,4,0,
41,15,512,1,30,
41,15,512,4,0,
41,15,590,4,0,
41,15,599,2,0,
41,16,16,2,0,
41,16,17,1,13,1
41,16,18,2,0,
41,16,19,4,0,
41,16,44,1,11,1
41,16,48,1,5,1
41,16,92,4,0,
41,16,95,2,0,
41,16,98,2,0,
41,16,104,4,0,
41,16,109,1,17,1
41,16,114,1,35,1
41,16,129,1,23,1
41,16,141,1,1,1
41,16,156,4,0,
41,16,162,3,0,
41,16,164,4,0,
41,16,168,4,0,
41,16,173,3,0,
41,16,174,2,0,
41,16,182,4,0,
41,16,185,2,0,
41,16,188,4,0,
41,16,202,2,0,
41,16,202,3,0,
41,16,207,4,0,
41,16,211,2,0,
41,16,211,4,0,
41,16,212,1,29,1
41,16,213,4,0,
41,16,214,4,0,
41,16,216,4,0,
41,16,218,4,0,
41,16,228,2,0,
41,16,237,4,0,
41,16,240,4,0,
41,16,241,4,0,
41,16,247,4,0,
41,16,253,3,0,
41,16,257,3,0,
41,16,259,4,0,
41,16,263,4,0,
41,16,269,4,0,
41,16,289,3,0,
41,16,290,4,0,
41,16,305,1,25,1
41,16,310,1,7,1
41,16,314,1,19,1
41,16,332,4,0,
41,16,355,4,0,
41,16,366,3,0,
41,16,369,4,0,
41,16,371,4,0,
41,16,403,1,41,1
41,16,413,2,0,
41,16,417,2,0,
41,16,428,2,0,
41,16,428,3,0,
41,16,432,2,0,
41,16,474,1,37,1
41,16,474,4,0,
41,16,496,4,0,
41,16,501,1,43,1
41,16,512,1,31,1
41,16,512,4,0,
41,16,590,4,0,
41,16,599,2,0,
41,17,16,2,0,
41,17,17,1,13,
41,17,18,2,0,
41,17,19,4,0,
41,17,44,1,11,
41,17,48,1,5,
41,17,71,1,1,
41,17,92,4,0,
41,17,95,2,0,
41,17,98,2,0,
41,17,104,4,0,
41,17,109,1,17,
41,17,114,1,35,
41,17,129,1,23,
41,17,141,1,31,
41,17,141,4,0,
41,17,156,4,0,
41,17,164,4,0,
41,17,168,4,0,
41,17,174,2,0,
41,17,182,4,0,
41,17,185,2,0,
41,17,188,4,0,
41,17,202,2,0,
41,17,207,4,0,
41,17,211,2,0,
41,17,211,4,0,
41,17,212,1,29,
41,17,213,4,0,
41,17,214,4,0,
41,17,216,4,0,
41,17,218,4,0,
41,17,228,2,0,
41,17,237,4,0,
41,17,240,4,0,
41,17,241,4,0,
41,17,247,4,0,
41,17,259,4,0,
41,17,263,4,0,
41,17,269,4,0,
41,17,305,1,25,
41,17,310,1,7,
41,17,314,1,19,
41,17,332,4,0,
41,17,355,4,0,
41,17,369,4,0,
41,17,371,4,0,
41,17,403,1,41,
41,17,413,2,0,
41,17,417,2,0,
41,17,428,2,0,
41,17,432,2,0,
41,17,474,1,37,
41,17,474,4,0,
41,17,496,4,0,
41,17,501,1,43,
41,17,512,4,0,
41,17,590,4,0,
41,17,599,2,0,
41,18,16,2,0,
41,18,17,1,13,
41,18,18,2,0,
41,18,19,4,0,
41,18,44,1,11,
41,18,48,1,5,
41,18,71,1,1,
41,18,92,4,0,
41,18,95,2,0,
41,18,98,2,0,
41,18,104,4,0,
41,18,109,1,17,
41,18,114,1,35,
41,18,129,1,23,
41,18,141,1,31,
41,18,141,4,0,
41,18,156,4,0,
41,18,164,4,0,
41,18,168,4,0,
41,18,174,2,0,
41,18,182,4,0,
41,18,185,2,0,
41,18,188,4,0,
41,18,202,2,0,
41,18,207,4,0,
41,18,211,2,0,
41,18,211,4,0,
41,18,212,1,29,
41,18,213,4,0,
41,18,214,4,0,
41,18,216,4,0,
41,18,218,4,0,
41,18,228,2,0,
41,18,237,4,0,
41,18,240,4,0,
41,18,241,4,0,
41,18,247,4,0,
41,18,259,4,0,
41,18,263,4,0,
41,18,269,4,0,
41,18,305,1,25,
41,18,310,1,7,
41,18,314,1,19,
41,18,332,4,0,
41,18,355,4,0,
41,18,369,4,0,
41,18,371,4,0,
41,18,403,1,41,
41,18,413,2,0,
41,18,417,2,0,
41,18,428,2,0,
41,18,432,2,0,
41,18,474,1,37,
41,18,474,4,0,
41,18,496,4,0,
41,18,501,1,43,
41,18,512,4,0,
41,18,590,4,0,
41,18,599,2,0,
42,1,13,4,0,
42,1,17,1,32,
42,1,18,4,0,
42,1,36,4,0,
42,1,38,4,0,
42,1,44,1,1,3
42,1,44,1,15,
42,1,48,1,10,
42,1,63,4,0,
42,1,72,4,0,
42,1,92,4,0,
42,1,99,4,0,
42,1,102,4,0,
42,1,103,1,1,2
42,1,104,4,0,
42,1,109,1,21,
42,1,114,1,43,
42,1,117,4,0,
42,1,129,4,0,
42,1,141,1,1,1
42,1,156,4,0,
42,1,164,4,0,
42,2,13,4,0,
42,2,17,1,32,
42,2,18,4,0,
42,2,36,4,0,
42,2,38,4,0,
42,2,44,1,1,3
42,2,44,1,15,
42,2,48,1,10,
42,2,63,4,0,
42,2,72,4,0,
42,2,92,4,0,
42,2,99,4,0,
42,2,102,4,0,
42,2,103,1,1,2
42,2,104,4,0,
42,2,109,1,21,
42,2,114,1,43,
42,2,117,4,0,
42,2,129,4,0,
42,2,141,1,1,1
42,2,156,4,0,
42,2,164,4,0,
42,3,17,1,30,
42,3,44,1,12,
42,3,48,1,1,3
42,3,48,1,6,
42,3,63,4,0,
42,3,92,4,0,
42,3,103,1,1,1
42,3,104,4,0,
42,3,109,1,19,
42,3,114,1,55,
42,3,129,4,0,
42,3,141,1,1,2
42,3,156,4,0,
42,3,168,4,0,
42,3,173,4,0,
42,3,174,4,0,
42,3,182,4,0,
42,3,197,4,0,
42,3,202,4,0,
42,3,203,4,0,
42,3,207,4,0,
42,3,211,4,0,
42,3,212,1,42,
42,3,213,4,0,
42,3,214,4,0,
42,3,216,4,0,
42,3,218,4,0,
42,3,237,4,0,
42,3,241,4,0,
42,4,17,1,30,
42,4,44,1,12,
42,4,48,1,1,3
42,4,48,1,6,
42,4,63,4,0,
42,4,92,4,0,
42,4,103,1,1,1
42,4,104,4,0,
42,4,109,1,19,
42,4,114,1,55,
42,4,129,4,0,
42,4,141,1,1,2
42,4,156,4,0,
42,4,168,4,0,
42,4,173,4,0,
42,4,174,4,0,
42,4,182,4,0,
42,4,197,4,0,
42,4,202,4,0,
42,4,203,4,0,
42,4,207,4,0,
42,4,211,4,0,
42,4,212,1,42,
42,4,213,4,0,
42,4,214,4,0,
42,4,216,4,0,
42,4,218,4,0,
42,4,237,4,0,
42,4,241,4,0,
42,5,17,1,21,
42,5,44,1,16,
42,5,48,1,1,3
42,5,48,1,6,
42,5,63,4,0,
42,5,92,4,0,
42,5,103,1,1,1
42,5,104,4,0,
42,5,109,1,28,
42,5,114,1,56,
42,5,141,1,1,2
42,5,156,4,0,
42,5,168,4,0,
42,5,182,4,0,
42,5,188,4,0,
42,5,202,4,0,
42,5,211,4,0,
42,5,212,1,42,
42,5,213,4,0,
42,5,216,4,0,
42,5,218,4,0,
42,5,237,4,0,
42,5,240,4,0,
42,5,241,4,0,
42,5,247,4,0,
42,5,259,4,0,
42,5,263,4,0,
42,5,269,4,0,
42,5,289,4,0,
42,5,290,4,0,
42,5,305,1,49,
42,5,310,1,1,4
42,5,310,1,11,
42,5,314,1,35,
42,5,332,4,0,
42,6,17,1,21,
42,6,38,3,0,
42,6,44,1,16,
42,6,48,1,1,3
42,6,48,1,6,
42,6,63,4,0,
42,6,92,4,0,
42,6,102,3,0,
42,6,103,1,1,1
42,6,104,4,0,
42,6,109,1,28,
42,6,114,1,56,
42,6,129,3,0,
42,6,141,1,1,2
42,6,156,4,0,
42,6,164,3,0,
42,6,168,4,0,
42,6,173,3,0,
42,6,182,4,0,
42,6,188,4,0,
42,6,202,4,0,
42,6,203,3,0,
42,6,207,3,0,
42,6,211,4,0,
42,6,212,1,42,
42,6,213,4,0,
42,6,214,3,0,
42,6,216,4,0,
42,6,218,4,0,
42,6,237,4,0,
42,6,240,4,0,
42,6,241,4,0,
42,6,247,4,0,
42,6,259,4,0,
42,6,263,4,0,
42,6,269,4,0,
42,6,289,4,0,
42,6,290,4,0,
42,6,305,1,49,
42,6,310,1,1,4
42,6,310,1,11,
42,6,314,1,35,
42,6,332,4,0,
42,7,17,1,21,
42,7,38,3,0,
42,7,44,1,16,
42,7,48,1,1,4
42,7,48,1,11,
42,7,63,4,0,
42,7,92,4,0,
42,7,102,3,0,
42,7,103,1,1,1
42,7,104,4,0,
42,7,109,1,28,
42,7,114,1,56,
42,7,141,1,1,2
42,7,156,4,0,
42,7,164,3,0,
42,7,168,4,0,
42,7,182,4,0,
42,7,188,4,0,
42,7,202,4,0,
42,7,211,4,0,
42,7,212,1,42,
42,7,213,4,0,
42,7,216,4,0,
42,7,218,4,0,
42,7,237,4,0,
42,7,240,4,0,
42,7,241,4,0,
42,7,247,4,0,
42,7,259,4,0,
42,7,263,4,0,
42,7,269,4,0,
42,7,289,4,0,
42,7,290,4,0,
42,7,305,1,49,
42,7,310,1,1,3
42,7,310,1,6,
42,7,314,1,35,
42,7,332,4,0,
42,8,17,1,17,
42,8,19,4,0,
42,8,44,1,13,
42,8,48,1,1,3
42,8,48,1,5,
42,8,63,4,0,
42,8,92,4,0,
42,8,103,1,1,1
42,8,104,4,0,
42,8,109,1,21,
42,8,114,1,45,
42,8,141,1,1,2
42,8,156,4,0,
42,8,164,4,0,
42,8,168,4,0,
42,8,182,4,0,
42,8,188,4,0,
42,8,202,4,0,
42,8,203,4,0,
42,8,207,4,0,
42,8,211,4,0,
42,8,212,1,33,
42,8,213,4,0,
42,8,214,4,0,
42,8,216,4,0,
42,8,218,4,0,
42,8,237,4,0,
42,8,240,4,0,
42,8,241,4,0,
42,8,247,4,0,
42,8,259,4,0,
42,8,263,4,0,
42,8,269,4,0,
42,8,289,4,0,
42,8,290,4,0,
42,8,305,1,39,
42,8,310,1,1,4
42,8,310,1,9,
42,8,314,1,27,
42,8,332,4,0,
42,8,355,4,0,
42,8,363,4,0,
42,8,365,4,0,
42,8,369,4,0,
42,8,371,4,0,
42,8,403,1,51,
42,8,416,4,0,
42,8,432,4,0,
42,8,445,4,0,
42,9,17,1,17,
42,9,19,4,0,
42,9,44,1,13,
42,9,48,1,1,3
42,9,48,1,5,
42,9,63,4,0,
42,9,92,4,0,
42,9,103,1,1,1
42,9,104,4,0,
42,9,109,1,21,
42,9,114,1,45,
42,9,129,3,0,
42,9,141,1,1,2
42,9,156,4,0,
42,9,164,4,0,
42,9,168,4,0,
42,9,173,3,0,
42,9,182,4,0,
42,9,188,4,0,
42,9,202,4,0,
42,9,203,4,0,
42,9,207,4,0,
42,9,211,4,0,
42,9,212,1,33,
42,9,213,4,0,
42,9,214,4,0,
42,9,216,4,0,
42,9,218,4,0,
42,9,237,4,0,
42,9,239,3,0,
42,9,240,4,0,
42,9,241,4,0,
42,9,247,4,0,
42,9,253,3,0,
42,9,257,3,0,
42,9,259,4,0,
42,9,263,4,0,
42,9,269,4,0,
42,9,289,4,0,
42,9,290,4,0,
42,9,305,1,39,
42,9,310,1,1,4
42,9,310,1,9,
42,9,314,1,27,
42,9,314,3,0,
42,9,332,4,0,
42,9,355,4,0,
42,9,363,4,0,
42,9,365,4,0,
42,9,369,4,0,
42,9,371,4,0,
42,9,403,1,51,
42,9,416,4,0,
42,9,428,3,0,
42,9,432,4,0,
42,9,445,4,0,
42,9,466,3,0,
42,10,17,1,17,
42,10,19,4,0,
42,10,44,1,13,
42,10,48,1,1,3
42,10,48,1,5,
42,10,63,4,0,
42,10,92,4,0,
42,10,103,1,1,1
42,10,104,4,0,
42,10,109,1,21,
42,10,114,1,45,
42,10,129,3,0,
42,10,141,1,1,2
42,10,156,4,0,
42,10,162,3,0,
42,10,164,4,0,
42,10,168,4,0,
42,10,173,3,0,
42,10,182,4,0,
42,10,188,4,0,
42,10,202,4,0,
42,10,203,4,0,
42,10,207,4,0,
42,10,211,4,0,
42,10,212,1,33,
42,10,213,4,0,
42,10,214,4,0,
42,10,216,4,0,
42,10,218,4,0,
42,10,237,4,0,
42,10,239,3,0,
42,10,240,4,0,
42,10,241,4,0,
42,10,247,4,0,
42,10,253,3,0,
42,10,257,3,0,
42,10,259,4,0,
42,10,263,4,0,
42,10,269,4,0,
42,10,289,4,0,
42,10,290,4,0,
42,10,305,1,39,
42,10,310,1,1,4
42,10,310,1,9,
42,10,314,1,27,
42,10,314,3,0,
42,10,332,4,0,
42,10,355,4,0,
42,10,363,4,0,
42,10,365,4,0,
42,10,366,3,0,
42,10,369,4,0,
42,10,371,4,0,
42,10,403,1,51,
42,10,416,4,0,
42,10,428,3,0,
42,10,445,4,0,
42,10,466,3,0,
42,11,17,1,17,
42,11,19,4,0,
42,11,44,1,13,
42,11,48,1,1,3
42,11,48,1,5,
42,11,63,4,0,
42,11,92,4,0,
42,11,103,1,1,1
42,11,104,4,0,
42,11,109,1,21,
42,11,114,1,51,
42,11,141,1,1,2
42,11,156,4,0,
42,11,164,4,0,
42,11,168,4,0,
42,11,182,4,0,
42,11,188,4,0,
42,11,207,4,0,
42,11,212,1,33,
42,11,213,4,0,
42,11,216,4,0,
42,11,218,4,0,
42,11,237,4,0,
42,11,240,4,0,
42,11,241,4,0,
42,11,247,4,0,
42,11,259,4,0,
42,11,263,4,0,
42,11,269,4,0,
42,11,305,1,45,
42,11,310,1,1,4
42,11,310,1,9,
42,11,314,1,27,
42,11,332,4,0,
42,11,365,4,0,
42,11,369,4,0,
42,11,371,4,0,
42,11,403,1,57,
42,11,416,4,0,
42,11,474,4,0,
42,11,496,4,0,
42,11,512,1,39,
42,11,512,4,0,
42,12,17,1,21,
42,12,44,1,16,
42,12,48,1,1,3
42,12,48,1,6,
42,12,63,4,0,
42,12,92,4,0,
42,12,103,1,1,1
42,12,104,4,0,
42,12,109,1,28,
42,12,114,1,56,
42,12,141,1,1,2
42,12,156,4,0,
42,12,168,4,0,
42,12,182,4,0,
42,12,188,4,0,
42,12,202,4,0,
42,12,211,4,0,
42,12,212,1,42,
42,12,213,4,0,
42,12,216,4,0,
42,12,218,4,0,
42,12,237,4,0,
42,12,240,4,0,
42,12,241,4,0,
42,12,247,4,0,
42,12,259,4,0,
42,12,263,4,0,
42,12,269,4,0,
42,12,289,4,0,
42,12,290,4,0,
42,12,305,1,49,
42,12,310,1,1,4
42,12,310,1,11,
42,12,314,1,35,
42,12,332,4,0,
42,13,17,1,21,
42,13,38,3,0,
42,13,44,1,16,
42,13,48,1,1,3
42,13,48,1,6,
42,13,63,4,0,
42,13,92,4,0,
42,13,102,3,0,
42,13,103,1,1,1
42,13,104,4,0,
42,13,109,1,28,
42,13,114,1,56,
42,13,141,1,1,2
42,13,156,4,0,
42,13,164,3,0,
42,13,168,4,0,
42,13,182,4,0,
42,13,188,4,0,
42,13,202,4,0,
42,13,207,3,0,
42,13,211,4,0,
42,13,212,1,42,
42,13,213,4,0,
42,13,216,4,0,
42,13,218,4,0,
42,13,237,4,0,
42,13,240,4,0,
42,13,241,4,0,
42,13,247,4,0,
42,13,259,4,0,
42,13,263,4,0,
42,13,269,4,0,
42,13,289,4,0,
42,13,290,4,0,
42,13,305,1,49,
42,13,310,1,1,4
42,13,310,1,11,
42,13,314,1,35,
42,13,332,4,0,
42,14,17,1,15,
42,14,19,4,0,
42,14,44,1,12,
42,14,48,1,1,3
42,14,48,1,4,
42,14,63,4,0,
42,14,92,4,0,
42,14,103,1,1,1
42,14,104,4,0,
42,14,109,1,19,
42,14,114,1,47,
42,14,129,1,24,
42,14,141,1,1,2
42,14,156,4,0,
42,14,162,3,0,
42,14,164,4,0,
42,14,168,4,0,
42,14,173,3,0,
42,14,182,4,0,
42,14,188,4,0,
42,14,202,3,0,
42,14,207,4,0,
42,14,212,1,38,
42,14,213,4,0,
42,14,214,3,0,
42,14,216,4,0,
42,14,218,4,0,
42,14,237,4,0,
42,14,240,4,0,
42,14,241,4,0,
42,14,247,4,0,
42,14,253,3,0,
42,14,257,3,0,
42,14,259,4,0,
42,14,263,4,0,
42,14,269,4,0,
42,14,289,3,0,
42,14,305,1,42,
42,14,310,1,1,4
42,14,310,1,8,
42,14,314,1,28,
42,14,332,4,0,
42,14,355,3,0,
42,14,365,4,0,
42,14,366,3,0,
42,14,369,4,0,
42,14,371,4,0,
42,14,403,1,52,
42,14,416,4,0,
42,14,428,3,0,
42,14,474,4,0,
42,14,496,4,0,
42,14,512,1,33,
42,14,512,4,0,
42,15,17,1,15,
42,15,19,4,0,
42,15,44,1,12,
42,15,48,1,1,3
42,15,48,1,4,
42,15,63,4,0,
42,15,92,4,0,
42,15,103,1,1,1
42,15,104,4,0,
42,15,109,1,19,
42,15,114,1,47,
42,15,129,1,24,
42,15,141,1,1,2
42,15,156,4,0,
42,15,164,4,0,
42,15,168,4,0,
42,15,182,4,0,
42,15,188,4,0,
42,15,207,4,0,
42,15,211,4,0,
42,15,212,1,38,
42,15,213,4,0,
42,15,214,4,0,
42,15,216,4,0,
42,15,218,4,0,
42,15,237,4,0,
42,15,240,4,0,
42,15,241,4,0,
42,15,247,4,0,
42,15,259,4,0,
42,15,263,4,0,
42,15,269,4,0,
42,15,305,1,42,
42,15,310,1,1,4
42,15,310,1,8,
42,15,314,1,28,
42,15,332,4,0,
42,15,355,4,0,
42,15,369,4,0,
42,15,371,4,0,
42,15,403,1,52,
42,15,416,4,0,
42,15,474,4,0,
42,15,496,4,0,
42,15,512,1,33,
42,15,512,4,0,
42,15,590,4,0,
42,16,17,1,13,1
42,16,19,4,0,
42,16,44,1,1,5
42,16,44,1,11,1
42,16,48,1,1,3
42,16,48,1,5,1
42,16,63,4,0,
42,16,92,4,0,
42,16,103,1,1,1
42,16,104,4,0,
42,16,109,1,17,1
42,16,114,1,40,1
42,16,129,1,24,1
42,16,141,1,1,2
42,16,156,4,0,
42,16,162,3,0,
42,16,164,4,0,
42,16,168,4,0,
42,16,173,3,0,
42,16,182,4,0,
42,16,188,4,0,
42,16,202,3,0,
42,16,207,4,0,
42,16,211,4,0,
42,16,212,1,32,1
42,16,213,4,0,
42,16,214,4,0,
42,16,216,4,0,
42,16,218,4,0,
42,16,237,4,0,
42,16,240,4,0,
42,16,241,4,0,
42,16,247,4,0,
42,16,253,3,0,
42,16,257,3,0,
42,16,259,4,0,
42,16,263,4,0,
42,16,269,4,0,
42,16,289,3,0,
42,16,290,4,0,
42,16,305,1,27,1
42,16,310,1,1,4
42,16,310,1,7,1
42,16,314,1,19,1
42,16,332,4,0,
42,16,355,4,0,
42,16,366,3,0,
42,16,369,4,0,
42,16,371,4,0,
42,16,403,1,48,1
42,16,416,4,0,
42,16,428,3,0,
42,16,474,1,43,1
42,16,474,4,0,
42,16,496,4,0,
42,16,501,1,51,1
42,16,512,1,35,1
42,16,512,4,0,
42,16,590,4,0,
42,17,17,1,13,
42,17,19,4,0,
42,17,44,1,1,5
42,17,44,1,11,
42,17,48,1,1,3
42,17,48,1,5,
42,17,63,4,0,
42,17,71,1,1,2
42,17,92,4,0,
42,17,103,1,1,1
42,17,104,4,0,
42,17,109,1,17,
42,17,114,1,40,
42,17,129,1,24,
42,17,141,1,35,
42,17,141,4,0,
42,17,156,4,0,
42,17,164,4,0,
42,17,168,4,0,
42,17,182,4,0,
42,17,188,4,0,
42,17,207,4,0,
42,17,211,4,0,
42,17,212,1,32,
42,17,213,4,0,
42,17,214,4,0,
42,17,216,4,0,
42,17,218,4,0,
42,17,237,4,0,
42,17,240,4,0,
42,17,241,4,0,
42,17,247,4,0,
42,17,259,4,0,
42,17,263,4,0,
42,17,269,4,0,
42,17,305,1,27,
42,17,310,1,1,4
42,17,310,1,7,
42,17,314,1,19,
42,17,332,4,0,
42,17,355,4,0,
42,17,369,4,0,
42,17,371,4,0,
42,17,403,1,48,
42,17,416,4,0,
42,17,474,1,43,
42,17,474,4,0,
42,17,496,4,0,
42,17,501,1,51,
42,17,512,4,0,
42,17,590,4,0,
42,18,17,1,13,
42,18,19,4,0,
42,18,44,1,1,5
42,18,44,1,11,
42,18,48,1,1,3
42,18,48,1,5,
42,18,63,4,0,
42,18,71,1,1,2
42,18,92,4,0,
42,18,103,1,1,1
42,18,104,4,0,
42,18,109,1,17,
42,18,114,1,40,
42,18,129,1,24,
42,18,141,1,35,
42,18,141,4,0,
42,18,156,4,0,
42,18,164,4,0,
42,18,168,4,0,
42,18,182,4,0,
42,18,188,4,0,
42,18,207,4,0,
42,18,211,4,0,
42,18,212,1,32,
42,18,213,4,0,
42,18,214,4,0,
42,18,216,4,0,
42,18,218,4,0,
42,18,237,4,0,
42,18,240,4,0,
42,18,241,4,0,
42,18,247,4,0,
42,18,259,4,0,
42,18,263,4,0,
42,18,269,4,0,
42,18,305,1,27,
42,18,310,1,1,4
42,18,310,1,7,
42,18,314,1,19,
42,18,332,4,0,
42,18,355,4,0,
42,18,369,4,0,
42,18,371,4,0,
42,18,403,1,48,
42,18,416,4,0,
42,18,474,1,43,
42,18,474,4,0,
42,18,496,4,0,
42,18,501,1,51,
42,18,512,4,0,
42,18,590,4,0,
43,1,14,4,0,
43,1,15,4,0,
43,1,36,4,0,
43,1,38,4,0,
43,1,51,1,24,
43,1,71,1,1,
43,1,72,4,0,
43,1,76,1,46,
43,1,76,4,0,
43,1,77,1,15,
43,1,78,1,17,
43,1,79,1,19,
43,1,80,1,33,
43,1,92,4,0,
43,1,99,4,0,
43,1,102,4,0,
43,1,104,4,0,
43,1,115,4,0,
43,1,117,4,0,
43,1,156,4,0,
43,1,164,4,0,
43,2,14,4,0,
43,2,15,4,0,
43,2,36,4,0,
43,2,38,4,0,
43,2,51,1,24,
43,2,71,1,1,
43,2,72,4,0,
43,2,76,1,46,
43,2,76,4,0,
43,2,77,1,15,
43,2,78,1,17,
43,2,79,1,19,
43,2,80,1,33,
43,2,92,4,0,
43,2,99,4,0,
43,2,102,4,0,
43,2,104,4,0,
43,2,115,4,0,
43,2,117,4,0,
43,2,156,4,0,
43,2,164,4,0,
43,3,14,2,0,
43,3,15,4,0,
43,3,51,1,23,
43,3,71,1,1,
43,3,75,2,0,
43,3,76,4,0,
43,3,77,1,14,
43,3,78,1,16,
43,3,79,1,18,
43,3,80,1,39,
43,3,92,4,0,
43,3,104,4,0,
43,3,148,4,0,
43,3,156,4,0,
43,3,173,4,0,
43,3,174,4,0,
43,3,175,2,0,
43,3,182,4,0,
43,3,188,4,0,
43,3,202,4,0,
43,3,203,4,0,
43,3,204,2,0,
43,3,207,4,0,
43,3,213,4,0,
43,3,214,4,0,
43,3,216,4,0,
43,3,218,4,0,
43,3,230,1,7,
43,3,230,4,0,
43,3,235,2,0,
43,3,236,1,32,
43,3,237,4,0,
43,3,241,4,0,
43,4,14,2,0,
43,4,15,4,0,
43,4,51,1,23,
43,4,71,1,1,
43,4,75,2,0,
43,4,76,4,0,
43,4,77,1,14,
43,4,78,1,16,
43,4,79,1,18,
43,4,80,1,39,
43,4,92,4,0,
43,4,104,4,0,
43,4,148,4,0,
43,4,156,4,0,
43,4,173,4,0,
43,4,174,4,0,
43,4,175,2,0,
43,4,182,4,0,
43,4,188,4,0,
43,4,202,4,0,
43,4,203,4,0,
43,4,207,4,0,
43,4,213,4,0,
43,4,214,4,0,
43,4,216,4,0,
43,4,218,4,0,
43,4,230,1,7,
43,4,230,4,0,
43,4,235,2,0,
43,4,236,1,32,
43,4,237,4,0,
43,4,241,4,0,
43,5,14,2,0,
43,5,15,4,0,
43,5,51,1,23,
43,5,71,1,1,
43,5,75,2,0,
43,5,76,4,0,
43,5,77,1,14,
43,5,78,1,16,
43,5,79,1,18,
43,5,80,1,39,
43,5,92,4,0,
43,5,104,4,0,
43,5,148,4,0,
43,5,156,4,0,
43,5,175,2,0,
43,5,182,4,0,
43,5,188,4,0,
43,5,202,4,0,
43,5,204,2,0,
43,5,213,4,0,
43,5,216,4,0,
43,5,218,4,0,
43,5,230,1,7,
43,5,235,2,0,
43,5,236,1,32,
43,5,237,4,0,
43,5,241,4,0,
43,5,263,4,0,
43,5,275,2,0,
43,5,290,4,0,
43,5,331,4,0,
43,6,14,2,0,
43,6,14,3,0,
43,6,15,4,0,
43,6,38,3,0,
43,6,51,1,23,
43,6,71,1,1,
43,6,75,2,0,
43,6,76,4,0,
43,6,77,1,14,
43,6,78,1,16,
43,6,79,1,18,
43,6,80,1,39,
43,6,92,4,0,
43,6,102,3,0,
43,6,104,4,0,
43,6,148,4,0,
43,6,156,4,0,
43,6,164,3,0,
43,6,173,3,0,
43,6,175,2,0,
43,6,182,4,0,
43,6,188,4,0,
43,6,202,4,0,
43,6,203,3,0,
43,6,204,2,0,
43,6,207,3,0,
43,6,213,4,0,
43,6,214,3,0,
43,6,216,4,0,
43,6,218,4,0,
43,6,230,1,7,
43,6,235,2,0,
43,6,236,1,32,
43,6,237,4,0,
43,6,241,4,0,
43,6,263,4,0,
43,6,275,2,0,
43,6,290,4,0,
43,6,331,4,0,
43,7,14,2,0,
43,7,14,3,0,
43,7,15,4,0,
43,7,38,3,0,
43,7,51,1,23,
43,7,71,1,1,
43,7,75,2,0,
43,7,76,4,0,
43,7,77,1,14,
43,7,78,1,16,
43,7,79,1,18,
43,7,80,1,39,
43,7,92,4,0,
43,7,102,3,0,
43,7,104,4,0,
43,7,148,4,0,
43,7,156,4,0,
43,7,164,3,0,
43,7,175,2,0,
43,7,182,4,0,
43,7,188,4,0,
43,7,202,4,0,
43,7,204,2,0,
43,7,213,4,0,
43,7,216,4,0,
43,7,218,4,0,
43,7,230,1,7,
43,7,235,2,0,
43,7,236,1,32,
43,7,237,4,0,
43,7,241,4,0,
43,7,263,4,0,
43,7,275,2,0,
43,7,290,4,0,
43,7,331,4,0,
43,8,14,2,0,
43,8,14,4,0,
43,8,15,4,0,
43,8,51,1,9,
43,8,71,1,1,
43,8,72,1,21,
43,8,75,2,0,
43,8,76,4,0,
43,8,77,1,13,
43,8,78,1,15,
43,8,79,1,17,
43,8,80,1,41,
43,8,92,4,0,
43,8,104,4,0,
43,8,148,4,0,
43,8,156,4,0,
43,8,164,4,0,
43,8,175,2,0,
43,8,182,4,0,
43,8,188,4,0,
43,8,202,1,37,
43,8,202,4,0,
43,8,203,4,0,
43,8,204,2,0,
43,8,207,4,0,
43,8,213,4,0,
43,8,214,4,0,
43,8,216,4,0,
43,8,218,4,0,
43,8,230,1,5,
43,8,235,2,0,
43,8,236,1,33,
43,8,237,4,0,
43,8,241,4,0,
43,8,263,4,0,
43,8,275,2,0,
43,8,290,4,0,
43,8,321,2,0,
43,8,331,4,0,
43,8,363,1,29,
43,8,363,4,0,
43,8,381,1,25,
43,8,412,4,0,
43,8,445,4,0,
43,8,447,4,0,
43,9,14,2,0,
43,9,14,4,0,
43,9,15,4,0,
43,9,51,1,9,
43,9,71,1,1,
43,9,72,1,21,
43,9,75,2,0,
43,9,76,4,0,
43,9,77,1,13,
43,9,78,1,15,
43,9,79,1,17,
43,9,80,1,41,
43,9,92,4,0,
43,9,104,4,0,
43,9,148,4,0,
43,9,156,4,0,
43,9,164,4,0,
43,9,173,3,0,
43,9,175,2,0,
43,9,182,4,0,
43,9,188,4,0,
43,9,202,1,37,
43,9,202,4,0,
43,9,203,4,0,
43,9,204,2,0,
43,9,207,4,0,
43,9,213,4,0,
43,9,214,4,0,
43,9,216,4,0,
43,9,218,4,0,
43,9,230,1,5,
43,9,235,2,0,
43,9,235,3,0,
43,9,236,1,33,
43,9,237,4,0,
43,9,241,4,0,
43,9,263,4,0,
43,9,275,2,0,
43,9,290,4,0,
43,9,321,2,0,
43,9,331,4,0,
43,9,363,1,29,
43,9,363,4,0,
43,9,380,3,0,
43,9,381,1,25,
43,9,402,3,0,
43,9,412,4,0,
43,9,445,4,0,
43,9,447,4,0,
43,10,14,2,0,
43,10,14,4,0,
43,10,15,4,0,
43,10,51,1,9,
43,10,71,1,1,
43,10,72,1,21,
43,10,75,2,0,
43,10,76,4,0,
43,10,77,1,13,
43,10,78,1,15,
43,10,79,1,17,
43,10,80,1,41,
43,10,92,4,0,
43,10,104,4,0,
43,10,148,4,0,
43,10,156,4,0,
43,10,164,4,0,
43,10,173,3,0,
43,10,175,2,0,
43,10,182,4,0,
43,10,188,4,0,
43,10,202,1,37,
43,10,202,4,0,
43,10,203,4,0,
43,10,204,2,0,
43,10,207,4,0,
43,10,213,4,0,
43,10,214,4,0,
43,10,216,4,0,
43,10,218,4,0,
43,10,230,1,5,
43,10,235,2,0,
43,10,235,3,0,
43,10,236,1,33,
43,10,237,4,0,
43,10,241,4,0,
43,10,263,4,0,
43,10,275,2,0,
43,10,290,4,0,
43,10,298,2,0,
43,10,321,2,0,
43,10,331,4,0,
43,10,363,1,29,
43,10,363,4,0,
43,10,380,3,0,
43,10,381,1,25,
43,10,388,3,0,
43,10,402,3,0,
43,10,412,4,0,
43,10,445,4,0,
43,10,447,4,0,
43,11,14,4,0,
43,11,15,4,0,
43,11,51,1,9,
43,11,71,1,1,
43,11,72,1,21,
43,11,75,2,0,
43,11,76,4,0,
43,11,77,1,13,
43,11,78,1,15,
43,11,79,1,17,
43,11,80,1,41,
43,11,92,4,0,
43,11,104,4,0,
43,11,148,4,0,
43,11,156,4,0,
43,11,164,4,0,
43,11,175,2,0,
43,11,182,4,0,
43,11,188,4,0,
43,11,202,1,37,
43,11,204,2,0,
43,11,207,4,0,
43,11,213,4,0,
43,11,216,4,0,
43,11,218,4,0,
43,11,230,1,5,
43,11,235,2,0,
43,11,236,1,33,
43,11,237,4,0,
43,11,241,4,0,
43,11,263,4,0,
43,11,267,2,0,
43,11,275,2,0,
43,11,290,2,0,
43,11,298,2,0,
43,11,321,2,0,
43,11,363,1,29,
43,11,381,1,25,
43,11,412,4,0,
43,11,447,4,0,
43,11,474,4,0,
43,11,495,2,0,
43,11,496,4,0,
43,12,15,4,0,
43,12,51,1,23,
43,12,71,1,1,
43,12,76,4,0,
43,12,77,1,14,
43,12,78,1,16,
43,12,79,1,18,
43,12,80,1,39,
43,12,92,4,0,
43,12,104,4,0,
43,12,148,4,0,
43,12,156,4,0,
43,12,182,4,0,
43,12,188,4,0,
43,12,202,4,0,
43,12,213,4,0,
43,12,216,4,0,
43,12,218,4,0,
43,12,230,1,7,
43,12,236,1,32,
43,12,237,4,0,
43,12,241,4,0,
43,12,263,4,0,
43,12,290,4,0,
43,12,331,4,0,
43,13,15,4,0,
43,13,38,3,0,
43,13,51,1,23,
43,13,71,1,1,
43,13,76,4,0,
43,13,77,1,14,
43,13,78,1,16,
43,13,79,1,18,
43,13,80,1,39,
43,13,92,4,0,
43,13,102,3,0,
43,13,104,4,0,
43,13,148,4,0,
43,13,156,4,0,
43,13,164,3,0,
43,13,182,4,0,
43,13,188,4,0,
43,13,202,4,0,
43,13,207,3,0,
43,13,213,4,0,
43,13,216,4,0,
43,13,218,4,0,
43,13,230,1,7,
43,13,236,1,32,
43,13,237,4,0,
43,13,241,4,0,
43,13,263,4,0,
43,13,290,4,0,
43,13,331,4,0,
43,14,14,4,0,
43,14,15,4,0,
43,14,51,1,9,
43,14,71,1,1,
43,14,72,1,21,
43,14,75,2,0,
43,14,76,4,0,
43,14,77,1,13,
43,14,78,1,15,
43,14,79,1,17,
43,14,80,1,41,
43,14,92,4,0,
43,14,104,4,0,
43,14,148,4,0,
43,14,156,4,0,
43,14,164,4,0,
43,14,173,3,0,
43,14,175,2,0,
43,14,182,4,0,
43,14,188,4,0,
43,14,202,1,37,
43,14,202,3,0,
43,14,204,2,0,
43,14,207,4,0,
43,14,213,4,0,
43,14,214,3,0,
43,14,216,4,0,
43,14,218,4,0,
43,14,230,1,5,
43,14,235,2,0,
43,14,235,3,0,
43,14,236,1,33,
43,14,237,4,0,
43,14,241,4,0,
43,14,263,4,0,
43,14,267,2,0,
43,14,275,2,0,
43,14,290,2,0,
43,14,298,2,0,
43,14,321,2,0,
43,14,363,1,29,
43,14,380,3,0,
43,14,381,1,25,
43,14,388,3,0,
43,14,402,3,0,
43,14,412,4,0,
43,14,447,4,0,
43,14,474,4,0,
43,14,495,2,0,
43,14,495,3,0,
43,14,496,4,0,
43,15,14,4,0,
43,15,15,4,0,
43,15,51,1,9,
43,15,71,1,1,
43,15,72,1,21,
43,15,75,2,0,
43,15,76,4,0,
43,15,77,1,13,
43,15,78,1,15,
43,15,79,1,17,
43,15,80,1,41,
43,15,92,4,0,
43,15,104,4,0,
43,15,148,4,0,
43,15,156,4,0,
43,15,164,4,0,
43,15,175,2,0,
43,15,182,4,0,
43,15,188,4,0,
43,15,202,1,37,
43,15,204,2,0,
43,15,207,4,0,
43,15,213,4,0,
43,15,214,4,0,
43,15,216,4,0,
43,15,218,4,0,
43,15,230,1,5,
43,15,235,2,0,
43,15,236,1,33,
43,15,237,4,0,
43,15,241,4,0,
43,15,263,4,0,
43,15,267,2,0,
43,15,267,4,0,
43,15,275,2,0,
43,15,290,2,0,
43,15,298,2,0,
43,15,321,2,0,
43,15,363,1,29,
43,15,381,1,25,
43,15,412,4,0,
43,15,447,4,0,
43,15,474,4,0,
43,15,495,2,0,
43,15,496,4,0,
43,15,580,1,45,
43,15,590,4,0,
43,15,605,4,0,
43,15,611,4,0,
43,16,14,4,0,
43,16,15,4,0,
43,16,51,1,9,1
43,16,71,1,1,1
43,16,72,1,19,1
43,16,75,2,0,
43,16,76,4,0,
43,16,77,1,13,1
43,16,78,1,14,1
43,16,79,1,15,1
43,16,80,1,51,1
43,16,92,1,35,1
43,16,92,4,0,
43,16,104,4,0,
43,16,148,4,0,
43,16,156,4,0,
43,16,164,4,0,
43,16,173,3,0,
43,16,175,2,0,
43,16,182,4,0,
43,16,188,4,0,
43,16,202,1,31,1
43,16,202,3,0,
43,16,204,2,0,
43,16,207,4,0,
43,16,213,4,0,
43,16,214,4,0,
43,16,216,4,0,
43,16,218,4,0,
43,16,230,1,5,1
43,16,235,2,0,
43,16,235,3,0,
43,16,236,1,27,1
43,16,237,4,0,
43,16,241,4,0,
43,16,263,4,0,
43,16,267,2,0,
43,16,267,4,0,
43,16,275,2,0,
43,16,290,2,0,
43,16,290,4,0,
43,16,298,2,0,
43,16,321,2,0,
43,16,363,1,39,1
43,16,380,3,0,
43,16,381,1,23,1
43,16,388,3,0,
43,16,402,3,0,
43,16,412,4,0,
43,16,447,4,0,
43,16,474,4,0,
43,16,495,2,0,
43,16,495,3,0,
43,16,496,4,0,
43,16,580,1,47,1
43,16,585,1,43,1
43,16,590,4,0,
43,16,605,4,0,
43,16,611,4,0,
43,17,14,4,0,
43,17,51,1,9,
43,17,71,1,1,1
43,17,72,1,19,
43,17,74,1,1,2
43,17,75,2,0,
43,17,76,4,0,
43,17,77,1,13,
43,17,78,1,14,
43,17,79,1,15,
43,17,80,1,51,
43,17,92,1,35,
43,17,92,4,0,
43,17,104,4,0,
43,17,156,4,0,
43,17,164,4,0,
43,17,175,2,0,
43,17,182,4,0,
43,17,188,4,0,
43,17,202,1,31,
43,17,204,2,0,
43,17,207,4,0,
43,17,213,4,0,
43,17,214,4,0,
43,17,216,4,0,
43,17,218,4,0,
43,17,230,1,5,
43,17,235,2,0,
43,17,236,1,27,
43,17,237,4,0,
43,17,241,4,0,
43,17,263,4,0,
43,17,267,2,0,
43,17,267,4,0,
43,17,275,2,0,
43,17,290,2,0,
43,17,298,2,0,
43,17,321,2,0,
43,17,363,1,39,
43,17,381,1,23,
43,17,412,4,0,
43,17,447,4,0,
43,17,474,4,0,
43,17,495,2,0,
43,17,496,4,0,
43,17,580,1,47,
43,17,585,1,43,
43,17,590,4,0,
43,17,605,4,0,
43,17,611,4,0,
43,18,14,4,0,
43,18,51,1,9,
43,18,71,1,1,1
43,18,72,1,19,
43,18,74,1,1,2
43,18,75,2,0,
43,18,76,4,0,
43,18,77,1,13,
43,18,78,1,14,
43,18,79,1,15,
43,18,80,1,51,
43,18,92,1,35,
43,18,92,4,0,
43,18,104,4,0,
43,18,156,4,0,
43,18,164,4,0,
43,18,175,2,0,
43,18,182,4,0,
43,18,188,4,0,
43,18,202,1,31,
43,18,204,2,0,
43,18,207,4,0,
43,18,213,4,0,
43,18,214,4,0,
43,18,216,4,0,
43,18,218,4,0,
43,18,230,1,5,
43,18,235,2,0,
43,18,236,1,27,
43,18,237,4,0,
43,18,241,4,0,
43,18,263,4,0,
43,18,267,2,0,
43,18,267,4,0,
43,18,275,2,0,
43,18,290,2,0,
43,18,298,2,0,
43,18,321,2,0,
43,18,363,1,39,
43,18,381,1,23,
43,18,412,4,0,
43,18,447,4,0,
43,18,474,4,0,
43,18,495,2,0,
43,18,496,4,0,
43,18,580,1,47,
43,18,585,1,43,
43,18,590,4,0,
43,18,605,4,0,
43,18,611,4,0,
43,18,668,2,0,
44,1,14,4,0,
44,1,15,4,0,
44,1,36,4,0,
44,1,38,4,0,
44,1,51,1,28,
44,1,71,1,1,1
44,1,72,4,0,
44,1,76,1,52,
44,1,76,4,0,
44,1,77,1,1,2
44,1,77,1,15,
44,1,78,1,1,3
44,1,78,1,17,
44,1,79,1,19,
44,1,80,1,38,
44,1,92,4,0,
44,1,99,4,0,
44,1,102,4,0,
44,1,104,4,0,
44,1,115,4,0,
44,1,117,4,0,
44,1,156,4,0,
44,1,164,4,0,
44,2,14,4,0,
44,2,15,4,0,
44,2,36,4,0,
44,2,38,4,0,
44,2,51,1,28,
44,2,71,1,1,1
44,2,72,4,0,
44,2,76,1,52,
44,2,76,4,0,
44,2,77,1,1,2
44,2,77,1,15,
44,2,78,1,1,3
44,2,78,1,17,
44,2,79,1,19,
44,2,80,1,38,
44,2,92,4,0,
44,2,99,4,0,
44,2,102,4,0,
44,2,104,4,0,
44,2,115,4,0,
44,2,117,4,0,
44,2,156,4,0,
44,2,164,4,0,
44,3,15,4,0,
44,3,51,1,24,
44,3,71,1,1,1
44,3,76,4,0,
44,3,77,1,1,3
44,3,77,1,14,
44,3,78,1,16,
44,3,79,1,18,
44,3,80,1,44,
44,3,92,4,0,
44,3,104,4,0,
44,3,148,4,0,
44,3,156,4,0,
44,3,173,4,0,
44,3,174,4,0,
44,3,182,4,0,
44,3,188,4,0,
44,3,202,4,0,
44,3,203,4,0,
44,3,207,4,0,
44,3,213,4,0,
44,3,214,4,0,
44,3,216,4,0,
44,3,218,4,0,
44,3,230,1,1,2
44,3,230,1,7,
44,3,230,4,0,
44,3,236,1,35,
44,3,237,4,0,
44,3,241,4,0,
44,4,15,4,0,
44,4,51,1,24,
44,4,71,1,1,1
44,4,76,4,0,
44,4,77,1,1,3
44,4,77,1,14,
44,4,78,1,16,
44,4,79,1,18,
44,4,80,1,44,
44,4,92,4,0,
44,4,104,4,0,
44,4,148,4,0,
44,4,156,4,0,
44,4,173,4,0,
44,4,174,4,0,
44,4,182,4,0,
44,4,188,4,0,
44,4,202,4,0,
44,4,203,4,0,
44,4,207,4,0,
44,4,213,4,0,
44,4,214,4,0,
44,4,216,4,0,
44,4,218,4,0,
44,4,230,1,1,2
44,4,230,1,7,
44,4,230,4,0,
44,4,236,1,35,
44,4,237,4,0,
44,4,241,4,0,
44,5,15,4,0,
44,5,51,1,24,
44,5,71,1,1,1
44,5,76,4,0,
44,5,77,1,1,3
44,5,77,1,14,
44,5,78,1,16,
44,5,79,1,18,
44,5,80,1,44,
44,5,92,4,0,
44,5,104,4,0,
44,5,148,4,0,
44,5,156,4,0,
44,5,182,4,0,
44,5,188,4,0,
44,5,202,4,0,
44,5,213,4,0,
44,5,216,4,0,
44,5,218,4,0,
44,5,230,1,1,2
44,5,230,1,7,
44,5,236,1,35,
44,5,237,4,0,
44,5,241,4,0,
44,5,263,4,0,
44,5,290,4,0,
44,5,331,4,0,
44,6,14,3,0,
44,6,15,4,0,
44,6,38,3,0,
44,6,51,1,24,
44,6,71,1,1,1
44,6,76,4,0,
44,6,77,1,1,3
44,6,77,1,14,
44,6,78,1,16,
44,6,79,1,18,
44,6,80,1,44,
44,6,92,4,0,
44,6,102,3,0,
44,6,104,4,0,
44,6,148,4,0,
44,6,156,4,0,
44,6,164,3,0,
44,6,173,3,0,
44,6,182,4,0,
44,6,188,4,0,
44,6,202,4,0,
44,6,203,3,0,
44,6,207,3,0,
44,6,213,4,0,
44,6,214,3,0,
44,6,216,4,0,
44,6,218,4,0,
44,6,230,1,1,2
44,6,230,1,7,
44,6,236,1,35,
44,6,237,4,0,
44,6,241,4,0,
44,6,263,4,0,
44,6,290,4,0,
44,6,331,4,0,
44,7,14,3,0,
44,7,15,4,0,
44,7,38,3,0,
44,7,51,1,24,
44,7,71,1,1,1
44,7,76,4,0,
44,7,77,1,1,3
44,7,77,1,14,
44,7,78,1,16,
44,7,79,1,18,
44,7,80,1,44,
44,7,92,4,0,
44,7,102,3,0,
44,7,104,4,0,
44,7,148,4,0,
44,7,156,4,0,
44,7,164,3,0,
44,7,182,4,0,
44,7,188,4,0,
44,7,202,4,0,
44,7,213,4,0,
44,7,216,4,0,
44,7,218,4,0,
44,7,230,1,1,2
44,7,230,1,7,
44,7,236,1,35,
44,7,237,4,0,
44,7,241,4,0,
44,7,263,4,0,
44,7,290,4,0,
44,7,331,4,0,
44,8,14,4,0,
44,8,15,4,0,
44,8,51,1,1,3
44,8,51,1,9,
44,8,71,1,1,1
44,8,72,1,23,
44,8,76,4,0,
44,8,77,1,13,
44,8,78,1,15,
44,8,79,1,17,
44,8,80,1,53,
44,8,92,4,0,
44,8,104,4,0,
44,8,148,4,0,
44,8,156,4,0,
44,8,164,4,0,
44,8,182,4,0,
44,8,188,4,0,
44,8,202,1,47,
44,8,202,4,0,
44,8,203,4,0,
44,8,207,4,0,
44,8,213,4,0,
44,8,214,4,0,
44,8,216,4,0,
44,8,218,4,0,
44,8,230,1,1,2
44,8,230,1,5,
44,8,236,1,41,
44,8,237,4,0,
44,8,241,4,0,
44,8,263,4,0,
44,8,290,4,0,
44,8,331,4,0,
44,8,363,1,35,
44,8,363,4,0,
44,8,374,4,0,
44,8,381,1,29,
44,8,409,4,0,
44,8,412,4,0,
44,8,445,4,0,
44,8,447,4,0,
44,9,14,4,0,
44,9,15,4,0,
44,9,51,1,1,3
44,9,51,1,9,
44,9,71,1,1,1
44,9,72,1,23,
44,9,76,4,0,
44,9,77,1,13,
44,9,78,1,15,
44,9,79,1,17,
44,9,80,1,53,
44,9,92,4,0,
44,9,104,4,0,
44,9,148,4,0,
44,9,156,4,0,
44,9,164,4,0,
44,9,173,3,0,
44,9,182,4,0,
44,9,188,4,0,
44,9,202,1,47,
44,9,202,4,0,
44,9,203,4,0,
44,9,207,4,0,
44,9,213,4,0,
44,9,214,4,0,
44,9,216,4,0,
44,9,218,4,0,
44,9,230,1,1,2
44,9,230,1,5,
44,9,235,3,0,
44,9,236,1,41,
44,9,237,4,0,
44,9,241,4,0,
44,9,263,4,0,
44,9,290,4,0,
44,9,331,4,0,
44,9,363,1,35,
44,9,363,4,0,
44,9,374,4,0,
44,9,380,3,0,
44,9,381,1,29,
44,9,402,3,0,
44,9,409,4,0,
44,9,412,4,0,
44,9,445,4,0,
44,9,447,4,0,
44,10,14,4,0,
44,10,15,4,0,
44,10,51,1,1,3
44,10,51,1,9,
44,10,71,1,1,1
44,10,72,1,23,
44,10,76,4,0,
44,10,77,1,13,
44,10,78,1,15,
44,10,79,1,17,
44,10,80,1,53,
44,10,92,4,0,
44,10,104,4,0,
44,10,148,4,0,
44,10,156,4,0,
44,10,164,4,0,
44,10,173,3,0,
44,10,182,4,0,
44,10,188,4,0,
44,10,202,1,47,
44,10,202,4,0,
44,10,203,4,0,
44,10,207,4,0,
44,10,213,4,0,
44,10,214,4,0,
44,10,216,4,0,
44,10,218,4,0,
44,10,230,1,1,2
44,10,230,1,5,
44,10,235,3,0,
44,10,236,1,41,
44,10,237,4,0,
44,10,241,4,0,
44,10,263,4,0,
44,10,290,4,0,
44,10,331,4,0,
44,10,363,1,35,
44,10,363,4,0,
44,10,374,4,0,
44,10,380,3,0,
44,10,381,1,29,
44,10,388,3,0,
44,10,402,3,0,
44,10,409,4,0,
44,10,412,4,0,
44,10,445,4,0,
44,10,447,4,0,
44,11,14,4,0,
44,11,15,4,0,
44,11,51,1,1,3
44,11,51,1,9,
44,11,71,1,1,1
44,11,72,1,23,
44,11,76,4,0,
44,11,77,1,13,
44,11,78,1,15,
44,11,79,1,17,
44,11,80,1,53,
44,11,92,4,0,
44,11,104,4,0,
44,11,148,4,0,
44,11,156,4,0,
44,11,164,4,0,
44,11,182,4,0,
44,11,188,4,0,
44,11,202,1,47,
44,11,207,4,0,
44,11,213,4,0,
44,11,216,4,0,
44,11,218,4,0,
44,11,230,1,1,2
44,11,230,1,5,
44,11,236,1,41,
44,11,237,4,0,
44,11,241,4,0,
44,11,263,4,0,
44,11,363,1,35,
44,11,374,4,0,
44,11,381,1,29,
44,11,412,4,0,
44,11,447,4,0,
44,11,474,4,0,
44,11,496,4,0,
44,12,15,4,0,
44,12,51,1,24,
44,12,71,1,1,1
44,12,76,4,0,
44,12,77,1,1,3
44,12,77,1,14,
44,12,78,1,16,
44,12,79,1,18,
44,12,80,1,44,
44,12,92,4,0,
44,12,104,4,0,
44,12,148,4,0,
44,12,156,4,0,
44,12,182,4,0,
44,12,188,4,0,
44,12,202,4,0,
44,12,213,4,0,
44,12,216,4,0,
44,12,218,4,0,
44,12,230,1,1,2
44,12,230,1,7,
44,12,236,1,35,
44,12,237,4,0,
44,12,241,4,0,
44,12,263,4,0,
44,12,290,4,0,
44,12,331,4,0,
44,13,15,4,0,
44,13,38,3,0,
44,13,51,1,24,
44,13,71,1,1,1
44,13,76,4,0,
44,13,77,1,1,3
44,13,77,1,14,
44,13,78,1,16,
44,13,79,1,18,
44,13,80,1,44,
44,13,92,4,0,
44,13,102,3,0,
44,13,104,4,0,
44,13,148,4,0,
44,13,156,4,0,
44,13,164,3,0,
44,13,182,4,0,
44,13,188,4,0,
44,13,202,4,0,
44,13,207,3,0,
44,13,213,4,0,
44,13,216,4,0,
44,13,218,4,0,
44,13,230,1,1,2
44,13,230,1,7,
44,13,236,1,35,
44,13,237,4,0,
44,13,241,4,0,
44,13,263,4,0,
44,13,290,4,0,
44,13,331,4,0,
44,14,14,4,0,
44,14,15,4,0,
44,14,51,1,1,3
44,14,51,1,9,
44,14,71,1,1,1
44,14,72,1,23,
44,14,76,4,0,
44,14,77,1,13,
44,14,78,1,15,
44,14,79,1,17,
44,14,80,1,53,
44,14,92,4,0,
44,14,104,4,0,
44,14,148,4,0,
44,14,156,4,0,
44,14,164,4,0,
44,14,173,3,0,
44,14,182,4,0,
44,14,188,4,0,
44,14,202,1,47,
44,14,202,3,0,
44,14,207,4,0,
44,14,213,4,0,
44,14,214,3,0,
44,14,216,4,0,
44,14,218,4,0,
44,14,230,1,1,2
44,14,230,1,5,
44,14,235,3,0,
44,14,236,1,41,
44,14,237,4,0,
44,14,241,4,0,
44,14,263,4,0,
44,14,363,1,35,
44,14,374,4,0,
44,14,380,3,0,
44,14,381,1,29,
44,14,388,3,0,
44,14,402,3,0,
44,14,409,3,0,
44,14,412,4,0,
44,14,447,4,0,
44,14,474,4,0,
44,14,495,3,0,
44,14,496,4,0,
44,15,14,4,0,
44,15,15,4,0,
44,15,51,1,1,3
44,15,51,1,9,
44,15,71,1,1,1
44,15,72,1,23,
44,15,76,4,0,
44,15,77,1,13,
44,15,78,1,15,
44,15,79,1,17,
44,15,80,1,53,
44,15,92,4,0,
44,15,104,4,0,
44,15,148,4,0,
44,15,156,4,0,
44,15,164,4,0,
44,15,182,4,0,
44,15,188,4,0,
44,15,202,1,47,
44,15,207,4,0,
44,15,213,4,0,
44,15,214,4,0,
44,15,216,4,0,
44,15,218,4,0,
44,15,230,1,1,2
44,15,230,1,5,
44,15,236,1,41,
44,15,237,4,0,
44,15,241,4,0,
44,15,263,4,0,
44,15,267,4,0,
44,15,363,1,35,
44,15,374,4,0,
44,15,381,1,29,
44,15,412,4,0,
44,15,447,4,0,
44,15,474,4,0,
44,15,496,4,0,
44,15,572,1,50,
44,15,580,1,56,
44,15,590,4,0,
44,15,605,4,0,
44,15,611,4,0,
44,16,14,4,0,
44,16,15,4,0,
44,16,51,1,1,3
44,16,51,1,9,1
44,16,71,1,1,1
44,16,72,1,19,1
44,16,76,4,0,
44,16,77,1,13,1
44,16,78,1,14,1
44,16,79,1,15,1
44,16,80,1,59,1
44,16,92,1,39,1
44,16,92,4,0,
44,16,104,4,0,
44,16,148,4,0,
44,16,156,4,0,
44,16,164,4,0,
44,16,173,3,0,
44,16,182,4,0,
44,16,188,4,0,
44,16,202,1,34,1
44,16,202,3,0,
44,16,207,4,0,
44,16,213,4,0,
44,16,214,4,0,
44,16,216,4,0,
44,16,218,4,0,
44,16,230,1,1,2
44,16,230,1,5,1
44,16,235,3,0,
44,16,236,1,29,1
44,16,237,4,0,
44,16,241,4,0,
44,16,263,4,0,
44,16,267,4,0,
44,16,290,4,0,
44,16,363,1,44,1
44,16,374,4,0,
44,16,380,3,0,
44,16,381,1,24,1
44,16,388,3,0,
44,16,402,3,0,
44,16,409,3,0,
44,16,412,4,0,
44,16,447,4,0,
44,16,474,4,0,
44,16,495,3,0,
44,16,496,4,0,
44,16,572,1,49,1
44,16,580,1,54,1
44,16,590,4,0,
44,16,605,4,0,
44,16,611,4,0,
44,17,14,4,0,
44,17,51,1,1,4
44,17,51,1,9,
44,17,71,1,1,1
44,17,72,1,19,
44,17,74,1,1,2
44,17,76,4,0,
44,17,77,1,13,
44,17,78,1,14,
44,17,79,1,15,
44,17,80,1,59,
44,17,92,1,39,
44,17,92,4,0,
44,17,104,4,0,
44,17,156,4,0,
44,17,164,4,0,
44,17,182,4,0,
44,17,188,4,0,
44,17,202,1,34,
44,17,207,4,0,
44,17,213,4,0,
44,17,214,4,0,
44,17,216,4,0,
44,17,218,4,0,
44,17,230,1,1,3
44,17,230,1,5,
44,17,236,1,29,
44,17,237,4,0,
44,17,241,4,0,
44,17,263,4,0,
44,17,267,4,0,
44,17,363,1,44,
44,17,374,4,0,
44,17,381,1,24,
44,17,412,4,0,
44,17,447,4,0,
44,17,474,4,0,
44,17,496,4,0,
44,17,572,1,49,
44,17,580,1,54,
44,17,590,4,0,
44,17,605,4,0,
44,17,611,4,0,
44,18,14,4,0,
44,18,51,1,1,4
44,18,51,1,9,
44,18,71,1,1,1
44,18,72,1,19,
44,18,74,1,1,2
44,18,76,4,0,
44,18,77,1,13,
44,18,78,1,14,
44,18,79,1,15,
44,18,80,1,59,
44,18,92,1,39,
44,18,92,4,0,
44,18,104,4,0,
44,18,156,4,0,
44,18,164,4,0,
44,18,182,4,0,
44,18,188,4,0,
44,18,202,1,34,
44,18,207,4,0,
44,18,213,4,0,
44,18,214,4,0,
44,18,216,4,0,
44,18,218,4,0,
44,18,230,1,1,3
44,18,230,1,5,
44,18,236,1,29,
44,18,237,4,0,
44,18,241,4,0,
44,18,263,4,0,
44,18,267,4,0,
44,18,363,1,44,
44,18,374,4,0,
44,18,381,1,24,
44,18,412,4,0,
44,18,447,4,0,
44,18,474,4,0,
44,18,496,4,0,
44,18,572,1,49,
44,18,580,1,54,
44,18,590,4,0,
44,18,605,4,0,
44,18,611,4,0,
45,1,14,4,0,
45,1,15,4,0,
45,1,34,4,0,
45,1,36,4,0,
45,1,38,4,0,
45,1,51,1,1,3
45,1,63,4,0,
45,1,72,4,0,
45,1,76,4,0,
45,1,77,1,15,
45,1,78,1,1,1
45,1,78,1,17,
45,1,79,1,1,2
45,1,79,1,19,
45,1,80,1,1,4
45,1,92,4,0,
45,1,99,4,0,
45,1,102,4,0,
45,1,104,4,0,
45,1,115,4,0,
45,1,117,4,0,
45,1,156,4,0,
45,1,164,4,0,
45,2,14,4,0,
45,2,15,4,0,
45,2,34,4,0,
45,2,36,4,0,
45,2,38,4,0,
45,2,51,1,1,3
45,2,63,4,0,
45,2,72,4,0,
45,2,76,4,0,
45,2,77,1,15,
45,2,78,1,1,1
45,2,78,1,17,
45,2,79,1,1,2
45,2,79,1,19,
45,2,80,1,1,4
45,2,92,4,0,
45,2,99,4,0,
45,2,102,4,0,
45,2,104,4,0,
45,2,115,4,0,
45,2,117,4,0,
45,2,156,4,0,
45,2,164,4,0,
45,3,15,4,0,
45,3,63,4,0,
45,3,71,1,1,1
45,3,76,4,0,
45,3,78,1,1,3
45,3,80,1,1,4
45,3,92,4,0,
45,3,104,4,0,
45,3,148,4,0,
45,3,156,4,0,
45,3,173,4,0,
45,3,174,4,0,
45,3,182,4,0,
45,3,188,4,0,
45,3,202,4,0,
45,3,203,4,0,
45,3,207,4,0,
45,3,213,4,0,
45,3,214,4,0,
45,3,216,4,0,
45,3,218,4,0,
45,3,230,1,1,2
45,3,230,4,0,
45,3,237,4,0,
45,3,241,4,0,
45,4,15,4,0,
45,4,63,4,0,
45,4,71,1,1,1
45,4,76,4,0,
45,4,78,1,1,3
45,4,80,1,1,4
45,4,92,4,0,
45,4,104,4,0,
45,4,148,4,0,
45,4,156,4,0,
45,4,173,4,0,
45,4,174,4,0,
45,4,182,4,0,
45,4,188,4,0,
45,4,202,4,0,
45,4,203,4,0,
45,4,207,4,0,
45,4,213,4,0,
45,4,214,4,0,
45,4,216,4,0,
45,4,218,4,0,
45,4,230,1,1,2
45,4,230,4,0,
45,4,237,4,0,
45,4,241,4,0,
45,5,15,4,0,
45,5,63,4,0,
45,5,71,1,1,1
45,5,72,1,1,4
45,5,76,4,0,
45,5,78,1,1,3
45,5,80,1,44,
45,5,92,4,0,
45,5,104,4,0,
45,5,148,4,0,
45,5,156,4,0,
45,5,182,4,0,
45,5,188,4,0,
45,5,202,4,0,
45,5,213,4,0,
45,5,216,4,0,
45,5,218,4,0,
45,5,237,4,0,
45,5,241,4,0,
45,5,263,4,0,
45,5,290,4,0,
45,5,312,1,1,2
45,5,331,4,0,
45,6,14,3,0,
45,6,15,4,0,
45,6,34,3,0,
45,6,38,3,0,
45,6,63,4,0,
45,6,71,1,1,1
45,6,72,1,1,4
45,6,76,4,0,
45,6,78,1,1,3
45,6,80,1,44,
45,6,92,4,0,
45,6,102,3,0,
45,6,104,4,0,
45,6,148,4,0,
45,6,156,4,0,
45,6,164,3,0,
45,6,173,3,0,
45,6,182,4,0,
45,6,188,4,0,
45,6,202,4,0,
45,6,203,3,0,
45,6,207,3,0,
45,6,213,4,0,
45,6,214,3,0,
45,6,216,4,0,
45,6,218,4,0,
45,6,237,4,0,
45,6,241,4,0,
45,6,263,4,0,
45,6,290,4,0,
45,6,312,1,1,2
45,6,331,4,0,
45,7,14,3,0,
45,7,15,4,0,
45,7,34,3,0,
45,7,38,3,0,
45,7,63,4,0,
45,7,71,1,1,1
45,7,72,1,1,4
45,7,76,4,0,
45,7,78,1,1,3
45,7,80,1,44,
45,7,92,4,0,
45,7,102,3,0,
45,7,104,4,0,
45,7,148,4,0,
45,7,156,4,0,
45,7,164,3,0,
45,7,182,4,0,
45,7,188,4,0,
45,7,202,4,0,
45,7,213,4,0,
45,7,216,4,0,
45,7,218,4,0,
45,7,237,4,0,
45,7,241,4,0,
45,7,263,4,0,
45,7,290,4,0,
45,7,312,1,1,2
45,7,331,4,0,
45,8,14,4,0,
45,8,15,4,0,
45,8,63,4,0,
45,8,72,1,1,1
45,8,76,1,65,
45,8,76,4,0,
45,8,77,1,1,4
45,8,78,1,1,3
45,8,80,1,53,
45,8,92,4,0,
45,8,104,4,0,
45,8,148,4,0,
45,8,156,4,0,
45,8,164,4,0,
45,8,182,4,0,
45,8,188,4,0,
45,8,202,4,0,
45,8,203,4,0,
45,8,207,4,0,
45,8,213,4,0,
45,8,214,4,0,
45,8,216,4,0,
45,8,218,4,0,
45,8,237,4,0,
45,8,241,4,0,
45,8,263,4,0,
45,8,290,4,0,
45,8,312,1,1,2
45,8,331,4,0,
45,8,363,4,0,
45,8,374,4,0,
45,8,409,4,0,
45,8,412,4,0,
45,8,416,4,0,
45,8,445,4,0,
45,8,447,4,0,
45,9,14,4,0,
45,9,15,4,0,
45,9,63,4,0,
45,9,72,1,1,1
45,9,76,1,65,
45,9,76,4,0,
45,9,77,1,1,4
45,9,78,1,1,3
45,9,80,1,53,
45,9,92,4,0,
45,9,104,4,0,
45,9,148,4,0,
45,9,156,4,0,
45,9,164,4,0,
45,9,173,3,0,
45,9,182,4,0,
45,9,188,4,0,
45,9,202,4,0,
45,9,203,4,0,
45,9,207,4,0,
45,9,213,4,0,
45,9,214,4,0,
45,9,216,4,0,
45,9,218,4,0,
45,9,235,3,0,
45,9,237,4,0,
45,9,241,4,0,
45,9,263,4,0,
45,9,290,4,0,
45,9,312,1,1,2
45,9,331,4,0,
45,9,363,4,0,
45,9,374,4,0,
45,9,380,3,0,
45,9,402,3,0,
45,9,409,4,0,
45,9,412,4,0,
45,9,416,4,0,
45,9,445,4,0,
45,9,447,4,0,
45,10,14,4,0,
45,10,15,4,0,
45,10,63,4,0,
45,10,72,1,1,1
45,10,76,1,65,
45,10,76,4,0,
45,10,77,1,1,4
45,10,78,1,1,3
45,10,80,1,53,
45,10,92,4,0,
45,10,104,4,0,
45,10,148,4,0,
45,10,156,4,0,
45,10,164,4,0,
45,10,173,3,0,
45,10,182,4,0,
45,10,188,4,0,
45,10,202,4,0,
45,10,203,4,0,
45,10,207,4,0,
45,10,213,4,0,
45,10,214,4,0,
45,10,216,4,0,
45,10,218,4,0,
45,10,235,3,0,
45,10,237,4,0,
45,10,241,4,0,
45,10,263,4,0,
45,10,290,4,0,
45,10,312,1,1,2
45,10,331,4,0,
45,10,363,4,0,
45,10,374,4,0,
45,10,380,3,0,
45,10,388,3,0,
45,10,402,3,0,
45,10,409,4,0,
45,10,412,4,0,
45,10,416,4,0,
45,10,445,4,0,
45,10,447,4,0,
45,11,14,4,0,
45,11,15,4,0,
45,11,63,4,0,
45,11,72,1,1,1
45,11,76,1,65,
45,11,76,4,0,
45,11,77,1,1,4
45,11,78,1,1,3
45,11,80,1,53,
45,11,92,4,0,
45,11,104,4,0,
45,11,148,4,0,
45,11,156,4,0,
45,11,164,4,0,
45,11,182,4,0,
45,11,188,4,0,
45,11,207,4,0,
45,11,213,4,0,
45,11,216,4,0,
45,11,218,4,0,
45,11,237,4,0,
45,11,241,4,0,
45,11,263,4,0,
45,11,312,1,1,2
45,11,374,4,0,
45,11,412,4,0,
45,11,416,4,0,
45,11,447,4,0,
45,11,474,4,0,
45,11,496,4,0,
45,12,15,4,0,
45,12,63,4,0,
45,12,71,1,1,1
45,12,72,1,1,4
45,12,76,4,0,
45,12,78,1,1,3
45,12,80,1,44,
45,12,92,4,0,
45,12,104,4,0,
45,12,148,4,0,
45,12,156,4,0,
45,12,182,4,0,
45,12,188,4,0,
45,12,202,4,0,
45,12,213,4,0,
45,12,216,4,0,
45,12,218,4,0,
45,12,237,4,0,
45,12,241,4,0,
45,12,263,4,0,
45,12,290,4,0,
45,12,312,1,1,2
45,12,331,4,0,
45,13,15,4,0,
45,13,34,3,0,
45,13,38,3,0,
45,13,63,4,0,
45,13,71,1,1,1
45,13,72,1,1,4
45,13,76,4,0,
45,13,78,1,1,3
45,13,80,1,44,
45,13,92,4,0,
45,13,102,3,0,
45,13,104,4,0,
45,13,148,4,0,
45,13,156,4,0,
45,13,164,3,0,
45,13,182,4,0,
45,13,188,4,0,
45,13,202,4,0,
45,13,207,3,0,
45,13,213,4,0,
45,13,216,4,0,
45,13,218,4,0,
45,13,237,4,0,
45,13,241,4,0,
45,13,263,4,0,
45,13,290,4,0,
45,13,312,1,1,2
45,13,331,4,0,
45,14,14,4,0,
45,14,15,4,0,
45,14,63,4,0,
45,14,72,1,1,1
45,14,76,1,65,
45,14,76,4,0,
45,14,77,1,1,4
45,14,78,1,1,3
45,14,80,1,53,
45,14,92,4,0,
45,14,104,4,0,
45,14,148,4,0,
45,14,156,4,0,
45,14,164,4,0,
45,14,173,3,0,
45,14,182,4,0,
45,14,188,4,0,
45,14,202,3,0,
45,14,207,4,0,
45,14,213,4,0,
45,14,214,3,0,
45,14,216,4,0,
45,14,218,4,0,
45,14,235,3,0,
45,14,237,4,0,
45,14,241,4,0,
45,14,263,4,0,
45,14,312,1,1,2
45,14,374,4,0,
45,14,380,3,0,
45,14,388,3,0,
45,14,402,3,0,
45,14,409,3,0,
45,14,412,4,0,
45,14,416,4,0,
45,14,447,4,0,
45,14,474,4,0,
45,14,495,3,0,
45,14,496,4,0,
45,15,14,4,0,
45,15,15,4,0,
45,15,63,4,0,
45,15,72,1,1,1
45,15,76,1,65,
45,15,76,4,0,
45,15,77,1,1,4
45,15,78,1,1,3
45,15,80,1,53,
45,15,92,4,0,
45,15,104,4,0,
45,15,148,4,0,
45,15,156,4,0,
45,15,164,4,0,
45,15,182,4,0,
45,15,188,4,0,
45,15,207,4,0,
45,15,213,4,0,
45,15,214,4,0,
45,15,216,4,0,
45,15,218,4,0,
45,15,219,4,0,
45,15,237,4,0,
45,15,241,4,0,
45,15,263,4,0,
45,15,267,4,0,
45,15,312,1,1,2
45,15,374,4,0,
45,15,412,4,0,
45,15,416,4,0,
45,15,447,4,0,
45,15,474,4,0,
45,15,496,4,0,
45,15,572,1,50,
45,15,590,4,0,
45,15,605,4,0,
45,15,611,4,0,
45,16,14,4,0,
45,16,15,4,0,
45,16,63,4,0,
45,16,72,1,1,1
45,16,76,1,64,1
45,16,76,4,0,
45,16,77,1,1,3
45,16,78,1,1,4
45,16,80,1,59,1
45,16,92,4,0,
45,16,104,4,0,
45,16,148,4,0,
45,16,156,4,0,
45,16,164,4,0,
45,16,173,3,0,
45,16,182,4,0,
45,16,188,4,0,
45,16,202,3,0,
45,16,207,4,0,
45,16,213,4,0,
45,16,214,4,0,
45,16,216,4,0,
45,16,218,4,0,
45,16,219,4,0,
45,16,235,3,0,
45,16,237,4,0,
45,16,241,4,0,
45,16,263,4,0,
45,16,267,4,0,
45,16,290,4,0,
45,16,312,1,1,2
45,16,374,4,0,
45,16,380,3,0,
45,16,388,3,0,
45,16,402,3,0,
45,16,409,3,0,
45,16,412,4,0,
45,16,416,4,0,
45,16,447,4,0,
45,16,474,4,0,
45,16,495,3,0,
45,16,496,4,0,
45,16,572,1,49,1
45,16,590,4,0,
45,16,605,4,0,
45,16,611,4,0,
45,17,14,4,0,
45,17,63,4,0,
45,17,72,1,1,1
45,17,76,1,69,
45,17,76,4,0,
45,17,77,1,1,3
45,17,78,1,1,4
45,17,80,1,59,
45,17,92,4,0,
45,17,104,4,0,
45,17,156,4,0,
45,17,164,4,0,
45,17,182,4,0,
45,17,188,4,0,
45,17,207,4,0,
45,17,213,4,0,
45,17,214,4,0,
45,17,216,4,0,
45,17,218,4,0,
45,17,219,4,0,
45,17,237,4,0,
45,17,241,4,0,
45,17,263,4,0,
45,17,267,4,0,
45,17,312,1,1,2
45,17,374,4,0,
45,17,412,4,0,
45,17,416,4,0,
45,17,447,4,0,
45,17,474,4,0,
45,17,496,4,0,
45,17,572,1,49,
45,17,590,4,0,
45,17,605,4,0,
45,17,611,4,0,
45,18,14,4,0,
45,18,63,4,0,
45,18,72,1,1,1
45,18,76,1,69,
45,18,76,4,0,
45,18,77,1,1,3
45,18,78,1,1,4
45,18,80,1,59,
45,18,92,4,0,
45,18,104,4,0,
45,18,156,4,0,
45,18,164,4,0,
45,18,182,4,0,
45,18,188,4,0,
45,18,207,4,0,
45,18,213,4,0,
45,18,214,4,0,
45,18,216,4,0,
45,18,218,4,0,
45,18,219,4,0,
45,18,237,4,0,
45,18,241,4,0,
45,18,263,4,0,
45,18,267,4,0,
45,18,312,1,1,2
45,18,374,4,0,
45,18,412,4,0,
45,18,416,4,0,
45,18,447,4,0,
45,18,474,4,0,
45,18,496,4,0,
45,18,572,1,49,
45,18,590,4,0,
45,18,605,4,0,
45,18,611,4,0,
46,1,10,1,1,
46,1,14,4,0,
46,1,15,4,0,
46,1,34,4,0,
46,1,36,4,0,
46,1,38,4,0,
46,1,72,4,0,
46,1,74,1,41,
46,1,76,4,0,
46,1,78,1,13,
46,1,91,4,0,
46,1,92,4,0,
46,1,99,4,0,
46,1,102,4,0,
46,1,104,4,0,
46,1,115,4,0,
46,1,117,4,0,
46,1,130,4,0,
46,1,141,1,20,
46,1,147,1,27,
46,1,156,4,0,
46,1,163,1,34,
46,1,164,4,0,
46,2,10,1,1,
46,2,14,4,0,
46,2,15,4,0,
46,2,34,4,0,
46,2,36,4,0,
46,2,38,4,0,
46,2,72,4,0,
46,2,74,1,41,
46,2,76,4,0,
46,2,78,1,13,
46,2,91,4,0,
46,2,92,4,0,
46,2,99,4,0,
46,2,102,4,0,
46,2,104,4,0,
46,2,115,4,0,
46,2,117,4,0,
46,2,130,4,0,
46,2,141,1,20,
46,2,147,1,27,
46,2,156,4,0,
46,2,163,1,34,
46,2,164,4,0,
46,3,10,1,1,
46,3,15,4,0,
46,3,60,2,0,
46,3,68,2,0,
46,3,74,1,37,
46,3,76,4,0,
46,3,77,1,13,
46,3,78,1,7,
46,3,91,4,0,
46,3,92,4,0,
46,3,103,2,0,
46,3,104,4,0,
46,3,113,2,0,
46,3,141,1,19,
46,3,147,1,25,
46,3,148,4,0,
46,3,156,4,0,
46,3,163,1,31,
46,3,168,4,0,
46,3,173,4,0,
46,3,174,4,0,
46,3,175,2,0,
46,3,182,4,0,
46,3,188,4,0,
46,3,202,1,43,
46,3,202,4,0,
46,3,203,4,0,
46,3,206,2,0,
46,3,207,4,0,
46,3,210,4,0,
46,3,213,4,0,
46,3,214,4,0,
46,3,216,4,0,
46,3,218,4,0,
46,3,228,2,0,
46,3,230,2,0,
46,3,230,4,0,
46,3,237,4,0,
46,3,241,4,0,
46,3,249,4,0,
46,4,10,1,1,
46,4,15,4,0,
46,4,60,2,0,
46,4,68,2,0,
46,4,74,1,37,
46,4,76,4,0,
46,4,77,1,13,
46,4,78,1,7,
46,4,91,4,0,
46,4,92,4,0,
46,4,103,2,0,
46,4,104,4,0,
46,4,113,2,0,
46,4,141,1,19,
46,4,147,1,25,
46,4,148,4,0,
46,4,156,4,0,
46,4,163,1,31,
46,4,168,4,0,
46,4,173,4,0,
46,4,174,4,0,
46,4,175,2,0,
46,4,182,4,0,
46,4,188,4,0,
46,4,202,1,43,
46,4,202,4,0,
46,4,203,4,0,
46,4,206,2,0,
46,4,207,4,0,
46,4,210,4,0,
46,4,213,4,0,
46,4,214,4,0,
46,4,216,4,0,
46,4,218,4,0,
46,4,228,2,0,
46,4,230,4,0,
46,4,237,4,0,
46,4,241,4,0,
46,4,249,4,0,
46,5,10,1,1,
46,5,15,4,0,
46,5,60,2,0,
46,5,68,2,0,
46,5,74,1,37,
46,5,76,4,0,
46,5,77,1,13,
46,5,78,1,7,
46,5,91,4,0,
46,5,92,4,0,
46,5,103,2,0,
46,5,104,4,0,
46,5,113,2,0,
46,5,141,1,19,
46,5,147,1,25,
46,5,148,4,0,
46,5,156,4,0,
46,5,163,1,31,
46,5,168,4,0,
46,5,175,2,0,
46,5,182,4,0,
46,5,188,4,0,
46,5,202,1,43,
46,5,202,4,0,
46,5,206,2,0,
46,5,213,4,0,
46,5,216,4,0,
46,5,218,4,0,
46,5,228,2,0,
46,5,230,2,0,
46,5,237,4,0,
46,5,241,4,0,
46,5,249,4,0,
46,5,263,4,0,
46,5,290,4,0,
46,5,312,1,49,
46,5,331,4,0,
46,5,332,4,0,
46,6,10,1,1,
46,6,14,3,0,
46,6,15,4,0,
46,6,34,3,0,
46,6,38,3,0,
46,6,60,2,0,
46,6,68,2,0,
46,6,68,3,0,
46,6,74,1,37,
46,6,76,4,0,
46,6,77,1,13,
46,6,78,1,7,
46,6,91,4,0,
46,6,92,4,0,
46,6,102,3,0,
46,6,103,2,0,
46,6,104,4,0,
46,6,113,2,0,
46,6,141,1,19,
46,6,147,1,25,
46,6,148,4,0,
46,6,156,4,0,
46,6,163,1,31,
46,6,164,3,0,
46,6,168,4,0,
46,6,173,3,0,
46,6,175,2,0,
46,6,182,4,0,
46,6,188,4,0,
46,6,202,1,43,
46,6,202,4,0,
46,6,203,3,0,
46,6,206,2,0,
46,6,207,3,0,
46,6,210,3,0,
46,6,213,4,0,
46,6,214,3,0,
46,6,216,4,0,
46,6,218,4,0,
46,6,228,2,0,
46,6,230,2,0,
46,6,237,4,0,
46,6,241,4,0,
46,6,249,4,0,
46,6,263,4,0,
46,6,290,4,0,
46,6,312,1,49,
46,6,331,4,0,
46,6,332,4,0,
46,7,10,1,1,
46,7,14,3,0,
46,7,15,4,0,
46,7,34,3,0,
46,7,38,3,0,
46,7,60,2,0,
46,7,68,2,0,
46,7,68,3,0,
46,7,74,1,37,
46,7,76,4,0,
46,7,77,1,13,
46,7,78,1,7,
46,7,91,4,0,
46,7,92,4,0,
46,7,102,3,0,
46,7,103,2,0,
46,7,104,4,0,
46,7,113,2,0,
46,7,141,1,19,
46,7,147,1,25,
46,7,148,4,0,
46,7,156,4,0,
46,7,163,1,31,
46,7,164,3,0,
46,7,168,4,0,
46,7,175,2,0,
46,7,182,4,0,
46,7,188,4,0,
46,7,202,1,43,
46,7,202,4,0,
46,7,206,2,0,
46,7,213,4,0,
46,7,216,4,0,
46,7,218,4,0,
46,7,228,2,0,
46,7,230,2,0,
46,7,237,4,0,
46,7,241,4,0,
46,7,249,4,0,
46,7,263,4,0,
46,7,290,4,0,
46,7,312,1,49,
46,7,331,4,0,
46,7,332,4,0,
46,8,10,1,1,
46,8,14,4,0,
46,8,15,4,0,
46,8,60,2,0,
46,8,68,2,0,
46,8,74,1,27,
46,8,76,4,0,
46,8,77,1,6,2
46,8,78,1,6,1
46,8,91,4,0,
46,8,92,4,0,
46,8,103,2,0,
46,8,104,4,0,
46,8,113,2,0,
46,8,141,1,11,
46,8,147,1,17,
46,8,148,4,0,
46,8,156,4,0,
46,8,163,1,22,
46,8,164,4,0,
46,8,168,4,0,
46,8,175,2,0,
46,8,182,4,0,
46,8,188,4,0,
46,8,202,1,33,
46,8,202,4,0,
46,8,203,4,0,
46,8,206,2,0,
46,8,206,4,0,
46,8,207,4,0,
46,8,213,4,0,
46,8,214,4,0,
46,8,216,4,0,
46,8,218,4,0,
46,8,228,2,0,
46,8,230,2,0,
46,8,232,2,0,
46,8,237,4,0,
46,8,241,4,0,
46,8,249,4,0,
46,8,263,4,0,
46,8,280,4,0,
46,8,290,4,0,
46,8,312,1,38,
46,8,331,4,0,
46,8,332,4,0,
46,8,363,4,0,
46,8,404,1,43,
46,8,404,4,0,
46,8,412,4,0,
46,8,440,2,0,
46,8,445,4,0,
46,8,447,4,0,
46,8,450,2,0,
46,9,10,1,1,
46,9,14,4,0,
46,9,15,4,0,
46,9,60,2,0,
46,9,68,2,0,
46,9,74,1,27,
46,9,76,4,0,
46,9,77,1,6,2
46,9,78,1,6,1
46,9,91,4,0,
46,9,92,4,0,
46,9,103,2,0,
46,9,104,4,0,
46,9,113,2,0,
46,9,141,1,11,
46,9,147,1,17,
46,9,148,4,0,
46,9,156,4,0,
46,9,163,1,22,
46,9,164,4,0,
46,9,168,4,0,
46,9,173,3,0,
46,9,175,2,0,
46,9,182,4,0,
46,9,188,4,0,
46,9,202,1,33,
46,9,202,4,0,
46,9,203,4,0,
46,9,206,2,0,
46,9,206,4,0,
46,9,207,4,0,
46,9,210,3,0,
46,9,213,4,0,
46,9,214,4,0,
46,9,216,4,0,
46,9,218,4,0,
46,9,228,2,0,
46,9,230,2,0,
46,9,232,2,0,
46,9,235,3,0,
46,9,237,4,0,
46,9,241,4,0,
46,9,249,4,0,
46,9,263,4,0,
46,9,280,4,0,
46,9,282,3,0,
46,9,290,4,0,
46,9,312,1,38,
46,9,331,4,0,
46,9,332,4,0,
46,9,363,4,0,
46,9,402,3,0,
46,9,404,1,43,
46,9,404,4,0,
46,9,412,4,0,
46,9,440,2,0,
46,9,445,4,0,
46,9,447,4,0,
46,9,450,2,0,
46,10,10,1,1,
46,10,14,4,0,
46,10,15,4,0,
46,10,60,2,0,
46,10,68,2,0,
46,10,74,1,27,
46,10,76,4,0,
46,10,77,1,6,2
46,10,78,1,6,1
46,10,81,3,0,
46,10,91,4,0,
46,10,92,4,0,
46,10,97,2,0,
46,10,103,2,0,
46,10,104,4,0,
46,10,113,2,0,
46,10,141,1,11,
46,10,147,1,17,
46,10,148,4,0,
46,10,156,4,0,
46,10,163,1,22,
46,10,164,4,0,
46,10,168,4,0,
46,10,173,3,0,
46,10,175,2,0,
46,10,182,4,0,
46,10,188,4,0,
46,10,202,1,33,
46,10,202,4,0,
46,10,203,4,0,
46,10,206,2,0,
46,10,206,4,0,
46,10,207,4,0,
46,10,210,3,0,
46,10,213,4,0,
46,10,214,4,0,
46,10,216,4,0,
46,10,218,4,0,
46,10,228,2,0,
46,10,230,2,0,
46,10,232,2,0,
46,10,235,3,0,
46,10,237,4,0,
46,10,241,4,0,
46,10,249,4,0,
46,10,263,4,0,
46,10,280,4,0,
46,10,282,3,0,
46,10,290,4,0,
46,10,312,1,38,
46,10,331,4,0,
46,10,332,4,0,
46,10,363,4,0,
46,10,388,3,0,
46,10,402,3,0,
46,10,404,1,43,
46,10,404,4,0,
46,10,412,4,0,
46,10,440,2,0,
46,10,445,4,0,
46,10,447,4,0,
46,10,450,2,0,
46,10,450,3,0,
46,11,10,1,1,
46,11,14,4,0,
46,11,15,4,0,
46,11,60,2,0,
46,11,68,2,0,
46,11,73,2,0,
46,11,74,1,33,
46,11,76,4,0,
46,11,77,1,6,2
46,11,78,1,6,1
46,11,91,4,0,
46,11,92,4,0,
46,11,97,2,0,
46,11,103,2,0,
46,11,104,4,0,
46,11,113,4,0,
46,11,141,1,11,
46,11,147,1,22,
46,11,148,4,0,
46,11,156,4,0,
46,11,163,1,27,
46,11,164,4,0,
46,11,168,4,0,
46,11,175,2,0,
46,11,182,4,0,
46,11,188,4,0,
46,11,202,1,38,
46,11,203,2,0,
46,11,206,4,0,
46,11,207,4,0,
46,11,210,1,17,
46,11,213,4,0,
46,11,216,4,0,
46,11,218,4,0,
46,11,228,2,0,
46,11,230,2,0,
46,11,232,2,0,
46,11,237,4,0,
46,11,241,4,0,
46,11,249,4,0,
46,11,263,4,0,
46,11,280,4,0,
46,11,312,1,43,
46,11,332,4,0,
46,11,363,2,0,
46,11,404,1,54,
46,11,404,4,0,
46,11,412,4,0,
46,11,440,2,0,
46,11,447,4,0,
46,11,450,2,0,
46,11,468,4,0,
46,11,474,4,0,
46,11,476,1,49,
46,11,496,4,0,
46,11,522,4,0,
46,12,10,1,1,
46,12,15,4,0,
46,12,74,1,37,
46,12,76,4,0,
46,12,77,1,13,
46,12,78,1,7,
46,12,91,4,0,
46,12,92,4,0,
46,12,104,4,0,
46,12,141,1,19,
46,12,147,1,25,
46,12,148,4,0,
46,12,156,4,0,
46,12,163,1,31,
46,12,168,4,0,
46,12,182,4,0,
46,12,188,4,0,
46,12,202,1,43,
46,12,202,4,0,
46,12,213,4,0,
46,12,216,4,0,
46,12,218,4,0,
46,12,237,4,0,
46,12,241,4,0,
46,12,249,4,0,
46,12,263,4,0,
46,12,290,4,0,
46,12,312,1,49,
46,12,331,4,0,
46,12,332,4,0,
46,13,10,1,1,
46,13,15,4,0,
46,13,34,3,0,
46,13,38,3,0,
46,13,74,1,37,
46,13,76,4,0,
46,13,77,1,13,
46,13,78,1,7,
46,13,91,4,0,
46,13,92,4,0,
46,13,102,3,0,
46,13,104,4,0,
46,13,141,1,19,
46,13,147,1,25,
46,13,148,4,0,
46,13,156,4,0,
46,13,163,1,31,
46,13,164,3,0,
46,13,168,4,0,
46,13,182,4,0,
46,13,188,4,0,
46,13,202,1,43,
46,13,202,4,0,
46,13,207,3,0,
46,13,213,4,0,
46,13,216,4,0,
46,13,218,4,0,
46,13,237,4,0,
46,13,241,4,0,
46,13,249,4,0,
46,13,263,4,0,
46,13,290,4,0,
46,13,312,1,49,
46,13,331,4,0,
46,13,332,4,0,
46,14,10,1,1,
46,14,14,4,0,
46,14,15,4,0,
46,14,60,2,0,
46,14,68,2,0,
46,14,73,2,0,
46,14,74,1,33,
46,14,76,4,0,
46,14,77,1,6,2
46,14,78,1,6,1
46,14,91,4,0,
46,14,92,4,0,
46,14,97,2,0,
46,14,103,2,0,
46,14,104,4,0,
46,14,113,4,0,
46,14,141,1,11,
46,14,147,1,22,
46,14,148,4,0,
46,14,156,4,0,
46,14,163,1,27,
46,14,164,4,0,
46,14,168,4,0,
46,14,173,3,0,
46,14,175,2,0,
46,14,182,4,0,
46,14,188,4,0,
46,14,202,1,38,
46,14,202,3,0,
46,14,203,2,0,
46,14,206,4,0,
46,14,207,4,0,
46,14,210,1,17,
46,14,213,4,0,
46,14,214,3,0,
46,14,216,4,0,
46,14,218,4,0,
46,14,228,2,0,
46,14,230,2,0,
46,14,232,2,0,
46,14,235,3,0,
46,14,237,4,0,
46,14,241,4,0,
46,14,249,4,0,
46,14,263,4,0,
46,14,280,4,0,
46,14,282,3,0,
46,14,312,1,43,
46,14,332,4,0,
46,14,363,2,0,
46,14,388,3,0,
46,14,402,3,0,
46,14,404,1,54,
46,14,404,4,0,
46,14,412,4,0,
46,14,440,2,0,
46,14,447,4,0,
46,14,450,2,0,
46,14,450,3,0,
46,14,468,4,0,
46,14,474,4,0,
46,14,476,1,49,
46,14,495,3,0,
46,14,496,4,0,
46,14,522,4,0,
46,15,10,1,1,
46,15,14,4,0,
46,15,15,4,0,
46,15,60,2,0,
46,15,68,2,0,
46,15,73,2,0,
46,15,74,1,33,
46,15,76,4,0,
46,15,77,1,6,2
46,15,78,1,6,1
46,15,91,4,0,
46,15,92,4,0,
46,15,97,2,0,
46,15,103,2,0,
46,15,104,4,0,
46,15,113,4,0,
46,15,141,1,11,
46,15,147,1,22,
46,15,148,4,0,
46,15,156,4,0,
46,15,163,1,27,
46,15,164,4,0,
46,15,168,4,0,
46,15,175,2,0,
46,15,182,4,0,
46,15,188,4,0,
46,15,202,1,38,
46,15,203,2,0,
46,15,206,4,0,
46,15,207,4,0,
46,15,210,1,17,
46,15,213,4,0,
46,15,214,4,0,
46,15,216,4,0,
46,15,218,4,0,
46,15,228,2,0,
46,15,230,2,0,
46,15,232,2,0,
46,15,237,4,0,
46,15,241,4,0,
46,15,249,4,0,
46,15,263,4,0,
46,15,267,4,0,
46,15,280,4,0,
46,15,312,1,43,
46,15,332,4,0,
46,15,363,2,0,
46,15,404,1,54,
46,15,404,4,0,
46,15,412,4,0,
46,15,440,2,0,
46,15,447,4,0,
46,15,450,2,0,
46,15,468,4,0,
46,15,469,2,0,
46,15,474,4,0,
46,15,476,1,49,
46,15,496,4,0,
46,15,522,4,0,
46,15,563,2,0,
46,15,565,2,0,
46,15,590,4,0,
46,16,10,1,1,1
46,16,14,4,0,
46,16,15,4,0,
46,16,60,2,0,
46,16,68,2,0,
46,16,73,2,0,
46,16,74,1,33,1
46,16,76,4,0,
46,16,77,1,6,2
46,16,78,1,6,1
46,16,91,4,0,
46,16,92,4,0,
46,16,97,2,0,
46,16,103,2,0,
46,16,104,4,0,
46,16,113,4,0,
46,16,141,1,11,1
46,16,147,1,22,1
46,16,148,4,0,
46,16,156,4,0,
46,16,163,1,27,1
46,16,164,4,0,
46,16,168,4,0,
46,16,173,3,0,
46,16,175,2,0,
46,16,182,4,0,
46,16,188,4,0,
46,16,202,1,38,1
46,16,202,3,0,
46,16,203,2,0,
46,16,206,4,0,
46,16,207,4,0,
46,16,210,1,17,1
46,16,213,4,0,
46,16,214,4,0,
46,16,216,4,0,
46,16,218,4,0,
46,16,228,2,0,
46,16,230,2,0,
46,16,232,2,0,
46,16,235,3,0,
46,16,237,4,0,
46,16,241,4,0,
46,16,249,4,0,
46,16,263,4,0,
46,16,267,4,0,
46,16,280,4,0,
46,16,282,3,0,
46,16,290,4,0,
46,16,312,1,43,1
46,16,332,4,0,
46,16,363,2,0,
46,16,388,3,0,
46,16,402,3,0,
46,16,404,1,54,1
46,16,404,4,0,
46,16,412,4,0,
46,16,440,2,0,
46,16,447,4,0,
46,16,450,2,0,
46,16,450,3,0,
46,16,468,4,0,
46,16,469,2,0,
46,16,474,4,0,
46,16,476,1,49,1
46,16,495,3,0,
46,16,496,4,0,
46,16,522,4,0,
46,16,563,2,0,
46,16,565,2,0,
46,16,590,4,0,
46,17,10,1,1,
46,17,14,4,0,
46,17,60,2,0,
46,17,68,2,0,
46,17,71,1,11,
46,17,73,2,0,
46,17,74,1,33,
46,17,76,4,0,
46,17,77,1,6,2
46,17,78,1,6,1
46,17,92,4,0,
46,17,97,2,0,
46,17,103,2,0,
46,17,104,4,0,
46,17,113,4,0,
46,17,141,4,0,
46,17,147,1,22,
46,17,156,4,0,
46,17,163,1,27,
46,17,164,4,0,
46,17,168,4,0,
46,17,175,2,0,
46,17,182,4,0,
46,17,188,4,0,
46,17,202,1,38,
46,17,203,2,0,
46,17,206,4,0,
46,17,207,4,0,
46,17,210,1,17,
46,17,213,4,0,
46,17,214,4,0,
46,17,216,4,0,
46,17,218,4,0,
46,17,228,2,0,
46,17,230,2,0,
46,17,232,2,0,
46,17,237,4,0,
46,17,241,4,0,
46,17,263,4,0,
46,17,267,4,0,
46,17,280,4,0,
46,17,312,1,43,
46,17,332,4,0,
46,17,363,2,0,
46,17,404,1,54,
46,17,404,4,0,
46,17,412,4,0,
46,17,440,2,0,
46,17,447,4,0,
46,17,450,2,0,
46,17,469,2,0,
46,17,474,4,0,
46,17,476,1,49,
46,17,496,4,0,
46,17,563,2,0,
46,17,565,2,0,
46,17,590,4,0,
46,18,10,1,1,
46,18,14,4,0,
46,18,60,2,0,
46,18,68,2,0,
46,18,71,1,11,
46,18,73,2,0,
46,18,74,1,33,
46,18,76,4,0,
46,18,77,1,6,2
46,18,78,1,6,1
46,18,92,4,0,
46,18,97,2,0,
46,18,103,2,0,
46,18,104,4,0,
46,18,113,4,0,
46,18,141,4,0,
46,18,147,1,22,
46,18,156,4,0,
46,18,163,1,27,
46,18,164,4,0,
46,18,168,4,0,
46,18,175,2,0,
46,18,182,4,0,
46,18,188,4,0,
46,18,202,1,38,
46,18,203,2,0,
46,18,206,4,0,
46,18,207,4,0,
46,18,210,1,17,
46,18,213,4,0,
46,18,214,4,0,
46,18,216,4,0,
46,18,218,4,0,
46,18,228,2,0,
46,18,230,2,0,
46,18,232,2,0,
46,18,237,4,0,
46,18,241,4,0,
46,18,263,4,0,
46,18,267,4,0,
46,18,280,4,0,
46,18,312,1,43,
46,18,332,4,0,
46,18,363,2,0,
46,18,404,1,54,
46,18,404,4,0,
46,18,412,4,0,
46,18,440,2,0,
46,18,447,4,0,
46,18,450,2,0,
46,18,469,2,0,
46,18,474,4,0,
46,18,476,1,49,
46,18,496,4,0,
46,18,563,2,0,
46,18,565,2,0,
46,18,580,2,0,
46,18,590,4,0,
47,1,10,1,1,1
47,1,14,4,0,
47,1,15,4,0,
47,1,34,4,0,
47,1,36,4,0,
47,1,38,4,0,
47,1,63,4,0,
47,1,72,4,0,
47,1,74,1,48,
47,1,76,4,0,
47,1,78,1,1,2
47,1,78,1,13,
47,1,91,4,0,
47,1,92,4,0,
47,1,99,4,0,
47,1,102,4,0,
47,1,104,4,0,
47,1,115,4,0,
47,1,117,4,0,
47,1,130,4,0,
47,1,141,1,1,3
47,1,141,1,20,
47,1,147,1,30,
47,1,156,4,0,
47,1,163,1,39,
47,1,164,4,0,
47,2,10,1,1,1
47,2,14,4,0,
47,2,15,4,0,
47,2,34,4,0,
47,2,36,4,0,
47,2,38,4,0,
47,2,63,4,0,
47,2,72,4,0,
47,2,74,1,48,
47,2,76,4,0,
47,2,78,1,1,2
47,2,78,1,13,
47,2,91,4,0,
47,2,92,4,0,
47,2,99,4,0,
47,2,102,4,0,
47,2,104,4,0,
47,2,115,4,0,
47,2,117,4,0,
47,2,130,4,0,
47,2,141,1,1,3
47,2,141,1,20,
47,2,147,1,30,
47,2,156,4,0,
47,2,163,1,39,
47,2,164,4,0,
47,3,10,1,1,1
47,3,15,4,0,
47,3,63,4,0,
47,3,74,1,46,
47,3,76,4,0,
47,3,77,1,1,3
47,3,77,1,13,
47,3,78,1,1,2
47,3,78,1,7,
47,3,91,4,0,
47,3,92,4,0,
47,3,104,4,0,
47,3,141,1,19,
47,3,147,1,28,
47,3,148,4,0,
47,3,156,4,0,
47,3,163,1,37,
47,3,168,4,0,
47,3,173,4,0,
47,3,174,4,0,
47,3,182,4,0,
47,3,188,4,0,
47,3,202,1,55,
47,3,202,4,0,
47,3,203,4,0,
47,3,207,4,0,
47,3,210,4,0,
47,3,213,4,0,
47,3,214,4,0,
47,3,216,4,0,
47,3,218,4,0,
47,3,230,4,0,
47,3,237,4,0,
47,3,241,4,0,
47,3,249,4,0,
47,4,10,1,1,1
47,4,15,4,0,
47,4,63,4,0,
47,4,74,1,46,
47,4,76,4,0,
47,4,77,1,1,3
47,4,77,1,13,
47,4,78,1,1,2
47,4,78,1,7,
47,4,91,4,0,
47,4,92,4,0,
47,4,104,4,0,
47,4,141,1,19,
47,4,147,1,28,
47,4,148,4,0,
47,4,156,4,0,
47,4,163,1,37,
47,4,168,4,0,
47,4,173,4,0,
47,4,174,4,0,
47,4,182,4,0,
47,4,188,4,0,
47,4,202,1,55,
47,4,202,4,0,
47,4,203,4,0,
47,4,207,4,0,
47,4,210,4,0,
47,4,213,4,0,
47,4,214,4,0,
47,4,216,4,0,
47,4,218,4,0,
47,4,230,4,0,
47,4,237,4,0,
47,4,241,4,0,
47,4,249,4,0,
47,5,10,1,1,1
47,5,15,4,0,
47,5,63,4,0,
47,5,74,1,43,
47,5,76,4,0,
47,5,77,1,1,3
47,5,77,1,13,
47,5,78,1,1,2
47,5,78,1,7,
47,5,91,4,0,
47,5,92,4,0,
47,5,104,4,0,
47,5,141,1,19,
47,5,147,1,27,
47,5,148,4,0,
47,5,156,4,0,
47,5,163,1,35,
47,5,168,4,0,
47,5,182,4,0,
47,5,188,4,0,
47,5,202,1,51,
47,5,202,4,0,
47,5,213,4,0,
47,5,216,4,0,
47,5,218,4,0,
47,5,237,4,0,
47,5,241,4,0,
47,5,249,4,0,
47,5,263,4,0,
47,5,290,4,0,
47,5,312,1,59,
47,5,331,4,0,
47,5,332,4,0,
47,6,10,1,1,1
47,6,14,3,0,
47,6,15,4,0,
47,6,34,3,0,
47,6,38,3,0,
47,6,63,4,0,
47,6,68,3,0,
47,6,74,1,43,
47,6,76,4,0,
47,6,77,1,1,3
47,6,77,1,13,
47,6,78,1,1,2
47,6,78,1,7,
47,6,91,4,0,
47,6,92,4,0,
47,6,102,3,0,
47,6,104,4,0,
47,6,141,1,19,
47,6,147,1,27,
47,6,148,4,0,
47,6,156,4,0,
47,6,163,1,35,
47,6,164,3,0,
47,6,168,4,0,
47,6,173,3,0,
47,6,182,4,0,
47,6,188,4,0,
47,6,202,1,51,
47,6,202,4,0,
47,6,203,3,0,
47,6,207,3,0,
47,6,210,3,0,
47,6,213,4,0,
47,6,214,3,0,
47,6,216,4,0,
47,6,218,4,0,
47,6,237,4,0,
47,6,241,4,0,
47,6,249,4,0,
47,6,263,4,0,
47,6,290,4,0,
47,6,312,1,59,
47,6,331,4,0,
47,6,332,4,0,
47,7,10,1,1,1
47,7,14,3,0,
47,7,15,4,0,
47,7,34,3,0,
47,7,38,3,0,
47,7,63,4,0,
47,7,68,3,0,
47,7,74,1,43,
47,7,76,4,0,
47,7,77,1,1,3
47,7,77,1,13,
47,7,78,1,1,2
47,7,78,1,7,
47,7,91,4,0,
47,7,92,4,0,
47,7,102,3,0,
47,7,104,4,0,
47,7,141,1,19,
47,7,147,1,27,
47,7,148,4,0,
47,7,156,4,0,
47,7,163,1,35,
47,7,164,3,0,
47,7,168,4,0,
47,7,182,4,0,
47,7,188,4,0,
47,7,202,1,51,
47,7,202,4,0,
47,7,213,4,0,
47,7,216,4,0,
47,7,218,4,0,
47,7,237,4,0,
47,7,241,4,0,
47,7,249,4,0,
47,7,263,4,0,
47,7,290,4,0,
47,7,312,1,59,
47,7,331,4,0,
47,7,332,4,0,
47,8,10,1,1,2
47,8,14,4,0,
47,8,15,4,0,
47,8,63,4,0,
47,8,74,1,30,
47,8,76,4,0,
47,8,77,1,1,4
47,8,77,1,6,2
47,8,78,1,1,3
47,8,78,1,6,1
47,8,91,4,0,
47,8,92,4,0,
47,8,104,4,0,
47,8,141,1,1,5
47,8,141,1,11,
47,8,147,1,17,
47,8,148,4,0,
47,8,156,4,0,
47,8,163,1,22,
47,8,164,4,0,
47,8,168,4,0,
47,8,182,4,0,
47,8,188,4,0,
47,8,202,1,39,
47,8,202,4,0,
47,8,203,4,0,
47,8,206,4,0,
47,8,207,4,0,
47,8,213,4,0,
47,8,214,4,0,
47,8,216,4,0,
47,8,218,4,0,
47,8,237,4,0,
47,8,241,4,0,
47,8,249,4,0,
47,8,263,4,0,
47,8,280,4,0,
47,8,290,4,0,
47,8,312,1,47,
47,8,331,4,0,
47,8,332,4,0,
47,8,363,4,0,
47,8,404,1,55,
47,8,404,4,0,
47,8,412,4,0,
47,8,416,4,0,
47,8,440,1,1,1
47,8,445,4,0,
47,8,447,4,0,
47,9,10,1,1,2
47,9,14,4,0,
47,9,15,4,0,
47,9,63,4,0,
47,9,74,1,30,
47,9,76,4,0,
47,9,77,1,1,4
47,9,77,1,6,2
47,9,78,1,1,3
47,9,78,1,6,1
47,9,91,4,0,
47,9,92,4,0,
47,9,104,4,0,
47,9,141,1,1,5
47,9,141,1,11,
47,9,147,1,17,
47,9,148,4,0,
47,9,156,4,0,
47,9,163,1,22,
47,9,164,4,0,
47,9,168,4,0,
47,9,173,3,0,
47,9,182,4,0,
47,9,188,4,0,
47,9,202,1,39,
47,9,202,4,0,
47,9,203,4,0,
47,9,206,4,0,
47,9,207,4,0,
47,9,210,3,0,
47,9,213,4,0,
47,9,214,4,0,
47,9,216,4,0,
47,9,218,4,0,
47,9,235,3,0,
47,9,237,4,0,
47,9,241,4,0,
47,9,249,4,0,
47,9,263,4,0,
47,9,280,4,0,
47,9,282,3,0,
47,9,290,4,0,
47,9,312,1,47,
47,9,331,4,0,
47,9,332,4,0,
47,9,363,4,0,
47,9,402,3,0,
47,9,404,1,55,
47,9,404,4,0,
47,9,412,4,0,
47,9,416,4,0,
47,9,440,1,1,1
47,9,445,4,0,
47,9,447,4,0,
47,10,10,1,1,2
47,10,14,4,0,
47,10,15,4,0,
47,10,63,4,0,
47,10,74,1,30,
47,10,76,4,0,
47,10,77,1,1,4
47,10,77,1,6,2
47,10,78,1,1,3
47,10,78,1,6,1
47,10,81,3,0,
47,10,91,4,0,
47,10,92,4,0,
47,10,104,4,0,
47,10,141,1,1,5
47,10,141,1,11,
47,10,147,1,17,
47,10,148,4,0,
47,10,156,4,0,
47,10,163,1,22,
47,10,164,4,0,
47,10,168,4,0,
47,10,173,3,0,
47,10,182,4,0,
47,10,188,4,0,
47,10,202,1,39,
47,10,202,4,0,
47,10,203,4,0,
47,10,206,4,0,
47,10,207,4,0,
47,10,210,3,0,
47,10,213,4,0,
47,10,214,4,0,
47,10,216,4,0,
47,10,218,4,0,
47,10,235,3,0,
47,10,237,4,0,
47,10,241,4,0,
47,10,249,4,0,
47,10,263,4,0,
47,10,280,4,0,
47,10,282,3,0,
47,10,290,4,0,
47,10,312,1,47,
47,10,331,4,0,
47,10,332,4,0,
47,10,363,4,0,
47,10,388,3,0,
47,10,402,3,0,
47,10,404,1,55,
47,10,404,4,0,
47,10,412,4,0,
47,10,416,4,0,
47,10,440,1,1,1
47,10,445,4,0,
47,10,447,4,0,
47,10,450,3,0,
47,11,10,1,1,2
47,11,14,4,0,
47,11,15,4,0,
47,11,63,4,0,
47,11,74,1,37,
47,11,76,4,0,
47,11,77,1,1,4
47,11,77,1,6,2
47,11,78,1,1,3
47,11,78,1,6,1
47,11,91,4,0,
47,11,92,4,0,
47,11,104,4,0,
47,11,113,4,0,
47,11,141,1,1,5
47,11,141,1,11,
47,11,147,1,22,
47,11,148,4,0,
47,11,156,4,0,
47,11,163,1,29,
47,11,164,4,0,
47,11,168,4,0,
47,11,182,4,0,
47,11,188,4,0,
47,11,202,1,44,
47,11,206,4,0,
47,11,207,4,0,
47,11,210,1,17,
47,11,213,4,0,
47,11,216,4,0,
47,11,218,4,0,
47,11,237,4,0,
47,11,241,4,0,
47,11,249,4,0,
47,11,263,4,0,
47,11,280,4,0,
47,11,312,1,51,
47,11,332,4,0,
47,11,404,1,66,
47,11,404,4,0,
47,11,412,4,0,
47,11,416,4,0,
47,11,440,1,1,1
47,11,447,4,0,
47,11,468,4,0,
47,11,474,4,0,
47,11,476,1,59,
47,11,496,4,0,
47,11,522,4,0,
47,12,10,1,1,1
47,12,15,4,0,
47,12,63,4,0,
47,12,74,1,43,
47,12,76,4,0,
47,12,77,1,1,3
47,12,77,1,13,
47,12,78,1,1,2
47,12,78,1,7,
47,12,91,4,0,
47,12,92,4,0,
47,12,104,4,0,
47,12,141,1,19,
47,12,147,1,27,
47,12,148,4,0,
47,12,156,4,0,
47,12,163,1,35,
47,12,168,4,0,
47,12,182,4,0,
47,12,188,4,0,
47,12,202,1,51,
47,12,202,4,0,
47,12,213,4,0,
47,12,216,4,0,
47,12,218,4,0,
47,12,237,4,0,
47,12,241,4,0,
47,12,249,4,0,
47,12,263,4,0,
47,12,290,4,0,
47,12,312,1,59,
47,12,331,4,0,
47,12,332,4,0,
47,13,10,1,1,1
47,13,15,4,0,
47,13,34,3,0,
47,13,38,3,0,
47,13,63,4,0,
47,13,74,1,43,
47,13,76,4,0,
47,13,77,1,1,3
47,13,77,1,13,
47,13,78,1,1,2
47,13,78,1,7,
47,13,91,4,0,
47,13,92,4,0,
47,13,102,3,0,
47,13,104,4,0,
47,13,141,1,19,
47,13,147,1,27,
47,13,148,4,0,
47,13,156,4,0,
47,13,163,1,35,
47,13,164,3,0,
47,13,168,4,0,
47,13,182,4,0,
47,13,188,4,0,
47,13,202,1,51,
47,13,202,4,0,
47,13,207,3,0,
47,13,213,4,0,
47,13,216,4,0,
47,13,218,4,0,
47,13,237,4,0,
47,13,241,4,0,
47,13,249,4,0,
47,13,263,4,0,
47,13,290,4,0,
47,13,312,1,59,
47,13,331,4,0,
47,13,332,4,0,
47,14,10,1,1,2
47,14,14,4,0,
47,14,15,4,0,
47,14,63,4,0,
47,14,74,1,37,
47,14,76,4,0,
47,14,77,1,1,4
47,14,77,1,6,2
47,14,78,1,1,3
47,14,78,1,6,1
47,14,91,4,0,
47,14,92,4,0,
47,14,104,4,0,
47,14,113,4,0,
47,14,141,1,1,5
47,14,141,1,11,
47,14,147,1,22,
47,14,148,4,0,
47,14,156,4,0,
47,14,163,1,29,
47,14,164,4,0,
47,14,168,4,0,
47,14,173,3,0,
47,14,182,4,0,
47,14,188,4,0,
47,14,202,1,44,
47,14,202,3,0,
47,14,206,4,0,
47,14,207,4,0,
47,14,210,1,17,
47,14,213,4,0,
47,14,214,3,0,
47,14,216,4,0,
47,14,218,4,0,
47,14,235,3,0,
47,14,237,4,0,
47,14,241,4,0,
47,14,249,4,0,
47,14,263,4,0,
47,14,280,4,0,
47,14,282,3,0,
47,14,312,1,51,
47,14,332,4,0,
47,14,388,3,0,
47,14,402,3,0,
47,14,404,1,66,
47,14,404,4,0,
47,14,412,4,0,
47,14,416,4,0,
47,14,440,1,1,1
47,14,447,4,0,
47,14,450,3,0,
47,14,468,4,0,
47,14,474,4,0,
47,14,476,1,59,
47,14,495,3,0,
47,14,496,4,0,
47,14,522,4,0,
47,15,10,1,1,2
47,15,14,4,0,
47,15,15,4,0,
47,15,63,4,0,
47,15,74,1,37,
47,15,76,4,0,
47,15,77,1,1,4
47,15,77,1,6,2
47,15,78,1,1,3
47,15,78,1,6,1
47,15,91,4,0,
47,15,92,4,0,
47,15,104,4,0,
47,15,113,4,0,
47,15,141,1,1,5
47,15,141,1,11,
47,15,147,1,22,
47,15,148,4,0,
47,15,156,4,0,
47,15,163,1,29,
47,15,164,4,0,
47,15,168,4,0,
47,15,182,4,0,
47,15,188,4,0,
47,15,202,1,44,
47,15,206,4,0,
47,15,207,4,0,
47,15,210,1,17,
47,15,213,4,0,
47,15,214,4,0,
47,15,216,4,0,
47,15,218,4,0,
47,15,237,4,0,
47,15,241,4,0,
47,15,249,4,0,
47,15,263,4,0,
47,15,267,4,0,
47,15,280,4,0,
47,15,312,1,51,
47,15,332,4,0,
47,15,404,1,66,
47,15,404,4,0,
47,15,412,4,0,
47,15,416,4,0,
47,15,440,1,1,1
47,15,447,4,0,
47,15,468,4,0,
47,15,474,4,0,
47,15,476,1,59,
47,15,496,4,0,
47,15,522,4,0,
47,15,590,4,0,
47,16,10,1,1,2
47,16,14,4,0,
47,16,15,4,0,
47,16,63,4,0,
47,16,74,1,37,1
47,16,76,4,0,
47,16,77,1,1,4
47,16,77,1,6,2
47,16,78,1,1,3
47,16,78,1,6,1
47,16,91,4,0,
47,16,92,4,0,
47,16,104,4,0,
47,16,113,4,0,
47,16,141,1,1,5
47,16,141,1,11,1
47,16,147,1,22,1
47,16,148,4,0,
47,16,156,4,0,
47,16,163,1,29,1
47,16,164,4,0,
47,16,168,4,0,
47,16,173,3,0,
47,16,182,4,0,
47,16,188,4,0,
47,16,202,1,44,1
47,16,202,3,0,
47,16,206,4,0,
47,16,207,4,0,
47,16,210,1,17,1
47,16,213,4,0,
47,16,214,4,0,
47,16,216,4,0,
47,16,218,4,0,
47,16,235,3,0,
47,16,237,4,0,
47,16,241,4,0,
47,16,249,4,0,
47,16,263,4,0,
47,16,267,4,0,
47,16,280,4,0,
47,16,282,3,0,
47,16,290,4,0,
47,16,312,1,51,1
47,16,332,4,0,
47,16,388,3,0,
47,16,402,3,0,
47,16,404,1,66,1
47,16,404,4,0,
47,16,412,4,0,
47,16,416,4,0,
47,16,440,1,1,1
47,16,447,4,0,
47,16,450,3,0,
47,16,468,4,0,
47,16,474,4,0,
47,16,476,1,59,1
47,16,495,3,0,
47,16,496,4,0,
47,16,522,4,0,
47,16,590,4,0,
47,17,10,1,1,2
47,17,14,4,0,
47,17,63,4,0,
47,17,71,1,1,5
47,17,71,1,11,
47,17,74,1,37,
47,17,76,4,0,
47,17,77,1,1,4
47,17,77,1,6,2
47,17,78,1,1,3
47,17,78,1,6,1
47,17,92,4,0,
47,17,104,4,0,
47,17,113,4,0,
47,17,141,4,0,
47,17,147,1,22,
47,17,156,4,0,
47,17,163,1,29,
47,17,164,4,0,
47,17,168,4,0,
47,17,182,4,0,
47,17,188,4,0,
47,17,202,1,44,
47,17,206,4,0,
47,17,207,4,0,
47,17,210,1,17,
47,17,213,4,0,
47,17,214,4,0,
47,17,216,4,0,
47,17,218,4,0,
47,17,237,4,0,
47,17,241,4,0,
47,17,263,4,0,
47,17,267,4,0,
47,17,280,4,0,
47,17,312,1,51,
47,17,332,4,0,
47,17,404,1,66,
47,17,404,4,0,
47,17,412,4,0,
47,17,416,4,0,
47,17,440,1,1,1
47,17,447,4,0,
47,17,474,4,0,
47,17,476,1,59,
47,17,496,4,0,
47,17,590,4,0,
47,18,10,1,1,2
47,18,14,4,0,
47,18,63,4,0,
47,18,71,1,1,5
47,18,71,1,11,
47,18,74,1,37,
47,18,76,4,0,
47,18,77,1,1,4
47,18,77,1,6,2
47,18,78,1,1,3
47,18,78,1,6,1
47,18,92,4,0,
47,18,104,4,0,
47,18,113,4,0,
47,18,141,4,0,
47,18,147,1,22,
47,18,156,4,0,
47,18,163,1,29,
47,18,164,4,0,
47,18,168,4,0,
47,18,182,4,0,
47,18,188,4,0,
47,18,202,1,44,
47,18,206,4,0,
47,18,207,4,0,
47,18,210,1,17,
47,18,213,4,0,
47,18,214,4,0,
47,18,216,4,0,
47,18,218,4,0,
47,18,237,4,0,
47,18,241,4,0,
47,18,263,4,0,
47,18,267,4,0,
47,18,280,4,0,
47,18,312,1,51,
47,18,332,4,0,
47,18,404,1,66,
47,18,404,4,0,
47,18,412,4,0,
47,18,416,4,0,
47,18,440,1,1,1
47,18,447,4,0,
47,18,474,4,0,
47,18,476,1,59,
47,18,496,4,0,
47,18,590,4,0,
48,1,33,1,1,1
48,1,36,4,0,
48,1,38,4,0,
48,1,50,1,1,2
48,1,60,1,35,
48,1,72,4,0,
48,1,76,4,0,
48,1,77,1,24,
48,1,78,1,30,
48,1,79,1,38,
48,1,92,4,0,
48,1,94,1,43,
48,1,94,4,0,
48,1,99,4,0,
48,1,102,4,0,
48,1,104,4,0,
48,1,115,4,0,
48,1,117,4,0,
48,1,141,1,27,
48,1,149,4,0,
48,1,156,4,0,
48,1,164,4,0,
48,2,33,1,1,1
48,2,36,4,0,
48,2,38,4,0,
48,2,48,1,11,
48,2,50,1,1,2
48,2,60,1,35,
48,2,72,4,0,
48,2,76,4,0,
48,2,77,1,22,
48,2,78,1,30,
48,2,79,1,38,
48,2,92,4,0,
48,2,93,1,19,
48,2,94,1,43,
48,2,94,4,0,
48,2,99,4,0,
48,2,102,4,0,
48,2,104,4,0,
48,2,115,4,0,
48,2,117,4,0,
48,2,141,1,27,
48,2,148,4,0,
48,2,149,4,0,
48,2,156,4,0,
48,2,164,4,0,
48,3,33,1,1,1
48,3,48,1,9,
48,3,50,1,1,2
48,3,60,1,33,
48,3,76,4,0,
48,3,77,1,20,
48,3,78,1,28,
48,3,79,1,36,
48,3,92,4,0,
48,3,93,1,17,
48,3,94,1,41,
48,3,94,4,0,
48,3,103,2,0,
48,3,104,4,0,
48,3,129,4,0,
48,3,141,1,25,
48,3,156,4,0,
48,3,168,4,0,
48,3,173,4,0,
48,3,174,4,0,
48,3,182,4,0,
48,3,188,4,0,
48,3,193,1,1,3
48,3,202,2,0,
48,3,202,4,0,
48,3,203,4,0,
48,3,207,4,0,
48,3,213,4,0,
48,3,214,4,0,
48,3,216,4,0,
48,3,218,4,0,
48,3,226,2,0,
48,3,230,4,0,
48,3,237,4,0,
48,3,241,4,0,
48,4,33,1,1,1
48,4,48,1,9,
48,4,50,1,1,2
48,4,60,1,33,
48,4,76,4,0,
48,4,77,1,20,
48,4,78,1,28,
48,4,79,1,36,
48,4,92,4,0,
48,4,93,1,17,
48,4,94,1,41,
48,4,94,4,0,
48,4,103,2,0,
48,4,104,4,0,
48,4,129,4,0,
48,4,141,1,25,
48,4,156,4,0,
48,4,168,4,0,
48,4,173,4,0,
48,4,174,4,0,
48,4,182,4,0,
48,4,188,4,0,
48,4,193,1,1,3
48,4,202,2,0,
48,4,202,4,0,
48,4,203,4,0,
48,4,207,4,0,
48,4,213,4,0,
48,4,214,4,0,
48,4,216,4,0,
48,4,218,4,0,
48,4,226,2,0,
48,4,230,4,0,
48,4,237,4,0,
48,4,241,4,0,
48,5,33,1,1,1
48,5,48,1,9,
48,5,50,1,1,2
48,5,60,1,33,
48,5,76,4,0,
48,5,77,1,20,
48,5,78,1,28,
48,5,79,1,36,
48,5,92,4,0,
48,5,93,1,17,
48,5,94,1,41,
48,5,94,4,0,
48,5,103,2,0,
48,5,104,4,0,
48,5,141,1,25,
48,5,148,4,0,
48,5,156,4,0,
48,5,168,4,0,
48,5,182,4,0,
48,5,188,4,0,
48,5,193,1,1,3
48,5,202,2,0,
48,5,202,4,0,
48,5,213,4,0,
48,5,216,4,0,
48,5,218,4,0,
48,5,226,2,0,
48,5,237,4,0,
48,5,241,4,0,
48,5,263,4,0,
48,5,285,4,0,
48,5,290,4,0,
48,5,324,2,0,
48,6,33,1,1,1
48,6,38,3,0,
48,6,48,1,9,
48,6,50,1,1,2
48,6,60,1,33,
48,6,76,4,0,
48,6,77,1,20,
48,6,78,1,28,
48,6,79,1,36,
48,6,92,4,0,
48,6,93,1,17,
48,6,94,1,41,
48,6,94,4,0,
48,6,102,3,0,
48,6,103,2,0,
48,6,104,4,0,
48,6,129,3,0,
48,6,141,1,25,
48,6,148,4,0,
48,6,156,4,0,
48,6,164,3,0,
48,6,168,4,0,
48,6,173,3,0,
48,6,182,4,0,
48,6,188,4,0,
48,6,193,1,1,3
48,6,202,2,0,
48,6,202,4,0,
48,6,203,3,0,
48,6,207,3,0,
48,6,213,4,0,
48,6,214,3,0,
48,6,216,4,0,
48,6,218,4,0,
48,6,226,2,0,
48,6,237,4,0,
48,6,241,4,0,
48,6,263,4,0,
48,6,285,4,0,
48,6,290,4,0,
48,6,324,2,0,
48,7,33,1,1,1
48,7,38,3,0,
48,7,48,1,9,
48,7,50,1,1,2
48,7,60,1,33,
48,7,76,4,0,
48,7,77,1,20,
48,7,78,1,28,
48,7,79,1,36,
48,7,92,4,0,
48,7,93,1,17,
48,7,94,1,41,
48,7,94,4,0,
48,7,102,3,0,
48,7,103,2,0,
48,7,104,4,0,
48,7,141,1,25,
48,7,148,4,0,
48,7,156,4,0,
48,7,164,3,0,
48,7,168,4,0,
48,7,182,4,0,
48,7,188,4,0,
48,7,193,1,1,3
48,7,202,2,0,
48,7,202,4,0,
48,7,213,4,0,
48,7,216,4,0,
48,7,218,4,0,
48,7,226,2,0,
48,7,237,4,0,
48,7,241,4,0,
48,7,263,4,0,
48,7,285,4,0,
48,7,290,4,0,
48,7,324,2,0,
48,8,33,1,1,1
48,8,48,1,5,
48,8,50,1,1,2
48,8,60,1,25,
48,8,76,4,0,
48,8,77,1,13,
48,8,78,1,23,
48,8,79,1,29,
48,8,92,4,0,
48,8,93,1,11,
48,8,94,1,47,
48,8,94,4,0,
48,8,97,2,0,
48,8,103,2,0,
48,8,104,4,0,
48,8,141,1,17,
48,8,148,4,0,
48,8,156,4,0,
48,8,164,4,0,
48,8,168,4,0,
48,8,182,4,0,
48,8,188,4,0,
48,8,193,1,1,3
48,8,202,2,0,
48,8,202,4,0,
48,8,203,4,0,
48,8,207,4,0,
48,8,213,4,0,
48,8,214,4,0,
48,8,216,4,0,
48,8,218,4,0,
48,8,226,2,0,
48,8,234,2,0,
48,8,237,4,0,
48,8,241,4,0,
48,8,263,4,0,
48,8,285,4,0,
48,8,290,4,0,
48,8,305,1,41,
48,8,324,1,35,
48,8,324,2,0,
48,8,363,4,0,
48,8,390,2,0,
48,8,428,1,37,
48,8,445,4,0,
48,8,450,2,0,
48,9,33,1,1,1
48,9,48,1,5,
48,9,50,1,1,2
48,9,60,1,25,
48,9,76,4,0,
48,9,77,1,13,
48,9,78,1,23,
48,9,79,1,29,
48,9,92,4,0,
48,9,93,1,11,
48,9,94,1,47,
48,9,94,4,0,
48,9,97,2,0,
48,9,103,2,0,
48,9,104,4,0,
48,9,129,3,0,
48,9,141,1,17,
48,9,148,4,0,
48,9,156,4,0,
48,9,164,4,0,
48,9,168,4,0,
48,9,173,3,0,
48,9,182,4,0,
48,9,188,4,0,
48,9,193,1,1,3
48,9,202,2,0,
48,9,202,4,0,
48,9,203,4,0,
48,9,207,4,0,
48,9,213,4,0,
48,9,214,4,0,
48,9,216,4,0,
48,9,218,4,0,
48,9,226,2,0,
48,9,234,2,0,
48,9,237,4,0,
48,9,241,4,0,
48,9,263,4,0,
48,9,285,4,0,
48,9,290,4,0,
48,9,305,1,41,
48,9,324,1,35,
48,9,324,2,0,
48,9,324,3,0,
48,9,363,4,0,
48,9,390,2,0,
48,9,428,1,37,
48,9,428,3,0,
48,9,445,4,0,
48,9,450,2,0,
48,10,33,1,1,1
48,10,48,1,5,
48,10,50,1,1,2
48,10,60,1,25,
48,10,76,4,0,
48,10,77,1,13,
48,10,78,1,23,
48,10,79,1,29,
48,10,81,3,0,
48,10,92,4,0,
48,10,93,1,11,
48,10,94,1,47,
48,10,94,4,0,
48,10,97,2,0,
48,10,103,2,0,
48,10,104,4,0,
48,10,129,3,0,
48,10,141,1,17,
48,10,148,4,0,
48,10,156,4,0,
48,10,164,4,0,
48,10,168,4,0,
48,10,173,3,0,
48,10,182,4,0,
48,10,188,4,0,
48,10,193,1,1,3
48,10,202,2,0,
48,10,202,4,0,
48,10,203,4,0,
48,10,207,4,0,
48,10,213,4,0,
48,10,214,4,0,
48,10,216,4,0,
48,10,218,4,0,
48,10,226,2,0,
48,10,234,2,0,
48,10,237,4,0,
48,10,241,4,0,
48,10,263,4,0,
48,10,285,4,0,
48,10,290,4,0,
48,10,305,1,41,
48,10,324,1,35,
48,10,324,2,0,
48,10,324,3,0,
48,10,363,4,0,
48,10,390,2,0,
48,10,428,1,37,
48,10,428,3,0,
48,10,445,4,0,
48,10,450,2,0,
48,10,450,3,0,
48,11,33,1,1,1
48,11,48,1,5,
48,11,50,1,1,2
48,11,60,1,25,
48,11,76,4,0,
48,11,77,1,13,
48,11,78,1,23,
48,11,79,1,29,
48,11,92,4,0,
48,11,93,1,11,
48,11,94,1,47,
48,11,94,4,0,
48,11,97,2,0,
48,11,103,2,0,
48,11,104,4,0,
48,11,141,1,17,
48,11,148,4,0,
48,11,156,4,0,
48,11,164,4,0,
48,11,168,4,0,
48,11,182,4,0,
48,11,188,4,0,
48,11,193,1,1,3
48,11,202,2,0,
48,11,207,4,0,
48,11,213,4,0,
48,11,216,4,0,
48,11,218,4,0,
48,11,226,2,0,
48,11,234,2,0,
48,11,237,4,0,
48,11,241,4,0,
48,11,263,4,0,
48,11,285,2,0,
48,11,290,2,0,
48,11,305,1,41,
48,11,324,1,35,
48,11,324,2,0,
48,11,390,2,0,
48,11,428,1,37,
48,11,450,2,0,
48,11,474,4,0,
48,11,476,2,0,
48,11,496,4,0,
48,11,522,4,0,
48,12,33,1,1,1
48,12,48,1,9,
48,12,50,1,1,2
48,12,60,1,33,
48,12,76,4,0,
48,12,77,1,20,
48,12,78,1,28,
48,12,79,1,36,
48,12,92,4,0,
48,12,93,1,17,
48,12,94,1,41,
48,12,94,4,0,
48,12,104,4,0,
48,12,141,1,25,
48,12,148,4,0,
48,12,156,4,0,
48,12,168,4,0,
48,12,182,4,0,
48,12,188,4,0,
48,12,193,1,1,3
48,12,202,4,0,
48,12,213,4,0,
48,12,216,4,0,
48,12,218,4,0,
48,12,237,4,0,
48,12,241,4,0,
48,12,263,4,0,
48,12,285,4,0,
48,12,290,4,0,
48,13,33,1,1,1
48,13,38,3,0,
48,13,48,1,9,
48,13,50,1,1,2
48,13,60,1,33,
48,13,76,4,0,
48,13,77,1,20,
48,13,78,1,28,
48,13,79,1,36,
48,13,92,4,0,
48,13,93,1,17,
48,13,94,1,41,
48,13,94,4,0,
48,13,102,3,0,
48,13,104,4,0,
48,13,141,1,25,
48,13,148,4,0,
48,13,156,4,0,
48,13,164,3,0,
48,13,168,4,0,
48,13,182,4,0,
48,13,188,4,0,
48,13,193,1,1,3
48,13,202,4,0,
48,13,207,3,0,
48,13,213,4,0,
48,13,216,4,0,
48,13,218,4,0,
48,13,237,4,0,
48,13,241,4,0,
48,13,263,4,0,
48,13,285,4,0,
48,13,290,4,0,
48,14,33,1,1,1
48,14,48,1,5,
48,14,50,1,1,2
48,14,60,1,25,
48,14,76,4,0,
48,14,77,1,13,
48,14,78,1,23,
48,14,79,1,29,
48,14,92,4,0,
48,14,93,1,11,
48,14,94,1,47,
48,14,94,4,0,
48,14,97,2,0,
48,14,103,2,0,
48,14,104,4,0,
48,14,141,1,17,
48,14,148,4,0,
48,14,156,4,0,
48,14,164,4,0,
48,14,168,4,0,
48,14,173,3,0,
48,14,182,4,0,
48,14,188,4,0,
48,14,193,1,1,3
48,14,202,2,0,
48,14,202,3,0,
48,14,207,4,0,
48,14,213,4,0,
48,14,214,3,0,
48,14,216,4,0,
48,14,218,4,0,
48,14,226,2,0,
48,14,234,2,0,
48,14,237,4,0,
48,14,241,4,0,
48,14,263,4,0,
48,14,285,2,0,
48,14,285,3,0,
48,14,290,2,0,
48,14,305,1,41,
48,14,324,1,35,
48,14,324,2,0,
48,14,324,3,0,
48,14,390,2,0,
48,14,428,1,37,
48,14,428,3,0,
48,14,450,2,0,
48,14,450,3,0,
48,14,474,4,0,
48,14,476,2,0,
48,14,496,4,0,
48,14,522,4,0,
48,15,33,1,1,1
48,15,48,1,5,
48,15,50,1,1,2
48,15,60,1,25,
48,15,76,4,0,
48,15,77,1,13,
48,15,78,1,23,
48,15,79,1,29,
48,15,92,4,0,
48,15,93,1,11,
48,15,94,1,47,
48,15,94,4,0,
48,15,97,2,0,
48,15,103,2,0,
48,15,104,4,0,
48,15,141,1,17,
48,15,148,4,0,
48,15,156,4,0,
48,15,164,4,0,
48,15,168,4,0,
48,15,182,4,0,
48,15,188,4,0,
48,15,193,1,1,3
48,15,202,2,0,
48,15,207,4,0,
48,15,213,4,0,
48,15,214,4,0,
48,15,216,4,0,
48,15,218,4,0,
48,15,226,2,0,
48,15,234,2,0,
48,15,237,4,0,
48,15,241,4,0,
48,15,263,4,0,
48,15,285,2,0,
48,15,290,2,0,
48,15,305,1,41,
48,15,324,1,35,
48,15,324,2,0,
48,15,390,2,0,
48,15,428,1,37,
48,15,450,2,0,
48,15,474,4,0,
48,15,476,2,0,
48,15,496,4,0,
48,15,522,4,0,
48,15,590,4,0,
48,15,611,4,0,
48,16,33,1,1,1
48,16,48,1,5,1
48,16,50,1,1,2
48,16,60,1,25,1
48,16,76,4,0,
48,16,77,1,13,1
48,16,78,1,23,1
48,16,79,1,29,1
48,16,92,4,0,
48,16,93,1,11,1
48,16,94,1,47,1
48,16,94,4,0,
48,16,97,2,0,
48,16,103,2,0,
48,16,104,4,0,
48,16,141,1,17,1
48,16,148,4,0,
48,16,156,4,0,
48,16,164,4,0,
48,16,168,4,0,
48,16,173,3,0,
48,16,182,4,0,
48,16,188,4,0,
48,16,193,1,1,3
48,16,202,2,0,
48,16,202,3,0,
48,16,207,4,0,
48,16,213,4,0,
48,16,214,4,0,
48,16,216,4,0,
48,16,218,4,0,
48,16,226,2,0,
48,16,234,2,0,
48,16,237,4,0,
48,16,241,4,0,
48,16,263,4,0,
48,16,285,2,0,
48,16,285,3,0,
48,16,290,2,0,
48,16,290,4,0,
48,16,305,1,41,1
48,16,324,1,35,1
48,16,324,2,0,
48,16,324,3,0,
48,16,390,2,0,
48,16,428,1,37,1
48,16,428,3,0,
48,16,450,2,0,
48,16,450,3,0,
48,16,474,4,0,
48,16,476,2,0,
48,16,496,4,0,
48,16,522,4,0,
48,16,590,4,0,
48,16,611,4,0,
48,17,33,1,1,1
48,17,48,1,5,
48,17,50,1,1,2
48,17,60,1,17,
48,17,76,4,0,
48,17,77,1,13,
48,17,78,1,23,
48,17,79,1,29,
48,17,92,4,0,
48,17,93,1,11,
48,17,94,1,47,
48,17,94,4,0,
48,17,97,2,0,
48,17,103,2,0,
48,17,104,4,0,
48,17,141,1,35,
48,17,141,4,0,
48,17,156,4,0,
48,17,164,4,0,
48,17,168,4,0,
48,17,182,4,0,
48,17,188,4,0,
48,17,193,1,1,3
48,17,202,2,0,
48,17,207,4,0,
48,17,213,4,0,
48,17,214,4,0,
48,17,216,4,0,
48,17,218,4,0,
48,17,226,2,0,
48,17,234,2,0,
48,17,237,4,0,
48,17,241,4,0,
48,17,263,4,0,
48,17,285,2,0,
48,17,290,2,0,
48,17,305,1,41,
48,17,324,1,25,
48,17,324,2,0,
48,17,390,2,0,
48,17,428,1,37,
48,17,450,2,0,
48,17,474,4,0,
48,17,476,2,0,
48,17,496,4,0,
48,17,590,4,0,
48,17,611,4,0,
48,18,33,1,1,1
48,18,48,1,5,
48,18,50,1,1,2
48,18,60,1,17,
48,18,76,4,0,
48,18,77,1,13,
48,18,78,1,23,
48,18,79,1,29,
48,18,92,4,0,
48,18,93,1,11,
48,18,94,1,47,
48,18,94,4,0,
48,18,97,2,0,
48,18,103,2,0,
48,18,104,4,0,
48,18,141,1,35,
48,18,141,4,0,
48,18,156,4,0,
48,18,164,4,0,
48,18,168,4,0,
48,18,182,4,0,
48,18,188,4,0,
48,18,193,1,1,3
48,18,202,2,0,
48,18,207,4,0,
48,18,213,4,0,
48,18,214,4,0,
48,18,216,4,0,
48,18,218,4,0,
48,18,226,2,0,
48,18,234,2,0,
48,18,237,4,0,
48,18,241,4,0,
48,18,263,4,0,
48,18,285,2,0,
48,18,290,2,0,
48,18,305,1,41,
48,18,324,1,25,
48,18,324,2,0,
48,18,390,2,0,
48,18,428,1,37,
48,18,450,2,0,
48,18,474,4,0,
48,18,476,2,0,
48,18,496,4,0,
48,18,590,4,0,
48,18,611,4,0,
49,1,13,4,0,
49,1,18,4,0,
49,1,33,1,1,1
49,1,36,4,0,
49,1,38,4,0,
49,1,50,1,1,2
49,1,60,1,38,
49,1,63,4,0,
49,1,72,4,0,
49,1,76,4,0,
49,1,77,1,1,3
49,1,77,1,24,
49,1,78,1,30,
49,1,79,1,43,
49,1,92,4,0,
49,1,94,1,50,
49,1,94,4,0,
49,1,99,4,0,
49,1,100,4,0,
49,1,102,4,0,
49,1,104,4,0,
49,1,115,4,0,
49,1,117,4,0,
49,1,129,4,0,
49,1,141,1,1,4
49,1,141,1,27,
49,1,149,4,0,
49,1,156,4,0,
49,1,164,4,0,
49,2,13,4,0,
49,2,18,4,0,
49,2,33,1,1,1
49,2,36,4,0,
49,2,38,4,0,
49,2,48,1,1,3
49,2,50,1,1,2
49,2,60,1,38,
49,2,63,4,0,
49,2,72,4,0,
49,2,76,4,0,
49,2,77,1,22,
49,2,78,1,30,
49,2,79,1,43,
49,2,92,4,0,
49,2,93,1,1,4
49,2,94,1,50,
49,2,94,4,0,
49,2,99,4,0,
49,2,100,4,0,
49,2,102,4,0,
49,2,104,4,0,
49,2,115,4,0,
49,2,117,4,0,
49,2,129,4,0,
49,2,141,1,27,
49,2,148,4,0,
49,2,149,4,0,
49,2,156,4,0,
49,2,164,4,0,
49,3,16,1,31,
49,3,33,1,1,1
49,3,48,1,1,4
49,3,48,1,9,
49,3,50,1,1,2
49,3,60,1,36,
49,3,63,4,0,
49,3,76,4,0,
49,3,77,1,20,
49,3,78,1,28,
49,3,79,1,42,
49,3,92,4,0,
49,3,93,1,17,
49,3,94,1,52,
49,3,94,4,0,
49,3,104,4,0,
49,3,129,4,0,
49,3,141,1,25,
49,3,148,4,0,
49,3,156,4,0,
49,3,168,4,0,
49,3,173,4,0,
49,3,174,4,0,
49,3,182,4,0,
49,3,188,4,0,
49,3,193,1,1,3
49,3,202,4,0,
49,3,203,4,0,
49,3,207,4,0,
49,3,213,4,0,
49,3,214,4,0,
49,3,216,4,0,
49,3,218,4,0,
49,3,230,4,0,
49,3,237,4,0,
49,3,241,4,0,
49,4,16,1,31,
49,4,33,1,1,1
49,4,48,1,1,4
49,4,48,1,9,
49,4,50,1,1,2
49,4,60,1,36,
49,4,63,4,0,
49,4,76,4,0,
49,4,77,1,20,
49,4,78,1,28,
49,4,79,1,42,
49,4,92,4,0,
49,4,93,1,17,
49,4,94,1,52,
49,4,94,4,0,
49,4,104,4,0,
49,4,129,4,0,
49,4,141,1,25,
49,4,148,4,0,
49,4,156,4,0,
49,4,168,4,0,
49,4,173,4,0,
49,4,174,4,0,
49,4,182,4,0,
49,4,188,4,0,
49,4,193,1,1,3
49,4,202,4,0,
49,4,203,4,0,
49,4,207,4,0,
49,4,213,4,0,
49,4,214,4,0,
49,4,216,4,0,
49,4,218,4,0,
49,4,230,4,0,
49,4,237,4,0,
49,4,241,4,0,
49,5,16,1,31,
49,5,33,1,1,2
49,5,48,1,1,5
49,5,48,1,9,
49,5,50,1,1,3
49,5,60,1,36,
49,5,63,4,0,
49,5,76,4,0,
49,5,77,1,20,
49,5,78,1,28,
49,5,79,1,42,
49,5,92,4,0,
49,5,93,1,17,
49,5,94,1,52,
49,5,94,4,0,
49,5,104,4,0,
49,5,141,1,25,
49,5,148,4,0,
49,5,156,4,0,
49,5,168,4,0,
49,5,182,4,0,
49,5,188,4,0,
49,5,193,1,1,4
49,5,202,4,0,
49,5,213,4,0,
49,5,216,4,0,
49,5,218,4,0,
49,5,237,4,0,
49,5,241,4,0,
49,5,263,4,0,
49,5,285,4,0,
49,5,290,4,0,
49,5,318,1,1,1
49,5,332,4,0,
49,6,16,1,31,
49,6,33,1,1,2
49,6,38,3,0,
49,6,48,1,1,5
49,6,48,1,9,
49,6,50,1,1,3
49,6,60,1,36,
49,6,63,4,0,
49,6,76,4,0,
49,6,77,1,20,
49,6,78,1,28,
49,6,79,1,42,
49,6,92,4,0,
49,6,93,1,17,
49,6,94,1,52,
49,6,94,4,0,
49,6,102,3,0,
49,6,104,4,0,
49,6,129,3,0,
49,6,141,1,25,
49,6,148,4,0,
49,6,156,4,0,
49,6,164,3,0,
49,6,168,4,0,
49,6,173,3,0,
49,6,182,4,0,
49,6,188,4,0,
49,6,193,1,1,4
49,6,202,4,0,
49,6,203,3,0,
49,6,207,3,0,
49,6,213,4,0,
49,6,214,3,0,
49,6,216,4,0,
49,6,218,4,0,
49,6,237,4,0,
49,6,241,4,0,
49,6,263,4,0,
49,6,285,4,0,
49,6,290,4,0,
49,6,318,1,1,1
49,6,332,4,0,
49,7,16,1,31,
49,7,33,1,1,2
49,7,38,3,0,
49,7,48,1,1,5
49,7,48,1,9,
49,7,50,1,1,3
49,7,60,1,36,
49,7,63,4,0,
49,7,76,4,0,
49,7,77,1,20,
49,7,78,1,28,
49,7,79,1,42,
49,7,92,4,0,
49,7,93,1,17,
49,7,94,1,52,
49,7,94,4,0,
49,7,102,3,0,
49,7,104,4,0,
49,7,141,1,25,
49,7,148,4,0,
49,7,156,4,0,
49,7,164,3,0,
49,7,168,4,0,
49,7,182,4,0,
49,7,188,4,0,
49,7,193,1,1,4
49,7,202,4,0,
49,7,213,4,0,
49,7,216,4,0,
49,7,218,4,0,
49,7,237,4,0,
49,7,241,4,0,
49,7,263,4,0,
49,7,285,4,0,
49,7,290,4,0,
49,7,318,1,1,1
49,7,332,4,0,
49,8,16,1,31,
49,8,33,1,1,2
49,8,48,1,1,5
49,8,48,1,5,
49,8,50,1,1,3
49,8,60,1,25,
49,8,63,4,0,
49,8,76,4,0,
49,8,77,1,13,
49,8,78,1,23,
49,8,79,1,29,
49,8,92,4,0,
49,8,93,1,11,
49,8,94,1,55,
49,8,94,4,0,
49,8,104,4,0,
49,8,141,1,17,
49,8,148,4,0,
49,8,156,4,0,
49,8,164,4,0,
49,8,168,4,0,
49,8,182,4,0,
49,8,188,4,0,
49,8,193,1,1,4
49,8,202,4,0,
49,8,203,4,0,
49,8,207,4,0,
49,8,213,4,0,
49,8,214,4,0,
49,8,216,4,0,
49,8,218,4,0,
49,8,237,4,0,
49,8,241,4,0,
49,8,263,4,0,
49,8,285,4,0,
49,8,290,4,0,
49,8,305,1,47,
49,8,318,1,1,1
49,8,318,4,0,
49,8,324,1,37,
49,8,332,4,0,
49,8,355,4,0,
49,8,363,4,0,
49,8,369,4,0,
49,8,405,1,59,
49,8,412,4,0,
49,8,416,4,0,
49,8,428,1,41,
49,8,432,4,0,
49,8,445,4,0,
49,9,16,1,31,
49,9,33,1,1,2
49,9,48,1,1,5
49,9,48,1,5,
49,9,50,1,1,3
49,9,60,1,25,
49,9,63,4,0,
49,9,76,4,0,
49,9,77,1,13,
49,9,78,1,23,
49,9,79,1,29,
49,9,92,4,0,
49,9,93,1,11,
49,9,94,1,55,
49,9,94,4,0,
49,9,104,4,0,
49,9,129,3,0,
49,9,141,1,17,
49,9,148,4,0,
49,9,156,4,0,
49,9,164,4,0,
49,9,168,4,0,
49,9,173,3,0,
49,9,182,4,0,
49,9,188,4,0,
49,9,193,1,1,4
49,9,202,4,0,
49,9,203,4,0,
49,9,207,4,0,
49,9,213,4,0,
49,9,214,4,0,
49,9,216,4,0,
49,9,218,4,0,
49,9,237,4,0,
49,9,239,3,0,
49,9,241,4,0,
49,9,263,4,0,
49,9,285,4,0,
49,9,290,4,0,
49,9,305,1,47,
49,9,314,3,0,
49,9,318,1,1,1
49,9,318,4,0,
49,9,324,1,37,
49,9,324,3,0,
49,9,332,4,0,
49,9,355,4,0,
49,9,363,4,0,
49,9,369,4,0,
49,9,405,1,59,
49,9,412,4,0,
49,9,416,4,0,
49,9,428,1,41,
49,9,428,3,0,
49,9,432,4,0,
49,9,445,4,0,
49,9,466,3,0,
49,10,16,1,31,
49,10,33,1,1,2
49,10,48,1,1,5
49,10,48,1,5,
49,10,50,1,1,3
49,10,60,1,25,
49,10,63,4,0,
49,10,76,4,0,
49,10,77,1,13,
49,10,78,1,23,
49,10,79,1,29,
49,10,81,3,0,
49,10,92,4,0,
49,10,93,1,11,
49,10,94,1,55,
49,10,94,4,0,
49,10,104,4,0,
49,10,129,3,0,
49,10,141,1,17,
49,10,148,4,0,
49,10,156,4,0,
49,10,164,4,0,
49,10,168,4,0,
49,10,173,3,0,
49,10,182,4,0,
49,10,188,4,0,
49,10,193,1,1,4
49,10,202,4,0,
49,10,203,4,0,
49,10,207,4,0,
49,10,213,4,0,
49,10,214,4,0,
49,10,216,4,0,
49,10,218,4,0,
49,10,237,4,0,
49,10,239,3,0,
49,10,241,4,0,
49,10,263,4,0,
49,10,285,4,0,
49,10,290,4,0,
49,10,305,1,47,
49,10,314,3,0,
49,10,318,1,1,1
49,10,318,4,0,
49,10,324,1,37,
49,10,324,3,0,
49,10,332,4,0,
49,10,355,4,0,
49,10,363,4,0,
49,10,366,3,0,
49,10,369,4,0,
49,10,405,1,59,
49,10,412,4,0,
49,10,416,4,0,
49,10,428,1,41,
49,10,428,3,0,
49,10,445,4,0,
49,10,450,3,0,
49,10,466,3,0,
49,11,16,1,31,
49,11,33,1,1,2
49,11,48,1,1,5
49,11,48,1,5,
49,11,50,1,1,3
49,11,60,1,25,
49,11,63,4,0,
49,11,76,4,0,
49,11,77,1,13,
49,11,78,1,23,
49,11,79,1,29,
49,11,92,4,0,
49,11,93,1,11,
49,11,94,1,55,
49,11,94,4,0,
49,11,104,4,0,
49,11,141,1,17,
49,11,148,4,0,
49,11,156,4,0,
49,11,164,4,0,
49,11,168,4,0,
49,11,182,4,0,
49,11,188,4,0,
49,11,193,1,1,4
49,11,207,4,0,
49,11,213,4,0,
49,11,216,4,0,
49,11,218,4,0,
49,11,237,4,0,
49,11,241,4,0,
49,11,263,4,0,
49,11,305,1,47,
49,11,318,1,1,1
49,11,324,1,37,
49,11,332,4,0,
49,11,369,4,0,
49,11,405,1,59,
49,11,412,4,0,
49,11,416,4,0,
49,11,428,1,41,
49,11,474,4,0,
49,11,483,1,63,
49,11,496,4,0,
49,11,512,4,0,
49,11,522,4,0,
49,12,16,1,31,
49,12,33,1,1,2
49,12,48,1,1,5
49,12,48,1,9,
49,12,50,1,1,3
49,12,60,1,36,
49,12,63,4,0,
49,12,76,4,0,
49,12,77,1,20,
49,12,78,1,28,
49,12,79,1,42,
49,12,92,4,0,
49,12,93,1,17,
49,12,94,1,52,
49,12,94,4,0,
49,12,104,4,0,
49,12,141,1,25,
49,12,148,4,0,
49,12,156,4,0,
49,12,168,4,0,
49,12,182,4,0,
49,12,188,4,0,
49,12,193,1,1,4
49,12,202,4,0,
49,12,213,4,0,
49,12,216,4,0,
49,12,218,4,0,
49,12,237,4,0,
49,12,241,4,0,
49,12,263,4,0,
49,12,285,4,0,
49,12,290,4,0,
49,12,318,1,1,1
49,12,332,4,0,
49,13,16,1,31,
49,13,33,1,1,2
49,13,38,3,0,
49,13,48,1,1,5
49,13,48,1,9,
49,13,50,1,1,3
49,13,60,1,36,
49,13,63,4,0,
49,13,76,4,0,
49,13,77,1,20,
49,13,78,1,28,
49,13,79,1,42,
49,13,92,4,0,
49,13,93,1,17,
49,13,94,1,52,
49,13,94,4,0,
49,13,102,3,0,
49,13,104,4,0,
49,13,141,1,25,
49,13,148,4,0,
49,13,156,4,0,
49,13,164,3,0,
49,13,168,4,0,
49,13,182,4,0,
49,13,188,4,0,
49,13,193,1,1,4
49,13,202,4,0,
49,13,207,3,0,
49,13,213,4,0,
49,13,216,4,0,
49,13,218,4,0,
49,13,237,4,0,
49,13,241,4,0,
49,13,263,4,0,
49,13,285,4,0,
49,13,290,4,0,
49,13,318,1,1,1
49,13,332,4,0,
49,14,16,1,31,
49,14,33,1,1,2
49,14,48,1,1,5
49,14,48,1,5,
49,14,50,1,1,3
49,14,60,1,25,
49,14,63,4,0,
49,14,76,4,0,
49,14,77,1,13,
49,14,78,1,23,
49,14,79,1,29,
49,14,92,4,0,
49,14,93,1,11,
49,14,94,1,55,
49,14,94,4,0,
49,14,104,4,0,
49,14,141,1,17,
49,14,148,4,0,
49,14,156,4,0,
49,14,164,4,0,
49,14,168,4,0,
49,14,173,3,0,
49,14,182,4,0,
49,14,188,4,0,
49,14,193,1,1,4
49,14,202,3,0,
49,14,207,4,0,
49,14,213,4,0,
49,14,214,3,0,
49,14,216,4,0,
49,14,218,4,0,
49,14,237,4,0,
49,14,241,4,0,
49,14,263,4,0,
49,14,285,3,0,
49,14,305,1,47,
49,14,318,1,1,1
49,14,324,1,37,
49,14,324,3,0,
49,14,332,4,0,
49,14,355,3,0,
49,14,366,3,0,
49,14,369,4,0,
49,14,405,1,59,
49,14,412,4,0,
49,14,416,4,0,
49,14,428,1,41,
49,14,428,3,0,
49,14,450,3,0,
49,14,474,4,0,
49,14,483,1,63,
49,14,496,4,0,
49,14,512,4,0,
49,14,522,4,0,
49,15,16,1,31,
49,15,33,1,1,4
49,15,48,1,1,7
49,15,48,1,5,
49,15,50,1,1,5
49,15,60,1,25,
49,15,63,4,0,
49,15,76,4,0,
49,15,77,1,13,
49,15,78,1,23,
49,15,79,1,29,
49,15,92,4,0,
49,15,93,1,11,
49,15,94,1,55,
49,15,94,4,0,
49,15,104,4,0,
49,15,141,1,17,
49,15,148,4,0,
49,15,156,4,0,
49,15,164,4,0,
49,15,168,4,0,
49,15,182,4,0,
49,15,188,4,0,
49,15,193,1,1,6
49,15,207,4,0,
49,15,213,4,0,
49,15,214,4,0,
49,15,216,4,0,
49,15,218,4,0,
49,15,237,4,0,
49,15,241,4,0,
49,15,263,4,0,
49,15,305,1,47,
49,15,318,1,1,3
49,15,324,1,37,
49,15,332,4,0,
49,15,355,4,0,
49,15,369,4,0,
49,15,405,1,1,2
49,15,405,1,59,
49,15,412,4,0,
49,15,416,4,0,
49,15,428,1,41,
49,15,474,4,0,
49,15,483,1,1,1
49,15,483,1,63,
49,15,496,4,0,
49,15,512,4,0,
49,15,522,4,0,
49,15,590,4,0,
49,15,611,4,0,
49,16,16,1,31,1
49,16,33,1,1,4
49,16,48,1,1,7
49,16,48,1,5,1
49,16,50,1,1,5
49,16,60,1,25,1
49,16,63,4,0,
49,16,76,4,0,
49,16,77,1,13,1
49,16,78,1,23,1
49,16,79,1,29,1
49,16,92,4,0,
49,16,93,1,11,1
49,16,94,1,55,1
49,16,94,4,0,
49,16,104,4,0,
49,16,141,1,17,1
49,16,148,4,0,
49,16,156,4,0,
49,16,164,4,0,
49,16,168,4,0,
49,16,173,3,0,
49,16,182,4,0,
49,16,188,4,0,
49,16,193,1,1,6
49,16,202,3,0,
49,16,207,4,0,
49,16,213,4,0,
49,16,214,4,0,
49,16,216,4,0,
49,16,218,4,0,
49,16,237,4,0,
49,16,241,4,0,
49,16,263,4,0,
49,16,285,3,0,
49,16,290,4,0,
49,16,305,1,47,1
49,16,318,1,1,3
49,16,324,1,37,1
49,16,324,3,0,
49,16,332,4,0,
49,16,355,4,0,
49,16,366,3,0,
49,16,369,4,0,
49,16,405,1,1,2
49,16,405,1,59,1
49,16,412,4,0,
49,16,416,4,0,
49,16,428,1,41,1
49,16,428,3,0,
49,16,450,3,0,
49,16,474,4,0,
49,16,483,1,1,1
49,16,483,1,63,1
49,16,496,4,0,
49,16,512,4,0,
49,16,522,4,0,
49,16,590,4,0,
49,16,611,4,0,
49,17,16,1,0,
49,17,16,1,1,1
49,17,33,1,1,5
49,17,48,1,1,8
49,17,48,1,5,
49,17,50,1,1,6
49,17,60,1,17,
49,17,63,4,0,
49,17,76,4,0,
49,17,77,1,13,
49,17,78,1,23,
49,17,79,1,29,
49,17,92,4,0,
49,17,93,1,11,
49,17,94,1,55,
49,17,94,4,0,
49,17,104,4,0,
49,17,141,1,37,
49,17,141,4,0,
49,17,156,4,0,
49,17,164,4,0,
49,17,168,4,0,
49,17,182,4,0,
49,17,188,4,0,
49,17,193,1,1,7
49,17,207,4,0,
49,17,213,4,0,
49,17,214,4,0,
49,17,216,4,0,
49,17,218,4,0,
49,17,237,4,0,
49,17,241,4,0,
49,17,263,4,0,
49,17,305,1,47,
49,17,318,1,1,4
49,17,324,1,25,
49,17,332,4,0,
49,17,355,4,0,
49,17,369,4,0,
49,17,405,1,1,3
49,17,405,1,59,
49,17,412,4,0,
49,17,416,4,0,
49,17,428,1,41,
49,17,474,4,0,
49,17,483,1,1,2
49,17,483,1,63,
49,17,496,4,0,
49,17,512,4,0,
49,17,590,4,0,
49,17,611,4,0,
49,18,16,1,0,
49,18,16,1,1,1
49,18,33,1,1,5
49,18,48,1,1,8
49,18,48,1,5,
49,18,50,1,1,6
49,18,60,1,17,
49,18,63,4,0,
49,18,76,4,0,
49,18,77,1,13,
49,18,78,1,23,
49,18,79,1,29,
49,18,92,4,0,
49,18,93,1,11,
49,18,94,1,55,
49,18,94,4,0,
49,18,104,4,0,
49,18,141,1,37,
49,18,141,4,0,
49,18,156,4,0,
49,18,164,4,0,
49,18,168,4,0,
49,18,182,4,0,
49,18,188,4,0,
49,18,193,1,1,7
49,18,207,4,0,
49,18,213,4,0,
49,18,214,4,0,
49,18,216,4,0,
49,18,218,4,0,
49,18,237,4,0,
49,18,241,4,0,
49,18,263,4,0,
49,18,305,1,47,
49,18,318,1,1,4
49,18,324,1,25,
49,18,332,4,0,
49,18,355,4,0,
49,18,369,4,0,
49,18,405,1,1,3
49,18,405,1,59,
49,18,412,4,0,
49,18,416,4,0,
49,18,428,1,41,
49,18,474,4,0,
49,18,483,1,1,2
49,18,483,1,63,
49,18,496,4,0,
49,18,512,4,0,
49,18,590,4,0,
49,18,611,4,0,
50,1,10,1,1,
50,1,28,1,24,
50,1,34,4,0,
50,1,36,4,0,
50,1,38,4,0,
50,1,45,1,15,
50,1,89,1,40,
50,1,89,4,0,
50,1,90,4,0,
50,1,91,1,19,
50,1,91,4,0,
50,1,92,4,0,
50,1,99,4,0,
50,1,102,4,0,
50,1,104,4,0,
50,1,117,4,0,
50,1,156,4,0,
50,1,157,4,0,
50,1,163,1,31,
50,1,164,4,0,
50,2,10,1,1,
50,2,15,4,0,
50,2,28,1,24,
50,2,34,4,0,
50,2,36,4,0,
50,2,38,4,0,
50,2,45,1,15,
50,2,89,1,40,
50,2,89,4,0,
50,2,90,4,0,
50,2,91,1,19,
50,2,91,4,0,
50,2,92,4,0,
50,2,99,4,0,
50,2,102,4,0,
50,2,104,4,0,
50,2,117,4,0,
50,2,156,4,0,
50,2,157,4,0,
50,2,163,1,31,
50,2,164,4,0,
50,3,10,1,1,
50,3,15,4,0,
50,3,28,1,25,
50,3,45,1,5,
50,3,89,1,41,
50,3,89,4,0,
50,3,90,1,49,
50,3,91,1,17,
50,3,91,4,0,
50,3,92,4,0,
50,3,103,2,0,
50,3,104,4,0,
50,3,156,4,0,
50,3,163,1,33,
50,3,168,4,0,
50,3,173,4,0,
50,3,174,4,0,
50,3,182,4,0,
50,3,185,2,0,
50,3,188,4,0,
50,3,189,4,0,
50,3,203,4,0,
50,3,207,4,0,
50,3,213,4,0,
50,3,214,4,0,
50,3,216,4,0,
50,3,218,4,0,
50,3,222,1,9,
50,3,228,2,0,
50,3,237,4,0,
50,3,241,4,0,
50,3,246,2,0,
50,3,249,4,0,
50,3,251,2,0,
50,4,10,1,1,
50,4,15,4,0,
50,4,28,1,25,
50,4,45,1,5,
50,4,89,1,41,
50,4,89,4,0,
50,4,90,1,49,
50,4,91,1,17,
50,4,91,4,0,
50,4,92,4,0,
50,4,103,2,0,
50,4,104,4,0,
50,4,156,4,0,
50,4,163,1,33,
50,4,168,4,0,
50,4,173,4,0,
50,4,174,4,0,
50,4,182,4,0,
50,4,185,2,0,
50,4,188,4,0,
50,4,189,4,0,
50,4,203,4,0,
50,4,207,4,0,
50,4,213,4,0,
50,4,214,4,0,
50,4,216,4,0,
50,4,218,4,0,
50,4,222,1,9,
50,4,228,2,0,
50,4,237,4,0,
50,4,241,4,0,
50,4,246,2,0,
50,4,249,4,0,
50,4,251,2,0,
50,5,10,1,1,1
50,5,15,4,0,
50,5,28,1,1,2
50,5,45,1,5,
50,5,89,1,41,
50,5,89,4,0,
50,5,90,1,49,
50,5,91,1,17,
50,5,91,4,0,
50,5,92,4,0,
50,5,103,2,0,
50,5,104,4,0,
50,5,156,4,0,
50,5,157,2,0,
50,5,163,1,33,
50,5,168,4,0,
50,5,182,4,0,
50,5,185,2,0,
50,5,188,4,0,
50,5,189,1,25,
50,5,213,4,0,
50,5,216,4,0,
50,5,218,4,0,
50,5,222,1,9,
50,5,228,2,0,
50,5,237,4,0,
50,5,241,4,0,
50,5,246,2,0,
50,5,249,4,0,
50,5,251,2,0,
50,5,253,2,0,
50,5,263,4,0,
50,5,290,4,0,
50,5,317,4,0,
50,5,332,4,0,
50,6,10,1,1,1
50,6,15,4,0,
50,6,28,1,1,2
50,6,34,3,0,
50,6,38,3,0,
50,6,45,1,5,
50,6,89,1,41,
50,6,89,4,0,
50,6,90,1,49,
50,6,91,1,17,
50,6,91,4,0,
50,6,92,4,0,
50,6,102,3,0,
50,6,103,2,0,
50,6,104,4,0,
50,6,156,4,0,
50,6,157,2,0,
50,6,157,3,0,
50,6,163,1,33,
50,6,164,3,0,
50,6,168,4,0,
50,6,173,3,0,
50,6,182,4,0,
50,6,185,2,0,
50,6,188,4,0,
50,6,189,1,25,
50,6,189,3,0,
50,6,203,3,0,
50,6,207,3,0,
50,6,213,4,0,
50,6,214,3,0,
50,6,216,4,0,
50,6,218,4,0,
50,6,222,1,9,
50,6,228,2,0,
50,6,237,4,0,
50,6,241,4,0,
50,6,246,2,0,
50,6,249,4,0,
50,6,251,2,0,
50,6,253,2,0,
50,6,263,4,0,
50,6,290,4,0,
50,6,317,4,0,
50,6,332,4,0,
50,7,10,1,1,2
50,7,15,4,0,
50,7,28,1,1,1
50,7,34,3,0,
50,7,38,3,0,
50,7,45,1,5,
50,7,89,1,41,
50,7,89,4,0,
50,7,90,1,49,
50,7,91,1,17,
50,7,91,4,0,
50,7,92,4,0,
50,7,102,3,0,
50,7,103,2,0,
50,7,104,4,0,
50,7,154,1,21,
50,7,156,4,0,
50,7,157,2,0,
50,7,157,3,0,
50,7,163,1,33,
50,7,164,3,0,
50,7,168,4,0,
50,7,182,4,0,
50,7,185,2,0,
50,7,188,4,0,
50,7,189,1,25,
50,7,213,4,0,
50,7,216,4,0,
50,7,218,4,0,
50,7,222,1,9,
50,7,228,2,0,
50,7,237,4,0,
50,7,241,4,0,
50,7,246,2,0,
50,7,249,4,0,
50,7,251,2,0,
50,7,253,2,0,
50,7,263,4,0,
50,7,290,4,0,
50,7,317,4,0,
50,7,332,4,0,
50,8,10,1,1,1
50,8,15,4,0,
50,8,28,1,1,2
50,8,45,1,4,
50,8,89,1,37,
50,8,89,4,0,
50,8,90,1,40,
50,8,91,1,18,
50,8,91,4,0,
50,8,92,4,0,
50,8,103,2,0,
50,8,104,4,0,
50,8,156,4,0,
50,8,157,2,0,
50,8,157,4,0,
50,8,163,1,34,
50,8,164,4,0,
50,8,168,4,0,
50,8,182,4,0,
50,8,185,2,0,
50,8,188,4,0,
50,8,189,1,15,
50,8,201,4,0,
50,8,203,4,0,
50,8,207,4,0,
50,8,213,4,0,
50,8,214,4,0,
50,8,216,4,0,
50,8,218,4,0,
50,8,222,1,12,
50,8,228,2,0,
50,8,237,4,0,
50,8,241,4,0,
50,8,246,2,0,
50,8,249,4,0,
50,8,251,2,0,
50,8,253,2,0,
50,8,263,4,0,
50,8,290,4,0,
50,8,310,1,7,
50,8,310,2,0,
50,8,317,4,0,
50,8,332,4,0,
50,8,363,4,0,
50,8,389,1,23,
50,8,414,1,26,
50,8,421,4,0,
50,8,426,1,29,
50,8,426,2,0,
50,8,445,4,0,
50,8,446,4,0,
50,9,10,1,1,1
50,9,15,4,0,
50,9,28,1,1,2
50,9,45,1,4,
50,9,89,1,37,
50,9,89,4,0,
50,9,90,1,40,
50,9,91,1,18,
50,9,91,4,0,
50,9,92,4,0,
50,9,103,2,0,
50,9,104,4,0,
50,9,156,4,0,
50,9,157,2,0,
50,9,157,4,0,
50,9,163,1,34,
50,9,164,4,0,
50,9,168,4,0,
50,9,173,3,0,
50,9,182,4,0,
50,9,185,2,0,
50,9,188,4,0,
50,9,189,1,15,
50,9,189,3,0,
50,9,201,4,0,
50,9,203,4,0,
50,9,207,4,0,
50,9,213,4,0,
50,9,214,4,0,
50,9,216,4,0,
50,9,218,4,0,
50,9,222,1,12,
50,9,228,2,0,
50,9,237,4,0,
50,9,241,4,0,
50,9,246,2,0,
50,9,249,4,0,
50,9,251,2,0,
50,9,253,2,0,
50,9,263,4,0,
50,9,290,4,0,
50,9,310,1,7,
50,9,310,2,0,
50,9,317,4,0,
50,9,332,4,0,
50,9,363,4,0,
50,9,389,1,23,
50,9,389,3,0,
50,9,414,1,26,
50,9,414,3,0,
50,9,421,4,0,
50,9,426,1,29,
50,9,426,2,0,
50,9,445,4,0,
50,9,446,4,0,
50,10,10,1,1,1
50,10,15,4,0,
50,10,28,1,1,2
50,10,45,1,4,
50,10,89,1,37,
50,10,89,4,0,
50,10,90,1,40,
50,10,91,1,18,
50,10,91,4,0,
50,10,92,4,0,
50,10,103,2,0,
50,10,104,4,0,
50,10,156,4,0,
50,10,157,2,0,
50,10,157,4,0,
50,10,163,1,34,
50,10,164,4,0,
50,10,168,4,0,
50,10,173,3,0,
50,10,179,2,0,
50,10,182,4,0,
50,10,185,2,0,
50,10,188,4,0,
50,10,189,1,15,
50,10,189,3,0,
50,10,201,4,0,
50,10,203,4,0,
50,10,207,4,0,
50,10,213,4,0,
50,10,214,4,0,
50,10,216,4,0,
50,10,218,4,0,
50,10,222,1,12,
50,10,228,2,0,
50,10,237,4,0,
50,10,241,4,0,
50,10,246,2,0,
50,10,249,4,0,
50,10,251,2,0,
50,10,253,2,0,
50,10,263,4,0,
50,10,290,4,0,
50,10,310,1,7,
50,10,310,2,0,
50,10,317,4,0,
50,10,332,4,0,
50,10,363,4,0,
50,10,389,1,23,
50,10,389,3,0,
50,10,414,1,26,
50,10,414,3,0,
50,10,421,4,0,
50,10,426,1,29,
50,10,426,2,0,
50,10,445,4,0,
50,10,446,4,0,
50,11,10,1,1,1
50,11,15,4,0,
50,11,28,1,1,2
50,11,29,2,0,
50,11,45,1,4,
50,11,89,1,40,
50,11,89,4,0,
50,11,90,1,45,
50,11,91,1,34,
50,11,91,4,0,
50,11,92,4,0,
50,11,103,2,0,
50,11,104,4,0,
50,11,156,4,0,
50,11,157,4,0,
50,11,163,1,37,
50,11,164,4,0,
50,11,168,4,0,
50,11,179,2,0,
50,11,182,4,0,
50,11,185,2,0,
50,11,188,4,0,
50,11,189,1,12,
50,11,201,4,0,
50,11,203,2,0,
50,11,207,4,0,
50,11,213,4,0,
50,11,216,4,0,
50,11,218,4,0,
50,11,222,1,15,
50,11,228,2,0,
50,11,237,4,0,
50,11,241,4,0,
50,11,246,2,0,
50,11,249,4,0,
50,11,251,2,0,
50,11,253,2,0,
50,11,262,2,0,
50,11,263,4,0,
50,11,310,1,7,
50,11,310,2,0,
50,11,317,4,0,
50,11,332,4,0,
50,11,389,1,23,
50,11,414,1,29,
50,11,421,4,0,
50,11,426,1,26,
50,11,426,2,0,
50,11,468,4,0,
50,11,496,4,0,
50,11,497,4,0,
50,11,515,2,0,
50,11,523,1,18,
50,11,523,4,0,
50,12,10,1,1,1
50,12,15,4,0,
50,12,28,1,1,2
50,12,45,1,5,
50,12,89,1,41,
50,12,89,4,0,
50,12,90,1,49,
50,12,91,1,17,
50,12,91,4,0,
50,12,92,4,0,
50,12,104,4,0,
50,12,156,4,0,
50,12,163,1,33,
50,12,168,4,0,
50,12,182,4,0,
50,12,188,4,0,
50,12,189,1,25,
50,12,213,4,0,
50,12,216,4,0,
50,12,218,4,0,
50,12,222,1,9,
50,12,237,4,0,
50,12,241,4,0,
50,12,249,4,0,
50,12,263,4,0,
50,12,290,4,0,
50,12,317,4,0,
50,12,332,4,0,
50,13,10,1,1,1
50,13,15,4,0,
50,13,28,1,1,2
50,13,34,3,0,
50,13,38,3,0,
50,13,45,1,5,
50,13,89,1,41,
50,13,89,4,0,
50,13,90,1,49,
50,13,91,1,17,
50,13,91,4,0,
50,13,92,4,0,
50,13,102,3,0,
50,13,104,4,0,
50,13,156,4,0,
50,13,163,1,33,
50,13,164,3,0,
50,13,168,4,0,
50,13,182,4,0,
50,13,188,4,0,
50,13,189,1,25,
50,13,207,3,0,
50,13,213,4,0,
50,13,216,4,0,
50,13,218,4,0,
50,13,222,1,9,
50,13,237,4,0,
50,13,241,4,0,
50,13,249,4,0,
50,13,263,4,0,
50,13,290,4,0,
50,13,317,4,0,
50,13,332,4,0,
50,14,10,1,1,1
50,14,15,4,0,
50,14,28,1,1,2
50,14,29,2,0,
50,14,45,1,4,
50,14,89,1,40,
50,14,89,4,0,
50,14,90,1,45,
50,14,91,1,34,
50,14,91,4,0,
50,14,92,4,0,
50,14,103,2,0,
50,14,104,4,0,
50,14,156,4,0,
50,14,157,4,0,
50,14,163,1,37,
50,14,164,4,0,
50,14,168,4,0,
50,14,173,3,0,
50,14,179,2,0,
50,14,182,4,0,
50,14,185,2,0,
50,14,188,4,0,
50,14,189,1,12,
50,14,201,4,0,
50,14,203,2,0,
50,14,207,4,0,
50,14,213,4,0,
50,14,214,3,0,
50,14,216,4,0,
50,14,218,4,0,
50,14,222,1,15,
50,14,228,2,0,
50,14,237,4,0,
50,14,241,4,0,
50,14,246,2,0,
50,14,249,4,0,
50,14,251,2,0,
50,14,253,2,0,
50,14,253,3,0,
50,14,262,2,0,
50,14,263,4,0,
50,14,310,1,7,
50,14,310,2,0,
50,14,317,4,0,
50,14,332,4,0,
50,14,389,1,23,
50,14,414,1,29,
50,14,414,3,0,
50,14,421,4,0,
50,14,426,1,26,
50,14,426,2,0,
50,14,446,3,0,
50,14,468,4,0,
50,14,496,4,0,
50,14,497,4,0,
50,14,515,2,0,
50,14,523,1,18,
50,14,523,4,0,
50,15,10,1,1,1
50,15,15,4,0,
50,15,28,1,1,2
50,15,29,2,0,
50,15,45,1,4,
50,15,89,1,40,
50,15,89,4,0,
50,15,90,1,45,
50,15,91,1,34,
50,15,91,4,0,
50,15,92,4,0,
50,15,103,2,0,
50,15,104,4,0,
50,15,156,4,0,
50,15,157,4,0,
50,15,163,1,37,
50,15,164,4,0,
50,15,168,4,0,
50,15,179,2,0,
50,15,182,4,0,
50,15,185,2,0,
50,15,188,4,0,
50,15,189,1,12,
50,15,201,4,0,
50,15,203,2,0,
50,15,207,4,0,
50,15,213,4,0,
50,15,214,4,0,
50,15,216,4,0,
50,15,218,4,0,
50,15,222,1,15,
50,15,228,2,0,
50,15,237,4,0,
50,15,241,4,0,
50,15,246,2,0,
50,15,249,4,0,
50,15,251,2,0,
50,15,253,2,0,
50,15,262,2,0,
50,15,263,4,0,
50,15,310,1,7,
50,15,310,2,0,
50,15,317,4,0,
50,15,332,4,0,
50,15,389,1,23,
50,15,414,1,29,
50,15,421,4,0,
50,15,426,1,26,
50,15,426,2,0,
50,15,468,4,0,
50,15,496,4,0,
50,15,497,4,0,
50,15,515,2,0,
50,15,523,1,18,
50,15,523,4,0,
50,15,590,4,0,
50,16,10,1,1,1
50,16,15,4,0,
50,16,28,1,1,2
50,16,29,2,0,
50,16,45,1,4,1
50,16,89,1,40,1
50,16,89,4,0,
50,16,90,1,45,1
50,16,91,1,34,1
50,16,91,4,0,
50,16,92,4,0,
50,16,103,2,0,
50,16,104,4,0,
50,16,156,4,0,
50,16,157,4,0,
50,16,163,1,37,1
50,16,164,4,0,
50,16,168,4,0,
50,16,173,3,0,
50,16,179,2,0,
50,16,182,4,0,
50,16,185,2,0,
50,16,188,4,0,
50,16,189,1,12,1
50,16,201,4,0,
50,16,203,2,0,
50,16,207,4,0,
50,16,213,4,0,
50,16,214,4,0,
50,16,216,4,0,
50,16,218,4,0,
50,16,222,1,15,1
50,16,228,2,0,
50,16,237,4,0,
50,16,241,4,0,
50,16,246,2,0,
50,16,249,4,0,
50,16,251,2,0,
50,16,253,2,0,
50,16,262,2,0,
50,16,263,4,0,
50,16,290,4,0,
50,16,310,1,7,1
50,16,310,2,0,
50,16,317,4,0,
50,16,332,4,0,
50,16,389,1,23,1
50,16,414,1,29,1
50,16,414,3,0,
50,16,421,4,0,
50,16,426,1,26,1
50,16,426,2,0,
50,16,446,3,0,
50,16,468,4,0,
50,16,496,4,0,
50,16,497,4,0,
50,16,515,2,0,
50,16,523,1,18,1
50,16,523,4,0,
50,16,590,4,0,
50,17,10,1,1,2
50,17,28,1,1,1
50,17,29,2,0,
50,17,45,1,4,
50,17,89,1,39,
50,17,89,4,0,
50,17,90,1,43,
50,17,91,1,31,
50,17,92,4,0,
50,17,103,2,0,
50,17,104,4,0,
50,17,156,4,0,
50,17,157,4,0,
50,17,163,1,35,
50,17,164,4,0,
50,17,168,4,0,
50,17,179,2,0,
50,17,182,4,0,
50,17,185,2,0,
50,17,188,4,0,
50,17,189,1,10,
50,17,201,4,0,
50,17,203,2,0,
50,17,207,4,0,
50,17,213,4,0,
50,17,214,4,0,
50,17,216,4,0,
50,17,218,4,0,
50,17,222,1,14,
50,17,228,2,0,
50,17,237,4,0,
50,17,241,4,0,
50,17,246,2,0,
50,17,251,2,0,
50,17,253,2,0,
50,17,262,2,0,
50,17,263,4,0,
50,17,310,1,7,
50,17,310,2,0,
50,17,317,4,0,
50,17,332,4,0,
50,17,389,1,22,
50,17,414,1,28,
50,17,421,4,0,
50,17,426,1,25,
50,17,426,2,0,
50,17,496,4,0,
50,17,497,4,0,
50,17,515,2,0,
50,17,523,1,18,
50,17,523,4,0,
50,17,590,4,0,
50,18,10,1,1,2
50,18,28,1,1,1
50,18,29,2,0,
50,18,45,1,4,
50,18,89,1,39,
50,18,89,4,0,
50,18,90,1,43,
50,18,91,1,31,
50,18,92,4,0,
50,18,103,2,0,
50,18,104,4,0,
50,18,156,4,0,
50,18,157,4,0,
50,18,163,1,35,
50,18,164,4,0,
50,18,168,4,0,
50,18,179,2,0,
50,18,182,4,0,
50,18,185,2,0,
50,18,188,4,0,
50,18,189,1,10,
50,18,201,4,0,
50,18,203,2,0,
50,18,207,4,0,
50,18,213,4,0,
50,18,214,4,0,
50,18,216,4,0,
50,18,218,4,0,
50,18,222,1,14,
50,18,228,2,0,
50,18,237,4,0,
50,18,241,4,0,
50,18,246,2,0,
50,18,251,2,0,
50,18,253,2,0,
50,18,262,2,0,
50,18,263,4,0,
50,18,310,1,7,
50,18,310,2,0,
50,18,317,4,0,
50,18,332,4,0,
50,18,389,1,22,
50,18,414,1,28,
50,18,421,4,0,
50,18,426,1,25,
50,18,426,2,0,
50,18,496,4,0,
50,18,497,4,0,
50,18,515,2,0,
50,18,523,1,18,
50,18,523,4,0,
50,18,590,4,0,
51,1,10,1,1,1
51,1,28,1,24,
51,1,34,4,0,
51,1,36,4,0,
51,1,38,4,0,
51,1,45,1,1,2
51,1,45,1,15,
51,1,63,4,0,
51,1,89,1,47,
51,1,89,4,0,
51,1,90,4,0,
51,1,91,1,1,3
51,1,91,1,19,
51,1,91,4,0,
51,1,92,4,0,
51,1,99,4,0,
51,1,102,4,0,
51,1,104,4,0,
51,1,117,4,0,
51,1,156,4,0,
51,1,157,4,0,
51,1,163,1,35,
51,1,164,4,0,
51,2,10,1,1,1
51,2,15,4,0,
51,2,28,1,24,
51,2,34,4,0,
51,2,36,4,0,
51,2,38,4,0,
51,2,45,1,1,2
51,2,45,1,15,
51,2,63,4,0,
51,2,89,1,47,
51,2,89,4,0,
51,2,90,4,0,
51,2,91,1,1,3
51,2,91,1,19,
51,2,91,4,0,
51,2,92,4,0,
51,2,99,4,0,
51,2,102,4,0,
51,2,104,4,0,
51,2,117,4,0,
51,2,156,4,0,
51,2,157,4,0,
51,2,163,1,35,
51,2,164,4,0,
51,3,10,1,1,1
51,3,15,4,0,
51,3,28,1,25,
51,3,45,1,1,2
51,3,45,1,5,
51,3,63,4,0,
51,3,89,1,49,
51,3,89,4,0,
51,3,90,1,61,
51,3,91,1,17,
51,3,91,4,0,
51,3,92,4,0,
51,3,104,4,0,
51,3,156,4,0,
51,3,163,1,37,
51,3,168,4,0,
51,3,173,4,0,
51,3,174,4,0,
51,3,182,4,0,
51,3,188,4,0,
51,3,189,4,0,
51,3,203,4,0,
51,3,207,4,0,
51,3,213,4,0,
51,3,214,4,0,
51,3,216,4,0,
51,3,218,4,0,
51,3,222,1,1,3
51,3,222,1,9,
51,3,237,4,0,
51,3,241,4,0,
51,3,249,4,0,
51,4,10,1,1,2
51,4,15,4,0,
51,4,28,1,25,
51,4,45,1,1,3
51,4,45,1,5,
51,4,63,4,0,
51,4,89,1,49,
51,4,89,4,0,
51,4,90,1,61,
51,4,91,1,17,
51,4,91,4,0,
51,4,92,4,0,
51,4,104,4,0,
51,4,156,4,0,
51,4,161,1,1,1
51,4,163,1,37,
51,4,168,4,0,
51,4,173,4,0,
51,4,174,4,0,
51,4,182,4,0,
51,4,188,4,0,
51,4,189,4,0,
51,4,203,4,0,
51,4,207,4,0,
51,4,213,4,0,
51,4,214,4,0,
51,4,216,4,0,
51,4,218,4,0,
51,4,222,1,1,4
51,4,222,1,9,
51,4,237,4,0,
51,4,241,4,0,
51,4,249,4,0,
51,5,10,1,1,2
51,5,15,4,0,
51,5,28,1,1,3
51,5,45,1,1,4
51,5,45,1,5,
51,5,63,4,0,
51,5,89,1,51,
51,5,89,4,0,
51,5,90,1,64,
51,5,91,1,17,
51,5,91,4,0,
51,5,92,4,0,
51,5,104,4,0,
51,5,156,4,0,
51,5,161,1,1,1
51,5,163,1,38,
51,5,168,4,0,
51,5,182,4,0,
51,5,188,4,0,
51,5,189,1,25,
51,5,213,4,0,
51,5,216,4,0,
51,5,218,4,0,
51,5,222,1,9,
51,5,237,4,0,
51,5,241,4,0,
51,5,249,4,0,
51,5,263,4,0,
51,5,290,4,0,
51,5,317,4,0,
51,5,328,1,26,
51,5,332,4,0,
51,6,10,1,1,2
51,6,15,4,0,
51,6,28,1,1,3
51,6,34,3,0,
51,6,38,3,0,
51,6,45,1,1,4
51,6,45,1,5,
51,6,63,4,0,
51,6,89,1,51,
51,6,89,4,0,
51,6,90,1,64,
51,6,91,1,17,
51,6,91,4,0,
51,6,92,4,0,
51,6,102,3,0,
51,6,104,4,0,
51,6,156,4,0,
51,6,157,3,0,
51,6,161,1,1,1
51,6,163,1,38,
51,6,164,3,0,
51,6,168,4,0,
51,6,173,3,0,
51,6,182,4,0,
51,6,188,4,0,
51,6,189,1,25,
51,6,189,3,0,
51,6,203,3,0,
51,6,207,3,0,
51,6,213,4,0,
51,6,214,3,0,
51,6,216,4,0,
51,6,218,4,0,
51,6,222,1,9,
51,6,237,4,0,
51,6,241,4,0,
51,6,249,4,0,
51,6,263,4,0,
51,6,290,4,0,
51,6,317,4,0,
51,6,328,1,26,
51,6,332,4,0,
51,7,10,1,1,3
51,7,15,4,0,
51,7,28,1,1,2
51,7,34,3,0,
51,7,38,3,0,
51,7,45,1,1,4
51,7,45,1,5,
51,7,63,4,0,
51,7,89,1,51,
51,7,89,4,0,
51,7,90,1,64,
51,7,91,1,17,
51,7,91,4,0,
51,7,92,4,0,
51,7,102,3,0,
51,7,104,4,0,
51,7,154,1,21,
51,7,156,4,0,
51,7,157,3,0,
51,7,161,1,1,1
51,7,163,1,38,
51,7,164,3,0,
51,7,168,4,0,
51,7,182,4,0,
51,7,188,4,0,
51,7,189,1,25,
51,7,213,4,0,
51,7,216,4,0,
51,7,218,4,0,
51,7,222,1,9,
51,7,237,4,0,
51,7,241,4,0,
51,7,249,4,0,
51,7,263,4,0,
51,7,290,4,0,
51,7,317,4,0,
51,7,328,1,26,
51,7,332,4,0,
51,8,10,1,1,3
51,8,15,4,0,
51,8,28,1,1,4
51,8,45,1,1,5
51,8,45,1,4,
51,8,63,4,0,
51,8,89,1,45,
51,8,89,4,0,
51,8,90,1,50,
51,8,91,1,18,
51,8,91,4,0,
51,8,92,4,0,
51,8,104,4,0,
51,8,156,4,0,
51,8,157,4,0,
51,8,161,1,1,2
51,8,163,1,40,
51,8,164,4,0,
51,8,168,4,0,
51,8,182,4,0,
51,8,188,4,0,
51,8,189,1,15,
51,8,201,4,0,
51,8,203,4,0,
51,8,207,4,0,
51,8,213,4,0,
51,8,214,4,0,
51,8,216,4,0,
51,8,218,4,0,
51,8,222,1,12,
51,8,237,4,0,
51,8,241,4,0,
51,8,249,4,0,
51,8,263,4,0,
51,8,290,4,0,
51,8,310,1,7,
51,8,317,4,0,
51,8,328,1,26,
51,8,332,4,0,
51,8,363,4,0,
51,8,389,1,23,
51,8,400,1,1,1
51,8,414,1,28,
51,8,416,4,0,
51,8,421,4,0,
51,8,426,1,33,
51,8,444,4,0,
51,8,445,4,0,
51,8,446,4,0,
51,9,10,1,1,3
51,9,15,4,0,
51,9,28,1,1,4
51,9,45,1,1,5
51,9,45,1,4,
51,9,63,4,0,
51,9,89,1,45,
51,9,89,4,0,
51,9,90,1,50,
51,9,91,1,18,
51,9,91,4,0,
51,9,92,4,0,
51,9,104,4,0,
51,9,156,4,0,
51,9,157,4,0,
51,9,161,1,1,2
51,9,163,1,40,
51,9,164,4,0,
51,9,168,4,0,
51,9,173,3,0,
51,9,182,4,0,
51,9,188,4,0,
51,9,189,1,15,
51,9,189,3,0,
51,9,201,4,0,
51,9,203,4,0,
51,9,207,4,0,
51,9,213,4,0,
51,9,214,4,0,
51,9,216,4,0,
51,9,218,4,0,
51,9,222,1,12,
51,9,237,4,0,
51,9,241,4,0,
51,9,249,4,0,
51,9,263,4,0,
51,9,290,4,0,
51,9,310,1,7,
51,9,317,4,0,
51,9,328,1,26,
51,9,332,4,0,
51,9,363,4,0,
51,9,389,1,23,
51,9,389,3,0,
51,9,400,1,1,1
51,9,414,1,28,
51,9,414,3,0,
51,9,416,4,0,
51,9,421,4,0,
51,9,426,1,33,
51,9,444,4,0,
51,9,445,4,0,
51,9,446,4,0,
51,10,10,1,1,3
51,10,15,4,0,
51,10,28,1,1,4
51,10,45,1,1,5
51,10,45,1,4,
51,10,63,4,0,
51,10,89,1,45,
51,10,89,4,0,
51,10,90,1,50,
51,10,91,1,18,
51,10,91,4,0,
51,10,92,4,0,
51,10,104,4,0,
51,10,156,4,0,
51,10,157,4,0,
51,10,161,1,1,2
51,10,163,1,40,
51,10,164,4,0,
51,10,168,4,0,
51,10,173,3,0,
51,10,182,4,0,
51,10,188,4,0,
51,10,189,1,15,
51,10,189,3,0,
51,10,201,4,0,
51,10,203,4,0,
51,10,207,4,0,
51,10,213,4,0,
51,10,214,4,0,
51,10,216,4,0,
51,10,218,4,0,
51,10,222,1,12,
51,10,237,4,0,
51,10,241,4,0,
51,10,249,4,0,
51,10,263,4,0,
51,10,290,4,0,
51,10,310,1,7,
51,10,317,4,0,
51,10,328,1,26,
51,10,332,4,0,
51,10,363,4,0,
51,10,389,1,23,
51,10,389,3,0,
51,10,400,1,1,1
51,10,414,1,28,
51,10,414,3,0,
51,10,416,4,0,
51,10,421,4,0,
51,10,426,1,33,
51,10,444,4,0,
51,10,445,4,0,
51,10,446,4,0,
51,11,10,1,1,3
51,11,15,4,0,
51,11,28,1,1,4
51,11,45,1,1,5
51,11,45,1,4,
51,11,63,4,0,
51,11,89,1,50,
51,11,89,4,0,
51,11,90,1,57,
51,11,91,1,40,
51,11,91,4,0,
51,11,92,4,0,
51,11,104,4,0,
51,11,156,4,0,
51,11,157,4,0,
51,11,161,1,1,2
51,11,163,1,45,
51,11,164,4,0,
51,11,168,4,0,
51,11,182,4,0,
51,11,188,4,0,
51,11,189,1,12,
51,11,201,4,0,
51,11,207,4,0,
51,11,213,4,0,
51,11,216,4,0,
51,11,218,4,0,
51,11,222,1,15,
51,11,237,4,0,
51,11,241,4,0,
51,11,249,4,0,
51,11,263,4,0,
51,11,310,1,7,
51,11,317,4,0,
51,11,328,1,26,
51,11,332,4,0,
51,11,389,1,23,
51,11,400,1,1,1
51,11,414,1,33,
51,11,416,4,0,
51,11,421,4,0,
51,11,426,1,28,
51,11,444,4,0,
51,11,468,4,0,
51,11,482,4,0,
51,11,496,4,0,
51,11,497,4,0,
51,11,523,1,18,
51,11,523,4,0,
51,12,10,1,1,2
51,12,15,4,0,
51,12,28,1,1,3
51,12,45,1,1,4
51,12,45,1,5,
51,12,63,4,0,
51,12,89,1,51,
51,12,89,4,0,
51,12,90,1,64,
51,12,91,1,17,
51,12,91,4,0,
51,12,92,4,0,
51,12,104,4,0,
51,12,156,4,0,
51,12,161,1,1,1
51,12,163,1,38,
51,12,168,4,0,
51,12,182,4,0,
51,12,188,4,0,
51,12,189,1,25,
51,12,213,4,0,
51,12,216,4,0,
51,12,218,4,0,
51,12,222,1,9,
51,12,237,4,0,
51,12,241,4,0,
51,12,249,4,0,
51,12,263,4,0,
51,12,290,4,0,
51,12,317,4,0,
51,12,328,1,26,
51,12,332,4,0,
51,13,10,1,1,2
51,13,15,4,0,
51,13,28,1,1,3
51,13,34,3,0,
51,13,38,3,0,
51,13,45,1,1,4
51,13,45,1,5,
51,13,63,4,0,
51,13,89,1,51,
51,13,89,4,0,
51,13,90,1,64,
51,13,91,1,17,
51,13,91,4,0,
51,13,92,4,0,
51,13,102,3,0,
51,13,104,4,0,
51,13,156,4,0,
51,13,161,1,1,1
51,13,163,1,38,
51,13,164,3,0,
51,13,168,4,0,
51,13,182,4,0,
51,13,188,4,0,
51,13,189,1,25,
51,13,207,3,0,
51,13,213,4,0,
51,13,216,4,0,
51,13,218,4,0,
51,13,222,1,9,
51,13,237,4,0,
51,13,241,4,0,
51,13,249,4,0,
51,13,263,4,0,
51,13,290,4,0,
51,13,317,4,0,
51,13,328,1,26,
51,13,332,4,0,
51,14,10,1,1,3
51,14,15,4,0,
51,14,28,1,1,4
51,14,45,1,1,5
51,14,45,1,4,
51,14,63,4,0,
51,14,89,1,50,
51,14,89,4,0,
51,14,90,1,57,
51,14,91,1,40,
51,14,91,4,0,
51,14,92,4,0,
51,14,104,4,0,
51,14,156,4,0,
51,14,157,4,0,
51,14,161,1,1,2
51,14,163,1,45,
51,14,164,4,0,
51,14,168,4,0,
51,14,173,3,0,
51,14,182,4,0,
51,14,188,4,0,
51,14,189,1,12,
51,14,201,4,0,
51,14,207,4,0,
51,14,213,4,0,
51,14,214,3,0,
51,14,216,4,0,
51,14,218,4,0,
51,14,222,1,15,
51,14,237,4,0,
51,14,241,4,0,
51,14,249,4,0,
51,14,253,3,0,
51,14,263,4,0,
51,14,310,1,7,
51,14,317,4,0,
51,14,328,1,26,
51,14,332,4,0,
51,14,389,1,23,
51,14,400,1,1,1
51,14,414,1,33,
51,14,414,3,0,
51,14,416,4,0,
51,14,421,4,0,
51,14,426,1,28,
51,14,444,4,0,
51,14,446,3,0,
51,14,468,4,0,
51,14,482,4,0,
51,14,496,4,0,
51,14,497,4,0,
51,14,523,1,18,
51,14,523,4,0,
51,15,10,1,1,4
51,15,15,4,0,
51,15,28,1,1,5
51,15,45,1,1,6
51,15,45,1,4,
51,15,63,4,0,
51,15,89,1,50,
51,15,89,4,0,
51,15,90,1,57,
51,15,91,1,40,
51,15,91,4,0,
51,15,92,4,0,
51,15,104,4,0,
51,15,156,4,0,
51,15,157,4,0,
51,15,161,1,1,3
51,15,163,1,45,
51,15,164,4,0,
51,15,168,4,0,
51,15,182,4,0,
51,15,188,4,0,
51,15,189,1,12,
51,15,201,4,0,
51,15,207,4,0,
51,15,213,4,0,
51,15,214,4,0,
51,15,216,4,0,
51,15,218,4,0,
51,15,222,1,15,
51,15,237,4,0,
51,15,241,4,0,
51,15,249,4,0,
51,15,263,4,0,
51,15,310,1,7,
51,15,317,4,0,
51,15,328,1,26,
51,15,332,4,0,
51,15,389,1,23,
51,15,400,1,1,2
51,15,414,1,33,
51,15,416,4,0,
51,15,421,4,0,
51,15,426,1,28,
51,15,444,4,0,
51,15,468,4,0,
51,15,482,4,0,
51,15,496,4,0,
51,15,497,4,0,
51,15,523,1,18,
51,15,523,4,0,
51,15,563,1,1,1
51,15,590,4,0,
51,16,10,1,1,4
51,16,15,4,0,
51,16,28,1,1,5
51,16,45,1,1,6
51,16,45,1,4,1
51,16,63,4,0,
51,16,89,1,50,1
51,16,89,4,0,
51,16,90,1,57,1
51,16,91,1,40,1
51,16,91,4,0,
51,16,92,4,0,
51,16,104,4,0,
51,16,156,4,0,
51,16,157,4,0,
51,16,161,1,1,3
51,16,163,1,45,1
51,16,164,4,0,
51,16,168,4,0,
51,16,173,3,0,
51,16,182,4,0,
51,16,188,4,0,
51,16,189,1,12,1
51,16,201,4,0,
51,16,207,4,0,
51,16,213,4,0,
51,16,214,4,0,
51,16,216,4,0,
51,16,218,4,0,
51,16,222,1,15,1
51,16,237,4,0,
51,16,241,4,0,
51,16,249,4,0,
51,16,263,4,0,
51,16,290,4,0,
51,16,310,1,7,1
51,16,317,4,0,
51,16,328,1,26,1
51,16,332,4,0,
51,16,389,1,23,1
51,16,400,1,1,2
51,16,414,1,33,1
51,16,414,3,0,
51,16,416,4,0,
51,16,421,4,0,
51,16,426,1,28,1
51,16,444,4,0,
51,16,446,3,0,
51,16,468,4,0,
51,16,482,4,0,
51,16,496,4,0,
51,16,497,4,0,
51,16,523,1,18,1
51,16,523,4,0,
51,16,563,1,1,1
51,16,590,4,0,
51,17,10,1,1,5
51,17,28,1,1,6
51,17,45,1,1,7
51,17,45,1,4,
51,17,63,4,0,
51,17,89,1,47,
51,17,89,4,0,
51,17,90,1,53,
51,17,91,1,35,
51,17,92,4,0,
51,17,104,4,0,
51,17,156,4,0,
51,17,157,4,0,
51,17,161,1,1,4
51,17,163,1,41,
51,17,164,4,0,
51,17,168,4,0,
51,17,182,4,0,
51,17,188,4,0,
51,17,189,1,10,
51,17,201,4,0,
51,17,207,4,0,
51,17,213,4,0,
51,17,214,4,0,
51,17,216,4,0,
51,17,218,4,0,
51,17,222,1,14,
51,17,237,4,0,
51,17,241,4,0,
51,17,263,4,0,
51,17,310,1,7,
51,17,317,4,0,
51,17,328,1,0,
51,17,328,1,1,1
51,17,332,4,0,
51,17,389,1,22,
51,17,400,1,1,3
51,17,414,1,30,
51,17,416,4,0,
51,17,421,4,0,
51,17,426,1,25,
51,17,444,4,0,
51,17,482,4,0,
51,17,496,4,0,
51,17,497,4,0,
51,17,523,1,18,
51,17,523,4,0,
51,17,563,1,1,2
51,17,590,4,0,
51,18,10,1,1,5
51,18,28,1,1,6
51,18,45,1,1,7
51,18,45,1,4,
51,18,63,4,0,
51,18,89,1,47,
51,18,89,4,0,
51,18,90,1,53,
51,18,91,1,35,
51,18,92,4,0,
51,18,104,4,0,
51,18,156,4,0,
51,18,157,4,0,
51,18,161,1,1,4
51,18,163,1,41,
51,18,164,4,0,
51,18,168,4,0,
51,18,182,4,0,
51,18,188,4,0,
51,18,189,1,10,
51,18,201,4,0,
51,18,207,4,0,
51,18,213,4,0,
51,18,214,4,0,
51,18,216,4,0,
51,18,218,4,0,
51,18,222,1,14,
51,18,237,4,0,
51,18,241,4,0,
51,18,263,4,0,
51,18,310,1,7,
51,18,317,4,0,
51,18,328,1,0,
51,18,328,1,1,1
51,18,332,4,0,
51,18,389,1,22,
51,18,400,1,1,3
51,18,414,1,30,
51,18,416,4,0,
51,18,421,4,0,
51,18,426,1,25,
51,18,444,4,0,
51,18,482,4,0,
51,18,496,4,0,
51,18,497,4,0,
51,18,523,1,18,
51,18,523,4,0,
51,18,563,1,1,2
51,18,590,4,0,
52,1,6,1,17,
52,1,6,4,0,
52,1,10,1,1,1
52,1,34,4,0,
52,1,36,4,0,
52,1,38,4,0,
52,1,44,1,12,
52,1,45,1,1,2
52,1,55,4,0,
52,1,61,4,0,
52,1,85,4,0,
52,1,87,4,0,
52,1,92,4,0,
52,1,99,4,0,
52,1,102,4,0,
52,1,103,1,24,
52,1,104,4,0,
52,1,117,4,0,
52,1,129,4,0,
52,1,130,4,0,
52,1,154,1,33,
52,1,156,4,0,
52,1,163,1,44,
52,1,164,4,0,
52,2,6,1,17,
52,2,6,4,0,
52,2,10,1,1,1
52,2,34,4,0,
52,2,36,4,0,
52,2,38,4,0,
52,2,44,1,12,
52,2,45,1,1,2
52,2,55,4,0,
52,2,61,4,0,
52,2,85,4,0,
52,2,87,4,0,
52,2,92,4,0,
52,2,99,4,0,
52,2,102,4,0,
52,2,103,1,24,
52,2,104,4,0,
52,2,117,4,0,
52,2,129,4,0,
52,2,130,4,0,
52,2,154,1,33,
52,2,156,4,0,
52,2,163,1,44,
52,2,164,4,0,
52,3,6,1,20,
52,3,10,1,1,1
52,3,29,4,0,
52,3,44,1,11,
52,3,45,1,1,2
52,3,87,4,0,
52,3,92,4,0,
52,3,95,2,0,
52,3,103,1,35,
52,3,104,4,0,
52,3,111,4,0,
52,3,129,4,0,
52,3,133,2,0,
52,3,138,4,0,
52,3,154,1,41,
52,3,156,4,0,
52,3,163,1,46,
52,3,168,4,0,
52,3,171,4,0,
52,3,173,4,0,
52,3,174,4,0,
52,3,180,2,0,
52,3,182,4,0,
52,3,185,1,28,
52,3,189,4,0,
52,3,192,4,0,
52,3,196,4,0,
52,3,197,4,0,
52,3,203,4,0,
52,3,204,2,0,
52,3,207,4,0,
52,3,213,4,0,
52,3,214,4,0,
52,3,216,4,0,
52,3,218,4,0,
52,3,231,4,0,
52,3,237,4,0,
52,3,241,4,0,
52,3,244,4,0,
52,3,247,4,0,
52,4,6,1,20,
52,4,10,1,1,1
52,4,29,4,0,
52,4,44,1,11,
52,4,45,1,1,2
52,4,85,3,0,
52,4,87,4,0,
52,4,92,4,0,
52,4,95,2,0,
52,4,103,1,35,
52,4,104,4,0,
52,4,111,4,0,
52,4,129,4,0,
52,4,133,2,0,
52,4,138,4,0,
52,4,154,1,41,
52,4,156,4,0,
52,4,163,1,46,
52,4,168,4,0,
52,4,171,4,0,
52,4,173,4,0,
52,4,174,4,0,
52,4,180,2,0,
52,4,182,4,0,
52,4,185,1,28,
52,4,189,4,0,
52,4,192,4,0,
52,4,196,4,0,
52,4,197,4,0,
52,4,203,4,0,
52,4,204,2,0,
52,4,207,4,0,
52,4,213,4,0,
52,4,214,4,0,
52,4,216,4,0,
52,4,218,4,0,
52,4,231,4,0,
52,4,237,4,0,
52,4,241,4,0,
52,4,244,4,0,
52,4,247,4,0,
52,5,6,1,20,
52,5,10,1,1,1
52,5,15,4,0,
52,5,44,1,11,
52,5,45,1,1,2
52,5,85,4,0,
52,5,87,4,0,
52,5,91,4,0,
52,5,92,4,0,
52,5,95,2,0,
52,5,103,1,35,
52,5,104,4,0,
52,5,133,2,0,
52,5,148,4,0,
52,5,154,1,41,
52,5,156,4,0,
52,5,163,1,46,
52,5,168,4,0,
52,5,180,2,0,
52,5,182,4,0,
52,5,185,1,28,
52,5,204,2,0,
52,5,213,4,0,
52,5,216,4,0,
52,5,218,4,0,
52,5,231,4,0,
52,5,237,4,0,
52,5,240,4,0,
52,5,241,4,0,
52,5,244,2,0,
52,5,247,4,0,
52,5,252,1,50,
52,5,259,4,0,
52,5,263,4,0,
52,5,269,4,0,
52,5,274,2,0,
52,5,289,4,0,
52,5,290,4,0,
52,5,332,4,0,
52,5,351,4,0,
52,5,352,4,0,
52,6,6,1,20,
52,6,10,1,1,1
52,6,15,4,0,
52,6,34,3,0,
52,6,38,3,0,
52,6,44,1,11,
52,6,45,1,1,2
52,6,85,4,0,
52,6,87,4,0,
52,6,91,4,0,
52,6,92,4,0,
52,6,95,2,0,
52,6,102,3,0,
52,6,103,1,35,
52,6,104,4,0,
52,6,111,3,0,
52,6,129,3,0,
52,6,133,2,0,
52,6,138,3,0,
52,6,148,4,0,
52,6,154,1,41,
52,6,156,4,0,
52,6,163,1,46,
52,6,164,3,0,
52,6,168,4,0,
52,6,173,3,0,
52,6,180,2,0,
52,6,182,4,0,
52,6,185,1,28,
52,6,189,3,0,
52,6,196,3,0,
52,6,203,3,0,
52,6,204,2,0,
52,6,207,3,0,
52,6,213,4,0,
52,6,214,3,0,
52,6,216,4,0,
52,6,218,4,0,
52,6,231,4,0,
52,6,237,4,0,
52,6,240,4,0,
52,6,241,4,0,
52,6,244,2,0,
52,6,244,3,0,
52,6,247,4,0,
52,6,252,1,50,
52,6,259,4,0,
52,6,263,4,0,
52,6,269,4,0,
52,6,274,2,0,
52,6,289,4,0,
52,6,290,4,0,
52,6,332,4,0,
52,6,351,4,0,
52,6,352,4,0,
52,7,6,1,18,
52,7,10,1,1,1
52,7,15,4,0,
52,7,34,3,0,
52,7,38,3,0,
52,7,44,1,10,
52,7,45,1,1,2
52,7,85,4,0,
52,7,87,4,0,
52,7,91,4,0,
52,7,92,4,0,
52,7,95,2,0,
52,7,102,3,0,
52,7,103,1,31,
52,7,104,4,0,
52,7,133,2,0,
52,7,138,3,0,
52,7,148,4,0,
52,7,154,1,36,
52,7,156,4,0,
52,7,163,1,40,
52,7,164,3,0,
52,7,168,4,0,
52,7,180,2,0,
52,7,182,4,0,
52,7,185,1,25,
52,7,204,2,0,
52,7,207,1,45,
52,7,213,4,0,
52,7,216,4,0,
52,7,218,4,0,
52,7,231,4,0,
52,7,237,4,0,
52,7,240,4,0,
52,7,241,4,0,
52,7,244,2,0,
52,7,247,4,0,
52,7,252,1,43,
52,7,259,4,0,
52,7,263,4,0,
52,7,269,4,0,
52,7,274,2,0,
52,7,289,4,0,
52,7,290,4,0,
52,7,332,4,0,
52,7,351,4,0,
52,7,352,4,0,
52,8,6,1,30,
52,8,10,1,1,1
52,8,15,4,0,
52,8,44,1,6,
52,8,45,1,1,2
52,8,85,4,0,
52,8,87,4,0,
52,8,91,4,0,
52,8,92,4,0,
52,8,95,2,0,
52,8,103,1,17,
52,8,104,4,0,
52,8,133,2,0,
52,8,138,4,0,
52,8,148,4,0,
52,8,154,1,14,
52,8,156,4,0,
52,8,163,1,33,
52,8,164,4,0,
52,8,168,4,0,
52,8,175,2,0,
52,8,180,2,0,
52,8,182,4,0,
52,8,185,1,22,
52,8,203,4,0,
52,8,204,2,0,
52,8,207,4,0,
52,8,213,4,0,
52,8,214,4,0,
52,8,216,4,0,
52,8,218,4,0,
52,8,231,4,0,
52,8,237,4,0,
52,8,240,4,0,
52,8,241,4,0,
52,8,244,2,0,
52,8,244,4,0,
52,8,247,4,0,
52,8,252,1,9,
52,8,259,4,0,
52,8,263,4,0,
52,8,269,1,25,
52,8,269,4,0,
52,8,274,2,0,
52,8,289,4,0,
52,8,290,4,0,
52,8,316,2,0,
52,8,332,4,0,
52,8,351,4,0,
52,8,352,4,0,
52,8,363,4,0,
52,8,369,4,0,
52,8,371,4,0,
52,8,372,1,41,
52,8,386,2,0,
52,8,387,2,0,
52,8,399,4,0,
52,8,400,1,49,
52,8,417,1,38,
52,8,421,4,0,
52,8,445,1,46,
52,8,445,4,0,
52,9,6,1,30,
52,9,10,1,1,1
52,9,15,4,0,
52,9,44,1,6,
52,9,45,1,1,2
52,9,85,4,0,
52,9,87,4,0,
52,9,91,4,0,
52,9,92,4,0,
52,9,95,2,0,
52,9,103,1,17,
52,9,104,4,0,
52,9,129,3,0,
52,9,133,2,0,
52,9,138,4,0,
52,9,148,4,0,
52,9,154,1,14,
52,9,156,4,0,
52,9,163,1,33,
52,9,164,4,0,
52,9,168,4,0,
52,9,173,3,0,
52,9,175,2,0,
52,9,180,2,0,
52,9,180,3,0,
52,9,182,4,0,
52,9,185,1,22,
52,9,189,3,0,
52,9,196,3,0,
52,9,203,4,0,
52,9,204,2,0,
52,9,207,4,0,
52,9,213,4,0,
52,9,214,4,0,
52,9,216,4,0,
52,9,218,4,0,
52,9,231,4,0,
52,9,237,4,0,
52,9,240,4,0,
52,9,241,4,0,
52,9,244,2,0,
52,9,244,4,0,
52,9,247,4,0,
52,9,252,1,9,
52,9,253,3,0,
52,9,259,4,0,
52,9,263,4,0,
52,9,269,1,25,
52,9,269,4,0,
52,9,274,2,0,
52,9,282,3,0,
52,9,289,4,0,
52,9,290,4,0,
52,9,316,2,0,
52,9,332,4,0,
52,9,351,4,0,
52,9,352,4,0,
52,9,363,4,0,
52,9,364,1,54,
52,9,369,4,0,
52,9,371,4,0,
52,9,372,1,41,
52,9,386,2,0,
52,9,387,2,0,
52,9,387,3,0,
52,9,399,4,0,
52,9,400,1,49,
52,9,402,3,0,
52,9,417,1,38,
52,9,421,4,0,
52,9,441,3,0,
52,9,445,1,46,
52,9,445,4,0,
52,10,6,1,30,
52,10,10,1,1,1
52,10,15,4,0,
52,10,29,3,0,
52,10,39,2,0,
52,10,44,1,6,
52,10,45,1,1,2
52,10,85,4,0,
52,10,87,4,0,
52,10,91,4,0,
52,10,92,4,0,
52,10,95,2,0,
52,10,103,1,17,
52,10,104,4,0,
52,10,129,3,0,
52,10,133,2,0,
52,10,138,4,0,
52,10,148,4,0,
52,10,154,1,14,
52,10,156,4,0,
52,10,163,1,33,
52,10,164,4,0,
52,10,168,4,0,
52,10,173,3,0,
52,10,175,2,0,
52,10,180,2,0,
52,10,180,3,0,
52,10,182,4,0,
52,10,185,1,22,
52,10,189,3,0,
52,10,196,3,0,
52,10,203,4,0,
52,10,204,2,0,
52,10,207,4,0,
52,10,213,4,0,
52,10,214,4,0,
52,10,216,4,0,
52,10,218,4,0,
52,10,231,4,0,
52,10,237,4,0,
52,10,240,4,0,
52,10,241,4,0,
52,10,244,2,0,
52,10,244,4,0,
52,10,247,4,0,
52,10,252,1,9,
52,10,253,3,0,
52,10,259,4,0,
52,10,263,4,0,
52,10,269,1,25,
52,10,269,4,0,
52,10,274,2,0,
52,10,282,3,0,
52,10,289,4,0,
52,10,290,4,0,
52,10,316,2,0,
52,10,332,4,0,
52,10,351,4,0,
52,10,352,4,0,
52,10,363,4,0,
52,10,364,1,54,
52,10,369,4,0,
52,10,371,4,0,
52,10,372,1,41,
52,10,386,2,0,
52,10,387,2,0,
52,10,387,3,0,
52,10,399,4,0,
52,10,400,1,49,
52,10,402,3,0,
52,10,417,1,38,
52,10,421,4,0,
52,10,441,3,0,
52,10,445,1,46,
52,10,445,4,0,
52,11,6,1,30,
52,11,10,1,1,1
52,11,15,4,0,
52,11,39,2,0,
52,11,44,1,6,
52,11,45,1,1,2
52,11,85,4,0,
52,11,87,4,0,
52,11,91,4,0,
52,11,92,4,0,
52,11,95,2,0,
52,11,103,1,17,
52,11,104,4,0,
52,11,133,2,0,
52,11,138,4,0,
52,11,148,4,0,
52,11,154,1,14,
52,11,156,4,0,
52,11,163,1,33,
52,11,164,4,0,
52,11,168,4,0,
52,11,175,2,0,
52,11,180,2,0,
52,11,182,4,0,
52,11,185,1,22,
52,11,204,2,0,
52,11,207,4,0,
52,11,213,4,0,
52,11,216,4,0,
52,11,218,4,0,
52,11,231,2,0,
52,11,237,4,0,
52,11,240,4,0,
52,11,241,4,0,
52,11,244,4,0,
52,11,247,4,0,
52,11,252,1,9,
52,11,259,4,0,
52,11,263,4,0,
52,11,269,1,25,
52,11,269,4,0,
52,11,274,2,0,
52,11,289,2,0,
52,11,316,2,0,
52,11,332,4,0,
52,11,364,1,54,
52,11,369,4,0,
52,11,371,4,0,
52,11,372,1,41,
52,11,386,2,0,
52,11,387,2,0,
52,11,400,1,49,
52,11,417,1,38,
52,11,421,4,0,
52,11,445,1,46,
52,11,468,4,0,
52,11,492,2,0,
52,11,496,4,0,
52,11,497,4,0,
52,11,514,4,0,
52,11,526,4,0,
52,12,6,1,20,
52,12,10,1,1,1
52,12,15,4,0,
52,12,44,1,11,
52,12,45,1,1,2
52,12,85,4,0,
52,12,87,4,0,
52,12,91,4,0,
52,12,92,4,0,
52,12,103,1,35,
52,12,104,4,0,
52,12,148,4,0,
52,12,154,1,41,
52,12,156,4,0,
52,12,163,1,46,
52,12,168,4,0,
52,12,182,4,0,
52,12,185,1,28,
52,12,213,4,0,
52,12,216,4,0,
52,12,218,4,0,
52,12,231,4,0,
52,12,237,4,0,
52,12,240,4,0,
52,12,241,4,0,
52,12,247,4,0,
52,12,252,1,50,
52,12,259,4,0,
52,12,263,4,0,
52,12,269,4,0,
52,12,289,4,0,
52,12,290,4,0,
52,12,332,4,0,
52,12,351,4,0,
52,12,352,4,0,
52,13,6,1,20,
52,13,10,1,1,1
52,13,15,4,0,
52,13,34,3,0,
52,13,38,3,0,
52,13,44,1,11,
52,13,45,1,1,2
52,13,85,4,0,
52,13,87,4,0,
52,13,91,4,0,
52,13,92,4,0,
52,13,102,3,0,
52,13,103,1,35,
52,13,104,4,0,
52,13,138,3,0,
52,13,148,4,0,
52,13,154,1,41,
52,13,156,4,0,
52,13,163,1,46,
52,13,164,3,0,
52,13,168,4,0,
52,13,171,3,0,
52,13,182,4,0,
52,13,185,1,28,
52,13,196,3,0,
52,13,207,3,0,
52,13,213,4,0,
52,13,216,4,0,
52,13,218,4,0,
52,13,231,4,0,
52,13,237,4,0,
52,13,240,4,0,
52,13,241,4,0,
52,13,247,4,0,
52,13,252,1,50,
52,13,259,4,0,
52,13,263,4,0,
52,13,269,4,0,
52,13,289,4,0,
52,13,290,4,0,
52,13,332,4,0,
52,13,351,4,0,
52,13,352,4,0,
52,14,6,1,30,
52,14,10,1,1,1
52,14,15,4,0,
52,14,39,2,0,
52,14,44,1,6,
52,14,45,1,1,2
52,14,85,4,0,
52,14,87,4,0,
52,14,91,4,0,
52,14,92,4,0,
52,14,95,2,0,
52,14,103,1,17,
52,14,104,4,0,
52,14,133,2,0,
52,14,138,4,0,
52,14,148,4,0,
52,14,154,1,14,
52,14,156,4,0,
52,14,163,1,33,
52,14,164,4,0,
52,14,168,4,0,
52,14,173,3,0,
52,14,175,2,0,
52,14,180,2,0,
52,14,180,3,0,
52,14,182,4,0,
52,14,185,1,22,
52,14,196,3,0,
52,14,204,2,0,
52,14,207,4,0,
52,14,213,4,0,
52,14,214,3,0,
52,14,216,4,0,
52,14,218,4,0,
52,14,231,2,0,
52,14,231,3,0,
52,14,237,4,0,
52,14,240,4,0,
52,14,241,4,0,
52,14,244,4,0,
52,14,247,4,0,
52,14,252,1,9,
52,14,253,3,0,
52,14,259,4,0,
52,14,263,4,0,
52,14,269,1,25,
52,14,269,4,0,
52,14,274,2,0,
52,14,282,3,0,
52,14,289,2,0,
52,14,289,3,0,
52,14,304,3,0,
52,14,316,2,0,
52,14,332,4,0,
52,14,343,3,0,
52,14,364,1,54,
52,14,369,4,0,
52,14,371,4,0,
52,14,372,1,41,
52,14,386,2,0,
52,14,387,2,0,
52,14,387,3,0,
52,14,399,3,0,
52,14,400,1,49,
52,14,402,3,0,
52,14,417,1,38,
52,14,421,4,0,
52,14,441,3,0,
52,14,445,1,46,
52,14,468,4,0,
52,14,492,2,0,
52,14,492,3,0,
52,14,496,4,0,
52,14,497,4,0,
52,14,514,4,0,
52,14,526,4,0,
52,15,6,1,30,
52,15,10,1,1,1
52,15,15,4,0,
52,15,39,2,0,
52,15,44,1,6,
52,15,45,1,1,2
52,15,85,4,0,
52,15,87,4,0,
52,15,91,4,0,
52,15,92,4,0,
52,15,95,2,0,
52,15,103,1,17,
52,15,104,4,0,
52,15,133,2,0,
52,15,138,4,0,
52,15,148,4,0,
52,15,154,1,14,
52,15,156,4,0,
52,15,163,1,33,
52,15,164,4,0,
52,15,168,4,0,
52,15,175,2,0,
52,15,180,2,0,
52,15,182,4,0,
52,15,185,1,22,
52,15,204,2,0,
52,15,207,4,0,
52,15,213,4,0,
52,15,214,4,0,
52,15,216,4,0,
52,15,218,4,0,
52,15,231,2,0,
52,15,237,4,0,
52,15,240,4,0,
52,15,241,4,0,
52,15,244,4,0,
52,15,247,4,0,
52,15,252,1,9,
52,15,259,4,0,
52,15,263,4,0,
52,15,269,1,25,
52,15,269,4,0,
52,15,274,2,0,
52,15,289,2,0,
52,15,316,2,0,
52,15,332,4,0,
52,15,364,1,50,
52,15,369,4,0,
52,15,371,4,0,
52,15,372,1,41,
52,15,386,2,0,
52,15,387,2,0,
52,15,399,4,0,
52,15,400,1,49,
52,15,417,1,38,
52,15,421,4,0,
52,15,445,1,46,
52,15,468,4,0,
52,15,492,2,0,
52,15,496,4,0,
52,15,497,4,0,
52,15,514,4,0,
52,15,590,4,0,
52,16,6,1,30,1
52,16,10,1,1,1
52,16,15,4,0,
52,16,39,2,0,
52,16,44,1,6,1
52,16,45,1,1,2
52,16,85,4,0,
52,16,87,4,0,
52,16,91,4,0,
52,16,92,4,0,
52,16,95,2,0,
52,16,103,1,17,1
52,16,104,4,0,
52,16,133,2,0,
52,16,138,4,0,
52,16,148,4,0,
52,16,154,1,14,1
52,16,156,4,0,
52,16,163,1,33,1
52,16,164,4,0,
52,16,168,4,0,
52,16,173,3,0,
52,16,175,2,0,
52,16,180,2,0,
52,16,180,3,0,
52,16,182,4,0,
52,16,185,1,22,1
52,16,196,3,0,
52,16,204,2,0,
52,16,207,4,0,
52,16,213,4,0,
52,16,214,4,0,
52,16,216,4,0,
52,16,218,4,0,
52,16,231,2,0,
52,16,231,3,0,
52,16,237,4,0,
52,16,240,4,0,
52,16,241,4,0,
52,16,244,4,0,
52,16,247,4,0,
52,16,252,1,9,1
52,16,253,3,0,
52,16,259,4,0,
52,16,263,4,0,
52,16,269,1,25,1
52,16,269,4,0,
52,16,274,2,0,
52,16,282,3,0,
52,16,289,2,0,
52,16,289,3,0,
52,16,290,4,0,
52,16,304,3,0,
52,16,316,2,0,
52,16,332,4,0,
52,16,343,3,0,
52,16,351,3,0,
52,16,352,3,0,
52,16,364,1,50,1
52,16,369,4,0,
52,16,371,4,0,
52,16,372,1,41,1
52,16,386,2,0,
52,16,387,2,0,
52,16,387,3,0,
52,16,399,4,0,
52,16,400,1,49,1
52,16,402,3,0,
52,16,417,1,38,1
52,16,421,4,0,
52,16,441,3,0,
52,16,445,1,46,1
52,16,468,4,0,
52,16,492,2,0,
52,16,492,3,0,
52,16,496,4,0,
52,16,497,4,0,
52,16,514,4,0,
52,16,590,4,0,
52,17,6,1,30,
52,17,10,1,1,1
52,17,39,2,0,
52,17,44,1,6,
52,17,45,1,1,2
52,17,85,4,0,
52,17,87,4,0,
52,17,92,4,0,
52,17,95,2,0,
52,17,103,1,17,
52,17,104,4,0,
52,17,133,2,0,
52,17,138,4,0,
52,17,154,1,14,
52,17,156,4,0,
52,17,163,1,33,
52,17,164,4,0,
52,17,168,4,0,
52,17,175,2,0,
52,17,180,2,0,
52,17,182,4,0,
52,17,185,1,22,
52,17,204,2,0,
52,17,207,4,0,
52,17,213,4,0,
52,17,214,4,0,
52,17,216,4,0,
52,17,218,4,0,
52,17,231,2,0,
52,17,237,4,0,
52,17,240,4,0,
52,17,241,4,0,
52,17,244,4,0,
52,17,247,4,0,
52,17,252,1,9,
52,17,259,4,0,
52,17,263,4,0,
52,17,269,1,25,
52,17,269,4,0,
52,17,274,2,0,
52,17,289,2,0,
52,17,316,2,0,
52,17,332,4,0,
52,17,364,1,50,
52,17,369,4,0,
52,17,371,4,0,
52,17,372,1,41,
52,17,386,2,0,
52,17,387,2,0,
52,17,399,4,0,
52,17,400,1,49,
52,17,417,1,38,
52,17,421,4,0,
52,17,445,1,46,
52,17,492,2,0,
52,17,496,4,0,
52,17,497,4,0,
52,17,526,4,0,
52,17,590,4,0,
52,18,6,1,30,
52,18,10,1,1,1
52,18,39,2,0,
52,18,44,1,6,
52,18,45,1,1,2
52,18,85,4,0,
52,18,87,4,0,
52,18,92,4,0,
52,18,95,2,0,
52,18,103,1,17,
52,18,104,4,0,
52,18,133,2,0,
52,18,138,4,0,
52,18,154,1,14,
52,18,156,4,0,
52,18,163,1,33,
52,18,164,4,0,
52,18,168,4,0,
52,18,175,2,0,
52,18,180,2,0,
52,18,182,4,0,
52,18,185,1,22,
52,18,204,2,0,
52,18,207,4,0,
52,18,213,4,0,
52,18,214,4,0,
52,18,216,4,0,
52,18,218,4,0,
52,18,231,2,0,
52,18,237,4,0,
52,18,240,4,0,
52,18,241,4,0,
52,18,244,4,0,
52,18,247,4,0,
52,18,252,1,9,
52,18,259,4,0,
52,18,263,4,0,
52,18,269,1,25,
52,18,269,4,0,
52,18,274,2,0,
52,18,289,2,0,
52,18,316,2,0,
52,18,332,4,0,
52,18,364,1,50,
52,18,369,4,0,
52,18,371,4,0,
52,18,372,1,41,
52,18,386,2,0,
52,18,387,2,0,
52,18,399,4,0,
52,18,400,1,49,
52,18,417,1,38,
52,18,421,4,0,
52,18,445,1,46,
52,18,492,2,0,
52,18,496,4,0,
52,18,497,4,0,
52,18,526,4,0,
52,18,590,4,0,
53,1,6,1,17,
53,1,6,4,0,
53,1,10,1,1,1
53,1,34,4,0,
53,1,36,4,0,
53,1,38,4,0,
53,1,44,1,1,3
53,1,44,1,12,
53,1,45,1,1,2
53,1,55,4,0,
53,1,61,4,0,
53,1,63,4,0,
53,1,85,4,0,
53,1,87,4,0,
53,1,92,4,0,
53,1,99,4,0,
53,1,102,4,0,
53,1,103,1,1,4
53,1,103,1,24,
53,1,104,4,0,
53,1,117,4,0,
53,1,129,4,0,
53,1,130,4,0,
53,1,154,1,37,
53,1,156,4,0,
53,1,163,1,51,
53,1,164,4,0,
53,2,6,1,17,
53,2,6,4,0,
53,2,10,1,1,1
53,2,34,4,0,
53,2,36,4,0,
53,2,38,4,0,
53,2,44,1,1,3
53,2,44,1,12,
53,2,45,1,1,2
53,2,55,4,0,
53,2,61,4,0,
53,2,63,4,0,
53,2,85,4,0,
53,2,87,4,0,
53,2,92,4,0,
53,2,99,4,0,
53,2,102,4,0,
53,2,103,1,1,4
53,2,103,1,24,
53,2,104,4,0,
53,2,117,4,0,
53,2,129,4,0,
53,2,130,4,0,
53,2,154,1,37,
53,2,156,4,0,
53,2,163,1,51,
53,2,164,4,0,
53,3,6,1,20,
53,3,10,1,1,1
53,3,29,4,0,
53,3,44,1,1,3
53,3,44,1,11,
53,3,45,1,1,2
53,3,46,4,0,
53,3,63,4,0,
53,3,87,4,0,
53,3,92,4,0,
53,3,103,1,38,
53,3,104,4,0,
53,3,111,4,0,
53,3,129,4,0,
53,3,138,4,0,
53,3,154,1,46,
53,3,156,4,0,
53,3,163,1,53,
53,3,168,4,0,
53,3,171,4,0,
53,3,173,4,0,
53,3,174,4,0,
53,3,182,4,0,
53,3,185,1,29,
53,3,189,4,0,
53,3,192,4,0,
53,3,196,4,0,
53,3,197,4,0,
53,3,203,4,0,
53,3,207,4,0,
53,3,213,4,0,
53,3,214,4,0,
53,3,216,4,0,
53,3,218,4,0,
53,3,231,4,0,
53,3,237,4,0,
53,3,241,4,0,
53,3,244,4,0,
53,3,247,4,0,
53,4,6,1,20,
53,4,10,1,1,1
53,4,29,4,0,
53,4,44,1,1,3
53,4,44,1,11,
53,4,45,1,1,2
53,4,46,4,0,
53,4,63,4,0,
53,4,85,3,0,
53,4,87,4,0,
53,4,92,4,0,
53,4,103,1,38,
53,4,104,4,0,
53,4,111,4,0,
53,4,129,4,0,
53,4,138,4,0,
53,4,154,1,46,
53,4,156,4,0,
53,4,163,1,53,
53,4,168,4,0,
53,4,171,4,0,
53,4,173,4,0,
53,4,174,4,0,
53,4,182,4,0,
53,4,185,1,29,
53,4,189,4,0,
53,4,192,4,0,
53,4,196,4,0,
53,4,197,4,0,
53,4,203,4,0,
53,4,207,4,0,
53,4,213,4,0,
53,4,214,4,0,
53,4,216,4,0,
53,4,218,4,0,
53,4,231,4,0,
53,4,237,4,0,
53,4,241,4,0,
53,4,244,4,0,
53,4,247,4,0,
53,5,6,1,20,
53,5,10,1,1,1
53,5,15,4,0,
53,5,44,1,1,3
53,5,44,1,11,
53,5,45,1,1,2
53,5,46,4,0,
53,5,63,4,0,
53,5,85,4,0,
53,5,87,4,0,
53,5,91,4,0,
53,5,92,4,0,
53,5,103,1,38,
53,5,104,4,0,
53,5,148,4,0,
53,5,154,1,46,
53,5,156,4,0,
53,5,163,1,53,
53,5,168,4,0,
53,5,182,4,0,
53,5,185,1,29,
53,5,213,4,0,
53,5,216,4,0,
53,5,218,4,0,
53,5,231,4,0,
53,5,237,4,0,
53,5,240,4,0,
53,5,241,4,0,
53,5,247,4,0,
53,5,252,1,59,
53,5,259,4,0,
53,5,263,4,0,
53,5,269,4,0,
53,5,289,4,0,
53,5,290,4,0,
53,5,332,4,0,
53,5,351,4,0,
53,5,352,4,0,
53,6,6,1,20,
53,6,10,1,1,1
53,6,15,4,0,
53,6,34,3,0,
53,6,38,3,0,
53,6,44,1,1,3
53,6,44,1,11,
53,6,45,1,1,2
53,6,46,4,0,
53,6,63,4,0,
53,6,85,4,0,
53,6,87,4,0,
53,6,91,4,0,
53,6,92,4,0,
53,6,102,3,0,
53,6,103,1,38,
53,6,104,4,0,
53,6,111,3,0,
53,6,129,3,0,
53,6,138,3,0,
53,6,148,4,0,
53,6,154,1,46,
53,6,156,4,0,
53,6,163,1,53,
53,6,164,3,0,
53,6,168,4,0,
53,6,173,3,0,
53,6,182,4,0,
53,6,185,1,29,
53,6,189,3,0,
53,6,196,3,0,
53,6,203,3,0,
53,6,207,3,0,
53,6,213,4,0,
53,6,214,3,0,
53,6,216,4,0,
53,6,218,4,0,
53,6,231,4,0,
53,6,237,4,0,
53,6,240,4,0,
53,6,241,4,0,
53,6,244,3,0,
53,6,247,4,0,
53,6,252,1,59,
53,6,259,4,0,
53,6,263,4,0,
53,6,269,4,0,
53,6,289,4,0,
53,6,290,4,0,
53,6,332,4,0,
53,6,351,4,0,
53,6,352,4,0,
53,7,6,1,18,
53,7,10,1,1,1
53,7,15,4,0,
53,7,34,3,0,
53,7,38,3,0,
53,7,44,1,1,3
53,7,44,1,10,
53,7,45,1,1,2
53,7,46,4,0,
53,7,63,4,0,
53,7,85,4,0,
53,7,87,4,0,
53,7,91,4,0,
53,7,92,4,0,
53,7,102,3,0,
53,7,103,1,34,
53,7,104,4,0,
53,7,138,3,0,
53,7,148,4,0,
53,7,154,1,42,
53,7,156,4,0,
53,7,163,1,49,
53,7,164,3,0,
53,7,168,4,0,
53,7,182,4,0,
53,7,185,1,25,
53,7,207,1,61,
53,7,213,4,0,
53,7,216,4,0,
53,7,218,4,0,
53,7,231,4,0,
53,7,237,4,0,
53,7,240,4,0,
53,7,241,4,0,
53,7,247,4,0,
53,7,252,1,55,
53,7,259,4,0,
53,7,263,4,0,
53,7,269,4,0,
53,7,289,4,0,
53,7,290,4,0,
53,7,332,4,0,
53,7,351,4,0,
53,7,352,4,0,
53,8,10,1,1,2
53,8,15,4,0,
53,8,44,1,1,4
53,8,44,1,6,
53,8,45,1,1,3
53,8,46,4,0,
53,8,63,4,0,
53,8,85,4,0,
53,8,87,4,0,
53,8,91,4,0,
53,8,92,4,0,
53,8,103,1,17,
53,8,104,4,0,
53,8,138,4,0,
53,8,148,4,0,
53,8,154,1,14,
53,8,156,4,0,
53,8,163,1,37,
53,8,164,4,0,
53,8,168,4,0,
53,8,182,4,0,
53,8,185,1,22,
53,8,203,4,0,
53,8,207,4,0,
53,8,213,4,0,
53,8,214,4,0,
53,8,216,4,0,
53,8,218,4,0,
53,8,231,4,0,
53,8,237,4,0,
53,8,240,4,0,
53,8,241,4,0,
53,8,244,4,0,
53,8,247,4,0,
53,8,252,1,1,5
53,8,252,1,9,
53,8,259,4,0,
53,8,263,4,0,
53,8,269,1,25,
53,8,269,4,0,
53,8,289,4,0,
53,8,290,4,0,
53,8,332,4,0,
53,8,351,4,0,
53,8,352,4,0,
53,8,363,4,0,
53,8,369,4,0,
53,8,371,4,0,
53,8,372,1,49,
53,8,373,4,0,
53,8,399,4,0,
53,8,400,1,61,
53,8,408,1,32,
53,8,415,1,1,1
53,8,416,4,0,
53,8,417,1,44,
53,8,421,4,0,
53,8,445,1,56,
53,8,445,4,0,
53,9,10,1,1,2
53,9,15,4,0,
53,9,44,1,1,4
53,9,44,1,6,
53,9,45,1,1,3
53,9,46,4,0,
53,9,63,4,0,
53,9,85,4,0,
53,9,87,4,0,
53,9,91,4,0,
53,9,92,4,0,
53,9,103,1,17,
53,9,104,4,0,
53,9,129,3,0,
53,9,138,4,0,
53,9,148,4,0,
53,9,154,1,14,
53,9,156,4,0,
53,9,163,1,37,
53,9,164,4,0,
53,9,168,4,0,
53,9,173,3,0,
53,9,180,3,0,
53,9,182,4,0,
53,9,185,1,22,
53,9,189,3,0,
53,9,196,3,0,
53,9,203,4,0,
53,9,207,4,0,
53,9,213,4,0,
53,9,214,4,0,
53,9,216,4,0,
53,9,218,4,0,
53,9,231,4,0,
53,9,237,4,0,
53,9,240,4,0,
53,9,241,4,0,
53,9,244,4,0,
53,9,247,4,0,
53,9,252,1,1,5
53,9,252,1,9,
53,9,253,3,0,
53,9,259,4,0,
53,9,263,4,0,
53,9,269,1,25,
53,9,269,4,0,
53,9,282,3,0,
53,9,289,4,0,
53,9,290,4,0,
53,9,332,4,0,
53,9,351,4,0,
53,9,352,4,0,
53,9,363,4,0,
53,9,364,1,68,
53,9,369,4,0,
53,9,371,4,0,
53,9,372,1,49,
53,9,373,4,0,
53,9,387,3,0,
53,9,399,4,0,
53,9,400,1,61,
53,9,402,3,0,
53,9,408,1,32,
53,9,415,1,1,1
53,9,416,4,0,
53,9,417,1,44,
53,9,421,4,0,
53,9,441,3,0,
53,9,445,1,56,
53,9,445,4,0,
53,10,10,1,1,2
53,10,15,4,0,
53,10,29,3,0,
53,10,44,1,1,4
53,10,44,1,6,
53,10,45,1,1,3
53,10,46,4,0,
53,10,63,4,0,
53,10,85,4,0,
53,10,87,4,0,
53,10,91,4,0,
53,10,92,4,0,
53,10,103,1,17,
53,10,104,4,0,
53,10,129,3,0,
53,10,138,4,0,
53,10,148,4,0,
53,10,154,1,14,
53,10,156,4,0,
53,10,163,1,37,
53,10,164,4,0,
53,10,168,4,0,
53,10,173,3,0,
53,10,180,3,0,
53,10,182,4,0,
53,10,185,1,22,
53,10,189,3,0,
53,10,196,3,0,
53,10,203,4,0,
53,10,207,4,0,
53,10,213,4,0,
53,10,214,4,0,
53,10,216,4,0,
53,10,218,4,0,
53,10,231,4,0,
53,10,237,4,0,
53,10,240,4,0,
53,10,241,4,0,
53,10,244,4,0,
53,10,247,4,0,
53,10,252,1,1,5
53,10,252,1,9,
53,10,253,3,0,
53,10,259,4,0,
53,10,263,4,0,
53,10,269,1,25,
53,10,269,4,0,
53,10,282,3,0,
53,10,289,4,0,
53,10,290,4,0,
53,10,332,4,0,
53,10,351,4,0,
53,10,352,4,0,
53,10,363,4,0,
53,10,364,1,68,
53,10,369,4,0,
53,10,371,4,0,
53,10,372,1,49,
53,10,373,4,0,
53,10,387,3,0,
53,10,399,4,0,
53,10,400,1,61,
53,10,402,3,0,
53,10,408,1,32,
53,10,415,1,1,1
53,10,416,4,0,
53,10,417,1,44,
53,10,421,4,0,
53,10,441,3,0,
53,10,445,1,56,
53,10,445,4,0,
53,11,10,1,1,2
53,11,15,4,0,
53,11,44,1,1,4
53,11,44,1,6,
53,11,45,1,1,3
53,11,46,4,0,
53,11,63,4,0,
53,11,85,4,0,
53,11,87,4,0,
53,11,91,4,0,
53,11,92,4,0,
53,11,103,1,17,
53,11,104,4,0,
53,11,138,4,0,
53,11,148,4,0,
53,11,154,1,14,
53,11,156,4,0,
53,11,163,1,37,
53,11,164,4,0,
53,11,168,4,0,
53,11,182,4,0,
53,11,185,1,22,
53,11,207,4,0,
53,11,213,4,0,
53,11,216,4,0,
53,11,218,4,0,
53,11,237,4,0,
53,11,240,4,0,
53,11,241,4,0,
53,11,244,4,0,
53,11,247,4,0,
53,11,252,1,1,5
53,11,252,1,9,
53,11,259,4,0,
53,11,263,4,0,
53,11,269,1,25,
53,11,269,4,0,
53,11,332,4,0,
53,11,364,1,68,
53,11,369,4,0,
53,11,371,4,0,
53,11,372,1,49,
53,11,373,4,0,
53,11,400,1,61,
53,11,408,1,32,
53,11,415,1,1,1
53,11,416,4,0,
53,11,417,1,44,
53,11,421,4,0,
53,11,445,1,56,
53,11,468,4,0,
53,11,496,4,0,
53,11,497,4,0,
53,11,514,4,0,
53,11,526,4,0,
53,12,6,1,20,
53,12,10,1,1,1
53,12,15,4,0,
53,12,44,1,1,3
53,12,44,1,11,
53,12,45,1,1,2
53,12,46,4,0,
53,12,63,4,0,
53,12,85,4,0,
53,12,87,4,0,
53,12,91,4,0,
53,12,92,4,0,
53,12,103,1,38,
53,12,104,4,0,
53,12,148,4,0,
53,12,154,1,46,
53,12,156,4,0,
53,12,163,1,53,
53,12,168,4,0,
53,12,182,4,0,
53,12,185,1,29,
53,12,213,4,0,
53,12,216,4,0,
53,12,218,4,0,
53,12,231,4,0,
53,12,237,4,0,
53,12,240,4,0,
53,12,241,4,0,
53,12,247,4,0,
53,12,252,1,59,
53,12,259,4,0,
53,12,263,4,0,
53,12,269,4,0,
53,12,289,4,0,
53,12,290,4,0,
53,12,332,4,0,
53,12,351,4,0,
53,12,352,4,0,
53,13,6,1,20,
53,13,10,1,1,1
53,13,15,4,0,
53,13,34,3,0,
53,13,38,3,0,
53,13,44,1,1,3
53,13,44,1,11,
53,13,45,1,1,2
53,13,46,4,0,
53,13,63,4,0,
53,13,85,4,0,
53,13,87,4,0,
53,13,91,4,0,
53,13,92,4,0,
53,13,102,3,0,
53,13,103,1,38,
53,13,104,4,0,
53,13,138,3,0,
53,13,148,4,0,
53,13,154,1,46,
53,13,156,4,0,
53,13,163,1,53,
53,13,164,3,0,
53,13,168,4,0,
53,13,171,3,0,
53,13,182,4,0,
53,13,185,1,29,
53,13,196,3,0,
53,13,207,3,0,
53,13,213,4,0,
53,13,216,4,0,
53,13,218,4,0,
53,13,231,4,0,
53,13,237,4,0,
53,13,240,4,0,
53,13,241,4,0,
53,13,247,4,0,
53,13,252,1,59,
53,13,259,4,0,
53,13,263,4,0,
53,13,269,4,0,
53,13,289,4,0,
53,13,290,4,0,
53,13,332,4,0,
53,13,351,4,0,
53,13,352,4,0,
53,14,10,1,1,2
53,14,15,4,0,
53,14,44,1,1,4
53,14,44,1,6,
53,14,45,1,1,3
53,14,46,4,0,
53,14,63,4,0,
53,14,85,4,0,
53,14,87,4,0,
53,14,91,4,0,
53,14,92,4,0,
53,14,103,1,17,
53,14,104,4,0,
53,14,129,1,28,
53,14,138,4,0,
53,14,148,4,0,
53,14,154,1,14,
53,14,156,4,0,
53,14,163,1,37,
53,14,164,4,0,
53,14,168,4,0,
53,14,173,3,0,
53,14,180,3,0,
53,14,182,4,0,
53,14,185,1,22,
53,14,196,3,0,
53,14,207,4,0,
53,14,213,4,0,
53,14,214,3,0,
53,14,216,4,0,
53,14,218,4,0,
53,14,231,3,0,
53,14,237,4,0,
53,14,240,4,0,
53,14,241,4,0,
53,14,244,4,0,
53,14,247,4,0,
53,14,252,1,1,5
53,14,252,1,9,
53,14,253,3,0,
53,14,259,4,0,
53,14,263,4,0,
53,14,269,1,25,
53,14,269,4,0,
53,14,282,3,0,
53,14,289,3,0,
53,14,304,3,0,
53,14,332,4,0,
53,14,343,3,0,
53,14,364,1,68,
53,14,369,4,0,
53,14,371,4,0,
53,14,372,1,49,
53,14,373,4,0,
53,14,387,3,0,
53,14,399,3,0,
53,14,400,1,61,
53,14,402,3,0,
53,14,408,1,32,
53,14,415,1,1,1
53,14,416,4,0,
53,14,417,1,44,
53,14,421,4,0,
53,14,441,3,0,
53,14,445,1,56,
53,14,468,4,0,
53,14,492,3,0,
53,14,496,4,0,
53,14,497,4,0,
53,14,514,4,0,
53,14,526,4,0,
53,15,10,1,1,3
53,15,15,4,0,
53,15,44,1,1,5
53,15,44,1,6,
53,15,45,1,1,4
53,15,46,4,0,
53,15,63,4,0,
53,15,85,4,0,
53,15,87,4,0,
53,15,91,4,0,
53,15,92,4,0,
53,15,103,1,17,
53,15,104,4,0,
53,15,129,1,28,
53,15,138,4,0,
53,15,148,4,0,
53,15,154,1,14,
53,15,156,4,0,
53,15,163,1,37,
53,15,164,4,0,
53,15,168,4,0,
53,15,182,4,0,
53,15,185,1,22,
53,15,207,4,0,
53,15,213,4,0,
53,15,214,4,0,
53,15,216,4,0,
53,15,218,4,0,
53,15,237,4,0,
53,15,240,4,0,
53,15,241,4,0,
53,15,244,4,0,
53,15,247,4,0,
53,15,252,1,1,6
53,15,252,1,9,
53,15,259,4,0,
53,15,263,4,0,
53,15,269,1,25,
53,15,269,4,0,
53,15,332,4,0,
53,15,364,1,65,
53,15,369,4,0,
53,15,371,4,0,
53,15,372,1,49,
53,15,373,4,0,
53,15,399,4,0,
53,15,400,1,61,
53,15,408,1,32,
53,15,415,1,1,2
53,15,416,4,0,
53,15,417,1,44,
53,15,421,4,0,
53,15,445,1,56,
53,15,468,4,0,
53,15,496,4,0,
53,15,497,4,0,
53,15,514,4,0,
53,15,583,1,1,1
53,15,590,4,0,
53,16,10,1,1,3
53,16,15,4,0,
53,16,44,1,1,5
53,16,44,1,6,1
53,16,45,1,1,4
53,16,46,4,0,
53,16,63,4,0,
53,16,85,4,0,
53,16,87,4,0,
53,16,91,4,0,
53,16,92,4,0,
53,16,103,1,17,1
53,16,104,4,0,
53,16,129,1,28,1
53,16,138,4,0,
53,16,148,4,0,
53,16,154,1,14,1
53,16,156,4,0,
53,16,163,1,37,1
53,16,164,4,0,
53,16,168,4,0,
53,16,173,3,0,
53,16,180,3,0,
53,16,182,4,0,
53,16,185,1,22,1
53,16,196,3,0,
53,16,207,4,0,
53,16,213,4,0,
53,16,214,4,0,
53,16,216,4,0,
53,16,218,4,0,
53,16,231,3,0,
53,16,237,4,0,
53,16,240,4,0,
53,16,241,4,0,
53,16,244,4,0,
53,16,247,4,0,
53,16,252,1,1,6
53,16,252,1,9,1
53,16,253,3,0,
53,16,259,4,0,
53,16,263,4,0,
53,16,269,1,25,1
53,16,269,4,0,
53,16,282,3,0,
53,16,289,3,0,
53,16,290,4,0,
53,16,304,3,0,
53,16,332,4,0,
53,16,343,3,0,
53,16,351,3,0,
53,16,352,3,0,
53,16,364,1,65,1
53,16,369,4,0,
53,16,371,4,0,
53,16,372,1,49,1
53,16,373,4,0,
53,16,387,3,0,
53,16,399,4,0,
53,16,400,1,61,1
53,16,402,3,0,
53,16,408,1,32,1
53,16,415,1,1,2
53,16,416,4,0,
53,16,417,1,44,1
53,16,421,4,0,
53,16,441,3,0,
53,16,445,1,56,1
53,16,468,4,0,
53,16,492,3,0,
53,16,496,4,0,
53,16,497,4,0,
53,16,514,4,0,
53,16,583,1,1,1
53,16,590,4,0,
53,17,10,1,1,4
53,17,44,1,1,6
53,17,44,1,6,
53,17,45,1,1,5
53,17,46,4,0,
53,17,63,4,0,
53,17,85,4,0,
53,17,87,4,0,
53,17,92,4,0,
53,17,103,1,17,
53,17,104,4,0,
53,17,129,1,0,
53,17,129,1,1,1
53,17,138,4,0,
53,17,154,1,14,
53,17,156,4,0,
53,17,163,1,37,
53,17,164,4,0,
53,17,168,4,0,
53,17,182,4,0,
53,17,185,1,22,
53,17,207,4,0,
53,17,213,4,0,
53,17,214,4,0,
53,17,216,4,0,
53,17,218,4,0,
53,17,237,4,0,
53,17,240,4,0,
53,17,241,4,0,
53,17,244,4,0,
53,17,247,4,0,
53,17,252,1,1,7
53,17,252,1,9,
53,17,259,4,0,
53,17,263,4,0,
53,17,269,1,25,
53,17,269,4,0,
53,17,332,4,0,
53,17,364,1,65,
53,17,369,4,0,
53,17,371,4,0,
53,17,372,1,49,
53,17,373,4,0,
53,17,399,4,0,
53,17,400,1,61,
53,17,408,1,32,
53,17,415,1,1,3
53,17,416,4,0,
53,17,417,1,44,
53,17,421,4,0,
53,17,445,1,56,
53,17,496,4,0,
53,17,497,4,0,
53,17,526,4,0,
53,17,583,1,1,2
53,17,590,4,0,
53,18,10,1,1,4
53,18,44,1,1,6
53,18,44,1,6,
53,18,45,1,1,5
53,18,46,4,0,
53,18,63,4,0,
53,18,85,4,0,
53,18,87,4,0,
53,18,92,4,0,
53,18,103,1,17,
53,18,104,4,0,
53,18,129,1,0,
53,18,129,1,1,1
53,18,138,4,0,
53,18,154,1,14,
53,18,156,4,0,
53,18,163,1,37,
53,18,164,4,0,
53,18,168,4,0,
53,18,182,4,0,
53,18,185,1,22,
53,18,207,4,0,
53,18,213,4,0,
53,18,214,4,0,
53,18,216,4,0,
53,18,218,4,0,
53,18,237,4,0,
53,18,240,4,0,
53,18,241,4,0,
53,18,244,4,0,
53,18,247,4,0,
53,18,252,1,1,7
53,18,252,1,9,
53,18,259,4,0,
53,18,263,4,0,
53,18,269,1,25,
53,18,269,4,0,
53,18,332,4,0,
53,18,364,1,65,
53,18,369,4,0,
53,18,371,4,0,
53,18,372,1,49,
53,18,373,4,0,
53,18,399,4,0,
53,18,400,1,61,
53,18,408,1,32,
53,18,415,1,1,3
53,18,416,4,0,
53,18,417,1,44,
53,18,421,4,0,
53,18,445,1,56,
53,18,496,4,0,
53,18,497,4,0,
53,18,526,4,0,
53,18,583,1,1,2
53,18,590,4,0,
54,1,5,4,0,
54,1,6,4,0,
54,1,10,1,1,
54,1,25,4,0,
54,1,34,4,0,
54,1,36,4,0,
54,1,38,4,0,
54,1,39,1,28,
54,1,50,1,31,
54,1,55,4,0,
54,1,56,1,52,
54,1,57,4,0,
54,1,58,4,0,
54,1,59,4,0,
54,1,61,4,0,
54,1,66,4,0,
54,1,68,4,0,
54,1,69,4,0,
54,1,70,4,0,
54,1,91,4,0,
54,1,92,4,0,
54,1,93,1,36,
54,1,99,4,0,
54,1,102,4,0,
54,1,104,4,0,
54,1,117,4,0,
54,1,129,4,0,
54,1,130,4,0,
54,1,154,1,43,
54,1,156,4,0,
54,1,164,4,0,
54,2,5,4,0,
54,2,6,4,0,
54,2,10,1,1,
54,2,25,4,0,
54,2,34,4,0,
54,2,36,4,0,
54,2,38,4,0,
54,2,39,1,28,
54,2,50,1,31,
54,2,55,4,0,
54,2,56,1,52,
54,2,57,4,0,
54,2,58,4,0,
54,2,59,4,0,
54,2,61,4,0,
54,2,66,4,0,
54,2,68,4,0,
54,2,69,4,0,
54,2,70,4,0,
54,2,91,4,0,
54,2,92,4,0,
54,2,93,1,36,
54,2,99,4,0,
54,2,102,4,0,
54,2,104,4,0,
54,2,117,4,0,
54,2,129,4,0,
54,2,130,4,0,
54,2,154,1,43,
54,2,156,4,0,
54,2,164,4,0,
54,3,8,4,0,
54,3,10,1,1,
54,3,29,4,0,
54,3,39,1,5,
54,3,50,1,10,
54,3,56,1,50,
54,3,57,4,0,
54,3,58,2,0,
54,3,59,4,0,
54,3,60,2,0,
54,3,70,4,0,
54,3,91,4,0,
54,3,92,4,0,
54,3,93,1,16,
54,3,94,2,0,
54,3,95,2,0,
54,3,103,1,23,
54,3,104,4,0,
54,3,113,2,0,
54,3,127,4,0,
54,3,129,4,0,
54,3,148,4,0,
54,3,154,1,40,
54,3,156,4,0,
54,3,173,4,0,
54,3,174,4,0,
54,3,182,4,0,
54,3,189,4,0,
54,3,193,2,0,
54,3,196,4,0,
54,3,203,4,0,
54,3,207,4,0,
54,3,213,4,0,
54,3,214,4,0,
54,3,216,4,0,
54,3,218,4,0,
54,3,223,4,0,
54,3,231,4,0,
54,3,237,4,0,
54,3,240,4,0,
54,3,244,1,31,
54,3,244,4,0,
54,3,248,2,0,
54,3,249,4,0,
54,3,250,4,0,
54,4,8,4,0,
54,4,10,1,1,
54,4,29,4,0,
54,4,39,1,5,
54,4,50,1,10,
54,4,56,1,50,
54,4,57,4,0,
54,4,58,2,0,
54,4,58,3,0,
54,4,59,4,0,
54,4,60,2,0,
54,4,70,4,0,
54,4,91,4,0,
54,4,92,4,0,
54,4,93,1,16,
54,4,94,2,0,
54,4,95,2,0,
54,4,103,1,23,
54,4,104,4,0,
54,4,113,2,0,
54,4,127,4,0,
54,4,129,4,0,
54,4,148,4,0,
54,4,154,1,40,
54,4,156,4,0,
54,4,173,4,0,
54,4,174,4,0,
54,4,182,4,0,
54,4,189,4,0,
54,4,193,2,0,
54,4,196,4,0,
54,4,203,4,0,
54,4,207,4,0,
54,4,213,4,0,
54,4,214,4,0,
54,4,216,4,0,
54,4,218,4,0,
54,4,223,4,0,
54,4,231,4,0,
54,4,237,4,0,
54,4,238,2,0,
54,4,240,4,0,
54,4,244,1,31,
54,4,244,4,0,
54,4,248,2,0,
54,4,249,4,0,
54,4,250,4,0,
54,5,10,1,1,2
54,5,39,1,5,
54,5,50,1,10,
54,5,56,1,50,
54,5,57,4,0,
54,5,58,4,0,
54,5,59,4,0,
54,5,60,2,0,
54,5,70,4,0,
54,5,91,4,0,
54,5,92,4,0,
54,5,93,1,16,
54,5,94,2,0,
54,5,95,2,0,
54,5,103,1,23,
54,5,104,4,0,
54,5,113,2,0,
54,5,127,4,0,
54,5,148,4,0,
54,5,154,1,40,
54,5,156,4,0,
54,5,182,4,0,
54,5,193,2,0,
54,5,213,4,0,
54,5,216,4,0,
54,5,218,4,0,
54,5,231,4,0,
54,5,237,4,0,
54,5,238,2,0,
54,5,240,4,0,
54,5,244,1,31,
54,5,248,2,0,
54,5,249,4,0,
54,5,258,4,0,
54,5,263,4,0,
54,5,264,4,0,
54,5,280,4,0,
54,5,287,2,0,
54,5,290,4,0,
54,5,291,4,0,
54,5,332,4,0,
54,5,346,1,1,1
54,5,347,4,0,
54,5,352,4,0,
54,6,5,3,0,
54,6,8,3,0,
54,6,10,1,1,2
54,6,25,3,0,
54,6,34,3,0,
54,6,38,3,0,
54,6,39,1,5,
54,6,50,1,10,
54,6,56,1,50,
54,6,57,4,0,
54,6,58,4,0,
54,6,59,4,0,
54,6,60,2,0,
54,6,68,3,0,
54,6,69,3,0,
54,6,70,4,0,
54,6,91,4,0,
54,6,92,4,0,
54,6,93,1,16,
54,6,94,2,0,
54,6,95,2,0,
54,6,102,3,0,
54,6,103,1,23,
54,6,104,4,0,
54,6,113,2,0,
54,6,127,4,0,
54,6,129,3,0,
54,6,148,4,0,
54,6,154,1,40,
54,6,156,4,0,
54,6,164,3,0,
54,6,173,3,0,
54,6,182,4,0,
54,6,189,3,0,
54,6,193,2,0,
54,6,196,3,0,
54,6,203,3,0,
54,6,207,3,0,
54,6,213,4,0,
54,6,214,3,0,
54,6,216,4,0,
54,6,218,4,0,
54,6,223,3,0,
54,6,231,4,0,
54,6,237,4,0,
54,6,238,2,0,
54,6,240,4,0,
54,6,244,1,31,
54,6,244,3,0,
54,6,248,2,0,
54,6,249,4,0,
54,6,258,4,0,
54,6,263,4,0,
54,6,264,4,0,
54,6,280,4,0,
54,6,287,2,0,
54,6,290,4,0,
54,6,291,4,0,
54,6,332,4,0,
54,6,346,1,1,1
54,6,347,4,0,
54,6,352,4,0,
54,7,5,3,0,
54,7,10,1,1,2
54,7,25,3,0,
54,7,34,3,0,
54,7,38,3,0,
54,7,39,1,5,
54,7,50,1,10,
54,7,56,1,50,
54,7,57,4,0,
54,7,58,4,0,
54,7,59,4,0,
54,7,60,2,0,
54,7,68,3,0,
54,7,69,3,0,
54,7,70,4,0,
54,7,91,4,0,
54,7,92,4,0,
54,7,93,1,16,
54,7,94,2,0,
54,7,95,2,0,
54,7,102,3,0,
54,7,103,1,23,
54,7,104,4,0,
54,7,113,2,0,
54,7,127,4,0,
54,7,148,4,0,
54,7,154,1,40,
54,7,156,4,0,
54,7,164,3,0,
54,7,182,4,0,
54,7,193,2,0,
54,7,213,4,0,
54,7,216,4,0,
54,7,218,4,0,
54,7,231,4,0,
54,7,237,4,0,
54,7,238,2,0,
54,7,240,4,0,
54,7,244,1,31,
54,7,248,2,0,
54,7,249,4,0,
54,7,258,4,0,
54,7,263,4,0,
54,7,264,4,0,
54,7,280,4,0,
54,7,287,2,0,
54,7,290,4,0,
54,7,291,4,0,
54,7,332,4,0,
54,7,346,1,1,1
54,7,347,4,0,
54,7,352,4,0,
54,8,10,1,1,2
54,8,39,1,5,
54,8,50,1,14,
54,8,55,1,9,
54,8,56,1,48,
54,8,57,4,0,
54,8,58,4,0,
54,8,59,4,0,
54,8,60,2,0,
54,8,70,4,0,
54,8,91,4,0,
54,8,92,4,0,
54,8,93,1,18,
54,8,94,2,0,
54,8,94,4,0,
54,8,95,2,0,
54,8,103,1,31,
54,8,104,4,0,
54,8,109,2,0,
54,8,113,2,0,
54,8,127,4,0,
54,8,133,1,44,
54,8,148,4,0,
54,8,154,1,27,
54,8,156,4,0,
54,8,164,4,0,
54,8,182,4,0,
54,8,193,2,0,
54,8,203,4,0,
54,8,207,4,0,
54,8,213,4,0,
54,8,214,4,0,
54,8,216,4,0,
54,8,218,4,0,
54,8,231,4,0,
54,8,237,4,0,
54,8,238,2,0,
54,8,240,4,0,
54,8,244,1,35,
54,8,244,4,0,
54,8,248,2,0,
54,8,249,4,0,
54,8,258,4,0,
54,8,263,4,0,
54,8,264,4,0,
54,8,280,4,0,
54,8,281,2,0,
54,8,287,2,0,
54,8,290,4,0,
54,8,332,4,0,
54,8,346,1,1,1
54,8,347,4,0,
54,8,352,1,22,
54,8,352,4,0,
54,8,362,4,0,
54,8,363,4,0,
54,8,374,4,0,
54,8,421,4,0,
54,8,426,2,0,
54,8,428,1,40,
54,8,445,4,0,
54,9,8,3,0,
54,9,10,1,1,2
54,9,39,1,5,
54,9,50,1,14,
54,9,55,1,9,
54,9,56,1,48,
54,9,57,4,0,
54,9,58,4,0,
54,9,59,4,0,
54,9,60,2,0,
54,9,70,4,0,
54,9,91,4,0,
54,9,92,4,0,
54,9,93,1,18,
54,9,94,2,0,
54,9,94,4,0,
54,9,95,2,0,
54,9,103,1,31,
54,9,104,4,0,
54,9,109,2,0,
54,9,113,2,0,
54,9,127,4,0,
54,9,129,3,0,
54,9,133,1,44,
54,9,148,4,0,
54,9,154,1,27,
54,9,156,4,0,
54,9,164,4,0,
54,9,173,3,0,
54,9,182,4,0,
54,9,189,3,0,
54,9,193,2,0,
54,9,196,3,0,
54,9,203,4,0,
54,9,207,4,0,
54,9,213,4,0,
54,9,214,4,0,
54,9,216,4,0,
54,9,218,4,0,
54,9,231,4,0,
54,9,237,4,0,
54,9,238,2,0,
54,9,240,4,0,
54,9,244,1,35,
54,9,244,4,0,
54,9,248,2,0,
54,9,249,4,0,
54,9,258,4,0,
54,9,263,4,0,
54,9,264,4,0,
54,9,280,4,0,
54,9,281,2,0,
54,9,287,2,0,
54,9,290,4,0,
54,9,291,3,0,
54,9,324,3,0,
54,9,332,4,0,
54,9,346,1,1,1
54,9,347,4,0,
54,9,352,1,22,
54,9,352,4,0,
54,9,362,4,0,
54,9,363,4,0,
54,9,374,4,0,
54,9,401,3,0,
54,9,421,4,0,
54,9,426,2,0,
54,9,428,1,40,
54,9,428,3,0,
54,9,445,4,0,
54,10,8,3,0,
54,10,10,1,1,2
54,10,29,3,0,
54,10,39,1,5,
54,10,50,1,14,
54,10,55,1,9,
54,10,56,1,48,
54,10,57,4,0,
54,10,58,4,0,
54,10,59,4,0,
54,10,60,2,0,
54,10,70,4,0,
54,10,91,4,0,
54,10,92,4,0,
54,10,93,1,18,
54,10,94,2,0,
54,10,94,4,0,
54,10,95,2,0,
54,10,103,1,31,
54,10,104,4,0,
54,10,109,2,0,
54,10,113,2,0,
54,10,127,4,0,
54,10,129,3,0,
54,10,133,1,44,
54,10,148,4,0,
54,10,154,1,27,
54,10,156,4,0,
54,10,164,4,0,
54,10,173,3,0,
54,10,182,4,0,
54,10,189,3,0,
54,10,193,2,0,
54,10,196,3,0,
54,10,203,4,0,
54,10,207,4,0,
54,10,213,4,0,
54,10,214,4,0,
54,10,216,4,0,
54,10,218,4,0,
54,10,227,2,0,
54,10,231,4,0,
54,10,237,4,0,
54,10,238,2,0,
54,10,240,4,0,
54,10,244,1,35,
54,10,244,4,0,
54,10,248,2,0,
54,10,249,4,0,
54,10,250,4,0,
54,10,258,4,0,
54,10,263,4,0,
54,10,264,4,0,
54,10,272,3,0,
54,10,280,4,0,
54,10,281,2,0,
54,10,287,2,0,
54,10,290,4,0,
54,10,291,3,0,
54,10,324,3,0,
54,10,332,4,0,
54,10,346,1,1,1
54,10,347,4,0,
54,10,352,1,22,
54,10,352,4,0,
54,10,362,4,0,
54,10,363,4,0,
54,10,374,4,0,
54,10,388,3,0,
54,10,401,3,0,
54,10,421,4,0,
54,10,426,2,0,
54,10,428,1,40,
54,10,428,3,0,
54,10,445,4,0,
54,11,10,1,1,2
54,11,39,1,5,
54,11,50,1,14,
54,11,55,1,9,
54,11,56,1,53,
54,11,57,4,0,
54,11,58,4,0,
54,11,59,4,0,
54,11,60,2,0,
54,11,70,4,0,
54,11,91,4,0,
54,11,92,4,0,
54,11,93,1,18,
54,11,94,4,0,
54,11,95,2,0,
54,11,103,1,31,
54,11,104,4,0,
54,11,109,2,0,
54,11,113,4,0,
54,11,127,4,0,
54,11,133,1,48,
54,11,148,4,0,
54,11,154,1,27,
54,11,156,4,0,
54,11,164,4,0,
54,11,182,4,0,
54,11,193,2,0,
54,11,207,4,0,
54,11,213,4,0,
54,11,214,2,0,
54,11,216,4,0,
54,11,218,4,0,
54,11,227,2,0,
54,11,237,4,0,
54,11,238,2,0,
54,11,240,4,0,
54,11,244,1,40,
54,11,244,4,0,
54,11,248,2,0,
54,11,249,4,0,
54,11,258,4,0,
54,11,263,4,0,
54,11,280,4,0,
54,11,281,2,0,
54,11,287,2,0,
54,11,290,2,0,
54,11,291,4,0,
54,11,332,4,0,
54,11,346,1,1,1
54,11,347,4,0,
54,11,352,1,22,
54,11,374,4,0,
54,11,421,4,0,
54,11,426,2,0,
54,11,428,1,44,
54,11,468,4,0,
54,11,472,1,57,
54,11,473,4,0,
54,11,477,4,0,
54,11,485,2,0,
54,11,487,1,35,
54,11,496,4,0,
54,11,503,4,0,
54,12,10,1,1,2
54,12,39,1,5,
54,12,50,1,10,
54,12,56,1,50,
54,12,57,4,0,
54,12,58,4,0,
54,12,59,4,0,
54,12,70,4,0,
54,12,91,4,0,
54,12,92,4,0,
54,12,93,1,16,
54,12,103,1,23,
54,12,104,4,0,
54,12,127,4,0,
54,12,148,4,0,
54,12,154,1,40,
54,12,156,4,0,
54,12,182,4,0,
54,12,213,4,0,
54,12,216,4,0,
54,12,218,4,0,
54,12,231,4,0,
54,12,237,4,0,
54,12,240,4,0,
54,12,244,1,31,
54,12,249,4,0,
54,12,258,4,0,
54,12,263,4,0,
54,12,264,4,0,
54,12,280,4,0,
54,12,290,4,0,
54,12,291,4,0,
54,12,332,4,0,
54,12,346,1,1,1
54,12,347,4,0,
54,12,352,4,0,
54,13,10,1,1,2
54,13,34,3,0,
54,13,38,3,0,
54,13,39,1,5,
54,13,50,1,10,
54,13,56,1,50,
54,13,57,4,0,
54,13,58,4,0,
54,13,59,4,0,
54,13,69,3,0,
54,13,70,4,0,
54,13,91,4,0,
54,13,92,4,0,
54,13,93,1,16,
54,13,102,3,0,
54,13,103,1,23,
54,13,104,4,0,
54,13,127,4,0,
54,13,148,4,0,
54,13,154,1,40,
54,13,156,4,0,
54,13,164,3,0,
54,13,182,4,0,
54,13,196,3,0,
54,13,207,3,0,
54,13,213,4,0,
54,13,216,4,0,
54,13,218,4,0,
54,13,231,4,0,
54,13,237,4,0,
54,13,240,4,0,
54,13,244,1,31,
54,13,249,4,0,
54,13,258,4,0,
54,13,263,4,0,
54,13,264,4,0,
54,13,280,4,0,
54,13,290,4,0,
54,13,291,4,0,
54,13,332,4,0,
54,13,346,1,1,1
54,13,347,4,0,
54,13,352,4,0,
54,14,8,3,0,
54,14,10,1,1,2
54,14,39,1,4,
54,14,50,1,11,
54,14,55,1,8,
54,14,56,1,46,
54,14,57,4,0,
54,14,58,4,0,
54,14,59,4,0,
54,14,60,2,0,
54,14,70,4,0,
54,14,91,4,0,
54,14,92,4,0,
54,14,93,1,15,
54,14,94,4,0,
54,14,95,2,0,
54,14,103,1,25,
54,14,104,4,0,
54,14,109,2,0,
54,14,113,4,0,
54,14,127,4,0,
54,14,133,1,43,
54,14,148,4,0,
54,14,154,1,22,
54,14,156,4,0,
54,14,164,4,0,
54,14,173,3,0,
54,14,182,4,0,
54,14,193,2,0,
54,14,196,3,0,
54,14,207,4,0,
54,14,213,4,0,
54,14,214,2,0,
54,14,214,3,0,
54,14,216,4,0,
54,14,218,4,0,
54,14,227,2,0,
54,14,231,3,0,
54,14,237,4,0,
54,14,238,2,0,
54,14,240,4,0,
54,14,244,1,39,
54,14,244,4,0,
54,14,248,2,0,
54,14,249,4,0,
54,14,258,4,0,
54,14,263,4,0,
54,14,272,3,0,
54,14,280,4,0,
54,14,281,2,0,
54,14,287,2,0,
54,14,290,2,0,
54,14,291,4,0,
54,14,324,3,0,
54,14,332,4,0,
54,14,346,1,1,1
54,14,347,4,0,
54,14,352,1,18,
54,14,374,4,0,
54,14,388,3,0,
54,14,401,1,32,
54,14,401,3,0,
54,14,421,4,0,
54,14,426,2,0,
54,14,428,1,29,
54,14,428,3,0,
54,14,468,4,0,
54,14,472,1,50,
54,14,472,3,0,
54,14,473,4,0,
54,14,477,4,0,
54,14,485,2,0,
54,14,487,1,36,
54,14,496,4,0,
54,14,503,4,0,
54,15,10,1,1,2
54,15,39,1,4,
54,15,50,1,11,
54,15,55,1,8,
54,15,56,1,46,
54,15,57,4,0,
54,15,58,4,0,
54,15,59,4,0,
54,15,60,2,0,
54,15,70,4,0,
54,15,91,4,0,
54,15,92,4,0,
54,15,93,1,15,
54,15,94,4,0,
54,15,95,2,0,
54,15,103,1,25,
54,15,104,4,0,
54,15,109,2,0,
54,15,113,4,0,
54,15,127,4,0,
54,15,133,1,43,
54,15,148,4,0,
54,15,154,1,22,
54,15,156,4,0,
54,15,164,4,0,
54,15,182,4,0,
54,15,193,2,0,
54,15,207,4,0,
54,15,213,4,0,
54,15,214,2,0,
54,15,214,4,0,
54,15,216,4,0,
54,15,218,4,0,
54,15,227,2,0,
54,15,237,4,0,
54,15,238,2,0,
54,15,240,4,0,
54,15,244,1,39,
54,15,244,4,0,
54,15,248,2,0,
54,15,249,4,0,
54,15,258,4,0,
54,15,263,4,0,
54,15,280,4,0,
54,15,281,2,0,
54,15,287,2,0,
54,15,290,2,0,
54,15,332,4,0,
54,15,346,1,1,1
54,15,347,4,0,
54,15,352,1,18,
54,15,374,4,0,
54,15,401,1,32,
54,15,421,4,0,
54,15,426,2,0,
54,15,428,1,29,
54,15,468,4,0,
54,15,472,1,50,
54,15,473,4,0,
54,15,485,2,0,
54,15,487,1,36,
54,15,493,2,0,
54,15,496,4,0,
54,15,499,2,0,
54,15,503,4,0,
54,15,590,4,0,
54,15,612,4,0,
54,16,8,3,0,
54,16,10,1,1,2
54,16,39,1,4,1
54,16,50,1,22,1
54,16,55,1,8,1
54,16,56,1,46,1
54,16,57,4,0,
54,16,58,4,0,
54,16,59,4,0,
54,16,60,2,0,
54,16,70,4,0,
54,16,91,4,0,
54,16,92,4,0,
54,16,93,1,11,1
54,16,94,4,0,
54,16,95,2,0,
54,16,103,1,25,1
54,16,104,4,0,
54,16,109,2,0,
54,16,113,4,0,
54,16,127,4,0,
54,16,133,1,43,1
54,16,148,4,0,
54,16,154,1,15,1
54,16,156,4,0,
54,16,164,4,0,
54,16,173,3,0,
54,16,182,4,0,
54,16,193,2,0,
54,16,196,3,0,
54,16,207,4,0,
54,16,213,4,0,
54,16,214,2,0,
54,16,214,4,0,
54,16,216,4,0,
54,16,218,4,0,
54,16,227,2,0,
54,16,231,3,0,
54,16,237,4,0,
54,16,238,2,0,
54,16,240,4,0,
54,16,244,1,39,1
54,16,244,4,0,
54,16,248,2,0,
54,16,249,4,0,
54,16,258,4,0,
54,16,263,4,0,
54,16,264,3,0,
54,16,272,3,0,
54,16,280,4,0,
54,16,281,2,0,
54,16,287,2,0,
54,16,290,2,0,
54,16,290,4,0,
54,16,291,4,0,
54,16,324,3,0,
54,16,332,4,0,
54,16,346,1,1,1
54,16,347,4,0,
54,16,352,1,18,1
54,16,352,3,0,
54,16,374,4,0,
54,16,388,3,0,
54,16,401,1,29,1
54,16,401,3,0,
54,16,421,4,0,
54,16,426,2,0,
54,16,428,1,32,1
54,16,428,3,0,
54,16,468,4,0,
54,16,472,1,50,1
54,16,472,3,0,
54,16,473,4,0,
54,16,485,2,0,
54,16,487,1,36,1
54,16,493,2,0,
54,16,496,4,0,
54,16,499,2,0,
54,16,503,4,0,
54,16,590,4,0,
54,16,612,4,0,
54,17,10,1,1,2
54,17,39,1,4,
54,17,50,1,19,
54,17,55,1,7,
54,17,56,1,40,
54,17,57,4,0,
54,17,58,4,0,
54,17,59,4,0,
54,17,60,2,0,
54,17,92,4,0,
54,17,93,1,10,
54,17,94,4,0,
54,17,95,2,0,
54,17,103,1,22,
54,17,104,4,0,
54,17,109,2,0,
54,17,113,4,0,
54,17,127,4,0,
54,17,133,1,37,
54,17,154,1,13,
54,17,156,4,0,
54,17,164,4,0,
54,17,182,4,0,
54,17,193,2,0,
54,17,207,4,0,
54,17,213,4,0,
54,17,214,2,0,
54,17,214,4,0,
54,17,216,4,0,
54,17,218,4,0,
54,17,227,2,0,
54,17,237,4,0,
54,17,238,2,0,
54,17,240,4,0,
54,17,244,1,34,
54,17,244,4,0,
54,17,248,2,0,
54,17,258,4,0,
54,17,263,4,0,
54,17,280,4,0,
54,17,281,2,0,
54,17,287,2,0,
54,17,290,2,0,
54,17,332,4,0,
54,17,346,1,1,1
54,17,347,4,0,
54,17,352,1,16,
54,17,374,4,0,
54,17,401,1,28,
54,17,421,4,0,
54,17,426,2,0,
54,17,428,1,25,
54,17,472,1,43,
54,17,473,4,0,
54,17,485,2,0,
54,17,487,1,31,
54,17,493,2,0,
54,17,496,4,0,
54,17,499,2,0,
54,17,503,4,0,
54,17,590,4,0,
54,18,10,1,1,2
54,18,39,1,4,
54,18,50,1,19,
54,18,55,1,7,
54,18,56,1,40,
54,18,57,4,0,
54,18,58,4,0,
54,18,59,4,0,
54,18,60,2,0,
54,18,92,4,0,
54,18,93,1,10,
54,18,94,4,0,
54,18,95,2,0,
54,18,103,1,22,
54,18,104,4,0,
54,18,109,2,0,
54,18,113,4,0,
54,18,127,4,0,
54,18,133,1,37,
54,18,154,1,13,
54,18,156,4,0,
54,18,164,4,0,
54,18,182,4,0,
54,18,193,2,0,
54,18,207,4,0,
54,18,213,4,0,
54,18,214,2,0,
54,18,214,4,0,
54,18,216,4,0,
54,18,218,4,0,
54,18,227,2,0,
54,18,237,4,0,
54,18,238,2,0,
54,18,240,4,0,
54,18,244,1,34,
54,18,244,4,0,
54,18,248,2,0,
54,18,258,4,0,
54,18,263,4,0,
54,18,280,4,0,
54,18,281,2,0,
54,18,287,2,0,
54,18,290,2,0,
54,18,332,4,0,
54,18,346,1,1,1
54,18,347,4,0,
54,18,352,1,16,
54,18,374,4,0,
54,18,401,1,28,
54,18,421,4,0,
54,18,426,2,0,
54,18,428,1,25,
54,18,472,1,43,
54,18,473,4,0,
54,18,485,2,0,
54,18,487,1,31,
54,18,493,2,0,
54,18,496,4,0,
54,18,499,2,0,
54,18,503,4,0,
54,18,590,4,0,
55,1,5,4,0,
55,1,6,4,0,
55,1,10,1,1,1
55,1,25,4,0,
55,1,34,4,0,
55,1,36,4,0,
55,1,38,4,0,
55,1,39,1,1,2
55,1,39,1,28,
55,1,50,1,1,3
55,1,50,1,31,
55,1,55,4,0,
55,1,56,1,59,
55,1,57,4,0,
55,1,58,4,0,
55,1,59,4,0,
55,1,61,4,0,
55,1,63,4,0,
55,1,66,4,0,
55,1,68,4,0,
55,1,69,4,0,
55,1,70,4,0,
55,1,91,4,0,
55,1,92,4,0,
55,1,93,1,39,
55,1,99,4,0,
55,1,102,4,0,
55,1,104,4,0,
55,1,117,4,0,
55,1,129,4,0,
55,1,130,4,0,
55,1,154,1,48,
55,1,156,4,0,
55,1,164,4,0,
55,2,5,4,0,
55,2,6,4,0,
55,2,10,1,1,1
55,2,25,4,0,
55,2,34,4,0,
55,2,36,4,0,
55,2,38,4,0,
55,2,39,1,1,2
55,2,39,1,28,
55,2,50,1,1,3
55,2,50,1,31,
55,2,55,4,0,
55,2,56,1,59,
55,2,57,4,0,
55,2,58,4,0,
55,2,59,4,0,
55,2,61,4,0,
55,2,63,4,0,
55,2,66,4,0,
55,2,68,4,0,
55,2,69,4,0,
55,2,70,4,0,
55,2,91,4,0,
55,2,92,4,0,
55,2,93,1,39,
55,2,99,4,0,
55,2,102,4,0,
55,2,104,4,0,
55,2,117,4,0,
55,2,129,4,0,
55,2,130,4,0,
55,2,154,1,48,
55,2,156,4,0,
55,2,164,4,0,
55,3,8,4,0,
55,3,10,1,1,1
55,3,29,4,0,
55,3,39,1,1,2
55,3,39,1,5,
55,3,50,1,1,3
55,3,50,1,10,
55,3,56,1,58,
55,3,57,4,0,
55,3,59,4,0,
55,3,63,4,0,
55,3,70,4,0,
55,3,91,4,0,
55,3,92,4,0,
55,3,93,1,1,4
55,3,93,1,16,
55,3,103,1,23,
55,3,104,4,0,
55,3,127,4,0,
55,3,129,4,0,
55,3,148,4,0,
55,3,154,1,44,
55,3,156,4,0,
55,3,173,4,0,
55,3,174,4,0,
55,3,182,4,0,
55,3,189,4,0,
55,3,196,4,0,
55,3,203,4,0,
55,3,207,4,0,
55,3,210,4,0,
55,3,213,4,0,
55,3,214,4,0,
55,3,216,4,0,
55,3,218,4,0,
55,3,223,4,0,
55,3,231,4,0,
55,3,237,4,0,
55,3,240,4,0,
55,3,244,1,31,
55,3,244,4,0,
55,3,249,4,0,
55,3,250,4,0,
55,4,8,4,0,
55,4,10,1,1,1
55,4,29,4,0,
55,4,39,1,1,2
55,4,39,1,5,
55,4,50,1,1,3
55,4,50,1,10,
55,4,56,1,58,
55,4,57,4,0,
55,4,58,3,0,
55,4,59,4,0,
55,4,63,4,0,
55,4,70,4,0,
55,4,91,4,0,
55,4,92,4,0,
55,4,93,1,1,4
55,4,93,1,16,
55,4,103,1,23,
55,4,104,4,0,
55,4,127,4,0,
55,4,129,4,0,
55,4,148,4,0,
55,4,154,1,44,
55,4,156,4,0,
55,4,173,4,0,
55,4,174,4,0,
55,4,182,4,0,
55,4,189,4,0,
55,4,196,4,0,
55,4,203,4,0,
55,4,207,4,0,
55,4,210,4,0,
55,4,213,4,0,
55,4,214,4,0,
55,4,216,4,0,
55,4,218,4,0,
55,4,223,4,0,
55,4,231,4,0,
55,4,237,4,0,
55,4,240,4,0,
55,4,244,1,31,
55,4,244,4,0,
55,4,249,4,0,
55,4,250,4,0,
55,5,10,1,1,2
55,5,39,1,1,3
55,5,39,1,5,
55,5,50,1,1,4
55,5,50,1,10,
55,5,56,1,58,
55,5,57,4,0,
55,5,58,4,0,
55,5,59,4,0,
55,5,63,4,0,
55,5,70,4,0,
55,5,91,4,0,
55,5,92,4,0,
55,5,93,1,16,
55,5,103,1,23,
55,5,104,4,0,
55,5,127,4,0,
55,5,148,4,0,
55,5,154,1,44,
55,5,156,4,0,
55,5,182,4,0,
55,5,213,4,0,
55,5,216,4,0,
55,5,218,4,0,
55,5,231,4,0,
55,5,237,4,0,
55,5,240,4,0,
55,5,244,1,31,
55,5,249,4,0,
55,5,258,4,0,
55,5,263,4,0,
55,5,264,4,0,
55,5,280,4,0,
55,5,290,4,0,
55,5,291,4,0,
55,5,332,4,0,
55,5,346,1,1,1
55,5,347,4,0,
55,5,352,4,0,
55,6,5,3,0,
55,6,8,3,0,
55,6,10,1,1,2
55,6,25,3,0,
55,6,34,3,0,
55,6,38,3,0,
55,6,39,1,1,3
55,6,39,1,5,
55,6,50,1,1,4
55,6,50,1,10,
55,6,56,1,58,
55,6,57,4,0,
55,6,58,4,0,
55,6,59,4,0,
55,6,63,4,0,
55,6,68,3,0,
55,6,69,3,0,
55,6,70,4,0,
55,6,91,4,0,
55,6,92,4,0,
55,6,93,1,16,
55,6,102,3,0,
55,6,103,1,23,
55,6,104,4,0,
55,6,127,4,0,
55,6,129,3,0,
55,6,148,4,0,
55,6,154,1,44,
55,6,156,4,0,
55,6,164,3,0,
55,6,173,3,0,
55,6,182,4,0,
55,6,189,3,0,
55,6,196,3,0,
55,6,203,3,0,
55,6,207,3,0,
55,6,210,3,0,
55,6,213,4,0,
55,6,214,3,0,
55,6,216,4,0,
55,6,218,4,0,
55,6,223,3,0,
55,6,231,4,0,
55,6,237,4,0,
55,6,240,4,0,
55,6,244,1,31,
55,6,244,3,0,
55,6,249,4,0,
55,6,258,4,0,
55,6,263,4,0,
55,6,264,4,0,
55,6,280,4,0,
55,6,290,4,0,
55,6,291,4,0,
55,6,332,4,0,
55,6,346,1,1,1
55,6,347,4,0,
55,6,352,4,0,
55,7,5,3,0,
55,7,10,1,1,2
55,7,25,3,0,
55,7,34,3,0,
55,7,38,3,0,
55,7,39,1,1,3
55,7,39,1,5,
55,7,50,1,1,4
55,7,50,1,10,
55,7,56,1,58,
55,7,57,4,0,
55,7,58,4,0,
55,7,59,4,0,
55,7,63,4,0,
55,7,68,3,0,
55,7,69,3,0,
55,7,70,4,0,
55,7,91,4,0,
55,7,92,4,0,
55,7,93,1,16,
55,7,102,3,0,
55,7,103,1,23,
55,7,104,4,0,
55,7,127,4,0,
55,7,148,4,0,
55,7,154,1,44,
55,7,156,4,0,
55,7,164,3,0,
55,7,182,4,0,
55,7,213,4,0,
55,7,216,4,0,
55,7,218,4,0,
55,7,231,4,0,
55,7,237,4,0,
55,7,240,4,0,
55,7,244,1,31,
55,7,249,4,0,
55,7,258,4,0,
55,7,263,4,0,
55,7,264,4,0,
55,7,280,4,0,
55,7,290,4,0,
55,7,291,4,0,
55,7,332,4,0,
55,7,346,1,1,1
55,7,347,4,0,
55,7,352,4,0,
55,8,10,1,1,2
55,8,39,1,1,3
55,8,39,1,5,
55,8,50,1,14,
55,8,55,1,1,4
55,8,55,1,9,
55,8,56,1,56,
55,8,57,4,0,
55,8,58,4,0,
55,8,59,4,0,
55,8,63,4,0,
55,8,70,4,0,
55,8,91,4,0,
55,8,92,4,0,
55,8,93,1,18,
55,8,94,4,0,
55,8,103,1,31,
55,8,104,4,0,
55,8,127,4,0,
55,8,133,1,50,
55,8,148,4,0,
55,8,154,1,27,
55,8,156,4,0,
55,8,164,4,0,
55,8,182,4,0,
55,8,203,4,0,
55,8,207,4,0,
55,8,213,4,0,
55,8,214,4,0,
55,8,216,4,0,
55,8,218,4,0,
55,8,231,4,0,
55,8,237,4,0,
55,8,240,4,0,
55,8,244,1,37,
55,8,244,4,0,
55,8,249,4,0,
55,8,258,4,0,
55,8,263,4,0,
55,8,264,4,0,
55,8,280,4,0,
55,8,290,4,0,
55,8,332,4,0,
55,8,346,1,1,1
55,8,347,4,0,
55,8,352,1,22,
55,8,352,4,0,
55,8,362,4,0,
55,8,363,4,0,
55,8,374,4,0,
55,8,411,4,0,
55,8,416,4,0,
55,8,421,4,0,
55,8,428,1,44,
55,8,431,4,0,
55,8,445,4,0,
55,9,8,3,0,
55,9,10,1,1,3
55,9,39,1,1,4
55,9,39,1,5,
55,9,50,1,14,
55,9,55,1,1,5
55,9,55,1,9,
55,9,56,1,56,
55,9,57,4,0,
55,9,58,4,0,
55,9,59,4,0,
55,9,63,4,0,
55,9,70,4,0,
55,9,91,4,0,
55,9,92,4,0,
55,9,93,1,18,
55,9,94,4,0,
55,9,103,1,31,
55,9,104,4,0,
55,9,127,4,0,
55,9,129,3,0,
55,9,133,1,50,
55,9,148,4,0,
55,9,154,1,27,
55,9,156,4,0,
55,9,164,4,0,
55,9,173,3,0,
55,9,182,4,0,
55,9,189,3,0,
55,9,196,3,0,
55,9,203,4,0,
55,9,207,4,0,
55,9,210,3,0,
55,9,213,4,0,
55,9,214,4,0,
55,9,216,4,0,
55,9,218,4,0,
55,9,231,4,0,
55,9,237,4,0,
55,9,240,4,0,
55,9,244,1,37,
55,9,244,4,0,
55,9,249,4,0,
55,9,258,4,0,
55,9,263,4,0,
55,9,264,4,0,
55,9,280,4,0,
55,9,290,4,0,
55,9,291,3,0,
55,9,324,3,0,
55,9,332,4,0,
55,9,346,1,1,2
55,9,347,4,0,
55,9,352,1,22,
55,9,352,4,0,
55,9,362,4,0,
55,9,363,4,0,
55,9,374,4,0,
55,9,401,3,0,
55,9,411,4,0,
55,9,416,4,0,
55,9,421,4,0,
55,9,428,1,44,
55,9,428,3,0,
55,9,431,4,0,
55,9,445,4,0,
55,9,453,1,1,1
55,10,8,3,0,
55,10,10,1,1,3
55,10,29,3,0,
55,10,39,1,1,4
55,10,39,1,5,
55,10,50,1,14,
55,10,55,1,1,5
55,10,55,1,9,
55,10,56,1,56,
55,10,57,4,0,
55,10,58,4,0,
55,10,59,4,0,
55,10,63,4,0,
55,10,67,3,0,
55,10,70,4,0,
55,10,91,4,0,
55,10,92,4,0,
55,10,93,1,18,
55,10,94,4,0,
55,10,103,1,31,
55,10,104,4,0,
55,10,127,4,0,
55,10,129,3,0,
55,10,133,1,50,
55,10,148,4,0,
55,10,154,1,27,
55,10,156,4,0,
55,10,164,4,0,
55,10,173,3,0,
55,10,182,4,0,
55,10,189,3,0,
55,10,196,3,0,
55,10,203,4,0,
55,10,207,4,0,
55,10,210,3,0,
55,10,213,4,0,
55,10,214,4,0,
55,10,216,4,0,
55,10,218,4,0,
55,10,231,4,0,
55,10,237,4,0,
55,10,240,4,0,
55,10,244,1,37,
55,10,244,4,0,
55,10,249,4,0,
55,10,250,4,0,
55,10,258,4,0,
55,10,263,4,0,
55,10,264,4,0,
55,10,272,3,0,
55,10,280,4,0,
55,10,290,4,0,
55,10,291,3,0,
55,10,324,3,0,
55,10,332,4,0,
55,10,346,1,1,2
55,10,347,4,0,
55,10,352,1,22,
55,10,352,4,0,
55,10,362,4,0,
55,10,363,4,0,
55,10,374,4,0,
55,10,388,3,0,
55,10,401,3,0,
55,10,411,4,0,
55,10,416,4,0,
55,10,421,4,0,
55,10,428,1,44,
55,10,428,3,0,
55,10,431,4,0,
55,10,445,4,0,
55,10,453,1,1,1
55,11,10,1,1,3
55,11,39,1,1,4
55,11,39,1,5,
55,11,50,1,14,
55,11,55,1,1,5
55,11,55,1,9,
55,11,56,1,63,
55,11,57,4,0,
55,11,58,4,0,
55,11,59,4,0,
55,11,63,4,0,
55,11,70,4,0,
55,11,91,4,0,
55,11,92,4,0,
55,11,93,1,18,
55,11,94,4,0,
55,11,103,1,31,
55,11,104,4,0,
55,11,113,4,0,
55,11,127,4,0,
55,11,133,1,56,
55,11,148,4,0,
55,11,154,1,27,
55,11,156,4,0,
55,11,164,4,0,
55,11,182,4,0,
55,11,207,4,0,
55,11,213,4,0,
55,11,216,4,0,
55,11,218,4,0,
55,11,237,4,0,
55,11,240,4,0,
55,11,244,1,44,
55,11,244,4,0,
55,11,249,4,0,
55,11,258,4,0,
55,11,263,4,0,
55,11,280,4,0,
55,11,291,4,0,
55,11,332,4,0,
55,11,346,1,1,2
55,11,347,4,0,
55,11,352,1,22,
55,11,374,4,0,
55,11,411,4,0,
55,11,416,4,0,
55,11,421,4,0,
55,11,428,1,50,
55,11,453,1,1,1
55,11,468,4,0,
55,11,472,1,69,
55,11,473,4,0,
55,11,477,4,0,
55,11,487,1,37,
55,11,490,4,0,
55,11,496,4,0,
55,11,503,4,0,
55,12,10,1,1,2
55,12,39,1,1,3
55,12,39,1,5,
55,12,50,1,1,4
55,12,50,1,10,
55,12,56,1,58,
55,12,57,4,0,
55,12,58,4,0,
55,12,59,4,0,
55,12,63,4,0,
55,12,70,4,0,
55,12,91,4,0,
55,12,92,4,0,
55,12,93,1,16,
55,12,103,1,23,
55,12,104,4,0,
55,12,127,4,0,
55,12,148,4,0,
55,12,154,1,44,
55,12,156,4,0,
55,12,182,4,0,
55,12,213,4,0,
55,12,216,4,0,
55,12,218,4,0,
55,12,231,4,0,
55,12,237,4,0,
55,12,240,4,0,
55,12,244,1,31,
55,12,249,4,0,
55,12,258,4,0,
55,12,263,4,0,
55,12,264,4,0,
55,12,280,4,0,
55,12,290,4,0,
55,12,291,4,0,
55,12,332,4,0,
55,12,346,1,1,1
55,12,347,4,0,
55,12,352,4,0,
55,13,10,1,1,2
55,13,34,3,0,
55,13,38,3,0,
55,13,39,1,1,3
55,13,39,1,5,
55,13,50,1,1,4
55,13,50,1,10,
55,13,56,1,58,
55,13,57,4,0,
55,13,58,4,0,
55,13,59,4,0,
55,13,63,4,0,
55,13,69,3,0,
55,13,70,4,0,
55,13,91,4,0,
55,13,92,4,0,
55,13,93,1,16,
55,13,102,3,0,
55,13,103,1,23,
55,13,104,4,0,
55,13,127,4,0,
55,13,148,4,0,
55,13,154,1,44,
55,13,156,4,0,
55,13,164,3,0,
55,13,182,4,0,
55,13,196,3,0,
55,13,207,3,0,
55,13,213,4,0,
55,13,216,4,0,
55,13,218,4,0,
55,13,231,4,0,
55,13,237,4,0,
55,13,240,4,0,
55,13,244,1,31,
55,13,249,4,0,
55,13,258,4,0,
55,13,263,4,0,
55,13,264,4,0,
55,13,280,4,0,
55,13,290,4,0,
55,13,291,4,0,
55,13,332,4,0,
55,13,346,1,1,1
55,13,347,4,0,
55,13,352,4,0,
55,14,8,3,0,
55,14,10,1,1,3
55,14,39,1,1,4
55,14,39,1,4,
55,14,50,1,11,
55,14,55,1,1,5
55,14,55,1,8,
55,14,56,1,54,
55,14,57,4,0,
55,14,58,4,0,
55,14,59,4,0,
55,14,63,4,0,
55,14,67,3,0,
55,14,70,4,0,
55,14,91,4,0,
55,14,92,4,0,
55,14,93,1,15,
55,14,94,4,0,
55,14,103,1,25,
55,14,104,4,0,
55,14,113,4,0,
55,14,127,4,0,
55,14,133,1,49,
55,14,148,4,0,
55,14,154,1,22,
55,14,156,4,0,
55,14,164,4,0,
55,14,173,3,0,
55,14,182,4,0,
55,14,196,3,0,
55,14,207,4,0,
55,14,213,4,0,
55,14,214,3,0,
55,14,216,4,0,
55,14,218,4,0,
55,14,231,3,0,
55,14,237,4,0,
55,14,240,4,0,
55,14,244,1,43,
55,14,244,4,0,
55,14,249,4,0,
55,14,258,4,0,
55,14,263,4,0,
55,14,272,3,0,
55,14,280,4,0,
55,14,291,4,0,
55,14,324,3,0,
55,14,332,4,0,
55,14,346,1,1,2
55,14,347,4,0,
55,14,352,1,18,
55,14,374,4,0,
55,14,388,3,0,
55,14,401,1,32,
55,14,401,3,0,
55,14,411,4,0,
55,14,416,4,0,
55,14,421,4,0,
55,14,428,1,29,
55,14,428,3,0,
55,14,453,1,1,1
55,14,468,4,0,
55,14,472,1,60,
55,14,472,3,0,
55,14,473,4,0,
55,14,477,4,0,
55,14,487,1,38,
55,14,490,4,0,
55,14,496,4,0,
55,14,503,4,0,
55,15,10,1,1,3
55,15,39,1,1,4
55,15,39,1,4,
55,15,50,1,11,
55,15,55,1,1,5
55,15,55,1,8,
55,15,56,1,54,
55,15,57,4,0,
55,15,58,4,0,
55,15,59,4,0,
55,15,63,4,0,
55,15,70,4,0,
55,15,91,4,0,
55,15,92,4,0,
55,15,93,1,15,
55,15,94,4,0,
55,15,103,1,25,
55,15,104,4,0,
55,15,113,4,0,
55,15,127,4,0,
55,15,133,1,49,
55,15,148,4,0,
55,15,154,1,22,
55,15,156,4,0,
55,15,164,4,0,
55,15,182,4,0,
55,15,207,4,0,
55,15,213,4,0,
55,15,214,4,0,
55,15,216,4,0,
55,15,218,4,0,
55,15,237,4,0,
55,15,240,4,0,
55,15,244,1,43,
55,15,244,4,0,
55,15,249,4,0,
55,15,258,4,0,
55,15,263,4,0,
55,15,280,4,0,
55,15,332,4,0,
55,15,346,1,1,2
55,15,347,4,0,
55,15,352,1,18,
55,15,374,4,0,
55,15,401,1,32,
55,15,411,4,0,
55,15,416,4,0,
55,15,421,4,0,
55,15,428,1,29,
55,15,453,1,1,1
55,15,468,4,0,
55,15,472,1,60,
55,15,473,4,0,
55,15,487,1,38,
55,15,490,4,0,
55,15,496,4,0,
55,15,503,4,0,
55,15,590,4,0,
55,15,612,4,0,
55,16,8,3,0,
55,16,10,1,1,3
55,16,39,1,1,4
55,16,39,1,4,1
55,16,50,1,22,1
55,16,55,1,1,5
55,16,55,1,8,1
55,16,56,1,54,1
55,16,57,4,0,
55,16,58,4,0,
55,16,59,4,0,
55,16,63,4,0,
55,16,67,3,0,
55,16,70,4,0,
55,16,91,4,0,
55,16,92,4,0,
55,16,93,1,11,1
55,16,94,4,0,
55,16,103,1,29,1
55,16,104,4,0,
55,16,113,4,0,
55,16,127,4,0,
55,16,133,1,49,1
55,16,148,4,0,
55,16,154,1,15,1
55,16,156,4,0,
55,16,164,4,0,
55,16,173,3,0,
55,16,182,4,0,
55,16,196,3,0,
55,16,207,4,0,
55,16,213,4,0,
55,16,214,4,0,
55,16,216,4,0,
55,16,218,4,0,
55,16,231,3,0,
55,16,237,4,0,
55,16,240,4,0,
55,16,244,1,43,1
55,16,244,4,0,
55,16,249,4,0,
55,16,258,4,0,
55,16,263,4,0,
55,16,264,3,0,
55,16,272,3,0,
55,16,280,4,0,
55,16,290,4,0,
55,16,291,4,0,
55,16,324,3,0,
55,16,332,4,0,
55,16,346,1,1,2
55,16,347,4,0,
55,16,352,1,18,1
55,16,352,3,0,
55,16,374,4,0,
55,16,388,3,0,
55,16,401,1,32,1
55,16,401,3,0,
55,16,411,4,0,
55,16,416,4,0,
55,16,421,4,0,
55,16,428,1,25,1
55,16,428,3,0,
55,16,453,1,1,1
55,16,468,4,0,
55,16,472,1,60,1
55,16,472,3,0,
55,16,473,4,0,
55,16,487,1,38,1
55,16,490,4,0,
55,16,496,4,0,
55,16,503,4,0,
55,16,590,4,0,
55,16,612,4,0,
55,17,10,1,1,4
55,17,39,1,1,5
55,17,39,1,4,
55,17,50,1,19,
55,17,55,1,1,6
55,17,55,1,7,
55,17,56,1,46,
55,17,57,4,0,
55,17,58,4,0,
55,17,59,4,0,
55,17,63,4,0,
55,17,92,4,0,
55,17,93,1,10,
55,17,94,4,0,
55,17,103,1,22,
55,17,104,4,0,
55,17,113,4,0,
55,17,127,4,0,
55,17,133,1,41,
55,17,154,1,13,
55,17,156,4,0,
55,17,164,4,0,
55,17,182,4,0,
55,17,207,4,0,
55,17,213,4,0,
55,17,214,4,0,
55,17,216,4,0,
55,17,218,4,0,
55,17,237,4,0,
55,17,240,4,0,
55,17,244,1,36,
55,17,244,4,0,
55,17,258,4,0,
55,17,263,4,0,
55,17,280,4,0,
55,17,332,4,0,
55,17,346,1,1,3
55,17,347,4,0,
55,17,352,1,16,
55,17,374,4,0,
55,17,382,1,1,1
55,17,401,1,28,
55,17,411,4,0,
55,17,416,4,0,
55,17,421,4,0,
55,17,428,1,25,
55,17,453,1,1,2
55,17,472,1,51,
55,17,473,4,0,
55,17,487,1,31,
55,17,490,4,0,
55,17,496,4,0,
55,17,503,4,0,
55,17,590,4,0,
55,18,10,1,1,4
55,18,39,1,1,5
55,18,39,1,4,
55,18,50,1,19,
55,18,55,1,1,6
55,18,55,1,7,
55,18,56,1,46,
55,18,57,4,0,
55,18,58,4,0,
55,18,59,4,0,
55,18,63,4,0,
55,18,92,4,0,
55,18,93,1,10,
55,18,94,4,0,
55,18,103,1,22,
55,18,104,4,0,
55,18,113,4,0,
55,18,127,4,0,
55,18,133,1,41,
55,18,154,1,13,
55,18,156,4,0,
55,18,164,4,0,
55,18,182,4,0,
55,18,207,4,0,
55,18,213,4,0,
55,18,214,4,0,
55,18,216,4,0,
55,18,218,4,0,
55,18,237,4,0,
55,18,240,4,0,
55,18,244,1,36,
55,18,244,4,0,
55,18,258,4,0,
55,18,263,4,0,
55,18,280,4,0,
55,18,332,4,0,
55,18,346,1,1,3
55,18,347,4,0,
55,18,352,1,16,
55,18,374,4,0,
55,18,382,1,1,1
55,18,401,1,28,
55,18,411,4,0,
55,18,416,4,0,
55,18,421,4,0,
55,18,428,1,25,
55,18,453,1,1,2
55,18,472,1,51,
55,18,473,4,0,
55,18,487,1,31,
55,18,490,4,0,
55,18,496,4,0,
55,18,503,4,0,
55,18,590,4,0,
56,1,2,1,15,
56,1,5,4,0,
56,1,6,4,0,
56,1,10,1,1,1
56,1,25,4,0,
56,1,34,4,0,
56,1,36,4,0,
56,1,37,1,39,
56,1,38,4,0,
56,1,43,1,1,2
56,1,66,4,0,
56,1,68,4,0,
56,1,69,1,33,
56,1,69,4,0,
56,1,70,4,0,
56,1,85,4,0,
56,1,87,4,0,
56,1,91,4,0,
56,1,92,4,0,
56,1,99,4,0,
56,1,102,4,0,
56,1,104,4,0,
56,1,116,1,27,
56,1,117,4,0,
56,1,118,4,0,
56,1,129,4,0,
56,1,130,4,0,
56,1,154,1,21,
56,1,156,4,0,
56,1,157,4,0,
56,1,164,4,0,
56,2,2,1,15,
56,2,5,4,0,
56,2,6,4,0,
56,2,10,1,1,1
56,2,25,4,0,
56,2,34,4,0,
56,2,36,4,0,
56,2,37,1,39,
56,2,38,4,0,
56,2,43,1,1,2
56,2,66,4,0,
56,2,67,1,9,
56,2,68,4,0,
56,2,69,1,33,
56,2,69,4,0,
56,2,70,4,0,
56,2,85,4,0,
56,2,87,4,0,
56,2,91,4,0,
56,2,92,4,0,
56,2,99,4,0,
56,2,102,4,0,
56,2,103,1,45,
56,2,104,4,0,
56,2,116,1,27,
56,2,117,4,0,
56,2,118,4,0,
56,2,129,4,0,
56,2,130,4,0,
56,2,154,1,21,
56,2,156,4,0,
56,2,157,4,0,
56,2,164,4,0,
56,3,2,1,15,
56,3,7,4,0,
56,3,8,4,0,
56,3,9,4,0,
56,3,10,1,1,1
56,3,29,4,0,
56,3,37,1,51,
56,3,43,1,1,2
56,3,67,1,9,
56,3,68,2,0,
56,3,69,1,33,
56,3,70,4,0,
56,3,87,4,0,
56,3,91,4,0,
56,3,92,4,0,
56,3,96,2,0,
56,3,103,1,45,
56,3,104,4,0,
56,3,111,4,0,
56,3,116,1,27,
56,3,129,4,0,
56,3,154,1,21,
56,3,156,4,0,
56,3,157,2,0,
56,3,168,4,0,
56,3,173,4,0,
56,3,174,4,0,
56,3,179,2,0,
56,3,182,4,0,
56,3,189,4,0,
56,3,193,2,0,
56,3,197,4,0,
56,3,203,4,0,
56,3,207,4,0,
56,3,213,4,0,
56,3,214,4,0,
56,3,216,4,0,
56,3,218,4,0,
56,3,223,4,0,
56,3,231,4,0,
56,3,237,4,0,
56,3,238,1,39,
56,3,241,4,0,
56,3,244,4,0,
56,3,249,4,0,
56,3,251,2,0,
56,4,2,1,15,
56,4,7,4,0,
56,4,8,4,0,
56,4,9,4,0,
56,4,10,1,1,1
56,4,29,4,0,
56,4,37,1,51,
56,4,43,1,1,2
56,4,67,1,9,
56,4,68,2,0,
56,4,69,1,33,
56,4,70,4,0,
56,4,85,3,0,
56,4,87,4,0,
56,4,91,4,0,
56,4,92,4,0,
56,4,96,2,0,
56,4,103,1,45,
56,4,104,4,0,
56,4,111,4,0,
56,4,116,1,27,
56,4,129,4,0,
56,4,154,1,21,
56,4,156,4,0,
56,4,157,2,0,
56,4,168,4,0,
56,4,173,4,0,
56,4,174,4,0,
56,4,179,2,0,
56,4,182,4,0,
56,4,189,4,0,
56,4,193,2,0,
56,4,197,4,0,
56,4,203,4,0,
56,4,207,4,0,
56,4,213,4,0,
56,4,214,4,0,
56,4,216,4,0,
56,4,218,4,0,
56,4,223,4,0,
56,4,231,4,0,
56,4,237,4,0,
56,4,238,1,39,
56,4,241,4,0,
56,4,244,4,0,
56,4,249,4,0,
56,4,251,2,0,
56,5,2,1,15,
56,5,10,1,1,1
56,5,37,1,51,
56,5,43,1,1,2
56,5,67,1,9,
56,5,68,2,0,
56,5,69,1,33,
56,5,70,4,0,
56,5,85,4,0,
56,5,87,4,0,
56,5,89,4,0,
56,5,91,4,0,
56,5,92,4,0,
56,5,96,2,0,
56,5,103,1,45,
56,5,104,4,0,
56,5,116,1,27,
56,5,154,1,21,
56,5,156,4,0,
56,5,157,2,0,
56,5,168,4,0,
56,5,179,2,0,
56,5,182,4,0,
56,5,193,2,0,
56,5,213,4,0,
56,5,216,4,0,
56,5,218,4,0,
56,5,231,4,0,
56,5,237,4,0,
56,5,238,1,39,
56,5,240,4,0,
56,5,241,4,0,
56,5,249,4,0,
56,5,251,2,0,
56,5,263,4,0,
56,5,264,4,0,
56,5,265,2,0,
56,5,269,4,0,
56,5,279,2,0,
56,5,280,4,0,
56,5,290,4,0,
56,5,315,4,0,
56,5,317,4,0,
56,5,332,4,0,
56,5,339,4,0,
56,6,2,1,15,
56,6,5,3,0,
56,6,7,3,0,
56,6,8,3,0,
56,6,9,3,0,
56,6,10,1,1,1
56,6,25,3,0,
56,6,34,3,0,
56,6,37,1,51,
56,6,38,3,0,
56,6,43,1,1,2
56,6,67,1,9,
56,6,68,2,0,
56,6,68,3,0,
56,6,69,1,33,
56,6,69,3,0,
56,6,70,4,0,
56,6,85,4,0,
56,6,87,4,0,
56,6,89,4,0,
56,6,91,4,0,
56,6,92,4,0,
56,6,96,2,0,
56,6,102,3,0,
56,6,103,1,45,
56,6,104,4,0,
56,6,111,3,0,
56,6,116,1,27,
56,6,118,3,0,
56,6,129,3,0,
56,6,154,1,21,
56,6,156,4,0,
56,6,157,2,0,
56,6,157,3,0,
56,6,164,3,0,
56,6,168,4,0,
56,6,173,3,0,
56,6,179,2,0,
56,6,182,4,0,
56,6,189,3,0,
56,6,193,2,0,
56,6,203,3,0,
56,6,207,3,0,
56,6,213,4,0,
56,6,214,3,0,
56,6,216,4,0,
56,6,218,4,0,
56,6,223,3,0,
56,6,231,4,0,
56,6,237,4,0,
56,6,238,1,39,
56,6,240,4,0,
56,6,241,4,0,
56,6,244,3,0,
56,6,249,4,0,
56,6,251,2,0,
56,6,263,4,0,
56,6,264,4,0,
56,6,265,2,0,
56,6,269,4,0,
56,6,279,2,0,
56,6,280,4,0,
56,6,290,4,0,
56,6,315,4,0,
56,6,317,4,0,
56,6,332,4,0,
56,6,339,4,0,
56,7,2,1,11,
56,7,5,3,0,
56,7,10,1,1,1
56,7,25,3,0,
56,7,34,3,0,
56,7,37,1,46,
56,7,38,3,0,
56,7,43,1,1,2
56,7,67,1,6,
56,7,68,2,0,
56,7,68,3,0,
56,7,69,1,26,
56,7,69,3,0,
56,7,70,4,0,
56,7,85,4,0,
56,7,87,4,0,
56,7,89,4,0,
56,7,91,4,0,
56,7,92,4,0,
56,7,96,2,0,
56,7,102,3,0,
56,7,103,1,41,
56,7,104,4,0,
56,7,116,1,21,
56,7,118,3,0,
56,7,154,1,16,
56,7,156,4,0,
56,7,157,2,0,
56,7,157,3,0,
56,7,164,3,0,
56,7,168,4,0,
56,7,179,2,0,
56,7,182,4,0,
56,7,193,2,0,
56,7,207,1,36,
56,7,213,4,0,
56,7,216,4,0,
56,7,218,4,0,
56,7,231,4,0,
56,7,237,4,0,
56,7,238,1,31,
56,7,240,4,0,
56,7,241,4,0,
56,7,249,4,0,
56,7,251,2,0,
56,7,263,4,0,
56,7,264,4,0,
56,7,265,2,0,
56,7,269,4,0,
56,7,279,2,0,
56,7,280,4,0,
56,7,290,4,0,
56,7,315,4,0,
56,7,317,4,0,
56,7,332,4,0,
56,7,339,4,0,
56,8,2,1,13,
56,8,10,1,1,2
56,8,37,1,41,
56,8,43,1,1,4
56,8,67,1,1,3
56,8,68,2,0,
56,8,69,1,17,
56,8,70,4,0,
56,8,85,4,0,
56,8,87,4,0,
56,8,89,4,0,
56,8,91,4,0,
56,8,92,4,0,
56,8,96,2,0,
56,8,103,1,21,
56,8,104,4,0,
56,8,116,1,1,5
56,8,154,1,9,
56,8,156,4,0,
56,8,157,2,0,
56,8,157,4,0,
56,8,164,4,0,
56,8,168,4,0,
56,8,179,2,0,
56,8,182,4,0,
56,8,193,2,0,
56,8,203,4,0,
56,8,207,1,33,
56,8,207,4,0,
56,8,213,4,0,
56,8,214,4,0,
56,8,216,4,0,
56,8,218,4,0,
56,8,231,4,0,
56,8,237,4,0,
56,8,238,1,37,
56,8,240,4,0,
56,8,241,4,0,
56,8,249,4,0,
56,8,251,2,0,
56,8,263,4,0,
56,8,264,4,0,
56,8,265,2,0,
56,8,269,4,0,
56,8,279,2,0,
56,8,280,4,0,
56,8,290,4,0,
56,8,315,4,0,
56,8,317,4,0,
56,8,332,4,0,
56,8,339,4,0,
56,8,343,1,1,1
56,8,363,4,0,
56,8,369,4,0,
56,8,370,1,49,
56,8,370,2,0,
56,8,371,4,0,
56,8,372,1,25,
56,8,374,4,0,
56,8,386,1,45,
56,8,398,4,0,
56,8,411,4,0,
56,8,431,4,0,
56,8,445,4,0,
56,9,2,1,13,
56,9,7,3,0,
56,9,8,3,0,
56,9,9,3,0,
56,9,10,1,1,2
56,9,37,1,41,
56,9,43,1,1,4
56,9,67,1,1,3
56,9,68,2,0,
56,9,69,1,17,
56,9,70,4,0,
56,9,85,4,0,
56,9,87,4,0,
56,9,89,4,0,
56,9,91,4,0,
56,9,92,4,0,
56,9,96,2,0,
56,9,103,1,21,
56,9,104,4,0,
56,9,116,1,1,5
56,9,129,3,0,
56,9,154,1,9,
56,9,156,4,0,
56,9,157,2,0,
56,9,157,4,0,
56,9,164,4,0,
56,9,168,4,0,
56,9,179,2,0,
56,9,180,3,0,
56,9,182,4,0,
56,9,189,3,0,
56,9,193,2,0,
56,9,200,3,0,
56,9,203,4,0,
56,9,207,1,33,
56,9,207,4,0,
56,9,213,4,0,
56,9,214,4,0,
56,9,216,4,0,
56,9,218,4,0,
56,9,231,4,0,
56,9,237,4,0,
56,9,238,1,37,
56,9,240,4,0,
56,9,241,4,0,
56,9,249,4,0,
56,9,251,2,0,
56,9,253,3,0,
56,9,263,4,0,
56,9,264,4,0,
56,9,265,2,0,
56,9,269,4,0,
56,9,270,3,0,
56,9,279,2,0,
56,9,280,4,0,
56,9,283,3,0,
56,9,290,4,0,
56,9,315,4,0,
56,9,317,4,0,
56,9,332,4,0,
56,9,339,4,0,
56,9,343,1,1,1
56,9,363,4,0,
56,9,369,4,0,
56,9,370,1,49,
56,9,370,2,0,
56,9,371,4,0,
56,9,372,1,25,
56,9,374,4,0,
56,9,386,1,45,
56,9,398,4,0,
56,9,402,3,0,
56,9,410,3,0,
56,9,411,4,0,
56,9,431,4,0,
56,9,441,3,0,
56,9,445,4,0,
56,10,2,1,13,
56,10,7,3,0,
56,10,8,3,0,
56,10,9,3,0,
56,10,10,1,1,2
56,10,29,3,0,
56,10,37,1,41,
56,10,43,1,1,4
56,10,67,1,1,3
56,10,67,3,0,
56,10,68,2,0,
56,10,69,1,17,
56,10,70,4,0,
56,10,85,4,0,
56,10,87,4,0,
56,10,89,4,0,
56,10,91,4,0,
56,10,92,4,0,
56,10,96,2,0,
56,10,103,1,21,
56,10,104,4,0,
56,10,116,1,1,5
56,10,129,3,0,
56,10,154,1,9,
56,10,156,4,0,
56,10,157,2,0,
56,10,157,4,0,
56,10,164,4,0,
56,10,168,4,0,
56,10,179,2,0,
56,10,180,3,0,
56,10,182,4,0,
56,10,189,3,0,
56,10,193,2,0,
56,10,200,3,0,
56,10,203,4,0,
56,10,207,1,33,
56,10,207,4,0,
56,10,213,4,0,
56,10,214,4,0,
56,10,216,4,0,
56,10,218,4,0,
56,10,227,2,0,
56,10,231,4,0,
56,10,237,4,0,
56,10,238,1,37,
56,10,240,4,0,
56,10,241,4,0,
56,10,249,4,0,
56,10,251,2,0,
56,10,253,3,0,
56,10,263,4,0,
56,10,264,4,0,
56,10,265,2,0,
56,10,269,4,0,
56,10,270,3,0,
56,10,272,3,0,
56,10,279,2,0,
56,10,280,4,0,
56,10,283,3,0,
56,10,290,4,0,
56,10,315,4,0,
56,10,317,4,0,
56,10,332,4,0,
56,10,339,4,0,
56,10,343,1,1,1
56,10,363,4,0,
56,10,369,4,0,
56,10,370,1,49,
56,10,370,2,0,
56,10,371,4,0,
56,10,372,1,25,
56,10,374,4,0,
56,10,386,1,45,
56,10,398,4,0,
56,10,402,3,0,
56,10,410,3,0,
56,10,411,4,0,
56,10,431,4,0,
56,10,441,3,0,
56,10,445,4,0,
56,11,2,1,13,
56,11,10,1,1,2
56,11,37,1,41,
56,11,43,1,1,4
56,11,67,1,1,3
56,11,68,2,0,
56,11,69,1,17,
56,11,70,4,0,
56,11,85,4,0,
56,11,87,4,0,
56,11,89,4,0,
56,11,91,4,0,
56,11,92,4,0,
56,11,96,2,0,
56,11,103,1,21,
56,11,104,4,0,
56,11,116,1,1,5
56,11,154,1,9,
56,11,156,4,0,
56,11,157,4,0,
56,11,164,4,0,
56,11,168,4,0,
56,11,179,2,0,
56,11,182,4,0,
56,11,193,2,0,
56,11,207,1,33,
56,11,207,4,0,
56,11,213,4,0,
56,11,214,2,0,
56,11,216,4,0,
56,11,218,4,0,
56,11,227,2,0,
56,11,237,4,0,
56,11,238,1,37,
56,11,240,4,0,
56,11,241,4,0,
56,11,249,4,0,
56,11,251,2,0,
56,11,263,4,0,
56,11,264,2,0,
56,11,265,2,0,
56,11,269,4,0,
56,11,279,2,0,
56,11,280,4,0,
56,11,315,4,0,
56,11,317,4,0,
56,11,332,4,0,
56,11,339,4,0,
56,11,343,1,1,1
56,11,369,4,0,
56,11,370,1,49,
56,11,370,2,0,
56,11,371,4,0,
56,11,372,1,25,
56,11,374,4,0,
56,11,386,1,45,
56,11,398,4,0,
56,11,411,4,0,
56,11,468,4,0,
56,11,479,4,0,
56,11,490,4,0,
56,11,496,4,0,
56,11,512,4,0,
56,11,514,4,0,
56,11,515,1,53,
56,11,523,4,0,
56,11,526,4,0,
56,12,2,1,15,
56,12,10,1,1,1
56,12,37,1,51,
56,12,43,1,1,2
56,12,67,1,9,
56,12,69,1,33,
56,12,70,4,0,
56,12,85,4,0,
56,12,87,4,0,
56,12,89,4,0,
56,12,91,4,0,
56,12,92,4,0,
56,12,103,1,45,
56,12,104,4,0,
56,12,116,1,27,
56,12,154,1,21,
56,12,156,4,0,
56,12,168,4,0,
56,12,182,4,0,
56,12,213,4,0,
56,12,216,4,0,
56,12,218,4,0,
56,12,231,4,0,
56,12,237,4,0,
56,12,238,1,39,
56,12,240,4,0,
56,12,241,4,0,
56,12,249,4,0,
56,12,263,4,0,
56,12,264,4,0,
56,12,269,4,0,
56,12,280,4,0,
56,12,290,4,0,
56,12,315,4,0,
56,12,317,4,0,
56,12,332,4,0,
56,12,339,4,0,
56,13,2,1,15,
56,13,10,1,1,1
56,13,34,3,0,
56,13,37,1,51,
56,13,38,3,0,
56,13,43,1,1,2
56,13,67,1,9,
56,13,69,1,33,
56,13,69,3,0,
56,13,70,4,0,
56,13,85,4,0,
56,13,87,4,0,
56,13,89,4,0,
56,13,91,4,0,
56,13,92,4,0,
56,13,102,3,0,
56,13,103,1,45,
56,13,104,4,0,
56,13,116,1,27,
56,13,154,1,21,
56,13,156,4,0,
56,13,164,3,0,
56,13,168,4,0,
56,13,182,4,0,
56,13,207,3,0,
56,13,213,4,0,
56,13,216,4,0,
56,13,218,4,0,
56,13,231,4,0,
56,13,237,4,0,
56,13,238,1,39,
56,13,240,4,0,
56,13,241,4,0,
56,13,249,4,0,
56,13,263,4,0,
56,13,264,4,0,
56,13,269,4,0,
56,13,280,4,0,
56,13,290,4,0,
56,13,315,4,0,
56,13,317,4,0,
56,13,332,4,0,
56,13,339,4,0,
56,14,2,1,13,
56,14,7,3,0,
56,14,8,3,0,
56,14,9,3,0,
56,14,10,1,1,2
56,14,37,1,41,
56,14,43,1,1,4
56,14,67,1,1,3
56,14,67,3,0,
56,14,68,2,0,
56,14,69,1,17,
56,14,70,4,0,
56,14,85,4,0,
56,14,87,4,0,
56,14,89,4,0,
56,14,91,4,0,
56,14,92,4,0,
56,14,96,2,0,
56,14,103,1,21,
56,14,104,4,0,
56,14,116,1,1,5
56,14,154,1,9,
56,14,156,4,0,
56,14,157,4,0,
56,14,164,4,0,
56,14,168,4,0,
56,14,179,2,0,
56,14,180,3,0,
56,14,182,4,0,
56,14,193,2,0,
56,14,200,3,0,
56,14,207,1,33,
56,14,207,4,0,
56,14,213,4,0,
56,14,214,2,0,
56,14,214,3,0,
56,14,216,4,0,
56,14,218,4,0,
56,14,227,2,0,
56,14,231,3,0,
56,14,237,4,0,
56,14,238,1,37,
56,14,240,4,0,
56,14,241,4,0,
56,14,249,4,0,
56,14,251,2,0,
56,14,253,3,0,
56,14,263,4,0,
56,14,264,2,0,
56,14,265,2,0,
56,14,269,4,0,
56,14,270,3,0,
56,14,272,3,0,
56,14,279,2,0,
56,14,280,4,0,
56,14,283,3,0,
56,14,315,4,0,
56,14,317,4,0,
56,14,332,4,0,
56,14,339,4,0,
56,14,343,1,1,1
56,14,343,3,0,
56,14,369,4,0,
56,14,370,1,49,
56,14,370,2,0,
56,14,371,4,0,
56,14,372,1,25,
56,14,374,4,0,
56,14,386,1,45,
56,14,398,4,0,
56,14,402,3,0,
56,14,411,4,0,
56,14,441,3,0,
56,14,468,4,0,
56,14,479,4,0,
56,14,490,4,0,
56,14,496,4,0,
56,14,512,4,0,
56,14,514,4,0,
56,14,515,1,53,
56,14,523,4,0,
56,14,526,4,0,
56,14,530,3,0,
56,15,2,1,13,
56,15,10,1,1,2
56,15,37,1,41,
56,15,43,1,1,4
56,15,67,1,1,3
56,15,68,2,0,
56,15,69,1,17,
56,15,70,4,0,
56,15,85,4,0,
56,15,87,4,0,
56,15,89,4,0,
56,15,91,4,0,
56,15,92,4,0,
56,15,96,2,0,
56,15,103,1,21,
56,15,104,4,0,
56,15,116,1,1,5
56,15,154,1,9,
56,15,156,4,0,
56,15,157,4,0,
56,15,164,4,0,
56,15,168,4,0,
56,15,179,2,0,
56,15,182,4,0,
56,15,193,2,0,
56,15,207,1,33,
56,15,207,4,0,
56,15,213,4,0,
56,15,214,2,0,
56,15,214,4,0,
56,15,216,4,0,
56,15,218,4,0,
56,15,227,2,0,
56,15,237,4,0,
56,15,238,1,37,
56,15,240,4,0,
56,15,241,4,0,
56,15,249,4,0,
56,15,251,2,0,
56,15,263,4,0,
56,15,264,2,0,
56,15,265,2,0,
56,15,269,4,0,
56,15,279,2,0,
56,15,280,4,0,
56,15,315,4,0,
56,15,317,4,0,
56,15,332,4,0,
56,15,339,4,0,
56,15,343,1,1,1
56,15,369,4,0,
56,15,370,1,49,
56,15,370,2,0,
56,15,371,4,0,
56,15,372,1,25,
56,15,374,4,0,
56,15,386,1,45,
56,15,398,4,0,
56,15,400,2,0,
56,15,411,4,0,
56,15,468,4,0,
56,15,479,4,0,
56,15,490,4,0,
56,15,496,4,0,
56,15,512,4,0,
56,15,514,4,0,
56,15,515,1,53,
56,15,523,4,0,
56,15,590,4,0,
56,15,612,4,0,
56,16,2,1,13,1
56,16,7,3,0,
56,16,8,3,0,
56,16,9,3,0,
56,16,10,1,1,2
56,16,37,1,41,1
56,16,43,1,1,4
56,16,67,1,1,3
56,16,67,3,0,
56,16,68,2,0,
56,16,69,1,17,1
56,16,70,4,0,
56,16,85,4,0,
56,16,87,4,0,
56,16,89,4,0,
56,16,91,4,0,
56,16,92,4,0,
56,16,96,2,0,
56,16,103,1,21,1
56,16,104,4,0,
56,16,116,1,1,5
56,16,154,1,9,1
56,16,156,4,0,
56,16,157,4,0,
56,16,164,4,0,
56,16,168,4,0,
56,16,173,3,0,
56,16,179,2,0,
56,16,180,3,0,
56,16,182,4,0,
56,16,193,2,0,
56,16,200,3,0,
56,16,207,1,33,1
56,16,207,4,0,
56,16,213,4,0,
56,16,214,2,0,
56,16,214,4,0,
56,16,216,4,0,
56,16,218,4,0,
56,16,227,2,0,
56,16,231,3,0,
56,16,237,4,0,
56,16,238,1,37,1
56,16,240,4,0,
56,16,241,4,0,
56,16,249,4,0,
56,16,251,2,0,
56,16,253,3,0,
56,16,263,4,0,
56,16,264,2,0,
56,16,264,3,0,
56,16,265,2,0,
56,16,269,4,0,
56,16,270,3,0,
56,16,272,3,0,
56,16,279,2,0,
56,16,280,4,0,
56,16,283,3,0,
56,16,290,4,0,
56,16,315,4,0,
56,16,317,4,0,
56,16,332,4,0,
56,16,339,4,0,
56,16,343,1,1,1
56,16,343,3,0,
56,16,369,4,0,
56,16,370,1,49,1
56,16,370,2,0,
56,16,371,4,0,
56,16,372,1,25,1
56,16,374,4,0,
56,16,386,1,45,1
56,16,398,4,0,
56,16,400,2,0,
56,16,402,3,0,
56,16,411,4,0,
56,16,441,3,0,
56,16,468,4,0,
56,16,479,4,0,
56,16,490,4,0,
56,16,496,4,0,
56,16,512,4,0,
56,16,514,4,0,
56,16,515,1,53,1
56,16,523,4,0,
56,16,530,3,0,
56,16,590,4,0,
56,16,612,4,0,
56,17,2,1,8,
56,17,10,1,1,2
56,17,37,1,33,
56,17,43,1,1,4
56,17,67,1,1,3
56,17,68,2,0,
56,17,69,1,15,
56,17,85,4,0,
56,17,87,4,0,
56,17,89,4,0,
56,17,92,4,0,
56,17,96,2,0,
56,17,103,1,40,
56,17,104,4,0,
56,17,116,1,1,5
56,17,154,1,5,
56,17,156,4,0,
56,17,157,4,0,
56,17,164,4,0,
56,17,168,4,0,
56,17,179,2,0,
56,17,182,4,0,
56,17,193,2,0,
56,17,200,1,47,
56,17,207,1,19,
56,17,207,4,0,
56,17,213,4,0,
56,17,214,2,0,
56,17,214,4,0,
56,17,216,4,0,
56,17,218,4,0,
56,17,227,2,0,
56,17,228,1,12,
56,17,237,4,0,
56,17,238,1,22,
56,17,240,4,0,
56,17,241,4,0,
56,17,251,2,0,
56,17,263,4,0,
56,17,264,2,0,
56,17,265,2,0,
56,17,269,4,0,
56,17,279,2,0,
56,17,280,4,0,
56,17,315,4,0,
56,17,317,4,0,
56,17,332,4,0,
56,17,339,4,0,
56,17,343,1,1,1
56,17,369,4,0,
56,17,370,1,36,
56,17,370,2,0,
56,17,371,4,0,
56,17,372,1,26,
56,17,374,4,0,
56,17,386,1,29,
56,17,398,4,0,
56,17,400,2,0,
56,17,411,4,0,
56,17,479,4,0,
56,17,490,4,0,
56,17,496,4,0,
56,17,512,4,0,
56,17,515,1,50,
56,17,523,4,0,
56,17,526,4,0,
56,17,590,4,0,
56,17,681,2,0,
56,17,707,1,43,
56,18,2,1,8,
56,18,10,1,1,2
56,18,37,1,33,
56,18,43,1,1,4
56,18,67,1,1,3
56,18,68,2,0,
56,18,69,1,15,
56,18,85,4,0,
56,18,87,4,0,
56,18,89,4,0,
56,18,92,4,0,
56,18,96,2,0,
56,18,103,1,40,
56,18,104,4,0,
56,18,116,1,1,5
56,18,154,1,5,
56,18,156,4,0,
56,18,157,4,0,
56,18,164,4,0,
56,18,168,4,0,
56,18,179,2,0,
56,18,182,4,0,
56,18,193,2,0,
56,18,200,1,47,
56,18,207,1,19,
56,18,207,4,0,
56,18,213,4,0,
56,18,214,2,0,
56,18,214,4,0,
56,18,216,4,0,
56,18,218,4,0,
56,18,227,2,0,
56,18,228,1,12,
56,18,237,4,0,
56,18,238,1,22,
56,18,240,4,0,
56,18,241,4,0,
56,18,251,2,0,
56,18,263,4,0,
56,18,264,2,0,
56,18,265,2,0,
56,18,269,4,0,
56,18,279,2,0,
56,18,280,4,0,
56,18,315,4,0,
56,18,317,4,0,
56,18,332,4,0,
56,18,339,4,0,
56,18,343,1,1,1
56,18,369,4,0,
56,18,370,1,36,
56,18,370,2,0,
56,18,371,4,0,
56,18,372,1,26,
56,18,374,4,0,
56,18,386,1,29,
56,18,398,4,0,
56,18,400,2,0,
56,18,411,4,0,
56,18,479,4,0,
56,18,490,4,0,
56,18,496,4,0,
56,18,512,4,0,
56,18,515,1,50,
56,18,523,4,0,
56,18,526,4,0,
56,18,590,4,0,
56,18,681,2,0,
56,18,707,1,43,
57,1,2,1,1,3
57,1,2,1,15,
57,1,5,4,0,
57,1,6,4,0,
57,1,10,1,1,1
57,1,25,4,0,
57,1,34,4,0,
57,1,36,4,0,
57,1,37,1,46,
57,1,38,4,0,
57,1,43,1,1,2
57,1,63,4,0,
57,1,66,4,0,
57,1,68,4,0,
57,1,69,1,37,
57,1,69,4,0,
57,1,70,4,0,
57,1,85,4,0,
57,1,87,4,0,
57,1,91,4,0,
57,1,92,4,0,
57,1,99,4,0,
57,1,102,4,0,
57,1,104,4,0,
57,1,116,1,27,
57,1,117,4,0,
57,1,118,4,0,
57,1,129,4,0,
57,1,130,4,0,
57,1,154,1,1,4
57,1,154,1,21,
57,1,156,4,0,
57,1,157,4,0,
57,1,164,4,0,
57,2,2,1,1,4
57,2,2,1,15,
57,2,5,4,0,
57,2,6,4,0,
57,2,10,1,1,1
57,2,25,4,0,
57,2,34,4,0,
57,2,36,4,0,
57,2,37,1,46,
57,2,38,4,0,
57,2,43,1,1,2
57,2,63,4,0,
57,2,66,4,0,
57,2,67,1,1,3
57,2,67,1,9,
57,2,68,4,0,
57,2,69,1,37,
57,2,69,4,0,
57,2,70,4,0,
57,2,85,4,0,
57,2,87,4,0,
57,2,91,4,0,
57,2,92,4,0,
57,2,99,1,28,
57,2,99,4,0,
57,2,102,4,0,
57,2,103,1,45,
57,2,104,4,0,
57,2,116,1,27,
57,2,117,4,0,
57,2,118,4,0,
57,2,129,4,0,
57,2,130,4,0,
57,2,154,1,21,
57,2,156,4,0,
57,2,157,4,0,
57,2,164,4,0,
57,3,2,1,15,
57,3,7,4,0,
57,3,8,4,0,
57,3,9,4,0,
57,3,10,1,1,1
57,3,29,4,0,
57,3,37,1,63,
57,3,43,1,1,2
57,3,63,4,0,
57,3,67,1,1,3
57,3,67,1,9,
57,3,69,1,36,
57,3,70,4,0,
57,3,87,4,0,
57,3,91,4,0,
57,3,92,4,0,
57,3,99,1,1,4
57,3,99,1,28,
57,3,103,1,54,
57,3,104,4,0,
57,3,111,4,0,
57,3,116,1,27,
57,3,129,4,0,
57,3,154,1,21,
57,3,156,4,0,
57,3,168,4,0,
57,3,173,4,0,
57,3,174,4,0,
57,3,182,4,0,
57,3,189,4,0,
57,3,197,4,0,
57,3,203,4,0,
57,3,207,4,0,
57,3,213,4,0,
57,3,214,4,0,
57,3,216,4,0,
57,3,218,4,0,
57,3,223,4,0,
57,3,231,4,0,
57,3,237,4,0,
57,3,238,1,45,
57,3,241,4,0,
57,3,244,4,0,
57,3,249,4,0,
57,4,2,1,15,
57,4,7,4,0,
57,4,8,4,0,
57,4,9,4,0,
57,4,10,1,1,1
57,4,29,4,0,
57,4,37,1,63,
57,4,43,1,1,2
57,4,63,4,0,
57,4,67,1,1,3
57,4,67,1,9,
57,4,69,1,36,
57,4,70,4,0,
57,4,85,3,0,
57,4,87,4,0,
57,4,91,4,0,
57,4,92,4,0,
57,4,99,1,1,4
57,4,99,1,28,
57,4,103,1,54,
57,4,104,4,0,
57,4,111,4,0,
57,4,116,1,27,
57,4,129,4,0,
57,4,154,1,21,
57,4,156,4,0,
57,4,168,4,0,
57,4,173,4,0,
57,4,174,4,0,
57,4,182,4,0,
57,4,189,4,0,
57,4,197,4,0,
57,4,203,4,0,
57,4,207,4,0,
57,4,213,4,0,
57,4,214,4,0,
57,4,216,4,0,
57,4,218,4,0,
57,4,223,4,0,
57,4,231,4,0,
57,4,237,4,0,
57,4,238,1,45,
57,4,241,4,0,
57,4,244,4,0,
57,4,249,4,0,
57,5,2,1,15,
57,5,10,1,1,1
57,5,37,1,63,
57,5,43,1,1,2
57,5,63,4,0,
57,5,67,1,1,3
57,5,67,1,9,
57,5,69,1,36,
57,5,70,4,0,
57,5,85,4,0,
57,5,87,4,0,
57,5,89,4,0,
57,5,91,4,0,
57,5,92,4,0,
57,5,99,1,1,4
57,5,99,1,28,
57,5,103,1,54,
57,5,104,4,0,
57,5,116,1,27,
57,5,154,1,21,
57,5,156,4,0,
57,5,168,4,0,
57,5,182,4,0,
57,5,213,4,0,
57,5,216,4,0,
57,5,218,4,0,
57,5,231,4,0,
57,5,237,4,0,
57,5,238,1,45,
57,5,240,4,0,
57,5,241,4,0,
57,5,249,4,0,
57,5,263,4,0,
57,5,264,4,0,
57,5,269,4,0,
57,5,280,4,0,
57,5,290,4,0,
57,5,315,4,0,
57,5,317,4,0,
57,5,332,4,0,
57,5,339,4,0,
57,6,2,1,15,
57,6,5,3,0,
57,6,7,3,0,
57,6,8,3,0,
57,6,9,3,0,
57,6,10,1,1,1
57,6,25,3,0,
57,6,34,3,0,
57,6,37,1,63,
57,6,38,3,0,
57,6,43,1,1,2
57,6,63,4,0,
57,6,67,1,1,3
57,6,67,1,9,
57,6,68,3,0,
57,6,69,1,36,
57,6,69,3,0,
57,6,70,4,0,
57,6,85,4,0,
57,6,87,4,0,
57,6,89,4,0,
57,6,91,4,0,
57,6,92,4,0,
57,6,99,1,1,4
57,6,99,1,28,
57,6,102,3,0,
57,6,103,1,54,
57,6,104,4,0,
57,6,111,3,0,
57,6,116,1,27,
57,6,118,3,0,
57,6,129,3,0,
57,6,154,1,21,
57,6,156,4,0,
57,6,157,3,0,
57,6,164,3,0,
57,6,168,4,0,
57,6,173,3,0,
57,6,182,4,0,
57,6,189,3,0,
57,6,203,3,0,
57,6,207,3,0,
57,6,213,4,0,
57,6,214,3,0,
57,6,216,4,0,
57,6,218,4,0,
57,6,223,3,0,
57,6,231,4,0,
57,6,237,4,0,
57,6,238,1,45,
57,6,240,4,0,
57,6,241,4,0,
57,6,244,3,0,
57,6,249,4,0,
57,6,263,4,0,
57,6,264,4,0,
57,6,269,4,0,
57,6,280,4,0,
57,6,290,4,0,
57,6,315,4,0,
57,6,317,4,0,
57,6,332,4,0,
57,6,339,4,0,
57,7,2,1,11,
57,7,5,3,0,
57,7,10,1,1,1
57,7,25,3,0,
57,7,34,3,0,
57,7,37,1,62,
57,7,38,3,0,
57,7,43,1,1,2
57,7,63,4,0,
57,7,67,1,1,3
57,7,67,1,6,
57,7,68,3,0,
57,7,69,1,26,
57,7,69,3,0,
57,7,70,4,0,
57,7,85,4,0,
57,7,87,4,0,
57,7,89,4,0,
57,7,91,4,0,
57,7,92,4,0,
57,7,99,1,1,4
57,7,99,1,28,
57,7,102,3,0,
57,7,103,1,53,
57,7,104,4,0,
57,7,116,1,21,
57,7,118,3,0,
57,7,154,1,16,
57,7,156,4,0,
57,7,157,3,0,
57,7,164,3,0,
57,7,168,4,0,
57,7,182,4,0,
57,7,207,1,44,
57,7,213,4,0,
57,7,216,4,0,
57,7,218,4,0,
57,7,231,4,0,
57,7,237,4,0,
57,7,238,1,35,
57,7,240,4,0,
57,7,241,4,0,
57,7,249,4,0,
57,7,263,4,0,
57,7,264,4,0,
57,7,269,4,0,
57,7,280,4,0,
57,7,290,4,0,
57,7,315,4,0,
57,7,317,4,0,
57,7,332,4,0,
57,7,339,4,0,
57,8,2,1,13,
57,8,10,1,1,2
57,8,37,1,47,
57,8,43,1,1,4
57,8,63,4,0,
57,8,67,1,1,3
57,8,69,1,17,
57,8,70,4,0,
57,8,85,4,0,
57,8,87,4,0,
57,8,89,4,0,
57,8,91,4,0,
57,8,92,4,0,
57,8,99,1,28,
57,8,103,1,21,
57,8,104,4,0,
57,8,116,1,1,5
57,8,154,1,9,
57,8,156,4,0,
57,8,157,4,0,
57,8,164,4,0,
57,8,168,4,0,
57,8,182,4,0,
57,8,203,4,0,
57,8,207,1,35,
57,8,207,4,0,
57,8,213,4,0,
57,8,214,4,0,
57,8,216,4,0,
57,8,218,4,0,
57,8,231,4,0,
57,8,237,4,0,
57,8,238,1,41,
57,8,240,4,0,
57,8,241,4,0,
57,8,249,4,0,
57,8,263,4,0,
57,8,264,4,0,
57,8,269,4,0,
57,8,280,4,0,
57,8,290,4,0,
57,8,315,4,0,
57,8,317,4,0,
57,8,332,4,0,
57,8,339,4,0,
57,8,363,4,0,
57,8,369,4,0,
57,8,370,1,59,
57,8,371,4,0,
57,8,372,1,25,
57,8,374,1,1,1
57,8,374,4,0,
57,8,386,1,53,
57,8,398,4,0,
57,8,411,4,0,
57,8,416,4,0,
57,8,431,4,0,
57,8,444,4,0,
57,8,445,4,0,
57,9,2,1,13,
57,9,7,3,0,
57,9,8,3,0,
57,9,9,3,0,
57,9,10,1,1,2
57,9,37,1,47,
57,9,43,1,1,4
57,9,63,4,0,
57,9,67,1,1,3
57,9,69,1,17,
57,9,70,4,0,
57,9,85,4,0,
57,9,87,4,0,
57,9,89,4,0,
57,9,91,4,0,
57,9,92,4,0,
57,9,99,1,28,
57,9,103,1,21,
57,9,104,4,0,
57,9,116,1,1,5
57,9,129,3,0,
57,9,154,1,9,
57,9,156,4,0,
57,9,157,4,0,
57,9,164,4,0,
57,9,168,4,0,
57,9,180,3,0,
57,9,182,4,0,
57,9,189,3,0,
57,9,200,3,0,
57,9,203,4,0,
57,9,207,1,35,
57,9,207,4,0,
57,9,213,4,0,
57,9,214,4,0,
57,9,216,4,0,
57,9,218,4,0,
57,9,231,4,0,
57,9,237,4,0,
57,9,238,1,41,
57,9,240,4,0,
57,9,241,4,0,
57,9,249,4,0,
57,9,253,3,0,
57,9,263,4,0,
57,9,264,4,0,
57,9,269,4,0,
57,9,270,3,0,
57,9,280,4,0,
57,9,283,3,0,
57,9,290,4,0,
57,9,315,4,0,
57,9,317,4,0,
57,9,332,4,0,
57,9,339,4,0,
57,9,363,4,0,
57,9,369,4,0,
57,9,370,1,59,
57,9,371,4,0,
57,9,372,1,25,
57,9,374,1,1,1
57,9,374,4,0,
57,9,386,1,53,
57,9,398,4,0,
57,9,402,3,0,
57,9,410,3,0,
57,9,411,4,0,
57,9,416,4,0,
57,9,431,4,0,
57,9,441,3,0,
57,9,444,4,0,
57,9,445,4,0,
57,10,2,1,13,
57,10,7,3,0,
57,10,8,3,0,
57,10,9,3,0,
57,10,10,1,1,2
57,10,29,3,0,
57,10,37,1,47,
57,10,43,1,1,4
57,10,63,4,0,
57,10,67,1,1,3
57,10,67,3,0,
57,10,69,1,17,
57,10,70,4,0,
57,10,85,4,0,
57,10,87,4,0,
57,10,89,4,0,
57,10,91,4,0,
57,10,92,4,0,
57,10,99,1,28,
57,10,103,1,21,
57,10,104,4,0,
57,10,116,1,1,5
57,10,129,3,0,
57,10,154,1,9,
57,10,156,4,0,
57,10,157,4,0,
57,10,164,4,0,
57,10,168,4,0,
57,10,180,3,0,
57,10,182,4,0,
57,10,189,3,0,
57,10,200,3,0,
57,10,203,4,0,
57,10,207,1,35,
57,10,207,4,0,
57,10,213,4,0,
57,10,214,4,0,
57,10,216,4,0,
57,10,218,4,0,
57,10,231,4,0,
57,10,237,4,0,
57,10,238,1,41,
57,10,240,4,0,
57,10,241,4,0,
57,10,249,4,0,
57,10,253,3,0,
57,10,263,4,0,
57,10,264,4,0,
57,10,269,4,0,
57,10,270,3,0,
57,10,272,3,0,
57,10,280,4,0,
57,10,283,3,0,
57,10,290,4,0,
57,10,315,4,0,
57,10,317,4,0,
57,10,332,4,0,
57,10,339,4,0,
57,10,363,4,0,
57,10,369,4,0,
57,10,370,1,59,
57,10,371,4,0,
57,10,372,1,25,
57,10,374,1,1,1
57,10,374,4,0,
57,10,386,1,53,
57,10,398,4,0,
57,10,402,3,0,
57,10,410,3,0,
57,10,411,4,0,
57,10,416,4,0,
57,10,431,4,0,
57,10,441,3,0,
57,10,444,4,0,
57,10,445,4,0,
57,11,2,1,13,
57,11,10,1,1,2
57,11,37,1,47,
57,11,43,1,1,4
57,11,63,4,0,
57,11,67,1,1,3
57,11,69,1,17,
57,11,70,4,0,
57,11,85,4,0,
57,11,87,4,0,
57,11,89,4,0,
57,11,91,4,0,
57,11,92,4,0,
57,11,99,1,28,
57,11,103,1,21,
57,11,104,4,0,
57,11,116,1,1,5
57,11,154,1,9,
57,11,156,4,0,
57,11,157,4,0,
57,11,164,4,0,
57,11,168,4,0,
57,11,182,4,0,
57,11,207,1,35,
57,11,207,4,0,
57,11,213,4,0,
57,11,216,4,0,
57,11,218,4,0,
57,11,237,4,0,
57,11,238,1,41,
57,11,240,4,0,
57,11,241,4,0,
57,11,249,4,0,
57,11,263,4,0,
57,11,269,4,0,
57,11,280,4,0,
57,11,315,4,0,
57,11,317,4,0,
57,11,332,4,0,
57,11,339,4,0,
57,11,369,4,0,
57,11,370,1,59,
57,11,371,4,0,
57,11,372,1,25,
57,11,374,1,1,1
57,11,374,4,0,
57,11,386,1,53,
57,11,398,4,0,
57,11,411,4,0,
57,11,416,4,0,
57,11,444,4,0,
57,11,468,4,0,
57,11,479,4,0,
57,11,490,4,0,
57,11,496,4,0,
57,11,512,4,0,
57,11,514,4,0,
57,11,515,1,63,
57,11,523,4,0,
57,11,526,4,0,
57,12,2,1,15,
57,12,10,1,1,1
57,12,37,1,63,
57,12,43,1,1,2
57,12,63,4,0,
57,12,67,1,1,3
57,12,67,1,9,
57,12,69,1,36,
57,12,70,4,0,
57,12,85,4,0,
57,12,87,4,0,
57,12,89,4,0,
57,12,91,4,0,
57,12,92,4,0,
57,12,99,1,1,4
57,12,99,1,28,
57,12,103,1,54,
57,12,104,4,0,
57,12,116,1,27,
57,12,154,1,21,
57,12,156,4,0,
57,12,168,4,0,
57,12,182,4,0,
57,12,213,4,0,
57,12,216,4,0,
57,12,218,4,0,
57,12,231,4,0,
57,12,237,4,0,
57,12,238,1,45,
57,12,240,4,0,
57,12,241,4,0,
57,12,249,4,0,
57,12,263,4,0,
57,12,264,4,0,
57,12,269,4,0,
57,12,280,4,0,
57,12,290,4,0,
57,12,315,4,0,
57,12,317,4,0,
57,12,332,4,0,
57,12,339,4,0,
57,13,2,1,15,
57,13,10,1,1,1
57,13,34,3,0,
57,13,37,1,63,
57,13,38,3,0,
57,13,43,1,1,2
57,13,63,4,0,
57,13,67,1,1,3
57,13,67,1,9,
57,13,69,1,36,
57,13,69,3,0,
57,13,70,4,0,
57,13,85,4,0,
57,13,87,4,0,
57,13,89,4,0,
57,13,91,4,0,
57,13,92,4,0,
57,13,99,1,1,4
57,13,99,1,28,
57,13,102,3,0,
57,13,103,1,54,
57,13,104,4,0,
57,13,116,1,27,
57,13,154,1,21,
57,13,156,4,0,
57,13,164,3,0,
57,13,168,4,0,
57,13,182,4,0,
57,13,207,3,0,
57,13,213,4,0,
57,13,216,4,0,
57,13,218,4,0,
57,13,231,4,0,
57,13,237,4,0,
57,13,238,1,45,
57,13,240,4,0,
57,13,241,4,0,
57,13,249,4,0,
57,13,263,4,0,
57,13,264,4,0,
57,13,269,4,0,
57,13,280,4,0,
57,13,290,4,0,
57,13,315,4,0,
57,13,317,4,0,
57,13,332,4,0,
57,13,339,4,0,
57,14,2,1,13,
57,14,7,3,0,
57,14,8,3,0,
57,14,9,3,0,
57,14,10,1,1,2
57,14,37,1,47,
57,14,43,1,1,4
57,14,63,4,0,
57,14,67,1,1,3
57,14,67,3,0,
57,14,69,1,17,
57,14,70,4,0,
57,14,85,4,0,
57,14,87,4,0,
57,14,89,4,0,
57,14,91,4,0,
57,14,92,4,0,
57,14,99,1,28,
57,14,103,1,21,
57,14,104,4,0,
57,14,116,1,1,5
57,14,154,1,9,
57,14,156,4,0,
57,14,157,4,0,
57,14,164,4,0,
57,14,168,4,0,
57,14,180,3,0,
57,14,182,4,0,
57,14,200,3,0,
57,14,207,1,35,
57,14,207,4,0,
57,14,213,4,0,
57,14,214,3,0,
57,14,216,4,0,
57,14,218,4,0,
57,14,231,3,0,
57,14,237,4,0,
57,14,238,1,41,
57,14,240,4,0,
57,14,241,4,0,
57,14,249,4,0,
57,14,253,3,0,
57,14,263,4,0,
57,14,269,4,0,
57,14,270,3,0,
57,14,272,3,0,
57,14,280,4,0,
57,14,283,3,0,
57,14,315,4,0,
57,14,317,4,0,
57,14,332,4,0,
57,14,339,4,0,
57,14,343,3,0,
57,14,369,4,0,
57,14,370,1,59,
57,14,371,4,0,
57,14,372,1,25,
57,14,374,1,1,1
57,14,374,4,0,
57,14,386,1,53,
57,14,398,4,0,
57,14,402,3,0,
57,14,411,4,0,
57,14,416,4,0,
57,14,441,3,0,
57,14,444,4,0,
57,14,468,4,0,
57,14,479,4,0,
57,14,490,4,0,
57,14,496,4,0,
57,14,512,4,0,
57,14,514,4,0,
57,14,515,1,63,
57,14,523,4,0,
57,14,526,4,0,
57,14,530,3,0,
57,15,2,1,13,
57,15,10,1,1,3
57,15,37,1,47,
57,15,43,1,1,5
57,15,63,4,0,
57,15,67,1,1,4
57,15,69,1,17,
57,15,70,4,0,
57,15,85,4,0,
57,15,87,4,0,
57,15,89,4,0,
57,15,91,4,0,
57,15,92,4,0,
57,15,99,1,28,
57,15,103,1,21,
57,15,104,4,0,
57,15,116,1,1,6
57,15,154,1,9,
57,15,156,4,0,
57,15,157,4,0,
57,15,164,4,0,
57,15,168,4,0,
57,15,182,4,0,
57,15,207,1,35,
57,15,207,4,0,
57,15,213,4,0,
57,15,214,4,0,
57,15,216,4,0,
57,15,218,4,0,
57,15,237,4,0,
57,15,238,1,41,
57,15,240,4,0,
57,15,241,4,0,
57,15,249,4,0,
57,15,263,4,0,
57,15,269,4,0,
57,15,280,4,0,
57,15,315,4,0,
57,15,317,4,0,
57,15,332,4,0,
57,15,339,4,0,
57,15,369,4,0,
57,15,370,1,59,
57,15,371,4,0,
57,15,372,1,25,
57,15,374,1,1,2
57,15,374,4,0,
57,15,386,1,53,
57,15,398,4,0,
57,15,411,4,0,
57,15,416,4,0,
57,15,444,4,0,
57,15,468,4,0,
57,15,479,4,0,
57,15,490,4,0,
57,15,496,4,0,
57,15,512,4,0,
57,15,514,4,0,
57,15,515,1,1,1
57,15,515,1,63,
57,15,523,4,0,
57,15,590,4,0,
57,15,612,4,0,
57,16,2,1,13,1
57,16,7,3,0,
57,16,8,3,0,
57,16,9,3,0,
57,16,10,1,1,3
57,16,37,1,47,1
57,16,43,1,1,5
57,16,63,4,0,
57,16,67,1,1,4
57,16,67,3,0,
57,16,69,1,17,1
57,16,70,4,0,
57,16,85,4,0,
57,16,87,4,0,
57,16,89,4,0,
57,16,91,4,0,
57,16,92,4,0,
57,16,99,1,28,1
57,16,103,1,21,1
57,16,104,4,0,
57,16,116,1,1,6
57,16,154,1,9,1
57,16,156,4,0,
57,16,157,4,0,
57,16,164,4,0,
57,16,168,4,0,
57,16,173,3,0,
57,16,180,3,0,
57,16,182,4,0,
57,16,200,3,0,
57,16,207,1,35,1
57,16,207,4,0,
57,16,213,4,0,
57,16,214,4,0,
57,16,216,4,0,
57,16,218,4,0,
57,16,231,3,0,
57,16,237,4,0,
57,16,238,1,41,1
57,16,240,4,0,
57,16,241,4,0,
57,16,249,4,0,
57,16,253,3,0,
57,16,263,4,0,
57,16,264,3,0,
57,16,269,4,0,
57,16,270,3,0,
57,16,272,3,0,
57,16,280,4,0,
57,16,283,3,0,
57,16,290,4,0,
57,16,315,4,0,
57,16,317,4,0,
57,16,332,4,0,
57,16,339,4,0,
57,16,343,3,0,
57,16,369,4,0,
57,16,370,1,59,1
57,16,371,4,0,
57,16,372,1,25,1
57,16,374,1,1,2
57,16,374,4,0,
57,16,386,1,53,1
57,16,398,4,0,
57,16,402,3,0,
57,16,411,4,0,
57,16,416,4,0,
57,16,441,3,0,
57,16,444,4,0,
57,16,468,4,0,
57,16,479,4,0,
57,16,490,4,0,
57,16,496,4,0,
57,16,512,4,0,
57,16,514,4,0,
57,16,515,1,1,1
57,16,515,1,63,1
57,16,523,4,0,
57,16,530,3,0,
57,16,590,4,0,
57,16,612,4,0,
57,17,2,1,8,
57,17,10,1,1,4
57,17,37,1,35,
57,17,43,1,1,6
57,17,63,4,0,
57,17,67,1,1,5
57,17,69,1,15,
57,17,85,4,0,
57,17,87,4,0,
57,17,89,4,0,
57,17,92,4,0,
57,17,99,1,0,
57,17,99,1,1,1
57,17,103,1,44,
57,17,104,4,0,
57,17,116,1,1,7
57,17,154,1,5,
57,17,156,4,0,
57,17,157,4,0,
57,17,164,4,0,
57,17,168,4,0,
57,17,182,4,0,
57,17,200,1,53,
57,17,207,1,19,
57,17,207,4,0,
57,17,213,4,0,
57,17,214,4,0,
57,17,216,4,0,
57,17,218,4,0,
57,17,228,1,12,
57,17,237,4,0,
57,17,238,1,22,
57,17,240,4,0,
57,17,241,4,0,
57,17,263,4,0,
57,17,269,4,0,
57,17,280,4,0,
57,17,315,4,0,
57,17,317,4,0,
57,17,332,4,0,
57,17,339,4,0,
57,17,369,4,0,
57,17,370,1,39,
57,17,371,4,0,
57,17,372,1,26,
57,17,374,1,1,3
57,17,374,4,0,
57,17,386,1,30,
57,17,398,4,0,
57,17,411,4,0,
57,17,416,4,0,
57,17,444,4,0,
57,17,479,4,0,
57,17,490,4,0,
57,17,496,4,0,
57,17,512,4,0,
57,17,515,1,1,2
57,17,515,1,57,
57,17,523,4,0,
57,17,526,4,0,
57,17,590,4,0,
57,17,707,1,48,
57,18,2,1,8,
57,18,10,1,1,4
57,18,37,1,35,
57,18,43,1,1,6
57,18,63,4,0,
57,18,67,1,1,5
57,18,69,1,15,
57,18,85,4,0,
57,18,87,4,0,
57,18,89,4,0,
57,18,92,4,0,
57,18,99,1,0,
57,18,99,1,1,1
57,18,103,1,44,
57,18,104,4,0,
57,18,116,1,1,7
57,18,154,1,5,
57,18,156,4,0,
57,18,157,4,0,
57,18,164,4,0,
57,18,168,4,0,
57,18,182,4,0,
57,18,200,1,53,
57,18,207,1,19,
57,18,207,4,0,
57,18,213,4,0,
57,18,214,4,0,
57,18,216,4,0,
57,18,218,4,0,
57,18,228,1,12,
57,18,237,4,0,
57,18,238,1,22,
57,18,240,4,0,
57,18,241,4,0,
57,18,263,4,0,
57,18,269,4,0,
57,18,280,4,0,
57,18,315,4,0,
57,18,317,4,0,
57,18,332,4,0,
57,18,339,4,0,
57,18,369,4,0,
57,18,370,1,39,
57,18,371,4,0,
57,18,372,1,26,
57,18,374,1,1,3
57,18,374,4,0,
57,18,386,1,30,
57,18,398,4,0,
57,18,411,4,0,
57,18,416,4,0,
57,18,444,4,0,
57,18,479,4,0,
57,18,490,4,0,
57,18,496,4,0,
57,18,512,4,0,
57,18,515,1,1,2
57,18,515,1,57,
57,18,523,4,0,
57,18,526,4,0,
57,18,590,4,0,
57,18,707,1,48,
58,1,34,4,0,
58,1,36,1,30,
58,1,36,4,0,
58,1,38,4,0,
58,1,43,1,23,
58,1,44,1,1,1
58,1,46,1,1,2
58,1,52,1,18,
58,1,53,1,50,
58,1,82,4,0,
58,1,91,4,0,
58,1,92,4,0,
58,1,97,1,39,
58,1,99,4,0,
58,1,102,4,0,
58,1,104,4,0,
58,1,115,4,0,
58,1,117,4,0,
58,1,126,4,0,
58,1,129,4,0,
58,1,130,4,0,
58,1,156,4,0,
58,1,164,4,0,
58,2,34,4,0,
58,2,36,1,30,
58,2,36,4,0,
58,2,38,4,0,
58,2,43,1,23,
58,2,44,1,1,1
58,2,46,1,1,2
58,2,52,1,18,
58,2,53,1,50,
58,2,82,4,0,
58,2,91,4,0,
58,2,92,4,0,
58,2,97,1,39,
58,2,99,4,0,
58,2,102,4,0,
58,2,104,4,0,
58,2,115,4,0,
58,2,117,4,0,
58,2,126,4,0,
58,2,129,4,0,
58,2,130,4,0,
58,2,156,4,0,
58,2,164,4,0,
58,3,29,4,0,
58,3,34,2,0,
58,3,36,1,26,
58,3,37,2,0,
58,3,43,1,18,
58,3,44,1,1,1
58,3,46,1,1,2
58,3,46,4,0,
58,3,52,1,9,
58,3,53,1,50,
58,3,83,2,0,
58,3,91,4,0,
58,3,92,4,0,
58,3,97,1,42,
58,3,104,4,0,
58,3,126,4,0,
58,3,129,4,0,
58,3,156,4,0,
58,3,172,1,34,
58,3,173,4,0,
58,3,174,4,0,
58,3,182,4,0,
58,3,203,4,0,
58,3,207,4,0,
58,3,213,4,0,
58,3,214,4,0,
58,3,216,4,0,
58,3,218,4,0,
58,3,219,2,0,
58,3,225,4,0,
58,3,231,4,0,
58,3,237,4,0,
58,3,241,4,0,
58,3,242,2,0,
58,3,249,4,0,
58,4,29,4,0,
58,4,34,2,0,
58,4,36,1,26,
58,4,37,2,0,
58,4,43,1,18,
58,4,44,1,1,1
58,4,46,1,1,2
58,4,46,4,0,
58,4,52,1,9,
58,4,53,1,50,
58,4,53,3,0,
58,4,83,2,0,
58,4,91,4,0,
58,4,92,4,0,
58,4,97,1,42,
58,4,104,4,0,
58,4,126,4,0,
58,4,129,4,0,
58,4,156,4,0,
58,4,172,1,34,
58,4,173,4,0,
58,4,174,4,0,
58,4,182,4,0,
58,4,203,4,0,
58,4,207,4,0,
58,4,213,4,0,
58,4,214,4,0,
58,4,216,4,0,
58,4,218,4,0,
58,4,219,2,0,
58,4,225,4,0,
58,4,231,4,0,
58,4,237,4,0,
58,4,241,4,0,
58,4,242,2,0,
58,4,249,4,0,
58,5,34,2,0,
58,5,36,1,25,
58,5,37,2,0,
58,5,43,1,13,
58,5,44,1,1,1
58,5,46,1,1,2
58,5,46,4,0,
58,5,52,1,7,
58,5,53,1,49,
58,5,53,4,0,
58,5,70,4,0,
58,5,83,2,0,
58,5,91,4,0,
58,5,92,4,0,
58,5,97,1,43,
58,5,104,4,0,
58,5,126,4,0,
58,5,156,4,0,
58,5,168,4,0,
58,5,172,1,31,
58,5,182,4,0,
58,5,213,4,0,
58,5,216,4,0,
58,5,218,4,0,
58,5,219,2,0,
58,5,231,4,0,
58,5,237,4,0,
58,5,241,4,0,
58,5,242,2,0,
58,5,249,4,0,
58,5,257,2,0,
58,5,263,4,0,
58,5,270,1,37,
58,5,290,4,0,
58,5,315,4,0,
58,5,316,1,19,
58,5,332,4,0,
58,5,336,2,0,
58,6,34,2,0,
58,6,34,3,0,
58,6,36,1,25,
58,6,37,2,0,
58,6,38,3,0,
58,6,43,1,13,
58,6,44,1,1,1
58,6,46,1,1,2
58,6,46,4,0,
58,6,52,1,7,
58,6,53,1,49,
58,6,53,4,0,
58,6,70,4,0,
58,6,83,2,0,
58,6,91,4,0,
58,6,92,4,0,
58,6,97,1,43,
58,6,102,3,0,
58,6,104,4,0,
58,6,126,4,0,
58,6,129,3,0,
58,6,156,4,0,
58,6,164,3,0,
58,6,168,4,0,
58,6,172,1,31,
58,6,173,3,0,
58,6,182,4,0,
58,6,203,3,0,
58,6,207,3,0,
58,6,213,4,0,
58,6,214,3,0,
58,6,216,4,0,
58,6,218,4,0,
58,6,219,2,0,
58,6,231,4,0,
58,6,237,4,0,
58,6,241,4,0,
58,6,242,2,0,
58,6,249,4,0,
58,6,257,2,0,
58,6,263,4,0,
58,6,270,1,37,
58,6,290,4,0,
58,6,315,4,0,
58,6,316,1,19,
58,6,332,4,0,
58,6,336,2,0,
58,7,34,2,0,
58,7,34,3,0,
58,7,36,1,25,
58,7,37,2,0,
58,7,38,3,0,
58,7,43,1,13,
58,7,44,1,1,1
58,7,46,1,1,2
58,7,46,4,0,
58,7,52,1,7,
58,7,53,1,49,
58,7,53,4,0,
58,7,70,4,0,
58,7,83,2,0,
58,7,91,4,0,
58,7,92,4,0,
58,7,97,1,43,
58,7,102,3,0,
58,7,104,4,0,
58,7,126,4,0,
58,7,156,4,0,
58,7,164,3,0,
58,7,168,4,0,
58,7,172,1,31,
58,7,182,4,0,
58,7,213,4,0,
58,7,216,4,0,
58,7,218,4,0,
58,7,219,2,0,
58,7,231,4,0,
58,7,237,4,0,
58,7,241,4,0,
58,7,242,2,0,
58,7,249,4,0,
58,7,257,2,0,
58,7,263,4,0,
58,7,270,1,37,
58,7,290,4,0,
58,7,315,4,0,
58,7,316,1,19,
58,7,332,4,0,
58,7,336,2,0,
58,8,34,2,0,
58,8,36,1,31,
58,8,37,2,0,
58,8,38,2,0,
58,8,43,1,9,
58,8,44,1,1,1
58,8,46,1,1,2
58,8,46,4,0,
58,8,52,1,6,
58,8,53,1,34,
58,8,53,4,0,
58,8,70,4,0,
58,8,83,2,0,
58,8,91,4,0,
58,8,92,4,0,
58,8,97,1,39,
58,8,104,4,0,
58,8,126,4,0,
58,8,156,4,0,
58,8,164,4,0,
58,8,168,4,0,
58,8,172,1,20,
58,8,179,1,25,
58,8,182,4,0,
58,8,203,4,0,
58,8,207,4,0,
58,8,213,4,0,
58,8,214,4,0,
58,8,216,4,0,
58,8,218,4,0,
58,8,219,2,0,
58,8,231,4,0,
58,8,237,4,0,
58,8,241,4,0,
58,8,242,1,42,
58,8,242,2,0,
58,8,249,4,0,
58,8,257,1,45,
58,8,257,2,0,
58,8,261,4,0,
58,8,263,4,0,
58,8,270,1,17,
58,8,290,4,0,
58,8,315,4,0,
58,8,316,1,14,
58,8,332,4,0,
58,8,336,2,0,
58,8,363,4,0,
58,8,394,1,48,
58,8,394,2,0,
58,8,424,1,28,
58,8,445,4,0,
58,9,34,2,0,
58,9,36,1,31,
58,9,37,2,0,
58,9,38,2,0,
58,9,43,1,9,
58,9,44,1,1,1
58,9,46,1,1,2
58,9,46,4,0,
58,9,52,1,6,
58,9,53,1,34,
58,9,53,4,0,
58,9,70,4,0,
58,9,83,2,0,
58,9,91,4,0,
58,9,92,4,0,
58,9,97,1,39,
58,9,104,4,0,
58,9,126,4,0,
58,9,129,3,0,
58,9,156,4,0,
58,9,164,4,0,
58,9,168,4,0,
58,9,172,1,20,
58,9,173,3,0,
58,9,179,1,25,
58,9,182,4,0,
58,9,189,3,0,
58,9,203,4,0,
58,9,207,4,0,
58,9,213,4,0,
58,9,214,4,0,
58,9,216,4,0,
58,9,218,4,0,
58,9,219,2,0,
58,9,231,4,0,
58,9,237,4,0,
58,9,241,4,0,
58,9,242,1,42,
58,9,242,2,0,
58,9,249,4,0,
58,9,257,1,45,
58,9,257,2,0,
58,9,257,3,0,
58,9,261,4,0,
58,9,263,4,0,
58,9,270,1,17,
58,9,270,3,0,
58,9,290,4,0,
58,9,315,4,0,
58,9,316,1,14,
58,9,332,4,0,
58,9,336,2,0,
58,9,363,4,0,
58,9,394,1,48,
58,9,394,2,0,
58,9,424,1,28,
58,9,445,4,0,
58,10,29,3,0,
58,10,34,2,0,
58,10,36,1,31,
58,10,37,2,0,
58,10,38,2,0,
58,10,43,1,9,
58,10,44,1,1,1
58,10,46,1,1,2
58,10,46,4,0,
58,10,52,1,6,
58,10,53,1,34,
58,10,53,4,0,
58,10,70,4,0,
58,10,83,2,0,
58,10,91,4,0,
58,10,92,4,0,
58,10,97,1,39,
58,10,104,4,0,
58,10,126,4,0,
58,10,129,3,0,
58,10,156,4,0,
58,10,164,4,0,
58,10,168,4,0,
58,10,172,1,20,
58,10,173,3,0,
58,10,179,1,25,
58,10,182,4,0,
58,10,189,3,0,
58,10,203,4,0,
58,10,207,4,0,
58,10,213,4,0,
58,10,214,4,0,
58,10,216,4,0,
58,10,218,4,0,
58,10,219,2,0,
58,10,231,4,0,
58,10,234,2,0,
58,10,237,4,0,
58,10,241,4,0,
58,10,242,1,42,
58,10,242,2,0,
58,10,249,4,0,
58,10,257,1,45,
58,10,257,2,0,
58,10,257,3,0,
58,10,261,4,0,
58,10,263,4,0,
58,10,270,1,17,
58,10,270,3,0,
58,10,290,4,0,
58,10,315,4,0,
58,10,316,1,14,
58,10,332,4,0,
58,10,336,2,0,
58,10,363,4,0,
58,10,394,1,48,
58,10,394,2,0,
58,10,424,1,28,
58,10,445,4,0,
58,11,24,2,0,
58,11,34,2,0,
58,11,36,1,34,
58,11,37,2,0,
58,11,38,2,0,
58,11,43,1,9,
58,11,44,1,1,1
58,11,46,1,1,2
58,11,46,4,0,
58,11,52,1,6,
58,11,53,1,39,
58,11,53,4,0,
58,11,70,4,0,
58,11,83,2,0,
58,11,91,4,0,
58,11,92,4,0,
58,11,97,1,42,
58,11,104,4,0,
58,11,126,4,0,
58,11,156,4,0,
58,11,164,4,0,
58,11,168,4,0,
58,11,172,1,20,
58,11,179,1,25,
58,11,182,4,0,
58,11,207,4,0,
58,11,213,4,0,
58,11,216,4,0,
58,11,218,4,0,
58,11,219,4,0,
58,11,231,2,0,
58,11,234,2,0,
58,11,237,4,0,
58,11,241,4,0,
58,11,242,1,45,
58,11,242,2,0,
58,11,249,4,0,
58,11,257,1,51,
58,11,257,2,0,
58,11,261,4,0,
58,11,263,4,0,
58,11,270,1,17,
58,11,315,4,0,
58,11,316,1,14,
58,11,332,4,0,
58,11,336,2,0,
58,11,343,2,0,
58,11,370,2,0,
58,11,394,1,56,
58,11,394,2,0,
58,11,424,1,28,
58,11,481,1,31,
58,11,488,4,0,
58,11,496,4,0,
58,11,510,4,0,
58,11,514,1,48,
58,11,514,4,0,
58,11,528,4,0,
58,11,555,4,0,
58,12,36,1,25,
58,12,43,1,13,
58,12,44,1,1,1
58,12,46,1,1,2
58,12,46,4,0,
58,12,52,1,7,
58,12,53,1,49,
58,12,53,4,0,
58,12,70,4,0,
58,12,91,4,0,
58,12,92,4,0,
58,12,97,1,43,
58,12,104,4,0,
58,12,126,4,0,
58,12,156,4,0,
58,12,168,4,0,
58,12,172,1,31,
58,12,182,4,0,
58,12,213,4,0,
58,12,216,4,0,
58,12,218,4,0,
58,12,231,4,0,
58,12,237,4,0,
58,12,241,4,0,
58,12,249,4,0,
58,12,263,4,0,
58,12,270,1,37,
58,12,290,4,0,
58,12,315,4,0,
58,12,316,1,19,
58,12,332,4,0,
58,13,34,3,0,
58,13,36,1,25,
58,13,38,3,0,
58,13,43,1,13,
58,13,44,1,1,1
58,13,46,1,1,2
58,13,46,4,0,
58,13,52,1,7,
58,13,53,1,49,
58,13,53,4,0,
58,13,70,4,0,
58,13,91,4,0,
58,13,92,4,0,
58,13,97,1,43,
58,13,102,3,0,
58,13,104,4,0,
58,13,126,4,0,
58,13,156,4,0,
58,13,164,3,0,
58,13,168,4,0,
58,13,172,1,31,
58,13,182,4,0,
58,13,207,3,0,
58,13,213,4,0,
58,13,216,4,0,
58,13,218,4,0,
58,13,231,4,0,
58,13,237,4,0,
58,13,241,4,0,
58,13,249,4,0,
58,13,263,4,0,
58,13,270,1,37,
58,13,290,4,0,
58,13,315,4,0,
58,13,316,1,19,
58,13,332,4,0,
58,14,24,2,0,
58,14,34,2,0,
58,14,36,1,23,
58,14,37,2,0,
58,14,38,2,0,
58,14,43,1,8,
58,14,44,1,1,1
58,14,46,1,1,2
58,14,46,4,0,
58,14,52,1,6,
58,14,53,1,34,
58,14,53,4,0,
58,14,70,4,0,
58,14,83,2,0,
58,14,91,4,0,
58,14,92,4,0,
58,14,97,1,30,
58,14,104,4,0,
58,14,126,4,0,
58,14,156,4,0,
58,14,164,4,0,
58,14,168,4,0,
58,14,172,1,17,
58,14,173,3,0,
58,14,179,1,19,
58,14,182,4,0,
58,14,200,1,43,
58,14,200,3,0,
58,14,207,4,0,
58,14,213,4,0,
58,14,214,3,0,
58,14,216,4,0,
58,14,218,4,0,
58,14,219,4,0,
58,14,231,2,0,
58,14,231,3,0,
58,14,234,2,0,
58,14,237,4,0,
58,14,241,4,0,
58,14,242,1,39,
58,14,242,2,0,
58,14,249,4,0,
58,14,257,1,41,
58,14,257,2,0,
58,14,257,3,0,
58,14,261,4,0,
58,14,263,4,0,
58,14,270,1,12,
58,14,270,3,0,
58,14,315,4,0,
58,14,316,1,10,
58,14,332,4,0,
58,14,336,2,0,
58,14,343,2,0,
58,14,343,3,0,
58,14,370,2,0,
58,14,394,1,45,
58,14,394,2,0,
58,14,424,1,21,
58,14,481,1,28,
58,14,488,4,0,
58,14,496,4,0,
58,14,510,4,0,
58,14,514,1,32,
58,14,514,4,0,
58,14,528,4,0,
58,14,555,4,0,
58,15,24,2,0,
58,15,34,2,0,
58,15,36,1,23,
58,15,37,2,0,
58,15,38,2,0,
58,15,43,1,8,
58,15,44,1,1,1
58,15,46,1,1,2
58,15,46,4,0,
58,15,52,1,6,
58,15,53,1,34,
58,15,53,4,0,
58,15,70,4,0,
58,15,83,2,0,
58,15,91,4,0,
58,15,92,4,0,
58,15,97,1,30,
58,15,104,4,0,
58,15,126,4,0,
58,15,156,4,0,
58,15,164,4,0,
58,15,168,4,0,
58,15,172,1,17,
58,15,179,1,19,
58,15,182,4,0,
58,15,200,1,43,
58,15,207,4,0,
58,15,213,4,0,
58,15,214,4,0,
58,15,216,4,0,
58,15,218,4,0,
58,15,219,4,0,
58,15,231,2,0,
58,15,234,2,0,
58,15,237,4,0,
58,15,241,4,0,
58,15,242,1,39,
58,15,242,2,0,
58,15,249,4,0,
58,15,257,1,41,
58,15,257,2,0,
58,15,261,4,0,
58,15,263,4,0,
58,15,270,1,12,
58,15,315,4,0,
58,15,316,1,10,
58,15,332,4,0,
58,15,336,2,0,
58,15,343,2,0,
58,15,370,2,0,
58,15,394,1,45,
58,15,394,2,0,
58,15,424,1,21,
58,15,481,1,28,
58,15,488,4,0,
58,15,496,4,0,
58,15,510,4,0,
58,15,514,1,32,
58,15,514,4,0,
58,15,528,4,0,
58,15,555,4,0,
58,15,590,4,0,
58,16,24,2,0,
58,16,34,2,0,
58,16,36,1,23,1
58,16,37,2,0,
58,16,38,2,0,
58,16,43,1,8,1
58,16,44,1,1,1
58,16,46,1,1,2
58,16,46,4,0,
58,16,52,1,6,1
58,16,53,1,34,1
58,16,53,4,0,
58,16,70,4,0,
58,16,83,2,0,
58,16,91,4,0,
58,16,92,4,0,
58,16,97,1,30,1
58,16,104,4,0,
58,16,126,4,0,
58,16,156,4,0,
58,16,164,4,0,
58,16,168,4,0,
58,16,172,1,17,1
58,16,173,3,0,
58,16,179,1,19,1
58,16,182,4,0,
58,16,200,1,43,1
58,16,200,3,0,
58,16,207,4,0,
58,16,213,4,0,
58,16,214,4,0,
58,16,216,4,0,
58,16,218,4,0,
58,16,219,4,0,
58,16,231,2,0,
58,16,231,3,0,
58,16,234,2,0,
58,16,237,4,0,
58,16,241,4,0,
58,16,242,1,39,1
58,16,242,2,0,
58,16,249,4,0,
58,16,257,1,41,1
58,16,257,2,0,
58,16,257,3,0,
58,16,261,4,0,
58,16,263,4,0,
58,16,270,1,12,1
58,16,270,3,0,
58,16,290,4,0,
58,16,315,4,0,
58,16,316,1,10,1
58,16,332,4,0,
58,16,336,2,0,
58,16,343,2,0,
58,16,343,3,0,
58,16,370,2,0,
58,16,394,1,45,1
58,16,394,2,0,
58,16,424,1,21,1
58,16,481,1,28,1
58,16,488,4,0,
58,16,496,4,0,
58,16,510,4,0,
58,16,514,1,32,1
58,16,514,4,0,
58,16,528,4,0,
58,16,555,4,0,
58,16,590,4,0,
58,17,24,2,0,
58,17,34,2,0,
58,17,36,1,23,
58,17,37,2,0,
58,17,38,2,0,
58,17,43,1,8,
58,17,44,1,1,1
58,17,46,1,1,2
58,17,46,4,0,
58,17,52,1,6,
58,17,53,1,34,
58,17,53,4,0,
58,17,83,2,0,
58,17,92,4,0,
58,17,97,1,30,
58,17,104,4,0,
58,17,126,4,0,
58,17,156,4,0,
58,17,164,4,0,
58,17,168,4,0,
58,17,172,1,17,
58,17,179,1,19,
58,17,182,4,0,
58,17,200,1,43,
58,17,207,4,0,
58,17,213,4,0,
58,17,214,4,0,
58,17,216,4,0,
58,17,218,4,0,
58,17,219,4,0,
58,17,231,2,0,
58,17,234,2,0,
58,17,237,4,0,
58,17,241,4,0,
58,17,242,1,39,
58,17,242,2,0,
58,17,257,1,41,
58,17,257,2,0,
58,17,261,4,0,
58,17,263,4,0,
58,17,270,1,12,
58,17,315,4,0,
58,17,316,1,10,
58,17,332,4,0,
58,17,336,2,0,
58,17,343,2,0,
58,17,370,2,0,
58,17,394,1,45,
58,17,394,2,0,
58,17,424,1,21,
58,17,481,1,28,
58,17,488,4,0,
58,17,496,4,0,
58,17,514,1,32,
58,17,528,4,0,
58,17,555,4,0,
58,17,590,4,0,
58,17,682,2,0,
58,18,24,2,0,
58,18,34,2,0,
58,18,36,1,23,
58,18,37,2,0,
58,18,38,2,0,
58,18,43,1,8,
58,18,44,1,1,1
58,18,46,1,1,2
58,18,46,4,0,
58,18,52,1,6,
58,18,53,1,34,
58,18,53,4,0,
58,18,83,2,0,
58,18,92,4,0,
58,18,97,1,30,
58,18,104,4,0,
58,18,126,4,0,
58,18,156,4,0,
58,18,164,4,0,
58,18,168,4,0,
58,18,172,1,17,
58,18,179,1,19,
58,18,182,4,0,
58,18,200,1,43,
58,18,207,4,0,
58,18,213,4,0,
58,18,214,4,0,
58,18,216,4,0,
58,18,218,4,0,
58,18,219,4,0,
58,18,231,2,0,
58,18,234,2,0,
58,18,237,4,0,
58,18,241,4,0,
58,18,242,1,39,
58,18,242,2,0,
58,18,257,1,41,
58,18,257,2,0,
58,18,261,4,0,
58,18,263,4,0,
58,18,270,1,12,
58,18,315,4,0,
58,18,316,1,10,
58,18,332,4,0,
58,18,336,2,0,
58,18,343,2,0,
58,18,370,2,0,
58,18,394,1,45,
58,18,394,2,0,
58,18,424,1,21,
58,18,481,1,28,
58,18,488,4,0,
58,18,496,4,0,
58,18,514,1,32,
58,18,528,4,0,
58,18,555,4,0,
58,18,590,4,0,
58,18,682,2,0,
59,1,34,4,0,
59,1,36,1,1,4
59,1,36,4,0,
59,1,38,4,0,
59,1,43,1,1,3
59,1,46,1,1,1
59,1,52,1,1,2
59,1,63,4,0,
59,1,82,4,0,
59,1,91,4,0,
59,1,92,4,0,
59,1,99,4,0,
59,1,100,4,0,
59,1,102,4,0,
59,1,104,4,0,
59,1,115,4,0,
59,1,117,4,0,
59,1,126,4,0,
59,1,129,4,0,
59,1,130,4,0,
59,1,156,4,0,
59,1,164,4,0,
59,2,34,4,0,
59,2,36,1,1,4
59,2,36,4,0,
59,2,38,4,0,
59,2,43,1,1,3
59,2,46,1,1,1
59,2,52,1,1,2
59,2,63,4,0,
59,2,82,4,0,
59,2,91,4,0,
59,2,92,4,0,
59,2,99,4,0,
59,2,100,4,0,
59,2,102,4,0,
59,2,104,4,0,
59,2,115,4,0,
59,2,117,4,0,
59,2,126,4,0,
59,2,129,4,0,
59,2,130,4,0,
59,2,156,4,0,
59,2,164,4,0,
59,3,29,4,0,
59,3,36,1,1,3
59,3,43,1,1,2
59,3,46,1,1,1
59,3,46,4,0,
59,3,63,4,0,
59,3,91,4,0,
59,3,92,4,0,
59,3,104,4,0,
59,3,126,4,0,
59,3,129,4,0,
59,3,156,4,0,
59,3,172,1,1,4
59,3,173,4,0,
59,3,174,4,0,
59,3,182,4,0,
59,3,203,4,0,
59,3,207,4,0,
59,3,213,4,0,
59,3,214,4,0,
59,3,216,4,0,
59,3,218,4,0,
59,3,225,4,0,
59,3,231,4,0,
59,3,237,4,0,
59,3,241,4,0,
59,3,245,1,50,
59,3,249,4,0,
59,4,29,4,0,
59,4,36,1,1,3
59,4,43,1,1,2
59,4,46,1,1,1
59,4,46,4,0,
59,4,53,3,0,
59,4,63,4,0,
59,4,91,4,0,
59,4,92,4,0,
59,4,104,4,0,
59,4,126,4,0,
59,4,129,4,0,
59,4,156,4,0,
59,4,172,1,1,4
59,4,173,4,0,
59,4,174,4,0,
59,4,182,4,0,
59,4,203,4,0,
59,4,207,4,0,
59,4,213,4,0,
59,4,214,4,0,
59,4,216,4,0,
59,4,218,4,0,
59,4,225,4,0,
59,4,231,4,0,
59,4,237,4,0,
59,4,241,4,0,
59,4,245,1,50,
59,4,249,4,0,
59,5,44,1,1,1
59,5,46,1,1,2
59,5,46,4,0,
59,5,52,1,1,3
59,5,53,4,0,
59,5,63,4,0,
59,5,70,4,0,
59,5,91,4,0,
59,5,92,4,0,
59,5,104,4,0,
59,5,126,4,0,
59,5,156,4,0,
59,5,168,4,0,
59,5,182,4,0,
59,5,213,4,0,
59,5,216,4,0,
59,5,218,4,0,
59,5,231,4,0,
59,5,237,4,0,
59,5,241,4,0,
59,5,245,1,49,
59,5,249,4,0,
59,5,263,4,0,
59,5,290,4,0,
59,5,315,4,0,
59,5,316,1,1,4
59,5,332,4,0,
59,6,34,3,0,
59,6,38,3,0,
59,6,44,1,1,1
59,6,46,1,1,2
59,6,46,4,0,
59,6,52,1,1,3
59,6,53,4,0,
59,6,63,4,0,
59,6,70,4,0,
59,6,91,4,0,
59,6,92,4,0,
59,6,102,3,0,
59,6,104,4,0,
59,6,126,4,0,
59,6,129,3,0,
59,6,156,4,0,
59,6,164,3,0,
59,6,168,4,0,
59,6,173,3,0,
59,6,182,4,0,
59,6,203,3,0,
59,6,207,3,0,
59,6,213,4,0,
59,6,214,3,0,
59,6,216,4,0,
59,6,218,4,0,
59,6,231,4,0,
59,6,237,4,0,
59,6,241,4,0,
59,6,245,1,49,
59,6,249,4,0,
59,6,263,4,0,
59,6,290,4,0,
59,6,315,4,0,
59,6,316,1,1,4
59,6,332,4,0,
59,7,34,3,0,
59,7,38,3,0,
59,7,44,1,1,1
59,7,46,1,1,2
59,7,46,4,0,
59,7,52,1,1,3
59,7,53,4,0,
59,7,63,4,0,
59,7,70,4,0,
59,7,91,4,0,
59,7,92,4,0,
59,7,102,3,0,
59,7,104,4,0,
59,7,126,4,0,
59,7,156,4,0,
59,7,164,3,0,
59,7,168,4,0,
59,7,182,4,0,
59,7,213,4,0,
59,7,216,4,0,
59,7,218,4,0,
59,7,231,4,0,
59,7,237,4,0,
59,7,241,4,0,
59,7,245,1,49,
59,7,249,4,0,
59,7,263,4,0,
59,7,290,4,0,
59,7,315,4,0,
59,7,316,1,1,4
59,7,332,4,0,
59,8,44,1,1,2
59,8,46,1,1,3
59,8,46,4,0,
59,8,53,4,0,
59,8,63,4,0,
59,8,70,4,0,
59,8,76,4,0,
59,8,91,4,0,
59,8,92,4,0,
59,8,104,4,0,
59,8,126,4,0,
59,8,156,4,0,
59,8,164,4,0,
59,8,168,4,0,
59,8,182,4,0,
59,8,203,4,0,
59,8,207,4,0,
59,8,213,4,0,
59,8,214,4,0,
59,8,216,4,0,
59,8,218,4,0,
59,8,231,4,0,
59,8,237,4,0,
59,8,241,4,0,
59,8,245,1,39,
59,8,249,4,0,
59,8,261,4,0,
59,8,263,4,0,
59,8,290,4,0,
59,8,315,4,0,
59,8,316,1,1,5
59,8,332,4,0,
59,8,363,4,0,
59,8,406,4,0,
59,8,416,4,0,
59,8,422,1,1,1
59,8,424,1,1,4
59,8,431,4,0,
59,8,445,4,0,
59,9,44,1,1,2
59,9,46,1,1,3
59,9,46,4,0,
59,9,53,4,0,
59,9,63,4,0,
59,9,70,4,0,
59,9,76,4,0,
59,9,91,4,0,
59,9,92,4,0,
59,9,104,4,0,
59,9,126,4,0,
59,9,129,3,0,
59,9,156,4,0,
59,9,164,4,0,
59,9,168,4,0,
59,9,173,3,0,
59,9,182,4,0,
59,9,189,3,0,
59,9,203,4,0,
59,9,207,4,0,
59,9,213,4,0,
59,9,214,4,0,
59,9,216,4,0,
59,9,218,4,0,
59,9,231,4,0,
59,9,237,4,0,
59,9,241,4,0,
59,9,245,1,39,
59,9,249,4,0,
59,9,257,3,0,
59,9,261,4,0,
59,9,263,4,0,
59,9,270,3,0,
59,9,290,4,0,
59,9,315,4,0,
59,9,316,1,1,5
59,9,332,4,0,
59,9,363,4,0,
59,9,406,4,0,
59,9,416,4,0,
59,9,422,1,1,1
59,9,424,1,1,4
59,9,431,4,0,
59,9,442,3,0,
59,9,445,4,0,
59,10,29,3,0,
59,10,44,1,1,2
59,10,46,1,1,3
59,10,46,4,0,
59,10,53,4,0,
59,10,63,4,0,
59,10,70,4,0,
59,10,76,4,0,
59,10,91,4,0,
59,10,92,4,0,
59,10,104,4,0,
59,10,126,4,0,
59,10,129,3,0,
59,10,156,4,0,
59,10,164,4,0,
59,10,168,4,0,
59,10,173,3,0,
59,10,182,4,0,
59,10,189,3,0,
59,10,203,4,0,
59,10,207,4,0,
59,10,213,4,0,
59,10,214,4,0,
59,10,216,4,0,
59,10,218,4,0,
59,10,231,4,0,
59,10,237,4,0,
59,10,241,4,0,
59,10,245,1,39,
59,10,249,4,0,
59,10,257,3,0,
59,10,261,4,0,
59,10,263,4,0,
59,10,270,3,0,
59,10,290,4,0,
59,10,315,4,0,
59,10,316,1,1,5
59,10,332,4,0,
59,10,363,4,0,
59,10,406,4,0,
59,10,416,4,0,
59,10,422,1,1,1
59,10,424,1,1,4
59,10,431,4,0,
59,10,442,3,0,
59,10,445,4,0,
59,11,44,1,1,2
59,11,46,1,1,3
59,11,46,4,0,
59,11,53,4,0,
59,11,63,4,0,
59,11,70,4,0,
59,11,76,4,0,
59,11,91,4,0,
59,11,92,4,0,
59,11,104,4,0,
59,11,126,4,0,
59,11,156,4,0,
59,11,164,4,0,
59,11,168,4,0,
59,11,182,4,0,
59,11,207,4,0,
59,11,213,4,0,
59,11,216,4,0,
59,11,218,4,0,
59,11,219,4,0,
59,11,237,4,0,
59,11,241,4,0,
59,11,245,1,39,
59,11,249,4,0,
59,11,261,4,0,
59,11,263,4,0,
59,11,315,4,0,
59,11,316,1,1,5
59,11,332,4,0,
59,11,416,4,0,
59,11,422,1,1,1
59,11,424,1,1,4
59,11,488,4,0,
59,11,496,4,0,
59,11,510,4,0,
59,11,514,4,0,
59,11,523,4,0,
59,11,528,4,0,
59,11,555,4,0,
59,12,44,1,1,1
59,12,46,1,1,2
59,12,46,4,0,
59,12,52,1,1,3
59,12,53,4,0,
59,12,63,4,0,
59,12,70,4,0,
59,12,91,4,0,
59,12,92,4,0,
59,12,104,4,0,
59,12,126,4,0,
59,12,156,4,0,
59,12,168,4,0,
59,12,182,4,0,
59,12,213,4,0,
59,12,216,4,0,
59,12,218,4,0,
59,12,231,4,0,
59,12,237,4,0,
59,12,241,4,0,
59,12,245,1,49,
59,12,249,4,0,
59,12,263,4,0,
59,12,290,4,0,
59,12,315,4,0,
59,12,316,1,1,4
59,12,332,4,0,
59,13,34,3,0,
59,13,38,3,0,
59,13,44,1,1,1
59,13,46,1,1,2
59,13,46,4,0,
59,13,52,1,1,3
59,13,53,4,0,
59,13,63,4,0,
59,13,70,4,0,
59,13,91,4,0,
59,13,92,4,0,
59,13,102,3,0,
59,13,104,4,0,
59,13,126,4,0,
59,13,156,4,0,
59,13,164,3,0,
59,13,168,4,0,
59,13,182,4,0,
59,13,207,3,0,
59,13,213,4,0,
59,13,216,4,0,
59,13,218,4,0,
59,13,231,4,0,
59,13,237,4,0,
59,13,241,4,0,
59,13,245,1,49,
59,13,249,4,0,
59,13,263,4,0,
59,13,290,4,0,
59,13,315,4,0,
59,13,316,1,1,4
59,13,332,4,0,
59,14,44,1,1,2
59,14,46,1,1,3
59,14,46,4,0,
59,14,53,4,0,
59,14,63,4,0,
59,14,70,4,0,
59,14,76,4,0,
59,14,91,4,0,
59,14,92,4,0,
59,14,104,4,0,
59,14,126,4,0,
59,14,156,4,0,
59,14,164,4,0,
59,14,168,4,0,
59,14,173,3,0,
59,14,182,4,0,
59,14,200,3,0,
59,14,207,4,0,
59,14,213,4,0,
59,14,214,3,0,
59,14,216,4,0,
59,14,218,4,0,
59,14,219,4,0,
59,14,231,3,0,
59,14,237,4,0,
59,14,241,4,0,
59,14,245,1,34,
59,14,249,4,0,
59,14,257,3,0,
59,14,261,4,0,
59,14,263,4,0,
59,14,270,3,0,
59,14,315,4,0,
59,14,316,1,1,4
59,14,332,4,0,
59,14,343,3,0,
59,14,406,3,0,
59,14,416,4,0,
59,14,422,1,1,1
59,14,424,1,1,5
59,14,442,3,0,
59,14,488,4,0,
59,14,496,4,0,
59,14,510,4,0,
59,14,514,4,0,
59,14,523,4,0,
59,14,528,4,0,
59,14,555,4,0,
59,15,44,1,1,2
59,15,46,1,1,3
59,15,46,4,0,
59,15,53,4,0,
59,15,63,4,0,
59,15,70,4,0,
59,15,76,4,0,
59,15,91,4,0,
59,15,92,4,0,
59,15,104,4,0,
59,15,126,4,0,
59,15,156,4,0,
59,15,164,4,0,
59,15,168,4,0,
59,15,182,4,0,
59,15,207,4,0,
59,15,213,4,0,
59,15,214,4,0,
59,15,216,4,0,
59,15,218,4,0,
59,15,219,4,0,
59,15,237,4,0,
59,15,241,4,0,
59,15,245,1,34,
59,15,249,4,0,
59,15,261,4,0,
59,15,263,4,0,
59,15,315,4,0,
59,15,316,1,1,4
59,15,332,4,0,
59,15,416,4,0,
59,15,422,1,1,1
59,15,424,1,1,5
59,15,488,4,0,
59,15,496,4,0,
59,15,510,4,0,
59,15,514,4,0,
59,15,523,4,0,
59,15,528,4,0,
59,15,555,4,0,
59,15,590,4,0,
59,16,44,1,1,2
59,16,46,1,1,3
59,16,46,4,0,
59,16,53,4,0,
59,16,63,4,0,
59,16,70,4,0,
59,16,76,4,0,
59,16,91,4,0,
59,16,92,4,0,
59,16,104,4,0,
59,16,126,4,0,
59,16,156,4,0,
59,16,164,4,0,
59,16,168,4,0,
59,16,173,3,0,
59,16,182,4,0,
59,16,200,3,0,
59,16,207,4,0,
59,16,213,4,0,
59,16,214,4,0,
59,16,216,4,0,
59,16,218,4,0,
59,16,219,4,0,
59,16,231,3,0,
59,16,237,4,0,
59,16,241,4,0,
59,16,245,1,34,1
59,16,249,4,0,
59,16,257,3,0,
59,16,261,4,0,
59,16,263,4,0,
59,16,270,3,0,
59,16,290,4,0,
59,16,315,4,0,
59,16,316,1,1,4
59,16,332,4,0,
59,16,343,3,0,
59,16,406,3,0,
59,16,416,4,0,
59,16,422,1,1,1
59,16,424,1,1,5
59,16,442,3,0,
59,16,488,4,0,
59,16,496,4,0,
59,16,510,4,0,
59,16,514,4,0,
59,16,523,4,0,
59,16,528,4,0,
59,16,555,4,0,
59,16,590,4,0,
59,17,44,1,1,2
59,17,46,1,1,3
59,17,46,4,0,
59,17,53,4,0,
59,17,63,4,0,
59,17,76,4,0,
59,17,92,4,0,
59,17,104,4,0,
59,17,126,4,0,
59,17,156,4,0,
59,17,164,4,0,
59,17,168,4,0,
59,17,182,4,0,
59,17,207,4,0,
59,17,213,4,0,
59,17,214,4,0,
59,17,216,4,0,
59,17,218,4,0,
59,17,219,4,0,
59,17,237,4,0,
59,17,241,4,0,
59,17,245,1,34,
59,17,261,4,0,
59,17,263,4,0,
59,17,315,4,0,
59,17,316,1,1,4
59,17,332,4,0,
59,17,416,4,0,
59,17,422,1,1,1
59,17,424,1,1,5
59,17,488,4,0,
59,17,496,4,0,
59,17,523,4,0,
59,17,528,4,0,
59,17,555,4,0,
59,17,590,4,0,
59,18,44,1,1,2
59,18,46,1,1,3
59,18,46,4,0,
59,18,53,4,0,
59,18,63,4,0,
59,18,76,4,0,
59,18,92,4,0,
59,18,104,4,0,
59,18,126,4,0,
59,18,156,4,0,
59,18,164,4,0,
59,18,168,4,0,
59,18,182,4,0,
59,18,207,4,0,
59,18,213,4,0,
59,18,214,4,0,
59,18,216,4,0,
59,18,218,4,0,
59,18,219,4,0,
59,18,237,4,0,
59,18,241,4,0,
59,18,245,1,34,
59,18,261,4,0,
59,18,263,4,0,
59,18,315,4,0,
59,18,316,1,1,4
59,18,332,4,0,
59,18,416,4,0,
59,18,422,1,1,1
59,18,424,1,1,5
59,18,488,4,0,
59,18,496,4,0,
59,18,523,4,0,
59,18,528,4,0,
59,18,555,4,0,
59,18,590,4,0,
60,1,3,1,25,
60,1,34,1,31,
60,1,34,4,0,
60,1,36,4,0,
60,1,38,4,0,
60,1,55,1,19,
60,1,55,4,0,
60,1,56,1,45,
60,1,57,4,0,
60,1,58,4,0,
60,1,59,4,0,
60,1,61,4,0,
60,1,92,4,0,
60,1,94,4,0,
60,1,95,1,16,
60,1,99,4,0,
60,1,102,4,0,
60,1,104,4,0,
60,1,117,4,0,
60,1,130,4,0,
60,1,133,1,38,
60,1,145,1,1,
60,1,149,4,0,
60,1,156,4,0,
60,1,164,4,0,
60,2,3,1,25,
60,2,34,1,31,
60,2,34,4,0,
60,2,36,4,0,
60,2,38,4,0,
60,2,55,1,19,
60,2,55,4,0,
60,2,56,1,45,
60,2,57,4,0,
60,2,58,4,0,
60,2,59,4,0,
60,2,61,4,0,
60,2,92,4,0,
60,2,94,4,0,
60,2,95,1,16,
60,2,99,4,0,
60,2,102,4,0,
60,2,104,4,0,
60,2,117,4,0,
60,2,130,4,0,
60,2,133,1,38,
60,2,145,1,1,
60,2,149,4,0,
60,2,156,4,0,
60,2,164,4,0,
60,3,3,1,19,
60,3,29,4,0,
60,3,34,1,31,
60,3,54,2,0,
60,3,55,1,13,
60,3,56,1,43,
60,3,57,4,0,
60,3,59,4,0,
60,3,61,2,0,
60,3,92,4,0,
60,3,94,4,0,
60,3,95,1,7,
60,3,104,4,0,
60,3,111,4,0,
60,3,114,2,0,
60,3,127,4,0,
60,3,145,1,1,
60,3,150,2,0,
60,3,156,4,0,
60,3,168,4,0,
60,3,170,2,0,
60,3,173,4,0,
60,3,174,4,0,
60,3,182,4,0,
60,3,187,1,37,
60,3,196,4,0,
60,3,203,4,0,
60,3,207,4,0,
60,3,213,4,0,
60,3,214,4,0,
60,3,216,4,0,
60,3,218,4,0,
60,3,237,4,0,
60,3,240,1,25,
60,3,240,4,0,
60,3,250,4,0,
60,4,3,1,19,
60,4,29,4,0,
60,4,34,1,31,
60,4,54,2,0,
60,4,55,1,13,
60,4,56,1,43,
60,4,57,4,0,
60,4,58,3,0,
60,4,59,4,0,
60,4,61,2,0,
60,4,92,4,0,
60,4,94,4,0,
60,4,95,1,7,
60,4,104,4,0,
60,4,111,4,0,
60,4,114,2,0,
60,4,127,4,0,
60,4,145,1,1,
60,4,150,2,0,
60,4,156,4,0,
60,4,168,4,0,
60,4,170,2,0,
60,4,173,4,0,
60,4,174,4,0,
60,4,182,4,0,
60,4,187,1,37,
60,4,196,4,0,
60,4,203,4,0,
60,4,207,4,0,
60,4,213,4,0,
60,4,214,4,0,
60,4,216,4,0,
60,4,218,4,0,
60,4,237,4,0,
60,4,240,1,25,
60,4,240,4,0,
60,4,250,4,0,
60,5,3,1,19,
60,5,34,1,31,
60,5,54,2,0,
60,5,55,1,13,
60,5,56,1,43,
60,5,57,4,0,
60,5,58,4,0,
60,5,59,4,0,
60,5,61,2,0,
60,5,91,4,0,
60,5,92,4,0,
60,5,94,4,0,
60,5,95,1,7,
60,5,104,4,0,
60,5,114,2,0,
60,5,127,4,0,
60,5,145,1,1,
60,5,150,2,0,
60,5,156,4,0,
60,5,168,4,0,
60,5,170,2,0,
60,5,182,4,0,
60,5,187,1,37,
60,5,213,4,0,
60,5,216,4,0,
60,5,218,4,0,
60,5,237,4,0,
60,5,240,1,25,
60,5,240,4,0,
60,5,258,4,0,
60,5,263,4,0,
60,5,290,4,0,
60,5,291,4,0,
60,5,301,2,0,
60,5,346,2,0,
60,5,352,4,0,
60,6,3,1,19,
60,6,34,1,31,
60,6,34,3,0,
60,6,38,3,0,
60,6,54,2,0,
60,6,55,1,13,
60,6,56,1,43,
60,6,57,4,0,
60,6,58,4,0,
60,6,59,4,0,
60,6,61,2,0,
60,6,91,4,0,
60,6,92,4,0,
60,6,94,4,0,
60,6,95,1,7,
60,6,102,3,0,
60,6,104,4,0,
60,6,111,3,0,
60,6,114,2,0,
60,6,127,4,0,
60,6,145,1,1,
60,6,150,2,0,
60,6,156,4,0,
60,6,164,3,0,
60,6,168,4,0,
60,6,170,2,0,
60,6,173,3,0,
60,6,182,4,0,
60,6,187,1,37,
60,6,196,3,0,
60,6,203,3,0,
60,6,207,3,0,
60,6,213,4,0,
60,6,214,3,0,
60,6,216,4,0,
60,6,218,4,0,
60,6,237,4,0,
60,6,240,1,25,
60,6,240,4,0,
60,6,258,4,0,
60,6,263,4,0,
60,6,290,4,0,
60,6,291,4,0,
60,6,301,2,0,
60,6,346,2,0,
60,6,352,4,0,
60,7,3,1,19,
60,7,34,1,31,
60,7,34,3,0,
60,7,38,3,0,
60,7,54,2,0,
60,7,55,1,13,
60,7,56,1,43,
60,7,57,4,0,
60,7,58,4,0,
60,7,59,4,0,
60,7,61,2,0,
60,7,91,4,0,
60,7,92,4,0,
60,7,94,4,0,
60,7,95,1,7,
60,7,102,3,0,
60,7,104,4,0,
60,7,114,2,0,
60,7,127,4,0,
60,7,145,1,1,
60,7,150,2,0,
60,7,156,4,0,
60,7,164,3,0,
60,7,168,4,0,
60,7,170,2,0,
60,7,182,4,0,
60,7,187,1,37,
60,7,213,4,0,
60,7,216,4,0,
60,7,218,4,0,
60,7,237,4,0,
60,7,240,1,25,
60,7,240,4,0,
60,7,258,4,0,
60,7,263,4,0,
60,7,290,4,0,
60,7,291,4,0,
60,7,301,2,0,
60,7,346,2,0,
60,7,352,4,0,
60,8,3,1,15,
60,8,34,1,21,
60,8,54,2,0,
60,8,55,1,11,
60,8,56,1,38,
60,8,57,4,0,
60,8,58,4,0,
60,8,59,4,0,
60,8,61,1,25,
60,8,61,2,0,
60,8,91,4,0,
60,8,92,4,0,
60,8,94,4,0,
60,8,95,1,8,
60,8,104,4,0,
60,8,114,2,0,
60,8,127,4,0,
60,8,145,1,5,
60,8,150,2,0,
60,8,156,4,0,
60,8,164,4,0,
60,8,168,4,0,
60,8,170,2,0,
60,8,182,4,0,
60,8,187,1,31,
60,8,203,4,0,
60,8,207,4,0,
60,8,213,4,0,
60,8,214,4,0,
60,8,216,4,0,
60,8,218,4,0,
60,8,237,4,0,
60,8,240,1,18,
60,8,240,4,0,
60,8,258,4,0,
60,8,263,4,0,
60,8,283,2,0,
60,8,287,2,0,
60,8,290,4,0,
60,8,301,2,0,
60,8,341,1,28,
60,8,341,2,0,
60,8,346,1,1,
60,8,346,2,0,
60,8,352,4,0,
60,8,358,1,35,
60,8,363,4,0,
60,8,426,1,41,
60,8,445,4,0,
60,9,3,1,15,
60,9,34,1,21,
60,9,54,2,0,
60,9,55,1,11,
60,9,56,1,38,
60,9,57,4,0,
60,9,58,4,0,
60,9,59,4,0,
60,9,61,1,25,
60,9,61,2,0,
60,9,91,4,0,
60,9,92,4,0,
60,9,94,4,0,
60,9,95,1,8,
60,9,104,4,0,
60,9,114,2,0,
60,9,127,4,0,
60,9,145,1,5,
60,9,150,2,0,
60,9,156,4,0,
60,9,164,4,0,
60,9,168,4,0,
60,9,170,2,0,
60,9,173,3,0,
60,9,182,4,0,
60,9,187,1,31,
60,9,196,3,0,
60,9,203,4,0,
60,9,207,4,0,
60,9,213,4,0,
60,9,214,4,0,
60,9,216,4,0,
60,9,218,4,0,
60,9,237,4,0,
60,9,240,1,18,
60,9,240,4,0,
60,9,258,4,0,
60,9,263,4,0,
60,9,270,3,0,
60,9,283,2,0,
60,9,287,2,0,
60,9,290,4,0,
60,9,291,3,0,
60,9,301,2,0,
60,9,341,1,28,
60,9,341,2,0,
60,9,346,1,1,
60,9,346,2,0,
60,9,352,4,0,
60,9,358,1,35,
60,9,363,4,0,
60,9,426,1,41,
60,9,445,4,0,
60,10,3,1,15,
60,10,29,3,0,
60,10,34,1,21,
60,10,54,2,0,
60,10,55,1,11,
60,10,56,1,38,
60,10,57,4,0,
60,10,58,4,0,
60,10,59,4,0,
60,10,61,1,25,
60,10,61,2,0,
60,10,91,4,0,
60,10,92,4,0,
60,10,94,4,0,
60,10,95,1,8,
60,10,104,4,0,
60,10,114,2,0,
60,10,127,4,0,
60,10,145,1,5,
60,10,150,2,0,
60,10,156,4,0,
60,10,164,4,0,
60,10,168,4,0,
60,10,170,2,0,
60,10,173,3,0,
60,10,182,4,0,
60,10,187,1,31,
60,10,196,3,0,
60,10,203,4,0,
60,10,207,4,0,
60,10,213,4,0,
60,10,214,4,0,
60,10,216,4,0,
60,10,218,4,0,
60,10,227,2,0,
60,10,237,4,0,
60,10,240,1,18,
60,10,240,4,0,
60,10,250,4,0,
60,10,258,4,0,
60,10,263,4,0,
60,10,270,3,0,
60,10,283,2,0,
60,10,287,2,0,
60,10,290,4,0,
60,10,291,3,0,
60,10,301,2,0,
60,10,341,1,28,
60,10,341,2,0,
60,10,346,1,1,
60,10,346,2,0,
60,10,352,4,0,
60,10,358,1,35,
60,10,363,4,0,
60,10,426,1,41,
60,10,445,4,0,
60,11,3,1,15,
60,11,34,1,21,
60,11,54,2,0,
60,11,55,1,11,
60,11,56,1,38,
60,11,57,4,0,
60,11,58,4,0,
60,11,59,4,0,
60,11,61,1,25,
60,11,61,2,0,
60,11,91,4,0,
60,11,92,4,0,
60,11,94,4,0,
60,11,95,1,8,
60,11,104,4,0,
60,11,114,2,0,
60,11,127,4,0,
60,11,145,1,5,
60,11,150,2,0,
60,11,156,4,0,
60,11,164,4,0,
60,11,168,4,0,
60,11,170,2,0,
60,11,182,4,0,
60,11,187,1,31,
60,11,203,2,0,
60,11,207,4,0,
60,11,213,4,0,
60,11,216,4,0,
60,11,218,4,0,
60,11,227,2,0,
60,11,237,4,0,
60,11,240,1,18,
60,11,240,4,0,
60,11,258,4,0,
60,11,263,4,0,
60,11,283,2,0,
60,11,287,2,0,
60,11,291,4,0,
60,11,301,2,0,
60,11,341,1,28,
60,11,341,2,0,
60,11,346,1,1,
60,11,346,2,0,
60,11,352,2,0,
60,11,358,1,35,
60,11,426,1,41,
60,11,496,4,0,
60,11,503,4,0,
60,12,3,1,19,
60,12,34,1,31,
60,12,55,1,13,
60,12,56,1,43,
60,12,57,4,0,
60,12,58,4,0,
60,12,59,4,0,
60,12,91,4,0,
60,12,92,4,0,
60,12,94,4,0,
60,12,95,1,7,
60,12,104,4,0,
60,12,127,4,0,
60,12,145,1,1,
60,12,156,4,0,
60,12,168,4,0,
60,12,182,4,0,
60,12,187,1,37,
60,12,213,4,0,
60,12,216,4,0,
60,12,218,4,0,
60,12,237,4,0,
60,12,240,1,25,
60,12,240,4,0,
60,12,258,4,0,
60,12,263,4,0,
60,12,290,4,0,
60,12,291,4,0,
60,12,352,4,0,
60,13,3,1,19,
60,13,34,1,31,
60,13,34,3,0,
60,13,38,3,0,
60,13,55,1,13,
60,13,56,1,43,
60,13,57,4,0,
60,13,58,4,0,
60,13,59,4,0,
60,13,91,4,0,
60,13,92,4,0,
60,13,94,4,0,
60,13,95,1,7,
60,13,102,3,0,
60,13,104,4,0,
60,13,127,4,0,
60,13,145,1,1,
60,13,156,4,0,
60,13,164,3,0,
60,13,168,4,0,
60,13,182,4,0,
60,13,187,1,37,
60,13,196,3,0,
60,13,207,3,0,
60,13,213,4,0,
60,13,216,4,0,
60,13,218,4,0,
60,13,237,4,0,
60,13,240,1,25,
60,13,240,4,0,
60,13,258,4,0,
60,13,263,4,0,
60,13,290,4,0,
60,13,291,4,0,
60,13,352,4,0,
60,14,3,1,15,
60,14,34,1,21,
60,14,54,2,0,
60,14,55,1,11,
60,14,56,1,38,
60,14,57,4,0,
60,14,58,4,0,
60,14,59,4,0,
60,14,61,1,25,
60,14,61,2,0,
60,14,91,4,0,
60,14,92,4,0,
60,14,94,4,0,
60,14,95,1,8,
60,14,104,4,0,
60,14,114,2,0,
60,14,127,4,0,
60,14,145,1,5,
60,14,150,2,0,
60,14,156,4,0,
60,14,164,4,0,
60,14,168,4,0,
60,14,170,2,0,
60,14,173,3,0,
60,14,182,4,0,
60,14,187,1,31,
60,14,196,3,0,
60,14,203,2,0,
60,14,207,4,0,
60,14,213,4,0,
60,14,214,3,0,
60,14,216,4,0,
60,14,218,4,0,
60,14,227,2,0,
60,14,237,4,0,
60,14,240,1,18,
60,14,240,4,0,
60,14,258,4,0,
60,14,263,4,0,
60,14,270,3,0,
60,14,283,2,0,
60,14,283,3,0,
60,14,287,2,0,
60,14,291,4,0,
60,14,301,2,0,
60,14,341,1,28,
60,14,341,2,0,
60,14,346,1,1,
60,14,346,2,0,
60,14,352,2,0,
60,14,358,1,35,
60,14,426,1,41,
60,14,496,4,0,
60,14,503,4,0,
60,15,3,1,15,
60,15,34,1,21,
60,15,54,2,0,
60,15,55,1,5,
60,15,56,1,38,
60,15,57,4,0,
60,15,58,4,0,
60,15,59,4,0,
60,15,61,1,25,
60,15,61,2,0,
60,15,91,4,0,
60,15,92,4,0,
60,15,94,4,0,
60,15,95,1,8,
60,15,104,4,0,
60,15,114,2,0,
60,15,127,4,0,
60,15,145,1,11,
60,15,150,2,0,
60,15,156,4,0,
60,15,164,4,0,
60,15,168,4,0,
60,15,170,2,0,
60,15,182,4,0,
60,15,187,1,31,
60,15,203,2,0,
60,15,207,4,0,
60,15,213,4,0,
60,15,214,4,0,
60,15,216,4,0,
60,15,218,4,0,
60,15,227,2,0,
60,15,237,4,0,
60,15,240,1,18,
60,15,240,4,0,
60,15,258,4,0,
60,15,263,4,0,
60,15,283,2,0,
60,15,287,2,0,
60,15,301,2,0,
60,15,341,1,28,
60,15,341,2,0,
60,15,346,1,1,
60,15,346,2,0,
60,15,352,2,0,
60,15,358,1,35,
60,15,426,1,41,
60,15,496,4,0,
60,15,503,4,0,
60,15,590,4,0,
60,16,3,1,15,1
60,16,34,1,21,1
60,16,54,2,0,
60,16,55,1,5,1
60,16,56,1,38,1
60,16,57,4,0,
60,16,58,4,0,
60,16,59,4,0,
60,16,61,1,25,1
60,16,61,2,0,
60,16,91,4,0,
60,16,92,4,0,
60,16,94,4,0,
60,16,95,1,8,1
60,16,104,4,0,
60,16,114,2,0,
60,16,127,4,0,
60,16,145,1,11,1
60,16,150,2,0,
60,16,156,4,0,
60,16,164,4,0,
60,16,168,4,0,
60,16,170,2,0,
60,16,173,3,0,
60,16,182,4,0,
60,16,187,1,31,1
60,16,196,3,0,
60,16,203,2,0,
60,16,207,4,0,
60,16,213,4,0,
60,16,214,4,0,
60,16,216,4,0,
60,16,218,4,0,
60,16,227,2,0,
60,16,237,4,0,
60,16,240,1,18,1
60,16,240,4,0,
60,16,258,4,0,
60,16,263,4,0,
60,16,270,3,0,
60,16,283,2,0,
60,16,283,3,0,
60,16,287,2,0,
60,16,290,4,0,
60,16,291,4,0,
60,16,301,2,0,
60,16,341,1,28,1
60,16,341,2,0,
60,16,346,1,1,1
60,16,346,2,0,
60,16,352,2,0,
60,16,352,3,0,
60,16,358,1,35,1
60,16,426,1,41,1
60,16,496,4,0,
60,16,503,4,0,
60,16,590,4,0,
60,17,3,1,15,
60,17,34,1,21,
60,17,54,2,0,
60,17,55,1,5,
60,17,56,1,38,
60,17,57,4,0,
60,17,58,4,0,
60,17,59,4,0,
60,17,61,1,25,
60,17,61,2,0,
60,17,92,4,0,
60,17,94,4,0,
60,17,95,1,8,
60,17,104,4,0,
60,17,114,2,0,
60,17,127,4,0,
60,17,145,1,11,
60,17,150,2,0,
60,17,156,4,0,
60,17,164,4,0,
60,17,168,4,0,
60,17,170,2,0,
60,17,182,4,0,
60,17,187,1,31,
60,17,203,2,0,
60,17,207,4,0,
60,17,213,4,0,
60,17,214,4,0,
60,17,216,4,0,
60,17,218,4,0,
60,17,227,2,0,
60,17,237,4,0,
60,17,240,1,18,
60,17,240,4,0,
60,17,258,4,0,
60,17,263,4,0,
60,17,283,2,0,
60,17,287,2,0,
60,17,301,2,0,
60,17,341,1,28,
60,17,341,2,0,
60,17,346,1,1,
60,17,346,2,0,
60,17,352,2,0,
60,17,358,1,35,
60,17,426,1,41,
60,17,496,4,0,
60,17,503,4,0,
60,17,590,4,0,
60,18,3,1,15,
60,18,34,1,21,
60,18,54,2,0,
60,18,55,1,5,
60,18,56,1,38,
60,18,57,4,0,
60,18,58,4,0,
60,18,59,4,0,
60,18,61,1,25,
60,18,61,2,0,
60,18,92,4,0,
60,18,94,4,0,
60,18,95,1,8,
60,18,104,4,0,
60,18,114,2,0,
60,18,127,4,0,
60,18,145,1,11,
60,18,150,2,0,
60,18,156,4,0,
60,18,164,4,0,
60,18,168,4,0,
60,18,170,2,0,
60,18,182,4,0,
60,18,187,1,31,
60,18,203,2,0,
60,18,207,4,0,
60,18,213,4,0,
60,18,214,4,0,
60,18,216,4,0,
60,18,218,4,0,
60,18,227,2,0,
60,18,237,4,0,
60,18,240,1,18,
60,18,240,4,0,
60,18,258,4,0,
60,18,263,4,0,
60,18,283,2,0,
60,18,287,2,0,
60,18,301,2,0,
60,18,341,1,28,
60,18,341,2,0,
60,18,346,1,1,
60,18,346,2,0,
60,18,352,2,0,
60,18,358,1,35,
60,18,426,1,41,
60,18,496,4,0,
60,18,503,4,0,
60,18,590,4,0,
61,1,3,1,26,
61,1,5,4,0,
61,1,25,4,0,
61,1,34,1,33,
61,1,34,4,0,
61,1,36,4,0,
61,1,38,4,0,
61,1,55,1,1,3
61,1,55,1,19,
61,1,55,4,0,
61,1,56,1,49,
61,1,57,4,0,
61,1,58,4,0,
61,1,59,4,0,
61,1,61,4,0,
61,1,66,4,0,
61,1,68,4,0,
61,1,69,4,0,
61,1,70,4,0,
61,1,89,4,0,
61,1,90,4,0,
61,1,92,4,0,
61,1,94,4,0,
61,1,95,1,1,2
61,1,95,1,16,
61,1,99,4,0,
61,1,102,4,0,
61,1,104,4,0,
61,1,117,4,0,
61,1,118,4,0,
61,1,130,4,0,
61,1,133,1,41,
61,1,145,1,1,1
61,1,149,4,0,
61,1,156,4,0,
61,1,164,4,0,
61,2,3,1,26,
61,2,5,4,0,
61,2,25,4,0,
61,2,34,1,33,
61,2,34,4,0,
61,2,36,4,0,
61,2,38,4,0,
61,2,55,1,1,3
61,2,55,1,19,
61,2,55,4,0,
61,2,56,1,49,
61,2,57,4,0,
61,2,58,4,0,
61,2,59,4,0,
61,2,61,4,0,
61,2,66,4,0,
61,2,68,4,0,
61,2,69,4,0,
61,2,70,4,0,
61,2,89,4,0,
61,2,90,4,0,
61,2,92,4,0,
61,2,94,4,0,
61,2,95,1,1,2
61,2,95,1,16,
61,2,99,4,0,
61,2,102,4,0,
61,2,104,4,0,
61,2,117,4,0,
61,2,118,4,0,
61,2,130,4,0,
61,2,133,1,41,
61,2,145,1,1,1
61,2,149,4,0,
61,2,156,4,0,
61,2,164,4,0,
61,3,3,1,19,
61,3,8,4,0,
61,3,29,4,0,
61,3,34,1,35,
61,3,55,1,1,3
61,3,55,1,13,
61,3,56,1,51,
61,3,57,4,0,
61,3,59,4,0,
61,3,70,4,0,
61,3,89,4,0,
61,3,92,4,0,
61,3,94,4,0,
61,3,95,1,1,2
61,3,95,1,7,
61,3,104,4,0,
61,3,111,4,0,
61,3,127,4,0,
61,3,145,1,1,1
61,3,156,4,0,
61,3,168,4,0,
61,3,173,4,0,
61,3,174,4,0,
61,3,182,4,0,
61,3,187,1,43,
61,3,189,4,0,
61,3,196,4,0,
61,3,197,4,0,
61,3,203,4,0,
61,3,207,4,0,
61,3,213,4,0,
61,3,214,4,0,
61,3,216,4,0,
61,3,218,4,0,
61,3,237,4,0,
61,3,240,1,27,
61,3,240,4,0,
61,3,249,4,0,
61,3,250,4,0,
61,4,3,1,19,
61,4,8,4,0,
61,4,29,4,0,
61,4,34,1,35,
61,4,55,1,1,3
61,4,55,1,13,
61,4,56,1,51,
61,4,57,4,0,
61,4,58,3,0,
61,4,59,4,0,
61,4,70,4,0,
61,4,89,4,0,
61,4,92,4,0,
61,4,94,4,0,
61,4,95,1,1,2
61,4,95,1,7,
61,4,104,4,0,
61,4,111,4,0,
61,4,127,4,0,
61,4,145,1,1,1
61,4,156,4,0,
61,4,168,4,0,
61,4,173,4,0,
61,4,174,4,0,
61,4,182,4,0,
61,4,187,1,43,
61,4,189,4,0,
61,4,196,4,0,
61,4,197,4,0,
61,4,203,4,0,
61,4,207,4,0,
61,4,213,4,0,
61,4,214,4,0,
61,4,216,4,0,
61,4,218,4,0,
61,4,237,4,0,
61,4,240,1,27,
61,4,240,4,0,
61,4,249,4,0,
61,4,250,4,0,
61,5,3,1,19,
61,5,34,1,35,
61,5,55,1,1,3
61,5,55,1,13,
61,5,56,1,51,
61,5,57,4,0,
61,5,58,4,0,
61,5,59,4,0,
61,5,70,4,0,
61,5,89,4,0,
61,5,91,4,0,
61,5,92,4,0,
61,5,94,4,0,
61,5,95,1,1,2
61,5,95,1,7,
61,5,104,4,0,
61,5,127,4,0,
61,5,145,1,1,1
61,5,156,4,0,
61,5,168,4,0,
61,5,182,4,0,
61,5,187,1,43,
61,5,213,4,0,
61,5,216,4,0,
61,5,218,4,0,
61,5,237,4,0,
61,5,240,1,27,
61,5,240,4,0,
61,5,249,4,0,
61,5,258,4,0,
61,5,263,4,0,
61,5,264,4,0,
61,5,280,4,0,
61,5,290,4,0,
61,5,291,4,0,
61,5,352,4,0,
61,6,3,1,19,
61,6,5,3,0,
61,6,8,3,0,
61,6,25,3,0,
61,6,34,1,35,
61,6,34,3,0,
61,6,38,3,0,
61,6,55,1,1,3
61,6,55,1,13,
61,6,56,1,51,
61,6,57,4,0,
61,6,58,4,0,
61,6,59,4,0,
61,6,68,3,0,
61,6,69,3,0,
61,6,70,4,0,
61,6,89,4,0,
61,6,91,4,0,
61,6,92,4,0,
61,6,94,4,0,
61,6,95,1,1,2
61,6,95,1,7,
61,6,102,3,0,
61,6,104,4,0,
61,6,111,3,0,
61,6,118,3,0,
61,6,127,4,0,
61,6,145,1,1,1
61,6,156,4,0,
61,6,164,3,0,
61,6,168,4,0,
61,6,173,3,0,
61,6,182,4,0,
61,6,187,1,43,
61,6,189,3,0,
61,6,196,3,0,
61,6,203,3,0,
61,6,207,3,0,
61,6,213,4,0,
61,6,214,3,0,
61,6,216,4,0,
61,6,218,4,0,
61,6,237,4,0,
61,6,240,1,27,
61,6,240,4,0,
61,6,249,4,0,
61,6,258,4,0,
61,6,263,4,0,
61,6,264,4,0,
61,6,280,4,0,
61,6,290,4,0,
61,6,291,4,0,
61,6,352,4,0,
61,7,3,1,19,
61,7,5,3,0,
61,7,25,3,0,
61,7,34,1,35,
61,7,34,3,0,
61,7,38,3,0,
61,7,55,1,1,3
61,7,55,1,13,
61,7,56,1,51,
61,7,57,4,0,
61,7,58,4,0,
61,7,59,4,0,
61,7,68,3,0,
61,7,69,3,0,
61,7,70,4,0,
61,7,89,4,0,
61,7,91,4,0,
61,7,92,4,0,
61,7,94,4,0,
61,7,95,1,1,2
61,7,95,1,7,
61,7,102,3,0,
61,7,104,4,0,
61,7,118,3,0,
61,7,127,4,0,
61,7,145,1,1,1
61,7,156,4,0,
61,7,164,3,0,
61,7,168,4,0,
61,7,182,4,0,
61,7,187,1,43,
61,7,213,4,0,
61,7,216,4,0,
61,7,218,4,0,
61,7,237,4,0,
61,7,240,1,27,
61,7,240,4,0,
61,7,249,4,0,
61,7,258,4,0,
61,7,263,4,0,
61,7,264,4,0,
61,7,280,4,0,
61,7,290,4,0,
61,7,291,4,0,
61,7,352,4,0,
61,8,3,1,15,
61,8,34,1,21,
61,8,55,1,11,
61,8,56,1,48,
61,8,57,4,0,
61,8,58,4,0,
61,8,59,4,0,
61,8,61,1,27,
61,8,70,4,0,
61,8,89,4,0,
61,8,91,4,0,
61,8,92,4,0,
61,8,94,4,0,
61,8,95,1,1,3
61,8,95,1,8,
61,8,104,4,0,
61,8,127,4,0,
61,8,145,1,1,2
61,8,145,1,5,
61,8,156,4,0,
61,8,164,4,0,
61,8,168,4,0,
61,8,182,4,0,
61,8,187,1,37,
61,8,203,4,0,
61,8,207,4,0,
61,8,213,4,0,
61,8,214,4,0,
61,8,216,4,0,
61,8,218,4,0,
61,8,237,4,0,
61,8,240,1,18,
61,8,240,4,0,
61,8,249,4,0,
61,8,258,4,0,
61,8,263,4,0,
61,8,264,4,0,
61,8,280,4,0,
61,8,290,4,0,
61,8,341,1,32,
61,8,346,1,1,1
61,8,352,4,0,
61,8,358,1,43,
61,8,363,4,0,
61,8,374,4,0,
61,8,426,1,53,
61,8,445,4,0,
61,9,3,1,15,
61,9,8,3,0,
61,9,34,1,21,
61,9,55,1,11,
61,9,56,1,48,
61,9,57,4,0,
61,9,58,4,0,
61,9,59,4,0,
61,9,61,1,27,
61,9,70,4,0,
61,9,89,4,0,
61,9,91,4,0,
61,9,92,4,0,
61,9,94,4,0,
61,9,95,1,1,3
61,9,95,1,8,
61,9,104,4,0,
61,9,127,4,0,
61,9,145,1,1,2
61,9,145,1,5,
61,9,156,4,0,
61,9,164,4,0,
61,9,168,4,0,
61,9,173,3,0,
61,9,182,4,0,
61,9,187,1,37,
61,9,189,3,0,
61,9,196,3,0,
61,9,203,4,0,
61,9,207,4,0,
61,9,213,4,0,
61,9,214,4,0,
61,9,216,4,0,
61,9,218,4,0,
61,9,237,4,0,
61,9,240,1,18,
61,9,240,4,0,
61,9,249,4,0,
61,9,258,4,0,
61,9,263,4,0,
61,9,264,4,0,
61,9,270,3,0,
61,9,280,4,0,
61,9,290,4,0,
61,9,291,3,0,
61,9,341,1,32,
61,9,346,1,1,1
61,9,352,4,0,
61,9,358,1,43,
61,9,363,4,0,
61,9,374,4,0,
61,9,426,1,53,
61,9,445,4,0,
61,10,3,1,15,
61,10,8,3,0,
61,10,29,3,0,
61,10,34,1,21,
61,10,55,1,11,
61,10,56,1,48,
61,10,57,4,0,
61,10,58,4,0,
61,10,59,4,0,
61,10,61,1,27,
61,10,70,4,0,
61,10,89,4,0,
61,10,91,4,0,
61,10,92,4,0,
61,10,94,4,0,
61,10,95,1,1,3
61,10,95,1,8,
61,10,104,4,0,
61,10,127,4,0,
61,10,145,1,1,2
61,10,145,1,5,
61,10,156,4,0,
61,10,164,4,0,
61,10,168,4,0,
61,10,173,3,0,
61,10,182,4,0,
61,10,187,1,37,
61,10,189,3,0,
61,10,196,3,0,
61,10,203,4,0,
61,10,207,4,0,
61,10,213,4,0,
61,10,214,4,0,
61,10,216,4,0,
61,10,218,4,0,
61,10,237,4,0,
61,10,240,1,18,
61,10,240,4,0,
61,10,249,4,0,
61,10,250,4,0,
61,10,258,4,0,
61,10,263,4,0,
61,10,264,4,0,
61,10,270,3,0,
61,10,280,4,0,
61,10,290,4,0,
61,10,291,3,0,
61,10,341,1,32,
61,10,346,1,1,1
61,10,352,4,0,
61,10,358,1,43,
61,10,363,4,0,
61,10,374,4,0,
61,10,426,1,53,
61,10,445,4,0,
61,11,3,1,15,
61,11,34,1,21,
61,11,55,1,11,
61,11,56,1,48,
61,11,57,4,0,
61,11,58,4,0,
61,11,59,4,0,
61,11,61,1,27,
61,11,70,4,0,
61,11,89,4,0,
61,11,91,4,0,
61,11,92,4,0,
61,11,94,4,0,
61,11,95,1,1,3
61,11,95,1,8,
61,11,104,4,0,
61,11,127,4,0,
61,11,145,1,1,2
61,11,145,1,5,
61,11,156,4,0,
61,11,164,4,0,
61,11,168,4,0,
61,11,182,4,0,
61,11,187,1,37,
61,11,207,4,0,
61,11,213,4,0,
61,11,216,4,0,
61,11,218,4,0,
61,11,237,4,0,
61,11,240,1,18,
61,11,240,4,0,
61,11,249,4,0,
61,11,258,4,0,
61,11,263,4,0,
61,11,280,4,0,
61,11,291,4,0,
61,11,341,1,32,
61,11,346,1,1,1
61,11,358,1,43,
61,11,374,4,0,
61,11,426,1,53,
61,11,496,4,0,
61,11,503,4,0,
61,11,523,4,0,
61,12,3,1,19,
61,12,34,1,35,
61,12,55,1,1,3
61,12,55,1,13,
61,12,56,1,51,
61,12,57,4,0,
61,12,58,4,0,
61,12,59,4,0,
61,12,70,4,0,
61,12,89,4,0,
61,12,91,4,0,
61,12,92,4,0,
61,12,94,4,0,
61,12,95,1,1,2
61,12,95,1,7,
61,12,104,4,0,
61,12,127,4,0,
61,12,145,1,1,1
61,12,156,4,0,
61,12,168,4,0,
61,12,182,4,0,
61,12,187,1,43,
61,12,213,4,0,
61,12,216,4,0,
61,12,218,4,0,
61,12,237,4,0,
61,12,240,1,27,
61,12,240,4,0,
61,12,249,4,0,
61,12,258,4,0,
61,12,263,4,0,
61,12,264,4,0,
61,12,280,4,0,
61,12,290,4,0,
61,12,291,4,0,
61,12,352,4,0,
61,13,3,1,19,
61,13,34,1,35,
61,13,34,3,0,
61,13,38,3,0,
61,13,55,1,1,3
61,13,55,1,13,
61,13,56,1,51,
61,13,57,4,0,
61,13,58,4,0,
61,13,59,4,0,
61,13,69,3,0,
61,13,70,4,0,
61,13,89,4,0,
61,13,91,4,0,
61,13,92,4,0,
61,13,94,4,0,
61,13,95,1,1,2
61,13,95,1,7,
61,13,102,3,0,
61,13,104,4,0,
61,13,127,4,0,
61,13,145,1,1,1
61,13,156,4,0,
61,13,164,3,0,
61,13,168,4,0,
61,13,182,4,0,
61,13,187,1,43,
61,13,196,3,0,
61,13,207,3,0,
61,13,213,4,0,
61,13,216,4,0,
61,13,218,4,0,
61,13,237,4,0,
61,13,240,1,27,
61,13,240,4,0,
61,13,249,4,0,
61,13,258,4,0,
61,13,263,4,0,
61,13,264,4,0,
61,13,280,4,0,
61,13,290,4,0,
61,13,291,4,0,
61,13,352,4,0,
61,14,3,1,15,
61,14,8,3,0,
61,14,34,1,21,
61,14,55,1,11,
61,14,56,1,48,
61,14,57,4,0,
61,14,58,4,0,
61,14,59,4,0,
61,14,61,1,27,
61,14,70,4,0,
61,14,89,4,0,
61,14,91,4,0,
61,14,92,4,0,
61,14,94,4,0,
61,14,95,1,1,3
61,14,95,1,8,
61,14,104,4,0,
61,14,127,4,0,
61,14,145,1,1,2
61,14,145,1,5,
61,14,156,4,0,
61,14,164,4,0,
61,14,168,4,0,
61,14,173,3,0,
61,14,182,4,0,
61,14,187,1,37,
61,14,196,3,0,
61,14,207,4,0,
61,14,213,4,0,
61,14,214,3,0,
61,14,216,4,0,
61,14,218,4,0,
61,14,237,4,0,
61,14,240,1,18,
61,14,240,4,0,
61,14,249,4,0,
61,14,258,4,0,
61,14,263,4,0,
61,14,270,3,0,
61,14,280,4,0,
61,14,283,3,0,
61,14,291,4,0,
61,14,341,1,32,
61,14,346,1,1,1
61,14,358,1,43,
61,14,374,4,0,
61,14,426,1,53,
61,14,496,4,0,
61,14,503,4,0,
61,14,523,4,0,
61,15,3,1,15,
61,15,34,1,21,
61,15,55,1,1,2
61,15,55,1,5,
61,15,56,1,48,
61,15,57,4,0,
61,15,58,4,0,
61,15,59,4,0,
61,15,61,1,27,
61,15,70,4,0,
61,15,89,4,0,
61,15,91,4,0,
61,15,92,4,0,
61,15,94,4,0,
61,15,95,1,1,3
61,15,95,1,8,
61,15,104,4,0,
61,15,127,4,0,
61,15,145,1,11,
61,15,156,4,0,
61,15,164,4,0,
61,15,168,4,0,
61,15,182,4,0,
61,15,187,1,37,
61,15,207,4,0,
61,15,213,4,0,
61,15,214,4,0,
61,15,216,4,0,
61,15,218,4,0,
61,15,237,4,0,
61,15,240,1,18,
61,15,240,4,0,
61,15,249,4,0,
61,15,258,4,0,
61,15,263,4,0,
61,15,280,4,0,
61,15,341,1,32,
61,15,346,1,1,1
61,15,358,1,43,
61,15,374,4,0,
61,15,426,1,53,
61,15,496,4,0,
61,15,503,4,0,
61,15,523,4,0,
61,15,590,4,0,
61,15,612,4,0,
61,16,3,1,15,1
61,16,8,3,0,
61,16,34,1,21,1
61,16,55,1,1,2
61,16,55,1,5,1
61,16,56,1,48,1
61,16,57,4,0,
61,16,58,4,0,
61,16,59,4,0,
61,16,61,1,27,1
61,16,70,4,0,
61,16,89,4,0,
61,16,91,4,0,
61,16,92,4,0,
61,16,94,4,0,
61,16,95,1,1,3
61,16,95,1,8,1
61,16,104,4,0,
61,16,127,4,0,
61,16,145,1,11,1
61,16,156,4,0,
61,16,164,4,0,
61,16,168,4,0,
61,16,173,3,0,
61,16,182,4,0,
61,16,187,1,37,1
61,16,196,3,0,
61,16,207,4,0,
61,16,213,4,0,
61,16,214,4,0,
61,16,216,4,0,
61,16,218,4,0,
61,16,237,4,0,
61,16,240,1,18,1
61,16,240,4,0,
61,16,249,4,0,
61,16,258,4,0,
61,16,263,4,0,
61,16,264,3,0,
61,16,270,3,0,
61,16,280,4,0,
61,16,283,3,0,
61,16,290,4,0,
61,16,291,4,0,
61,16,341,1,32,1
61,16,346,1,1,1
61,16,352,3,0,
61,16,358,1,43,1
61,16,374,4,0,
61,16,426,1,53,1
61,16,496,4,0,
61,16,503,4,0,
61,16,523,4,0,
61,16,590,4,0,
61,16,612,4,0,
61,17,3,1,15,
61,17,34,1,21,
61,17,55,1,1,2
61,17,55,1,5,
61,17,56,1,48,
61,17,57,4,0,
61,17,58,4,0,
61,17,59,4,0,
61,17,61,1,27,
61,17,89,4,0,
61,17,92,4,0,
61,17,94,4,0,
61,17,95,1,1,3
61,17,95,1,8,
61,17,104,4,0,
61,17,127,4,0,
61,17,145,1,11,
61,17,156,4,0,
61,17,164,4,0,
61,17,168,4,0,
61,17,182,4,0,
61,17,187,1,37,
61,17,207,4,0,
61,17,213,4,0,
61,17,214,4,0,
61,17,216,4,0,
61,17,218,4,0,
61,17,237,4,0,
61,17,240,1,18,
61,17,240,4,0,
61,17,258,4,0,
61,17,263,4,0,
61,17,280,4,0,
61,17,341,1,32,
61,17,346,1,1,1
61,17,358,1,43,
61,17,374,4,0,
61,17,426,1,53,
61,17,496,4,0,
61,17,503,4,0,
61,17,523,4,0,
61,17,590,4,0,
61,18,3,1,15,
61,18,34,1,21,
61,18,55,1,1,2
61,18,55,1,5,
61,18,56,1,48,
61,18,57,4,0,
61,18,58,4,0,
61,18,59,4,0,
61,18,61,1,27,
61,18,89,4,0,
61,18,92,4,0,
61,18,94,4,0,
61,18,95,1,1,3
61,18,95,1,8,
61,18,104,4,0,
61,18,127,4,0,
61,18,145,1,11,
61,18,156,4,0,
61,18,164,4,0,
61,18,168,4,0,
61,18,182,4,0,
61,18,187,1,37,
61,18,207,4,0,
61,18,213,4,0,
61,18,214,4,0,
61,18,216,4,0,
61,18,218,4,0,
61,18,237,4,0,
61,18,240,1,18,
61,18,240,4,0,
61,18,258,4,0,
61,18,263,4,0,
61,18,280,4,0,
61,18,341,1,32,
61,18,346,1,1,1
61,18,358,1,43,
61,18,374,4,0,
61,18,426,1,53,
61,18,496,4,0,
61,18,503,4,0,
61,18,523,4,0,
61,18,590,4,0,
62,1,3,1,1,3
62,1,5,4,0,
62,1,25,4,0,
62,1,34,1,1,4
62,1,34,4,0,
62,1,36,4,0,
62,1,38,4,0,
62,1,55,1,1,2
62,1,55,1,19,
62,1,55,4,0,
62,1,57,4,0,
62,1,58,4,0,
62,1,59,4,0,
62,1,61,4,0,
62,1,63,4,0,
62,1,66,4,0,
62,1,68,4,0,
62,1,69,4,0,
62,1,70,4,0,
62,1,89,4,0,
62,1,90,4,0,
62,1,92,4,0,
62,1,94,4,0,
62,1,95,1,1,1
62,1,95,1,16,
62,1,99,4,0,
62,1,102,4,0,
62,1,104,4,0,
62,1,117,4,0,
62,1,118,4,0,
62,1,130,4,0,
62,1,149,4,0,
62,1,156,4,0,
62,1,164,4,0,
62,2,3,1,1,3
62,2,5,4,0,
62,2,25,4,0,
62,2,34,1,1,4
62,2,34,4,0,
62,2,36,4,0,
62,2,38,4,0,
62,2,55,1,1,2
62,2,55,1,19,
62,2,55,4,0,
62,2,57,4,0,
62,2,58,4,0,
62,2,59,4,0,
62,2,61,4,0,
62,2,63,4,0,
62,2,66,4,0,
62,2,68,4,0,
62,2,69,4,0,
62,2,70,4,0,
62,2,89,4,0,
62,2,90,4,0,
62,2,92,4,0,
62,2,94,4,0,
62,2,95,1,1,1
62,2,95,1,16,
62,2,99,4,0,
62,2,102,4,0,
62,2,104,4,0,
62,2,117,4,0,
62,2,118,4,0,
62,2,130,4,0,
62,2,149,4,0,
62,2,156,4,0,
62,2,164,4,0,
62,3,3,1,1,3
62,3,8,4,0,
62,3,29,4,0,
62,3,55,1,1,1
62,3,57,4,0,
62,3,59,4,0,
62,3,63,4,0,
62,3,66,1,1,4
62,3,66,1,35,
62,3,70,4,0,
62,3,89,4,0,
62,3,92,4,0,
62,3,94,4,0,
62,3,95,1,1,2
62,3,104,4,0,
62,3,111,4,0,
62,3,127,4,0,
62,3,156,4,0,
62,3,168,4,0,
62,3,170,1,51,
62,3,173,4,0,
62,3,174,4,0,
62,3,182,4,0,
62,3,189,4,0,
62,3,196,4,0,
62,3,197,4,0,
62,3,203,4,0,
62,3,207,4,0,
62,3,213,4,0,
62,3,214,4,0,
62,3,216,4,0,
62,3,218,4,0,
62,3,223,4,0,
62,3,237,4,0,
62,3,240,4,0,
62,3,249,4,0,
62,3,250,4,0,
62,4,3,1,1,3
62,4,8,4,0,
62,4,29,4,0,
62,4,55,1,1,1
62,4,57,4,0,
62,4,58,3,0,
62,4,59,4,0,
62,4,63,4,0,
62,4,66,1,1,4
62,4,66,1,35,
62,4,70,4,0,
62,4,89,4,0,
62,4,92,4,0,
62,4,94,4,0,
62,4,95,1,1,2
62,4,104,4,0,
62,4,111,4,0,
62,4,127,4,0,
62,4,156,4,0,
62,4,168,4,0,
62,4,170,1,51,
62,4,173,4,0,
62,4,174,4,0,
62,4,182,4,0,
62,4,189,4,0,
62,4,196,4,0,
62,4,197,4,0,
62,4,203,4,0,
62,4,207,4,0,
62,4,213,4,0,
62,4,214,4,0,
62,4,216,4,0,
62,4,218,4,0,
62,4,223,4,0,
62,4,237,4,0,
62,4,240,4,0,
62,4,249,4,0,
62,4,250,4,0,
62,5,3,1,1,3
62,5,55,1,1,1
62,5,57,4,0,
62,5,58,4,0,
62,5,59,4,0,
62,5,63,4,0,
62,5,66,1,1,4
62,5,66,1,35,
62,5,70,4,0,
62,5,89,4,0,
62,5,91,4,0,
62,5,92,4,0,
62,5,94,4,0,
62,5,95,1,1,2
62,5,104,4,0,
62,5,127,4,0,
62,5,156,4,0,
62,5,168,4,0,
62,5,170,1,51,
62,5,182,4,0,
62,5,213,4,0,
62,5,216,4,0,
62,5,218,4,0,
62,5,237,4,0,
62,5,240,4,0,
62,5,249,4,0,
62,5,258,4,0,
62,5,263,4,0,
62,5,264,4,0,
62,5,280,4,0,
62,5,290,4,0,
62,5,291,4,0,
62,5,317,4,0,
62,5,339,4,0,
62,5,352,4,0,
62,6,3,1,1,3
62,6,5,3,0,
62,6,8,3,0,
62,6,25,3,0,
62,6,34,3,0,
62,6,38,3,0,
62,6,55,1,1,1
62,6,57,4,0,
62,6,58,4,0,
62,6,59,4,0,
62,6,63,4,0,
62,6,66,1,1,4
62,6,66,1,35,
62,6,68,3,0,
62,6,69,3,0,
62,6,70,4,0,
62,6,89,4,0,
62,6,91,4,0,
62,6,92,4,0,
62,6,94,4,0,
62,6,95,1,1,2
62,6,102,3,0,
62,6,104,4,0,
62,6,111,3,0,
62,6,118,3,0,
62,6,127,4,0,
62,6,156,4,0,
62,6,164,3,0,
62,6,168,4,0,
62,6,170,1,51,
62,6,173,3,0,
62,6,182,4,0,
62,6,189,3,0,
62,6,196,3,0,
62,6,203,3,0,
62,6,207,3,0,
62,6,213,4,0,
62,6,214,3,0,
62,6,216,4,0,
62,6,218,4,0,
62,6,223,3,0,
62,6,237,4,0,
62,6,240,4,0,
62,6,249,4,0,
62,6,258,4,0,
62,6,263,4,0,
62,6,264,4,0,
62,6,280,4,0,
62,6,290,4,0,
62,6,291,4,0,
62,6,317,4,0,
62,6,339,4,0,
62,6,352,4,0,
62,7,3,1,1,3
62,7,5,3,0,
62,7,25,3,0,
62,7,34,3,0,
62,7,38,3,0,
62,7,55,1,1,1
62,7,57,4,0,
62,7,58,4,0,
62,7,59,4,0,
62,7,63,4,0,
62,7,66,1,1,4
62,7,66,1,35,
62,7,68,3,0,
62,7,69,3,0,
62,7,70,4,0,
62,7,89,4,0,
62,7,91,4,0,
62,7,92,4,0,
62,7,94,4,0,
62,7,95,1,1,2
62,7,102,3,0,
62,7,104,4,0,
62,7,118,3,0,
62,7,127,4,0,
62,7,156,4,0,
62,7,164,3,0,
62,7,168,4,0,
62,7,170,1,51,
62,7,182,4,0,
62,7,213,4,0,
62,7,216,4,0,
62,7,218,4,0,
62,7,237,4,0,
62,7,240,4,0,
62,7,249,4,0,
62,7,258,4,0,
62,7,263,4,0,
62,7,264,4,0,
62,7,280,4,0,
62,7,290,4,0,
62,7,291,4,0,
62,7,317,4,0,
62,7,339,4,0,
62,7,352,4,0,
62,8,3,1,1,3
62,8,57,4,0,
62,8,58,4,0,
62,8,59,4,0,
62,8,61,1,1,1
62,8,63,4,0,
62,8,66,1,1,4
62,8,70,4,0,
62,8,89,4,0,
62,8,91,4,0,
62,8,92,4,0,
62,8,94,4,0,
62,8,95,1,1,2
62,8,104,4,0,
62,8,127,4,0,
62,8,156,4,0,
62,8,157,4,0,
62,8,164,4,0,
62,8,168,4,0,
62,8,170,1,53,
62,8,182,4,0,
62,8,203,4,0,
62,8,207,4,0,
62,8,213,4,0,
62,8,214,4,0,
62,8,216,4,0,
62,8,218,4,0,
62,8,223,1,43,
62,8,237,4,0,
62,8,240,4,0,
62,8,249,4,0,
62,8,258,4,0,
62,8,263,4,0,
62,8,264,4,0,
62,8,280,4,0,
62,8,290,4,0,
62,8,317,4,0,
62,8,339,4,0,
62,8,352,4,0,
62,8,363,4,0,
62,8,371,4,0,
62,8,374,4,0,
62,8,398,4,0,
62,8,411,4,0,
62,8,416,4,0,
62,8,431,4,0,
62,8,445,4,0,
62,9,3,1,1,3
62,9,8,3,0,
62,9,57,4,0,
62,9,58,4,0,
62,9,59,4,0,
62,9,61,1,1,1
62,9,63,4,0,
62,9,66,1,1,4
62,9,70,4,0,
62,9,89,4,0,
62,9,91,4,0,
62,9,92,4,0,
62,9,94,4,0,
62,9,95,1,1,2
62,9,104,4,0,
62,9,127,4,0,
62,9,156,4,0,
62,9,157,4,0,
62,9,164,4,0,
62,9,168,4,0,
62,9,170,1,53,
62,9,173,3,0,
62,9,182,4,0,
62,9,189,3,0,
62,9,196,3,0,
62,9,203,4,0,
62,9,207,4,0,
62,9,213,4,0,
62,9,214,4,0,
62,9,216,4,0,
62,9,218,4,0,
62,9,223,1,43,
62,9,237,4,0,
62,9,240,4,0,
62,9,249,4,0,
62,9,258,4,0,
62,9,263,4,0,
62,9,264,4,0,
62,9,270,3,0,
62,9,280,4,0,
62,9,290,4,0,
62,9,291,3,0,
62,9,317,4,0,
62,9,339,4,0,
62,9,352,4,0,
62,9,363,4,0,
62,9,371,4,0,
62,9,374,4,0,
62,9,398,4,0,
62,9,410,3,0,
62,9,411,4,0,
62,9,416,4,0,
62,9,431,4,0,
62,9,445,4,0,
62,10,3,1,1,3
62,10,8,3,0,
62,10,29,3,0,
62,10,57,4,0,
62,10,58,4,0,
62,10,59,4,0,
62,10,61,1,1,1
62,10,63,4,0,
62,10,66,1,1,4
62,10,70,4,0,
62,10,89,4,0,
62,10,91,4,0,
62,10,92,4,0,
62,10,94,4,0,
62,10,95,1,1,2
62,10,104,4,0,
62,10,127,4,0,
62,10,156,4,0,
62,10,157,4,0,
62,10,164,4,0,
62,10,168,4,0,
62,10,170,1,53,
62,10,173,3,0,
62,10,182,4,0,
62,10,189,3,0,
62,10,196,3,0,
62,10,203,4,0,
62,10,207,4,0,
62,10,213,4,0,
62,10,214,4,0,
62,10,216,4,0,
62,10,218,4,0,
62,10,223,1,43,
62,10,237,4,0,
62,10,240,4,0,
62,10,249,4,0,
62,10,250,4,0,
62,10,258,4,0,
62,10,263,4,0,
62,10,264,4,0,
62,10,270,3,0,
62,10,280,4,0,
62,10,290,4,0,
62,10,291,3,0,
62,10,317,4,0,
62,10,339,4,0,
62,10,352,4,0,
62,10,363,4,0,
62,10,371,4,0,
62,10,374,4,0,
62,10,398,4,0,
62,10,410,3,0,
62,10,411,4,0,
62,10,416,4,0,
62,10,431,4,0,
62,10,445,4,0,
62,11,3,1,1,3
62,11,57,4,0,
62,11,58,4,0,
62,11,59,4,0,
62,11,61,1,1,1
62,11,63,4,0,
62,11,66,1,1,4
62,11,70,4,0,
62,11,89,4,0,
62,11,91,4,0,
62,11,92,4,0,
62,11,94,4,0,
62,11,95,1,1,2
62,11,104,4,0,
62,11,127,4,0,
62,11,156,4,0,
62,11,157,4,0,
62,11,164,4,0,
62,11,168,4,0,
62,11,170,1,43,
62,11,182,4,0,
62,11,207,4,0,
62,11,213,4,0,
62,11,216,4,0,
62,11,218,4,0,
62,11,223,1,32,
62,11,237,4,0,
62,11,240,4,0,
62,11,249,4,0,
62,11,258,4,0,
62,11,263,4,0,
62,11,280,4,0,
62,11,291,4,0,
62,11,317,4,0,
62,11,339,4,0,
62,11,371,4,0,
62,11,374,4,0,
62,11,398,4,0,
62,11,411,4,0,
62,11,416,4,0,
62,11,490,4,0,
62,11,496,4,0,
62,11,503,4,0,
62,11,509,1,53,
62,11,523,4,0,
62,11,526,4,0,
62,12,3,1,1,3
62,12,55,1,1,1
62,12,57,4,0,
62,12,58,4,0,
62,12,59,4,0,
62,12,63,4,0,
62,12,66,1,1,4
62,12,66,1,35,
62,12,70,4,0,
62,12,89,4,0,
62,12,91,4,0,
62,12,92,4,0,
62,12,94,4,0,
62,12,95,1,1,2
62,12,104,4,0,
62,12,127,4,0,
62,12,156,4,0,
62,12,168,4,0,
62,12,170,1,51,
62,12,182,4,0,
62,12,213,4,0,
62,12,216,4,0,
62,12,218,4,0,
62,12,237,4,0,
62,12,240,4,0,
62,12,249,4,0,
62,12,258,4,0,
62,12,263,4,0,
62,12,264,4,0,
62,12,280,4,0,
62,12,290,4,0,
62,12,291,4,0,
62,12,317,4,0,
62,12,339,4,0,
62,12,352,4,0,
62,13,3,1,1,3
62,13,34,3,0,
62,13,38,3,0,
62,13,55,1,1,1
62,13,57,4,0,
62,13,58,4,0,
62,13,59,4,0,
62,13,63,4,0,
62,13,66,1,1,4
62,13,66,1,35,
62,13,69,3,0,
62,13,70,4,0,
62,13,89,4,0,
62,13,91,4,0,
62,13,92,4,0,
62,13,94,4,0,
62,13,95,1,1,2
62,13,102,3,0,
62,13,104,4,0,
62,13,127,4,0,
62,13,156,4,0,
62,13,164,3,0,
62,13,168,4,0,
62,13,170,1,51,
62,13,182,4,0,
62,13,196,3,0,
62,13,207,3,0,
62,13,213,4,0,
62,13,216,4,0,
62,13,218,4,0,
62,13,237,4,0,
62,13,240,4,0,
62,13,249,4,0,
62,13,258,4,0,
62,13,263,4,0,
62,13,264,4,0,
62,13,280,4,0,
62,13,290,4,0,
62,13,291,4,0,
62,13,317,4,0,
62,13,339,4,0,
62,13,352,4,0,
62,14,3,1,1,3
62,14,8,3,0,
62,14,57,4,0,
62,14,58,4,0,
62,14,59,4,0,
62,14,61,1,1,1
62,14,63,4,0,
62,14,66,1,1,4
62,14,70,4,0,
62,14,89,4,0,
62,14,91,4,0,
62,14,92,4,0,
62,14,94,4,0,
62,14,95,1,1,2
62,14,104,4,0,
62,14,127,4,0,
62,14,156,4,0,
62,14,157,4,0,
62,14,164,4,0,
62,14,168,4,0,
62,14,170,1,43,
62,14,173,3,0,
62,14,182,4,0,
62,14,196,3,0,
62,14,207,4,0,
62,14,213,4,0,
62,14,214,3,0,
62,14,216,4,0,
62,14,218,4,0,
62,14,223,1,32,
62,14,237,4,0,
62,14,240,4,0,
62,14,249,4,0,
62,14,258,4,0,
62,14,263,4,0,
62,14,270,3,0,
62,14,280,4,0,
62,14,283,3,0,
62,14,291,4,0,
62,14,317,4,0,
62,14,339,4,0,
62,14,371,4,0,
62,14,374,4,0,
62,14,398,4,0,
62,14,411,4,0,
62,14,416,4,0,
62,14,490,4,0,
62,14,496,4,0,
62,14,503,4,0,
62,14,509,1,53,
62,14,523,4,0,
62,14,526,4,0,
62,15,3,1,1,4
62,15,57,4,0,
62,15,58,4,0,
62,15,59,4,0,
62,15,61,1,1,2
62,15,63,4,0,
62,15,66,1,1,5
62,15,70,4,0,
62,15,89,4,0,
62,15,91,4,0,
62,15,92,4,0,
62,15,94,4,0,
62,15,95,1,1,3
62,15,104,4,0,
62,15,127,4,0,
62,15,156,4,0,
62,15,157,4,0,
62,15,164,4,0,
62,15,168,4,0,
62,15,170,1,43,
62,15,182,4,0,
62,15,207,4,0,
62,15,213,4,0,
62,15,214,4,0,
62,15,216,4,0,
62,15,218,4,0,
62,15,223,1,32,
62,15,237,4,0,
62,15,240,4,0,
62,15,249,4,0,
62,15,258,4,0,
62,15,263,4,0,
62,15,280,4,0,
62,15,317,4,0,
62,15,339,4,0,
62,15,371,4,0,
62,15,374,4,0,
62,15,398,4,0,
62,15,411,4,0,
62,15,416,4,0,
62,15,490,4,0,
62,15,496,4,0,
62,15,503,4,0,
62,15,509,1,1,1
62,15,509,1,53,
62,15,523,4,0,
62,15,590,4,0,
62,15,612,4,0,
62,16,3,1,1,4
62,16,8,3,0,
62,16,57,4,0,
62,16,58,4,0,
62,16,59,4,0,
62,16,61,1,1,2
62,16,63,4,0,
62,16,66,1,1,5
62,16,70,4,0,
62,16,89,4,0,
62,16,91,4,0,
62,16,92,4,0,
62,16,94,4,0,
62,16,95,1,1,3
62,16,104,4,0,
62,16,127,4,0,
62,16,156,4,0,
62,16,157,4,0,
62,16,164,4,0,
62,16,168,4,0,
62,16,170,1,43,1
62,16,173,3,0,
62,16,182,4,0,
62,16,196,3,0,
62,16,207,4,0,
62,16,213,4,0,
62,16,214,4,0,
62,16,216,4,0,
62,16,218,4,0,
62,16,223,1,32,1
62,16,237,4,0,
62,16,240,4,0,
62,16,249,4,0,
62,16,258,4,0,
62,16,263,4,0,
62,16,264,3,0,
62,16,270,3,0,
62,16,280,4,0,
62,16,283,3,0,
62,16,290,4,0,
62,16,291,4,0,
62,16,317,4,0,
62,16,339,4,0,
62,16,352,3,0,
62,16,371,4,0,
62,16,374,4,0,
62,16,398,4,0,
62,16,411,4,0,
62,16,416,4,0,
62,16,490,4,0,
62,16,496,4,0,
62,16,503,4,0,
62,16,509,1,1,1
62,16,509,1,53,1
62,16,523,4,0,
62,16,590,4,0,
62,16,612,4,0,
62,17,3,1,1,5
62,17,57,4,0,
62,17,58,4,0,
62,17,59,4,0,
62,17,61,1,1,3
62,17,63,4,0,
62,17,66,1,0,
62,17,66,1,1,1
62,17,89,4,0,
62,17,92,4,0,
62,17,94,4,0,
62,17,95,1,1,4
62,17,104,4,0,
62,17,127,4,0,
62,17,156,4,0,
62,17,157,4,0,
62,17,164,4,0,
62,17,168,4,0,
62,17,170,1,43,
62,17,182,4,0,
62,17,207,4,0,
62,17,213,4,0,
62,17,214,4,0,
62,17,216,4,0,
62,17,218,4,0,
62,17,223,1,32,
62,17,237,4,0,
62,17,240,4,0,
62,17,258,4,0,
62,17,263,4,0,
62,17,280,4,0,
62,17,317,4,0,
62,17,339,4,0,
62,17,371,4,0,
62,17,374,4,0,
62,17,398,4,0,
62,17,411,4,0,
62,17,416,4,0,
62,17,490,4,0,
62,17,496,4,0,
62,17,503,4,0,
62,17,509,1,1,2
62,17,509,1,53,
62,17,523,4,0,
62,17,526,4,0,
62,17,590,4,0,
62,18,3,1,1,5
62,18,57,4,0,
62,18,58,4,0,
62,18,59,4,0,
62,18,61,1,1,3
62,18,63,4,0,
62,18,66,1,0,
62,18,66,1,1,1
62,18,89,4,0,
62,18,92,4,0,
62,18,94,4,0,
62,18,95,1,1,4
62,18,104,4,0,
62,18,127,4,0,
62,18,156,4,0,
62,18,157,4,0,
62,18,164,4,0,
62,18,168,4,0,
62,18,170,1,43,
62,18,182,4,0,
62,18,207,4,0,
62,18,213,4,0,
62,18,214,4,0,
62,18,216,4,0,
62,18,218,4,0,
62,18,223,1,32,
62,18,237,4,0,
62,18,240,4,0,
62,18,258,4,0,
62,18,263,4,0,
62,18,280,4,0,
62,18,317,4,0,
62,18,339,4,0,
62,18,371,4,0,
62,18,374,4,0,
62,18,398,4,0,
62,18,411,4,0,
62,18,416,4,0,
62,18,490,4,0,
62,18,496,4,0,
62,18,503,4,0,
62,18,509,1,1,2
62,18,509,1,53,
62,18,523,4,0,
62,18,526,4,0,
62,18,590,4,0,
63,1,5,4,0,
63,1,25,4,0,
63,1,34,4,0,
63,1,36,4,0,
63,1,38,4,0,
63,1,66,4,0,
63,1,68,4,0,
63,1,69,4,0,
63,1,86,4,0,
63,1,92,4,0,
63,1,94,4,0,
63,1,99,4,0,
63,1,100,1,1,
63,1,100,4,0,
63,1,102,4,0,
63,1,104,4,0,
63,1,115,4,0,
63,1,117,4,0,
63,1,118,4,0,
63,1,130,4,0,
63,1,148,4,0,
63,1,149,4,0,
63,1,156,4,0,
63,1,161,4,0,
63,1,164,4,0,
63,2,5,4,0,
63,2,25,4,0,
63,2,34,4,0,
63,2,36,4,0,
63,2,38,4,0,
63,2,66,4,0,
63,2,68,4,0,
63,2,69,4,0,
63,2,86,4,0,
63,2,92,4,0,
63,2,94,4,0,
63,2,99,4,0,
63,2,100,1,1,
63,2,100,4,0,
63,2,102,4,0,
63,2,104,4,0,
63,2,115,4,0,
63,2,117,4,0,
63,2,118,4,0,
63,2,130,4,0,
63,2,148,4,0,
63,2,149,4,0,
63,2,156,4,0,
63,2,161,4,0,
63,2,164,4,0,
63,3,7,4,0,
63,3,8,4,0,
63,3,9,4,0,
63,3,29,4,0,
63,3,92,4,0,
63,3,94,4,0,
63,3,100,1,1,
63,3,104,4,0,
63,3,112,2,0,
63,3,113,2,0,
63,3,138,4,0,
63,3,148,4,0,
63,3,156,4,0,
63,3,168,4,0,
63,3,171,4,0,
63,3,173,4,0,
63,3,174,4,0,
63,3,182,4,0,
63,3,192,4,0,
63,3,203,4,0,
63,3,207,4,0,
63,3,213,4,0,
63,3,214,4,0,
63,3,216,4,0,
63,3,218,4,0,
63,3,223,4,0,
63,3,227,2,0,
63,3,237,4,0,
63,3,240,4,0,
63,3,241,4,0,
63,3,244,4,0,
63,3,247,4,0,
63,4,7,4,0,
63,4,8,4,0,
63,4,9,4,0,
63,4,29,4,0,
63,4,92,4,0,
63,4,94,4,0,
63,4,100,1,1,
63,4,104,4,0,
63,4,112,2,0,
63,4,113,2,0,
63,4,138,4,0,
63,4,148,4,0,
63,4,156,4,0,
63,4,168,4,0,
63,4,171,4,0,
63,4,173,4,0,
63,4,174,4,0,
63,4,182,4,0,
63,4,192,4,0,
63,4,203,4,0,
63,4,207,4,0,
63,4,213,4,0,
63,4,214,4,0,
63,4,216,4,0,
63,4,218,4,0,
63,4,223,4,0,
63,4,227,2,0,
63,4,237,4,0,
63,4,240,4,0,
63,4,241,4,0,
63,4,244,4,0,
63,4,247,4,0,
63,5,7,2,0,
63,5,8,2,0,
63,5,9,2,0,
63,5,92,4,0,
63,5,94,4,0,
63,5,100,1,1,
63,5,104,4,0,
63,5,112,2,0,
63,5,113,4,0,
63,5,115,4,0,
63,5,148,4,0,
63,5,156,4,0,
63,5,168,4,0,
63,5,182,4,0,
63,5,213,4,0,
63,5,216,4,0,
63,5,218,4,0,
63,5,219,4,0,
63,5,227,2,0,
63,5,231,4,0,
63,5,237,4,0,
63,5,240,4,0,
63,5,241,4,0,
63,5,247,4,0,
63,5,259,4,0,
63,5,263,4,0,
63,5,264,4,0,
63,5,269,4,0,
63,5,282,2,0,
63,5,285,4,0,
63,5,289,4,0,
63,5,290,4,0,
63,5,347,4,0,
63,5,351,4,0,
63,6,5,3,0,
63,6,7,2,0,
63,6,7,3,0,
63,6,8,2,0,
63,6,8,3,0,
63,6,9,2,0,
63,6,9,3,0,
63,6,25,3,0,
63,6,34,3,0,
63,6,38,3,0,
63,6,68,3,0,
63,6,69,3,0,
63,6,86,3,0,
63,6,92,4,0,
63,6,94,4,0,
63,6,100,1,1,
63,6,102,3,0,
63,6,104,4,0,
63,6,112,2,0,
63,6,113,4,0,
63,6,115,4,0,
63,6,118,3,0,
63,6,138,3,0,
63,6,148,4,0,
63,6,156,4,0,
63,6,164,3,0,
63,6,168,4,0,
63,6,173,3,0,
63,6,182,4,0,
63,6,203,3,0,
63,6,207,3,0,
63,6,213,4,0,
63,6,214,3,0,
63,6,216,4,0,
63,6,218,4,0,
63,6,219,4,0,
63,6,223,3,0,
63,6,227,2,0,
63,6,231,4,0,
63,6,237,4,0,
63,6,240,4,0,
63,6,241,4,0,
63,6,244,3,0,
63,6,247,4,0,
63,6,259,4,0,
63,6,263,4,0,
63,6,264,4,0,
63,6,269,4,0,
63,6,282,2,0,
63,6,285,4,0,
63,6,289,4,0,
63,6,290,4,0,
63,6,347,4,0,
63,6,351,4,0,
63,7,5,3,0,
63,7,7,2,0,
63,7,8,2,0,
63,7,9,2,0,
63,7,25,3,0,
63,7,34,3,0,
63,7,38,3,0,
63,7,68,3,0,
63,7,69,3,0,
63,7,86,3,0,
63,7,92,4,0,
63,7,94,4,0,
63,7,100,1,1,
63,7,102,3,0,
63,7,104,4,0,
63,7,112,2,0,
63,7,113,4,0,
63,7,115,4,0,
63,7,118,3,0,
63,7,138,3,0,
63,7,148,4,0,
63,7,156,4,0,
63,7,164,3,0,
63,7,168,4,0,
63,7,182,4,0,
63,7,213,4,0,
63,7,216,4,0,
63,7,218,4,0,
63,7,219,4,0,
63,7,227,2,0,
63,7,231,4,0,
63,7,237,4,0,
63,7,240,4,0,
63,7,241,4,0,
63,7,247,4,0,
63,7,259,4,0,
63,7,263,4,0,
63,7,264,4,0,
63,7,269,4,0,
63,7,282,2,0,
63,7,285,4,0,
63,7,289,4,0,
63,7,290,4,0,
63,7,347,4,0,
63,7,351,4,0,
63,8,7,2,0,
63,8,8,2,0,
63,8,9,2,0,
63,8,86,4,0,
63,8,92,4,0,
63,8,94,4,0,
63,8,100,1,1,
63,8,104,4,0,
63,8,112,2,0,
63,8,113,4,0,
63,8,115,4,0,
63,8,138,4,0,
63,8,148,4,0,
63,8,156,4,0,
63,8,164,4,0,
63,8,168,4,0,
63,8,182,4,0,
63,8,203,4,0,
63,8,207,4,0,
63,8,213,4,0,
63,8,214,4,0,
63,8,216,4,0,
63,8,218,4,0,
63,8,219,4,0,
63,8,227,2,0,
63,8,231,4,0,
63,8,237,4,0,
63,8,240,4,0,
63,8,241,4,0,
63,8,244,4,0,
63,8,247,4,0,
63,8,259,4,0,
63,8,263,4,0,
63,8,264,4,0,
63,8,269,4,0,
63,8,278,4,0,
63,8,282,2,0,
63,8,285,4,0,
63,8,289,4,0,
63,8,290,4,0,
63,8,347,4,0,
63,8,351,4,0,
63,8,363,4,0,
63,8,373,4,0,
63,8,374,4,0,
63,8,379,2,0,
63,8,385,2,0,
63,8,409,4,0,
63,8,412,4,0,
63,8,433,4,0,
63,8,445,4,0,
63,8,447,4,0,
63,8,451,4,0,
63,9,7,2,0,
63,9,7,3,0,
63,9,8,2,0,
63,9,8,3,0,
63,9,9,2,0,
63,9,9,3,0,
63,9,86,4,0,
63,9,92,4,0,
63,9,94,4,0,
63,9,100,1,1,
63,9,104,4,0,
63,9,112,2,0,
63,9,113,4,0,
63,9,115,4,0,
63,9,138,4,0,
63,9,148,4,0,
63,9,156,4,0,
63,9,164,4,0,
63,9,168,4,0,
63,9,173,3,0,
63,9,182,4,0,
63,9,203,4,0,
63,9,207,4,0,
63,9,213,4,0,
63,9,214,4,0,
63,9,216,4,0,
63,9,218,4,0,
63,9,219,4,0,
63,9,227,2,0,
63,9,231,4,0,
63,9,237,4,0,
63,9,240,4,0,
63,9,241,4,0,
63,9,244,4,0,
63,9,247,4,0,
63,9,259,4,0,
63,9,263,4,0,
63,9,264,4,0,
63,9,269,4,0,
63,9,271,3,0,
63,9,278,4,0,
63,9,282,2,0,
63,9,282,3,0,
63,9,285,4,0,
63,9,289,4,0,
63,9,290,4,0,
63,9,324,3,0,
63,9,347,4,0,
63,9,351,4,0,
63,9,363,4,0,
63,9,373,4,0,
63,9,374,4,0,
63,9,379,2,0,
63,9,385,2,0,
63,9,409,4,0,
63,9,412,4,0,
63,9,428,3,0,
63,9,433,4,0,
63,9,445,4,0,
63,9,447,4,0,
63,9,451,4,0,
63,10,7,2,0,
63,10,7,3,0,
63,10,8,2,0,
63,10,8,3,0,
63,10,9,2,0,
63,10,9,3,0,
63,10,29,3,0,
63,10,86,4,0,
63,10,92,4,0,
63,10,94,4,0,
63,10,100,1,1,
63,10,104,4,0,
63,10,112,2,0,
63,10,113,4,0,
63,10,115,4,0,
63,10,138,4,0,
63,10,148,4,0,
63,10,156,4,0,
63,10,164,4,0,
63,10,168,4,0,
63,10,173,3,0,
63,10,182,4,0,
63,10,203,4,0,
63,10,207,4,0,
63,10,213,4,0,
63,10,214,4,0,
63,10,216,4,0,
63,10,218,4,0,
63,10,219,4,0,
63,10,227,2,0,
63,10,231,4,0,
63,10,237,4,0,
63,10,240,4,0,
63,10,241,4,0,
63,10,244,4,0,
63,10,247,4,0,
63,10,259,4,0,
63,10,263,4,0,
63,10,264,4,0,
63,10,269,4,0,
63,10,271,3,0,
63,10,272,3,0,
63,10,277,3,0,
63,10,278,4,0,
63,10,282,2,0,
63,10,282,3,0,
63,10,285,4,0,
63,10,289,4,0,
63,10,290,4,0,
63,10,324,3,0,
63,10,347,4,0,
63,10,351,4,0,
63,10,356,3,0,
63,10,363,4,0,
63,10,373,4,0,
63,10,374,4,0,
63,10,379,2,0,
63,10,385,2,0,
63,10,409,4,0,
63,10,412,4,0,
63,10,428,3,0,
63,10,433,4,0,
63,10,445,4,0,
63,10,447,4,0,
63,10,451,4,0,
63,11,7,2,0,
63,11,8,2,0,
63,11,9,2,0,
63,11,86,4,0,
63,11,92,4,0,
63,11,94,4,0,
63,11,100,1,1,
63,11,104,4,0,
63,11,112,2,0,
63,11,113,4,0,
63,11,115,4,0,
63,11,138,4,0,
63,11,148,4,0,
63,11,156,4,0,
63,11,164,4,0,
63,11,168,4,0,
63,11,182,4,0,
63,11,207,4,0,
63,11,213,4,0,
63,11,216,4,0,
63,11,218,4,0,
63,11,219,4,0,
63,11,227,2,0,
63,11,237,4,0,
63,11,240,4,0,
63,11,241,4,0,
63,11,244,4,0,
63,11,247,4,0,
63,11,259,4,0,
63,11,263,4,0,
63,11,269,4,0,
63,11,282,2,0,
63,11,285,2,0,
63,11,347,4,0,
63,11,373,4,0,
63,11,374,4,0,
63,11,379,2,0,
63,11,385,2,0,
63,11,412,4,0,
63,11,433,4,0,
63,11,447,4,0,
63,11,451,4,0,
63,11,470,2,0,
63,11,473,4,0,
63,11,477,4,0,
63,11,496,4,0,
63,11,502,4,0,
63,12,92,4,0,
63,12,94,4,0,
63,12,100,1,1,
63,12,104,4,0,
63,12,113,4,0,
63,12,115,4,0,
63,12,148,4,0,
63,12,156,4,0,
63,12,168,4,0,
63,12,182,4,0,
63,12,213,4,0,
63,12,216,4,0,
63,12,218,4,0,
63,12,219,4,0,
63,12,231,4,0,
63,12,237,4,0,
63,12,240,4,0,
63,12,241,4,0,
63,12,247,4,0,
63,12,259,4,0,
63,12,263,4,0,
63,12,264,4,0,
63,12,269,4,0,
63,12,285,4,0,
63,12,289,4,0,
63,12,290,4,0,
63,12,347,4,0,
63,12,351,4,0,
63,13,34,3,0,
63,13,38,3,0,
63,13,69,3,0,
63,13,86,3,0,
63,13,92,4,0,
63,13,94,4,0,
63,13,100,1,1,
63,13,102,3,0,
63,13,104,4,0,
63,13,113,4,0,
63,13,115,4,0,
63,13,138,3,0,
63,13,148,4,0,
63,13,156,4,0,
63,13,164,3,0,
63,13,168,4,0,
63,13,171,3,0,
63,13,182,4,0,
63,13,207,3,0,
63,13,213,4,0,
63,13,216,4,0,
63,13,218,4,0,
63,13,219,4,0,
63,13,231,4,0,
63,13,237,4,0,
63,13,240,4,0,
63,13,241,4,0,
63,13,247,4,0,
63,13,259,4,0,
63,13,263,4,0,
63,13,264,4,0,
63,13,269,4,0,
63,13,285,4,0,
63,13,289,4,0,
63,13,290,4,0,
63,13,347,4,0,
63,13,351,4,0,
63,14,7,2,0,
63,14,7,3,0,
63,14,8,2,0,
63,14,8,3,0,
63,14,9,2,0,
63,14,9,3,0,
63,14,86,4,0,
63,14,92,4,0,
63,14,94,4,0,
63,14,100,1,1,
63,14,104,4,0,
63,14,112,2,0,
63,14,113,4,0,
63,14,115,4,0,
63,14,138,4,0,
63,14,148,4,0,
63,14,156,4,0,
63,14,164,4,0,
63,14,168,4,0,
63,14,173,3,0,
63,14,182,4,0,
63,14,207,4,0,
63,14,213,4,0,
63,14,214,3,0,
63,14,216,4,0,
63,14,218,4,0,
63,14,219,4,0,
63,14,227,2,0,
63,14,231,3,0,
63,14,237,4,0,
63,14,240,4,0,
63,14,241,4,0,
63,14,244,4,0,
63,14,247,4,0,
63,14,259,4,0,
63,14,263,4,0,
63,14,269,4,0,
63,14,271,3,0,
63,14,272,3,0,
63,14,277,3,0,
63,14,278,3,0,
63,14,282,2,0,
63,14,282,3,0,
63,14,285,2,0,
63,14,285,3,0,
63,14,289,3,0,
63,14,324,3,0,
63,14,347,4,0,
63,14,356,3,0,
63,14,373,4,0,
63,14,374,4,0,
63,14,379,2,0,
63,14,385,2,0,
63,14,409,3,0,
63,14,412,4,0,
63,14,428,3,0,
63,14,433,4,0,
63,14,447,4,0,
63,14,451,4,0,
63,14,470,2,0,
63,14,472,3,0,
63,14,473,4,0,
63,14,477,4,0,
63,14,478,3,0,
63,14,492,3,0,
63,14,496,4,0,
63,14,502,4,0,
63,15,7,2,0,
63,15,8,2,0,
63,15,9,2,0,
63,15,86,4,0,
63,15,92,4,0,
63,15,94,4,0,
63,15,100,1,1,
63,15,104,4,0,
63,15,112,2,0,
63,15,113,4,0,
63,15,115,4,0,
63,15,138,4,0,
63,15,148,4,0,
63,15,156,4,0,
63,15,164,4,0,
63,15,168,4,0,
63,15,182,4,0,
63,15,207,4,0,
63,15,213,4,0,
63,15,214,4,0,
63,15,216,4,0,
63,15,218,4,0,
63,15,219,4,0,
63,15,227,2,0,
63,15,237,4,0,
63,15,240,4,0,
63,15,241,4,0,
63,15,244,4,0,
63,15,247,4,0,
63,15,259,4,0,
63,15,263,4,0,
63,15,269,4,0,
63,15,282,2,0,
63,15,285,2,0,
63,15,347,4,0,
63,15,373,4,0,
63,15,374,4,0,
63,15,375,2,0,
63,15,379,2,0,
63,15,385,2,0,
63,15,412,4,0,
63,15,433,4,0,
63,15,447,4,0,
63,15,451,4,0,
63,15,470,2,0,
63,15,473,4,0,
63,15,496,4,0,
63,15,502,2,0,
63,15,590,4,0,
63,15,605,4,0,
63,16,7,2,0,
63,16,7,3,0,
63,16,8,2,0,
63,16,8,3,0,
63,16,9,2,0,
63,16,9,3,0,
63,16,86,4,0,
63,16,92,4,0,
63,16,94,4,0,
63,16,100,1,1,1
63,16,104,4,0,
63,16,112,2,0,
63,16,113,4,0,
63,16,115,4,0,
63,16,138,4,0,
63,16,148,4,0,
63,16,156,4,0,
63,16,164,4,0,
63,16,168,4,0,
63,16,173,3,0,
63,16,182,4,0,
63,16,207,4,0,
63,16,213,4,0,
63,16,214,4,0,
63,16,216,4,0,
63,16,218,4,0,
63,16,219,4,0,
63,16,227,2,0,
63,16,231,3,0,
63,16,237,4,0,
63,16,240,4,0,
63,16,241,4,0,
63,16,244,4,0,
63,16,247,4,0,
63,16,259,4,0,
63,16,263,4,0,
63,16,264,3,0,
63,16,269,4,0,
63,16,271,3,0,
63,16,272,3,0,
63,16,277,3,0,
63,16,278,3,0,
63,16,282,2,0,
63,16,282,3,0,
63,16,285,2,0,
63,16,285,3,0,
63,16,289,3,0,
63,16,290,4,0,
63,16,324,3,0,
63,16,347,4,0,
63,16,351,3,0,
63,16,356,3,0,
63,16,373,4,0,
63,16,374,4,0,
63,16,375,2,0,
63,16,379,2,0,
63,16,385,2,0,
63,16,409,3,0,
63,16,412,4,0,
63,16,428,3,0,
63,16,433,4,0,
63,16,447,4,0,
63,16,451,4,0,
63,16,470,2,0,
63,16,472,3,0,
63,16,473,4,0,
63,16,478,3,0,
63,16,492,3,0,
63,16,496,4,0,
63,16,502,2,0,
63,16,590,4,0,
63,16,605,4,0,
63,17,7,2,0,
63,17,8,2,0,
63,17,9,2,0,
63,17,86,4,0,
63,17,92,4,0,
63,17,94,4,0,
63,17,100,1,1,
63,17,104,4,0,
63,17,112,2,0,
63,17,113,4,0,
63,17,115,4,0,
63,17,138,4,0,
63,17,156,4,0,
63,17,164,4,0,
63,17,168,4,0,
63,17,182,4,0,
63,17,207,4,0,
63,17,213,4,0,
63,17,214,4,0,
63,17,216,4,0,
63,17,218,4,0,
63,17,219,4,0,
63,17,227,2,0,
63,17,237,4,0,
63,17,240,4,0,
63,17,241,4,0,
63,17,244,4,0,
63,17,247,4,0,
63,17,259,4,0,
63,17,263,4,0,
63,17,269,4,0,
63,17,282,2,0,
63,17,285,2,0,
63,17,347,4,0,
63,17,373,4,0,
63,17,374,4,0,
63,17,375,2,0,
63,17,379,2,0,
63,17,385,2,0,
63,17,412,4,0,
63,17,433,4,0,
63,17,447,4,0,
63,17,451,4,0,
63,17,470,2,0,
63,17,473,4,0,
63,17,496,4,0,
63,17,502,2,0,
63,17,590,4,0,
63,17,605,4,0,
63,18,7,2,0,
63,18,8,2,0,
63,18,9,2,0,
63,18,86,4,0,
63,18,92,4,0,
63,18,94,4,0,
63,18,100,1,1,
63,18,104,4,0,
63,18,112,2,0,
63,18,113,4,0,
63,18,115,4,0,
63,18,138,4,0,
63,18,156,4,0,
63,18,164,4,0,
63,18,168,4,0,
63,18,182,4,0,
63,18,207,4,0,
63,18,213,4,0,
63,18,214,4,0,
63,18,216,4,0,
63,18,218,4,0,
63,18,219,4,0,
63,18,227,2,0,
63,18,237,4,0,
63,18,240,4,0,
63,18,241,4,0,
63,18,244,4,0,
63,18,247,4,0,
63,18,259,4,0,
63,18,263,4,0,
63,18,269,4,0,
63,18,282,2,0,
63,18,285,2,0,
63,18,347,4,0,
63,18,373,4,0,
63,18,374,4,0,
63,18,375,2,0,
63,18,379,2,0,
63,18,385,2,0,
63,18,412,4,0,
63,18,433,4,0,
63,18,447,4,0,
63,18,451,4,0,
63,18,470,2,0,
63,18,473,4,0,
63,18,496,4,0,
63,18,502,2,0,
63,18,590,4,0,
63,18,605,4,0,
63,18,678,2,0,
64,1,5,4,0,
64,1,25,4,0,
64,1,34,4,0,
64,1,36,4,0,
64,1,38,4,0,
64,1,50,1,1,3
64,1,50,1,20,
64,1,60,1,27,
64,1,66,4,0,
64,1,68,4,0,
64,1,69,4,0,
64,1,86,4,0,
64,1,91,4,0,
64,1,92,4,0,
64,1,93,1,1,2
64,1,93,1,16,
64,1,94,1,38,
64,1,94,4,0,
64,1,99,4,0,
64,1,100,1,1,1
64,1,100,4,0,
64,1,102,4,0,
64,1,104,4,0,
64,1,105,1,31,
64,1,115,1,42,
64,1,115,4,0,
64,1,117,4,0,
64,1,118,4,0,
64,1,130,4,0,
64,1,148,4,0,
64,1,149,4,0,
64,1,156,4,0,
64,1,161,4,0,
64,1,164,4,0,
64,2,5,4,0,
64,2,25,4,0,
64,2,34,4,0,
64,2,36,4,0,
64,2,38,4,0,
64,2,50,1,20,
64,2,60,1,27,
64,2,66,4,0,
64,2,68,4,0,
64,2,69,4,0,
64,2,86,4,0,
64,2,91,4,0,
64,2,92,4,0,
64,2,93,1,16,
64,2,94,1,38,
64,2,94,4,0,
64,2,99,4,0,
64,2,100,1,1,1
64,2,100,4,0,
64,2,102,4,0,
64,2,104,4,0,
64,2,105,1,31,
64,2,115,1,42,
64,2,115,4,0,
64,2,117,4,0,
64,2,118,4,0,
64,2,130,4,0,
64,2,134,1,1,2
64,2,148,4,0,
64,2,149,4,0,
64,2,156,4,0,
64,2,161,4,0,
64,2,164,4,0,
64,3,7,4,0,
64,3,8,4,0,
64,3,9,4,0,
64,3,29,4,0,
64,3,50,1,18,
64,3,60,1,21,
64,3,91,4,0,
64,3,92,4,0,
64,3,93,1,1,3
64,3,93,1,16,
64,3,94,1,38,
64,3,94,4,0,
64,3,100,1,1,1
64,3,104,4,0,
64,3,105,1,26,
64,3,115,1,45,
64,3,134,1,1,2
64,3,138,4,0,
64,3,148,4,0,
64,3,156,4,0,
64,3,168,4,0,
64,3,171,4,0,
64,3,173,4,0,
64,3,174,4,0,
64,3,182,4,0,
64,3,192,4,0,
64,3,203,4,0,
64,3,207,4,0,
64,3,213,4,0,
64,3,214,4,0,
64,3,216,4,0,
64,3,218,4,0,
64,3,223,4,0,
64,3,237,4,0,
64,3,240,4,0,
64,3,241,4,0,
64,3,244,4,0,
64,3,247,4,0,
64,3,248,1,31,
64,4,7,4,0,
64,4,8,4,0,
64,4,9,4,0,
64,4,29,4,0,
64,4,50,1,18,
64,4,60,1,21,
64,4,91,4,0,
64,4,92,4,0,
64,4,93,1,1,3
64,4,93,1,16,
64,4,94,1,38,
64,4,94,4,0,
64,4,100,1,1,1
64,4,104,4,0,
64,4,105,1,26,
64,4,115,1,45,
64,4,134,1,1,2
64,4,138,4,0,
64,4,148,4,0,
64,4,156,4,0,
64,4,168,4,0,
64,4,171,4,0,
64,4,173,4,0,
64,4,174,4,0,
64,4,182,4,0,
64,4,192,4,0,
64,4,203,4,0,
64,4,207,4,0,
64,4,213,4,0,
64,4,214,4,0,
64,4,216,4,0,
64,4,218,4,0,
64,4,223,4,0,
64,4,237,4,0,
64,4,240,4,0,
64,4,241,4,0,
64,4,244,4,0,
64,4,247,4,0,
64,4,248,1,31,
64,5,50,1,18,
64,5,60,1,21,
64,5,92,4,0,
64,5,93,1,1,3
64,5,93,1,16,
64,5,94,1,36,
64,5,94,4,0,
64,5,100,1,1,1
64,5,104,4,0,
64,5,105,1,25,
64,5,113,4,0,
64,5,115,1,23,
64,5,115,4,0,
64,5,134,1,1,2
64,5,148,4,0,
64,5,156,4,0,
64,5,168,4,0,
64,5,182,4,0,
64,5,213,4,0,
64,5,216,4,0,
64,5,218,4,0,
64,5,219,4,0,
64,5,231,4,0,
64,5,237,4,0,
64,5,240,4,0,
64,5,241,4,0,
64,5,247,4,0,
64,5,248,1,30,
64,5,259,4,0,
64,5,263,4,0,
64,5,264,4,0,
64,5,269,4,0,
64,5,271,1,43,
64,5,272,1,33,
64,5,285,4,0,
64,5,289,4,0,
64,5,290,4,0,
64,5,347,4,0,
64,5,351,4,0,
64,6,5,3,0,
64,6,7,3,0,
64,6,8,3,0,
64,6,9,3,0,
64,6,25,3,0,
64,6,34,3,0,
64,6,38,3,0,
64,6,50,1,18,
64,6,60,1,21,
64,6,68,3,0,
64,6,69,3,0,
64,6,86,3,0,
64,6,92,4,0,
64,6,93,1,1,3
64,6,93,1,16,
64,6,94,1,36,
64,6,94,4,0,
64,6,100,1,1,1
64,6,102,3,0,
64,6,104,4,0,
64,6,105,1,25,
64,6,113,4,0,
64,6,115,1,23,
64,6,115,4,0,
64,6,118,3,0,
64,6,134,1,1,2
64,6,138,3,0,
64,6,148,4,0,
64,6,156,4,0,
64,6,164,3,0,
64,6,168,4,0,
64,6,173,3,0,
64,6,182,4,0,
64,6,203,3,0,
64,6,207,3,0,
64,6,213,4,0,
64,6,214,3,0,
64,6,216,4,0,
64,6,218,4,0,
64,6,219,4,0,
64,6,223,3,0,
64,6,231,4,0,
64,6,237,4,0,
64,6,240,4,0,
64,6,241,4,0,
64,6,244,3,0,
64,6,247,4,0,
64,6,248,1,30,
64,6,259,4,0,
64,6,263,4,0,
64,6,264,4,0,
64,6,269,4,0,
64,6,271,1,43,
64,6,272,1,33,
64,6,285,4,0,
64,6,289,4,0,
64,6,290,4,0,
64,6,347,4,0,
64,6,351,4,0,
64,7,5,3,0,
64,7,25,3,0,
64,7,34,3,0,
64,7,38,3,0,
64,7,50,1,18,
64,7,60,1,21,
64,7,68,3,0,
64,7,69,3,0,
64,7,86,3,0,
64,7,92,4,0,
64,7,93,1,1,3
64,7,93,1,16,
64,7,94,1,36,
64,7,94,4,0,
64,7,100,1,1,1
64,7,102,3,0,
64,7,104,4,0,
64,7,105,1,25,
64,7,113,4,0,
64,7,115,1,23,
64,7,115,4,0,
64,7,118,3,0,
64,7,134,1,1,2
64,7,138,3,0,
64,7,148,4,0,
64,7,156,4,0,
64,7,164,3,0,
64,7,168,4,0,
64,7,182,4,0,
64,7,213,4,0,
64,7,216,4,0,
64,7,218,4,0,
64,7,219,4,0,
64,7,231,4,0,
64,7,237,4,0,
64,7,240,4,0,
64,7,241,4,0,
64,7,247,4,0,
64,7,248,1,30,
64,7,259,4,0,
64,7,263,4,0,
64,7,264,4,0,
64,7,269,4,0,
64,7,271,1,43,
64,7,272,1,33,
64,7,285,4,0,
64,7,289,4,0,
64,7,290,4,0,
64,7,347,4,0,
64,7,351,4,0,
64,8,50,1,18,
64,8,60,1,24,
64,8,86,4,0,
64,8,92,4,0,
64,8,93,1,1,3
64,8,93,1,16,
64,8,94,1,40,
64,8,94,4,0,
64,8,100,1,1,1
64,8,104,4,0,
64,8,105,1,30,
64,8,113,4,0,
64,8,115,1,28,
64,8,115,4,0,
64,8,134,1,1,2
64,8,138,4,0,
64,8,148,4,0,
64,8,156,4,0,
64,8,164,4,0,
64,8,168,4,0,
64,8,182,4,0,
64,8,203,4,0,
64,8,207,4,0,
64,8,213,4,0,
64,8,214,4,0,
64,8,216,4,0,
64,8,218,4,0,
64,8,219,4,0,
64,8,231,4,0,
64,8,237,4,0,
64,8,240,4,0,
64,8,241,4,0,
64,8,244,4,0,
64,8,247,4,0,
64,8,248,1,42,
64,8,259,4,0,
64,8,263,4,0,
64,8,264,4,0,
64,8,269,4,0,
64,8,271,1,46,
64,8,272,1,36,
64,8,278,4,0,
64,8,285,4,0,
64,8,289,4,0,
64,8,290,4,0,
64,8,347,4,0,
64,8,351,4,0,
64,8,357,1,22,
64,8,363,4,0,
64,8,373,4,0,
64,8,374,4,0,
64,8,409,4,0,
64,8,412,4,0,
64,8,427,1,34,
64,8,433,4,0,
64,8,445,4,0,
64,8,447,4,0,
64,8,451,4,0,
64,9,7,3,0,
64,9,8,3,0,
64,9,9,3,0,
64,9,50,1,18,
64,9,60,1,24,
64,9,86,4,0,
64,9,92,4,0,
64,9,93,1,1,3
64,9,93,1,16,
64,9,94,1,40,
64,9,94,4,0,
64,9,100,1,1,1
64,9,104,4,0,
64,9,105,1,30,
64,9,113,4,0,
64,9,115,1,28,
64,9,115,4,0,
64,9,134,1,1,2
64,9,138,4,0,
64,9,148,4,0,
64,9,156,4,0,
64,9,164,4,0,
64,9,168,4,0,
64,9,173,3,0,
64,9,182,4,0,
64,9,203,4,0,
64,9,207,4,0,
64,9,213,4,0,
64,9,214,4,0,
64,9,216,4,0,
64,9,218,4,0,
64,9,219,4,0,
64,9,231,4,0,
64,9,237,4,0,
64,9,240,4,0,
64,9,241,4,0,
64,9,244,4,0,
64,9,247,4,0,
64,9,248,1,42,
64,9,259,4,0,
64,9,263,4,0,
64,9,264,4,0,
64,9,269,4,0,
64,9,271,1,46,
64,9,271,3,0,
64,9,272,1,36,
64,9,278,4,0,
64,9,282,3,0,
64,9,285,4,0,
64,9,289,4,0,
64,9,290,4,0,
64,9,324,3,0,
64,9,347,4,0,
64,9,351,4,0,
64,9,357,1,22,
64,9,363,4,0,
64,9,373,4,0,
64,9,374,4,0,
64,9,409,4,0,
64,9,412,4,0,
64,9,427,1,34,
64,9,428,3,0,
64,9,433,4,0,
64,9,445,4,0,
64,9,447,4,0,
64,9,451,4,0,
64,10,7,3,0,
64,10,8,3,0,
64,10,9,3,0,
64,10,29,3,0,
64,10,50,1,18,
64,10,60,1,24,
64,10,86,4,0,
64,10,92,4,0,
64,10,93,1,1,3
64,10,93,1,16,
64,10,94,1,40,
64,10,94,4,0,
64,10,100,1,1,1
64,10,104,4,0,
64,10,105,1,30,
64,10,113,4,0,
64,10,115,1,28,
64,10,115,4,0,
64,10,134,1,1,2
64,10,138,4,0,
64,10,148,4,0,
64,10,156,4,0,
64,10,164,4,0,
64,10,168,4,0,
64,10,173,3,0,
64,10,182,4,0,
64,10,203,4,0,
64,10,207,4,0,
64,10,213,4,0,
64,10,214,4,0,
64,10,216,4,0,
64,10,218,4,0,
64,10,219,4,0,
64,10,231,4,0,
64,10,237,4,0,
64,10,240,4,0,
64,10,241,4,0,
64,10,244,4,0,
64,10,247,4,0,
64,10,248,1,42,
64,10,259,4,0,
64,10,263,4,0,
64,10,264,4,0,
64,10,269,4,0,
64,10,271,1,46,
64,10,271,3,0,
64,10,272,1,36,
64,10,272,3,0,
64,10,277,3,0,
64,10,278,4,0,
64,10,282,3,0,
64,10,285,4,0,
64,10,289,4,0,
64,10,290,4,0,
64,10,324,3,0,
64,10,347,4,0,
64,10,351,4,0,
64,10,356,3,0,
64,10,357,1,22,
64,10,363,4,0,
64,10,373,4,0,
64,10,374,4,0,
64,10,409,4,0,
64,10,412,4,0,
64,10,427,1,34,
64,10,428,3,0,
64,10,433,4,0,
64,10,445,4,0,
64,10,447,4,0,
64,10,451,4,0,
64,11,50,1,18,
64,11,60,1,28,
64,11,86,4,0,
64,11,92,4,0,
64,11,93,1,1,3
64,11,93,1,16,
64,11,94,1,46,
64,11,94,4,0,
64,11,100,1,1,1
64,11,104,4,0,
64,11,105,1,36,
64,11,113,4,0,
64,11,115,1,30,
64,11,115,4,0,
64,11,134,1,1,2
64,11,138,4,0,
64,11,148,4,0,
64,11,156,4,0,
64,11,164,4,0,
64,11,168,4,0,
64,11,182,4,0,
64,11,207,4,0,
64,11,213,4,0,
64,11,216,4,0,
64,11,218,4,0,
64,11,219,4,0,
64,11,237,4,0,
64,11,240,4,0,
64,11,241,4,0,
64,11,244,4,0,
64,11,247,4,0,
64,11,248,1,48,
64,11,259,4,0,
64,11,263,4,0,
64,11,269,4,0,
64,11,271,1,52,
64,11,272,1,42,
64,11,347,4,0,
64,11,357,1,22,
64,11,373,4,0,
64,11,374,4,0,
64,11,412,4,0,
64,11,427,1,40,
64,11,433,4,0,
64,11,447,4,0,
64,11,451,4,0,
64,11,473,4,0,
64,11,477,1,34,
64,11,477,4,0,
64,11,496,4,0,
64,11,502,1,24,
64,11,502,4,0,
64,12,50,1,18,
64,12,60,1,21,
64,12,92,4,0,
64,12,93,1,1,3
64,12,93,1,16,
64,12,94,1,36,
64,12,94,4,0,
64,12,100,1,1,1
64,12,104,4,0,
64,12,105,1,25,
64,12,113,4,0,
64,12,115,1,23,
64,12,115,4,0,
64,12,134,1,1,2
64,12,148,4,0,
64,12,156,4,0,
64,12,168,4,0,
64,12,182,4,0,
64,12,213,4,0,
64,12,216,4,0,
64,12,218,4,0,
64,12,219,4,0,
64,12,231,4,0,
64,12,237,4,0,
64,12,240,4,0,
64,12,241,4,0,
64,12,247,4,0,
64,12,248,1,30,
64,12,259,4,0,
64,12,263,4,0,
64,12,264,4,0,
64,12,269,4,0,
64,12,271,1,43,
64,12,272,1,33,
64,12,285,4,0,
64,12,289,4,0,
64,12,290,4,0,
64,12,347,4,0,
64,12,351,4,0,
64,13,34,3,0,
64,13,38,3,0,
64,13,50,1,18,
64,13,60,1,21,
64,13,69,3,0,
64,13,86,3,0,
64,13,92,4,0,
64,13,93,1,1,3
64,13,93,1,16,
64,13,94,1,36,
64,13,94,4,0,
64,13,100,1,1,1
64,13,102,3,0,
64,13,104,4,0,
64,13,105,1,25,
64,13,113,4,0,
64,13,115,1,23,
64,13,115,4,0,
64,13,134,1,1,2
64,13,138,3,0,
64,13,148,4,0,
64,13,156,4,0,
64,13,164,3,0,
64,13,168,4,0,
64,13,171,3,0,
64,13,182,4,0,
64,13,207,3,0,
64,13,213,4,0,
64,13,216,4,0,
64,13,218,4,0,
64,13,219,4,0,
64,13,231,4,0,
64,13,237,4,0,
64,13,240,4,0,
64,13,241,4,0,
64,13,247,4,0,
64,13,248,1,30,
64,13,259,4,0,
64,13,263,4,0,
64,13,264,4,0,
64,13,269,4,0,
64,13,271,1,43,
64,13,272,1,33,
64,13,285,4,0,
64,13,289,4,0,
64,13,290,4,0,
64,13,347,4,0,
64,13,351,4,0,
64,14,7,3,0,
64,14,8,3,0,
64,14,9,3,0,
64,14,50,1,18,
64,14,60,1,28,
64,14,86,4,0,
64,14,92,4,0,
64,14,93,1,1,3
64,14,93,1,16,
64,14,94,1,46,
64,14,94,4,0,
64,14,100,1,1,1
64,14,104,4,0,
64,14,105,1,36,
64,14,113,4,0,
64,14,115,1,30,
64,14,115,4,0,
64,14,134,1,1,2
64,14,138,4,0,
64,14,148,4,0,
64,14,156,4,0,
64,14,164,4,0,
64,14,168,4,0,
64,14,173,3,0,
64,14,182,4,0,
64,14,207,4,0,
64,14,213,4,0,
64,14,214,3,0,
64,14,216,4,0,
64,14,218,4,0,
64,14,219,4,0,
64,14,231,3,0,
64,14,237,4,0,
64,14,240,4,0,
64,14,241,4,0,
64,14,244,4,0,
64,14,247,4,0,
64,14,248,1,48,
64,14,259,4,0,
64,14,263,4,0,
64,14,269,4,0,
64,14,271,1,52,
64,14,271,3,0,
64,14,272,1,42,
64,14,272,3,0,
64,14,277,3,0,
64,14,278,3,0,
64,14,282,3,0,
64,14,285,3,0,
64,14,289,3,0,
64,14,324,3,0,
64,14,347,4,0,
64,14,356,3,0,
64,14,357,1,22,
64,14,373,4,0,
64,14,374,4,0,
64,14,409,3,0,
64,14,412,4,0,
64,14,427,1,40,
64,14,428,3,0,
64,14,433,4,0,
64,14,447,4,0,
64,14,451,4,0,
64,14,472,3,0,
64,14,473,4,0,
64,14,477,1,34,
64,14,477,4,0,
64,14,478,3,0,
64,14,492,3,0,
64,14,496,4,0,
64,14,502,1,24,
64,14,502,4,0,
64,15,50,1,18,
64,15,60,1,28,
64,15,86,4,0,
64,15,92,4,0,
64,15,93,1,1,3
64,15,93,1,16,
64,15,94,1,46,
64,15,94,4,0,
64,15,100,1,1,1
64,15,104,4,0,
64,15,105,1,36,
64,15,113,4,0,
64,15,115,1,30,
64,15,115,4,0,
64,15,134,1,1,2
64,15,138,4,0,
64,15,148,4,0,
64,15,156,4,0,
64,15,164,4,0,
64,15,168,4,0,
64,15,182,4,0,
64,15,207,4,0,
64,15,213,4,0,
64,15,214,4,0,
64,15,216,4,0,
64,15,218,4,0,
64,15,219,4,0,
64,15,237,4,0,
64,15,240,4,0,
64,15,241,4,0,
64,15,244,4,0,
64,15,247,4,0,
64,15,248,1,48,
64,15,259,4,0,
64,15,263,4,0,
64,15,269,4,0,
64,15,271,1,50,
64,15,272,1,42,
64,15,347,4,0,
64,15,357,1,22,
64,15,373,4,0,
64,15,374,4,0,
64,15,412,4,0,
64,15,427,1,40,
64,15,433,4,0,
64,15,447,4,0,
64,15,451,4,0,
64,15,473,4,0,
64,15,477,1,34,
64,15,496,4,0,
64,15,502,1,24,
64,15,590,4,0,
64,15,605,4,0,
64,16,7,3,0,
64,16,8,3,0,
64,16,9,3,0,
64,16,50,1,18,1
64,16,60,1,21,1
64,16,86,4,0,
64,16,92,4,0,
64,16,93,1,1,3
64,16,93,1,16,1
64,16,94,1,38,1
64,16,94,4,0,
64,16,100,1,1,1
64,16,104,4,0,
64,16,105,1,31,1
64,16,113,4,0,
64,16,115,1,26,1
64,16,115,4,0,
64,16,134,1,1,2
64,16,138,4,0,
64,16,148,4,0,
64,16,156,4,0,
64,16,164,4,0,
64,16,168,4,0,
64,16,173,3,0,
64,16,182,4,0,
64,16,207,4,0,
64,16,213,4,0,
64,16,214,4,0,
64,16,216,4,0,
64,16,218,4,0,
64,16,219,4,0,
64,16,231,3,0,
64,16,237,4,0,
64,16,240,4,0,
64,16,241,4,0,
64,16,244,4,0,
64,16,247,4,0,
64,16,248,1,43,1
64,16,259,4,0,
64,16,263,4,0,
64,16,264,3,0,
64,16,269,4,0,
64,16,271,1,46,1
64,16,271,3,0,
64,16,272,1,41,1
64,16,272,3,0,
64,16,277,3,0,
64,16,278,3,0,
64,16,282,3,0,
64,16,285,3,0,
64,16,289,3,0,
64,16,290,4,0,
64,16,324,3,0,
64,16,347,4,0,
64,16,351,3,0,
64,16,356,3,0,
64,16,357,1,23,1
64,16,373,4,0,
64,16,374,4,0,
64,16,409,3,0,
64,16,412,4,0,
64,16,427,1,28,1
64,16,428,3,0,
64,16,433,4,0,
64,16,447,4,0,
64,16,451,4,0,
64,16,472,3,0,
64,16,473,4,0,
64,16,477,1,33,1
64,16,478,3,0,
64,16,492,3,0,
64,16,496,4,0,
64,16,502,1,36,1
64,16,590,4,0,
64,16,605,4,0,
64,17,50,1,18,
64,17,60,1,21,
64,17,86,4,0,
64,17,92,4,0,
64,17,93,1,1,3
64,17,93,1,16,
64,17,94,1,38,
64,17,94,4,0,
64,17,100,1,1,2
64,17,104,4,0,
64,17,105,1,31,
64,17,113,4,0,
64,17,115,1,26,
64,17,115,4,0,
64,17,134,1,0,
64,17,134,1,1,1
64,17,138,4,0,
64,17,156,4,0,
64,17,164,4,0,
64,17,168,4,0,
64,17,182,4,0,
64,17,207,4,0,
64,17,213,4,0,
64,17,214,4,0,
64,17,216,4,0,
64,17,218,4,0,
64,17,219,4,0,
64,17,237,4,0,
64,17,240,4,0,
64,17,241,4,0,
64,17,244,4,0,
64,17,247,4,0,
64,17,248,1,43,
64,17,259,4,0,
64,17,263,4,0,
64,17,269,4,0,
64,17,271,1,46,
64,17,272,1,41,
64,17,347,4,0,
64,17,357,1,23,
64,17,373,4,0,
64,17,374,4,0,
64,17,412,4,0,
64,17,427,1,28,
64,17,433,4,0,
64,17,447,4,0,
64,17,451,4,0,
64,17,473,4,0,
64,17,477,1,33,
64,17,496,4,0,
64,17,502,1,36,
64,17,590,4,0,
64,17,605,4,0,
64,18,50,1,18,
64,18,60,1,21,
64,18,86,4,0,
64,18,92,4,0,
64,18,93,1,1,3
64,18,93,1,16,
64,18,94,1,38,
64,18,94,4,0,
64,18,100,1,1,2
64,18,104,4,0,
64,18,105,1,31,
64,18,113,4,0,
64,18,115,1,26,
64,18,115,4,0,
64,18,134,1,0,
64,18,134,1,1,1
64,18,138,4,0,
64,18,156,4,0,
64,18,164,4,0,
64,18,168,4,0,
64,18,182,4,0,
64,18,207,4,0,
64,18,213,4,0,
64,18,214,4,0,
64,18,216,4,0,
64,18,218,4,0,
64,18,219,4,0,
64,18,237,4,0,
64,18,240,4,0,
64,18,241,4,0,
64,18,244,4,0,
64,18,247,4,0,
64,18,248,1,43,
64,18,259,4,0,
64,18,263,4,0,
64,18,269,4,0,
64,18,271,1,46,
64,18,272,1,41,
64,18,347,4,0,
64,18,357,1,23,
64,18,373,4,0,
64,18,374,4,0,
64,18,412,4,0,
64,18,427,1,28,
64,18,433,4,0,
64,18,447,4,0,
64,18,451,4,0,
64,18,473,4,0,
64,18,477,1,33,
64,18,496,4,0,
64,18,502,1,36,
64,18,590,4,0,
64,18,605,4,0,
65,1,5,4,0,
65,1,25,4,0,
65,1,34,4,0,
65,1,36,4,0,
65,1,38,4,0,
65,1,50,1,1,3
65,1,50,1,20,
65,1,60,1,27,
65,1,63,4,0,
65,1,66,4,0,
65,1,68,4,0,
65,1,69,4,0,
65,1,86,4,0,
65,1,91,4,0,
65,1,92,4,0,
65,1,93,1,1,2
65,1,93,1,16,
65,1,94,1,38,
65,1,94,4,0,
65,1,99,4,0,
65,1,100,1,1,1
65,1,100,4,0,
65,1,102,4,0,
65,1,104,4,0,
65,1,105,1,31,
65,1,115,1,42,
65,1,115,4,0,
65,1,117,4,0,
65,1,118,4,0,
65,1,130,4,0,
65,1,148,4,0,
65,1,149,4,0,
65,1,156,4,0,
65,1,161,4,0,
65,1,164,4,0,
65,2,5,4,0,
65,2,25,4,0,
65,2,34,4,0,
65,2,36,4,0,
65,2,38,4,0,
65,2,50,1,20,
65,2,60,1,27,
65,2,63,4,0,
65,2,66,4,0,
65,2,68,4,0,
65,2,69,4,0,
65,2,86,4,0,
65,2,91,4,0,
65,2,92,4,0,
65,2,93,1,16,
65,2,94,1,38,
65,2,94,4,0,
65,2,99,4,0,
65,2,100,1,1,1
65,2,100,4,0,
65,2,102,4,0,
65,2,104,4,0,
65,2,105,1,31,
65,2,115,1,42,
65,2,115,4,0,
65,2,117,4,0,
65,2,118,4,0,
65,2,130,4,0,
65,2,134,1,1,2
65,2,148,4,0,
65,2,149,4,0,
65,2,156,4,0,
65,2,161,4,0,
65,2,164,4,0,
65,3,7,4,0,
65,3,8,4,0,
65,3,9,4,0,
65,3,29,4,0,
65,3,50,1,18,
65,3,60,1,21,
65,3,63,4,0,
65,3,91,4,0,
65,3,92,4,0,
65,3,93,1,1,3
65,3,93,1,16,
65,3,94,1,38,
65,3,94,4,0,
65,3,100,1,1,1
65,3,104,4,0,
65,3,105,1,26,
65,3,115,1,45,
65,3,134,1,1,2
65,3,138,4,0,
65,3,148,4,0,
65,3,156,4,0,
65,3,168,4,0,
65,3,171,4,0,
65,3,173,4,0,
65,3,174,4,0,
65,3,182,4,0,
65,3,192,4,0,
65,3,203,4,0,
65,3,207,4,0,
65,3,213,4,0,
65,3,214,4,0,
65,3,216,4,0,
65,3,218,4,0,
65,3,223,4,0,
65,3,237,4,0,
65,3,240,4,0,
65,3,241,4,0,
65,3,244,4,0,
65,3,247,4,0,
65,3,248,1,31,
65,4,7,4,0,
65,4,8,4,0,
65,4,9,4,0,
65,4,29,4,0,
65,4,50,1,18,
65,4,60,1,21,
65,4,63,4,0,
65,4,91,4,0,
65,4,92,4,0,
65,4,93,1,1,3
65,4,93,1,16,
65,4,94,1,38,
65,4,94,4,0,
65,4,100,1,1,1
65,4,104,4,0,
65,4,105,1,26,
65,4,115,1,45,
65,4,134,1,1,2
65,4,138,4,0,
65,4,148,4,0,
65,4,156,4,0,
65,4,168,4,0,
65,4,171,4,0,
65,4,173,4,0,
65,4,174,4,0,
65,4,182,4,0,
65,4,192,4,0,
65,4,203,4,0,
65,4,207,4,0,
65,4,213,4,0,
65,4,214,4,0,
65,4,216,4,0,
65,4,218,4,0,
65,4,223,4,0,
65,4,237,4,0,
65,4,240,4,0,
65,4,241,4,0,
65,4,244,4,0,
65,4,247,4,0,
65,4,248,1,31,
65,5,50,1,18,
65,5,60,1,21,
65,5,63,4,0,
65,5,92,4,0,
65,5,93,1,1,3
65,5,93,1,16,
65,5,94,1,36,
65,5,94,4,0,
65,5,100,1,1,1
65,5,104,4,0,
65,5,105,1,25,
65,5,113,4,0,
65,5,115,1,23,
65,5,115,4,0,
65,5,134,1,1,2
65,5,148,4,0,
65,5,156,4,0,
65,5,168,4,0,
65,5,182,4,0,
65,5,213,4,0,
65,5,216,4,0,
65,5,218,4,0,
65,5,219,4,0,
65,5,231,4,0,
65,5,237,4,0,
65,5,240,4,0,
65,5,241,4,0,
65,5,247,4,0,
65,5,248,1,30,
65,5,259,4,0,
65,5,263,4,0,
65,5,264,4,0,
65,5,269,4,0,
65,5,271,1,43,
65,5,285,4,0,
65,5,289,4,0,
65,5,290,4,0,
65,5,347,1,33,
65,5,347,4,0,
65,5,351,4,0,
65,6,5,3,0,
65,6,7,3,0,
65,6,8,3,0,
65,6,9,3,0,
65,6,25,3,0,
65,6,34,3,0,
65,6,38,3,0,
65,6,50,1,18,
65,6,60,1,21,
65,6,63,4,0,
65,6,68,3,0,
65,6,69,3,0,
65,6,86,3,0,
65,6,92,4,0,
65,6,93,1,1,3
65,6,93,1,16,
65,6,94,1,36,
65,6,94,4,0,
65,6,100,1,1,1
65,6,102,3,0,
65,6,104,4,0,
65,6,105,1,25,
65,6,113,4,0,
65,6,115,1,23,
65,6,115,4,0,
65,6,118,3,0,
65,6,134,1,1,2
65,6,138,3,0,
65,6,148,4,0,
65,6,156,4,0,
65,6,164,3,0,
65,6,168,4,0,
65,6,173,3,0,
65,6,182,4,0,
65,6,203,3,0,
65,6,207,3,0,
65,6,213,4,0,
65,6,214,3,0,
65,6,216,4,0,
65,6,218,4,0,
65,6,219,4,0,
65,6,223,3,0,
65,6,231,4,0,
65,6,237,4,0,
65,6,240,4,0,
65,6,241,4,0,
65,6,244,3,0,
65,6,247,4,0,
65,6,248,1,30,
65,6,259,4,0,
65,6,263,4,0,
65,6,264,4,0,
65,6,269,4,0,
65,6,271,1,43,
65,6,285,4,0,
65,6,289,4,0,
65,6,290,4,0,
65,6,347,1,33,
65,6,347,4,0,
65,6,351,4,0,
65,7,5,3,0,
65,7,25,3,0,
65,7,34,3,0,
65,7,38,3,0,
65,7,50,1,18,
65,7,60,1,21,
65,7,63,4,0,
65,7,68,3,0,
65,7,69,3,0,
65,7,86,3,0,
65,7,92,4,0,
65,7,93,1,1,3
65,7,93,1,16,
65,7,94,1,36,
65,7,94,4,0,
65,7,100,1,1,1
65,7,102,3,0,
65,7,104,4,0,
65,7,105,1,25,
65,7,113,4,0,
65,7,115,1,23,
65,7,115,4,0,
65,7,118,3,0,
65,7,134,1,1,2
65,7,138,3,0,
65,7,148,4,0,
65,7,156,4,0,
65,7,164,3,0,
65,7,168,4,0,
65,7,182,4,0,
65,7,213,4,0,
65,7,216,4,0,
65,7,218,4,0,
65,7,219,4,0,
65,7,231,4,0,
65,7,237,4,0,
65,7,240,4,0,
65,7,241,4,0,
65,7,247,4,0,
65,7,248,1,30,
65,7,259,4,0,
65,7,263,4,0,
65,7,264,4,0,
65,7,269,4,0,
65,7,271,1,43,
65,7,285,4,0,
65,7,289,4,0,
65,7,290,4,0,
65,7,347,1,33,
65,7,347,4,0,
65,7,351,4,0,
65,8,50,1,18,
65,8,60,1,24,
65,8,63,4,0,
65,8,86,4,0,
65,8,92,4,0,
65,8,93,1,1,3
65,8,93,1,16,
65,8,94,1,40,
65,8,94,4,0,
65,8,100,1,1,1
65,8,104,4,0,
65,8,105,1,30,
65,8,113,4,0,
65,8,115,1,28,
65,8,115,4,0,
65,8,134,1,1,2
65,8,138,4,0,
65,8,148,4,0,
65,8,156,4,0,
65,8,164,4,0,
65,8,168,4,0,
65,8,182,4,0,
65,8,203,4,0,
65,8,207,4,0,
65,8,213,4,0,
65,8,214,4,0,
65,8,216,4,0,
65,8,218,4,0,
65,8,219,4,0,
65,8,231,4,0,
65,8,237,4,0,
65,8,240,4,0,
65,8,241,4,0,
65,8,244,4,0,
65,8,247,4,0,
65,8,248,1,42,
65,8,259,4,0,
65,8,263,4,0,
65,8,264,4,0,
65,8,269,4,0,
65,8,271,1,46,
65,8,278,4,0,
65,8,285,4,0,
65,8,289,4,0,
65,8,290,4,0,
65,8,347,1,36,
65,8,347,4,0,
65,8,351,4,0,
65,8,357,1,22,
65,8,363,4,0,
65,8,373,4,0,
65,8,374,4,0,
65,8,409,4,0,
65,8,411,4,0,
65,8,412,4,0,
65,8,416,4,0,
65,8,427,1,34,
65,8,433,4,0,
65,8,445,4,0,
65,8,447,4,0,
65,8,451,4,0,
65,9,7,3,0,
65,9,8,3,0,
65,9,9,3,0,
65,9,50,1,18,
65,9,60,1,24,
65,9,63,4,0,
65,9,86,4,0,
65,9,92,4,0,
65,9,93,1,1,3
65,9,93,1,16,
65,9,94,1,40,
65,9,94,4,0,
65,9,100,1,1,1
65,9,104,4,0,
65,9,105,1,30,
65,9,113,4,0,
65,9,115,1,28,
65,9,115,4,0,
65,9,134,1,1,2
65,9,138,4,0,
65,9,148,4,0,
65,9,156,4,0,
65,9,164,4,0,
65,9,168,4,0,
65,9,173,3,0,
65,9,182,4,0,
65,9,203,4,0,
65,9,207,4,0,
65,9,213,4,0,
65,9,214,4,0,
65,9,216,4,0,
65,9,218,4,0,
65,9,219,4,0,
65,9,231,4,0,
65,9,237,4,0,
65,9,240,4,0,
65,9,241,4,0,
65,9,244,4,0,
65,9,247,4,0,
65,9,248,1,42,
65,9,259,4,0,
65,9,263,4,0,
65,9,264,4,0,
65,9,269,4,0,
65,9,271,1,46,
65,9,271,3,0,
65,9,278,4,0,
65,9,282,3,0,
65,9,285,4,0,
65,9,289,4,0,
65,9,290,4,0,
65,9,324,3,0,
65,9,347,1,36,
65,9,347,4,0,
65,9,351,4,0,
65,9,357,1,22,
65,9,363,4,0,
65,9,373,4,0,
65,9,374,4,0,
65,9,409,4,0,
65,9,411,4,0,
65,9,412,4,0,
65,9,416,4,0,
65,9,427,1,34,
65,9,428,3,0,
65,9,433,4,0,
65,9,445,4,0,
65,9,447,4,0,
65,9,451,4,0,
65,10,7,3,0,
65,10,8,3,0,
65,10,9,3,0,
65,10,29,3,0,
65,10,50,1,18,
65,10,60,1,24,
65,10,63,4,0,
65,10,86,4,0,
65,10,92,4,0,
65,10,93,1,1,3
65,10,93,1,16,
65,10,94,1,40,
65,10,94,4,0,
65,10,100,1,1,1
65,10,104,4,0,
65,10,105,1,30,
65,10,113,4,0,
65,10,115,1,28,
65,10,115,4,0,
65,10,134,1,1,2
65,10,138,4,0,
65,10,148,4,0,
65,10,156,4,0,
65,10,164,4,0,
65,10,168,4,0,
65,10,173,3,0,
65,10,182,4,0,
65,10,203,4,0,
65,10,207,4,0,
65,10,213,4,0,
65,10,214,4,0,
65,10,216,4,0,
65,10,218,4,0,
65,10,219,4,0,
65,10,231,4,0,
65,10,237,4,0,
65,10,240,4,0,
65,10,241,4,0,
65,10,244,4,0,
65,10,247,4,0,
65,10,248,1,42,
65,10,259,4,0,
65,10,263,4,0,
65,10,264,4,0,
65,10,269,4,0,
65,10,271,1,46,
65,10,271,3,0,
65,10,272,3,0,
65,10,277,3,0,
65,10,278,4,0,
65,10,282,3,0,
65,10,285,4,0,
65,10,289,4,0,
65,10,290,4,0,
65,10,324,3,0,
65,10,347,1,36,
65,10,347,4,0,
65,10,351,4,0,
65,10,356,3,0,
65,10,357,1,22,
65,10,363,4,0,
65,10,373,4,0,
65,10,374,4,0,
65,10,409,4,0,
65,10,411,4,0,
65,10,412,4,0,
65,10,416,4,0,
65,10,427,1,34,
65,10,428,3,0,
65,10,433,4,0,
65,10,445,4,0,
65,10,447,4,0,
65,10,451,4,0,
65,11,50,1,18,
65,11,60,1,28,
65,11,63,4,0,
65,11,86,4,0,
65,11,92,4,0,
65,11,93,1,1,3
65,11,93,1,16,
65,11,94,1,46,
65,11,94,4,0,
65,11,100,1,1,1
65,11,104,4,0,
65,11,105,1,36,
65,11,113,4,0,
65,11,115,1,30,
65,11,115,4,0,
65,11,134,1,1,2
65,11,138,4,0,
65,11,148,4,0,
65,11,156,4,0,
65,11,164,4,0,
65,11,168,4,0,
65,11,182,4,0,
65,11,207,4,0,
65,11,213,4,0,
65,11,216,4,0,
65,11,218,4,0,
65,11,219,4,0,
65,11,237,4,0,
65,11,240,4,0,
65,11,241,4,0,
65,11,244,4,0,
65,11,247,4,0,
65,11,248,1,48,
65,11,259,4,0,
65,11,263,4,0,
65,11,269,4,0,
65,11,271,1,52,
65,11,347,1,42,
65,11,347,4,0,
65,11,357,1,22,
65,11,373,4,0,
65,11,374,4,0,
65,11,411,4,0,
65,11,412,4,0,
65,11,416,4,0,
65,11,427,1,40,
65,11,433,4,0,
65,11,447,4,0,
65,11,451,4,0,
65,11,473,4,0,
65,11,477,1,34,
65,11,477,4,0,
65,11,496,4,0,
65,11,502,1,24,
65,11,502,4,0,
65,12,50,1,18,
65,12,60,1,21,
65,12,63,4,0,
65,12,92,4,0,
65,12,93,1,1,3
65,12,93,1,16,
65,12,94,1,36,
65,12,94,4,0,
65,12,100,1,1,1
65,12,104,4,0,
65,12,105,1,25,
65,12,113,4,0,
65,12,115,1,23,
65,12,115,4,0,
65,12,134,1,1,2
65,12,148,4,0,
65,12,156,4,0,
65,12,168,4,0,
65,12,182,4,0,
65,12,213,4,0,
65,12,216,4,0,
65,12,218,4,0,
65,12,219,4,0,
65,12,231,4,0,
65,12,237,4,0,
65,12,240,4,0,
65,12,241,4,0,
65,12,247,4,0,
65,12,248,1,30,
65,12,259,4,0,
65,12,263,4,0,
65,12,264,4,0,
65,12,269,4,0,
65,12,271,1,43,
65,12,285,4,0,
65,12,289,4,0,
65,12,290,4,0,
65,12,347,1,33,
65,12,347,4,0,
65,12,351,4,0,
65,13,34,3,0,
65,13,38,3,0,
65,13,50,1,18,
65,13,60,1,21,
65,13,63,4,0,
65,13,69,3,0,
65,13,86,3,0,
65,13,92,4,0,
65,13,93,1,1,3
65,13,93,1,16,
65,13,94,1,36,
65,13,94,4,0,
65,13,100,1,1,1
65,13,102,3,0,
65,13,104,4,0,
65,13,105,1,25,
65,13,113,4,0,
65,13,115,1,23,
65,13,115,4,0,
65,13,134,1,1,2
65,13,138,3,0,
65,13,148,4,0,
65,13,156,4,0,
65,13,164,3,0,
65,13,168,4,0,
65,13,171,3,0,
65,13,182,4,0,
65,13,207,3,0,
65,13,213,4,0,
65,13,216,4,0,
65,13,218,4,0,
65,13,219,4,0,
65,13,231,4,0,
65,13,237,4,0,
65,13,240,4,0,
65,13,241,4,0,
65,13,247,4,0,
65,13,248,1,30,
65,13,259,4,0,
65,13,263,4,0,
65,13,264,4,0,
65,13,269,4,0,
65,13,271,1,43,
65,13,285,4,0,
65,13,289,4,0,
65,13,290,4,0,
65,13,347,1,33,
65,13,347,4,0,
65,13,351,4,0,
65,14,7,3,0,
65,14,8,3,0,
65,14,9,3,0,
65,14,50,1,18,
65,14,60,1,28,
65,14,63,4,0,
65,14,86,4,0,
65,14,92,4,0,
65,14,93,1,1,3
65,14,93,1,16,
65,14,94,1,46,
65,14,94,4,0,
65,14,100,1,1,1
65,14,104,4,0,
65,14,105,1,36,
65,14,113,4,0,
65,14,115,1,30,
65,14,115,4,0,
65,14,134,1,1,2
65,14,138,4,0,
65,14,148,4,0,
65,14,156,4,0,
65,14,164,4,0,
65,14,168,4,0,
65,14,173,3,0,
65,14,182,4,0,
65,14,207,4,0,
65,14,213,4,0,
65,14,214,3,0,
65,14,216,4,0,
65,14,218,4,0,
65,14,219,4,0,
65,14,231,3,0,
65,14,237,4,0,
65,14,240,4,0,
65,14,241,4,0,
65,14,244,4,0,
65,14,247,4,0,
65,14,248,1,48,
65,14,259,4,0,
65,14,263,4,0,
65,14,269,4,0,
65,14,271,1,52,
65,14,271,3,0,
65,14,272,3,0,
65,14,277,3,0,
65,14,278,3,0,
65,14,282,3,0,
65,14,285,3,0,
65,14,289,3,0,
65,14,324,3,0,
65,14,347,1,42,
65,14,347,4,0,
65,14,356,3,0,
65,14,357,1,22,
65,14,373,4,0,
65,14,374,4,0,
65,14,409,3,0,
65,14,411,4,0,
65,14,412,4,0,
65,14,416,4,0,
65,14,427,1,40,
65,14,428,3,0,
65,14,433,4,0,
65,14,447,4,0,
65,14,451,4,0,
65,14,472,3,0,
65,14,473,4,0,
65,14,477,1,34,
65,14,477,4,0,
65,14,478,3,0,
65,14,492,3,0,
65,14,496,4,0,
65,14,502,1,24,
65,14,502,4,0,
65,15,50,1,18,
65,15,60,1,28,
65,15,63,4,0,
65,15,86,4,0,
65,15,92,4,0,
65,15,93,1,1,3
65,15,93,1,16,
65,15,94,1,46,
65,15,94,4,0,
65,15,100,1,1,1
65,15,104,4,0,
65,15,105,1,36,
65,15,113,4,0,
65,15,115,1,30,
65,15,115,4,0,
65,15,134,1,1,2
65,15,138,4,0,
65,15,148,4,0,
65,15,156,4,0,
65,15,164,4,0,
65,15,168,4,0,
65,15,182,4,0,
65,15,207,4,0,
65,15,213,4,0,
65,15,214,4,0,
65,15,216,4,0,
65,15,218,4,0,
65,15,219,4,0,
65,15,237,4,0,
65,15,240,4,0,
65,15,241,4,0,
65,15,244,4,0,
65,15,247,4,0,
65,15,248,1,48,
65,15,259,4,0,
65,15,263,4,0,
65,15,269,4,0,
65,15,271,1,50,
65,15,347,1,42,
65,15,347,4,0,
65,15,357,1,22,
65,15,373,4,0,
65,15,374,4,0,
65,15,411,4,0,
65,15,412,4,0,
65,15,416,4,0,
65,15,427,1,40,
65,15,433,4,0,
65,15,447,4,0,
65,15,451,4,0,
65,15,473,4,0,
65,15,477,1,34,
65,15,496,4,0,
65,15,502,1,24,
65,15,590,4,0,
65,15,605,4,0,
65,16,7,3,0,
65,16,8,3,0,
65,16,9,3,0,
65,16,50,1,18,1
65,16,60,1,21,1
65,16,63,4,0,
65,16,86,4,0,
65,16,92,4,0,
65,16,93,1,1,3
65,16,93,1,16,1
65,16,94,1,38,1
65,16,94,4,0,
65,16,100,1,1,1
65,16,104,4,0,
65,16,105,1,31,1
65,16,113,4,0,
65,16,115,1,26,1
65,16,115,4,0,
65,16,134,1,1,2
65,16,138,4,0,
65,16,148,4,0,
65,16,156,4,0,
65,16,164,4,0,
65,16,168,4,0,
65,16,173,3,0,
65,16,182,4,0,
65,16,207,4,0,
65,16,213,4,0,
65,16,214,4,0,
65,16,216,4,0,
65,16,218,4,0,
65,16,219,4,0,
65,16,231,3,0,
65,16,237,4,0,
65,16,240,4,0,
65,16,241,4,0,
65,16,244,4,0,
65,16,247,4,0,
65,16,248,1,43,1
65,16,259,4,0,
65,16,263,4,0,
65,16,264,3,0,
65,16,269,4,0,
65,16,271,1,46,1
65,16,271,3,0,
65,16,272,3,0,
65,16,277,3,0,
65,16,278,3,0,
65,16,282,3,0,
65,16,285,3,0,
65,16,289,3,0,
65,16,290,4,0,
65,16,324,3,0,
65,16,347,1,41,1
65,16,347,4,0,
65,16,351,3,0,
65,16,356,3,0,
65,16,357,1,23,1
65,16,373,4,0,
65,16,374,4,0,
65,16,409,3,0,
65,16,411,4,0,
65,16,412,4,0,
65,16,416,4,0,
65,16,427,1,28,1
65,16,428,3,0,
65,16,433,4,0,
65,16,447,4,0,
65,16,451,4,0,
65,16,472,3,0,
65,16,473,4,0,
65,16,477,1,33,1
65,16,478,3,0,
65,16,492,3,0,
65,16,496,4,0,
65,16,502,1,36,1
65,16,590,4,0,
65,16,605,4,0,
65,17,50,1,18,
65,17,60,1,21,
65,17,63,4,0,
65,17,86,4,0,
65,17,92,4,0,
65,17,93,1,1,3
65,17,93,1,16,
65,17,94,1,38,
65,17,94,4,0,
65,17,100,1,1,2
65,17,104,4,0,
65,17,105,1,31,
65,17,113,4,0,
65,17,115,1,26,
65,17,115,4,0,
65,17,134,1,0,
65,17,134,1,1,1
65,17,138,4,0,
65,17,156,4,0,
65,17,164,4,0,
65,17,168,4,0,
65,17,182,4,0,
65,17,207,4,0,
65,17,213,4,0,
65,17,214,4,0,
65,17,216,4,0,
65,17,218,4,0,
65,17,219,4,0,
65,17,237,4,0,
65,17,240,4,0,
65,17,241,4,0,
65,17,244,4,0,
65,17,247,4,0,
65,17,248,1,43,
65,17,259,4,0,
65,17,263,4,0,
65,17,269,4,0,
65,17,271,1,46,
65,17,347,1,41,
65,17,347,4,0,
65,17,357,1,23,
65,17,373,4,0,
65,17,374,4,0,
65,17,411,4,0,
65,17,412,4,0,
65,17,416,4,0,
65,17,427,1,28,
65,17,433,4,0,
65,17,447,4,0,
65,17,451,4,0,
65,17,473,4,0,
65,17,477,1,33,
65,17,496,4,0,
65,17,502,1,36,
65,17,590,4,0,
65,17,605,4,0,
65,18,50,1,18,
65,18,60,1,21,
65,18,63,4,0,
65,18,86,4,0,
65,18,92,4,0,
65,18,93,1,1,3
65,18,93,1,16,
65,18,94,1,38,
65,18,94,4,0,
65,18,100,1,1,2
65,18,104,4,0,
65,18,105,1,31,
65,18,113,4,0,
65,18,115,1,26,
65,18,115,4,0,
65,18,134,1,0,
65,18,134,1,1,1
65,18,138,4,0,
65,18,156,4,0,
65,18,164,4,0,
65,18,168,4,0,
65,18,182,4,0,
65,18,207,4,0,
65,18,213,4,0,
65,18,214,4,0,
65,18,216,4,0,
65,18,218,4,0,
65,18,219,4,0,
65,18,237,4,0,
65,18,240,4,0,
65,18,241,4,0,
65,18,244,4,0,
65,18,247,4,0,
65,18,248,1,43,
65,18,259,4,0,
65,18,263,4,0,
65,18,269,4,0,
65,18,271,1,46,
65,18,347,1,41,
65,18,347,4,0,
65,18,357,1,23,
65,18,373,4,0,
65,18,374,4,0,
65,18,411,4,0,
65,18,412,4,0,
65,18,416,4,0,
65,18,427,1,28,
65,18,433,4,0,
65,18,447,4,0,
65,18,451,4,0,
65,18,473,4,0,
65,18,477,1,33,
65,18,496,4,0,
65,18,502,1,36,
65,18,590,4,0,
65,18,605,4,0,
66,1,2,1,1,
66,1,5,4,0,
66,1,25,4,0,
66,1,34,4,0,
66,1,36,4,0,
66,1,38,4,0,
66,1,43,1,25,
66,1,66,1,46,
66,1,66,4,0,
66,1,67,1,20,
66,1,68,4,0,
66,1,69,1,39,
66,1,69,4,0,
66,1,70,4,0,
66,1,89,4,0,
66,1,90,4,0,
66,1,91,4,0,
66,1,92,4,0,
66,1,99,4,0,
66,1,102,4,0,
66,1,104,4,0,
66,1,116,1,32,
66,1,117,4,0,
66,1,118,4,0,
66,1,126,4,0,
66,1,130,4,0,
66,1,156,4,0,
66,1,157,4,0,
66,1,164,4,0,
66,2,2,1,1,
66,2,5,4,0,
66,2,25,4,0,
66,2,34,4,0,
66,2,36,4,0,
66,2,38,4,0,
66,2,43,1,25,
66,2,66,1,46,
66,2,66,4,0,
66,2,67,1,20,
66,2,68,4,0,
66,2,69,1,39,
66,2,69,4,0,
66,2,70,4,0,
66,2,89,4,0,
66,2,90,4,0,
66,2,91,4,0,
66,2,92,4,0,
66,2,99,4,0,
66,2,102,4,0,
66,2,104,4,0,
66,2,116,1,32,
66,2,117,4,0,
66,2,118,4,0,
66,2,126,4,0,
66,2,130,4,0,
66,2,156,4,0,
66,2,157,4,0,
66,2,164,4,0,
66,3,2,1,13,
66,3,7,4,0,
66,3,8,4,0,
66,3,9,4,0,
66,3,27,2,0,
66,3,29,4,0,
66,3,43,1,1,2
66,3,66,1,49,
66,3,67,1,1,1
66,3,69,1,19,
66,3,70,4,0,
66,3,89,4,0,
66,3,91,4,0,
66,3,92,4,0,
66,3,96,2,0,
66,3,104,4,0,
66,3,113,2,0,
66,3,116,1,7,
66,3,126,4,0,
66,3,156,4,0,
66,3,168,4,0,
66,3,173,4,0,
66,3,174,4,0,
66,3,182,4,0,
66,3,184,1,43,
66,3,189,4,0,
66,3,193,1,25,
66,3,197,4,0,
66,3,203,4,0,
66,3,207,4,0,
66,3,213,4,0,
66,3,214,4,0,
66,3,216,4,0,
66,3,218,4,0,
66,3,223,4,0,
66,3,227,2,0,
66,3,233,1,31,
66,3,237,4,0,
66,3,238,1,37,
66,3,241,4,0,
66,3,249,4,0,
66,4,2,1,13,
66,4,7,4,0,
66,4,8,4,0,
66,4,9,4,0,
66,4,27,2,0,
66,4,29,4,0,
66,4,43,1,1,2
66,4,53,3,0,
66,4,66,1,49,
66,4,67,1,1,1
66,4,69,1,19,
66,4,70,4,0,
66,4,89,4,0,
66,4,91,4,0,
66,4,92,4,0,
66,4,96,2,0,
66,4,104,4,0,
66,4,113,2,0,
66,4,116,1,7,
66,4,126,4,0,
66,4,156,4,0,
66,4,168,4,0,
66,4,173,4,0,
66,4,174,4,0,
66,4,182,4,0,
66,4,184,1,43,
66,4,189,4,0,
66,4,193,1,25,
66,4,197,4,0,
66,4,203,4,0,
66,4,207,4,0,
66,4,213,4,0,
66,4,214,4,0,
66,4,216,4,0,
66,4,218,4,0,
66,4,223,4,0,
66,4,227,2,0,
66,4,233,1,31,
66,4,237,4,0,
66,4,238,1,37,
66,4,241,4,0,
66,4,249,4,0,
66,5,2,1,13,
66,5,27,2,0,
66,5,43,1,1,2
66,5,53,4,0,
66,5,66,1,37,
66,5,67,1,1,1
66,5,68,2,0,
66,5,69,1,19,
66,5,70,4,0,
66,5,89,4,0,
66,5,91,4,0,
66,5,92,4,0,
66,5,96,2,0,
66,5,104,4,0,
66,5,113,2,0,
66,5,116,1,7,
66,5,126,4,0,
66,5,156,4,0,
66,5,157,2,0,
66,5,168,4,0,
66,5,182,4,0,
66,5,184,1,43,
66,5,193,1,22,
66,5,213,4,0,
66,5,216,4,0,
66,5,218,4,0,
66,5,223,1,49,
66,5,227,2,0,
66,5,233,1,31,
66,5,237,4,0,
66,5,238,1,40,
66,5,240,4,0,
66,5,241,4,0,
66,5,249,4,0,
66,5,263,4,0,
66,5,264,4,0,
66,5,265,2,0,
66,5,279,1,25,
66,5,280,4,0,
66,5,290,4,0,
66,5,317,4,0,
66,5,339,4,0,
66,6,2,1,13,
66,6,5,3,0,
66,6,7,3,0,
66,6,8,3,0,
66,6,9,3,0,
66,6,25,3,0,
66,6,27,2,0,
66,6,34,3,0,
66,6,38,3,0,
66,6,43,1,1,2
66,6,53,4,0,
66,6,66,1,37,
66,6,67,1,1,1
66,6,68,2,0,
66,6,68,3,0,
66,6,69,1,19,
66,6,69,3,0,
66,6,70,4,0,
66,6,89,4,0,
66,6,91,4,0,
66,6,92,4,0,
66,6,96,2,0,
66,6,102,3,0,
66,6,104,4,0,
66,6,113,2,0,
66,6,116,1,7,
66,6,118,3,0,
66,6,126,4,0,
66,6,156,4,0,
66,6,157,2,0,
66,6,157,3,0,
66,6,164,3,0,
66,6,168,4,0,
66,6,173,3,0,
66,6,182,4,0,
66,6,184,1,43,
66,6,189,3,0,
66,6,193,1,22,
66,6,203,3,0,
66,6,207,3,0,
66,6,213,4,0,
66,6,214,3,0,
66,6,216,4,0,
66,6,218,4,0,
66,6,223,1,49,
66,6,223,3,0,
66,6,227,2,0,
66,6,233,1,31,
66,6,237,4,0,
66,6,238,1,40,
66,6,240,4,0,
66,6,241,4,0,
66,6,249,4,0,
66,6,263,4,0,
66,6,264,4,0,
66,6,265,2,0,
66,6,279,1,25,
66,6,280,4,0,
66,6,290,4,0,
66,6,317,4,0,
66,6,339,4,0,
66,7,2,1,13,
66,7,5,3,0,
66,7,25,3,0,
66,7,27,2,0,
66,7,34,3,0,
66,7,38,3,0,
66,7,43,1,1,2
66,7,53,4,0,
66,7,66,1,37,
66,7,67,1,1,1
66,7,68,2,0,
66,7,68,3,0,
66,7,69,1,19,
66,7,69,3,0,
66,7,70,4,0,
66,7,89,4,0,
66,7,91,4,0,
66,7,92,4,0,
66,7,96,2,0,
66,7,102,3,0,
66,7,104,4,0,
66,7,113,2,0,
66,7,116,1,7,
66,7,118,3,0,
66,7,126,4,0,
66,7,156,4,0,
66,7,157,2,0,
66,7,157,3,0,
66,7,164,3,0,
66,7,168,4,0,
66,7,182,4,0,
66,7,184,1,43,
66,7,193,1,22,
66,7,213,4,0,
66,7,216,4,0,
66,7,218,4,0,
66,7,223,1,49,
66,7,227,2,0,
66,7,233,1,31,
66,7,237,4,0,
66,7,238,1,40,
66,7,240,4,0,
66,7,241,4,0,
66,7,249,4,0,
66,7,263,4,0,
66,7,264,4,0,
66,7,265,2,0,
66,7,279,1,25,
66,7,280,4,0,
66,7,290,4,0,
66,7,317,4,0,
66,7,339,4,0,
66,8,2,1,10,
66,8,7,2,0,
66,8,8,2,0,
66,8,9,2,0,
66,8,27,2,0,
66,8,43,1,1,2
66,8,53,4,0,
66,8,66,1,31,
66,8,67,1,1,1
66,8,68,2,0,
66,8,69,1,19,
66,8,70,4,0,
66,8,89,4,0,
66,8,91,4,0,
66,8,92,4,0,
66,8,96,2,0,
66,8,104,4,0,
66,8,113,2,0,
66,8,116,1,7,
66,8,126,4,0,
66,8,156,4,0,
66,8,157,2,0,
66,8,157,4,0,
66,8,164,4,0,
66,8,168,4,0,
66,8,182,4,0,
66,8,184,1,43,
66,8,193,1,13,
66,8,203,4,0,
66,8,207,4,0,
66,8,213,4,0,
66,8,214,4,0,
66,8,216,4,0,
66,8,218,4,0,
66,8,223,1,46,
66,8,227,2,0,
66,8,233,1,25,
66,8,237,4,0,
66,8,238,1,37,
66,8,240,4,0,
66,8,241,4,0,
66,8,249,4,0,
66,8,263,4,0,
66,8,264,4,0,
66,8,265,2,0,
66,8,279,1,22,
66,8,280,4,0,
66,8,290,4,0,
66,8,317,4,0,
66,8,339,4,0,
66,8,358,1,34,
66,8,363,4,0,
66,8,370,2,0,
66,8,371,4,0,
66,8,374,4,0,
66,8,398,4,0,
66,8,411,4,0,
66,8,418,2,0,
66,8,431,4,0,
66,8,445,4,0,
66,9,2,1,10,
66,9,7,2,0,
66,9,7,3,0,
66,9,8,2,0,
66,9,8,3,0,
66,9,9,2,0,
66,9,9,3,0,
66,9,27,2,0,
66,9,43,1,1,2
66,9,53,4,0,
66,9,66,1,31,
66,9,67,1,1,1
66,9,68,2,0,
66,9,69,1,19,
66,9,70,4,0,
66,9,89,4,0,
66,9,91,4,0,
66,9,92,4,0,
66,9,96,2,0,
66,9,104,4,0,
66,9,113,2,0,
66,9,116,1,7,
66,9,126,4,0,
66,9,156,4,0,
66,9,157,2,0,
66,9,157,4,0,
66,9,164,4,0,
66,9,168,4,0,
66,9,173,3,0,
66,9,182,4,0,
66,9,184,1,43,
66,9,189,3,0,
66,9,193,1,13,
66,9,203,4,0,
66,9,207,4,0,
66,9,213,4,0,
66,9,214,4,0,
66,9,216,4,0,
66,9,218,4,0,
66,9,223,1,46,
66,9,227,2,0,
66,9,233,1,25,
66,9,237,4,0,
66,9,238,1,37,
66,9,240,4,0,
66,9,241,4,0,
66,9,249,4,0,
66,9,263,4,0,
66,9,264,4,0,
66,9,265,2,0,
66,9,270,3,0,
66,9,276,3,0,
66,9,279,1,22,
66,9,280,4,0,
66,9,290,4,0,
66,9,317,4,0,
66,9,339,4,0,
66,9,358,1,34,
66,9,363,4,0,
66,9,370,2,0,
66,9,371,4,0,
66,9,374,4,0,
66,9,398,4,0,
66,9,410,3,0,
66,9,411,4,0,
66,9,418,2,0,
66,9,431,4,0,
66,9,445,4,0,
66,10,2,1,10,
66,10,7,2,0,
66,10,7,3,0,
66,10,8,2,0,
66,10,8,3,0,
66,10,9,2,0,
66,10,9,3,0,
66,10,27,2,0,
66,10,29,3,0,
66,10,43,1,1,2
66,10,53,4,0,
66,10,66,1,31,
66,10,67,1,1,1
66,10,67,3,0,
66,10,68,2,0,
66,10,69,1,19,
66,10,70,4,0,
66,10,89,4,0,
66,10,91,4,0,
66,10,92,4,0,
66,10,96,2,0,
66,10,104,4,0,
66,10,113,2,0,
66,10,116,1,7,
66,10,126,4,0,
66,10,156,4,0,
66,10,157,2,0,
66,10,157,4,0,
66,10,164,4,0,
66,10,168,4,0,
66,10,173,3,0,
66,10,182,4,0,
66,10,184,1,43,
66,10,189,3,0,
66,10,193,1,13,
66,10,203,4,0,
66,10,207,4,0,
66,10,213,4,0,
66,10,214,4,0,
66,10,216,4,0,
66,10,218,4,0,
66,10,223,1,46,
66,10,227,2,0,
66,10,233,1,25,
66,10,237,4,0,
66,10,238,1,37,
66,10,240,4,0,
66,10,241,4,0,
66,10,249,4,0,
66,10,263,4,0,
66,10,264,4,0,
66,10,265,2,0,
66,10,270,3,0,
66,10,272,3,0,
66,10,276,3,0,
66,10,279,1,22,
66,10,280,4,0,
66,10,290,4,0,
66,10,317,4,0,
66,10,339,4,0,
66,10,358,1,34,
66,10,363,4,0,
66,10,370,2,0,
66,10,371,4,0,
66,10,374,4,0,
66,10,379,2,0,
66,10,398,4,0,
66,10,410,3,0,
66,10,411,4,0,
66,10,418,2,0,
66,10,431,4,0,
66,10,445,4,0,
66,11,2,1,10,
66,11,7,2,0,
66,11,8,2,0,
66,11,9,2,0,
66,11,27,2,0,
66,11,43,1,1,2
66,11,53,4,0,
66,11,66,1,34,
66,11,67,1,1,1
66,11,68,2,0,
66,11,69,1,22,
66,11,70,4,0,
66,11,89,4,0,
66,11,91,4,0,
66,11,92,4,0,
66,11,96,2,0,
66,11,104,4,0,
66,11,113,4,0,
66,11,116,1,7,
66,11,126,4,0,
66,11,156,4,0,
66,11,157,4,0,
66,11,164,4,0,
66,11,168,4,0,
66,11,182,4,0,
66,11,184,1,46,
66,11,193,1,19,
66,11,207,4,0,
66,11,213,4,0,
66,11,216,4,0,
66,11,218,4,0,
66,11,223,1,49,
66,11,227,2,0,
66,11,233,1,31,
66,11,237,4,0,
66,11,238,1,43,
66,11,240,4,0,
66,11,241,4,0,
66,11,249,4,0,
66,11,263,4,0,
66,11,265,2,0,
66,11,279,1,25,
66,11,280,4,0,
66,11,282,2,0,
66,11,317,4,0,
66,11,321,2,0,
66,11,339,4,0,
66,11,358,1,37,
66,11,370,2,0,
66,11,371,4,0,
66,11,374,4,0,
66,11,379,2,0,
66,11,398,4,0,
66,11,411,4,0,
66,11,418,2,0,
66,11,479,4,0,
66,11,484,2,0,
66,11,490,1,13,
66,11,490,4,0,
66,11,496,4,0,
66,11,510,4,0,
66,11,514,4,0,
66,11,523,4,0,
66,11,526,4,0,
66,12,2,1,13,
66,12,43,1,1,2
66,12,53,4,0,
66,12,66,1,37,
66,12,67,1,1,1
66,12,69,1,19,
66,12,70,4,0,
66,12,89,4,0,
66,12,91,4,0,
66,12,92,4,0,
66,12,104,4,0,
66,12,116,1,7,
66,12,126,4,0,
66,12,156,4,0,
66,12,168,4,0,
66,12,182,4,0,
66,12,184,1,43,
66,12,193,1,22,
66,12,213,4,0,
66,12,216,4,0,
66,12,218,4,0,
66,12,223,1,49,
66,12,233,1,31,
66,12,237,4,0,
66,12,238,1,40,
66,12,240,4,0,
66,12,241,4,0,
66,12,249,4,0,
66,12,263,4,0,
66,12,264,4,0,
66,12,279,1,25,
66,12,280,4,0,
66,12,290,4,0,
66,12,317,4,0,
66,12,339,4,0,
66,13,2,1,13,
66,13,34,3,0,
66,13,38,3,0,
66,13,43,1,1,2
66,13,53,4,0,
66,13,66,1,37,
66,13,67,1,1,1
66,13,69,1,19,
66,13,69,3,0,
66,13,70,4,0,
66,13,89,4,0,
66,13,91,4,0,
66,13,92,4,0,
66,13,102,3,0,
66,13,104,4,0,
66,13,116,1,7,
66,13,126,4,0,
66,13,156,4,0,
66,13,164,3,0,
66,13,168,4,0,
66,13,182,4,0,
66,13,184,1,43,
66,13,193,1,22,
66,13,207,3,0,
66,13,213,4,0,
66,13,216,4,0,
66,13,218,4,0,
66,13,223,1,49,
66,13,233,1,31,
66,13,237,4,0,
66,13,238,1,40,
66,13,240,4,0,
66,13,241,4,0,
66,13,249,4,0,
66,13,263,4,0,
66,13,264,4,0,
66,13,279,1,25,
66,13,280,4,0,
66,13,290,4,0,
66,13,317,4,0,
66,13,339,4,0,
66,14,2,1,10,
66,14,7,2,0,
66,14,7,3,0,
66,14,8,2,0,
66,14,8,3,0,
66,14,9,2,0,
66,14,9,3,0,
66,14,27,2,0,
66,14,43,1,1,2
66,14,53,4,0,
66,14,66,1,34,
66,14,67,1,1,1
66,14,67,3,0,
66,14,68,2,0,
66,14,69,1,22,
66,14,70,4,0,
66,14,89,4,0,
66,14,91,4,0,
66,14,92,4,0,
66,14,96,2,0,
66,14,104,4,0,
66,14,113,4,0,
66,14,116,1,7,
66,14,126,4,0,
66,14,156,4,0,
66,14,157,4,0,
66,14,164,4,0,
66,14,168,4,0,
66,14,173,3,0,
66,14,182,4,0,
66,14,184,1,46,
66,14,193,1,19,
66,14,207,4,0,
66,14,213,4,0,
66,14,214,3,0,
66,14,216,4,0,
66,14,218,4,0,
66,14,223,1,49,
66,14,227,2,0,
66,14,233,1,31,
66,14,237,4,0,
66,14,238,1,43,
66,14,240,4,0,
66,14,241,4,0,
66,14,249,4,0,
66,14,263,4,0,
66,14,265,2,0,
66,14,270,3,0,
66,14,272,3,0,
66,14,276,3,0,
66,14,279,1,25,
66,14,280,4,0,
66,14,282,2,0,
66,14,282,3,0,
66,14,317,4,0,
66,14,321,2,0,
66,14,339,4,0,
66,14,358,1,37,
66,14,370,2,0,
66,14,371,4,0,
66,14,374,4,0,
66,14,379,2,0,
66,14,398,4,0,
66,14,411,4,0,
66,14,418,2,0,
66,14,479,4,0,
66,14,484,2,0,
66,14,490,1,13,
66,14,490,4,0,
66,14,496,4,0,
66,14,510,4,0,
66,14,514,4,0,
66,14,523,4,0,
66,14,526,4,0,
66,14,530,3,0,
66,15,2,1,10,
66,15,7,2,0,
66,15,8,2,0,
66,15,9,2,0,
66,15,27,2,0,
66,15,43,1,1,2
66,15,53,4,0,
66,15,66,1,34,
66,15,67,1,1,1
66,15,68,2,0,
66,15,69,1,22,
66,15,70,4,0,
66,15,89,4,0,
66,15,91,4,0,
66,15,92,4,0,
66,15,96,2,0,
66,15,104,4,0,
66,15,113,4,0,
66,15,116,1,7,
66,15,126,4,0,
66,15,156,4,0,
66,15,157,4,0,
66,15,164,4,0,
66,15,168,4,0,
66,15,182,4,0,
66,15,184,1,46,
66,15,193,1,19,
66,15,207,4,0,
66,15,213,4,0,
66,15,214,4,0,
66,15,216,4,0,
66,15,218,4,0,
66,15,223,1,49,
66,15,227,2,0,
66,15,233,1,31,
66,15,237,4,0,
66,15,238,1,43,
66,15,240,4,0,
66,15,241,4,0,
66,15,249,4,0,
66,15,263,4,0,
66,15,265,2,0,
66,15,279,1,25,
66,15,280,4,0,
66,15,282,2,0,
66,15,317,4,0,
66,15,321,2,0,
66,15,339,4,0,
66,15,358,1,37,
66,15,370,2,0,
66,15,371,4,0,
66,15,374,4,0,
66,15,379,2,0,
66,15,398,4,0,
66,15,411,4,0,
66,15,418,2,0,
66,15,479,4,0,
66,15,484,2,0,
66,15,490,1,13,
66,15,490,4,0,
66,15,496,4,0,
66,15,501,2,0,
66,15,510,4,0,
66,15,514,4,0,
66,15,523,4,0,
66,15,590,4,0,
66,15,612,4,0,
66,16,2,1,7,1
66,16,7,2,0,
66,16,7,3,0,
66,16,8,2,0,
66,16,8,3,0,
66,16,9,2,0,
66,16,9,3,0,
66,16,27,2,0,
66,16,43,1,1,2
66,16,53,4,0,
66,16,66,1,33,1
66,16,67,1,1,1
66,16,67,3,0,
66,16,68,2,0,
66,16,69,1,15,1
66,16,70,4,0,
66,16,89,4,0,
66,16,91,4,0,
66,16,92,4,0,
66,16,96,2,0,
66,16,104,4,0,
66,16,113,4,0,
66,16,116,1,3,1
66,16,126,4,0,
66,16,156,4,0,
66,16,157,4,0,
66,16,164,4,0,
66,16,168,4,0,
66,16,173,3,0,
66,16,182,4,0,
66,16,184,1,43,1
66,16,193,1,9,1
66,16,207,4,0,
66,16,213,4,0,
66,16,214,4,0,
66,16,216,4,0,
66,16,218,4,0,
66,16,223,1,45,1
66,16,227,2,0,
66,16,233,1,25,1
66,16,237,4,0,
66,16,238,1,39,1
66,16,240,4,0,
66,16,241,4,0,
66,16,249,4,0,
66,16,263,4,0,
66,16,264,3,0,
66,16,265,2,0,
66,16,270,3,0,
66,16,272,3,0,
66,16,276,3,0,
66,16,279,1,19,1
66,16,280,4,0,
66,16,282,1,21,1
66,16,282,2,0,
66,16,282,3,0,
66,16,290,4,0,
66,16,317,4,0,
66,16,321,2,0,
66,16,339,1,37,1
66,16,339,4,0,
66,16,358,1,27,1
66,16,370,2,0,
66,16,371,4,0,
66,16,374,4,0,
66,16,379,2,0,
66,16,398,4,0,
66,16,411,4,0,
66,16,418,2,0,
66,16,479,4,0,
66,16,484,2,0,
66,16,490,1,13,1
66,16,490,4,0,
66,16,496,4,0,
66,16,501,2,0,
66,16,510,4,0,
66,16,514,4,0,
66,16,523,4,0,
66,16,530,1,31,1
66,16,530,3,0,
66,16,590,4,0,
66,16,612,4,0,
66,17,2,1,7,
66,17,7,2,0,
66,17,8,2,0,
66,17,9,2,0,
66,17,27,2,0,
66,17,43,1,1,2
66,17,53,4,0,
66,17,66,1,33,
66,17,67,1,1,1
66,17,68,2,0,
66,17,69,1,15,
66,17,89,4,0,
66,17,92,4,0,
66,17,96,2,0,
66,17,104,4,0,
66,17,113,4,0,
66,17,116,1,3,
66,17,126,4,0,
66,17,156,4,0,
66,17,157,4,0,
66,17,164,4,0,
66,17,168,4,0,
66,17,182,4,0,
66,17,184,1,43,
66,17,193,1,9,
66,17,207,4,0,
66,17,213,4,0,
66,17,214,4,0,
66,17,216,4,0,
66,17,218,4,0,
66,17,223,1,45,
66,17,227,2,0,
66,17,233,1,25,
66,17,237,4,0,
66,17,238,1,39,
66,17,240,4,0,
66,17,241,4,0,
66,17,263,4,0,
66,17,265,2,0,
66,17,279,1,19,
66,17,280,4,0,
66,17,282,1,21,
66,17,282,2,0,
66,17,317,4,0,
66,17,321,2,0,
66,17,339,1,37,
66,17,339,4,0,
66,17,358,1,27,
66,17,370,2,0,
66,17,371,4,0,
66,17,374,4,0,
66,17,379,2,0,
66,17,398,4,0,
66,17,411,4,0,
66,17,418,2,0,
66,17,479,4,0,
66,17,484,2,0,
66,17,490,1,13,
66,17,490,4,0,
66,17,496,4,0,
66,17,501,2,0,
66,17,523,4,0,
66,17,526,4,0,
66,17,530,1,31,
66,17,590,4,0,
66,18,2,1,7,
66,18,7,2,0,
66,18,8,2,0,
66,18,9,2,0,
66,18,27,2,0,
66,18,43,1,1,2
66,18,53,4,0,
66,18,66,1,33,
66,18,67,1,1,1
66,18,68,2,0,
66,18,69,1,15,
66,18,89,4,0,
66,18,92,4,0,
66,18,96,2,0,
66,18,104,4,0,
66,18,113,4,0,
66,18,116,1,3,
66,18,126,4,0,
66,18,156,4,0,
66,18,157,4,0,
66,18,164,4,0,
66,18,168,4,0,
66,18,182,4,0,
66,18,184,1,43,
66,18,193,1,9,
66,18,207,4,0,
66,18,213,4,0,
66,18,214,4,0,
66,18,216,4,0,
66,18,218,4,0,
66,18,223,1,45,
66,18,227,2,0,
66,18,233,1,25,
66,18,237,4,0,
66,18,238,1,39,
66,18,240,4,0,
66,18,241,4,0,
66,18,263,4,0,
66,18,265,2,0,
66,18,279,1,19,
66,18,280,4,0,
66,18,282,1,21,
66,18,282,2,0,
66,18,317,4,0,
66,18,321,2,0,
66,18,339,1,37,
66,18,339,4,0,
66,18,358,1,27,
66,18,370,2,0,
66,18,371,4,0,
66,18,374,4,0,
66,18,379,2,0,
66,18,398,4,0,
66,18,411,4,0,
66,18,418,2,0,
66,18,479,4,0,
66,18,484,2,0,
66,18,490,1,13,
66,18,490,4,0,
66,18,496,4,0,
66,18,501,2,0,
66,18,523,4,0,
66,18,526,4,0,
66,18,530,1,31,
66,18,590,4,0,
67,1,2,1,1,1
67,1,5,4,0,
67,1,25,4,0,
67,1,34,4,0,
67,1,36,4,0,
67,1,38,4,0,
67,1,43,1,1,3
67,1,43,1,25,
67,1,66,1,52,
67,1,66,4,0,
67,1,67,1,1,2
67,1,67,1,20,
67,1,68,4,0,
67,1,69,1,44,
67,1,69,4,0,
67,1,70,4,0,
67,1,89,4,0,
67,1,90,4,0,
67,1,91,4,0,
67,1,92,4,0,
67,1,99,4,0,
67,1,102,4,0,
67,1,104,4,0,
67,1,116,1,36,
67,1,117,4,0,
67,1,118,4,0,
67,1,126,4,0,
67,1,130,4,0,
67,1,156,4,0,
67,1,157,4,0,
67,1,164,4,0,
67,2,2,1,1,1
67,2,5,4,0,
67,2,25,4,0,
67,2,34,4,0,
67,2,36,4,0,
67,2,38,4,0,
67,2,43,1,1,3
67,2,43,1,25,
67,2,66,1,52,
67,2,66,4,0,
67,2,67,1,1,2
67,2,67,1,20,
67,2,68,4,0,
67,2,69,1,44,
67,2,69,4,0,
67,2,70,4,0,
67,2,89,4,0,
67,2,90,4,0,
67,2,91,4,0,
67,2,92,4,0,
67,2,99,4,0,
67,2,102,4,0,
67,2,104,4,0,
67,2,116,1,36,
67,2,117,4,0,
67,2,118,4,0,
67,2,126,4,0,
67,2,130,4,0,
67,2,156,4,0,
67,2,157,4,0,
67,2,164,4,0,
67,3,2,1,15,
67,3,7,4,0,
67,3,8,4,0,
67,3,9,4,0,
67,3,29,4,0,
67,3,43,1,1,2
67,3,66,1,61,
67,3,67,1,1,1
67,3,69,1,19,
67,3,70,4,0,
67,3,89,4,0,
67,3,91,4,0,
67,3,92,4,0,
67,3,104,4,0,
67,3,116,1,1,3
67,3,116,1,8,
67,3,126,4,0,
67,3,156,4,0,
67,3,168,4,0,
67,3,173,4,0,
67,3,174,4,0,
67,3,182,4,0,
67,3,184,1,52,
67,3,189,4,0,
67,3,193,1,25,
67,3,197,4,0,
67,3,203,4,0,
67,3,207,4,0,
67,3,213,4,0,
67,3,214,4,0,
67,3,216,4,0,
67,3,218,4,0,
67,3,223,4,0,
67,3,233,1,34,
67,3,237,4,0,
67,3,238,1,43,
67,3,241,4,0,
67,3,249,4,0,
67,4,2,1,15,
67,4,7,4,0,
67,4,8,4,0,
67,4,9,4,0,
67,4,29,4,0,
67,4,43,1,1,2
67,4,53,3,0,
67,4,66,1,61,
67,4,67,1,1,1
67,4,69,1,19,
67,4,70,4,0,
67,4,89,4,0,
67,4,91,4,0,
67,4,92,4,0,
67,4,104,4,0,
67,4,116,1,1,3
67,4,116,1,8,
67,4,126,4,0,
67,4,156,4,0,
67,4,168,4,0,
67,4,173,4,0,
67,4,174,4,0,
67,4,182,4,0,
67,4,184,1,52,
67,4,189,4,0,
67,4,193,1,25,
67,4,197,4,0,
67,4,203,4,0,
67,4,207,4,0,
67,4,213,4,0,
67,4,214,4,0,
67,4,216,4,0,
67,4,218,4,0,
67,4,223,4,0,
67,4,233,1,34,
67,4,237,4,0,
67,4,238,1,43,
67,4,241,4,0,
67,4,249,4,0,
67,5,2,1,13,
67,5,43,1,1,2
67,5,53,4,0,
67,5,66,1,41,
67,5,67,1,1,1
67,5,69,1,19,
67,5,70,4,0,
67,5,89,4,0,
67,5,91,4,0,
67,5,92,4,0,
67,5,104,4,0,
67,5,116,1,1,3
67,5,116,1,7,
67,5,126,4,0,
67,5,156,4,0,
67,5,168,4,0,
67,5,182,4,0,
67,5,184,1,51,
67,5,193,1,22,
67,5,213,4,0,
67,5,216,4,0,
67,5,218,4,0,
67,5,223,1,59,
67,5,233,1,33,
67,5,237,4,0,
67,5,238,1,46,
67,5,240,4,0,
67,5,241,4,0,
67,5,249,4,0,
67,5,263,4,0,
67,5,264,4,0,
67,5,279,1,25,
67,5,280,4,0,
67,5,290,4,0,
67,5,317,4,0,
67,5,339,4,0,
67,6,2,1,13,
67,6,5,3,0,
67,6,7,3,0,
67,6,8,3,0,
67,6,9,3,0,
67,6,25,3,0,
67,6,34,3,0,
67,6,38,3,0,
67,6,43,1,1,2
67,6,53,4,0,
67,6,66,1,41,
67,6,67,1,1,1
67,6,68,3,0,
67,6,69,1,19,
67,6,69,3,0,
67,6,70,4,0,
67,6,89,4,0,
67,6,91,4,0,
67,6,92,4,0,
67,6,102,3,0,
67,6,104,4,0,
67,6,116,1,1,3
67,6,116,1,7,
67,6,118,3,0,
67,6,126,4,0,
67,6,156,4,0,
67,6,157,3,0,
67,6,164,3,0,
67,6,168,4,0,
67,6,173,3,0,
67,6,182,4,0,
67,6,184,1,51,
67,6,189,3,0,
67,6,193,1,22,
67,6,203,3,0,
67,6,207,3,0,
67,6,213,4,0,
67,6,214,3,0,
67,6,216,4,0,
67,6,218,4,0,
67,6,223,1,59,
67,6,223,3,0,
67,6,233,1,33,
67,6,237,4,0,
67,6,238,1,46,
67,6,240,4,0,
67,6,241,4,0,
67,6,249,4,0,
67,6,263,4,0,
67,6,264,4,0,
67,6,279,1,25,
67,6,280,4,0,
67,6,290,4,0,
67,6,317,4,0,
67,6,339,4,0,
67,7,2,1,13,
67,7,5,3,0,
67,7,25,3,0,
67,7,34,3,0,
67,7,38,3,0,
67,7,43,1,1,2
67,7,53,4,0,
67,7,66,1,41,
67,7,67,1,1,1
67,7,68,3,0,
67,7,69,1,19,
67,7,69,3,0,
67,7,70,4,0,
67,7,89,4,0,
67,7,91,4,0,
67,7,92,4,0,
67,7,102,3,0,
67,7,104,4,0,
67,7,116,1,1,3
67,7,116,1,7,
67,7,118,3,0,
67,7,126,4,0,
67,7,156,4,0,
67,7,157,3,0,
67,7,164,3,0,
67,7,168,4,0,
67,7,182,4,0,
67,7,184,1,51,
67,7,193,1,22,
67,7,213,4,0,
67,7,216,4,0,
67,7,218,4,0,
67,7,223,1,59,
67,7,233,1,33,
67,7,237,4,0,
67,7,238,1,46,
67,7,240,4,0,
67,7,241,4,0,
67,7,249,4,0,
67,7,263,4,0,
67,7,264,4,0,
67,7,279,1,25,
67,7,280,4,0,
67,7,290,4,0,
67,7,317,4,0,
67,7,339,4,0,
67,8,2,1,10,
67,8,43,1,1,2
67,8,53,4,0,
67,8,66,1,32,
67,8,67,1,1,1
67,8,69,1,19,
67,8,70,4,0,
67,8,89,4,0,
67,8,91,4,0,
67,8,92,4,0,
67,8,104,4,0,
67,8,116,1,1,3
67,8,116,1,7,
67,8,126,4,0,
67,8,156,4,0,
67,8,157,4,0,
67,8,164,4,0,
67,8,168,4,0,
67,8,182,4,0,
67,8,184,1,44,
67,8,193,1,13,
67,8,203,4,0,
67,8,207,4,0,
67,8,213,4,0,
67,8,214,4,0,
67,8,216,4,0,
67,8,218,4,0,
67,8,223,1,51,
67,8,233,1,25,
67,8,237,4,0,
67,8,238,1,40,
67,8,240,4,0,
67,8,241,4,0,
67,8,249,4,0,
67,8,263,4,0,
67,8,264,4,0,
67,8,279,1,22,
67,8,280,4,0,
67,8,290,4,0,
67,8,317,4,0,
67,8,339,4,0,
67,8,358,1,36,
67,8,363,4,0,
67,8,371,4,0,
67,8,374,4,0,
67,8,398,4,0,
67,8,411,4,0,
67,8,431,4,0,
67,8,445,4,0,
67,9,2,1,10,
67,9,7,3,0,
67,9,8,3,0,
67,9,9,3,0,
67,9,43,1,1,2
67,9,53,4,0,
67,9,66,1,32,
67,9,67,1,1,1
67,9,69,1,19,
67,9,70,4,0,
67,9,89,4,0,
67,9,91,4,0,
67,9,92,4,0,
67,9,104,4,0,
67,9,116,1,1,3
67,9,116,1,7,
67,9,126,4,0,
67,9,156,4,0,
67,9,157,4,0,
67,9,164,4,0,
67,9,168,4,0,
67,9,173,3,0,
67,9,182,4,0,
67,9,184,1,44,
67,9,189,3,0,
67,9,193,1,13,
67,9,203,4,0,
67,9,207,4,0,
67,9,213,4,0,
67,9,214,4,0,
67,9,216,4,0,
67,9,218,4,0,
67,9,223,1,51,
67,9,233,1,25,
67,9,237,4,0,
67,9,238,1,40,
67,9,240,4,0,
67,9,241,4,0,
67,9,249,4,0,
67,9,263,4,0,
67,9,264,4,0,
67,9,270,3,0,
67,9,276,3,0,
67,9,279,1,22,
67,9,280,4,0,
67,9,290,4,0,
67,9,317,4,0,
67,9,339,4,0,
67,9,358,1,36,
67,9,363,4,0,
67,9,371,4,0,
67,9,374,4,0,
67,9,398,4,0,
67,9,410,3,0,
67,9,411,4,0,
67,9,431,4,0,
67,9,445,4,0,
67,10,2,1,10,
67,10,7,3,0,
67,10,8,3,0,
67,10,9,3,0,
67,10,29,3,0,
67,10,43,1,1,2
67,10,53,4,0,
67,10,66,1,32,
67,10,67,1,1,1
67,10,67,3,0,
67,10,69,1,19,
67,10,70,4,0,
67,10,89,4,0,
67,10,91,4,0,
67,10,92,4,0,
67,10,104,4,0,
67,10,116,1,1,3
67,10,116,1,7,
67,10,126,4,0,
67,10,156,4,0,
67,10,157,4,0,
67,10,164,4,0,
67,10,168,4,0,
67,10,173,3,0,
67,10,182,4,0,
67,10,184,1,44,
67,10,189,3,0,
67,10,193,1,13,
67,10,203,4,0,
67,10,207,4,0,
67,10,213,4,0,
67,10,214,4,0,
67,10,216,4,0,
67,10,218,4,0,
67,10,223,1,51,
67,10,233,1,25,
67,10,237,4,0,
67,10,238,1,40,
67,10,240,4,0,
67,10,241,4,0,
67,10,249,4,0,
67,10,263,4,0,
67,10,264,4,0,
67,10,270,3,0,
67,10,272,3,0,
67,10,276,3,0,
67,10,279,1,22,
67,10,280,4,0,
67,10,290,4,0,
67,10,317,4,0,
67,10,339,4,0,
67,10,358,1,36,
67,10,363,4,0,
67,10,371,4,0,
67,10,374,4,0,
67,10,398,4,0,
67,10,410,3,0,
67,10,411,4,0,
67,10,431,4,0,
67,10,445,4,0,
67,11,2,1,1,4
67,11,2,1,10,
67,11,43,1,1,2
67,11,53,4,0,
67,11,66,1,36,
67,11,67,1,1,1
67,11,69,1,22,
67,11,70,4,0,
67,11,89,4,0,
67,11,91,4,0,
67,11,92,4,0,
67,11,104,4,0,
67,11,113,4,0,
67,11,116,1,1,3
67,11,116,1,7,
67,11,126,4,0,
67,11,156,4,0,
67,11,157,4,0,
67,11,164,4,0,
67,11,168,4,0,
67,11,182,4,0,
67,11,184,1,51,
67,11,193,1,19,
67,11,207,4,0,
67,11,213,4,0,
67,11,216,4,0,
67,11,218,4,0,
67,11,223,1,55,
67,11,233,1,32,
67,11,237,4,0,
67,11,238,1,44,
67,11,240,4,0,
67,11,241,4,0,
67,11,249,4,0,
67,11,263,4,0,
67,11,279,1,25,
67,11,280,4,0,
67,11,317,4,0,
67,11,339,4,0,
67,11,358,1,40,
67,11,371,4,0,
67,11,374,4,0,
67,11,398,4,0,
67,11,411,4,0,
67,11,479,4,0,
67,11,490,1,13,
67,11,490,4,0,
67,11,496,4,0,
67,11,510,4,0,
67,11,514,4,0,
67,11,523,4,0,
67,11,526,4,0,
67,12,2,1,13,
67,12,43,1,1,2
67,12,53,4,0,
67,12,66,1,41,
67,12,67,1,1,1
67,12,69,1,19,
67,12,70,4,0,
67,12,89,4,0,
67,12,91,4,0,
67,12,92,4,0,
67,12,104,4,0,
67,12,116,1,1,3
67,12,116,1,7,
67,12,126,4,0,
67,12,156,4,0,
67,12,168,4,0,
67,12,182,4,0,
67,12,184,1,51,
67,12,193,1,22,
67,12,213,4,0,
67,12,216,4,0,
67,12,218,4,0,
67,12,223,1,59,
67,12,233,1,33,
67,12,237,4,0,
67,12,238,1,46,
67,12,240,4,0,
67,12,241,4,0,
67,12,249,4,0,
67,12,263,4,0,
67,12,264,4,0,
67,12,279,1,25,
67,12,280,4,0,
67,12,290,4,0,
67,12,317,4,0,
67,12,339,4,0,
67,13,2,1,13,
67,13,34,3,0,
67,13,38,3,0,
67,13,43,1,1,2
67,13,53,4,0,
67,13,66,1,41,
67,13,67,1,1,1
67,13,69,1,19,
67,13,69,3,0,
67,13,70,4,0,
67,13,89,4,0,
67,13,91,4,0,
67,13,92,4,0,
67,13,102,3,0,
67,13,104,4,0,
67,13,116,1,1,3
67,13,116,1,7,
67,13,126,4,0,
67,13,156,4,0,
67,13,164,3,0,
67,13,168,4,0,
67,13,182,4,0,
67,13,184,1,51,
67,13,193,1,22,
67,13,207,3,0,
67,13,213,4,0,
67,13,216,4,0,
67,13,218,4,0,
67,13,223,1,59,
67,13,233,1,33,
67,13,237,4,0,
67,13,238,1,46,
67,13,240,4,0,
67,13,241,4,0,
67,13,249,4,0,
67,13,263,4,0,
67,13,264,4,0,
67,13,279,1,25,
67,13,280,4,0,
67,13,290,4,0,
67,13,317,4,0,
67,13,339,4,0,
67,14,2,1,1,4
67,14,2,1,10,
67,14,7,3,0,
67,14,8,3,0,
67,14,9,3,0,
67,14,43,1,1,2
67,14,53,4,0,
67,14,66,1,36,
67,14,67,1,1,1
67,14,67,3,0,
67,14,69,1,22,
67,14,70,4,0,
67,14,89,4,0,
67,14,91,4,0,
67,14,92,4,0,
67,14,104,4,0,
67,14,113,4,0,
67,14,116,1,1,3
67,14,116,1,7,
67,14,126,4,0,
67,14,156,4,0,
67,14,157,4,0,
67,14,164,4,0,
67,14,168,4,0,
67,14,173,3,0,
67,14,182,4,0,
67,14,184,1,51,
67,14,193,1,19,
67,14,207,4,0,
67,14,213,4,0,
67,14,214,3,0,
67,14,216,4,0,
67,14,218,4,0,
67,14,223,1,55,
67,14,233,1,32,
67,14,237,4,0,
67,14,238,1,44,
67,14,240,4,0,
67,14,241,4,0,
67,14,249,4,0,
67,14,263,4,0,
67,14,270,3,0,
67,14,272,3,0,
67,14,276,3,0,
67,14,279,1,25,
67,14,280,4,0,
67,14,282,3,0,
67,14,317,4,0,
67,14,339,4,0,
67,14,358,1,40,
67,14,371,4,0,
67,14,374,4,0,
67,14,398,4,0,
67,14,411,4,0,
67,14,479,4,0,
67,14,490,1,13,
67,14,490,4,0,
67,14,496,4,0,
67,14,510,4,0,
67,14,514,4,0,
67,14,523,4,0,
67,14,526,4,0,
67,14,530,3,0,
67,15,2,1,1,4
67,15,2,1,10,
67,15,43,1,1,2
67,15,53,4,0,
67,15,66,1,36,
67,15,67,1,1,1
67,15,69,1,22,
67,15,70,4,0,
67,15,89,4,0,
67,15,91,4,0,
67,15,92,4,0,
67,15,104,4,0,
67,15,113,4,0,
67,15,116,1,1,3
67,15,116,1,7,
67,15,126,4,0,
67,15,156,4,0,
67,15,157,4,0,
67,15,164,4,0,
67,15,168,4,0,
67,15,182,4,0,
67,15,184,1,51,
67,15,193,1,19,
67,15,207,4,0,
67,15,213,4,0,
67,15,214,4,0,
67,15,216,4,0,
67,15,218,4,0,
67,15,223,1,55,
67,15,233,1,32,
67,15,237,4,0,
67,15,238,1,44,
67,15,240,4,0,
67,15,241,4,0,
67,15,249,4,0,
67,15,263,4,0,
67,15,279,1,25,
67,15,280,4,0,
67,15,317,4,0,
67,15,339,4,0,
67,15,358,1,40,
67,15,371,4,0,
67,15,374,4,0,
67,15,398,4,0,
67,15,411,4,0,
67,15,479,4,0,
67,15,490,1,13,
67,15,490,4,0,
67,15,496,4,0,
67,15,510,4,0,
67,15,514,4,0,
67,15,523,4,0,
67,15,590,4,0,
67,15,612,4,0,
67,16,2,1,1,4
67,16,2,1,7,1
67,16,7,3,0,
67,16,8,3,0,
67,16,9,3,0,
67,16,43,1,1,2
67,16,53,4,0,
67,16,66,1,37,1
67,16,67,1,1,1
67,16,67,3,0,
67,16,69,1,15,1
67,16,70,4,0,
67,16,89,4,0,
67,16,91,4,0,
67,16,92,4,0,
67,16,104,4,0,
67,16,113,4,0,
67,16,116,1,1,3
67,16,116,1,3,1
67,16,126,4,0,
67,16,156,4,0,
67,16,157,4,0,
67,16,164,4,0,
67,16,168,4,0,
67,16,173,3,0,
67,16,182,4,0,
67,16,184,1,53,1
67,16,193,1,9,1
67,16,207,4,0,
67,16,213,4,0,
67,16,214,4,0,
67,16,216,4,0,
67,16,218,4,0,
67,16,223,1,57,1
67,16,233,1,25,1
67,16,237,4,0,
67,16,238,1,47,1
67,16,240,4,0,
67,16,241,4,0,
67,16,249,4,0,
67,16,263,4,0,
67,16,264,3,0,
67,16,270,3,0,
67,16,272,3,0,
67,16,276,3,0,
67,16,279,1,19,1
67,16,280,4,0,
67,16,282,1,21,1
67,16,282,3,0,
67,16,290,4,0,
67,16,317,4,0,
67,16,339,1,43,1
67,16,339,4,0,
67,16,358,1,27,1
67,16,371,4,0,
67,16,374,4,0,
67,16,398,4,0,
67,16,411,4,0,
67,16,479,4,0,
67,16,490,1,13,1
67,16,490,4,0,
67,16,496,4,0,
67,16,510,4,0,
67,16,514,4,0,
67,16,523,4,0,
67,16,530,1,33,1
67,16,530,3,0,
67,16,590,4,0,
67,16,612,4,0,
67,17,2,1,1,4
67,17,2,1,7,
67,17,43,1,1,2
67,17,53,4,0,
67,17,66,1,37,
67,17,67,1,1,1
67,17,69,1,15,
67,17,89,4,0,
67,17,92,4,0,
67,17,104,4,0,
67,17,113,4,0,
67,17,116,1,1,3
67,17,116,1,3,
67,17,126,4,0,
67,17,156,4,0,
67,17,157,4,0,
67,17,164,4,0,
67,17,168,4,0,
67,17,182,4,0,
67,17,184,1,53,
67,17,193,1,9,
67,17,207,4,0,
67,17,213,4,0,
67,17,214,4,0,
67,17,216,4,0,
67,17,218,4,0,
67,17,223,1,57,
67,17,233,1,25,
67,17,237,4,0,
67,17,238,1,47,
67,17,240,4,0,
67,17,241,4,0,
67,17,263,4,0,
67,17,279,1,19,
67,17,280,4,0,
67,17,282,1,21,
67,17,317,4,0,
67,17,339,1,43,
67,17,339,4,0,
67,17,358,1,27,
67,17,371,4,0,
67,17,374,4,0,
67,17,398,4,0,
67,17,411,4,0,
67,17,479,4,0,
67,17,490,1,13,
67,17,490,4,0,
67,17,496,4,0,
67,17,523,4,0,
67,17,526,4,0,
67,17,530,1,33,
67,17,590,4,0,
67,18,2,1,1,4
67,18,2,1,7,
67,18,43,1,1,2
67,18,53,4,0,
67,18,66,1,37,
67,18,67,1,1,1
67,18,69,1,15,
67,18,89,4,0,
67,18,92,4,0,
67,18,104,4,0,
67,18,113,4,0,
67,18,116,1,1,3
67,18,116,1,3,
67,18,126,4,0,
67,18,156,4,0,
67,18,157,4,0,
67,18,164,4,0,
67,18,168,4,0,
67,18,182,4,0,
67,18,184,1,53,
67,18,193,1,9,
67,18,207,4,0,
67,18,213,4,0,
67,18,214,4,0,
67,18,216,4,0,
67,18,218,4,0,
67,18,223,1,57,
67,18,233,1,25,
67,18,237,4,0,
67,18,238,1,47,
67,18,240,4,0,
67,18,241,4,0,
67,18,263,4,0,
67,18,279,1,19,
67,18,280,4,0,
67,18,282,1,21,
67,18,317,4,0,
67,18,339,1,43,
67,18,339,4,0,
67,18,358,1,27,
67,18,371,4,0,
67,18,374,4,0,
67,18,398,4,0,
67,18,411,4,0,
67,18,479,4,0,
67,18,490,1,13,
67,18,490,4,0,
67,18,496,4,0,
67,18,523,4,0,
67,18,526,4,0,
67,18,530,1,33,
67,18,590,4,0,
68,1,2,1,1,1
68,1,5,4,0,
68,1,25,4,0,
68,1,34,4,0,
68,1,36,4,0,
68,1,38,4,0,
68,1,43,1,1,3
68,1,43,1,25,
68,1,63,4,0,
68,1,66,1,52,
68,1,66,4,0,
68,1,67,1,1,2
68,1,67,1,20,
68,1,68,4,0,
68,1,69,1,44,
68,1,69,4,0,
68,1,70,4,0,
68,1,89,4,0,
68,1,90,4,0,
68,1,91,4,0,
68,1,92,4,0,
68,1,99,4,0,
68,1,102,4,0,
68,1,104,4,0,
68,1,116,1,36,
68,1,117,4,0,
68,1,118,4,0,
68,1,126,4,0,
68,1,130,4,0,
68,1,156,4,0,
68,1,157,4,0,
68,1,164,4,0,
68,2,2,1,1,1
68,2,5,4,0,
68,2,25,4,0,
68,2,34,4,0,
68,2,36,4,0,
68,2,38,4,0,
68,2,43,1,1,3
68,2,43,1,25,
68,2,63,4,0,
68,2,66,1,52,
68,2,66,4,0,
68,2,67,1,1,2
68,2,67,1,20,
68,2,68,4,0,
68,2,69,1,44,
68,2,69,4,0,
68,2,70,4,0,
68,2,89,4,0,
68,2,90,4,0,
68,2,91,4,0,
68,2,92,4,0,
68,2,99,4,0,
68,2,102,4,0,
68,2,104,4,0,
68,2,116,1,36,
68,2,117,4,0,
68,2,118,4,0,
68,2,126,4,0,
68,2,130,4,0,
68,2,156,4,0,
68,2,157,4,0,
68,2,164,4,0,
68,3,2,1,15,
68,3,7,4,0,
68,3,8,4,0,
68,3,9,4,0,
68,3,29,4,0,
68,3,43,1,1,2
68,3,63,4,0,
68,3,66,1,61,
68,3,67,1,1,1
68,3,69,1,19,
68,3,70,4,0,
68,3,89,4,0,
68,3,91,4,0,
68,3,92,4,0,
68,3,104,4,0,
68,3,116,1,1,3
68,3,116,1,8,
68,3,126,4,0,
68,3,156,4,0,
68,3,168,4,0,
68,3,173,4,0,
68,3,174,4,0,
68,3,182,4,0,
68,3,184,1,52,
68,3,189,4,0,
68,3,193,1,25,
68,3,197,4,0,
68,3,203,4,0,
68,3,207,4,0,
68,3,213,4,0,
68,3,214,4,0,
68,3,216,4,0,
68,3,218,4,0,
68,3,223,4,0,
68,3,233,1,34,
68,3,237,4,0,
68,3,238,1,43,
68,3,241,4,0,
68,3,249,4,0,
68,4,2,1,15,
68,4,7,4,0,
68,4,8,4,0,
68,4,9,4,0,
68,4,29,4,0,
68,4,43,1,1,2
68,4,53,3,0,
68,4,63,4,0,
68,4,66,1,61,
68,4,67,1,1,1
68,4,69,1,19,
68,4,70,4,0,
68,4,89,4,0,
68,4,91,4,0,
68,4,92,4,0,
68,4,104,4,0,
68,4,116,1,1,3
68,4,116,1,8,
68,4,126,4,0,
68,4,156,4,0,
68,4,168,4,0,
68,4,173,4,0,
68,4,174,4,0,
68,4,182,4,0,
68,4,184,1,52,
68,4,189,4,0,
68,4,193,1,25,
68,4,197,4,0,
68,4,203,4,0,
68,4,207,4,0,
68,4,213,4,0,
68,4,214,4,0,
68,4,216,4,0,
68,4,218,4,0,
68,4,223,4,0,
68,4,233,1,34,
68,4,237,4,0,
68,4,238,1,43,
68,4,241,4,0,
68,4,249,4,0,
68,5,2,1,13,
68,5,43,1,1,2
68,5,53,4,0,
68,5,63,4,0,
68,5,66,1,41,
68,5,67,1,1,1
68,5,69,1,19,
68,5,70,4,0,
68,5,89,4,0,
68,5,91,4,0,
68,5,92,4,0,
68,5,104,4,0,
68,5,116,1,1,3
68,5,116,1,7,
68,5,126,4,0,
68,5,156,4,0,
68,5,168,4,0,
68,5,182,4,0,
68,5,184,1,51,
68,5,193,1,22,
68,5,213,4,0,
68,5,216,4,0,
68,5,218,4,0,
68,5,223,1,59,
68,5,233,1,33,
68,5,237,4,0,
68,5,238,1,46,
68,5,240,4,0,
68,5,241,4,0,
68,5,249,4,0,
68,5,263,4,0,
68,5,264,4,0,
68,5,279,1,25,
68,5,280,4,0,
68,5,290,4,0,
68,5,317,4,0,
68,5,339,4,0,
68,6,2,1,13,
68,6,5,3,0,
68,6,7,3,0,
68,6,8,3,0,
68,6,9,3,0,
68,6,25,3,0,
68,6,34,3,0,
68,6,38,3,0,
68,6,43,1,1,2
68,6,53,4,0,
68,6,63,4,0,
68,6,66,1,41,
68,6,67,1,1,1
68,6,68,3,0,
68,6,69,1,19,
68,6,69,3,0,
68,6,70,4,0,
68,6,89,4,0,
68,6,91,4,0,
68,6,92,4,0,
68,6,102,3,0,
68,6,104,4,0,
68,6,116,1,1,3
68,6,116,1,7,
68,6,118,3,0,
68,6,126,4,0,
68,6,156,4,0,
68,6,157,3,0,
68,6,164,3,0,
68,6,168,4,0,
68,6,173,3,0,
68,6,182,4,0,
68,6,184,1,51,
68,6,189,3,0,
68,6,193,1,22,
68,6,203,3,0,
68,6,207,3,0,
68,6,213,4,0,
68,6,214,3,0,
68,6,216,4,0,
68,6,218,4,0,
68,6,223,1,59,
68,6,223,3,0,
68,6,233,1,33,
68,6,237,4,0,
68,6,238,1,46,
68,6,240,4,0,
68,6,241,4,0,
68,6,249,4,0,
68,6,263,4,0,
68,6,264,4,0,
68,6,279,1,25,
68,6,280,4,0,
68,6,290,4,0,
68,6,317,4,0,
68,6,339,4,0,
68,7,2,1,13,
68,7,5,3,0,
68,7,25,3,0,
68,7,34,3,0,
68,7,38,3,0,
68,7,43,1,1,2
68,7,53,4,0,
68,7,63,4,0,
68,7,66,1,41,
68,7,67,1,1,1
68,7,68,3,0,
68,7,69,1,19,
68,7,69,3,0,
68,7,70,4,0,
68,7,89,4,0,
68,7,91,4,0,
68,7,92,4,0,
68,7,102,3,0,
68,7,104,4,0,
68,7,116,1,1,3
68,7,116,1,7,
68,7,118,3,0,
68,7,126,4,0,
68,7,156,4,0,
68,7,157,3,0,
68,7,164,3,0,
68,7,168,4,0,
68,7,182,4,0,
68,7,184,1,51,
68,7,193,1,22,
68,7,213,4,0,
68,7,216,4,0,
68,7,218,4,0,
68,7,223,1,59,
68,7,233,1,33,
68,7,237,4,0,
68,7,238,1,46,
68,7,240,4,0,
68,7,241,4,0,
68,7,249,4,0,
68,7,263,4,0,
68,7,264,4,0,
68,7,279,1,25,
68,7,280,4,0,
68,7,290,4,0,
68,7,317,4,0,
68,7,339,4,0,
68,8,2,1,10,
68,8,43,1,1,2
68,8,53,4,0,
68,8,63,4,0,
68,8,66,1,32,
68,8,67,1,1,1
68,8,69,1,19,
68,8,70,4,0,
68,8,89,4,0,
68,8,91,4,0,
68,8,92,4,0,
68,8,104,4,0,
68,8,116,1,1,3
68,8,116,1,7,
68,8,126,4,0,
68,8,156,4,0,
68,8,157,4,0,
68,8,164,4,0,
68,8,168,4,0,
68,8,182,4,0,
68,8,184,1,44,
68,8,193,1,13,
68,8,203,4,0,
68,8,207,4,0,
68,8,213,4,0,
68,8,214,4,0,
68,8,216,4,0,
68,8,218,4,0,
68,8,223,1,51,
68,8,233,1,25,
68,8,237,4,0,
68,8,238,1,40,
68,8,240,4,0,
68,8,241,4,0,
68,8,249,4,0,
68,8,263,4,0,
68,8,264,4,0,
68,8,279,1,22,
68,8,280,4,0,
68,8,290,4,0,
68,8,317,4,0,
68,8,339,4,0,
68,8,358,1,36,
68,8,363,4,0,
68,8,371,4,0,
68,8,374,4,0,
68,8,398,4,0,
68,8,411,4,0,
68,8,416,4,0,
68,8,431,4,0,
68,8,444,4,0,
68,8,445,4,0,
68,9,2,1,10,
68,9,7,3,0,
68,9,8,3,0,
68,9,9,3,0,
68,9,43,1,1,2
68,9,53,4,0,
68,9,63,4,0,
68,9,66,1,32,
68,9,67,1,1,1
68,9,69,1,19,
68,9,70,4,0,
68,9,89,4,0,
68,9,91,4,0,
68,9,92,4,0,
68,9,104,4,0,
68,9,116,1,1,3
68,9,116,1,7,
68,9,126,4,0,
68,9,156,4,0,
68,9,157,4,0,
68,9,164,4,0,
68,9,168,4,0,
68,9,173,3,0,
68,9,182,4,0,
68,9,184,1,44,
68,9,189,3,0,
68,9,193,1,13,
68,9,203,4,0,
68,9,207,4,0,
68,9,213,4,0,
68,9,214,4,0,
68,9,216,4,0,
68,9,218,4,0,
68,9,223,1,51,
68,9,233,1,25,
68,9,237,4,0,
68,9,238,1,40,
68,9,240,4,0,
68,9,241,4,0,
68,9,249,4,0,
68,9,263,4,0,
68,9,264,4,0,
68,9,270,3,0,
68,9,276,3,0,
68,9,279,1,22,
68,9,280,4,0,
68,9,290,4,0,
68,9,317,4,0,
68,9,339,4,0,
68,9,358,1,36,
68,9,363,4,0,
68,9,371,4,0,
68,9,374,4,0,
68,9,398,4,0,
68,9,410,3,0,
68,9,411,4,0,
68,9,416,4,0,
68,9,431,4,0,
68,9,444,4,0,
68,9,445,4,0,
68,10,2,1,10,
68,10,7,3,0,
68,10,8,3,0,
68,10,9,3,0,
68,10,29,3,0,
68,10,43,1,1,2
68,10,53,4,0,
68,10,63,4,0,
68,10,66,1,32,
68,10,67,1,1,1
68,10,67,3,0,
68,10,69,1,19,
68,10,70,4,0,
68,10,89,4,0,
68,10,91,4,0,
68,10,92,4,0,
68,10,104,4,0,
68,10,116,1,1,3
68,10,116,1,7,
68,10,126,4,0,
68,10,156,4,0,
68,10,157,4,0,
68,10,164,4,0,
68,10,168,4,0,
68,10,173,3,0,
68,10,182,4,0,
68,10,184,1,44,
68,10,189,3,0,
68,10,193,1,13,
68,10,203,4,0,
68,10,207,4,0,
68,10,213,4,0,
68,10,214,4,0,
68,10,216,4,0,
68,10,218,4,0,
68,10,223,1,51,
68,10,233,1,25,
68,10,237,4,0,
68,10,238,1,40,
68,10,240,4,0,
68,10,241,4,0,
68,10,249,4,0,
68,10,263,4,0,
68,10,264,4,0,
68,10,270,3,0,
68,10,272,3,0,
68,10,276,3,0,
68,10,279,1,22,
68,10,280,4,0,
68,10,290,4,0,
68,10,317,4,0,
68,10,339,4,0,
68,10,358,1,36,
68,10,363,4,0,
68,10,371,4,0,
68,10,374,4,0,
68,10,398,4,0,
68,10,410,3,0,
68,10,411,4,0,
68,10,416,4,0,
68,10,431,4,0,
68,10,444,4,0,
68,10,445,4,0,
68,11,2,1,1,5
68,11,2,1,10,
68,11,43,1,1,3
68,11,53,4,0,
68,11,63,4,0,
68,11,66,1,36,
68,11,67,1,1,2
68,11,69,1,22,
68,11,70,4,0,
68,11,89,4,0,
68,11,91,4,0,
68,11,92,4,0,
68,11,104,4,0,
68,11,113,4,0,
68,11,116,1,1,4
68,11,116,1,7,
68,11,126,4,0,
68,11,156,4,0,
68,11,157,4,0,
68,11,164,4,0,
68,11,168,4,0,
68,11,182,4,0,
68,11,184,1,51,
68,11,193,1,19,
68,11,207,4,0,
68,11,213,4,0,
68,11,216,4,0,
68,11,218,4,0,
68,11,223,1,55,
68,11,233,1,32,
68,11,237,4,0,
68,11,238,1,44,
68,11,240,4,0,
68,11,241,4,0,
68,11,249,4,0,
68,11,263,4,0,
68,11,279,1,25,
68,11,280,4,0,
68,11,317,4,0,
68,11,339,4,0,
68,11,358,1,40,
68,11,371,4,0,
68,11,374,4,0,
68,11,398,4,0,
68,11,411,4,0,
68,11,416,4,0,
68,11,444,4,0,
68,11,469,1,1,1
68,11,479,4,0,
68,11,490,1,13,
68,11,490,4,0,
68,11,496,4,0,
68,11,510,4,0,
68,11,514,4,0,
68,11,523,4,0,
68,11,526,4,0,
68,12,2,1,13,
68,12,43,1,1,2
68,12,53,4,0,
68,12,63,4,0,
68,12,66,1,41,
68,12,67,1,1,1
68,12,69,1,19,
68,12,70,4,0,
68,12,89,4,0,
68,12,91,4,0,
68,12,92,4,0,
68,12,104,4,0,
68,12,116,1,1,3
68,12,116,1,7,
68,12,126,4,0,
68,12,156,4,0,
68,12,168,4,0,
68,12,182,4,0,
68,12,184,1,51,
68,12,193,1,22,
68,12,213,4,0,
68,12,216,4,0,
68,12,218,4,0,
68,12,223,1,59,
68,12,233,1,33,
68,12,237,4,0,
68,12,238,1,46,
68,12,240,4,0,
68,12,241,4,0,
68,12,249,4,0,
68,12,263,4,0,
68,12,264,4,0,
68,12,279,1,25,
68,12,280,4,0,
68,12,290,4,0,
68,12,317,4,0,
68,12,339,4,0,
68,13,2,1,13,
68,13,34,3,0,
68,13,38,3,0,
68,13,43,1,1,2
68,13,53,4,0,
68,13,63,4,0,
68,13,66,1,41,
68,13,67,1,1,1
68,13,69,1,19,
68,13,69,3,0,
68,13,70,4,0,
68,13,89,4,0,
68,13,91,4,0,
68,13,92,4,0,
68,13,102,3,0,
68,13,104,4,0,
68,13,116,1,1,3
68,13,116,1,7,
68,13,126,4,0,
68,13,156,4,0,
68,13,164,3,0,
68,13,168,4,0,
68,13,182,4,0,
68,13,184,1,51,
68,13,193,1,22,
68,13,207,3,0,
68,13,213,4,0,
68,13,216,4,0,
68,13,218,4,0,
68,13,223,1,59,
68,13,233,1,33,
68,13,237,4,0,
68,13,238,1,46,
68,13,240,4,0,
68,13,241,4,0,
68,13,249,4,0,
68,13,263,4,0,
68,13,264,4,0,
68,13,279,1,25,
68,13,280,4,0,
68,13,290,4,0,
68,13,317,4,0,
68,13,339,4,0,
68,14,2,1,1,5
68,14,2,1,10,
68,14,7,3,0,
68,14,8,3,0,
68,14,9,3,0,
68,14,43,1,1,3
68,14,53,4,0,
68,14,63,4,0,
68,14,66,1,36,
68,14,67,1,1,2
68,14,67,3,0,
68,14,69,1,22,
68,14,70,4,0,
68,14,89,4,0,
68,14,91,4,0,
68,14,92,4,0,
68,14,104,4,0,
68,14,113,4,0,
68,14,116,1,1,4
68,14,116,1,7,
68,14,126,4,0,
68,14,156,4,0,
68,14,157,4,0,
68,14,164,4,0,
68,14,168,4,0,
68,14,173,3,0,
68,14,182,4,0,
68,14,184,1,51,
68,14,193,1,19,
68,14,207,4,0,
68,14,213,4,0,
68,14,214,3,0,
68,14,216,4,0,
68,14,218,4,0,
68,14,223,1,55,
68,14,233,1,32,
68,14,237,4,0,
68,14,238,1,44,
68,14,240,4,0,
68,14,241,4,0,
68,14,249,4,0,
68,14,263,4,0,
68,14,270,3,0,
68,14,272,3,0,
68,14,276,3,0,
68,14,279,1,25,
68,14,280,4,0,
68,14,282,3,0,
68,14,317,4,0,
68,14,339,4,0,
68,14,358,1,40,
68,14,371,4,0,
68,14,374,4,0,
68,14,398,4,0,
68,14,411,4,0,
68,14,416,4,0,
68,14,444,4,0,
68,14,469,1,1,1
68,14,479,4,0,
68,14,490,1,13,
68,14,490,4,0,
68,14,496,4,0,
68,14,510,4,0,
68,14,514,4,0,
68,14,523,4,0,
68,14,526,4,0,
68,14,530,3,0,
68,15,2,1,1,5
68,15,2,1,10,
68,15,43,1,1,3
68,15,53,4,0,
68,15,63,4,0,
68,15,66,1,36,
68,15,67,1,1,2
68,15,69,1,22,
68,15,70,4,0,
68,15,89,4,0,
68,15,91,4,0,
68,15,92,4,0,
68,15,104,4,0,
68,15,113,4,0,
68,15,116,1,1,4
68,15,116,1,7,
68,15,126,4,0,
68,15,156,4,0,
68,15,157,4,0,
68,15,164,4,0,
68,15,168,4,0,
68,15,182,4,0,
68,15,184,1,51,
68,15,193,1,19,
68,15,207,4,0,
68,15,213,4,0,
68,15,214,4,0,
68,15,216,4,0,
68,15,218,4,0,
68,15,223,1,55,
68,15,233,1,32,
68,15,237,4,0,
68,15,238,1,44,
68,15,240,4,0,
68,15,241,4,0,
68,15,249,4,0,
68,15,263,4,0,
68,15,279,1,25,
68,15,280,4,0,
68,15,317,4,0,
68,15,339,4,0,
68,15,358,1,40,
68,15,371,4,0,
68,15,374,4,0,
68,15,398,4,0,
68,15,411,4,0,
68,15,416,4,0,
68,15,444,4,0,
68,15,469,1,1,1
68,15,479,4,0,
68,15,490,1,13,
68,15,490,4,0,
68,15,496,4,0,
68,15,510,4,0,
68,15,514,4,0,
68,15,523,4,0,
68,15,590,4,0,
68,15,612,4,0,
68,16,2,1,1,5
68,16,2,1,7,1
68,16,7,3,0,
68,16,8,3,0,
68,16,9,3,0,
68,16,43,1,1,3
68,16,53,4,0,
68,16,63,4,0,
68,16,66,1,37,1
68,16,67,1,1,2
68,16,67,3,0,
68,16,69,1,15,1
68,16,70,4,0,
68,16,89,4,0,
68,16,91,4,0,
68,16,92,4,0,
68,16,104,4,0,
68,16,113,4,0,
68,16,116,1,1,4
68,16,116,1,3,1
68,16,126,4,0,
68,16,156,4,0,
68,16,157,4,0,
68,16,164,4,0,
68,16,168,4,0,
68,16,173,3,0,
68,16,182,4,0,
68,16,184,1,53,1
68,16,193,1,9,1
68,16,207,4,0,
68,16,213,4,0,
68,16,214,4,0,
68,16,216,4,0,
68,16,218,4,0,
68,16,223,1,57,1
68,16,233,1,25,1
68,16,237,4,0,
68,16,238,1,47,1
68,16,240,4,0,
68,16,241,4,0,
68,16,249,4,0,
68,16,263,4,0,
68,16,264,3,0,
68,16,270,3,0,
68,16,272,3,0,
68,16,276,3,0,
68,16,279,1,19,1
68,16,280,4,0,
68,16,282,1,21,1
68,16,282,3,0,
68,16,290,4,0,
68,16,317,4,0,
68,16,339,1,43,1
68,16,339,4,0,
68,16,358,1,27,1
68,16,371,4,0,
68,16,374,4,0,
68,16,398,4,0,
68,16,411,4,0,
68,16,416,4,0,
68,16,444,4,0,
68,16,469,1,1,1
68,16,479,4,0,
68,16,490,1,13,1
68,16,490,4,0,
68,16,496,4,0,
68,16,510,4,0,
68,16,514,4,0,
68,16,523,4,0,
68,16,530,1,33,1
68,16,530,3,0,
68,16,590,4,0,
68,16,612,4,0,
68,17,2,1,1,6
68,17,2,1,7,
68,17,43,1,1,4
68,17,53,4,0,
68,17,63,4,0,
68,17,66,1,37,
68,17,67,1,1,3
68,17,69,1,15,
68,17,70,1,0,
68,17,70,1,1,1
68,17,89,4,0,
68,17,92,4,0,
68,17,104,4,0,
68,17,113,4,0,
68,17,116,1,1,5
68,17,116,1,3,
68,17,126,4,0,
68,17,156,4,0,
68,17,157,4,0,
68,17,164,4,0,
68,17,168,4,0,
68,17,182,4,0,
68,17,184,1,53,
68,17,193,1,9,
68,17,207,4,0,
68,17,213,4,0,
68,17,214,4,0,
68,17,216,4,0,
68,17,218,4,0,
68,17,223,1,57,
68,17,233,1,25,
68,17,237,4,0,
68,17,238,1,47,
68,17,240,4,0,
68,17,241,4,0,
68,17,263,4,0,
68,17,279,1,19,
68,17,280,4,0,
68,17,282,1,21,
68,17,317,4,0,
68,17,339,1,43,
68,17,339,4,0,
68,17,358,1,27,
68,17,371,4,0,
68,17,374,4,0,
68,17,398,4,0,
68,17,411,4,0,
68,17,416,4,0,
68,17,444,4,0,
68,17,469,1,1,2
68,17,479,4,0,
68,17,490,1,13,
68,17,490,4,0,
68,17,496,4,0,
68,17,523,4,0,
68,17,526,4,0,
68,17,530,1,33,
68,17,590,4,0,
68,18,2,1,1,6
68,18,2,1,7,
68,18,43,1,1,4
68,18,53,4,0,
68,18,63,4,0,
68,18,66,1,37,
68,18,67,1,1,3
68,18,69,1,15,
68,18,70,1,0,
68,18,70,1,1,1
68,18,89,4,0,
68,18,92,4,0,
68,18,104,4,0,
68,18,113,4,0,
68,18,116,1,1,5
68,18,116,1,3,
68,18,126,4,0,
68,18,156,4,0,
68,18,157,4,0,
68,18,164,4,0,
68,18,168,4,0,
68,18,182,4,0,
68,18,184,1,53,
68,18,193,1,9,
68,18,207,4,0,
68,18,213,4,0,
68,18,214,4,0,
68,18,216,4,0,
68,18,218,4,0,
68,18,223,1,57,
68,18,233,1,25,
68,18,237,4,0,
68,18,238,1,47,
68,18,240,4,0,
68,18,241,4,0,
68,18,263,4,0,
68,18,279,1,19,
68,18,280,4,0,
68,18,282,1,21,
68,18,317,4,0,
68,18,339,1,43,
68,18,339,4,0,
68,18,358,1,27,
68,18,371,4,0,
68,18,374,4,0,
68,18,398,4,0,
68,18,411,4,0,
68,18,416,4,0,
68,18,444,4,0,
68,18,469,1,1,2
68,18,479,4,0,
68,18,490,1,13,
68,18,490,4,0,
68,18,496,4,0,
68,18,523,4,0,
68,18,526,4,0,
68,18,530,1,33,
68,18,590,4,0,
69,1,14,4,0,
69,1,15,4,0,
69,1,21,1,42,
69,1,22,1,1,1
69,1,35,1,13,
69,1,36,4,0,
69,1,38,4,0,
69,1,51,1,26,
69,1,72,4,0,
69,1,74,1,1,2
69,1,75,1,33,
69,1,76,4,0,
69,1,77,1,15,
69,1,78,1,21,
69,1,79,1,18,
69,1,92,4,0,
69,1,99,4,0,
69,1,102,4,0,
69,1,104,4,0,
69,1,115,4,0,
69,1,117,4,0,
69,1,156,4,0,
69,1,164,4,0,
69,2,14,4,0,
69,2,15,4,0,
69,2,21,1,42,
69,2,22,1,1,1
69,2,35,1,13,
69,2,36,4,0,
69,2,38,4,0,
69,2,51,1,26,
69,2,72,4,0,
69,2,74,1,1,2
69,2,75,1,33,
69,2,76,4,0,
69,2,77,1,15,
69,2,78,1,21,
69,2,79,1,18,
69,2,92,4,0,
69,2,99,4,0,
69,2,102,4,0,
69,2,104,4,0,
69,2,115,4,0,
69,2,117,4,0,
69,2,156,4,0,
69,2,164,4,0,
69,3,14,2,0,
69,3,15,4,0,
69,3,21,1,45,
69,3,22,1,1,
69,3,35,1,11,
69,3,51,1,23,
69,3,74,1,6,
69,3,75,1,37,
69,3,76,4,0,
69,3,77,1,17,
69,3,78,1,19,
69,3,79,1,15,
69,3,92,4,0,
69,3,104,4,0,
69,3,115,2,0,
69,3,141,2,0,
69,3,148,4,0,
69,3,156,4,0,
69,3,173,4,0,
69,3,174,4,0,
69,3,182,4,0,
69,3,188,4,0,
69,3,202,4,0,
69,3,203,4,0,
69,3,207,4,0,
69,3,213,4,0,
69,3,214,4,0,
69,3,216,4,0,
69,3,218,4,0,
69,3,227,2,0,
69,3,230,1,30,
69,3,230,4,0,
69,3,235,2,0,
69,3,237,4,0,
69,3,241,4,0,
69,4,14,2,0,
69,4,15,4,0,
69,4,21,1,45,
69,4,22,1,1,
69,4,35,1,11,
69,4,51,1,23,
69,4,74,1,6,
69,4,75,1,37,
69,4,76,4,0,
69,4,77,1,17,
69,4,78,1,19,
69,4,79,1,15,
69,4,92,4,0,
69,4,104,4,0,
69,4,115,2,0,
69,4,141,2,0,
69,4,148,4,0,
69,4,156,4,0,
69,4,173,4,0,
69,4,174,4,0,
69,4,182,4,0,
69,4,188,4,0,
69,4,202,4,0,
69,4,203,4,0,
69,4,207,4,0,
69,4,213,4,0,
69,4,214,4,0,
69,4,216,4,0,
69,4,218,4,0,
69,4,227,2,0,
69,4,230,1,30,
69,4,230,4,0,
69,4,235,2,0,
69,4,237,4,0,
69,4,241,4,0,
69,5,14,2,0,
69,5,15,4,0,
69,5,21,1,45,
69,5,22,1,1,
69,5,35,1,11,
69,5,51,1,23,
69,5,74,1,6,
69,5,75,1,37,
69,5,76,4,0,
69,5,77,1,17,
69,5,78,1,19,
69,5,79,1,15,
69,5,92,4,0,
69,5,104,4,0,
69,5,115,2,0,
69,5,141,2,0,
69,5,148,4,0,
69,5,156,4,0,
69,5,168,4,0,
69,5,182,4,0,
69,5,188,4,0,
69,5,202,4,0,
69,5,213,4,0,
69,5,216,4,0,
69,5,218,4,0,
69,5,227,2,0,
69,5,230,1,30,
69,5,235,2,0,
69,5,237,4,0,
69,5,241,4,0,
69,5,263,4,0,
69,5,275,2,0,
69,5,290,4,0,
69,5,331,4,0,
69,5,345,2,0,
69,6,14,2,0,
69,6,14,3,0,
69,6,15,4,0,
69,6,21,1,45,
69,6,22,1,1,
69,6,35,1,11,
69,6,38,3,0,
69,6,51,1,23,
69,6,74,1,6,
69,6,75,1,37,
69,6,76,4,0,
69,6,77,1,17,
69,6,78,1,19,
69,6,79,1,15,
69,6,92,4,0,
69,6,102,3,0,
69,6,104,4,0,
69,6,115,2,0,
69,6,141,2,0,
69,6,148,4,0,
69,6,156,4,0,
69,6,164,3,0,
69,6,168,4,0,
69,6,173,3,0,
69,6,182,4,0,
69,6,188,4,0,
69,6,202,4,0,
69,6,203,3,0,
69,6,207,3,0,
69,6,213,4,0,
69,6,214,3,0,
69,6,216,4,0,
69,6,218,4,0,
69,6,227,2,0,
69,6,230,1,30,
69,6,235,2,0,
69,6,237,4,0,
69,6,241,4,0,
69,6,263,4,0,
69,6,275,2,0,
69,6,290,4,0,
69,6,331,4,0,
69,6,345,2,0,
69,7,14,2,0,
69,7,14,3,0,
69,7,15,4,0,
69,7,21,1,45,
69,7,22,1,1,
69,7,35,1,11,
69,7,38,3,0,
69,7,51,1,23,
69,7,74,1,6,
69,7,75,1,37,
69,7,76,4,0,
69,7,77,1,17,
69,7,78,1,19,
69,7,79,1,15,
69,7,92,4,0,
69,7,102,3,0,
69,7,104,4,0,
69,7,115,2,0,
69,7,141,2,0,
69,7,148,4,0,
69,7,156,4,0,
69,7,164,3,0,
69,7,168,4,0,
69,7,182,4,0,
69,7,188,4,0,
69,7,202,4,0,
69,7,213,4,0,
69,7,216,4,0,
69,7,218,4,0,
69,7,227,2,0,
69,7,230,1,30,
69,7,235,2,0,
69,7,237,4,0,
69,7,241,4,0,
69,7,263,4,0,
69,7,275,2,0,
69,7,290,4,0,
69,7,331,4,0,
69,7,345,2,0,
69,8,14,2,0,
69,8,14,4,0,
69,8,15,4,0,
69,8,21,1,41,
69,8,22,1,1,
69,8,35,1,11,
69,8,51,1,23,
69,8,74,1,7,
69,8,75,1,39,
69,8,76,4,0,
69,8,77,1,15,
69,8,78,1,17,
69,8,79,1,13,
69,8,92,4,0,
69,8,104,4,0,
69,8,115,2,0,
69,8,141,2,0,
69,8,148,4,0,
69,8,156,4,0,
69,8,164,4,0,
69,8,168,4,0,
69,8,182,4,0,
69,8,188,4,0,
69,8,202,4,0,
69,8,203,4,0,
69,8,207,4,0,
69,8,213,4,0,
69,8,214,4,0,
69,8,216,4,0,
69,8,218,4,0,
69,8,227,2,0,
69,8,230,1,29,
69,8,235,2,0,
69,8,237,4,0,
69,8,241,4,0,
69,8,263,4,0,
69,8,275,2,0,
69,8,282,1,27,
69,8,290,4,0,
69,8,321,2,0,
69,8,331,4,0,
69,8,345,2,0,
69,8,363,4,0,
69,8,378,1,47,
69,8,380,1,35,
69,8,388,2,0,
69,8,412,4,0,
69,8,445,4,0,
69,8,447,4,0,
69,9,14,2,0,
69,9,14,4,0,
69,9,15,4,0,
69,9,21,1,41,
69,9,22,1,1,
69,9,35,1,11,
69,9,51,1,23,
69,9,74,1,7,
69,9,75,1,39,
69,9,76,4,0,
69,9,77,1,15,
69,9,78,1,17,
69,9,79,1,13,
69,9,92,4,0,
69,9,104,4,0,
69,9,115,2,0,
69,9,141,2,0,
69,9,148,4,0,
69,9,156,4,0,
69,9,164,4,0,
69,9,168,4,0,
69,9,173,3,0,
69,9,182,4,0,
69,9,188,4,0,
69,9,202,4,0,
69,9,203,4,0,
69,9,207,4,0,
69,9,213,4,0,
69,9,214,4,0,
69,9,216,4,0,
69,9,218,4,0,
69,9,227,2,0,
69,9,230,1,29,
69,9,235,2,0,
69,9,235,3,0,
69,9,237,4,0,
69,9,241,4,0,
69,9,263,4,0,
69,9,275,2,0,
69,9,282,1,27,
69,9,282,3,0,
69,9,290,4,0,
69,9,321,2,0,
69,9,331,4,0,
69,9,345,2,0,
69,9,363,4,0,
69,9,378,1,47,
69,9,380,1,35,
69,9,380,3,0,
69,9,388,2,0,
69,9,389,3,0,
69,9,402,3,0,
69,9,412,4,0,
69,9,445,4,0,
69,9,447,4,0,
69,10,14,2,0,
69,10,14,4,0,
69,10,15,4,0,
69,10,21,1,41,
69,10,22,1,1,
69,10,35,1,11,
69,10,51,1,23,
69,10,74,1,7,
69,10,75,1,39,
69,10,76,4,0,
69,10,77,1,15,
69,10,78,1,17,
69,10,79,1,13,
69,10,92,4,0,
69,10,104,4,0,
69,10,115,2,0,
69,10,141,2,0,
69,10,148,4,0,
69,10,156,4,0,
69,10,164,4,0,
69,10,168,4,0,
69,10,173,3,0,
69,10,182,4,0,
69,10,188,4,0,
69,10,202,4,0,
69,10,203,4,0,
69,10,207,4,0,
69,10,213,4,0,
69,10,214,4,0,
69,10,216,4,0,
69,10,218,4,0,
69,10,227,2,0,
69,10,230,1,29,
69,10,235,2,0,
69,10,235,3,0,
69,10,237,4,0,
69,10,241,4,0,
69,10,263,4,0,
69,10,275,2,0,
69,10,282,1,27,
69,10,282,3,0,
69,10,290,4,0,
69,10,311,2,0,
69,10,321,2,0,
69,10,331,4,0,
69,10,345,2,0,
69,10,363,4,0,
69,10,378,1,47,
69,10,380,1,35,
69,10,380,3,0,
69,10,388,2,0,
69,10,388,3,0,
69,10,389,3,0,
69,10,402,3,0,
69,10,412,4,0,
69,10,445,4,0,
69,10,447,4,0,
69,11,14,4,0,
69,11,15,4,0,
69,11,21,1,41,
69,11,22,1,1,
69,11,35,1,11,
69,11,51,1,23,
69,11,74,1,7,
69,11,75,1,39,
69,11,76,4,0,
69,11,77,1,15,
69,11,78,1,17,
69,11,79,1,13,
69,11,92,4,0,
69,11,104,4,0,
69,11,115,4,0,
69,11,141,2,0,
69,11,148,4,0,
69,11,156,4,0,
69,11,164,4,0,
69,11,168,4,0,
69,11,182,4,0,
69,11,188,4,0,
69,11,202,2,0,
69,11,207,4,0,
69,11,213,4,0,
69,11,216,4,0,
69,11,218,4,0,
69,11,227,2,0,
69,11,230,1,29,
69,11,235,2,0,
69,11,237,4,0,
69,11,241,4,0,
69,11,263,4,0,
69,11,275,2,0,
69,11,282,1,27,
69,11,311,2,0,
69,11,321,2,0,
69,11,331,2,0,
69,11,345,2,0,
69,11,363,2,0,
69,11,378,1,47,
69,11,380,1,35,
69,11,388,2,0,
69,11,412,4,0,
69,11,438,2,0,
69,11,447,4,0,
69,11,474,4,0,
69,11,496,4,0,
69,11,499,2,0,
69,12,15,4,0,
69,12,21,1,45,
69,12,22,1,1,
69,12,35,1,11,
69,12,51,1,23,
69,12,74,1,6,
69,12,75,1,37,
69,12,76,4,0,
69,12,77,1,17,
69,12,78,1,19,
69,12,79,1,15,
69,12,92,4,0,
69,12,104,4,0,
69,12,148,4,0,
69,12,156,4,0,
69,12,168,4,0,
69,12,182,4,0,
69,12,188,4,0,
69,12,202,4,0,
69,12,213,4,0,
69,12,216,4,0,
69,12,218,4,0,
69,12,230,1,30,
69,12,237,4,0,
69,12,241,4,0,
69,12,263,4,0,
69,12,290,4,0,
69,12,331,4,0,
69,13,15,4,0,
69,13,21,1,45,
69,13,22,1,1,
69,13,35,1,11,
69,13,38,3,0,
69,13,51,1,23,
69,13,74,1,6,
69,13,75,1,37,
69,13,76,4,0,
69,13,77,1,17,
69,13,78,1,19,
69,13,79,1,15,
69,13,92,4,0,
69,13,102,3,0,
69,13,104,4,0,
69,13,148,4,0,
69,13,156,4,0,
69,13,164,3,0,
69,13,168,4,0,
69,13,182,4,0,
69,13,188,4,0,
69,13,202,4,0,
69,13,207,3,0,
69,13,213,4,0,
69,13,216,4,0,
69,13,218,4,0,
69,13,230,1,30,
69,13,237,4,0,
69,13,241,4,0,
69,13,263,4,0,
69,13,290,4,0,
69,13,331,4,0,
69,14,14,4,0,
69,14,15,4,0,
69,14,20,3,0,
69,14,21,1,41,
69,14,22,1,1,
69,14,35,1,11,
69,14,51,1,23,
69,14,74,1,7,
69,14,75,1,39,
69,14,76,4,0,
69,14,77,1,15,
69,14,78,1,17,
69,14,79,1,13,
69,14,92,4,0,
69,14,104,4,0,
69,14,115,4,0,
69,14,141,2,0,
69,14,148,4,0,
69,14,156,4,0,
69,14,164,4,0,
69,14,168,4,0,
69,14,173,3,0,
69,14,182,4,0,
69,14,188,4,0,
69,14,202,2,0,
69,14,202,3,0,
69,14,207,4,0,
69,14,213,4,0,
69,14,214,3,0,
69,14,216,4,0,
69,14,218,4,0,
69,14,227,2,0,
69,14,230,1,29,
69,14,235,2,0,
69,14,235,3,0,
69,14,237,4,0,
69,14,241,4,0,
69,14,263,4,0,
69,14,275,2,0,
69,14,282,1,27,
69,14,282,3,0,
69,14,311,2,0,
69,14,321,2,0,
69,14,331,2,0,
69,14,345,2,0,
69,14,363,2,0,
69,14,378,1,47,
69,14,380,1,35,
69,14,380,3,0,
69,14,388,2,0,
69,14,388,3,0,
69,14,402,3,0,
69,14,412,4,0,
69,14,438,2,0,
69,14,447,4,0,
69,14,474,4,0,
69,14,496,4,0,
69,14,499,2,0,
69,15,14,4,0,
69,15,15,4,0,
69,15,21,1,41,
69,15,22,1,1,
69,15,35,1,11,
69,15,51,1,23,
69,15,74,1,7,
69,15,75,1,39,
69,15,76,4,0,
69,15,77,1,15,
69,15,78,1,17,
69,15,79,1,13,
69,15,92,4,0,
69,15,104,4,0,
69,15,115,4,0,
69,15,141,2,0,
69,15,148,4,0,
69,15,156,4,0,
69,15,164,4,0,
69,15,168,4,0,
69,15,182,4,0,
69,15,188,4,0,
69,15,202,2,0,
69,15,207,4,0,
69,15,213,4,0,
69,15,214,4,0,
69,15,216,4,0,
69,15,218,4,0,
69,15,227,2,0,
69,15,230,1,29,
69,15,235,2,0,
69,15,237,4,0,
69,15,241,4,0,
69,15,263,4,0,
69,15,267,4,0,
69,15,275,2,0,
69,15,282,1,27,
69,15,311,2,0,
69,15,321,2,0,
69,15,331,2,0,
69,15,345,2,0,
69,15,363,2,0,
69,15,378,1,47,
69,15,380,1,35,
69,15,388,2,0,
69,15,412,4,0,
69,15,438,2,0,
69,15,447,4,0,
69,15,474,4,0,
69,15,491,2,0,
69,15,496,4,0,
69,15,499,2,0,
69,15,562,2,0,
69,15,590,4,0,
69,15,611,4,0,
69,16,14,4,0,
69,16,15,4,0,
69,16,20,3,0,
69,16,21,1,41,1
69,16,22,1,1,1
69,16,35,1,11,1
69,16,51,1,23,1
69,16,74,1,7,1
69,16,75,1,39,1
69,16,76,4,0,
69,16,77,1,15,1
69,16,78,1,17,1
69,16,79,1,13,1
69,16,92,4,0,
69,16,104,4,0,
69,16,115,4,0,
69,16,141,2,0,
69,16,148,4,0,
69,16,156,4,0,
69,16,164,4,0,
69,16,168,4,0,
69,16,173,3,0,
69,16,182,4,0,
69,16,188,4,0,
69,16,202,2,0,
69,16,202,3,0,
69,16,207,4,0,
69,16,213,4,0,
69,16,214,4,0,
69,16,216,4,0,
69,16,218,4,0,
69,16,227,2,0,
69,16,230,1,29,1
69,16,235,2,0,
69,16,235,3,0,
69,16,237,4,0,
69,16,241,4,0,
69,16,263,4,0,
69,16,267,4,0,
69,16,275,2,0,
69,16,282,1,27,1
69,16,282,3,0,
69,16,290,4,0,
69,16,311,2,0,
69,16,321,2,0,
69,16,331,2,0,
69,16,345,2,0,
69,16,363,2,0,
69,16,378,1,47,1
69,16,380,1,35,1
69,16,380,3,0,
69,16,388,2,0,
69,16,388,3,0,
69,16,402,3,0,
69,16,412,4,0,
69,16,438,2,0,
69,16,447,4,0,
69,16,474,4,0,
69,16,491,2,0,
69,16,496,4,0,
69,16,499,2,0,
69,16,562,2,0,
69,16,590,4,0,
69,16,611,4,0,
69,17,14,4,0,
69,17,21,1,47,
69,17,22,1,1,
69,17,35,1,11,
69,17,51,1,23,
69,17,74,1,7,
69,17,75,1,39,
69,17,76,4,0,
69,17,77,1,15,
69,17,78,1,17,
69,17,79,1,13,
69,17,92,4,0,
69,17,104,4,0,
69,17,115,4,0,
69,17,141,2,0,
69,17,156,4,0,
69,17,164,4,0,
69,17,168,4,0,
69,17,182,4,0,
69,17,188,4,0,
69,17,202,2,0,
69,17,207,4,0,
69,17,213,4,0,
69,17,214,4,0,
69,17,216,4,0,
69,17,218,4,0,
69,17,227,2,0,
69,17,230,1,29,
69,17,235,2,0,
69,17,237,4,0,
69,17,241,4,0,
69,17,263,4,0,
69,17,267,4,0,
69,17,275,2,0,
69,17,282,1,27,
69,17,311,2,0,
69,17,321,2,0,
69,17,331,2,0,
69,17,345,2,0,
69,17,363,2,0,
69,17,378,1,50,
69,17,380,1,35,
69,17,388,2,0,
69,17,398,1,41,
69,17,398,4,0,
69,17,412,4,0,
69,17,438,2,0,
69,17,447,4,0,
69,17,474,4,0,
69,17,491,2,0,
69,17,496,4,0,
69,17,499,2,0,
69,17,562,2,0,
69,17,590,4,0,
69,17,611,4,0,
69,18,14,4,0,
69,18,21,1,47,
69,18,22,1,1,
69,18,35,1,11,
69,18,51,1,23,
69,18,74,1,7,
69,18,75,1,39,
69,18,76,4,0,
69,18,77,1,15,
69,18,78,1,17,
69,18,79,1,13,
69,18,92,4,0,
69,18,104,4,0,
69,18,115,4,0,
69,18,141,2,0,
69,18,156,4,0,
69,18,164,4,0,
69,18,168,4,0,
69,18,182,4,0,
69,18,188,4,0,
69,18,202,2,0,
69,18,207,4,0,
69,18,213,4,0,
69,18,214,4,0,
69,18,216,4,0,
69,18,218,4,0,
69,18,227,2,0,
69,18,230,1,29,
69,18,235,2,0,
69,18,237,4,0,
69,18,241,4,0,
69,18,263,4,0,
69,18,267,4,0,
69,18,275,2,0,
69,18,282,1,27,
69,18,311,2,0,
69,18,321,2,0,
69,18,331,2,0,
69,18,345,2,0,
69,18,363,2,0,
69,18,378,1,50,
69,18,380,1,35,
69,18,388,2,0,
69,18,398,1,41,
69,18,398,4,0,
69,18,412,4,0,
69,18,438,2,0,
69,18,447,4,0,
69,18,474,4,0,
69,18,491,2,0,
69,18,496,4,0,
69,18,499,2,0,
69,18,562,2,0,
69,18,590,4,0,
69,18,611,4,0,
69,18,668,2,0,
70,1,14,4,0,
70,1,15,4,0,
70,1,21,1,49,
70,1,22,1,1,1
70,1,35,1,1,3
70,1,35,1,13,
70,1,36,4,0,
70,1,38,4,0,
70,1,51,1,29,
70,1,72,4,0,
70,1,74,1,1,2
70,1,75,1,38,
70,1,76,4,0,
70,1,77,1,15,
70,1,78,1,23,
70,1,79,1,18,
70,1,92,4,0,
70,1,99,4,0,
70,1,102,4,0,
70,1,104,4,0,
70,1,115,4,0,
70,1,117,4,0,
70,1,156,4,0,
70,1,164,4,0,
70,2,14,4,0,
70,2,15,4,0,
70,2,21,1,49,
70,2,22,1,1,1
70,2,35,1,1,3
70,2,35,1,13,
70,2,36,4,0,
70,2,38,4,0,
70,2,51,1,29,
70,2,72,4,0,
70,2,74,1,1,2
70,2,75,1,38,
70,2,76,4,0,
70,2,77,1,15,
70,2,78,1,23,
70,2,79,1,18,
70,2,92,4,0,
70,2,99,4,0,
70,2,102,4,0,
70,2,104,4,0,
70,2,115,4,0,
70,2,117,4,0,
70,2,156,4,0,
70,2,164,4,0,
70,3,15,4,0,
70,3,21,1,54,
70,3,22,1,1,1
70,3,35,1,1,3
70,3,35,1,11,
70,3,51,1,24,
70,3,74,1,1,2
70,3,74,1,6,
70,3,75,1,42,
70,3,76,4,0,
70,3,77,1,17,
70,3,78,1,19,
70,3,79,1,15,
70,3,92,4,0,
70,3,104,4,0,
70,3,148,4,0,
70,3,156,4,0,
70,3,173,4,0,
70,3,174,4,0,
70,3,182,4,0,
70,3,188,4,0,
70,3,202,4,0,
70,3,203,4,0,
70,3,207,4,0,
70,3,213,4,0,
70,3,214,4,0,
70,3,216,4,0,
70,3,218,4,0,
70,3,230,1,33,
70,3,230,4,0,
70,3,237,4,0,
70,3,241,4,0,
70,4,15,4,0,
70,4,21,1,54,
70,4,22,1,1,1
70,4,35,1,1,3
70,4,35,1,11,
70,4,51,1,24,
70,4,74,1,1,2
70,4,74,1,6,
70,4,75,1,42,
70,4,76,4,0,
70,4,77,1,17,
70,4,78,1,19,
70,4,79,1,15,
70,4,92,4,0,
70,4,104,4,0,
70,4,148,4,0,
70,4,156,4,0,
70,4,173,4,0,
70,4,174,4,0,
70,4,182,4,0,
70,4,188,4,0,
70,4,202,4,0,
70,4,203,4,0,
70,4,207,4,0,
70,4,213,4,0,
70,4,214,4,0,
70,4,216,4,0,
70,4,218,4,0,
70,4,230,1,33,
70,4,230,4,0,
70,4,237,4,0,
70,4,241,4,0,
70,5,15,4,0,
70,5,21,1,54,
70,5,22,1,1,1
70,5,35,1,1,3
70,5,35,1,11,
70,5,51,1,24,
70,5,74,1,1,2
70,5,74,1,6,
70,5,75,1,42,
70,5,76,4,0,
70,5,77,1,17,
70,5,78,1,19,
70,5,79,1,15,
70,5,92,4,0,
70,5,104,4,0,
70,5,148,4,0,
70,5,156,4,0,
70,5,168,4,0,
70,5,182,4,0,
70,5,188,4,0,
70,5,202,4,0,
70,5,213,4,0,
70,5,216,4,0,
70,5,218,4,0,
70,5,230,1,33,
70,5,237,4,0,
70,5,241,4,0,
70,5,263,4,0,
70,5,290,4,0,
70,5,331,4,0,
70,6,14,3,0,
70,6,15,4,0,
70,6,21,1,54,
70,6,22,1,1,1
70,6,35,1,1,3
70,6,35,1,11,
70,6,38,3,0,
70,6,51,1,24,
70,6,74,1,1,2
70,6,74,1,6,
70,6,75,1,42,
70,6,76,4,0,
70,6,77,1,17,
70,6,78,1,19,
70,6,79,1,15,
70,6,92,4,0,
70,6,102,3,0,
70,6,104,4,0,
70,6,148,4,0,
70,6,156,4,0,
70,6,164,3,0,
70,6,168,4,0,
70,6,173,3,0,
70,6,182,4,0,
70,6,188,4,0,
70,6,202,4,0,
70,6,203,3,0,
70,6,207,3,0,
70,6,213,4,0,
70,6,214,3,0,
70,6,216,4,0,
70,6,218,4,0,
70,6,230,1,33,
70,6,237,4,0,
70,6,241,4,0,
70,6,263,4,0,
70,6,290,4,0,
70,6,331,4,0,
70,7,14,3,0,
70,7,15,4,0,
70,7,21,1,54,
70,7,22,1,1,1
70,7,35,1,1,3
70,7,35,1,11,
70,7,38,3,0,
70,7,51,1,24,
70,7,74,1,1,2
70,7,74,1,6,
70,7,75,1,42,
70,7,76,4,0,
70,7,77,1,17,
70,7,78,1,19,
70,7,79,1,15,
70,7,92,4,0,
70,7,102,3,0,
70,7,104,4,0,
70,7,148,4,0,
70,7,156,4,0,
70,7,164,3,0,
70,7,168,4,0,
70,7,182,4,0,
70,7,188,4,0,
70,7,202,4,0,
70,7,213,4,0,
70,7,216,4,0,
70,7,218,4,0,
70,7,230,1,33,
70,7,237,4,0,
70,7,241,4,0,
70,7,263,4,0,
70,7,290,4,0,
70,7,331,4,0,
70,8,14,4,0,
70,8,15,4,0,
70,8,21,1,41,
70,8,22,1,1,1
70,8,35,1,1,3
70,8,35,1,11,
70,8,51,1,23,
70,8,74,1,1,2
70,8,74,1,7,
70,8,75,1,39,
70,8,76,4,0,
70,8,77,1,15,
70,8,78,1,17,
70,8,79,1,13,
70,8,92,4,0,
70,8,104,4,0,
70,8,148,4,0,
70,8,156,4,0,
70,8,164,4,0,
70,8,168,4,0,
70,8,182,4,0,
70,8,188,4,0,
70,8,202,4,0,
70,8,203,4,0,
70,8,207,4,0,
70,8,213,4,0,
70,8,214,4,0,
70,8,216,4,0,
70,8,218,4,0,
70,8,230,1,29,
70,8,237,4,0,
70,8,241,4,0,
70,8,263,4,0,
70,8,282,1,27,
70,8,290,4,0,
70,8,331,4,0,
70,8,363,4,0,
70,8,378,1,47,
70,8,380,1,35,
70,8,412,4,0,
70,8,445,4,0,
70,8,447,4,0,
70,9,14,4,0,
70,9,15,4,0,
70,9,21,1,41,
70,9,22,1,1,1
70,9,35,1,1,3
70,9,35,1,11,
70,9,51,1,23,
70,9,74,1,1,2
70,9,74,1,7,
70,9,75,1,39,
70,9,76,4,0,
70,9,77,1,15,
70,9,78,1,17,
70,9,79,1,13,
70,9,92,4,0,
70,9,104,4,0,
70,9,148,4,0,
70,9,156,4,0,
70,9,164,4,0,
70,9,168,4,0,
70,9,173,3,0,
70,9,182,4,0,
70,9,188,4,0,
70,9,202,4,0,
70,9,203,4,0,
70,9,207,4,0,
70,9,213,4,0,
70,9,214,4,0,
70,9,216,4,0,
70,9,218,4,0,
70,9,230,1,29,
70,9,235,3,0,
70,9,237,4,0,
70,9,241,4,0,
70,9,263,4,0,
70,9,282,1,27,
70,9,282,3,0,
70,9,290,4,0,
70,9,331,4,0,
70,9,363,4,0,
70,9,378,1,47,
70,9,380,1,35,
70,9,380,3,0,
70,9,389,3,0,
70,9,402,3,0,
70,9,412,4,0,
70,9,445,4,0,
70,9,447,4,0,
70,10,14,4,0,
70,10,15,4,0,
70,10,21,1,41,
70,10,22,1,1,1
70,10,35,1,1,3
70,10,35,1,11,
70,10,51,1,23,
70,10,74,1,1,2
70,10,74,1,7,
70,10,75,1,39,
70,10,76,4,0,
70,10,77,1,15,
70,10,78,1,17,
70,10,79,1,13,
70,10,92,4,0,
70,10,104,4,0,
70,10,148,4,0,
70,10,156,4,0,
70,10,164,4,0,
70,10,168,4,0,
70,10,173,3,0,
70,10,182,4,0,
70,10,188,4,0,
70,10,202,4,0,
70,10,203,4,0,
70,10,207,4,0,
70,10,213,4,0,
70,10,214,4,0,
70,10,216,4,0,
70,10,218,4,0,
70,10,230,1,29,
70,10,235,3,0,
70,10,237,4,0,
70,10,241,4,0,
70,10,263,4,0,
70,10,282,1,27,
70,10,282,3,0,
70,10,290,4,0,
70,10,331,4,0,
70,10,363,4,0,
70,10,378,1,47,
70,10,380,1,35,
70,10,380,3,0,
70,10,388,3,0,
70,10,389,3,0,
70,10,402,3,0,
70,10,412,4,0,
70,10,445,4,0,
70,10,447,4,0,
70,11,14,4,0,
70,11,15,4,0,
70,11,21,1,41,
70,11,22,1,1,1
70,11,35,1,1,3
70,11,35,1,11,
70,11,51,1,23,
70,11,74,1,1,2
70,11,74,1,7,
70,11,75,1,39,
70,11,76,4,0,
70,11,77,1,15,
70,11,78,1,17,
70,11,79,1,13,
70,11,92,4,0,
70,11,104,4,0,
70,11,115,4,0,
70,11,148,4,0,
70,11,156,4,0,
70,11,164,4,0,
70,11,168,4,0,
70,11,182,4,0,
70,11,188,4,0,
70,11,207,4,0,
70,11,213,4,0,
70,11,216,4,0,
70,11,218,4,0,
70,11,230,1,29,
70,11,237,4,0,
70,11,241,4,0,
70,11,263,4,0,
70,11,282,1,27,
70,11,378,1,47,
70,11,380,1,35,
70,11,412,4,0,
70,11,447,4,0,
70,11,474,4,0,
70,11,496,4,0,
70,12,15,4,0,
70,12,21,1,54,
70,12,22,1,1,1
70,12,35,1,1,3
70,12,35,1,11,
70,12,51,1,24,
70,12,74,1,1,2
70,12,74,1,6,
70,12,75,1,42,
70,12,76,4,0,
70,12,77,1,17,
70,12,78,1,19,
70,12,79,1,15,
70,12,92,4,0,
70,12,104,4,0,
70,12,148,4,0,
70,12,156,4,0,
70,12,168,4,0,
70,12,182,4,0,
70,12,188,4,0,
70,12,202,4,0,
70,12,213,4,0,
70,12,216,4,0,
70,12,218,4,0,
70,12,230,1,33,
70,12,237,4,0,
70,12,241,4,0,
70,12,263,4,0,
70,12,290,4,0,
70,12,331,4,0,
70,13,15,4,0,
70,13,21,1,54,
70,13,22,1,1,1
70,13,35,1,1,3
70,13,35,1,11,
70,13,38,3,0,
70,13,51,1,24,
70,13,74,1,1,2
70,13,74,1,6,
70,13,75,1,42,
70,13,76,4,0,
70,13,77,1,17,
70,13,78,1,19,
70,13,79,1,15,
70,13,92,4,0,
70,13,102,3,0,
70,13,104,4,0,
70,13,148,4,0,
70,13,156,4,0,
70,13,164,3,0,
70,13,168,4,0,
70,13,182,4,0,
70,13,188,4,0,
70,13,202,4,0,
70,13,207,3,0,
70,13,213,4,0,
70,13,216,4,0,
70,13,218,4,0,
70,13,230,1,33,
70,13,237,4,0,
70,13,241,4,0,
70,13,263,4,0,
70,13,290,4,0,
70,13,331,4,0,
70,14,14,4,0,
70,14,15,4,0,
70,14,20,3,0,
70,14,21,1,41,
70,14,22,1,1,1
70,14,35,1,1,3
70,14,35,1,11,
70,14,51,1,23,
70,14,74,1,1,2
70,14,74,1,7,
70,14,75,1,39,
70,14,76,4,0,
70,14,77,1,15,
70,14,78,1,17,
70,14,79,1,13,
70,14,92,4,0,
70,14,104,4,0,
70,14,115,4,0,
70,14,148,4,0,
70,14,156,4,0,
70,14,164,4,0,
70,14,168,4,0,
70,14,173,3,0,
70,14,182,4,0,
70,14,188,4,0,
70,14,202,3,0,
70,14,207,4,0,
70,14,213,4,0,
70,14,214,3,0,
70,14,216,4,0,
70,14,218,4,0,
70,14,230,1,29,
70,14,235,3,0,
70,14,237,4,0,
70,14,241,4,0,
70,14,263,4,0,
70,14,282,1,27,
70,14,282,3,0,
70,14,378,1,47,
70,14,380,1,35,
70,14,380,3,0,
70,14,388,3,0,
70,14,402,3,0,
70,14,412,4,0,
70,14,447,4,0,
70,14,450,3,0,
70,14,474,4,0,
70,14,496,4,0,
70,15,14,4,0,
70,15,15,4,0,
70,15,21,1,41,
70,15,22,1,1,1
70,15,35,1,1,3
70,15,35,1,11,
70,15,51,1,23,
70,15,74,1,1,2
70,15,74,1,7,
70,15,75,1,39,
70,15,76,4,0,
70,15,77,1,15,
70,15,78,1,17,
70,15,79,1,13,
70,15,92,4,0,
70,15,104,4,0,
70,15,115,4,0,
70,15,148,4,0,
70,15,156,4,0,
70,15,164,4,0,
70,15,168,4,0,
70,15,182,4,0,
70,15,188,4,0,
70,15,207,4,0,
70,15,213,4,0,
70,15,214,4,0,
70,15,216,4,0,
70,15,218,4,0,
70,15,230,1,29,
70,15,237,4,0,
70,15,241,4,0,
70,15,263,4,0,
70,15,267,4,0,
70,15,282,1,27,
70,15,378,1,47,
70,15,380,1,35,
70,15,412,4,0,
70,15,447,4,0,
70,15,474,4,0,
70,15,496,4,0,
70,15,590,4,0,
70,15,611,4,0,
70,16,14,4,0,
70,16,15,4,0,
70,16,20,3,0,
70,16,21,1,41,1
70,16,22,1,1,1
70,16,35,1,1,3
70,16,35,1,11,1
70,16,51,1,23,1
70,16,74,1,1,2
70,16,74,1,7,1
70,16,75,1,39,1
70,16,76,4,0,
70,16,77,1,15,1
70,16,78,1,17,1
70,16,79,1,13,1
70,16,92,4,0,
70,16,104,4,0,
70,16,115,4,0,
70,16,148,4,0,
70,16,156,4,0,
70,16,164,4,0,
70,16,168,4,0,
70,16,173,3,0,
70,16,182,4,0,
70,16,188,4,0,
70,16,202,3,0,
70,16,207,4,0,
70,16,213,4,0,
70,16,214,4,0,
70,16,216,4,0,
70,16,218,4,0,
70,16,230,1,29,1
70,16,235,3,0,
70,16,237,4,0,
70,16,241,4,0,
70,16,263,4,0,
70,16,267,4,0,
70,16,282,1,27,1
70,16,282,3,0,
70,16,290,4,0,
70,16,378,1,47,1
70,16,380,1,35,1
70,16,380,3,0,
70,16,388,3,0,
70,16,402,3,0,
70,16,412,4,0,
70,16,447,4,0,
70,16,474,4,0,
70,16,496,4,0,
70,16,590,4,0,
70,16,611,4,0,
70,17,14,4,0,
70,17,21,1,54,
70,17,22,1,1,1
70,17,35,1,1,3
70,17,35,1,11,
70,17,51,1,24,
70,17,74,1,1,2
70,17,74,1,7,
70,17,75,1,44,
70,17,76,4,0,
70,17,77,1,15,
70,17,78,1,17,
70,17,79,1,13,
70,17,92,4,0,
70,17,104,4,0,
70,17,115,4,0,
70,17,156,4,0,
70,17,164,4,0,
70,17,168,4,0,
70,17,182,4,0,
70,17,188,4,0,
70,17,207,4,0,
70,17,213,4,0,
70,17,214,4,0,
70,17,216,4,0,
70,17,218,4,0,
70,17,230,1,32,
70,17,237,4,0,
70,17,241,4,0,
70,17,263,4,0,
70,17,267,4,0,
70,17,282,1,29,
70,17,378,1,58,
70,17,380,1,39,
70,17,398,1,47,
70,17,398,4,0,
70,17,412,4,0,
70,17,447,4,0,
70,17,474,4,0,
70,17,496,4,0,
70,17,590,4,0,
70,17,611,4,0,
70,18,14,4,0,
70,18,21,1,54,
70,18,22,1,1,1
70,18,35,1,1,3
70,18,35,1,11,
70,18,51,1,24,
70,18,74,1,1,2
70,18,74,1,7,
70,18,75,1,44,
70,18,76,4,0,
70,18,77,1,15,
70,18,78,1,17,
70,18,79,1,13,
70,18,92,4,0,
70,18,104,4,0,
70,18,115,4,0,
70,18,156,4,0,
70,18,164,4,0,
70,18,168,4,0,
70,18,182,4,0,
70,18,188,4,0,
70,18,207,4,0,
70,18,213,4,0,
70,18,214,4,0,
70,18,216,4,0,
70,18,218,4,0,
70,18,230,1,32,
70,18,237,4,0,
70,18,241,4,0,
70,18,263,4,0,
70,18,267,4,0,
70,18,282,1,29,
70,18,378,1,58,
70,18,380,1,39,
70,18,398,1,47,
70,18,398,4,0,
70,18,412,4,0,
70,18,447,4,0,
70,18,474,4,0,
70,18,496,4,0,
70,18,590,4,0,
70,18,611,4,0,
71,1,14,4,0,
71,1,15,4,0,
71,1,34,4,0,
71,1,35,1,13,
71,1,36,4,0,
71,1,38,4,0,
71,1,51,1,1,3
71,1,63,4,0,
71,1,72,4,0,
71,1,75,1,1,4
71,1,76,4,0,
71,1,77,1,15,
71,1,78,1,1,2
71,1,79,1,1,1
71,1,79,1,18,
71,1,92,4,0,
71,1,99,4,0,
71,1,102,4,0,
71,1,104,4,0,
71,1,115,4,0,
71,1,117,4,0,
71,1,156,4,0,
71,1,164,4,0,
71,2,14,4,0,
71,2,15,4,0,
71,2,34,4,0,
71,2,35,1,13,
71,2,36,4,0,
71,2,38,4,0,
71,2,51,1,1,3
71,2,63,4,0,
71,2,72,4,0,
71,2,75,1,1,4
71,2,76,4,0,
71,2,77,1,15,
71,2,78,1,1,2
71,2,79,1,1,1
71,2,79,1,18,
71,2,92,4,0,
71,2,99,4,0,
71,2,102,4,0,
71,2,104,4,0,
71,2,115,4,0,
71,2,117,4,0,
71,2,156,4,0,
71,2,164,4,0,
71,3,15,4,0,
71,3,22,1,1,1
71,3,63,4,0,
71,3,75,1,1,4
71,3,76,4,0,
71,3,79,1,1,2
71,3,92,4,0,
71,3,104,4,0,
71,3,148,4,0,
71,3,156,4,0,
71,3,173,4,0,
71,3,174,4,0,
71,3,182,4,0,
71,3,188,4,0,
71,3,202,4,0,
71,3,203,4,0,
71,3,207,4,0,
71,3,213,4,0,
71,3,214,4,0,
71,3,216,4,0,
71,3,218,4,0,
71,3,230,1,1,3
71,3,230,4,0,
71,3,237,4,0,
71,3,241,4,0,
71,4,15,4,0,
71,4,22,1,1,1
71,4,63,4,0,
71,4,75,1,1,4
71,4,76,4,0,
71,4,79,1,1,2
71,4,92,4,0,
71,4,104,4,0,
71,4,148,4,0,
71,4,156,4,0,
71,4,173,4,0,
71,4,174,4,0,
71,4,182,4,0,
71,4,188,4,0,
71,4,202,4,0,
71,4,203,4,0,
71,4,207,4,0,
71,4,213,4,0,
71,4,214,4,0,
71,4,216,4,0,
71,4,218,4,0,
71,4,230,1,1,3
71,4,230,4,0,
71,4,237,4,0,
71,4,241,4,0,
71,5,15,4,0,
71,5,22,1,1,1
71,5,63,4,0,
71,5,75,1,1,4
71,5,76,4,0,
71,5,79,1,1,2
71,5,92,4,0,
71,5,104,4,0,
71,5,148,4,0,
71,5,156,4,0,
71,5,168,4,0,
71,5,182,4,0,
71,5,188,4,0,
71,5,202,4,0,
71,5,213,4,0,
71,5,216,4,0,
71,5,218,4,0,
71,5,230,1,1,3
71,5,237,4,0,
71,5,241,4,0,
71,5,263,4,0,
71,5,290,4,0,
71,5,331,4,0,
71,6,14,3,0,
71,6,15,4,0,
71,6,22,1,1,1
71,6,34,3,0,
71,6,38,3,0,
71,6,63,4,0,
71,6,75,1,1,4
71,6,76,4,0,
71,6,79,1,1,2
71,6,92,4,0,
71,6,102,3,0,
71,6,104,4,0,
71,6,148,4,0,
71,6,156,4,0,
71,6,164,3,0,
71,6,168,4,0,
71,6,173,3,0,
71,6,182,4,0,
71,6,188,4,0,
71,6,202,4,0,
71,6,203,3,0,
71,6,207,3,0,
71,6,213,4,0,
71,6,214,3,0,
71,6,216,4,0,
71,6,218,4,0,
71,6,230,1,1,3
71,6,237,4,0,
71,6,241,4,0,
71,6,263,4,0,
71,6,290,4,0,
71,6,331,4,0,
71,7,14,3,0,
71,7,15,4,0,
71,7,22,1,1,4
71,7,34,3,0,
71,7,38,3,0,
71,7,63,4,0,
71,7,75,1,1,7
71,7,76,4,0,
71,7,79,1,1,5
71,7,92,4,0,
71,7,102,3,0,
71,7,104,4,0,
71,7,148,4,0,
71,7,156,4,0,
71,7,164,3,0,
71,7,168,4,0,
71,7,182,4,0,
71,7,188,4,0,
71,7,202,4,0,
71,7,213,4,0,
71,7,216,4,0,
71,7,218,4,0,
71,7,230,1,1,6
71,7,237,4,0,
71,7,241,4,0,
71,7,254,1,1,1
71,7,255,1,1,2
71,7,256,1,1,3
71,7,263,4,0,
71,7,290,4,0,
71,7,331,4,0,
71,8,14,4,0,
71,8,15,4,0,
71,8,22,1,1,4
71,8,63,4,0,
71,8,75,1,1,7
71,8,76,4,0,
71,8,79,1,1,5
71,8,92,4,0,
71,8,104,4,0,
71,8,148,4,0,
71,8,156,4,0,
71,8,164,4,0,
71,8,168,4,0,
71,8,182,4,0,
71,8,188,4,0,
71,8,202,4,0,
71,8,203,4,0,
71,8,207,4,0,
71,8,213,4,0,
71,8,214,4,0,
71,8,216,4,0,
71,8,218,4,0,
71,8,230,1,1,6
71,8,237,4,0,
71,8,241,4,0,
71,8,254,1,1,1
71,8,255,1,1,3
71,8,256,1,1,2
71,8,263,4,0,
71,8,290,4,0,
71,8,331,4,0,
71,8,363,4,0,
71,8,412,4,0,
71,8,416,4,0,
71,8,437,1,47,
71,8,445,4,0,
71,8,447,4,0,
71,9,14,4,0,
71,9,15,4,0,
71,9,22,1,1,4
71,9,63,4,0,
71,9,75,1,1,7
71,9,76,4,0,
71,9,79,1,1,5
71,9,92,4,0,
71,9,104,4,0,
71,9,148,4,0,
71,9,156,4,0,
71,9,164,4,0,
71,9,168,4,0,
71,9,173,3,0,
71,9,182,4,0,
71,9,188,4,0,
71,9,202,4,0,
71,9,203,4,0,
71,9,207,4,0,
71,9,213,4,0,
71,9,214,4,0,
71,9,216,4,0,
71,9,218,4,0,
71,9,230,1,1,6
71,9,235,3,0,
71,9,237,4,0,
71,9,241,4,0,
71,9,254,1,1,1
71,9,255,1,1,3
71,9,256,1,1,2
71,9,263,4,0,
71,9,282,3,0,
71,9,290,4,0,
71,9,331,4,0,
71,9,348,1,47,2
71,9,363,4,0,
71,9,380,3,0,
71,9,389,3,0,
71,9,402,3,0,
71,9,412,4,0,
71,9,416,4,0,
71,9,437,1,47,1
71,9,445,4,0,
71,9,447,4,0,
71,10,14,4,0,
71,10,15,4,0,
71,10,22,1,1,4
71,10,63,4,0,
71,10,75,1,1,7
71,10,76,4,0,
71,10,79,1,1,5
71,10,92,4,0,
71,10,104,4,0,
71,10,148,4,0,
71,10,156,4,0,
71,10,164,4,0,
71,10,168,4,0,
71,10,173,3,0,
71,10,182,4,0,
71,10,188,4,0,
71,10,202,4,0,
71,10,203,4,0,
71,10,207,4,0,
71,10,213,4,0,
71,10,214,4,0,
71,10,216,4,0,
71,10,218,4,0,
71,10,230,1,1,6
71,10,235,3,0,
71,10,237,4,0,
71,10,241,4,0,
71,10,254,1,1,1
71,10,255,1,1,3
71,10,256,1,1,2
71,10,263,4,0,
71,10,282,3,0,
71,10,290,4,0,
71,10,331,4,0,
71,10,348,1,47,2
71,10,363,4,0,
71,10,380,3,0,
71,10,388,3,0,
71,10,389,3,0,
71,10,402,3,0,
71,10,412,4,0,
71,10,416,4,0,
71,10,437,1,47,1
71,10,445,4,0,
71,10,447,4,0,
71,11,14,4,0,
71,11,15,4,0,
71,11,22,1,1,4
71,11,63,4,0,
71,11,75,1,1,7
71,11,76,4,0,
71,11,79,1,1,5
71,11,92,4,0,
71,11,104,4,0,
71,11,115,4,0,
71,11,148,4,0,
71,11,156,4,0,
71,11,164,4,0,
71,11,168,4,0,
71,11,182,4,0,
71,11,188,4,0,
71,11,207,4,0,
71,11,213,4,0,
71,11,216,4,0,
71,11,218,4,0,
71,11,230,1,1,6
71,11,237,4,0,
71,11,241,4,0,
71,11,254,1,1,1
71,11,255,1,1,3
71,11,256,1,1,2
71,11,263,4,0,
71,11,348,1,47,2
71,11,412,4,0,
71,11,416,4,0,
71,11,437,1,47,1
71,11,447,4,0,
71,11,474,4,0,
71,11,496,4,0,
71,11,536,1,27,
71,12,15,4,0,
71,12,22,1,1,1
71,12,63,4,0,
71,12,75,1,1,4
71,12,76,4,0,
71,12,79,1,1,2
71,12,92,4,0,
71,12,104,4,0,
71,12,148,4,0,
71,12,156,4,0,
71,12,168,4,0,
71,12,182,4,0,
71,12,188,4,0,
71,12,202,4,0,
71,12,213,4,0,
71,12,216,4,0,
71,12,218,4,0,
71,12,230,1,1,3
71,12,237,4,0,
71,12,241,4,0,
71,12,263,4,0,
71,12,290,4,0,
71,12,331,4,0,
71,13,15,4,0,
71,13,22,1,1,1
71,13,34,3,0,
71,13,38,3,0,
71,13,63,4,0,
71,13,75,1,1,4
71,13,76,4,0,
71,13,79,1,1,2
71,13,92,4,0,
71,13,102,3,0,
71,13,104,4,0,
71,13,148,4,0,
71,13,156,4,0,
71,13,164,3,0,
71,13,168,4,0,
71,13,182,4,0,
71,13,188,4,0,
71,13,202,4,0,
71,13,207,3,0,
71,13,213,4,0,
71,13,216,4,0,
71,13,218,4,0,
71,13,230,1,1,3
71,13,237,4,0,
71,13,241,4,0,
71,13,263,4,0,
71,13,290,4,0,
71,13,331,4,0,
71,14,14,4,0,
71,14,15,4,0,
71,14,20,3,0,
71,14,22,1,1,4
71,14,63,4,0,
71,14,75,1,1,7
71,14,76,4,0,
71,14,79,1,1,5
71,14,92,4,0,
71,14,104,4,0,
71,14,115,4,0,
71,14,148,4,0,
71,14,156,4,0,
71,14,164,4,0,
71,14,168,4,0,
71,14,173,3,0,
71,14,182,4,0,
71,14,188,4,0,
71,14,202,3,0,
71,14,207,4,0,
71,14,213,4,0,
71,14,214,3,0,
71,14,216,4,0,
71,14,218,4,0,
71,14,230,1,1,6
71,14,235,3,0,
71,14,237,4,0,
71,14,241,4,0,
71,14,254,1,1,1
71,14,255,1,1,3
71,14,256,1,1,2
71,14,263,4,0,
71,14,282,3,0,
71,14,348,1,47,2
71,14,380,3,0,
71,14,388,3,0,
71,14,402,3,0,
71,14,412,4,0,
71,14,416,4,0,
71,14,437,1,47,1
71,14,447,4,0,
71,14,450,3,0,
71,14,474,4,0,
71,14,496,4,0,
71,14,536,1,27,
71,15,14,4,0,
71,15,15,4,0,
71,15,22,1,1,4
71,15,63,4,0,
71,15,75,1,1,7
71,15,76,4,0,
71,15,79,1,1,5
71,15,92,4,0,
71,15,104,4,0,
71,15,115,4,0,
71,15,148,4,0,
71,15,156,4,0,
71,15,164,4,0,
71,15,168,4,0,
71,15,182,4,0,
71,15,188,4,0,
71,15,207,4,0,
71,15,213,4,0,
71,15,214,4,0,
71,15,216,4,0,
71,15,218,4,0,
71,15,230,1,1,6
71,15,237,4,0,
71,15,241,4,0,
71,15,254,1,1,1
71,15,255,1,1,3
71,15,256,1,1,2
71,15,263,4,0,
71,15,267,4,0,
71,15,348,1,47,2
71,15,412,4,0,
71,15,416,4,0,
71,15,437,1,47,1
71,15,447,4,0,
71,15,474,4,0,
71,15,496,4,0,
71,15,536,1,27,
71,15,590,4,0,
71,15,611,4,0,
71,16,14,4,0,
71,16,15,4,0,
71,16,20,3,0,
71,16,22,1,1,4
71,16,63,4,0,
71,16,75,1,1,7
71,16,76,4,0,
71,16,79,1,1,5
71,16,92,4,0,
71,16,104,4,0,
71,16,115,4,0,
71,16,148,4,0,
71,16,156,4,0,
71,16,164,4,0,
71,16,168,4,0,
71,16,173,3,0,
71,16,182,4,0,
71,16,188,4,0,
71,16,202,3,0,
71,16,207,4,0,
71,16,213,4,0,
71,16,214,4,0,
71,16,216,4,0,
71,16,218,4,0,
71,16,230,1,1,6
71,16,235,3,0,
71,16,237,4,0,
71,16,241,4,0,
71,16,254,1,1,1
71,16,255,1,1,3
71,16,256,1,1,2
71,16,263,4,0,
71,16,267,4,0,
71,16,282,3,0,
71,16,290,4,0,
71,16,348,1,47,2
71,16,380,3,0,
71,16,388,3,0,
71,16,402,3,0,
71,16,412,4,0,
71,16,416,4,0,
71,16,437,1,47,1
71,16,447,4,0,
71,16,474,4,0,
71,16,496,4,0,
71,16,536,1,27,1
71,16,590,4,0,
71,16,611,4,0,
71,17,14,4,0,
71,17,22,1,1,5
71,17,63,4,0,
71,17,75,1,1,8
71,17,76,4,0,
71,17,79,1,1,6
71,17,92,4,0,
71,17,104,4,0,
71,17,115,4,0,
71,17,156,4,0,
71,17,164,4,0,
71,17,168,4,0,
71,17,182,4,0,
71,17,188,4,0,
71,17,207,4,0,
71,17,213,4,0,
71,17,214,4,0,
71,17,216,4,0,
71,17,218,4,0,
71,17,230,1,1,7
71,17,237,4,0,
71,17,241,4,0,
71,17,254,1,1,2
71,17,255,1,1,4
71,17,256,1,1,3
71,17,263,4,0,
71,17,267,4,0,
71,17,348,1,44,
71,17,398,4,0,
71,17,412,4,0,
71,17,416,4,0,
71,17,437,1,32,
71,17,447,4,0,
71,17,474,4,0,
71,17,496,4,0,
71,17,536,1,0,
71,17,536,1,1,1
71,17,590,4,0,
71,17,611,4,0,
71,18,14,4,0,
71,18,22,1,1,5
71,18,63,4,0,
71,18,75,1,1,8
71,18,76,4,0,
71,18,79,1,1,6
71,18,92,4,0,
71,18,104,4,0,
71,18,115,4,0,
71,18,156,4,0,
71,18,164,4,0,
71,18,168,4,0,
71,18,182,4,0,
71,18,188,4,0,
71,18,207,4,0,
71,18,213,4,0,
71,18,214,4,0,
71,18,216,4,0,
71,18,218,4,0,
71,18,230,1,1,7
71,18,237,4,0,
71,18,241,4,0,
71,18,254,1,1,2
71,18,255,1,1,4
71,18,256,1,1,3
71,18,263,4,0,
71,18,267,4,0,
71,18,348,1,44,
71,18,398,4,0,
71,18,412,4,0,
71,18,416,4,0,
71,18,437,1,32,
71,18,447,4,0,
71,18,474,4,0,
71,18,496,4,0,
71,18,536,1,0,
71,18,536,1,1,1
71,18,590,4,0,
71,18,611,4,0,
72,1,14,4,0,
72,1,15,4,0,
72,1,35,1,13,
72,1,36,4,0,
72,1,38,4,0,
72,1,40,1,18,
72,1,48,1,7,
72,1,51,1,1,
72,1,55,1,22,
72,1,55,4,0,
72,1,56,1,48,
72,1,57,4,0,
72,1,58,4,0,
72,1,59,4,0,
72,1,61,4,0,
72,1,72,4,0,
72,1,92,4,0,
72,1,99,4,0,
72,1,102,4,0,
72,1,103,1,40,
72,1,104,4,0,
72,1,112,1,33,
72,1,115,4,0,
72,1,117,4,0,
72,1,130,4,0,
72,1,132,1,27,
72,1,156,4,0,
72,1,164,4,0,
72,2,14,4,0,
72,2,15,4,0,
72,2,35,1,13,
72,2,36,4,0,
72,2,38,4,0,
72,2,40,1,18,
72,2,48,1,7,
72,2,51,1,1,
72,2,55,1,22,
72,2,55,4,0,
72,2,56,1,48,
72,2,57,4,0,
72,2,58,4,0,
72,2,59,4,0,
72,2,61,4,0,
72,2,72,4,0,
72,2,92,4,0,
72,2,99,4,0,
72,2,102,4,0,
72,2,103,1,40,
72,2,104,4,0,
72,2,112,1,33,
72,2,115,4,0,
72,2,117,4,0,
72,2,130,4,0,
72,2,132,1,27,
72,2,156,4,0,
72,2,164,4,0,
72,3,15,4,0,
72,3,35,1,30,
72,3,40,1,1,
72,3,48,1,6,
72,3,51,1,19,
72,3,56,1,49,
72,3,57,4,0,
72,3,59,4,0,
72,3,61,1,25,
72,3,62,2,0,
72,3,92,4,0,
72,3,103,1,43,
72,3,104,4,0,
72,3,112,1,36,
72,3,114,2,0,
72,3,132,1,12,
72,3,156,4,0,
72,3,173,4,0,
72,3,174,4,0,
72,3,182,4,0,
72,3,188,4,0,
72,3,196,4,0,
72,3,202,4,0,
72,3,203,4,0,
72,3,207,4,0,
72,3,213,4,0,
72,3,214,4,0,
72,3,216,4,0,
72,3,218,4,0,
72,3,219,2,0,
72,3,229,2,0,
72,3,237,4,0,
72,3,240,4,0,
72,3,243,2,0,
72,3,250,4,0,
72,4,15,4,0,
72,4,35,1,30,
72,4,40,1,1,
72,4,48,1,6,
72,4,51,1,19,
72,4,56,1,49,
72,4,57,4,0,
72,4,58,3,0,
72,4,59,4,0,
72,4,61,1,25,
72,4,62,2,0,
72,4,92,4,0,
72,4,103,1,43,
72,4,104,4,0,
72,4,112,1,36,
72,4,114,2,0,
72,4,132,1,12,
72,4,156,4,0,
72,4,173,4,0,
72,4,174,4,0,
72,4,182,4,0,
72,4,188,4,0,
72,4,196,4,0,
72,4,202,4,0,
72,4,203,4,0,
72,4,207,4,0,
72,4,213,4,0,
72,4,214,4,0,
72,4,216,4,0,
72,4,218,4,0,
72,4,219,2,0,
72,4,229,2,0,
72,4,237,4,0,
72,4,240,4,0,
72,4,243,2,0,
72,4,250,4,0,
72,5,15,4,0,
72,5,35,1,30,
72,5,40,1,1,
72,5,48,1,6,
72,5,51,1,19,
72,5,56,1,49,
72,5,57,4,0,
72,5,58,4,0,
72,5,59,4,0,
72,5,61,1,25,
72,5,62,2,0,
72,5,92,4,0,
72,5,103,1,43,
72,5,104,4,0,
72,5,109,2,0,
72,5,112,1,36,
72,5,114,2,0,
72,5,127,4,0,
72,5,132,1,12,
72,5,156,4,0,
72,5,168,4,0,
72,5,182,4,0,
72,5,188,4,0,
72,5,202,4,0,
72,5,213,4,0,
72,5,216,4,0,
72,5,218,4,0,
72,5,219,2,0,
72,5,229,2,0,
72,5,237,4,0,
72,5,240,4,0,
72,5,243,2,0,
72,5,258,4,0,
72,5,263,4,0,
72,5,290,4,0,
72,5,291,4,0,
72,5,352,4,0,
72,6,14,3,0,
72,6,15,4,0,
72,6,35,1,30,
72,6,38,3,0,
72,6,40,1,1,
72,6,48,1,6,
72,6,51,1,19,
72,6,56,1,49,
72,6,57,4,0,
72,6,58,4,0,
72,6,59,4,0,
72,6,61,1,25,
72,6,62,2,0,
72,6,92,4,0,
72,6,102,3,0,
72,6,103,1,43,
72,6,104,4,0,
72,6,109,2,0,
72,6,112,1,36,
72,6,114,2,0,
72,6,127,4,0,
72,6,132,1,12,
72,6,156,4,0,
72,6,164,3,0,
72,6,168,4,0,
72,6,173,3,0,
72,6,182,4,0,
72,6,188,4,0,
72,6,196,3,0,
72,6,202,4,0,
72,6,203,3,0,
72,6,207,3,0,
72,6,213,4,0,
72,6,214,3,0,
72,6,216,4,0,
72,6,218,4,0,
72,6,219,2,0,
72,6,229,2,0,
72,6,237,4,0,
72,6,240,4,0,
72,6,243,2,0,
72,6,258,4,0,
72,6,263,4,0,
72,6,290,4,0,
72,6,291,4,0,
72,6,352,4,0,
72,7,14,3,0,
72,7,15,4,0,
72,7,35,1,30,
72,7,38,3,0,
72,7,40,1,1,
72,7,48,1,6,
72,7,51,1,19,
72,7,56,1,49,
72,7,57,4,0,
72,7,58,4,0,
72,7,59,4,0,
72,7,61,1,25,
72,7,62,2,0,
72,7,92,4,0,
72,7,102,3,0,
72,7,103,1,43,
72,7,104,4,0,
72,7,109,2,0,
72,7,112,1,36,
72,7,114,2,0,
72,7,127,4,0,
72,7,132,1,12,
72,7,156,4,0,
72,7,164,3,0,
72,7,168,4,0,
72,7,182,4,0,
72,7,188,4,0,
72,7,202,4,0,
72,7,213,4,0,
72,7,216,4,0,
72,7,218,4,0,
72,7,219,2,0,
72,7,229,2,0,
72,7,237,4,0,
72,7,240,4,0,
72,7,243,2,0,
72,7,258,4,0,
72,7,263,4,0,
72,7,290,4,0,
72,7,291,4,0,
72,7,352,4,0,
72,8,14,4,0,
72,8,15,4,0,
72,8,35,1,22,
72,8,40,1,1,
72,8,48,1,5,
72,8,51,1,12,
72,8,56,1,40,
72,8,57,4,0,
72,8,58,4,0,
72,8,59,4,0,
72,8,61,1,19,
72,8,62,2,0,
72,8,92,4,0,
72,8,103,1,36,
72,8,104,4,0,
72,8,109,2,0,
72,8,112,1,26,
72,8,114,2,0,
72,8,127,4,0,
72,8,132,1,8,
72,8,156,4,0,
72,8,164,4,0,
72,8,168,4,0,
72,8,182,4,0,
72,8,188,4,0,
72,8,202,4,0,
72,8,203,4,0,
72,8,207,4,0,
72,8,213,4,0,
72,8,214,4,0,
72,8,216,4,0,
72,8,218,4,0,
72,8,219,2,0,
72,8,229,2,0,
72,8,237,4,0,
72,8,240,4,0,
72,8,243,2,0,
72,8,258,4,0,
72,8,263,4,0,
72,8,282,2,0,
72,8,290,4,0,
72,8,352,1,29,
72,8,352,4,0,
72,8,362,4,0,
72,8,363,4,0,
72,8,367,2,0,
72,8,371,4,0,
72,8,378,1,43,
72,8,390,1,15,
72,8,398,1,33,
72,8,398,4,0,
72,8,445,4,0,
72,9,14,4,0,
72,9,15,4,0,
72,9,35,1,22,
72,9,40,1,1,
72,9,48,1,5,
72,9,51,1,12,
72,9,56,1,40,
72,9,57,4,0,
72,9,58,4,0,
72,9,59,4,0,
72,9,61,1,19,
72,9,62,2,0,
72,9,92,4,0,
72,9,103,1,36,
72,9,104,4,0,
72,9,109,2,0,
72,9,112,1,26,
72,9,114,2,0,
72,9,127,4,0,
72,9,132,1,8,
72,9,156,4,0,
72,9,164,4,0,
72,9,168,4,0,
72,9,173,3,0,
72,9,182,4,0,
72,9,188,4,0,
72,9,196,3,0,
72,9,202,4,0,
72,9,203,4,0,
72,9,207,4,0,
72,9,213,4,0,
72,9,214,4,0,
72,9,216,4,0,
72,9,218,4,0,
72,9,219,2,0,
72,9,229,2,0,
72,9,237,4,0,
72,9,240,4,0,
72,9,243,2,0,
72,9,258,4,0,
72,9,263,4,0,
72,9,282,2,0,
72,9,282,3,0,
72,9,290,4,0,
72,9,291,3,0,
72,9,352,1,29,
72,9,352,4,0,
72,9,362,4,0,
72,9,363,4,0,
72,9,367,2,0,
72,9,371,4,0,
72,9,378,1,43,
72,9,390,1,15,
72,9,398,1,33,
72,9,398,4,0,
72,9,445,4,0,
72,10,14,4,0,
72,10,15,4,0,
72,10,35,1,22,
72,10,40,1,1,
72,10,48,1,5,
72,10,51,1,12,
72,10,56,1,40,
72,10,57,4,0,
72,10,58,4,0,
72,10,59,4,0,
72,10,61,1,19,
72,10,62,2,0,
72,10,92,4,0,
72,10,103,1,36,
72,10,104,4,0,
72,10,109,2,0,
72,10,112,1,26,
72,10,114,2,0,
72,10,127,4,0,
72,10,132,1,8,
72,10,156,4,0,
72,10,164,4,0,
72,10,168,4,0,
72,10,173,3,0,
72,10,182,4,0,
72,10,188,4,0,
72,10,196,3,0,
72,10,202,4,0,
72,10,203,4,0,
72,10,207,4,0,
72,10,213,4,0,
72,10,214,4,0,
72,10,216,4,0,
72,10,218,4,0,
72,10,219,2,0,
72,10,229,2,0,
72,10,237,4,0,
72,10,240,4,0,
72,10,243,2,0,
72,10,250,4,0,
72,10,258,4,0,
72,10,263,4,0,
72,10,277,3,0,
72,10,282,2,0,
72,10,282,3,0,
72,10,290,4,0,
72,10,291,3,0,
72,10,330,2,0,
72,10,352,1,29,
72,10,352,4,0,
72,10,362,4,0,
72,10,363,4,0,
72,10,367,2,0,
72,10,371,4,0,
72,10,378,1,43,
72,10,390,1,15,
72,10,398,1,33,
72,10,398,4,0,
72,10,445,4,0,
72,11,14,4,0,
72,11,15,4,0,
72,11,35,1,22,
72,11,40,1,1,
72,11,48,1,5,
72,11,51,1,12,
72,11,56,1,47,
72,11,57,4,0,
72,11,58,4,0,
72,11,59,4,0,
72,11,61,1,19,
72,11,62,2,0,
72,11,92,4,0,
72,11,103,1,40,
72,11,104,4,0,
72,11,109,2,0,
72,11,112,1,29,
72,11,114,2,0,
72,11,127,4,0,
72,11,132,1,8,
72,11,145,2,0,
72,11,156,4,0,
72,11,164,4,0,
72,11,168,4,0,
72,11,182,4,0,
72,11,188,4,0,
72,11,207,4,0,
72,11,213,4,0,
72,11,216,4,0,
72,11,218,4,0,
72,11,219,4,0,
72,11,229,2,0,
72,11,237,4,0,
72,11,240,4,0,
72,11,243,2,0,
72,11,258,4,0,
72,11,263,4,0,
72,11,282,2,0,
72,11,291,4,0,
72,11,321,2,0,
72,11,330,2,0,
72,11,352,1,33,
72,11,367,2,0,
72,11,371,4,0,
72,11,378,1,54,
72,11,390,1,15,
72,11,392,2,0,
72,11,398,1,36,
72,11,398,4,0,
72,11,474,4,0,
72,11,482,1,50,
72,11,482,4,0,
72,11,491,1,26,
72,11,496,4,0,
72,11,503,4,0,
72,11,506,1,43,
72,12,15,4,0,
72,12,35,1,30,
72,12,40,1,1,
72,12,48,1,6,
72,12,51,1,19,
72,12,56,1,49,
72,12,57,4,0,
72,12,58,4,0,
72,12,59,4,0,
72,12,61,1,25,
72,12,92,4,0,
72,12,103,1,43,
72,12,104,4,0,
72,12,112,1,36,
72,12,127,4,0,
72,12,132,1,12,
72,12,156,4,0,
72,12,168,4,0,
72,12,182,4,0,
72,12,188,4,0,
72,12,202,4,0,
72,12,213,4,0,
72,12,216,4,0,
72,12,218,4,0,
72,12,237,4,0,
72,12,240,4,0,
72,12,258,4,0,
72,12,263,4,0,
72,12,290,4,0,
72,12,291,4,0,
72,12,352,4,0,
72,13,15,4,0,
72,13,35,1,30,
72,13,38,3,0,
72,13,40,1,1,
72,13,48,1,6,
72,13,51,1,19,
72,13,56,1,49,
72,13,57,4,0,
72,13,58,4,0,
72,13,59,4,0,
72,13,61,1,25,
72,13,92,4,0,
72,13,102,3,0,
72,13,103,1,43,
72,13,104,4,0,
72,13,112,1,36,
72,13,127,4,0,
72,13,132,1,12,
72,13,156,4,0,
72,13,164,3,0,
72,13,168,4,0,
72,13,182,4,0,
72,13,188,4,0,
72,13,196,3,0,
72,13,202,4,0,
72,13,207,3,0,
72,13,213,4,0,
72,13,216,4,0,
72,13,218,4,0,
72,13,237,4,0,
72,13,240,4,0,
72,13,258,4,0,
72,13,263,4,0,
72,13,290,4,0,
72,13,291,4,0,
72,13,352,4,0,
72,14,14,4,0,
72,14,15,4,0,
72,14,20,3,0,
72,14,35,1,22,
72,14,40,1,1,
72,14,48,1,5,
72,14,51,1,12,
72,14,56,1,47,
72,14,57,4,0,
72,14,58,4,0,
72,14,59,4,0,
72,14,61,1,19,
72,14,62,2,0,
72,14,92,4,0,
72,14,103,1,40,
72,14,104,4,0,
72,14,109,2,0,
72,14,112,1,29,
72,14,114,2,0,
72,14,127,4,0,
72,14,132,1,8,
72,14,145,2,0,
72,14,156,4,0,
72,14,164,4,0,
72,14,168,4,0,
72,14,173,3,0,
72,14,182,4,0,
72,14,188,4,0,
72,14,196,3,0,
72,14,202,3,0,
72,14,207,4,0,
72,14,213,4,0,
72,14,214,3,0,
72,14,216,4,0,
72,14,218,4,0,
72,14,219,4,0,
72,14,229,2,0,
72,14,237,4,0,
72,14,240,4,0,
72,14,243,2,0,
72,14,258,4,0,
72,14,263,4,0,
72,14,277,3,0,
72,14,282,2,0,
72,14,282,3,0,
72,14,291,4,0,
72,14,321,2,0,
72,14,330,2,0,
72,14,352,1,33,
72,14,367,2,0,
72,14,371,4,0,
72,14,378,1,54,
72,14,390,1,15,
72,14,392,2,0,
72,14,398,1,36,
72,14,398,4,0,
72,14,474,4,0,
72,14,482,1,50,
72,14,482,4,0,
72,14,491,1,26,
72,14,496,4,0,
72,14,503,4,0,
72,14,506,1,43,
72,15,14,4,0,
72,15,15,4,0,
72,15,35,1,22,
72,15,40,1,1,
72,15,48,1,5,
72,15,51,1,12,
72,15,56,1,47,
72,15,57,4,0,
72,15,58,4,0,
72,15,59,4,0,
72,15,61,1,19,
72,15,62,2,0,
72,15,92,4,0,
72,15,103,1,40,
72,15,104,4,0,
72,15,109,2,0,
72,15,112,1,29,
72,15,114,2,0,
72,15,127,4,0,
72,15,132,1,8,
72,15,145,2,0,
72,15,156,4,0,
72,15,164,4,0,
72,15,168,4,0,
72,15,182,4,0,
72,15,188,4,0,
72,15,207,4,0,
72,15,213,4,0,
72,15,214,4,0,
72,15,216,4,0,
72,15,218,4,0,
72,15,219,4,0,
72,15,229,2,0,
72,15,237,4,0,
72,15,240,4,0,
72,15,243,2,0,
72,15,258,4,0,
72,15,263,4,0,
72,15,282,2,0,
72,15,321,2,0,
72,15,330,2,0,
72,15,352,1,33,
72,15,367,2,0,
72,15,371,4,0,
72,15,378,1,54,
72,15,390,1,15,
72,15,392,2,0,
72,15,398,1,36,
72,15,398,4,0,
72,15,474,4,0,
72,15,482,1,50,
72,15,482,4,0,
72,15,491,1,26,
72,15,496,4,0,
72,15,503,4,0,
72,15,506,1,43,
72,15,590,4,0,
72,15,605,4,0,
72,15,611,4,0,
72,16,14,4,0,
72,16,15,4,0,
72,16,20,3,0,
72,16,35,1,19,1
72,16,40,1,1,1
72,16,48,1,4,1
72,16,51,1,10,1
72,16,56,1,46,1
72,16,57,4,0,
72,16,58,4,0,
72,16,59,4,0,
72,16,61,1,25,1
72,16,62,2,0,
72,16,92,4,0,
72,16,103,1,37,1
72,16,104,4,0,
72,16,109,2,0,
72,16,112,1,28,1
72,16,114,2,0,
72,16,127,4,0,
72,16,132,1,7,1
72,16,145,2,0,
72,16,156,4,0,
72,16,164,4,0,
72,16,168,4,0,
72,16,173,3,0,
72,16,182,4,0,
72,16,188,4,0,
72,16,196,3,0,
72,16,202,3,0,
72,16,207,4,0,
72,16,213,4,0,
72,16,214,4,0,
72,16,216,4,0,
72,16,218,4,0,
72,16,219,4,0,
72,16,229,2,0,
72,16,237,4,0,
72,16,240,4,0,
72,16,243,2,0,
72,16,258,4,0,
72,16,263,4,0,
72,16,277,3,0,
72,16,282,2,0,
72,16,282,3,0,
72,16,290,4,0,
72,16,291,4,0,
72,16,321,2,0,
72,16,330,2,0,
72,16,352,1,16,1
72,16,352,3,0,
72,16,362,1,34,1
72,16,367,2,0,
72,16,371,4,0,
72,16,378,1,49,1
72,16,390,1,13,1
72,16,392,2,0,
72,16,398,1,31,1
72,16,398,4,0,
72,16,474,4,0,
72,16,482,1,43,1
72,16,482,4,0,
72,16,491,1,22,1
72,16,496,4,0,
72,16,503,4,0,
72,16,506,1,40,1
72,16,590,4,0,
72,16,605,4,0,
72,16,611,4,0,
72,17,14,4,0,
72,17,35,1,19,
72,17,40,1,1,
72,17,48,1,4,
72,17,51,1,10,
72,17,56,1,46,
72,17,57,4,0,
72,17,58,4,0,
72,17,59,4,0,
72,17,61,1,25,
72,17,62,2,0,
72,17,92,4,0,
72,17,103,1,37,
72,17,104,4,0,
72,17,109,2,0,
72,17,112,1,28,
72,17,114,2,0,
72,17,127,4,0,
72,17,132,1,7,
72,17,145,2,0,
72,17,156,4,0,
72,17,164,4,0,
72,17,168,4,0,
72,17,182,4,0,
72,17,188,4,0,
72,17,207,4,0,
72,17,213,4,0,
72,17,214,4,0,
72,17,216,4,0,
72,17,218,4,0,
72,17,219,4,0,
72,17,229,2,0,
72,17,237,4,0,
72,17,240,4,0,
72,17,243,2,0,
72,17,258,4,0,
72,17,263,4,0,
72,17,282,2,0,
72,17,321,2,0,
72,17,330,2,0,
72,17,352,1,16,
72,17,362,1,34,
72,17,367,2,0,
72,17,371,4,0,
72,17,378,1,49,
72,17,390,1,13,
72,17,392,2,0,
72,17,398,1,31,
72,17,398,4,0,
72,17,474,4,0,
72,17,482,1,43,
72,17,482,4,0,
72,17,491,1,22,
72,17,496,4,0,
72,17,503,4,0,
72,17,506,1,40,
72,17,590,4,0,
72,17,605,4,0,
72,17,611,4,0,
72,18,14,4,0,
72,18,35,1,19,
72,18,40,1,1,
72,18,48,1,4,
72,18,51,1,10,
72,18,56,1,46,
72,18,57,4,0,
72,18,58,4,0,
72,18,59,4,0,
72,18,61,1,25,
72,18,62,2,0,
72,18,92,4,0,
72,18,103,1,37,
72,18,104,4,0,
72,18,109,2,0,
72,18,112,1,28,
72,18,114,2,0,
72,18,127,4,0,
72,18,132,1,7,
72,18,145,2,0,
72,18,156,4,0,
72,18,164,4,0,
72,18,168,4,0,
72,18,182,4,0,
72,18,188,4,0,
72,18,207,4,0,
72,18,213,4,0,
72,18,214,4,0,
72,18,216,4,0,
72,18,218,4,0,
72,18,219,4,0,
72,18,229,2,0,
72,18,237,4,0,
72,18,240,4,0,
72,18,243,2,0,
72,18,258,4,0,
72,18,263,4,0,
72,18,282,2,0,
72,18,321,2,0,
72,18,330,2,0,
72,18,352,1,16,
72,18,362,1,34,
72,18,367,2,0,
72,18,371,4,0,
72,18,378,1,49,
72,18,390,1,13,
72,18,392,2,0,
72,18,398,1,31,
72,18,398,4,0,
72,18,474,4,0,
72,18,482,1,43,
72,18,482,4,0,
72,18,491,1,22,
72,18,496,4,0,
72,18,503,4,0,
72,18,506,1,40,
72,18,590,4,0,
72,18,605,4,0,
72,18,611,4,0,
73,1,14,4,0,
73,1,15,4,0,
73,1,35,1,1,3
73,1,35,1,13,
73,1,36,4,0,
73,1,38,4,0,
73,1,40,1,18,
73,1,48,1,1,2
73,1,48,1,7,
73,1,51,1,1,1
73,1,55,1,22,
73,1,55,4,0,
73,1,56,1,50,
73,1,57,4,0,
73,1,58,4,0,
73,1,59,4,0,
73,1,61,4,0,
73,1,63,4,0,
73,1,72,4,0,
73,1,92,4,0,
73,1,99,4,0,
73,1,102,4,0,
73,1,103,1,43,
73,1,104,4,0,
73,1,112,1,35,
73,1,115,4,0,
73,1,117,4,0,
73,1,130,4,0,
73,1,132,1,27,
73,1,156,4,0,
73,1,164,4,0,
73,2,14,4,0,
73,2,15,4,0,
73,2,35,1,1,3
73,2,35,1,13,
73,2,36,4,0,
73,2,38,4,0,
73,2,40,1,18,
73,2,48,1,1,2
73,2,48,1,7,
73,2,51,1,1,1
73,2,55,1,22,
73,2,55,4,0,
73,2,56,1,50,
73,2,57,4,0,
73,2,58,4,0,
73,2,59,4,0,
73,2,61,4,0,
73,2,63,4,0,
73,2,72,4,0,
73,2,92,4,0,
73,2,99,4,0,
73,2,102,4,0,
73,2,103,1,43,
73,2,104,4,0,
73,2,112,1,35,
73,2,115,4,0,
73,2,117,4,0,
73,2,130,4,0,
73,2,132,1,27,
73,2,156,4,0,
73,2,164,4,0,
73,3,15,4,0,
73,3,35,1,30,
73,3,40,1,1,1
73,3,48,1,1,2
73,3,48,1,6,
73,3,51,1,19,
73,3,56,1,55,
73,3,57,4,0,
73,3,59,4,0,
73,3,61,1,25,
73,3,63,4,0,
73,3,92,4,0,
73,3,103,1,47,
73,3,104,4,0,
73,3,112,1,38,
73,3,132,1,1,3
73,3,132,1,12,
73,3,156,4,0,
73,3,173,4,0,
73,3,174,4,0,
73,3,182,4,0,
73,3,188,4,0,
73,3,196,4,0,
73,3,202,4,0,
73,3,203,4,0,
73,3,207,4,0,
73,3,213,4,0,
73,3,214,4,0,
73,3,216,4,0,
73,3,218,4,0,
73,3,237,4,0,
73,3,240,4,0,
73,3,250,4,0,
73,4,15,4,0,
73,4,35,1,30,
73,4,40,1,1,1
73,4,48,1,1,2
73,4,48,1,6,
73,4,51,1,19,
73,4,56,1,55,
73,4,57,4,0,
73,4,58,3,0,
73,4,59,4,0,
73,4,61,1,25,
73,4,63,4,0,
73,4,92,4,0,
73,4,103,1,47,
73,4,104,4,0,
73,4,112,1,38,
73,4,132,1,1,3
73,4,132,1,12,
73,4,156,4,0,
73,4,173,4,0,
73,4,174,4,0,
73,4,182,4,0,
73,4,188,4,0,
73,4,196,4,0,
73,4,202,4,0,
73,4,203,4,0,
73,4,207,4,0,
73,4,213,4,0,
73,4,214,4,0,
73,4,216,4,0,
73,4,218,4,0,
73,4,237,4,0,
73,4,240,4,0,
73,4,250,4,0,
73,5,15,4,0,
73,5,35,1,30,
73,5,40,1,1,1
73,5,48,1,1,2
73,5,48,1,6,
73,5,51,1,19,
73,5,56,1,55,
73,5,57,4,0,
73,5,58,4,0,
73,5,59,4,0,
73,5,61,1,25,
73,5,63,4,0,
73,5,92,4,0,
73,5,103,1,47,
73,5,104,4,0,
73,5,112,1,38,
73,5,127,4,0,
73,5,132,1,1,3
73,5,132,1,12,
73,5,156,4,0,
73,5,168,4,0,
73,5,182,4,0,
73,5,188,4,0,
73,5,202,4,0,
73,5,213,4,0,
73,5,216,4,0,
73,5,218,4,0,
73,5,237,4,0,
73,5,240,4,0,
73,5,258,4,0,
73,5,263,4,0,
73,5,290,4,0,
73,5,291,4,0,
73,5,352,4,0,
73,6,14,3,0,
73,6,15,4,0,
73,6,35,1,30,
73,6,38,3,0,
73,6,40,1,1,1
73,6,48,1,1,2
73,6,48,1,6,
73,6,51,1,19,
73,6,56,1,55,
73,6,57,4,0,
73,6,58,4,0,
73,6,59,4,0,
73,6,61,1,25,
73,6,63,4,0,
73,6,92,4,0,
73,6,102,3,0,
73,6,103,1,47,
73,6,104,4,0,
73,6,112,1,38,
73,6,127,4,0,
73,6,132,1,1,3
73,6,132,1,12,
73,6,156,4,0,
73,6,164,3,0,
73,6,168,4,0,
73,6,173,3,0,
73,6,182,4,0,
73,6,188,4,0,
73,6,196,3,0,
73,6,202,4,0,
73,6,203,3,0,
73,6,207,3,0,
73,6,213,4,0,
73,6,214,3,0,
73,6,216,4,0,
73,6,218,4,0,
73,6,237,4,0,
73,6,240,4,0,
73,6,258,4,0,
73,6,263,4,0,
73,6,290,4,0,
73,6,291,4,0,
73,6,352,4,0,
73,7,14,3,0,
73,7,15,4,0,
73,7,35,1,30,
73,7,38,3,0,
73,7,40,1,1,1
73,7,48,1,1,2
73,7,48,1,6,
73,7,51,1,19,
73,7,56,1,55,
73,7,57,4,0,
73,7,58,4,0,
73,7,59,4,0,
73,7,61,1,25,
73,7,63,4,0,
73,7,92,4,0,
73,7,102,3,0,
73,7,103,1,47,
73,7,104,4,0,
73,7,112,1,38,
73,7,127,4,0,
73,7,132,1,1,3
73,7,132,1,12,
73,7,156,4,0,
73,7,164,3,0,
73,7,168,4,0,
73,7,182,4,0,
73,7,188,4,0,
73,7,202,4,0,
73,7,213,4,0,
73,7,216,4,0,
73,7,218,4,0,
73,7,237,4,0,
73,7,240,4,0,
73,7,258,4,0,
73,7,263,4,0,
73,7,290,4,0,
73,7,291,4,0,
73,7,352,4,0,
73,8,14,4,0,
73,8,15,4,0,
73,8,35,1,22,
73,8,40,1,1,1
73,8,48,1,1,2
73,8,48,1,5,
73,8,51,1,12,
73,8,56,1,49,
73,8,57,4,0,
73,8,58,4,0,
73,8,59,4,0,
73,8,61,1,19,
73,8,63,4,0,
73,8,92,4,0,
73,8,103,1,42,
73,8,104,4,0,
73,8,112,1,26,
73,8,127,4,0,
73,8,132,1,1,3
73,8,132,1,8,
73,8,156,4,0,
73,8,164,4,0,
73,8,168,4,0,
73,8,182,4,0,
73,8,188,4,0,
73,8,202,4,0,
73,8,203,4,0,
73,8,207,4,0,
73,8,213,4,0,
73,8,214,4,0,
73,8,216,4,0,
73,8,218,4,0,
73,8,237,4,0,
73,8,240,4,0,
73,8,258,4,0,
73,8,263,4,0,
73,8,290,4,0,
73,8,352,1,29,
73,8,352,4,0,
73,8,362,4,0,
73,8,363,4,0,
73,8,371,4,0,
73,8,378,1,55,
73,8,390,1,15,
73,8,398,1,36,
73,8,398,4,0,
73,8,416,4,0,
73,8,445,4,0,
73,9,14,4,0,
73,9,15,4,0,
73,9,35,1,22,
73,9,40,1,1,1
73,9,48,1,1,2
73,9,48,1,5,
73,9,51,1,12,
73,9,56,1,49,
73,9,57,4,0,
73,9,58,4,0,
73,9,59,4,0,
73,9,61,1,19,
73,9,63,4,0,
73,9,92,4,0,
73,9,103,1,42,
73,9,104,4,0,
73,9,112,1,26,
73,9,127,4,0,
73,9,132,1,1,3
73,9,132,1,8,
73,9,156,4,0,
73,9,164,4,0,
73,9,168,4,0,
73,9,173,3,0,
73,9,182,4,0,
73,9,188,4,0,
73,9,196,3,0,
73,9,202,4,0,
73,9,203,4,0,
73,9,207,4,0,
73,9,213,4,0,
73,9,214,4,0,
73,9,216,4,0,
73,9,218,4,0,
73,9,237,4,0,
73,9,240,4,0,
73,9,258,4,0,
73,9,263,4,0,
73,9,282,3,0,
73,9,290,4,0,
73,9,291,3,0,
73,9,352,1,29,
73,9,352,4,0,
73,9,362,4,0,
73,9,363,4,0,
73,9,371,4,0,
73,9,378,1,55,
73,9,390,1,15,
73,9,398,1,36,
73,9,398,4,0,
73,9,416,4,0,
73,9,445,4,0,
73,10,14,4,0,
73,10,15,4,0,
73,10,35,1,22,
73,10,40,1,1,1
73,10,48,1,1,2
73,10,48,1,5,
73,10,51,1,12,
73,10,56,1,49,
73,10,57,4,0,
73,10,58,4,0,
73,10,59,4,0,
73,10,61,1,19,
73,10,63,4,0,
73,10,92,4,0,
73,10,103,1,42,
73,10,104,4,0,
73,10,112,1,26,
73,10,127,4,0,
73,10,132,1,1,3
73,10,132,1,8,
73,10,156,4,0,
73,10,164,4,0,
73,10,168,4,0,
73,10,173,3,0,
73,10,182,4,0,
73,10,188,4,0,
73,10,196,3,0,
73,10,202,4,0,
73,10,203,4,0,
73,10,207,4,0,
73,10,213,4,0,
73,10,214,4,0,
73,10,216,4,0,
73,10,218,4,0,
73,10,237,4,0,
73,10,240,4,0,
73,10,250,4,0,
73,10,258,4,0,
73,10,263,4,0,
73,10,277,3,0,
73,10,282,3,0,
73,10,290,4,0,
73,10,291,3,0,
73,10,352,1,29,
73,10,352,4,0,
73,10,362,4,0,
73,10,363,4,0,
73,10,371,4,0,
73,10,378,1,55,
73,10,390,1,15,
73,10,398,1,36,
73,10,398,4,0,
73,10,416,4,0,
73,10,445,4,0,
73,11,14,4,0,
73,11,15,4,0,
73,11,35,1,22,
73,11,40,1,1,1
73,11,48,1,1,2
73,11,48,1,5,
73,11,51,1,12,
73,11,56,1,52,
73,11,57,4,0,
73,11,58,4,0,
73,11,59,4,0,
73,11,61,1,19,
73,11,63,4,0,
73,11,92,4,0,
73,11,103,1,43,
73,11,104,4,0,
73,11,112,1,29,
73,11,127,4,0,
73,11,132,1,1,3
73,11,132,1,8,
73,11,156,4,0,
73,11,164,4,0,
73,11,168,4,0,
73,11,182,4,0,
73,11,188,4,0,
73,11,207,4,0,
73,11,213,4,0,
73,11,216,4,0,
73,11,218,4,0,
73,11,219,4,0,
73,11,237,4,0,
73,11,240,4,0,
73,11,258,4,0,
73,11,263,4,0,
73,11,291,4,0,
73,11,352,1,34,
73,11,371,4,0,
73,11,378,1,61,
73,11,390,1,15,
73,11,398,1,38,
73,11,398,4,0,
73,11,416,4,0,
73,11,474,4,0,
73,11,482,1,56,
73,11,482,4,0,
73,11,491,1,26,
73,11,496,4,0,
73,11,503,4,0,
73,11,506,1,47,
73,12,15,4,0,
73,12,35,1,30,
73,12,40,1,1,1
73,12,48,1,1,2
73,12,48,1,6,
73,12,51,1,19,
73,12,56,1,55,
73,12,57,4,0,
73,12,58,4,0,
73,12,59,4,0,
73,12,61,1,25,
73,12,63,4,0,
73,12,92,4,0,
73,12,103,1,47,
73,12,104,4,0,
73,12,112,1,38,
73,12,127,4,0,
73,12,132,1,1,3
73,12,132,1,12,
73,12,156,4,0,
73,12,168,4,0,
73,12,182,4,0,
73,12,188,4,0,
73,12,202,4,0,
73,12,213,4,0,
73,12,216,4,0,
73,12,218,4,0,
73,12,237,4,0,
73,12,240,4,0,
73,12,258,4,0,
73,12,263,4,0,
73,12,290,4,0,
73,12,291,4,0,
73,12,352,4,0,
73,13,15,4,0,
73,13,35,1,30,
73,13,38,3,0,
73,13,40,1,1,1
73,13,48,1,1,2
73,13,48,1,6,
73,13,51,1,19,
73,13,56,1,55,
73,13,57,4,0,
73,13,58,4,0,
73,13,59,4,0,
73,13,61,1,25,
73,13,63,4,0,
73,13,92,4,0,
73,13,102,3,0,
73,13,103,1,47,
73,13,104,4,0,
73,13,112,1,38,
73,13,127,4,0,
73,13,132,1,1,3
73,13,132,1,12,
73,13,156,4,0,
73,13,164,3,0,
73,13,168,4,0,
73,13,182,4,0,
73,13,188,4,0,
73,13,196,3,0,
73,13,202,4,0,
73,13,207,3,0,
73,13,213,4,0,
73,13,216,4,0,
73,13,218,4,0,
73,13,237,4,0,
73,13,240,4,0,
73,13,258,4,0,
73,13,263,4,0,
73,13,290,4,0,
73,13,291,4,0,
73,13,352,4,0,
73,14,14,4,0,
73,14,15,4,0,
73,14,20,3,0,
73,14,35,1,22,
73,14,40,1,1,1
73,14,48,1,1,2
73,14,48,1,5,
73,14,51,1,12,
73,14,56,1,52,
73,14,57,4,0,
73,14,58,4,0,
73,14,59,4,0,
73,14,61,1,19,
73,14,63,4,0,
73,14,92,4,0,
73,14,103,1,43,
73,14,104,4,0,
73,14,112,1,29,
73,14,127,4,0,
73,14,132,1,1,3
73,14,132,1,8,
73,14,156,4,0,
73,14,164,4,0,
73,14,168,4,0,
73,14,173,3,0,
73,14,182,4,0,
73,14,188,4,0,
73,14,196,3,0,
73,14,202,3,0,
73,14,207,4,0,
73,14,213,4,0,
73,14,214,3,0,
73,14,216,4,0,
73,14,218,4,0,
73,14,219,4,0,
73,14,237,4,0,
73,14,240,4,0,
73,14,258,4,0,
73,14,263,4,0,
73,14,277,3,0,
73,14,282,3,0,
73,14,291,4,0,
73,14,352,1,34,
73,14,371,4,0,
73,14,378,1,61,
73,14,390,1,15,
73,14,398,1,38,
73,14,398,4,0,
73,14,416,4,0,
73,14,474,4,0,
73,14,482,1,56,
73,14,482,4,0,
73,14,491,1,26,
73,14,496,4,0,
73,14,503,4,0,
73,14,506,1,47,
73,15,14,4,0,
73,15,15,4,0,
73,15,35,1,22,
73,15,40,1,1,3
73,15,48,1,1,4
73,15,48,1,5,
73,15,51,1,12,
73,15,56,1,52,
73,15,57,4,0,
73,15,58,4,0,
73,15,59,4,0,
73,15,61,1,19,
73,15,63,4,0,
73,15,92,4,0,
73,15,103,1,43,
73,15,104,4,0,
73,15,112,1,29,
73,15,127,4,0,
73,15,132,1,1,5
73,15,132,1,8,
73,15,156,4,0,
73,15,164,4,0,
73,15,168,4,0,
73,15,182,4,0,
73,15,188,4,0,
73,15,207,4,0,
73,15,213,4,0,
73,15,214,4,0,
73,15,216,4,0,
73,15,218,4,0,
73,15,219,4,0,
73,15,237,4,0,
73,15,240,4,0,
73,15,258,4,0,
73,15,263,4,0,
73,15,352,1,34,
73,15,371,4,0,
73,15,378,1,1,2
73,15,378,1,61,
73,15,390,1,15,
73,15,398,1,38,
73,15,398,4,0,
73,15,416,4,0,
73,15,474,4,0,
73,15,482,1,56,
73,15,482,4,0,
73,15,491,1,26,
73,15,496,4,0,
73,15,503,4,0,
73,15,506,1,47,
73,15,513,1,1,1
73,15,590,4,0,
73,15,605,4,0,
73,15,611,4,0,
73,16,14,4,0,
73,16,15,4,0,
73,16,20,3,0,
73,16,35,1,19,1
73,16,40,1,1,3
73,16,48,1,1,4
73,16,48,1,4,1
73,16,51,1,1,6
73,16,51,1,10,1
73,16,56,1,52,1
73,16,57,4,0,
73,16,58,4,0,
73,16,59,4,0,
73,16,61,1,25,1
73,16,63,4,0,
73,16,92,4,0,
73,16,103,1,40,1
73,16,104,4,0,
73,16,112,1,28,1
73,16,127,4,0,
73,16,132,1,1,5
73,16,132,1,7,1
73,16,156,4,0,
73,16,164,4,0,
73,16,168,4,0,
73,16,173,3,0,
73,16,182,4,0,
73,16,188,4,0,
73,16,196,3,0,
73,16,202,3,0,
73,16,207,4,0,
73,16,213,4,0,
73,16,214,4,0,
73,16,216,4,0,
73,16,218,4,0,
73,16,219,4,0,
73,16,237,4,0,
73,16,240,4,0,
73,16,258,4,0,
73,16,263,4,0,
73,16,277,3,0,
73,16,282,3,0,
73,16,290,4,0,
73,16,291,4,0,
73,16,352,1,16,1
73,16,352,3,0,
73,16,362,1,36,1
73,16,371,4,0,
73,16,378,1,1,2
73,16,378,1,56,1
73,16,390,1,13,1
73,16,398,1,32,1
73,16,398,4,0,
73,16,416,4,0,
73,16,474,4,0,
73,16,482,1,48,1
73,16,482,4,0,
73,16,491,1,22,1
73,16,496,4,0,
73,16,503,4,0,
73,16,506,1,44,1
73,16,513,1,1,1
73,16,590,4,0,
73,16,605,4,0,
73,16,611,4,0,
73,17,14,4,0,
73,17,35,1,19,
73,17,40,1,1,3
73,17,48,1,1,4
73,17,48,1,4,
73,17,51,1,1,6
73,17,51,1,10,
73,17,56,1,52,
73,17,57,4,0,
73,17,58,4,0,
73,17,59,4,0,
73,17,61,1,25,
73,17,63,4,0,
73,17,92,4,0,
73,17,103,1,40,
73,17,104,4,0,
73,17,112,1,28,
73,17,127,4,0,
73,17,132,1,1,5
73,17,132,1,7,
73,17,156,4,0,
73,17,164,4,0,
73,17,168,4,0,
73,17,182,4,0,
73,17,188,4,0,
73,17,207,4,0,
73,17,213,4,0,
73,17,214,4,0,
73,17,216,4,0,
73,17,218,4,0,
73,17,219,4,0,
73,17,237,4,0,
73,17,240,4,0,
73,17,258,4,0,
73,17,263,4,0,
73,17,352,1,16,
73,17,362,1,36,
73,17,371,4,0,
73,17,378,1,1,2
73,17,378,1,56,
73,17,390,1,13,
73,17,398,1,32,
73,17,398,4,0,
73,17,416,4,0,
73,17,474,4,0,
73,17,482,1,48,
73,17,482,4,0,
73,17,491,1,22,
73,17,496,4,0,
73,17,503,4,0,
73,17,506,1,44,
73,17,513,1,1,1
73,17,590,4,0,
73,17,605,4,0,
73,17,611,4,0,
73,18,14,4,0,
73,18,35,1,19,
73,18,40,1,1,3
73,18,48,1,1,4
73,18,48,1,4,
73,18,51,1,1,6
73,18,51,1,10,
73,18,56,1,52,
73,18,57,4,0,
73,18,58,4,0,
73,18,59,4,0,
73,18,61,1,25,
73,18,63,4,0,
73,18,92,4,0,
73,18,103,1,40,
73,18,104,4,0,
73,18,112,1,28,
73,18,127,4,0,
73,18,132,1,1,5
73,18,132,1,7,
73,18,156,4,0,
73,18,164,4,0,
73,18,168,4,0,
73,18,182,4,0,
73,18,188,4,0,
73,18,207,4,0,
73,18,213,4,0,
73,18,214,4,0,
73,18,216,4,0,
73,18,218,4,0,
73,18,219,4,0,
73,18,237,4,0,
73,18,240,4,0,
73,18,258,4,0,
73,18,263,4,0,
73,18,352,1,16,
73,18,362,1,36,
73,18,371,4,0,
73,18,378,1,1,2
73,18,378,1,56,
73,18,390,1,13,
73,18,398,1,32,
73,18,398,4,0,
73,18,416,4,0,
73,18,474,4,0,
73,18,482,1,48,
73,18,482,4,0,
73,18,491,1,22,
73,18,496,4,0,
73,18,503,4,0,
73,18,506,1,44,
73,18,513,1,1,1
73,18,590,4,0,
73,18,605,4,0,
73,18,611,4,0,
74,1,5,4,0,
74,1,33,1,1,
74,1,34,4,0,
74,1,36,4,0,
74,1,38,4,0,
74,1,66,4,0,
74,1,68,4,0,
74,1,69,4,0,
74,1,70,4,0,
74,1,88,1,16,
74,1,89,1,31,
74,1,89,4,0,
74,1,90,4,0,
74,1,91,4,0,
74,1,92,4,0,
74,1,99,4,0,
74,1,102,4,0,
74,1,104,4,0,
74,1,106,1,26,
74,1,111,1,11,
74,1,117,4,0,
74,1,118,4,0,
74,1,120,1,21,
74,1,120,4,0,
74,1,126,4,0,
74,1,153,1,36,
74,1,153,4,0,
74,1,156,4,0,
74,1,157,4,0,
74,1,164,4,0,
74,2,5,4,0,
74,2,33,1,1,
74,2,34,4,0,
74,2,36,4,0,
74,2,38,4,0,
74,2,66,4,0,
74,2,68,4,0,
74,2,69,4,0,
74,2,70,4,0,
74,2,88,1,16,
74,2,89,1,31,
74,2,89,4,0,
74,2,90,4,0,
74,2,91,4,0,
74,2,92,4,0,
74,2,99,4,0,
74,2,102,4,0,
74,2,104,4,0,
74,2,106,1,26,
74,2,111,1,11,
74,2,117,4,0,
74,2,118,4,0,
74,2,120,1,21,
74,2,120,4,0,
74,2,126,4,0,
74,2,153,1,36,
74,2,153,4,0,
74,2,156,4,0,
74,2,157,4,0,
74,2,164,4,0,
74,3,5,2,0,
74,3,7,4,0,
74,3,29,4,0,
74,3,33,1,1,
74,3,70,4,0,
74,3,88,1,11,
74,3,89,1,36,
74,3,89,4,0,
74,3,91,4,0,
74,3,92,4,0,
74,3,104,4,0,
74,3,106,1,26,
74,3,111,1,6,
74,3,111,4,0,
74,3,120,1,21,
74,3,126,4,0,
74,3,153,1,41,
74,3,156,4,0,
74,3,157,2,0,
74,3,173,4,0,
74,3,174,4,0,
74,3,182,4,0,
74,3,189,4,0,
74,3,201,4,0,
74,3,203,4,0,
74,3,205,1,31,
74,3,205,4,0,
74,3,207,4,0,
74,3,213,4,0,
74,3,214,4,0,
74,3,216,4,0,
74,3,218,4,0,
74,3,222,1,16,
74,3,223,4,0,
74,3,237,4,0,
74,3,241,4,0,
74,3,249,4,0,
74,4,5,2,0,
74,4,7,4,0,
74,4,29,4,0,
74,4,33,1,1,
74,4,53,3,0,
74,4,70,4,0,
74,4,88,1,11,
74,4,89,1,36,
74,4,89,4,0,
74,4,91,4,0,
74,4,92,4,0,
74,4,104,4,0,
74,4,106,1,26,
74,4,111,1,6,
74,4,111,4,0,
74,4,120,1,21,
74,4,126,4,0,
74,4,153,1,41,
74,4,156,4,0,
74,4,157,2,0,
74,4,173,4,0,
74,4,174,4,0,
74,4,182,4,0,
74,4,189,4,0,
74,4,201,4,0,
74,4,203,4,0,
74,4,205,1,31,
74,4,205,4,0,
74,4,207,4,0,
74,4,213,4,0,
74,4,214,4,0,
74,4,216,4,0,
74,4,218,4,0,
74,4,222,1,16,
74,4,223,4,0,
74,4,237,4,0,
74,4,241,4,0,
74,4,249,4,0,
74,5,5,2,0,
74,5,33,1,1,1
74,5,38,1,46,
74,5,53,4,0,
74,5,70,4,0,
74,5,88,1,11,
74,5,89,1,36,
74,5,89,4,0,
74,5,91,4,0,
74,5,92,4,0,
74,5,104,4,0,
74,5,111,1,1,2
74,5,120,1,21,
74,5,126,4,0,
74,5,153,1,41,
74,5,156,4,0,
74,5,157,2,0,
74,5,182,4,0,
74,5,201,4,0,
74,5,205,1,26,
74,5,213,4,0,
74,5,216,4,0,
74,5,218,4,0,
74,5,222,1,16,
74,5,237,4,0,
74,5,241,4,0,
74,5,249,4,0,
74,5,263,4,0,
74,5,264,4,0,
74,5,280,4,0,
74,5,290,4,0,
74,5,300,1,6,
74,5,317,4,0,
74,5,335,2,0,
74,5,350,1,31,
74,6,5,2,0,
74,6,5,3,0,
74,6,7,3,0,
74,6,33,1,1,1
74,6,34,3,0,
74,6,38,1,46,
74,6,38,3,0,
74,6,53,4,0,
74,6,68,3,0,
74,6,69,3,0,
74,6,70,4,0,
74,6,88,1,11,
74,6,89,1,36,
74,6,89,4,0,
74,6,91,4,0,
74,6,92,4,0,
74,6,102,3,0,
74,6,104,4,0,
74,6,111,1,1,2
74,6,111,3,0,
74,6,118,3,0,
74,6,120,1,21,
74,6,126,4,0,
74,6,153,1,41,
74,6,153,3,0,
74,6,156,4,0,
74,6,157,2,0,
74,6,157,3,0,
74,6,164,3,0,
74,6,173,3,0,
74,6,182,4,0,
74,6,189,3,0,
74,6,201,4,0,
74,6,203,3,0,
74,6,205,1,26,
74,6,205,3,0,
74,6,207,3,0,
74,6,213,4,0,
74,6,214,3,0,
74,6,216,4,0,
74,6,218,4,0,
74,6,222,1,16,
74,6,223,3,0,
74,6,237,4,0,
74,6,241,4,0,
74,6,249,4,0,
74,6,263,4,0,
74,6,264,4,0,
74,6,280,4,0,
74,6,290,4,0,
74,6,300,1,6,
74,6,317,4,0,
74,6,335,2,0,
74,6,350,1,31,
74,7,5,2,0,
74,7,5,3,0,
74,7,33,1,1,1
74,7,34,3,0,
74,7,38,1,46,
74,7,38,3,0,
74,7,53,4,0,
74,7,68,3,0,
74,7,69,3,0,
74,7,70,4,0,
74,7,88,1,11,
74,7,89,1,36,
74,7,89,4,0,
74,7,91,4,0,
74,7,92,4,0,
74,7,102,3,0,
74,7,104,4,0,
74,7,111,1,1,2
74,7,118,3,0,
74,7,120,1,21,
74,7,126,4,0,
74,7,153,1,41,
74,7,153,3,0,
74,7,156,4,0,
74,7,157,2,0,
74,7,157,3,0,
74,7,164,3,0,
74,7,182,4,0,
74,7,201,4,0,
74,7,205,1,26,
74,7,213,4,0,
74,7,216,4,0,
74,7,218,4,0,
74,7,222,1,16,
74,7,237,4,0,
74,7,241,4,0,
74,7,249,4,0,
74,7,263,4,0,
74,7,264,4,0,
74,7,280,4,0,
74,7,290,4,0,
74,7,300,1,6,
74,7,317,4,0,
74,7,335,2,0,
74,7,350,1,31,
74,8,5,2,0,
74,8,33,1,1,1
74,8,38,1,36,
74,8,53,4,0,
74,8,70,4,0,
74,8,88,1,11,
74,8,89,1,29,
74,8,89,4,0,
74,8,91,4,0,
74,8,92,4,0,
74,8,104,4,0,
74,8,111,1,1,2
74,8,120,1,18,
74,8,126,4,0,
74,8,153,1,32,
74,8,153,4,0,
74,8,156,4,0,
74,8,157,2,0,
74,8,157,4,0,
74,8,164,4,0,
74,8,175,2,0,
74,8,182,4,0,
74,8,201,4,0,
74,8,203,4,0,
74,8,205,1,22,
74,8,207,4,0,
74,8,213,4,0,
74,8,214,4,0,
74,8,216,4,0,
74,8,218,4,0,
74,8,222,1,15,
74,8,237,4,0,
74,8,241,4,0,
74,8,249,4,0,
74,8,263,4,0,
74,8,264,4,0,
74,8,280,4,0,
74,8,290,4,0,
74,8,300,1,4,
74,8,317,4,0,
74,8,335,2,0,
74,8,350,1,25,
74,8,359,2,0,
74,8,360,4,0,
74,8,363,4,0,
74,8,374,4,0,
74,8,397,1,8,
74,8,397,4,0,
74,8,431,4,0,
74,8,444,1,39,
74,8,444,4,0,
74,8,445,4,0,
74,8,446,4,0,
74,9,5,2,0,
74,9,7,3,0,
74,9,9,3,0,
74,9,33,1,1,1
74,9,38,1,36,
74,9,53,4,0,
74,9,70,4,0,
74,9,88,1,11,
74,9,89,1,29,
74,9,89,4,0,
74,9,91,4,0,
74,9,92,4,0,
74,9,104,4,0,
74,9,111,1,1,2
74,9,120,1,18,
74,9,126,4,0,
74,9,153,1,32,
74,9,153,4,0,
74,9,156,4,0,
74,9,157,2,0,
74,9,157,4,0,
74,9,164,4,0,
74,9,173,3,0,
74,9,175,2,0,
74,9,182,4,0,
74,9,189,3,0,
74,9,201,4,0,
74,9,203,4,0,
74,9,205,1,22,
74,9,205,3,0,
74,9,207,4,0,
74,9,213,4,0,
74,9,214,4,0,
74,9,216,4,0,
74,9,218,4,0,
74,9,222,1,15,
74,9,237,4,0,
74,9,241,4,0,
74,9,246,3,0,
74,9,249,4,0,
74,9,263,4,0,
74,9,264,4,0,
74,9,276,3,0,
74,9,280,4,0,
74,9,290,4,0,
74,9,300,1,4,
74,9,317,4,0,
74,9,335,2,0,
74,9,350,1,25,
74,9,359,2,0,
74,9,360,4,0,
74,9,363,4,0,
74,9,374,4,0,
74,9,389,3,0,
74,9,397,1,8,
74,9,397,4,0,
74,9,414,3,0,
74,9,431,4,0,
74,9,444,1,39,
74,9,444,4,0,
74,9,445,4,0,
74,9,446,4,0,
74,10,5,2,0,
74,10,7,3,0,
74,10,9,3,0,
74,10,29,3,0,
74,10,33,1,1,1
74,10,38,1,36,
74,10,53,4,0,
74,10,70,4,0,
74,10,88,1,11,
74,10,89,1,29,
74,10,89,4,0,
74,10,91,4,0,
74,10,92,4,0,
74,10,104,4,0,
74,10,111,1,1,2
74,10,120,1,18,
74,10,126,4,0,
74,10,153,1,32,
74,10,153,4,0,
74,10,156,4,0,
74,10,157,2,0,
74,10,157,4,0,
74,10,164,4,0,
74,10,173,3,0,
74,10,174,2,0,
74,10,175,2,0,
74,10,182,4,0,
74,10,189,3,0,
74,10,201,4,0,
74,10,203,4,0,
74,10,205,1,22,
74,10,205,3,0,
74,10,207,4,0,
74,10,213,4,0,
74,10,214,4,0,
74,10,216,4,0,
74,10,218,4,0,
74,10,222,1,15,
74,10,237,4,0,
74,10,241,4,0,
74,10,246,3,0,
74,10,249,4,0,
74,10,263,4,0,
74,10,264,4,0,
74,10,276,3,0,
74,10,280,4,0,
74,10,290,4,0,
74,10,300,1,4,
74,10,317,4,0,
74,10,335,2,0,
74,10,335,3,0,
74,10,350,1,25,
74,10,359,2,0,
74,10,360,4,0,
74,10,363,4,0,
74,10,374,4,0,
74,10,389,3,0,
74,10,397,1,8,
74,10,397,4,0,
74,10,414,3,0,
74,10,431,4,0,
74,10,444,1,39,
74,10,444,4,0,
74,10,445,4,0,
74,10,446,4,0,
74,11,5,2,0,
74,11,33,1,1,1
74,11,38,1,46,
74,11,53,4,0,
74,11,70,4,0,
74,11,88,1,11,
74,11,89,1,39,
74,11,89,4,0,
74,11,91,4,0,
74,11,92,4,0,
74,11,104,4,0,
74,11,111,1,1,2
74,11,120,1,29,
74,11,126,4,0,
74,11,153,1,43,
74,11,153,4,0,
74,11,156,4,0,
74,11,157,4,0,
74,11,164,4,0,
74,11,174,2,0,
74,11,175,2,0,
74,11,182,4,0,
74,11,201,4,0,
74,11,203,2,0,
74,11,205,1,18,
74,11,207,4,0,
74,11,213,4,0,
74,11,216,4,0,
74,11,218,4,0,
74,11,222,1,15,
74,11,237,4,0,
74,11,241,4,0,
74,11,249,4,0,
74,11,263,4,0,
74,11,264,2,0,
74,11,280,4,0,
74,11,300,1,4,
74,11,317,4,0,
74,11,335,2,0,
74,11,350,1,22,
74,11,359,2,0,
74,11,360,4,0,
74,11,374,4,0,
74,11,397,1,8,
74,11,397,4,0,
74,11,431,2,0,
74,11,444,1,50,
74,11,444,4,0,
74,11,446,1,36,
74,11,475,2,0,
74,11,479,1,25,
74,11,479,4,0,
74,11,496,4,0,
74,11,510,4,0,
74,11,523,1,32,
74,11,523,4,0,
74,12,33,1,1,1
74,12,38,1,46,
74,12,53,4,0,
74,12,70,4,0,
74,12,88,1,11,
74,12,89,1,36,
74,12,89,4,0,
74,12,91,4,0,
74,12,92,4,0,
74,12,104,4,0,
74,12,111,1,1,2
74,12,120,1,21,
74,12,126,4,0,
74,12,153,1,41,
74,12,156,4,0,
74,12,182,4,0,
74,12,201,4,0,
74,12,205,1,26,
74,12,213,4,0,
74,12,216,4,0,
74,12,218,4,0,
74,12,222,1,16,
74,12,237,4,0,
74,12,241,4,0,
74,12,249,4,0,
74,12,263,4,0,
74,12,264,4,0,
74,12,280,4,0,
74,12,290,4,0,
74,12,300,1,6,
74,12,317,4,0,
74,12,350,1,31,
74,13,33,1,1,1
74,13,34,3,0,
74,13,38,1,46,
74,13,38,3,0,
74,13,53,4,0,
74,13,69,3,0,
74,13,70,4,0,
74,13,88,1,11,
74,13,89,1,36,
74,13,89,4,0,
74,13,91,4,0,
74,13,92,4,0,
74,13,102,3,0,
74,13,104,4,0,
74,13,111,1,1,2
74,13,120,1,21,
74,13,120,3,0,
74,13,126,4,0,
74,13,153,1,41,
74,13,156,4,0,
74,13,164,3,0,
74,13,182,4,0,
74,13,201,4,0,
74,13,205,1,26,
74,13,207,3,0,
74,13,213,4,0,
74,13,216,4,0,
74,13,218,4,0,
74,13,222,1,16,
74,13,237,4,0,
74,13,241,4,0,
74,13,249,4,0,
74,13,263,4,0,
74,13,264,4,0,
74,13,280,4,0,
74,13,290,4,0,
74,13,300,1,6,
74,13,317,4,0,
74,13,350,1,31,
74,14,5,2,0,
74,14,7,3,0,
74,14,9,3,0,
74,14,33,1,1,1
74,14,38,1,46,
74,14,53,4,0,
74,14,70,4,0,
74,14,88,1,11,
74,14,89,1,39,
74,14,89,4,0,
74,14,91,4,0,
74,14,92,4,0,
74,14,104,4,0,
74,14,111,1,1,2
74,14,120,1,29,
74,14,126,4,0,
74,14,153,1,43,
74,14,153,4,0,
74,14,156,4,0,
74,14,157,4,0,
74,14,164,4,0,
74,14,173,3,0,
74,14,174,2,0,
74,14,175,2,0,
74,14,182,4,0,
74,14,201,4,0,
74,14,203,2,0,
74,14,205,1,18,
74,14,207,4,0,
74,14,213,4,0,
74,14,214,3,0,
74,14,216,4,0,
74,14,218,4,0,
74,14,222,1,15,
74,14,237,4,0,
74,14,241,4,0,
74,14,249,4,0,
74,14,263,4,0,
74,14,264,2,0,
74,14,276,3,0,
74,14,280,4,0,
74,14,300,1,4,
74,14,317,4,0,
74,14,334,3,0,
74,14,335,2,0,
74,14,335,3,0,
74,14,350,1,22,
74,14,359,2,0,
74,14,360,4,0,
74,14,374,4,0,
74,14,397,1,8,
74,14,397,4,0,
74,14,414,3,0,
74,14,431,2,0,
74,14,444,1,50,
74,14,444,4,0,
74,14,446,1,36,
74,14,446,3,0,
74,14,475,2,0,
74,14,479,1,25,
74,14,479,4,0,
74,14,496,4,0,
74,14,510,4,0,
74,14,523,1,32,
74,14,523,4,0,
74,15,5,2,0,
74,15,33,1,1,1
74,15,38,1,46,
74,15,53,4,0,
74,15,70,4,0,
74,15,88,1,18,
74,15,89,1,39,
74,15,89,4,0,
74,15,91,4,0,
74,15,92,4,0,
74,15,104,4,0,
74,15,111,1,1,2
74,15,120,1,29,
74,15,126,4,0,
74,15,153,1,43,
74,15,153,4,0,
74,15,156,4,0,
74,15,157,4,0,
74,15,164,4,0,
74,15,174,2,0,
74,15,175,2,0,
74,15,182,4,0,
74,15,201,4,0,
74,15,203,2,0,
74,15,205,1,11,
74,15,207,4,0,
74,15,213,4,0,
74,15,214,4,0,
74,15,216,4,0,
74,15,218,4,0,
74,15,222,1,15,
74,15,237,4,0,
74,15,241,4,0,
74,15,249,4,0,
74,15,263,4,0,
74,15,264,2,0,
74,15,267,4,0,
74,15,280,4,0,
74,15,300,1,4,
74,15,317,4,0,
74,15,335,2,0,
74,15,350,1,22,
74,15,359,2,0,
74,15,360,4,0,
74,15,374,4,0,
74,15,397,1,8,
74,15,397,4,0,
74,15,431,2,0,
74,15,444,1,50,
74,15,444,4,0,
74,15,446,1,36,
74,15,469,2,0,
74,15,475,2,0,
74,15,479,1,25,
74,15,479,4,0,
74,15,496,4,0,
74,15,510,4,0,
74,15,523,1,32,
74,15,523,4,0,
74,15,590,4,0,
74,15,612,4,0,
74,16,5,2,0,
74,16,7,3,0,
74,16,9,3,0,
74,16,33,1,1,1
74,16,38,1,40,1
74,16,53,4,0,
74,16,70,4,0,
74,16,88,1,16,1
74,16,89,1,34,1
74,16,89,4,0,
74,16,91,4,0,
74,16,92,4,0,
74,16,104,4,0,
74,16,111,1,1,2
74,16,120,1,24,1
74,16,126,4,0,
74,16,153,1,36,1
74,16,153,4,0,
74,16,156,4,0,
74,16,157,4,0,
74,16,164,4,0,
74,16,173,3,0,
74,16,174,2,0,
74,16,175,2,0,
74,16,182,4,0,
74,16,201,4,0,
74,16,203,2,0,
74,16,205,1,10,1
74,16,207,4,0,
74,16,213,4,0,
74,16,214,4,0,
74,16,216,4,0,
74,16,218,4,0,
74,16,222,1,12,1
74,16,237,4,0,
74,16,241,4,0,
74,16,249,4,0,
74,16,263,4,0,
74,16,264,2,0,
74,16,264,3,0,
74,16,267,4,0,
74,16,276,3,0,
74,16,280,4,0,
74,16,290,4,0,
74,16,300,1,4,1
74,16,317,4,0,
74,16,334,3,0,
74,16,335,2,0,
74,16,335,3,0,
74,16,350,1,30,1
74,16,359,2,0,
74,16,360,4,0,
74,16,374,4,0,
74,16,397,1,6,1
74,16,397,4,0,
74,16,414,3,0,
74,16,431,2,0,
74,16,444,1,42,1
74,16,444,4,0,
74,16,446,1,28,1
74,16,446,3,0,
74,16,469,2,0,
74,16,475,2,0,
74,16,479,1,18,1
74,16,479,4,0,
74,16,496,4,0,
74,16,510,4,0,
74,16,523,1,22,1
74,16,523,4,0,
74,16,590,4,0,
74,16,612,4,0,
74,17,5,2,0,
74,17,33,1,1,1
74,17,38,1,40,
74,17,53,4,0,
74,17,88,1,16,
74,17,89,1,34,
74,17,89,4,0,
74,17,92,4,0,
74,17,104,4,0,
74,17,111,1,1,2
74,17,120,1,24,
74,17,126,4,0,
74,17,153,1,36,
74,17,153,4,0,
74,17,156,4,0,
74,17,157,4,0,
74,17,164,4,0,
74,17,174,2,0,
74,17,175,2,0,
74,17,182,4,0,
74,17,201,4,0,
74,17,203,2,0,
74,17,205,1,10,
74,17,207,4,0,
74,17,213,4,0,
74,17,214,4,0,
74,17,216,4,0,
74,17,218,4,0,
74,17,222,1,12,
74,17,237,4,0,
74,17,241,4,0,
74,17,263,4,0,
74,17,264,2,0,
74,17,267,4,0,
74,17,280,4,0,
74,17,300,1,4,
74,17,317,4,0,
74,17,335,2,0,
74,17,350,1,30,
74,17,359,2,0,
74,17,360,4,0,
74,17,374,4,0,
74,17,397,1,6,
74,17,397,4,0,
74,17,431,2,0,
74,17,444,1,42,
74,17,444,4,0,
74,17,446,1,28,
74,17,469,2,0,
74,17,475,2,0,
74,17,479,1,18,
74,17,479,4,0,
74,17,496,4,0,
74,17,523,1,22,
74,17,523,4,0,
74,17,590,4,0,
74,18,5,2,0,
74,18,33,1,1,1
74,18,38,1,40,
74,18,53,4,0,
74,18,88,1,16,
74,18,89,1,34,
74,18,89,4,0,
74,18,92,4,0,
74,18,104,4,0,
74,18,111,1,1,2
74,18,120,1,24,
74,18,126,4,0,
74,18,153,1,36,
74,18,153,4,0,
74,18,156,4,0,
74,18,157,4,0,
74,18,164,4,0,
74,18,174,2,0,
74,18,175,2,0,
74,18,182,4,0,
74,18,201,4,0,
74,18,203,2,0,
74,18,205,1,10,
74,18,207,4,0,
74,18,213,4,0,
74,18,214,4,0,
74,18,216,4,0,
74,18,218,4,0,
74,18,222,1,12,
74,18,237,4,0,
74,18,241,4,0,
74,18,263,4,0,
74,18,264,2,0,
74,18,267,4,0,
74,18,280,4,0,
74,18,300,1,4,
74,18,317,4,0,
74,18,335,2,0,
74,18,350,1,30,
74,18,359,2,0,
74,18,360,4,0,
74,18,374,4,0,
74,18,397,1,6,
74,18,397,4,0,
74,18,431,2,0,
74,18,444,1,42,
74,18,444,4,0,
74,18,446,1,28,
74,18,469,2,0,
74,18,475,2,0,
74,18,479,1,18,
74,18,479,4,0,
74,18,496,4,0,
74,18,523,1,22,
74,18,523,4,0,
74,18,590,4,0,
75,1,5,4,0,
75,1,33,1,1,1
75,1,34,4,0,
75,1,36,4,0,
75,1,38,4,0,
75,1,66,4,0,
75,1,68,4,0,
75,1,69,4,0,
75,1,70,4,0,
75,1,88,1,16,
75,1,89,1,36,
75,1,89,4,0,
75,1,90,4,0,
75,1,91,4,0,
75,1,92,4,0,
75,1,99,4,0,
75,1,102,4,0,
75,1,104,4,0,
75,1,106,1,29,
75,1,111,1,1,2
75,1,111,1,11,
75,1,117,4,0,
75,1,118,4,0,
75,1,120,1,21,
75,1,120,4,0,
75,1,126,4,0,
75,1,153,1,43,
75,1,153,4,0,
75,1,156,4,0,
75,1,157,4,0,
75,1,164,4,0,
75,2,5,4,0,
75,2,33,1,1,1
75,2,34,4,0,
75,2,36,4,0,
75,2,38,4,0,
75,2,66,4,0,
75,2,68,4,0,
75,2,69,4,0,
75,2,70,4,0,
75,2,88,1,16,
75,2,89,1,36,
75,2,89,4,0,
75,2,90,4,0,
75,2,91,4,0,
75,2,92,4,0,
75,2,99,4,0,
75,2,102,4,0,
75,2,104,4,0,
75,2,106,1,29,
75,2,111,1,1,2
75,2,111,1,11,
75,2,117,4,0,
75,2,118,4,0,
75,2,120,1,21,
75,2,120,4,0,
75,2,126,4,0,
75,2,153,1,43,
75,2,153,4,0,
75,2,156,4,0,
75,2,157,4,0,
75,2,164,4,0,
75,3,7,4,0,
75,3,29,4,0,
75,3,33,1,1,1
75,3,70,4,0,
75,3,88,1,1,3
75,3,88,1,11,
75,3,89,1,41,
75,3,89,4,0,
75,3,91,4,0,
75,3,92,4,0,
75,3,104,4,0,
75,3,106,1,27,
75,3,111,1,1,2
75,3,111,1,6,
75,3,111,4,0,
75,3,120,1,21,
75,3,126,4,0,
75,3,153,1,48,
75,3,156,4,0,
75,3,173,4,0,
75,3,174,4,0,
75,3,182,4,0,
75,3,189,4,0,
75,3,201,4,0,
75,3,203,4,0,
75,3,205,1,34,
75,3,205,4,0,
75,3,207,4,0,
75,3,213,4,0,
75,3,214,4,0,
75,3,216,4,0,
75,3,218,4,0,
75,3,222,1,16,
75,3,223,4,0,
75,3,237,4,0,
75,3,241,4,0,
75,3,249,4,0,
75,4,7,4,0,
75,4,29,4,0,
75,4,33,1,1,1
75,4,53,3,0,
75,4,70,4,0,
75,4,88,1,1,3
75,4,88,1,11,
75,4,89,1,41,
75,4,89,4,0,
75,4,91,4,0,
75,4,92,4,0,
75,4,104,4,0,
75,4,106,1,27,
75,4,111,1,1,2
75,4,111,1,6,
75,4,111,4,0,
75,4,120,1,21,
75,4,126,4,0,
75,4,153,1,48,
75,4,156,4,0,
75,4,173,4,0,
75,4,174,4,0,
75,4,182,4,0,
75,4,189,4,0,
75,4,201,4,0,
75,4,203,4,0,
75,4,205,1,34,
75,4,205,4,0,
75,4,207,4,0,
75,4,213,4,0,
75,4,214,4,0,
75,4,216,4,0,
75,4,218,4,0,
75,4,222,1,16,
75,4,223,4,0,
75,4,237,4,0,
75,4,241,4,0,
75,4,249,4,0,
75,5,33,1,1,1
75,5,38,1,62,
75,5,53,4,0,
75,5,70,4,0,
75,5,88,1,1,4
75,5,88,1,11,
75,5,89,1,45,
75,5,89,4,0,
75,5,91,4,0,
75,5,92,4,0,
75,5,104,4,0,
75,5,111,1,1,2
75,5,120,1,21,
75,5,126,4,0,
75,5,153,1,53,
75,5,156,4,0,
75,5,182,4,0,
75,5,201,4,0,
75,5,205,1,29,
75,5,213,4,0,
75,5,216,4,0,
75,5,218,4,0,
75,5,222,1,16,
75,5,237,4,0,
75,5,241,4,0,
75,5,249,4,0,
75,5,263,4,0,
75,5,264,4,0,
75,5,280,4,0,
75,5,290,4,0,
75,5,300,1,1,3
75,5,300,1,6,
75,5,317,4,0,
75,5,350,1,37,
75,6,5,3,0,
75,6,7,3,0,
75,6,33,1,1,1
75,6,34,3,0,
75,6,38,1,62,
75,6,38,3,0,
75,6,53,4,0,
75,6,68,3,0,
75,6,69,3,0,
75,6,70,4,0,
75,6,88,1,1,4
75,6,88,1,11,
75,6,89,1,45,
75,6,89,4,0,
75,6,91,4,0,
75,6,92,4,0,
75,6,102,3,0,
75,6,104,4,0,
75,6,111,1,1,2
75,6,111,3,0,
75,6,118,3,0,
75,6,120,1,21,
75,6,126,4,0,
75,6,153,1,53,
75,6,153,3,0,
75,6,156,4,0,
75,6,157,3,0,
75,6,164,3,0,
75,6,173,3,0,
75,6,182,4,0,
75,6,189,3,0,
75,6,201,4,0,
75,6,203,3,0,
75,6,205,1,29,
75,6,205,3,0,
75,6,207,3,0,
75,6,213,4,0,
75,6,214,3,0,
75,6,216,4,0,
75,6,218,4,0,
75,6,222,1,16,
75,6,223,3,0,
75,6,237,4,0,
75,6,241,4,0,
75,6,249,4,0,
75,6,263,4,0,
75,6,264,4,0,
75,6,280,4,0,
75,6,290,4,0,
75,6,300,1,1,3
75,6,300,1,6,
75,6,317,4,0,
75,6,350,1,37,
75,7,5,3,0,
75,7,33,1,1,1
75,7,34,3,0,
75,7,38,1,62,
75,7,38,3,0,
75,7,53,4,0,
75,7,68,3,0,
75,7,69,3,0,
75,7,70,4,0,
75,7,88,1,1,4
75,7,88,1,11,
75,7,89,1,45,
75,7,89,4,0,
75,7,91,4,0,
75,7,92,4,0,
75,7,102,3,0,
75,7,104,4,0,
75,7,111,1,1,2
75,7,118,3,0,
75,7,120,1,21,
75,7,126,4,0,
75,7,153,1,53,
75,7,153,3,0,
75,7,156,4,0,
75,7,157,3,0,
75,7,164,3,0,
75,7,182,4,0,
75,7,201,4,0,
75,7,205,1,29,
75,7,213,4,0,
75,7,216,4,0,
75,7,218,4,0,
75,7,222,1,16,
75,7,237,4,0,
75,7,241,4,0,
75,7,249,4,0,
75,7,263,4,0,
75,7,264,4,0,
75,7,280,4,0,
75,7,290,4,0,
75,7,300,1,1,3
75,7,300,1,6,
75,7,317,4,0,
75,7,350,1,37,
75,8,33,1,1,1
75,8,38,1,44,
75,8,53,4,0,
75,8,70,4,0,
75,8,88,1,11,
75,8,89,1,33,
75,8,89,4,0,
75,8,91,4,0,
75,8,92,4,0,
75,8,104,4,0,
75,8,111,1,1,2
75,8,120,1,18,
75,8,126,4,0,
75,8,153,1,38,
75,8,153,4,0,
75,8,156,4,0,
75,8,157,4,0,
75,8,164,4,0,
75,8,182,4,0,
75,8,201,4,0,
75,8,203,4,0,
75,8,205,1,22,
75,8,207,4,0,
75,8,213,4,0,
75,8,214,4,0,
75,8,216,4,0,
75,8,218,4,0,
75,8,222,1,15,
75,8,237,4,0,
75,8,241,4,0,
75,8,249,4,0,
75,8,263,4,0,
75,8,264,4,0,
75,8,280,4,0,
75,8,290,4,0,
75,8,300,1,1,3
75,8,300,1,4,
75,8,317,4,0,
75,8,350,1,27,
75,8,360,4,0,
75,8,363,4,0,
75,8,374,4,0,
75,8,397,1,1,4
75,8,397,1,8,
75,8,397,4,0,
75,8,431,4,0,
75,8,444,1,49,
75,8,444,4,0,
75,8,445,4,0,
75,8,446,4,0,
75,9,7,3,0,
75,9,9,3,0,
75,9,33,1,1,1
75,9,38,1,44,
75,9,53,4,0,
75,9,70,4,0,
75,9,88,1,11,
75,9,89,1,33,
75,9,89,4,0,
75,9,91,4,0,
75,9,92,4,0,
75,9,104,4,0,
75,9,111,1,1,2
75,9,120,1,18,
75,9,126,4,0,
75,9,153,1,38,
75,9,153,4,0,
75,9,156,4,0,
75,9,157,4,0,
75,9,164,4,0,
75,9,173,3,0,
75,9,182,4,0,
75,9,189,3,0,
75,9,201,4,0,
75,9,203,4,0,
75,9,205,1,22,
75,9,205,3,0,
75,9,207,4,0,
75,9,213,4,0,
75,9,214,4,0,
75,9,216,4,0,
75,9,218,4,0,
75,9,222,1,15,
75,9,237,4,0,
75,9,241,4,0,
75,9,246,3,0,
75,9,249,4,0,
75,9,263,4,0,
75,9,264,4,0,
75,9,276,3,0,
75,9,280,4,0,
75,9,290,4,0,
75,9,300,1,1,3
75,9,300,1,4,
75,9,317,4,0,
75,9,350,1,27,
75,9,360,4,0,
75,9,363,4,0,
75,9,374,4,0,
75,9,389,3,0,
75,9,397,1,1,4
75,9,397,1,8,
75,9,397,4,0,
75,9,414,3,0,
75,9,431,4,0,
75,9,444,1,49,
75,9,444,4,0,
75,9,445,4,0,
75,9,446,4,0,
75,10,7,3,0,
75,10,9,3,0,
75,10,29,3,0,
75,10,33,1,1,1
75,10,38,1,44,
75,10,53,4,0,
75,10,70,4,0,
75,10,88,1,11,
75,10,89,1,33,
75,10,89,4,0,
75,10,91,4,0,
75,10,92,4,0,
75,10,104,4,0,
75,10,111,1,1,2
75,10,120,1,18,
75,10,126,4,0,
75,10,153,1,38,
75,10,153,4,0,
75,10,156,4,0,
75,10,157,4,0,
75,10,164,4,0,
75,10,173,3,0,
75,10,182,4,0,
75,10,189,3,0,
75,10,201,4,0,
75,10,203,4,0,
75,10,205,1,22,
75,10,205,3,0,
75,10,207,4,0,
75,10,213,4,0,
75,10,214,4,0,
75,10,216,4,0,
75,10,218,4,0,
75,10,222,1,15,
75,10,237,4,0,
75,10,241,4,0,
75,10,246,3,0,
75,10,249,4,0,
75,10,263,4,0,
75,10,264,4,0,
75,10,276,3,0,
75,10,280,4,0,
75,10,290,4,0,
75,10,300,1,1,3
75,10,300,1,4,
75,10,317,4,0,
75,10,335,3,0,
75,10,350,1,27,
75,10,360,4,0,
75,10,363,4,0,
75,10,374,4,0,
75,10,389,3,0,
75,10,397,1,1,4
75,10,397,1,8,
75,10,397,4,0,
75,10,414,3,0,
75,10,431,4,0,
75,10,444,1,49,
75,10,444,4,0,
75,10,445,4,0,
75,10,446,4,0,
75,11,33,1,1,1
75,11,38,1,58,
75,11,53,4,0,
75,11,70,4,0,
75,11,88,1,11,
75,11,89,1,47,
75,11,89,4,0,
75,11,91,4,0,
75,11,92,4,0,
75,11,104,4,0,
75,11,111,1,1,2
75,11,120,1,31,
75,11,126,4,0,
75,11,153,1,53,
75,11,153,4,0,
75,11,156,4,0,
75,11,157,4,0,
75,11,164,4,0,
75,11,182,4,0,
75,11,201,4,0,
75,11,205,1,18,
75,11,207,4,0,
75,11,213,4,0,
75,11,216,4,0,
75,11,218,4,0,
75,11,222,1,15,
75,11,237,4,0,
75,11,241,4,0,
75,11,249,4,0,
75,11,263,4,0,
75,11,280,4,0,
75,11,300,1,1,3
75,11,300,1,4,
75,11,317,4,0,
75,11,350,1,22,
75,11,360,4,0,
75,11,374,4,0,
75,11,397,1,1,4
75,11,397,1,8,
75,11,397,4,0,
75,11,444,1,64,
75,11,444,4,0,
75,11,446,1,42,
75,11,479,1,27,
75,11,479,4,0,
75,11,496,4,0,
75,11,510,4,0,
75,11,523,1,36,
75,11,523,4,0,
75,12,33,1,1,1
75,12,38,1,62,
75,12,53,4,0,
75,12,70,4,0,
75,12,88,1,1,4
75,12,88,1,11,
75,12,89,1,45,
75,12,89,4,0,
75,12,91,4,0,
75,12,92,4,0,
75,12,104,4,0,
75,12,111,1,1,2
75,12,120,1,21,
75,12,126,4,0,
75,12,153,1,53,
75,12,156,4,0,
75,12,182,4,0,
75,12,201,4,0,
75,12,205,1,29,
75,12,213,4,0,
75,12,216,4,0,
75,12,218,4,0,
75,12,222,1,16,
75,12,237,4,0,
75,12,241,4,0,
75,12,249,4,0,
75,12,263,4,0,
75,12,264,4,0,
75,12,280,4,0,
75,12,290,4,0,
75,12,300,1,1,3
75,12,300,1,6,
75,12,317,4,0,
75,12,350,1,37,
75,13,33,1,1,1
75,13,34,3,0,
75,13,38,1,62,
75,13,38,3,0,
75,13,53,4,0,
75,13,69,3,0,
75,13,70,4,0,
75,13,88,1,1,4
75,13,88,1,11,
75,13,89,1,45,
75,13,89,4,0,
75,13,91,4,0,
75,13,92,4,0,
75,13,102,3,0,
75,13,104,4,0,
75,13,111,1,1,2
75,13,120,1,21,
75,13,120,3,0,
75,13,126,4,0,
75,13,153,1,53,
75,13,156,4,0,
75,13,164,3,0,
75,13,182,4,0,
75,13,201,4,0,
75,13,205,1,29,
75,13,207,3,0,
75,13,213,4,0,
75,13,216,4,0,
75,13,218,4,0,
75,13,222,1,16,
75,13,237,4,0,
75,13,241,4,0,
75,13,249,4,0,
75,13,263,4,0,
75,13,264,4,0,
75,13,280,4,0,
75,13,290,4,0,
75,13,300,1,1,3
75,13,300,1,6,
75,13,317,4,0,
75,13,350,1,37,
75,14,7,3,0,
75,14,9,3,0,
75,14,33,1,1,1
75,14,38,1,58,
75,14,53,4,0,
75,14,70,4,0,
75,14,88,1,11,
75,14,89,1,47,
75,14,89,4,0,
75,14,91,4,0,
75,14,92,4,0,
75,14,104,4,0,
75,14,111,1,1,2
75,14,120,1,31,
75,14,126,4,0,
75,14,153,1,53,
75,14,153,4,0,
75,14,156,4,0,
75,14,157,4,0,
75,14,164,4,0,
75,14,173,3,0,
75,14,182,4,0,
75,14,201,4,0,
75,14,205,1,18,
75,14,207,4,0,
75,14,213,4,0,
75,14,214,3,0,
75,14,216,4,0,
75,14,218,4,0,
75,14,222,1,15,
75,14,237,4,0,
75,14,241,4,0,
75,14,249,4,0,
75,14,263,4,0,
75,14,276,3,0,
75,14,280,4,0,
75,14,300,1,1,3
75,14,300,1,4,
75,14,317,4,0,
75,14,334,3,0,
75,14,335,3,0,
75,14,350,1,22,
75,14,360,4,0,
75,14,374,4,0,
75,14,397,1,1,4
75,14,397,1,8,
75,14,397,4,0,
75,14,414,3,0,
75,14,444,1,64,
75,14,444,4,0,
75,14,446,1,42,
75,14,446,3,0,
75,14,479,1,27,
75,14,479,4,0,
75,14,496,4,0,
75,14,510,4,0,
75,14,523,1,36,
75,14,523,4,0,
75,15,33,1,1,1
75,15,38,1,58,
75,15,53,4,0,
75,15,70,4,0,
75,15,88,1,18,
75,15,89,1,47,
75,15,89,4,0,
75,15,91,4,0,
75,15,92,4,0,
75,15,104,4,0,
75,15,111,1,1,2
75,15,120,1,31,
75,15,126,4,0,
75,15,153,1,53,
75,15,153,4,0,
75,15,156,4,0,
75,15,157,4,0,
75,15,164,4,0,
75,15,182,4,0,
75,15,201,4,0,
75,15,205,1,11,
75,15,207,4,0,
75,15,213,4,0,
75,15,214,4,0,
75,15,216,4,0,
75,15,218,4,0,
75,15,222,1,15,
75,15,237,4,0,
75,15,241,4,0,
75,15,249,4,0,
75,15,263,4,0,
75,15,267,4,0,
75,15,280,4,0,
75,15,300,1,1,3
75,15,300,1,4,
75,15,317,4,0,
75,15,350,1,22,
75,15,360,4,0,
75,15,374,4,0,
75,15,397,1,1,4
75,15,397,1,8,
75,15,397,4,0,
75,15,444,1,64,
75,15,444,4,0,
75,15,446,1,42,
75,15,479,1,27,
75,15,479,4,0,
75,15,496,4,0,
75,15,510,4,0,
75,15,523,1,36,
75,15,523,4,0,
75,15,590,4,0,
75,15,612,4,0,
75,16,7,3,0,
75,16,9,3,0,
75,16,33,1,1,1
75,16,38,1,50,1
75,16,53,4,0,
75,16,70,4,0,
75,16,88,1,16,1
75,16,89,1,40,1
75,16,89,4,0,
75,16,91,4,0,
75,16,92,4,0,
75,16,104,4,0,
75,16,111,1,1,2
75,16,120,1,24,1
75,16,126,4,0,
75,16,153,1,44,1
75,16,153,4,0,
75,16,156,4,0,
75,16,157,4,0,
75,16,164,4,0,
75,16,173,3,0,
75,16,182,4,0,
75,16,201,4,0,
75,16,205,1,10,1
75,16,207,4,0,
75,16,213,4,0,
75,16,214,4,0,
75,16,216,4,0,
75,16,218,4,0,
75,16,222,1,12,1
75,16,237,4,0,
75,16,241,4,0,
75,16,249,4,0,
75,16,263,4,0,
75,16,264,3,0,
75,16,267,4,0,
75,16,276,3,0,
75,16,280,4,0,
75,16,290,4,0,
75,16,300,1,1,3
75,16,300,1,4,1
75,16,317,4,0,
75,16,334,3,0,
75,16,335,3,0,
75,16,350,1,34,1
75,16,360,4,0,
75,16,374,4,0,
75,16,397,1,1,4
75,16,397,1,6,1
75,16,397,4,0,
75,16,414,3,0,
75,16,444,1,54,1
75,16,444,4,0,
75,16,446,1,30,1
75,16,446,3,0,
75,16,479,1,18,1
75,16,479,4,0,
75,16,496,4,0,
75,16,510,4,0,
75,16,523,1,22,1
75,16,523,4,0,
75,16,590,4,0,
75,16,612,4,0,
75,17,33,1,1,1
75,17,38,1,50,
75,17,53,4,0,
75,17,88,1,16,
75,17,89,1,40,
75,17,89,4,0,
75,17,92,4,0,
75,17,104,4,0,
75,17,111,1,1,2
75,17,120,1,24,
75,17,126,4,0,
75,17,153,1,44,
75,17,153,4,0,
75,17,156,4,0,
75,17,157,4,0,
75,17,164,4,0,
75,17,182,4,0,
75,17,201,4,0,
75,17,205,1,10,
75,17,207,4,0,
75,17,213,4,0,
75,17,214,4,0,
75,17,216,4,0,
75,17,218,4,0,
75,17,222,1,12,
75,17,237,4,0,
75,17,241,4,0,
75,17,263,4,0,
75,17,267,4,0,
75,17,280,4,0,
75,17,300,1,1,3
75,17,300,1,4,
75,17,317,4,0,
75,17,350,1,34,
75,17,360,4,0,
75,17,374,4,0,
75,17,397,1,1,4
75,17,397,1,6,
75,17,397,4,0,
75,17,444,1,54,
75,17,444,4,0,
75,17,446,1,30,
75,17,479,1,18,
75,17,479,4,0,
75,17,496,4,0,
75,17,523,1,22,
75,17,523,4,0,
75,17,590,4,0,
75,18,33,1,1,1
75,18,38,1,50,
75,18,53,4,0,
75,18,88,1,16,
75,18,89,1,40,
75,18,89,4,0,
75,18,92,4,0,
75,18,104,4,0,
75,18,111,1,1,2
75,18,120,1,24,
75,18,126,4,0,
75,18,153,1,44,
75,18,153,4,0,
75,18,156,4,0,
75,18,157,4,0,
75,18,164,4,0,
75,18,182,4,0,
75,18,201,4,0,
75,18,205,1,10,
75,18,207,4,0,
75,18,213,4,0,
75,18,214,4,0,
75,18,216,4,0,
75,18,218,4,0,
75,18,222,1,12,
75,18,237,4,0,
75,18,241,4,0,
75,18,263,4,0,
75,18,267,4,0,
75,18,280,4,0,
75,18,300,1,1,3
75,18,300,1,4,
75,18,317,4,0,
75,18,350,1,34,
75,18,360,4,0,
75,18,374,4,0,
75,18,397,1,1,4
75,18,397,1,6,
75,18,397,4,0,
75,18,444,1,54,
75,18,444,4,0,
75,18,446,1,30,
75,18,479,1,18,
75,18,479,4,0,
75,18,496,4,0,
75,18,523,1,22,
75,18,523,4,0,
75,18,590,4,0,
76,1,5,4,0,
76,1,25,4,0,
76,1,33,1,1,1
76,1,34,4,0,
76,1,36,4,0,
76,1,38,4,0,
76,1,63,4,0,
76,1,66,4,0,
76,1,68,4,0,
76,1,69,4,0,
76,1,70,4,0,
76,1,88,1,16,
76,1,89,1,36,
76,1,89,4,0,
76,1,90,4,0,
76,1,91,4,0,
76,1,92,4,0,
76,1,99,4,0,
76,1,102,4,0,
76,1,104,4,0,
76,1,106,1,29,
76,1,111,1,1,2
76,1,111,1,11,
76,1,117,4,0,
76,1,118,4,0,
76,1,120,1,21,
76,1,120,4,0,
76,1,126,4,0,
76,1,153,1,43,
76,1,153,4,0,
76,1,156,4,0,
76,1,157,4,0,
76,1,164,4,0,
76,2,5,4,0,
76,2,25,4,0,
76,2,33,1,1,1
76,2,34,4,0,
76,2,36,4,0,
76,2,38,4,0,
76,2,63,4,0,
76,2,66,4,0,
76,2,68,4,0,
76,2,69,4,0,
76,2,70,4,0,
76,2,88,1,16,
76,2,89,1,36,
76,2,89,4,0,
76,2,90,4,0,
76,2,91,4,0,
76,2,92,4,0,
76,2,99,4,0,
76,2,102,4,0,
76,2,104,4,0,
76,2,106,1,29,
76,2,111,1,1,2
76,2,111,1,11,
76,2,117,4,0,
76,2,118,4,0,
76,2,120,1,21,
76,2,120,4,0,
76,2,126,4,0,
76,2,153,1,43,
76,2,153,4,0,
76,2,156,4,0,
76,2,157,4,0,
76,2,164,4,0,
76,3,7,4,0,
76,3,29,4,0,
76,3,33,1,1,1
76,3,46,4,0,
76,3,63,4,0,
76,3,70,4,0,
76,3,88,1,1,3
76,3,88,1,11,
76,3,89,1,41,
76,3,89,4,0,
76,3,91,4,0,
76,3,92,4,0,
76,3,104,4,0,
76,3,106,1,27,
76,3,111,1,1,2
76,3,111,1,6,
76,3,111,4,0,
76,3,120,1,21,
76,3,126,4,0,
76,3,153,1,48,
76,3,156,4,0,
76,3,173,4,0,
76,3,174,4,0,
76,3,182,4,0,
76,3,189,4,0,
76,3,201,4,0,
76,3,203,4,0,
76,3,205,1,34,
76,3,205,4,0,
76,3,207,4,0,
76,3,210,4,0,
76,3,213,4,0,
76,3,214,4,0,
76,3,216,4,0,
76,3,218,4,0,
76,3,222,1,1,4
76,3,222,1,16,
76,3,223,4,0,
76,3,237,4,0,
76,3,241,4,0,
76,3,249,4,0,
76,4,7,4,0,
76,4,29,4,0,
76,4,33,1,1,1
76,4,46,4,0,
76,4,53,3,0,
76,4,63,4,0,
76,4,70,4,0,
76,4,88,1,1,3
76,4,88,1,11,
76,4,89,1,41,
76,4,89,4,0,
76,4,91,4,0,
76,4,92,4,0,
76,4,104,4,0,
76,4,106,1,27,
76,4,111,1,1,2
76,4,111,1,6,
76,4,111,4,0,
76,4,120,1,21,
76,4,126,4,0,
76,4,153,1,48,
76,4,156,4,0,
76,4,173,4,0,
76,4,174,4,0,
76,4,182,4,0,
76,4,189,4,0,
76,4,201,4,0,
76,4,203,4,0,
76,4,205,1,34,
76,4,205,4,0,
76,4,207,4,0,
76,4,210,4,0,
76,4,213,4,0,
76,4,214,4,0,
76,4,216,4,0,
76,4,218,4,0,
76,4,222,1,1,4
76,4,222,1,16,
76,4,223,4,0,
76,4,237,4,0,
76,4,241,4,0,
76,4,249,4,0,
76,5,33,1,1,1
76,5,38,1,62,
76,5,46,4,0,
76,5,53,4,0,
76,5,63,4,0,
76,5,70,4,0,
76,5,88,1,1,4
76,5,88,1,11,
76,5,89,1,45,
76,5,89,4,0,
76,5,91,4,0,
76,5,92,4,0,
76,5,104,4,0,
76,5,111,1,1,2
76,5,120,1,21,
76,5,126,4,0,
76,5,153,1,53,
76,5,156,4,0,
76,5,182,4,0,
76,5,201,4,0,
76,5,205,1,29,
76,5,213,4,0,
76,5,216,4,0,
76,5,218,4,0,
76,5,222,1,16,
76,5,237,4,0,
76,5,241,4,0,
76,5,249,4,0,
76,5,263,4,0,
76,5,264,4,0,
76,5,280,4,0,
76,5,290,4,0,
76,5,300,1,1,3
76,5,300,1,6,
76,5,317,4,0,
76,5,350,1,37,
76,6,5,3,0,
76,6,7,3,0,
76,6,25,3,0,
76,6,33,1,1,1
76,6,34,3,0,
76,6,38,1,62,
76,6,38,3,0,
76,6,46,4,0,
76,6,53,4,0,
76,6,63,4,0,
76,6,68,3,0,
76,6,69,3,0,
76,6,70,4,0,
76,6,88,1,1,4
76,6,88,1,11,
76,6,89,1,45,
76,6,89,4,0,
76,6,91,4,0,
76,6,92,4,0,
76,6,102,3,0,
76,6,104,4,0,
76,6,111,1,1,2
76,6,111,3,0,
76,6,118,3,0,
76,6,120,1,21,
76,6,126,4,0,
76,6,153,1,53,
76,6,153,3,0,
76,6,156,4,0,
76,6,157,3,0,
76,6,164,3,0,
76,6,173,3,0,
76,6,182,4,0,
76,6,189,3,0,
76,6,201,4,0,
76,6,203,3,0,
76,6,205,1,29,
76,6,205,3,0,
76,6,207,3,0,
76,6,210,3,0,
76,6,213,4,0,
76,6,214,3,0,
76,6,216,4,0,
76,6,218,4,0,
76,6,222,1,16,
76,6,223,3,0,
76,6,237,4,0,
76,6,241,4,0,
76,6,249,4,0,
76,6,263,4,0,
76,6,264,4,0,
76,6,280,4,0,
76,6,290,4,0,
76,6,300,1,1,3
76,6,300,1,6,
76,6,317,4,0,
76,6,350,1,37,
76,7,5,3,0,
76,7,25,3,0,
76,7,33,1,1,1
76,7,34,3,0,
76,7,38,1,62,
76,7,38,3,0,
76,7,46,4,0,
76,7,53,4,0,
76,7,63,4,0,
76,7,68,3,0,
76,7,69,3,0,
76,7,70,4,0,
76,7,88,1,1,4
76,7,88,1,11,
76,7,89,1,45,
76,7,89,4,0,
76,7,91,4,0,
76,7,92,4,0,
76,7,102,3,0,
76,7,104,4,0,
76,7,111,1,1,2
76,7,118,3,0,
76,7,120,1,21,
76,7,126,4,0,
76,7,153,1,53,
76,7,153,3,0,
76,7,156,4,0,
76,7,157,3,0,
76,7,164,3,0,
76,7,182,4,0,
76,7,201,4,0,
76,7,205,1,29,
76,7,213,4,0,
76,7,216,4,0,
76,7,218,4,0,
76,7,222,1,16,
76,7,237,4,0,
76,7,241,4,0,
76,7,249,4,0,
76,7,263,4,0,
76,7,264,4,0,
76,7,280,4,0,
76,7,290,4,0,
76,7,300,1,1,3
76,7,300,1,6,
76,7,317,4,0,
76,7,350,1,37,
76,8,33,1,1,1
76,8,38,1,44,
76,8,46,4,0,
76,8,53,4,0,
76,8,63,4,0,
76,8,70,4,0,
76,8,88,1,11,
76,8,89,1,33,
76,8,89,4,0,
76,8,91,4,0,
76,8,92,4,0,
76,8,104,4,0,
76,8,111,1,1,2
76,8,120,1,18,
76,8,126,4,0,
76,8,153,1,38,
76,8,153,4,0,
76,8,156,4,0,
76,8,157,4,0,
76,8,164,4,0,
76,8,182,4,0,
76,8,201,4,0,
76,8,203,4,0,
76,8,205,1,22,
76,8,207,4,0,
76,8,213,4,0,
76,8,214,4,0,
76,8,216,4,0,
76,8,218,4,0,
76,8,222,1,15,
76,8,237,4,0,
76,8,241,4,0,
76,8,249,4,0,
76,8,263,4,0,
76,8,264,4,0,
76,8,280,4,0,
76,8,290,4,0,
76,8,300,1,1,3
76,8,300,1,4,
76,8,317,4,0,
76,8,350,1,27,
76,8,360,4,0,
76,8,363,4,0,
76,8,374,4,0,
76,8,397,1,1,4
76,8,397,1,8,
76,8,397,4,0,
76,8,411,4,0,
76,8,416,4,0,
76,8,431,4,0,
76,8,444,1,49,
76,8,444,4,0,
76,8,445,4,0,
76,8,446,4,0,
76,9,7,3,0,
76,9,9,3,0,
76,9,33,1,1,1
76,9,38,1,44,
76,9,46,4,0,
76,9,53,4,0,
76,9,63,4,0,
76,9,70,4,0,
76,9,88,1,11,
76,9,89,1,33,
76,9,89,4,0,
76,9,91,4,0,
76,9,92,4,0,
76,9,104,4,0,
76,9,111,1,1,2
76,9,120,1,18,
76,9,126,4,0,
76,9,153,1,38,
76,9,153,4,0,
76,9,156,4,0,
76,9,157,4,0,
76,9,164,4,0,
76,9,173,3,0,
76,9,182,4,0,
76,9,189,3,0,
76,9,201,4,0,
76,9,203,4,0,
76,9,205,1,22,
76,9,205,3,0,
76,9,207,4,0,
76,9,210,3,0,
76,9,213,4,0,
76,9,214,4,0,
76,9,216,4,0,
76,9,218,4,0,
76,9,222,1,15,
76,9,237,4,0,
76,9,241,4,0,
76,9,246,3,0,
76,9,249,4,0,
76,9,263,4,0,
76,9,264,4,0,
76,9,276,3,0,
76,9,280,4,0,
76,9,290,4,0,
76,9,300,1,1,3
76,9,300,1,4,
76,9,317,4,0,
76,9,350,1,27,
76,9,360,4,0,
76,9,363,4,0,
76,9,374,4,0,
76,9,389,3,0,
76,9,397,1,1,4
76,9,397,1,8,
76,9,397,4,0,
76,9,411,4,0,
76,9,414,3,0,
76,9,416,4,0,
76,9,431,4,0,
76,9,442,3,0,
76,9,444,1,49,
76,9,444,4,0,
76,9,445,4,0,
76,9,446,4,0,
76,10,7,3,0,
76,10,9,3,0,
76,10,29,3,0,
76,10,33,1,1,1
76,10,38,1,44,
76,10,46,4,0,
76,10,53,4,0,
76,10,63,4,0,
76,10,70,4,0,
76,10,88,1,11,
76,10,89,1,33,
76,10,89,4,0,
76,10,91,4,0,
76,10,92,4,0,
76,10,104,4,0,
76,10,111,1,1,2
76,10,120,1,18,
76,10,126,4,0,
76,10,153,1,38,
76,10,153,4,0,
76,10,156,4,0,
76,10,157,4,0,
76,10,164,4,0,
76,10,173,3,0,
76,10,182,4,0,
76,10,189,3,0,
76,10,201,4,0,
76,10,203,4,0,
76,10,205,1,22,
76,10,205,3,0,
76,10,207,4,0,
76,10,210,3,0,
76,10,213,4,0,
76,10,214,4,0,
76,10,216,4,0,
76,10,218,4,0,
76,10,222,1,15,
76,10,237,4,0,
76,10,241,4,0,
76,10,246,3,0,
76,10,249,4,0,
76,10,263,4,0,
76,10,264,4,0,
76,10,276,3,0,
76,10,280,4,0,
76,10,290,4,0,
76,10,300,1,1,3
76,10,300,1,4,
76,10,317,4,0,
76,10,335,3,0,
76,10,350,1,27,
76,10,360,4,0,
76,10,363,4,0,
76,10,374,4,0,
76,10,389,3,0,
76,10,397,1,1,4
76,10,397,1,8,
76,10,397,4,0,
76,10,411,4,0,
76,10,414,3,0,
76,10,416,4,0,
76,10,431,4,0,
76,10,442,3,0,
76,10,444,1,49,
76,10,444,4,0,
76,10,445,4,0,
76,10,446,4,0,
76,11,33,1,1,1
76,11,38,1,58,
76,11,46,4,0,
76,11,53,4,0,
76,11,63,4,0,
76,11,70,4,0,
76,11,88,1,11,
76,11,89,1,47,
76,11,89,4,0,
76,11,91,4,0,
76,11,92,4,0,
76,11,104,4,0,
76,11,111,1,1,2
76,11,120,1,31,
76,11,126,4,0,
76,11,153,1,53,
76,11,153,4,0,
76,11,156,4,0,
76,11,157,4,0,
76,11,164,4,0,
76,11,182,4,0,
76,11,201,4,0,
76,11,207,4,0,
76,11,213,4,0,
76,11,216,4,0,
76,11,218,4,0,
76,11,222,1,15,
76,11,237,4,0,
76,11,241,4,0,
76,11,249,4,0,
76,11,263,4,0,
76,11,280,4,0,
76,11,300,1,1,3
76,11,300,1,4,
76,11,317,4,0,
76,11,350,1,22,
76,11,360,4,0,
76,11,374,4,0,
76,11,397,1,1,4
76,11,397,1,8,
76,11,397,4,0,
76,11,411,4,0,
76,11,416,4,0,
76,11,444,1,64,
76,11,444,4,0,
76,11,446,1,42,
76,11,479,1,27,
76,11,479,4,0,
76,11,484,1,69,
76,11,496,4,0,
76,11,510,4,0,
76,11,523,1,36,
76,11,523,4,0,
76,11,537,1,18,
76,12,33,1,1,1
76,12,38,1,62,
76,12,46,4,0,
76,12,53,4,0,
76,12,63,4,0,
76,12,70,4,0,
76,12,88,1,1,4
76,12,88,1,11,
76,12,89,1,45,
76,12,89,4,0,
76,12,91,4,0,
76,12,92,4,0,
76,12,104,4,0,
76,12,111,1,1,2
76,12,120,1,21,
76,12,126,4,0,
76,12,153,1,53,
76,12,156,4,0,
76,12,182,4,0,
76,12,201,4,0,
76,12,205,1,29,
76,12,213,4,0,
76,12,216,4,0,
76,12,218,4,0,
76,12,222,1,16,
76,12,237,4,0,
76,12,241,4,0,
76,12,249,4,0,
76,12,263,4,0,
76,12,264,4,0,
76,12,280,4,0,
76,12,290,4,0,
76,12,300,1,1,3
76,12,300,1,6,
76,12,317,4,0,
76,12,350,1,37,
76,13,33,1,1,1
76,13,34,3,0,
76,13,38,1,62,
76,13,38,3,0,
76,13,46,4,0,
76,13,53,4,0,
76,13,63,4,0,
76,13,69,3,0,
76,13,70,4,0,
76,13,88,1,1,4
76,13,88,1,11,
76,13,89,1,45,
76,13,89,4,0,
76,13,91,4,0,
76,13,92,4,0,
76,13,102,3,0,
76,13,104,4,0,
76,13,111,1,1,2
76,13,120,1,21,
76,13,120,3,0,
76,13,126,4,0,
76,13,153,1,53,
76,13,156,4,0,
76,13,164,3,0,
76,13,182,4,0,
76,13,201,4,0,
76,13,205,1,29,
76,13,207,3,0,
76,13,213,4,0,
76,13,216,4,0,
76,13,218,4,0,
76,13,222,1,16,
76,13,237,4,0,
76,13,241,4,0,
76,13,249,4,0,
76,13,263,4,0,
76,13,264,4,0,
76,13,280,4,0,
76,13,290,4,0,
76,13,300,1,1,3
76,13,300,1,6,
76,13,317,4,0,
76,13,350,1,37,
76,14,7,3,0,
76,14,9,3,0,
76,14,33,1,1,1
76,14,38,1,58,
76,14,46,4,0,
76,14,53,4,0,
76,14,63,4,0,
76,14,70,4,0,
76,14,88,1,11,
76,14,89,1,47,
76,14,89,4,0,
76,14,91,4,0,
76,14,92,4,0,
76,14,104,4,0,
76,14,111,1,1,2
76,14,120,1,31,
76,14,126,4,0,
76,14,153,1,53,
76,14,153,4,0,
76,14,156,4,0,
76,14,157,4,0,
76,14,164,4,0,
76,14,173,3,0,
76,14,182,4,0,
76,14,201,4,0,
76,14,207,4,0,
76,14,213,4,0,
76,14,214,3,0,
76,14,216,4,0,
76,14,218,4,0,
76,14,222,1,15,
76,14,237,4,0,
76,14,241,4,0,
76,14,249,4,0,
76,14,263,4,0,
76,14,276,3,0,
76,14,280,4,0,
76,14,300,1,1,3
76,14,300,1,4,
76,14,317,4,0,
76,14,334,3,0,
76,14,335,3,0,
76,14,350,1,22,
76,14,360,4,0,
76,14,374,4,0,
76,14,397,1,1,4
76,14,397,1,8,
76,14,397,4,0,
76,14,411,4,0,
76,14,414,3,0,
76,14,416,4,0,
76,14,442,3,0,
76,14,444,1,64,
76,14,444,4,0,
76,14,446,1,42,
76,14,446,3,0,
76,14,479,1,27,
76,14,479,4,0,
76,14,484,1,69,
76,14,496,4,0,
76,14,510,4,0,
76,14,523,1,36,
76,14,523,4,0,
76,14,537,1,18,
76,15,33,1,1,2
76,15,38,1,58,
76,15,46,4,0,
76,15,53,4,0,
76,15,63,4,0,
76,15,70,4,0,
76,15,88,1,18,
76,15,89,1,47,
76,15,89,4,0,
76,15,91,4,0,
76,15,92,4,0,
76,15,104,4,0,
76,15,111,1,1,3
76,15,120,1,31,
76,15,126,4,0,
76,15,153,1,53,
76,15,153,4,0,
76,15,156,4,0,
76,15,157,4,0,
76,15,164,4,0,
76,15,182,4,0,
76,15,201,4,0,
76,15,207,4,0,
76,15,213,4,0,
76,15,214,4,0,
76,15,216,4,0,
76,15,218,4,0,
76,15,222,1,15,
76,15,237,4,0,
76,15,241,4,0,
76,15,249,4,0,
76,15,263,4,0,
76,15,267,4,0,
76,15,280,4,0,
76,15,300,1,1,4
76,15,300,1,4,
76,15,317,4,0,
76,15,350,1,22,
76,15,360,4,0,
76,15,374,4,0,
76,15,397,1,1,5
76,15,397,1,8,
76,15,397,4,0,
76,15,411,4,0,
76,15,416,4,0,
76,15,444,1,64,
76,15,444,4,0,
76,15,446,1,42,
76,15,479,1,27,
76,15,479,4,0,
76,15,484,1,1,1
76,15,484,1,69,
76,15,496,4,0,
76,15,510,4,0,
76,15,523,1,36,
76,15,523,4,0,
76,15,537,1,11,
76,15,590,4,0,
76,15,612,4,0,
76,16,7,3,0,
76,16,9,3,0,
76,16,33,1,1,2
76,16,38,1,50,1
76,16,46,4,0,
76,16,53,4,0,
76,16,63,4,0,
76,16,70,4,0,
76,16,88,1,16,1
76,16,89,1,40,1
76,16,89,4,0,
76,16,91,4,0,
76,16,92,4,0,
76,16,104,4,0,
76,16,111,1,1,3
76,16,120,1,24,1
76,16,126,4,0,
76,16,153,1,44,1
76,16,153,4,0,
76,16,156,4,0,
76,16,157,4,0,
76,16,164,4,0,
76,16,173,3,0,
76,16,182,4,0,
76,16,201,4,0,
76,16,207,4,0,
76,16,213,4,0,
76,16,214,4,0,
76,16,216,4,0,
76,16,218,4,0,
76,16,222,1,12,1
76,16,237,4,0,
76,16,241,4,0,
76,16,249,4,0,
76,16,263,4,0,
76,16,264,3,0,
76,16,267,4,0,
76,16,276,3,0,
76,16,280,4,0,
76,16,290,4,0,
76,16,300,1,1,4
76,16,300,1,4,1
76,16,317,4,0,
76,16,334,3,0,
76,16,335,3,0,
76,16,350,1,34,1
76,16,360,4,0,
76,16,374,4,0,
76,16,397,1,1,5
76,16,397,1,6,1
76,16,397,4,0,
76,16,411,4,0,
76,16,414,3,0,
76,16,416,4,0,
76,16,442,3,0,
76,16,444,1,54,1
76,16,444,4,0,
76,16,446,1,30,1
76,16,446,3,0,
76,16,479,1,18,1
76,16,479,4,0,
76,16,484,1,1,1
76,16,484,1,60,1
76,16,496,4,0,
76,16,510,4,0,
76,16,523,1,22,1
76,16,523,4,0,
76,16,537,1,10,1
76,16,590,4,0,
76,16,612,4,0,
76,17,33,1,1,2
76,17,38,1,50,
76,17,46,4,0,
76,17,53,4,0,
76,17,63,4,0,
76,17,88,1,16,
76,17,89,1,40,
76,17,89,4,0,
76,17,92,4,0,
76,17,104,4,0,
76,17,111,1,1,3
76,17,120,1,24,
76,17,126,4,0,
76,17,153,1,44,
76,17,153,4,0,
76,17,156,4,0,
76,17,157,4,0,
76,17,164,4,0,
76,17,182,4,0,
76,17,201,4,0,
76,17,207,4,0,
76,17,213,4,0,
76,17,214,4,0,
76,17,216,4,0,
76,17,218,4,0,
76,17,222,1,12,
76,17,237,4,0,
76,17,241,4,0,
76,17,263,4,0,
76,17,267,4,0,
76,17,280,4,0,
76,17,300,1,1,4
76,17,300,1,4,
76,17,317,4,0,
76,17,350,1,34,
76,17,360,4,0,
76,17,374,4,0,
76,17,397,1,1,5
76,17,397,1,6,
76,17,397,4,0,
76,17,411,4,0,
76,17,416,4,0,
76,17,444,1,54,
76,17,444,4,0,
76,17,446,1,30,
76,17,479,1,18,
76,17,479,4,0,
76,17,484,1,1,1
76,17,484,1,60,
76,17,496,4,0,
76,17,523,1,22,
76,17,523,4,0,
76,17,537,1,10,
76,17,590,4,0,
76,18,33,1,1,2
76,18,38,1,50,
76,18,46,4,0,
76,18,53,4,0,
76,18,63,4,0,
76,18,88,1,16,
76,18,89,1,40,
76,18,89,4,0,
76,18,92,4,0,
76,18,104,4,0,
76,18,111,1,1,3
76,18,120,1,24,
76,18,126,4,0,
76,18,153,1,44,
76,18,153,4,0,
76,18,156,4,0,
76,18,157,4,0,
76,18,164,4,0,
76,18,182,4,0,
76,18,201,4,0,
76,18,207,4,0,
76,18,213,4,0,
76,18,214,4,0,
76,18,216,4,0,
76,18,218,4,0,
76,18,222,1,12,
76,18,237,4,0,
76,18,241,4,0,
76,18,263,4,0,
76,18,267,4,0,
76,18,280,4,0,
76,18,300,1,1,4
76,18,300,1,4,
76,18,317,4,0,
76,18,350,1,34,
76,18,360,4,0,
76,18,374,4,0,
76,18,397,1,1,5
76,18,397,1,6,
76,18,397,4,0,
76,18,411,4,0,
76,18,416,4,0,
76,18,444,1,54,
76,18,444,4,0,
76,18,446,1,30,
76,18,479,1,18,
76,18,479,4,0,
76,18,484,1,1,1
76,18,484,1,60,
76,18,496,4,0,
76,18,523,1,22,
76,18,523,4,0,
76,18,537,1,10,
76,18,590,4,0,
77,1,23,1,32,
77,1,32,4,0,
77,1,34,4,0,
77,1,36,1,43,
77,1,36,4,0,
77,1,38,4,0,
77,1,39,1,30,
77,1,45,1,35,
77,1,52,1,1,
77,1,83,1,39,
77,1,92,4,0,
77,1,97,1,48,
77,1,99,4,0,
77,1,102,4,0,
77,1,104,4,0,
77,1,115,4,0,
77,1,117,4,0,
77,1,126,4,0,
77,1,129,4,0,
77,1,130,4,0,
77,1,156,4,0,
77,1,164,4,0,
77,2,23,1,32,
77,2,32,4,0,
77,2,34,4,0,
77,2,36,1,43,
77,2,36,4,0,
77,2,38,4,0,
77,2,39,1,30,
77,2,45,1,35,
77,2,52,1,1,
77,2,83,1,39,
77,2,92,4,0,
77,2,97,1,48,
77,2,99,4,0,
77,2,102,4,0,
77,2,104,4,0,
77,2,115,4,0,
77,2,117,4,0,
77,2,126,4,0,
77,2,129,4,0,
77,2,130,4,0,
77,2,156,4,0,
77,2,164,4,0,
77,3,23,1,19,
77,3,24,2,0,
77,3,29,4,0,
77,3,33,1,1,
77,3,36,1,34,
77,3,37,2,0,
77,3,39,1,8,
77,3,45,1,4,
77,3,52,1,13,
77,3,83,1,26,
77,3,92,4,0,
77,3,95,2,0,
77,3,97,1,43,
77,3,98,2,0,
77,3,104,4,0,
77,3,126,1,53,
77,3,126,4,0,
77,3,129,4,0,
77,3,156,4,0,
77,3,172,2,0,
77,3,173,4,0,
77,3,174,4,0,
77,3,182,4,0,
77,3,203,4,0,
77,3,204,2,0,
77,3,207,4,0,
77,3,213,4,0,
77,3,214,4,0,
77,3,216,4,0,
77,3,218,4,0,
77,3,231,4,0,
77,3,237,4,0,
77,3,241,4,0,
77,4,23,1,19,
77,4,24,2,0,
77,4,29,4,0,
77,4,33,1,1,
77,4,36,1,34,
77,4,37,2,0,
77,4,39,1,8,
77,4,45,1,4,
77,4,52,1,13,
77,4,53,3,0,
77,4,83,1,26,
77,4,92,4,0,
77,4,95,2,0,
77,4,97,1,43,
77,4,98,2,0,
77,4,104,4,0,
77,4,126,1,53,
77,4,126,4,0,
77,4,129,4,0,
77,4,156,4,0,
77,4,172,2,0,
77,4,173,4,0,
77,4,174,4,0,
77,4,182,4,0,
77,4,203,4,0,
77,4,204,2,0,
77,4,207,4,0,
77,4,213,4,0,
77,4,214,4,0,
77,4,216,4,0,
77,4,218,4,0,
77,4,231,4,0,
77,4,237,4,0,
77,4,241,4,0,
77,5,23,1,19,
77,5,24,2,0,
77,5,33,1,1,
77,5,36,1,31,
77,5,37,2,0,
77,5,38,2,0,
77,5,39,1,9,
77,5,45,1,5,
77,5,52,1,14,
77,5,53,4,0,
77,5,70,4,0,
77,5,76,4,0,
77,5,83,1,25,
77,5,92,4,0,
77,5,95,2,0,
77,5,97,1,38,
77,5,104,4,0,
77,5,126,1,53,
77,5,126,4,0,
77,5,156,4,0,
77,5,172,2,0,
77,5,182,4,0,
77,5,204,2,0,
77,5,213,4,0,
77,5,216,4,0,
77,5,218,4,0,
77,5,231,4,0,
77,5,237,4,0,
77,5,241,4,0,
77,5,263,4,0,
77,5,290,4,0,
77,5,315,4,0,
77,5,340,1,45,
77,6,23,1,19,
77,6,24,2,0,
77,6,33,1,1,
77,6,34,3,0,
77,6,36,1,31,
77,6,37,2,0,
77,6,38,2,0,
77,6,38,3,0,
77,6,39,1,9,
77,6,45,1,5,
77,6,52,1,14,
77,6,53,4,0,
77,6,70,4,0,
77,6,76,4,0,
77,6,83,1,25,
77,6,92,4,0,
77,6,95,2,0,
77,6,97,1,38,
77,6,102,3,0,
77,6,104,4,0,
77,6,126,1,53,
77,6,126,4,0,
77,6,129,3,0,
77,6,156,4,0,
77,6,164,3,0,
77,6,172,2,0,
77,6,173,3,0,
77,6,182,4,0,
77,6,203,3,0,
77,6,204,2,0,
77,6,207,3,0,
77,6,213,4,0,
77,6,214,3,0,
77,6,216,4,0,
77,6,218,4,0,
77,6,231,4,0,
77,6,237,4,0,
77,6,241,4,0,
77,6,263,4,0,
77,6,290,4,0,
77,6,315,4,0,
77,6,340,1,45,
77,7,23,1,19,
77,7,24,2,0,
77,7,34,3,0,
77,7,36,1,31,
77,7,37,2,0,
77,7,38,2,0,
77,7,38,3,0,
77,7,39,1,9,
77,7,45,1,5,
77,7,52,1,14,
77,7,53,4,0,
77,7,70,4,0,
77,7,76,4,0,
77,7,83,1,25,
77,7,92,4,0,
77,7,95,2,0,
77,7,97,1,38,
77,7,98,1,1,
77,7,102,3,0,
77,7,104,4,0,
77,7,126,1,53,
77,7,126,4,0,
77,7,156,4,0,
77,7,164,3,0,
77,7,172,2,0,
77,7,182,4,0,
77,7,204,2,0,
77,7,213,4,0,
77,7,216,4,0,
77,7,218,4,0,
77,7,231,4,0,
77,7,237,4,0,
77,7,241,4,0,
77,7,263,4,0,
77,7,290,4,0,
77,7,315,4,0,
77,7,340,1,45,
77,8,23,1,19,
77,8,24,2,0,
77,8,32,2,0,
77,8,33,1,1,
77,8,36,1,28,
77,8,37,2,0,
77,8,38,2,0,
77,8,39,1,10,
77,8,45,1,7,
77,8,52,1,16,
77,8,53,4,0,
77,8,70,4,0,
77,8,76,4,0,
77,8,83,1,25,
77,8,92,4,0,
77,8,95,2,0,
77,8,97,1,34,
77,8,104,4,0,
77,8,126,1,38,
77,8,126,4,0,
77,8,156,4,0,
77,8,164,4,0,
77,8,172,2,0,
77,8,182,4,0,
77,8,203,4,0,
77,8,204,2,0,
77,8,207,4,0,
77,8,213,4,0,
77,8,214,4,0,
77,8,216,4,0,
77,8,218,4,0,
77,8,231,4,0,
77,8,237,4,0,
77,8,241,4,0,
77,8,261,4,0,
77,8,263,4,0,
77,8,290,4,0,
77,8,315,4,0,
77,8,340,1,44,
77,8,363,4,0,
77,8,394,1,48,
77,8,445,4,0,
77,9,23,1,19,
77,9,24,2,0,
77,9,32,2,0,
77,9,33,1,1,2
77,9,36,1,28,
77,9,37,2,0,
77,9,38,2,0,
77,9,39,1,6,
77,9,45,1,1,1
77,9,52,1,10,
77,9,53,4,0,
77,9,70,4,0,
77,9,76,4,0,
77,9,83,1,24,
77,9,92,4,0,
77,9,95,2,0,
77,9,97,1,33,
77,9,104,4,0,
77,9,126,1,37,
77,9,126,4,0,
77,9,129,3,0,
77,9,156,4,0,
77,9,164,4,0,
77,9,172,1,15,
77,9,172,2,0,
77,9,173,3,0,
77,9,182,4,0,
77,9,203,4,0,
77,9,204,2,0,
77,9,207,4,0,
77,9,213,4,0,
77,9,214,4,0,
77,9,216,4,0,
77,9,218,4,0,
77,9,231,4,0,
77,9,237,4,0,
77,9,241,4,0,
77,9,257,3,0,
77,9,261,4,0,
77,9,263,4,0,
77,9,290,4,0,
77,9,315,4,0,
77,9,340,1,42,
77,9,340,3,0,
77,9,363,4,0,
77,9,394,1,46,
77,9,445,4,0,
77,10,23,1,19,
77,10,24,2,0,
77,10,29,3,0,
77,10,32,2,0,
77,10,33,1,1,2
77,10,36,1,28,
77,10,37,2,0,
77,10,38,2,0,
77,10,39,1,6,
77,10,45,1,1,1
77,10,52,1,10,
77,10,53,4,0,
77,10,70,4,0,
77,10,76,4,0,
77,10,83,1,24,
77,10,92,4,0,
77,10,95,2,0,
77,10,97,1,33,
77,10,104,4,0,
77,10,126,1,37,
77,10,126,4,0,
77,10,129,3,0,
77,10,156,4,0,
77,10,164,4,0,
77,10,172,1,15,
77,10,172,2,0,
77,10,173,3,0,
77,10,182,4,0,
77,10,203,4,0,
77,10,204,2,0,
77,10,207,4,0,
77,10,213,4,0,
77,10,214,4,0,
77,10,216,4,0,
77,10,218,4,0,
77,10,231,4,0,
77,10,234,2,0,
77,10,237,4,0,
77,10,241,4,0,
77,10,257,3,0,
77,10,261,4,0,
77,10,263,4,0,
77,10,290,4,0,
77,10,315,4,0,
77,10,340,1,42,
77,10,340,3,0,
77,10,363,4,0,
77,10,394,1,46,
77,10,445,4,0,
77,11,23,1,17,
77,11,24,2,0,
77,11,32,2,0,
77,11,33,1,1,2
77,11,36,1,29,
77,11,37,2,0,
77,11,38,2,0,
77,11,39,1,4,
77,11,45,1,1,1
77,11,52,1,9,
77,11,53,4,0,
77,11,67,2,0,
77,11,70,4,0,
77,11,76,4,0,
77,11,83,1,25,
77,11,92,4,0,
77,11,95,2,0,
77,11,97,1,37,
77,11,104,4,0,
77,11,126,1,41,
77,11,126,4,0,
77,11,156,4,0,
77,11,164,4,0,
77,11,172,1,13,
77,11,172,2,0,
77,11,182,4,0,
77,11,204,2,0,
77,11,207,4,0,
77,11,213,4,0,
77,11,216,4,0,
77,11,218,4,0,
77,11,234,2,0,
77,11,237,4,0,
77,11,241,4,0,
77,11,261,4,0,
77,11,263,4,0,
77,11,315,4,0,
77,11,340,1,45,
77,11,394,1,49,
77,11,445,2,0,
77,11,488,1,21,
77,11,488,4,0,
77,11,496,4,0,
77,11,497,4,0,
77,11,510,4,0,
77,11,517,1,33,
77,11,528,4,0,
77,12,23,1,19,
77,12,33,1,1,
77,12,36,1,31,
77,12,39,1,9,
77,12,45,1,5,
77,12,52,1,14,
77,12,53,4,0,
77,12,70,4,0,
77,12,76,4,0,
77,12,83,1,25,
77,12,92,4,0,
77,12,97,1,38,
77,12,104,4,0,
77,12,126,1,53,
77,12,126,4,0,
77,12,156,4,0,
77,12,182,4,0,
77,12,213,4,0,
77,12,216,4,0,
77,12,218,4,0,
77,12,231,4,0,
77,12,237,4,0,
77,12,241,4,0,
77,12,263,4,0,
77,12,290,4,0,
77,12,315,4,0,
77,12,340,1,45,
77,13,23,1,19,
77,13,33,1,1,
77,13,34,3,0,
77,13,36,1,31,
77,13,38,3,0,
77,13,39,1,9,
77,13,45,1,5,
77,13,52,1,14,
77,13,53,4,0,
77,13,70,4,0,
77,13,76,4,0,
77,13,83,1,25,
77,13,92,4,0,
77,13,97,1,38,
77,13,102,3,0,
77,13,104,4,0,
77,13,126,1,53,
77,13,126,4,0,
77,13,156,4,0,
77,13,164,3,0,
77,13,182,4,0,
77,13,207,3,0,
77,13,213,4,0,
77,13,216,4,0,
77,13,218,4,0,
77,13,231,4,0,
77,13,237,4,0,
77,13,241,4,0,
77,13,263,4,0,
77,13,290,4,0,
77,13,315,4,0,
77,13,340,1,45,
77,14,23,1,17,
77,14,24,2,0,
77,14,32,2,0,
77,14,33,1,1,2
77,14,36,1,29,
77,14,37,2,0,
77,14,38,2,0,
77,14,39,1,4,
77,14,45,1,1,1
77,14,52,1,9,
77,14,53,4,0,
77,14,67,2,0,
77,14,67,3,0,
77,14,70,4,0,
77,14,76,4,0,
77,14,83,1,25,
77,14,92,4,0,
77,14,95,2,0,
77,14,97,1,37,
77,14,104,4,0,
77,14,126,1,41,
77,14,126,4,0,
77,14,156,4,0,
77,14,164,4,0,
77,14,172,1,13,
77,14,172,2,0,
77,14,173,3,0,
77,14,182,4,0,
77,14,204,2,0,
77,14,207,4,0,
77,14,213,4,0,
77,14,214,3,0,
77,14,216,4,0,
77,14,218,4,0,
77,14,231,3,0,
77,14,234,2,0,
77,14,237,4,0,
77,14,241,4,0,
77,14,257,3,0,
77,14,261,4,0,
77,14,263,4,0,
77,14,315,4,0,
77,14,340,1,45,
77,14,340,3,0,
77,14,394,1,49,
77,14,445,2,0,
77,14,488,1,21,
77,14,488,4,0,
77,14,496,4,0,
77,14,497,4,0,
77,14,510,4,0,
77,14,517,1,33,
77,14,528,4,0,
77,15,23,1,17,
77,15,24,2,0,
77,15,32,2,0,
77,15,33,1,1,2
77,15,36,1,29,
77,15,37,2,0,
77,15,38,2,0,
77,15,39,1,4,
77,15,45,1,1,1
77,15,52,1,9,
77,15,53,4,0,
77,15,67,2,0,
77,15,70,4,0,
77,15,76,4,0,
77,15,83,1,25,
77,15,92,4,0,
77,15,95,2,0,
77,15,97,1,37,
77,15,104,4,0,
77,15,126,1,41,
77,15,126,4,0,
77,15,156,4,0,
77,15,164,4,0,
77,15,172,1,13,
77,15,172,2,0,
77,15,182,4,0,
77,15,204,2,0,
77,15,207,4,0,
77,15,213,4,0,
77,15,214,4,0,
77,15,216,4,0,
77,15,218,4,0,
77,15,234,2,0,
77,15,237,4,0,
77,15,241,4,0,
77,15,261,4,0,
77,15,263,4,0,
77,15,315,4,0,
77,15,340,1,45,
77,15,394,1,49,
77,15,445,2,0,
77,15,488,1,21,
77,15,488,4,0,
77,15,496,4,0,
77,15,497,4,0,
77,15,502,2,0,
77,15,510,4,0,
77,15,517,1,33,
77,15,528,4,0,
77,15,590,4,0,
77,16,23,1,17,1
77,16,24,2,0,
77,16,32,2,0,
77,16,33,1,1,2
77,16,36,1,29,1
77,16,37,2,0,
77,16,38,2,0,
77,16,39,1,4,1
77,16,45,1,1,1
77,16,52,1,9,1
77,16,53,4,0,
77,16,67,2,0,
77,16,67,3,0,
77,16,70,4,0,
77,16,76,4,0,
77,16,83,1,25,1
77,16,92,4,0,
77,16,95,2,0,
77,16,97,1,37,1
77,16,104,4,0,
77,16,126,1,41,1
77,16,126,4,0,
77,16,156,4,0,
77,16,164,4,0,
77,16,172,1,13,1
77,16,172,2,0,
77,16,173,3,0,
77,16,182,4,0,
77,16,204,2,0,
77,16,207,4,0,
77,16,213,4,0,
77,16,214,4,0,
77,16,216,4,0,
77,16,218,4,0,
77,16,231,3,0,
77,16,234,2,0,
77,16,237,4,0,
77,16,241,4,0,
77,16,257,3,0,
77,16,261,4,0,
77,16,263,4,0,
77,16,290,4,0,
77,16,315,4,0,
77,16,340,1,45,1
77,16,340,3,0,
77,16,394,1,49,1
77,16,445,2,0,
77,16,488,1,21,1
77,16,488,4,0,
77,16,496,4,0,
77,16,497,4,0,
77,16,502,2,0,
77,16,510,4,0,
77,16,517,1,33,1
77,16,528,4,0,
77,16,590,4,0,
77,17,23,1,17,
77,17,24,2,0,
77,17,32,2,0,
77,17,33,1,1,2
77,17,36,1,29,
77,17,37,2,0,
77,17,38,2,0,
77,17,39,1,4,
77,17,45,1,1,1
77,17,52,1,9,
77,17,53,4,0,
77,17,67,2,0,
77,17,76,4,0,
77,17,83,1,25,
77,17,92,4,0,
77,17,95,2,0,
77,17,97,1,37,
77,17,104,4,0,
77,17,126,1,41,
77,17,126,4,0,
77,17,156,4,0,
77,17,164,4,0,
77,17,172,1,13,
77,17,172,2,0,
77,17,182,4,0,
77,17,204,2,0,
77,17,207,4,0,
77,17,213,4,0,
77,17,214,4,0,
77,17,216,4,0,
77,17,218,4,0,
77,17,234,2,0,
77,17,237,4,0,
77,17,241,4,0,
77,17,261,4,0,
77,17,263,4,0,
77,17,315,4,0,
77,17,340,1,45,
77,17,394,1,49,
77,17,445,2,0,
77,17,488,1,21,
77,17,488,4,0,
77,17,496,4,0,
77,17,497,4,0,
77,17,502,2,0,
77,17,517,1,33,
77,17,528,4,0,
77,17,590,4,0,
77,18,23,1,17,
77,18,24,2,0,
77,18,32,2,0,
77,18,33,1,1,2
77,18,36,1,29,
77,18,37,2,0,
77,18,38,2,0,
77,18,39,1,4,
77,18,45,1,1,1
77,18,52,1,9,
77,18,53,4,0,
77,18,67,2,0,
77,18,76,4,0,
77,18,83,1,25,
77,18,92,4,0,
77,18,95,2,0,
77,18,97,1,37,
77,18,104,4,0,
77,18,126,1,41,
77,18,126,4,0,
77,18,156,4,0,
77,18,164,4,0,
77,18,172,1,13,
77,18,172,2,0,
77,18,182,4,0,
77,18,204,2,0,
77,18,207,4,0,
77,18,213,4,0,
77,18,214,4,0,
77,18,216,4,0,
77,18,218,4,0,
77,18,234,2,0,
77,18,237,4,0,
77,18,241,4,0,
77,18,261,4,0,
77,18,263,4,0,
77,18,315,4,0,
77,18,340,1,45,
77,18,394,1,49,
77,18,445,2,0,
77,18,488,1,21,
77,18,488,4,0,
77,18,496,4,0,
77,18,497,4,0,
77,18,502,2,0,
77,18,517,1,33,
77,18,528,4,0,
77,18,590,4,0,
77,18,667,2,0,
78,1,23,1,1,3
78,1,23,1,32,
78,1,32,4,0,
78,1,34,4,0,
78,1,36,1,47,
78,1,36,4,0,
78,1,38,4,0,
78,1,39,1,1,2
78,1,39,1,30,
78,1,45,1,1,4
78,1,45,1,35,
78,1,52,1,1,1
78,1,63,4,0,
78,1,83,1,39,
78,1,92,4,0,
78,1,97,1,55,
78,1,99,4,0,
78,1,102,4,0,
78,1,104,4,0,
78,1,115,4,0,
78,1,117,4,0,
78,1,126,4,0,
78,1,129,4,0,
78,1,130,4,0,
78,1,156,4,0,
78,1,164,4,0,
78,2,23,1,1,3
78,2,23,1,32,
78,2,32,4,0,
78,2,34,4,0,
78,2,36,1,47,
78,2,36,4,0,
78,2,38,4,0,
78,2,39,1,1,2
78,2,39,1,30,
78,2,45,1,1,4
78,2,45,1,35,
78,2,52,1,1,1
78,2,63,4,0,
78,2,83,1,39,
78,2,92,4,0,
78,2,97,1,55,
78,2,99,4,0,
78,2,102,4,0,
78,2,104,4,0,
78,2,115,4,0,
78,2,117,4,0,
78,2,126,4,0,
78,2,129,4,0,
78,2,130,4,0,
78,2,156,4,0,
78,2,164,4,0,
78,3,23,1,19,
78,3,29,4,0,
78,3,31,1,40,
78,3,33,1,1,1
78,3,36,1,34,
78,3,39,1,1,3
78,3,39,1,8,
78,3,45,1,1,2
78,3,45,1,4,
78,3,52,1,1,4
78,3,52,1,13,
78,3,63,4,0,
78,3,83,1,26,
78,3,92,4,0,
78,3,97,1,47,
78,3,104,4,0,
78,3,126,1,61,
78,3,126,4,0,
78,3,129,4,0,
78,3,156,4,0,
78,3,173,4,0,
78,3,174,4,0,
78,3,182,4,0,
78,3,203,4,0,
78,3,207,4,0,
78,3,213,4,0,
78,3,214,4,0,
78,3,216,4,0,
78,3,218,4,0,
78,3,231,4,0,
78,3,237,4,0,
78,3,241,4,0,
78,4,23,1,19,
78,4,29,4,0,
78,4,31,1,40,
78,4,33,1,1,1
78,4,36,1,34,
78,4,39,1,1,3
78,4,39,1,8,
78,4,45,1,1,2
78,4,45,1,4,
78,4,52,1,1,4
78,4,52,1,13,
78,4,53,3,0,
78,4,63,4,0,
78,4,83,1,26,
78,4,92,4,0,
78,4,97,1,47,
78,4,104,4,0,
78,4,126,1,61,
78,4,126,4,0,
78,4,129,4,0,
78,4,156,4,0,
78,4,173,4,0,
78,4,174,4,0,
78,4,182,4,0,
78,4,203,4,0,
78,4,207,4,0,
78,4,213,4,0,
78,4,214,4,0,
78,4,216,4,0,
78,4,218,4,0,
78,4,231,4,0,
78,4,237,4,0,
78,4,241,4,0,
78,5,23,1,19,
78,5,31,1,40,
78,5,33,1,1,1
78,5,36,1,31,
78,5,39,1,1,3
78,5,39,1,9,
78,5,45,1,1,2
78,5,45,1,5,
78,5,52,1,1,4
78,5,52,1,14,
78,5,53,4,0,
78,5,63,4,0,
78,5,70,4,0,
78,5,76,4,0,
78,5,83,1,25,
78,5,92,4,0,
78,5,97,1,38,
78,5,104,4,0,
78,5,126,1,63,
78,5,126,4,0,
78,5,156,4,0,
78,5,182,4,0,
78,5,213,4,0,
78,5,216,4,0,
78,5,218,4,0,
78,5,231,4,0,
78,5,237,4,0,
78,5,241,4,0,
78,5,263,4,0,
78,5,290,4,0,
78,5,315,4,0,
78,5,340,1,50,
78,6,23,1,19,
78,6,31,1,40,
78,6,33,1,1,1
78,6,34,3,0,
78,6,36,1,31,
78,6,38,3,0,
78,6,39,1,1,3
78,6,39,1,9,
78,6,45,1,1,2
78,6,45,1,5,
78,6,52,1,1,4
78,6,52,1,14,
78,6,53,4,0,
78,6,63,4,0,
78,6,70,4,0,
78,6,76,4,0,
78,6,83,1,25,
78,6,92,4,0,
78,6,97,1,38,
78,6,102,3,0,
78,6,104,4,0,
78,6,126,1,63,
78,6,126,4,0,
78,6,129,3,0,
78,6,156,4,0,
78,6,164,3,0,
78,6,173,3,0,
78,6,182,4,0,
78,6,203,3,0,
78,6,207,3,0,
78,6,213,4,0,
78,6,214,3,0,
78,6,216,4,0,
78,6,218,4,0,
78,6,231,4,0,
78,6,237,4,0,
78,6,241,4,0,
78,6,263,4,0,
78,6,290,4,0,
78,6,315,4,0,
78,6,340,1,50,
78,7,23,1,19,
78,7,31,1,40,
78,7,34,3,0,
78,7,36,1,31,
78,7,38,3,0,
78,7,39,1,1,3
78,7,39,1,9,
78,7,45,1,1,2
78,7,45,1,5,
78,7,52,1,1,4
78,7,52,1,14,
78,7,53,4,0,
78,7,63,4,0,
78,7,70,4,0,
78,7,76,4,0,
78,7,83,1,25,
78,7,92,4,0,
78,7,97,1,38,
78,7,98,1,1,1
78,7,102,3,0,
78,7,104,4,0,
78,7,126,1,63,
78,7,126,4,0,
78,7,156,4,0,
78,7,164,3,0,
78,7,182,4,0,
78,7,213,4,0,
78,7,216,4,0,
78,7,218,4,0,
78,7,231,4,0,
78,7,237,4,0,
78,7,241,4,0,
78,7,263,4,0,
78,7,290,4,0,
78,7,315,4,0,
78,7,340,1,50,
78,8,23,1,19,
78,8,31,1,40,
78,8,36,1,28,
78,8,39,1,1,5
78,8,39,1,10,
78,8,45,1,1,4
78,8,45,1,7,
78,8,52,1,1,6
78,8,52,1,16,
78,8,53,4,0,
78,8,63,4,0,
78,8,70,4,0,
78,8,76,4,0,
78,8,83,1,25,
78,8,92,4,0,
78,8,97,1,34,
78,8,98,1,1,3
78,8,104,4,0,
78,8,126,1,38,
78,8,126,4,0,
78,8,156,4,0,
78,8,164,4,0,
78,8,182,4,0,
78,8,203,4,0,
78,8,207,4,0,
78,8,213,4,0,
78,8,214,4,0,
78,8,216,4,0,
78,8,218,4,0,
78,8,224,1,1,2
78,8,231,4,0,
78,8,237,4,0,
78,8,241,4,0,
78,8,261,4,0,
78,8,263,4,0,
78,8,290,4,0,
78,8,315,4,0,
78,8,340,1,49,
78,8,363,4,0,
78,8,394,1,58,
78,8,398,1,1,1
78,8,398,4,0,
78,8,416,4,0,
78,8,445,4,0,
78,9,23,1,19,
78,9,31,1,40,
78,9,36,1,28,
78,9,39,1,1,5
78,9,39,1,6,
78,9,45,1,1,3
78,9,52,1,1,6
78,9,52,1,10,
78,9,53,4,0,
78,9,63,4,0,
78,9,70,4,0,
78,9,76,4,0,
78,9,83,1,24,
78,9,92,4,0,
78,9,97,1,33,
78,9,98,1,1,4
78,9,104,4,0,
78,9,126,1,37,
78,9,126,4,0,
78,9,129,3,0,
78,9,156,4,0,
78,9,164,4,0,
78,9,172,1,15,
78,9,173,3,0,
78,9,182,4,0,
78,9,203,4,0,
78,9,207,4,0,
78,9,213,4,0,
78,9,214,4,0,
78,9,216,4,0,
78,9,218,4,0,
78,9,224,1,1,2
78,9,231,4,0,
78,9,237,4,0,
78,9,241,4,0,
78,9,257,3,0,
78,9,261,4,0,
78,9,263,4,0,
78,9,290,4,0,
78,9,315,4,0,
78,9,340,1,47,
78,9,340,3,0,
78,9,363,4,0,
78,9,394,1,56,
78,9,398,1,1,1
78,9,398,4,0,
78,9,416,4,0,
78,9,445,4,0,
78,10,23,1,19,
78,10,29,3,0,
78,10,31,1,40,
78,10,36,1,28,
78,10,39,1,1,5
78,10,39,1,6,
78,10,45,1,1,3
78,10,52,1,1,6
78,10,52,1,10,
78,10,53,4,0,
78,10,63,4,0,
78,10,70,4,0,
78,10,76,4,0,
78,10,83,1,24,
78,10,92,4,0,
78,10,97,1,33,
78,10,98,1,1,4
78,10,104,4,0,
78,10,126,1,37,
78,10,126,4,0,
78,10,129,3,0,
78,10,156,4,0,
78,10,164,4,0,
78,10,172,1,15,
78,10,173,3,0,
78,10,182,4,0,
78,10,203,4,0,
78,10,207,4,0,
78,10,213,4,0,
78,10,214,4,0,
78,10,216,4,0,
78,10,218,4,0,
78,10,224,1,1,2
78,10,231,4,0,
78,10,237,4,0,
78,10,241,4,0,
78,10,257,3,0,
78,10,261,4,0,
78,10,263,4,0,
78,10,290,4,0,
78,10,315,4,0,
78,10,340,1,47,
78,10,340,3,0,
78,10,363,4,0,
78,10,394,1,56,
78,10,398,1,1,1
78,10,398,4,0,
78,10,416,4,0,
78,10,445,4,0,
78,11,23,1,17,
78,11,31,1,40,
78,11,36,1,29,
78,11,39,1,1,5
78,11,39,1,4,
78,11,45,1,1,3
78,11,52,1,1,6
78,11,52,1,9,
78,11,53,4,0,
78,11,63,4,0,
78,11,70,4,0,
78,11,76,4,0,
78,11,83,1,25,
78,11,92,4,0,
78,11,97,1,37,
78,11,98,1,1,4
78,11,104,4,0,
78,11,126,1,41,
78,11,126,4,0,
78,11,156,4,0,
78,11,164,4,0,
78,11,172,1,13,
78,11,182,4,0,
78,11,207,4,0,
78,11,213,4,0,
78,11,216,4,0,
78,11,218,4,0,
78,11,224,1,1,2
78,11,237,4,0,
78,11,241,4,0,
78,11,261,4,0,
78,11,263,4,0,
78,11,315,4,0,
78,11,340,1,45,
78,11,394,1,49,
78,11,398,1,1,1
78,11,398,4,0,
78,11,416,4,0,
78,11,488,1,21,
78,11,488,4,0,
78,11,496,4,0,
78,11,497,4,0,
78,11,510,4,0,
78,11,517,1,33,
78,11,528,4,0,
78,12,23,1,19,
78,12,31,1,40,
78,12,33,1,1,1
78,12,36,1,31,
78,12,39,1,1,3
78,12,39,1,9,
78,12,45,1,1,2
78,12,45,1,5,
78,12,52,1,1,4
78,12,52,1,14,
78,12,53,4,0,
78,12,63,4,0,
78,12,70,4,0,
78,12,76,4,0,
78,12,83,1,25,
78,12,92,4,0,
78,12,97,1,38,
78,12,104,4,0,
78,12,126,1,63,
78,12,126,4,0,
78,12,156,4,0,
78,12,182,4,0,
78,12,213,4,0,
78,12,216,4,0,
78,12,218,4,0,
78,12,231,4,0,
78,12,237,4,0,
78,12,241,4,0,
78,12,263,4,0,
78,12,290,4,0,
78,12,315,4,0,
78,12,340,1,50,
78,13,23,1,19,
78,13,31,1,40,
78,13,33,1,1,1
78,13,34,3,0,
78,13,36,1,31,
78,13,38,3,0,
78,13,39,1,1,3
78,13,39,1,9,
78,13,45,1,1,2
78,13,45,1,5,
78,13,52,1,1,4
78,13,52,1,14,
78,13,53,4,0,
78,13,63,4,0,
78,13,70,4,0,
78,13,76,4,0,
78,13,83,1,25,
78,13,92,4,0,
78,13,97,1,38,
78,13,102,3,0,
78,13,104,4,0,
78,13,126,1,63,
78,13,126,4,0,
78,13,156,4,0,
78,13,164,3,0,
78,13,182,4,0,
78,13,207,3,0,
78,13,213,4,0,
78,13,216,4,0,
78,13,218,4,0,
78,13,231,4,0,
78,13,237,4,0,
78,13,241,4,0,
78,13,263,4,0,
78,13,290,4,0,
78,13,315,4,0,
78,13,340,1,50,
78,14,23,1,17,
78,14,31,1,40,
78,14,36,1,29,
78,14,39,1,1,5
78,14,39,1,4,
78,14,45,1,1,3
78,14,52,1,1,6
78,14,52,1,9,
78,14,53,4,0,
78,14,63,4,0,
78,14,67,3,0,
78,14,70,4,0,
78,14,76,4,0,
78,14,83,1,25,
78,14,92,4,0,
78,14,97,1,37,
78,14,98,1,1,4
78,14,104,4,0,
78,14,126,1,41,
78,14,126,4,0,
78,14,156,4,0,
78,14,164,4,0,
78,14,172,1,13,
78,14,173,3,0,
78,14,182,4,0,
78,14,207,4,0,
78,14,213,4,0,
78,14,214,3,0,
78,14,216,4,0,
78,14,218,4,0,
78,14,224,1,1,2
78,14,231,3,0,
78,14,237,4,0,
78,14,241,4,0,
78,14,257,3,0,
78,14,261,4,0,
78,14,263,4,0,
78,14,315,4,0,
78,14,340,1,45,
78,14,340,3,0,
78,14,394,1,49,
78,14,398,1,1,1
78,14,398,4,0,
78,14,416,4,0,
78,14,488,1,21,
78,14,488,4,0,
78,14,496,4,0,
78,14,497,4,0,
78,14,510,4,0,
78,14,517,1,33,
78,14,528,4,0,
78,14,529,3,0,
78,15,23,1,17,
78,15,31,1,40,
78,15,36,1,29,
78,15,39,1,1,5
78,15,39,1,4,
78,15,45,1,1,3
78,15,52,1,1,6
78,15,52,1,9,
78,15,53,4,0,
78,15,63,4,0,
78,15,70,4,0,
78,15,76,4,0,
78,15,83,1,25,
78,15,92,4,0,
78,15,97,1,37,
78,15,98,1,1,4
78,15,104,4,0,
78,15,126,1,41,
78,15,126,4,0,
78,15,156,4,0,
78,15,164,4,0,
78,15,172,1,13,
78,15,182,4,0,
78,15,207,4,0,
78,15,213,4,0,
78,15,214,4,0,
78,15,216,4,0,
78,15,218,4,0,
78,15,224,1,1,2
78,15,237,4,0,
78,15,241,4,0,
78,15,261,4,0,
78,15,263,4,0,
78,15,315,4,0,
78,15,340,1,45,
78,15,394,1,49,
78,15,398,1,1,1
78,15,398,4,0,
78,15,416,4,0,
78,15,488,1,21,
78,15,488,4,0,
78,15,496,4,0,
78,15,497,4,0,
78,15,510,4,0,
78,15,517,1,33,
78,15,528,4,0,
78,15,590,4,0,
78,16,23,1,17,1
78,16,31,1,40,1
78,16,36,1,29,1
78,16,39,1,1,5
78,16,39,1,4,1
78,16,45,1,1,3
78,16,52,1,1,6
78,16,52,1,9,1
78,16,53,4,0,
78,16,63,4,0,
78,16,67,3,0,
78,16,70,4,0,
78,16,76,4,0,
78,16,83,1,25,1
78,16,92,4,0,
78,16,97,1,37,1
78,16,98,1,1,4
78,16,104,4,0,
78,16,126,1,41,1
78,16,126,4,0,
78,16,156,4,0,
78,16,164,4,0,
78,16,172,1,13,1
78,16,173,3,0,
78,16,182,4,0,
78,16,207,4,0,
78,16,213,4,0,
78,16,214,4,0,
78,16,216,4,0,
78,16,218,4,0,
78,16,224,1,1,2
78,16,231,3,0,
78,16,237,4,0,
78,16,241,4,0,
78,16,257,3,0,
78,16,261,4,0,
78,16,263,4,0,
78,16,290,4,0,
78,16,315,4,0,
78,16,340,1,45,1
78,16,340,3,0,
78,16,394,1,49,1
78,16,398,1,1,1
78,16,398,4,0,
78,16,416,4,0,
78,16,488,1,21,1
78,16,488,4,0,
78,16,496,4,0,
78,16,497,4,0,
78,16,510,4,0,
78,16,517,1,33,1
78,16,528,4,0,
78,16,529,3,0,
78,16,590,4,0,
78,17,23,1,17,
78,17,31,1,0,
78,17,31,1,1,1
78,17,36,1,29,
78,17,39,1,1,6
78,17,39,1,4,
78,17,45,1,1,4
78,17,52,1,1,7
78,17,52,1,9,
78,17,53,4,0,
78,17,63,4,0,
78,17,76,4,0,
78,17,83,1,25,
78,17,92,4,0,
78,17,97,1,37,
78,17,98,1,1,5
78,17,104,4,0,
78,17,126,1,41,
78,17,126,4,0,
78,17,156,4,0,
78,17,164,4,0,
78,17,172,1,13,
78,17,182,4,0,
78,17,207,4,0,
78,17,213,4,0,
78,17,214,4,0,
78,17,216,4,0,
78,17,218,4,0,
78,17,224,1,1,3
78,17,237,4,0,
78,17,241,4,0,
78,17,261,4,0,
78,17,263,4,0,
78,17,315,4,0,
78,17,340,1,45,
78,17,394,1,49,
78,17,398,1,1,2
78,17,398,4,0,
78,17,416,4,0,
78,17,488,1,21,
78,17,488,4,0,
78,17,496,4,0,
78,17,497,4,0,
78,17,517,1,33,
78,17,528,4,0,
78,17,590,4,0,
78,17,684,4,0,
78,18,23,1,17,
78,18,31,1,0,
78,18,31,1,1,1
78,18,36,1,29,
78,18,39,1,1,6
78,18,39,1,4,
78,18,45,1,1,4
78,18,52,1,1,7
78,18,52,1,9,
78,18,53,4,0,
78,18,63,4,0,
78,18,76,4,0,
78,18,83,1,25,
78,18,92,4,0,
78,18,97,1,37,
78,18,98,1,1,5
78,18,104,4,0,
78,18,126,1,41,
78,18,126,4,0,
78,18,156,4,0,
78,18,164,4,0,
78,18,172,1,13,
78,18,182,4,0,
78,18,207,4,0,
78,18,213,4,0,
78,18,214,4,0,
78,18,216,4,0,
78,18,218,4,0,
78,18,224,1,1,3
78,18,237,4,0,
78,18,241,4,0,
78,18,261,4,0,
78,18,263,4,0,
78,18,315,4,0,
78,18,340,1,45,
78,18,394,1,49,
78,18,398,1,1,2
78,18,398,4,0,
78,18,416,4,0,
78,18,488,1,21,
78,18,488,4,0,
78,18,496,4,0,
78,18,497,4,0,
78,18,517,1,33,
78,18,528,4,0,
78,18,590,4,0,
78,18,684,4,0,
79,1,6,4,0,
79,1,29,1,22,
79,1,34,4,0,
79,1,36,4,0,
79,1,38,4,0,
79,1,45,1,27,
79,1,50,1,18,
79,1,55,1,33,
79,1,55,4,0,
79,1,57,4,0,
79,1,58,4,0,
79,1,59,4,0,
79,1,61,4,0,
79,1,70,4,0,
79,1,86,4,0,
79,1,89,4,0,
79,1,90,4,0,
79,1,91,4,0,
79,1,92,4,0,
79,1,93,1,1,
79,1,94,1,48,
79,1,94,4,0,
79,1,99,4,0,
79,1,100,4,0,
79,1,102,4,0,
79,1,104,4,0,
79,1,115,4,0,
79,1,117,4,0,
79,1,126,4,0,
79,1,129,4,0,
79,1,130,4,0,
79,1,133,1,40,
79,1,148,4,0,
79,1,149,4,0,
79,1,156,4,0,
79,1,161,4,0,
79,1,164,4,0,
79,2,6,4,0,
79,2,29,1,22,
79,2,34,4,0,
79,2,36,4,0,
79,2,38,4,0,
79,2,45,1,27,
79,2,50,1,18,
79,2,55,1,33,
79,2,55,4,0,
79,2,57,4,0,
79,2,58,4,0,
79,2,59,4,0,
79,2,61,4,0,
79,2,70,4,0,
79,2,86,4,0,
79,2,89,4,0,
79,2,90,4,0,
79,2,91,4,0,
79,2,92,4,0,
79,2,93,1,1,
79,2,94,1,48,
79,2,94,4,0,
79,2,99,4,0,
79,2,100,4,0,
79,2,102,4,0,
79,2,104,4,0,
79,2,115,4,0,
79,2,117,4,0,
79,2,126,4,0,
79,2,129,4,0,
79,2,130,4,0,
79,2,133,1,40,
79,2,148,4,0,
79,2,149,4,0,
79,2,156,4,0,
79,2,161,4,0,
79,2,164,4,0,
79,3,23,2,0,
79,3,29,1,34,
79,3,29,4,0,
79,3,33,1,1,2
79,3,45,1,6,
79,3,50,1,29,
79,3,55,1,15,
79,3,57,4,0,
79,3,59,4,0,
79,3,70,4,0,
79,3,89,4,0,
79,3,91,4,0,
79,3,92,4,0,
79,3,93,1,20,
79,3,94,1,48,
79,3,94,4,0,
79,3,104,4,0,
79,3,126,4,0,
79,3,129,4,0,
79,3,133,1,43,
79,3,138,4,0,
79,3,148,4,0,
79,3,156,4,0,
79,3,171,4,0,
79,3,173,4,0,
79,3,174,1,1,1
79,3,174,4,0,
79,3,182,4,0,
79,3,187,2,0,
79,3,189,4,0,
79,3,192,4,0,
79,3,196,4,0,
79,3,203,4,0,
79,3,207,4,0,
79,3,213,4,0,
79,3,214,4,0,
79,3,216,4,0,
79,3,218,4,0,
79,3,219,2,0,
79,3,231,4,0,
79,3,237,4,0,
79,3,240,4,0,
79,3,241,4,0,
79,3,244,4,0,
79,3,247,4,0,
79,3,248,2,0,
79,4,23,2,0,
79,4,29,1,34,
79,4,29,4,0,
79,4,33,1,1,2
79,4,45,1,6,
79,4,50,1,29,
79,4,53,3,0,
79,4,55,1,15,
79,4,57,4,0,
79,4,58,3,0,
79,4,59,4,0,
79,4,70,4,0,
79,4,89,4,0,
79,4,91,4,0,
79,4,92,4,0,
79,4,93,1,20,
79,4,94,1,48,
79,4,94,4,0,
79,4,104,4,0,
79,4,126,4,0,
79,4,129,4,0,
79,4,133,1,43,
79,4,138,4,0,
79,4,148,4,0,
79,4,156,4,0,
79,4,171,4,0,
79,4,173,4,0,
79,4,174,1,1,1
79,4,174,4,0,
79,4,182,4,0,
79,4,187,2,0,
79,4,189,4,0,
79,4,192,4,0,
79,4,196,4,0,
79,4,203,4,0,
79,4,207,4,0,
79,4,213,4,0,
79,4,214,4,0,
79,4,216,4,0,
79,4,218,4,0,
79,4,219,2,0,
79,4,231,4,0,
79,4,237,4,0,
79,4,240,4,0,
79,4,241,4,0,
79,4,244,4,0,
79,4,247,4,0,
79,4,248,2,0,
79,5,23,2,0,
79,5,29,1,34,
79,5,33,1,1,3
79,5,45,1,6,
79,5,50,1,29,
79,5,53,4,0,
79,5,55,1,15,
79,5,57,4,0,
79,5,58,4,0,
79,5,59,4,0,
79,5,70,4,0,
79,5,89,4,0,
79,5,91,4,0,
79,5,92,4,0,
79,5,93,1,20,
79,5,94,1,48,
79,5,94,4,0,
79,5,104,4,0,
79,5,126,4,0,
79,5,133,1,43,
79,5,148,4,0,
79,5,156,4,0,
79,5,173,2,0,
79,5,174,1,1,1
79,5,182,4,0,
79,5,187,2,0,
79,5,213,4,0,
79,5,214,2,0,
79,5,216,4,0,
79,5,218,4,0,
79,5,219,2,0,
79,5,219,4,0,
79,5,231,4,0,
79,5,237,4,0,
79,5,240,4,0,
79,5,241,4,0,
79,5,247,4,0,
79,5,248,2,0,
79,5,258,4,0,
79,5,263,4,0,
79,5,281,1,1,2
79,5,285,4,0,
79,5,290,4,0,
79,5,291,4,0,
79,5,300,2,0,
79,5,347,4,0,
79,5,352,4,0,
79,6,23,2,0,
79,6,29,1,34,
79,6,33,1,1,3
79,6,34,3,0,
79,6,38,3,0,
79,6,45,1,6,
79,6,50,1,29,
79,6,53,4,0,
79,6,55,1,15,
79,6,57,4,0,
79,6,58,4,0,
79,6,59,4,0,
79,6,70,4,0,
79,6,86,3,0,
79,6,89,4,0,
79,6,91,4,0,
79,6,92,4,0,
79,6,93,1,20,
79,6,94,1,48,
79,6,94,4,0,
79,6,102,3,0,
79,6,104,4,0,
79,6,126,4,0,
79,6,129,3,0,
79,6,133,1,43,
79,6,138,3,0,
79,6,148,4,0,
79,6,156,4,0,
79,6,164,3,0,
79,6,173,2,0,
79,6,173,3,0,
79,6,174,1,1,1
79,6,182,4,0,
79,6,187,2,0,
79,6,189,3,0,
79,6,196,3,0,
79,6,203,3,0,
79,6,207,3,0,
79,6,213,4,0,
79,6,214,2,0,
79,6,214,3,0,
79,6,216,4,0,
79,6,218,4,0,
79,6,219,2,0,
79,6,219,4,0,
79,6,231,4,0,
79,6,237,4,0,
79,6,240,4,0,
79,6,241,4,0,
79,6,244,3,0,
79,6,247,4,0,
79,6,248,2,0,
79,6,258,4,0,
79,6,263,4,0,
79,6,281,1,1,2
79,6,285,4,0,
79,6,290,4,0,
79,6,291,4,0,
79,6,300,2,0,
79,6,347,4,0,
79,6,352,4,0,
79,7,23,2,0,
79,7,29,1,29,
79,7,33,1,1,3
79,7,34,3,0,
79,7,38,3,0,
79,7,45,1,6,
79,7,50,1,24,
79,7,53,4,0,
79,7,55,1,13,
79,7,57,4,0,
79,7,58,4,0,
79,7,59,4,0,
79,7,70,4,0,
79,7,86,3,0,
79,7,89,4,0,
79,7,91,4,0,
79,7,92,4,0,
79,7,93,1,17,
79,7,94,1,40,
79,7,94,4,0,
79,7,102,3,0,
79,7,104,4,0,
79,7,126,4,0,
79,7,133,1,36,
79,7,138,3,0,
79,7,148,4,0,
79,7,156,4,0,
79,7,164,3,0,
79,7,173,2,0,
79,7,174,1,1,1
79,7,182,4,0,
79,7,187,2,0,
79,7,213,4,0,
79,7,214,2,0,
79,7,216,4,0,
79,7,218,4,0,
79,7,219,2,0,
79,7,219,4,0,
79,7,231,4,0,
79,7,237,4,0,
79,7,240,4,0,
79,7,241,4,0,
79,7,244,1,47,
79,7,247,4,0,
79,7,248,2,0,
79,7,258,4,0,
79,7,263,4,0,
79,7,281,1,1,2
79,7,285,4,0,
79,7,290,4,0,
79,7,291,4,0,
79,7,300,2,0,
79,7,347,4,0,
79,7,352,4,0,
79,8,23,2,0,
79,8,29,1,25,
79,8,33,1,1,3
79,8,45,1,6,
79,8,50,1,20,
79,8,53,4,0,
79,8,55,1,11,
79,8,57,4,0,
79,8,58,4,0,
79,8,59,4,0,
79,8,70,4,0,
79,8,86,4,0,
79,8,89,4,0,
79,8,91,4,0,
79,8,92,4,0,
79,8,93,1,15,
79,8,94,1,48,
79,8,94,4,0,
79,8,104,4,0,
79,8,113,4,0,
79,8,126,4,0,
79,8,133,1,43,
79,8,138,4,0,
79,8,148,4,0,
79,8,156,4,0,
79,8,164,4,0,
79,8,173,2,0,
79,8,174,1,1,1
79,8,182,4,0,
79,8,187,2,0,
79,8,203,4,0,
79,8,207,4,0,
79,8,213,4,0,
79,8,214,2,0,
79,8,214,4,0,
79,8,216,4,0,
79,8,218,4,0,
79,8,219,2,0,
79,8,219,4,0,
79,8,231,4,0,
79,8,237,4,0,
79,8,240,1,53,
79,8,240,4,0,
79,8,241,4,0,
79,8,244,1,57,
79,8,244,4,0,
79,8,247,4,0,
79,8,248,2,0,
79,8,258,4,0,
79,8,263,4,0,
79,8,278,4,0,
79,8,281,1,1,2
79,8,285,4,0,
79,8,290,4,0,
79,8,300,2,0,
79,8,303,1,39,
79,8,335,2,0,
79,8,347,4,0,
79,8,352,1,29,
79,8,352,4,0,
79,8,362,4,0,
79,8,363,4,0,
79,8,382,2,0,
79,8,428,1,34,
79,8,428,2,0,
79,8,433,4,0,
79,8,445,4,0,
79,8,447,4,0,
79,9,23,2,0,
79,9,29,1,25,
79,9,33,1,1,3
79,9,45,1,6,
79,9,50,1,20,
79,9,53,4,0,
79,9,55,1,11,
79,9,57,4,0,
79,9,58,4,0,
79,9,59,4,0,
79,9,70,4,0,
79,9,86,4,0,
79,9,89,4,0,
79,9,91,4,0,
79,9,92,4,0,
79,9,93,1,15,
79,9,94,1,48,
79,9,94,4,0,
79,9,104,4,0,
79,9,113,4,0,
79,9,126,4,0,
79,9,129,3,0,
79,9,133,1,43,
79,9,138,4,0,
79,9,148,4,0,
79,9,156,4,0,
79,9,164,4,0,
79,9,173,2,0,
79,9,173,3,0,
79,9,174,1,1,1
79,9,182,4,0,
79,9,187,2,0,
79,9,189,3,0,
79,9,196,3,0,
79,9,203,4,0,
79,9,207,4,0,
79,9,213,4,0,
79,9,214,2,0,
79,9,214,4,0,
79,9,216,4,0,
79,9,218,4,0,
79,9,219,2,0,
79,9,219,4,0,
79,9,231,4,0,
79,9,237,4,0,
79,9,240,1,53,
79,9,240,4,0,
79,9,241,4,0,
79,9,244,1,57,
79,9,244,4,0,
79,9,247,4,0,
79,9,248,2,0,
79,9,258,4,0,
79,9,263,4,0,
79,9,271,3,0,
79,9,278,4,0,
79,9,281,1,1,2
79,9,285,4,0,
79,9,290,4,0,
79,9,291,3,0,
79,9,300,2,0,
79,9,303,1,39,
79,9,324,3,0,
79,9,335,2,0,
79,9,347,4,0,
79,9,352,1,29,
79,9,352,4,0,
79,9,362,4,0,
79,9,363,4,0,
79,9,382,2,0,
79,9,401,3,0,
79,9,428,1,34,
79,9,428,2,0,
79,9,428,3,0,
79,9,433,4,0,
79,9,445,4,0,
79,9,447,4,0,
79,10,23,2,0,
79,10,29,1,25,
79,10,29,3,0,
79,10,33,1,1,3
79,10,45,1,6,
79,10,50,1,20,
79,10,53,4,0,
79,10,55,1,11,
79,10,57,4,0,
79,10,58,4,0,
79,10,59,4,0,
79,10,70,4,0,
79,10,86,4,0,
79,10,89,4,0,
79,10,91,4,0,
79,10,92,4,0,
79,10,93,1,15,
79,10,94,1,48,
79,10,94,4,0,
79,10,104,4,0,
79,10,113,4,0,
79,10,126,4,0,
79,10,129,3,0,
79,10,133,1,43,
79,10,138,4,0,
79,10,148,4,0,
79,10,156,4,0,
79,10,164,4,0,
79,10,173,2,0,
79,10,173,3,0,
79,10,174,1,1,1
79,10,182,4,0,
79,10,187,2,0,
79,10,189,3,0,
79,10,196,3,0,
79,10,203,4,0,
79,10,207,4,0,
79,10,213,4,0,
79,10,214,2,0,
79,10,214,4,0,
79,10,216,4,0,
79,10,218,4,0,
79,10,219,2,0,
79,10,219,4,0,
79,10,231,4,0,
79,10,237,4,0,
79,10,240,1,53,
79,10,240,4,0,
79,10,241,4,0,
79,10,244,1,57,
79,10,244,4,0,
79,10,247,4,0,
79,10,248,2,0,
79,10,250,4,0,
79,10,258,4,0,
79,10,263,4,0,
79,10,271,3,0,
79,10,277,3,0,
79,10,278,4,0,
79,10,281,1,1,2
79,10,285,4,0,
79,10,290,4,0,
79,10,291,3,0,
79,10,300,2,0,
79,10,303,1,39,
79,10,324,3,0,
79,10,335,2,0,
79,10,335,3,0,
79,10,347,4,0,
79,10,352,1,29,
79,10,352,4,0,
79,10,362,4,0,
79,10,363,4,0,
79,10,382,2,0,
79,10,401,3,0,
79,10,428,1,34,
79,10,428,2,0,
79,10,428,3,0,
79,10,433,4,0,
79,10,445,4,0,
79,10,447,4,0,
79,11,23,2,0,
79,11,29,1,23,
79,11,33,1,1,3
79,11,45,1,5,
79,11,50,1,19,
79,11,53,4,0,
79,11,55,1,9,
79,11,57,4,0,
79,11,58,4,0,
79,11,59,4,0,
79,11,70,4,0,
79,11,86,4,0,
79,11,89,4,0,
79,11,91,4,0,
79,11,92,4,0,
79,11,93,1,14,
79,11,94,1,45,
79,11,94,4,0,
79,11,104,4,0,
79,11,113,4,0,
79,11,126,4,0,
79,11,133,1,41,
79,11,138,4,0,
79,11,148,4,0,
79,11,156,4,0,
79,11,164,4,0,
79,11,173,2,0,
79,11,174,1,1,1
79,11,182,4,0,
79,11,187,2,0,
79,11,207,4,0,
79,11,213,4,0,
79,11,214,2,0,
79,11,216,4,0,
79,11,218,4,0,
79,11,219,4,0,
79,11,237,4,0,
79,11,240,1,49,
79,11,240,4,0,
79,11,241,4,0,
79,11,244,1,54,
79,11,244,4,0,
79,11,247,4,0,
79,11,248,2,0,
79,11,258,4,0,
79,11,263,4,0,
79,11,281,1,1,2
79,11,291,4,0,
79,11,300,2,0,
79,11,303,1,36,
79,11,335,2,0,
79,11,347,4,0,
79,11,352,1,28,
79,11,382,2,0,
79,11,428,1,32,
79,11,428,2,0,
79,11,433,4,0,
79,11,447,4,0,
79,11,472,2,0,
79,11,473,4,0,
79,11,477,4,0,
79,11,496,4,0,
79,11,497,4,0,
79,11,503,4,0,
79,11,505,1,58,
79,11,510,4,0,
79,11,523,4,0,
79,12,29,1,34,
79,12,33,1,1,3
79,12,45,1,6,
79,12,50,1,29,
79,12,53,4,0,
79,12,55,1,15,
79,12,57,4,0,
79,12,58,4,0,
79,12,59,4,0,
79,12,70,4,0,
79,12,89,4,0,
79,12,91,4,0,
79,12,92,4,0,
79,12,93,1,20,
79,12,94,1,48,
79,12,94,4,0,
79,12,104,4,0,
79,12,126,4,0,
79,12,133,1,43,
79,12,148,4,0,
79,12,156,4,0,
79,12,174,1,1,1
79,12,182,4,0,
79,12,213,4,0,
79,12,216,4,0,
79,12,218,4,0,
79,12,219,4,0,
79,12,231,4,0,
79,12,237,4,0,
79,12,240,4,0,
79,12,241,4,0,
79,12,247,4,0,
79,12,258,4,0,
79,12,263,4,0,
79,12,281,1,1,2
79,12,285,4,0,
79,12,290,4,0,
79,12,291,4,0,
79,12,347,4,0,
79,12,352,4,0,
79,13,29,1,34,
79,13,33,1,1,3
79,13,34,3,0,
79,13,38,3,0,
79,13,45,1,6,
79,13,50,1,29,
79,13,53,4,0,
79,13,55,1,15,
79,13,57,4,0,
79,13,58,4,0,
79,13,59,4,0,
79,13,70,4,0,
79,13,86,3,0,
79,13,89,4,0,
79,13,91,4,0,
79,13,92,4,0,
79,13,93,1,20,
79,13,94,1,48,
79,13,94,4,0,
79,13,102,3,0,
79,13,104,4,0,
79,13,126,4,0,
79,13,133,1,43,
79,13,138,3,0,
79,13,148,4,0,
79,13,156,4,0,
79,13,164,3,0,
79,13,171,3,0,
79,13,174,1,1,1
79,13,182,4,0,
79,13,196,3,0,
79,13,207,3,0,
79,13,213,4,0,
79,13,216,4,0,
79,13,218,4,0,
79,13,219,4,0,
79,13,231,4,0,
79,13,237,4,0,
79,13,240,4,0,
79,13,241,4,0,
79,13,247,4,0,
79,13,258,4,0,
79,13,263,4,0,
79,13,281,1,1,2
79,13,285,4,0,
79,13,290,4,0,
79,13,291,4,0,
79,13,347,4,0,
79,13,352,4,0,
79,14,23,2,0,
79,14,29,1,23,
79,14,33,1,1,3
79,14,45,1,5,
79,14,50,1,19,
79,14,53,4,0,
79,14,55,1,9,
79,14,57,4,0,
79,14,58,4,0,
79,14,59,4,0,
79,14,70,4,0,
79,14,86,4,0,
79,14,89,4,0,
79,14,91,4,0,
79,14,92,4,0,
79,14,93,1,14,
79,14,94,1,45,
79,14,94,4,0,
79,14,104,4,0,
79,14,113,4,0,
79,14,126,4,0,
79,14,133,1,41,
79,14,138,4,0,
79,14,148,4,0,
79,14,156,4,0,
79,14,164,4,0,
79,14,173,2,0,
79,14,173,3,0,
79,14,174,1,1,1
79,14,182,4,0,
79,14,187,2,0,
79,14,196,3,0,
79,14,207,4,0,
79,14,213,4,0,
79,14,214,2,0,
79,14,214,3,0,
79,14,216,4,0,
79,14,218,4,0,
79,14,219,4,0,
79,14,231,3,0,
79,14,237,4,0,
79,14,240,1,49,
79,14,240,4,0,
79,14,241,4,0,
79,14,244,1,54,
79,14,244,4,0,
79,14,247,4,0,
79,14,248,2,0,
79,14,258,4,0,
79,14,263,4,0,
79,14,271,3,0,
79,14,277,3,0,
79,14,278,3,0,
79,14,281,1,1,2
79,14,285,3,0,
79,14,291,4,0,
79,14,300,2,0,
79,14,303,1,36,
79,14,324,3,0,
79,14,335,2,0,
79,14,335,3,0,
79,14,347,4,0,
79,14,352,1,28,
79,14,382,2,0,
79,14,401,3,0,
79,14,428,1,32,
79,14,428,2,0,
79,14,428,3,0,
79,14,433,4,0,
79,14,447,4,0,
79,14,472,2,0,
79,14,472,3,0,
79,14,473,4,0,
79,14,477,4,0,
79,14,495,3,0,
79,14,496,4,0,
79,14,497,4,0,
79,14,503,4,0,
79,14,505,1,58,
79,14,510,4,0,
79,14,523,4,0,
79,15,23,2,0,
79,15,29,1,23,
79,15,33,1,1,3
79,15,45,1,5,
79,15,50,1,19,
79,15,53,4,0,
79,15,55,1,9,
79,15,57,4,0,
79,15,58,4,0,
79,15,59,4,0,
79,15,70,4,0,
79,15,86,4,0,
79,15,89,4,0,
79,15,91,4,0,
79,15,92,4,0,
79,15,93,1,14,
79,15,94,1,45,
79,15,94,4,0,
79,15,104,4,0,
79,15,113,4,0,
79,15,126,4,0,
79,15,133,1,41,
79,15,138,4,0,
79,15,148,4,0,
79,15,156,4,0,
79,15,164,4,0,
79,15,173,2,0,
79,15,174,1,1,1
79,15,182,4,0,
79,15,187,2,0,
79,15,207,4,0,
79,15,213,4,0,
79,15,214,2,0,
79,15,214,4,0,
79,15,216,4,0,
79,15,218,4,0,
79,15,219,4,0,
79,15,237,4,0,
79,15,240,1,49,
79,15,240,4,0,
79,15,241,4,0,
79,15,244,1,54,
79,15,244,4,0,
79,15,247,4,0,
79,15,248,2,0,
79,15,258,4,0,
79,15,263,4,0,
79,15,281,1,1,2
79,15,300,2,0,
79,15,303,1,36,
79,15,335,2,0,
79,15,347,4,0,
79,15,352,1,28,
79,15,382,2,0,
79,15,428,1,32,
79,15,428,2,0,
79,15,433,4,0,
79,15,447,4,0,
79,15,472,2,0,
79,15,473,4,0,
79,15,496,4,0,
79,15,497,4,0,
79,15,503,4,0,
79,15,505,1,58,
79,15,510,4,0,
79,15,523,4,0,
79,15,562,2,0,
79,15,590,4,0,
79,16,23,2,0,
79,16,29,1,23,1
79,16,33,1,1,3
79,16,45,1,5,1
79,16,50,1,19,1
79,16,53,4,0,
79,16,55,1,9,1
79,16,57,4,0,
79,16,58,4,0,
79,16,59,4,0,
79,16,70,4,0,
79,16,86,4,0,
79,16,89,4,0,
79,16,91,4,0,
79,16,92,4,0,
79,16,93,1,14,1
79,16,94,1,45,1
79,16,94,4,0,
79,16,104,4,0,
79,16,113,4,0,
79,16,126,4,0,
79,16,133,1,41,1
79,16,138,4,0,
79,16,148,4,0,
79,16,156,4,0,
79,16,164,4,0,
79,16,173,2,0,
79,16,173,3,0,
79,16,174,1,1,1
79,16,182,4,0,
79,16,187,2,0,
79,16,196,3,0,
79,16,207,4,0,
79,16,213,4,0,
79,16,214,2,0,
79,16,214,4,0,
79,16,216,4,0,
79,16,218,4,0,
79,16,219,4,0,
79,16,231,3,0,
79,16,237,4,0,
79,16,240,1,49,1
79,16,240,4,0,
79,16,241,4,0,
79,16,244,1,54,1
79,16,244,4,0,
79,16,247,4,0,
79,16,248,2,0,
79,16,258,4,0,
79,16,263,4,0,
79,16,271,3,0,
79,16,277,3,0,
79,16,278,3,0,
79,16,281,1,1,2
79,16,285,3,0,
79,16,290,4,0,
79,16,291,4,0,
79,16,300,2,0,
79,16,303,1,36,1
79,16,324,3,0,
79,16,335,2,0,
79,16,335,3,0,
79,16,347,4,0,
79,16,352,1,28,1
79,16,352,3,0,
79,16,382,2,0,
79,16,401,3,0,
79,16,428,1,32,1
79,16,428,2,0,
79,16,428,3,0,
79,16,433,4,0,
79,16,447,4,0,
79,16,472,2,0,
79,16,472,3,0,
79,16,473,4,0,
79,16,495,3,0,
79,16,496,4,0,
79,16,497,4,0,
79,16,503,4,0,
79,16,505,1,58,1
79,16,510,4,0,
79,16,523,4,0,
79,16,562,2,0,
79,16,590,4,0,
79,17,23,2,0,
79,17,29,1,23,
79,17,33,1,1,3
79,17,45,1,5,
79,17,50,1,19,
79,17,53,4,0,
79,17,55,1,9,
79,17,57,4,0,
79,17,58,4,0,
79,17,59,4,0,
79,17,86,4,0,
79,17,89,4,0,
79,17,92,4,0,
79,17,93,1,14,
79,17,94,1,45,
79,17,94,4,0,
79,17,104,4,0,
79,17,113,4,0,
79,17,126,4,0,
79,17,133,1,41,
79,17,138,4,0,
79,17,156,4,0,
79,17,164,4,0,
79,17,173,2,0,
79,17,174,1,1,1
79,17,182,4,0,
79,17,187,2,0,
79,17,207,4,0,
79,17,213,4,0,
79,17,214,2,0,
79,17,214,4,0,
79,17,216,4,0,
79,17,218,4,0,
79,17,219,4,0,
79,17,237,4,0,
79,17,240,1,49,
79,17,240,4,0,
79,17,241,4,0,
79,17,244,1,54,
79,17,244,4,0,
79,17,247,4,0,
79,17,248,2,0,
79,17,258,4,0,
79,17,263,4,0,
79,17,281,1,1,2
79,17,300,2,0,
79,17,303,1,36,
79,17,335,2,0,
79,17,347,4,0,
79,17,352,1,28,
79,17,382,2,0,
79,17,428,1,32,
79,17,428,2,0,
79,17,433,4,0,
79,17,447,4,0,
79,17,472,2,0,
79,17,473,4,0,
79,17,496,4,0,
79,17,497,4,0,
79,17,503,4,0,
79,17,505,1,58,
79,17,523,4,0,
79,17,562,2,0,
79,17,590,4,0,
79,18,23,2,0,
79,18,29,1,23,
79,18,33,1,1,3
79,18,45,1,5,
79,18,50,1,19,
79,18,53,4,0,
79,18,55,1,9,
79,18,57,4,0,
79,18,58,4,0,
79,18,59,4,0,
79,18,86,4,0,
79,18,89,4,0,
79,18,92,4,0,
79,18,93,1,14,
79,18,94,1,45,
79,18,94,4,0,
79,18,104,4,0,
79,18,113,4,0,
79,18,126,4,0,
79,18,133,1,41,
79,18,138,4,0,
79,18,156,4,0,
79,18,164,4,0,
79,18,173,2,0,
79,18,174,1,1,1
79,18,182,4,0,
79,18,187,2,0,
79,18,207,4,0,
79,18,213,4,0,
79,18,214,2,0,
79,18,214,4,0,
79,18,216,4,0,
79,18,218,4,0,
79,18,219,4,0,
79,18,237,4,0,
79,18,240,1,49,
79,18,240,4,0,
79,18,241,4,0,
79,18,244,1,54,
79,18,244,4,0,
79,18,247,4,0,
79,18,248,2,0,
79,18,258,4,0,
79,18,263,4,0,
79,18,281,1,1,2
79,18,300,2,0,
79,18,303,1,36,
79,18,335,2,0,
79,18,347,4,0,
79,18,352,1,28,
79,18,382,2,0,
79,18,428,1,32,
79,18,428,2,0,
79,18,433,4,0,
79,18,447,4,0,
79,18,472,2,0,
79,18,473,4,0,
79,18,496,4,0,
79,18,497,4,0,
79,18,503,4,0,
79,18,505,1,58,
79,18,523,4,0,
79,18,562,2,0,
79,18,590,4,0,
80,1,5,4,0,
80,1,6,4,0,
80,1,25,4,0,
80,1,29,1,1,3
80,1,29,1,22,
80,1,34,4,0,
80,1,36,4,0,
80,1,38,4,0,
80,1,45,1,27,
80,1,50,1,1,2
80,1,50,1,18,
80,1,55,1,33,
80,1,55,4,0,
80,1,57,4,0,
80,1,58,4,0,
80,1,59,4,0,
80,1,61,4,0,
80,1,63,4,0,
80,1,66,4,0,
80,1,68,4,0,
80,1,69,4,0,
80,1,70,4,0,
80,1,86,4,0,
80,1,89,4,0,
80,1,90,4,0,
80,1,91,4,0,
80,1,92,4,0,
80,1,93,1,1,1
80,1,94,1,55,
80,1,94,4,0,
80,1,99,4,0,
80,1,100,4,0,
80,1,102,4,0,
80,1,104,4,0,
80,1,110,1,37,
80,1,115,4,0,
80,1,117,4,0,
80,1,126,4,0,
80,1,129,4,0,
80,1,130,4,0,
80,1,133,1,44,
80,1,148,4,0,
80,1,149,4,0,
80,1,156,4,0,
80,1,161,4,0,
80,1,164,4,0,
80,2,5,4,0,
80,2,6,4,0,
80,2,25,4,0,
80,2,29,1,1,3
80,2,29,1,22,
80,2,34,4,0,
80,2,36,4,0,
80,2,38,4,0,
80,2,45,1,27,
80,2,50,1,1,2
80,2,50,1,18,
80,2,55,1,33,
80,2,55,4,0,
80,2,57,4,0,
80,2,58,4,0,
80,2,59,4,0,
80,2,61,4,0,
80,2,63,4,0,
80,2,66,4,0,
80,2,68,4,0,
80,2,69,4,0,
80,2,70,4,0,
80,2,86,4,0,
80,2,89,4,0,
80,2,90,4,0,
80,2,91,4,0,
80,2,92,4,0,
80,2,93,1,1,1
80,2,94,1,55,
80,2,94,4,0,
80,2,99,4,0,
80,2,100,4,0,
80,2,102,4,0,
80,2,104,4,0,
80,2,110,1,37,
80,2,115,4,0,
80,2,117,4,0,
80,2,126,4,0,
80,2,129,4,0,
80,2,130,4,0,
80,2,133,1,44,
80,2,148,4,0,
80,2,149,4,0,
80,2,156,4,0,
80,2,161,4,0,
80,2,164,4,0,
80,3,8,4,0,
80,3,29,1,34,
80,3,29,4,0,
80,3,33,1,1,2
80,3,45,1,1,3
80,3,45,1,6,
80,3,50,1,29,
80,3,55,1,1,4
80,3,55,1,15,
80,3,57,4,0,
80,3,59,4,0,
80,3,63,4,0,
80,3,70,4,0,
80,3,89,4,0,
80,3,91,4,0,
80,3,92,4,0,
80,3,93,1,20,
80,3,94,1,54,
80,3,94,4,0,
80,3,104,4,0,
80,3,110,1,37,
80,3,126,4,0,
80,3,129,4,0,
80,3,133,1,46,
80,3,138,4,0,
80,3,148,4,0,
80,3,156,4,0,
80,3,171,4,0,
80,3,173,4,0,
80,3,174,1,1,1
80,3,174,4,0,
80,3,182,4,0,
80,3,189,4,0,
80,3,192,4,0,
80,3,196,4,0,
80,3,203,4,0,
80,3,207,4,0,
80,3,210,4,0,
80,3,213,4,0,
80,3,214,4,0,
80,3,216,4,0,
80,3,218,4,0,
80,3,223,4,0,
80,3,231,4,0,
80,3,237,4,0,
80,3,240,4,0,
80,3,241,4,0,
80,3,244,4,0,
80,3,247,4,0,
80,3,249,4,0,
80,4,8,4,0,
80,4,29,1,34,
80,4,29,4,0,
80,4,33,1,1,2
80,4,45,1,1,3
80,4,45,1,6,
80,4,50,1,29,
80,4,53,3,0,
80,4,55,1,1,4
80,4,55,1,15,
80,4,57,4,0,
80,4,58,3,0,
80,4,59,4,0,
80,4,63,4,0,
80,4,70,4,0,
80,4,89,4,0,
80,4,91,4,0,
80,4,92,4,0,
80,4,93,1,20,
80,4,94,1,54,
80,4,94,4,0,
80,4,104,4,0,
80,4,110,1,37,
80,4,126,4,0,
80,4,129,4,0,
80,4,133,1,46,
80,4,138,4,0,
80,4,148,4,0,
80,4,156,4,0,
80,4,171,4,0,
80,4,173,4,0,
80,4,174,1,1,1
80,4,174,4,0,
80,4,182,4,0,
80,4,189,4,0,
80,4,192,4,0,
80,4,196,4,0,
80,4,203,4,0,
80,4,207,4,0,
80,4,210,4,0,
80,4,213,4,0,
80,4,214,4,0,
80,4,216,4,0,
80,4,218,4,0,
80,4,223,4,0,
80,4,231,4,0,
80,4,237,4,0,
80,4,240,4,0,
80,4,241,4,0,
80,4,244,4,0,
80,4,247,4,0,
80,4,249,4,0,
80,5,29,1,34,
80,5,33,1,1,3
80,5,45,1,1,4
80,5,45,1,6,
80,5,50,1,29,
80,5,53,4,0,
80,5,55,1,15,
80,5,57,4,0,
80,5,58,4,0,
80,5,59,4,0,
80,5,63,4,0,
80,5,70,4,0,
80,5,89,4,0,
80,5,91,4,0,
80,5,92,4,0,
80,5,93,1,20,
80,5,94,1,54,
80,5,94,4,0,
80,5,104,4,0,
80,5,110,1,37,
80,5,126,4,0,
80,5,133,1,46,
80,5,148,4,0,
80,5,156,4,0,
80,5,174,1,1,1
80,5,182,4,0,
80,5,213,4,0,
80,5,216,4,0,
80,5,218,4,0,
80,5,219,4,0,
80,5,231,4,0,
80,5,237,4,0,
80,5,240,4,0,
80,5,241,4,0,
80,5,247,4,0,
80,5,249,4,0,
80,5,258,4,0,
80,5,263,4,0,
80,5,264,4,0,
80,5,280,4,0,
80,5,281,1,1,2
80,5,285,4,0,
80,5,290,4,0,
80,5,291,4,0,
80,5,347,4,0,
80,5,352,4,0,
80,6,5,3,0,
80,6,8,3,0,
80,6,25,3,0,
80,6,29,1,34,
80,6,33,1,1,3
80,6,34,3,0,
80,6,38,3,0,
80,6,45,1,1,4
80,6,45,1,6,
80,6,50,1,29,
80,6,53,4,0,
80,6,55,1,15,
80,6,57,4,0,
80,6,58,4,0,
80,6,59,4,0,
80,6,63,4,0,
80,6,68,3,0,
80,6,69,3,0,
80,6,70,4,0,
80,6,86,3,0,
80,6,89,4,0,
80,6,91,4,0,
80,6,92,4,0,
80,6,93,1,20,
80,6,94,1,54,
80,6,94,4,0,
80,6,102,3,0,
80,6,104,4,0,
80,6,110,1,37,
80,6,126,4,0,
80,6,129,3,0,
80,6,133,1,46,
80,6,138,3,0,
80,6,148,4,0,
80,6,156,4,0,
80,6,164,3,0,
80,6,173,3,0,
80,6,174,1,1,1
80,6,182,4,0,
80,6,189,3,0,
80,6,196,3,0,
80,6,203,3,0,
80,6,207,3,0,
80,6,210,3,0,
80,6,213,4,0,
80,6,214,3,0,
80,6,216,4,0,
80,6,218,4,0,
80,6,219,4,0,
80,6,223,3,0,
80,6,231,4,0,
80,6,237,4,0,
80,6,240,4,0,
80,6,241,4,0,
80,6,244,3,0,
80,6,247,4,0,
80,6,249,4,0,
80,6,258,4,0,
80,6,263,4,0,
80,6,264,4,0,
80,6,280,4,0,
80,6,281,1,1,2
80,6,285,4,0,
80,6,290,4,0,
80,6,291,4,0,
80,6,347,4,0,
80,6,352,4,0,
80,7,5,3,0,
80,7,25,3,0,
80,7,29,1,29,
80,7,33,1,1,3
80,7,34,3,0,
80,7,38,3,0,
80,7,45,1,1,4
80,7,45,1,6,
80,7,50,1,24,
80,7,53,4,0,
80,7,55,1,13,
80,7,57,4,0,
80,7,58,4,0,
80,7,59,4,0,
80,7,63,4,0,
80,7,68,3,0,
80,7,69,3,0,
80,7,70,4,0,
80,7,86,3,0,
80,7,89,4,0,
80,7,91,4,0,
80,7,92,4,0,
80,7,93,1,17,
80,7,94,1,44,
80,7,94,4,0,
80,7,102,3,0,
80,7,104,4,0,
80,7,110,1,37,
80,7,126,4,0,
80,7,133,1,36,
80,7,138,3,0,
80,7,148,4,0,
80,7,156,4,0,
80,7,164,3,0,
80,7,174,1,1,1
80,7,182,4,0,
80,7,213,4,0,
80,7,216,4,0,
80,7,218,4,0,
80,7,219,4,0,
80,7,231,4,0,
80,7,237,4,0,
80,7,240,4,0,
80,7,241,4,0,
80,7,244,1,55,
80,7,247,4,0,
80,7,249,4,0,
80,7,258,4,0,
80,7,263,4,0,
80,7,264,4,0,
80,7,280,4,0,
80,7,281,1,1,2
80,7,285,4,0,
80,7,290,4,0,
80,7,291,4,0,
80,7,347,4,0,
80,7,352,4,0,
80,8,29,1,25,
80,8,33,1,1,3
80,8,45,1,1,4
80,8,45,1,6,
80,8,50,1,20,
80,8,53,4,0,
80,8,55,1,11,
80,8,57,4,0,
80,8,58,4,0,
80,8,59,4,0,
80,8,63,4,0,
80,8,70,4,0,
80,8,86,4,0,
80,8,89,4,0,
80,8,91,4,0,
80,8,92,4,0,
80,8,93,1,15,
80,8,94,1,54,
80,8,94,4,0,
80,8,104,4,0,
80,8,110,1,37,
80,8,113,4,0,
80,8,126,4,0,
80,8,133,1,47,
80,8,138,4,0,
80,8,148,4,0,
80,8,156,4,0,
80,8,164,4,0,
80,8,174,1,1,1
80,8,182,4,0,
80,8,203,4,0,
80,8,207,4,0,
80,8,213,4,0,
80,8,214,4,0,
80,8,216,4,0,
80,8,218,4,0,
80,8,219,4,0,
80,8,231,4,0,
80,8,237,4,0,
80,8,240,1,61,
80,8,240,4,0,
80,8,241,4,0,
80,8,244,1,67,
80,8,244,4,0,
80,8,247,4,0,
80,8,249,4,0,
80,8,258,4,0,
80,8,263,4,0,
80,8,264,4,0,
80,8,278,4,0,
80,8,280,4,0,
80,8,281,1,1,2
80,8,285,4,0,
80,8,290,4,0,
80,8,303,1,41,
80,8,347,4,0,
80,8,352,1,29,
80,8,352,4,0,
80,8,362,4,0,
80,8,363,4,0,
80,8,374,4,0,
80,8,409,4,0,
80,8,411,4,0,
80,8,416,4,0,
80,8,419,4,0,
80,8,428,1,34,
80,8,433,4,0,
80,8,445,4,0,
80,8,447,4,0,
80,9,8,3,0,
80,9,29,1,25,
80,9,33,1,1,3
80,9,45,1,1,4
80,9,45,1,6,
80,9,50,1,20,
80,9,53,4,0,
80,9,55,1,11,
80,9,57,4,0,
80,9,58,4,0,
80,9,59,4,0,
80,9,63,4,0,
80,9,70,4,0,
80,9,86,4,0,
80,9,89,4,0,
80,9,91,4,0,
80,9,92,4,0,
80,9,93,1,15,
80,9,94,1,54,
80,9,94,4,0,
80,9,104,4,0,
80,9,110,1,37,
80,9,113,4,0,
80,9,126,4,0,
80,9,129,3,0,
80,9,133,1,47,
80,9,138,4,0,
80,9,148,4,0,
80,9,156,4,0,
80,9,164,4,0,
80,9,173,3,0,
80,9,174,1,1,1
80,9,182,4,0,
80,9,189,3,0,
80,9,196,3,0,
80,9,203,4,0,
80,9,207,4,0,
80,9,210,3,0,
80,9,213,4,0,
80,9,214,4,0,
80,9,216,4,0,
80,9,218,4,0,
80,9,219,4,0,
80,9,231,4,0,
80,9,237,4,0,
80,9,240,1,61,
80,9,240,4,0,
80,9,241,4,0,
80,9,244,1,67,
80,9,244,4,0,
80,9,247,4,0,
80,9,249,4,0,
80,9,258,4,0,
80,9,263,4,0,
80,9,264,4,0,
80,9,271,3,0,
80,9,278,4,0,
80,9,280,4,0,
80,9,281,1,1,2
80,9,285,4,0,
80,9,290,4,0,
80,9,291,3,0,
80,9,303,1,41,
80,9,324,3,0,
80,9,334,3,0,
80,9,347,4,0,
80,9,352,1,29,
80,9,352,4,0,
80,9,362,4,0,
80,9,363,4,0,
80,9,374,4,0,
80,9,401,3,0,
80,9,409,4,0,
80,9,411,4,0,
80,9,416,4,0,
80,9,419,4,0,
80,9,428,1,34,
80,9,428,3,0,
80,9,433,4,0,
80,9,445,4,0,
80,9,447,4,0,
80,10,8,3,0,
80,10,29,1,25,
80,10,29,3,0,
80,10,33,1,1,3
80,10,45,1,1,4
80,10,45,1,6,
80,10,50,1,20,
80,10,53,4,0,
80,10,55,1,11,
80,10,57,4,0,
80,10,58,4,0,
80,10,59,4,0,
80,10,63,4,0,
80,10,70,4,0,
80,10,86,4,0,
80,10,89,4,0,
80,10,91,4,0,
80,10,92,4,0,
80,10,93,1,15,
80,10,94,1,54,
80,10,94,4,0,
80,10,104,4,0,
80,10,110,1,37,
80,10,113,4,0,
80,10,126,4,0,
80,10,129,3,0,
80,10,133,1,47,
80,10,138,4,0,
80,10,148,4,0,
80,10,156,4,0,
80,10,164,4,0,
80,10,173,3,0,
80,10,174,1,1,1
80,10,182,4,0,
80,10,189,3,0,
80,10,196,3,0,
80,10,203,4,0,
80,10,207,4,0,
80,10,210,3,0,
80,10,213,4,0,
80,10,214,4,0,
80,10,216,4,0,
80,10,218,4,0,
80,10,219,4,0,
80,10,231,4,0,
80,10,237,4,0,
80,10,240,1,61,
80,10,240,4,0,
80,10,241,4,0,
80,10,244,1,67,
80,10,244,4,0,
80,10,247,4,0,
80,10,249,4,0,
80,10,250,4,0,
80,10,258,4,0,
80,10,263,4,0,
80,10,264,4,0,
80,10,271,3,0,
80,10,277,3,0,
80,10,278,4,0,
80,10,280,4,0,
80,10,281,1,1,2
80,10,285,4,0,
80,10,290,4,0,
80,10,291,3,0,
80,10,303,1,41,
80,10,324,3,0,
80,10,334,3,0,
80,10,335,3,0,
80,10,347,4,0,
80,10,352,1,29,
80,10,352,4,0,
80,10,362,4,0,
80,10,363,4,0,
80,10,374,4,0,
80,10,401,3,0,
80,10,409,4,0,
80,10,411,4,0,
80,10,416,4,0,
80,10,419,4,0,
80,10,428,1,34,
80,10,428,3,0,
80,10,433,4,0,
80,10,445,4,0,
80,10,447,4,0,
80,11,29,1,23,
80,11,33,1,1,3
80,11,45,1,1,4
80,11,45,1,5,
80,11,50,1,19,
80,11,53,4,0,
80,11,55,1,9,
80,11,57,4,0,
80,11,58,4,0,
80,11,59,4,0,
80,11,63,4,0,
80,11,70,4,0,
80,11,86,4,0,
80,11,89,4,0,
80,11,91,4,0,
80,11,92,4,0,
80,11,93,1,14,
80,11,94,1,49,
80,11,94,4,0,
80,11,104,4,0,
80,11,110,1,37,
80,11,113,4,0,
80,11,126,4,0,
80,11,133,1,43,
80,11,138,4,0,
80,11,148,4,0,
80,11,156,4,0,
80,11,164,4,0,
80,11,174,1,1,1
80,11,182,4,0,
80,11,207,4,0,
80,11,213,4,0,
80,11,216,4,0,
80,11,218,4,0,
80,11,219,4,0,
80,11,237,4,0,
80,11,240,1,55,
80,11,240,4,0,
80,11,241,4,0,
80,11,244,1,62,
80,11,244,4,0,
80,11,247,4,0,
80,11,249,4,0,
80,11,258,4,0,
80,11,263,4,0,
80,11,280,4,0,
80,11,281,1,1,2
80,11,291,4,0,
80,11,303,1,36,
80,11,332,4,0,
80,11,347,4,0,
80,11,352,1,28,
80,11,374,4,0,
80,11,411,4,0,
80,11,416,4,0,
80,11,428,1,32,
80,11,433,4,0,
80,11,447,4,0,
80,11,473,4,0,
80,11,477,4,0,
80,11,496,4,0,
80,11,497,4,0,
80,11,503,4,0,
80,11,505,1,68,
80,11,510,4,0,
80,11,523,4,0,
80,12,29,1,34,
80,12,33,1,1,3
80,12,45,1,1,4
80,12,45,1,6,
80,12,50,1,29,
80,12,53,4,0,
80,12,55,1,15,
80,12,57,4,0,
80,12,58,4,0,
80,12,59,4,0,
80,12,63,4,0,
80,12,70,4,0,
80,12,89,4,0,
80,12,91,4,0,
80,12,92,4,0,
80,12,93,1,20,
80,12,94,1,54,
80,12,94,4,0,
80,12,104,4,0,
80,12,110,1,37,
80,12,126,4,0,
80,12,133,1,46,
80,12,148,4,0,
80,12,156,4,0,
80,12,174,1,1,1
80,12,182,4,0,
80,12,213,4,0,
80,12,216,4,0,
80,12,218,4,0,
80,12,219,4,0,
80,12,231,4,0,
80,12,237,4,0,
80,12,240,4,0,
80,12,241,4,0,
80,12,247,4,0,
80,12,249,4,0,
80,12,258,4,0,
80,12,263,4,0,
80,12,264,4,0,
80,12,280,4,0,
80,12,281,1,1,2
80,12,285,4,0,
80,12,290,4,0,
80,12,291,4,0,
80,12,347,4,0,
80,12,352,4,0,
80,13,29,1,34,
80,13,33,1,1,3
80,13,34,3,0,
80,13,38,3,0,
80,13,45,1,1,4
80,13,45,1,6,
80,13,50,1,29,
80,13,53,4,0,
80,13,55,1,15,
80,13,57,4,0,
80,13,58,4,0,
80,13,59,4,0,
80,13,63,4,0,
80,13,69,3,0,
80,13,70,4,0,
80,13,86,3,0,
80,13,89,4,0,
80,13,91,4,0,
80,13,92,4,0,
80,13,93,1,20,
80,13,94,1,54,
80,13,94,4,0,
80,13,102,3,0,
80,13,104,4,0,
80,13,110,1,37,
80,13,126,4,0,
80,13,133,1,46,
80,13,138,3,0,
80,13,148,4,0,
80,13,156,4,0,
80,13,164,3,0,
80,13,171,3,0,
80,13,174,1,1,1
80,13,182,4,0,
80,13,196,3,0,
80,13,207,3,0,
80,13,213,4,0,
80,13,216,4,0,
80,13,218,4,0,
80,13,219,4,0,
80,13,231,4,0,
80,13,237,4,0,
80,13,240,4,0,
80,13,241,4,0,
80,13,247,4,0,
80,13,249,4,0,
80,13,258,4,0,
80,13,263,4,0,
80,13,264,4,0,
80,13,280,4,0,
80,13,281,1,1,2
80,13,285,4,0,
80,13,290,4,0,
80,13,291,4,0,
80,13,347,4,0,
80,13,352,4,0,
80,14,8,3,0,
80,14,29,1,23,
80,14,33,1,1,3
80,14,45,1,1,4
80,14,45,1,5,
80,14,50,1,19,
80,14,53,4,0,
80,14,55,1,9,
80,14,57,4,0,
80,14,58,4,0,
80,14,59,4,0,
80,14,63,4,0,
80,14,70,4,0,
80,14,86,4,0,
80,14,89,4,0,
80,14,91,4,0,
80,14,92,4,0,
80,14,93,1,14,
80,14,94,1,49,
80,14,94,4,0,
80,14,104,4,0,
80,14,110,1,37,
80,14,113,4,0,
80,14,126,4,0,
80,14,133,1,43,
80,14,138,4,0,
80,14,148,4,0,
80,14,156,4,0,
80,14,164,4,0,
80,14,173,3,0,
80,14,174,1,1,1
80,14,182,4,0,
80,14,196,3,0,
80,14,207,4,0,
80,14,213,4,0,
80,14,214,3,0,
80,14,216,4,0,
80,14,218,4,0,
80,14,219,4,0,
80,14,231,3,0,
80,14,237,4,0,
80,14,240,1,55,
80,14,240,4,0,
80,14,241,4,0,
80,14,244,1,62,
80,14,244,4,0,
80,14,247,4,0,
80,14,249,4,0,
80,14,258,4,0,
80,14,263,4,0,
80,14,271,3,0,
80,14,277,3,0,
80,14,278,3,0,
80,14,280,4,0,
80,14,281,1,1,2
80,14,285,3,0,
80,14,291,4,0,
80,14,303,1,36,
80,14,324,3,0,
80,14,332,4,0,
80,14,334,3,0,
80,14,335,3,0,
80,14,347,4,0,
80,14,352,1,28,
80,14,374,4,0,
80,14,401,3,0,
80,14,409,3,0,
80,14,411,4,0,
80,14,416,4,0,
80,14,428,1,32,
80,14,428,3,0,
80,14,433,4,0,
80,14,447,4,0,
80,14,472,3,0,
80,14,473,4,0,
80,14,477,4,0,
80,14,492,3,0,
80,14,495,3,0,
80,14,496,4,0,
80,14,497,4,0,
80,14,503,4,0,
80,14,505,1,68,
80,14,510,4,0,
80,14,523,4,0,
80,15,29,1,23,
80,15,33,1,1,4
80,15,45,1,1,5
80,15,45,1,5,
80,15,50,1,19,
80,15,53,4,0,
80,15,55,1,9,
80,15,57,4,0,
80,15,58,4,0,
80,15,59,4,0,
80,15,63,4,0,
80,15,70,4,0,
80,15,86,4,0,
80,15,89,4,0,
80,15,91,4,0,
80,15,92,4,0,
80,15,93,1,14,
80,15,94,1,49,
80,15,94,4,0,
80,15,104,4,0,
80,15,110,1,37,
80,15,113,4,0,
80,15,126,4,0,
80,15,133,1,43,
80,15,138,4,0,
80,15,148,4,0,
80,15,156,4,0,
80,15,164,4,0,
80,15,174,1,1,2
80,15,182,4,0,
80,15,207,4,0,
80,15,213,4,0,
80,15,214,4,0,
80,15,216,4,0,
80,15,218,4,0,
80,15,219,4,0,
80,15,237,4,0,
80,15,240,1,55,
80,15,240,4,0,
80,15,241,4,0,
80,15,244,1,62,
80,15,244,4,0,
80,15,247,4,0,
80,15,249,4,0,
80,15,258,4,0,
80,15,263,4,0,
80,15,280,4,0,
80,15,281,1,1,3
80,15,303,1,36,
80,15,332,4,0,
80,15,347,4,0,
80,15,352,1,28,
80,15,374,4,0,
80,15,411,4,0,
80,15,416,4,0,
80,15,428,1,32,
80,15,433,4,0,
80,15,447,4,0,
80,15,473,4,0,
80,15,496,4,0,
80,15,497,4,0,
80,15,503,4,0,
80,15,505,1,1,1
80,15,505,1,68,
80,15,510,4,0,
80,15,523,4,0,
80,15,590,4,0,
80,16,8,3,0,
80,16,29,1,23,1
80,16,33,1,1,4
80,16,45,1,1,5
80,16,45,1,5,1
80,16,50,1,19,1
80,16,53,4,0,
80,16,55,1,9,1
80,16,57,4,0,
80,16,58,4,0,
80,16,59,4,0,
80,16,63,4,0,
80,16,70,4,0,
80,16,86,4,0,
80,16,89,4,0,
80,16,91,4,0,
80,16,92,4,0,
80,16,93,1,14,1
80,16,94,1,49,1
80,16,94,4,0,
80,16,104,4,0,
80,16,110,1,37,1
80,16,113,4,0,
80,16,126,4,0,
80,16,133,1,43,1
80,16,138,4,0,
80,16,148,4,0,
80,16,156,4,0,
80,16,164,4,0,
80,16,173,3,0,
80,16,174,1,1,2
80,16,182,4,0,
80,16,196,3,0,
80,16,207,4,0,
80,16,213,4,0,
80,16,214,4,0,
80,16,216,4,0,
80,16,218,4,0,
80,16,219,4,0,
80,16,231,3,0,
80,16,237,4,0,
80,16,240,1,55,1
80,16,240,4,0,
80,16,241,4,0,
80,16,244,1,62,1
80,16,244,4,0,
80,16,247,4,0,
80,16,249,4,0,
80,16,258,4,0,
80,16,263,4,0,
80,16,264,3,0,
80,16,271,3,0,
80,16,277,3,0,
80,16,278,3,0,
80,16,280,4,0,
80,16,281,1,1,3
80,16,285,3,0,
80,16,290,4,0,
80,16,291,4,0,
80,16,303,1,36,1
80,16,324,3,0,
80,16,332,4,0,
80,16,334,3,0,
80,16,335,3,0,
80,16,347,4,0,
80,16,352,1,28,1
80,16,352,3,0,
80,16,374,4,0,
80,16,401,3,0,
80,16,409,3,0,
80,16,411,4,0,
80,16,416,4,0,
80,16,428,1,32,1
80,16,428,3,0,
80,16,433,4,0,
80,16,447,4,0,
80,16,472,3,0,
80,16,473,4,0,
80,16,492,3,0,
80,16,495,3,0,
80,16,496,4,0,
80,16,497,4,0,
80,16,503,4,0,
80,16,505,1,1,1
80,16,505,1,68,1
80,16,510,4,0,
80,16,523,4,0,
80,16,590,4,0,
80,17,29,1,23,
80,17,33,1,1,5
80,17,45,1,1,6
80,17,45,1,5,
80,17,50,1,19,
80,17,53,4,0,
80,17,55,1,9,
80,17,57,4,0,
80,17,58,4,0,
80,17,59,4,0,
80,17,63,4,0,
80,17,86,4,0,
80,17,89,4,0,
80,17,92,4,0,
80,17,93,1,14,
80,17,94,1,49,
80,17,94,4,0,
80,17,104,4,0,
80,17,110,1,0,
80,17,110,1,1,1
80,17,113,4,0,
80,17,126,4,0,
80,17,133,1,43,
80,17,138,4,0,
80,17,156,4,0,
80,17,164,4,0,
80,17,174,1,1,3
80,17,182,4,0,
80,17,207,4,0,
80,17,213,4,0,
80,17,214,4,0,
80,17,216,4,0,
80,17,218,4,0,
80,17,219,4,0,
80,17,237,4,0,
80,17,240,1,55,
80,17,240,4,0,
80,17,241,4,0,
80,17,244,1,62,
80,17,244,4,0,
80,17,247,4,0,
80,17,258,4,0,
80,17,263,4,0,
80,17,280,4,0,
80,17,281,1,1,4
80,17,303,1,36,
80,17,332,4,0,
80,17,347,4,0,
80,17,352,1,28,
80,17,374,4,0,
80,17,411,4,0,
80,17,416,4,0,
80,17,428,1,32,
80,17,433,4,0,
80,17,447,4,0,
80,17,473,4,0,
80,17,496,4,0,
80,17,497,4,0,
80,17,503,4,0,
80,17,505,1,1,2
80,17,505,1,68,
80,17,523,4,0,
80,17,590,4,0,
80,18,29,1,23,
80,18,33,1,1,5
80,18,45,1,1,6
80,18,45,1,5,
80,18,50,1,19,
80,18,53,4,0,
80,18,55,1,9,
80,18,57,4,0,
80,18,58,4,0,
80,18,59,4,0,
80,18,63,4,0,
80,18,86,4,0,
80,18,89,4,0,
80,18,92,4,0,
80,18,93,1,14,
80,18,94,1,49,
80,18,94,4,0,
80,18,104,4,0,
80,18,110,1,0,
80,18,110,1,1,1
80,18,113,4,0,
80,18,126,4,0,
80,18,133,1,43,
80,18,138,4,0,
80,18,156,4,0,
80,18,164,4,0,
80,18,174,1,1,3
80,18,182,4,0,
80,18,207,4,0,
80,18,213,4,0,
80,18,214,4,0,
80,18,216,4,0,
80,18,218,4,0,
80,18,219,4,0,
80,18,237,4,0,
80,18,240,1,55,
80,18,240,4,0,
80,18,241,4,0,
80,18,244,1,62,
80,18,244,4,0,
80,18,247,4,0,
80,18,258,4,0,
80,18,263,4,0,
80,18,280,4,0,
80,18,281,1,1,4
80,18,303,1,36,
80,18,332,4,0,
80,18,347,4,0,
80,18,352,1,28,
80,18,374,4,0,
80,18,411,4,0,
80,18,416,4,0,
80,18,428,1,32,
80,18,433,4,0,
80,18,447,4,0,
80,18,473,4,0,
80,18,496,4,0,
80,18,497,4,0,
80,18,503,4,0,
80,18,505,1,1,2
80,18,505,1,68,
80,18,523,4,0,
80,18,590,4,0,
81,1,33,1,1,
81,1,36,4,0,
81,1,38,4,0,
81,1,48,1,29,
81,1,49,1,21,
81,1,84,1,25,
81,1,85,4,0,
81,1,86,1,35,
81,1,86,4,0,
81,1,87,4,0,
81,1,92,4,0,
81,1,99,4,0,
81,1,100,4,0,
81,1,102,4,0,
81,1,103,1,47,
81,1,104,4,0,
81,1,115,4,0,
81,1,117,4,0,
81,1,129,1,41,
81,1,129,4,0,
81,1,148,4,0,
81,1,156,4,0,
81,1,164,4,0,
81,2,33,1,1,
81,2,36,4,0,
81,2,38,4,0,
81,2,48,1,29,
81,2,49,1,21,
81,2,84,1,25,
81,2,85,4,0,
81,2,86,1,35,
81,2,86,4,0,
81,2,87,4,0,
81,2,92,4,0,
81,2,99,4,0,
81,2,100,4,0,
81,2,102,4,0,
81,2,103,1,47,
81,2,104,4,0,
81,2,115,4,0,
81,2,117,4,0,
81,2,129,1,41,
81,2,129,4,0,
81,2,148,4,0,
81,2,156,4,0,
81,2,164,4,0,
81,3,33,1,1,
81,3,48,1,11,
81,3,49,1,16,
81,3,84,1,6,
81,3,86,1,21,
81,3,87,4,0,
81,3,92,4,0,
81,3,103,1,39,
81,3,104,4,0,
81,3,129,1,33,
81,3,129,4,0,
81,3,148,4,0,
81,3,156,4,0,
81,3,173,4,0,
81,3,174,4,0,
81,3,182,4,0,
81,3,192,1,45,
81,3,192,4,0,
81,3,199,1,27,
81,3,203,4,0,
81,3,205,4,0,
81,3,207,4,0,
81,3,214,4,0,
81,3,216,4,0,
81,3,218,4,0,
81,3,237,4,0,
81,3,240,4,0,
81,4,33,1,1,
81,4,48,1,11,
81,4,49,1,16,
81,4,84,1,6,
81,4,85,3,0,
81,4,86,1,21,
81,4,87,4,0,
81,4,92,4,0,
81,4,103,1,39,
81,4,104,4,0,
81,4,129,1,33,
81,4,129,4,0,
81,4,148,4,0,
81,4,156,4,0,
81,4,173,4,0,
81,4,174,4,0,
81,4,182,4,0,
81,4,192,1,45,
81,4,192,4,0,
81,4,199,1,27,
81,4,203,4,0,
81,4,205,4,0,
81,4,207,4,0,
81,4,214,4,0,
81,4,216,4,0,
81,4,218,4,0,
81,4,237,4,0,
81,4,240,4,0,
81,5,33,1,1,2
81,5,48,1,11,
81,5,49,1,16,
81,5,84,1,6,
81,5,85,4,0,
81,5,86,1,21,
81,5,87,4,0,
81,5,92,4,0,
81,5,103,1,44,
81,5,104,4,0,
81,5,115,4,0,
81,5,129,1,38,
81,5,148,4,0,
81,5,156,4,0,
81,5,182,4,0,
81,5,192,1,50,
81,5,199,1,32,
81,5,209,1,26,
81,5,216,4,0,
81,5,218,4,0,
81,5,237,4,0,
81,5,240,4,0,
81,5,241,4,0,
81,5,263,4,0,
81,5,290,4,0,
81,5,319,1,1,1
81,5,351,4,0,
81,6,33,1,1,2
81,6,38,3,0,
81,6,48,1,11,
81,6,49,1,16,
81,6,84,1,6,
81,6,85,4,0,
81,6,86,1,21,
81,6,86,3,0,
81,6,87,4,0,
81,6,92,4,0,
81,6,102,3,0,
81,6,103,1,44,
81,6,104,4,0,
81,6,115,4,0,
81,6,129,1,38,
81,6,129,3,0,
81,6,148,4,0,
81,6,156,4,0,
81,6,164,3,0,
81,6,173,3,0,
81,6,182,4,0,
81,6,192,1,50,
81,6,199,1,32,
81,6,203,3,0,
81,6,205,3,0,
81,6,207,3,0,
81,6,209,1,26,
81,6,214,3,0,
81,6,216,4,0,
81,6,218,4,0,
81,6,237,4,0,
81,6,240,4,0,
81,6,241,4,0,
81,6,263,4,0,
81,6,290,4,0,
81,6,319,1,1,1
81,6,351,4,0,
81,7,33,1,1,2
81,7,38,3,0,
81,7,48,1,11,
81,7,49,1,16,
81,7,84,1,6,
81,7,85,4,0,
81,7,86,1,21,
81,7,86,3,0,
81,7,87,4,0,
81,7,92,4,0,
81,7,102,3,0,
81,7,103,1,44,
81,7,104,4,0,
81,7,115,4,0,
81,7,129,1,38,
81,7,148,4,0,
81,7,156,4,0,
81,7,164,3,0,
81,7,182,4,0,
81,7,192,1,50,
81,7,199,1,32,
81,7,209,1,26,
81,7,216,4,0,
81,7,218,4,0,
81,7,237,4,0,
81,7,240,4,0,
81,7,241,4,0,
81,7,263,4,0,
81,7,290,4,0,
81,7,319,1,1,1
81,7,351,4,0,
81,8,33,1,1,2
81,8,48,1,11,
81,8,49,1,14,
81,8,84,1,6,
81,8,85,4,0,
81,8,86,1,17,
81,8,86,4,0,
81,8,87,4,0,
81,8,92,4,0,
81,8,103,1,33,
81,8,104,4,0,
81,8,113,4,0,
81,8,115,4,0,
81,8,148,4,0,
81,8,153,4,0,
81,8,156,4,0,
81,8,164,4,0,
81,8,182,4,0,
81,8,192,1,54,
81,8,199,1,27,
81,8,203,4,0,
81,8,207,4,0,
81,8,209,1,22,
81,8,214,4,0,
81,8,216,4,0,
81,8,218,4,0,
81,8,237,4,0,
81,8,240,4,0,
81,8,241,4,0,
81,8,244,4,0,
81,8,263,4,0,
81,8,278,4,0,
81,8,290,4,0,
81,8,319,1,1,1
81,8,351,4,0,
81,8,360,1,49,
81,8,360,4,0,
81,8,363,4,0,
81,8,393,1,46,
81,8,429,1,43,
81,8,430,4,0,
81,8,435,1,38,
81,8,443,1,30,
81,8,451,4,0,
81,9,33,1,1,2
81,9,48,1,11,
81,9,49,1,14,
81,9,84,1,6,
81,9,85,4,0,
81,9,86,1,17,
81,9,86,4,0,
81,9,87,4,0,
81,9,92,4,0,
81,9,103,1,33,
81,9,104,4,0,
81,9,113,4,0,
81,9,115,4,0,
81,9,129,3,0,
81,9,148,4,0,
81,9,153,4,0,
81,9,156,4,0,
81,9,164,4,0,
81,9,173,3,0,
81,9,182,4,0,
81,9,192,1,54,
81,9,199,1,27,
81,9,203,4,0,
81,9,205,3,0,
81,9,207,4,0,
81,9,209,1,22,
81,9,214,4,0,
81,9,216,4,0,
81,9,218,4,0,
81,9,237,4,0,
81,9,240,4,0,
81,9,241,4,0,
81,9,244,4,0,
81,9,263,4,0,
81,9,278,4,0,
81,9,290,4,0,
81,9,319,1,1,1
81,9,324,3,0,
81,9,334,3,0,
81,9,351,4,0,
81,9,360,1,49,
81,9,360,4,0,
81,9,363,4,0,
81,9,393,1,46,
81,9,393,3,0,
81,9,429,1,43,
81,9,430,4,0,
81,9,435,1,38,
81,9,443,1,30,
81,9,451,4,0,
81,10,33,1,1,2
81,10,48,1,11,
81,10,49,1,14,
81,10,84,1,6,
81,10,85,4,0,
81,10,86,1,17,
81,10,86,4,0,
81,10,87,4,0,
81,10,92,4,0,
81,10,103,1,33,
81,10,104,4,0,
81,10,113,4,0,
81,10,115,4,0,
81,10,129,3,0,
81,10,148,4,0,
81,10,153,4,0,
81,10,156,4,0,
81,10,164,4,0,
81,10,173,3,0,
81,10,182,4,0,
81,10,192,1,54,
81,10,199,1,27,
81,10,203,4,0,
81,10,205,3,0,
81,10,207,4,0,
81,10,209,1,22,
81,10,214,4,0,
81,10,216,4,0,
81,10,218,4,0,
81,10,237,4,0,
81,10,240,4,0,
81,10,241,4,0,
81,10,244,4,0,
81,10,263,4,0,
81,10,277,3,0,
81,10,278,4,0,
81,10,290,4,0,
81,10,319,1,1,1
81,10,324,3,0,
81,10,334,3,0,
81,10,351,4,0,
81,10,356,3,0,
81,10,360,1,49,
81,10,360,4,0,
81,10,363,4,0,
81,10,393,1,46,
81,10,393,3,0,
81,10,429,1,43,
81,10,430,4,0,
81,10,435,1,38,
81,10,443,1,30,
81,10,451,4,0,
81,11,33,1,1,2
81,11,48,1,11,
81,11,49,1,14,
81,11,84,1,6,
81,11,85,4,0,
81,11,86,1,17,
81,11,86,4,0,
81,11,87,4,0,
81,11,92,4,0,
81,11,103,1,38,
81,11,104,4,0,
81,11,113,4,0,
81,11,115,4,0,
81,11,148,4,0,
81,11,153,4,0,
81,11,156,4,0,
81,11,164,4,0,
81,11,182,4,0,
81,11,192,1,59,
81,11,199,1,30,
81,11,207,4,0,
81,11,209,1,22,
81,11,216,4,0,
81,11,218,4,0,
81,11,237,4,0,
81,11,240,4,0,
81,11,241,4,0,
81,11,244,4,0,
81,11,263,4,0,
81,11,319,1,1,1
81,11,360,1,54,
81,11,360,4,0,
81,11,393,1,49,
81,11,429,1,46,
81,11,430,4,0,
81,11,435,1,43,
81,11,443,1,33,
81,11,451,4,0,
81,11,486,1,27,
81,11,496,4,0,
81,11,521,4,0,
81,11,528,4,0,
81,12,33,1,1,2
81,12,48,1,11,
81,12,49,1,16,
81,12,84,1,6,
81,12,85,4,0,
81,12,86,1,21,
81,12,87,4,0,
81,12,92,4,0,
81,12,103,1,44,
81,12,104,4,0,
81,12,115,4,0,
81,12,129,1,38,
81,12,148,4,0,
81,12,156,4,0,
81,12,182,4,0,
81,12,192,1,50,
81,12,199,1,32,
81,12,209,1,26,
81,12,216,4,0,
81,12,218,4,0,
81,12,237,4,0,
81,12,240,4,0,
81,12,241,4,0,
81,12,263,4,0,
81,12,290,4,0,
81,12,319,1,1,1
81,12,351,4,0,
81,13,33,1,1,2
81,13,38,3,0,
81,13,48,1,11,
81,13,49,1,16,
81,13,84,1,6,
81,13,85,4,0,
81,13,86,1,21,
81,13,86,3,0,
81,13,87,4,0,
81,13,92,4,0,
81,13,102,3,0,
81,13,103,1,44,
81,13,104,4,0,
81,13,115,4,0,
81,13,129,1,38,
81,13,148,4,0,
81,13,156,4,0,
81,13,164,3,0,
81,13,182,4,0,
81,13,192,1,50,
81,13,199,1,32,
81,13,207,3,0,
81,13,209,1,26,
81,13,216,4,0,
81,13,218,4,0,
81,13,237,4,0,
81,13,240,4,0,
81,13,241,4,0,
81,13,263,4,0,
81,13,290,4,0,
81,13,319,1,1,1
81,13,351,4,0,
81,14,33,1,1,
81,14,48,1,4,
81,14,49,1,11,
81,14,84,1,7,
81,14,85,4,0,
81,14,86,1,15,
81,14,86,4,0,
81,14,87,4,0,
81,14,92,4,0,
81,14,103,1,39,
81,14,104,4,0,
81,14,113,4,0,
81,14,115,4,0,
81,14,148,4,0,
81,14,153,4,0,
81,14,156,4,0,
81,14,164,4,0,
81,14,173,3,0,
81,14,182,4,0,
81,14,192,1,57,
81,14,199,1,46,
81,14,207,4,0,
81,14,209,1,21,
81,14,214,3,0,
81,14,216,4,0,
81,14,218,4,0,
81,14,237,4,0,
81,14,240,4,0,
81,14,241,4,0,
81,14,244,4,0,
81,14,263,4,0,
81,14,277,3,0,
81,14,278,3,0,
81,14,319,1,29,
81,14,324,3,0,
81,14,334,3,0,
81,14,356,3,0,
81,14,360,1,53,
81,14,360,4,0,
81,14,393,1,49,
81,14,393,3,0,
81,14,429,1,25,
81,14,430,1,35,
81,14,430,4,0,
81,14,435,1,43,
81,14,443,1,18,
81,14,451,4,0,
81,14,486,1,32,
81,14,496,4,0,
81,14,521,4,0,
81,14,527,3,0,
81,14,528,4,0,
81,15,33,1,1,
81,15,48,1,4,
81,15,49,1,11,
81,15,84,1,7,
81,15,85,4,0,
81,15,86,1,15,
81,15,86,4,0,
81,15,87,4,0,
81,15,92,4,0,
81,15,103,1,39,
81,15,104,4,0,
81,15,113,4,0,
81,15,115,4,0,
81,15,148,4,0,
81,15,153,4,0,
81,15,156,4,0,
81,15,164,4,0,
81,15,182,4,0,
81,15,192,1,57,
81,15,199,1,46,
81,15,207,4,0,
81,15,209,1,21,
81,15,214,4,0,
81,15,216,4,0,
81,15,218,4,0,
81,15,237,4,0,
81,15,240,4,0,
81,15,241,4,0,
81,15,244,4,0,
81,15,263,4,0,
81,15,319,1,29,
81,15,360,1,53,
81,15,360,4,0,
81,15,393,1,49,
81,15,429,1,25,
81,15,430,1,35,
81,15,430,4,0,
81,15,435,1,43,
81,15,443,1,18,
81,15,451,4,0,
81,15,486,1,32,
81,15,496,4,0,
81,15,521,4,0,
81,15,528,4,0,
81,15,590,4,0,
81,16,33,1,1,1
81,16,48,1,5,1
81,16,49,1,11,1
81,16,84,1,7,1
81,16,85,4,0,
81,16,86,1,13,1
81,16,86,4,0,
81,16,87,4,0,
81,16,92,4,0,
81,16,103,1,35,1
81,16,104,4,0,
81,16,113,4,0,
81,16,115,4,0,
81,16,148,4,0,
81,16,153,4,0,
81,16,156,4,0,
81,16,164,4,0,
81,16,173,3,0,
81,16,182,4,0,
81,16,192,1,49,1
81,16,199,1,41,1
81,16,207,4,0,
81,16,209,1,19,1
81,16,214,4,0,
81,16,216,4,0,
81,16,218,4,0,
81,16,237,4,0,
81,16,240,4,0,
81,16,241,4,0,
81,16,244,4,0,
81,16,263,4,0,
81,16,277,3,0,
81,16,278,3,0,
81,16,290,4,0,
81,16,319,1,25,1
81,16,324,3,0,
81,16,334,3,0,
81,16,351,3,0,
81,16,356,3,0,
81,16,360,1,47,1
81,16,360,4,0,
81,16,393,1,43,1
81,16,393,3,0,
81,16,429,1,23,1
81,16,430,1,31,1
81,16,430,4,0,
81,16,435,1,37,1
81,16,443,1,17,1
81,16,451,4,0,
81,16,486,1,29,1
81,16,496,4,0,
81,16,521,4,0,
81,16,527,3,0,
81,16,528,4,0,
81,16,590,4,0,
81,17,33,1,1,1
81,17,48,1,1,2
81,17,49,1,17,
81,17,84,1,5,
81,17,85,4,0,
81,17,86,1,11,
81,17,86,4,0,
81,17,87,4,0,
81,17,92,4,0,
81,17,103,1,35,
81,17,104,4,0,
81,17,113,1,13,
81,17,113,4,0,
81,17,115,4,0,
81,17,153,4,0,
81,17,156,4,0,
81,17,164,4,0,
81,17,182,4,0,
81,17,192,1,49,
81,17,199,1,41,
81,17,207,4,0,
81,17,209,1,19,
81,17,214,4,0,
81,17,216,4,0,
81,17,218,4,0,
81,17,237,4,0,
81,17,240,4,0,
81,17,241,4,0,
81,17,244,4,0,
81,17,263,4,0,
81,17,319,1,25,
81,17,360,1,47,
81,17,360,4,0,
81,17,393,1,43,
81,17,429,1,23,
81,17,430,1,31,
81,17,430,4,0,
81,17,435,1,37,
81,17,443,1,7,
81,17,451,4,0,
81,17,486,1,29,
81,17,496,4,0,
81,17,521,4,0,
81,17,528,4,0,
81,17,590,4,0,
81,18,33,1,1,1
81,18,48,1,1,2
81,18,49,1,17,
81,18,84,1,5,
81,18,85,4,0,
81,18,86,1,7,
81,18,86,4,0,
81,18,87,4,0,
81,18,92,4,0,
81,18,103,1,35,
81,18,104,4,0,
81,18,113,1,13,
81,18,113,4,0,
81,18,115,4,0,
81,18,153,4,0,
81,18,156,4,0,
81,18,164,4,0,
81,18,182,4,0,
81,18,192,1,49,
81,18,199,1,41,
81,18,207,4,0,
81,18,209,1,19,
81,18,214,4,0,
81,18,216,4,0,
81,18,218,4,0,
81,18,237,4,0,
81,18,240,4,0,
81,18,241,4,0,
81,18,244,4,0,
81,18,263,4,0,
81,18,319,1,25,
81,18,360,1,47,
81,18,360,4,0,
81,18,393,1,43,
81,18,429,1,23,
81,18,430,1,31,
81,18,430,4,0,
81,18,435,1,37,
81,18,443,1,11,
81,18,451,4,0,
81,18,486,1,29,
81,18,496,4,0,
81,18,521,4,0,
81,18,528,4,0,
81,18,590,4,0,
82,1,33,1,1,1
82,1,36,4,0,
82,1,38,4,0,
82,1,48,1,29,
82,1,49,1,1,2
82,1,49,1,21,
82,1,63,4,0,
82,1,84,1,1,3
82,1,84,1,25,
82,1,85,4,0,
82,1,86,1,38,
82,1,86,4,0,
82,1,87,4,0,
82,1,92,4,0,
82,1,99,4,0,
82,1,100,4,0,
82,1,102,4,0,
82,1,103,1,54,
82,1,104,4,0,
82,1,115,4,0,
82,1,117,4,0,
82,1,129,1,46,
82,1,129,4,0,
82,1,148,4,0,
82,1,156,4,0,
82,1,164,4,0,
82,2,33,1,1,1
82,2,36,4,0,
82,2,38,4,0,
82,2,48,1,29,
82,2,49,1,1,2
82,2,49,1,21,
82,2,63,4,0,
82,2,84,1,1,3
82,2,84,1,25,
82,2,85,4,0,
82,2,86,1,38,
82,2,86,4,0,
82,2,87,4,0,
82,2,92,4,0,
82,2,99,4,0,
82,2,100,4,0,
82,2,102,4,0,
82,2,103,1,54,
82,2,104,4,0,
82,2,115,4,0,
82,2,117,4,0,
82,2,129,1,46,
82,2,129,4,0,
82,2,148,4,0,
82,2,156,4,0,
82,2,164,4,0,
82,3,33,1,1,1
82,3,48,1,1,3
82,3,48,1,11,
82,3,49,1,1,4
82,3,49,1,16,
82,3,63,4,0,
82,3,84,1,1,2
82,3,84,1,6,
82,3,86,1,21,
82,3,87,4,0,
82,3,92,4,0,
82,3,103,1,43,
82,3,104,4,0,
82,3,129,1,35,
82,3,129,4,0,
82,3,148,4,0,
82,3,156,4,0,
82,3,173,4,0,
82,3,174,4,0,
82,3,182,4,0,
82,3,192,1,53,
82,3,192,4,0,
82,3,199,1,27,
82,3,203,4,0,
82,3,205,4,0,
82,3,207,4,0,
82,3,214,4,0,
82,3,216,4,0,
82,3,218,4,0,
82,3,237,4,0,
82,3,240,4,0,
82,4,33,1,1,1
82,4,48,1,1,3
82,4,48,1,11,
82,4,49,1,1,4
82,4,49,1,16,
82,4,63,4,0,
82,4,84,1,1,2
82,4,84,1,6,
82,4,85,3,0,
82,4,86,1,21,
82,4,87,4,0,
82,4,92,4,0,
82,4,103,1,43,
82,4,104,4,0,
82,4,129,4,0,
82,4,148,4,0,
82,4,156,4,0,
82,4,161,1,35,
82,4,173,4,0,
82,4,174,4,0,
82,4,182,4,0,
82,4,192,1,53,
82,4,192,4,0,
82,4,199,1,27,
82,4,203,4,0,
82,4,205,4,0,
82,4,207,4,0,
82,4,214,4,0,
82,4,216,4,0,
82,4,218,4,0,
82,4,237,4,0,
82,4,240,4,0,
82,5,33,1,1,2
82,5,48,1,1,4
82,5,48,1,11,
82,5,49,1,16,
82,5,63,4,0,
82,5,84,1,1,3
82,5,84,1,6,
82,5,85,4,0,
82,5,86,1,21,
82,5,87,4,0,
82,5,92,4,0,
82,5,103,1,53,
82,5,104,4,0,
82,5,115,4,0,
82,5,148,4,0,
82,5,156,4,0,
82,5,161,1,44,
82,5,182,4,0,
82,5,192,1,62,
82,5,199,1,35,
82,5,209,1,26,
82,5,216,4,0,
82,5,218,4,0,
82,5,237,4,0,
82,5,240,4,0,
82,5,241,4,0,
82,5,263,4,0,
82,5,290,4,0,
82,5,319,1,1,1
82,5,351,4,0,
82,6,33,1,1,2
82,6,38,3,0,
82,6,48,1,1,4
82,6,48,1,11,
82,6,49,1,16,
82,6,63,4,0,
82,6,84,1,1,3
82,6,84,1,6,
82,6,85,4,0,
82,6,86,1,21,
82,6,86,3,0,
82,6,87,4,0,
82,6,92,4,0,
82,6,102,3,0,
82,6,103,1,53,
82,6,104,4,0,
82,6,115,4,0,
82,6,129,3,0,
82,6,148,4,0,
82,6,156,4,0,
82,6,161,1,44,
82,6,164,3,0,
82,6,173,3,0,
82,6,182,4,0,
82,6,192,1,62,
82,6,199,1,35,
82,6,203,3,0,
82,6,205,3,0,
82,6,207,3,0,
82,6,209,1,26,
82,6,214,3,0,
82,6,216,4,0,
82,6,218,4,0,
82,6,237,4,0,
82,6,240,4,0,
82,6,241,4,0,
82,6,263,4,0,
82,6,290,4,0,
82,6,319,1,1,1
82,6,351,4,0,
82,7,33,1,1,2
82,7,38,3,0,
82,7,48,1,1,4
82,7,48,1,11,
82,7,49,1,16,
82,7,63,4,0,
82,7,84,1,1,3
82,7,84,1,6,
82,7,85,4,0,
82,7,86,1,21,
82,7,86,3,0,
82,7,87,4,0,
82,7,92,4,0,
82,7,102,3,0,
82,7,103,1,53,
82,7,104,4,0,
82,7,115,4,0,
82,7,148,4,0,
82,7,156,4,0,
82,7,161,1,44,
82,7,164,3,0,
82,7,182,4,0,
82,7,192,1,62,
82,7,199,1,35,
82,7,209,1,26,
82,7,216,4,0,
82,7,218,4,0,
82,7,237,4,0,
82,7,240,4,0,
82,7,241,4,0,
82,7,263,4,0,
82,7,290,4,0,
82,7,319,1,1,1
82,7,351,4,0,
82,8,33,1,1,3
82,8,48,1,1,5
82,8,48,1,11,
82,8,49,1,14,
82,8,63,4,0,
82,8,84,1,1,4
82,8,84,1,6,
82,8,85,4,0,
82,8,86,1,17,
82,8,86,4,0,
82,8,87,4,0,
82,8,92,4,0,
82,8,103,1,34,
82,8,104,4,0,
82,8,113,4,0,
82,8,115,4,0,
82,8,148,4,0,
82,8,153,4,0,
82,8,156,4,0,
82,8,161,1,1,1
82,8,164,4,0,
82,8,182,4,0,
82,8,192,1,60,
82,8,199,1,27,
82,8,203,4,0,
82,8,207,4,0,
82,8,209,1,22,
82,8,214,4,0,
82,8,216,4,0,
82,8,218,4,0,
82,8,237,4,0,
82,8,240,4,0,
82,8,241,4,0,
82,8,244,4,0,
82,8,263,4,0,
82,8,278,4,0,
82,8,290,4,0,
82,8,319,1,1,2
82,8,351,4,0,
82,8,360,1,54,
82,8,360,4,0,
82,8,363,4,0,
82,8,393,1,50,
82,8,416,4,0,
82,8,429,1,46,
82,8,430,4,0,
82,8,435,1,40,
82,8,443,1,30,
82,8,451,4,0,
82,9,33,1,1,3
82,9,48,1,1,5
82,9,48,1,11,
82,9,49,1,14,
82,9,63,4,0,
82,9,84,1,1,4
82,9,84,1,6,
82,9,85,4,0,
82,9,86,1,17,
82,9,86,4,0,
82,9,87,4,0,
82,9,92,4,0,
82,9,103,1,34,
82,9,104,4,0,
82,9,113,4,0,
82,9,115,4,0,
82,9,129,3,0,
82,9,148,4,0,
82,9,153,4,0,
82,9,156,4,0,
82,9,161,1,1,1
82,9,164,4,0,
82,9,173,3,0,
82,9,182,4,0,
82,9,192,1,60,
82,9,199,1,27,
82,9,203,4,0,
82,9,205,3,0,
82,9,207,4,0,
82,9,209,1,22,
82,9,214,4,0,
82,9,216,4,0,
82,9,218,4,0,
82,9,237,4,0,
82,9,240,4,0,
82,9,241,4,0,
82,9,244,4,0,
82,9,263,4,0,
82,9,278,4,0,
82,9,290,4,0,
82,9,319,1,1,2
82,9,324,3,0,
82,9,334,3,0,
82,9,351,4,0,
82,9,360,1,54,
82,9,360,4,0,
82,9,363,4,0,
82,9,393,1,50,
82,9,393,3,0,
82,9,416,4,0,
82,9,429,1,46,
82,9,430,4,0,
82,9,435,1,40,
82,9,443,1,30,
82,9,451,4,0,
82,10,33,1,1,3
82,10,48,1,1,5
82,10,48,1,11,
82,10,49,1,14,
82,10,63,4,0,
82,10,84,1,1,4
82,10,84,1,6,
82,10,85,4,0,
82,10,86,1,17,
82,10,86,4,0,
82,10,87,4,0,
82,10,92,4,0,
82,10,103,1,34,
82,10,104,4,0,
82,10,113,4,0,
82,10,115,4,0,
82,10,129,3,0,
82,10,148,4,0,
82,10,153,4,0,
82,10,156,4,0,
82,10,161,1,1,1
82,10,164,4,0,
82,10,173,3,0,
82,10,182,4,0,
82,10,192,1,60,
82,10,199,1,27,
82,10,203,4,0,
82,10,205,3,0,
82,10,207,4,0,
82,10,209,1,22,
82,10,214,4,0,
82,10,216,4,0,
82,10,218,4,0,
82,10,237,4,0,
82,10,240,4,0,
82,10,241,4,0,
82,10,244,4,0,
82,10,263,4,0,
82,10,277,3,0,
82,10,278,4,0,
82,10,290,4,0,
82,10,319,1,1,2
82,10,324,3,0,
82,10,334,3,0,
82,10,351,4,0,
82,10,356,3,0,
82,10,360,1,54,
82,10,360,4,0,
82,10,363,4,0,
82,10,393,1,50,
82,10,393,3,0,
82,10,416,4,0,
82,10,429,1,46,
82,10,430,4,0,
82,10,435,1,40,
82,10,443,1,30,
82,10,451,4,0,
82,11,33,1,1,3
82,11,48,1,1,5
82,11,48,1,11,
82,11,49,1,14,
82,11,63,4,0,
82,11,84,1,1,4
82,11,84,1,6,
82,11,85,4,0,
82,11,86,1,17,
82,11,86,4,0,
82,11,87,4,0,
82,11,92,4,0,
82,11,103,1,40,
82,11,104,4,0,
82,11,113,4,0,
82,11,115,4,0,
82,11,148,4,0,
82,11,153,4,0,
82,11,156,4,0,
82,11,161,1,1,1
82,11,164,4,0,
82,11,182,4,0,
82,11,192,1,66,
82,11,199,1,30,
82,11,207,4,0,
82,11,209,1,22,
82,11,216,4,0,
82,11,218,4,0,
82,11,237,4,0,
82,11,240,4,0,
82,11,241,4,0,
82,11,244,4,0,
82,11,263,4,0,
82,11,319,1,1,2
82,11,360,1,60,
82,11,360,4,0,
82,11,393,1,54,
82,11,416,4,0,
82,11,429,1,50,
82,11,430,4,0,
82,11,435,1,46,
82,11,443,1,34,
82,11,451,4,0,
82,11,486,1,27,
82,11,496,4,0,
82,11,521,4,0,
82,11,528,4,0,
82,12,33,1,1,2
82,12,48,1,1,4
82,12,48,1,11,
82,12,49,1,16,
82,12,63,4,0,
82,12,84,1,1,3
82,12,84,1,6,
82,12,85,4,0,
82,12,86,1,21,
82,12,87,4,0,
82,12,92,4,0,
82,12,103,1,53,
82,12,104,4,0,
82,12,115,4,0,
82,12,148,4,0,
82,12,156,4,0,
82,12,161,1,44,
82,12,182,4,0,
82,12,192,1,62,
82,12,199,1,35,
82,12,209,1,26,
82,12,216,4,0,
82,12,218,4,0,
82,12,237,4,0,
82,12,240,4,0,
82,12,241,4,0,
82,12,263,4,0,
82,12,290,4,0,
82,12,319,1,1,1
82,12,351,4,0,
82,13,33,1,1,2
82,13,38,3,0,
82,13,48,1,1,4
82,13,48,1,11,
82,13,49,1,16,
82,13,63,4,0,
82,13,84,1,1,3
82,13,84,1,6,
82,13,85,4,0,
82,13,86,1,21,
82,13,86,3,0,
82,13,87,4,0,
82,13,92,4,0,
82,13,102,3,0,
82,13,103,1,53,
82,13,104,4,0,
82,13,115,4,0,
82,13,148,4,0,
82,13,156,4,0,
82,13,161,1,44,
82,13,164,3,0,
82,13,182,4,0,
82,13,192,1,62,
82,13,199,1,35,
82,13,207,3,0,
82,13,209,1,26,
82,13,216,4,0,
82,13,218,4,0,
82,13,237,4,0,
82,13,240,4,0,
82,13,241,4,0,
82,13,263,4,0,
82,13,290,4,0,
82,13,319,1,1,1
82,13,351,4,0,
82,14,33,1,1,2
82,14,48,1,1,3
82,14,48,1,4,
82,14,49,1,1,5
82,14,49,1,11,
82,14,63,4,0,
82,14,84,1,1,4
82,14,84,1,7,
82,14,85,4,0,
82,14,86,1,15,
82,14,86,4,0,
82,14,87,4,0,
82,14,92,4,0,
82,14,103,1,45,
82,14,104,4,0,
82,14,113,4,0,
82,14,115,4,0,
82,14,148,4,0,
82,14,153,4,0,
82,14,156,4,0,
82,14,161,1,1,1
82,14,164,4,0,
82,14,173,3,0,
82,14,182,4,0,
82,14,192,1,73,
82,14,199,1,56,
82,14,207,4,0,
82,14,209,1,21,
82,14,214,3,0,
82,14,216,4,0,
82,14,218,4,0,
82,14,237,4,0,
82,14,240,4,0,
82,14,241,4,0,
82,14,244,4,0,
82,14,263,4,0,
82,14,277,3,0,
82,14,278,3,0,
82,14,319,1,29,
82,14,324,3,0,
82,14,334,3,0,
82,14,356,3,0,
82,14,360,1,67,
82,14,360,4,0,
82,14,393,1,62,
82,14,393,3,0,
82,14,416,4,0,
82,14,429,1,25,
82,14,430,1,39,
82,14,430,4,0,
82,14,435,1,51,
82,14,443,1,18,
82,14,451,4,0,
82,14,486,1,34,
82,14,496,4,0,
82,14,521,4,0,
82,14,527,3,0,
82,14,528,4,0,
82,15,33,1,1,3
82,15,48,1,1,4
82,15,48,1,4,
82,15,49,1,1,6
82,15,63,4,0,
82,15,84,1,1,5
82,15,84,1,7,
82,15,85,4,0,
82,15,86,1,15,
82,15,86,4,0,
82,15,87,4,0,
82,15,92,4,0,
82,15,103,1,45,
82,15,104,4,0,
82,15,113,4,0,
82,15,115,4,0,
82,15,148,4,0,
82,15,153,4,0,
82,15,156,4,0,
82,15,161,1,1,2
82,15,164,4,0,
82,15,182,4,0,
82,15,192,1,1,1
82,15,192,1,73,
82,15,199,1,56,
82,15,207,4,0,
82,15,209,1,21,
82,15,214,4,0,
82,15,216,4,0,
82,15,218,4,0,
82,15,237,4,0,
82,15,240,4,0,
82,15,241,4,0,
82,15,244,4,0,
82,15,263,4,0,
82,15,319,1,29,
82,15,360,1,67,
82,15,360,4,0,
82,15,393,1,62,
82,15,416,4,0,
82,15,429,1,25,
82,15,430,1,39,
82,15,430,4,0,
82,15,435,1,51,
82,15,443,1,18,
82,15,451,4,0,
82,15,486,1,34,
82,15,496,4,0,
82,15,521,4,0,
82,15,528,4,0,
82,15,590,4,0,
82,15,604,1,11,
82,16,33,1,1,3
82,16,48,1,1,4
82,16,48,1,5,1
82,16,49,1,1,6
82,16,49,1,11,1
82,16,63,4,0,
82,16,84,1,1,5
82,16,84,1,7,1
82,16,85,4,0,
82,16,86,1,13,1
82,16,86,4,0,
82,16,87,4,0,
82,16,92,4,0,
82,16,103,1,39,1
82,16,104,4,0,
82,16,113,4,0,
82,16,115,4,0,
82,16,148,4,0,
82,16,153,4,0,
82,16,156,4,0,
82,16,161,1,30,1
82,16,164,4,0,
82,16,173,3,0,
82,16,182,4,0,
82,16,192,1,1,1
82,16,192,1,63,1
82,16,199,1,49,1
82,16,207,4,0,
82,16,209,1,19,1
82,16,214,4,0,
82,16,216,4,0,
82,16,218,4,0,
82,16,237,4,0,
82,16,240,4,0,
82,16,241,4,0,
82,16,244,4,0,
82,16,263,4,0,
82,16,277,3,0,
82,16,278,3,0,
82,16,290,4,0,
82,16,319,1,25,1
82,16,324,3,0,
82,16,334,3,0,
82,16,351,3,0,
82,16,356,3,0,
82,16,360,1,59,1
82,16,360,4,0,
82,16,393,1,53,1
82,16,393,3,0,
82,16,416,4,0,
82,16,429,1,23,1
82,16,430,1,33,1
82,16,430,4,0,
82,16,435,1,43,1
82,16,443,1,17,1
82,16,451,4,0,
82,16,486,1,29,1
82,16,496,4,0,
82,16,521,4,0,
82,16,527,3,0,
82,16,528,4,0,
82,16,590,4,0,
82,16,604,1,1,2
82,17,33,1,1,4
82,17,48,1,1,5
82,17,49,1,17,
82,17,63,4,0,
82,17,84,1,1,6
82,17,84,1,5,
82,17,85,4,0,
82,17,86,1,11,
82,17,86,4,0,
82,17,87,4,0,
82,17,92,4,0,
82,17,103,1,39,
82,17,104,4,0,
82,17,113,1,13,
82,17,113,4,0,
82,17,115,4,0,
82,17,153,4,0,
82,17,156,4,0,
82,17,161,1,0,
82,17,161,1,1,1
82,17,164,4,0,
82,17,182,4,0,
82,17,192,1,1,2
82,17,192,1,63,
82,17,199,1,49,
82,17,207,4,0,
82,17,209,1,19,
82,17,214,4,0,
82,17,216,4,0,
82,17,218,4,0,
82,17,237,4,0,
82,17,240,4,0,
82,17,241,4,0,
82,17,244,4,0,
82,17,263,4,0,
82,17,319,1,25,
82,17,360,1,59,
82,17,360,4,0,
82,17,393,1,53,
82,17,416,4,0,
82,17,429,1,23,
82,17,430,1,33,
82,17,430,4,0,
82,17,435,1,43,
82,17,443,1,1,7
82,17,443,1,7,
82,17,451,4,0,
82,17,486,1,29,
82,17,496,4,0,
82,17,521,4,0,
82,17,528,4,0,
82,17,590,4,0,
82,17,604,1,1,3
82,18,33,1,1,4
82,18,48,1,1,5
82,18,49,1,17,
82,18,63,4,0,
82,18,84,1,1,6
82,18,84,1,5,
82,18,85,4,0,
82,18,86,1,1,7
82,18,86,1,7,
82,18,86,4,0,
82,18,87,4,0,
82,18,92,4,0,
82,18,103,1,39,
82,18,104,4,0,
82,18,113,1,13,
82,18,113,4,0,
82,18,115,4,0,
82,18,153,4,0,
82,18,156,4,0,
82,18,161,1,0,
82,18,161,1,1,1
82,18,164,4,0,
82,18,182,4,0,
82,18,192,1,1,2
82,18,192,1,63,
82,18,199,1,49,
82,18,207,4,0,
82,18,209,1,19,
82,18,214,4,0,
82,18,216,4,0,
82,18,218,4,0,
82,18,237,4,0,
82,18,240,4,0,
82,18,241,4,0,
82,18,244,4,0,
82,18,263,4,0,
82,18,319,1,25,
82,18,360,1,59,
82,18,360,4,0,
82,18,393,1,53,
82,18,416,4,0,
82,18,429,1,23,
82,18,430,1,33,
82,18,430,4,0,
82,18,435,1,43,
82,18,443,1,11,
82,18,451,4,0,
82,18,486,1,29,
82,18,496,4,0,
82,18,521,4,0,
82,18,528,4,0,
82,18,590,4,0,
82,18,604,1,1,3
83,1,13,4,0,
83,1,14,1,23,
83,1,14,4,0,
83,1,15,4,0,
83,1,18,4,0,
83,1,19,4,0,
83,1,28,1,1,2
83,1,31,1,15,
83,1,34,4,0,
83,1,36,4,0,
83,1,38,4,0,
83,1,43,1,7,
83,1,64,1,1,1
83,1,92,4,0,
83,1,97,1,31,
83,1,99,4,0,
83,1,102,4,0,
83,1,104,4,0,
83,1,115,4,0,
83,1,117,4,0,
83,1,129,4,0,
83,1,130,4,0,
83,1,156,4,0,
83,1,163,1,39,
83,1,164,4,0,
83,2,13,4,0,
83,2,14,1,23,
83,2,14,4,0,
83,2,15,4,0,
83,2,18,4,0,
83,2,19,4,0,
83,2,28,1,1,2
83,2,31,1,15,
83,2,34,4,0,
83,2,36,4,0,
83,2,38,4,0,
83,2,43,1,7,
83,2,64,1,1,1
83,2,92,4,0,
83,2,97,1,31,
83,2,99,4,0,
83,2,102,4,0,
83,2,104,4,0,
83,2,115,4,0,
83,2,117,4,0,
83,2,129,4,0,
83,2,130,4,0,
83,2,156,4,0,
83,2,163,1,39,
83,2,164,4,0,
83,3,14,1,25,
83,3,15,4,0,
83,3,16,2,0,
83,3,19,4,0,
83,3,28,1,7,
83,3,29,4,0,
83,3,31,1,19,
83,3,43,1,13,
83,3,64,1,1,
83,3,92,4,0,
83,3,97,1,31,
83,3,98,2,0,
83,3,104,4,0,
83,3,119,2,0,
83,3,129,4,0,
83,3,156,4,0,
83,3,163,1,37,
83,3,168,4,0,
83,3,173,4,0,
83,3,174,4,0,
83,3,175,2,0,
83,3,182,4,0,
83,3,189,4,0,
83,3,193,2,0,
83,3,197,4,0,
83,3,203,4,0,
83,3,206,1,44,
83,3,207,4,0,
83,3,211,2,0,
83,3,211,4,0,
83,3,213,4,0,
83,3,214,4,0,
83,3,216,4,0,
83,3,218,4,0,
83,3,231,4,0,
83,3,237,4,0,
83,3,241,4,0,
83,3,244,4,0,
83,4,14,1,25,
83,4,15,4,0,
83,4,16,2,0,
83,4,19,4,0,
83,4,28,1,7,
83,4,29,4,0,
83,4,31,1,19,
83,4,43,1,13,
83,4,64,1,1,
83,4,92,4,0,
83,4,97,1,31,
83,4,98,2,0,
83,4,104,4,0,
83,4,119,2,0,
83,4,129,4,0,
83,4,156,4,0,
83,4,163,1,37,
83,4,168,4,0,
83,4,173,4,0,
83,4,174,4,0,
83,4,175,2,0,
83,4,182,4,0,
83,4,189,4,0,
83,4,193,2,0,
83,4,197,4,0,
83,4,203,4,0,
83,4,206,1,44,
83,4,207,4,0,
83,4,211,4,0,
83,4,213,4,0,
83,4,214,4,0,
83,4,216,4,0,
83,4,218,4,0,
83,4,231,4,0,
83,4,237,4,0,
83,4,241,4,0,
83,4,244,4,0,
83,5,14,1,31,
83,5,15,4,0,
83,5,16,2,0,
83,5,19,4,0,
83,5,28,1,6,
83,5,31,1,16,
83,5,43,1,11,
83,5,64,1,1,
83,5,92,4,0,
83,5,97,1,36,
83,5,98,2,0,
83,5,104,4,0,
83,5,119,2,0,
83,5,156,4,0,
83,5,163,1,41,
83,5,168,4,0,
83,5,174,2,0,
83,5,175,2,0,
83,5,182,4,0,
83,5,193,2,0,
83,5,206,1,46,
83,5,210,1,26,
83,5,211,2,0,
83,5,211,4,0,
83,5,213,4,0,
83,5,216,4,0,
83,5,218,4,0,
83,5,231,4,0,
83,5,237,4,0,
83,5,241,4,0,
83,5,263,4,0,
83,5,282,1,21,
83,5,290,4,0,
83,5,297,2,0,
83,5,332,4,0,
83,6,14,1,31,
83,6,14,3,0,
83,6,15,4,0,
83,6,16,2,0,
83,6,19,4,0,
83,6,28,1,6,
83,6,31,1,16,
83,6,34,3,0,
83,6,38,3,0,
83,6,43,1,11,
83,6,64,1,1,
83,6,92,4,0,
83,6,97,1,36,
83,6,98,2,0,
83,6,102,3,0,
83,6,104,4,0,
83,6,119,2,0,
83,6,129,3,0,
83,6,156,4,0,
83,6,163,1,41,
83,6,164,3,0,
83,6,168,4,0,
83,6,173,3,0,
83,6,174,2,0,
83,6,175,2,0,
83,6,182,4,0,
83,6,189,3,0,
83,6,193,2,0,
83,6,203,3,0,
83,6,206,1,46,
83,6,207,3,0,
83,6,210,1,26,
83,6,211,2,0,
83,6,211,4,0,
83,6,213,4,0,
83,6,214,3,0,
83,6,216,4,0,
83,6,218,4,0,
83,6,231,4,0,
83,6,237,4,0,
83,6,241,4,0,
83,6,244,3,0,
83,6,263,4,0,
83,6,282,1,21,
83,6,290,4,0,
83,6,297,2,0,
83,6,332,4,0,
83,7,14,1,31,
83,7,14,3,0,
83,7,15,4,0,
83,7,16,2,0,
83,7,19,4,0,
83,7,28,1,6,
83,7,31,1,16,
83,7,34,3,0,
83,7,38,3,0,
83,7,43,1,11,
83,7,64,1,1,
83,7,92,4,0,
83,7,97,1,36,
83,7,98,2,0,
83,7,102,3,0,
83,7,104,4,0,
83,7,119,2,0,
83,7,156,4,0,
83,7,163,1,41,
83,7,164,3,0,
83,7,168,4,0,
83,7,174,2,0,
83,7,175,2,0,
83,7,182,4,0,
83,7,193,2,0,
83,7,206,1,46,
83,7,210,1,26,
83,7,211,2,0,
83,7,211,4,0,
83,7,213,4,0,
83,7,216,4,0,
83,7,218,4,0,
83,7,231,4,0,
83,7,237,4,0,
83,7,241,4,0,
83,7,263,4,0,
83,7,282,1,21,
83,7,290,4,0,
83,7,297,2,0,
83,7,332,4,0,
83,8,14,1,25,
83,8,14,4,0,
83,8,15,4,0,
83,8,16,2,0,
83,8,19,4,0,
83,8,28,1,1,3
83,8,31,1,7,
83,8,43,1,1,4
83,8,64,1,1,2
83,8,92,4,0,
83,8,97,1,31,
83,8,98,2,0,
83,8,104,4,0,
83,8,119,2,0,
83,8,156,4,0,
83,8,163,1,19,
83,8,164,4,0,
83,8,168,4,0,
83,8,174,2,0,
83,8,175,2,0,
83,8,182,4,0,
83,8,189,2,0,
83,8,193,2,0,
83,8,203,4,0,
83,8,206,1,43,
83,8,206,4,0,
83,8,207,4,0,
83,8,210,1,1,5
83,8,211,2,0,
83,8,211,4,0,
83,8,213,4,0,
83,8,214,4,0,
83,8,216,4,0,
83,8,218,4,0,
83,8,231,4,0,
83,8,237,4,0,
83,8,241,4,0,
83,8,244,4,0,
83,8,263,4,0,
83,8,282,1,9,
83,8,290,4,0,
83,8,297,2,0,
83,8,314,1,21,
83,8,332,1,13,
83,8,332,4,0,
83,8,343,2,0,
83,8,355,4,0,
83,8,363,4,0,
83,8,365,4,0,
83,8,369,4,0,
83,8,398,1,1,1
83,8,398,4,0,
83,8,400,1,33,
83,8,400,2,0,
83,8,403,1,37,
83,8,432,4,0,
83,8,445,4,0,
83,9,14,1,25,
83,9,14,4,0,
83,9,15,4,0,
83,9,16,2,0,
83,9,19,4,0,
83,9,28,1,1,3
83,9,31,1,7,
83,9,43,1,1,4
83,9,64,1,1,2
83,9,92,4,0,
83,9,97,1,31,
83,9,98,2,0,
83,9,104,4,0,
83,9,119,2,0,
83,9,129,3,0,
83,9,156,4,0,
83,9,163,1,19,
83,9,164,4,0,
83,9,168,4,0,
83,9,173,3,0,
83,9,174,2,0,
83,9,175,2,0,
83,9,182,4,0,
83,9,189,2,0,
83,9,189,3,0,
83,9,193,2,0,
83,9,203,4,0,
83,9,206,1,45,
83,9,206,4,0,
83,9,207,4,0,
83,9,210,1,1,5
83,9,210,3,0,
83,9,211,2,0,
83,9,211,4,0,
83,9,213,4,0,
83,9,214,4,0,
83,9,216,4,0,
83,9,218,4,0,
83,9,231,4,0,
83,9,237,4,0,
83,9,239,3,0,
83,9,241,4,0,
83,9,244,4,0,
83,9,253,3,0,
83,9,257,3,0,
83,9,263,4,0,
83,9,282,1,9,
83,9,282,3,0,
83,9,290,4,0,
83,9,297,2,0,
83,9,314,1,21,
83,9,314,3,0,
83,9,332,1,13,
83,9,332,4,0,
83,9,343,2,0,
83,9,355,4,0,
83,9,363,4,0,
83,9,364,1,43,
83,9,365,4,0,
83,9,369,4,0,
83,9,387,3,0,
83,9,398,1,1,1
83,9,398,4,0,
83,9,400,1,33,
83,9,400,2,0,
83,9,403,1,37,
83,9,432,4,0,
83,9,445,4,0,
83,9,466,3,0,
83,10,14,1,25,
83,10,14,4,0,
83,10,15,4,0,
83,10,16,2,0,
83,10,19,4,0,
83,10,28,1,1,3
83,10,29,3,0,
83,10,31,1,7,
83,10,43,1,1,4
83,10,64,1,1,2
83,10,92,4,0,
83,10,97,1,31,
83,10,98,2,0,
83,10,104,4,0,
83,10,119,2,0,
83,10,129,3,0,
83,10,156,4,0,
83,10,163,1,19,
83,10,164,4,0,
83,10,168,4,0,
83,10,173,3,0,
83,10,174,2,0,
83,10,175,2,0,
83,10,182,4,0,
83,10,189,2,0,
83,10,189,3,0,
83,10,193,2,0,
83,10,203,4,0,
83,10,206,1,45,
83,10,206,4,0,
83,10,207,4,0,
83,10,210,1,1,5
83,10,210,3,0,
83,10,211,2,0,
83,10,211,4,0,
83,10,213,4,0,
83,10,214,4,0,
83,10,216,4,0,
83,10,218,4,0,
83,10,231,4,0,
83,10,237,4,0,
83,10,239,3,0,
83,10,241,4,0,
83,10,244,4,0,
83,10,253,3,0,
83,10,257,3,0,
83,10,263,4,0,
83,10,282,1,9,
83,10,282,3,0,
83,10,290,4,0,
83,10,297,2,0,
83,10,314,1,21,
83,10,314,3,0,
83,10,332,1,13,
83,10,332,4,0,
83,10,343,2,0,
83,10,348,2,0,
83,10,355,4,0,
83,10,363,4,0,
83,10,364,1,43,
83,10,365,4,0,
83,10,369,4,0,
83,10,387,3,0,
83,10,398,1,1,1
83,10,398,4,0,
83,10,400,1,33,
83,10,400,2,0,
83,10,403,1,37,
83,10,445,4,0,
83,10,466,3,0,
83,11,14,1,25,
83,11,14,4,0,
83,11,15,4,0,
83,11,16,2,0,
83,11,19,4,0,
83,11,28,1,1,3
83,11,31,1,7,
83,11,43,1,1,4
83,11,64,1,1,2
83,11,92,4,0,
83,11,97,1,31,
83,11,98,2,0,
83,11,104,4,0,
83,11,119,2,0,
83,11,156,4,0,
83,11,163,1,19,
83,11,164,4,0,
83,11,168,4,0,
83,11,174,2,0,
83,11,175,2,0,
83,11,182,4,0,
83,11,189,2,0,
83,11,193,2,0,
83,11,206,1,45,
83,11,206,4,0,
83,11,207,4,0,
83,11,210,1,1,5
83,11,211,2,0,
83,11,213,4,0,
83,11,216,4,0,
83,11,218,4,0,
83,11,237,4,0,
83,11,241,4,0,
83,11,244,4,0,
83,11,263,4,0,
83,11,279,2,0,
83,11,282,1,9,
83,11,297,2,0,
83,11,314,1,21,
83,11,332,1,13,
83,11,332,4,0,
83,11,343,2,0,
83,11,348,2,0,
83,11,355,2,0,
83,11,364,1,43,
83,11,365,4,0,
83,11,369,4,0,
83,11,376,2,0,
83,11,398,1,1,1
83,11,398,4,0,
83,11,400,1,33,
83,11,400,2,0,
83,11,403,1,49,
83,11,413,1,55,
83,11,496,4,0,
83,11,512,1,37,
83,11,512,4,0,
83,11,514,4,0,
83,11,526,4,0,
83,12,14,1,31,
83,12,15,4,0,
83,12,19,4,0,
83,12,28,1,6,
83,12,31,1,16,
83,12,43,1,11,
83,12,64,1,1,
83,12,92,4,0,
83,12,97,1,36,
83,12,104,4,0,
83,12,156,4,0,
83,12,163,1,41,
83,12,168,4,0,
83,12,182,4,0,
83,12,206,1,46,
83,12,210,1,26,
83,12,211,4,0,
83,12,213,4,0,
83,12,216,4,0,
83,12,218,4,0,
83,12,231,4,0,
83,12,237,4,0,
83,12,241,4,0,
83,12,263,4,0,
83,12,282,1,21,
83,12,290,4,0,
83,12,332,4,0,
83,13,14,1,31,
83,13,15,4,0,
83,13,19,4,0,
83,13,28,1,6,
83,13,31,1,16,
83,13,34,3,0,
83,13,38,3,0,
83,13,43,1,11,
83,13,64,1,1,
83,13,92,4,0,
83,13,97,1,36,
83,13,102,3,0,
83,13,104,4,0,
83,13,156,4,0,
83,13,163,1,41,
83,13,164,3,0,
83,13,168,4,0,
83,13,182,4,0,
83,13,206,1,46,
83,13,207,3,0,
83,13,210,1,26,
83,13,211,4,0,
83,13,213,4,0,
83,13,216,4,0,
83,13,218,4,0,
83,13,231,4,0,
83,13,237,4,0,
83,13,241,4,0,
83,13,263,4,0,
83,13,282,1,21,
83,13,290,4,0,
83,13,332,4,0,
83,14,14,1,25,
83,14,14,4,0,
83,14,15,4,0,
83,14,16,2,0,
83,14,19,4,0,
83,14,28,1,1,3
83,14,31,1,7,
83,14,43,1,1,4
83,14,64,1,1,2
83,14,92,4,0,
83,14,97,1,31,
83,14,98,2,0,
83,14,104,4,0,
83,14,119,2,0,
83,14,143,3,0,
83,14,156,4,0,
83,14,163,1,19,
83,14,164,4,0,
83,14,168,4,0,
83,14,173,3,0,
83,14,174,2,0,
83,14,175,2,0,
83,14,182,4,0,
83,14,189,2,0,
83,14,193,2,0,
83,14,206,1,45,
83,14,206,4,0,
83,14,207,4,0,
83,14,210,1,1,5
83,14,211,2,0,
83,14,213,4,0,
83,14,214,3,0,
83,14,216,4,0,
83,14,218,4,0,
83,14,231,3,0,
83,14,237,4,0,
83,14,241,4,0,
83,14,244,4,0,
83,14,253,3,0,
83,14,257,3,0,
83,14,263,4,0,
83,14,270,3,0,
83,14,279,2,0,
83,14,282,1,9,
83,14,282,3,0,
83,14,297,2,0,
83,14,314,1,21,
83,14,332,1,13,
83,14,332,4,0,
83,14,343,2,0,
83,14,343,3,0,
83,14,348,2,0,
83,14,355,2,0,
83,14,355,3,0,
83,14,364,1,43,
83,14,365,4,0,
83,14,366,3,0,
83,14,369,4,0,
83,14,376,2,0,
83,14,387,3,0,
83,14,398,1,1,1
83,14,398,4,0,
83,14,400,1,33,
83,14,400,2,0,
83,14,403,1,49,
83,14,413,1,55,
83,14,496,4,0,
83,14,512,1,37,
83,14,512,4,0,
83,14,514,4,0,
83,14,526,4,0,
83,15,14,1,25,
83,15,14,4,0,
83,15,15,4,0,
83,15,16,2,0,
83,15,19,4,0,
83,15,28,1,1,4
83,15,31,1,7,
83,15,43,1,1,5
83,15,64,1,1,3
83,15,92,4,0,
83,15,97,1,31,
83,15,98,2,0,
83,15,104,4,0,
83,15,119,2,0,
83,15,156,4,0,
83,15,163,1,19,
83,15,164,4,0,
83,15,168,4,0,
83,15,174,2,0,
83,15,175,2,0,
83,15,182,4,0,
83,15,189,2,0,
83,15,193,2,0,
83,15,206,1,45,
83,15,206,4,0,
83,15,207,4,0,
83,15,210,1,1,6
83,15,211,2,0,
83,15,211,4,0,
83,15,213,4,0,
83,15,214,4,0,
83,15,216,4,0,
83,15,218,4,0,
83,15,237,4,0,
83,15,241,4,0,
83,15,244,4,0,
83,15,263,4,0,
83,15,279,2,0,
83,15,282,1,13,
83,15,297,2,0,
83,15,314,1,21,
83,15,332,1,9,
83,15,332,4,0,
83,15,343,2,0,
83,15,348,2,0,
83,15,355,2,0,
83,15,355,4,0,
83,15,364,1,43,
83,15,369,4,0,
83,15,376,2,0,
83,15,398,1,1,2
83,15,398,4,0,
83,15,400,1,33,
83,15,400,2,0,
83,15,403,1,49,
83,15,413,1,1,1
83,15,413,1,55,
83,15,493,2,0,
83,15,496,4,0,
83,15,512,1,37,
83,15,512,4,0,
83,15,514,4,0,
83,15,590,4,0,
83,16,14,1,25,1
83,16,14,4,0,
83,16,15,4,0,
83,16,16,2,0,
83,16,19,4,0,
83,16,28,1,1,4
83,16,31,1,7,1
83,16,43,1,1,5
83,16,64,1,1,3
83,16,92,4,0,
83,16,97,1,31,1
83,16,98,2,0,
83,16,104,4,0,
83,16,119,2,0,
83,16,143,3,0,
83,16,156,4,0,
83,16,163,1,19,1
83,16,164,4,0,
83,16,168,4,0,
83,16,173,3,0,
83,16,174,2,0,
83,16,175,2,0,
83,16,182,4,0,
83,16,189,2,0,
83,16,193,2,0,
83,16,206,1,45,1
83,16,206,4,0,
83,16,207,4,0,
83,16,210,1,1,6
83,16,211,2,0,
83,16,211,4,0,
83,16,213,4,0,
83,16,214,4,0,
83,16,216,4,0,
83,16,218,4,0,
83,16,231,3,0,
83,16,237,4,0,
83,16,241,4,0,
83,16,244,4,0,
83,16,253,3,0,
83,16,257,3,0,
83,16,263,4,0,
83,16,270,3,0,
83,16,279,2,0,
83,16,282,1,13,1
83,16,282,3,0,
83,16,290,4,0,
83,16,297,2,0,
83,16,314,1,21,1
83,16,332,1,9,1
83,16,332,4,0,
83,16,343,2,0,
83,16,343,3,0,
83,16,348,2,0,
83,16,355,2,0,
83,16,355,4,0,
83,16,364,1,43,1
83,16,366,3,0,
83,16,369,4,0,
83,16,376,2,0,
83,16,387,3,0,
83,16,398,1,1,2
83,16,398,4,0,
83,16,400,1,33,1
83,16,400,2,0,
83,16,403,1,49,1
83,16,413,1,1,1
83,16,413,1,55,1
83,16,493,2,0,
83,16,496,4,0,
83,16,512,1,37,1
83,16,512,4,0,
83,16,514,4,0,
83,16,590,4,0,
83,17,14,1,25,
83,17,14,4,0,
83,17,16,2,0,
83,17,19,4,0,
83,17,28,1,1,4
83,17,31,1,7,
83,17,43,1,1,5
83,17,64,1,1,3
83,17,92,4,0,
83,17,97,1,31,
83,17,98,2,0,
83,17,104,4,0,
83,17,119,2,0,
83,17,156,4,0,
83,17,163,1,19,
83,17,164,4,0,
83,17,168,4,0,
83,17,174,2,0,
83,17,175,2,0,
83,17,182,4,0,
83,17,189,2,0,
83,17,193,2,0,
83,17,206,1,45,
83,17,206,4,0,
83,17,207,4,0,
83,17,210,1,1,6
83,17,211,2,0,
83,17,211,4,0,
83,17,213,4,0,
83,17,214,4,0,
83,17,216,4,0,
83,17,218,4,0,
83,17,237,4,0,
83,17,241,4,0,
83,17,244,4,0,
83,17,263,4,0,
83,17,279,2,0,
83,17,282,1,13,
83,17,297,2,0,
83,17,314,1,21,
83,17,332,1,9,
83,17,332,4,0,
83,17,343,2,0,
83,17,348,2,0,
83,17,355,2,0,
83,17,355,4,0,
83,17,364,1,43,
83,17,369,4,0,
83,17,376,2,0,
83,17,398,1,1,2
83,17,398,4,0,
83,17,400,1,33,
83,17,400,2,0,
83,17,403,1,49,
83,17,413,1,1,1
83,17,413,1,55,
83,17,493,2,0,
83,17,496,4,0,
83,17,512,1,37,
83,17,512,4,0,
83,17,526,4,0,
83,17,590,4,0,
83,17,693,4,0,
83,18,14,1,25,
83,18,14,4,0,
83,18,16,2,0,
83,18,19,4,0,
83,18,28,1,1,4
83,18,31,1,7,
83,18,43,1,1,5
83,18,64,1,1,3
83,18,92,4,0,
83,18,97,1,31,
83,18,98,2,0,
83,18,104,4,0,
83,18,119,2,0,
83,18,156,4,0,
83,18,163,1,19,
83,18,164,4,0,
83,18,168,4,0,
83,18,174,2,0,
83,18,175,2,0,
83,18,182,4,0,
83,18,189,2,0,
83,18,193,2,0,
83,18,206,1,45,
83,18,206,4,0,
83,18,207,4,0,
83,18,210,1,1,6
83,18,211,2,0,
83,18,211,4,0,
83,18,213,4,0,
83,18,214,4,0,
83,18,216,4,0,
83,18,218,4,0,
83,18,237,4,0,
83,18,241,4,0,
83,18,244,4,0,
83,18,263,4,0,
83,18,279,2,0,
83,18,282,1,13,
83,18,297,2,0,
83,18,314,1,21,
83,18,332,1,9,
83,18,332,4,0,
83,18,343,2,0,
83,18,348,2,0,
83,18,355,2,0,
83,18,355,4,0,
83,18,364,1,43,
83,18,369,4,0,
83,18,376,2,0,
83,18,398,1,1,2
83,18,398,4,0,
83,18,400,1,33,
83,18,400,2,0,
83,18,403,1,49,
83,18,413,1,1,1
83,18,413,1,55,
83,18,493,2,0,
83,18,496,4,0,
83,18,512,1,37,
83,18,512,4,0,
83,18,515,2,0,
83,18,526,4,0,
83,18,590,4,0,
83,18,660,2,0,
83,18,693,4,0,
84,1,18,4,0,
84,1,19,4,0,
84,1,31,1,24,
84,1,34,4,0,
84,1,36,4,0,
84,1,38,4,0,
84,1,45,1,20,
84,1,64,1,1,
84,1,65,1,30,
84,1,92,4,0,
84,1,97,1,44,
84,1,99,1,36,
84,1,99,4,0,
84,1,102,4,0,
84,1,104,4,0,
84,1,115,4,0,
84,1,117,4,0,
84,1,130,4,0,
84,1,143,4,0,
84,1,156,4,0,
84,1,161,1,40,
84,1,161,4,0,
84,1,164,4,0,
84,2,18,4,0,
84,2,19,4,0,
84,2,31,1,24,
84,2,34,4,0,
84,2,36,4,0,
84,2,38,4,0,
84,2,45,1,20,
84,2,64,1,1,
84,2,65,1,30,
84,2,92,4,0,
84,2,97,1,44,
84,2,99,1,36,
84,2,99,4,0,
84,2,102,4,0,
84,2,104,4,0,
84,2,115,4,0,
84,2,117,4,0,
84,2,130,4,0,
84,2,143,4,0,
84,2,156,4,0,
84,2,161,1,40,
84,2,161,4,0,
84,2,164,4,0,
84,3,19,4,0,
84,3,31,1,13,
84,3,45,1,1,2
84,3,48,2,0,
84,3,64,1,1,1
84,3,65,1,33,
84,3,92,4,0,
84,3,97,1,37,
84,3,98,2,0,
84,3,99,1,25,
84,3,104,4,0,
84,3,114,2,0,
84,3,129,4,0,
84,3,156,4,0,
84,3,161,1,21,
84,3,168,4,0,
84,3,173,4,0,
84,3,174,4,0,
84,3,175,2,0,
84,3,182,4,0,
84,3,185,2,0,
84,3,189,4,0,
84,3,203,4,0,
84,3,207,4,0,
84,3,211,4,0,
84,3,213,4,0,
84,3,214,4,0,
84,3,216,4,0,
84,3,218,4,0,
84,3,228,1,9,
84,3,237,4,0,
84,3,241,4,0,
84,4,19,4,0,
84,4,31,1,13,
84,4,45,1,1,2
84,4,48,2,0,
84,4,64,1,1,1
84,4,65,1,33,
84,4,92,4,0,
84,4,97,1,37,
84,4,98,2,0,
84,4,99,1,25,
84,4,104,4,0,
84,4,114,2,0,
84,4,129,4,0,
84,4,156,4,0,
84,4,161,1,21,
84,4,168,4,0,
84,4,173,4,0,
84,4,174,4,0,
84,4,175,2,0,
84,4,182,4,0,
84,4,185,2,0,
84,4,189,4,0,
84,4,203,4,0,
84,4,207,4,0,
84,4,211,4,0,
84,4,213,4,0,
84,4,214,4,0,
84,4,216,4,0,
84,4,218,4,0,
84,4,228,1,9,
84,4,237,4,0,
84,4,241,4,0,
84,5,19,4,0,
84,5,31,1,13,
84,5,45,1,1,2
84,5,48,2,0,
84,5,64,1,1,1
84,5,65,1,37,
84,5,92,4,0,
84,5,97,1,45,
84,5,98,2,0,
84,5,99,1,25,
84,5,104,4,0,
84,5,114,2,0,
84,5,156,4,0,
84,5,161,1,21,
84,5,168,4,0,
84,5,175,2,0,
84,5,182,4,0,
84,5,185,2,0,
84,5,211,4,0,
84,5,213,4,0,
84,5,216,4,0,
84,5,218,4,0,
84,5,228,1,9,
84,5,237,4,0,
84,5,241,4,0,
84,5,253,1,33,
84,5,263,4,0,
84,5,283,2,0,
84,5,290,4,0,
84,5,332,4,0,
84,6,19,4,0,
84,6,31,1,13,
84,6,34,3,0,
84,6,38,3,0,
84,6,45,1,1,2
84,6,48,2,0,
84,6,64,1,1,1
84,6,65,1,37,
84,6,92,4,0,
84,6,97,1,45,
84,6,98,2,0,
84,6,99,1,25,
84,6,102,3,0,
84,6,104,4,0,
84,6,114,2,0,
84,6,129,3,0,
84,6,156,4,0,
84,6,161,1,21,
84,6,164,3,0,
84,6,168,4,0,
84,6,173,3,0,
84,6,175,2,0,
84,6,182,4,0,
84,6,185,2,0,
84,6,189,3,0,
84,6,203,3,0,
84,6,207,3,0,
84,6,211,4,0,
84,6,213,4,0,
84,6,214,3,0,
84,6,216,4,0,
84,6,218,4,0,
84,6,228,1,9,
84,6,237,4,0,
84,6,241,4,0,
84,6,253,1,33,
84,6,263,4,0,
84,6,283,2,0,
84,6,290,4,0,
84,6,332,4,0,
84,7,19,4,0,
84,7,31,1,13,
84,7,34,3,0,
84,7,38,3,0,
84,7,45,1,1,2
84,7,48,2,0,
84,7,64,1,1,1
84,7,65,1,37,
84,7,92,4,0,
84,7,97,1,45,
84,7,98,2,0,
84,7,99,1,25,
84,7,102,3,0,
84,7,104,4,0,
84,7,114,2,0,
84,7,156,4,0,
84,7,161,1,21,
84,7,164,3,0,
84,7,168,4,0,
84,7,175,2,0,
84,7,182,4,0,
84,7,185,2,0,
84,7,211,4,0,
84,7,213,4,0,
84,7,216,4,0,
84,7,218,4,0,
84,7,228,1,9,
84,7,237,4,0,
84,7,241,4,0,
84,7,253,1,33,
84,7,263,4,0,
84,7,283,2,0,
84,7,290,4,0,
84,7,332,4,0,
84,8,19,4,0,
84,8,31,1,14,
84,8,45,1,1,2
84,8,48,2,0,
84,8,64,1,1,1
84,8,65,1,41,
84,8,92,4,0,
84,8,97,1,37,
84,8,98,1,5,
84,8,98,2,0,
84,8,99,1,10,
84,8,104,4,0,
84,8,114,2,0,
84,8,119,2,0,
84,8,156,4,0,
84,8,164,4,0,
84,8,168,4,0,
84,8,175,2,0,
84,8,182,4,0,
84,8,185,2,0,
84,8,203,4,0,
84,8,207,4,0,
84,8,211,4,0,
84,8,213,4,0,
84,8,214,4,0,
84,8,216,4,0,
84,8,218,4,0,
84,8,228,1,19,
84,8,237,4,0,
84,8,241,4,0,
84,8,253,1,23,
84,8,263,4,0,
84,8,283,1,46,
84,8,283,2,0,
84,8,290,4,0,
84,8,332,4,0,
84,8,355,4,0,
84,8,363,4,0,
84,8,365,4,0,
84,8,367,1,28,
84,8,413,2,0,
84,8,445,4,0,
84,8,458,1,32,
84,9,19,4,0,
84,9,31,1,14,
84,9,45,1,1,2
84,9,48,2,0,
84,9,64,1,1,1
84,9,65,1,41,
84,9,92,4,0,
84,9,97,1,37,
84,9,98,1,5,
84,9,98,2,0,
84,9,99,1,10,
84,9,104,4,0,
84,9,114,2,0,
84,9,119,2,0,
84,9,129,3,0,
84,9,156,4,0,
84,9,164,4,0,
84,9,168,4,0,
84,9,173,3,0,
84,9,175,2,0,
84,9,182,4,0,
84,9,185,2,0,
84,9,189,3,0,
84,9,203,4,0,
84,9,207,4,0,
84,9,211,4,0,
84,9,213,4,0,
84,9,214,4,0,
84,9,216,4,0,
84,9,218,4,0,
84,9,228,1,19,
84,9,237,4,0,
84,9,241,4,0,
84,9,253,1,23,
84,9,253,3,0,
84,9,263,4,0,
84,9,282,3,0,
84,9,283,1,46,
84,9,283,2,0,
84,9,283,3,0,
84,9,290,4,0,
84,9,314,3,0,
84,9,332,4,0,
84,9,355,4,0,
84,9,363,4,0,
84,9,365,4,0,
84,9,367,1,28,
84,9,413,2,0,
84,9,445,4,0,
84,9,458,1,32,
84,10,19,4,0,
84,10,31,1,14,
84,10,45,1,1,2
84,10,48,2,0,
84,10,64,1,1,1
84,10,65,1,41,
84,10,92,4,0,
84,10,97,1,37,
84,10,98,1,5,
84,10,98,2,0,
84,10,99,1,10,
84,10,104,4,0,
84,10,114,2,0,
84,10,119,2,0,
84,10,129,3,0,
84,10,156,4,0,
84,10,164,4,0,
84,10,168,4,0,
84,10,173,3,0,
84,10,175,2,0,
84,10,182,4,0,
84,10,185,2,0,
84,10,189,3,0,
84,10,203,4,0,
84,10,207,4,0,
84,10,211,4,0,
84,10,213,4,0,
84,10,214,4,0,
84,10,216,4,0,
84,10,218,4,0,
84,10,228,1,19,
84,10,237,4,0,
84,10,241,4,0,
84,10,253,1,23,
84,10,253,3,0,
84,10,263,4,0,
84,10,282,3,0,
84,10,283,1,46,
84,10,283,2,0,
84,10,283,3,0,
84,10,290,4,0,
84,10,314,3,0,
84,10,332,4,0,
84,10,355,4,0,
84,10,363,4,0,
84,10,365,4,0,
84,10,367,1,28,
84,10,413,2,0,
84,10,445,4,0,
84,10,458,1,32,
84,11,19,4,0,
84,11,31,1,14,
84,11,37,1,50,
84,11,45,1,1,2
84,11,48,2,0,
84,11,64,1,1,1
84,11,65,1,41,
84,11,92,4,0,
84,11,97,1,37,
84,11,98,1,5,
84,11,98,2,0,
84,11,99,1,10,
84,11,104,4,0,
84,11,114,2,0,
84,11,119,2,0,
84,11,156,4,0,
84,11,164,4,0,
84,11,168,4,0,
84,11,175,2,0,
84,11,182,4,0,
84,11,185,2,0,
84,11,207,4,0,
84,11,213,4,0,
84,11,216,4,0,
84,11,218,4,0,
84,11,228,1,19,
84,11,237,4,0,
84,11,241,4,0,
84,11,253,1,23,
84,11,263,4,0,
84,11,283,1,46,
84,11,283,2,0,
84,11,332,4,0,
84,11,363,2,0,
84,11,365,4,0,
84,11,367,1,28,
84,11,372,2,0,
84,11,413,2,0,
84,11,458,1,32,
84,11,496,4,0,
84,11,497,4,0,
84,11,526,4,0,
84,12,19,4,0,
84,12,31,1,13,
84,12,45,1,1,2
84,12,64,1,1,1
84,12,65,1,37,
84,12,92,4,0,
84,12,97,1,45,
84,12,99,1,25,
84,12,104,4,0,
84,12,156,4,0,
84,12,161,1,21,
84,12,168,4,0,
84,12,182,4,0,
84,12,211,4,0,
84,12,213,4,0,
84,12,216,4,0,
84,12,218,4,0,
84,12,228,1,9,
84,12,237,4,0,
84,12,241,4,0,
84,12,253,1,33,
84,12,263,4,0,
84,12,290,4,0,
84,12,332,4,0,
84,13,19,4,0,
84,13,31,1,13,
84,13,34,3,0,
84,13,38,3,0,
84,13,45,1,1,2
84,13,64,1,1,1
84,13,65,1,37,
84,13,92,4,0,
84,13,97,1,45,
84,13,99,1,25,
84,13,102,3,0,
84,13,104,4,0,
84,13,143,3,0,
84,13,156,4,0,
84,13,161,1,21,
84,13,164,3,0,
84,13,168,4,0,
84,13,182,4,0,
84,13,207,3,0,
84,13,211,4,0,
84,13,213,4,0,
84,13,216,4,0,
84,13,218,4,0,
84,13,228,1,9,
84,13,237,4,0,
84,13,241,4,0,
84,13,253,1,33,
84,13,263,4,0,
84,13,290,4,0,
84,13,332,4,0,
84,14,19,4,0,
84,14,31,1,14,
84,14,37,1,50,
84,14,45,1,1,2
84,14,48,2,0,
84,14,64,1,1,1
84,14,65,1,41,
84,14,92,4,0,
84,14,97,1,37,
84,14,98,1,5,
84,14,98,2,0,
84,14,99,1,10,
84,14,104,4,0,
84,14,114,2,0,
84,14,119,2,0,
84,14,156,4,0,
84,14,164,4,0,
84,14,168,4,0,
84,14,173,3,0,
84,14,175,2,0,
84,14,182,4,0,
84,14,185,2,0,
84,14,207,4,0,
84,14,213,4,0,
84,14,214,3,0,
84,14,216,4,0,
84,14,218,4,0,
84,14,228,1,19,
84,14,237,4,0,
84,14,241,4,0,
84,14,253,1,23,
84,14,253,3,0,
84,14,263,4,0,
84,14,282,3,0,
84,14,283,1,46,
84,14,283,2,0,
84,14,283,3,0,
84,14,332,4,0,
84,14,355,3,0,
84,14,363,2,0,
84,14,365,4,0,
84,14,367,1,28,
84,14,372,2,0,
84,14,413,2,0,
84,14,458,1,32,
84,14,496,4,0,
84,14,497,4,0,
84,14,526,4,0,
84,15,19,4,0,
84,15,31,1,14,
84,15,37,1,50,
84,15,45,1,1,2
84,15,48,2,0,
84,15,64,1,1,1
84,15,65,1,41,
84,15,92,4,0,
84,15,97,1,37,
84,15,98,1,5,
84,15,98,2,0,
84,15,99,1,10,
84,15,104,4,0,
84,15,114,2,0,
84,15,119,2,0,
84,15,156,4,0,
84,15,164,4,0,
84,15,168,4,0,
84,15,175,2,0,
84,15,182,4,0,
84,15,185,2,0,
84,15,207,4,0,
84,15,211,4,0,
84,15,213,4,0,
84,15,214,4,0,
84,15,216,4,0,
84,15,218,4,0,
84,15,228,1,19,
84,15,237,4,0,
84,15,241,4,0,
84,15,253,1,23,
84,15,263,4,0,
84,15,283,1,46,
84,15,283,2,0,
84,15,332,4,0,
84,15,355,4,0,
84,15,363,2,0,
84,15,367,1,28,
84,15,372,2,0,
84,15,413,2,0,
84,15,458,1,32,
84,15,496,4,0,
84,15,497,4,0,
84,15,590,4,0,
84,16,19,4,0,
84,16,31,1,13,1
84,16,37,1,49,1
84,16,45,1,1,2
84,16,48,2,0,
84,16,64,1,1,1
84,16,65,1,37,1
84,16,92,4,0,
84,16,97,1,33,1
84,16,98,1,5,1
84,16,98,2,0,
84,16,99,1,9,1
84,16,104,4,0,
84,16,114,2,0,
84,16,119,2,0,
84,16,156,4,0,
84,16,164,4,0,
84,16,168,4,0,
84,16,173,3,0,
84,16,175,2,0,
84,16,182,4,0,
84,16,185,2,0,
84,16,207,4,0,
84,16,211,4,0,
84,16,213,4,0,
84,16,214,4,0,
84,16,216,4,0,
84,16,218,4,0,
84,16,228,1,17,1
84,16,237,4,0,
84,16,241,4,0,
84,16,253,1,41,1
84,16,253,3,0,
84,16,263,4,0,
84,16,282,3,0,
84,16,283,1,45,1
84,16,283,2,0,
84,16,283,3,0,
84,16,290,4,0,
84,16,332,4,0,
84,16,355,4,0,
84,16,363,2,0,
84,16,365,1,21,1
84,16,367,1,29,1
84,16,372,2,0,
84,16,413,2,0,
84,16,458,1,25,1
84,16,496,4,0,
84,16,497,4,0,
84,16,590,4,0,
84,17,14,1,36,
84,17,14,4,0,
84,17,19,4,0,
84,17,26,1,40,
84,17,31,1,12,
84,17,37,1,50,
84,17,45,1,1,2
84,17,48,2,0,
84,17,64,1,1,1
84,17,65,1,43,
84,17,92,4,0,
84,17,97,1,26,
84,17,98,1,5,
84,17,98,2,0,
84,17,99,1,8,
84,17,104,4,0,
84,17,114,2,0,
84,17,119,2,0,
84,17,156,4,0,
84,17,164,4,0,
84,17,168,4,0,
84,17,175,2,0,
84,17,182,4,0,
84,17,185,2,0,
84,17,207,4,0,
84,17,211,4,0,
84,17,213,4,0,
84,17,214,4,0,
84,17,216,4,0,
84,17,218,4,0,
84,17,228,1,15,
84,17,237,4,0,
84,17,241,4,0,
84,17,253,1,29,
84,17,263,4,0,
84,17,283,1,47,
84,17,283,2,0,
84,17,332,4,0,
84,17,355,4,0,
84,17,363,2,0,
84,17,365,1,19,
84,17,367,1,33,
84,17,372,2,0,
84,17,413,2,0,
84,17,458,1,22,
84,17,496,4,0,
84,17,497,4,0,
84,17,526,4,0,
84,17,590,4,0,
84,18,14,1,36,
84,18,14,4,0,
84,18,19,4,0,
84,18,26,1,40,
84,18,31,1,12,
84,18,37,1,50,
84,18,45,1,1,2
84,18,48,2,0,
84,18,64,1,1,1
84,18,65,1,43,
84,18,92,4,0,
84,18,97,1,26,
84,18,98,1,5,
84,18,98,2,0,
84,18,99,1,8,
84,18,104,4,0,
84,18,114,2,0,
84,18,119,2,0,
84,18,156,4,0,
84,18,164,4,0,
84,18,168,4,0,
84,18,175,2,0,
84,18,182,4,0,
84,18,185,2,0,
84,18,207,4,0,
84,18,211,4,0,
84,18,213,4,0,
84,18,214,4,0,
84,18,216,4,0,
84,18,218,4,0,
84,18,228,1,15,
84,18,237,4,0,
84,18,241,4,0,
84,18,253,1,29,
84,18,263,4,0,
84,18,283,1,47,
84,18,283,2,0,
84,18,332,4,0,
84,18,355,4,0,
84,18,363,2,0,
84,18,365,1,19,
84,18,367,1,33,
84,18,372,2,0,
84,18,413,2,0,
84,18,458,1,22,
84,18,496,4,0,
84,18,497,4,0,
84,18,526,4,0,
84,18,590,4,0,
85,1,18,4,0,
85,1,19,4,0,
85,1,31,1,1,3
85,1,31,1,24,
85,1,34,4,0,
85,1,36,4,0,
85,1,38,4,0,
85,1,45,1,1,2
85,1,45,1,20,
85,1,63,4,0,
85,1,64,1,1,1
85,1,65,1,30,
85,1,92,4,0,
85,1,97,1,51,
85,1,99,1,39,
85,1,99,4,0,
85,1,102,4,0,
85,1,104,4,0,
85,1,115,4,0,
85,1,117,4,0,
85,1,130,4,0,
85,1,143,4,0,
85,1,156,4,0,
85,1,161,1,45,
85,1,161,4,0,
85,1,164,4,0,
85,2,18,4,0,
85,2,19,4,0,
85,2,31,1,1,3
85,2,31,1,24,
85,2,34,4,0,
85,2,36,4,0,
85,2,38,4,0,
85,2,45,1,1,2
85,2,45,1,20,
85,2,63,4,0,
85,2,64,1,1,1
85,2,65,1,30,
85,2,92,4,0,
85,2,97,1,51,
85,2,99,1,39,
85,2,99,4,0,
85,2,102,4,0,
85,2,104,4,0,
85,2,115,4,0,
85,2,117,4,0,
85,2,130,4,0,
85,2,143,4,0,
85,2,156,4,0,
85,2,161,1,45,
85,2,161,4,0,
85,2,164,4,0,
85,3,19,4,0,
85,3,31,1,1,4
85,3,31,1,13,
85,3,45,1,1,2
85,3,63,4,0,
85,3,64,1,1,1
85,3,65,1,38,
85,3,92,4,0,
85,3,97,1,47,
85,3,99,1,25,
85,3,104,4,0,
85,3,129,4,0,
85,3,156,4,0,
85,3,161,1,21,
85,3,168,4,0,
85,3,173,4,0,
85,3,174,4,0,
85,3,182,4,0,
85,3,189,4,0,
85,3,203,4,0,
85,3,207,4,0,
85,3,211,4,0,
85,3,213,4,0,
85,3,214,4,0,
85,3,216,4,0,
85,3,218,4,0,
85,3,228,1,1,3
85,3,228,1,9,
85,3,237,4,0,
85,3,241,4,0,
85,4,19,4,0,
85,4,31,1,1,4
85,4,31,1,13,
85,4,45,1,1,2
85,4,63,4,0,
85,4,64,1,1,1
85,4,65,1,38,
85,4,92,4,0,
85,4,97,1,47,
85,4,99,1,25,
85,4,104,4,0,
85,4,129,4,0,
85,4,156,4,0,
85,4,161,1,21,
85,4,168,4,0,
85,4,173,4,0,
85,4,174,4,0,
85,4,182,4,0,
85,4,189,4,0,
85,4,203,4,0,
85,4,207,4,0,
85,4,211,4,0,
85,4,213,4,0,
85,4,214,4,0,
85,4,216,4,0,
85,4,218,4,0,
85,4,228,1,1,3
85,4,228,1,9,
85,4,237,4,0,
85,4,241,4,0,
85,5,19,4,0,
85,5,31,1,1,4
85,5,31,1,13,
85,5,45,1,1,2
85,5,63,4,0,
85,5,64,1,1,1
85,5,65,1,47,
85,5,92,4,0,
85,5,97,1,60,
85,5,99,1,25,
85,5,104,4,0,
85,5,156,4,0,
85,5,161,1,21,
85,5,168,4,0,
85,5,182,4,0,
85,5,211,4,0,
85,5,213,4,0,
85,5,216,4,0,
85,5,218,4,0,
85,5,228,1,1,3
85,5,228,1,9,
85,5,237,4,0,
85,5,241,4,0,
85,5,253,1,38,
85,5,259,4,0,
85,5,263,4,0,
85,5,269,4,0,
85,5,290,4,0,
85,5,332,4,0,
85,6,19,4,0,
85,6,31,1,1,4
85,6,31,1,13,
85,6,34,3,0,
85,6,38,3,0,
85,6,45,1,1,2
85,6,63,4,0,
85,6,64,1,1,1
85,6,65,1,47,
85,6,92,4,0,
85,6,97,1,60,
85,6,99,1,25,
85,6,102,3,0,
85,6,104,4,0,
85,6,129,3,0,
85,6,156,4,0,
85,6,161,1,21,
85,6,164,3,0,
85,6,168,4,0,
85,6,173,3,0,
85,6,182,4,0,
85,6,189,3,0,
85,6,203,3,0,
85,6,207,3,0,
85,6,211,4,0,
85,6,213,4,0,
85,6,214,3,0,
85,6,216,4,0,
85,6,218,4,0,
85,6,228,1,1,3
85,6,228,1,9,
85,6,237,4,0,
85,6,241,4,0,
85,6,253,1,38,
85,6,259,4,0,
85,6,263,4,0,
85,6,269,4,0,
85,6,290,4,0,
85,6,332,4,0,
85,7,19,4,0,
85,7,31,1,1,4
85,7,31,1,13,
85,7,34,3,0,
85,7,38,3,0,
85,7,45,1,1,2
85,7,63,4,0,
85,7,64,1,1,1
85,7,65,1,47,
85,7,92,4,0,
85,7,97,1,60,
85,7,99,1,25,
85,7,102,3,0,
85,7,104,4,0,
85,7,156,4,0,
85,7,161,1,21,
85,7,164,3,0,
85,7,168,4,0,
85,7,182,4,0,
85,7,211,4,0,
85,7,213,4,0,
85,7,216,4,0,
85,7,218,4,0,
85,7,228,1,1,3
85,7,228,1,9,
85,7,237,4,0,
85,7,241,4,0,
85,7,253,1,38,
85,7,259,4,0,
85,7,263,4,0,
85,7,269,4,0,
85,7,290,4,0,
85,7,332,4,0,
85,8,19,4,0,
85,8,31,1,14,
85,8,45,1,1,3
85,8,63,4,0,
85,8,64,1,1,2
85,8,65,1,47,
85,8,92,4,0,
85,8,97,1,41,
85,8,98,1,1,4
85,8,98,1,5,
85,8,99,1,1,5
85,8,99,1,10,
85,8,104,4,0,
85,8,156,4,0,
85,8,161,1,34,
85,8,164,4,0,
85,8,168,4,0,
85,8,182,4,0,
85,8,203,4,0,
85,8,207,4,0,
85,8,211,4,0,
85,8,213,4,0,
85,8,214,4,0,
85,8,216,4,0,
85,8,218,4,0,
85,8,228,1,19,
85,8,237,4,0,
85,8,241,4,0,
85,8,253,1,23,
85,8,259,4,0,
85,8,263,4,0,
85,8,269,4,0,
85,8,283,1,54,
85,8,290,4,0,
85,8,332,4,0,
85,8,355,4,0,
85,8,363,4,0,
85,8,365,1,1,1
85,8,365,4,0,
85,8,367,1,28,
85,8,371,4,0,
85,8,416,4,0,
85,8,445,4,0,
85,9,19,4,0,
85,9,31,1,14,
85,9,45,1,1,3
85,9,63,4,0,
85,9,64,1,1,2
85,9,65,1,47,
85,9,92,4,0,
85,9,97,1,41,
85,9,98,1,1,4
85,9,98,1,5,
85,9,99,1,1,5
85,9,99,1,10,
85,9,104,4,0,
85,9,129,3,0,
85,9,156,4,0,
85,9,161,1,34,
85,9,164,4,0,
85,9,168,4,0,
85,9,173,3,0,
85,9,182,4,0,
85,9,189,3,0,
85,9,203,4,0,
85,9,207,4,0,
85,9,211,4,0,
85,9,213,4,0,
85,9,214,4,0,
85,9,216,4,0,
85,9,218,4,0,
85,9,228,1,19,
85,9,237,4,0,
85,9,241,4,0,
85,9,253,1,23,
85,9,253,3,0,
85,9,259,4,0,
85,9,263,4,0,
85,9,269,4,0,
85,9,282,3,0,
85,9,283,1,54,
85,9,283,3,0,
85,9,290,4,0,
85,9,314,3,0,
85,9,332,4,0,
85,9,355,4,0,
85,9,363,4,0,
85,9,365,1,1,1
85,9,365,4,0,
85,9,367,1,28,
85,9,371,4,0,
85,9,416,4,0,
85,9,445,4,0,
85,10,19,4,0,
85,10,31,1,14,
85,10,45,1,1,3
85,10,63,4,0,
85,10,64,1,1,2
85,10,65,1,47,
85,10,92,4,0,
85,10,97,1,41,
85,10,98,1,1,4
85,10,98,1,5,
85,10,99,1,1,5
85,10,99,1,10,
85,10,104,4,0,
85,10,129,3,0,
85,10,143,3,0,
85,10,156,4,0,
85,10,161,1,34,
85,10,164,4,0,
85,10,168,4,0,
85,10,173,3,0,
85,10,182,4,0,
85,10,189,3,0,
85,10,203,4,0,
85,10,207,4,0,
85,10,211,4,0,
85,10,213,4,0,
85,10,214,4,0,
85,10,216,4,0,
85,10,218,4,0,
85,10,228,1,19,
85,10,237,4,0,
85,10,241,4,0,
85,10,253,1,23,
85,10,253,3,0,
85,10,259,4,0,
85,10,263,4,0,
85,10,269,4,0,
85,10,282,3,0,
85,10,283,1,54,
85,10,283,3,0,
85,10,290,4,0,
85,10,314,3,0,
85,10,332,4,0,
85,10,355,4,0,
85,10,363,4,0,
85,10,365,1,1,1
85,10,365,4,0,
85,10,367,1,28,
85,10,371,4,0,
85,10,416,4,0,
85,10,445,4,0,
85,11,19,4,0,
85,11,31,1,14,
85,11,37,1,60,
85,11,45,1,1,3
85,11,63,4,0,
85,11,64,1,1,2
85,11,65,1,47,
85,11,92,4,0,
85,11,97,1,41,
85,11,98,1,1,4
85,11,98,1,5,
85,11,99,1,1,5
85,11,99,1,10,
85,11,104,4,0,
85,11,156,4,0,
85,11,161,1,34,
85,11,164,4,0,
85,11,168,4,0,
85,11,182,4,0,
85,11,207,4,0,
85,11,213,4,0,
85,11,216,4,0,
85,11,218,4,0,
85,11,228,1,19,
85,11,237,4,0,
85,11,241,4,0,
85,11,253,1,23,
85,11,259,4,0,
85,11,263,4,0,
85,11,269,4,0,
85,11,283,1,54,
85,11,332,4,0,
85,11,365,1,1,1
85,11,365,4,0,
85,11,367,1,28,
85,11,371,4,0,
85,11,416,4,0,
85,11,496,4,0,
85,11,497,4,0,
85,11,526,4,0,
85,12,19,4,0,
85,12,31,1,1,4
85,12,31,1,13,
85,12,45,1,1,2
85,12,63,4,0,
85,12,64,1,1,1
85,12,65,1,47,
85,12,92,4,0,
85,12,97,1,60,
85,12,99,1,25,
85,12,104,4,0,
85,12,156,4,0,
85,12,161,1,21,
85,12,168,4,0,
85,12,182,4,0,
85,12,211,4,0,
85,12,213,4,0,
85,12,216,4,0,
85,12,218,4,0,
85,12,228,1,1,3
85,12,228,1,9,
85,12,237,4,0,
85,12,241,4,0,
85,12,253,1,38,
85,12,259,4,0,
85,12,263,4,0,
85,12,269,4,0,
85,12,290,4,0,
85,12,332,4,0,
85,13,19,4,0,
85,13,31,1,1,4
85,13,31,1,13,
85,13,34,3,0,
85,13,38,3,0,
85,13,45,1,1,2
85,13,63,4,0,
85,13,64,1,1,1
85,13,65,1,47,
85,13,92,4,0,
85,13,97,1,60,
85,13,99,1,25,
85,13,102,3,0,
85,13,104,4,0,
85,13,143,3,0,
85,13,156,4,0,
85,13,161,1,21,
85,13,164,3,0,
85,13,168,4,0,
85,13,182,4,0,
85,13,207,3,0,
85,13,211,4,0,
85,13,213,4,0,
85,13,216,4,0,
85,13,218,4,0,
85,13,228,1,1,3
85,13,228,1,9,
85,13,237,4,0,
85,13,241,4,0,
85,13,253,1,38,
85,13,259,4,0,
85,13,263,4,0,
85,13,269,4,0,
85,13,290,4,0,
85,13,332,4,0,
85,14,19,4,0,
85,14,31,1,14,
85,14,37,1,60,
85,14,45,1,1,3
85,14,63,4,0,
85,14,64,1,1,2
85,14,65,1,47,
85,14,92,4,0,
85,14,97,1,41,
85,14,98,1,1,4
85,14,98,1,5,
85,14,99,1,1,5
85,14,99,1,10,
85,14,104,4,0,
85,14,143,3,0,
85,14,156,4,0,
85,14,161,1,34,
85,14,164,4,0,
85,14,168,4,0,
85,14,173,3,0,
85,14,182,4,0,
85,14,207,4,0,
85,14,213,4,0,
85,14,214,3,0,
85,14,216,4,0,
85,14,218,4,0,
85,14,228,1,19,
85,14,237,4,0,
85,14,241,4,0,
85,14,253,1,23,
85,14,253,3,0,
85,14,259,4,0,
85,14,263,4,0,
85,14,269,4,0,
85,14,282,3,0,
85,14,283,1,54,
85,14,283,3,0,
85,14,332,4,0,
85,14,355,3,0,
85,14,365,1,1,1
85,14,365,4,0,
85,14,367,1,28,
85,14,371,4,0,
85,14,416,4,0,
85,14,496,4,0,
85,14,497,4,0,
85,14,526,4,0,
85,15,19,4,0,
85,15,31,1,14,
85,15,37,1,60,
85,15,45,1,1,3
85,15,63,4,0,
85,15,64,1,1,2
85,15,65,1,47,
85,15,92,4,0,
85,15,97,1,41,
85,15,98,1,1,4
85,15,98,1,5,
85,15,99,1,1,5
85,15,99,1,10,
85,15,104,4,0,
85,15,156,4,0,
85,15,161,1,34,
85,15,164,4,0,
85,15,168,4,0,
85,15,182,4,0,
85,15,207,4,0,
85,15,211,4,0,
85,15,213,4,0,
85,15,214,4,0,
85,15,216,4,0,
85,15,218,4,0,
85,15,228,1,19,
85,15,237,4,0,
85,15,241,4,0,
85,15,253,1,23,
85,15,259,4,0,
85,15,263,4,0,
85,15,269,4,0,
85,15,283,1,54,
85,15,332,4,0,
85,15,355,4,0,
85,15,365,1,1,1
85,15,367,1,28,
85,15,371,4,0,
85,15,416,4,0,
85,15,496,4,0,
85,15,497,4,0,
85,15,590,4,0,
85,16,19,4,0,
85,16,31,1,13,1
85,16,37,1,59,1
85,16,45,1,1,2
85,16,63,4,0,
85,16,64,1,1,1
85,16,65,1,41,1
85,16,92,4,0,
85,16,97,1,35,1
85,16,98,1,1,3
85,16,98,1,5,1
85,16,99,1,1,4
85,16,99,1,9,1
85,16,104,4,0,
85,16,143,3,0,
85,16,156,4,0,
85,16,161,1,25,1
85,16,164,4,0,
85,16,168,4,0,
85,16,173,3,0,
85,16,182,4,0,
85,16,207,4,0,
85,16,211,4,0,
85,16,213,4,0,
85,16,214,4,0,
85,16,216,4,0,
85,16,218,4,0,
85,16,228,1,17,1
85,16,237,4,0,
85,16,241,4,0,
85,16,253,1,47,1
85,16,253,3,0,
85,16,259,4,0,
85,16,263,4,0,
85,16,269,4,0,
85,16,282,3,0,
85,16,283,1,53,1
85,16,283,3,0,
85,16,290,4,0,
85,16,332,4,0,
85,16,355,4,0,
85,16,365,1,21,1
85,16,367,1,29,1
85,16,371,4,0,
85,16,416,4,0,
85,16,496,4,0,
85,16,497,4,0,
85,16,590,4,0,
85,17,14,1,38,
85,17,14,4,0,
85,17,19,4,0,
85,17,26,1,43,
85,17,31,1,12,
85,17,37,1,56,
85,17,45,1,1,3
85,17,63,4,0,
85,17,64,1,1,2
85,17,65,1,47,
85,17,92,4,0,
85,17,97,1,26,
85,17,98,1,1,4
85,17,98,1,5,
85,17,99,1,1,5
85,17,99,1,8,
85,17,104,4,0,
85,17,156,4,0,
85,17,161,1,0,
85,17,161,1,1,1
85,17,164,4,0,
85,17,168,4,0,
85,17,182,4,0,
85,17,207,4,0,
85,17,211,4,0,
85,17,213,4,0,
85,17,214,4,0,
85,17,216,4,0,
85,17,218,4,0,
85,17,228,1,15,
85,17,237,4,0,
85,17,241,4,0,
85,17,253,1,29,
85,17,259,4,0,
85,17,263,4,0,
85,17,269,4,0,
85,17,283,1,52,
85,17,332,4,0,
85,17,355,4,0,
85,17,365,1,19,
85,17,367,1,34,
85,17,371,4,0,
85,17,416,4,0,
85,17,458,1,22,
85,17,496,4,0,
85,17,497,4,0,
85,17,526,4,0,
85,17,590,4,0,
85,18,14,1,38,
85,18,14,4,0,
85,18,19,4,0,
85,18,26,1,43,
85,18,31,1,12,
85,18,37,1,56,
85,18,45,1,1,3
85,18,63,4,0,
85,18,64,1,1,2
85,18,65,1,47,
85,18,92,4,0,
85,18,97,1,26,
85,18,98,1,1,4
85,18,98,1,5,
85,18,99,1,1,5
85,18,99,1,8,
85,18,104,4,0,
85,18,156,4,0,
85,18,161,1,0,
85,18,161,1,1,1
85,18,164,4,0,
85,18,168,4,0,
85,18,182,4,0,
85,18,207,4,0,
85,18,211,4,0,
85,18,213,4,0,
85,18,214,4,0,
85,18,216,4,0,
85,18,218,4,0,
85,18,228,1,15,
85,18,237,4,0,
85,18,241,4,0,
85,18,253,1,29,
85,18,259,4,0,
85,18,263,4,0,
85,18,269,4,0,
85,18,283,1,52,
85,18,332,4,0,
85,18,355,4,0,
85,18,365,1,19,
85,18,367,1,34,
85,18,371,4,0,
85,18,416,4,0,
85,18,458,1,22,
85,18,496,4,0,
85,18,497,4,0,
85,18,526,4,0,
85,18,590,4,0,
86,1,6,4,0,
86,1,29,1,1,
86,1,32,4,0,
86,1,34,4,0,
86,1,36,1,45,
86,1,36,4,0,
86,1,38,4,0,
86,1,45,1,30,
86,1,55,4,0,
86,1,57,4,0,
86,1,58,1,50,
86,1,58,4,0,
86,1,59,4,0,
86,1,61,4,0,
86,1,62,1,35,
86,1,70,4,0,
86,1,92,4,0,
86,1,99,4,0,
86,1,102,4,0,
86,1,104,4,0,
86,1,117,4,0,
86,1,130,4,0,
86,1,156,1,40,
86,1,156,4,0,
86,1,164,4,0,
86,2,6,4,0,
86,2,29,1,1,
86,2,32,4,0,
86,2,34,4,0,
86,2,36,1,45,
86,2,36,4,0,
86,2,38,4,0,
86,2,45,1,30,
86,2,55,4,0,
86,2,57,4,0,
86,2,58,1,50,
86,2,58,4,0,
86,2,59,4,0,
86,2,61,4,0,
86,2,62,1,35,
86,2,70,4,0,
86,2,92,4,0,
86,2,99,4,0,
86,2,102,4,0,
86,2,104,4,0,
86,2,117,4,0,
86,2,130,4,0,
86,2,156,1,40,
86,2,156,4,0,
86,2,164,4,0,
86,3,21,2,0,
86,3,29,1,1,
86,3,29,4,0,
86,3,36,1,32,
86,3,45,1,5,
86,3,50,2,0,
86,3,57,4,0,
86,3,58,1,37,
86,3,59,4,0,
86,3,62,1,16,
86,3,64,2,0,
86,3,92,4,0,
86,3,104,4,0,
86,3,122,2,0,
86,3,127,4,0,
86,3,156,1,21,
86,3,156,4,0,
86,3,173,4,0,
86,3,174,4,0,
86,3,182,4,0,
86,3,195,2,0,
86,3,196,4,0,
86,3,203,4,0,
86,3,207,4,0,
86,3,213,4,0,
86,3,214,4,0,
86,3,216,4,0,
86,3,218,4,0,
86,3,219,1,48,
86,3,227,2,0,
86,3,237,4,0,
86,3,240,4,0,
86,3,250,4,0,
86,4,21,2,0,
86,4,29,1,1,
86,4,29,4,0,
86,4,36,1,32,
86,4,45,1,5,
86,4,50,2,0,
86,4,57,4,0,
86,4,58,1,37,
86,4,58,3,0,
86,4,59,4,0,
86,4,62,1,16,
86,4,64,2,0,
86,4,92,4,0,
86,4,104,4,0,
86,4,122,2,0,
86,4,127,4,0,
86,4,156,1,21,
86,4,156,4,0,
86,4,173,4,0,
86,4,174,4,0,
86,4,182,4,0,
86,4,195,2,0,
86,4,196,4,0,
86,4,203,4,0,
86,4,207,4,0,
86,4,213,4,0,
86,4,214,4,0,
86,4,216,4,0,
86,4,218,4,0,
86,4,219,1,48,
86,4,227,2,0,
86,4,237,4,0,
86,4,240,4,0,
86,4,250,4,0,
86,5,21,2,0,
86,5,29,1,1,
86,5,32,2,0,
86,5,36,1,37,
86,5,45,1,9,
86,5,50,2,0,
86,5,57,4,0,
86,5,58,1,41,
86,5,58,4,0,
86,5,59,4,0,
86,5,62,1,21,
86,5,92,4,0,
86,5,104,4,0,
86,5,122,2,0,
86,5,127,4,0,
86,5,156,1,29,
86,5,156,4,0,
86,5,168,4,0,
86,5,182,4,0,
86,5,195,2,0,
86,5,196,1,17,
86,5,213,4,0,
86,5,216,4,0,
86,5,218,4,0,
86,5,219,1,49,
86,5,219,4,0,
86,5,227,2,0,
86,5,237,4,0,
86,5,240,4,0,
86,5,252,2,0,
86,5,258,4,0,
86,5,263,4,0,
86,5,290,4,0,
86,5,291,4,0,
86,5,333,2,0,
86,5,352,4,0,
86,6,21,2,0,
86,6,29,1,1,
86,6,32,2,0,
86,6,34,3,0,
86,6,36,1,37,
86,6,38,3,0,
86,6,45,1,9,
86,6,50,2,0,
86,6,57,4,0,
86,6,58,1,41,
86,6,58,4,0,
86,6,59,4,0,
86,6,62,1,21,
86,6,92,4,0,
86,6,102,3,0,
86,6,104,4,0,
86,6,122,2,0,
86,6,127,4,0,
86,6,156,1,29,
86,6,156,4,0,
86,6,164,3,0,
86,6,168,4,0,
86,6,173,3,0,
86,6,182,4,0,
86,6,195,2,0,
86,6,196,1,17,
86,6,196,3,0,
86,6,203,3,0,
86,6,207,3,0,
86,6,213,4,0,
86,6,214,3,0,
86,6,216,4,0,
86,6,218,4,0,
86,6,219,1,49,
86,6,219,4,0,
86,6,227,2,0,
86,6,237,4,0,
86,6,240,4,0,
86,6,252,2,0,
86,6,258,4,0,
86,6,263,4,0,
86,6,290,4,0,
86,6,291,4,0,
86,6,333,2,0,
86,6,352,4,0,
86,7,21,2,0,
86,7,29,1,1,
86,7,32,2,0,
86,7,34,3,0,
86,7,36,1,37,
86,7,38,3,0,
86,7,45,1,9,
86,7,50,2,0,
86,7,57,4,0,
86,7,58,1,41,
86,7,58,4,0,
86,7,59,4,0,
86,7,62,1,21,
86,7,92,4,0,
86,7,102,3,0,
86,7,104,4,0,
86,7,122,2,0,
86,7,127,4,0,
86,7,156,1,29,
86,7,156,4,0,
86,7,164,3,0,
86,7,168,4,0,
86,7,182,4,0,
86,7,195,2,0,
86,7,196,1,17,
86,7,213,4,0,
86,7,216,4,0,
86,7,218,4,0,
86,7,219,1,49,
86,7,219,4,0,
86,7,227,2,0,
86,7,237,4,0,
86,7,240,4,0,
86,7,252,2,0,
86,7,258,4,0,
86,7,263,4,0,
86,7,290,4,0,
86,7,291,4,0,
86,7,333,2,0,
86,7,352,4,0,
86,8,21,2,0,
86,8,29,1,1,
86,8,32,2,0,
86,8,36,1,37,
86,8,45,1,3,
86,8,50,2,0,
86,8,57,4,0,
86,8,58,1,47,
86,8,58,4,0,
86,8,59,4,0,
86,8,62,1,27,
86,8,92,4,0,
86,8,104,4,0,
86,8,122,2,0,
86,8,127,4,0,
86,8,156,1,21,
86,8,156,4,0,
86,8,164,4,0,
86,8,168,4,0,
86,8,182,4,0,
86,8,195,2,0,
86,8,196,1,11,
86,8,203,4,0,
86,8,207,4,0,
86,8,213,4,0,
86,8,214,4,0,
86,8,216,4,0,
86,8,218,4,0,
86,8,219,1,51,
86,8,219,4,0,
86,8,227,1,13,
86,8,227,2,0,
86,8,237,4,0,
86,8,240,4,0,
86,8,252,2,0,
86,8,258,4,0,
86,8,263,4,0,
86,8,290,4,0,
86,8,291,1,41,
86,8,324,2,0,
86,8,333,2,0,
86,8,346,1,7,
86,8,352,4,0,
86,8,362,1,33,
86,8,362,4,0,
86,8,363,4,0,
86,8,374,4,0,
86,8,392,1,23,
86,8,401,1,43,
86,8,420,1,17,
86,8,445,4,0,
86,8,453,1,31,
86,9,21,2,0,
86,9,29,1,1,
86,9,32,2,0,
86,9,36,1,37,
86,9,45,1,3,
86,9,50,2,0,
86,9,57,4,0,
86,9,58,1,47,
86,9,58,4,0,
86,9,59,4,0,
86,9,62,1,27,
86,9,92,4,0,
86,9,104,4,0,
86,9,122,2,0,
86,9,127,4,0,
86,9,156,1,21,
86,9,156,4,0,
86,9,164,4,0,
86,9,168,4,0,
86,9,173,3,0,
86,9,182,4,0,
86,9,195,2,0,
86,9,196,1,11,
86,9,196,3,0,
86,9,203,4,0,
86,9,207,4,0,
86,9,213,4,0,
86,9,214,4,0,
86,9,216,4,0,
86,9,218,4,0,
86,9,219,1,51,
86,9,219,4,0,
86,9,227,1,13,
86,9,227,2,0,
86,9,237,4,0,
86,9,240,4,0,
86,9,252,2,0,
86,9,258,4,0,
86,9,263,4,0,
86,9,290,4,0,
86,9,291,1,41,
86,9,291,3,0,
86,9,324,2,0,
86,9,324,3,0,
86,9,333,2,0,
86,9,346,1,7,
86,9,352,4,0,
86,9,362,1,33,
86,9,362,4,0,
86,9,363,4,0,
86,9,374,4,0,
86,9,392,1,23,
86,9,401,1,43,
86,9,401,3,0,
86,9,420,1,17,
86,9,445,4,0,
86,9,453,1,31,
86,10,21,2,0,
86,10,29,1,1,
86,10,29,3,0,
86,10,32,2,0,
86,10,36,1,37,
86,10,45,1,3,
86,10,50,2,0,
86,10,57,4,0,
86,10,58,1,47,
86,10,58,4,0,
86,10,59,4,0,
86,10,62,1,27,
86,10,92,4,0,
86,10,104,4,0,
86,10,122,2,0,
86,10,127,4,0,
86,10,156,1,21,
86,10,156,4,0,
86,10,164,4,0,
86,10,168,4,0,
86,10,173,3,0,
86,10,182,4,0,
86,10,195,2,0,
86,10,196,1,11,
86,10,196,3,0,
86,10,203,4,0,
86,10,207,4,0,
86,10,213,4,0,
86,10,214,4,0,
86,10,216,4,0,
86,10,218,4,0,
86,10,219,1,51,
86,10,219,4,0,
86,10,227,1,13,
86,10,227,2,0,
86,10,237,4,0,
86,10,240,4,0,
86,10,250,4,0,
86,10,252,2,0,
86,10,254,2,0,
86,10,255,2,0,
86,10,256,2,0,
86,10,258,4,0,
86,10,263,4,0,
86,10,290,4,0,
86,10,291,1,41,
86,10,291,3,0,
86,10,324,2,0,
86,10,324,3,0,
86,10,333,2,0,
86,10,346,1,7,
86,10,352,4,0,
86,10,362,1,33,
86,10,362,4,0,
86,10,363,4,0,
86,10,374,4,0,
86,10,392,1,23,
86,10,401,1,43,
86,10,401,3,0,
86,10,420,1,17,
86,10,445,4,0,
86,10,453,1,31,
86,11,21,2,0,
86,11,29,1,1,
86,11,32,2,0,
86,11,36,1,37,
86,11,45,1,3,
86,11,50,2,0,
86,11,57,4,0,
86,11,58,1,47,
86,11,58,4,0,
86,11,59,4,0,
86,11,62,1,27,
86,11,92,4,0,
86,11,104,4,0,
86,11,122,2,0,
86,11,127,4,0,
86,11,156,1,21,
86,11,156,4,0,
86,11,164,4,0,
86,11,168,4,0,
86,11,182,4,0,
86,11,195,2,0,
86,11,196,1,11,
86,11,207,4,0,
86,11,213,4,0,
86,11,214,2,0,
86,11,216,4,0,
86,11,218,4,0,
86,11,219,1,51,
86,11,219,4,0,
86,11,227,1,13,
86,11,227,2,0,
86,11,231,2,0,
86,11,237,4,0,
86,11,240,4,0,
86,11,252,2,0,
86,11,254,2,0,
86,11,255,2,0,
86,11,256,2,0,
86,11,258,1,53,
86,11,258,4,0,
86,11,263,4,0,
86,11,291,1,41,
86,11,291,4,0,
86,11,324,2,0,
86,11,333,2,0,
86,11,346,1,7,
86,11,352,2,0,
86,11,362,1,33,
86,11,374,4,0,
86,11,392,1,23,
86,11,401,1,43,
86,11,420,1,17,
86,11,453,1,31,
86,11,496,4,0,
86,11,497,4,0,
86,12,29,1,1,
86,12,36,1,37,
86,12,45,1,9,
86,12,57,4,0,
86,12,58,1,41,
86,12,58,4,0,
86,12,59,4,0,
86,12,62,1,21,
86,12,92,4,0,
86,12,104,4,0,
86,12,127,4,0,
86,12,156,1,29,
86,12,156,4,0,
86,12,168,4,0,
86,12,182,4,0,
86,12,196,1,17,
86,12,213,4,0,
86,12,216,4,0,
86,12,218,4,0,
86,12,219,1,49,
86,12,219,4,0,
86,12,237,4,0,
86,12,240,4,0,
86,12,258,4,0,
86,12,263,4,0,
86,12,290,4,0,
86,12,291,4,0,
86,12,352,4,0,
86,13,29,1,1,
86,13,34,3,0,
86,13,36,1,37,
86,13,38,3,0,
86,13,45,1,9,
86,13,57,4,0,
86,13,58,1,41,
86,13,58,4,0,
86,13,59,4,0,
86,13,62,1,21,
86,13,92,4,0,
86,13,102,3,0,
86,13,104,4,0,
86,13,127,4,0,
86,13,156,1,29,
86,13,156,4,0,
86,13,164,3,0,
86,13,168,4,0,
86,13,182,4,0,
86,13,196,1,17,
86,13,196,3,0,
86,13,207,3,0,
86,13,213,4,0,
86,13,216,4,0,
86,13,218,4,0,
86,13,219,1,49,
86,13,219,4,0,
86,13,237,4,0,
86,13,240,4,0,
86,13,258,4,0,
86,13,263,4,0,
86,13,290,4,0,
86,13,291,4,0,
86,13,352,4,0,
86,14,21,2,0,
86,14,29,1,1,
86,14,32,2,0,
86,14,36,1,37,
86,14,45,1,3,
86,14,50,2,0,
86,14,57,4,0,
86,14,58,1,47,
86,14,58,4,0,
86,14,59,4,0,
86,14,62,1,27,
86,14,92,4,0,
86,14,104,4,0,
86,14,122,2,0,
86,14,127,4,0,
86,14,156,1,21,
86,14,156,4,0,
86,14,164,4,0,
86,14,168,4,0,
86,14,173,3,0,
86,14,182,4,0,
86,14,195,2,0,
86,14,196,1,11,
86,14,196,3,0,
86,14,207,4,0,
86,14,213,4,0,
86,14,214,2,0,
86,14,214,3,0,
86,14,216,4,0,
86,14,218,4,0,
86,14,219,1,51,
86,14,219,4,0,
86,14,227,1,13,
86,14,227,2,0,
86,14,231,2,0,
86,14,231,3,0,
86,14,237,4,0,
86,14,240,4,0,
86,14,252,2,0,
86,14,254,2,0,
86,14,255,2,0,
86,14,256,2,0,
86,14,258,1,53,
86,14,258,4,0,
86,14,263,4,0,
86,14,291,1,41,
86,14,291,4,0,
86,14,324,2,0,
86,14,324,3,0,
86,14,333,2,0,
86,14,346,1,7,
86,14,352,2,0,
86,14,362,1,33,
86,14,374,4,0,
86,14,392,1,23,
86,14,401,1,43,
86,14,401,3,0,
86,14,420,1,17,
86,14,453,1,31,
86,14,496,4,0,
86,14,497,4,0,
86,14,529,3,0,
86,15,21,2,0,
86,15,29,1,1,
86,15,32,2,0,
86,15,36,1,37,
86,15,45,1,3,
86,15,50,2,0,
86,15,57,4,0,
86,15,58,1,47,
86,15,58,4,0,
86,15,59,4,0,
86,15,62,1,27,
86,15,92,4,0,
86,15,104,4,0,
86,15,122,2,0,
86,15,127,4,0,
86,15,156,1,21,
86,15,156,4,0,
86,15,164,4,0,
86,15,168,4,0,
86,15,182,4,0,
86,15,195,2,0,
86,15,196,1,11,
86,15,207,4,0,
86,15,213,4,0,
86,15,214,2,0,
86,15,214,4,0,
86,15,216,4,0,
86,15,218,4,0,
86,15,219,1,51,
86,15,219,4,0,
86,15,227,1,13,
86,15,227,2,0,
86,15,231,2,0,
86,15,237,4,0,
86,15,240,4,0,
86,15,252,2,0,
86,15,254,2,0,
86,15,255,2,0,
86,15,256,2,0,
86,15,258,1,53,
86,15,258,4,0,
86,15,263,4,0,
86,15,291,1,41,
86,15,324,2,0,
86,15,333,2,0,
86,15,346,1,7,
86,15,352,2,0,
86,15,362,1,33,
86,15,374,4,0,
86,15,392,1,23,
86,15,401,1,43,
86,15,420,1,17,
86,15,453,1,31,
86,15,494,2,0,
86,15,496,4,0,
86,15,497,4,0,
86,15,562,2,0,
86,15,590,4,0,
86,16,21,2,0,
86,16,29,1,1,1
86,16,32,2,0,
86,16,36,1,37,1
86,16,45,1,3,1
86,16,50,2,0,
86,16,57,4,0,
86,16,58,1,47,1
86,16,58,4,0,
86,16,59,4,0,
86,16,62,1,27,1
86,16,92,4,0,
86,16,104,4,0,
86,16,122,2,0,
86,16,127,4,0,
86,16,156,1,21,1
86,16,156,4,0,
86,16,164,4,0,
86,16,168,4,0,
86,16,173,3,0,
86,16,182,4,0,
86,16,195,2,0,
86,16,196,1,11,1
86,16,196,3,0,
86,16,207,4,0,
86,16,213,4,0,
86,16,214,2,0,
86,16,214,4,0,
86,16,216,4,0,
86,16,218,4,0,
86,16,219,1,51,1
86,16,219,4,0,
86,16,227,1,13,1
86,16,227,2,0,
86,16,231,2,0,
86,16,231,3,0,
86,16,237,4,0,
86,16,240,4,0,
86,16,252,2,0,
86,16,254,2,0,
86,16,255,2,0,
86,16,256,2,0,
86,16,258,1,53,1
86,16,258,4,0,
86,16,263,4,0,
86,16,290,4,0,
86,16,291,1,41,1
86,16,291,4,0,
86,16,324,2,0,
86,16,324,3,0,
86,16,333,2,0,
86,16,346,1,7,1
86,16,352,2,0,
86,16,352,3,0,
86,16,362,1,33,1
86,16,374,4,0,
86,16,392,1,23,1
86,16,401,1,43,1
86,16,401,3,0,
86,16,420,1,17,1
86,16,453,1,31,1
86,16,494,2,0,
86,16,496,4,0,
86,16,497,4,0,
86,16,529,3,0,
86,16,562,2,0,
86,16,590,4,0,
86,17,21,2,0,
86,17,29,1,1,
86,17,32,2,0,
86,17,36,1,37,
86,17,45,1,3,
86,17,50,2,0,
86,17,57,4,0,
86,17,58,1,47,
86,17,58,4,0,
86,17,59,4,0,
86,17,62,1,27,
86,17,92,4,0,
86,17,104,4,0,
86,17,122,2,0,
86,17,127,4,0,
86,17,156,1,21,
86,17,156,4,0,
86,17,164,4,0,
86,17,168,4,0,
86,17,182,4,0,
86,17,195,2,0,
86,17,196,1,11,
86,17,207,4,0,
86,17,213,4,0,
86,17,214,2,0,
86,17,214,4,0,
86,17,216,4,0,
86,17,218,4,0,
86,17,219,1,51,
86,17,219,4,0,
86,17,227,1,13,
86,17,227,2,0,
86,17,231,2,0,
86,17,237,4,0,
86,17,240,4,0,
86,17,252,2,0,
86,17,254,2,0,
86,17,255,2,0,
86,17,256,2,0,
86,17,258,1,53,
86,17,258,4,0,
86,17,263,4,0,
86,17,291,1,41,
86,17,324,2,0,
86,17,333,2,0,
86,17,346,1,7,
86,17,352,2,0,
86,17,362,1,33,
86,17,374,4,0,
86,17,392,1,23,
86,17,401,1,43,
86,17,420,1,17,
86,17,453,1,31,
86,17,494,2,0,
86,17,496,4,0,
86,17,497,4,0,
86,17,562,2,0,
86,17,590,4,0,
86,17,684,4,0,
86,18,21,2,0,
86,18,29,1,1,
86,18,32,2,0,
86,18,36,1,37,
86,18,45,1,3,
86,18,50,2,0,
86,18,57,4,0,
86,18,58,1,47,
86,18,58,4,0,
86,18,59,4,0,
86,18,62,1,27,
86,18,92,4,0,
86,18,104,4,0,
86,18,122,2,0,
86,18,127,4,0,
86,18,156,1,21,
86,18,156,4,0,
86,18,164,4,0,
86,18,168,4,0,
86,18,182,4,0,
86,18,195,2,0,
86,18,196,1,11,
86,18,207,4,0,
86,18,213,4,0,
86,18,214,2,0,
86,18,214,4,0,
86,18,216,4,0,
86,18,218,4,0,
86,18,219,1,51,
86,18,219,4,0,
86,18,227,1,13,
86,18,227,2,0,
86,18,231,2,0,
86,18,237,4,0,
86,18,240,4,0,
86,18,252,2,0,
86,18,254,2,0,
86,18,255,2,0,
86,18,256,2,0,
86,18,258,1,53,
86,18,258,4,0,
86,18,263,4,0,
86,18,291,1,41,
86,18,324,2,0,
86,18,333,2,0,
86,18,346,1,7,
86,18,352,2,0,
86,18,362,1,33,
86,18,374,4,0,
86,18,392,1,23,
86,18,401,1,43,
86,18,420,1,17,
86,18,453,1,31,
86,18,494,2,0,
86,18,496,4,0,
86,18,497,4,0,
86,18,562,2,0,
86,18,590,4,0,
86,18,684,4,0,
87,1,6,4,0,
87,1,29,1,1,1
87,1,32,4,0,
87,1,34,4,0,
87,1,36,1,50,
87,1,36,4,0,
87,1,38,4,0,
87,1,45,1,1,2
87,1,45,1,30,
87,1,55,4,0,
87,1,57,4,0,
87,1,58,1,56,
87,1,58,4,0,
87,1,59,4,0,
87,1,61,4,0,
87,1,62,1,1,3
87,1,62,1,35,
87,1,63,4,0,
87,1,70,4,0,
87,1,92,4,0,
87,1,99,4,0,
87,1,102,4,0,
87,1,104,4,0,
87,1,117,4,0,
87,1,130,4,0,
87,1,156,1,44,
87,1,156,4,0,
87,1,164,4,0,
87,2,6,4,0,
87,2,29,1,1,1
87,2,32,4,0,
87,2,34,4,0,
87,2,36,1,50,
87,2,36,4,0,
87,2,38,4,0,
87,2,45,1,1,2
87,2,45,1,30,
87,2,55,4,0,
87,2,57,4,0,
87,2,58,1,56,
87,2,58,4,0,
87,2,59,4,0,
87,2,61,4,0,
87,2,62,1,1,3
87,2,62,1,35,
87,2,63,4,0,
87,2,70,4,0,
87,2,92,4,0,
87,2,99,4,0,
87,2,102,4,0,
87,2,104,4,0,
87,2,117,4,0,
87,2,130,4,0,
87,2,156,1,44,
87,2,156,4,0,
87,2,164,4,0,
87,3,29,1,1,1
87,3,29,4,0,
87,3,36,1,32,
87,3,45,1,1,2
87,3,45,1,5,
87,3,57,4,0,
87,3,58,1,43,
87,3,59,4,0,
87,3,62,1,1,3
87,3,62,1,16,
87,3,63,4,0,
87,3,92,4,0,
87,3,104,4,0,
87,3,127,4,0,
87,3,156,1,21,
87,3,156,4,0,
87,3,173,4,0,
87,3,174,4,0,
87,3,182,4,0,
87,3,196,4,0,
87,3,203,4,0,
87,3,207,4,0,
87,3,213,4,0,
87,3,214,4,0,
87,3,216,4,0,
87,3,218,4,0,
87,3,219,1,60,
87,3,237,4,0,
87,3,240,4,0,
87,3,250,4,0,
87,4,29,1,1,1
87,4,29,4,0,
87,4,36,1,32,
87,4,45,1,1,2
87,4,45,1,5,
87,4,57,4,0,
87,4,58,1,43,
87,4,58,3,0,
87,4,59,4,0,
87,4,62,1,1,3
87,4,62,1,16,
87,4,63,4,0,
87,4,92,4,0,
87,4,104,4,0,
87,4,127,4,0,
87,4,156,1,21,
87,4,156,4,0,
87,4,173,4,0,
87,4,174,4,0,
87,4,182,4,0,
87,4,196,4,0,
87,4,203,4,0,
87,4,207,4,0,
87,4,213,4,0,
87,4,214,4,0,
87,4,216,4,0,
87,4,218,4,0,
87,4,219,1,60,
87,4,237,4,0,
87,4,240,4,0,
87,4,250,4,0,
87,5,29,1,1,1
87,5,36,1,42,
87,5,45,1,1,2
87,5,45,1,9,
87,5,57,4,0,
87,5,58,1,51,
87,5,58,4,0,
87,5,59,4,0,
87,5,62,1,1,4
87,5,62,1,21,
87,5,63,4,0,
87,5,92,4,0,
87,5,104,4,0,
87,5,127,4,0,
87,5,156,1,29,
87,5,156,4,0,
87,5,168,4,0,
87,5,182,4,0,
87,5,196,1,1,3
87,5,196,1,17,
87,5,213,4,0,
87,5,216,4,0,
87,5,218,4,0,
87,5,219,1,64,
87,5,219,4,0,
87,5,237,4,0,
87,5,240,4,0,
87,5,258,4,0,
87,5,263,4,0,
87,5,290,4,0,
87,5,291,4,0,
87,5,329,1,34,
87,5,352,4,0,
87,6,29,1,1,1
87,6,34,3,0,
87,6,36,1,42,
87,6,38,3,0,
87,6,45,1,1,2
87,6,45,1,9,
87,6,57,4,0,
87,6,58,1,51,
87,6,58,4,0,
87,6,59,4,0,
87,6,62,1,1,4
87,6,62,1,21,
87,6,63,4,0,
87,6,92,4,0,
87,6,102,3,0,
87,6,104,4,0,
87,6,127,4,0,
87,6,156,1,29,
87,6,156,4,0,
87,6,164,3,0,
87,6,168,4,0,
87,6,173,3,0,
87,6,182,4,0,
87,6,196,1,1,3
87,6,196,1,17,
87,6,196,3,0,
87,6,203,3,0,
87,6,207,3,0,
87,6,213,4,0,
87,6,214,3,0,
87,6,216,4,0,
87,6,218,4,0,
87,6,219,1,64,
87,6,219,4,0,
87,6,237,4,0,
87,6,240,4,0,
87,6,258,4,0,
87,6,263,4,0,
87,6,290,4,0,
87,6,291,4,0,
87,6,329,1,34,
87,6,352,4,0,
87,7,29,1,1,2
87,7,34,3,0,
87,7,36,1,42,
87,7,38,3,0,
87,7,45,1,1,3
87,7,45,1,9,
87,7,57,4,0,
87,7,58,1,51,
87,7,58,4,0,
87,7,59,4,0,
87,7,62,1,1,5
87,7,62,1,21,
87,7,63,4,0,
87,7,92,4,0,
87,7,102,3,0,
87,7,104,4,0,
87,7,127,4,0,
87,7,156,1,29,
87,7,156,4,0,
87,7,164,3,0,
87,7,168,4,0,
87,7,182,4,0,
87,7,196,1,1,4
87,7,196,1,17,
87,7,213,4,0,
87,7,216,4,0,
87,7,218,4,0,
87,7,219,1,64,
87,7,219,4,0,
87,7,237,4,0,
87,7,240,4,0,
87,7,258,4,0,
87,7,263,4,0,
87,7,290,4,0,
87,7,291,4,0,
87,7,324,1,1,1
87,7,329,1,34,
87,7,352,4,0,
87,8,29,1,1,1
87,8,36,1,37,
87,8,45,1,1,2
87,8,45,1,3,
87,8,57,4,0,
87,8,58,1,47,
87,8,58,4,0,
87,8,59,4,0,
87,8,62,1,27,
87,8,63,4,0,
87,8,92,4,0,
87,8,104,4,0,
87,8,127,4,0,
87,8,156,1,21,
87,8,156,4,0,
87,8,164,4,0,
87,8,168,4,0,
87,8,182,4,0,
87,8,196,1,1,4
87,8,196,1,11,
87,8,203,4,0,
87,8,207,4,0,
87,8,213,4,0,
87,8,214,4,0,
87,8,216,4,0,
87,8,218,4,0,
87,8,219,1,51,
87,8,219,4,0,
87,8,227,1,13,
87,8,237,4,0,
87,8,240,4,0,
87,8,258,4,0,
87,8,263,4,0,
87,8,290,4,0,
87,8,291,1,41,
87,8,324,1,1,3
87,8,324,1,7,
87,8,329,1,34,
87,8,352,4,0,
87,8,362,1,33,
87,8,362,4,0,
87,8,363,4,0,
87,8,374,4,0,
87,8,392,1,23,
87,8,401,1,43,
87,8,416,4,0,
87,8,419,4,0,
87,8,420,1,17,
87,8,445,4,0,
87,8,453,1,31,
87,9,29,1,1,1
87,9,36,1,37,
87,9,45,1,1,2
87,9,45,1,3,
87,9,57,4,0,
87,9,58,1,47,
87,9,58,4,0,
87,9,59,4,0,
87,9,62,1,27,
87,9,63,4,0,
87,9,92,4,0,
87,9,104,4,0,
87,9,127,4,0,
87,9,156,1,21,
87,9,156,4,0,
87,9,164,4,0,
87,9,168,4,0,
87,9,173,3,0,
87,9,182,4,0,
87,9,196,1,1,4
87,9,196,1,11,
87,9,196,3,0,
87,9,203,4,0,
87,9,207,4,0,
87,9,213,4,0,
87,9,214,4,0,
87,9,216,4,0,
87,9,218,4,0,
87,9,219,1,51,
87,9,219,4,0,
87,9,227,1,13,
87,9,237,4,0,
87,9,240,4,0,
87,9,258,4,0,
87,9,263,4,0,
87,9,290,4,0,
87,9,291,1,41,
87,9,291,3,0,
87,9,324,1,1,3
87,9,324,1,7,
87,9,324,3,0,
87,9,329,1,34,
87,9,352,4,0,
87,9,362,1,33,
87,9,362,4,0,
87,9,363,4,0,
87,9,374,4,0,
87,9,392,1,23,
87,9,401,1,43,
87,9,401,3,0,
87,9,416,4,0,
87,9,419,4,0,
87,9,420,1,17,
87,9,445,4,0,
87,9,453,1,31,
87,10,29,1,1,1
87,10,29,3,0,
87,10,36,1,37,
87,10,45,1,1,2
87,10,45,1,3,
87,10,57,4,0,
87,10,58,1,47,
87,10,58,4,0,
87,10,59,4,0,
87,10,62,1,27,
87,10,63,4,0,
87,10,92,4,0,
87,10,104,4,0,
87,10,127,4,0,
87,10,156,1,21,
87,10,156,4,0,
87,10,164,4,0,
87,10,168,4,0,
87,10,173,3,0,
87,10,182,4,0,
87,10,196,1,1,4
87,10,196,1,11,
87,10,196,3,0,
87,10,203,4,0,
87,10,207,4,0,
87,10,213,4,0,
87,10,214,4,0,
87,10,216,4,0,
87,10,218,4,0,
87,10,219,1,51,
87,10,219,4,0,
87,10,227,1,13,
87,10,237,4,0,
87,10,240,4,0,
87,10,250,4,0,
87,10,258,4,0,
87,10,263,4,0,
87,10,290,4,0,
87,10,291,1,41,
87,10,291,3,0,
87,10,324,1,1,3
87,10,324,1,7,
87,10,324,3,0,
87,10,329,1,34,
87,10,352,4,0,
87,10,362,1,33,
87,10,362,4,0,
87,10,363,4,0,
87,10,374,4,0,
87,10,392,1,23,
87,10,401,1,43,
87,10,401,3,0,
87,10,416,4,0,
87,10,419,4,0,
87,10,420,1,17,
87,10,445,4,0,
87,10,453,1,31,
87,11,29,1,1,1
87,11,36,1,39,
87,11,45,1,1,2
87,11,45,1,3,
87,11,57,4,0,
87,11,58,1,55,
87,11,58,4,0,
87,11,59,4,0,
87,11,62,1,27,
87,11,63,4,0,
87,11,92,4,0,
87,11,104,4,0,
87,11,127,4,0,
87,11,156,1,21,
87,11,156,4,0,
87,11,164,4,0,
87,11,168,4,0,
87,11,182,4,0,
87,11,196,1,1,4
87,11,196,1,11,
87,11,207,4,0,
87,11,213,4,0,
87,11,216,4,0,
87,11,218,4,0,
87,11,219,1,61,
87,11,219,4,0,
87,11,227,1,13,
87,11,237,4,0,
87,11,240,4,0,
87,11,258,1,65,
87,11,258,4,0,
87,11,263,4,0,
87,11,291,1,45,
87,11,291,4,0,
87,11,324,1,1,3
87,11,324,1,7,
87,11,329,1,34,
87,11,362,1,33,
87,11,374,4,0,
87,11,392,1,23,
87,11,401,1,49,
87,11,416,4,0,
87,11,420,1,17,
87,11,453,1,31,
87,11,496,4,0,
87,11,497,4,0,
87,11,524,4,0,
87,12,29,1,1,1
87,12,36,1,42,
87,12,45,1,1,2
87,12,45,1,9,
87,12,57,4,0,
87,12,58,1,51,
87,12,58,4,0,
87,12,59,4,0,
87,12,62,1,1,4
87,12,62,1,21,
87,12,63,4,0,
87,12,92,4,0,
87,12,104,4,0,
87,12,127,4,0,
87,12,156,1,29,
87,12,156,4,0,
87,12,168,4,0,
87,12,182,4,0,
87,12,196,1,1,3
87,12,196,1,17,
87,12,213,4,0,
87,12,216,4,0,
87,12,218,4,0,
87,12,219,1,64,
87,12,219,4,0,
87,12,237,4,0,
87,12,240,4,0,
87,12,258,4,0,
87,12,263,4,0,
87,12,290,4,0,
87,12,291,4,0,
87,12,329,1,34,
87,12,352,4,0,
87,13,29,1,1,1
87,13,34,3,0,
87,13,36,1,42,
87,13,38,3,0,
87,13,45,1,1,2
87,13,45,1,9,
87,13,57,4,0,
87,13,58,1,51,
87,13,58,4,0,
87,13,59,4,0,
87,13,62,1,1,4
87,13,62,1,21,
87,13,63,4,0,
87,13,92,4,0,
87,13,102,3,0,
87,13,104,4,0,
87,13,127,4,0,
87,13,156,1,29,
87,13,156,4,0,
87,13,164,3,0,
87,13,168,4,0,
87,13,182,4,0,
87,13,196,1,1,3
87,13,196,1,17,
87,13,196,3,0,
87,13,207,3,0,
87,13,213,4,0,
87,13,216,4,0,
87,13,218,4,0,
87,13,219,1,64,
87,13,219,4,0,
87,13,237,4,0,
87,13,240,4,0,
87,13,258,4,0,
87,13,263,4,0,
87,13,290,4,0,
87,13,291,4,0,
87,13,329,1,34,
87,13,352,4,0,
87,14,29,1,1,1
87,14,36,1,39,
87,14,45,1,1,2
87,14,45,1,3,
87,14,57,4,0,
87,14,58,1,55,
87,14,58,4,0,
87,14,59,4,0,
87,14,62,1,27,
87,14,63,4,0,
87,14,92,4,0,
87,14,104,4,0,
87,14,127,4,0,
87,14,156,1,21,
87,14,156,4,0,
87,14,164,4,0,
87,14,168,4,0,
87,14,173,3,0,
87,14,182,4,0,
87,14,196,1,1,4
87,14,196,1,11,
87,14,196,3,0,
87,14,207,4,0,
87,14,213,4,0,
87,14,214,3,0,
87,14,216,4,0,
87,14,218,4,0,
87,14,219,1,61,
87,14,219,4,0,
87,14,227,1,13,
87,14,231,3,0,
87,14,237,4,0,
87,14,240,4,0,
87,14,258,1,65,
87,14,258,4,0,
87,14,263,4,0,
87,14,291,1,45,
87,14,291,4,0,
87,14,324,1,1,3
87,14,324,1,7,
87,14,324,3,0,
87,14,329,1,34,
87,14,362,1,33,
87,14,374,4,0,
87,14,392,1,23,
87,14,401,1,49,
87,14,401,3,0,
87,14,416,4,0,
87,14,420,1,17,
87,14,453,1,31,
87,14,496,4,0,
87,14,497,4,0,
87,14,524,4,0,
87,14,529,3,0,
87,15,29,1,1,1
87,15,36,1,39,
87,15,45,1,1,2
87,15,45,1,3,
87,15,57,4,0,
87,15,58,1,55,
87,15,58,4,0,
87,15,59,4,0,
87,15,62,1,27,
87,15,63,4,0,
87,15,92,4,0,
87,15,104,4,0,
87,15,127,4,0,
87,15,156,1,21,
87,15,156,4,0,
87,15,164,4,0,
87,15,168,4,0,
87,15,182,4,0,
87,15,196,1,1,4
87,15,196,1,11,
87,15,207,4,0,
87,15,213,4,0,
87,15,214,4,0,
87,15,216,4,0,
87,15,218,4,0,
87,15,219,1,61,
87,15,219,4,0,
87,15,227,1,13,
87,15,237,4,0,
87,15,240,4,0,
87,15,258,1,65,
87,15,258,4,0,
87,15,263,4,0,
87,15,291,1,45,
87,15,324,1,1,3
87,15,324,1,7,
87,15,329,1,34,
87,15,362,1,33,
87,15,374,4,0,
87,15,392,1,23,
87,15,401,1,49,
87,15,416,4,0,
87,15,420,1,17,
87,15,453,1,31,
87,15,496,4,0,
87,15,497,4,0,
87,15,524,4,0,
87,15,590,4,0,
87,16,29,1,1,1
87,16,36,1,39,1
87,16,45,1,1,2
87,16,45,1,3,1
87,16,57,4,0,
87,16,58,1,55,1
87,16,58,4,0,
87,16,59,4,0,
87,16,62,1,27,1
87,16,63,4,0,
87,16,92,4,0,
87,16,104,4,0,
87,16,127,4,0,
87,16,156,1,21,1
87,16,156,4,0,
87,16,164,4,0,
87,16,168,4,0,
87,16,173,3,0,
87,16,182,4,0,
87,16,196,1,1,4
87,16,196,1,11,1
87,16,196,3,0,
87,16,207,4,0,
87,16,213,4,0,
87,16,214,4,0,
87,16,216,4,0,
87,16,218,4,0,
87,16,219,1,61,1
87,16,219,4,0,
87,16,227,1,13,1
87,16,231,3,0,
87,16,237,4,0,
87,16,240,4,0,
87,16,258,1,65,1
87,16,258,4,0,
87,16,263,4,0,
87,16,290,4,0,
87,16,291,1,45,1
87,16,291,4,0,
87,16,324,1,1,3
87,16,324,1,7,1
87,16,324,3,0,
87,16,329,1,34,1
87,16,352,3,0,
87,16,362,1,33,1
87,16,374,4,0,
87,16,392,1,23,1
87,16,401,1,49,1
87,16,401,3,0,
87,16,416,4,0,
87,16,420,1,17,1
87,16,453,1,31,1
87,16,496,4,0,
87,16,497,4,0,
87,16,524,4,0,
87,16,529,3,0,
87,16,590,4,0,
87,17,29,1,1,2
87,17,36,1,39,
87,17,45,1,1,3
87,17,45,1,3,
87,17,57,4,0,
87,17,58,1,55,
87,17,58,4,0,
87,17,59,4,0,
87,17,62,1,27,
87,17,63,4,0,
87,17,92,4,0,
87,17,104,4,0,
87,17,127,4,0,
87,17,156,1,21,
87,17,156,4,0,
87,17,164,4,0,
87,17,168,4,0,
87,17,182,4,0,
87,17,196,1,1,5
87,17,196,1,11,
87,17,207,4,0,
87,17,213,4,0,
87,17,214,4,0,
87,17,216,4,0,
87,17,218,4,0,
87,17,219,1,61,
87,17,219,4,0,
87,17,227,1,13,
87,17,237,4,0,
87,17,240,4,0,
87,17,258,1,65,
87,17,258,4,0,
87,17,263,4,0,
87,17,291,1,45,
87,17,324,1,1,4
87,17,324,1,7,
87,17,329,1,0,
87,17,329,1,1,1
87,17,362,1,33,
87,17,374,4,0,
87,17,392,1,23,
87,17,401,1,49,
87,17,416,4,0,
87,17,420,1,17,
87,17,453,1,31,
87,17,496,4,0,
87,17,497,4,0,
87,17,524,4,0,
87,17,590,4,0,
87,17,684,4,0,
87,18,29,1,1,2
87,18,36,1,39,
87,18,45,1,1,3
87,18,45,1,3,
87,18,57,4,0,
87,18,58,1,55,
87,18,58,4,0,
87,18,59,4,0,
87,18,62,1,27,
87,18,63,4,0,
87,18,92,4,0,
87,18,104,4,0,
87,18,127,4,0,
87,18,156,1,21,
87,18,156,4,0,
87,18,164,4,0,
87,18,168,4,0,
87,18,182,4,0,
87,18,196,1,1,5
87,18,196,1,11,
87,18,207,4,0,
87,18,213,4,0,
87,18,214,4,0,
87,18,216,4,0,
87,18,218,4,0,
87,18,219,1,61,
87,18,219,4,0,
87,18,227,1,13,
87,18,237,4,0,
87,18,240,4,0,
87,18,258,1,65,
87,18,258,4,0,
87,18,263,4,0,
87,18,291,1,45,
87,18,324,1,1,4
87,18,324,1,7,
87,18,329,1,0,
87,18,329,1,1,1
87,18,362,1,33,
87,18,374,4,0,
87,18,392,1,23,
87,18,401,1,49,
87,18,416,4,0,
87,18,420,1,17,
87,18,453,1,31,
87,18,496,4,0,
87,18,497,4,0,
87,18,524,4,0,
87,18,590,4,0,
87,18,684,4,0,
88,1,1,1,1,1
88,1,34,4,0,
88,1,50,1,1,2
88,1,72,4,0,
88,1,85,4,0,
88,1,87,4,0,
88,1,92,4,0,
88,1,99,4,0,
88,1,102,4,0,
88,1,103,1,48,
88,1,104,4,0,
88,1,106,1,42,
88,1,107,1,33,
88,1,117,4,0,
88,1,120,4,0,
88,1,124,1,37,
88,1,126,4,0,
88,1,139,1,30,
88,1,151,1,55,
88,1,153,4,0,
88,1,156,4,0,
88,1,164,4,0,
88,2,1,1,1,1
88,2,34,4,0,
88,2,50,1,1,2
88,2,72,4,0,
88,2,85,4,0,
88,2,87,4,0,
88,2,92,4,0,
88,2,99,4,0,
88,2,102,4,0,
88,2,103,1,48,
88,2,104,4,0,
88,2,106,1,42,
88,2,107,1,33,
88,2,117,4,0,
88,2,120,4,0,
88,2,124,1,37,
88,2,126,4,0,
88,2,139,1,30,
88,2,151,1,55,
88,2,153,4,0,
88,2,156,4,0,
88,2,164,4,0,
88,3,1,1,1,2
88,3,7,4,0,
88,3,8,4,0,
88,3,9,4,0,
88,3,50,1,10,
88,3,87,4,0,
88,3,92,4,0,
88,3,103,1,31,
88,3,104,4,0,
88,3,106,1,5,
88,3,107,1,23,
88,3,114,2,0,
88,3,122,2,0,
88,3,124,1,16,
88,3,126,4,0,
88,3,139,1,1,1
88,3,151,1,40,
88,3,156,4,0,
88,3,168,4,0,
88,3,173,4,0,
88,3,174,4,0,
88,3,182,4,0,
88,3,188,1,50,
88,3,188,4,0,
88,3,189,4,0,
88,3,192,4,0,
88,3,202,4,0,
88,3,203,4,0,
88,3,207,4,0,
88,3,212,2,0,
88,3,213,4,0,
88,3,214,4,0,
88,3,216,4,0,
88,3,218,4,0,
88,3,223,4,0,
88,3,237,4,0,
88,3,241,4,0,
88,4,1,1,1,2
88,4,7,4,0,
88,4,8,4,0,
88,4,9,4,0,
88,4,50,1,10,
88,4,53,3,0,
88,4,85,3,0,
88,4,87,4,0,
88,4,92,4,0,
88,4,103,1,31,
88,4,104,4,0,
88,4,106,1,5,
88,4,107,1,23,
88,4,114,2,0,
88,4,122,2,0,
88,4,124,1,16,
88,4,126,4,0,
88,4,139,1,1,1
88,4,151,1,40,
88,4,156,4,0,
88,4,168,4,0,
88,4,173,4,0,
88,4,174,4,0,
88,4,182,4,0,
88,4,188,1,50,
88,4,188,4,0,
88,4,189,4,0,
88,4,192,4,0,
88,4,202,4,0,
88,4,203,4,0,
88,4,207,4,0,
88,4,212,2,0,
88,4,213,4,0,
88,4,214,4,0,
88,4,216,4,0,
88,4,218,4,0,
88,4,223,4,0,
88,4,237,4,0,
88,4,241,4,0,
88,5,1,1,1,2
88,5,50,1,8,
88,5,53,4,0,
88,5,85,4,0,
88,5,87,4,0,
88,5,91,4,0,
88,5,92,4,0,
88,5,103,1,26,
88,5,104,4,0,
88,5,106,1,4,
88,5,107,1,19,
88,5,114,2,0,
88,5,122,2,0,
88,5,124,1,13,
88,5,126,4,0,
88,5,139,1,1,1
88,5,151,1,34,
88,5,153,2,0,
88,5,156,4,0,
88,5,168,4,0,
88,5,174,2,0,
88,5,182,4,0,
88,5,188,1,43,
88,5,188,4,0,
88,5,202,4,0,
88,5,212,2,0,
88,5,213,4,0,
88,5,216,4,0,
88,5,218,4,0,
88,5,237,4,0,
88,5,240,4,0,
88,5,241,4,0,
88,5,259,4,0,
88,5,262,1,53,
88,5,263,4,0,
88,5,269,4,0,
88,5,286,2,0,
88,5,290,4,0,
88,5,317,4,0,
88,5,325,2,0,
88,5,351,4,0,
88,6,1,1,1,2
88,6,7,3,0,
88,6,8,3,0,
88,6,9,3,0,
88,6,34,3,0,
88,6,50,1,8,
88,6,53,4,0,
88,6,85,4,0,
88,6,87,4,0,
88,6,91,4,0,
88,6,92,4,0,
88,6,102,3,0,
88,6,103,1,26,
88,6,104,4,0,
88,6,106,1,4,
88,6,107,1,19,
88,6,114,2,0,
88,6,122,2,0,
88,6,124,1,13,
88,6,126,4,0,
88,6,139,1,1,1
88,6,151,1,34,
88,6,153,2,0,
88,6,153,3,0,
88,6,156,4,0,
88,6,164,3,0,
88,6,168,4,0,
88,6,173,3,0,
88,6,174,2,0,
88,6,182,4,0,
88,6,188,1,43,
88,6,188,4,0,
88,6,189,3,0,
88,6,202,4,0,
88,6,203,3,0,
88,6,207,3,0,
88,6,212,2,0,
88,6,213,4,0,
88,6,214,3,0,
88,6,216,4,0,
88,6,218,4,0,
88,6,223,3,0,
88,6,237,4,0,
88,6,240,4,0,
88,6,241,4,0,
88,6,259,4,0,
88,6,262,1,53,
88,6,263,4,0,
88,6,269,4,0,
88,6,286,2,0,
88,6,290,4,0,
88,6,317,4,0,
88,6,325,2,0,
88,6,351,4,0,
88,7,1,1,1,2
88,7,34,3,0,
88,7,50,1,8,
88,7,53,4,0,
88,7,85,4,0,
88,7,87,4,0,
88,7,91,4,0,
88,7,92,4,0,
88,7,102,3,0,
88,7,103,1,26,
88,7,104,4,0,
88,7,106,1,4,
88,7,107,1,19,
88,7,114,2,0,
88,7,122,2,0,
88,7,124,1,13,
88,7,126,4,0,
88,7,139,1,1,1
88,7,151,1,34,
88,7,153,2,0,
88,7,153,3,0,
88,7,156,4,0,
88,7,164,3,0,
88,7,168,4,0,
88,7,174,2,0,
88,7,182,4,0,
88,7,188,1,43,
88,7,188,4,0,
88,7,202,4,0,
88,7,212,2,0,
88,7,213,4,0,
88,7,216,4,0,
88,7,218,4,0,
88,7,237,4,0,
88,7,240,4,0,
88,7,241,4,0,
88,7,259,4,0,
88,7,262,1,53,
88,7,263,4,0,
88,7,269,4,0,
88,7,286,2,0,
88,7,290,4,0,
88,7,317,4,0,
88,7,325,2,0,
88,7,351,4,0,
88,8,1,1,1,2
88,8,50,1,12,
88,8,53,4,0,
88,8,70,4,0,
88,8,85,4,0,
88,8,87,4,0,
88,8,91,4,0,
88,8,92,4,0,
88,8,103,1,33,
88,8,104,4,0,
88,8,106,1,4,
88,8,107,1,17,
88,8,114,2,0,
88,8,122,2,0,
88,8,124,1,20,
88,8,126,4,0,
88,8,139,1,1,1
88,8,151,1,39,
88,8,153,2,0,
88,8,153,4,0,
88,8,156,4,0,
88,8,157,4,0,
88,8,164,4,0,
88,8,168,4,0,
88,8,174,2,0,
88,8,182,4,0,
88,8,188,1,36,
88,8,188,4,0,
88,8,189,1,7,
88,8,202,4,0,
88,8,203,4,0,
88,8,207,4,0,
88,8,212,2,0,
88,8,213,4,0,
88,8,214,4,0,
88,8,216,4,0,
88,8,218,4,0,
88,8,237,4,0,
88,8,240,4,0,
88,8,241,4,0,
88,8,247,4,0,
88,8,254,2,0,
88,8,255,2,0,
88,8,256,2,0,
88,8,259,4,0,
88,8,262,1,49,
88,8,263,4,0,
88,8,269,4,0,
88,8,286,2,0,
88,8,290,4,0,
88,8,317,4,0,
88,8,325,2,0,
88,8,351,4,0,
88,8,363,4,0,
88,8,371,4,0,
88,8,374,1,28,
88,8,374,4,0,
88,8,398,4,0,
88,8,425,2,0,
88,8,426,1,23,
88,8,441,1,44,
88,8,445,4,0,
88,9,1,1,1,2
88,9,7,3,0,
88,9,8,3,0,
88,9,9,3,0,
88,9,50,1,12,
88,9,53,4,0,
88,9,70,4,0,
88,9,85,4,0,
88,9,87,4,0,
88,9,91,4,0,
88,9,92,4,0,
88,9,103,1,33,
88,9,104,4,0,
88,9,106,1,4,
88,9,107,1,17,
88,9,114,2,0,
88,9,122,2,0,
88,9,124,1,20,
88,9,126,4,0,
88,9,139,1,1,1
88,9,151,1,39,
88,9,153,2,0,
88,9,153,4,0,
88,9,156,4,0,
88,9,157,4,0,
88,9,164,4,0,
88,9,168,4,0,
88,9,173,3,0,
88,9,174,2,0,
88,9,182,4,0,
88,9,188,1,36,
88,9,188,4,0,
88,9,189,1,7,
88,9,189,3,0,
88,9,202,4,0,
88,9,203,4,0,
88,9,207,4,0,
88,9,212,2,0,
88,9,213,4,0,
88,9,214,4,0,
88,9,216,4,0,
88,9,218,4,0,
88,9,237,4,0,
88,9,240,4,0,
88,9,241,4,0,
88,9,247,4,0,
88,9,254,2,0,
88,9,255,2,0,
88,9,256,2,0,
88,9,259,4,0,
88,9,262,1,49,
88,9,263,4,0,
88,9,269,4,0,
88,9,286,2,0,
88,9,290,4,0,
88,9,317,4,0,
88,9,325,2,0,
88,9,351,4,0,
88,9,363,4,0,
88,9,371,4,0,
88,9,374,1,28,
88,9,374,4,0,
88,9,398,4,0,
88,9,425,2,0,
88,9,426,1,23,
88,9,441,1,44,
88,9,441,3,0,
88,9,445,4,0,
88,10,1,1,1,2
88,10,7,3,0,
88,10,8,3,0,
88,10,9,3,0,
88,10,50,1,12,
88,10,53,4,0,
88,10,70,4,0,
88,10,85,4,0,
88,10,87,4,0,
88,10,91,4,0,
88,10,92,4,0,
88,10,103,1,33,
88,10,104,4,0,
88,10,106,1,4,
88,10,107,1,17,
88,10,114,2,0,
88,10,122,2,0,
88,10,124,1,20,
88,10,126,4,0,
88,10,139,1,1,1
88,10,151,1,39,
88,10,153,2,0,
88,10,153,4,0,
88,10,156,4,0,
88,10,157,4,0,
88,10,164,4,0,
88,10,168,4,0,
88,10,173,3,0,
88,10,174,2,0,
88,10,182,4,0,
88,10,188,1,36,
88,10,188,4,0,
88,10,189,1,7,
88,10,189,3,0,
88,10,202,4,0,
88,10,203,4,0,
88,10,207,4,0,
88,10,212,2,0,
88,10,213,4,0,
88,10,214,4,0,
88,10,216,4,0,
88,10,218,4,0,
88,10,220,3,0,
88,10,237,4,0,
88,10,240,4,0,
88,10,241,4,0,
88,10,247,4,0,
88,10,254,2,0,
88,10,255,2,0,
88,10,256,2,0,
88,10,259,4,0,
88,10,262,1,49,
88,10,263,4,0,
88,10,269,4,0,
88,10,286,2,0,
88,10,290,4,0,
88,10,317,4,0,
88,10,325,2,0,
88,10,351,4,0,
88,10,363,4,0,
88,10,371,4,0,
88,10,374,1,28,
88,10,374,4,0,
88,10,398,4,0,
88,10,425,2,0,
88,10,426,1,23,
88,10,441,1,44,
88,10,441,3,0,
88,10,445,4,0,
88,11,1,1,1,2
88,11,50,1,12,
88,11,53,4,0,
88,11,70,4,0,
88,11,85,4,0,
88,11,87,4,0,
88,11,91,4,0,
88,11,92,4,0,
88,11,103,1,33,
88,11,104,4,0,
88,11,106,1,4,
88,11,107,1,17,
88,11,114,2,0,
88,11,122,2,0,
88,11,124,1,20,
88,11,126,4,0,
88,11,139,1,1,1
88,11,151,1,39,
88,11,153,4,0,
88,11,156,4,0,
88,11,157,4,0,
88,11,164,4,0,
88,11,168,4,0,
88,11,174,2,0,
88,11,182,4,0,
88,11,184,2,0,
88,11,188,1,36,
88,11,188,4,0,
88,11,189,1,7,
88,11,207,4,0,
88,11,212,2,0,
88,11,213,4,0,
88,11,216,4,0,
88,11,218,4,0,
88,11,237,4,0,
88,11,240,4,0,
88,11,241,4,0,
88,11,247,4,0,
88,11,254,2,0,
88,11,255,2,0,
88,11,256,2,0,
88,11,259,4,0,
88,11,262,1,52,
88,11,263,4,0,
88,11,269,4,0,
88,11,286,2,0,
88,11,317,4,0,
88,11,325,2,0,
88,11,371,4,0,
88,11,374,1,28,
88,11,374,4,0,
88,11,398,4,0,
88,11,425,2,0,
88,11,426,1,23,
88,11,441,1,49,
88,11,474,4,0,
88,11,482,1,44,
88,11,482,4,0,
88,11,491,2,0,
88,11,496,4,0,
88,11,510,4,0,
88,12,1,1,1,2
88,12,50,1,8,
88,12,53,4,0,
88,12,85,4,0,
88,12,87,4,0,
88,12,91,4,0,
88,12,92,4,0,
88,12,103,1,26,
88,12,104,4,0,
88,12,106,1,4,
88,12,107,1,19,
88,12,124,1,13,
88,12,126,4,0,
88,12,139,1,1,1
88,12,151,1,34,
88,12,156,4,0,
88,12,168,4,0,
88,12,182,4,0,
88,12,188,1,43,
88,12,188,4,0,
88,12,202,4,0,
88,12,213,4,0,
88,12,216,4,0,
88,12,218,4,0,
88,12,237,4,0,
88,12,240,4,0,
88,12,241,4,0,
88,12,259,4,0,
88,12,262,1,53,
88,12,263,4,0,
88,12,269,4,0,
88,12,290,4,0,
88,12,317,4,0,
88,12,351,4,0,
88,13,1,1,1,2
88,13,34,3,0,
88,13,50,1,8,
88,13,53,4,0,
88,13,85,4,0,
88,13,87,4,0,
88,13,91,4,0,
88,13,92,4,0,
88,13,102,3,0,
88,13,103,1,26,
88,13,104,4,0,
88,13,106,1,4,
88,13,107,1,19,
88,13,120,3,0,
88,13,124,1,13,
88,13,126,4,0,
88,13,139,1,1,1
88,13,151,1,34,
88,13,156,4,0,
88,13,164,3,0,
88,13,168,4,0,
88,13,182,4,0,
88,13,188,1,43,
88,13,188,4,0,
88,13,202,4,0,
88,13,207,3,0,
88,13,213,4,0,
88,13,216,4,0,
88,13,218,4,0,
88,13,237,4,0,
88,13,240,4,0,
88,13,241,4,0,
88,13,259,4,0,
88,13,262,1,53,
88,13,263,4,0,
88,13,269,4,0,
88,13,290,4,0,
88,13,317,4,0,
88,13,351,4,0,
88,14,1,1,1,1
88,14,7,3,0,
88,14,8,3,0,
88,14,9,3,0,
88,14,50,1,12,
88,14,53,4,0,
88,14,70,4,0,
88,14,85,4,0,
88,14,87,4,0,
88,14,91,4,0,
88,14,92,4,0,
88,14,103,1,32,
88,14,104,4,0,
88,14,106,1,4,
88,14,107,1,18,
88,14,114,2,0,
88,14,122,2,0,
88,14,124,1,15,
88,14,126,4,0,
88,14,139,1,1,2
88,14,151,1,40,
88,14,153,4,0,
88,14,156,4,0,
88,14,157,4,0,
88,14,164,4,0,
88,14,168,4,0,
88,14,173,3,0,
88,14,174,2,0,
88,14,182,4,0,
88,14,184,2,0,
88,14,188,1,26,
88,14,188,4,0,
88,14,189,1,7,
88,14,202,3,0,
88,14,207,4,0,
88,14,212,2,0,
88,14,213,4,0,
88,14,214,3,0,
88,14,216,4,0,
88,14,218,4,0,
88,14,220,3,0,
88,14,237,4,0,
88,14,240,4,0,
88,14,241,4,0,
88,14,247,4,0,
88,14,254,2,0,
88,14,255,2,0,
88,14,256,2,0,
88,14,259,4,0,
88,14,262,1,48,
88,14,263,4,0,
88,14,269,4,0,
88,14,286,2,0,
88,14,317,4,0,
88,14,325,2,0,
88,14,371,4,0,
88,14,374,1,29,
88,14,374,4,0,
88,14,398,4,0,
88,14,425,2,0,
88,14,426,1,21,
88,14,441,1,43,
88,14,441,3,0,
88,14,474,4,0,
88,14,482,1,37,
88,14,482,4,0,
88,14,491,2,0,
88,14,496,4,0,
88,14,510,4,0,
88,15,1,1,1,1
88,15,50,1,12,
88,15,53,4,0,
88,15,70,4,0,
88,15,85,4,0,
88,15,87,4,0,
88,15,91,4,0,
88,15,92,4,0,
88,15,103,1,32,
88,15,104,4,0,
88,15,106,1,4,
88,15,107,1,18,
88,15,114,2,0,
88,15,122,2,0,
88,15,124,1,15,
88,15,126,4,0,
88,15,139,1,1,2
88,15,151,1,40,
88,15,153,4,0,
88,15,156,4,0,
88,15,157,4,0,
88,15,164,4,0,
88,15,168,4,0,
88,15,174,2,0,
88,15,182,4,0,
88,15,184,2,0,
88,15,188,1,26,
88,15,188,4,0,
88,15,189,1,7,
88,15,207,4,0,
88,15,212,2,0,
88,15,213,4,0,
88,15,214,4,0,
88,15,216,4,0,
88,15,218,4,0,
88,15,237,4,0,
88,15,240,4,0,
88,15,241,4,0,
88,15,247,4,0,
88,15,254,2,0,
88,15,255,2,0,
88,15,256,2,0,
88,15,259,4,0,
88,15,262,1,48,
88,15,263,4,0,
88,15,269,4,0,
88,15,286,2,0,
88,15,317,4,0,
88,15,325,2,0,
88,15,371,4,0,
88,15,374,1,29,
88,15,374,4,0,
88,15,398,4,0,
88,15,425,2,0,
88,15,426,1,21,
88,15,441,1,43,
88,15,474,4,0,
88,15,482,1,37,
88,15,482,4,0,
88,15,491,2,0,
88,15,496,4,0,
88,15,510,4,0,
88,15,562,1,46,
88,15,590,4,0,
88,15,611,4,0,
88,15,612,4,0,
88,16,1,1,1,1
88,16,7,3,0,
88,16,8,3,0,
88,16,9,3,0,
88,16,50,1,12,1
88,16,53,4,0,
88,16,70,4,0,
88,16,85,4,0,
88,16,87,4,0,
88,16,91,4,0,
88,16,92,4,0,
88,16,103,1,37,1
88,16,104,4,0,
88,16,106,1,4,1
88,16,107,1,21,1
88,16,114,2,0,
88,16,122,2,0,
88,16,124,1,15,1
88,16,126,4,0,
88,16,139,1,1,2
88,16,151,1,43,1
88,16,153,4,0,
88,16,156,4,0,
88,16,157,4,0,
88,16,164,4,0,
88,16,168,4,0,
88,16,173,3,0,
88,16,174,2,0,
88,16,182,4,0,
88,16,184,2,0,
88,16,188,1,29,1
88,16,188,4,0,
88,16,189,1,7,1
88,16,202,3,0,
88,16,207,4,0,
88,16,212,2,0,
88,16,213,4,0,
88,16,214,4,0,
88,16,216,4,0,
88,16,218,4,0,
88,16,220,3,0,
88,16,237,4,0,
88,16,240,4,0,
88,16,241,4,0,
88,16,247,4,0,
88,16,254,2,0,
88,16,255,2,0,
88,16,256,2,0,
88,16,259,4,0,
88,16,262,1,48,1
88,16,263,4,0,
88,16,269,4,0,
88,16,286,2,0,
88,16,290,4,0,
88,16,317,4,0,
88,16,325,2,0,
88,16,351,3,0,
88,16,371,4,0,
88,16,374,1,26,1
88,16,374,4,0,
88,16,398,4,0,
88,16,425,2,0,
88,16,426,1,18,1
88,16,441,1,40,1
88,16,441,3,0,
88,16,474,4,0,
88,16,482,1,32,1
88,16,482,4,0,
88,16,491,2,0,
88,16,496,4,0,
88,16,510,4,0,
88,16,562,1,46,1
88,16,590,4,0,
88,16,611,4,0,
88,16,612,4,0,
88,17,1,1,1,1
88,17,50,1,12,
88,17,53,4,0,
88,17,85,4,0,
88,17,87,4,0,
88,17,92,4,0,
88,17,103,1,37,
88,17,104,4,0,
88,17,106,1,4,
88,17,107,1,21,
88,17,114,2,0,
88,17,122,2,0,
88,17,124,1,15,
88,17,126,4,0,
88,17,139,1,1,2
88,17,151,1,43,
88,17,153,4,0,
88,17,156,4,0,
88,17,157,4,0,
88,17,164,4,0,
88,17,168,4,0,
88,17,174,2,0,
88,17,182,4,0,
88,17,184,2,0,
88,17,188,1,29,
88,17,188,4,0,
88,17,189,1,7,
88,17,207,4,0,
88,17,212,2,0,
88,17,213,4,0,
88,17,214,4,0,
88,17,216,4,0,
88,17,218,4,0,
88,17,237,4,0,
88,17,240,4,0,
88,17,241,4,0,
88,17,247,4,0,
88,17,254,2,0,
88,17,255,2,0,
88,17,256,2,0,
88,17,259,4,0,
88,17,262,1,48,
88,17,263,4,0,
88,17,269,4,0,
88,17,286,2,0,
88,17,317,4,0,
88,17,325,2,0,
88,17,371,4,0,
88,17,374,1,26,
88,17,374,4,0,
88,17,398,4,0,
88,17,425,2,0,
88,17,426,1,18,
88,17,441,1,40,
88,17,474,4,0,
88,17,482,1,32,
88,17,482,4,0,
88,17,491,2,0,
88,17,496,4,0,
88,17,562,1,46,
88,17,590,4,0,
88,17,611,4,0,
88,18,1,1,1,1
88,18,50,1,12,
88,18,53,4,0,
88,18,85,4,0,
88,18,87,4,0,
88,18,92,4,0,
88,18,103,1,37,
88,18,104,4,0,
88,18,106,1,4,
88,18,107,1,21,
88,18,114,2,0,
88,18,122,2,0,
88,18,124,1,15,
88,18,126,4,0,
88,18,139,1,1,2
88,18,151,1,43,
88,18,153,4,0,
88,18,156,4,0,
88,18,157,4,0,
88,18,164,4,0,
88,18,168,4,0,
88,18,174,2,0,
88,18,182,4,0,
88,18,184,2,0,
88,18,188,1,29,
88,18,188,4,0,
88,18,189,1,7,
88,18,207,4,0,
88,18,212,2,0,
88,18,213,4,0,
88,18,214,4,0,
88,18,216,4,0,
88,18,218,4,0,
88,18,237,4,0,
88,18,240,4,0,
88,18,241,4,0,
88,18,247,4,0,
88,18,254,2,0,
88,18,255,2,0,
88,18,256,2,0,
88,18,259,4,0,
88,18,262,1,48,
88,18,263,4,0,
88,18,269,4,0,
88,18,286,2,0,
88,18,317,4,0,
88,18,325,2,0,
88,18,371,4,0,
88,18,374,1,26,
88,18,374,4,0,
88,18,398,4,0,
88,18,425,2,0,
88,18,426,1,18,
88,18,441,1,40,
88,18,474,4,0,
88,18,482,1,32,
88,18,482,4,0,
88,18,491,2,0,
88,18,496,4,0,
88,18,562,1,46,
88,18,590,4,0,
88,18,611,4,0,
88,18,612,2,0,
89,1,1,1,1,1
89,1,34,4,0,
89,1,50,1,1,2
89,1,63,4,0,
89,1,72,4,0,
89,1,85,4,0,
89,1,87,4,0,
89,1,92,4,0,
89,1,99,4,0,
89,1,102,4,0,
89,1,103,1,53,
89,1,104,4,0,
89,1,106,1,45,
89,1,107,1,33,
89,1,117,4,0,
89,1,120,4,0,
89,1,124,1,37,
89,1,126,4,0,
89,1,139,1,1,3
89,1,139,1,30,
89,1,151,1,60,
89,1,153,4,0,
89,1,156,4,0,
89,1,164,4,0,
89,2,1,1,1,1
89,2,34,4,0,
89,2,50,1,1,2
89,2,63,4,0,
89,2,72,4,0,
89,2,85,4,0,
89,2,87,4,0,
89,2,92,4,0,
89,2,99,4,0,
89,2,102,4,0,
89,2,103,1,53,
89,2,104,4,0,
89,2,106,1,45,
89,2,107,1,33,
89,2,117,4,0,
89,2,120,4,0,
89,2,124,1,37,
89,2,126,4,0,
89,2,139,1,1,3
89,2,139,1,30,
89,2,151,1,60,
89,2,153,4,0,
89,2,156,4,0,
89,2,164,4,0,
89,3,1,1,1,2
89,3,7,4,0,
89,3,8,4,0,
89,3,9,4,0,
89,3,50,1,37,
89,3,63,4,0,
89,3,87,4,0,
89,3,92,4,0,
89,3,103,1,31,
89,3,104,4,0,
89,3,106,1,1,3
89,3,106,1,33,
89,3,107,1,23,
89,3,124,1,45,
89,3,126,4,0,
89,3,139,1,1,1
89,3,151,1,45,
89,3,156,4,0,
89,3,168,4,0,
89,3,173,4,0,
89,3,174,4,0,
89,3,182,4,0,
89,3,188,1,60,
89,3,188,4,0,
89,3,189,4,0,
89,3,192,4,0,
89,3,202,4,0,
89,3,203,4,0,
89,3,207,4,0,
89,3,213,4,0,
89,3,214,4,0,
89,3,216,4,0,
89,3,218,4,0,
89,3,223,4,0,
89,3,237,4,0,
89,3,241,4,0,
89,4,1,1,1,2
89,4,7,4,0,
89,4,8,4,0,
89,4,9,4,0,
89,4,50,1,37,
89,4,53,3,0,
89,4,63,4,0,
89,4,85,3,0,
89,4,87,4,0,
89,4,92,4,0,
89,4,103,1,31,
89,4,104,4,0,
89,4,106,1,1,3
89,4,106,1,33,
89,4,107,1,23,
89,4,124,1,45,
89,4,126,4,0,
89,4,139,1,1,1
89,4,151,1,45,
89,4,156,4,0,
89,4,168,4,0,
89,4,173,4,0,
89,4,174,4,0,
89,4,182,4,0,
89,4,188,1,60,
89,4,188,4,0,
89,4,189,4,0,
89,4,192,4,0,
89,4,202,4,0,
89,4,203,4,0,
89,4,207,4,0,
89,4,213,4,0,
89,4,214,4,0,
89,4,216,4,0,
89,4,218,4,0,
89,4,223,4,0,
89,4,237,4,0,
89,4,241,4,0,
89,5,1,1,1,2
89,5,50,1,8,
89,5,53,4,0,
89,5,63,4,0,
89,5,70,4,0,
89,5,85,4,0,
89,5,87,4,0,
89,5,91,4,0,
89,5,92,4,0,
89,5,103,1,26,
89,5,104,4,0,
89,5,106,1,1,3
89,5,106,1,4,
89,5,107,1,19,
89,5,124,1,13,
89,5,126,4,0,
89,5,139,1,1,1
89,5,151,1,34,
89,5,156,4,0,
89,5,168,4,0,
89,5,182,4,0,
89,5,188,1,47,
89,5,188,4,0,
89,5,202,4,0,
89,5,213,4,0,
89,5,216,4,0,
89,5,218,4,0,
89,5,237,4,0,
89,5,240,4,0,
89,5,241,4,0,
89,5,249,4,0,
89,5,259,4,0,
89,5,262,1,61,
89,5,263,4,0,
89,5,264,4,0,
89,5,269,4,0,
89,5,280,4,0,
89,5,290,4,0,
89,5,317,4,0,
89,5,351,4,0,
89,6,1,1,1,2
89,6,7,3,0,
89,6,8,3,0,
89,6,9,3,0,
89,6,34,3,0,
89,6,50,1,8,
89,6,53,4,0,
89,6,63,4,0,
89,6,70,4,0,
89,6,85,4,0,
89,6,87,4,0,
89,6,91,4,0,
89,6,92,4,0,
89,6,102,3,0,
89,6,103,1,26,
89,6,104,4,0,
89,6,106,1,1,3
89,6,106,1,4,
89,6,107,1,19,
89,6,124,1,13,
89,6,126,4,0,
89,6,139,1,1,1
89,6,151,1,34,
89,6,153,3,0,
89,6,156,4,0,
89,6,164,3,0,
89,6,168,4,0,
89,6,173,3,0,
89,6,182,4,0,
89,6,188,1,47,
89,6,188,4,0,
89,6,189,3,0,
89,6,202,4,0,
89,6,203,3,0,
89,6,207,3,0,
89,6,213,4,0,
89,6,214,3,0,
89,6,216,4,0,
89,6,218,4,0,
89,6,223,3,0,
89,6,237,4,0,
89,6,240,4,0,
89,6,241,4,0,
89,6,249,4,0,
89,6,259,4,0,
89,6,262,1,61,
89,6,263,4,0,
89,6,264,4,0,
89,6,269,4,0,
89,6,280,4,0,
89,6,290,4,0,
89,6,317,4,0,
89,6,351,4,0,
89,7,1,1,1,2
89,7,34,3,0,
89,7,50,1,8,
89,7,53,4,0,
89,7,63,4,0,
89,7,70,4,0,
89,7,85,4,0,
89,7,87,4,0,
89,7,91,4,0,
89,7,92,4,0,
89,7,102,3,0,
89,7,103,1,26,
89,7,104,4,0,
89,7,106,1,1,3
89,7,106,1,4,
89,7,107,1,19,
89,7,124,1,13,
89,7,126,4,0,
89,7,139,1,1,1
89,7,151,1,34,
89,7,153,3,0,
89,7,156,4,0,
89,7,164,3,0,
89,7,168,4,0,
89,7,182,4,0,
89,7,188,1,47,
89,7,188,4,0,
89,7,202,4,0,
89,7,213,4,0,
89,7,216,4,0,
89,7,218,4,0,
89,7,237,4,0,
89,7,240,4,0,
89,7,241,4,0,
89,7,249,4,0,
89,7,259,4,0,
89,7,262,1,61,
89,7,263,4,0,
89,7,264,4,0,
89,7,269,4,0,
89,7,280,4,0,
89,7,290,4,0,
89,7,317,4,0,
89,7,351,4,0,
89,8,1,1,1,2
89,8,50,1,12,
89,8,53,4,0,
89,8,63,4,0,
89,8,70,4,0,
89,8,85,4,0,
89,8,87,4,0,
89,8,91,4,0,
89,8,92,4,0,
89,8,103,1,33,
89,8,104,4,0,
89,8,106,1,1,3
89,8,106,1,4,
89,8,107,1,17,
89,8,124,1,20,
89,8,126,4,0,
89,8,139,1,1,1
89,8,151,1,44,
89,8,153,4,0,
89,8,156,4,0,
89,8,157,4,0,
89,8,164,4,0,
89,8,168,4,0,
89,8,182,4,0,
89,8,188,1,36,
89,8,188,4,0,
89,8,189,1,1,4
89,8,189,1,7,
89,8,202,4,0,
89,8,203,4,0,
89,8,207,4,0,
89,8,213,4,0,
89,8,214,4,0,
89,8,216,4,0,
89,8,218,4,0,
89,8,237,4,0,
89,8,240,4,0,
89,8,241,4,0,
89,8,247,4,0,
89,8,249,4,0,
89,8,259,4,0,
89,8,262,1,65,
89,8,263,4,0,
89,8,264,4,0,
89,8,269,4,0,
89,8,280,4,0,
89,8,290,4,0,
89,8,317,4,0,
89,8,351,4,0,
89,8,363,4,0,
89,8,371,4,0,
89,8,374,1,28,
89,8,374,4,0,
89,8,398,4,0,
89,8,399,4,0,
89,8,411,4,0,
89,8,416,4,0,
89,8,426,1,23,
89,8,441,1,54,
89,8,445,4,0,
89,9,1,1,1,2
89,9,7,3,0,
89,9,8,3,0,
89,9,9,3,0,
89,9,50,1,12,
89,9,53,4,0,
89,9,63,4,0,
89,9,70,4,0,
89,9,85,4,0,
89,9,87,4,0,
89,9,91,4,0,
89,9,92,4,0,
89,9,103,1,33,
89,9,104,4,0,
89,9,106,1,1,3
89,9,106,1,4,
89,9,107,1,17,
89,9,124,1,20,
89,9,126,4,0,
89,9,139,1,1,1
89,9,151,1,44,
89,9,153,4,0,
89,9,156,4,0,
89,9,157,4,0,
89,9,164,4,0,
89,9,168,4,0,
89,9,173,3,0,
89,9,182,4,0,
89,9,188,1,36,
89,9,188,4,0,
89,9,189,1,1,4
89,9,189,1,7,
89,9,189,3,0,
89,9,202,4,0,
89,9,203,4,0,
89,9,207,4,0,
89,9,213,4,0,
89,9,214,4,0,
89,9,216,4,0,
89,9,218,4,0,
89,9,237,4,0,
89,9,240,4,0,
89,9,241,4,0,
89,9,247,4,0,
89,9,249,4,0,
89,9,259,4,0,
89,9,262,1,65,
89,9,263,4,0,
89,9,264,4,0,
89,9,269,4,0,
89,9,280,4,0,
89,9,290,4,0,
89,9,317,4,0,
89,9,351,4,0,
89,9,363,4,0,
89,9,371,4,0,
89,9,374,1,28,
89,9,374,4,0,
89,9,398,4,0,
89,9,399,4,0,
89,9,411,4,0,
89,9,416,4,0,
89,9,426,1,23,
89,9,441,1,54,
89,9,441,3,0,
89,9,445,4,0,
89,10,1,1,1,2
89,10,7,3,0,
89,10,8,3,0,
89,10,9,3,0,
89,10,50,1,12,
89,10,53,4,0,
89,10,63,4,0,
89,10,70,4,0,
89,10,85,4,0,
89,10,87,4,0,
89,10,91,4,0,
89,10,92,4,0,
89,10,103,1,33,
89,10,104,4,0,
89,10,106,1,1,3
89,10,106,1,4,
89,10,107,1,17,
89,10,124,1,20,
89,10,126,4,0,
89,10,139,1,1,1
89,10,151,1,44,
89,10,153,4,0,
89,10,156,4,0,
89,10,157,4,0,
89,10,164,4,0,
89,10,168,4,0,
89,10,173,3,0,
89,10,182,4,0,
89,10,188,1,36,
89,10,188,4,0,
89,10,189,1,1,4
89,10,189,1,7,
89,10,189,3,0,
89,10,202,4,0,
89,10,203,4,0,
89,10,207,4,0,
89,10,213,4,0,
89,10,214,4,0,
89,10,216,4,0,
89,10,218,4,0,
89,10,220,3,0,
89,10,237,4,0,
89,10,240,4,0,
89,10,241,4,0,
89,10,247,4,0,
89,10,249,4,0,
89,10,259,4,0,
89,10,262,1,65,
89,10,263,4,0,
89,10,264,4,0,
89,10,269,4,0,
89,10,280,4,0,
89,10,290,4,0,
89,10,317,4,0,
89,10,335,3,0,
89,10,351,4,0,
89,10,363,4,0,
89,10,371,4,0,
89,10,374,1,28,
89,10,374,4,0,
89,10,398,4,0,
89,10,399,4,0,
89,10,411,4,0,
89,10,416,4,0,
89,10,426,1,23,
89,10,441,1,54,
89,10,441,3,0,
89,10,445,4,0,
89,11,1,1,1,2
89,11,50,1,12,
89,11,53,4,0,
89,11,63,4,0,
89,11,70,4,0,
89,11,85,4,0,
89,11,87,4,0,
89,11,91,4,0,
89,11,92,4,0,
89,11,103,1,33,
89,11,104,4,0,
89,11,106,1,1,3
89,11,106,1,4,
89,11,107,1,17,
89,11,124,1,20,
89,11,126,4,0,
89,11,139,1,1,1
89,11,151,1,42,
89,11,153,4,0,
89,11,156,4,0,
89,11,157,4,0,
89,11,164,4,0,
89,11,168,4,0,
89,11,182,4,0,
89,11,188,1,36,
89,11,188,4,0,
89,11,189,1,1,4
89,11,189,1,7,
89,11,207,4,0,
89,11,213,4,0,
89,11,216,4,0,
89,11,218,4,0,
89,11,237,4,0,
89,11,240,4,0,
89,11,241,4,0,
89,11,247,4,0,
89,11,249,4,0,
89,11,259,4,0,
89,11,262,1,64,
89,11,263,4,0,
89,11,269,4,0,
89,11,280,4,0,
89,11,317,4,0,
89,11,371,4,0,
89,11,374,1,28,
89,11,374,4,0,
89,11,398,4,0,
89,11,411,4,0,
89,11,416,4,0,
89,11,426,1,23,
89,11,441,1,58,
89,11,474,4,0,
89,11,482,1,50,
89,11,482,4,0,
89,11,496,4,0,
89,11,510,4,0,
89,12,1,1,1,2
89,12,50,1,8,
89,12,53,4,0,
89,12,63,4,0,
89,12,70,4,0,
89,12,85,4,0,
89,12,87,4,0,
89,12,91,4,0,
89,12,92,4,0,
89,12,103,1,26,
89,12,104,4,0,
89,12,106,1,1,3
89,12,106,1,4,
89,12,107,1,19,
89,12,124,1,13,
89,12,126,4,0,
89,12,139,1,1,1
89,12,151,1,34,
89,12,156,4,0,
89,12,168,4,0,
89,12,182,4,0,
89,12,188,1,47,
89,12,188,4,0,
89,12,202,4,0,
89,12,213,4,0,
89,12,216,4,0,
89,12,218,4,0,
89,12,237,4,0,
89,12,240,4,0,
89,12,241,4,0,
89,12,249,4,0,
89,12,259,4,0,
89,12,262,1,61,
89,12,263,4,0,
89,12,264,4,0,
89,12,269,4,0,
89,12,280,4,0,
89,12,290,4,0,
89,12,317,4,0,
89,12,351,4,0,
89,13,1,1,1,2
89,13,34,3,0,
89,13,50,1,8,
89,13,53,4,0,
89,13,63,4,0,
89,13,70,4,0,
89,13,85,4,0,
89,13,87,4,0,
89,13,91,4,0,
89,13,92,4,0,
89,13,102,3,0,
89,13,103,1,26,
89,13,104,4,0,
89,13,106,1,1,3
89,13,106,1,4,
89,13,107,1,19,
89,13,120,3,0,
89,13,124,1,13,
89,13,126,4,0,
89,13,139,1,1,1
89,13,151,1,34,
89,13,156,4,0,
89,13,164,3,0,
89,13,168,4,0,
89,13,182,4,0,
89,13,188,1,47,
89,13,188,4,0,
89,13,202,4,0,
89,13,207,3,0,
89,13,213,4,0,
89,13,216,4,0,
89,13,218,4,0,
89,13,237,4,0,
89,13,240,4,0,
89,13,241,4,0,
89,13,249,4,0,
89,13,259,4,0,
89,13,262,1,61,
89,13,263,4,0,
89,13,264,4,0,
89,13,269,4,0,
89,13,280,4,0,
89,13,290,4,0,
89,13,317,4,0,
89,13,351,4,0,
89,14,1,1,1,1
89,14,7,3,0,
89,14,8,3,0,
89,14,9,3,0,
89,14,50,1,12,
89,14,53,4,0,
89,14,63,4,0,
89,14,70,4,0,
89,14,85,4,0,
89,14,87,4,0,
89,14,91,4,0,
89,14,92,4,0,
89,14,103,1,32,
89,14,104,4,0,
89,14,106,1,1,3
89,14,106,1,4,
89,14,107,1,18,
89,14,124,1,15,
89,14,126,4,0,
89,14,139,1,1,2
89,14,151,1,43,
89,14,153,4,0,
89,14,156,4,0,
89,14,157,4,0,
89,14,164,4,0,
89,14,168,4,0,
89,14,173,3,0,
89,14,182,4,0,
89,14,188,1,26,
89,14,188,4,0,
89,14,189,1,1,4
89,14,189,1,7,
89,14,202,3,0,
89,14,207,4,0,
89,14,213,4,0,
89,14,214,3,0,
89,14,216,4,0,
89,14,218,4,0,
89,14,220,3,0,
89,14,237,4,0,
89,14,240,4,0,
89,14,241,4,0,
89,14,247,4,0,
89,14,249,4,0,
89,14,259,4,0,
89,14,262,1,57,
89,14,263,4,0,
89,14,269,4,0,
89,14,280,4,0,
89,14,317,4,0,
89,14,335,3,0,
89,14,371,4,0,
89,14,374,1,29,
89,14,374,4,0,
89,14,398,4,0,
89,14,399,3,0,
89,14,411,4,0,
89,14,416,4,0,
89,14,426,1,21,
89,14,441,1,49,
89,14,441,3,0,
89,14,474,4,0,
89,14,482,1,37,
89,14,482,4,0,
89,14,496,4,0,
89,14,510,4,0,
89,15,1,1,1,1
89,15,50,1,12,
89,15,53,4,0,
89,15,63,4,0,
89,15,70,4,0,
89,15,85,4,0,
89,15,87,4,0,
89,15,91,4,0,
89,15,92,4,0,
89,15,103,1,32,
89,15,104,4,0,
89,15,106,1,1,3
89,15,106,1,4,
89,15,107,1,18,
89,15,124,1,15,
89,15,126,4,0,
89,15,139,1,1,2
89,15,151,1,43,
89,15,153,4,0,
89,15,156,4,0,
89,15,157,4,0,
89,15,164,4,0,
89,15,168,4,0,
89,15,182,4,0,
89,15,188,1,26,
89,15,188,4,0,
89,15,189,1,1,4
89,15,189,1,7,
89,15,207,4,0,
89,15,213,4,0,
89,15,214,4,0,
89,15,216,4,0,
89,15,218,4,0,
89,15,237,4,0,
89,15,240,4,0,
89,15,241,4,0,
89,15,247,4,0,
89,15,249,4,0,
89,15,259,4,0,
89,15,262,1,57,
89,15,263,4,0,
89,15,269,4,0,
89,15,280,4,0,
89,15,317,4,0,
89,15,371,4,0,
89,15,374,1,29,
89,15,374,4,0,
89,15,398,4,0,
89,15,399,4,0,
89,15,411,4,0,
89,15,416,4,0,
89,15,426,1,21,
89,15,441,1,49,
89,15,474,4,0,
89,15,482,1,37,
89,15,482,4,0,
89,15,496,4,0,
89,15,510,4,0,
89,15,562,1,53,
89,15,590,4,0,
89,15,611,4,0,
89,15,612,4,0,
89,16,1,1,1,1
89,16,7,3,0,
89,16,8,3,0,
89,16,9,3,0,
89,16,50,1,12,1
89,16,53,4,0,
89,16,63,4,0,
89,16,70,4,0,
89,16,85,4,0,
89,16,87,4,0,
89,16,91,4,0,
89,16,92,4,0,
89,16,103,1,37,1
89,16,104,4,0,
89,16,106,1,1,3
89,16,106,1,4,1
89,16,107,1,21,1
89,16,124,1,15,1
89,16,126,4,0,
89,16,139,1,1,2
89,16,151,1,46,1
89,16,153,4,0,
89,16,156,4,0,
89,16,157,4,0,
89,16,164,4,0,
89,16,168,4,0,
89,16,173,3,0,
89,16,182,4,0,
89,16,188,1,29,1
89,16,188,4,0,
89,16,189,1,1,4
89,16,189,1,7,1
89,16,202,3,0,
89,16,207,4,0,
89,16,213,4,0,
89,16,214,4,0,
89,16,216,4,0,
89,16,218,4,0,
89,16,220,3,0,
89,16,237,4,0,
89,16,240,4,0,
89,16,241,4,0,
89,16,247,4,0,
89,16,249,4,0,
89,16,259,4,0,
89,16,262,1,57,1
89,16,263,4,0,
89,16,264,3,0,
89,16,269,4,0,
89,16,280,4,0,
89,16,290,4,0,
89,16,317,4,0,
89,16,335,3,0,
89,16,351,3,0,
89,16,371,4,0,
89,16,374,1,26,1
89,16,374,4,0,
89,16,398,4,0,
89,16,399,4,0,
89,16,411,4,0,
89,16,416,4,0,
89,16,426,1,18,1
89,16,441,1,40,1
89,16,441,3,0,
89,16,474,4,0,
89,16,482,1,32,1
89,16,482,4,0,
89,16,496,4,0,
89,16,510,4,0,
89,16,562,1,52,1
89,16,590,4,0,
89,16,599,1,38,1
89,16,611,4,0,
89,16,612,4,0,
89,17,1,1,1,2
89,17,50,1,12,
89,17,53,4,0,
89,17,63,4,0,
89,17,85,4,0,
89,17,87,4,0,
89,17,92,4,0,
89,17,103,1,37,
89,17,104,4,0,
89,17,106,1,1,4
89,17,106,1,4,
89,17,107,1,21,
89,17,124,1,15,
89,17,126,4,0,
89,17,139,1,1,3
89,17,151,1,46,
89,17,153,4,0,
89,17,156,4,0,
89,17,157,4,0,
89,17,164,4,0,
89,17,168,4,0,
89,17,182,4,0,
89,17,188,1,29,
89,17,188,4,0,
89,17,189,1,1,5
89,17,189,1,7,
89,17,207,4,0,
89,17,213,4,0,
89,17,214,4,0,
89,17,216,4,0,
89,17,218,4,0,
89,17,237,4,0,
89,17,240,4,0,
89,17,241,4,0,
89,17,247,4,0,
89,17,259,4,0,
89,17,262,1,57,
89,17,263,4,0,
89,17,269,4,0,
89,17,280,4,0,
89,17,317,4,0,
89,17,371,4,0,
89,17,374,1,26,
89,17,374,4,0,
89,17,398,4,0,
89,17,399,4,0,
89,17,411,4,0,
89,17,416,4,0,
89,17,426,1,18,
89,17,441,1,40,
89,17,474,4,0,
89,17,482,1,32,
89,17,482,4,0,
89,17,496,4,0,
89,17,562,1,52,
89,17,590,4,0,
89,17,599,1,0,
89,17,599,1,1,1
89,17,611,4,0,
89,18,1,1,1,2
89,18,50,1,12,
89,18,53,4,0,
89,18,63,4,0,
89,18,85,4,0,
89,18,87,4,0,
89,18,92,4,0,
89,18,103,1,37,
89,18,104,4,0,
89,18,106,1,1,4
89,18,106,1,4,
89,18,107,1,21,
89,18,124,1,15,
89,18,126,4,0,
89,18,139,1,1,3
89,18,151,1,46,
89,18,153,4,0,
89,18,156,4,0,
89,18,157,4,0,
89,18,164,4,0,
89,18,168,4,0,
89,18,182,4,0,
89,18,188,1,29,
89,18,188,4,0,
89,18,189,1,1,5
89,18,189,1,7,
89,18,207,4,0,
89,18,213,4,0,
89,18,214,4,0,
89,18,216,4,0,
89,18,218,4,0,
89,18,237,4,0,
89,18,240,4,0,
89,18,241,4,0,
89,18,247,4,0,
89,18,259,4,0,
89,18,262,1,57,
89,18,263,4,0,
89,18,269,4,0,
89,18,280,4,0,
89,18,317,4,0,
89,18,371,4,0,
89,18,374,1,26,
89,18,374,4,0,
89,18,398,4,0,
89,18,399,4,0,
89,18,411,4,0,
89,18,416,4,0,
89,18,426,1,18,
89,18,441,1,40,
89,18,474,4,0,
89,18,482,1,32,
89,18,482,4,0,
89,18,496,4,0,
89,18,562,1,52,
89,18,590,4,0,
89,18,599,1,0,
89,18,599,1,1,1
89,18,611,4,0,
90,1,33,1,1,1
90,1,36,4,0,
90,1,38,4,0,
90,1,43,1,39,
90,1,48,1,18,
90,1,55,4,0,
90,1,57,4,0,
90,1,58,1,50,
90,1,58,4,0,
90,1,59,4,0,
90,1,61,4,0,
90,1,62,1,30,
90,1,92,4,0,
90,1,99,4,0,
90,1,100,4,0,
90,1,102,4,0,
90,1,104,4,0,
90,1,110,1,1,2
90,1,115,4,0,
90,1,117,4,0,
90,1,120,4,0,
90,1,128,1,23,
90,1,129,4,0,
90,1,153,4,0,
90,1,156,4,0,
90,1,161,4,0,
90,1,164,4,0,
90,2,33,1,1,1
90,2,36,4,0,
90,2,38,4,0,
90,2,43,1,39,
90,2,48,1,18,
90,2,55,4,0,
90,2,57,4,0,
90,2,58,1,50,
90,2,58,4,0,
90,2,59,4,0,
90,2,61,4,0,
90,2,62,1,30,
90,2,92,4,0,
90,2,99,4,0,
90,2,100,4,0,
90,2,102,4,0,
90,2,104,4,0,
90,2,110,1,1,2
90,2,115,4,0,
90,2,117,4,0,
90,2,120,4,0,
90,2,128,1,23,
90,2,129,4,0,
90,2,153,4,0,
90,2,156,4,0,
90,2,161,4,0,
90,2,164,4,0,
90,3,33,1,1,1
90,3,36,2,0,
90,3,43,1,33,
90,3,48,1,9,
90,3,57,4,0,
90,3,58,1,49,
90,3,59,4,0,
90,3,61,2,0,
90,3,62,1,17,
90,3,92,4,0,
90,3,103,2,0,
90,3,104,4,0,
90,3,110,1,1,2
90,3,112,2,0,
90,3,128,1,41,
90,3,129,4,0,
90,3,156,4,0,
90,3,173,4,0,
90,3,174,4,0,
90,3,182,1,25,
90,3,182,4,0,
90,3,196,4,0,
90,3,203,4,0,
90,3,207,4,0,
90,3,213,4,0,
90,3,214,4,0,
90,3,216,4,0,
90,3,218,4,0,
90,3,229,2,0,
90,3,237,4,0,
90,3,240,4,0,
90,3,250,4,0,
90,4,33,1,1,1
90,4,36,2,0,
90,4,43,1,33,
90,4,48,1,9,
90,4,57,4,0,
90,4,58,1,49,
90,4,58,3,0,
90,4,59,4,0,
90,4,61,2,0,
90,4,62,1,17,
90,4,92,4,0,
90,4,103,2,0,
90,4,104,4,0,
90,4,110,1,1,2
90,4,112,2,0,
90,4,128,1,41,
90,4,129,4,0,
90,4,156,4,0,
90,4,173,4,0,
90,4,174,4,0,
90,4,182,1,25,
90,4,182,4,0,
90,4,196,4,0,
90,4,203,4,0,
90,4,207,4,0,
90,4,213,4,0,
90,4,214,4,0,
90,4,216,4,0,
90,4,218,4,0,
90,4,229,2,0,
90,4,237,4,0,
90,4,240,4,0,
90,4,250,4,0,
90,5,33,1,1,1
90,5,36,2,0,
90,5,43,1,33,
90,5,48,1,9,
90,5,57,4,0,
90,5,58,1,49,
90,5,58,4,0,
90,5,59,4,0,
90,5,61,2,0,
90,5,62,1,17,
90,5,92,4,0,
90,5,103,2,0,
90,5,104,4,0,
90,5,110,1,1,2
90,5,112,2,0,
90,5,128,1,41,
90,5,156,4,0,
90,5,182,1,25,
90,5,182,4,0,
90,5,213,4,0,
90,5,216,4,0,
90,5,218,4,0,
90,5,229,2,0,
90,5,237,4,0,
90,5,240,4,0,
90,5,258,4,0,
90,5,263,4,0,
90,5,290,4,0,
90,5,291,4,0,
90,5,333,2,0,
90,5,352,4,0,
90,6,33,1,1,1
90,6,36,2,0,
90,6,38,3,0,
90,6,43,1,33,
90,6,48,1,9,
90,6,57,4,0,
90,6,58,1,49,
90,6,58,4,0,
90,6,59,4,0,
90,6,61,2,0,
90,6,62,1,17,
90,6,92,4,0,
90,6,102,3,0,
90,6,103,2,0,
90,6,104,4,0,
90,6,110,1,1,2
90,6,112,2,0,
90,6,128,1,41,
90,6,129,3,0,
90,6,153,3,0,
90,6,156,4,0,
90,6,164,3,0,
90,6,173,3,0,
90,6,182,1,25,
90,6,182,4,0,
90,6,196,3,0,
90,6,203,3,0,
90,6,207,3,0,
90,6,213,4,0,
90,6,214,3,0,
90,6,216,4,0,
90,6,218,4,0,
90,6,229,2,0,
90,6,237,4,0,
90,6,240,4,0,
90,6,258,4,0,
90,6,263,4,0,
90,6,290,4,0,
90,6,291,4,0,
90,6,333,2,0,
90,6,352,4,0,
90,7,33,1,1,1
90,7,36,2,0,
90,7,38,3,0,
90,7,43,1,36,
90,7,48,1,15,
90,7,57,4,0,
90,7,58,1,50,
90,7,58,4,0,
90,7,59,4,0,
90,7,61,2,0,
90,7,62,1,22,
90,7,92,4,0,
90,7,102,3,0,
90,7,103,2,0,
90,7,104,4,0,
90,7,110,1,1,2
90,7,112,2,0,
90,7,128,1,43,
90,7,153,3,0,
90,7,156,4,0,
90,7,164,3,0,
90,7,182,1,29,
90,7,182,4,0,
90,7,213,4,0,
90,7,216,4,0,
90,7,218,4,0,
90,7,229,2,0,
90,7,237,4,0,
90,7,240,4,0,
90,7,258,4,0,
90,7,263,4,0,
90,7,290,4,0,
90,7,291,4,0,
90,7,333,1,8,
90,7,333,2,0,
90,7,352,4,0,
90,8,33,1,1,
90,8,36,2,0,
90,8,43,1,20,
90,8,48,1,8,
90,8,57,4,0,
90,8,58,1,49,
90,8,58,4,0,
90,8,59,4,0,
90,8,61,2,0,
90,8,62,1,32,
90,8,92,4,0,
90,8,103,2,0,
90,8,104,4,0,
90,8,110,1,4,
90,8,112,2,0,
90,8,128,1,25,
90,8,153,4,0,
90,8,156,4,0,
90,8,164,4,0,
90,8,182,1,16,
90,8,182,4,0,
90,8,203,4,0,
90,8,207,4,0,
90,8,213,4,0,
90,8,214,4,0,
90,8,216,4,0,
90,8,218,4,0,
90,8,229,2,0,
90,8,237,4,0,
90,8,240,4,0,
90,8,250,1,37,
90,8,258,4,0,
90,8,263,4,0,
90,8,290,4,0,
90,8,333,1,13,
90,8,333,2,0,
90,8,334,1,40,
90,8,341,2,0,
90,8,352,4,0,
90,8,362,1,44,
90,8,362,4,0,
90,8,363,4,0,
90,8,371,4,0,
90,8,420,1,28,
90,8,445,4,0,
90,9,33,1,1,
90,9,36,2,0,
90,9,43,1,20,
90,9,48,1,8,
90,9,57,4,0,
90,9,58,1,49,
90,9,58,4,0,
90,9,59,4,0,
90,9,61,2,0,
90,9,62,1,32,
90,9,92,4,0,
90,9,103,2,0,
90,9,104,4,0,
90,9,110,1,4,
90,9,112,2,0,
90,9,128,1,25,
90,9,129,3,0,
90,9,153,4,0,
90,9,156,4,0,
90,9,164,4,0,
90,9,173,3,0,
90,9,182,1,16,
90,9,182,4,0,
90,9,196,3,0,
90,9,203,4,0,
90,9,207,4,0,
90,9,213,4,0,
90,9,214,4,0,
90,9,216,4,0,
90,9,218,4,0,
90,9,229,2,0,
90,9,237,4,0,
90,9,240,4,0,
90,9,250,1,37,
90,9,258,4,0,
90,9,263,4,0,
90,9,290,4,0,
90,9,291,3,0,
90,9,333,1,13,
90,9,333,2,0,
90,9,334,1,40,
90,9,334,3,0,
90,9,341,2,0,
90,9,352,4,0,
90,9,362,1,44,
90,9,362,4,0,
90,9,363,4,0,
90,9,371,4,0,
90,9,420,1,28,
90,9,445,4,0,
90,10,33,1,1,
90,10,36,2,0,
90,10,43,1,20,
90,10,48,1,8,
90,10,57,4,0,
90,10,58,1,49,
90,10,58,4,0,
90,10,59,4,0,
90,10,61,2,0,
90,10,62,1,32,
90,10,92,4,0,
90,10,103,2,0,
90,10,104,4,0,
90,10,110,1,4,
90,10,112,2,0,
90,10,128,1,25,
90,10,129,3,0,
90,10,153,4,0,
90,10,156,4,0,
90,10,164,4,0,
90,10,173,3,0,
90,10,182,1,16,
90,10,182,4,0,
90,10,196,3,0,
90,10,203,4,0,
90,10,207,4,0,
90,10,213,4,0,
90,10,214,4,0,
90,10,216,4,0,
90,10,218,4,0,
90,10,229,2,0,
90,10,237,4,0,
90,10,240,4,0,
90,10,250,1,37,
90,10,250,4,0,
90,10,258,4,0,
90,10,263,4,0,
90,10,290,4,0,
90,10,291,3,0,
90,10,333,1,13,
90,10,333,2,0,
90,10,334,1,40,
90,10,334,3,0,
90,10,341,2,0,
90,10,350,2,0,
90,10,352,4,0,
90,10,362,1,44,
90,10,362,4,0,
90,10,363,4,0,
90,10,371,4,0,
90,10,420,1,28,
90,10,445,4,0,
90,11,33,1,1,
90,11,36,2,0,
90,11,41,2,0,
90,11,43,1,20,
90,11,48,1,8,
90,11,56,1,61,
90,11,57,4,0,
90,11,58,1,52,
90,11,58,4,0,
90,11,59,4,0,
90,11,61,2,0,
90,11,62,1,37,
90,11,92,4,0,
90,11,103,2,0,
90,11,104,4,0,
90,11,110,1,4,
90,11,112,2,0,
90,11,128,1,25,
90,11,153,4,0,
90,11,156,4,0,
90,11,164,4,0,
90,11,182,1,16,
90,11,182,4,0,
90,11,207,4,0,
90,11,213,4,0,
90,11,216,4,0,
90,11,218,4,0,
90,11,229,2,0,
90,11,237,4,0,
90,11,240,4,0,
90,11,250,1,40,
90,11,258,4,0,
90,11,263,4,0,
90,11,291,4,0,
90,11,333,1,13,
90,11,333,2,0,
90,11,334,1,49,
90,11,341,2,0,
90,11,350,2,0,
90,11,352,2,0,
90,11,362,1,44,
90,11,371,4,0,
90,11,392,2,0,
90,11,419,2,0,
90,11,420,1,28,
90,11,496,4,0,
90,11,504,1,56,
90,11,534,1,32,
90,12,33,1,1,1
90,12,43,1,33,
90,12,48,1,9,
90,12,57,4,0,
90,12,58,1,49,
90,12,58,4,0,
90,12,59,4,0,
90,12,62,1,17,
90,12,92,4,0,
90,12,104,4,0,
90,12,110,1,1,2
90,12,128,1,41,
90,12,156,4,0,
90,12,182,1,25,
90,12,182,4,0,
90,12,213,4,0,
90,12,216,4,0,
90,12,218,4,0,
90,12,237,4,0,
90,12,240,4,0,
90,12,258,4,0,
90,12,263,4,0,
90,12,290,4,0,
90,12,291,4,0,
90,12,352,4,0,
90,13,33,1,1,1
90,13,38,3,0,
90,13,43,1,33,
90,13,48,1,9,
90,13,57,4,0,
90,13,58,1,49,
90,13,58,4,0,
90,13,59,4,0,
90,13,62,1,17,
90,13,92,4,0,
90,13,102,3,0,
90,13,104,4,0,
90,13,110,1,1,2
90,13,120,3,0,
90,13,128,1,41,
90,13,156,4,0,
90,13,164,3,0,
90,13,182,1,25,
90,13,182,4,0,
90,13,196,3,0,
90,13,207,3,0,
90,13,213,4,0,
90,13,216,4,0,
90,13,218,4,0,
90,13,237,4,0,
90,13,240,4,0,
90,13,258,4,0,
90,13,263,4,0,
90,13,290,4,0,
90,13,291,4,0,
90,13,352,4,0,
90,14,33,1,1,
90,14,36,2,0,
90,14,41,2,0,
90,14,43,1,20,
90,14,48,1,8,
90,14,56,1,61,
90,14,57,4,0,
90,14,58,1,52,
90,14,58,4,0,
90,14,59,4,0,
90,14,61,2,0,
90,14,62,1,37,
90,14,92,4,0,
90,14,103,2,0,
90,14,104,4,0,
90,14,110,1,4,
90,14,112,2,0,
90,14,128,1,25,
90,14,153,4,0,
90,14,156,4,0,
90,14,164,4,0,
90,14,173,3,0,
90,14,182,1,16,
90,14,182,4,0,
90,14,196,3,0,
90,14,207,4,0,
90,14,213,4,0,
90,14,214,3,0,
90,14,216,4,0,
90,14,218,4,0,
90,14,229,2,0,
90,14,237,4,0,
90,14,240,4,0,
90,14,250,1,40,
90,14,258,4,0,
90,14,263,4,0,
90,14,291,4,0,
90,14,333,1,13,
90,14,333,2,0,
90,14,334,1,49,
90,14,334,3,0,
90,14,341,2,0,
90,14,350,2,0,
90,14,352,2,0,
90,14,362,1,44,
90,14,371,4,0,
90,14,392,2,0,
90,14,419,2,0,
90,14,420,1,28,
90,14,496,4,0,
90,14,504,1,56,
90,14,534,1,32,
90,15,33,1,1,
90,15,36,2,0,
90,15,41,2,0,
90,15,43,1,20,
90,15,48,1,8,
90,15,56,1,61,
90,15,57,4,0,
90,15,58,1,52,
90,15,58,4,0,
90,15,59,4,0,
90,15,61,2,0,
90,15,62,1,37,
90,15,92,4,0,
90,15,103,2,0,
90,15,104,4,0,
90,15,110,1,4,
90,15,112,2,0,
90,15,128,1,25,
90,15,153,4,0,
90,15,156,4,0,
90,15,164,4,0,
90,15,182,1,16,
90,15,182,4,0,
90,15,207,4,0,
90,15,213,4,0,
90,15,214,4,0,
90,15,216,4,0,
90,15,218,4,0,
90,15,229,2,0,
90,15,237,4,0,
90,15,240,4,0,
90,15,250,1,40,
90,15,258,4,0,
90,15,263,4,0,
90,15,333,1,13,
90,15,333,2,0,
90,15,334,1,49,
90,15,341,2,0,
90,15,350,2,0,
90,15,352,2,0,
90,15,362,1,44,
90,15,371,4,0,
90,15,392,2,0,
90,15,419,2,0,
90,15,420,1,28,
90,15,496,4,0,
90,15,504,1,56,
90,15,534,1,32,
90,15,590,4,0,
90,16,33,1,1,1
90,16,36,2,0,
90,16,41,2,0,
90,16,43,1,20,1
90,16,48,1,8,1
90,16,56,1,61,1
90,16,57,4,0,
90,16,58,1,52,1
90,16,58,4,0,
90,16,59,4,0,
90,16,61,2,0,
90,16,62,1,37,1
90,16,92,4,0,
90,16,103,2,0,
90,16,104,4,0,
90,16,110,1,4,1
90,16,112,2,0,
90,16,128,1,25,1
90,16,153,4,0,
90,16,156,4,0,
90,16,164,4,0,
90,16,173,3,0,
90,16,182,1,16,1
90,16,182,4,0,
90,16,196,3,0,
90,16,207,4,0,
90,16,213,4,0,
90,16,214,4,0,
90,16,216,4,0,
90,16,218,4,0,
90,16,229,2,0,
90,16,237,4,0,
90,16,240,4,0,
90,16,250,1,40,1
90,16,258,4,0,
90,16,263,4,0,
90,16,290,4,0,
90,16,291,4,0,
90,16,333,1,13,1
90,16,333,2,0,
90,16,334,1,49,1
90,16,334,3,0,
90,16,341,2,0,
90,16,350,2,0,
90,16,352,2,0,
90,16,352,3,0,
90,16,362,1,44,1
90,16,371,4,0,
90,16,392,2,0,
90,16,419,2,0,
90,16,420,1,28,1
90,16,496,4,0,
90,16,504,1,56,1
90,16,534,1,32,1
90,16,590,4,0,
90,17,33,1,1,1
90,17,36,2,0,
90,17,41,2,0,
90,17,43,1,20,
90,17,48,1,8,
90,17,55,1,1,2
90,17,56,1,61,
90,17,57,4,0,
90,17,58,1,52,
90,17,58,4,0,
90,17,59,4,0,
90,17,61,2,0,
90,17,62,1,37,
90,17,92,4,0,
90,17,103,2,0,
90,17,104,4,0,
90,17,110,1,4,
90,17,112,2,0,
90,17,128,1,25,
90,17,153,4,0,
90,17,156,4,0,
90,17,164,4,0,
90,17,182,1,16,
90,17,182,4,0,
90,17,207,4,0,
90,17,213,4,0,
90,17,214,4,0,
90,17,216,4,0,
90,17,218,4,0,
90,17,229,2,0,
90,17,237,4,0,
90,17,240,4,0,
90,17,250,1,40,
90,17,258,4,0,
90,17,263,4,0,
90,17,333,1,13,
90,17,333,2,0,
90,17,334,1,49,
90,17,341,2,0,
90,17,350,2,0,
90,17,352,2,0,
90,17,362,1,44,
90,17,371,4,0,
90,17,392,2,0,
90,17,419,2,0,
90,17,420,1,28,
90,17,496,4,0,
90,17,504,1,56,
90,17,534,1,32,
90,17,590,4,0,
90,18,33,1,1,1
90,18,36,2,0,
90,18,41,2,0,
90,18,43,1,20,
90,18,48,1,8,
90,18,55,1,1,2
90,18,56,1,61,
90,18,57,4,0,
90,18,58,1,52,
90,18,58,4,0,
90,18,59,4,0,
90,18,61,2,0,
90,18,62,1,37,
90,18,92,4,0,
90,18,103,2,0,
90,18,104,4,0,
90,18,110,1,4,
90,18,112,2,0,
90,18,128,1,25,
90,18,153,4,0,
90,18,156,4,0,
90,18,164,4,0,
90,18,182,1,16,
90,18,182,4,0,
90,18,207,4,0,
90,18,213,4,0,
90,18,214,4,0,
90,18,216,4,0,
90,18,218,4,0,
90,18,229,2,0,
90,18,237,4,0,
90,18,240,4,0,
90,18,250,1,40,
90,18,258,4,0,
90,18,263,4,0,
90,18,333,1,13,
90,18,333,2,0,
90,18,334,1,49,
90,18,341,2,0,
90,18,350,2,0,
90,18,352,2,0,
90,18,362,1,44,
90,18,371,4,0,
90,18,392,2,0,
90,18,419,2,0,
90,18,420,1,28,
90,18,496,4,0,
90,18,504,1,56,
90,18,534,1,32,
90,18,590,4,0,
91,1,36,4,0,
91,1,38,4,0,
91,1,48,1,1,2
91,1,55,4,0,
91,1,57,4,0,
91,1,58,4,0,
91,1,59,4,0,
91,1,61,4,0,
91,1,62,1,1,4
91,1,63,4,0,
91,1,92,4,0,
91,1,99,4,0,
91,1,100,4,0,
91,1,102,4,0,
91,1,104,4,0,
91,1,110,1,1,1
91,1,115,4,0,
91,1,117,4,0,
91,1,120,4,0,
91,1,128,1,1,3
91,1,129,4,0,
91,1,131,1,50,
91,1,153,4,0,
91,1,156,4,0,
91,1,161,4,0,
91,1,164,4,0,
91,2,36,4,0,
91,2,38,4,0,
91,2,48,1,1,2
91,2,55,4,0,
91,2,57,4,0,
91,2,58,4,0,
91,2,59,4,0,
91,2,61,4,0,
91,2,62,1,1,4
91,2,63,4,0,
91,2,92,4,0,
91,2,99,4,0,
91,2,100,4,0,
91,2,102,4,0,
91,2,104,4,0,
91,2,110,1,1,1
91,2,115,4,0,
91,2,117,4,0,
91,2,120,4,0,
91,2,128,1,1,3
91,2,129,4,0,
91,2,131,1,50,
91,2,153,4,0,
91,2,156,4,0,
91,2,161,4,0,
91,2,164,4,0,
91,3,48,1,1,2
91,3,57,4,0,
91,3,59,4,0,
91,3,62,1,1,3
91,3,63,4,0,
91,3,92,4,0,
91,3,104,4,0,
91,3,110,1,1,1
91,3,129,4,0,
91,3,131,1,41,
91,3,156,4,0,
91,3,173,4,0,
91,3,174,4,0,
91,3,182,1,1,4
91,3,182,4,0,
91,3,196,4,0,
91,3,203,4,0,
91,3,207,4,0,
91,3,213,4,0,
91,3,214,4,0,
91,3,216,4,0,
91,3,218,4,0,
91,3,237,4,0,
91,3,240,4,0,
91,3,250,4,0,
91,4,48,1,1,2
91,4,57,4,0,
91,4,58,3,0,
91,4,59,4,0,
91,4,62,1,1,3
91,4,63,4,0,
91,4,92,4,0,
91,4,104,4,0,
91,4,110,1,1,1
91,4,129,4,0,
91,4,131,1,41,
91,4,156,4,0,
91,4,173,4,0,
91,4,174,4,0,
91,4,182,1,1,4
91,4,182,4,0,
91,4,191,1,33,
91,4,196,4,0,
91,4,203,4,0,
91,4,207,4,0,
91,4,213,4,0,
91,4,214,4,0,
91,4,216,4,0,
91,4,218,4,0,
91,4,237,4,0,
91,4,240,4,0,
91,4,250,4,0,
91,5,48,1,1,2
91,5,57,4,0,
91,5,58,4,0,
91,5,59,4,0,
91,5,62,1,1,3
91,5,63,4,0,
91,5,92,4,0,
91,5,104,4,0,
91,5,110,1,1,1
91,5,131,1,41,
91,5,156,4,0,
91,5,182,1,1,4
91,5,182,4,0,
91,5,191,1,33,
91,5,213,4,0,
91,5,216,4,0,
91,5,218,4,0,
91,5,237,4,0,
91,5,240,4,0,
91,5,258,4,0,
91,5,259,4,0,
91,5,263,4,0,
91,5,290,4,0,
91,5,291,4,0,
91,5,352,4,0,
91,6,38,3,0,
91,6,48,1,1,2
91,6,57,4,0,
91,6,58,4,0,
91,6,59,4,0,
91,6,62,1,1,3
91,6,63,4,0,
91,6,92,4,0,
91,6,102,3,0,
91,6,104,4,0,
91,6,110,1,1,1
91,6,129,3,0,
91,6,131,1,41,
91,6,153,3,0,
91,6,156,4,0,
91,6,164,3,0,
91,6,173,3,0,
91,6,182,1,1,4
91,6,182,4,0,
91,6,191,1,33,
91,6,196,3,0,
91,6,203,3,0,
91,6,207,3,0,
91,6,213,4,0,
91,6,214,3,0,
91,6,216,4,0,
91,6,218,4,0,
91,6,237,4,0,
91,6,240,4,0,
91,6,258,4,0,
91,6,259,4,0,
91,6,263,4,0,
91,6,290,4,0,
91,6,291,4,0,
91,6,352,4,0,
91,7,38,3,0,
91,7,48,1,1,2
91,7,57,4,0,
91,7,58,4,0,
91,7,59,4,0,
91,7,62,1,1,3
91,7,63,4,0,
91,7,92,4,0,
91,7,102,3,0,
91,7,104,4,0,
91,7,110,1,1,1
91,7,131,1,43,
91,7,153,3,0,
91,7,156,4,0,
91,7,164,3,0,
91,7,182,1,1,4
91,7,182,4,0,
91,7,191,1,36,
91,7,213,4,0,
91,7,216,4,0,
91,7,218,4,0,
91,7,237,4,0,
91,7,240,4,0,
91,7,258,4,0,
91,7,259,4,0,
91,7,263,4,0,
91,7,290,4,0,
91,7,291,4,0,
91,7,352,4,0,
91,8,48,1,1,3
91,8,57,4,0,
91,8,58,4,0,
91,8,59,4,0,
91,8,62,1,1,4
91,8,63,4,0,
91,8,92,4,0,
91,8,104,4,0,
91,8,110,1,1,2
91,8,131,1,40,
91,8,153,4,0,
91,8,156,4,0,
91,8,164,4,0,
91,8,182,1,1,5
91,8,182,4,0,
91,8,191,1,28,
91,8,203,4,0,
91,8,207,4,0,
91,8,213,4,0,
91,8,214,4,0,
91,8,216,4,0,
91,8,218,4,0,
91,8,237,4,0,
91,8,240,4,0,
91,8,258,4,0,
91,8,259,4,0,
91,8,263,4,0,
91,8,290,4,0,
91,8,352,4,0,
91,8,362,4,0,
91,8,363,4,0,
91,8,371,4,0,
91,8,390,1,1,1
91,8,398,4,0,
91,8,416,4,0,
91,8,419,4,0,
91,8,445,4,0,
91,9,48,1,1,3
91,9,57,4,0,
91,9,58,4,0,
91,9,59,4,0,
91,9,62,1,1,4
91,9,63,4,0,
91,9,92,4,0,
91,9,104,4,0,
91,9,110,1,1,2
91,9,129,3,0,
91,9,131,1,40,
91,9,153,4,0,
91,9,156,4,0,
91,9,164,4,0,
91,9,173,3,0,
91,9,182,1,1,5
91,9,182,4,0,
91,9,191,1,28,
91,9,196,3,0,
91,9,203,4,0,
91,9,207,4,0,
91,9,213,4,0,
91,9,214,4,0,
91,9,216,4,0,
91,9,218,4,0,
91,9,237,4,0,
91,9,240,4,0,
91,9,258,4,0,
91,9,259,4,0,
91,9,263,4,0,
91,9,290,4,0,
91,9,291,3,0,
91,9,324,3,0,
91,9,334,3,0,
91,9,352,4,0,
91,9,362,4,0,
91,9,363,4,0,
91,9,371,4,0,
91,9,390,1,1,1
91,9,398,4,0,
91,9,416,4,0,
91,9,419,4,0,
91,9,445,4,0,
91,10,48,1,1,3
91,10,57,4,0,
91,10,58,4,0,
91,10,59,4,0,
91,10,62,1,1,4
91,10,63,4,0,
91,10,92,4,0,
91,10,104,4,0,
91,10,110,1,1,2
91,10,129,3,0,
91,10,131,1,40,
91,10,153,4,0,
91,10,156,4,0,
91,10,164,4,0,
91,10,173,3,0,
91,10,182,1,1,5
91,10,182,4,0,
91,10,191,1,28,
91,10,196,3,0,
91,10,203,4,0,
91,10,207,4,0,
91,10,213,4,0,
91,10,214,4,0,
91,10,216,4,0,
91,10,218,4,0,
91,10,237,4,0,
91,10,240,4,0,
91,10,250,4,0,
91,10,258,4,0,
91,10,259,4,0,
91,10,263,4,0,
91,10,290,4,0,
91,10,291,3,0,
91,10,324,3,0,
91,10,334,3,0,
91,10,352,4,0,
91,10,362,4,0,
91,10,363,4,0,
91,10,371,4,0,
91,10,390,1,1,1
91,10,398,4,0,
91,10,416,4,0,
91,10,419,4,0,
91,10,445,4,0,
91,11,48,1,1,3
91,11,57,4,0,
91,11,58,4,0,
91,11,59,4,0,
91,11,62,1,1,5
91,11,63,4,0,
91,11,92,4,0,
91,11,104,4,0,
91,11,110,1,1,2
91,11,131,1,13,
91,11,153,4,0,
91,11,156,4,0,
91,11,164,4,0,
91,11,182,1,1,4
91,11,182,4,0,
91,11,191,1,28,
91,11,207,4,0,
91,11,213,4,0,
91,11,216,4,0,
91,11,218,4,0,
91,11,237,4,0,
91,11,240,4,0,
91,11,258,4,0,
91,11,259,4,0,
91,11,263,4,0,
91,11,291,4,0,
91,11,371,4,0,
91,11,390,1,1,1
91,11,398,4,0,
91,11,416,4,0,
91,11,496,4,0,
91,11,524,4,0,
91,11,556,1,52,
91,12,48,1,1,2
91,12,57,4,0,
91,12,58,4,0,
91,12,59,4,0,
91,12,62,1,1,3
91,12,63,4,0,
91,12,92,4,0,
91,12,104,4,0,
91,12,110,1,1,1
91,12,131,1,41,
91,12,156,4,0,
91,12,182,1,1,4
91,12,182,4,0,
91,12,191,1,33,
91,12,213,4,0,
91,12,216,4,0,
91,12,218,4,0,
91,12,237,4,0,
91,12,240,4,0,
91,12,258,4,0,
91,12,259,4,0,
91,12,263,4,0,
91,12,290,4,0,
91,12,291,4,0,
91,12,352,4,0,
91,13,38,3,0,
91,13,48,1,1,2
91,13,57,4,0,
91,13,58,4,0,
91,13,59,4,0,
91,13,62,1,1,3
91,13,63,4,0,
91,13,92,4,0,
91,13,102,3,0,
91,13,104,4,0,
91,13,110,1,1,1
91,13,120,3,0,
91,13,131,1,41,
91,13,156,4,0,
91,13,164,3,0,
91,13,182,1,1,4
91,13,182,4,0,
91,13,191,1,33,
91,13,196,3,0,
91,13,207,3,0,
91,13,213,4,0,
91,13,216,4,0,
91,13,218,4,0,
91,13,237,4,0,
91,13,240,4,0,
91,13,258,4,0,
91,13,259,4,0,
91,13,263,4,0,
91,13,290,4,0,
91,13,291,4,0,
91,13,352,4,0,
91,14,48,1,1,3
91,14,57,4,0,
91,14,58,4,0,
91,14,59,4,0,
91,14,62,1,1,5
91,14,63,4,0,
91,14,92,4,0,
91,14,104,4,0,
91,14,110,1,1,2
91,14,131,1,13,
91,14,153,4,0,
91,14,156,4,0,
91,14,164,4,0,
91,14,173,3,0,
91,14,182,1,1,4
91,14,182,4,0,
91,14,191,1,28,
91,14,196,3,0,
91,14,207,4,0,
91,14,213,4,0,
91,14,214,3,0,
91,14,216,4,0,
91,14,218,4,0,
91,14,237,4,0,
91,14,240,4,0,
91,14,258,4,0,
91,14,259,4,0,
91,14,263,4,0,
91,14,291,4,0,
91,14,324,3,0,
91,14,334,3,0,
91,14,371,4,0,
91,14,390,1,1,1
91,14,398,4,0,
91,14,416,4,0,
91,14,496,4,0,
91,14,524,4,0,
91,14,556,1,52,
91,15,48,1,1,5
91,15,56,1,1,1
91,15,57,4,0,
91,15,58,4,0,
91,15,59,4,0,
91,15,62,1,1,7
91,15,63,4,0,
91,15,92,4,0,
91,15,104,4,0,
91,15,110,1,1,4
91,15,131,1,13,
91,15,153,4,0,
91,15,156,4,0,
91,15,164,4,0,
91,15,182,1,1,6
91,15,182,4,0,
91,15,191,1,28,
91,15,207,4,0,
91,15,213,4,0,
91,15,214,4,0,
91,15,216,4,0,
91,15,218,4,0,
91,15,237,4,0,
91,15,240,4,0,
91,15,258,4,0,
91,15,259,4,0,
91,15,263,4,0,
91,15,371,4,0,
91,15,390,1,1,3
91,15,398,4,0,
91,15,416,4,0,
91,15,496,4,0,
91,15,504,1,1,2
91,15,524,4,0,
91,15,556,1,50,
91,15,590,4,0,
91,16,48,1,1,5
91,16,56,1,1,1
91,16,57,4,0,
91,16,58,4,0,
91,16,59,4,0,
91,16,62,1,1,7
91,16,63,4,0,
91,16,92,4,0,
91,16,104,4,0,
91,16,110,1,1,4
91,16,131,1,13,1
91,16,153,4,0,
91,16,156,4,0,
91,16,164,4,0,
91,16,173,3,0,
91,16,182,1,1,6
91,16,182,4,0,
91,16,191,1,28,1
91,16,196,3,0,
91,16,207,4,0,
91,16,213,4,0,
91,16,214,4,0,
91,16,216,4,0,
91,16,218,4,0,
91,16,237,4,0,
91,16,240,4,0,
91,16,258,4,0,
91,16,259,4,0,
91,16,263,4,0,
91,16,290,4,0,
91,16,291,4,0,
91,16,324,3,0,
91,16,334,3,0,
91,16,352,3,0,
91,16,371,4,0,
91,16,390,1,1,3
91,16,398,4,0,
91,16,416,4,0,
91,16,496,4,0,
91,16,504,1,1,2
91,16,524,4,0,
91,16,556,1,50,1
91,16,590,4,0,
91,17,48,1,1,5
91,17,56,1,1,1
91,17,57,4,0,
91,17,58,4,0,
91,17,59,4,0,
91,17,62,1,1,7
91,17,63,4,0,
91,17,92,4,0,
91,17,104,4,0,
91,17,110,1,1,4
91,17,131,1,13,
91,17,153,4,0,
91,17,156,4,0,
91,17,164,4,0,
91,17,182,1,1,6
91,17,182,4,0,
91,17,191,1,28,
91,17,207,4,0,
91,17,213,4,0,
91,17,214,4,0,
91,17,216,4,0,
91,17,218,4,0,
91,17,237,4,0,
91,17,240,4,0,
91,17,258,4,0,
91,17,259,4,0,
91,17,263,4,0,
91,17,371,4,0,
91,17,390,1,1,3
91,17,398,4,0,
91,17,416,4,0,
91,17,496,4,0,
91,17,504,1,1,2
91,17,524,4,0,
91,17,556,1,50,
91,17,590,4,0,
91,17,684,4,0,
91,18,48,1,1,5
91,18,56,1,1,1
91,18,57,4,0,
91,18,58,4,0,
91,18,59,4,0,
91,18,62,1,1,7
91,18,63,4,0,
91,18,92,4,0,
91,18,104,4,0,
91,18,110,1,1,4
91,18,131,1,13,
91,18,153,4,0,
91,18,156,4,0,
91,18,164,4,0,
91,18,182,1,1,6
91,18,182,4,0,
91,18,191,1,28,
91,18,207,4,0,
91,18,213,4,0,
91,18,214,4,0,
91,18,216,4,0,
91,18,218,4,0,
91,18,237,4,0,
91,18,240,4,0,
91,18,258,4,0,
91,18,259,4,0,
91,18,263,4,0,
91,18,371,4,0,
91,18,390,1,1,3
91,18,398,4,0,
91,18,416,4,0,
91,18,496,4,0,
91,18,504,1,1,2
91,18,524,4,0,
91,18,556,1,50,
91,18,590,4,0,
91,18,684,4,0,
92,1,72,4,0,
92,1,85,4,0,
92,1,87,4,0,
92,1,92,4,0,
92,1,94,4,0,
92,1,95,1,27,
92,1,99,4,0,
92,1,101,1,1,3
92,1,102,4,0,
92,1,104,4,0,
92,1,109,1,1,2
92,1,117,4,0,
92,1,120,4,0,
92,1,122,1,1,1
92,1,138,1,35,
92,1,138,4,0,
92,1,149,4,0,
92,1,153,4,0,
92,1,156,4,0,
92,1,164,4,0,
92,2,72,4,0,
92,2,85,4,0,
92,2,87,4,0,
92,2,92,4,0,
92,2,94,4,0,
92,2,95,1,27,
92,2,99,4,0,
92,2,101,1,1,3
92,2,102,4,0,
92,2,104,4,0,
92,2,109,1,1,2
92,2,117,4,0,
92,2,120,4,0,
92,2,122,1,1,1
92,2,138,1,35,
92,2,138,4,0,
92,2,149,4,0,
92,2,153,4,0,
92,2,156,4,0,
92,2,164,4,0,
92,3,87,4,0,
92,3,92,4,0,
92,3,94,4,0,
92,3,95,1,1,1
92,3,101,1,21,
92,3,104,4,0,
92,3,109,1,28,
92,3,114,2,0,
92,3,122,1,1,2
92,3,138,1,33,
92,3,138,4,0,
92,3,149,2,0,
92,3,156,4,0,
92,3,168,4,0,
92,3,171,4,0,
92,3,173,4,0,
92,3,174,1,16,
92,3,174,4,0,
92,3,180,1,8,
92,3,182,4,0,
92,3,192,4,0,
92,3,194,1,36,
92,3,195,2,0,
92,3,202,4,0,
92,3,203,4,0,
92,3,207,4,0,
92,3,212,1,13,
92,3,213,4,0,
92,3,214,4,0,
92,3,216,4,0,
92,3,218,4,0,
92,3,237,4,0,
92,3,240,4,0,
92,3,241,4,0,
92,3,244,4,0,
92,3,247,4,0,
92,4,85,3,0,
92,4,87,4,0,
92,4,92,4,0,
92,4,94,4,0,
92,4,95,1,1,1
92,4,101,1,21,
92,4,104,4,0,
92,4,109,1,28,
92,4,114,2,0,
92,4,122,1,1,2
92,4,138,1,33,
92,4,138,4,0,
92,4,149,2,0,
92,4,156,4,0,
92,4,168,4,0,
92,4,171,4,0,
92,4,173,4,0,
92,4,174,1,16,
92,4,174,4,0,
92,4,180,1,8,
92,4,182,4,0,
92,4,192,4,0,
92,4,194,1,36,
92,4,195,2,0,
92,4,202,4,0,
92,4,203,4,0,
92,4,207,4,0,
92,4,212,1,13,
92,4,213,4,0,
92,4,214,4,0,
92,4,216,4,0,
92,4,218,4,0,
92,4,237,4,0,
92,4,240,4,0,
92,4,241,4,0,
92,4,244,4,0,
92,4,247,4,0,
92,5,85,4,0,
92,5,92,4,0,
92,5,94,4,0,
92,5,95,1,1,1
92,5,101,1,21,
92,5,104,4,0,
92,5,109,1,28,
92,5,114,2,0,
92,5,122,1,1,2
92,5,138,1,33,
92,5,149,2,0,
92,5,153,2,0,
92,5,156,4,0,
92,5,168,4,0,
92,5,174,1,16,
92,5,180,1,8,
92,5,182,4,0,
92,5,188,4,0,
92,5,194,1,36,
92,5,195,2,0,
92,5,202,4,0,
92,5,212,1,13,
92,5,213,4,0,
92,5,216,4,0,
92,5,218,4,0,
92,5,237,4,0,
92,5,240,4,0,
92,5,241,4,0,
92,5,247,4,0,
92,5,259,4,0,
92,5,261,2,0,
92,5,263,4,0,
92,5,269,4,0,
92,5,285,4,0,
92,5,288,2,0,
92,5,289,4,0,
92,5,290,4,0,
92,5,310,2,0,
92,6,85,4,0,
92,6,92,4,0,
92,6,94,4,0,
92,6,95,1,1,1
92,6,101,1,21,
92,6,102,3,0,
92,6,104,4,0,
92,6,109,1,28,
92,6,114,2,0,
92,6,122,1,1,2
92,6,138,1,33,
92,6,138,3,0,
92,6,149,2,0,
92,6,153,2,0,
92,6,153,3,0,
92,6,156,4,0,
92,6,164,3,0,
92,6,168,4,0,
92,6,173,3,0,
92,6,174,1,16,
92,6,180,1,8,
92,6,182,4,0,
92,6,188,4,0,
92,6,194,1,36,
92,6,195,2,0,
92,6,202,4,0,
92,6,203,3,0,
92,6,207,3,0,
92,6,212,1,13,
92,6,213,4,0,
92,6,214,3,0,
92,6,216,4,0,
92,6,218,4,0,
92,6,237,4,0,
92,6,240,4,0,
92,6,241,4,0,
92,6,244,3,0,
92,6,247,4,0,
92,6,259,4,0,
92,6,261,2,0,
92,6,263,4,0,
92,6,269,4,0,
92,6,285,4,0,
92,6,288,2,0,
92,6,289,4,0,
92,6,290,4,0,
92,6,310,2,0,
92,7,85,4,0,
92,7,92,4,0,
92,7,94,4,0,
92,7,95,1,1,1
92,7,101,1,16,
92,7,102,3,0,
92,7,104,4,0,
92,7,109,1,21,
92,7,114,2,0,
92,7,122,1,1,2
92,7,138,1,28,
92,7,138,3,0,
92,7,149,2,0,
92,7,153,2,0,
92,7,153,3,0,
92,7,156,4,0,
92,7,164,3,0,
92,7,168,4,0,
92,7,171,1,41,
92,7,174,1,13,
92,7,180,1,8,
92,7,182,4,0,
92,7,188,4,0,
92,7,194,1,33,
92,7,195,2,0,
92,7,202,4,0,
92,7,212,1,48,
92,7,213,4,0,
92,7,216,4,0,
92,7,218,4,0,
92,7,237,4,0,
92,7,240,4,0,
92,7,241,4,0,
92,7,247,1,36,
92,7,247,4,0,
92,7,259,4,0,
92,7,261,2,0,
92,7,263,4,0,
92,7,269,4,0,
92,7,285,4,0,
92,7,288,2,0,
92,7,289,4,0,
92,7,290,4,0,
92,7,310,2,0,
92,8,7,2,0,
92,8,8,2,0,
92,8,9,2,0,
92,8,85,4,0,
92,8,92,4,0,
92,8,94,4,0,
92,8,95,1,1,1
92,8,101,1,15,
92,8,104,4,0,
92,8,109,1,19,
92,8,114,2,0,
92,8,122,1,1,2
92,8,138,1,33,
92,8,138,4,0,
92,8,149,2,0,
92,8,153,2,0,
92,8,153,4,0,
92,8,156,4,0,
92,8,164,4,0,
92,8,168,4,0,
92,8,171,1,43,
92,8,174,1,12,
92,8,180,1,5,
92,8,182,4,0,
92,8,188,4,0,
92,8,194,1,40,
92,8,195,2,0,
92,8,202,4,0,
92,8,203,4,0,
92,8,207,4,0,
92,8,212,1,8,
92,8,213,4,0,
92,8,214,4,0,
92,8,216,4,0,
92,8,218,4,0,
92,8,237,4,0,
92,8,240,4,0,
92,8,241,4,0,
92,8,244,4,0,
92,8,247,1,29,
92,8,247,4,0,
92,8,259,4,0,
92,8,261,2,0,
92,8,261,4,0,
92,8,263,4,0,
92,8,269,4,0,
92,8,285,4,0,
92,8,288,2,0,
92,8,289,4,0,
92,8,290,4,0,
92,8,310,2,0,
92,8,363,4,0,
92,8,371,1,26,
92,8,371,4,0,
92,8,373,4,0,
92,8,389,1,22,
92,8,399,1,36,
92,8,399,4,0,
92,8,412,4,0,
92,8,433,4,0,
92,8,445,4,0,
92,9,7,2,0,
92,9,7,3,0,
92,9,8,2,0,
92,9,8,3,0,
92,9,9,2,0,
92,9,9,3,0,
92,9,85,4,0,
92,9,92,4,0,
92,9,94,4,0,
92,9,95,1,1,1
92,9,101,1,15,
92,9,104,4,0,
92,9,109,1,19,
92,9,114,2,0,
92,9,122,1,1,2
92,9,138,1,33,
92,9,138,4,0,
92,9,149,2,0,
92,9,153,2,0,
92,9,153,4,0,
92,9,156,4,0,
92,9,164,4,0,
92,9,168,4,0,
92,9,171,1,43,
92,9,173,3,0,
92,9,174,1,12,
92,9,180,1,5,
92,9,180,3,0,
92,9,182,4,0,
92,9,188,4,0,
92,9,194,1,40,
92,9,195,2,0,
92,9,196,3,0,
92,9,202,4,0,
92,9,203,4,0,
92,9,207,4,0,
92,9,212,1,8,
92,9,213,4,0,
92,9,214,4,0,
92,9,216,4,0,
92,9,218,4,0,
92,9,237,4,0,
92,9,240,4,0,
92,9,241,4,0,
92,9,244,4,0,
92,9,247,1,29,
92,9,247,4,0,
92,9,253,3,0,
92,9,259,4,0,
92,9,261,2,0,
92,9,261,4,0,
92,9,263,4,0,
92,9,269,4,0,
92,9,271,3,0,
92,9,282,3,0,
92,9,285,4,0,
92,9,288,2,0,
92,9,289,4,0,
92,9,290,4,0,
92,9,310,2,0,
92,9,363,4,0,
92,9,371,1,26,
92,9,371,4,0,
92,9,373,4,0,
92,9,389,1,22,
92,9,389,3,0,
92,9,399,1,36,
92,9,399,4,0,
92,9,412,4,0,
92,9,433,4,0,
92,9,445,4,0,
92,9,466,3,0,
92,10,7,2,0,
92,10,7,3,0,
92,10,8,2,0,
92,10,8,3,0,
92,10,9,2,0,
92,10,9,3,0,
92,10,50,2,0,
92,10,85,4,0,
92,10,92,4,0,
92,10,94,4,0,
92,10,95,1,1,1
92,10,101,1,15,
92,10,104,4,0,
92,10,109,1,19,
92,10,114,2,0,
92,10,122,1,1,2
92,10,138,1,33,
92,10,138,4,0,
92,10,149,2,0,
92,10,153,2,0,
92,10,153,4,0,
92,10,156,4,0,
92,10,164,4,0,
92,10,168,4,0,
92,10,171,1,43,
92,10,173,3,0,
92,10,174,1,12,
92,10,180,1,5,
92,10,180,3,0,
92,10,182,4,0,
92,10,188,4,0,
92,10,194,1,40,
92,10,195,2,0,
92,10,196,3,0,
92,10,202,4,0,
92,10,203,4,0,
92,10,207,4,0,
92,10,212,1,8,
92,10,213,4,0,
92,10,214,4,0,
92,10,216,4,0,
92,10,218,4,0,
92,10,220,3,0,
92,10,237,4,0,
92,10,240,4,0,
92,10,241,4,0,
92,10,244,4,0,
92,10,247,1,29,
92,10,247,4,0,
92,10,253,3,0,
92,10,259,4,0,
92,10,261,2,0,
92,10,261,4,0,
92,10,263,4,0,
92,10,269,4,0,
92,10,271,3,0,
92,10,282,3,0,
92,10,285,4,0,
92,10,288,2,0,
92,10,289,4,0,
92,10,290,4,0,
92,10,310,2,0,
92,10,363,4,0,
92,10,371,1,26,
92,10,371,4,0,
92,10,373,4,0,
92,10,389,1,22,
92,10,389,3,0,
92,10,399,1,36,
92,10,399,4,0,
92,10,412,4,0,
92,10,433,4,0,
92,10,445,4,0,
92,10,466,3,0,
92,11,7,2,0,
92,11,8,2,0,
92,11,9,2,0,
92,11,50,2,0,
92,11,85,4,0,
92,11,92,4,0,
92,11,94,4,0,
92,11,95,1,1,1
92,11,101,1,15,
92,11,104,4,0,
92,11,109,1,19,
92,11,114,2,0,
92,11,122,1,1,2
92,11,123,2,0,
92,11,138,1,33,
92,11,138,4,0,
92,11,149,2,0,
92,11,153,4,0,
92,11,156,4,0,
92,11,164,4,0,
92,11,168,4,0,
92,11,171,1,47,
92,11,174,1,12,
92,11,180,1,5,
92,11,182,4,0,
92,11,184,2,0,
92,11,188,4,0,
92,11,194,1,40,
92,11,195,2,0,
92,11,207,4,0,
92,11,212,1,8,
92,11,213,4,0,
92,11,216,4,0,
92,11,218,4,0,
92,11,237,4,0,
92,11,240,4,0,
92,11,241,4,0,
92,11,244,4,0,
92,11,247,1,29,
92,11,247,4,0,
92,11,259,4,0,
92,11,261,4,0,
92,11,263,4,0,
92,11,269,4,0,
92,11,288,2,0,
92,11,310,2,0,
92,11,371,1,26,
92,11,371,4,0,
92,11,373,4,0,
92,11,389,1,22,
92,11,399,1,36,
92,11,412,4,0,
92,11,433,4,0,
92,11,474,4,0,
92,11,477,4,0,
92,11,496,4,0,
92,11,499,2,0,
92,11,506,1,43,
92,12,85,4,0,
92,12,92,4,0,
92,12,94,4,0,
92,12,95,1,1,1
92,12,101,1,21,
92,12,104,4,0,
92,12,109,1,28,
92,12,122,1,1,2
92,12,138,1,33,
92,12,156,4,0,
92,12,168,4,0,
92,12,174,1,16,
92,12,180,1,8,
92,12,182,4,0,
92,12,188,4,0,
92,12,194,1,36,
92,12,202,4,0,
92,12,212,1,13,
92,12,213,4,0,
92,12,216,4,0,
92,12,218,4,0,
92,12,237,4,0,
92,12,240,4,0,
92,12,241,4,0,
92,12,247,4,0,
92,12,259,4,0,
92,12,263,4,0,
92,12,269,4,0,
92,12,285,4,0,
92,12,289,4,0,
92,12,290,4,0,
92,13,85,4,0,
92,13,92,4,0,
92,13,94,4,0,
92,13,95,1,1,1
92,13,101,1,21,
92,13,102,3,0,
92,13,104,4,0,
92,13,109,1,28,
92,13,120,3,0,
92,13,122,1,1,2
92,13,138,1,33,
92,13,138,3,0,
92,13,156,4,0,
92,13,164,3,0,
92,13,168,4,0,
92,13,171,3,0,
92,13,174,1,16,
92,13,180,1,8,
92,13,182,4,0,
92,13,188,4,0,
92,13,194,1,36,
92,13,202,4,0,
92,13,207,3,0,
92,13,212,1,13,
92,13,213,4,0,
92,13,216,4,0,
92,13,218,4,0,
92,13,237,4,0,
92,13,240,4,0,
92,13,241,4,0,
92,13,247,4,0,
92,13,259,4,0,
92,13,263,4,0,
92,13,269,4,0,
92,13,285,4,0,
92,13,289,4,0,
92,13,290,4,0,
92,14,7,2,0,
92,14,7,3,0,
92,14,8,2,0,
92,14,8,3,0,
92,14,9,2,0,
92,14,9,3,0,
92,14,50,2,0,
92,14,85,4,0,
92,14,92,4,0,
92,14,94,4,0,
92,14,95,1,1,1
92,14,101,1,15,
92,14,104,4,0,
92,14,109,1,19,
92,14,114,2,0,
92,14,122,1,1,2
92,14,123,2,0,
92,14,138,1,33,
92,14,138,4,0,
92,14,149,2,0,
92,14,153,4,0,
92,14,156,4,0,
92,14,164,4,0,
92,14,168,4,0,
92,14,171,1,47,
92,14,173,3,0,
92,14,174,1,12,
92,14,180,1,5,
92,14,180,3,0,
92,14,182,4,0,
92,14,184,2,0,
92,14,188,4,0,
92,14,194,1,40,
92,14,195,2,0,
92,14,196,3,0,
92,14,202,3,0,
92,14,207,4,0,
92,14,212,1,8,
92,14,213,4,0,
92,14,214,3,0,
92,14,216,4,0,
92,14,218,4,0,
92,14,220,3,0,
92,14,237,4,0,
92,14,240,4,0,
92,14,241,4,0,
92,14,244,4,0,
92,14,247,1,29,
92,14,247,4,0,
92,14,253,3,0,
92,14,259,4,0,
92,14,261,4,0,
92,14,263,4,0,
92,14,269,4,0,
92,14,271,3,0,
92,14,282,3,0,
92,14,285,3,0,
92,14,288,2,0,
92,14,289,3,0,
92,14,310,2,0,
92,14,371,1,26,
92,14,371,4,0,
92,14,373,4,0,
92,14,389,1,22,
92,14,399,1,36,
92,14,399,3,0,
92,14,412,4,0,
92,14,433,4,0,
92,14,472,3,0,
92,14,474,4,0,
92,14,477,4,0,
92,14,492,3,0,
92,14,496,4,0,
92,14,499,2,0,
92,14,506,1,43,
92,15,7,2,0,
92,15,8,2,0,
92,15,9,2,0,
92,15,50,2,0,
92,15,85,4,0,
92,15,92,4,0,
92,15,94,4,0,
92,15,95,1,1,1
92,15,101,1,15,
92,15,104,4,0,
92,15,109,1,19,
92,15,114,2,0,
92,15,122,1,1,2
92,15,123,2,0,
92,15,138,1,33,
92,15,138,4,0,
92,15,149,2,0,
92,15,153,4,0,
92,15,156,4,0,
92,15,164,4,0,
92,15,168,4,0,
92,15,171,1,47,
92,15,174,1,12,
92,15,180,1,5,
92,15,182,4,0,
92,15,184,2,0,
92,15,188,4,0,
92,15,194,1,40,
92,15,195,2,0,
92,15,207,4,0,
92,15,212,1,8,
92,15,213,4,0,
92,15,214,4,0,
92,15,216,4,0,
92,15,218,4,0,
92,15,237,4,0,
92,15,240,4,0,
92,15,241,4,0,
92,15,244,4,0,
92,15,247,1,29,
92,15,247,4,0,
92,15,259,4,0,
92,15,261,4,0,
92,15,263,4,0,
92,15,269,4,0,
92,15,288,2,0,
92,15,310,2,0,
92,15,371,1,26,
92,15,371,4,0,
92,15,373,4,0,
92,15,389,1,22,
92,15,399,1,36,
92,15,399,4,0,
92,15,412,4,0,
92,15,433,4,0,
92,15,474,4,0,
92,15,496,4,0,
92,15,499,2,0,
92,15,506,1,43,
92,15,513,2,0,
92,15,590,4,0,
92,15,605,4,0,
92,15,611,4,0,
92,16,7,2,0,
92,16,7,3,0,
92,16,8,2,0,
92,16,8,3,0,
92,16,9,2,0,
92,16,9,3,0,
92,16,50,2,0,
92,16,85,4,0,
92,16,92,4,0,
92,16,94,4,0,
92,16,95,1,1,1
92,16,101,1,15,1
92,16,104,4,0,
92,16,109,1,19,1
92,16,114,2,0,
92,16,122,1,1,2
92,16,123,2,0,
92,16,138,1,33,1
92,16,138,4,0,
92,16,149,2,0,
92,16,153,4,0,
92,16,156,4,0,
92,16,164,4,0,
92,16,168,4,0,
92,16,171,1,47,1
92,16,173,3,0,
92,16,174,1,12,1
92,16,180,1,5,1
92,16,180,3,0,
92,16,182,4,0,
92,16,184,2,0,
92,16,188,4,0,
92,16,194,1,40,1
92,16,195,2,0,
92,16,196,3,0,
92,16,202,3,0,
92,16,207,4,0,
92,16,212,1,8,1
92,16,213,4,0,
92,16,214,4,0,
92,16,216,4,0,
92,16,218,4,0,
92,16,220,3,0,
92,16,237,4,0,
92,16,240,4,0,
92,16,241,4,0,
92,16,244,4,0,
92,16,247,1,29,1
92,16,247,4,0,
92,16,253,3,0,
92,16,259,4,0,
92,16,261,4,0,
92,16,263,4,0,
92,16,269,4,0,
92,16,271,3,0,
92,16,282,3,0,
92,16,285,3,0,
92,16,288,2,0,
92,16,289,3,0,
92,16,290,4,0,
92,16,310,2,0,
92,16,371,1,26,1
92,16,371,4,0,
92,16,373,4,0,
92,16,389,1,22,1
92,16,399,1,36,1
92,16,399,4,0,
92,16,412,4,0,
92,16,433,4,0,
92,16,472,3,0,
92,16,474,4,0,
92,16,492,3,0,
92,16,496,4,0,
92,16,499,2,0,
92,16,506,1,43,1
92,16,513,2,0,
92,16,590,4,0,
92,16,605,4,0,
92,16,611,4,0,
92,17,7,2,0,
92,17,8,2,0,
92,17,9,2,0,
92,17,50,2,0,
92,17,85,4,0,
92,17,92,4,0,
92,17,94,4,0,
92,17,95,1,1,1
92,17,101,1,15,
92,17,104,4,0,
92,17,109,1,19,
92,17,114,2,0,
92,17,122,1,1,2
92,17,123,2,0,
92,17,138,1,33,
92,17,138,4,0,
92,17,149,2,0,
92,17,153,4,0,
92,17,156,4,0,
92,17,164,4,0,
92,17,168,4,0,
92,17,171,1,47,
92,17,174,1,12,
92,17,180,1,5,
92,17,182,4,0,
92,17,184,2,0,
92,17,188,4,0,
92,17,194,1,40,
92,17,195,2,0,
92,17,207,4,0,
92,17,212,1,8,
92,17,213,4,0,
92,17,214,4,0,
92,17,216,4,0,
92,17,218,4,0,
92,17,237,4,0,
92,17,240,4,0,
92,17,241,4,0,
92,17,244,4,0,
92,17,247,1,29,
92,17,247,4,0,
92,17,259,4,0,
92,17,261,4,0,
92,17,263,4,0,
92,17,269,4,0,
92,17,288,2,0,
92,17,310,2,0,
92,17,371,1,26,
92,17,371,4,0,
92,17,373,4,0,
92,17,389,1,22,
92,17,399,1,36,
92,17,399,4,0,
92,17,412,4,0,
92,17,433,4,0,
92,17,474,4,0,
92,17,496,4,0,
92,17,499,2,0,
92,17,506,1,43,
92,17,513,2,0,
92,17,590,4,0,
92,17,605,4,0,
92,17,611,4,0,
92,18,7,2,0,
92,18,8,2,0,
92,18,9,2,0,
92,18,50,2,0,
92,18,85,4,0,
92,18,92,4,0,
92,18,94,4,0,
92,18,95,1,1,1
92,18,101,1,15,
92,18,104,4,0,
92,18,109,1,19,
92,18,114,2,0,
92,18,122,1,1,2
92,18,123,2,0,
92,18,138,1,33,
92,18,138,4,0,
92,18,149,2,0,
92,18,153,4,0,
92,18,156,4,0,
92,18,164,4,0,
92,18,168,4,0,
92,18,171,1,47,
92,18,174,1,12,
92,18,180,1,5,
92,18,182,4,0,
92,18,184,2,0,
92,18,188,4,0,
92,18,194,1,40,
92,18,195,2,0,
92,18,207,4,0,
92,18,212,1,8,
92,18,213,4,0,
92,18,214,4,0,
92,18,216,4,0,
92,18,218,4,0,
92,18,237,4,0,
92,18,240,4,0,
92,18,241,4,0,
92,18,244,4,0,
92,18,247,1,29,
92,18,247,4,0,
92,18,259,4,0,
92,18,261,4,0,
92,18,263,4,0,
92,18,269,4,0,
92,18,288,2,0,
92,18,310,2,0,
92,18,371,1,26,
92,18,371,4,0,
92,18,373,4,0,
92,18,389,1,22,
92,18,399,1,36,
92,18,399,4,0,
92,18,412,4,0,
92,18,433,4,0,
92,18,474,4,0,
92,18,496,4,0,
92,18,499,2,0,
92,18,506,1,43,
92,18,513,2,0,
92,18,590,4,0,
92,18,605,4,0,
92,18,611,4,0,
93,1,72,4,0,
93,1,85,4,0,
93,1,87,4,0,
93,1,92,4,0,
93,1,94,4,0,
93,1,95,1,29,
93,1,99,4,0,
93,1,101,1,1,3
93,1,102,4,0,
93,1,104,4,0,
93,1,109,1,1,2
93,1,117,4,0,
93,1,120,4,0,
93,1,122,1,1,1
93,1,138,1,38,
93,1,138,4,0,
93,1,149,4,0,
93,1,153,4,0,
93,1,156,4,0,
93,1,164,4,0,
93,2,72,4,0,
93,2,85,4,0,
93,2,87,4,0,
93,2,92,4,0,
93,2,94,4,0,
93,2,95,1,29,
93,2,99,4,0,
93,2,101,1,1,3
93,2,102,4,0,
93,2,104,4,0,
93,2,109,1,1,2
93,2,117,4,0,
93,2,120,4,0,
93,2,122,1,1,1
93,2,138,1,38,
93,2,138,4,0,
93,2,149,4,0,
93,2,153,4,0,
93,2,156,4,0,
93,2,164,4,0,
93,3,87,4,0,
93,3,92,4,0,
93,3,94,4,0,
93,3,95,1,1,1
93,3,101,1,21,
93,3,104,4,0,
93,3,109,1,31,
93,3,122,1,1,2
93,3,138,1,39,
93,3,138,4,0,
93,3,156,4,0,
93,3,168,4,0,
93,3,171,4,0,
93,3,173,4,0,
93,3,174,1,16,
93,3,174,4,0,
93,3,180,1,1,3
93,3,180,1,8,
93,3,182,4,0,
93,3,192,4,0,
93,3,194,1,48,
93,3,202,4,0,
93,3,203,4,0,
93,3,207,4,0,
93,3,212,1,13,
93,3,213,4,0,
93,3,214,4,0,
93,3,216,4,0,
93,3,218,4,0,
93,3,237,4,0,
93,3,240,4,0,
93,3,241,4,0,
93,3,244,4,0,
93,3,247,4,0,
93,4,85,3,0,
93,4,87,4,0,
93,4,92,4,0,
93,4,94,4,0,
93,4,95,1,1,1
93,4,101,1,21,
93,4,104,4,0,
93,4,109,1,31,
93,4,122,1,1,2
93,4,138,1,39,
93,4,138,4,0,
93,4,156,4,0,
93,4,168,4,0,
93,4,171,4,0,
93,4,173,4,0,
93,4,174,1,16,
93,4,174,4,0,
93,4,180,1,1,3
93,4,180,1,8,
93,4,182,4,0,
93,4,192,4,0,
93,4,194,1,48,
93,4,202,4,0,
93,4,203,4,0,
93,4,207,4,0,
93,4,212,1,13,
93,4,213,4,0,
93,4,214,4,0,
93,4,216,4,0,
93,4,218,4,0,
93,4,237,4,0,
93,4,240,4,0,
93,4,241,4,0,
93,4,244,4,0,
93,4,247,4,0,
93,5,85,4,0,
93,5,92,4,0,
93,5,94,4,0,
93,5,95,1,1,1
93,5,101,1,21,
93,5,104,4,0,
93,5,109,1,31,
93,5,122,1,1,2
93,5,138,1,39,
93,5,156,4,0,
93,5,168,4,0,
93,5,174,1,16,
93,5,180,1,1,3
93,5,180,1,8,
93,5,182,4,0,
93,5,188,4,0,
93,5,194,1,48,
93,5,202,4,0,
93,5,212,1,13,
93,5,213,4,0,
93,5,216,4,0,
93,5,218,4,0,
93,5,237,4,0,
93,5,240,4,0,
93,5,241,4,0,
93,5,247,4,0,
93,5,259,4,0,
93,5,263,4,0,
93,5,269,4,0,
93,5,285,4,0,
93,5,289,4,0,
93,5,290,4,0,
93,5,325,1,25,
93,6,85,4,0,
93,6,92,4,0,
93,6,94,4,0,
93,6,95,1,1,1
93,6,101,1,21,
93,6,102,3,0,
93,6,104,4,0,
93,6,109,1,31,
93,6,122,1,1,2
93,6,138,1,39,
93,6,138,3,0,
93,6,153,3,0,
93,6,156,4,0,
93,6,164,3,0,
93,6,168,4,0,
93,6,173,3,0,
93,6,174,1,16,
93,6,180,1,1,3
93,6,180,1,8,
93,6,182,4,0,
93,6,188,4,0,
93,6,194,1,48,
93,6,202,4,0,
93,6,203,3,0,
93,6,207,3,0,
93,6,212,1,13,
93,6,213,4,0,
93,6,214,3,0,
93,6,216,4,0,
93,6,218,4,0,
93,6,237,4,0,
93,6,240,4,0,
93,6,241,4,0,
93,6,244,3,0,
93,6,247,4,0,
93,6,259,4,0,
93,6,263,4,0,
93,6,269,4,0,
93,6,285,4,0,
93,6,289,4,0,
93,6,290,4,0,
93,6,325,1,25,
93,7,85,4,0,
93,7,92,4,0,
93,7,94,4,0,
93,7,95,1,1,1
93,7,101,1,16,
93,7,102,3,0,
93,7,104,4,0,
93,7,109,1,21,
93,7,122,1,1,2
93,7,138,1,31,
93,7,138,3,0,
93,7,153,3,0,
93,7,156,4,0,
93,7,164,3,0,
93,7,168,4,0,
93,7,171,1,53,
93,7,174,1,13,
93,7,180,1,1,3
93,7,180,1,8,
93,7,182,4,0,
93,7,188,4,0,
93,7,194,1,39,
93,7,202,4,0,
93,7,212,1,64,
93,7,213,4,0,
93,7,216,4,0,
93,7,218,4,0,
93,7,237,4,0,
93,7,240,4,0,
93,7,241,4,0,
93,7,247,1,45,
93,7,247,4,0,
93,7,259,4,0,
93,7,263,4,0,
93,7,269,4,0,
93,7,285,4,0,
93,7,289,4,0,
93,7,290,4,0,
93,7,325,1,25,
93,8,85,4,0,
93,8,92,4,0,
93,8,94,4,0,
93,8,95,1,1,1
93,8,101,1,15,
93,8,104,4,0,
93,8,109,1,19,
93,8,122,1,1,2
93,8,138,1,39,
93,8,138,4,0,
93,8,153,4,0,
93,8,156,4,0,
93,8,164,4,0,
93,8,168,4,0,
93,8,171,1,55,
93,8,174,1,12,
93,8,180,1,1,3
93,8,180,1,5,
93,8,182,4,0,
93,8,188,4,0,
93,8,194,1,50,
93,8,202,4,0,
93,8,203,4,0,
93,8,207,4,0,
93,8,212,1,8,
93,8,213,4,0,
93,8,214,4,0,
93,8,216,4,0,
93,8,218,4,0,
93,8,237,4,0,
93,8,240,4,0,
93,8,241,4,0,
93,8,244,4,0,
93,8,247,1,33,
93,8,247,4,0,
93,8,259,4,0,
93,8,261,4,0,
93,8,263,4,0,
93,8,269,4,0,
93,8,285,4,0,
93,8,289,4,0,
93,8,290,4,0,
93,8,325,1,25,
93,8,363,4,0,
93,8,371,1,28,
93,8,371,4,0,
93,8,373,4,0,
93,8,374,4,0,
93,8,389,1,22,
93,8,398,4,0,
93,8,399,1,44,
93,8,399,4,0,
93,8,412,4,0,
93,8,421,4,0,
93,8,433,4,0,
93,8,445,4,0,
93,9,7,3,0,
93,9,8,3,0,
93,9,9,3,0,
93,9,85,4,0,
93,9,92,4,0,
93,9,94,4,0,
93,9,95,1,1,1
93,9,101,1,15,
93,9,104,4,0,
93,9,109,1,19,
93,9,122,1,1,2
93,9,138,1,39,
93,9,138,4,0,
93,9,153,4,0,
93,9,156,4,0,
93,9,164,4,0,
93,9,168,4,0,
93,9,171,1,55,
93,9,173,3,0,
93,9,174,1,12,
93,9,180,1,1,3
93,9,180,1,5,
93,9,180,3,0,
93,9,182,4,0,
93,9,188,4,0,
93,9,194,1,50,
93,9,196,3,0,
93,9,202,4,0,
93,9,203,4,0,
93,9,207,4,0,
93,9,212,1,8,
93,9,213,4,0,
93,9,214,4,0,
93,9,216,4,0,
93,9,218,4,0,
93,9,237,4,0,
93,9,240,4,0,
93,9,241,4,0,
93,9,244,4,0,
93,9,247,1,33,
93,9,247,4,0,
93,9,253,3,0,
93,9,259,4,0,
93,9,261,4,0,
93,9,263,4,0,
93,9,269,4,0,
93,9,271,3,0,
93,9,282,3,0,
93,9,285,4,0,
93,9,289,4,0,
93,9,290,4,0,
93,9,325,1,25,
93,9,363,4,0,
93,9,371,1,28,
93,9,371,4,0,
93,9,373,4,0,
93,9,374,4,0,
93,9,389,1,22,
93,9,389,3,0,
93,9,398,4,0,
93,9,399,1,44,
93,9,399,4,0,
93,9,412,4,0,
93,9,421,4,0,
93,9,433,4,0,
93,9,445,4,0,
93,9,466,3,0,
93,10,7,3,0,
93,10,8,3,0,
93,10,9,3,0,
93,10,85,4,0,
93,10,92,4,0,
93,10,94,4,0,
93,10,95,1,1,1
93,10,101,1,15,
93,10,104,4,0,
93,10,109,1,19,
93,10,122,1,1,2
93,10,138,1,39,
93,10,138,4,0,
93,10,153,4,0,
93,10,156,4,0,
93,10,164,4,0,
93,10,168,4,0,
93,10,171,1,55,
93,10,173,3,0,
93,10,174,1,12,
93,10,180,1,1,3
93,10,180,1,5,
93,10,180,3,0,
93,10,182,4,0,
93,10,188,4,0,
93,10,194,1,50,
93,10,196,3,0,
93,10,202,4,0,
93,10,203,4,0,
93,10,207,4,0,
93,10,212,1,8,
93,10,213,4,0,
93,10,214,4,0,
93,10,216,4,0,
93,10,218,4,0,
93,10,220,3,0,
93,10,237,4,0,
93,10,240,4,0,
93,10,241,4,0,
93,10,244,4,0,
93,10,247,1,33,
93,10,247,4,0,
93,10,253,3,0,
93,10,259,4,0,
93,10,261,4,0,
93,10,263,4,0,
93,10,269,4,0,
93,10,271,3,0,
93,10,282,3,0,
93,10,285,4,0,
93,10,289,4,0,
93,10,290,4,0,
93,10,325,1,25,
93,10,363,4,0,
93,10,371,1,28,
93,10,371,4,0,
93,10,373,4,0,
93,10,374,4,0,
93,10,389,1,22,
93,10,389,3,0,
93,10,398,4,0,
93,10,399,1,44,
93,10,399,4,0,
93,10,412,4,0,
93,10,421,4,0,
93,10,433,4,0,
93,10,445,4,0,
93,10,466,3,0,
93,11,85,4,0,
93,11,92,4,0,
93,11,94,4,0,
93,11,95,1,1,1
93,11,101,1,15,
93,11,104,4,0,
93,11,109,1,19,
93,11,122,1,1,2
93,11,138,1,39,
93,11,138,4,0,
93,11,153,4,0,
93,11,156,4,0,
93,11,164,4,0,
93,11,168,4,0,
93,11,171,1,61,
93,11,174,1,12,
93,11,180,1,1,3
93,11,180,1,5,
93,11,182,4,0,
93,11,188,4,0,
93,11,194,1,50,
93,11,207,4,0,
93,11,212,1,8,
93,11,213,4,0,
93,11,216,4,0,
93,11,218,4,0,
93,11,237,4,0,
93,11,240,4,0,
93,11,241,4,0,
93,11,244,4,0,
93,11,247,1,33,
93,11,247,4,0,
93,11,259,4,0,
93,11,261,4,0,
93,11,263,4,0,
93,11,269,4,0,
93,11,325,1,25,
93,11,371,1,28,
93,11,371,4,0,
93,11,373,4,0,
93,11,374,4,0,
93,11,389,1,22,
93,11,398,4,0,
93,11,399,1,44,
93,11,412,4,0,
93,11,421,4,0,
93,11,433,4,0,
93,11,474,4,0,
93,11,477,4,0,
93,11,496,4,0,
93,11,506,1,55,
93,12,85,4,0,
93,12,92,4,0,
93,12,94,4,0,
93,12,95,1,1,1
93,12,101,1,21,
93,12,104,4,0,
93,12,109,1,31,
93,12,122,1,1,2
93,12,138,1,39,
93,12,156,4,0,
93,12,168,4,0,
93,12,174,1,16,
93,12,180,1,1,3
93,12,180,1,8,
93,12,182,4,0,
93,12,188,4,0,
93,12,194,1,48,
93,12,202,4,0,
93,12,212,1,13,
93,12,213,4,0,
93,12,216,4,0,
93,12,218,4,0,
93,12,237,4,0,
93,12,240,4,0,
93,12,241,4,0,
93,12,247,4,0,
93,12,259,4,0,
93,12,263,4,0,
93,12,269,4,0,
93,12,285,4,0,
93,12,289,4,0,
93,12,290,4,0,
93,12,325,1,25,
93,13,85,4,0,
93,13,92,4,0,
93,13,94,4,0,
93,13,95,1,1,1
93,13,101,1,21,
93,13,102,3,0,
93,13,104,4,0,
93,13,109,1,31,
93,13,120,3,0,
93,13,122,1,1,2
93,13,138,1,39,
93,13,138,3,0,
93,13,156,4,0,
93,13,164,3,0,
93,13,168,4,0,
93,13,171,3,0,
93,13,174,1,16,
93,13,180,1,1,3
93,13,180,1,8,
93,13,182,4,0,
93,13,188,4,0,
93,13,194,1,48,
93,13,202,4,0,
93,13,207,3,0,
93,13,212,1,13,
93,13,213,4,0,
93,13,216,4,0,
93,13,218,4,0,
93,13,237,4,0,
93,13,240,4,0,
93,13,241,4,0,
93,13,247,4,0,
93,13,259,4,0,
93,13,263,4,0,
93,13,269,4,0,
93,13,285,4,0,
93,13,289,4,0,
93,13,290,4,0,
93,13,325,1,25,
93,14,7,3,0,
93,14,8,3,0,
93,14,9,3,0,
93,14,85,4,0,
93,14,92,4,0,
93,14,94,4,0,
93,14,95,1,1,1
93,14,101,1,15,
93,14,104,4,0,
93,14,109,1,19,
93,14,122,1,1,2
93,14,138,1,39,
93,14,138,4,0,
93,14,153,4,0,
93,14,156,4,0,
93,14,164,4,0,
93,14,168,4,0,
93,14,171,1,61,
93,14,173,3,0,
93,14,174,1,12,
93,14,180,1,1,3
93,14,180,1,5,
93,14,180,3,0,
93,14,182,4,0,
93,14,188,4,0,
93,14,194,1,50,
93,14,196,3,0,
93,14,202,3,0,
93,14,207,4,0,
93,14,212,1,8,
93,14,213,4,0,
93,14,214,3,0,
93,14,216,4,0,
93,14,218,4,0,
93,14,220,3,0,
93,14,237,4,0,
93,14,240,4,0,
93,14,241,4,0,
93,14,244,4,0,
93,14,247,1,33,
93,14,247,4,0,
93,14,253,3,0,
93,14,259,4,0,
93,14,261,4,0,
93,14,263,4,0,
93,14,269,4,0,
93,14,271,3,0,
93,14,282,3,0,
93,14,285,3,0,
93,14,289,3,0,
93,14,325,1,25,
93,14,371,1,28,
93,14,371,4,0,
93,14,373,4,0,
93,14,374,4,0,
93,14,389,1,22,
93,14,398,4,0,
93,14,399,1,44,
93,14,399,3,0,
93,14,412,4,0,
93,14,421,4,0,
93,14,433,4,0,
93,14,472,3,0,
93,14,474,4,0,
93,14,477,4,0,
93,14,492,3,0,
93,14,496,4,0,
93,14,506,1,55,
93,15,85,4,0,
93,15,92,4,0,
93,15,94,4,0,
93,15,95,1,1,1
93,15,101,1,15,
93,15,104,4,0,
93,15,109,1,19,
93,15,122,1,1,2
93,15,138,1,39,
93,15,138,4,0,
93,15,153,4,0,
93,15,156,4,0,
93,15,164,4,0,
93,15,168,4,0,
93,15,171,1,61,
93,15,174,1,12,
93,15,180,1,1,3
93,15,180,1,5,
93,15,182,4,0,
93,15,188,4,0,
93,15,194,1,50,
93,15,207,4,0,
93,15,212,1,8,
93,15,213,4,0,
93,15,214,4,0,
93,15,216,4,0,
93,15,218,4,0,
93,15,237,4,0,
93,15,240,4,0,
93,15,241,4,0,
93,15,244,4,0,
93,15,247,1,33,
93,15,247,4,0,
93,15,259,4,0,
93,15,261,4,0,
93,15,263,4,0,
93,15,269,4,0,
93,15,325,1,25,
93,15,371,1,28,
93,15,371,4,0,
93,15,373,4,0,
93,15,374,4,0,
93,15,389,1,22,
93,15,398,4,0,
93,15,399,1,44,
93,15,399,4,0,
93,15,412,4,0,
93,15,421,4,0,
93,15,433,4,0,
93,15,474,4,0,
93,15,496,4,0,
93,15,506,1,55,
93,15,590,4,0,
93,15,605,4,0,
93,15,611,4,0,
93,16,7,3,0,
93,16,8,3,0,
93,16,9,3,0,
93,16,85,4,0,
93,16,92,4,0,
93,16,94,4,0,
93,16,95,1,1,1
93,16,101,1,15,1
93,16,104,4,0,
93,16,109,1,19,1
93,16,122,1,1,2
93,16,138,1,39,1
93,16,138,4,0,
93,16,153,4,0,
93,16,156,4,0,
93,16,164,4,0,
93,16,168,4,0,
93,16,171,1,61,1
93,16,173,3,0,
93,16,174,1,12,1
93,16,180,1,1,3
93,16,180,1,5,1
93,16,180,3,0,
93,16,182,4,0,
93,16,188,4,0,
93,16,194,1,50,1
93,16,196,3,0,
93,16,202,3,0,
93,16,207,4,0,
93,16,212,1,8,1
93,16,213,4,0,
93,16,214,4,0,
93,16,216,4,0,
93,16,218,4,0,
93,16,220,3,0,
93,16,237,4,0,
93,16,240,4,0,
93,16,241,4,0,
93,16,244,4,0,
93,16,247,1,33,1
93,16,247,4,0,
93,16,253,3,0,
93,16,259,4,0,
93,16,261,4,0,
93,16,263,4,0,
93,16,269,4,0,
93,16,271,3,0,
93,16,282,3,0,
93,16,285,3,0,
93,16,289,3,0,
93,16,290,4,0,
93,16,325,1,25,1
93,16,371,1,28,1
93,16,371,4,0,
93,16,373,4,0,
93,16,374,4,0,
93,16,389,1,22,1
93,16,398,4,0,
93,16,399,1,44,1
93,16,399,4,0,
93,16,412,4,0,
93,16,421,4,0,
93,16,433,4,0,
93,16,472,3,0,
93,16,474,4,0,
93,16,492,3,0,
93,16,496,4,0,
93,16,506,1,55,1
93,16,590,4,0,
93,16,605,4,0,
93,16,611,4,0,
93,17,85,4,0,
93,17,92,4,0,
93,17,94,4,0,
93,17,95,1,1,2
93,17,101,1,15,
93,17,104,4,0,
93,17,109,1,19,
93,17,122,1,1,3
93,17,138,1,39,
93,17,138,4,0,
93,17,153,4,0,
93,17,156,4,0,
93,17,164,4,0,
93,17,168,4,0,
93,17,171,1,61,
93,17,174,1,12,
93,17,180,1,1,4
93,17,180,1,5,
93,17,182,4,0,
93,17,188,4,0,
93,17,194,1,50,
93,17,207,4,0,
93,17,212,1,8,
93,17,213,4,0,
93,17,214,4,0,
93,17,216,4,0,
93,17,218,4,0,
93,17,237,4,0,
93,17,240,4,0,
93,17,241,4,0,
93,17,244,4,0,
93,17,247,1,33,
93,17,247,4,0,
93,17,259,4,0,
93,17,261,4,0,
93,17,263,4,0,
93,17,269,4,0,
93,17,325,1,0,
93,17,325,1,1,1
93,17,371,1,28,
93,17,371,4,0,
93,17,373,4,0,
93,17,374,4,0,
93,17,389,1,22,
93,17,398,4,0,
93,17,399,1,44,
93,17,399,4,0,
93,17,412,4,0,
93,17,421,4,0,
93,17,433,4,0,
93,17,474,4,0,
93,17,496,4,0,
93,17,506,1,55,
93,17,590,4,0,
93,17,605,4,0,
93,17,611,4,0,
93,18,85,4,0,
93,18,92,4,0,
93,18,94,4,0,
93,18,95,1,1,2
93,18,101,1,15,
93,18,104,4,0,
93,18,109,1,19,
93,18,122,1,1,3
93,18,138,1,39,
93,18,138,4,0,
93,18,153,4,0,
93,18,156,4,0,
93,18,164,4,0,
93,18,168,4,0,
93,18,171,1,61,
93,18,174,1,12,
93,18,180,1,1,4
93,18,180,1,5,
93,18,182,4,0,
93,18,188,4,0,
93,18,194,1,50,
93,18,207,4,0,
93,18,212,1,8,
93,18,213,4,0,
93,18,214,4,0,
93,18,216,4,0,
93,18,218,4,0,
93,18,237,4,0,
93,18,240,4,0,
93,18,241,4,0,
93,18,244,4,0,
93,18,247,1,33,
93,18,247,4,0,
93,18,259,4,0,
93,18,261,4,0,
93,18,263,4,0,
93,18,269,4,0,
93,18,325,1,0,
93,18,325,1,1,1
93,18,371,1,28,
93,18,371,4,0,
93,18,373,4,0,
93,18,374,4,0,
93,18,389,1,22,
93,18,398,4,0,
93,18,399,1,44,
93,18,399,4,0,
93,18,412,4,0,
93,18,421,4,0,
93,18,433,4,0,
93,18,474,4,0,
93,18,496,4,0,
93,18,506,1,55,
93,18,590,4,0,
93,18,605,4,0,
93,18,611,4,0,
94,1,5,4,0,
94,1,25,4,0,
94,1,34,4,0,
94,1,36,4,0,
94,1,38,4,0,
94,1,63,4,0,
94,1,66,4,0,
94,1,68,4,0,
94,1,69,4,0,
94,1,70,4,0,
94,1,72,4,0,
94,1,85,4,0,
94,1,87,4,0,
94,1,92,4,0,
94,1,94,4,0,
94,1,95,1,29,
94,1,99,4,0,
94,1,101,1,1,3
94,1,102,4,0,
94,1,104,4,0,
94,1,109,1,1,2
94,1,117,4,0,
94,1,118,4,0,
94,1,120,4,0,
94,1,122,1,1,1
94,1,130,4,0,
94,1,138,1,38,
94,1,138,4,0,
94,1,149,4,0,
94,1,153,4,0,
94,1,156,4,0,
94,1,164,4,0,
94,2,5,4,0,
94,2,25,4,0,
94,2,34,4,0,
94,2,36,4,0,
94,2,38,4,0,
94,2,63,4,0,
94,2,66,4,0,
94,2,68,4,0,
94,2,69,4,0,
94,2,70,4,0,
94,2,72,4,0,
94,2,85,4,0,
94,2,87,4,0,
94,2,92,4,0,
94,2,94,4,0,
94,2,95,1,29,
94,2,99,4,0,
94,2,101,1,1,3
94,2,102,4,0,
94,2,104,4,0,
94,2,109,1,1,2
94,2,117,4,0,
94,2,118,4,0,
94,2,120,4,0,
94,2,122,1,1,1
94,2,130,4,0,
94,2,138,1,38,
94,2,138,4,0,
94,2,149,4,0,
94,2,153,4,0,
94,2,156,4,0,
94,2,164,4,0,
94,3,7,4,0,
94,3,8,4,0,
94,3,9,4,0,
94,3,29,4,0,
94,3,63,4,0,
94,3,70,4,0,
94,3,87,4,0,
94,3,92,4,0,
94,3,94,4,0,
94,3,95,1,1,1
94,3,101,1,21,
94,3,104,4,0,
94,3,109,1,31,
94,3,122,1,1,2
94,3,138,1,39,
94,3,138,4,0,
94,3,156,4,0,
94,3,168,4,0,
94,3,171,4,0,
94,3,173,4,0,
94,3,174,1,16,
94,3,174,4,0,
94,3,180,1,1,3
94,3,180,1,8,
94,3,182,4,0,
94,3,192,4,0,
94,3,194,1,48,
94,3,202,4,0,
94,3,203,4,0,
94,3,207,4,0,
94,3,212,1,13,
94,3,213,4,0,
94,3,214,4,0,
94,3,216,4,0,
94,3,218,4,0,
94,3,223,4,0,
94,3,237,4,0,
94,3,240,4,0,
94,3,241,4,0,
94,3,244,4,0,
94,3,247,4,0,
94,3,249,4,0,
94,4,7,4,0,
94,4,8,4,0,
94,4,9,4,0,
94,4,29,4,0,
94,4,63,4,0,
94,4,70,4,0,
94,4,85,3,0,
94,4,87,4,0,
94,4,92,4,0,
94,4,94,4,0,
94,4,95,1,1,1
94,4,101,1,21,
94,4,104,4,0,
94,4,109,1,31,
94,4,122,1,1,2
94,4,138,1,39,
94,4,138,4,0,
94,4,156,4,0,
94,4,168,4,0,
94,4,171,4,0,
94,4,173,4,0,
94,4,174,1,16,
94,4,174,4,0,
94,4,180,1,1,3
94,4,180,1,8,
94,4,182,4,0,
94,4,192,4,0,
94,4,194,1,48,
94,4,202,4,0,
94,4,203,4,0,
94,4,207,4,0,
94,4,212,1,13,
94,4,213,4,0,
94,4,214,4,0,
94,4,216,4,0,
94,4,218,4,0,
94,4,223,4,0,
94,4,237,4,0,
94,4,240,4,0,
94,4,241,4,0,
94,4,244,4,0,
94,4,247,4,0,
94,4,249,4,0,
94,5,63,4,0,
94,5,70,4,0,
94,5,85,4,0,
94,5,87,4,0,
94,5,92,4,0,
94,5,94,4,0,
94,5,95,1,1,1
94,5,101,1,21,
94,5,104,4,0,
94,5,109,1,31,
94,5,122,1,1,2
94,5,138,1,39,
94,5,156,4,0,
94,5,168,4,0,
94,5,174,1,16,
94,5,180,1,1,3
94,5,180,1,8,
94,5,182,4,0,
94,5,188,4,0,
94,5,194,1,48,
94,5,202,4,0,
94,5,212,1,13,
94,5,213,4,0,
94,5,216,4,0,
94,5,218,4,0,
94,5,237,4,0,
94,5,240,4,0,
94,5,241,4,0,
94,5,247,4,0,
94,5,249,4,0,
94,5,259,4,0,
94,5,263,4,0,
94,5,264,4,0,
94,5,269,4,0,
94,5,280,4,0,
94,5,285,4,0,
94,5,289,4,0,
94,5,290,4,0,
94,5,325,1,25,
94,6,5,3,0,
94,6,7,3,0,
94,6,8,3,0,
94,6,9,3,0,
94,6,25,3,0,
94,6,34,3,0,
94,6,38,3,0,
94,6,63,4,0,
94,6,68,3,0,
94,6,69,3,0,
94,6,70,4,0,
94,6,85,4,0,
94,6,87,4,0,
94,6,92,4,0,
94,6,94,4,0,
94,6,95,1,1,1
94,6,101,1,21,
94,6,102,3,0,
94,6,104,4,0,
94,6,109,1,31,
94,6,118,3,0,
94,6,122,1,1,2
94,6,138,1,39,
94,6,138,3,0,
94,6,153,3,0,
94,6,156,4,0,
94,6,164,3,0,
94,6,168,4,0,
94,6,173,3,0,
94,6,174,1,16,
94,6,180,1,1,3
94,6,180,1,8,
94,6,182,4,0,
94,6,188,4,0,
94,6,194,1,48,
94,6,202,4,0,
94,6,203,3,0,
94,6,207,3,0,
94,6,212,1,13,
94,6,213,4,0,
94,6,214,3,0,
94,6,216,4,0,
94,6,218,4,0,
94,6,223,3,0,
94,6,237,4,0,
94,6,240,4,0,
94,6,241,4,0,
94,6,244,3,0,
94,6,247,4,0,
94,6,249,4,0,
94,6,259,4,0,
94,6,263,4,0,
94,6,264,4,0,
94,6,269,4,0,
94,6,280,4,0,
94,6,285,4,0,
94,6,289,4,0,
94,6,290,4,0,
94,6,325,1,25,
94,7,5,3,0,
94,7,25,3,0,
94,7,34,3,0,
94,7,38,3,0,
94,7,63,4,0,
94,7,68,3,0,
94,7,69,3,0,
94,7,70,4,0,
94,7,85,4,0,
94,7,87,4,0,
94,7,92,4,0,
94,7,94,4,0,
94,7,95,1,1,1
94,7,101,1,16,
94,7,102,3,0,
94,7,104,4,0,
94,7,109,1,21,
94,7,118,3,0,
94,7,122,1,1,2
94,7,138,1,31,
94,7,138,3,0,
94,7,153,3,0,
94,7,156,4,0,
94,7,164,3,0,
94,7,168,4,0,
94,7,171,1,53,
94,7,174,1,13,
94,7,180,1,1,3
94,7,180,1,8,
94,7,182,4,0,
94,7,188,4,0,
94,7,194,1,39,
94,7,202,4,0,
94,7,212,1,64,
94,7,213,4,0,
94,7,216,4,0,
94,7,218,4,0,
94,7,237,4,0,
94,7,240,4,0,
94,7,241,4,0,
94,7,247,1,45,
94,7,247,4,0,
94,7,249,4,0,
94,7,259,4,0,
94,7,263,4,0,
94,7,264,4,0,
94,7,269,4,0,
94,7,280,4,0,
94,7,285,4,0,
94,7,289,4,0,
94,7,290,4,0,
94,7,325,1,25,
94,8,63,4,0,
94,8,70,4,0,
94,8,85,4,0,
94,8,87,4,0,
94,8,92,4,0,
94,8,94,4,0,
94,8,95,1,1,1
94,8,101,1,15,
94,8,104,4,0,
94,8,109,1,19,
94,8,122,1,1,2
94,8,138,1,39,
94,8,138,4,0,
94,8,153,4,0,
94,8,156,4,0,
94,8,164,4,0,
94,8,168,4,0,
94,8,171,1,55,
94,8,174,1,12,
94,8,180,1,1,3
94,8,180,1,5,
94,8,182,4,0,
94,8,188,4,0,
94,8,194,1,50,
94,8,202,4,0,
94,8,203,4,0,
94,8,207,4,0,
94,8,212,1,8,
94,8,213,4,0,
94,8,214,4,0,
94,8,216,4,0,
94,8,218,4,0,
94,8,237,4,0,
94,8,240,4,0,
94,8,241,4,0,
94,8,244,4,0,
94,8,247,1,33,
94,8,247,4,0,
94,8,249,4,0,
94,8,259,4,0,
94,8,261,4,0,
94,8,263,4,0,
94,8,264,4,0,
94,8,269,4,0,
94,8,280,4,0,
94,8,285,4,0,
94,8,289,4,0,
94,8,290,4,0,
94,8,325,1,25,
94,8,363,4,0,
94,8,371,1,28,
94,8,371,4,0,
94,8,373,4,0,
94,8,374,4,0,
94,8,389,1,22,
94,8,398,4,0,
94,8,399,1,44,
94,8,399,4,0,
94,8,409,4,0,
94,8,411,4,0,
94,8,412,4,0,
94,8,416,4,0,
94,8,421,4,0,
94,8,433,4,0,
94,8,445,4,0,
94,9,7,3,0,
94,9,8,3,0,
94,9,9,3,0,
94,9,63,4,0,
94,9,70,4,0,
94,9,85,4,0,
94,9,87,4,0,
94,9,92,4,0,
94,9,94,4,0,
94,9,95,1,1,1
94,9,101,1,15,
94,9,104,4,0,
94,9,109,1,19,
94,9,122,1,1,2
94,9,138,1,39,
94,9,138,4,0,
94,9,153,4,0,
94,9,156,4,0,
94,9,164,4,0,
94,9,168,4,0,
94,9,171,1,55,
94,9,173,3,0,
94,9,174,1,12,
94,9,180,1,1,3
94,9,180,1,5,
94,9,180,3,0,
94,9,182,4,0,
94,9,188,4,0,
94,9,194,1,50,
94,9,196,3,0,
94,9,202,4,0,
94,9,203,4,0,
94,9,207,4,0,
94,9,212,1,8,
94,9,213,4,0,
94,9,214,4,0,
94,9,216,4,0,
94,9,218,4,0,
94,9,237,4,0,
94,9,240,4,0,
94,9,241,4,0,
94,9,244,4,0,
94,9,247,1,33,
94,9,247,4,0,
94,9,249,4,0,
94,9,253,3,0,
94,9,259,4,0,
94,9,261,4,0,
94,9,263,4,0,
94,9,264,4,0,
94,9,269,4,0,
94,9,271,3,0,
94,9,280,4,0,
94,9,282,3,0,
94,9,285,4,0,
94,9,289,4,0,
94,9,290,4,0,
94,9,325,1,25,
94,9,363,4,0,
94,9,371,1,28,
94,9,371,4,0,
94,9,373,4,0,
94,9,374,4,0,
94,9,389,1,22,
94,9,389,3,0,
94,9,398,4,0,
94,9,399,1,44,
94,9,399,4,0,
94,9,409,4,0,
94,9,411,4,0,
94,9,412,4,0,
94,9,416,4,0,
94,9,421,4,0,
94,9,433,4,0,
94,9,445,4,0,
94,9,466,3,0,
94,10,7,3,0,
94,10,8,3,0,
94,10,9,3,0,
94,10,29,3,0,
94,10,63,4,0,
94,10,70,4,0,
94,10,85,4,0,
94,10,87,4,0,
94,10,92,4,0,
94,10,94,4,0,
94,10,95,1,1,1
94,10,101,1,15,
94,10,104,4,0,
94,10,109,1,19,
94,10,122,1,1,2
94,10,138,1,39,
94,10,138,4,0,
94,10,153,4,0,
94,10,156,4,0,
94,10,164,4,0,
94,10,168,4,0,
94,10,171,1,55,
94,10,173,3,0,
94,10,174,1,12,
94,10,180,1,1,3
94,10,180,1,5,
94,10,180,3,0,
94,10,182,4,0,
94,10,188,4,0,
94,10,194,1,50,
94,10,196,3,0,
94,10,202,4,0,
94,10,203,4,0,
94,10,207,4,0,
94,10,212,1,8,
94,10,213,4,0,
94,10,214,4,0,
94,10,216,4,0,
94,10,218,4,0,
94,10,220,3,0,
94,10,237,4,0,
94,10,240,4,0,
94,10,241,4,0,
94,10,244,4,0,
94,10,247,1,33,
94,10,247,4,0,
94,10,249,4,0,
94,10,253,3,0,
94,10,259,4,0,
94,10,261,4,0,
94,10,263,4,0,
94,10,264,4,0,
94,10,269,4,0,
94,10,271,3,0,
94,10,272,3,0,
94,10,280,4,0,
94,10,282,3,0,
94,10,285,4,0,
94,10,289,4,0,
94,10,290,4,0,
94,10,325,1,25,
94,10,363,4,0,
94,10,371,1,28,
94,10,371,4,0,
94,10,373,4,0,
94,10,374,4,0,
94,10,389,1,22,
94,10,389,3,0,
94,10,398,4,0,
94,10,399,1,44,
94,10,399,4,0,
94,10,409,4,0,
94,10,411,4,0,
94,10,412,4,0,
94,10,416,4,0,
94,10,421,4,0,
94,10,433,4,0,
94,10,445,4,0,
94,10,466,3,0,
94,11,63,4,0,
94,11,70,4,0,
94,11,85,4,0,
94,11,87,4,0,
94,11,92,4,0,
94,11,94,4,0,
94,11,95,1,1,1
94,11,101,1,15,
94,11,104,4,0,
94,11,109,1,19,
94,11,122,1,1,2
94,11,138,1,39,
94,11,138,4,0,
94,11,153,4,0,
94,11,156,4,0,
94,11,164,4,0,
94,11,168,4,0,
94,11,171,1,61,
94,11,174,1,12,
94,11,180,1,1,3
94,11,180,1,5,
94,11,182,4,0,
94,11,188,4,0,
94,11,194,1,50,
94,11,207,4,0,
94,11,212,1,8,
94,11,213,4,0,
94,11,216,4,0,
94,11,218,4,0,
94,11,237,4,0,
94,11,240,4,0,
94,11,241,4,0,
94,11,244,4,0,
94,11,247,1,33,
94,11,247,4,0,
94,11,249,4,0,
94,11,259,4,0,
94,11,261,4,0,
94,11,263,4,0,
94,11,269,4,0,
94,11,280,4,0,
94,11,325,1,25,
94,11,371,1,28,
94,11,371,4,0,
94,11,373,4,0,
94,11,374,4,0,
94,11,389,1,22,
94,11,398,4,0,
94,11,399,1,44,
94,11,411,4,0,
94,11,412,4,0,
94,11,416,4,0,
94,11,421,4,0,
94,11,433,4,0,
94,11,474,4,0,
94,11,477,4,0,
94,11,496,4,0,
94,11,506,1,55,
94,12,63,4,0,
94,12,70,4,0,
94,12,85,4,0,
94,12,87,4,0,
94,12,92,4,0,
94,12,94,4,0,
94,12,95,1,1,1
94,12,101,1,21,
94,12,104,4,0,
94,12,109,1,31,
94,12,122,1,1,2
94,12,138,1,39,
94,12,156,4,0,
94,12,168,4,0,
94,12,174,1,16,
94,12,180,1,1,3
94,12,180,1,8,
94,12,182,4,0,
94,12,188,4,0,
94,12,194,1,48,
94,12,202,4,0,
94,12,212,1,13,
94,12,213,4,0,
94,12,216,4,0,
94,12,218,4,0,
94,12,237,4,0,
94,12,240,4,0,
94,12,241,4,0,
94,12,247,4,0,
94,12,249,4,0,
94,12,259,4,0,
94,12,263,4,0,
94,12,264,4,0,
94,12,269,4,0,
94,12,280,4,0,
94,12,285,4,0,
94,12,289,4,0,
94,12,290,4,0,
94,12,325,1,25,
94,13,34,3,0,
94,13,38,3,0,
94,13,63,4,0,
94,13,69,3,0,
94,13,70,4,0,
94,13,85,4,0,
94,13,87,4,0,
94,13,92,4,0,
94,13,94,4,0,
94,13,95,1,1,1
94,13,101,1,21,
94,13,102,3,0,
94,13,104,4,0,
94,13,109,1,31,
94,13,120,3,0,
94,13,122,1,1,2
94,13,138,1,39,
94,13,138,3,0,
94,13,156,4,0,
94,13,164,3,0,
94,13,168,4,0,
94,13,171,3,0,
94,13,174,1,16,
94,13,180,1,1,3
94,13,180,1,8,
94,13,182,4,0,
94,13,188,4,0,
94,13,194,1,48,
94,13,202,4,0,
94,13,207,3,0,
94,13,212,1,13,
94,13,213,4,0,
94,13,216,4,0,
94,13,218,4,0,
94,13,237,4,0,
94,13,240,4,0,
94,13,241,4,0,
94,13,247,4,0,
94,13,249,4,0,
94,13,259,4,0,
94,13,263,4,0,
94,13,264,4,0,
94,13,269,4,0,
94,13,280,4,0,
94,13,285,4,0,
94,13,289,4,0,
94,13,290,4,0,
94,13,325,1,25,
94,14,7,3,0,
94,14,8,3,0,
94,14,9,3,0,
94,14,63,4,0,
94,14,70,4,0,
94,14,85,4,0,
94,14,87,4,0,
94,14,92,4,0,
94,14,94,4,0,
94,14,95,1,1,1
94,14,101,1,15,
94,14,104,4,0,
94,14,109,1,19,
94,14,122,1,1,2
94,14,138,1,39,
94,14,138,4,0,
94,14,153,4,0,
94,14,156,4,0,
94,14,164,4,0,
94,14,168,4,0,
94,14,171,1,61,
94,14,173,3,0,
94,14,174,1,12,
94,14,180,1,1,3
94,14,180,1,5,
94,14,180,3,0,
94,14,182,4,0,
94,14,188,4,0,
94,14,194,1,50,
94,14,196,3,0,
94,14,202,3,0,
94,14,207,4,0,
94,14,212,1,8,
94,14,213,4,0,
94,14,214,3,0,
94,14,216,4,0,
94,14,218,4,0,
94,14,220,3,0,
94,14,237,4,0,
94,14,240,4,0,
94,14,241,4,0,
94,14,244,4,0,
94,14,247,1,33,
94,14,247,4,0,
94,14,249,4,0,
94,14,253,3,0,
94,14,259,4,0,
94,14,261,4,0,
94,14,263,4,0,
94,14,269,4,0,
94,14,271,3,0,
94,14,272,3,0,
94,14,280,4,0,
94,14,282,3,0,
94,14,285,3,0,
94,14,289,3,0,
94,14,325,1,25,
94,14,371,1,28,
94,14,371,4,0,
94,14,373,4,0,
94,14,374,4,0,
94,14,389,1,22,
94,14,398,4,0,
94,14,399,1,44,
94,14,399,3,0,
94,14,409,3,0,
94,14,411,4,0,
94,14,412,4,0,
94,14,416,4,0,
94,14,421,4,0,
94,14,433,4,0,
94,14,472,3,0,
94,14,474,4,0,
94,14,477,4,0,
94,14,492,3,0,
94,14,496,4,0,
94,14,506,1,55,
94,15,63,4,0,
94,15,70,4,0,
94,15,85,4,0,
94,15,87,4,0,
94,15,92,4,0,
94,15,94,4,0,
94,15,95,1,1,1
94,15,101,1,15,
94,15,104,4,0,
94,15,109,1,19,
94,15,122,1,1,2
94,15,138,1,39,
94,15,138,4,0,
94,15,153,4,0,
94,15,156,4,0,
94,15,164,4,0,
94,15,168,4,0,
94,15,171,1,61,
94,15,174,1,12,
94,15,180,1,1,3
94,15,180,1,5,
94,15,182,4,0,
94,15,188,4,0,
94,15,194,1,50,
94,15,207,4,0,
94,15,212,1,8,
94,15,213,4,0,
94,15,214,4,0,
94,15,216,4,0,
94,15,218,4,0,
94,15,237,4,0,
94,15,240,4,0,
94,15,241,4,0,
94,15,244,4,0,
94,15,247,1,33,
94,15,247,4,0,
94,15,249,4,0,
94,15,259,4,0,
94,15,261,4,0,
94,15,263,4,0,
94,15,269,4,0,
94,15,280,4,0,
94,15,325,1,25,
94,15,371,1,28,
94,15,371,4,0,
94,15,373,4,0,
94,15,374,4,0,
94,15,389,1,22,
94,15,398,4,0,
94,15,399,1,44,
94,15,399,4,0,
94,15,411,4,0,
94,15,412,4,0,
94,15,416,4,0,
94,15,421,4,0,
94,15,433,4,0,
94,15,474,4,0,
94,15,496,4,0,
94,15,506,1,55,
94,15,590,4,0,
94,15,605,4,0,
94,15,611,4,0,
94,15,612,4,0,
94,16,7,3,0,
94,16,8,3,0,
94,16,9,3,0,
94,16,63,4,0,
94,16,70,4,0,
94,16,85,4,0,
94,16,87,4,0,
94,16,92,4,0,
94,16,94,4,0,
94,16,95,1,1,1
94,16,101,1,15,1
94,16,104,4,0,
94,16,109,1,19,1
94,16,122,1,1,2
94,16,138,1,39,1
94,16,138,4,0,
94,16,153,4,0,
94,16,156,4,0,
94,16,164,4,0,
94,16,168,4,0,
94,16,171,1,61,1
94,16,173,3,0,
94,16,174,1,12,1
94,16,180,1,1,3
94,16,180,1,5,1
94,16,180,3,0,
94,16,182,4,0,
94,16,188,4,0,
94,16,194,1,50,1
94,16,196,3,0,
94,16,202,3,0,
94,16,207,4,0,
94,16,212,1,8,1
94,16,213,4,0,
94,16,214,4,0,
94,16,216,4,0,
94,16,218,4,0,
94,16,220,3,0,
94,16,237,4,0,
94,16,240,4,0,
94,16,241,4,0,
94,16,244,4,0,
94,16,247,1,33,1
94,16,247,4,0,
94,16,249,4,0,
94,16,253,3,0,
94,16,259,4,0,
94,16,261,4,0,
94,16,263,4,0,
94,16,264,3,0,
94,16,269,4,0,
94,16,271,3,0,
94,16,272,3,0,
94,16,280,4,0,
94,16,282,3,0,
94,16,285,3,0,
94,16,289,3,0,
94,16,290,4,0,
94,16,325,1,25,1
94,16,371,1,28,1
94,16,371,4,0,
94,16,373,4,0,
94,16,374,4,0,
94,16,389,1,22,1
94,16,398,4,0,
94,16,399,1,44,1
94,16,399,4,0,
94,16,409,3,0,
94,16,411,4,0,
94,16,412,4,0,
94,16,416,4,0,
94,16,421,4,0,
94,16,433,4,0,
94,16,472,3,0,
94,16,474,4,0,
94,16,492,3,0,
94,16,496,4,0,
94,16,506,1,55,1
94,16,590,4,0,
94,16,605,4,0,
94,16,611,4,0,
94,16,612,4,0,
94,17,63,4,0,
94,17,85,4,0,
94,17,87,4,0,
94,17,92,4,0,
94,17,94,4,0,
94,17,95,1,1,2
94,17,101,1,15,
94,17,104,4,0,
94,17,109,1,19,
94,17,122,1,1,3
94,17,138,1,39,
94,17,138,4,0,
94,17,153,4,0,
94,17,156,4,0,
94,17,164,4,0,
94,17,168,4,0,
94,17,171,1,61,
94,17,174,1,12,
94,17,180,1,1,4
94,17,180,1,5,
94,17,182,4,0,
94,17,188,4,0,
94,17,194,1,50,
94,17,207,4,0,
94,17,212,1,8,
94,17,213,4,0,
94,17,214,4,0,
94,17,216,4,0,
94,17,218,4,0,
94,17,237,4,0,
94,17,240,4,0,
94,17,241,4,0,
94,17,244,4,0,
94,17,247,1,33,
94,17,247,4,0,
94,17,259,4,0,
94,17,261,4,0,
94,17,263,4,0,
94,17,269,4,0,
94,17,280,4,0,
94,17,325,1,0,
94,17,325,1,1,1
94,17,371,1,28,
94,17,371,4,0,
94,17,373,4,0,
94,17,374,4,0,
94,17,389,1,22,
94,17,398,4,0,
94,17,399,1,44,
94,17,399,4,0,
94,17,411,4,0,
94,17,412,4,0,
94,17,416,4,0,
94,17,421,4,0,
94,17,433,4,0,
94,17,474,4,0,
94,17,496,4,0,
94,17,506,1,55,
94,17,590,4,0,
94,17,605,4,0,
94,17,611,4,0,
94,18,63,4,0,
94,18,85,4,0,
94,18,87,4,0,
94,18,92,4,0,
94,18,94,4,0,
94,18,95,1,1,2
94,18,101,1,15,
94,18,104,4,0,
94,18,109,1,19,
94,18,122,1,1,3
94,18,138,1,39,
94,18,138,4,0,
94,18,153,4,0,
94,18,156,4,0,
94,18,164,4,0,
94,18,168,4,0,
94,18,171,1,61,
94,18,174,1,12,
94,18,180,1,1,4
94,18,180,1,5,
94,18,182,4,0,
94,18,188,4,0,
94,18,194,1,50,
94,18,207,4,0,
94,18,212,1,8,
94,18,213,4,0,
94,18,214,4,0,
94,18,216,4,0,
94,18,218,4,0,
94,18,237,4,0,
94,18,240,4,0,
94,18,241,4,0,
94,18,244,4,0,
94,18,247,1,33,
94,18,247,4,0,
94,18,259,4,0,
94,18,261,4,0,
94,18,263,4,0,
94,18,269,4,0,
94,18,280,4,0,
94,18,325,1,0,
94,18,325,1,1,1
94,18,371,1,28,
94,18,371,4,0,
94,18,373,4,0,
94,18,374,4,0,
94,18,389,1,22,
94,18,398,4,0,
94,18,399,1,44,
94,18,399,4,0,
94,18,411,4,0,
94,18,412,4,0,
94,18,416,4,0,
94,18,421,4,0,
94,18,433,4,0,
94,18,474,4,0,
94,18,496,4,0,
94,18,506,1,55,
94,18,590,4,0,
94,18,605,4,0,
94,18,611,4,0,
95,1,20,1,15,
95,1,21,1,33,
95,1,33,1,1,1
95,1,34,4,0,
95,1,36,4,0,
95,1,38,4,0,
95,1,70,4,0,
95,1,88,1,19,
95,1,89,4,0,
95,1,90,4,0,
95,1,91,4,0,
95,1,92,4,0,
95,1,99,1,25,
95,1,99,4,0,
95,1,102,4,0,
95,1,103,1,1,2
95,1,104,4,0,
95,1,106,1,43,
95,1,117,4,0,
95,1,120,4,0,
95,1,130,4,0,
95,1,153,4,0,
95,1,156,4,0,
95,1,157,4,0,
95,1,164,4,0,
95,2,20,1,15,
95,2,21,1,33,
95,2,33,1,1,1
95,2,34,4,0,
95,2,36,4,0,
95,2,38,4,0,
95,2,70,4,0,
95,2,88,1,19,
95,2,89,4,0,
95,2,90,4,0,
95,2,91,4,0,
95,2,92,4,0,
95,2,99,1,25,
95,2,99,4,0,
95,2,102,4,0,
95,2,103,1,1,2
95,2,104,4,0,
95,2,106,1,43,
95,2,117,4,0,
95,2,120,4,0,
95,2,130,4,0,
95,2,153,4,0,
95,2,156,4,0,
95,2,157,4,0,
95,2,164,4,0,
95,3,20,1,10,
95,3,21,1,40,
95,3,29,4,0,
95,3,33,1,1,1
95,3,46,4,0,
95,3,70,4,0,
95,3,88,1,14,
95,3,89,4,0,
95,3,91,4,0,
95,3,92,4,0,
95,3,99,1,27,
95,3,103,1,1,2
95,3,104,4,0,
95,3,106,1,23,
95,3,156,4,0,
95,3,157,2,0,
95,3,173,4,0,
95,3,174,4,0,
95,3,175,2,0,
95,3,182,4,0,
95,3,189,4,0,
95,3,201,1,36,
95,3,201,4,0,
95,3,203,4,0,
95,3,207,4,0,
95,3,213,4,0,
95,3,214,4,0,
95,3,216,4,0,
95,3,218,4,0,
95,3,231,4,0,
95,3,237,4,0,
95,3,241,4,0,
95,3,249,4,0,
95,4,20,1,10,
95,4,21,1,40,
95,4,29,4,0,
95,4,33,1,1,1
95,4,46,4,0,
95,4,70,4,0,
95,4,88,1,14,
95,4,89,4,0,
95,4,91,4,0,
95,4,92,4,0,
95,4,99,1,27,
95,4,103,1,1,2
95,4,104,4,0,
95,4,106,1,23,
95,4,156,4,0,
95,4,157,2,0,
95,4,173,4,0,
95,4,174,4,0,
95,4,175,2,0,
95,4,182,4,0,
95,4,189,4,0,
95,4,201,1,36,
95,4,201,4,0,
95,4,203,4,0,
95,4,207,4,0,
95,4,213,4,0,
95,4,214,4,0,
95,4,216,4,0,
95,4,218,4,0,
95,4,231,4,0,
95,4,237,4,0,
95,4,241,4,0,
95,4,249,4,0,
95,5,20,1,9,
95,5,21,1,37,
95,5,33,1,1,1
95,5,38,1,57,
95,5,46,4,0,
95,5,70,4,0,
95,5,88,1,13,
95,5,89,4,0,
95,5,91,4,0,
95,5,92,4,0,
95,5,99,1,25,
95,5,103,1,1,2
95,5,104,4,0,
95,5,106,1,21,
95,5,153,2,0,
95,5,156,4,0,
95,5,157,2,0,
95,5,175,2,0,
95,5,182,4,0,
95,5,201,1,33,
95,5,201,4,0,
95,5,213,4,0,
95,5,216,4,0,
95,5,218,4,0,
95,5,231,1,45,
95,5,231,4,0,
95,5,237,4,0,
95,5,241,4,0,
95,5,249,4,0,
95,5,259,4,0,
95,5,263,4,0,
95,5,269,4,0,
95,5,290,4,0,
95,5,317,4,0,
95,5,328,1,49,
95,5,335,2,0,
95,6,20,1,9,
95,6,21,1,37,
95,6,33,1,1,1
95,6,34,3,0,
95,6,38,1,57,
95,6,38,3,0,
95,6,46,4,0,
95,6,70,4,0,
95,6,88,1,13,
95,6,89,4,0,
95,6,91,4,0,
95,6,92,4,0,
95,6,99,1,25,
95,6,102,3,0,
95,6,103,1,1,2
95,6,104,4,0,
95,6,106,1,21,
95,6,153,2,0,
95,6,153,3,0,
95,6,156,4,0,
95,6,157,2,0,
95,6,157,3,0,
95,6,164,3,0,
95,6,173,3,0,
95,6,175,2,0,
95,6,182,4,0,
95,6,189,3,0,
95,6,201,1,33,
95,6,201,4,0,
95,6,203,3,0,
95,6,207,3,0,
95,6,213,4,0,
95,6,214,3,0,
95,6,216,4,0,
95,6,218,4,0,
95,6,231,1,45,
95,6,231,4,0,
95,6,237,4,0,
95,6,241,4,0,
95,6,244,3,0,
95,6,249,4,0,
95,6,259,4,0,
95,6,263,4,0,
95,6,269,4,0,
95,6,290,4,0,
95,6,317,4,0,
95,6,328,1,49,
95,6,335,2,0,
95,7,20,1,8,
95,7,21,1,41,
95,7,33,1,1,1
95,7,34,3,0,
95,7,38,1,56,
95,7,38,3,0,
95,7,46,4,0,
95,7,70,4,0,
95,7,88,1,12,
95,7,89,4,0,
95,7,91,4,0,
95,7,92,4,0,
95,7,99,1,23,
95,7,102,3,0,
95,7,103,1,1,2
95,7,104,4,0,
95,7,106,1,19,
95,7,153,2,0,
95,7,153,3,0,
95,7,156,4,0,
95,7,157,2,0,
95,7,157,3,0,
95,7,164,3,0,
95,7,175,2,0,
95,7,182,4,0,
95,7,201,1,34,
95,7,201,4,0,
95,7,213,4,0,
95,7,216,4,0,
95,7,218,4,0,
95,7,225,1,30,
95,7,231,1,45,
95,7,231,4,0,
95,7,237,4,0,
95,7,241,4,0,
95,7,249,4,0,
95,7,259,4,0,
95,7,263,4,0,
95,7,269,4,0,
95,7,290,4,0,
95,7,317,4,0,
95,7,328,1,52,
95,7,335,2,0,
95,8,20,1,1,4
95,8,21,1,25,
95,8,33,1,1,2
95,8,38,1,46,
95,8,46,4,0,
95,8,70,4,0,
95,8,88,1,9,
95,8,89,4,0,
95,8,91,4,0,
95,8,92,4,0,
95,8,99,1,14,
95,8,103,1,6,
95,8,104,4,0,
95,8,106,1,1,3
95,8,111,2,0,
95,8,153,2,0,
95,8,153,4,0,
95,8,156,4,0,
95,8,157,2,0,
95,8,157,4,0,
95,8,164,4,0,
95,8,175,2,0,
95,8,182,4,0,
95,8,201,1,22,
95,8,201,4,0,
95,8,203,4,0,
95,8,205,2,0,
95,8,207,4,0,
95,8,213,4,0,
95,8,214,4,0,
95,8,216,4,0,
95,8,218,4,0,
95,8,225,1,33,
95,8,231,1,38,
95,8,231,4,0,
95,8,237,4,0,
95,8,241,4,0,
95,8,244,4,0,
95,8,249,4,0,
95,8,259,4,0,
95,8,263,4,0,
95,8,269,4,0,
95,8,290,4,0,
95,8,300,1,1,1
95,8,317,1,17,
95,8,317,4,0,
95,8,328,1,41,
95,8,335,2,0,
95,8,350,2,0,
95,8,360,4,0,
95,8,363,4,0,
95,8,371,4,0,
95,8,397,1,30,
95,8,397,4,0,
95,8,406,4,0,
95,8,430,4,0,
95,8,431,4,0,
95,8,444,1,49,
95,8,444,4,0,
95,8,445,4,0,
95,8,446,4,0,
95,9,20,1,1,4
95,9,21,1,25,
95,9,33,1,1,2
95,9,38,1,49,
95,9,46,4,0,
95,9,70,4,0,
95,9,88,1,9,
95,9,89,4,0,
95,9,91,4,0,
95,9,92,4,0,
95,9,99,1,14,
95,9,103,1,6,
95,9,104,4,0,
95,9,106,1,1,3
95,9,111,2,0,
95,9,153,2,0,
95,9,153,4,0,
95,9,156,4,0,
95,9,157,2,0,
95,9,157,4,0,
95,9,164,4,0,
95,9,173,3,0,
95,9,174,1,38,
95,9,175,2,0,
95,9,182,4,0,
95,9,189,3,0,
95,9,201,1,22,
95,9,201,4,0,
95,9,203,4,0,
95,9,205,2,0,
95,9,205,3,0,
95,9,207,4,0,
95,9,213,4,0,
95,9,214,4,0,
95,9,216,4,0,
95,9,218,4,0,
95,9,225,1,33,
95,9,231,1,41,
95,9,231,4,0,
95,9,237,4,0,
95,9,239,3,0,
95,9,241,4,0,
95,9,244,4,0,
95,9,246,3,0,
95,9,249,4,0,
95,9,259,4,0,
95,9,263,4,0,
95,9,269,4,0,
95,9,290,4,0,
95,9,300,1,1,1
95,9,317,1,17,
95,9,317,4,0,
95,9,328,1,46,
95,9,335,2,0,
95,9,350,2,0,
95,9,360,4,0,
95,9,363,4,0,
95,9,371,4,0,
95,9,397,1,30,
95,9,397,4,0,
95,9,406,4,0,
95,9,414,3,0,
95,9,430,4,0,
95,9,431,4,0,
95,9,442,3,0,
95,9,444,1,54,
95,9,444,4,0,
95,9,445,4,0,
95,9,446,4,0,
95,10,20,1,1,4
95,10,21,1,25,
95,10,29,3,0,
95,10,33,1,1,2
95,10,38,1,49,
95,10,46,4,0,
95,10,70,4,0,
95,10,88,1,9,
95,10,89,4,0,
95,10,91,4,0,
95,10,92,4,0,
95,10,99,1,14,
95,10,103,1,6,
95,10,104,4,0,
95,10,106,1,1,3
95,10,111,2,0,
95,10,153,2,0,
95,10,153,4,0,
95,10,156,4,0,
95,10,157,2,0,
95,10,157,4,0,
95,10,164,4,0,
95,10,173,3,0,
95,10,174,1,38,
95,10,175,2,0,
95,10,182,4,0,
95,10,189,3,0,
95,10,201,1,22,
95,10,201,4,0,
95,10,203,4,0,
95,10,205,2,0,
95,10,205,3,0,
95,10,207,4,0,
95,10,213,4,0,
95,10,214,4,0,
95,10,216,4,0,
95,10,218,4,0,
95,10,225,1,33,
95,10,231,1,41,
95,10,231,4,0,
95,10,237,4,0,
95,10,239,3,0,
95,10,241,4,0,
95,10,244,4,0,
95,10,246,3,0,
95,10,249,4,0,
95,10,259,4,0,
95,10,263,4,0,
95,10,269,4,0,
95,10,290,4,0,
95,10,300,1,1,1
95,10,317,1,17,
95,10,317,4,0,
95,10,328,1,46,
95,10,335,2,0,
95,10,335,3,0,
95,10,350,2,0,
95,10,360,4,0,
95,10,363,4,0,
95,10,371,4,0,
95,10,397,1,30,
95,10,397,4,0,
95,10,406,4,0,
95,10,414,3,0,
95,10,430,4,0,
95,10,431,4,0,
95,10,442,3,0,
95,10,444,1,54,
95,10,444,4,0,
95,10,445,4,0,
95,10,446,4,0,
95,11,20,1,1,4
95,11,21,1,33,
95,11,33,1,1,2
95,11,38,1,57,
95,11,46,4,0,
95,11,70,4,0,
95,11,88,1,9,
95,11,89,4,0,
95,11,91,4,0,
95,11,92,4,0,
95,11,99,1,14,
95,11,103,1,6,
95,11,104,4,0,
95,11,106,1,1,3
95,11,111,2,0,
95,11,153,4,0,
95,11,156,4,0,
95,11,157,4,0,
95,11,164,4,0,
95,11,174,1,46,
95,11,175,2,0,
95,11,182,4,0,
95,11,201,1,25,
95,11,201,4,0,
95,11,205,2,0,
95,11,207,4,0,
95,11,213,4,0,
95,11,216,4,0,
95,11,218,4,0,
95,11,225,1,41,
95,11,231,1,49,
95,11,237,4,0,
95,11,241,4,0,
95,11,244,4,0,
95,11,249,4,0,
95,11,259,4,0,
95,11,263,4,0,
95,11,269,4,0,
95,11,300,1,1,1
95,11,317,1,17,
95,11,317,4,0,
95,11,328,1,54,
95,11,335,2,0,
95,11,350,2,0,
95,11,360,4,0,
95,11,371,4,0,
95,11,397,1,30,
95,11,397,4,0,
95,11,430,4,0,
95,11,431,2,0,
95,11,444,1,62,
95,11,444,4,0,
95,11,446,1,38,
95,11,446,2,0,
95,11,479,1,22,
95,11,479,4,0,
95,11,484,2,0,
95,11,496,4,0,
95,11,523,4,0,
95,11,525,4,0,
95,12,20,1,9,
95,12,21,1,37,
95,12,33,1,1,1
95,12,38,1,57,
95,12,46,4,0,
95,12,70,4,0,
95,12,88,1,13,
95,12,89,4,0,
95,12,91,4,0,
95,12,92,4,0,
95,12,99,1,25,
95,12,103,1,1,2
95,12,104,4,0,
95,12,106,1,21,
95,12,156,4,0,
95,12,182,4,0,
95,12,201,1,33,
95,12,201,4,0,
95,12,213,4,0,
95,12,216,4,0,
95,12,218,4,0,
95,12,231,1,45,
95,12,231,4,0,
95,12,237,4,0,
95,12,241,4,0,
95,12,249,4,0,
95,12,259,4,0,
95,12,263,4,0,
95,12,269,4,0,
95,12,290,4,0,
95,12,317,4,0,
95,12,328,1,49,
95,13,20,1,9,
95,13,21,1,37,
95,13,33,1,1,1
95,13,34,3,0,
95,13,38,1,57,
95,13,38,3,0,
95,13,46,4,0,
95,13,70,4,0,
95,13,88,1,13,
95,13,89,4,0,
95,13,91,4,0,
95,13,92,4,0,
95,13,99,1,25,
95,13,102,3,0,
95,13,103,1,1,2
95,13,104,4,0,
95,13,106,1,21,
95,13,120,3,0,
95,13,156,4,0,
95,13,164,3,0,
95,13,182,4,0,
95,13,201,1,33,
95,13,201,4,0,
95,13,207,3,0,
95,13,213,4,0,
95,13,216,4,0,
95,13,218,4,0,
95,13,231,1,45,
95,13,231,4,0,
95,13,237,4,0,
95,13,241,4,0,
95,13,249,4,0,
95,13,259,4,0,
95,13,263,4,0,
95,13,269,4,0,
95,13,290,4,0,
95,13,317,4,0,
95,13,328,1,49,
95,14,20,1,1,4
95,14,20,3,0,
95,14,21,1,28,
95,14,33,1,1,2
95,14,38,1,49,
95,14,46,4,0,
95,14,70,4,0,
95,14,88,1,7,
95,14,89,4,0,
95,14,91,1,43,
95,14,91,4,0,
95,14,92,4,0,
95,14,99,1,10,
95,14,103,1,31,
95,14,104,4,0,
95,14,106,1,1,3
95,14,111,2,0,
95,14,153,4,0,
95,14,156,4,0,
95,14,157,1,34,
95,14,157,4,0,
95,14,164,4,0,
95,14,173,3,0,
95,14,174,1,4,
95,14,175,2,0,
95,14,182,4,0,
95,14,201,1,52,
95,14,201,4,0,
95,14,205,2,0,
95,14,207,4,0,
95,14,213,4,0,
95,14,214,3,0,
95,14,216,4,0,
95,14,218,4,0,
95,14,225,1,25,
95,14,231,1,40,
95,14,231,3,0,
95,14,237,4,0,
95,14,241,4,0,
95,14,244,4,0,
95,14,249,4,0,
95,14,259,4,0,
95,14,263,4,0,
95,14,269,4,0,
95,14,300,1,1,1
95,14,317,1,13,
95,14,317,4,0,
95,14,328,1,37,
95,14,335,2,0,
95,14,335,3,0,
95,14,350,2,0,
95,14,360,4,0,
95,14,371,4,0,
95,14,397,1,19,
95,14,397,4,0,
95,14,406,3,0,
95,14,414,3,0,
95,14,430,4,0,
95,14,431,2,0,
95,14,442,3,0,
95,14,444,1,46,
95,14,444,4,0,
95,14,446,1,16,
95,14,446,2,0,
95,14,446,3,0,
95,14,479,1,22,
95,14,479,4,0,
95,14,484,2,0,
95,14,496,4,0,
95,14,523,4,0,
95,14,525,4,0,
95,15,20,1,1,4
95,15,21,1,28,
95,15,33,1,1,2
95,15,38,1,49,
95,15,46,4,0,
95,15,70,4,0,
95,15,88,1,7,
95,15,89,4,0,
95,15,91,1,43,
95,15,91,4,0,
95,15,92,4,0,
95,15,99,1,13,
95,15,103,1,31,
95,15,104,4,0,
95,15,106,1,1,3
95,15,111,2,0,
95,15,153,4,0,
95,15,156,4,0,
95,15,157,1,34,
95,15,157,4,0,
95,15,164,4,0,
95,15,174,1,4,
95,15,175,2,0,
95,15,182,4,0,
95,15,201,1,52,
95,15,201,4,0,
95,15,205,2,0,
95,15,207,4,0,
95,15,213,4,0,
95,15,214,4,0,
95,15,216,4,0,
95,15,218,4,0,
95,15,225,1,25,
95,15,231,1,40,
95,15,237,4,0,
95,15,241,4,0,
95,15,244,4,0,
95,15,249,4,0,
95,15,259,4,0,
95,15,263,4,0,
95,15,267,4,0,
95,15,269,4,0,
95,15,300,1,1,1
95,15,317,1,10,
95,15,317,4,0,
95,15,328,1,37,
95,15,335,2,0,
95,15,350,2,0,
95,15,360,1,20,
95,15,360,4,0,
95,15,371,4,0,
95,15,397,1,19,
95,15,397,4,0,
95,15,430,4,0,
95,15,431,2,0,
95,15,444,1,46,
95,15,444,4,0,
95,15,446,1,16,
95,15,446,2,0,
95,15,479,1,22,
95,15,479,4,0,
95,15,484,2,0,
95,15,496,4,0,
95,15,523,4,0,
95,15,525,4,0,
95,15,563,2,0,
95,15,590,4,0,
95,16,20,1,1,4
95,16,20,3,0,
95,16,21,1,28,1
95,16,33,1,1,2
95,16,38,1,49,1
95,16,46,4,0,
95,16,70,4,0,
95,16,88,1,7,1
95,16,89,4,0,
95,16,91,1,43,1
95,16,91,4,0,
95,16,92,4,0,
95,16,99,1,13,1
95,16,103,1,31,1
95,16,104,4,0,
95,16,106,1,1,3
95,16,111,2,0,
95,16,153,4,0,
95,16,156,4,0,
95,16,157,1,34,1
95,16,157,4,0,
95,16,164,4,0,
95,16,173,3,0,
95,16,174,1,4,1
95,16,175,2,0,
95,16,182,4,0,
95,16,201,1,52,1
95,16,201,4,0,
95,16,205,2,0,
95,16,207,4,0,
95,16,213,4,0,
95,16,214,4,0,
95,16,216,4,0,
95,16,218,4,0,
95,16,225,1,25,1
95,16,231,1,40,1
95,16,231,3,0,
95,16,237,4,0,
95,16,241,4,0,
95,16,244,4,0,
95,16,249,4,0,
95,16,259,4,0,
95,16,263,4,0,
95,16,267,4,0,
95,16,269,4,0,
95,16,290,4,0,
95,16,300,1,1,1
95,16,317,1,10,1
95,16,317,4,0,
95,16,328,1,37,1
95,16,335,2,0,
95,16,335,3,0,
95,16,350,2,0,
95,16,360,1,20,1
95,16,360,4,0,
95,16,371,4,0,
95,16,397,1,19,1
95,16,397,4,0,
95,16,406,3,0,
95,16,414,3,0,
95,16,430,4,0,
95,16,431,2,0,
95,16,442,3,0,
95,16,444,1,46,1
95,16,444,4,0,
95,16,446,1,16,1
95,16,446,2,0,
95,16,446,3,0,
95,16,479,1,22,1
95,16,479,4,0,
95,16,484,2,0,
95,16,496,4,0,
95,16,523,4,0,
95,16,525,4,0,
95,16,563,2,0,
95,16,590,4,0,
95,17,20,1,1,4
95,17,21,1,28,
95,17,33,1,1,2
95,17,38,1,49,
95,17,46,4,0,
95,17,88,1,7,
95,17,89,4,0,
95,17,91,1,43,
95,17,92,4,0,
95,17,99,1,13,
95,17,103,1,31,
95,17,104,4,0,
95,17,106,1,1,3
95,17,111,2,0,
95,17,153,4,0,
95,17,156,4,0,
95,17,157,1,34,
95,17,157,4,0,
95,17,164,4,0,
95,17,174,1,4,
95,17,175,2,0,
95,17,182,4,0,
95,17,201,1,52,
95,17,201,4,0,
95,17,205,2,0,
95,17,207,4,0,
95,17,213,4,0,
95,17,214,4,0,
95,17,216,4,0,
95,17,218,4,0,
95,17,225,1,25,
95,17,231,1,40,
95,17,237,4,0,
95,17,241,4,0,
95,17,244,4,0,
95,17,259,4,0,
95,17,263,4,0,
95,17,267,4,0,
95,17,269,4,0,
95,17,300,1,1,1
95,17,317,1,10,
95,17,317,4,0,
95,17,328,1,37,
95,17,335,2,0,
95,17,350,2,0,
95,17,360,1,20,
95,17,360,4,0,
95,17,371,4,0,
95,17,397,1,19,
95,17,397,4,0,
95,17,430,4,0,
95,17,431,2,0,
95,17,444,1,46,
95,17,444,4,0,
95,17,446,1,16,
95,17,446,2,0,
95,17,479,1,22,
95,17,479,4,0,
95,17,484,2,0,
95,17,496,4,0,
95,17,523,4,0,
95,17,525,4,0,
95,17,563,2,0,
95,17,590,4,0,
95,17,693,4,0,
95,18,20,1,1,4
95,18,21,1,28,
95,18,33,1,1,2
95,18,38,1,49,
95,18,46,4,0,
95,18,88,1,7,
95,18,89,4,0,
95,18,91,1,43,
95,18,92,4,0,
95,18,99,1,13,
95,18,103,1,31,
95,18,104,4,0,
95,18,106,1,1,3
95,18,111,2,0,
95,18,153,4,0,
95,18,156,4,0,
95,18,157,1,34,
95,18,157,4,0,
95,18,164,4,0,
95,18,174,1,4,
95,18,175,2,0,
95,18,182,4,0,
95,18,201,1,52,
95,18,201,4,0,
95,18,205,2,0,
95,18,207,4,0,
95,18,213,4,0,
95,18,214,4,0,
95,18,216,4,0,
95,18,218,4,0,
95,18,225,1,25,
95,18,231,1,40,
95,18,237,4,0,
95,18,241,4,0,
95,18,244,4,0,
95,18,259,4,0,
95,18,263,4,0,
95,18,267,4,0,
95,18,269,4,0,
95,18,300,1,1,1
95,18,317,1,10,
95,18,317,4,0,
95,18,328,1,37,
95,18,335,2,0,
95,18,350,2,0,
95,18,360,1,20,
95,18,360,4,0,
95,18,371,4,0,
95,18,397,1,19,
95,18,397,4,0,
95,18,430,4,0,
95,18,431,2,0,
95,18,444,1,46,
95,18,444,4,0,
95,18,446,1,16,
95,18,446,2,0,
95,18,469,2,0,
95,18,479,1,22,
95,18,479,4,0,
95,18,484,2,0,
95,18,496,4,0,
95,18,523,4,0,
95,18,525,4,0,
95,18,563,2,0,
95,18,590,4,0,
95,18,693,4,0,
96,1,1,1,1,1
96,1,5,4,0,
96,1,25,4,0,
96,1,29,1,24,
96,1,34,4,0,
96,1,36,4,0,
96,1,38,4,0,
96,1,50,1,12,
96,1,66,4,0,
96,1,68,4,0,
96,1,69,4,0,
96,1,86,4,0,
96,1,92,4,0,
96,1,93,1,17,
96,1,94,1,32,
96,1,94,4,0,
96,1,95,1,1,2
96,1,96,1,37,
96,1,99,4,0,
96,1,100,4,0,
96,1,102,4,0,
96,1,104,4,0,
96,1,115,4,0,
96,1,117,4,0,
96,1,118,4,0,
96,1,130,4,0,
96,1,138,4,0,
96,1,139,1,29,
96,1,148,4,0,
96,1,149,4,0,
96,1,156,4,0,
96,1,161,4,0,
96,1,164,4,0,
96,2,1,1,1,1
96,2,5,4,0,
96,2,25,4,0,
96,2,29,1,24,
96,2,34,4,0,
96,2,36,4,0,
96,2,38,4,0,
96,2,50,1,12,
96,2,66,4,0,
96,2,68,4,0,
96,2,69,4,0,
96,2,86,4,0,
96,2,92,4,0,
96,2,93,1,17,
96,2,94,1,32,
96,2,94,4,0,
96,2,95,1,1,2
96,2,96,1,37,
96,2,99,4,0,
96,2,100,4,0,
96,2,102,4,0,
96,2,104,4,0,
96,2,115,4,0,
96,2,117,4,0,
96,2,118,4,0,
96,2,130,4,0,
96,2,138,4,0,
96,2,139,1,29,
96,2,148,4,0,
96,2,149,4,0,
96,2,156,4,0,
96,2,161,4,0,
96,2,164,4,0,
96,3,1,1,1,1
96,3,7,4,0,
96,3,8,4,0,
96,3,9,4,0,
96,3,29,1,25,
96,3,29,4,0,
96,3,50,1,10,
96,3,92,4,0,
96,3,93,1,18,
96,3,94,1,40,
96,3,94,4,0,
96,3,95,1,1,2
96,3,96,1,36,
96,3,104,4,0,
96,3,112,2,0,
96,3,113,2,0,
96,3,138,4,0,
96,3,139,1,31,
96,3,148,4,0,
96,3,156,4,0,
96,3,171,4,0,
96,3,173,4,0,
96,3,174,4,0,
96,3,182,4,0,
96,3,192,4,0,
96,3,203,4,0,
96,3,207,4,0,
96,3,213,4,0,
96,3,214,4,0,
96,3,216,4,0,
96,3,218,4,0,
96,3,223,4,0,
96,3,237,4,0,
96,3,240,4,0,
96,3,241,4,0,
96,3,244,1,43,
96,3,244,4,0,
96,3,247,4,0,
96,3,248,1,45,
96,4,1,1,1,1
96,4,7,4,0,
96,4,8,4,0,
96,4,9,4,0,
96,4,29,1,25,
96,4,29,4,0,
96,4,50,1,10,
96,4,92,4,0,
96,4,93,1,18,
96,4,94,1,40,
96,4,94,4,0,
96,4,95,1,1,2
96,4,96,1,36,
96,4,104,4,0,
96,4,112,2,0,
96,4,113,2,0,
96,4,138,4,0,
96,4,139,1,31,
96,4,148,4,0,
96,4,156,4,0,
96,4,171,4,0,
96,4,173,4,0,
96,4,174,4,0,
96,4,182,4,0,
96,4,192,4,0,
96,4,203,4,0,
96,4,207,4,0,
96,4,213,4,0,
96,4,214,4,0,
96,4,216,4,0,
96,4,218,4,0,
96,4,223,4,0,
96,4,237,4,0,
96,4,240,4,0,
96,4,241,4,0,
96,4,244,1,43,
96,4,244,4,0,
96,4,247,4,0,
96,4,248,1,45,
96,5,1,1,1,1
96,5,7,2,0,
96,5,8,2,0,
96,5,9,2,0,
96,5,29,1,25,
96,5,50,1,10,
96,5,92,4,0,
96,5,93,1,18,
96,5,94,1,40,
96,5,94,4,0,
96,5,95,1,1,2
96,5,96,1,36,
96,5,104,4,0,
96,5,112,2,0,
96,5,113,4,0,
96,5,115,4,0,
96,5,139,1,31,
96,5,148,4,0,
96,5,156,4,0,
96,5,168,4,0,
96,5,182,4,0,
96,5,213,4,0,
96,5,216,4,0,
96,5,218,4,0,
96,5,219,4,0,
96,5,237,4,0,
96,5,240,4,0,
96,5,241,4,0,
96,5,244,1,43,
96,5,247,4,0,
96,5,248,1,45,
96,5,259,4,0,
96,5,263,4,0,
96,5,264,4,0,
96,5,269,4,0,
96,5,272,2,0,
96,5,274,2,0,
96,5,280,4,0,
96,5,285,4,0,
96,5,289,4,0,
96,5,290,4,0,
96,5,347,4,0,
96,6,1,1,1,1
96,6,5,3,0,
96,6,7,2,0,
96,6,7,3,0,
96,6,8,2,0,
96,6,8,3,0,
96,6,9,2,0,
96,6,9,3,0,
96,6,25,3,0,
96,6,29,1,25,
96,6,34,3,0,
96,6,38,3,0,
96,6,50,1,10,
96,6,68,3,0,
96,6,69,3,0,
96,6,86,3,0,
96,6,92,4,0,
96,6,93,1,18,
96,6,94,1,40,
96,6,94,4,0,
96,6,95,1,1,2
96,6,96,1,36,
96,6,102,3,0,
96,6,104,4,0,
96,6,112,2,0,
96,6,113,4,0,
96,6,115,4,0,
96,6,118,3,0,
96,6,138,3,0,
96,6,139,1,31,
96,6,148,4,0,
96,6,156,4,0,
96,6,164,3,0,
96,6,168,4,0,
96,6,173,3,0,
96,6,182,4,0,
96,6,203,3,0,
96,6,207,3,0,
96,6,213,4,0,
96,6,214,3,0,
96,6,216,4,0,
96,6,218,4,0,
96,6,219,4,0,
96,6,223,3,0,
96,6,237,4,0,
96,6,240,4,0,
96,6,241,4,0,
96,6,244,1,43,
96,6,244,3,0,
96,6,247,4,0,
96,6,248,1,45,
96,6,259,4,0,
96,6,263,4,0,
96,6,264,4,0,
96,6,269,4,0,
96,6,272,2,0,
96,6,274,2,0,
96,6,280,4,0,
96,6,285,4,0,
96,6,289,4,0,
96,6,290,4,0,
96,6,347,4,0,
96,7,1,1,1,1
96,7,5,3,0,
96,7,7,2,0,
96,7,8,2,0,
96,7,9,2,0,
96,7,25,3,0,
96,7,29,1,17,
96,7,34,3,0,
96,7,38,3,0,
96,7,50,1,7,
96,7,68,3,0,
96,7,69,3,0,
96,7,86,3,0,
96,7,92,4,0,
96,7,93,1,11,
96,7,94,1,31,
96,7,94,4,0,
96,7,95,1,1,2
96,7,96,1,27,
96,7,102,3,0,
96,7,104,4,0,
96,7,112,2,0,
96,7,113,4,0,
96,7,115,4,0,
96,7,118,3,0,
96,7,138,3,0,
96,7,139,1,21,
96,7,148,4,0,
96,7,156,4,0,
96,7,164,3,0,
96,7,168,4,0,
96,7,182,4,0,
96,7,207,1,41,
96,7,213,4,0,
96,7,216,4,0,
96,7,218,4,0,
96,7,219,4,0,
96,7,237,4,0,
96,7,240,4,0,
96,7,241,4,0,
96,7,244,1,37,
96,7,247,4,0,
96,7,248,1,47,
96,7,259,4,0,
96,7,263,4,0,
96,7,264,4,0,
96,7,269,4,0,
96,7,272,2,0,
96,7,274,2,0,
96,7,280,4,0,
96,7,285,4,0,
96,7,289,4,0,
96,7,290,4,0,
96,7,347,4,0,
96,8,1,1,1,1
96,8,7,2,0,
96,8,8,2,0,
96,8,9,2,0,
96,8,29,1,15,
96,8,29,1,32,
96,8,50,1,7,
96,8,60,1,26,
96,8,86,4,0,
96,8,92,4,0,
96,8,93,1,9,
96,8,94,1,40,
96,8,94,4,0,
96,8,95,1,1,2
96,8,96,1,21,
96,8,104,4,0,
96,8,112,2,0,
96,8,113,4,0,
96,8,115,4,0,
96,8,138,4,0,
96,8,139,1,18,
96,8,148,4,0,
96,8,156,4,0,
96,8,164,4,0,
96,8,168,4,0,
96,8,182,4,0,
96,8,203,4,0,
96,8,207,1,37,
96,8,207,4,0,
96,8,213,4,0,
96,8,214,4,0,
96,8,216,4,0,
96,8,218,4,0,
96,8,219,4,0,
96,8,237,4,0,
96,8,240,4,0,
96,8,241,4,0,
96,8,244,1,29,
96,8,244,4,0,
96,8,247,4,0,
96,8,248,1,53,
96,8,259,4,0,
96,8,260,2,0,
96,8,263,4,0,
96,8,264,4,0,
96,8,269,4,0,
96,8,272,2,0,
96,8,274,2,0,
96,8,278,4,0,
96,8,280,4,0,
96,8,285,4,0,
96,8,289,4,0,
96,8,290,4,0,
96,8,347,4,0,
96,8,363,4,0,
96,8,374,4,0,
96,8,409,4,0,
96,8,417,1,43,
96,8,417,2,0,
96,8,427,2,0,
96,8,428,1,50,
96,8,433,4,0,
96,8,445,4,0,
96,8,447,4,0,
96,9,1,1,1,1
96,9,7,2,0,
96,9,7,3,0,
96,9,8,2,0,
96,9,8,3,0,
96,9,9,2,0,
96,9,9,3,0,
96,9,29,1,15,
96,9,29,1,32,
96,9,50,1,7,
96,9,60,1,26,
96,9,86,4,0,
96,9,92,4,0,
96,9,93,1,9,
96,9,94,1,40,
96,9,94,4,0,
96,9,95,1,1,2
96,9,96,1,21,
96,9,104,4,0,
96,9,112,2,0,
96,9,113,4,0,
96,9,115,4,0,
96,9,138,4,0,
96,9,139,1,18,
96,9,148,4,0,
96,9,156,4,0,
96,9,164,4,0,
96,9,168,4,0,
96,9,182,4,0,
96,9,203,4,0,
96,9,207,1,37,
96,9,207,4,0,
96,9,213,4,0,
96,9,214,4,0,
96,9,216,4,0,
96,9,218,4,0,
96,9,219,4,0,
96,9,237,4,0,
96,9,240,4,0,
96,9,241,4,0,
96,9,244,1,29,
96,9,244,4,0,
96,9,247,4,0,
96,9,248,1,53,
96,9,259,4,0,
96,9,260,2,0,
96,9,263,4,0,
96,9,264,4,0,
96,9,269,4,0,
96,9,271,3,0,
96,9,272,2,0,
96,9,274,2,0,
96,9,278,4,0,
96,9,280,4,0,
96,9,285,4,0,
96,9,289,4,0,
96,9,290,4,0,
96,9,324,3,0,
96,9,347,4,0,
96,9,363,4,0,
96,9,374,4,0,
96,9,409,4,0,
96,9,417,1,43,
96,9,417,2,0,
96,9,427,2,0,
96,9,428,1,50,
96,9,428,3,0,
96,9,433,4,0,
96,9,445,4,0,
96,9,447,4,0,
96,10,1,1,1,1
96,10,7,2,0,
96,10,7,3,0,
96,10,8,2,0,
96,10,8,3,0,
96,10,9,2,0,
96,10,9,3,0,
96,10,29,1,15,
96,10,29,1,32,
96,10,29,3,0,
96,10,50,1,7,
96,10,60,1,26,
96,10,67,3,0,
96,10,86,4,0,
96,10,92,4,0,
96,10,93,1,9,
96,10,94,1,40,
96,10,94,4,0,
96,10,95,1,1,2
96,10,96,1,21,
96,10,104,4,0,
96,10,112,2,0,
96,10,113,4,0,
96,10,115,4,0,
96,10,138,4,0,
96,10,139,1,18,
96,10,148,4,0,
96,10,156,4,0,
96,10,164,4,0,
96,10,168,4,0,
96,10,182,4,0,
96,10,203,4,0,
96,10,207,1,37,
96,10,207,4,0,
96,10,213,4,0,
96,10,214,4,0,
96,10,216,4,0,
96,10,218,4,0,
96,10,219,4,0,
96,10,237,4,0,
96,10,240,4,0,
96,10,241,4,0,
96,10,244,1,29,
96,10,244,4,0,
96,10,247,4,0,
96,10,248,1,53,
96,10,259,4,0,
96,10,260,2,0,
96,10,263,4,0,
96,10,264,4,0,
96,10,269,4,0,
96,10,271,3,0,
96,10,272,2,0,
96,10,272,3,0,
96,10,274,2,0,
96,10,277,3,0,
96,10,278,4,0,
96,10,280,4,0,
96,10,285,4,0,
96,10,289,4,0,
96,10,290,4,0,
96,10,324,3,0,
96,10,347,4,0,
96,10,363,4,0,
96,10,374,4,0,
96,10,385,2,0,
96,10,409,4,0,
96,10,417,1,43,
96,10,417,2,0,
96,10,427,2,0,
96,10,428,1,50,
96,10,428,3,0,
96,10,433,4,0,
96,10,445,4,0,
96,10,447,4,0,
96,11,1,1,1,1
96,11,7,2,0,
96,11,8,2,0,
96,11,9,2,0,
96,11,29,1,13,
96,11,29,1,29,
96,11,50,1,5,
96,11,60,1,25,
96,11,86,4,0,
96,11,92,4,0,
96,11,93,1,9,
96,11,94,1,49,
96,11,94,4,0,
96,11,95,1,1,2
96,11,96,1,21,
96,11,104,4,0,
96,11,112,2,0,
96,11,113,4,0,
96,11,115,4,0,
96,11,138,4,0,
96,11,139,1,17,
96,11,148,4,0,
96,11,156,4,0,
96,11,164,4,0,
96,11,168,4,0,
96,11,182,4,0,
96,11,207,1,45,
96,11,207,4,0,
96,11,213,4,0,
96,11,216,4,0,
96,11,218,4,0,
96,11,219,4,0,
96,11,237,4,0,
96,11,240,4,0,
96,11,241,4,0,
96,11,244,1,33,
96,11,244,4,0,
96,11,247,4,0,
96,11,248,1,61,
96,11,259,4,0,
96,11,260,2,0,
96,11,263,4,0,
96,11,269,4,0,
96,11,272,2,0,
96,11,274,2,0,
96,11,280,4,0,
96,11,285,2,0,
96,11,290,2,0,
96,11,347,4,0,
96,11,374,4,0,
96,11,385,2,0,
96,11,417,1,53,
96,11,417,2,0,
96,11,427,2,0,
96,11,428,1,41,
96,11,433,4,0,
96,11,447,4,0,
96,11,473,1,57,
96,11,473,4,0,
96,11,477,4,0,
96,11,485,1,37,
96,11,490,4,0,
96,11,496,4,0,
96,12,1,1,1,1
96,12,29,1,25,
96,12,50,1,10,
96,12,92,4,0,
96,12,93,1,18,
96,12,94,1,40,
96,12,94,4,0,
96,12,95,1,1,2
96,12,96,1,36,
96,12,104,4,0,
96,12,113,4,0,
96,12,115,4,0,
96,12,139,1,31,
96,12,148,4,0,
96,12,156,4,0,
96,12,168,4,0,
96,12,182,4,0,
96,12,213,4,0,
96,12,216,4,0,
96,12,218,4,0,
96,12,219,4,0,
96,12,237,4,0,
96,12,240,4,0,
96,12,241,4,0,
96,12,244,1,43,
96,12,247,4,0,
96,12,248,1,45,
96,12,259,4,0,
96,12,263,4,0,
96,12,264,4,0,
96,12,269,4,0,
96,12,280,4,0,
96,12,285,4,0,
96,12,289,4,0,
96,12,290,4,0,
96,12,347,4,0,
96,13,1,1,1,1
96,13,29,1,25,
96,13,34,3,0,
96,13,38,3,0,
96,13,50,1,10,
96,13,69,3,0,
96,13,86,3,0,
96,13,92,4,0,
96,13,93,1,18,
96,13,94,1,40,
96,13,94,4,0,
96,13,95,1,1,2
96,13,96,1,36,
96,13,102,3,0,
96,13,104,4,0,
96,13,113,4,0,
96,13,115,4,0,
96,13,138,3,0,
96,13,139,1,31,
96,13,148,4,0,
96,13,156,4,0,
96,13,164,3,0,
96,13,168,4,0,
96,13,171,3,0,
96,13,182,4,0,
96,13,207,3,0,
96,13,213,4,0,
96,13,216,4,0,
96,13,218,4,0,
96,13,219,4,0,
96,13,237,4,0,
96,13,240,4,0,
96,13,241,4,0,
96,13,244,1,43,
96,13,247,4,0,
96,13,248,1,45,
96,13,259,4,0,
96,13,263,4,0,
96,13,264,4,0,
96,13,269,4,0,
96,13,280,4,0,
96,13,285,4,0,
96,13,289,4,0,
96,13,290,4,0,
96,13,347,4,0,
96,14,1,1,1,1
96,14,7,2,0,
96,14,7,3,0,
96,14,8,2,0,
96,14,8,3,0,
96,14,9,2,0,
96,14,9,3,0,
96,14,29,1,13,
96,14,29,1,29,
96,14,50,1,5,
96,14,60,1,25,
96,14,67,3,0,
96,14,86,4,0,
96,14,92,4,0,
96,14,93,1,9,
96,14,94,1,49,
96,14,94,4,0,
96,14,95,1,1,2
96,14,96,1,21,
96,14,104,4,0,
96,14,112,2,0,
96,14,113,4,0,
96,14,115,4,0,
96,14,138,4,0,
96,14,139,1,17,
96,14,148,4,0,
96,14,156,4,0,
96,14,164,4,0,
96,14,168,4,0,
96,14,182,4,0,
96,14,207,1,45,
96,14,207,4,0,
96,14,213,4,0,
96,14,214,3,0,
96,14,216,4,0,
96,14,218,4,0,
96,14,219,4,0,
96,14,237,4,0,
96,14,240,4,0,
96,14,241,4,0,
96,14,244,1,33,
96,14,244,4,0,
96,14,247,4,0,
96,14,248,1,61,
96,14,259,4,0,
96,14,260,2,0,
96,14,263,4,0,
96,14,269,4,0,
96,14,271,3,0,
96,14,272,2,0,
96,14,272,3,0,
96,14,274,2,0,
96,14,277,3,0,
96,14,278,3,0,
96,14,280,4,0,
96,14,285,2,0,
96,14,285,3,0,
96,14,289,3,0,
96,14,290,2,0,
96,14,324,3,0,
96,14,347,4,0,
96,14,374,4,0,
96,14,385,2,0,
96,14,409,3,0,
96,14,417,1,53,
96,14,417,2,0,
96,14,427,2,0,
96,14,428,1,41,
96,14,428,3,0,
96,14,433,4,0,
96,14,447,4,0,
96,14,473,1,57,
96,14,473,4,0,
96,14,477,4,0,
96,14,478,3,0,
96,14,485,1,37,
96,14,490,4,0,
96,14,492,3,0,
96,14,496,4,0,
96,15,1,1,1,1
96,15,7,2,0,
96,15,8,2,0,
96,15,9,2,0,
96,15,29,1,13,
96,15,29,1,29,
96,15,50,1,5,
96,15,60,1,25,
96,15,86,4,0,
96,15,92,4,0,
96,15,93,1,9,
96,15,94,1,49,
96,15,94,4,0,
96,15,95,1,1,2
96,15,96,1,21,
96,15,104,4,0,
96,15,112,2,0,
96,15,113,4,0,
96,15,115,4,0,
96,15,138,4,0,
96,15,139,1,17,
96,15,148,4,0,
96,15,156,4,0,
96,15,164,4,0,
96,15,168,4,0,
96,15,182,4,0,
96,15,207,1,45,
96,15,207,4,0,
96,15,213,4,0,
96,15,214,4,0,
96,15,216,4,0,
96,15,218,4,0,
96,15,219,4,0,
96,15,237,4,0,
96,15,240,4,0,
96,15,241,4,0,
96,15,244,1,33,
96,15,244,4,0,
96,15,247,4,0,
96,15,248,1,61,
96,15,259,4,0,
96,15,260,2,0,
96,15,263,4,0,
96,15,269,4,0,
96,15,272,2,0,
96,15,274,2,0,
96,15,280,4,0,
96,15,285,2,0,
96,15,290,2,0,
96,15,347,4,0,
96,15,374,4,0,
96,15,385,2,0,
96,15,417,1,53,
96,15,417,2,0,
96,15,427,2,0,
96,15,428,1,41,
96,15,433,4,0,
96,15,447,4,0,
96,15,473,1,57,
96,15,473,4,0,
96,15,485,1,37,
96,15,490,4,0,
96,15,496,4,0,
96,15,590,4,0,
96,15,605,4,0,
96,15,612,4,0,
96,16,1,1,1,1
96,16,7,2,0,
96,16,7,3,0,
96,16,8,2,0,
96,16,8,3,0,
96,16,9,2,0,
96,16,9,3,0,
96,16,29,1,13,1
96,16,29,1,29,1
96,16,50,1,5,1
96,16,60,1,25,1
96,16,67,3,0,
96,16,86,4,0,
96,16,92,4,0,
96,16,93,1,9,1
96,16,94,1,49,1
96,16,94,4,0,
96,16,95,1,1,2
96,16,96,1,21,1
96,16,104,4,0,
96,16,112,2,0,
96,16,113,4,0,
96,16,115,4,0,
96,16,138,4,0,
96,16,139,1,17,1
96,16,148,4,0,
96,16,156,4,0,
96,16,164,4,0,
96,16,168,4,0,
96,16,173,3,0,
96,16,182,4,0,
96,16,207,1,45,1
96,16,207,4,0,
96,16,213,4,0,
96,16,214,4,0,
96,16,216,4,0,
96,16,218,4,0,
96,16,219,4,0,
96,16,237,4,0,
96,16,240,4,0,
96,16,241,4,0,
96,16,244,1,33,1
96,16,244,4,0,
96,16,247,4,0,
96,16,248,1,61,1
96,16,259,4,0,
96,16,260,2,0,
96,16,263,4,0,
96,16,264,3,0,
96,16,269,4,0,
96,16,271,3,0,
96,16,272,2,0,
96,16,272,3,0,
96,16,274,2,0,
96,16,277,3,0,
96,16,278,3,0,
96,16,280,4,0,
96,16,285,2,0,
96,16,285,3,0,
96,16,289,3,0,
96,16,290,2,0,
96,16,290,4,0,
96,16,324,3,0,
96,16,347,4,0,
96,16,374,4,0,
96,16,385,2,0,
96,16,409,3,0,
96,16,417,1,53,1
96,16,417,2,0,
96,16,427,2,0,
96,16,428,1,41,1
96,16,428,3,0,
96,16,433,4,0,
96,16,447,4,0,
96,16,473,1,57,1
96,16,473,4,0,
96,16,478,3,0,
96,16,485,1,37,1
96,16,490,4,0,
96,16,492,3,0,
96,16,496,4,0,
96,16,590,4,0,
96,16,605,4,0,
96,16,612,4,0,
96,17,1,1,1,1
96,17,7,2,0,
96,17,8,2,0,
96,17,9,2,0,
96,17,29,1,13,
96,17,29,1,29,
96,17,50,1,5,
96,17,60,1,25,
96,17,86,4,0,
96,17,92,4,0,
96,17,93,1,9,
96,17,94,1,49,
96,17,94,4,0,
96,17,95,1,1,2
96,17,96,1,21,
96,17,104,4,0,
96,17,112,2,0,
96,17,113,4,0,
96,17,115,4,0,
96,17,138,4,0,
96,17,139,1,17,
96,17,156,4,0,
96,17,164,4,0,
96,17,168,4,0,
96,17,182,4,0,
96,17,207,1,45,
96,17,207,4,0,
96,17,213,4,0,
96,17,214,4,0,
96,17,216,4,0,
96,17,218,4,0,
96,17,219,4,0,
96,17,237,4,0,
96,17,240,4,0,
96,17,241,4,0,
96,17,244,1,33,
96,17,244,4,0,
96,17,247,4,0,
96,17,248,1,61,
96,17,259,4,0,
96,17,260,2,0,
96,17,263,4,0,
96,17,269,4,0,
96,17,272,2,0,
96,17,274,2,0,
96,17,280,4,0,
96,17,285,2,0,
96,17,290,2,0,
96,17,347,4,0,
96,17,374,4,0,
96,17,385,2,0,
96,17,417,1,53,
96,17,417,2,0,
96,17,427,2,0,
96,17,428,1,41,
96,17,433,4,0,
96,17,447,4,0,
96,17,473,1,57,
96,17,473,4,0,
96,17,485,1,37,
96,17,490,4,0,
96,17,496,4,0,
96,17,590,4,0,
96,17,605,4,0,
96,18,1,1,1,1
96,18,7,2,0,
96,18,8,2,0,
96,18,9,2,0,
96,18,29,1,13,
96,18,50,1,5,
96,18,60,1,25,
96,18,86,4,0,
96,18,92,4,0,
96,18,93,1,9,
96,18,94,1,49,
96,18,94,4,0,
96,18,95,1,1,2
96,18,96,1,21,
96,18,104,4,0,
96,18,112,2,0,
96,18,113,4,0,
96,18,115,4,0,
96,18,138,4,0,
96,18,139,1,17,
96,18,156,4,0,
96,18,164,4,0,
96,18,168,4,0,
96,18,182,4,0,
96,18,207,1,45,
96,18,207,4,0,
96,18,213,4,0,
96,18,214,4,0,
96,18,216,4,0,
96,18,218,4,0,
96,18,219,4,0,
96,18,237,4,0,
96,18,240,4,0,
96,18,241,4,0,
96,18,244,1,33,
96,18,244,4,0,
96,18,247,4,0,
96,18,248,1,61,
96,18,259,4,0,
96,18,260,2,0,
96,18,263,4,0,
96,18,269,4,0,
96,18,272,2,0,
96,18,274,2,0,
96,18,280,4,0,
96,18,285,2,0,
96,18,290,2,0,
96,18,347,4,0,
96,18,358,1,29,
96,18,374,4,0,
96,18,385,2,0,
96,18,417,1,53,
96,18,417,2,0,
96,18,427,2,0,
96,18,428,1,41,
96,18,433,4,0,
96,18,447,4,0,
96,18,471,2,0,
96,18,473,1,57,
96,18,473,4,0,
96,18,485,1,37,
96,18,490,4,0,
96,18,496,4,0,
96,18,590,4,0,
96,18,605,4,0,
96,18,678,2,0,
97,1,1,1,1,1
97,1,5,4,0,
97,1,25,4,0,
97,1,29,1,24,
97,1,34,4,0,
97,1,36,4,0,
97,1,38,4,0,
97,1,50,1,1,3
97,1,50,1,12,
97,1,63,4,0,
97,1,66,4,0,
97,1,68,4,0,
97,1,69,4,0,
97,1,86,4,0,
97,1,92,4,0,
97,1,93,1,1,4
97,1,93,1,17,
97,1,94,1,37,
97,1,94,4,0,
97,1,95,1,1,2
97,1,96,1,43,
97,1,99,4,0,
97,1,100,4,0,
97,1,102,4,0,
97,1,104,4,0,
97,1,115,4,0,
97,1,117,4,0,
97,1,118,4,0,
97,1,130,4,0,
97,1,138,4,0,
97,1,139,1,33,
97,1,148,4,0,
97,1,149,4,0,
97,1,156,4,0,
97,1,161,4,0,
97,1,164,4,0,
97,2,1,1,1,1
97,2,5,4,0,
97,2,25,4,0,
97,2,29,1,24,
97,2,34,4,0,
97,2,36,4,0,
97,2,38,4,0,
97,2,50,1,1,3
97,2,50,1,12,
97,2,63,4,0,
97,2,66,4,0,
97,2,68,4,0,
97,2,69,4,0,
97,2,86,4,0,
97,2,92,4,0,
97,2,93,1,1,4
97,2,93,1,17,
97,2,94,1,37,
97,2,94,4,0,
97,2,95,1,1,2
97,2,96,1,43,
97,2,99,4,0,
97,2,100,4,0,
97,2,102,4,0,
97,2,104,4,0,
97,2,115,4,0,
97,2,117,4,0,
97,2,118,4,0,
97,2,130,4,0,
97,2,138,4,0,
97,2,139,1,33,
97,2,148,4,0,
97,2,149,4,0,
97,2,156,4,0,
97,2,161,4,0,
97,2,164,4,0,
97,3,1,1,1,1
97,3,7,4,0,
97,3,8,4,0,
97,3,9,4,0,
97,3,29,1,25,
97,3,29,4,0,
97,3,50,1,1,3
97,3,50,1,10,
97,3,63,4,0,
97,3,92,4,0,
97,3,93,1,1,4
97,3,93,1,18,
97,3,94,1,49,
97,3,94,4,0,
97,3,95,1,1,2
97,3,96,1,40,
97,3,104,4,0,
97,3,138,4,0,
97,3,139,1,33,
97,3,148,4,0,
97,3,156,4,0,
97,3,171,4,0,
97,3,173,4,0,
97,3,174,4,0,
97,3,182,4,0,
97,3,192,4,0,
97,3,203,4,0,
97,3,207,4,0,
97,3,213,4,0,
97,3,214,4,0,
97,3,216,4,0,
97,3,218,4,0,
97,3,223,4,0,
97,3,237,4,0,
97,3,240,4,0,
97,3,241,4,0,
97,3,244,1,55,
97,3,244,4,0,
97,3,247,4,0,
97,3,248,1,60,
97,4,1,1,1,1
97,4,7,4,0,
97,4,8,4,0,
97,4,9,4,0,
97,4,29,1,25,
97,4,29,4,0,
97,4,50,1,1,3
97,4,50,1,10,
97,4,63,4,0,
97,4,92,4,0,
97,4,93,1,1,4
97,4,93,1,18,
97,4,94,1,49,
97,4,94,4,0,
97,4,95,1,1,2
97,4,96,1,40,
97,4,104,4,0,
97,4,138,4,0,
97,4,139,1,33,
97,4,148,4,0,
97,4,156,4,0,
97,4,171,4,0,
97,4,173,4,0,
97,4,174,4,0,
97,4,182,4,0,
97,4,192,4,0,
97,4,203,4,0,
97,4,207,4,0,
97,4,213,4,0,
97,4,214,4,0,
97,4,216,4,0,
97,4,218,4,0,
97,4,223,4,0,
97,4,237,4,0,
97,4,240,4,0,
97,4,241,4,0,
97,4,244,1,55,
97,4,244,4,0,
97,4,247,4,0,
97,4,248,1,60,
97,5,1,1,1,1
97,5,29,1,25,
97,5,50,1,1,3
97,5,50,1,10,
97,5,63,4,0,
97,5,92,4,0,
97,5,93,1,1,4
97,5,93,1,18,
97,5,94,1,49,
97,5,94,4,0,
97,5,95,1,1,2
97,5,96,1,40,
97,5,104,4,0,
97,5,113,4,0,
97,5,115,4,0,
97,5,139,1,33,
97,5,148,4,0,
97,5,156,4,0,
97,5,168,4,0,
97,5,182,4,0,
97,5,213,4,0,
97,5,216,4,0,
97,5,218,4,0,
97,5,219,4,0,
97,5,237,4,0,
97,5,240,4,0,
97,5,241,4,0,
97,5,244,1,55,
97,5,247,4,0,
97,5,248,1,60,
97,5,259,4,0,
97,5,263,4,0,
97,5,264,4,0,
97,5,269,4,0,
97,5,280,4,0,
97,5,285,4,0,
97,5,289,4,0,
97,5,290,4,0,
97,5,347,4,0,
97,6,1,1,1,1
97,6,5,3,0,
97,6,7,3,0,
97,6,8,3,0,
97,6,9,3,0,
97,6,25,3,0,
97,6,29,1,25,
97,6,34,3,0,
97,6,38,3,0,
97,6,50,1,1,3
97,6,50,1,10,
97,6,63,4,0,
97,6,68,3,0,
97,6,69,3,0,
97,6,86,3,0,
97,6,92,4,0,
97,6,93,1,1,4
97,6,93,1,18,
97,6,94,1,49,
97,6,94,4,0,
97,6,95,1,1,2
97,6,96,1,40,
97,6,102,3,0,
97,6,104,4,0,
97,6,113,4,0,
97,6,115,4,0,
97,6,118,3,0,
97,6,138,3,0,
97,6,139,1,33,
97,6,148,4,0,
97,6,156,4,0,
97,6,164,3,0,
97,6,168,4,0,
97,6,173,3,0,
97,6,182,4,0,
97,6,203,3,0,
97,6,207,3,0,
97,6,213,4,0,
97,6,214,3,0,
97,6,216,4,0,
97,6,218,4,0,
97,6,219,4,0,
97,6,223,3,0,
97,6,237,4,0,
97,6,240,4,0,
97,6,241,4,0,
97,6,244,1,55,
97,6,244,3,0,
97,6,247,4,0,
97,6,248,1,60,
97,6,259,4,0,
97,6,263,4,0,
97,6,264,4,0,
97,6,269,4,0,
97,6,280,4,0,
97,6,285,4,0,
97,6,289,4,0,
97,6,290,4,0,
97,6,347,4,0,
97,7,1,1,1,2
97,7,5,3,0,
97,7,25,3,0,
97,7,29,1,17,
97,7,34,3,0,
97,7,38,3,0,
97,7,50,1,1,4
97,7,50,1,7,
97,7,63,4,0,
97,7,68,3,0,
97,7,69,3,0,
97,7,86,3,0,
97,7,92,4,0,
97,7,93,1,1,5
97,7,93,1,11,
97,7,94,1,35,
97,7,94,4,0,
97,7,95,1,1,3
97,7,96,1,29,
97,7,102,3,0,
97,7,104,4,0,
97,7,113,4,0,
97,7,115,4,0,
97,7,118,3,0,
97,7,138,3,0,
97,7,139,1,21,
97,7,148,4,0,
97,7,156,4,0,
97,7,164,3,0,
97,7,168,4,0,
97,7,171,1,1,1
97,7,182,4,0,
97,7,207,1,49,
97,7,213,4,0,
97,7,216,4,0,
97,7,218,4,0,
97,7,219,4,0,
97,7,237,4,0,
97,7,240,4,0,
97,7,241,4,0,
97,7,244,1,43,
97,7,247,4,0,
97,7,248,1,57,
97,7,259,4,0,
97,7,263,4,0,
97,7,264,4,0,
97,7,269,4,0,
97,7,280,4,0,
97,7,285,4,0,
97,7,289,4,0,
97,7,290,4,0,
97,7,347,4,0,
97,8,1,1,1,3
97,8,29,1,15,
97,8,29,1,38,
97,8,50,1,1,5
97,8,50,1,7,
97,8,60,1,28,
97,8,63,4,0,
97,8,86,4,0,
97,8,92,4,0,
97,8,93,1,1,6
97,8,93,1,9,
97,8,94,1,50,
97,8,94,4,0,
97,8,95,1,1,4
97,8,96,1,21,
97,8,104,4,0,
97,8,113,4,0,
97,8,115,4,0,
97,8,138,4,0,
97,8,139,1,18,
97,8,148,4,0,
97,8,156,4,0,
97,8,164,4,0,
97,8,168,4,0,
97,8,171,1,1,1
97,8,182,4,0,
97,8,203,4,0,
97,8,207,1,45,
97,8,207,4,0,
97,8,213,4,0,
97,8,214,4,0,
97,8,216,4,0,
97,8,218,4,0,
97,8,219,4,0,
97,8,237,4,0,
97,8,240,4,0,
97,8,241,4,0,
97,8,244,1,33,
97,8,244,4,0,
97,8,247,4,0,
97,8,248,1,69,
97,8,259,4,0,
97,8,263,4,0,
97,8,264,4,0,
97,8,269,4,0,
97,8,278,4,0,
97,8,280,4,0,
97,8,285,4,0,
97,8,289,4,0,
97,8,290,4,0,
97,8,347,4,0,
97,8,363,4,0,
97,8,374,4,0,
97,8,409,4,0,
97,8,411,4,0,
97,8,415,1,1,2
97,8,416,4,0,
97,8,417,1,55,
97,8,428,1,64,
97,8,433,4,0,
97,8,445,4,0,
97,8,447,4,0,
97,9,1,1,1,3
97,9,7,3,0,
97,9,8,3,0,
97,9,9,3,0,
97,9,29,1,15,
97,9,29,1,38,
97,9,50,1,1,5
97,9,50,1,7,
97,9,60,1,28,
97,9,63,4,0,
97,9,86,4,0,
97,9,92,4,0,
97,9,93,1,1,6
97,9,93,1,9,
97,9,94,1,50,
97,9,94,4,0,
97,9,95,1,1,4
97,9,96,1,21,
97,9,104,4,0,
97,9,113,4,0,
97,9,115,4,0,
97,9,138,4,0,
97,9,139,1,18,
97,9,148,4,0,
97,9,156,4,0,
97,9,164,4,0,
97,9,168,4,0,
97,9,171,1,1,1
97,9,182,4,0,
97,9,203,4,0,
97,9,207,1,45,
97,9,207,4,0,
97,9,213,4,0,
97,9,214,4,0,
97,9,216,4,0,
97,9,218,4,0,
97,9,219,4,0,
97,9,237,4,0,
97,9,240,4,0,
97,9,241,4,0,
97,9,244,1,33,
97,9,244,4,0,
97,9,247,4,0,
97,9,248,1,69,
97,9,259,4,0,
97,9,263,4,0,
97,9,264,4,0,
97,9,269,4,0,
97,9,271,3,0,
97,9,278,4,0,
97,9,280,4,0,
97,9,285,4,0,
97,9,289,4,0,
97,9,290,4,0,
97,9,324,3,0,
97,9,347,4,0,
97,9,363,4,0,
97,9,374,4,0,
97,9,409,4,0,
97,9,411,4,0,
97,9,415,1,1,2
97,9,416,4,0,
97,9,417,1,55,
97,9,428,1,64,
97,9,428,3,0,
97,9,433,4,0,
97,9,445,4,0,
97,9,447,4,0,
97,10,1,1,1,3
97,10,7,3,0,
97,10,8,3,0,
97,10,9,3,0,
97,10,29,1,15,
97,10,29,1,38,
97,10,29,3,0,
97,10,50,1,1,5
97,10,50,1,7,
97,10,60,1,28,
97,10,63,4,0,
97,10,67,3,0,
97,10,86,4,0,
97,10,92,4,0,
97,10,93,1,1,6
97,10,93,1,9,
97,10,94,1,50,
97,10,94,4,0,
97,10,95,1,1,4
97,10,96,1,21,
97,10,104,4,0,
97,10,113,4,0,
97,10,115,4,0,
97,10,138,4,0,
97,10,139,1,18,
97,10,148,4,0,
97,10,156,4,0,
97,10,164,4,0,
97,10,168,4,0,
97,10,171,1,1,1
97,10,182,4,0,
97,10,203,4,0,
97,10,207,1,45,
97,10,207,4,0,
97,10,213,4,0,
97,10,214,4,0,
97,10,216,4,0,
97,10,218,4,0,
97,10,219,4,0,
97,10,237,4,0,
97,10,240,4,0,
97,10,241,4,0,
97,10,244,1,33,
97,10,244,4,0,
97,10,247,4,0,
97,10,248,1,69,
97,10,259,4,0,
97,10,263,4,0,
97,10,264,4,0,
97,10,269,4,0,
97,10,271,3,0,
97,10,272,3,0,
97,10,277,3,0,
97,10,278,4,0,
97,10,280,4,0,
97,10,285,4,0,
97,10,289,4,0,
97,10,290,4,0,
97,10,324,3,0,
97,10,347,4,0,
97,10,363,4,0,
97,10,374,4,0,
97,10,409,4,0,
97,10,411,4,0,
97,10,415,1,1,2
97,10,416,4,0,
97,10,417,1,55,
97,10,428,1,64,
97,10,428,3,0,
97,10,433,4,0,
97,10,445,4,0,
97,10,447,4,0,
97,11,1,1,1,3
97,11,29,1,13,
97,11,29,1,29,
97,11,50,1,1,5
97,11,50,1,5,
97,11,60,1,25,
97,11,63,4,0,
97,11,86,4,0,
97,11,92,4,0,
97,11,93,1,1,6
97,11,93,1,9,
97,11,94,1,49,
97,11,94,4,0,
97,11,95,1,1,4
97,11,96,1,21,
97,11,104,4,0,
97,11,113,4,0,
97,11,115,4,0,
97,11,138,4,0,
97,11,139,1,17,
97,11,148,4,0,
97,11,156,4,0,
97,11,164,4,0,
97,11,168,4,0,
97,11,171,1,1,1
97,11,182,4,0,
97,11,207,1,45,
97,11,207,4,0,
97,11,213,4,0,
97,11,216,4,0,
97,11,218,4,0,
97,11,219,4,0,
97,11,237,4,0,
97,11,240,4,0,
97,11,241,4,0,
97,11,244,1,33,
97,11,244,4,0,
97,11,247,4,0,
97,11,248,1,61,
97,11,259,4,0,
97,11,263,4,0,
97,11,269,4,0,
97,11,280,4,0,
97,11,347,4,0,
97,11,374,4,0,
97,11,411,4,0,
97,11,415,1,1,2
97,11,416,4,0,
97,11,417,1,53,
97,11,428,1,41,
97,11,433,4,0,
97,11,447,4,0,
97,11,473,1,57,
97,11,473,4,0,
97,11,477,4,0,
97,11,485,1,37,
97,11,490,4,0,
97,11,496,4,0,
97,12,1,1,1,1
97,12,29,1,25,
97,12,50,1,1,3
97,12,50,1,10,
97,12,63,4,0,
97,12,92,4,0,
97,12,93,1,1,4
97,12,93,1,18,
97,12,94,1,49,
97,12,94,4,0,
97,12,95,1,1,2
97,12,96,1,40,
97,12,104,4,0,
97,12,113,4,0,
97,12,115,4,0,
97,12,139,1,33,
97,12,148,4,0,
97,12,156,4,0,
97,12,168,4,0,
97,12,182,4,0,
97,12,213,4,0,
97,12,216,4,0,
97,12,218,4,0,
97,12,219,4,0,
97,12,237,4,0,
97,12,240,4,0,
97,12,241,4,0,
97,12,244,1,55,
97,12,247,4,0,
97,12,248,1,60,
97,12,259,4,0,
97,12,263,4,0,
97,12,264,4,0,
97,12,269,4,0,
97,12,280,4,0,
97,12,285,4,0,
97,12,289,4,0,
97,12,290,4,0,
97,12,347,4,0,
97,13,1,1,1,1
97,13,29,1,25,
97,13,34,3,0,
97,13,38,3,0,
97,13,50,1,1,3
97,13,50,1,10,
97,13,63,4,0,
97,13,69,3,0,
97,13,86,3,0,
97,13,92,4,0,
97,13,93,1,1,4
97,13,93,1,18,
97,13,94,1,49,
97,13,94,4,0,
97,13,95,1,1,2
97,13,96,1,40,
97,13,102,3,0,
97,13,104,4,0,
97,13,113,4,0,
97,13,115,4,0,
97,13,138,3,0,
97,13,139,1,33,
97,13,148,4,0,
97,13,156,4,0,
97,13,164,3,0,
97,13,168,4,0,
97,13,171,3,0,
97,13,182,4,0,
97,13,207,3,0,
97,13,213,4,0,
97,13,216,4,0,
97,13,218,4,0,
97,13,219,4,0,
97,13,237,4,0,
97,13,240,4,0,
97,13,241,4,0,
97,13,244,1,55,
97,13,247,4,0,
97,13,248,1,60,
97,13,259,4,0,
97,13,263,4,0,
97,13,264,4,0,
97,13,269,4,0,
97,13,280,4,0,
97,13,285,4,0,
97,13,289,4,0,
97,13,290,4,0,
97,13,347,4,0,
97,14,1,1,1,3
97,14,7,3,0,
97,14,8,3,0,
97,14,9,3,0,
97,14,29,1,13,
97,14,29,1,29,
97,14,50,1,1,5
97,14,50,1,5,
97,14,60,1,25,
97,14,63,4,0,
97,14,67,3,0,
97,14,86,4,0,
97,14,92,4,0,
97,14,93,1,1,6
97,14,93,1,9,
97,14,94,1,49,
97,14,94,4,0,
97,14,95,1,1,4
97,14,96,1,21,
97,14,104,4,0,
97,14,113,4,0,
97,14,115,4,0,
97,14,138,4,0,
97,14,139,1,17,
97,14,148,4,0,
97,14,156,4,0,
97,14,164,4,0,
97,14,168,4,0,
97,14,171,1,1,1
97,14,182,4,0,
97,14,207,1,45,
97,14,207,4,0,
97,14,213,4,0,
97,14,214,3,0,
97,14,216,4,0,
97,14,218,4,0,
97,14,219,4,0,
97,14,237,4,0,
97,14,240,4,0,
97,14,241,4,0,
97,14,244,1,33,
97,14,244,4,0,
97,14,247,4,0,
97,14,248,1,61,
97,14,259,4,0,
97,14,263,4,0,
97,14,269,4,0,
97,14,271,3,0,
97,14,272,3,0,
97,14,277,3,0,
97,14,278,3,0,
97,14,280,4,0,
97,14,285,3,0,
97,14,289,3,0,
97,14,324,3,0,
97,14,347,4,0,
97,14,374,4,0,
97,14,409,3,0,
97,14,411,4,0,
97,14,415,1,1,2
97,14,416,4,0,
97,14,417,1,53,
97,14,428,1,41,
97,14,428,3,0,
97,14,433,4,0,
97,14,447,4,0,
97,14,473,1,57,
97,14,473,4,0,
97,14,477,4,0,
97,14,478,3,0,
97,14,485,1,37,
97,14,490,4,0,
97,14,492,3,0,
97,14,496,4,0,
97,15,1,1,1,5
97,15,29,1,13,
97,15,29,1,29,
97,15,50,1,1,7
97,15,50,1,5,
97,15,60,1,25,
97,15,63,4,0,
97,15,86,4,0,
97,15,92,4,0,
97,15,93,1,1,8
97,15,93,1,9,
97,15,94,1,49,
97,15,94,4,0,
97,15,95,1,1,6
97,15,96,1,21,
97,15,104,4,0,
97,15,113,4,0,
97,15,115,4,0,
97,15,138,4,0,
97,15,139,1,17,
97,15,148,4,0,
97,15,156,4,0,
97,15,164,4,0,
97,15,168,4,0,
97,15,171,1,1,3
97,15,182,4,0,
97,15,207,1,45,
97,15,207,4,0,
97,15,213,4,0,
97,15,214,4,0,
97,15,216,4,0,
97,15,218,4,0,
97,15,219,4,0,
97,15,237,4,0,
97,15,240,4,0,
97,15,241,4,0,
97,15,244,1,33,
97,15,244,4,0,
97,15,247,4,0,
97,15,248,1,1,1
97,15,248,1,61,
97,15,259,4,0,
97,15,263,4,0,
97,15,269,4,0,
97,15,280,4,0,
97,15,347,4,0,
97,15,374,4,0,
97,15,411,4,0,
97,15,415,1,1,4
97,15,416,4,0,
97,15,417,1,1,2
97,15,417,1,53,
97,15,428,1,41,
97,15,433,4,0,
97,15,447,4,0,
97,15,473,1,57,
97,15,473,4,0,
97,15,485,1,37,
97,15,490,4,0,
97,15,496,4,0,
97,15,590,4,0,
97,15,605,4,0,
97,15,612,4,0,
97,16,1,1,1,5
97,16,7,3,0,
97,16,8,3,0,
97,16,9,3,0,
97,16,29,1,13,1
97,16,29,1,29,1
97,16,50,1,1,7
97,16,50,1,5,1
97,16,60,1,25,1
97,16,63,4,0,
97,16,67,3,0,
97,16,86,4,0,
97,16,92,4,0,
97,16,93,1,1,8
97,16,93,1,9,1
97,16,94,1,49,1
97,16,94,4,0,
97,16,95,1,1,6
97,16,96,1,21,1
97,16,104,4,0,
97,16,113,4,0,
97,16,115,4,0,
97,16,138,4,0,
97,16,139,1,17,1
97,16,148,4,0,
97,16,156,4,0,
97,16,164,4,0,
97,16,168,4,0,
97,16,171,1,1,3
97,16,173,3,0,
97,16,182,4,0,
97,16,207,1,45,1
97,16,207,4,0,
97,16,213,4,0,
97,16,214,4,0,
97,16,216,4,0,
97,16,218,4,0,
97,16,219,4,0,
97,16,237,4,0,
97,16,240,4,0,
97,16,241,4,0,
97,16,244,1,33,1
97,16,244,4,0,
97,16,247,4,0,
97,16,248,1,1,1
97,16,248,1,61,1
97,16,259,4,0,
97,16,263,4,0,
97,16,264,3,0,
97,16,269,4,0,
97,16,271,3,0,
97,16,272,3,0,
97,16,277,3,0,
97,16,278,3,0,
97,16,280,4,0,
97,16,285,3,0,
97,16,289,3,0,
97,16,290,4,0,
97,16,324,3,0,
97,16,347,4,0,
97,16,374,4,0,
97,16,409,3,0,
97,16,411,4,0,
97,16,415,1,1,4
97,16,416,4,0,
97,16,417,1,1,2
97,16,417,1,53,1
97,16,428,1,41,1
97,16,428,3,0,
97,16,433,4,0,
97,16,447,4,0,
97,16,473,1,57,1
97,16,473,4,0,
97,16,478,3,0,
97,16,485,1,37,1
97,16,490,4,0,
97,16,492,3,0,
97,16,496,4,0,
97,16,590,4,0,
97,16,605,4,0,
97,16,612,4,0,
97,17,1,1,1,5
97,17,29,1,13,
97,17,29,1,29,
97,17,50,1,1,7
97,17,50,1,5,
97,17,60,1,25,
97,17,63,4,0,
97,17,86,4,0,
97,17,92,4,0,
97,17,93,1,1,8
97,17,93,1,9,
97,17,94,1,49,
97,17,94,4,0,
97,17,95,1,1,6
97,17,96,1,21,
97,17,104,4,0,
97,17,113,4,0,
97,17,115,4,0,
97,17,138,4,0,
97,17,139,1,17,
97,17,156,4,0,
97,17,164,4,0,
97,17,168,4,0,
97,17,171,1,1,3
97,17,182,4,0,
97,17,207,1,45,
97,17,207,4,0,
97,17,213,4,0,
97,17,214,4,0,
97,17,216,4,0,
97,17,218,4,0,
97,17,219,4,0,
97,17,237,4,0,
97,17,240,4,0,
97,17,241,4,0,
97,17,244,1,33,
97,17,244,4,0,
97,17,247,4,0,
97,17,248,1,1,1
97,17,248,1,61,
97,17,259,4,0,
97,17,263,4,0,
97,17,269,4,0,
97,17,280,4,0,
97,17,347,4,0,
97,17,374,4,0,
97,17,411,4,0,
97,17,415,1,1,4
97,17,416,4,0,
97,17,417,1,1,2
97,17,417,1,53,
97,17,428,1,41,
97,17,433,4,0,
97,17,447,4,0,
97,17,473,1,57,
97,17,473,4,0,
97,17,485,1,37,
97,17,490,4,0,
97,17,496,4,0,
97,17,590,4,0,
97,17,605,4,0,
97,18,1,1,1,5
97,18,29,1,13,
97,18,50,1,1,7
97,18,50,1,5,
97,18,60,1,25,
97,18,63,4,0,
97,18,86,4,0,
97,18,92,4,0,
97,18,93,1,1,8
97,18,93,1,9,
97,18,94,1,49,
97,18,94,4,0,
97,18,95,1,1,6
97,18,96,1,21,
97,18,104,4,0,
97,18,113,4,0,
97,18,115,4,0,
97,18,138,4,0,
97,18,139,1,17,
97,18,156,4,0,
97,18,164,4,0,
97,18,168,4,0,
97,18,171,1,1,3
97,18,182,4,0,
97,18,207,1,45,
97,18,207,4,0,
97,18,213,4,0,
97,18,214,4,0,
97,18,216,4,0,
97,18,218,4,0,
97,18,219,4,0,
97,18,237,4,0,
97,18,240,4,0,
97,18,241,4,0,
97,18,244,1,33,
97,18,244,4,0,
97,18,247,4,0,
97,18,248,1,1,1
97,18,248,1,61,
97,18,259,4,0,
97,18,263,4,0,
97,18,269,4,0,
97,18,280,4,0,
97,18,347,4,0,
97,18,358,1,29,
97,18,374,4,0,
97,18,411,4,0,
97,18,415,1,1,4
97,18,416,4,0,
97,18,417,1,1,2
97,18,417,1,53,
97,18,428,1,41,
97,18,433,4,0,
97,18,447,4,0,
97,18,473,1,57,
97,18,473,4,0,
97,18,485,1,37,
97,18,490,4,0,
97,18,496,4,0,
97,18,590,4,0,
97,18,605,4,0,
98,1,11,1,20,
98,1,12,1,25,
98,1,14,4,0,
98,1,15,4,0,
98,1,23,1,30,
98,1,34,4,0,
98,1,36,4,0,
98,1,38,4,0,
98,1,43,1,1,2
98,1,55,4,0,
98,1,57,4,0,
98,1,58,4,0,
98,1,59,4,0,
98,1,61,4,0,
98,1,70,4,0,
98,1,92,4,0,
98,1,99,4,0,
98,1,102,4,0,
98,1,104,4,0,
98,1,106,1,40,
98,1,117,4,0,
98,1,145,1,1,1
98,1,152,1,35,
98,1,156,4,0,
98,1,164,4,0,
98,2,11,1,20,
98,2,12,1,25,
98,2,14,4,0,
98,2,15,4,0,
98,2,23,1,30,
98,2,34,4,0,
98,2,36,4,0,
98,2,38,4,0,
98,2,43,1,1,2
98,2,55,4,0,
98,2,57,4,0,
98,2,58,4,0,
98,2,59,4,0,
98,2,61,4,0,
98,2,70,4,0,
98,2,92,4,0,
98,2,99,4,0,
98,2,102,4,0,
98,2,104,4,0,
98,2,106,1,40,
98,2,117,4,0,
98,2,145,1,1,1
98,2,152,1,35,
98,2,156,4,0,
98,2,164,4,0,
98,3,11,1,12,
98,3,12,1,27,
98,3,15,4,0,
98,3,21,2,0,
98,3,23,1,23,
98,3,43,1,5,
98,3,57,4,0,
98,3,59,4,0,
98,3,70,4,0,
98,3,91,2,0,
98,3,92,4,0,
98,3,104,4,0,
98,3,106,1,16,
98,3,114,2,0,
98,3,133,2,0,
98,3,145,1,1,
98,3,152,1,41,
98,3,156,4,0,
98,3,168,4,0,
98,3,173,4,0,
98,3,174,4,0,
98,3,175,2,0,
98,3,182,1,34,
98,3,182,4,0,
98,3,189,4,0,
98,3,196,4,0,
98,3,203,4,0,
98,3,207,4,0,
98,3,210,4,0,
98,3,213,4,0,
98,3,214,4,0,
98,3,216,4,0,
98,3,218,4,0,
98,3,237,4,0,
98,3,240,4,0,
98,3,249,4,0,
98,3,250,4,0,
98,4,11,1,12,
98,4,12,1,27,
98,4,15,4,0,
98,4,21,2,0,
98,4,23,1,23,
98,4,43,1,5,
98,4,57,4,0,
98,4,58,3,0,
98,4,59,4,0,
98,4,70,4,0,
98,4,91,2,0,
98,4,92,4,0,
98,4,104,4,0,
98,4,106,1,16,
98,4,114,2,0,
98,4,133,2,0,
98,4,145,1,1,
98,4,152,1,41,
98,4,156,4,0,
98,4,168,4,0,
98,4,173,4,0,
98,4,174,4,0,
98,4,175,2,0,
98,4,182,1,34,
98,4,182,4,0,
98,4,189,4,0,
98,4,196,4,0,
98,4,203,4,0,
98,4,207,4,0,
98,4,210,4,0,
98,4,213,4,0,
98,4,214,4,0,
98,4,216,4,0,
98,4,218,4,0,
98,4,237,4,0,
98,4,240,4,0,
98,4,249,4,0,
98,4,250,4,0,
98,5,11,1,12,
98,5,12,1,34,
98,5,14,2,0,
98,5,15,4,0,
98,5,21,2,0,
98,5,23,1,27,
98,5,43,1,5,
98,5,57,4,0,
98,5,58,4,0,
98,5,59,4,0,
98,5,70,4,0,
98,5,91,2,0,
98,5,91,4,0,
98,5,92,4,0,
98,5,104,4,0,
98,5,106,1,16,
98,5,114,2,0,
98,5,133,2,0,
98,5,145,1,1,
98,5,152,1,45,
98,5,156,4,0,
98,5,168,4,0,
98,5,175,2,0,
98,5,182,1,41,
98,5,182,4,0,
98,5,213,4,0,
98,5,216,4,0,
98,5,218,4,0,
98,5,237,4,0,
98,5,240,4,0,
98,5,249,4,0,
98,5,258,4,0,
98,5,263,4,0,
98,5,282,2,0,
98,5,290,4,0,
98,5,291,4,0,
98,5,317,4,0,
98,5,341,1,23,
98,5,352,4,0,
98,6,11,1,12,
98,6,12,1,34,
98,6,14,2,0,
98,6,14,3,0,
98,6,15,4,0,
98,6,21,2,0,
98,6,23,1,27,
98,6,34,3,0,
98,6,38,3,0,
98,6,43,1,5,
98,6,57,4,0,
98,6,58,4,0,
98,6,59,4,0,
98,6,70,4,0,
98,6,91,2,0,
98,6,91,4,0,
98,6,92,4,0,
98,6,102,3,0,
98,6,104,4,0,
98,6,106,1,16,
98,6,114,2,0,
98,6,133,2,0,
98,6,145,1,1,
98,6,152,1,45,
98,6,156,4,0,
98,6,164,3,0,
98,6,168,4,0,
98,6,173,3,0,
98,6,175,2,0,
98,6,182,1,41,
98,6,182,4,0,
98,6,189,3,0,
98,6,196,3,0,
98,6,203,3,0,
98,6,207,3,0,
98,6,210,3,0,
98,6,213,4,0,
98,6,214,3,0,
98,6,216,4,0,
98,6,218,4,0,
98,6,237,4,0,
98,6,240,4,0,
98,6,249,4,0,
98,6,258,4,0,
98,6,263,4,0,
98,6,282,2,0,
98,6,290,4,0,
98,6,291,4,0,
98,6,317,4,0,
98,6,341,1,23,
98,6,352,4,0,
98,7,11,1,12,
98,7,12,1,34,
98,7,14,2,0,
98,7,14,3,0,
98,7,15,4,0,
98,7,21,2,0,
98,7,23,1,27,
98,7,34,3,0,
98,7,38,3,0,
98,7,43,1,5,
98,7,57,4,0,
98,7,58,4,0,
98,7,59,4,0,
98,7,70,4,0,
98,7,91,2,0,
98,7,91,4,0,
98,7,92,4,0,
98,7,102,3,0,
98,7,104,4,0,
98,7,106,1,16,
98,7,114,2,0,
98,7,133,2,0,
98,7,145,1,1,
98,7,152,1,45,
98,7,156,4,0,
98,7,164,3,0,
98,7,168,4,0,
98,7,175,1,49,
98,7,175,2,0,
98,7,182,1,38,
98,7,182,4,0,
98,7,213,4,0,
98,7,216,4,0,
98,7,218,4,0,
98,7,237,4,0,
98,7,240,4,0,
98,7,249,4,0,
98,7,258,4,0,
98,7,263,4,0,
98,7,282,2,0,
98,7,290,4,0,
98,7,291,4,0,
98,7,317,4,0,
98,7,341,1,23,
98,7,352,4,0,
98,8,11,1,5,
98,8,12,1,31,
98,8,14,2,0,
98,8,14,4,0,
98,8,15,4,0,
98,8,21,1,35,
98,8,21,2,0,
98,8,23,1,25,
98,8,43,1,9,
98,8,57,4,0,
98,8,58,4,0,
98,8,59,4,0,
98,8,61,1,15,
98,8,70,4,0,
98,8,91,2,0,
98,8,91,4,0,
98,8,92,4,0,
98,8,104,4,0,
98,8,106,1,11,
98,8,114,2,0,
98,8,133,2,0,
98,8,145,1,1,2
98,8,152,1,41,
98,8,156,4,0,
98,8,157,4,0,
98,8,164,4,0,
98,8,168,4,0,
98,8,175,1,45,
98,8,175,2,0,
98,8,182,1,29,
98,8,182,4,0,
98,8,203,4,0,
98,8,206,4,0,
98,8,207,4,0,
98,8,213,4,0,
98,8,214,4,0,
98,8,216,4,0,
98,8,218,4,0,
98,8,232,1,21,
98,8,237,4,0,
98,8,240,4,0,
98,8,246,2,0,
98,8,249,4,0,
98,8,258,4,0,
98,8,263,4,0,
98,8,280,4,0,
98,8,282,2,0,
98,8,290,4,0,
98,8,300,1,1,1
98,8,317,4,0,
98,8,321,2,0,
98,8,341,1,19,
98,8,352,4,0,
98,8,362,1,39,
98,8,362,4,0,
98,8,363,4,0,
98,8,374,4,0,
98,8,404,4,0,
98,8,445,4,0,
98,9,11,1,5,
98,9,12,1,31,
98,9,14,2,0,
98,9,14,4,0,
98,9,15,4,0,
98,9,21,1,35,
98,9,21,2,0,
98,9,23,1,25,
98,9,43,1,9,
98,9,57,4,0,
98,9,58,4,0,
98,9,59,4,0,
98,9,61,1,15,
98,9,70,4,0,
98,9,91,2,0,
98,9,91,4,0,
98,9,92,4,0,
98,9,104,4,0,
98,9,106,1,11,
98,9,114,2,0,
98,9,133,2,0,
98,9,145,1,1,2
98,9,152,1,41,
98,9,156,4,0,
98,9,157,4,0,
98,9,164,4,0,
98,9,168,4,0,
98,9,173,3,0,
98,9,175,1,45,
98,9,175,2,0,
98,9,182,1,29,
98,9,182,4,0,
98,9,189,3,0,
98,9,196,3,0,
98,9,203,4,0,
98,9,206,4,0,
98,9,207,4,0,
98,9,210,3,0,
98,9,213,4,0,
98,9,214,4,0,
98,9,216,4,0,
98,9,218,4,0,
98,9,232,1,21,
98,9,237,4,0,
98,9,240,4,0,
98,9,246,2,0,
98,9,246,3,0,
98,9,249,4,0,
98,9,258,4,0,
98,9,263,4,0,
98,9,276,3,0,
98,9,280,4,0,
98,9,282,2,0,
98,9,282,3,0,
98,9,290,4,0,
98,9,291,3,0,
98,9,300,1,1,1
98,9,317,4,0,
98,9,321,2,0,
98,9,334,3,0,
98,9,341,1,19,
98,9,352,4,0,
98,9,362,1,39,
98,9,362,4,0,
98,9,363,4,0,
98,9,374,4,0,
98,9,404,4,0,
98,9,445,4,0,
98,10,11,1,5,
98,10,12,1,31,
98,10,14,2,0,
98,10,14,4,0,
98,10,15,4,0,
98,10,21,1,35,
98,10,21,2,0,
98,10,23,1,25,
98,10,43,1,9,
98,10,57,4,0,
98,10,58,4,0,
98,10,59,4,0,
98,10,61,1,15,
98,10,70,4,0,
98,10,91,2,0,
98,10,91,4,0,
98,10,92,4,0,
98,10,97,2,0,
98,10,104,4,0,
98,10,106,1,11,
98,10,114,2,0,
98,10,133,2,0,
98,10,145,1,1,2
98,10,152,1,41,
98,10,156,4,0,
98,10,157,4,0,
98,10,164,4,0,
98,10,168,4,0,
98,10,173,3,0,
98,10,175,1,45,
98,10,175,2,0,
98,10,182,1,29,
98,10,182,4,0,
98,10,189,3,0,
98,10,196,3,0,
98,10,203,4,0,
98,10,206,4,0,
98,10,207,4,0,
98,10,210,3,0,
98,10,213,4,0,
98,10,214,4,0,
98,10,216,4,0,
98,10,218,4,0,
98,10,232,1,21,
98,10,237,4,0,
98,10,240,4,0,
98,10,246,2,0,
98,10,246,3,0,
98,10,249,4,0,
98,10,250,4,0,
98,10,258,4,0,
98,10,263,4,0,
98,10,276,3,0,
98,10,280,4,0,
98,10,282,2,0,
98,10,282,3,0,
98,10,290,4,0,
98,10,291,3,0,
98,10,300,1,1,1
98,10,317,4,0,
98,10,321,2,0,
98,10,334,3,0,
98,10,341,1,19,
98,10,352,4,0,
98,10,362,1,39,
98,10,362,4,0,
98,10,363,4,0,
98,10,374,4,0,
98,10,404,4,0,
98,10,445,4,0,
98,11,11,1,5,
98,11,12,1,31,
98,11,14,4,0,
98,11,15,4,0,
98,11,21,1,35,
98,11,21,2,0,
98,11,23,1,25,
98,11,43,1,9,
98,11,57,4,0,
98,11,58,4,0,
98,11,59,4,0,
98,11,61,1,15,
98,11,70,4,0,
98,11,91,4,0,
98,11,92,4,0,
98,11,97,2,0,
98,11,104,4,0,
98,11,106,1,11,
98,11,114,2,0,
98,11,117,2,0,
98,11,133,2,0,
98,11,145,1,1,2
98,11,152,1,41,
98,11,156,4,0,
98,11,157,4,0,
98,11,164,4,0,
98,11,168,4,0,
98,11,175,1,45,
98,11,175,2,0,
98,11,182,1,29,
98,11,182,4,0,
98,11,203,2,0,
98,11,206,4,0,
98,11,207,4,0,
98,11,213,4,0,
98,11,216,4,0,
98,11,218,4,0,
98,11,232,1,21,
98,11,237,4,0,
98,11,240,4,0,
98,11,246,2,0,
98,11,249,4,0,
98,11,258,4,0,
98,11,263,4,0,
98,11,280,4,0,
98,11,282,2,0,
98,11,291,4,0,
98,11,300,1,1,1
98,11,317,4,0,
98,11,321,2,0,
98,11,341,1,19,
98,11,362,1,39,
98,11,374,4,0,
98,11,404,4,0,
98,11,468,4,0,
98,11,496,4,0,
98,11,498,2,0,
98,11,503,4,0,
98,12,11,1,12,
98,12,12,1,34,
98,12,15,4,0,
98,12,23,1,27,
98,12,43,1,5,
98,12,57,4,0,
98,12,58,4,0,
98,12,59,4,0,
98,12,70,4,0,
98,12,91,4,0,
98,12,92,4,0,
98,12,104,4,0,
98,12,106,1,16,
98,12,145,1,1,
98,12,152,1,45,
98,12,156,4,0,
98,12,168,4,0,
98,12,182,1,41,
98,12,182,4,0,
98,12,213,4,0,
98,12,216,4,0,
98,12,218,4,0,
98,12,237,4,0,
98,12,240,4,0,
98,12,249,4,0,
98,12,258,4,0,
98,12,263,4,0,
98,12,290,4,0,
98,12,291,4,0,
98,12,317,4,0,
98,12,341,1,23,
98,12,352,4,0,
98,13,11,1,12,
98,13,12,1,34,
98,13,15,4,0,
98,13,23,1,27,
98,13,34,3,0,
98,13,38,3,0,
98,13,43,1,5,
98,13,57,4,0,
98,13,58,4,0,
98,13,59,4,0,
98,13,70,4,0,
98,13,91,4,0,
98,13,92,4,0,
98,13,102,3,0,
98,13,104,4,0,
98,13,106,1,16,
98,13,145,1,1,
98,13,152,1,45,
98,13,156,4,0,
98,13,164,3,0,
98,13,168,4,0,
98,13,182,1,41,
98,13,182,4,0,
98,13,196,3,0,
98,13,207,3,0,
98,13,213,4,0,
98,13,216,4,0,
98,13,218,4,0,
98,13,237,4,0,
98,13,240,4,0,
98,13,249,4,0,
98,13,258,4,0,
98,13,263,4,0,
98,13,290,4,0,
98,13,291,4,0,
98,13,317,4,0,
98,13,341,1,23,
98,13,352,4,0,
98,14,11,1,5,
98,14,12,1,31,
98,14,14,4,0,
98,14,15,4,0,
98,14,21,1,35,
98,14,21,2,0,
98,14,23,1,25,
98,14,43,1,9,
98,14,57,4,0,
98,14,58,4,0,
98,14,59,4,0,
98,14,61,1,15,
98,14,70,4,0,
98,14,91,4,0,
98,14,92,4,0,
98,14,97,2,0,
98,14,104,4,0,
98,14,106,1,11,
98,14,114,2,0,
98,14,117,2,0,
98,14,133,2,0,
98,14,145,1,1,2
98,14,152,1,41,
98,14,156,4,0,
98,14,157,4,0,
98,14,164,4,0,
98,14,168,4,0,
98,14,173,3,0,
98,14,175,1,45,
98,14,175,2,0,
98,14,182,1,29,
98,14,182,4,0,
98,14,196,3,0,
98,14,203,2,0,
98,14,206,4,0,
98,14,207,4,0,
98,14,213,4,0,
98,14,214,3,0,
98,14,216,4,0,
98,14,218,4,0,
98,14,232,1,21,
98,14,237,4,0,
98,14,240,4,0,
98,14,246,2,0,
98,14,249,4,0,
98,14,258,4,0,
98,14,263,4,0,
98,14,276,3,0,
98,14,280,4,0,
98,14,282,2,0,
98,14,282,3,0,
98,14,291,4,0,
98,14,300,1,1,1
98,14,317,4,0,
98,14,321,2,0,
98,14,334,3,0,
98,14,341,1,19,
98,14,362,1,39,
98,14,374,4,0,
98,14,404,4,0,
98,14,468,4,0,
98,14,496,4,0,
98,14,498,2,0,
98,14,503,4,0,
98,15,11,1,5,
98,15,12,1,31,
98,15,14,4,0,
98,15,15,4,0,
98,15,21,1,35,
98,15,21,2,0,
98,15,23,1,25,
98,15,43,1,9,
98,15,57,4,0,
98,15,58,4,0,
98,15,59,4,0,
98,15,61,1,15,
98,15,70,4,0,
98,15,91,4,0,
98,15,92,4,0,
98,15,97,2,0,
98,15,104,4,0,
98,15,106,1,11,
98,15,114,2,0,
98,15,117,2,0,
98,15,133,2,0,
98,15,145,1,1,2
98,15,152,1,41,
98,15,156,4,0,
98,15,157,4,0,
98,15,164,4,0,
98,15,168,4,0,
98,15,175,1,45,
98,15,175,2,0,
98,15,182,1,29,
98,15,182,4,0,
98,15,203,2,0,
98,15,206,4,0,
98,15,207,4,0,
98,15,213,4,0,
98,15,214,4,0,
98,15,216,4,0,
98,15,218,4,0,
98,15,232,1,21,
98,15,237,4,0,
98,15,240,4,0,
98,15,246,2,0,
98,15,249,4,0,
98,15,258,4,0,
98,15,263,4,0,
98,15,280,4,0,
98,15,282,2,0,
98,15,300,1,1,1
98,15,317,4,0,
98,15,321,2,0,
98,15,341,1,19,
98,15,362,1,39,
98,15,374,4,0,
98,15,404,4,0,
98,15,468,4,0,
98,15,496,4,0,
98,15,498,2,0,
98,15,502,2,0,
98,15,503,4,0,
98,15,590,4,0,
98,16,11,1,5,1
98,16,12,1,31,1
98,16,14,4,0,
98,16,15,4,0,
98,16,21,1,35,1
98,16,21,2,0,
98,16,23,1,25,1
98,16,43,1,9,1
98,16,57,4,0,
98,16,58,4,0,
98,16,59,4,0,
98,16,61,1,15,1
98,16,70,4,0,
98,16,91,4,0,
98,16,92,4,0,
98,16,97,2,0,
98,16,104,4,0,
98,16,106,1,11,1
98,16,114,2,0,
98,16,117,2,0,
98,16,133,2,0,
98,16,145,1,1,2
98,16,152,1,41,1
98,16,156,4,0,
98,16,157,4,0,
98,16,164,4,0,
98,16,168,4,0,
98,16,173,3,0,
98,16,175,1,45,1
98,16,175,2,0,
98,16,182,1,29,1
98,16,182,4,0,
98,16,196,3,0,
98,16,203,2,0,
98,16,206,4,0,
98,16,207,4,0,
98,16,213,4,0,
98,16,214,4,0,
98,16,216,4,0,
98,16,218,4,0,
98,16,232,1,21,1
98,16,237,4,0,
98,16,240,4,0,
98,16,246,2,0,
98,16,249,4,0,
98,16,258,4,0,
98,16,263,4,0,
98,16,276,3,0,
98,16,280,4,0,
98,16,282,2,0,
98,16,282,3,0,
98,16,290,4,0,
98,16,291,4,0,
98,16,300,1,1,1
98,16,317,4,0,
98,16,321,2,0,
98,16,334,3,0,
98,16,341,1,19,1
98,16,352,3,0,
98,16,362,1,39,1
98,16,374,4,0,
98,16,404,4,0,
98,16,468,4,0,
98,16,496,4,0,
98,16,498,2,0,
98,16,502,2,0,
98,16,503,4,0,
98,16,590,4,0,
98,17,11,1,5,
98,17,12,1,31,
98,17,14,4,0,
98,17,21,1,35,
98,17,21,2,0,
98,17,23,1,25,
98,17,43,1,9,
98,17,57,4,0,
98,17,58,4,0,
98,17,59,4,0,
98,17,61,1,15,
98,17,92,4,0,
98,17,97,2,0,
98,17,104,4,0,
98,17,106,1,11,
98,17,114,2,0,
98,17,117,2,0,
98,17,133,2,0,
98,17,145,1,1,2
98,17,152,1,41,
98,17,156,4,0,
98,17,157,4,0,
98,17,164,4,0,
98,17,168,4,0,
98,17,175,1,45,
98,17,175,2,0,
98,17,182,1,29,
98,17,182,4,0,
98,17,203,2,0,
98,17,206,4,0,
98,17,207,4,0,
98,17,213,4,0,
98,17,214,4,0,
98,17,216,4,0,
98,17,218,4,0,
98,17,232,1,21,
98,17,237,4,0,
98,17,240,4,0,
98,17,246,2,0,
98,17,258,4,0,
98,17,263,4,0,
98,17,280,4,0,
98,17,282,2,0,
98,17,300,1,1,1
98,17,317,4,0,
98,17,321,2,0,
98,17,341,1,19,
98,17,362,1,39,
98,17,374,4,0,
98,17,404,4,0,
98,17,496,4,0,
98,17,498,2,0,
98,17,502,2,0,
98,17,503,4,0,
98,17,590,4,0,
98,18,11,1,5,
98,18,12,1,31,
98,18,14,4,0,
98,18,21,1,35,
98,18,21,2,0,
98,18,23,1,25,
98,18,43,1,9,
98,18,57,4,0,
98,18,58,4,0,
98,18,59,4,0,
98,18,61,1,15,
98,18,92,4,0,
98,18,97,2,0,
98,18,104,4,0,
98,18,106,1,11,
98,18,114,2,0,
98,18,117,2,0,
98,18,133,2,0,
98,18,145,1,1,2
98,18,152,1,41,
98,18,156,4,0,
98,18,157,4,0,
98,18,164,4,0,
98,18,168,4,0,
98,18,175,1,45,
98,18,175,2,0,
98,18,182,1,29,
98,18,182,4,0,
98,18,203,2,0,
98,18,206,4,0,
98,18,207,4,0,
98,18,213,4,0,
98,18,214,4,0,
98,18,216,4,0,
98,18,218,4,0,
98,18,232,1,21,
98,18,237,4,0,
98,18,240,4,0,
98,18,246,2,0,
98,18,258,4,0,
98,18,263,4,0,
98,18,280,4,0,
98,18,282,2,0,
98,18,300,1,1,1
98,18,317,4,0,
98,18,321,2,0,
98,18,341,1,19,
98,18,362,1,39,
98,18,374,4,0,
98,18,404,4,0,
98,18,496,4,0,
98,18,498,2,0,
98,18,502,2,0,
98,18,503,4,0,
98,18,590,4,0,
99,1,11,1,1,3
99,1,11,1,20,
99,1,12,1,25,
99,1,14,4,0,
99,1,15,4,0,
99,1,23,1,34,
99,1,34,4,0,
99,1,36,4,0,
99,1,38,4,0,
99,1,43,1,1,2
99,1,55,4,0,
99,1,57,4,0,
99,1,58,4,0,
99,1,59,4,0,
99,1,61,4,0,
99,1,63,4,0,
99,1,70,4,0,
99,1,92,4,0,
99,1,99,4,0,
99,1,102,4,0,
99,1,104,4,0,
99,1,106,1,49,
99,1,117,4,0,
99,1,145,1,1,1
99,1,152,1,42,
99,1,156,4,0,
99,1,164,4,0,
99,2,11,1,1,3
99,2,11,1,20,
99,2,12,1,25,
99,2,14,4,0,
99,2,15,4,0,
99,2,23,1,34,
99,2,34,4,0,
99,2,36,4,0,
99,2,38,4,0,
99,2,43,1,1,2
99,2,55,4,0,
99,2,57,4,0,
99,2,58,4,0,
99,2,59,4,0,
99,2,61,4,0,
99,2,63,4,0,
99,2,70,4,0,
99,2,92,4,0,
99,2,99,4,0,
99,2,102,4,0,
99,2,104,4,0,
99,2,106,1,49,
99,2,117,4,0,
99,2,145,1,1,1
99,2,152,1,42,
99,2,156,4,0,
99,2,164,4,0,
99,3,11,1,1,3
99,3,11,1,12,
99,3,12,1,27,
99,3,15,4,0,
99,3,23,1,23,
99,3,43,1,1,2
99,3,43,1,5,
99,3,57,4,0,
99,3,59,4,0,
99,3,63,4,0,
99,3,70,4,0,
99,3,92,4,0,
99,3,104,4,0,
99,3,106,1,16,
99,3,145,1,1,1
99,3,152,1,49,
99,3,156,4,0,
99,3,168,4,0,
99,3,173,4,0,
99,3,174,4,0,
99,3,182,1,38,
99,3,182,4,0,
99,3,189,4,0,
99,3,196,4,0,
99,3,203,4,0,
99,3,207,4,0,
99,3,210,4,0,
99,3,213,4,0,
99,3,214,4,0,
99,3,216,4,0,
99,3,218,4,0,
99,3,237,4,0,
99,3,240,4,0,
99,3,249,4,0,
99,3,250,4,0,
99,4,11,1,1,3
99,4,11,1,12,
99,4,12,1,27,
99,4,15,4,0,
99,4,23,1,23,
99,4,43,1,1,2
99,4,43,1,5,
99,4,57,4,0,
99,4,58,3,0,
99,4,59,4,0,
99,4,63,4,0,
99,4,70,4,0,
99,4,92,4,0,
99,4,104,4,0,
99,4,106,1,16,
99,4,145,1,1,1
99,4,152,1,49,
99,4,156,4,0,
99,4,168,4,0,
99,4,173,4,0,
99,4,174,4,0,
99,4,182,1,38,
99,4,182,4,0,
99,4,189,4,0,
99,4,196,4,0,
99,4,203,4,0,
99,4,207,4,0,
99,4,210,4,0,
99,4,213,4,0,
99,4,214,4,0,
99,4,216,4,0,
99,4,218,4,0,
99,4,237,4,0,
99,4,240,4,0,
99,4,249,4,0,
99,4,250,4,0,
99,5,11,1,1,3
99,5,11,1,12,
99,5,12,1,38,
99,5,15,4,0,
99,5,23,1,27,
99,5,43,1,1,2
99,5,43,1,5,
99,5,57,4,0,
99,5,58,4,0,
99,5,59,4,0,
99,5,63,4,0,
99,5,70,4,0,
99,5,91,4,0,
99,5,92,4,0,
99,5,104,4,0,
99,5,106,1,16,
99,5,145,1,1,1
99,5,152,1,57,
99,5,156,4,0,
99,5,168,4,0,
99,5,182,1,49,
99,5,182,4,0,
99,5,213,4,0,
99,5,216,4,0,
99,5,218,4,0,
99,5,237,4,0,
99,5,240,4,0,
99,5,249,4,0,
99,5,258,4,0,
99,5,263,4,0,
99,5,290,4,0,
99,5,291,4,0,
99,5,317,4,0,
99,5,341,1,23,
99,5,352,4,0,
99,6,11,1,1,3
99,6,11,1,12,
99,6,12,1,38,
99,6,14,3,0,
99,6,15,4,0,
99,6,23,1,27,
99,6,34,3,0,
99,6,38,3,0,
99,6,43,1,1,2
99,6,43,1,5,
99,6,57,4,0,
99,6,58,4,0,
99,6,59,4,0,
99,6,63,4,0,
99,6,70,4,0,
99,6,91,4,0,
99,6,92,4,0,
99,6,102,3,0,
99,6,104,4,0,
99,6,106,1,16,
99,6,145,1,1,1
99,6,152,1,57,
99,6,156,4,0,
99,6,164,3,0,
99,6,168,4,0,
99,6,173,3,0,
99,6,182,1,49,
99,6,182,4,0,
99,6,189,3,0,
99,6,196,3,0,
99,6,203,3,0,
99,6,207,3,0,
99,6,210,3,0,
99,6,213,4,0,
99,6,214,3,0,
99,6,216,4,0,
99,6,218,4,0,
99,6,237,4,0,
99,6,240,4,0,
99,6,249,4,0,
99,6,258,4,0,
99,6,263,4,0,
99,6,290,4,0,
99,6,291,4,0,
99,6,317,4,0,
99,6,341,1,23,
99,6,352,4,0,
99,7,11,1,1,4
99,7,11,1,12,
99,7,12,1,38,
99,7,14,3,0,
99,7,15,4,0,
99,7,23,1,27,
99,7,34,3,0,
99,7,38,3,0,
99,7,43,1,1,3
99,7,43,1,5,
99,7,57,4,0,
99,7,58,4,0,
99,7,59,4,0,
99,7,63,4,0,
99,7,70,4,0,
99,7,91,4,0,
99,7,92,4,0,
99,7,102,3,0,
99,7,104,4,0,
99,7,106,1,1,5
99,7,106,1,16,
99,7,145,1,1,2
99,7,152,1,57,
99,7,156,4,0,
99,7,164,3,0,
99,7,168,4,0,
99,7,175,1,65,
99,7,182,1,42,
99,7,182,4,0,
99,7,213,4,0,
99,7,216,4,0,
99,7,218,4,0,
99,7,232,1,1,1
99,7,237,4,0,
99,7,240,4,0,
99,7,249,4,0,
99,7,258,4,0,
99,7,263,4,0,
99,7,290,4,0,
99,7,291,4,0,
99,7,317,4,0,
99,7,341,1,23,
99,7,352,4,0,
99,8,11,1,1,3
99,8,11,1,5,
99,8,12,1,37,
99,8,14,4,0,
99,8,15,4,0,
99,8,21,1,44,
99,8,23,1,25,
99,8,43,1,9,
99,8,57,4,0,
99,8,58,4,0,
99,8,59,4,0,
99,8,61,1,15,
99,8,63,4,0,
99,8,70,4,0,
99,8,91,4,0,
99,8,92,4,0,
99,8,104,4,0,
99,8,106,1,11,
99,8,145,1,1,2
99,8,152,1,56,
99,8,156,4,0,
99,8,157,4,0,
99,8,164,4,0,
99,8,168,4,0,
99,8,175,1,63,
99,8,182,1,32,
99,8,182,4,0,
99,8,203,4,0,
99,8,206,4,0,
99,8,207,4,0,
99,8,213,4,0,
99,8,214,4,0,
99,8,216,4,0,
99,8,218,4,0,
99,8,232,1,21,
99,8,237,4,0,
99,8,240,4,0,
99,8,249,4,0,
99,8,258,4,0,
99,8,263,4,0,
99,8,280,4,0,
99,8,290,4,0,
99,8,300,1,1,1
99,8,317,4,0,
99,8,341,1,19,
99,8,352,4,0,
99,8,362,1,51,
99,8,362,4,0,
99,8,363,4,0,
99,8,374,4,0,
99,8,404,4,0,
99,8,416,4,0,
99,8,445,4,0,
99,9,11,1,1,3
99,9,11,1,5,
99,9,12,1,37,
99,9,14,4,0,
99,9,15,4,0,
99,9,21,1,44,
99,9,23,1,25,
99,9,43,1,9,
99,9,57,4,0,
99,9,58,4,0,
99,9,59,4,0,
99,9,61,1,15,
99,9,63,4,0,
99,9,70,4,0,
99,9,91,4,0,
99,9,92,4,0,
99,9,104,4,0,
99,9,106,1,11,
99,9,145,1,1,2
99,9,152,1,56,
99,9,156,4,0,
99,9,157,4,0,
99,9,164,4,0,
99,9,168,4,0,
99,9,173,3,0,
99,9,175,1,63,
99,9,182,1,32,
99,9,182,4,0,
99,9,189,3,0,
99,9,196,3,0,
99,9,203,4,0,
99,9,206,4,0,
99,9,207,4,0,
99,9,210,3,0,
99,9,213,4,0,
99,9,214,4,0,
99,9,216,4,0,
99,9,218,4,0,
99,9,232,1,21,
99,9,237,4,0,
99,9,240,4,0,
99,9,246,3,0,
99,9,249,4,0,
99,9,258,4,0,
99,9,263,4,0,
99,9,276,3,0,
99,9,280,4,0,
99,9,282,3,0,
99,9,290,4,0,
99,9,291,3,0,
99,9,300,1,1,1
99,9,317,4,0,
99,9,334,3,0,
99,9,341,1,19,
99,9,352,4,0,
99,9,362,1,51,
99,9,362,4,0,
99,9,363,4,0,
99,9,374,4,0,
99,9,404,4,0,
99,9,416,4,0,
99,9,445,4,0,
99,10,11,1,1,3
99,10,11,1,5,
99,10,12,1,37,
99,10,14,4,0,
99,10,15,4,0,
99,10,21,1,44,
99,10,23,1,25,
99,10,43,1,9,
99,10,57,4,0,
99,10,58,4,0,
99,10,59,4,0,
99,10,61,1,15,
99,10,63,4,0,
99,10,70,4,0,
99,10,91,4,0,
99,10,92,4,0,
99,10,104,4,0,
99,10,106,1,11,
99,10,145,1,1,2
99,10,152,1,56,
99,10,156,4,0,
99,10,157,4,0,
99,10,164,4,0,
99,10,168,4,0,
99,10,173,3,0,
99,10,175,1,63,
99,10,182,1,32,
99,10,182,4,0,
99,10,189,3,0,
99,10,196,3,0,
99,10,203,4,0,
99,10,206,4,0,
99,10,207,4,0,
99,10,210,3,0,
99,10,213,4,0,
99,10,214,4,0,
99,10,216,4,0,
99,10,218,4,0,
99,10,232,1,21,
99,10,237,4,0,
99,10,240,4,0,
99,10,246,3,0,
99,10,249,4,0,
99,10,250,4,0,
99,10,258,4,0,
99,10,263,4,0,
99,10,276,3,0,
99,10,280,4,0,
99,10,282,3,0,
99,10,290,4,0,
99,10,291,3,0,
99,10,300,1,1,1
99,10,317,4,0,
99,10,334,3,0,
99,10,341,1,19,
99,10,352,4,0,
99,10,362,1,51,
99,10,362,4,0,
99,10,363,4,0,
99,10,374,4,0,
99,10,404,4,0,
99,10,416,4,0,
99,10,445,4,0,
99,11,11,1,1,4
99,11,11,1,5,
99,11,12,1,37,
99,11,14,4,0,
99,11,15,4,0,
99,11,21,1,44,
99,11,23,1,25,
99,11,43,1,1,5
99,11,43,1,9,
99,11,57,4,0,
99,11,58,4,0,
99,11,59,4,0,
99,11,61,1,15,
99,11,63,4,0,
99,11,70,4,0,
99,11,91,4,0,
99,11,92,4,0,
99,11,104,4,0,
99,11,106,1,11,
99,11,145,1,1,3
99,11,152,1,56,
99,11,156,4,0,
99,11,157,4,0,
99,11,164,4,0,
99,11,168,4,0,
99,11,175,1,63,
99,11,182,1,32,
99,11,182,4,0,
99,11,206,4,0,
99,11,207,4,0,
99,11,213,4,0,
99,11,216,4,0,
99,11,218,4,0,
99,11,232,1,21,
99,11,237,4,0,
99,11,240,4,0,
99,11,249,4,0,
99,11,258,4,0,
99,11,263,4,0,
99,11,280,4,0,
99,11,291,4,0,
99,11,300,1,1,2
99,11,317,4,0,
99,11,341,1,19,
99,11,362,1,51,
99,11,374,4,0,
99,11,404,4,0,
99,11,416,4,0,
99,11,468,4,0,
99,11,469,1,1,1
99,11,496,4,0,
99,11,503,4,0,
99,11,511,4,0,
99,12,11,1,1,3
99,12,11,1,12,
99,12,12,1,38,
99,12,15,4,0,
99,12,23,1,27,
99,12,43,1,1,2
99,12,43,1,5,
99,12,57,4,0,
99,12,58,4,0,
99,12,59,4,0,
99,12,63,4,0,
99,12,70,4,0,
99,12,91,4,0,
99,12,92,4,0,
99,12,104,4,0,
99,12,106,1,16,
99,12,145,1,1,1
99,12,152,1,57,
99,12,156,4,0,
99,12,168,4,0,
99,12,182,1,49,
99,12,182,4,0,
99,12,213,4,0,
99,12,216,4,0,
99,12,218,4,0,
99,12,237,4,0,
99,12,240,4,0,
99,12,249,4,0,
99,12,258,4,0,
99,12,263,4,0,
99,12,290,4,0,
99,12,291,4,0,
99,12,317,4,0,
99,12,341,1,23,
99,12,352,4,0,
99,13,11,1,1,3
99,13,11,1,12,
99,13,12,1,38,
99,13,15,4,0,
99,13,23,1,27,
99,13,34,3,0,
99,13,38,3,0,
99,13,43,1,1,2
99,13,43,1,5,
99,13,57,4,0,
99,13,58,4,0,
99,13,59,4,0,
99,13,63,4,0,
99,13,70,4,0,
99,13,91,4,0,
99,13,92,4,0,
99,13,102,3,0,
99,13,104,4,0,
99,13,106,1,16,
99,13,145,1,1,1
99,13,152,1,57,
99,13,156,4,0,
99,13,164,3,0,
99,13,168,4,0,
99,13,182,1,49,
99,13,182,4,0,
99,13,196,3,0,
99,13,207,3,0,
99,13,213,4,0,
99,13,216,4,0,
99,13,218,4,0,
99,13,237,4,0,
99,13,240,4,0,
99,13,249,4,0,
99,13,258,4,0,
99,13,263,4,0,
99,13,290,4,0,
99,13,291,4,0,
99,13,317,4,0,
99,13,341,1,23,
99,13,352,4,0,
99,14,11,1,1,4
99,14,11,1,5,
99,14,12,1,37,
99,14,14,4,0,
99,14,15,4,0,
99,14,21,1,44,
99,14,23,1,25,
99,14,43,1,1,5
99,14,43,1,9,
99,14,57,4,0,
99,14,58,4,0,
99,14,59,4,0,
99,14,61,1,15,
99,14,63,4,0,
99,14,70,4,0,
99,14,91,4,0,
99,14,92,4,0,
99,14,104,4,0,
99,14,106,1,11,
99,14,145,1,1,3
99,14,152,1,56,
99,14,156,4,0,
99,14,157,4,0,
99,14,164,4,0,
99,14,168,4,0,
99,14,173,3,0,
99,14,175,1,63,
99,14,182,1,32,
99,14,182,4,0,
99,14,196,3,0,
99,14,206,4,0,
99,14,207,4,0,
99,14,213,4,0,
99,14,214,3,0,
99,14,216,4,0,
99,14,218,4,0,
99,14,232,1,21,
99,14,237,4,0,
99,14,240,4,0,
99,14,249,4,0,
99,14,258,4,0,
99,14,263,4,0,
99,14,276,3,0,
99,14,280,4,0,
99,14,282,3,0,
99,14,291,4,0,
99,14,300,1,1,2
99,14,317,4,0,
99,14,334,3,0,
99,14,341,1,19,
99,14,362,1,51,
99,14,374,4,0,
99,14,404,4,0,
99,14,416,4,0,
99,14,468,4,0,
99,14,469,1,1,1
99,14,496,4,0,
99,14,503,4,0,
99,14,511,4,0,
99,15,11,1,1,4
99,15,11,1,5,
99,15,12,1,37,
99,15,14,4,0,
99,15,15,4,0,
99,15,21,1,44,
99,15,23,1,25,
99,15,43,1,1,5
99,15,43,1,9,
99,15,57,4,0,
99,15,58,4,0,
99,15,59,4,0,
99,15,61,1,15,
99,15,63,4,0,
99,15,70,4,0,
99,15,91,4,0,
99,15,92,4,0,
99,15,104,4,0,
99,15,106,1,11,
99,15,145,1,1,3
99,15,152,1,56,
99,15,156,4,0,
99,15,157,4,0,
99,15,164,4,0,
99,15,168,4,0,
99,15,175,1,63,
99,15,182,1,32,
99,15,182,4,0,
99,15,206,4,0,
99,15,207,4,0,
99,15,213,4,0,
99,15,214,4,0,
99,15,216,4,0,
99,15,218,4,0,
99,15,232,1,21,
99,15,237,4,0,
99,15,240,4,0,
99,15,249,4,0,
99,15,258,4,0,
99,15,263,4,0,
99,15,280,4,0,
99,15,300,1,1,2
99,15,317,4,0,
99,15,341,1,19,
99,15,362,1,51,
99,15,374,4,0,
99,15,404,4,0,
99,15,416,4,0,
99,15,468,4,0,
99,15,469,1,1,1
99,15,496,4,0,
99,15,503,4,0,
99,15,511,4,0,
99,15,590,4,0,
99,16,11,1,1,4
99,16,11,1,5,1
99,16,12,1,37,1
99,16,14,4,0,
99,16,15,4,0,
99,16,21,1,44,1
99,16,23,1,25,1
99,16,43,1,1,5
99,16,43,1,9,1
99,16,57,4,0,
99,16,58,4,0,
99,16,59,4,0,
99,16,61,1,15,1
99,16,63,4,0,
99,16,70,4,0,
99,16,91,4,0,
99,16,92,4,0,
99,16,104,4,0,
99,16,106,1,11,1
99,16,145,1,1,3
99,16,152,1,56,1
99,16,156,4,0,
99,16,157,4,0,
99,16,164,4,0,
99,16,168,4,0,
99,16,173,3,0,
99,16,175,1,63,1
99,16,182,1,32,1
99,16,182,4,0,
99,16,196,3,0,
99,16,206,4,0,
99,16,207,4,0,
99,16,213,4,0,
99,16,214,4,0,
99,16,216,4,0,
99,16,218,4,0,
99,16,232,1,21,1
99,16,237,4,0,
99,16,240,4,0,
99,16,249,4,0,
99,16,258,4,0,
99,16,263,4,0,
99,16,276,3,0,
99,16,280,4,0,
99,16,282,3,0,
99,16,290,4,0,
99,16,291,4,0,
99,16,300,1,1,2
99,16,317,4,0,
99,16,334,3,0,
99,16,341,1,19,1
99,16,352,3,0,
99,16,362,1,51,1
99,16,374,4,0,
99,16,404,4,0,
99,16,416,4,0,
99,16,468,4,0,
99,16,469,1,1,1
99,16,496,4,0,
99,16,503,4,0,
99,16,511,4,0,
99,16,590,4,0,
99,17,11,1,1,4
99,17,11,1,5,
99,17,12,1,37,
99,17,14,4,0,
99,17,21,1,44,
99,17,23,1,25,
99,17,43,1,1,5
99,17,43,1,9,
99,17,57,4,0,
99,17,58,4,0,
99,17,59,4,0,
99,17,61,1,15,
99,17,63,4,0,
99,17,92,4,0,
99,17,104,4,0,
99,17,106,1,11,
99,17,145,1,1,3
99,17,152,1,56,
99,17,156,4,0,
99,17,157,4,0,
99,17,164,4,0,
99,17,168,4,0,
99,17,175,1,63,
99,17,182,1,32,
99,17,182,4,0,
99,17,206,4,0,
99,17,207,4,0,
99,17,213,4,0,
99,17,214,4,0,
99,17,216,4,0,
99,17,218,4,0,
99,17,232,1,21,
99,17,237,4,0,
99,17,240,4,0,
99,17,258,4,0,
99,17,263,4,0,
99,17,280,4,0,
99,17,300,1,1,2
99,17,317,4,0,
99,17,341,1,19,
99,17,362,1,51,
99,17,374,4,0,
99,17,404,4,0,
99,17,416,4,0,
99,17,469,1,1,1
99,17,496,4,0,
99,17,503,4,0,
99,17,511,4,0,
99,17,590,4,0,
99,18,11,1,1,4
99,18,11,1,5,
99,18,12,1,37,
99,18,14,4,0,
99,18,21,1,44,
99,18,23,1,25,
99,18,43,1,1,5
99,18,43,1,9,
99,18,57,4,0,
99,18,58,4,0,
99,18,59,4,0,
99,18,61,1,15,
99,18,63,4,0,
99,18,92,4,0,
99,18,104,4,0,
99,18,106,1,11,
99,18,145,1,1,3
99,18,152,1,56,
99,18,156,4,0,
99,18,157,4,0,
99,18,164,4,0,
99,18,168,4,0,
99,18,175,1,63,
99,18,182,1,32,
99,18,182,4,0,
99,18,206,4,0,
99,18,207,4,0,
99,18,213,4,0,
99,18,214,4,0,
99,18,216,4,0,
99,18,218,4,0,
99,18,232,1,21,
99,18,237,4,0,
99,18,240,4,0,
99,18,258,4,0,
99,18,263,4,0,
99,18,280,4,0,
99,18,300,1,1,2
99,18,317,4,0,
99,18,341,1,19,
99,18,362,1,51,
99,18,374,4,0,
99,18,404,4,0,
99,18,416,4,0,
99,18,469,1,1,1
99,18,496,4,0,
99,18,503,4,0,
99,18,511,4,0,
99,18,590,4,0,
100,1,33,1,1,1
100,1,36,4,0,
100,1,49,1,17,
100,1,85,4,0,
100,1,86,4,0,
100,1,87,4,0,
100,1,92,4,0,
100,1,99,4,0,
100,1,100,4,0,
100,1,102,4,0,
100,1,103,1,1,2
100,1,104,4,0,
100,1,113,1,29,
100,1,115,4,0,
100,1,117,4,0,
100,1,120,1,22,
100,1,120,4,0,
100,1,129,1,36,
100,1,129,4,0,
100,1,148,4,0,
100,1,153,1,43,
100,1,153,4,0,
100,1,156,4,0,
100,1,164,4,0,
100,2,33,1,1,1
100,2,36,4,0,
100,2,49,1,17,
100,2,85,4,0,
100,2,86,4,0,
100,2,87,4,0,
100,2,92,4,0,
100,2,99,4,0,
100,2,100,4,0,
100,2,102,4,0,
100,2,103,1,1,2
100,2,104,4,0,
100,2,113,1,29,
100,2,115,4,0,
100,2,117,4,0,
100,2,120,1,22,
100,2,120,4,0,
100,2,129,1,36,
100,2,129,4,0,
100,2,148,4,0,
100,2,153,1,43,
100,2,153,4,0,
100,2,156,4,0,
100,2,164,4,0,
100,3,29,4,0,
100,3,33,1,1,
100,3,49,1,17,
100,3,87,4,0,
100,3,92,4,0,
100,3,103,1,9,
100,3,104,4,0,
100,3,113,1,33,
100,3,120,1,23,
100,3,129,1,37,
100,3,129,4,0,
100,3,148,4,0,
100,3,153,1,39,
100,3,156,4,0,
100,3,173,4,0,
100,3,174,4,0,
100,3,182,4,0,
100,3,192,4,0,
100,3,203,4,0,
100,3,205,1,29,
100,3,205,4,0,
100,3,207,4,0,
100,3,214,4,0,
100,3,216,4,0,
100,3,218,4,0,
100,3,237,4,0,
100,3,240,4,0,
100,3,243,1,41,
100,4,29,4,0,
100,4,33,1,1,
100,4,49,1,17,
100,4,85,3,0,
100,4,87,4,0,
100,4,92,4,0,
100,4,103,1,9,
100,4,104,4,0,
100,4,113,1,33,
100,4,120,1,23,
100,4,129,1,37,
100,4,129,4,0,
100,4,148,4,0,
100,4,153,1,39,
100,4,156,4,0,
100,4,173,4,0,
100,4,174,4,0,
100,4,182,4,0,
100,4,192,4,0,
100,4,203,4,0,
100,4,205,1,29,
100,4,205,4,0,
100,4,207,4,0,
100,4,214,4,0,
100,4,216,4,0,
100,4,218,4,0,
100,4,237,4,0,
100,4,240,4,0,
100,4,243,1,41,
100,5,33,1,1,2
100,5,49,1,15,
100,5,85,4,0,
100,5,87,4,0,
100,5,92,4,0,
100,5,103,1,8,
100,5,104,4,0,
100,5,113,1,37,
100,5,113,4,0,
100,5,120,1,27,
100,5,129,1,42,
100,5,148,4,0,
100,5,153,1,46,
100,5,156,4,0,
100,5,168,4,0,
100,5,182,4,0,
100,5,205,1,32,
100,5,209,1,21,
100,5,216,4,0,
100,5,218,4,0,
100,5,237,4,0,
100,5,240,4,0,
100,5,243,1,49,
100,5,259,4,0,
100,5,263,4,0,
100,5,268,1,1,1
100,5,269,4,0,
100,5,290,4,0,
100,5,351,4,0,
100,6,33,1,1,2
100,6,49,1,15,
100,6,85,4,0,
100,6,86,3,0,
100,6,87,4,0,
100,6,92,4,0,
100,6,102,3,0,
100,6,103,1,8,
100,6,104,4,0,
100,6,113,1,37,
100,6,113,4,0,
100,6,120,1,27,
100,6,129,1,42,
100,6,129,3,0,
100,6,148,4,0,
100,6,153,1,46,
100,6,153,3,0,
100,6,156,4,0,
100,6,164,3,0,
100,6,168,4,0,
100,6,173,3,0,
100,6,182,4,0,
100,6,203,3,0,
100,6,205,1,32,
100,6,205,3,0,
100,6,207,3,0,
100,6,209,1,21,
100,6,214,3,0,
100,6,216,4,0,
100,6,218,4,0,
100,6,237,4,0,
100,6,240,4,0,
100,6,243,1,49,
100,6,259,4,0,
100,6,263,4,0,
100,6,268,1,1,1
100,6,269,4,0,
100,6,290,4,0,
100,6,351,4,0,
100,7,33,1,1,2
100,7,49,1,15,
100,7,85,4,0,
100,7,86,3,0,
100,7,87,4,0,
100,7,92,4,0,
100,7,102,3,0,
100,7,103,1,8,
100,7,104,4,0,
100,7,113,1,37,
100,7,113,4,0,
100,7,120,1,27,
100,7,129,1,42,
100,7,148,4,0,
100,7,153,1,46,
100,7,153,3,0,
100,7,156,4,0,
100,7,164,3,0,
100,7,168,4,0,
100,7,182,4,0,
100,7,205,1,32,
100,7,209,1,21,
100,7,216,4,0,
100,7,218,4,0,
100,7,237,4,0,
100,7,240,4,0,
100,7,243,1,49,
100,7,259,4,0,
100,7,263,4,0,
100,7,268,1,1,1
100,7,269,4,0,
100,7,290,4,0,
100,7,351,4,0,
100,8,33,1,5,
100,8,49,1,8,
100,8,85,4,0,
100,8,86,4,0,
100,8,87,4,0,
100,8,92,4,0,
100,8,103,1,19,
100,8,104,4,0,
100,8,113,1,22,
100,8,113,4,0,
100,8,120,1,29,
100,8,129,1,33,
100,8,148,4,0,
100,8,153,1,43,
100,8,153,4,0,
100,8,156,4,0,
100,8,164,4,0,
100,8,168,4,0,
100,8,182,4,0,
100,8,203,4,0,
100,8,205,1,15,
100,8,207,4,0,
100,8,209,1,12,
100,8,214,4,0,
100,8,216,4,0,
100,8,218,4,0,
100,8,237,4,0,
100,8,240,4,0,
100,8,243,1,47,
100,8,259,4,0,
100,8,263,4,0,
100,8,268,1,1,
100,8,269,4,0,
100,8,290,4,0,
100,8,351,4,0,
100,8,360,1,40,
100,8,360,4,0,
100,8,363,4,0,
100,8,393,1,36,
100,8,451,1,26,
100,8,451,4,0,
100,9,33,1,5,
100,9,49,1,8,
100,9,85,4,0,
100,9,86,4,0,
100,9,87,4,0,
100,9,92,4,0,
100,9,103,1,19,
100,9,104,4,0,
100,9,113,1,22,
100,9,113,4,0,
100,9,120,1,29,
100,9,129,1,33,
100,9,129,3,0,
100,9,148,4,0,
100,9,153,1,43,
100,9,153,4,0,
100,9,156,4,0,
100,9,164,4,0,
100,9,168,4,0,
100,9,173,3,0,
100,9,182,4,0,
100,9,203,4,0,
100,9,205,1,15,
100,9,205,3,0,
100,9,207,4,0,
100,9,209,1,12,
100,9,214,4,0,
100,9,216,4,0,
100,9,218,4,0,
100,9,237,4,0,
100,9,240,4,0,
100,9,243,1,47,
100,9,259,4,0,
100,9,263,4,0,
100,9,268,1,1,
100,9,269,4,0,
100,9,290,4,0,
100,9,324,3,0,
100,9,351,4,0,
100,9,360,1,40,
100,9,360,4,0,
100,9,363,4,0,
100,9,389,3,0,
100,9,393,1,36,
100,9,393,3,0,
100,9,451,1,26,
100,9,451,4,0,
100,10,29,3,0,
100,10,33,1,5,
100,10,49,1,8,
100,10,85,4,0,
100,10,86,4,0,
100,10,87,4,0,
100,10,92,4,0,
100,10,103,1,19,
100,10,104,4,0,
100,10,113,1,22,
100,10,113,4,0,
100,10,120,1,29,
100,10,129,1,33,
100,10,129,3,0,
100,10,148,4,0,
100,10,153,1,43,
100,10,153,4,0,
100,10,156,4,0,
100,10,164,4,0,
100,10,168,4,0,
100,10,173,3,0,
100,10,182,4,0,
100,10,203,4,0,
100,10,205,1,15,
100,10,205,3,0,
100,10,207,4,0,
100,10,209,1,12,
100,10,214,4,0,
100,10,216,4,0,
100,10,218,4,0,
100,10,237,4,0,
100,10,240,4,0,
100,10,243,1,47,
100,10,259,4,0,
100,10,263,4,0,
100,10,268,1,1,
100,10,269,4,0,
100,10,277,3,0,
100,10,290,4,0,
100,10,324,3,0,
100,10,351,4,0,
100,10,360,1,40,
100,10,360,4,0,
100,10,363,4,0,
100,10,389,3,0,
100,10,393,1,36,
100,10,393,3,0,
100,10,451,1,26,
100,10,451,4,0,
100,11,33,1,5,
100,11,49,1,8,
100,11,85,4,0,
100,11,86,4,0,
100,11,87,4,0,
100,11,92,4,0,
100,11,103,1,19,
100,11,104,4,0,
100,11,113,1,26,
100,11,113,4,0,
100,11,120,1,33,
100,11,129,1,36,
100,11,148,4,0,
100,11,153,1,47,
100,11,153,4,0,
100,11,156,4,0,
100,11,164,4,0,
100,11,168,4,0,
100,11,182,4,0,
100,11,205,1,15,
100,11,207,4,0,
100,11,209,1,12,
100,11,216,4,0,
100,11,218,4,0,
100,11,237,4,0,
100,11,240,4,0,
100,11,243,1,50,
100,11,259,4,0,
100,11,263,4,0,
100,11,268,1,1,
100,11,269,4,0,
100,11,360,1,43,
100,11,360,4,0,
100,11,393,1,40,
100,11,451,1,22,
100,11,451,4,0,
100,11,486,1,29,
100,11,496,4,0,
100,11,521,4,0,
100,11,528,4,0,
100,12,33,1,1,2
100,12,49,1,15,
100,12,85,4,0,
100,12,87,4,0,
100,12,92,4,0,
100,12,103,1,8,
100,12,104,4,0,
100,12,113,1,37,
100,12,113,4,0,
100,12,120,1,27,
100,12,129,1,42,
100,12,148,4,0,
100,12,153,1,46,
100,12,156,4,0,
100,12,168,4,0,
100,12,182,4,0,
100,12,205,1,32,
100,12,209,1,21,
100,12,216,4,0,
100,12,218,4,0,
100,12,237,4,0,
100,12,240,4,0,
100,12,243,1,49,
100,12,259,4,0,
100,12,263,4,0,
100,12,268,1,1,1
100,12,269,4,0,
100,12,290,4,0,
100,12,351,4,0,
100,13,33,1,1,2
100,13,49,1,15,
100,13,85,4,0,
100,13,86,3,0,
100,13,87,4,0,
100,13,92,4,0,
100,13,102,3,0,
100,13,103,1,8,
100,13,104,4,0,
100,13,113,1,37,
100,13,113,4,0,
100,13,120,1,27,
100,13,120,3,0,
100,13,129,1,42,
100,13,148,4,0,
100,13,153,1,46,
100,13,156,4,0,
100,13,164,3,0,
100,13,168,4,0,
100,13,182,4,0,
100,13,205,1,32,
100,13,207,3,0,
100,13,209,1,21,
100,13,216,4,0,
100,13,218,4,0,
100,13,237,4,0,
100,13,240,4,0,
100,13,243,1,49,
100,13,259,4,0,
100,13,263,4,0,
100,13,268,1,1,1
100,13,269,4,0,
100,13,290,4,0,
100,13,351,4,0,
100,14,33,1,5,
100,14,49,1,8,
100,14,85,4,0,
100,14,86,4,0,
100,14,87,4,0,
100,14,92,4,0,
100,14,103,1,19,
100,14,104,4,0,
100,14,113,1,26,
100,14,113,4,0,
100,14,120,1,33,
100,14,129,1,36,
100,14,148,4,0,
100,14,153,1,47,
100,14,153,4,0,
100,14,156,4,0,
100,14,164,4,0,
100,14,168,4,0,
100,14,173,3,0,
100,14,182,4,0,
100,14,205,1,15,
100,14,207,4,0,
100,14,209,1,12,
100,14,214,3,0,
100,14,216,4,0,
100,14,218,4,0,
100,14,237,4,0,
100,14,240,4,0,
100,14,243,1,50,
100,14,259,4,0,
100,14,263,4,0,
100,14,268,1,1,
100,14,269,4,0,
100,14,277,3,0,
100,14,324,3,0,
100,14,360,1,43,
100,14,360,4,0,
100,14,393,1,40,
100,14,393,3,0,
100,14,451,1,22,
100,14,451,4,0,
100,14,486,1,29,
100,14,492,3,0,
100,14,496,4,0,
100,14,521,4,0,
100,14,528,4,0,
100,15,33,1,5,
100,15,49,1,8,
100,15,85,4,0,
100,15,86,4,0,
100,15,87,4,0,
100,15,92,4,0,
100,15,103,1,19,
100,15,104,4,0,
100,15,113,1,26,
100,15,113,4,0,
100,15,120,1,33,
100,15,129,1,36,
100,15,148,4,0,
100,15,153,1,47,
100,15,153,4,0,
100,15,156,4,0,
100,15,164,4,0,
100,15,168,4,0,
100,15,182,4,0,
100,15,205,1,15,
100,15,207,4,0,
100,15,209,1,12,
100,15,214,4,0,
100,15,216,4,0,
100,15,218,4,0,
100,15,237,4,0,
100,15,240,4,0,
100,15,243,1,50,
100,15,259,4,0,
100,15,263,4,0,
100,15,268,1,1,
100,15,269,4,0,
100,15,360,1,43,
100,15,360,4,0,
100,15,393,1,40,
100,15,451,1,22,
100,15,451,4,0,
100,15,486,1,29,
100,15,496,4,0,
100,15,521,4,0,
100,15,528,4,0,
100,15,590,4,0,
100,15,598,1,10,
100,16,33,1,1,2
100,16,49,1,4,1
100,16,85,4,0,
100,16,86,4,0,
100,16,87,4,0,
100,16,92,4,0,
100,16,103,1,13,1
100,16,104,4,0,
100,16,113,1,29,1
100,16,113,4,0,
100,16,120,1,26,1
100,16,129,1,20,1
100,16,148,4,0,
100,16,153,1,41,1
100,16,153,4,0,
100,16,156,4,0,
100,16,164,4,0,
100,16,168,4,0,
100,16,173,3,0,
100,16,182,4,0,
100,16,205,1,11,1
100,16,207,4,0,
100,16,209,1,9,1
100,16,214,4,0,
100,16,216,4,0,
100,16,218,4,0,
100,16,237,4,0,
100,16,240,4,0,
100,16,243,1,48,1
100,16,259,4,0,
100,16,263,4,0,
100,16,268,1,1,1
100,16,269,4,0,
100,16,277,3,0,
100,16,290,4,0,
100,16,324,3,0,
100,16,351,3,0,
100,16,360,1,46,1
100,16,360,4,0,
100,16,393,1,34,1
100,16,393,3,0,
100,16,435,1,37,1
100,16,451,1,16,1
100,16,451,4,0,
100,16,486,1,22,1
100,16,492,3,0,
100,16,496,4,0,
100,16,521,4,0,
100,16,528,4,0,
100,16,590,4,0,
100,16,598,1,6,1
100,17,33,1,1,2
100,17,49,1,4,
100,17,85,4,0,
100,17,86,4,0,
100,17,87,4,0,
100,17,92,4,0,
100,17,103,1,13,
100,17,104,4,0,
100,17,113,1,29,
100,17,113,4,0,
100,17,120,1,26,
100,17,129,1,20,
100,17,153,1,41,
100,17,153,4,0,
100,17,156,4,0,
100,17,164,4,0,
100,17,168,4,0,
100,17,182,4,0,
100,17,205,1,11,
100,17,207,4,0,
100,17,209,1,9,
100,17,214,4,0,
100,17,216,4,0,
100,17,218,4,0,
100,17,237,4,0,
100,17,240,4,0,
100,17,243,1,48,
100,17,259,4,0,
100,17,263,4,0,
100,17,268,1,1,1
100,17,269,4,0,
100,17,360,1,46,
100,17,360,4,0,
100,17,393,1,34,
100,17,435,1,37,
100,17,451,1,16,
100,17,451,4,0,
100,17,486,1,22,
100,17,496,4,0,
100,17,521,4,0,
100,17,528,4,0,
100,17,590,4,0,
100,17,598,1,6,
100,18,33,1,1,2
100,18,49,1,4,
100,18,85,4,0,
100,18,86,4,0,
100,18,87,4,0,
100,18,92,4,0,
100,18,103,1,13,
100,18,104,4,0,
100,18,113,1,29,
100,18,113,4,0,
100,18,120,1,26,
100,18,129,1,20,
100,18,153,1,41,
100,18,153,4,0,
100,18,156,4,0,
100,18,164,4,0,
100,18,168,4,0,
100,18,182,4,0,
100,18,205,1,11,
100,18,207,4,0,
100,18,209,1,9,
100,18,214,4,0,
100,18,216,4,0,
100,18,218,4,0,
100,18,237,4,0,
100,18,240,4,0,
100,18,243,1,48,
100,18,259,4,0,
100,18,263,4,0,
100,18,268,1,1,1
100,18,269,4,0,
100,18,360,1,46,
100,18,360,4,0,
100,18,393,1,34,
100,18,435,1,37,
100,18,451,1,16,
100,18,451,4,0,
100,18,486,1,22,
100,18,496,4,0,
100,18,521,4,0,
100,18,528,4,0,
100,18,590,4,0,
100,18,598,1,6,
101,1,33,1,1,1
101,1,36,4,0,
101,1,49,1,1,3
101,1,49,1,17,
101,1,63,4,0,
101,1,85,4,0,
101,1,86,4,0,
101,1,87,4,0,
101,1,92,4,0,
101,1,99,4,0,
101,1,100,4,0,
101,1,102,4,0,
101,1,103,1,1,2
101,1,104,4,0,
101,1,113,1,29,
101,1,115,4,0,
101,1,117,4,0,
101,1,120,1,22,
101,1,120,4,0,
101,1,129,1,40,
101,1,129,4,0,
101,1,130,4,0,
101,1,148,4,0,
101,1,153,1,50,
101,1,153,4,0,
101,1,156,4,0,
101,1,164,4,0,
101,2,33,1,1,1
101,2,36,4,0,
101,2,49,1,1,3
101,2,49,1,17,
101,2,63,4,0,
101,2,85,4,0,
101,2,86,4,0,
101,2,87,4,0,
101,2,92,4,0,
101,2,99,4,0,
101,2,100,4,0,
101,2,102,4,0,
101,2,103,1,1,2
101,2,104,4,0,
101,2,113,1,29,
101,2,115,4,0,
101,2,117,4,0,
101,2,120,1,22,
101,2,120,4,0,
101,2,129,1,40,
101,2,129,4,0,
101,2,130,4,0,
101,2,148,4,0,
101,2,153,1,50,
101,2,153,4,0,
101,2,156,4,0,
101,2,164,4,0,
101,3,29,4,0,
101,3,33,1,1,1
101,3,49,1,1,3
101,3,49,1,17,
101,3,63,4,0,
101,3,87,4,0,
101,3,92,4,0,
101,3,103,1,1,2
101,3,103,1,9,
101,3,104,4,0,
101,3,113,1,34,
101,3,120,1,1,4
101,3,120,1,23,
101,3,129,1,40,
101,3,129,4,0,
101,3,148,4,0,
101,3,153,1,44,
101,3,156,4,0,
101,3,173,4,0,
101,3,174,4,0,
101,3,182,4,0,
101,3,192,4,0,
101,3,203,4,0,
101,3,205,1,29,
101,3,205,4,0,
101,3,207,4,0,
101,3,214,4,0,
101,3,216,4,0,
101,3,218,4,0,
101,3,237,4,0,
101,3,240,4,0,
101,3,243,1,48,
101,4,29,4,0,
101,4,33,1,1,1
101,4,49,1,1,3
101,4,49,1,17,
101,4,63,4,0,
101,4,85,3,0,
101,4,87,4,0,
101,4,92,4,0,
101,4,103,1,1,2
101,4,103,1,9,
101,4,104,4,0,
101,4,113,1,34,
101,4,120,1,1,4
101,4,120,1,23,
101,4,129,1,40,
101,4,129,4,0,
101,4,148,4,0,
101,4,153,1,44,
101,4,156,4,0,
101,4,173,4,0,
101,4,174,4,0,
101,4,182,4,0,
101,4,192,4,0,
101,4,203,4,0,
101,4,205,1,29,
101,4,205,4,0,
101,4,207,4,0,
101,4,214,4,0,
101,4,216,4,0,
101,4,218,4,0,
101,4,237,4,0,
101,4,240,4,0,
101,4,243,1,48,
101,5,33,1,1,2
101,5,49,1,1,4
101,5,49,1,15,
101,5,63,4,0,
101,5,85,4,0,
101,5,87,4,0,
101,5,92,4,0,
101,5,103,1,1,3
101,5,103,1,8,
101,5,104,4,0,
101,5,113,1,41,
101,5,113,4,0,
101,5,120,1,27,
101,5,129,1,48,
101,5,148,4,0,
101,5,153,1,54,
101,5,156,4,0,
101,5,168,4,0,
101,5,182,4,0,
101,5,205,1,34,
101,5,209,1,21,
101,5,216,4,0,
101,5,218,4,0,
101,5,237,4,0,
101,5,240,4,0,
101,5,243,1,59,
101,5,259,4,0,
101,5,263,4,0,
101,5,268,1,1,1
101,5,269,4,0,
101,5,290,4,0,
101,5,351,4,0,
101,6,33,1,1,2
101,6,49,1,1,4
101,6,49,1,15,
101,6,63,4,0,
101,6,85,4,0,
101,6,86,3,0,
101,6,87,4,0,
101,6,92,4,0,
101,6,102,3,0,
101,6,103,1,1,3
101,6,103,1,8,
101,6,104,4,0,
101,6,113,1,41,
101,6,113,4,0,
101,6,120,1,27,
101,6,129,1,48,
101,6,129,3,0,
101,6,148,4,0,
101,6,153,1,54,
101,6,153,3,0,
101,6,156,4,0,
101,6,164,3,0,
101,6,168,4,0,
101,6,173,3,0,
101,6,182,4,0,
101,6,203,3,0,
101,6,205,1,34,
101,6,205,3,0,
101,6,207,3,0,
101,6,209,1,21,
101,6,214,3,0,
101,6,216,4,0,
101,6,218,4,0,
101,6,237,4,0,
101,6,240,4,0,
101,6,243,1,59,
101,6,259,4,0,
101,6,263,4,0,
101,6,268,1,1,1
101,6,269,4,0,
101,6,290,4,0,
101,6,351,4,0,
101,7,33,1,1,2
101,7,49,1,1,4
101,7,49,1,15,
101,7,63,4,0,
101,7,85,4,0,
101,7,86,3,0,
101,7,87,4,0,
101,7,92,4,0,
101,7,102,3,0,
101,7,103,1,1,3
101,7,103,1,8,
101,7,104,4,0,
101,7,113,1,41,
101,7,113,4,0,
101,7,120,1,27,
101,7,129,1,48,
101,7,148,4,0,
101,7,153,1,54,
101,7,153,3,0,
101,7,156,4,0,
101,7,164,3,0,
101,7,168,4,0,
101,7,182,4,0,
101,7,205,1,34,
101,7,209,1,21,
101,7,216,4,0,
101,7,218,4,0,
101,7,237,4,0,
101,7,240,4,0,
101,7,243,1,59,
101,7,259,4,0,
101,7,263,4,0,
101,7,268,1,1,1
101,7,269,4,0,
101,7,290,4,0,
101,7,351,4,0,
101,8,33,1,1,2
101,8,33,1,5,
101,8,49,1,1,3
101,8,49,1,8,
101,8,63,4,0,
101,8,85,4,0,
101,8,86,4,0,
101,8,87,4,0,
101,8,92,4,0,
101,8,103,1,19,
101,8,104,4,0,
101,8,113,1,22,
101,8,113,4,0,
101,8,120,1,29,
101,8,129,1,35,
101,8,148,4,0,
101,8,153,1,51,
101,8,153,4,0,
101,8,156,4,0,
101,8,164,4,0,
101,8,168,4,0,
101,8,182,4,0,
101,8,203,4,0,
101,8,205,1,15,
101,8,207,4,0,
101,8,209,1,1,4
101,8,209,1,12,
101,8,214,4,0,
101,8,216,4,0,
101,8,218,4,0,
101,8,237,4,0,
101,8,240,4,0,
101,8,243,1,57,
101,8,259,4,0,
101,8,263,4,0,
101,8,268,1,1,1
101,8,269,4,0,
101,8,290,4,0,
101,8,351,4,0,
101,8,360,1,46,
101,8,360,4,0,
101,8,363,4,0,
101,8,393,1,40,
101,8,416,4,0,
101,8,451,1,26,
101,8,451,4,0,
101,9,33,1,1,2
101,9,33,1,5,
101,9,49,1,1,3
101,9,49,1,8,
101,9,63,4,0,
101,9,85,4,0,
101,9,86,4,0,
101,9,87,4,0,
101,9,92,4,0,
101,9,103,1,19,
101,9,104,4,0,
101,9,113,1,22,
101,9,113,4,0,
101,9,120,1,29,
101,9,129,1,35,
101,9,129,3,0,
101,9,148,4,0,
101,9,153,1,51,
101,9,153,4,0,
101,9,156,4,0,
101,9,164,4,0,
101,9,168,4,0,
101,9,173,3,0,
101,9,182,4,0,
101,9,203,4,0,
101,9,205,1,15,
101,9,205,3,0,
101,9,207,4,0,
101,9,209,1,1,4
101,9,209,1,12,
101,9,214,4,0,
101,9,216,4,0,
101,9,218,4,0,
101,9,237,4,0,
101,9,240,4,0,
101,9,243,1,57,
101,9,259,4,0,
101,9,263,4,0,
101,9,268,1,1,1
101,9,269,4,0,
101,9,290,4,0,
101,9,324,3,0,
101,9,351,4,0,
101,9,360,1,46,
101,9,360,4,0,
101,9,363,4,0,
101,9,389,3,0,
101,9,393,1,40,
101,9,393,3,0,
101,9,416,4,0,
101,9,451,1,26,
101,9,451,4,0,
101,10,29,3,0,
101,10,33,1,1,2
101,10,33,1,5,
101,10,49,1,1,3
101,10,49,1,8,
101,10,63,4,0,
101,10,85,4,0,
101,10,86,4,0,
101,10,87,4,0,
101,10,92,4,0,
101,10,103,1,19,
101,10,104,4,0,
101,10,113,1,22,
101,10,113,4,0,
101,10,120,1,29,
101,10,129,1,35,
101,10,129,3,0,
101,10,148,4,0,
101,10,153,1,51,
101,10,153,4,0,
101,10,156,4,0,
101,10,164,4,0,
101,10,168,4,0,
101,10,173,3,0,
101,10,182,4,0,
101,10,203,4,0,
101,10,205,1,15,
101,10,205,3,0,
101,10,207,4,0,
101,10,209,1,1,4
101,10,209,1,12,
101,10,214,4,0,
101,10,216,4,0,
101,10,218,4,0,
101,10,237,4,0,
101,10,240,4,0,
101,10,243,1,57,
101,10,259,4,0,
101,10,263,4,0,
101,10,268,1,1,1
101,10,269,4,0,
101,10,277,3,0,
101,10,290,4,0,
101,10,324,3,0,
101,10,351,4,0,
101,10,360,1,46,
101,10,360,4,0,
101,10,363,4,0,
101,10,389,3,0,
101,10,393,1,40,
101,10,393,3,0,
101,10,416,4,0,
101,10,451,1,26,
101,10,451,4,0,
101,11,33,1,1,2
101,11,33,1,5,
101,11,49,1,1,3
101,11,49,1,8,
101,11,63,4,0,
101,11,85,4,0,
101,11,86,4,0,
101,11,87,4,0,
101,11,92,4,0,
101,11,103,1,19,
101,11,104,4,0,
101,11,113,1,26,
101,11,113,4,0,
101,11,120,1,35,
101,11,129,1,40,
101,11,148,4,0,
101,11,153,1,57,
101,11,153,4,0,
101,11,156,4,0,
101,11,164,4,0,
101,11,168,4,0,
101,11,182,4,0,
101,11,205,1,15,
101,11,207,4,0,
101,11,209,1,1,4
101,11,209,1,12,
101,11,216,4,0,
101,11,218,4,0,
101,11,237,4,0,
101,11,240,4,0,
101,11,243,1,62,
101,11,259,4,0,
101,11,263,4,0,
101,11,268,1,1,1
101,11,269,4,0,
101,11,360,1,51,
101,11,360,4,0,
101,11,393,1,46,
101,11,416,4,0,
101,11,451,1,22,
101,11,451,4,0,
101,11,486,1,29,
101,11,496,4,0,
101,11,521,4,0,
101,11,528,4,0,
101,12,33,1,1,2
101,12,49,1,1,4
101,12,49,1,15,
101,12,63,4,0,
101,12,85,4,0,
101,12,87,4,0,
101,12,92,4,0,
101,12,103,1,1,3
101,12,103,1,8,
101,12,104,4,0,
101,12,113,1,41,
101,12,113,4,0,
101,12,120,1,27,
101,12,129,1,48,
101,12,148,4,0,
101,12,153,1,54,
101,12,156,4,0,
101,12,168,4,0,
101,12,182,4,0,
101,12,205,1,34,
101,12,209,1,21,
101,12,216,4,0,
101,12,218,4,0,
101,12,237,4,0,
101,12,240,4,0,
101,12,243,1,59,
101,12,259,4,0,
101,12,263,4,0,
101,12,268,1,1,1
101,12,269,4,0,
101,12,290,4,0,
101,12,351,4,0,
101,13,33,1,1,2
101,13,49,1,1,4
101,13,49,1,15,
101,13,63,4,0,
101,13,85,4,0,
101,13,86,3,0,
101,13,87,4,0,
101,13,92,4,0,
101,13,102,3,0,
101,13,103,1,1,3
101,13,103,1,8,
101,13,104,4,0,
101,13,113,1,41,
101,13,113,4,0,
101,13,120,1,27,
101,13,120,3,0,
101,13,129,1,48,
101,13,148,4,0,
101,13,153,1,54,
101,13,156,4,0,
101,13,164,3,0,
101,13,168,4,0,
101,13,182,4,0,
101,13,205,1,34,
101,13,207,3,0,
101,13,209,1,21,
101,13,216,4,0,
101,13,218,4,0,
101,13,237,4,0,
101,13,240,4,0,
101,13,243,1,59,
101,13,259,4,0,
101,13,263,4,0,
101,13,268,1,1,1
101,13,269,4,0,
101,13,290,4,0,
101,13,351,4,0,
101,14,33,1,1,2
101,14,33,1,5,
101,14,49,1,1,3
101,14,49,1,8,
101,14,63,4,0,
101,14,85,4,0,
101,14,86,4,0,
101,14,87,4,0,
101,14,92,4,0,
101,14,103,1,19,
101,14,104,4,0,
101,14,113,1,26,
101,14,113,4,0,
101,14,120,1,35,
101,14,129,1,40,
101,14,148,4,0,
101,14,153,1,57,
101,14,153,4,0,
101,14,156,4,0,
101,14,164,4,0,
101,14,168,4,0,
101,14,173,3,0,
101,14,182,4,0,
101,14,205,1,15,
101,14,207,4,0,
101,14,209,1,1,4
101,14,209,1,12,
101,14,214,3,0,
101,14,216,4,0,
101,14,218,4,0,
101,14,237,4,0,
101,14,240,4,0,
101,14,243,1,62,
101,14,259,4,0,
101,14,263,4,0,
101,14,268,1,1,1
101,14,269,4,0,
101,14,277,3,0,
101,14,324,3,0,
101,14,360,1,51,
101,14,360,4,0,
101,14,393,1,46,
101,14,393,3,0,
101,14,416,4,0,
101,14,451,1,22,
101,14,451,4,0,
101,14,486,1,29,
101,14,492,3,0,
101,14,496,4,0,
101,14,521,4,0,
101,14,528,4,0,
101,15,33,1,1,3
101,15,33,1,5,
101,15,49,1,1,4
101,15,63,4,0,
101,15,85,4,0,
101,15,86,4,0,
101,15,87,4,0,
101,15,92,4,0,
101,15,103,1,19,
101,15,104,4,0,
101,15,113,1,26,
101,15,113,4,0,
101,15,120,1,35,
101,15,129,1,40,
101,15,148,4,0,
101,15,153,1,57,
101,15,153,4,0,
101,15,156,4,0,
101,15,164,4,0,
101,15,168,4,0,
101,15,182,4,0,
101,15,205,1,15,
101,15,207,4,0,
101,15,209,1,1,5
101,15,209,1,12,
101,15,214,4,0,
101,15,216,4,0,
101,15,218,4,0,
101,15,237,4,0,
101,15,240,4,0,
101,15,243,1,62,
101,15,259,4,0,
101,15,263,4,0,
101,15,268,1,1,2
101,15,269,4,0,
101,15,360,1,51,
101,15,360,4,0,
101,15,393,1,46,
101,15,416,4,0,
101,15,451,1,22,
101,15,451,4,0,
101,15,486,1,29,
101,15,496,4,0,
101,15,521,4,0,
101,15,528,4,0,
101,15,590,4,0,
101,15,598,1,8,
101,15,602,1,1,1
101,16,33,1,1,3
101,16,49,1,1,4
101,16,49,1,4,1
101,16,63,4,0,
101,16,85,4,0,
101,16,86,4,0,
101,16,87,4,0,
101,16,92,4,0,
101,16,103,1,13,1
101,16,104,4,0,
101,16,113,1,29,1
101,16,113,4,0,
101,16,120,1,26,1
101,16,129,1,20,1
101,16,148,4,0,
101,16,153,1,47,1
101,16,153,4,0,
101,16,156,4,0,
101,16,164,4,0,
101,16,168,4,0,
101,16,173,3,0,
101,16,182,4,0,
101,16,205,1,11,1
101,16,207,4,0,
101,16,209,1,1,5
101,16,209,1,9,1
101,16,214,4,0,
101,16,216,4,0,
101,16,218,4,0,
101,16,237,4,0,
101,16,240,4,0,
101,16,243,1,58,1
101,16,259,4,0,
101,16,263,4,0,
101,16,268,1,1,2
101,16,269,4,0,
101,16,277,3,0,
101,16,290,4,0,
101,16,324,3,0,
101,16,351,3,0,
101,16,360,1,54,1
101,16,360,4,0,
101,16,393,1,36,1
101,16,393,3,0,
101,16,416,4,0,
101,16,435,1,41,1
101,16,451,1,16,1
101,16,451,4,0,
101,16,486,1,22,1
101,16,492,3,0,
101,16,496,4,0,
101,16,521,4,0,
101,16,528,4,0,
101,16,590,4,0,
101,16,598,1,6,1
101,16,602,1,1,1
101,17,33,1,1,3
101,17,49,1,1,4
101,17,49,1,4,
101,17,63,4,0,
101,17,85,4,0,
101,17,86,4,0,
101,17,87,4,0,
101,17,92,4,0,
101,17,103,1,13,
101,17,104,4,0,
101,17,113,1,29,
101,17,113,4,0,
101,17,120,1,26,
101,17,129,1,20,
101,17,153,1,47,
101,17,153,4,0,
101,17,156,4,0,
101,17,164,4,0,
101,17,168,4,0,
101,17,182,4,0,
101,17,205,1,11,
101,17,207,4,0,
101,17,209,1,9,
101,17,214,4,0,
101,17,216,4,0,
101,17,218,4,0,
101,17,237,4,0,
101,17,240,4,0,
101,17,243,1,58,
101,17,259,4,0,
101,17,263,4,0,
101,17,268,1,1,2
101,17,269,4,0,
101,17,360,1,54,
101,17,360,4,0,
101,17,393,1,36,
101,17,416,4,0,
101,17,435,1,41,
101,17,451,1,16,
101,17,451,4,0,
101,17,486,1,22,
101,17,496,4,0,
101,17,521,4,0,
101,17,528,4,0,
101,17,590,4,0,
101,17,598,1,1,5
101,17,598,1,6,
101,17,602,1,1,1
101,18,33,1,1,3
101,18,49,1,1,4
101,18,49,1,4,
101,18,63,4,0,
101,18,85,4,0,
101,18,86,4,0,
101,18,87,4,0,
101,18,92,4,0,
101,18,103,1,13,
101,18,104,4,0,
101,18,113,1,29,
101,18,113,4,0,
101,18,120,1,26,
101,18,129,1,20,
101,18,153,1,47,
101,18,153,4,0,
101,18,156,4,0,
101,18,164,4,0,
101,18,168,4,0,
101,18,182,4,0,
101,18,205,1,11,
101,18,207,4,0,
101,18,209,1,9,
101,18,214,4,0,
101,18,216,4,0,
101,18,218,4,0,
101,18,237,4,0,
101,18,240,4,0,
101,18,243,1,58,
101,18,259,4,0,
101,18,263,4,0,
101,18,268,1,1,2
101,18,269,4,0,
101,18,360,1,54,
101,18,360,4,0,
101,18,393,1,36,
101,18,416,4,0,
101,18,435,1,41,
101,18,451,1,16,
101,18,451,4,0,
101,18,486,1,22,
101,18,496,4,0,
101,18,521,4,0,
101,18,528,4,0,
101,18,590,4,0,
101,18,598,1,1,5
101,18,598,1,6,
101,18,602,1,1,1
102,1,36,4,0,
102,1,38,4,0,
102,1,73,1,28,
102,1,76,1,42,
102,1,77,1,37,
102,1,78,1,32,
102,1,79,1,48,
102,1,92,4,0,
102,1,94,4,0,
102,1,95,1,1,2
102,1,99,4,0,
102,1,100,4,0,
102,1,102,4,0,
102,1,104,4,0,
102,1,115,1,25,
102,1,115,4,0,
102,1,117,4,0,
102,1,120,4,0,
102,1,121,4,0,
102,1,140,1,1,1
102,1,149,4,0,
102,1,153,4,0,
102,1,156,4,0,
102,1,164,4,0,
102,2,36,4,0,
102,2,38,4,0,
102,2,73,1,28,
102,2,76,1,42,
102,2,77,1,37,
102,2,78,1,32,
102,2,79,1,48,
102,2,92,4,0,
102,2,94,4,0,
102,2,95,1,1,2
102,2,99,4,0,
102,2,100,4,0,
102,2,102,4,0,
102,2,104,4,0,
102,2,115,1,25,
102,2,115,4,0,
102,2,117,4,0,
102,2,120,4,0,
102,2,121,4,0,
102,2,140,1,1,1
102,2,149,4,0,
102,2,153,4,0,
102,2,156,4,0,
102,2,164,4,0,
102,3,70,4,0,
102,3,72,2,0,
102,3,73,1,13,
102,3,76,1,43,
102,3,76,4,0,
102,3,77,1,31,
102,3,78,1,25,
102,3,79,1,37,
102,3,92,4,0,
102,3,93,1,19,
102,3,94,4,0,
102,3,95,1,1,2
102,3,104,4,0,
102,3,115,1,7,
102,3,115,2,0,
102,3,138,4,0,
102,3,140,1,1,1
102,3,148,4,0,
102,3,156,4,0,
102,3,168,4,0,
102,3,171,4,0,
102,3,173,4,0,
102,3,174,4,0,
102,3,182,4,0,
102,3,188,4,0,
102,3,202,4,0,
102,3,203,4,0,
102,3,205,4,0,
102,3,207,4,0,
102,3,213,4,0,
102,3,214,4,0,
102,3,216,4,0,
102,3,218,4,0,
102,3,235,2,0,
102,3,236,2,0,
102,3,237,4,0,
102,3,241,4,0,
102,3,244,4,0,
102,3,246,2,0,
102,4,70,4,0,
102,4,72,2,0,
102,4,73,1,13,
102,4,76,1,43,
102,4,76,4,0,
102,4,77,1,31,
102,4,78,1,25,
102,4,79,1,37,
102,4,92,4,0,
102,4,93,1,19,
102,4,94,4,0,
102,4,95,1,1,2
102,4,104,4,0,
102,4,115,1,7,
102,4,115,2,0,
102,4,138,4,0,
102,4,140,1,1,1
102,4,148,4,0,
102,4,156,4,0,
102,4,168,4,0,
102,4,171,4,0,
102,4,173,4,0,
102,4,174,4,0,
102,4,182,4,0,
102,4,188,4,0,
102,4,202,4,0,
102,4,203,4,0,
102,4,205,4,0,
102,4,207,4,0,
102,4,213,4,0,
102,4,214,4,0,
102,4,216,4,0,
102,4,218,4,0,
102,4,235,2,0,
102,4,236,2,0,
102,4,237,4,0,
102,4,241,4,0,
102,4,244,4,0,
102,4,246,2,0,
102,5,70,4,0,
102,5,73,1,13,
102,5,76,1,43,
102,5,76,4,0,
102,5,77,1,31,
102,5,78,1,25,
102,5,79,1,37,
102,5,92,4,0,
102,5,93,1,19,
102,5,94,4,0,
102,5,95,1,1,3
102,5,104,4,0,
102,5,113,4,0,
102,5,115,1,7,
102,5,115,2,0,
102,5,115,4,0,
102,5,140,1,1,1
102,5,148,4,0,
102,5,156,4,0,
102,5,168,4,0,
102,5,174,2,0,
102,5,182,4,0,
102,5,188,4,0,
102,5,202,4,0,
102,5,213,4,0,
102,5,216,4,0,
102,5,218,4,0,
102,5,235,2,0,
102,5,236,2,0,
102,5,237,4,0,
102,5,241,4,0,
102,5,244,2,0,
102,5,246,2,0,
102,5,253,1,1,2
102,5,263,4,0,
102,5,275,2,0,
102,5,285,4,0,
102,5,290,4,0,
102,5,331,4,0,
102,6,38,3,0,
102,6,70,4,0,
102,6,73,1,13,
102,6,76,1,43,
102,6,76,4,0,
102,6,77,1,31,
102,6,78,1,25,
102,6,79,1,37,
102,6,92,4,0,
102,6,93,1,19,
102,6,94,4,0,
102,6,95,1,1,3
102,6,102,3,0,
102,6,104,4,0,
102,6,113,4,0,
102,6,115,1,7,
102,6,115,2,0,
102,6,115,4,0,
102,6,138,3,0,
102,6,140,1,1,1
102,6,148,4,0,
102,6,153,3,0,
102,6,156,4,0,
102,6,164,3,0,
102,6,168,4,0,
102,6,173,3,0,
102,6,174,2,0,
102,6,182,4,0,
102,6,188,4,0,
102,6,202,4,0,
102,6,203,3,0,
102,6,205,3,0,
102,6,207,3,0,
102,6,213,4,0,
102,6,214,3,0,
102,6,216,4,0,
102,6,218,4,0,
102,6,235,2,0,
102,6,236,2,0,
102,6,237,4,0,
102,6,241,4,0,
102,6,244,2,0,
102,6,244,3,0,
102,6,246,2,0,
102,6,253,1,1,2
102,6,263,4,0,
102,6,275,2,0,
102,6,285,4,0,
102,6,290,4,0,
102,6,331,4,0,
102,7,38,3,0,
102,7,70,4,0,
102,7,73,1,13,
102,7,76,1,43,
102,7,76,4,0,
102,7,77,1,31,
102,7,78,1,25,
102,7,79,1,37,
102,7,92,4,0,
102,7,93,1,19,
102,7,94,4,0,
102,7,95,1,1,3
102,7,102,3,0,
102,7,104,4,0,
102,7,113,4,0,
102,7,115,1,7,
102,7,115,2,0,
102,7,115,4,0,
102,7,138,3,0,
102,7,140,1,1,1
102,7,148,4,0,
102,7,153,3,0,
102,7,156,4,0,
102,7,164,3,0,
102,7,168,4,0,
102,7,174,2,0,
102,7,182,4,0,
102,7,188,4,0,
102,7,202,4,0,
102,7,213,4,0,
102,7,216,4,0,
102,7,218,4,0,
102,7,235,2,0,
102,7,236,2,0,
102,7,237,4,0,
102,7,241,4,0,
102,7,244,2,0,
102,7,246,2,0,
102,7,253,1,1,2
102,7,263,4,0,
102,7,275,2,0,
102,7,285,4,0,
102,7,290,4,0,
102,7,331,4,0,
102,8,14,4,0,
102,8,70,4,0,
102,8,73,1,11,
102,8,76,1,43,
102,8,76,4,0,
102,8,77,1,21,
102,8,78,1,19,
102,8,79,1,23,
102,8,92,4,0,
102,8,93,1,27,
102,8,94,1,47,
102,8,94,4,0,
102,8,95,1,1,3
102,8,104,4,0,
102,8,113,4,0,
102,8,115,1,7,
102,8,115,2,0,
102,8,115,4,0,
102,8,138,4,0,
102,8,140,1,1,1
102,8,148,4,0,
102,8,153,4,0,
102,8,156,4,0,
102,8,164,4,0,
102,8,168,4,0,
102,8,174,2,0,
102,8,182,4,0,
102,8,188,4,0,
102,8,202,4,0,
102,8,203,4,0,
102,8,207,4,0,
102,8,213,4,0,
102,8,214,4,0,
102,8,216,4,0,
102,8,218,4,0,
102,8,235,2,0,
102,8,236,2,0,
102,8,237,4,0,
102,8,241,4,0,
102,8,244,2,0,
102,8,244,4,0,
102,8,246,2,0,
102,8,253,1,1,2
102,8,263,4,0,
102,8,267,2,0,
102,8,275,2,0,
102,8,285,4,0,
102,8,290,4,0,
102,8,331,1,17,
102,8,331,4,0,
102,8,363,1,37,
102,8,363,4,0,
102,8,381,2,0,
102,8,388,1,33,
102,8,412,4,0,
102,8,433,4,0,
102,8,437,2,0,
102,8,445,4,0,
102,8,447,4,0,
102,9,14,4,0,
102,9,70,4,0,
102,9,73,1,11,
102,9,76,1,43,
102,9,76,4,0,
102,9,77,1,21,
102,9,78,1,19,
102,9,79,1,23,
102,9,92,4,0,
102,9,93,1,27,
102,9,94,1,47,
102,9,94,4,0,
102,9,95,1,1,3
102,9,104,4,0,
102,9,113,4,0,
102,9,115,1,7,
102,9,115,2,0,
102,9,115,4,0,
102,9,138,4,0,
102,9,140,1,1,1
102,9,148,4,0,
102,9,153,4,0,
102,9,156,4,0,
102,9,164,4,0,
102,9,168,4,0,
102,9,173,3,0,
102,9,174,2,0,
102,9,182,4,0,
102,9,188,4,0,
102,9,202,4,0,
102,9,203,4,0,
102,9,205,3,0,
102,9,207,4,0,
102,9,213,4,0,
102,9,214,4,0,
102,9,216,4,0,
102,9,218,4,0,
102,9,235,2,0,
102,9,235,3,0,
102,9,236,2,0,
102,9,237,4,0,
102,9,241,4,0,
102,9,244,2,0,
102,9,244,4,0,
102,9,246,2,0,
102,9,246,3,0,
102,9,253,1,1,2
102,9,253,3,0,
102,9,263,4,0,
102,9,267,2,0,
102,9,275,2,0,
102,9,285,4,0,
102,9,290,4,0,
102,9,331,1,17,
102,9,331,4,0,
102,9,363,1,37,
102,9,363,4,0,
102,9,381,2,0,
102,9,388,1,33,
102,9,402,3,0,
102,9,412,4,0,
102,9,433,4,0,
102,9,437,2,0,
102,9,445,4,0,
102,9,447,4,0,
102,10,14,4,0,
102,10,70,4,0,
102,10,73,1,11,
102,10,76,1,43,
102,10,76,4,0,
102,10,77,1,21,
102,10,78,1,19,
102,10,79,1,23,
102,10,92,4,0,
102,10,93,1,27,
102,10,94,1,47,
102,10,94,4,0,
102,10,95,1,1,3
102,10,104,4,0,
102,10,113,4,0,
102,10,115,1,7,
102,10,115,2,0,
102,10,115,4,0,
102,10,138,4,0,
102,10,140,1,1,1
102,10,148,4,0,
102,10,153,4,0,
102,10,156,4,0,
102,10,164,4,0,
102,10,168,4,0,
102,10,173,3,0,
102,10,174,2,0,
102,10,182,4,0,
102,10,188,4,0,
102,10,202,4,0,
102,10,203,4,0,
102,10,205,3,0,
102,10,207,4,0,
102,10,213,4,0,
102,10,214,4,0,
102,10,216,4,0,
102,10,218,4,0,
102,10,235,2,0,
102,10,235,3,0,
102,10,236,2,0,
102,10,237,4,0,
102,10,241,4,0,
102,10,244,2,0,
102,10,244,4,0,
102,10,246,2,0,
102,10,246,3,0,
102,10,253,1,1,2
102,10,253,3,0,
102,10,263,4,0,
102,10,267,2,0,
102,10,275,2,0,
102,10,285,4,0,
102,10,290,4,0,
102,10,331,1,17,
102,10,331,4,0,
102,10,356,3,0,
102,10,363,1,37,
102,10,363,4,0,
102,10,381,2,0,
102,10,384,2,0,
102,10,388,1,33,
102,10,388,3,0,
102,10,402,3,0,
102,10,412,4,0,
102,10,433,4,0,
102,10,437,2,0,
102,10,445,4,0,
102,10,447,4,0,
102,11,14,4,0,
102,11,70,4,0,
102,11,73,1,11,
102,11,76,1,43,
102,11,76,4,0,
102,11,77,1,21,
102,11,78,1,19,
102,11,79,1,23,
102,11,92,4,0,
102,11,93,1,27,
102,11,94,1,47,
102,11,94,4,0,
102,11,95,1,1,3
102,11,104,4,0,
102,11,113,4,0,
102,11,115,1,7,
102,11,115,4,0,
102,11,138,4,0,
102,11,140,1,1,1
102,11,148,4,0,
102,11,153,4,0,
102,11,156,4,0,
102,11,164,4,0,
102,11,168,4,0,
102,11,174,2,0,
102,11,182,4,0,
102,11,188,4,0,
102,11,202,2,0,
102,11,207,4,0,
102,11,213,4,0,
102,11,216,4,0,
102,11,218,4,0,
102,11,235,2,0,
102,11,236,2,0,
102,11,237,4,0,
102,11,241,4,0,
102,11,244,4,0,
102,11,246,2,0,
102,11,253,1,1,2
102,11,263,4,0,
102,11,267,2,0,
102,11,275,2,0,
102,11,285,2,0,
102,11,331,1,17,
102,11,335,2,0,
102,11,363,1,37,
102,11,363,2,0,
102,11,381,2,0,
102,11,384,2,0,
102,11,388,1,33,
102,11,412,4,0,
102,11,433,4,0,
102,11,437,2,0,
102,11,447,4,0,
102,11,477,4,0,
102,11,496,4,0,
102,11,516,1,53,
102,12,70,4,0,
102,12,73,1,13,
102,12,76,1,43,
102,12,76,4,0,
102,12,77,1,31,
102,12,78,1,25,
102,12,79,1,37,
102,12,92,4,0,
102,12,93,1,19,
102,12,94,4,0,
102,12,95,1,1,3
102,12,104,4,0,
102,12,113,4,0,
102,12,115,1,7,
102,12,115,4,0,
102,12,140,1,1,1
102,12,148,4,0,
102,12,156,4,0,
102,12,168,4,0,
102,12,182,4,0,
102,12,188,4,0,
102,12,202,4,0,
102,12,213,4,0,
102,12,216,4,0,
102,12,218,4,0,
102,12,237,4,0,
102,12,241,4,0,
102,12,253,1,1,2
102,12,263,4,0,
102,12,285,4,0,
102,12,290,4,0,
102,12,331,4,0,
102,13,38,3,0,
102,13,70,4,0,
102,13,73,1,13,
102,13,76,1,43,
102,13,76,4,0,
102,13,77,1,31,
102,13,78,1,25,
102,13,79,1,37,
102,13,92,4,0,
102,13,93,1,19,
102,13,94,4,0,
102,13,95,1,1,3
102,13,102,3,0,
102,13,104,4,0,
102,13,113,4,0,
102,13,115,1,7,
102,13,115,4,0,
102,13,120,3,0,
102,13,138,3,0,
102,13,140,1,1,1
102,13,148,4,0,
102,13,156,4,0,
102,13,164,3,0,
102,13,168,4,0,
102,13,171,3,0,
102,13,182,4,0,
102,13,188,4,0,
102,13,202,4,0,
102,13,207,3,0,
102,13,213,4,0,
102,13,216,4,0,
102,13,218,4,0,
102,13,237,4,0,
102,13,241,4,0,
102,13,253,1,1,2
102,13,263,4,0,
102,13,285,4,0,
102,13,290,4,0,
102,13,331,4,0,
102,14,14,4,0,
102,14,70,4,0,
102,14,73,1,11,
102,14,76,1,43,
102,14,76,4,0,
102,14,77,1,21,
102,14,78,1,19,
102,14,79,1,23,
102,14,92,4,0,
102,14,93,1,27,
102,14,94,4,0,
102,14,95,1,1,3
102,14,104,4,0,
102,14,113,4,0,
102,14,115,1,7,
102,14,115,4,0,
102,14,138,4,0,
102,14,140,1,1,1
102,14,148,4,0,
102,14,153,4,0,
102,14,156,4,0,
102,14,164,4,0,
102,14,168,4,0,
102,14,173,3,0,
102,14,174,2,0,
102,14,182,4,0,
102,14,188,4,0,
102,14,202,2,0,
102,14,202,3,0,
102,14,207,4,0,
102,14,213,4,0,
102,14,214,3,0,
102,14,216,4,0,
102,14,218,4,0,
102,14,235,2,0,
102,14,235,3,0,
102,14,236,2,0,
102,14,237,4,0,
102,14,241,4,0,
102,14,244,4,0,
102,14,246,2,0,
102,14,253,1,1,2
102,14,253,3,0,
102,14,263,4,0,
102,14,267,2,0,
102,14,275,2,0,
102,14,285,2,0,
102,14,285,3,0,
102,14,326,1,47,
102,14,331,1,17,
102,14,335,2,0,
102,14,335,3,0,
102,14,356,3,0,
102,14,363,1,37,
102,14,363,2,0,
102,14,381,2,0,
102,14,384,2,0,
102,14,388,1,33,
102,14,388,3,0,
102,14,402,3,0,
102,14,412,4,0,
102,14,433,4,0,
102,14,437,2,0,
102,14,447,4,0,
102,14,477,4,0,
102,14,496,4,0,
102,14,516,1,53,
102,15,14,4,0,
102,15,70,4,0,
102,15,73,1,11,
102,15,76,1,43,
102,15,76,4,0,
102,15,77,1,21,
102,15,78,1,19,
102,15,79,1,23,
102,15,92,4,0,
102,15,93,1,27,
102,15,94,4,0,
102,15,95,1,1,3
102,15,104,4,0,
102,15,113,4,0,
102,15,115,1,7,
102,15,115,4,0,
102,15,138,4,0,
102,15,140,1,1,1
102,15,148,4,0,
102,15,153,4,0,
102,15,156,4,0,
102,15,164,4,0,
102,15,168,4,0,
102,15,174,2,0,
102,15,182,4,0,
102,15,188,4,0,
102,15,202,2,0,
102,15,207,4,0,
102,15,213,4,0,
102,15,214,4,0,
102,15,216,4,0,
102,15,218,4,0,
102,15,235,2,0,
102,15,236,2,0,
102,15,237,4,0,
102,15,241,4,0,
102,15,244,4,0,
102,15,246,2,0,
102,15,253,1,1,2
102,15,263,4,0,
102,15,267,2,0,
102,15,267,4,0,
102,15,275,2,0,
102,15,285,2,0,
102,15,326,1,47,
102,15,331,1,17,
102,15,335,2,0,
102,15,363,1,37,
102,15,363,2,0,
102,15,381,2,0,
102,15,384,2,0,
102,15,388,1,33,
102,15,412,4,0,
102,15,433,4,0,
102,15,437,2,0,
102,15,447,4,0,
102,15,496,4,0,
102,15,516,1,50,
102,15,580,2,0,
102,15,590,4,0,
102,15,611,4,0,
102,16,14,4,0,
102,16,70,4,0,
102,16,73,1,11,1
102,16,76,1,43,1
102,16,76,4,0,
102,16,77,1,21,1
102,16,78,1,19,1
102,16,79,1,23,1
102,16,92,4,0,
102,16,93,1,27,1
102,16,94,4,0,
102,16,95,1,1,3
102,16,104,4,0,
102,16,113,4,0,
102,16,115,1,7,1
102,16,115,4,0,
102,16,138,4,0,
102,16,140,1,1,1
102,16,148,4,0,
102,16,153,4,0,
102,16,156,4,0,
102,16,164,4,0,
102,16,168,4,0,
102,16,173,3,0,
102,16,174,2,0,
102,16,182,4,0,
102,16,188,4,0,
102,16,202,2,0,
102,16,202,3,0,
102,16,207,4,0,
102,16,213,4,0,
102,16,214,4,0,
102,16,216,4,0,
102,16,218,4,0,
102,16,235,2,0,
102,16,235,3,0,
102,16,236,2,0,
102,16,237,4,0,
102,16,241,4,0,
102,16,244,4,0,
102,16,246,2,0,
102,16,253,1,1,2
102,16,253,3,0,
102,16,263,4,0,
102,16,267,2,0,
102,16,267,4,0,
102,16,275,2,0,
102,16,285,2,0,
102,16,285,3,0,
102,16,290,4,0,
102,16,326,1,47,1
102,16,331,1,17,1
102,16,335,2,0,
102,16,335,3,0,
102,16,356,3,0,
102,16,363,1,37,1
102,16,363,2,0,
102,16,381,2,0,
102,16,384,2,0,
102,16,388,1,33,1
102,16,388,3,0,
102,16,402,3,0,
102,16,412,4,0,
102,16,433,4,0,
102,16,437,2,0,
102,16,447,4,0,
102,16,496,4,0,
102,16,516,1,50,1
102,16,580,2,0,
102,16,590,4,0,
102,16,611,4,0,
102,17,14,4,0,
102,17,73,1,11,
102,17,76,1,43,
102,17,76,4,0,
102,17,77,1,21,
102,17,78,1,19,
102,17,79,1,23,
102,17,92,4,0,
102,17,93,1,27,
102,17,94,4,0,
102,17,95,1,1,3
102,17,104,4,0,
102,17,113,4,0,
102,17,115,1,7,
102,17,115,4,0,
102,17,138,4,0,
102,17,140,1,1,1
102,17,153,4,0,
102,17,156,4,0,
102,17,164,4,0,
102,17,168,4,0,
102,17,174,2,0,
102,17,182,4,0,
102,17,188,4,0,
102,17,202,2,0,
102,17,207,4,0,
102,17,213,4,0,
102,17,214,4,0,
102,17,216,4,0,
102,17,218,4,0,
102,17,235,2,0,
102,17,236,2,0,
102,17,237,4,0,
102,17,241,4,0,
102,17,244,4,0,
102,17,246,2,0,
102,17,253,1,1,2
102,17,263,4,0,
102,17,267,2,0,
102,17,267,4,0,
102,17,275,2,0,
102,17,285,2,0,
102,17,326,1,47,
102,17,331,1,17,
102,17,335,2,0,
102,17,363,1,37,
102,17,363,2,0,
102,17,381,2,0,
102,17,384,2,0,
102,17,388,1,33,
102,17,412,4,0,
102,17,433,4,0,
102,17,437,2,0,
102,17,447,4,0,
102,17,496,4,0,
102,17,516,1,50,
102,17,580,2,0,
102,17,590,4,0,
102,17,611,4,0,
102,18,14,4,0,
102,18,73,1,11,
102,18,76,1,43,
102,18,76,4,0,
102,18,77,1,21,
102,18,78,1,19,
102,18,79,1,23,
102,18,92,4,0,
102,18,93,1,27,
102,18,94,4,0,
102,18,95,1,1,3
102,18,104,4,0,
102,18,113,4,0,
102,18,115,1,7,
102,18,115,4,0,
102,18,138,4,0,
102,18,140,1,1,1
102,18,153,4,0,
102,18,156,4,0,
102,18,164,4,0,
102,18,168,4,0,
102,18,174,2,0,
102,18,182,4,0,
102,18,188,4,0,
102,18,202,2,0,
102,18,207,4,0,
102,18,213,4,0,
102,18,214,4,0,
102,18,216,4,0,
102,18,218,4,0,
102,18,235,2,0,
102,18,236,2,0,
102,18,237,4,0,
102,18,241,4,0,
102,18,244,4,0,
102,18,246,2,0,
102,18,253,1,1,2
102,18,263,4,0,
102,18,267,2,0,
102,18,267,4,0,
102,18,275,2,0,
102,18,285,2,0,
102,18,326,1,47,
102,18,331,1,17,
102,18,335,2,0,
102,18,363,1,37,
102,18,363,2,0,
102,18,381,2,0,
102,18,384,2,0,
102,18,388,1,33,
102,18,412,4,0,
102,18,433,4,0,
102,18,437,2,0,
102,18,447,4,0,
102,18,496,4,0,
102,18,516,1,50,
102,18,580,2,0,
102,18,590,4,0,
102,18,611,4,0,
103,1,23,1,28,
103,1,36,4,0,
103,1,38,4,0,
103,1,63,4,0,
103,1,70,4,0,
103,1,72,4,0,
103,1,76,4,0,
103,1,92,4,0,
103,1,94,4,0,
103,1,95,1,1,2
103,1,99,4,0,
103,1,100,4,0,
103,1,102,4,0,
103,1,104,4,0,
103,1,115,4,0,
103,1,117,4,0,
103,1,120,4,0,
103,1,121,4,0,
103,1,140,1,1,1
103,1,149,4,0,
103,1,153,4,0,
103,1,156,4,0,
103,1,164,4,0,
103,2,23,1,28,
103,2,36,4,0,
103,2,38,4,0,
103,2,63,4,0,
103,2,70,4,0,
103,2,72,4,0,
103,2,76,4,0,
103,2,92,4,0,
103,2,94,4,0,
103,2,95,1,1,2
103,2,99,4,0,
103,2,100,4,0,
103,2,102,4,0,
103,2,104,4,0,
103,2,115,4,0,
103,2,117,4,0,
103,2,120,4,0,
103,2,121,4,0,
103,2,140,1,1,1
103,2,149,4,0,
103,2,153,4,0,
103,2,156,4,0,
103,2,164,4,0,
103,3,23,1,19,
103,3,29,4,0,
103,3,63,4,0,
103,3,70,4,0,
103,3,76,4,0,
103,3,92,4,0,
103,3,93,1,1,3
103,3,94,4,0,
103,3,95,1,1,2
103,3,104,4,0,
103,3,121,1,31,
103,3,138,4,0,
103,3,140,1,1,1
103,3,148,4,0,
103,3,156,4,0,
103,3,168,4,0,
103,3,171,4,0,
103,3,173,4,0,
103,3,174,4,0,
103,3,182,4,0,
103,3,188,4,0,
103,3,202,4,0,
103,3,203,4,0,
103,3,205,4,0,
103,3,207,4,0,
103,3,213,4,0,
103,3,214,4,0,
103,3,216,4,0,
103,3,218,4,0,
103,3,237,4,0,
103,3,241,4,0,
103,3,244,4,0,
103,4,23,1,19,
103,4,29,4,0,
103,4,63,4,0,
103,4,70,4,0,
103,4,76,4,0,
103,4,92,4,0,
103,4,93,1,1,3
103,4,94,4,0,
103,4,95,1,1,2
103,4,104,4,0,
103,4,121,1,31,
103,4,138,4,0,
103,4,140,1,1,1
103,4,148,4,0,
103,4,156,4,0,
103,4,168,4,0,
103,4,171,4,0,
103,4,173,4,0,
103,4,174,4,0,
103,4,182,4,0,
103,4,188,4,0,
103,4,202,4,0,
103,4,203,4,0,
103,4,205,4,0,
103,4,207,4,0,
103,4,213,4,0,
103,4,214,4,0,
103,4,216,4,0,
103,4,218,4,0,
103,4,237,4,0,
103,4,241,4,0,
103,4,244,4,0,
103,5,23,1,19,
103,5,63,4,0,
103,5,70,4,0,
103,5,76,4,0,
103,5,92,4,0,
103,5,93,1,1,3
103,5,94,4,0,
103,5,95,1,1,2
103,5,104,4,0,
103,5,113,4,0,
103,5,115,4,0,
103,5,121,1,31,
103,5,140,1,1,1
103,5,148,4,0,
103,5,156,4,0,
103,5,168,4,0,
103,5,182,4,0,
103,5,188,4,0,
103,5,202,4,0,
103,5,213,4,0,
103,5,216,4,0,
103,5,218,4,0,
103,5,237,4,0,
103,5,241,4,0,
103,5,263,4,0,
103,5,285,4,0,
103,5,290,4,0,
103,5,331,4,0,
103,6,23,1,19,
103,6,38,3,0,
103,6,63,4,0,
103,6,70,4,0,
103,6,76,4,0,
103,6,92,4,0,
103,6,93,1,1,3
103,6,94,4,0,
103,6,95,1,1,2
103,6,102,3,0,
103,6,104,4,0,
103,6,113,4,0,
103,6,115,4,0,
103,6,121,1,31,
103,6,138,3,0,
103,6,140,1,1,1
103,6,148,4,0,
103,6,153,3,0,
103,6,156,4,0,
103,6,164,3,0,
103,6,168,4,0,
103,6,173,3,0,
103,6,182,4,0,
103,6,188,4,0,
103,6,202,4,0,
103,6,203,3,0,
103,6,205,3,0,
103,6,207,3,0,
103,6,213,4,0,
103,6,214,3,0,
103,6,216,4,0,
103,6,218,4,0,
103,6,237,4,0,
103,6,241,4,0,
103,6,244,3,0,
103,6,263,4,0,
103,6,285,4,0,
103,6,290,4,0,
103,6,331,4,0,
103,7,23,1,19,
103,7,38,3,0,
103,7,63,4,0,
103,7,70,4,0,
103,7,76,4,0,
103,7,92,4,0,
103,7,93,1,1,3
103,7,94,4,0,
103,7,95,1,1,2
103,7,102,3,0,
103,7,104,4,0,
103,7,113,4,0,
103,7,115,4,0,
103,7,121,1,31,
103,7,138,3,0,
103,7,140,1,1,1
103,7,148,4,0,
103,7,153,3,0,
103,7,156,4,0,
103,7,164,3,0,
103,7,168,4,0,
103,7,182,4,0,
103,7,188,4,0,
103,7,202,4,0,
103,7,213,4,0,
103,7,216,4,0,
103,7,218,4,0,
103,7,237,4,0,
103,7,241,4,0,
103,7,263,4,0,
103,7,285,4,0,
103,7,290,4,0,
103,7,331,4,0,
103,8,14,4,0,
103,8,23,1,1,5
103,8,23,1,17,
103,8,63,4,0,
103,8,70,4,0,
103,8,76,4,0,
103,8,92,4,0,
103,8,93,1,1,4
103,8,94,4,0,
103,8,95,1,1,3
103,8,104,4,0,
103,8,113,4,0,
103,8,115,4,0,
103,8,121,1,27,
103,8,138,4,0,
103,8,140,1,1,2
103,8,148,4,0,
103,8,153,4,0,
103,8,156,4,0,
103,8,164,4,0,
103,8,168,4,0,
103,8,182,4,0,
103,8,188,4,0,
103,8,202,4,0,
103,8,203,4,0,
103,8,207,4,0,
103,8,213,4,0,
103,8,214,4,0,
103,8,216,4,0,
103,8,218,4,0,
103,8,237,4,0,
103,8,241,4,0,
103,8,244,4,0,
103,8,263,4,0,
103,8,285,4,0,
103,8,290,4,0,
103,8,331,4,0,
103,8,363,4,0,
103,8,402,1,1,1
103,8,412,4,0,
103,8,416,4,0,
103,8,433,4,0,
103,8,437,1,47,
103,8,445,4,0,
103,8,447,4,0,
103,8,452,1,37,
103,9,14,4,0,
103,9,23,1,1,5
103,9,23,1,17,
103,9,63,4,0,
103,9,70,4,0,
103,9,76,4,0,
103,9,92,4,0,
103,9,93,1,1,4
103,9,94,4,0,
103,9,95,1,1,3
103,9,104,4,0,
103,9,113,4,0,
103,9,115,4,0,
103,9,121,1,27,
103,9,138,4,0,
103,9,140,1,1,2
103,9,148,4,0,
103,9,153,4,0,
103,9,156,4,0,
103,9,164,4,0,
103,9,168,4,0,
103,9,173,3,0,
103,9,182,4,0,
103,9,188,4,0,
103,9,202,4,0,
103,9,203,4,0,
103,9,205,3,0,
103,9,207,4,0,
103,9,213,4,0,
103,9,214,4,0,
103,9,216,4,0,
103,9,218,4,0,
103,9,235,3,0,
103,9,237,4,0,
103,9,241,4,0,
103,9,244,4,0,
103,9,246,3,0,
103,9,263,4,0,
103,9,285,4,0,
103,9,290,4,0,
103,9,331,4,0,
103,9,363,4,0,
103,9,402,1,1,1
103,9,402,3,0,
103,9,412,4,0,
103,9,416,4,0,
103,9,428,3,0,
103,9,433,4,0,
103,9,437,1,47,
103,9,445,4,0,
103,9,447,4,0,
103,9,452,1,37,
103,10,14,4,0,
103,10,23,1,1,5
103,10,23,1,17,
103,10,29,3,0,
103,10,63,4,0,
103,10,67,3,0,
103,10,70,4,0,
103,10,76,4,0,
103,10,92,4,0,
103,10,93,1,1,4
103,10,94,4,0,
103,10,95,1,1,3
103,10,104,4,0,
103,10,113,4,0,
103,10,115,4,0,
103,10,121,1,27,
103,10,138,4,0,
103,10,140,1,1,2
103,10,148,4,0,
103,10,153,4,0,
103,10,156,4,0,
103,10,164,4,0,
103,10,168,4,0,
103,10,173,3,0,
103,10,182,4,0,
103,10,188,4,0,
103,10,202,4,0,
103,10,203,4,0,
103,10,205,3,0,
103,10,207,4,0,
103,10,213,4,0,
103,10,214,4,0,
103,10,216,4,0,
103,10,218,4,0,
103,10,235,3,0,
103,10,237,4,0,
103,10,241,4,0,
103,10,244,4,0,
103,10,246,3,0,
103,10,263,4,0,
103,10,285,4,0,
103,10,290,4,0,
103,10,331,4,0,
103,10,356,3,0,
103,10,363,4,0,
103,10,388,3,0,
103,10,402,1,1,1
103,10,402,3,0,
103,10,412,4,0,
103,10,416,4,0,
103,10,428,3,0,
103,10,433,4,0,
103,10,437,1,47,
103,10,445,4,0,
103,10,447,4,0,
103,10,452,1,37,
103,11,14,4,0,
103,11,23,1,1,5
103,11,63,4,0,
103,11,70,4,0,
103,11,76,4,0,
103,11,92,4,0,
103,11,93,1,1,4
103,11,94,4,0,
103,11,95,1,1,3
103,11,104,4,0,
103,11,113,4,0,
103,11,115,4,0,
103,11,121,1,27,
103,11,138,4,0,
103,11,140,1,1,2
103,11,148,4,0,
103,11,153,4,0,
103,11,156,4,0,
103,11,164,4,0,
103,11,168,4,0,
103,11,182,4,0,
103,11,188,4,0,
103,11,207,4,0,
103,11,213,4,0,
103,11,216,4,0,
103,11,218,4,0,
103,11,237,4,0,
103,11,241,4,0,
103,11,244,4,0,
103,11,263,4,0,
103,11,402,1,1,1
103,11,412,4,0,
103,11,416,4,0,
103,11,433,4,0,
103,11,437,1,47,
103,11,447,4,0,
103,11,452,1,37,
103,11,473,1,17,
103,11,473,4,0,
103,11,477,4,0,
103,11,496,4,0,
103,12,23,1,19,
103,12,63,4,0,
103,12,70,4,0,
103,12,76,4,0,
103,12,92,4,0,
103,12,93,1,1,3
103,12,94,4,0,
103,12,95,1,1,2
103,12,104,4,0,
103,12,113,4,0,
103,12,115,4,0,
103,12,121,1,31,
103,12,140,1,1,1
103,12,148,4,0,
103,12,156,4,0,
103,12,168,4,0,
103,12,182,4,0,
103,12,188,4,0,
103,12,202,4,0,
103,12,213,4,0,
103,12,216,4,0,
103,12,218,4,0,
103,12,237,4,0,
103,12,241,4,0,
103,12,263,4,0,
103,12,285,4,0,
103,12,290,4,0,
103,12,331,4,0,
103,13,23,1,19,
103,13,38,3,0,
103,13,63,4,0,
103,13,70,4,0,
103,13,76,4,0,
103,13,92,4,0,
103,13,93,1,1,3
103,13,94,4,0,
103,13,95,1,1,2
103,13,102,3,0,
103,13,104,4,0,
103,13,113,4,0,
103,13,115,4,0,
103,13,120,3,0,
103,13,121,1,31,
103,13,138,3,0,
103,13,140,1,1,1
103,13,148,4,0,
103,13,156,4,0,
103,13,164,3,0,
103,13,168,4,0,
103,13,171,3,0,
103,13,182,4,0,
103,13,188,4,0,
103,13,202,4,0,
103,13,207,3,0,
103,13,213,4,0,
103,13,216,4,0,
103,13,218,4,0,
103,13,237,4,0,
103,13,241,4,0,
103,13,263,4,0,
103,13,285,4,0,
103,13,290,4,0,
103,13,331,4,0,
103,14,14,4,0,
103,14,23,1,1,5
103,14,63,4,0,
103,14,67,3,0,
103,14,70,4,0,
103,14,76,4,0,
103,14,92,4,0,
103,14,93,1,1,4
103,14,94,4,0,
103,14,95,1,1,3
103,14,104,4,0,
103,14,113,4,0,
103,14,115,4,0,
103,14,121,1,27,
103,14,138,4,0,
103,14,140,1,1,2
103,14,148,4,0,
103,14,153,4,0,
103,14,156,4,0,
103,14,164,4,0,
103,14,168,4,0,
103,14,173,3,0,
103,14,182,4,0,
103,14,188,4,0,
103,14,202,3,0,
103,14,207,4,0,
103,14,213,4,0,
103,14,214,3,0,
103,14,216,4,0,
103,14,218,4,0,
103,14,235,3,0,
103,14,237,4,0,
103,14,241,4,0,
103,14,244,4,0,
103,14,263,4,0,
103,14,285,3,0,
103,14,335,3,0,
103,14,356,3,0,
103,14,388,3,0,
103,14,402,1,1,1
103,14,402,3,0,
103,14,412,4,0,
103,14,416,4,0,
103,14,428,3,0,
103,14,433,4,0,
103,14,437,1,47,
103,14,447,4,0,
103,14,452,1,37,
103,14,473,1,17,
103,14,473,4,0,
103,14,477,4,0,
103,14,496,4,0,
103,15,14,4,0,
103,15,23,1,1,5
103,15,63,4,0,
103,15,70,4,0,
103,15,76,4,0,
103,15,92,4,0,
103,15,93,1,1,4
103,15,94,4,0,
103,15,95,1,1,3
103,15,104,4,0,
103,15,113,4,0,
103,15,115,4,0,
103,15,121,1,27,
103,15,138,4,0,
103,15,140,1,1,2
103,15,148,4,0,
103,15,153,4,0,
103,15,156,4,0,
103,15,164,4,0,
103,15,168,4,0,
103,15,182,4,0,
103,15,188,4,0,
103,15,207,4,0,
103,15,213,4,0,
103,15,214,4,0,
103,15,216,4,0,
103,15,218,4,0,
103,15,237,4,0,
103,15,241,4,0,
103,15,244,4,0,
103,15,263,4,0,
103,15,267,4,0,
103,15,402,1,1,1
103,15,412,4,0,
103,15,416,4,0,
103,15,433,4,0,
103,15,437,1,47,
103,15,447,4,0,
103,15,452,1,37,
103,15,473,1,17,
103,15,473,4,0,
103,15,496,4,0,
103,15,590,4,0,
103,15,611,4,0,
103,16,14,4,0,
103,16,23,1,1,5
103,16,63,4,0,
103,16,67,3,0,
103,16,70,4,0,
103,16,76,4,0,
103,16,92,4,0,
103,16,93,1,1,4
103,16,94,4,0,
103,16,95,1,1,3
103,16,104,4,0,
103,16,113,4,0,
103,16,115,4,0,
103,16,121,1,27,1
103,16,138,4,0,
103,16,140,1,1,2
103,16,148,4,0,
103,16,153,4,0,
103,16,156,4,0,
103,16,164,4,0,
103,16,168,4,0,
103,16,173,3,0,
103,16,182,4,0,
103,16,188,4,0,
103,16,202,3,0,
103,16,207,4,0,
103,16,213,4,0,
103,16,214,4,0,
103,16,216,4,0,
103,16,218,4,0,
103,16,235,3,0,
103,16,237,4,0,
103,16,241,4,0,
103,16,244,4,0,
103,16,263,4,0,
103,16,267,4,0,
103,16,285,3,0,
103,16,290,4,0,
103,16,335,3,0,
103,16,356,3,0,
103,16,388,3,0,
103,16,402,1,1,1
103,16,402,3,0,
103,16,412,4,0,
103,16,416,4,0,
103,16,428,3,0,
103,16,433,4,0,
103,16,437,1,47,1
103,16,447,4,0,
103,16,452,1,37,1
103,16,473,1,17,1
103,16,473,4,0,
103,16,496,4,0,
103,16,590,4,0,
103,16,611,4,0,
103,17,14,4,0,
103,17,23,1,0,
103,17,23,1,1,1
103,17,63,4,0,
103,17,76,4,0,
103,17,92,4,0,
103,17,93,1,1,5
103,17,94,4,0,
103,17,95,1,1,4
103,17,104,4,0,
103,17,113,4,0,
103,17,115,4,0,
103,17,121,1,27,
103,17,138,4,0,
103,17,140,1,1,3
103,17,153,4,0,
103,17,156,4,0,
103,17,164,4,0,
103,17,168,4,0,
103,17,182,4,0,
103,17,188,4,0,
103,17,207,4,0,
103,17,213,4,0,
103,17,214,4,0,
103,17,216,4,0,
103,17,218,4,0,
103,17,237,4,0,
103,17,241,4,0,
103,17,244,4,0,
103,17,263,4,0,
103,17,267,4,0,
103,17,402,1,1,2
103,17,412,4,0,
103,17,416,4,0,
103,17,433,4,0,
103,17,437,1,47,
103,17,447,4,0,
103,17,452,1,37,
103,17,473,1,17,
103,17,473,4,0,
103,17,496,4,0,
103,17,590,4,0,
103,17,611,4,0,
103,18,14,4,0,
103,18,23,1,0,
103,18,23,1,1,1
103,18,63,4,0,
103,18,76,4,0,
103,18,92,4,0,
103,18,93,1,1,5
103,18,94,4,0,
103,18,95,1,1,4
103,18,104,4,0,
103,18,113,4,0,
103,18,115,4,0,
103,18,121,1,27,
103,18,138,4,0,
103,18,140,1,1,3
103,18,153,4,0,
103,18,156,4,0,
103,18,164,4,0,
103,18,168,4,0,
103,18,182,4,0,
103,18,188,4,0,
103,18,207,4,0,
103,18,213,4,0,
103,18,214,4,0,
103,18,216,4,0,
103,18,218,4,0,
103,18,237,4,0,
103,18,241,4,0,
103,18,244,4,0,
103,18,263,4,0,
103,18,267,4,0,
103,18,402,1,1,2
103,18,412,4,0,
103,18,416,4,0,
103,18,433,4,0,
103,18,437,1,47,
103,18,447,4,0,
103,18,452,1,37,
103,18,473,1,17,
103,18,473,4,0,
103,18,496,4,0,
103,18,590,4,0,
103,18,611,4,0,
104,1,5,4,0,
104,1,25,4,0,
104,1,34,4,0,
104,1,36,4,0,
104,1,37,1,38,
104,1,38,4,0,
104,1,43,1,25,
104,1,45,1,1,2
104,1,55,4,0,
104,1,58,4,0,
104,1,59,4,0,
104,1,61,4,0,
104,1,66,4,0,
104,1,68,4,0,
104,1,69,4,0,
104,1,70,4,0,
104,1,89,4,0,
104,1,90,4,0,
104,1,91,4,0,
104,1,92,4,0,
104,1,99,1,46,
104,1,99,4,0,
104,1,102,4,0,
104,1,104,4,0,
104,1,116,1,31,
104,1,117,4,0,
104,1,125,1,1,1
104,1,126,4,0,
104,1,130,4,0,
104,1,155,1,43,
104,1,156,4,0,
104,1,164,4,0,
104,2,5,4,0,
104,2,25,4,0,
104,2,29,1,18,
104,2,34,4,0,
104,2,36,4,0,
104,2,37,1,38,
104,2,38,4,0,
104,2,39,1,13,
104,2,43,1,25,
104,2,45,1,1,
104,2,55,4,0,
104,2,58,4,0,
104,2,59,4,0,
104,2,61,4,0,
104,2,66,4,0,
104,2,68,4,0,
104,2,69,4,0,
104,2,70,4,0,
104,2,89,4,0,
104,2,90,4,0,
104,2,91,4,0,
104,2,92,4,0,
104,2,99,1,46,
104,2,99,4,0,
104,2,102,4,0,
104,2,104,4,0,
104,2,116,1,31,
104,2,117,4,0,
104,2,125,1,10,
104,2,126,4,0,
104,2,130,4,0,
104,2,155,1,43,
104,2,156,4,0,
104,2,164,4,0,
104,3,7,4,0,
104,3,9,4,0,
104,3,29,1,13,
104,3,29,4,0,
104,3,37,1,37,
104,3,39,1,5,
104,3,43,1,17,
104,3,45,1,1,
104,3,59,4,0,
104,3,70,4,0,
104,3,89,4,0,
104,3,91,4,0,
104,3,92,4,0,
104,3,99,1,29,
104,3,103,2,0,
104,3,104,4,0,
104,3,116,1,21,
104,3,125,1,9,
104,3,126,4,0,
104,3,130,2,0,
104,3,155,1,25,
104,3,156,4,0,
104,3,157,2,0,
104,3,168,4,0,
104,3,173,4,0,
104,3,174,4,0,
104,3,182,4,0,
104,3,187,2,0,
104,3,189,4,0,
104,3,195,2,0,
104,3,196,4,0,
104,3,197,4,0,
104,3,198,1,41,
104,3,201,4,0,
104,3,203,4,0,
104,3,206,1,33,
104,3,207,4,0,
104,3,213,4,0,
104,3,214,4,0,
104,3,216,4,0,
104,3,218,4,0,
104,3,223,4,0,
104,3,231,4,0,
104,3,237,4,0,
104,3,241,4,0,
104,3,246,2,0,
104,3,249,4,0,
104,4,7,4,0,
104,4,9,4,0,
104,4,14,2,0,
104,4,29,1,13,
104,4,29,4,0,
104,4,37,1,37,
104,4,39,1,5,
104,4,43,1,17,
104,4,45,1,1,
104,4,53,3,0,
104,4,58,3,0,
104,4,59,4,0,
104,4,70,4,0,
104,4,89,4,0,
104,4,91,4,0,
104,4,92,4,0,
104,4,99,1,29,
104,4,103,2,0,
104,4,104,4,0,
104,4,116,1,21,
104,4,125,1,9,
104,4,126,4,0,
104,4,130,2,0,
104,4,155,1,25,
104,4,156,4,0,
104,4,157,2,0,
104,4,168,4,0,
104,4,173,4,0,
104,4,174,4,0,
104,4,182,4,0,
104,4,187,2,0,
104,4,189,4,0,
104,4,195,2,0,
104,4,196,4,0,
104,4,197,4,0,
104,4,198,1,41,
104,4,201,4,0,
104,4,203,4,0,
104,4,206,1,33,
104,4,207,4,0,
104,4,213,4,0,
104,4,214,4,0,
104,4,216,4,0,
104,4,218,4,0,
104,4,223,4,0,
104,4,231,4,0,
104,4,237,4,0,
104,4,241,4,0,
104,4,246,2,0,
104,4,249,4,0,
104,5,14,2,0,
104,5,29,1,13,
104,5,37,1,37,
104,5,38,1,45,
104,5,39,1,5,
104,5,43,1,17,
104,5,45,1,1,
104,5,53,4,0,
104,5,58,4,0,
104,5,59,4,0,
104,5,70,4,0,
104,5,89,4,0,
104,5,91,4,0,
104,5,92,4,0,
104,5,99,1,29,
104,5,103,2,0,
104,5,104,4,0,
104,5,116,1,21,
104,5,125,1,9,
104,5,126,4,0,
104,5,130,2,0,
104,5,155,1,25,
104,5,156,4,0,
104,5,157,2,0,
104,5,168,4,0,
104,5,182,4,0,
104,5,187,2,0,
104,5,195,2,0,
104,5,198,1,41,
104,5,201,4,0,
104,5,206,1,33,
104,5,213,4,0,
104,5,216,4,0,
104,5,218,4,0,
104,5,231,4,0,
104,5,237,4,0,
104,5,241,4,0,
104,5,246,2,0,
104,5,249,4,0,
104,5,263,4,0,
104,5,264,4,0,
104,5,280,4,0,
104,5,290,4,0,
104,5,317,4,0,
104,5,332,4,0,
104,6,5,3,0,
104,6,7,3,0,
104,6,9,3,0,
104,6,14,2,0,
104,6,14,3,0,
104,6,25,3,0,
104,6,29,1,13,
104,6,34,3,0,
104,6,37,1,37,
104,6,38,1,45,
104,6,38,3,0,
104,6,39,1,5,
104,6,43,1,17,
104,6,45,1,1,
104,6,53,4,0,
104,6,58,4,0,
104,6,59,4,0,
104,6,68,3,0,
104,6,69,3,0,
104,6,70,4,0,
104,6,89,4,0,
104,6,91,4,0,
104,6,92,4,0,
104,6,99,1,29,
104,6,102,3,0,
104,6,103,2,0,
104,6,104,4,0,
104,6,116,1,21,
104,6,125,1,9,
104,6,126,4,0,
104,6,130,2,0,
104,6,155,1,25,
104,6,156,4,0,
104,6,157,2,0,
104,6,157,3,0,
104,6,164,3,0,
104,6,168,4,0,
104,6,173,3,0,
104,6,182,4,0,
104,6,187,2,0,
104,6,189,3,0,
104,6,195,2,0,
104,6,196,3,0,
104,6,198,1,41,
104,6,201,4,0,
104,6,203,3,0,
104,6,206,1,33,
104,6,207,3,0,
104,6,213,4,0,
104,6,214,3,0,
104,6,216,4,0,
104,6,218,4,0,
104,6,223,3,0,
104,6,231,4,0,
104,6,237,4,0,
104,6,241,4,0,
104,6,246,2,0,
104,6,249,4,0,
104,6,263,4,0,
104,6,264,4,0,
104,6,280,4,0,
104,6,290,4,0,
104,6,317,4,0,
104,6,332,4,0,
104,7,5,3,0,
104,7,14,2,0,
104,7,14,3,0,
104,7,25,3,0,
104,7,29,1,13,
104,7,34,3,0,
104,7,37,1,37,
104,7,38,1,45,
104,7,38,3,0,
104,7,39,1,5,
104,7,43,1,17,
104,7,45,1,1,
104,7,53,4,0,
104,7,58,4,0,
104,7,59,4,0,
104,7,68,3,0,
104,7,69,3,0,
104,7,70,4,0,
104,7,89,4,0,
104,7,91,4,0,
104,7,92,4,0,
104,7,99,1,29,
104,7,102,3,0,
104,7,103,2,0,
104,7,104,4,0,
104,7,116,1,21,
104,7,125,1,9,
104,7,126,4,0,
104,7,130,2,0,
104,7,155,1,25,
104,7,156,4,0,
104,7,157,2,0,
104,7,157,3,0,
104,7,164,3,0,
104,7,168,4,0,
104,7,182,4,0,
104,7,187,2,0,
104,7,195,2,0,
104,7,198,1,41,
104,7,201,4,0,
104,7,206,1,33,
104,7,213,4,0,
104,7,216,4,0,
104,7,218,4,0,
104,7,231,4,0,
104,7,237,4,0,
104,7,241,4,0,
104,7,246,2,0,
104,7,249,4,0,
104,7,263,4,0,
104,7,264,4,0,
104,7,280,4,0,
104,7,290,4,0,
104,7,317,4,0,
104,7,332,4,0,
104,8,14,2,0,
104,8,14,4,0,
104,8,24,2,0,
104,8,29,1,11,
104,8,37,1,31,
104,8,38,1,43,
104,8,39,1,3,
104,8,43,1,13,
104,8,45,1,1,
104,8,53,4,0,
104,8,58,4,0,
104,8,59,4,0,
104,8,70,4,0,
104,8,89,4,0,
104,8,91,4,0,
104,8,92,4,0,
104,8,99,1,23,
104,8,103,2,0,
104,8,104,4,0,
104,8,116,1,17,
104,8,125,1,7,
104,8,126,4,0,
104,8,130,2,0,
104,8,155,1,21,
104,8,156,4,0,
104,8,157,2,0,
104,8,157,4,0,
104,8,164,4,0,
104,8,168,4,0,
104,8,182,4,0,
104,8,187,2,0,
104,8,195,2,0,
104,8,198,1,37,
104,8,201,4,0,
104,8,203,4,0,
104,8,206,1,27,
104,8,206,4,0,
104,8,207,4,0,
104,8,213,4,0,
104,8,214,4,0,
104,8,216,4,0,
104,8,218,4,0,
104,8,231,4,0,
104,8,237,4,0,
104,8,241,4,0,
104,8,246,2,0,
104,8,249,4,0,
104,8,263,4,0,
104,8,264,4,0,
104,8,280,4,0,
104,8,283,1,41,
104,8,290,4,0,
104,8,317,4,0,
104,8,332,4,0,
104,8,363,4,0,
104,8,374,1,33,
104,8,374,4,0,
104,8,431,4,0,
104,8,442,2,0,
104,8,445,4,0,
104,8,446,4,0,
104,9,7,3,0,
104,9,9,3,0,
104,9,14,2,0,
104,9,14,4,0,
104,9,24,2,0,
104,9,29,1,11,
104,9,37,1,31,
104,9,38,1,43,
104,9,39,1,3,
104,9,43,1,13,
104,9,45,1,1,
104,9,53,4,0,
104,9,58,4,0,
104,9,59,4,0,
104,9,70,4,0,
104,9,89,4,0,
104,9,91,4,0,
104,9,92,4,0,
104,9,99,1,23,
104,9,103,2,0,
104,9,104,4,0,
104,9,116,1,17,
104,9,125,1,7,
104,9,126,4,0,
104,9,130,2,0,
104,9,155,1,21,
104,9,156,4,0,
104,9,157,2,0,
104,9,157,4,0,
104,9,164,4,0,
104,9,168,4,0,
104,9,173,3,0,
104,9,182,4,0,
104,9,187,2,0,
104,9,189,3,0,
104,9,195,2,0,
104,9,196,3,0,
104,9,198,1,37,
104,9,201,4,0,
104,9,203,4,0,
104,9,206,1,27,
104,9,206,4,0,
104,9,207,4,0,
104,9,210,3,0,
104,9,213,4,0,
104,9,214,4,0,
104,9,216,4,0,
104,9,218,4,0,
104,9,231,4,0,
104,9,237,4,0,
104,9,241,4,0,
104,9,246,2,0,
104,9,249,4,0,
104,9,253,3,0,
104,9,263,4,0,
104,9,264,4,0,
104,9,280,4,0,
104,9,282,3,0,
104,9,283,1,41,
104,9,283,3,0,
104,9,290,4,0,
104,9,317,4,0,
104,9,332,4,0,
104,9,334,3,0,
104,9,363,4,0,
104,9,374,1,33,
104,9,374,4,0,
104,9,414,3,0,
104,9,431,4,0,
104,9,442,2,0,
104,9,445,4,0,
104,9,446,4,0,
104,10,7,3,0,
104,10,9,3,0,
104,10,14,2,0,
104,10,14,4,0,
104,10,24,2,0,
104,10,29,1,11,
104,10,29,3,0,
104,10,37,1,31,
104,10,38,1,43,
104,10,39,1,3,
104,10,43,1,13,
104,10,45,1,1,
104,10,53,4,0,
104,10,58,4,0,
104,10,59,4,0,
104,10,67,3,0,
104,10,70,4,0,
104,10,89,4,0,
104,10,91,4,0,
104,10,92,4,0,
104,10,99,1,23,
104,10,103,2,0,
104,10,104,4,0,
104,10,116,1,17,
104,10,125,1,7,
104,10,126,4,0,
104,10,130,2,0,
104,10,155,1,21,
104,10,156,4,0,
104,10,157,2,0,
104,10,157,4,0,
104,10,164,4,0,
104,10,168,4,0,
104,10,173,3,0,
104,10,182,4,0,
104,10,187,2,0,
104,10,189,3,0,
104,10,195,2,0,
104,10,196,3,0,
104,10,197,2,0,
104,10,198,1,37,
104,10,201,4,0,
104,10,203,4,0,
104,10,206,1,27,
104,10,206,4,0,
104,10,207,4,0,
104,10,210,3,0,
104,10,213,4,0,
104,10,214,4,0,
104,10,216,4,0,
104,10,218,4,0,
104,10,231,4,0,
104,10,237,4,0,
104,10,241,4,0,
104,10,246,2,0,
104,10,249,4,0,
104,10,253,3,0,
104,10,263,4,0,
104,10,264,4,0,
104,10,280,4,0,
104,10,282,3,0,
104,10,283,1,41,
104,10,283,3,0,
104,10,290,4,0,
104,10,317,4,0,
104,10,332,4,0,
104,10,334,3,0,
104,10,363,4,0,
104,10,374,1,33,
104,10,374,4,0,
104,10,414,3,0,
104,10,431,4,0,
104,10,442,2,0,
104,10,445,4,0,
104,10,446,4,0,
104,11,14,4,0,
104,11,24,2,0,
104,11,29,1,11,
104,11,37,1,31,
104,11,38,1,43,
104,11,39,1,3,
104,11,43,1,13,
104,11,45,1,1,
104,11,53,4,0,
104,11,58,4,0,
104,11,59,4,0,
104,11,70,4,0,
104,11,89,4,0,
104,11,91,4,0,
104,11,92,4,0,
104,11,99,1,23,
104,11,103,2,0,
104,11,104,4,0,
104,11,116,1,17,
104,11,125,1,7,
104,11,126,4,0,
104,11,130,2,0,
104,11,155,1,21,
104,11,156,4,0,
104,11,157,4,0,
104,11,164,4,0,
104,11,168,4,0,
104,11,182,4,0,
104,11,187,2,0,
104,11,195,2,0,
104,11,197,2,0,
104,11,198,1,37,
104,11,201,4,0,
104,11,203,2,0,
104,11,206,1,27,
104,11,206,4,0,
104,11,207,4,0,
104,11,213,4,0,
104,11,216,4,0,
104,11,218,4,0,
104,11,237,4,0,
104,11,241,4,0,
104,11,246,2,0,
104,11,249,4,0,
104,11,263,4,0,
104,11,280,4,0,
104,11,283,1,41,
104,11,317,4,0,
104,11,332,4,0,
104,11,374,1,33,
104,11,374,4,0,
104,11,442,2,0,
104,11,479,4,0,
104,11,496,4,0,
104,11,497,4,0,
104,11,498,2,0,
104,11,510,4,0,
104,11,514,1,47,
104,11,514,4,0,
104,11,523,4,0,
104,12,29,1,13,
104,12,37,1,37,
104,12,38,1,45,
104,12,39,1,5,
104,12,43,1,17,
104,12,45,1,1,
104,12,53,4,0,
104,12,58,4,0,
104,12,59,4,0,
104,12,70,4,0,
104,12,89,4,0,
104,12,91,4,0,
104,12,92,4,0,
104,12,99,1,29,
104,12,104,4,0,
104,12,116,1,21,
104,12,125,1,9,
104,12,126,4,0,
104,12,155,1,25,
104,12,156,4,0,
104,12,168,4,0,
104,12,182,4,0,
104,12,198,1,41,
104,12,201,4,0,
104,12,206,1,33,
104,12,213,4,0,
104,12,216,4,0,
104,12,218,4,0,
104,12,231,4,0,
104,12,237,4,0,
104,12,241,4,0,
104,12,249,4,0,
104,12,263,4,0,
104,12,264,4,0,
104,12,280,4,0,
104,12,290,4,0,
104,12,317,4,0,
104,12,332,4,0,
104,13,29,1,13,
104,13,34,3,0,
104,13,37,1,37,
104,13,38,1,45,
104,13,38,3,0,
104,13,39,1,5,
104,13,43,1,17,
104,13,45,1,1,
104,13,53,4,0,
104,13,58,4,0,
104,13,59,4,0,
104,13,69,3,0,
104,13,70,4,0,
104,13,89,4,0,
104,13,91,4,0,
104,13,92,4,0,
104,13,99,1,29,
104,13,102,3,0,
104,13,104,4,0,
104,13,116,1,21,
104,13,125,1,9,
104,13,126,4,0,
104,13,155,1,25,
104,13,156,4,0,
104,13,164,3,0,
104,13,168,4,0,
104,13,182,4,0,
104,13,196,3,0,
104,13,198,1,41,
104,13,201,4,0,
104,13,206,1,33,
104,13,207,3,0,
104,13,213,4,0,
104,13,216,4,0,
104,13,218,4,0,
104,13,231,4,0,
104,13,237,4,0,
104,13,241,4,0,
104,13,249,4,0,
104,13,263,4,0,
104,13,264,4,0,
104,13,280,4,0,
104,13,290,4,0,
104,13,317,4,0,
104,13,332,4,0,
104,14,7,3,0,
104,14,9,3,0,
104,14,14,4,0,
104,14,24,2,0,
104,14,29,1,11,
104,14,37,1,31,
104,14,38,1,43,
104,14,39,1,3,
104,14,43,1,13,
104,14,45,1,1,
104,14,53,4,0,
104,14,58,4,0,
104,14,59,4,0,
104,14,67,3,0,
104,14,70,4,0,
104,14,89,4,0,
104,14,91,4,0,
104,14,92,4,0,
104,14,99,1,23,
104,14,103,2,0,
104,14,104,4,0,
104,14,116,1,17,
104,14,125,1,7,
104,14,126,4,0,
104,14,130,2,0,
104,14,155,1,21,
104,14,156,4,0,
104,14,157,4,0,
104,14,164,4,0,
104,14,168,4,0,
104,14,173,3,0,
104,14,182,4,0,
104,14,187,2,0,
104,14,195,2,0,
104,14,196,3,0,
104,14,197,2,0,
104,14,198,1,37,
104,14,201,4,0,
104,14,203,2,0,
104,14,206,1,27,
104,14,206,4,0,
104,14,207,4,0,
104,14,213,4,0,
104,14,214,3,0,
104,14,216,4,0,
104,14,218,4,0,
104,14,231,3,0,
104,14,237,4,0,
104,14,241,4,0,
104,14,246,2,0,
104,14,249,4,0,
104,14,253,3,0,
104,14,263,4,0,
104,14,280,4,0,
104,14,282,3,0,
104,14,283,1,41,
104,14,283,3,0,
104,14,317,4,0,
104,14,332,4,0,
104,14,334,3,0,
104,14,374,1,33,
104,14,374,4,0,
104,14,414,3,0,
104,14,442,2,0,
104,14,442,3,0,
104,14,446,3,0,
104,14,479,4,0,
104,14,496,4,0,
104,14,497,4,0,
104,14,498,2,0,
104,14,510,4,0,
104,14,514,1,47,
104,14,514,4,0,
104,14,523,4,0,
104,15,14,4,0,
104,15,24,2,0,
104,15,29,1,11,
104,15,37,1,31,
104,15,38,1,43,
104,15,39,1,3,
104,15,43,1,13,
104,15,45,1,1,
104,15,53,4,0,
104,15,58,4,0,
104,15,59,4,0,
104,15,70,4,0,
104,15,89,4,0,
104,15,91,4,0,
104,15,92,4,0,
104,15,99,1,23,
104,15,103,2,0,
104,15,104,4,0,
104,15,116,1,17,
104,15,125,1,7,
104,15,126,4,0,
104,15,130,2,0,
104,15,155,1,21,
104,15,156,4,0,
104,15,157,4,0,
104,15,164,4,0,
104,15,168,4,0,
104,15,182,4,0,
104,15,187,2,0,
104,15,195,2,0,
104,15,197,2,0,
104,15,198,1,37,
104,15,201,4,0,
104,15,203,2,0,
104,15,206,1,27,
104,15,206,4,0,
104,15,207,4,0,
104,15,213,4,0,
104,15,214,4,0,
104,15,216,4,0,
104,15,218,4,0,
104,15,237,4,0,
104,15,241,4,0,
104,15,246,2,0,
104,15,249,4,0,
104,15,263,4,0,
104,15,280,4,0,
104,15,283,1,41,
104,15,317,4,0,
104,15,332,4,0,
104,15,374,1,33,
104,15,374,4,0,
104,15,442,2,0,
104,15,479,4,0,
104,15,496,4,0,
104,15,497,4,0,
104,15,498,2,0,
104,15,510,4,0,
104,15,514,1,47,
104,15,514,4,0,
104,15,523,4,0,
104,15,590,4,0,
104,15,612,4,0,
104,16,7,3,0,
104,16,9,3,0,
104,16,14,4,0,
104,16,24,2,0,
104,16,29,1,11,1
104,16,37,1,31,1
104,16,38,1,43,1
104,16,39,1,3,1
104,16,43,1,13,1
104,16,45,1,1,1
104,16,53,4,0,
104,16,58,4,0,
104,16,59,4,0,
104,16,67,3,0,
104,16,70,4,0,
104,16,89,4,0,
104,16,91,4,0,
104,16,92,4,0,
104,16,99,1,23,1
104,16,103,2,0,
104,16,104,4,0,
104,16,116,1,17,1
104,16,125,1,7,1
104,16,126,4,0,
104,16,130,2,0,
104,16,155,1,21,1
104,16,156,4,0,
104,16,157,4,0,
104,16,164,4,0,
104,16,168,4,0,
104,16,173,3,0,
104,16,182,4,0,
104,16,187,2,0,
104,16,195,2,0,
104,16,196,3,0,
104,16,197,2,0,
104,16,198,1,37,1
104,16,201,4,0,
104,16,203,2,0,
104,16,206,1,27,1
104,16,206,4,0,
104,16,207,4,0,
104,16,213,4,0,
104,16,214,4,0,
104,16,216,4,0,
104,16,218,4,0,
104,16,231,3,0,
104,16,237,4,0,
104,16,241,4,0,
104,16,246,2,0,
104,16,249,4,0,
104,16,253,3,0,
104,16,263,4,0,
104,16,264,3,0,
104,16,280,4,0,
104,16,282,3,0,
104,16,283,1,41,1
104,16,283,3,0,
104,16,290,4,0,
104,16,317,4,0,
104,16,332,4,0,
104,16,334,3,0,
104,16,374,1,33,1
104,16,374,4,0,
104,16,414,3,0,
104,16,442,2,0,
104,16,442,3,0,
104,16,446,3,0,
104,16,479,4,0,
104,16,496,4,0,
104,16,497,4,0,
104,16,498,2,0,
104,16,510,4,0,
104,16,514,1,47,1
104,16,514,4,0,
104,16,523,4,0,
104,16,590,4,0,
104,16,612,4,0,
104,17,14,4,0,
104,17,24,2,0,
104,17,29,1,11,
104,17,37,1,31,
104,17,38,1,43,
104,17,39,1,3,
104,17,43,1,13,
104,17,45,1,1,
104,17,53,4,0,
104,17,58,4,0,
104,17,59,4,0,
104,17,89,4,0,
104,17,92,4,0,
104,17,99,1,23,
104,17,103,2,0,
104,17,104,4,0,
104,17,116,1,17,
104,17,125,1,7,
104,17,126,4,0,
104,17,130,2,0,
104,17,155,1,21,
104,17,156,4,0,
104,17,157,4,0,
104,17,164,4,0,
104,17,168,4,0,
104,17,182,4,0,
104,17,187,2,0,
104,17,195,2,0,
104,17,197,2,0,
104,17,198,1,51,
104,17,201,4,0,
104,17,203,2,0,
104,17,206,1,27,
104,17,206,4,0,
104,17,207,4,0,
104,17,213,4,0,
104,17,214,4,0,
104,17,216,4,0,
104,17,218,4,0,
104,17,237,4,0,
104,17,241,4,0,
104,17,246,2,0,
104,17,263,4,0,
104,17,280,4,0,
104,17,283,1,41,
104,17,317,4,0,
104,17,332,4,0,
104,17,374,1,33,
104,17,374,4,0,
104,17,442,2,0,
104,17,479,4,0,
104,17,496,4,0,
104,17,497,4,0,
104,17,498,2,0,
104,17,514,1,47,
104,17,523,4,0,
104,17,590,4,0,
104,17,693,4,0,
104,17,707,1,37,
104,18,14,4,0,
104,18,24,2,0,
104,18,29,1,11,
104,18,37,1,31,
104,18,38,1,43,
104,18,39,1,3,
104,18,43,1,13,
104,18,45,1,1,
104,18,53,4,0,
104,18,58,4,0,
104,18,59,4,0,
104,18,89,4,0,
104,18,92,4,0,
104,18,99,1,23,
104,18,103,2,0,
104,18,104,4,0,
104,18,116,1,17,
104,18,125,1,7,
104,18,126,4,0,
104,18,130,2,0,
104,18,155,1,21,
104,18,156,4,0,
104,18,157,4,0,
104,18,164,4,0,
104,18,168,4,0,
104,18,174,2,0,
104,18,182,4,0,
104,18,187,2,0,
104,18,195,2,0,
104,18,197,2,0,
104,18,198,1,51,
104,18,201,4,0,
104,18,203,2,0,
104,18,206,1,27,
104,18,206,4,0,
104,18,207,4,0,
104,18,213,4,0,
104,18,214,4,0,
104,18,216,4,0,
104,18,218,4,0,
104,18,237,4,0,
104,18,241,4,0,
104,18,246,2,0,
104,18,263,4,0,
104,18,280,4,0,
104,18,283,1,41,
104,18,317,4,0,
104,18,332,4,0,
104,18,374,1,33,
104,18,374,4,0,
104,18,442,2,0,
104,18,479,4,0,
104,18,496,4,0,
104,18,497,4,0,
104,18,498,2,0,
104,18,514,1,47,
104,18,523,4,0,
104,18,590,4,0,
104,18,693,4,0,
104,18,707,1,37,
105,1,5,4,0,
105,1,25,4,0,
105,1,34,4,0,
105,1,36,4,0,
105,1,37,1,41,
105,1,38,4,0,
105,1,43,1,1,3
105,1,43,1,25,
105,1,45,1,1,2
105,1,55,4,0,
105,1,58,4,0,
105,1,59,4,0,
105,1,61,4,0,
105,1,63,4,0,
105,1,66,4,0,
105,1,68,4,0,
105,1,69,4,0,
105,1,70,4,0,
105,1,89,4,0,
105,1,90,4,0,
105,1,91,4,0,
105,1,92,4,0,
105,1,99,1,55,
105,1,99,4,0,
105,1,102,4,0,
105,1,104,4,0,
105,1,116,1,1,4
105,1,116,1,33,
105,1,117,4,0,
105,1,125,1,1,1
105,1,126,4,0,
105,1,130,4,0,
105,1,155,1,48,
105,1,156,4,0,
105,1,164,4,0,
105,2,5,4,0,
105,2,25,4,0,
105,2,29,1,18,
105,2,34,4,0,
105,2,36,4,0,
105,2,37,1,41,
105,2,38,4,0,
105,2,39,1,1,2
105,2,39,1,13,
105,2,43,1,25,
105,2,55,4,0,
105,2,58,4,0,
105,2,59,4,0,
105,2,61,4,0,
105,2,63,4,0,
105,2,66,4,0,
105,2,68,4,0,
105,2,69,4,0,
105,2,70,4,0,
105,2,89,4,0,
105,2,90,4,0,
105,2,91,4,0,
105,2,92,4,0,
105,2,99,1,55,
105,2,99,4,0,
105,2,102,4,0,
105,2,104,4,0,
105,2,116,1,33,
105,2,117,4,0,
105,2,125,1,1,1
105,2,125,1,10,
105,2,126,4,0,
105,2,130,4,0,
105,2,155,1,48,
105,2,156,4,0,
105,2,164,4,0,
105,3,7,4,0,
105,3,9,4,0,
105,3,29,1,1,4
105,3,29,1,13,
105,3,29,4,0,
105,3,37,1,46,
105,3,39,1,1,2
105,3,39,1,5,
105,3,43,1,17,
105,3,45,1,1,1
105,3,59,4,0,
105,3,63,4,0,
105,3,70,4,0,
105,3,89,4,0,
105,3,91,4,0,
105,3,92,4,0,
105,3,99,1,32,
105,3,104,4,0,
105,3,116,1,21,
105,3,125,1,1,3
105,3,125,1,9,
105,3,126,4,0,
105,3,155,1,25,
105,3,156,4,0,
105,3,168,4,0,
105,3,173,4,0,
105,3,174,4,0,
105,3,182,4,0,
105,3,189,4,0,
105,3,196,4,0,
105,3,197,4,0,
105,3,198,1,53,
105,3,201,4,0,
105,3,203,4,0,
105,3,206,1,39,
105,3,207,4,0,
105,3,213,4,0,
105,3,214,4,0,
105,3,216,4,0,
105,3,218,4,0,
105,3,223,4,0,
105,3,231,4,0,
105,3,237,4,0,
105,3,241,4,0,
105,3,249,4,0,
105,4,7,4,0,
105,4,9,4,0,
105,4,29,1,1,4
105,4,29,1,13,
105,4,29,4,0,
105,4,37,1,46,
105,4,39,1,1,2
105,4,39,1,5,
105,4,43,1,17,
105,4,45,1,1,1
105,4,53,3,0,
105,4,58,3,0,
105,4,59,4,0,
105,4,63,4,0,
105,4,70,4,0,
105,4,89,4,0,
105,4,91,4,0,
105,4,92,4,0,
105,4,99,1,32,
105,4,104,4,0,
105,4,116,1,21,
105,4,125,1,1,3
105,4,125,1,9,
105,4,126,4,0,
105,4,155,1,25,
105,4,156,4,0,
105,4,168,4,0,
105,4,173,4,0,
105,4,174,4,0,
105,4,182,4,0,
105,4,189,4,0,
105,4,196,4,0,
105,4,197,4,0,
105,4,198,1,53,
105,4,201,4,0,
105,4,203,4,0,
105,4,206,1,39,
105,4,207,4,0,
105,4,213,4,0,
105,4,214,4,0,
105,4,216,4,0,
105,4,218,4,0,
105,4,223,4,0,
105,4,231,4,0,
105,4,237,4,0,
105,4,241,4,0,
105,4,249,4,0,
105,5,29,1,1,4
105,5,29,1,13,
105,5,37,1,46,
105,5,38,1,61,
105,5,39,1,1,2
105,5,39,1,5,
105,5,43,1,17,
105,5,45,1,1,1
105,5,53,4,0,
105,5,58,4,0,
105,5,59,4,0,
105,5,63,4,0,
105,5,70,4,0,
105,5,89,4,0,
105,5,91,4,0,
105,5,92,4,0,
105,5,99,1,32,
105,5,104,4,0,
105,5,116,1,21,
105,5,125,1,1,3
105,5,125,1,9,
105,5,126,4,0,
105,5,155,1,25,
105,5,156,4,0,
105,5,168,4,0,
105,5,182,4,0,
105,5,198,1,53,
105,5,201,4,0,
105,5,206,1,39,
105,5,213,4,0,
105,5,216,4,0,
105,5,218,4,0,
105,5,231,4,0,
105,5,237,4,0,
105,5,241,4,0,
105,5,249,4,0,
105,5,263,4,0,
105,5,264,4,0,
105,5,280,4,0,
105,5,290,4,0,
105,5,317,4,0,
105,5,332,4,0,
105,6,5,3,0,
105,6,7,3,0,
105,6,9,3,0,
105,6,14,3,0,
105,6,25,3,0,
105,6,29,1,1,4
105,6,29,1,13,
105,6,34,3,0,
105,6,37,1,46,
105,6,38,1,61,
105,6,38,3,0,
105,6,39,1,1,2
105,6,39,1,5,
105,6,43,1,17,
105,6,45,1,1,1
105,6,53,4,0,
105,6,58,4,0,
105,6,59,4,0,
105,6,63,4,0,
105,6,68,3,0,
105,6,69,3,0,
105,6,70,4,0,
105,6,89,4,0,
105,6,91,4,0,
105,6,92,4,0,
105,6,99,1,32,
105,6,102,3,0,
105,6,104,4,0,
105,6,116,1,21,
105,6,125,1,1,3
105,6,125,1,9,
105,6,126,4,0,
105,6,155,1,25,
105,6,156,4,0,
105,6,157,3,0,
105,6,164,3,0,
105,6,168,4,0,
105,6,173,3,0,
105,6,182,4,0,
105,6,189,3,0,
105,6,196,3,0,
105,6,198,1,53,
105,6,201,4,0,
105,6,203,3,0,
105,6,206,1,39,
105,6,207,3,0,
105,6,213,4,0,
105,6,214,3,0,
105,6,216,4,0,
105,6,218,4,0,
105,6,223,3,0,
105,6,231,4,0,
105,6,237,4,0,
105,6,241,4,0,
105,6,249,4,0,
105,6,263,4,0,
105,6,264,4,0,
105,6,280,4,0,
105,6,290,4,0,
105,6,317,4,0,
105,6,332,4,0,
105,7,5,3,0,
105,7,14,3,0,
105,7,25,3,0,
105,7,29,1,1,4
105,7,29,1,13,
105,7,34,3,0,
105,7,37,1,46,
105,7,38,1,61,
105,7,38,3,0,
105,7,39,1,1,2
105,7,39,1,5,
105,7,43,1,17,
105,7,45,1,1,1
105,7,53,4,0,
105,7,58,4,0,
105,7,59,4,0,
105,7,63,4,0,
105,7,68,3,0,
105,7,69,3,0,
105,7,70,4,0,
105,7,89,4,0,
105,7,91,4,0,
105,7,92,4,0,
105,7,99,1,32,
105,7,102,3,0,
105,7,104,4,0,
105,7,116,1,21,
105,7,125,1,1,3
105,7,125,1,9,
105,7,126,4,0,
105,7,155,1,25,
105,7,156,4,0,
105,7,157,3,0,
105,7,164,3,0,
105,7,168,4,0,
105,7,182,4,0,
105,7,198,1,53,
105,7,201,4,0,
105,7,206,1,39,
105,7,213,4,0,
105,7,216,4,0,
105,7,218,4,0,
105,7,231,4,0,
105,7,237,4,0,
105,7,241,4,0,
105,7,249,4,0,
105,7,263,4,0,
105,7,264,4,0,
105,7,280,4,0,
105,7,290,4,0,
105,7,317,4,0,
105,7,332,4,0,
105,8,14,4,0,
105,8,29,1,1,4
105,8,29,1,11,
105,8,37,1,33,
105,8,38,1,53,
105,8,39,1,1,2
105,8,39,1,3,
105,8,43,1,13,
105,8,45,1,1,1
105,8,53,4,0,
105,8,58,4,0,
105,8,59,4,0,
105,8,63,4,0,
105,8,70,4,0,
105,8,89,4,0,
105,8,91,4,0,
105,8,92,4,0,
105,8,99,1,23,
105,8,104,4,0,
105,8,116,1,17,
105,8,125,1,1,3
105,8,125,1,7,
105,8,126,4,0,
105,8,155,1,21,
105,8,156,4,0,
105,8,157,4,0,
105,8,164,4,0,
105,8,168,4,0,
105,8,182,4,0,
105,8,198,1,43,
105,8,201,4,0,
105,8,203,4,0,
105,8,206,1,27,
105,8,206,4,0,
105,8,207,4,0,
105,8,213,4,0,
105,8,214,4,0,
105,8,216,4,0,
105,8,218,4,0,
105,8,231,4,0,
105,8,237,4,0,
105,8,241,4,0,
105,8,249,4,0,
105,8,263,4,0,
105,8,264,4,0,
105,8,280,4,0,
105,8,283,1,49,
105,8,290,4,0,
105,8,317,4,0,
105,8,332,4,0,
105,8,363,4,0,
105,8,374,1,37,
105,8,374,4,0,
105,8,411,4,0,
105,8,416,4,0,
105,8,431,4,0,
105,8,444,4,0,
105,8,445,4,0,
105,8,446,4,0,
105,9,7,3,0,
105,9,9,3,0,
105,9,14,4,0,
105,9,29,1,1,4
105,9,29,1,11,
105,9,37,1,33,
105,9,38,1,53,
105,9,39,1,1,2
105,9,39,1,3,
105,9,43,1,13,
105,9,45,1,1,1
105,9,53,4,0,
105,9,58,4,0,
105,9,59,4,0,
105,9,63,4,0,
105,9,70,4,0,
105,9,89,4,0,
105,9,91,4,0,
105,9,92,4,0,
105,9,99,1,23,
105,9,104,4,0,
105,9,116,1,17,
105,9,125,1,1,3
105,9,125,1,7,
105,9,126,4,0,
105,9,155,1,21,
105,9,156,4,0,
105,9,157,4,0,
105,9,164,4,0,
105,9,168,4,0,
105,9,173,3,0,
105,9,182,4,0,
105,9,189,3,0,
105,9,196,3,0,
105,9,198,1,43,
105,9,200,3,0,
105,9,201,4,0,
105,9,203,4,0,
105,9,206,1,27,
105,9,206,4,0,
105,9,207,4,0,
105,9,210,3,0,
105,9,213,4,0,
105,9,214,4,0,
105,9,216,4,0,
105,9,218,4,0,
105,9,231,4,0,
105,9,237,4,0,
105,9,241,4,0,
105,9,249,4,0,
105,9,253,3,0,
105,9,263,4,0,
105,9,264,4,0,
105,9,280,4,0,
105,9,282,3,0,
105,9,283,1,49,
105,9,283,3,0,
105,9,290,4,0,
105,9,317,4,0,
105,9,332,4,0,
105,9,334,3,0,
105,9,363,4,0,
105,9,374,1,37,
105,9,374,4,0,
105,9,411,4,0,
105,9,414,3,0,
105,9,416,4,0,
105,9,431,4,0,
105,9,444,4,0,
105,9,445,4,0,
105,9,446,4,0,
105,10,7,3,0,
105,10,9,3,0,
105,10,14,4,0,
105,10,29,1,1,4
105,10,29,1,11,
105,10,29,3,0,
105,10,37,1,33,
105,10,38,1,53,
105,10,39,1,1,2
105,10,39,1,3,
105,10,43,1,13,
105,10,45,1,1,1
105,10,53,4,0,
105,10,58,4,0,
105,10,59,4,0,
105,10,63,4,0,
105,10,67,3,0,
105,10,70,4,0,
105,10,89,4,0,
105,10,91,4,0,
105,10,92,4,0,
105,10,99,1,23,
105,10,104,4,0,
105,10,116,1,17,
105,10,125,1,1,3
105,10,125,1,7,
105,10,126,4,0,
105,10,155,1,21,
105,10,156,4,0,
105,10,157,4,0,
105,10,164,4,0,
105,10,168,4,0,
105,10,173,3,0,
105,10,182,4,0,
105,10,189,3,0,
105,10,196,3,0,
105,10,198,1,43,
105,10,200,3,0,
105,10,201,4,0,
105,10,203,4,0,
105,10,206,1,27,
105,10,206,4,0,
105,10,207,4,0,
105,10,210,3,0,
105,10,213,4,0,
105,10,214,4,0,
105,10,216,4,0,
105,10,218,4,0,
105,10,231,4,0,
105,10,237,4,0,
105,10,241,4,0,
105,10,249,4,0,
105,10,253,3,0,
105,10,263,4,0,
105,10,264,4,0,
105,10,280,4,0,
105,10,282,3,0,
105,10,283,1,49,
105,10,283,3,0,
105,10,290,4,0,
105,10,317,4,0,
105,10,332,4,0,
105,10,334,3,0,
105,10,363,4,0,
105,10,374,1,37,
105,10,374,4,0,
105,10,411,4,0,
105,10,414,3,0,
105,10,416,4,0,
105,10,431,4,0,
105,10,444,4,0,
105,10,445,4,0,
105,10,446,4,0,
105,11,14,4,0,
105,11,29,1,1,4
105,11,29,1,11,
105,11,37,1,33,
105,11,38,1,53,
105,11,39,1,1,2
105,11,39,1,3,
105,11,43,1,13,
105,11,45,1,1,1
105,11,53,4,0,
105,11,58,4,0,
105,11,59,4,0,
105,11,63,4,0,
105,11,70,4,0,
105,11,89,4,0,
105,11,91,4,0,
105,11,92,4,0,
105,11,99,1,23,
105,11,104,4,0,
105,11,116,1,17,
105,11,125,1,1,3
105,11,125,1,7,
105,11,126,4,0,
105,11,155,1,21,
105,11,156,4,0,
105,11,157,4,0,
105,11,164,4,0,
105,11,168,4,0,
105,11,182,4,0,
105,11,198,1,43,
105,11,201,4,0,
105,11,206,1,27,
105,11,206,4,0,
105,11,207,4,0,
105,11,213,4,0,
105,11,216,4,0,
105,11,218,4,0,
105,11,237,4,0,
105,11,241,4,0,
105,11,249,4,0,
105,11,263,4,0,
105,11,280,4,0,
105,11,283,1,49,
105,11,317,4,0,
105,11,332,4,0,
105,11,374,1,37,
105,11,374,4,0,
105,11,411,4,0,
105,11,416,4,0,
105,11,444,4,0,
105,11,479,4,0,
105,11,496,4,0,
105,11,497,4,0,
105,11,510,4,0,
105,11,514,1,59,
105,11,514,4,0,
105,11,523,4,0,
105,12,29,1,1,4
105,12,29,1,13,
105,12,37,1,46,
105,12,38,1,61,
105,12,39,1,1,2
105,12,39,1,5,
105,12,43,1,17,
105,12,45,1,1,1
105,12,53,4,0,
105,12,58,4,0,
105,12,59,4,0,
105,12,63,4,0,
105,12,70,4,0,
105,12,89,4,0,
105,12,91,4,0,
105,12,92,4,0,
105,12,99,1,32,
105,12,104,4,0,
105,12,116,1,21,
105,12,125,1,1,3
105,12,125,1,9,
105,12,126,4,0,
105,12,155,1,25,
105,12,156,4,0,
105,12,168,4,0,
105,12,182,4,0,
105,12,198,1,53,
105,12,201,4,0,
105,12,206,1,39,
105,12,213,4,0,
105,12,216,4,0,
105,12,218,4,0,
105,12,231,4,0,
105,12,237,4,0,
105,12,241,4,0,
105,12,249,4,0,
105,12,263,4,0,
105,12,264,4,0,
105,12,280,4,0,
105,12,290,4,0,
105,12,317,4,0,
105,12,332,4,0,
105,13,29,1,1,4
105,13,29,1,13,
105,13,34,3,0,
105,13,37,1,46,
105,13,38,1,61,
105,13,38,3,0,
105,13,39,1,1,2
105,13,39,1,5,
105,13,43,1,17,
105,13,45,1,1,1
105,13,53,4,0,
105,13,58,4,0,
105,13,59,4,0,
105,13,63,4,0,
105,13,69,3,0,
105,13,70,4,0,
105,13,89,4,0,
105,13,91,4,0,
105,13,92,4,0,
105,13,99,1,32,
105,13,102,3,0,
105,13,104,4,0,
105,13,116,1,21,
105,13,125,1,1,3
105,13,125,1,9,
105,13,126,4,0,
105,13,155,1,25,
105,13,156,4,0,
105,13,164,3,0,
105,13,168,4,0,
105,13,182,4,0,
105,13,196,3,0,
105,13,198,1,53,
105,13,201,4,0,
105,13,206,1,39,
105,13,207,3,0,
105,13,213,4,0,
105,13,216,4,0,
105,13,218,4,0,
105,13,231,4,0,
105,13,237,4,0,
105,13,241,4,0,
105,13,249,4,0,
105,13,263,4,0,
105,13,264,4,0,
105,13,280,4,0,
105,13,290,4,0,
105,13,317,4,0,
105,13,332,4,0,
105,14,7,3,0,
105,14,9,3,0,
105,14,14,4,0,
105,14,29,1,1,4
105,14,29,1,11,
105,14,37,1,33,
105,14,38,1,53,
105,14,39,1,1,2
105,14,39,1,3,
105,14,43,1,13,
105,14,45,1,1,1
105,14,53,4,0,
105,14,58,4,0,
105,14,59,4,0,
105,14,63,4,0,
105,14,67,3,0,
105,14,70,4,0,
105,14,89,4,0,
105,14,91,4,0,
105,14,92,4,0,
105,14,99,1,23,
105,14,104,4,0,
105,14,116,1,17,
105,14,125,1,1,3
105,14,125,1,7,
105,14,126,4,0,
105,14,155,1,21,
105,14,156,4,0,
105,14,157,4,0,
105,14,164,4,0,
105,14,168,4,0,
105,14,173,3,0,
105,14,182,4,0,
105,14,196,3,0,
105,14,198,1,43,
105,14,200,3,0,
105,14,201,4,0,
105,14,206,1,27,
105,14,206,4,0,
105,14,207,4,0,
105,14,213,4,0,
105,14,214,3,0,
105,14,216,4,0,
105,14,218,4,0,
105,14,231,3,0,
105,14,237,4,0,
105,14,241,4,0,
105,14,249,4,0,
105,14,253,3,0,
105,14,263,4,0,
105,14,280,4,0,
105,14,282,3,0,
105,14,283,1,49,
105,14,283,3,0,
105,14,317,4,0,
105,14,332,4,0,
105,14,334,3,0,
105,14,374,1,37,
105,14,374,4,0,
105,14,411,4,0,
105,14,414,3,0,
105,14,416,4,0,
105,14,442,3,0,
105,14,444,4,0,
105,14,446,3,0,
105,14,479,4,0,
105,14,496,4,0,
105,14,497,4,0,
105,14,510,4,0,
105,14,514,1,59,
105,14,514,4,0,
105,14,523,4,0,
105,15,14,4,0,
105,15,29,1,1,4
105,15,29,1,11,
105,15,37,1,33,
105,15,38,1,53,
105,15,39,1,1,2
105,15,39,1,3,
105,15,43,1,13,
105,15,45,1,1,1
105,15,53,4,0,
105,15,58,4,0,
105,15,59,4,0,
105,15,63,4,0,
105,15,70,4,0,
105,15,89,4,0,
105,15,91,4,0,
105,15,92,4,0,
105,15,99,1,23,
105,15,104,4,0,
105,15,116,1,17,
105,15,125,1,1,3
105,15,125,1,7,
105,15,126,4,0,
105,15,155,1,21,
105,15,156,4,0,
105,15,157,4,0,
105,15,164,4,0,
105,15,168,4,0,
105,15,182,4,0,
105,15,198,1,43,
105,15,201,4,0,
105,15,206,1,27,
105,15,206,4,0,
105,15,207,4,0,
105,15,213,4,0,
105,15,214,4,0,
105,15,216,4,0,
105,15,218,4,0,
105,15,237,4,0,
105,15,241,4,0,
105,15,249,4,0,
105,15,263,4,0,
105,15,280,4,0,
105,15,283,1,49,
105,15,317,4,0,
105,15,332,4,0,
105,15,374,1,37,
105,15,374,4,0,
105,15,411,4,0,
105,15,416,4,0,
105,15,444,4,0,
105,15,479,4,0,
105,15,496,4,0,
105,15,497,4,0,
105,15,510,4,0,
105,15,514,1,59,
105,15,514,4,0,
105,15,523,4,0,
105,15,590,4,0,
105,15,612,4,0,
105,16,7,3,0,
105,16,9,3,0,
105,16,14,4,0,
105,16,29,1,1,4
105,16,29,1,11,1
105,16,37,1,33,1
105,16,38,1,53,1
105,16,39,1,1,2
105,16,39,1,3,1
105,16,43,1,13,1
105,16,45,1,1,1
105,16,53,4,0,
105,16,58,4,0,
105,16,59,4,0,
105,16,63,4,0,
105,16,67,3,0,
105,16,70,4,0,
105,16,89,4,0,
105,16,91,4,0,
105,16,92,4,0,
105,16,99,1,23,1
105,16,104,4,0,
105,16,116,1,17,1
105,16,125,1,1,3
105,16,125,1,7,1
105,16,126,4,0,
105,16,155,1,21,1
105,16,156,4,0,
105,16,157,4,0,
105,16,164,4,0,
105,16,168,4,0,
105,16,173,3,0,
105,16,182,4,0,
105,16,196,3,0,
105,16,198,1,43,1
105,16,200,3,0,
105,16,201,4,0,
105,16,206,1,27,1
105,16,206,4,0,
105,16,207,4,0,
105,16,213,4,0,
105,16,214,4,0,
105,16,216,4,0,
105,16,218,4,0,
105,16,231,3,0,
105,16,237,4,0,
105,16,241,4,0,
105,16,249,4,0,
105,16,253,3,0,
105,16,263,4,0,
105,16,264,3,0,
105,16,280,4,0,
105,16,282,3,0,
105,16,283,1,49,1
105,16,283,3,0,
105,16,290,4,0,
105,16,317,4,0,
105,16,332,4,0,
105,16,334,3,0,
105,16,374,1,37,1
105,16,374,4,0,
105,16,411,4,0,
105,16,414,3,0,
105,16,416,4,0,
105,16,442,3,0,
105,16,444,4,0,
105,16,446,3,0,
105,16,479,4,0,
105,16,496,4,0,
105,16,497,4,0,
105,16,510,4,0,
105,16,514,1,59,1
105,16,514,4,0,
105,16,523,4,0,
105,16,590,4,0,
105,16,612,4,0,
105,17,14,4,0,
105,17,29,1,1,4
105,17,29,1,11,
105,17,37,1,33,
105,17,38,1,53,
105,17,39,1,1,2
105,17,39,1,3,
105,17,43,1,13,
105,17,45,1,1,1
105,17,53,4,0,
105,17,58,4,0,
105,17,59,4,0,
105,17,63,4,0,
105,17,89,4,0,
105,17,92,4,0,
105,17,99,1,23,
105,17,104,4,0,
105,17,116,1,17,
105,17,125,1,1,3
105,17,125,1,7,
105,17,126,4,0,
105,17,155,1,21,
105,17,156,4,0,
105,17,157,4,0,
105,17,164,4,0,
105,17,168,4,0,
105,17,182,4,0,
105,17,198,1,65,
105,17,201,4,0,
105,17,206,1,27,
105,17,206,4,0,
105,17,207,4,0,
105,17,213,4,0,
105,17,214,4,0,
105,17,216,4,0,
105,17,218,4,0,
105,17,237,4,0,
105,17,241,4,0,
105,17,263,4,0,
105,17,280,4,0,
105,17,283,1,49,
105,17,317,4,0,
105,17,332,4,0,
105,17,374,1,37,
105,17,374,4,0,
105,17,411,4,0,
105,17,416,4,0,
105,17,444,4,0,
105,17,479,4,0,
105,17,496,4,0,
105,17,497,4,0,
105,17,514,1,59,
105,17,523,4,0,
105,17,590,4,0,
105,17,693,4,0,
105,17,707,1,43,
105,18,14,4,0,
105,18,29,1,1,4
105,18,29,1,11,
105,18,37,1,33,
105,18,38,1,53,
105,18,39,1,1,2
105,18,39,1,3,
105,18,43,1,13,
105,18,45,1,1,1
105,18,53,4,0,
105,18,58,4,0,
105,18,59,4,0,
105,18,63,4,0,
105,18,89,4,0,
105,18,92,4,0,
105,18,99,1,23,
105,18,104,4,0,
105,18,116,1,17,
105,18,125,1,1,3
105,18,125,1,7,
105,18,126,4,0,
105,18,155,1,21,
105,18,156,4,0,
105,18,157,4,0,
105,18,164,4,0,
105,18,168,4,0,
105,18,182,4,0,
105,18,198,1,65,
105,18,201,4,0,
105,18,206,1,27,
105,18,206,4,0,
105,18,207,4,0,
105,18,213,4,0,
105,18,214,4,0,
105,18,216,4,0,
105,18,218,4,0,
105,18,237,4,0,
105,18,241,4,0,
105,18,263,4,0,
105,18,280,4,0,
105,18,283,1,49,
105,18,317,4,0,
105,18,332,4,0,
105,18,374,1,37,
105,18,374,4,0,
105,18,411,4,0,
105,18,416,4,0,
105,18,444,4,0,
105,18,479,4,0,
105,18,496,4,0,
105,18,497,4,0,
105,18,514,1,59,
105,18,523,4,0,
105,18,590,4,0,
105,18,693,4,0,
105,18,707,1,43,
106,1,5,4,0,
106,1,24,1,1,1
106,1,25,1,53,
106,1,25,4,0,
106,1,26,1,38,
106,1,27,1,33,
106,1,34,4,0,
106,1,36,4,0,
106,1,38,4,0,
106,1,66,4,0,
106,1,68,4,0,
106,1,69,4,0,
106,1,70,4,0,
106,1,92,4,0,
106,1,96,1,1,2
106,1,99,4,0,
106,1,102,4,0,
106,1,104,4,0,
106,1,116,1,43,
106,1,117,4,0,
106,1,118,4,0,
106,1,129,4,0,
106,1,130,4,0,
106,1,136,1,48,
106,1,156,4,0,
106,1,164,4,0,
106,2,5,4,0,
106,2,24,1,1,1
106,2,25,1,53,
106,2,25,4,0,
106,2,26,1,38,
106,2,27,1,33,
106,2,34,4,0,
106,2,36,4,0,
106,2,38,4,0,
106,2,66,4,0,
106,2,68,4,0,
106,2,69,4,0,
106,2,70,4,0,
106,2,92,4,0,
106,2,96,1,1,2
106,2,99,4,0,
106,2,102,4,0,
106,2,104,4,0,
106,2,116,1,43,
106,2,117,4,0,
106,2,118,4,0,
106,2,129,4,0,
106,2,130,4,0,
106,2,136,1,48,
106,2,156,4,0,
106,2,164,4,0,
106,3,24,1,1,
106,3,25,1,46,
106,3,26,1,16,
106,3,27,1,11,
106,3,29,4,0,
106,3,70,4,0,
106,3,92,4,0,
106,3,96,1,6,
106,3,104,4,0,
106,3,116,1,21,
106,3,129,4,0,
106,3,136,1,26,
106,3,156,4,0,
106,3,168,4,0,
106,3,170,1,31,
106,3,173,4,0,
106,3,174,4,0,
106,3,179,1,51,
106,3,182,4,0,
106,3,189,4,0,
106,3,193,1,36,
106,3,197,4,0,
106,3,203,1,41,
106,3,203,4,0,
106,3,207,4,0,
106,3,213,4,0,
106,3,214,4,0,
106,3,216,4,0,
106,3,218,4,0,
106,3,223,4,0,
106,3,237,4,0,
106,3,241,4,0,
106,3,249,4,0,
106,4,24,1,1,
106,4,25,1,46,
106,4,26,1,16,
106,4,27,1,11,
106,4,29,4,0,
106,4,70,4,0,
106,4,92,4,0,
106,4,96,1,6,
106,4,104,4,0,
106,4,116,1,21,
106,4,129,4,0,
106,4,136,1,26,
106,4,156,4,0,
106,4,168,4,0,
106,4,170,1,31,
106,4,173,4,0,
106,4,174,4,0,
106,4,179,1,51,
106,4,182,4,0,
106,4,189,4,0,
106,4,193,1,36,
106,4,197,4,0,
106,4,203,1,41,
106,4,203,4,0,
106,4,207,4,0,
106,4,213,4,0,
106,4,214,4,0,
106,4,216,4,0,
106,4,218,4,0,
106,4,223,4,0,
106,4,237,4,0,
106,4,241,4,0,
106,4,249,4,0,
106,5,24,1,1,2
106,5,25,1,46,
106,5,26,1,16,
106,5,27,1,11,
106,5,70,4,0,
106,5,89,4,0,
106,5,92,4,0,
106,5,96,1,6,
106,5,104,4,0,
106,5,116,1,21,
106,5,136,1,26,
106,5,156,4,0,
106,5,168,4,0,
106,5,170,1,31,
106,5,179,1,51,
106,5,182,4,0,
106,5,193,1,36,
106,5,203,1,41,
106,5,213,4,0,
106,5,216,4,0,
106,5,218,4,0,
106,5,237,4,0,
106,5,240,4,0,
106,5,241,4,0,
106,5,249,4,0,
106,5,263,4,0,
106,5,264,4,0,
106,5,279,1,1,1
106,5,280,1,20,
106,5,280,4,0,
106,5,290,4,0,
106,5,317,4,0,
106,5,339,4,0,
106,6,5,3,0,
106,6,24,1,1,2
106,6,25,1,46,
106,6,25,3,0,
106,6,26,1,16,
106,6,27,1,11,
106,6,34,3,0,
106,6,38,3,0,
106,6,68,3,0,
106,6,69,3,0,
106,6,70,4,0,
106,6,89,4,0,
106,6,92,4,0,
106,6,96,1,6,
106,6,102,3,0,
106,6,104,4,0,
106,6,116,1,21,
106,6,118,3,0,
106,6,129,3,0,
106,6,136,1,26,
106,6,156,4,0,
106,6,157,3,0,
106,6,164,3,0,
106,6,168,4,0,
106,6,170,1,31,
106,6,173,3,0,
106,6,179,1,51,
106,6,182,4,0,
106,6,189,3,0,
106,6,193,1,36,
106,6,203,1,41,
106,6,203,3,0,
106,6,207,3,0,
106,6,213,4,0,
106,6,214,3,0,
106,6,216,4,0,
106,6,218,4,0,
106,6,223,3,0,
106,6,237,4,0,
106,6,240,4,0,
106,6,241,4,0,
106,6,249,4,0,
106,6,263,4,0,
106,6,264,4,0,
106,6,279,1,1,1
106,6,280,1,20,
106,6,280,4,0,
106,6,290,4,0,
106,6,317,4,0,
106,6,339,4,0,
106,7,5,3,0,
106,7,24,1,1,2
106,7,25,1,46,
106,7,25,3,0,
106,7,26,1,16,
106,7,27,1,11,
106,7,34,3,0,
106,7,38,3,0,
106,7,68,3,0,
106,7,69,3,0,
106,7,70,4,0,
106,7,89,4,0,
106,7,92,4,0,
106,7,96,1,6,
106,7,102,3,0,
106,7,104,4,0,
106,7,116,1,21,
106,7,118,3,0,
106,7,136,1,26,
106,7,156,4,0,
106,7,157,3,0,
106,7,164,3,0,
106,7,168,4,0,
106,7,170,1,31,
106,7,179,1,51,
106,7,182,4,0,
106,7,193,1,36,
106,7,203,1,41,
106,7,213,4,0,
106,7,216,4,0,
106,7,218,4,0,
106,7,237,4,0,
106,7,240,4,0,
106,7,241,4,0,
106,7,249,4,0,
106,7,263,4,0,
106,7,264,4,0,
106,7,279,1,1,1
106,7,280,1,20,
106,7,280,4,0,
106,7,290,4,0,
106,7,317,4,0,
106,7,339,4,0,
106,8,24,1,1,2
106,8,25,1,49,
106,8,26,1,13,
106,8,27,1,9,
106,8,70,4,0,
106,8,89,4,0,
106,8,92,4,0,
106,8,96,1,5,
106,8,104,4,0,
106,8,116,1,21,
106,8,136,1,29,
106,8,156,4,0,
106,8,157,4,0,
106,8,164,4,0,
106,8,168,4,0,
106,8,170,1,33,
106,8,179,1,57,
106,8,182,4,0,
106,8,193,1,37,
106,8,203,1,45,
106,8,203,4,0,
106,8,207,4,0,
106,8,213,4,0,
106,8,214,4,0,
106,8,216,4,0,
106,8,218,4,0,
106,8,237,4,0,
106,8,240,4,0,
106,8,241,4,0,
106,8,249,4,0,
106,8,263,4,0,
106,8,264,4,0,
106,8,279,1,1,1
106,8,280,1,17,
106,8,280,4,0,
106,8,290,4,0,
106,8,299,1,41,
106,8,317,4,0,
106,8,339,4,0,
106,8,363,4,0,
106,8,364,1,25,
106,8,370,1,53,
106,8,374,4,0,
106,8,398,4,0,
106,8,411,4,0,
106,8,431,4,0,
106,8,444,4,0,
106,8,445,4,0,
106,9,24,1,1,2
106,9,25,1,49,
106,9,26,1,13,
106,9,27,1,9,
106,9,70,4,0,
106,9,89,4,0,
106,9,92,4,0,
106,9,96,1,5,
106,9,104,4,0,
106,9,116,1,21,
106,9,129,3,0,
106,9,136,1,29,
106,9,156,4,0,
106,9,157,4,0,
106,9,164,4,0,
106,9,168,4,0,
106,9,170,1,33,
106,9,173,3,0,
106,9,179,1,57,
106,9,182,4,0,
106,9,189,3,0,
106,9,193,1,37,
106,9,203,1,45,
106,9,203,4,0,
106,9,207,4,0,
106,9,213,4,0,
106,9,214,4,0,
106,9,216,4,0,
106,9,218,4,0,
106,9,237,4,0,
106,9,240,4,0,
106,9,241,4,0,
106,9,249,4,0,
106,9,263,4,0,
106,9,264,4,0,
106,9,270,3,0,
106,9,276,3,0,
106,9,279,1,1,1
106,9,280,1,17,
106,9,280,4,0,
106,9,282,3,0,
106,9,290,4,0,
106,9,299,1,41,
106,9,317,4,0,
106,9,339,4,0,
106,9,340,3,0,
106,9,363,4,0,
106,9,364,1,25,
106,9,370,1,53,
106,9,374,4,0,
106,9,389,3,0,
106,9,398,4,0,
106,9,410,3,0,
106,9,411,4,0,
106,9,431,4,0,
106,9,444,4,0,
106,9,445,4,0,
106,10,24,1,1,2
106,10,25,1,49,
106,10,26,1,13,
106,10,27,1,9,
106,10,29,3,0,
106,10,67,3,0,
106,10,70,4,0,
106,10,89,4,0,
106,10,92,4,0,
106,10,96,1,5,
106,10,104,4,0,
106,10,116,1,21,
106,10,129,3,0,
106,10,136,1,29,
106,10,156,4,0,
106,10,157,4,0,
106,10,164,4,0,
106,10,168,4,0,
106,10,170,1,33,
106,10,173,3,0,
106,10,179,1,57,
106,10,182,4,0,
106,10,189,3,0,
106,10,193,1,37,
106,10,203,1,45,
106,10,203,4,0,
106,10,207,4,0,
106,10,213,4,0,
106,10,214,4,0,
106,10,216,4,0,
106,10,218,4,0,
106,10,237,4,0,
106,10,240,4,0,
106,10,241,4,0,
106,10,249,4,0,
106,10,263,4,0,
106,10,264,4,0,
106,10,270,3,0,
106,10,272,3,0,
106,10,276,3,0,
106,10,279,1,1,1
106,10,280,1,17,
106,10,280,4,0,
106,10,282,3,0,
106,10,290,4,0,
106,10,299,1,41,
106,10,317,4,0,
106,10,339,4,0,
106,10,340,3,0,
106,10,363,4,0,
106,10,364,1,25,
106,10,370,1,53,
106,10,374,4,0,
106,10,389,3,0,
106,10,398,4,0,
106,10,410,3,0,
106,10,411,4,0,
106,10,431,4,0,
106,10,444,4,0,
106,10,445,4,0,
106,11,24,1,1,2
106,11,25,1,53,
106,11,26,1,13,
106,11,27,1,9,
106,11,70,4,0,
106,11,89,4,0,
106,11,92,4,0,
106,11,96,1,5,
106,11,104,4,0,
106,11,116,1,21,
106,11,136,1,29,
106,11,156,4,0,
106,11,157,4,0,
106,11,164,4,0,
106,11,168,4,0,
106,11,170,1,33,
106,11,179,1,61,
106,11,182,4,0,
106,11,193,1,37,
106,11,203,1,49,
106,11,207,4,0,
106,11,213,4,0,
106,11,216,4,0,
106,11,218,4,0,
106,11,237,4,0,
106,11,240,4,0,
106,11,241,4,0,
106,11,249,4,0,
106,11,263,4,0,
106,11,279,1,1,1
106,11,280,1,17,
106,11,280,4,0,
106,11,299,1,45,
106,11,317,4,0,
106,11,339,4,0,
106,11,364,1,25,
106,11,370,1,57,
106,11,374,4,0,
106,11,398,4,0,
106,11,411,4,0,
106,11,444,4,0,
106,11,469,1,41,
106,11,490,4,0,
106,11,496,4,0,
106,11,514,4,0,
106,11,523,4,0,
106,11,526,4,0,
106,12,24,1,1,2
106,12,25,1,46,
106,12,26,1,16,
106,12,27,1,11,
106,12,70,4,0,
106,12,89,4,0,
106,12,92,4,0,
106,12,96,1,6,
106,12,104,4,0,
106,12,116,1,21,
106,12,136,1,26,
106,12,156,4,0,
106,12,168,4,0,
106,12,170,1,31,
106,12,179,1,51,
106,12,182,4,0,
106,12,193,1,36,
106,12,203,1,41,
106,12,213,4,0,
106,12,216,4,0,
106,12,218,4,0,
106,12,237,4,0,
106,12,240,4,0,
106,12,241,4,0,
106,12,249,4,0,
106,12,263,4,0,
106,12,264,4,0,
106,12,279,1,1,1
106,12,280,1,20,
106,12,280,4,0,
106,12,290,4,0,
106,12,317,4,0,
106,12,339,4,0,
106,13,24,1,1,2
106,13,25,1,46,
106,13,26,1,16,
106,13,27,1,11,
106,13,34,3,0,
106,13,38,3,0,
106,13,69,3,0,
106,13,70,4,0,
106,13,89,4,0,
106,13,92,4,0,
106,13,96,1,6,
106,13,102,3,0,
106,13,104,4,0,
106,13,116,1,21,
106,13,136,1,26,
106,13,156,4,0,
106,13,164,3,0,
106,13,168,4,0,
106,13,170,1,31,
106,13,179,1,51,
106,13,182,4,0,
106,13,193,1,36,
106,13,203,1,41,
106,13,207,3,0,
106,13,213,4,0,
106,13,216,4,0,
106,13,218,4,0,
106,13,237,4,0,
106,13,240,4,0,
106,13,241,4,0,
106,13,249,4,0,
106,13,263,4,0,
106,13,264,4,0,
106,13,279,1,1,1
106,13,280,1,20,
106,13,280,4,0,
106,13,290,4,0,
106,13,317,4,0,
106,13,339,4,0,
106,14,24,1,1,2
106,14,25,1,53,
106,14,26,1,13,
106,14,27,1,9,
106,14,67,3,0,
106,14,70,4,0,
106,14,89,4,0,
106,14,92,4,0,
106,14,96,1,5,
106,14,104,4,0,
106,14,116,1,21,
106,14,136,1,29,
106,14,156,4,0,
106,14,157,4,0,
106,14,164,4,0,
106,14,168,4,0,
106,14,170,1,33,
106,14,173,3,0,
106,14,179,1,61,
106,14,182,4,0,
106,14,193,1,37,
106,14,203,1,49,
106,14,207,4,0,
106,14,213,4,0,
106,14,214,3,0,
106,14,216,4,0,
106,14,218,4,0,
106,14,237,4,0,
106,14,240,4,0,
106,14,241,4,0,
106,14,249,4,0,
106,14,263,4,0,
106,14,270,3,0,
106,14,272,3,0,
106,14,276,3,0,
106,14,279,1,1,1
106,14,280,1,17,
106,14,280,4,0,
106,14,282,3,0,
106,14,299,1,45,
106,14,317,4,0,
106,14,339,4,0,
106,14,340,3,0,
106,14,343,3,0,
106,14,364,1,25,
106,14,370,1,57,
106,14,374,4,0,
106,14,398,4,0,
106,14,411,4,0,
106,14,444,4,0,
106,14,469,1,41,
106,14,490,4,0,
106,14,496,4,0,
106,14,514,4,0,
106,14,523,4,0,
106,14,526,4,0,
106,15,24,1,1,5
106,15,25,1,1,3
106,15,25,1,53,
106,15,26,1,13,
106,15,27,1,9,
106,15,70,4,0,
106,15,89,4,0,
106,15,92,4,0,
106,15,96,1,5,
106,15,104,4,0,
106,15,116,1,21,
106,15,136,1,29,
106,15,156,4,0,
106,15,157,4,0,
106,15,164,4,0,
106,15,168,4,0,
106,15,170,1,33,
106,15,179,1,1,1
106,15,179,1,61,
106,15,182,4,0,
106,15,193,1,37,
106,15,203,1,49,
106,15,207,4,0,
106,15,213,4,0,
106,15,214,4,0,
106,15,216,4,0,
106,15,218,4,0,
106,15,237,4,0,
106,15,240,4,0,
106,15,241,4,0,
106,15,249,4,0,
106,15,263,4,0,
106,15,279,1,1,4
106,15,280,1,17,
106,15,280,4,0,
106,15,299,1,45,
106,15,317,4,0,
106,15,339,4,0,
106,15,364,1,25,
106,15,370,1,1,2
106,15,370,1,57,
106,15,374,4,0,
106,15,398,4,0,
106,15,411,4,0,
106,15,444,4,0,
106,15,469,1,41,
106,15,490,4,0,
106,15,496,4,0,
106,15,514,4,0,
106,15,523,4,0,
106,15,590,4,0,
106,15,612,4,0,
106,16,24,1,1,5
106,16,25,1,1,3
106,16,25,1,53,1
106,16,26,1,13,1
106,16,27,1,9,1
106,16,67,3,0,
106,16,70,4,0,
106,16,89,4,0,
106,16,92,4,0,
106,16,96,1,5,1
106,16,104,4,0,
106,16,116,1,21,1
106,16,136,1,29,1
106,16,156,4,0,
106,16,157,4,0,
106,16,164,4,0,
106,16,168,4,0,
106,16,170,1,33,1
106,16,173,3,0,
106,16,179,1,1,1
106,16,179,1,61,1
106,16,182,4,0,
106,16,193,1,37,1
106,16,203,1,49,1
106,16,207,4,0,
106,16,213,4,0,
106,16,214,4,0,
106,16,216,4,0,
106,16,218,4,0,
106,16,237,4,0,
106,16,240,4,0,
106,16,241,4,0,
106,16,249,4,0,
106,16,263,4,0,
106,16,264,3,0,
106,16,270,3,0,
106,16,272,3,0,
106,16,276,3,0,
106,16,279,1,1,4
106,16,280,1,17,1
106,16,280,4,0,
106,16,282,3,0,
106,16,290,4,0,
106,16,299,1,45,1
106,16,317,4,0,
106,16,339,4,0,
106,16,340,3,0,
106,16,343,3,0,
106,16,364,1,25,1
106,16,370,1,1,2
106,16,370,1,57,1
106,16,374,4,0,
106,16,398,4,0,
106,16,411,4,0,
106,16,444,4,0,
106,16,469,1,41,1
106,16,490,4,0,
106,16,496,4,0,
106,16,514,4,0,
106,16,523,4,0,
106,16,590,4,0,
106,16,612,4,0,
106,17,24,1,0,
106,17,24,1,1,1
106,17,25,1,1,4
106,17,25,1,53,
106,17,26,1,1,8
106,17,26,1,13,
106,17,27,1,1,7
106,17,27,1,9,
106,17,89,4,0,
106,17,92,4,0,
106,17,96,1,1,6
106,17,96,1,5,
106,17,104,4,0,
106,17,116,1,21,
106,17,136,1,29,
106,17,156,4,0,
106,17,157,4,0,
106,17,164,4,0,
106,17,168,4,0,
106,17,170,1,33,
106,17,179,1,1,2
106,17,179,1,61,
106,17,182,4,0,
106,17,193,1,37,
106,17,203,1,49,
106,17,207,4,0,
106,17,213,4,0,
106,17,214,4,0,
106,17,216,4,0,
106,17,218,4,0,
106,17,237,4,0,
106,17,240,4,0,
106,17,241,4,0,
106,17,263,4,0,
106,17,279,1,1,5
106,17,280,1,17,
106,17,280,4,0,
106,17,299,1,45,
106,17,317,4,0,
106,17,339,4,0,
106,17,364,1,25,
106,17,370,1,1,3
106,17,370,1,57,
106,17,374,4,0,
106,17,398,4,0,
106,17,411,4,0,
106,17,444,4,0,
106,17,469,1,41,
106,17,490,4,0,
106,17,496,4,0,
106,17,523,4,0,
106,17,526,4,0,
106,17,590,4,0,
106,18,24,1,0,
106,18,24,1,1,1
106,18,25,1,1,4
106,18,25,1,53,
106,18,26,1,1,8
106,18,26,1,13,
106,18,27,1,1,7
106,18,27,1,9,
106,18,89,4,0,
106,18,92,4,0,
106,18,96,1,1,6
106,18,96,1,5,
106,18,104,4,0,
106,18,116,1,21,
106,18,136,1,29,
106,18,156,4,0,
106,18,157,4,0,
106,18,164,4,0,
106,18,168,4,0,
106,18,170,1,33,
106,18,179,1,1,2
106,18,179,1,61,
106,18,182,4,0,
106,18,193,1,37,
106,18,203,1,49,
106,18,207,4,0,
106,18,213,4,0,
106,18,214,4,0,
106,18,216,4,0,
106,18,218,4,0,
106,18,237,4,0,
106,18,240,4,0,
106,18,241,4,0,
106,18,263,4,0,
106,18,279,1,1,5
106,18,280,1,17,
106,18,280,4,0,
106,18,299,1,45,
106,18,317,4,0,
106,18,339,4,0,
106,18,364,1,25,
106,18,370,1,1,3
106,18,370,1,57,
106,18,374,4,0,
106,18,398,4,0,
106,18,411,4,0,
106,18,444,4,0,
106,18,469,1,41,
106,18,490,4,0,
106,18,496,4,0,
106,18,523,4,0,
106,18,526,4,0,
106,18,590,4,0,
107,1,4,1,1,1
107,1,5,1,48,
107,1,5,4,0,
107,1,7,1,33,
107,1,8,1,38,
107,1,9,1,43,
107,1,25,4,0,
107,1,34,4,0,
107,1,36,4,0,
107,1,38,4,0,
107,1,66,4,0,
107,1,68,1,53,
107,1,68,4,0,
107,1,69,4,0,
107,1,70,4,0,
107,1,92,4,0,
107,1,97,1,1,2
107,1,99,4,0,
107,1,102,4,0,
107,1,104,4,0,
107,1,117,4,0,
107,1,118,4,0,
107,1,129,4,0,
107,1,130,4,0,
107,1,156,4,0,
107,1,164,4,0,
107,2,4,1,1,1
107,2,5,1,48,
107,2,5,4,0,
107,2,7,1,33,
107,2,8,1,38,
107,2,9,1,43,
107,2,25,4,0,
107,2,34,4,0,
107,2,36,4,0,
107,2,38,4,0,
107,2,66,4,0,
107,2,68,1,53,
107,2,68,4,0,
107,2,69,4,0,
107,2,70,4,0,
107,2,92,4,0,
107,2,97,1,1,2
107,2,99,4,0,
107,2,102,4,0,
107,2,104,4,0,
107,2,117,4,0,
107,2,118,4,0,
107,2,129,4,0,
107,2,130,4,0,
107,2,156,4,0,
107,2,164,4,0,
107,3,4,1,1,
107,3,5,1,38,
107,3,7,1,26,3
107,3,7,4,0,
107,3,8,1,26,2
107,3,8,4,0,
107,3,9,1,26,1
107,3,9,4,0,
107,3,29,4,0,
107,3,68,1,50,
107,3,70,4,0,
107,3,92,4,0,
107,3,97,1,7,
107,3,104,4,0,
107,3,129,4,0,
107,3,156,4,0,
107,3,168,4,0,
107,3,173,4,0,
107,3,174,4,0,
107,3,182,4,0,
107,3,183,1,32,
107,3,189,4,0,
107,3,197,1,44,
107,3,197,4,0,
107,3,203,4,0,
107,3,207,4,0,
107,3,213,4,0,
107,3,214,4,0,
107,3,216,4,0,
107,3,218,4,0,
107,3,223,4,0,
107,3,228,1,13,
107,3,237,4,0,
107,3,241,4,0,
107,3,249,4,0,
107,4,4,1,1,
107,4,5,1,38,
107,4,7,1,26,3
107,4,7,4,0,
107,4,8,1,26,2
107,4,8,4,0,
107,4,9,1,26,1
107,4,9,4,0,
107,4,29,4,0,
107,4,68,1,50,
107,4,70,4,0,
107,4,92,4,0,
107,4,97,1,7,
107,4,104,4,0,
107,4,129,4,0,
107,4,156,4,0,
107,4,168,4,0,
107,4,173,4,0,
107,4,174,4,0,
107,4,182,4,0,
107,4,183,1,32,
107,4,189,4,0,
107,4,197,1,44,
107,4,197,4,0,
107,4,203,4,0,
107,4,207,4,0,
107,4,213,4,0,
107,4,214,4,0,
107,4,216,4,0,
107,4,218,4,0,
107,4,223,4,0,
107,4,228,1,13,
107,4,237,4,0,
107,4,241,4,0,
107,4,249,4,0,
107,5,4,1,1,2
107,5,5,1,38,
107,5,7,1,26,4
107,5,8,1,26,3
107,5,9,1,26,
107,5,68,1,50,
107,5,70,4,0,
107,5,89,4,0,
107,5,92,4,0,
107,5,97,1,7,
107,5,104,4,0,
107,5,156,4,0,
107,5,168,4,0,
107,5,182,4,0,
107,5,183,1,20,
107,5,197,1,44,
107,5,213,4,0,
107,5,216,4,0,
107,5,218,4,0,
107,5,228,1,13,
107,5,237,4,0,
107,5,240,4,0,
107,5,241,4,0,
107,5,249,4,0,
107,5,263,4,0,
107,5,264,4,0,
107,5,279,1,1,1
107,5,280,4,0,
107,5,290,4,0,
107,5,317,4,0,
107,5,327,1,32,
107,5,339,4,0,
107,6,4,1,1,2
107,6,5,1,38,
107,6,5,3,0,
107,6,7,1,26,4
107,6,7,3,0,
107,6,8,1,26,3
107,6,8,3,0,
107,6,9,1,26,
107,6,9,3,0,
107,6,25,3,0,
107,6,34,3,0,
107,6,38,3,0,
107,6,68,1,50,
107,6,68,3,0,
107,6,69,3,0,
107,6,70,4,0,
107,6,89,4,0,
107,6,92,4,0,
107,6,97,1,7,
107,6,102,3,0,
107,6,104,4,0,
107,6,118,3,0,
107,6,129,3,0,
107,6,156,4,0,
107,6,157,3,0,
107,6,164,3,0,
107,6,168,4,0,
107,6,173,3,0,
107,6,182,4,0,
107,6,183,1,20,
107,6,189,3,0,
107,6,197,1,44,
107,6,203,3,0,
107,6,207,3,0,
107,6,213,4,0,
107,6,214,3,0,
107,6,216,4,0,
107,6,218,4,0,
107,6,223,3,0,
107,6,228,1,13,
107,6,237,4,0,
107,6,240,4,0,
107,6,241,4,0,
107,6,249,4,0,
107,6,263,4,0,
107,6,264,4,0,
107,6,279,1,1,1
107,6,280,4,0,
107,6,290,4,0,
107,6,317,4,0,
107,6,327,1,32,
107,6,339,4,0,
107,7,4,1,1,2
107,7,5,1,38,
107,7,5,3,0,
107,7,7,1,26,4
107,7,8,1,26,3
107,7,9,1,26,
107,7,25,3,0,
107,7,34,3,0,
107,7,38,3,0,
107,7,68,1,50,
107,7,68,3,0,
107,7,69,3,0,
107,7,70,4,0,
107,7,89,4,0,
107,7,92,4,0,
107,7,97,1,7,
107,7,102,3,0,
107,7,104,4,0,
107,7,118,3,0,
107,7,156,4,0,
107,7,157,3,0,
107,7,164,3,0,
107,7,168,4,0,
107,7,182,4,0,
107,7,183,1,20,
107,7,197,1,44,
107,7,213,4,0,
107,7,216,4,0,
107,7,218,4,0,
107,7,228,1,13,
107,7,237,4,0,
107,7,240,4,0,
107,7,241,4,0,
107,7,249,4,0,
107,7,263,4,0,
107,7,264,4,0,
107,7,279,1,1,1
107,7,280,4,0,
107,7,290,4,0,
107,7,317,4,0,
107,7,327,1,32,
107,7,339,4,0,
107,8,4,1,1,2
107,8,5,1,41,
107,8,7,1,31,3
107,8,8,1,31,2
107,8,9,1,31,1
107,8,68,1,51,
107,8,70,4,0,
107,8,89,4,0,
107,8,92,4,0,
107,8,97,1,6,
107,8,104,4,0,
107,8,156,4,0,
107,8,157,4,0,
107,8,164,4,0,
107,8,168,4,0,
107,8,182,4,0,
107,8,183,1,16,1
107,8,197,1,46,
107,8,203,4,0,
107,8,207,4,0,
107,8,213,4,0,
107,8,214,4,0,
107,8,216,4,0,
107,8,218,4,0,
107,8,228,1,11,
107,8,237,4,0,
107,8,240,4,0,
107,8,241,4,0,
107,8,249,4,0,
107,8,263,4,0,
107,8,264,4,0,
107,8,279,1,1,1
107,8,280,4,0,
107,8,290,4,0,
107,8,317,4,0,
107,8,327,1,36,
107,8,339,4,0,
107,8,363,4,0,
107,8,364,1,21,
107,8,370,1,56,
107,8,374,4,0,
107,8,409,4,0,
107,8,410,1,26,
107,8,411,4,0,
107,8,418,1,16,2
107,8,431,4,0,
107,8,444,4,0,
107,8,445,4,0,
107,9,4,1,1,2
107,9,5,1,41,
107,9,7,1,31,3
107,9,7,3,0,
107,9,8,1,31,2
107,9,8,3,0,
107,9,9,1,31,1
107,9,9,3,0,
107,9,68,1,51,
107,9,70,4,0,
107,9,89,4,0,
107,9,92,4,0,
107,9,97,1,6,
107,9,104,4,0,
107,9,129,3,0,
107,9,156,4,0,
107,9,157,4,0,
107,9,164,4,0,
107,9,168,4,0,
107,9,173,3,0,
107,9,182,4,0,
107,9,183,1,16,1
107,9,189,3,0,
107,9,197,1,46,
107,9,203,4,0,
107,9,207,4,0,
107,9,213,4,0,
107,9,214,4,0,
107,9,216,4,0,
107,9,218,4,0,
107,9,228,1,11,
107,9,237,4,0,
107,9,240,4,0,
107,9,241,4,0,
107,9,249,4,0,
107,9,263,4,0,
107,9,264,4,0,
107,9,270,3,0,
107,9,279,1,1,1
107,9,280,4,0,
107,9,290,4,0,
107,9,317,4,0,
107,9,327,1,36,
107,9,339,4,0,
107,9,363,4,0,
107,9,364,1,21,
107,9,370,1,56,
107,9,374,4,0,
107,9,409,4,0,
107,9,410,1,26,
107,9,410,3,0,
107,9,411,4,0,
107,9,418,1,16,2
107,9,431,4,0,
107,9,444,4,0,
107,9,445,4,0,
107,10,4,1,1,2
107,10,5,1,41,
107,10,7,1,31,3
107,10,7,3,0,
107,10,8,1,31,2
107,10,8,3,0,
107,10,9,1,31,1
107,10,9,3,0,
107,10,29,3,0,
107,10,67,3,0,
107,10,68,1,51,
107,10,70,4,0,
107,10,89,4,0,
107,10,92,4,0,
107,10,97,1,6,
107,10,104,4,0,
107,10,129,3,0,
107,10,156,4,0,
107,10,157,4,0,
107,10,164,4,0,
107,10,168,4,0,
107,10,173,3,0,
107,10,182,4,0,
107,10,183,1,16,1
107,10,189,3,0,
107,10,197,1,46,
107,10,203,4,0,
107,10,207,4,0,
107,10,213,4,0,
107,10,214,4,0,
107,10,216,4,0,
107,10,218,4,0,
107,10,228,1,11,
107,10,237,4,0,
107,10,240,4,0,
107,10,241,4,0,
107,10,249,4,0,
107,10,263,4,0,
107,10,264,4,0,
107,10,270,3,0,
107,10,272,3,0,
107,10,279,1,1,1
107,10,280,4,0,
107,10,290,4,0,
107,10,317,4,0,
107,10,327,1,36,
107,10,339,4,0,
107,10,363,4,0,
107,10,364,1,21,
107,10,370,1,56,
107,10,374,4,0,
107,10,409,4,0,
107,10,410,1,26,
107,10,410,3,0,
107,10,411,4,0,
107,10,418,1,16,2
107,10,431,4,0,
107,10,444,4,0,
107,10,445,4,0,
107,11,4,1,1,2
107,11,5,1,46,
107,11,7,1,36,3
107,11,8,1,36,2
107,11,9,1,36,1
107,11,68,1,61,
107,11,70,4,0,
107,11,89,4,0,
107,11,92,4,0,
107,11,97,1,6,
107,11,104,4,0,
107,11,156,4,0,
107,11,157,4,0,
107,11,164,4,0,
107,11,168,4,0,
107,11,182,4,0,
107,11,183,1,16,1
107,11,197,1,51,
107,11,207,4,0,
107,11,213,4,0,
107,11,216,4,0,
107,11,218,4,0,
107,11,228,1,11,
107,11,237,4,0,
107,11,240,4,0,
107,11,241,4,0,
107,11,249,4,0,
107,11,263,4,0,
107,11,264,1,56,
107,11,279,1,1,1
107,11,280,4,0,
107,11,317,4,0,
107,11,327,1,41,
107,11,339,4,0,
107,11,364,1,21,
107,11,370,1,66,
107,11,374,4,0,
107,11,410,1,26,
107,11,411,4,0,
107,11,418,1,16,2
107,11,444,4,0,
107,11,490,4,0,
107,11,496,4,0,
107,11,501,1,31,
107,11,514,4,0,
107,11,523,4,0,
107,11,526,4,0,
107,12,4,1,1,2
107,12,5,1,38,
107,12,7,1,26,4
107,12,8,1,26,3
107,12,9,1,26,
107,12,68,1,50,
107,12,70,4,0,
107,12,89,4,0,
107,12,92,4,0,
107,12,97,1,7,
107,12,104,4,0,
107,12,156,4,0,
107,12,168,4,0,
107,12,182,4,0,
107,12,183,1,20,
107,12,197,1,44,
107,12,213,4,0,
107,12,216,4,0,
107,12,218,4,0,
107,12,228,1,13,
107,12,237,4,0,
107,12,240,4,0,
107,12,241,4,0,
107,12,249,4,0,
107,12,263,4,0,
107,12,264,4,0,
107,12,279,1,1,1
107,12,280,4,0,
107,12,290,4,0,
107,12,317,4,0,
107,12,327,1,32,
107,12,339,4,0,
107,13,4,1,1,2
107,13,5,1,38,
107,13,7,1,26,4
107,13,8,1,26,3
107,13,9,1,26,
107,13,34,3,0,
107,13,38,3,0,
107,13,68,1,50,
107,13,69,3,0,
107,13,70,4,0,
107,13,89,4,0,
107,13,92,4,0,
107,13,97,1,7,
107,13,102,3,0,
107,13,104,4,0,
107,13,156,4,0,
107,13,164,3,0,
107,13,168,4,0,
107,13,182,4,0,
107,13,183,1,20,
107,13,197,1,44,
107,13,207,3,0,
107,13,213,4,0,
107,13,216,4,0,
107,13,218,4,0,
107,13,228,1,13,
107,13,237,4,0,
107,13,240,4,0,
107,13,241,4,0,
107,13,249,4,0,
107,13,263,4,0,
107,13,264,4,0,
107,13,279,1,1,1
107,13,280,4,0,
107,13,290,4,0,
107,13,317,4,0,
107,13,327,1,32,
107,13,339,4,0,
107,14,4,1,1,2
107,14,5,1,46,
107,14,7,1,36,3
107,14,7,3,0,
107,14,8,1,36,2
107,14,8,3,0,
107,14,9,1,36,1
107,14,9,3,0,
107,14,67,3,0,
107,14,68,1,61,
107,14,70,4,0,
107,14,89,4,0,
107,14,92,4,0,
107,14,97,1,6,
107,14,104,4,0,
107,14,156,4,0,
107,14,157,4,0,
107,14,164,4,0,
107,14,168,4,0,
107,14,173,3,0,
107,14,182,4,0,
107,14,183,1,16,1
107,14,197,1,51,
107,14,207,4,0,
107,14,213,4,0,
107,14,214,3,0,
107,14,216,4,0,
107,14,218,4,0,
107,14,228,1,11,
107,14,237,4,0,
107,14,240,4,0,
107,14,241,4,0,
107,14,249,4,0,
107,14,263,4,0,
107,14,264,1,56,
107,14,270,3,0,
107,14,272,3,0,
107,14,279,1,1,1
107,14,280,4,0,
107,14,317,4,0,
107,14,327,1,41,
107,14,339,4,0,
107,14,343,3,0,
107,14,364,1,21,
107,14,370,1,66,
107,14,374,4,0,
107,14,409,3,0,
107,14,410,1,26,
107,14,411,4,0,
107,14,418,1,16,2
107,14,444,4,0,
107,14,490,4,0,
107,14,496,4,0,
107,14,501,1,31,
107,14,514,4,0,
107,14,523,4,0,
107,14,526,4,0,
107,15,4,1,1,5
107,15,5,1,46,
107,15,7,1,36,3
107,15,8,1,36,2
107,15,9,1,36,1
107,15,68,1,1,2
107,15,68,1,61,
107,15,70,4,0,
107,15,89,4,0,
107,15,92,4,0,
107,15,97,1,6,
107,15,104,4,0,
107,15,156,4,0,
107,15,157,4,0,
107,15,164,4,0,
107,15,168,4,0,
107,15,182,4,0,
107,15,183,1,16,1
107,15,197,1,50,
107,15,207,4,0,
107,15,213,4,0,
107,15,214,4,0,
107,15,216,4,0,
107,15,218,4,0,
107,15,228,1,11,
107,15,237,4,0,
107,15,240,4,0,
107,15,241,4,0,
107,15,249,4,0,
107,15,263,4,0,
107,15,264,1,1,3
107,15,264,1,56,
107,15,279,1,1,4
107,15,280,4,0,
107,15,317,4,0,
107,15,327,1,41,
107,15,339,4,0,
107,15,364,1,21,
107,15,370,1,1,1
107,15,370,1,66,
107,15,374,4,0,
107,15,410,1,26,
107,15,411,4,0,
107,15,418,1,16,2
107,15,444,4,0,
107,15,490,4,0,
107,15,496,4,0,
107,15,501,1,31,
107,15,514,4,0,
107,15,523,4,0,
107,15,590,4,0,
107,15,612,4,0,
107,16,4,1,1,5
107,16,5,1,46,1
107,16,7,1,36,3
107,16,7,3,0,
107,16,8,1,36,2
107,16,8,3,0,
107,16,9,1,36,1
107,16,9,3,0,
107,16,67,3,0,
107,16,68,1,1,2
107,16,68,1,61,1
107,16,70,4,0,
107,16,89,4,0,
107,16,92,4,0,
107,16,97,1,6,1
107,16,104,4,0,
107,16,156,4,0,
107,16,157,4,0,
107,16,164,4,0,
107,16,168,4,0,
107,16,173,3,0,
107,16,182,4,0,
107,16,183,1,16,1
107,16,197,1,50,1
107,16,207,4,0,
107,16,213,4,0,
107,16,214,4,0,
107,16,216,4,0,
107,16,218,4,0,
107,16,228,1,11,1
107,16,237,4,0,
107,16,240,4,0,
107,16,241,4,0,
107,16,249,4,0,
107,16,263,4,0,
107,16,264,1,1,3
107,16,264,1,56,1
107,16,264,3,0,
107,16,270,3,0,
107,16,272,3,0,
107,16,279,1,1,4
107,16,280,4,0,
107,16,290,4,0,
107,16,317,4,0,
107,16,327,1,41,1
107,16,339,4,0,
107,16,343,3,0,
107,16,364,1,21,1
107,16,370,1,1,1
107,16,370,1,66,1
107,16,374,4,0,
107,16,409,3,0,
107,16,410,1,26,1
107,16,411,4,0,
107,16,418,1,16,2
107,16,444,4,0,
107,16,490,4,0,
107,16,496,4,0,
107,16,501,1,31,1
107,16,514,4,0,
107,16,523,4,0,
107,16,590,4,0,
107,16,612,4,0,
107,17,4,1,0,
107,17,4,1,1,1
107,17,5,1,46,
107,17,7,1,36,3
107,17,8,1,36,2
107,17,9,1,36,1
107,17,68,1,1,3
107,17,68,1,61,
107,17,89,4,0,
107,17,92,4,0,
107,17,97,1,1,6
107,17,97,1,6,
107,17,104,4,0,
107,17,156,4,0,
107,17,157,4,0,
107,17,164,4,0,
107,17,168,4,0,
107,17,182,4,0,
107,17,183,1,1,8
107,17,183,1,16,1
107,17,197,1,50,
107,17,207,4,0,
107,17,213,4,0,
107,17,214,4,0,
107,17,216,4,0,
107,17,218,4,0,
107,17,228,1,1,7
107,17,228,1,11,
107,17,237,4,0,
107,17,240,4,0,
107,17,241,4,0,
107,17,263,4,0,
107,17,264,1,1,4
107,17,264,1,56,
107,17,279,1,1,5
107,17,280,4,0,
107,17,317,4,0,
107,17,327,1,41,
107,17,339,4,0,
107,17,364,1,21,
107,17,370,1,1,2
107,17,370,1,66,
107,17,374,4,0,
107,17,410,1,26,
107,17,411,4,0,
107,17,418,1,16,2
107,17,444,4,0,
107,17,490,4,0,
107,17,496,4,0,
107,17,501,1,31,
107,17,523,4,0,
107,17,526,4,0,
107,17,590,4,0,
107,18,4,1,0,
107,18,4,1,1,1
107,18,5,1,46,
107,18,7,1,36,3
107,18,8,1,36,2
107,18,9,1,36,1
107,18,68,1,1,3
107,18,68,1,61,
107,18,89,4,0,
107,18,92,4,0,
107,18,97,1,1,6
107,18,97,1,6,
107,18,104,4,0,
107,18,156,4,0,
107,18,157,4,0,
107,18,164,4,0,
107,18,168,4,0,
107,18,182,4,0,
107,18,183,1,1,8
107,18,183,1,16,1
107,18,197,1,50,
107,18,207,4,0,
107,18,213,4,0,
107,18,214,4,0,
107,18,216,4,0,
107,18,218,4,0,
107,18,228,1,1,7
107,18,228,1,11,
107,18,237,4,0,
107,18,240,4,0,
107,18,241,4,0,
107,18,263,4,0,
107,18,264,1,1,4
107,18,264,1,56,
107,18,279,1,1,5
107,18,280,4,0,
107,18,317,4,0,
107,18,327,1,41,
107,18,339,4,0,
107,18,364,1,21,
107,18,370,1,1,2
107,18,370,1,66,
107,18,374,4,0,
107,18,410,1,26,
107,18,411,4,0,
107,18,418,1,16,2
107,18,444,4,0,
107,18,490,4,0,
107,18,496,4,0,
107,18,501,1,31,
107,18,523,4,0,
107,18,526,4,0,
107,18,590,4,0,
108,1,5,4,0,
108,1,14,4,0,
108,1,15,4,0,
108,1,21,1,31,
108,1,23,1,7,
108,1,25,4,0,
108,1,34,4,0,
108,1,35,1,1,1
108,1,36,4,0,
108,1,38,4,0,
108,1,48,1,1,2
108,1,50,1,15,
108,1,55,4,0,
108,1,57,4,0,
108,1,58,4,0,
108,1,59,4,0,
108,1,61,4,0,
108,1,63,4,0,
108,1,66,4,0,
108,1,68,4,0,
108,1,69,4,0,
108,1,70,4,0,
108,1,85,4,0,
108,1,87,4,0,
108,1,89,4,0,
108,1,90,4,0,
108,1,92,4,0,
108,1,99,4,0,
108,1,102,4,0,
108,1,103,1,39,
108,1,104,4,0,
108,1,111,1,23,
108,1,117,4,0,
108,1,126,4,0,
108,1,130,4,0,
108,1,156,4,0,
108,1,164,4,0,
108,2,5,4,0,
108,2,14,4,0,
108,2,15,4,0,
108,2,21,1,31,
108,2,23,1,7,
108,2,25,4,0,
108,2,34,4,0,
108,2,35,1,1,1
108,2,36,4,0,
108,2,38,4,0,
108,2,48,1,1,2
108,2,50,1,15,
108,2,55,4,0,
108,2,57,4,0,
108,2,58,4,0,
108,2,59,4,0,
108,2,61,4,0,
108,2,63,4,0,
108,2,66,4,0,
108,2,68,4,0,
108,2,69,4,0,
108,2,70,4,0,
108,2,85,4,0,
108,2,87,4,0,
108,2,89,4,0,
108,2,90,4,0,
108,2,92,4,0,
108,2,99,4,0,
108,2,102,4,0,
108,2,103,1,39,
108,2,104,4,0,
108,2,111,1,23,
108,2,117,4,0,
108,2,126,4,0,
108,2,130,4,0,
108,2,156,4,0,
108,2,164,4,0,
108,3,7,4,0,
108,3,8,4,0,
108,3,9,4,0,
108,3,15,4,0,
108,3,21,1,37,
108,3,23,1,19,
108,3,29,4,0,
108,3,34,2,0,
108,3,35,1,25,
108,3,48,1,7,
108,3,50,1,31,
108,3,57,4,0,
108,3,59,4,0,
108,3,63,4,0,
108,3,70,4,0,
108,3,87,4,0,
108,3,89,4,0,
108,3,92,4,0,
108,3,103,1,43,
108,3,104,4,0,
108,3,111,1,13,
108,3,111,4,0,
108,3,122,1,1,
108,3,126,4,0,
108,3,138,4,0,
108,3,156,4,0,
108,3,168,4,0,
108,3,171,4,0,
108,3,173,4,0,
108,3,174,4,0,
108,3,182,4,0,
108,3,187,2,0,
108,3,189,4,0,
108,3,196,4,0,
108,3,201,4,0,
108,3,203,4,0,
108,3,205,4,0,
108,3,207,4,0,
108,3,213,4,0,
108,3,214,4,0,
108,3,216,4,0,
108,3,218,4,0,
108,3,222,2,0,
108,3,223,4,0,
108,3,231,4,0,
108,3,237,4,0,
108,3,240,4,0,
108,3,241,4,0,
108,3,244,4,0,
108,3,247,4,0,
108,3,249,4,0,
108,4,7,4,0,
108,4,8,4,0,
108,4,9,4,0,
108,4,15,4,0,
108,4,21,1,37,
108,4,23,1,19,
108,4,29,4,0,
108,4,34,2,0,
108,4,35,1,25,
108,4,48,1,7,
108,4,50,1,31,
108,4,53,3,0,
108,4,57,4,0,
108,4,58,3,0,
108,4,59,4,0,
108,4,63,4,0,
108,4,70,4,0,
108,4,85,3,0,
108,4,87,4,0,
108,4,89,4,0,
108,4,92,4,0,
108,4,103,1,43,
108,4,104,4,0,
108,4,111,1,13,
108,4,111,4,0,
108,4,122,1,1,
108,4,126,4,0,
108,4,138,4,0,
108,4,156,4,0,
108,4,168,4,0,
108,4,171,4,0,
108,4,173,4,0,
108,4,174,4,0,
108,4,182,4,0,
108,4,187,2,0,
108,4,189,4,0,
108,4,196,4,0,
108,4,201,4,0,
108,4,203,4,0,
108,4,205,4,0,
108,4,207,4,0,
108,4,213,4,0,
108,4,214,4,0,
108,4,216,4,0,
108,4,218,4,0,
108,4,222,2,0,
108,4,223,4,0,
108,4,231,4,0,
108,4,237,4,0,
108,4,240,4,0,
108,4,241,4,0,
108,4,244,4,0,
108,4,247,4,0,
108,4,249,4,0,
108,5,15,4,0,
108,5,21,1,40,
108,5,23,1,23,
108,5,34,2,0,
108,5,35,1,29,
108,5,48,1,7,
108,5,50,1,34,
108,5,53,4,0,
108,5,57,4,0,
108,5,58,4,0,
108,5,59,4,0,
108,5,63,4,0,
108,5,70,4,0,
108,5,76,4,0,
108,5,85,4,0,
108,5,87,4,0,
108,5,89,4,0,
108,5,91,4,0,
108,5,92,4,0,
108,5,103,1,45,
108,5,104,4,0,
108,5,111,1,12,
108,5,122,1,1,
108,5,126,4,0,
108,5,156,4,0,
108,5,164,2,0,
108,5,168,4,0,
108,5,173,2,0,
108,5,174,2,0,
108,5,182,4,0,
108,5,187,2,0,
108,5,201,4,0,
108,5,213,4,0,
108,5,214,2,0,
108,5,216,4,0,
108,5,218,4,0,
108,5,222,2,0,
108,5,231,4,0,
108,5,237,4,0,
108,5,240,4,0,
108,5,241,4,0,
108,5,247,4,0,
108,5,249,4,0,
108,5,263,4,0,
108,5,264,4,0,
108,5,265,2,0,
108,5,280,4,0,
108,5,282,1,18,
108,5,287,1,51,
108,5,290,4,0,
108,5,317,4,0,
108,5,351,4,0,
108,5,352,4,0,
108,6,5,3,0,
108,6,7,3,0,
108,6,8,3,0,
108,6,9,3,0,
108,6,14,3,0,
108,6,15,4,0,
108,6,21,1,40,
108,6,23,1,23,
108,6,25,3,0,
108,6,34,2,0,
108,6,34,3,0,
108,6,35,1,29,
108,6,38,3,0,
108,6,48,1,7,
108,6,50,1,34,
108,6,53,4,0,
108,6,57,4,0,
108,6,58,4,0,
108,6,59,4,0,
108,6,63,4,0,
108,6,68,3,0,
108,6,69,3,0,
108,6,70,4,0,
108,6,76,4,0,
108,6,85,4,0,
108,6,87,4,0,
108,6,89,4,0,
108,6,91,4,0,
108,6,92,4,0,
108,6,102,3,0,
108,6,103,1,45,
108,6,104,4,0,
108,6,111,1,12,
108,6,111,3,0,
108,6,122,1,1,
108,6,126,4,0,
108,6,138,3,0,
108,6,156,4,0,
108,6,157,3,0,
108,6,164,2,0,
108,6,164,3,0,
108,6,168,4,0,
108,6,173,2,0,
108,6,173,3,0,
108,6,174,2,0,
108,6,182,4,0,
108,6,187,2,0,
108,6,189,3,0,
108,6,196,3,0,
108,6,201,4,0,
108,6,203,3,0,
108,6,205,3,0,
108,6,207,3,0,
108,6,213,4,0,
108,6,214,2,0,
108,6,214,3,0,
108,6,216,4,0,
108,6,218,4,0,
108,6,222,2,0,
108,6,223,3,0,
108,6,231,4,0,
108,6,237,4,0,
108,6,240,4,0,
108,6,241,4,0,
108,6,244,3,0,
108,6,247,4,0,
108,6,249,4,0,
108,6,263,4,0,
108,6,264,4,0,
108,6,265,2,0,
108,6,280,4,0,
108,6,282,1,18,
108,6,287,1,51,
108,6,290,4,0,
108,6,317,4,0,
108,6,351,4,0,
108,6,352,4,0,
108,7,5,3,0,
108,7,14,3,0,
108,7,15,4,0,
108,7,21,1,40,
108,7,23,1,23,
108,7,25,3,0,
108,7,34,2,0,
108,7,34,3,0,
108,7,35,1,29,
108,7,38,3,0,
108,7,48,1,7,
108,7,50,1,34,
108,7,53,4,0,
108,7,57,4,0,
108,7,58,4,0,
108,7,59,4,0,
108,7,63,4,0,
108,7,68,3,0,
108,7,69,3,0,
108,7,70,4,0,
108,7,76,4,0,
108,7,85,4,0,
108,7,87,4,0,
108,7,89,4,0,
108,7,91,4,0,
108,7,92,4,0,
108,7,102,3,0,
108,7,103,1,45,
108,7,104,4,0,
108,7,111,1,12,
108,7,122,1,1,
108,7,126,4,0,
108,7,138,3,0,
108,7,156,4,0,
108,7,157,3,0,
108,7,164,2,0,
108,7,164,3,0,
108,7,168,4,0,
108,7,173,2,0,
108,7,174,2,0,
108,7,182,4,0,
108,7,187,2,0,
108,7,201,4,0,
108,7,213,4,0,
108,7,214,2,0,
108,7,216,4,0,
108,7,218,4,0,
108,7,222,2,0,
108,7,231,4,0,
108,7,237,4,0,
108,7,240,4,0,
108,7,241,4,0,
108,7,247,4,0,
108,7,249,4,0,
108,7,263,4,0,
108,7,264,4,0,
108,7,265,2,0,
108,7,280,4,0,
108,7,282,1,18,
108,7,287,1,51,
108,7,290,4,0,
108,7,317,4,0,
108,7,351,4,0,
108,7,352,4,0,
108,8,14,4,0,
108,8,15,4,0,
108,8,21,1,29,
108,8,23,1,21,
108,8,34,2,0,
108,8,35,1,17,
108,8,48,1,5,
108,8,50,1,25,
108,8,53,4,0,
108,8,57,4,0,
108,8,58,4,0,
108,8,59,4,0,
108,8,63,4,0,
108,8,70,4,0,
108,8,76,4,0,
108,8,85,4,0,
108,8,87,4,0,
108,8,89,4,0,
108,8,91,4,0,
108,8,92,4,0,
108,8,103,1,45,
108,8,104,4,0,
108,8,111,1,9,
108,8,122,1,1,
108,8,126,4,0,
108,8,133,2,0,
108,8,138,4,0,
108,8,156,4,0,
108,8,157,4,0,
108,8,164,2,0,
108,8,164,4,0,
108,8,168,4,0,
108,8,173,2,0,
108,8,174,2,0,
108,8,182,4,0,
108,8,187,2,0,
108,8,201,4,0,
108,8,203,4,0,
108,8,205,1,33,
108,8,207,4,0,
108,8,213,4,0,
108,8,214,2,0,
108,8,214,4,0,
108,8,216,4,0,
108,8,218,4,0,
108,8,222,2,0,
108,8,231,4,0,
108,8,237,4,0,
108,8,240,4,0,
108,8,241,4,0,
108,8,244,4,0,
108,8,247,4,0,
108,8,249,4,0,
108,8,263,4,0,
108,8,264,4,0,
108,8,265,2,0,
108,8,280,4,0,
108,8,282,1,13,
108,8,287,1,41,
108,8,290,4,0,
108,8,317,4,0,
108,8,351,4,0,
108,8,352,4,0,
108,8,359,2,0,
108,8,363,4,0,
108,8,374,4,0,
108,8,378,1,53,
108,8,382,1,37,
108,8,416,4,0,
108,8,431,4,0,
108,8,438,1,49,
108,8,445,4,0,
108,9,7,3,0,
108,9,8,3,0,
108,9,9,3,0,
108,9,14,4,0,
108,9,15,4,0,
108,9,21,1,29,
108,9,23,1,21,
108,9,34,2,0,
108,9,35,1,17,
108,9,48,1,5,
108,9,50,1,25,
108,9,53,4,0,
108,9,57,4,0,
108,9,58,4,0,
108,9,59,4,0,
108,9,63,4,0,
108,9,70,4,0,
108,9,76,4,0,
108,9,85,4,0,
108,9,87,4,0,
108,9,89,4,0,
108,9,91,4,0,
108,9,92,4,0,
108,9,103,1,45,
108,9,104,4,0,
108,9,111,1,9,
108,9,122,1,1,
108,9,126,4,0,
108,9,133,2,0,
108,9,138,4,0,
108,9,156,4,0,
108,9,157,4,0,
108,9,164,2,0,
108,9,164,4,0,
108,9,168,4,0,
108,9,173,2,0,
108,9,173,3,0,
108,9,174,2,0,
108,9,182,4,0,
108,9,187,2,0,
108,9,189,3,0,
108,9,196,3,0,
108,9,201,4,0,
108,9,203,4,0,
108,9,205,1,33,
108,9,205,3,0,
108,9,207,4,0,
108,9,213,4,0,
108,9,214,2,0,
108,9,214,4,0,
108,9,216,4,0,
108,9,218,4,0,
108,9,222,2,0,
108,9,231,4,0,
108,9,237,4,0,
108,9,240,4,0,
108,9,241,4,0,
108,9,244,4,0,
108,9,247,4,0,
108,9,249,4,0,
108,9,263,4,0,
108,9,264,4,0,
108,9,265,2,0,
108,9,280,4,0,
108,9,282,1,13,
108,9,282,3,0,
108,9,287,1,41,
108,9,290,4,0,
108,9,317,4,0,
108,9,351,4,0,
108,9,352,4,0,
108,9,359,2,0,
108,9,363,4,0,
108,9,374,4,0,
108,9,378,1,53,
108,9,382,1,37,
108,9,401,3,0,
108,9,416,4,0,
108,9,428,3,0,
108,9,431,4,0,
108,9,438,1,49,
108,9,445,4,0,
108,10,7,3,0,
108,10,8,3,0,
108,10,9,3,0,
108,10,14,4,0,
108,10,15,4,0,
108,10,21,1,29,
108,10,23,1,21,
108,10,29,3,0,
108,10,34,2,0,
108,10,35,1,17,
108,10,48,1,5,
108,10,50,1,25,
108,10,53,4,0,
108,10,57,4,0,
108,10,58,4,0,
108,10,59,4,0,
108,10,63,4,0,
108,10,70,4,0,
108,10,76,4,0,
108,10,85,4,0,
108,10,87,4,0,
108,10,89,4,0,
108,10,91,4,0,
108,10,92,4,0,
108,10,103,1,45,
108,10,104,4,0,
108,10,111,1,9,
108,10,122,1,1,
108,10,126,4,0,
108,10,133,2,0,
108,10,138,4,0,
108,10,156,4,0,
108,10,157,4,0,
108,10,164,2,0,
108,10,164,4,0,
108,10,168,4,0,
108,10,173,2,0,
108,10,173,3,0,
108,10,174,2,0,
108,10,182,4,0,
108,10,187,2,0,
108,10,189,3,0,
108,10,196,3,0,
108,10,201,4,0,
108,10,203,4,0,
108,10,205,1,33,
108,10,205,3,0,
108,10,207,4,0,
108,10,213,4,0,
108,10,214,2,0,
108,10,214,4,0,
108,10,216,4,0,
108,10,218,4,0,
108,10,222,2,0,
108,10,231,4,0,
108,10,237,4,0,
108,10,240,4,0,
108,10,241,4,0,
108,10,244,4,0,
108,10,247,4,0,
108,10,249,4,0,
108,10,250,4,0,
108,10,263,4,0,
108,10,264,4,0,
108,10,265,2,0,
108,10,280,4,0,
108,10,282,1,13,
108,10,282,3,0,
108,10,287,1,41,
108,10,290,4,0,
108,10,317,4,0,
108,10,330,2,0,
108,10,351,4,0,
108,10,352,4,0,
108,10,359,2,0,
108,10,363,4,0,
108,10,374,4,0,
108,10,378,1,53,
108,10,382,1,37,
108,10,401,3,0,
108,10,416,4,0,
108,10,428,3,0,
108,10,431,4,0,
108,10,438,1,49,
108,10,445,4,0,
108,11,14,4,0,
108,11,15,4,0,
108,11,21,1,29,
108,11,23,1,21,
108,11,34,2,0,
108,11,35,1,17,
108,11,48,1,5,
108,11,50,1,25,
108,11,53,4,0,
108,11,57,4,0,
108,11,58,4,0,
108,11,59,4,0,
108,11,63,4,0,
108,11,70,4,0,
108,11,76,4,0,
108,11,85,4,0,
108,11,87,4,0,
108,11,89,4,0,
108,11,91,4,0,
108,11,92,4,0,
108,11,103,1,49,
108,11,104,4,0,
108,11,111,1,9,
108,11,122,1,1,
108,11,126,4,0,
108,11,133,2,0,
108,11,138,4,0,
108,11,156,4,0,
108,11,157,4,0,
108,11,164,4,0,
108,11,168,4,0,
108,11,173,2,0,
108,11,174,2,0,
108,11,182,4,0,
108,11,187,2,0,
108,11,201,4,0,
108,11,205,1,33,
108,11,207,4,0,
108,11,213,4,0,
108,11,214,2,0,
108,11,216,4,0,
108,11,218,4,0,
108,11,222,2,0,
108,11,237,4,0,
108,11,240,4,0,
108,11,241,4,0,
108,11,244,4,0,
108,11,247,4,0,
108,11,249,4,0,
108,11,263,4,0,
108,11,265,2,0,
108,11,280,4,0,
108,11,282,1,13,
108,11,287,1,45,
108,11,317,4,0,
108,11,330,2,0,
108,11,359,2,0,
108,11,374,4,0,
108,11,378,1,57,
108,11,382,1,41,
108,11,416,4,0,
108,11,428,2,0,
108,11,438,1,53,
108,11,496,4,0,
108,11,498,1,37,
108,11,510,4,0,
108,11,514,4,0,
108,11,523,4,0,
108,11,525,4,0,
108,11,526,4,0,
108,12,15,4,0,
108,12,21,1,40,
108,12,23,1,23,
108,12,35,1,29,
108,12,48,1,7,
108,12,50,1,34,
108,12,53,4,0,
108,12,57,4,0,
108,12,58,4,0,
108,12,59,4,0,
108,12,63,4,0,
108,12,70,4,0,
108,12,76,4,0,
108,12,85,4,0,
108,12,87,4,0,
108,12,89,4,0,
108,12,91,4,0,
108,12,92,4,0,
108,12,103,1,45,
108,12,104,4,0,
108,12,111,1,12,
108,12,122,1,1,
108,12,126,4,0,
108,12,156,4,0,
108,12,168,4,0,
108,12,182,4,0,
108,12,201,4,0,
108,12,213,4,0,
108,12,216,4,0,
108,12,218,4,0,
108,12,231,4,0,
108,12,237,4,0,
108,12,240,4,0,
108,12,241,4,0,
108,12,247,4,0,
108,12,249,4,0,
108,12,263,4,0,
108,12,264,4,0,
108,12,280,4,0,
108,12,282,1,18,
108,12,287,1,51,
108,12,290,4,0,
108,12,317,4,0,
108,12,351,4,0,
108,12,352,4,0,
108,13,15,4,0,
108,13,21,1,40,
108,13,23,1,23,
108,13,34,3,0,
108,13,35,1,29,
108,13,38,3,0,
108,13,48,1,7,
108,13,50,1,34,
108,13,53,4,0,
108,13,57,4,0,
108,13,58,4,0,
108,13,59,4,0,
108,13,63,4,0,
108,13,69,3,0,
108,13,70,4,0,
108,13,76,4,0,
108,13,85,4,0,
108,13,87,4,0,
108,13,89,4,0,
108,13,91,4,0,
108,13,92,4,0,
108,13,102,3,0,
108,13,103,1,45,
108,13,104,4,0,
108,13,111,1,12,
108,13,122,1,1,
108,13,126,4,0,
108,13,138,3,0,
108,13,156,4,0,
108,13,164,3,0,
108,13,168,4,0,
108,13,171,3,0,
108,13,182,4,0,
108,13,196,3,0,
108,13,201,4,0,
108,13,207,3,0,
108,13,213,4,0,
108,13,216,4,0,
108,13,218,4,0,
108,13,231,4,0,
108,13,237,4,0,
108,13,240,4,0,
108,13,241,4,0,
108,13,247,4,0,
108,13,249,4,0,
108,13,263,4,0,
108,13,264,4,0,
108,13,280,4,0,
108,13,282,1,18,
108,13,287,1,51,
108,13,290,4,0,
108,13,317,4,0,
108,13,351,4,0,
108,13,352,4,0,
108,14,7,3,0,
108,14,8,3,0,
108,14,9,3,0,
108,14,14,4,0,
108,14,15,4,0,
108,14,20,3,0,
108,14,21,1,29,
108,14,23,1,21,
108,14,34,2,0,
108,14,35,1,17,
108,14,48,1,5,
108,14,50,1,25,
108,14,53,4,0,
108,14,57,4,0,
108,14,58,4,0,
108,14,59,4,0,
108,14,63,4,0,
108,14,70,4,0,
108,14,76,4,0,
108,14,85,4,0,
108,14,87,4,0,
108,14,89,4,0,
108,14,91,4,0,
108,14,92,4,0,
108,14,103,1,49,
108,14,104,4,0,
108,14,111,1,9,
108,14,122,1,1,
108,14,126,4,0,
108,14,133,2,0,
108,14,138,4,0,
108,14,156,4,0,
108,14,157,4,0,
108,14,164,4,0,
108,14,168,4,0,
108,14,173,2,0,
108,14,173,3,0,
108,14,174,2,0,
108,14,182,4,0,
108,14,187,2,0,
108,14,196,3,0,
108,14,201,4,0,
108,14,205,1,33,
108,14,207,4,0,
108,14,213,4,0,
108,14,214,2,0,
108,14,214,3,0,
108,14,216,4,0,
108,14,218,4,0,
108,14,222,2,0,
108,14,231,3,0,
108,14,237,4,0,
108,14,240,4,0,
108,14,241,4,0,
108,14,244,4,0,
108,14,247,4,0,
108,14,249,4,0,
108,14,263,4,0,
108,14,265,2,0,
108,14,280,4,0,
108,14,282,1,13,
108,14,282,3,0,
108,14,287,1,45,
108,14,317,4,0,
108,14,330,2,0,
108,14,359,2,0,
108,14,374,4,0,
108,14,378,1,57,
108,14,382,1,41,
108,14,401,3,0,
108,14,416,4,0,
108,14,428,2,0,
108,14,428,3,0,
108,14,438,1,53,
108,14,496,4,0,
108,14,498,1,37,
108,14,510,4,0,
108,14,514,4,0,
108,14,523,4,0,
108,14,525,4,0,
108,14,526,4,0,
108,15,14,4,0,
108,15,15,4,0,
108,15,21,1,29,
108,15,23,1,21,
108,15,34,2,0,
108,15,35,1,17,
108,15,48,1,5,
108,15,50,1,25,
108,15,53,4,0,
108,15,57,4,0,
108,15,58,4,0,
108,15,59,4,0,
108,15,63,4,0,
108,15,70,4,0,
108,15,76,4,0,
108,15,85,4,0,
108,15,87,4,0,
108,15,89,4,0,
108,15,91,4,0,
108,15,92,4,0,
108,15,103,1,49,
108,15,104,4,0,
108,15,111,1,9,
108,15,122,1,1,
108,15,126,4,0,
108,15,133,2,0,
108,15,138,4,0,
108,15,156,4,0,
108,15,157,4,0,
108,15,164,4,0,
108,15,168,4,0,
108,15,173,2,0,
108,15,174,2,0,
108,15,182,4,0,
108,15,187,2,0,
108,15,201,4,0,
108,15,205,1,33,
108,15,207,4,0,
108,15,213,4,0,
108,15,214,2,0,
108,15,214,4,0,
108,15,216,4,0,
108,15,218,4,0,
108,15,222,2,0,
108,15,237,4,0,
108,15,240,4,0,
108,15,241,4,0,
108,15,244,4,0,
108,15,247,4,0,
108,15,249,4,0,
108,15,263,4,0,
108,15,265,2,0,
108,15,280,4,0,
108,15,282,1,13,
108,15,287,1,45,
108,15,317,4,0,
108,15,330,2,0,
108,15,359,2,0,
108,15,374,4,0,
108,15,378,1,57,
108,15,382,1,41,
108,15,416,4,0,
108,15,428,2,0,
108,15,438,1,53,
108,15,496,4,0,
108,15,498,1,37,
108,15,510,4,0,
108,15,514,4,0,
108,15,523,4,0,
108,15,525,4,0,
108,15,562,2,0,
108,15,590,4,0,
108,15,612,4,0,
108,16,7,3,0,
108,16,8,3,0,
108,16,9,3,0,
108,16,14,4,0,
108,16,15,4,0,
108,16,20,3,0,
108,16,21,1,29,1
108,16,23,1,21,1
108,16,34,2,0,
108,16,35,1,17,1
108,16,48,1,5,1
108,16,50,1,25,1
108,16,53,4,0,
108,16,57,4,0,
108,16,58,4,0,
108,16,59,4,0,
108,16,63,4,0,
108,16,70,4,0,
108,16,76,4,0,
108,16,85,4,0,
108,16,87,4,0,
108,16,89,4,0,
108,16,91,4,0,
108,16,92,4,0,
108,16,103,1,49,1
108,16,104,4,0,
108,16,111,1,9,1
108,16,122,1,1,1
108,16,126,4,0,
108,16,133,2,0,
108,16,138,4,0,
108,16,156,4,0,
108,16,157,4,0,
108,16,164,4,0,
108,16,168,4,0,
108,16,173,2,0,
108,16,173,3,0,
108,16,174,2,0,
108,16,182,4,0,
108,16,187,2,0,
108,16,196,3,0,
108,16,201,4,0,
108,16,205,1,33,1
108,16,207,4,0,
108,16,213,4,0,
108,16,214,2,0,
108,16,214,4,0,
108,16,216,4,0,
108,16,218,4,0,
108,16,222,2,0,
108,16,231,3,0,
108,16,237,4,0,
108,16,240,4,0,
108,16,241,4,0,
108,16,244,4,0,
108,16,247,4,0,
108,16,249,4,0,
108,16,263,4,0,
108,16,264,3,0,
108,16,265,2,0,
108,16,280,4,0,
108,16,282,1,13,1
108,16,282,3,0,
108,16,287,1,45,1
108,16,290,4,0,
108,16,317,4,0,
108,16,330,2,0,
108,16,351,3,0,
108,16,352,3,0,
108,16,359,2,0,
108,16,374,4,0,
108,16,378,1,57,1
108,16,382,1,41,1
108,16,401,3,0,
108,16,416,4,0,
108,16,428,2,0,
108,16,428,3,0,
108,16,438,1,53,1
108,16,496,4,0,
108,16,498,1,37,1
108,16,510,4,0,
108,16,514,4,0,
108,16,523,4,0,
108,16,525,4,0,
108,16,562,2,0,
108,16,590,4,0,
108,16,612,4,0,
108,17,14,4,0,
108,17,21,1,29,
108,17,23,1,21,
108,17,34,2,0,
108,17,35,1,17,
108,17,48,1,5,
108,17,50,1,25,
108,17,53,4,0,
108,17,57,4,0,
108,17,58,4,0,
108,17,59,4,0,
108,17,63,4,0,
108,17,76,4,0,
108,17,85,4,0,
108,17,87,4,0,
108,17,89,4,0,
108,17,92,4,0,
108,17,103,1,49,
108,17,104,4,0,
108,17,111,1,9,
108,17,122,1,1,
108,17,126,4,0,
108,17,133,2,0,
108,17,138,4,0,
108,17,156,4,0,
108,17,157,4,0,
108,17,164,4,0,
108,17,168,4,0,
108,17,173,2,0,
108,17,174,2,0,
108,17,182,4,0,
108,17,187,2,0,
108,17,201,4,0,
108,17,205,1,33,
108,17,207,4,0,
108,17,213,4,0,
108,17,214,2,0,
108,17,214,4,0,
108,17,216,4,0,
108,17,218,4,0,
108,17,222,2,0,
108,17,237,4,0,
108,17,240,4,0,
108,17,241,4,0,
108,17,244,4,0,
108,17,247,4,0,
108,17,263,4,0,
108,17,265,2,0,
108,17,280,4,0,
108,17,282,1,13,
108,17,287,1,45,
108,17,317,4,0,
108,17,330,2,0,
108,17,359,2,0,
108,17,374,4,0,
108,17,378,1,57,
108,17,382,1,41,
108,17,416,4,0,
108,17,428,2,0,
108,17,438,1,53,
108,17,496,4,0,
108,17,498,1,37,
108,17,523,4,0,
108,17,525,4,0,
108,17,526,4,0,
108,17,562,2,0,
108,17,590,4,0,
108,17,693,4,0,
108,18,14,4,0,
108,18,21,1,29,
108,18,23,1,21,
108,18,34,2,0,
108,18,35,1,17,
108,18,37,2,0,
108,18,48,1,5,
108,18,50,1,25,
108,18,53,4,0,
108,18,57,4,0,
108,18,58,4,0,
108,18,59,4,0,
108,18,63,4,0,
108,18,76,4,0,
108,18,85,4,0,
108,18,87,4,0,
108,18,89,4,0,
108,18,92,4,0,
108,18,103,1,49,
108,18,104,4,0,
108,18,111,1,9,
108,18,122,1,1,
108,18,126,4,0,
108,18,133,2,0,
108,18,138,4,0,
108,18,156,4,0,
108,18,157,4,0,
108,18,164,4,0,
108,18,168,4,0,
108,18,173,2,0,
108,18,174,2,0,
108,18,182,4,0,
108,18,187,2,0,
108,18,201,4,0,
108,18,205,1,33,
108,18,207,4,0,
108,18,213,4,0,
108,18,214,2,0,
108,18,214,4,0,
108,18,216,4,0,
108,18,218,4,0,
108,18,222,2,0,
108,18,237,4,0,
108,18,240,4,0,
108,18,241,4,0,
108,18,244,4,0,
108,18,247,4,0,
108,18,263,4,0,
108,18,265,2,0,
108,18,280,4,0,
108,18,282,1,13,
108,18,287,1,45,
108,18,317,4,0,
108,18,330,2,0,
108,18,359,2,0,
108,18,374,4,0,
108,18,378,1,57,
108,18,382,1,41,
108,18,416,4,0,
108,18,428,2,0,
108,18,438,1,53,
108,18,496,4,0,
108,18,498,1,37,
108,18,523,4,0,
108,18,525,4,0,
108,18,526,4,0,
108,18,562,2,0,
108,18,590,4,0,
108,18,693,4,0,
109,1,33,1,1,1
109,1,85,4,0,
109,1,87,4,0,
109,1,92,4,0,
109,1,99,4,0,
109,1,102,4,0,
109,1,104,4,0,
109,1,108,1,37,
109,1,114,1,45,
109,1,117,4,0,
109,1,120,1,40,
109,1,120,4,0,
109,1,123,1,1,2
109,1,124,1,32,
109,1,126,4,0,
109,1,153,1,48,
109,1,153,4,0,
109,1,156,4,0,
109,1,164,4,0,
109,2,33,1,1,1
109,2,85,4,0,
109,2,87,4,0,
109,2,92,4,0,
109,2,99,4,0,
109,2,102,4,0,
109,2,104,4,0,
109,2,108,1,37,
109,2,114,1,45,
109,2,117,4,0,
109,2,120,1,40,
109,2,120,4,0,
109,2,123,1,1,2
109,2,124,1,32,
109,2,126,4,0,
109,2,153,1,48,
109,2,153,4,0,
109,2,156,4,0,
109,2,164,4,0,
109,3,33,1,1,2
109,3,60,2,0,
109,3,87,4,0,
109,3,92,4,0,
109,3,103,2,0,
109,3,104,4,0,
109,3,108,1,25,
109,3,114,1,33,
109,3,120,1,17,
109,3,123,1,9,
109,3,124,1,21,
109,3,126,4,0,
109,3,139,1,1,1
109,3,149,2,0,
109,3,153,1,41,
109,3,156,4,0,
109,3,168,4,0,
109,3,173,4,0,
109,3,174,4,0,
109,3,182,4,0,
109,3,188,4,0,
109,3,192,4,0,
109,3,194,1,45,
109,3,194,2,0,
109,3,203,4,0,
109,3,205,4,0,
109,3,207,4,0,
109,3,213,4,0,
109,3,214,4,0,
109,3,216,4,0,
109,3,218,4,0,
109,3,220,2,0,
109,3,237,4,0,
109,3,241,4,0,
109,4,33,1,1,2
109,4,53,3,0,
109,4,60,2,0,
109,4,85,3,0,
109,4,87,4,0,
109,4,92,4,0,
109,4,103,2,0,
109,4,104,4,0,
109,4,108,1,25,
109,4,114,1,33,
109,4,120,1,17,
109,4,123,1,9,
109,4,124,1,21,
109,4,126,4,0,
109,4,139,1,1,1
109,4,149,2,0,
109,4,153,1,41,
109,4,156,4,0,
109,4,168,4,0,
109,4,173,4,0,
109,4,174,4,0,
109,4,182,4,0,
109,4,188,4,0,
109,4,192,4,0,
109,4,194,1,45,
109,4,194,2,0,
109,4,203,4,0,
109,4,205,4,0,
109,4,207,4,0,
109,4,213,4,0,
109,4,214,4,0,
109,4,216,4,0,
109,4,218,4,0,
109,4,220,2,0,
109,4,237,4,0,
109,4,241,4,0,
109,5,33,1,1,2
109,5,53,4,0,
109,5,60,2,0,
109,5,85,4,0,
109,5,87,4,0,
109,5,92,4,0,
109,5,103,2,0,
109,5,104,4,0,
109,5,108,1,25,
109,5,114,1,33,
109,5,120,1,17,
109,5,123,1,9,
109,5,124,1,21,
109,5,126,4,0,
109,5,139,1,1,1
109,5,148,4,0,
109,5,149,2,0,
109,5,153,1,41,
109,5,156,4,0,
109,5,168,4,0,
109,5,182,4,0,
109,5,188,4,0,
109,5,194,1,45,
109,5,194,2,0,
109,5,213,4,0,
109,5,216,4,0,
109,5,218,4,0,
109,5,220,2,0,
109,5,237,4,0,
109,5,240,4,0,
109,5,241,4,0,
109,5,247,4,0,
109,5,259,4,0,
109,5,261,2,0,
109,5,262,1,49,
109,5,263,4,0,
109,5,269,4,0,
109,5,290,4,0,
109,5,351,4,0,
109,6,33,1,1,2
109,6,53,4,0,
109,6,60,2,0,
109,6,85,4,0,
109,6,87,4,0,
109,6,92,4,0,
109,6,102,3,0,
109,6,103,2,0,
109,6,104,4,0,
109,6,108,1,25,
109,6,114,1,33,
109,6,120,1,17,
109,6,123,1,9,
109,6,124,1,21,
109,6,126,4,0,
109,6,139,1,1,1
109,6,148,4,0,
109,6,149,2,0,
109,6,153,1,41,
109,6,153,3,0,
109,6,156,4,0,
109,6,164,3,0,
109,6,168,4,0,
109,6,173,3,0,
109,6,182,4,0,
109,6,188,4,0,
109,6,194,1,45,
109,6,194,2,0,
109,6,203,3,0,
109,6,205,3,0,
109,6,207,3,0,
109,6,213,4,0,
109,6,214,3,0,
109,6,216,4,0,
109,6,218,4,0,
109,6,220,2,0,
109,6,237,4,0,
109,6,240,4,0,
109,6,241,4,0,
109,6,247,4,0,
109,6,259,4,0,
109,6,261,2,0,
109,6,262,1,49,
109,6,263,4,0,
109,6,269,4,0,
109,6,290,4,0,
109,6,351,4,0,
109,7,33,1,1,2
109,7,53,4,0,
109,7,60,2,0,
109,7,85,4,0,
109,7,87,4,0,
109,7,92,4,0,
109,7,102,3,0,
109,7,103,2,0,
109,7,104,4,0,
109,7,108,1,25,
109,7,114,1,33,
109,7,120,1,17,
109,7,123,1,9,
109,7,124,1,21,
109,7,126,4,0,
109,7,139,1,1,1
109,7,148,4,0,
109,7,149,2,0,
109,7,153,1,41,
109,7,153,3,0,
109,7,156,4,0,
109,7,164,3,0,
109,7,168,4,0,
109,7,182,4,0,
109,7,188,4,0,
109,7,194,1,45,
109,7,194,2,0,
109,7,213,4,0,
109,7,216,4,0,
109,7,218,4,0,
109,7,220,2,0,
109,7,237,4,0,
109,7,240,4,0,
109,7,241,4,0,
109,7,247,4,0,
109,7,259,4,0,
109,7,261,2,0,
109,7,262,1,49,
109,7,263,4,0,
109,7,269,4,0,
109,7,290,4,0,
109,7,351,4,0,
109,8,33,1,1,2
109,8,53,4,0,
109,8,60,2,0,
109,8,85,4,0,
109,8,87,4,0,
109,8,92,4,0,
109,8,103,2,0,
109,8,104,4,0,
109,8,108,1,10,
109,8,114,1,28,
109,8,120,1,19,
109,8,123,1,6,
109,8,124,1,24,
109,8,126,4,0,
109,8,139,1,1,1
109,8,148,4,0,
109,8,149,2,0,
109,8,153,1,37,
109,8,153,4,0,
109,8,156,4,0,
109,8,164,4,0,
109,8,168,4,0,
109,8,174,2,0,
109,8,180,2,0,
109,8,182,4,0,
109,8,188,1,42,
109,8,188,4,0,
109,8,194,1,46,
109,8,194,2,0,
109,8,203,4,0,
109,8,207,4,0,
109,8,213,4,0,
109,8,214,4,0,
109,8,216,4,0,
109,8,218,4,0,
109,8,220,2,0,
109,8,237,4,0,
109,8,240,4,0,
109,8,241,4,0,
109,8,247,4,0,
109,8,259,4,0,
109,8,261,2,0,
109,8,261,4,0,
109,8,262,1,51,
109,8,263,4,0,
109,8,269,4,0,
109,8,288,2,0,
109,8,290,4,0,
109,8,351,4,0,
109,8,360,1,33,
109,8,360,4,0,
109,8,363,4,0,
109,8,371,4,0,
109,8,372,1,15,
109,8,399,4,0,
109,8,445,4,0,
109,9,33,1,1,2
109,9,53,4,0,
109,9,60,2,0,
109,9,85,4,0,
109,9,87,4,0,
109,9,92,4,0,
109,9,103,2,0,
109,9,104,4,0,
109,9,108,1,10,
109,9,114,1,28,
109,9,120,1,19,
109,9,123,1,6,
109,9,124,1,24,
109,9,126,4,0,
109,9,139,1,1,1
109,9,148,4,0,
109,9,149,2,0,
109,9,153,1,37,
109,9,153,4,0,
109,9,156,4,0,
109,9,164,4,0,
109,9,168,4,0,
109,9,173,3,0,
109,9,174,2,0,
109,9,180,2,0,
109,9,180,3,0,
109,9,182,4,0,
109,9,188,1,42,
109,9,188,4,0,
109,9,194,1,46,
109,9,194,2,0,
109,9,203,4,0,
109,9,205,3,0,
109,9,207,4,0,
109,9,213,4,0,
109,9,214,4,0,
109,9,216,4,0,
109,9,218,4,0,
109,9,220,2,0,
109,9,237,4,0,
109,9,240,4,0,
109,9,241,4,0,
109,9,247,4,0,
109,9,253,3,0,
109,9,259,4,0,
109,9,261,2,0,
109,9,261,4,0,
109,9,262,1,51,
109,9,263,4,0,
109,9,269,4,0,
109,9,288,2,0,
109,9,290,4,0,
109,9,351,4,0,
109,9,360,1,33,
109,9,360,4,0,
109,9,363,4,0,
109,9,371,4,0,
109,9,372,1,15,
109,9,399,4,0,
109,9,445,4,0,
109,10,33,1,1,2
109,10,53,4,0,
109,10,60,2,0,
109,10,85,4,0,
109,10,87,4,0,
109,10,92,4,0,
109,10,103,2,0,
109,10,104,4,0,
109,10,108,1,10,
109,10,114,1,28,
109,10,120,1,19,
109,10,123,1,6,
109,10,124,1,24,
109,10,126,4,0,
109,10,139,1,1,1
109,10,148,4,0,
109,10,149,2,0,
109,10,153,1,37,
109,10,153,4,0,
109,10,156,4,0,
109,10,164,4,0,
109,10,168,4,0,
109,10,173,3,0,
109,10,174,2,0,
109,10,180,2,0,
109,10,180,3,0,
109,10,182,4,0,
109,10,188,1,42,
109,10,188,4,0,
109,10,194,1,46,
109,10,194,2,0,
109,10,203,4,0,
109,10,205,3,0,
109,10,207,4,0,
109,10,213,4,0,
109,10,214,4,0,
109,10,216,4,0,
109,10,218,4,0,
109,10,220,2,0,
109,10,220,3,0,
109,10,237,4,0,
109,10,240,4,0,
109,10,241,4,0,
109,10,247,4,0,
109,10,253,3,0,
109,10,259,4,0,
109,10,261,2,0,
109,10,261,4,0,
109,10,262,1,51,
109,10,263,4,0,
109,10,269,4,0,
109,10,288,2,0,
109,10,290,4,0,
109,10,351,4,0,
109,10,360,1,33,
109,10,360,4,0,
109,10,363,4,0,
109,10,371,4,0,
109,10,372,1,15,
109,10,399,4,0,
109,10,445,4,0,
109,11,33,1,1,2
109,11,53,4,0,
109,11,60,2,0,
109,11,85,4,0,
109,11,87,4,0,
109,11,92,4,0,
109,11,103,2,0,
109,11,104,4,0,
109,11,108,1,10,
109,11,114,1,33,
109,11,120,1,24,
109,11,123,1,6,
109,11,124,1,28,
109,11,126,4,0,
109,11,139,1,1,1
109,11,148,4,0,
109,11,149,2,0,
109,11,153,1,42,
109,11,153,4,0,
109,11,156,4,0,
109,11,164,4,0,
109,11,168,4,0,
109,11,174,2,0,
109,11,180,2,0,
109,11,182,4,0,
109,11,188,1,46,
109,11,188,4,0,
109,11,194,1,51,
109,11,194,2,0,
109,11,207,4,0,
109,11,213,4,0,
109,11,216,4,0,
109,11,218,4,0,
109,11,220,2,0,
109,11,237,4,0,
109,11,240,4,0,
109,11,241,4,0,
109,11,247,4,0,
109,11,254,2,0,
109,11,255,2,0,
109,11,256,2,0,
109,11,259,4,0,
109,11,261,4,0,
109,11,262,1,55,
109,11,263,4,0,
109,11,269,4,0,
109,11,288,2,0,
109,11,360,1,37,
109,11,360,4,0,
109,11,371,4,0,
109,11,372,1,15,
109,11,474,4,0,
109,11,496,4,0,
109,11,499,1,19,
109,11,510,4,0,
109,12,33,1,1,2
109,12,53,4,0,
109,12,85,4,0,
109,12,87,4,0,
109,12,92,4,0,
109,12,104,4,0,
109,12,108,1,25,
109,12,114,1,33,
109,12,120,1,17,
109,12,123,1,9,
109,12,124,1,21,
109,12,126,4,0,
109,12,139,1,1,1
109,12,148,4,0,
109,12,153,1,41,
109,12,156,4,0,
109,12,168,4,0,
109,12,182,4,0,
109,12,188,4,0,
109,12,194,1,45,
109,12,213,4,0,
109,12,216,4,0,
109,12,218,4,0,
109,12,237,4,0,
109,12,240,4,0,
109,12,241,4,0,
109,12,247,4,0,
109,12,259,4,0,
109,12,262,1,49,
109,12,263,4,0,
109,12,269,4,0,
109,12,290,4,0,
109,12,351,4,0,
109,13,33,1,1,2
109,13,53,4,0,
109,13,85,4,0,
109,13,87,4,0,
109,13,92,4,0,
109,13,102,3,0,
109,13,104,4,0,
109,13,108,1,25,
109,13,114,1,33,
109,13,120,1,17,
109,13,120,3,0,
109,13,123,1,9,
109,13,124,1,21,
109,13,126,4,0,
109,13,139,1,1,1
109,13,148,4,0,
109,13,153,1,41,
109,13,156,4,0,
109,13,164,3,0,
109,13,168,4,0,
109,13,182,4,0,
109,13,188,4,0,
109,13,194,1,45,
109,13,207,3,0,
109,13,213,4,0,
109,13,216,4,0,
109,13,218,4,0,
109,13,237,4,0,
109,13,240,4,0,
109,13,241,4,0,
109,13,247,4,0,
109,13,259,4,0,
109,13,262,1,49,
109,13,263,4,0,
109,13,269,4,0,
109,13,290,4,0,
109,13,351,4,0,
109,14,33,1,1,2
109,14,53,4,0,
109,14,60,2,0,
109,14,85,4,0,
109,14,87,4,0,
109,14,92,4,0,
109,14,103,2,0,
109,14,104,4,0,
109,14,108,1,7,
109,14,114,1,26,
109,14,120,1,23,
109,14,123,1,4,
109,14,124,1,18,
109,14,126,4,0,
109,14,139,1,1,1
109,14,148,4,0,
109,14,149,2,0,
109,14,153,1,37,
109,14,153,4,0,
109,14,156,4,0,
109,14,164,4,0,
109,14,168,4,0,
109,14,173,3,0,
109,14,174,2,0,
109,14,180,2,0,
109,14,180,3,0,
109,14,182,4,0,
109,14,188,1,34,
109,14,188,4,0,
109,14,194,1,40,
109,14,194,2,0,
109,14,207,4,0,
109,14,213,4,0,
109,14,214,3,0,
109,14,216,4,0,
109,14,218,4,0,
109,14,220,2,0,
109,14,220,3,0,
109,14,237,4,0,
109,14,240,4,0,
109,14,241,4,0,
109,14,247,4,0,
109,14,253,3,0,
109,14,254,2,0,
109,14,255,2,0,
109,14,256,2,0,
109,14,259,4,0,
109,14,261,4,0,
109,14,262,1,45,
109,14,263,4,0,
109,14,269,4,0,
109,14,288,2,0,
109,14,360,1,29,
109,14,360,4,0,
109,14,371,4,0,
109,14,372,1,12,
109,14,399,3,0,
109,14,474,4,0,
109,14,496,4,0,
109,14,499,1,15,
109,14,510,4,0,
109,15,33,1,1,2
109,15,53,4,0,
109,15,60,2,0,
109,15,85,4,0,
109,15,87,4,0,
109,15,92,4,0,
109,15,103,2,0,
109,15,104,4,0,
109,15,108,1,7,
109,15,114,1,26,
109,15,120,1,23,
109,15,123,1,4,
109,15,124,1,18,
109,15,126,4,0,
109,15,139,1,1,1
109,15,148,4,0,
109,15,149,2,0,
109,15,153,1,37,
109,15,153,4,0,
109,15,156,4,0,
109,15,164,4,0,
109,15,168,4,0,
109,15,174,2,0,
109,15,180,2,0,
109,15,182,4,0,
109,15,188,1,34,
109,15,188,4,0,
109,15,194,1,40,
109,15,194,2,0,
109,15,207,4,0,
109,15,213,4,0,
109,15,214,4,0,
109,15,216,4,0,
109,15,218,4,0,
109,15,220,2,0,
109,15,237,4,0,
109,15,240,4,0,
109,15,241,4,0,
109,15,247,4,0,
109,15,254,2,0,
109,15,255,2,0,
109,15,256,2,0,
109,15,259,4,0,
109,15,261,4,0,
109,15,262,1,45,
109,15,263,4,0,
109,15,269,4,0,
109,15,288,2,0,
109,15,360,1,29,
109,15,360,4,0,
109,15,371,4,0,
109,15,372,1,12,
109,15,390,2,0,
109,15,399,4,0,
109,15,474,4,0,
109,15,496,4,0,
109,15,499,1,15,
109,15,510,4,0,
109,15,562,1,42,
109,15,590,4,0,
109,15,611,4,0,
109,16,33,1,1,2
109,16,53,4,0,
109,16,60,2,0,
109,16,85,4,0,
109,16,87,4,0,
109,16,92,4,0,
109,16,103,2,0,
109,16,104,4,0,
109,16,108,1,7,1
109,16,114,1,26,1
109,16,120,1,23,1
109,16,123,1,4,1
109,16,124,1,18,1
109,16,126,4,0,
109,16,139,1,1,1
109,16,148,4,0,
109,16,149,2,0,
109,16,153,1,37,1
109,16,153,4,0,
109,16,156,4,0,
109,16,164,4,0,
109,16,168,4,0,
109,16,173,3,0,
109,16,174,2,0,
109,16,180,2,0,
109,16,180,3,0,
109,16,182,4,0,
109,16,188,1,34,1
109,16,188,4,0,
109,16,194,1,40,1
109,16,194,2,0,
109,16,207,4,0,
109,16,213,4,0,
109,16,214,4,0,
109,16,216,4,0,
109,16,218,4,0,
109,16,220,2,0,
109,16,220,3,0,
109,16,237,4,0,
109,16,240,4,0,
109,16,241,4,0,
109,16,247,4,0,
109,16,253,3,0,
109,16,254,2,0,
109,16,255,2,0,
109,16,256,2,0,
109,16,259,4,0,
109,16,261,4,0,
109,16,262,1,45,1
109,16,263,4,0,
109,16,269,4,0,
109,16,288,2,0,
109,16,290,4,0,
109,16,351,3,0,
109,16,360,1,29,1
109,16,360,4,0,
109,16,371,4,0,
109,16,372,1,12,1
109,16,390,2,0,
109,16,399,4,0,
109,16,474,4,0,
109,16,496,4,0,
109,16,499,1,15,1
109,16,510,4,0,
109,16,562,1,42,1
109,16,590,4,0,
109,16,611,4,0,
109,17,33,1,1,2
109,17,53,4,0,
109,17,60,2,0,
109,17,85,4,0,
109,17,87,4,0,
109,17,92,4,0,
109,17,103,2,0,
109,17,104,4,0,
109,17,108,1,7,
109,17,114,1,26,
109,17,120,1,23,
109,17,123,1,4,
109,17,124,1,18,
109,17,126,4,0,
109,17,139,1,1,1
109,17,149,2,0,
109,17,153,1,37,
109,17,153,4,0,
109,17,156,4,0,
109,17,164,4,0,
109,17,168,4,0,
109,17,174,2,0,
109,17,180,2,0,
109,17,182,4,0,
109,17,188,1,34,
109,17,188,4,0,
109,17,194,1,40,
109,17,194,2,0,
109,17,207,4,0,
109,17,213,4,0,
109,17,214,4,0,
109,17,216,4,0,
109,17,218,4,0,
109,17,220,2,0,
109,17,237,4,0,
109,17,240,4,0,
109,17,241,4,0,
109,17,247,4,0,
109,17,254,2,0,
109,17,255,2,0,
109,17,256,2,0,
109,17,259,4,0,
109,17,261,4,0,
109,17,262,1,45,
109,17,263,4,0,
109,17,269,4,0,
109,17,288,2,0,
109,17,360,1,29,
109,17,360,4,0,
109,17,371,4,0,
109,17,372,1,12,
109,17,390,2,0,
109,17,399,4,0,
109,17,474,4,0,
109,17,496,4,0,
109,17,499,1,15,
109,17,562,1,42,
109,17,590,4,0,
109,17,611,4,0,
109,18,33,1,1,2
109,18,53,4,0,
109,18,60,2,0,
109,18,85,4,0,
109,18,87,4,0,
109,18,92,4,0,
109,18,103,2,0,
109,18,104,4,0,
109,18,108,1,7,
109,18,114,1,26,
109,18,120,1,23,
109,18,123,1,4,
109,18,124,1,18,
109,18,126,4,0,
109,18,139,1,1,1
109,18,149,2,0,
109,18,153,1,37,
109,18,153,4,0,
109,18,156,4,0,
109,18,164,4,0,
109,18,168,4,0,
109,18,174,2,0,
109,18,180,2,0,
109,18,182,4,0,
109,18,188,1,34,
109,18,188,4,0,
109,18,194,1,40,
109,18,194,2,0,
109,18,207,4,0,
109,18,213,4,0,
109,18,214,4,0,
109,18,216,4,0,
109,18,218,4,0,
109,18,220,2,0,
109,18,237,4,0,
109,18,240,4,0,
109,18,241,4,0,
109,18,247,4,0,
109,18,254,2,0,
109,18,255,2,0,
109,18,256,2,0,
109,18,259,4,0,
109,18,261,4,0,
109,18,262,1,45,
109,18,263,4,0,
109,18,269,4,0,
109,18,288,2,0,
109,18,360,1,29,
109,18,360,4,0,
109,18,371,4,0,
109,18,372,1,12,
109,18,390,2,0,
109,18,399,4,0,
109,18,474,4,0,
109,18,496,4,0,
109,18,499,1,15,
109,18,562,1,42,
109,18,590,4,0,
109,18,599,2,0,
109,18,611,4,0,
110,1,33,1,1,1
110,1,63,4,0,
110,1,85,4,0,
110,1,87,4,0,
110,1,92,4,0,
110,1,99,4,0,
110,1,102,4,0,
110,1,104,4,0,
110,1,108,1,39,
110,1,114,1,49,
110,1,117,4,0,
110,1,120,1,43,
110,1,120,4,0,
110,1,123,1,1,2
110,1,124,1,1,3
110,1,124,1,32,
110,1,126,4,0,
110,1,153,1,53,
110,1,153,4,0,
110,1,156,4,0,
110,1,164,4,0,
110,2,33,1,1,1
110,2,63,4,0,
110,2,85,4,0,
110,2,87,4,0,
110,2,92,4,0,
110,2,99,4,0,
110,2,102,4,0,
110,2,104,4,0,
110,2,108,1,39,
110,2,114,1,49,
110,2,117,4,0,
110,2,120,1,43,
110,2,120,4,0,
110,2,123,1,1,2
110,2,124,1,1,3
110,2,124,1,32,
110,2,126,4,0,
110,2,153,1,53,
110,2,153,4,0,
110,2,156,4,0,
110,2,164,4,0,
110,3,33,1,1,2
110,3,63,4,0,
110,3,87,4,0,
110,3,92,4,0,
110,3,104,4,0,
110,3,108,1,25,
110,3,114,1,33,
110,3,120,1,1,4
110,3,120,1,17,
110,3,123,1,1,3
110,3,123,1,9,
110,3,124,1,21,
110,3,126,4,0,
110,3,139,1,1,1
110,3,153,1,44,
110,3,156,4,0,
110,3,168,4,0,
110,3,173,4,0,
110,3,174,4,0,
110,3,182,4,0,
110,3,188,4,0,
110,3,192,4,0,
110,3,194,1,51,
110,3,203,4,0,
110,3,205,4,0,
110,3,207,4,0,
110,3,213,4,0,
110,3,214,4,0,
110,3,216,4,0,
110,3,218,4,0,
110,3,237,4,0,
110,3,241,4,0,
110,4,33,1,1,2
110,4,53,3,0,
110,4,63,4,0,
110,4,85,3,0,
110,4,87,4,0,
110,4,92,4,0,
110,4,104,4,0,
110,4,108,1,25,
110,4,114,1,33,
110,4,120,1,1,4
110,4,120,1,17,
110,4,123,1,1,3
110,4,123,1,9,
110,4,124,1,21,
110,4,126,4,0,
110,4,139,1,1,1
110,4,153,1,44,
110,4,156,4,0,
110,4,168,4,0,
110,4,173,4,0,
110,4,174,4,0,
110,4,182,4,0,
110,4,188,4,0,
110,4,192,4,0,
110,4,194,1,51,
110,4,203,4,0,
110,4,205,4,0,
110,4,207,4,0,
110,4,213,4,0,
110,4,214,4,0,
110,4,216,4,0,
110,4,218,4,0,
110,4,237,4,0,
110,4,241,4,0,
110,5,33,1,1,2
110,5,53,4,0,
110,5,63,4,0,
110,5,85,4,0,
110,5,87,4,0,
110,5,92,4,0,
110,5,104,4,0,
110,5,108,1,25,
110,5,114,1,33,
110,5,120,1,1,4
110,5,120,1,17,
110,5,123,1,1,3
110,5,123,1,9,
110,5,124,1,21,
110,5,126,4,0,
110,5,139,1,1,1
110,5,148,4,0,
110,5,153,1,44,
110,5,156,4,0,
110,5,168,4,0,
110,5,182,4,0,
110,5,188,4,0,
110,5,194,1,51,
110,5,213,4,0,
110,5,216,4,0,
110,5,218,4,0,
110,5,237,4,0,
110,5,240,4,0,
110,5,241,4,0,
110,5,247,4,0,
110,5,259,4,0,
110,5,262,1,58,
110,5,263,4,0,
110,5,269,4,0,
110,5,290,4,0,
110,5,351,4,0,
110,6,33,1,1,2
110,6,53,4,0,
110,6,63,4,0,
110,6,85,4,0,
110,6,87,4,0,
110,6,92,4,0,
110,6,102,3,0,
110,6,104,4,0,
110,6,108,1,25,
110,6,114,1,33,
110,6,120,1,1,4
110,6,120,1,17,
110,6,123,1,1,3
110,6,123,1,9,
110,6,124,1,21,
110,6,126,4,0,
110,6,139,1,1,1
110,6,148,4,0,
110,6,153,1,44,
110,6,153,3,0,
110,6,156,4,0,
110,6,164,3,0,
110,6,168,4,0,
110,6,173,3,0,
110,6,182,4,0,
110,6,188,4,0,
110,6,194,1,51,
110,6,203,3,0,
110,6,205,3,0,
110,6,207,3,0,
110,6,213,4,0,
110,6,214,3,0,
110,6,216,4,0,
110,6,218,4,0,
110,6,237,4,0,
110,6,240,4,0,
110,6,241,4,0,
110,6,247,4,0,
110,6,259,4,0,
110,6,262,1,58,
110,6,263,4,0,
110,6,269,4,0,
110,6,290,4,0,
110,6,351,4,0,
110,7,33,1,1,2
110,7,53,4,0,
110,7,63,4,0,
110,7,85,4,0,
110,7,87,4,0,
110,7,92,4,0,
110,7,102,3,0,
110,7,104,4,0,
110,7,108,1,25,
110,7,114,1,33,
110,7,120,1,1,4
110,7,120,1,17,
110,7,123,1,1,3
110,7,123,1,9,
110,7,124,1,21,
110,7,126,4,0,
110,7,139,1,1,1
110,7,148,4,0,
110,7,153,1,44,
110,7,153,3,0,
110,7,156,4,0,
110,7,164,3,0,
110,7,168,4,0,
110,7,182,4,0,
110,7,188,4,0,
110,7,194,1,51,
110,7,213,4,0,
110,7,216,4,0,
110,7,218,4,0,
110,7,237,4,0,
110,7,240,4,0,
110,7,241,4,0,
110,7,247,4,0,
110,7,259,4,0,
110,7,262,1,58,
110,7,263,4,0,
110,7,269,4,0,
110,7,290,4,0,
110,7,351,4,0,
110,8,33,1,1,2
110,8,53,4,0,
110,8,63,4,0,
110,8,85,4,0,
110,8,87,4,0,
110,8,92,4,0,
110,8,104,4,0,
110,8,108,1,1,4
110,8,108,1,10,
110,8,114,1,28,
110,8,120,1,19,
110,8,123,1,1,3
110,8,123,1,6,
110,8,124,1,24,
110,8,126,4,0,
110,8,139,1,1,1
110,8,148,4,0,
110,8,153,1,40,
110,8,153,4,0,
110,8,156,4,0,
110,8,164,4,0,
110,8,168,4,0,
110,8,182,4,0,
110,8,188,1,48,
110,8,188,4,0,
110,8,194,1,55,
110,8,203,4,0,
110,8,207,4,0,
110,8,213,4,0,
110,8,214,4,0,
110,8,216,4,0,
110,8,218,4,0,
110,8,237,4,0,
110,8,240,4,0,
110,8,241,4,0,
110,8,247,4,0,
110,8,259,4,0,
110,8,261,4,0,
110,8,262,1,63,
110,8,263,4,0,
110,8,269,4,0,
110,8,290,4,0,
110,8,351,4,0,
110,8,360,4,0,
110,8,363,4,0,
110,8,371,4,0,
110,8,372,1,15,
110,8,399,4,0,
110,8,416,4,0,
110,8,445,4,0,
110,8,458,1,33,
110,9,33,1,1,2
110,9,53,4,0,
110,9,63,4,0,
110,9,85,4,0,
110,9,87,4,0,
110,9,92,4,0,
110,9,104,4,0,
110,9,108,1,1,4
110,9,108,1,10,
110,9,114,1,28,
110,9,120,1,19,
110,9,123,1,1,3
110,9,123,1,6,
110,9,124,1,24,
110,9,126,4,0,
110,9,139,1,1,1
110,9,148,4,0,
110,9,153,1,40,
110,9,153,4,0,
110,9,156,4,0,
110,9,164,4,0,
110,9,168,4,0,
110,9,173,3,0,
110,9,180,3,0,
110,9,182,4,0,
110,9,188,1,48,
110,9,188,4,0,
110,9,194,1,55,
110,9,203,4,0,
110,9,205,3,0,
110,9,207,4,0,
110,9,213,4,0,
110,9,214,4,0,
110,9,216,4,0,
110,9,218,4,0,
110,9,237,4,0,
110,9,240,4,0,
110,9,241,4,0,
110,9,247,4,0,
110,9,253,3,0,
110,9,259,4,0,
110,9,261,4,0,
110,9,262,1,63,
110,9,263,4,0,
110,9,269,4,0,
110,9,290,4,0,
110,9,351,4,0,
110,9,360,4,0,
110,9,363,4,0,
110,9,371,4,0,
110,9,372,1,15,
110,9,399,4,0,
110,9,416,4,0,
110,9,445,4,0,
110,9,458,1,33,
110,10,33,1,1,2
110,10,53,4,0,
110,10,63,4,0,
110,10,85,4,0,
110,10,87,4,0,
110,10,92,4,0,
110,10,104,4,0,
110,10,108,1,1,4
110,10,108,1,10,
110,10,114,1,28,
110,10,120,1,19,
110,10,123,1,1,3
110,10,123,1,6,
110,10,124,1,24,
110,10,126,4,0,
110,10,139,1,1,1
110,10,148,4,0,
110,10,153,1,40,
110,10,153,4,0,
110,10,156,4,0,
110,10,164,4,0,
110,10,168,4,0,
110,10,173,3,0,
110,10,180,3,0,
110,10,182,4,0,
110,10,188,1,48,
110,10,188,4,0,
110,10,194,1,55,
110,10,203,4,0,
110,10,205,3,0,
110,10,207,4,0,
110,10,213,4,0,
110,10,214,4,0,
110,10,216,4,0,
110,10,218,4,0,
110,10,220,3,0,
110,10,237,4,0,
110,10,240,4,0,
110,10,241,4,0,
110,10,247,4,0,
110,10,253,3,0,
110,10,259,4,0,
110,10,261,4,0,
110,10,262,1,63,
110,10,263,4,0,
110,10,269,4,0,
110,10,290,4,0,
110,10,351,4,0,
110,10,360,4,0,
110,10,363,4,0,
110,10,371,4,0,
110,10,372,1,15,
110,10,399,4,0,
110,10,416,4,0,
110,10,445,4,0,
110,10,458,1,33,
110,11,33,1,1,2
110,11,53,4,0,
110,11,63,4,0,
110,11,85,4,0,
110,11,87,4,0,
110,11,92,4,0,
110,11,104,4,0,
110,11,108,1,1,4
110,11,108,1,10,
110,11,114,1,33,
110,11,120,1,24,
110,11,123,1,1,3
110,11,123,1,6,
110,11,124,1,28,
110,11,126,4,0,
110,11,139,1,1,1
110,11,148,4,0,
110,11,153,1,46,
110,11,153,4,0,
110,11,156,4,0,
110,11,164,4,0,
110,11,168,4,0,
110,11,182,4,0,
110,11,188,1,52,
110,11,188,4,0,
110,11,194,1,59,
110,11,207,4,0,
110,11,213,4,0,
110,11,216,4,0,
110,11,218,4,0,
110,11,237,4,0,
110,11,240,4,0,
110,11,241,4,0,
110,11,247,4,0,
110,11,259,4,0,
110,11,261,4,0,
110,11,262,1,65,
110,11,263,4,0,
110,11,269,4,0,
110,11,360,4,0,
110,11,371,4,0,
110,11,372,1,15,
110,11,416,4,0,
110,11,458,1,39,
110,11,474,4,0,
110,11,496,4,0,
110,11,499,1,19,
110,11,510,4,0,
110,12,33,1,1,2
110,12,53,4,0,
110,12,63,4,0,
110,12,85,4,0,
110,12,87,4,0,
110,12,92,4,0,
110,12,104,4,0,
110,12,108,1,25,
110,12,114,1,33,
110,12,120,1,1,4
110,12,120,1,17,
110,12,123,1,1,3
110,12,123,1,9,
110,12,124,1,21,
110,12,126,4,0,
110,12,139,1,1,1
110,12,148,4,0,
110,12,153,1,44,
110,12,156,4,0,
110,12,168,4,0,
110,12,182,4,0,
110,12,188,4,0,
110,12,194,1,51,
110,12,213,4,0,
110,12,216,4,0,
110,12,218,4,0,
110,12,237,4,0,
110,12,240,4,0,
110,12,241,4,0,
110,12,247,4,0,
110,12,259,4,0,
110,12,262,1,58,
110,12,263,4,0,
110,12,269,4,0,
110,12,290,4,0,
110,12,351,4,0,
110,13,33,1,1,2
110,13,53,4,0,
110,13,63,4,0,
110,13,85,4,0,
110,13,87,4,0,
110,13,92,4,0,
110,13,102,3,0,
110,13,104,4,0,
110,13,108,1,25,
110,13,114,1,33,
110,13,120,1,1,4
110,13,120,1,17,
110,13,120,3,0,
110,13,123,1,1,3
110,13,123,1,9,
110,13,124,1,21,
110,13,126,4,0,
110,13,139,1,1,1
110,13,148,4,0,
110,13,153,1,44,
110,13,156,4,0,
110,13,164,3,0,
110,13,168,4,0,
110,13,182,4,0,
110,13,188,4,0,
110,13,194,1,51,
110,13,207,3,0,
110,13,213,4,0,
110,13,216,4,0,
110,13,218,4,0,
110,13,237,4,0,
110,13,240,4,0,
110,13,241,4,0,
110,13,247,4,0,
110,13,259,4,0,
110,13,262,1,58,
110,13,263,4,0,
110,13,269,4,0,
110,13,290,4,0,
110,13,351,4,0,
110,14,33,1,1,2
110,14,53,4,0,
110,14,63,4,0,
110,14,85,4,0,
110,14,87,4,0,
110,14,92,4,0,
110,14,104,4,0,
110,14,108,1,1,4
110,14,108,1,7,
110,14,114,1,26,
110,14,120,1,23,
110,14,123,1,1,3
110,14,123,1,4,
110,14,124,1,18,
110,14,126,4,0,
110,14,139,1,1,1
110,14,148,4,0,
110,14,153,1,40,
110,14,153,4,0,
110,14,156,4,0,
110,14,164,4,0,
110,14,168,4,0,
110,14,173,3,0,
110,14,180,3,0,
110,14,182,4,0,
110,14,188,1,34,
110,14,188,4,0,
110,14,194,1,46,
110,14,207,4,0,
110,14,213,4,0,
110,14,214,3,0,
110,14,216,4,0,
110,14,218,4,0,
110,14,220,3,0,
110,14,237,4,0,
110,14,240,4,0,
110,14,241,4,0,
110,14,247,4,0,
110,14,253,3,0,
110,14,259,4,0,
110,14,261,4,0,
110,14,262,1,54,
110,14,263,4,0,
110,14,269,4,0,
110,14,360,4,0,
110,14,371,4,0,
110,14,372,1,12,
110,14,399,3,0,
110,14,416,4,0,
110,14,458,1,29,
110,14,474,4,0,
110,14,496,4,0,
110,14,499,1,15,
110,14,510,4,0,
110,15,33,1,1,2
110,15,53,4,0,
110,15,63,4,0,
110,15,85,4,0,
110,15,87,4,0,
110,15,92,4,0,
110,15,104,4,0,
110,15,108,1,1,4
110,15,108,1,7,
110,15,114,1,26,
110,15,120,1,23,
110,15,123,1,1,3
110,15,123,1,4,
110,15,124,1,18,
110,15,126,4,0,
110,15,139,1,1,1
110,15,148,4,0,
110,15,153,1,40,
110,15,153,4,0,
110,15,156,4,0,
110,15,164,4,0,
110,15,168,4,0,
110,15,182,4,0,
110,15,188,1,34,
110,15,188,4,0,
110,15,194,1,46,
110,15,207,4,0,
110,15,213,4,0,
110,15,214,4,0,
110,15,216,4,0,
110,15,218,4,0,
110,15,237,4,0,
110,15,240,4,0,
110,15,241,4,0,
110,15,247,4,0,
110,15,259,4,0,
110,15,261,4,0,
110,15,262,1,54,
110,15,263,4,0,
110,15,269,4,0,
110,15,360,4,0,
110,15,371,4,0,
110,15,372,1,12,
110,15,399,4,0,
110,15,416,4,0,
110,15,458,1,29,
110,15,474,4,0,
110,15,496,4,0,
110,15,499,1,15,
110,15,510,4,0,
110,15,562,1,50,
110,15,590,4,0,
110,15,611,4,0,
110,16,33,1,1,2
110,16,53,4,0,
110,16,63,4,0,
110,16,85,4,0,
110,16,87,4,0,
110,16,92,4,0,
110,16,104,4,0,
110,16,108,1,1,4
110,16,108,1,7,1
110,16,114,1,26,1
110,16,120,1,23,1
110,16,123,1,1,3
110,16,123,1,4,1
110,16,124,1,18,1
110,16,126,4,0,
110,16,139,1,1,1
110,16,148,4,0,
110,16,153,1,40,1
110,16,153,4,0,
110,16,156,4,0,
110,16,164,4,0,
110,16,168,4,0,
110,16,173,3,0,
110,16,180,3,0,
110,16,182,4,0,
110,16,188,1,34,1
110,16,188,4,0,
110,16,194,1,46,1
110,16,207,4,0,
110,16,213,4,0,
110,16,214,4,0,
110,16,216,4,0,
110,16,218,4,0,
110,16,220,3,0,
110,16,237,4,0,
110,16,240,4,0,
110,16,241,4,0,
110,16,247,4,0,
110,16,253,3,0,
110,16,259,4,0,
110,16,261,4,0,
110,16,262,1,57,1
110,16,263,4,0,
110,16,269,4,0,
110,16,290,4,0,
110,16,351,3,0,
110,16,360,4,0,
110,16,371,4,0,
110,16,372,1,12,1
110,16,399,4,0,
110,16,416,4,0,
110,16,458,1,29,1
110,16,474,4,0,
110,16,496,4,0,
110,16,499,1,15,1
110,16,510,4,0,
110,16,562,1,51,1
110,16,590,4,0,
110,16,611,4,0,
110,17,33,1,1,3
110,17,53,4,0,
110,17,63,4,0,
110,17,85,4,0,
110,17,87,4,0,
110,17,92,4,0,
110,17,104,4,0,
110,17,108,1,1,5
110,17,108,1,7,
110,17,114,1,26,
110,17,120,1,23,
110,17,123,1,1,4
110,17,123,1,4,
110,17,124,1,18,
110,17,126,4,0,
110,17,139,1,1,2
110,17,153,1,40,
110,17,153,4,0,
110,17,156,4,0,
110,17,164,4,0,
110,17,168,4,0,
110,17,182,4,0,
110,17,188,1,34,
110,17,188,4,0,
110,17,194,1,46,
110,17,207,4,0,
110,17,213,4,0,
110,17,214,4,0,
110,17,216,4,0,
110,17,218,4,0,
110,17,237,4,0,
110,17,240,4,0,
110,17,241,4,0,
110,17,247,4,0,
110,17,259,4,0,
110,17,261,4,0,
110,17,262,1,57,
110,17,263,4,0,
110,17,269,4,0,
110,17,360,1,29,
110,17,360,4,0,
110,17,371,4,0,
110,17,372,1,12,
110,17,399,4,0,
110,17,416,4,0,
110,17,458,1,0,
110,17,458,1,1,1
110,17,474,4,0,
110,17,496,4,0,
110,17,499,1,15,
110,17,562,1,51,
110,17,590,4,0,
110,17,611,4,0,
110,18,33,1,1,3
110,18,53,4,0,
110,18,63,4,0,
110,18,85,4,0,
110,18,87,4,0,
110,18,92,4,0,
110,18,104,4,0,
110,18,108,1,1,5
110,18,108,1,7,
110,18,114,1,26,
110,18,120,1,23,
110,18,123,1,1,4
110,18,123,1,4,
110,18,124,1,18,
110,18,126,4,0,
110,18,139,1,1,2
110,18,153,1,40,
110,18,153,4,0,
110,18,156,4,0,
110,18,164,4,0,
110,18,168,4,0,
110,18,182,4,0,
110,18,188,1,34,
110,18,188,4,0,
110,18,194,1,46,
110,18,207,4,0,
110,18,213,4,0,
110,18,214,4,0,
110,18,216,4,0,
110,18,218,4,0,
110,18,237,4,0,
110,18,240,4,0,
110,18,241,4,0,
110,18,247,4,0,
110,18,259,4,0,
110,18,261,4,0,
110,18,262,1,57,
110,18,263,4,0,
110,18,269,4,0,
110,18,360,1,29,
110,18,360,4,0,
110,18,371,4,0,
110,18,372,1,12,
110,18,399,4,0,
110,18,416,4,0,
110,18,458,1,0,
110,18,458,1,1,1
110,18,474,4,0,
110,18,496,4,0,
110,18,499,1,15,
110,18,562,1,51,
110,18,590,4,0,
110,18,611,4,0,
111,1,23,1,30,
111,1,30,1,1,
111,1,31,1,40,
111,1,32,1,45,
111,1,32,4,0,
111,1,34,4,0,
111,1,36,1,55,
111,1,36,4,0,
111,1,38,4,0,
111,1,39,1,35,
111,1,43,1,50,
111,1,70,4,0,
111,1,85,4,0,
111,1,87,4,0,
111,1,89,4,0,
111,1,90,4,0,
111,1,91,4,0,
111,1,92,4,0,
111,1,99,4,0,
111,1,102,4,0,
111,1,104,4,0,
111,1,117,4,0,
111,1,126,4,0,
111,1,130,4,0,
111,1,156,4,0,
111,1,157,4,0,
111,1,164,4,0,
111,2,23,1,1,2
111,2,23,1,30,
111,2,30,1,1,1
111,2,31,1,1,4
111,2,31,1,40,
111,2,32,1,45,
111,2,32,4,0,
111,2,34,4,0,
111,2,36,1,55,
111,2,36,4,0,
111,2,38,4,0,
111,2,39,1,1,3
111,2,39,1,35,
111,2,43,1,50,
111,2,70,4,0,
111,2,85,4,0,
111,2,87,4,0,
111,2,89,4,0,
111,2,90,4,0,
111,2,91,4,0,
111,2,92,4,0,
111,2,99,4,0,
111,2,102,4,0,
111,2,104,4,0,
111,2,117,4,0,
111,2,126,4,0,
111,2,130,4,0,
111,2,156,4,0,
111,2,157,4,0,
111,2,164,4,0,
111,3,23,1,13,
111,3,29,4,0,
111,3,30,1,1,1
111,3,31,1,19,
111,3,32,1,37,
111,3,36,1,49,
111,3,37,2,0,
111,3,39,1,1,2
111,3,46,4,0,
111,3,59,4,0,
111,3,68,2,0,
111,3,70,4,0,
111,3,87,4,0,
111,3,89,1,55,
111,3,89,4,0,
111,3,91,4,0,
111,3,92,4,0,
111,3,104,4,0,
111,3,126,4,0,
111,3,156,4,0,
111,3,157,2,0,
111,3,173,4,0,
111,3,174,4,0,
111,3,179,2,0,
111,3,182,4,0,
111,3,184,1,31,
111,3,189,4,0,
111,3,192,4,0,
111,3,196,4,0,
111,3,201,4,0,
111,3,203,4,0,
111,3,205,4,0,
111,3,207,4,0,
111,3,213,4,0,
111,3,214,4,0,
111,3,216,4,0,
111,3,218,4,0,
111,3,222,2,0,
111,3,228,2,0,
111,3,231,4,0,
111,3,237,4,0,
111,3,241,4,0,
111,3,242,2,0,
111,3,249,4,0,
111,4,23,1,13,
111,4,29,4,0,
111,4,30,1,1,1
111,4,31,1,19,
111,4,32,1,37,
111,4,36,1,49,
111,4,37,2,0,
111,4,39,1,1,2
111,4,46,4,0,
111,4,53,3,0,
111,4,58,3,0,
111,4,59,4,0,
111,4,68,2,0,
111,4,70,4,0,
111,4,85,3,0,
111,4,87,4,0,
111,4,89,1,55,
111,4,89,4,0,
111,4,91,4,0,
111,4,92,4,0,
111,4,104,4,0,
111,4,126,4,0,
111,4,156,4,0,
111,4,157,2,0,
111,4,173,4,0,
111,4,174,4,0,
111,4,179,2,0,
111,4,182,4,0,
111,4,184,1,31,
111,4,189,4,0,
111,4,192,4,0,
111,4,196,4,0,
111,4,201,4,0,
111,4,203,4,0,
111,4,205,4,0,
111,4,207,4,0,
111,4,213,4,0,
111,4,214,4,0,
111,4,216,4,0,
111,4,218,4,0,
111,4,222,2,0,
111,4,228,2,0,
111,4,231,4,0,
111,4,237,4,0,
111,4,241,4,0,
111,4,242,2,0,
111,4,249,4,0,
111,5,14,2,0,
111,5,23,1,10,
111,5,30,1,1,1
111,5,31,1,15,
111,5,32,1,38,
111,5,36,1,43,
111,5,39,1,1,2
111,5,46,4,0,
111,5,53,4,0,
111,5,58,4,0,
111,5,59,4,0,
111,5,68,2,0,
111,5,70,4,0,
111,5,85,4,0,
111,5,87,4,0,
111,5,89,1,52,
111,5,89,4,0,
111,5,91,4,0,
111,5,92,4,0,
111,5,104,4,0,
111,5,126,4,0,
111,5,156,4,0,
111,5,157,2,0,
111,5,168,4,0,
111,5,174,2,0,
111,5,179,2,0,
111,5,182,4,0,
111,5,184,1,24,
111,5,201,4,0,
111,5,213,4,0,
111,5,216,4,0,
111,5,218,4,0,
111,5,222,2,0,
111,5,224,1,57,
111,5,231,4,0,
111,5,237,4,0,
111,5,240,4,0,
111,5,241,4,0,
111,5,242,2,0,
111,5,249,4,0,
111,5,263,4,0,
111,5,290,4,0,
111,5,306,2,0,
111,5,317,4,0,
111,5,350,1,29,
111,5,351,4,0,
111,6,14,2,0,
111,6,14,3,0,
111,6,23,1,10,
111,6,30,1,1,1
111,6,31,1,15,
111,6,32,1,38,
111,6,34,3,0,
111,6,36,1,43,
111,6,38,3,0,
111,6,39,1,1,2
111,6,46,4,0,
111,6,53,4,0,
111,6,58,4,0,
111,6,59,4,0,
111,6,68,2,0,
111,6,68,3,0,
111,6,70,4,0,
111,6,85,4,0,
111,6,87,4,0,
111,6,89,1,52,
111,6,89,4,0,
111,6,91,4,0,
111,6,92,4,0,
111,6,102,3,0,
111,6,104,4,0,
111,6,126,4,0,
111,6,156,4,0,
111,6,157,2,0,
111,6,157,3,0,
111,6,164,3,0,
111,6,168,4,0,
111,6,173,3,0,
111,6,174,2,0,
111,6,179,2,0,
111,6,182,4,0,
111,6,184,1,24,
111,6,189,3,0,
111,6,196,3,0,
111,6,201,4,0,
111,6,203,3,0,
111,6,205,3,0,
111,6,207,3,0,
111,6,213,4,0,
111,6,214,3,0,
111,6,216,4,0,
111,6,218,4,0,
111,6,222,2,0,
111,6,224,1,57,
111,6,231,4,0,
111,6,237,4,0,
111,6,240,4,0,
111,6,241,4,0,
111,6,242,2,0,
111,6,249,4,0,
111,6,263,4,0,
111,6,290,4,0,
111,6,306,2,0,
111,6,317,4,0,
111,6,350,1,29,
111,6,351,4,0,
111,7,14,2,0,
111,7,14,3,0,
111,7,23,1,10,
111,7,30,1,1,1
111,7,31,1,15,
111,7,32,1,38,
111,7,34,3,0,
111,7,36,1,43,
111,7,38,3,0,
111,7,39,1,1,2
111,7,46,4,0,
111,7,53,4,0,
111,7,58,4,0,
111,7,59,4,0,
111,7,68,2,0,
111,7,68,3,0,
111,7,70,4,0,
111,7,85,4,0,
111,7,87,4,0,
111,7,89,1,52,
111,7,89,4,0,
111,7,91,4,0,
111,7,92,4,0,
111,7,102,3,0,
111,7,104,4,0,
111,7,126,4,0,
111,7,156,4,0,
111,7,157,2,0,
111,7,157,3,0,
111,7,164,3,0,
111,7,168,4,0,
111,7,174,2,0,
111,7,179,2,0,
111,7,182,4,0,
111,7,184,1,24,
111,7,201,4,0,
111,7,213,4,0,
111,7,216,4,0,
111,7,218,4,0,
111,7,222,2,0,
111,7,224,1,57,
111,7,231,4,0,
111,7,237,4,0,
111,7,240,4,0,
111,7,241,4,0,
111,7,242,2,0,
111,7,249,4,0,
111,7,263,4,0,
111,7,290,4,0,
111,7,306,2,0,
111,7,317,4,0,
111,7,350,1,29,
111,7,351,4,0,
111,8,14,2,0,
111,8,14,4,0,
111,8,23,1,9,
111,8,30,1,1,1
111,8,31,1,13,
111,8,32,1,37,
111,8,36,1,33,
111,8,39,1,1,2
111,8,46,4,0,
111,8,53,4,0,
111,8,58,4,0,
111,8,59,4,0,
111,8,68,2,0,
111,8,70,4,0,
111,8,85,4,0,
111,8,87,4,0,
111,8,89,1,49,
111,8,89,4,0,
111,8,91,4,0,
111,8,92,4,0,
111,8,104,4,0,
111,8,126,4,0,
111,8,156,4,0,
111,8,157,2,0,
111,8,157,4,0,
111,8,164,4,0,
111,8,168,4,0,
111,8,174,2,0,
111,8,179,2,0,
111,8,182,4,0,
111,8,184,1,21,
111,8,201,4,0,
111,8,203,4,0,
111,8,207,4,0,
111,8,213,4,0,
111,8,214,4,0,
111,8,216,4,0,
111,8,218,4,0,
111,8,222,2,0,
111,8,224,1,57,
111,8,231,4,0,
111,8,237,4,0,
111,8,240,4,0,
111,8,241,4,0,
111,8,242,2,0,
111,8,249,4,0,
111,8,263,4,0,
111,8,290,4,0,
111,8,306,2,0,
111,8,317,4,0,
111,8,350,1,25,
111,8,351,4,0,
111,8,363,4,0,
111,8,371,4,0,
111,8,397,4,0,
111,8,398,4,0,
111,8,406,4,0,
111,8,407,2,0,
111,8,422,2,0,
111,8,423,2,0,
111,8,424,2,0,
111,8,431,4,0,
111,8,444,1,45,
111,8,444,4,0,
111,8,445,4,0,
111,8,446,4,0,
111,9,14,2,0,
111,9,14,4,0,
111,9,23,1,9,
111,9,30,1,1,1
111,9,31,1,13,
111,9,32,1,37,
111,9,36,1,33,
111,9,39,1,1,2
111,9,46,4,0,
111,9,53,4,0,
111,9,58,4,0,
111,9,59,4,0,
111,9,68,2,0,
111,9,70,4,0,
111,9,85,4,0,
111,9,87,4,0,
111,9,89,1,49,
111,9,89,4,0,
111,9,91,4,0,
111,9,92,4,0,
111,9,104,4,0,
111,9,126,4,0,
111,9,156,4,0,
111,9,157,2,0,
111,9,157,4,0,
111,9,164,4,0,
111,9,168,4,0,
111,9,173,3,0,
111,9,174,2,0,
111,9,179,2,0,
111,9,180,3,0,
111,9,182,4,0,
111,9,184,1,21,
111,9,189,3,0,
111,9,196,3,0,
111,9,201,4,0,
111,9,203,4,0,
111,9,205,3,0,
111,9,207,4,0,
111,9,213,4,0,
111,9,214,4,0,
111,9,216,4,0,
111,9,218,4,0,
111,9,222,2,0,
111,9,224,1,57,
111,9,231,4,0,
111,9,237,4,0,
111,9,240,4,0,
111,9,241,4,0,
111,9,242,2,0,
111,9,246,3,0,
111,9,249,4,0,
111,9,253,3,0,
111,9,263,4,0,
111,9,276,3,0,
111,9,283,3,0,
111,9,290,4,0,
111,9,306,2,0,
111,9,317,4,0,
111,9,350,1,25,
111,9,351,4,0,
111,9,363,4,0,
111,9,371,4,0,
111,9,397,4,0,
111,9,398,4,0,
111,9,401,3,0,
111,9,406,4,0,
111,9,407,2,0,
111,9,414,3,0,
111,9,422,2,0,
111,9,423,2,0,
111,9,424,2,0,
111,9,431,4,0,
111,9,444,1,45,
111,9,444,4,0,
111,9,445,4,0,
111,9,446,4,0,
111,10,14,2,0,
111,10,14,4,0,
111,10,23,1,9,
111,10,29,3,0,
111,10,30,1,1,1
111,10,31,1,13,
111,10,32,1,37,
111,10,36,1,33,
111,10,39,1,1,2
111,10,46,4,0,
111,10,53,4,0,
111,10,58,4,0,
111,10,59,4,0,
111,10,68,2,0,
111,10,70,4,0,
111,10,85,4,0,
111,10,87,4,0,
111,10,89,1,49,
111,10,89,4,0,
111,10,91,4,0,
111,10,92,4,0,
111,10,104,4,0,
111,10,126,4,0,
111,10,130,2,0,
111,10,156,4,0,
111,10,157,2,0,
111,10,157,4,0,
111,10,164,4,0,
111,10,168,4,0,
111,10,173,3,0,
111,10,174,2,0,
111,10,179,2,0,
111,10,180,3,0,
111,10,182,4,0,
111,10,184,1,21,
111,10,189,3,0,
111,10,196,3,0,
111,10,201,4,0,
111,10,203,4,0,
111,10,205,3,0,
111,10,207,4,0,
111,10,213,4,0,
111,10,214,4,0,
111,10,216,4,0,
111,10,218,4,0,
111,10,222,2,0,
111,10,224,1,57,
111,10,231,4,0,
111,10,237,4,0,
111,10,240,4,0,
111,10,241,4,0,
111,10,242,2,0,
111,10,246,3,0,
111,10,249,4,0,
111,10,253,3,0,
111,10,263,4,0,
111,10,276,3,0,
111,10,283,3,0,
111,10,290,4,0,
111,10,306,2,0,
111,10,317,4,0,
111,10,350,1,25,
111,10,351,4,0,
111,10,363,4,0,
111,10,371,4,0,
111,10,397,4,0,
111,10,398,4,0,
111,10,401,3,0,
111,10,406,4,0,
111,10,407,2,0,
111,10,414,3,0,
111,10,422,2,0,
111,10,423,2,0,
111,10,424,2,0,
111,10,431,4,0,
111,10,444,1,45,
111,10,444,4,0,
111,10,445,4,0,
111,10,446,4,0,
111,11,14,4,0,
111,11,23,1,8,
111,11,30,1,1,1
111,11,31,1,12,
111,11,32,1,63,
111,11,36,1,41,
111,11,39,1,1,2
111,11,46,4,0,
111,11,53,4,0,
111,11,58,4,0,
111,11,59,4,0,
111,11,68,2,0,
111,11,70,4,0,
111,11,85,4,0,
111,11,87,4,0,
111,11,89,1,56,
111,11,89,4,0,
111,11,91,4,0,
111,11,92,4,0,
111,11,104,4,0,
111,11,126,4,0,
111,11,130,2,0,
111,11,156,4,0,
111,11,157,4,0,
111,11,164,4,0,
111,11,168,4,0,
111,11,174,2,0,
111,11,179,2,0,
111,11,182,4,0,
111,11,184,1,19,
111,11,201,4,0,
111,11,207,4,0,
111,11,213,4,0,
111,11,216,4,0,
111,11,218,4,0,
111,11,222,2,0,
111,11,224,1,67,
111,11,231,2,0,
111,11,237,4,0,
111,11,240,4,0,
111,11,241,4,0,
111,11,242,2,0,
111,11,249,4,0,
111,11,263,4,0,
111,11,306,2,0,
111,11,317,4,0,
111,11,350,1,23,
111,11,371,4,0,
111,11,397,4,0,
111,11,398,4,0,
111,11,407,2,0,
111,11,422,2,0,
111,11,423,2,0,
111,11,424,2,0,
111,11,431,2,0,
111,11,444,1,52,
111,11,444,4,0,
111,11,496,4,0,
111,11,498,1,34,
111,11,510,4,0,
111,11,523,1,30,
111,11,523,4,0,
111,11,529,1,45,
111,12,23,1,10,
111,12,30,1,1,1
111,12,31,1,15,
111,12,32,1,38,
111,12,36,1,43,
111,12,39,1,1,2
111,12,46,4,0,
111,12,53,4,0,
111,12,58,4,0,
111,12,59,4,0,
111,12,70,4,0,
111,12,85,4,0,
111,12,87,4,0,
111,12,89,1,52,
111,12,89,4,0,
111,12,91,4,0,
111,12,92,4,0,
111,12,104,4,0,
111,12,126,4,0,
111,12,156,4,0,
111,12,168,4,0,
111,12,182,4,0,
111,12,184,1,24,
111,12,201,4,0,
111,12,213,4,0,
111,12,216,4,0,
111,12,218,4,0,
111,12,224,1,57,
111,12,231,4,0,
111,12,237,4,0,
111,12,240,4,0,
111,12,241,4,0,
111,12,249,4,0,
111,12,263,4,0,
111,12,290,4,0,
111,12,317,4,0,
111,12,350,1,29,
111,12,351,4,0,
111,13,23,1,10,
111,13,30,1,1,1
111,13,31,1,15,
111,13,32,1,38,
111,13,34,3,0,
111,13,36,1,43,
111,13,38,3,0,
111,13,39,1,1,2
111,13,46,4,0,
111,13,53,4,0,
111,13,58,4,0,
111,13,59,4,0,
111,13,70,4,0,
111,13,85,4,0,
111,13,87,4,0,
111,13,89,1,52,
111,13,89,4,0,
111,13,91,4,0,
111,13,92,4,0,
111,13,102,3,0,
111,13,104,4,0,
111,13,126,4,0,
111,13,156,4,0,
111,13,164,3,0,
111,13,168,4,0,
111,13,182,4,0,
111,13,184,1,24,
111,13,196,3,0,
111,13,201,4,0,
111,13,207,3,0,
111,13,213,4,0,
111,13,216,4,0,
111,13,218,4,0,
111,13,224,1,57,
111,13,231,4,0,
111,13,237,4,0,
111,13,240,4,0,
111,13,241,4,0,
111,13,249,4,0,
111,13,263,4,0,
111,13,290,4,0,
111,13,317,4,0,
111,13,350,1,29,
111,13,351,4,0,
111,14,14,4,0,
111,14,23,1,8,
111,14,30,1,1,1
111,14,31,1,12,
111,14,32,1,63,
111,14,36,1,41,
111,14,39,1,1,2
111,14,46,4,0,
111,14,53,4,0,
111,14,58,4,0,
111,14,59,4,0,
111,14,68,2,0,
111,14,70,4,0,
111,14,85,4,0,
111,14,87,4,0,
111,14,89,1,56,
111,14,89,4,0,
111,14,91,4,0,
111,14,92,4,0,
111,14,104,4,0,
111,14,126,4,0,
111,14,130,2,0,
111,14,156,4,0,
111,14,157,4,0,
111,14,164,4,0,
111,14,168,4,0,
111,14,173,3,0,
111,14,174,2,0,
111,14,179,2,0,
111,14,180,3,0,
111,14,182,4,0,
111,14,184,1,19,
111,14,196,3,0,
111,14,201,4,0,
111,14,207,4,0,
111,14,213,4,0,
111,14,214,3,0,
111,14,216,4,0,
111,14,218,4,0,
111,14,222,2,0,
111,14,224,1,67,
111,14,231,2,0,
111,14,231,3,0,
111,14,237,4,0,
111,14,240,4,0,
111,14,241,4,0,
111,14,242,2,0,
111,14,249,4,0,
111,14,253,3,0,
111,14,263,4,0,
111,14,276,3,0,
111,14,283,3,0,
111,14,306,2,0,
111,14,317,4,0,
111,14,350,1,23,
111,14,371,4,0,
111,14,397,4,0,
111,14,398,4,0,
111,14,401,3,0,
111,14,406,3,0,
111,14,407,2,0,
111,14,414,3,0,
111,14,422,2,0,
111,14,423,2,0,
111,14,424,2,0,
111,14,431,2,0,
111,14,444,1,52,
111,14,444,4,0,
111,14,446,3,0,
111,14,496,4,0,
111,14,498,1,34,
111,14,510,4,0,
111,14,523,1,30,
111,14,523,4,0,
111,14,529,1,45,
111,14,529,3,0,
111,15,14,4,0,
111,15,23,1,8,
111,15,30,1,1,1
111,15,31,1,12,
111,15,32,1,63,
111,15,36,1,41,
111,15,39,1,1,2
111,15,46,4,0,
111,15,53,4,0,
111,15,58,4,0,
111,15,59,4,0,
111,15,68,2,0,
111,15,70,4,0,
111,15,85,4,0,
111,15,87,4,0,
111,15,89,1,56,
111,15,89,4,0,
111,15,91,4,0,
111,15,92,4,0,
111,15,104,4,0,
111,15,126,4,0,
111,15,130,2,0,
111,15,156,4,0,
111,15,157,4,0,
111,15,164,4,0,
111,15,168,4,0,
111,15,174,2,0,
111,15,179,2,0,
111,15,182,4,0,
111,15,184,1,19,
111,15,201,4,0,
111,15,207,4,0,
111,15,213,4,0,
111,15,214,4,0,
111,15,216,4,0,
111,15,218,4,0,
111,15,222,2,0,
111,15,224,1,67,
111,15,231,2,0,
111,15,237,4,0,
111,15,240,4,0,
111,15,241,4,0,
111,15,242,2,0,
111,15,249,4,0,
111,15,263,4,0,
111,15,306,2,0,
111,15,317,4,0,
111,15,350,1,23,
111,15,368,2,0,
111,15,371,4,0,
111,15,397,4,0,
111,15,398,4,0,
111,15,407,2,0,
111,15,422,2,0,
111,15,423,2,0,
111,15,424,2,0,
111,15,431,2,0,
111,15,444,1,52,
111,15,444,4,0,
111,15,470,2,0,
111,15,496,4,0,
111,15,498,1,34,
111,15,510,4,0,
111,15,523,1,30,
111,15,523,4,0,
111,15,529,1,45,
111,15,563,2,0,
111,15,590,4,0,
111,16,14,4,0,
111,16,23,1,17,1
111,16,30,1,1,1
111,16,31,1,5,1
111,16,32,1,53,1
111,16,36,1,37,1
111,16,39,1,1,2
111,16,46,4,0,
111,16,53,4,0,
111,16,58,4,0,
111,16,59,4,0,
111,16,68,2,0,
111,16,70,4,0,
111,16,85,4,0,
111,16,87,4,0,
111,16,89,1,45,1
111,16,89,4,0,
111,16,91,4,0,
111,16,92,4,0,
111,16,104,4,0,
111,16,126,4,0,
111,16,130,2,0,
111,16,156,4,0,
111,16,157,4,0,
111,16,164,4,0,
111,16,168,4,0,
111,16,173,3,0,
111,16,174,2,0,
111,16,179,2,0,
111,16,180,3,0,
111,16,182,4,0,
111,16,184,1,9,1
111,16,196,3,0,
111,16,201,4,0,
111,16,207,4,0,
111,16,213,4,0,
111,16,214,4,0,
111,16,216,4,0,
111,16,218,4,0,
111,16,222,2,0,
111,16,224,1,49,1
111,16,231,2,0,
111,16,231,3,0,
111,16,237,4,0,
111,16,240,4,0,
111,16,241,4,0,
111,16,242,2,0,
111,16,249,4,0,
111,16,253,3,0,
111,16,263,4,0,
111,16,276,3,0,
111,16,283,3,0,
111,16,290,4,0,
111,16,306,2,0,
111,16,317,4,0,
111,16,350,1,29,1
111,16,351,3,0,
111,16,368,2,0,
111,16,371,4,0,
111,16,397,4,0,
111,16,398,4,0,
111,16,401,3,0,
111,16,406,3,0,
111,16,407,2,0,
111,16,414,3,0,
111,16,422,2,0,
111,16,423,2,0,
111,16,424,2,0,
111,16,431,2,0,
111,16,444,1,41,1
111,16,444,4,0,
111,16,446,3,0,
111,16,470,2,0,
111,16,479,1,13,1
111,16,479,4,0,
111,16,496,4,0,
111,16,498,1,25,1
111,16,510,4,0,
111,16,523,1,21,1
111,16,523,4,0,
111,16,529,1,33,1
111,16,529,3,0,
111,16,563,2,0,
111,16,590,4,0,
111,17,14,4,0,
111,17,23,1,17,
111,17,30,1,1,1
111,17,31,1,5,
111,17,32,1,53,
111,17,36,1,37,
111,17,39,1,1,2
111,17,46,4,0,
111,17,53,4,0,
111,17,58,4,0,
111,17,59,4,0,
111,17,68,2,0,
111,17,85,4,0,
111,17,87,4,0,
111,17,89,1,45,
111,17,89,4,0,
111,17,92,4,0,
111,17,104,4,0,
111,17,126,4,0,
111,17,130,2,0,
111,17,156,4,0,
111,17,157,4,0,
111,17,164,4,0,
111,17,168,4,0,
111,17,174,2,0,
111,17,179,2,0,
111,17,182,4,0,
111,17,184,1,9,
111,17,201,4,0,
111,17,207,4,0,
111,17,213,4,0,
111,17,214,4,0,
111,17,216,4,0,
111,17,218,4,0,
111,17,222,2,0,
111,17,224,1,49,
111,17,231,2,0,
111,17,237,4,0,
111,17,240,4,0,
111,17,241,4,0,
111,17,242,2,0,
111,17,263,4,0,
111,17,306,2,0,
111,17,317,4,0,
111,17,350,1,29,
111,17,368,2,0,
111,17,371,4,0,
111,17,397,4,0,
111,17,398,4,0,
111,17,407,2,0,
111,17,422,2,0,
111,17,423,2,0,
111,17,424,2,0,
111,17,431,2,0,
111,17,444,1,41,
111,17,444,4,0,
111,17,470,2,0,
111,17,479,1,13,
111,17,479,4,0,
111,17,496,4,0,
111,17,498,1,25,
111,17,523,1,21,
111,17,523,4,0,
111,17,529,1,33,
111,17,563,2,0,
111,17,590,4,0,
111,17,684,4,0,
111,18,14,4,0,
111,18,23,1,17,
111,18,30,1,1,1
111,18,31,1,5,
111,18,32,1,53,
111,18,36,1,37,
111,18,39,1,1,2
111,18,46,4,0,
111,18,53,4,0,
111,18,58,4,0,
111,18,59,4,0,
111,18,68,2,0,
111,18,85,4,0,
111,18,87,4,0,
111,18,89,1,45,
111,18,89,4,0,
111,18,92,4,0,
111,18,104,4,0,
111,18,126,4,0,
111,18,130,2,0,
111,18,156,4,0,
111,18,157,4,0,
111,18,164,4,0,
111,18,168,4,0,
111,18,174,2,0,
111,18,179,2,0,
111,18,182,4,0,
111,18,184,1,9,
111,18,201,4,0,
111,18,207,4,0,
111,18,213,4,0,
111,18,214,4,0,
111,18,216,4,0,
111,18,218,4,0,
111,18,222,2,0,
111,18,224,1,49,
111,18,231,2,0,
111,18,237,4,0,
111,18,240,4,0,
111,18,241,4,0,
111,18,242,2,0,
111,18,263,4,0,
111,18,306,2,0,
111,18,317,4,0,
111,18,350,1,29,
111,18,368,2,0,
111,18,371,4,0,
111,18,397,4,0,
111,18,398,4,0,
111,18,407,2,0,
111,18,422,2,0,
111,18,423,2,0,
111,18,424,2,0,
111,18,431,2,0,
111,18,444,1,41,
111,18,444,4,0,
111,18,470,2,0,
111,18,479,1,13,
111,18,479,4,0,
111,18,496,4,0,
111,18,498,1,25,
111,18,523,1,21,
111,18,523,4,0,
111,18,529,1,33,
111,18,563,2,0,
111,18,590,4,0,
111,18,684,4,0,
112,1,5,4,0,
112,1,6,4,0,
112,1,23,1,1,2
112,1,23,1,30,
112,1,25,4,0,
112,1,30,1,1,1
112,1,31,1,1,4
112,1,31,1,40,
112,1,32,1,48,
112,1,32,4,0,
112,1,34,4,0,
112,1,36,1,64,
112,1,36,4,0,
112,1,38,4,0,
112,1,39,1,1,3
112,1,39,1,35,
112,1,43,1,55,
112,1,55,4,0,
112,1,57,4,0,
112,1,58,4,0,
112,1,59,4,0,
112,1,61,4,0,
112,1,63,4,0,
112,1,66,4,0,
112,1,68,4,0,
112,1,69,4,0,
112,1,70,4,0,
112,1,85,4,0,
112,1,87,4,0,
112,1,89,4,0,
112,1,90,4,0,
112,1,91,4,0,
112,1,92,4,0,
112,1,99,4,0,
112,1,102,4,0,
112,1,104,4,0,
112,1,117,4,0,
112,1,126,4,0,
112,1,130,4,0,
112,1,156,4,0,
112,1,157,4,0,
112,1,164,4,0,
112,2,5,4,0,
112,2,6,4,0,
112,2,23,1,30,
112,2,25,4,0,
112,2,30,1,1,
112,2,31,1,40,
112,2,32,1,48,
112,2,32,4,0,
112,2,34,4,0,
112,2,36,1,64,
112,2,36,4,0,
112,2,38,4,0,
112,2,39,1,35,
112,2,43,1,55,
112,2,55,4,0,
112,2,57,4,0,
112,2,58,4,0,
112,2,59,4,0,
112,2,61,4,0,
112,2,63,4,0,
112,2,66,4,0,
112,2,68,4,0,
112,2,69,4,0,
112,2,70,4,0,
112,2,85,4,0,
112,2,87,4,0,
112,2,89,4,0,
112,2,90,4,0,
112,2,91,4,0,
112,2,92,4,0,
112,2,99,4,0,
112,2,102,4,0,
112,2,104,4,0,
112,2,117,4,0,
112,2,126,4,0,
112,2,130,4,0,
112,2,156,4,0,
112,2,157,4,0,
112,2,164,4,0,
112,3,7,4,0,
112,3,9,4,0,
112,3,23,1,1,3
112,3,23,1,13,
112,3,29,4,0,
112,3,30,1,1,1
112,3,31,1,1,4
112,3,31,1,19,
112,3,32,1,37,
112,3,36,1,54,
112,3,39,1,1,2
112,3,46,4,0,
112,3,57,4,0,
112,3,59,4,0,
112,3,63,4,0,
112,3,70,4,0,
112,3,87,4,0,
112,3,89,1,65,
112,3,89,4,0,
112,3,91,4,0,
112,3,92,4,0,
112,3,104,4,0,
112,3,126,4,0,
112,3,156,4,0,
112,3,173,4,0,
112,3,174,4,0,
112,3,182,4,0,
112,3,184,1,31,
112,3,189,4,0,
112,3,192,4,0,
112,3,196,4,0,
112,3,201,4,0,
112,3,203,4,0,
112,3,205,4,0,
112,3,207,4,0,
112,3,210,4,0,
112,3,213,4,0,
112,3,214,4,0,
112,3,216,4,0,
112,3,218,4,0,
112,3,223,4,0,
112,3,231,4,0,
112,3,237,4,0,
112,3,241,4,0,
112,3,249,4,0,
112,4,7,4,0,
112,4,9,4,0,
112,4,23,1,1,3
112,4,23,1,13,
112,4,29,4,0,
112,4,30,1,1,1
112,4,31,1,1,4
112,4,31,1,19,
112,4,32,1,37,
112,4,36,1,54,
112,4,39,1,1,2
112,4,46,4,0,
112,4,53,3,0,
112,4,57,4,0,
112,4,58,3,0,
112,4,59,4,0,
112,4,63,4,0,
112,4,70,4,0,
112,4,85,3,0,
112,4,87,4,0,
112,4,89,1,65,
112,4,89,4,0,
112,4,91,4,0,
112,4,92,4,0,
112,4,104,4,0,
112,4,126,4,0,
112,4,156,4,0,
112,4,173,4,0,
112,4,174,4,0,
112,4,182,4,0,
112,4,184,1,31,
112,4,189,4,0,
112,4,192,4,0,
112,4,196,4,0,
112,4,201,4,0,
112,4,203,4,0,
112,4,205,4,0,
112,4,207,4,0,
112,4,210,4,0,
112,4,213,4,0,
112,4,214,4,0,
112,4,216,4,0,
112,4,218,4,0,
112,4,223,4,0,
112,4,231,4,0,
112,4,237,4,0,
112,4,241,4,0,
112,4,249,4,0,
112,5,15,4,0,
112,5,23,1,1,3
112,5,23,1,10,
112,5,30,1,1,1
112,5,31,1,1,4
112,5,31,1,15,
112,5,32,1,38,
112,5,36,1,46,
112,5,39,1,1,2
112,5,46,4,0,
112,5,53,4,0,
112,5,57,4,0,
112,5,58,4,0,
112,5,59,4,0,
112,5,63,4,0,
112,5,70,4,0,
112,5,85,4,0,
112,5,87,4,0,
112,5,89,1,58,
112,5,89,4,0,
112,5,91,4,0,
112,5,92,4,0,
112,5,104,4,0,
112,5,126,4,0,
112,5,156,4,0,
112,5,168,4,0,
112,5,182,4,0,
112,5,184,1,24,
112,5,201,4,0,
112,5,213,4,0,
112,5,216,4,0,
112,5,218,4,0,
112,5,224,1,66,
112,5,231,4,0,
112,5,237,4,0,
112,5,240,4,0,
112,5,241,4,0,
112,5,249,4,0,
112,5,263,4,0,
112,5,264,4,0,
112,5,280,4,0,
112,5,290,4,0,
112,5,317,4,0,
112,5,350,1,29,
112,5,351,4,0,
112,6,5,3,0,
112,6,7,3,0,
112,6,9,3,0,
112,6,14,3,0,
112,6,15,4,0,
112,6,23,1,1,3
112,6,23,1,10,
112,6,25,3,0,
112,6,30,1,1,1
112,6,31,1,1,4
112,6,31,1,15,
112,6,32,1,38,
112,6,34,3,0,
112,6,36,1,46,
112,6,38,3,0,
112,6,39,1,1,2
112,6,46,4,0,
112,6,53,4,0,
112,6,57,4,0,
112,6,58,4,0,
112,6,59,4,0,
112,6,63,4,0,
112,6,68,3,0,
112,6,69,3,0,
112,6,70,4,0,
112,6,85,4,0,
112,6,87,4,0,
112,6,89,1,58,
112,6,89,4,0,
112,6,91,4,0,
112,6,92,4,0,
112,6,102,3,0,
112,6,104,4,0,
112,6,126,4,0,
112,6,156,4,0,
112,6,157,3,0,
112,6,164,3,0,
112,6,168,4,0,
112,6,173,3,0,
112,6,182,4,0,
112,6,184,1,24,
112,6,189,3,0,
112,6,196,3,0,
112,6,201,4,0,
112,6,203,3,0,
112,6,205,3,0,
112,6,207,3,0,
112,6,210,3,0,
112,6,213,4,0,
112,6,214,3,0,
112,6,216,4,0,
112,6,218,4,0,
112,6,223,3,0,
112,6,224,1,66,
112,6,231,4,0,
112,6,237,4,0,
112,6,240,4,0,
112,6,241,4,0,
112,6,249,4,0,
112,6,263,4,0,
112,6,264,4,0,
112,6,280,4,0,
112,6,290,4,0,
112,6,317,4,0,
112,6,350,1,29,
112,6,351,4,0,
112,7,5,3,0,
112,7,14,3,0,
112,7,15,4,0,
112,7,23,1,1,3
112,7,23,1,10,
112,7,25,3,0,
112,7,30,1,1,1
112,7,31,1,1,4
112,7,31,1,15,
112,7,32,1,38,
112,7,34,3,0,
112,7,36,1,46,
112,7,38,3,0,
112,7,39,1,1,2
112,7,46,4,0,
112,7,53,4,0,
112,7,57,4,0,
112,7,58,4,0,
112,7,59,4,0,
112,7,63,4,0,
112,7,68,3,0,
112,7,69,3,0,
112,7,70,4,0,
112,7,85,4,0,
112,7,87,4,0,
112,7,89,1,58,
112,7,89,4,0,
112,7,91,4,0,
112,7,92,4,0,
112,7,102,3,0,
112,7,104,4,0,
112,7,126,4,0,
112,7,156,4,0,
112,7,157,3,0,
112,7,164,3,0,
112,7,168,4,0,
112,7,182,4,0,
112,7,184,1,24,
112,7,201,4,0,
112,7,213,4,0,
112,7,216,4,0,
112,7,218,4,0,
112,7,224,1,66,
112,7,231,4,0,
112,7,237,4,0,
112,7,240,4,0,
112,7,241,4,0,
112,7,249,4,0,
112,7,263,4,0,
112,7,264,4,0,
112,7,280,4,0,
112,7,290,4,0,
112,7,317,4,0,
112,7,350,1,29,
112,7,351,4,0,
112,8,14,4,0,
112,8,15,4,0,
112,8,23,1,1,3
112,8,23,1,9,
112,8,30,1,1,1
112,8,31,1,1,4
112,8,31,1,13,
112,8,32,1,37,
112,8,36,1,33,
112,8,39,1,1,2
112,8,46,4,0,
112,8,53,4,0,
112,8,57,4,0,
112,8,58,4,0,
112,8,59,4,0,
112,8,63,4,0,
112,8,70,4,0,
112,8,85,4,0,
112,8,87,4,0,
112,8,89,1,49,
112,8,89,4,0,
112,8,91,4,0,
112,8,92,4,0,
112,8,104,4,0,
112,8,126,4,0,
112,8,156,4,0,
112,8,157,4,0,
112,8,164,4,0,
112,8,168,4,0,
112,8,182,4,0,
112,8,184,1,21,
112,8,201,4,0,
112,8,203,4,0,
112,8,207,4,0,
112,8,213,4,0,
112,8,214,4,0,
112,8,216,4,0,
112,8,218,4,0,
112,8,224,1,57,
112,8,231,4,0,
112,8,237,4,0,
112,8,240,4,0,
112,8,241,4,0,
112,8,249,4,0,
112,8,263,4,0,
112,8,264,4,0,
112,8,280,4,0,
112,8,290,4,0,
112,8,317,4,0,
112,8,350,1,25,
112,8,351,4,0,
112,8,359,1,42,
112,8,363,4,0,
112,8,371,4,0,
112,8,374,4,0,
112,8,397,4,0,
112,8,398,4,0,
112,8,406,4,0,
112,8,411,4,0,
112,8,416,4,0,
112,8,419,4,0,
112,8,421,4,0,
112,8,431,4,0,
112,8,444,1,45,
112,8,444,4,0,
112,8,445,4,0,
112,8,446,4,0,
112,9,7,3,0,
112,9,8,3,0,
112,9,9,3,0,
112,9,14,4,0,
112,9,15,4,0,
112,9,23,1,1,3
112,9,23,1,9,
112,9,30,1,1,1
112,9,31,1,1,4
112,9,31,1,13,
112,9,32,1,37,
112,9,36,1,33,
112,9,39,1,1,2
112,9,46,4,0,
112,9,53,4,0,
112,9,57,4,0,
112,9,58,4,0,
112,9,59,4,0,
112,9,63,4,0,
112,9,70,4,0,
112,9,85,4,0,
112,9,87,4,0,
112,9,89,1,49,
112,9,89,4,0,
112,9,91,4,0,
112,9,92,4,0,
112,9,104,4,0,
112,9,126,4,0,
112,9,156,4,0,
112,9,157,4,0,
112,9,164,4,0,
112,9,168,4,0,
112,9,173,3,0,
112,9,180,3,0,
112,9,182,4,0,
112,9,184,1,21,
112,9,189,3,0,
112,9,196,3,0,
112,9,200,3,0,
112,9,201,4,0,
112,9,203,4,0,
112,9,205,3,0,
112,9,207,4,0,
112,9,210,3,0,
112,9,213,4,0,
112,9,214,4,0,
112,9,216,4,0,
112,9,218,4,0,
112,9,224,1,57,
112,9,231,4,0,
112,9,237,4,0,
112,9,240,4,0,
112,9,241,4,0,
112,9,246,3,0,
112,9,249,4,0,
112,9,253,3,0,
112,9,263,4,0,
112,9,264,4,0,
112,9,276,3,0,
112,9,280,4,0,
112,9,283,3,0,
112,9,290,4,0,
112,9,317,4,0,
112,9,350,1,25,
112,9,351,4,0,
112,9,359,1,42,
112,9,363,4,0,
112,9,371,4,0,
112,9,374,4,0,
112,9,397,4,0,
112,9,398,4,0,
112,9,401,3,0,
112,9,406,4,0,
112,9,411,4,0,
112,9,414,3,0,
112,9,416,4,0,
112,9,419,4,0,
112,9,421,4,0,
112,9,431,4,0,
112,9,444,1,45,
112,9,444,4,0,
112,9,445,4,0,
112,9,446,4,0,
112,10,7,3,0,
112,10,8,3,0,
112,10,9,3,0,
112,10,14,4,0,
112,10,15,4,0,
112,10,23,1,1,3
112,10,23,1,9,
112,10,29,3,0,
112,10,30,1,1,1
112,10,31,1,1,4
112,10,31,1,13,
112,10,32,1,37,
112,10,36,1,33,
112,10,39,1,1,2
112,10,46,4,0,
112,10,53,4,0,
112,10,57,4,0,
112,10,58,4,0,
112,10,59,4,0,
112,10,63,4,0,
112,10,70,4,0,
112,10,85,4,0,
112,10,87,4,0,
112,10,89,1,49,
112,10,89,4,0,
112,10,91,4,0,
112,10,92,4,0,
112,10,104,4,0,
112,10,126,4,0,
112,10,156,4,0,
112,10,157,4,0,
112,10,164,4,0,
112,10,168,4,0,
112,10,173,3,0,
112,10,180,3,0,
112,10,182,4,0,
112,10,184,1,21,
112,10,189,3,0,
112,10,196,3,0,
112,10,200,3,0,
112,10,201,4,0,
112,10,203,4,0,
112,10,205,3,0,
112,10,207,4,0,
112,10,210,3,0,
112,10,213,4,0,
112,10,214,4,0,
112,10,216,4,0,
112,10,218,4,0,
112,10,224,1,57,
112,10,231,4,0,
112,10,237,4,0,
112,10,240,4,0,
112,10,241,4,0,
112,10,246,3,0,
112,10,249,4,0,
112,10,250,4,0,
112,10,253,3,0,
112,10,263,4,0,
112,10,264,4,0,
112,10,276,3,0,
112,10,280,4,0,
112,10,283,3,0,
112,10,290,4,0,
112,10,317,4,0,
112,10,335,3,0,
112,10,350,1,25,
112,10,351,4,0,
112,10,359,1,42,
112,10,363,4,0,
112,10,371,4,0,
112,10,374,4,0,
112,10,397,4,0,
112,10,398,4,0,
112,10,401,3,0,
112,10,406,4,0,
112,10,411,4,0,
112,10,414,3,0,
112,10,416,4,0,
112,10,419,4,0,
112,10,421,4,0,
112,10,431,4,0,
112,10,444,1,45,
112,10,444,4,0,
112,10,445,4,0,
112,10,446,4,0,
112,11,14,4,0,
112,11,15,4,0,
112,11,23,1,1,3
112,11,23,1,9,
112,11,30,1,1,1
112,11,31,1,1,4
112,11,31,1,12,
112,11,32,1,71,
112,11,36,1,41,
112,11,39,1,1,2
112,11,46,4,0,
112,11,53,4,0,
112,11,57,4,0,
112,11,58,4,0,
112,11,59,4,0,
112,11,63,4,0,
112,11,70,4,0,
112,11,85,4,0,
112,11,87,4,0,
112,11,89,1,62,
112,11,89,4,0,
112,11,91,4,0,
112,11,92,4,0,
112,11,104,4,0,
112,11,126,4,0,
112,11,156,4,0,
112,11,157,4,0,
112,11,164,4,0,
112,11,168,4,0,
112,11,182,4,0,
112,11,184,1,19,
112,11,201,4,0,
112,11,207,4,0,
112,11,213,4,0,
112,11,216,4,0,
112,11,218,4,0,
112,11,224,1,77,
112,11,237,4,0,
112,11,240,4,0,
112,11,241,4,0,
112,11,249,4,0,
112,11,263,4,0,
112,11,280,4,0,
112,11,317,4,0,
112,11,350,1,23,
112,11,359,1,42,
112,11,371,4,0,
112,11,374,4,0,
112,11,397,4,0,
112,11,398,4,0,
112,11,411,4,0,
112,11,416,4,0,
112,11,421,4,0,
112,11,444,1,56,
112,11,444,4,0,
112,11,479,4,0,
112,11,496,4,0,
112,11,498,1,34,
112,11,510,4,0,
112,11,523,1,30,
112,11,523,4,0,
112,11,525,4,0,
112,11,529,1,47,
112,12,15,4,0,
112,12,23,1,1,3
112,12,23,1,10,
112,12,30,1,1,1
112,12,31,1,1,4
112,12,31,1,15,
112,12,32,1,38,
112,12,36,1,46,
112,12,39,1,1,2
112,12,46,4,0,
112,12,53,4,0,
112,12,57,4,0,
112,12,58,4,0,
112,12,59,4,0,
112,12,63,4,0,
112,12,70,4,0,
112,12,85,4,0,
112,12,87,4,0,
112,12,89,1,58,
112,12,89,4,0,
112,12,91,4,0,
112,12,92,4,0,
112,12,104,4,0,
112,12,126,4,0,
112,12,156,4,0,
112,12,168,4,0,
112,12,182,4,0,
112,12,184,1,24,
112,12,201,4,0,
112,12,213,4,0,
112,12,216,4,0,
112,12,218,4,0,
112,12,224,1,66,
112,12,231,4,0,
112,12,237,4,0,
112,12,240,4,0,
112,12,241,4,0,
112,12,249,4,0,
112,12,263,4,0,
112,12,264,4,0,
112,12,280,4,0,
112,12,290,4,0,
112,12,317,4,0,
112,12,350,1,29,
112,12,351,4,0,
112,13,15,4,0,
112,13,23,1,1,3
112,13,23,1,10,
112,13,30,1,1,1
112,13,31,1,1,4
112,13,31,1,15,
112,13,32,1,38,
112,13,34,3,0,
112,13,36,1,46,
112,13,38,3,0,
112,13,39,1,1,2
112,13,46,4,0,
112,13,53,4,0,
112,13,57,4,0,
112,13,58,4,0,
112,13,59,4,0,
112,13,63,4,0,
112,13,69,3,0,
112,13,70,4,0,
112,13,85,4,0,
112,13,87,4,0,
112,13,89,1,58,
112,13,89,4,0,
112,13,91,4,0,
112,13,92,4,0,
112,13,102,3,0,
112,13,104,4,0,
112,13,126,4,0,
112,13,156,4,0,
112,13,164,3,0,
112,13,168,4,0,
112,13,182,4,0,
112,13,184,1,24,
112,13,196,3,0,
112,13,201,4,0,
112,13,207,3,0,
112,13,213,4,0,
112,13,216,4,0,
112,13,218,4,0,
112,13,224,1,66,
112,13,231,4,0,
112,13,237,4,0,
112,13,240,4,0,
112,13,241,4,0,
112,13,249,4,0,
112,13,263,4,0,
112,13,264,4,0,
112,13,280,4,0,
112,13,290,4,0,
112,13,317,4,0,
112,13,350,1,29,
112,13,351,4,0,
112,14,7,3,0,
112,14,8,3,0,
112,14,9,3,0,
112,14,14,4,0,
112,14,15,4,0,
112,14,23,1,1,3
112,14,23,1,9,
112,14,30,1,1,1
112,14,31,1,1,4
112,14,31,1,12,
112,14,32,1,71,
112,14,36,1,41,
112,14,39,1,1,2
112,14,46,4,0,
112,14,53,4,0,
112,14,57,4,0,
112,14,58,4,0,
112,14,59,4,0,
112,14,63,4,0,
112,14,70,4,0,
112,14,85,4,0,
112,14,87,4,0,
112,14,89,1,62,
112,14,89,4,0,
112,14,91,4,0,
112,14,92,4,0,
112,14,104,4,0,
112,14,126,4,0,
112,14,156,4,0,
112,14,157,4,0,
112,14,164,4,0,
112,14,168,4,0,
112,14,173,3,0,
112,14,180,3,0,
112,14,182,4,0,
112,14,184,1,19,
112,14,196,3,0,
112,14,200,3,0,
112,14,201,4,0,
112,14,207,4,0,
112,14,213,4,0,
112,14,214,3,0,
112,14,216,4,0,
112,14,218,4,0,
112,14,224,1,77,
112,14,231,3,0,
112,14,237,4,0,
112,14,240,4,0,
112,14,241,4,0,
112,14,249,4,0,
112,14,253,3,0,
112,14,263,4,0,
112,14,276,3,0,
112,14,280,4,0,
112,14,283,3,0,
112,14,317,4,0,
112,14,335,3,0,
112,14,350,1,23,
112,14,359,1,42,
112,14,371,4,0,
112,14,374,4,0,
112,14,397,4,0,
112,14,398,4,0,
112,14,401,3,0,
112,14,406,3,0,
112,14,411,4,0,
112,14,414,3,0,
112,14,416,4,0,
112,14,421,4,0,
112,14,444,1,56,
112,14,444,4,0,
112,14,446,3,0,
112,14,479,4,0,
112,14,496,4,0,
112,14,498,1,34,
112,14,510,4,0,
112,14,523,1,30,
112,14,523,4,0,
112,14,525,4,0,
112,14,529,1,47,
112,14,529,3,0,
112,15,14,4,0,
112,15,15,4,0,
112,15,23,1,1,5
112,15,23,1,9,
112,15,30,1,1,3
112,15,31,1,1,6
112,15,31,1,12,
112,15,32,1,1,2
112,15,32,1,71,
112,15,36,1,41,
112,15,39,1,1,4
112,15,46,4,0,
112,15,53,4,0,
112,15,57,4,0,
112,15,58,4,0,
112,15,59,4,0,
112,15,63,4,0,
112,15,70,4,0,
112,15,85,4,0,
112,15,87,4,0,
112,15,89,1,62,
112,15,89,4,0,
112,15,91,4,0,
112,15,92,4,0,
112,15,104,4,0,
112,15,126,4,0,
112,15,156,4,0,
112,15,157,4,0,
112,15,164,4,0,
112,15,168,4,0,
112,15,182,4,0,
112,15,184,1,19,
112,15,201,4,0,
112,15,207,4,0,
112,15,213,4,0,
112,15,214,4,0,
112,15,216,4,0,
112,15,218,4,0,
112,15,224,1,1,1
112,15,224,1,77,
112,15,237,4,0,
112,15,240,4,0,
112,15,241,4,0,
112,15,249,4,0,
112,15,263,4,0,
112,15,280,4,0,
112,15,317,4,0,
112,15,350,1,23,
112,15,359,1,42,
112,15,371,4,0,
112,15,374,4,0,
112,15,397,4,0,
112,15,398,4,0,
112,15,411,4,0,
112,15,416,4,0,
112,15,421,4,0,
112,15,444,1,56,
112,15,444,4,0,
112,15,479,4,0,
112,15,496,4,0,
112,15,498,1,34,
112,15,510,4,0,
112,15,523,1,30,
112,15,523,4,0,
112,15,525,4,0,
112,15,529,1,47,
112,15,590,4,0,
112,15,612,4,0,
112,16,7,3,0,
112,16,8,3,0,
112,16,9,3,0,
112,16,14,4,0,
112,16,15,4,0,
112,16,23,1,17,1
112,16,30,1,1,2
112,16,31,1,1,4
112,16,31,1,5,1
112,16,32,1,1,1
112,16,32,1,62,1
112,16,36,1,37,1
112,16,39,1,1,3
112,16,46,4,0,
112,16,53,4,0,
112,16,57,4,0,
112,16,58,4,0,
112,16,59,4,0,
112,16,63,4,0,
112,16,70,4,0,
112,16,85,4,0,
112,16,87,4,0,
112,16,89,1,48,1
112,16,89,4,0,
112,16,91,4,0,
112,16,92,4,0,
112,16,104,4,0,
112,16,126,4,0,
112,16,156,4,0,
112,16,157,4,0,
112,16,164,4,0,
112,16,168,4,0,
112,16,173,3,0,
112,16,180,3,0,
112,16,182,4,0,
112,16,184,1,1,5
112,16,184,1,9,1
112,16,196,3,0,
112,16,200,3,0,
112,16,201,4,0,
112,16,207,4,0,
112,16,213,4,0,
112,16,214,4,0,
112,16,216,4,0,
112,16,218,4,0,
112,16,224,1,55,1
112,16,231,3,0,
112,16,237,4,0,
112,16,240,4,0,
112,16,241,4,0,
112,16,249,4,0,
112,16,253,3,0,
112,16,263,4,0,
112,16,264,3,0,
112,16,276,3,0,
112,16,280,4,0,
112,16,283,3,0,
112,16,290,4,0,
112,16,317,4,0,
112,16,335,3,0,
112,16,350,1,29,1
112,16,351,3,0,
112,16,359,1,42,1
112,16,371,4,0,
112,16,374,4,0,
112,16,397,4,0,
112,16,398,4,0,
112,16,401,3,0,
112,16,406,3,0,
112,16,411,4,0,
112,16,414,3,0,
112,16,416,4,0,
112,16,421,4,0,
112,16,444,1,41,1
112,16,444,4,0,
112,16,446,3,0,
112,16,479,1,13,1
112,16,479,4,0,
112,16,496,4,0,
112,16,498,1,25,1
112,16,510,4,0,
112,16,523,1,21,1
112,16,523,4,0,
112,16,525,4,0,
112,16,529,1,33,1
112,16,529,3,0,
112,16,590,4,0,
112,16,612,4,0,
112,17,14,4,0,
112,17,23,1,17,
112,17,30,1,1,3
112,17,31,1,1,5
112,17,31,1,5,
112,17,32,1,1,2
112,17,32,1,62,
112,17,36,1,37,
112,17,39,1,1,4
112,17,46,4,0,
112,17,53,4,0,
112,17,57,4,0,
112,17,58,4,0,
112,17,59,4,0,
112,17,63,4,0,
112,17,85,4,0,
112,17,87,4,0,
112,17,89,1,48,
112,17,89,4,0,
112,17,92,4,0,
112,17,104,4,0,
112,17,126,4,0,
112,17,156,4,0,
112,17,157,4,0,
112,17,164,4,0,
112,17,168,4,0,
112,17,182,4,0,
112,17,184,1,1,6
112,17,184,1,9,
112,17,201,4,0,
112,17,207,4,0,
112,17,213,4,0,
112,17,214,4,0,
112,17,216,4,0,
112,17,218,4,0,
112,17,224,1,55,
112,17,237,4,0,
112,17,240,4,0,
112,17,241,4,0,
112,17,263,4,0,
112,17,280,4,0,
112,17,317,4,0,
112,17,350,1,29,
112,17,359,1,0,
112,17,359,1,1,1
112,17,371,4,0,
112,17,374,4,0,
112,17,397,4,0,
112,17,398,4,0,
112,17,411,4,0,
112,17,416,4,0,
112,17,421,4,0,
112,17,444,1,41,
112,17,444,4,0,
112,17,479,1,13,
112,17,479,4,0,
112,17,496,4,0,
112,17,498,1,25,
112,17,523,1,21,
112,17,523,4,0,
112,17,525,4,0,
112,17,529,1,33,
112,17,590,4,0,
112,17,684,4,0,
112,17,693,4,0,
112,18,14,4,0,
112,18,23,1,17,
112,18,30,1,1,3
112,18,31,1,1,5
112,18,31,1,5,
112,18,32,1,1,2
112,18,32,1,62,
112,18,36,1,37,
112,18,39,1,1,4
112,18,46,4,0,
112,18,53,4,0,
112,18,57,4,0,
112,18,58,4,0,
112,18,59,4,0,
112,18,63,4,0,
112,18,85,4,0,
112,18,87,4,0,
112,18,89,1,48,
112,18,89,4,0,
112,18,92,4,0,
112,18,104,4,0,
112,18,126,4,0,
112,18,156,4,0,
112,18,157,4,0,
112,18,164,4,0,
112,18,168,4,0,
112,18,182,4,0,
112,18,184,1,1,6
112,18,184,1,9,
112,18,201,4,0,
112,18,207,4,0,
112,18,213,4,0,
112,18,214,4,0,
112,18,216,4,0,
112,18,218,4,0,
112,18,224,1,55,
112,18,237,4,0,
112,18,240,4,0,
112,18,241,4,0,
112,18,263,4,0,
112,18,280,4,0,
112,18,317,4,0,
112,18,350,1,29,
112,18,359,1,0,
112,18,359,1,1,1
112,18,371,4,0,
112,18,374,4,0,
112,18,397,4,0,
112,18,398,4,0,
112,18,411,4,0,
112,18,416,4,0,
112,18,421,4,0,
112,18,444,1,41,
112,18,444,4,0,
112,18,479,1,13,
112,18,479,4,0,
112,18,496,4,0,
112,18,498,1,25,
112,18,523,1,21,
112,18,523,4,0,
112,18,525,4,0,
112,18,529,1,33,
112,18,590,4,0,
112,18,684,4,0,
112,18,693,4,0,
113,1,1,1,1,1
113,1,3,1,1,2
113,1,5,4,0,
113,1,25,4,0,
113,1,34,4,0,
113,1,36,4,0,
113,1,38,1,54,
113,1,38,4,0,
113,1,45,1,30,
113,1,47,1,24,
113,1,55,4,0,
113,1,58,4,0,
113,1,59,4,0,
113,1,61,4,0,
113,1,63,4,0,
113,1,66,4,0,
113,1,68,4,0,
113,1,69,4,0,
113,1,70,4,0,
113,1,76,4,0,
113,1,85,4,0,
113,1,86,4,0,
113,1,87,4,0,
113,1,92,4,0,
113,1,94,4,0,
113,1,99,4,0,
113,1,100,4,0,
113,1,102,4,0,
113,1,104,4,0,
113,1,107,1,38,
113,1,111,1,44,
113,1,113,1,48,
113,1,115,4,0,
113,1,117,4,0,
113,1,118,4,0,
113,1,121,4,0,
113,1,126,4,0,
113,1,130,4,0,
113,1,135,4,0,
113,1,148,4,0,
113,1,149,4,0,
113,1,156,4,0,
113,1,161,4,0,
113,1,164,4,0,
113,2,1,1,1,1
113,2,3,1,12,
113,2,5,4,0,
113,2,25,4,0,
113,2,34,4,0,
113,2,36,4,0,
113,2,38,1,54,
113,2,38,4,0,
113,2,39,1,1,2
113,2,45,1,30,
113,2,47,1,24,
113,2,55,4,0,
113,2,58,4,0,
113,2,59,4,0,
113,2,61,4,0,
113,2,63,4,0,
113,2,66,4,0,
113,2,68,4,0,
113,2,69,4,0,
113,2,70,4,0,
113,2,76,4,0,
113,2,85,4,0,
113,2,86,4,0,
113,2,87,4,0,
113,2,92,4,0,
113,2,94,4,0,
113,2,99,4,0,
113,2,100,4,0,
113,2,102,4,0,
113,2,104,4,0,
113,2,107,1,38,
113,2,111,1,44,
113,2,113,1,48,
113,2,115,4,0,
113,2,117,4,0,
113,2,118,4,0,
113,2,121,4,0,
113,2,126,4,0,
113,2,130,4,0,
113,2,135,4,0,
113,2,148,4,0,
113,2,149,4,0,
113,2,156,4,0,
113,2,161,4,0,
113,2,164,4,0,
113,3,1,1,1,
113,3,3,1,17,
113,3,29,4,0,
113,3,38,1,57,
113,3,39,1,9,
113,3,45,1,5,
113,3,47,1,29,
113,3,59,4,0,
113,3,63,4,0,
113,3,70,4,0,
113,3,76,4,0,
113,3,87,4,0,
113,3,92,4,0,
113,3,94,4,0,
113,3,104,4,0,
113,3,107,1,23,
113,3,111,1,41,
113,3,111,4,0,
113,3,113,1,49,
113,3,118,2,0,
113,3,121,1,35,
113,3,126,4,0,
113,3,135,1,13,
113,3,138,4,0,
113,3,148,4,0,
113,3,156,4,0,
113,3,173,4,0,
113,3,174,4,0,
113,3,182,4,0,
113,3,189,4,0,
113,3,192,4,0,
113,3,196,4,0,
113,3,201,4,0,
113,3,203,4,0,
113,3,205,4,0,
113,3,207,4,0,
113,3,213,4,0,
113,3,214,4,0,
113,3,215,2,0,
113,3,216,4,0,
113,3,217,2,0,
113,3,218,4,0,
113,3,223,4,0,
113,3,231,4,0,
113,3,237,4,0,
113,3,240,4,0,
113,3,241,4,0,
113,3,244,4,0,
113,3,247,4,0,
113,3,249,4,0,
113,4,1,1,1,
113,4,3,1,17,
113,4,29,4,0,
113,4,38,1,57,
113,4,39,1,9,
113,4,45,1,5,
113,4,47,1,29,
113,4,53,3,0,
113,4,58,3,0,
113,4,59,4,0,
113,4,63,4,0,
113,4,70,4,0,
113,4,76,4,0,
113,4,85,3,0,
113,4,87,4,0,
113,4,92,4,0,
113,4,94,4,0,
113,4,104,4,0,
113,4,107,1,23,
113,4,111,1,41,
113,4,111,4,0,
113,4,113,1,49,
113,4,118,2,0,
113,4,121,1,35,
113,4,126,4,0,
113,4,135,1,13,
113,4,138,4,0,
113,4,148,4,0,
113,4,156,4,0,
113,4,173,4,0,
113,4,174,4,0,
113,4,182,4,0,
113,4,189,4,0,
113,4,192,4,0,
113,4,196,4,0,
113,4,201,4,0,
113,4,203,4,0,
113,4,205,4,0,
113,4,207,4,0,
113,4,213,4,0,
113,4,214,4,0,
113,4,215,2,0,
113,4,216,4,0,
113,4,217,2,0,
113,4,218,4,0,
113,4,223,4,0,
113,4,231,4,0,
113,4,237,4,0,
113,4,240,4,0,
113,4,241,4,0,
113,4,244,4,0,
113,4,247,4,0,
113,4,249,4,0,
113,5,1,1,1,1
113,5,3,1,17,
113,5,38,1,57,
113,5,39,1,5,
113,5,45,1,1,2
113,5,47,1,29,
113,5,53,4,0,
113,5,58,4,0,
113,5,59,4,0,
113,5,63,4,0,
113,5,70,4,0,
113,5,76,4,0,
113,5,85,4,0,
113,5,87,4,0,
113,5,89,4,0,
113,5,92,4,0,
113,5,94,4,0,
113,5,104,4,0,
113,5,107,1,23,
113,5,111,1,41,
113,5,113,1,49,
113,5,113,4,0,
113,5,118,2,0,
113,5,121,1,35,
113,5,126,4,0,
113,5,135,1,13,
113,5,148,4,0,
113,5,156,4,0,
113,5,164,2,0,
113,5,182,4,0,
113,5,201,4,0,
113,5,213,4,0,
113,5,215,2,0,
113,5,216,4,0,
113,5,217,2,0,
113,5,218,4,0,
113,5,219,4,0,
113,5,231,4,0,
113,5,237,4,0,
113,5,240,4,0,
113,5,241,4,0,
113,5,247,4,0,
113,5,249,4,0,
113,5,258,4,0,
113,5,263,4,0,
113,5,264,4,0,
113,5,280,4,0,
113,5,285,4,0,
113,5,287,1,9,
113,5,289,4,0,
113,5,290,4,0,
113,5,312,2,0,
113,5,317,4,0,
113,5,347,4,0,
113,5,351,4,0,
113,5,352,4,0,
113,6,1,1,1,1
113,6,3,1,17,
113,6,5,3,0,
113,6,25,3,0,
113,6,34,3,0,
113,6,38,1,57,
113,6,38,3,0,
113,6,39,1,5,
113,6,45,1,1,2
113,6,47,1,29,
113,6,53,4,0,
113,6,58,4,0,
113,6,59,4,0,
113,6,63,4,0,
113,6,68,3,0,
113,6,69,3,0,
113,6,70,4,0,
113,6,76,4,0,
113,6,85,4,0,
113,6,86,3,0,
113,6,87,4,0,
113,6,89,4,0,
113,6,92,4,0,
113,6,94,4,0,
113,6,102,3,0,
113,6,104,4,0,
113,6,107,1,23,
113,6,111,1,41,
113,6,111,3,0,
113,6,113,1,49,
113,6,113,4,0,
113,6,118,2,0,
113,6,118,3,0,
113,6,121,1,35,
113,6,126,4,0,
113,6,135,1,13,
113,6,135,3,0,
113,6,138,3,0,
113,6,148,4,0,
113,6,156,4,0,
113,6,164,2,0,
113,6,164,3,0,
113,6,173,3,0,
113,6,182,4,0,
113,6,189,3,0,
113,6,196,3,0,
113,6,201,4,0,
113,6,203,3,0,
113,6,205,3,0,
113,6,207,3,0,
113,6,213,4,0,
113,6,214,3,0,
113,6,215,2,0,
113,6,216,4,0,
113,6,217,2,0,
113,6,218,4,0,
113,6,219,4,0,
113,6,223,3,0,
113,6,231,4,0,
113,6,237,4,0,
113,6,240,4,0,
113,6,241,4,0,
113,6,244,3,0,
113,6,247,4,0,
113,6,249,4,0,
113,6,258,4,0,
113,6,263,4,0,
113,6,264,4,0,
113,6,280,4,0,
113,6,285,4,0,
113,6,287,1,9,
113,6,289,4,0,
113,6,290,4,0,
113,6,312,2,0,
113,6,317,4,0,
113,6,347,4,0,
113,6,351,4,0,
113,6,352,4,0,
113,7,1,1,1,1
113,7,3,1,17,
113,7,5,3,0,
113,7,25,3,0,
113,7,34,3,0,
113,7,38,1,57,
113,7,38,3,0,
113,7,39,1,5,
113,7,45,1,1,2
113,7,47,1,29,
113,7,53,4,0,
113,7,58,4,0,
113,7,59,4,0,
113,7,63,4,0,
113,7,68,3,0,
113,7,69,3,0,
113,7,70,4,0,
113,7,76,4,0,
113,7,85,4,0,
113,7,86,3,0,
113,7,87,4,0,
113,7,89,4,0,
113,7,92,4,0,
113,7,94,4,0,
113,7,102,3,0,
113,7,104,4,0,
113,7,107,1,23,
113,7,111,1,41,
113,7,113,1,49,
113,7,113,4,0,
113,7,118,2,0,
113,7,118,3,0,
113,7,121,1,35,
113,7,126,4,0,
113,7,135,1,13,
113,7,135,3,0,
113,7,138,3,0,
113,7,148,4,0,
113,7,156,4,0,
113,7,164,2,0,
113,7,164,3,0,
113,7,182,4,0,
113,7,201,4,0,
113,7,213,4,0,
113,7,215,2,0,
113,7,216,4,0,
113,7,217,2,0,
113,7,218,4,0,
113,7,219,4,0,
113,7,231,4,0,
113,7,237,4,0,
113,7,240,4,0,
113,7,241,4,0,
113,7,247,4,0,
113,7,249,4,0,
113,7,258,4,0,
113,7,263,4,0,
113,7,264,4,0,
113,7,280,4,0,
113,7,285,4,0,
113,7,287,1,9,
113,7,289,4,0,
113,7,290,4,0,
113,7,312,2,0,
113,7,317,4,0,
113,7,347,4,0,
113,7,351,4,0,
113,7,352,4,0,
113,8,1,1,1,1
113,8,3,1,16,
113,8,38,1,46,
113,8,39,1,5,
113,8,45,1,1,2
113,8,47,1,23,
113,8,53,4,0,
113,8,58,4,0,
113,8,59,4,0,
113,8,63,4,0,
113,8,68,2,0,
113,8,70,4,0,
113,8,76,4,0,
113,8,85,4,0,
113,8,86,4,0,
113,8,87,4,0,
113,8,89,4,0,
113,8,92,4,0,
113,8,94,4,0,
113,8,104,4,0,
113,8,107,1,20,
113,8,111,1,31,
113,8,113,1,34,
113,8,113,4,0,
113,8,118,2,0,
113,8,121,1,38,
113,8,126,4,0,
113,8,135,1,12,
113,8,138,4,0,
113,8,148,4,0,
113,8,156,4,0,
113,8,157,4,0,
113,8,164,2,0,
113,8,164,4,0,
113,8,182,4,0,
113,8,201,4,0,
113,8,203,4,0,
113,8,207,4,0,
113,8,213,4,0,
113,8,214,4,0,
113,8,215,2,0,
113,8,216,4,0,
113,8,217,2,0,
113,8,218,4,0,
113,8,219,4,0,
113,8,231,4,0,
113,8,237,4,0,
113,8,240,4,0,
113,8,241,4,0,
113,8,244,4,0,
113,8,247,4,0,
113,8,249,4,0,
113,8,258,4,0,
113,8,263,4,0,
113,8,264,4,0,
113,8,270,2,0,
113,8,278,4,0,
113,8,280,4,0,
113,8,285,4,0,
113,8,287,1,9,
113,8,289,4,0,
113,8,290,4,0,
113,8,312,2,0,
113,8,317,4,0,
113,8,347,4,0,
113,8,351,4,0,
113,8,352,4,0,
113,8,356,2,0,
113,8,361,1,42,
113,8,363,4,0,
113,8,374,1,27,
113,8,374,4,0,
113,8,409,4,0,
113,8,416,4,0,
113,8,431,4,0,
113,8,445,4,0,
113,8,446,4,0,
113,8,447,4,0,
113,8,451,4,0,
113,9,1,1,1,1
113,9,3,1,16,
113,9,7,3,0,
113,9,8,3,0,
113,9,9,3,0,
113,9,38,1,46,
113,9,39,1,5,
113,9,45,1,1,2
113,9,47,1,23,
113,9,53,4,0,
113,9,58,4,0,
113,9,59,4,0,
113,9,63,4,0,
113,9,68,2,0,
113,9,70,4,0,
113,9,76,4,0,
113,9,85,4,0,
113,9,86,4,0,
113,9,87,4,0,
113,9,89,4,0,
113,9,92,4,0,
113,9,94,4,0,
113,9,104,4,0,
113,9,107,1,20,
113,9,111,1,31,
113,9,113,1,34,
113,9,113,4,0,
113,9,118,2,0,
113,9,121,1,38,
113,9,126,4,0,
113,9,135,1,12,
113,9,138,4,0,
113,9,148,4,0,
113,9,156,4,0,
113,9,157,4,0,
113,9,164,2,0,
113,9,164,4,0,
113,9,173,3,0,
113,9,182,4,0,
113,9,189,3,0,
113,9,196,3,0,
113,9,201,4,0,
113,9,203,4,0,
113,9,205,3,0,
113,9,207,4,0,
113,9,213,4,0,
113,9,214,4,0,
113,9,215,2,0,
113,9,216,4,0,
113,9,217,2,0,
113,9,218,4,0,
113,9,219,4,0,
113,9,231,4,0,
113,9,237,4,0,
113,9,240,4,0,
113,9,241,4,0,
113,9,244,4,0,
113,9,247,4,0,
113,9,249,4,0,
113,9,258,4,0,
113,9,263,4,0,
113,9,264,4,0,
113,9,270,2,0,
113,9,270,3,0,
113,9,278,4,0,
113,9,280,4,0,
113,9,283,3,0,
113,9,285,4,0,
113,9,287,1,9,
113,9,289,4,0,
113,9,290,4,0,
113,9,312,2,0,
113,9,317,4,0,
113,9,347,4,0,
113,9,351,4,0,
113,9,352,4,0,
113,9,356,2,0,
113,9,361,1,42,
113,9,363,4,0,
113,9,374,1,27,
113,9,374,4,0,
113,9,387,3,0,
113,9,409,4,0,
113,9,416,4,0,
113,9,428,3,0,
113,9,431,4,0,
113,9,445,4,0,
113,9,446,4,0,
113,9,447,4,0,
113,9,451,4,0,
113,10,1,1,1,1
113,10,3,1,16,
113,10,7,3,0,
113,10,8,3,0,
113,10,9,3,0,
113,10,29,3,0,
113,10,38,1,46,
113,10,39,1,5,
113,10,45,1,1,2
113,10,47,1,23,
113,10,53,4,0,
113,10,58,4,0,
113,10,59,4,0,
113,10,63,4,0,
113,10,68,2,0,
113,10,70,4,0,
113,10,76,4,0,
113,10,85,4,0,
113,10,86,4,0,
113,10,87,4,0,
113,10,89,4,0,
113,10,92,4,0,
113,10,94,4,0,
113,10,104,4,0,
113,10,107,1,20,
113,10,111,1,31,
113,10,113,1,34,
113,10,113,4,0,
113,10,118,2,0,
113,10,121,1,38,
113,10,126,4,0,
113,10,135,1,12,
113,10,138,4,0,
113,10,148,4,0,
113,10,156,4,0,
113,10,157,4,0,
113,10,164,2,0,
113,10,164,4,0,
113,10,173,3,0,
113,10,182,4,0,
113,10,189,3,0,
113,10,196,3,0,
113,10,201,4,0,
113,10,203,4,0,
113,10,205,3,0,
113,10,207,4,0,
113,10,213,4,0,
113,10,214,4,0,
113,10,215,2,0,
113,10,215,3,0,
113,10,216,4,0,
113,10,217,2,0,
113,10,218,4,0,
113,10,219,4,0,
113,10,231,4,0,
113,10,237,4,0,
113,10,240,4,0,
113,10,241,4,0,
113,10,244,4,0,
113,10,247,4,0,
113,10,249,4,0,
113,10,258,4,0,
113,10,263,4,0,
113,10,264,4,0,
113,10,270,2,0,
113,10,270,3,0,
113,10,278,4,0,
113,10,280,4,0,
113,10,283,3,0,
113,10,285,4,0,
113,10,287,1,9,
113,10,289,4,0,
113,10,290,4,0,
113,10,312,2,0,
113,10,317,4,0,
113,10,347,4,0,
113,10,351,4,0,
113,10,352,4,0,
113,10,356,2,0,
113,10,356,3,0,
113,10,361,1,42,
113,10,363,4,0,
113,10,374,1,27,
113,10,374,4,0,
113,10,387,3,0,
113,10,409,4,0,
113,10,416,4,0,
113,10,426,2,0,
113,10,428,3,0,
113,10,431,4,0,
113,10,445,4,0,
113,10,446,4,0,
113,10,447,4,0,
113,10,451,4,0,
113,11,1,1,1,2
113,11,3,1,12,
113,11,36,1,27,
113,11,38,1,54,
113,11,39,1,5,
113,11,45,1,1,3
113,11,47,1,31,
113,11,53,4,0,
113,11,58,4,0,
113,11,59,4,0,
113,11,63,4,0,
113,11,68,2,0,
113,11,70,4,0,
113,11,76,4,0,
113,11,85,4,0,
113,11,86,4,0,
113,11,87,4,0,
113,11,89,4,0,
113,11,92,4,0,
113,11,94,4,0,
113,11,104,4,0,
113,11,107,1,23,
113,11,111,1,1,1
113,11,113,1,46,
113,11,113,4,0,
113,11,118,2,0,
113,11,121,1,42,
113,11,126,4,0,
113,11,135,1,16,
113,11,138,4,0,
113,11,148,4,0,
113,11,156,4,0,
113,11,157,4,0,
113,11,164,4,0,
113,11,182,4,0,
113,11,201,4,0,
113,11,203,2,0,
113,11,207,4,0,
113,11,213,4,0,
113,11,215,2,0,
113,11,216,4,0,
113,11,217,2,0,
113,11,218,4,0,
113,11,219,4,0,
113,11,237,4,0,
113,11,240,4,0,
113,11,241,4,0,
113,11,244,4,0,
113,11,247,4,0,
113,11,249,4,0,
113,11,258,4,0,
113,11,263,4,0,
113,11,270,2,0,
113,11,280,4,0,
113,11,287,1,9,
113,11,312,2,0,
113,11,317,4,0,
113,11,347,4,0,
113,11,356,2,0,
113,11,361,1,50,
113,11,363,2,0,
113,11,374,1,34,
113,11,374,4,0,
113,11,416,4,0,
113,11,426,2,0,
113,11,447,4,0,
113,11,451,4,0,
113,11,496,4,0,
113,11,497,4,0,
113,11,505,1,38,
113,11,510,4,0,
113,11,514,4,0,
113,11,516,1,20,
113,11,523,4,0,
113,11,526,4,0,
113,11,528,4,0,
113,12,1,1,1,1
113,12,3,1,17,
113,12,38,1,57,
113,12,39,1,5,
113,12,45,1,1,2
113,12,47,1,29,
113,12,53,4,0,
113,12,58,4,0,
113,12,59,4,0,
113,12,63,4,0,
113,12,70,4,0,
113,12,76,4,0,
113,12,85,4,0,
113,12,87,4,0,
113,12,89,4,0,
113,12,92,4,0,
113,12,94,4,0,
113,12,104,4,0,
113,12,107,1,23,
113,12,111,1,41,
113,12,113,1,49,
113,12,113,4,0,
113,12,121,1,35,
113,12,126,4,0,
113,12,135,1,13,
113,12,148,4,0,
113,12,156,4,0,
113,12,182,4,0,
113,12,201,4,0,
113,12,213,4,0,
113,12,216,4,0,
113,12,218,4,0,
113,12,219,4,0,
113,12,231,4,0,
113,12,237,4,0,
113,12,240,4,0,
113,12,241,4,0,
113,12,247,4,0,
113,12,249,4,0,
113,12,258,4,0,
113,12,263,4,0,
113,12,264,4,0,
113,12,280,4,0,
113,12,285,4,0,
113,12,287,1,9,
113,12,289,4,0,
113,12,290,4,0,
113,12,317,4,0,
113,12,347,4,0,
113,12,351,4,0,
113,12,352,4,0,
113,13,1,1,1,1
113,13,3,1,17,
113,13,34,3,0,
113,13,38,1,57,
113,13,38,3,0,
113,13,39,1,5,
113,13,45,1,1,2
113,13,47,1,29,
113,13,53,4,0,
113,13,58,4,0,
113,13,59,4,0,
113,13,63,4,0,
113,13,69,3,0,
113,13,70,4,0,
113,13,76,4,0,
113,13,85,4,0,
113,13,86,3,0,
113,13,87,4,0,
113,13,89,4,0,
113,13,92,4,0,
113,13,94,4,0,
113,13,102,3,0,
113,13,104,4,0,
113,13,107,1,23,
113,13,111,1,41,
113,13,113,1,49,
113,13,113,4,0,
113,13,121,1,35,
113,13,126,4,0,
113,13,135,1,13,
113,13,138,3,0,
113,13,148,4,0,
113,13,156,4,0,
113,13,164,3,0,
113,13,182,4,0,
113,13,196,3,0,
113,13,201,4,0,
113,13,207,3,0,
113,13,213,4,0,
113,13,216,4,0,
113,13,218,4,0,
113,13,219,4,0,
113,13,231,4,0,
113,13,237,4,0,
113,13,240,4,0,
113,13,241,4,0,
113,13,247,4,0,
113,13,249,4,0,
113,13,258,4,0,
113,13,263,4,0,
113,13,264,4,0,
113,13,280,4,0,
113,13,285,4,0,
113,13,287,1,9,
113,13,289,4,0,
113,13,290,4,0,
113,13,317,4,0,
113,13,347,4,0,
113,13,351,4,0,
113,13,352,4,0,
113,14,1,1,1,2
113,14,3,1,12,
113,14,7,3,0,
113,14,8,3,0,
113,14,9,3,0,
113,14,36,1,27,
113,14,38,1,54,
113,14,39,1,5,
113,14,45,1,1,3
113,14,47,1,31,
113,14,53,4,0,
113,14,58,4,0,
113,14,59,4,0,
113,14,63,4,0,
113,14,68,2,0,
113,14,70,4,0,
113,14,76,4,0,
113,14,85,4,0,
113,14,86,4,0,
113,14,87,4,0,
113,14,89,4,0,
113,14,92,4,0,
113,14,94,4,0,
113,14,104,4,0,
113,14,107,1,23,
113,14,111,1,1,1
113,14,113,1,46,
113,14,113,4,0,
113,14,118,2,0,
113,14,121,1,42,
113,14,126,4,0,
113,14,135,1,16,
113,14,138,4,0,
113,14,148,4,0,
113,14,156,4,0,
113,14,157,4,0,
113,14,164,4,0,
113,14,173,3,0,
113,14,182,4,0,
113,14,196,3,0,
113,14,201,4,0,
113,14,203,2,0,
113,14,207,4,0,
113,14,213,4,0,
113,14,214,3,0,
113,14,215,2,0,
113,14,215,3,0,
113,14,216,4,0,
113,14,217,2,0,
113,14,218,4,0,
113,14,219,4,0,
113,14,231,3,0,
113,14,237,4,0,
113,14,240,4,0,
113,14,241,4,0,
113,14,244,4,0,
113,14,247,4,0,
113,14,249,4,0,
113,14,258,4,0,
113,14,263,4,0,
113,14,270,2,0,
113,14,270,3,0,
113,14,278,3,0,
113,14,280,4,0,
113,14,283,3,0,
113,14,285,3,0,
113,14,287,1,9,
113,14,289,3,0,
113,14,304,3,0,
113,14,312,2,0,
113,14,317,4,0,
113,14,343,3,0,
113,14,347,4,0,
113,14,356,2,0,
113,14,356,3,0,
113,14,361,1,50,
113,14,363,2,0,
113,14,374,1,34,
113,14,374,4,0,
113,14,387,3,0,
113,14,409,3,0,
113,14,416,4,0,
113,14,426,2,0,
113,14,428,3,0,
113,14,446,3,0,
113,14,447,4,0,
113,14,451,4,0,
113,14,496,4,0,
113,14,497,4,0,
113,14,505,1,38,
113,14,510,4,0,
113,14,514,4,0,
113,14,516,1,20,
113,14,523,4,0,
113,14,526,4,0,
113,14,528,4,0,
113,15,1,1,1,3
113,15,3,1,12,
113,15,36,1,27,
113,15,38,1,1,1
113,15,38,1,54,
113,15,39,1,5,
113,15,45,1,1,4
113,15,47,1,31,
113,15,53,4,0,
113,15,58,4,0,
113,15,59,4,0,
113,15,63,4,0,
113,15,68,2,0,
113,15,69,2,0,
113,15,70,4,0,
113,15,76,4,0,
113,15,85,4,0,
113,15,86,4,0,
113,15,87,4,0,
113,15,89,4,0,
113,15,92,4,0,
113,15,94,4,0,
113,15,104,4,0,
113,15,107,1,23,
113,15,111,1,1,2
113,15,113,1,46,
113,15,113,4,0,
113,15,118,2,0,
113,15,121,1,42,
113,15,126,4,0,
113,15,135,1,16,
113,15,138,4,0,
113,15,148,4,0,
113,15,156,4,0,
113,15,157,4,0,
113,15,164,4,0,
113,15,182,4,0,
113,15,201,4,0,
113,15,203,2,0,
113,15,207,4,0,
113,15,213,4,0,
113,15,214,4,0,
113,15,215,2,0,
113,15,216,4,0,
113,15,217,2,0,
113,15,218,4,0,
113,15,219,4,0,
113,15,237,4,0,
113,15,240,4,0,
113,15,241,4,0,
113,15,244,4,0,
113,15,247,4,0,
113,15,249,4,0,
113,15,258,4,0,
113,15,263,4,0,
113,15,270,2,0,
113,15,280,4,0,
113,15,287,1,9,
113,15,312,2,0,
113,15,317,4,0,
113,15,347,4,0,
113,15,356,2,0,
113,15,361,1,50,
113,15,363,2,0,
113,15,374,1,34,
113,15,374,4,0,
113,15,416,4,0,
113,15,426,2,0,
113,15,447,4,0,
113,15,451,4,0,
113,15,496,4,0,
113,15,497,4,0,
113,15,505,1,38,
113,15,510,4,0,
113,15,514,4,0,
113,15,516,1,20,
113,15,523,4,0,
113,15,528,4,0,
113,15,590,4,0,
113,15,605,4,0,
113,15,612,4,0,
113,16,1,1,1,3
113,16,3,1,12,1
113,16,7,3,0,
113,16,8,3,0,
113,16,9,3,0,
113,16,36,1,27,1
113,16,38,1,1,1
113,16,38,1,54,1
113,16,39,1,5,1
113,16,45,1,1,4
113,16,47,1,31,1
113,16,53,4,0,
113,16,58,4,0,
113,16,59,4,0,
113,16,63,4,0,
113,16,68,2,0,
113,16,69,2,0,
113,16,70,4,0,
113,16,76,4,0,
113,16,85,4,0,
113,16,86,4,0,
113,16,87,4,0,
113,16,89,4,0,
113,16,92,4,0,
113,16,94,4,0,
113,16,104,4,0,
113,16,107,1,23,1
113,16,111,1,1,2
113,16,113,1,46,1
113,16,113,4,0,
113,16,118,2,0,
113,16,121,1,42,1
113,16,126,4,0,
113,16,135,1,16,1
113,16,138,4,0,
113,16,148,4,0,
113,16,156,4,0,
113,16,157,4,0,
113,16,164,4,0,
113,16,173,3,0,
113,16,182,4,0,
113,16,196,3,0,
113,16,201,4,0,
113,16,203,2,0,
113,16,207,4,0,
113,16,213,4,0,
113,16,214,4,0,
113,16,215,2,0,
113,16,215,3,0,
113,16,216,4,0,
113,16,217,2,0,
113,16,218,4,0,
113,16,219,4,0,
113,16,231,3,0,
113,16,237,4,0,
113,16,240,4,0,
113,16,241,4,0,
113,16,244,4,0,
113,16,247,4,0,
113,16,249,4,0,
113,16,258,4,0,
113,16,263,4,0,
113,16,264,3,0,
113,16,270,2,0,
113,16,270,3,0,
113,16,278,3,0,
113,16,280,4,0,
113,16,283,3,0,
113,16,285,3,0,
113,16,287,1,9,1
113,16,289,3,0,
113,16,290,4,0,
113,16,304,3,0,
113,16,312,2,0,
113,16,317,4,0,
113,16,343,3,0,
113,16,347,4,0,
113,16,351,3,0,
113,16,352,3,0,
113,16,356,2,0,
113,16,356,3,0,
113,16,361,1,50,1
113,16,363,2,0,
113,16,374,1,34,1
113,16,374,4,0,
113,16,387,3,0,
113,16,409,3,0,
113,16,416,4,0,
113,16,426,2,0,
113,16,428,3,0,
113,16,446,3,0,
113,16,447,4,0,
113,16,451,4,0,
113,16,496,4,0,
113,16,497,4,0,
113,16,505,1,38,1
113,16,510,4,0,
113,16,514,4,0,
113,16,516,1,20,1
113,16,523,4,0,
113,16,528,4,0,
113,16,590,4,0,
113,16,605,4,0,
113,16,612,4,0,
113,17,1,1,1,3
113,17,3,1,12,
113,17,36,1,27,
113,17,38,1,1,1
113,17,38,1,54,
113,17,39,1,5,
113,17,45,1,1,4
113,17,47,1,31,
113,17,53,4,0,
113,17,58,4,0,
113,17,59,4,0,
113,17,63,4,0,
113,17,68,2,0,
113,17,69,2,0,
113,17,76,4,0,
113,17,85,4,0,
113,17,86,4,0,
113,17,87,4,0,
113,17,89,4,0,
113,17,92,4,0,
113,17,94,4,0,
113,17,104,4,0,
113,17,107,1,23,
113,17,111,1,1,2
113,17,113,1,46,
113,17,113,4,0,
113,17,118,2,0,
113,17,121,1,42,
113,17,126,4,0,
113,17,135,1,16,
113,17,138,4,0,
113,17,156,4,0,
113,17,157,4,0,
113,17,164,4,0,
113,17,182,4,0,
113,17,201,4,0,
113,17,203,2,0,
113,17,207,4,0,
113,17,213,4,0,
113,17,214,4,0,
113,17,215,2,0,
113,17,216,4,0,
113,17,217,2,0,
113,17,218,4,0,
113,17,219,4,0,
113,17,237,4,0,
113,17,240,4,0,
113,17,241,4,0,
113,17,244,4,0,
113,17,247,4,0,
113,17,258,4,0,
113,17,263,4,0,
113,17,270,2,0,
113,17,280,4,0,
113,17,287,1,9,
113,17,312,2,0,
113,17,317,4,0,
113,17,347,4,0,
113,17,356,2,0,
113,17,361,1,50,
113,17,363,2,0,
113,17,374,1,34,
113,17,374,4,0,
113,17,416,4,0,
113,17,426,2,0,
113,17,447,4,0,
113,17,451,4,0,
113,17,496,4,0,
113,17,497,4,0,
113,17,505,1,38,
113,17,516,1,20,
113,17,523,4,0,
113,17,526,4,0,
113,17,528,4,0,
113,17,590,4,0,
113,17,605,4,0,
113,18,1,1,1,3
113,18,3,1,12,
113,18,36,1,27,
113,18,38,1,1,1
113,18,38,1,65,
113,18,39,1,5,
113,18,45,1,1,4
113,18,47,1,31,
113,18,53,4,0,
113,18,58,4,0,
113,18,59,4,0,
113,18,63,4,0,
113,18,68,2,0,
113,18,69,2,0,
113,18,76,4,0,
113,18,85,4,0,
113,18,86,4,0,
113,18,87,4,0,
113,18,89,4,0,
113,18,92,4,0,
113,18,94,4,0,
113,18,104,4,0,
113,18,107,1,23,
113,18,111,1,1,2
113,18,113,1,50,
113,18,113,4,0,
113,18,118,2,0,
113,18,121,1,44,
113,18,126,4,0,
113,18,135,1,16,
113,18,138,4,0,
113,18,156,4,0,
113,18,157,4,0,
113,18,164,4,0,
113,18,182,4,0,
113,18,201,4,0,
113,18,203,2,0,
113,18,207,4,0,
113,18,213,4,0,
113,18,214,4,0,
113,18,215,2,0,
113,18,216,4,0,
113,18,217,2,0,
113,18,218,4,0,
113,18,219,4,0,
113,18,237,4,0,
113,18,240,4,0,
113,18,241,4,0,
113,18,244,4,0,
113,18,247,4,0,
113,18,258,4,0,
113,18,263,4,0,
113,18,270,2,0,
113,18,280,4,0,
113,18,287,1,9,
113,18,312,2,0,
113,18,317,4,0,
113,18,347,4,0,
113,18,356,2,0,
113,18,361,1,57,
113,18,363,2,0,
113,18,374,1,35,
113,18,374,4,0,
113,18,416,4,0,
113,18,426,2,0,
113,18,447,4,0,
113,18,451,4,0,
113,18,496,4,0,
113,18,497,4,0,
113,18,505,1,39,
113,18,516,1,20,
113,18,523,4,0,
113,18,526,4,0,
113,18,528,4,0,
113,18,590,4,0,
113,18,605,4,0,
114,1,14,4,0,
114,1,15,4,0,
114,1,20,1,1,2
114,1,21,1,45,
114,1,34,4,0,
114,1,36,4,0,
114,1,38,4,0,
114,1,63,4,0,
114,1,71,1,29,
114,1,72,4,0,
114,1,74,1,49,
114,1,76,4,0,
114,1,77,1,32,
114,1,78,1,36,
114,1,79,1,39,
114,1,92,4,0,
114,1,99,4,0,
114,1,102,4,0,
114,1,104,4,0,
114,1,117,4,0,
114,1,130,4,0,
114,1,132,1,1,1
114,1,156,4,0,
114,1,164,4,0,
114,2,14,4,0,
114,2,15,4,0,
114,2,20,1,24,
114,2,21,1,45,
114,2,22,1,29,
114,2,34,4,0,
114,2,36,4,0,
114,2,38,4,0,
114,2,63,4,0,
114,2,71,1,27,
114,2,72,4,0,
114,2,74,1,48,
114,2,76,4,0,
114,2,77,1,32,
114,2,78,1,36,
114,2,79,1,39,
114,2,92,4,0,
114,2,99,4,0,
114,2,102,4,0,
114,2,104,4,0,
114,2,117,4,0,
114,2,130,4,0,
114,2,132,1,1,
114,2,156,4,0,
114,2,164,4,0,
114,3,15,4,0,
114,3,20,1,25,
114,3,21,1,40,
114,3,22,1,19,
114,3,29,4,0,
114,3,63,4,0,
114,3,71,1,10,
114,3,72,1,31,
114,3,72,2,0,
114,3,74,1,46,
114,3,76,4,0,
114,3,77,1,13,
114,3,78,1,34,
114,3,79,1,4,
114,3,92,4,0,
114,3,93,2,0,
114,3,104,4,0,
114,3,115,2,0,
114,3,132,1,1,
114,3,133,2,0,
114,3,148,4,0,
114,3,156,4,0,
114,3,168,4,0,
114,3,173,4,0,
114,3,174,4,0,
114,3,175,2,0,
114,3,182,4,0,
114,3,188,4,0,
114,3,202,4,0,
114,3,203,4,0,
114,3,207,4,0,
114,3,213,4,0,
114,3,214,4,0,
114,3,216,4,0,
114,3,218,4,0,
114,3,230,4,0,
114,3,237,4,0,
114,3,241,4,0,
114,3,244,4,0,
114,4,15,4,0,
114,4,20,1,25,
114,4,21,1,40,
114,4,22,1,19,
114,4,29,4,0,
114,4,63,4,0,
114,4,71,1,10,
114,4,72,1,31,
114,4,72,2,0,
114,4,74,1,46,
114,4,76,4,0,
114,4,77,1,13,
114,4,78,1,34,
114,4,79,1,4,
114,4,92,4,0,
114,4,93,2,0,
114,4,104,4,0,
114,4,115,2,0,
114,4,132,1,1,
114,4,133,2,0,
114,4,148,4,0,
114,4,156,4,0,
114,4,168,4,0,
114,4,173,4,0,
114,4,174,4,0,
114,4,175,2,0,
114,4,182,4,0,
114,4,188,4,0,
114,4,202,4,0,
114,4,203,4,0,
114,4,207,4,0,
114,4,213,4,0,
114,4,214,4,0,
114,4,216,4,0,
114,4,218,4,0,
114,4,230,4,0,
114,4,237,4,0,
114,4,241,4,0,
114,4,244,4,0,
114,5,15,4,0,
114,5,20,1,28,
114,5,21,1,40,
114,5,22,1,22,
114,5,63,4,0,
114,5,71,1,10,
114,5,72,1,31,
114,5,72,2,0,
114,5,73,2,0,
114,5,74,1,13,
114,5,76,4,0,
114,5,77,1,19,
114,5,78,1,37,
114,5,79,1,4,
114,5,92,4,0,
114,5,93,2,0,
114,5,104,4,0,
114,5,115,2,0,
114,5,132,1,1,2
114,5,133,2,0,
114,5,148,4,0,
114,5,156,4,0,
114,5,168,4,0,
114,5,175,2,0,
114,5,182,4,0,
114,5,188,4,0,
114,5,202,4,0,
114,5,213,4,0,
114,5,216,4,0,
114,5,218,4,0,
114,5,237,4,0,
114,5,241,4,0,
114,5,249,4,0,
114,5,263,4,0,
114,5,267,2,0,
114,5,275,1,1,1
114,5,290,4,0,
114,5,321,1,46,
114,5,331,4,0,
114,6,14,3,0,
114,6,15,4,0,
114,6,20,1,28,
114,6,21,1,40,
114,6,22,1,22,
114,6,34,3,0,
114,6,38,3,0,
114,6,63,4,0,
114,6,71,1,10,
114,6,72,1,31,
114,6,72,2,0,
114,6,73,2,0,
114,6,74,1,13,
114,6,76,4,0,
114,6,77,1,19,
114,6,78,1,37,
114,6,79,1,4,
114,6,92,4,0,
114,6,93,2,0,
114,6,102,3,0,
114,6,104,4,0,
114,6,115,2,0,
114,6,132,1,1,2
114,6,133,2,0,
114,6,148,4,0,
114,6,156,4,0,
114,6,164,3,0,
114,6,168,4,0,
114,6,173,3,0,
114,6,175,2,0,
114,6,182,4,0,
114,6,188,4,0,
114,6,202,4,0,
114,6,203,3,0,
114,6,207,3,0,
114,6,213,4,0,
114,6,214,3,0,
114,6,216,4,0,
114,6,218,4,0,
114,6,237,4,0,
114,6,241,4,0,
114,6,244,3,0,
114,6,249,4,0,
114,6,263,4,0,
114,6,267,2,0,
114,6,275,1,1,1
114,6,290,4,0,
114,6,321,1,46,
114,6,331,4,0,
114,7,14,3,0,
114,7,15,4,0,
114,7,20,1,28,
114,7,21,1,40,
114,7,22,1,22,
114,7,34,3,0,
114,7,38,3,0,
114,7,63,4,0,
114,7,71,1,10,
114,7,72,1,31,
114,7,72,2,0,
114,7,73,2,0,
114,7,74,1,13,
114,7,76,4,0,
114,7,77,1,19,
114,7,78,1,37,
114,7,79,1,4,
114,7,92,4,0,
114,7,93,2,0,
114,7,102,3,0,
114,7,104,4,0,
114,7,115,2,0,
114,7,132,1,1,2
114,7,133,2,0,
114,7,148,4,0,
114,7,156,4,0,
114,7,164,3,0,
114,7,168,4,0,
114,7,175,2,0,
114,7,182,4,0,
114,7,188,4,0,
114,7,202,4,0,
114,7,213,4,0,
114,7,216,4,0,
114,7,218,4,0,
114,7,237,4,0,
114,7,241,4,0,
114,7,249,4,0,
114,7,263,4,0,
114,7,267,2,0,
114,7,275,1,1,1
114,7,290,4,0,
114,7,321,1,46,
114,7,331,4,0,
114,8,14,4,0,
114,8,15,4,0,
114,8,20,1,22,
114,8,21,1,43,
114,8,22,1,19,
114,8,63,4,0,
114,8,71,1,8,
114,8,72,1,26,
114,8,72,2,0,
114,8,73,2,0,
114,8,74,1,12,
114,8,76,4,0,
114,8,77,1,15,
114,8,78,1,29,
114,8,79,1,5,
114,8,92,4,0,
114,8,93,2,0,
114,8,104,4,0,
114,8,115,2,0,
114,8,115,4,0,
114,8,132,1,1,2
114,8,133,2,0,
114,8,148,4,0,
114,8,156,4,0,
114,8,164,4,0,
114,8,168,4,0,
114,8,175,2,0,
114,8,182,4,0,
114,8,188,4,0,
114,8,202,4,0,
114,8,203,4,0,
114,8,207,4,0,
114,8,213,4,0,
114,8,214,4,0,
114,8,216,4,0,
114,8,218,4,0,
114,8,237,4,0,
114,8,241,4,0,
114,8,244,4,0,
114,8,246,1,33,
114,8,249,4,0,
114,8,263,4,0,
114,8,267,2,0,
114,8,275,1,1,1
114,8,282,1,36,
114,8,283,2,0,
114,8,290,4,0,
114,8,321,1,47,
114,8,331,4,0,
114,8,351,4,0,
114,8,363,1,40,
114,8,363,4,0,
114,8,378,1,50,
114,8,412,4,0,
114,8,416,4,0,
114,8,437,2,0,
114,8,438,1,54,
114,8,445,4,0,
114,8,447,4,0,
114,9,14,4,0,
114,9,15,4,0,
114,9,20,1,22,
114,9,21,1,43,
114,9,22,1,19,
114,9,63,4,0,
114,9,71,1,8,
114,9,72,1,26,
114,9,72,2,0,
114,9,73,2,0,
114,9,74,1,12,
114,9,76,4,0,
114,9,77,1,15,
114,9,78,1,29,
114,9,79,1,5,
114,9,92,4,0,
114,9,93,2,0,
114,9,104,4,0,
114,9,115,2,0,
114,9,115,4,0,
114,9,132,1,1,2
114,9,133,2,0,
114,9,148,4,0,
114,9,156,4,0,
114,9,164,4,0,
114,9,168,4,0,
114,9,173,3,0,
114,9,175,2,0,
114,9,182,4,0,
114,9,188,4,0,
114,9,202,4,0,
114,9,203,4,0,
114,9,207,4,0,
114,9,213,4,0,
114,9,214,4,0,
114,9,216,4,0,
114,9,218,4,0,
114,9,235,3,0,
114,9,237,4,0,
114,9,241,4,0,
114,9,244,4,0,
114,9,246,1,33,
114,9,246,3,0,
114,9,249,4,0,
114,9,263,4,0,
114,9,267,2,0,
114,9,275,1,1,1
114,9,282,1,36,
114,9,282,3,0,
114,9,283,2,0,
114,9,290,4,0,
114,9,321,1,47,
114,9,331,4,0,
114,9,351,4,0,
114,9,363,1,40,
114,9,363,4,0,
114,9,378,1,50,
114,9,402,3,0,
114,9,412,4,0,
114,9,416,4,0,
114,9,437,2,0,
114,9,438,1,54,
114,9,445,4,0,
114,9,447,4,0,
114,10,14,4,0,
114,10,15,4,0,
114,10,20,1,22,
114,10,21,1,43,
114,10,22,1,19,
114,10,29,3,0,
114,10,63,4,0,
114,10,71,1,8,
114,10,72,1,26,
114,10,72,2,0,
114,10,73,2,0,
114,10,74,1,12,
114,10,76,4,0,
114,10,77,1,15,
114,10,78,1,29,
114,10,79,1,5,
114,10,92,4,0,
114,10,93,2,0,
114,10,104,4,0,
114,10,115,2,0,
114,10,115,4,0,
114,10,132,1,1,2
114,10,133,2,0,
114,10,148,4,0,
114,10,156,4,0,
114,10,164,4,0,
114,10,168,4,0,
114,10,173,3,0,
114,10,175,2,0,
114,10,182,4,0,
114,10,188,4,0,
114,10,202,4,0,
114,10,203,4,0,
114,10,207,4,0,
114,10,213,4,0,
114,10,214,4,0,
114,10,216,4,0,
114,10,218,4,0,
114,10,220,3,0,
114,10,235,3,0,
114,10,237,4,0,
114,10,241,4,0,
114,10,244,4,0,
114,10,246,1,33,
114,10,246,3,0,
114,10,249,4,0,
114,10,263,4,0,
114,10,267,2,0,
114,10,275,1,1,1
114,10,282,1,36,
114,10,282,3,0,
114,10,283,2,0,
114,10,290,4,0,
114,10,321,1,47,
114,10,331,4,0,
114,10,351,4,0,
114,10,363,1,40,
114,10,363,4,0,
114,10,378,1,50,
114,10,384,2,0,
114,10,388,3,0,
114,10,402,3,0,
114,10,412,4,0,
114,10,416,4,0,
114,10,437,2,0,
114,10,438,1,54,
114,10,445,4,0,
114,10,447,4,0,
114,11,14,4,0,
114,11,15,4,0,
114,11,20,1,22,
114,11,21,1,43,
114,11,22,1,19,
114,11,63,4,0,
114,11,71,1,8,
114,11,72,1,26,
114,11,72,2,0,
114,11,73,2,0,
114,11,74,1,12,
114,11,76,4,0,
114,11,77,1,15,
114,11,78,1,29,
114,11,79,1,5,
114,11,92,4,0,
114,11,93,2,0,
114,11,104,4,0,
114,11,115,4,0,
114,11,132,1,1,2
114,11,133,2,0,
114,11,148,4,0,
114,11,156,4,0,
114,11,164,4,0,
114,11,168,4,0,
114,11,175,2,0,
114,11,182,4,0,
114,11,188,4,0,
114,11,202,2,0,
114,11,207,4,0,
114,11,213,4,0,
114,11,216,4,0,
114,11,218,4,0,
114,11,237,4,0,
114,11,241,4,0,
114,11,244,4,0,
114,11,246,1,36,
114,11,249,4,0,
114,11,263,4,0,
114,11,267,2,0,
114,11,275,1,1,1
114,11,282,1,33,
114,11,283,2,0,
114,11,321,1,47,
114,11,363,1,40,
114,11,363,2,0,
114,11,378,1,50,
114,11,384,2,0,
114,11,412,4,0,
114,11,416,4,0,
114,11,437,2,0,
114,11,438,1,54,
114,11,447,4,0,
114,11,476,2,0,
114,11,496,4,0,
114,12,15,4,0,
114,12,20,1,28,
114,12,21,1,40,
114,12,22,1,22,
114,12,63,4,0,
114,12,71,1,10,
114,12,72,1,31,
114,12,74,1,13,
114,12,76,4,0,
114,12,77,1,19,
114,12,78,1,37,
114,12,79,1,4,
114,12,92,4,0,
114,12,104,4,0,
114,12,132,1,1,2
114,12,148,4,0,
114,12,156,4,0,
114,12,168,4,0,
114,12,182,4,0,
114,12,188,4,0,
114,12,202,4,0,
114,12,213,4,0,
114,12,216,4,0,
114,12,218,4,0,
114,12,237,4,0,
114,12,241,4,0,
114,12,249,4,0,
114,12,263,4,0,
114,12,275,1,1,1
114,12,290,4,0,
114,12,321,1,46,
114,12,331,4,0,
114,13,15,4,0,
114,13,20,1,28,
114,13,21,1,40,
114,13,22,1,22,
114,13,34,3,0,
114,13,38,3,0,
114,13,63,4,0,
114,13,71,1,10,
114,13,72,1,31,
114,13,74,1,13,
114,13,76,4,0,
114,13,77,1,19,
114,13,78,1,37,
114,13,79,1,4,
114,13,92,4,0,
114,13,102,3,0,
114,13,104,4,0,
114,13,132,1,1,2
114,13,148,4,0,
114,13,156,4,0,
114,13,164,3,0,
114,13,168,4,0,
114,13,182,4,0,
114,13,188,4,0,
114,13,202,4,0,
114,13,207,3,0,
114,13,213,4,0,
114,13,216,4,0,
114,13,218,4,0,
114,13,237,4,0,
114,13,241,4,0,
114,13,249,4,0,
114,13,263,4,0,
114,13,275,1,1,1
114,13,290,4,0,
114,13,321,1,46,
114,13,331,4,0,
114,14,14,4,0,
114,14,15,4,0,
114,14,20,1,17,
114,14,20,3,0,
114,14,21,1,43,
114,14,22,1,7,
114,14,63,4,0,
114,14,71,1,10,
114,14,72,1,23,
114,14,72,2,0,
114,14,73,2,0,
114,14,74,1,20,
114,14,76,4,0,
114,14,77,1,14,
114,14,78,1,30,
114,14,79,1,4,
114,14,92,4,0,
114,14,93,2,0,
114,14,104,4,0,
114,14,115,4,0,
114,14,132,1,1,2
114,14,133,2,0,
114,14,148,4,0,
114,14,156,4,0,
114,14,164,4,0,
114,14,168,4,0,
114,14,173,3,0,
114,14,175,2,0,
114,14,182,4,0,
114,14,188,4,0,
114,14,202,1,36,
114,14,202,2,0,
114,14,202,3,0,
114,14,207,4,0,
114,14,213,4,0,
114,14,214,3,0,
114,14,216,4,0,
114,14,218,4,0,
114,14,220,3,0,
114,14,235,3,0,
114,14,237,4,0,
114,14,241,4,0,
114,14,244,4,0,
114,14,246,1,40,
114,14,249,4,0,
114,14,263,4,0,
114,14,267,2,0,
114,14,275,1,1,1
114,14,282,1,27,
114,14,282,3,0,
114,14,283,2,0,
114,14,283,3,0,
114,14,321,1,46,
114,14,363,1,33,
114,14,363,2,0,
114,14,378,1,49,
114,14,384,2,0,
114,14,388,3,0,
114,14,402,3,0,
114,14,412,4,0,
114,14,416,4,0,
114,14,437,2,0,
114,14,438,1,53,
114,14,447,4,0,
114,14,476,2,0,
114,14,496,4,0,
114,15,14,4,0,
114,15,15,4,0,
114,15,20,1,17,
114,15,21,1,41,
114,15,22,1,7,
114,15,63,4,0,
114,15,71,1,10,
114,15,72,1,23,
114,15,72,2,0,
114,15,73,2,0,
114,15,74,1,20,
114,15,76,4,0,
114,15,77,1,14,
114,15,78,1,30,
114,15,79,1,4,
114,15,92,4,0,
114,15,93,2,0,
114,15,104,4,0,
114,15,115,4,0,
114,15,132,1,1,2
114,15,133,2,0,
114,15,148,4,0,
114,15,156,4,0,
114,15,164,4,0,
114,15,168,4,0,
114,15,175,2,0,
114,15,182,4,0,
114,15,188,4,0,
114,15,202,1,36,
114,15,202,2,0,
114,15,207,4,0,
114,15,213,4,0,
114,15,214,4,0,
114,15,216,4,0,
114,15,218,4,0,
114,15,237,4,0,
114,15,241,4,0,
114,15,244,4,0,
114,15,246,1,38,
114,15,249,4,0,
114,15,263,4,0,
114,15,267,2,0,
114,15,275,1,1,1
114,15,282,1,27,
114,15,283,2,0,
114,15,321,1,44,
114,15,363,1,33,
114,15,363,2,0,
114,15,378,1,46,
114,15,384,2,0,
114,15,412,4,0,
114,15,416,4,0,
114,15,437,2,0,
114,15,438,1,50,
114,15,447,4,0,
114,15,476,2,0,
114,15,496,4,0,
114,15,580,1,48,
114,15,590,4,0,
114,15,611,4,0,
114,16,14,4,0,
114,16,15,4,0,
114,16,20,1,17,1
114,16,20,3,0,
114,16,21,1,41,1
114,16,22,1,7,1
114,16,63,4,0,
114,16,71,1,10,1
114,16,72,1,23,1
114,16,72,2,0,
114,16,73,2,0,
114,16,74,1,20,1
114,16,76,4,0,
114,16,77,1,14,1
114,16,78,1,30,1
114,16,79,1,4,1
114,16,92,4,0,
114,16,93,2,0,
114,16,104,4,0,
114,16,115,4,0,
114,16,132,1,1,2
114,16,133,2,0,
114,16,148,4,0,
114,16,156,4,0,
114,16,164,4,0,
114,16,168,4,0,
114,16,173,3,0,
114,16,175,2,0,
114,16,182,4,0,
114,16,188,4,0,
114,16,202,1,36,1
114,16,202,2,0,
114,16,202,3,0,
114,16,207,4,0,
114,16,213,4,0,
114,16,214,4,0,
114,16,216,4,0,
114,16,218,4,0,
114,16,220,3,0,
114,16,235,3,0,
114,16,237,4,0,
114,16,241,4,0,
114,16,244,4,0,
114,16,246,1,38,1
114,16,249,4,0,
114,16,263,4,0,
114,16,267,2,0,
114,16,267,4,0,
114,16,275,1,1,1
114,16,282,1,27,1
114,16,282,3,0,
114,16,283,2,0,
114,16,283,3,0,
114,16,290,4,0,
114,16,321,1,44,1
114,16,351,3,0,
114,16,363,1,33,1
114,16,363,2,0,
114,16,378,1,46,1
114,16,384,2,0,
114,16,388,3,0,
114,16,402,3,0,
114,16,412,4,0,
114,16,416,4,0,
114,16,437,2,0,
114,16,438,1,50,1
114,16,447,4,0,
114,16,476,2,0,
114,16,496,4,0,
114,16,580,1,48,1
114,16,590,4,0,
114,16,611,4,0,
114,17,14,4,0,
114,17,20,1,17,
114,17,21,1,41,
114,17,22,1,7,
114,17,63,4,0,
114,17,71,1,10,
114,17,72,1,23,
114,17,72,2,0,
114,17,73,2,0,
114,17,74,1,20,
114,17,76,4,0,
114,17,77,1,14,
114,17,78,1,30,
114,17,79,1,4,
114,17,92,4,0,
114,17,93,2,0,
114,17,104,4,0,
114,17,115,4,0,
114,17,132,1,1,2
114,17,133,2,0,
114,17,156,4,0,
114,17,164,4,0,
114,17,168,4,0,
114,17,175,2,0,
114,17,182,4,0,
114,17,188,4,0,
114,17,202,1,36,
114,17,202,2,0,
114,17,207,4,0,
114,17,213,4,0,
114,17,214,4,0,
114,17,216,4,0,
114,17,218,4,0,
114,17,237,4,0,
114,17,241,4,0,
114,17,244,4,0,
114,17,246,1,38,
114,17,263,4,0,
114,17,267,2,0,
114,17,267,4,0,
114,17,275,1,1,1
114,17,282,1,27,
114,17,283,2,0,
114,17,321,1,44,
114,17,363,1,33,
114,17,363,2,0,
114,17,378,1,46,
114,17,384,2,0,
114,17,412,4,0,
114,17,416,4,0,
114,17,437,2,0,
114,17,438,1,50,
114,17,447,4,0,
114,17,476,2,0,
114,17,496,4,0,
114,17,580,1,48,
114,17,590,4,0,
114,17,611,4,0,
114,18,14,4,0,
114,18,20,1,17,
114,18,21,1,41,
114,18,22,1,7,
114,18,63,4,0,
114,18,71,1,10,
114,18,72,1,23,
114,18,72,2,0,
114,18,73,2,0,
114,18,74,1,20,
114,18,76,4,0,
114,18,77,1,14,
114,18,78,1,30,
114,18,79,1,4,
114,18,92,4,0,
114,18,93,2,0,
114,18,104,4,0,
114,18,115,4,0,
114,18,132,1,1,2
114,18,133,2,0,
114,18,156,4,0,
114,18,164,4,0,
114,18,168,4,0,
114,18,175,2,0,
114,18,182,4,0,
114,18,188,4,0,
114,18,202,1,36,
114,18,202,2,0,
114,18,207,4,0,
114,18,213,4,0,
114,18,214,4,0,
114,18,216,4,0,
114,18,218,4,0,
114,18,237,4,0,
114,18,241,4,0,
114,18,244,4,0,
114,18,246,1,38,
114,18,263,4,0,
114,18,267,2,0,
114,18,267,4,0,
114,18,275,1,1,1
114,18,282,1,27,
114,18,283,2,0,
114,18,321,1,44,
114,18,358,2,0,
114,18,363,1,33,
114,18,363,2,0,
114,18,378,1,46,
114,18,384,2,0,
114,18,412,4,0,
114,18,416,4,0,
114,18,437,2,0,
114,18,438,1,50,
114,18,447,4,0,
114,18,476,2,0,
114,18,496,4,0,
114,18,580,1,48,
114,18,590,4,0,
114,18,611,4,0,
115,1,4,1,1,1
115,1,5,1,36,
115,1,5,4,0,
115,1,25,4,0,
115,1,34,4,0,
115,1,36,4,0,
115,1,38,4,0,
115,1,39,1,31,
115,1,43,1,41,
115,1,44,1,26,
115,1,55,4,0,
115,1,57,4,0,
115,1,58,4,0,
115,1,59,4,0,
115,1,61,4,0,
115,1,63,4,0,
115,1,66,4,0,
115,1,68,4,0,
115,1,69,4,0,
115,1,70,4,0,
115,1,85,4,0,
115,1,87,4,0,
115,1,89,4,0,
115,1,90,4,0,
115,1,92,4,0,
115,1,99,1,1,2
115,1,99,4,0,
115,1,102,4,0,
115,1,104,4,0,
115,1,117,4,0,
115,1,126,4,0,
115,1,130,4,0,
115,1,146,1,46,
115,1,156,4,0,
115,1,157,4,0,
115,1,164,4,0,
115,2,4,1,1,1
115,2,5,1,36,
115,2,5,4,0,
115,2,25,4,0,
115,2,34,4,0,
115,2,36,4,0,
115,2,38,4,0,
115,2,39,1,31,
115,2,43,1,41,
115,2,44,1,26,
115,2,55,4,0,
115,2,57,4,0,
115,2,58,4,0,
115,2,59,4,0,
115,2,61,4,0,
115,2,63,4,0,
115,2,66,4,0,
115,2,68,4,0,
115,2,69,4,0,
115,2,70,4,0,
115,2,85,4,0,
115,2,87,4,0,
115,2,89,4,0,
115,2,90,4,0,
115,2,92,4,0,
115,2,99,1,1,2
115,2,99,4,0,
115,2,102,4,0,
115,2,104,4,0,
115,2,117,4,0,
115,2,126,4,0,
115,2,130,4,0,
115,2,146,1,46,
115,2,156,4,0,
115,2,157,4,0,
115,2,164,4,0,
115,3,4,1,1,
115,3,5,1,25,
115,3,7,4,0,
115,3,8,4,0,
115,3,9,4,0,
115,3,23,2,0,
115,3,29,4,0,
115,3,39,1,19,
115,3,43,1,7,
115,3,44,1,13,
115,3,46,4,0,
115,3,50,2,0,
115,3,57,4,0,
115,3,59,4,0,
115,3,63,4,0,
115,3,70,4,0,
115,3,87,4,0,
115,3,89,4,0,
115,3,92,4,0,
115,3,99,1,31,
115,3,104,4,0,
115,3,116,2,0,
115,3,126,4,0,
115,3,146,1,43,
115,3,156,4,0,
115,3,173,4,0,
115,3,174,4,0,
115,3,179,1,49,
115,3,182,4,0,
115,3,189,4,0,
115,3,192,4,0,
115,3,193,2,0,
115,3,196,4,0,
115,3,201,4,0,
115,3,203,1,37,
115,3,203,4,0,
115,3,207,4,0,
115,3,210,4,0,
115,3,213,4,0,
115,3,214,4,0,
115,3,216,4,0,
115,3,218,4,0,
115,3,219,2,0,
115,3,223,4,0,
115,3,231,4,0,
115,3,237,4,0,
115,3,240,4,0,
115,3,241,4,0,
115,3,247,4,0,
115,3,249,4,0,
115,4,4,1,1,
115,4,5,1,25,
115,4,7,4,0,
115,4,8,4,0,
115,4,9,4,0,
115,4,23,2,0,
115,4,29,4,0,
115,4,39,1,19,
115,4,43,1,7,
115,4,44,1,13,
115,4,46,4,0,
115,4,50,2,0,
115,4,53,3,0,
115,4,57,4,0,
115,4,58,3,0,
115,4,59,4,0,
115,4,63,4,0,
115,4,70,4,0,
115,4,85,3,0,
115,4,87,4,0,
115,4,89,4,0,
115,4,92,4,0,
115,4,99,1,31,
115,4,104,4,0,
115,4,116,2,0,
115,4,126,4,0,
115,4,146,1,43,
115,4,156,4,0,
115,4,173,4,0,
115,4,174,4,0,
115,4,179,1,49,
115,4,182,4,0,
115,4,189,4,0,
115,4,192,4,0,
115,4,193,2,0,
115,4,196,4,0,
115,4,201,4,0,
115,4,203,1,37,
115,4,203,4,0,
115,4,207,4,0,
115,4,210,4,0,
115,4,213,4,0,
115,4,214,4,0,
115,4,216,4,0,
115,4,218,4,0,
115,4,219,2,0,
115,4,223,4,0,
115,4,231,4,0,
115,4,237,4,0,
115,4,240,4,0,
115,4,241,4,0,
115,4,247,4,0,
115,4,249,4,0,
115,5,4,1,1,1
115,5,5,1,25,
115,5,15,4,0,
115,5,23,2,0,
115,5,39,1,13,
115,5,43,1,1,2
115,5,44,1,7,
115,5,46,4,0,
115,5,50,2,0,
115,5,53,4,0,
115,5,57,4,0,
115,5,58,4,0,
115,5,59,4,0,
115,5,63,4,0,
115,5,68,2,0,
115,5,70,4,0,
115,5,76,4,0,
115,5,85,4,0,
115,5,87,4,0,
115,5,89,4,0,
115,5,91,4,0,
115,5,92,4,0,
115,5,99,1,31,
115,5,104,4,0,
115,5,116,2,0,
115,5,126,4,0,
115,5,146,1,43,
115,5,156,4,0,
115,5,164,2,0,
115,5,168,4,0,
115,5,179,1,49,
115,5,182,4,0,
115,5,193,2,0,
115,5,201,4,0,
115,5,203,1,37,
115,5,213,4,0,
115,5,216,4,0,
115,5,218,4,0,
115,5,219,2,0,
115,5,231,4,0,
115,5,237,4,0,
115,5,240,4,0,
115,5,241,4,0,
115,5,247,4,0,
115,5,249,4,0,
115,5,252,1,19,
115,5,258,4,0,
115,5,263,4,0,
115,5,264,4,0,
115,5,280,4,0,
115,5,290,4,0,
115,5,306,2,0,
115,5,317,4,0,
115,5,332,4,0,
115,5,351,4,0,
115,5,352,4,0,
115,6,4,1,1,1
115,6,5,1,25,
115,6,5,3,0,
115,6,7,3,0,
115,6,8,3,0,
115,6,9,3,0,
115,6,15,4,0,
115,6,23,2,0,
115,6,25,3,0,
115,6,34,3,0,
115,6,38,3,0,
115,6,39,1,13,
115,6,43,1,1,2
115,6,44,1,7,
115,6,46,4,0,
115,6,50,2,0,
115,6,53,4,0,
115,6,57,4,0,
115,6,58,4,0,
115,6,59,4,0,
115,6,63,4,0,
115,6,68,2,0,
115,6,68,3,0,
115,6,69,3,0,
115,6,70,4,0,
115,6,76,4,0,
115,6,85,4,0,
115,6,87,4,0,
115,6,89,4,0,
115,6,91,4,0,
115,6,92,4,0,
115,6,99,1,31,
115,6,102,3,0,
115,6,104,4,0,
115,6,116,2,0,
115,6,126,4,0,
115,6,146,1,43,
115,6,156,4,0,
115,6,157,3,0,
115,6,164,2,0,
115,6,164,3,0,
115,6,168,4,0,
115,6,173,3,0,
115,6,179,1,49,
115,6,182,4,0,
115,6,189,3,0,
115,6,193,2,0,
115,6,196,3,0,
115,6,201,4,0,
115,6,203,1,37,
115,6,203,3,0,
115,6,207,3,0,
115,6,210,3,0,
115,6,213,4,0,
115,6,214,3,0,
115,6,216,4,0,
115,6,218,4,0,
115,6,219,2,0,
115,6,223,3,0,
115,6,231,4,0,
115,6,237,4,0,
115,6,240,4,0,
115,6,241,4,0,
115,6,247,4,0,
115,6,249,4,0,
115,6,252,1,19,
115,6,258,4,0,
115,6,263,4,0,
115,6,264,4,0,
115,6,280,4,0,
115,6,290,4,0,
115,6,306,2,0,
115,6,317,4,0,
115,6,332,4,0,
115,6,351,4,0,
115,6,352,4,0,
115,7,4,1,1,1
115,7,5,1,25,
115,7,5,3,0,
115,7,15,4,0,
115,7,23,2,0,
115,7,25,3,0,
115,7,34,3,0,
115,7,38,3,0,
115,7,39,1,13,
115,7,43,1,1,2
115,7,44,1,7,
115,7,46,4,0,
115,7,50,2,0,
115,7,53,4,0,
115,7,57,4,0,
115,7,58,4,0,
115,7,59,4,0,
115,7,63,4,0,
115,7,68,2,0,
115,7,68,3,0,
115,7,69,3,0,
115,7,70,4,0,
115,7,76,4,0,
115,7,85,4,0,
115,7,87,4,0,
115,7,89,4,0,
115,7,91,4,0,
115,7,92,4,0,
115,7,99,1,31,
115,7,102,3,0,
115,7,104,4,0,
115,7,116,2,0,
115,7,126,4,0,
115,7,146,1,43,
115,7,156,4,0,
115,7,157,3,0,
115,7,164,2,0,
115,7,164,3,0,
115,7,168,4,0,
115,7,179,1,49,
115,7,182,4,0,
115,7,193,2,0,
115,7,201,4,0,
115,7,203,1,37,
115,7,213,4,0,
115,7,216,4,0,
115,7,218,4,0,
115,7,219,2,0,
115,7,231,4,0,
115,7,237,4,0,
115,7,240,4,0,
115,7,241,4,0,
115,7,247,4,0,
115,7,249,4,0,
115,7,252,1,19,
115,7,258,4,0,
115,7,263,4,0,
115,7,264,4,0,
115,7,280,4,0,
115,7,290,4,0,
115,7,306,2,0,
115,7,317,4,0,
115,7,332,4,0,
115,7,351,4,0,
115,7,352,4,0,
115,8,4,1,1,1
115,8,5,1,19,
115,8,15,4,0,
115,8,23,2,0,
115,8,38,2,0,
115,8,39,1,10,
115,8,43,1,1,2
115,8,44,1,13,
115,8,46,4,0,
115,8,50,2,0,
115,8,53,4,0,
115,8,57,4,0,
115,8,58,4,0,
115,8,59,4,0,
115,8,63,4,0,
115,8,68,2,0,
115,8,70,4,0,
115,8,76,4,0,
115,8,85,4,0,
115,8,87,4,0,
115,8,89,4,0,
115,8,91,4,0,
115,8,92,4,0,
115,8,99,1,22,
115,8,104,4,0,
115,8,116,2,0,
115,8,126,4,0,
115,8,146,1,25,
115,8,156,4,0,
115,8,157,4,0,
115,8,164,2,0,
115,8,164,4,0,
115,8,168,4,0,
115,8,179,1,49,
115,8,182,4,0,
115,8,193,2,0,
115,8,200,1,37,
115,8,201,4,0,
115,8,203,1,34,
115,8,203,4,0,
115,8,207,4,0,
115,8,213,4,0,
115,8,214,4,0,
115,8,216,4,0,
115,8,218,4,0,
115,8,219,2,0,
115,8,231,4,0,
115,8,237,4,0,
115,8,240,4,0,
115,8,241,4,0,
115,8,242,1,31,
115,8,247,4,0,
115,8,249,4,0,
115,8,252,1,7,
115,8,258,4,0,
115,8,263,4,0,
115,8,264,4,0,
115,8,280,4,0,
115,8,283,2,0,
115,8,290,4,0,
115,8,306,2,0,
115,8,317,4,0,
115,8,332,4,0,
115,8,351,4,0,
115,8,352,4,0,
115,8,359,2,0,
115,8,363,4,0,
115,8,374,4,0,
115,8,389,1,46,
115,8,409,4,0,
115,8,411,4,0,
115,8,416,4,0,
115,8,419,4,0,
115,8,421,4,0,
115,8,431,4,0,
115,8,445,4,0,
115,8,458,1,43,
115,9,4,1,1,1
115,9,5,1,19,
115,9,7,3,0,
115,9,8,3,0,
115,9,9,3,0,
115,9,15,4,0,
115,9,23,2,0,
115,9,38,2,0,
115,9,39,1,10,
115,9,43,1,1,2
115,9,44,1,13,
115,9,46,4,0,
115,9,50,2,0,
115,9,53,4,0,
115,9,57,4,0,
115,9,58,4,0,
115,9,59,4,0,
115,9,63,4,0,
115,9,68,2,0,
115,9,70,4,0,
115,9,76,4,0,
115,9,85,4,0,
115,9,87,4,0,
115,9,89,4,0,
115,9,91,4,0,
115,9,92,4,0,
115,9,99,1,22,
115,9,104,4,0,
115,9,116,2,0,
115,9,126,4,0,
115,9,146,1,25,
115,9,156,4,0,
115,9,157,4,0,
115,9,164,2,0,
115,9,164,4,0,
115,9,168,4,0,
115,9,173,3,0,
115,9,179,1,49,
115,9,180,3,0,
115,9,182,4,0,
115,9,189,3,0,
115,9,193,2,0,
115,9,196,3,0,
115,9,200,1,37,
115,9,200,3,0,
115,9,201,4,0,
115,9,203,1,34,
115,9,203,4,0,
115,9,207,4,0,
115,9,210,3,0,
115,9,213,4,0,
115,9,214,4,0,
115,9,216,4,0,
115,9,218,4,0,
115,9,219,2,0,
115,9,231,4,0,
115,9,237,4,0,
115,9,240,4,0,
115,9,241,4,0,
115,9,242,1,31,
115,9,247,4,0,
115,9,249,4,0,
115,9,252,1,7,
115,9,253,3,0,
115,9,258,4,0,
115,9,263,4,0,
115,9,264,4,0,
115,9,270,3,0,
115,9,280,4,0,
115,9,283,2,0,
115,9,283,3,0,
115,9,290,4,0,
115,9,306,2,0,
115,9,317,4,0,
115,9,332,4,0,
115,9,351,4,0,
115,9,352,4,0,
115,9,359,2,0,
115,9,363,4,0,
115,9,374,4,0,
115,9,389,1,46,
115,9,389,3,0,
115,9,401,3,0,
115,9,409,4,0,
115,9,411,4,0,
115,9,416,4,0,
115,9,419,4,0,
115,9,421,4,0,
115,9,431,4,0,
115,9,445,4,0,
115,9,458,1,43,
115,10,4,1,1,1
115,10,5,1,19,
115,10,7,3,0,
115,10,8,3,0,
115,10,9,3,0,
115,10,15,4,0,
115,10,23,2,0,
115,10,29,3,0,
115,10,38,2,0,
115,10,39,1,10,
115,10,43,1,1,2
115,10,44,1,13,
115,10,46,4,0,
115,10,50,2,0,
115,10,53,4,0,
115,10,57,4,0,
115,10,58,4,0,
115,10,59,4,0,
115,10,63,4,0,
115,10,67,3,0,
115,10,68,2,0,
115,10,70,4,0,
115,10,76,4,0,
115,10,85,4,0,
115,10,87,4,0,
115,10,89,4,0,
115,10,91,4,0,
115,10,92,4,0,
115,10,99,1,22,
115,10,104,4,0,
115,10,116,2,0,
115,10,126,4,0,
115,10,146,1,25,
115,10,156,4,0,
115,10,157,4,0,
115,10,164,2,0,
115,10,164,4,0,
115,10,168,4,0,
115,10,173,3,0,
115,10,179,1,49,
115,10,180,3,0,
115,10,182,4,0,
115,10,189,3,0,
115,10,193,2,0,
115,10,196,3,0,
115,10,200,1,37,
115,10,200,3,0,
115,10,201,4,0,
115,10,203,1,34,
115,10,203,4,0,
115,10,207,4,0,
115,10,210,3,0,
115,10,213,4,0,
115,10,214,4,0,
115,10,216,4,0,
115,10,218,4,0,
115,10,219,2,0,
115,10,231,4,0,
115,10,237,4,0,
115,10,240,4,0,
115,10,241,4,0,
115,10,242,1,31,
115,10,247,4,0,
115,10,249,4,0,
115,10,250,4,0,
115,10,252,1,7,
115,10,253,3,0,
115,10,258,4,0,
115,10,263,4,0,
115,10,264,4,0,
115,10,270,3,0,
115,10,280,4,0,
115,10,283,2,0,
115,10,283,3,0,
115,10,290,4,0,
115,10,306,2,0,
115,10,317,4,0,
115,10,332,4,0,
115,10,351,4,0,
115,10,352,4,0,
115,10,359,2,0,
115,10,363,4,0,
115,10,374,4,0,
115,10,389,1,46,
115,10,389,3,0,
115,10,401,3,0,
115,10,409,4,0,
115,10,411,4,0,
115,10,416,4,0,
115,10,419,4,0,
115,10,421,4,0,
115,10,431,4,0,
115,10,445,4,0,
115,10,458,1,43,
115,11,4,1,1,1
115,11,5,1,25,
115,11,15,4,0,
115,11,23,2,0,
115,11,38,2,0,
115,11,39,1,10,
115,11,43,1,1,2
115,11,44,1,13,
115,11,46,4,0,
115,11,50,2,0,
115,11,53,4,0,
115,11,57,4,0,
115,11,58,4,0,
115,11,59,4,0,
115,11,63,4,0,
115,11,68,2,0,
115,11,70,4,0,
115,11,76,4,0,
115,11,85,4,0,
115,11,87,4,0,
115,11,89,4,0,
115,11,91,4,0,
115,11,92,4,0,
115,11,99,1,22,
115,11,104,4,0,
115,11,116,2,0,
115,11,126,4,0,
115,11,146,1,34,
115,11,156,4,0,
115,11,157,4,0,
115,11,164,4,0,
115,11,168,4,0,
115,11,179,1,55,
115,11,182,4,0,
115,11,193,2,0,
115,11,200,1,46,
115,11,201,4,0,
115,11,203,1,43,
115,11,207,4,0,
115,11,213,4,0,
115,11,216,4,0,
115,11,218,4,0,
115,11,219,4,0,
115,11,237,4,0,
115,11,240,4,0,
115,11,241,4,0,
115,11,242,1,37,
115,11,247,4,0,
115,11,249,4,0,
115,11,252,1,7,
115,11,253,2,0,
115,11,258,4,0,
115,11,263,4,0,
115,11,264,2,0,
115,11,280,4,0,
115,11,283,2,0,
115,11,306,2,0,
115,11,317,4,0,
115,11,332,4,0,
115,11,359,2,0,
115,11,374,4,0,
115,11,376,2,0,
115,11,389,1,49,
115,11,411,4,0,
115,11,416,4,0,
115,11,421,4,0,
115,11,458,1,19,
115,11,496,4,0,
115,11,498,1,31,
115,11,509,2,0,
115,11,510,4,0,
115,11,514,4,0,
115,11,523,4,0,
115,11,526,4,0,
115,12,4,1,1,1
115,12,5,1,25,
115,12,15,4,0,
115,12,39,1,13,
115,12,43,1,1,2
115,12,44,1,7,
115,12,46,4,0,
115,12,53,4,0,
115,12,57,4,0,
115,12,58,4,0,
115,12,59,4,0,
115,12,63,4,0,
115,12,70,4,0,
115,12,76,4,0,
115,12,85,4,0,
115,12,87,4,0,
115,12,89,4,0,
115,12,91,4,0,
115,12,92,4,0,
115,12,99,1,31,
115,12,104,4,0,
115,12,126,4,0,
115,12,146,1,43,
115,12,156,4,0,
115,12,168,4,0,
115,12,179,1,49,
115,12,182,4,0,
115,12,201,4,0,
115,12,203,1,37,
115,12,213,4,0,
115,12,216,4,0,
115,12,218,4,0,
115,12,231,4,0,
115,12,237,4,0,
115,12,240,4,0,
115,12,241,4,0,
115,12,247,4,0,
115,12,249,4,0,
115,12,252,1,19,
115,12,258,4,0,
115,12,263,4,0,
115,12,264,4,0,
115,12,280,4,0,
115,12,290,4,0,
115,12,317,4,0,
115,12,332,4,0,
115,12,351,4,0,
115,12,352,4,0,
115,13,4,1,1,1
115,13,5,1,25,
115,13,15,4,0,
115,13,34,3,0,
115,13,38,3,0,
115,13,39,1,13,
115,13,43,1,1,2
115,13,44,1,7,
115,13,46,4,0,
115,13,53,4,0,
115,13,57,4,0,
115,13,58,4,0,
115,13,59,4,0,
115,13,63,4,0,
115,13,69,3,0,
115,13,70,4,0,
115,13,76,4,0,
115,13,85,4,0,
115,13,87,4,0,
115,13,89,4,0,
115,13,91,4,0,
115,13,92,4,0,
115,13,99,1,31,
115,13,102,3,0,
115,13,104,4,0,
115,13,126,4,0,
115,13,146,1,43,
115,13,156,4,0,
115,13,164,3,0,
115,13,168,4,0,
115,13,179,1,49,
115,13,182,4,0,
115,13,196,3,0,
115,13,201,4,0,
115,13,203,1,37,
115,13,207,3,0,
115,13,213,4,0,
115,13,216,4,0,
115,13,218,4,0,
115,13,231,4,0,
115,13,237,4,0,
115,13,240,4,0,
115,13,241,4,0,
115,13,247,4,0,
115,13,249,4,0,
115,13,252,1,19,
115,13,258,4,0,
115,13,263,4,0,
115,13,264,4,0,
115,13,280,4,0,
115,13,290,4,0,
115,13,317,4,0,
115,13,332,4,0,
115,13,351,4,0,
115,13,352,4,0,
115,14,4,1,1,1
115,14,5,1,25,
115,14,7,3,0,
115,14,8,3,0,
115,14,9,3,0,
115,14,15,4,0,
115,14,23,2,0,
115,14,38,2,0,
115,14,39,1,10,
115,14,43,1,1,2
115,14,44,1,13,
115,14,46,4,0,
115,14,50,2,0,
115,14,53,4,0,
115,14,57,4,0,
115,14,58,4,0,
115,14,59,4,0,
115,14,63,4,0,
115,14,67,3,0,
115,14,68,2,0,
115,14,70,4,0,
115,14,76,4,0,
115,14,85,4,0,
115,14,87,4,0,
115,14,89,4,0,
115,14,91,4,0,
115,14,92,4,0,
115,14,99,1,22,
115,14,104,4,0,
115,14,116,2,0,
115,14,126,4,0,
115,14,146,1,34,
115,14,156,4,0,
115,14,157,4,0,
115,14,164,4,0,
115,14,168,4,0,
115,14,173,3,0,
115,14,179,1,55,
115,14,180,3,0,
115,14,182,4,0,
115,14,193,2,0,
115,14,196,3,0,
115,14,200,1,46,
115,14,200,3,0,
115,14,201,4,0,
115,14,203,1,43,
115,14,207,4,0,
115,14,213,4,0,
115,14,214,3,0,
115,14,216,4,0,
115,14,218,4,0,
115,14,219,4,0,
115,14,231,3,0,
115,14,237,4,0,
115,14,240,4,0,
115,14,241,4,0,
115,14,242,1,37,
115,14,247,4,0,
115,14,249,4,0,
115,14,252,1,7,
115,14,253,2,0,
115,14,253,3,0,
115,14,258,4,0,
115,14,263,4,0,
115,14,264,2,0,
115,14,270,3,0,
115,14,280,4,0,
115,14,283,2,0,
115,14,283,3,0,
115,14,306,2,0,
115,14,317,4,0,
115,14,332,4,0,
115,14,343,3,0,
115,14,359,2,0,
115,14,374,4,0,
115,14,376,2,0,
115,14,389,1,49,
115,14,401,3,0,
115,14,409,3,0,
115,14,411,4,0,
115,14,416,4,0,
115,14,421,4,0,
115,14,458,1,19,
115,14,496,4,0,
115,14,498,1,31,
115,14,509,2,0,
115,14,510,4,0,
115,14,514,4,0,
115,14,523,4,0,
115,14,526,4,0,
115,15,4,1,1,1
115,15,5,1,25,
115,15,15,4,0,
115,15,23,2,0,
115,15,38,2,0,
115,15,39,1,10,
115,15,43,1,1,2
115,15,44,1,13,
115,15,46,4,0,
115,15,50,2,0,
115,15,53,4,0,
115,15,57,4,0,
115,15,58,4,0,
115,15,59,4,0,
115,15,63,4,0,
115,15,68,2,0,
115,15,70,4,0,
115,15,76,4,0,
115,15,85,4,0,
115,15,87,4,0,
115,15,89,4,0,
115,15,91,4,0,
115,15,92,4,0,
115,15,99,1,22,
115,15,104,4,0,
115,15,116,2,0,
115,15,126,4,0,
115,15,146,1,34,
115,15,156,4,0,
115,15,157,4,0,
115,15,164,4,0,
115,15,168,4,0,
115,15,179,1,50,
115,15,182,4,0,
115,15,193,2,0,
115,15,200,1,46,
115,15,201,4,0,
115,15,203,1,43,
115,15,207,4,0,
115,15,213,4,0,
115,15,214,4,0,
115,15,216,4,0,
115,15,218,4,0,
115,15,219,4,0,
115,15,237,4,0,
115,15,240,4,0,
115,15,241,4,0,
115,15,242,1,37,
115,15,247,4,0,
115,15,249,4,0,
115,15,252,1,7,
115,15,253,2,0,
115,15,258,4,0,
115,15,263,4,0,
115,15,264,2,0,
115,15,280,4,0,
115,15,283,2,0,
115,15,306,2,0,
115,15,317,4,0,
115,15,332,4,0,
115,15,359,2,0,
115,15,374,4,0,
115,15,376,2,0,
115,15,389,1,49,
115,15,411,4,0,
115,15,416,4,0,
115,15,421,4,0,
115,15,458,1,19,
115,15,496,4,0,
115,15,498,1,31,
115,15,509,2,0,
115,15,510,4,0,
115,15,514,4,0,
115,15,523,4,0,
115,15,590,4,0,
115,15,612,4,0,
115,16,4,1,1,1
115,16,5,1,25,1
115,16,7,3,0,
115,16,8,3,0,
115,16,9,3,0,
115,16,15,4,0,
115,16,23,2,0,
115,16,38,2,0,
115,16,39,1,10,1
115,16,43,1,1,2
115,16,44,1,13,1
115,16,46,4,0,
115,16,50,2,0,
115,16,53,4,0,
115,16,57,4,0,
115,16,58,4,0,
115,16,59,4,0,
115,16,63,4,0,
115,16,67,3,0,
115,16,68,2,0,
115,16,70,4,0,
115,16,76,4,0,
115,16,85,4,0,
115,16,87,4,0,
115,16,89,4,0,
115,16,91,4,0,
115,16,92,4,0,
115,16,99,1,22,1
115,16,104,4,0,
115,16,116,2,0,
115,16,126,4,0,
115,16,146,1,34,1
115,16,156,4,0,
115,16,157,4,0,
115,16,164,4,0,
115,16,168,4,0,
115,16,173,3,0,
115,16,179,1,50,1
115,16,180,3,0,
115,16,182,4,0,
115,16,193,2,0,
115,16,196,3,0,
115,16,200,1,46,1
115,16,200,3,0,
115,16,201,4,0,
115,16,203,1,43,1
115,16,207,4,0,
115,16,213,4,0,
115,16,214,4,0,
115,16,216,4,0,
115,16,218,4,0,
115,16,219,4,0,
115,16,231,3,0,
115,16,237,4,0,
115,16,240,4,0,
115,16,241,4,0,
115,16,242,1,37,1
115,16,247,4,0,
115,16,249,4,0,
115,16,252,1,7,1
115,16,253,2,0,
115,16,253,3,0,
115,16,258,4,0,
115,16,263,4,0,
115,16,264,2,0,
115,16,264,3,0,
115,16,270,3,0,
115,16,280,4,0,
115,16,283,2,0,
115,16,283,3,0,
115,16,290,4,0,
115,16,306,2,0,
115,16,317,4,0,
115,16,332,4,0,
115,16,343,3,0,
115,16,351,3,0,
115,16,352,3,0,
115,16,359,2,0,
115,16,374,4,0,
115,16,376,2,0,
115,16,389,1,49,1
115,16,401,3,0,
115,16,409,3,0,
115,16,411,4,0,
115,16,416,4,0,
115,16,421,4,0,
115,16,458,1,19,1
115,16,496,4,0,
115,16,498,1,31,1
115,16,509,2,0,
115,16,510,4,0,
115,16,514,4,0,
115,16,523,4,0,
115,16,590,4,0,
115,16,612,4,0,
115,17,4,1,1,1
115,17,5,1,25,
115,17,23,2,0,
115,17,38,2,0,
115,17,39,1,10,
115,17,43,1,1,2
115,17,44,1,13,
115,17,46,4,0,
115,17,50,2,0,
115,17,53,4,0,
115,17,57,4,0,
115,17,58,4,0,
115,17,59,4,0,
115,17,63,4,0,
115,17,68,2,0,
115,17,76,4,0,
115,17,85,4,0,
115,17,87,4,0,
115,17,89,4,0,
115,17,92,4,0,
115,17,99,1,22,
115,17,104,4,0,
115,17,116,2,0,
115,17,126,4,0,
115,17,146,1,34,
115,17,156,4,0,
115,17,157,4,0,
115,17,164,4,0,
115,17,168,4,0,
115,17,179,1,50,
115,17,182,4,0,
115,17,193,2,0,
115,17,200,1,46,
115,17,201,4,0,
115,17,203,1,43,
115,17,207,4,0,
115,17,213,4,0,
115,17,214,4,0,
115,17,216,4,0,
115,17,218,4,0,
115,17,219,4,0,
115,17,237,4,0,
115,17,240,4,0,
115,17,241,4,0,
115,17,242,1,37,
115,17,247,4,0,
115,17,252,1,7,
115,17,253,2,0,
115,17,258,4,0,
115,17,263,4,0,
115,17,264,2,0,
115,17,280,4,0,
115,17,283,2,0,
115,17,306,2,0,
115,17,317,4,0,
115,17,332,4,0,
115,17,359,2,0,
115,17,374,4,0,
115,17,376,2,0,
115,17,389,1,49,
115,17,411,4,0,
115,17,416,4,0,
115,17,421,4,0,
115,17,458,1,19,
115,17,496,4,0,
115,17,498,1,31,
115,17,509,2,0,
115,17,523,4,0,
115,17,526,4,0,
115,17,590,4,0,
115,18,4,1,1,1
115,18,5,1,25,
115,18,23,2,0,
115,18,38,2,0,
115,18,39,1,10,
115,18,43,1,1,2
115,18,44,1,13,
115,18,46,4,0,
115,18,50,2,0,
115,18,53,4,0,
115,18,57,4,0,
115,18,58,4,0,
115,18,59,4,0,
115,18,63,4,0,
115,18,68,2,0,
115,18,76,4,0,
115,18,85,4,0,
115,18,87,4,0,
115,18,89,4,0,
115,18,92,4,0,
115,18,99,1,22,
115,18,104,4,0,
115,18,116,2,0,
115,18,126,4,0,
115,18,146,1,34,
115,18,156,4,0,
115,18,157,4,0,
115,18,164,4,0,
115,18,168,4,0,
115,18,179,1,50,
115,18,182,4,0,
115,18,193,2,0,
115,18,200,1,46,
115,18,201,4,0,
115,18,203,1,43,
115,18,207,4,0,
115,18,213,4,0,
115,18,214,4,0,
115,18,216,4,0,
115,18,218,4,0,
115,18,219,4,0,
115,18,237,4,0,
115,18,240,4,0,
115,18,241,4,0,
115,18,242,1,37,
115,18,247,4,0,
115,18,252,1,7,
115,18,253,2,0,
115,18,258,4,0,
115,18,263,4,0,
115,18,264,2,0,
115,18,280,4,0,
115,18,283,2,0,
115,18,306,2,0,
115,18,317,4,0,
115,18,332,4,0,
115,18,359,2,0,
115,18,374,4,0,
115,18,376,2,0,
115,18,389,1,49,
115,18,411,4,0,
115,18,416,4,0,
115,18,421,4,0,
115,18,458,1,19,
115,18,496,4,0,
115,18,498,1,31,
115,18,509,2,0,
115,18,523,4,0,
115,18,526,4,0,
115,18,590,4,0,
116,1,36,4,0,
116,1,38,4,0,
116,1,43,1,24,
116,1,55,1,30,
116,1,55,4,0,
116,1,56,1,45,
116,1,57,4,0,
116,1,58,4,0,
116,1,59,4,0,
116,1,61,4,0,
116,1,92,4,0,
116,1,97,1,37,
116,1,99,4,0,
116,1,102,4,0,
116,1,104,4,0,
116,1,108,1,19,
116,1,117,4,0,
116,1,129,4,0,
116,1,130,4,0,
116,1,145,1,1,
116,1,156,4,0,
116,1,164,4,0,
116,2,36,4,0,
116,2,38,4,0,
116,2,43,1,24,
116,2,55,1,30,
116,2,55,4,0,
116,2,56,1,45,
116,2,57,4,0,
116,2,58,4,0,
116,2,59,4,0,
116,2,61,4,0,
116,2,92,4,0,
116,2,97,1,37,
116,2,99,4,0,
116,2,102,4,0,
116,2,104,4,0,
116,2,108,1,19,
116,2,117,4,0,
116,2,129,4,0,
116,2,130,4,0,
116,2,145,1,1,
116,2,156,4,0,
116,2,164,4,0,
116,3,29,4,0,
116,3,43,1,15,
116,3,50,2,0,
116,3,55,1,22,
116,3,56,1,43,
116,3,57,4,0,
116,3,59,4,0,
116,3,62,2,0,
116,3,82,2,0,
116,3,92,4,0,
116,3,97,1,36,
116,3,104,4,0,
116,3,108,1,8,
116,3,127,4,0,
116,3,129,4,0,
116,3,145,1,1,
116,3,150,2,0,
116,3,156,4,0,
116,3,173,4,0,
116,3,174,4,0,
116,3,175,2,0,
116,3,182,4,0,
116,3,190,2,0,
116,3,196,4,0,
116,3,203,4,0,
116,3,207,4,0,
116,3,213,4,0,
116,3,214,4,0,
116,3,216,4,0,
116,3,218,4,0,
116,3,225,4,0,
116,3,237,4,0,
116,3,239,1,29,
116,3,240,4,0,
116,3,250,4,0,
116,4,29,4,0,
116,4,43,1,15,
116,4,50,2,0,
116,4,55,1,22,
116,4,56,1,43,
116,4,57,4,0,
116,4,58,3,0,
116,4,59,4,0,
116,4,62,2,0,
116,4,82,2,0,
116,4,92,4,0,
116,4,97,1,36,
116,4,104,4,0,
116,4,108,1,8,
116,4,127,4,0,
116,4,129,4,0,
116,4,145,1,1,
116,4,150,2,0,
116,4,156,4,0,
116,4,173,4,0,
116,4,174,4,0,
116,4,175,2,0,
116,4,182,4,0,
116,4,190,2,0,
116,4,196,4,0,
116,4,203,4,0,
116,4,207,4,0,
116,4,213,4,0,
116,4,214,4,0,
116,4,216,4,0,
116,4,218,4,0,
116,4,225,4,0,
116,4,237,4,0,
116,4,239,1,29,
116,4,240,4,0,
116,4,250,4,0,
116,5,43,1,15,
116,5,50,2,0,
116,5,55,1,22,
116,5,56,1,43,
116,5,57,4,0,
116,5,58,4,0,
116,5,59,4,0,
116,5,62,2,0,
116,5,82,2,0,
116,5,92,4,0,
116,5,97,1,36,
116,5,104,4,0,
116,5,108,1,8,
116,5,127,4,0,
116,5,145,1,1,
116,5,150,2,0,
116,5,156,4,0,
116,5,175,2,0,
116,5,182,4,0,
116,5,190,2,0,
116,5,213,4,0,
116,5,216,4,0,
116,5,218,4,0,
116,5,225,2,0,
116,5,237,4,0,
116,5,239,1,29,
116,5,240,4,0,
116,5,258,4,0,
116,5,263,4,0,
116,5,290,4,0,
116,5,291,4,0,
116,5,349,1,50,
116,5,352,4,0,
116,6,38,3,0,
116,6,43,1,15,
116,6,50,2,0,
116,6,55,1,22,
116,6,56,1,43,
116,6,57,4,0,
116,6,58,4,0,
116,6,59,4,0,
116,6,62,2,0,
116,6,82,2,0,
116,6,92,4,0,
116,6,97,1,36,
116,6,102,3,0,
116,6,104,4,0,
116,6,108,1,8,
116,6,127,4,0,
116,6,129,3,0,
116,6,145,1,1,
116,6,150,2,0,
116,6,156,4,0,
116,6,164,3,0,
116,6,173,3,0,
116,6,175,2,0,
116,6,182,4,0,
116,6,190,2,0,
116,6,196,3,0,
116,6,203,3,0,
116,6,207,3,0,
116,6,213,4,0,
116,6,214,3,0,
116,6,216,4,0,
116,6,218,4,0,
116,6,225,2,0,
116,6,237,4,0,
116,6,239,1,29,
116,6,240,4,0,
116,6,258,4,0,
116,6,263,4,0,
116,6,290,4,0,
116,6,291,4,0,
116,6,349,1,50,
116,6,352,4,0,
116,7,38,3,0,
116,7,43,1,15,
116,7,50,2,0,
116,7,55,1,22,
116,7,56,1,43,
116,7,57,4,0,
116,7,58,4,0,
116,7,59,4,0,
116,7,62,2,0,
116,7,82,2,0,
116,7,92,4,0,
116,7,97,1,36,
116,7,102,3,0,
116,7,104,4,0,
116,7,108,1,8,
116,7,127,4,0,
116,7,145,1,1,
116,7,150,2,0,
116,7,156,4,0,
116,7,164,3,0,
116,7,175,2,0,
116,7,182,4,0,
116,7,190,2,0,
116,7,213,4,0,
116,7,216,4,0,
116,7,218,4,0,
116,7,225,2,0,
116,7,237,4,0,
116,7,239,1,29,
116,7,240,4,0,
116,7,258,4,0,
116,7,263,4,0,
116,7,290,4,0,
116,7,291,4,0,
116,7,349,1,50,
116,7,352,4,0,
116,8,43,1,8,
116,8,50,2,0,
116,8,55,1,11,
116,8,56,1,35,
116,8,57,4,0,
116,8,58,4,0,
116,8,59,4,0,
116,8,61,1,18,
116,8,62,2,0,
116,8,82,2,0,
116,8,92,4,0,
116,8,97,1,23,
116,8,104,4,0,
116,8,108,1,4,
116,8,116,1,14,
116,8,127,4,0,
116,8,145,1,1,
116,8,150,2,0,
116,8,156,4,0,
116,8,164,4,0,
116,8,175,2,0,
116,8,182,4,0,
116,8,190,2,0,
116,8,203,4,0,
116,8,207,4,0,
116,8,213,4,0,
116,8,214,4,0,
116,8,216,4,0,
116,8,218,4,0,
116,8,225,2,0,
116,8,237,4,0,
116,8,239,1,26,
116,8,240,4,0,
116,8,258,4,0,
116,8,263,4,0,
116,8,290,4,0,
116,8,324,2,0,
116,8,349,1,38,
116,8,352,4,0,
116,8,362,1,30,
116,8,362,4,0,
116,8,363,4,0,
116,8,406,1,42,
116,8,406,4,0,
116,8,430,4,0,
116,8,445,4,0,
116,9,43,1,8,
116,9,50,2,0,
116,9,55,1,11,
116,9,56,1,35,
116,9,57,4,0,
116,9,58,4,0,
116,9,59,4,0,
116,9,61,1,18,
116,9,62,2,0,
116,9,82,2,0,
116,9,92,4,0,
116,9,97,1,23,
116,9,104,4,0,
116,9,108,1,4,
116,9,116,1,14,
116,9,127,4,0,
116,9,129,3,0,
116,9,145,1,1,
116,9,150,2,0,
116,9,156,4,0,
116,9,164,4,0,
116,9,173,3,0,
116,9,175,2,0,
116,9,182,4,0,
116,9,190,2,0,
116,9,196,3,0,
116,9,200,3,0,
116,9,203,4,0,
116,9,207,4,0,
116,9,213,4,0,
116,9,214,4,0,
116,9,216,4,0,
116,9,218,4,0,
116,9,225,2,0,
116,9,237,4,0,
116,9,239,1,26,
116,9,239,3,0,
116,9,240,4,0,
116,9,258,4,0,
116,9,263,4,0,
116,9,290,4,0,
116,9,291,3,0,
116,9,324,2,0,
116,9,324,3,0,
116,9,340,3,0,
116,9,349,1,38,
116,9,352,4,0,
116,9,362,1,30,
116,9,362,4,0,
116,9,363,4,0,
116,9,406,1,42,
116,9,406,4,0,
116,9,430,4,0,
116,9,445,4,0,
116,10,13,2,0,
116,10,29,3,0,
116,10,43,1,8,
116,10,50,2,0,
116,10,55,1,11,
116,10,56,1,35,
116,10,57,4,0,
116,10,58,4,0,
116,10,59,4,0,
116,10,61,1,18,
116,10,62,2,0,
116,10,82,2,0,
116,10,92,4,0,
116,10,97,1,23,
116,10,104,4,0,
116,10,108,1,4,
116,10,116,1,14,
116,10,127,4,0,
116,10,129,3,0,
116,10,145,1,1,
116,10,150,2,0,
116,10,156,4,0,
116,10,164,4,0,
116,10,173,3,0,
116,10,175,2,0,
116,10,182,4,0,
116,10,190,2,0,
116,10,196,3,0,
116,10,200,3,0,
116,10,203,4,0,
116,10,207,4,0,
116,10,213,4,0,
116,10,214,4,0,
116,10,216,4,0,
116,10,218,4,0,
116,10,225,2,0,
116,10,237,4,0,
116,10,239,1,26,
116,10,239,3,0,
116,10,240,4,0,
116,10,250,4,0,
116,10,258,4,0,
116,10,263,4,0,
116,10,290,4,0,
116,10,291,3,0,
116,10,324,2,0,
116,10,324,3,0,
116,10,330,2,0,
116,10,340,3,0,
116,10,349,1,38,
116,10,352,4,0,
116,10,362,1,30,
116,10,362,4,0,
116,10,363,4,0,
116,10,406,1,42,
116,10,406,4,0,
116,10,430,4,0,
116,10,445,4,0,
116,11,13,2,0,
116,11,43,1,8,
116,11,50,2,0,
116,11,55,1,11,
116,11,56,1,35,
116,11,57,4,0,
116,11,58,4,0,
116,11,59,4,0,
116,11,61,1,18,
116,11,62,2,0,
116,11,82,2,0,
116,11,92,4,0,
116,11,97,1,23,
116,11,104,4,0,
116,11,108,1,4,
116,11,116,1,14,
116,11,127,4,0,
116,11,145,1,1,
116,11,150,2,0,
116,11,156,4,0,
116,11,164,4,0,
116,11,175,2,0,
116,11,182,4,0,
116,11,190,2,0,
116,11,200,2,0,
116,11,207,4,0,
116,11,213,4,0,
116,11,216,4,0,
116,11,218,4,0,
116,11,225,2,0,
116,11,237,4,0,
116,11,239,1,26,
116,11,240,4,0,
116,11,258,4,0,
116,11,263,4,0,
116,11,291,4,0,
116,11,324,2,0,
116,11,330,2,0,
116,11,349,1,38,
116,11,352,2,0,
116,11,362,1,30,
116,11,406,1,42,
116,11,430,4,0,
116,11,496,4,0,
116,11,499,2,0,
116,11,503,4,0,
116,12,43,1,15,
116,12,55,1,22,
116,12,56,1,43,
116,12,57,4,0,
116,12,58,4,0,
116,12,59,4,0,
116,12,92,4,0,
116,12,97,1,36,
116,12,104,4,0,
116,12,108,1,8,
116,12,127,4,0,
116,12,145,1,1,
116,12,156,4,0,
116,12,182,4,0,
116,12,213,4,0,
116,12,216,4,0,
116,12,218,4,0,
116,12,237,4,0,
116,12,239,1,29,
116,12,240,4,0,
116,12,258,4,0,
116,12,263,4,0,
116,12,290,4,0,
116,12,291,4,0,
116,12,349,1,50,
116,12,352,4,0,
116,13,38,3,0,
116,13,43,1,15,
116,13,55,1,22,
116,13,56,1,43,
116,13,57,4,0,
116,13,58,4,0,
116,13,59,4,0,
116,13,92,4,0,
116,13,97,1,36,
116,13,102,3,0,
116,13,104,4,0,
116,13,108,1,8,
116,13,127,4,0,
116,13,145,1,1,
116,13,156,4,0,
116,13,164,3,0,
116,13,182,4,0,
116,13,196,3,0,
116,13,207,3,0,
116,13,213,4,0,
116,13,216,4,0,
116,13,218,4,0,
116,13,237,4,0,
116,13,239,1,29,
116,13,240,4,0,
116,13,258,4,0,
116,13,263,4,0,
116,13,290,4,0,
116,13,291,4,0,
116,13,349,1,50,
116,13,352,4,0,
116,14,13,2,0,
116,14,43,1,8,
116,14,50,2,0,
116,14,55,1,11,
116,14,56,1,35,
116,14,57,4,0,
116,14,58,4,0,
116,14,59,4,0,
116,14,61,1,18,
116,14,62,2,0,
116,14,82,2,0,
116,14,92,4,0,
116,14,97,1,23,
116,14,104,4,0,
116,14,108,1,4,
116,14,116,1,14,
116,14,127,4,0,
116,14,145,1,1,
116,14,150,2,0,
116,14,156,4,0,
116,14,164,4,0,
116,14,173,3,0,
116,14,175,2,0,
116,14,182,4,0,
116,14,190,2,0,
116,14,196,3,0,
116,14,200,2,0,
116,14,200,3,0,
116,14,207,4,0,
116,14,213,4,0,
116,14,214,3,0,
116,14,216,4,0,
116,14,218,4,0,
116,14,225,2,0,
116,14,237,4,0,
116,14,239,1,26,
116,14,240,4,0,
116,14,258,4,0,
116,14,263,4,0,
116,14,291,4,0,
116,14,324,2,0,
116,14,324,3,0,
116,14,330,2,0,
116,14,340,3,0,
116,14,349,1,38,
116,14,352,2,0,
116,14,362,1,30,
116,14,406,1,42,
116,14,406,3,0,
116,14,430,4,0,
116,14,496,4,0,
116,14,499,2,0,
116,14,503,4,0,
116,15,13,2,0,
116,15,43,1,8,
116,15,50,2,0,
116,15,55,1,1,
116,15,56,1,35,
116,15,57,4,0,
116,15,58,4,0,
116,15,59,4,0,
116,15,61,1,18,
116,15,62,2,0,
116,15,82,2,0,
116,15,92,4,0,
116,15,97,1,23,
116,15,104,4,0,
116,15,108,1,4,
116,15,116,1,14,
116,15,127,4,0,
116,15,145,1,11,
116,15,150,2,0,
116,15,156,4,0,
116,15,164,4,0,
116,15,175,2,0,
116,15,182,4,0,
116,15,190,2,0,
116,15,200,2,0,
116,15,207,4,0,
116,15,213,4,0,
116,15,214,4,0,
116,15,216,4,0,
116,15,218,4,0,
116,15,225,2,0,
116,15,237,4,0,
116,15,239,1,26,
116,15,240,4,0,
116,15,258,4,0,
116,15,263,4,0,
116,15,324,2,0,
116,15,330,2,0,
116,15,349,1,38,
116,15,352,2,0,
116,15,362,1,30,
116,15,406,1,42,
116,15,430,4,0,
116,15,496,4,0,
116,15,499,2,0,
116,15,503,4,0,
116,15,590,4,0,
116,16,13,2,0,
116,16,43,1,9,1
116,16,50,2,0,
116,16,55,1,13,1
116,16,56,1,52,1
116,16,57,4,0,
116,16,58,4,0,
116,16,59,4,0,
116,16,61,1,21,1
116,16,62,2,0,
116,16,82,2,0,
116,16,92,4,0,
116,16,97,1,36,1
116,16,104,4,0,
116,16,108,1,5,1
116,16,116,1,26,1
116,16,127,4,0,
116,16,145,1,1,1
116,16,150,2,0,
116,16,156,4,0,
116,16,164,4,0,
116,16,173,3,0,
116,16,175,2,0,
116,16,182,4,0,
116,16,190,2,0,
116,16,196,3,0,
116,16,200,2,0,
116,16,200,3,0,
116,16,207,4,0,
116,16,213,4,0,
116,16,214,4,0,
116,16,216,4,0,
116,16,218,4,0,
116,16,225,2,0,
116,16,237,4,0,
116,16,239,1,17,1
116,16,240,4,0,
116,16,258,4,0,
116,16,263,4,0,
116,16,290,4,0,
116,16,291,4,0,
116,16,324,2,0,
116,16,324,3,0,
116,16,330,2,0,
116,16,340,3,0,
116,16,349,1,46,1
116,16,352,2,0,
116,16,352,3,0,
116,16,362,1,31,1
116,16,406,1,41,1
116,16,406,3,0,
116,16,430,4,0,
116,16,496,4,0,
116,16,499,2,0,
116,16,503,4,0,
116,16,590,4,0,
116,17,13,2,0,
116,17,43,1,9,
116,17,50,2,0,
116,17,55,1,13,
116,17,56,1,52,
116,17,57,4,0,
116,17,58,4,0,
116,17,59,4,0,
116,17,61,1,21,
116,17,62,2,0,
116,17,82,2,0,
116,17,92,4,0,
116,17,97,1,36,
116,17,104,4,0,
116,17,108,1,5,
116,17,116,1,26,
116,17,127,4,0,
116,17,145,1,1,
116,17,150,2,0,
116,17,156,4,0,
116,17,164,4,0,
116,17,175,2,0,
116,17,182,4,0,
116,17,190,2,0,
116,17,200,2,0,
116,17,207,4,0,
116,17,213,4,0,
116,17,214,4,0,
116,17,216,4,0,
116,17,218,4,0,
116,17,225,2,0,
116,17,237,4,0,
116,17,239,1,17,
116,17,240,4,0,
116,17,258,4,0,
116,17,263,4,0,
116,17,324,2,0,
116,17,330,2,0,
116,17,349,1,46,
116,17,352,2,0,
116,17,362,1,31,
116,17,406,1,41,
116,17,430,4,0,
116,17,496,4,0,
116,17,499,2,0,
116,17,503,4,0,
116,17,590,4,0,
116,18,13,2,0,
116,18,43,1,9,
116,18,50,2,0,
116,18,55,1,13,
116,18,56,1,52,
116,18,57,4,0,
116,18,58,4,0,
116,18,59,4,0,
116,18,61,1,21,
116,18,62,2,0,
116,18,82,2,0,
116,18,92,4,0,
116,18,97,1,36,
116,18,104,4,0,
116,18,108,1,5,
116,18,116,1,26,
116,18,127,4,0,
116,18,145,1,1,
116,18,150,2,0,
116,18,156,4,0,
116,18,164,4,0,
116,18,175,2,0,
116,18,182,4,0,
116,18,190,2,0,
116,18,200,2,0,
116,18,207,4,0,
116,18,213,4,0,
116,18,214,4,0,
116,18,216,4,0,
116,18,218,4,0,
116,18,225,2,0,
116,18,237,4,0,
116,18,239,1,17,
116,18,240,4,0,
116,18,258,4,0,
116,18,263,4,0,
116,18,324,2,0,
116,18,330,2,0,
116,18,349,1,46,
116,18,352,2,0,
116,18,362,1,31,
116,18,406,1,41,
116,18,430,4,0,
116,18,496,4,0,
116,18,499,2,0,
116,18,503,4,0,
116,18,590,4,0,
117,1,36,4,0,
117,1,38,4,0,
117,1,43,1,24,
117,1,55,1,30,
117,1,55,4,0,
117,1,56,1,52,
117,1,57,4,0,
117,1,58,4,0,
117,1,59,4,0,
117,1,61,4,0,
117,1,63,4,0,
117,1,92,4,0,
117,1,97,1,41,
117,1,99,4,0,
117,1,102,4,0,
117,1,104,4,0,
117,1,108,1,1,2
117,1,108,1,19,
117,1,117,4,0,
117,1,129,4,0,
117,1,130,4,0,
117,1,145,1,1,1
117,1,156,4,0,
117,1,164,4,0,
117,2,36,4,0,
117,2,38,4,0,
117,2,43,1,24,
117,2,55,1,30,
117,2,55,4,0,
117,2,56,1,52,
117,2,57,4,0,
117,2,58,4,0,
117,2,59,4,0,
117,2,61,4,0,
117,2,63,4,0,
117,2,92,4,0,
117,2,97,1,41,
117,2,99,4,0,
117,2,102,4,0,
117,2,104,4,0,
117,2,108,1,1,2
117,2,108,1,19,
117,2,117,4,0,
117,2,129,4,0,
117,2,130,4,0,
117,2,145,1,1,1
117,2,156,4,0,
117,2,164,4,0,
117,3,29,4,0,
117,3,43,1,1,3
117,3,43,1,15,
117,3,55,1,1,4
117,3,55,1,22,
117,3,56,1,51,
117,3,57,4,0,
117,3,59,4,0,
117,3,63,4,0,
117,3,92,4,0,
117,3,97,1,40,
117,3,104,4,0,
117,3,108,1,1,2
117,3,108,1,8,
117,3,127,4,0,
117,3,129,4,0,
117,3,145,1,1,1
117,3,156,4,0,
117,3,173,4,0,
117,3,174,4,0,
117,3,182,4,0,
117,3,196,4,0,
117,3,203,4,0,
117,3,207,4,0,
117,3,213,4,0,
117,3,214,4,0,
117,3,216,4,0,
117,3,218,4,0,
117,3,225,4,0,
117,3,237,4,0,
117,3,239,1,29,
117,3,240,4,0,
117,3,250,4,0,
117,4,29,4,0,
117,4,43,1,1,3
117,4,43,1,15,
117,4,55,1,1,4
117,4,55,1,22,
117,4,56,1,51,
117,4,57,4,0,
117,4,58,3,0,
117,4,59,4,0,
117,4,63,4,0,
117,4,92,4,0,
117,4,97,1,40,
117,4,104,4,0,
117,4,108,1,1,2
117,4,108,1,8,
117,4,127,4,0,
117,4,129,4,0,
117,4,145,1,1,1
117,4,156,4,0,
117,4,173,4,0,
117,4,174,4,0,
117,4,182,4,0,
117,4,196,4,0,
117,4,203,4,0,
117,4,207,4,0,
117,4,213,4,0,
117,4,214,4,0,
117,4,216,4,0,
117,4,218,4,0,
117,4,225,4,0,
117,4,237,4,0,
117,4,239,1,29,
117,4,240,4,0,
117,4,250,4,0,
117,5,43,1,1,3
117,5,43,1,15,
117,5,55,1,1,4
117,5,55,1,22,
117,5,56,1,51,
117,5,57,4,0,
117,5,58,4,0,
117,5,59,4,0,
117,5,63,4,0,
117,5,92,4,0,
117,5,97,1,40,
117,5,104,4,0,
117,5,108,1,1,2
117,5,108,1,8,
117,5,127,4,0,
117,5,145,1,1,1
117,5,156,4,0,
117,5,182,4,0,
117,5,213,4,0,
117,5,216,4,0,
117,5,218,4,0,
117,5,237,4,0,
117,5,239,1,29,
117,5,240,4,0,
117,5,258,4,0,
117,5,263,4,0,
117,5,290,4,0,
117,5,291,4,0,
117,5,349,1,62,
117,5,352,4,0,
117,6,38,3,0,
117,6,43,1,1,3
117,6,43,1,15,
117,6,55,1,1,4
117,6,55,1,22,
117,6,56,1,51,
117,6,57,4,0,
117,6,58,4,0,
117,6,59,4,0,
117,6,63,4,0,
117,6,92,4,0,
117,6,97,1,40,
117,6,102,3,0,
117,6,104,4,0,
117,6,108,1,1,2
117,6,108,1,8,
117,6,127,4,0,
117,6,129,3,0,
117,6,145,1,1,1
117,6,156,4,0,
117,6,164,3,0,
117,6,173,3,0,
117,6,182,4,0,
117,6,196,3,0,
117,6,203,3,0,
117,6,207,3,0,
117,6,213,4,0,
117,6,214,3,0,
117,6,216,4,0,
117,6,218,4,0,
117,6,237,4,0,
117,6,239,1,29,
117,6,240,4,0,
117,6,258,4,0,
117,6,263,4,0,
117,6,290,4,0,
117,6,291,4,0,
117,6,349,1,62,
117,6,352,4,0,
117,7,38,3,0,
117,7,43,1,1,3
117,7,43,1,15,
117,7,55,1,1,4
117,7,55,1,22,
117,7,56,1,51,
117,7,57,4,0,
117,7,58,4,0,
117,7,59,4,0,
117,7,63,4,0,
117,7,92,4,0,
117,7,97,1,40,
117,7,102,3,0,
117,7,104,4,0,
117,7,108,1,1,2
117,7,108,1,8,
117,7,127,4,0,
117,7,145,1,1,1
117,7,156,4,0,
117,7,164,3,0,
117,7,182,4,0,
117,7,213,4,0,
117,7,216,4,0,
117,7,218,4,0,
117,7,237,4,0,
117,7,239,1,29,
117,7,240,4,0,
117,7,258,4,0,
117,7,263,4,0,
117,7,290,4,0,
117,7,291,4,0,
117,7,349,1,62,
117,7,352,4,0,
117,8,43,1,1,3
117,8,43,1,8,
117,8,55,1,1,4
117,8,55,1,11,
117,8,56,1,40,
117,8,57,4,0,
117,8,58,4,0,
117,8,59,4,0,
117,8,61,1,18,
117,8,63,4,0,
117,8,92,4,0,
117,8,97,1,23,
117,8,104,4,0,
117,8,108,1,1,2
117,8,108,1,4,
117,8,116,1,14,
117,8,127,4,0,
117,8,145,1,1,1
117,8,156,4,0,
117,8,164,4,0,
117,8,182,4,0,
117,8,203,4,0,
117,8,207,4,0,
117,8,213,4,0,
117,8,214,4,0,
117,8,216,4,0,
117,8,218,4,0,
117,8,237,4,0,
117,8,239,1,26,
117,8,240,4,0,
117,8,258,4,0,
117,8,263,4,0,
117,8,290,4,0,
117,8,349,1,48,
117,8,352,4,0,
117,8,362,1,30,
117,8,362,4,0,
117,8,363,4,0,
117,8,406,1,57,
117,8,406,4,0,
117,8,416,4,0,
117,8,430,4,0,
117,8,445,4,0,
117,9,43,1,1,3
117,9,43,1,8,
117,9,55,1,1,4
117,9,55,1,11,
117,9,56,1,40,
117,9,57,4,0,
117,9,58,4,0,
117,9,59,4,0,
117,9,61,1,18,
117,9,63,4,0,
117,9,92,4,0,
117,9,97,1,23,
117,9,104,4,0,
117,9,108,1,1,2
117,9,108,1,4,
117,9,116,1,14,
117,9,127,4,0,
117,9,129,3,0,
117,9,145,1,1,1
117,9,156,4,0,
117,9,164,4,0,
117,9,173,3,0,
117,9,182,4,0,
117,9,196,3,0,
117,9,200,3,0,
117,9,203,4,0,
117,9,207,4,0,
117,9,213,4,0,
117,9,214,4,0,
117,9,216,4,0,
117,9,218,4,0,
117,9,237,4,0,
117,9,239,1,26,
117,9,239,3,0,
117,9,240,4,0,
117,9,258,4,0,
117,9,263,4,0,
117,9,290,4,0,
117,9,291,3,0,
117,9,324,3,0,
117,9,340,3,0,
117,9,349,1,48,
117,9,352,4,0,
117,9,362,1,30,
117,9,362,4,0,
117,9,363,4,0,
117,9,406,1,57,
117,9,406,4,0,
117,9,416,4,0,
117,9,430,4,0,
117,9,445,4,0,
117,10,29,3,0,
117,10,43,1,1,3
117,10,43,1,8,
117,10,55,1,1,4
117,10,55,1,11,
117,10,56,1,40,
117,10,57,4,0,
117,10,58,4,0,
117,10,59,4,0,
117,10,61,1,18,
117,10,63,4,0,
117,10,92,4,0,
117,10,97,1,23,
117,10,104,4,0,
117,10,108,1,1,2
117,10,108,1,4,
117,10,116,1,14,
117,10,127,4,0,
117,10,129,3,0,
117,10,145,1,1,1
117,10,156,4,0,
117,10,164,4,0,
117,10,173,3,0,
117,10,182,4,0,
117,10,196,3,0,
117,10,200,3,0,
117,10,203,4,0,
117,10,207,4,0,
117,10,213,4,0,
117,10,214,4,0,
117,10,216,4,0,
117,10,218,4,0,
117,10,237,4,0,
117,10,239,1,26,
117,10,239,3,0,
117,10,240,4,0,
117,10,250,4,0,
117,10,258,4,0,
117,10,263,4,0,
117,10,290,4,0,
117,10,291,3,0,
117,10,324,3,0,
117,10,340,3,0,
117,10,349,1,48,
117,10,352,4,0,
117,10,362,1,30,
117,10,362,4,0,
117,10,363,4,0,
117,10,406,1,57,
117,10,406,4,0,
117,10,416,4,0,
117,10,430,4,0,
117,10,445,4,0,
117,11,43,1,1,3
117,11,43,1,8,
117,11,55,1,1,4
117,11,55,1,11,
117,11,56,1,40,
117,11,57,4,0,
117,11,58,4,0,
117,11,59,4,0,
117,11,61,1,18,
117,11,63,4,0,
117,11,92,4,0,
117,11,97,1,23,
117,11,104,4,0,
117,11,108,1,1,2
117,11,108,1,4,
117,11,116,1,14,
117,11,127,4,0,
117,11,145,1,1,1
117,11,156,4,0,
117,11,164,4,0,
117,11,182,4,0,
117,11,207,4,0,
117,11,213,4,0,
117,11,216,4,0,
117,11,218,4,0,
117,11,237,4,0,
117,11,239,1,26,
117,11,240,4,0,
117,11,258,4,0,
117,11,263,4,0,
117,11,291,4,0,
117,11,349,1,48,
117,11,362,1,30,
117,11,406,1,57,
117,11,416,4,0,
117,11,430,4,0,
117,11,496,4,0,
117,11,503,4,0,
117,12,43,1,1,3
117,12,43,1,15,
117,12,55,1,1,4
117,12,55,1,22,
117,12,56,1,51,
117,12,57,4,0,
117,12,58,4,0,
117,12,59,4,0,
117,12,63,4,0,
117,12,92,4,0,
117,12,97,1,40,
117,12,104,4,0,
117,12,108,1,1,2
117,12,108,1,8,
117,12,127,4,0,
117,12,145,1,1,1
117,12,156,4,0,
117,12,182,4,0,
117,12,213,4,0,
117,12,216,4,0,
117,12,218,4,0,
117,12,237,4,0,
117,12,239,1,29,
117,12,240,4,0,
117,12,258,4,0,
117,12,263,4,0,
117,12,290,4,0,
117,12,291,4,0,
117,12,349,1,62,
117,12,352,4,0,
117,13,38,3,0,
117,13,43,1,1,3
117,13,43,1,15,
117,13,55,1,1,4
117,13,55,1,22,
117,13,56,1,51,
117,13,57,4,0,
117,13,58,4,0,
117,13,59,4,0,
117,13,63,4,0,
117,13,92,4,0,
117,13,97,1,40,
117,13,102,3,0,
117,13,104,4,0,
117,13,108,1,1,2
117,13,108,1,8,
117,13,127,4,0,
117,13,145,1,1,1
117,13,156,4,0,
117,13,164,3,0,
117,13,182,4,0,
117,13,196,3,0,
117,13,207,3,0,
117,13,213,4,0,
117,13,216,4,0,
117,13,218,4,0,
117,13,237,4,0,
117,13,239,1,29,
117,13,240,4,0,
117,13,258,4,0,
117,13,263,4,0,
117,13,290,4,0,
117,13,291,4,0,
117,13,349,1,62,
117,13,352,4,0,
117,14,43,1,1,3
117,14,43,1,8,
117,14,55,1,1,4
117,14,55,1,11,
117,14,56,1,40,
117,14,57,4,0,
117,14,58,4,0,
117,14,59,4,0,
117,14,61,1,18,
117,14,63,4,0,
117,14,92,4,0,
117,14,97,1,23,
117,14,104,4,0,
117,14,108,1,1,2
117,14,108,1,4,
117,14,116,1,14,
117,14,127,4,0,
117,14,145,1,1,1
117,14,156,4,0,
117,14,164,4,0,
117,14,173,3,0,
117,14,182,4,0,
117,14,196,3,0,
117,14,200,3,0,
117,14,207,4,0,
117,14,213,4,0,
117,14,214,3,0,
117,14,216,4,0,
117,14,218,4,0,
117,14,237,4,0,
117,14,239,1,26,
117,14,240,4,0,
117,14,258,4,0,
117,14,263,4,0,
117,14,291,4,0,
117,14,324,3,0,
117,14,340,3,0,
117,14,349,1,48,
117,14,362,1,30,
117,14,406,1,57,
117,14,406,3,0,
117,14,416,4,0,
117,14,430,4,0,
117,14,496,4,0,
117,14,503,4,0,
117,15,43,1,1,3
117,15,43,1,8,
117,15,55,1,1,1
117,15,56,1,40,
117,15,57,4,0,
117,15,58,4,0,
117,15,59,4,0,
117,15,61,1,18,
117,15,63,4,0,
117,15,92,4,0,
117,15,97,1,23,
117,15,104,4,0,
117,15,108,1,1,2
117,15,108,1,4,
117,15,116,1,14,
117,15,127,4,0,
117,15,145,1,1,4
117,15,145,1,11,
117,15,156,4,0,
117,15,164,4,0,
117,15,182,4,0,
117,15,207,4,0,
117,15,213,4,0,
117,15,214,4,0,
117,15,216,4,0,
117,15,218,4,0,
117,15,237,4,0,
117,15,239,1,26,
117,15,240,4,0,
117,15,258,4,0,
117,15,263,4,0,
117,15,349,1,48,
117,15,362,1,30,
117,15,406,1,57,
117,15,416,4,0,
117,15,430,4,0,
117,15,496,4,0,
117,15,503,4,0,
117,15,590,4,0,
117,16,43,1,1,4
117,16,43,1,9,1
117,16,55,1,1,5
117,16,55,1,13,1
117,16,56,1,1,1
117,16,56,1,60,1
117,16,57,4,0,
117,16,58,4,0,
117,16,59,4,0,
117,16,61,1,21,1
117,16,63,4,0,
117,16,92,4,0,
117,16,97,1,38,1
117,16,104,4,0,
117,16,108,1,1,3
117,16,108,1,5,1
117,16,116,1,26,1
117,16,127,4,0,
117,16,145,1,1,2
117,16,156,4,0,
117,16,164,4,0,
117,16,173,3,0,
117,16,182,4,0,
117,16,196,3,0,
117,16,200,3,0,
117,16,207,4,0,
117,16,213,4,0,
117,16,214,4,0,
117,16,216,4,0,
117,16,218,4,0,
117,16,237,4,0,
117,16,239,1,17,1
117,16,240,4,0,
117,16,258,4,0,
117,16,263,4,0,
117,16,290,4,0,
117,16,291,4,0,
117,16,324,3,0,
117,16,340,3,0,
117,16,349,1,52,1
117,16,352,3,0,
117,16,362,1,31,1
117,16,406,1,45,1
117,16,406,3,0,
117,16,416,4,0,
117,16,430,4,0,
117,16,496,4,0,
117,16,503,4,0,
117,16,590,4,0,
117,17,43,1,1,4
117,17,43,1,9,
117,17,55,1,1,5
117,17,55,1,13,
117,17,56,1,1,1
117,17,56,1,60,
117,17,57,4,0,
117,17,58,4,0,
117,17,59,4,0,
117,17,61,1,21,
117,17,63,4,0,
117,17,92,4,0,
117,17,97,1,38,
117,17,104,4,0,
117,17,108,1,1,3
117,17,108,1,5,
117,17,116,1,26,
117,17,127,4,0,
117,17,145,1,1,2
117,17,156,4,0,
117,17,164,4,0,
117,17,182,4,0,
117,17,207,4,0,
117,17,213,4,0,
117,17,214,4,0,
117,17,216,4,0,
117,17,218,4,0,
117,17,237,4,0,
117,17,239,1,17,
117,17,240,4,0,
117,17,258,4,0,
117,17,263,4,0,
117,17,349,1,52,
117,17,362,1,31,
117,17,406,1,45,
117,17,416,4,0,
117,17,430,4,0,
117,17,496,4,0,
117,17,503,4,0,
117,17,590,4,0,
117,18,43,1,1,4
117,18,43,1,9,
117,18,55,1,1,5
117,18,55,1,13,
117,18,56,1,1,1
117,18,56,1,60,
117,18,57,4,0,
117,18,58,4,0,
117,18,59,4,0,
117,18,61,1,21,
117,18,63,4,0,
117,18,92,4,0,
117,18,97,1,38,
117,18,104,4,0,
117,18,108,1,1,3
117,18,108,1,5,
117,18,116,1,26,
117,18,127,4,0,
117,18,145,1,1,2
117,18,156,4,0,
117,18,164,4,0,
117,18,182,4,0,
117,18,207,4,0,
117,18,213,4,0,
117,18,214,4,0,
117,18,216,4,0,
117,18,218,4,0,
117,18,237,4,0,
117,18,239,1,17,
117,18,240,4,0,
117,18,258,4,0,
117,18,263,4,0,
117,18,349,1,52,
117,18,362,1,31,
117,18,406,1,45,
117,18,416,4,0,
117,18,430,4,0,
117,18,496,4,0,
117,18,503,4,0,
117,18,590,4,0,
118,1,30,1,24,
118,1,31,1,30,
118,1,32,1,45,
118,1,32,4,0,
118,1,36,4,0,
118,1,38,4,0,
118,1,39,1,1,2
118,1,48,1,19,
118,1,55,4,0,
118,1,57,4,0,
118,1,58,4,0,
118,1,59,4,0,
118,1,61,4,0,
118,1,64,1,1,1
118,1,92,4,0,
118,1,97,1,54,
118,1,99,4,0,
118,1,102,4,0,
118,1,104,4,0,
118,1,117,4,0,
118,1,127,1,37,
118,1,129,4,0,
118,1,130,4,0,
118,1,156,4,0,
118,1,164,4,0,
118,2,30,1,24,
118,2,31,1,30,
118,2,32,1,45,
118,2,32,4,0,
118,2,36,4,0,
118,2,38,4,0,
118,2,39,1,1,2
118,2,48,1,19,
118,2,55,4,0,
118,2,57,4,0,
118,2,58,4,0,
118,2,59,4,0,
118,2,61,4,0,
118,2,64,1,1,1
118,2,92,4,0,
118,2,97,1,54,
118,2,99,4,0,
118,2,102,4,0,
118,2,104,4,0,
118,2,117,4,0,
118,2,127,1,37,
118,2,129,4,0,
118,2,130,4,0,
118,2,156,4,0,
118,2,164,4,0,
118,3,30,1,15,
118,3,31,1,29,
118,3,32,1,43,
118,3,39,1,1,2
118,3,48,1,10,
118,3,56,2,0,
118,3,57,4,0,
118,3,59,4,0,
118,3,60,2,0,
118,3,64,1,1,1
118,3,92,4,0,
118,3,97,1,52,
118,3,104,4,0,
118,3,114,2,0,
118,3,127,1,38,
118,3,127,4,0,
118,3,129,4,0,
118,3,156,4,0,
118,3,173,4,0,
118,3,174,4,0,
118,3,175,1,24,
118,3,182,4,0,
118,3,196,4,0,
118,3,203,4,0,
118,3,207,4,0,
118,3,213,4,0,
118,3,214,4,0,
118,3,216,4,0,
118,3,218,4,0,
118,3,237,4,0,
118,3,240,4,0,
118,4,30,1,15,
118,4,31,1,29,
118,4,32,1,43,
118,4,39,1,1,2
118,4,48,1,10,
118,4,56,2,0,
118,4,57,4,0,
118,4,58,3,0,
118,4,59,4,0,
118,4,60,2,0,
118,4,64,1,1,1
118,4,92,4,0,
118,4,97,1,52,
118,4,104,4,0,
118,4,114,2,0,
118,4,127,1,38,
118,4,127,4,0,
118,4,129,4,0,
118,4,156,4,0,
118,4,173,4,0,
118,4,174,4,0,
118,4,175,1,24,
118,4,182,4,0,
118,4,196,4,0,
118,4,203,4,0,
118,4,207,4,0,
118,4,213,4,0,
118,4,214,4,0,
118,4,216,4,0,
118,4,218,4,0,
118,4,237,4,0,
118,4,240,4,0,
118,5,30,1,15,
118,5,31,1,29,
118,5,32,1,43,
118,5,39,1,1,2
118,5,48,1,10,
118,5,56,2,0,
118,5,57,4,0,
118,5,58,4,0,
118,5,59,4,0,
118,5,60,2,0,
118,5,64,1,1,1
118,5,92,4,0,
118,5,97,1,52,
118,5,104,4,0,
118,5,114,2,0,
118,5,127,1,38,
118,5,127,4,0,
118,5,156,4,0,
118,5,175,1,24,
118,5,182,4,0,
118,5,213,4,0,
118,5,214,2,0,
118,5,216,4,0,
118,5,218,4,0,
118,5,237,4,0,
118,5,240,4,0,
118,5,258,4,0,
118,5,263,4,0,
118,5,290,4,0,
118,5,291,4,0,
118,5,300,2,0,
118,5,346,1,1,3
118,5,352,4,0,
118,6,30,1,15,
118,6,31,1,29,
118,6,32,1,43,
118,6,38,3,0,
118,6,39,1,1,2
118,6,48,1,10,
118,6,56,2,0,
118,6,57,4,0,
118,6,58,4,0,
118,6,59,4,0,
118,6,60,2,0,
118,6,64,1,1,1
118,6,92,4,0,
118,6,97,1,52,
118,6,102,3,0,
118,6,104,4,0,
118,6,114,2,0,
118,6,127,1,38,
118,6,127,4,0,
118,6,129,3,0,
118,6,156,4,0,
118,6,164,3,0,
118,6,173,3,0,
118,6,175,1,24,
118,6,182,4,0,
118,6,196,3,0,
118,6,203,3,0,
118,6,207,3,0,
118,6,213,4,0,
118,6,214,2,0,
118,6,214,3,0,
118,6,216,4,0,
118,6,218,4,0,
118,6,237,4,0,
118,6,240,4,0,
118,6,258,4,0,
118,6,263,4,0,
118,6,290,4,0,
118,6,291,4,0,
118,6,300,2,0,
118,6,346,1,1,3
118,6,352,4,0,
118,7,30,1,15,
118,7,31,1,29,
118,7,32,1,43,
118,7,38,3,0,
118,7,39,1,1,2
118,7,48,1,10,
118,7,56,2,0,
118,7,57,4,0,
118,7,58,4,0,
118,7,59,4,0,
118,7,60,2,0,
118,7,64,1,1,1
118,7,92,4,0,
118,7,97,1,52,
118,7,102,3,0,
118,7,104,4,0,
118,7,114,2,0,
118,7,127,1,38,
118,7,127,4,0,
118,7,156,4,0,
118,7,164,3,0,
118,7,175,1,24,
118,7,182,4,0,
118,7,213,4,0,
118,7,214,2,0,
118,7,216,4,0,
118,7,218,4,0,
118,7,224,1,57,
118,7,237,4,0,
118,7,240,4,0,
118,7,258,4,0,
118,7,263,4,0,
118,7,290,4,0,
118,7,291,4,0,
118,7,300,2,0,
118,7,346,1,1,3
118,7,352,4,0,
118,8,30,1,11,
118,8,31,1,31,
118,8,32,1,41,
118,8,39,1,1,2
118,8,48,1,7,
118,8,56,2,0,
118,8,57,4,0,
118,8,58,4,0,
118,8,59,4,0,
118,8,60,2,0,
118,8,64,1,1,1
118,8,92,4,0,
118,8,97,1,47,
118,8,104,4,0,
118,8,114,2,0,
118,8,127,1,37,
118,8,127,4,0,
118,8,156,4,0,
118,8,164,4,0,
118,8,175,1,21,
118,8,182,4,0,
118,8,189,2,0,
118,8,203,4,0,
118,8,207,4,0,
118,8,213,4,0,
118,8,214,2,0,
118,8,214,4,0,
118,8,216,4,0,
118,8,218,4,0,
118,8,224,1,51,
118,8,237,4,0,
118,8,240,4,0,
118,8,258,4,0,
118,8,263,4,0,
118,8,290,4,0,
118,8,300,2,0,
118,8,346,1,1,3
118,8,352,1,17,
118,8,352,4,0,
118,8,363,4,0,
118,8,392,1,27,
118,8,398,4,0,
118,8,401,2,0,
118,8,445,4,0,
118,9,30,1,11,
118,9,31,1,31,
118,9,32,1,41,
118,9,39,1,1,2
118,9,48,1,7,
118,9,56,2,0,
118,9,57,4,0,
118,9,58,4,0,
118,9,59,4,0,
118,9,60,2,0,
118,9,64,1,1,1
118,9,92,4,0,
118,9,97,1,47,
118,9,104,4,0,
118,9,114,2,0,
118,9,127,1,37,
118,9,127,4,0,
118,9,129,3,0,
118,9,156,4,0,
118,9,164,4,0,
118,9,173,3,0,
118,9,175,1,21,
118,9,182,4,0,
118,9,189,2,0,
118,9,189,3,0,
118,9,196,3,0,
118,9,203,4,0,
118,9,207,4,0,
118,9,210,3,0,
118,9,213,4,0,
118,9,214,2,0,
118,9,214,4,0,
118,9,216,4,0,
118,9,218,4,0,
118,9,224,1,51,
118,9,237,4,0,
118,9,240,4,0,
118,9,258,4,0,
118,9,263,4,0,
118,9,282,3,0,
118,9,290,4,0,
118,9,291,3,0,
118,9,300,2,0,
118,9,340,3,0,
118,9,346,1,1,3
118,9,352,1,17,
118,9,352,4,0,
118,9,363,4,0,
118,9,392,1,27,
118,9,398,4,0,
118,9,401,2,0,
118,9,401,3,0,
118,9,445,4,0,
118,10,30,1,11,
118,10,31,1,31,
118,10,32,1,41,
118,10,34,2,0,
118,10,39,1,1,2
118,10,48,1,7,
118,10,56,2,0,
118,10,57,4,0,
118,10,58,4,0,
118,10,59,4,0,
118,10,60,2,0,
118,10,64,1,1,1
118,10,92,4,0,
118,10,97,1,47,
118,10,104,4,0,
118,10,114,2,0,
118,10,127,1,37,
118,10,127,4,0,
118,10,129,3,0,
118,10,156,4,0,
118,10,164,4,0,
118,10,173,3,0,
118,10,175,1,21,
118,10,182,4,0,
118,10,189,2,0,
118,10,189,3,0,
118,10,196,3,0,
118,10,203,4,0,
118,10,207,4,0,
118,10,210,3,0,
118,10,213,4,0,
118,10,214,2,0,
118,10,214,4,0,
118,10,216,4,0,
118,10,218,4,0,
118,10,224,1,51,
118,10,237,4,0,
118,10,240,4,0,
118,10,250,4,0,
118,10,258,4,0,
118,10,263,4,0,
118,10,282,3,0,
118,10,290,4,0,
118,10,291,3,0,
118,10,300,2,0,
118,10,340,3,0,
118,10,346,1,1,3
118,10,352,1,17,
118,10,352,4,0,
118,10,363,4,0,
118,10,392,1,27,
118,10,398,4,0,
118,10,401,2,0,
118,10,401,3,0,
118,10,445,4,0,
118,11,30,1,11,
118,11,31,1,31,
118,11,32,1,41,
118,11,34,2,0,
118,11,39,1,1,2
118,11,48,1,7,
118,11,56,2,0,
118,11,57,4,0,
118,11,58,4,0,
118,11,59,4,0,
118,11,60,2,0,
118,11,64,1,1,1
118,11,92,4,0,
118,11,97,1,47,
118,11,104,4,0,
118,11,114,2,0,
118,11,127,1,37,
118,11,127,4,0,
118,11,130,2,0,
118,11,156,4,0,
118,11,164,4,0,
118,11,175,1,21,
118,11,182,4,0,
118,11,189,2,0,
118,11,207,4,0,
118,11,213,4,0,
118,11,214,2,0,
118,11,216,4,0,
118,11,218,4,0,
118,11,224,1,57,
118,11,237,4,0,
118,11,240,4,0,
118,11,258,4,0,
118,11,263,4,0,
118,11,291,4,0,
118,11,300,2,0,
118,11,324,2,0,
118,11,341,2,0,
118,11,346,1,1,3
118,11,352,1,17,
118,11,392,1,27,
118,11,398,4,0,
118,11,401,2,0,
118,11,487,1,51,
118,11,496,4,0,
118,11,503,4,0,
118,12,30,1,15,
118,12,31,1,29,
118,12,32,1,43,
118,12,39,1,1,2
118,12,48,1,10,
118,12,57,4,0,
118,12,58,4,0,
118,12,59,4,0,
118,12,64,1,1,1
118,12,92,4,0,
118,12,97,1,52,
118,12,104,4,0,
118,12,127,1,38,
118,12,127,4,0,
118,12,156,4,0,
118,12,175,1,24,
118,12,182,4,0,
118,12,213,4,0,
118,12,216,4,0,
118,12,218,4,0,
118,12,237,4,0,
118,12,240,4,0,
118,12,258,4,0,
118,12,263,4,0,
118,12,290,4,0,
118,12,291,4,0,
118,12,346,1,1,3
118,12,352,4,0,
118,13,30,1,15,
118,13,31,1,29,
118,13,32,1,43,
118,13,38,3,0,
118,13,39,1,1,2
118,13,48,1,10,
118,13,57,4,0,
118,13,58,4,0,
118,13,59,4,0,
118,13,64,1,1,1
118,13,92,4,0,
118,13,97,1,52,
118,13,102,3,0,
118,13,104,4,0,
118,13,127,1,38,
118,13,127,4,0,
118,13,156,4,0,
118,13,164,3,0,
118,13,175,1,24,
118,13,182,4,0,
118,13,196,3,0,
118,13,207,3,0,
118,13,213,4,0,
118,13,216,4,0,
118,13,218,4,0,
118,13,237,4,0,
118,13,240,4,0,
118,13,258,4,0,
118,13,263,4,0,
118,13,290,4,0,
118,13,291,4,0,
118,13,346,1,1,3
118,13,352,4,0,
118,14,30,1,11,
118,14,31,1,31,
118,14,32,1,41,
118,14,34,2,0,
118,14,39,1,1,2
118,14,48,1,7,
118,14,56,2,0,
118,14,57,4,0,
118,14,58,4,0,
118,14,59,4,0,
118,14,60,2,0,
118,14,64,1,1,1
118,14,92,4,0,
118,14,97,1,47,
118,14,104,4,0,
118,14,114,2,0,
118,14,127,1,37,
118,14,127,4,0,
118,14,130,2,0,
118,14,156,4,0,
118,14,164,4,0,
118,14,173,3,0,
118,14,175,1,21,
118,14,182,4,0,
118,14,189,2,0,
118,14,196,3,0,
118,14,207,4,0,
118,14,213,4,0,
118,14,214,2,0,
118,14,214,3,0,
118,14,216,4,0,
118,14,218,4,0,
118,14,224,1,57,
118,14,237,4,0,
118,14,240,4,0,
118,14,258,4,0,
118,14,263,4,0,
118,14,282,3,0,
118,14,291,4,0,
118,14,300,2,0,
118,14,324,2,0,
118,14,324,3,0,
118,14,340,3,0,
118,14,341,2,0,
118,14,346,1,1,3
118,14,352,1,17,
118,14,392,1,27,
118,14,398,4,0,
118,14,401,2,0,
118,14,401,3,0,
118,14,487,1,51,
118,14,496,4,0,
118,14,503,4,0,
118,14,529,3,0,
118,15,30,1,11,
118,15,31,1,31,
118,15,32,1,41,
118,15,34,2,0,
118,15,39,1,1,2
118,15,48,1,7,
118,15,56,2,0,
118,15,57,4,0,
118,15,58,4,0,
118,15,59,4,0,
118,15,60,2,0,
118,15,64,1,1,1
118,15,92,4,0,
118,15,97,1,47,
118,15,104,4,0,
118,15,114,2,0,
118,15,127,1,37,
118,15,127,4,0,
118,15,130,2,0,
118,15,156,4,0,
118,15,164,4,0,
118,15,175,1,21,
118,15,182,4,0,
118,15,189,2,0,
118,15,207,4,0,
118,15,213,4,0,
118,15,214,2,0,
118,15,214,4,0,
118,15,216,4,0,
118,15,218,4,0,
118,15,224,1,57,
118,15,237,4,0,
118,15,240,4,0,
118,15,258,4,0,
118,15,263,4,0,
118,15,300,2,0,
118,15,324,2,0,
118,15,341,2,0,
118,15,346,1,1,3
118,15,352,1,17,
118,15,392,1,27,
118,15,398,4,0,
118,15,401,2,0,
118,15,487,1,50,
118,15,496,4,0,
118,15,503,4,0,
118,15,590,4,0,
118,16,30,1,8,1
118,16,31,1,24,1
118,16,32,1,37,1
118,16,34,2,0,
118,16,39,1,1,2
118,16,48,1,5,1
118,16,56,2,0,
118,16,57,4,0,
118,16,58,4,0,
118,16,59,4,0,
118,16,60,2,0,
118,16,64,1,1,1
118,16,92,4,0,
118,16,97,1,29,1
118,16,104,4,0,
118,16,114,2,0,
118,16,127,1,32,1
118,16,127,4,0,
118,16,130,2,0,
118,16,156,4,0,
118,16,164,4,0,
118,16,173,3,0,
118,16,175,1,13,1
118,16,182,4,0,
118,16,189,2,0,
118,16,196,3,0,
118,16,207,4,0,
118,16,213,4,0,
118,16,214,2,0,
118,16,214,4,0,
118,16,216,4,0,
118,16,218,4,0,
118,16,224,1,45,1
118,16,237,4,0,
118,16,240,4,0,
118,16,258,4,0,
118,16,263,4,0,
118,16,282,3,0,
118,16,290,4,0,
118,16,291,4,0,
118,16,300,2,0,
118,16,324,2,0,
118,16,324,3,0,
118,16,340,3,0,
118,16,341,2,0,
118,16,346,1,1,3
118,16,352,1,16,1
118,16,352,3,0,
118,16,392,1,21,1
118,16,398,4,0,
118,16,401,2,0,
118,16,401,3,0,
118,16,487,1,40,1
118,16,496,4,0,
118,16,503,4,0,
118,16,529,3,0,
118,16,590,4,0,
118,17,30,1,8,
118,17,31,1,24,
118,17,32,1,37,
118,17,34,2,0,
118,17,39,1,1,2
118,17,48,1,5,
118,17,56,2,0,
118,17,57,4,0,
118,17,58,4,0,
118,17,59,4,0,
118,17,60,2,0,
118,17,64,1,1,1
118,17,92,4,0,
118,17,97,1,29,
118,17,104,4,0,
118,17,114,2,0,
118,17,127,1,32,
118,17,127,4,0,
118,17,130,2,0,
118,17,156,4,0,
118,17,164,4,0,
118,17,175,1,13,
118,17,182,4,0,
118,17,189,2,0,
118,17,207,4,0,
118,17,213,4,0,
118,17,214,2,0,
118,17,214,4,0,
118,17,216,4,0,
118,17,218,4,0,
118,17,224,1,45,
118,17,237,4,0,
118,17,240,4,0,
118,17,258,4,0,
118,17,263,4,0,
118,17,300,2,0,
118,17,324,2,0,
118,17,341,2,0,
118,17,346,1,1,3
118,17,352,1,16,
118,17,392,1,21,
118,17,398,4,0,
118,17,401,2,0,
118,17,487,1,40,
118,17,496,4,0,
118,17,503,4,0,
118,17,590,4,0,
118,17,684,4,0,
118,18,30,1,8,
118,18,31,1,24,
118,18,32,1,37,
118,18,34,2,0,
118,18,39,1,1,2
118,18,48,1,5,
118,18,56,2,0,
118,18,57,4,0,
118,18,58,4,0,
118,18,59,4,0,
118,18,60,2,0,
118,18,64,1,1,1
118,18,92,4,0,
118,18,97,1,29,
118,18,104,4,0,
118,18,114,2,0,
118,18,127,1,32,
118,18,127,4,0,
118,18,130,2,0,
118,18,156,4,0,
118,18,164,4,0,
118,18,175,1,13,
118,18,182,4,0,
118,18,189,2,0,
118,18,207,4,0,
118,18,213,4,0,
118,18,214,2,0,
118,18,214,4,0,
118,18,216,4,0,
118,18,218,4,0,
118,18,224,1,45,
118,18,237,4,0,
118,18,240,4,0,
118,18,258,4,0,
118,18,263,4,0,
118,18,300,2,0,
118,18,324,2,0,
118,18,341,2,0,
118,18,346,1,1,3
118,18,352,1,16,
118,18,392,1,21,
118,18,398,4,0,
118,18,401,2,0,
118,18,487,1,40,
118,18,496,4,0,
118,18,503,4,0,
118,18,590,4,0,
118,18,684,4,0,
119,1,30,1,24,
119,1,31,1,30,
119,1,32,1,48,
119,1,32,4,0,
119,1,36,4,0,
119,1,38,4,0,
119,1,39,1,1,2
119,1,48,1,1,3
119,1,48,1,19,
119,1,55,4,0,
119,1,57,4,0,
119,1,58,4,0,
119,1,59,4,0,
119,1,61,4,0,
119,1,63,4,0,
119,1,64,1,1,1
119,1,92,4,0,
119,1,97,1,54,
119,1,99,4,0,
119,1,102,4,0,
119,1,104,4,0,
119,1,117,4,0,
119,1,127,1,39,
119,1,129,4,0,
119,1,130,4,0,
119,1,156,4,0,
119,1,164,4,0,
119,2,30,1,24,
119,2,31,1,30,
119,2,32,1,48,
119,2,32,4,0,
119,2,36,4,0,
119,2,38,4,0,
119,2,39,1,1,2
119,2,48,1,1,3
119,2,48,1,19,
119,2,55,4,0,
119,2,57,4,0,
119,2,58,4,0,
119,2,59,4,0,
119,2,61,4,0,
119,2,63,4,0,
119,2,64,1,1,1
119,2,92,4,0,
119,2,97,1,54,
119,2,99,4,0,
119,2,102,4,0,
119,2,104,4,0,
119,2,117,4,0,
119,2,127,1,39,
119,2,129,4,0,
119,2,130,4,0,
119,2,156,4,0,
119,2,164,4,0,
119,3,30,1,15,
119,3,31,1,29,
119,3,32,1,49,
119,3,39,1,1,2
119,3,48,1,10,
119,3,57,4,0,
119,3,59,4,0,
119,3,63,4,0,
119,3,64,1,1,1
119,3,92,4,0,
119,3,97,1,61,
119,3,104,4,0,
119,3,127,1,41,
119,3,127,4,0,
119,3,129,4,0,
119,3,156,4,0,
119,3,173,4,0,
119,3,174,4,0,
119,3,175,1,24,
119,3,182,4,0,
119,3,196,4,0,
119,3,203,4,0,
119,3,207,4,0,
119,3,213,4,0,
119,3,214,4,0,
119,3,216,4,0,
119,3,218,4,0,
119,3,237,4,0,
119,3,240,4,0,
119,4,30,1,15,
119,4,31,1,29,
119,4,32,1,49,
119,4,39,1,1,2
119,4,48,1,10,
119,4,57,4,0,
119,4,58,3,0,
119,4,59,4,0,
119,4,63,4,0,
119,4,64,1,1,1
119,4,92,4,0,
119,4,97,1,61,
119,4,104,4,0,
119,4,127,1,41,
119,4,127,4,0,
119,4,129,4,0,
119,4,156,4,0,
119,4,173,4,0,
119,4,174,4,0,
119,4,175,1,24,
119,4,182,4,0,
119,4,196,4,0,
119,4,203,4,0,
119,4,207,4,0,
119,4,213,4,0,
119,4,214,4,0,
119,4,216,4,0,
119,4,218,4,0,
119,4,237,4,0,
119,4,240,4,0,
119,5,30,1,15,
119,5,31,1,29,
119,5,32,1,49,
119,5,39,1,1,2
119,5,48,1,1,4
119,5,48,1,10,
119,5,57,4,0,
119,5,58,4,0,
119,5,59,4,0,
119,5,63,4,0,
119,5,64,1,1,1
119,5,92,4,0,
119,5,97,1,61,
119,5,104,4,0,
119,5,127,1,41,
119,5,127,4,0,
119,5,156,4,0,
119,5,175,1,24,
119,5,182,4,0,
119,5,213,4,0,
119,5,216,4,0,
119,5,218,4,0,
119,5,237,4,0,
119,5,240,4,0,
119,5,258,4,0,
119,5,263,4,0,
119,5,290,4,0,
119,5,291,4,0,
119,5,346,1,1,3
119,5,352,4,0,
119,6,30,1,15,
119,6,31,1,29,
119,6,32,1,49,
119,6,38,3,0,
119,6,39,1,1,2
119,6,48,1,1,4
119,6,48,1,10,
119,6,57,4,0,
119,6,58,4,0,
119,6,59,4,0,
119,6,63,4,0,
119,6,64,1,1,1
119,6,92,4,0,
119,6,97,1,61,
119,6,102,3,0,
119,6,104,4,0,
119,6,127,1,41,
119,6,127,4,0,
119,6,129,3,0,
119,6,156,4,0,
119,6,164,3,0,
119,6,173,3,0,
119,6,175,1,24,
119,6,182,4,0,
119,6,196,3,0,
119,6,203,3,0,
119,6,207,3,0,
119,6,213,4,0,
119,6,214,3,0,
119,6,216,4,0,
119,6,218,4,0,
119,6,237,4,0,
119,6,240,4,0,
119,6,258,4,0,
119,6,263,4,0,
119,6,290,4,0,
119,6,291,4,0,
119,6,346,1,1,3
119,6,352,4,0,
119,7,30,1,15,
119,7,31,1,29,
119,7,32,1,49,
119,7,38,3,0,
119,7,39,1,1,2
119,7,48,1,1,4
119,7,48,1,10,
119,7,57,4,0,
119,7,58,4,0,
119,7,59,4,0,
119,7,63,4,0,
119,7,64,1,1,1
119,7,92,4,0,
119,7,97,1,61,
119,7,102,3,0,
119,7,104,4,0,
119,7,127,1,41,
119,7,127,4,0,
119,7,156,4,0,
119,7,164,3,0,
119,7,175,1,24,
119,7,182,4,0,
119,7,213,4,0,
119,7,216,4,0,
119,7,218,4,0,
119,7,224,1,69,
119,7,237,4,0,
119,7,240,4,0,
119,7,258,4,0,
119,7,263,4,0,
119,7,290,4,0,
119,7,291,4,0,
119,7,346,1,1,3
119,7,352,4,0,
119,8,30,1,11,
119,8,31,1,31,
119,8,32,1,47,
119,8,39,1,1,3
119,8,48,1,1,5
119,8,48,1,7,
119,8,57,4,0,
119,8,58,4,0,
119,8,59,4,0,
119,8,63,4,0,
119,8,64,1,1,2
119,8,92,4,0,
119,8,97,1,56,
119,8,104,4,0,
119,8,127,1,40,
119,8,127,4,0,
119,8,156,4,0,
119,8,164,4,0,
119,8,175,1,21,
119,8,182,4,0,
119,8,203,4,0,
119,8,207,4,0,
119,8,213,4,0,
119,8,214,4,0,
119,8,216,4,0,
119,8,218,4,0,
119,8,224,1,63,
119,8,237,4,0,
119,8,240,4,0,
119,8,258,4,0,
119,8,263,4,0,
119,8,290,4,0,
119,8,346,1,1,4
119,8,352,1,17,
119,8,352,4,0,
119,8,363,4,0,
119,8,392,1,27,
119,8,398,1,1,1
119,8,398,4,0,
119,8,416,4,0,
119,8,445,4,0,
119,9,30,1,11,
119,9,31,1,31,
119,9,32,1,47,
119,9,39,1,1,3
119,9,48,1,1,5
119,9,48,1,7,
119,9,57,4,0,
119,9,58,4,0,
119,9,59,4,0,
119,9,63,4,0,
119,9,64,1,1,2
119,9,92,4,0,
119,9,97,1,56,
119,9,104,4,0,
119,9,127,1,40,
119,9,127,4,0,
119,9,129,3,0,
119,9,156,4,0,
119,9,164,4,0,
119,9,173,3,0,
119,9,175,1,21,
119,9,182,4,0,
119,9,189,3,0,
119,9,196,3,0,
119,9,203,4,0,
119,9,207,4,0,
119,9,210,3,0,
119,9,213,4,0,
119,9,214,4,0,
119,9,216,4,0,
119,9,218,4,0,
119,9,224,1,63,
119,9,237,4,0,
119,9,240,4,0,
119,9,258,4,0,
119,9,263,4,0,
119,9,282,3,0,
119,9,290,4,0,
119,9,291,3,0,
119,9,340,3,0,
119,9,346,1,1,4
119,9,352,1,17,
119,9,352,4,0,
119,9,363,4,0,
119,9,392,1,27,
119,9,398,1,1,1
119,9,398,4,0,
119,9,401,3,0,
119,9,416,4,0,
119,9,445,4,0,
119,10,30,1,11,
119,10,31,1,31,
119,10,32,1,47,
119,10,39,1,1,3
119,10,48,1,1,5
119,10,48,1,7,
119,10,57,4,0,
119,10,58,4,0,
119,10,59,4,0,
119,10,63,4,0,
119,10,64,1,1,2
119,10,92,4,0,
119,10,97,1,56,
119,10,104,4,0,
119,10,127,1,40,
119,10,127,4,0,
119,10,129,3,0,
119,10,156,4,0,
119,10,164,4,0,
119,10,173,3,0,
119,10,175,1,21,
119,10,182,4,0,
119,10,189,3,0,
119,10,196,3,0,
119,10,203,4,0,
119,10,207,4,0,
119,10,210,3,0,
119,10,213,4,0,
119,10,214,4,0,
119,10,216,4,0,
119,10,218,4,0,
119,10,224,1,63,
119,10,237,4,0,
119,10,240,4,0,
119,10,250,4,0,
119,10,258,4,0,
119,10,263,4,0,
119,10,282,3,0,
119,10,290,4,0,
119,10,291,3,0,
119,10,340,3,0,
119,10,346,1,1,4
119,10,352,1,17,
119,10,352,4,0,
119,10,363,4,0,
119,10,392,1,27,
119,10,398,1,1,1
119,10,398,4,0,
119,10,401,3,0,
119,10,416,4,0,
119,10,445,4,0,
119,11,30,1,11,
119,11,31,1,31,
119,11,32,1,47,
119,11,39,1,1,3
119,11,48,1,1,5
119,11,48,1,7,
119,11,57,4,0,
119,11,58,4,0,
119,11,59,4,0,
119,11,63,4,0,
119,11,64,1,1,2
119,11,92,4,0,
119,11,97,1,56,
119,11,104,4,0,
119,11,127,1,40,
119,11,127,4,0,
119,11,156,4,0,
119,11,164,4,0,
119,11,175,1,21,
119,11,182,4,0,
119,11,207,4,0,
119,11,213,4,0,
119,11,216,4,0,
119,11,218,4,0,
119,11,224,1,72,
119,11,237,4,0,
119,11,240,4,0,
119,11,258,4,0,
119,11,263,4,0,
119,11,291,4,0,
119,11,346,1,1,4
119,11,352,1,17,
119,11,392,1,27,
119,11,398,1,1,1
119,11,398,4,0,
119,11,416,4,0,
119,11,487,1,63,
119,11,496,4,0,
119,11,503,4,0,
119,12,30,1,15,
119,12,31,1,29,
119,12,32,1,49,
119,12,39,1,1,2
119,12,48,1,1,4
119,12,48,1,10,
119,12,57,4,0,
119,12,58,4,0,
119,12,59,4,0,
119,12,63,4,0,
119,12,64,1,1,1
119,12,92,4,0,
119,12,97,1,61,
119,12,104,4,0,
119,12,127,1,41,
119,12,127,4,0,
119,12,156,4,0,
119,12,175,1,24,
119,12,182,4,0,
119,12,213,4,0,
119,12,216,4,0,
119,12,218,4,0,
119,12,237,4,0,
119,12,240,4,0,
119,12,258,4,0,
119,12,263,4,0,
119,12,290,4,0,
119,12,291,4,0,
119,12,346,1,1,3
119,12,352,4,0,
119,13,30,1,15,
119,13,31,1,29,
119,13,32,1,49,
119,13,38,3,0,
119,13,39,1,1,2
119,13,48,1,1,4
119,13,48,1,10,
119,13,57,4,0,
119,13,58,4,0,
119,13,59,4,0,
119,13,63,4,0,
119,13,64,1,1,1
119,13,92,4,0,
119,13,97,1,61,
119,13,102,3,0,
119,13,104,4,0,
119,13,127,1,41,
119,13,127,4,0,
119,13,156,4,0,
119,13,164,3,0,
119,13,175,1,24,
119,13,182,4,0,
119,13,196,3,0,
119,13,207,3,0,
119,13,213,4,0,
119,13,216,4,0,
119,13,218,4,0,
119,13,237,4,0,
119,13,240,4,0,
119,13,258,4,0,
119,13,263,4,0,
119,13,290,4,0,
119,13,291,4,0,
119,13,346,1,1,3
119,13,352,4,0,
119,14,30,1,11,
119,14,31,1,31,
119,14,32,1,47,
119,14,39,1,1,3
119,14,48,1,1,5
119,14,48,1,7,
119,14,57,4,0,
119,14,58,4,0,
119,14,59,4,0,
119,14,63,4,0,
119,14,64,1,1,2
119,14,92,4,0,
119,14,97,1,56,
119,14,104,4,0,
119,14,127,1,40,
119,14,127,4,0,
119,14,156,4,0,
119,14,164,4,0,
119,14,173,3,0,
119,14,175,1,21,
119,14,182,4,0,
119,14,196,3,0,
119,14,207,4,0,
119,14,213,4,0,
119,14,214,3,0,
119,14,216,4,0,
119,14,218,4,0,
119,14,224,1,72,
119,14,237,4,0,
119,14,240,4,0,
119,14,258,4,0,
119,14,263,4,0,
119,14,282,3,0,
119,14,291,4,0,
119,14,324,3,0,
119,14,340,3,0,
119,14,346,1,1,4
119,14,352,1,17,
119,14,392,1,27,
119,14,398,1,1,1
119,14,398,4,0,
119,14,401,3,0,
119,14,416,4,0,
119,14,487,1,63,
119,14,496,4,0,
119,14,503,4,0,
119,14,529,3,0,
119,15,30,1,11,
119,15,31,1,31,
119,15,32,1,47,
119,15,39,1,1,4
119,15,48,1,1,6
119,15,48,1,7,
119,15,57,4,0,
119,15,58,4,0,
119,15,59,4,0,
119,15,63,4,0,
119,15,64,1,1,3
119,15,92,4,0,
119,15,97,1,56,
119,15,104,4,0,
119,15,127,1,40,
119,15,127,4,0,
119,15,156,4,0,
119,15,164,4,0,
119,15,175,1,21,
119,15,182,4,0,
119,15,207,4,0,
119,15,213,4,0,
119,15,214,4,0,
119,15,216,4,0,
119,15,218,4,0,
119,15,224,1,1,1
119,15,224,1,72,
119,15,237,4,0,
119,15,240,4,0,
119,15,258,4,0,
119,15,263,4,0,
119,15,346,1,1,5
119,15,352,1,17,
119,15,392,1,27,
119,15,398,1,1,2
119,15,398,4,0,
119,15,416,4,0,
119,15,487,1,63,
119,15,496,4,0,
119,15,503,4,0,
119,15,590,4,0,
119,16,30,1,8,1
119,16,31,1,24,1
119,16,32,1,40,1
119,16,39,1,1,4
119,16,48,1,1,6
119,16,48,1,5,1
119,16,57,4,0,
119,16,58,4,0,
119,16,59,4,0,
119,16,63,4,0,
119,16,64,1,1,3
119,16,92,4,0,
119,16,97,1,29,1
119,16,104,4,0,
119,16,127,1,32,1
119,16,127,4,0,
119,16,156,4,0,
119,16,164,4,0,
119,16,173,3,0,
119,16,175,1,13,1
119,16,182,4,0,
119,16,196,3,0,
119,16,207,4,0,
119,16,213,4,0,
119,16,214,4,0,
119,16,216,4,0,
119,16,218,4,0,
119,16,224,1,1,1
119,16,224,1,54,1
119,16,237,4,0,
119,16,240,4,0,
119,16,258,4,0,
119,16,263,4,0,
119,16,282,3,0,
119,16,290,4,0,
119,16,291,4,0,
119,16,324,3,0,
119,16,340,3,0,
119,16,346,1,1,5
119,16,352,1,16,1
119,16,352,3,0,
119,16,392,1,21,1
119,16,398,1,1,2
119,16,398,4,0,
119,16,401,3,0,
119,16,416,4,0,
119,16,487,1,46,1
119,16,496,4,0,
119,16,503,4,0,
119,16,529,3,0,
119,16,590,4,0,
119,17,30,1,8,
119,17,31,1,24,
119,17,32,1,40,
119,17,39,1,1,4
119,17,48,1,1,6
119,17,48,1,5,
119,17,57,4,0,
119,17,58,4,0,
119,17,59,4,0,
119,17,63,4,0,
119,17,64,1,1,3
119,17,92,4,0,
119,17,97,1,29,
119,17,104,4,0,
119,17,127,1,32,
119,17,127,4,0,
119,17,156,4,0,
119,17,164,4,0,
119,17,175,1,13,
119,17,182,4,0,
119,17,207,4,0,
119,17,213,4,0,
119,17,214,4,0,
119,17,216,4,0,
119,17,218,4,0,
119,17,224,1,1,1
119,17,224,1,54,
119,17,237,4,0,
119,17,240,4,0,
119,17,258,4,0,
119,17,263,4,0,
119,17,346,1,1,5
119,17,352,1,16,
119,17,392,1,21,
119,17,398,1,1,2
119,17,398,4,0,
119,17,416,4,0,
119,17,487,1,46,
119,17,496,4,0,
119,17,503,4,0,
119,17,590,4,0,
119,17,684,4,0,
119,18,30,1,8,
119,18,31,1,24,
119,18,32,1,40,
119,18,39,1,1,4
119,18,48,1,1,6
119,18,48,1,5,
119,18,57,4,0,
119,18,58,4,0,
119,18,59,4,0,
119,18,63,4,0,
119,18,64,1,1,3
119,18,92,4,0,
119,18,97,1,29,
119,18,104,4,0,
119,18,127,1,32,
119,18,127,4,0,
119,18,156,4,0,
119,18,164,4,0,
119,18,175,1,13,
119,18,182,4,0,
119,18,207,4,0,
119,18,213,4,0,
119,18,214,4,0,
119,18,216,4,0,
119,18,218,4,0,
119,18,224,1,1,1
119,18,224,1,54,
119,18,237,4,0,
119,18,240,4,0,
119,18,258,4,0,
119,18,263,4,0,
119,18,346,1,1,5
119,18,352,1,16,
119,18,392,1,21,
119,18,398,1,1,2
119,18,398,4,0,
119,18,416,4,0,
119,18,487,1,46,
119,18,496,4,0,
119,18,503,4,0,
119,18,590,4,0,
119,18,684,4,0,
120,1,33,1,1,
120,1,36,4,0,
120,1,38,4,0,
120,1,55,1,17,
120,1,55,4,0,
120,1,56,1,47,
120,1,57,4,0,
120,1,58,4,0,
120,1,59,4,0,
120,1,61,4,0,
120,1,85,4,0,
120,1,86,4,0,
120,1,87,4,0,
120,1,92,4,0,
120,1,94,4,0,
120,1,99,4,0,
120,1,100,4,0,
120,1,102,4,0,
120,1,104,4,0,
120,1,105,1,27,
120,1,106,1,22,
120,1,107,1,37,
120,1,113,1,42,
120,1,115,4,0,
120,1,117,4,0,
120,1,129,1,32,
120,1,129,4,0,
120,1,130,4,0,
120,1,148,4,0,
120,1,149,4,0,
120,1,156,4,0,
120,1,161,4,0,
120,1,164,4,0,
120,2,33,1,1,
120,2,36,4,0,
120,2,38,4,0,
120,2,55,1,17,
120,2,55,4,0,
120,2,56,1,47,
120,2,57,4,0,
120,2,58,4,0,
120,2,59,4,0,
120,2,61,4,0,
120,2,85,4,0,
120,2,86,4,0,
120,2,87,4,0,
120,2,92,4,0,
120,2,94,4,0,
120,2,99,4,0,
120,2,100,4,0,
120,2,102,4,0,
120,2,104,4,0,
120,2,105,1,27,
120,2,106,1,22,
120,2,107,1,37,
120,2,113,1,42,
120,2,115,4,0,
120,2,117,4,0,
120,2,129,1,32,
120,2,129,4,0,
120,2,130,4,0,
120,2,148,4,0,
120,2,149,4,0,
120,2,156,4,0,
120,2,161,4,0,
120,2,164,4,0,
120,3,33,1,1,1
120,3,48,2,0,
120,3,55,1,7,
120,3,56,1,50,
120,3,57,4,0,
120,3,59,4,0,
120,3,61,1,31,
120,3,62,2,0,
120,3,87,4,0,
120,3,92,4,0,
120,3,94,4,0,
120,3,104,4,0,
120,3,105,1,19,
120,3,106,1,1,2
120,3,107,1,37,
120,3,112,2,0,
120,3,113,1,43,
120,3,127,4,0,
120,3,129,1,25,
120,3,129,4,0,
120,3,148,4,0,
120,3,156,4,0,
120,3,173,4,0,
120,3,174,4,0,
120,3,182,4,0,
120,3,192,4,0,
120,3,196,4,0,
120,3,203,4,0,
120,3,207,4,0,
120,3,213,4,0,
120,3,214,4,0,
120,3,216,4,0,
120,3,218,4,0,
120,3,229,1,13,
120,3,237,4,0,
120,3,240,4,0,
120,3,244,4,0,
120,3,250,4,0,
120,4,33,1,1,1
120,4,55,1,7,
120,4,56,1,50,
120,4,57,4,0,
120,4,58,3,0,
120,4,59,4,0,
120,4,61,1,31,
120,4,85,3,0,
120,4,87,4,0,
120,4,92,4,0,
120,4,94,4,0,
120,4,104,4,0,
120,4,105,1,19,
120,4,106,1,1,2
120,4,107,1,37,
120,4,113,1,43,
120,4,127,4,0,
120,4,129,1,25,
120,4,129,4,0,
120,4,148,4,0,
120,4,156,4,0,
120,4,173,4,0,
120,4,174,4,0,
120,4,182,4,0,
120,4,192,4,0,
120,4,196,4,0,
120,4,203,4,0,
120,4,207,4,0,
120,4,213,4,0,
120,4,214,4,0,
120,4,216,4,0,
120,4,218,4,0,
120,4,229,1,13,
120,4,237,4,0,
120,4,240,4,0,
120,4,244,4,0,
120,4,250,4,0,
120,5,33,1,1,1
120,5,55,1,6,
120,5,56,1,46,
120,5,57,4,0,
120,5,58,4,0,
120,5,59,4,0,
120,5,61,1,28,
120,5,85,4,0,
120,5,87,4,0,
120,5,92,4,0,
120,5,94,4,0,
120,5,104,4,0,
120,5,105,1,15,
120,5,106,1,1,2
120,5,107,1,33,
120,5,113,1,37,
120,5,113,4,0,
120,5,115,4,0,
120,5,127,4,0,
120,5,129,1,24,
120,5,148,4,0,
120,5,156,4,0,
120,5,182,4,0,
120,5,216,4,0,
120,5,218,4,0,
120,5,229,1,10,
120,5,237,4,0,
120,5,240,4,0,
120,5,258,4,0,
120,5,263,4,0,
120,5,290,4,0,
120,5,291,4,0,
120,5,293,1,19,
120,5,322,1,42,
120,5,352,4,0,
120,6,33,1,1,1
120,6,38,3,0,
120,6,55,1,6,
120,6,56,1,46,
120,6,57,4,0,
120,6,58,4,0,
120,6,59,4,0,
120,6,61,1,28,
120,6,85,4,0,
120,6,86,3,0,
120,6,87,4,0,
120,6,92,4,0,
120,6,94,4,0,
120,6,102,3,0,
120,6,104,4,0,
120,6,105,1,15,
120,6,106,1,1,2
120,6,107,1,33,
120,6,113,1,37,
120,6,113,4,0,
120,6,115,4,0,
120,6,127,4,0,
120,6,129,1,24,
120,6,129,3,0,
120,6,148,4,0,
120,6,156,4,0,
120,6,164,3,0,
120,6,173,3,0,
120,6,182,4,0,
120,6,196,3,0,
120,6,203,3,0,
120,6,207,3,0,
120,6,214,3,0,
120,6,216,4,0,
120,6,218,4,0,
120,6,229,1,10,
120,6,237,4,0,
120,6,240,4,0,
120,6,244,3,0,
120,6,258,4,0,
120,6,263,4,0,
120,6,290,4,0,
120,6,291,4,0,
120,6,293,1,19,
120,6,322,1,42,
120,6,352,4,0,
120,7,33,1,1,1
120,7,38,3,0,
120,7,55,1,6,
120,7,56,1,46,
120,7,57,4,0,
120,7,58,4,0,
120,7,59,4,0,
120,7,61,1,28,
120,7,85,4,0,
120,7,86,3,0,
120,7,87,4,0,
120,7,92,4,0,
120,7,94,4,0,
120,7,102,3,0,
120,7,104,4,0,
120,7,105,1,15,
120,7,106,1,1,2
120,7,107,1,33,
120,7,113,1,37,
120,7,113,4,0,
120,7,115,4,0,
120,7,127,4,0,
120,7,129,1,24,
120,7,148,4,0,
120,7,156,4,0,
120,7,164,3,0,
120,7,182,4,0,
120,7,216,4,0,
120,7,218,4,0,
120,7,229,1,10,
120,7,237,4,0,
120,7,240,4,0,
120,7,258,4,0,
120,7,263,4,0,
120,7,290,4,0,
120,7,291,4,0,
120,7,293,1,19,
120,7,322,1,42,
120,7,352,4,0,
120,8,33,1,1,1
120,8,55,1,6,
120,8,56,1,55,
120,8,57,4,0,
120,8,58,4,0,
120,8,59,4,0,
120,8,61,1,28,
120,8,85,4,0,
120,8,86,4,0,
120,8,87,4,0,
120,8,92,4,0,
120,8,94,4,0,
120,8,104,4,0,
120,8,105,1,15,
120,8,106,1,1,2
120,8,107,1,33,
120,8,113,1,42,
120,8,113,4,0,
120,8,115,4,0,
120,8,127,4,0,
120,8,129,1,24,
120,8,148,4,0,
120,8,156,4,0,
120,8,164,4,0,
120,8,182,4,0,
120,8,203,4,0,
120,8,207,4,0,
120,8,214,4,0,
120,8,216,4,0,
120,8,218,4,0,
120,8,229,1,10,
120,8,237,4,0,
120,8,240,4,0,
120,8,244,4,0,
120,8,258,4,0,
120,8,263,4,0,
120,8,278,4,0,
120,8,290,4,0,
120,8,293,1,19,
120,8,322,1,51,
120,8,352,4,0,
120,8,360,1,37,
120,8,360,4,0,
120,8,362,4,0,
120,8,363,4,0,
120,8,408,1,46,
120,8,430,4,0,
120,9,33,1,1,1
120,9,55,1,6,
120,9,56,1,55,
120,9,57,4,0,
120,9,58,4,0,
120,9,59,4,0,
120,9,61,1,28,
120,9,85,4,0,
120,9,86,4,0,
120,9,87,4,0,
120,9,92,4,0,
120,9,94,4,0,
120,9,104,4,0,
120,9,105,1,15,
120,9,106,1,1,2
120,9,107,1,33,
120,9,113,1,42,
120,9,113,4,0,
120,9,115,4,0,
120,9,127,4,0,
120,9,129,1,24,
120,9,129,3,0,
120,9,148,4,0,
120,9,156,4,0,
120,9,164,4,0,
120,9,173,3,0,
120,9,182,4,0,
120,9,196,3,0,
120,9,203,4,0,
120,9,205,3,0,
120,9,207,4,0,
120,9,214,4,0,
120,9,216,4,0,
120,9,218,4,0,
120,9,229,1,10,
120,9,237,4,0,
120,9,239,3,0,
120,9,240,4,0,
120,9,244,4,0,
120,9,258,4,0,
120,9,263,4,0,
120,9,278,4,0,
120,9,290,4,0,
120,9,291,3,0,
120,9,293,1,19,
120,9,322,1,51,
120,9,324,3,0,
120,9,352,4,0,
120,9,360,1,37,
120,9,360,4,0,
120,9,362,4,0,
120,9,363,4,0,
120,9,408,1,46,
120,9,430,4,0,
120,10,33,1,1,1
120,10,55,1,6,
120,10,56,1,55,
120,10,57,4,0,
120,10,58,4,0,
120,10,59,4,0,
120,10,61,1,28,
120,10,85,4,0,
120,10,86,4,0,
120,10,87,4,0,
120,10,92,4,0,
120,10,94,4,0,
120,10,104,4,0,
120,10,105,1,15,
120,10,106,1,1,2
120,10,107,1,33,
120,10,113,1,42,
120,10,113,4,0,
120,10,115,4,0,
120,10,127,4,0,
120,10,129,1,24,
120,10,129,3,0,
120,10,148,4,0,
120,10,156,4,0,
120,10,164,4,0,
120,10,173,3,0,
120,10,182,4,0,
120,10,196,3,0,
120,10,203,4,0,
120,10,205,3,0,
120,10,207,4,0,
120,10,214,4,0,
120,10,216,4,0,
120,10,218,4,0,
120,10,220,3,0,
120,10,229,1,10,
120,10,237,4,0,
120,10,239,3,0,
120,10,240,4,0,
120,10,244,4,0,
120,10,250,4,0,
120,10,258,4,0,
120,10,263,4,0,
120,10,277,3,0,
120,10,278,4,0,
120,10,290,4,0,
120,10,291,3,0,
120,10,293,1,19,
120,10,322,1,51,
120,10,324,3,0,
120,10,352,4,0,
120,10,356,3,0,
120,10,360,1,37,
120,10,360,4,0,
120,10,362,4,0,
120,10,363,4,0,
120,10,408,1,46,
120,10,430,4,0,
120,11,33,1,1,1
120,11,55,1,6,
120,11,56,1,60,
120,11,57,4,0,
120,11,58,4,0,
120,11,59,4,0,
120,11,61,1,28,
120,11,85,4,0,
120,11,86,4,0,
120,11,87,4,0,
120,11,92,4,0,
120,11,94,4,0,
120,11,104,4,0,
120,11,105,1,15,
120,11,106,1,1,2
120,11,107,1,33,
120,11,113,1,42,
120,11,113,4,0,
120,11,115,4,0,
120,11,127,4,0,
120,11,129,1,24,
120,11,148,4,0,
120,11,156,4,0,
120,11,164,4,0,
120,11,182,4,0,
120,11,207,4,0,
120,11,216,4,0,
120,11,218,4,0,
120,11,229,1,10,
120,11,237,4,0,
120,11,240,4,0,
120,11,244,4,0,
120,11,258,4,0,
120,11,263,4,0,
120,11,291,4,0,
120,11,293,1,19,
120,11,322,1,55,
120,11,360,1,37,
120,11,360,4,0,
120,11,408,1,51,
120,11,430,4,0,
120,11,496,4,0,
120,11,503,4,0,
120,11,513,1,46,
120,12,33,1,1,1
120,12,55,1,6,
120,12,56,1,46,
120,12,57,4,0,
120,12,58,4,0,
120,12,59,4,0,
120,12,61,1,28,
120,12,85,4,0,
120,12,87,4,0,
120,12,92,4,0,
120,12,94,4,0,
120,12,104,4,0,
120,12,105,1,15,
120,12,106,1,1,2
120,12,107,1,33,
120,12,113,1,37,
120,12,113,4,0,
120,12,115,4,0,
120,12,127,4,0,
120,12,129,1,24,
120,12,148,4,0,
120,12,156,4,0,
120,12,182,4,0,
120,12,216,4,0,
120,12,218,4,0,
120,12,229,1,10,
120,12,237,4,0,
120,12,240,4,0,
120,12,258,4,0,
120,12,263,4,0,
120,12,290,4,0,
120,12,291,4,0,
120,12,293,1,19,
120,12,322,1,42,
120,12,352,4,0,
120,13,33,1,1,1
120,13,38,3,0,
120,13,55,1,6,
120,13,56,1,46,
120,13,57,4,0,
120,13,58,4,0,
120,13,59,4,0,
120,13,61,1,28,
120,13,85,4,0,
120,13,86,3,0,
120,13,87,4,0,
120,13,92,4,0,
120,13,94,4,0,
120,13,102,3,0,
120,13,104,4,0,
120,13,105,1,15,
120,13,106,1,1,2
120,13,107,1,33,
120,13,113,1,37,
120,13,113,4,0,
120,13,115,4,0,
120,13,127,4,0,
120,13,129,1,24,
120,13,148,4,0,
120,13,156,4,0,
120,13,164,3,0,
120,13,182,4,0,
120,13,196,3,0,
120,13,207,3,0,
120,13,216,4,0,
120,13,218,4,0,
120,13,229,1,10,
120,13,237,4,0,
120,13,240,4,0,
120,13,258,4,0,
120,13,263,4,0,
120,13,290,4,0,
120,13,291,4,0,
120,13,293,1,19,
120,13,322,1,42,
120,13,352,4,0,
120,14,33,1,1,1
120,14,55,1,6,
120,14,56,1,52,
120,14,57,4,0,
120,14,58,4,0,
120,14,59,4,0,
120,14,61,1,22,
120,14,85,4,0,
120,14,86,4,0,
120,14,87,4,0,
120,14,92,4,0,
120,14,94,4,0,
120,14,104,4,0,
120,14,105,1,12,
120,14,106,1,1,2
120,14,107,1,25,
120,14,113,1,33,
120,14,113,4,0,
120,14,115,4,0,
120,14,127,4,0,
120,14,129,1,18,
120,14,148,4,0,
120,14,156,4,0,
120,14,164,4,0,
120,14,173,3,0,
120,14,182,4,0,
120,14,196,3,0,
120,14,207,4,0,
120,14,214,3,0,
120,14,216,4,0,
120,14,218,4,0,
120,14,220,3,0,
120,14,229,1,10,
120,14,237,4,0,
120,14,240,4,0,
120,14,244,4,0,
120,14,258,4,0,
120,14,263,4,0,
120,14,277,3,0,
120,14,278,3,0,
120,14,291,4,0,
120,14,293,1,15,
120,14,322,1,48,
120,14,324,3,0,
120,14,356,3,0,
120,14,360,1,30,
120,14,360,4,0,
120,14,362,1,36,
120,14,408,1,43,
120,14,430,4,0,
120,14,496,4,0,
120,14,503,4,0,
120,14,513,1,40,
120,15,33,1,1,1
120,15,55,1,6,
120,15,56,1,52,
120,15,57,4,0,
120,15,58,4,0,
120,15,59,4,0,
120,15,61,1,22,
120,15,85,4,0,
120,15,86,4,0,
120,15,87,4,0,
120,15,92,4,0,
120,15,94,4,0,
120,15,104,4,0,
120,15,105,1,12,
120,15,106,1,1,2
120,15,107,1,25,
120,15,113,1,33,
120,15,113,4,0,
120,15,115,4,0,
120,15,127,4,0,
120,15,129,1,18,
120,15,148,4,0,
120,15,156,4,0,
120,15,164,4,0,
120,15,182,4,0,
120,15,207,4,0,
120,15,214,4,0,
120,15,216,4,0,
120,15,218,4,0,
120,15,229,1,10,
120,15,237,4,0,
120,15,240,4,0,
120,15,244,4,0,
120,15,258,4,0,
120,15,263,4,0,
120,15,293,1,15,
120,15,322,1,48,
120,15,360,1,30,
120,15,360,4,0,
120,15,362,1,36,
120,15,408,1,43,
120,15,430,4,0,
120,15,496,4,0,
120,15,503,4,0,
120,15,513,1,40,
120,15,590,4,0,
120,15,605,4,0,
120,16,33,1,1,1
120,16,55,1,4,1
120,16,56,1,53,1
120,16,57,4,0,
120,16,58,4,0,
120,16,59,4,0,
120,16,61,1,18,1
120,16,85,4,0,
120,16,86,4,0,
120,16,87,4,0,
120,16,92,4,0,
120,16,94,1,42,1
120,16,94,4,0,
120,16,104,4,0,
120,16,105,1,10,1
120,16,106,1,1,2
120,16,107,1,31,1
120,16,109,1,40,1
120,16,113,1,46,1
120,16,113,4,0,
120,16,115,4,0,
120,16,127,4,0,
120,16,129,1,16,1
120,16,148,4,0,
120,16,149,1,13,1
120,16,156,4,0,
120,16,164,4,0,
120,16,173,3,0,
120,16,182,4,0,
120,16,196,3,0,
120,16,207,4,0,
120,16,214,4,0,
120,16,216,4,0,
120,16,218,4,0,
120,16,220,3,0,
120,16,229,1,7,1
120,16,237,4,0,
120,16,240,4,0,
120,16,244,4,0,
120,16,258,4,0,
120,16,263,4,0,
120,16,277,3,0,
120,16,278,3,0,
120,16,290,4,0,
120,16,291,4,0,
120,16,293,1,22,1
120,16,322,1,49,1
120,16,324,3,0,
120,16,352,3,0,
120,16,356,3,0,
120,16,360,1,24,1
120,16,360,4,0,
120,16,362,1,28,1
120,16,408,1,37,1
120,16,430,4,0,
120,16,496,4,0,
120,16,503,4,0,
120,16,513,1,35,1
120,16,590,4,0,
120,16,605,4,0,
120,17,33,1,1,1
120,17,55,1,4,
120,17,56,1,53,
120,17,57,4,0,
120,17,58,4,0,
120,17,59,4,0,
120,17,61,1,18,
120,17,85,4,0,
120,17,86,4,0,
120,17,87,4,0,
120,17,92,4,0,
120,17,94,1,42,
120,17,94,4,0,
120,17,104,4,0,
120,17,105,1,10,
120,17,106,1,1,2
120,17,107,1,31,
120,17,109,1,40,
120,17,113,1,46,
120,17,113,4,0,
120,17,115,4,0,
120,17,127,4,0,
120,17,129,1,16,
120,17,149,1,13,
120,17,156,4,0,
120,17,164,4,0,
120,17,182,4,0,
120,17,207,4,0,
120,17,214,4,0,
120,17,216,4,0,
120,17,218,4,0,
120,17,229,1,7,
120,17,237,4,0,
120,17,240,4,0,
120,17,244,4,0,
120,17,258,4,0,
120,17,263,4,0,
120,17,293,1,22,
120,17,322,1,49,
120,17,360,1,24,
120,17,360,4,0,
120,17,362,1,28,
120,17,408,1,37,
120,17,430,4,0,
120,17,496,4,0,
120,17,503,4,0,
120,17,513,1,35,
120,17,590,4,0,
120,17,605,4,0,
120,18,33,1,1,1
120,18,55,1,4,
120,18,56,1,53,
120,18,57,4,0,
120,18,58,4,0,
120,18,59,4,0,
120,18,61,1,18,
120,18,85,4,0,
120,18,86,4,0,
120,18,87,4,0,
120,18,92,4,0,
120,18,94,1,42,
120,18,94,4,0,
120,18,104,4,0,
120,18,105,1,10,
120,18,106,1,1,2
120,18,107,1,31,
120,18,109,1,40,
120,18,113,1,46,
120,18,113,4,0,
120,18,115,4,0,
120,18,127,4,0,
120,18,129,1,16,
120,18,149,1,13,
120,18,156,4,0,
120,18,164,4,0,
120,18,182,4,0,
120,18,207,4,0,
120,18,214,4,0,
120,18,216,4,0,
120,18,218,4,0,
120,18,229,1,7,
120,18,237,4,0,
120,18,240,4,0,
120,18,244,4,0,
120,18,258,4,0,
120,18,263,4,0,
120,18,293,1,22,
120,18,322,1,49,
120,18,360,1,24,
120,18,360,4,0,
120,18,362,1,28,
120,18,408,1,37,
120,18,430,4,0,
120,18,496,4,0,
120,18,503,4,0,
120,18,513,1,35,
120,18,590,4,0,
120,18,605,4,0,
121,1,33,1,1,1
121,1,36,4,0,
121,1,38,4,0,
121,1,55,1,1,2
121,1,55,4,0,
121,1,57,4,0,
121,1,58,4,0,
121,1,59,4,0,
121,1,61,4,0,
121,1,63,4,0,
121,1,85,4,0,
121,1,86,4,0,
121,1,87,4,0,
121,1,92,4,0,
121,1,94,4,0,
121,1,99,4,0,
121,1,100,4,0,
121,1,102,4,0,
121,1,104,4,0,
121,1,106,1,1,3
121,1,115,4,0,
121,1,117,4,0,
121,1,129,4,0,
121,1,130,4,0,
121,1,148,4,0,
121,1,149,4,0,
121,1,156,4,0,
121,1,161,4,0,
121,1,164,4,0,
121,2,33,1,1,1
121,2,36,4,0,
121,2,38,4,0,
121,2,55,1,1,2
121,2,55,4,0,
121,2,57,4,0,
121,2,58,4,0,
121,2,59,4,0,
121,2,61,4,0,
121,2,63,4,0,
121,2,85,4,0,
121,2,86,4,0,
121,2,87,4,0,
121,2,92,4,0,
121,2,94,4,0,
121,2,99,4,0,
121,2,100,4,0,
121,2,102,4,0,
121,2,104,4,0,
121,2,106,1,1,3
121,2,115,4,0,
121,2,117,4,0,
121,2,129,4,0,
121,2,130,4,0,
121,2,148,4,0,
121,2,149,4,0,
121,2,156,4,0,
121,2,161,4,0,
121,2,164,4,0,
121,3,33,1,1,1
121,3,57,4,0,
121,3,59,4,0,
121,3,61,1,1,4
121,3,63,4,0,
121,3,87,4,0,
121,3,92,4,0,
121,3,94,4,0,
121,3,104,4,0,
121,3,105,1,1,3
121,3,109,1,37,
121,3,127,4,0,
121,3,129,4,0,
121,3,138,4,0,
121,3,148,4,0,
121,3,156,4,0,
121,3,171,4,0,
121,3,173,4,0,
121,3,174,4,0,
121,3,182,4,0,
121,3,192,4,0,
121,3,196,4,0,
121,3,203,4,0,
121,3,207,4,0,
121,3,213,4,0,
121,3,214,4,0,
121,3,216,4,0,
121,3,218,4,0,
121,3,229,1,1,2
121,3,237,4,0,
121,3,240,4,0,
121,3,244,4,0,
121,3,250,4,0,
121,4,33,1,1,1
121,4,57,4,0,
121,4,58,3,0,
121,4,59,4,0,
121,4,61,1,1,4
121,4,63,4,0,
121,4,85,3,0,
121,4,87,4,0,
121,4,92,4,0,
121,4,94,4,0,
121,4,104,4,0,
121,4,105,1,1,3
121,4,109,1,37,
121,4,127,4,0,
121,4,129,4,0,
121,4,138,4,0,
121,4,148,4,0,
121,4,156,4,0,
121,4,171,4,0,
121,4,173,4,0,
121,4,174,4,0,
121,4,182,4,0,
121,4,192,4,0,
121,4,196,4,0,
121,4,203,4,0,
121,4,207,4,0,
121,4,213,4,0,
121,4,214,4,0,
121,4,216,4,0,
121,4,218,4,0,
121,4,229,1,1,2
121,4,237,4,0,
121,4,240,4,0,
121,4,244,4,0,
121,4,250,4,0,
121,5,55,1,1,1
121,5,57,4,0,
121,5,58,4,0,
121,5,59,4,0,
121,5,63,4,0,
121,5,85,4,0,
121,5,87,4,0,
121,5,92,4,0,
121,5,94,4,0,
121,5,104,4,0,
121,5,105,1,1,3
121,5,109,1,33,
121,5,113,4,0,
121,5,115,4,0,
121,5,127,4,0,
121,5,129,1,1,4
121,5,148,4,0,
121,5,156,4,0,
121,5,182,4,0,
121,5,216,4,0,
121,5,218,4,0,
121,5,229,1,1,2
121,5,237,4,0,
121,5,240,4,0,
121,5,258,4,0,
121,5,263,4,0,
121,5,285,4,0,
121,5,290,4,0,
121,5,291,4,0,
121,5,352,4,0,
121,6,38,3,0,
121,6,55,1,1,1
121,6,57,4,0,
121,6,58,4,0,
121,6,59,4,0,
121,6,63,4,0,
121,6,85,4,0,
121,6,86,3,0,
121,6,87,4,0,
121,6,92,4,0,
121,6,94,4,0,
121,6,102,3,0,
121,6,104,4,0,
121,6,105,1,1,3
121,6,109,1,33,
121,6,113,4,0,
121,6,115,4,0,
121,6,127,4,0,
121,6,129,1,1,4
121,6,129,3,0,
121,6,138,3,0,
121,6,148,4,0,
121,6,156,4,0,
121,6,164,3,0,
121,6,173,3,0,
121,6,182,4,0,
121,6,196,3,0,
121,6,203,3,0,
121,6,207,3,0,
121,6,214,3,0,
121,6,216,4,0,
121,6,218,4,0,
121,6,229,1,1,2
121,6,237,4,0,
121,6,240,4,0,
121,6,244,3,0,
121,6,258,4,0,
121,6,263,4,0,
121,6,285,4,0,
121,6,290,4,0,
121,6,291,4,0,
121,6,352,4,0,
121,7,38,3,0,
121,7,55,1,1,1
121,7,57,4,0,
121,7,58,4,0,
121,7,59,4,0,
121,7,63,4,0,
121,7,85,4,0,
121,7,86,3,0,
121,7,87,4,0,
121,7,92,4,0,
121,7,94,4,0,
121,7,102,3,0,
121,7,104,4,0,
121,7,105,1,1,3
121,7,109,1,33,
121,7,113,4,0,
121,7,115,4,0,
121,7,127,4,0,
121,7,129,1,1,4
121,7,138,3,0,
121,7,148,4,0,
121,7,156,4,0,
121,7,164,3,0,
121,7,182,4,0,
121,7,216,4,0,
121,7,218,4,0,
121,7,229,1,1,2
121,7,237,4,0,
121,7,240,4,0,
121,7,258,4,0,
121,7,263,4,0,
121,7,285,4,0,
121,7,290,4,0,
121,7,291,4,0,
121,7,352,4,0,
121,8,55,1,1,1
121,8,57,4,0,
121,8,58,4,0,
121,8,59,4,0,
121,8,63,4,0,
121,8,85,4,0,
121,8,86,4,0,
121,8,87,4,0,
121,8,92,4,0,
121,8,94,4,0,
121,8,104,4,0,
121,8,105,1,1,3
121,8,109,1,28,
121,8,113,4,0,
121,8,115,4,0,
121,8,127,4,0,
121,8,129,1,1,4
121,8,138,4,0,
121,8,148,4,0,
121,8,156,4,0,
121,8,164,4,0,
121,8,182,4,0,
121,8,203,4,0,
121,8,207,4,0,
121,8,214,4,0,
121,8,216,4,0,
121,8,218,4,0,
121,8,229,1,1,2
121,8,237,4,0,
121,8,240,4,0,
121,8,244,4,0,
121,8,258,4,0,
121,8,263,4,0,
121,8,278,4,0,
121,8,285,4,0,
121,8,290,4,0,
121,8,352,4,0,
121,8,360,4,0,
121,8,362,4,0,
121,8,363,4,0,
121,8,416,4,0,
121,8,419,4,0,
121,8,430,4,0,
121,8,433,4,0,
121,8,447,4,0,
121,9,55,1,1,1
121,9,57,4,0,
121,9,58,4,0,
121,9,59,4,0,
121,9,63,4,0,
121,9,85,4,0,
121,9,86,4,0,
121,9,87,4,0,
121,9,92,4,0,
121,9,94,4,0,
121,9,104,4,0,
121,9,105,1,1,3
121,9,109,1,28,
121,9,113,4,0,
121,9,115,4,0,
121,9,127,4,0,
121,9,129,1,1,4
121,9,129,3,0,
121,9,138,4,0,
121,9,148,4,0,
121,9,156,4,0,
121,9,164,4,0,
121,9,173,3,0,
121,9,182,4,0,
121,9,196,3,0,
121,9,203,4,0,
121,9,205,3,0,
121,9,207,4,0,
121,9,214,4,0,
121,9,216,4,0,
121,9,218,4,0,
121,9,229,1,1,2
121,9,237,4,0,
121,9,239,3,0,
121,9,240,4,0,
121,9,244,4,0,
121,9,258,4,0,
121,9,263,4,0,
121,9,271,3,0,
121,9,278,4,0,
121,9,285,4,0,
121,9,290,4,0,
121,9,291,3,0,
121,9,324,3,0,
121,9,352,4,0,
121,9,360,4,0,
121,9,362,4,0,
121,9,363,4,0,
121,9,416,4,0,
121,9,419,4,0,
121,9,430,4,0,
121,9,433,4,0,
121,9,447,4,0,
121,10,55,1,1,1
121,10,57,4,0,
121,10,58,4,0,
121,10,59,4,0,
121,10,63,4,0,
121,10,85,4,0,
121,10,86,4,0,
121,10,87,4,0,
121,10,92,4,0,
121,10,94,4,0,
121,10,104,4,0,
121,10,105,1,1,3
121,10,109,1,28,
121,10,113,4,0,
121,10,115,4,0,
121,10,127,4,0,
121,10,129,1,1,4
121,10,129,3,0,
121,10,138,4,0,
121,10,148,4,0,
121,10,156,4,0,
121,10,164,4,0,
121,10,173,3,0,
121,10,182,4,0,
121,10,196,3,0,
121,10,203,4,0,
121,10,205,3,0,
121,10,207,4,0,
121,10,214,4,0,
121,10,216,4,0,
121,10,218,4,0,
121,10,220,3,0,
121,10,229,1,1,2
121,10,237,4,0,
121,10,239,3,0,
121,10,240,4,0,
121,10,244,4,0,
121,10,250,4,0,
121,10,258,4,0,
121,10,263,4,0,
121,10,271,3,0,
121,10,277,3,0,
121,10,278,4,0,
121,10,285,4,0,
121,10,290,4,0,
121,10,291,3,0,
121,10,324,3,0,
121,10,352,4,0,
121,10,356,3,0,
121,10,360,4,0,
121,10,362,4,0,
121,10,363,4,0,
121,10,416,4,0,
121,10,419,4,0,
121,10,430,4,0,
121,10,433,4,0,
121,10,447,4,0,
121,11,55,1,1,1
121,11,57,4,0,
121,11,58,4,0,
121,11,59,4,0,
121,11,63,4,0,
121,11,85,4,0,
121,11,86,4,0,
121,11,87,4,0,
121,11,92,4,0,
121,11,94,4,0,
121,11,104,4,0,
121,11,105,1,1,3
121,11,109,1,28,
121,11,113,4,0,
121,11,115,4,0,
121,11,127,4,0,
121,11,129,1,1,4
121,11,138,4,0,
121,11,148,4,0,
121,11,156,4,0,
121,11,164,4,0,
121,11,182,4,0,
121,11,207,4,0,
121,11,216,4,0,
121,11,218,4,0,
121,11,229,1,1,2
121,11,237,4,0,
121,11,240,4,0,
121,11,244,4,0,
121,11,258,4,0,
121,11,263,4,0,
121,11,291,4,0,
121,11,360,4,0,
121,11,416,4,0,
121,11,430,4,0,
121,11,433,4,0,
121,11,447,4,0,
121,11,473,4,0,
121,11,477,4,0,
121,11,496,4,0,
121,11,503,4,0,
121,12,55,1,1,1
121,12,57,4,0,
121,12,58,4,0,
121,12,59,4,0,
121,12,63,4,0,
121,12,85,4,0,
121,12,87,4,0,
121,12,92,4,0,
121,12,94,4,0,
121,12,104,4,0,
121,12,105,1,1,3
121,12,109,1,33,
121,12,113,4,0,
121,12,115,4,0,
121,12,127,4,0,
121,12,129,1,1,4
121,12,148,4,0,
121,12,156,4,0,
121,12,182,4,0,
121,12,216,4,0,
121,12,218,4,0,
121,12,229,1,1,2
121,12,237,4,0,
121,12,240,4,0,
121,12,258,4,0,
121,12,263,4,0,
121,12,285,4,0,
121,12,290,4,0,
121,12,291,4,0,
121,12,352,4,0,
121,13,38,3,0,
121,13,55,1,1,1
121,13,57,4,0,
121,13,58,4,0,
121,13,59,4,0,
121,13,63,4,0,
121,13,85,4,0,
121,13,86,3,0,
121,13,87,4,0,
121,13,92,4,0,
121,13,94,4,0,
121,13,102,3,0,
121,13,104,4,0,
121,13,105,1,1,3
121,13,109,1,33,
121,13,113,4,0,
121,13,115,4,0,
121,13,127,4,0,
121,13,129,1,1,4
121,13,138,3,0,
121,13,148,4,0,
121,13,156,4,0,
121,13,164,3,0,
121,13,171,3,0,
121,13,182,4,0,
121,13,196,3,0,
121,13,207,3,0,
121,13,216,4,0,
121,13,218,4,0,
121,13,229,1,1,2
121,13,237,4,0,
121,13,240,4,0,
121,13,258,4,0,
121,13,263,4,0,
121,13,285,4,0,
121,13,290,4,0,
121,13,291,4,0,
121,13,352,4,0,
121,14,55,1,1,1
121,14,57,4,0,
121,14,58,4,0,
121,14,59,4,0,
121,14,63,4,0,
121,14,85,4,0,
121,14,86,4,0,
121,14,87,4,0,
121,14,92,4,0,
121,14,94,4,0,
121,14,104,4,0,
121,14,105,1,1,3
121,14,109,1,22,
121,14,113,4,0,
121,14,115,4,0,
121,14,127,4,0,
121,14,129,1,1,4
121,14,138,4,0,
121,14,148,4,0,
121,14,156,4,0,
121,14,164,4,0,
121,14,173,3,0,
121,14,182,4,0,
121,14,196,3,0,
121,14,207,4,0,
121,14,214,3,0,
121,14,216,4,0,
121,14,218,4,0,
121,14,220,3,0,
121,14,229,1,1,2
121,14,237,4,0,
121,14,240,4,0,
121,14,244,4,0,
121,14,258,4,0,
121,14,263,4,0,
121,14,271,3,0,
121,14,277,3,0,
121,14,278,3,0,
121,14,285,3,0,
121,14,291,4,0,
121,14,324,3,0,
121,14,356,3,0,
121,14,360,4,0,
121,14,416,4,0,
121,14,430,4,0,
121,14,433,4,0,
121,14,447,4,0,
121,14,472,3,0,
121,14,473,4,0,
121,14,477,4,0,
121,14,496,4,0,
121,14,503,4,0,
121,15,55,1,1,2
121,15,56,1,1,1
121,15,57,4,0,
121,15,58,4,0,
121,15,59,4,0,
121,15,63,4,0,
121,15,85,4,0,
121,15,86,4,0,
121,15,87,4,0,
121,15,92,4,0,
121,15,94,4,0,
121,15,104,4,0,
121,15,105,1,1,4
121,15,109,1,22,
121,15,113,4,0,
121,15,115,4,0,
121,15,127,4,0,
121,15,129,1,1,5
121,15,138,4,0,
121,15,148,4,0,
121,15,156,4,0,
121,15,164,4,0,
121,15,182,4,0,
121,15,207,4,0,
121,15,214,4,0,
121,15,216,4,0,
121,15,218,4,0,
121,15,229,1,1,3
121,15,237,4,0,
121,15,240,4,0,
121,15,244,4,0,
121,15,258,4,0,
121,15,263,4,0,
121,15,360,4,0,
121,15,416,4,0,
121,15,430,4,0,
121,15,433,4,0,
121,15,447,4,0,
121,15,473,4,0,
121,15,496,4,0,
121,15,503,4,0,
121,15,590,4,0,
121,15,605,4,0,
121,16,55,1,1,2
121,16,56,1,1,1
121,16,57,4,0,
121,16,58,4,0,
121,16,59,4,0,
121,16,63,4,0,
121,16,85,4,0,
121,16,86,4,0,
121,16,87,4,0,
121,16,92,4,0,
121,16,94,4,0,
121,16,104,4,0,
121,16,105,1,1,4
121,16,109,1,40,1
121,16,113,4,0,
121,16,115,4,0,
121,16,127,4,0,
121,16,129,1,1,5
121,16,138,4,0,
121,16,148,4,0,
121,16,156,4,0,
121,16,164,4,0,
121,16,173,3,0,
121,16,182,4,0,
121,16,196,3,0,
121,16,207,4,0,
121,16,214,4,0,
121,16,216,4,0,
121,16,218,4,0,
121,16,220,3,0,
121,16,229,1,1,3
121,16,237,4,0,
121,16,240,4,0,
121,16,244,4,0,
121,16,258,4,0,
121,16,263,4,0,
121,16,271,3,0,
121,16,277,3,0,
121,16,278,3,0,
121,16,285,3,0,
121,16,290,4,0,
121,16,291,4,0,
121,16,324,3,0,
121,16,352,3,0,
121,16,356,3,0,
121,16,360,4,0,
121,16,416,4,0,
121,16,430,4,0,
121,16,433,4,0,
121,16,447,4,0,
121,16,472,3,0,
121,16,473,4,0,
121,16,496,4,0,
121,16,503,4,0,
121,16,590,4,0,
121,16,605,4,0,
121,17,55,1,1,3
121,17,56,1,1,1
121,17,57,4,0,
121,17,58,4,0,
121,17,59,4,0,
121,17,63,4,0,
121,17,85,4,0,
121,17,86,4,0,
121,17,87,4,0,
121,17,92,4,0,
121,17,94,4,0,
121,17,104,4,0,
121,17,105,1,1,5
121,17,109,1,40,
121,17,113,4,0,
121,17,115,4,0,
121,17,127,4,0,
121,17,129,1,1,6
121,17,138,4,0,
121,17,156,4,0,
121,17,164,4,0,
121,17,182,4,0,
121,17,207,4,0,
121,17,214,4,0,
121,17,216,4,0,
121,17,218,4,0,
121,17,229,1,1,4
121,17,237,4,0,
121,17,240,4,0,
121,17,244,4,0,
121,17,258,4,0,
121,17,263,4,0,
121,17,360,4,0,
121,17,416,4,0,
121,17,430,4,0,
121,17,433,4,0,
121,17,447,4,0,
121,17,473,4,0,
121,17,496,4,0,
121,17,503,4,0,
121,17,590,4,0,
121,17,605,4,0,
121,17,671,1,1,2
121,18,55,1,1,3
121,18,56,1,1,1
121,18,57,4,0,
121,18,58,4,0,
121,18,59,4,0,
121,18,63,4,0,
121,18,85,4,0,
121,18,86,4,0,
121,18,87,4,0,
121,18,92,4,0,
121,18,94,4,0,
121,18,104,4,0,
121,18,105,1,1,5
121,18,109,1,40,
121,18,113,4,0,
121,18,115,4,0,
121,18,127,4,0,
121,18,129,1,1,6
121,18,138,4,0,
121,18,156,4,0,
121,18,164,4,0,
121,18,182,4,0,
121,18,207,4,0,
121,18,214,4,0,
121,18,216,4,0,
121,18,218,4,0,
121,18,229,1,1,4
121,18,237,4,0,
121,18,240,4,0,
121,18,244,4,0,
121,18,258,4,0,
121,18,263,4,0,
121,18,360,4,0,
121,18,416,4,0,
121,18,430,4,0,
121,18,433,4,0,
121,18,447,4,0,
121,18,473,4,0,
121,18,496,4,0,
121,18,503,4,0,
121,18,590,4,0,
121,18,605,4,0,
121,18,671,1,1,2
122,1,3,1,31,
122,1,5,4,0,
122,1,25,4,0,
122,1,34,4,0,
122,1,36,4,0,
122,1,38,4,0,
122,1,63,4,0,
122,1,66,4,0,
122,1,68,4,0,
122,1,69,4,0,
122,1,76,4,0,
122,1,85,4,0,
122,1,86,4,0,
122,1,87,4,0,
122,1,92,4,0,
122,1,93,1,1,1
122,1,93,1,15,
122,1,94,4,0,
122,1,96,1,39,
122,1,99,4,0,
122,1,100,4,0,
122,1,102,4,0,
122,1,104,4,0,
122,1,112,1,1,2
122,1,113,1,23,
122,1,115,4,0,
122,1,117,4,0,
122,1,118,4,0,
122,1,130,4,0,
122,1,148,4,0,
122,1,149,4,0,
122,1,156,4,0,
122,1,164,1,47,
122,1,164,4,0,
122,2,3,1,31,
122,2,5,4,0,
122,2,25,4,0,
122,2,34,4,0,
122,2,36,4,0,
122,2,38,4,0,
122,2,63,4,0,
122,2,66,4,0,
122,2,68,4,0,
122,2,69,4,0,
122,2,76,4,0,
122,2,85,4,0,
122,2,86,4,0,
122,2,87,4,0,
122,2,92,4,0,
122,2,93,1,1,1
122,2,93,1,15,
122,2,94,4,0,
122,2,96,1,39,
122,2,99,4,0,
122,2,100,4,0,
122,2,102,4,0,
122,2,104,4,0,
122,2,112,1,1,2
122,2,113,1,23,
122,2,115,4,0,
122,2,117,4,0,
122,2,118,4,0,
122,2,130,4,0,
122,2,148,4,0,
122,2,149,4,0,
122,2,156,4,0,
122,2,164,1,47,
122,2,164,4,0,
122,3,3,1,21,
122,3,7,4,0,
122,3,8,4,0,
122,3,9,4,0,
122,3,29,4,0,
122,3,60,1,36,
122,3,63,4,0,
122,3,76,4,0,
122,3,87,4,0,
122,3,92,4,0,
122,3,93,1,6,
122,3,94,4,0,
122,3,95,2,0,
122,3,96,1,16,
122,3,102,2,0,
122,3,104,4,0,
122,3,112,1,1,
122,3,113,1,26,1
122,3,115,1,26,2
122,3,138,4,0,
122,3,148,4,0,
122,3,156,4,0,
122,3,164,1,11,
122,3,168,4,0,
122,3,171,4,0,
122,3,173,4,0,
122,3,174,4,0,
122,3,182,4,0,
122,3,189,4,0,
122,3,192,4,0,
122,3,203,4,0,
122,3,207,4,0,
122,3,213,4,0,
122,3,214,4,0,
122,3,216,4,0,
122,3,218,4,0,
122,3,219,1,46,
122,3,223,4,0,
122,3,226,1,41,
122,3,227,1,31,
122,3,237,4,0,
122,3,241,4,0,
122,3,244,4,0,
122,3,247,4,0,
122,3,248,2,0,
122,4,3,1,21,
122,4,7,4,0,
122,4,8,4,0,
122,4,9,4,0,
122,4,29,4,0,
122,4,60,1,36,
122,4,63,4,0,
122,4,76,4,0,
122,4,85,3,0,
122,4,87,4,0,
122,4,92,4,0,
122,4,93,1,6,
122,4,94,4,0,
122,4,95,2,0,
122,4,96,1,16,
122,4,102,2,0,
122,4,104,4,0,
122,4,112,1,1,
122,4,113,1,26,1
122,4,115,1,26,2
122,4,138,4,0,
122,4,148,4,0,
122,4,156,4,0,
122,4,164,1,11,
122,4,168,4,0,
122,4,171,4,0,
122,4,173,4,0,
122,4,174,4,0,
122,4,182,4,0,
122,4,189,4,0,
122,4,192,4,0,
122,4,203,4,0,
122,4,207,4,0,
122,4,213,4,0,
122,4,214,4,0,
122,4,216,4,0,
122,4,218,4,0,
122,4,219,1,46,
122,4,223,4,0,
122,4,226,1,41,
122,4,227,1,31,
122,4,237,4,0,
122,4,241,4,0,
122,4,244,4,0,
122,4,247,4,0,
122,4,248,2,0,
122,5,3,1,17,
122,5,60,1,29,
122,5,63,4,0,
122,5,76,4,0,
122,5,85,4,0,
122,5,87,4,0,
122,5,92,4,0,
122,5,93,1,5,
122,5,94,1,45,
122,5,94,4,0,
122,5,95,2,0,
122,5,96,1,13,
122,5,102,2,0,
122,5,104,4,0,
122,5,112,1,1,
122,5,113,1,21,1
122,5,113,4,0,
122,5,115,1,21,2
122,5,115,4,0,
122,5,148,4,0,
122,5,156,4,0,
122,5,164,1,9,
122,5,168,4,0,
122,5,182,4,0,
122,5,213,4,0,
122,5,216,4,0,
122,5,218,4,0,
122,5,219,1,53,
122,5,219,4,0,
122,5,226,1,49,
122,5,227,1,25,
122,5,237,4,0,
122,5,240,4,0,
122,5,241,4,0,
122,5,244,2,0,
122,5,247,4,0,
122,5,248,2,0,
122,5,252,2,0,
122,5,259,4,0,
122,5,263,4,0,
122,5,264,4,0,
122,5,269,4,0,
122,5,271,1,37,
122,5,271,2,0,
122,5,272,1,41,
122,5,278,1,33,
122,5,280,4,0,
122,5,285,4,0,
122,5,289,4,0,
122,5,290,4,0,
122,5,347,4,0,
122,5,351,4,0,
122,6,3,1,17,
122,6,5,3,0,
122,6,7,3,0,
122,6,8,3,0,
122,6,9,3,0,
122,6,25,3,0,
122,6,34,3,0,
122,6,38,3,0,
122,6,60,1,29,
122,6,63,4,0,
122,6,68,3,0,
122,6,69,3,0,
122,6,76,4,0,
122,6,85,4,0,
122,6,86,3,0,
122,6,87,4,0,
122,6,92,4,0,
122,6,93,1,5,
122,6,94,1,45,
122,6,94,4,0,
122,6,95,2,0,
122,6,96,1,13,
122,6,102,2,0,
122,6,102,3,0,
122,6,104,4,0,
122,6,112,1,1,
122,6,113,1,21,1
122,6,113,4,0,
122,6,115,1,21,2
122,6,115,4,0,
122,6,118,3,0,
122,6,138,3,0,
122,6,148,4,0,
122,6,156,4,0,
122,6,164,1,9,
122,6,164,3,0,
122,6,168,4,0,
122,6,173,3,0,
122,6,182,4,0,
122,6,189,3,0,
122,6,203,3,0,
122,6,207,3,0,
122,6,213,4,0,
122,6,214,3,0,
122,6,216,4,0,
122,6,218,4,0,
122,6,219,1,53,
122,6,219,4,0,
122,6,226,1,49,
122,6,227,1,25,
122,6,237,4,0,
122,6,240,4,0,
122,6,241,4,0,
122,6,244,2,0,
122,6,244,3,0,
122,6,247,4,0,
122,6,248,2,0,
122,6,252,2,0,
122,6,259,4,0,
122,6,263,4,0,
122,6,264,4,0,
122,6,269,4,0,
122,6,271,1,37,
122,6,271,2,0,
122,6,272,1,41,
122,6,278,1,33,
122,6,280,4,0,
122,6,285,4,0,
122,6,289,4,0,
122,6,290,4,0,
122,6,347,4,0,
122,6,351,4,0,
122,7,3,1,15,
122,7,5,3,0,
122,7,25,3,0,
122,7,34,3,0,
122,7,38,3,0,
122,7,60,1,29,
122,7,63,4,0,
122,7,68,3,0,
122,7,69,3,0,
122,7,76,4,0,
122,7,85,4,0,
122,7,86,3,0,
122,7,87,4,0,
122,7,92,4,0,
122,7,93,1,5,
122,7,94,1,43,
122,7,94,4,0,
122,7,95,2,0,
122,7,96,1,12,
122,7,102,2,0,
122,7,102,3,0,
122,7,104,4,0,
122,7,112,1,1,
122,7,113,1,19,1
122,7,113,4,0,
122,7,115,1,19,2
122,7,115,4,0,
122,7,118,3,0,
122,7,138,3,0,
122,7,148,4,0,
122,7,156,4,0,
122,7,164,1,8,
122,7,164,3,0,
122,7,168,4,0,
122,7,182,4,0,
122,7,213,4,0,
122,7,216,4,0,
122,7,218,4,0,
122,7,219,1,50,
122,7,219,4,0,
122,7,226,1,47,
122,7,227,1,26,
122,7,237,4,0,
122,7,240,4,0,
122,7,241,4,0,
122,7,244,2,0,
122,7,247,4,0,
122,7,248,2,0,
122,7,252,2,0,
122,7,259,4,0,
122,7,263,4,0,
122,7,264,4,0,
122,7,269,4,0,
122,7,271,1,36,
122,7,271,2,0,
122,7,272,1,40,
122,7,278,1,33,
122,7,280,4,0,
122,7,285,4,0,
122,7,289,4,0,
122,7,290,4,0,
122,7,345,1,22,
122,7,347,4,0,
122,7,351,4,0,
122,8,3,1,15,
122,8,60,1,25,
122,8,63,4,0,
122,8,76,4,0,
122,8,85,4,0,
122,8,86,4,0,
122,8,87,4,0,
122,8,92,4,0,
122,8,93,1,1,5
122,8,94,1,39,
122,8,94,4,0,
122,8,95,2,0,
122,8,96,1,8,
122,8,102,1,18,
122,8,102,2,0,
122,8,104,4,0,
122,8,109,2,0,
122,8,112,1,1,4
122,8,113,1,22,1
122,8,113,4,0,
122,8,115,1,22,2
122,8,115,4,0,
122,8,138,4,0,
122,8,148,4,0,
122,8,156,4,0,
122,8,164,1,29,
122,8,164,4,0,
122,8,168,4,0,
122,8,182,4,0,
122,8,203,4,0,
122,8,207,4,0,
122,8,213,4,0,
122,8,214,4,0,
122,8,216,4,0,
122,8,218,4,0,
122,8,219,1,50,
122,8,219,4,0,
122,8,226,1,46,
122,8,227,1,11,
122,8,237,4,0,
122,8,240,4,0,
122,8,241,4,0,
122,8,244,2,0,
122,8,244,4,0,
122,8,247,4,0,
122,8,248,2,0,
122,8,252,2,0,
122,8,259,4,0,
122,8,263,4,0,
122,8,264,4,0,
122,8,269,4,0,
122,8,271,1,36,
122,8,271,2,0,
122,8,272,1,43,
122,8,278,1,32,
122,8,278,4,0,
122,8,280,4,0,
122,8,285,4,0,
122,8,289,4,0,
122,8,290,4,0,
122,8,298,2,0,
122,8,332,4,0,
122,8,345,1,1,1
122,8,347,4,0,
122,8,351,4,0,
122,8,358,2,0,
122,8,363,4,0,
122,8,371,4,0,
122,8,374,4,0,
122,8,383,1,4,
122,8,384,1,1,2
122,8,385,1,1,3
122,8,409,4,0,
122,8,411,4,0,
122,8,412,4,0,
122,8,416,4,0,
122,8,433,4,0,
122,8,445,4,0,
122,8,447,4,0,
122,8,451,4,0,
122,9,3,1,15,
122,9,7,3,0,
122,9,8,3,0,
122,9,9,3,0,
122,9,60,1,25,
122,9,63,4,0,
122,9,76,4,0,
122,9,85,4,0,
122,9,86,4,0,
122,9,87,4,0,
122,9,92,4,0,
122,9,93,1,1,5
122,9,94,1,39,
122,9,94,4,0,
122,9,95,2,0,
122,9,96,1,8,
122,9,102,1,18,
122,9,102,2,0,
122,9,104,4,0,
122,9,109,2,0,
122,9,112,1,1,4
122,9,113,1,22,1
122,9,113,4,0,
122,9,115,1,22,2
122,9,115,4,0,
122,9,138,4,0,
122,9,148,4,0,
122,9,156,4,0,
122,9,164,1,29,
122,9,164,4,0,
122,9,168,4,0,
122,9,173,3,0,
122,9,182,4,0,
122,9,189,3,0,
122,9,203,4,0,
122,9,207,4,0,
122,9,213,4,0,
122,9,214,4,0,
122,9,216,4,0,
122,9,218,4,0,
122,9,219,1,50,
122,9,219,4,0,
122,9,226,1,46,
122,9,227,1,11,
122,9,237,4,0,
122,9,240,4,0,
122,9,241,4,0,
122,9,244,2,0,
122,9,244,4,0,
122,9,247,4,0,
122,9,248,2,0,
122,9,252,2,0,
122,9,259,4,0,
122,9,263,4,0,
122,9,264,4,0,
122,9,269,4,0,
122,9,270,3,0,
122,9,271,1,36,
122,9,271,2,0,
122,9,271,3,0,
122,9,272,1,43,
122,9,278,1,32,
122,9,278,4,0,
122,9,280,4,0,
122,9,285,4,0,
122,9,289,4,0,
122,9,290,4,0,
122,9,298,2,0,
122,9,324,3,0,
122,9,332,4,0,
122,9,334,3,0,
122,9,345,1,1,1
122,9,347,4,0,
122,9,351,4,0,
122,9,358,2,0,
122,9,363,4,0,
122,9,371,4,0,
122,9,374,4,0,
122,9,383,1,4,
122,9,384,1,1,2
122,9,385,1,1,3
122,9,409,4,0,
122,9,411,4,0,
122,9,412,4,0,
122,9,416,4,0,
122,9,428,3,0,
122,9,433,4,0,
122,9,445,4,0,
122,9,447,4,0,
122,9,451,4,0,
122,10,3,1,15,
122,10,7,3,0,
122,10,8,3,0,
122,10,9,3,0,
122,10,29,3,0,
122,10,60,1,25,
122,10,63,4,0,
122,10,76,4,0,
122,10,85,4,0,
122,10,86,4,0,
122,10,87,4,0,
122,10,92,4,0,
122,10,93,1,1,5
122,10,94,1,39,
122,10,94,4,0,
122,10,95,2,0,
122,10,96,1,8,
122,10,102,1,18,
122,10,102,2,0,
122,10,104,4,0,
122,10,109,2,0,
122,10,112,1,1,4
122,10,113,1,22,1
122,10,113,4,0,
122,10,115,1,22,2
122,10,115,4,0,
122,10,138,4,0,
122,10,148,4,0,
122,10,156,4,0,
122,10,164,1,29,
122,10,164,4,0,
122,10,168,4,0,
122,10,173,3,0,
122,10,182,4,0,
122,10,189,3,0,
122,10,203,4,0,
122,10,207,4,0,
122,10,213,4,0,
122,10,214,4,0,
122,10,216,4,0,
122,10,218,4,0,
122,10,219,1,50,
122,10,219,4,0,
122,10,226,1,46,
122,10,227,1,11,
122,10,237,4,0,
122,10,240,4,0,
122,10,241,4,0,
122,10,244,2,0,
122,10,244,4,0,
122,10,247,4,0,
122,10,248,2,0,
122,10,252,2,0,
122,10,259,4,0,
122,10,263,4,0,
122,10,264,4,0,
122,10,269,4,0,
122,10,270,3,0,
122,10,271,1,36,
122,10,271,2,0,
122,10,271,3,0,
122,10,272,1,43,
122,10,272,3,0,
122,10,277,3,0,
122,10,278,1,32,
122,10,278,4,0,
122,10,280,4,0,
122,10,285,4,0,
122,10,289,4,0,
122,10,290,4,0,
122,10,298,2,0,
122,10,324,3,0,
122,10,332,4,0,
122,10,334,3,0,
122,10,345,1,1,1
122,10,347,4,0,
122,10,351,4,0,
122,10,358,2,0,
122,10,363,4,0,
122,10,371,4,0,
122,10,374,4,0,
122,10,383,1,4,
122,10,384,1,1,2
122,10,385,1,1,3
122,10,409,4,0,
122,10,411,4,0,
122,10,412,4,0,
122,10,416,4,0,
122,10,417,2,0,
122,10,428,3,0,
122,10,433,4,0,
122,10,445,4,0,
122,10,447,4,0,
122,10,451,4,0,
122,11,3,1,15,
122,11,60,1,25,
122,11,63,4,0,
122,11,76,4,0,
122,11,85,4,0,
122,11,86,4,0,
122,11,87,4,0,
122,11,92,4,0,
122,11,93,1,1,7
122,11,94,1,39,
122,11,94,4,0,
122,11,95,2,0,
122,11,96,1,8,
122,11,102,1,18,
122,11,102,2,0,
122,11,104,4,0,
122,11,109,2,0,
122,11,112,1,1,6
122,11,113,1,22,1
122,11,113,4,0,
122,11,115,1,22,2
122,11,115,4,0,
122,11,138,4,0,
122,11,148,4,0,
122,11,156,4,0,
122,11,164,1,29,
122,11,164,4,0,
122,11,168,4,0,
122,11,182,4,0,
122,11,196,2,0,
122,11,207,4,0,
122,11,213,4,0,
122,11,216,4,0,
122,11,218,4,0,
122,11,219,1,50,
122,11,219,4,0,
122,11,226,1,46,
122,11,227,1,11,
122,11,237,4,0,
122,11,240,4,0,
122,11,241,4,0,
122,11,244,4,0,
122,11,247,4,0,
122,11,248,2,0,
122,11,252,2,0,
122,11,259,4,0,
122,11,263,4,0,
122,11,269,4,0,
122,11,271,1,36,
122,11,271,2,0,
122,11,272,1,43,
122,11,278,1,32,
122,11,280,4,0,
122,11,298,2,0,
122,11,332,4,0,
122,11,345,1,1,1
122,11,347,4,0,
122,11,358,2,0,
122,11,371,4,0,
122,11,374,4,0,
122,11,383,1,4,
122,11,384,1,1,4
122,11,385,1,1,5
122,11,411,4,0,
122,11,412,4,0,
122,11,416,4,0,
122,11,417,2,0,
122,11,433,4,0,
122,11,447,4,0,
122,11,451,4,0,
122,11,469,1,1,3
122,11,471,2,0,
122,11,473,4,0,
122,11,477,4,0,
122,11,478,2,0,
122,11,496,4,0,
122,11,501,1,1,2
122,12,3,1,17,
122,12,60,1,29,
122,12,63,4,0,
122,12,76,4,0,
122,12,85,4,0,
122,12,87,4,0,
122,12,92,4,0,
122,12,93,1,5,
122,12,94,1,45,
122,12,94,4,0,
122,12,96,1,13,
122,12,104,4,0,
122,12,112,1,1,
122,12,113,1,21,1
122,12,113,4,0,
122,12,115,1,21,2
122,12,115,4,0,
122,12,148,4,0,
122,12,156,4,0,
122,12,164,1,9,
122,12,168,4,0,
122,12,182,4,0,
122,12,213,4,0,
122,12,216,4,0,
122,12,218,4,0,
122,12,219,1,53,
122,12,219,4,0,
122,12,226,1,49,
122,12,227,1,25,
122,12,237,4,0,
122,12,240,4,0,
122,12,241,4,0,
122,12,247,4,0,
122,12,259,4,0,
122,12,263,4,0,
122,12,264,4,0,
122,12,269,4,0,
122,12,271,1,37,
122,12,272,1,41,
122,12,278,1,33,
122,12,280,4,0,
122,12,285,4,0,
122,12,289,4,0,
122,12,290,4,0,
122,12,347,4,0,
122,12,351,4,0,
122,13,3,1,17,
122,13,34,3,0,
122,13,38,3,0,
122,13,60,1,29,
122,13,63,4,0,
122,13,69,3,0,
122,13,76,4,0,
122,13,85,4,0,
122,13,86,3,0,
122,13,87,4,0,
122,13,92,4,0,
122,13,93,1,5,
122,13,94,1,45,
122,13,94,4,0,
122,13,96,1,13,
122,13,102,3,0,
122,13,104,4,0,
122,13,112,1,1,
122,13,113,1,21,1
122,13,113,4,0,
122,13,115,1,21,2
122,13,115,4,0,
122,13,138,3,0,
122,13,148,4,0,
122,13,156,4,0,
122,13,164,1,9,
122,13,164,3,0,
122,13,168,4,0,
122,13,171,3,0,
122,13,182,4,0,
122,13,207,3,0,
122,13,213,4,0,
122,13,216,4,0,
122,13,218,4,0,
122,13,219,1,53,
122,13,219,4,0,
122,13,226,1,49,
122,13,227,1,25,
122,13,237,4,0,
122,13,240,4,0,
122,13,241,4,0,
122,13,247,4,0,
122,13,259,4,0,
122,13,263,4,0,
122,13,264,4,0,
122,13,269,4,0,
122,13,271,1,37,
122,13,272,1,41,
122,13,278,1,33,
122,13,280,4,0,
122,13,285,4,0,
122,13,289,4,0,
122,13,290,4,0,
122,13,347,4,0,
122,13,351,4,0,
122,14,3,1,11,
122,14,7,3,0,
122,14,8,3,0,
122,14,9,3,0,
122,14,60,1,25,
122,14,63,4,0,
122,14,76,4,0,
122,14,85,4,0,
122,14,86,4,0,
122,14,87,4,0,
122,14,92,4,0,
122,14,93,1,1,7
122,14,94,1,39,
122,14,94,4,0,
122,14,95,2,0,
122,14,96,1,8,
122,14,102,1,15,1
122,14,102,2,0,
122,14,104,4,0,
122,14,109,2,0,
122,14,112,1,1,6
122,14,113,1,22,1
122,14,113,4,0,
122,14,115,1,22,2
122,14,115,4,0,
122,14,138,4,0,
122,14,148,4,0,
122,14,149,1,15,2
122,14,156,4,0,
122,14,164,1,29,
122,14,164,4,0,
122,14,168,4,0,
122,14,173,3,0,
122,14,182,4,0,
122,14,196,2,0,
122,14,196,3,0,
122,14,207,4,0,
122,14,213,4,0,
122,14,214,3,0,
122,14,216,4,0,
122,14,218,4,0,
122,14,219,1,50,
122,14,219,4,0,
122,14,226,1,46,
122,14,227,1,18,
122,14,237,4,0,
122,14,240,4,0,
122,14,241,4,0,
122,14,244,4,0,
122,14,247,4,0,
122,14,248,2,0,
122,14,252,2,0,
122,14,259,4,0,
122,14,263,4,0,
122,14,269,4,0,
122,14,270,3,0,
122,14,271,1,36,
122,14,271,2,0,
122,14,271,3,0,
122,14,272,1,43,
122,14,272,3,0,
122,14,277,3,0,
122,14,278,1,32,
122,14,278,3,0,
122,14,280,4,0,
122,14,285,3,0,
122,14,289,3,0,
122,14,298,2,0,
122,14,324,3,0,
122,14,332,4,0,
122,14,334,3,0,
122,14,343,3,0,
122,14,345,1,1,1
122,14,347,4,0,
122,14,358,2,0,
122,14,371,4,0,
122,14,374,4,0,
122,14,383,1,4,
122,14,384,1,1,4
122,14,385,1,1,5
122,14,409,3,0,
122,14,411,4,0,
122,14,412,4,0,
122,14,416,4,0,
122,14,417,2,0,
122,14,428,3,0,
122,14,433,4,0,
122,14,447,4,0,
122,14,451,4,0,
122,14,469,1,1,3
122,14,471,2,0,
122,14,472,3,0,
122,14,473,4,0,
122,14,477,4,0,
122,14,478,2,0,
122,14,478,3,0,
122,14,492,3,0,
122,14,496,4,0,
122,14,501,1,1,2
122,15,3,1,11,
122,15,60,1,25,
122,15,63,4,0,
122,15,76,4,0,
122,15,85,4,0,
122,15,86,4,0,
122,15,87,4,0,
122,15,92,4,0,
122,15,93,1,1,8
122,15,94,1,39,
122,15,94,4,0,
122,15,95,2,0,
122,15,96,1,8,
122,15,102,1,15,1
122,15,102,2,0,
122,15,104,4,0,
122,15,109,2,0,
122,15,112,1,1,7
122,15,113,1,22,1
122,15,113,4,0,
122,15,115,1,22,2
122,15,115,4,0,
122,15,138,4,0,
122,15,148,4,0,
122,15,149,1,15,2
122,15,156,4,0,
122,15,164,1,29,
122,15,164,4,0,
122,15,168,4,0,
122,15,182,4,0,
122,15,196,2,0,
122,15,207,4,0,
122,15,213,4,0,
122,15,214,4,0,
122,15,216,4,0,
122,15,218,4,0,
122,15,219,1,50,
122,15,219,4,0,
122,15,226,1,46,
122,15,227,1,18,
122,15,237,4,0,
122,15,240,4,0,
122,15,241,4,0,
122,15,244,4,0,
122,15,247,4,0,
122,15,248,2,0,
122,15,252,2,0,
122,15,259,4,0,
122,15,263,4,0,
122,15,269,4,0,
122,15,271,1,36,
122,15,271,2,0,
122,15,272,1,43,
122,15,278,1,32,
122,15,280,4,0,
122,15,298,2,0,
122,15,332,4,0,
122,15,345,1,1,2
122,15,347,4,0,
122,15,358,2,0,
122,15,371,4,0,
122,15,374,4,0,
122,15,383,1,4,
122,15,384,1,1,5
122,15,385,1,1,6
122,15,411,4,0,
122,15,412,4,0,
122,15,416,4,0,
122,15,417,2,0,
122,15,433,4,0,
122,15,447,4,0,
122,15,451,4,0,
122,15,469,1,1,4
122,15,471,2,0,
122,15,473,4,0,
122,15,478,2,0,
122,15,496,4,0,
122,15,501,1,1,3
122,15,581,1,1,1
122,15,590,4,0,
122,15,605,4,0,
122,15,611,4,0,
122,15,612,4,0,
122,16,3,1,11,1
122,16,7,3,0,
122,16,8,3,0,
122,16,9,3,0,
122,16,60,1,25,1
122,16,63,4,0,
122,16,76,4,0,
122,16,85,4,0,
122,16,86,4,0,
122,16,87,4,0,
122,16,92,4,0,
122,16,93,1,1,8
122,16,94,1,39,1
122,16,94,4,0,
122,16,95,2,0,
122,16,96,1,8,1
122,16,102,1,15,1
122,16,102,2,0,
122,16,104,4,0,
122,16,109,2,0,
122,16,112,1,1,7
122,16,113,1,22,1
122,16,113,4,0,
122,16,115,1,22,2
122,16,115,4,0,
122,16,138,4,0,
122,16,148,4,0,
122,16,149,1,15,2
122,16,156,4,0,
122,16,164,1,29,1
122,16,164,4,0,
122,16,168,4,0,
122,16,173,3,0,
122,16,182,4,0,
122,16,196,2,0,
122,16,196,3,0,
122,16,207,4,0,
122,16,213,4,0,
122,16,214,4,0,
122,16,216,4,0,
122,16,218,4,0,
122,16,219,1,50,1
122,16,219,4,0,
122,16,226,1,46,1
122,16,227,1,18,1
122,16,237,4,0,
122,16,240,4,0,
122,16,241,4,0,
122,16,244,4,0,
122,16,247,4,0,
122,16,248,2,0,
122,16,252,2,0,
122,16,259,4,0,
122,16,263,4,0,
122,16,264,3,0,
122,16,269,4,0,
122,16,270,3,0,
122,16,271,1,36,1
122,16,271,2,0,
122,16,271,3,0,
122,16,272,1,43,1
122,16,272,3,0,
122,16,277,3,0,
122,16,278,1,32,1
122,16,278,3,0,
122,16,280,4,0,
122,16,285,3,0,
122,16,289,3,0,
122,16,290,4,0,
122,16,298,2,0,
122,16,324,3,0,
122,16,332,4,0,
122,16,334,3,0,
122,16,343,3,0,
122,16,345,1,1,2
122,16,347,4,0,
122,16,351,3,0,
122,16,358,2,0,
122,16,371,4,0,
122,16,374,4,0,
122,16,383,1,4,1
122,16,384,1,1,5
122,16,385,1,1,6
122,16,409,3,0,
122,16,411,4,0,
122,16,412,4,0,
122,16,416,4,0,
122,16,417,2,0,
122,16,428,3,0,
122,16,433,4,0,
122,16,447,4,0,
122,16,451,4,0,
122,16,469,1,1,4
122,16,471,2,0,
122,16,472,3,0,
122,16,473,4,0,
122,16,478,2,0,
122,16,478,3,0,
122,16,492,3,0,
122,16,496,4,0,
122,16,501,1,1,3
122,16,581,1,1,1
122,16,590,4,0,
122,16,605,4,0,
122,16,611,4,0,
122,16,612,4,0,
122,17,3,1,11,
122,17,60,1,25,
122,17,63,4,0,
122,17,76,4,0,
122,17,85,4,0,
122,17,86,4,0,
122,17,87,4,0,
122,17,92,4,0,
122,17,93,1,1,8
122,17,94,1,39,
122,17,94,4,0,
122,17,95,2,0,
122,17,96,1,8,
122,17,102,1,15,1
122,17,102,2,0,
122,17,104,4,0,
122,17,109,2,0,
122,17,112,1,1,7
122,17,113,1,22,1
122,17,113,4,0,
122,17,115,1,22,2
122,17,115,4,0,
122,17,138,4,0,
122,17,149,1,15,2
122,17,156,4,0,
122,17,164,1,29,
122,17,164,4,0,
122,17,168,4,0,
122,17,182,4,0,
122,17,196,2,0,
122,17,207,4,0,
122,17,213,4,0,
122,17,214,4,0,
122,17,216,4,0,
122,17,218,4,0,
122,17,219,1,50,
122,17,219,4,0,
122,17,226,1,46,
122,17,227,1,18,
122,17,237,4,0,
122,17,240,4,0,
122,17,241,4,0,
122,17,244,4,0,
122,17,247,4,0,
122,17,248,2,0,
122,17,252,2,0,
122,17,259,4,0,
122,17,263,4,0,
122,17,269,4,0,
122,17,271,1,36,
122,17,271,2,0,
122,17,272,1,43,
122,17,278,1,32,
122,17,280,4,0,
122,17,298,2,0,
122,17,332,4,0,
122,17,345,1,1,2
122,17,347,4,0,
122,17,358,2,0,
122,17,371,4,0,
122,17,374,4,0,
122,17,383,1,4,
122,17,384,1,1,5
122,17,385,1,1,6
122,17,411,4,0,
122,17,412,4,0,
122,17,416,4,0,
122,17,417,2,0,
122,17,433,4,0,
122,17,447,4,0,
122,17,451,4,0,
122,17,469,1,1,4
122,17,471,2,0,
122,17,473,4,0,
122,17,478,2,0,
122,17,496,4,0,
122,17,501,1,1,3
122,17,581,1,1,1
122,17,590,4,0,
122,17,605,4,0,
122,17,611,4,0,
122,18,1,1,1,8
122,18,3,1,11,
122,18,60,1,25,
122,18,63,4,0,
122,18,76,4,0,
122,18,85,4,0,
122,18,86,4,0,
122,18,87,4,0,
122,18,92,4,0,
122,18,93,1,1,9
122,18,94,1,39,
122,18,94,4,0,
122,18,95,2,0,
122,18,96,1,8,
122,18,102,1,15,1
122,18,102,2,0,
122,18,104,4,0,
122,18,109,2,0,
122,18,112,1,1,7
122,18,113,1,22,1
122,18,113,4,0,
122,18,115,1,22,2
122,18,115,4,0,
122,18,138,4,0,
122,18,149,1,15,2
122,18,156,4,0,
122,18,164,1,29,
122,18,164,4,0,
122,18,168,4,0,
122,18,182,4,0,
122,18,196,2,0,
122,18,207,4,0,
122,18,213,4,0,
122,18,214,4,0,
122,18,216,4,0,
122,18,218,4,0,
122,18,219,1,50,
122,18,219,4,0,
122,18,226,1,46,
122,18,227,1,18,
122,18,237,4,0,
122,18,240,4,0,
122,18,241,4,0,
122,18,244,4,0,
122,18,247,4,0,
122,18,248,2,0,
122,18,252,2,0,
122,18,259,4,0,
122,18,263,4,0,
122,18,269,4,0,
122,18,271,1,36,
122,18,271,2,0,
122,18,272,1,43,
122,18,278,1,32,
122,18,280,4,0,
122,18,298,2,0,
122,18,332,4,0,
122,18,345,1,1,2
122,18,347,4,0,
122,18,358,2,0,
122,18,371,4,0,
122,18,374,4,0,
122,18,383,1,4,
122,18,384,1,1,5
122,18,385,1,1,6
122,18,411,4,0,
122,18,412,4,0,
122,18,416,4,0,
122,18,417,2,0,
122,18,433,4,0,
122,18,447,4,0,
122,18,451,4,0,
122,18,469,1,1,4
122,18,471,2,0,
122,18,473,4,0,
122,18,478,2,0,
122,18,496,4,0,
122,18,501,1,1,3
122,18,581,1,1,1
122,18,590,4,0,
122,18,605,4,0,
122,18,611,4,0,
122,18,678,2,0,
123,1,14,1,35,
123,1,14,4,0,
123,1,15,4,0,
123,1,36,4,0,
123,1,38,4,0,
123,1,43,1,17,
123,1,63,4,0,
123,1,92,4,0,
123,1,97,1,42,
123,1,98,1,1,
123,1,99,4,0,
123,1,102,4,0,
123,1,104,1,24,
123,1,104,4,0,
123,1,116,1,20,
123,1,117,4,0,
123,1,129,4,0,
123,1,130,4,0,
123,1,156,4,0,
123,1,163,1,29,
123,1,164,4,0,
123,2,14,1,35,
123,2,14,4,0,
123,2,15,4,0,
123,2,17,1,50,
123,2,36,4,0,
123,2,38,4,0,
123,2,43,1,17,
123,2,63,4,0,
123,2,92,4,0,
123,2,97,1,42,
123,2,98,1,1,
123,2,99,4,0,
123,2,102,4,0,
123,2,104,1,24,
123,2,104,4,0,
123,2,116,1,20,
123,2,117,4,0,
123,2,129,4,0,
123,2,130,4,0,
123,2,156,4,0,
123,2,163,1,29,
123,2,164,4,0,
123,3,13,2,0,
123,3,14,1,42,
123,3,15,4,0,
123,3,17,1,30,
123,3,29,4,0,
123,3,43,1,1,2
123,3,63,4,0,
123,3,68,2,0,
123,3,92,4,0,
123,3,97,1,24,
123,3,98,1,1,1
123,3,104,1,48,
123,3,104,4,0,
123,3,113,2,0,
123,3,116,1,6,
123,3,129,4,0,
123,3,156,4,0,
123,3,163,1,36,
123,3,168,4,0,
123,3,173,4,0,
123,3,174,4,0,
123,3,179,2,0,
123,3,182,4,0,
123,3,197,4,0,
123,3,203,4,0,
123,3,206,1,18,
123,3,207,4,0,
123,3,210,4,0,
123,3,211,4,0,
123,3,213,4,0,
123,3,214,4,0,
123,3,216,4,0,
123,3,218,4,0,
123,3,219,2,0,
123,3,226,2,0,
123,3,228,1,12,
123,3,237,4,0,
123,3,241,4,0,
123,3,249,4,0,
123,4,13,2,0,
123,4,14,1,42,
123,4,15,4,0,
123,4,17,1,30,
123,4,29,4,0,
123,4,43,1,1,2
123,4,63,4,0,
123,4,68,2,0,
123,4,92,4,0,
123,4,97,1,24,
123,4,98,1,1,1
123,4,104,1,48,
123,4,104,4,0,
123,4,113,2,0,
123,4,116,1,6,
123,4,129,4,0,
123,4,156,4,0,
123,4,163,1,36,
123,4,168,4,0,
123,4,173,4,0,
123,4,174,4,0,
123,4,179,2,0,
123,4,182,4,0,
123,4,197,4,0,
123,4,203,4,0,
123,4,206,1,18,
123,4,207,4,0,
123,4,210,4,0,
123,4,211,4,0,
123,4,213,4,0,
123,4,214,4,0,
123,4,216,4,0,
123,4,218,4,0,
123,4,219,2,0,
123,4,226,2,0,
123,4,228,1,12,
123,4,237,4,0,
123,4,241,4,0,
123,4,249,4,0,
123,5,13,2,0,
123,5,14,1,36,
123,5,15,4,0,
123,5,17,1,26,
123,5,43,1,1,2
123,5,63,4,0,
123,5,68,2,0,
123,5,92,4,0,
123,5,97,1,21,
123,5,98,1,1,1
123,5,104,1,41,
123,5,104,4,0,
123,5,113,2,0,
123,5,116,1,6,
123,5,156,4,0,
123,5,163,1,31,
123,5,168,4,0,
123,5,179,2,0,
123,5,182,4,0,
123,5,203,2,0,
123,5,206,1,16,
123,5,210,1,46,
123,5,211,4,0,
123,5,213,4,0,
123,5,216,4,0,
123,5,218,4,0,
123,5,219,2,0,
123,5,226,2,0,
123,5,228,1,11,
123,5,237,4,0,
123,5,240,4,0,
123,5,241,4,0,
123,5,249,4,0,
123,5,263,4,0,
123,5,290,4,0,
123,5,318,2,0,
123,5,332,4,0,
123,6,13,2,0,
123,6,14,1,36,
123,6,14,3,0,
123,6,15,4,0,
123,6,17,1,26,
123,6,38,3,0,
123,6,43,1,1,2
123,6,63,4,0,
123,6,68,2,0,
123,6,68,3,0,
123,6,92,4,0,
123,6,97,1,21,
123,6,98,1,1,1
123,6,102,3,0,
123,6,104,1,41,
123,6,104,4,0,
123,6,113,2,0,
123,6,116,1,6,
123,6,129,3,0,
123,6,156,4,0,
123,6,163,1,31,
123,6,164,3,0,
123,6,168,4,0,
123,6,173,3,0,
123,6,179,2,0,
123,6,182,4,0,
123,6,203,2,0,
123,6,203,3,0,
123,6,206,1,16,
123,6,207,3,0,
123,6,210,1,46,
123,6,210,3,0,
123,6,211,4,0,
123,6,213,4,0,
123,6,214,3,0,
123,6,216,4,0,
123,6,218,4,0,
123,6,219,2,0,
123,6,226,2,0,
123,6,228,1,11,
123,6,237,4,0,
123,6,240,4,0,
123,6,241,4,0,
123,6,249,4,0,
123,6,263,4,0,
123,6,290,4,0,
123,6,318,2,0,
123,6,332,4,0,
123,7,13,2,0,
123,7,14,1,36,
123,7,14,3,0,
123,7,15,4,0,
123,7,17,1,26,
123,7,38,3,0,
123,7,43,1,1,2
123,7,63,4,0,
123,7,68,2,0,
123,7,68,3,0,
123,7,92,4,0,
123,7,97,1,21,
123,7,98,1,1,1
123,7,102,3,0,
123,7,104,1,41,
123,7,104,4,0,
123,7,113,2,0,
123,7,116,1,6,
123,7,156,4,0,
123,7,163,1,31,
123,7,164,3,0,
123,7,168,4,0,
123,7,179,2,0,
123,7,182,4,0,
123,7,203,2,0,
123,7,206,1,16,
123,7,210,1,46,
123,7,211,4,0,
123,7,213,4,0,
123,7,216,4,0,
123,7,218,4,0,
123,7,219,2,0,
123,7,226,2,0,
123,7,228,1,11,
123,7,237,4,0,
123,7,240,4,0,
123,7,241,4,0,
123,7,249,4,0,
123,7,263,4,0,
123,7,290,4,0,
123,7,318,2,0,
123,7,332,4,0,
123,8,13,1,33,
123,8,13,2,0,
123,8,14,1,57,
123,8,14,4,0,
123,8,15,4,0,
123,8,17,1,21,
123,8,43,1,1,2
123,8,63,4,0,
123,8,68,2,0,
123,8,92,4,0,
123,8,97,1,17,
123,8,98,1,1,1
123,8,104,1,37,
123,8,104,4,0,
123,8,113,2,0,
123,8,116,1,5,
123,8,156,4,0,
123,8,163,1,29,
123,8,164,4,0,
123,8,168,4,0,
123,8,179,2,0,
123,8,182,4,0,
123,8,203,2,0,
123,8,203,4,0,
123,8,206,1,13,
123,8,206,4,0,
123,8,207,4,0,
123,8,210,1,25,
123,8,211,4,0,
123,8,213,4,0,
123,8,214,4,0,
123,8,216,4,0,
123,8,218,4,0,
123,8,219,2,0,
123,8,226,2,0,
123,8,228,1,9,
123,8,237,4,0,
123,8,240,4,0,
123,8,241,4,0,
123,8,249,4,0,
123,8,263,4,0,
123,8,280,4,0,
123,8,290,4,0,
123,8,318,2,0,
123,8,318,4,0,
123,8,332,4,0,
123,8,355,4,0,
123,8,363,4,0,
123,8,369,4,0,
123,8,400,1,45,
123,8,400,2,0,
123,8,403,1,53,
123,8,404,1,41,
123,8,404,4,0,
123,8,405,2,0,
123,8,416,4,0,
123,8,432,4,0,
123,8,445,4,0,
123,8,458,1,49,
123,9,13,1,33,
123,9,13,2,0,
123,9,14,1,57,
123,9,14,4,0,
123,9,15,4,0,
123,9,17,1,21,
123,9,43,1,1,3
123,9,63,4,0,
123,9,68,2,0,
123,9,92,4,0,
123,9,97,1,17,
123,9,98,1,1,2
123,9,104,1,37,
123,9,104,4,0,
123,9,113,2,0,
123,9,116,1,5,
123,9,129,3,0,
123,9,156,4,0,
123,9,163,1,29,
123,9,164,4,0,
123,9,168,4,0,
123,9,173,3,0,
123,9,179,2,0,
123,9,182,4,0,
123,9,203,2,0,
123,9,203,4,0,
123,9,206,1,13,
123,9,206,4,0,
123,9,207,4,0,
123,9,210,1,25,
123,9,210,3,0,
123,9,211,4,0,
123,9,213,4,0,
123,9,214,4,0,
123,9,216,4,0,
123,9,218,4,0,
123,9,219,2,0,
123,9,226,2,0,
123,9,228,1,9,
123,9,237,4,0,
123,9,240,4,0,
123,9,241,4,0,
123,9,249,4,0,
123,9,263,4,0,
123,9,280,4,0,
123,9,282,3,0,
123,9,290,4,0,
123,9,318,2,0,
123,9,318,4,0,
123,9,332,4,0,
123,9,355,4,0,
123,9,363,4,0,
123,9,364,1,61,
123,9,369,4,0,
123,9,400,1,45,
123,9,400,2,0,
123,9,403,1,53,
123,9,404,1,41,
123,9,404,4,0,
123,9,405,2,0,
123,9,410,1,1,1
123,9,416,4,0,
123,9,432,4,0,
123,9,445,4,0,
123,9,458,1,49,
123,9,466,3,0,
123,10,13,1,33,
123,10,13,2,0,
123,10,14,1,57,
123,10,14,4,0,
123,10,15,4,0,
123,10,17,1,21,
123,10,29,3,0,
123,10,43,1,1,3
123,10,63,4,0,
123,10,68,2,0,
123,10,92,4,0,
123,10,97,1,17,
123,10,98,1,1,2
123,10,104,1,37,
123,10,104,4,0,
123,10,113,2,0,
123,10,116,1,5,
123,10,129,3,0,
123,10,156,4,0,
123,10,163,1,29,
123,10,164,4,0,
123,10,168,4,0,
123,10,173,3,0,
123,10,179,2,0,
123,10,182,4,0,
123,10,203,2,0,
123,10,203,4,0,
123,10,206,1,13,
123,10,206,4,0,
123,10,207,4,0,
123,10,210,1,25,
123,10,210,3,0,
123,10,211,4,0,
123,10,213,4,0,
123,10,214,4,0,
123,10,216,4,0,
123,10,218,4,0,
123,10,219,2,0,
123,10,226,2,0,
123,10,228,1,9,
123,10,237,4,0,
123,10,240,4,0,
123,10,241,4,0,
123,10,249,4,0,
123,10,263,4,0,
123,10,280,4,0,
123,10,282,3,0,
123,10,290,4,0,
123,10,318,2,0,
123,10,318,4,0,
123,10,332,4,0,
123,10,355,4,0,
123,10,363,4,0,
123,10,364,1,61,
123,10,366,3,0,
123,10,369,4,0,
123,10,400,1,45,
123,10,400,2,0,
123,10,403,1,53,
123,10,404,1,41,
123,10,404,4,0,
123,10,405,2,0,
123,10,410,1,1,1
123,10,416,4,0,
123,10,445,4,0,
123,10,450,3,0,
123,10,458,1,49,
123,10,466,3,0,
123,11,13,1,33,
123,11,13,2,0,
123,11,14,1,57,
123,11,14,4,0,
123,11,15,4,0,
123,11,17,1,21,
123,11,43,1,1,3
123,11,63,4,0,
123,11,68,2,0,
123,11,92,4,0,
123,11,97,1,17,
123,11,98,1,1,2
123,11,104,1,37,
123,11,104,4,0,
123,11,113,4,0,
123,11,116,1,5,
123,11,156,4,0,
123,11,163,1,29,
123,11,164,4,0,
123,11,168,4,0,
123,11,179,2,0,
123,11,182,4,0,
123,11,203,2,0,
123,11,206,1,13,
123,11,206,4,0,
123,11,207,4,0,
123,11,210,1,25,
123,11,211,2,0,
123,11,213,4,0,
123,11,216,4,0,
123,11,218,4,0,
123,11,219,4,0,
123,11,226,2,0,
123,11,228,1,9,
123,11,237,4,0,
123,11,240,4,0,
123,11,241,4,0,
123,11,249,4,0,
123,11,263,4,0,
123,11,280,4,0,
123,11,318,2,0,
123,11,332,4,0,
123,11,364,1,61,
123,11,369,4,0,
123,11,400,1,45,
123,11,400,2,0,
123,11,403,1,53,
123,11,404,1,41,
123,11,404,4,0,
123,11,405,2,0,
123,11,410,1,1,1
123,11,416,4,0,
123,11,432,2,0,
123,11,458,1,49,
123,11,496,4,0,
123,11,522,4,0,
123,12,14,1,36,
123,12,15,4,0,
123,12,17,1,26,
123,12,43,1,1,2
123,12,63,4,0,
123,12,92,4,0,
123,12,97,1,21,
123,12,98,1,1,1
123,12,104,1,41,
123,12,104,4,0,
123,12,116,1,6,
123,12,156,4,0,
123,12,163,1,31,
123,12,168,4,0,
123,12,182,4,0,
123,12,206,1,16,
123,12,210,1,46,
123,12,211,4,0,
123,12,213,4,0,
123,12,216,4,0,
123,12,218,4,0,
123,12,228,1,11,
123,12,237,4,0,
123,12,240,4,0,
123,12,241,4,0,
123,12,249,4,0,
123,12,263,4,0,
123,12,290,4,0,
123,12,332,4,0,
123,13,14,1,36,
123,13,15,4,0,
123,13,17,1,26,
123,13,38,3,0,
123,13,43,1,1,2
123,13,63,4,0,
123,13,92,4,0,
123,13,97,1,21,
123,13,98,1,1,1
123,13,102,3,0,
123,13,104,1,41,
123,13,104,4,0,
123,13,116,1,6,
123,13,156,4,0,
123,13,163,1,31,
123,13,164,3,0,
123,13,168,4,0,
123,13,182,4,0,
123,13,206,1,16,
123,13,207,3,0,
123,13,210,1,46,
123,13,211,4,0,
123,13,213,4,0,
123,13,216,4,0,
123,13,218,4,0,
123,13,228,1,11,
123,13,237,4,0,
123,13,240,4,0,
123,13,241,4,0,
123,13,249,4,0,
123,13,263,4,0,
123,13,290,4,0,
123,13,332,4,0,
123,14,13,1,33,
123,14,13,2,0,
123,14,14,1,57,
123,14,14,4,0,
123,14,15,4,0,
123,14,17,1,21,
123,14,43,1,1,3
123,14,63,4,0,
123,14,68,2,0,
123,14,92,4,0,
123,14,97,1,17,
123,14,98,1,1,2
123,14,104,1,37,
123,14,104,4,0,
123,14,113,4,0,
123,14,116,1,5,
123,14,156,4,0,
123,14,163,1,29,
123,14,164,4,0,
123,14,168,4,0,
123,14,173,3,0,
123,14,179,2,0,
123,14,182,4,0,
123,14,203,2,0,
123,14,206,1,13,
123,14,206,4,0,
123,14,207,4,0,
123,14,210,1,25,
123,14,211,2,0,
123,14,213,4,0,
123,14,214,3,0,
123,14,216,4,0,
123,14,218,4,0,
123,14,219,4,0,
123,14,226,2,0,
123,14,228,1,9,
123,14,237,4,0,
123,14,240,4,0,
123,14,241,4,0,
123,14,249,4,0,
123,14,263,4,0,
123,14,280,4,0,
123,14,282,3,0,
123,14,318,2,0,
123,14,332,4,0,
123,14,355,3,0,
123,14,364,1,61,
123,14,366,3,0,
123,14,369,4,0,
123,14,400,1,45,
123,14,400,2,0,
123,14,403,1,53,
123,14,404,1,41,
123,14,404,4,0,
123,14,405,2,0,
123,14,410,1,1,1
123,14,416,4,0,
123,14,432,2,0,
123,14,450,3,0,
123,14,458,1,49,
123,14,496,4,0,
123,14,522,4,0,
123,15,13,1,33,
123,15,13,2,0,
123,15,14,1,57,
123,15,14,4,0,
123,15,15,4,0,
123,15,17,1,21,
123,15,43,1,1,3
123,15,63,4,0,
123,15,68,2,0,
123,15,92,4,0,
123,15,97,1,17,
123,15,98,1,1,2
123,15,104,1,37,
123,15,104,4,0,
123,15,113,4,0,
123,15,116,1,5,
123,15,156,4,0,
123,15,163,1,29,
123,15,164,4,0,
123,15,168,4,0,
123,15,179,2,0,
123,15,182,4,0,
123,15,203,2,0,
123,15,206,1,13,
123,15,206,4,0,
123,15,207,4,0,
123,15,210,1,25,
123,15,211,2,0,
123,15,211,4,0,
123,15,213,4,0,
123,15,214,4,0,
123,15,216,4,0,
123,15,218,4,0,
123,15,219,4,0,
123,15,226,2,0,
123,15,228,1,9,
123,15,237,4,0,
123,15,240,4,0,
123,15,241,4,0,
123,15,249,4,0,
123,15,263,4,0,
123,15,280,4,0,
123,15,318,2,0,
123,15,332,4,0,
123,15,355,4,0,
123,15,364,1,61,
123,15,369,4,0,
123,15,400,1,45,
123,15,400,2,0,
123,15,403,1,50,
123,15,404,1,41,
123,15,404,4,0,
123,15,405,2,0,
123,15,410,1,1,1
123,15,416,4,0,
123,15,432,2,0,
123,15,458,1,49,
123,15,496,4,0,
123,15,501,2,0,
123,15,522,4,0,
123,15,590,4,0,
123,16,13,1,33,1
123,16,13,2,0,
123,16,14,1,57,1
123,16,14,4,0,
123,16,15,4,0,
123,16,17,1,21,1
123,16,43,1,1,3
123,16,63,4,0,
123,16,68,2,0,
123,16,92,4,0,
123,16,97,1,17,1
123,16,98,1,1,2
123,16,104,1,37,1
123,16,104,4,0,
123,16,113,4,0,
123,16,116,1,5,1
123,16,156,4,0,
123,16,163,1,29,1
123,16,164,4,0,
123,16,168,4,0,
123,16,173,3,0,
123,16,179,2,0,
123,16,182,4,0,
123,16,203,2,0,
123,16,206,1,13,1
123,16,206,4,0,
123,16,207,4,0,
123,16,210,1,25,1
123,16,211,2,0,
123,16,211,4,0,
123,16,213,4,0,
123,16,214,4,0,
123,16,216,4,0,
123,16,218,4,0,
123,16,219,4,0,
123,16,226,2,0,
123,16,228,1,9,1
123,16,237,4,0,
123,16,240,4,0,
123,16,241,4,0,
123,16,249,4,0,
123,16,263,4,0,
123,16,280,4,0,
123,16,282,3,0,
123,16,290,4,0,
123,16,318,2,0,
123,16,332,4,0,
123,16,355,4,0,
123,16,364,1,61,1
123,16,366,3,0,
123,16,369,4,0,
123,16,400,1,45,1
123,16,400,2,0,
123,16,403,1,50,1
123,16,404,1,41,1
123,16,404,4,0,
123,16,405,2,0,
123,16,410,1,1,1
123,16,416,4,0,
123,16,432,2,0,
123,16,450,3,0,
123,16,458,1,49,1
123,16,496,4,0,
123,16,501,2,0,
123,16,522,4,0,
123,16,590,4,0,
123,17,13,1,33,
123,17,13,2,0,
123,17,14,1,57,
123,17,14,4,0,
123,17,17,1,21,
123,17,43,1,1,3
123,17,63,4,0,
123,17,68,2,0,
123,17,92,4,0,
123,17,97,1,17,
123,17,98,1,1,2
123,17,104,1,37,
123,17,104,4,0,
123,17,113,4,0,
123,17,116,1,5,
123,17,156,4,0,
123,17,163,1,29,
123,17,164,4,0,
123,17,168,4,0,
123,17,179,2,0,
123,17,182,4,0,
123,17,203,2,0,
123,17,206,1,13,
123,17,206,4,0,
123,17,207,4,0,
123,17,210,1,25,
123,17,211,2,0,
123,17,211,4,0,
123,17,213,4,0,
123,17,214,4,0,
123,17,216,4,0,
123,17,218,4,0,
123,17,219,4,0,
123,17,226,2,0,
123,17,228,1,9,
123,17,237,4,0,
123,17,240,4,0,
123,17,241,4,0,
123,17,263,4,0,
123,17,280,4,0,
123,17,318,2,0,
123,17,332,4,0,
123,17,355,4,0,
123,17,364,1,61,
123,17,369,4,0,
123,17,400,1,45,
123,17,400,2,0,
123,17,403,1,50,
123,17,404,1,41,
123,17,404,4,0,
123,17,405,2,0,
123,17,410,1,1,1
123,17,416,4,0,
123,17,432,2,0,
123,17,458,1,49,
123,17,496,4,0,
123,17,501,2,0,
123,17,590,4,0,
123,17,693,4,0,
123,18,13,1,33,
123,18,13,2,0,
123,18,14,1,57,
123,18,14,4,0,
123,18,17,1,21,
123,18,43,1,1,3
123,18,63,4,0,
123,18,68,2,0,
123,18,92,4,0,
123,18,97,1,17,
123,18,98,1,1,2
123,18,104,1,37,
123,18,104,4,0,
123,18,113,4,0,
123,18,116,1,5,
123,18,156,4,0,
123,18,163,1,29,
123,18,164,4,0,
123,18,168,4,0,
123,18,179,2,0,
123,18,182,4,0,
123,18,203,2,0,
123,18,206,1,13,
123,18,206,4,0,
123,18,207,4,0,
123,18,210,1,25,
123,18,211,2,0,
123,18,211,4,0,
123,18,213,4,0,
123,18,214,4,0,
123,18,216,4,0,
123,18,218,4,0,
123,18,219,4,0,
123,18,226,2,0,
123,18,228,1,9,
123,18,237,4,0,
123,18,240,4,0,
123,18,241,4,0,
123,18,263,4,0,
123,18,280,4,0,
123,18,318,2,0,
123,18,332,4,0,
123,18,355,4,0,
123,18,364,1,61,
123,18,369,4,0,
123,18,400,1,45,
123,18,400,2,0,
123,18,403,1,50,
123,18,404,1,41,
123,18,404,4,0,
123,18,405,2,0,
123,18,410,1,1,1
123,18,416,4,0,
123,18,432,2,0,
123,18,458,1,49,
123,18,496,4,0,
123,18,501,2,0,
123,18,590,4,0,
123,18,693,4,0,
124,1,1,1,1,1
124,1,3,1,23,
124,1,5,4,0,
124,1,8,1,31,
124,1,25,4,0,
124,1,34,1,39,
124,1,34,4,0,
124,1,36,4,0,
124,1,37,1,47,
124,1,38,4,0,
124,1,55,4,0,
124,1,58,4,0,
124,1,59,1,58,
124,1,59,4,0,
124,1,61,4,0,
124,1,63,4,0,
124,1,66,4,0,
124,1,68,4,0,
124,1,69,4,0,
124,1,92,4,0,
124,1,94,4,0,
124,1,99,4,0,
124,1,100,4,0,
124,1,102,4,0,
124,1,104,4,0,
124,1,115,4,0,
124,1,117,4,0,
124,1,118,4,0,
124,1,122,1,18,
124,1,130,4,0,
124,1,142,1,1,2
124,1,149,4,0,
124,1,156,4,0,
124,1,164,4,0,
124,2,1,1,1,1
124,2,3,1,23,
124,2,5,4,0,
124,2,8,1,31,
124,2,25,4,0,
124,2,34,1,39,
124,2,34,4,0,
124,2,36,4,0,
124,2,37,1,47,
124,2,38,4,0,
124,2,55,4,0,
124,2,58,4,0,
124,2,59,1,58,
124,2,59,4,0,
124,2,61,4,0,
124,2,63,4,0,
124,2,66,4,0,
124,2,68,4,0,
124,2,69,4,0,
124,2,92,4,0,
124,2,94,4,0,
124,2,99,4,0,
124,2,100,4,0,
124,2,102,4,0,
124,2,104,4,0,
124,2,115,4,0,
124,2,117,4,0,
124,2,118,4,0,
124,2,122,1,18,
124,2,130,4,0,
124,2,142,1,1,2
124,2,149,4,0,
124,2,156,4,0,
124,2,164,4,0,
124,3,1,1,1,1
124,3,3,1,21,
124,3,8,1,25,
124,3,8,4,0,
124,3,29,4,0,
124,3,34,1,41,
124,3,59,1,57,
124,3,59,4,0,
124,3,63,4,0,
124,3,92,4,0,
124,3,94,4,0,
124,3,104,4,0,
124,3,122,1,1,2
124,3,138,4,0,
124,3,142,1,1,3
124,3,142,1,9,
124,3,156,4,0,
124,3,168,4,0,
124,3,171,4,0,
124,3,173,4,0,
124,3,174,4,0,
124,3,181,1,1,4
124,3,181,1,13,
124,3,182,4,0,
124,3,189,4,0,
124,3,195,1,51,
124,3,196,4,0,
124,3,203,4,0,
124,3,207,4,0,
124,3,212,1,35,
124,3,213,4,0,
124,3,214,4,0,
124,3,216,4,0,
124,3,218,4,0,
124,3,223,4,0,
124,3,230,4,0,
124,3,237,4,0,
124,3,240,4,0,
124,3,244,4,0,
124,3,247,4,0,
124,4,1,1,1,1
124,4,3,1,21,
124,4,8,1,25,
124,4,8,4,0,
124,4,29,4,0,
124,4,34,1,41,
124,4,58,3,0,
124,4,59,1,57,
124,4,59,4,0,
124,4,63,4,0,
124,4,92,4,0,
124,4,94,4,0,
124,4,104,4,0,
124,4,122,1,1,2
124,4,138,4,0,
124,4,142,1,1,3
124,4,142,1,9,
124,4,156,4,0,
124,4,168,4,0,
124,4,171,4,0,
124,4,173,4,0,
124,4,174,4,0,
124,4,181,1,1,4
124,4,181,1,13,
124,4,182,4,0,
124,4,189,4,0,
124,4,195,1,51,
124,4,196,4,0,
124,4,203,4,0,
124,4,207,4,0,
124,4,212,1,35,
124,4,213,4,0,
124,4,214,4,0,
124,4,216,4,0,
124,4,218,4,0,
124,4,223,4,0,
124,4,230,4,0,
124,4,237,4,0,
124,4,240,4,0,
124,4,244,4,0,
124,4,247,4,0,
124,5,1,1,1,1
124,5,3,1,21,
124,5,8,1,25,
124,5,34,1,51,
124,5,58,4,0,
124,5,59,1,67,
124,5,59,4,0,
124,5,63,4,0,
124,5,92,4,0,
124,5,94,4,0,
124,5,104,4,0,
124,5,113,4,0,
124,5,115,4,0,
124,5,122,1,1,2
124,5,142,1,1,3
124,5,142,1,9,
124,5,148,4,0,
124,5,156,4,0,
124,5,168,4,0,
124,5,181,1,1,4
124,5,181,1,13,
124,5,182,4,0,
124,5,195,1,57,
124,5,212,1,35,
124,5,213,4,0,
124,5,216,4,0,
124,5,218,4,0,
124,5,237,4,0,
124,5,240,4,0,
124,5,247,4,0,
124,5,258,4,0,
124,5,259,4,0,
124,5,263,4,0,
124,5,264,4,0,
124,5,269,4,0,
124,5,280,4,0,
124,5,285,4,0,
124,5,290,4,0,
124,5,313,1,41,
124,5,347,4,0,
124,5,352,4,0,
124,6,1,1,1,1
124,6,3,1,21,
124,6,5,3,0,
124,6,8,1,25,
124,6,8,3,0,
124,6,25,3,0,
124,6,34,1,51,
124,6,34,3,0,
124,6,38,3,0,
124,6,58,4,0,
124,6,59,1,67,
124,6,59,4,0,
124,6,63,4,0,
124,6,68,3,0,
124,6,69,3,0,
124,6,92,4,0,
124,6,94,4,0,
124,6,102,3,0,
124,6,104,4,0,
124,6,113,4,0,
124,6,115,4,0,
124,6,118,3,0,
124,6,122,1,1,2
124,6,138,3,0,
124,6,142,1,1,3
124,6,142,1,9,
124,6,148,4,0,
124,6,156,4,0,
124,6,164,3,0,
124,6,168,4,0,
124,6,173,3,0,
124,6,181,1,1,4
124,6,181,1,13,
124,6,182,4,0,
124,6,189,3,0,
124,6,195,1,57,
124,6,196,3,0,
124,6,203,3,0,
124,6,207,3,0,
124,6,212,1,35,
124,6,213,4,0,
124,6,214,3,0,
124,6,216,4,0,
124,6,218,4,0,
124,6,223,3,0,
124,6,237,4,0,
124,6,240,4,0,
124,6,244,3,0,
124,6,247,4,0,
124,6,258,4,0,
124,6,259,4,0,
124,6,263,4,0,
124,6,264,4,0,
124,6,269,4,0,
124,6,280,4,0,
124,6,285,4,0,
124,6,290,4,0,
124,6,313,1,41,
124,6,347,4,0,
124,6,352,4,0,
124,7,1,1,1,1
124,7,3,1,21,
124,7,5,3,0,
124,7,8,1,25,
124,7,25,3,0,
124,7,34,1,51,
124,7,34,3,0,
124,7,38,3,0,
124,7,58,4,0,
124,7,59,1,67,
124,7,59,4,0,
124,7,63,4,0,
124,7,68,3,0,
124,7,69,3,0,
124,7,92,4,0,
124,7,94,4,0,
124,7,102,3,0,
124,7,104,4,0,
124,7,113,4,0,
124,7,115,4,0,
124,7,118,3,0,
124,7,122,1,1,2
124,7,138,3,0,
124,7,142,1,1,3
124,7,142,1,9,
124,7,148,4,0,
124,7,156,4,0,
124,7,164,3,0,
124,7,168,4,0,
124,7,181,1,1,4
124,7,181,1,13,
124,7,182,4,0,
124,7,195,1,57,
124,7,212,1,35,
124,7,213,4,0,
124,7,216,4,0,
124,7,218,4,0,
124,7,237,4,0,
124,7,240,4,0,
124,7,247,4,0,
124,7,258,4,0,
124,7,259,4,0,
124,7,263,4,0,
124,7,264,4,0,
124,7,269,4,0,
124,7,280,4,0,
124,7,285,4,0,
124,7,290,4,0,
124,7,313,1,41,
124,7,347,4,0,
124,7,352,4,0,
124,8,1,1,1,1
124,8,3,1,15,
124,8,8,1,18,
124,8,34,1,39,
124,8,58,4,0,
124,8,59,1,55,
124,8,59,4,0,
124,8,63,4,0,
124,8,92,4,0,
124,8,94,4,0,
124,8,104,4,0,
124,8,113,4,0,
124,8,115,4,0,
124,8,122,1,1,2
124,8,122,1,5,
124,8,138,4,0,
124,8,142,1,1,3
124,8,142,1,8,
124,8,148,4,0,
124,8,156,4,0,
124,8,164,4,0,
124,8,168,4,0,
124,8,181,1,1,4
124,8,181,1,11,
124,8,182,4,0,
124,8,195,1,49,
124,8,203,4,0,
124,8,207,4,0,
124,8,212,1,21,
124,8,213,4,0,
124,8,214,4,0,
124,8,216,4,0,
124,8,218,4,0,
124,8,237,4,0,
124,8,240,4,0,
124,8,244,4,0,
124,8,247,4,0,
124,8,258,4,0,
124,8,259,4,0,
124,8,263,4,0,
124,8,264,4,0,
124,8,269,4,0,
124,8,278,4,0,
124,8,280,4,0,
124,8,285,4,0,
124,8,290,4,0,
124,8,313,1,25,
124,8,347,4,0,
124,8,352,4,0,
124,8,358,1,28,
124,8,363,4,0,
124,8,371,4,0,
124,8,374,4,0,
124,8,378,1,44,
124,8,409,4,0,
124,8,411,4,0,
124,8,412,4,0,
124,8,416,4,0,
124,8,419,1,33,
124,8,419,4,0,
124,8,433,4,0,
124,8,445,4,0,
124,8,447,4,0,
124,9,1,1,1,1
124,9,3,1,15,
124,9,8,1,18,
124,9,8,3,0,
124,9,34,1,39,
124,9,58,4,0,
124,9,59,1,55,
124,9,59,4,0,
124,9,63,4,0,
124,9,92,4,0,
124,9,94,4,0,
124,9,104,4,0,
124,9,113,4,0,
124,9,115,4,0,
124,9,122,1,1,2
124,9,122,1,5,
124,9,138,4,0,
124,9,142,1,1,3
124,9,142,1,8,
124,9,148,4,0,
124,9,156,4,0,
124,9,164,4,0,
124,9,168,4,0,
124,9,173,3,0,
124,9,181,1,1,4
124,9,181,1,11,
124,9,182,4,0,
124,9,189,3,0,
124,9,195,1,49,
124,9,196,3,0,
124,9,203,4,0,
124,9,207,4,0,
124,9,212,1,21,
124,9,213,4,0,
124,9,214,4,0,
124,9,216,4,0,
124,9,218,4,0,
124,9,237,4,0,
124,9,240,4,0,
124,9,244,4,0,
124,9,247,4,0,
124,9,258,4,0,
124,9,259,4,0,
124,9,263,4,0,
124,9,264,4,0,
124,9,269,4,0,
124,9,270,3,0,
124,9,271,3,0,
124,9,278,4,0,
124,9,280,4,0,
124,9,285,4,0,
124,9,290,4,0,
124,9,313,1,25,
124,9,324,3,0,
124,9,347,4,0,
124,9,352,4,0,
124,9,358,1,28,
124,9,363,4,0,
124,9,371,4,0,
124,9,374,4,0,
124,9,378,1,44,
124,9,409,4,0,
124,9,411,4,0,
124,9,412,4,0,
124,9,416,4,0,
124,9,419,1,33,
124,9,419,4,0,
124,9,428,3,0,
124,9,433,4,0,
124,9,445,4,0,
124,9,447,4,0,
124,10,1,1,1,1
124,10,3,1,15,
124,10,8,1,18,
124,10,8,3,0,
124,10,29,3,0,
124,10,34,1,39,
124,10,58,4,0,
124,10,59,1,55,
124,10,59,4,0,
124,10,63,4,0,
124,10,92,4,0,
124,10,94,4,0,
124,10,104,4,0,
124,10,113,4,0,
124,10,115,4,0,
124,10,122,1,1,2
124,10,122,1,5,
124,10,138,4,0,
124,10,142,1,1,3
124,10,142,1,8,
124,10,148,4,0,
124,10,156,4,0,
124,10,164,4,0,
124,10,168,4,0,
124,10,173,3,0,
124,10,181,1,1,4
124,10,181,1,11,
124,10,182,4,0,
124,10,189,3,0,
124,10,195,1,49,
124,10,196,3,0,
124,10,203,4,0,
124,10,207,4,0,
124,10,212,1,21,
124,10,213,4,0,
124,10,214,4,0,
124,10,215,3,0,
124,10,216,4,0,
124,10,218,4,0,
124,10,237,4,0,
124,10,240,4,0,
124,10,244,4,0,
124,10,247,4,0,
124,10,258,4,0,
124,10,259,4,0,
124,10,263,4,0,
124,10,264,4,0,
124,10,269,4,0,
124,10,270,3,0,
124,10,271,3,0,
124,10,272,3,0,
124,10,277,3,0,
124,10,278,4,0,
124,10,280,4,0,
124,10,285,4,0,
124,10,290,4,0,
124,10,313,1,25,
124,10,324,3,0,
124,10,347,4,0,
124,10,352,4,0,
124,10,358,1,28,
124,10,363,4,0,
124,10,371,4,0,
124,10,374,4,0,
124,10,378,1,44,
124,10,409,4,0,
124,10,411,4,0,
124,10,412,4,0,
124,10,416,4,0,
124,10,419,1,33,
124,10,419,4,0,
124,10,428,3,0,
124,10,433,4,0,
124,10,445,4,0,
124,10,447,4,0,
124,11,1,1,1,1
124,11,3,1,15,
124,11,8,1,18,
124,11,34,1,44,
124,11,58,4,0,
124,11,59,1,60,
124,11,59,4,0,
124,11,63,4,0,
124,11,92,4,0,
124,11,94,4,0,
124,11,104,4,0,
124,11,113,4,0,
124,11,115,4,0,
124,11,122,1,1,2
124,11,122,1,5,
124,11,138,4,0,
124,11,142,1,1,3
124,11,142,1,8,
124,11,148,4,0,
124,11,156,4,0,
124,11,164,4,0,
124,11,168,4,0,
124,11,181,1,1,4
124,11,181,1,11,
124,11,182,4,0,
124,11,195,1,55,
124,11,207,4,0,
124,11,212,1,25,
124,11,213,4,0,
124,11,216,4,0,
124,11,218,4,0,
124,11,237,4,0,
124,11,240,4,0,
124,11,244,4,0,
124,11,247,4,0,
124,11,258,4,0,
124,11,259,4,0,
124,11,263,4,0,
124,11,269,4,0,
124,11,280,4,0,
124,11,313,1,28,
124,11,347,4,0,
124,11,358,1,33,
124,11,371,4,0,
124,11,374,4,0,
124,11,378,1,49,
124,11,411,4,0,
124,11,412,4,0,
124,11,416,4,0,
124,11,419,1,39,
124,11,433,4,0,
124,11,447,4,0,
124,11,473,4,0,
124,11,477,4,0,
124,11,496,4,0,
124,11,497,4,0,
124,11,524,4,0,
124,11,531,1,21,
124,12,1,1,1,1
124,12,3,1,21,
124,12,8,1,25,
124,12,34,1,51,
124,12,58,4,0,
124,12,59,1,67,
124,12,59,4,0,
124,12,63,4,0,
124,12,92,4,0,
124,12,94,4,0,
124,12,104,4,0,
124,12,113,4,0,
124,12,115,4,0,
124,12,122,1,1,2
124,12,142,1,1,3
124,12,142,1,9,
124,12,148,4,0,
124,12,156,4,0,
124,12,168,4,0,
124,12,181,1,1,4
124,12,181,1,13,
124,12,182,4,0,
124,12,195,1,57,
124,12,212,1,35,
124,12,213,4,0,
124,12,216,4,0,
124,12,218,4,0,
124,12,237,4,0,
124,12,240,4,0,
124,12,247,4,0,
124,12,258,4,0,
124,12,259,4,0,
124,12,263,4,0,
124,12,264,4,0,
124,12,269,4,0,
124,12,280,4,0,
124,12,285,4,0,
124,12,290,4,0,
124,12,313,1,41,
124,12,347,4,0,
124,12,352,4,0,
124,13,1,1,1,1
124,13,3,1,21,
124,13,8,1,25,
124,13,34,1,51,
124,13,34,3,0,
124,13,38,3,0,
124,13,58,4,0,
124,13,59,1,67,
124,13,59,4,0,
124,13,63,4,0,
124,13,69,3,0,
124,13,92,4,0,
124,13,94,4,0,
124,13,102,3,0,
124,13,104,4,0,
124,13,113,4,0,
124,13,115,4,0,
124,13,122,1,1,2
124,13,138,3,0,
124,13,142,1,1,3
124,13,142,1,9,
124,13,148,4,0,
124,13,156,4,0,
124,13,164,3,0,
124,13,168,4,0,
124,13,171,3,0,
124,13,181,1,1,4
124,13,181,1,13,
124,13,182,4,0,
124,13,195,1,57,
124,13,196,3,0,
124,13,207,3,0,
124,13,212,1,35,
124,13,213,4,0,
124,13,216,4,0,
124,13,218,4,0,
124,13,237,4,0,
124,13,240,4,0,
124,13,247,4,0,
124,13,258,4,0,
124,13,259,4,0,
124,13,263,4,0,
124,13,264,4,0,
124,13,269,4,0,
124,13,280,4,0,
124,13,285,4,0,
124,13,290,4,0,
124,13,313,1,41,
124,13,347,4,0,
124,13,352,4,0,
124,14,1,1,1,1
124,14,3,1,15,
124,14,8,1,18,
124,14,8,3,0,
124,14,34,1,44,
124,14,58,4,0,
124,14,59,1,60,
124,14,59,4,0,
124,14,63,4,0,
124,14,92,4,0,
124,14,94,4,0,
124,14,104,4,0,
124,14,113,4,0,
124,14,115,4,0,
124,14,122,1,1,2
124,14,122,1,5,
124,14,138,4,0,
124,14,142,1,1,3
124,14,142,1,8,
124,14,148,4,0,
124,14,156,4,0,
124,14,164,4,0,
124,14,168,4,0,
124,14,173,3,0,
124,14,181,1,1,4
124,14,181,1,11,
124,14,182,4,0,
124,14,195,1,55,
124,14,196,3,0,
124,14,207,4,0,
124,14,212,1,25,
124,14,213,4,0,
124,14,214,3,0,
124,14,215,3,0,
124,14,216,4,0,
124,14,218,4,0,
124,14,237,4,0,
124,14,240,4,0,
124,14,244,4,0,
124,14,247,4,0,
124,14,258,4,0,
124,14,259,4,0,
124,14,263,4,0,
124,14,269,4,0,
124,14,270,3,0,
124,14,271,3,0,
124,14,272,3,0,
124,14,277,3,0,
124,14,278,3,0,
124,14,280,4,0,
124,14,285,3,0,
124,14,304,3,0,
124,14,313,1,28,
124,14,324,3,0,
124,14,343,3,0,
124,14,347,4,0,
124,14,358,1,33,
124,14,371,4,0,
124,14,374,4,0,
124,14,378,1,49,
124,14,409,3,0,
124,14,411,4,0,
124,14,412,4,0,
124,14,416,4,0,
124,14,419,1,39,
124,14,428,3,0,
124,14,433,4,0,
124,14,447,4,0,
124,14,473,4,0,
124,14,477,4,0,
124,14,478,3,0,
124,14,496,4,0,
124,14,497,4,0,
124,14,524,4,0,
124,14,531,1,21,
124,15,1,1,1,3
124,15,3,1,15,
124,15,8,1,18,
124,15,34,1,44,
124,15,58,4,0,
124,15,59,1,60,
124,15,59,4,0,
124,15,63,4,0,
124,15,92,4,0,
124,15,94,4,0,
124,15,104,4,0,
124,15,113,4,0,
124,15,115,4,0,
124,15,122,1,1,4
124,15,122,1,5,
124,15,138,4,0,
124,15,142,1,1,5
124,15,142,1,8,
124,15,148,4,0,
124,15,156,4,0,
124,15,164,4,0,
124,15,168,4,0,
124,15,181,1,1,6
124,15,181,1,11,
124,15,182,4,0,
124,15,195,1,1,2
124,15,195,1,55,
124,15,207,4,0,
124,15,212,1,25,
124,15,213,4,0,
124,15,214,4,0,
124,15,216,4,0,
124,15,218,4,0,
124,15,237,4,0,
124,15,240,4,0,
124,15,244,4,0,
124,15,247,4,0,
124,15,258,4,0,
124,15,259,4,0,
124,15,263,4,0,
124,15,269,4,0,
124,15,280,4,0,
124,15,313,1,28,
124,15,347,4,0,
124,15,358,1,33,
124,15,371,4,0,
124,15,374,4,0,
124,15,378,1,49,
124,15,411,4,0,
124,15,412,4,0,
124,15,416,4,0,
124,15,419,1,39,
124,15,433,4,0,
124,15,447,4,0,
124,15,473,4,0,
124,15,496,4,0,
124,15,497,4,0,
124,15,524,4,0,
124,15,531,1,21,
124,15,577,1,1,1
124,15,590,4,0,
124,15,612,4,0,
124,16,1,1,1,3
124,16,3,1,15,1
124,16,8,1,18,1
124,16,8,3,0,
124,16,34,1,44,1
124,16,58,4,0,
124,16,59,1,60,1
124,16,59,4,0,
124,16,63,4,0,
124,16,92,4,0,
124,16,94,4,0,
124,16,104,4,0,
124,16,113,4,0,
124,16,115,4,0,
124,16,122,1,1,4
124,16,122,1,5,1
124,16,138,4,0,
124,16,142,1,1,5
124,16,142,1,8,1
124,16,148,4,0,
124,16,156,4,0,
124,16,164,4,0,
124,16,168,4,0,
124,16,173,3,0,
124,16,181,1,1,6
124,16,181,1,11,1
124,16,182,4,0,
124,16,195,1,1,2
124,16,195,1,55,1
124,16,196,3,0,
124,16,207,4,0,
124,16,212,1,25,1
124,16,213,4,0,
124,16,214,4,0,
124,16,215,3,0,
124,16,216,4,0,
124,16,218,4,0,
124,16,237,4,0,
124,16,240,4,0,
124,16,244,4,0,
124,16,247,4,0,
124,16,258,4,0,
124,16,259,4,0,
124,16,263,4,0,
124,16,264,3,0,
124,16,269,4,0,
124,16,270,3,0,
124,16,271,3,0,
124,16,272,3,0,
124,16,277,3,0,
124,16,278,3,0,
124,16,280,4,0,
124,16,285,3,0,
124,16,290,4,0,
124,16,304,3,0,
124,16,313,1,28,1
124,16,324,3,0,
124,16,343,3,0,
124,16,347,4,0,
124,16,352,3,0,
124,16,358,1,33,1
124,16,371,4,0,
124,16,374,4,0,
124,16,378,1,49,1
124,16,409,3,0,
124,16,411,4,0,
124,16,412,4,0,
124,16,416,4,0,
124,16,419,1,39,1
124,16,428,3,0,
124,16,433,4,0,
124,16,447,4,0,
124,16,473,4,0,
124,16,478,3,0,
124,16,496,4,0,
124,16,497,4,0,
124,16,524,4,0,
124,16,531,1,21,1
124,16,577,1,1,1
124,16,590,4,0,
124,16,612,4,0,
124,17,1,1,1,3
124,17,3,1,15,
124,17,8,1,18,
124,17,34,1,44,
124,17,58,4,0,
124,17,59,1,60,
124,17,59,4,0,
124,17,63,4,0,
124,17,92,4,0,
124,17,94,4,0,
124,17,104,4,0,
124,17,113,4,0,
124,17,115,4,0,
124,17,122,1,1,4
124,17,122,1,5,
124,17,138,4,0,
124,17,142,1,1,5
124,17,142,1,8,
124,17,156,4,0,
124,17,164,4,0,
124,17,168,4,0,
124,17,181,1,1,6
124,17,181,1,11,
124,17,182,4,0,
124,17,195,1,1,2
124,17,195,1,55,
124,17,207,4,0,
124,17,212,1,25,
124,17,213,4,0,
124,17,214,4,0,
124,17,216,4,0,
124,17,218,4,0,
124,17,237,4,0,
124,17,240,4,0,
124,17,244,4,0,
124,17,247,4,0,
124,17,258,4,0,
124,17,259,4,0,
124,17,263,4,0,
124,17,269,4,0,
124,17,280,4,0,
124,17,313,1,28,
124,17,347,4,0,
124,17,358,1,33,
124,17,371,4,0,
124,17,374,4,0,
124,17,378,1,49,
124,17,411,4,0,
124,17,412,4,0,
124,17,416,4,0,
124,17,419,1,39,
124,17,433,4,0,
124,17,447,4,0,
124,17,473,4,0,
124,17,496,4,0,
124,17,497,4,0,
124,17,524,4,0,
124,17,531,1,21,
124,17,577,1,1,1
124,17,590,4,0,
124,17,694,4,0,
124,18,1,1,1,3
124,18,3,1,15,
124,18,8,1,18,
124,18,34,1,44,
124,18,58,4,0,
124,18,59,1,60,
124,18,59,4,0,
124,18,63,4,0,
124,18,92,4,0,
124,18,94,4,0,
124,18,104,4,0,
124,18,113,4,0,
124,18,115,4,0,
124,18,122,1,1,4
124,18,122,1,5,
124,18,138,4,0,
124,18,142,1,1,5
124,18,142,1,8,
124,18,156,4,0,
124,18,164,4,0,
124,18,168,4,0,
124,18,181,1,1,6
124,18,181,1,11,
124,18,182,4,0,
124,18,195,1,1,2
124,18,195,1,55,
124,18,207,4,0,
124,18,212,1,25,
124,18,213,4,0,
124,18,214,4,0,
124,18,216,4,0,
124,18,218,4,0,
124,18,237,4,0,
124,18,240,4,0,
124,18,244,4,0,
124,18,247,4,0,
124,18,258,4,0,
124,18,259,4,0,
124,18,263,4,0,
124,18,269,4,0,
124,18,280,4,0,
124,18,313,1,28,
124,18,347,4,0,
124,18,358,1,33,
124,18,371,4,0,
124,18,374,4,0,
124,18,378,1,49,
124,18,411,4,0,
124,18,412,4,0,
124,18,416,4,0,
124,18,419,1,39,
124,18,433,4,0,
124,18,447,4,0,
124,18,473,4,0,
124,18,496,4,0,
124,18,497,4,0,
124,18,524,4,0,
124,18,531,1,21,
124,18,577,1,1,1
124,18,590,4,0,
124,18,694,4,0,
125,1,5,4,0,
125,1,9,1,42,
125,1,25,4,0,
125,1,34,4,0,
125,1,36,4,0,
125,1,38,4,0,
125,1,43,1,1,2
125,1,63,4,0,
125,1,66,4,0,
125,1,68,4,0,
125,1,69,4,0,
125,1,70,4,0,
125,1,84,1,34,
125,1,85,4,0,
125,1,86,4,0,
125,1,87,1,54,
125,1,87,4,0,
125,1,92,4,0,
125,1,94,4,0,
125,1,98,1,1,1
125,1,99,4,0,
125,1,100,4,0,
125,1,102,4,0,
125,1,103,1,37,
125,1,104,4,0,
125,1,113,1,49,
125,1,115,4,0,
125,1,117,4,0,
125,1,118,4,0,
125,1,129,4,0,
125,1,130,4,0,
125,1,148,4,0,
125,1,149,4,0,
125,1,156,4,0,
125,1,164,4,0,
125,2,5,4,0,
125,2,9,1,42,
125,2,25,4,0,
125,2,34,4,0,
125,2,36,4,0,
125,2,38,4,0,
125,2,43,1,1,2
125,2,63,4,0,
125,2,66,4,0,
125,2,68,4,0,
125,2,69,4,0,
125,2,70,4,0,
125,2,84,1,34,
125,2,85,4,0,
125,2,86,4,0,
125,2,87,1,54,
125,2,87,4,0,
125,2,92,4,0,
125,2,94,4,0,
125,2,98,1,1,1
125,2,99,4,0,
125,2,100,4,0,
125,2,102,4,0,
125,2,103,1,37,
125,2,104,4,0,
125,2,113,1,49,
125,2,115,4,0,
125,2,117,4,0,
125,2,118,4,0,
125,2,129,4,0,
125,2,130,4,0,
125,2,148,4,0,
125,2,149,4,0,
125,2,156,4,0,
125,2,164,4,0,
125,3,7,4,0,
125,3,8,4,0,
125,3,9,1,1,3
125,3,9,1,9,
125,3,9,4,0,
125,3,29,4,0,
125,3,43,1,1,2
125,3,63,4,0,
125,3,70,4,0,
125,3,85,1,47,
125,3,87,1,58,
125,3,87,4,0,
125,3,92,4,0,
125,3,94,4,0,
125,3,98,1,1,1
125,3,103,1,36,
125,3,104,4,0,
125,3,113,1,17,
125,3,129,1,25,
125,3,129,4,0,
125,3,148,4,0,
125,3,156,4,0,
125,3,168,4,0,
125,3,173,4,0,
125,3,174,4,0,
125,3,182,4,0,
125,3,189,4,0,
125,3,192,4,0,
125,3,197,4,0,
125,3,203,4,0,
125,3,207,4,0,
125,3,213,4,0,
125,3,214,4,0,
125,3,216,4,0,
125,3,218,4,0,
125,3,223,4,0,
125,3,231,4,0,
125,3,237,4,0,
125,3,240,4,0,
125,3,249,4,0,
125,4,7,4,0,
125,4,8,4,0,
125,4,9,1,1,3
125,4,9,1,9,
125,4,9,4,0,
125,4,29,4,0,
125,4,43,1,1,2
125,4,63,4,0,
125,4,70,4,0,
125,4,85,1,47,
125,4,85,3,0,
125,4,87,1,58,
125,4,87,4,0,
125,4,92,4,0,
125,4,94,4,0,
125,4,98,1,1,1
125,4,103,1,36,
125,4,104,4,0,
125,4,113,1,17,
125,4,129,1,25,
125,4,129,4,0,
125,4,148,4,0,
125,4,156,4,0,
125,4,168,4,0,
125,4,173,4,0,
125,4,174,4,0,
125,4,182,4,0,
125,4,189,4,0,
125,4,192,4,0,
125,4,197,4,0,
125,4,203,4,0,
125,4,207,4,0,
125,4,213,4,0,
125,4,214,4,0,
125,4,216,4,0,
125,4,218,4,0,
125,4,223,4,0,
125,4,231,4,0,
125,4,237,4,0,
125,4,240,4,0,
125,4,249,4,0,
125,5,9,1,1,3
125,5,9,1,9,
125,5,43,1,1,2
125,5,63,4,0,
125,5,70,4,0,
125,5,85,1,47,
125,5,85,4,0,
125,5,87,1,58,
125,5,87,4,0,
125,5,92,4,0,
125,5,94,4,0,
125,5,98,1,1,1
125,5,103,1,36,
125,5,104,4,0,
125,5,113,1,17,
125,5,113,4,0,
125,5,129,1,25,
125,5,148,4,0,
125,5,156,4,0,
125,5,168,4,0,
125,5,182,4,0,
125,5,213,4,0,
125,5,216,4,0,
125,5,218,4,0,
125,5,231,4,0,
125,5,237,4,0,
125,5,240,4,0,
125,5,249,4,0,
125,5,263,4,0,
125,5,264,4,0,
125,5,280,4,0,
125,5,290,4,0,
125,5,351,4,0,
125,6,5,3,0,
125,6,7,3,0,
125,6,8,3,0,
125,6,9,1,1,3
125,6,9,1,9,
125,6,9,3,0,
125,6,25,3,0,
125,6,34,3,0,
125,6,38,3,0,
125,6,43,1,1,2
125,6,63,4,0,
125,6,68,3,0,
125,6,69,3,0,
125,6,70,4,0,
125,6,85,1,47,
125,6,85,4,0,
125,6,86,3,0,
125,6,87,1,58,
125,6,87,4,0,
125,6,92,4,0,
125,6,94,4,0,
125,6,98,1,1,1
125,6,102,3,0,
125,6,103,1,36,
125,6,104,4,0,
125,6,113,1,17,
125,6,113,4,0,
125,6,129,1,25,
125,6,129,3,0,
125,6,148,4,0,
125,6,156,4,0,
125,6,164,3,0,
125,6,168,4,0,
125,6,173,3,0,
125,6,182,4,0,
125,6,189,3,0,
125,6,203,3,0,
125,6,207,3,0,
125,6,213,4,0,
125,6,214,3,0,
125,6,216,4,0,
125,6,218,4,0,
125,6,223,3,0,
125,6,231,4,0,
125,6,237,4,0,
125,6,240,4,0,
125,6,249,4,0,
125,6,263,4,0,
125,6,264,4,0,
125,6,280,4,0,
125,6,290,4,0,
125,6,351,4,0,
125,7,5,3,0,
125,7,9,1,1,3
125,7,9,1,9,
125,7,25,3,0,
125,7,34,3,0,
125,7,38,3,0,
125,7,43,1,1,2
125,7,63,4,0,
125,7,68,3,0,
125,7,69,3,0,
125,7,70,4,0,
125,7,85,1,47,
125,7,85,4,0,
125,7,86,3,0,
125,7,87,1,58,
125,7,87,4,0,
125,7,92,4,0,
125,7,94,4,0,
125,7,98,1,1,1
125,7,102,3,0,
125,7,103,1,36,
125,7,104,4,0,
125,7,113,1,17,
125,7,113,4,0,
125,7,129,1,25,
125,7,148,4,0,
125,7,156,4,0,
125,7,164,3,0,
125,7,168,4,0,
125,7,182,4,0,
125,7,213,4,0,
125,7,216,4,0,
125,7,218,4,0,
125,7,231,4,0,
125,7,237,4,0,
125,7,240,4,0,
125,7,249,4,0,
125,7,263,4,0,
125,7,264,4,0,
125,7,280,4,0,
125,7,290,4,0,
125,7,351,4,0,
125,8,9,1,28,
125,8,43,1,1,2
125,8,63,4,0,
125,8,67,1,10,
125,8,70,4,0,
125,8,84,1,1,3
125,8,84,1,7,
125,8,85,1,43,
125,8,85,4,0,
125,8,86,4,0,
125,8,87,1,58,
125,8,87,4,0,
125,8,92,4,0,
125,8,94,4,0,
125,8,98,1,1,1
125,8,103,1,52,
125,8,104,4,0,
125,8,113,1,25,
125,8,113,4,0,
125,8,129,1,16,
125,8,148,4,0,
125,8,156,4,0,
125,8,164,4,0,
125,8,168,4,0,
125,8,182,4,0,
125,8,203,4,0,
125,8,207,4,0,
125,8,213,4,0,
125,8,214,4,0,
125,8,216,4,0,
125,8,218,4,0,
125,8,231,4,0,
125,8,237,4,0,
125,8,240,4,0,
125,8,249,4,0,
125,8,263,4,0,
125,8,264,4,0,
125,8,280,4,0,
125,8,290,4,0,
125,8,351,1,19,
125,8,351,4,0,
125,8,363,4,0,
125,8,374,4,0,
125,8,411,4,0,
125,8,416,4,0,
125,8,431,4,0,
125,8,435,1,37,
125,8,445,4,0,
125,8,451,4,0,
125,9,7,3,0,
125,9,8,3,0,
125,9,9,1,28,
125,9,9,3,0,
125,9,43,1,1,2
125,9,63,4,0,
125,9,67,1,10,
125,9,70,4,0,
125,9,84,1,1,3
125,9,84,1,7,
125,9,85,1,43,
125,9,85,4,0,
125,9,86,4,0,
125,9,87,1,58,
125,9,87,4,0,
125,9,92,4,0,
125,9,94,4,0,
125,9,98,1,1,1
125,9,103,1,52,
125,9,104,4,0,
125,9,113,1,25,
125,9,113,4,0,
125,9,129,1,16,
125,9,129,3,0,
125,9,148,4,0,
125,9,156,4,0,
125,9,164,4,0,
125,9,168,4,0,
125,9,173,3,0,
125,9,182,4,0,
125,9,189,3,0,
125,9,203,4,0,
125,9,207,4,0,
125,9,213,4,0,
125,9,214,4,0,
125,9,216,4,0,
125,9,218,4,0,
125,9,231,4,0,
125,9,237,4,0,
125,9,240,4,0,
125,9,249,4,0,
125,9,263,4,0,
125,9,264,4,0,
125,9,270,3,0,
125,9,280,4,0,
125,9,290,4,0,
125,9,324,3,0,
125,9,351,1,19,
125,9,351,4,0,
125,9,363,4,0,
125,9,374,4,0,
125,9,393,3,0,
125,9,411,4,0,
125,9,416,4,0,
125,9,431,4,0,
125,9,435,1,37,
125,9,445,4,0,
125,9,451,4,0,
125,10,7,3,0,
125,10,8,3,0,
125,10,9,1,28,
125,10,9,3,0,
125,10,29,3,0,
125,10,43,1,1,2
125,10,63,4,0,
125,10,67,1,10,
125,10,67,3,0,
125,10,70,4,0,
125,10,84,1,1,3
125,10,84,1,7,
125,10,85,1,43,
125,10,85,4,0,
125,10,86,4,0,
125,10,87,1,58,
125,10,87,4,0,
125,10,92,4,0,
125,10,94,4,0,
125,10,98,1,1,1
125,10,103,1,52,
125,10,104,4,0,
125,10,113,1,25,
125,10,113,4,0,
125,10,129,1,16,
125,10,129,3,0,
125,10,148,4,0,
125,10,156,4,0,
125,10,164,4,0,
125,10,168,4,0,
125,10,173,3,0,
125,10,182,4,0,
125,10,189,3,0,
125,10,203,4,0,
125,10,207,4,0,
125,10,213,4,0,
125,10,214,4,0,
125,10,216,4,0,
125,10,218,4,0,
125,10,231,4,0,
125,10,237,4,0,
125,10,240,4,0,
125,10,249,4,0,
125,10,263,4,0,
125,10,264,4,0,
125,10,270,3,0,
125,10,280,4,0,
125,10,290,4,0,
125,10,324,3,0,
125,10,351,1,19,
125,10,351,4,0,
125,10,363,4,0,
125,10,374,4,0,
125,10,393,3,0,
125,10,411,4,0,
125,10,416,4,0,
125,10,431,4,0,
125,10,435,1,37,
125,10,445,4,0,
125,10,451,4,0,
125,11,9,1,38,
125,11,43,1,1,2
125,11,63,4,0,
125,11,67,1,11,
125,11,70,4,0,
125,11,84,1,1,3
125,11,84,1,6,
125,11,85,1,50,
125,11,85,4,0,
125,11,86,4,0,
125,11,87,1,62,
125,11,87,4,0,
125,11,92,4,0,
125,11,94,4,0,
125,11,98,1,1,1
125,11,103,1,56,
125,11,104,4,0,
125,11,113,1,26,
125,11,113,4,0,
125,11,129,1,16,
125,11,148,4,0,
125,11,156,4,0,
125,11,164,4,0,
125,11,168,4,0,
125,11,182,4,0,
125,11,207,4,0,
125,11,213,4,0,
125,11,216,4,0,
125,11,218,4,0,
125,11,237,4,0,
125,11,240,4,0,
125,11,249,4,0,
125,11,263,4,0,
125,11,280,4,0,
125,11,351,1,21,
125,11,374,4,0,
125,11,411,4,0,
125,11,416,4,0,
125,11,435,1,44,
125,11,451,4,0,
125,11,486,1,32,
125,11,490,4,0,
125,11,496,4,0,
125,11,521,4,0,
125,11,528,4,0,
125,12,9,1,1,3
125,12,9,1,9,
125,12,43,1,1,2
125,12,63,4,0,
125,12,70,4,0,
125,12,85,1,47,
125,12,85,4,0,
125,12,87,1,58,
125,12,87,4,0,
125,12,92,4,0,
125,12,94,4,0,
125,12,98,1,1,1
125,12,103,1,36,
125,12,104,4,0,
125,12,113,1,17,
125,12,113,4,0,
125,12,129,1,25,
125,12,148,4,0,
125,12,156,4,0,
125,12,168,4,0,
125,12,182,4,0,
125,12,213,4,0,
125,12,216,4,0,
125,12,218,4,0,
125,12,231,4,0,
125,12,237,4,0,
125,12,240,4,0,
125,12,249,4,0,
125,12,263,4,0,
125,12,264,4,0,
125,12,280,4,0,
125,12,290,4,0,
125,12,351,4,0,
125,13,9,1,1,3
125,13,9,1,9,
125,13,34,3,0,
125,13,38,3,0,
125,13,43,1,1,2
125,13,63,4,0,
125,13,69,3,0,
125,13,70,4,0,
125,13,85,1,47,
125,13,85,4,0,
125,13,86,3,0,
125,13,87,1,58,
125,13,87,4,0,
125,13,92,4,0,
125,13,94,4,0,
125,13,98,1,1,1
125,13,102,3,0,
125,13,103,1,36,
125,13,104,4,0,
125,13,113,1,17,
125,13,113,4,0,
125,13,129,1,25,
125,13,148,4,0,
125,13,156,4,0,
125,13,164,3,0,
125,13,168,4,0,
125,13,182,4,0,
125,13,207,3,0,
125,13,213,4,0,
125,13,216,4,0,
125,13,218,4,0,
125,13,231,4,0,
125,13,237,4,0,
125,13,240,4,0,
125,13,249,4,0,
125,13,263,4,0,
125,13,264,4,0,
125,13,280,4,0,
125,13,290,4,0,
125,13,351,4,0,
125,14,7,3,0,
125,14,8,3,0,
125,14,9,1,29,
125,14,9,3,0,
125,14,43,1,1,2
125,14,63,4,0,
125,14,67,1,8,
125,14,67,3,0,
125,14,70,4,0,
125,14,84,1,1,3
125,14,84,1,5,
125,14,85,1,49,
125,14,85,4,0,
125,14,86,1,19,
125,14,86,4,0,
125,14,87,1,55,
125,14,87,4,0,
125,14,92,4,0,
125,14,94,4,0,
125,14,98,1,1,1
125,14,103,1,42,
125,14,104,4,0,
125,14,113,1,26,
125,14,113,4,0,
125,14,129,1,12,
125,14,148,4,0,
125,14,156,4,0,
125,14,164,4,0,
125,14,168,4,0,
125,14,173,3,0,
125,14,182,4,0,
125,14,207,4,0,
125,14,213,4,0,
125,14,214,3,0,
125,14,216,4,0,
125,14,218,4,0,
125,14,231,3,0,
125,14,237,4,0,
125,14,240,4,0,
125,14,249,4,0,
125,14,263,4,0,
125,14,270,3,0,
125,14,280,4,0,
125,14,324,3,0,
125,14,343,3,0,
125,14,351,1,15,
125,14,374,4,0,
125,14,393,3,0,
125,14,411,4,0,
125,14,416,4,0,
125,14,435,1,36,
125,14,451,4,0,
125,14,486,1,22,
125,14,490,4,0,
125,14,496,4,0,
125,14,521,4,0,
125,14,527,3,0,
125,14,528,4,0,
125,14,530,3,0,
125,15,9,1,29,
125,15,43,1,1,2
125,15,63,4,0,
125,15,67,1,8,
125,15,70,4,0,
125,15,84,1,1,3
125,15,84,1,5,
125,15,85,1,49,
125,15,85,4,0,
125,15,86,1,19,
125,15,86,4,0,
125,15,87,1,55,
125,15,87,4,0,
125,15,92,4,0,
125,15,94,4,0,
125,15,98,1,1,1
125,15,103,1,42,
125,15,104,4,0,
125,15,113,1,26,
125,15,113,4,0,
125,15,129,1,12,
125,15,148,4,0,
125,15,156,4,0,
125,15,164,4,0,
125,15,168,4,0,
125,15,182,4,0,
125,15,207,4,0,
125,15,213,4,0,
125,15,214,4,0,
125,15,216,4,0,
125,15,218,4,0,
125,15,237,4,0,
125,15,240,4,0,
125,15,249,4,0,
125,15,263,4,0,
125,15,280,4,0,
125,15,351,1,15,
125,15,374,4,0,
125,15,411,4,0,
125,15,416,4,0,
125,15,435,1,36,
125,15,451,4,0,
125,15,486,1,22,
125,15,490,4,0,
125,15,496,4,0,
125,15,521,4,0,
125,15,528,4,0,
125,15,590,4,0,
125,15,612,4,0,
125,16,7,3,0,
125,16,8,3,0,
125,16,9,1,29,1
125,16,9,3,0,
125,16,43,1,1,2
125,16,63,4,0,
125,16,67,1,8,1
125,16,67,3,0,
125,16,70,4,0,
125,16,84,1,1,3
125,16,84,1,5,1
125,16,85,1,49,1
125,16,85,4,0,
125,16,86,1,19,1
125,16,86,4,0,
125,16,87,1,55,1
125,16,87,4,0,
125,16,92,4,0,
125,16,94,4,0,
125,16,98,1,1,1
125,16,103,1,42,1
125,16,104,4,0,
125,16,113,1,26,1
125,16,113,4,0,
125,16,129,1,12,1
125,16,148,4,0,
125,16,156,4,0,
125,16,164,4,0,
125,16,168,4,0,
125,16,173,3,0,
125,16,182,4,0,
125,16,207,4,0,
125,16,213,4,0,
125,16,214,4,0,
125,16,216,4,0,
125,16,218,4,0,
125,16,231,3,0,
125,16,237,4,0,
125,16,240,4,0,
125,16,249,4,0,
125,16,263,4,0,
125,16,264,3,0,
125,16,270,3,0,
125,16,280,4,0,
125,16,290,4,0,
125,16,324,3,0,
125,16,343,3,0,
125,16,351,1,15,1
125,16,351,3,0,
125,16,374,4,0,
125,16,393,3,0,
125,16,411,4,0,
125,16,416,4,0,
125,16,435,1,36,1
125,16,451,4,0,
125,16,486,1,22,1
125,16,490,4,0,
125,16,496,4,0,
125,16,521,4,0,
125,16,527,3,0,
125,16,528,4,0,
125,16,530,3,0,
125,16,590,4,0,
125,16,612,4,0,
125,17,9,1,29,
125,17,43,1,1,2
125,17,63,4,0,
125,17,67,1,8,
125,17,84,1,1,3
125,17,84,1,5,
125,17,85,1,49,
125,17,85,4,0,
125,17,86,1,19,
125,17,86,4,0,
125,17,87,1,55,
125,17,87,4,0,
125,17,92,4,0,
125,17,94,4,0,
125,17,98,1,1,1
125,17,103,1,42,
125,17,104,4,0,
125,17,113,1,26,
125,17,113,4,0,
125,17,129,1,12,
125,17,156,4,0,
125,17,164,4,0,
125,17,168,4,0,
125,17,182,4,0,
125,17,207,4,0,
125,17,213,4,0,
125,17,214,4,0,
125,17,216,4,0,
125,17,218,4,0,
125,17,237,4,0,
125,17,240,4,0,
125,17,263,4,0,
125,17,280,4,0,
125,17,351,1,15,
125,17,374,4,0,
125,17,411,4,0,
125,17,416,4,0,
125,17,435,1,36,
125,17,451,4,0,
125,17,486,1,22,
125,17,490,4,0,
125,17,496,4,0,
125,17,521,4,0,
125,17,528,4,0,
125,17,590,4,0,
125,18,9,1,29,
125,18,43,1,1,2
125,18,63,4,0,
125,18,67,1,8,
125,18,84,1,1,3
125,18,84,1,5,
125,18,85,1,49,
125,18,85,4,0,
125,18,86,1,19,
125,18,86,4,0,
125,18,87,1,55,
125,18,87,4,0,
125,18,92,4,0,
125,18,94,4,0,
125,18,98,1,1,1
125,18,103,1,42,
125,18,104,4,0,
125,18,113,1,26,
125,18,113,4,0,
125,18,129,1,12,
125,18,156,4,0,
125,18,164,4,0,
125,18,168,4,0,
125,18,182,4,0,
125,18,207,4,0,
125,18,213,4,0,
125,18,214,4,0,
125,18,216,4,0,
125,18,218,4,0,
125,18,237,4,0,
125,18,240,4,0,
125,18,263,4,0,
125,18,280,4,0,
125,18,351,1,15,
125,18,374,4,0,
125,18,411,4,0,
125,18,416,4,0,
125,18,435,1,36,
125,18,451,4,0,
125,18,486,1,22,
125,18,490,4,0,
125,18,496,4,0,
125,18,521,4,0,
125,18,528,4,0,
125,18,590,4,0,
126,1,5,4,0,
126,1,7,1,43,
126,1,25,4,0,
126,1,34,4,0,
126,1,36,4,0,
126,1,38,4,0,
126,1,43,1,36,
126,1,52,1,1,
126,1,53,1,55,
126,1,63,4,0,
126,1,66,4,0,
126,1,68,4,0,
126,1,69,4,0,
126,1,70,4,0,
126,1,92,4,0,
126,1,94,4,0,
126,1,99,4,0,
126,1,100,4,0,
126,1,102,4,0,
126,1,104,4,0,
126,1,108,1,48,
126,1,109,1,39,
126,1,117,4,0,
126,1,118,4,0,
126,1,123,1,52,
126,1,126,4,0,
126,1,130,4,0,
126,1,149,4,0,
126,1,156,4,0,
126,1,164,4,0,
126,2,5,4,0,
126,2,7,1,43,
126,2,25,4,0,
126,2,34,4,0,
126,2,36,4,0,
126,2,38,4,0,
126,2,43,1,36,
126,2,52,1,1,
126,2,53,1,55,
126,2,63,4,0,
126,2,66,4,0,
126,2,68,4,0,
126,2,69,4,0,
126,2,70,4,0,
126,2,92,4,0,
126,2,94,4,0,
126,2,99,4,0,
126,2,100,4,0,
126,2,102,4,0,
126,2,104,4,0,
126,2,108,1,48,
126,2,109,1,39,
126,2,117,4,0,
126,2,118,4,0,
126,2,123,1,52,
126,2,126,4,0,
126,2,130,4,0,
126,2,149,4,0,
126,2,156,4,0,
126,2,164,4,0,
126,3,7,1,1,4
126,3,7,1,19,
126,3,7,4,0,
126,3,9,4,0,
126,3,29,4,0,
126,3,43,1,1,2
126,3,43,1,7,
126,3,52,1,1,1
126,3,53,1,41,
126,3,63,4,0,
126,3,70,4,0,
126,3,92,4,0,
126,3,94,4,0,
126,3,104,4,0,
126,3,108,1,25,
126,3,109,1,49,
126,3,123,1,1,3
126,3,123,1,13,
126,3,126,1,57,
126,3,126,4,0,
126,3,156,4,0,
126,3,168,4,0,
126,3,173,4,0,
126,3,174,4,0,
126,3,182,4,0,
126,3,189,4,0,
126,3,197,4,0,
126,3,203,4,0,
126,3,207,4,0,
126,3,213,4,0,
126,3,214,4,0,
126,3,216,4,0,
126,3,218,4,0,
126,3,223,4,0,
126,3,231,4,0,
126,3,237,4,0,
126,3,241,1,33,
126,3,241,4,0,
126,3,249,4,0,
126,4,7,1,1,4
126,4,7,1,19,
126,4,7,4,0,
126,4,9,4,0,
126,4,29,4,0,
126,4,43,1,1,2
126,4,43,1,7,
126,4,52,1,1,1
126,4,53,1,41,
126,4,53,3,0,
126,4,63,4,0,
126,4,70,4,0,
126,4,92,4,0,
126,4,94,4,0,
126,4,104,4,0,
126,4,108,1,25,
126,4,109,1,49,
126,4,123,1,1,3
126,4,123,1,13,
126,4,126,1,57,
126,4,126,4,0,
126,4,156,4,0,
126,4,168,4,0,
126,4,173,4,0,
126,4,174,4,0,
126,4,182,4,0,
126,4,189,4,0,
126,4,197,4,0,
126,4,203,4,0,
126,4,207,4,0,
126,4,213,4,0,
126,4,214,4,0,
126,4,216,4,0,
126,4,218,4,0,
126,4,223,4,0,
126,4,231,4,0,
126,4,237,4,0,
126,4,241,1,33,
126,4,241,4,0,
126,4,249,4,0,
126,5,7,1,1,4
126,5,7,1,19,
126,5,43,1,1,2
126,5,43,1,7,
126,5,52,1,1,1
126,5,53,1,41,
126,5,53,4,0,
126,5,63,4,0,
126,5,70,4,0,
126,5,92,4,0,
126,5,94,4,0,
126,5,104,4,0,
126,5,108,1,25,
126,5,109,1,49,
126,5,123,1,1,3
126,5,123,1,13,
126,5,126,1,57,
126,5,126,4,0,
126,5,156,4,0,
126,5,168,4,0,
126,5,182,4,0,
126,5,213,4,0,
126,5,216,4,0,
126,5,218,4,0,
126,5,231,4,0,
126,5,237,4,0,
126,5,241,1,33,
126,5,241,4,0,
126,5,249,4,0,
126,5,263,4,0,
126,5,264,4,0,
126,5,280,4,0,
126,5,290,4,0,
126,6,5,3,0,
126,6,7,1,1,4
126,6,7,1,19,
126,6,7,3,0,
126,6,9,3,0,
126,6,25,3,0,
126,6,34,3,0,
126,6,38,3,0,
126,6,43,1,1,2
126,6,43,1,7,
126,6,52,1,1,1
126,6,53,1,41,
126,6,53,4,0,
126,6,63,4,0,
126,6,68,3,0,
126,6,69,3,0,
126,6,70,4,0,
126,6,92,4,0,
126,6,94,4,0,
126,6,102,3,0,
126,6,104,4,0,
126,6,108,1,25,
126,6,109,1,49,
126,6,123,1,1,3
126,6,123,1,13,
126,6,126,1,57,
126,6,126,4,0,
126,6,156,4,0,
126,6,164,3,0,
126,6,168,4,0,
126,6,173,3,0,
126,6,182,4,0,
126,6,189,3,0,
126,6,203,3,0,
126,6,207,3,0,
126,6,213,4,0,
126,6,214,3,0,
126,6,216,4,0,
126,6,218,4,0,
126,6,223,3,0,
126,6,231,4,0,
126,6,237,4,0,
126,6,241,1,33,
126,6,241,4,0,
126,6,249,4,0,
126,6,263,4,0,
126,6,264,4,0,
126,6,280,4,0,
126,6,290,4,0,
126,7,5,3,0,
126,7,7,1,1,4
126,7,7,1,19,
126,7,25,3,0,
126,7,34,3,0,
126,7,38,3,0,
126,7,43,1,1,2
126,7,43,1,7,
126,7,52,1,1,1
126,7,53,1,41,
126,7,53,4,0,
126,7,63,4,0,
126,7,68,3,0,
126,7,69,3,0,
126,7,70,4,0,
126,7,92,4,0,
126,7,94,4,0,
126,7,102,3,0,
126,7,104,4,0,
126,7,108,1,25,
126,7,109,1,49,
126,7,123,1,1,3
126,7,123,1,13,
126,7,126,1,57,
126,7,126,4,0,
126,7,156,4,0,
126,7,164,3,0,
126,7,168,4,0,
126,7,182,4,0,
126,7,213,4,0,
126,7,216,4,0,
126,7,218,4,0,
126,7,231,4,0,
126,7,237,4,0,
126,7,241,1,33,
126,7,241,4,0,
126,7,249,4,0,
126,7,263,4,0,
126,7,264,4,0,
126,7,280,4,0,
126,7,290,4,0,
126,8,7,1,28,
126,8,43,1,1,2
126,8,52,1,1,3
126,8,52,1,7,
126,8,53,1,41,
126,8,53,4,0,
126,8,63,4,0,
126,8,70,4,0,
126,8,83,1,19,
126,8,92,4,0,
126,8,94,4,0,
126,8,104,4,0,
126,8,108,1,10,
126,8,109,1,25,
126,8,123,1,1,1
126,8,126,1,54,
126,8,126,4,0,
126,8,156,4,0,
126,8,164,4,0,
126,8,168,4,0,
126,8,182,4,0,
126,8,185,1,16,
126,8,203,4,0,
126,8,207,4,0,
126,8,213,4,0,
126,8,214,4,0,
126,8,216,4,0,
126,8,218,4,0,
126,8,231,4,0,
126,8,237,4,0,
126,8,241,1,49,
126,8,241,4,0,
126,8,249,4,0,
126,8,261,4,0,
126,8,263,4,0,
126,8,264,4,0,
126,8,280,4,0,
126,8,290,4,0,
126,8,315,4,0,
126,8,363,4,0,
126,8,374,4,0,
126,8,411,4,0,
126,8,416,4,0,
126,8,431,4,0,
126,8,436,1,36,
126,8,445,4,0,
126,9,7,1,28,
126,9,7,3,0,
126,9,9,3,0,
126,9,43,1,1,2
126,9,52,1,1,3
126,9,52,1,7,
126,9,53,1,41,
126,9,53,4,0,
126,9,63,4,0,
126,9,70,4,0,
126,9,83,1,19,
126,9,92,4,0,
126,9,94,4,0,
126,9,104,4,0,
126,9,108,1,10,
126,9,109,1,25,
126,9,123,1,1,1
126,9,126,1,54,
126,9,126,4,0,
126,9,156,4,0,
126,9,164,4,0,
126,9,168,4,0,
126,9,173,3,0,
126,9,182,4,0,
126,9,185,1,16,
126,9,189,3,0,
126,9,203,4,0,
126,9,207,4,0,
126,9,213,4,0,
126,9,214,4,0,
126,9,216,4,0,
126,9,218,4,0,
126,9,231,4,0,
126,9,237,4,0,
126,9,241,1,49,
126,9,241,4,0,
126,9,249,4,0,
126,9,257,3,0,
126,9,261,4,0,
126,9,263,4,0,
126,9,264,4,0,
126,9,270,3,0,
126,9,280,4,0,
126,9,290,4,0,
126,9,315,4,0,
126,9,363,4,0,
126,9,374,4,0,
126,9,411,4,0,
126,9,416,4,0,
126,9,431,4,0,
126,9,436,1,36,
126,9,445,4,0,
126,10,7,1,28,
126,10,7,3,0,
126,10,9,3,0,
126,10,29,3,0,
126,10,43,1,1,2
126,10,52,1,1,3
126,10,52,1,7,
126,10,53,1,41,
126,10,53,4,0,
126,10,63,4,0,
126,10,67,3,0,
126,10,70,4,0,
126,10,83,1,19,
126,10,92,4,0,
126,10,94,4,0,
126,10,104,4,0,
126,10,108,1,10,
126,10,109,1,25,
126,10,123,1,1,1
126,10,126,1,54,
126,10,126,4,0,
126,10,156,4,0,
126,10,164,4,0,
126,10,168,4,0,
126,10,173,3,0,
126,10,182,4,0,
126,10,185,1,16,
126,10,189,3,0,
126,10,203,4,0,
126,10,207,4,0,
126,10,213,4,0,
126,10,214,4,0,
126,10,216,4,0,
126,10,218,4,0,
126,10,231,4,0,
126,10,237,4,0,
126,10,241,1,49,
126,10,241,4,0,
126,10,249,4,0,
126,10,257,3,0,
126,10,261,4,0,
126,10,263,4,0,
126,10,264,4,0,
126,10,270,3,0,
126,10,280,4,0,
126,10,290,4,0,
126,10,315,4,0,
126,10,363,4,0,
126,10,374,4,0,
126,10,411,4,0,
126,10,416,4,0,
126,10,431,4,0,
126,10,436,1,36,
126,10,445,4,0,
126,11,7,1,38,
126,11,43,1,1,2
126,11,52,1,1,3
126,11,52,1,6,
126,11,53,1,50,
126,11,53,4,0,
126,11,63,4,0,
126,11,70,4,0,
126,11,83,1,21,
126,11,92,4,0,
126,11,94,4,0,
126,11,104,4,0,
126,11,108,1,11,
126,11,109,1,26,
126,11,123,1,1,1
126,11,126,1,62,
126,11,126,4,0,
126,11,156,4,0,
126,11,164,4,0,
126,11,168,4,0,
126,11,182,4,0,
126,11,185,1,16,
126,11,207,4,0,
126,11,213,4,0,
126,11,216,4,0,
126,11,218,4,0,
126,11,237,4,0,
126,11,241,1,56,
126,11,241,4,0,
126,11,249,4,0,
126,11,261,4,0,
126,11,263,4,0,
126,11,280,4,0,
126,11,315,4,0,
126,11,374,4,0,
126,11,411,4,0,
126,11,416,4,0,
126,11,436,1,44,
126,11,481,1,32,
126,11,488,4,0,
126,11,490,4,0,
126,11,496,4,0,
126,11,510,4,0,
126,12,7,1,1,4
126,12,7,1,19,
126,12,43,1,1,2
126,12,43,1,7,
126,12,52,1,1,1
126,12,53,1,41,
126,12,53,4,0,
126,12,63,4,0,
126,12,70,4,0,
126,12,92,4,0,
126,12,94,4,0,
126,12,104,4,0,
126,12,108,1,25,
126,12,109,1,49,
126,12,123,1,1,3
126,12,123,1,13,
126,12,126,1,57,
126,12,126,4,0,
126,12,156,4,0,
126,12,168,4,0,
126,12,182,4,0,
126,12,213,4,0,
126,12,216,4,0,
126,12,218,4,0,
126,12,231,4,0,
126,12,237,4,0,
126,12,241,1,33,
126,12,241,4,0,
126,12,249,4,0,
126,12,263,4,0,
126,12,264,4,0,
126,12,280,4,0,
126,12,290,4,0,
126,13,7,1,1,4
126,13,7,1,19,
126,13,34,3,0,
126,13,38,3,0,
126,13,43,1,1,2
126,13,43,1,7,
126,13,52,1,1,1
126,13,53,1,41,
126,13,53,4,0,
126,13,63,4,0,
126,13,69,3,0,
126,13,70,4,0,
126,13,92,4,0,
126,13,94,4,0,
126,13,102,3,0,
126,13,104,4,0,
126,13,108,1,25,
126,13,109,1,49,
126,13,123,1,1,3
126,13,123,1,13,
126,13,126,1,57,
126,13,126,4,0,
126,13,156,4,0,
126,13,164,3,0,
126,13,168,4,0,
126,13,182,4,0,
126,13,207,3,0,
126,13,213,4,0,
126,13,216,4,0,
126,13,218,4,0,
126,13,231,4,0,
126,13,237,4,0,
126,13,241,1,33,
126,13,241,4,0,
126,13,249,4,0,
126,13,263,4,0,
126,13,264,4,0,
126,13,280,4,0,
126,13,290,4,0,
126,14,7,1,29,
126,14,7,3,0,
126,14,9,3,0,
126,14,43,1,1,2
126,14,52,1,1,3
126,14,52,1,5,
126,14,53,1,49,
126,14,53,4,0,
126,14,63,4,0,
126,14,67,3,0,
126,14,70,4,0,
126,14,83,1,15,
126,14,92,4,0,
126,14,94,4,0,
126,14,104,4,0,
126,14,108,1,8,
126,14,109,1,26,
126,14,123,1,1,1
126,14,126,1,55,
126,14,126,4,0,
126,14,156,4,0,
126,14,164,4,0,
126,14,168,4,0,
126,14,173,3,0,
126,14,182,4,0,
126,14,185,1,12,
126,14,207,4,0,
126,14,213,4,0,
126,14,214,3,0,
126,14,216,4,0,
126,14,218,4,0,
126,14,231,3,0,
126,14,237,4,0,
126,14,241,1,42,
126,14,241,4,0,
126,14,249,4,0,
126,14,257,3,0,
126,14,261,4,0,
126,14,263,4,0,
126,14,270,3,0,
126,14,280,4,0,
126,14,315,4,0,
126,14,343,3,0,
126,14,374,4,0,
126,14,411,4,0,
126,14,416,4,0,
126,14,436,1,36,
126,14,481,1,22,
126,14,488,4,0,
126,14,490,4,0,
126,14,496,4,0,
126,14,499,1,19,
126,14,510,4,0,
126,14,530,3,0,
126,15,7,1,29,
126,15,43,1,1,2
126,15,52,1,1,3
126,15,52,1,5,
126,15,53,1,49,
126,15,53,4,0,
126,15,63,4,0,
126,15,70,4,0,
126,15,83,1,15,
126,15,92,4,0,
126,15,94,4,0,
126,15,104,4,0,
126,15,108,1,8,
126,15,109,1,26,
126,15,123,1,1,1
126,15,126,1,55,
126,15,126,4,0,
126,15,156,4,0,
126,15,164,4,0,
126,15,168,4,0,
126,15,182,4,0,
126,15,185,1,12,
126,15,207,4,0,
126,15,213,4,0,
126,15,214,4,0,
126,15,216,4,0,
126,15,218,4,0,
126,15,237,4,0,
126,15,241,1,42,
126,15,241,4,0,
126,15,249,4,0,
126,15,261,4,0,
126,15,263,4,0,
126,15,280,4,0,
126,15,315,4,0,
126,15,374,4,0,
126,15,411,4,0,
126,15,416,4,0,
126,15,436,1,36,
126,15,481,1,22,
126,15,488,4,0,
126,15,490,4,0,
126,15,496,4,0,
126,15,499,1,19,
126,15,510,4,0,
126,15,590,4,0,
126,15,612,4,0,
126,16,7,1,29,1
126,16,7,3,0,
126,16,9,3,0,
126,16,43,1,1,2
126,16,52,1,1,3
126,16,52,1,5,1
126,16,53,1,49,1
126,16,53,4,0,
126,16,63,4,0,
126,16,67,3,0,
126,16,70,4,0,
126,16,83,1,15,1
126,16,92,4,0,
126,16,94,4,0,
126,16,104,4,0,
126,16,108,1,8,1
126,16,109,1,26,1
126,16,123,1,1,1
126,16,126,1,55,1
126,16,126,4,0,
126,16,156,4,0,
126,16,164,4,0,
126,16,168,4,0,
126,16,173,3,0,
126,16,182,4,0,
126,16,185,1,12,1
126,16,207,4,0,
126,16,213,4,0,
126,16,214,4,0,
126,16,216,4,0,
126,16,218,4,0,
126,16,231,3,0,
126,16,237,4,0,
126,16,241,1,42,1
126,16,241,4,0,
126,16,249,4,0,
126,16,257,3,0,
126,16,261,4,0,
126,16,263,4,0,
126,16,264,3,0,
126,16,270,3,0,
126,16,280,4,0,
126,16,290,4,0,
126,16,315,4,0,
126,16,343,3,0,
126,16,374,4,0,
126,16,411,4,0,
126,16,416,4,0,
126,16,436,1,36,1
126,16,481,1,22,1
126,16,488,4,0,
126,16,490,4,0,
126,16,496,4,0,
126,16,499,1,19,1
126,16,510,4,0,
126,16,530,3,0,
126,16,590,4,0,
126,16,612,4,0,
126,17,7,1,29,
126,17,43,1,1,2
126,17,52,1,1,3
126,17,52,1,5,
126,17,53,1,49,
126,17,53,4,0,
126,17,63,4,0,
126,17,83,1,15,
126,17,92,4,0,
126,17,94,4,0,
126,17,104,4,0,
126,17,108,1,8,
126,17,109,1,26,
126,17,123,1,1,1
126,17,126,1,55,
126,17,126,4,0,
126,17,156,4,0,
126,17,164,4,0,
126,17,168,4,0,
126,17,182,4,0,
126,17,185,1,12,
126,17,207,4,0,
126,17,213,4,0,
126,17,214,4,0,
126,17,216,4,0,
126,17,218,4,0,
126,17,237,4,0,
126,17,241,1,42,
126,17,241,4,0,
126,17,261,4,0,
126,17,263,4,0,
126,17,280,4,0,
126,17,315,4,0,
126,17,374,4,0,
126,17,411,4,0,
126,17,416,4,0,
126,17,436,1,36,
126,17,481,1,22,
126,17,488,4,0,
126,17,490,4,0,
126,17,496,4,0,
126,17,499,1,19,
126,17,590,4,0,
126,18,7,1,29,
126,18,43,1,1,2
126,18,52,1,1,3
126,18,52,1,5,
126,18,53,1,49,
126,18,53,4,0,
126,18,63,4,0,
126,18,83,1,15,
126,18,92,4,0,
126,18,94,4,0,
126,18,104,4,0,
126,18,108,1,8,
126,18,109,1,26,
126,18,123,1,1,1
126,18,126,1,55,
126,18,126,4,0,
126,18,156,4,0,
126,18,164,4,0,
126,18,168,4,0,
126,18,182,4,0,
126,18,185,1,12,
126,18,207,4,0,
126,18,213,4,0,
126,18,214,4,0,
126,18,216,4,0,
126,18,218,4,0,
126,18,237,4,0,
126,18,241,1,42,
126,18,241,4,0,
126,18,261,4,0,
126,18,263,4,0,
126,18,280,4,0,
126,18,315,4,0,
126,18,374,4,0,
126,18,411,4,0,
126,18,416,4,0,
126,18,436,1,36,
126,18,481,1,22,
126,18,488,4,0,
126,18,490,4,0,
126,18,496,4,0,
126,18,499,1,19,
126,18,590,4,0,
127,1,11,1,1,
127,1,12,1,30,
127,1,14,1,54,
127,1,14,4,0,
127,1,15,4,0,
127,1,34,4,0,
127,1,36,4,0,
127,1,38,4,0,
127,1,63,4,0,
127,1,66,4,0,
127,1,69,1,25,
127,1,69,4,0,
127,1,70,4,0,
127,1,92,4,0,
127,1,99,4,0,
127,1,102,4,0,
127,1,104,4,0,
127,1,106,1,43,
127,1,116,1,36,
127,1,117,4,0,
127,1,156,4,0,
127,1,163,1,49,
127,1,164,4,0,
127,2,11,1,1,
127,2,12,1,30,
127,2,14,1,54,
127,2,14,4,0,
127,2,15,4,0,
127,2,20,1,21,
127,2,34,4,0,
127,2,36,4,0,
127,2,38,4,0,
127,2,63,4,0,
127,2,66,4,0,
127,2,69,1,25,
127,2,69,4,0,
127,2,70,4,0,
127,2,92,4,0,
127,2,99,4,0,
127,2,102,4,0,
127,2,104,4,0,
127,2,106,1,43,
127,2,116,1,36,
127,2,117,4,0,
127,2,156,4,0,
127,2,163,1,49,
127,2,164,4,0,
127,3,11,1,1,
127,3,12,1,31,
127,3,14,1,43,
127,3,15,4,0,
127,3,20,1,13,
127,3,29,4,0,
127,3,31,2,0,
127,3,63,4,0,
127,3,66,1,37,
127,3,69,1,19,
127,3,70,4,0,
127,3,92,4,0,
127,3,104,4,0,
127,3,106,1,25,
127,3,116,1,7,
127,3,156,4,0,
127,3,168,4,0,
127,3,173,4,0,
127,3,174,4,0,
127,3,175,2,0,
127,3,182,4,0,
127,3,203,4,0,
127,3,207,4,0,
127,3,210,4,0,
127,3,213,4,0,
127,3,214,4,0,
127,3,216,4,0,
127,3,218,4,0,
127,3,237,4,0,
127,3,241,4,0,
127,3,249,4,0,
127,4,11,1,1,
127,4,12,1,31,
127,4,14,1,43,
127,4,15,4,0,
127,4,20,1,13,
127,4,29,4,0,
127,4,31,2,0,
127,4,63,4,0,
127,4,66,1,37,
127,4,69,1,19,
127,4,70,4,0,
127,4,92,4,0,
127,4,104,4,0,
127,4,106,1,25,
127,4,116,1,7,
127,4,156,4,0,
127,4,168,4,0,
127,4,173,4,0,
127,4,174,4,0,
127,4,175,2,0,
127,4,182,4,0,
127,4,203,4,0,
127,4,207,4,0,
127,4,210,4,0,
127,4,213,4,0,
127,4,214,4,0,
127,4,216,4,0,
127,4,218,4,0,
127,4,237,4,0,
127,4,241,4,0,
127,4,249,4,0,
127,5,11,1,1,1
127,5,12,1,37,
127,5,14,1,49,
127,5,15,4,0,
127,5,20,1,7,
127,5,31,2,0,
127,5,63,4,0,
127,5,66,1,43,
127,5,69,1,13,
127,5,70,4,0,
127,5,89,4,0,
127,5,91,4,0,
127,5,92,4,0,
127,5,104,4,0,
127,5,106,1,19,
127,5,116,1,1,2
127,5,156,4,0,
127,5,168,4,0,
127,5,175,2,0,
127,5,182,4,0,
127,5,185,2,0,
127,5,206,2,0,
127,5,213,4,0,
127,5,216,4,0,
127,5,218,4,0,
127,5,237,4,0,
127,5,240,4,0,
127,5,241,4,0,
127,5,249,4,0,
127,5,263,4,0,
127,5,264,4,0,
127,5,279,1,25,
127,5,280,1,31,
127,5,280,4,0,
127,5,290,4,0,
127,5,317,4,0,
127,5,339,4,0,
127,6,11,1,1,1
127,6,12,1,37,
127,6,14,1,49,
127,6,14,3,0,
127,6,15,4,0,
127,6,20,1,7,
127,6,31,2,0,
127,6,34,3,0,
127,6,38,3,0,
127,6,63,4,0,
127,6,66,1,43,
127,6,69,1,13,
127,6,69,3,0,
127,6,70,4,0,
127,6,89,4,0,
127,6,91,4,0,
127,6,92,4,0,
127,6,102,3,0,
127,6,104,4,0,
127,6,106,1,19,
127,6,116,1,1,2
127,6,156,4,0,
127,6,157,3,0,
127,6,164,3,0,
127,6,168,4,0,
127,6,173,3,0,
127,6,175,2,0,
127,6,182,4,0,
127,6,185,2,0,
127,6,203,3,0,
127,6,206,2,0,
127,6,207,3,0,
127,6,210,3,0,
127,6,213,4,0,
127,6,214,3,0,
127,6,216,4,0,
127,6,218,4,0,
127,6,237,4,0,
127,6,240,4,0,
127,6,241,4,0,
127,6,249,4,0,
127,6,263,4,0,
127,6,264,4,0,
127,6,279,1,25,
127,6,280,1,31,
127,6,280,4,0,
127,6,290,4,0,
127,6,317,4,0,
127,6,339,4,0,
127,7,11,1,1,1
127,7,12,1,37,
127,7,14,1,49,
127,7,14,3,0,
127,7,15,4,0,
127,7,20,1,7,
127,7,31,2,0,
127,7,34,3,0,
127,7,38,3,0,
127,7,63,4,0,
127,7,66,1,43,
127,7,69,1,13,
127,7,69,3,0,
127,7,70,4,0,
127,7,89,4,0,
127,7,91,4,0,
127,7,92,4,0,
127,7,102,3,0,
127,7,104,4,0,
127,7,106,1,19,
127,7,116,1,1,2
127,7,156,4,0,
127,7,157,3,0,
127,7,164,3,0,
127,7,168,4,0,
127,7,175,2,0,
127,7,182,4,0,
127,7,185,2,0,
127,7,206,2,0,
127,7,213,4,0,
127,7,216,4,0,
127,7,218,4,0,
127,7,237,4,0,
127,7,240,4,0,
127,7,241,4,0,
127,7,249,4,0,
127,7,263,4,0,
127,7,264,4,0,
127,7,279,1,25,
127,7,280,1,31,
127,7,280,4,0,
127,7,290,4,0,
127,7,317,4,0,
127,7,339,4,0,
127,8,11,1,1,1
127,8,12,1,47,
127,8,14,1,38,
127,8,14,4,0,
127,8,15,4,0,
127,8,20,1,4,
127,8,31,2,0,
127,8,37,1,35,
127,8,63,4,0,
127,8,66,1,42,
127,8,69,1,8,
127,8,70,4,0,
127,8,89,4,0,
127,8,91,4,0,
127,8,92,4,0,
127,8,98,2,0,
127,8,104,4,0,
127,8,106,1,13,
127,8,116,1,1,2
127,8,156,4,0,
127,8,157,4,0,
127,8,164,4,0,
127,8,168,4,0,
127,8,175,2,0,
127,8,182,4,0,
127,8,185,2,0,
127,8,203,4,0,
127,8,206,2,0,
127,8,206,4,0,
127,8,207,4,0,
127,8,213,4,0,
127,8,214,4,0,
127,8,216,4,0,
127,8,218,4,0,
127,8,233,1,25,
127,8,237,4,0,
127,8,240,4,0,
127,8,241,4,0,
127,8,249,4,0,
127,8,263,4,0,
127,8,264,4,0,
127,8,276,1,52,
127,8,279,1,18,
127,8,280,1,21,
127,8,280,4,0,
127,8,290,4,0,
127,8,317,4,0,
127,8,339,4,0,
127,8,363,4,0,
127,8,364,2,0,
127,8,370,2,0,
127,8,374,4,0,
127,8,404,1,30,
127,8,404,4,0,
127,8,411,4,0,
127,8,416,4,0,
127,8,431,4,0,
127,8,444,4,0,
127,8,445,4,0,
127,8,446,4,0,
127,9,11,1,1,1
127,9,12,1,47,
127,9,14,1,38,
127,9,14,4,0,
127,9,15,4,0,
127,9,20,1,4,
127,9,31,2,0,
127,9,37,1,35,
127,9,63,4,0,
127,9,66,1,42,
127,9,69,1,8,
127,9,70,4,0,
127,9,89,4,0,
127,9,91,4,0,
127,9,92,4,0,
127,9,98,2,0,
127,9,104,4,0,
127,9,106,1,13,
127,9,116,1,1,2
127,9,156,4,0,
127,9,157,4,0,
127,9,164,4,0,
127,9,168,4,0,
127,9,173,3,0,
127,9,175,2,0,
127,9,182,4,0,
127,9,185,2,0,
127,9,203,4,0,
127,9,206,2,0,
127,9,206,4,0,
127,9,207,4,0,
127,9,210,3,0,
127,9,213,4,0,
127,9,214,4,0,
127,9,216,4,0,
127,9,218,4,0,
127,9,233,1,25,
127,9,237,4,0,
127,9,240,4,0,
127,9,241,4,0,
127,9,249,4,0,
127,9,263,4,0,
127,9,264,4,0,
127,9,276,1,52,
127,9,276,3,0,
127,9,279,1,18,
127,9,280,1,21,
127,9,280,4,0,
127,9,282,3,0,
127,9,290,4,0,
127,9,317,4,0,
127,9,334,3,0,
127,9,339,4,0,
127,9,363,4,0,
127,9,364,2,0,
127,9,370,2,0,
127,9,374,4,0,
127,9,404,1,30,
127,9,404,4,0,
127,9,411,4,0,
127,9,416,4,0,
127,9,431,4,0,
127,9,444,4,0,
127,9,445,4,0,
127,9,446,4,0,
127,10,11,1,1,1
127,10,12,1,47,
127,10,14,1,38,
127,10,14,4,0,
127,10,15,4,0,
127,10,20,1,4,
127,10,29,3,0,
127,10,31,2,0,
127,10,37,1,35,
127,10,63,4,0,
127,10,66,1,42,
127,10,69,1,8,
127,10,70,4,0,
127,10,81,3,0,
127,10,89,4,0,
127,10,91,4,0,
127,10,92,4,0,
127,10,98,2,0,
127,10,104,4,0,
127,10,106,1,13,
127,10,116,1,1,2
127,10,156,4,0,
127,10,157,4,0,
127,10,164,4,0,
127,10,168,4,0,
127,10,173,3,0,
127,10,175,2,0,
127,10,182,4,0,
127,10,185,2,0,
127,10,203,4,0,
127,10,206,2,0,
127,10,206,4,0,
127,10,207,4,0,
127,10,210,3,0,
127,10,213,4,0,
127,10,214,4,0,
127,10,216,4,0,
127,10,218,4,0,
127,10,233,1,25,
127,10,237,4,0,
127,10,240,4,0,
127,10,241,4,0,
127,10,249,4,0,
127,10,263,4,0,
127,10,264,4,0,
127,10,276,1,52,
127,10,276,3,0,
127,10,279,1,18,
127,10,280,1,21,
127,10,280,4,0,
127,10,282,3,0,
127,10,290,4,0,
127,10,317,4,0,
127,10,334,3,0,
127,10,339,4,0,
127,10,363,4,0,
127,10,364,2,0,
127,10,370,2,0,
127,10,374,4,0,
127,10,404,1,30,
127,10,404,4,0,
127,10,411,4,0,
127,10,416,4,0,
127,10,431,4,0,
127,10,444,4,0,
127,10,445,4,0,
127,10,446,4,0,
127,11,11,1,1,1
127,11,12,1,47,
127,11,14,1,38,
127,11,14,4,0,
127,11,15,4,0,
127,11,20,1,4,
127,11,31,2,0,
127,11,37,1,35,
127,11,63,4,0,
127,11,66,1,42,
127,11,69,1,8,
127,11,70,4,0,
127,11,89,4,0,
127,11,91,4,0,
127,11,92,4,0,
127,11,98,2,0,
127,11,104,4,0,
127,11,106,1,13,
127,11,116,1,1,2
127,11,156,4,0,
127,11,157,4,0,
127,11,164,4,0,
127,11,168,4,0,
127,11,175,2,0,
127,11,182,4,0,
127,11,185,2,0,
127,11,206,4,0,
127,11,207,4,0,
127,11,213,4,0,
127,11,216,4,0,
127,11,218,4,0,
127,11,233,1,25,
127,11,237,4,0,
127,11,240,4,0,
127,11,241,4,0,
127,11,249,4,0,
127,11,263,4,0,
127,11,276,1,52,
127,11,276,2,0,
127,11,279,1,18,
127,11,280,1,21,
127,11,280,4,0,
127,11,317,4,0,
127,11,339,4,0,
127,11,364,2,0,
127,11,370,2,0,
127,11,374,4,0,
127,11,382,2,0,
127,11,404,1,30,
127,11,404,4,0,
127,11,411,4,0,
127,11,416,4,0,
127,11,444,4,0,
127,11,450,2,0,
127,11,479,4,0,
127,11,496,4,0,
127,11,522,4,0,
127,11,523,4,0,
127,12,11,1,1,1
127,12,12,1,37,
127,12,14,1,49,
127,12,15,4,0,
127,12,20,1,7,
127,12,63,4,0,
127,12,66,1,43,
127,12,69,1,13,
127,12,70,4,0,
127,12,89,4,0,
127,12,91,4,0,
127,12,92,4,0,
127,12,104,4,0,
127,12,106,1,19,
127,12,116,1,1,2
127,12,156,4,0,
127,12,168,4,0,
127,12,182,4,0,
127,12,213,4,0,
127,12,216,4,0,
127,12,218,4,0,
127,12,237,4,0,
127,12,240,4,0,
127,12,241,4,0,
127,12,249,4,0,
127,12,263,4,0,
127,12,264,4,0,
127,12,279,1,25,
127,12,280,1,31,
127,12,280,4,0,
127,12,290,4,0,
127,12,317,4,0,
127,12,339,4,0,
127,13,11,1,1,1
127,13,12,1,37,
127,13,14,1,49,
127,13,15,4,0,
127,13,20,1,7,
127,13,34,3,0,
127,13,38,3,0,
127,13,63,4,0,
127,13,66,1,43,
127,13,69,1,13,
127,13,69,3,0,
127,13,70,4,0,
127,13,89,4,0,
127,13,91,4,0,
127,13,92,4,0,
127,13,102,3,0,
127,13,104,4,0,
127,13,106,1,19,
127,13,116,1,1,2
127,13,156,4,0,
127,13,164,3,0,
127,13,168,4,0,
127,13,182,4,0,
127,13,207,3,0,
127,13,213,4,0,
127,13,216,4,0,
127,13,218,4,0,
127,13,237,4,0,
127,13,240,4,0,
127,13,241,4,0,
127,13,249,4,0,
127,13,263,4,0,
127,13,264,4,0,
127,13,279,1,25,
127,13,280,1,31,
127,13,280,4,0,
127,13,290,4,0,
127,13,317,4,0,
127,13,339,4,0,
127,14,11,1,1,1
127,14,12,1,47,
127,14,14,1,40,
127,14,14,4,0,
127,14,15,4,0,
127,14,20,1,4,
127,14,20,3,0,
127,14,31,2,0,
127,14,37,1,36,
127,14,63,4,0,
127,14,66,1,26,
127,14,69,1,8,
127,14,70,4,0,
127,14,89,4,0,
127,14,91,4,0,
127,14,92,4,0,
127,14,98,2,0,
127,14,104,4,0,
127,14,106,1,11,
127,14,116,1,1,2
127,14,156,4,0,
127,14,157,4,0,
127,14,164,4,0,
127,14,168,4,0,
127,14,173,3,0,
127,14,175,2,0,
127,14,182,4,0,
127,14,185,2,0,
127,14,206,4,0,
127,14,207,4,0,
127,14,213,4,0,
127,14,214,3,0,
127,14,216,4,0,
127,14,218,4,0,
127,14,233,1,22,
127,14,237,4,0,
127,14,240,4,0,
127,14,241,4,0,
127,14,249,4,0,
127,14,263,4,0,
127,14,276,1,43,
127,14,276,2,0,
127,14,276,3,0,
127,14,279,1,15,
127,14,280,1,18,
127,14,280,4,0,
127,14,282,3,0,
127,14,317,4,0,
127,14,334,3,0,
127,14,339,4,0,
127,14,364,2,0,
127,14,370,2,0,
127,14,374,4,0,
127,14,382,2,0,
127,14,404,1,29,
127,14,404,4,0,
127,14,411,4,0,
127,14,416,4,0,
127,14,444,4,0,
127,14,446,3,0,
127,14,450,2,0,
127,14,450,3,0,
127,14,479,4,0,
127,14,480,1,33,
127,14,496,4,0,
127,14,522,4,0,
127,14,523,4,0,
127,15,11,1,1,1
127,15,12,1,47,
127,15,14,1,40,
127,15,14,4,0,
127,15,15,4,0,
127,15,20,1,4,
127,15,31,2,0,
127,15,37,1,36,
127,15,63,4,0,
127,15,66,1,26,
127,15,69,1,8,
127,15,70,4,0,
127,15,89,4,0,
127,15,91,4,0,
127,15,92,4,0,
127,15,98,2,0,
127,15,104,4,0,
127,15,106,1,11,
127,15,116,1,1,2
127,15,156,4,0,
127,15,157,4,0,
127,15,164,4,0,
127,15,168,4,0,
127,15,175,2,0,
127,15,182,4,0,
127,15,185,2,0,
127,15,206,4,0,
127,15,207,4,0,
127,15,213,4,0,
127,15,214,4,0,
127,15,216,4,0,
127,15,218,4,0,
127,15,233,1,22,
127,15,237,4,0,
127,15,240,4,0,
127,15,241,4,0,
127,15,249,4,0,
127,15,263,4,0,
127,15,276,1,43,
127,15,276,2,0,
127,15,279,1,15,
127,15,280,1,18,
127,15,280,4,0,
127,15,317,4,0,
127,15,339,4,0,
127,15,364,2,0,
127,15,370,2,0,
127,15,374,4,0,
127,15,382,2,0,
127,15,404,1,29,
127,15,404,4,0,
127,15,411,4,0,
127,15,416,4,0,
127,15,444,4,0,
127,15,450,2,0,
127,15,479,4,0,
127,15,480,1,33,
127,15,496,4,0,
127,15,522,4,0,
127,15,523,4,0,
127,15,590,4,0,
127,16,11,1,1,1
127,16,12,1,50,1
127,16,14,1,40,1
127,16,14,4,0,
127,16,15,4,0,
127,16,20,1,4,1
127,16,20,3,0,
127,16,31,2,0,
127,16,37,1,43,1
127,16,63,4,0,
127,16,66,1,29,1
127,16,69,1,8,1
127,16,70,4,0,
127,16,89,4,0,
127,16,91,4,0,
127,16,92,4,0,
127,16,98,2,0,
127,16,104,4,0,
127,16,106,1,11,1
127,16,116,1,1,2
127,16,156,4,0,
127,16,157,4,0,
127,16,164,4,0,
127,16,168,4,0,
127,16,173,3,0,
127,16,175,2,0,
127,16,182,4,0,
127,16,185,2,0,
127,16,206,4,0,
127,16,207,4,0,
127,16,213,4,0,
127,16,214,4,0,
127,16,216,4,0,
127,16,218,4,0,
127,16,233,1,18,1
127,16,237,4,0,
127,16,240,4,0,
127,16,241,4,0,
127,16,249,4,0,
127,16,263,4,0,
127,16,264,3,0,
127,16,276,1,47,1
127,16,276,2,0,
127,16,276,3,0,
127,16,279,1,15,1
127,16,280,1,26,1
127,16,280,4,0,
127,16,282,3,0,
127,16,290,4,0,
127,16,317,4,0,
127,16,334,3,0,
127,16,339,4,0,
127,16,364,2,0,
127,16,370,2,0,
127,16,374,4,0,
127,16,382,2,0,
127,16,404,1,33,1
127,16,404,4,0,
127,16,411,4,0,
127,16,416,4,0,
127,16,444,4,0,
127,16,446,3,0,
127,16,450,2,0,
127,16,450,3,0,
127,16,458,1,22,1
127,16,479,4,0,
127,16,480,1,36,1
127,16,496,4,0,
127,16,522,4,0,
127,16,523,4,0,
127,16,590,4,0,
127,17,11,1,1,1
127,17,12,1,50,
127,17,14,1,40,
127,17,14,4,0,
127,17,20,1,4,
127,17,31,2,0,
127,17,37,1,43,
127,17,63,4,0,
127,17,66,1,33,
127,17,69,1,8,
127,17,89,4,0,
127,17,92,4,0,
127,17,98,2,0,
127,17,104,4,0,
127,17,106,1,11,
127,17,116,1,1,2
127,17,156,4,0,
127,17,157,4,0,
127,17,164,4,0,
127,17,168,4,0,
127,17,175,2,0,
127,17,182,4,0,
127,17,185,2,0,
127,17,206,4,0,
127,17,207,4,0,
127,17,213,4,0,
127,17,214,4,0,
127,17,216,4,0,
127,17,218,4,0,
127,17,233,1,18,
127,17,237,4,0,
127,17,240,4,0,
127,17,241,4,0,
127,17,263,4,0,
127,17,276,1,47,
127,17,276,2,0,
127,17,279,1,15,
127,17,280,1,26,
127,17,280,4,0,
127,17,317,4,0,
127,17,339,4,0,
127,17,364,2,0,
127,17,370,2,0,
127,17,374,4,0,
127,17,382,2,0,
127,17,404,1,29,
127,17,404,4,0,
127,17,411,4,0,
127,17,416,4,0,
127,17,444,4,0,
127,17,450,2,0,
127,17,458,1,22,
127,17,479,4,0,
127,17,480,1,36,
127,17,496,4,0,
127,17,523,4,0,
127,17,590,4,0,
127,17,693,4,0,
127,18,11,1,1,1
127,18,12,1,50,
127,18,14,1,40,
127,18,14,4,0,
127,18,20,1,4,
127,18,31,2,0,
127,18,37,1,43,
127,18,63,4,0,
127,18,66,1,33,
127,18,69,1,8,
127,18,89,4,0,
127,18,92,4,0,
127,18,98,2,0,
127,18,104,4,0,
127,18,106,1,11,
127,18,116,1,1,2
127,18,156,4,0,
127,18,157,4,0,
127,18,164,4,0,
127,18,168,4,0,
127,18,175,2,0,
127,18,182,4,0,
127,18,185,2,0,
127,18,206,4,0,
127,18,207,4,0,
127,18,213,4,0,
127,18,214,4,0,
127,18,216,4,0,
127,18,218,4,0,
127,18,233,1,18,
127,18,237,4,0,
127,18,240,4,0,
127,18,241,4,0,
127,18,263,4,0,
127,18,276,1,47,
127,18,276,2,0,
127,18,279,1,15,
127,18,280,1,26,
127,18,280,4,0,
127,18,317,4,0,
127,18,339,4,0,
127,18,364,2,0,
127,18,370,2,0,
127,18,374,4,0,
127,18,382,2,0,
127,18,404,1,29,
127,18,404,4,0,
127,18,411,4,0,
127,18,416,4,0,
127,18,444,4,0,
127,18,450,2,0,
127,18,458,1,22,
127,18,479,4,0,
127,18,480,1,36,
127,18,496,4,0,
127,18,523,4,0,
127,18,590,4,0,
127,18,693,4,0,
128,1,23,1,21,
128,1,32,4,0,
128,1,33,1,1,
128,1,34,4,0,
128,1,36,1,51,
128,1,36,4,0,
128,1,38,4,0,
128,1,39,1,28,
128,1,43,1,35,
128,1,58,4,0,
128,1,59,4,0,
128,1,63,4,0,
128,1,70,4,0,
128,1,85,4,0,
128,1,87,4,0,
128,1,89,4,0,
128,1,90,4,0,
128,1,92,4,0,
128,1,99,1,44,
128,1,99,4,0,
128,1,102,4,0,
128,1,104,4,0,
128,1,117,4,0,
128,1,126,4,0,
128,1,130,4,0,
128,1,156,4,0,
128,1,164,4,0,
128,2,23,1,21,
128,2,32,4,0,
128,2,33,1,1,
128,2,34,4,0,
128,2,36,1,51,
128,2,36,4,0,
128,2,38,4,0,
128,2,39,1,28,
128,2,43,1,35,
128,2,58,4,0,
128,2,59,4,0,
128,2,63,4,0,
128,2,70,4,0,
128,2,85,4,0,
128,2,87,4,0,
128,2,89,4,0,
128,2,90,4,0,
128,2,92,4,0,
128,2,99,1,44,
128,2,99,4,0,
128,2,102,4,0,
128,2,104,4,0,
128,2,117,4,0,
128,2,126,4,0,
128,2,130,4,0,
128,2,156,4,0,
128,2,164,4,0,
128,3,29,4,0,
128,3,30,1,13,
128,3,33,1,1,
128,3,36,1,53,
128,3,37,1,43,
128,3,39,1,4,
128,3,57,4,0,
128,3,59,4,0,
128,3,63,4,0,
128,3,70,4,0,
128,3,87,4,0,
128,3,89,4,0,
128,3,92,4,0,
128,3,99,1,8,
128,3,104,4,0,
128,3,126,4,0,
128,3,156,1,34,
128,3,156,4,0,
128,3,173,4,0,
128,3,174,4,0,
128,3,182,4,0,
128,3,184,1,19,
128,3,192,4,0,
128,3,196,4,0,
128,3,203,4,0,
128,3,207,4,0,
128,3,213,4,0,
128,3,214,4,0,
128,3,216,4,0,
128,3,218,4,0,
128,3,228,1,26,
128,3,231,4,0,
128,3,237,4,0,
128,3,241,4,0,
128,3,249,4,0,
128,4,29,4,0,
128,4,30,1,13,
128,4,33,1,1,
128,4,36,1,53,
128,4,37,1,43,
128,4,39,1,4,
128,4,53,3,0,
128,4,57,4,0,
128,4,58,3,0,
128,4,59,4,0,
128,4,63,4,0,
128,4,70,4,0,
128,4,85,3,0,
128,4,87,4,0,
128,4,89,4,0,
128,4,92,4,0,
128,4,99,1,8,
128,4,104,4,0,
128,4,126,4,0,
128,4,156,1,34,
128,4,156,4,0,
128,4,173,4,0,
128,4,174,4,0,
128,4,182,4,0,
128,4,184,1,19,
128,4,192,4,0,
128,4,196,4,0,
128,4,203,4,0,
128,4,207,4,0,
128,4,213,4,0,
128,4,214,4,0,
128,4,216,4,0,
128,4,218,4,0,
128,4,228,1,26,
128,4,231,4,0,
128,4,237,4,0,
128,4,241,4,0,
128,4,249,4,0,
128,5,30,1,13,
128,5,33,1,1,
128,5,36,1,53,
128,5,37,1,43,
128,5,39,1,4,
128,5,53,4,0,
128,5,57,4,0,
128,5,58,4,0,
128,5,59,4,0,
128,5,63,4,0,
128,5,70,4,0,
128,5,76,4,0,
128,5,85,4,0,
128,5,87,4,0,
128,5,89,4,0,
128,5,92,4,0,
128,5,99,1,8,
128,5,104,4,0,
128,5,126,4,0,
128,5,156,1,34,
128,5,156,4,0,
128,5,182,4,0,
128,5,184,1,19,
128,5,201,4,0,
128,5,213,4,0,
128,5,216,4,0,
128,5,218,4,0,
128,5,228,1,26,
128,5,231,4,0,
128,5,237,4,0,
128,5,240,4,0,
128,5,241,4,0,
128,5,249,4,0,
128,5,263,4,0,
128,5,290,4,0,
128,5,317,4,0,
128,5,351,4,0,
128,5,352,4,0,
128,6,30,1,13,
128,6,33,1,1,
128,6,34,3,0,
128,6,36,1,53,
128,6,37,1,43,
128,6,38,3,0,
128,6,39,1,4,
128,6,53,4,0,
128,6,57,4,0,
128,6,58,4,0,
128,6,59,4,0,
128,6,63,4,0,
128,6,70,4,0,
128,6,76,4,0,
128,6,85,4,0,
128,6,87,4,0,
128,6,89,4,0,
128,6,92,4,0,
128,6,99,1,8,
128,6,102,3,0,
128,6,104,4,0,
128,6,126,4,0,
128,6,156,1,34,
128,6,156,4,0,
128,6,164,3,0,
128,6,173,3,0,
128,6,182,4,0,
128,6,184,1,19,
128,6,196,3,0,
128,6,201,4,0,
128,6,203,3,0,
128,6,207,3,0,
128,6,213,4,0,
128,6,214,3,0,
128,6,216,4,0,
128,6,218,4,0,
128,6,228,1,26,
128,6,231,4,0,
128,6,237,4,0,
128,6,240,4,0,
128,6,241,4,0,
128,6,249,4,0,
128,6,263,4,0,
128,6,290,4,0,
128,6,317,4,0,
128,6,351,4,0,
128,6,352,4,0,
128,7,30,1,8,
128,7,33,1,1,1
128,7,34,3,0,
128,7,36,1,53,
128,7,37,1,43,
128,7,38,3,0,
128,7,39,1,1,2
128,7,53,4,0,
128,7,57,4,0,
128,7,58,4,0,
128,7,59,4,0,
128,7,63,4,0,
128,7,70,4,0,
128,7,76,4,0,
128,7,85,4,0,
128,7,87,4,0,
128,7,89,4,0,
128,7,92,4,0,
128,7,99,1,4,
128,7,102,3,0,
128,7,104,4,0,
128,7,126,4,0,
128,7,156,1,34,
128,7,156,4,0,
128,7,164,3,0,
128,7,182,4,0,
128,7,184,1,13,
128,7,201,4,0,
128,7,207,1,26,
128,7,213,4,0,
128,7,216,4,0,
128,7,218,4,0,
128,7,228,1,19,
128,7,231,4,0,
128,7,237,4,0,
128,7,240,4,0,
128,7,241,4,0,
128,7,249,4,0,
128,7,263,4,0,
128,7,290,4,0,
128,7,317,4,0,
128,7,351,4,0,
128,7,352,4,0,
128,8,30,1,8,
128,8,33,1,1,
128,8,36,1,35,
128,8,37,1,48,
128,8,39,1,3,
128,8,53,4,0,
128,8,57,4,0,
128,8,58,4,0,
128,8,59,4,0,
128,8,63,4,0,
128,8,70,4,0,
128,8,76,4,0,
128,8,85,4,0,
128,8,87,4,0,
128,8,89,4,0,
128,8,92,4,0,
128,8,99,1,5,
128,8,104,4,0,
128,8,126,4,0,
128,8,156,1,19,
128,8,156,4,0,
128,8,157,4,0,
128,8,164,4,0,
128,8,182,4,0,
128,8,184,1,11,
128,8,201,4,0,
128,8,203,4,0,
128,8,207,1,41,
128,8,207,4,0,
128,8,213,4,0,
128,8,214,4,0,
128,8,216,4,0,
128,8,218,4,0,
128,8,228,1,15,
128,8,231,4,0,
128,8,237,4,0,
128,8,240,4,0,
128,8,241,4,0,
128,8,249,4,0,
128,8,263,4,0,
128,8,290,4,0,
128,8,317,4,0,
128,8,351,4,0,
128,8,352,4,0,
128,8,363,4,0,
128,8,371,1,24,
128,8,371,4,0,
128,8,416,1,55,
128,8,416,4,0,
128,8,428,1,29,
128,8,431,4,0,
128,8,444,4,0,
128,8,445,4,0,
128,9,30,1,8,
128,9,33,1,1,
128,9,36,1,35,
128,9,37,1,48,
128,9,39,1,3,
128,9,53,4,0,
128,9,57,4,0,
128,9,58,4,0,
128,9,59,4,0,
128,9,63,4,0,
128,9,70,4,0,
128,9,76,4,0,
128,9,85,4,0,
128,9,87,4,0,
128,9,89,4,0,
128,9,92,4,0,
128,9,99,1,5,
128,9,104,4,0,
128,9,126,4,0,
128,9,156,1,19,
128,9,156,4,0,
128,9,157,4,0,
128,9,164,4,0,
128,9,173,3,0,
128,9,180,3,0,
128,9,182,4,0,
128,9,184,1,11,
128,9,196,3,0,
128,9,200,3,0,
128,9,201,4,0,
128,9,203,4,0,
128,9,207,1,41,
128,9,207,4,0,
128,9,213,4,0,
128,9,214,4,0,
128,9,216,4,0,
128,9,218,4,0,
128,9,228,1,15,
128,9,231,4,0,
128,9,237,4,0,
128,9,240,4,0,
128,9,241,4,0,
128,9,249,4,0,
128,9,253,3,0,
128,9,263,4,0,
128,9,270,3,0,
128,9,283,3,0,
128,9,290,4,0,
128,9,317,4,0,
128,9,351,4,0,
128,9,352,4,0,
128,9,363,4,0,
128,9,371,1,24,
128,9,371,4,0,
128,9,416,1,55,
128,9,416,4,0,
128,9,428,1,29,
128,9,428,3,0,
128,9,431,4,0,
128,9,442,3,0,
128,9,444,4,0,
128,9,445,4,0,
128,10,29,3,0,
128,10,30,1,8,
128,10,33,1,1,
128,10,36,1,35,
128,10,37,1,48,
128,10,39,1,3,
128,10,53,4,0,
128,10,57,4,0,
128,10,58,4,0,
128,10,59,4,0,
128,10,63,4,0,
128,10,70,4,0,
128,10,76,4,0,
128,10,85,4,0,
128,10,87,4,0,
128,10,89,4,0,
128,10,92,4,0,
128,10,99,1,5,
128,10,104,4,0,
128,10,126,4,0,
128,10,156,1,19,
128,10,156,4,0,
128,10,157,4,0,
128,10,164,4,0,
128,10,173,3,0,
128,10,180,3,0,
128,10,182,4,0,
128,10,184,1,11,
128,10,196,3,0,
128,10,200,3,0,
128,10,201,4,0,
128,10,203,4,0,
128,10,207,1,41,
128,10,207,4,0,
128,10,213,4,0,
128,10,214,4,0,
128,10,216,4,0,
128,10,218,4,0,
128,10,228,1,15,
128,10,231,4,0,
128,10,237,4,0,
128,10,240,4,0,
128,10,241,4,0,
128,10,249,4,0,
128,10,250,4,0,
128,10,253,3,0,
128,10,263,4,0,
128,10,270,3,0,
128,10,272,3,0,
128,10,283,3,0,
128,10,290,4,0,
128,10,317,4,0,
128,10,351,4,0,
128,10,352,4,0,
128,10,363,4,0,
128,10,371,1,24,
128,10,371,4,0,
128,10,416,1,55,
128,10,416,4,0,
128,10,428,1,29,
128,10,428,3,0,
128,10,431,4,0,
128,10,442,3,0,
128,10,444,4,0,
128,10,445,4,0,
128,11,30,1,8,
128,11,33,1,1,
128,11,36,1,41,
128,11,37,1,55,
128,11,39,1,3,
128,11,53,4,0,
128,11,57,4,0,
128,11,58,4,0,
128,11,59,4,0,
128,11,63,4,0,
128,11,70,4,0,
128,11,76,4,0,
128,11,85,4,0,
128,11,87,4,0,
128,11,89,4,0,
128,11,92,4,0,
128,11,99,1,5,
128,11,104,4,0,
128,11,126,4,0,
128,11,156,1,19,
128,11,156,4,0,
128,11,157,4,0,
128,11,164,4,0,
128,11,182,4,0,
128,11,184,1,11,
128,11,201,4,0,
128,11,207,1,48,
128,11,207,4,0,
128,11,213,4,0,
128,11,216,4,0,
128,11,218,4,0,
128,11,228,1,15,
128,11,237,4,0,
128,11,240,4,0,
128,11,241,4,0,
128,11,249,4,0,
128,11,263,4,0,
128,11,317,4,0,
128,11,371,1,24,
128,11,371,4,0,
128,11,416,1,63,
128,11,416,4,0,
128,11,428,1,35,
128,11,444,4,0,
128,11,496,4,0,
128,11,510,4,0,
128,11,514,4,0,
128,11,523,4,0,
128,11,526,1,29,
128,11,526,4,0,
128,11,528,4,0,
128,12,30,1,13,
128,12,33,1,1,
128,12,36,1,53,
128,12,37,1,43,
128,12,39,1,4,
128,12,53,4,0,
128,12,57,4,0,
128,12,58,4,0,
128,12,59,4,0,
128,12,63,4,0,
128,12,70,4,0,
128,12,76,4,0,
128,12,85,4,0,
128,12,87,4,0,
128,12,89,4,0,
128,12,92,4,0,
128,12,99,1,8,
128,12,104,4,0,
128,12,126,4,0,
128,12,156,1,34,
128,12,156,4,0,
128,12,182,4,0,
128,12,184,1,19,
128,12,201,4,0,
128,12,213,4,0,
128,12,216,4,0,
128,12,218,4,0,
128,12,228,1,26,
128,12,231,4,0,
128,12,237,4,0,
128,12,240,4,0,
128,12,241,4,0,
128,12,249,4,0,
128,12,263,4,0,
128,12,290,4,0,
128,12,317,4,0,
128,12,351,4,0,
128,12,352,4,0,
128,13,30,1,13,
128,13,33,1,1,
128,13,34,3,0,
128,13,36,1,53,
128,13,37,1,43,
128,13,38,3,0,
128,13,39,1,4,
128,13,53,4,0,
128,13,57,4,0,
128,13,58,4,0,
128,13,59,4,0,
128,13,63,4,0,
128,13,70,4,0,
128,13,76,4,0,
128,13,85,4,0,
128,13,87,4,0,
128,13,89,4,0,
128,13,92,4,0,
128,13,99,1,8,
128,13,102,3,0,
128,13,104,4,0,
128,13,126,4,0,
128,13,156,1,34,
128,13,156,4,0,
128,13,164,3,0,
128,13,182,4,0,
128,13,184,1,19,
128,13,196,3,0,
128,13,201,4,0,
128,13,207,3,0,
128,13,213,4,0,
128,13,216,4,0,
128,13,218,4,0,
128,13,228,1,26,
128,13,231,4,0,
128,13,237,4,0,
128,13,240,4,0,
128,13,241,4,0,
128,13,249,4,0,
128,13,263,4,0,
128,13,290,4,0,
128,13,317,4,0,
128,13,351,4,0,
128,13,352,4,0,
128,14,30,1,8,
128,14,33,1,1,
128,14,36,1,41,
128,14,37,1,55,
128,14,39,1,3,
128,14,53,4,0,
128,14,57,4,0,
128,14,58,4,0,
128,14,59,4,0,
128,14,63,4,0,
128,14,70,4,0,
128,14,76,4,0,
128,14,85,4,0,
128,14,87,4,0,
128,14,89,4,0,
128,14,92,4,0,
128,14,99,1,5,
128,14,104,4,0,
128,14,126,4,0,
128,14,156,1,19,
128,14,156,4,0,
128,14,157,4,0,
128,14,164,4,0,
128,14,173,3,0,
128,14,180,3,0,
128,14,182,4,0,
128,14,184,1,11,
128,14,196,3,0,
128,14,200,3,0,
128,14,201,4,0,
128,14,207,1,48,
128,14,207,4,0,
128,14,213,4,0,
128,14,214,3,0,
128,14,216,4,0,
128,14,218,4,0,
128,14,228,1,15,
128,14,231,3,0,
128,14,237,4,0,
128,14,240,4,0,
128,14,241,4,0,
128,14,249,4,0,
128,14,253,3,0,
128,14,263,4,0,
128,14,270,3,0,
128,14,272,3,0,
128,14,283,3,0,
128,14,317,4,0,
128,14,371,1,24,
128,14,371,4,0,
128,14,416,1,63,
128,14,416,4,0,
128,14,428,1,35,
128,14,428,3,0,
128,14,442,3,0,
128,14,444,4,0,
128,14,496,4,0,
128,14,510,4,0,
128,14,514,4,0,
128,14,523,4,0,
128,14,526,1,29,
128,14,526,4,0,
128,14,528,4,0,
128,15,30,1,8,
128,15,33,1,1,
128,15,36,1,41,
128,15,37,1,50,
128,15,39,1,3,
128,15,53,4,0,
128,15,57,4,0,
128,15,58,4,0,
128,15,59,4,0,
128,15,63,4,0,
128,15,70,4,0,
128,15,76,4,0,
128,15,85,4,0,
128,15,87,4,0,
128,15,89,4,0,
128,15,92,4,0,
128,15,99,1,5,
128,15,104,4,0,
128,15,126,4,0,
128,15,156,1,19,
128,15,156,4,0,
128,15,157,4,0,
128,15,164,4,0,
128,15,182,4,0,
128,15,184,1,11,
128,15,201,4,0,
128,15,207,1,48,
128,15,207,4,0,
128,15,213,4,0,
128,15,214,4,0,
128,15,216,4,0,
128,15,218,4,0,
128,15,228,1,15,
128,15,237,4,0,
128,15,240,4,0,
128,15,241,4,0,
128,15,249,4,0,
128,15,263,4,0,
128,15,317,4,0,
128,15,371,1,24,
128,15,371,4,0,
128,15,416,1,63,
128,15,416,4,0,
128,15,428,1,35,
128,15,444,4,0,
128,15,496,4,0,
128,15,510,4,0,
128,15,514,4,0,
128,15,523,4,0,
128,15,526,1,29,
128,15,528,4,0,
128,15,590,4,0,
128,16,30,1,8,1
128,16,33,1,1,1
128,16,36,1,41,1
128,16,37,1,50,1
128,16,39,1,3,1
128,16,53,4,0,
128,16,57,4,0,
128,16,58,4,0,
128,16,59,4,0,
128,16,63,4,0,
128,16,70,4,0,
128,16,76,4,0,
128,16,85,4,0,
128,16,87,4,0,
128,16,89,4,0,
128,16,92,4,0,
128,16,99,1,5,1
128,16,104,4,0,
128,16,126,4,0,
128,16,156,1,19,1
128,16,156,4,0,
128,16,157,4,0,
128,16,164,4,0,
128,16,173,3,0,
128,16,180,3,0,
128,16,182,4,0,
128,16,184,1,11,1
128,16,196,3,0,
128,16,200,3,0,
128,16,201,4,0,
128,16,207,1,48,1
128,16,207,4,0,
128,16,213,4,0,
128,16,214,4,0,
128,16,216,4,0,
128,16,218,4,0,
128,16,228,1,15,1
128,16,231,3,0,
128,16,237,4,0,
128,16,240,4,0,
128,16,241,4,0,
128,16,249,4,0,
128,16,253,3,0,
128,16,263,4,0,
128,16,270,3,0,
128,16,272,3,0,
128,16,283,3,0,
128,16,290,4,0,
128,16,317,4,0,
128,16,351,3,0,
128,16,352,3,0,
128,16,371,1,24,1
128,16,371,4,0,
128,16,416,1,63,1
128,16,416,4,0,
128,16,428,1,35,1
128,16,428,3,0,
128,16,442,3,0,
128,16,444,4,0,
128,16,496,4,0,
128,16,510,4,0,
128,16,514,4,0,
128,16,523,4,0,
128,16,526,1,29,1
128,16,528,4,0,
128,16,590,4,0,
128,17,30,1,8,
128,17,33,1,1,
128,17,36,1,41,
128,17,37,1,50,
128,17,39,1,3,
128,17,53,4,0,
128,17,57,4,0,
128,17,58,4,0,
128,17,59,4,0,
128,17,63,4,0,
128,17,76,4,0,
128,17,85,4,0,
128,17,87,4,0,
128,17,89,4,0,
128,17,92,4,0,
128,17,99,1,5,
128,17,104,4,0,
128,17,126,4,0,
128,17,156,1,19,
128,17,156,4,0,
128,17,157,4,0,
128,17,164,4,0,
128,17,182,4,0,
128,17,184,1,11,
128,17,201,4,0,
128,17,207,1,48,
128,17,207,4,0,
128,17,213,4,0,
128,17,214,4,0,
128,17,216,4,0,
128,17,218,4,0,
128,17,228,1,15,
128,17,237,4,0,
128,17,240,4,0,
128,17,241,4,0,
128,17,263,4,0,
128,17,317,4,0,
128,17,371,1,24,
128,17,371,4,0,
128,17,416,1,63,
128,17,416,4,0,
128,17,428,1,35,
128,17,444,4,0,
128,17,496,4,0,
128,17,523,4,0,
128,17,526,1,29,
128,17,526,4,0,
128,17,528,4,0,
128,17,590,4,0,
128,17,684,4,0,
128,18,30,1,8,
128,18,33,1,1,
128,18,36,1,35,
128,18,37,1,55,
128,18,38,1,63,
128,18,39,1,3,
128,18,53,4,0,
128,18,57,4,0,
128,18,58,4,0,
128,18,59,4,0,
128,18,63,4,0,
128,18,76,4,0,
128,18,85,4,0,
128,18,87,4,0,
128,18,89,4,0,
128,18,92,4,0,
128,18,99,1,5,
128,18,104,4,0,
128,18,126,4,0,
128,18,156,1,19,
128,18,156,4,0,
128,18,157,4,0,
128,18,164,4,0,
128,18,182,4,0,
128,18,184,1,11,
128,18,201,4,0,
128,18,207,1,48,
128,18,207,4,0,
128,18,213,4,0,
128,18,214,4,0,
128,18,216,4,0,
128,18,218,4,0,
128,18,228,1,15,
128,18,237,4,0,
128,18,240,4,0,
128,18,241,4,0,
128,18,263,4,0,
128,18,317,4,0,
128,18,371,1,24,
128,18,371,4,0,
128,18,416,1,71,
128,18,416,4,0,
128,18,428,1,41,
128,18,444,4,0,
128,18,496,4,0,
128,18,523,4,0,
128,18,526,1,29,
128,18,526,4,0,
128,18,528,4,0,
128,18,590,4,0,
128,18,684,4,0,
129,1,33,1,15,
129,1,150,1,1,
129,2,33,1,15,
129,2,150,1,1,
129,3,33,1,15,
129,3,150,1,1,
129,3,175,1,30,
129,4,33,1,15,
129,4,150,1,1,
129,4,175,1,30,
129,5,33,1,15,
129,5,150,1,1,
129,5,175,1,30,
129,6,33,1,15,
129,6,150,1,1,
129,6,175,1,30,
129,7,33,1,15,
129,7,150,1,1,
129,7,175,1,30,
129,8,33,1,15,
129,8,150,1,1,
129,8,175,1,30,
129,9,33,1,15,
129,9,150,1,1,
129,9,175,1,30,
129,9,340,3,0,
129,10,33,1,15,
129,10,150,1,1,
129,10,175,1,30,
129,10,340,3,0,
129,11,33,1,15,
129,11,150,1,1,
129,11,175,1,30,
129,12,33,1,15,
129,12,150,1,1,
129,12,175,1,30,
129,13,33,1,15,
129,13,150,1,1,
129,13,175,1,30,
129,14,33,1,15,
129,14,150,1,1,
129,14,175,1,30,
129,14,340,3,0,
129,15,33,1,15,
129,15,150,1,1,
129,15,175,1,30,
129,16,33,1,15,1
129,16,150,1,1,1
129,16,175,1,30,1
129,16,340,3,0,
129,17,33,1,15,
129,17,150,1,1,
129,17,175,1,30,
129,18,33,1,15,
129,18,150,1,1,
129,18,175,1,30,
130,1,34,4,0,
130,1,36,4,0,
130,1,38,4,0,
130,1,43,1,1,3
130,1,43,1,32,
130,1,44,1,1,1
130,1,44,1,20,
130,1,55,4,0,
130,1,56,1,1,4
130,1,56,1,41,
130,1,57,4,0,
130,1,58,4,0,
130,1,59,4,0,
130,1,61,4,0,
130,1,63,1,52,
130,1,63,4,0,
130,1,70,4,0,
130,1,82,1,1,2
130,1,82,1,25,
130,1,82,4,0,
130,1,85,4,0,
130,1,87,4,0,
130,1,92,4,0,
130,1,99,4,0,
130,1,102,4,0,
130,1,104,4,0,
130,1,115,4,0,
130,1,117,4,0,
130,1,126,4,0,
130,1,130,4,0,
130,1,156,4,0,
130,1,164,4,0,
130,2,33,1,1,
130,2,34,4,0,
130,2,36,4,0,
130,2,38,4,0,
130,2,43,1,32,
130,2,44,1,20,
130,2,55,4,0,
130,2,56,1,41,
130,2,57,4,0,
130,2,58,4,0,
130,2,59,4,0,
130,2,61,4,0,
130,2,63,1,52,
130,2,63,4,0,
130,2,70,4,0,
130,2,82,1,25,
130,2,82,4,0,
130,2,85,4,0,
130,2,87,4,0,
130,2,92,4,0,
130,2,99,4,0,
130,2,102,4,0,
130,2,104,4,0,
130,2,115,4,0,
130,2,117,4,0,
130,2,126,4,0,
130,2,130,4,0,
130,2,156,4,0,
130,2,164,4,0,
130,3,29,4,0,
130,3,37,1,1,
130,3,43,1,30,
130,3,44,1,20,
130,3,46,4,0,
130,3,56,1,40,
130,3,57,4,0,
130,3,59,4,0,
130,3,63,1,50,
130,3,63,4,0,
130,3,70,4,0,
130,3,82,1,25,
130,3,87,4,0,
130,3,92,4,0,
130,3,104,4,0,
130,3,126,4,0,
130,3,127,4,0,
130,3,156,4,0,
130,3,173,4,0,
130,3,174,4,0,
130,3,182,4,0,
130,3,192,4,0,
130,3,196,4,0,
130,3,201,4,0,
130,3,203,4,0,
130,3,207,4,0,
130,3,213,4,0,
130,3,214,4,0,
130,3,216,4,0,
130,3,218,4,0,
130,3,225,4,0,
130,3,237,4,0,
130,3,239,1,35,
130,3,240,1,45,
130,3,240,4,0,
130,3,249,4,0,
130,3,250,4,0,
130,4,29,4,0,
130,4,37,1,1,
130,4,43,1,30,
130,4,44,1,20,
130,4,46,4,0,
130,4,53,3,0,
130,4,56,1,40,
130,4,57,4,0,
130,4,58,3,0,
130,4,59,4,0,
130,4,63,1,50,
130,4,63,4,0,
130,4,70,4,0,
130,4,82,1,25,
130,4,85,3,0,
130,4,87,4,0,
130,4,92,4,0,
130,4,104,4,0,
130,4,126,4,0,
130,4,127,4,0,
130,4,156,4,0,
130,4,173,4,0,
130,4,174,4,0,
130,4,182,4,0,
130,4,192,4,0,
130,4,196,4,0,
130,4,201,4,0,
130,4,203,4,0,
130,4,207,4,0,
130,4,213,4,0,
130,4,214,4,0,
130,4,216,4,0,
130,4,218,4,0,
130,4,225,4,0,
130,4,237,4,0,
130,4,239,1,35,
130,4,240,1,45,
130,4,240,4,0,
130,4,249,4,0,
130,4,250,4,0,
130,5,37,1,1,
130,5,43,1,30,
130,5,44,1,20,
130,5,46,4,0,
130,5,53,4,0,
130,5,56,1,40,
130,5,57,4,0,
130,5,58,4,0,
130,5,59,4,0,
130,5,63,1,55,
130,5,63,4,0,
130,5,70,4,0,
130,5,82,1,25,
130,5,85,4,0,
130,5,87,4,0,
130,5,89,4,0,
130,5,92,4,0,
130,5,104,4,0,
130,5,126,4,0,
130,5,127,4,0,
130,5,156,4,0,
130,5,182,4,0,
130,5,201,4,0,
130,5,213,4,0,
130,5,216,4,0,
130,5,218,4,0,
130,5,237,4,0,
130,5,239,1,35,
130,5,240,1,45,
130,5,240,4,0,
130,5,249,4,0,
130,5,258,4,0,
130,5,259,4,0,
130,5,263,4,0,
130,5,269,4,0,
130,5,290,4,0,
130,5,291,4,0,
130,5,349,1,50,
130,5,352,4,0,
130,6,34,3,0,
130,6,37,1,1,
130,6,38,3,0,
130,6,43,1,30,
130,6,44,1,20,
130,6,46,4,0,
130,6,53,4,0,
130,6,56,1,40,
130,6,57,4,0,
130,6,58,4,0,
130,6,59,4,0,
130,6,63,1,55,
130,6,63,4,0,
130,6,70,4,0,
130,6,82,1,25,
130,6,85,4,0,
130,6,86,3,0,
130,6,87,4,0,
130,6,89,4,0,
130,6,92,4,0,
130,6,102,3,0,
130,6,104,4,0,
130,6,126,4,0,
130,6,127,4,0,
130,6,156,4,0,
130,6,164,3,0,
130,6,173,3,0,
130,6,182,4,0,
130,6,196,3,0,
130,6,201,4,0,
130,6,203,3,0,
130,6,207,3,0,
130,6,213,4,0,
130,6,214,3,0,
130,6,216,4,0,
130,6,218,4,0,
130,6,237,4,0,
130,6,239,1,35,
130,6,240,1,45,
130,6,240,4,0,
130,6,249,4,0,
130,6,258,4,0,
130,6,259,4,0,
130,6,263,4,0,
130,6,269,4,0,
130,6,290,4,0,
130,6,291,4,0,
130,6,349,1,50,
130,6,352,4,0,
130,7,34,3,0,
130,7,37,1,1,
130,7,38,3,0,
130,7,43,1,30,
130,7,44,1,20,
130,7,46,4,0,
130,7,53,4,0,
130,7,56,1,40,
130,7,57,4,0,
130,7,58,4,0,
130,7,59,4,0,
130,7,63,1,55,
130,7,63,4,0,
130,7,70,4,0,
130,7,82,1,25,
130,7,85,4,0,
130,7,86,3,0,
130,7,87,4,0,
130,7,89,4,0,
130,7,92,4,0,
130,7,102,3,0,
130,7,104,4,0,
130,7,126,4,0,
130,7,127,4,0,
130,7,156,4,0,
130,7,164,3,0,
130,7,182,4,0,
130,7,201,4,0,
130,7,213,4,0,
130,7,216,4,0,
130,7,218,4,0,
130,7,237,4,0,
130,7,239,1,35,
130,7,240,1,45,
130,7,240,4,0,
130,7,249,4,0,
130,7,258,4,0,
130,7,259,4,0,
130,7,263,4,0,
130,7,269,4,0,
130,7,290,4,0,
130,7,291,4,0,
130,7,349,1,50,
130,7,352,4,0,
130,8,37,1,1,
130,8,43,1,26,
130,8,44,1,20,
130,8,46,4,0,
130,8,53,4,0,
130,8,56,1,41,
130,8,57,4,0,
130,8,58,4,0,
130,8,59,4,0,
130,8,63,1,47,
130,8,63,4,0,
130,8,70,4,0,
130,8,82,1,23,
130,8,85,4,0,
130,8,86,4,0,
130,8,87,4,0,
130,8,89,4,0,
130,8,92,4,0,
130,8,104,4,0,
130,8,126,4,0,
130,8,127,4,0,
130,8,156,4,0,
130,8,164,4,0,
130,8,182,4,0,
130,8,201,4,0,
130,8,203,4,0,
130,8,207,4,0,
130,8,213,4,0,
130,8,214,4,0,
130,8,216,4,0,
130,8,218,4,0,
130,8,237,4,0,
130,8,239,1,29,
130,8,240,1,38,
130,8,240,4,0,
130,8,249,4,0,
130,8,258,4,0,
130,8,259,4,0,
130,8,263,4,0,
130,8,269,4,0,
130,8,290,4,0,
130,8,349,1,44,
130,8,352,4,0,
130,8,362,4,0,
130,8,363,4,0,
130,8,371,4,0,
130,8,399,4,0,
130,8,401,1,35,
130,8,406,4,0,
130,8,416,4,0,
130,8,419,4,0,
130,8,423,1,32,
130,8,444,4,0,
130,8,445,4,0,
130,9,37,1,1,
130,9,43,1,26,
130,9,44,1,20,
130,9,46,4,0,
130,9,53,4,0,
130,9,56,1,41,
130,9,57,4,0,
130,9,58,4,0,
130,9,59,4,0,
130,9,63,1,47,
130,9,63,4,0,
130,9,70,4,0,
130,9,82,1,23,
130,9,85,4,0,
130,9,86,4,0,
130,9,87,4,0,
130,9,89,4,0,
130,9,92,4,0,
130,9,104,4,0,
130,9,126,4,0,
130,9,127,4,0,
130,9,156,4,0,
130,9,164,4,0,
130,9,173,3,0,
130,9,180,3,0,
130,9,182,4,0,
130,9,196,3,0,
130,9,200,3,0,
130,9,201,4,0,
130,9,203,4,0,
130,9,207,4,0,
130,9,213,4,0,
130,9,214,4,0,
130,9,216,4,0,
130,9,218,4,0,
130,9,237,4,0,
130,9,239,1,29,
130,9,239,3,0,
130,9,240,1,38,
130,9,240,4,0,
130,9,249,4,0,
130,9,253,3,0,
130,9,258,4,0,
130,9,259,4,0,
130,9,263,4,0,
130,9,269,4,0,
130,9,290,4,0,
130,9,291,3,0,
130,9,340,3,0,
130,9,349,1,44,
130,9,352,4,0,
130,9,362,4,0,
130,9,363,4,0,
130,9,371,4,0,
130,9,399,4,0,
130,9,401,1,35,
130,9,401,3,0,
130,9,406,4,0,
130,9,416,4,0,
130,9,419,4,0,
130,9,423,1,32,
130,9,442,3,0,
130,9,444,4,0,
130,9,445,4,0,
130,10,29,3,0,
130,10,37,1,1,
130,10,43,1,26,
130,10,44,1,20,
130,10,46,4,0,
130,10,53,4,0,
130,10,56,1,41,
130,10,57,4,0,
130,10,58,4,0,
130,10,59,4,0,
130,10,63,1,47,
130,10,63,4,0,
130,10,70,4,0,
130,10,82,1,23,
130,10,85,4,0,
130,10,86,4,0,
130,10,87,4,0,
130,10,89,4,0,
130,10,92,4,0,
130,10,104,4,0,
130,10,126,4,0,
130,10,127,4,0,
130,10,156,4,0,
130,10,164,4,0,
130,10,173,3,0,
130,10,180,3,0,
130,10,182,4,0,
130,10,196,3,0,
130,10,200,3,0,
130,10,201,4,0,
130,10,203,4,0,
130,10,207,4,0,
130,10,213,4,0,
130,10,214,4,0,
130,10,216,4,0,
130,10,218,4,0,
130,10,237,4,0,
130,10,239,1,29,
130,10,239,3,0,
130,10,240,1,38,
130,10,240,4,0,
130,10,249,4,0,
130,10,250,4,0,
130,10,253,3,0,
130,10,258,4,0,
130,10,259,4,0,
130,10,263,4,0,
130,10,269,4,0,
130,10,290,4,0,
130,10,291,3,0,
130,10,340,3,0,
130,10,349,1,44,
130,10,352,4,0,
130,10,362,4,0,
130,10,363,4,0,
130,10,371,4,0,
130,10,399,4,0,
130,10,401,1,35,
130,10,401,3,0,
130,10,406,4,0,
130,10,416,4,0,
130,10,419,4,0,
130,10,423,1,32,
130,10,442,3,0,
130,10,444,4,0,
130,10,445,4,0,
130,11,37,1,1,
130,11,43,1,26,
130,11,44,1,20,
130,11,46,4,0,
130,11,53,4,0,
130,11,56,1,41,
130,11,57,4,0,
130,11,58,4,0,
130,11,59,4,0,
130,11,63,1,47,
130,11,63,4,0,
130,11,70,4,0,
130,11,82,1,23,
130,11,85,4,0,
130,11,86,4,0,
130,11,87,4,0,
130,11,89,4,0,
130,11,92,4,0,
130,11,104,4,0,
130,11,126,4,0,
130,11,127,4,0,
130,11,156,4,0,
130,11,164,4,0,
130,11,182,4,0,
130,11,201,4,0,
130,11,207,4,0,
130,11,213,4,0,
130,11,216,4,0,
130,11,218,4,0,
130,11,237,4,0,
130,11,239,1,29,
130,11,240,1,38,
130,11,240,4,0,
130,11,249,4,0,
130,11,258,4,0,
130,11,259,4,0,
130,11,263,4,0,
130,11,269,4,0,
130,11,291,4,0,
130,11,349,1,44,
130,11,371,4,0,
130,11,401,1,35,
130,11,416,4,0,
130,11,423,1,32,
130,11,444,4,0,
130,11,496,4,0,
130,11,503,4,0,
130,11,510,4,0,
130,11,523,4,0,
130,11,525,4,0,
130,12,37,1,1,
130,12,43,1,30,
130,12,44,1,20,
130,12,46,4,0,
130,12,53,4,0,
130,12,56,1,40,
130,12,57,4,0,
130,12,58,4,0,
130,12,59,4,0,
130,12,63,1,55,
130,12,63,4,0,
130,12,70,4,0,
130,12,82,1,25,
130,12,85,4,0,
130,12,87,4,0,
130,12,89,4,0,
130,12,92,4,0,
130,12,104,4,0,
130,12,126,4,0,
130,12,127,4,0,
130,12,156,4,0,
130,12,182,4,0,
130,12,201,4,0,
130,12,213,4,0,
130,12,216,4,0,
130,12,218,4,0,
130,12,237,4,0,
130,12,239,1,35,
130,12,240,1,45,
130,12,240,4,0,
130,12,249,4,0,
130,12,258,4,0,
130,12,259,4,0,
130,12,263,4,0,
130,12,269,4,0,
130,12,290,4,0,
130,12,291,4,0,
130,12,349,1,50,
130,12,352,4,0,
130,13,34,3,0,
130,13,37,1,1,
130,13,38,3,0,
130,13,43,1,30,
130,13,44,1,20,
130,13,46,4,0,
130,13,53,4,0,
130,13,56,1,40,
130,13,57,4,0,
130,13,58,4,0,
130,13,59,4,0,
130,13,63,1,55,
130,13,63,4,0,
130,13,70,4,0,
130,13,82,1,25,
130,13,85,4,0,
130,13,86,3,0,
130,13,87,4,0,
130,13,89,4,0,
130,13,92,4,0,
130,13,102,3,0,
130,13,104,4,0,
130,13,126,4,0,
130,13,127,4,0,
130,13,156,4,0,
130,13,164,3,0,
130,13,182,4,0,
130,13,196,3,0,
130,13,201,4,0,
130,13,207,3,0,
130,13,213,4,0,
130,13,216,4,0,
130,13,218,4,0,
130,13,237,4,0,
130,13,239,1,35,
130,13,240,1,45,
130,13,240,4,0,
130,13,249,4,0,
130,13,258,4,0,
130,13,259,4,0,
130,13,263,4,0,
130,13,269,4,0,
130,13,290,4,0,
130,13,291,4,0,
130,13,349,1,50,
130,13,352,4,0,
130,14,37,1,1,
130,14,43,1,26,
130,14,44,1,20,
130,14,46,4,0,
130,14,53,4,0,
130,14,56,1,41,
130,14,57,4,0,
130,14,58,4,0,
130,14,59,4,0,
130,14,63,1,47,
130,14,63,4,0,
130,14,70,4,0,
130,14,82,1,23,
130,14,85,4,0,
130,14,86,4,0,
130,14,87,4,0,
130,14,89,4,0,
130,14,92,4,0,
130,14,104,4,0,
130,14,126,4,0,
130,14,127,4,0,
130,14,156,4,0,
130,14,164,4,0,
130,14,173,3,0,
130,14,180,3,0,
130,14,182,4,0,
130,14,196,3,0,
130,14,200,3,0,
130,14,201,4,0,
130,14,207,4,0,
130,14,213,4,0,
130,14,214,3,0,
130,14,216,4,0,
130,14,218,4,0,
130,14,231,3,0,
130,14,237,4,0,
130,14,239,1,29,
130,14,240,1,38,
130,14,240,4,0,
130,14,249,4,0,
130,14,253,3,0,
130,14,258,4,0,
130,14,259,4,0,
130,14,263,4,0,
130,14,269,4,0,
130,14,291,4,0,
130,14,340,3,0,
130,14,349,1,44,
130,14,371,4,0,
130,14,399,3,0,
130,14,401,1,35,
130,14,401,3,0,
130,14,406,3,0,
130,14,416,4,0,
130,14,423,1,32,
130,14,442,3,0,
130,14,444,4,0,
130,14,496,4,0,
130,14,503,4,0,
130,14,510,4,0,
130,14,523,4,0,
130,14,525,4,0,
130,15,37,1,1,
130,15,43,1,26,
130,15,44,1,20,
130,15,46,4,0,
130,15,53,4,0,
130,15,56,1,41,
130,15,57,4,0,
130,15,58,4,0,
130,15,59,4,0,
130,15,63,1,47,
130,15,63,4,0,
130,15,70,4,0,
130,15,82,1,23,
130,15,85,4,0,
130,15,86,4,0,
130,15,87,4,0,
130,15,89,4,0,
130,15,92,4,0,
130,15,104,4,0,
130,15,126,4,0,
130,15,127,4,0,
130,15,156,4,0,
130,15,164,4,0,
130,15,182,4,0,
130,15,201,4,0,
130,15,207,4,0,
130,15,213,4,0,
130,15,214,4,0,
130,15,216,4,0,
130,15,218,4,0,
130,15,237,4,0,
130,15,239,1,29,
130,15,240,1,38,
130,15,240,4,0,
130,15,249,4,0,
130,15,258,4,0,
130,15,259,4,0,
130,15,263,4,0,
130,15,269,4,0,
130,15,349,1,44,
130,15,371,4,0,
130,15,399,4,0,
130,15,401,1,35,
130,15,416,4,0,
130,15,423,1,32,
130,15,444,4,0,
130,15,496,4,0,
130,15,503,4,0,
130,15,510,4,0,
130,15,523,4,0,
130,15,525,4,0,
130,15,590,4,0,
130,16,37,1,1,1
130,16,43,1,26,1
130,16,44,1,20,1
130,16,46,4,0,
130,16,53,4,0,
130,16,56,1,44,1
130,16,57,4,0,
130,16,58,4,0,
130,16,59,4,0,
130,16,63,1,50,1
130,16,63,4,0,
130,16,70,4,0,
130,16,82,1,23,1
130,16,85,4,0,
130,16,86,4,0,
130,16,87,4,0,
130,16,89,4,0,
130,16,92,4,0,
130,16,104,4,0,
130,16,126,4,0,
130,16,127,4,0,
130,16,156,4,0,
130,16,164,4,0,
130,16,173,3,0,
130,16,180,3,0,
130,16,182,4,0,
130,16,196,3,0,
130,16,200,3,0,
130,16,201,4,0,
130,16,207,4,0,
130,16,213,4,0,
130,16,214,4,0,
130,16,216,4,0,
130,16,218,4,0,
130,16,231,3,0,
130,16,237,4,0,
130,16,239,1,29,1
130,16,240,1,38,1
130,16,240,4,0,
130,16,242,1,41,1
130,16,249,4,0,
130,16,253,3,0,
130,16,258,4,0,
130,16,259,4,0,
130,16,263,4,0,
130,16,269,4,0,
130,16,290,4,0,
130,16,291,4,0,
130,16,340,3,0,
130,16,349,1,47,1
130,16,352,3,0,
130,16,371,4,0,
130,16,399,4,0,
130,16,401,1,35,1
130,16,401,3,0,
130,16,406,3,0,
130,16,416,4,0,
130,16,423,1,32,1
130,16,442,3,0,
130,16,444,4,0,
130,16,496,4,0,
130,16,503,4,0,
130,16,510,4,0,
130,16,523,4,0,
130,16,525,4,0,
130,16,590,4,0,
130,17,37,1,1,2
130,17,43,1,21,
130,17,44,1,0,
130,17,44,1,1,1
130,17,46,4,0,
130,17,53,4,0,
130,17,56,1,42,
130,17,57,4,0,
130,17,58,4,0,
130,17,59,4,0,
130,17,63,1,54,
130,17,63,4,0,
130,17,82,1,36,
130,17,85,4,0,
130,17,86,4,0,
130,17,87,4,0,
130,17,89,4,0,
130,17,92,4,0,
130,17,104,4,0,
130,17,126,4,0,
130,17,127,4,0,
130,17,156,4,0,
130,17,164,4,0,
130,17,182,4,0,
130,17,184,1,33,
130,17,201,4,0,
130,17,207,4,0,
130,17,213,4,0,
130,17,214,4,0,
130,17,216,4,0,
130,17,218,4,0,
130,17,237,4,0,
130,17,239,1,24,
130,17,240,1,51,
130,17,240,4,0,
130,17,242,1,39,
130,17,258,4,0,
130,17,259,4,0,
130,17,263,4,0,
130,17,269,4,0,
130,17,349,1,45,
130,17,371,4,0,
130,17,399,4,0,
130,17,401,1,30,
130,17,416,4,0,
130,17,423,1,27,
130,17,444,4,0,
130,17,496,4,0,
130,17,503,4,0,
130,17,523,4,0,
130,17,525,4,0,
130,17,542,1,48,
130,17,590,4,0,
130,17,693,4,0,
130,18,37,1,1,2
130,18,43,1,21,
130,18,44,1,0,
130,18,44,1,1,1
130,18,46,4,0,
130,18,53,4,0,
130,18,56,1,42,
130,18,57,4,0,
130,18,58,4,0,
130,18,59,4,0,
130,18,63,1,54,
130,18,63,4,0,
130,18,82,1,36,
130,18,85,4,0,
130,18,86,4,0,
130,18,87,4,0,
130,18,89,4,0,
130,18,92,4,0,
130,18,104,4,0,
130,18,126,4,0,
130,18,127,4,0,
130,18,156,4,0,
130,18,164,4,0,
130,18,182,4,0,
130,18,184,1,33,
130,18,201,4,0,
130,18,207,4,0,
130,18,213,4,0,
130,18,214,4,0,
130,18,216,4,0,
130,18,218,4,0,
130,18,237,4,0,
130,18,239,1,24,
130,18,240,1,51,
130,18,240,4,0,
130,18,242,1,39,
130,18,258,4,0,
130,18,259,4,0,
130,18,263,4,0,
130,18,269,4,0,
130,18,349,1,45,
130,18,371,4,0,
130,18,399,4,0,
130,18,401,1,30,
130,18,416,4,0,
130,18,423,1,27,
130,18,444,4,0,
130,18,496,4,0,
130,18,503,4,0,
130,18,523,4,0,
130,18,525,4,0,
130,18,542,1,48,
130,18,590,4,0,
130,18,693,4,0,
131,1,32,4,0,
131,1,34,1,25,
131,1,34,4,0,
131,1,36,4,0,
131,1,38,4,0,
131,1,45,1,1,2
131,1,47,1,16,
131,1,54,1,20,
131,1,55,1,1,1
131,1,55,4,0,
131,1,56,1,46,
131,1,57,4,0,
131,1,58,1,38,
131,1,58,4,0,
131,1,59,4,0,
131,1,61,4,0,
131,1,63,4,0,
131,1,70,4,0,
131,1,76,4,0,
131,1,82,4,0,
131,1,85,4,0,
131,1,87,4,0,
131,1,92,4,0,
131,1,94,4,0,
131,1,99,4,0,
131,1,102,4,0,
131,1,104,4,0,
131,1,109,1,31,
131,1,115,4,0,
131,1,117,4,0,
131,1,130,4,0,
131,1,149,4,0,
131,1,156,4,0,
131,1,164,4,0,
131,2,32,4,0,
131,2,34,1,25,
131,2,34,4,0,
131,2,36,4,0,
131,2,38,4,0,
131,2,45,1,1,2
131,2,47,1,16,
131,2,54,1,20,
131,2,55,1,1,1
131,2,55,4,0,
131,2,56,1,46,
131,2,57,4,0,
131,2,58,1,38,
131,2,58,4,0,
131,2,59,4,0,
131,2,61,4,0,
131,2,63,4,0,
131,2,70,4,0,
131,2,76,4,0,
131,2,82,4,0,
131,2,85,4,0,
131,2,87,4,0,
131,2,92,4,0,
131,2,94,4,0,
131,2,99,4,0,
131,2,102,4,0,
131,2,104,4,0,
131,2,109,1,31,
131,2,115,4,0,
131,2,117,4,0,
131,2,130,4,0,
131,2,149,4,0,
131,2,156,4,0,
131,2,164,4,0,
131,3,29,4,0,
131,3,34,1,15,
131,3,45,1,1,2
131,3,47,1,1,3
131,3,54,1,8,
131,3,55,1,1,1
131,3,56,1,57,
131,3,57,4,0,
131,3,58,1,36,
131,3,59,4,0,
131,3,62,2,0,
131,3,63,4,0,
131,3,70,4,0,
131,3,87,4,0,
131,3,92,4,0,
131,3,94,4,0,
131,3,104,4,0,
131,3,109,1,22,
131,3,138,4,0,
131,3,156,4,0,
131,3,171,4,0,
131,3,173,4,0,
131,3,174,4,0,
131,3,182,4,0,
131,3,192,4,0,
131,3,193,2,0,
131,3,195,1,29,
131,3,196,4,0,
131,3,203,4,0,
131,3,207,4,0,
131,3,213,4,0,
131,3,214,4,0,
131,3,216,4,0,
131,3,218,4,0,
131,3,219,1,50,
131,3,225,4,0,
131,3,231,4,0,
131,3,237,4,0,
131,3,240,1,43,
131,3,240,4,0,
131,3,249,4,0,
131,3,250,4,0,
131,4,29,4,0,
131,4,34,1,15,
131,4,45,1,1,2
131,4,47,1,1,3
131,4,54,1,8,
131,4,55,1,1,1
131,4,56,1,57,
131,4,57,4,0,
131,4,58,1,36,
131,4,58,3,0,
131,4,59,4,0,
131,4,62,2,0,
131,4,63,4,0,
131,4,70,4,0,
131,4,85,3,0,
131,4,87,4,0,
131,4,92,4,0,
131,4,94,4,0,
131,4,104,4,0,
131,4,109,1,22,
131,4,138,4,0,
131,4,156,4,0,
131,4,171,4,0,
131,4,173,4,0,
131,4,174,4,0,
131,4,182,4,0,
131,4,192,4,0,
131,4,193,2,0,
131,4,195,1,29,
131,4,196,4,0,
131,4,203,4,0,
131,4,207,4,0,
131,4,213,4,0,
131,4,214,4,0,
131,4,216,4,0,
131,4,218,4,0,
131,4,219,1,50,
131,4,225,4,0,
131,4,231,4,0,
131,4,237,4,0,
131,4,240,1,43,
131,4,240,4,0,
131,4,249,4,0,
131,4,250,4,0,
131,5,32,2,0,
131,5,34,1,13,
131,5,45,1,1,2
131,5,46,4,0,
131,5,47,1,1,3
131,5,54,1,7,
131,5,55,1,1,1
131,5,56,1,49,
131,5,57,4,0,
131,5,58,1,31,
131,5,58,4,0,
131,5,59,4,0,
131,5,63,4,0,
131,5,70,4,0,
131,5,85,4,0,
131,5,87,4,0,
131,5,92,4,0,
131,5,94,4,0,
131,5,104,4,0,
131,5,109,1,19,
131,5,127,4,0,
131,5,156,4,0,
131,5,164,2,0,
131,5,174,2,0,
131,5,182,4,0,
131,5,193,2,0,
131,5,195,1,25,
131,5,213,4,0,
131,5,214,2,0,
131,5,216,4,0,
131,5,218,4,0,
131,5,219,1,43,
131,5,219,4,0,
131,5,231,4,0,
131,5,237,4,0,
131,5,240,1,37,
131,5,240,4,0,
131,5,249,4,0,
131,5,258,4,0,
131,5,263,4,0,
131,5,287,2,0,
131,5,290,4,0,
131,5,291,4,0,
131,5,321,2,0,
131,5,329,1,55,
131,5,349,2,0,
131,5,351,4,0,
131,5,352,4,0,
131,6,32,2,0,
131,6,34,1,13,
131,6,34,3,0,
131,6,38,3,0,
131,6,45,1,1,2
131,6,46,4,0,
131,6,47,1,1,3
131,6,54,1,7,
131,6,55,1,1,1
131,6,56,1,49,
131,6,57,4,0,
131,6,58,1,31,
131,6,58,4,0,
131,6,59,4,0,
131,6,63,4,0,
131,6,70,4,0,
131,6,85,4,0,
131,6,87,4,0,
131,6,92,4,0,
131,6,94,4,0,
131,6,102,3,0,
131,6,104,4,0,
131,6,109,1,19,
131,6,127,4,0,
131,6,138,3,0,
131,6,156,4,0,
131,6,164,2,0,
131,6,164,3,0,
131,6,173,3,0,
131,6,174,2,0,
131,6,182,4,0,
131,6,193,2,0,
131,6,195,1,25,
131,6,196,3,0,
131,6,203,3,0,
131,6,207,3,0,
131,6,213,4,0,
131,6,214,2,0,
131,6,214,3,0,
131,6,216,4,0,
131,6,218,4,0,
131,6,219,1,43,
131,6,219,4,0,
131,6,231,4,0,
131,6,237,4,0,
131,6,240,1,37,
131,6,240,4,0,
131,6,249,4,0,
131,6,258,4,0,
131,6,263,4,0,
131,6,287,2,0,
131,6,290,4,0,
131,6,291,4,0,
131,6,321,2,0,
131,6,329,1,55,
131,6,349,2,0,
131,6,351,4,0,
131,6,352,4,0,
131,7,32,2,0,
131,7,34,1,13,
131,7,34,3,0,
131,7,38,3,0,
131,7,45,1,1,2
131,7,46,4,0,
131,7,47,1,1,3
131,7,54,1,7,
131,7,55,1,1,1
131,7,56,1,49,
131,7,57,4,0,
131,7,58,1,31,
131,7,58,4,0,
131,7,59,4,0,
131,7,63,4,0,
131,7,70,4,0,
131,7,85,4,0,
131,7,87,4,0,
131,7,92,4,0,
131,7,94,4,0,
131,7,102,3,0,
131,7,104,4,0,
131,7,109,1,19,
131,7,127,4,0,
131,7,138,3,0,
131,7,156,4,0,
131,7,164,2,0,
131,7,164,3,0,
131,7,174,2,0,
131,7,182,4,0,
131,7,193,2,0,
131,7,195,1,25,
131,7,213,4,0,
131,7,214,2,0,
131,7,216,4,0,
131,7,218,4,0,
131,7,219,1,43,
131,7,219,4,0,
131,7,231,4,0,
131,7,237,4,0,
131,7,240,1,37,
131,7,240,4,0,
131,7,249,4,0,
131,7,258,4,0,
131,7,263,4,0,
131,7,287,2,0,
131,7,290,4,0,
131,7,291,4,0,
131,7,321,2,0,
131,7,329,1,55,
131,7,349,2,0,
131,7,351,4,0,
131,7,352,4,0,
131,8,32,2,0,
131,8,34,1,18,
131,8,45,1,1,2
131,8,46,4,0,
131,8,47,1,1,1
131,8,54,1,4,
131,8,55,1,1,3
131,8,56,1,49,
131,8,57,4,0,
131,8,58,1,32,
131,8,58,4,0,
131,8,59,4,0,
131,8,63,4,0,
131,8,70,4,0,
131,8,85,4,0,
131,8,87,4,0,
131,8,90,2,0,
131,8,92,4,0,
131,8,94,4,0,
131,8,104,4,0,
131,8,109,1,7,
131,8,127,4,0,
131,8,138,4,0,
131,8,156,4,0,
131,8,164,2,0,
131,8,164,4,0,
131,8,174,2,0,
131,8,182,4,0,
131,8,193,2,0,
131,8,195,1,27,
131,8,203,4,0,
131,8,207,4,0,
131,8,213,4,0,
131,8,214,2,0,
131,8,214,4,0,
131,8,216,4,0,
131,8,218,4,0,
131,8,219,1,43,
131,8,219,4,0,
131,8,231,4,0,
131,8,237,4,0,
131,8,240,1,22,
131,8,240,4,0,
131,8,246,2,0,
131,8,249,4,0,
131,8,250,2,0,
131,8,258,4,0,
131,8,263,4,0,
131,8,287,2,0,
131,8,290,4,0,
131,8,321,2,0,
131,8,329,1,55,
131,8,349,2,0,
131,8,351,4,0,
131,8,352,1,14,
131,8,352,4,0,
131,8,362,1,37,
131,8,362,4,0,
131,8,363,4,0,
131,8,406,4,0,
131,8,416,4,0,
131,8,419,4,0,
131,8,420,1,10,
131,8,445,4,0,
131,9,32,2,0,
131,9,34,1,18,
131,9,45,1,1,2
131,9,46,4,0,
131,9,47,1,1,1
131,9,54,1,4,
131,9,55,1,1,3
131,9,56,1,49,
131,9,57,4,0,
131,9,58,1,32,
131,9,58,4,0,
131,9,59,4,0,
131,9,63,4,0,
131,9,70,4,0,
131,9,85,4,0,
131,9,87,4,0,
131,9,90,2,0,
131,9,92,4,0,
131,9,94,4,0,
131,9,104,4,0,
131,9,109,1,7,
131,9,127,4,0,
131,9,138,4,0,
131,9,156,4,0,
131,9,164,2,0,
131,9,164,4,0,
131,9,173,3,0,
131,9,174,2,0,
131,9,182,4,0,
131,9,193,2,0,
131,9,195,1,27,
131,9,196,3,0,
131,9,200,3,0,
131,9,203,4,0,
131,9,207,4,0,
131,9,213,4,0,
131,9,214,2,0,
131,9,214,4,0,
131,9,216,4,0,
131,9,218,4,0,
131,9,219,1,43,
131,9,219,4,0,
131,9,231,4,0,
131,9,237,4,0,
131,9,240,1,22,
131,9,240,4,0,
131,9,246,2,0,
131,9,246,3,0,
131,9,249,4,0,
131,9,250,2,0,
131,9,258,4,0,
131,9,263,4,0,
131,9,287,2,0,
131,9,290,4,0,
131,9,291,3,0,
131,9,321,2,0,
131,9,324,3,0,
131,9,329,1,55,
131,9,349,2,0,
131,9,351,4,0,
131,9,352,1,14,
131,9,352,4,0,
131,9,362,1,37,
131,9,362,4,0,
131,9,363,4,0,
131,9,401,3,0,
131,9,406,4,0,
131,9,416,4,0,
131,9,419,4,0,
131,9,420,1,10,
131,9,428,3,0,
131,9,442,3,0,
131,9,445,4,0,
131,10,29,3,0,
131,10,32,2,0,
131,10,34,1,18,
131,10,45,1,1,2
131,10,46,4,0,
131,10,47,1,1,1
131,10,54,1,4,
131,10,55,1,1,3
131,10,56,1,49,
131,10,57,4,0,
131,10,58,1,32,
131,10,58,4,0,
131,10,59,4,0,
131,10,63,4,0,
131,10,70,4,0,
131,10,85,4,0,
131,10,87,4,0,
131,10,90,2,0,
131,10,92,4,0,
131,10,94,4,0,
131,10,104,4,0,
131,10,109,1,7,
131,10,127,4,0,
131,10,138,4,0,
131,10,156,4,0,
131,10,164,2,0,
131,10,164,4,0,
131,10,173,3,0,
131,10,174,2,0,
131,10,182,4,0,
131,10,193,2,0,
131,10,195,1,27,
131,10,196,3,0,
131,10,200,3,0,
131,10,203,4,0,
131,10,207,4,0,
131,10,213,4,0,
131,10,214,2,0,
131,10,214,4,0,
131,10,215,3,0,
131,10,216,4,0,
131,10,218,4,0,
131,10,219,1,43,
131,10,219,4,0,
131,10,231,4,0,
131,10,237,4,0,
131,10,240,1,22,
131,10,240,4,0,
131,10,246,2,0,
131,10,246,3,0,
131,10,249,4,0,
131,10,250,2,0,
131,10,250,4,0,
131,10,258,4,0,
131,10,263,4,0,
131,10,287,2,0,
131,10,290,4,0,
131,10,291,3,0,
131,10,321,2,0,
131,10,324,3,0,
131,10,329,1,55,
131,10,335,3,0,
131,10,349,2,0,
131,10,351,4,0,
131,10,352,1,14,
131,10,352,4,0,
131,10,362,1,37,
131,10,362,4,0,
131,10,363,4,0,
131,10,401,3,0,
131,10,406,4,0,
131,10,416,4,0,
131,10,419,4,0,
131,10,420,1,10,
131,10,428,3,0,
131,10,442,3,0,
131,10,445,4,0,
131,11,32,2,0,
131,11,34,1,18,
131,11,45,1,1,2
131,11,46,4,0,
131,11,47,1,1,1
131,11,54,1,4,
131,11,55,1,1,3
131,11,56,1,49,
131,11,57,4,0,
131,11,58,1,32,
131,11,58,4,0,
131,11,59,4,0,
131,11,63,4,0,
131,11,70,4,0,
131,11,85,4,0,
131,11,87,4,0,
131,11,90,2,0,
131,11,92,4,0,
131,11,94,4,0,
131,11,104,4,0,
131,11,109,1,7,
131,11,127,4,0,
131,11,138,4,0,
131,11,156,4,0,
131,11,164,4,0,
131,11,174,2,0,
131,11,182,4,0,
131,11,193,2,0,
131,11,195,1,27,
131,11,207,4,0,
131,11,213,4,0,
131,11,214,2,0,
131,11,216,4,0,
131,11,218,4,0,
131,11,219,1,43,
131,11,219,4,0,
131,11,237,4,0,
131,11,240,1,22,
131,11,240,4,0,
131,11,246,2,0,
131,11,248,2,0,
131,11,249,4,0,
131,11,250,2,0,
131,11,258,4,0,
131,11,263,4,0,
131,11,287,2,0,
131,11,291,4,0,
131,11,321,2,0,
131,11,329,1,55,
131,11,349,2,0,
131,11,352,1,14,
131,11,362,1,37,
131,11,406,2,0,
131,11,416,4,0,
131,11,419,2,0,
131,11,420,1,10,
131,11,496,4,0,
131,11,497,4,0,
131,11,523,4,0,
131,11,524,4,0,
131,12,34,1,13,
131,12,45,1,1,2
131,12,46,4,0,
131,12,47,1,1,3
131,12,54,1,7,
131,12,55,1,1,1
131,12,56,1,49,
131,12,57,4,0,
131,12,58,1,31,
131,12,58,4,0,
131,12,59,4,0,
131,12,63,4,0,
131,12,70,4,0,
131,12,85,4,0,
131,12,87,4,0,
131,12,92,4,0,
131,12,94,4,0,
131,12,104,4,0,
131,12,109,1,19,
131,12,127,4,0,
131,12,156,4,0,
131,12,182,4,0,
131,12,195,1,25,
131,12,213,4,0,
131,12,216,4,0,
131,12,218,4,0,
131,12,219,1,43,
131,12,219,4,0,
131,12,231,4,0,
131,12,237,4,0,
131,12,240,1,37,
131,12,240,4,0,
131,12,249,4,0,
131,12,258,4,0,
131,12,263,4,0,
131,12,290,4,0,
131,12,291,4,0,
131,12,329,1,55,
131,12,351,4,0,
131,12,352,4,0,
131,13,34,1,13,
131,13,34,3,0,
131,13,38,3,0,
131,13,45,1,1,2
131,13,46,4,0,
131,13,47,1,1,3
131,13,54,1,7,
131,13,55,1,1,1
131,13,56,1,49,
131,13,57,4,0,
131,13,58,1,31,
131,13,58,4,0,
131,13,59,4,0,
131,13,63,4,0,
131,13,70,4,0,
131,13,85,4,0,
131,13,87,4,0,
131,13,92,4,0,
131,13,94,4,0,
131,13,102,3,0,
131,13,104,4,0,
131,13,109,1,19,
131,13,127,4,0,
131,13,138,3,0,
131,13,156,4,0,
131,13,164,3,0,
131,13,171,3,0,
131,13,182,4,0,
131,13,195,1,25,
131,13,196,3,0,
131,13,207,3,0,
131,13,213,4,0,
131,13,216,4,0,
131,13,218,4,0,
131,13,219,1,43,
131,13,219,4,0,
131,13,231,4,0,
131,13,237,4,0,
131,13,240,1,37,
131,13,240,4,0,
131,13,249,4,0,
131,13,258,4,0,
131,13,263,4,0,
131,13,290,4,0,
131,13,291,4,0,
131,13,329,1,55,
131,13,351,4,0,
131,13,352,4,0,
131,14,32,2,0,
131,14,34,1,18,
131,14,45,1,1,2
131,14,46,4,0,
131,14,47,1,1,1
131,14,54,1,4,
131,14,55,1,1,3
131,14,56,1,49,
131,14,57,4,0,
131,14,58,1,32,
131,14,58,4,0,
131,14,59,4,0,
131,14,63,4,0,
131,14,70,4,0,
131,14,85,4,0,
131,14,87,4,0,
131,14,90,2,0,
131,14,92,4,0,
131,14,94,4,0,
131,14,104,4,0,
131,14,109,1,7,
131,14,127,4,0,
131,14,138,4,0,
131,14,156,4,0,
131,14,164,4,0,
131,14,173,3,0,
131,14,174,2,0,
131,14,182,4,0,
131,14,193,2,0,
131,14,195,1,27,
131,14,196,3,0,
131,14,200,3,0,
131,14,207,4,0,
131,14,213,4,0,
131,14,214,2,0,
131,14,214,3,0,
131,14,215,3,0,
131,14,216,4,0,
131,14,218,4,0,
131,14,219,1,43,
131,14,219,4,0,
131,14,231,3,0,
131,14,237,4,0,
131,14,240,1,22,
131,14,240,4,0,
131,14,246,2,0,
131,14,248,2,0,
131,14,249,4,0,
131,14,250,2,0,
131,14,258,4,0,
131,14,263,4,0,
131,14,287,2,0,
131,14,291,4,0,
131,14,304,3,0,
131,14,321,2,0,
131,14,324,3,0,
131,14,329,1,55,
131,14,335,3,0,
131,14,349,2,0,
131,14,352,1,14,
131,14,362,1,37,
131,14,401,3,0,
131,14,406,2,0,
131,14,406,3,0,
131,14,416,4,0,
131,14,419,2,0,
131,14,420,1,10,
131,14,428,3,0,
131,14,442,3,0,
131,14,496,4,0,
131,14,497,4,0,
131,14,523,4,0,
131,14,524,4,0,
131,14,529,3,0,
131,15,32,2,0,
131,15,34,1,18,
131,15,45,1,1,2
131,15,46,4,0,
131,15,47,1,1,1
131,15,54,1,4,
131,15,55,1,1,3
131,15,56,1,47,
131,15,57,4,0,
131,15,58,1,32,
131,15,58,4,0,
131,15,59,4,0,
131,15,63,4,0,
131,15,70,4,0,
131,15,85,4,0,
131,15,87,4,0,
131,15,90,2,0,
131,15,92,4,0,
131,15,94,4,0,
131,15,104,4,0,
131,15,109,1,7,
131,15,127,4,0,
131,15,138,4,0,
131,15,156,4,0,
131,15,164,4,0,
131,15,174,2,0,
131,15,182,4,0,
131,15,193,2,0,
131,15,195,1,27,
131,15,207,4,0,
131,15,213,4,0,
131,15,214,2,0,
131,15,214,4,0,
131,15,216,4,0,
131,15,218,4,0,
131,15,219,1,43,
131,15,219,4,0,
131,15,237,4,0,
131,15,240,1,22,
131,15,240,4,0,
131,15,246,2,0,
131,15,248,2,0,
131,15,249,4,0,
131,15,250,2,0,
131,15,258,4,0,
131,15,263,4,0,
131,15,287,2,0,
131,15,321,2,0,
131,15,329,1,50,
131,15,349,2,0,
131,15,352,1,14,
131,15,362,1,37,
131,15,406,2,0,
131,15,416,4,0,
131,15,419,2,0,
131,15,420,1,10,
131,15,496,4,0,
131,15,497,4,0,
131,15,523,4,0,
131,15,524,4,0,
131,15,573,2,0,
131,15,590,4,0,
131,16,32,2,0,
131,16,34,1,18,1
131,16,45,1,1,2
131,16,46,4,0,
131,16,47,1,1,1
131,16,54,1,4,1
131,16,55,1,1,3
131,16,56,1,47,1
131,16,57,4,0,
131,16,58,1,32,1
131,16,58,4,0,
131,16,59,4,0,
131,16,63,4,0,
131,16,70,4,0,
131,16,85,4,0,
131,16,87,4,0,
131,16,90,2,0,
131,16,92,4,0,
131,16,94,4,0,
131,16,104,4,0,
131,16,109,1,7,1
131,16,127,4,0,
131,16,138,4,0,
131,16,156,4,0,
131,16,164,4,0,
131,16,173,3,0,
131,16,174,2,0,
131,16,182,4,0,
131,16,193,2,0,
131,16,195,1,27,1
131,16,196,3,0,
131,16,200,3,0,
131,16,207,4,0,
131,16,213,4,0,
131,16,214,2,0,
131,16,214,4,0,
131,16,215,3,0,
131,16,216,4,0,
131,16,218,4,0,
131,16,219,1,43,1
131,16,219,4,0,
131,16,231,3,0,
131,16,237,4,0,
131,16,240,1,22,1
131,16,240,4,0,
131,16,246,2,0,
131,16,248,2,0,
131,16,249,4,0,
131,16,250,2,0,
131,16,258,4,0,
131,16,263,4,0,
131,16,287,2,0,
131,16,290,4,0,
131,16,291,4,0,
131,16,304,3,0,
131,16,321,2,0,
131,16,324,3,0,
131,16,329,1,50,1
131,16,335,3,0,
131,16,349,2,0,
131,16,351,3,0,
131,16,352,1,14,1
131,16,352,3,0,
131,16,362,1,37,1
131,16,401,3,0,
131,16,406,2,0,
131,16,406,3,0,
131,16,416,4,0,
131,16,419,2,0,
131,16,420,1,10,1
131,16,428,3,0,
131,16,442,3,0,
131,16,496,4,0,
131,16,497,4,0,
131,16,523,4,0,
131,16,524,4,0,
131,16,529,3,0,
131,16,573,2,0,
131,16,590,4,0,
131,17,32,2,0,
131,17,34,1,18,
131,17,45,1,1,2
131,17,46,4,0,
131,17,47,1,1,1
131,17,54,1,4,
131,17,55,1,1,3
131,17,56,1,47,
131,17,57,4,0,
131,17,58,1,32,
131,17,58,4,0,
131,17,59,4,0,
131,17,63,4,0,
131,17,85,4,0,
131,17,87,4,0,
131,17,90,2,0,
131,17,92,4,0,
131,17,94,4,0,
131,17,104,4,0,
131,17,109,1,7,
131,17,127,4,0,
131,17,138,4,0,
131,17,156,4,0,
131,17,164,4,0,
131,17,174,2,0,
131,17,182,4,0,
131,17,193,2,0,
131,17,195,1,27,
131,17,207,4,0,
131,17,213,4,0,
131,17,214,2,0,
131,17,214,4,0,
131,17,216,4,0,
131,17,218,4,0,
131,17,219,1,43,
131,17,219,4,0,
131,17,237,4,0,
131,17,240,1,22,
131,17,240,4,0,
131,17,246,2,0,
131,17,248,2,0,
131,17,250,2,0,
131,17,258,4,0,
131,17,263,4,0,
131,17,287,2,0,
131,17,321,2,0,
131,17,329,1,50,
131,17,349,2,0,
131,17,352,1,14,
131,17,362,1,37,
131,17,406,2,0,
131,17,416,4,0,
131,17,419,2,0,
131,17,420,1,10,
131,17,496,4,0,
131,17,497,4,0,
131,17,523,4,0,
131,17,524,4,0,
131,17,573,2,0,
131,17,590,4,0,
131,17,684,4,0,
131,18,32,2,0,
131,18,34,1,18,
131,18,45,1,1,2
131,18,46,4,0,
131,18,47,1,1,1
131,18,54,1,4,
131,18,55,1,1,3
131,18,56,1,47,
131,18,57,4,0,
131,18,58,1,32,
131,18,58,4,0,
131,18,59,4,0,
131,18,63,4,0,
131,18,85,4,0,
131,18,87,4,0,
131,18,90,2,0,
131,18,92,4,0,
131,18,94,4,0,
131,18,104,4,0,
131,18,109,1,7,
131,18,127,4,0,
131,18,138,4,0,
131,18,156,4,0,
131,18,164,4,0,
131,18,174,2,0,
131,18,182,4,0,
131,18,193,2,0,
131,18,195,1,27,
131,18,207,4,0,
131,18,213,4,0,
131,18,214,2,0,
131,18,214,4,0,
131,18,216,4,0,
131,18,218,4,0,
131,18,219,1,43,
131,18,219,4,0,
131,18,237,4,0,
131,18,240,1,22,
131,18,240,4,0,
131,18,246,2,0,
131,18,248,2,0,
131,18,250,2,0,
131,18,258,4,0,
131,18,263,4,0,
131,18,287,2,0,
131,18,321,2,0,
131,18,329,1,50,
131,18,349,2,0,
131,18,352,1,14,
131,18,362,1,37,
131,18,406,2,0,
131,18,416,4,0,
131,18,419,2,0,
131,18,420,1,10,
131,18,496,4,0,
131,18,497,4,0,
131,18,523,4,0,
131,18,524,4,0,
131,18,573,2,0,
131,18,590,4,0,
131,18,684,4,0,
132,1,144,1,1,
132,2,144,1,1,
132,3,144,1,1,
132,4,144,1,1,
132,5,144,1,1,
132,6,144,1,1,
132,7,144,1,1,
132,8,144,1,1,
132,9,144,1,1,
132,10,144,1,1,
132,11,144,1,1,
132,12,144,1,1,
132,13,144,1,1,
132,14,144,1,1,
132,15,144,1,1,
132,16,144,1,1,1
132,17,144,1,1,
132,18,144,1,1,
133,1,28,1,1,2
133,1,33,1,1,1
133,1,34,4,0,
133,1,36,1,45,
133,1,36,4,0,
133,1,38,4,0,
133,1,39,1,31,
133,1,44,1,37,
133,1,92,4,0,
133,1,98,1,27,
133,1,99,4,0,
133,1,102,4,0,
133,1,104,4,0,
133,1,115,4,0,
133,1,117,4,0,
133,1,129,4,0,
133,1,130,4,0,
133,1,156,4,0,
133,1,164,4,0,
133,2,28,1,8,
133,2,33,1,1,1
133,2,34,4,0,
133,2,36,1,42,
133,2,36,4,0,
133,2,38,4,0,
133,2,39,1,1,2
133,2,44,1,30,
133,2,45,1,16,
133,2,92,4,0,
133,2,98,1,23,
133,2,99,4,0,
133,2,102,4,0,
133,2,104,4,0,
133,2,115,4,0,
133,2,116,1,36,
133,2,117,4,0,
133,2,129,4,0,
133,2,130,4,0,
133,2,156,4,0,
133,2,164,4,0,
133,3,28,1,8,
133,3,29,4,0,
133,3,33,1,1,1
133,3,36,1,42,
133,3,39,1,1,2
133,3,44,1,30,
133,3,45,1,16,
133,3,92,4,0,
133,3,98,1,23,
133,3,104,4,0,
133,3,116,1,36,
133,3,129,4,0,
133,3,156,4,0,
133,3,173,4,0,
133,3,174,4,0,
133,3,175,2,0,
133,3,182,4,0,
133,3,189,4,0,
133,3,197,4,0,
133,3,203,4,0,
133,3,204,2,0,
133,3,207,4,0,
133,3,213,4,0,
133,3,214,4,0,
133,3,216,4,0,
133,3,218,4,0,
133,3,231,4,0,
133,3,237,4,0,
133,3,240,4,0,
133,3,241,4,0,
133,3,247,4,0,
133,4,28,1,8,
133,4,29,4,0,
133,4,33,1,1,1
133,4,36,1,42,
133,4,39,1,1,2
133,4,44,1,30,
133,4,45,1,16,
133,4,92,4,0,
133,4,98,1,23,
133,4,104,4,0,
133,4,129,4,0,
133,4,156,4,0,
133,4,173,4,0,
133,4,174,4,0,
133,4,175,2,0,
133,4,182,4,0,
133,4,189,4,0,
133,4,197,4,0,
133,4,203,4,0,
133,4,204,2,0,
133,4,207,4,0,
133,4,213,4,0,
133,4,214,4,0,
133,4,216,4,0,
133,4,218,4,0,
133,4,226,1,36,
133,4,231,4,0,
133,4,237,4,0,
133,4,240,4,0,
133,4,241,4,0,
133,4,247,4,0,
133,5,28,1,8,
133,5,33,1,1,1
133,5,36,1,42,
133,5,39,1,1,2
133,5,44,1,30,
133,5,45,1,16,
133,5,91,4,0,
133,5,92,4,0,
133,5,98,1,23,
133,5,104,4,0,
133,5,156,4,0,
133,5,174,2,0,
133,5,175,2,0,
133,5,182,4,0,
133,5,203,2,0,
133,5,204,2,0,
133,5,213,4,0,
133,5,216,4,0,
133,5,218,4,0,
133,5,226,1,36,
133,5,231,4,0,
133,5,237,4,0,
133,5,240,4,0,
133,5,241,4,0,
133,5,247,4,0,
133,5,263,4,0,
133,5,270,1,1,3
133,5,273,2,0,
133,5,290,4,0,
133,5,321,2,0,
133,6,28,1,8,
133,6,33,1,1,1
133,6,34,3,0,
133,6,36,1,42,
133,6,38,3,0,
133,6,39,1,1,2
133,6,44,1,30,
133,6,45,1,16,
133,6,91,4,0,
133,6,92,4,0,
133,6,98,1,23,
133,6,102,3,0,
133,6,104,4,0,
133,6,129,3,0,
133,6,156,4,0,
133,6,164,3,0,
133,6,173,3,0,
133,6,174,2,0,
133,6,175,2,0,
133,6,182,4,0,
133,6,189,3,0,
133,6,203,2,0,
133,6,203,3,0,
133,6,204,2,0,
133,6,207,3,0,
133,6,213,4,0,
133,6,214,3,0,
133,6,216,4,0,
133,6,218,4,0,
133,6,226,1,36,
133,6,231,4,0,
133,6,237,4,0,
133,6,240,4,0,
133,6,241,4,0,
133,6,247,4,0,
133,6,263,4,0,
133,6,270,1,1,3
133,6,273,2,0,
133,6,290,4,0,
133,6,321,2,0,
133,7,28,1,8,
133,7,33,1,1,1
133,7,34,3,0,
133,7,36,1,42,
133,7,38,3,0,
133,7,39,1,1,2
133,7,44,1,30,
133,7,45,1,16,
133,7,91,4,0,
133,7,92,4,0,
133,7,98,1,23,
133,7,102,3,0,
133,7,104,4,0,
133,7,156,4,0,
133,7,164,3,0,
133,7,174,2,0,
133,7,175,2,0,
133,7,182,4,0,
133,7,203,2,0,
133,7,204,2,0,
133,7,213,4,0,
133,7,216,4,0,
133,7,218,4,0,
133,7,226,1,36,
133,7,231,4,0,
133,7,237,4,0,
133,7,240,4,0,
133,7,241,4,0,
133,7,247,4,0,
133,7,263,4,0,
133,7,270,1,1,3
133,7,273,2,0,
133,7,290,4,0,
133,7,321,2,0,
133,8,28,1,8,
133,8,33,1,1,1
133,8,36,1,43,
133,8,39,1,1,2
133,8,44,1,29,
133,8,45,1,15,
133,8,91,4,0,
133,8,92,4,0,
133,8,98,1,22,
133,8,104,4,0,
133,8,156,4,0,
133,8,164,4,0,
133,8,174,2,0,
133,8,175,2,0,
133,8,182,4,0,
133,8,203,2,0,
133,8,203,4,0,
133,8,204,2,0,
133,8,207,4,0,
133,8,213,4,0,
133,8,214,4,0,
133,8,216,4,0,
133,8,218,4,0,
133,8,226,1,36,
133,8,231,4,0,
133,8,237,4,0,
133,8,240,4,0,
133,8,241,4,0,
133,8,247,4,0,
133,8,263,4,0,
133,8,270,1,1,3
133,8,273,2,0,
133,8,281,2,0,
133,8,290,4,0,
133,8,313,2,0,
133,8,321,2,0,
133,8,343,2,0,
133,8,363,4,0,
133,8,376,1,57,
133,8,387,1,50,
133,8,445,4,0,
133,9,28,1,8,
133,9,33,1,1,2
133,9,36,1,43,
133,9,39,1,1,1
133,9,44,1,29,
133,9,45,1,15,
133,9,91,4,0,
133,9,92,4,0,
133,9,98,1,22,
133,9,104,4,0,
133,9,129,3,0,
133,9,156,4,0,
133,9,164,4,0,
133,9,173,3,0,
133,9,174,2,0,
133,9,175,2,0,
133,9,182,4,0,
133,9,189,3,0,
133,9,203,2,0,
133,9,203,4,0,
133,9,204,2,0,
133,9,207,4,0,
133,9,213,4,0,
133,9,214,4,0,
133,9,216,4,0,
133,9,218,4,0,
133,9,226,1,36,
133,9,231,4,0,
133,9,237,4,0,
133,9,240,4,0,
133,9,241,4,0,
133,9,247,4,0,
133,9,263,4,0,
133,9,270,1,1,3
133,9,270,3,0,
133,9,273,2,0,
133,9,281,2,0,
133,9,290,4,0,
133,9,313,2,0,
133,9,321,2,0,
133,9,343,2,0,
133,9,363,4,0,
133,9,376,1,57,
133,9,387,1,50,
133,9,387,3,0,
133,9,445,4,0,
133,10,28,1,8,
133,10,29,3,0,
133,10,33,1,1,2
133,10,36,1,43,
133,10,39,1,1,1
133,10,44,1,29,
133,10,45,1,15,
133,10,91,4,0,
133,10,92,4,0,
133,10,98,1,22,
133,10,104,4,0,
133,10,129,3,0,
133,10,156,4,0,
133,10,164,4,0,
133,10,173,3,0,
133,10,174,2,0,
133,10,175,2,0,
133,10,182,4,0,
133,10,189,3,0,
133,10,197,2,0,
133,10,203,2,0,
133,10,203,4,0,
133,10,204,2,0,
133,10,207,4,0,
133,10,213,4,0,
133,10,214,4,0,
133,10,215,3,0,
133,10,216,4,0,
133,10,218,4,0,
133,10,226,1,36,
133,10,231,4,0,
133,10,237,4,0,
133,10,240,4,0,
133,10,241,4,0,
133,10,247,4,0,
133,10,263,4,0,
133,10,270,1,1,3
133,10,270,3,0,
133,10,273,2,0,
133,10,281,2,0,
133,10,290,4,0,
133,10,313,2,0,
133,10,321,2,0,
133,10,343,2,0,
133,10,363,4,0,
133,10,376,1,57,
133,10,387,1,50,
133,10,387,3,0,
133,10,445,4,0,
133,11,28,1,8,
133,11,33,1,1,2
133,11,36,1,43,
133,11,39,1,1,1
133,11,44,1,29,
133,11,45,1,15,
133,11,91,4,0,
133,11,92,4,0,
133,11,98,1,22,
133,11,104,4,0,
133,11,156,4,0,
133,11,164,4,0,
133,11,174,2,0,
133,11,175,2,0,
133,11,182,4,0,
133,11,197,2,0,
133,11,203,2,0,
133,11,204,2,0,
133,11,207,4,0,
133,11,213,4,0,
133,11,216,4,0,
133,11,218,4,0,
133,11,226,1,36,
133,11,237,4,0,
133,11,240,4,0,
133,11,241,4,0,
133,11,247,4,0,
133,11,263,4,0,
133,11,270,1,1,3
133,11,273,2,0,
133,11,281,2,0,
133,11,313,2,0,
133,11,321,2,0,
133,11,343,2,0,
133,11,363,2,0,
133,11,376,1,57,
133,11,387,1,50,
133,11,485,2,0,
133,11,496,4,0,
133,11,497,4,0,
133,11,500,2,0,
133,11,514,4,0,
133,11,526,4,0,
133,12,28,1,8,
133,12,33,1,1,1
133,12,36,1,42,
133,12,39,1,1,2
133,12,44,1,30,
133,12,45,1,16,
133,12,91,4,0,
133,12,92,4,0,
133,12,98,1,23,
133,12,104,4,0,
133,12,156,4,0,
133,12,182,4,0,
133,12,213,4,0,
133,12,216,4,0,
133,12,218,4,0,
133,12,226,1,36,
133,12,231,4,0,
133,12,237,4,0,
133,12,240,4,0,
133,12,241,4,0,
133,12,247,4,0,
133,12,263,4,0,
133,12,270,1,1,3
133,12,290,4,0,
133,13,28,1,8,
133,13,33,1,1,1
133,13,34,3,0,
133,13,36,1,42,
133,13,38,3,0,
133,13,39,1,1,2
133,13,44,1,30,
133,13,45,1,16,
133,13,91,4,0,
133,13,92,4,0,
133,13,98,1,23,
133,13,102,3,0,
133,13,104,4,0,
133,13,156,4,0,
133,13,164,3,0,
133,13,182,4,0,
133,13,207,3,0,
133,13,213,4,0,
133,13,216,4,0,
133,13,218,4,0,
133,13,226,1,36,
133,13,231,4,0,
133,13,237,4,0,
133,13,240,4,0,
133,13,241,4,0,
133,13,247,4,0,
133,13,263,4,0,
133,13,270,1,1,3
133,13,290,4,0,
133,14,28,1,5,
133,14,33,1,1,2
133,14,36,1,25,
133,14,38,1,37,
133,14,39,1,1,3
133,14,44,1,17,
133,14,45,1,9,
133,14,91,4,0,
133,14,92,4,0,
133,14,98,1,13,
133,14,104,4,0,
133,14,156,4,0,
133,14,164,4,0,
133,14,173,3,0,
133,14,174,2,0,
133,14,175,2,0,
133,14,182,4,0,
133,14,197,2,0,
133,14,203,2,0,
133,14,204,1,29,
133,14,204,2,0,
133,14,207,4,0,
133,14,213,4,0,
133,14,214,3,0,
133,14,215,3,0,
133,14,216,4,0,
133,14,218,4,0,
133,14,226,1,33,
133,14,231,3,0,
133,14,237,4,0,
133,14,240,4,0,
133,14,241,4,0,
133,14,247,4,0,
133,14,263,4,0,
133,14,270,1,1,1
133,14,270,3,0,
133,14,273,2,0,
133,14,281,2,0,
133,14,304,3,0,
133,14,313,2,0,
133,14,321,2,0,
133,14,343,1,21,
133,14,343,2,0,
133,14,343,3,0,
133,14,363,2,0,
133,14,376,1,45,
133,14,387,1,41,
133,14,387,3,0,
133,14,485,2,0,
133,14,496,4,0,
133,14,497,4,0,
133,14,500,2,0,
133,14,514,4,0,
133,14,526,4,0,
133,15,28,1,5,
133,15,33,1,1,3
133,15,36,1,25,
133,15,38,1,37,
133,15,39,1,1,4
133,15,44,1,17,
133,15,45,1,1,2
133,15,91,4,0,
133,15,92,4,0,
133,15,98,1,13,
133,15,104,4,0,
133,15,129,1,10,
133,15,156,4,0,
133,15,164,4,0,
133,15,174,2,0,
133,15,175,2,0,
133,15,182,4,0,
133,15,197,2,0,
133,15,203,2,0,
133,15,204,1,29,
133,15,204,2,0,
133,15,207,4,0,
133,15,213,4,0,
133,15,214,4,0,
133,15,216,4,0,
133,15,218,4,0,
133,15,226,1,33,
133,15,237,4,0,
133,15,240,4,0,
133,15,241,4,0,
133,15,247,4,0,
133,15,263,4,0,
133,15,270,1,1,1
133,15,273,2,0,
133,15,281,2,0,
133,15,287,1,20,
133,15,313,2,0,
133,15,321,2,0,
133,15,343,1,23,
133,15,343,2,0,
133,15,363,2,0,
133,15,376,1,45,
133,15,387,1,41,
133,15,445,2,0,
133,15,485,2,0,
133,15,496,4,0,
133,15,497,4,0,
133,15,500,2,0,
133,15,514,4,0,
133,15,590,4,0,
133,15,608,1,9,
133,16,28,1,5,1
133,16,33,1,1,3
133,16,36,1,25,1
133,16,38,1,37,1
133,16,39,1,1,4
133,16,44,1,17,1
133,16,45,1,1,2
133,16,91,4,0,
133,16,92,4,0,
133,16,98,1,13,1
133,16,104,4,0,
133,16,129,1,10,1
133,16,156,4,0,
133,16,164,4,0,
133,16,173,3,0,
133,16,174,2,0,
133,16,175,2,0,
133,16,182,4,0,
133,16,197,2,0,
133,16,203,2,0,
133,16,204,1,29,1
133,16,204,2,0,
133,16,207,4,0,
133,16,213,4,0,
133,16,214,4,0,
133,16,215,3,0,
133,16,216,4,0,
133,16,218,4,0,
133,16,226,1,33,1
133,16,231,3,0,
133,16,237,4,0,
133,16,240,4,0,
133,16,241,4,0,
133,16,247,4,0,
133,16,263,4,0,
133,16,270,1,1,1
133,16,270,3,0,
133,16,273,2,0,
133,16,281,2,0,
133,16,287,1,20,1
133,16,290,4,0,
133,16,304,3,0,
133,16,313,2,0,
133,16,321,2,0,
133,16,343,1,23,1
133,16,343,2,0,
133,16,343,3,0,
133,16,363,2,0,
133,16,376,1,45,1
133,16,387,1,41,1
133,16,387,3,0,
133,16,445,2,0,
133,16,485,2,0,
133,16,496,4,0,
133,16,497,4,0,
133,16,500,2,0,
133,16,514,4,0,
133,16,590,4,0,
133,16,608,1,9,1
133,17,28,1,5,
133,17,33,1,1,4
133,17,36,1,25,
133,17,38,1,37,
133,17,39,1,1,5
133,17,44,1,17,1
133,17,45,1,1,3
133,17,92,4,0,
133,17,98,1,13,
133,17,104,4,0,
133,17,129,1,17,2
133,17,156,4,0,
133,17,164,4,0,
133,17,174,2,0,
133,17,175,2,0,
133,17,182,4,0,
133,17,197,2,0,
133,17,203,2,0,
133,17,204,1,29,
133,17,204,2,0,
133,17,207,4,0,
133,17,213,4,0,
133,17,214,4,0,
133,17,216,4,0,
133,17,218,4,0,
133,17,226,1,33,
133,17,237,4,0,
133,17,240,4,0,
133,17,241,4,0,
133,17,247,4,0,
133,17,263,4,0,
133,17,270,1,1,2
133,17,273,2,0,
133,17,281,2,0,
133,17,287,1,20,
133,17,313,2,0,
133,17,321,2,0,
133,17,343,1,1,1
133,17,343,2,0,
133,17,363,2,0,
133,17,376,1,45,
133,17,387,1,41,
133,17,445,2,0,
133,17,485,2,0,
133,17,496,4,0,
133,17,497,4,0,
133,17,500,2,0,
133,17,526,4,0,
133,17,590,4,0,
133,17,608,1,9,
133,18,28,1,5,
133,18,33,1,1,4
133,18,36,1,25,
133,18,38,1,37,
133,18,39,1,1,5
133,18,44,1,17,1
133,18,45,1,1,3
133,18,92,4,0,
133,18,98,1,13,
133,18,104,4,0,
133,18,129,1,17,2
133,18,156,4,0,
133,18,164,4,0,
133,18,174,2,0,
133,18,175,2,0,
133,18,182,4,0,
133,18,197,2,0,
133,18,203,2,0,
133,18,204,1,29,
133,18,204,2,0,
133,18,207,4,0,
133,18,213,4,0,
133,18,214,4,0,
133,18,216,4,0,
133,18,218,4,0,
133,18,226,1,33,
133,18,237,4,0,
133,18,240,4,0,
133,18,241,4,0,
133,18,247,4,0,
133,18,263,4,0,
133,18,270,1,1,2
133,18,273,2,0,
133,18,281,2,0,
133,18,287,1,20,
133,18,313,2,0,
133,18,321,2,0,
133,18,343,1,1,1
133,18,343,2,0,
133,18,363,2,0,
133,18,376,1,45,
133,18,387,1,41,
133,18,445,2,0,
133,18,485,2,0,
133,18,496,4,0,
133,18,497,4,0,
133,18,500,2,0,
133,18,526,4,0,
133,18,590,4,0,
133,18,608,1,9,
134,1,28,1,1,2
134,1,33,1,1,1
134,1,34,4,0,
134,1,36,4,0,
134,1,38,4,0,
134,1,39,1,37,
134,1,44,1,40,
134,1,54,1,48,
134,1,55,1,1,4
134,1,55,1,31,
134,1,55,4,0,
134,1,56,1,54,
134,1,57,4,0,
134,1,58,4,0,
134,1,59,4,0,
134,1,61,4,0,
134,1,63,4,0,
134,1,92,4,0,
134,1,98,1,1,3
134,1,98,1,27,
134,1,99,4,0,
134,1,102,4,0,
134,1,104,4,0,
134,1,114,1,44,
134,1,115,4,0,
134,1,117,4,0,
134,1,129,4,0,
134,1,130,4,0,
134,1,151,1,42,
134,1,156,4,0,
134,1,164,4,0,
134,2,28,1,8,
134,2,33,1,1,1
134,2,34,4,0,
134,2,36,4,0,
134,2,38,4,0,
134,2,39,1,1,2
134,2,44,1,30,
134,2,54,1,42,2
134,2,55,1,1,4
134,2,55,1,16,
134,2,55,4,0,
134,2,56,1,52,
134,2,57,4,0,
134,2,58,4,0,
134,2,59,4,0,
134,2,61,4,0,
134,2,62,1,36,
134,2,63,4,0,
134,2,92,4,0,
134,2,98,1,1,3
134,2,98,1,23,
134,2,99,4,0,
134,2,102,4,0,
134,2,104,4,0,
134,2,114,1,42,1
134,2,115,4,0,
134,2,117,4,0,
134,2,129,4,0,
134,2,130,4,0,
134,2,151,1,47,
134,2,156,4,0,
134,2,164,4,0,
134,3,28,1,8,
134,3,29,4,0,
134,3,33,1,1,1
134,3,39,1,1,2
134,3,44,1,30,
134,3,46,4,0,
134,3,55,1,16,
134,3,56,1,52,
134,3,57,4,0,
134,3,59,4,0,
134,3,62,1,36,
134,3,63,4,0,
134,3,92,4,0,
134,3,98,1,23,
134,3,104,4,0,
134,3,114,1,42,
134,3,127,4,0,
134,3,129,4,0,
134,3,151,1,47,
134,3,156,4,0,
134,3,173,4,0,
134,3,174,4,0,
134,3,182,4,0,
134,3,189,4,0,
134,3,196,4,0,
134,3,197,4,0,
134,3,203,4,0,
134,3,207,4,0,
134,3,213,4,0,
134,3,214,4,0,
134,3,216,4,0,
134,3,218,4,0,
134,3,231,4,0,
134,3,237,4,0,
134,3,240,4,0,
134,3,241,4,0,
134,3,247,4,0,
134,3,250,4,0,
134,4,28,1,8,
134,4,29,4,0,
134,4,33,1,1,1
134,4,39,1,1,2
134,4,44,1,30,
134,4,46,4,0,
134,4,55,1,16,
134,4,56,1,52,
134,4,57,4,0,
134,4,58,3,0,
134,4,59,4,0,
134,4,62,1,36,
134,4,63,4,0,
134,4,92,4,0,
134,4,98,1,23,
134,4,104,4,0,
134,4,114,1,42,
134,4,127,4,0,
134,4,129,4,0,
134,4,151,1,47,
134,4,156,4,0,
134,4,173,4,0,
134,4,174,4,0,
134,4,182,4,0,
134,4,189,4,0,
134,4,196,4,0,
134,4,197,4,0,
134,4,203,4,0,
134,4,207,4,0,
134,4,213,4,0,
134,4,214,4,0,
134,4,216,4,0,
134,4,218,4,0,
134,4,231,4,0,
134,4,237,4,0,
134,4,240,4,0,
134,4,241,4,0,
134,4,247,4,0,
134,4,250,4,0,
134,5,28,1,8,
134,5,33,1,1,1
134,5,39,1,1,2
134,5,44,1,30,
134,5,46,4,0,
134,5,55,1,16,
134,5,56,1,52,
134,5,57,4,0,
134,5,58,4,0,
134,5,59,4,0,
134,5,62,1,36,
134,5,63,4,0,
134,5,91,4,0,
134,5,92,4,0,
134,5,98,1,23,
134,5,104,4,0,
134,5,114,1,42,
134,5,127,4,0,
134,5,151,1,47,
134,5,156,4,0,
134,5,182,4,0,
134,5,213,4,0,
134,5,216,4,0,
134,5,218,4,0,
134,5,231,4,0,
134,5,237,4,0,
134,5,240,4,0,
134,5,241,4,0,
134,5,247,4,0,
134,5,258,4,0,
134,5,263,4,0,
134,5,270,1,1,3
134,5,290,4,0,
134,5,291,4,0,
134,5,352,4,0,
134,6,28,1,8,
134,6,33,1,1,1
134,6,34,3,0,
134,6,38,3,0,
134,6,39,1,1,2
134,6,44,1,30,
134,6,46,4,0,
134,6,55,1,16,
134,6,56,1,52,
134,6,57,4,0,
134,6,58,4,0,
134,6,59,4,0,
134,6,62,1,36,
134,6,63,4,0,
134,6,91,4,0,
134,6,92,4,0,
134,6,98,1,23,
134,6,102,3,0,
134,6,104,4,0,
134,6,114,1,42,
134,6,127,4,0,
134,6,129,3,0,
134,6,151,1,47,
134,6,156,4,0,
134,6,164,3,0,
134,6,173,3,0,
134,6,182,4,0,
134,6,189,3,0,
134,6,196,3,0,
134,6,203,3,0,
134,6,207,3,0,
134,6,213,4,0,
134,6,214,3,0,
134,6,216,4,0,
134,6,218,4,0,
134,6,231,4,0,
134,6,237,4,0,
134,6,240,4,0,
134,6,241,4,0,
134,6,247,4,0,
134,6,258,4,0,
134,6,263,4,0,
134,6,270,1,1,3
134,6,290,4,0,
134,6,291,4,0,
134,6,352,4,0,
134,7,28,1,8,
134,7,33,1,1,1
134,7,34,3,0,
134,7,38,3,0,
134,7,39,1,1,2
134,7,44,1,30,
134,7,46,4,0,
134,7,55,1,16,
134,7,56,1,52,
134,7,57,4,0,
134,7,58,4,0,
134,7,59,4,0,
134,7,62,1,36,
134,7,63,4,0,
134,7,91,4,0,
134,7,92,4,0,
134,7,98,1,23,
134,7,102,3,0,
134,7,104,4,0,
134,7,114,1,42,
134,7,127,4,0,
134,7,151,1,47,
134,7,156,4,0,
134,7,164,3,0,
134,7,182,4,0,
134,7,213,4,0,
134,7,216,4,0,
134,7,218,4,0,
134,7,231,4,0,
134,7,237,4,0,
134,7,240,4,0,
134,7,241,4,0,
134,7,247,4,0,
134,7,258,4,0,
134,7,263,4,0,
134,7,270,1,1,3
134,7,290,4,0,
134,7,291,4,0,
134,7,352,4,0,
134,8,28,1,8,
134,8,33,1,1,1
134,8,39,1,1,2
134,8,44,1,29,
134,8,46,4,0,
134,8,55,1,15,
134,8,56,1,71,
134,8,57,4,0,
134,8,58,4,0,
134,8,59,4,0,
134,8,62,1,36,
134,8,63,4,0,
134,8,70,4,0,
134,8,91,4,0,
134,8,92,4,0,
134,8,98,1,22,
134,8,104,4,0,
134,8,114,1,57,
134,8,127,4,0,
134,8,151,1,64,
134,8,156,4,0,
134,8,164,4,0,
134,8,182,4,0,
134,8,203,4,0,
134,8,207,4,0,
134,8,213,4,0,
134,8,214,4,0,
134,8,216,4,0,
134,8,218,4,0,
134,8,231,4,0,
134,8,237,4,0,
134,8,240,4,0,
134,8,241,4,0,
134,8,247,4,0,
134,8,249,4,0,
134,8,258,4,0,
134,8,263,4,0,
134,8,270,1,1,3
134,8,290,4,0,
134,8,352,4,0,
134,8,362,4,0,
134,8,363,4,0,
134,8,387,1,50,
134,8,392,1,43,
134,8,416,4,0,
134,8,445,4,0,
134,9,28,1,8,
134,9,33,1,1,2
134,9,39,1,1,1
134,9,44,1,29,
134,9,46,4,0,
134,9,55,1,15,
134,9,56,1,71,
134,9,57,4,0,
134,9,58,4,0,
134,9,59,4,0,
134,9,62,1,36,
134,9,63,4,0,
134,9,70,4,0,
134,9,91,4,0,
134,9,92,4,0,
134,9,98,1,22,
134,9,104,4,0,
134,9,114,1,57,
134,9,127,4,0,
134,9,129,3,0,
134,9,151,1,64,
134,9,156,4,0,
134,9,164,4,0,
134,9,173,3,0,
134,9,182,4,0,
134,9,189,3,0,
134,9,196,3,0,
134,9,203,4,0,
134,9,207,4,0,
134,9,213,4,0,
134,9,214,4,0,
134,9,216,4,0,
134,9,218,4,0,
134,9,231,4,0,
134,9,237,4,0,
134,9,240,4,0,
134,9,241,4,0,
134,9,247,4,0,
134,9,249,4,0,
134,9,258,4,0,
134,9,263,4,0,
134,9,270,1,1,3
134,9,270,3,0,
134,9,290,4,0,
134,9,291,3,0,
134,9,324,3,0,
134,9,330,1,78,
134,9,352,4,0,
134,9,362,4,0,
134,9,363,4,0,
134,9,387,1,50,
134,9,387,3,0,
134,9,392,1,43,
134,9,401,3,0,
134,9,416,4,0,
134,9,445,4,0,
134,10,28,1,8,
134,10,29,3,0,
134,10,33,1,1,2
134,10,39,1,1,1
134,10,44,1,29,
134,10,46,4,0,
134,10,55,1,15,
134,10,56,1,71,
134,10,57,4,0,
134,10,58,4,0,
134,10,59,4,0,
134,10,62,1,36,
134,10,63,4,0,
134,10,70,4,0,
134,10,91,4,0,
134,10,92,4,0,
134,10,98,1,22,
134,10,104,4,0,
134,10,114,1,57,
134,10,127,4,0,
134,10,129,3,0,
134,10,151,1,64,
134,10,156,4,0,
134,10,164,4,0,
134,10,173,3,0,
134,10,182,4,0,
134,10,189,3,0,
134,10,196,3,0,
134,10,203,4,0,
134,10,207,4,0,
134,10,213,4,0,
134,10,214,4,0,
134,10,215,3,0,
134,10,216,4,0,
134,10,218,4,0,
134,10,231,4,0,
134,10,237,4,0,
134,10,240,4,0,
134,10,241,4,0,
134,10,247,4,0,
134,10,249,4,0,
134,10,250,4,0,
134,10,258,4,0,
134,10,263,4,0,
134,10,270,1,1,3
134,10,270,3,0,
134,10,290,4,0,
134,10,291,3,0,
134,10,324,3,0,
134,10,330,1,78,
134,10,352,4,0,
134,10,362,4,0,
134,10,363,4,0,
134,10,387,1,50,
134,10,387,3,0,
134,10,392,1,43,
134,10,401,3,0,
134,10,416,4,0,
134,10,445,4,0,
134,11,28,1,8,
134,11,33,1,1,2
134,11,39,1,1,1
134,11,44,1,29,
134,11,46,4,0,
134,11,55,1,15,
134,11,56,1,71,
134,11,57,4,0,
134,11,58,4,0,
134,11,59,4,0,
134,11,62,1,36,
134,11,63,4,0,
134,11,70,4,0,
134,11,91,4,0,
134,11,92,4,0,
134,11,98,1,22,
134,11,104,4,0,
134,11,114,1,57,
134,11,127,4,0,
134,11,151,1,64,
134,11,156,4,0,
134,11,164,4,0,
134,11,182,4,0,
134,11,207,4,0,
134,11,213,4,0,
134,11,216,4,0,
134,11,218,4,0,
134,11,237,4,0,
134,11,240,4,0,
134,11,241,4,0,
134,11,247,4,0,
134,11,249,4,0,
134,11,258,4,0,
134,11,263,4,0,
134,11,270,1,1,3
134,11,291,4,0,
134,11,330,1,78,
134,11,387,1,50,
134,11,392,1,43,
134,11,416,4,0,
134,11,496,4,0,
134,11,497,4,0,
134,11,503,4,0,
134,11,514,4,0,
134,11,526,4,0,
134,12,28,1,8,
134,12,33,1,1,1
134,12,39,1,1,2
134,12,44,1,30,
134,12,46,4,0,
134,12,55,1,16,
134,12,56,1,52,
134,12,57,4,0,
134,12,58,4,0,
134,12,59,4,0,
134,12,62,1,36,
134,12,63,4,0,
134,12,91,4,0,
134,12,92,4,0,
134,12,98,1,23,
134,12,104,4,0,
134,12,114,1,42,
134,12,127,4,0,
134,12,151,1,47,
134,12,156,4,0,
134,12,182,4,0,
134,12,213,4,0,
134,12,216,4,0,
134,12,218,4,0,
134,12,231,4,0,
134,12,237,4,0,
134,12,240,4,0,
134,12,241,4,0,
134,12,247,4,0,
134,12,258,4,0,
134,12,263,4,0,
134,12,270,1,1,3
134,12,290,4,0,
134,12,291,4,0,
134,12,352,4,0,
134,13,28,1,8,
134,13,33,1,1,1
134,13,34,3,0,
134,13,38,3,0,
134,13,39,1,1,2
134,13,44,1,30,
134,13,46,4,0,
134,13,55,1,16,
134,13,56,1,52,
134,13,57,4,0,
134,13,58,4,0,
134,13,59,4,0,
134,13,62,1,36,
134,13,63,4,0,
134,13,91,4,0,
134,13,92,4,0,
134,13,98,1,23,
134,13,102,3,0,
134,13,104,4,0,
134,13,114,1,42,
134,13,127,4,0,
134,13,151,1,47,
134,13,156,4,0,
134,13,164,3,0,
134,13,182,4,0,
134,13,196,3,0,
134,13,207,3,0,
134,13,213,4,0,
134,13,216,4,0,
134,13,218,4,0,
134,13,231,4,0,
134,13,237,4,0,
134,13,240,4,0,
134,13,241,4,0,
134,13,247,4,0,
134,13,258,4,0,
134,13,263,4,0,
134,13,270,1,1,3
134,13,290,4,0,
134,13,291,4,0,
134,13,352,4,0,
134,14,28,1,5,
134,14,33,1,1,2
134,14,39,1,1,3
134,14,46,4,0,
134,14,55,1,9,
134,14,56,1,45,
134,14,57,4,0,
134,14,58,4,0,
134,14,59,4,0,
134,14,62,1,21,
134,14,63,4,0,
134,14,70,4,0,
134,14,91,4,0,
134,14,92,4,0,
134,14,98,1,13,
134,14,104,4,0,
134,14,114,1,33,
134,14,127,4,0,
134,14,151,1,29,
134,14,156,4,0,
134,14,164,4,0,
134,14,173,3,0,
134,14,182,4,0,
134,14,196,3,0,
134,14,207,4,0,
134,14,213,4,0,
134,14,214,3,0,
134,14,215,3,0,
134,14,216,4,0,
134,14,218,4,0,
134,14,231,3,0,
134,14,237,4,0,
134,14,240,4,0,
134,14,241,4,0,
134,14,247,4,0,
134,14,249,4,0,
134,14,258,4,0,
134,14,263,4,0,
134,14,270,1,1,1
134,14,270,3,0,
134,14,291,4,0,
134,14,304,3,0,
134,14,324,3,0,
134,14,330,1,37,
134,14,343,3,0,
134,14,352,1,17,
134,14,387,1,41,
134,14,387,3,0,
134,14,392,1,25,
134,14,401,3,0,
134,14,416,4,0,
134,14,496,4,0,
134,14,497,4,0,
134,14,503,4,0,
134,14,514,4,0,
134,14,526,4,0,
134,15,28,1,5,
134,15,33,1,1,2
134,15,39,1,1,3
134,15,46,4,0,
134,15,55,1,9,
134,15,56,1,45,
134,15,57,4,0,
134,15,58,4,0,
134,15,59,4,0,
134,15,62,1,20,
134,15,63,4,0,
134,15,70,4,0,
134,15,91,4,0,
134,15,92,4,0,
134,15,98,1,13,
134,15,104,4,0,
134,15,114,1,33,
134,15,127,4,0,
134,15,151,1,29,
134,15,156,4,0,
134,15,164,4,0,
134,15,182,4,0,
134,15,207,4,0,
134,15,213,4,0,
134,15,214,4,0,
134,15,216,4,0,
134,15,218,4,0,
134,15,237,4,0,
134,15,240,4,0,
134,15,241,4,0,
134,15,247,4,0,
134,15,249,4,0,
134,15,258,4,0,
134,15,263,4,0,
134,15,270,1,1,1
134,15,330,1,37,
134,15,352,1,17,
134,15,387,1,41,
134,15,392,1,25,
134,15,416,4,0,
134,15,496,4,0,
134,15,497,4,0,
134,15,503,4,0,
134,15,514,4,0,
134,15,590,4,0,
134,16,28,1,5,1
134,16,33,1,1,2
134,16,39,1,1,3
134,16,46,4,0,
134,16,55,1,9,1
134,16,56,1,45,1
134,16,57,4,0,
134,16,58,4,0,
134,16,59,4,0,
134,16,62,1,20,1
134,16,63,4,0,
134,16,70,4,0,
134,16,91,4,0,
134,16,92,4,0,
134,16,98,1,13,1
134,16,104,4,0,
134,16,114,1,33,1
134,16,127,4,0,
134,16,151,1,29,1
134,16,156,4,0,
134,16,164,4,0,
134,16,173,3,0,
134,16,182,4,0,
134,16,196,3,0,
134,16,207,4,0,
134,16,213,4,0,
134,16,214,4,0,
134,16,215,3,0,
134,16,216,4,0,
134,16,218,4,0,
134,16,231,3,0,
134,16,237,4,0,
134,16,240,4,0,
134,16,241,4,0,
134,16,247,4,0,
134,16,249,4,0,
134,16,258,4,0,
134,16,263,4,0,
134,16,270,1,1,1
134,16,270,3,0,
134,16,290,4,0,
134,16,291,4,0,
134,16,304,3,0,
134,16,324,3,0,
134,16,330,1,37,1
134,16,343,3,0,
134,16,352,1,17,1
134,16,352,3,0,
134,16,387,1,41,1
134,16,387,3,0,
134,16,392,1,25,1
134,16,401,3,0,
134,16,416,4,0,
134,16,496,4,0,
134,16,497,4,0,
134,16,503,4,0,
134,16,514,4,0,
134,16,590,4,0,
134,17,28,1,5,
134,17,33,1,1,3
134,17,39,1,1,4
134,17,46,4,0,
134,17,55,1,0,
134,17,55,1,1,1
134,17,56,1,45,
134,17,57,4,0,
134,17,58,4,0,
134,17,59,4,0,
134,17,62,1,20,
134,17,63,4,0,
134,17,92,4,0,
134,17,98,1,13,
134,17,104,4,0,
134,17,114,1,33,
134,17,127,4,0,
134,17,151,1,29,
134,17,156,4,0,
134,17,164,4,0,
134,17,182,4,0,
134,17,207,4,0,
134,17,213,4,0,
134,17,214,4,0,
134,17,216,4,0,
134,17,218,4,0,
134,17,237,4,0,
134,17,240,4,0,
134,17,241,4,0,
134,17,247,4,0,
134,17,258,4,0,
134,17,263,4,0,
134,17,270,1,1,2
134,17,330,1,37,
134,17,352,1,17,
134,17,387,1,41,
134,17,392,1,25,
134,17,416,4,0,
134,17,496,4,0,
134,17,497,4,0,
134,17,503,4,0,
134,17,526,4,0,
134,17,590,4,0,
134,17,608,1,9,
134,18,28,1,5,
134,18,33,1,1,3
134,18,39,1,1,4
134,18,46,4,0,
134,18,55,1,0,
134,18,55,1,1,1
134,18,56,1,45,
134,18,57,4,0,
134,18,58,4,0,
134,18,59,4,0,
134,18,62,1,20,
134,18,63,4,0,
134,18,92,4,0,
134,18,98,1,13,
134,18,104,4,0,
134,18,114,1,33,
134,18,127,4,0,
134,18,151,1,29,
134,18,156,4,0,
134,18,164,4,0,
134,18,182,4,0,
134,18,207,4,0,
134,18,213,4,0,
134,18,214,4,0,
134,18,216,4,0,
134,18,218,4,0,
134,18,237,4,0,
134,18,240,4,0,
134,18,241,4,0,
134,18,247,4,0,
134,18,258,4,0,
134,18,263,4,0,
134,18,270,1,1,2
134,18,330,1,37,
134,18,352,1,17,
134,18,387,1,41,
134,18,392,1,25,
134,18,416,4,0,
134,18,496,4,0,
134,18,497,4,0,
134,18,503,4,0,
134,18,526,4,0,
134,18,590,4,0,
134,18,608,1,9,
135,1,24,1,42,
135,1,28,1,1,2
135,1,33,1,1,1
135,1,34,4,0,
135,1,36,4,0,
135,1,38,4,0,
135,1,39,1,37,
135,1,42,1,48,
135,1,63,4,0,
135,1,84,1,1,4
135,1,84,1,31,
135,1,85,4,0,
135,1,86,1,40,
135,1,86,4,0,
135,1,87,1,54,
135,1,87,4,0,
135,1,92,4,0,
135,1,97,1,44,
135,1,98,1,1,3
135,1,98,1,27,
135,1,99,4,0,
135,1,102,4,0,
135,1,104,4,0,
135,1,115,4,0,
135,1,117,4,0,
135,1,129,4,0,
135,1,130,4,0,
135,1,148,4,0,
135,1,156,4,0,
135,1,164,4,0,
135,2,24,1,30,
135,2,28,1,8,
135,2,33,1,1,1
135,2,34,4,0,
135,2,36,4,0,
135,2,38,4,0,
135,2,39,1,1,2
135,2,42,1,36,
135,2,63,4,0,
135,2,84,1,1,4
135,2,84,1,16,
135,2,85,4,0,
135,2,86,1,42,
135,2,86,4,0,
135,2,87,1,52,
135,2,87,4,0,
135,2,92,4,0,
135,2,97,1,47,
135,2,98,1,1,3
135,2,98,1,23,
135,2,99,4,0,
135,2,102,4,0,
135,2,104,4,0,
135,2,115,4,0,
135,2,117,4,0,
135,2,129,4,0,
135,2,130,4,0,
135,2,148,4,0,
135,2,156,4,0,
135,2,164,4,0,
135,3,24,1,30,
135,3,28,1,8,
135,3,29,4,0,
135,3,33,1,1,1
135,3,39,1,1,2
135,3,42,1,36,
135,3,46,4,0,
135,3,63,4,0,
135,3,84,1,16,
135,3,86,1,42,
135,3,87,1,52,
135,3,87,4,0,
135,3,92,4,0,
135,3,97,1,47,
135,3,98,1,23,
135,3,104,4,0,
135,3,129,4,0,
135,3,148,4,0,
135,3,156,4,0,
135,3,173,4,0,
135,3,174,4,0,
135,3,182,4,0,
135,3,189,4,0,
135,3,192,4,0,
135,3,197,4,0,
135,3,203,4,0,
135,3,207,4,0,
135,3,213,4,0,
135,3,214,4,0,
135,3,216,4,0,
135,3,218,4,0,
135,3,231,4,0,
135,3,237,4,0,
135,3,240,4,0,
135,3,241,4,0,
135,3,247,4,0,
135,4,24,1,30,
135,4,28,1,8,
135,4,29,4,0,
135,4,33,1,1,1
135,4,39,1,1,2
135,4,42,1,36,
135,4,46,4,0,
135,4,63,4,0,
135,4,84,1,16,
135,4,85,3,0,
135,4,86,1,42,
135,4,87,1,52,
135,4,87,4,0,
135,4,92,4,0,
135,4,97,1,47,
135,4,98,1,23,
135,4,104,4,0,
135,4,129,4,0,
135,4,148,4,0,
135,4,156,4,0,
135,4,173,4,0,
135,4,174,4,0,
135,4,182,4,0,
135,4,189,4,0,
135,4,192,4,0,
135,4,197,4,0,
135,4,203,4,0,
135,4,207,4,0,
135,4,213,4,0,
135,4,214,4,0,
135,4,216,4,0,
135,4,218,4,0,
135,4,231,4,0,
135,4,237,4,0,
135,4,240,4,0,
135,4,241,4,0,
135,4,247,4,0,
135,5,24,1,30,
135,5,28,1,8,
135,5,33,1,1,1
135,5,39,1,1,2
135,5,42,1,36,
135,5,46,4,0,
135,5,63,4,0,
135,5,84,1,16,
135,5,85,4,0,
135,5,86,1,42,
135,5,87,1,52,
135,5,87,4,0,
135,5,91,4,0,
135,5,92,4,0,
135,5,97,1,47,
135,5,98,1,23,
135,5,104,4,0,
135,5,148,4,0,
135,5,156,4,0,
135,5,182,4,0,
135,5,213,4,0,
135,5,216,4,0,
135,5,218,4,0,
135,5,231,4,0,
135,5,237,4,0,
135,5,240,4,0,
135,5,241,4,0,
135,5,247,4,0,
135,5,263,4,0,
135,5,270,1,1,3
135,5,290,4,0,
135,5,351,4,0,
135,6,24,1,30,
135,6,28,1,8,
135,6,33,1,1,1
135,6,34,3,0,
135,6,38,3,0,
135,6,39,1,1,2
135,6,42,1,36,
135,6,46,4,0,
135,6,63,4,0,
135,6,84,1,16,
135,6,85,4,0,
135,6,86,1,42,
135,6,86,3,0,
135,6,87,1,52,
135,6,87,4,0,
135,6,91,4,0,
135,6,92,4,0,
135,6,97,1,47,
135,6,98,1,23,
135,6,102,3,0,
135,6,104,4,0,
135,6,129,3,0,
135,6,148,4,0,
135,6,156,4,0,
135,6,164,3,0,
135,6,173,3,0,
135,6,182,4,0,
135,6,189,3,0,
135,6,203,3,0,
135,6,207,3,0,
135,6,213,4,0,
135,6,214,3,0,
135,6,216,4,0,
135,6,218,4,0,
135,6,231,4,0,
135,6,237,4,0,
135,6,240,4,0,
135,6,241,4,0,
135,6,247,4,0,
135,6,263,4,0,
135,6,270,1,1,3
135,6,290,4,0,
135,6,351,4,0,
135,7,24,1,30,
135,7,28,1,8,
135,7,33,1,1,1
135,7,34,3,0,
135,7,38,3,0,
135,7,39,1,1,2
135,7,42,1,36,
135,7,46,4,0,
135,7,63,4,0,
135,7,84,1,16,
135,7,85,4,0,
135,7,86,1,42,
135,7,86,3,0,
135,7,87,1,52,
135,7,87,4,0,
135,7,91,4,0,
135,7,92,4,0,
135,7,97,1,47,
135,7,98,1,23,
135,7,102,3,0,
135,7,104,4,0,
135,7,148,4,0,
135,7,156,4,0,
135,7,164,3,0,
135,7,182,4,0,
135,7,213,4,0,
135,7,216,4,0,
135,7,218,4,0,
135,7,231,4,0,
135,7,237,4,0,
135,7,240,4,0,
135,7,241,4,0,
135,7,247,4,0,
135,7,263,4,0,
135,7,270,1,1,3
135,7,290,4,0,
135,7,351,4,0,
135,8,24,1,29,
135,8,28,1,8,
135,8,33,1,1,1
135,8,39,1,1,2
135,8,42,1,36,
135,8,46,4,0,
135,8,63,4,0,
135,8,70,4,0,
135,8,84,1,15,
135,8,85,4,0,
135,8,86,1,57,
135,8,86,4,0,
135,8,87,1,71,
135,8,87,4,0,
135,8,91,4,0,
135,8,92,4,0,
135,8,97,1,64,
135,8,98,1,22,
135,8,104,4,0,
135,8,113,4,0,
135,8,148,4,0,
135,8,156,4,0,
135,8,164,4,0,
135,8,182,4,0,
135,8,203,4,0,
135,8,207,4,0,
135,8,213,4,0,
135,8,214,4,0,
135,8,216,4,0,
135,8,218,4,0,
135,8,231,4,0,
135,8,237,4,0,
135,8,240,4,0,
135,8,241,4,0,
135,8,247,4,0,
135,8,249,4,0,
135,8,263,4,0,
135,8,270,1,1,3
135,8,290,4,0,
135,8,351,4,0,
135,8,363,4,0,
135,8,387,1,50,
135,8,416,4,0,
135,8,422,1,43,
135,8,445,4,0,
135,8,451,4,0,
135,9,24,1,29,
135,9,28,1,8,
135,9,33,1,1,2
135,9,39,1,1,1
135,9,42,1,36,
135,9,46,4,0,
135,9,63,4,0,
135,9,70,4,0,
135,9,84,1,15,
135,9,85,4,0,
135,9,86,1,57,
135,9,86,4,0,
135,9,87,1,71,
135,9,87,4,0,
135,9,91,4,0,
135,9,92,4,0,
135,9,97,1,64,
135,9,98,1,22,
135,9,104,4,0,
135,9,113,4,0,
135,9,129,3,0,
135,9,148,4,0,
135,9,156,4,0,
135,9,164,4,0,
135,9,173,3,0,
135,9,182,4,0,
135,9,189,3,0,
135,9,203,4,0,
135,9,207,4,0,
135,9,213,4,0,
135,9,214,4,0,
135,9,216,4,0,
135,9,218,4,0,
135,9,231,4,0,
135,9,237,4,0,
135,9,240,4,0,
135,9,241,4,0,
135,9,247,4,0,
135,9,249,4,0,
135,9,263,4,0,
135,9,270,1,1,3
135,9,270,3,0,
135,9,290,4,0,
135,9,324,3,0,
135,9,351,4,0,
135,9,363,4,0,
135,9,387,1,50,
135,9,387,3,0,
135,9,393,3,0,
135,9,416,4,0,
135,9,422,1,43,
135,9,435,1,78,
135,9,445,4,0,
135,9,451,4,0,
135,10,24,1,29,
135,10,28,1,8,
135,10,29,3,0,
135,10,33,1,1,2
135,10,39,1,1,1
135,10,42,1,36,
135,10,46,4,0,
135,10,63,4,0,
135,10,70,4,0,
135,10,84,1,15,
135,10,85,4,0,
135,10,86,1,57,
135,10,86,4,0,
135,10,87,1,71,
135,10,87,4,0,
135,10,91,4,0,
135,10,92,4,0,
135,10,97,1,64,
135,10,98,1,22,
135,10,104,4,0,
135,10,113,4,0,
135,10,129,3,0,
135,10,148,4,0,
135,10,156,4,0,
135,10,164,4,0,
135,10,173,3,0,
135,10,182,4,0,
135,10,189,3,0,
135,10,203,4,0,
135,10,207,4,0,
135,10,213,4,0,
135,10,214,4,0,
135,10,215,3,0,
135,10,216,4,0,
135,10,218,4,0,
135,10,231,4,0,
135,10,237,4,0,
135,10,240,4,0,
135,10,241,4,0,
135,10,247,4,0,
135,10,249,4,0,
135,10,263,4,0,
135,10,270,1,1,3
135,10,270,3,0,
135,10,290,4,0,
135,10,324,3,0,
135,10,351,4,0,
135,10,363,4,0,
135,10,387,1,50,
135,10,387,3,0,
135,10,393,3,0,
135,10,416,4,0,
135,10,422,1,43,
135,10,435,1,78,
135,10,445,4,0,
135,10,451,4,0,
135,11,24,1,29,
135,11,28,1,8,
135,11,33,1,1,2
135,11,39,1,1,1
135,11,42,1,36,
135,11,46,4,0,
135,11,63,4,0,
135,11,70,4,0,
135,11,84,1,15,
135,11,85,4,0,
135,11,86,1,57,
135,11,86,4,0,
135,11,87,1,71,
135,11,87,4,0,
135,11,91,4,0,
135,11,92,4,0,
135,11,97,1,64,
135,11,98,1,22,
135,11,104,4,0,
135,11,113,4,0,
135,11,148,4,0,
135,11,156,4,0,
135,11,164,4,0,
135,11,182,4,0,
135,11,207,4,0,
135,11,213,4,0,
135,11,216,4,0,
135,11,218,4,0,
135,11,237,4,0,
135,11,240,4,0,
135,11,241,4,0,
135,11,247,4,0,
135,11,249,4,0,
135,11,263,4,0,
135,11,270,1,1,3
135,11,387,1,50,
135,11,416,4,0,
135,11,422,1,43,
135,11,435,1,78,
135,11,451,4,0,
135,11,496,4,0,
135,11,497,4,0,
135,11,514,4,0,
135,11,521,4,0,
135,11,526,4,0,
135,11,528,4,0,
135,12,24,1,30,
135,12,28,1,8,
135,12,33,1,1,1
135,12,39,1,1,2
135,12,42,1,36,
135,12,46,4,0,
135,12,63,4,0,
135,12,84,1,16,
135,12,85,4,0,
135,12,86,1,42,
135,12,87,1,52,
135,12,87,4,0,
135,12,91,4,0,
135,12,92,4,0,
135,12,97,1,47,
135,12,98,1,23,
135,12,104,4,0,
135,12,148,4,0,
135,12,156,4,0,
135,12,182,4,0,
135,12,213,4,0,
135,12,216,4,0,
135,12,218,4,0,
135,12,231,4,0,
135,12,237,4,0,
135,12,240,4,0,
135,12,241,4,0,
135,12,247,4,0,
135,12,263,4,0,
135,12,270,1,1,3
135,12,290,4,0,
135,12,351,4,0,
135,13,24,1,30,
135,13,28,1,8,
135,13,33,1,1,1
135,13,34,3,0,
135,13,38,3,0,
135,13,39,1,1,2
135,13,42,1,36,
135,13,46,4,0,
135,13,63,4,0,
135,13,84,1,16,
135,13,85,4,0,
135,13,86,1,42,
135,13,86,3,0,
135,13,87,1,52,
135,13,87,4,0,
135,13,91,4,0,
135,13,92,4,0,
135,13,97,1,47,
135,13,98,1,23,
135,13,102,3,0,
135,13,104,4,0,
135,13,148,4,0,
135,13,156,4,0,
135,13,164,3,0,
135,13,182,4,0,
135,13,207,3,0,
135,13,213,4,0,
135,13,216,4,0,
135,13,218,4,0,
135,13,231,4,0,
135,13,237,4,0,
135,13,240,4,0,
135,13,241,4,0,
135,13,247,4,0,
135,13,263,4,0,
135,13,270,1,1,3
135,13,290,4,0,
135,13,351,4,0,
135,14,24,1,17,
135,14,28,1,5,
135,14,33,1,1,2
135,14,39,1,1,3
135,14,42,1,25,
135,14,46,4,0,
135,14,63,4,0,
135,14,70,4,0,
135,14,84,1,9,
135,14,85,4,0,
135,14,86,1,33,
135,14,86,4,0,
135,14,87,1,45,
135,14,87,4,0,
135,14,91,4,0,
135,14,92,4,0,
135,14,97,1,29,
135,14,98,1,13,
135,14,104,4,0,
135,14,113,4,0,
135,14,148,4,0,
135,14,156,4,0,
135,14,164,4,0,
135,14,173,3,0,
135,14,182,4,0,
135,14,207,4,0,
135,14,213,4,0,
135,14,214,3,0,
135,14,215,3,0,
135,14,216,4,0,
135,14,218,4,0,
135,14,231,3,0,
135,14,237,4,0,
135,14,240,4,0,
135,14,241,4,0,
135,14,247,4,0,
135,14,249,4,0,
135,14,263,4,0,
135,14,270,1,1,1
135,14,270,3,0,
135,14,304,3,0,
135,14,324,3,0,
135,14,343,3,0,
135,14,387,1,41,
135,14,387,3,0,
135,14,393,3,0,
135,14,416,4,0,
135,14,422,1,21,
135,14,435,1,37,
135,14,451,4,0,
135,14,496,4,0,
135,14,497,4,0,
135,14,514,4,0,
135,14,521,4,0,
135,14,526,4,0,
135,14,528,4,0,
135,15,24,1,17,
135,15,28,1,5,
135,15,33,1,1,2
135,15,39,1,1,3
135,15,42,1,25,
135,15,46,4,0,
135,15,63,4,0,
135,15,70,4,0,
135,15,84,1,9,
135,15,85,4,0,
135,15,86,1,33,
135,15,86,4,0,
135,15,87,1,45,
135,15,87,4,0,
135,15,91,4,0,
135,15,92,4,0,
135,15,97,1,29,
135,15,98,1,13,
135,15,104,4,0,
135,15,113,4,0,
135,15,148,4,0,
135,15,156,4,0,
135,15,164,4,0,
135,15,182,4,0,
135,15,207,4,0,
135,15,213,4,0,
135,15,214,4,0,
135,15,216,4,0,
135,15,218,4,0,
135,15,237,4,0,
135,15,240,4,0,
135,15,241,4,0,
135,15,247,4,0,
135,15,249,4,0,
135,15,263,4,0,
135,15,270,1,1,1
135,15,387,1,41,
135,15,416,4,0,
135,15,422,1,20,
135,15,435,1,37,
135,15,451,4,0,
135,15,496,4,0,
135,15,497,4,0,
135,15,514,4,0,
135,15,521,4,0,
135,15,528,4,0,
135,15,590,4,0,
135,16,24,1,17,1
135,16,28,1,5,1
135,16,33,1,1,2
135,16,39,1,1,3
135,16,42,1,25,1
135,16,46,4,0,
135,16,63,4,0,
135,16,70,4,0,
135,16,84,1,9,1
135,16,85,4,0,
135,16,86,1,33,1
135,16,86,4,0,
135,16,87,1,45,1
135,16,87,4,0,
135,16,91,4,0,
135,16,92,4,0,
135,16,97,1,29,1
135,16,98,1,13,1
135,16,104,4,0,
135,16,113,4,0,
135,16,148,4,0,
135,16,156,4,0,
135,16,164,4,0,
135,16,173,3,0,
135,16,182,4,0,
135,16,207,4,0,
135,16,213,4,0,
135,16,214,4,0,
135,16,215,3,0,
135,16,216,4,0,
135,16,218,4,0,
135,16,231,3,0,
135,16,237,4,0,
135,16,240,4,0,
135,16,241,4,0,
135,16,247,4,0,
135,16,249,4,0,
135,16,263,4,0,
135,16,270,1,1,1
135,16,270,3,0,
135,16,290,4,0,
135,16,304,3,0,
135,16,324,3,0,
135,16,343,3,0,
135,16,351,3,0,
135,16,387,1,41,1
135,16,387,3,0,
135,16,393,3,0,
135,16,416,4,0,
135,16,422,1,20,1
135,16,435,1,37,1
135,16,451,4,0,
135,16,496,4,0,
135,16,497,4,0,
135,16,514,4,0,
135,16,521,4,0,
135,16,528,4,0,
135,16,590,4,0,
135,17,24,1,17,
135,17,28,1,5,
135,17,33,1,1,3
135,17,39,1,1,4
135,17,42,1,25,
135,17,46,4,0,
135,17,63,4,0,
135,17,84,1,0,
135,17,84,1,1,1
135,17,85,4,0,
135,17,86,1,33,
135,17,86,4,0,
135,17,87,1,45,
135,17,87,4,0,
135,17,92,4,0,
135,17,97,1,29,
135,17,98,1,13,
135,17,104,4,0,
135,17,113,4,0,
135,17,156,4,0,
135,17,164,4,0,
135,17,182,4,0,
135,17,207,4,0,
135,17,213,4,0,
135,17,214,4,0,
135,17,216,4,0,
135,17,218,4,0,
135,17,237,4,0,
135,17,240,4,0,
135,17,241,4,0,
135,17,247,4,0,
135,17,263,4,0,
135,17,270,1,1,2
135,17,387,1,41,
135,17,416,4,0,
135,17,422,1,20,
135,17,435,1,37,
135,17,451,4,0,
135,17,496,4,0,
135,17,497,4,0,
135,17,521,4,0,
135,17,526,4,0,
135,17,528,4,0,
135,17,590,4,0,
135,17,608,1,9,
135,18,24,1,17,
135,18,28,1,5,
135,18,33,1,1,3
135,18,39,1,1,4
135,18,42,1,25,
135,18,46,4,0,
135,18,63,4,0,
135,18,84,1,0,
135,18,84,1,1,1
135,18,85,4,0,
135,18,86,1,33,
135,18,86,4,0,
135,18,87,1,45,
135,18,87,4,0,
135,18,92,4,0,
135,18,97,1,29,
135,18,98,1,13,
135,18,104,4,0,
135,18,113,4,0,
135,18,156,4,0,
135,18,164,4,0,
135,18,182,4,0,
135,18,207,4,0,
135,18,213,4,0,
135,18,214,4,0,
135,18,216,4,0,
135,18,218,4,0,
135,18,237,4,0,
135,18,240,4,0,
135,18,241,4,0,
135,18,247,4,0,
135,18,263,4,0,
135,18,270,1,1,2
135,18,387,1,41,
135,18,416,4,0,
135,18,422,1,20,
135,18,435,1,37,
135,18,451,4,0,
135,18,496,4,0,
135,18,497,4,0,
135,18,521,4,0,
135,18,526,4,0,
135,18,528,4,0,
135,18,590,4,0,
135,18,608,1,9,
136,1,28,1,1,2
136,1,33,1,1,1
136,1,34,4,0,
136,1,36,4,0,
136,1,38,4,0,
136,1,39,1,37,
136,1,43,1,42,
136,1,44,1,40,
136,1,52,1,1,4
136,1,52,1,31,
136,1,53,1,54,
136,1,63,4,0,
136,1,83,1,44,
136,1,92,4,0,
136,1,98,1,1,3
136,1,98,1,27,
136,1,99,1,48,
136,1,99,4,0,
136,1,102,4,0,
136,1,104,4,0,
136,1,115,4,0,
136,1,117,4,0,
136,1,126,4,0,
136,1,129,4,0,
136,1,130,4,0,
136,1,156,4,0,
136,1,164,4,0,
136,2,28,1,8,
136,2,33,1,1,1
136,2,34,4,0,
136,2,36,4,0,
136,2,38,4,0,
136,2,39,1,1,2
136,2,43,1,47,
136,2,44,1,30,
136,2,52,1,1,4
136,2,52,1,16,
136,2,53,1,52,
136,2,63,4,0,
136,2,83,1,36,
136,2,92,4,0,
136,2,98,1,1,3
136,2,98,1,23,
136,2,99,4,0,
136,2,102,4,0,
136,2,104,4,0,
136,2,115,4,0,
136,2,117,4,0,
136,2,123,1,42,
136,2,126,4,0,
136,2,129,4,0,
136,2,130,4,0,
136,2,156,4,0,
136,2,164,4,0,
136,3,28,1,8,
136,3,29,4,0,
136,3,33,1,1,1
136,3,39,1,1,2
136,3,43,1,47,
136,3,44,1,30,
136,3,46,4,0,
136,3,52,1,16,
136,3,53,1,52,
136,3,63,4,0,
136,3,83,1,36,
136,3,92,4,0,
136,3,98,1,23,
136,3,104,4,0,
136,3,123,1,42,
136,3,126,4,0,
136,3,129,4,0,
136,3,156,4,0,
136,3,173,4,0,
136,3,174,4,0,
136,3,182,4,0,
136,3,189,4,0,
136,3,192,4,0,
136,3,197,4,0,
136,3,203,4,0,
136,3,207,4,0,
136,3,213,4,0,
136,3,214,4,0,
136,3,216,4,0,
136,3,218,4,0,
136,3,231,4,0,
136,3,237,4,0,
136,3,240,4,0,
136,3,241,4,0,
136,3,247,4,0,
136,4,28,1,8,
136,4,29,4,0,
136,4,33,1,1,1
136,4,39,1,1,2
136,4,43,1,47,
136,4,44,1,30,
136,4,46,4,0,
136,4,52,1,16,
136,4,53,1,52,
136,4,53,3,0,
136,4,63,4,0,
136,4,83,1,36,
136,4,92,4,0,
136,4,98,1,23,
136,4,104,4,0,
136,4,123,1,42,
136,4,126,4,0,
136,4,129,4,0,
136,4,156,4,0,
136,4,173,4,0,
136,4,174,4,0,
136,4,182,4,0,
136,4,189,4,0,
136,4,192,4,0,
136,4,197,4,0,
136,4,203,4,0,
136,4,207,4,0,
136,4,213,4,0,
136,4,214,4,0,
136,4,216,4,0,
136,4,218,4,0,
136,4,231,4,0,
136,4,237,4,0,
136,4,240,4,0,
136,4,241,4,0,
136,4,247,4,0,
136,5,28,1,8,
136,5,33,1,1,1
136,5,39,1,1,2
136,5,43,1,47,
136,5,44,1,30,
136,5,46,4,0,
136,5,52,1,16,
136,5,53,1,52,
136,5,53,4,0,
136,5,63,4,0,
136,5,83,1,36,
136,5,91,4,0,
136,5,92,4,0,
136,5,98,1,23,
136,5,104,4,0,
136,5,123,1,42,
136,5,126,4,0,
136,5,156,4,0,
136,5,182,4,0,
136,5,213,4,0,
136,5,216,4,0,
136,5,218,4,0,
136,5,231,4,0,
136,5,237,4,0,
136,5,240,4,0,
136,5,241,4,0,
136,5,247,4,0,
136,5,263,4,0,
136,5,270,1,1,3
136,5,290,4,0,
136,5,315,4,0,
136,6,28,1,8,
136,6,33,1,1,1
136,6,34,3,0,
136,6,38,3,0,
136,6,39,1,1,2
136,6,43,1,47,
136,6,44,1,30,
136,6,46,4,0,
136,6,52,1,16,
136,6,53,1,52,
136,6,53,4,0,
136,6,63,4,0,
136,6,83,1,36,
136,6,91,4,0,
136,6,92,4,0,
136,6,98,1,23,
136,6,102,3,0,
136,6,104,4,0,
136,6,123,1,42,
136,6,126,4,0,
136,6,129,3,0,
136,6,156,4,0,
136,6,164,3,0,
136,6,173,3,0,
136,6,182,4,0,
136,6,189,3,0,
136,6,203,3,0,
136,6,207,3,0,
136,6,213,4,0,
136,6,214,3,0,
136,6,216,4,0,
136,6,218,4,0,
136,6,231,4,0,
136,6,237,4,0,
136,6,240,4,0,
136,6,241,4,0,
136,6,247,4,0,
136,6,263,4,0,
136,6,270,1,1,3
136,6,290,4,0,
136,6,315,4,0,
136,7,28,1,8,
136,7,33,1,1,1
136,7,34,3,0,
136,7,38,3,0,
136,7,39,1,1,2
136,7,43,1,47,
136,7,44,1,30,
136,7,46,4,0,
136,7,52,1,16,
136,7,53,1,52,
136,7,53,4,0,
136,7,63,4,0,
136,7,83,1,36,
136,7,91,4,0,
136,7,92,4,0,
136,7,98,1,23,
136,7,102,3,0,
136,7,104,4,0,
136,7,123,1,42,
136,7,126,4,0,
136,7,156,4,0,
136,7,164,3,0,
136,7,182,4,0,
136,7,213,4,0,
136,7,216,4,0,
136,7,218,4,0,
136,7,231,4,0,
136,7,237,4,0,
136,7,240,4,0,
136,7,241,4,0,
136,7,247,4,0,
136,7,263,4,0,
136,7,270,1,1,3
136,7,290,4,0,
136,7,315,4,0,
136,8,28,1,8,
136,8,33,1,1,1
136,8,39,1,1,2
136,8,44,1,29,
136,8,46,4,0,
136,8,52,1,15,
136,8,53,4,0,
136,8,63,4,0,
136,8,70,4,0,
136,8,83,1,36,
136,8,91,4,0,
136,8,92,4,0,
136,8,98,1,22,
136,8,104,4,0,
136,8,123,1,57,
136,8,126,1,71,
136,8,126,4,0,
136,8,156,4,0,
136,8,164,4,0,
136,8,182,4,0,
136,8,184,1,64,
136,8,203,4,0,
136,8,207,4,0,
136,8,213,4,0,
136,8,214,4,0,
136,8,216,4,0,
136,8,218,4,0,
136,8,231,4,0,
136,8,237,4,0,
136,8,240,4,0,
136,8,241,4,0,
136,8,247,4,0,
136,8,249,4,0,
136,8,261,4,0,
136,8,263,4,0,
136,8,270,1,1,3
136,8,290,4,0,
136,8,315,4,0,
136,8,363,4,0,
136,8,387,1,50,
136,8,416,4,0,
136,8,424,1,43,
136,8,445,4,0,
136,9,28,1,8,
136,9,33,1,1,2
136,9,39,1,1,1
136,9,44,1,29,
136,9,46,4,0,
136,9,52,1,15,
136,9,53,4,0,
136,9,63,4,0,
136,9,70,4,0,
136,9,83,1,36,
136,9,91,4,0,
136,9,92,4,0,
136,9,98,1,22,
136,9,104,4,0,
136,9,123,1,57,
136,9,126,1,71,
136,9,126,4,0,
136,9,129,3,0,
136,9,156,4,0,
136,9,164,4,0,
136,9,173,3,0,
136,9,182,4,0,
136,9,184,1,64,
136,9,189,3,0,
136,9,203,4,0,
136,9,207,4,0,
136,9,213,4,0,
136,9,214,4,0,
136,9,216,4,0,
136,9,218,4,0,
136,9,231,4,0,
136,9,237,4,0,
136,9,240,4,0,
136,9,241,4,0,
136,9,247,4,0,
136,9,249,4,0,
136,9,257,3,0,
136,9,261,4,0,
136,9,263,4,0,
136,9,270,1,1,3
136,9,270,3,0,
136,9,276,3,0,
136,9,290,4,0,
136,9,315,4,0,
136,9,363,4,0,
136,9,387,1,50,
136,9,387,3,0,
136,9,416,4,0,
136,9,424,1,43,
136,9,436,1,78,
136,9,445,4,0,
136,10,28,1,8,
136,10,29,3,0,
136,10,33,1,1,2
136,10,39,1,1,1
136,10,44,1,29,
136,10,46,4,0,
136,10,52,1,15,
136,10,53,4,0,
136,10,63,4,0,
136,10,70,4,0,
136,10,83,1,36,
136,10,91,4,0,
136,10,92,4,0,
136,10,98,1,22,
136,10,104,4,0,
136,10,123,1,57,
136,10,126,1,71,
136,10,126,4,0,
136,10,129,3,0,
136,10,156,4,0,
136,10,164,4,0,
136,10,173,3,0,
136,10,182,4,0,
136,10,184,1,64,
136,10,189,3,0,
136,10,203,4,0,
136,10,207,4,0,
136,10,213,4,0,
136,10,214,4,0,
136,10,215,3,0,
136,10,216,4,0,
136,10,218,4,0,
136,10,231,4,0,
136,10,237,4,0,
136,10,240,4,0,
136,10,241,4,0,
136,10,247,4,0,
136,10,249,4,0,
136,10,257,3,0,
136,10,261,4,0,
136,10,263,4,0,
136,10,270,1,1,3
136,10,270,3,0,
136,10,276,3,0,
136,10,290,4,0,
136,10,315,4,0,
136,10,363,4,0,
136,10,387,1,50,
136,10,387,3,0,
136,10,416,4,0,
136,10,424,1,43,
136,10,436,1,78,
136,10,445,4,0,
136,11,28,1,8,
136,11,33,1,1,2
136,11,39,1,1,1
136,11,44,1,29,
136,11,46,4,0,
136,11,52,1,15,
136,11,53,4,0,
136,11,63,4,0,
136,11,70,4,0,
136,11,83,1,36,
136,11,91,4,0,
136,11,92,4,0,
136,11,98,1,22,
136,11,104,4,0,
136,11,123,1,57,
136,11,126,1,71,
136,11,126,4,0,
136,11,156,4,0,
136,11,164,4,0,
136,11,182,4,0,
136,11,184,1,64,
136,11,207,4,0,
136,11,213,4,0,
136,11,216,4,0,
136,11,218,4,0,
136,11,237,4,0,
136,11,240,4,0,
136,11,241,4,0,
136,11,247,4,0,
136,11,249,4,0,
136,11,261,4,0,
136,11,263,4,0,
136,11,270,1,1,3
136,11,315,4,0,
136,11,387,1,50,
136,11,416,4,0,
136,11,424,1,43,
136,11,436,1,78,
136,11,488,4,0,
136,11,496,4,0,
136,11,497,4,0,
136,11,510,4,0,
136,11,514,4,0,
136,11,526,4,0,
136,12,28,1,8,
136,12,33,1,1,1
136,12,39,1,1,2
136,12,43,1,47,
136,12,44,1,30,
136,12,46,4,0,
136,12,52,1,16,
136,12,53,1,52,
136,12,53,4,0,
136,12,63,4,0,
136,12,83,1,36,
136,12,91,4,0,
136,12,92,4,0,
136,12,98,1,23,
136,12,104,4,0,
136,12,123,1,42,
136,12,126,4,0,
136,12,156,4,0,
136,12,182,4,0,
136,12,213,4,0,
136,12,216,4,0,
136,12,218,4,0,
136,12,231,4,0,
136,12,237,4,0,
136,12,240,4,0,
136,12,241,4,0,
136,12,247,4,0,
136,12,263,4,0,
136,12,270,1,1,3
136,12,290,4,0,
136,12,315,4,0,
136,13,28,1,8,
136,13,33,1,1,1
136,13,34,3,0,
136,13,38,3,0,
136,13,39,1,1,2
136,13,43,1,47,
136,13,44,1,30,
136,13,46,4,0,
136,13,52,1,16,
136,13,53,1,52,
136,13,53,4,0,
136,13,63,4,0,
136,13,83,1,36,
136,13,91,4,0,
136,13,92,4,0,
136,13,98,1,23,
136,13,102,3,0,
136,13,104,4,0,
136,13,123,1,42,
136,13,126,4,0,
136,13,156,4,0,
136,13,164,3,0,
136,13,182,4,0,
136,13,207,3,0,
136,13,213,4,0,
136,13,216,4,0,
136,13,218,4,0,
136,13,231,4,0,
136,13,237,4,0,
136,13,240,4,0,
136,13,241,4,0,
136,13,247,4,0,
136,13,263,4,0,
136,13,270,1,1,3
136,13,290,4,0,
136,13,315,4,0,
136,14,28,1,5,
136,14,33,1,1,2
136,14,39,1,1,3
136,14,44,1,17,
136,14,46,4,0,
136,14,52,1,9,
136,14,53,4,0,
136,14,63,4,0,
136,14,70,4,0,
136,14,83,1,25,
136,14,91,4,0,
136,14,92,4,0,
136,14,98,1,13,
136,14,104,4,0,
136,14,123,1,33,
136,14,126,1,45,
136,14,126,4,0,
136,14,156,4,0,
136,14,164,4,0,
136,14,173,3,0,
136,14,182,4,0,
136,14,184,1,29,
136,14,207,4,0,
136,14,213,4,0,
136,14,214,3,0,
136,14,215,3,0,
136,14,216,4,0,
136,14,218,4,0,
136,14,231,3,0,
136,14,237,4,0,
136,14,240,4,0,
136,14,241,4,0,
136,14,247,4,0,
136,14,249,4,0,
136,14,257,3,0,
136,14,261,4,0,
136,14,263,4,0,
136,14,270,1,1,1
136,14,270,3,0,
136,14,276,3,0,
136,14,304,3,0,
136,14,315,4,0,
136,14,343,3,0,
136,14,387,1,41,
136,14,387,3,0,
136,14,416,4,0,
136,14,424,1,21,
136,14,436,1,37,
136,14,488,4,0,
136,14,496,4,0,
136,14,497,4,0,
136,14,510,4,0,
136,14,514,4,0,
136,14,526,4,0,
136,15,28,1,5,
136,15,33,1,1,2
136,15,39,1,1,3
136,15,44,1,17,
136,15,46,4,0,
136,15,52,1,9,
136,15,53,4,0,
136,15,63,4,0,
136,15,70,4,0,
136,15,83,1,25,
136,15,91,4,0,
136,15,92,4,0,
136,15,98,1,13,
136,15,104,4,0,
136,15,123,1,33,
136,15,126,4,0,
136,15,156,4,0,
136,15,164,4,0,
136,15,182,4,0,
136,15,184,1,29,
136,15,207,4,0,
136,15,213,4,0,
136,15,214,4,0,
136,15,216,4,0,
136,15,218,4,0,
136,15,237,4,0,
136,15,240,4,0,
136,15,241,4,0,
136,15,247,4,0,
136,15,249,4,0,
136,15,261,4,0,
136,15,263,4,0,
136,15,270,1,1,1
136,15,315,4,0,
136,15,387,1,41,
136,15,394,1,45,
136,15,416,4,0,
136,15,424,1,20,
136,15,436,1,37,
136,15,488,4,0,
136,15,496,4,0,
136,15,497,4,0,
136,15,510,4,0,
136,15,514,4,0,
136,15,590,4,0,
136,16,28,1,5,1
136,16,33,1,1,2
136,16,39,1,1,3
136,16,44,1,17,1
136,16,46,4,0,
136,16,52,1,9,1
136,16,53,4,0,
136,16,63,4,0,
136,16,70,4,0,
136,16,83,1,25,1
136,16,91,4,0,
136,16,92,4,0,
136,16,98,1,13,1
136,16,104,4,0,
136,16,123,1,33,1
136,16,126,4,0,
136,16,156,4,0,
136,16,164,4,0,
136,16,173,3,0,
136,16,182,4,0,
136,16,184,1,29,1
136,16,207,4,0,
136,16,213,4,0,
136,16,214,4,0,
136,16,215,3,0,
136,16,216,4,0,
136,16,218,4,0,
136,16,231,3,0,
136,16,237,4,0,
136,16,240,4,0,
136,16,241,4,0,
136,16,247,4,0,
136,16,249,4,0,
136,16,257,3,0,
136,16,261,4,0,
136,16,263,4,0,
136,16,270,1,1,1
136,16,270,3,0,
136,16,276,3,0,
136,16,290,4,0,
136,16,304,3,0,
136,16,315,4,0,
136,16,343,3,0,
136,16,387,1,41,1
136,16,387,3,0,
136,16,394,1,45,1
136,16,416,4,0,
136,16,424,1,20,1
136,16,436,1,37,1
136,16,488,4,0,
136,16,496,4,0,
136,16,497,4,0,
136,16,510,4,0,
136,16,514,4,0,
136,16,590,4,0,
136,17,28,1,5,
136,17,33,1,1,3
136,17,39,1,1,4
136,17,44,1,17,
136,17,46,4,0,
136,17,52,1,0,
136,17,52,1,1,1
136,17,53,4,0,
136,17,63,4,0,
136,17,83,1,25,
136,17,92,4,0,
136,17,98,1,13,
136,17,104,4,0,
136,17,123,1,33,
136,17,126,4,0,
136,17,156,4,0,
136,17,164,4,0,
136,17,182,4,0,
136,17,184,1,29,
136,17,207,4,0,
136,17,213,4,0,
136,17,214,4,0,
136,17,216,4,0,
136,17,218,4,0,
136,17,237,4,0,
136,17,240,4,0,
136,17,241,4,0,
136,17,247,4,0,
136,17,261,4,0,
136,17,263,4,0,
136,17,270,1,1,2
136,17,315,4,0,
136,17,387,1,41,
136,17,394,1,45,
136,17,416,4,0,
136,17,424,1,20,
136,17,436,1,37,
136,17,488,4,0,
136,17,496,4,0,
136,17,497,4,0,
136,17,526,4,0,
136,17,590,4,0,
136,17,608,1,9,
136,18,28,1,5,
136,18,33,1,1,3
136,18,39,1,1,4
136,18,44,1,17,
136,18,46,4,0,
136,18,52,1,0,
136,18,52,1,1,1
136,18,53,4,0,
136,18,63,4,0,
136,18,83,1,25,
136,18,92,4,0,
136,18,98,1,13,
136,18,104,4,0,
136,18,123,1,33,
136,18,126,4,0,
136,18,156,4,0,
136,18,164,4,0,
136,18,182,4,0,
136,18,184,1,29,
136,18,207,4,0,
136,18,213,4,0,
136,18,214,4,0,
136,18,216,4,0,
136,18,218,4,0,
136,18,237,4,0,
136,18,240,4,0,
136,18,241,4,0,
136,18,247,4,0,
136,18,261,4,0,
136,18,263,4,0,
136,18,270,1,1,2
136,18,315,4,0,
136,18,387,1,41,
136,18,394,1,45,
136,18,416,4,0,
136,18,424,1,20,
136,18,436,1,37,
136,18,488,4,0,
136,18,496,4,0,
136,18,497,4,0,
136,18,526,4,0,
136,18,590,4,0,
136,18,608,1,9,
137,1,33,1,1,1
137,1,36,4,0,
137,1,38,4,0,
137,1,58,4,0,
137,1,59,4,0,
137,1,60,1,23,
137,1,63,4,0,
137,1,85,4,0,
137,1,86,4,0,
137,1,87,4,0,
137,1,92,4,0,
137,1,94,4,0,
137,1,97,1,35,
137,1,99,4,0,
137,1,100,4,0,
137,1,102,4,0,
137,1,104,4,0,
137,1,105,1,28,
137,1,115,4,0,
137,1,117,4,0,
137,1,129,4,0,
137,1,130,4,0,
137,1,148,4,0,
137,1,149,4,0,
137,1,156,4,0,
137,1,159,1,1,2
137,1,160,1,1,3
137,1,161,1,42,
137,1,161,4,0,
137,1,164,4,0,
137,2,33,1,1,1
137,2,36,4,0,
137,2,38,4,0,
137,2,58,4,0,
137,2,59,4,0,
137,2,60,1,23,
137,2,63,4,0,
137,2,85,4,0,
137,2,86,4,0,
137,2,87,4,0,
137,2,92,4,0,
137,2,94,4,0,
137,2,97,1,35,
137,2,99,4,0,
137,2,100,4,0,
137,2,102,4,0,
137,2,104,4,0,
137,2,105,1,28,
137,2,115,4,0,
137,2,117,4,0,
137,2,129,4,0,
137,2,130,4,0,
137,2,148,4,0,
137,2,149,4,0,
137,2,156,4,0,
137,2,159,1,1,2
137,2,160,1,1,3
137,2,161,1,42,
137,2,161,4,0,
137,2,164,4,0,
137,3,33,1,1,2
137,3,59,4,0,
137,3,60,1,12,
137,3,63,4,0,
137,3,87,4,0,
137,3,92,4,0,
137,3,94,4,0,
137,3,97,1,9,
137,3,104,4,0,
137,3,105,1,20,
137,3,129,4,0,
137,3,138,4,0,
137,3,148,4,0,
137,3,156,4,0,
137,3,159,1,24,
137,3,160,1,1,3
137,3,161,1,36,
137,3,168,4,0,
137,3,171,4,0,
137,3,173,4,0,
137,3,174,4,0,
137,3,176,1,1,1
137,3,182,4,0,
137,3,192,1,44,
137,3,192,4,0,
137,3,196,4,0,
137,3,199,1,32,
137,3,203,4,0,
137,3,207,4,0,
137,3,214,4,0,
137,3,216,4,0,
137,3,218,4,0,
137,3,231,4,0,
137,3,237,4,0,
137,3,240,4,0,
137,3,241,4,0,
137,3,244,4,0,
137,4,33,1,1,2
137,4,58,3,0,
137,4,59,4,0,
137,4,60,1,12,
137,4,63,4,0,
137,4,85,3,0,
137,4,87,4,0,
137,4,92,4,0,
137,4,94,4,0,
137,4,97,1,9,
137,4,104,4,0,
137,4,105,1,20,
137,4,129,4,0,
137,4,138,4,0,
137,4,148,4,0,
137,4,156,4,0,
137,4,159,1,24,
137,4,160,1,1,3
137,4,161,1,36,
137,4,168,4,0,
137,4,171,4,0,
137,4,173,4,0,
137,4,174,4,0,
137,4,176,1,1,1
137,4,182,4,0,
137,4,192,1,44,
137,4,192,4,0,
137,4,196,4,0,
137,4,199,1,32,
137,4,203,4,0,
137,4,207,4,0,
137,4,214,4,0,
137,4,216,4,0,
137,4,218,4,0,
137,4,231,4,0,
137,4,237,4,0,
137,4,240,4,0,
137,4,241,4,0,
137,4,244,4,0,
137,5,33,1,1,2
137,5,58,4,0,
137,5,59,4,0,
137,5,60,1,12,
137,5,63,4,0,
137,5,76,4,0,
137,5,85,4,0,
137,5,87,4,0,
137,5,92,4,0,
137,5,94,4,0,
137,5,97,1,9,
137,5,104,4,0,
137,5,105,1,20,
137,5,148,4,0,
137,5,156,4,0,
137,5,159,1,24,
137,5,160,1,1,3
137,5,161,1,36,
137,5,168,4,0,
137,5,176,1,1,1
137,5,182,4,0,
137,5,192,1,48,
137,5,199,1,32,
137,5,216,4,0,
137,5,218,4,0,
137,5,231,4,0,
137,5,237,4,0,
137,5,240,4,0,
137,5,241,4,0,
137,5,247,4,0,
137,5,263,4,0,
137,5,278,1,44,
137,5,290,4,0,
137,5,332,4,0,
137,5,351,4,0,
137,6,33,1,1,2
137,6,38,3,0,
137,6,58,4,0,
137,6,59,4,0,
137,6,60,1,12,
137,6,63,4,0,
137,6,76,4,0,
137,6,85,4,0,
137,6,86,3,0,
137,6,87,4,0,
137,6,92,4,0,
137,6,94,4,0,
137,6,97,1,9,
137,6,102,3,0,
137,6,104,4,0,
137,6,105,1,20,
137,6,129,3,0,
137,6,138,3,0,
137,6,148,4,0,
137,6,156,4,0,
137,6,159,1,24,
137,6,160,1,1,3
137,6,161,1,36,
137,6,164,3,0,
137,6,168,4,0,
137,6,173,3,0,
137,6,176,1,1,1
137,6,182,4,0,
137,6,192,1,48,
137,6,196,3,0,
137,6,199,1,32,
137,6,203,3,0,
137,6,207,3,0,
137,6,214,3,0,
137,6,216,4,0,
137,6,218,4,0,
137,6,231,4,0,
137,6,237,4,0,
137,6,240,4,0,
137,6,241,4,0,
137,6,244,3,0,
137,6,247,4,0,
137,6,263,4,0,
137,6,278,1,44,
137,6,290,4,0,
137,6,332,4,0,
137,6,351,4,0,
137,7,33,1,1,2
137,7,38,3,0,
137,7,58,4,0,
137,7,59,4,0,
137,7,60,1,12,
137,7,63,4,0,
137,7,76,4,0,
137,7,85,4,0,
137,7,86,3,0,
137,7,87,4,0,
137,7,92,4,0,
137,7,94,4,0,
137,7,97,1,9,
137,7,102,3,0,
137,7,104,4,0,
137,7,105,1,20,
137,7,138,3,0,
137,7,148,4,0,
137,7,156,4,0,
137,7,159,1,24,
137,7,160,1,1,3
137,7,161,1,36,
137,7,164,3,0,
137,7,168,4,0,
137,7,176,1,1,1
137,7,182,4,0,
137,7,192,1,48,
137,7,199,1,32,
137,7,216,4,0,
137,7,218,4,0,
137,7,231,4,0,
137,7,237,4,0,
137,7,240,4,0,
137,7,241,4,0,
137,7,247,4,0,
137,7,263,4,0,
137,7,278,1,44,
137,7,290,4,0,
137,7,332,4,0,
137,7,351,4,0,
137,8,33,1,1,2
137,8,58,4,0,
137,8,59,4,0,
137,8,60,1,7,
137,8,63,4,0,
137,8,76,4,0,
137,8,85,4,0,
137,8,86,4,0,
137,8,87,4,0,
137,8,92,4,0,
137,8,94,4,0,
137,8,97,1,12,
137,8,104,4,0,
137,8,105,1,18,
137,8,138,4,0,
137,8,148,4,0,
137,8,156,4,0,
137,8,159,1,1,4
137,8,160,1,1,3
137,8,161,1,51,
137,8,164,4,0,
137,8,168,4,0,
137,8,176,1,1,1
137,8,182,4,0,
137,8,192,1,62,
137,8,199,1,45,
137,8,203,4,0,
137,8,207,4,0,
137,8,214,4,0,
137,8,216,4,0,
137,8,218,4,0,
137,8,231,4,0,
137,8,237,4,0,
137,8,240,4,0,
137,8,241,4,0,
137,8,244,4,0,
137,8,247,4,0,
137,8,263,4,0,
137,8,277,1,56,
137,8,278,1,34,
137,8,278,4,0,
137,8,290,4,0,
137,8,324,1,29,
137,8,332,4,0,
137,8,351,4,0,
137,8,363,4,0,
137,8,393,1,23,
137,8,416,4,0,
137,8,433,4,0,
137,8,435,1,40,
137,8,451,4,0,
137,9,33,1,1,2
137,9,58,4,0,
137,9,59,4,0,
137,9,60,1,7,
137,9,63,4,0,
137,9,76,4,0,
137,9,85,4,0,
137,9,86,4,0,
137,9,87,4,0,
137,9,92,4,0,
137,9,94,4,0,
137,9,97,1,12,
137,9,104,4,0,
137,9,105,1,18,
137,9,129,3,0,
137,9,138,4,0,
137,9,148,4,0,
137,9,156,4,0,
137,9,159,1,1,4
137,9,160,1,1,3
137,9,161,1,51,
137,9,164,4,0,
137,9,168,4,0,
137,9,173,3,0,
137,9,176,1,1,1
137,9,182,4,0,
137,9,192,1,62,
137,9,196,3,0,
137,9,199,1,45,
137,9,203,4,0,
137,9,207,4,0,
137,9,214,4,0,
137,9,216,4,0,
137,9,218,4,0,
137,9,231,4,0,
137,9,237,4,0,
137,9,240,4,0,
137,9,241,4,0,
137,9,244,4,0,
137,9,247,4,0,
137,9,263,4,0,
137,9,271,3,0,
137,9,277,1,56,
137,9,278,1,34,
137,9,278,4,0,
137,9,290,4,0,
137,9,324,1,29,
137,9,324,3,0,
137,9,332,4,0,
137,9,351,4,0,
137,9,363,4,0,
137,9,387,3,0,
137,9,393,1,23,
137,9,416,4,0,
137,9,428,3,0,
137,9,433,4,0,
137,9,435,1,40,
137,9,451,4,0,
137,10,33,1,1,2
137,10,58,4,0,
137,10,59,4,0,
137,10,60,1,7,
137,10,63,4,0,
137,10,76,4,0,
137,10,85,4,0,
137,10,86,4,0,
137,10,87,4,0,
137,10,92,4,0,
137,10,94,4,0,
137,10,97,1,12,
137,10,104,4,0,
137,10,105,1,18,
137,10,129,3,0,
137,10,138,4,0,
137,10,148,4,0,
137,10,156,4,0,
137,10,159,1,1,4
137,10,160,1,1,3
137,10,161,1,51,
137,10,164,4,0,
137,10,168,4,0,
137,10,173,3,0,
137,10,176,1,1,1
137,10,182,4,0,
137,10,192,1,62,
137,10,196,3,0,
137,10,199,1,45,
137,10,203,4,0,
137,10,207,4,0,
137,10,214,4,0,
137,10,216,4,0,
137,10,218,4,0,
137,10,220,3,0,
137,10,231,4,0,
137,10,237,4,0,
137,10,240,4,0,
137,10,241,4,0,
137,10,244,4,0,
137,10,247,4,0,
137,10,263,4,0,
137,10,271,3,0,
137,10,277,1,56,
137,10,277,3,0,
137,10,278,1,34,
137,10,278,4,0,
137,10,290,4,0,
137,10,324,1,29,
137,10,324,3,0,
137,10,332,4,0,
137,10,351,4,0,
137,10,356,3,0,
137,10,363,4,0,
137,10,387,3,0,
137,10,393,1,23,
137,10,416,4,0,
137,10,428,3,0,
137,10,433,4,0,
137,10,435,1,40,
137,10,451,4,0,
137,11,33,1,1,2
137,11,58,4,0,
137,11,59,4,0,
137,11,60,1,7,
137,11,63,4,0,
137,11,76,4,0,
137,11,85,4,0,
137,11,86,4,0,
137,11,87,4,0,
137,11,92,4,0,
137,11,94,4,0,
137,11,97,1,12,
137,11,104,4,0,
137,11,105,1,18,
137,11,138,4,0,
137,11,148,4,0,
137,11,156,4,0,
137,11,159,1,1,4
137,11,160,1,1,3
137,11,161,1,51,
137,11,164,4,0,
137,11,168,4,0,
137,11,176,1,1,1
137,11,182,4,0,
137,11,192,1,62,
137,11,199,1,45,
137,11,207,4,0,
137,11,216,4,0,
137,11,218,4,0,
137,11,237,4,0,
137,11,240,4,0,
137,11,241,4,0,
137,11,244,4,0,
137,11,247,4,0,
137,11,263,4,0,
137,11,277,1,56,
137,11,278,1,34,
137,11,324,1,29,
137,11,332,4,0,
137,11,393,1,23,
137,11,416,4,0,
137,11,433,4,0,
137,11,435,1,40,
137,11,451,4,0,
137,11,473,4,0,
137,11,496,4,0,
137,12,33,1,1,2
137,12,58,4,0,
137,12,59,4,0,
137,12,60,1,12,
137,12,63,4,0,
137,12,76,4,0,
137,12,85,4,0,
137,12,87,4,0,
137,12,92,4,0,
137,12,94,4,0,
137,12,97,1,9,
137,12,104,4,0,
137,12,105,1,20,
137,12,148,4,0,
137,12,156,4,0,
137,12,159,1,24,
137,12,160,1,1,3
137,12,161,1,36,
137,12,168,4,0,
137,12,176,1,1,1
137,12,182,4,0,
137,12,192,1,48,
137,12,199,1,32,
137,12,216,4,0,
137,12,218,4,0,
137,12,231,4,0,
137,12,237,4,0,
137,12,240,4,0,
137,12,241,4,0,
137,12,247,4,0,
137,12,263,4,0,
137,12,278,1,44,
137,12,290,4,0,
137,12,332,4,0,
137,12,351,4,0,
137,13,33,1,1,2
137,13,38,3,0,
137,13,58,4,0,
137,13,59,4,0,
137,13,60,1,12,
137,13,63,4,0,
137,13,76,4,0,
137,13,85,4,0,
137,13,86,3,0,
137,13,87,4,0,
137,13,92,4,0,
137,13,94,4,0,
137,13,97,1,9,
137,13,102,3,0,
137,13,104,4,0,
137,13,105,1,20,
137,13,138,3,0,
137,13,148,4,0,
137,13,156,4,0,
137,13,159,1,24,
137,13,160,1,1,3
137,13,161,1,36,
137,13,164,3,0,
137,13,168,4,0,
137,13,171,3,0,
137,13,176,1,1,1
137,13,182,4,0,
137,13,192,1,48,
137,13,196,3,0,
137,13,199,1,32,
137,13,207,3,0,
137,13,216,4,0,
137,13,218,4,0,
137,13,231,4,0,
137,13,237,4,0,
137,13,240,4,0,
137,13,241,4,0,
137,13,247,4,0,
137,13,263,4,0,
137,13,278,1,44,
137,13,290,4,0,
137,13,332,4,0,
137,13,351,4,0,
137,14,33,1,1,2
137,14,58,4,0,
137,14,59,4,0,
137,14,60,1,7,
137,14,63,4,0,
137,14,76,4,0,
137,14,85,4,0,
137,14,86,4,0,
137,14,87,4,0,
137,14,92,4,0,
137,14,94,4,0,
137,14,97,1,12,
137,14,104,4,0,
137,14,105,1,18,
137,14,138,4,0,
137,14,148,4,0,
137,14,156,4,0,
137,14,159,1,1,4
137,14,160,1,1,3
137,14,161,1,51,
137,14,164,4,0,
137,14,168,4,0,
137,14,173,3,0,
137,14,176,1,1,1
137,14,182,4,0,
137,14,192,1,62,
137,14,196,3,0,
137,14,199,1,45,
137,14,207,4,0,
137,14,214,3,0,
137,14,216,4,0,
137,14,218,4,0,
137,14,220,3,0,
137,14,231,3,0,
137,14,237,4,0,
137,14,240,4,0,
137,14,241,4,0,
137,14,244,4,0,
137,14,247,4,0,
137,14,263,4,0,
137,14,271,3,0,
137,14,277,1,56,
137,14,277,3,0,
137,14,278,1,34,
137,14,278,3,0,
137,14,324,1,29,
137,14,324,3,0,
137,14,332,4,0,
137,14,356,3,0,
137,14,387,3,0,
137,14,393,1,23,
137,14,393,3,0,
137,14,416,4,0,
137,14,428,3,0,
137,14,433,4,0,
137,14,435,1,40,
137,14,451,4,0,
137,14,472,3,0,
137,14,473,4,0,
137,14,492,3,0,
137,14,496,4,0,
137,14,527,3,0,
137,15,33,1,1,2
137,15,58,4,0,
137,15,59,4,0,
137,15,60,1,7,
137,15,63,4,0,
137,15,76,4,0,
137,15,85,4,0,
137,15,86,4,0,
137,15,87,4,0,
137,15,92,4,0,
137,15,94,4,0,
137,15,97,1,12,
137,15,104,4,0,
137,15,105,1,18,
137,15,138,4,0,
137,15,148,4,0,
137,15,156,4,0,
137,15,159,1,1,4
137,15,160,1,1,3
137,15,161,1,50,
137,15,164,4,0,
137,15,168,4,0,
137,15,176,1,1,1
137,15,182,4,0,
137,15,192,1,62,
137,15,199,1,45,
137,15,207,4,0,
137,15,214,4,0,
137,15,216,4,0,
137,15,218,4,0,
137,15,237,4,0,
137,15,240,4,0,
137,15,241,4,0,
137,15,244,4,0,
137,15,247,4,0,
137,15,263,4,0,
137,15,277,1,56,
137,15,278,1,34,
137,15,324,1,29,
137,15,332,4,0,
137,15,393,1,23,
137,15,416,4,0,
137,15,433,4,0,
137,15,435,1,40,
137,15,451,4,0,
137,15,473,4,0,
137,15,496,4,0,
137,15,590,4,0,
137,16,33,1,1,2
137,16,58,4,0,
137,16,59,4,0,
137,16,60,1,7,1
137,16,63,4,0,
137,16,76,4,0,
137,16,85,4,0,
137,16,86,4,0,
137,16,87,4,0,
137,16,92,4,0,
137,16,94,4,0,
137,16,97,1,12,1
137,16,104,4,0,
137,16,105,1,18,1
137,16,138,4,0,
137,16,148,4,0,
137,16,156,4,0,
137,16,159,1,1,4
137,16,160,1,1,3
137,16,161,1,50,1
137,16,164,4,0,
137,16,168,4,0,
137,16,173,3,0,
137,16,176,1,1,1
137,16,182,4,0,
137,16,192,1,62,1
137,16,196,3,0,
137,16,199,1,45,1
137,16,207,4,0,
137,16,214,4,0,
137,16,216,4,0,
137,16,218,4,0,
137,16,220,3,0,
137,16,231,3,0,
137,16,237,4,0,
137,16,240,4,0,
137,16,241,4,0,
137,16,244,4,0,
137,16,247,4,0,
137,16,263,4,0,
137,16,271,3,0,
137,16,277,1,56,1
137,16,277,3,0,
137,16,278,1,34,1
137,16,278,3,0,
137,16,290,4,0,
137,16,324,1,29,1
137,16,324,3,0,
137,16,332,4,0,
137,16,351,3,0,
137,16,356,3,0,
137,16,387,3,0,
137,16,393,1,23,1
137,16,393,3,0,
137,16,416,4,0,
137,16,428,3,0,
137,16,433,4,0,
137,16,435,1,40,1
137,16,451,4,0,
137,16,472,3,0,
137,16,473,4,0,
137,16,492,3,0,
137,16,496,4,0,
137,16,527,3,0,
137,16,590,4,0,
137,17,33,1,1,2
137,17,58,4,0,
137,17,59,4,0,
137,17,60,1,7,
137,17,63,4,0,
137,17,76,4,0,
137,17,85,4,0,
137,17,86,4,0,
137,17,87,4,0,
137,17,92,4,0,
137,17,94,4,0,
137,17,97,1,12,
137,17,104,4,0,
137,17,105,1,18,
137,17,138,4,0,
137,17,156,4,0,
137,17,159,1,1,4
137,17,160,1,1,3
137,17,161,1,50,
137,17,164,4,0,
137,17,168,4,0,
137,17,176,1,1,1
137,17,182,4,0,
137,17,192,1,62,
137,17,199,1,45,
137,17,207,4,0,
137,17,214,4,0,
137,17,216,4,0,
137,17,218,4,0,
137,17,237,4,0,
137,17,240,4,0,
137,17,241,4,0,
137,17,244,4,0,
137,17,247,4,0,
137,17,263,4,0,
137,17,277,1,56,
137,17,278,1,34,
137,17,324,1,29,
137,17,332,4,0,
137,17,393,1,23,
137,17,416,4,0,
137,17,433,4,0,
137,17,435,1,40,
137,17,451,4,0,
137,17,473,4,0,
137,17,496,4,0,
137,17,590,4,0,
137,18,33,1,1,2
137,18,58,4,0,
137,18,59,4,0,
137,18,60,1,7,
137,18,63,4,0,
137,18,76,4,0,
137,18,85,4,0,
137,18,86,4,0,
137,18,87,4,0,
137,18,92,4,0,
137,18,94,4,0,
137,18,97,1,12,
137,18,104,4,0,
137,18,105,1,18,
137,18,138,4,0,
137,18,156,4,0,
137,18,159,1,1,4
137,18,160,1,1,3
137,18,161,1,50,
137,18,164,4,0,
137,18,168,4,0,
137,18,176,1,1,1
137,18,182,4,0,
137,18,192,1,62,
137,18,199,1,45,
137,18,207,4,0,
137,18,214,4,0,
137,18,216,4,0,
137,18,218,4,0,
137,18,237,4,0,
137,18,240,4,0,
137,18,241,4,0,
137,18,244,4,0,
137,18,247,4,0,
137,18,263,4,0,
137,18,277,1,56,
137,18,278,1,34,
137,18,324,1,29,
137,18,332,4,0,
137,18,393,1,23,
137,18,416,4,0,
137,18,433,4,0,
137,18,435,1,40,
137,18,451,4,0,
137,18,473,4,0,
137,18,496,4,0,
137,18,590,4,0,
138,1,30,1,34,
138,1,34,4,0,
138,1,36,4,0,
138,1,38,4,0,
138,1,43,1,39,
138,1,55,1,1,1
138,1,55,4,0,
138,1,56,1,53,
138,1,57,4,0,
138,1,58,4,0,
138,1,59,4,0,
138,1,61,4,0,
138,1,92,4,0,
138,1,99,4,0,
138,1,102,4,0,
138,1,104,4,0,
138,1,110,1,1,2
138,1,115,4,0,
138,1,117,4,0,
138,1,131,1,46,
138,1,156,4,0,
138,1,164,4,0,
138,2,30,1,34,
138,2,34,4,0,
138,2,36,4,0,
138,2,38,4,0,
138,2,43,1,39,
138,2,55,1,1,1
138,2,55,4,0,
138,2,56,1,53,
138,2,57,4,0,
138,2,58,4,0,
138,2,59,4,0,
138,2,61,4,0,
138,2,92,4,0,
138,2,99,4,0,
138,2,102,4,0,
138,2,104,4,0,
138,2,110,1,1,2
138,2,115,4,0,
138,2,117,4,0,
138,2,131,1,46,
138,2,156,4,0,
138,2,164,4,0,
138,3,21,2,0,
138,3,29,4,0,
138,3,43,1,31,
138,3,44,1,13,
138,3,48,2,0,
138,3,55,1,19,
138,3,56,1,55,
138,3,57,4,0,
138,3,59,4,0,
138,3,61,2,0,
138,3,62,2,0,
138,3,92,4,0,
138,3,104,4,0,
138,3,110,1,1,2
138,3,114,2,0,
138,3,132,1,1,1
138,3,156,4,0,
138,3,168,4,0,
138,3,173,4,0,
138,3,174,4,0,
138,3,182,1,37,
138,3,182,4,0,
138,3,196,4,0,
138,3,201,4,0,
138,3,203,4,0,
138,3,205,4,0,
138,3,207,4,0,
138,3,213,4,0,
138,3,214,4,0,
138,3,216,4,0,
138,3,218,4,0,
138,3,237,4,0,
138,3,240,4,0,
138,3,246,1,49,
138,3,249,4,0,
138,3,250,4,0,
138,4,21,2,0,
138,4,29,4,0,
138,4,43,1,31,
138,4,44,1,13,
138,4,48,2,0,
138,4,55,1,19,
138,4,56,1,55,
138,4,57,4,0,
138,4,58,3,0,
138,4,59,4,0,
138,4,61,2,0,
138,4,62,2,0,
138,4,92,4,0,
138,4,104,4,0,
138,4,110,1,1,2
138,4,114,2,0,
138,4,132,1,1,1
138,4,156,4,0,
138,4,168,4,0,
138,4,173,4,0,
138,4,174,4,0,
138,4,182,1,37,
138,4,182,4,0,
138,4,196,4,0,
138,4,201,4,0,
138,4,203,4,0,
138,4,205,4,0,
138,4,207,4,0,
138,4,213,4,0,
138,4,214,4,0,
138,4,216,4,0,
138,4,218,4,0,
138,4,237,4,0,
138,4,240,4,0,
138,4,246,1,49,
138,4,249,4,0,
138,4,250,4,0,
138,5,21,2,0,
138,5,43,1,31,
138,5,44,1,13,
138,5,48,2,0,
138,5,55,1,19,
138,5,56,1,55,
138,5,57,4,0,
138,5,58,4,0,
138,5,59,4,0,
138,5,61,2,0,
138,5,62,2,0,
138,5,92,4,0,
138,5,104,4,0,
138,5,110,1,1,2
138,5,114,2,0,
138,5,127,4,0,
138,5,132,1,1,1
138,5,156,4,0,
138,5,157,2,0,
138,5,168,4,0,
138,5,182,1,37,
138,5,182,4,0,
138,5,191,2,0,
138,5,201,4,0,
138,5,213,4,0,
138,5,216,4,0,
138,5,218,4,0,
138,5,237,4,0,
138,5,240,4,0,
138,5,246,1,49,
138,5,249,4,0,
138,5,258,4,0,
138,5,263,4,0,
138,5,290,4,0,
138,5,291,4,0,
138,5,317,4,0,
138,5,321,1,43,
138,5,341,1,25,
138,5,352,4,0,
138,6,21,2,0,
138,6,34,3,0,
138,6,38,3,0,
138,6,43,1,31,
138,6,44,1,13,
138,6,48,2,0,
138,6,55,1,19,
138,6,56,1,55,
138,6,57,4,0,
138,6,58,4,0,
138,6,59,4,0,
138,6,61,2,0,
138,6,62,2,0,
138,6,92,4,0,
138,6,102,3,0,
138,6,104,4,0,
138,6,110,1,1,2
138,6,114,2,0,
138,6,127,4,0,
138,6,132,1,1,1
138,6,156,4,0,
138,6,157,2,0,
138,6,157,3,0,
138,6,164,3,0,
138,6,168,4,0,
138,6,173,3,0,
138,6,182,1,37,
138,6,182,4,0,
138,6,191,2,0,
138,6,196,3,0,
138,6,201,4,0,
138,6,203,3,0,
138,6,205,3,0,
138,6,207,3,0,
138,6,213,4,0,
138,6,214,3,0,
138,6,216,4,0,
138,6,218,4,0,
138,6,237,4,0,
138,6,240,4,0,
138,6,246,1,49,
138,6,249,4,0,
138,6,258,4,0,
138,6,263,4,0,
138,6,290,4,0,
138,6,291,4,0,
138,6,317,4,0,
138,6,321,1,43,
138,6,341,1,25,
138,6,352,4,0,
138,7,21,2,0,
138,7,34,3,0,
138,7,38,3,0,
138,7,43,1,31,
138,7,44,1,13,
138,7,48,2,0,
138,7,55,1,19,
138,7,56,1,55,
138,7,57,4,0,
138,7,58,4,0,
138,7,59,4,0,
138,7,61,2,0,
138,7,62,2,0,
138,7,92,4,0,
138,7,102,3,0,
138,7,104,4,0,
138,7,110,1,1,2
138,7,114,2,0,
138,7,127,4,0,
138,7,132,1,1,1
138,7,156,4,0,
138,7,157,2,0,
138,7,157,3,0,
138,7,164,3,0,
138,7,168,4,0,
138,7,182,1,37,
138,7,182,4,0,
138,7,191,2,0,
138,7,201,4,0,
138,7,213,4,0,
138,7,216,4,0,
138,7,218,4,0,
138,7,237,4,0,
138,7,240,4,0,
138,7,246,1,49,
138,7,249,4,0,
138,7,258,4,0,
138,7,263,4,0,
138,7,290,4,0,
138,7,291,4,0,
138,7,317,4,0,
138,7,321,1,43,
138,7,341,1,25,
138,7,352,4,0,
138,8,21,2,0,
138,8,43,1,19,
138,8,44,1,7,
138,8,48,2,0,
138,8,55,1,10,
138,8,56,1,52,
138,8,57,4,0,
138,8,58,4,0,
138,8,59,4,0,
138,8,61,2,0,
138,8,62,2,0,
138,8,92,4,0,
138,8,104,4,0,
138,8,110,1,1,2
138,8,114,2,0,
138,8,127,4,0,
138,8,132,1,1,1
138,8,156,4,0,
138,8,157,2,0,
138,8,157,4,0,
138,8,164,4,0,
138,8,168,4,0,
138,8,182,1,34,
138,8,182,4,0,
138,8,191,2,0,
138,8,201,4,0,
138,8,203,4,0,
138,8,205,1,16,
138,8,207,4,0,
138,8,213,4,0,
138,8,214,4,0,
138,8,216,4,0,
138,8,218,4,0,
138,8,237,4,0,
138,8,240,4,0,
138,8,246,1,37,
138,8,249,4,0,
138,8,258,4,0,
138,8,263,4,0,
138,8,282,2,0,
138,8,290,4,0,
138,8,317,4,0,
138,8,321,1,43,
138,8,341,1,25,
138,8,350,1,46,
138,8,352,4,0,
138,8,360,4,0,
138,8,362,1,28,
138,8,362,4,0,
138,8,363,4,0,
138,8,378,2,0,
138,8,390,2,0,
138,8,397,4,0,
138,8,445,4,0,
138,8,446,4,0,
138,9,21,2,0,
138,9,43,1,19,
138,9,44,1,7,
138,9,48,2,0,
138,9,55,1,10,
138,9,56,1,52,
138,9,57,4,0,
138,9,58,4,0,
138,9,59,4,0,
138,9,61,2,0,
138,9,62,2,0,
138,9,92,4,0,
138,9,104,4,0,
138,9,110,1,1,2
138,9,114,2,0,
138,9,127,4,0,
138,9,132,1,1,1
138,9,156,4,0,
138,9,157,2,0,
138,9,157,4,0,
138,9,164,4,0,
138,9,168,4,0,
138,9,173,3,0,
138,9,182,1,34,
138,9,182,4,0,
138,9,191,2,0,
138,9,196,3,0,
138,9,201,4,0,
138,9,203,4,0,
138,9,205,1,16,
138,9,205,3,0,
138,9,207,4,0,
138,9,213,4,0,
138,9,214,4,0,
138,9,216,4,0,
138,9,218,4,0,
138,9,237,4,0,
138,9,240,4,0,
138,9,246,1,37,
138,9,246,3,0,
138,9,249,4,0,
138,9,258,4,0,
138,9,263,4,0,
138,9,282,2,0,
138,9,282,3,0,
138,9,290,4,0,
138,9,291,3,0,
138,9,317,4,0,
138,9,321,1,43,
138,9,334,3,0,
138,9,341,1,25,
138,9,350,1,46,
138,9,352,4,0,
138,9,360,4,0,
138,9,362,1,28,
138,9,362,4,0,
138,9,363,4,0,
138,9,378,2,0,
138,9,390,2,0,
138,9,397,4,0,
138,9,414,3,0,
138,9,445,4,0,
138,9,446,4,0,
138,10,21,2,0,
138,10,29,3,0,
138,10,43,1,19,
138,10,44,1,7,
138,10,48,2,0,
138,10,55,1,10,
138,10,56,1,52,
138,10,57,4,0,
138,10,58,4,0,
138,10,59,4,0,
138,10,61,2,0,
138,10,62,2,0,
138,10,92,4,0,
138,10,104,4,0,
138,10,110,1,1,2
138,10,114,2,0,
138,10,127,4,0,
138,10,132,1,1,1
138,10,156,4,0,
138,10,157,2,0,
138,10,157,4,0,
138,10,164,4,0,
138,10,168,4,0,
138,10,173,3,0,
138,10,182,1,34,
138,10,182,4,0,
138,10,191,2,0,
138,10,196,3,0,
138,10,201,4,0,
138,10,203,4,0,
138,10,205,1,16,
138,10,205,3,0,
138,10,207,4,0,
138,10,213,4,0,
138,10,214,4,0,
138,10,216,4,0,
138,10,218,4,0,
138,10,237,4,0,
138,10,240,4,0,
138,10,246,1,37,
138,10,246,3,0,
138,10,249,4,0,
138,10,250,4,0,
138,10,258,4,0,
138,10,263,4,0,
138,10,282,2,0,
138,10,282,3,0,
138,10,290,4,0,
138,10,291,3,0,
138,10,317,4,0,
138,10,321,1,43,
138,10,330,2,0,
138,10,334,3,0,
138,10,341,1,25,
138,10,350,1,46,
138,10,352,4,0,
138,10,360,4,0,
138,10,362,1,28,
138,10,362,4,0,
138,10,363,4,0,
138,10,378,2,0,
138,10,390,2,0,
138,10,397,4,0,
138,10,414,3,0,
138,10,445,4,0,
138,10,446,4,0,
138,11,21,2,0,
138,11,43,1,19,
138,11,44,1,7,
138,11,48,2,0,
138,11,55,1,10,
138,11,56,1,55,
138,11,57,4,0,
138,11,58,4,0,
138,11,59,4,0,
138,11,61,2,0,
138,11,62,2,0,
138,11,92,4,0,
138,11,104,4,0,
138,11,110,1,1,2
138,11,114,2,0,
138,11,117,2,0,
138,11,127,4,0,
138,11,132,1,1,1
138,11,156,4,0,
138,11,157,4,0,
138,11,164,4,0,
138,11,168,4,0,
138,11,182,1,34,
138,11,182,4,0,
138,11,191,2,0,
138,11,201,4,0,
138,11,205,1,16,
138,11,207,4,0,
138,11,213,4,0,
138,11,216,4,0,
138,11,218,4,0,
138,11,237,4,0,
138,11,240,4,0,
138,11,246,1,37,
138,11,249,4,0,
138,11,250,2,0,
138,11,258,4,0,
138,11,263,4,0,
138,11,282,2,0,
138,11,291,4,0,
138,11,317,4,0,
138,11,321,1,43,
138,11,330,2,0,
138,11,341,1,25,
138,11,350,1,46,
138,11,352,2,0,
138,11,360,4,0,
138,11,362,1,28,
138,11,378,2,0,
138,11,390,2,0,
138,11,397,4,0,
138,11,479,4,0,
138,11,496,4,0,
138,11,503,4,0,
138,11,504,1,52,
138,12,43,1,31,
138,12,44,1,13,
138,12,55,1,19,
138,12,56,1,55,
138,12,57,4,0,
138,12,58,4,0,
138,12,59,4,0,
138,12,92,4,0,
138,12,104,4,0,
138,12,110,1,1,2
138,12,127,4,0,
138,12,132,1,1,1
138,12,156,4,0,
138,12,168,4,0,
138,12,182,1,37,
138,12,182,4,0,
138,12,201,4,0,
138,12,213,4,0,
138,12,216,4,0,
138,12,218,4,0,
138,12,237,4,0,
138,12,240,4,0,
138,12,246,1,49,
138,12,249,4,0,
138,12,258,4,0,
138,12,263,4,0,
138,12,290,4,0,
138,12,291,4,0,
138,12,317,4,0,
138,12,321,1,43,
138,12,341,1,25,
138,12,352,4,0,
138,13,34,3,0,
138,13,38,3,0,
138,13,43,1,31,
138,13,44,1,13,
138,13,55,1,19,
138,13,56,1,55,
138,13,57,4,0,
138,13,58,4,0,
138,13,59,4,0,
138,13,92,4,0,
138,13,102,3,0,
138,13,104,4,0,
138,13,110,1,1,2
138,13,127,4,0,
138,13,132,1,1,1
138,13,156,4,0,
138,13,164,3,0,
138,13,168,4,0,
138,13,182,1,37,
138,13,182,4,0,
138,13,196,3,0,
138,13,201,4,0,
138,13,207,3,0,
138,13,213,4,0,
138,13,216,4,0,
138,13,218,4,0,
138,13,237,4,0,
138,13,240,4,0,
138,13,246,1,49,
138,13,249,4,0,
138,13,258,4,0,
138,13,263,4,0,
138,13,290,4,0,
138,13,291,4,0,
138,13,317,4,0,
138,13,321,1,43,
138,13,341,1,25,
138,13,352,4,0,
138,14,20,3,0,
138,14,21,2,0,
138,14,43,1,19,
138,14,44,1,7,
138,14,48,2,0,
138,14,55,1,10,
138,14,56,1,55,
138,14,57,4,0,
138,14,58,4,0,
138,14,59,4,0,
138,14,61,2,0,
138,14,62,2,0,
138,14,92,4,0,
138,14,104,4,0,
138,14,110,1,1,2
138,14,114,2,0,
138,14,117,2,0,
138,14,127,4,0,
138,14,132,1,1,1
138,14,156,4,0,
138,14,157,4,0,
138,14,164,4,0,
138,14,168,4,0,
138,14,173,3,0,
138,14,182,1,34,
138,14,182,4,0,
138,14,191,2,0,
138,14,196,3,0,
138,14,201,4,0,
138,14,205,1,16,
138,14,207,4,0,
138,14,213,4,0,
138,14,214,3,0,
138,14,216,4,0,
138,14,218,4,0,
138,14,237,4,0,
138,14,240,4,0,
138,14,246,1,37,
138,14,249,4,0,
138,14,250,2,0,
138,14,258,4,0,
138,14,263,4,0,
138,14,282,2,0,
138,14,282,3,0,
138,14,291,4,0,
138,14,317,4,0,
138,14,321,1,43,
138,14,330,2,0,
138,14,334,3,0,
138,14,341,1,25,
138,14,350,1,46,
138,14,352,2,0,
138,14,360,4,0,
138,14,362,1,28,
138,14,378,2,0,
138,14,390,2,0,
138,14,397,4,0,
138,14,414,3,0,
138,14,446,3,0,
138,14,479,4,0,
138,14,496,4,0,
138,14,503,4,0,
138,14,504,1,52,
138,15,21,2,0,
138,15,43,1,19,
138,15,44,1,7,
138,15,48,2,0,
138,15,55,1,10,
138,15,56,1,55,
138,15,57,4,0,
138,15,58,4,0,
138,15,59,4,0,
138,15,61,2,0,
138,15,62,2,0,
138,15,92,4,0,
138,15,104,4,0,
138,15,110,1,1,2
138,15,114,2,0,
138,15,117,2,0,
138,15,127,4,0,
138,15,132,1,1,1
138,15,156,4,0,
138,15,157,4,0,
138,15,164,4,0,
138,15,168,4,0,
138,15,182,1,34,
138,15,182,4,0,
138,15,191,2,0,
138,15,201,4,0,
138,15,205,1,16,
138,15,207,4,0,
138,15,213,4,0,
138,15,214,4,0,
138,15,216,4,0,
138,15,218,4,0,
138,15,237,4,0,
138,15,240,4,0,
138,15,246,1,37,
138,15,249,4,0,
138,15,250,2,0,
138,15,258,4,0,
138,15,263,4,0,
138,15,282,2,0,
138,15,317,4,0,
138,15,321,1,43,
138,15,330,2,0,
138,15,341,1,25,
138,15,350,1,46,
138,15,352,2,0,
138,15,360,4,0,
138,15,362,1,28,
138,15,378,2,0,
138,15,390,2,0,
138,15,397,4,0,
138,15,479,4,0,
138,15,496,4,0,
138,15,503,4,0,
138,15,504,1,50,
138,15,513,2,0,
138,15,590,4,0,
138,16,20,3,0,
138,16,21,2,0,
138,16,43,1,19,1
138,16,44,1,7,1
138,16,48,2,0,
138,16,55,1,10,1
138,16,56,1,55,1
138,16,57,4,0,
138,16,58,4,0,
138,16,59,4,0,
138,16,61,2,0,
138,16,62,2,0,
138,16,92,4,0,
138,16,104,4,0,
138,16,110,1,1,2
138,16,114,2,0,
138,16,117,2,0,
138,16,127,4,0,
138,16,132,1,1,1
138,16,156,4,0,
138,16,157,4,0,
138,16,164,4,0,
138,16,168,4,0,
138,16,173,3,0,
138,16,182,1,34,1
138,16,182,4,0,
138,16,191,2,0,
138,16,196,3,0,
138,16,201,4,0,
138,16,205,1,16,1
138,16,207,4,0,
138,16,213,4,0,
138,16,214,4,0,
138,16,216,4,0,
138,16,218,4,0,
138,16,237,4,0,
138,16,240,4,0,
138,16,246,1,37,1
138,16,249,4,0,
138,16,250,2,0,
138,16,258,4,0,
138,16,263,4,0,
138,16,282,2,0,
138,16,282,3,0,
138,16,290,4,0,
138,16,291,4,0,
138,16,317,4,0,
138,16,321,1,43,1
138,16,330,2,0,
138,16,334,3,0,
138,16,341,1,25,1
138,16,350,1,46,1
138,16,352,2,0,
138,16,352,3,0,
138,16,360,4,0,
138,16,362,1,28,1
138,16,378,2,0,
138,16,390,2,0,
138,16,397,4,0,
138,16,414,3,0,
138,16,446,3,0,
138,16,479,4,0,
138,16,496,4,0,
138,16,503,4,0,
138,16,504,1,50,1
138,16,513,2,0,
138,16,590,4,0,
138,17,21,2,0,
138,17,43,1,19,
138,17,44,1,7,
138,17,48,2,0,
138,17,55,1,10,
138,17,56,1,55,
138,17,57,4,0,
138,17,58,4,0,
138,17,59,4,0,
138,17,61,2,0,
138,17,62,2,0,
138,17,92,4,0,
138,17,104,4,0,
138,17,110,1,1,2
138,17,114,2,0,
138,17,117,2,0,
138,17,127,4,0,
138,17,132,1,1,1
138,17,156,4,0,
138,17,157,4,0,
138,17,164,4,0,
138,17,168,4,0,
138,17,182,1,34,
138,17,182,4,0,
138,17,191,2,0,
138,17,201,4,0,
138,17,205,1,16,
138,17,207,4,0,
138,17,213,4,0,
138,17,214,4,0,
138,17,216,4,0,
138,17,218,4,0,
138,17,237,4,0,
138,17,240,4,0,
138,17,246,1,37,
138,17,250,2,0,
138,17,258,4,0,
138,17,263,4,0,
138,17,282,2,0,
138,17,317,4,0,
138,17,321,1,43,
138,17,330,2,0,
138,17,341,1,25,
138,17,350,1,46,
138,17,352,2,0,
138,17,360,4,0,
138,17,362,1,28,
138,17,378,2,0,
138,17,390,2,0,
138,17,397,4,0,
138,17,479,4,0,
138,17,496,4,0,
138,17,503,4,0,
138,17,504,1,50,
138,17,513,2,0,
138,17,590,4,0,
138,18,21,2,0,
138,18,43,1,19,
138,18,44,1,7,
138,18,48,2,0,
138,18,55,1,10,
138,18,56,1,55,
138,18,57,4,0,
138,18,58,4,0,
138,18,59,4,0,
138,18,61,2,0,
138,18,62,2,0,
138,18,92,4,0,
138,18,104,4,0,
138,18,110,1,1,2
138,18,114,2,0,
138,18,117,2,0,
138,18,127,4,0,
138,18,132,1,1,1
138,18,156,4,0,
138,18,157,4,0,
138,18,164,4,0,
138,18,168,4,0,
138,18,182,1,34,
138,18,182,4,0,
138,18,191,2,0,
138,18,201,4,0,
138,18,205,1,16,
138,18,207,4,0,
138,18,213,4,0,
138,18,214,4,0,
138,18,216,4,0,
138,18,218,4,0,
138,18,237,4,0,
138,18,240,4,0,
138,18,246,1,37,
138,18,250,2,0,
138,18,258,4,0,
138,18,263,4,0,
138,18,282,2,0,
138,18,317,4,0,
138,18,321,1,43,
138,18,330,2,0,
138,18,341,1,25,
138,18,350,1,46,
138,18,352,2,0,
138,18,360,4,0,
138,18,362,1,28,
138,18,378,2,0,
138,18,390,2,0,
138,18,397,4,0,
138,18,479,4,0,
138,18,496,4,0,
138,18,503,4,0,
138,18,504,1,50,
138,18,513,2,0,
138,18,590,4,0,
139,1,30,1,1,3
139,1,30,1,34,
139,1,32,4,0,
139,1,34,4,0,
139,1,36,4,0,
139,1,38,4,0,
139,1,43,1,39,
139,1,55,1,1,1
139,1,55,4,0,
139,1,56,1,49,
139,1,57,4,0,
139,1,58,4,0,
139,1,59,4,0,
139,1,61,4,0,
139,1,63,4,0,
139,1,66,4,0,
139,1,69,4,0,
139,1,92,4,0,
139,1,99,4,0,
139,1,102,4,0,
139,1,104,4,0,
139,1,110,1,1,2
139,1,115,4,0,
139,1,117,4,0,
139,1,130,4,0,
139,1,131,1,44,
139,1,156,4,0,
139,1,164,4,0,
139,2,30,1,1,3
139,2,30,1,34,
139,2,32,4,0,
139,2,34,4,0,
139,2,36,4,0,
139,2,38,4,0,
139,2,43,1,39,
139,2,55,1,1,1
139,2,55,4,0,
139,2,56,1,49,
139,2,57,4,0,
139,2,58,4,0,
139,2,59,4,0,
139,2,61,4,0,
139,2,63,4,0,
139,2,66,4,0,
139,2,69,4,0,
139,2,92,4,0,
139,2,99,4,0,
139,2,102,4,0,
139,2,104,4,0,
139,2,110,1,1,2
139,2,115,4,0,
139,2,117,4,0,
139,2,130,4,0,
139,2,131,1,44,
139,2,156,4,0,
139,2,164,4,0,
139,3,29,4,0,
139,3,43,1,31,
139,3,44,1,1,3
139,3,44,1,13,
139,3,55,1,19,
139,3,56,1,65,
139,3,57,4,0,
139,3,59,4,0,
139,3,63,4,0,
139,3,92,4,0,
139,3,104,4,0,
139,3,110,1,1,2
139,3,131,1,40,
139,3,132,1,1,1
139,3,156,4,0,
139,3,168,4,0,
139,3,173,4,0,
139,3,174,4,0,
139,3,182,1,37,
139,3,182,4,0,
139,3,196,4,0,
139,3,201,4,0,
139,3,203,4,0,
139,3,205,4,0,
139,3,207,4,0,
139,3,213,4,0,
139,3,214,4,0,
139,3,216,4,0,
139,3,218,4,0,
139,3,237,4,0,
139,3,240,4,0,
139,3,246,1,54,
139,3,249,4,0,
139,3,250,4,0,
139,4,29,4,0,
139,4,43,1,31,
139,4,44,1,1,3
139,4,44,1,13,
139,4,55,1,19,
139,4,56,1,65,
139,4,57,4,0,
139,4,58,3,0,
139,4,59,4,0,
139,4,63,4,0,
139,4,92,4,0,
139,4,104,4,0,
139,4,110,1,1,2
139,4,131,1,40,
139,4,132,1,1,1
139,4,156,4,0,
139,4,168,4,0,
139,4,173,4,0,
139,4,174,4,0,
139,4,182,1,37,
139,4,182,4,0,
139,4,196,4,0,
139,4,201,4,0,
139,4,203,4,0,
139,4,205,4,0,
139,4,207,4,0,
139,4,213,4,0,
139,4,214,4,0,
139,4,216,4,0,
139,4,218,4,0,
139,4,237,4,0,
139,4,240,4,0,
139,4,246,1,54,
139,4,249,4,0,
139,4,250,4,0,
139,5,43,1,31,
139,5,44,1,1,3
139,5,44,1,13,
139,5,55,1,19,
139,5,56,1,65,
139,5,57,4,0,
139,5,58,4,0,
139,5,59,4,0,
139,5,63,4,0,
139,5,92,4,0,
139,5,104,4,0,
139,5,110,1,1,2
139,5,127,4,0,
139,5,131,1,40,
139,5,132,1,1,1
139,5,156,4,0,
139,5,168,4,0,
139,5,182,1,37,
139,5,182,4,0,
139,5,201,4,0,
139,5,213,4,0,
139,5,216,4,0,
139,5,218,4,0,
139,5,237,4,0,
139,5,240,4,0,
139,5,246,1,55,
139,5,249,4,0,
139,5,258,4,0,
139,5,263,4,0,
139,5,290,4,0,
139,5,291,4,0,
139,5,317,4,0,
139,5,321,1,46,
139,5,341,1,25,
139,5,352,4,0,
139,6,34,3,0,
139,6,38,3,0,
139,6,43,1,31,
139,6,44,1,1,3
139,6,44,1,13,
139,6,55,1,19,
139,6,56,1,65,
139,6,57,4,0,
139,6,58,4,0,
139,6,59,4,0,
139,6,63,4,0,
139,6,69,3,0,
139,6,92,4,0,
139,6,102,3,0,
139,6,104,4,0,
139,6,110,1,1,2
139,6,127,4,0,
139,6,131,1,40,
139,6,132,1,1,1
139,6,156,4,0,
139,6,157,3,0,
139,6,164,3,0,
139,6,168,4,0,
139,6,173,3,0,
139,6,182,1,37,
139,6,182,4,0,
139,6,196,3,0,
139,6,201,4,0,
139,6,203,3,0,
139,6,205,3,0,
139,6,207,3,0,
139,6,213,4,0,
139,6,214,3,0,
139,6,216,4,0,
139,6,218,4,0,
139,6,237,4,0,
139,6,240,4,0,
139,6,246,1,55,
139,6,249,4,0,
139,6,258,4,0,
139,6,263,4,0,
139,6,290,4,0,
139,6,291,4,0,
139,6,317,4,0,
139,6,321,1,46,
139,6,341,1,25,
139,6,352,4,0,
139,7,34,3,0,
139,7,38,3,0,
139,7,43,1,31,
139,7,44,1,1,3
139,7,44,1,13,
139,7,55,1,1,4
139,7,55,1,19,
139,7,56,1,65,
139,7,57,4,0,
139,7,58,4,0,
139,7,59,4,0,
139,7,63,4,0,
139,7,69,3,0,
139,7,92,4,0,
139,7,102,3,0,
139,7,104,4,0,
139,7,110,1,1,2
139,7,127,4,0,
139,7,131,1,40,
139,7,132,1,1,1
139,7,156,4,0,
139,7,157,3,0,
139,7,164,3,0,
139,7,168,4,0,
139,7,182,1,37,
139,7,182,4,0,
139,7,201,4,0,
139,7,213,4,0,
139,7,216,4,0,
139,7,218,4,0,
139,7,237,4,0,
139,7,240,4,0,
139,7,246,1,55,
139,7,249,4,0,
139,7,258,4,0,
139,7,263,4,0,
139,7,290,4,0,
139,7,291,4,0,
139,7,317,4,0,
139,7,321,1,46,
139,7,341,1,25,
139,7,352,4,0,
139,8,43,1,19,
139,8,44,1,1,3
139,8,44,1,7,
139,8,55,1,10,
139,8,56,1,67,
139,8,57,4,0,
139,8,58,4,0,
139,8,59,4,0,
139,8,63,4,0,
139,8,92,4,0,
139,8,104,4,0,
139,8,110,1,1,2
139,8,127,4,0,
139,8,131,1,40,
139,8,132,1,1,1
139,8,156,4,0,
139,8,157,4,0,
139,8,164,4,0,
139,8,168,4,0,
139,8,182,1,34,
139,8,182,4,0,
139,8,201,4,0,
139,8,203,4,0,
139,8,205,1,16,
139,8,207,4,0,
139,8,213,4,0,
139,8,214,4,0,
139,8,216,4,0,
139,8,218,4,0,
139,8,237,4,0,
139,8,240,4,0,
139,8,246,1,37,
139,8,249,4,0,
139,8,258,4,0,
139,8,263,4,0,
139,8,290,4,0,
139,8,317,4,0,
139,8,321,1,48,
139,8,341,1,25,
139,8,350,1,56,
139,8,352,4,0,
139,8,360,4,0,
139,8,362,1,28,
139,8,362,4,0,
139,8,363,4,0,
139,8,397,4,0,
139,8,416,4,0,
139,8,431,4,0,
139,8,444,4,0,
139,8,445,4,0,
139,8,446,4,0,
139,9,43,1,19,
139,9,44,1,1,3
139,9,44,1,7,
139,9,55,1,10,
139,9,56,1,67,
139,9,57,4,0,
139,9,58,4,0,
139,9,59,4,0,
139,9,63,4,0,
139,9,92,4,0,
139,9,104,4,0,
139,9,110,1,1,2
139,9,127,4,0,
139,9,131,1,40,
139,9,132,1,1,1
139,9,156,4,0,
139,9,157,4,0,
139,9,164,4,0,
139,9,168,4,0,
139,9,173,3,0,
139,9,182,1,34,
139,9,182,4,0,
139,9,196,3,0,
139,9,201,4,0,
139,9,203,4,0,
139,9,205,1,16,
139,9,205,3,0,
139,9,207,4,0,
139,9,213,4,0,
139,9,214,4,0,
139,9,216,4,0,
139,9,218,4,0,
139,9,237,4,0,
139,9,240,4,0,
139,9,246,1,37,
139,9,246,3,0,
139,9,249,4,0,
139,9,258,4,0,
139,9,263,4,0,
139,9,282,3,0,
139,9,290,4,0,
139,9,291,3,0,
139,9,317,4,0,
139,9,321,1,48,
139,9,334,3,0,
139,9,341,1,25,
139,9,350,1,56,
139,9,352,4,0,
139,9,360,4,0,
139,9,362,1,28,
139,9,362,4,0,
139,9,363,4,0,
139,9,397,4,0,
139,9,414,3,0,
139,9,416,4,0,
139,9,431,4,0,
139,9,444,4,0,
139,9,445,4,0,
139,9,446,4,0,
139,10,29,3,0,
139,10,43,1,19,
139,10,44,1,1,3
139,10,44,1,7,
139,10,55,1,10,
139,10,56,1,67,
139,10,57,4,0,
139,10,58,4,0,
139,10,59,4,0,
139,10,63,4,0,
139,10,92,4,0,
139,10,104,4,0,
139,10,110,1,1,2
139,10,127,4,0,
139,10,131,1,40,
139,10,132,1,1,1
139,10,156,4,0,
139,10,157,4,0,
139,10,164,4,0,
139,10,168,4,0,
139,10,173,3,0,
139,10,182,1,34,
139,10,182,4,0,
139,10,196,3,0,
139,10,201,4,0,
139,10,203,4,0,
139,10,205,1,16,
139,10,205,3,0,
139,10,207,4,0,
139,10,213,4,0,
139,10,214,4,0,
139,10,216,4,0,
139,10,218,4,0,
139,10,237,4,0,
139,10,240,4,0,
139,10,246,1,37,
139,10,246,3,0,
139,10,249,4,0,
139,10,250,4,0,
139,10,258,4,0,
139,10,263,4,0,
139,10,282,3,0,
139,10,290,4,0,
139,10,291,3,0,
139,10,317,4,0,
139,10,321,1,48,
139,10,334,3,0,
139,10,341,1,25,
139,10,350,1,56,
139,10,352,4,0,
139,10,360,4,0,
139,10,362,1,28,
139,10,362,4,0,
139,10,363,4,0,
139,10,397,4,0,
139,10,414,3,0,
139,10,416,4,0,
139,10,431,4,0,
139,10,444,4,0,
139,10,445,4,0,
139,10,446,4,0,
139,11,43,1,19,
139,11,44,1,1,3
139,11,44,1,7,
139,11,55,1,10,
139,11,56,1,75,
139,11,57,4,0,
139,11,58,4,0,
139,11,59,4,0,
139,11,63,4,0,
139,11,92,4,0,
139,11,104,4,0,
139,11,110,1,1,2
139,11,127,4,0,
139,11,131,1,40,
139,11,132,1,1,1
139,11,156,4,0,
139,11,157,4,0,
139,11,164,4,0,
139,11,168,4,0,
139,11,182,1,34,
139,11,182,4,0,
139,11,201,4,0,
139,11,205,1,16,
139,11,207,4,0,
139,11,213,4,0,
139,11,216,4,0,
139,11,218,4,0,
139,11,237,4,0,
139,11,240,4,0,
139,11,246,1,37,
139,11,249,4,0,
139,11,258,4,0,
139,11,263,4,0,
139,11,291,4,0,
139,11,317,4,0,
139,11,321,1,48,
139,11,341,1,25,
139,11,350,1,56,
139,11,360,4,0,
139,11,362,1,28,
139,11,397,4,0,
139,11,416,4,0,
139,11,444,4,0,
139,11,479,4,0,
139,11,496,4,0,
139,11,503,4,0,
139,11,504,1,67,
139,12,43,1,31,
139,12,44,1,1,3
139,12,44,1,13,
139,12,55,1,19,
139,12,56,1,65,
139,12,57,4,0,
139,12,58,4,0,
139,12,59,4,0,
139,12,63,4,0,
139,12,92,4,0,
139,12,104,4,0,
139,12,110,1,1,2
139,12,127,4,0,
139,12,131,1,40,
139,12,132,1,1,1
139,12,156,4,0,
139,12,168,4,0,
139,12,182,1,37,
139,12,182,4,0,
139,12,201,4,0,
139,12,213,4,0,
139,12,216,4,0,
139,12,218,4,0,
139,12,237,4,0,
139,12,240,4,0,
139,12,246,1,55,
139,12,249,4,0,
139,12,258,4,0,
139,12,263,4,0,
139,12,290,4,0,
139,12,291,4,0,
139,12,317,4,0,
139,12,321,1,46,
139,12,341,1,25,
139,12,352,4,0,
139,13,34,3,0,
139,13,38,3,0,
139,13,43,1,31,
139,13,44,1,1,3
139,13,44,1,13,
139,13,55,1,19,
139,13,56,1,65,
139,13,57,4,0,
139,13,58,4,0,
139,13,59,4,0,
139,13,63,4,0,
139,13,69,3,0,
139,13,92,4,0,
139,13,102,3,0,
139,13,104,4,0,
139,13,110,1,1,2
139,13,127,4,0,
139,13,131,1,40,
139,13,132,1,1,1
139,13,156,4,0,
139,13,164,3,0,
139,13,168,4,0,
139,13,182,1,37,
139,13,182,4,0,
139,13,196,3,0,
139,13,201,4,0,
139,13,207,3,0,
139,13,213,4,0,
139,13,216,4,0,
139,13,218,4,0,
139,13,237,4,0,
139,13,240,4,0,
139,13,246,1,55,
139,13,249,4,0,
139,13,258,4,0,
139,13,263,4,0,
139,13,290,4,0,
139,13,291,4,0,
139,13,317,4,0,
139,13,321,1,46,
139,13,341,1,25,
139,13,352,4,0,
139,14,20,3,0,
139,14,43,1,19,
139,14,44,1,1,3
139,14,44,1,7,
139,14,55,1,10,
139,14,56,1,75,
139,14,57,4,0,
139,14,58,4,0,
139,14,59,4,0,
139,14,63,4,0,
139,14,92,4,0,
139,14,104,4,0,
139,14,110,1,1,2
139,14,127,4,0,
139,14,131,1,40,
139,14,132,1,1,1
139,14,156,4,0,
139,14,157,4,0,
139,14,164,4,0,
139,14,168,4,0,
139,14,173,3,0,
139,14,182,1,34,
139,14,182,4,0,
139,14,196,3,0,
139,14,201,4,0,
139,14,205,1,16,
139,14,207,4,0,
139,14,213,4,0,
139,14,214,3,0,
139,14,216,4,0,
139,14,218,4,0,
139,14,237,4,0,
139,14,240,4,0,
139,14,246,1,37,
139,14,249,4,0,
139,14,258,4,0,
139,14,263,4,0,
139,14,282,3,0,
139,14,291,4,0,
139,14,317,4,0,
139,14,321,1,48,
139,14,334,3,0,
139,14,341,1,25,
139,14,350,1,56,
139,14,360,4,0,
139,14,362,1,28,
139,14,397,4,0,
139,14,414,3,0,
139,14,416,4,0,
139,14,444,4,0,
139,14,446,3,0,
139,14,479,4,0,
139,14,496,4,0,
139,14,503,4,0,
139,14,504,1,67,
139,15,43,1,19,
139,15,44,1,1,4
139,15,44,1,7,
139,15,55,1,10,
139,15,56,1,1,1
139,15,56,1,75,
139,15,57,4,0,
139,15,58,4,0,
139,15,59,4,0,
139,15,63,4,0,
139,15,92,4,0,
139,15,104,4,0,
139,15,110,1,1,3
139,15,127,4,0,
139,15,131,1,40,
139,15,132,1,1,2
139,15,156,4,0,
139,15,157,4,0,
139,15,164,4,0,
139,15,168,4,0,
139,15,182,1,34,
139,15,182,4,0,
139,15,201,4,0,
139,15,205,1,16,
139,15,207,4,0,
139,15,213,4,0,
139,15,214,4,0,
139,15,216,4,0,
139,15,218,4,0,
139,15,237,4,0,
139,15,240,4,0,
139,15,246,1,37,
139,15,249,4,0,
139,15,258,4,0,
139,15,263,4,0,
139,15,317,4,0,
139,15,321,1,48,
139,15,341,1,25,
139,15,350,1,56,
139,15,360,4,0,
139,15,362,1,28,
139,15,397,4,0,
139,15,416,4,0,
139,15,444,4,0,
139,15,479,4,0,
139,15,496,4,0,
139,15,503,4,0,
139,15,504,1,67,
139,15,590,4,0,
139,16,20,3,0,
139,16,43,1,19,1
139,16,44,1,1,4
139,16,44,1,7,1
139,16,55,1,10,1
139,16,56,1,1,1
139,16,56,1,75,1
139,16,57,4,0,
139,16,58,4,0,
139,16,59,4,0,
139,16,63,4,0,
139,16,92,4,0,
139,16,104,4,0,
139,16,110,1,1,3
139,16,127,4,0,
139,16,131,1,40,1
139,16,132,1,1,2
139,16,156,4,0,
139,16,157,4,0,
139,16,164,4,0,
139,16,168,4,0,
139,16,173,3,0,
139,16,182,1,34,1
139,16,182,4,0,
139,16,196,3,0,
139,16,201,4,0,
139,16,205,1,16,1
139,16,207,4,0,
139,16,213,4,0,
139,16,214,4,0,
139,16,216,4,0,
139,16,218,4,0,
139,16,237,4,0,
139,16,240,4,0,
139,16,246,1,37,1
139,16,249,4,0,
139,16,258,4,0,
139,16,263,4,0,
139,16,282,3,0,
139,16,290,4,0,
139,16,291,4,0,
139,16,317,4,0,
139,16,321,1,48,1
139,16,334,3,0,
139,16,341,1,25,1
139,16,350,1,56,1
139,16,352,3,0,
139,16,360,4,0,
139,16,362,1,28,1
139,16,397,4,0,
139,16,414,3,0,
139,16,416,4,0,
139,16,444,4,0,
139,16,446,3,0,
139,16,479,4,0,
139,16,496,4,0,
139,16,503,4,0,
139,16,504,1,67,1
139,16,590,4,0,
139,17,43,1,19,
139,17,44,1,1,5
139,17,44,1,7,
139,17,55,1,10,
139,17,56,1,1,2
139,17,56,1,75,
139,17,57,4,0,
139,17,58,4,0,
139,17,59,4,0,
139,17,63,4,0,
139,17,92,4,0,
139,17,104,4,0,
139,17,110,1,1,4
139,17,127,4,0,
139,17,131,1,0,
139,17,131,1,1,1
139,17,132,1,1,3
139,17,156,4,0,
139,17,157,4,0,
139,17,164,4,0,
139,17,168,4,0,
139,17,182,1,34,
139,17,182,4,0,
139,17,201,4,0,
139,17,205,1,16,
139,17,207,4,0,
139,17,213,4,0,
139,17,214,4,0,
139,17,216,4,0,
139,17,218,4,0,
139,17,237,4,0,
139,17,240,4,0,
139,17,246,1,37,
139,17,258,4,0,
139,17,263,4,0,
139,17,317,4,0,
139,17,321,1,48,
139,17,341,1,25,
139,17,350,1,56,
139,17,360,4,0,
139,17,362,1,28,
139,17,397,4,0,
139,17,416,4,0,
139,17,444,4,0,
139,17,479,4,0,
139,17,496,4,0,
139,17,503,4,0,
139,17,504,1,67,
139,17,590,4,0,
139,18,43,1,19,
139,18,44,1,1,5
139,18,44,1,7,
139,18,55,1,10,
139,18,56,1,1,2
139,18,56,1,75,
139,18,57,4,0,
139,18,58,4,0,
139,18,59,4,0,
139,18,63,4,0,
139,18,92,4,0,
139,18,104,4,0,
139,18,110,1,1,4
139,18,127,4,0,
139,18,131,1,0,
139,18,131,1,1,1
139,18,132,1,1,3
139,18,156,4,0,
139,18,157,4,0,
139,18,164,4,0,
139,18,168,4,0,
139,18,182,1,34,
139,18,182,4,0,
139,18,201,4,0,
139,18,205,1,16,
139,18,207,4,0,
139,18,213,4,0,
139,18,214,4,0,
139,18,216,4,0,
139,18,218,4,0,
139,18,237,4,0,
139,18,240,4,0,
139,18,246,1,37,
139,18,258,4,0,
139,18,263,4,0,
139,18,317,4,0,
139,18,321,1,48,
139,18,341,1,25,
139,18,350,1,56,
139,18,360,4,0,
139,18,362,1,28,
139,18,397,4,0,
139,18,416,4,0,
139,18,444,4,0,
139,18,479,4,0,
139,18,496,4,0,
139,18,503,4,0,
139,18,504,1,67,
139,18,590,4,0,
140,1,10,1,1,1
140,1,34,4,0,
140,1,36,4,0,
140,1,38,4,0,
140,1,43,1,44,
140,1,55,4,0,
140,1,56,1,49,
140,1,57,4,0,
140,1,58,4,0,
140,1,59,4,0,
140,1,61,4,0,
140,1,71,1,34,
140,1,92,4,0,
140,1,99,4,0,
140,1,102,4,0,
140,1,104,4,0,
140,1,106,1,1,2
140,1,115,4,0,
140,1,117,4,0,
140,1,156,4,0,
140,1,163,1,39,
140,1,164,4,0,
140,2,10,1,1,1
140,2,34,4,0,
140,2,36,4,0,
140,2,38,4,0,
140,2,43,1,44,
140,2,55,4,0,
140,2,56,1,49,
140,2,57,4,0,
140,2,58,4,0,
140,2,59,4,0,
140,2,61,4,0,
140,2,71,1,34,
140,2,92,4,0,
140,2,99,4,0,
140,2,102,4,0,
140,2,104,4,0,
140,2,106,1,1,2
140,2,115,4,0,
140,2,117,4,0,
140,2,156,4,0,
140,2,163,1,39,
140,2,164,4,0,
140,3,10,1,1,1
140,3,28,1,28,
140,3,43,1,19,
140,3,59,4,0,
140,3,61,2,0,
140,3,62,2,0,
140,3,71,1,10,
140,3,72,1,46,
140,3,91,2,0,
140,3,92,4,0,
140,3,104,4,0,
140,3,106,1,1,2
140,3,156,4,0,
140,3,168,4,0,
140,3,173,4,0,
140,3,174,4,0,
140,3,175,2,0,
140,3,182,4,0,
140,3,196,4,0,
140,3,201,4,0,
140,3,202,4,0,
140,3,203,1,37,
140,3,203,4,0,
140,3,205,4,0,
140,3,207,4,0,
140,3,213,4,0,
140,3,214,4,0,
140,3,216,4,0,
140,3,218,4,0,
140,3,229,2,0,
140,3,237,4,0,
140,3,240,4,0,
140,3,246,1,55,
140,3,249,4,0,
140,4,10,1,1,1
140,4,28,1,28,
140,4,43,1,19,
140,4,58,3,0,
140,4,59,4,0,
140,4,61,2,0,
140,4,62,2,0,
140,4,71,1,10,
140,4,72,1,46,
140,4,91,2,0,
140,4,92,4,0,
140,4,104,4,0,
140,4,106,1,1,2
140,4,156,4,0,
140,4,168,4,0,
140,4,173,4,0,
140,4,174,4,0,
140,4,175,2,0,
140,4,182,4,0,
140,4,196,4,0,
140,4,201,4,0,
140,4,202,4,0,
140,4,203,1,37,
140,4,203,4,0,
140,4,205,4,0,
140,4,207,4,0,
140,4,213,4,0,
140,4,214,4,0,
140,4,216,4,0,
140,4,218,4,0,
140,4,229,2,0,
140,4,237,4,0,
140,4,240,4,0,
140,4,246,1,55,
140,4,249,4,0,
140,5,10,1,1,1
140,5,28,1,31,
140,5,43,1,19,
140,5,57,4,0,
140,5,58,4,0,
140,5,59,4,0,
140,5,61,2,0,
140,5,62,2,0,
140,5,71,1,13,
140,5,72,1,49,
140,5,91,2,0,
140,5,91,4,0,
140,5,92,4,0,
140,5,104,4,0,
140,5,106,1,1,2
140,5,109,2,0,
140,5,127,4,0,
140,5,156,4,0,
140,5,168,4,0,
140,5,175,2,0,
140,5,182,4,0,
140,5,201,4,0,
140,5,202,4,0,
140,5,203,1,37,
140,5,213,4,0,
140,5,216,4,0,
140,5,218,4,0,
140,5,229,2,0,
140,5,237,4,0,
140,5,240,4,0,
140,5,246,1,55,
140,5,249,4,0,
140,5,258,4,0,
140,5,263,4,0,
140,5,282,2,0,
140,5,290,4,0,
140,5,317,4,0,
140,5,319,1,43,
140,5,332,4,0,
140,5,341,1,25,
140,5,352,4,0,
140,6,10,1,1,1
140,6,28,1,31,
140,6,34,3,0,
140,6,38,3,0,
140,6,43,1,19,
140,6,57,4,0,
140,6,58,4,0,
140,6,59,4,0,
140,6,61,2,0,
140,6,62,2,0,
140,6,71,1,13,
140,6,72,1,49,
140,6,91,2,0,
140,6,91,4,0,
140,6,92,4,0,
140,6,102,3,0,
140,6,104,4,0,
140,6,106,1,1,2
140,6,109,2,0,
140,6,127,4,0,
140,6,156,4,0,
140,6,157,3,0,
140,6,164,3,0,
140,6,168,4,0,
140,6,173,3,0,
140,6,175,2,0,
140,6,182,4,0,
140,6,196,3,0,
140,6,201,4,0,
140,6,202,4,0,
140,6,203,1,37,
140,6,203,3,0,
140,6,205,3,0,
140,6,207,3,0,
140,6,213,4,0,
140,6,214,3,0,
140,6,216,4,0,
140,6,218,4,0,
140,6,229,2,0,
140,6,237,4,0,
140,6,240,4,0,
140,6,246,1,55,
140,6,249,4,0,
140,6,258,4,0,
140,6,263,4,0,
140,6,282,2,0,
140,6,290,4,0,
140,6,317,4,0,
140,6,319,1,43,
140,6,332,4,0,
140,6,341,1,25,
140,6,352,4,0,
140,7,10,1,1,1
140,7,28,1,31,
140,7,34,3,0,
140,7,38,3,0,
140,7,43,1,19,
140,7,57,4,0,
140,7,58,4,0,
140,7,59,4,0,
140,7,61,2,0,
140,7,62,2,0,
140,7,71,1,13,
140,7,72,1,49,
140,7,91,2,0,
140,7,91,4,0,
140,7,92,4,0,
140,7,102,3,0,
140,7,104,4,0,
140,7,106,1,1,2
140,7,109,2,0,
140,7,127,4,0,
140,7,156,4,0,
140,7,157,3,0,
140,7,164,3,0,
140,7,168,4,0,
140,7,175,2,0,
140,7,182,4,0,
140,7,201,4,0,
140,7,202,4,0,
140,7,203,1,37,
140,7,213,4,0,
140,7,216,4,0,
140,7,218,4,0,
140,7,229,2,0,
140,7,237,4,0,
140,7,240,4,0,
140,7,246,1,55,
140,7,249,4,0,
140,7,258,4,0,
140,7,263,4,0,
140,7,282,2,0,
140,7,290,4,0,
140,7,317,4,0,
140,7,319,1,43,
140,7,332,4,0,
140,7,341,1,25,
140,7,352,4,0,
140,8,10,1,1,1
140,8,28,1,21,
140,8,43,1,11,
140,8,57,4,0,
140,8,58,4,0,
140,8,59,4,0,
140,8,61,2,0,
140,8,62,2,0,
140,8,71,1,6,
140,8,72,1,36,
140,8,91,2,0,
140,8,91,4,0,
140,8,92,4,0,
140,8,103,2,0,
140,8,104,4,0,
140,8,106,1,1,2
140,8,109,2,0,
140,8,127,4,0,
140,8,156,4,0,
140,8,157,4,0,
140,8,164,4,0,
140,8,168,4,0,
140,8,175,2,0,
140,8,182,4,0,
140,8,196,2,0,
140,8,201,4,0,
140,8,202,4,0,
140,8,203,1,26,
140,8,203,4,0,
140,8,207,4,0,
140,8,213,4,0,
140,8,214,4,0,
140,8,216,4,0,
140,8,218,4,0,
140,8,229,2,0,
140,8,237,4,0,
140,8,240,4,0,
140,8,246,1,46,
140,8,249,4,0,
140,8,258,4,0,
140,8,263,4,0,
140,8,282,2,0,
140,8,290,4,0,
140,8,317,4,0,
140,8,319,1,41,
140,8,341,1,16,
140,8,341,2,0,
140,8,352,4,0,
140,8,362,4,0,
140,8,363,4,0,
140,8,378,1,51,
140,8,397,4,0,
140,8,445,4,0,
140,8,446,4,0,
140,8,453,1,31,
140,9,10,1,1,1
140,9,28,1,21,
140,9,43,1,11,
140,9,57,4,0,
140,9,58,4,0,
140,9,59,4,0,
140,9,61,2,0,
140,9,62,2,0,
140,9,71,1,6,
140,9,72,1,36,
140,9,91,2,0,
140,9,91,4,0,
140,9,92,4,0,
140,9,103,2,0,
140,9,104,4,0,
140,9,106,1,1,2
140,9,109,2,0,
140,9,127,4,0,
140,9,156,4,0,
140,9,157,4,0,
140,9,164,4,0,
140,9,168,4,0,
140,9,173,3,0,
140,9,175,2,0,
140,9,182,4,0,
140,9,189,3,0,
140,9,196,2,0,
140,9,196,3,0,
140,9,201,4,0,
140,9,202,4,0,
140,9,203,1,26,
140,9,203,4,0,
140,9,205,3,0,
140,9,207,4,0,
140,9,213,4,0,
140,9,214,4,0,
140,9,216,4,0,
140,9,218,4,0,
140,9,229,2,0,
140,9,237,4,0,
140,9,240,4,0,
140,9,246,1,46,
140,9,246,3,0,
140,9,249,4,0,
140,9,258,4,0,
140,9,263,4,0,
140,9,282,2,0,
140,9,282,3,0,
140,9,290,4,0,
140,9,317,4,0,
140,9,319,1,41,
140,9,334,3,0,
140,9,341,1,16,
140,9,341,2,0,
140,9,352,4,0,
140,9,362,4,0,
140,9,363,4,0,
140,9,378,1,51,
140,9,397,4,0,
140,9,414,3,0,
140,9,445,4,0,
140,9,446,4,0,
140,9,453,1,31,
140,10,10,1,1,1
140,10,28,1,21,
140,10,43,1,11,
140,10,57,4,0,
140,10,58,4,0,
140,10,59,4,0,
140,10,61,2,0,
140,10,62,2,0,
140,10,71,1,6,
140,10,72,1,36,
140,10,91,2,0,
140,10,91,4,0,
140,10,92,4,0,
140,10,103,2,0,
140,10,104,4,0,
140,10,106,1,1,2
140,10,109,2,0,
140,10,127,4,0,
140,10,156,4,0,
140,10,157,4,0,
140,10,164,4,0,
140,10,168,4,0,
140,10,173,3,0,
140,10,175,2,0,
140,10,182,4,0,
140,10,189,3,0,
140,10,196,2,0,
140,10,196,3,0,
140,10,201,4,0,
140,10,202,4,0,
140,10,203,1,26,
140,10,203,4,0,
140,10,205,3,0,
140,10,207,4,0,
140,10,213,4,0,
140,10,214,4,0,
140,10,216,4,0,
140,10,218,4,0,
140,10,229,2,0,
140,10,237,4,0,
140,10,240,4,0,
140,10,246,1,46,
140,10,246,3,0,
140,10,249,4,0,
140,10,250,4,0,
140,10,258,4,0,
140,10,263,4,0,
140,10,282,2,0,
140,10,282,3,0,
140,10,290,4,0,
140,10,317,4,0,
140,10,319,1,41,
140,10,334,3,0,
140,10,341,1,16,
140,10,341,2,0,
140,10,352,4,0,
140,10,362,4,0,
140,10,363,4,0,
140,10,378,1,51,
140,10,397,4,0,
140,10,414,3,0,
140,10,445,4,0,
140,10,446,4,0,
140,10,453,1,31,
140,11,10,1,1,1
140,11,28,1,21,
140,11,43,1,11,
140,11,57,4,0,
140,11,58,4,0,
140,11,59,4,0,
140,11,61,2,0,
140,11,62,2,0,
140,11,71,1,6,
140,11,72,1,36,
140,11,91,4,0,
140,11,92,4,0,
140,11,103,2,0,
140,11,104,4,0,
140,11,106,1,1,2
140,11,109,2,0,
140,11,127,4,0,
140,11,156,4,0,
140,11,157,4,0,
140,11,164,4,0,
140,11,168,4,0,
140,11,175,2,0,
140,11,182,4,0,
140,11,193,2,0,
140,11,196,2,0,
140,11,201,4,0,
140,11,202,2,0,
140,11,203,1,26,
140,11,207,4,0,
140,11,213,4,0,
140,11,216,4,0,
140,11,218,4,0,
140,11,229,2,0,
140,11,237,4,0,
140,11,240,4,0,
140,11,246,1,46,
140,11,249,4,0,
140,11,258,4,0,
140,11,263,4,0,
140,11,282,2,0,
140,11,317,4,0,
140,11,319,1,41,
140,11,341,1,16,
140,11,341,2,0,
140,11,378,1,51,
140,11,397,4,0,
140,11,453,1,31,
140,11,468,4,0,
140,11,479,4,0,
140,11,496,4,0,
140,11,503,4,0,
140,12,10,1,1,1
140,12,28,1,31,
140,12,43,1,19,
140,12,57,4,0,
140,12,58,4,0,
140,12,59,4,0,
140,12,71,1,13,
140,12,72,1,49,
140,12,91,4,0,
140,12,92,4,0,
140,12,104,4,0,
140,12,106,1,1,2
140,12,127,4,0,
140,12,156,4,0,
140,12,168,4,0,
140,12,182,4,0,
140,12,201,4,0,
140,12,202,4,0,
140,12,203,1,37,
140,12,213,4,0,
140,12,216,4,0,
140,12,218,4,0,
140,12,237,4,0,
140,12,240,4,0,
140,12,246,1,55,
140,12,249,4,0,
140,12,258,4,0,
140,12,263,4,0,
140,12,290,4,0,
140,12,317,4,0,
140,12,319,1,43,
140,12,332,4,0,
140,12,341,1,25,
140,12,352,4,0,
140,13,10,1,1,1
140,13,28,1,31,
140,13,34,3,0,
140,13,38,3,0,
140,13,43,1,19,
140,13,57,4,0,
140,13,58,4,0,
140,13,59,4,0,
140,13,71,1,13,
140,13,72,1,49,
140,13,91,4,0,
140,13,92,4,0,
140,13,102,3,0,
140,13,104,4,0,
140,13,106,1,1,2
140,13,127,4,0,
140,13,156,4,0,
140,13,164,3,0,
140,13,168,4,0,
140,13,182,4,0,
140,13,196,3,0,
140,13,201,4,0,
140,13,202,4,0,
140,13,203,1,37,
140,13,207,3,0,
140,13,213,4,0,
140,13,216,4,0,
140,13,218,4,0,
140,13,237,4,0,
140,13,240,4,0,
140,13,246,1,55,
140,13,249,4,0,
140,13,258,4,0,
140,13,263,4,0,
140,13,290,4,0,
140,13,317,4,0,
140,13,319,1,43,
140,13,332,4,0,
140,13,341,1,25,
140,13,352,4,0,
140,14,10,1,1,1
140,14,28,1,21,
140,14,43,1,11,
140,14,57,4,0,
140,14,58,4,0,
140,14,59,4,0,
140,14,61,2,0,
140,14,62,2,0,
140,14,71,1,6,
140,14,72,1,36,
140,14,91,4,0,
140,14,92,4,0,
140,14,103,2,0,
140,14,104,4,0,
140,14,106,1,1,2
140,14,109,2,0,
140,14,127,4,0,
140,14,156,4,0,
140,14,157,4,0,
140,14,164,4,0,
140,14,168,4,0,
140,14,173,3,0,
140,14,175,2,0,
140,14,182,4,0,
140,14,193,2,0,
140,14,196,2,0,
140,14,196,3,0,
140,14,201,4,0,
140,14,202,2,0,
140,14,202,3,0,
140,14,203,1,26,
140,14,207,4,0,
140,14,213,4,0,
140,14,214,3,0,
140,14,216,4,0,
140,14,218,4,0,
140,14,229,2,0,
140,14,237,4,0,
140,14,240,4,0,
140,14,246,1,46,
140,14,249,4,0,
140,14,258,4,0,
140,14,263,4,0,
140,14,282,2,0,
140,14,282,3,0,
140,14,317,4,0,
140,14,319,1,41,
140,14,334,3,0,
140,14,341,1,16,
140,14,341,2,0,
140,14,378,1,51,
140,14,397,4,0,
140,14,414,3,0,
140,14,446,3,0,
140,14,453,1,31,
140,14,468,4,0,
140,14,479,4,0,
140,14,496,4,0,
140,14,503,4,0,
140,15,10,1,1,1
140,15,28,1,21,
140,15,36,2,0,
140,15,43,1,11,
140,15,57,4,0,
140,15,58,4,0,
140,15,59,4,0,
140,15,61,2,0,
140,15,62,2,0,
140,15,71,1,6,
140,15,72,1,36,
140,15,91,4,0,
140,15,92,4,0,
140,15,103,2,0,
140,15,104,4,0,
140,15,106,1,1,2
140,15,109,2,0,
140,15,127,4,0,
140,15,156,4,0,
140,15,157,4,0,
140,15,164,4,0,
140,15,168,4,0,
140,15,175,2,0,
140,15,182,4,0,
140,15,193,2,0,
140,15,196,2,0,
140,15,201,4,0,
140,15,202,2,0,
140,15,203,1,26,
140,15,207,4,0,
140,15,213,4,0,
140,15,214,4,0,
140,15,216,4,0,
140,15,218,4,0,
140,15,229,2,0,
140,15,237,4,0,
140,15,240,4,0,
140,15,246,1,46,
140,15,249,4,0,
140,15,258,4,0,
140,15,263,4,0,
140,15,282,2,0,
140,15,317,4,0,
140,15,319,1,41,
140,15,341,1,16,
140,15,341,2,0,
140,15,378,1,50,
140,15,397,4,0,
140,15,453,1,31,
140,15,468,4,0,
140,15,479,4,0,
140,15,496,4,0,
140,15,503,4,0,
140,15,590,4,0,
140,16,10,1,1,1
140,16,28,1,21,1
140,16,36,2,0,
140,16,43,1,11,1
140,16,57,4,0,
140,16,58,4,0,
140,16,59,4,0,
140,16,61,2,0,
140,16,62,2,0,
140,16,71,1,6,1
140,16,72,1,36,1
140,16,91,4,0,
140,16,92,4,0,
140,16,103,2,0,
140,16,104,4,0,
140,16,106,1,1,2
140,16,109,2,0,
140,16,127,4,0,
140,16,156,4,0,
140,16,157,4,0,
140,16,164,4,0,
140,16,168,4,0,
140,16,173,3,0,
140,16,175,2,0,
140,16,182,4,0,
140,16,193,2,0,
140,16,196,2,0,
140,16,196,3,0,
140,16,201,4,0,
140,16,202,2,0,
140,16,202,3,0,
140,16,203,1,26,1
140,16,207,4,0,
140,16,213,4,0,
140,16,214,4,0,
140,16,216,4,0,
140,16,218,4,0,
140,16,229,2,0,
140,16,237,4,0,
140,16,240,4,0,
140,16,246,1,46,1
140,16,249,4,0,
140,16,258,4,0,
140,16,263,4,0,
140,16,282,2,0,
140,16,282,3,0,
140,16,290,4,0,
140,16,317,4,0,
140,16,319,1,41,1
140,16,332,4,0,
140,16,334,3,0,
140,16,341,1,16,1
140,16,341,2,0,
140,16,352,3,0,
140,16,378,1,50,1
140,16,397,4,0,
140,16,414,3,0,
140,16,446,3,0,
140,16,453,1,31,1
140,16,468,4,0,
140,16,479,4,0,
140,16,496,4,0,
140,16,503,4,0,
140,16,590,4,0,
140,17,10,1,1,1
140,17,28,1,21,
140,17,36,2,0,
140,17,43,1,11,
140,17,57,4,0,
140,17,58,4,0,
140,17,59,4,0,
140,17,61,2,0,
140,17,62,2,0,
140,17,71,1,6,
140,17,72,1,36,
140,17,92,4,0,
140,17,103,2,0,
140,17,104,4,0,
140,17,106,1,1,2
140,17,109,2,0,
140,17,127,4,0,
140,17,156,4,0,
140,17,157,4,0,
140,17,164,4,0,
140,17,168,4,0,
140,17,175,2,0,
140,17,182,4,0,
140,17,193,2,0,
140,17,196,2,0,
140,17,201,4,0,
140,17,202,2,0,
140,17,203,1,26,
140,17,207,4,0,
140,17,213,4,0,
140,17,214,4,0,
140,17,216,4,0,
140,17,218,4,0,
140,17,229,2,0,
140,17,237,4,0,
140,17,240,4,0,
140,17,246,1,46,
140,17,258,4,0,
140,17,263,4,0,
140,17,282,2,0,
140,17,317,4,0,
140,17,319,1,41,
140,17,332,4,0,
140,17,341,1,16,
140,17,341,2,0,
140,17,378,1,50,
140,17,397,4,0,
140,17,453,1,31,
140,17,479,4,0,
140,17,496,4,0,
140,17,503,4,0,
140,17,590,4,0,
140,18,10,1,1,1
140,18,28,1,21,
140,18,36,2,0,
140,18,43,1,11,
140,18,57,4,0,
140,18,58,4,0,
140,18,59,4,0,
140,18,61,2,0,
140,18,62,2,0,
140,18,71,1,6,
140,18,72,1,36,
140,18,92,4,0,
140,18,103,2,0,
140,18,104,4,0,
140,18,106,1,1,2
140,18,109,2,0,
140,18,127,4,0,
140,18,156,4,0,
140,18,157,4,0,
140,18,164,4,0,
140,18,168,4,0,
140,18,175,2,0,
140,18,182,4,0,
140,18,193,2,0,
140,18,196,2,0,
140,18,201,4,0,
140,18,202,2,0,
140,18,203,1,26,
140,18,207,4,0,
140,18,213,4,0,
140,18,214,4,0,
140,18,216,4,0,
140,18,218,4,0,
140,18,229,2,0,
140,18,237,4,0,
140,18,240,4,0,
140,18,246,1,46,
140,18,258,4,0,
140,18,263,4,0,
140,18,282,2,0,
140,18,317,4,0,
140,18,319,1,41,
140,18,332,4,0,
140,18,341,1,16,
140,18,341,2,0,
140,18,378,1,50,
140,18,397,4,0,
140,18,453,1,31,
140,18,479,4,0,
140,18,496,4,0,
140,18,503,4,0,
140,18,590,4,0,
141,1,10,1,1,1
141,1,13,4,0,
141,1,14,4,0,
141,1,25,4,0,
141,1,34,4,0,
141,1,36,4,0,
141,1,38,4,0,
141,1,43,1,46,
141,1,55,4,0,
141,1,56,1,53,
141,1,57,4,0,
141,1,58,4,0,
141,1,59,4,0,
141,1,61,4,0,
141,1,63,4,0,
141,1,66,4,0,
141,1,69,4,0,
141,1,71,1,1,3
141,1,71,1,34,
141,1,92,4,0,
141,1,99,4,0,
141,1,102,4,0,
141,1,104,4,0,
141,1,106,1,1,2
141,1,115,4,0,
141,1,117,4,0,
141,1,130,4,0,
141,1,156,4,0,
141,1,163,1,39,
141,1,164,4,0,
141,2,10,1,1,1
141,2,13,4,0,
141,2,14,4,0,
141,2,15,4,0,
141,2,25,4,0,
141,2,34,4,0,
141,2,36,4,0,
141,2,38,4,0,
141,2,43,1,46,
141,2,55,4,0,
141,2,56,1,53,
141,2,57,4,0,
141,2,58,4,0,
141,2,59,4,0,
141,2,61,4,0,
141,2,63,4,0,
141,2,66,4,0,
141,2,69,4,0,
141,2,71,1,1,3
141,2,71,1,34,
141,2,92,4,0,
141,2,99,4,0,
141,2,102,4,0,
141,2,104,4,0,
141,2,106,1,1,2
141,2,115,4,0,
141,2,117,4,0,
141,2,130,4,0,
141,2,156,4,0,
141,2,163,1,39,
141,2,164,4,0,
141,3,10,1,1,1
141,3,15,4,0,
141,3,28,1,28,
141,3,29,4,0,
141,3,43,1,19,
141,3,57,4,0,
141,3,59,4,0,
141,3,63,4,0,
141,3,71,1,1,3
141,3,71,1,10,
141,3,72,1,51,
141,3,92,4,0,
141,3,104,4,0,
141,3,106,1,1,2
141,3,156,4,0,
141,3,163,1,40,
141,3,168,4,0,
141,3,173,4,0,
141,3,174,4,0,
141,3,182,4,0,
141,3,196,4,0,
141,3,201,4,0,
141,3,202,4,0,
141,3,203,1,37,
141,3,203,4,0,
141,3,205,4,0,
141,3,207,4,0,
141,3,210,4,0,
141,3,213,4,0,
141,3,214,4,0,
141,3,216,4,0,
141,3,218,4,0,
141,3,237,4,0,
141,3,240,4,0,
141,3,246,1,65,
141,3,249,4,0,
141,3,250,4,0,
141,4,10,1,1,1
141,4,15,4,0,
141,4,28,1,28,
141,4,29,4,0,
141,4,43,1,19,
141,4,57,4,0,
141,4,58,3,0,
141,4,59,4,0,
141,4,63,4,0,
141,4,71,1,1,3
141,4,71,1,10,
141,4,72,1,51,
141,4,92,4,0,
141,4,104,4,0,
141,4,106,1,1,2
141,4,156,4,0,
141,4,163,1,40,
141,4,168,4,0,
141,4,173,4,0,
141,4,174,4,0,
141,4,182,4,0,
141,4,196,4,0,
141,4,201,4,0,
141,4,202,4,0,
141,4,203,1,37,
141,4,203,4,0,
141,4,205,4,0,
141,4,207,4,0,
141,4,210,4,0,
141,4,213,4,0,
141,4,214,4,0,
141,4,216,4,0,
141,4,218,4,0,
141,4,237,4,0,
141,4,240,4,0,
141,4,246,1,65,
141,4,249,4,0,
141,4,250,4,0,
141,5,10,1,1,1
141,5,15,4,0,
141,5,28,1,31,
141,5,43,1,19,
141,5,57,4,0,
141,5,58,4,0,
141,5,59,4,0,
141,5,63,4,0,
141,5,71,1,1,3
141,5,71,1,13,
141,5,72,1,55,
141,5,91,4,0,
141,5,92,4,0,
141,5,104,4,0,
141,5,106,1,1,2
141,5,127,4,0,
141,5,156,4,0,
141,5,163,1,40,
141,5,168,4,0,
141,5,182,4,0,
141,5,201,4,0,
141,5,202,4,0,
141,5,203,1,37,
141,5,213,4,0,
141,5,216,4,0,
141,5,218,4,0,
141,5,237,4,0,
141,5,240,4,0,
141,5,246,1,65,
141,5,249,4,0,
141,5,258,4,0,
141,5,263,4,0,
141,5,280,4,0,
141,5,290,4,0,
141,5,291,4,0,
141,5,317,4,0,
141,5,319,1,46,
141,5,332,4,0,
141,5,341,1,25,
141,5,352,4,0,
141,6,10,1,1,1
141,6,14,3,0,
141,6,15,4,0,
141,6,25,3,0,
141,6,28,1,31,
141,6,34,3,0,
141,6,38,3,0,
141,6,43,1,19,
141,6,57,4,0,
141,6,58,4,0,
141,6,59,4,0,
141,6,63,4,0,
141,6,69,3,0,
141,6,71,1,1,3
141,6,71,1,13,
141,6,72,1,55,
141,6,91,4,0,
141,6,92,4,0,
141,6,102,3,0,
141,6,104,4,0,
141,6,106,1,1,2
141,6,127,4,0,
141,6,156,4,0,
141,6,157,3,0,
141,6,163,1,40,
141,6,164,3,0,
141,6,168,4,0,
141,6,173,3,0,
141,6,182,4,0,
141,6,196,3,0,
141,6,201,4,0,
141,6,202,4,0,
141,6,203,1,37,
141,6,203,3,0,
141,6,205,3,0,
141,6,207,3,0,
141,6,210,3,0,
141,6,213,4,0,
141,6,214,3,0,
141,6,216,4,0,
141,6,218,4,0,
141,6,237,4,0,
141,6,240,4,0,
141,6,246,1,65,
141,6,249,4,0,
141,6,258,4,0,
141,6,263,4,0,
141,6,280,4,0,
141,6,290,4,0,
141,6,291,4,0,
141,6,317,4,0,
141,6,319,1,46,
141,6,332,4,0,
141,6,341,1,25,
141,6,352,4,0,
141,7,10,1,1,2
141,7,14,3,0,
141,7,15,4,0,
141,7,25,3,0,
141,7,28,1,31,
141,7,34,3,0,
141,7,38,3,0,
141,7,43,1,1,5
141,7,43,1,19,
141,7,57,4,0,
141,7,58,4,0,
141,7,59,4,0,
141,7,63,4,0,
141,7,69,3,0,
141,7,71,1,1,4
141,7,71,1,13,
141,7,72,1,55,
141,7,91,4,0,
141,7,92,4,0,
141,7,102,3,0,
141,7,104,4,0,
141,7,106,1,1,3
141,7,127,4,0,
141,7,156,4,0,
141,7,157,3,0,
141,7,163,1,40,
141,7,164,3,0,
141,7,168,4,0,
141,7,182,4,0,
141,7,201,4,0,
141,7,202,4,0,
141,7,203,1,37,
141,7,210,1,1,1
141,7,213,4,0,
141,7,216,4,0,
141,7,218,4,0,
141,7,237,4,0,
141,7,240,4,0,
141,7,246,1,65,
141,7,249,4,0,
141,7,258,4,0,
141,7,263,4,0,
141,7,280,4,0,
141,7,290,4,0,
141,7,291,4,0,
141,7,317,4,0,
141,7,319,1,46,
141,7,332,4,0,
141,7,341,1,25,
141,7,352,4,0,
141,8,10,1,1,1
141,8,14,4,0,
141,8,15,4,0,
141,8,28,1,21,
141,8,43,1,11,
141,8,57,4,0,
141,8,58,4,0,
141,8,59,4,0,
141,8,63,4,0,
141,8,71,1,1,3
141,8,71,1,6,
141,8,72,1,36,
141,8,91,4,0,
141,8,92,4,0,
141,8,104,4,0,
141,8,106,1,1,2
141,8,127,4,0,
141,8,156,4,0,
141,8,157,4,0,
141,8,163,1,40,
141,8,164,4,0,
141,8,168,4,0,
141,8,182,4,0,
141,8,201,4,0,
141,8,202,4,0,
141,8,203,1,26,
141,8,203,4,0,
141,8,207,4,0,
141,8,213,4,0,
141,8,214,4,0,
141,8,216,4,0,
141,8,218,4,0,
141,8,237,4,0,
141,8,240,4,0,
141,8,246,1,54,
141,8,249,4,0,
141,8,258,4,0,
141,8,263,4,0,
141,8,280,4,0,
141,8,290,4,0,
141,8,317,4,0,
141,8,319,1,45,
141,8,332,4,0,
141,8,341,1,16,
141,8,352,4,0,
141,8,362,4,0,
141,8,363,4,0,
141,8,378,1,63,
141,8,397,4,0,
141,8,400,1,72,
141,8,404,4,0,
141,8,416,4,0,
141,8,431,4,0,
141,8,444,4,0,
141,8,445,4,0,
141,8,446,4,0,
141,8,453,1,31,
141,9,10,1,1,2
141,9,14,4,0,
141,9,15,4,0,
141,9,28,1,21,
141,9,43,1,1,5
141,9,43,1,11,
141,9,57,4,0,
141,9,58,4,0,
141,9,59,4,0,
141,9,63,4,0,
141,9,71,1,1,4
141,9,71,1,6,
141,9,72,1,36,
141,9,91,4,0,
141,9,92,4,0,
141,9,104,4,0,
141,9,106,1,1,3
141,9,127,4,0,
141,9,156,4,0,
141,9,157,4,0,
141,9,163,1,40,
141,9,164,4,0,
141,9,168,4,0,
141,9,173,3,0,
141,9,182,4,0,
141,9,189,3,0,
141,9,196,3,0,
141,9,201,4,0,
141,9,202,4,0,
141,9,203,1,26,
141,9,203,4,0,
141,9,205,3,0,
141,9,207,4,0,
141,9,210,3,0,
141,9,213,4,0,
141,9,214,4,0,
141,9,216,4,0,
141,9,218,4,0,
141,9,237,4,0,
141,9,240,4,0,
141,9,246,1,54,
141,9,246,3,0,
141,9,249,4,0,
141,9,258,4,0,
141,9,263,4,0,
141,9,276,3,0,
141,9,280,4,0,
141,9,282,3,0,
141,9,290,4,0,
141,9,291,3,0,
141,9,317,4,0,
141,9,319,1,45,
141,9,332,4,0,
141,9,334,3,0,
141,9,341,1,16,
141,9,352,4,0,
141,9,362,4,0,
141,9,363,4,0,
141,9,364,1,1,1
141,9,378,1,63,
141,9,397,4,0,
141,9,400,1,72,
141,9,401,3,0,
141,9,404,4,0,
141,9,414,3,0,
141,9,416,4,0,
141,9,431,4,0,
141,9,444,4,0,
141,9,445,4,0,
141,9,446,4,0,
141,9,453,1,31,
141,10,10,1,1,2
141,10,14,4,0,
141,10,15,4,0,
141,10,28,1,21,
141,10,29,3,0,
141,10,43,1,1,5
141,10,43,1,11,
141,10,57,4,0,
141,10,58,4,0,
141,10,59,4,0,
141,10,63,4,0,
141,10,67,3,0,
141,10,71,1,1,4
141,10,71,1,6,
141,10,72,1,36,
141,10,91,4,0,
141,10,92,4,0,
141,10,104,4,0,
141,10,106,1,1,3
141,10,127,4,0,
141,10,156,4,0,
141,10,157,4,0,
141,10,163,1,40,
141,10,164,4,0,
141,10,168,4,0,
141,10,173,3,0,
141,10,182,4,0,
141,10,189,3,0,
141,10,196,3,0,
141,10,201,4,0,
141,10,202,4,0,
141,10,203,1,26,
141,10,203,4,0,
141,10,205,3,0,
141,10,207,4,0,
141,10,210,3,0,
141,10,213,4,0,
141,10,214,4,0,
141,10,216,4,0,
141,10,218,4,0,
141,10,237,4,0,
141,10,240,4,0,
141,10,246,1,54,
141,10,246,3,0,
141,10,249,4,0,
141,10,250,4,0,
141,10,258,4,0,
141,10,263,4,0,
141,10,276,3,0,
141,10,280,4,0,
141,10,282,3,0,
141,10,290,4,0,
141,10,291,3,0,
141,10,317,4,0,
141,10,319,1,45,
141,10,332,4,0,
141,10,334,3,0,
141,10,341,1,16,
141,10,352,4,0,
141,10,362,4,0,
141,10,363,4,0,
141,10,364,1,1,1
141,10,378,1,63,
141,10,397,4,0,
141,10,400,1,72,
141,10,401,3,0,
141,10,404,4,0,
141,10,414,3,0,
141,10,416,4,0,
141,10,431,4,0,
141,10,444,4,0,
141,10,445,4,0,
141,10,446,4,0,
141,10,453,1,31,
141,11,10,1,1,2
141,11,14,4,0,
141,11,15,4,0,
141,11,28,1,21,
141,11,43,1,1,5
141,11,43,1,11,
141,11,57,4,0,
141,11,58,4,0,
141,11,59,4,0,
141,11,63,4,0,
141,11,71,1,1,4
141,11,71,1,6,
141,11,72,1,36,
141,11,91,4,0,
141,11,92,4,0,
141,11,104,4,0,
141,11,106,1,1,3
141,11,127,4,0,
141,11,156,4,0,
141,11,157,4,0,
141,11,163,1,40,
141,11,164,4,0,
141,11,168,4,0,
141,11,182,4,0,
141,11,201,4,0,
141,11,203,1,26,
141,11,207,4,0,
141,11,213,4,0,
141,11,216,4,0,
141,11,218,4,0,
141,11,237,4,0,
141,11,240,4,0,
141,11,246,1,54,
141,11,249,4,0,
141,11,258,4,0,
141,11,263,4,0,
141,11,280,4,0,
141,11,291,4,0,
141,11,317,4,0,
141,11,319,1,45,
141,11,332,4,0,
141,11,341,1,16,
141,11,364,1,1,1
141,11,378,1,63,
141,11,397,4,0,
141,11,400,1,72,
141,11,404,4,0,
141,11,416,4,0,
141,11,444,4,0,
141,11,453,1,31,
141,11,468,4,0,
141,11,479,4,0,
141,11,496,4,0,
141,11,503,4,0,
141,12,10,1,1,1
141,12,15,4,0,
141,12,28,1,31,
141,12,43,1,19,
141,12,57,4,0,
141,12,58,4,0,
141,12,59,4,0,
141,12,63,4,0,
141,12,71,1,1,3
141,12,71,1,13,
141,12,72,1,55,
141,12,91,4,0,
141,12,92,4,0,
141,12,104,4,0,
141,12,106,1,1,2
141,12,127,4,0,
141,12,156,4,0,
141,12,163,1,40,
141,12,168,4,0,
141,12,182,4,0,
141,12,201,4,0,
141,12,202,4,0,
141,12,203,1,37,
141,12,213,4,0,
141,12,216,4,0,
141,12,218,4,0,
141,12,237,4,0,
141,12,240,4,0,
141,12,246,1,65,
141,12,249,4,0,
141,12,258,4,0,
141,12,263,4,0,
141,12,280,4,0,
141,12,290,4,0,
141,12,291,4,0,
141,12,317,4,0,
141,12,319,1,46,
141,12,332,4,0,
141,12,341,1,25,
141,12,352,4,0,
141,13,10,1,1,1
141,13,15,4,0,
141,13,28,1,31,
141,13,34,3,0,
141,13,38,3,0,
141,13,43,1,19,
141,13,57,4,0,
141,13,58,4,0,
141,13,59,4,0,
141,13,63,4,0,
141,13,69,3,0,
141,13,71,1,1,3
141,13,71,1,13,
141,13,72,1,55,
141,13,91,4,0,
141,13,92,4,0,
141,13,102,3,0,
141,13,104,4,0,
141,13,106,1,1,2
141,13,127,4,0,
141,13,156,4,0,
141,13,163,1,40,
141,13,164,3,0,
141,13,168,4,0,
141,13,182,4,0,
141,13,196,3,0,
141,13,201,4,0,
141,13,202,4,0,
141,13,203,1,37,
141,13,207,3,0,
141,13,213,4,0,
141,13,216,4,0,
141,13,218,4,0,
141,13,237,4,0,
141,13,240,4,0,
141,13,246,1,65,
141,13,249,4,0,
141,13,258,4,0,
141,13,263,4,0,
141,13,280,4,0,
141,13,290,4,0,
141,13,291,4,0,
141,13,317,4,0,
141,13,319,1,46,
141,13,332,4,0,
141,13,341,1,25,
141,13,352,4,0,
141,14,10,1,1,2
141,14,14,4,0,
141,14,15,4,0,
141,14,28,1,21,
141,14,43,1,1,5
141,14,43,1,11,
141,14,57,4,0,
141,14,58,4,0,
141,14,59,4,0,
141,14,63,4,0,
141,14,67,3,0,
141,14,71,1,1,4
141,14,71,1,6,
141,14,72,1,36,
141,14,91,4,0,
141,14,92,4,0,
141,14,104,4,0,
141,14,106,1,1,3
141,14,127,4,0,
141,14,156,4,0,
141,14,157,4,0,
141,14,163,1,40,
141,14,164,4,0,
141,14,168,4,0,
141,14,173,3,0,
141,14,182,4,0,
141,14,196,3,0,
141,14,201,4,0,
141,14,202,3,0,
141,14,203,1,26,
141,14,207,4,0,
141,14,213,4,0,
141,14,214,3,0,
141,14,216,4,0,
141,14,218,4,0,
141,14,237,4,0,
141,14,240,4,0,
141,14,246,1,54,
141,14,249,4,0,
141,14,258,4,0,
141,14,263,4,0,
141,14,276,3,0,
141,14,280,4,0,
141,14,282,3,0,
141,14,291,4,0,
141,14,317,4,0,
141,14,319,1,45,
141,14,332,4,0,
141,14,334,3,0,
141,14,341,1,16,
141,14,364,1,1,1
141,14,378,1,63,
141,14,397,4,0,
141,14,400,1,72,
141,14,401,3,0,
141,14,404,4,0,
141,14,414,3,0,
141,14,416,4,0,
141,14,444,4,0,
141,14,446,3,0,
141,14,453,1,31,
141,14,468,4,0,
141,14,479,4,0,
141,14,496,4,0,
141,14,503,4,0,
141,15,10,1,1,3
141,15,14,4,0,
141,15,15,4,0,
141,15,28,1,21,
141,15,43,1,1,6
141,15,43,1,11,
141,15,57,4,0,
141,15,58,4,0,
141,15,59,4,0,
141,15,63,4,0,
141,15,71,1,1,5
141,15,71,1,6,
141,15,72,1,36,
141,15,91,4,0,
141,15,92,4,0,
141,15,104,4,0,
141,15,106,1,1,4
141,15,127,4,0,
141,15,156,4,0,
141,15,157,4,0,
141,15,163,1,40,
141,15,164,4,0,
141,15,168,4,0,
141,15,182,4,0,
141,15,201,4,0,
141,15,203,1,26,
141,15,207,4,0,
141,15,213,4,0,
141,15,214,4,0,
141,15,216,4,0,
141,15,218,4,0,
141,15,237,4,0,
141,15,240,4,0,
141,15,246,1,54,
141,15,249,4,0,
141,15,258,4,0,
141,15,263,4,0,
141,15,267,4,0,
141,15,280,4,0,
141,15,317,4,0,
141,15,319,1,45,
141,15,332,4,0,
141,15,341,1,16,
141,15,364,1,1,2
141,15,378,1,63,
141,15,397,4,0,
141,15,400,1,1,1
141,15,400,1,72,
141,15,404,4,0,
141,15,416,4,0,
141,15,444,4,0,
141,15,453,1,31,
141,15,468,4,0,
141,15,479,4,0,
141,15,496,4,0,
141,15,503,4,0,
141,15,590,4,0,
141,16,10,1,1,3
141,16,14,4,0,
141,16,15,4,0,
141,16,28,1,21,1
141,16,43,1,1,6
141,16,43,1,11,1
141,16,57,4,0,
141,16,58,4,0,
141,16,59,4,0,
141,16,63,4,0,
141,16,67,3,0,
141,16,71,1,1,5
141,16,71,1,6,1
141,16,72,1,36,1
141,16,91,4,0,
141,16,92,4,0,
141,16,104,4,0,
141,16,106,1,1,4
141,16,127,4,0,
141,16,156,4,0,
141,16,157,4,0,
141,16,163,1,40,1
141,16,164,4,0,
141,16,168,4,0,
141,16,173,3,0,
141,16,182,4,0,
141,16,196,3,0,
141,16,201,4,0,
141,16,202,3,0,
141,16,203,1,26,1
141,16,207,4,0,
141,16,213,4,0,
141,16,214,4,0,
141,16,216,4,0,
141,16,218,4,0,
141,16,237,4,0,
141,16,240,4,0,
141,16,246,1,54,1
141,16,249,4,0,
141,16,258,4,0,
141,16,263,4,0,
141,16,267,4,0,
141,16,276,3,0,
141,16,280,4,0,
141,16,282,3,0,
141,16,290,4,0,
141,16,291,4,0,
141,16,317,4,0,
141,16,319,1,45,1
141,16,332,4,0,
141,16,334,3,0,
141,16,341,1,16,1
141,16,352,3,0,
141,16,364,1,1,2
141,16,378,1,63,1
141,16,397,4,0,
141,16,400,1,1,1
141,16,400,1,72,1
141,16,401,3,0,
141,16,404,4,0,
141,16,414,3,0,
141,16,416,4,0,
141,16,444,4,0,
141,16,446,3,0,
141,16,453,1,31,1
141,16,468,4,0,
141,16,479,4,0,
141,16,496,4,0,
141,16,503,4,0,
141,16,590,4,0,
141,17,10,1,1,4
141,17,14,4,0,
141,17,28,1,21,
141,17,43,1,1,7
141,17,43,1,11,
141,17,57,4,0,
141,17,58,4,0,
141,17,59,4,0,
141,17,63,4,0,
141,17,71,1,1,6
141,17,71,1,6,
141,17,72,1,36,
141,17,92,4,0,
141,17,104,4,0,
141,17,106,1,1,5
141,17,127,4,0,
141,17,156,4,0,
141,17,157,4,0,
141,17,163,1,0,
141,17,163,1,1,1
141,17,164,4,0,
141,17,168,4,0,
141,17,182,4,0,
141,17,201,4,0,
141,17,203,1,26,
141,17,207,4,0,
141,17,213,4,0,
141,17,214,4,0,
141,17,216,4,0,
141,17,218,4,0,
141,17,237,4,0,
141,17,240,4,0,
141,17,246,1,54,
141,17,258,4,0,
141,17,263,4,0,
141,17,267,4,0,
141,17,280,4,0,
141,17,317,4,0,
141,17,319,1,45,
141,17,332,4,0,
141,17,341,1,16,
141,17,364,1,1,3
141,17,378,1,63,
141,17,397,4,0,
141,17,400,1,1,2
141,17,400,1,72,
141,17,404,4,0,
141,17,416,4,0,
141,17,444,4,0,
141,17,453,1,31,
141,17,479,4,0,
141,17,496,4,0,
141,17,503,4,0,
141,17,590,4,0,
141,18,10,1,1,4
141,18,14,4,0,
141,18,28,1,21,
141,18,43,1,1,7
141,18,43,1,11,
141,18,57,4,0,
141,18,58,4,0,
141,18,59,4,0,
141,18,63,4,0,
141,18,71,1,1,6
141,18,71,1,6,
141,18,72,1,36,
141,18,92,4,0,
141,18,104,4,0,
141,18,106,1,1,5
141,18,127,4,0,
141,18,156,4,0,
141,18,157,4,0,
141,18,163,1,0,
141,18,163,1,1,1
141,18,164,4,0,
141,18,168,4,0,
141,18,182,4,0,
141,18,201,4,0,
141,18,203,1,26,
141,18,207,4,0,
141,18,213,4,0,
141,18,214,4,0,
141,18,216,4,0,
141,18,218,4,0,
141,18,237,4,0,
141,18,240,4,0,
141,18,246,1,54,
141,18,258,4,0,
141,18,263,4,0,
141,18,267,4,0,
141,18,280,4,0,
141,18,317,4,0,
141,18,319,1,45,
141,18,332,4,0,
141,18,341,1,16,
141,18,364,1,1,3
141,18,378,1,63,
141,18,397,4,0,
141,18,400,1,1,2
141,18,400,1,72,
141,18,404,4,0,
141,18,416,4,0,
141,18,444,4,0,
141,18,453,1,31,
141,18,479,4,0,
141,18,496,4,0,
141,18,503,4,0,
141,18,590,4,0,
142,1,13,4,0,
142,1,17,1,1,1
142,1,18,4,0,
142,1,19,4,0,
142,1,36,1,45,
142,1,36,4,0,
142,1,38,4,0,
142,1,44,1,38,
142,1,48,1,33,
142,1,63,1,54,
142,1,63,4,0,
142,1,82,4,0,
142,1,92,4,0,
142,1,97,1,1,2
142,1,99,4,0,
142,1,102,4,0,
142,1,104,4,0,
142,1,115,4,0,
142,1,117,4,0,
142,1,126,4,0,
142,1,129,4,0,
142,1,143,4,0,
142,1,156,4,0,
142,1,164,4,0,
142,2,13,4,0,
142,2,17,1,1,1
142,2,18,4,0,
142,2,19,4,0,
142,2,36,1,45,
142,2,36,4,0,
142,2,38,4,0,
142,2,44,1,38,
142,2,48,1,33,
142,2,63,1,54,
142,2,63,4,0,
142,2,82,4,0,
142,2,92,4,0,
142,2,97,1,1,2
142,2,99,4,0,
142,2,102,4,0,
142,2,104,4,0,
142,2,115,4,0,
142,2,117,4,0,
142,2,126,4,0,
142,2,129,4,0,
142,2,143,4,0,
142,2,156,4,0,
142,2,164,4,0,
142,3,17,1,1,
142,3,18,2,0,
142,3,19,4,0,
142,3,29,4,0,
142,3,36,1,43,
142,3,44,1,15,
142,3,46,4,0,
142,3,48,1,22,
142,3,63,1,50,
142,3,63,4,0,
142,3,89,4,0,
142,3,92,4,0,
142,3,97,1,8,
142,3,104,4,0,
142,3,126,4,0,
142,3,129,4,0,
142,3,156,4,0,
142,3,173,4,0,
142,3,174,4,0,
142,3,182,4,0,
142,3,184,1,36,
142,3,193,2,0,
142,3,197,4,0,
142,3,201,4,0,
142,3,203,4,0,
142,3,207,4,0,
142,3,211,2,0,
142,3,211,4,0,
142,3,213,4,0,
142,3,214,4,0,
142,3,216,4,0,
142,3,218,4,0,
142,3,225,4,0,
142,3,228,2,0,
142,3,231,4,0,
142,3,237,4,0,
142,3,240,4,0,
142,3,246,1,29,
142,3,249,4,0,
142,4,17,1,1,
142,4,18,2,0,
142,4,19,4,0,
142,4,29,4,0,
142,4,36,1,43,
142,4,44,1,15,
142,4,46,4,0,
142,4,48,1,22,
142,4,53,3,0,
142,4,63,1,50,
142,4,63,4,0,
142,4,89,4,0,
142,4,92,4,0,
142,4,97,1,8,
142,4,104,4,0,
142,4,126,4,0,
142,4,129,4,0,
142,4,156,4,0,
142,4,173,4,0,
142,4,174,4,0,
142,4,182,4,0,
142,4,184,1,36,
142,4,193,2,0,
142,4,197,4,0,
142,4,201,4,0,
142,4,203,4,0,
142,4,207,4,0,
142,4,211,4,0,
142,4,213,4,0,
142,4,214,4,0,
142,4,216,4,0,
142,4,218,4,0,
142,4,225,4,0,
142,4,228,2,0,
142,4,231,4,0,
142,4,237,4,0,
142,4,240,4,0,
142,4,246,1,29,
142,4,249,4,0,
142,5,17,1,1,
142,5,18,2,0,
142,5,19,4,0,
142,5,36,1,43,
142,5,44,1,15,
142,5,46,4,0,
142,5,48,1,22,
142,5,53,4,0,
142,5,63,1,50,
142,5,63,4,0,
142,5,70,4,0,
142,5,89,4,0,
142,5,92,4,0,
142,5,97,1,8,
142,5,104,4,0,
142,5,126,4,0,
142,5,156,4,0,
142,5,168,4,0,
142,5,174,2,0,
142,5,182,4,0,
142,5,184,1,36,
142,5,193,2,0,
142,5,201,4,0,
142,5,211,2,0,
142,5,211,4,0,
142,5,213,4,0,
142,5,216,4,0,
142,5,218,4,0,
142,5,225,2,0,
142,5,228,2,0,
142,5,231,4,0,
142,5,237,4,0,
142,5,240,4,0,
142,5,241,4,0,
142,5,246,1,29,
142,5,249,4,0,
142,5,259,4,0,
142,5,263,4,0,
142,5,269,4,0,
142,5,290,4,0,
142,5,317,4,0,
142,5,332,4,0,
142,5,337,4,0,
142,6,17,1,1,
142,6,18,2,0,
142,6,19,4,0,
142,6,36,1,43,
142,6,38,3,0,
142,6,44,1,15,
142,6,46,4,0,
142,6,48,1,22,
142,6,53,4,0,
142,6,63,1,50,
142,6,63,4,0,
142,6,70,4,0,
142,6,89,4,0,
142,6,92,4,0,
142,6,97,1,8,
142,6,102,3,0,
142,6,104,4,0,
142,6,126,4,0,
142,6,129,3,0,
142,6,156,4,0,
142,6,157,3,0,
142,6,164,3,0,
142,6,168,4,0,
142,6,173,3,0,
142,6,174,2,0,
142,6,182,4,0,
142,6,184,1,36,
142,6,193,2,0,
142,6,201,4,0,
142,6,203,3,0,
142,6,207,3,0,
142,6,211,2,0,
142,6,211,4,0,
142,6,213,4,0,
142,6,214,3,0,
142,6,216,4,0,
142,6,218,4,0,
142,6,225,2,0,
142,6,228,2,0,
142,6,231,4,0,
142,6,237,4,0,
142,6,240,4,0,
142,6,241,4,0,
142,6,246,1,29,
142,6,249,4,0,
142,6,259,4,0,
142,6,263,4,0,
142,6,269,4,0,
142,6,290,4,0,
142,6,317,4,0,
142,6,332,4,0,
142,6,337,4,0,
142,7,17,1,1,
142,7,18,2,0,
142,7,19,4,0,
142,7,36,1,43,
142,7,38,3,0,
142,7,44,1,15,
142,7,46,4,0,
142,7,48,1,22,
142,7,53,4,0,
142,7,63,1,50,
142,7,63,4,0,
142,7,70,4,0,
142,7,89,4,0,
142,7,92,4,0,
142,7,97,1,8,
142,7,102,3,0,
142,7,104,4,0,
142,7,126,4,0,
142,7,156,4,0,
142,7,157,3,0,
142,7,164,3,0,
142,7,168,4,0,
142,7,174,2,0,
142,7,182,4,0,
142,7,184,1,36,
142,7,193,2,0,
142,7,201,4,0,
142,7,211,2,0,
142,7,211,4,0,
142,7,213,4,0,
142,7,216,4,0,
142,7,218,4,0,
142,7,225,2,0,
142,7,228,2,0,
142,7,231,4,0,
142,7,237,4,0,
142,7,240,4,0,
142,7,241,4,0,
142,7,246,1,29,
142,7,249,4,0,
142,7,259,4,0,
142,7,263,4,0,
142,7,269,4,0,
142,7,290,4,0,
142,7,317,4,0,
142,7,332,4,0,
142,7,337,4,0,
142,8,17,1,1,4
142,8,18,2,0,
142,8,19,4,0,
142,8,36,1,41,
142,8,44,1,1,6
142,8,46,1,9,
142,8,46,4,0,
142,8,48,1,1,5
142,8,53,4,0,
142,8,63,1,57,
142,8,63,4,0,
142,8,70,4,0,
142,8,89,4,0,
142,8,92,4,0,
142,8,97,1,17,
142,8,104,4,0,
142,8,126,4,0,
142,8,156,4,0,
142,8,157,1,65,
142,8,157,4,0,
142,8,164,4,0,
142,8,168,4,0,
142,8,174,2,0,
142,8,182,4,0,
142,8,184,1,1,7
142,8,193,2,0,
142,8,201,4,0,
142,8,203,4,0,
142,8,207,4,0,
142,8,211,2,0,
142,8,211,4,0,
142,8,213,4,0,
142,8,214,4,0,
142,8,216,4,0,
142,8,218,4,0,
142,8,225,2,0,
142,8,228,2,0,
142,8,231,4,0,
142,8,237,4,0,
142,8,240,4,0,
142,8,241,4,0,
142,8,242,1,33,
142,8,246,1,25,
142,8,249,4,0,
142,8,259,4,0,
142,8,263,4,0,
142,8,269,4,0,
142,8,290,4,0,
142,8,317,4,0,
142,8,332,4,0,
142,8,337,4,0,
142,8,355,4,0,
142,8,363,4,0,
142,8,371,4,0,
142,8,372,2,0,
142,8,397,4,0,
142,8,406,4,0,
142,8,416,1,73,
142,8,416,4,0,
142,8,422,1,1,3
142,8,423,1,1,1
142,8,424,1,1,2
142,8,432,4,0,
142,8,442,1,49,
142,8,444,4,0,
142,8,445,4,0,
142,8,446,4,0,
142,9,17,1,1,4
142,9,18,2,0,
142,9,19,4,0,
142,9,36,1,41,
142,9,44,1,1,6
142,9,46,1,9,
142,9,46,4,0,
142,9,48,1,1,5
142,9,53,4,0,
142,9,63,1,57,
142,9,63,4,0,
142,9,70,4,0,
142,9,89,4,0,
142,9,92,4,0,
142,9,97,1,17,
142,9,104,4,0,
142,9,126,4,0,
142,9,129,3,0,
142,9,156,4,0,
142,9,157,1,65,
142,9,157,4,0,
142,9,164,4,0,
142,9,168,4,0,
142,9,173,3,0,
142,9,174,2,0,
142,9,182,4,0,
142,9,184,1,1,7
142,9,193,2,0,
142,9,201,4,0,
142,9,203,4,0,
142,9,207,4,0,
142,9,211,2,0,
142,9,211,4,0,
142,9,213,4,0,
142,9,214,4,0,
142,9,216,4,0,
142,9,218,4,0,
142,9,225,2,0,
142,9,228,2,0,
142,9,231,4,0,
142,9,237,4,0,
142,9,239,3,0,
142,9,240,4,0,
142,9,241,4,0,
142,9,242,1,33,
142,9,246,1,25,
142,9,246,3,0,
142,9,249,4,0,
142,9,257,3,0,
142,9,259,4,0,
142,9,263,4,0,
142,9,269,4,0,
142,9,290,4,0,
142,9,314,3,0,
142,9,317,4,0,
142,9,332,4,0,
142,9,337,4,0,
142,9,355,4,0,
142,9,363,4,0,
142,9,371,4,0,
142,9,372,2,0,
142,9,397,4,0,
142,9,401,3,0,
142,9,406,4,0,
142,9,414,3,0,
142,9,416,1,73,
142,9,416,4,0,
142,9,422,1,1,3
142,9,423,1,1,1
142,9,424,1,1,2
142,9,432,4,0,
142,9,442,1,49,
142,9,442,3,0,
142,9,444,4,0,
142,9,445,4,0,
142,9,446,4,0,
142,9,466,3,0,
142,10,17,1,1,4
142,10,18,2,0,
142,10,19,4,0,
142,10,29,3,0,
142,10,36,1,41,
142,10,44,1,1,6
142,10,46,1,9,
142,10,46,4,0,
142,10,48,1,1,5
142,10,53,4,0,
142,10,63,1,57,
142,10,63,4,0,
142,10,70,4,0,
142,10,89,4,0,
142,10,92,4,0,
142,10,97,1,17,
142,10,104,4,0,
142,10,126,4,0,
142,10,129,3,0,
142,10,143,3,0,
142,10,156,4,0,
142,10,157,1,65,
142,10,157,4,0,
142,10,164,4,0,
142,10,168,4,0,
142,10,173,3,0,
142,10,174,2,0,
142,10,182,4,0,
142,10,184,1,1,7
142,10,193,2,0,
142,10,201,4,0,
142,10,203,4,0,
142,10,207,4,0,
142,10,211,2,0,
142,10,211,4,0,
142,10,213,4,0,
142,10,214,4,0,
142,10,216,4,0,
142,10,218,4,0,
142,10,225,2,0,
142,10,228,2,0,
142,10,231,4,0,
142,10,237,4,0,
142,10,239,3,0,
142,10,240,4,0,
142,10,241,4,0,
142,10,242,1,33,
142,10,246,1,25,
142,10,246,3,0,
142,10,249,4,0,
142,10,257,3,0,
142,10,259,4,0,
142,10,263,4,0,
142,10,269,4,0,
142,10,290,4,0,
142,10,314,3,0,
142,10,317,4,0,
142,10,332,4,0,
142,10,337,4,0,
142,10,355,4,0,
142,10,363,4,0,
142,10,366,3,0,
142,10,371,4,0,
142,10,372,2,0,
142,10,397,4,0,
142,10,401,3,0,
142,10,406,4,0,
142,10,414,3,0,
142,10,416,1,73,
142,10,416,4,0,
142,10,422,1,1,3
142,10,423,1,1,1
142,10,424,1,1,2
142,10,442,1,49,
142,10,442,3,0,
142,10,444,4,0,
142,10,445,4,0,
142,10,446,4,0,
142,10,466,3,0,
142,11,17,1,1,4
142,11,18,2,0,
142,11,19,4,0,
142,11,36,1,41,
142,11,44,1,1,6
142,11,46,1,9,
142,11,46,4,0,
142,11,48,1,1,5
142,11,53,4,0,
142,11,63,1,65,
142,11,63,4,0,
142,11,70,4,0,
142,11,89,4,0,
142,11,92,4,0,
142,11,97,1,17,
142,11,104,4,0,
142,11,126,4,0,
142,11,156,4,0,
142,11,157,1,73,
142,11,157,4,0,
142,11,164,4,0,
142,11,168,4,0,
142,11,174,2,0,
142,11,182,4,0,
142,11,184,1,1,7
142,11,193,2,0,
142,11,201,4,0,
142,11,207,4,0,
142,11,211,2,0,
142,11,213,4,0,
142,11,216,4,0,
142,11,218,4,0,
142,11,225,2,0,
142,11,228,2,0,
142,11,237,4,0,
142,11,240,4,0,
142,11,241,4,0,
142,11,242,1,33,
142,11,246,1,25,
142,11,249,4,0,
142,11,259,4,0,
142,11,263,4,0,
142,11,269,4,0,
142,11,317,4,0,
142,11,332,4,0,
142,11,337,4,0,
142,11,355,2,0,
142,11,366,2,0,
142,11,371,4,0,
142,11,372,2,0,
142,11,397,4,0,
142,11,416,1,81,
142,11,416,4,0,
142,11,422,1,1,3
142,11,423,1,1,1
142,11,424,1,1,2
142,11,442,1,57,
142,11,444,4,0,
142,11,468,4,0,
142,11,479,4,0,
142,11,496,4,0,
142,11,507,1,49,
142,11,507,4,0,
142,11,510,4,0,
142,11,523,4,0,
142,12,17,1,1,
142,12,19,4,0,
142,12,36,1,43,
142,12,44,1,15,
142,12,46,4,0,
142,12,48,1,22,
142,12,53,4,0,
142,12,63,1,50,
142,12,63,4,0,
142,12,70,4,0,
142,12,89,4,0,
142,12,92,4,0,
142,12,97,1,8,
142,12,104,4,0,
142,12,126,4,0,
142,12,156,4,0,
142,12,168,4,0,
142,12,182,4,0,
142,12,184,1,36,
142,12,201,4,0,
142,12,211,4,0,
142,12,213,4,0,
142,12,216,4,0,
142,12,218,4,0,
142,12,231,4,0,
142,12,237,4,0,
142,12,240,4,0,
142,12,241,4,0,
142,12,246,1,29,
142,12,249,4,0,
142,12,259,4,0,
142,12,263,4,0,
142,12,269,4,0,
142,12,290,4,0,
142,12,317,4,0,
142,12,332,4,0,
142,12,337,4,0,
142,13,17,1,1,
142,13,19,4,0,
142,13,36,1,43,
142,13,38,3,0,
142,13,44,1,15,
142,13,46,4,0,
142,13,48,1,22,
142,13,53,4,0,
142,13,63,1,50,
142,13,63,4,0,
142,13,70,4,0,
142,13,89,4,0,
142,13,92,4,0,
142,13,97,1,8,
142,13,102,3,0,
142,13,104,4,0,
142,13,126,4,0,
142,13,143,3,0,
142,13,156,4,0,
142,13,164,3,0,
142,13,168,4,0,
142,13,182,4,0,
142,13,184,1,36,
142,13,201,4,0,
142,13,207,3,0,
142,13,211,4,0,
142,13,213,4,0,
142,13,216,4,0,
142,13,218,4,0,
142,13,231,4,0,
142,13,237,4,0,
142,13,240,4,0,
142,13,241,4,0,
142,13,246,1,29,
142,13,249,4,0,
142,13,259,4,0,
142,13,263,4,0,
142,13,269,4,0,
142,13,290,4,0,
142,13,317,4,0,
142,13,332,4,0,
142,13,337,4,0,
142,14,17,1,1,4
142,14,18,2,0,
142,14,19,4,0,
142,14,36,1,41,
142,14,44,1,1,6
142,14,46,1,9,
142,14,46,4,0,
142,14,48,1,1,5
142,14,53,4,0,
142,14,63,1,65,
142,14,63,4,0,
142,14,70,4,0,
142,14,89,4,0,
142,14,92,4,0,
142,14,97,1,17,
142,14,104,4,0,
142,14,126,4,0,
142,14,143,3,0,
142,14,156,4,0,
142,14,157,1,73,
142,14,157,4,0,
142,14,164,4,0,
142,14,168,4,0,
142,14,173,3,0,
142,14,174,2,0,
142,14,182,4,0,
142,14,184,1,1,7
142,14,193,2,0,
142,14,201,4,0,
142,14,207,4,0,
142,14,211,2,0,
142,14,213,4,0,
142,14,214,3,0,
142,14,216,4,0,
142,14,218,4,0,
142,14,225,2,0,
142,14,228,2,0,
142,14,231,3,0,
142,14,237,4,0,
142,14,240,4,0,
142,14,241,4,0,
142,14,242,1,33,
142,14,246,1,25,
142,14,249,4,0,
142,14,257,3,0,
142,14,259,4,0,
142,14,263,4,0,
142,14,269,4,0,
142,14,317,4,0,
142,14,332,4,0,
142,14,337,4,0,
142,14,355,2,0,
142,14,355,3,0,
142,14,366,2,0,
142,14,366,3,0,
142,14,371,4,0,
142,14,372,2,0,
142,14,397,4,0,
142,14,401,3,0,
142,14,406,3,0,
142,14,414,3,0,
142,14,416,1,81,
142,14,416,4,0,
142,14,422,1,1,3
142,14,423,1,1,1
142,14,424,1,1,2
142,14,442,1,57,
142,14,442,3,0,
142,14,444,4,0,
142,14,446,3,0,
142,14,468,4,0,
142,14,479,4,0,
142,14,496,4,0,
142,14,507,1,49,
142,14,507,4,0,
142,14,510,4,0,
142,14,523,4,0,
142,15,17,1,1,5
142,15,18,2,0,
142,15,19,4,0,
142,15,36,1,41,
142,15,44,1,1,7
142,15,46,1,9,
142,15,46,4,0,
142,15,48,1,1,6
142,15,53,4,0,
142,15,63,1,65,
142,15,63,4,0,
142,15,70,4,0,
142,15,89,4,0,
142,15,92,4,0,
142,15,97,1,17,
142,15,104,4,0,
142,15,126,4,0,
142,15,156,4,0,
142,15,157,1,73,
142,15,157,4,0,
142,15,164,4,0,
142,15,168,4,0,
142,15,174,2,0,
142,15,182,4,0,
142,15,184,1,1,8
142,15,193,2,0,
142,15,201,4,0,
142,15,207,4,0,
142,15,211,2,0,
142,15,211,4,0,
142,15,213,4,0,
142,15,214,4,0,
142,15,216,4,0,
142,15,218,4,0,
142,15,225,2,0,
142,15,228,2,0,
142,15,237,4,0,
142,15,240,4,0,
142,15,241,4,0,
142,15,242,1,33,
142,15,246,1,25,
142,15,249,4,0,
142,15,259,4,0,
142,15,263,4,0,
142,15,269,4,0,
142,15,317,4,0,
142,15,332,4,0,
142,15,337,4,0,
142,15,355,2,0,
142,15,355,4,0,
142,15,366,2,0,
142,15,371,4,0,
142,15,372,2,0,
142,15,397,4,0,
142,15,416,1,81,
142,15,416,4,0,
142,15,422,1,1,4
142,15,423,1,1,2
142,15,424,1,1,3
142,15,442,1,1,1
142,15,442,1,57,
142,15,444,4,0,
142,15,468,4,0,
142,15,469,2,0,
142,15,479,4,0,
142,15,496,4,0,
142,15,507,1,49,
142,15,507,4,0,
142,15,510,4,0,
142,15,523,4,0,
142,15,590,4,0,
142,16,17,1,1,5
142,16,18,2,0,
142,16,19,4,0,
142,16,36,1,41,1
142,16,44,1,1,7
142,16,46,1,9,1
142,16,46,4,0,
142,16,48,1,1,6
142,16,53,4,0,
142,16,63,1,65,1
142,16,63,4,0,
142,16,70,4,0,
142,16,89,4,0,
142,16,92,4,0,
142,16,97,1,17,1
142,16,104,4,0,
142,16,126,4,0,
142,16,143,3,0,
142,16,156,4,0,
142,16,157,1,73,1
142,16,157,4,0,
142,16,164,4,0,
142,16,168,4,0,
142,16,173,3,0,
142,16,174,2,0,
142,16,182,4,0,
142,16,184,1,1,8
142,16,193,2,0,
142,16,201,4,0,
142,16,207,4,0,
142,16,211,2,0,
142,16,211,4,0,
142,16,213,4,0,
142,16,214,4,0,
142,16,216,4,0,
142,16,218,4,0,
142,16,225,2,0,
142,16,228,2,0,
142,16,231,3,0,
142,16,237,4,0,
142,16,240,4,0,
142,16,241,4,0,
142,16,242,1,33,1
142,16,246,1,25,1
142,16,249,4,0,
142,16,257,3,0,
142,16,259,4,0,
142,16,263,4,0,
142,16,269,4,0,
142,16,290,4,0,
142,16,317,4,0,
142,16,332,4,0,
142,16,337,4,0,
142,16,355,2,0,
142,16,355,4,0,
142,16,366,2,0,
142,16,366,3,0,
142,16,371,4,0,
142,16,372,2,0,
142,16,397,4,0,
142,16,401,3,0,
142,16,406,3,0,
142,16,414,3,0,
142,16,416,1,81,1
142,16,416,4,0,
142,16,422,1,1,4
142,16,423,1,1,2
142,16,424,1,1,3
142,16,442,1,1,1
142,16,442,1,57,1
142,16,442,3,0,
142,16,444,4,0,
142,16,446,3,0,
142,16,468,4,0,
142,16,469,2,0,
142,16,479,4,0,
142,16,496,4,0,
142,16,507,1,49,1
142,16,507,4,0,
142,16,510,4,0,
142,16,523,4,0,
142,16,590,4,0,
142,17,17,1,1,5
142,17,18,2,0,
142,17,19,4,0,
142,17,36,1,41,
142,17,44,1,1,7
142,17,46,1,9,
142,17,46,4,0,
142,17,48,1,1,6
142,17,53,4,0,
142,17,63,1,65,
142,17,63,4,0,
142,17,89,4,0,
142,17,92,4,0,
142,17,97,1,17,
142,17,104,4,0,
142,17,126,4,0,
142,17,156,4,0,
142,17,157,1,73,
142,17,157,4,0,
142,17,164,4,0,
142,17,168,4,0,
142,17,174,2,0,
142,17,182,4,0,
142,17,184,1,1,8
142,17,193,2,0,
142,17,201,4,0,
142,17,207,4,0,
142,17,211,2,0,
142,17,211,4,0,
142,17,213,4,0,
142,17,214,4,0,
142,17,216,4,0,
142,17,218,4,0,
142,17,225,2,0,
142,17,228,2,0,
142,17,237,4,0,
142,17,240,4,0,
142,17,241,4,0,
142,17,242,1,33,
142,17,246,1,25,
142,17,259,4,0,
142,17,263,4,0,
142,17,269,4,0,
142,17,317,4,0,
142,17,332,4,0,
142,17,337,4,0,
142,17,355,2,0,
142,17,355,4,0,
142,17,366,2,0,
142,17,371,4,0,
142,17,372,2,0,
142,17,397,4,0,
142,17,416,1,81,
142,17,416,4,0,
142,17,422,1,1,4
142,17,423,1,1,2
142,17,424,1,1,3
142,17,442,1,1,1
142,17,442,1,57,
142,17,444,4,0,
142,17,469,2,0,
142,17,479,4,0,
142,17,496,4,0,
142,17,507,1,49,
142,17,507,4,0,
142,17,523,4,0,
142,17,590,4,0,
142,17,693,4,0,
142,18,17,1,1,5
142,18,18,2,0,
142,18,19,4,0,
142,18,36,1,41,
142,18,44,1,1,7
142,18,46,1,9,
142,18,46,4,0,
142,18,48,1,1,6
142,18,53,4,0,
142,18,63,1,65,
142,18,63,4,0,
142,18,89,4,0,
142,18,92,4,0,
142,18,97,1,17,
142,18,104,4,0,
142,18,126,4,0,
142,18,156,4,0,
142,18,157,1,73,
142,18,157,4,0,
142,18,164,4,0,
142,18,168,4,0,
142,18,174,2,0,
142,18,182,4,0,
142,18,184,1,1,8
142,18,193,2,0,
142,18,201,4,0,
142,18,207,4,0,
142,18,211,2,0,
142,18,211,4,0,
142,18,213,4,0,
142,18,214,4,0,
142,18,216,4,0,
142,18,218,4,0,
142,18,225,2,0,
142,18,228,2,0,
142,18,237,4,0,
142,18,240,4,0,
142,18,241,4,0,
142,18,242,1,33,
142,18,246,1,25,
142,18,259,4,0,
142,18,263,4,0,
142,18,269,4,0,
142,18,317,4,0,
142,18,332,4,0,
142,18,337,4,0,
142,18,355,2,0,
142,18,355,4,0,
142,18,366,2,0,
142,18,371,4,0,
142,18,372,2,0,
142,18,397,4,0,
142,18,416,1,81,
142,18,416,4,0,
142,18,422,1,1,4
142,18,423,1,1,2
142,18,424,1,1,3
142,18,442,1,1,1
142,18,442,1,57,
142,18,444,4,0,
142,18,469,2,0,
142,18,479,4,0,
142,18,496,4,0,
142,18,507,1,49,
142,18,507,4,0,
142,18,523,4,0,
142,18,590,4,0,
142,18,693,4,0,
143,1,5,4,0,
143,1,6,4,0,
143,1,25,4,0,
143,1,29,1,1,1
143,1,34,1,35,
143,1,34,4,0,
143,1,36,4,0,
143,1,38,1,48,
143,1,38,4,0,
143,1,55,4,0,
143,1,57,4,0,
143,1,58,4,0,
143,1,59,4,0,
143,1,61,4,0,
143,1,63,1,56,
143,1,63,4,0,
143,1,66,4,0,
143,1,68,4,0,
143,1,69,4,0,
143,1,70,4,0,
143,1,76,4,0,
143,1,85,4,0,
143,1,87,4,0,
143,1,89,4,0,
143,1,90,4,0,
143,1,92,4,0,
143,1,94,4,0,
143,1,99,4,0,
143,1,102,4,0,
143,1,104,4,0,
143,1,106,1,41,
143,1,115,4,0,
143,1,117,4,0,
143,1,118,4,0,
143,1,120,4,0,
143,1,126,4,0,
143,1,130,4,0,
143,1,133,1,1,2
143,1,149,4,0,
143,1,156,1,1,3
143,1,156,4,0,
143,1,157,4,0,
143,1,164,4,0,
143,2,5,4,0,
143,2,6,4,0,
143,2,25,4,0,
143,2,29,1,1,1
143,2,34,1,35,
143,2,34,4,0,
143,2,36,4,0,
143,2,38,1,48,
143,2,38,4,0,
143,2,55,4,0,
143,2,57,4,0,
143,2,58,4,0,
143,2,59,4,0,
143,2,61,4,0,
143,2,63,1,56,
143,2,63,4,0,
143,2,66,4,0,
143,2,68,4,0,
143,2,69,4,0,
143,2,70,4,0,
143,2,76,4,0,
143,2,85,4,0,
143,2,87,4,0,
143,2,89,4,0,
143,2,90,4,0,
143,2,92,4,0,
143,2,94,4,0,
143,2,99,4,0,
143,2,102,4,0,
143,2,104,4,0,
143,2,106,1,41,
143,2,115,4,0,
143,2,117,4,0,
143,2,118,4,0,
143,2,120,4,0,
143,2,126,4,0,
143,2,130,4,0,
143,2,133,1,1,2
143,2,149,4,0,
143,2,156,1,1,3
143,2,156,4,0,
143,2,157,4,0,
143,2,164,4,0,
143,3,7,4,0,
143,3,8,4,0,
143,3,9,4,0,
143,3,29,1,29,
143,3,29,4,0,
143,3,33,1,1,
143,3,34,1,43,
143,3,57,4,0,
143,3,59,4,0,
143,3,63,1,57,
143,3,63,4,0,
143,3,70,4,0,
143,3,76,4,0,
143,3,87,4,0,
143,3,89,4,0,
143,3,92,4,0,
143,3,94,4,0,
143,3,104,4,0,
143,3,111,1,15,
143,3,111,4,0,
143,3,122,2,0,
143,3,126,4,0,
143,3,133,1,8,
143,3,156,1,36,2
143,3,156,4,0,
143,3,173,1,36,1
143,3,173,4,0,
143,3,174,4,0,
143,3,182,4,0,
143,3,187,1,22,
143,3,189,4,0,
143,3,192,4,0,
143,3,196,4,0,
143,3,201,4,0,
143,3,203,4,0,
143,3,204,2,0,
143,3,205,1,50,
143,3,205,4,0,
143,3,207,4,0,
143,3,213,4,0,
143,3,214,4,0,
143,3,216,4,0,
143,3,218,4,0,
143,3,223,4,0,
143,3,237,4,0,
143,3,240,4,0,
143,3,241,4,0,
143,3,244,4,0,
143,3,247,4,0,
143,3,249,4,0,
143,4,7,4,0,
143,4,8,4,0,
143,4,9,4,0,
143,4,29,1,29,
143,4,29,4,0,
143,4,33,1,1,
143,4,34,1,43,
143,4,53,3,0,
143,4,57,4,0,
143,4,58,3,0,
143,4,59,4,0,
143,4,63,1,57,
143,4,63,4,0,
143,4,70,4,0,
143,4,76,4,0,
143,4,85,3,0,
143,4,87,4,0,
143,4,89,4,0,
143,4,92,4,0,
143,4,94,4,0,
143,4,104,4,0,
143,4,111,1,15,
143,4,111,4,0,
143,4,122,2,0,
143,4,126,4,0,
143,4,133,1,8,
143,4,156,1,36,2
143,4,156,4,0,
143,4,173,1,36,1
143,4,173,4,0,
143,4,174,4,0,
143,4,182,4,0,
143,4,187,1,22,
143,4,189,4,0,
143,4,192,4,0,
143,4,196,4,0,
143,4,201,4,0,
143,4,203,4,0,
143,4,205,1,50,
143,4,205,4,0,
143,4,207,4,0,
143,4,213,4,0,
143,4,214,4,0,
143,4,216,4,0,
143,4,218,4,0,
143,4,223,4,0,
143,4,237,4,0,
143,4,240,4,0,
143,4,241,4,0,
143,4,244,4,0,
143,4,247,4,0,
143,4,249,4,0,
143,5,29,1,19,
143,5,33,1,1,
143,5,34,1,33,
143,5,38,2,0,
143,5,53,4,0,
143,5,57,4,0,
143,5,58,4,0,
143,5,59,4,0,
143,5,63,1,51,
143,5,63,4,0,
143,5,70,4,0,
143,5,76,4,0,
143,5,85,4,0,
143,5,87,4,0,
143,5,89,4,0,
143,5,90,2,0,
143,5,92,4,0,
143,5,94,4,0,
143,5,104,4,0,
143,5,111,1,10,
143,5,122,2,0,
143,5,126,4,0,
143,5,133,1,6,
143,5,156,1,28,1
143,5,156,4,0,
143,5,164,2,0,
143,5,173,1,28,2
143,5,174,2,0,
143,5,182,4,0,
143,5,187,1,15,
143,5,201,4,0,
143,5,204,2,0,
143,5,205,1,46,
143,5,213,4,0,
143,5,216,4,0,
143,5,218,4,0,
143,5,237,4,0,
143,5,240,4,0,
143,5,241,4,0,
143,5,247,4,0,
143,5,263,4,0,
143,5,264,4,0,
143,5,280,4,0,
143,5,281,1,24,
143,5,290,4,0,
143,5,317,4,0,
143,5,335,1,37,
143,5,343,1,42,
143,5,351,4,0,
143,5,352,4,0,
143,6,5,3,0,
143,6,7,3,0,
143,6,8,3,0,
143,6,9,3,0,
143,6,25,3,0,
143,6,29,1,19,
143,6,33,1,1,
143,6,34,1,33,
143,6,34,3,0,
143,6,38,2,0,
143,6,38,3,0,
143,6,53,4,0,
143,6,57,4,0,
143,6,58,4,0,
143,6,59,4,0,
143,6,63,1,51,
143,6,63,4,0,
143,6,68,3,0,
143,6,69,3,0,
143,6,70,4,0,
143,6,76,4,0,
143,6,85,4,0,
143,6,87,4,0,
143,6,89,4,0,
143,6,90,2,0,
143,6,92,4,0,
143,6,94,4,0,
143,6,102,3,0,
143,6,104,4,0,
143,6,111,1,10,
143,6,111,3,0,
143,6,118,3,0,
143,6,122,2,0,
143,6,126,4,0,
143,6,133,1,6,
143,6,156,1,28,1
143,6,156,4,0,
143,6,157,3,0,
143,6,164,2,0,
143,6,164,3,0,
143,6,173,1,28,2
143,6,173,3,0,
143,6,174,2,0,
143,6,182,4,0,
143,6,187,1,15,
143,6,189,3,0,
143,6,196,3,0,
143,6,201,4,0,
143,6,203,3,0,
143,6,204,2,0,
143,6,205,1,46,
143,6,205,3,0,
143,6,207,3,0,
143,6,213,4,0,
143,6,214,3,0,
143,6,216,4,0,
143,6,218,4,0,
143,6,223,3,0,
143,6,237,4,0,
143,6,240,4,0,
143,6,241,4,0,
143,6,244,3,0,
143,6,247,4,0,
143,6,263,4,0,
143,6,264,4,0,
143,6,280,4,0,
143,6,281,1,24,
143,6,290,4,0,
143,6,317,4,0,
143,6,335,1,37,
143,6,343,1,42,
143,6,351,4,0,
143,6,352,4,0,
143,7,5,3,0,
143,7,25,3,0,
143,7,29,1,17,
143,7,33,1,1,
143,7,34,1,33,
143,7,34,3,0,
143,7,38,2,0,
143,7,38,3,0,
143,7,53,4,0,
143,7,57,4,0,
143,7,58,4,0,
143,7,59,4,0,
143,7,63,1,53,
143,7,63,4,0,
143,7,68,3,0,
143,7,69,3,0,
143,7,70,4,0,
143,7,76,4,0,
143,7,85,4,0,
143,7,87,4,0,
143,7,89,4,0,
143,7,90,2,0,
143,7,92,4,0,
143,7,94,4,0,
143,7,102,3,0,
143,7,104,4,0,
143,7,111,1,9,
143,7,118,3,0,
143,7,122,2,0,
143,7,126,4,0,
143,7,133,1,5,
143,7,156,1,25,
143,7,156,4,0,
143,7,157,3,0,
143,7,164,2,0,
143,7,164,3,0,
143,7,173,1,29,
143,7,174,2,0,
143,7,182,4,0,
143,7,187,1,13,
143,7,201,4,0,
143,7,204,2,0,
143,7,205,1,49,
143,7,213,4,0,
143,7,214,1,37,
143,7,216,4,0,
143,7,218,4,0,
143,7,237,4,0,
143,7,240,4,0,
143,7,241,4,0,
143,7,247,4,0,
143,7,263,4,0,
143,7,264,4,0,
143,7,280,4,0,
143,7,281,1,21,
143,7,290,4,0,
143,7,317,4,0,
143,7,335,1,41,
143,7,343,1,45,
143,7,351,4,0,
143,7,352,4,0,
143,8,18,2,0,
143,8,33,1,1,
143,8,34,1,33,
143,8,38,2,0,
143,8,53,4,0,
143,8,57,4,0,
143,8,58,4,0,
143,8,59,4,0,
143,8,63,4,0,
143,8,70,4,0,
143,8,76,4,0,
143,8,85,4,0,
143,8,87,4,0,
143,8,89,4,0,
143,8,90,2,0,
143,8,92,4,0,
143,8,94,4,0,
143,8,104,4,0,
143,8,111,1,4,
143,8,122,1,12,
143,8,122,2,0,
143,8,126,4,0,
143,8,133,1,9,
143,8,156,1,25,
143,8,156,4,0,
143,8,157,4,0,
143,8,164,2,0,
143,8,164,4,0,
143,8,173,1,28,1
143,8,174,2,0,
143,8,182,4,0,
143,8,187,1,17,
143,8,201,4,0,
143,8,203,4,0,
143,8,204,2,0,
143,8,205,1,41,
143,8,207,4,0,
143,8,213,4,0,
143,8,214,1,28,2
143,8,214,4,0,
143,8,216,4,0,
143,8,218,4,0,
143,8,228,2,0,
143,8,237,4,0,
143,8,240,4,0,
143,8,241,4,0,
143,8,242,1,44,
143,8,247,4,0,
143,8,249,4,0,
143,8,263,4,0,
143,8,264,4,0,
143,8,278,4,0,
143,8,280,4,0,
143,8,281,1,20,
143,8,290,4,0,
143,8,317,4,0,
143,8,335,1,36,
143,8,351,4,0,
143,8,352,4,0,
143,8,363,4,0,
143,8,374,4,0,
143,8,411,4,0,
143,8,416,1,49,
143,8,416,4,0,
143,8,431,4,0,
143,8,445,4,0,
143,9,7,3,0,
143,9,8,3,0,
143,9,9,3,0,
143,9,18,2,0,
143,9,33,1,1,
143,9,34,1,33,
143,9,38,2,0,
143,9,53,4,0,
143,9,57,4,0,
143,9,58,4,0,
143,9,59,4,0,
143,9,63,4,0,
143,9,70,4,0,
143,9,76,4,0,
143,9,85,4,0,
143,9,87,4,0,
143,9,89,4,0,
143,9,90,2,0,
143,9,92,4,0,
143,9,94,4,0,
143,9,104,4,0,
143,9,111,1,4,
143,9,122,1,12,
143,9,122,2,0,
143,9,126,4,0,
143,9,133,1,9,
143,9,156,1,25,
143,9,156,4,0,
143,9,157,4,0,
143,9,164,2,0,
143,9,164,4,0,
143,9,173,1,28,1
143,9,173,3,0,
143,9,174,2,0,
143,9,182,4,0,
143,9,187,1,17,
143,9,189,3,0,
143,9,196,3,0,
143,9,200,3,0,
143,9,201,4,0,
143,9,203,4,0,
143,9,204,2,0,
143,9,205,1,41,
143,9,205,3,0,
143,9,207,4,0,
143,9,213,4,0,
143,9,214,1,28,2
143,9,214,4,0,
143,9,216,4,0,
143,9,218,4,0,
143,9,228,2,0,
143,9,237,4,0,
143,9,240,4,0,
143,9,241,4,0,
143,9,242,1,44,
143,9,247,4,0,
143,9,249,4,0,
143,9,263,4,0,
143,9,264,4,0,
143,9,276,3,0,
143,9,278,4,0,
143,9,280,4,0,
143,9,281,1,20,
143,9,290,4,0,
143,9,317,4,0,
143,9,335,1,36,
143,9,351,4,0,
143,9,352,4,0,
143,9,363,4,0,
143,9,374,4,0,
143,9,387,3,0,
143,9,402,3,0,
143,9,411,4,0,
143,9,416,1,49,
143,9,416,4,0,
143,9,428,3,0,
143,9,431,4,0,
143,9,441,3,0,
143,9,442,3,0,
143,9,445,4,0,
143,10,7,3,0,
143,10,8,3,0,
143,10,9,3,0,
143,10,18,2,0,
143,10,29,3,0,
143,10,33,1,1,
143,10,34,1,33,
143,10,38,2,0,
143,10,53,4,0,
143,10,57,4,0,
143,10,58,4,0,
143,10,59,4,0,
143,10,63,4,0,
143,10,68,2,0,
143,10,70,4,0,
143,10,76,4,0,
143,10,85,4,0,
143,10,87,4,0,
143,10,89,4,0,
143,10,90,2,0,
143,10,92,4,0,
143,10,94,4,0,
143,10,104,4,0,
143,10,111,1,4,
143,10,122,1,12,
143,10,122,2,0,
143,10,126,4,0,
143,10,133,1,9,
143,10,156,1,25,
143,10,156,4,0,
143,10,157,4,0,
143,10,164,2,0,
143,10,164,4,0,
143,10,173,1,28,1
143,10,173,3,0,
143,10,174,2,0,
143,10,182,4,0,
143,10,187,1,17,
143,10,189,3,0,
143,10,196,3,0,
143,10,200,3,0,
143,10,201,4,0,
143,10,203,4,0,
143,10,204,2,0,
143,10,205,1,41,
143,10,205,3,0,
143,10,207,4,0,
143,10,213,4,0,
143,10,214,1,28,2
143,10,214,4,0,
143,10,216,4,0,
143,10,218,4,0,
143,10,228,2,0,
143,10,237,4,0,
143,10,240,4,0,
143,10,241,4,0,
143,10,242,1,44,
143,10,247,4,0,
143,10,249,4,0,
143,10,250,4,0,
143,10,263,4,0,
143,10,264,4,0,
143,10,276,3,0,
143,10,278,4,0,
143,10,280,4,0,
143,10,281,1,20,
143,10,290,4,0,
143,10,317,4,0,
143,10,335,1,36,
143,10,335,3,0,
143,10,351,4,0,
143,10,352,4,0,
143,10,363,4,0,
143,10,374,4,0,
143,10,387,3,0,
143,10,402,3,0,
143,10,411,4,0,
143,10,416,1,49,
143,10,416,4,0,
143,10,428,3,0,
143,10,431,4,0,
143,10,441,3,0,
143,10,442,3,0,
143,10,445,4,0,
143,11,18,2,0,
143,11,33,1,1,
143,11,34,1,36,
143,11,38,2,0,
143,11,53,4,0,
143,11,57,4,0,
143,11,58,4,0,
143,11,59,4,0,
143,11,63,4,0,
143,11,68,2,0,
143,11,70,4,0,
143,11,76,4,0,
143,11,85,4,0,
143,11,87,4,0,
143,11,89,4,0,
143,11,90,2,0,
143,11,92,4,0,
143,11,94,4,0,
143,11,104,4,0,
143,11,111,1,4,
143,11,122,1,12,
143,11,122,2,0,
143,11,126,4,0,
143,11,133,1,9,
143,11,156,1,28,1
143,11,156,4,0,
143,11,157,4,0,
143,11,164,4,0,
143,11,173,1,28,2
143,11,174,2,0,
143,11,182,4,0,
143,11,187,1,17,
143,11,201,4,0,
143,11,204,2,0,
143,11,205,1,44,
143,11,207,4,0,
143,11,213,4,0,
143,11,214,1,33,
143,11,216,4,0,
143,11,218,4,0,
143,11,228,2,0,
143,11,237,4,0,
143,11,240,4,0,
143,11,241,4,0,
143,11,242,1,49,
143,11,247,4,0,
143,11,249,4,0,
143,11,263,4,0,
143,11,280,4,0,
143,11,281,1,20,
143,11,317,4,0,
143,11,335,1,41,
143,11,363,2,0,
143,11,374,4,0,
143,11,411,4,0,
143,11,416,1,57,
143,11,416,4,0,
143,11,479,4,0,
143,11,484,1,52,
143,11,495,2,0,
143,11,496,4,0,
143,11,498,1,25,
143,11,510,4,0,
143,11,514,4,0,
143,11,523,4,0,
143,11,526,4,0,
143,11,528,4,0,
143,12,29,1,19,
143,12,33,1,1,
143,12,34,1,33,
143,12,53,4,0,
143,12,57,4,0,
143,12,58,4,0,
143,12,59,4,0,
143,12,63,1,51,
143,12,63,4,0,
143,12,70,4,0,
143,12,76,4,0,
143,12,85,4,0,
143,12,87,4,0,
143,12,89,4,0,
143,12,92,4,0,
143,12,94,4,0,
143,12,104,4,0,
143,12,111,1,10,
143,12,126,4,0,
143,12,133,1,6,
143,12,156,1,28,1
143,12,156,4,0,
143,12,173,1,28,2
143,12,182,4,0,
143,12,187,1,15,
143,12,201,4,0,
143,12,205,1,46,
143,12,213,4,0,
143,12,216,4,0,
143,12,218,4,0,
143,12,237,4,0,
143,12,240,4,0,
143,12,241,4,0,
143,12,247,4,0,
143,12,263,4,0,
143,12,264,4,0,
143,12,280,4,0,
143,12,281,1,24,
143,12,290,4,0,
143,12,317,4,0,
143,12,335,1,37,
143,12,343,1,42,
143,12,351,4,0,
143,12,352,4,0,
143,13,29,1,19,
143,13,33,1,1,
143,13,34,1,33,
143,13,34,3,0,
143,13,38,3,0,
143,13,53,4,0,
143,13,57,4,0,
143,13,58,4,0,
143,13,59,4,0,
143,13,63,1,51,
143,13,63,4,0,
143,13,69,3,0,
143,13,70,4,0,
143,13,76,4,0,
143,13,85,4,0,
143,13,87,4,0,
143,13,89,4,0,
143,13,92,4,0,
143,13,94,4,0,
143,13,102,3,0,
143,13,104,4,0,
143,13,111,1,10,
143,13,120,3,0,
143,13,126,4,0,
143,13,133,1,6,
143,13,156,1,28,1
143,13,156,4,0,
143,13,164,3,0,
143,13,173,1,28,2
143,13,182,4,0,
143,13,187,1,15,
143,13,196,3,0,
143,13,201,4,0,
143,13,205,1,46,
143,13,207,3,0,
143,13,213,4,0,
143,13,216,4,0,
143,13,218,4,0,
143,13,237,4,0,
143,13,240,4,0,
143,13,241,4,0,
143,13,247,4,0,
143,13,263,4,0,
143,13,264,4,0,
143,13,280,4,0,
143,13,281,1,24,
143,13,290,4,0,
143,13,317,4,0,
143,13,335,1,37,
143,13,343,1,42,
143,13,351,4,0,
143,13,352,4,0,
143,14,7,3,0,
143,14,8,3,0,
143,14,9,3,0,
143,14,18,2,0,
143,14,33,1,1,
143,14,34,1,36,
143,14,38,2,0,
143,14,53,4,0,
143,14,57,4,0,
143,14,58,4,0,
143,14,59,4,0,
143,14,63,4,0,
143,14,68,2,0,
143,14,70,4,0,
143,14,76,4,0,
143,14,85,4,0,
143,14,87,4,0,
143,14,89,4,0,
143,14,90,2,0,
143,14,92,4,0,
143,14,94,4,0,
143,14,104,4,0,
143,14,111,1,4,
143,14,122,1,12,
143,14,122,2,0,
143,14,126,4,0,
143,14,133,1,9,
143,14,156,1,28,1
143,14,156,4,0,
143,14,157,4,0,
143,14,164,4,0,
143,14,173,1,28,2
143,14,173,3,0,
143,14,174,2,0,
143,14,182,4,0,
143,14,187,1,17,
143,14,196,3,0,
143,14,200,3,0,
143,14,201,4,0,
143,14,204,2,0,
143,14,205,1,44,
143,14,207,4,0,
143,14,213,4,0,
143,14,214,1,33,
143,14,214,3,0,
143,14,216,4,0,
143,14,218,4,0,
143,14,228,2,0,
143,14,237,4,0,
143,14,240,4,0,
143,14,241,4,0,
143,14,242,1,49,
143,14,247,4,0,
143,14,249,4,0,
143,14,263,4,0,
143,14,276,3,0,
143,14,278,3,0,
143,14,280,4,0,
143,14,281,1,20,
143,14,304,3,0,
143,14,317,4,0,
143,14,335,1,41,
143,14,335,3,0,
143,14,343,3,0,
143,14,363,2,0,
143,14,374,4,0,
143,14,387,3,0,
143,14,402,3,0,
143,14,411,4,0,
143,14,416,1,57,
143,14,416,4,0,
143,14,428,3,0,
143,14,441,3,0,
143,14,442,3,0,
143,14,479,4,0,
143,14,484,1,52,
143,14,495,2,0,
143,14,495,3,0,
143,14,496,4,0,
143,14,498,1,25,
143,14,510,4,0,
143,14,514,4,0,
143,14,523,4,0,
143,14,526,4,0,
143,14,528,4,0,
143,15,18,2,0,
143,15,33,1,1,
143,15,34,1,25,
143,15,38,2,0,
143,15,53,4,0,
143,15,57,4,0,
143,15,58,4,0,
143,15,59,4,0,
143,15,63,4,0,
143,15,68,2,0,
143,15,70,4,0,
143,15,76,4,0,
143,15,85,4,0,
143,15,87,4,0,
143,15,89,4,0,
143,15,90,2,0,
143,15,92,4,0,
143,15,94,4,0,
143,15,104,4,0,
143,15,111,1,4,
143,15,122,1,12,
143,15,122,2,0,
143,15,126,4,0,
143,15,133,1,9,
143,15,156,1,28,1
143,15,156,4,0,
143,15,157,4,0,
143,15,164,4,0,
143,15,173,1,28,2
143,15,174,2,0,
143,15,182,4,0,
143,15,187,1,44,
143,15,201,4,0,
143,15,204,2,0,
143,15,205,1,36,
143,15,207,4,0,
143,15,213,4,0,
143,15,214,1,33,
143,15,214,4,0,
143,15,216,4,0,
143,15,218,4,0,
143,15,228,2,0,
143,15,237,4,0,
143,15,240,4,0,
143,15,241,4,0,
143,15,242,1,49,
143,15,247,4,0,
143,15,249,4,0,
143,15,263,4,0,
143,15,280,4,0,
143,15,281,1,20,
143,15,317,4,0,
143,15,335,1,41,
143,15,363,2,0,
143,15,374,4,0,
143,15,411,4,0,
143,15,416,1,57,
143,15,416,4,0,
143,15,479,4,0,
143,15,484,1,50,
143,15,495,2,0,
143,15,496,4,0,
143,15,498,1,17,
143,15,510,4,0,
143,15,514,4,0,
143,15,523,4,0,
143,15,528,4,0,
143,15,562,2,0,
143,15,590,4,0,
143,15,612,4,0,
143,16,7,3,0,
143,16,8,3,0,
143,16,9,3,0,
143,16,18,2,0,
143,16,33,1,1,1
143,16,34,1,25,1
143,16,38,2,0,
143,16,53,4,0,
143,16,57,4,0,
143,16,58,4,0,
143,16,59,4,0,
143,16,63,4,0,
143,16,68,2,0,
143,16,70,4,0,
143,16,76,4,0,
143,16,85,4,0,
143,16,87,4,0,
143,16,89,4,0,
143,16,90,2,0,
143,16,92,4,0,
143,16,94,4,0,
143,16,104,4,0,
143,16,111,1,4,1
143,16,122,1,12,1
143,16,122,2,0,
143,16,126,4,0,
143,16,133,1,9,1
143,16,156,1,28,1
143,16,156,4,0,
143,16,157,4,0,
143,16,164,4,0,
143,16,173,1,28,2
143,16,173,3,0,
143,16,174,2,0,
143,16,182,4,0,
143,16,187,1,44,1
143,16,196,3,0,
143,16,200,3,0,
143,16,201,4,0,
143,16,204,2,0,
143,16,205,1,36,1
143,16,207,4,0,
143,16,213,4,0,
143,16,214,1,33,1
143,16,214,4,0,
143,16,216,4,0,
143,16,218,4,0,
143,16,228,2,0,
143,16,237,4,0,
143,16,240,4,0,
143,16,241,4,0,
143,16,242,1,49,1
143,16,247,4,0,
143,16,249,4,0,
143,16,263,4,0,
143,16,264,3,0,
143,16,276,3,0,
143,16,278,3,0,
143,16,280,4,0,
143,16,281,1,20,1
143,16,290,4,0,
143,16,304,3,0,
143,16,317,4,0,
143,16,335,1,41,1
143,16,335,3,0,
143,16,343,3,0,
143,16,351,3,0,
143,16,352,3,0,
143,16,363,2,0,
143,16,374,4,0,
143,16,387,3,0,
143,16,402,3,0,
143,16,411,4,0,
143,16,416,1,57,1
143,16,416,4,0,
143,16,428,3,0,
143,16,441,3,0,
143,16,442,3,0,
143,16,479,4,0,
143,16,484,1,50,1
143,16,495,2,0,
143,16,495,3,0,
143,16,496,4,0,
143,16,498,1,17,1
143,16,510,4,0,
143,16,514,4,0,
143,16,523,4,0,
143,16,528,4,0,
143,16,562,2,0,
143,16,590,4,0,
143,16,612,4,0,
143,17,18,2,0,
143,17,33,1,1,
143,17,34,1,25,
143,17,38,2,0,
143,17,53,4,0,
143,17,57,4,0,
143,17,58,4,0,
143,17,59,4,0,
143,17,63,4,0,
143,17,68,2,0,
143,17,76,4,0,
143,17,85,4,0,
143,17,87,4,0,
143,17,89,4,0,
143,17,90,2,0,
143,17,92,4,0,
143,17,94,4,0,
143,17,104,4,0,
143,17,111,1,4,
143,17,122,1,12,
143,17,122,2,0,
143,17,126,4,0,
143,17,133,1,9,
143,17,156,1,28,1
143,17,156,4,0,
143,17,157,4,0,
143,17,164,4,0,
143,17,173,1,28,2
143,17,174,2,0,
143,17,182,4,0,
143,17,187,1,44,
143,17,201,4,0,
143,17,204,2,0,
143,17,205,1,36,
143,17,207,4,0,
143,17,213,4,0,
143,17,214,1,33,
143,17,214,4,0,
143,17,216,4,0,
143,17,218,4,0,
143,17,228,2,0,
143,17,237,4,0,
143,17,240,4,0,
143,17,241,4,0,
143,17,242,1,49,
143,17,247,4,0,
143,17,263,4,0,
143,17,280,4,0,
143,17,281,1,20,
143,17,317,4,0,
143,17,335,1,41,
143,17,363,2,0,
143,17,374,4,0,
143,17,411,4,0,
143,17,416,1,35,
143,17,416,4,0,
143,17,479,4,0,
143,17,484,1,50,
143,17,495,2,0,
143,17,496,4,0,
143,17,498,1,17,
143,17,523,4,0,
143,17,526,4,0,
143,17,528,4,0,
143,17,562,2,0,
143,17,590,4,0,
143,17,667,1,57,
143,18,18,2,0,
143,18,33,1,1,
143,18,34,1,25,
143,18,38,2,0,
143,18,53,4,0,
143,18,57,4,0,
143,18,58,4,0,
143,18,59,4,0,
143,18,63,4,0,
143,18,68,2,0,
143,18,76,4,0,
143,18,85,4,0,
143,18,87,4,0,
143,18,89,4,0,
143,18,90,2,0,
143,18,92,4,0,
143,18,94,4,0,
143,18,104,4,0,
143,18,111,1,4,
143,18,122,1,12,
143,18,122,2,0,
143,18,126,4,0,
143,18,133,1,9,
143,18,156,1,28,1
143,18,156,4,0,
143,18,157,4,0,
143,18,164,4,0,
143,18,173,1,28,2
143,18,174,2,0,
143,18,182,4,0,
143,18,187,1,44,
143,18,201,4,0,
143,18,204,2,0,
143,18,205,1,36,
143,18,207,4,0,
143,18,213,4,0,
143,18,214,1,33,
143,18,214,4,0,
143,18,216,4,0,
143,18,218,4,0,
143,18,228,2,0,
143,18,237,4,0,
143,18,240,4,0,
143,18,241,4,0,
143,18,242,1,49,
143,18,247,4,0,
143,18,263,4,0,
143,18,280,4,0,
143,18,281,1,20,
143,18,317,4,0,
143,18,335,1,41,
143,18,363,2,0,
143,18,374,4,0,
143,18,411,4,0,
143,18,416,1,35,
143,18,416,4,0,
143,18,479,4,0,
143,18,484,1,50,
143,18,495,2,0,
143,18,496,4,0,
143,18,498,1,17,
143,18,523,4,0,
143,18,526,4,0,
143,18,528,4,0,
143,18,562,2,0,
143,18,590,4,0,
143,18,612,2,0,
143,18,667,1,57,
144,1,13,4,0,
144,1,18,4,0,
144,1,19,4,0,
144,1,36,4,0,
144,1,38,4,0,
144,1,54,1,60,
144,1,55,4,0,
144,1,58,1,1,2
144,1,58,4,0,
144,1,59,1,51,
144,1,59,4,0,
144,1,61,4,0,
144,1,63,4,0,
144,1,64,1,1,1
144,1,92,4,0,
144,1,97,1,55,
144,1,99,4,0,
144,1,102,4,0,
144,1,104,4,0,
144,1,115,4,0,
144,1,117,4,0,
144,1,129,4,0,
144,1,143,4,0,
144,1,156,4,0,
144,1,164,4,0,
144,2,13,4,0,
144,2,18,4,0,
144,2,19,4,0,
144,2,36,4,0,
144,2,38,4,0,
144,2,54,1,60,
144,2,55,4,0,
144,2,58,1,1,2
144,2,58,4,0,
144,2,59,1,51,
144,2,59,4,0,
144,2,61,4,0,
144,2,63,4,0,
144,2,64,1,1,1
144,2,92,4,0,
144,2,97,1,55,
144,2,99,4,0,
144,2,102,4,0,
144,2,104,4,0,
144,2,115,4,0,
144,2,117,4,0,
144,2,129,4,0,
144,2,143,4,0,
144,2,156,4,0,
144,2,164,4,0,
144,3,16,1,1,1
144,3,19,4,0,
144,3,46,4,0,
144,3,54,1,13,
144,3,58,1,49,
144,3,59,1,73,
144,3,59,4,0,
144,3,63,4,0,
144,3,92,4,0,
144,3,97,1,25,
144,3,104,4,0,
144,3,115,1,61,
144,3,129,4,0,
144,3,156,4,0,
144,3,170,1,37,
144,3,173,4,0,
144,3,174,4,0,
144,3,181,1,1,2
144,3,182,4,0,
144,3,189,4,0,
144,3,196,4,0,
144,3,197,4,0,
144,3,201,4,0,
144,3,203,4,0,
144,3,207,4,0,
144,3,211,4,0,
144,3,214,4,0,
144,3,216,4,0,
144,3,218,4,0,
144,3,237,4,0,
144,3,240,4,0,
144,3,241,4,0,
144,3,249,4,0,
144,4,16,1,1,1
144,4,19,4,0,
144,4,46,4,0,
144,4,54,1,13,
144,4,58,1,49,
144,4,58,3,0,
144,4,59,1,73,
144,4,59,4,0,
144,4,63,4,0,
144,4,92,4,0,
144,4,97,1,25,
144,4,104,4,0,
144,4,115,1,61,
144,4,129,4,0,
144,4,156,4,0,
144,4,170,1,37,
144,4,173,4,0,
144,4,174,4,0,
144,4,181,1,1,2
144,4,182,4,0,
144,4,189,4,0,
144,4,196,4,0,
144,4,197,4,0,
144,4,201,4,0,
144,4,203,4,0,
144,4,207,4,0,
144,4,211,4,0,
144,4,214,4,0,
144,4,216,4,0,
144,4,218,4,0,
144,4,237,4,0,
144,4,240,4,0,
144,4,241,4,0,
144,4,249,4,0,
144,5,16,1,1,1
144,5,19,4,0,
144,5,46,4,0,
144,5,54,1,13,
144,5,58,1,49,
144,5,58,4,0,
144,5,59,1,73,
144,5,59,4,0,
144,5,63,4,0,
144,5,92,4,0,
144,5,97,1,25,
144,5,104,4,0,
144,5,115,1,61,
144,5,115,4,0,
144,5,156,4,0,
144,5,170,1,37,
144,5,181,1,1,2
144,5,182,4,0,
144,5,201,4,0,
144,5,211,4,0,
144,5,216,4,0,
144,5,218,4,0,
144,5,237,4,0,
144,5,240,4,0,
144,5,241,4,0,
144,5,249,4,0,
144,5,258,4,0,
144,5,263,4,0,
144,5,290,4,0,
144,5,329,1,85,
144,5,332,4,0,
144,5,352,4,0,
144,6,16,1,1,1
144,6,19,4,0,
144,6,38,3,0,
144,6,46,4,0,
144,6,54,1,13,
144,6,58,1,49,
144,6,58,4,0,
144,6,59,1,73,
144,6,59,4,0,
144,6,63,4,0,
144,6,92,4,0,
144,6,97,1,25,
144,6,102,3,0,
144,6,104,4,0,
144,6,115,1,61,
144,6,115,4,0,
144,6,129,3,0,
144,6,156,4,0,
144,6,164,3,0,
144,6,170,1,37,
144,6,173,3,0,
144,6,181,1,1,2
144,6,182,4,0,
144,6,189,3,0,
144,6,196,3,0,
144,6,201,4,0,
144,6,203,3,0,
144,6,207,3,0,
144,6,211,4,0,
144,6,214,3,0,
144,6,216,4,0,
144,6,218,4,0,
144,6,237,4,0,
144,6,240,4,0,
144,6,241,4,0,
144,6,249,4,0,
144,6,258,4,0,
144,6,263,4,0,
144,6,290,4,0,
144,6,329,1,85,
144,6,332,4,0,
144,6,352,4,0,
144,7,16,1,1,1
144,7,19,4,0,
144,7,38,3,0,
144,7,46,4,0,
144,7,54,1,13,
144,7,58,1,49,
144,7,58,4,0,
144,7,59,1,73,
144,7,59,4,0,
144,7,63,4,0,
144,7,92,4,0,
144,7,97,1,25,
144,7,102,3,0,
144,7,104,4,0,
144,7,115,1,61,
144,7,115,4,0,
144,7,156,4,0,
144,7,164,3,0,
144,7,170,1,37,
144,7,181,1,1,2
144,7,182,4,0,
144,7,201,4,0,
144,7,211,4,0,
144,7,216,4,0,
144,7,218,4,0,
144,7,237,4,0,
144,7,240,4,0,
144,7,241,4,0,
144,7,249,4,0,
144,7,258,4,0,
144,7,263,4,0,
144,7,290,4,0,
144,7,329,1,85,
144,7,332,4,0,
144,7,352,4,0,
144,8,16,1,1,1
144,8,19,4,0,
144,8,46,4,0,
144,8,54,1,8,
144,8,58,1,43,
144,8,58,4,0,
144,8,59,1,71,
144,8,59,4,0,
144,8,63,4,0,
144,8,92,4,0,
144,8,97,1,36,
144,8,104,4,0,
144,8,115,1,50,
144,8,115,4,0,
144,8,156,4,0,
144,8,164,4,0,
144,8,170,1,22,
144,8,181,1,1,2
144,8,182,4,0,
144,8,201,4,0,
144,8,203,4,0,
144,8,207,4,0,
144,8,211,4,0,
144,8,214,4,0,
144,8,216,4,0,
144,8,218,4,0,
144,8,237,4,0,
144,8,240,4,0,
144,8,241,4,0,
144,8,246,1,29,
144,8,249,4,0,
144,8,258,1,85,
144,8,258,4,0,
144,8,263,4,0,
144,8,290,4,0,
144,8,329,1,78,
144,8,332,4,0,
144,8,352,4,0,
144,8,355,1,57,
144,8,355,4,0,
144,8,363,4,0,
144,8,365,4,0,
144,8,366,1,64,
144,8,369,4,0,
144,8,416,4,0,
144,8,419,4,0,
144,8,420,1,15,
144,8,432,4,0,
144,9,16,1,1,1
144,9,19,4,0,
144,9,46,4,0,
144,9,54,1,8,
144,9,58,1,43,
144,9,58,4,0,
144,9,59,1,71,
144,9,59,4,0,
144,9,63,4,0,
144,9,92,4,0,
144,9,97,1,36,
144,9,104,4,0,
144,9,115,1,50,
144,9,115,4,0,
144,9,129,3,0,
144,9,156,4,0,
144,9,164,4,0,
144,9,170,1,22,
144,9,173,3,0,
144,9,181,1,1,2
144,9,182,4,0,
144,9,189,3,0,
144,9,196,3,0,
144,9,201,4,0,
144,9,203,4,0,
144,9,207,4,0,
144,9,211,4,0,
144,9,214,4,0,
144,9,216,4,0,
144,9,218,4,0,
144,9,237,4,0,
144,9,239,3,0,
144,9,240,4,0,
144,9,241,4,0,
144,9,246,1,29,
144,9,246,3,0,
144,9,249,4,0,
144,9,258,1,85,
144,9,258,4,0,
144,9,263,4,0,
144,9,290,4,0,
144,9,314,3,0,
144,9,324,3,0,
144,9,329,1,78,
144,9,332,4,0,
144,9,352,4,0,
144,9,355,1,57,
144,9,355,4,0,
144,9,363,4,0,
144,9,365,4,0,
144,9,366,1,64,
144,9,369,4,0,
144,9,416,4,0,
144,9,419,4,0,
144,9,420,1,15,
144,9,432,4,0,
144,9,466,3,0,
144,10,16,1,1,1
144,10,19,4,0,
144,10,46,4,0,
144,10,54,1,8,
144,10,58,1,43,
144,10,58,4,0,
144,10,59,1,71,
144,10,59,4,0,
144,10,63,4,0,
144,10,92,4,0,
144,10,97,1,36,
144,10,104,4,0,
144,10,115,1,50,
144,10,115,4,0,
144,10,129,3,0,
144,10,143,3,0,
144,10,156,4,0,
144,10,164,4,0,
144,10,170,1,22,
144,10,173,3,0,
144,10,181,1,1,2
144,10,182,4,0,
144,10,189,3,0,
144,10,196,3,0,
144,10,201,4,0,
144,10,203,4,0,
144,10,207,4,0,
144,10,211,4,0,
144,10,214,4,0,
144,10,216,4,0,
144,10,218,4,0,
144,10,237,4,0,
144,10,239,3,0,
144,10,240,4,0,
144,10,241,4,0,
144,10,246,1,29,
144,10,246,3,0,
144,10,249,4,0,
144,10,258,1,85,
144,10,258,4,0,
144,10,263,4,0,
144,10,290,4,0,
144,10,314,3,0,
144,10,324,3,0,
144,10,329,1,78,
144,10,332,4,0,
144,10,352,4,0,
144,10,355,1,57,
144,10,355,4,0,
144,10,363,4,0,
144,10,365,4,0,
144,10,366,1,64,
144,10,366,3,0,
144,10,369,4,0,
144,10,416,4,0,
144,10,419,4,0,
144,10,420,1,15,
144,10,466,3,0,
144,11,16,1,1,1
144,11,19,4,0,
144,11,46,4,0,
144,11,54,1,8,
144,11,58,1,43,
144,11,58,4,0,
144,11,59,1,71,
144,11,59,4,0,
144,11,63,4,0,
144,11,92,4,0,
144,11,97,1,36,
144,11,104,4,0,
144,11,115,1,50,
144,11,115,4,0,
144,11,156,4,0,
144,11,164,4,0,
144,11,170,1,22,
144,11,181,1,1,2
144,11,182,4,0,
144,11,201,4,0,
144,11,207,4,0,
144,11,216,4,0,
144,11,218,4,0,
144,11,237,4,0,
144,11,240,4,0,
144,11,241,4,0,
144,11,246,1,29,
144,11,249,4,0,
144,11,258,1,85,
144,11,258,4,0,
144,11,263,4,0,
144,11,329,1,78,
144,11,332,4,0,
144,11,355,1,57,
144,11,365,4,0,
144,11,366,1,64,
144,11,369,4,0,
144,11,416,4,0,
144,11,420,1,15,
144,11,496,4,0,
144,11,507,4,0,
144,11,524,4,0,
144,12,16,1,1,1
144,12,19,4,0,
144,12,46,4,0,
144,12,54,1,13,
144,12,58,1,49,
144,12,58,4,0,
144,12,59,1,73,
144,12,59,4,0,
144,12,63,4,0,
144,12,92,4,0,
144,12,97,1,25,
144,12,104,4,0,
144,12,115,1,61,
144,12,115,4,0,
144,12,156,4,0,
144,12,170,1,37,
144,12,181,1,1,2
144,12,182,4,0,
144,12,201,4,0,
144,12,211,4,0,
144,12,216,4,0,
144,12,218,4,0,
144,12,237,4,0,
144,12,240,4,0,
144,12,241,4,0,
144,12,249,4,0,
144,12,258,4,0,
144,12,263,4,0,
144,12,290,4,0,
144,12,329,1,85,
144,12,332,4,0,
144,12,352,4,0,
144,13,16,1,1,1
144,13,19,4,0,
144,13,38,3,0,
144,13,46,4,0,
144,13,54,1,13,
144,13,58,1,49,
144,13,58,4,0,
144,13,59,1,73,
144,13,59,4,0,
144,13,63,4,0,
144,13,92,4,0,
144,13,97,1,25,
144,13,102,3,0,
144,13,104,4,0,
144,13,115,1,61,
144,13,115,4,0,
144,13,143,3,0,
144,13,156,4,0,
144,13,164,3,0,
144,13,170,1,37,
144,13,181,1,1,2
144,13,182,4,0,
144,13,196,3,0,
144,13,201,4,0,
144,13,207,3,0,
144,13,211,4,0,
144,13,216,4,0,
144,13,218,4,0,
144,13,237,4,0,
144,13,240,4,0,
144,13,241,4,0,
144,13,249,4,0,
144,13,258,4,0,
144,13,263,4,0,
144,13,290,4,0,
144,13,329,1,85,
144,13,332,4,0,
144,13,352,4,0,
144,14,16,1,1,1
144,14,19,4,0,
144,14,46,4,0,
144,14,54,1,8,
144,14,58,1,43,
144,14,58,4,0,
144,14,59,1,71,
144,14,59,4,0,
144,14,63,4,0,
144,14,92,4,0,
144,14,97,1,36,
144,14,104,4,0,
144,14,115,1,50,
144,14,115,4,0,
144,14,143,3,0,
144,14,156,4,0,
144,14,164,4,0,
144,14,170,1,22,
144,14,173,3,0,
144,14,181,1,1,2
144,14,182,4,0,
144,14,196,3,0,
144,14,201,4,0,
144,14,207,4,0,
144,14,214,3,0,
144,14,216,4,0,
144,14,218,4,0,
144,14,237,4,0,
144,14,240,4,0,
144,14,241,4,0,
144,14,246,1,29,
144,14,249,4,0,
144,14,258,1,85,
144,14,258,4,0,
144,14,263,4,0,
144,14,324,3,0,
144,14,329,1,78,
144,14,332,4,0,
144,14,355,1,57,
144,14,355,3,0,
144,14,365,4,0,
144,14,366,1,64,
144,14,366,3,0,
144,14,369,4,0,
144,14,416,4,0,
144,14,420,1,15,
144,14,496,4,0,
144,14,507,4,0,
144,14,524,4,0,
144,14,542,1,92,
144,15,16,1,1,6
144,15,19,4,0,
144,15,46,4,0,
144,15,54,1,8,
144,15,58,1,43,
144,15,58,4,0,
144,15,59,1,71,
144,15,59,4,0,
144,15,63,4,0,
144,15,92,4,0,
144,15,97,1,36,
144,15,104,4,0,
144,15,115,1,50,
144,15,115,4,0,
144,15,156,4,0,
144,15,164,4,0,
144,15,170,1,22,
144,15,181,1,1,7
144,15,182,4,0,
144,15,201,4,0,
144,15,207,4,0,
144,15,211,4,0,
144,15,214,4,0,
144,15,216,4,0,
144,15,218,4,0,
144,15,237,4,0,
144,15,240,4,0,
144,15,241,4,0,
144,15,246,1,29,
144,15,249,4,0,
144,15,258,1,57,
144,15,258,4,0,
144,15,263,4,0,
144,15,329,1,1,5
144,15,329,1,78,
144,15,332,4,0,
144,15,355,1,1,1
144,15,355,1,85,
144,15,355,4,0,
144,15,366,1,1,4
144,15,366,1,64,
144,15,369,4,0,
144,15,416,4,0,
144,15,420,1,15,
144,15,496,4,0,
144,15,507,4,0,
144,15,524,4,0,
144,15,542,1,1,2
144,15,542,1,92,
144,15,573,1,1,3
144,15,590,4,0,
144,16,16,1,1,6
144,16,19,4,0,
144,16,46,4,0,
144,16,54,1,8,1
144,16,58,1,43,1
144,16,58,4,0,
144,16,59,1,71,1
144,16,59,4,0,
144,16,63,4,0,
144,16,92,4,0,
144,16,97,1,36,1
144,16,104,4,0,
144,16,115,1,50,1
144,16,115,4,0,
144,16,143,3,0,
144,16,156,4,0,
144,16,164,4,0,
144,16,170,1,22,1
144,16,173,3,0,
144,16,181,1,1,7
144,16,182,4,0,
144,16,196,3,0,
144,16,201,4,0,
144,16,207,4,0,
144,16,211,4,0,
144,16,214,4,0,
144,16,216,4,0,
144,16,218,4,0,
144,16,237,4,0,
144,16,240,4,0,
144,16,241,4,0,
144,16,246,1,29,1
144,16,249,4,0,
144,16,258,1,57,1
144,16,258,4,0,
144,16,263,4,0,
144,16,290,4,0,
144,16,324,3,0,
144,16,329,1,1,5
144,16,329,1,78,1
144,16,332,4,0,
144,16,352,3,0,
144,16,355,1,1,1
144,16,355,1,85,1
144,16,355,4,0,
144,16,366,1,1,4
144,16,366,1,64,1
144,16,366,3,0,
144,16,369,4,0,
144,16,416,4,0,
144,16,420,1,15,1
144,16,496,4,0,
144,16,507,4,0,
144,16,524,4,0,
144,16,542,1,1,2
144,16,542,1,92,1
144,16,573,1,1,3
144,16,590,4,0,
144,17,16,1,1,1
144,17,19,4,0,
144,17,46,4,0,
144,17,54,1,8,
144,17,58,1,71,
144,17,58,4,0,
144,17,59,1,78,
144,17,59,4,0,
144,17,63,4,0,
144,17,92,4,0,
144,17,97,1,36,
144,17,104,4,0,
144,17,115,1,50,
144,17,115,4,0,
144,17,156,4,0,
144,17,164,4,0,
144,17,170,1,22,
144,17,181,1,1,2
144,17,182,4,0,
144,17,201,4,0,
144,17,207,4,0,
144,17,211,4,0,
144,17,214,4,0,
144,17,216,4,0,
144,17,218,4,0,
144,17,237,4,0,
144,17,240,4,0,
144,17,241,4,0,
144,17,246,1,29,
144,17,258,1,57,
144,17,258,4,0,
144,17,263,4,0,
144,17,329,1,99,
144,17,332,4,0,
144,17,355,1,85,
144,17,355,4,0,
144,17,366,1,64,
144,17,369,4,0,
144,17,416,4,0,
144,17,420,1,15,
144,17,496,4,0,
144,17,507,4,0,
144,17,524,4,0,
144,17,542,1,92,
144,17,573,1,43,
144,17,590,4,0,
144,17,694,4,0,
144,18,16,1,1,1
144,18,19,4,0,
144,18,46,4,0,
144,18,54,1,8,
144,18,58,1,71,
144,18,58,4,0,
144,18,59,1,78,
144,18,59,4,0,
144,18,63,4,0,
144,18,92,4,0,
144,18,97,1,36,
144,18,104,4,0,
144,18,115,1,50,
144,18,115,4,0,
144,18,156,4,0,
144,18,164,4,0,
144,18,170,1,22,
144,18,181,1,1,2
144,18,182,4,0,
144,18,201,4,0,
144,18,207,4,0,
144,18,211,4,0,
144,18,214,4,0,
144,18,216,4,0,
144,18,218,4,0,
144,18,237,4,0,
144,18,240,4,0,
144,18,241,4,0,
144,18,246,1,29,
144,18,258,1,57,
144,18,258,4,0,
144,18,263,4,0,
144,18,329,1,99,
144,18,332,4,0,
144,18,355,1,85,
144,18,355,4,0,
144,18,366,1,64,
144,18,369,4,0,
144,18,416,4,0,
144,18,420,1,15,
144,18,496,4,0,
144,18,507,4,0,
144,18,524,4,0,
144,18,542,1,92,
144,18,573,1,43,
144,18,590,4,0,
144,18,694,4,0,
145,1,13,4,0,
145,1,18,4,0,
145,1,19,4,0,
145,1,36,4,0,
145,1,38,4,0,
145,1,63,4,0,
145,1,65,1,1,2
145,1,84,1,1,1
145,1,85,4,0,
145,1,86,4,0,
145,1,87,1,51,
145,1,87,4,0,
145,1,92,4,0,
145,1,97,1,55,
145,1,99,4,0,
145,1,102,4,0,
145,1,104,4,0,
145,1,113,1,60,
145,1,115,4,0,
145,1,117,4,0,
145,1,129,4,0,
145,1,143,4,0,
145,1,148,4,0,
145,1,156,4,0,
145,1,164,4,0,
145,2,13,4,0,
145,2,18,4,0,
145,2,19,4,0,
145,2,36,4,0,
145,2,38,4,0,
145,2,63,4,0,
145,2,65,1,1,2
145,2,84,1,1,1
145,2,85,4,0,
145,2,86,4,0,
145,2,87,1,51,
145,2,87,4,0,
145,2,92,4,0,
145,2,97,1,55,
145,2,99,4,0,
145,2,102,4,0,
145,2,104,4,0,
145,2,113,1,60,
145,2,115,4,0,
145,2,117,4,0,
145,2,129,4,0,
145,2,143,4,0,
145,2,148,4,0,
145,2,156,4,0,
145,2,164,4,0,
145,3,19,4,0,
145,3,46,4,0,
145,3,63,4,0,
145,3,64,1,1,1
145,3,65,1,49,
145,3,84,1,1,2
145,3,86,1,13,
145,3,87,1,73,
145,3,87,4,0,
145,3,92,4,0,
145,3,97,1,25,
145,3,104,4,0,
145,3,113,1,61,
145,3,129,4,0,
145,3,148,4,0,
145,3,156,4,0,
145,3,173,4,0,
145,3,174,4,0,
145,3,182,4,0,
145,3,189,4,0,
145,3,192,4,0,
145,3,197,1,37,
145,3,197,4,0,
145,3,201,4,0,
145,3,203,4,0,
145,3,207,4,0,
145,3,211,4,0,
145,3,214,4,0,
145,3,216,4,0,
145,3,218,4,0,
145,3,237,4,0,
145,3,240,4,0,
145,3,241,4,0,
145,3,249,4,0,
145,4,19,4,0,
145,4,46,4,0,
145,4,63,4,0,
145,4,64,1,1,1
145,4,65,1,49,
145,4,84,1,1,2
145,4,85,3,0,
145,4,86,1,13,
145,4,87,1,73,
145,4,87,4,0,
145,4,92,4,0,
145,4,97,1,25,
145,4,104,4,0,
145,4,113,1,61,
145,4,129,4,0,
145,4,148,4,0,
145,4,156,4,0,
145,4,173,4,0,
145,4,174,4,0,
145,4,182,4,0,
145,4,189,4,0,
145,4,192,4,0,
145,4,197,1,37,
145,4,197,4,0,
145,4,201,4,0,
145,4,203,4,0,
145,4,207,4,0,
145,4,211,4,0,
145,4,214,4,0,
145,4,216,4,0,
145,4,218,4,0,
145,4,237,4,0,
145,4,240,4,0,
145,4,241,4,0,
145,4,249,4,0,
145,5,19,4,0,
145,5,46,4,0,
145,5,63,4,0,
145,5,64,1,1,1
145,5,65,1,49,
145,5,84,1,1,2
145,5,85,4,0,
145,5,86,1,13,
145,5,87,1,85,
145,5,87,4,0,
145,5,92,4,0,
145,5,97,1,25,
145,5,104,4,0,
145,5,113,1,73,
145,5,113,4,0,
145,5,148,4,0,
145,5,156,4,0,
145,5,182,4,0,
145,5,197,1,37,
145,5,201,4,0,
145,5,211,4,0,
145,5,216,4,0,
145,5,218,4,0,
145,5,237,4,0,
145,5,240,4,0,
145,5,241,4,0,
145,5,249,4,0,
145,5,263,4,0,
145,5,268,1,61,
145,5,290,4,0,
145,5,332,4,0,
145,5,351,4,0,
145,6,19,4,0,
145,6,38,3,0,
145,6,46,4,0,
145,6,63,4,0,
145,6,64,1,1,1
145,6,65,1,49,
145,6,84,1,1,2
145,6,85,4,0,
145,6,86,1,13,
145,6,86,3,0,
145,6,87,1,85,
145,6,87,4,0,
145,6,92,4,0,
145,6,97,1,25,
145,6,102,3,0,
145,6,104,4,0,
145,6,113,1,73,
145,6,113,4,0,
145,6,129,3,0,
145,6,148,4,0,
145,6,156,4,0,
145,6,164,3,0,
145,6,173,3,0,
145,6,182,4,0,
145,6,189,3,0,
145,6,197,1,37,
145,6,201,4,0,
145,6,203,3,0,
145,6,207,3,0,
145,6,211,4,0,
145,6,214,3,0,
145,6,216,4,0,
145,6,218,4,0,
145,6,237,4,0,
145,6,240,4,0,
145,6,241,4,0,
145,6,249,4,0,
145,6,263,4,0,
145,6,268,1,61,
145,6,290,4,0,
145,6,332,4,0,
145,6,351,4,0,
145,7,19,4,0,
145,7,38,3,0,
145,7,46,4,0,
145,7,63,4,0,
145,7,64,1,1,1
145,7,65,1,49,
145,7,84,1,1,2
145,7,85,4,0,
145,7,86,1,13,
145,7,86,3,0,
145,7,87,1,85,
145,7,87,4,0,
145,7,92,4,0,
145,7,97,1,25,
145,7,102,3,0,
145,7,104,4,0,
145,7,113,1,73,
145,7,113,4,0,
145,7,148,4,0,
145,7,156,4,0,
145,7,164,3,0,
145,7,182,4,0,
145,7,197,1,37,
145,7,201,4,0,
145,7,211,4,0,
145,7,216,4,0,
145,7,218,4,0,
145,7,237,4,0,
145,7,240,4,0,
145,7,241,4,0,
145,7,249,4,0,
145,7,263,4,0,
145,7,268,1,61,
145,7,290,4,0,
145,7,332,4,0,
145,7,351,4,0,
145,8,19,4,0,
145,8,46,4,0,
145,8,63,4,0,
145,8,64,1,1,1
145,8,65,1,71,
145,8,84,1,1,2
145,8,85,4,0,
145,8,86,1,8,
145,8,86,4,0,
145,8,87,1,78,
145,8,87,4,0,
145,8,92,4,0,
145,8,97,1,43,
145,8,104,4,0,
145,8,113,1,64,
145,8,113,4,0,
145,8,148,4,0,
145,8,156,4,0,
145,8,164,4,0,
145,8,182,4,0,
145,8,197,1,15,
145,8,201,4,0,
145,8,203,4,0,
145,8,207,4,0,
145,8,211,4,0,
145,8,214,4,0,
145,8,216,4,0,
145,8,218,4,0,
145,8,237,4,0,
145,8,240,1,85,
145,8,240,4,0,
145,8,241,4,0,
145,8,246,1,29,
145,8,249,4,0,
145,8,263,4,0,
145,8,268,1,36,
145,8,290,4,0,
145,8,332,4,0,
145,8,351,4,0,
145,8,355,1,57,
145,8,355,4,0,
145,8,363,4,0,
145,8,365,1,22,
145,8,365,4,0,
145,8,369,4,0,
145,8,416,4,0,
145,8,432,4,0,
145,8,435,1,50,
145,8,451,4,0,
145,9,19,4,0,
145,9,46,4,0,
145,9,63,4,0,
145,9,64,1,1,1
145,9,65,1,71,
145,9,84,1,1,2
145,9,85,4,0,
145,9,86,1,8,
145,9,86,4,0,
145,9,87,1,78,
145,9,87,4,0,
145,9,92,4,0,
145,9,97,1,43,
145,9,104,4,0,
145,9,113,1,64,
145,9,113,4,0,
145,9,129,3,0,
145,9,148,4,0,
145,9,156,4,0,
145,9,164,4,0,
145,9,173,3,0,
145,9,182,4,0,
145,9,189,3,0,
145,9,197,1,15,
145,9,201,4,0,
145,9,203,4,0,
145,9,207,4,0,
145,9,211,4,0,
145,9,214,4,0,
145,9,216,4,0,
145,9,218,4,0,
145,9,237,4,0,
145,9,239,3,0,
145,9,240,1,85,
145,9,240,4,0,
145,9,241,4,0,
145,9,246,1,29,
145,9,246,3,0,
145,9,249,4,0,
145,9,257,3,0,
145,9,263,4,0,
145,9,268,1,36,
145,9,290,4,0,
145,9,314,3,0,
145,9,324,3,0,
145,9,332,4,0,
145,9,351,4,0,
145,9,355,1,57,
145,9,355,4,0,
145,9,363,4,0,
145,9,365,1,22,
145,9,365,4,0,
145,9,369,4,0,
145,9,416,4,0,
145,9,432,4,0,
145,9,435,1,50,
145,9,451,4,0,
145,9,466,3,0,
145,10,19,4,0,
145,10,46,4,0,
145,10,63,4,0,
145,10,64,1,1,1
145,10,65,1,71,
145,10,84,1,1,2
145,10,85,4,0,
145,10,86,1,8,
145,10,86,4,0,
145,10,87,1,78,
145,10,87,4,0,
145,10,92,4,0,
145,10,97,1,43,
145,10,104,4,0,
145,10,113,1,64,
145,10,113,4,0,
145,10,129,3,0,
145,10,143,3,0,
145,10,148,4,0,
145,10,156,4,0,
145,10,164,4,0,
145,10,173,3,0,
145,10,182,4,0,
145,10,189,3,0,
145,10,197,1,15,
145,10,201,4,0,
145,10,203,4,0,
145,10,207,4,0,
145,10,211,4,0,
145,10,214,4,0,
145,10,216,4,0,
145,10,218,4,0,
145,10,237,4,0,
145,10,239,3,0,
145,10,240,1,85,
145,10,240,4,0,
145,10,241,4,0,
145,10,246,1,29,
145,10,246,3,0,
145,10,249,4,0,
145,10,257,3,0,
145,10,263,4,0,
145,10,268,1,36,
145,10,290,4,0,
145,10,314,3,0,
145,10,324,3,0,
145,10,332,4,0,
145,10,351,4,0,
145,10,355,1,57,
145,10,355,4,0,
145,10,363,4,0,
145,10,365,1,22,
145,10,365,4,0,
145,10,366,3,0,
145,10,369,4,0,
145,10,416,4,0,
145,10,435,1,50,
145,10,451,4,0,
145,10,466,3,0,
145,11,19,4,0,
145,11,46,4,0,
145,11,63,4,0,
145,11,64,1,1,1
145,11,65,1,71,
145,11,84,1,1,2
145,11,85,4,0,
145,11,86,1,8,
145,11,86,4,0,
145,11,87,1,78,
145,11,87,4,0,
145,11,92,4,0,
145,11,97,1,43,
145,11,104,4,0,
145,11,113,1,64,
145,11,113,4,0,
145,11,148,4,0,
145,11,156,4,0,
145,11,164,4,0,
145,11,182,4,0,
145,11,197,1,15,
145,11,201,4,0,
145,11,207,4,0,
145,11,216,4,0,
145,11,218,4,0,
145,11,237,4,0,
145,11,240,1,85,
145,11,240,4,0,
145,11,241,4,0,
145,11,246,1,29,
145,11,249,4,0,
145,11,263,4,0,
145,11,268,1,36,
145,11,332,4,0,
145,11,355,1,57,
145,11,365,1,22,
145,11,365,4,0,
145,11,369,4,0,
145,11,416,4,0,
145,11,435,1,50,
145,11,451,4,0,
145,11,496,4,0,
145,11,507,4,0,
145,11,521,4,0,
145,11,528,4,0,
145,12,19,4,0,
145,12,46,4,0,
145,12,63,4,0,
145,12,64,1,1,1
145,12,65,1,49,
145,12,84,1,1,2
145,12,85,4,0,
145,12,86,1,13,
145,12,87,1,85,
145,12,87,4,0,
145,12,92,4,0,
145,12,97,1,25,
145,12,104,4,0,
145,12,113,1,73,
145,12,113,4,0,
145,12,148,4,0,
145,12,156,4,0,
145,12,182,4,0,
145,12,197,1,37,
145,12,201,4,0,
145,12,211,4,0,
145,12,216,4,0,
145,12,218,4,0,
145,12,237,4,0,
145,12,240,4,0,
145,12,241,4,0,
145,12,249,4,0,
145,12,263,4,0,
145,12,268,1,61,
145,12,290,4,0,
145,12,332,4,0,
145,12,351,4,0,
145,13,19,4,0,
145,13,38,3,0,
145,13,46,4,0,
145,13,63,4,0,
145,13,64,1,1,1
145,13,65,1,49,
145,13,84,1,1,2
145,13,85,4,0,
145,13,86,1,13,
145,13,86,3,0,
145,13,87,1,85,
145,13,87,4,0,
145,13,92,4,0,
145,13,97,1,25,
145,13,102,3,0,
145,13,104,4,0,
145,13,113,1,73,
145,13,113,4,0,
145,13,143,3,0,
145,13,148,4,0,
145,13,156,4,0,
145,13,164,3,0,
145,13,182,4,0,
145,13,197,1,37,
145,13,201,4,0,
145,13,207,3,0,
145,13,211,4,0,
145,13,216,4,0,
145,13,218,4,0,
145,13,237,4,0,
145,13,240,4,0,
145,13,241,4,0,
145,13,249,4,0,
145,13,263,4,0,
145,13,268,1,61,
145,13,290,4,0,
145,13,332,4,0,
145,13,351,4,0,
145,14,19,4,0,
145,14,46,4,0,
145,14,63,4,0,
145,14,64,1,1,1
145,14,65,1,71,
145,14,84,1,1,2
145,14,85,4,0,
145,14,86,1,8,
145,14,86,4,0,
145,14,87,1,78,
145,14,87,4,0,
145,14,92,4,0,
145,14,97,1,43,
145,14,104,4,0,
145,14,113,1,64,
145,14,113,4,0,
145,14,143,3,0,
145,14,148,4,0,
145,14,156,4,0,
145,14,164,4,0,
145,14,173,3,0,
145,14,182,4,0,
145,14,192,1,92,
145,14,197,1,15,
145,14,201,4,0,
145,14,207,4,0,
145,14,214,3,0,
145,14,216,4,0,
145,14,218,4,0,
145,14,237,4,0,
145,14,240,1,85,
145,14,240,4,0,
145,14,241,4,0,
145,14,246,1,29,
145,14,249,4,0,
145,14,257,3,0,
145,14,263,4,0,
145,14,268,1,36,
145,14,324,3,0,
145,14,332,4,0,
145,14,355,1,57,
145,14,355,3,0,
145,14,365,1,22,
145,14,365,4,0,
145,14,366,3,0,
145,14,369,4,0,
145,14,416,4,0,
145,14,435,1,50,
145,14,451,4,0,
145,14,496,4,0,
145,14,507,4,0,
145,14,521,4,0,
145,14,528,4,0,
145,15,19,4,0,
145,15,46,4,0,
145,15,63,4,0,
145,15,64,1,1,4
145,15,65,1,1,3
145,15,65,1,71,
145,15,84,1,1,5
145,15,85,4,0,
145,15,86,1,8,
145,15,86,4,0,
145,15,87,1,78,
145,15,87,4,0,
145,15,92,4,0,
145,15,97,1,43,
145,15,104,4,0,
145,15,113,1,64,
145,15,113,4,0,
145,15,148,4,0,
145,15,156,4,0,
145,15,164,4,0,
145,15,182,4,0,
145,15,192,1,1,2
145,15,192,1,92,
145,15,197,1,15,
145,15,201,4,0,
145,15,207,4,0,
145,15,211,4,0,
145,15,214,4,0,
145,15,216,4,0,
145,15,218,4,0,
145,15,237,4,0,
145,15,240,1,57,
145,15,240,4,0,
145,15,241,4,0,
145,15,246,1,29,
145,15,249,4,0,
145,15,263,4,0,
145,15,268,1,36,
145,15,332,4,0,
145,15,355,1,1,1
145,15,355,1,85,
145,15,355,4,0,
145,15,365,1,22,
145,15,369,4,0,
145,15,416,4,0,
145,15,435,1,50,
145,15,451,4,0,
145,15,496,4,0,
145,15,507,4,0,
145,15,521,4,0,
145,15,528,4,0,
145,15,590,4,0,
145,16,19,4,0,
145,16,46,4,0,
145,16,63,4,0,
145,16,64,1,1,4
145,16,65,1,1,3
145,16,65,1,71,1
145,16,84,1,1,5
145,16,85,4,0,
145,16,86,1,8,1
145,16,86,4,0,
145,16,87,1,78,1
145,16,87,4,0,
145,16,92,4,0,
145,16,97,1,43,1
145,16,104,4,0,
145,16,113,1,64,1
145,16,113,4,0,
145,16,143,3,0,
145,16,148,4,0,
145,16,156,4,0,
145,16,164,4,0,
145,16,173,3,0,
145,16,182,4,0,
145,16,192,1,1,2
145,16,192,1,92,1
145,16,197,1,15,1
145,16,201,4,0,
145,16,207,4,0,
145,16,211,4,0,
145,16,214,4,0,
145,16,216,4,0,
145,16,218,4,0,
145,16,237,4,0,
145,16,240,1,57,1
145,16,240,4,0,
145,16,241,4,0,
145,16,246,1,29,1
145,16,249,4,0,
145,16,257,3,0,
145,16,263,4,0,
145,16,268,1,36,1
145,16,290,4,0,
145,16,324,3,0,
145,16,332,4,0,
145,16,351,3,0,
145,16,355,1,1,1
145,16,355,1,85,1
145,16,355,4,0,
145,16,365,1,22,1
145,16,366,3,0,
145,16,369,4,0,
145,16,416,4,0,
145,16,435,1,50,1
145,16,451,4,0,
145,16,496,4,0,
145,16,507,4,0,
145,16,521,4,0,
145,16,528,4,0,
145,16,590,4,0,
145,17,19,4,0,
145,17,46,4,0,
145,17,63,4,0,
145,17,64,1,1,1
145,17,65,1,71,
145,17,84,1,1,2
145,17,85,4,0,
145,17,86,1,8,
145,17,86,4,0,
145,17,87,1,78,
145,17,87,4,0,
145,17,92,4,0,
145,17,97,1,43,
145,17,104,4,0,
145,17,113,1,64,
145,17,113,4,0,
145,17,156,4,0,
145,17,164,4,0,
145,17,182,4,0,
145,17,192,1,99,
145,17,197,1,15,
145,17,201,4,0,
145,17,207,4,0,
145,17,211,4,0,
145,17,214,4,0,
145,17,216,4,0,
145,17,218,4,0,
145,17,237,4,0,
145,17,240,1,57,
145,17,240,4,0,
145,17,241,4,0,
145,17,246,1,29,
145,17,263,4,0,
145,17,268,1,36,
145,17,332,4,0,
145,17,355,1,85,
145,17,355,4,0,
145,17,365,1,22,
145,17,369,4,0,
145,17,416,4,0,
145,17,435,1,50,
145,17,451,4,0,
145,17,496,4,0,
145,17,507,4,0,
145,17,521,4,0,
145,17,528,4,0,
145,17,590,4,0,
145,17,602,1,92,
145,18,19,4,0,
145,18,46,4,0,
145,18,63,4,0,
145,18,64,1,1,1
145,18,65,1,71,
145,18,84,1,1,2
145,18,85,4,0,
145,18,86,1,8,
145,18,86,4,0,
145,18,87,1,78,
145,18,87,4,0,
145,18,92,4,0,
145,18,97,1,43,
145,18,104,4,0,
145,18,113,1,64,
145,18,113,4,0,
145,18,156,4,0,
145,18,164,4,0,
145,18,182,4,0,
145,18,192,1,99,
145,18,197,1,15,
145,18,201,4,0,
145,18,207,4,0,
145,18,211,4,0,
145,18,214,4,0,
145,18,216,4,0,
145,18,218,4,0,
145,18,237,4,0,
145,18,240,1,57,
145,18,240,4,0,
145,18,241,4,0,
145,18,246,1,29,
145,18,263,4,0,
145,18,268,1,36,
145,18,332,4,0,
145,18,355,1,85,
145,18,355,4,0,
145,18,365,1,22,
145,18,369,4,0,
145,18,416,4,0,
145,18,435,1,50,
145,18,451,4,0,
145,18,496,4,0,
145,18,507,4,0,
145,18,521,4,0,
145,18,528,4,0,
145,18,590,4,0,
145,18,602,1,92,
146,1,13,4,0,
146,1,18,4,0,
146,1,19,4,0,
146,1,36,4,0,
146,1,38,4,0,
146,1,43,1,51,
146,1,63,4,0,
146,1,64,1,1,1
146,1,83,1,1,2
146,1,92,4,0,
146,1,97,1,55,
146,1,99,4,0,
146,1,102,4,0,
146,1,104,4,0,
146,1,115,4,0,
146,1,117,4,0,
146,1,126,4,0,
146,1,129,4,0,
146,1,143,1,60,
146,1,143,4,0,
146,1,156,4,0,
146,1,164,4,0,
146,2,13,4,0,
146,2,18,4,0,
146,2,19,4,0,
146,2,36,4,0,
146,2,38,4,0,
146,2,43,1,51,
146,2,63,4,0,
146,2,64,1,1,1
146,2,83,1,1,2
146,2,92,4,0,
146,2,97,1,55,
146,2,99,4,0,
146,2,102,4,0,
146,2,104,4,0,
146,2,115,4,0,
146,2,117,4,0,
146,2,126,4,0,
146,2,129,4,0,
146,2,143,1,60,
146,2,143,4,0,
146,2,156,4,0,
146,2,164,4,0,
146,3,17,1,1,1
146,3,19,4,0,
146,3,46,4,0,
146,3,52,1,1,2
146,3,53,1,49,
146,3,63,4,0,
146,3,83,1,13,
146,3,92,4,0,
146,3,97,1,25,
146,3,104,4,0,
146,3,126,4,0,
146,3,129,4,0,
146,3,143,1,73,
146,3,156,4,0,
146,3,173,4,0,
146,3,174,4,0,
146,3,182,4,0,
146,3,189,4,0,
146,3,197,4,0,
146,3,201,4,0,
146,3,203,1,37,
146,3,203,4,0,
146,3,207,4,0,
146,3,211,4,0,
146,3,214,4,0,
146,3,216,4,0,
146,3,218,4,0,
146,3,219,1,61,
146,3,237,4,0,
146,3,240,4,0,
146,3,241,4,0,
146,3,249,4,0,
146,4,17,1,1,1
146,4,19,4,0,
146,4,46,4,0,
146,4,52,1,1,2
146,4,53,1,49,
146,4,53,3,0,
146,4,63,4,0,
146,4,83,1,13,
146,4,92,4,0,
146,4,97,1,25,
146,4,104,4,0,
146,4,126,4,0,
146,4,129,4,0,
146,4,143,1,73,
146,4,156,4,0,
146,4,173,4,0,
146,4,174,4,0,
146,4,182,4,0,
146,4,189,4,0,
146,4,197,4,0,
146,4,201,4,0,
146,4,203,1,37,
146,4,203,4,0,
146,4,207,4,0,
146,4,211,4,0,
146,4,214,4,0,
146,4,216,4,0,
146,4,218,4,0,
146,4,219,1,61,
146,4,237,4,0,
146,4,240,4,0,
146,4,241,4,0,
146,4,249,4,0,
146,5,17,1,1,1
146,5,19,4,0,
146,5,46,4,0,
146,5,52,1,1,2
146,5,53,1,49,
146,5,53,4,0,
146,5,63,4,0,
146,5,83,1,13,
146,5,92,4,0,
146,5,97,1,25,
146,5,104,4,0,
146,5,126,4,0,
146,5,143,1,85,
146,5,156,4,0,
146,5,182,4,0,
146,5,201,4,0,
146,5,203,1,37,
146,5,211,4,0,
146,5,216,4,0,
146,5,218,4,0,
146,5,219,1,61,
146,5,219,4,0,
146,5,237,4,0,
146,5,240,4,0,
146,5,241,4,0,
146,5,249,4,0,
146,5,257,1,73,
146,5,263,4,0,
146,5,290,4,0,
146,5,315,4,0,
146,5,332,4,0,
146,6,17,1,1,1
146,6,19,4,0,
146,6,38,3,0,
146,6,46,4,0,
146,6,52,1,1,2
146,6,53,1,49,
146,6,53,4,0,
146,6,63,4,0,
146,6,83,1,13,
146,6,92,4,0,
146,6,97,1,25,
146,6,102,3,0,
146,6,104,4,0,
146,6,126,4,0,
146,6,129,3,0,
146,6,143,1,85,
146,6,156,4,0,
146,6,164,3,0,
146,6,173,3,0,
146,6,182,4,0,
146,6,189,3,0,
146,6,201,4,0,
146,6,203,1,37,
146,6,203,3,0,
146,6,207,3,0,
146,6,211,4,0,
146,6,214,3,0,
146,6,216,4,0,
146,6,218,4,0,
146,6,219,1,61,
146,6,219,4,0,
146,6,237,4,0,
146,6,240,4,0,
146,6,241,4,0,
146,6,249,4,0,
146,6,257,1,73,
146,6,263,4,0,
146,6,290,4,0,
146,6,315,4,0,
146,6,332,4,0,
146,7,17,1,1,1
146,7,19,4,0,
146,7,38,3,0,
146,7,46,4,0,
146,7,52,1,1,2
146,7,53,1,49,
146,7,53,4,0,
146,7,63,4,0,
146,7,83,1,13,
146,7,92,4,0,
146,7,97,1,25,
146,7,102,3,0,
146,7,104,4,0,
146,7,126,4,0,
146,7,143,1,85,
146,7,156,4,0,
146,7,164,3,0,
146,7,182,4,0,
146,7,201,4,0,
146,7,203,1,37,
146,7,211,4,0,
146,7,216,4,0,
146,7,218,4,0,
146,7,219,1,61,
146,7,219,4,0,
146,7,237,4,0,
146,7,240,4,0,
146,7,241,4,0,
146,7,249,4,0,
146,7,257,1,73,
146,7,263,4,0,
146,7,290,4,0,
146,7,315,4,0,
146,7,332,4,0,
146,8,17,1,1,1
146,8,19,4,0,
146,8,46,4,0,
146,8,52,1,1,2
146,8,53,1,36,
146,8,53,4,0,
146,8,63,4,0,
146,8,76,1,71,
146,8,76,4,0,
146,8,83,1,8,
146,8,92,4,0,
146,8,97,1,15,
146,8,104,4,0,
146,8,126,4,0,
146,8,143,1,78,
146,8,156,4,0,
146,8,164,4,0,
146,8,182,4,0,
146,8,201,4,0,
146,8,203,1,22,
146,8,203,4,0,
146,8,207,4,0,
146,8,211,4,0,
146,8,214,4,0,
146,8,216,4,0,
146,8,218,4,0,
146,8,219,1,43,
146,8,219,4,0,
146,8,237,4,0,
146,8,240,4,0,
146,8,241,1,85,
146,8,241,4,0,
146,8,246,1,29,
146,8,249,4,0,
146,8,257,1,64,
146,8,261,4,0,
146,8,263,4,0,
146,8,290,4,0,
146,8,315,4,0,
146,8,332,4,0,
146,8,355,1,57,
146,8,355,4,0,
146,8,363,4,0,
146,8,365,4,0,
146,8,369,4,0,
146,8,403,1,50,
146,8,416,4,0,
146,8,432,4,0,
146,9,17,1,1,1
146,9,19,4,0,
146,9,46,4,0,
146,9,52,1,1,2
146,9,53,1,36,
146,9,53,4,0,
146,9,63,4,0,
146,9,76,1,71,
146,9,76,4,0,
146,9,83,1,8,
146,9,92,4,0,
146,9,97,1,15,
146,9,104,4,0,
146,9,126,4,0,
146,9,129,3,0,
146,9,143,1,78,
146,9,156,4,0,
146,9,164,4,0,
146,9,173,3,0,
146,9,182,4,0,
146,9,189,3,0,
146,9,201,4,0,
146,9,203,1,22,
146,9,203,4,0,
146,9,207,4,0,
146,9,211,4,0,
146,9,214,4,0,
146,9,216,4,0,
146,9,218,4,0,
146,9,219,1,43,
146,9,219,4,0,
146,9,237,4,0,
146,9,239,3,0,
146,9,240,4,0,
146,9,241,1,85,
146,9,241,4,0,
146,9,246,1,29,
146,9,246,3,0,
146,9,249,4,0,
146,9,257,1,64,
146,9,257,3,0,
146,9,261,4,0,
146,9,263,4,0,
146,9,290,4,0,
146,9,314,3,0,
146,9,315,4,0,
146,9,332,4,0,
146,9,355,1,57,
146,9,355,4,0,
146,9,363,4,0,
146,9,365,4,0,
146,9,369,4,0,
146,9,403,1,50,
146,9,416,4,0,
146,9,432,4,0,
146,9,466,3,0,
146,10,17,1,1,1
146,10,19,4,0,
146,10,46,4,0,
146,10,52,1,1,2
146,10,53,1,36,
146,10,53,4,0,
146,10,63,4,0,
146,10,76,1,71,
146,10,76,4,0,
146,10,83,1,8,
146,10,92,4,0,
146,10,97,1,15,
146,10,104,4,0,
146,10,126,4,0,
146,10,129,3,0,
146,10,143,1,78,
146,10,143,3,0,
146,10,156,4,0,
146,10,164,4,0,
146,10,173,3,0,
146,10,182,4,0,
146,10,189,3,0,
146,10,201,4,0,
146,10,203,1,22,
146,10,203,4,0,
146,10,207,4,0,
146,10,211,4,0,
146,10,214,4,0,
146,10,216,4,0,
146,10,218,4,0,
146,10,219,1,43,
146,10,219,4,0,
146,10,237,4,0,
146,10,239,3,0,
146,10,240,4,0,
146,10,241,1,85,
146,10,241,4,0,
146,10,246,1,29,
146,10,246,3,0,
146,10,249,4,0,
146,10,257,1,64,
146,10,257,3,0,
146,10,261,4,0,
146,10,263,4,0,
146,10,290,4,0,
146,10,314,3,0,
146,10,315,4,0,
146,10,332,4,0,
146,10,355,1,57,
146,10,355,4,0,
146,10,363,4,0,
146,10,365,4,0,
146,10,366,3,0,
146,10,369,4,0,
146,10,403,1,50,
146,10,416,4,0,
146,10,466,3,0,
146,11,17,1,1,1
146,11,19,4,0,
146,11,46,4,0,
146,11,52,1,1,2
146,11,53,1,36,
146,11,53,4,0,
146,11,63,4,0,
146,11,76,1,71,
146,11,76,4,0,
146,11,83,1,8,
146,11,92,4,0,
146,11,97,1,15,
146,11,104,4,0,
146,11,126,4,0,
146,11,143,1,78,
146,11,156,4,0,
146,11,164,4,0,
146,11,182,4,0,
146,11,201,4,0,
146,11,203,1,22,
146,11,207,4,0,
146,11,216,4,0,
146,11,218,4,0,
146,11,219,1,43,
146,11,219,4,0,
146,11,237,4,0,
146,11,240,4,0,
146,11,241,1,85,
146,11,241,4,0,
146,11,246,1,29,
146,11,249,4,0,
146,11,257,1,64,
146,11,261,4,0,
146,11,263,4,0,
146,11,315,4,0,
146,11,332,4,0,
146,11,355,1,57,
146,11,365,4,0,
146,11,369,4,0,
146,11,403,1,50,
146,11,416,4,0,
146,11,488,4,0,
146,11,496,4,0,
146,11,507,4,0,
146,11,510,4,0,
146,12,17,1,1,1
146,12,19,4,0,
146,12,46,4,0,
146,12,52,1,1,2
146,12,53,1,49,
146,12,53,4,0,
146,12,63,4,0,
146,12,83,1,13,
146,12,92,4,0,
146,12,97,1,25,
146,12,104,4,0,
146,12,126,4,0,
146,12,143,1,85,
146,12,156,4,0,
146,12,182,4,0,
146,12,201,4,0,
146,12,203,1,37,
146,12,211,4,0,
146,12,216,4,0,
146,12,218,4,0,
146,12,219,1,61,
146,12,219,4,0,
146,12,237,4,0,
146,12,240,4,0,
146,12,241,4,0,
146,12,249,4,0,
146,12,257,1,73,
146,12,263,4,0,
146,12,290,4,0,
146,12,315,4,0,
146,12,332,4,0,
146,13,17,1,1,1
146,13,19,4,0,
146,13,38,3,0,
146,13,46,4,0,
146,13,52,1,1,2
146,13,53,1,49,
146,13,53,4,0,
146,13,63,4,0,
146,13,83,1,13,
146,13,92,4,0,
146,13,97,1,25,
146,13,102,3,0,
146,13,104,4,0,
146,13,126,4,0,
146,13,143,1,85,
146,13,143,3,0,
146,13,156,4,0,
146,13,164,3,0,
146,13,182,4,0,
146,13,201,4,0,
146,13,203,1,37,
146,13,207,3,0,
146,13,211,4,0,
146,13,216,4,0,
146,13,218,4,0,
146,13,219,1,61,
146,13,219,4,0,
146,13,237,4,0,
146,13,240,4,0,
146,13,241,4,0,
146,13,249,4,0,
146,13,257,1,73,
146,13,263,4,0,
146,13,290,4,0,
146,13,315,4,0,
146,13,332,4,0,
146,14,17,1,1,1
146,14,19,4,0,
146,14,46,4,0,
146,14,52,1,1,2
146,14,53,1,36,
146,14,53,4,0,
146,14,63,4,0,
146,14,76,1,71,
146,14,76,4,0,
146,14,83,1,8,
146,14,92,4,0,
146,14,97,1,15,
146,14,104,4,0,
146,14,126,4,0,
146,14,143,1,78,
146,14,143,3,0,
146,14,156,4,0,
146,14,164,4,0,
146,14,173,3,0,
146,14,182,4,0,
146,14,201,4,0,
146,14,203,1,22,
146,14,207,4,0,
146,14,214,3,0,
146,14,216,4,0,
146,14,218,4,0,
146,14,219,1,43,
146,14,219,4,0,
146,14,237,4,0,
146,14,240,4,0,
146,14,241,1,85,
146,14,241,4,0,
146,14,246,1,29,
146,14,249,4,0,
146,14,257,1,64,
146,14,257,3,0,
146,14,261,4,0,
146,14,263,4,0,
146,14,315,4,0,
146,14,332,4,0,
146,14,355,1,57,
146,14,355,3,0,
146,14,365,4,0,
146,14,366,3,0,
146,14,369,4,0,
146,14,403,1,50,
146,14,416,4,0,
146,14,488,4,0,
146,14,496,4,0,
146,14,507,4,0,
146,14,510,4,0,
146,14,542,1,92,
146,15,17,1,1,5
146,15,19,4,0,
146,15,46,4,0,
146,15,52,1,1,6
146,15,53,1,36,
146,15,53,4,0,
146,15,63,4,0,
146,15,76,1,71,
146,15,76,4,0,
146,15,83,1,8,
146,15,92,4,0,
146,15,97,1,15,
146,15,104,4,0,
146,15,126,4,0,
146,15,143,1,1,3
146,15,143,1,78,
146,15,156,4,0,
146,15,164,4,0,
146,15,182,4,0,
146,15,201,4,0,
146,15,203,1,22,
146,15,207,4,0,
146,15,211,4,0,
146,15,214,4,0,
146,15,216,4,0,
146,15,218,4,0,
146,15,219,1,43,
146,15,219,4,0,
146,15,237,4,0,
146,15,240,4,0,
146,15,241,1,57,
146,15,241,4,0,
146,15,246,1,29,
146,15,249,4,0,
146,15,257,1,1,4
146,15,257,1,64,
146,15,261,4,0,
146,15,263,4,0,
146,15,315,4,0,
146,15,332,4,0,
146,15,355,1,1,1
146,15,355,1,85,
146,15,355,4,0,
146,15,369,4,0,
146,15,403,1,50,
146,15,416,4,0,
146,15,488,4,0,
146,15,496,4,0,
146,15,507,4,0,
146,15,510,4,0,
146,15,542,1,1,2
146,15,542,1,92,
146,15,590,4,0,
146,16,17,1,1,5
146,16,19,4,0,
146,16,46,4,0,
146,16,52,1,1,6
146,16,53,1,36,1
146,16,53,4,0,
146,16,63,4,0,
146,16,76,1,71,1
146,16,76,4,0,
146,16,83,1,8,1
146,16,92,4,0,
146,16,97,1,15,1
146,16,104,4,0,
146,16,126,4,0,
146,16,143,1,1,3
146,16,143,1,78,1
146,16,143,3,0,
146,16,156,4,0,
146,16,164,4,0,
146,16,173,3,0,
146,16,182,4,0,
146,16,201,4,0,
146,16,203,1,22,1
146,16,207,4,0,
146,16,211,4,0,
146,16,214,4,0,
146,16,216,4,0,
146,16,218,4,0,
146,16,219,1,43,1
146,16,219,4,0,
146,16,237,4,0,
146,16,240,4,0,
146,16,241,1,57,1
146,16,241,4,0,
146,16,246,1,29,1
146,16,249,4,0,
146,16,257,1,1,4
146,16,257,1,64,1
146,16,257,3,0,
146,16,261,4,0,
146,16,263,4,0,
146,16,290,4,0,
146,16,315,4,0,
146,16,332,4,0,
146,16,355,1,1,1
146,16,355,1,85,1
146,16,355,4,0,
146,16,366,3,0,
146,16,369,4,0,
146,16,403,1,50,1
146,16,416,4,0,
146,16,488,4,0,
146,16,496,4,0,
146,16,507,4,0,
146,16,510,4,0,
146,16,542,1,1,2
146,16,542,1,92,1
146,16,590,4,0,
146,17,17,1,1,1
146,17,19,4,0,
146,17,46,4,0,
146,17,52,1,1,2
146,17,53,1,36,
146,17,53,4,0,
146,17,63,4,0,
146,17,76,1,71,
146,17,76,4,0,
146,17,83,1,8,
146,17,92,4,0,
146,17,97,1,15,
146,17,104,4,0,
146,17,126,4,0,
146,17,143,1,78,
146,17,156,4,0,
146,17,164,4,0,
146,17,182,4,0,
146,17,201,4,0,
146,17,203,1,22,
146,17,207,4,0,
146,17,211,4,0,
146,17,214,4,0,
146,17,216,4,0,
146,17,218,4,0,
146,17,219,1,43,
146,17,219,4,0,
146,17,237,4,0,
146,17,240,4,0,
146,17,241,1,57,
146,17,241,4,0,
146,17,246,1,29,
146,17,257,1,64,
146,17,261,4,0,
146,17,263,4,0,
146,17,315,4,0,
146,17,332,4,0,
146,17,355,1,85,
146,17,355,4,0,
146,17,369,4,0,
146,17,403,1,50,
146,17,416,4,0,
146,17,488,4,0,
146,17,496,4,0,
146,17,507,4,0,
146,17,542,1,92,
146,17,590,4,0,
146,17,682,1,99,
146,18,17,1,1,1
146,18,19,4,0,
146,18,46,4,0,
146,18,52,1,1,2
146,18,53,1,36,
146,18,53,4,0,
146,18,63,4,0,
146,18,76,1,71,
146,18,76,4,0,
146,18,83,1,8,
146,18,92,4,0,
146,18,97,1,15,
146,18,104,4,0,
146,18,126,4,0,
146,18,143,1,78,
146,18,156,4,0,
146,18,164,4,0,
146,18,182,4,0,
146,18,201,4,0,
146,18,203,1,22,
146,18,207,4,0,
146,18,211,4,0,
146,18,214,4,0,
146,18,216,4,0,
146,18,218,4,0,
146,18,219,1,43,
146,18,219,4,0,
146,18,237,4,0,
146,18,240,4,0,
146,18,241,1,57,
146,18,241,4,0,
146,18,246,1,29,
146,18,257,1,64,
146,18,261,4,0,
146,18,263,4,0,
146,18,315,4,0,
146,18,332,4,0,
146,18,355,1,85,
146,18,355,4,0,
146,18,369,4,0,
146,18,403,1,50,
146,18,416,4,0,
146,18,488,4,0,
146,18,496,4,0,
146,18,507,4,0,
146,18,542,1,92,
146,18,590,4,0,
146,18,682,1,99,
147,1,21,1,30,
147,1,34,4,0,
147,1,35,1,1,1
147,1,36,4,0,
147,1,38,4,0,
147,1,43,1,1,2
147,1,55,4,0,
147,1,57,4,0,
147,1,58,4,0,
147,1,59,4,0,
147,1,61,4,0,
147,1,63,1,50,
147,1,82,1,40,
147,1,82,4,0,
147,1,85,4,0,
147,1,86,1,10,
147,1,86,4,0,
147,1,87,4,0,
147,1,92,4,0,
147,1,97,1,20,
147,1,99,4,0,
147,1,102,4,0,
147,1,104,4,0,
147,1,115,4,0,
147,1,117,4,0,
147,1,126,4,0,
147,1,129,4,0,
147,1,130,4,0,
147,1,156,4,0,
147,1,164,4,0,
147,2,21,1,30,
147,2,34,4,0,
147,2,35,1,1,1
147,2,36,4,0,
147,2,38,4,0,
147,2,43,1,1,2
147,2,55,4,0,
147,2,57,4,0,
147,2,58,4,0,
147,2,59,4,0,
147,2,61,4,0,
147,2,63,1,50,
147,2,82,1,40,
147,2,82,4,0,
147,2,85,4,0,
147,2,86,1,10,
147,2,86,4,0,
147,2,87,4,0,
147,2,92,4,0,
147,2,97,1,20,
147,2,99,4,0,
147,2,102,4,0,
147,2,104,4,0,
147,2,115,4,0,
147,2,117,4,0,
147,2,126,4,0,
147,2,129,4,0,
147,2,130,4,0,
147,2,156,4,0,
147,2,164,4,0,
147,3,21,1,29,
147,3,29,4,0,
147,3,35,1,1,1
147,3,43,1,1,2
147,3,48,2,0,
147,3,54,2,0,
147,3,57,4,0,
147,3,59,4,0,
147,3,63,1,57,
147,3,82,1,22,
147,3,86,1,8,
147,3,87,4,0,
147,3,92,4,0,
147,3,97,1,36,
147,3,104,4,0,
147,3,113,2,0,
147,3,114,2,0,
147,3,126,4,0,
147,3,127,4,0,
147,3,129,4,0,
147,3,156,4,0,
147,3,173,4,0,
147,3,174,4,0,
147,3,182,4,0,
147,3,192,4,0,
147,3,196,4,0,
147,3,197,4,0,
147,3,200,1,50,
147,3,203,4,0,
147,3,207,4,0,
147,3,213,4,0,
147,3,214,4,0,
147,3,216,4,0,
147,3,218,4,0,
147,3,219,1,43,
147,3,225,4,0,
147,3,231,4,0,
147,3,237,4,0,
147,3,239,1,15,
147,3,240,4,0,
147,4,21,1,29,
147,4,29,4,0,
147,4,35,1,1,1
147,4,43,1,1,2
147,4,48,2,0,
147,4,53,3,0,
147,4,54,2,0,
147,4,57,4,0,
147,4,58,3,0,
147,4,59,4,0,
147,4,63,1,57,
147,4,82,1,22,
147,4,85,3,0,
147,4,86,1,8,
147,4,87,4,0,
147,4,92,4,0,
147,4,97,1,36,
147,4,104,4,0,
147,4,113,2,0,
147,4,114,2,0,
147,4,126,4,0,
147,4,127,4,0,
147,4,129,4,0,
147,4,156,4,0,
147,4,173,4,0,
147,4,174,4,0,
147,4,182,4,0,
147,4,192,4,0,
147,4,196,4,0,
147,4,197,4,0,
147,4,200,1,50,
147,4,203,4,0,
147,4,207,4,0,
147,4,213,4,0,
147,4,214,4,0,
147,4,216,4,0,
147,4,218,4,0,
147,4,219,1,43,
147,4,225,4,0,
147,4,231,4,0,
147,4,237,4,0,
147,4,239,1,15,
147,4,240,4,0,
147,5,21,1,29,
147,5,35,1,1,1
147,5,43,1,1,2
147,5,48,2,0,
147,5,53,4,0,
147,5,54,2,0,
147,5,57,4,0,
147,5,58,4,0,
147,5,59,4,0,
147,5,63,1,57,
147,5,63,4,0,
147,5,82,1,22,
147,5,85,4,0,
147,5,86,1,8,
147,5,87,4,0,
147,5,92,4,0,
147,5,97,1,36,
147,5,104,4,0,
147,5,113,2,0,
147,5,114,2,0,
147,5,126,4,0,
147,5,127,4,0,
147,5,156,4,0,
147,5,182,4,0,
147,5,200,1,50,
147,5,213,4,0,
147,5,216,4,0,
147,5,218,4,0,
147,5,219,1,43,
147,5,219,4,0,
147,5,225,2,0,
147,5,231,4,0,
147,5,237,4,0,
147,5,239,1,15,
147,5,240,4,0,
147,5,241,4,0,
147,5,258,4,0,
147,5,263,4,0,
147,5,290,4,0,
147,5,349,2,0,
147,5,351,4,0,
147,5,352,4,0,
147,6,21,1,29,
147,6,34,3,0,
147,6,35,1,1,1
147,6,38,3,0,
147,6,43,1,1,2
147,6,48,2,0,
147,6,53,4,0,
147,6,54,2,0,
147,6,57,4,0,
147,6,58,4,0,
147,6,59,4,0,
147,6,63,1,57,
147,6,63,4,0,
147,6,82,1,22,
147,6,85,4,0,
147,6,86,1,8,
147,6,86,3,0,
147,6,87,4,0,
147,6,92,4,0,
147,6,97,1,36,
147,6,102,3,0,
147,6,104,4,0,
147,6,113,2,0,
147,6,114,2,0,
147,6,126,4,0,
147,6,127,4,0,
147,6,129,3,0,
147,6,156,4,0,
147,6,164,3,0,
147,6,173,3,0,
147,6,182,4,0,
147,6,196,3,0,
147,6,200,1,50,
147,6,203,3,0,
147,6,207,3,0,
147,6,213,4,0,
147,6,214,3,0,
147,6,216,4,0,
147,6,218,4,0,
147,6,219,1,43,
147,6,219,4,0,
147,6,225,2,0,
147,6,231,4,0,
147,6,237,4,0,
147,6,239,1,15,
147,6,240,4,0,
147,6,241,4,0,
147,6,258,4,0,
147,6,263,4,0,
147,6,290,4,0,
147,6,349,2,0,
147,6,351,4,0,
147,6,352,4,0,
147,7,21,1,29,
147,7,34,3,0,
147,7,35,1,1,1
147,7,38,3,0,
147,7,43,1,1,2
147,7,48,2,0,
147,7,53,4,0,
147,7,54,2,0,
147,7,57,4,0,
147,7,58,4,0,
147,7,59,4,0,
147,7,63,1,57,
147,7,63,4,0,
147,7,82,1,22,
147,7,85,4,0,
147,7,86,1,8,
147,7,86,3,0,
147,7,87,4,0,
147,7,92,4,0,
147,7,97,1,36,
147,7,102,3,0,
147,7,104,4,0,
147,7,113,2,0,
147,7,114,2,0,
147,7,126,4,0,
147,7,127,4,0,
147,7,156,4,0,
147,7,164,3,0,
147,7,182,4,0,
147,7,200,1,50,
147,7,213,4,0,
147,7,216,4,0,
147,7,218,4,0,
147,7,219,1,43,
147,7,219,4,0,
147,7,225,2,0,
147,7,231,4,0,
147,7,237,4,0,
147,7,239,1,15,
147,7,240,4,0,
147,7,241,4,0,
147,7,258,4,0,
147,7,263,4,0,
147,7,290,4,0,
147,7,349,2,0,
147,7,351,4,0,
147,7,352,4,0,
147,8,21,1,21,
147,8,35,1,1,1
147,8,43,1,1,2
147,8,48,2,0,
147,8,53,4,0,
147,8,54,2,0,
147,8,57,4,0,
147,8,58,4,0,
147,8,59,4,0,
147,8,63,1,55,
147,8,63,4,0,
147,8,82,1,15,
147,8,85,4,0,
147,8,86,1,5,
147,8,86,4,0,
147,8,87,4,0,
147,8,92,4,0,
147,8,97,1,25,
147,8,104,4,0,
147,8,113,2,0,
147,8,114,2,0,
147,8,126,4,0,
147,8,127,4,0,
147,8,156,4,0,
147,8,164,4,0,
147,8,182,4,0,
147,8,200,1,51,
147,8,203,4,0,
147,8,207,4,0,
147,8,213,4,0,
147,8,214,4,0,
147,8,216,4,0,
147,8,218,4,0,
147,8,219,1,41,
147,8,219,4,0,
147,8,225,2,0,
147,8,231,4,0,
147,8,237,4,0,
147,8,239,1,11,
147,8,240,4,0,
147,8,241,4,0,
147,8,258,4,0,
147,8,263,4,0,
147,8,290,4,0,
147,8,349,1,45,
147,8,349,2,0,
147,8,351,4,0,
147,8,352,4,0,
147,8,363,4,0,
147,8,401,1,31,
147,8,406,4,0,
147,8,407,1,35,
147,8,407,2,0,
147,8,434,3,0,
147,8,445,4,0,
147,9,21,1,21,
147,9,35,1,1,1
147,9,43,1,1,2
147,9,48,2,0,
147,9,53,4,0,
147,9,54,2,0,
147,9,57,4,0,
147,9,58,4,0,
147,9,59,4,0,
147,9,63,1,55,
147,9,63,4,0,
147,9,82,1,15,
147,9,85,4,0,
147,9,86,1,5,
147,9,86,4,0,
147,9,87,4,0,
147,9,92,4,0,
147,9,97,1,25,
147,9,104,4,0,
147,9,113,2,0,
147,9,114,2,0,
147,9,126,4,0,
147,9,127,4,0,
147,9,129,3,0,
147,9,156,4,0,
147,9,164,4,0,
147,9,173,3,0,
147,9,182,4,0,
147,9,196,3,0,
147,9,200,1,51,
147,9,200,3,0,
147,9,203,4,0,
147,9,207,4,0,
147,9,213,4,0,
147,9,214,4,0,
147,9,216,4,0,
147,9,218,4,0,
147,9,219,1,41,
147,9,219,4,0,
147,9,225,2,0,
147,9,231,4,0,
147,9,237,4,0,
147,9,239,1,11,
147,9,239,3,0,
147,9,240,4,0,
147,9,241,4,0,
147,9,258,4,0,
147,9,263,4,0,
147,9,290,4,0,
147,9,349,1,45,
147,9,349,2,0,
147,9,351,4,0,
147,9,352,4,0,
147,9,363,4,0,
147,9,401,1,31,
147,9,401,3,0,
147,9,406,4,0,
147,9,407,1,35,
147,9,407,2,0,
147,9,434,3,0,
147,9,445,4,0,
147,10,21,1,21,
147,10,29,3,0,
147,10,35,1,1,1
147,10,43,1,1,2
147,10,48,2,0,
147,10,53,4,0,
147,10,54,2,0,
147,10,57,4,0,
147,10,58,4,0,
147,10,59,4,0,
147,10,63,1,55,
147,10,63,4,0,
147,10,82,1,15,
147,10,85,4,0,
147,10,86,1,5,
147,10,86,4,0,
147,10,87,4,0,
147,10,92,4,0,
147,10,97,1,25,
147,10,104,4,0,
147,10,113,2,0,
147,10,114,2,0,
147,10,126,4,0,
147,10,127,4,0,
147,10,129,3,0,
147,10,156,4,0,
147,10,164,4,0,
147,10,173,3,0,
147,10,182,4,0,
147,10,196,3,0,
147,10,200,1,51,
147,10,200,3,0,
147,10,203,4,0,
147,10,207,4,0,
147,10,213,4,0,
147,10,214,4,0,
147,10,216,4,0,
147,10,218,4,0,
147,10,219,1,41,
147,10,219,4,0,
147,10,225,2,0,
147,10,231,4,0,
147,10,237,4,0,
147,10,239,1,11,
147,10,239,3,0,
147,10,240,4,0,
147,10,241,4,0,
147,10,245,2,0,
147,10,250,4,0,
147,10,258,4,0,
147,10,263,4,0,
147,10,290,4,0,
147,10,349,1,45,
147,10,349,2,0,
147,10,351,4,0,
147,10,352,4,0,
147,10,363,4,0,
147,10,401,1,31,
147,10,401,3,0,
147,10,406,4,0,
147,10,407,1,35,
147,10,407,2,0,
147,10,434,3,0,
147,10,445,4,0,
147,11,21,1,21,
147,11,35,1,1,1
147,11,43,1,1,2
147,11,48,2,0,
147,11,53,4,0,
147,11,54,2,0,
147,11,57,4,0,
147,11,58,4,0,
147,11,59,4,0,
147,11,63,1,61,
147,11,63,4,0,
147,11,82,1,15,
147,11,85,4,0,
147,11,86,1,5,
147,11,86,4,0,
147,11,87,4,0,
147,11,92,4,0,
147,11,97,1,25,
147,11,104,4,0,
147,11,113,4,0,
147,11,114,2,0,
147,11,126,4,0,
147,11,127,4,0,
147,11,156,4,0,
147,11,164,4,0,
147,11,182,4,0,
147,11,200,1,55,
147,11,207,4,0,
147,11,213,4,0,
147,11,216,4,0,
147,11,218,4,0,
147,11,219,1,45,
147,11,219,4,0,
147,11,225,2,0,
147,11,231,2,0,
147,11,237,4,0,
147,11,239,1,11,
147,11,240,4,0,
147,11,241,4,0,
147,11,245,2,0,
147,11,258,4,0,
147,11,263,4,0,
147,11,349,1,51,
147,11,349,2,0,
147,11,352,2,0,
147,11,401,1,35,
147,11,406,2,0,
147,11,407,1,41,
147,11,407,2,0,
147,11,434,3,0,
147,11,453,2,0,
147,11,496,4,0,
147,11,510,4,0,
147,11,525,1,31,
147,11,525,4,0,
147,12,21,1,29,
147,12,35,1,1,1
147,12,43,1,1,2
147,12,53,4,0,
147,12,57,4,0,
147,12,58,4,0,
147,12,59,4,0,
147,12,63,1,57,
147,12,63,4,0,
147,12,82,1,22,
147,12,85,4,0,
147,12,86,1,8,
147,12,87,4,0,
147,12,92,4,0,
147,12,97,1,36,
147,12,104,4,0,
147,12,126,4,0,
147,12,127,4,0,
147,12,156,4,0,
147,12,182,4,0,
147,12,200,1,50,
147,12,213,4,0,
147,12,216,4,0,
147,12,218,4,0,
147,12,219,1,43,
147,12,219,4,0,
147,12,231,4,0,
147,12,237,4,0,
147,12,239,1,15,
147,12,240,4,0,
147,12,241,4,0,
147,12,258,4,0,
147,12,263,4,0,
147,12,290,4,0,
147,12,351,4,0,
147,12,352,4,0,
147,13,21,1,29,
147,13,34,3,0,
147,13,35,1,1,1
147,13,38,3,0,
147,13,43,1,1,2
147,13,53,4,0,
147,13,57,4,0,
147,13,58,4,0,
147,13,59,4,0,
147,13,63,1,57,
147,13,63,4,0,
147,13,82,1,22,
147,13,85,4,0,
147,13,86,1,8,
147,13,86,3,0,
147,13,87,4,0,
147,13,92,4,0,
147,13,97,1,36,
147,13,102,3,0,
147,13,104,4,0,
147,13,126,4,0,
147,13,127,4,0,
147,13,156,4,0,
147,13,164,3,0,
147,13,182,4,0,
147,13,196,3,0,
147,13,200,1,50,
147,13,207,3,0,
147,13,213,4,0,
147,13,216,4,0,
147,13,218,4,0,
147,13,219,1,43,
147,13,219,4,0,
147,13,231,4,0,
147,13,237,4,0,
147,13,239,1,15,
147,13,240,4,0,
147,13,241,4,0,
147,13,258,4,0,
147,13,263,4,0,
147,13,290,4,0,
147,13,351,4,0,
147,13,352,4,0,
147,14,20,3,0,
147,14,21,1,21,
147,14,35,1,1,1
147,14,43,1,1,2
147,14,48,2,0,
147,14,53,4,0,
147,14,54,2,0,
147,14,57,4,0,
147,14,58,4,0,
147,14,59,4,0,
147,14,63,1,61,
147,14,63,4,0,
147,14,82,1,15,
147,14,85,4,0,
147,14,86,1,5,
147,14,86,4,0,
147,14,87,4,0,
147,14,92,4,0,
147,14,97,1,25,
147,14,104,4,0,
147,14,113,4,0,
147,14,114,2,0,
147,14,126,4,0,
147,14,127,4,0,
147,14,156,4,0,
147,14,164,4,0,
147,14,173,3,0,
147,14,182,4,0,
147,14,196,3,0,
147,14,200,1,55,
147,14,200,3,0,
147,14,207,4,0,
147,14,213,4,0,
147,14,214,3,0,
147,14,216,4,0,
147,14,218,4,0,
147,14,219,1,45,
147,14,219,4,0,
147,14,225,2,0,
147,14,231,2,0,
147,14,231,3,0,
147,14,237,4,0,
147,14,239,1,11,
147,14,240,4,0,
147,14,241,4,0,
147,14,245,2,0,
147,14,258,4,0,
147,14,263,4,0,
147,14,349,1,51,
147,14,349,2,0,
147,14,352,2,0,
147,14,401,1,35,
147,14,401,3,0,
147,14,406,2,0,
147,14,406,3,0,
147,14,407,1,41,
147,14,407,2,0,
147,14,434,3,0,
147,14,453,2,0,
147,14,496,4,0,
147,14,510,4,0,
147,14,525,1,31,
147,14,525,4,0,
147,15,21,1,21,
147,15,35,1,1,1
147,15,43,1,1,2
147,15,48,2,0,
147,15,53,4,0,
147,15,54,2,0,
147,15,57,4,0,
147,15,58,4,0,
147,15,59,4,0,
147,15,63,1,61,
147,15,63,4,0,
147,15,82,1,15,
147,15,85,4,0,
147,15,86,1,5,
147,15,86,4,0,
147,15,87,4,0,
147,15,92,4,0,
147,15,97,1,25,
147,15,104,4,0,
147,15,113,4,0,
147,15,114,2,0,
147,15,126,4,0,
147,15,127,4,0,
147,15,156,4,0,
147,15,164,4,0,
147,15,182,4,0,
147,15,200,1,55,
147,15,207,4,0,
147,15,213,4,0,
147,15,214,4,0,
147,15,216,4,0,
147,15,218,4,0,
147,15,219,1,45,
147,15,219,4,0,
147,15,225,2,0,
147,15,231,2,0,
147,15,237,4,0,
147,15,239,1,11,
147,15,240,4,0,
147,15,241,4,0,
147,15,245,2,0,
147,15,258,4,0,
147,15,263,4,0,
147,15,349,1,51,
147,15,349,2,0,
147,15,352,2,0,
147,15,401,1,35,
147,15,406,2,0,
147,15,407,1,41,
147,15,407,2,0,
147,15,434,3,0,
147,15,453,2,0,
147,15,496,4,0,
147,15,510,4,0,
147,15,525,1,31,
147,15,525,4,0,
147,15,590,4,0,
147,16,20,3,0,
147,16,21,1,21,1
147,16,35,1,1,1
147,16,43,1,1,2
147,16,48,2,0,
147,16,53,4,0,
147,16,54,2,0,
147,16,57,4,0,
147,16,58,4,0,
147,16,59,4,0,
147,16,63,1,61,1
147,16,63,4,0,
147,16,82,1,15,1
147,16,85,4,0,
147,16,86,1,5,1
147,16,86,4,0,
147,16,87,4,0,
147,16,92,4,0,
147,16,97,1,25,1
147,16,104,4,0,
147,16,113,4,0,
147,16,114,2,0,
147,16,126,4,0,
147,16,127,4,0,
147,16,156,4,0,
147,16,164,4,0,
147,16,173,3,0,
147,16,182,4,0,
147,16,196,3,0,
147,16,200,1,55,1
147,16,200,3,0,
147,16,207,4,0,
147,16,213,4,0,
147,16,214,4,0,
147,16,216,4,0,
147,16,218,4,0,
147,16,219,1,45,1
147,16,219,4,0,
147,16,225,2,0,
147,16,231,2,0,
147,16,231,3,0,
147,16,237,4,0,
147,16,239,1,11,1
147,16,240,4,0,
147,16,241,4,0,
147,16,245,2,0,
147,16,258,4,0,
147,16,263,4,0,
147,16,290,4,0,
147,16,349,1,51,1
147,16,349,2,0,
147,16,351,3,0,
147,16,352,2,0,
147,16,352,3,0,
147,16,401,1,35,1
147,16,401,3,0,
147,16,406,2,0,
147,16,406,3,0,
147,16,407,1,41,1
147,16,407,2,0,
147,16,434,3,0,
147,16,453,2,0,
147,16,496,4,0,
147,16,510,4,0,
147,16,525,1,31,1
147,16,525,4,0,
147,16,590,4,0,
147,17,21,1,21,
147,17,35,1,1,1
147,17,43,1,1,2
147,17,48,2,0,
147,17,53,4,0,
147,17,54,2,0,
147,17,57,4,0,
147,17,58,4,0,
147,17,59,4,0,
147,17,63,1,61,
147,17,63,4,0,
147,17,82,1,15,
147,17,85,4,0,
147,17,86,1,5,
147,17,86,4,0,
147,17,87,4,0,
147,17,92,4,0,
147,17,97,1,25,
147,17,104,4,0,
147,17,113,4,0,
147,17,114,2,0,
147,17,126,4,0,
147,17,127,4,0,
147,17,156,4,0,
147,17,164,4,0,
147,17,182,4,0,
147,17,200,1,55,
147,17,207,4,0,
147,17,213,4,0,
147,17,214,4,0,
147,17,216,4,0,
147,17,218,4,0,
147,17,219,1,45,
147,17,219,4,0,
147,17,225,2,0,
147,17,231,2,0,
147,17,237,4,0,
147,17,239,1,11,
147,17,240,4,0,
147,17,241,4,0,
147,17,245,2,0,
147,17,258,4,0,
147,17,263,4,0,
147,17,349,1,51,
147,17,349,2,0,
147,17,352,2,0,
147,17,401,1,35,
147,17,406,2,0,
147,17,407,1,41,
147,17,407,2,0,
147,17,434,3,0,
147,17,453,2,0,
147,17,496,4,0,
147,17,525,1,31,
147,17,525,4,0,
147,17,590,4,0,
147,17,693,4,0,
147,18,21,1,21,
147,18,35,1,1,1
147,18,43,1,1,2
147,18,48,2,0,
147,18,53,4,0,
147,18,54,2,0,
147,18,57,4,0,
147,18,58,4,0,
147,18,59,4,0,
147,18,63,1,61,
147,18,63,4,0,
147,18,82,1,15,
147,18,85,4,0,
147,18,86,1,5,
147,18,86,4,0,
147,18,87,4,0,
147,18,92,4,0,
147,18,97,1,25,
147,18,104,4,0,
147,18,113,4,0,
147,18,114,2,0,
147,18,126,4,0,
147,18,127,4,0,
147,18,156,4,0,
147,18,164,4,0,
147,18,182,4,0,
147,18,200,1,55,
147,18,207,4,0,
147,18,213,4,0,
147,18,214,4,0,
147,18,216,4,0,
147,18,218,4,0,
147,18,219,1,45,
147,18,219,4,0,
147,18,225,2,0,
147,18,231,2,0,
147,18,237,4,0,
147,18,239,1,11,
147,18,240,4,0,
147,18,241,4,0,
147,18,245,2,0,
147,18,258,4,0,
147,18,263,4,0,
147,18,349,1,51,
147,18,349,2,0,
147,18,352,2,0,
147,18,401,1,35,
147,18,406,2,0,
147,18,407,1,41,
147,18,407,2,0,
147,18,434,3,0,
147,18,453,2,0,
147,18,496,4,0,
147,18,525,1,31,
147,18,525,4,0,
147,18,590,4,0,
147,18,693,4,0,
148,1,21,1,35,
148,1,32,4,0,
148,1,34,4,0,
148,1,35,1,1,1
148,1,36,4,0,
148,1,38,4,0,
148,1,43,1,1,2
148,1,55,4,0,
148,1,57,4,0,
148,1,58,4,0,
148,1,59,4,0,
148,1,61,4,0,
148,1,63,1,55,
148,1,82,1,45,
148,1,82,4,0,
148,1,85,4,0,
148,1,86,1,1,3
148,1,86,1,10,
148,1,86,4,0,
148,1,87,4,0,
148,1,92,4,0,
148,1,97,1,20,
148,1,99,4,0,
148,1,102,4,0,
148,1,104,4,0,
148,1,115,4,0,
148,1,117,4,0,
148,1,126,4,0,
148,1,129,4,0,
148,1,130,4,0,
148,1,156,4,0,
148,1,164,4,0,
148,2,21,1,35,
148,2,32,4,0,
148,2,34,4,0,
148,2,35,1,1,1
148,2,36,4,0,
148,2,38,4,0,
148,2,43,1,1,2
148,2,55,4,0,
148,2,57,4,0,
148,2,58,4,0,
148,2,59,4,0,
148,2,61,4,0,
148,2,63,1,55,
148,2,82,1,45,
148,2,82,4,0,
148,2,85,4,0,
148,2,86,1,1,3
148,2,86,1,10,
148,2,86,4,0,
148,2,87,4,0,
148,2,92,4,0,
148,2,97,1,20,
148,2,99,4,0,
148,2,102,4,0,
148,2,104,4,0,
148,2,115,4,0,
148,2,117,4,0,
148,2,126,4,0,
148,2,129,4,0,
148,2,130,4,0,
148,2,156,4,0,
148,2,164,4,0,
148,3,21,1,29,
148,3,29,4,0,
148,3,35,1,1,1
148,3,43,1,1,2
148,3,57,4,0,
148,3,59,4,0,
148,3,63,1,65,
148,3,82,1,22,
148,3,86,1,1,3
148,3,86,1,8,
148,3,87,4,0,
148,3,92,4,0,
148,3,97,1,38,
148,3,104,4,0,
148,3,126,4,0,
148,3,127,4,0,
148,3,129,4,0,
148,3,156,4,0,
148,3,173,4,0,
148,3,174,4,0,
148,3,182,4,0,
148,3,192,4,0,
148,3,196,4,0,
148,3,197,4,0,
148,3,200,1,56,
148,3,203,4,0,
148,3,207,4,0,
148,3,213,4,0,
148,3,214,4,0,
148,3,216,4,0,
148,3,218,4,0,
148,3,219,1,47,
148,3,225,4,0,
148,3,231,4,0,
148,3,237,4,0,
148,3,239,1,1,4
148,3,239,1,15,
148,3,240,4,0,
148,4,21,1,29,
148,4,29,4,0,
148,4,35,1,1,1
148,4,43,1,1,2
148,4,53,3,0,
148,4,57,4,0,
148,4,58,3,0,
148,4,59,4,0,
148,4,63,1,65,
148,4,82,1,22,
148,4,85,3,0,
148,4,86,1,1,3
148,4,86,1,8,
148,4,87,4,0,
148,4,92,4,0,
148,4,97,1,38,
148,4,104,4,0,
148,4,126,4,0,
148,4,127,4,0,
148,4,129,4,0,
148,4,156,4,0,
148,4,173,4,0,
148,4,174,4,0,
148,4,182,4,0,
148,4,192,4,0,
148,4,196,4,0,
148,4,197,4,0,
148,4,200,1,56,
148,4,203,4,0,
148,4,207,4,0,
148,4,213,4,0,
148,4,214,4,0,
148,4,216,4,0,
148,4,218,4,0,
148,4,219,1,47,
148,4,225,4,0,
148,4,231,4,0,
148,4,237,4,0,
148,4,239,1,1,4
148,4,239,1,15,
148,4,240,4,0,
148,5,21,1,29,
148,5,35,1,1,1
148,5,43,1,1,2
148,5,53,4,0,
148,5,57,4,0,
148,5,58,4,0,
148,5,59,4,0,
148,5,63,1,65,
148,5,63,4,0,
148,5,82,1,22,
148,5,85,4,0,
148,5,86,1,1,3
148,5,86,1,8,
148,5,87,4,0,
148,5,92,4,0,
148,5,97,1,38,
148,5,104,4,0,
148,5,126,4,0,
148,5,127,4,0,
148,5,156,4,0,
148,5,182,4,0,
148,5,200,1,56,
148,5,213,4,0,
148,5,216,4,0,
148,5,218,4,0,
148,5,219,1,47,
148,5,219,4,0,
148,5,231,4,0,
148,5,237,4,0,
148,5,239,1,1,4
148,5,239,1,15,
148,5,240,4,0,
148,5,241,4,0,
148,5,258,4,0,
148,5,263,4,0,
148,5,290,4,0,
148,5,351,4,0,
148,5,352,4,0,
148,6,21,1,29,
148,6,34,3,0,
148,6,35,1,1,1
148,6,38,3,0,
148,6,43,1,1,2
148,6,53,4,0,
148,6,57,4,0,
148,6,58,4,0,
148,6,59,4,0,
148,6,63,1,65,
148,6,63,4,0,
148,6,82,1,22,
148,6,85,4,0,
148,6,86,1,1,3
148,6,86,1,8,
148,6,86,3,0,
148,6,87,4,0,
148,6,92,4,0,
148,6,97,1,38,
148,6,102,3,0,
148,6,104,4,0,
148,6,126,4,0,
148,6,127,4,0,
148,6,129,3,0,
148,6,156,4,0,
148,6,164,3,0,
148,6,173,3,0,
148,6,182,4,0,
148,6,196,3,0,
148,6,200,1,56,
148,6,203,3,0,
148,6,207,3,0,
148,6,213,4,0,
148,6,214,3,0,
148,6,216,4,0,
148,6,218,4,0,
148,6,219,1,47,
148,6,219,4,0,
148,6,231,4,0,
148,6,237,4,0,
148,6,239,1,1,4
148,6,239,1,15,
148,6,240,4,0,
148,6,241,4,0,
148,6,258,4,0,
148,6,263,4,0,
148,6,290,4,0,
148,6,351,4,0,
148,6,352,4,0,
148,7,21,1,29,
148,7,34,3,0,
148,7,35,1,1,1
148,7,38,3,0,
148,7,43,1,1,2
148,7,53,4,0,
148,7,57,4,0,
148,7,58,4,0,
148,7,59,4,0,
148,7,63,1,65,
148,7,63,4,0,
148,7,82,1,22,
148,7,85,4,0,
148,7,86,1,1,3
148,7,86,1,8,
148,7,86,3,0,
148,7,87,4,0,
148,7,92,4,0,
148,7,97,1,38,
148,7,102,3,0,
148,7,104,4,0,
148,7,126,4,0,
148,7,127,4,0,
148,7,156,4,0,
148,7,164,3,0,
148,7,182,4,0,
148,7,200,1,56,
148,7,213,4,0,
148,7,216,4,0,
148,7,218,4,0,
148,7,219,1,47,
148,7,219,4,0,
148,7,231,4,0,
148,7,237,4,0,
148,7,239,1,1,4
148,7,239,1,15,
148,7,240,4,0,
148,7,241,4,0,
148,7,258,4,0,
148,7,263,4,0,
148,7,290,4,0,
148,7,351,4,0,
148,7,352,4,0,
148,8,21,1,21,
148,8,35,1,1,1
148,8,43,1,1,2
148,8,53,4,0,
148,8,57,4,0,
148,8,58,4,0,
148,8,59,4,0,
148,8,63,1,67,
148,8,63,4,0,
148,8,82,1,15,
148,8,85,4,0,
148,8,86,1,1,3
148,8,86,1,5,
148,8,86,4,0,
148,8,87,4,0,
148,8,92,4,0,
148,8,97,1,25,
148,8,104,4,0,
148,8,126,4,0,
148,8,127,4,0,
148,8,156,4,0,
148,8,164,4,0,
148,8,182,4,0,
148,8,200,1,61,
148,8,203,4,0,
148,8,207,4,0,
148,8,213,4,0,
148,8,214,4,0,
148,8,216,4,0,
148,8,218,4,0,
148,8,219,1,47,
148,8,219,4,0,
148,8,231,4,0,
148,8,237,4,0,
148,8,239,1,1,4
148,8,239,1,11,
148,8,240,4,0,
148,8,241,4,0,
148,8,258,4,0,
148,8,263,4,0,
148,8,290,4,0,
148,8,349,1,53,
148,8,351,4,0,
148,8,352,4,0,
148,8,363,4,0,
148,8,401,1,33,
148,8,406,4,0,
148,8,407,1,39,
148,8,434,3,0,
148,8,445,4,0,
148,9,21,1,21,
148,9,35,1,1,1
148,9,43,1,1,2
148,9,53,4,0,
148,9,57,4,0,
148,9,58,4,0,
148,9,59,4,0,
148,9,63,1,67,
148,9,63,4,0,
148,9,82,1,15,
148,9,85,4,0,
148,9,86,1,1,3
148,9,86,1,5,
148,9,86,4,0,
148,9,87,4,0,
148,9,92,4,0,
148,9,97,1,25,
148,9,104,4,0,
148,9,126,4,0,
148,9,127,4,0,
148,9,129,3,0,
148,9,156,4,0,
148,9,164,4,0,
148,9,173,3,0,
148,9,182,4,0,
148,9,196,3,0,
148,9,200,1,61,
148,9,200,3,0,
148,9,203,4,0,
148,9,207,4,0,
148,9,213,4,0,
148,9,214,4,0,
148,9,216,4,0,
148,9,218,4,0,
148,9,219,1,47,
148,9,219,4,0,
148,9,231,4,0,
148,9,237,4,0,
148,9,239,1,1,4
148,9,239,1,11,
148,9,239,3,0,
148,9,240,4,0,
148,9,241,4,0,
148,9,258,4,0,
148,9,263,4,0,
148,9,290,4,0,
148,9,349,1,53,
148,9,351,4,0,
148,9,352,4,0,
148,9,363,4,0,
148,9,401,1,33,
148,9,401,3,0,
148,9,406,4,0,
148,9,407,1,39,
148,9,434,3,0,
148,9,445,4,0,
148,10,21,1,21,
148,10,29,3,0,
148,10,35,1,1,1
148,10,43,1,1,2
148,10,53,4,0,
148,10,57,4,0,
148,10,58,4,0,
148,10,59,4,0,
148,10,63,1,67,
148,10,63,4,0,
148,10,82,1,15,
148,10,85,4,0,
148,10,86,1,1,3
148,10,86,1,5,
148,10,86,4,0,
148,10,87,4,0,
148,10,92,4,0,
148,10,97,1,25,
148,10,104,4,0,
148,10,126,4,0,
148,10,127,4,0,
148,10,129,3,0,
148,10,156,4,0,
148,10,164,4,0,
148,10,173,3,0,
148,10,182,4,0,
148,10,196,3,0,
148,10,200,1,61,
148,10,200,3,0,
148,10,203,4,0,
148,10,207,4,0,
148,10,213,4,0,
148,10,214,4,0,
148,10,216,4,0,
148,10,218,4,0,
148,10,219,1,47,
148,10,219,4,0,
148,10,231,4,0,
148,10,237,4,0,
148,10,239,1,1,4
148,10,239,1,11,
148,10,239,3,0,
148,10,240,4,0,
148,10,241,4,0,
148,10,250,4,0,
148,10,258,4,0,
148,10,263,4,0,
148,10,290,4,0,
148,10,349,1,53,
148,10,351,4,0,
148,10,352,4,0,
148,10,363,4,0,
148,10,401,1,33,
148,10,401,3,0,
148,10,406,4,0,
148,10,407,1,39,
148,10,434,3,0,
148,10,445,4,0,
148,11,21,1,21,
148,11,35,1,1,1
148,11,43,1,1,2
148,11,53,4,0,
148,11,57,4,0,
148,11,58,4,0,
148,11,59,4,0,
148,11,63,1,75,
148,11,63,4,0,
148,11,82,1,15,
148,11,85,4,0,
148,11,86,1,1,3
148,11,86,1,5,
148,11,86,4,0,
148,11,87,4,0,
148,11,92,4,0,
148,11,97,1,25,
148,11,104,4,0,
148,11,113,4,0,
148,11,126,4,0,
148,11,127,4,0,
148,11,156,4,0,
148,11,164,4,0,
148,11,182,4,0,
148,11,200,1,67,
148,11,207,4,0,
148,11,213,4,0,
148,11,216,4,0,
148,11,218,4,0,
148,11,219,1,53,
148,11,219,4,0,
148,11,237,4,0,
148,11,239,1,1,4
148,11,239,1,11,
148,11,240,4,0,
148,11,241,4,0,
148,11,258,4,0,
148,11,263,4,0,
148,11,349,1,61,
148,11,401,1,39,
148,11,407,1,47,
148,11,434,3,0,
148,11,496,4,0,
148,11,510,4,0,
148,11,525,1,33,
148,11,525,4,0,
148,12,21,1,29,
148,12,35,1,1,1
148,12,43,1,1,2
148,12,53,4,0,
148,12,57,4,0,
148,12,58,4,0,
148,12,59,4,0,
148,12,63,1,65,
148,12,63,4,0,
148,12,82,1,22,
148,12,85,4,0,
148,12,86,1,1,3
148,12,86,1,8,
148,12,87,4,0,
148,12,92,4,0,
148,12,97,1,38,
148,12,104,4,0,
148,12,126,4,0,
148,12,127,4,0,
148,12,156,4,0,
148,12,182,4,0,
148,12,200,1,56,
148,12,213,4,0,
148,12,216,4,0,
148,12,218,4,0,
148,12,219,1,47,
148,12,219,4,0,
148,12,231,4,0,
148,12,237,4,0,
148,12,239,1,1,4
148,12,239,1,15,
148,12,240,4,0,
148,12,241,4,0,
148,12,258,4,0,
148,12,263,4,0,
148,12,290,4,0,
148,12,351,4,0,
148,12,352,4,0,
148,13,21,1,29,
148,13,34,3,0,
148,13,35,1,1,1
148,13,38,3,0,
148,13,43,1,1,2
148,13,53,4,0,
148,13,57,4,0,
148,13,58,4,0,
148,13,59,4,0,
148,13,63,1,65,
148,13,63,4,0,
148,13,82,1,22,
148,13,85,4,0,
148,13,86,1,1,3
148,13,86,1,8,
148,13,86,3,0,
148,13,87,4,0,
148,13,92,4,0,
148,13,97,1,38,
148,13,102,3,0,
148,13,104,4,0,
148,13,126,4,0,
148,13,127,4,0,
148,13,156,4,0,
148,13,164,3,0,
148,13,182,4,0,
148,13,196,3,0,
148,13,200,1,56,
148,13,207,3,0,
148,13,213,4,0,
148,13,216,4,0,
148,13,218,4,0,
148,13,219,1,47,
148,13,219,4,0,
148,13,231,4,0,
148,13,237,4,0,
148,13,239,1,1,4
148,13,239,1,15,
148,13,240,4,0,
148,13,241,4,0,
148,13,258,4,0,
148,13,263,4,0,
148,13,290,4,0,
148,13,351,4,0,
148,13,352,4,0,
148,14,20,3,0,
148,14,21,1,21,
148,14,35,1,1,1
148,14,43,1,1,2
148,14,53,4,0,
148,14,57,4,0,
148,14,58,4,0,
148,14,59,4,0,
148,14,63,1,75,
148,14,63,4,0,
148,14,82,1,15,
148,14,85,4,0,
148,14,86,1,1,3
148,14,86,1,5,
148,14,86,4,0,
148,14,87,4,0,
148,14,92,4,0,
148,14,97,1,25,
148,14,104,4,0,
148,14,113,4,0,
148,14,126,4,0,
148,14,127,4,0,
148,14,156,4,0,
148,14,164,4,0,
148,14,173,3,0,
148,14,182,4,0,
148,14,196,3,0,
148,14,200,1,67,
148,14,200,3,0,
148,14,207,4,0,
148,14,213,4,0,
148,14,214,3,0,
148,14,216,4,0,
148,14,218,4,0,
148,14,219,1,53,
148,14,219,4,0,
148,14,231,3,0,
148,14,237,4,0,
148,14,239,1,1,4
148,14,239,1,11,
148,14,240,4,0,
148,14,241,4,0,
148,14,258,4,0,
148,14,263,4,0,
148,14,349,1,61,
148,14,401,1,39,
148,14,401,3,0,
148,14,406,3,0,
148,14,407,1,47,
148,14,434,3,0,
148,14,496,4,0,
148,14,510,4,0,
148,14,525,1,33,
148,14,525,4,0,
148,15,21,1,21,
148,15,35,1,1,1
148,15,43,1,1,2
148,15,53,4,0,
148,15,57,4,0,
148,15,58,4,0,
148,15,59,4,0,
148,15,63,1,75,
148,15,63,4,0,
148,15,82,1,15,
148,15,85,4,0,
148,15,86,1,1,3
148,15,86,1,5,
148,15,86,4,0,
148,15,87,4,0,
148,15,92,4,0,
148,15,97,1,25,
148,15,104,4,0,
148,15,113,4,0,
148,15,126,4,0,
148,15,127,4,0,
148,15,156,4,0,
148,15,164,4,0,
148,15,182,4,0,
148,15,200,1,67,
148,15,207,4,0,
148,15,213,4,0,
148,15,214,4,0,
148,15,216,4,0,
148,15,218,4,0,
148,15,219,1,53,
148,15,219,4,0,
148,15,237,4,0,
148,15,239,1,1,4
148,15,239,1,11,
148,15,240,4,0,
148,15,241,4,0,
148,15,258,4,0,
148,15,263,4,0,
148,15,349,1,61,
148,15,401,1,39,
148,15,407,1,47,
148,15,434,3,0,
148,15,496,4,0,
148,15,510,4,0,
148,15,525,1,33,
148,15,525,4,0,
148,15,590,4,0,
148,16,20,3,0,
148,16,21,1,21,1
148,16,35,1,1,1
148,16,43,1,1,2
148,16,53,4,0,
148,16,57,4,0,
148,16,58,4,0,
148,16,59,4,0,
148,16,63,1,75,1
148,16,63,4,0,
148,16,82,1,15,1
148,16,85,4,0,
148,16,86,1,1,3
148,16,86,1,5,1
148,16,86,4,0,
148,16,87,4,0,
148,16,92,4,0,
148,16,97,1,25,1
148,16,104,4,0,
148,16,113,4,0,
148,16,126,4,0,
148,16,127,4,0,
148,16,156,4,0,
148,16,164,4,0,
148,16,173,3,0,
148,16,182,4,0,
148,16,196,3,0,
148,16,200,1,67,1
148,16,200,3,0,
148,16,207,4,0,
148,16,213,4,0,
148,16,214,4,0,
148,16,216,4,0,
148,16,218,4,0,
148,16,219,1,53,1
148,16,219,4,0,
148,16,231,3,0,
148,16,237,4,0,
148,16,239,1,1,4
148,16,239,1,11,1
148,16,240,4,0,
148,16,241,4,0,
148,16,258,4,0,
148,16,263,4,0,
148,16,290,4,0,
148,16,349,1,61,1
148,16,351,3,0,
148,16,352,3,0,
148,16,401,1,39,1
148,16,401,3,0,
148,16,406,3,0,
148,16,407,1,47,1
148,16,434,3,0,
148,16,496,4,0,
148,16,510,4,0,
148,16,525,1,33,1
148,16,525,4,0,
148,16,590,4,0,
148,17,21,1,21,
148,17,35,1,1,1
148,17,43,1,1,2
148,17,53,4,0,
148,17,57,4,0,
148,17,58,4,0,
148,17,59,4,0,
148,17,63,1,75,
148,17,63,4,0,
148,17,82,1,15,
148,17,85,4,0,
148,17,86,1,1,3
148,17,86,1,5,
148,17,86,4,0,
148,17,87,4,0,
148,17,92,4,0,
148,17,97,1,25,
148,17,104,4,0,
148,17,113,4,0,
148,17,126,4,0,
148,17,127,4,0,
148,17,156,4,0,
148,17,164,4,0,
148,17,182,4,0,
148,17,200,1,67,
148,17,207,4,0,
148,17,213,4,0,
148,17,214,4,0,
148,17,216,4,0,
148,17,218,4,0,
148,17,219,1,53,
148,17,219,4,0,
148,17,237,4,0,
148,17,239,1,1,4
148,17,239,1,11,
148,17,240,4,0,
148,17,241,4,0,
148,17,258,4,0,
148,17,263,4,0,
148,17,349,1,61,
148,17,401,1,39,
148,17,407,1,47,
148,17,434,3,0,
148,17,496,4,0,
148,17,525,1,33,
148,17,525,4,0,
148,17,590,4,0,
148,17,693,4,0,
148,18,21,1,21,
148,18,35,1,1,1
148,18,43,1,1,2
148,18,53,4,0,
148,18,57,4,0,
148,18,58,4,0,
148,18,59,4,0,
148,18,63,1,75,
148,18,63,4,0,
148,18,82,1,15,
148,18,85,4,0,
148,18,86,1,1,3
148,18,86,1,5,
148,18,86,4,0,
148,18,87,4,0,
148,18,92,4,0,
148,18,97,1,25,
148,18,104,4,0,
148,18,113,4,0,
148,18,126,4,0,
148,18,127,4,0,
148,18,156,4,0,
148,18,164,4,0,
148,18,182,4,0,
148,18,200,1,67,
148,18,207,4,0,
148,18,213,4,0,
148,18,214,4,0,
148,18,216,4,0,
148,18,218,4,0,
148,18,219,1,53,
148,18,219,4,0,
148,18,237,4,0,
148,18,239,1,1,4
148,18,239,1,11,
148,18,240,4,0,
148,18,241,4,0,
148,18,258,4,0,
148,18,263,4,0,
148,18,349,1,61,
148,18,401,1,39,
148,18,407,1,47,
148,18,434,3,0,
148,18,496,4,0,
148,18,525,1,33,
148,18,525,4,0,
148,18,590,4,0,
148,18,693,4,0,
149,1,13,4,0,
149,1,21,1,35,
149,1,32,4,0,
149,1,34,4,0,
149,1,35,1,1,1
149,1,36,4,0,
149,1,38,4,0,
149,1,43,1,1,2
149,1,55,4,0,
149,1,57,4,0,
149,1,58,4,0,
149,1,59,4,0,
149,1,61,4,0,
149,1,63,1,60,
149,1,63,4,0,
149,1,70,4,0,
149,1,82,1,45,
149,1,82,4,0,
149,1,85,4,0,
149,1,86,1,1,3
149,1,86,1,10,
149,1,86,4,0,
149,1,87,4,0,
149,1,92,4,0,
149,1,97,1,1,4
149,1,97,1,20,
149,1,99,4,0,
149,1,102,4,0,
149,1,104,4,0,
149,1,115,4,0,
149,1,117,4,0,
149,1,126,4,0,
149,1,129,4,0,
149,1,130,4,0,
149,1,156,4,0,
149,1,164,4,0,
149,2,13,4,0,
149,2,21,1,35,
149,2,32,4,0,
149,2,34,4,0,
149,2,35,1,1,1
149,2,36,4,0,
149,2,38,4,0,
149,2,43,1,1,2
149,2,55,4,0,
149,2,57,4,0,
149,2,58,4,0,
149,2,59,4,0,
149,2,61,4,0,
149,2,63,1,60,
149,2,63,4,0,
149,2,70,4,0,
149,2,82,1,45,
149,2,82,4,0,
149,2,85,4,0,
149,2,86,1,1,3
149,2,86,1,10,
149,2,86,4,0,
149,2,87,4,0,
149,2,92,4,0,
149,2,97,1,1,4
149,2,97,1,20,
149,2,99,4,0,
149,2,102,4,0,
149,2,104,4,0,
149,2,115,4,0,
149,2,117,4,0,
149,2,126,4,0,
149,2,129,4,0,
149,2,130,4,0,
149,2,156,4,0,
149,2,164,4,0,
149,3,7,4,0,
149,3,8,4,0,
149,3,9,4,0,
149,3,17,1,55,
149,3,19,4,0,
149,3,21,1,29,
149,3,29,4,0,
149,3,35,1,1,1
149,3,43,1,1,2
149,3,57,4,0,
149,3,59,4,0,
149,3,63,1,75,
149,3,63,4,0,
149,3,70,4,0,
149,3,82,1,22,
149,3,86,1,1,3
149,3,86,1,8,
149,3,87,4,0,
149,3,92,4,0,
149,3,97,1,38,
149,3,104,4,0,
149,3,126,4,0,
149,3,127,4,0,
149,3,129,4,0,
149,3,156,4,0,
149,3,173,4,0,
149,3,174,4,0,
149,3,182,4,0,
149,3,189,4,0,
149,3,192,4,0,
149,3,196,4,0,
149,3,197,4,0,
149,3,200,1,61,
149,3,201,4,0,
149,3,203,4,0,
149,3,207,4,0,
149,3,210,4,0,
149,3,211,4,0,
149,3,213,4,0,
149,3,214,4,0,
149,3,216,4,0,
149,3,218,4,0,
149,3,219,1,47,
149,3,223,4,0,
149,3,225,4,0,
149,3,231,4,0,
149,3,237,4,0,
149,3,239,1,1,4
149,3,239,1,15,
149,3,240,4,0,
149,3,249,4,0,
149,3,250,4,0,
149,4,7,4,0,
149,4,8,4,0,
149,4,9,4,0,
149,4,17,1,55,
149,4,19,4,0,
149,4,21,1,29,
149,4,29,4,0,
149,4,35,1,1,1
149,4,43,1,1,2
149,4,53,3,0,
149,4,57,4,0,
149,4,58,3,0,
149,4,59,4,0,
149,4,63,1,75,
149,4,63,4,0,
149,4,70,4,0,
149,4,82,1,22,
149,4,85,3,0,
149,4,86,1,1,3
149,4,86,1,8,
149,4,87,4,0,
149,4,92,4,0,
149,4,97,1,38,
149,4,104,4,0,
149,4,126,4,0,
149,4,127,4,0,
149,4,129,4,0,
149,4,156,4,0,
149,4,173,4,0,
149,4,174,4,0,
149,4,182,4,0,
149,4,189,4,0,
149,4,192,4,0,
149,4,196,4,0,
149,4,197,4,0,
149,4,200,1,61,
149,4,201,4,0,
149,4,203,4,0,
149,4,207,4,0,
149,4,210,4,0,
149,4,211,4,0,
149,4,213,4,0,
149,4,214,4,0,
149,4,216,4,0,
149,4,218,4,0,
149,4,219,1,47,
149,4,223,4,0,
149,4,225,4,0,
149,4,231,4,0,
149,4,237,4,0,
149,4,239,1,1,4
149,4,239,1,15,
149,4,240,4,0,
149,4,249,4,0,
149,4,250,4,0,
149,5,15,4,0,
149,5,17,1,55,
149,5,19,4,0,
149,5,21,1,29,
149,5,35,1,1,1
149,5,43,1,1,2
149,5,46,4,0,
149,5,53,4,0,
149,5,57,4,0,
149,5,58,4,0,
149,5,59,4,0,
149,5,63,1,75,
149,5,63,4,0,
149,5,70,4,0,
149,5,82,1,22,
149,5,85,4,0,
149,5,86,1,1,3
149,5,86,1,8,
149,5,87,4,0,
149,5,89,4,0,
149,5,92,4,0,
149,5,97,1,38,
149,5,104,4,0,
149,5,126,4,0,
149,5,127,4,0,
149,5,156,4,0,
149,5,182,4,0,
149,5,200,1,61,
149,5,201,4,0,
149,5,211,4,0,
149,5,213,4,0,
149,5,216,4,0,
149,5,218,4,0,
149,5,219,1,47,
149,5,219,4,0,
149,5,231,4,0,
149,5,237,4,0,
149,5,239,1,1,4
149,5,239,1,15,
149,5,240,4,0,
149,5,241,4,0,
149,5,249,4,0,
149,5,258,4,0,
149,5,263,4,0,
149,5,264,4,0,
149,5,280,4,0,
149,5,290,4,0,
149,5,291,4,0,
149,5,317,4,0,
149,5,332,4,0,
149,5,337,4,0,
149,5,351,4,0,
149,5,352,4,0,
149,6,7,3,0,
149,6,8,3,0,
149,6,9,3,0,
149,6,15,4,0,
149,6,17,1,55,
149,6,19,4,0,
149,6,21,1,29,
149,6,34,3,0,
149,6,35,1,1,1
149,6,38,3,0,
149,6,43,1,1,2
149,6,46,4,0,
149,6,53,4,0,
149,6,57,4,0,
149,6,58,4,0,
149,6,59,4,0,
149,6,63,1,75,
149,6,63,4,0,
149,6,70,4,0,
149,6,82,1,22,
149,6,85,4,0,
149,6,86,1,1,3
149,6,86,1,8,
149,6,86,3,0,
149,6,87,4,0,
149,6,89,4,0,
149,6,92,4,0,
149,6,97,1,38,
149,6,102,3,0,
149,6,104,4,0,
149,6,126,4,0,
149,6,127,4,0,
149,6,129,3,0,
149,6,156,4,0,
149,6,164,3,0,
149,6,173,3,0,
149,6,182,4,0,
149,6,189,3,0,
149,6,196,3,0,
149,6,200,1,61,
149,6,201,4,0,
149,6,203,3,0,
149,6,207,3,0,
149,6,210,3,0,
149,6,211,4,0,
149,6,213,4,0,
149,6,214,3,0,
149,6,216,4,0,
149,6,218,4,0,
149,6,219,1,47,
149,6,219,4,0,
149,6,223,3,0,
149,6,231,4,0,
149,6,237,4,0,
149,6,239,1,1,4
149,6,239,1,15,
149,6,240,4,0,
149,6,241,4,0,
149,6,249,4,0,
149,6,258,4,0,
149,6,263,4,0,
149,6,264,4,0,
149,6,280,4,0,
149,6,290,4,0,
149,6,291,4,0,
149,6,317,4,0,
149,6,332,4,0,
149,6,337,4,0,
149,6,351,4,0,
149,6,352,4,0,
149,7,15,4,0,
149,7,17,1,55,
149,7,19,4,0,
149,7,21,1,29,
149,7,34,3,0,
149,7,35,1,1,1
149,7,38,3,0,
149,7,43,1,1,2
149,7,46,4,0,
149,7,53,4,0,
149,7,57,4,0,
149,7,58,4,0,
149,7,59,4,0,
149,7,63,1,75,
149,7,63,4,0,
149,7,70,4,0,
149,7,82,1,22,
149,7,85,4,0,
149,7,86,1,1,3
149,7,86,1,8,
149,7,86,3,0,
149,7,87,4,0,
149,7,89,4,0,
149,7,92,4,0,
149,7,97,1,38,
149,7,102,3,0,
149,7,104,4,0,
149,7,126,4,0,
149,7,127,4,0,
149,7,156,4,0,
149,7,164,3,0,
149,7,182,4,0,
149,7,200,1,61,
149,7,201,4,0,
149,7,211,4,0,
149,7,213,4,0,
149,7,216,4,0,
149,7,218,4,0,
149,7,219,1,47,
149,7,219,4,0,
149,7,231,4,0,
149,7,237,4,0,
149,7,239,1,1,4
149,7,239,1,15,
149,7,240,4,0,
149,7,241,4,0,
149,7,249,4,0,
149,7,258,4,0,
149,7,263,4,0,
149,7,264,4,0,
149,7,280,4,0,
149,7,290,4,0,
149,7,291,4,0,
149,7,317,4,0,
149,7,332,4,0,
149,7,337,4,0,
149,7,351,4,0,
149,7,352,4,0,
149,8,7,1,1,1
149,8,9,1,1,2
149,8,15,4,0,
149,8,17,1,55,
149,8,19,4,0,
149,8,21,1,21,
149,8,35,1,1,4
149,8,43,1,1,5
149,8,46,4,0,
149,8,53,4,0,
149,8,57,4,0,
149,8,58,4,0,
149,8,59,4,0,
149,8,63,1,73,
149,8,63,4,0,
149,8,70,4,0,
149,8,82,1,15,
149,8,85,4,0,
149,8,86,1,1,6
149,8,86,1,5,
149,8,86,4,0,
149,8,87,4,0,
149,8,89,4,0,
149,8,92,4,0,
149,8,97,1,25,
149,8,104,4,0,
149,8,126,4,0,
149,8,127,4,0,
149,8,156,4,0,
149,8,157,4,0,
149,8,164,4,0,
149,8,182,4,0,
149,8,200,1,64,
149,8,201,4,0,
149,8,203,4,0,
149,8,207,4,0,
149,8,211,4,0,
149,8,213,4,0,
149,8,214,4,0,
149,8,216,4,0,
149,8,218,4,0,
149,8,219,1,47,
149,8,219,4,0,
149,8,231,4,0,
149,8,237,4,0,
149,8,239,1,1,7
149,8,239,1,11,
149,8,240,4,0,
149,8,241,4,0,
149,8,249,4,0,
149,8,258,4,0,
149,8,263,4,0,
149,8,264,4,0,
149,8,280,4,0,
149,8,290,4,0,
149,8,317,4,0,
149,8,332,4,0,
149,8,337,4,0,
149,8,349,1,53,
149,8,351,4,0,
149,8,352,4,0,
149,8,355,1,1,3
149,8,355,4,0,
149,8,363,4,0,
149,8,374,4,0,
149,8,401,1,33,
149,8,406,4,0,
149,8,407,1,39,
149,8,411,4,0,
149,8,416,4,0,
149,8,432,4,0,
149,8,434,3,0,
149,8,444,4,0,
149,8,445,4,0,
149,9,7,1,1,1
149,9,7,3,0,
149,9,8,3,0,
149,9,9,1,1,2
149,9,9,3,0,
149,9,15,4,0,
149,9,17,1,55,
149,9,19,4,0,
149,9,21,1,21,
149,9,35,1,1,4
149,9,43,1,1,5
149,9,46,4,0,
149,9,53,4,0,
149,9,57,4,0,
149,9,58,4,0,
149,9,59,4,0,
149,9,63,1,73,
149,9,63,4,0,
149,9,70,4,0,
149,9,82,1,15,
149,9,85,4,0,
149,9,86,1,1,6
149,9,86,1,5,
149,9,86,4,0,
149,9,87,4,0,
149,9,89,4,0,
149,9,92,4,0,
149,9,97,1,25,
149,9,104,4,0,
149,9,126,4,0,
149,9,127,4,0,
149,9,129,3,0,
149,9,156,4,0,
149,9,157,4,0,
149,9,164,4,0,
149,9,173,3,0,
149,9,182,4,0,
149,9,189,3,0,
149,9,196,3,0,
149,9,200,1,64,
149,9,200,3,0,
149,9,201,4,0,
149,9,203,4,0,
149,9,207,4,0,
149,9,210,3,0,
149,9,211,4,0,
149,9,213,4,0,
149,9,214,4,0,
149,9,216,4,0,
149,9,218,4,0,
149,9,219,1,47,
149,9,219,4,0,
149,9,231,4,0,
149,9,237,4,0,
149,9,239,1,1,7
149,9,239,1,11,
149,9,239,3,0,
149,9,240,4,0,
149,9,241,4,0,
149,9,249,4,0,
149,9,257,3,0,
149,9,258,4,0,
149,9,263,4,0,
149,9,264,4,0,
149,9,276,3,0,
149,9,280,4,0,
149,9,290,4,0,
149,9,291,3,0,
149,9,314,3,0,
149,9,317,4,0,
149,9,332,4,0,
149,9,337,4,0,
149,9,349,1,53,
149,9,351,4,0,
149,9,352,4,0,
149,9,355,1,1,3
149,9,355,4,0,
149,9,363,4,0,
149,9,374,4,0,
149,9,401,1,33,
149,9,401,3,0,
149,9,406,4,0,
149,9,407,1,39,
149,9,411,4,0,
149,9,416,4,0,
149,9,432,4,0,
149,9,434,3,0,
149,9,442,3,0,
149,9,444,4,0,
149,9,445,4,0,
149,9,466,3,0,
149,10,7,1,1,1
149,10,7,3,0,
149,10,8,3,0,
149,10,9,1,1,2
149,10,9,3,0,
149,10,15,4,0,
149,10,17,1,55,
149,10,19,4,0,
149,10,21,1,21,
149,10,29,3,0,
149,10,35,1,1,4
149,10,43,1,1,5
149,10,46,4,0,
149,10,53,4,0,
149,10,57,4,0,
149,10,58,4,0,
149,10,59,4,0,
149,10,63,1,73,
149,10,63,4,0,
149,10,70,4,0,
149,10,82,1,15,
149,10,85,4,0,
149,10,86,1,1,6
149,10,86,1,5,
149,10,86,4,0,
149,10,87,4,0,
149,10,89,4,0,
149,10,92,4,0,
149,10,97,1,25,
149,10,104,4,0,
149,10,126,4,0,
149,10,127,4,0,
149,10,129,3,0,
149,10,156,4,0,
149,10,157,4,0,
149,10,164,4,0,
149,10,173,3,0,
149,10,182,4,0,
149,10,189,3,0,
149,10,196,3,0,
149,10,200,1,64,
149,10,200,3,0,
149,10,201,4,0,
149,10,203,4,0,
149,10,207,4,0,
149,10,210,3,0,
149,10,211,4,0,
149,10,213,4,0,
149,10,214,4,0,
149,10,216,4,0,
149,10,218,4,0,
149,10,219,1,47,
149,10,219,4,0,
149,10,231,4,0,
149,10,237,4,0,
149,10,239,1,1,7
149,10,239,1,11,
149,10,239,3,0,
149,10,240,4,0,
149,10,241,4,0,
149,10,249,4,0,
149,10,250,4,0,
149,10,257,3,0,
149,10,258,4,0,
149,10,263,4,0,
149,10,264,4,0,
149,10,276,3,0,
149,10,280,4,0,
149,10,290,4,0,
149,10,291,3,0,
149,10,314,3,0,
149,10,317,4,0,
149,10,332,4,0,
149,10,337,4,0,
149,10,349,1,53,
149,10,351,4,0,
149,10,352,4,0,
149,10,355,1,1,3
149,10,355,4,0,
149,10,363,4,0,
149,10,366,3,0,
149,10,374,4,0,
149,10,401,1,33,
149,10,401,3,0,
149,10,406,4,0,
149,10,407,1,39,
149,10,411,4,0,
149,10,416,4,0,
149,10,434,3,0,
149,10,442,3,0,
149,10,444,4,0,
149,10,445,4,0,
149,10,466,3,0,
149,11,7,1,1,1
149,11,9,1,1,2
149,11,15,4,0,
149,11,17,1,55,
149,11,19,4,0,
149,11,21,1,21,
149,11,35,1,1,4
149,11,43,1,1,5
149,11,46,4,0,
149,11,53,4,0,
149,11,57,4,0,
149,11,58,4,0,
149,11,59,4,0,
149,11,63,1,75,
149,11,63,4,0,
149,11,70,4,0,
149,11,82,1,15,
149,11,85,4,0,
149,11,86,1,1,6
149,11,86,1,5,
149,11,86,4,0,
149,11,87,4,0,
149,11,89,4,0,
149,11,92,4,0,
149,11,97,1,25,
149,11,104,4,0,
149,11,113,4,0,
149,11,126,4,0,
149,11,127,4,0,
149,11,156,4,0,
149,11,157,4,0,
149,11,164,4,0,
149,11,182,4,0,
149,11,200,1,67,
149,11,201,4,0,
149,11,207,4,0,
149,11,213,4,0,
149,11,216,4,0,
149,11,218,4,0,
149,11,219,1,53,
149,11,219,4,0,
149,11,237,4,0,
149,11,239,1,1,7
149,11,239,1,11,
149,11,240,4,0,
149,11,241,4,0,
149,11,249,4,0,
149,11,258,4,0,
149,11,263,4,0,
149,11,280,4,0,
149,11,291,4,0,
149,11,317,4,0,
149,11,332,4,0,
149,11,337,4,0,
149,11,349,1,61,
149,11,355,1,1,3
149,11,374,4,0,
149,11,401,1,39,
149,11,407,1,47,
149,11,411,4,0,
149,11,416,4,0,
149,11,434,3,0,
149,11,444,4,0,
149,11,468,4,0,
149,11,496,4,0,
149,11,507,4,0,
149,11,510,4,0,
149,11,523,4,0,
149,11,525,1,33,
149,11,525,4,0,
149,11,542,1,81,
149,12,15,4,0,
149,12,17,1,55,
149,12,19,4,0,
149,12,21,1,29,
149,12,35,1,1,1
149,12,43,1,1,2
149,12,46,4,0,
149,12,53,4,0,
149,12,57,4,0,
149,12,58,4,0,
149,12,59,4,0,
149,12,63,1,75,
149,12,63,4,0,
149,12,70,4,0,
149,12,82,1,22,
149,12,85,4,0,
149,12,86,1,1,3
149,12,86,1,8,
149,12,87,4,0,
149,12,89,4,0,
149,12,92,4,0,
149,12,97,1,38,
149,12,104,4,0,
149,12,126,4,0,
149,12,127,4,0,
149,12,156,4,0,
149,12,182,4,0,
149,12,200,1,61,
149,12,201,4,0,
149,12,211,4,0,
149,12,213,4,0,
149,12,216,4,0,
149,12,218,4,0,
149,12,219,1,47,
149,12,219,4,0,
149,12,231,4,0,
149,12,237,4,0,
149,12,239,1,1,4
149,12,239,1,15,
149,12,240,4,0,
149,12,241,4,0,
149,12,249,4,0,
149,12,258,4,0,
149,12,263,4,0,
149,12,264,4,0,
149,12,280,4,0,
149,12,290,4,0,
149,12,291,4,0,
149,12,317,4,0,
149,12,332,4,0,
149,12,337,4,0,
149,12,351,4,0,
149,12,352,4,0,
149,13,15,4,0,
149,13,17,1,55,
149,13,19,4,0,
149,13,21,1,29,
149,13,34,3,0,
149,13,35,1,1,1
149,13,38,3,0,
149,13,43,1,1,2
149,13,46,4,0,
149,13,53,4,0,
149,13,57,4,0,
149,13,58,4,0,
149,13,59,4,0,
149,13,63,1,75,
149,13,63,4,0,
149,13,70,4,0,
149,13,82,1,22,
149,13,85,4,0,
149,13,86,1,1,3
149,13,86,1,8,
149,13,86,3,0,
149,13,87,4,0,
149,13,89,4,0,
149,13,92,4,0,
149,13,97,1,38,
149,13,102,3,0,
149,13,104,4,0,
149,13,126,4,0,
149,13,127,4,0,
149,13,156,4,0,
149,13,164,3,0,
149,13,182,4,0,
149,13,196,3,0,
149,13,200,1,61,
149,13,201,4,0,
149,13,207,3,0,
149,13,211,4,0,
149,13,213,4,0,
149,13,216,4,0,
149,13,218,4,0,
149,13,219,1,47,
149,13,219,4,0,
149,13,231,4,0,
149,13,237,4,0,
149,13,239,1,1,4
149,13,239,1,15,
149,13,240,4,0,
149,13,241,4,0,
149,13,249,4,0,
149,13,258,4,0,
149,13,263,4,0,
149,13,264,4,0,
149,13,280,4,0,
149,13,290,4,0,
149,13,291,4,0,
149,13,317,4,0,
149,13,332,4,0,
149,13,337,4,0,
149,13,351,4,0,
149,13,352,4,0,
149,14,7,1,1,1
149,14,7,3,0,
149,14,8,3,0,
149,14,9,1,1,2
149,14,9,3,0,
149,14,15,4,0,
149,14,17,1,55,
149,14,19,4,0,
149,14,20,3,0,
149,14,21,1,21,
149,14,35,1,1,4
149,14,43,1,1,5
149,14,46,4,0,
149,14,53,4,0,
149,14,57,4,0,
149,14,58,4,0,
149,14,59,4,0,
149,14,63,1,75,
149,14,63,4,0,
149,14,70,4,0,
149,14,82,1,15,
149,14,85,4,0,
149,14,86,1,1,6
149,14,86,1,5,
149,14,86,4,0,
149,14,87,4,0,
149,14,89,4,0,
149,14,92,4,0,
149,14,97,1,25,
149,14,104,4,0,
149,14,113,4,0,
149,14,126,4,0,
149,14,127,4,0,
149,14,156,4,0,
149,14,157,4,0,
149,14,164,4,0,
149,14,173,3,0,
149,14,182,4,0,
149,14,196,3,0,
149,14,200,1,67,
149,14,200,3,0,
149,14,201,4,0,
149,14,207,4,0,
149,14,213,4,0,
149,14,214,3,0,
149,14,216,4,0,
149,14,218,4,0,
149,14,219,1,53,
149,14,219,4,0,
149,14,231,3,0,
149,14,237,4,0,
149,14,239,1,1,7
149,14,239,1,11,
149,14,240,4,0,
149,14,241,4,0,
149,14,249,4,0,
149,14,257,3,0,
149,14,258,4,0,
149,14,263,4,0,
149,14,276,3,0,
149,14,280,4,0,
149,14,291,4,0,
149,14,317,4,0,
149,14,332,4,0,
149,14,337,4,0,
149,14,349,1,61,
149,14,355,1,1,3
149,14,355,3,0,
149,14,366,3,0,
149,14,374,4,0,
149,14,401,1,39,
149,14,401,3,0,
149,14,406,3,0,
149,14,407,1,47,
149,14,411,4,0,
149,14,416,4,0,
149,14,434,3,0,
149,14,442,3,0,
149,14,444,4,0,
149,14,468,4,0,
149,14,496,4,0,
149,14,507,4,0,
149,14,510,4,0,
149,14,523,4,0,
149,14,525,1,33,
149,14,525,4,0,
149,14,542,1,81,
149,15,7,1,1,2
149,15,9,1,1,3
149,15,15,4,0,
149,15,17,1,55,
149,15,19,4,0,
149,15,21,1,21,
149,15,35,1,1,5
149,15,43,1,1,6
149,15,46,4,0,
149,15,53,4,0,
149,15,57,4,0,
149,15,58,4,0,
149,15,59,4,0,
149,15,63,1,75,
149,15,63,4,0,
149,15,70,4,0,
149,15,82,1,15,
149,15,85,4,0,
149,15,86,1,1,7
149,15,86,1,5,
149,15,86,4,0,
149,15,87,4,0,
149,15,89,4,0,
149,15,92,4,0,
149,15,97,1,25,
149,15,104,4,0,
149,15,113,4,0,
149,15,126,4,0,
149,15,127,4,0,
149,15,156,4,0,
149,15,157,4,0,
149,15,164,4,0,
149,15,182,4,0,
149,15,200,1,67,
149,15,201,4,0,
149,15,207,4,0,
149,15,211,4,0,
149,15,213,4,0,
149,15,214,4,0,
149,15,216,4,0,
149,15,218,4,0,
149,15,219,1,53,
149,15,219,4,0,
149,15,237,4,0,
149,15,239,1,1,8
149,15,239,1,11,
149,15,240,4,0,
149,15,241,4,0,
149,15,249,4,0,
149,15,258,4,0,
149,15,263,4,0,
149,15,280,4,0,
149,15,317,4,0,
149,15,332,4,0,
149,15,337,4,0,
149,15,349,1,61,
149,15,355,1,1,4
149,15,355,4,0,
149,15,374,4,0,
149,15,401,1,39,
149,15,407,1,47,
149,15,411,4,0,
149,15,416,4,0,
149,15,434,3,0,
149,15,444,4,0,
149,15,468,4,0,
149,15,496,4,0,
149,15,507,4,0,
149,15,510,4,0,
149,15,523,4,0,
149,15,525,1,33,
149,15,525,4,0,
149,15,542,1,1,1
149,15,542,1,81,
149,15,590,4,0,
149,15,612,4,0,
149,16,7,1,1,2
149,16,7,3,0,
149,16,8,3,0,
149,16,9,1,1,3
149,16,9,3,0,
149,16,15,4,0,
149,16,17,1,55,1
149,16,19,4,0,
149,16,20,3,0,
149,16,21,1,21,1
149,16,35,1,1,5
149,16,43,1,1,6
149,16,46,4,0,
149,16,53,4,0,
149,16,57,4,0,
149,16,58,4,0,
149,16,59,4,0,
149,16,63,1,75,1
149,16,63,4,0,
149,16,70,4,0,
149,16,82,1,15,1
149,16,85,4,0,
149,16,86,1,1,7
149,16,86,1,5,1
149,16,86,4,0,
149,16,87,4,0,
149,16,89,4,0,
149,16,92,4,0,
149,16,97,1,25,1
149,16,104,4,0,
149,16,113,4,0,
149,16,126,4,0,
149,16,127,4,0,
149,16,156,4,0,
149,16,157,4,0,
149,16,164,4,0,
149,16,173,3,0,
149,16,182,4,0,
149,16,196,3,0,
149,16,200,1,67,1
149,16,200,3,0,
149,16,201,4,0,
149,16,207,4,0,
149,16,211,4,0,
149,16,213,4,0,
149,16,214,4,0,
149,16,216,4,0,
149,16,218,4,0,
149,16,219,1,53,1
149,16,219,4,0,
149,16,231,3,0,
149,16,237,4,0,
149,16,239,1,1,8
149,16,239,1,11,1
149,16,240,4,0,
149,16,241,4,0,
149,16,249,4,0,
149,16,257,3,0,
149,16,258,4,0,
149,16,263,4,0,
149,16,264,3,0,
149,16,276,3,0,
149,16,280,4,0,
149,16,290,4,0,
149,16,291,4,0,
149,16,317,4,0,
149,16,332,4,0,
149,16,337,4,0,
149,16,349,1,61,1
149,16,351,3,0,
149,16,352,3,0,
149,16,355,1,1,4
149,16,355,4,0,
149,16,366,3,0,
149,16,374,4,0,
149,16,401,1,39,1
149,16,401,3,0,
149,16,406,3,0,
149,16,407,1,47,1
149,16,411,4,0,
149,16,416,4,0,
149,16,434,3,0,
149,16,442,3,0,
149,16,444,4,0,
149,16,468,4,0,
149,16,496,4,0,
149,16,507,4,0,
149,16,510,4,0,
149,16,523,4,0,
149,16,525,1,33,1
149,16,525,4,0,
149,16,542,1,1,1
149,16,542,1,81,1
149,16,590,4,0,
149,16,612,4,0,
149,17,7,1,1,3
149,17,9,1,1,4
149,17,17,1,0,
149,17,17,1,1,1
149,17,19,4,0,
149,17,21,1,21,
149,17,35,1,1,6
149,17,43,1,1,7
149,17,46,4,0,
149,17,53,4,0,
149,17,57,4,0,
149,17,58,4,0,
149,17,59,4,0,
149,17,63,1,75,
149,17,63,4,0,
149,17,82,1,15,
149,17,85,4,0,
149,17,86,1,1,8
149,17,86,1,5,
149,17,86,4,0,
149,17,87,4,0,
149,17,89,4,0,
149,17,92,4,0,
149,17,97,1,25,
149,17,104,4,0,
149,17,113,4,0,
149,17,126,4,0,
149,17,127,4,0,
149,17,156,4,0,
149,17,157,4,0,
149,17,164,4,0,
149,17,182,4,0,
149,17,200,1,67,
149,17,201,4,0,
149,17,207,4,0,
149,17,211,4,0,
149,17,213,4,0,
149,17,214,4,0,
149,17,216,4,0,
149,17,218,4,0,
149,17,219,1,53,
149,17,219,4,0,
149,17,237,4,0,
149,17,239,1,1,9
149,17,239,1,11,
149,17,240,4,0,
149,17,241,4,0,
149,17,258,4,0,
149,17,263,4,0,
149,17,280,4,0,
149,17,317,4,0,
149,17,332,4,0,
149,17,337,4,0,
149,17,349,1,61,
149,17,355,1,1,5
149,17,355,4,0,
149,17,374,4,0,
149,17,401,1,39,
149,17,407,1,47,
149,17,411,4,0,
149,17,416,4,0,
149,17,434,3,0,
149,17,444,4,0,
149,17,496,4,0,
149,17,507,4,0,
149,17,523,4,0,
149,17,525,1,33,
149,17,525,4,0,
149,17,542,1,1,2
149,17,542,1,81,
149,17,590,4,0,
149,17,693,4,0,
149,18,7,1,1,3
149,18,9,1,1,4
149,18,17,1,0,
149,18,17,1,1,1
149,18,19,4,0,
149,18,21,1,21,
149,18,35,1,1,6
149,18,43,1,1,7
149,18,46,4,0,
149,18,53,4,0,
149,18,57,4,0,
149,18,58,4,0,
149,18,59,4,0,
149,18,63,1,75,
149,18,63,4,0,
149,18,82,1,15,
149,18,85,4,0,
149,18,86,1,1,8
149,18,86,1,5,
149,18,86,4,0,
149,18,87,4,0,
149,18,89,4,0,
149,18,92,4,0,
149,18,97,1,25,
149,18,104,4,0,
149,18,113,4,0,
149,18,126,4,0,
149,18,127,4,0,
149,18,156,4,0,
149,18,157,4,0,
149,18,164,4,0,
149,18,182,4,0,
149,18,200,1,67,
149,18,201,4,0,
149,18,207,4,0,
149,18,211,4,0,
149,18,213,4,0,
149,18,214,4,0,
149,18,216,4,0,
149,18,218,4,0,
149,18,219,1,53,
149,18,219,4,0,
149,18,237,4,0,
149,18,239,1,1,9
149,18,239,1,11,
149,18,240,4,0,
149,18,241,4,0,
149,18,258,4,0,
149,18,263,4,0,
149,18,280,4,0,
149,18,317,4,0,
149,18,332,4,0,
149,18,337,4,0,
149,18,349,1,61,
149,18,355,1,1,5
149,18,355,4,0,
149,18,374,4,0,
149,18,401,1,39,
149,18,407,1,47,
149,18,411,4,0,
149,18,416,4,0,
149,18,434,3,0,
149,18,444,4,0,
149,18,496,4,0,
149,18,507,4,0,
149,18,523,4,0,
149,18,525,1,33,
149,18,525,4,0,
149,18,542,1,1,2
149,18,542,1,81,
149,18,590,4,0,
149,18,693,4,0,
150,1,5,4,0,
150,1,6,4,0,
150,1,25,4,0,
150,1,34,4,0,
150,1,36,4,0,
150,1,38,4,0,
150,1,50,1,1,2
150,1,54,1,75,
150,1,55,4,0,
150,1,58,4,0,
150,1,59,4,0,
150,1,61,4,0,
150,1,63,4,0,
150,1,66,4,0,
150,1,68,4,0,
150,1,69,4,0,
150,1,70,4,0,
150,1,76,4,0,
150,1,85,4,0,
150,1,86,4,0,
150,1,87,4,0,
150,1,92,4,0,
150,1,93,1,1,1
150,1,94,1,1,4
150,1,94,1,66,
150,1,94,4,0,
150,1,99,4,0,
150,1,100,4,0,
150,1,102,4,0,
150,1,104,4,0,
150,1,105,1,70,
150,1,112,1,63,
150,1,115,4,0,
150,1,117,4,0,
150,1,118,4,0,
150,1,120,4,0,
150,1,126,4,0,
150,1,129,1,1,3
150,1,130,4,0,
150,1,133,1,81,
150,1,148,4,0,
150,1,149,4,0,
150,1,156,4,0,
150,1,161,4,0,
150,1,164,4,0,
150,2,5,4,0,
150,2,25,4,0,
150,2,34,4,0,
150,2,36,4,0,
150,2,38,4,0,
150,2,50,1,1,2
150,2,54,1,75,
150,2,55,4,0,
150,2,58,4,0,
150,2,59,4,0,
150,2,61,4,0,
150,2,63,4,0,
150,2,66,4,0,
150,2,68,4,0,
150,2,69,4,0,
150,2,70,4,0,
150,2,76,4,0,
150,2,85,4,0,
150,2,86,4,0,
150,2,87,4,0,
150,2,92,4,0,
150,2,93,1,1,1
150,2,94,1,1,4
150,2,94,1,66,
150,2,94,4,0,
150,2,99,4,0,
150,2,100,4,0,
150,2,102,4,0,
150,2,104,4,0,
150,2,105,1,70,
150,2,112,1,63,
150,2,115,4,0,
150,2,117,4,0,
150,2,118,4,0,
150,2,120,4,0,
150,2,126,4,0,
150,2,129,1,1,3
150,2,130,4,0,
150,2,133,1,81,
150,2,148,4,0,
150,2,149,4,0,
150,2,156,4,0,
150,2,161,4,0,
150,2,164,4,0,
150,3,7,4,0,
150,3,8,4,0,
150,3,9,4,0,
150,3,29,4,0,
150,3,50,1,1,2
150,3,54,1,55,
150,3,59,4,0,
150,3,63,4,0,
150,3,70,4,0,
150,3,76,4,0,
150,3,87,4,0,
150,3,92,4,0,
150,3,93,1,1,1
150,3,94,1,66,
150,3,94,4,0,
150,3,104,4,0,
150,3,105,1,88,
150,3,112,1,11,
150,3,126,4,0,
150,3,129,1,22,
150,3,129,4,0,
150,3,133,1,77,
150,3,138,4,0,
150,3,148,4,0,
150,3,156,4,0,
150,3,171,4,0,
150,3,173,4,0,
150,3,174,4,0,
150,3,182,4,0,
150,3,189,4,0,
150,3,192,4,0,
150,3,196,4,0,
150,3,197,4,0,
150,3,203,4,0,
150,3,207,4,0,
150,3,214,4,0,
150,3,216,4,0,
150,3,218,4,0,
150,3,219,1,99,
150,3,223,4,0,
150,3,231,4,0,
150,3,237,4,0,
150,3,240,4,0,
150,3,241,4,0,
150,3,244,1,33,
150,3,244,4,0,
150,3,247,4,0,
150,3,248,1,44,
150,3,249,4,0,
150,4,7,4,0,
150,4,8,4,0,
150,4,9,4,0,
150,4,29,4,0,
150,4,50,1,1,2
150,4,53,3,0,
150,4,54,1,55,
150,4,58,3,0,
150,4,59,4,0,
150,4,63,4,0,
150,4,70,4,0,
150,4,76,4,0,
150,4,85,3,0,
150,4,87,4,0,
150,4,92,4,0,
150,4,93,1,1,1
150,4,94,1,66,
150,4,94,4,0,
150,4,104,4,0,
150,4,105,1,88,
150,4,112,1,11,
150,4,126,4,0,
150,4,129,1,22,
150,4,129,4,0,
150,4,133,1,77,
150,4,138,4,0,
150,4,148,4,0,
150,4,156,4,0,
150,4,171,4,0,
150,4,173,4,0,
150,4,174,4,0,
150,4,182,4,0,
150,4,189,4,0,
150,4,192,4,0,
150,4,196,4,0,
150,4,197,4,0,
150,4,203,4,0,
150,4,207,4,0,
150,4,214,4,0,
150,4,216,4,0,
150,4,218,4,0,
150,4,219,1,99,
150,4,223,4,0,
150,4,231,4,0,
150,4,237,4,0,
150,4,240,4,0,
150,4,241,4,0,
150,4,244,1,33,
150,4,244,4,0,
150,4,247,4,0,
150,4,248,1,44,
150,4,249,4,0,
150,5,50,1,1,2
150,5,53,4,0,
150,5,54,1,55,
150,5,58,4,0,
150,5,59,4,0,
150,5,63,4,0,
150,5,70,4,0,
150,5,76,4,0,
150,5,85,4,0,
150,5,87,4,0,
150,5,89,4,0,
150,5,92,4,0,
150,5,93,1,1,1
150,5,94,1,66,
150,5,94,4,0,
150,5,104,4,0,
150,5,105,1,88,
150,5,112,1,11,
150,5,113,4,0,
150,5,115,4,0,
150,5,126,4,0,
150,5,129,1,22,
150,5,133,1,77,
150,5,148,4,0,
150,5,156,4,0,
150,5,182,4,0,
150,5,201,4,0,
150,5,216,4,0,
150,5,218,4,0,
150,5,219,1,99,
150,5,219,4,0,
150,5,231,4,0,
150,5,237,4,0,
150,5,240,4,0,
150,5,241,4,0,
150,5,244,1,33,
150,5,247,4,0,
150,5,248,1,44,
150,5,249,4,0,
150,5,258,4,0,
150,5,259,4,0,
150,5,263,4,0,
150,5,264,4,0,
150,5,269,4,0,
150,5,280,4,0,
150,5,285,4,0,
150,5,289,4,0,
150,5,290,4,0,
150,5,317,4,0,
150,5,332,4,0,
150,5,339,4,0,
150,5,347,4,0,
150,5,351,4,0,
150,5,352,4,0,
150,6,5,3,0,
150,6,7,3,0,
150,6,8,3,0,
150,6,9,3,0,
150,6,25,3,0,
150,6,34,3,0,
150,6,38,3,0,
150,6,50,1,1,2
150,6,53,4,0,
150,6,54,1,55,
150,6,58,4,0,
150,6,59,4,0,
150,6,63,4,0,
150,6,68,3,0,
150,6,69,3,0,
150,6,70,4,0,
150,6,76,4,0,
150,6,85,4,0,
150,6,86,3,0,
150,6,87,4,0,
150,6,89,4,0,
150,6,92,4,0,
150,6,93,1,1,1
150,6,94,1,66,
150,6,94,4,0,
150,6,102,3,0,
150,6,104,4,0,
150,6,105,1,88,
150,6,112,1,11,
150,6,113,4,0,
150,6,115,4,0,
150,6,118,3,0,
150,6,126,4,0,
150,6,129,1,22,
150,6,129,3,0,
150,6,133,1,77,
150,6,138,3,0,
150,6,148,4,0,
150,6,156,4,0,
150,6,164,3,0,
150,6,173,3,0,
150,6,182,4,0,
150,6,189,3,0,
150,6,196,3,0,
150,6,201,4,0,
150,6,203,3,0,
150,6,207,3,0,
150,6,214,3,0,
150,6,216,4,0,
150,6,218,4,0,
150,6,219,1,99,
150,6,219,4,0,
150,6,223,3,0,
150,6,231,4,0,
150,6,237,4,0,
150,6,240,4,0,
150,6,241,4,0,
150,6,244,1,33,
150,6,244,3,0,
150,6,247,4,0,
150,6,248,1,44,
150,6,249,4,0,
150,6,258,4,0,
150,6,259,4,0,
150,6,263,4,0,
150,6,264,4,0,
150,6,269,4,0,
150,6,280,4,0,
150,6,285,4,0,
150,6,289,4,0,
150,6,290,4,0,
150,6,317,4,0,
150,6,332,4,0,
150,6,339,4,0,
150,6,347,4,0,
150,6,351,4,0,
150,6,352,4,0,
150,7,5,3,0,
150,7,25,3,0,
150,7,34,3,0,
150,7,38,3,0,
150,7,50,1,1,2
150,7,53,4,0,
150,7,54,1,22,
150,7,58,4,0,
150,7,59,4,0,
150,7,63,4,0,
150,7,68,3,0,
150,7,69,3,0,
150,7,70,4,0,
150,7,76,4,0,
150,7,85,4,0,
150,7,86,3,0,
150,7,87,4,0,
150,7,89,4,0,
150,7,92,4,0,
150,7,93,1,1,1
150,7,94,1,66,
150,7,94,4,0,
150,7,102,3,0,
150,7,104,4,0,
150,7,105,1,44,
150,7,112,1,11,
150,7,113,4,0,
150,7,115,4,0,
150,7,118,3,0,
150,7,126,4,0,
150,7,129,1,33,
150,7,133,1,99,
150,7,138,3,0,
150,7,148,4,0,
150,7,156,4,0,
150,7,164,3,0,
150,7,182,4,0,
150,7,201,4,0,
150,7,216,4,0,
150,7,218,4,0,
150,7,219,1,55,
150,7,219,4,0,
150,7,231,4,0,
150,7,237,4,0,
150,7,240,4,0,
150,7,241,4,0,
150,7,244,1,77,
150,7,247,4,0,
150,7,248,1,88,
150,7,249,4,0,
150,7,258,4,0,
150,7,259,4,0,
150,7,263,4,0,
150,7,264,4,0,
150,7,269,4,0,
150,7,280,4,0,
150,7,285,4,0,
150,7,289,4,0,
150,7,290,4,0,
150,7,317,4,0,
150,7,332,4,0,
150,7,339,4,0,
150,7,347,4,0,
150,7,351,4,0,
150,7,352,4,0,
150,8,50,1,1,2
150,8,53,4,0,
150,8,54,1,43,
150,8,58,4,0,
150,8,59,4,0,
150,8,63,4,0,
150,8,70,4,0,
150,8,76,4,0,
150,8,85,4,0,
150,8,86,4,0,
150,8,87,4,0,
150,8,89,4,0,
150,8,92,4,0,
150,8,93,1,1,1
150,8,94,1,71,
150,8,94,4,0,
150,8,104,4,0,
150,8,105,1,86,
150,8,112,1,8,
150,8,113,4,0,
150,8,115,4,0,
150,8,126,4,0,
150,8,129,1,15,
150,8,133,1,57,
150,8,138,4,0,
150,8,148,4,0,
150,8,156,4,0,
150,8,157,4,0,
150,8,164,4,0,
150,8,182,4,0,
150,8,201,4,0,
150,8,203,4,0,
150,8,207,4,0,
150,8,214,4,0,
150,8,216,4,0,
150,8,218,4,0,
150,8,219,1,93,
150,8,219,4,0,
150,8,231,4,0,
150,8,237,4,0,
150,8,240,4,0,
150,8,241,4,0,
150,8,244,1,29,
150,8,244,4,0,
150,8,247,4,0,
150,8,248,1,22,
150,8,249,4,0,
150,8,258,4,0,
150,8,259,4,0,
150,8,261,4,0,
150,8,263,4,0,
150,8,264,4,0,
150,8,269,4,0,
150,8,278,4,0,
150,8,280,4,0,
150,8,285,4,0,
150,8,289,4,0,
150,8,290,4,0,
150,8,317,4,0,
150,8,332,4,0,
150,8,339,4,0,
150,8,347,4,0,
150,8,351,4,0,
150,8,352,4,0,
150,8,357,1,36,
150,8,363,4,0,
150,8,373,4,0,
150,8,374,4,0,
150,8,382,1,79,
150,8,384,1,64,1
150,8,385,1,64,2
150,8,396,1,100,
150,8,398,4,0,
150,8,409,4,0,
150,8,411,4,0,
150,8,412,4,0,
150,8,416,4,0,
150,8,419,4,0,
150,8,427,1,50,
150,8,431,4,0,
150,8,433,4,0,
150,8,444,4,0,
150,8,447,4,0,
150,8,451,4,0,
150,9,7,3,0,
150,9,8,3,0,
150,9,9,3,0,
150,9,50,1,1,2
150,9,53,4,0,
150,9,54,1,43,
150,9,58,4,0,
150,9,59,4,0,
150,9,63,4,0,
150,9,70,4,0,
150,9,76,4,0,
150,9,85,4,0,
150,9,86,4,0,
150,9,87,4,0,
150,9,89,4,0,
150,9,92,4,0,
150,9,93,1,1,1
150,9,94,1,71,
150,9,94,4,0,
150,9,104,4,0,
150,9,105,1,86,
150,9,112,1,8,
150,9,113,4,0,
150,9,115,4,0,
150,9,126,4,0,
150,9,129,1,15,
150,9,129,3,0,
150,9,133,1,57,
150,9,138,4,0,
150,9,148,4,0,
150,9,156,4,0,
150,9,157,4,0,
150,9,164,4,0,
150,9,173,3,0,
150,9,182,4,0,
150,9,189,3,0,
150,9,196,3,0,
150,9,201,4,0,
150,9,203,4,0,
150,9,207,4,0,
150,9,214,4,0,
150,9,216,4,0,
150,9,218,4,0,
150,9,219,1,93,
150,9,219,4,0,
150,9,231,4,0,
150,9,237,4,0,
150,9,240,4,0,
150,9,241,4,0,
150,9,244,1,29,
150,9,244,4,0,
150,9,247,4,0,
150,9,248,1,22,
150,9,249,4,0,
150,9,258,4,0,
150,9,259,4,0,
150,9,261,4,0,
150,9,263,4,0,
150,9,264,4,0,
150,9,269,4,0,
150,9,271,3,0,
150,9,278,4,0,
150,9,280,4,0,
150,9,285,4,0,
150,9,289,4,0,
150,9,290,4,0,
150,9,317,4,0,
150,9,324,3,0,
150,9,332,4,0,
150,9,339,4,0,
150,9,347,4,0,
150,9,351,4,0,
150,9,352,4,0,
150,9,357,1,36,
150,9,363,4,0,
150,9,373,4,0,
150,9,374,4,0,
150,9,382,1,79,
150,9,384,1,64,1
150,9,385,1,64,2
150,9,396,1,100,
150,9,398,4,0,
150,9,401,3,0,
150,9,409,4,0,
150,9,411,4,0,
150,9,412,4,0,
150,9,416,4,0,
150,9,419,4,0,
150,9,427,1,50,
150,9,428,3,0,
150,9,431,4,0,
150,9,433,4,0,
150,9,444,4,0,
150,9,447,4,0,
150,9,451,4,0,
150,10,7,3,0,
150,10,8,3,0,
150,10,9,3,0,
150,10,29,3,0,
150,10,50,1,1,2
150,10,53,4,0,
150,10,54,1,43,
150,10,58,4,0,
150,10,59,4,0,
150,10,63,4,0,
150,10,67,3,0,
150,10,70,4,0,
150,10,76,4,0,
150,10,85,4,0,
150,10,86,4,0,
150,10,87,4,0,
150,10,89,4,0,
150,10,92,4,0,
150,10,93,1,1,1
150,10,94,1,71,
150,10,94,4,0,
150,10,104,4,0,
150,10,105,1,86,
150,10,112,1,8,
150,10,113,4,0,
150,10,115,4,0,
150,10,126,4,0,
150,10,129,1,15,
150,10,129,3,0,
150,10,133,1,57,
150,10,138,4,0,
150,10,148,4,0,
150,10,156,4,0,
150,10,157,4,0,
150,10,164,4,0,
150,10,173,3,0,
150,10,182,4,0,
150,10,189,3,0,
150,10,196,3,0,
150,10,201,4,0,
150,10,203,4,0,
150,10,207,4,0,
150,10,214,4,0,
150,10,216,4,0,
150,10,218,4,0,
150,10,219,1,93,
150,10,219,4,0,
150,10,231,4,0,
150,10,237,4,0,
150,10,240,4,0,
150,10,241,4,0,
150,10,244,1,29,
150,10,244,4,0,
150,10,247,4,0,
150,10,248,1,22,
150,10,249,4,0,
150,10,258,4,0,
150,10,259,4,0,
150,10,261,4,0,
150,10,263,4,0,
150,10,264,4,0,
150,10,269,4,0,
150,10,271,3,0,
150,10,272,3,0,
150,10,277,3,0,
150,10,278,4,0,
150,10,280,4,0,
150,10,285,4,0,
150,10,289,4,0,
150,10,290,4,0,
150,10,317,4,0,
150,10,324,3,0,
150,10,332,4,0,
150,10,339,4,0,
150,10,347,4,0,
150,10,351,4,0,
150,10,352,4,0,
150,10,356,3,0,
150,10,357,1,36,
150,10,363,4,0,
150,10,373,4,0,
150,10,374,4,0,
150,10,382,1,79,
150,10,384,1,64,1
150,10,385,1,64,2
150,10,396,1,100,
150,10,398,4,0,
150,10,401,3,0,
150,10,409,4,0,
150,10,411,4,0,
150,10,412,4,0,
150,10,416,4,0,
150,10,419,4,0,
150,10,427,1,50,
150,10,428,3,0,
150,10,431,4,0,
150,10,433,4,0,
150,10,444,4,0,
150,10,447,4,0,
150,10,451,4,0,
150,11,50,1,1,2
150,11,53,4,0,
150,11,54,1,36,
150,11,58,4,0,
150,11,59,4,0,
150,11,63,4,0,
150,11,70,4,0,
150,11,76,4,0,
150,11,85,4,0,
150,11,86,4,0,
150,11,87,4,0,
150,11,89,4,0,
150,11,92,4,0,
150,11,93,1,1,1
150,11,94,1,64,
150,11,94,4,0,
150,11,104,4,0,
150,11,105,1,79,
150,11,112,1,1,3
150,11,113,4,0,
150,11,115,4,0,
150,11,126,4,0,
150,11,129,1,8,
150,11,133,1,50,
150,11,138,4,0,
150,11,148,4,0,
150,11,156,4,0,
150,11,157,4,0,
150,11,164,4,0,
150,11,182,4,0,
150,11,201,4,0,
150,11,207,4,0,
150,11,216,4,0,
150,11,218,4,0,
150,11,219,1,86,
150,11,219,4,0,
150,11,237,4,0,
150,11,240,4,0,
150,11,241,4,0,
150,11,244,1,22,
150,11,244,4,0,
150,11,247,4,0,
150,11,248,1,15,
150,11,249,4,0,
150,11,258,4,0,
150,11,259,4,0,
150,11,261,4,0,
150,11,263,4,0,
150,11,269,4,0,
150,11,280,4,0,
150,11,317,4,0,
150,11,332,4,0,
150,11,339,4,0,
150,11,347,4,0,
150,11,357,1,29,
150,11,373,4,0,
150,11,374,4,0,
150,11,382,1,71,
150,11,384,1,57,1
150,11,385,1,57,2
150,11,396,1,93,
150,11,398,4,0,
150,11,411,4,0,
150,11,412,4,0,
150,11,416,4,0,
150,11,427,1,43,
150,11,433,4,0,
150,11,444,4,0,
150,11,447,4,0,
150,11,451,4,0,
150,11,473,4,0,
150,11,477,4,0,
150,11,490,4,0,
150,11,496,4,0,
150,11,510,4,0,
150,11,523,4,0,
150,11,540,1,100,
150,12,50,1,1,2
150,12,53,4,0,
150,12,54,1,55,
150,12,58,4,0,
150,12,59,4,0,
150,12,63,4,0,
150,12,70,4,0,
150,12,76,4,0,
150,12,85,4,0,
150,12,87,4,0,
150,12,89,4,0,
150,12,92,4,0,
150,12,93,1,1,1
150,12,94,1,66,
150,12,94,4,0,
150,12,104,4,0,
150,12,105,1,88,
150,12,112,1,11,
150,12,113,4,0,
150,12,115,4,0,
150,12,126,4,0,
150,12,129,1,22,
150,12,133,1,77,
150,12,148,4,0,
150,12,156,4,0,
150,12,182,4,0,
150,12,201,4,0,
150,12,216,4,0,
150,12,218,4,0,
150,12,219,1,99,
150,12,219,4,0,
150,12,231,4,0,
150,12,237,4,0,
150,12,240,4,0,
150,12,241,4,0,
150,12,244,1,33,
150,12,247,4,0,
150,12,248,1,44,
150,12,249,4,0,
150,12,258,4,0,
150,12,259,4,0,
150,12,263,4,0,
150,12,264,4,0,
150,12,269,4,0,
150,12,280,4,0,
150,12,285,4,0,
150,12,289,4,0,
150,12,290,4,0,
150,12,317,4,0,
150,12,332,4,0,
150,12,339,4,0,
150,12,347,4,0,
150,12,351,4,0,
150,12,352,4,0,
150,13,34,3,0,
150,13,38,3,0,
150,13,50,1,1,2
150,13,53,4,0,
150,13,54,1,55,
150,13,58,4,0,
150,13,59,4,0,
150,13,63,4,0,
150,13,69,3,0,
150,13,70,4,0,
150,13,76,4,0,
150,13,85,4,0,
150,13,86,3,0,
150,13,87,4,0,
150,13,89,4,0,
150,13,92,4,0,
150,13,93,1,1,1
150,13,94,1,66,
150,13,94,4,0,
150,13,102,3,0,
150,13,104,4,0,
150,13,105,1,88,
150,13,112,1,11,
150,13,113,4,0,
150,13,115,4,0,
150,13,120,3,0,
150,13,126,4,0,
150,13,129,1,22,
150,13,133,1,77,
150,13,138,3,0,
150,13,148,4,0,
150,13,156,4,0,
150,13,164,3,0,
150,13,171,3,0,
150,13,182,4,0,
150,13,196,3,0,
150,13,201,4,0,
150,13,207,3,0,
150,13,216,4,0,
150,13,218,4,0,
150,13,219,1,99,
150,13,219,4,0,
150,13,231,4,0,
150,13,237,4,0,
150,13,240,4,0,
150,13,241,4,0,
150,13,244,1,33,
150,13,247,4,0,
150,13,248,1,44,
150,13,249,4,0,
150,13,258,4,0,
150,13,259,4,0,
150,13,263,4,0,
150,13,264,4,0,
150,13,269,4,0,
150,13,280,4,0,
150,13,285,4,0,
150,13,289,4,0,
150,13,290,4,0,
150,13,317,4,0,
150,13,332,4,0,
150,13,339,4,0,
150,13,347,4,0,
150,13,351,4,0,
150,13,352,4,0,
150,14,7,3,0,
150,14,8,3,0,
150,14,9,3,0,
150,14,50,1,1,2
150,14,53,4,0,
150,14,54,1,36,
150,14,58,4,0,
150,14,59,4,0,
150,14,63,4,0,
150,14,67,3,0,
150,14,70,4,0,
150,14,76,4,0,
150,14,85,4,0,
150,14,86,4,0,
150,14,87,4,0,
150,14,89,4,0,
150,14,92,4,0,
150,14,93,1,1,1
150,14,94,1,64,
150,14,94,4,0,
150,14,104,4,0,
150,14,105,1,79,
150,14,112,1,1,3
150,14,113,4,0,
150,14,115,4,0,
150,14,126,4,0,
150,14,129,1,8,
150,14,133,1,50,
150,14,138,4,0,
150,14,148,4,0,
150,14,156,4,0,
150,14,157,4,0,
150,14,164,4,0,
150,14,173,3,0,
150,14,182,4,0,
150,14,196,3,0,
150,14,201,4,0,
150,14,207,4,0,
150,14,214,3,0,
150,14,216,4,0,
150,14,218,4,0,
150,14,219,1,86,
150,14,219,4,0,
150,14,231,3,0,
150,14,237,4,0,
150,14,240,4,0,
150,14,241,4,0,
150,14,244,1,22,
150,14,244,4,0,
150,14,247,4,0,
150,14,248,1,15,
150,14,249,4,0,
150,14,258,4,0,
150,14,259,4,0,
150,14,261,4,0,
150,14,263,4,0,
150,14,269,4,0,
150,14,271,3,0,
150,14,272,3,0,
150,14,277,3,0,
150,14,278,3,0,
150,14,280,4,0,
150,14,285,3,0,
150,14,289,3,0,
150,14,317,4,0,
150,14,324,3,0,
150,14,332,4,0,
150,14,339,4,0,
150,14,347,4,0,
150,14,356,3,0,
150,14,357,1,29,
150,14,373,4,0,
150,14,374,4,0,
150,14,382,1,71,
150,14,384,1,57,1
150,14,385,1,57,2
150,14,396,1,93,
150,14,398,4,0,
150,14,401,3,0,
150,14,409,3,0,
150,14,411,4,0,
150,14,412,4,0,
150,14,416,4,0,
150,14,427,1,43,
150,14,428,3,0,
150,14,433,4,0,
150,14,444,4,0,
150,14,447,4,0,
150,14,451,4,0,
150,14,472,3,0,
150,14,473,4,0,
150,14,477,4,0,
150,14,478,3,0,
150,14,490,4,0,
150,14,492,3,0,
150,14,496,4,0,
150,14,510,4,0,
150,14,523,4,0,
150,14,540,1,100,
150,15,50,1,1,2
150,15,53,4,0,
150,15,54,1,86,
150,15,58,4,0,
150,15,59,4,0,
150,15,63,4,0,
150,15,70,4,0,
150,15,76,4,0,
150,15,85,4,0,
150,15,86,4,0,
150,15,87,4,0,
150,15,89,4,0,
150,15,92,4,0,
150,15,93,1,1,1
150,15,94,1,57,
150,15,94,4,0,
150,15,104,4,0,
150,15,105,1,50,
150,15,112,1,64,
150,15,113,4,0,
150,15,115,4,0,
150,15,126,4,0,
150,15,129,1,8,
150,15,133,1,79,
150,15,138,4,0,
150,15,148,4,0,
150,15,156,4,0,
150,15,157,4,0,
150,15,164,4,0,
150,15,182,4,0,
150,15,201,4,0,
150,15,207,4,0,
150,15,214,4,0,
150,15,216,4,0,
150,15,218,4,0,
150,15,219,1,1,3
150,15,219,4,0,
150,15,237,4,0,
150,15,240,4,0,
150,15,241,4,0,
150,15,244,1,22,
150,15,244,4,0,
150,15,247,4,0,
150,15,248,1,15,
150,15,249,4,0,
150,15,258,4,0,
150,15,259,4,0,
150,15,261,4,0,
150,15,263,4,0,
150,15,269,4,0,
150,15,280,4,0,
150,15,317,4,0,
150,15,332,4,0,
150,15,339,4,0,
150,15,347,4,0,
150,15,357,1,29,
150,15,373,4,0,
150,15,374,4,0,
150,15,382,1,93,
150,15,384,1,43,1
150,15,385,1,43,2
150,15,396,1,70,
150,15,398,4,0,
150,15,411,4,0,
150,15,412,4,0,
150,15,416,4,0,
150,15,427,1,36,
150,15,433,4,0,
150,15,444,4,0,
150,15,447,4,0,
150,15,451,4,0,
150,15,473,4,0,
150,15,490,4,0,
150,15,496,4,0,
150,15,510,4,0,
150,15,523,4,0,
150,15,540,1,100,
150,15,590,4,0,
150,15,612,4,0,
150,16,7,3,0,
150,16,8,3,0,
150,16,9,3,0,
150,16,50,1,1,2
150,16,53,4,0,
150,16,54,1,86,1
150,16,58,4,0,
150,16,59,4,0,
150,16,63,4,0,
150,16,67,3,0,
150,16,70,4,0,
150,16,76,4,0,
150,16,85,4,0,
150,16,86,4,0,
150,16,87,4,0,
150,16,89,4,0,
150,16,92,4,0,
150,16,93,1,1,1
150,16,94,1,57,1
150,16,94,4,0,
150,16,104,4,0,
150,16,105,1,50,1
150,16,112,1,64,1
150,16,113,4,0,
150,16,115,4,0,
150,16,126,4,0,
150,16,129,1,8,1
150,16,133,1,79,1
150,16,138,4,0,
150,16,148,4,0,
150,16,156,4,0,
150,16,157,4,0,
150,16,164,4,0,
150,16,173,3,0,
150,16,182,4,0,
150,16,196,3,0,
150,16,201,4,0,
150,16,207,4,0,
150,16,214,4,0,
150,16,216,4,0,
150,16,218,4,0,
150,16,219,1,1,3
150,16,219,4,0,
150,16,231,3,0,
150,16,237,4,0,
150,16,240,4,0,
150,16,241,4,0,
150,16,244,1,22,1
150,16,244,4,0,
150,16,247,4,0,
150,16,248,1,15,1
150,16,249,4,0,
150,16,258,4,0,
150,16,259,4,0,
150,16,261,4,0,
150,16,263,4,0,
150,16,264,3,0,
150,16,269,4,0,
150,16,271,3,0,
150,16,272,3,0,
150,16,277,3,0,
150,16,278,3,0,
150,16,280,4,0,
150,16,285,3,0,
150,16,289,3,0,
150,16,290,4,0,
150,16,291,4,0,
150,16,317,4,0,
150,16,324,3,0,
150,16,332,4,0,
150,16,339,4,0,
150,16,347,4,0,
150,16,351,3,0,
150,16,352,3,0,
150,16,356,3,0,
150,16,357,1,29,1
150,16,373,4,0,
150,16,374,4,0,
150,16,382,1,93,1
150,16,384,1,43,1
150,16,385,1,43,2
150,16,396,1,70,1
150,16,398,4,0,
150,16,401,3,0,
150,16,409,3,0,
150,16,411,4,0,
150,16,412,4,0,
150,16,416,4,0,
150,16,427,1,36,1
150,16,428,3,0,
150,16,433,4,0,
150,16,444,4,0,
150,16,447,4,0,
150,16,451,4,0,
150,16,472,3,0,
150,16,473,4,0,
150,16,478,3,0,
150,16,490,4,0,
150,16,492,3,0,
150,16,496,4,0,
150,16,510,4,0,
150,16,523,4,0,
150,16,540,1,100,1
150,16,590,4,0,
150,16,612,4,0,
150,17,50,1,1,4
150,17,53,4,0,
150,17,54,1,86,
150,17,58,4,0,
150,17,59,4,0,
150,17,63,4,0,
150,17,76,4,0,
150,17,85,4,0,
150,17,86,4,0,
150,17,87,4,0,
150,17,89,4,0,
150,17,92,4,0,
150,17,93,1,1,3
150,17,94,1,57,
150,17,94,4,0,
150,17,104,4,0,
150,17,105,1,50,
150,17,112,1,64,
150,17,113,4,0,
150,17,115,4,0,
150,17,126,4,0,
150,17,129,1,8,
150,17,133,1,79,
150,17,138,4,0,
150,17,149,1,1,2
150,17,156,4,0,
150,17,157,4,0,
150,17,164,4,0,
150,17,182,4,0,
150,17,201,4,0,
150,17,207,4,0,
150,17,214,4,0,
150,17,216,4,0,
150,17,218,4,0,
150,17,219,1,1,5
150,17,219,4,0,
150,17,237,4,0,
150,17,240,4,0,
150,17,241,4,0,
150,17,244,1,22,
150,17,244,4,0,
150,17,247,4,0,
150,17,248,1,15,
150,17,258,4,0,
150,17,259,4,0,
150,17,261,4,0,
150,17,263,4,0,
150,17,269,4,0,
150,17,280,4,0,
150,17,317,4,0,
150,17,332,4,0,
150,17,339,4,0,
150,17,347,4,0,
150,17,357,1,29,
150,17,373,4,0,
150,17,374,4,0,
150,17,382,1,93,
150,17,384,1,43,1
150,17,385,1,43,2
150,17,396,1,70,
150,17,398,4,0,
150,17,411,4,0,
150,17,412,4,0,
150,17,416,4,0,
150,17,427,1,36,
150,17,433,4,0,
150,17,444,4,0,
150,17,447,4,0,
150,17,451,4,0,
150,17,473,4,0,
150,17,490,4,0,
150,17,496,4,0,
150,17,523,4,0,
150,17,540,1,100,
150,17,590,4,0,
150,17,673,1,1,1
150,17,693,4,0,
150,18,50,1,1,4
150,18,53,4,0,
150,18,54,1,86,
150,18,58,4,0,
150,18,59,4,0,
150,18,63,4,0,
150,18,76,4,0,
150,18,85,4,0,
150,18,86,4,0,
150,18,87,4,0,
150,18,89,4,0,
150,18,92,4,0,
150,18,93,1,1,3
150,18,94,1,57,
150,18,94,4,0,
150,18,104,4,0,
150,18,105,1,50,
150,18,112,1,64,
150,18,113,4,0,
150,18,115,4,0,
150,18,126,4,0,
150,18,129,1,8,
150,18,133,1,79,
150,18,138,4,0,
150,18,149,1,1,2
150,18,156,4,0,
150,18,157,4,0,
150,18,164,4,0,
150,18,182,4,0,
150,18,201,4,0,
150,18,207,4,0,
150,18,214,4,0,
150,18,216,4,0,
150,18,218,4,0,
150,18,219,1,1,5
150,18,219,4,0,
150,18,237,4,0,
150,18,240,4,0,
150,18,241,4,0,
150,18,244,1,22,
150,18,244,4,0,
150,18,247,4,0,
150,18,248,1,15,
150,18,258,4,0,
150,18,259,4,0,
150,18,261,4,0,
150,18,263,4,0,
150,18,269,4,0,
150,18,280,4,0,
150,18,317,4,0,
150,18,332,4,0,
150,18,339,4,0,
150,18,347,4,0,
150,18,357,1,29,
150,18,373,4,0,
150,18,374,4,0,
150,18,382,1,93,
150,18,384,1,43,1
150,18,385,1,43,2
150,18,396,1,70,
150,18,398,4,0,
150,18,411,4,0,
150,18,412,4,0,
150,18,416,4,0,
150,18,427,1,36,
150,18,433,4,0,
150,18,444,4,0,
150,18,447,4,0,
150,18,451,4,0,
150,18,473,4,0,
150,18,490,4,0,
150,18,496,4,0,
150,18,523,4,0,
150,18,540,1,100,
150,18,590,4,0,
150,18,673,1,1,1
150,18,693,4,0,
151,1,1,1,1,
151,1,5,1,20,
151,1,5,4,0,
151,1,6,4,0,
151,1,13,4,0,
151,1,14,4,0,
151,1,15,4,0,
151,1,18,4,0,
151,1,19,4,0,
151,1,25,4,0,
151,1,32,4,0,
151,1,34,4,0,
151,1,36,4,0,
151,1,38,4,0,
151,1,55,4,0,
151,1,57,4,0,
151,1,58,4,0,
151,1,59,4,0,
151,1,61,4,0,
151,1,63,4,0,
151,1,66,4,0,
151,1,68,4,0,
151,1,69,4,0,
151,1,70,4,0,
151,1,72,4,0,
151,1,76,4,0,
151,1,82,4,0,
151,1,85,4,0,
151,1,86,4,0,
151,1,87,4,0,
151,1,89,4,0,
151,1,90,4,0,
151,1,91,4,0,
151,1,92,4,0,
151,1,94,1,40,
151,1,94,4,0,
151,1,99,4,0,
151,1,100,4,0,
151,1,102,4,0,
151,1,104,4,0,
151,1,115,4,0,
151,1,117,4,0,
151,1,118,1,30,
151,1,118,4,0,
151,1,120,4,0,
151,1,121,4,0,
151,1,126,4,0,
151,1,129,4,0,
151,1,130,4,0,
151,1,135,4,0,
151,1,138,4,0,
151,1,143,4,0,
151,1,144,1,10,
151,1,148,4,0,
151,1,149,4,0,
151,1,153,4,0,
151,1,156,4,0,
151,1,157,4,0,
151,1,161,4,0,
151,1,164,4,0,
151,2,1,1,1,
151,2,5,1,20,
151,2,5,4,0,
151,2,6,4,0,
151,2,13,4,0,
151,2,14,4,0,
151,2,15,4,0,
151,2,18,4,0,
151,2,19,4,0,
151,2,25,4,0,
151,2,32,4,0,
151,2,34,4,0,
151,2,36,4,0,
151,2,38,4,0,
151,2,55,4,0,
151,2,57,4,0,
151,2,58,4,0,
151,2,59,4,0,
151,2,61,4,0,
151,2,63,4,0,
151,2,66,4,0,
151,2,68,4,0,
151,2,69,4,0,
151,2,70,4,0,
151,2,72,4,0,
151,2,76,4,0,
151,2,82,4,0,
151,2,85,4,0,
151,2,86,4,0,
151,2,87,4,0,
151,2,89,4,0,
151,2,90,4,0,
151,2,91,4,0,
151,2,92,4,0,
151,2,94,1,40,
151,2,94,4,0,
151,2,99,4,0,
151,2,100,4,0,
151,2,102,4,0,
151,2,104,4,0,
151,2,115,4,0,
151,2,117,4,0,
151,2,118,1,30,
151,2,118,4,0,
151,2,120,4,0,
151,2,121,4,0,
151,2,126,4,0,
151,2,129,4,0,
151,2,130,4,0,
151,2,135,4,0,
151,2,138,4,0,
151,2,143,4,0,
151,2,144,1,10,
151,2,148,4,0,
151,2,149,4,0,
151,2,153,4,0,
151,2,156,4,0,
151,2,157,4,0,
151,2,161,4,0,
151,2,164,4,0,
151,3,1,1,1,
151,3,5,1,20,
151,3,7,4,0,
151,3,8,4,0,
151,3,9,4,0,
151,3,15,4,0,
151,3,19,4,0,
151,3,29,4,0,
151,3,46,4,0,
151,3,57,4,0,
151,3,59,4,0,
151,3,63,4,0,
151,3,70,4,0,
151,3,76,4,0,
151,3,87,4,0,
151,3,89,4,0,
151,3,91,4,0,
151,3,92,4,0,
151,3,94,1,40,
151,3,94,4,0,
151,3,104,4,0,
151,3,111,4,0,
151,3,118,1,30,
151,3,126,4,0,
151,3,127,4,0,
151,3,129,4,0,
151,3,138,4,0,
151,3,144,1,10,
151,3,148,4,0,
151,3,156,4,0,
151,3,168,4,0,
151,3,171,4,0,
151,3,173,4,0,
151,3,174,4,0,
151,3,182,4,0,
151,3,188,4,0,
151,3,189,4,0,
151,3,192,4,0,
151,3,196,4,0,
151,3,197,4,0,
151,3,201,4,0,
151,3,202,4,0,
151,3,203,4,0,
151,3,205,4,0,
151,3,207,4,0,
151,3,210,4,0,
151,3,211,4,0,
151,3,213,4,0,
151,3,214,4,0,
151,3,216,4,0,
151,3,218,4,0,
151,3,223,4,0,
151,3,225,4,0,
151,3,230,4,0,
151,3,231,4,0,
151,3,237,4,0,
151,3,240,4,0,
151,3,241,4,0,
151,3,244,4,0,
151,3,246,1,50,
151,3,247,4,0,
151,3,249,4,0,
151,3,250,4,0,
151,4,1,1,1,
151,4,5,1,20,
151,4,7,4,0,
151,4,8,4,0,
151,4,9,4,0,
151,4,15,4,0,
151,4,19,4,0,
151,4,29,4,0,
151,4,46,4,0,
151,4,53,3,0,
151,4,57,4,0,
151,4,58,3,0,
151,4,59,4,0,
151,4,63,4,0,
151,4,70,4,0,
151,4,76,4,0,
151,4,85,3,0,
151,4,87,4,0,
151,4,89,4,0,
151,4,91,4,0,
151,4,92,4,0,
151,4,94,1,40,
151,4,94,4,0,
151,4,104,4,0,
151,4,111,4,0,
151,4,118,1,30,
151,4,126,4,0,
151,4,127,4,0,
151,4,129,4,0,
151,4,138,4,0,
151,4,144,1,10,
151,4,148,4,0,
151,4,156,4,0,
151,4,168,4,0,
151,4,171,4,0,
151,4,173,4,0,
151,4,174,4,0,
151,4,182,4,0,
151,4,188,4,0,
151,4,189,4,0,
151,4,192,4,0,
151,4,196,4,0,
151,4,197,4,0,
151,4,201,4,0,
151,4,202,4,0,
151,4,203,4,0,
151,4,205,4,0,
151,4,207,4,0,
151,4,210,4,0,
151,4,211,4,0,
151,4,213,4,0,
151,4,214,4,0,
151,4,216,4,0,
151,4,218,4,0,
151,4,223,4,0,
151,4,225,4,0,
151,4,230,4,0,
151,4,231,4,0,
151,4,237,4,0,
151,4,240,4,0,
151,4,241,4,0,
151,4,244,4,0,
151,4,246,1,50,
151,4,247,4,0,
151,4,249,4,0,
151,4,250,4,0,
151,5,1,1,1,
151,5,5,1,20,
151,5,15,4,0,
151,5,19,4,0,
151,5,46,4,0,
151,5,53,4,0,
151,5,57,4,0,
151,5,58,4,0,
151,5,59,4,0,
151,5,63,4,0,
151,5,70,4,0,
151,5,76,4,0,
151,5,85,4,0,
151,5,87,4,0,
151,5,89,4,0,
151,5,91,4,0,
151,5,92,4,0,
151,5,94,1,40,
151,5,94,4,0,
151,5,104,4,0,
151,5,113,4,0,
151,5,115,4,0,
151,5,118,1,30,
151,5,126,4,0,
151,5,127,4,0,
151,5,144,1,10,
151,5,148,4,0,
151,5,156,4,0,
151,5,168,4,0,
151,5,182,4,0,
151,5,188,4,0,
151,5,201,4,0,
151,5,202,4,0,
151,5,211,4,0,
151,5,213,4,0,
151,5,216,4,0,
151,5,218,4,0,
151,5,219,4,0,
151,5,231,4,0,
151,5,237,4,0,
151,5,240,4,0,
151,5,241,4,0,
151,5,246,1,50,
151,5,247,4,0,
151,5,249,4,0,
151,5,258,4,0,
151,5,259,4,0,
151,5,263,4,0,
151,5,264,4,0,
151,5,269,4,0,
151,5,280,4,0,
151,5,285,4,0,
151,5,289,4,0,
151,5,290,4,0,
151,5,291,4,0,
151,5,315,4,0,
151,5,317,4,0,
151,5,331,4,0,
151,5,332,4,0,
151,5,337,4,0,
151,5,339,4,0,
151,5,347,4,0,
151,5,351,4,0,
151,5,352,4,0,
151,6,1,1,1,
151,6,5,1,20,
151,6,5,3,0,
151,6,7,3,0,
151,6,8,3,0,
151,6,9,3,0,
151,6,14,3,0,
151,6,15,4,0,
151,6,19,4,0,
151,6,25,3,0,
151,6,34,3,0,
151,6,38,3,0,
151,6,46,4,0,
151,6,53,4,0,
151,6,57,4,0,
151,6,58,4,0,
151,6,59,4,0,
151,6,63,4,0,
151,6,68,3,0,
151,6,69,3,0,
151,6,70,4,0,
151,6,76,4,0,
151,6,85,4,0,
151,6,86,3,0,
151,6,87,4,0,
151,6,89,4,0,
151,6,91,4,0,
151,6,92,4,0,
151,6,94,1,40,
151,6,94,4,0,
151,6,102,3,0,
151,6,104,4,0,
151,6,111,3,0,
151,6,113,4,0,
151,6,115,4,0,
151,6,118,1,30,
151,6,118,3,0,
151,6,126,4,0,
151,6,127,4,0,
151,6,129,3,0,
151,6,135,3,0,
151,6,138,3,0,
151,6,144,1,10,
151,6,148,4,0,
151,6,153,3,0,
151,6,156,4,0,
151,6,157,3,0,
151,6,164,3,0,
151,6,168,4,0,
151,6,173,3,0,
151,6,182,4,0,
151,6,188,4,0,
151,6,189,3,0,
151,6,196,3,0,
151,6,201,4,0,
151,6,202,4,0,
151,6,203,3,0,
151,6,205,3,0,
151,6,207,3,0,
151,6,210,3,0,
151,6,211,4,0,
151,6,213,4,0,
151,6,214,3,0,
151,6,216,4,0,
151,6,218,4,0,
151,6,219,4,0,
151,6,223,3,0,
151,6,231,4,0,
151,6,237,4,0,
151,6,240,4,0,
151,6,241,4,0,
151,6,244,3,0,
151,6,246,1,50,
151,6,247,4,0,
151,6,249,4,0,
151,6,258,4,0,
151,6,259,4,0,
151,6,263,4,0,
151,6,264,4,0,
151,6,269,4,0,
151,6,280,4,0,
151,6,285,4,0,
151,6,289,4,0,
151,6,290,4,0,
151,6,291,4,0,
151,6,315,4,0,
151,6,317,4,0,
151,6,331,4,0,
151,6,332,4,0,
151,6,337,4,0,
151,6,339,4,0,
151,6,347,4,0,
151,6,351,4,0,
151,6,352,4,0,
151,7,1,1,1,
151,7,5,1,20,
151,7,5,3,0,
151,7,14,3,0,
151,7,15,4,0,
151,7,19,4,0,
151,7,25,3,0,
151,7,34,3,0,
151,7,38,3,0,
151,7,46,4,0,
151,7,53,4,0,
151,7,57,4,0,
151,7,58,4,0,
151,7,59,4,0,
151,7,63,4,0,
151,7,68,3,0,
151,7,69,3,0,
151,7,70,4,0,
151,7,76,4,0,
151,7,85,4,0,
151,7,86,3,0,
151,7,87,4,0,
151,7,89,4,0,
151,7,91,4,0,
151,7,92,4,0,
151,7,94,1,40,
151,7,94,4,0,
151,7,102,3,0,
151,7,104,4,0,
151,7,113,4,0,
151,7,115,4,0,
151,7,118,1,30,
151,7,118,3,0,
151,7,126,4,0,
151,7,127,4,0,
151,7,135,3,0,
151,7,138,3,0,
151,7,144,1,10,
151,7,148,4,0,
151,7,153,3,0,
151,7,156,4,0,
151,7,157,3,0,
151,7,164,3,0,
151,7,168,4,0,
151,7,182,4,0,
151,7,188,4,0,
151,7,201,4,0,
151,7,202,4,0,
151,7,211,4,0,
151,7,213,4,0,
151,7,216,4,0,
151,7,218,4,0,
151,7,219,4,0,
151,7,231,4,0,
151,7,237,4,0,
151,7,240,4,0,
151,7,241,4,0,
151,7,246,1,50,
151,7,247,4,0,
151,7,249,4,0,
151,7,258,4,0,
151,7,259,4,0,
151,7,263,4,0,
151,7,264,4,0,
151,7,269,4,0,
151,7,280,4,0,
151,7,285,4,0,
151,7,289,4,0,
151,7,290,4,0,
151,7,291,4,0,
151,7,315,4,0,
151,7,317,4,0,
151,7,331,4,0,
151,7,332,4,0,
151,7,337,4,0,
151,7,339,4,0,
151,7,347,4,0,
151,7,351,4,0,
151,7,352,4,0,
151,8,1,1,1,1
151,8,5,1,10,
151,8,14,4,0,
151,8,15,4,0,
151,8,19,4,0,
151,8,46,4,0,
151,8,53,4,0,
151,8,57,4,0,
151,8,58,4,0,
151,8,59,4,0,
151,8,63,4,0,
151,8,70,4,0,
151,8,76,4,0,
151,8,85,4,0,
151,8,86,4,0,
151,8,87,4,0,
151,8,89,4,0,
151,8,91,4,0,
151,8,92,4,0,
151,8,94,1,30,
151,8,94,4,0,
151,8,104,4,0,
151,8,112,1,40,
151,8,113,4,0,
151,8,115,4,0,
151,8,118,1,20,
151,8,126,4,0,
151,8,127,4,0,
151,8,133,1,60,
151,8,138,4,0,
151,8,144,1,1,2
151,8,148,4,0,
151,8,153,4,0,
151,8,156,4,0,
151,8,157,4,0,
151,8,164,4,0,
151,8,168,4,0,
151,8,182,4,0,
151,8,188,4,0,
151,8,201,4,0,
151,8,202,4,0,
151,8,203,4,0,
151,8,206,4,0,
151,8,207,4,0,
151,8,211,4,0,
151,8,213,4,0,
151,8,214,4,0,
151,8,216,4,0,
151,8,218,4,0,
151,8,219,4,0,
151,8,226,1,80,
151,8,231,4,0,
151,8,237,4,0,
151,8,240,4,0,
151,8,241,4,0,
151,8,244,4,0,
151,8,246,1,50,
151,8,247,4,0,
151,8,249,4,0,
151,8,258,4,0,
151,8,259,4,0,
151,8,261,4,0,
151,8,263,4,0,
151,8,264,4,0,
151,8,269,4,0,
151,8,278,4,0,
151,8,280,4,0,
151,8,285,4,0,
151,8,289,4,0,
151,8,290,4,0,
151,8,315,4,0,
151,8,317,4,0,
151,8,318,4,0,
151,8,331,4,0,
151,8,332,4,0,
151,8,337,4,0,
151,8,339,4,0,
151,8,347,4,0,
151,8,351,4,0,
151,8,352,4,0,
151,8,355,4,0,
151,8,360,4,0,
151,8,362,4,0,
151,8,363,4,0,
151,8,365,4,0,
151,8,369,4,0,
151,8,371,4,0,
151,8,373,4,0,
151,8,374,4,0,
151,8,382,1,70,
151,8,396,1,100,
151,8,397,4,0,
151,8,398,4,0,
151,8,399,4,0,
151,8,404,4,0,
151,8,406,4,0,
151,8,409,4,0,
151,8,411,4,0,
151,8,412,4,0,
151,8,416,4,0,
151,8,417,1,90,
151,8,419,4,0,
151,8,421,4,0,
151,8,430,4,0,
151,8,431,4,0,
151,8,432,4,0,
151,8,433,4,0,
151,8,444,4,0,
151,8,445,4,0,
151,8,446,4,0,
151,8,447,4,0,
151,8,451,4,0,
151,9,1,1,1,1
151,9,5,1,10,
151,9,7,3,0,
151,9,8,3,0,
151,9,9,3,0,
151,9,14,4,0,
151,9,15,4,0,
151,9,19,4,0,
151,9,46,4,0,
151,9,53,4,0,
151,9,57,4,0,
151,9,58,4,0,
151,9,59,4,0,
151,9,63,4,0,
151,9,70,4,0,
151,9,76,4,0,
151,9,85,4,0,
151,9,86,4,0,
151,9,87,4,0,
151,9,89,4,0,
151,9,91,4,0,
151,9,92,4,0,
151,9,94,1,30,
151,9,94,4,0,
151,9,104,4,0,
151,9,112,1,40,
151,9,113,4,0,
151,9,115,4,0,
151,9,118,1,20,
151,9,126,4,0,
151,9,127,4,0,
151,9,129,3,0,
151,9,133,1,60,
151,9,138,4,0,
151,9,144,1,1,2
151,9,148,4,0,
151,9,153,4,0,
151,9,156,4,0,
151,9,157,4,0,
151,9,164,4,0,
151,9,168,4,0,
151,9,173,3,0,
151,9,180,3,0,
151,9,182,4,0,
151,9,188,4,0,
151,9,189,3,0,
151,9,196,3,0,
151,9,200,3,0,
151,9,201,4,0,
151,9,202,4,0,
151,9,203,4,0,
151,9,205,3,0,
151,9,206,4,0,
151,9,207,4,0,
151,9,210,3,0,
151,9,211,4,0,
151,9,213,4,0,
151,9,214,4,0,
151,9,216,4,0,
151,9,218,4,0,
151,9,219,4,0,
151,9,226,1,80,
151,9,231,4,0,
151,9,235,3,0,
151,9,237,4,0,
151,9,239,3,0,
151,9,240,4,0,
151,9,241,4,0,
151,9,244,4,0,
151,9,246,1,50,
151,9,246,3,0,
151,9,247,4,0,
151,9,249,4,0,
151,9,253,3,0,
151,9,257,3,0,
151,9,258,4,0,
151,9,259,4,0,
151,9,261,4,0,
151,9,263,4,0,
151,9,264,4,0,
151,9,269,4,0,
151,9,270,3,0,
151,9,271,3,0,
151,9,276,3,0,
151,9,278,4,0,
151,9,280,4,0,
151,9,282,3,0,
151,9,283,3,0,
151,9,285,4,0,
151,9,289,4,0,
151,9,290,4,0,
151,9,291,3,0,
151,9,314,3,0,
151,9,315,4,0,
151,9,317,4,0,
151,9,318,4,0,
151,9,324,3,0,
151,9,331,4,0,
151,9,332,4,0,
151,9,334,3,0,
151,9,337,4,0,
151,9,339,4,0,
151,9,340,3,0,
151,9,347,4,0,
151,9,351,4,0,
151,9,352,4,0,
151,9,355,4,0,
151,9,360,4,0,
151,9,362,4,0,
151,9,363,4,0,
151,9,365,4,0,
151,9,369,4,0,
151,9,371,4,0,
151,9,373,4,0,
151,9,374,4,0,
151,9,380,3,0,
151,9,382,1,70,
151,9,387,3,0,
151,9,389,3,0,
151,9,393,3,0,
151,9,396,1,100,
151,9,397,4,0,
151,9,398,4,0,
151,9,399,4,0,
151,9,401,3,0,
151,9,402,3,0,
151,9,404,4,0,
151,9,406,4,0,
151,9,409,4,0,
151,9,410,3,0,
151,9,411,4,0,
151,9,412,4,0,
151,9,414,3,0,
151,9,416,4,0,
151,9,417,1,90,
151,9,419,4,0,
151,9,421,4,0,
151,9,428,3,0,
151,9,430,4,0,
151,9,431,4,0,
151,9,432,4,0,
151,9,433,4,0,
151,9,441,3,0,
151,9,442,3,0,
151,9,444,4,0,
151,9,445,4,0,
151,9,446,4,0,
151,9,447,4,0,
151,9,451,4,0,
151,9,466,3,0,
151,10,1,1,1,1
151,10,5,1,10,
151,10,7,3,0,
151,10,8,3,0,
151,10,9,3,0,
151,10,14,4,0,
151,10,15,4,0,
151,10,19,4,0,
151,10,29,3,0,
151,10,46,4,0,
151,10,53,4,0,
151,10,57,4,0,
151,10,58,4,0,
151,10,59,4,0,
151,10,63,4,0,
151,10,67,3,0,
151,10,70,4,0,
151,10,76,4,0,
151,10,81,3,0,
151,10,85,4,0,
151,10,86,4,0,
151,10,87,4,0,
151,10,89,4,0,
151,10,91,4,0,
151,10,92,4,0,
151,10,94,1,30,
151,10,94,4,0,
151,10,104,4,0,
151,10,112,1,40,
151,10,113,4,0,
151,10,115,4,0,
151,10,118,1,20,
151,10,126,4,0,
151,10,127,4,0,
151,10,129,3,0,
151,10,133,1,60,
151,10,138,4,0,
151,10,143,3,0,
151,10,144,1,1,2
151,10,148,4,0,
151,10,153,4,0,
151,10,156,4,0,
151,10,157,4,0,
151,10,162,3,0,
151,10,164,4,0,
151,10,168,4,0,
151,10,173,3,0,
151,10,180,3,0,
151,10,182,4,0,
151,10,188,4,0,
151,10,189,3,0,
151,10,196,3,0,
151,10,200,3,0,
151,10,201,4,0,
151,10,202,4,0,
151,10,203,4,0,
151,10,205,3,0,
151,10,206,4,0,
151,10,207,4,0,
151,10,210,3,0,
151,10,211,4,0,
151,10,213,4,0,
151,10,214,4,0,
151,10,215,3,0,
151,10,216,4,0,
151,10,218,4,0,
151,10,219,4,0,
151,10,220,3,0,
151,10,226,1,80,
151,10,231,4,0,
151,10,235,3,0,
151,10,237,4,0,
151,10,239,3,0,
151,10,240,4,0,
151,10,241,4,0,
151,10,244,4,0,
151,10,246,1,50,
151,10,246,3,0,
151,10,247,4,0,
151,10,249,4,0,
151,10,250,4,0,
151,10,253,3,0,
151,10,257,3,0,
151,10,258,4,0,
151,10,259,4,0,
151,10,261,4,0,
151,10,263,4,0,
151,10,264,4,0,
151,10,269,4,0,
151,10,270,3,0,
151,10,271,3,0,
151,10,272,3,0,
151,10,276,3,0,
151,10,277,3,0,
151,10,278,4,0,
151,10,280,4,0,
151,10,282,3,0,
151,10,283,3,0,
151,10,285,4,0,
151,10,289,4,0,
151,10,290,4,0,
151,10,291,3,0,
151,10,314,3,0,
151,10,315,4,0,
151,10,317,4,0,
151,10,318,4,0,
151,10,324,3,0,
151,10,331,4,0,
151,10,332,4,0,
151,10,334,3,0,
151,10,335,3,0,
151,10,337,4,0,
151,10,339,4,0,
151,10,340,3,0,
151,10,347,4,0,
151,10,351,4,0,
151,10,352,4,0,
151,10,355,4,0,
151,10,356,3,0,
151,10,360,4,0,
151,10,362,4,0,
151,10,363,4,0,
151,10,365,4,0,
151,10,366,3,0,
151,10,369,4,0,
151,10,371,4,0,
151,10,373,4,0,
151,10,374,4,0,
151,10,380,3,0,
151,10,382,1,70,
151,10,387,3,0,
151,10,388,3,0,
151,10,389,3,0,
151,10,393,3,0,
151,10,396,1,100,
151,10,397,4,0,
151,10,398,4,0,
151,10,399,4,0,
151,10,401,3,0,
151,10,402,3,0,
151,10,404,4,0,
151,10,406,4,0,
151,10,409,4,0,
151,10,410,3,0,
151,10,411,4,0,
151,10,412,4,0,
151,10,414,3,0,
151,10,416,4,0,
151,10,417,1,90,
151,10,419,4,0,
151,10,421,4,0,
151,10,428,3,0,
151,10,430,4,0,
151,10,431,4,0,
151,10,433,4,0,
151,10,441,3,0,
151,10,442,3,0,
151,10,444,4,0,
151,10,445,4,0,
151,10,446,4,0,
151,10,447,4,0,
151,10,450,3,0,
151,10,451,4,0,
151,10,466,3,0,
151,11,1,1,1,1
151,11,5,1,10,
151,11,14,4,0,
151,11,15,4,0,
151,11,19,4,0,
151,11,46,4,0,
151,11,53,4,0,
151,11,57,4,0,
151,11,58,4,0,
151,11,59,4,0,
151,11,63,4,0,
151,11,70,4,0,
151,11,76,4,0,
151,11,85,4,0,
151,11,86,4,0,
151,11,87,4,0,
151,11,89,4,0,
151,11,91,4,0,
151,11,92,4,0,
151,11,94,1,30,
151,11,94,4,0,
151,11,104,4,0,
151,11,112,1,40,
151,11,113,4,0,
151,11,115,4,0,
151,11,118,1,20,
151,11,126,4,0,
151,11,127,4,0,
151,11,133,1,60,
151,11,138,4,0,
151,11,144,1,1,3
151,11,148,4,0,
151,11,153,4,0,
151,11,156,4,0,
151,11,157,4,0,
151,11,164,4,0,
151,11,168,4,0,
151,11,182,4,0,
151,11,188,4,0,
151,11,201,4,0,
151,11,206,4,0,
151,11,207,4,0,
151,11,213,4,0,
151,11,216,4,0,
151,11,218,4,0,
151,11,219,4,0,
151,11,226,1,80,
151,11,237,4,0,
151,11,240,4,0,
151,11,241,4,0,
151,11,244,4,0,
151,11,246,1,50,
151,11,247,4,0,
151,11,249,4,0,
151,11,258,4,0,
151,11,259,4,0,
151,11,261,4,0,
151,11,263,4,0,
151,11,269,4,0,
151,11,280,4,0,
151,11,291,4,0,
151,11,315,4,0,
151,11,317,4,0,
151,11,332,4,0,
151,11,337,4,0,
151,11,339,4,0,
151,11,347,4,0,
151,11,360,4,0,
151,11,365,4,0,
151,11,369,4,0,
151,11,371,4,0,
151,11,373,4,0,
151,11,374,4,0,
151,11,382,1,70,
151,11,396,1,100,
151,11,397,4,0,
151,11,398,4,0,
151,11,404,4,0,
151,11,411,4,0,
151,11,412,4,0,
151,11,416,4,0,
151,11,417,1,90,
151,11,421,4,0,
151,11,430,4,0,
151,11,433,4,0,
151,11,444,4,0,
151,11,447,4,0,
151,11,451,4,0,
151,11,468,4,0,
151,11,473,4,0,
151,11,474,4,0,
151,11,477,4,0,
151,11,479,4,0,
151,11,482,4,0,
151,11,488,4,0,
151,11,490,4,0,
151,11,496,4,0,
151,11,497,4,0,
151,11,502,4,0,
151,11,503,4,0,
151,11,507,4,0,
151,11,510,4,0,
151,11,511,4,0,
151,11,512,4,0,
151,11,513,1,1,2
151,11,514,4,0,
151,11,521,4,0,
151,11,522,4,0,
151,11,523,4,0,
151,11,524,4,0,
151,11,525,4,0,
151,11,526,4,0,
151,11,528,4,0,
151,11,555,4,0,
151,12,1,1,1,
151,12,5,1,20,
151,12,15,4,0,
151,12,19,4,0,
151,12,46,4,0,
151,12,53,4,0,
151,12,57,4,0,
151,12,58,4,0,
151,12,59,4,0,
151,12,63,4,0,
151,12,70,4,0,
151,12,76,4,0,
151,12,85,4,0,
151,12,87,4,0,
151,12,89,4,0,
151,12,91,4,0,
151,12,92,4,0,
151,12,94,1,40,
151,12,94,4,0,
151,12,104,4,0,
151,12,113,4,0,
151,12,115,4,0,
151,12,118,1,30,
151,12,126,4,0,
151,12,127,4,0,
151,12,144,1,10,
151,12,148,4,0,
151,12,156,4,0,
151,12,168,4,0,
151,12,182,4,0,
151,12,188,4,0,
151,12,201,4,0,
151,12,202,4,0,
151,12,211,4,0,
151,12,213,4,0,
151,12,216,4,0,
151,12,218,4,0,
151,12,219,4,0,
151,12,231,4,0,
151,12,237,4,0,
151,12,240,4,0,
151,12,241,4,0,
151,12,246,1,50,
151,12,247,4,0,
151,12,249,4,0,
151,12,258,4,0,
151,12,259,4,0,
151,12,263,4,0,
151,12,264,4,0,
151,12,269,4,0,
151,12,280,4,0,
151,12,285,4,0,
151,12,289,4,0,
151,12,290,4,0,
151,12,291,4,0,
151,12,315,4,0,
151,12,317,4,0,
151,12,331,4,0,
151,12,332,4,0,
151,12,337,4,0,
151,12,339,4,0,
151,12,347,4,0,
151,12,351,4,0,
151,12,352,4,0,
151,13,1,1,1,
151,13,5,1,20,
151,13,15,4,0,
151,13,19,4,0,
151,13,34,3,0,
151,13,38,3,0,
151,13,46,4,0,
151,13,53,4,0,
151,13,57,4,0,
151,13,58,4,0,
151,13,59,4,0,
151,13,63,4,0,
151,13,69,3,0,
151,13,70,4,0,
151,13,76,4,0,
151,13,85,4,0,
151,13,86,3,0,
151,13,87,4,0,
151,13,89,4,0,
151,13,91,4,0,
151,13,92,4,0,
151,13,94,1,40,
151,13,94,4,0,
151,13,95,3,0,
151,13,101,3,0,
151,13,102,3,0,
151,13,104,4,0,
151,13,113,4,0,
151,13,115,4,0,
151,13,118,1,30,
151,13,120,3,0,
151,13,126,4,0,
151,13,127,4,0,
151,13,138,3,0,
151,13,143,3,0,
151,13,144,1,10,
151,13,148,4,0,
151,13,156,4,0,
151,13,164,3,0,
151,13,168,4,0,
151,13,171,3,0,
151,13,182,4,0,
151,13,185,3,0,
151,13,188,4,0,
151,13,192,3,0,
151,13,196,3,0,
151,13,201,4,0,
151,13,202,4,0,
151,13,207,3,0,
151,13,211,4,0,
151,13,213,4,0,
151,13,216,4,0,
151,13,218,4,0,
151,13,219,4,0,
151,13,231,4,0,
151,13,237,4,0,
151,13,240,4,0,
151,13,241,4,0,
151,13,246,1,50,
151,13,247,4,0,
151,13,249,4,0,
151,13,252,3,0,
151,13,258,4,0,
151,13,259,4,0,
151,13,263,4,0,
151,13,264,4,0,
151,13,269,4,0,
151,13,272,3,0,
151,13,280,4,0,
151,13,285,4,0,
151,13,289,4,0,
151,13,290,4,0,
151,13,291,4,0,
151,13,315,4,0,
151,13,317,4,0,
151,13,331,4,0,
151,13,332,4,0,
151,13,337,4,0,
151,13,339,4,0,
151,13,347,4,0,
151,13,351,4,0,
151,13,352,4,0,
151,14,1,1,1,1
151,14,5,1,10,
151,14,7,3,0,
151,14,8,3,0,
151,14,9,3,0,
151,14,14,4,0,
151,14,15,4,0,
151,14,19,4,0,
151,14,20,3,0,
151,14,46,4,0,
151,14,53,4,0,
151,14,57,4,0,
151,14,58,4,0,
151,14,59,4,0,
151,14,63,4,0,
151,14,67,3,0,
151,14,70,4,0,
151,14,76,4,0,
151,14,85,4,0,
151,14,86,4,0,
151,14,87,4,0,
151,14,89,4,0,
151,14,91,4,0,
151,14,92,4,0,
151,14,94,1,30,
151,14,94,4,0,
151,14,104,4,0,
151,14,112,1,40,
151,14,113,4,0,
151,14,115,4,0,
151,14,118,1,20,
151,14,126,4,0,
151,14,127,4,0,
151,14,133,1,60,
151,14,138,4,0,
151,14,143,3,0,
151,14,144,1,1,3
151,14,148,4,0,
151,14,153,4,0,
151,14,156,4,0,
151,14,157,4,0,
151,14,162,3,0,
151,14,164,4,0,
151,14,168,4,0,
151,14,173,3,0,
151,14,180,3,0,
151,14,182,4,0,
151,14,188,4,0,
151,14,196,3,0,
151,14,200,3,0,
151,14,201,4,0,
151,14,202,3,0,
151,14,206,4,0,
151,14,207,4,0,
151,14,213,4,0,
151,14,214,3,0,
151,14,215,3,0,
151,14,216,4,0,
151,14,218,4,0,
151,14,219,4,0,
151,14,220,3,0,
151,14,226,1,80,
151,14,231,3,0,
151,14,235,3,0,
151,14,237,4,0,
151,14,240,4,0,
151,14,241,4,0,
151,14,244,4,0,
151,14,246,1,50,
151,14,247,4,0,
151,14,249,4,0,
151,14,253,3,0,
151,14,257,3,0,
151,14,258,4,0,
151,14,259,4,0,
151,14,261,4,0,
151,14,263,4,0,
151,14,269,4,0,
151,14,270,3,0,
151,14,271,3,0,
151,14,272,3,0,
151,14,276,3,0,
151,14,277,3,0,
151,14,278,3,0,
151,14,280,4,0,
151,14,282,3,0,
151,14,283,3,0,
151,14,285,3,0,
151,14,289,3,0,
151,14,291,4,0,
151,14,304,3,0,
151,14,315,4,0,
151,14,317,4,0,
151,14,324,3,0,
151,14,332,4,0,
151,14,334,3,0,
151,14,335,3,0,
151,14,337,4,0,
151,14,339,4,0,
151,14,340,3,0,
151,14,343,3,0,
151,14,347,4,0,
151,14,355,3,0,
151,14,356,3,0,
151,14,360,4,0,
151,14,365,4,0,
151,14,366,3,0,
151,14,369,4,0,
151,14,371,4,0,
151,14,373,4,0,
151,14,374,4,0,
151,14,380,3,0,
151,14,382,1,70,
151,14,387,3,0,
151,14,388,3,0,
151,14,393,3,0,
151,14,396,1,100,
151,14,397,4,0,
151,14,398,4,0,
151,14,399,3,0,
151,14,401,3,0,
151,14,402,3,0,
151,14,404,4,0,
151,14,406,3,0,
151,14,409,3,0,
151,14,411,4,0,
151,14,412,4,0,
151,14,414,3,0,
151,14,416,4,0,
151,14,417,1,90,
151,14,421,4,0,
151,14,428,3,0,
151,14,430,4,0,
151,14,433,4,0,
151,14,441,3,0,
151,14,442,3,0,
151,14,444,4,0,
151,14,446,3,0,
151,14,447,4,0,
151,14,450,3,0,
151,14,451,4,0,
151,14,468,4,0,
151,14,472,3,0,
151,14,473,4,0,
151,14,474,4,0,
151,14,477,4,0,
151,14,478,3,0,
151,14,479,4,0,
151,14,482,4,0,
151,14,488,4,0,
151,14,490,4,0,
151,14,492,3,0,
151,14,495,3,0,
151,14,496,4,0,
151,14,497,4,0,
151,14,502,4,0,
151,14,503,4,0,
151,14,507,4,0,
151,14,510,4,0,
151,14,511,4,0,
151,14,512,4,0,
151,14,513,1,1,2
151,14,514,4,0,
151,14,521,4,0,
151,14,522,4,0,
151,14,523,4,0,
151,14,524,4,0,
151,14,525,4,0,
151,14,526,4,0,
151,14,527,3,0,
151,14,528,4,0,
151,14,529,3,0,
151,14,530,3,0,
151,14,555,4,0,
151,15,1,1,1,1
151,15,5,1,10,
151,15,14,4,0,
151,15,15,4,0,
151,15,19,4,0,
151,15,46,4,0,
151,15,53,4,0,
151,15,57,4,0,
151,15,58,4,0,
151,15,59,4,0,
151,15,63,4,0,
151,15,70,4,0,
151,15,76,4,0,
151,15,85,4,0,
151,15,86,4,0,
151,15,87,4,0,
151,15,89,4,0,
151,15,91,4,0,
151,15,92,4,0,
151,15,94,1,30,
151,15,94,4,0,
151,15,104,4,0,
151,15,112,1,40,
151,15,113,4,0,
151,15,115,4,0,
151,15,118,1,20,
151,15,126,4,0,
151,15,127,4,0,
151,15,133,1,60,
151,15,138,4,0,
151,15,144,1,1,3
151,15,148,4,0,
151,15,153,4,0,
151,15,156,4,0,
151,15,157,4,0,
151,15,164,4,0,
151,15,168,4,0,
151,15,182,4,0,
151,15,188,4,0,
151,15,201,4,0,
151,15,206,4,0,
151,15,207,4,0,
151,15,211,4,0,
151,15,213,4,0,
151,15,214,4,0,
151,15,216,4,0,
151,15,218,4,0,
151,15,219,4,0,
151,15,226,1,80,
151,15,237,4,0,
151,15,240,4,0,
151,15,241,4,0,
151,15,244,4,0,
151,15,246,1,50,
151,15,247,4,0,
151,15,249,4,0,
151,15,258,4,0,
151,15,259,4,0,
151,15,261,4,0,
151,15,263,4,0,
151,15,267,4,0,
151,15,269,4,0,
151,15,280,4,0,
151,15,315,4,0,
151,15,317,4,0,
151,15,332,4,0,
151,15,337,4,0,
151,15,339,4,0,
151,15,347,4,0,
151,15,355,4,0,
151,15,360,4,0,
151,15,369,4,0,
151,15,371,4,0,
151,15,373,4,0,
151,15,374,4,0,
151,15,382,1,70,
151,15,396,1,100,
151,15,397,4,0,
151,15,398,4,0,
151,15,399,4,0,
151,15,404,4,0,
151,15,411,4,0,
151,15,412,4,0,
151,15,416,4,0,
151,15,417,1,90,
151,15,421,4,0,
151,15,430,4,0,
151,15,433,4,0,
151,15,444,4,0,
151,15,447,4,0,
151,15,451,4,0,
151,15,468,4,0,
151,15,473,4,0,
151,15,474,4,0,
151,15,479,4,0,
151,15,482,4,0,
151,15,488,4,0,
151,15,490,4,0,
151,15,496,4,0,
151,15,497,4,0,
151,15,503,4,0,
151,15,507,4,0,
151,15,510,4,0,
151,15,511,4,0,
151,15,512,4,0,
151,15,513,1,1,2
151,15,514,4,0,
151,15,521,4,0,
151,15,522,4,0,
151,15,523,4,0,
151,15,524,4,0,
151,15,525,4,0,
151,15,528,4,0,
151,15,555,4,0,
151,15,590,4,0,
151,15,605,4,0,
151,15,611,4,0,
151,15,612,4,0,
151,16,1,1,1,1
151,16,5,1,10,1
151,16,7,3,0,
151,16,8,3,0,
151,16,9,3,0,
151,16,14,4,0,
151,16,15,4,0,
151,16,19,4,0,
151,16,20,3,0,
151,16,46,4,0,
151,16,53,4,0,
151,16,57,4,0,
151,16,58,4,0,
151,16,59,4,0,
151,16,63,4,0,
151,16,67,3,0,
151,16,70,4,0,
151,16,76,4,0,
151,16,85,4,0,
151,16,86,4,0,
151,16,87,4,0,
151,16,89,4,0,
151,16,91,4,0,
151,16,92,4,0,
151,16,94,1,30,1
151,16,94,4,0,
151,16,104,4,0,
151,16,112,1,40,1
151,16,113,4,0,
151,16,115,4,0,
151,16,118,1,20,1
151,16,126,4,0,
151,16,127,4,0,
151,16,133,1,60,1
151,16,138,4,0,
151,16,143,3,0,
151,16,144,1,1,3
151,16,148,4,0,
151,16,153,4,0,
151,16,156,4,0,
151,16,157,4,0,
151,16,162,3,0,
151,16,164,4,0,
151,16,168,4,0,
151,16,173,3,0,
151,16,180,3,0,
151,16,182,4,0,
151,16,188,4,0,
151,16,196,3,0,
151,16,200,3,0,
151,16,201,4,0,
151,16,202,3,0,
151,16,206,4,0,
151,16,207,4,0,
151,16,211,4,0,
151,16,213,4,0,
151,16,214,4,0,
151,16,215,3,0,
151,16,216,4,0,
151,16,218,4,0,
151,16,219,4,0,
151,16,220,3,0,
151,16,226,1,80,1
151,16,231,3,0,
151,16,235,3,0,
151,16,237,4,0,
151,16,240,4,0,
151,16,241,4,0,
151,16,244,4,0,
151,16,246,1,50,1
151,16,247,4,0,
151,16,249,4,0,
151,16,253,3,0,
151,16,257,3,0,
151,16,258,4,0,
151,16,259,4,0,
151,16,261,4,0,
151,16,263,4,0,
151,16,264,3,0,
151,16,267,4,0,
151,16,269,4,0,
151,16,270,3,0,
151,16,271,3,0,
151,16,272,3,0,
151,16,276,3,0,
151,16,277,3,0,
151,16,278,3,0,
151,16,280,4,0,
151,16,282,3,0,
151,16,283,3,0,
151,16,285,3,0,
151,16,289,3,0,
151,16,290,4,0,
151,16,291,4,0,
151,16,304,3,0,
151,16,315,4,0,
151,16,317,4,0,
151,16,324,3,0,
151,16,332,4,0,
151,16,334,3,0,
151,16,335,3,0,
151,16,337,4,0,
151,16,339,4,0,
151,16,340,3,0,
151,16,343,3,0,
151,16,347,4,0,
151,16,351,3,0,
151,16,352,3,0,
151,16,355,4,0,
151,16,356,3,0,
151,16,360,4,0,
151,16,366,3,0,
151,16,369,4,0,
151,16,371,4,0,
151,16,373,4,0,
151,16,374,4,0,
151,16,380,3,0,
151,16,382,1,70,1
151,16,387,3,0,
151,16,388,3,0,
151,16,393,3,0,
151,16,396,1,100,1
151,16,397,4,0,
151,16,398,4,0,
151,16,399,4,0,
151,16,401,3,0,
151,16,402,3,0,
151,16,404,4,0,
151,16,406,3,0,
151,16,409,3,0,
151,16,411,4,0,
151,16,412,4,0,
151,16,414,3,0,
151,16,416,4,0,
151,16,417,1,90,1
151,16,421,4,0,
151,16,428,3,0,
151,16,430,4,0,
151,16,433,4,0,
151,16,441,3,0,
151,16,442,3,0,
151,16,444,4,0,
151,16,446,3,0,
151,16,447,4,0,
151,16,450,3,0,
151,16,451,4,0,
151,16,468,4,0,
151,16,472,3,0,
151,16,473,4,0,
151,16,474,4,0,
151,16,478,3,0,
151,16,479,4,0,
151,16,482,4,0,
151,16,488,4,0,
151,16,490,4,0,
151,16,492,3,0,
151,16,495,3,0,
151,16,496,4,0,
151,16,497,4,0,
151,16,503,4,0,
151,16,507,4,0,
151,16,510,4,0,
151,16,511,4,0,
151,16,512,4,0,
151,16,513,1,1,2
151,16,514,4,0,
151,16,521,4,0,
151,16,522,4,0,
151,16,523,4,0,
151,16,524,4,0,
151,16,525,4,0,
151,16,527,3,0,
151,16,528,4,0,
151,16,529,3,0,
151,16,530,3,0,
151,16,555,4,0,
151,16,590,4,0,
151,16,605,4,0,
151,16,611,4,0,
151,16,612,4,0,
151,17,1,1,1,1
151,17,5,1,10,
151,17,14,4,0,
151,17,19,4,0,
151,17,46,4,0,
151,17,53,4,0,
151,17,57,4,0,
151,17,58,4,0,
151,17,59,4,0,
151,17,63,4,0,
151,17,76,4,0,
151,17,85,4,0,
151,17,86,4,0,
151,17,87,4,0,
151,17,89,4,0,
151,17,92,4,0,
151,17,94,1,30,
151,17,94,4,0,
151,17,104,4,0,
151,17,112,1,40,
151,17,113,4,0,
151,17,115,4,0,
151,17,118,1,20,
151,17,126,4,0,
151,17,127,4,0,
151,17,133,1,60,
151,17,138,4,0,
151,17,141,4,0,
151,17,144,1,1,3
151,17,153,4,0,
151,17,156,4,0,
151,17,157,4,0,
151,17,164,4,0,
151,17,168,4,0,
151,17,182,4,0,
151,17,188,4,0,
151,17,201,4,0,
151,17,206,4,0,
151,17,207,4,0,
151,17,211,4,0,
151,17,213,4,0,
151,17,214,4,0,
151,17,216,4,0,
151,17,218,4,0,
151,17,219,4,0,
151,17,226,1,80,
151,17,237,4,0,
151,17,240,4,0,
151,17,241,4,0,
151,17,244,4,0,
151,17,246,1,50,
151,17,247,4,0,
151,17,258,4,0,
151,17,259,4,0,
151,17,261,4,0,
151,17,263,4,0,
151,17,267,4,0,
151,17,269,4,0,
151,17,280,4,0,
151,17,315,4,0,
151,17,317,4,0,
151,17,332,4,0,
151,17,337,4,0,
151,17,339,4,0,
151,17,347,4,0,
151,17,355,4,0,
151,17,360,4,0,
151,17,369,4,0,
151,17,371,4,0,
151,17,373,4,0,
151,17,374,4,0,
151,17,382,1,70,
151,17,396,1,100,
151,17,397,4,0,
151,17,398,4,0,
151,17,399,4,0,
151,17,404,4,0,
151,17,411,4,0,
151,17,412,4,0,
151,17,416,4,0,
151,17,417,1,90,
151,17,421,4,0,
151,17,430,4,0,
151,17,433,4,0,
151,17,444,4,0,
151,17,447,4,0,
151,17,451,4,0,
151,17,473,4,0,
151,17,474,4,0,
151,17,479,4,0,
151,17,482,4,0,
151,17,488,4,0,
151,17,490,4,0,
151,17,496,4,0,
151,17,497,4,0,
151,17,503,4,0,
151,17,507,4,0,
151,17,511,4,0,
151,17,512,4,0,
151,17,513,1,1,2
151,17,521,4,0,
151,17,523,4,0,
151,17,524,4,0,
151,17,525,4,0,
151,17,526,4,0,
151,17,528,4,0,
151,17,555,4,0,
151,17,590,4,0,
151,17,605,4,0,
151,17,611,4,0,
151,17,684,4,0,
151,17,693,4,0,
151,17,694,4,0,
151,18,1,1,1,1
151,18,5,1,10,
151,18,14,4,0,
151,18,19,4,0,
151,18,46,4,0,
151,18,53,4,0,
151,18,57,4,0,
151,18,58,4,0,
151,18,59,4,0,
151,18,63,4,0,
151,18,76,4,0,
151,18,85,4,0,
151,18,86,4,0,
151,18,87,4,0,
151,18,89,4,0,
151,18,92,4,0,
151,18,94,1,30,
151,18,94,4,0,
151,18,104,4,0,
151,18,112,1,40,
151,18,113,4,0,
151,18,115,4,0,
151,18,118,1,20,
151,18,126,4,0,
151,18,127,4,0,
151,18,133,1,60,
151,18,138,4,0,
151,18,141,4,0,
151,18,144,1,1,3
151,18,153,4,0,
151,18,156,4,0,
151,18,157,4,0,
151,18,164,4,0,
151,18,168,4,0,
151,18,182,4,0,
151,18,188,4,0,
151,18,201,4,0,
151,18,206,4,0,
151,18,207,4,0,
151,18,211,4,0,
151,18,213,4,0,
151,18,214,4,0,
151,18,216,4,0,
151,18,218,4,0,
151,18,219,4,0,
151,18,226,1,80,
151,18,237,4,0,
151,18,240,4,0,
151,18,241,4,0,
151,18,244,4,0,
151,18,246,1,50,
151,18,247,4,0,
151,18,258,4,0,
151,18,259,4,0,
151,18,261,4,0,
151,18,263,4,0,
151,18,267,4,0,
151,18,269,4,0,
151,18,280,4,0,
151,18,315,4,0,
151,18,317,4,0,
151,18,332,4,0,
151,18,337,4,0,
151,18,339,4,0,
151,18,347,4,0,
151,18,355,4,0,
151,18,360,4,0,
151,18,369,4,0,
151,18,371,4,0,
151,18,373,4,0,
151,18,374,4,0,
151,18,382,1,70,
151,18,396,1,100,
151,18,397,4,0,
151,18,398,4,0,
151,18,399,4,0,
151,18,404,4,0,
151,18,411,4,0,
151,18,412,4,0,
151,18,416,4,0,
151,18,417,1,90,
151,18,421,4,0,
151,18,430,4,0,
151,18,433,4,0,
151,18,444,4,0,
151,18,447,4,0,
151,18,451,4,0,
151,18,473,4,0,
151,18,474,4,0,
151,18,479,4,0,
151,18,482,4,0,
151,18,488,4,0,
151,18,490,4,0,
151,18,496,4,0,
151,18,497,4,0,
151,18,503,4,0,
151,18,507,4,0,
151,18,511,4,0,
151,18,512,4,0,
151,18,513,1,1,2
151,18,521,4,0,
151,18,523,4,0,
151,18,524,4,0,
151,18,525,4,0,
151,18,526,4,0,
151,18,528,4,0,
151,18,555,4,0,
151,18,590,4,0,
151,18,605,4,0,
151,18,611,4,0,
151,18,684,4,0,
151,18,693,4,0,
151,18,694,4,0,
152,3,15,4,0,
152,3,22,2,0,
152,3,29,4,0,
152,3,33,1,1,1
152,3,34,1,29,
152,3,45,1,1,2
152,3,68,2,0,
152,3,73,2,0,
152,3,75,1,8,
152,3,76,1,50,
152,3,76,4,0,
152,3,77,1,15,
152,3,92,4,0,
152,3,104,4,0,
152,3,113,1,36,
152,3,115,1,12,
152,3,148,4,0,
152,3,156,4,0,
152,3,173,4,0,
152,3,174,4,0,
152,3,175,2,0,
152,3,182,4,0,
152,3,189,4,0,
152,3,197,4,0,
152,3,202,4,0,
152,3,203,4,0,
152,3,207,4,0,
152,3,213,4,0,
152,3,214,4,0,
152,3,216,4,0,
152,3,218,4,0,
152,3,219,1,43,
152,3,230,4,0,
152,3,231,4,0,
152,3,235,1,22,
152,3,237,4,0,
152,3,241,4,0,
152,3,246,2,0,
152,4,14,2,0,
152,4,15,4,0,
152,4,22,2,0,
152,4,29,4,0,
152,4,33,1,1,1
152,4,34,1,29,
152,4,45,1,1,2
152,4,68,2,0,
152,4,73,2,0,
152,4,75,1,8,
152,4,76,1,50,
152,4,76,4,0,
152,4,77,1,15,
152,4,92,4,0,
152,4,104,4,0,
152,4,113,1,36,
152,4,115,1,12,
152,4,148,4,0,
152,4,156,4,0,
152,4,173,4,0,
152,4,174,4,0,
152,4,175,2,0,
152,4,182,4,0,
152,4,189,4,0,
152,4,197,4,0,
152,4,202,4,0,
152,4,203,4,0,
152,4,207,4,0,
152,4,213,4,0,
152,4,214,4,0,
152,4,216,4,0,
152,4,218,4,0,
152,4,219,1,43,
152,4,230,4,0,
152,4,231,4,0,
152,4,235,1,22,
152,4,237,4,0,
152,4,241,4,0,
152,4,246,2,0,
152,5,15,4,0,
152,5,22,2,0,
152,5,33,1,1,1
152,5,34,1,29,
152,5,45,1,1,2
152,5,68,2,0,
152,5,73,2,0,
152,5,75,1,8,
152,5,76,1,50,
152,5,76,4,0,
152,5,77,1,15,
152,5,92,4,0,
152,5,104,4,0,
152,5,113,1,36,
152,5,113,4,0,
152,5,115,1,12,
152,5,115,4,0,
152,5,148,4,0,
152,5,156,4,0,
152,5,175,2,0,
152,5,182,4,0,
152,5,202,4,0,
152,5,213,4,0,
152,5,216,4,0,
152,5,218,4,0,
152,5,219,1,43,
152,5,219,4,0,
152,5,231,4,0,
152,5,235,1,22,
152,5,237,4,0,
152,5,241,4,0,
152,5,246,2,0,
152,5,263,4,0,
152,5,267,2,0,
152,5,275,2,0,
152,5,290,4,0,
152,5,320,2,0,
152,5,331,4,0,
152,6,14,3,0,
152,6,15,4,0,
152,6,22,2,0,
152,6,33,1,1,1
152,6,34,1,29,
152,6,34,3,0,
152,6,38,3,0,
152,6,45,1,1,2
152,6,68,2,0,
152,6,68,3,0,
152,6,73,2,0,
152,6,75,1,8,
152,6,76,1,50,
152,6,76,4,0,
152,6,77,1,15,
152,6,92,4,0,
152,6,102,3,0,
152,6,104,4,0,
152,6,113,1,36,
152,6,113,4,0,
152,6,115,1,12,
152,6,115,4,0,
152,6,148,4,0,
152,6,156,4,0,
152,6,164,3,0,
152,6,173,3,0,
152,6,175,2,0,
152,6,182,4,0,
152,6,189,3,0,
152,6,202,4,0,
152,6,203,3,0,
152,6,207,3,0,
152,6,213,4,0,
152,6,214,3,0,
152,6,216,4,0,
152,6,218,4,0,
152,6,219,1,43,
152,6,219,4,0,
152,6,231,4,0,
152,6,235,1,22,
152,6,237,4,0,
152,6,241,4,0,
152,6,246,2,0,
152,6,263,4,0,
152,6,267,2,0,
152,6,275,2,0,
152,6,290,4,0,
152,6,320,2,0,
152,6,331,4,0,
152,7,14,3,0,
152,7,15,4,0,
152,7,22,2,0,
152,7,33,1,1,1
152,7,34,1,29,
152,7,34,3,0,
152,7,38,3,0,
152,7,45,1,1,2
152,7,68,2,0,
152,7,68,3,0,
152,7,73,2,0,
152,7,75,1,8,
152,7,76,1,50,
152,7,76,4,0,
152,7,77,1,15,
152,7,92,4,0,
152,7,102,3,0,
152,7,104,4,0,
152,7,113,1,36,
152,7,113,4,0,
152,7,115,1,12,
152,7,115,4,0,
152,7,148,4,0,
152,7,156,4,0,
152,7,164,3,0,
152,7,175,2,0,
152,7,182,4,0,
152,7,202,4,0,
152,7,213,4,0,
152,7,216,4,0,
152,7,218,4,0,
152,7,219,1,43,
152,7,219,4,0,
152,7,231,4,0,
152,7,235,1,22,
152,7,237,4,0,
152,7,241,4,0,
152,7,246,2,0,
152,7,263,4,0,
152,7,267,2,0,
152,7,275,2,0,
152,7,290,4,0,
152,7,320,2,0,
152,7,331,4,0,
152,8,14,4,0,
152,8,15,4,0,
152,8,22,2,0,
152,8,33,1,1,1
152,8,34,1,34,
152,8,45,1,1,2
152,8,68,2,0,
152,8,73,2,0,
152,8,75,1,6,
152,8,76,1,45,
152,8,76,4,0,
152,8,77,1,9,
152,8,92,4,0,
152,8,104,4,0,
152,8,113,1,31,
152,8,113,4,0,
152,8,115,1,17,
152,8,115,4,0,
152,8,148,4,0,
152,8,156,4,0,
152,8,164,4,0,
152,8,175,2,0,
152,8,182,4,0,
152,8,202,4,0,
152,8,203,4,0,
152,8,207,4,0,
152,8,213,4,0,
152,8,214,4,0,
152,8,216,4,0,
152,8,218,4,0,
152,8,219,1,39,
152,8,219,4,0,
152,8,230,1,28,
152,8,231,4,0,
152,8,235,1,12,
152,8,237,4,0,
152,8,241,4,0,
152,8,246,2,0,
152,8,263,4,0,
152,8,267,2,0,
152,8,275,2,0,
152,8,290,4,0,
152,8,312,1,42,
152,8,312,2,0,
152,8,320,2,0,
152,8,331,4,0,
152,8,345,1,20,
152,8,363,1,23,
152,8,363,4,0,
152,8,378,2,0,
152,8,412,4,0,
152,8,437,2,0,
152,8,445,4,0,
152,8,447,4,0,
152,9,14,4,0,
152,9,15,4,0,
152,9,22,2,0,
152,9,33,1,1,1
152,9,34,1,34,
152,9,45,1,1,2
152,9,68,2,0,
152,9,73,2,0,
152,9,75,1,6,
152,9,76,1,45,
152,9,76,4,0,
152,9,77,1,9,
152,9,92,4,0,
152,9,104,4,0,
152,9,113,1,31,
152,9,113,4,0,
152,9,115,1,17,
152,9,115,4,0,
152,9,148,4,0,
152,9,156,4,0,
152,9,164,4,0,
152,9,173,3,0,
152,9,175,2,0,
152,9,182,4,0,
152,9,189,3,0,
152,9,202,4,0,
152,9,203,4,0,
152,9,207,4,0,
152,9,210,3,0,
152,9,213,4,0,
152,9,214,4,0,
152,9,216,4,0,
152,9,218,4,0,
152,9,219,1,39,
152,9,219,4,0,
152,9,230,1,28,
152,9,231,4,0,
152,9,235,1,12,
152,9,235,3,0,
152,9,237,4,0,
152,9,241,4,0,
152,9,246,2,0,
152,9,246,3,0,
152,9,263,4,0,
152,9,267,2,0,
152,9,275,2,0,
152,9,290,4,0,
152,9,312,1,42,
152,9,312,2,0,
152,9,320,2,0,
152,9,331,4,0,
152,9,345,1,20,
152,9,363,1,23,
152,9,363,4,0,
152,9,378,2,0,
152,9,402,3,0,
152,9,412,4,0,
152,9,437,2,0,
152,9,445,4,0,
152,9,447,4,0,
152,10,14,4,0,
152,10,15,4,0,
152,10,22,2,0,
152,10,29,3,0,
152,10,33,1,1,1
152,10,34,1,34,
152,10,34,2,0,
152,10,45,1,1,2
152,10,68,2,0,
152,10,73,2,0,
152,10,75,1,6,
152,10,76,1,45,
152,10,76,4,0,
152,10,77,1,9,
152,10,92,4,0,
152,10,104,4,0,
152,10,113,1,31,
152,10,113,4,0,
152,10,115,1,17,
152,10,115,4,0,
152,10,148,4,0,
152,10,156,4,0,
152,10,164,4,0,
152,10,173,3,0,
152,10,175,2,0,
152,10,182,4,0,
152,10,189,3,0,
152,10,202,4,0,
152,10,203,4,0,
152,10,207,4,0,
152,10,210,3,0,
152,10,213,4,0,
152,10,214,4,0,
152,10,216,4,0,
152,10,218,4,0,
152,10,219,1,39,
152,10,219,4,0,
152,10,230,1,28,
152,10,231,4,0,
152,10,235,1,12,
152,10,235,3,0,
152,10,237,4,0,
152,10,241,4,0,
152,10,246,2,0,
152,10,246,3,0,
152,10,263,4,0,
152,10,267,2,0,
152,10,275,2,0,
152,10,277,3,0,
152,10,290,4,0,
152,10,312,1,42,
152,10,312,2,0,
152,10,320,2,0,
152,10,331,4,0,
152,10,345,1,20,
152,10,363,1,23,
152,10,363,4,0,
152,10,378,2,0,
152,10,388,3,0,
152,10,402,3,0,
152,10,412,4,0,
152,10,437,2,0,
152,10,445,4,0,
152,10,447,4,0,
152,11,14,4,0,
152,11,15,4,0,
152,11,22,2,0,
152,11,33,1,1,1
152,11,34,1,34,
152,11,34,2,0,
152,11,45,1,1,2
152,11,68,2,0,
152,11,73,2,0,
152,11,75,1,6,
152,11,76,1,45,
152,11,76,4,0,
152,11,77,1,9,
152,11,92,4,0,
152,11,104,4,0,
152,11,113,1,31,
152,11,113,4,0,
152,11,115,1,17,
152,11,115,4,0,
152,11,148,4,0,
152,11,156,4,0,
152,11,164,4,0,
152,11,175,2,0,
152,11,182,4,0,
152,11,207,4,0,
152,11,213,4,0,
152,11,216,4,0,
152,11,218,4,0,
152,11,219,1,39,
152,11,219,4,0,
152,11,230,1,28,
152,11,235,1,12,
152,11,237,4,0,
152,11,241,4,0,
152,11,246,2,0,
152,11,263,4,0,
152,11,267,2,0,
152,11,275,2,0,
152,11,287,2,0,
152,11,312,1,42,
152,11,312,2,0,
152,11,320,2,0,
152,11,345,1,20,
152,11,363,1,23,
152,11,378,2,0,
152,11,412,4,0,
152,11,437,2,0,
152,11,447,4,0,
152,11,496,4,0,
152,11,497,4,0,
152,11,505,2,0,
152,11,520,3,0,
152,12,15,4,0,
152,12,33,1,1,1
152,12,34,1,29,
152,12,45,1,1,2
152,12,75,1,8,
152,12,76,1,50,
152,12,76,4,0,
152,12,77,1,15,
152,12,92,4,0,
152,12,104,4,0,
152,12,113,1,36,
152,12,113,4,0,
152,12,115,1,12,
152,12,115,4,0,
152,12,148,4,0,
152,12,156,4,0,
152,12,182,4,0,
152,12,202,4,0,
152,12,213,4,0,
152,12,216,4,0,
152,12,218,4,0,
152,12,219,1,43,
152,12,219,4,0,
152,12,231,4,0,
152,12,235,1,22,
152,12,237,4,0,
152,12,241,4,0,
152,12,263,4,0,
152,12,290,4,0,
152,12,331,4,0,
152,13,15,4,0,
152,13,33,1,1,1
152,13,34,1,29,
152,13,34,3,0,
152,13,38,3,0,
152,13,45,1,1,2
152,13,75,1,8,
152,13,76,1,50,
152,13,76,4,0,
152,13,77,1,15,
152,13,92,4,0,
152,13,102,3,0,
152,13,104,4,0,
152,13,113,1,36,
152,13,113,4,0,
152,13,115,1,12,
152,13,115,4,0,
152,13,148,4,0,
152,13,156,4,0,
152,13,164,3,0,
152,13,182,4,0,
152,13,202,4,0,
152,13,207,3,0,
152,13,213,4,0,
152,13,216,4,0,
152,13,218,4,0,
152,13,219,1,43,
152,13,219,4,0,
152,13,231,4,0,
152,13,235,1,22,
152,13,237,4,0,
152,13,241,4,0,
152,13,263,4,0,
152,13,290,4,0,
152,13,331,4,0,
152,14,14,4,0,
152,14,15,4,0,
152,14,22,2,0,
152,14,33,1,1,1
152,14,34,1,34,
152,14,34,2,0,
152,14,45,1,1,2
152,14,68,2,0,
152,14,73,2,0,
152,14,75,1,6,
152,14,76,1,45,
152,14,76,4,0,
152,14,77,1,9,
152,14,92,4,0,
152,14,104,4,0,
152,14,113,1,31,
152,14,113,4,0,
152,14,115,1,17,
152,14,115,4,0,
152,14,148,4,0,
152,14,156,4,0,
152,14,164,4,0,
152,14,173,3,0,
152,14,175,2,0,
152,14,182,4,0,
152,14,202,3,0,
152,14,207,4,0,
152,14,213,4,0,
152,14,214,3,0,
152,14,216,4,0,
152,14,218,4,0,
152,14,219,1,39,
152,14,219,4,0,
152,14,230,1,28,
152,14,231,3,0,
152,14,235,1,12,
152,14,235,3,0,
152,14,237,4,0,
152,14,241,4,0,
152,14,246,2,0,
152,14,263,4,0,
152,14,267,2,0,
152,14,275,2,0,
152,14,277,3,0,
152,14,287,2,0,
152,14,312,1,42,
152,14,312,2,0,
152,14,320,2,0,
152,14,345,1,20,
152,14,363,1,23,
152,14,378,2,0,
152,14,388,3,0,
152,14,402,3,0,
152,14,412,4,0,
152,14,437,2,0,
152,14,447,4,0,
152,14,496,4,0,
152,14,497,4,0,
152,14,505,2,0,
152,14,520,3,0,
152,15,14,4,0,
152,15,15,4,0,
152,15,22,2,0,
152,15,33,1,1,1
152,15,34,1,34,
152,15,34,2,0,
152,15,45,1,1,2
152,15,68,2,0,
152,15,73,2,0,
152,15,75,1,6,
152,15,76,1,45,
152,15,76,4,0,
152,15,77,1,9,
152,15,92,4,0,
152,15,104,4,0,
152,15,113,1,31,
152,15,113,4,0,
152,15,115,1,17,
152,15,115,4,0,
152,15,148,4,0,
152,15,156,4,0,
152,15,164,4,0,
152,15,175,2,0,
152,15,182,4,0,
152,15,207,4,0,
152,15,213,4,0,
152,15,214,4,0,
152,15,216,4,0,
152,15,218,4,0,
152,15,219,1,39,
152,15,219,4,0,
152,15,230,1,28,
152,15,235,1,12,
152,15,237,4,0,
152,15,241,4,0,
152,15,246,2,0,
152,15,263,4,0,
152,15,267,2,0,
152,15,267,4,0,
152,15,275,2,0,
152,15,287,2,0,
152,15,312,1,42,
152,15,312,2,0,
152,15,320,2,0,
152,15,345,1,20,
152,15,363,1,23,
152,15,378,2,0,
152,15,412,4,0,
152,15,437,2,0,
152,15,447,4,0,
152,15,496,4,0,
152,15,497,4,0,
152,15,505,2,0,
152,15,520,3,0,
152,15,580,2,0,
152,15,590,4,0,
152,16,14,4,0,
152,16,15,4,0,
152,16,22,2,0,
152,16,33,1,1,1
152,16,34,1,34,1
152,16,34,2,0,
152,16,45,1,1,2
152,16,68,2,0,
152,16,73,2,0,
152,16,75,1,6,1
152,16,76,1,45,1
152,16,76,4,0,
152,16,77,1,9,1
152,16,92,4,0,
152,16,104,4,0,
152,16,113,1,31,1
152,16,113,4,0,
152,16,115,1,17,1
152,16,115,4,0,
152,16,148,4,0,
152,16,156,4,0,
152,16,164,4,0,
152,16,173,3,0,
152,16,175,2,0,
152,16,182,4,0,
152,16,202,3,0,
152,16,207,4,0,
152,16,213,4,0,
152,16,214,4,0,
152,16,216,4,0,
152,16,218,4,0,
152,16,219,1,39,1
152,16,219,4,0,
152,16,230,1,28,1
152,16,231,3,0,
152,16,235,1,12,1
152,16,235,3,0,
152,16,237,4,0,
152,16,241,4,0,
152,16,246,2,0,
152,16,263,4,0,
152,16,267,2,0,
152,16,267,4,0,
152,16,275,2,0,
152,16,277,3,0,
152,16,287,2,0,
152,16,290,4,0,
152,16,312,1,42,1
152,16,312,2,0,
152,16,320,2,0,
152,16,345,1,20,1
152,16,363,1,23,1
152,16,378,2,0,
152,16,388,3,0,
152,16,402,3,0,
152,16,412,4,0,
152,16,437,2,0,
152,16,447,4,0,
152,16,496,4,0,
152,16,497,4,0,
152,16,505,2,0,
152,16,520,3,0,
152,16,580,2,0,
152,16,590,4,0,
152,17,14,4,0,
152,17,22,2,0,
152,17,33,1,1,1
152,17,34,1,34,
152,17,34,2,0,
152,17,45,1,1,2
152,17,68,2,0,
152,17,73,2,0,
152,17,75,1,6,
152,17,76,1,45,
152,17,76,4,0,
152,17,77,1,9,
152,17,92,4,0,
152,17,104,4,0,
152,17,113,1,31,
152,17,113,4,0,
152,17,115,1,17,
152,17,115,4,0,
152,17,156,4,0,
152,17,164,4,0,
152,17,175,2,0,
152,17,182,4,0,
152,17,207,4,0,
152,17,213,4,0,
152,17,214,4,0,
152,17,216,4,0,
152,17,218,4,0,
152,17,219,1,39,
152,17,219,4,0,
152,17,230,1,28,
152,17,235,1,12,
152,17,237,4,0,
152,17,241,4,0,
152,17,246,2,0,
152,17,263,4,0,
152,17,267,2,0,
152,17,267,4,0,
152,17,275,2,0,
152,17,287,2,0,
152,17,312,1,42,
152,17,312,2,0,
152,17,320,2,0,
152,17,345,1,20,
152,17,363,1,23,
152,17,378,2,0,
152,17,412,4,0,
152,17,437,2,0,
152,17,447,4,0,
152,17,496,4,0,
152,17,497,4,0,
152,17,505,2,0,
152,17,520,3,0,
152,17,526,4,0,
152,17,580,2,0,
152,17,590,4,0,
152,18,14,4,0,
152,18,22,2,0,
152,18,33,1,1,1
152,18,34,1,34,
152,18,34,2,0,
152,18,45,1,1,2
152,18,68,2,0,
152,18,73,2,0,
152,18,75,1,6,
152,18,76,1,45,
152,18,76,4,0,
152,18,77,1,9,
152,18,92,4,0,
152,18,104,4,0,
152,18,113,1,31,
152,18,113,4,0,
152,18,115,1,17,
152,18,115,4,0,
152,18,156,4,0,
152,18,164,4,0,
152,18,175,2,0,
152,18,182,4,0,
152,18,207,4,0,
152,18,213,4,0,
152,18,214,4,0,
152,18,216,4,0,
152,18,218,4,0,
152,18,219,1,39,
152,18,219,4,0,
152,18,230,1,28,
152,18,235,1,12,
152,18,237,4,0,
152,18,241,4,0,
152,18,246,2,0,
152,18,263,4,0,
152,18,267,2,0,
152,18,267,4,0,
152,18,275,2,0,
152,18,287,2,0,
152,18,312,1,42,
152,18,312,2,0,
152,18,320,2,0,
152,18,345,1,20,
152,18,363,1,23,
152,18,378,2,0,
152,18,412,4,0,
152,18,437,2,0,
152,18,447,4,0,
152,18,496,4,0,
152,18,497,4,0,
152,18,505,2,0,
152,18,520,3,0,
152,18,526,4,0,
152,18,580,2,0,
152,18,590,4,0,
153,3,15,4,0,
153,3,29,4,0,
153,3,33,1,1,1
153,3,34,1,31,
153,3,45,1,1,2
153,3,70,4,0,
153,3,75,1,1,3
153,3,75,1,8,
153,3,76,1,55,
153,3,76,4,0,
153,3,77,1,15,
153,3,92,4,0,
153,3,104,4,0,
153,3,113,1,39,
153,3,115,1,1,4
153,3,115,1,12,
153,3,148,4,0,
153,3,156,4,0,
153,3,173,4,0,
153,3,174,4,0,
153,3,182,4,0,
153,3,189,4,0,
153,3,197,4,0,
153,3,202,4,0,
153,3,203,4,0,
153,3,207,4,0,
153,3,210,4,0,
153,3,213,4,0,
153,3,214,4,0,
153,3,216,4,0,
153,3,218,4,0,
153,3,219,1,47,
153,3,230,4,0,
153,3,231,4,0,
153,3,235,1,23,
153,3,237,4,0,
153,3,241,4,0,
153,3,249,4,0,
153,4,15,4,0,
153,4,29,4,0,
153,4,33,1,1,1
153,4,34,1,31,
153,4,45,1,1,2
153,4,70,4,0,
153,4,75,1,1,3
153,4,75,1,8,
153,4,76,1,55,
153,4,76,4,0,
153,4,77,1,15,
153,4,92,4,0,
153,4,104,4,0,
153,4,113,1,39,
153,4,115,1,1,4
153,4,115,1,12,
153,4,148,4,0,
153,4,156,4,0,
153,4,173,4,0,
153,4,174,4,0,
153,4,182,4,0,
153,4,189,4,0,
153,4,197,4,0,
153,4,202,4,0,
153,4,203,4,0,
153,4,207,4,0,
153,4,210,4,0,
153,4,213,4,0,
153,4,214,4,0,
153,4,216,4,0,
153,4,218,4,0,
153,4,219,1,47,
153,4,230,4,0,
153,4,231,4,0,
153,4,235,1,23,
153,4,237,4,0,
153,4,241,4,0,
153,4,249,4,0,
153,5,15,4,0,
153,5,33,1,1,1
153,5,34,1,31,
153,5,45,1,1,2
153,5,70,4,0,
153,5,75,1,1,3
153,5,75,1,8,
153,5,76,1,55,
153,5,76,4,0,
153,5,77,1,15,
153,5,92,4,0,
153,5,104,4,0,
153,5,113,1,39,
153,5,113,4,0,
153,5,115,1,1,4
153,5,115,1,12,
153,5,115,4,0,
153,5,148,4,0,
153,5,156,4,0,
153,5,182,4,0,
153,5,202,4,0,
153,5,213,4,0,
153,5,216,4,0,
153,5,218,4,0,
153,5,219,1,47,
153,5,219,4,0,
153,5,231,4,0,
153,5,235,1,23,
153,5,237,4,0,
153,5,241,4,0,
153,5,249,4,0,
153,5,263,4,0,
153,5,290,4,0,
153,5,331,4,0,
153,6,14,3,0,
153,6,15,4,0,
153,6,33,1,1,1
153,6,34,1,31,
153,6,34,3,0,
153,6,38,3,0,
153,6,45,1,1,2
153,6,68,3,0,
153,6,70,4,0,
153,6,75,1,1,3
153,6,75,1,8,
153,6,76,1,55,
153,6,76,4,0,
153,6,77,1,15,
153,6,92,4,0,
153,6,102,3,0,
153,6,104,4,0,
153,6,113,1,39,
153,6,113,4,0,
153,6,115,1,1,4
153,6,115,1,12,
153,6,115,4,0,
153,6,148,4,0,
153,6,156,4,0,
153,6,164,3,0,
153,6,173,3,0,
153,6,182,4,0,
153,6,189,3,0,
153,6,202,4,0,
153,6,203,3,0,
153,6,207,3,0,
153,6,210,3,0,
153,6,213,4,0,
153,6,214,3,0,
153,6,216,4,0,
153,6,218,4,0,
153,6,219,1,47,
153,6,219,4,0,
153,6,231,4,0,
153,6,235,1,23,
153,6,237,4,0,
153,6,241,4,0,
153,6,249,4,0,
153,6,263,4,0,
153,6,290,4,0,
153,6,331,4,0,
153,7,14,3,0,
153,7,15,4,0,
153,7,33,1,1,1
153,7,34,1,31,
153,7,34,3,0,
153,7,38,3,0,
153,7,45,1,1,2
153,7,68,3,0,
153,7,70,4,0,
153,7,75,1,1,3
153,7,75,1,8,
153,7,76,1,55,
153,7,76,4,0,
153,7,77,1,15,
153,7,92,4,0,
153,7,102,3,0,
153,7,104,4,0,
153,7,113,1,39,
153,7,113,4,0,
153,7,115,1,1,4
153,7,115,1,12,
153,7,115,4,0,
153,7,148,4,0,
153,7,156,4,0,
153,7,164,3,0,
153,7,182,4,0,
153,7,202,4,0,
153,7,213,4,0,
153,7,216,4,0,
153,7,218,4,0,
153,7,219,1,47,
153,7,219,4,0,
153,7,231,4,0,
153,7,235,1,23,
153,7,237,4,0,
153,7,241,4,0,
153,7,249,4,0,
153,7,263,4,0,
153,7,290,4,0,
153,7,331,4,0,
153,8,14,4,0,
153,8,15,4,0,
153,8,33,1,1,1
153,8,34,1,40,
153,8,45,1,1,2
153,8,70,4,0,
153,8,75,1,1,3
153,8,75,1,6,
153,8,76,1,54,
153,8,76,4,0,
153,8,77,1,1,4
153,8,77,1,9,
153,8,92,4,0,
153,8,104,4,0,
153,8,113,1,36,
153,8,113,4,0,
153,8,115,1,18,
153,8,115,4,0,
153,8,148,4,0,
153,8,156,4,0,
153,8,164,4,0,
153,8,182,4,0,
153,8,202,4,0,
153,8,203,4,0,
153,8,207,4,0,
153,8,213,4,0,
153,8,214,4,0,
153,8,216,4,0,
153,8,218,4,0,
153,8,219,1,46,
153,8,219,4,0,
153,8,230,1,32,
153,8,231,4,0,
153,8,235,1,12,
153,8,237,4,0,
153,8,241,4,0,
153,8,249,4,0,
153,8,263,4,0,
153,8,290,4,0,
153,8,312,1,50,
153,8,331,4,0,
153,8,345,1,22,
153,8,363,1,26,
153,8,363,4,0,
153,8,412,4,0,
153,8,445,4,0,
153,8,447,4,0,
153,9,14,4,0,
153,9,15,4,0,
153,9,33,1,1,1
153,9,34,1,40,
153,9,45,1,1,2
153,9,70,4,0,
153,9,75,1,1,3
153,9,75,1,6,
153,9,76,1,54,
153,9,76,4,0,
153,9,77,1,1,4
153,9,77,1,9,
153,9,92,4,0,
153,9,104,4,0,
153,9,113,1,36,
153,9,113,4,0,
153,9,115,1,18,
153,9,115,4,0,
153,9,148,4,0,
153,9,156,4,0,
153,9,164,4,0,
153,9,173,3,0,
153,9,182,4,0,
153,9,189,3,0,
153,9,202,4,0,
153,9,203,4,0,
153,9,207,4,0,
153,9,210,3,0,
153,9,213,4,0,
153,9,214,4,0,
153,9,216,4,0,
153,9,218,4,0,
153,9,219,1,46,
153,9,219,4,0,
153,9,230,1,32,
153,9,231,4,0,
153,9,235,1,12,
153,9,235,3,0,
153,9,237,4,0,
153,9,241,4,0,
153,9,246,3,0,
153,9,249,4,0,
153,9,263,4,0,
153,9,290,4,0,
153,9,312,1,50,
153,9,331,4,0,
153,9,345,1,22,
153,9,363,1,26,
153,9,363,4,0,
153,9,402,3,0,
153,9,412,4,0,
153,9,445,4,0,
153,9,447,4,0,
153,10,14,4,0,
153,10,15,4,0,
153,10,29,3,0,
153,10,33,1,1,1
153,10,34,1,40,
153,10,45,1,1,2
153,10,70,4,0,
153,10,75,1,1,3
153,10,75,1,6,
153,10,76,1,54,
153,10,76,4,0,
153,10,77,1,1,4
153,10,77,1,9,
153,10,92,4,0,
153,10,104,4,0,
153,10,113,1,36,
153,10,113,4,0,
153,10,115,1,18,
153,10,115,4,0,
153,10,148,4,0,
153,10,156,4,0,
153,10,164,4,0,
153,10,173,3,0,
153,10,182,4,0,
153,10,189,3,0,
153,10,202,4,0,
153,10,203,4,0,
153,10,207,4,0,
153,10,210,3,0,
153,10,213,4,0,
153,10,214,4,0,
153,10,216,4,0,
153,10,218,4,0,
153,10,219,1,46,
153,10,219,4,0,
153,10,230,1,32,
153,10,231,4,0,
153,10,235,1,12,
153,10,235,3,0,
153,10,237,4,0,
153,10,241,4,0,
153,10,246,3,0,
153,10,249,4,0,
153,10,263,4,0,
153,10,277,3,0,
153,10,290,4,0,
153,10,312,1,50,
153,10,331,4,0,
153,10,345,1,22,
153,10,363,1,26,
153,10,363,4,0,
153,10,388,3,0,
153,10,402,3,0,
153,10,412,4,0,
153,10,445,4,0,
153,10,447,4,0,
153,11,14,4,0,
153,11,15,4,0,
153,11,33,1,1,1
153,11,34,1,40,
153,11,45,1,1,2
153,11,70,4,0,
153,11,75,1,1,3
153,11,75,1,6,
153,11,76,1,54,
153,11,76,4,0,
153,11,77,1,1,4
153,11,77,1,9,
153,11,92,4,0,
153,11,104,4,0,
153,11,113,1,36,
153,11,113,4,0,
153,11,115,1,18,
153,11,115,4,0,
153,11,148,4,0,
153,11,156,4,0,
153,11,164,4,0,
153,11,182,4,0,
153,11,207,4,0,
153,11,213,4,0,
153,11,216,4,0,
153,11,218,4,0,
153,11,219,1,46,
153,11,219,4,0,
153,11,230,1,32,
153,11,235,1,12,
153,11,237,4,0,
153,11,241,4,0,
153,11,249,4,0,
153,11,263,4,0,
153,11,312,1,50,
153,11,345,1,22,
153,11,363,1,26,
153,11,412,4,0,
153,11,447,4,0,
153,11,496,4,0,
153,11,497,4,0,
153,11,520,3,0,
153,12,15,4,0,
153,12,33,1,1,1
153,12,34,1,31,
153,12,45,1,1,2
153,12,70,4,0,
153,12,75,1,1,3
153,12,75,1,8,
153,12,76,1,55,
153,12,76,4,0,
153,12,77,1,15,
153,12,92,4,0,
153,12,104,4,0,
153,12,113,1,39,
153,12,113,4,0,
153,12,115,1,1,4
153,12,115,1,12,
153,12,115,4,0,
153,12,148,4,0,
153,12,156,4,0,
153,12,182,4,0,
153,12,202,4,0,
153,12,213,4,0,
153,12,216,4,0,
153,12,218,4,0,
153,12,219,1,47,
153,12,219,4,0,
153,12,231,4,0,
153,12,235,1,23,
153,12,237,4,0,
153,12,241,4,0,
153,12,249,4,0,
153,12,263,4,0,
153,12,290,4,0,
153,12,331,4,0,
153,13,15,4,0,
153,13,33,1,1,1
153,13,34,1,31,
153,13,34,3,0,
153,13,38,3,0,
153,13,45,1,1,2
153,13,70,4,0,
153,13,75,1,1,3
153,13,75,1,8,
153,13,76,1,55,
153,13,76,4,0,
153,13,77,1,15,
153,13,92,4,0,
153,13,102,3,0,
153,13,104,4,0,
153,13,113,1,39,
153,13,113,4,0,
153,13,115,1,1,4
153,13,115,1,12,
153,13,115,4,0,
153,13,148,4,0,
153,13,156,4,0,
153,13,164,3,0,
153,13,182,4,0,
153,13,202,4,0,
153,13,207,3,0,
153,13,213,4,0,
153,13,216,4,0,
153,13,218,4,0,
153,13,219,1,47,
153,13,219,4,0,
153,13,231,4,0,
153,13,235,1,23,
153,13,237,4,0,
153,13,241,4,0,
153,13,249,4,0,
153,13,263,4,0,
153,13,290,4,0,
153,13,331,4,0,
153,14,14,4,0,
153,14,15,4,0,
153,14,33,1,1,1
153,14,34,1,40,
153,14,45,1,1,2
153,14,70,4,0,
153,14,75,1,1,3
153,14,75,1,6,
153,14,76,1,54,
153,14,76,4,0,
153,14,77,1,1,4
153,14,77,1,9,
153,14,92,4,0,
153,14,104,4,0,
153,14,113,1,36,
153,14,113,4,0,
153,14,115,1,18,
153,14,115,4,0,
153,14,148,4,0,
153,14,156,4,0,
153,14,164,4,0,
153,14,173,3,0,
153,14,182,4,0,
153,14,202,3,0,
153,14,207,4,0,
153,14,213,4,0,
153,14,214,3,0,
153,14,216,4,0,
153,14,218,4,0,
153,14,219,1,46,
153,14,219,4,0,
153,14,230,1,32,
153,14,231,3,0,
153,14,235,1,12,
153,14,235,3,0,
153,14,237,4,0,
153,14,241,4,0,
153,14,249,4,0,
153,14,263,4,0,
153,14,277,3,0,
153,14,312,1,50,
153,14,345,1,22,
153,14,363,1,26,
153,14,388,3,0,
153,14,402,3,0,
153,14,412,4,0,
153,14,447,4,0,
153,14,496,4,0,
153,14,497,4,0,
153,14,520,3,0,
153,15,14,4,0,
153,15,15,4,0,
153,15,33,1,1,1
153,15,34,1,40,
153,15,45,1,1,2
153,15,70,4,0,
153,15,75,1,1,3
153,15,75,1,6,
153,15,76,1,54,
153,15,76,4,0,
153,15,77,1,1,4
153,15,77,1,9,
153,15,92,4,0,
153,15,104,4,0,
153,15,113,1,36,
153,15,113,4,0,
153,15,115,1,18,
153,15,115,4,0,
153,15,148,4,0,
153,15,156,4,0,
153,15,164,4,0,
153,15,182,4,0,
153,15,207,4,0,
153,15,213,4,0,
153,15,214,4,0,
153,15,216,4,0,
153,15,218,4,0,
153,15,219,1,46,
153,15,219,4,0,
153,15,230,1,32,
153,15,235,1,12,
153,15,237,4,0,
153,15,241,4,0,
153,15,249,4,0,
153,15,263,4,0,
153,15,267,4,0,
153,15,312,1,50,
153,15,345,1,22,
153,15,363,1,26,
153,15,412,4,0,
153,15,447,4,0,
153,15,496,4,0,
153,15,497,4,0,
153,15,520,3,0,
153,15,590,4,0,
153,16,14,4,0,
153,16,15,4,0,
153,16,33,1,1,1
153,16,34,1,40,1
153,16,45,1,1,2
153,16,70,4,0,
153,16,75,1,1,3
153,16,75,1,6,1
153,16,76,1,54,1
153,16,76,4,0,
153,16,77,1,1,4
153,16,77,1,9,1
153,16,92,4,0,
153,16,104,4,0,
153,16,113,1,36,1
153,16,113,4,0,
153,16,115,1,18,1
153,16,115,4,0,
153,16,148,4,0,
153,16,156,4,0,
153,16,164,4,0,
153,16,173,3,0,
153,16,182,4,0,
153,16,202,3,0,
153,16,207,4,0,
153,16,213,4,0,
153,16,214,4,0,
153,16,216,4,0,
153,16,218,4,0,
153,16,219,1,46,1
153,16,219,4,0,
153,16,230,1,32,1
153,16,231,3,0,
153,16,235,1,12,1
153,16,235,3,0,
153,16,237,4,0,
153,16,241,4,0,
153,16,249,4,0,
153,16,263,4,0,
153,16,267,4,0,
153,16,277,3,0,
153,16,290,4,0,
153,16,312,1,50,1
153,16,345,1,22,1
153,16,363,1,26,1
153,16,388,3,0,
153,16,402,3,0,
153,16,412,4,0,
153,16,447,4,0,
153,16,496,4,0,
153,16,497,4,0,
153,16,520,3,0,
153,16,590,4,0,
153,17,14,4,0,
153,17,33,1,1,1
153,17,34,1,40,
153,17,45,1,1,2
153,17,75,1,1,3
153,17,75,1,6,
153,17,76,1,54,
153,17,76,4,0,
153,17,77,1,1,4
153,17,77,1,9,
153,17,92,4,0,
153,17,104,4,0,
153,17,113,1,36,
153,17,113,4,0,
153,17,115,1,18,
153,17,115,4,0,
153,17,156,4,0,
153,17,164,4,0,
153,17,182,4,0,
153,17,207,4,0,
153,17,213,4,0,
153,17,214,4,0,
153,17,216,4,0,
153,17,218,4,0,
153,17,219,1,46,
153,17,219,4,0,
153,17,230,1,32,
153,17,235,1,12,
153,17,237,4,0,
153,17,241,4,0,
153,17,263,4,0,
153,17,267,4,0,
153,17,312,1,50,
153,17,345,1,22,
153,17,363,1,26,
153,17,412,4,0,
153,17,447,4,0,
153,17,496,4,0,
153,17,497,4,0,
153,17,520,3,0,
153,17,526,4,0,
153,17,590,4,0,
153,18,14,4,0,
153,18,33,1,1,1
153,18,34,1,40,
153,18,45,1,1,2
153,18,75,1,1,3
153,18,75,1,6,
153,18,76,1,54,
153,18,76,4,0,
153,18,77,1,1,4
153,18,77,1,9,
153,18,92,4,0,
153,18,104,4,0,
153,18,113,1,36,
153,18,113,4,0,
153,18,115,1,18,
153,18,115,4,0,
153,18,156,4,0,
153,18,164,4,0,
153,18,182,4,0,
153,18,207,4,0,
153,18,213,4,0,
153,18,214,4,0,
153,18,216,4,0,
153,18,218,4,0,
153,18,219,1,46,
153,18,219,4,0,
153,18,230,1,32,
153,18,235,1,12,
153,18,237,4,0,
153,18,241,4,0,
153,18,263,4,0,
153,18,267,4,0,
153,18,312,1,50,
153,18,345,1,22,
153,18,363,1,26,
153,18,412,4,0,
153,18,447,4,0,
153,18,496,4,0,
153,18,497,4,0,
153,18,520,3,0,
153,18,526,4,0,
153,18,590,4,0,
154,3,15,4,0,
154,3,29,4,0,
154,3,33,1,1,1
154,3,34,1,31,
154,3,45,1,1,2
154,3,63,4,0,
154,3,70,4,0,
154,3,75,1,1,3
154,3,75,1,8,
154,3,76,1,61,
154,3,76,4,0,
154,3,77,1,15,
154,3,89,4,0,
154,3,92,4,0,
154,3,104,4,0,
154,3,113,1,41,
154,3,115,1,1,4
154,3,115,1,12,
154,3,148,4,0,
154,3,156,4,0,
154,3,173,4,0,
154,3,174,4,0,
154,3,182,4,0,
154,3,189,4,0,
154,3,197,4,0,
154,3,202,4,0,
154,3,203,4,0,
154,3,207,4,0,
154,3,210,4,0,
154,3,213,4,0,
154,3,214,4,0,
154,3,216,4,0,
154,3,218,4,0,
154,3,219,1,51,
154,3,230,4,0,
154,3,231,4,0,
154,3,235,1,23,
154,3,237,4,0,
154,3,241,4,0,
154,3,249,4,0,
154,4,15,4,0,
154,4,29,4,0,
154,4,33,1,1,1
154,4,34,1,31,
154,4,45,1,1,2
154,4,63,4,0,
154,4,70,4,0,
154,4,75,1,1,3
154,4,75,1,8,
154,4,76,1,61,
154,4,76,4,0,
154,4,77,1,15,
154,4,89,4,0,
154,4,92,4,0,
154,4,104,4,0,
154,4,113,1,41,
154,4,115,1,1,4
154,4,115,1,12,
154,4,148,4,0,
154,4,156,4,0,
154,4,173,4,0,
154,4,174,4,0,
154,4,182,4,0,
154,4,189,4,0,
154,4,197,4,0,
154,4,202,4,0,
154,4,203,4,0,
154,4,207,4,0,
154,4,210,4,0,
154,4,213,4,0,
154,4,214,4,0,
154,4,216,4,0,
154,4,218,4,0,
154,4,219,1,51,
154,4,230,4,0,
154,4,231,4,0,
154,4,235,1,23,
154,4,237,4,0,
154,4,241,4,0,
154,4,249,4,0,
154,5,15,4,0,
154,5,33,1,1,1
154,5,34,1,31,
154,5,45,1,1,2
154,5,63,4,0,
154,5,70,4,0,
154,5,75,1,1,3
154,5,75,1,8,
154,5,76,1,61,
154,5,76,4,0,
154,5,77,1,15,
154,5,89,4,0,
154,5,92,4,0,
154,5,104,4,0,
154,5,113,1,41,
154,5,113,4,0,
154,5,115,1,1,4
154,5,115,1,12,
154,5,115,4,0,
154,5,148,4,0,
154,5,156,4,0,
154,5,182,4,0,
154,5,202,4,0,
154,5,213,4,0,
154,5,216,4,0,
154,5,218,4,0,
154,5,219,1,51,
154,5,219,4,0,
154,5,231,4,0,
154,5,235,1,23,
154,5,237,4,0,
154,5,241,4,0,
154,5,249,4,0,
154,5,263,4,0,
154,5,290,4,0,
154,5,331,4,0,
154,6,14,3,0,
154,6,15,4,0,
154,6,33,1,1,1
154,6,34,1,31,
154,6,34,3,0,
154,6,38,3,0,
154,6,45,1,1,2
154,6,63,4,0,
154,6,68,3,0,
154,6,70,4,0,
154,6,75,1,1,3
154,6,75,1,8,
154,6,76,1,61,
154,6,76,4,0,
154,6,77,1,15,
154,6,89,4,0,
154,6,92,4,0,
154,6,102,3,0,
154,6,104,4,0,
154,6,113,1,41,
154,6,113,4,0,
154,6,115,1,1,4
154,6,115,1,12,
154,6,115,4,0,
154,6,148,4,0,
154,6,156,4,0,
154,6,164,3,0,
154,6,173,3,0,
154,6,182,4,0,
154,6,189,3,0,
154,6,202,4,0,
154,6,203,3,0,
154,6,207,3,0,
154,6,210,3,0,
154,6,213,4,0,
154,6,214,3,0,
154,6,216,4,0,
154,6,218,4,0,
154,6,219,1,51,
154,6,219,4,0,
154,6,231,4,0,
154,6,235,1,23,
154,6,237,4,0,
154,6,241,4,0,
154,6,249,4,0,
154,6,263,4,0,
154,6,290,4,0,
154,6,331,4,0,
154,7,14,3,0,
154,7,15,4,0,
154,7,33,1,1,1
154,7,34,1,31,
154,7,34,3,0,
154,7,38,3,0,
154,7,45,1,1,2
154,7,63,4,0,
154,7,68,3,0,
154,7,70,4,0,
154,7,75,1,1,3
154,7,75,1,8,
154,7,76,1,61,
154,7,76,4,0,
154,7,77,1,15,
154,7,89,4,0,
154,7,92,4,0,
154,7,102,3,0,
154,7,104,4,0,
154,7,113,1,41,
154,7,113,4,0,
154,7,115,1,1,4
154,7,115,1,12,
154,7,115,4,0,
154,7,148,4,0,
154,7,156,4,0,
154,7,164,3,0,
154,7,182,4,0,
154,7,202,4,0,
154,7,213,4,0,
154,7,216,4,0,
154,7,218,4,0,
154,7,219,1,51,
154,7,219,4,0,
154,7,231,4,0,
154,7,235,1,23,
154,7,237,4,0,
154,7,241,4,0,
154,7,249,4,0,
154,7,263,4,0,
154,7,290,4,0,
154,7,331,4,0,
154,8,14,4,0,
154,8,15,4,0,
154,8,33,1,1,1
154,8,34,1,46,
154,8,45,1,1,2
154,8,63,4,0,
154,8,70,4,0,
154,8,75,1,1,3
154,8,75,1,6,
154,8,76,1,66,
154,8,76,4,0,
154,8,77,1,1,4
154,8,77,1,9,
154,8,80,1,32,
154,8,89,4,0,
154,8,92,4,0,
154,8,104,4,0,
154,8,113,1,40,
154,8,113,4,0,
154,8,115,1,18,
154,8,115,4,0,
154,8,148,4,0,
154,8,156,4,0,
154,8,164,4,0,
154,8,182,4,0,
154,8,202,4,0,
154,8,203,4,0,
154,8,207,4,0,
154,8,213,4,0,
154,8,214,4,0,
154,8,216,4,0,
154,8,218,4,0,
154,8,219,1,54,
154,8,219,4,0,
154,8,230,1,34,
154,8,231,4,0,
154,8,235,1,12,
154,8,237,4,0,
154,8,241,4,0,
154,8,249,4,0,
154,8,263,4,0,
154,8,290,4,0,
154,8,312,1,60,
154,8,331,4,0,
154,8,338,3,0,
154,8,345,1,22,
154,8,363,1,26,
154,8,363,4,0,
154,8,412,4,0,
154,8,416,4,0,
154,8,431,4,0,
154,8,445,4,0,
154,8,447,4,0,
154,9,14,4,0,
154,9,15,4,0,
154,9,33,1,1,1
154,9,34,1,46,
154,9,45,1,1,2
154,9,63,4,0,
154,9,70,4,0,
154,9,75,1,1,3
154,9,75,1,6,
154,9,76,1,66,
154,9,76,4,0,
154,9,77,1,1,4
154,9,77,1,9,
154,9,80,1,32,
154,9,89,4,0,
154,9,92,4,0,
154,9,104,4,0,
154,9,113,1,40,
154,9,113,4,0,
154,9,115,1,18,
154,9,115,4,0,
154,9,148,4,0,
154,9,156,4,0,
154,9,164,4,0,
154,9,173,3,0,
154,9,182,4,0,
154,9,189,3,0,
154,9,200,3,0,
154,9,202,4,0,
154,9,203,4,0,
154,9,207,4,0,
154,9,210,3,0,
154,9,213,4,0,
154,9,214,4,0,
154,9,216,4,0,
154,9,218,4,0,
154,9,219,1,54,
154,9,219,4,0,
154,9,230,1,34,
154,9,231,4,0,
154,9,235,1,12,
154,9,235,3,0,
154,9,237,4,0,
154,9,241,4,0,
154,9,246,3,0,
154,9,249,4,0,
154,9,263,4,0,
154,9,290,4,0,
154,9,312,1,60,
154,9,331,4,0,
154,9,338,3,0,
154,9,345,1,22,
154,9,363,1,26,
154,9,363,4,0,
154,9,402,3,0,
154,9,412,4,0,
154,9,416,4,0,
154,9,431,4,0,
154,9,445,4,0,
154,9,447,4,0,
154,10,14,4,0,
154,10,15,4,0,
154,10,29,3,0,
154,10,33,1,1,1
154,10,34,1,46,
154,10,45,1,1,2
154,10,63,4,0,
154,10,70,4,0,
154,10,75,1,1,3
154,10,75,1,6,
154,10,76,1,66,
154,10,76,4,0,
154,10,77,1,1,4
154,10,77,1,9,
154,10,80,1,32,
154,10,89,4,0,
154,10,92,4,0,
154,10,104,4,0,
154,10,113,1,40,
154,10,113,4,0,
154,10,115,1,18,
154,10,115,4,0,
154,10,148,4,0,
154,10,156,4,0,
154,10,164,4,0,
154,10,173,3,0,
154,10,182,4,0,
154,10,189,3,0,
154,10,200,3,0,
154,10,202,4,0,
154,10,203,4,0,
154,10,207,4,0,
154,10,210,3,0,
154,10,213,4,0,
154,10,214,4,0,
154,10,216,4,0,
154,10,218,4,0,
154,10,219,1,54,
154,10,219,4,0,
154,10,230,1,34,
154,10,231,4,0,
154,10,235,1,12,
154,10,235,3,0,
154,10,237,4,0,
154,10,241,4,0,
154,10,246,3,0,
154,10,249,4,0,
154,10,263,4,0,
154,10,277,3,0,
154,10,290,4,0,
154,10,312,1,60,
154,10,331,4,0,
154,10,338,3,0,
154,10,345,1,22,
154,10,363,1,26,
154,10,363,4,0,
154,10,388,3,0,
154,10,402,3,0,
154,10,412,4,0,
154,10,416,4,0,
154,10,431,4,0,
154,10,445,4,0,
154,10,447,4,0,
154,11,14,4,0,
154,11,15,4,0,
154,11,33,1,1,1
154,11,34,1,46,
154,11,45,1,1,2
154,11,63,4,0,
154,11,70,4,0,
154,11,75,1,1,3
154,11,75,1,6,
154,11,76,1,66,
154,11,76,4,0,
154,11,77,1,1,4
154,11,77,1,9,
154,11,80,1,32,
154,11,89,4,0,
154,11,92,4,0,
154,11,104,4,0,
154,11,113,1,40,
154,11,113,4,0,
154,11,115,1,18,
154,11,115,4,0,
154,11,148,4,0,
154,11,156,4,0,
154,11,164,4,0,
154,11,182,4,0,
154,11,207,4,0,
154,11,213,4,0,
154,11,216,4,0,
154,11,218,4,0,
154,11,219,1,54,
154,11,219,4,0,
154,11,230,1,34,
154,11,235,1,12,
154,11,237,4,0,
154,11,241,4,0,
154,11,249,4,0,
154,11,263,4,0,
154,11,312,1,60,
154,11,338,3,0,
154,11,345,1,22,
154,11,363,1,26,
154,11,412,4,0,
154,11,416,4,0,
154,11,447,4,0,
154,11,496,4,0,
154,11,497,4,0,
154,11,520,3,0,
154,11,523,4,0,
154,11,525,4,0,
154,12,15,4,0,
154,12,33,1,1,1
154,12,34,1,31,
154,12,45,1,1,2
154,12,63,4,0,
154,12,70,4,0,
154,12,75,1,1,3
154,12,75,1,8,
154,12,76,1,61,
154,12,76,4,0,
154,12,77,1,15,
154,12,89,4,0,
154,12,92,4,0,
154,12,104,4,0,
154,12,113,1,41,
154,12,113,4,0,
154,12,115,1,1,4
154,12,115,1,12,
154,12,115,4,0,
154,12,148,4,0,
154,12,156,4,0,
154,12,182,4,0,
154,12,202,4,0,
154,12,213,4,0,
154,12,216,4,0,
154,12,218,4,0,
154,12,219,1,51,
154,12,219,4,0,
154,12,231,4,0,
154,12,235,1,23,
154,12,237,4,0,
154,12,241,4,0,
154,12,249,4,0,
154,12,263,4,0,
154,12,290,4,0,
154,12,331,4,0,
154,13,15,4,0,
154,13,33,1,1,1
154,13,34,1,31,
154,13,34,3,0,
154,13,38,3,0,
154,13,45,1,1,2
154,13,63,4,0,
154,13,70,4,0,
154,13,75,1,1,3
154,13,75,1,8,
154,13,76,1,61,
154,13,76,4,0,
154,13,77,1,15,
154,13,89,4,0,
154,13,92,4,0,
154,13,102,3,0,
154,13,104,4,0,
154,13,113,1,41,
154,13,113,4,0,
154,13,115,1,1,4
154,13,115,1,12,
154,13,115,4,0,
154,13,148,4,0,
154,13,156,4,0,
154,13,164,3,0,
154,13,182,4,0,
154,13,202,4,0,
154,13,207,3,0,
154,13,213,4,0,
154,13,216,4,0,
154,13,218,4,0,
154,13,219,1,51,
154,13,219,4,0,
154,13,231,4,0,
154,13,235,1,23,
154,13,237,4,0,
154,13,241,4,0,
154,13,249,4,0,
154,13,263,4,0,
154,13,290,4,0,
154,13,331,4,0,
154,14,14,4,0,
154,14,15,4,0,
154,14,33,1,1,1
154,14,34,1,46,
154,14,45,1,1,2
154,14,63,4,0,
154,14,70,4,0,
154,14,75,1,1,3
154,14,75,1,6,
154,14,76,1,66,
154,14,76,4,0,
154,14,77,1,1,4
154,14,77,1,9,
154,14,80,1,32,
154,14,89,4,0,
154,14,92,4,0,
154,14,104,4,0,
154,14,113,1,40,
154,14,113,4,0,
154,14,115,1,18,
154,14,115,4,0,
154,14,148,4,0,
154,14,156,4,0,
154,14,164,4,0,
154,14,173,3,0,
154,14,182,4,0,
154,14,200,3,0,
154,14,202,3,0,
154,14,207,4,0,
154,14,213,4,0,
154,14,214,3,0,
154,14,216,4,0,
154,14,218,4,0,
154,14,219,1,54,
154,14,219,4,0,
154,14,230,1,34,
154,14,231,3,0,
154,14,235,1,12,
154,14,235,3,0,
154,14,237,4,0,
154,14,241,4,0,
154,14,249,4,0,
154,14,263,4,0,
154,14,277,3,0,
154,14,312,1,60,
154,14,338,3,0,
154,14,345,1,22,
154,14,363,1,26,
154,14,388,3,0,
154,14,402,3,0,
154,14,412,4,0,
154,14,416,4,0,
154,14,447,4,0,
154,14,496,4,0,
154,14,497,4,0,
154,14,520,3,0,
154,14,523,4,0,
154,14,525,4,0,
154,15,14,4,0,
154,15,15,4,0,
154,15,33,1,1,2
154,15,34,1,46,
154,15,45,1,1,3
154,15,63,4,0,
154,15,70,4,0,
154,15,75,1,1,4
154,15,75,1,6,
154,15,76,1,66,
154,15,76,4,0,
154,15,77,1,1,5
154,15,77,1,9,
154,15,80,1,32,
154,15,89,4,0,
154,15,92,4,0,
154,15,104,4,0,
154,15,113,1,40,
154,15,113,4,0,
154,15,115,1,18,
154,15,115,4,0,
154,15,148,4,0,
154,15,156,4,0,
154,15,164,4,0,
154,15,182,4,0,
154,15,207,4,0,
154,15,213,4,0,
154,15,214,4,0,
154,15,216,4,0,
154,15,218,4,0,
154,15,219,1,54,
154,15,219,4,0,
154,15,230,1,34,
154,15,235,1,12,
154,15,237,4,0,
154,15,241,4,0,
154,15,249,4,0,
154,15,263,4,0,
154,15,267,4,0,
154,15,312,1,60,
154,15,338,3,0,
154,15,345,1,22,
154,15,363,1,26,
154,15,412,4,0,
154,15,416,4,0,
154,15,447,4,0,
154,15,496,4,0,
154,15,497,4,0,
154,15,520,3,0,
154,15,523,4,0,
154,15,525,4,0,
154,15,572,1,1,1
154,15,572,1,70,
154,15,590,4,0,
154,16,14,4,0,
154,16,15,4,0,
154,16,33,1,1,2
154,16,34,1,46,1
154,16,45,1,1,3
154,16,63,4,0,
154,16,70,4,0,
154,16,75,1,1,4
154,16,75,1,6,1
154,16,76,1,66,1
154,16,76,4,0,
154,16,77,1,1,5
154,16,77,1,9,1
154,16,80,1,32,1
154,16,89,4,0,
154,16,92,4,0,
154,16,104,4,0,
154,16,113,1,40,1
154,16,113,4,0,
154,16,115,1,18,1
154,16,115,4,0,
154,16,148,4,0,
154,16,156,4,0,
154,16,164,4,0,
154,16,173,3,0,
154,16,182,4,0,
154,16,200,3,0,
154,16,202,3,0,
154,16,207,4,0,
154,16,213,4,0,
154,16,214,4,0,
154,16,216,4,0,
154,16,218,4,0,
154,16,219,1,54,1
154,16,219,4,0,
154,16,230,1,34,1
154,16,231,3,0,
154,16,235,1,12,1
154,16,235,3,0,
154,16,237,4,0,
154,16,241,4,0,
154,16,249,4,0,
154,16,263,4,0,
154,16,267,4,0,
154,16,277,3,0,
154,16,290,4,0,
154,16,312,1,60,1
154,16,338,3,0,
154,16,345,1,22,1
154,16,363,1,26,1
154,16,388,3,0,
154,16,402,3,0,
154,16,412,4,0,
154,16,416,4,0,
154,16,447,4,0,
154,16,496,4,0,
154,16,497,4,0,
154,16,520,3,0,
154,16,523,4,0,
154,16,525,4,0,
154,16,572,1,1,1
154,16,572,1,70,1
154,16,590,4,0,
154,17,14,4,0,
154,17,33,1,1,3
154,17,34,1,46,
154,17,45,1,1,4
154,17,63,4,0,
154,17,75,1,1,5
154,17,75,1,6,
154,17,76,1,66,
154,17,76,4,0,
154,17,77,1,1,6
154,17,77,1,9,
154,17,80,1,0,
154,17,80,1,1,1
154,17,89,4,0,
154,17,92,4,0,
154,17,104,4,0,
154,17,113,1,40,
154,17,113,4,0,
154,17,115,1,18,
154,17,115,4,0,
154,17,156,4,0,
154,17,164,4,0,
154,17,182,4,0,
154,17,207,4,0,
154,17,213,4,0,
154,17,214,4,0,
154,17,216,4,0,
154,17,218,4,0,
154,17,219,1,54,
154,17,219,4,0,
154,17,230,1,34,
154,17,235,1,12,
154,17,237,4,0,
154,17,241,4,0,
154,17,263,4,0,
154,17,267,4,0,
154,17,312,1,60,
154,17,338,3,0,
154,17,345,1,22,
154,17,363,1,26,
154,17,412,4,0,
154,17,416,4,0,
154,17,447,4,0,
154,17,496,4,0,
154,17,497,4,0,
154,17,520,3,0,
154,17,523,4,0,
154,17,525,4,0,
154,17,526,4,0,
154,17,572,1,1,2
154,17,572,1,70,
154,17,590,4,0,
154,18,14,4,0,
154,18,33,1,1,3
154,18,34,1,46,
154,18,45,1,1,4
154,18,63,4,0,
154,18,75,1,1,5
154,18,75,1,6,
154,18,76,1,66,
154,18,76,4,0,
154,18,77,1,1,6
154,18,77,1,9,
154,18,80,1,0,
154,18,80,1,1,1
154,18,89,4,0,
154,18,92,4,0,
154,18,104,4,0,
154,18,113,1,40,
154,18,113,4,0,
154,18,115,1,18,
154,18,115,4,0,
154,18,156,4,0,
154,18,164,4,0,
154,18,182,4,0,
154,18,207,4,0,
154,18,213,4,0,
154,18,214,4,0,
154,18,216,4,0,
154,18,218,4,0,
154,18,219,1,54,
154,18,219,4,0,
154,18,230,1,34,
154,18,235,1,12,
154,18,237,4,0,
154,18,241,4,0,
154,18,263,4,0,
154,18,267,4,0,
154,18,312,1,60,
154,18,338,3,0,
154,18,345,1,22,
154,18,363,1,26,
154,18,412,4,0,
154,18,416,4,0,
154,18,447,4,0,
154,18,496,4,0,
154,18,497,4,0,
154,18,520,3,0,
154,18,523,4,0,
154,18,525,4,0,
154,18,526,4,0,
154,18,572,1,1,2
154,18,572,1,70,
154,18,590,4,0,
155,3,15,4,0,
155,3,29,4,0,
155,3,33,1,1,1
155,3,37,2,0,
155,3,43,1,1,2
155,3,52,1,12,
155,3,53,1,46,
155,3,91,4,0,
155,3,92,4,0,
155,3,98,1,19,
155,3,98,2,0,
155,3,104,4,0,
155,3,108,1,6,
155,3,111,4,0,
155,3,126,4,0,
155,3,129,1,36,
155,3,129,4,0,
155,3,154,2,0,
155,3,156,4,0,
155,3,172,1,27,
155,3,173,4,0,
155,3,174,4,0,
155,3,179,2,0,
155,3,182,4,0,
155,3,189,4,0,
155,3,193,2,0,
155,3,197,4,0,
155,3,203,4,0,
155,3,205,4,0,
155,3,207,4,0,
155,3,213,4,0,
155,3,214,4,0,
155,3,216,4,0,
155,3,218,4,0,
155,3,231,4,0,
155,3,237,4,0,
155,3,241,4,0,
155,4,15,4,0,
155,4,29,4,0,
155,4,33,1,1,1
155,4,37,2,0,
155,4,43,1,1,2
155,4,52,1,12,
155,4,53,1,46,
155,4,53,3,0,
155,4,66,2,0,
155,4,91,4,0,
155,4,92,4,0,
155,4,98,1,19,
155,4,98,2,0,
155,4,104,4,0,
155,4,108,1,6,
155,4,111,4,0,
155,4,126,4,0,
155,4,129,1,36,
155,4,129,4,0,
155,4,154,2,0,
155,4,156,4,0,
155,4,172,1,27,
155,4,173,4,0,
155,4,174,4,0,
155,4,179,2,0,
155,4,182,4,0,
155,4,189,4,0,
155,4,193,2,0,
155,4,197,4,0,
155,4,203,4,0,
155,4,205,4,0,
155,4,207,4,0,
155,4,213,4,0,
155,4,214,4,0,
155,4,216,4,0,
155,4,218,4,0,
155,4,231,4,0,
155,4,237,4,0,
155,4,241,4,0,
155,5,15,4,0,
155,5,33,1,1,1
155,5,37,2,0,
155,5,43,1,1,2
155,5,52,1,12,
155,5,53,1,46,
155,5,53,4,0,
155,5,91,4,0,
155,5,92,4,0,
155,5,98,1,19,
155,5,98,2,0,
155,5,104,4,0,
155,5,108,1,6,
155,5,126,4,0,
155,5,129,1,36,
155,5,154,2,0,
155,5,156,4,0,
155,5,172,1,27,
155,5,179,2,0,
155,5,182,4,0,
155,5,193,2,0,
155,5,213,4,0,
155,5,216,4,0,
155,5,218,4,0,
155,5,237,4,0,
155,5,241,4,0,
155,5,263,4,0,
155,5,290,4,0,
155,5,306,2,0,
155,5,315,4,0,
155,5,332,4,0,
155,5,336,2,0,
155,5,343,2,0,
155,6,15,4,0,
155,6,33,1,1,1
155,6,34,3,0,
155,6,37,2,0,
155,6,38,3,0,
155,6,43,1,1,2
155,6,52,1,12,
155,6,53,1,46,
155,6,53,4,0,
155,6,91,4,0,
155,6,92,4,0,
155,6,98,1,19,
155,6,98,2,0,
155,6,102,3,0,
155,6,104,4,0,
155,6,108,1,6,
155,6,111,3,0,
155,6,126,4,0,
155,6,129,1,36,
155,6,129,3,0,
155,6,154,2,0,
155,6,156,4,0,
155,6,164,3,0,
155,6,172,1,27,
155,6,173,3,0,
155,6,179,2,0,
155,6,182,4,0,
155,6,189,3,0,
155,6,193,2,0,
155,6,203,3,0,
155,6,205,3,0,
155,6,207,3,0,
155,6,213,4,0,
155,6,214,3,0,
155,6,216,4,0,
155,6,218,4,0,
155,6,237,4,0,
155,6,241,4,0,
155,6,263,4,0,
155,6,290,4,0,
155,6,306,2,0,
155,6,315,4,0,
155,6,332,4,0,
155,6,336,2,0,
155,6,343,2,0,
155,7,15,4,0,
155,7,33,1,1,1
155,7,34,3,0,
155,7,37,2,0,
155,7,38,3,0,
155,7,43,1,1,2
155,7,52,1,12,
155,7,53,1,46,
155,7,53,4,0,
155,7,91,4,0,
155,7,92,4,0,
155,7,98,1,19,
155,7,98,2,0,
155,7,102,3,0,
155,7,104,4,0,
155,7,108,1,6,
155,7,126,4,0,
155,7,129,1,36,
155,7,154,2,0,
155,7,156,4,0,
155,7,164,3,0,
155,7,172,1,27,
155,7,179,2,0,
155,7,182,4,0,
155,7,193,2,0,
155,7,213,4,0,
155,7,216,4,0,
155,7,218,4,0,
155,7,237,4,0,
155,7,241,4,0,
155,7,263,4,0,
155,7,290,4,0,
155,7,306,2,0,
155,7,315,4,0,
155,7,332,4,0,
155,7,336,2,0,
155,7,343,2,0,
155,8,15,4,0,
155,8,24,2,0,
155,8,33,1,1,1
155,8,37,2,0,
155,8,38,1,46,
155,8,38,2,0,
155,8,43,1,1,2
155,8,52,1,10,
155,8,53,1,37,
155,8,53,4,0,
155,8,91,4,0,
155,8,92,4,0,
155,8,98,1,13,
155,8,98,2,0,
155,8,104,4,0,
155,8,108,1,4,
155,8,111,1,22,
155,8,126,4,0,
155,8,129,1,28,
155,8,154,2,0,
155,8,156,4,0,
155,8,164,4,0,
155,8,172,1,19,
155,8,179,2,0,
155,8,182,4,0,
155,8,193,2,0,
155,8,203,4,0,
155,8,205,1,40,
155,8,207,4,0,
155,8,213,4,0,
155,8,214,4,0,
155,8,216,4,0,
155,8,218,4,0,
155,8,237,4,0,
155,8,241,4,0,
155,8,261,4,0,
155,8,263,4,0,
155,8,284,1,49,
155,8,290,4,0,
155,8,306,2,0,
155,8,315,4,0,
155,8,332,4,0,
155,8,336,2,0,
155,8,343,2,0,
155,8,363,4,0,
155,8,394,2,0,
155,8,436,1,31,
155,8,445,4,0,
155,9,15,4,0,
155,9,24,2,0,
155,9,33,1,1,1
155,9,37,2,0,
155,9,38,1,46,
155,9,38,2,0,
155,9,43,1,1,2
155,9,52,1,10,
155,9,53,1,37,
155,9,53,4,0,
155,9,91,4,0,
155,9,92,4,0,
155,9,98,1,13,
155,9,98,2,0,
155,9,104,4,0,
155,9,108,1,4,
155,9,111,1,22,
155,9,126,4,0,
155,9,129,1,28,
155,9,129,3,0,
155,9,154,2,0,
155,9,156,4,0,
155,9,164,4,0,
155,9,172,1,19,
155,9,173,3,0,
155,9,179,2,0,
155,9,182,4,0,
155,9,189,3,0,
155,9,193,2,0,
155,9,203,4,0,
155,9,205,1,40,
155,9,205,3,0,
155,9,207,4,0,
155,9,213,4,0,
155,9,214,4,0,
155,9,216,4,0,
155,9,218,4,0,
155,9,237,4,0,
155,9,241,4,0,
155,9,257,3,0,
155,9,261,4,0,
155,9,263,4,0,
155,9,284,1,49,
155,9,290,4,0,
155,9,306,2,0,
155,9,315,4,0,
155,9,332,4,0,
155,9,336,2,0,
155,9,343,2,0,
155,9,363,4,0,
155,9,394,2,0,
155,9,436,1,31,
155,9,445,4,0,
155,10,15,4,0,
155,10,24,2,0,
155,10,29,3,0,
155,10,33,1,1,1
155,10,37,2,0,
155,10,38,1,46,
155,10,38,2,0,
155,10,43,1,1,2
155,10,52,1,10,
155,10,53,1,37,
155,10,53,4,0,
155,10,91,4,0,
155,10,92,4,0,
155,10,98,1,13,
155,10,98,2,0,
155,10,104,4,0,
155,10,108,1,6,
155,10,111,1,22,
155,10,126,4,0,
155,10,129,1,28,
155,10,129,3,0,
155,10,154,2,0,
155,10,156,4,0,
155,10,164,4,0,
155,10,172,1,19,
155,10,173,3,0,
155,10,179,2,0,
155,10,182,4,0,
155,10,189,3,0,
155,10,193,2,0,
155,10,203,4,0,
155,10,205,1,40,
155,10,205,3,0,
155,10,207,4,0,
155,10,213,4,0,
155,10,214,4,0,
155,10,216,4,0,
155,10,218,4,0,
155,10,237,4,0,
155,10,241,4,0,
155,10,257,3,0,
155,10,261,4,0,
155,10,263,4,0,
155,10,284,1,49,
155,10,290,4,0,
155,10,306,2,0,
155,10,315,4,0,
155,10,326,2,0,
155,10,332,4,0,
155,10,336,2,0,
155,10,343,2,0,
155,10,363,4,0,
155,10,394,2,0,
155,10,436,1,31,
155,10,445,4,0,
155,11,15,4,0,
155,11,24,2,0,
155,11,33,1,1,1
155,11,37,2,0,
155,11,38,1,55,
155,11,38,2,0,
155,11,43,1,1,2
155,11,52,1,10,
155,11,53,1,40,
155,11,53,4,0,
155,11,91,4,0,
155,11,92,4,0,
155,11,98,1,13,
155,11,98,2,0,
155,11,104,4,0,
155,11,108,1,6,
155,11,111,1,22,
155,11,126,4,0,
155,11,129,1,31,
155,11,154,2,0,
155,11,156,4,0,
155,11,164,4,0,
155,11,172,1,19,
155,11,179,2,0,
155,11,182,4,0,
155,11,193,2,0,
155,11,205,1,49,
155,11,207,4,0,
155,11,213,4,0,
155,11,216,4,0,
155,11,218,4,0,
155,11,237,4,0,
155,11,241,4,0,
155,11,261,4,0,
155,11,263,4,0,
155,11,267,2,0,
155,11,284,1,58,
155,11,306,2,0,
155,11,315,4,0,
155,11,326,2,0,
155,11,332,4,0,
155,11,336,2,0,
155,11,343,2,0,
155,11,394,2,0,
155,11,436,1,37,
155,11,481,2,0,
155,11,488,1,28,
155,11,488,4,0,
155,11,496,4,0,
155,11,510,4,0,
155,11,517,1,46,
155,11,519,3,0,
155,11,528,4,0,
155,12,15,4,0,
155,12,33,1,1,1
155,12,43,1,1,2
155,12,52,1,12,
155,12,53,1,46,
155,12,53,4,0,
155,12,91,4,0,
155,12,92,4,0,
155,12,98,1,19,
155,12,104,4,0,
155,12,108,1,6,
155,12,126,4,0,
155,12,129,1,36,
155,12,156,4,0,
155,12,172,1,27,
155,12,182,4,0,
155,12,213,4,0,
155,12,216,4,0,
155,12,218,4,0,
155,12,237,4,0,
155,12,241,4,0,
155,12,263,4,0,
155,12,290,4,0,
155,12,315,4,0,
155,12,332,4,0,
155,13,15,4,0,
155,13,33,1,1,1
155,13,34,3,0,
155,13,38,3,0,
155,13,43,1,1,2
155,13,52,1,12,
155,13,53,1,46,
155,13,53,4,0,
155,13,91,4,0,
155,13,92,4,0,
155,13,98,1,19,
155,13,102,3,0,
155,13,104,4,0,
155,13,108,1,6,
155,13,126,4,0,
155,13,129,1,36,
155,13,156,4,0,
155,13,164,3,0,
155,13,172,1,27,
155,13,182,4,0,
155,13,207,3,0,
155,13,213,4,0,
155,13,216,4,0,
155,13,218,4,0,
155,13,237,4,0,
155,13,241,4,0,
155,13,263,4,0,
155,13,290,4,0,
155,13,315,4,0,
155,13,332,4,0,
155,14,15,4,0,
155,14,24,2,0,
155,14,33,1,1,1
155,14,37,2,0,
155,14,38,1,55,
155,14,38,2,0,
155,14,43,1,1,2
155,14,52,1,10,
155,14,53,1,40,
155,14,53,4,0,
155,14,91,4,0,
155,14,92,4,0,
155,14,98,1,13,
155,14,98,2,0,
155,14,104,4,0,
155,14,108,1,6,
155,14,111,1,22,
155,14,126,4,0,
155,14,129,1,31,
155,14,154,2,0,
155,14,156,4,0,
155,14,164,4,0,
155,14,172,1,19,
155,14,173,3,0,
155,14,179,2,0,
155,14,182,4,0,
155,14,193,2,0,
155,14,205,1,49,
155,14,207,4,0,
155,14,213,4,0,
155,14,214,3,0,
155,14,216,4,0,
155,14,218,4,0,
155,14,237,4,0,
155,14,241,4,0,
155,14,257,3,0,
155,14,261,4,0,
155,14,263,4,0,
155,14,267,2,0,
155,14,284,1,58,
155,14,306,2,0,
155,14,315,4,0,
155,14,326,2,0,
155,14,332,4,0,
155,14,336,2,0,
155,14,343,2,0,
155,14,343,3,0,
155,14,394,2,0,
155,14,436,1,37,
155,14,481,2,0,
155,14,488,1,28,
155,14,488,4,0,
155,14,496,4,0,
155,14,510,4,0,
155,14,517,1,46,
155,14,519,3,0,
155,14,528,4,0,
155,15,15,4,0,
155,15,24,2,0,
155,15,33,1,1,1
155,15,37,2,0,
155,15,38,1,55,
155,15,38,2,0,
155,15,43,1,1,2
155,15,52,1,10,
155,15,53,1,40,
155,15,53,4,0,
155,15,91,4,0,
155,15,92,4,0,
155,15,98,1,13,
155,15,98,2,0,
155,15,104,4,0,
155,15,108,1,6,
155,15,111,1,22,
155,15,126,4,0,
155,15,129,1,31,
155,15,154,2,0,
155,15,156,4,0,
155,15,164,4,0,
155,15,172,1,19,
155,15,179,2,0,
155,15,182,4,0,
155,15,193,2,0,
155,15,205,1,49,
155,15,207,4,0,
155,15,213,4,0,
155,15,214,4,0,
155,15,216,4,0,
155,15,218,4,0,
155,15,237,4,0,
155,15,241,4,0,
155,15,261,4,0,
155,15,263,4,0,
155,15,267,2,0,
155,15,267,4,0,
155,15,284,1,58,
155,15,306,2,0,
155,15,315,4,0,
155,15,326,2,0,
155,15,332,4,0,
155,15,336,2,0,
155,15,343,2,0,
155,15,394,2,0,
155,15,436,1,37,
155,15,481,2,0,
155,15,488,1,28,
155,15,488,4,0,
155,15,496,4,0,
155,15,510,4,0,
155,15,517,1,46,
155,15,519,3,0,
155,15,528,4,0,
155,15,590,4,0,
155,16,15,4,0,
155,16,24,2,0,
155,16,33,1,1,1
155,16,37,2,0,
155,16,38,1,55,1
155,16,38,2,0,
155,16,43,1,1,2
155,16,52,1,10,1
155,16,53,1,40,1
155,16,53,4,0,
155,16,91,4,0,
155,16,92,4,0,
155,16,98,1,13,1
155,16,98,2,0,
155,16,104,4,0,
155,16,108,1,6,1
155,16,111,1,22,1
155,16,126,4,0,
155,16,129,1,31,1
155,16,154,2,0,
155,16,156,4,0,
155,16,164,4,0,
155,16,172,1,19,1
155,16,173,3,0,
155,16,179,2,0,
155,16,182,4,0,
155,16,193,2,0,
155,16,205,1,49,1
155,16,207,4,0,
155,16,213,4,0,
155,16,214,4,0,
155,16,216,4,0,
155,16,218,4,0,
155,16,237,4,0,
155,16,241,4,0,
155,16,257,3,0,
155,16,261,4,0,
155,16,263,4,0,
155,16,267,2,0,
155,16,267,4,0,
155,16,284,1,58,1
155,16,290,4,0,
155,16,306,2,0,
155,16,315,4,0,
155,16,326,2,0,
155,16,332,4,0,
155,16,336,2,0,
155,16,343,2,0,
155,16,343,3,0,
155,16,394,2,0,
155,16,436,1,37,1
155,16,481,2,0,
155,16,488,1,28,1
155,16,488,4,0,
155,16,496,4,0,
155,16,510,4,0,
155,16,517,1,46,1
155,16,519,3,0,
155,16,528,4,0,
155,16,590,4,0,
155,17,24,2,0,
155,17,33,1,1,1
155,17,37,2,0,
155,17,38,1,55,
155,17,38,2,0,
155,17,43,1,1,2
155,17,52,1,10,
155,17,53,1,40,
155,17,53,4,0,
155,17,92,4,0,
155,17,98,1,13,
155,17,98,2,0,
155,17,104,4,0,
155,17,108,1,6,
155,17,111,1,22,
155,17,126,4,0,
155,17,129,1,31,
155,17,154,2,0,
155,17,156,4,0,
155,17,164,4,0,
155,17,172,1,19,
155,17,179,2,0,
155,17,182,4,0,
155,17,193,2,0,
155,17,205,1,49,
155,17,207,4,0,
155,17,213,4,0,
155,17,214,4,0,
155,17,216,4,0,
155,17,218,4,0,
155,17,237,4,0,
155,17,241,4,0,
155,17,261,4,0,
155,17,263,4,0,
155,17,267,2,0,
155,17,267,4,0,
155,17,284,1,64,
155,17,306,2,0,
155,17,315,4,0,
155,17,326,2,0,
155,17,332,4,0,
155,17,336,2,0,
155,17,343,2,0,
155,17,394,2,0,
155,17,436,1,37,
155,17,481,2,0,
155,17,488,1,28,
155,17,488,4,0,
155,17,496,4,0,
155,17,517,1,46,
155,17,519,3,0,
155,17,526,4,0,
155,17,528,4,0,
155,17,590,4,0,
155,17,682,1,58,
155,18,24,2,0,
155,18,33,1,1,1
155,18,37,2,0,
155,18,38,1,55,
155,18,38,2,0,
155,18,43,1,1,2
155,18,52,1,10,
155,18,53,1,40,
155,18,53,4,0,
155,18,92,4,0,
155,18,98,1,13,
155,18,98,2,0,
155,18,104,4,0,
155,18,108,1,6,
155,18,111,1,22,
155,18,126,4,0,
155,18,129,1,31,
155,18,154,2,0,
155,18,156,4,0,
155,18,164,4,0,
155,18,172,1,19,
155,18,179,2,0,
155,18,182,4,0,
155,18,193,2,0,
155,18,205,1,49,
155,18,207,4,0,
155,18,213,4,0,
155,18,214,4,0,
155,18,216,4,0,
155,18,218,4,0,
155,18,237,4,0,
155,18,241,4,0,
155,18,261,4,0,
155,18,263,4,0,
155,18,267,2,0,
155,18,267,4,0,
155,18,284,1,64,
155,18,306,2,0,
155,18,315,4,0,
155,18,326,2,0,
155,18,332,4,0,
155,18,336,2,0,
155,18,343,2,0,
155,18,394,2,0,
155,18,436,1,37,
155,18,481,2,0,
155,18,488,1,28,
155,18,488,4,0,
155,18,496,4,0,
155,18,517,1,46,
155,18,519,3,0,
155,18,526,4,0,
155,18,528,4,0,
155,18,590,4,0,
155,18,682,1,58,
156,3,15,4,0,
156,3,29,4,0,
156,3,33,1,1,1
156,3,43,1,1,2
156,3,46,4,0,
156,3,52,1,12,
156,3,53,1,54,
156,3,70,4,0,
156,3,91,4,0,
156,3,92,4,0,
156,3,98,1,21,
156,3,104,4,0,
156,3,108,1,1,3
156,3,108,1,6,
156,3,111,4,0,
156,3,126,4,0,
156,3,129,1,42,
156,3,129,4,0,
156,3,156,4,0,
156,3,172,1,31,
156,3,173,4,0,
156,3,174,4,0,
156,3,182,4,0,
156,3,189,4,0,
156,3,197,4,0,
156,3,203,4,0,
156,3,205,4,0,
156,3,207,4,0,
156,3,210,4,0,
156,3,213,4,0,
156,3,214,4,0,
156,3,216,4,0,
156,3,218,4,0,
156,3,231,4,0,
156,3,237,4,0,
156,3,241,4,0,
156,3,249,4,0,
156,4,15,4,0,
156,4,29,4,0,
156,4,33,1,1,1
156,4,43,1,1,2
156,4,46,4,0,
156,4,52,1,12,
156,4,53,1,54,
156,4,53,3,0,
156,4,70,4,0,
156,4,91,4,0,
156,4,92,4,0,
156,4,98,1,21,
156,4,104,4,0,
156,4,108,1,1,3
156,4,108,1,6,
156,4,111,4,0,
156,4,126,4,0,
156,4,129,1,42,
156,4,129,4,0,
156,4,156,4,0,
156,4,172,1,31,
156,4,173,4,0,
156,4,174,4,0,
156,4,182,4,0,
156,4,189,4,0,
156,4,197,4,0,
156,4,203,4,0,
156,4,205,4,0,
156,4,207,4,0,
156,4,210,4,0,
156,4,213,4,0,
156,4,214,4,0,
156,4,216,4,0,
156,4,218,4,0,
156,4,231,4,0,
156,4,237,4,0,
156,4,241,4,0,
156,4,249,4,0,
156,5,15,4,0,
156,5,33,1,1,1
156,5,43,1,1,2
156,5,46,4,0,
156,5,52,1,12,
156,5,53,1,54,
156,5,53,4,0,
156,5,70,4,0,
156,5,91,4,0,
156,5,92,4,0,
156,5,98,1,21,
156,5,104,4,0,
156,5,108,1,1,3
156,5,108,1,6,
156,5,126,4,0,
156,5,129,1,42,
156,5,156,4,0,
156,5,172,1,31,
156,5,182,4,0,
156,5,213,4,0,
156,5,216,4,0,
156,5,218,4,0,
156,5,237,4,0,
156,5,241,4,0,
156,5,249,4,0,
156,5,263,4,0,
156,5,264,4,0,
156,5,280,4,0,
156,5,290,4,0,
156,5,315,4,0,
156,5,332,4,0,
156,6,15,4,0,
156,6,33,1,1,1
156,6,34,3,0,
156,6,38,3,0,
156,6,43,1,1,2
156,6,46,4,0,
156,6,52,1,12,
156,6,53,1,54,
156,6,53,4,0,
156,6,70,4,0,
156,6,91,4,0,
156,6,92,4,0,
156,6,98,1,21,
156,6,102,3,0,
156,6,104,4,0,
156,6,108,1,1,3
156,6,108,1,6,
156,6,111,3,0,
156,6,126,4,0,
156,6,129,1,42,
156,6,129,3,0,
156,6,156,4,0,
156,6,164,3,0,
156,6,172,1,31,
156,6,173,3,0,
156,6,182,4,0,
156,6,189,3,0,
156,6,203,3,0,
156,6,205,3,0,
156,6,207,3,0,
156,6,210,3,0,
156,6,213,4,0,
156,6,214,3,0,
156,6,216,4,0,
156,6,218,4,0,
156,6,237,4,0,
156,6,241,4,0,
156,6,249,4,0,
156,6,263,4,0,
156,6,264,4,0,
156,6,280,4,0,
156,6,290,4,0,
156,6,315,4,0,
156,6,332,4,0,
156,7,15,4,0,
156,7,33,1,1,1
156,7,34,3,0,
156,7,38,3,0,
156,7,43,1,1,2
156,7,46,4,0,
156,7,52,1,12,
156,7,53,1,54,
156,7,53,4,0,
156,7,70,4,0,
156,7,91,4,0,
156,7,92,4,0,
156,7,98,1,21,
156,7,102,3,0,
156,7,104,4,0,
156,7,108,1,1,3
156,7,108,1,6,
156,7,126,4,0,
156,7,129,1,42,
156,7,156,4,0,
156,7,164,3,0,
156,7,172,1,31,
156,7,182,4,0,
156,7,213,4,0,
156,7,216,4,0,
156,7,218,4,0,
156,7,237,4,0,
156,7,241,4,0,
156,7,249,4,0,
156,7,263,4,0,
156,7,264,4,0,
156,7,280,4,0,
156,7,290,4,0,
156,7,315,4,0,
156,7,332,4,0,
156,8,15,4,0,
156,8,33,1,1,1
156,8,38,1,53,
156,8,43,1,1,2
156,8,46,4,0,
156,8,52,1,10,
156,8,53,1,42,
156,8,53,4,0,
156,8,70,4,0,
156,8,91,4,0,
156,8,92,4,0,
156,8,98,1,13,
156,8,104,4,0,
156,8,108,1,1,3
156,8,108,1,4,
156,8,111,1,24,
156,8,126,4,0,
156,8,129,1,31,
156,8,156,4,0,
156,8,164,4,0,
156,8,172,1,20,
156,8,182,4,0,
156,8,203,4,0,
156,8,205,1,46,
156,8,207,4,0,
156,8,213,4,0,
156,8,214,4,0,
156,8,216,4,0,
156,8,218,4,0,
156,8,237,4,0,
156,8,241,4,0,
156,8,249,4,0,
156,8,261,4,0,
156,8,263,4,0,
156,8,264,4,0,
156,8,280,4,0,
156,8,284,1,57,
156,8,290,4,0,
156,8,315,4,0,
156,8,332,4,0,
156,8,363,4,0,
156,8,436,1,35,
156,8,445,4,0,
156,9,15,4,0,
156,9,33,1,1,1
156,9,38,1,53,
156,9,43,1,1,2
156,9,46,4,0,
156,9,52,1,10,
156,9,53,1,42,
156,9,53,4,0,
156,9,70,4,0,
156,9,91,4,0,
156,9,92,4,0,
156,9,98,1,13,
156,9,104,4,0,
156,9,108,1,1,3
156,9,108,1,4,
156,9,111,1,24,
156,9,126,4,0,
156,9,129,1,31,
156,9,129,3,0,
156,9,156,4,0,
156,9,164,4,0,
156,9,172,1,20,
156,9,173,3,0,
156,9,182,4,0,
156,9,189,3,0,
156,9,203,4,0,
156,9,205,1,46,
156,9,205,3,0,
156,9,207,4,0,
156,9,210,3,0,
156,9,213,4,0,
156,9,214,4,0,
156,9,216,4,0,
156,9,218,4,0,
156,9,237,4,0,
156,9,241,4,0,
156,9,249,4,0,
156,9,257,3,0,
156,9,261,4,0,
156,9,263,4,0,
156,9,264,4,0,
156,9,280,4,0,
156,9,284,1,57,
156,9,290,4,0,
156,9,315,4,0,
156,9,332,4,0,
156,9,363,4,0,
156,9,436,1,35,
156,9,445,4,0,
156,10,15,4,0,
156,10,29,3,0,
156,10,33,1,1,1
156,10,38,1,53,
156,10,43,1,1,2
156,10,46,4,0,
156,10,52,1,10,
156,10,53,1,42,
156,10,53,4,0,
156,10,70,4,0,
156,10,91,4,0,
156,10,92,4,0,
156,10,98,1,13,
156,10,104,4,0,
156,10,108,1,1,3
156,10,108,1,6,
156,10,111,1,24,
156,10,126,4,0,
156,10,129,1,31,
156,10,129,3,0,
156,10,156,4,0,
156,10,164,4,0,
156,10,172,1,20,
156,10,173,3,0,
156,10,182,4,0,
156,10,189,3,0,
156,10,203,4,0,
156,10,205,1,46,
156,10,205,3,0,
156,10,207,4,0,
156,10,210,3,0,
156,10,213,4,0,
156,10,214,4,0,
156,10,216,4,0,
156,10,218,4,0,
156,10,237,4,0,
156,10,241,4,0,
156,10,249,4,0,
156,10,257,3,0,
156,10,261,4,0,
156,10,263,4,0,
156,10,264,4,0,
156,10,280,4,0,
156,10,284,1,57,
156,10,290,4,0,
156,10,315,4,0,
156,10,332,4,0,
156,10,363,4,0,
156,10,436,1,35,
156,10,445,4,0,
156,11,15,4,0,
156,11,33,1,1,1
156,11,38,1,64,
156,11,43,1,1,2
156,11,46,4,0,
156,11,52,1,10,
156,11,53,1,46,
156,11,53,4,0,
156,11,70,4,0,
156,11,91,4,0,
156,11,92,4,0,
156,11,98,1,13,
156,11,104,4,0,
156,11,108,1,1,3
156,11,108,1,6,
156,11,111,1,24,
156,11,126,4,0,
156,11,129,1,31,
156,11,156,4,0,
156,11,164,4,0,
156,11,172,1,20,
156,11,182,4,0,
156,11,205,1,57,
156,11,207,4,0,
156,11,213,4,0,
156,11,216,4,0,
156,11,218,4,0,
156,11,237,4,0,
156,11,241,4,0,
156,11,249,4,0,
156,11,261,4,0,
156,11,263,4,0,
156,11,280,4,0,
156,11,284,1,68,
156,11,315,4,0,
156,11,332,4,0,
156,11,436,1,42,
156,11,488,1,35,
156,11,488,4,0,
156,11,496,4,0,
156,11,510,4,0,
156,11,517,1,53,
156,11,519,3,0,
156,11,528,4,0,
156,12,15,4,0,
156,12,33,1,1,1
156,12,43,1,1,2
156,12,46,4,0,
156,12,52,1,12,
156,12,53,1,54,
156,12,53,4,0,
156,12,70,4,0,
156,12,91,4,0,
156,12,92,4,0,
156,12,98,1,21,
156,12,104,4,0,
156,12,108,1,1,3
156,12,108,1,6,
156,12,126,4,0,
156,12,129,1,42,
156,12,156,4,0,
156,12,172,1,31,
156,12,182,4,0,
156,12,213,4,0,
156,12,216,4,0,
156,12,218,4,0,
156,12,237,4,0,
156,12,241,4,0,
156,12,249,4,0,
156,12,263,4,0,
156,12,264,4,0,
156,12,280,4,0,
156,12,290,4,0,
156,12,315,4,0,
156,12,332,4,0,
156,13,15,4,0,
156,13,33,1,1,1
156,13,34,3,0,
156,13,38,3,0,
156,13,43,1,1,2
156,13,46,4,0,
156,13,52,1,12,
156,13,53,1,54,
156,13,53,4,0,
156,13,70,4,0,
156,13,91,4,0,
156,13,92,4,0,
156,13,98,1,21,
156,13,102,3,0,
156,13,104,4,0,
156,13,108,1,1,3
156,13,108,1,6,
156,13,126,4,0,
156,13,129,1,42,
156,13,156,4,0,
156,13,164,3,0,
156,13,172,1,31,
156,13,182,4,0,
156,13,207,3,0,
156,13,213,4,0,
156,13,216,4,0,
156,13,218,4,0,
156,13,237,4,0,
156,13,241,4,0,
156,13,249,4,0,
156,13,263,4,0,
156,13,264,4,0,
156,13,280,4,0,
156,13,290,4,0,
156,13,315,4,0,
156,13,332,4,0,
156,14,15,4,0,
156,14,33,1,1,1
156,14,38,1,64,
156,14,43,1,1,2
156,14,46,4,0,
156,14,52,1,10,
156,14,53,1,46,
156,14,53,4,0,
156,14,70,4,0,
156,14,91,4,0,
156,14,92,4,0,
156,14,98,1,13,
156,14,104,4,0,
156,14,108,1,1,3
156,14,108,1,6,
156,14,111,1,24,
156,14,126,4,0,
156,14,129,1,31,
156,14,156,4,0,
156,14,164,4,0,
156,14,172,1,20,
156,14,173,3,0,
156,14,182,4,0,
156,14,205,1,57,
156,14,207,4,0,
156,14,213,4,0,
156,14,214,3,0,
156,14,216,4,0,
156,14,218,4,0,
156,14,237,4,0,
156,14,241,4,0,
156,14,249,4,0,
156,14,257,3,0,
156,14,261,4,0,
156,14,263,4,0,
156,14,280,4,0,
156,14,284,1,68,
156,14,315,4,0,
156,14,332,4,0,
156,14,343,3,0,
156,14,436,1,42,
156,14,488,1,35,
156,14,488,4,0,
156,14,496,4,0,
156,14,510,4,0,
156,14,517,1,53,
156,14,519,3,0,
156,14,528,4,0,
156,15,15,4,0,
156,15,33,1,1,1
156,15,38,1,64,
156,15,43,1,1,2
156,15,46,4,0,
156,15,52,1,10,
156,15,53,1,46,
156,15,53,4,0,
156,15,70,4,0,
156,15,91,4,0,
156,15,92,4,0,
156,15,98,1,13,
156,15,104,4,0,
156,15,108,1,1,3
156,15,108,1,6,
156,15,111,1,24,
156,15,126,4,0,
156,15,129,1,31,
156,15,156,4,0,
156,15,164,4,0,
156,15,172,1,20,
156,15,182,4,0,
156,15,205,1,57,
156,15,207,4,0,
156,15,213,4,0,
156,15,214,4,0,
156,15,216,4,0,
156,15,218,4,0,
156,15,237,4,0,
156,15,241,4,0,
156,15,249,4,0,
156,15,261,4,0,
156,15,263,4,0,
156,15,267,4,0,
156,15,280,4,0,
156,15,284,1,68,
156,15,315,4,0,
156,15,332,4,0,
156,15,436,1,42,
156,15,488,1,35,
156,15,488,4,0,
156,15,496,4,0,
156,15,510,4,0,
156,15,517,1,53,
156,15,519,3,0,
156,15,528,4,0,
156,15,590,4,0,
156,16,15,4,0,
156,16,33,1,1,1
156,16,38,1,64,1
156,16,43,1,1,2
156,16,46,4,0,
156,16,52,1,10,1
156,16,53,1,46,1
156,16,53,4,0,
156,16,70,4,0,
156,16,91,4,0,
156,16,92,4,0,
156,16,98,1,13,1
156,16,104,4,0,
156,16,108,1,1,3
156,16,108,1,6,1
156,16,111,1,24,1
156,16,126,4,0,
156,16,129,1,31,1
156,16,156,4,0,
156,16,164,4,0,
156,16,172,1,20,1
156,16,173,3,0,
156,16,182,4,0,
156,16,205,1,57,1
156,16,207,4,0,
156,16,213,4,0,
156,16,214,4,0,
156,16,216,4,0,
156,16,218,4,0,
156,16,237,4,0,
156,16,241,4,0,
156,16,249,4,0,
156,16,257,3,0,
156,16,261,4,0,
156,16,263,4,0,
156,16,264,3,0,
156,16,267,4,0,
156,16,280,4,0,
156,16,284,1,68,1
156,16,290,4,0,
156,16,315,4,0,
156,16,332,4,0,
156,16,343,3,0,
156,16,436,1,42,1
156,16,488,1,35,1
156,16,488,4,0,
156,16,496,4,0,
156,16,510,4,0,
156,16,517,1,53,1
156,16,519,3,0,
156,16,528,4,0,
156,16,590,4,0,
156,17,33,1,1,1
156,17,38,1,64,
156,17,43,1,1,2
156,17,46,4,0,
156,17,52,1,10,
156,17,53,1,46,
156,17,53,4,0,
156,17,92,4,0,
156,17,98,1,13,
156,17,104,4,0,
156,17,108,1,1,3
156,17,108,1,6,
156,17,111,1,24,
156,17,126,4,0,
156,17,129,1,31,
156,17,156,4,0,
156,17,164,4,0,
156,17,172,1,20,
156,17,182,4,0,
156,17,205,1,57,
156,17,207,4,0,
156,17,213,4,0,
156,17,214,4,0,
156,17,216,4,0,
156,17,218,4,0,
156,17,237,4,0,
156,17,241,4,0,
156,17,261,4,0,
156,17,263,4,0,
156,17,267,4,0,
156,17,280,4,0,
156,17,284,1,75,
156,17,315,4,0,
156,17,332,4,0,
156,17,436,1,42,
156,17,488,1,35,
156,17,488,4,0,
156,17,496,4,0,
156,17,517,1,53,
156,17,519,3,0,
156,17,526,4,0,
156,17,528,4,0,
156,17,590,4,0,
156,17,682,1,68,
156,18,33,1,1,1
156,18,38,1,64,
156,18,43,1,1,2
156,18,46,4,0,
156,18,52,1,10,
156,18,53,1,46,
156,18,53,4,0,
156,18,92,4,0,
156,18,98,1,13,
156,18,104,4,0,
156,18,108,1,1,3
156,18,108,1,6,
156,18,111,1,24,
156,18,126,4,0,
156,18,129,1,31,
156,18,156,4,0,
156,18,164,4,0,
156,18,172,1,20,
156,18,182,4,0,
156,18,205,1,57,
156,18,207,4,0,
156,18,213,4,0,
156,18,214,4,0,
156,18,216,4,0,
156,18,218,4,0,
156,18,237,4,0,
156,18,241,4,0,
156,18,261,4,0,
156,18,263,4,0,
156,18,267,4,0,
156,18,280,4,0,
156,18,284,1,75,
156,18,315,4,0,
156,18,332,4,0,
156,18,436,1,42,
156,18,488,1,35,
156,18,488,4,0,
156,18,496,4,0,
156,18,517,1,53,
156,18,519,3,0,
156,18,526,4,0,
156,18,528,4,0,
156,18,590,4,0,
156,18,682,1,68,
157,3,7,4,0,
157,3,9,4,0,
157,3,15,4,0,
157,3,29,4,0,
157,3,33,1,1,1
157,3,43,1,1,2
157,3,46,4,0,
157,3,52,1,1,4
157,3,52,1,12,
157,3,53,1,60,
157,3,63,4,0,
157,3,70,4,0,
157,3,89,4,0,
157,3,91,4,0,
157,3,92,4,0,
157,3,98,1,21,
157,3,104,4,0,
157,3,108,1,1,3
157,3,108,1,6,
157,3,111,4,0,
157,3,126,4,0,
157,3,129,1,45,
157,3,129,4,0,
157,3,156,4,0,
157,3,172,1,31,
157,3,173,4,0,
157,3,174,4,0,
157,3,182,4,0,
157,3,189,4,0,
157,3,197,4,0,
157,3,203,4,0,
157,3,205,4,0,
157,3,207,4,0,
157,3,210,4,0,
157,3,213,4,0,
157,3,214,4,0,
157,3,216,4,0,
157,3,218,4,0,
157,3,223,4,0,
157,3,231,4,0,
157,3,237,4,0,
157,3,241,4,0,
157,3,249,4,0,
157,4,7,4,0,
157,4,9,4,0,
157,4,15,4,0,
157,4,29,4,0,
157,4,33,1,1,1
157,4,43,1,1,2
157,4,46,4,0,
157,4,52,1,1,4
157,4,52,1,12,
157,4,53,1,60,
157,4,53,3,0,
157,4,63,4,0,
157,4,70,4,0,
157,4,89,4,0,
157,4,91,4,0,
157,4,92,4,0,
157,4,98,1,21,
157,4,104,4,0,
157,4,108,1,1,3
157,4,108,1,6,
157,4,111,4,0,
157,4,126,4,0,
157,4,129,1,45,
157,4,129,4,0,
157,4,156,4,0,
157,4,172,1,31,
157,4,173,4,0,
157,4,174,4,0,
157,4,182,4,0,
157,4,189,4,0,
157,4,197,4,0,
157,4,203,4,0,
157,4,205,4,0,
157,4,207,4,0,
157,4,210,4,0,
157,4,213,4,0,
157,4,214,4,0,
157,4,216,4,0,
157,4,218,4,0,
157,4,223,4,0,
157,4,231,4,0,
157,4,237,4,0,
157,4,241,4,0,
157,4,249,4,0,
157,5,15,4,0,
157,5,33,1,1,1
157,5,43,1,1,2
157,5,46,4,0,
157,5,52,1,1,4
157,5,52,1,12,
157,5,53,1,60,
157,5,53,4,0,
157,5,63,4,0,
157,5,70,4,0,
157,5,89,4,0,
157,5,91,4,0,
157,5,92,4,0,
157,5,98,1,21,
157,5,104,4,0,
157,5,108,1,1,3
157,5,108,1,6,
157,5,126,4,0,
157,5,129,1,45,
157,5,156,4,0,
157,5,172,1,31,
157,5,182,4,0,
157,5,213,4,0,
157,5,216,4,0,
157,5,218,4,0,
157,5,237,4,0,
157,5,241,4,0,
157,5,249,4,0,
157,5,263,4,0,
157,5,264,4,0,
157,5,280,4,0,
157,5,290,4,0,
157,5,315,4,0,
157,5,332,4,0,
157,6,5,3,0,
157,6,7,3,0,
157,6,9,3,0,
157,6,15,4,0,
157,6,25,3,0,
157,6,33,1,1,1
157,6,34,3,0,
157,6,38,3,0,
157,6,43,1,1,2
157,6,46,4,0,
157,6,52,1,1,4
157,6,52,1,12,
157,6,53,1,60,
157,6,53,4,0,
157,6,63,4,0,
157,6,68,3,0,
157,6,69,3,0,
157,6,70,4,0,
157,6,89,4,0,
157,6,91,4,0,
157,6,92,4,0,
157,6,98,1,21,
157,6,102,3,0,
157,6,104,4,0,
157,6,108,1,1,3
157,6,108,1,6,
157,6,111,3,0,
157,6,126,4,0,
157,6,129,1,45,
157,6,129,3,0,
157,6,156,4,0,
157,6,157,3,0,
157,6,164,3,0,
157,6,172,1,31,
157,6,173,3,0,
157,6,182,4,0,
157,6,189,3,0,
157,6,203,3,0,
157,6,205,3,0,
157,6,207,3,0,
157,6,210,3,0,
157,6,213,4,0,
157,6,214,3,0,
157,6,216,4,0,
157,6,218,4,0,
157,6,223,3,0,
157,6,237,4,0,
157,6,241,4,0,
157,6,249,4,0,
157,6,263,4,0,
157,6,264,4,0,
157,6,280,4,0,
157,6,290,4,0,
157,6,315,4,0,
157,6,332,4,0,
157,7,5,3,0,
157,7,15,4,0,
157,7,25,3,0,
157,7,33,1,1,1
157,7,34,3,0,
157,7,38,3,0,
157,7,43,1,1,2
157,7,46,4,0,
157,7,52,1,1,4
157,7,52,1,12,
157,7,53,1,60,
157,7,53,4,0,
157,7,63,4,0,
157,7,68,3,0,
157,7,69,3,0,
157,7,70,4,0,
157,7,89,4,0,
157,7,91,4,0,
157,7,92,4,0,
157,7,98,1,21,
157,7,102,3,0,
157,7,104,4,0,
157,7,108,1,1,3
157,7,108,1,6,
157,7,126,4,0,
157,7,129,1,45,
157,7,156,4,0,
157,7,157,3,0,
157,7,164,3,0,
157,7,172,1,31,
157,7,182,4,0,
157,7,213,4,0,
157,7,216,4,0,
157,7,218,4,0,
157,7,237,4,0,
157,7,241,4,0,
157,7,249,4,0,
157,7,263,4,0,
157,7,264,4,0,
157,7,280,4,0,
157,7,290,4,0,
157,7,315,4,0,
157,7,332,4,0,
157,8,15,4,0,
157,8,33,1,1,2
157,8,38,1,53,
157,8,43,1,1,3
157,8,46,4,0,
157,8,52,1,1,5
157,8,52,1,10,
157,8,53,1,42,
157,8,53,4,0,
157,8,63,4,0,
157,8,70,4,0,
157,8,76,4,0,
157,8,89,4,0,
157,8,91,4,0,
157,8,92,4,0,
157,8,98,1,13,
157,8,104,4,0,
157,8,108,1,1,4
157,8,108,1,4,
157,8,111,1,24,
157,8,126,4,0,
157,8,129,1,31,
157,8,156,4,0,
157,8,157,4,0,
157,8,164,4,0,
157,8,172,1,20,
157,8,182,4,0,
157,8,203,4,0,
157,8,205,1,46,
157,8,207,4,0,
157,8,213,4,0,
157,8,214,4,0,
157,8,216,4,0,
157,8,218,4,0,
157,8,237,4,0,
157,8,241,4,0,
157,8,249,4,0,
157,8,261,4,0,
157,8,263,4,0,
157,8,264,4,0,
157,8,280,4,0,
157,8,284,1,57,
157,8,290,4,0,
157,8,307,3,0,
157,8,315,4,0,
157,8,317,4,0,
157,8,332,4,0,
157,8,360,1,1,1
157,8,360,4,0,
157,8,363,4,0,
157,8,374,4,0,
157,8,411,4,0,
157,8,416,4,0,
157,8,421,4,0,
157,8,431,4,0,
157,8,436,1,35,
157,8,445,4,0,
157,9,7,3,0,
157,9,9,3,0,
157,9,15,4,0,
157,9,33,1,1,2
157,9,38,1,53,
157,9,43,1,1,3
157,9,46,4,0,
157,9,52,1,1,5
157,9,52,1,10,
157,9,53,1,42,
157,9,53,4,0,
157,9,63,4,0,
157,9,70,4,0,
157,9,76,4,0,
157,9,89,4,0,
157,9,91,4,0,
157,9,92,4,0,
157,9,98,1,13,
157,9,104,4,0,
157,9,108,1,1,4
157,9,108,1,4,
157,9,111,1,24,
157,9,126,4,0,
157,9,129,1,31,
157,9,129,3,0,
157,9,156,4,0,
157,9,157,4,0,
157,9,164,4,0,
157,9,172,1,20,
157,9,173,3,0,
157,9,182,4,0,
157,9,189,3,0,
157,9,203,4,0,
157,9,205,1,46,
157,9,205,3,0,
157,9,207,4,0,
157,9,210,3,0,
157,9,213,4,0,
157,9,214,4,0,
157,9,216,4,0,
157,9,218,4,0,
157,9,237,4,0,
157,9,241,4,0,
157,9,249,4,0,
157,9,257,3,0,
157,9,261,4,0,
157,9,263,4,0,
157,9,264,4,0,
157,9,280,4,0,
157,9,284,1,57,
157,9,290,4,0,
157,9,307,3,0,
157,9,315,4,0,
157,9,317,4,0,
157,9,332,4,0,
157,9,360,1,1,1
157,9,360,4,0,
157,9,363,4,0,
157,9,374,4,0,
157,9,411,4,0,
157,9,416,4,0,
157,9,421,4,0,
157,9,431,4,0,
157,9,436,1,35,
157,9,445,4,0,
157,10,7,3,0,
157,10,9,3,0,
157,10,15,4,0,
157,10,29,3,0,
157,10,33,1,1,2
157,10,38,1,53,
157,10,43,1,1,3
157,10,46,4,0,
157,10,52,1,1,5
157,10,52,1,10,
157,10,53,1,42,
157,10,53,4,0,
157,10,63,4,0,
157,10,67,3,0,
157,10,70,4,0,
157,10,76,4,0,
157,10,89,4,0,
157,10,91,4,0,
157,10,92,4,0,
157,10,98,1,13,
157,10,104,4,0,
157,10,108,1,1,4
157,10,108,1,6,
157,10,111,1,24,
157,10,126,4,0,
157,10,129,1,31,
157,10,129,3,0,
157,10,156,4,0,
157,10,157,4,0,
157,10,164,4,0,
157,10,172,1,20,
157,10,173,3,0,
157,10,182,4,0,
157,10,189,3,0,
157,10,203,4,0,
157,10,205,1,46,
157,10,205,3,0,
157,10,207,4,0,
157,10,210,3,0,
157,10,213,4,0,
157,10,214,4,0,
157,10,216,4,0,
157,10,218,4,0,
157,10,237,4,0,
157,10,241,4,0,
157,10,249,4,0,
157,10,257,3,0,
157,10,261,4,0,
157,10,263,4,0,
157,10,264,4,0,
157,10,280,4,0,
157,10,284,1,57,
157,10,290,4,0,
157,10,307,3,0,
157,10,315,4,0,
157,10,317,4,0,
157,10,332,4,0,
157,10,360,1,1,1
157,10,360,4,0,
157,10,363,4,0,
157,10,374,4,0,
157,10,411,4,0,
157,10,416,4,0,
157,10,421,4,0,
157,10,431,4,0,
157,10,436,1,35,
157,10,445,4,0,
157,11,15,4,0,
157,11,33,1,1,2
157,11,38,1,69,
157,11,43,1,1,3
157,11,46,4,0,
157,11,52,1,1,5
157,11,52,1,10,
157,11,53,1,48,
157,11,53,4,0,
157,11,63,4,0,
157,11,70,4,0,
157,11,76,4,0,
157,11,89,4,0,
157,11,91,4,0,
157,11,92,4,0,
157,11,98,1,13,
157,11,104,4,0,
157,11,108,1,1,4
157,11,108,1,6,
157,11,111,1,24,
157,11,126,4,0,
157,11,129,1,31,
157,11,156,4,0,
157,11,157,4,0,
157,11,164,4,0,
157,11,172,1,20,
157,11,182,4,0,
157,11,205,1,61,
157,11,207,4,0,
157,11,213,4,0,
157,11,216,4,0,
157,11,218,4,0,
157,11,237,4,0,
157,11,241,4,0,
157,11,249,4,0,
157,11,261,4,0,
157,11,263,4,0,
157,11,280,4,0,
157,11,284,1,74,
157,11,307,3,0,
157,11,315,4,0,
157,11,317,4,0,
157,11,332,4,0,
157,11,360,1,1,1
157,11,360,4,0,
157,11,374,4,0,
157,11,411,4,0,
157,11,416,4,0,
157,11,421,4,0,
157,11,436,1,43,
157,11,488,1,35,
157,11,488,4,0,
157,11,496,4,0,
157,11,510,4,0,
157,11,517,1,56,
157,11,519,3,0,
157,11,523,4,0,
157,11,528,4,0,
157,12,15,4,0,
157,12,33,1,1,1
157,12,43,1,1,2
157,12,46,4,0,
157,12,52,1,1,4
157,12,52,1,12,
157,12,53,1,60,
157,12,53,4,0,
157,12,63,4,0,
157,12,70,4,0,
157,12,89,4,0,
157,12,91,4,0,
157,12,92,4,0,
157,12,98,1,21,
157,12,104,4,0,
157,12,108,1,1,3
157,12,108,1,6,
157,12,126,4,0,
157,12,129,1,45,
157,12,156,4,0,
157,12,172,1,31,
157,12,182,4,0,
157,12,213,4,0,
157,12,216,4,0,
157,12,218,4,0,
157,12,237,4,0,
157,12,241,4,0,
157,12,249,4,0,
157,12,263,4,0,
157,12,264,4,0,
157,12,280,4,0,
157,12,290,4,0,
157,12,315,4,0,
157,12,332,4,0,
157,13,15,4,0,
157,13,33,1,1,1
157,13,34,3,0,
157,13,38,3,0,
157,13,43,1,1,2
157,13,46,4,0,
157,13,52,1,1,4
157,13,52,1,12,
157,13,53,1,60,
157,13,53,4,0,
157,13,63,4,0,
157,13,69,3,0,
157,13,70,4,0,
157,13,89,4,0,
157,13,91,4,0,
157,13,92,4,0,
157,13,98,1,21,
157,13,102,3,0,
157,13,104,4,0,
157,13,108,1,1,3
157,13,108,1,6,
157,13,126,4,0,
157,13,129,1,45,
157,13,156,4,0,
157,13,164,3,0,
157,13,172,1,31,
157,13,182,4,0,
157,13,207,3,0,
157,13,213,4,0,
157,13,216,4,0,
157,13,218,4,0,
157,13,237,4,0,
157,13,241,4,0,
157,13,249,4,0,
157,13,263,4,0,
157,13,264,4,0,
157,13,280,4,0,
157,13,290,4,0,
157,13,315,4,0,
157,13,332,4,0,
157,14,7,3,0,
157,14,9,3,0,
157,14,15,4,0,
157,14,33,1,1,2
157,14,38,1,69,
157,14,43,1,1,3
157,14,46,4,0,
157,14,52,1,1,5
157,14,52,1,10,
157,14,53,1,48,
157,14,53,4,0,
157,14,63,4,0,
157,14,67,3,0,
157,14,70,4,0,
157,14,76,4,0,
157,14,89,4,0,
157,14,91,4,0,
157,14,92,4,0,
157,14,98,1,13,
157,14,104,4,0,
157,14,108,1,1,4
157,14,108,1,6,
157,14,111,1,24,
157,14,126,4,0,
157,14,129,1,31,
157,14,156,4,0,
157,14,157,4,0,
157,14,164,4,0,
157,14,172,1,20,
157,14,173,3,0,
157,14,182,4,0,
157,14,205,1,61,
157,14,207,4,0,
157,14,213,4,0,
157,14,214,3,0,
157,14,216,4,0,
157,14,218,4,0,
157,14,237,4,0,
157,14,241,4,0,
157,14,249,4,0,
157,14,257,3,0,
157,14,261,4,0,
157,14,263,4,0,
157,14,280,4,0,
157,14,284,1,74,
157,14,307,3,0,
157,14,315,4,0,
157,14,317,4,0,
157,14,332,4,0,
157,14,343,3,0,
157,14,360,1,1,1
157,14,360,4,0,
157,14,374,4,0,
157,14,411,4,0,
157,14,416,4,0,
157,14,421,4,0,
157,14,436,1,43,
157,14,488,1,35,
157,14,488,4,0,
157,14,496,4,0,
157,14,510,4,0,
157,14,517,1,56,
157,14,519,3,0,
157,14,523,4,0,
157,14,528,4,0,
157,15,15,4,0,
157,15,33,1,1,4
157,15,38,1,1,2
157,15,38,1,69,
157,15,43,1,1,5
157,15,46,4,0,
157,15,52,1,1,7
157,15,52,1,10,
157,15,53,1,48,
157,15,53,4,0,
157,15,63,4,0,
157,15,70,4,0,
157,15,76,4,0,
157,15,89,4,0,
157,15,91,4,0,
157,15,92,4,0,
157,15,98,1,13,
157,15,104,4,0,
157,15,108,1,1,6
157,15,108,1,6,
157,15,111,1,24,
157,15,126,4,0,
157,15,129,1,31,
157,15,156,4,0,
157,15,157,4,0,
157,15,164,4,0,
157,15,172,1,20,
157,15,182,4,0,
157,15,205,1,61,
157,15,207,4,0,
157,15,213,4,0,
157,15,214,4,0,
157,15,216,4,0,
157,15,218,4,0,
157,15,237,4,0,
157,15,241,4,0,
157,15,249,4,0,
157,15,261,4,0,
157,15,263,4,0,
157,15,267,4,0,
157,15,280,4,0,
157,15,284,1,1,1
157,15,284,1,74,
157,15,307,3,0,
157,15,315,4,0,
157,15,317,4,0,
157,15,332,4,0,
157,15,360,1,1,3
157,15,360,4,0,
157,15,374,4,0,
157,15,411,4,0,
157,15,416,4,0,
157,15,421,4,0,
157,15,436,1,43,
157,15,488,1,35,
157,15,488,4,0,
157,15,496,4,0,
157,15,510,4,0,
157,15,517,1,56,
157,15,519,3,0,
157,15,523,4,0,
157,15,528,4,0,
157,15,590,4,0,
157,15,612,4,0,
157,16,7,3,0,
157,16,9,3,0,
157,16,15,4,0,
157,16,33,1,1,4
157,16,38,1,1,2
157,16,38,1,69,1
157,16,43,1,1,5
157,16,46,4,0,
157,16,52,1,1,7
157,16,52,1,10,1
157,16,53,1,48,1
157,16,53,4,0,
157,16,63,4,0,
157,16,67,3,0,
157,16,70,4,0,
157,16,76,4,0,
157,16,89,4,0,
157,16,91,4,0,
157,16,92,4,0,
157,16,98,1,13,1
157,16,104,4,0,
157,16,108,1,1,6
157,16,108,1,6,1
157,16,111,1,24,1
157,16,126,4,0,
157,16,129,1,31,1
157,16,156,4,0,
157,16,157,4,0,
157,16,164,4,0,
157,16,172,1,20,1
157,16,173,3,0,
157,16,182,4,0,
157,16,205,1,61,1
157,16,207,4,0,
157,16,213,4,0,
157,16,214,4,0,
157,16,216,4,0,
157,16,218,4,0,
157,16,237,4,0,
157,16,241,4,0,
157,16,249,4,0,
157,16,257,3,0,
157,16,261,4,0,
157,16,263,4,0,
157,16,264,3,0,
157,16,267,4,0,
157,16,280,4,0,
157,16,284,1,1,1
157,16,284,1,74,1
157,16,290,4,0,
157,16,307,3,0,
157,16,315,4,0,
157,16,317,4,0,
157,16,332,4,0,
157,16,343,3,0,
157,16,360,1,1,3
157,16,360,4,0,
157,16,374,4,0,
157,16,411,4,0,
157,16,416,4,0,
157,16,421,4,0,
157,16,436,1,43,1
157,16,488,1,35,1
157,16,488,4,0,
157,16,496,4,0,
157,16,510,4,0,
157,16,517,1,56,1
157,16,519,3,0,
157,16,523,4,0,
157,16,528,4,0,
157,16,590,4,0,
157,16,612,4,0,
157,17,33,1,1,4
157,17,38,1,1,2
157,17,38,1,69,
157,17,43,1,1,5
157,17,46,4,0,
157,17,52,1,1,7
157,17,52,1,10,
157,17,53,1,48,
157,17,53,4,0,
157,17,63,4,0,
157,17,76,4,0,
157,17,89,4,0,
157,17,92,4,0,
157,17,98,1,13,
157,17,104,4,0,
157,17,108,1,1,6
157,17,108,1,6,
157,17,111,1,24,
157,17,126,4,0,
157,17,129,1,31,
157,17,156,4,0,
157,17,157,4,0,
157,17,164,4,0,
157,17,172,1,20,
157,17,182,4,0,
157,17,205,1,61,
157,17,207,4,0,
157,17,213,4,0,
157,17,214,4,0,
157,17,216,4,0,
157,17,218,4,0,
157,17,237,4,0,
157,17,241,4,0,
157,17,261,4,0,
157,17,263,4,0,
157,17,267,4,0,
157,17,280,4,0,
157,17,284,1,1,1
157,17,284,1,82,
157,17,307,3,0,
157,17,315,4,0,
157,17,317,4,0,
157,17,332,4,0,
157,17,360,1,1,3
157,17,360,4,0,
157,17,374,4,0,
157,17,411,4,0,
157,17,416,4,0,
157,17,421,4,0,
157,17,436,1,43,
157,17,488,1,35,
157,17,488,4,0,
157,17,496,4,0,
157,17,517,1,56,
157,17,519,3,0,
157,17,523,4,0,
157,17,526,4,0,
157,17,528,4,0,
157,17,590,4,0,
157,17,682,1,74,
157,18,33,1,1,4
157,18,38,1,1,2
157,18,38,1,69,
157,18,43,1,1,5
157,18,46,4,0,
157,18,52,1,1,7
157,18,52,1,10,
157,18,53,1,48,
157,18,53,4,0,
157,18,63,4,0,
157,18,76,4,0,
157,18,89,4,0,
157,18,92,4,0,
157,18,98,1,13,
157,18,104,4,0,
157,18,108,1,1,6
157,18,108,1,6,
157,18,111,1,24,
157,18,126,4,0,
157,18,129,1,31,
157,18,156,4,0,
157,18,157,4,0,
157,18,164,4,0,
157,18,172,1,20,
157,18,182,4,0,
157,18,205,1,61,
157,18,207,4,0,
157,18,213,4,0,
157,18,214,4,0,
157,18,216,4,0,
157,18,218,4,0,
157,18,237,4,0,
157,18,241,4,0,
157,18,261,4,0,
157,18,263,4,0,
157,18,267,4,0,
157,18,280,4,0,
157,18,284,1,1,1
157,18,284,1,82,
157,18,307,3,0,
157,18,315,4,0,
157,18,317,4,0,
157,18,332,4,0,
157,18,360,1,1,3
157,18,360,4,0,
157,18,374,4,0,
157,18,411,4,0,
157,18,416,4,0,
157,18,421,4,0,
157,18,436,1,43,
157,18,488,1,35,
157,18,488,4,0,
157,18,496,4,0,
157,18,517,1,56,
157,18,519,3,0,
157,18,523,4,0,
157,18,526,4,0,
157,18,528,4,0,
157,18,590,4,0,
157,18,682,1,74,
158,3,8,4,0,
158,3,10,1,1,1
158,3,13,2,0,
158,3,15,4,0,
158,3,29,4,0,
158,3,37,2,0,
158,3,43,1,1,2
158,3,44,1,20,
158,3,55,1,13,
158,3,56,1,52,
158,3,56,2,0,
158,3,57,4,0,
158,3,59,4,0,
158,3,91,4,0,
158,3,92,4,0,
158,3,99,1,7,
158,3,103,1,43,
158,3,104,4,0,
158,3,156,4,0,
158,3,157,2,0,
158,3,163,1,35,
158,3,173,4,0,
158,3,174,4,0,
158,3,182,4,0,
158,3,184,1,27,
158,3,189,4,0,
158,3,196,4,0,
158,3,197,4,0,
158,3,203,4,0,
158,3,207,4,0,
158,3,213,4,0,
158,3,214,4,0,
158,3,216,4,0,
158,3,218,4,0,
158,3,223,4,0,
158,3,231,4,0,
158,3,237,4,0,
158,3,240,4,0,
158,3,242,2,0,
158,3,246,2,0,
158,3,250,4,0,
158,4,8,4,0,
158,4,10,1,1,1
158,4,13,2,0,
158,4,15,4,0,
158,4,29,4,0,
158,4,37,2,0,
158,4,43,1,1,2
158,4,44,1,20,
158,4,55,1,13,
158,4,56,1,52,
158,4,56,2,0,
158,4,57,4,0,
158,4,58,3,0,
158,4,59,4,0,
158,4,91,4,0,
158,4,92,4,0,
158,4,99,1,7,
158,4,103,1,43,
158,4,104,4,0,
158,4,156,4,0,
158,4,157,2,0,
158,4,163,1,35,
158,4,173,4,0,
158,4,174,4,0,
158,4,182,4,0,
158,4,184,1,27,
158,4,189,4,0,
158,4,196,4,0,
158,4,197,4,0,
158,4,203,4,0,
158,4,207,4,0,
158,4,213,4,0,
158,4,214,4,0,
158,4,216,4,0,
158,4,218,4,0,
158,4,223,4,0,
158,4,231,4,0,
158,4,237,4,0,
158,4,240,4,0,
158,4,242,2,0,
158,4,246,2,0,
158,4,250,4,0,
158,5,10,1,1,1
158,5,15,4,0,
158,5,37,2,0,
158,5,43,1,1,2
158,5,44,1,20,
158,5,55,1,13,
158,5,56,1,52,
158,5,56,2,0,
158,5,57,4,0,
158,5,58,4,0,
158,5,59,4,0,
158,5,91,4,0,
158,5,92,4,0,
158,5,99,1,7,
158,5,103,1,43,
158,5,104,4,0,
158,5,127,4,0,
158,5,156,4,0,
158,5,157,2,0,
158,5,163,1,35,
158,5,182,4,0,
158,5,184,1,27,
158,5,213,4,0,
158,5,216,4,0,
158,5,218,4,0,
158,5,231,4,0,
158,5,237,4,0,
158,5,240,4,0,
158,5,242,2,0,
158,5,246,2,0,
158,5,258,4,0,
158,5,263,4,0,
158,5,264,4,0,
158,5,280,4,0,
158,5,290,4,0,
158,5,291,4,0,
158,5,300,2,0,
158,5,332,4,0,
158,5,337,2,0,
158,5,346,2,0,
158,5,352,4,0,
158,6,5,3,0,
158,6,8,3,0,
158,6,10,1,1,1
158,6,14,3,0,
158,6,15,4,0,
158,6,25,3,0,
158,6,34,3,0,
158,6,37,2,0,
158,6,38,3,0,
158,6,43,1,1,2
158,6,44,1,20,
158,6,55,1,13,
158,6,56,1,52,
158,6,56,2,0,
158,6,57,4,0,
158,6,58,4,0,
158,6,59,4,0,
158,6,68,3,0,
158,6,69,3,0,
158,6,91,4,0,
158,6,92,4,0,
158,6,99,1,7,
158,6,102,3,0,
158,6,103,1,43,
158,6,104,4,0,
158,6,127,4,0,
158,6,156,4,0,
158,6,157,2,0,
158,6,157,3,0,
158,6,163,1,35,
158,6,164,3,0,
158,6,173,3,0,
158,6,182,4,0,
158,6,184,1,27,
158,6,189,3,0,
158,6,196,3,0,
158,6,203,3,0,
158,6,207,3,0,
158,6,213,4,0,
158,6,214,3,0,
158,6,216,4,0,
158,6,218,4,0,
158,6,223,3,0,
158,6,231,4,0,
158,6,237,4,0,
158,6,240,4,0,
158,6,242,2,0,
158,6,246,2,0,
158,6,258,4,0,
158,6,263,4,0,
158,6,264,4,0,
158,6,280,4,0,
158,6,290,4,0,
158,6,291,4,0,
158,6,300,2,0,
158,6,332,4,0,
158,6,337,2,0,
158,6,346,2,0,
158,6,352,4,0,
158,7,5,3,0,
158,7,10,1,1,1
158,7,14,3,0,
158,7,15,4,0,
158,7,25,3,0,
158,7,34,3,0,
158,7,37,2,0,
158,7,38,3,0,
158,7,43,1,1,2
158,7,44,1,20,
158,7,55,1,13,
158,7,56,1,52,
158,7,56,2,0,
158,7,57,4,0,
158,7,58,4,0,
158,7,59,4,0,
158,7,68,3,0,
158,7,69,3,0,
158,7,91,4,0,
158,7,92,4,0,
158,7,99,1,7,
158,7,102,3,0,
158,7,103,1,43,
158,7,104,4,0,
158,7,127,4,0,
158,7,156,4,0,
158,7,157,2,0,
158,7,157,3,0,
158,7,163,1,35,
158,7,164,3,0,
158,7,182,4,0,
158,7,184,1,27,
158,7,213,4,0,
158,7,216,4,0,
158,7,218,4,0,
158,7,231,4,0,
158,7,237,4,0,
158,7,240,4,0,
158,7,242,2,0,
158,7,246,2,0,
158,7,258,4,0,
158,7,263,4,0,
158,7,264,4,0,
158,7,280,4,0,
158,7,290,4,0,
158,7,291,4,0,
158,7,300,2,0,
158,7,332,4,0,
158,7,337,2,0,
158,7,346,2,0,
158,7,352,4,0,
158,8,8,2,0,
158,8,10,1,1,1
158,8,14,4,0,
158,8,15,4,0,
158,8,37,1,22,
158,8,37,2,0,
158,8,43,1,1,2
158,8,44,1,13,
158,8,55,1,6,
158,8,56,1,43,
158,8,56,2,0,
158,8,57,4,0,
158,8,58,4,0,
158,8,59,4,0,
158,8,91,4,0,
158,8,92,4,0,
158,8,99,1,8,
158,8,103,1,34,
158,8,104,4,0,
158,8,127,4,0,
158,8,156,4,0,
158,8,157,2,0,
158,8,157,4,0,
158,8,163,1,29,
158,8,164,4,0,
158,8,182,4,0,
158,8,184,1,15,
158,8,203,4,0,
158,8,207,4,0,
158,8,213,4,0,
158,8,214,4,0,
158,8,216,4,0,
158,8,218,4,0,
158,8,231,4,0,
158,8,232,2,0,
158,8,237,4,0,
158,8,240,4,0,
158,8,242,1,27,
158,8,242,2,0,
158,8,246,2,0,
158,8,258,4,0,
158,8,263,4,0,
158,8,264,4,0,
158,8,276,1,41,
158,8,280,4,0,
158,8,290,4,0,
158,8,300,2,0,
158,8,317,4,0,
158,8,332,4,0,
158,8,337,2,0,
158,8,346,2,0,
158,8,349,2,0,
158,8,352,4,0,
158,8,363,4,0,
158,8,374,4,0,
158,8,401,1,36,
158,8,421,4,0,
158,8,423,1,20,
158,8,445,4,0,
158,9,8,2,0,
158,9,8,3,0,
158,9,10,1,1,1
158,9,14,4,0,
158,9,15,4,0,
158,9,37,1,36,
158,9,37,2,0,
158,9,43,1,1,2
158,9,44,1,13,
158,9,55,1,6,
158,9,56,1,48,
158,9,56,2,0,
158,9,57,4,0,
158,9,58,4,0,
158,9,59,4,0,
158,9,91,4,0,
158,9,92,4,0,
158,9,99,1,8,
158,9,103,1,34,
158,9,104,4,0,
158,9,127,4,0,
158,9,156,4,0,
158,9,157,2,0,
158,9,157,4,0,
158,9,163,1,29,
158,9,164,4,0,
158,9,173,3,0,
158,9,175,1,22,
158,9,180,3,0,
158,9,182,4,0,
158,9,184,1,15,
158,9,189,3,0,
158,9,196,3,0,
158,9,203,4,0,
158,9,207,4,0,
158,9,213,4,0,
158,9,214,4,0,
158,9,216,4,0,
158,9,218,4,0,
158,9,231,4,0,
158,9,232,2,0,
158,9,237,4,0,
158,9,240,4,0,
158,9,242,1,27,
158,9,242,2,0,
158,9,246,2,0,
158,9,246,3,0,
158,9,253,3,0,
158,9,258,4,0,
158,9,263,4,0,
158,9,264,4,0,
158,9,276,1,43,
158,9,276,3,0,
158,9,280,4,0,
158,9,290,4,0,
158,9,291,3,0,
158,9,300,2,0,
158,9,317,4,0,
158,9,332,4,0,
158,9,337,2,0,
158,9,346,2,0,
158,9,349,2,0,
158,9,352,4,0,
158,9,363,4,0,
158,9,374,4,0,
158,9,401,1,41,
158,9,401,3,0,
158,9,421,4,0,
158,9,423,1,20,
158,9,445,4,0,
158,10,8,2,0,
158,10,8,3,0,
158,10,10,1,1,1
158,10,14,4,0,
158,10,15,4,0,
158,10,29,3,0,
158,10,37,1,36,
158,10,37,2,0,
158,10,43,1,1,2
158,10,44,1,13,
158,10,55,1,6,
158,10,56,1,48,
158,10,56,2,0,
158,10,57,4,0,
158,10,58,4,0,
158,10,59,4,0,
158,10,67,3,0,
158,10,91,4,0,
158,10,92,4,0,
158,10,99,1,8,
158,10,103,1,34,
158,10,104,4,0,
158,10,127,4,0,
158,10,156,4,0,
158,10,157,2,0,
158,10,157,4,0,
158,10,163,1,29,
158,10,164,4,0,
158,10,173,3,0,
158,10,175,1,22,
158,10,180,3,0,
158,10,182,4,0,
158,10,184,1,15,
158,10,189,3,0,
158,10,196,3,0,
158,10,203,4,0,
158,10,207,4,0,
158,10,213,4,0,
158,10,214,4,0,
158,10,216,4,0,
158,10,218,4,0,
158,10,231,4,0,
158,10,232,2,0,
158,10,237,4,0,
158,10,240,4,0,
158,10,242,1,27,
158,10,242,2,0,
158,10,246,2,0,
158,10,246,3,0,
158,10,250,4,0,
158,10,253,3,0,
158,10,258,4,0,
158,10,263,4,0,
158,10,264,4,0,
158,10,276,1,43,
158,10,276,3,0,
158,10,280,4,0,
158,10,290,4,0,
158,10,291,3,0,
158,10,300,2,0,
158,10,317,4,0,
158,10,332,4,0,
158,10,337,2,0,
158,10,346,2,0,
158,10,349,2,0,
158,10,352,4,0,
158,10,363,4,0,
158,10,374,4,0,
158,10,401,1,41,
158,10,401,3,0,
158,10,421,4,0,
158,10,423,1,20,
158,10,445,4,0,
158,10,453,2,0,
158,11,8,2,0,
158,11,10,1,1,1
158,11,14,4,0,
158,11,15,4,0,
158,11,37,1,41,
158,11,37,2,0,
158,11,43,1,1,2
158,11,44,1,13,
158,11,55,1,6,
158,11,56,1,50,
158,11,56,2,0,
158,11,57,4,0,
158,11,58,4,0,
158,11,59,4,0,
158,11,91,4,0,
158,11,92,4,0,
158,11,99,1,8,
158,11,103,1,36,
158,11,104,4,0,
158,11,127,4,0,
158,11,156,4,0,
158,11,157,4,0,
158,11,163,1,34,
158,11,164,4,0,
158,11,175,1,22,
158,11,182,4,0,
158,11,184,1,15,
158,11,207,4,0,
158,11,213,4,0,
158,11,216,4,0,
158,11,218,4,0,
158,11,232,2,0,
158,11,237,4,0,
158,11,240,4,0,
158,11,242,1,27,
158,11,242,2,0,
158,11,246,2,0,
158,11,258,4,0,
158,11,263,4,0,
158,11,276,1,48,
158,11,280,4,0,
158,11,291,4,0,
158,11,300,2,0,
158,11,313,2,0,
158,11,317,4,0,
158,11,332,4,0,
158,11,335,2,0,
158,11,337,4,0,
158,11,346,2,0,
158,11,349,2,0,
158,11,352,2,0,
158,11,374,4,0,
158,11,401,1,43,
158,11,421,4,0,
158,11,423,1,20,
158,11,453,2,0,
158,11,468,4,0,
158,11,496,4,0,
158,11,498,1,29,
158,11,503,4,0,
158,11,518,3,0,
158,12,10,1,1,1
158,12,15,4,0,
158,12,43,1,1,2
158,12,44,1,20,
158,12,55,1,13,
158,12,56,1,52,
158,12,57,4,0,
158,12,58,4,0,
158,12,59,4,0,
158,12,91,4,0,
158,12,92,4,0,
158,12,99,1,7,
158,12,103,1,43,
158,12,104,4,0,
158,12,127,4,0,
158,12,156,4,0,
158,12,163,1,35,
158,12,182,4,0,
158,12,184,1,27,
158,12,213,4,0,
158,12,216,4,0,
158,12,218,4,0,
158,12,231,4,0,
158,12,237,4,0,
158,12,240,4,0,
158,12,258,4,0,
158,12,263,4,0,
158,12,264,4,0,
158,12,280,4,0,
158,12,290,4,0,
158,12,291,4,0,
158,12,332,4,0,
158,12,352,4,0,
158,13,10,1,1,1
158,13,15,4,0,
158,13,34,3,0,
158,13,38,3,0,
158,13,43,1,1,2
158,13,44,1,20,
158,13,55,1,13,
158,13,56,1,52,
158,13,57,4,0,
158,13,58,4,0,
158,13,59,4,0,
158,13,69,3,0,
158,13,91,4,0,
158,13,92,4,0,
158,13,99,1,7,
158,13,102,3,0,
158,13,103,1,43,
158,13,104,4,0,
158,13,127,4,0,
158,13,156,4,0,
158,13,163,1,35,
158,13,164,3,0,
158,13,182,4,0,
158,13,184,1,27,
158,13,196,3,0,
158,13,207,3,0,
158,13,213,4,0,
158,13,216,4,0,
158,13,218,4,0,
158,13,231,4,0,
158,13,237,4,0,
158,13,240,4,0,
158,13,258,4,0,
158,13,263,4,0,
158,13,264,4,0,
158,13,280,4,0,
158,13,290,4,0,
158,13,291,4,0,
158,13,332,4,0,
158,13,352,4,0,
158,14,8,2,0,
158,14,8,3,0,
158,14,10,1,1,1
158,14,14,4,0,
158,14,15,4,0,
158,14,37,1,41,
158,14,37,2,0,
158,14,43,1,1,2
158,14,44,1,13,
158,14,55,1,6,
158,14,56,1,50,
158,14,56,2,0,
158,14,57,4,0,
158,14,58,4,0,
158,14,59,4,0,
158,14,67,3,0,
158,14,91,4,0,
158,14,92,4,0,
158,14,99,1,8,
158,14,103,1,36,
158,14,104,4,0,
158,14,127,4,0,
158,14,156,4,0,
158,14,157,4,0,
158,14,163,1,34,
158,14,164,4,0,
158,14,173,3,0,
158,14,175,1,22,
158,14,180,3,0,
158,14,182,4,0,
158,14,184,1,15,
158,14,196,3,0,
158,14,207,4,0,
158,14,213,4,0,
158,14,214,3,0,
158,14,216,4,0,
158,14,218,4,0,
158,14,231,3,0,
158,14,232,2,0,
158,14,237,4,0,
158,14,240,4,0,
158,14,242,1,27,
158,14,242,2,0,
158,14,246,2,0,
158,14,253,3,0,
158,14,258,4,0,
158,14,263,4,0,
158,14,276,1,48,
158,14,276,3,0,
158,14,280,4,0,
158,14,291,4,0,
158,14,300,2,0,
158,14,313,2,0,
158,14,317,4,0,
158,14,332,4,0,
158,14,335,2,0,
158,14,335,3,0,
158,14,337,4,0,
158,14,346,2,0,
158,14,349,2,0,
158,14,352,2,0,
158,14,374,4,0,
158,14,401,1,43,
158,14,401,3,0,
158,14,421,4,0,
158,14,423,1,20,
158,14,453,2,0,
158,14,468,4,0,
158,14,496,4,0,
158,14,498,1,29,
158,14,503,4,0,
158,14,518,3,0,
158,15,8,2,0,
158,15,10,1,1,1
158,15,14,4,0,
158,15,15,4,0,
158,15,37,1,41,
158,15,37,2,0,
158,15,43,1,1,2
158,15,44,1,13,
158,15,55,1,6,
158,15,56,1,50,
158,15,56,2,0,
158,15,57,4,0,
158,15,58,4,0,
158,15,59,4,0,
158,15,91,4,0,
158,15,92,4,0,
158,15,99,1,8,
158,15,103,1,36,
158,15,104,4,0,
158,15,127,4,0,
158,15,156,4,0,
158,15,157,4,0,
158,15,163,1,34,
158,15,164,4,0,
158,15,175,1,22,
158,15,182,4,0,
158,15,184,1,15,
158,15,207,4,0,
158,15,213,4,0,
158,15,214,4,0,
158,15,216,4,0,
158,15,218,4,0,
158,15,232,2,0,
158,15,237,4,0,
158,15,240,4,0,
158,15,242,1,27,
158,15,242,2,0,
158,15,246,2,0,
158,15,258,4,0,
158,15,260,2,0,
158,15,263,4,0,
158,15,276,1,48,
158,15,280,4,0,
158,15,300,2,0,
158,15,313,2,0,
158,15,317,4,0,
158,15,332,4,0,
158,15,335,2,0,
158,15,337,4,0,
158,15,346,2,0,
158,15,349,2,0,
158,15,352,2,0,
158,15,374,4,0,
158,15,401,1,43,
158,15,421,4,0,
158,15,423,1,20,
158,15,453,2,0,
158,15,468,4,0,
158,15,496,4,0,
158,15,498,1,29,
158,15,503,4,0,
158,15,518,3,0,
158,15,590,4,0,
158,15,612,4,0,
158,16,8,2,0,
158,16,8,3,0,
158,16,10,1,1,1
158,16,14,4,0,
158,16,15,4,0,
158,16,37,1,41,1
158,16,37,2,0,
158,16,43,1,1,2
158,16,44,1,13,1
158,16,55,1,6,1
158,16,56,1,50,1
158,16,56,2,0,
158,16,57,4,0,
158,16,58,4,0,
158,16,59,4,0,
158,16,67,3,0,
158,16,91,4,0,
158,16,92,4,0,
158,16,99,1,8,1
158,16,103,1,36,1
158,16,104,4,0,
158,16,127,4,0,
158,16,156,4,0,
158,16,157,4,0,
158,16,163,1,34,1
158,16,164,4,0,
158,16,173,3,0,
158,16,175,1,22,1
158,16,180,3,0,
158,16,182,4,0,
158,16,184,1,15,1
158,16,196,3,0,
158,16,207,4,0,
158,16,213,4,0,
158,16,214,4,0,
158,16,216,4,0,
158,16,218,4,0,
158,16,231,3,0,
158,16,232,2,0,
158,16,237,4,0,
158,16,240,4,0,
158,16,242,1,27,1
158,16,242,2,0,
158,16,246,2,0,
158,16,253,3,0,
158,16,258,4,0,
158,16,260,2,0,
158,16,263,4,0,
158,16,264,3,0,
158,16,276,1,48,1
158,16,276,3,0,
158,16,280,4,0,
158,16,290,4,0,
158,16,291,4,0,
158,16,300,2,0,
158,16,313,2,0,
158,16,317,4,0,
158,16,332,4,0,
158,16,335,2,0,
158,16,335,3,0,
158,16,337,4,0,
158,16,346,2,0,
158,16,349,2,0,
158,16,352,2,0,
158,16,352,3,0,
158,16,374,4,0,
158,16,401,1,43,1
158,16,401,3,0,
158,16,421,4,0,
158,16,423,1,20,1
158,16,453,2,0,
158,16,468,4,0,
158,16,496,4,0,
158,16,498,1,29,1
158,16,503,4,0,
158,16,518,3,0,
158,16,590,4,0,
158,16,612,4,0,
158,17,8,2,0,
158,17,10,1,1,1
158,17,14,4,0,
158,17,37,1,41,
158,17,37,2,0,
158,17,43,1,1,2
158,17,44,1,13,
158,17,55,1,6,
158,17,56,1,50,
158,17,56,2,0,
158,17,57,4,0,
158,17,58,4,0,
158,17,59,4,0,
158,17,92,4,0,
158,17,99,1,8,
158,17,103,1,36,
158,17,104,4,0,
158,17,127,4,0,
158,17,156,4,0,
158,17,157,4,0,
158,17,163,1,34,
158,17,164,4,0,
158,17,175,1,22,
158,17,182,4,0,
158,17,184,1,15,
158,17,207,4,0,
158,17,213,4,0,
158,17,214,4,0,
158,17,216,4,0,
158,17,218,4,0,
158,17,232,2,0,
158,17,237,4,0,
158,17,240,4,0,
158,17,242,1,27,
158,17,242,2,0,
158,17,246,2,0,
158,17,258,4,0,
158,17,260,2,0,
158,17,263,4,0,
158,17,276,1,48,
158,17,280,4,0,
158,17,300,2,0,
158,17,313,2,0,
158,17,317,4,0,
158,17,332,4,0,
158,17,335,2,0,
158,17,337,4,0,
158,17,346,2,0,
158,17,349,2,0,
158,17,352,2,0,
158,17,374,4,0,
158,17,401,1,43,
158,17,421,4,0,
158,17,423,1,20,
158,17,453,2,0,
158,17,496,4,0,
158,17,498,1,29,
158,17,503,4,0,
158,17,518,3,0,
158,17,526,4,0,
158,17,590,4,0,
158,18,8,2,0,
158,18,10,1,1,1
158,18,14,4,0,
158,18,37,1,41,
158,18,37,2,0,
158,18,43,1,1,2
158,18,44,1,13,
158,18,55,1,6,
158,18,56,1,50,
158,18,56,2,0,
158,18,57,4,0,
158,18,58,4,0,
158,18,59,4,0,
158,18,92,4,0,
158,18,99,1,8,
158,18,103,1,36,
158,18,104,4,0,
158,18,127,4,0,
158,18,156,4,0,
158,18,157,4,0,
158,18,163,1,34,
158,18,164,4,0,
158,18,175,1,22,
158,18,182,4,0,
158,18,184,1,15,
158,18,207,4,0,
158,18,213,4,0,
158,18,214,4,0,
158,18,216,4,0,
158,18,218,4,0,
158,18,232,2,0,
158,18,237,4,0,
158,18,240,4,0,
158,18,242,1,27,
158,18,242,2,0,
158,18,246,2,0,
158,18,258,4,0,
158,18,260,2,0,
158,18,263,4,0,
158,18,276,1,48,
158,18,280,4,0,
158,18,300,2,0,
158,18,313,2,0,
158,18,317,4,0,
158,18,332,4,0,
158,18,335,2,0,
158,18,337,4,0,
158,18,346,2,0,
158,18,349,2,0,
158,18,352,2,0,
158,18,374,4,0,
158,18,401,1,43,
158,18,421,4,0,
158,18,423,1,20,
158,18,453,2,0,
158,18,496,4,0,
158,18,498,1,29,
158,18,503,4,0,
158,18,518,3,0,
158,18,526,4,0,
158,18,590,4,0,
159,3,8,4,0,
159,3,10,1,1,1
159,3,15,4,0,
159,3,29,4,0,
159,3,43,1,1,2
159,3,44,1,21,
159,3,46,4,0,
159,3,55,1,13,
159,3,56,1,55,
159,3,57,4,0,
159,3,59,4,0,
159,3,70,4,0,
159,3,91,4,0,
159,3,92,4,0,
159,3,99,1,1,3
159,3,99,1,7,
159,3,103,1,45,
159,3,104,4,0,
159,3,156,4,0,
159,3,163,1,37,
159,3,173,4,0,
159,3,174,4,0,
159,3,182,4,0,
159,3,184,1,28,
159,3,189,4,0,
159,3,196,4,0,
159,3,197,4,0,
159,3,203,4,0,
159,3,207,4,0,
159,3,210,4,0,
159,3,213,4,0,
159,3,214,4,0,
159,3,216,4,0,
159,3,218,4,0,
159,3,223,4,0,
159,3,231,4,0,
159,3,237,4,0,
159,3,240,4,0,
159,3,249,4,0,
159,3,250,4,0,
159,4,8,4,0,
159,4,10,1,1,1
159,4,15,4,0,
159,4,29,4,0,
159,4,43,1,1,2
159,4,44,1,21,
159,4,46,4,0,
159,4,55,1,13,
159,4,56,1,55,
159,4,57,4,0,
159,4,58,3,0,
159,4,59,4,0,
159,4,70,4,0,
159,4,91,4,0,
159,4,92,4,0,
159,4,99,1,1,3
159,4,99,1,7,
159,4,103,1,45,
159,4,104,4,0,
159,4,156,4,0,
159,4,163,1,37,
159,4,173,4,0,
159,4,174,4,0,
159,4,182,4,0,
159,4,184,1,28,
159,4,189,4,0,
159,4,196,4,0,
159,4,197,4,0,
159,4,203,4,0,
159,4,207,4,0,
159,4,210,4,0,
159,4,213,4,0,
159,4,214,4,0,
159,4,216,4,0,
159,4,218,4,0,
159,4,223,4,0,
159,4,231,4,0,
159,4,237,4,0,
159,4,240,4,0,
159,4,249,4,0,
159,4,250,4,0,
159,5,10,1,1,1
159,5,15,4,0,
159,5,43,1,1,2
159,5,44,1,21,
159,5,46,4,0,
159,5,55,1,13,
159,5,56,1,55,
159,5,57,4,0,
159,5,58,4,0,
159,5,59,4,0,
159,5,70,4,0,
159,5,91,4,0,
159,5,92,4,0,
159,5,99,1,1,3
159,5,99,1,7,
159,5,103,1,45,
159,5,104,4,0,
159,5,127,4,0,
159,5,156,4,0,
159,5,163,1,37,
159,5,182,4,0,
159,5,184,1,28,
159,5,213,4,0,
159,5,216,4,0,
159,5,218,4,0,
159,5,231,4,0,
159,5,237,4,0,
159,5,240,4,0,
159,5,249,4,0,
159,5,258,4,0,
159,5,263,4,0,
159,5,264,4,0,
159,5,280,4,0,
159,5,290,4,0,
159,5,291,4,0,
159,5,332,4,0,
159,5,352,4,0,
159,6,5,3,0,
159,6,8,3,0,
159,6,10,1,1,1
159,6,14,3,0,
159,6,15,4,0,
159,6,25,3,0,
159,6,34,3,0,
159,6,38,3,0,
159,6,43,1,1,2
159,6,44,1,21,
159,6,46,4,0,
159,6,55,1,13,
159,6,56,1,55,
159,6,57,4,0,
159,6,58,4,0,
159,6,59,4,0,
159,6,68,3,0,
159,6,69,3,0,
159,6,70,4,0,
159,6,91,4,0,
159,6,92,4,0,
159,6,99,1,1,3
159,6,99,1,7,
159,6,102,3,0,
159,6,103,1,45,
159,6,104,4,0,
159,6,127,4,0,
159,6,156,4,0,
159,6,157,3,0,
159,6,163,1,37,
159,6,164,3,0,
159,6,173,3,0,
159,6,182,4,0,
159,6,184,1,28,
159,6,189,3,0,
159,6,196,3,0,
159,6,203,3,0,
159,6,207,3,0,
159,6,210,3,0,
159,6,213,4,0,
159,6,214,3,0,
159,6,216,4,0,
159,6,218,4,0,
159,6,223,3,0,
159,6,231,4,0,
159,6,237,4,0,
159,6,240,4,0,
159,6,249,4,0,
159,6,258,4,0,
159,6,263,4,0,
159,6,264,4,0,
159,6,280,4,0,
159,6,290,4,0,
159,6,291,4,0,
159,6,332,4,0,
159,6,352,4,0,
159,7,5,3,0,
159,7,10,1,1,1
159,7,14,3,0,
159,7,15,4,0,
159,7,25,3,0,
159,7,34,3,0,
159,7,38,3,0,
159,7,43,1,1,2
159,7,44,1,21,
159,7,46,4,0,
159,7,55,1,13,
159,7,56,1,55,
159,7,57,4,0,
159,7,58,4,0,
159,7,59,4,0,
159,7,68,3,0,
159,7,69,3,0,
159,7,70,4,0,
159,7,91,4,0,
159,7,92,4,0,
159,7,99,1,1,3
159,7,99,1,7,
159,7,102,3,0,
159,7,103,1,45,
159,7,104,4,0,
159,7,127,4,0,
159,7,156,4,0,
159,7,157,3,0,
159,7,163,1,37,
159,7,164,3,0,
159,7,182,4,0,
159,7,184,1,28,
159,7,213,4,0,
159,7,216,4,0,
159,7,218,4,0,
159,7,231,4,0,
159,7,237,4,0,
159,7,240,4,0,
159,7,249,4,0,
159,7,258,4,0,
159,7,263,4,0,
159,7,264,4,0,
159,7,280,4,0,
159,7,290,4,0,
159,7,291,4,0,
159,7,332,4,0,
159,7,352,4,0,
159,8,10,1,1,1
159,8,14,4,0,
159,8,15,4,0,
159,8,37,1,24,
159,8,43,1,1,2
159,8,44,1,13,
159,8,46,4,0,
159,8,55,1,1,3
159,8,55,1,6,
159,8,56,1,51,
159,8,57,4,0,
159,8,58,4,0,
159,8,59,4,0,
159,8,70,4,0,
159,8,91,4,0,
159,8,92,4,0,
159,8,99,1,8,
159,8,103,1,39,
159,8,104,4,0,
159,8,127,4,0,
159,8,156,4,0,
159,8,157,4,0,
159,8,163,1,33,
159,8,164,4,0,
159,8,182,4,0,
159,8,184,1,15,
159,8,203,4,0,
159,8,207,4,0,
159,8,213,4,0,
159,8,214,4,0,
159,8,216,4,0,
159,8,218,4,0,
159,8,231,4,0,
159,8,237,4,0,
159,8,240,4,0,
159,8,242,1,30,
159,8,249,4,0,
159,8,258,4,0,
159,8,263,4,0,
159,8,264,4,0,
159,8,276,1,48,
159,8,280,4,0,
159,8,290,4,0,
159,8,317,4,0,
159,8,332,4,0,
159,8,352,4,0,
159,8,363,4,0,
159,8,374,4,0,
159,8,401,1,42,
159,8,421,4,0,
159,8,423,1,21,
159,8,445,4,0,
159,9,8,3,0,
159,9,10,1,1,1
159,9,14,4,0,
159,9,15,4,0,
159,9,37,1,42,
159,9,43,1,1,2
159,9,44,1,13,
159,9,46,4,0,
159,9,55,1,1,3
159,9,55,1,6,
159,9,56,1,57,
159,9,57,4,0,
159,9,58,4,0,
159,9,59,4,0,
159,9,70,4,0,
159,9,91,4,0,
159,9,92,4,0,
159,9,99,1,8,
159,9,103,1,39,
159,9,104,4,0,
159,9,127,4,0,
159,9,156,4,0,
159,9,157,4,0,
159,9,163,1,33,
159,9,164,4,0,
159,9,173,3,0,
159,9,175,1,24,
159,9,180,3,0,
159,9,182,4,0,
159,9,184,1,15,
159,9,189,3,0,
159,9,196,3,0,
159,9,203,4,0,
159,9,207,4,0,
159,9,210,3,0,
159,9,213,4,0,
159,9,214,4,0,
159,9,216,4,0,
159,9,218,4,0,
159,9,231,4,0,
159,9,237,4,0,
159,9,240,4,0,
159,9,242,1,30,
159,9,246,3,0,
159,9,249,4,0,
159,9,253,3,0,
159,9,258,4,0,
159,9,263,4,0,
159,9,264,4,0,
159,9,276,1,51,
159,9,276,3,0,
159,9,280,4,0,
159,9,290,4,0,
159,9,291,3,0,
159,9,317,4,0,
159,9,332,4,0,
159,9,352,4,0,
159,9,363,4,0,
159,9,374,4,0,
159,9,401,1,48,
159,9,421,4,0,
159,9,423,1,21,
159,9,445,4,0,
159,10,8,3,0,
159,10,10,1,1,1
159,10,14,4,0,
159,10,15,4,0,
159,10,29,3,0,
159,10,37,1,42,
159,10,43,1,1,2
159,10,44,1,13,
159,10,46,4,0,
159,10,55,1,1,3
159,10,55,1,6,
159,10,56,1,57,
159,10,57,4,0,
159,10,58,4,0,
159,10,59,4,0,
159,10,67,3,0,
159,10,70,4,0,
159,10,91,4,0,
159,10,92,4,0,
159,10,99,1,8,
159,10,103,1,39,
159,10,104,4,0,
159,10,127,4,0,
159,10,156,4,0,
159,10,157,4,0,
159,10,163,1,33,
159,10,164,4,0,
159,10,173,3,0,
159,10,175,1,24,
159,10,180,3,0,
159,10,182,4,0,
159,10,184,1,15,
159,10,189,3,0,
159,10,196,3,0,
159,10,203,4,0,
159,10,207,4,0,
159,10,210,3,0,
159,10,213,4,0,
159,10,214,4,0,
159,10,216,4,0,
159,10,218,4,0,
159,10,231,4,0,
159,10,237,4,0,
159,10,240,4,0,
159,10,242,1,30,
159,10,246,3,0,
159,10,249,4,0,
159,10,250,4,0,
159,10,253,3,0,
159,10,258,4,0,
159,10,263,4,0,
159,10,264,4,0,
159,10,276,1,51,
159,10,276,3,0,
159,10,280,4,0,
159,10,290,4,0,
159,10,291,3,0,
159,10,317,4,0,
159,10,332,4,0,
159,10,352,4,0,
159,10,363,4,0,
159,10,374,4,0,
159,10,401,1,48,
159,10,421,4,0,
159,10,423,1,21,
159,10,445,4,0,
159,11,10,1,1,1
159,11,14,4,0,
159,11,15,4,0,
159,11,37,1,48,
159,11,43,1,1,2
159,11,44,1,13,
159,11,46,4,0,
159,11,55,1,1,3
159,11,55,1,6,
159,11,56,1,60,
159,11,57,4,0,
159,11,58,4,0,
159,11,59,4,0,
159,11,70,4,0,
159,11,91,4,0,
159,11,92,4,0,
159,11,99,1,8,
159,11,103,1,42,
159,11,104,4,0,
159,11,127,4,0,
159,11,156,4,0,
159,11,157,4,0,
159,11,163,1,39,
159,11,164,4,0,
159,11,175,1,24,
159,11,182,4,0,
159,11,184,1,15,
159,11,207,4,0,
159,11,213,4,0,
159,11,216,4,0,
159,11,218,4,0,
159,11,237,4,0,
159,11,240,4,0,
159,11,242,1,30,
159,11,249,4,0,
159,11,258,4,0,
159,11,263,4,0,
159,11,276,1,57,
159,11,280,4,0,
159,11,291,4,0,
159,11,317,4,0,
159,11,332,4,0,
159,11,337,4,0,
159,11,374,4,0,
159,11,401,1,51,
159,11,421,4,0,
159,11,423,1,21,
159,11,468,4,0,
159,11,496,4,0,
159,11,498,1,33,
159,11,503,4,0,
159,11,518,3,0,
159,12,10,1,1,1
159,12,15,4,0,
159,12,43,1,1,2
159,12,44,1,21,
159,12,46,4,0,
159,12,55,1,13,
159,12,56,1,55,
159,12,57,4,0,
159,12,58,4,0,
159,12,59,4,0,
159,12,70,4,0,
159,12,91,4,0,
159,12,92,4,0,
159,12,99,1,1,3
159,12,99,1,7,
159,12,103,1,45,
159,12,104,4,0,
159,12,127,4,0,
159,12,156,4,0,
159,12,163,1,37,
159,12,182,4,0,
159,12,184,1,28,
159,12,213,4,0,
159,12,216,4,0,
159,12,218,4,0,
159,12,231,4,0,
159,12,237,4,0,
159,12,240,4,0,
159,12,249,4,0,
159,12,258,4,0,
159,12,263,4,0,
159,12,264,4,0,
159,12,280,4,0,
159,12,290,4,0,
159,12,291,4,0,
159,12,332,4,0,
159,12,352,4,0,
159,13,10,1,1,1
159,13,15,4,0,
159,13,34,3,0,
159,13,38,3,0,
159,13,43,1,1,2
159,13,44,1,21,
159,13,46,4,0,
159,13,55,1,13,
159,13,56,1,55,
159,13,57,4,0,
159,13,58,4,0,
159,13,59,4,0,
159,13,69,3,0,
159,13,70,4,0,
159,13,91,4,0,
159,13,92,4,0,
159,13,99,1,1,3
159,13,99,1,7,
159,13,102,3,0,
159,13,103,1,45,
159,13,104,4,0,
159,13,127,4,0,
159,13,156,4,0,
159,13,163,1,37,
159,13,164,3,0,
159,13,182,4,0,
159,13,184,1,28,
159,13,196,3,0,
159,13,207,3,0,
159,13,213,4,0,
159,13,216,4,0,
159,13,218,4,0,
159,13,231,4,0,
159,13,237,4,0,
159,13,240,4,0,
159,13,249,4,0,
159,13,258,4,0,
159,13,263,4,0,
159,13,264,4,0,
159,13,280,4,0,
159,13,290,4,0,
159,13,291,4,0,
159,13,332,4,0,
159,13,352,4,0,
159,14,8,3,0,
159,14,10,1,1,1
159,14,14,4,0,
159,14,15,4,0,
159,14,37,1,48,
159,14,43,1,1,2
159,14,44,1,13,
159,14,46,4,0,
159,14,55,1,1,3
159,14,55,1,6,
159,14,56,1,60,
159,14,57,4,0,
159,14,58,4,0,
159,14,59,4,0,
159,14,67,3,0,
159,14,70,4,0,
159,14,91,4,0,
159,14,92,4,0,
159,14,99,1,8,
159,14,103,1,42,
159,14,104,4,0,
159,14,127,4,0,
159,14,156,4,0,
159,14,157,4,0,
159,14,163,1,39,
159,14,164,4,0,
159,14,173,3,0,
159,14,175,1,24,
159,14,180,3,0,
159,14,182,4,0,
159,14,184,1,15,
159,14,196,3,0,
159,14,207,4,0,
159,14,213,4,0,
159,14,214,3,0,
159,14,216,4,0,
159,14,218,4,0,
159,14,231,3,0,
159,14,237,4,0,
159,14,240,4,0,
159,14,242,1,30,
159,14,249,4,0,
159,14,253,3,0,
159,14,258,4,0,
159,14,263,4,0,
159,14,276,1,57,
159,14,276,3,0,
159,14,280,4,0,
159,14,291,4,0,
159,14,317,4,0,
159,14,332,4,0,
159,14,335,3,0,
159,14,337,4,0,
159,14,374,4,0,
159,14,401,1,51,
159,14,401,3,0,
159,14,421,4,0,
159,14,423,1,21,
159,14,468,4,0,
159,14,496,4,0,
159,14,498,1,33,
159,14,503,4,0,
159,14,518,3,0,
159,15,10,1,1,1
159,15,14,4,0,
159,15,15,4,0,
159,15,37,1,48,
159,15,43,1,1,2
159,15,44,1,13,
159,15,46,4,0,
159,15,55,1,1,3
159,15,55,1,6,
159,15,56,1,60,
159,15,57,4,0,
159,15,58,4,0,
159,15,59,4,0,
159,15,70,4,0,
159,15,91,4,0,
159,15,92,4,0,
159,15,99,1,8,
159,15,103,1,42,
159,15,104,4,0,
159,15,127,4,0,
159,15,156,4,0,
159,15,157,4,0,
159,15,163,1,39,
159,15,164,4,0,
159,15,175,1,24,
159,15,182,4,0,
159,15,184,1,15,
159,15,207,4,0,
159,15,213,4,0,
159,15,214,4,0,
159,15,216,4,0,
159,15,218,4,0,
159,15,237,4,0,
159,15,240,4,0,
159,15,242,1,30,
159,15,249,4,0,
159,15,258,4,0,
159,15,263,4,0,
159,15,276,1,57,
159,15,280,4,0,
159,15,317,4,0,
159,15,332,4,0,
159,15,337,4,0,
159,15,374,4,0,
159,15,401,1,51,
159,15,421,4,0,
159,15,423,1,21,
159,15,468,4,0,
159,15,496,4,0,
159,15,498,1,33,
159,15,503,4,0,
159,15,518,3,0,
159,15,590,4,0,
159,15,612,4,0,
159,16,8,3,0,
159,16,10,1,1,1
159,16,14,4,0,
159,16,15,4,0,
159,16,37,1,48,1
159,16,43,1,1,2
159,16,44,1,13,1
159,16,46,4,0,
159,16,55,1,1,3
159,16,55,1,6,1
159,16,56,1,60,1
159,16,57,4,0,
159,16,58,4,0,
159,16,59,4,0,
159,16,67,3,0,
159,16,70,4,0,
159,16,91,4,0,
159,16,92,4,0,
159,16,99,1,8,1
159,16,103,1,42,1
159,16,104,4,0,
159,16,127,4,0,
159,16,156,4,0,
159,16,157,4,0,
159,16,163,1,39,1
159,16,164,4,0,
159,16,173,3,0,
159,16,175,1,24,1
159,16,180,3,0,
159,16,182,4,0,
159,16,184,1,15,1
159,16,196,3,0,
159,16,207,4,0,
159,16,213,4,0,
159,16,214,4,0,
159,16,216,4,0,
159,16,218,4,0,
159,16,231,3,0,
159,16,237,4,0,
159,16,240,4,0,
159,16,242,1,30,1
159,16,249,4,0,
159,16,253,3,0,
159,16,258,4,0,
159,16,263,4,0,
159,16,264,3,0,
159,16,276,1,57,1
159,16,276,3,0,
159,16,280,4,0,
159,16,290,4,0,
159,16,291,4,0,
159,16,317,4,0,
159,16,332,4,0,
159,16,335,3,0,
159,16,337,4,0,
159,16,352,3,0,
159,16,374,4,0,
159,16,401,1,51,1
159,16,401,3,0,
159,16,421,4,0,
159,16,423,1,21,1
159,16,468,4,0,
159,16,496,4,0,
159,16,498,1,33,1
159,16,503,4,0,
159,16,518,3,0,
159,16,590,4,0,
159,16,612,4,0,
159,17,10,1,1,1
159,17,14,4,0,
159,17,37,1,48,
159,17,43,1,1,2
159,17,44,1,13,
159,17,46,4,0,
159,17,55,1,1,3
159,17,55,1,6,
159,17,56,1,60,
159,17,57,4,0,
159,17,58,4,0,
159,17,59,4,0,
159,17,92,4,0,
159,17,99,1,8,
159,17,103,1,42,
159,17,104,4,0,
159,17,127,4,0,
159,17,156,4,0,
159,17,157,4,0,
159,17,163,1,39,
159,17,164,4,0,
159,17,175,1,24,
159,17,182,4,0,
159,17,184,1,15,
159,17,207,4,0,
159,17,213,4,0,
159,17,214,4,0,
159,17,216,4,0,
159,17,218,4,0,
159,17,237,4,0,
159,17,240,4,0,
159,17,242,1,30,
159,17,258,4,0,
159,17,263,4,0,
159,17,276,1,57,
159,17,280,4,0,
159,17,317,4,0,
159,17,332,4,0,
159,17,337,4,0,
159,17,374,4,0,
159,17,401,1,51,
159,17,421,4,0,
159,17,423,1,21,
159,17,496,4,0,
159,17,498,1,33,
159,17,503,4,0,
159,17,518,3,0,
159,17,526,4,0,
159,17,590,4,0,
159,18,10,1,1,1
159,18,14,4,0,
159,18,37,1,48,
159,18,43,1,1,2
159,18,44,1,13,
159,18,46,4,0,
159,18,55,1,1,3
159,18,55,1,6,
159,18,56,1,60,
159,18,57,4,0,
159,18,58,4,0,
159,18,59,4,0,
159,18,92,4,0,
159,18,99,1,8,
159,18,103,1,42,
159,18,104,4,0,
159,18,127,4,0,
159,18,156,4,0,
159,18,157,4,0,
159,18,163,1,39,
159,18,164,4,0,
159,18,175,1,24,
159,18,182,4,0,
159,18,184,1,15,
159,18,207,4,0,
159,18,213,4,0,
159,18,214,4,0,
159,18,216,4,0,
159,18,218,4,0,
159,18,237,4,0,
159,18,240,4,0,
159,18,242,1,30,
159,18,258,4,0,
159,18,263,4,0,
159,18,276,1,57,
159,18,280,4,0,
159,18,317,4,0,
159,18,332,4,0,
159,18,337,4,0,
159,18,374,4,0,
159,18,401,1,51,
159,18,421,4,0,
159,18,423,1,21,
159,18,496,4,0,
159,18,498,1,33,
159,18,503,4,0,
159,18,518,3,0,
159,18,526,4,0,
159,18,590,4,0,
160,3,8,4,0,
160,3,10,1,1,1
160,3,15,4,0,
160,3,29,4,0,
160,3,43,1,1,2
160,3,44,1,21,
160,3,46,4,0,
160,3,55,1,1,4
160,3,55,1,13,
160,3,56,1,58,
160,3,57,4,0,
160,3,59,4,0,
160,3,63,4,0,
160,3,70,4,0,
160,3,89,4,0,
160,3,91,4,0,
160,3,92,4,0,
160,3,99,1,1,3
160,3,99,1,7,
160,3,103,1,47,
160,3,104,4,0,
160,3,156,4,0,
160,3,163,1,38,
160,3,173,4,0,
160,3,174,4,0,
160,3,182,4,0,
160,3,184,1,28,
160,3,189,4,0,
160,3,196,4,0,
160,3,197,4,0,
160,3,203,4,0,
160,3,207,4,0,
160,3,210,4,0,
160,3,213,4,0,
160,3,214,4,0,
160,3,216,4,0,
160,3,218,4,0,
160,3,223,4,0,
160,3,231,4,0,
160,3,237,4,0,
160,3,240,4,0,
160,3,249,4,0,
160,3,250,4,0,
160,4,8,4,0,
160,4,10,1,1,1
160,4,15,4,0,
160,4,29,4,0,
160,4,43,1,1,2
160,4,44,1,21,
160,4,46,4,0,
160,4,55,1,1,4
160,4,55,1,13,
160,4,56,1,58,
160,4,57,4,0,
160,4,58,3,0,
160,4,59,4,0,
160,4,63,4,0,
160,4,70,4,0,
160,4,89,4,0,
160,4,91,4,0,
160,4,92,4,0,
160,4,99,1,1,3
160,4,99,1,7,
160,4,103,1,47,
160,4,104,4,0,
160,4,156,4,0,
160,4,163,1,38,
160,4,173,4,0,
160,4,174,4,0,
160,4,182,4,0,
160,4,184,1,28,
160,4,189,4,0,
160,4,196,4,0,
160,4,197,4,0,
160,4,203,4,0,
160,4,207,4,0,
160,4,210,4,0,
160,4,213,4,0,
160,4,214,4,0,
160,4,216,4,0,
160,4,218,4,0,
160,4,223,4,0,
160,4,231,4,0,
160,4,237,4,0,
160,4,240,4,0,
160,4,249,4,0,
160,4,250,4,0,
160,5,10,1,1,1
160,5,15,4,0,
160,5,43,1,1,2
160,5,44,1,21,
160,5,46,4,0,
160,5,55,1,1,4
160,5,55,1,13,
160,5,56,1,58,
160,5,57,4,0,
160,5,58,4,0,
160,5,59,4,0,
160,5,63,4,0,
160,5,70,4,0,
160,5,89,4,0,
160,5,91,4,0,
160,5,92,4,0,
160,5,99,1,1,3
160,5,99,1,7,
160,5,103,1,47,
160,5,104,4,0,
160,5,127,4,0,
160,5,156,4,0,
160,5,163,1,38,
160,5,182,4,0,
160,5,184,1,28,
160,5,213,4,0,
160,5,216,4,0,
160,5,218,4,0,
160,5,231,4,0,
160,5,237,4,0,
160,5,240,4,0,
160,5,249,4,0,
160,5,258,4,0,
160,5,263,4,0,
160,5,264,4,0,
160,5,280,4,0,
160,5,290,4,0,
160,5,291,4,0,
160,5,332,4,0,
160,5,337,4,0,
160,5,352,4,0,
160,6,5,3,0,
160,6,8,3,0,
160,6,10,1,1,1
160,6,14,3,0,
160,6,15,4,0,
160,6,25,3,0,
160,6,34,3,0,
160,6,38,3,0,
160,6,43,1,1,2
160,6,44,1,21,
160,6,46,4,0,
160,6,55,1,1,4
160,6,55,1,13,
160,6,56,1,58,
160,6,57,4,0,
160,6,58,4,0,
160,6,59,4,0,
160,6,63,4,0,
160,6,68,3,0,
160,6,69,3,0,
160,6,70,4,0,
160,6,89,4,0,
160,6,91,4,0,
160,6,92,4,0,
160,6,99,1,1,3
160,6,99,1,7,
160,6,102,3,0,
160,6,103,1,47,
160,6,104,4,0,
160,6,127,4,0,
160,6,156,4,0,
160,6,157,3,0,
160,6,163,1,38,
160,6,164,3,0,
160,6,173,3,0,
160,6,182,4,0,
160,6,184,1,28,
160,6,189,3,0,
160,6,196,3,0,
160,6,203,3,0,
160,6,207,3,0,
160,6,210,3,0,
160,6,213,4,0,
160,6,214,3,0,
160,6,216,4,0,
160,6,218,4,0,
160,6,223,3,0,
160,6,231,4,0,
160,6,237,4,0,
160,6,240,4,0,
160,6,249,4,0,
160,6,258,4,0,
160,6,263,4,0,
160,6,264,4,0,
160,6,280,4,0,
160,6,290,4,0,
160,6,291,4,0,
160,6,332,4,0,
160,6,337,4,0,
160,6,352,4,0,
160,7,5,3,0,
160,7,10,1,1,1
160,7,14,3,0,
160,7,15,4,0,
160,7,25,3,0,
160,7,34,3,0,
160,7,38,3,0,
160,7,43,1,1,2
160,7,44,1,21,
160,7,46,4,0,
160,7,55,1,1,4
160,7,55,1,13,
160,7,56,1,58,
160,7,57,4,0,
160,7,58,4,0,
160,7,59,4,0,
160,7,63,4,0,
160,7,68,3,0,
160,7,69,3,0,
160,7,70,4,0,
160,7,89,4,0,
160,7,91,4,0,
160,7,92,4,0,
160,7,99,1,1,3
160,7,99,1,7,
160,7,102,3,0,
160,7,103,1,47,
160,7,104,4,0,
160,7,127,4,0,
160,7,156,4,0,
160,7,157,3,0,
160,7,163,1,38,
160,7,164,3,0,
160,7,182,4,0,
160,7,184,1,28,
160,7,213,4,0,
160,7,216,4,0,
160,7,218,4,0,
160,7,231,4,0,
160,7,237,4,0,
160,7,240,4,0,
160,7,249,4,0,
160,7,258,4,0,
160,7,263,4,0,
160,7,264,4,0,
160,7,280,4,0,
160,7,290,4,0,
160,7,291,4,0,
160,7,332,4,0,
160,7,337,4,0,
160,7,352,4,0,
160,8,10,1,1,1
160,8,14,4,0,
160,8,15,4,0,
160,8,37,1,24,
160,8,43,1,1,2
160,8,44,1,13,
160,8,46,4,0,
160,8,55,1,1,3
160,8,55,1,6,
160,8,56,1,63,
160,8,57,4,0,
160,8,58,4,0,
160,8,59,4,0,
160,8,63,4,0,
160,8,70,4,0,
160,8,89,4,0,
160,8,91,4,0,
160,8,92,4,0,
160,8,97,1,30,
160,8,99,1,1,4
160,8,99,1,8,
160,8,103,1,45,
160,8,104,4,0,
160,8,127,4,0,
160,8,156,4,0,
160,8,157,4,0,
160,8,163,1,37,
160,8,164,4,0,
160,8,182,4,0,
160,8,184,1,15,
160,8,203,4,0,
160,8,207,4,0,
160,8,213,4,0,
160,8,214,4,0,
160,8,216,4,0,
160,8,218,4,0,
160,8,231,4,0,
160,8,237,4,0,
160,8,240,4,0,
160,8,242,1,32,
160,8,249,4,0,
160,8,258,4,0,
160,8,263,4,0,
160,8,264,4,0,
160,8,276,1,58,
160,8,280,4,0,
160,8,290,4,0,
160,8,308,3,0,
160,8,317,4,0,
160,8,332,4,0,
160,8,337,4,0,
160,8,352,4,0,
160,8,363,4,0,
160,8,374,4,0,
160,8,401,1,50,
160,8,406,4,0,
160,8,411,4,0,
160,8,416,4,0,
160,8,419,4,0,
160,8,421,4,0,
160,8,423,1,21,
160,8,431,4,0,
160,8,445,4,0,
160,9,8,3,0,
160,9,10,1,1,1
160,9,14,4,0,
160,9,15,4,0,
160,9,37,1,50,
160,9,43,1,1,2
160,9,44,1,13,
160,9,46,4,0,
160,9,55,1,1,3
160,9,55,1,6,
160,9,56,1,71,
160,9,57,4,0,
160,9,58,4,0,
160,9,59,4,0,
160,9,63,4,0,
160,9,70,4,0,
160,9,89,4,0,
160,9,91,4,0,
160,9,92,4,0,
160,9,97,1,30,
160,9,99,1,1,4
160,9,99,1,8,
160,9,103,1,45,
160,9,104,4,0,
160,9,127,4,0,
160,9,156,4,0,
160,9,157,4,0,
160,9,163,1,37,
160,9,164,4,0,
160,9,173,3,0,
160,9,175,1,24,
160,9,180,3,0,
160,9,182,4,0,
160,9,184,1,15,
160,9,189,3,0,
160,9,196,3,0,
160,9,200,3,0,
160,9,203,4,0,
160,9,207,4,0,
160,9,210,3,0,
160,9,213,4,0,
160,9,214,4,0,
160,9,216,4,0,
160,9,218,4,0,
160,9,231,4,0,
160,9,237,4,0,
160,9,240,4,0,
160,9,242,1,32,
160,9,246,3,0,
160,9,249,4,0,
160,9,253,3,0,
160,9,258,4,0,
160,9,263,4,0,
160,9,264,4,0,
160,9,276,1,63,
160,9,276,3,0,
160,9,280,4,0,
160,9,290,4,0,
160,9,291,3,0,
160,9,308,3,0,
160,9,317,4,0,
160,9,332,4,0,
160,9,337,4,0,
160,9,352,4,0,
160,9,363,4,0,
160,9,374,4,0,
160,9,401,1,58,
160,9,401,3,0,
160,9,406,4,0,
160,9,411,4,0,
160,9,416,4,0,
160,9,419,4,0,
160,9,421,4,0,
160,9,423,1,21,
160,9,431,4,0,
160,9,445,4,0,
160,10,8,3,0,
160,10,10,1,1,1
160,10,14,4,0,
160,10,15,4,0,
160,10,29,3,0,
160,10,37,1,50,
160,10,43,1,1,2
160,10,44,1,13,
160,10,46,4,0,
160,10,55,1,1,3
160,10,55,1,6,
160,10,56,1,71,
160,10,57,4,0,
160,10,58,4,0,
160,10,59,4,0,
160,10,63,4,0,
160,10,67,3,0,
160,10,70,4,0,
160,10,89,4,0,
160,10,91,4,0,
160,10,92,4,0,
160,10,97,1,30,
160,10,99,1,1,4
160,10,99,1,8,
160,10,103,1,45,
160,10,104,4,0,
160,10,127,4,0,
160,10,156,4,0,
160,10,157,4,0,
160,10,163,1,37,
160,10,164,4,0,
160,10,173,3,0,
160,10,175,1,24,
160,10,180,3,0,
160,10,182,4,0,
160,10,184,1,15,
160,10,189,3,0,
160,10,196,3,0,
160,10,200,3,0,
160,10,203,4,0,
160,10,207,4,0,
160,10,210,3,0,
160,10,213,4,0,
160,10,214,4,0,
160,10,216,4,0,
160,10,218,4,0,
160,10,231,4,0,
160,10,237,4,0,
160,10,240,4,0,
160,10,242,1,32,
160,10,246,3,0,
160,10,249,4,0,
160,10,250,4,0,
160,10,253,3,0,
160,10,258,4,0,
160,10,263,4,0,
160,10,264,4,0,
160,10,276,1,63,
160,10,276,3,0,
160,10,280,4,0,
160,10,290,4,0,
160,10,291,3,0,
160,10,308,3,0,
160,10,317,4,0,
160,10,332,4,0,
160,10,337,4,0,
160,10,352,4,0,
160,10,363,4,0,
160,10,374,4,0,
160,10,401,1,58,
160,10,401,3,0,
160,10,406,4,0,
160,10,411,4,0,
160,10,416,4,0,
160,10,419,4,0,
160,10,421,4,0,
160,10,423,1,21,
160,10,431,4,0,
160,10,445,4,0,
160,11,10,1,1,1
160,11,14,4,0,
160,11,15,4,0,
160,11,37,1,58,
160,11,43,1,1,2
160,11,44,1,13,
160,11,46,4,0,
160,11,55,1,1,3
160,11,55,1,6,
160,11,56,1,76,
160,11,57,4,0,
160,11,58,4,0,
160,11,59,4,0,
160,11,63,4,0,
160,11,70,4,0,
160,11,89,4,0,
160,11,91,4,0,
160,11,92,4,0,
160,11,97,1,30,
160,11,99,1,1,4
160,11,99,1,8,
160,11,103,1,50,
160,11,104,4,0,
160,11,127,4,0,
160,11,156,4,0,
160,11,157,4,0,
160,11,163,1,45,
160,11,164,4,0,
160,11,175,1,24,
160,11,182,4,0,
160,11,184,1,15,
160,11,207,4,0,
160,11,213,4,0,
160,11,216,4,0,
160,11,218,4,0,
160,11,237,4,0,
160,11,240,4,0,
160,11,242,1,32,
160,11,249,4,0,
160,11,258,4,0,
160,11,263,4,0,
160,11,276,1,71,
160,11,280,4,0,
160,11,291,4,0,
160,11,308,3,0,
160,11,317,4,0,
160,11,332,4,0,
160,11,337,4,0,
160,11,374,4,0,
160,11,401,1,63,
160,11,411,4,0,
160,11,416,4,0,
160,11,421,4,0,
160,11,423,1,21,
160,11,468,4,0,
160,11,496,4,0,
160,11,498,1,37,
160,11,503,4,0,
160,11,518,3,0,
160,11,523,4,0,
160,11,525,4,0,
160,12,10,1,1,1
160,12,15,4,0,
160,12,43,1,1,2
160,12,44,1,21,
160,12,46,4,0,
160,12,55,1,1,4
160,12,55,1,13,
160,12,56,1,58,
160,12,57,4,0,
160,12,58,4,0,
160,12,59,4,0,
160,12,63,4,0,
160,12,70,4,0,
160,12,89,4,0,
160,12,91,4,0,
160,12,92,4,0,
160,12,99,1,1,3
160,12,99,1,7,
160,12,103,1,47,
160,12,104,4,0,
160,12,127,4,0,
160,12,156,4,0,
160,12,163,1,38,
160,12,182,4,0,
160,12,184,1,28,
160,12,213,4,0,
160,12,216,4,0,
160,12,218,4,0,
160,12,231,4,0,
160,12,237,4,0,
160,12,240,4,0,
160,12,249,4,0,
160,12,258,4,0,
160,12,263,4,0,
160,12,264,4,0,
160,12,280,4,0,
160,12,290,4,0,
160,12,291,4,0,
160,12,332,4,0,
160,12,337,4,0,
160,12,352,4,0,
160,13,10,1,1,1
160,13,15,4,0,
160,13,34,3,0,
160,13,38,3,0,
160,13,43,1,1,2
160,13,44,1,21,
160,13,46,4,0,
160,13,55,1,1,4
160,13,55,1,13,
160,13,56,1,58,
160,13,57,4,0,
160,13,58,4,0,
160,13,59,4,0,
160,13,63,4,0,
160,13,69,3,0,
160,13,70,4,0,
160,13,89,4,0,
160,13,91,4,0,
160,13,92,4,0,
160,13,99,1,1,3
160,13,99,1,7,
160,13,102,3,0,
160,13,103,1,47,
160,13,104,4,0,
160,13,127,4,0,
160,13,156,4,0,
160,13,163,1,38,
160,13,164,3,0,
160,13,182,4,0,
160,13,184,1,28,
160,13,196,3,0,
160,13,207,3,0,
160,13,213,4,0,
160,13,216,4,0,
160,13,218,4,0,
160,13,231,4,0,
160,13,237,4,0,
160,13,240,4,0,
160,13,249,4,0,
160,13,258,4,0,
160,13,263,4,0,
160,13,264,4,0,
160,13,280,4,0,
160,13,290,4,0,
160,13,291,4,0,
160,13,332,4,0,
160,13,337,4,0,
160,13,352,4,0,
160,14,8,3,0,
160,14,10,1,1,1
160,14,14,4,0,
160,14,15,4,0,
160,14,37,1,58,
160,14,43,1,1,2
160,14,44,1,13,
160,14,46,4,0,
160,14,55,1,1,3
160,14,55,1,6,
160,14,56,1,76,
160,14,57,4,0,
160,14,58,4,0,
160,14,59,4,0,
160,14,63,4,0,
160,14,67,3,0,
160,14,70,4,0,
160,14,89,4,0,
160,14,91,4,0,
160,14,92,4,0,
160,14,97,1,30,
160,14,99,1,1,4
160,14,99,1,8,
160,14,103,1,50,
160,14,104,4,0,
160,14,127,4,0,
160,14,156,4,0,
160,14,157,4,0,
160,14,163,1,45,
160,14,164,4,0,
160,14,173,3,0,
160,14,175,1,24,
160,14,180,3,0,
160,14,182,4,0,
160,14,184,1,15,
160,14,196,3,0,
160,14,200,3,0,
160,14,207,4,0,
160,14,213,4,0,
160,14,214,3,0,
160,14,216,4,0,
160,14,218,4,0,
160,14,231,3,0,
160,14,237,4,0,
160,14,240,4,0,
160,14,242,1,32,
160,14,249,4,0,
160,14,253,3,0,
160,14,258,4,0,
160,14,263,4,0,
160,14,276,1,71,
160,14,276,3,0,
160,14,280,4,0,
160,14,291,4,0,
160,14,308,3,0,
160,14,317,4,0,
160,14,332,4,0,
160,14,335,3,0,
160,14,337,4,0,
160,14,374,4,0,
160,14,401,1,63,
160,14,401,3,0,
160,14,406,3,0,
160,14,411,4,0,
160,14,416,4,0,
160,14,421,4,0,
160,14,423,1,21,
160,14,468,4,0,
160,14,496,4,0,
160,14,498,1,37,
160,14,503,4,0,
160,14,518,3,0,
160,14,523,4,0,
160,14,525,4,0,
160,15,10,1,1,1
160,15,14,4,0,
160,15,15,4,0,
160,15,37,1,58,
160,15,43,1,1,2
160,15,44,1,13,
160,15,46,4,0,
160,15,55,1,1,3
160,15,55,1,6,
160,15,56,1,76,
160,15,57,4,0,
160,15,58,4,0,
160,15,59,4,0,
160,15,63,4,0,
160,15,70,4,0,
160,15,89,4,0,
160,15,91,4,0,
160,15,92,4,0,
160,15,97,1,30,
160,15,99,1,1,4
160,15,99,1,8,
160,15,103,1,50,
160,15,104,4,0,
160,15,127,4,0,
160,15,156,4,0,
160,15,157,4,0,
160,15,163,1,45,
160,15,164,4,0,
160,15,175,1,24,
160,15,182,4,0,
160,15,184,1,15,
160,15,207,4,0,
160,15,213,4,0,
160,15,214,4,0,
160,15,216,4,0,
160,15,218,4,0,
160,15,237,4,0,
160,15,240,4,0,
160,15,242,1,32,
160,15,249,4,0,
160,15,258,4,0,
160,15,263,4,0,
160,15,276,1,71,
160,15,280,4,0,
160,15,308,3,0,
160,15,317,4,0,
160,15,332,4,0,
160,15,337,4,0,
160,15,374,4,0,
160,15,401,1,63,
160,15,411,4,0,
160,15,416,4,0,
160,15,421,4,0,
160,15,423,1,21,
160,15,468,4,0,
160,15,496,4,0,
160,15,498,1,37,
160,15,503,4,0,
160,15,518,3,0,
160,15,523,4,0,
160,15,525,4,0,
160,15,590,4,0,
160,15,612,4,0,
160,16,8,3,0,
160,16,10,1,1,1
160,16,14,4,0,
160,16,15,4,0,
160,16,37,1,58,1
160,16,43,1,1,2
160,16,44,1,13,1
160,16,46,4,0,
160,16,55,1,1,3
160,16,55,1,6,1
160,16,56,1,76,1
160,16,57,4,0,
160,16,58,4,0,
160,16,59,4,0,
160,16,63,4,0,
160,16,67,3,0,
160,16,70,4,0,
160,16,89,4,0,
160,16,91,4,0,
160,16,92,4,0,
160,16,97,1,30,1
160,16,99,1,1,4
160,16,99,1,8,1
160,16,103,1,50,1
160,16,104,4,0,
160,16,127,4,0,
160,16,156,4,0,
160,16,157,4,0,
160,16,163,1,45,1
160,16,164,4,0,
160,16,173,3,0,
160,16,175,1,24,1
160,16,180,3,0,
160,16,182,4,0,
160,16,184,1,15,1
160,16,196,3,0,
160,16,200,3,0,
160,16,207,4,0,
160,16,213,4,0,
160,16,214,4,0,
160,16,216,4,0,
160,16,218,4,0,
160,16,231,3,0,
160,16,237,4,0,
160,16,240,4,0,
160,16,242,1,32,1
160,16,249,4,0,
160,16,253,3,0,
160,16,258,4,0,
160,16,263,4,0,
160,16,264,3,0,
160,16,276,1,71,1
160,16,276,3,0,
160,16,280,4,0,
160,16,290,4,0,
160,16,291,4,0,
160,16,308,3,0,
160,16,317,4,0,
160,16,332,4,0,
160,16,335,3,0,
160,16,337,4,0,
160,16,352,3,0,
160,16,374,4,0,
160,16,401,1,63,1
160,16,401,3,0,
160,16,406,3,0,
160,16,411,4,0,
160,16,416,4,0,
160,16,421,4,0,
160,16,423,1,21,1
160,16,468,4,0,
160,16,496,4,0,
160,16,498,1,37,1
160,16,503,4,0,
160,16,518,3,0,
160,16,523,4,0,
160,16,525,4,0,
160,16,590,4,0,
160,16,612,4,0,
160,17,10,1,1,2
160,17,14,4,0,
160,17,37,1,58,
160,17,43,1,1,3
160,17,44,1,13,
160,17,46,4,0,
160,17,55,1,1,4
160,17,55,1,6,
160,17,56,1,76,
160,17,57,4,0,
160,17,58,4,0,
160,17,59,4,0,
160,17,63,4,0,
160,17,89,4,0,
160,17,92,4,0,
160,17,97,1,1,1
160,17,99,1,1,5
160,17,99,1,8,
160,17,103,1,50,
160,17,104,4,0,
160,17,127,4,0,
160,17,156,4,0,
160,17,157,4,0,
160,17,163,1,45,
160,17,164,4,0,
160,17,175,1,24,
160,17,182,4,0,
160,17,184,1,15,
160,17,207,4,0,
160,17,213,4,0,
160,17,214,4,0,
160,17,216,4,0,
160,17,218,4,0,
160,17,237,4,0,
160,17,240,4,0,
160,17,242,1,32,
160,17,258,4,0,
160,17,263,4,0,
160,17,276,1,71,
160,17,280,4,0,
160,17,308,3,0,
160,17,317,4,0,
160,17,332,4,0,
160,17,337,4,0,
160,17,374,4,0,
160,17,401,1,63,
160,17,411,4,0,
160,17,416,4,0,
160,17,421,4,0,
160,17,423,1,21,
160,17,496,4,0,
160,17,498,1,37,
160,17,503,4,0,
160,17,518,3,0,
160,17,523,4,0,
160,17,525,4,0,
160,17,526,4,0,
160,17,590,4,0,
160,18,10,1,1,2
160,18,14,4,0,
160,18,37,1,58,
160,18,43,1,1,3
160,18,44,1,13,
160,18,46,4,0,
160,18,55,1,1,4
160,18,55,1,6,
160,18,56,1,76,
160,18,57,4,0,
160,18,58,4,0,
160,18,59,4,0,
160,18,63,4,0,
160,18,89,4,0,
160,18,92,4,0,
160,18,97,1,1,1
160,18,99,1,1,5
160,18,99,1,8,
160,18,103,1,50,
160,18,104,4,0,
160,18,127,4,0,
160,18,156,4,0,
160,18,157,4,0,
160,18,163,1,45,
160,18,164,4,0,
160,18,175,1,24,
160,18,182,4,0,
160,18,184,1,15,
160,18,207,4,0,
160,18,213,4,0,
160,18,214,4,0,
160,18,216,4,0,
160,18,218,4,0,
160,18,237,4,0,
160,18,240,4,0,
160,18,242,1,32,
160,18,258,4,0,
160,18,263,4,0,
160,18,276,1,71,
160,18,280,4,0,
160,18,308,3,0,
160,18,317,4,0,
160,18,332,4,0,
160,18,337,4,0,
160,18,374,4,0,
160,18,401,1,63,
160,18,411,4,0,
160,18,416,4,0,
160,18,421,4,0,
160,18,423,1,21,
160,18,496,4,0,
160,18,498,1,37,
160,18,503,4,0,
160,18,518,3,0,
160,18,523,4,0,
160,18,525,4,0,
160,18,526,4,0,
160,18,590,4,0,
161,3,7,4,0,
161,3,8,4,0,
161,3,9,4,0,
161,3,15,4,0,
161,3,21,1,25,
161,3,29,4,0,
161,3,33,1,1,
161,3,38,2,0,
161,3,57,4,0,
161,3,91,4,0,
161,3,92,4,0,
161,3,98,1,11,
161,3,104,4,0,
161,3,111,1,5,
161,3,111,4,0,
161,3,116,2,0,
161,3,129,4,0,
161,3,133,1,41,
161,3,154,1,17,
161,3,156,1,33,
161,3,156,4,0,
161,3,163,2,0,
161,3,168,4,0,
161,3,173,4,0,
161,3,174,4,0,
161,3,179,2,0,
161,3,182,4,0,
161,3,189,4,0,
161,3,197,4,0,
161,3,203,4,0,
161,3,205,4,0,
161,3,207,4,0,
161,3,210,4,0,
161,3,213,4,0,
161,3,214,4,0,
161,3,216,4,0,
161,3,218,4,0,
161,3,223,4,0,
161,3,228,2,0,
161,3,231,4,0,
161,3,237,4,0,
161,3,241,4,0,
161,3,247,4,0,
161,4,7,4,0,
161,4,8,4,0,
161,4,9,4,0,
161,4,15,4,0,
161,4,21,1,25,
161,4,29,4,0,
161,4,33,1,1,
161,4,38,2,0,
161,4,57,4,0,
161,4,91,4,0,
161,4,92,4,0,
161,4,98,1,11,
161,4,104,4,0,
161,4,111,1,5,
161,4,111,4,0,
161,4,116,2,0,
161,4,129,4,0,
161,4,133,1,41,
161,4,154,1,17,
161,4,156,1,33,
161,4,156,4,0,
161,4,163,2,0,
161,4,168,4,0,
161,4,173,4,0,
161,4,174,4,0,
161,4,179,2,0,
161,4,182,4,0,
161,4,189,4,0,
161,4,197,4,0,
161,4,203,4,0,
161,4,205,4,0,
161,4,207,4,0,
161,4,210,4,0,
161,4,213,4,0,
161,4,214,4,0,
161,4,216,4,0,
161,4,218,4,0,
161,4,223,4,0,
161,4,228,2,0,
161,4,231,4,0,
161,4,237,4,0,
161,4,241,4,0,
161,4,247,4,0,
161,5,10,1,1,
161,5,15,4,0,
161,5,21,1,24,
161,5,38,2,0,
161,5,53,4,0,
161,5,57,4,0,
161,5,58,4,0,
161,5,76,4,0,
161,5,85,4,0,
161,5,91,4,0,
161,5,92,4,0,
161,5,98,1,7,
161,5,104,4,0,
161,5,111,1,4,
161,5,116,2,0,
161,5,133,1,49,
161,5,154,1,12,
161,5,156,1,40,
161,5,156,4,0,
161,5,163,2,0,
161,5,164,2,0,
161,5,168,4,0,
161,5,179,2,0,
161,5,182,4,0,
161,5,213,4,0,
161,5,216,4,0,
161,5,218,4,0,
161,5,228,2,0,
161,5,231,4,0,
161,5,237,4,0,
161,5,240,4,0,
161,5,241,4,0,
161,5,247,4,0,
161,5,263,4,0,
161,5,264,4,0,
161,5,266,1,31,
161,5,270,1,17,
161,5,271,2,0,
161,5,274,2,0,
161,5,280,4,0,
161,5,290,4,0,
161,5,351,4,0,
161,5,352,4,0,
161,6,7,3,0,
161,6,8,3,0,
161,6,9,3,0,
161,6,10,1,1,
161,6,15,4,0,
161,6,21,1,24,
161,6,34,3,0,
161,6,38,2,0,
161,6,38,3,0,
161,6,53,4,0,
161,6,57,4,0,
161,6,58,4,0,
161,6,76,4,0,
161,6,85,4,0,
161,6,91,4,0,
161,6,92,4,0,
161,6,98,1,7,
161,6,102,3,0,
161,6,104,4,0,
161,6,111,1,4,
161,6,111,3,0,
161,6,116,2,0,
161,6,129,3,0,
161,6,133,1,49,
161,6,154,1,12,
161,6,156,1,40,
161,6,156,4,0,
161,6,163,2,0,
161,6,164,2,0,
161,6,164,3,0,
161,6,168,4,0,
161,6,173,3,0,
161,6,179,2,0,
161,6,182,4,0,
161,6,189,3,0,
161,6,203,3,0,
161,6,205,3,0,
161,6,207,3,0,
161,6,210,3,0,
161,6,213,4,0,
161,6,214,3,0,
161,6,216,4,0,
161,6,218,4,0,
161,6,223,3,0,
161,6,228,2,0,
161,6,231,4,0,
161,6,237,4,0,
161,6,240,4,0,
161,6,241,4,0,
161,6,247,4,0,
161,6,263,4,0,
161,6,264,4,0,
161,6,266,1,31,
161,6,270,1,17,
161,6,271,2,0,
161,6,274,2,0,
161,6,280,4,0,
161,6,290,4,0,
161,6,351,4,0,
161,6,352,4,0,
161,7,10,1,1,
161,7,15,4,0,
161,7,21,1,24,
161,7,34,3,0,
161,7,38,2,0,
161,7,38,3,0,
161,7,53,4,0,
161,7,57,4,0,
161,7,58,4,0,
161,7,76,4,0,
161,7,85,4,0,
161,7,91,4,0,
161,7,92,4,0,
161,7,98,1,7,
161,7,102,3,0,
161,7,104,4,0,
161,7,111,1,4,
161,7,116,2,0,
161,7,133,1,49,
161,7,154,1,12,
161,7,156,1,40,
161,7,156,4,0,
161,7,163,2,0,
161,7,164,2,0,
161,7,164,3,0,
161,7,168,4,0,
161,7,179,2,0,
161,7,182,4,0,
161,7,213,4,0,
161,7,216,4,0,
161,7,218,4,0,
161,7,228,2,0,
161,7,231,4,0,
161,7,237,4,0,
161,7,240,4,0,
161,7,241,4,0,
161,7,247,4,0,
161,7,263,4,0,
161,7,264,4,0,
161,7,266,1,31,
161,7,270,1,17,
161,7,271,2,0,
161,7,274,2,0,
161,7,280,4,0,
161,7,290,4,0,
161,7,351,4,0,
161,7,352,4,0,
161,8,10,1,1,1
161,8,15,4,0,
161,8,21,1,25,
161,8,38,2,0,
161,8,53,4,0,
161,8,57,4,0,
161,8,58,4,0,
161,8,76,4,0,
161,8,85,4,0,
161,8,91,4,0,
161,8,92,4,0,
161,8,98,1,7,
161,8,104,4,0,
161,8,111,1,4,
161,8,116,2,0,
161,8,133,1,36,
161,8,154,1,13,
161,8,156,1,28,
161,8,156,4,0,
161,8,163,2,0,
161,8,164,2,0,
161,8,164,4,0,
161,8,168,4,0,
161,8,179,2,0,
161,8,182,4,0,
161,8,193,1,1,2
161,8,203,4,0,
161,8,204,2,0,
161,8,207,4,0,
161,8,213,4,0,
161,8,214,4,0,
161,8,216,4,0,
161,8,218,4,0,
161,8,226,1,39,
161,8,228,2,0,
161,8,231,4,0,
161,8,237,4,0,
161,8,240,4,0,
161,8,241,4,0,
161,8,247,4,0,
161,8,263,4,0,
161,8,264,4,0,
161,8,266,1,19,
161,8,270,1,16,
161,8,271,2,0,
161,8,274,2,0,
161,8,280,4,0,
161,8,290,4,0,
161,8,304,1,47,
161,8,343,2,0,
161,8,351,4,0,
161,8,352,4,0,
161,8,363,4,0,
161,8,369,4,0,
161,8,374,4,0,
161,8,382,1,42,
161,8,387,2,0,
161,8,389,1,31,
161,8,421,4,0,
161,8,445,4,0,
161,8,447,4,0,
161,8,451,4,0,
161,9,7,3,0,
161,9,8,3,0,
161,9,9,3,0,
161,9,10,1,1,1
161,9,15,4,0,
161,9,21,1,25,
161,9,38,2,0,
161,9,53,4,0,
161,9,57,4,0,
161,9,58,4,0,
161,9,76,4,0,
161,9,85,4,0,
161,9,91,4,0,
161,9,92,4,0,
161,9,98,1,7,
161,9,104,4,0,
161,9,111,1,4,
161,9,116,2,0,
161,9,129,3,0,
161,9,133,1,36,
161,9,154,1,13,
161,9,156,1,28,
161,9,156,4,0,
161,9,163,2,0,
161,9,164,2,0,
161,9,164,4,0,
161,9,168,4,0,
161,9,173,3,0,
161,9,179,2,0,
161,9,182,4,0,
161,9,189,3,0,
161,9,193,1,1,2
161,9,203,4,0,
161,9,204,2,0,
161,9,205,3,0,
161,9,207,4,0,
161,9,210,3,0,
161,9,213,4,0,
161,9,214,4,0,
161,9,216,4,0,
161,9,218,4,0,
161,9,226,1,39,
161,9,228,2,0,
161,9,231,4,0,
161,9,237,4,0,
161,9,240,4,0,
161,9,241,4,0,
161,9,247,4,0,
161,9,253,3,0,
161,9,263,4,0,
161,9,264,4,0,
161,9,266,1,19,
161,9,270,1,16,
161,9,270,3,0,
161,9,271,2,0,
161,9,274,2,0,
161,9,280,4,0,
161,9,282,3,0,
161,9,290,4,0,
161,9,304,1,47,
161,9,343,2,0,
161,9,351,4,0,
161,9,352,4,0,
161,9,363,4,0,
161,9,369,4,0,
161,9,374,4,0,
161,9,382,1,42,
161,9,387,2,0,
161,9,387,3,0,
161,9,389,1,31,
161,9,389,3,0,
161,9,401,3,0,
161,9,421,4,0,
161,9,445,4,0,
161,9,447,4,0,
161,9,451,4,0,
161,10,7,3,0,
161,10,8,3,0,
161,10,9,3,0,
161,10,10,1,1,1
161,10,15,4,0,
161,10,21,1,25,
161,10,29,3,0,
161,10,38,2,0,
161,10,53,4,0,
161,10,57,4,0,
161,10,58,4,0,
161,10,76,4,0,
161,10,85,4,0,
161,10,91,4,0,
161,10,92,4,0,
161,10,98,1,7,
161,10,104,4,0,
161,10,111,1,4,
161,10,116,2,0,
161,10,129,3,0,
161,10,133,1,36,
161,10,154,1,13,
161,10,156,1,28,
161,10,156,4,0,
161,10,162,3,0,
161,10,163,2,0,
161,10,164,2,0,
161,10,164,4,0,
161,10,168,4,0,
161,10,173,3,0,
161,10,179,2,0,
161,10,182,4,0,
161,10,189,3,0,
161,10,193,1,1,2
161,10,203,4,0,
161,10,204,2,0,
161,10,205,3,0,
161,10,207,4,0,
161,10,210,3,0,
161,10,213,4,0,
161,10,214,4,0,
161,10,216,4,0,
161,10,218,4,0,
161,10,226,1,39,
161,10,228,2,0,
161,10,231,4,0,
161,10,237,4,0,
161,10,240,4,0,
161,10,241,4,0,
161,10,247,4,0,
161,10,250,4,0,
161,10,253,3,0,
161,10,263,4,0,
161,10,264,4,0,
161,10,266,1,19,
161,10,270,1,16,
161,10,270,3,0,
161,10,271,2,0,
161,10,274,2,0,
161,10,280,4,0,
161,10,282,3,0,
161,10,290,4,0,
161,10,304,1,47,
161,10,343,2,0,
161,10,351,4,0,
161,10,352,4,0,
161,10,363,4,0,
161,10,369,4,0,
161,10,374,4,0,
161,10,382,1,42,
161,10,387,2,0,
161,10,387,3,0,
161,10,389,1,31,
161,10,389,3,0,
161,10,401,3,0,
161,10,421,4,0,
161,10,445,4,0,
161,10,447,4,0,
161,10,451,4,0,
161,11,10,1,1,1
161,11,15,4,0,
161,11,21,1,25,
161,11,38,2,0,
161,11,53,4,0,
161,11,57,4,0,
161,11,58,4,0,
161,11,76,4,0,
161,11,85,4,0,
161,11,91,4,0,
161,11,92,4,0,
161,11,98,1,7,
161,11,104,4,0,
161,11,111,1,4,
161,11,116,2,0,
161,11,133,1,36,
161,11,154,1,13,
161,11,156,1,28,
161,11,156,4,0,
161,11,163,2,0,
161,11,164,4,0,
161,11,168,4,0,
161,11,179,2,0,
161,11,182,4,0,
161,11,193,1,1,2
161,11,204,2,0,
161,11,207,4,0,
161,11,213,4,0,
161,11,216,4,0,
161,11,218,4,0,
161,11,226,1,39,
161,11,228,2,0,
161,11,231,2,0,
161,11,237,4,0,
161,11,240,4,0,
161,11,241,4,0,
161,11,247,4,0,
161,11,263,4,0,
161,11,266,1,19,
161,11,270,1,16,
161,11,271,2,0,
161,11,274,2,0,
161,11,280,4,0,
161,11,304,1,47,
161,11,343,2,0,
161,11,363,2,0,
161,11,369,4,0,
161,11,374,4,0,
161,11,382,1,42,
161,11,387,2,0,
161,11,389,1,31,
161,11,421,4,0,
161,11,447,4,0,
161,11,451,4,0,
161,11,468,4,0,
161,11,496,4,0,
161,11,497,4,0,
161,11,514,4,0,
161,11,526,4,0,
161,12,10,1,1,
161,12,15,4,0,
161,12,21,1,24,
161,12,53,4,0,
161,12,57,4,0,
161,12,58,4,0,
161,12,76,4,0,
161,12,85,4,0,
161,12,91,4,0,
161,12,92,4,0,
161,12,98,1,7,
161,12,104,4,0,
161,12,111,1,4,
161,12,133,1,49,
161,12,154,1,12,
161,12,156,1,40,
161,12,156,4,0,
161,12,168,4,0,
161,12,182,4,0,
161,12,213,4,0,
161,12,216,4,0,
161,12,218,4,0,
161,12,231,4,0,
161,12,237,4,0,
161,12,240,4,0,
161,12,241,4,0,
161,12,247,4,0,
161,12,263,4,0,
161,12,264,4,0,
161,12,266,1,31,
161,12,270,1,17,
161,12,280,4,0,
161,12,290,4,0,
161,12,351,4,0,
161,12,352,4,0,
161,13,10,1,1,
161,13,15,4,0,
161,13,21,1,24,
161,13,34,3,0,
161,13,38,3,0,
161,13,53,4,0,
161,13,57,4,0,
161,13,58,4,0,
161,13,76,4,0,
161,13,85,4,0,
161,13,91,4,0,
161,13,92,4,0,
161,13,98,1,7,
161,13,102,3,0,
161,13,104,4,0,
161,13,111,1,4,
161,13,133,1,49,
161,13,154,1,12,
161,13,156,1,40,
161,13,156,4,0,
161,13,164,3,0,
161,13,168,4,0,
161,13,182,4,0,
161,13,207,3,0,
161,13,213,4,0,
161,13,216,4,0,
161,13,218,4,0,
161,13,231,4,0,
161,13,237,4,0,
161,13,240,4,0,
161,13,241,4,0,
161,13,247,4,0,
161,13,263,4,0,
161,13,264,4,0,
161,13,266,1,31,
161,13,270,1,17,
161,13,280,4,0,
161,13,290,4,0,
161,13,351,4,0,
161,13,352,4,0,
161,14,7,3,0,
161,14,8,3,0,
161,14,9,3,0,
161,14,10,1,1,1
161,14,15,4,0,
161,14,21,1,25,
161,14,38,2,0,
161,14,53,4,0,
161,14,57,4,0,
161,14,58,4,0,
161,14,76,4,0,
161,14,85,4,0,
161,14,91,4,0,
161,14,92,4,0,
161,14,98,1,7,
161,14,104,4,0,
161,14,111,1,4,
161,14,116,2,0,
161,14,133,1,36,
161,14,154,1,13,
161,14,156,1,28,
161,14,156,4,0,
161,14,162,3,0,
161,14,163,2,0,
161,14,164,4,0,
161,14,168,4,0,
161,14,173,3,0,
161,14,179,2,0,
161,14,182,4,0,
161,14,193,1,1,2
161,14,204,2,0,
161,14,207,4,0,
161,14,213,4,0,
161,14,214,3,0,
161,14,216,4,0,
161,14,218,4,0,
161,14,226,1,39,
161,14,228,2,0,
161,14,231,2,0,
161,14,231,3,0,
161,14,237,4,0,
161,14,240,4,0,
161,14,241,4,0,
161,14,247,4,0,
161,14,253,3,0,
161,14,263,4,0,
161,14,266,1,19,
161,14,270,1,16,
161,14,270,3,0,
161,14,271,2,0,
161,14,271,3,0,
161,14,274,2,0,
161,14,280,4,0,
161,14,282,3,0,
161,14,304,1,47,
161,14,304,3,0,
161,14,343,2,0,
161,14,343,3,0,
161,14,363,2,0,
161,14,369,4,0,
161,14,374,4,0,
161,14,382,1,42,
161,14,387,2,0,
161,14,387,3,0,
161,14,389,1,31,
161,14,401,3,0,
161,14,421,4,0,
161,14,447,4,0,
161,14,451,4,0,
161,14,468,4,0,
161,14,496,4,0,
161,14,497,4,0,
161,14,514,4,0,
161,14,526,4,0,
161,15,10,1,1,1
161,15,15,4,0,
161,15,21,1,25,
161,15,38,2,0,
161,15,53,4,0,
161,15,57,4,0,
161,15,58,4,0,
161,15,76,4,0,
161,15,85,4,0,
161,15,91,4,0,
161,15,92,4,0,
161,15,98,1,7,
161,15,104,4,0,
161,15,111,1,4,
161,15,116,2,0,
161,15,133,1,36,
161,15,154,1,13,
161,15,156,1,28,
161,15,156,4,0,
161,15,163,2,0,
161,15,164,4,0,
161,15,168,4,0,
161,15,179,2,0,
161,15,182,4,0,
161,15,193,1,1,2
161,15,204,2,0,
161,15,207,4,0,
161,15,213,4,0,
161,15,214,4,0,
161,15,216,4,0,
161,15,218,4,0,
161,15,226,1,39,
161,15,228,2,0,
161,15,231,2,0,
161,15,237,4,0,
161,15,240,4,0,
161,15,241,4,0,
161,15,247,4,0,
161,15,263,4,0,
161,15,266,1,19,
161,15,270,1,16,
161,15,271,2,0,
161,15,274,2,0,
161,15,280,4,0,
161,15,304,1,47,
161,15,343,2,0,
161,15,363,2,0,
161,15,369,4,0,
161,15,374,4,0,
161,15,382,1,42,
161,15,387,2,0,
161,15,389,1,31,
161,15,421,4,0,
161,15,445,2,0,
161,15,447,4,0,
161,15,451,4,0,
161,15,468,4,0,
161,15,496,4,0,
161,15,497,4,0,
161,15,514,4,0,
161,15,590,4,0,
161,15,612,4,0,
161,16,7,3,0,
161,16,8,3,0,
161,16,9,3,0,
161,16,10,1,1,1
161,16,15,4,0,
161,16,21,1,25,1
161,16,38,2,0,
161,16,53,4,0,
161,16,57,4,0,
161,16,58,4,0,
161,16,76,4,0,
161,16,85,4,0,
161,16,91,4,0,
161,16,92,4,0,
161,16,98,1,7,1
161,16,104,4,0,
161,16,111,1,4,1
161,16,116,2,0,
161,16,133,1,36,1
161,16,154,1,13,1
161,16,156,1,28,1
161,16,156,4,0,
161,16,162,3,0,
161,16,163,2,0,
161,16,164,4,0,
161,16,168,4,0,
161,16,173,3,0,
161,16,179,2,0,
161,16,182,4,0,
161,16,193,1,1,2
161,16,204,2,0,
161,16,207,4,0,
161,16,213,4,0,
161,16,214,4,0,
161,16,216,4,0,
161,16,218,4,0,
161,16,226,1,39,1
161,16,228,2,0,
161,16,231,2,0,
161,16,231,3,0,
161,16,237,4,0,
161,16,240,4,0,
161,16,241,4,0,
161,16,247,4,0,
161,16,253,3,0,
161,16,263,4,0,
161,16,264,3,0,
161,16,266,1,19,1
161,16,270,1,16,1
161,16,270,3,0,
161,16,271,2,0,
161,16,271,3,0,
161,16,274,2,0,
161,16,280,4,0,
161,16,282,3,0,
161,16,290,4,0,
161,16,304,1,47,1
161,16,304,3,0,
161,16,343,2,0,
161,16,343,3,0,
161,16,351,3,0,
161,16,352,3,0,
161,16,363,2,0,
161,16,369,4,0,
161,16,374,4,0,
161,16,382,1,42,1
161,16,387,2,0,
161,16,387,3,0,
161,16,389,1,31,1
161,16,401,3,0,
161,16,421,4,0,
161,16,445,2,0,
161,16,447,4,0,
161,16,451,4,0,
161,16,468,4,0,
161,16,496,4,0,
161,16,497,4,0,
161,16,514,4,0,
161,16,590,4,0,
161,16,612,4,0,
161,17,10,1,1,1
161,17,21,1,25,
161,17,38,2,0,
161,17,53,4,0,
161,17,57,4,0,
161,17,58,4,0,
161,17,76,4,0,
161,17,85,4,0,
161,17,92,4,0,
161,17,98,1,7,
161,17,104,4,0,
161,17,111,1,4,
161,17,116,2,0,
161,17,133,1,36,
161,17,154,1,13,
161,17,156,1,28,
161,17,156,4,0,
161,17,163,2,0,
161,17,164,4,0,
161,17,168,4,0,
161,17,179,2,0,
161,17,182,4,0,
161,17,193,1,1,2
161,17,204,2,0,
161,17,207,4,0,
161,17,213,4,0,
161,17,214,4,0,
161,17,216,4,0,
161,17,218,4,0,
161,17,226,1,39,
161,17,228,2,0,
161,17,231,2,0,
161,17,237,4,0,
161,17,240,4,0,
161,17,241,4,0,
161,17,247,4,0,
161,17,263,4,0,
161,17,266,1,19,
161,17,270,1,16,
161,17,271,2,0,
161,17,274,2,0,
161,17,280,4,0,
161,17,304,1,47,
161,17,343,2,0,
161,17,363,2,0,
161,17,369,4,0,
161,17,374,4,0,
161,17,382,1,42,
161,17,387,2,0,
161,17,389,1,31,
161,17,421,4,0,
161,17,445,2,0,
161,17,447,4,0,
161,17,451,4,0,
161,17,496,4,0,
161,17,497,4,0,
161,17,526,4,0,
161,17,590,4,0,
161,17,693,4,0,
161,18,10,1,1,1
161,18,21,1,25,
161,18,38,2,0,
161,18,53,4,0,
161,18,57,4,0,
161,18,58,4,0,
161,18,76,4,0,
161,18,85,4,0,
161,18,92,4,0,
161,18,98,1,7,
161,18,104,4,0,
161,18,111,1,4,
161,18,116,2,0,
161,18,133,1,36,
161,18,154,1,13,
161,18,156,1,28,
161,18,156,4,0,
161,18,163,2,0,
161,18,164,4,0,
161,18,168,4,0,
161,18,179,2,0,
161,18,182,4,0,
161,18,193,1,1,2
161,18,204,2,0,
161,18,207,4,0,
161,18,213,4,0,
161,18,214,4,0,
161,18,216,4,0,
161,18,218,4,0,
161,18,226,1,39,
161,18,228,2,0,
161,18,231,2,0,
161,18,237,4,0,
161,18,240,4,0,
161,18,241,4,0,
161,18,247,4,0,
161,18,263,4,0,
161,18,266,1,19,
161,18,270,1,16,
161,18,271,2,0,
161,18,274,2,0,
161,18,280,4,0,
161,18,304,1,47,
161,18,343,2,0,
161,18,363,2,0,
161,18,369,4,0,
161,18,374,4,0,
161,18,382,1,42,
161,18,387,2,0,
161,18,389,1,31,
161,18,421,4,0,
161,18,445,2,0,
161,18,447,4,0,
161,18,451,4,0,
161,18,496,4,0,
161,18,497,4,0,
161,18,526,4,0,
161,18,590,4,0,
161,18,608,2,0,
161,18,693,4,0,
162,3,7,4,0,
162,3,8,4,0,
162,3,9,4,0,
162,3,10,1,1,1
162,3,15,4,0,
162,3,21,1,28,
162,3,29,4,0,
162,3,57,4,0,
162,3,63,4,0,
162,3,70,4,0,
162,3,91,4,0,
162,3,92,4,0,
162,3,98,1,1,3
162,3,98,1,11,
162,3,104,4,0,
162,3,111,1,1,2
162,3,111,1,5,
162,3,111,4,0,
162,3,129,4,0,
162,3,133,1,48,
162,3,154,1,18,
162,3,156,1,38,
162,3,156,4,0,
162,3,168,4,0,
162,3,173,4,0,
162,3,174,4,0,
162,3,182,4,0,
162,3,189,4,0,
162,3,197,4,0,
162,3,203,4,0,
162,3,205,4,0,
162,3,207,4,0,
162,3,210,4,0,
162,3,213,4,0,
162,3,214,4,0,
162,3,216,4,0,
162,3,218,4,0,
162,3,223,4,0,
162,3,231,4,0,
162,3,237,4,0,
162,3,241,4,0,
162,3,247,4,0,
162,4,7,4,0,
162,4,8,4,0,
162,4,9,4,0,
162,4,10,1,1,1
162,4,15,4,0,
162,4,21,1,28,
162,4,29,4,0,
162,4,57,4,0,
162,4,63,4,0,
162,4,70,4,0,
162,4,91,4,0,
162,4,92,4,0,
162,4,98,1,1,3
162,4,98,1,11,
162,4,104,4,0,
162,4,111,1,1,2
162,4,111,1,5,
162,4,111,4,0,
162,4,129,4,0,
162,4,133,1,48,
162,4,154,1,18,
162,4,156,1,38,
162,4,156,4,0,
162,4,168,4,0,
162,4,173,4,0,
162,4,174,4,0,
162,4,182,4,0,
162,4,189,4,0,
162,4,197,4,0,
162,4,203,4,0,
162,4,205,4,0,
162,4,207,4,0,
162,4,210,4,0,
162,4,213,4,0,
162,4,214,4,0,
162,4,216,4,0,
162,4,218,4,0,
162,4,223,4,0,
162,4,231,4,0,
162,4,237,4,0,
162,4,241,4,0,
162,4,247,4,0,
162,5,10,1,1,1
162,5,15,4,0,
162,5,21,1,28,
162,5,53,4,0,
162,5,57,4,0,
162,5,58,4,0,
162,5,59,4,0,
162,5,63,4,0,
162,5,70,4,0,
162,5,76,4,0,
162,5,85,4,0,
162,5,87,4,0,
162,5,91,4,0,
162,5,92,4,0,
162,5,98,1,1,3
162,5,98,1,7,
162,5,104,4,0,
162,5,111,1,1,2
162,5,111,1,4,
162,5,133,1,59,
162,5,154,1,12,
162,5,156,1,48,
162,5,156,4,0,
162,5,168,4,0,
162,5,182,4,0,
162,5,213,4,0,
162,5,216,4,0,
162,5,218,4,0,
162,5,231,4,0,
162,5,237,4,0,
162,5,240,4,0,
162,5,241,4,0,
162,5,247,4,0,
162,5,249,4,0,
162,5,263,4,0,
162,5,264,4,0,
162,5,266,1,37,
162,5,270,1,19,
162,5,280,4,0,
162,5,290,4,0,
162,5,351,4,0,
162,5,352,4,0,
162,6,7,3,0,
162,6,8,3,0,
162,6,9,3,0,
162,6,10,1,1,1
162,6,15,4,0,
162,6,21,1,28,
162,6,34,3,0,
162,6,38,3,0,
162,6,53,4,0,
162,6,57,4,0,
162,6,58,4,0,
162,6,59,4,0,
162,6,63,4,0,
162,6,70,4,0,
162,6,76,4,0,
162,6,85,4,0,
162,6,87,4,0,
162,6,91,4,0,
162,6,92,4,0,
162,6,98,1,1,3
162,6,98,1,7,
162,6,102,3,0,
162,6,104,4,0,
162,6,111,1,1,2
162,6,111,1,4,
162,6,111,3,0,
162,6,129,3,0,
162,6,133,1,59,
162,6,154,1,12,
162,6,156,1,48,
162,6,156,4,0,
162,6,164,3,0,
162,6,168,4,0,
162,6,173,3,0,
162,6,182,4,0,
162,6,189,3,0,
162,6,203,3,0,
162,6,205,3,0,
162,6,207,3,0,
162,6,210,3,0,
162,6,213,4,0,
162,6,214,3,0,
162,6,216,4,0,
162,6,218,4,0,
162,6,223,3,0,
162,6,231,4,0,
162,6,237,4,0,
162,6,240,4,0,
162,6,241,4,0,
162,6,247,4,0,
162,6,249,4,0,
162,6,263,4,0,
162,6,264,4,0,
162,6,266,1,37,
162,6,270,1,19,
162,6,280,4,0,
162,6,290,4,0,
162,6,351,4,0,
162,6,352,4,0,
162,7,10,1,1,1
162,7,15,4,0,
162,7,21,1,28,
162,7,34,3,0,
162,7,38,3,0,
162,7,53,4,0,
162,7,57,4,0,
162,7,58,4,0,
162,7,59,4,0,
162,7,63,4,0,
162,7,70,4,0,
162,7,76,4,0,
162,7,85,4,0,
162,7,87,4,0,
162,7,91,4,0,
162,7,92,4,0,
162,7,98,1,1,3
162,7,98,1,7,
162,7,102,3,0,
162,7,104,4,0,
162,7,111,1,1,2
162,7,111,1,4,
162,7,133,1,59,
162,7,154,1,12,
162,7,156,1,48,
162,7,156,4,0,
162,7,164,3,0,
162,7,168,4,0,
162,7,182,4,0,
162,7,213,4,0,
162,7,216,4,0,
162,7,218,4,0,
162,7,231,4,0,
162,7,237,4,0,
162,7,240,4,0,
162,7,241,4,0,
162,7,247,4,0,
162,7,249,4,0,
162,7,263,4,0,
162,7,264,4,0,
162,7,266,1,37,
162,7,270,1,19,
162,7,280,4,0,
162,7,290,4,0,
162,7,351,4,0,
162,7,352,4,0,
162,8,10,1,1,1
162,8,15,4,0,
162,8,21,1,28,
162,8,53,4,0,
162,8,57,4,0,
162,8,58,4,0,
162,8,59,4,0,
162,8,63,4,0,
162,8,70,4,0,
162,8,76,4,0,
162,8,85,4,0,
162,8,87,4,0,
162,8,91,4,0,
162,8,92,4,0,
162,8,98,1,1,4
162,8,98,1,7,
162,8,104,4,0,
162,8,111,1,1,3
162,8,111,1,4,
162,8,133,1,42,
162,8,154,1,13,
162,8,156,1,32,
162,8,156,4,0,
162,8,164,4,0,
162,8,168,4,0,
162,8,182,4,0,
162,8,193,1,1,2
162,8,203,4,0,
162,8,207,4,0,
162,8,213,4,0,
162,8,214,4,0,
162,8,216,4,0,
162,8,218,4,0,
162,8,226,1,46,
162,8,231,4,0,
162,8,237,4,0,
162,8,240,4,0,
162,8,241,4,0,
162,8,247,4,0,
162,8,249,4,0,
162,8,263,4,0,
162,8,264,4,0,
162,8,266,1,21,
162,8,270,1,17,
162,8,280,4,0,
162,8,290,4,0,
162,8,304,1,56,
162,8,351,4,0,
162,8,352,4,0,
162,8,363,4,0,
162,8,369,4,0,
162,8,374,4,0,
162,8,382,1,50,
162,8,389,1,36,
162,8,411,4,0,
162,8,416,4,0,
162,8,421,4,0,
162,8,445,4,0,
162,8,447,4,0,
162,8,451,4,0,
162,9,7,3,0,
162,9,8,3,0,
162,9,9,3,0,
162,9,10,1,1,1
162,9,15,4,0,
162,9,21,1,28,
162,9,53,4,0,
162,9,57,4,0,
162,9,58,4,0,
162,9,59,4,0,
162,9,63,4,0,
162,9,70,4,0,
162,9,76,4,0,
162,9,85,4,0,
162,9,87,4,0,
162,9,91,4,0,
162,9,92,4,0,
162,9,98,1,1,4
162,9,98,1,7,
162,9,104,4,0,
162,9,111,1,1,3
162,9,111,1,4,
162,9,129,3,0,
162,9,133,1,42,
162,9,154,1,13,
162,9,156,1,32,
162,9,156,4,0,
162,9,164,4,0,
162,9,168,4,0,
162,9,173,3,0,
162,9,182,4,0,
162,9,189,3,0,
162,9,193,1,1,2
162,9,203,4,0,
162,9,205,3,0,
162,9,207,4,0,
162,9,210,3,0,
162,9,213,4,0,
162,9,214,4,0,
162,9,216,4,0,
162,9,218,4,0,
162,9,226,1,46,
162,9,231,4,0,
162,9,237,4,0,
162,9,240,4,0,
162,9,241,4,0,
162,9,247,4,0,
162,9,249,4,0,
162,9,253,3,0,
162,9,263,4,0,
162,9,264,4,0,
162,9,266,1,21,
162,9,270,1,17,
162,9,270,3,0,
162,9,280,4,0,
162,9,282,3,0,
162,9,290,4,0,
162,9,304,1,56,
162,9,351,4,0,
162,9,352,4,0,
162,9,363,4,0,
162,9,369,4,0,
162,9,374,4,0,
162,9,382,1,50,
162,9,387,3,0,
162,9,389,1,36,
162,9,389,3,0,
162,9,401,3,0,
162,9,411,4,0,
162,9,416,4,0,
162,9,421,4,0,
162,9,445,4,0,
162,9,447,4,0,
162,9,451,4,0,
162,10,7,3,0,
162,10,8,3,0,
162,10,9,3,0,
162,10,10,1,1,1
162,10,15,4,0,
162,10,21,1,28,
162,10,29,3,0,
162,10,53,4,0,
162,10,57,4,0,
162,10,58,4,0,
162,10,59,4,0,
162,10,63,4,0,
162,10,70,4,0,
162,10,76,4,0,
162,10,85,4,0,
162,10,87,4,0,
162,10,91,4,0,
162,10,92,4,0,
162,10,98,1,1,4
162,10,98,1,7,
162,10,104,4,0,
162,10,111,1,1,3
162,10,111,1,4,
162,10,129,3,0,
162,10,133,1,42,
162,10,154,1,13,
162,10,156,1,32,
162,10,156,4,0,
162,10,162,3,0,
162,10,164,4,0,
162,10,168,4,0,
162,10,173,3,0,
162,10,182,4,0,
162,10,189,3,0,
162,10,193,1,1,2
162,10,203,4,0,
162,10,205,3,0,
162,10,207,4,0,
162,10,210,3,0,
162,10,213,4,0,
162,10,214,4,0,
162,10,216,4,0,
162,10,218,4,0,
162,10,226,1,46,
162,10,231,4,0,
162,10,237,4,0,
162,10,240,4,0,
162,10,241,4,0,
162,10,247,4,0,
162,10,249,4,0,
162,10,250,4,0,
162,10,253,3,0,
162,10,263,4,0,
162,10,264,4,0,
162,10,266,1,21,
162,10,270,1,17,
162,10,270,3,0,
162,10,280,4,0,
162,10,282,3,0,
162,10,290,4,0,
162,10,304,1,56,
162,10,351,4,0,
162,10,352,4,0,
162,10,363,4,0,
162,10,369,4,0,
162,10,374,4,0,
162,10,382,1,50,
162,10,387,3,0,
162,10,389,1,36,
162,10,389,3,0,
162,10,401,3,0,
162,10,411,4,0,
162,10,416,4,0,
162,10,421,4,0,
162,10,445,4,0,
162,10,447,4,0,
162,10,451,4,0,
162,11,10,1,1,1
162,11,15,4,0,
162,11,21,1,28,
162,11,53,4,0,
162,11,57,4,0,
162,11,58,4,0,
162,11,59,4,0,
162,11,63,4,0,
162,11,70,4,0,
162,11,76,4,0,
162,11,85,4,0,
162,11,87,4,0,
162,11,91,4,0,
162,11,92,4,0,
162,11,98,1,1,4
162,11,98,1,7,
162,11,104,4,0,
162,11,111,1,1,3
162,11,111,1,4,
162,11,133,1,42,
162,11,154,1,13,
162,11,156,1,32,
162,11,156,4,0,
162,11,164,4,0,
162,11,168,4,0,
162,11,182,4,0,
162,11,193,1,1,2
162,11,207,4,0,
162,11,213,4,0,
162,11,216,4,0,
162,11,218,4,0,
162,11,226,1,46,
162,11,237,4,0,
162,11,240,4,0,
162,11,241,4,0,
162,11,247,4,0,
162,11,249,4,0,
162,11,263,4,0,
162,11,266,1,21,
162,11,270,1,17,
162,11,280,4,0,
162,11,304,1,56,
162,11,369,4,0,
162,11,374,4,0,
162,11,382,1,50,
162,11,389,1,36,
162,11,411,4,0,
162,11,416,4,0,
162,11,421,4,0,
162,11,447,4,0,
162,11,451,4,0,
162,11,468,4,0,
162,11,496,4,0,
162,11,497,4,0,
162,11,514,4,0,
162,11,526,4,0,
162,12,10,1,1,1
162,12,15,4,0,
162,12,21,1,28,
162,12,53,4,0,
162,12,57,4,0,
162,12,58,4,0,
162,12,59,4,0,
162,12,63,4,0,
162,12,70,4,0,
162,12,76,4,0,
162,12,85,4,0,
162,12,87,4,0,
162,12,91,4,0,
162,12,92,4,0,
162,12,98,1,1,3
162,12,98,1,7,
162,12,104,4,0,
162,12,111,1,1,2
162,12,111,1,4,
162,12,133,1,59,
162,12,154,1,12,
162,12,156,1,48,
162,12,156,4,0,
162,12,168,4,0,
162,12,182,4,0,
162,12,213,4,0,
162,12,216,4,0,
162,12,218,4,0,
162,12,231,4,0,
162,12,237,4,0,
162,12,240,4,0,
162,12,241,4,0,
162,12,247,4,0,
162,12,249,4,0,
162,12,263,4,0,
162,12,264,4,0,
162,12,266,1,37,
162,12,270,1,19,
162,12,280,4,0,
162,12,290,4,0,
162,12,351,4,0,
162,12,352,4,0,
162,13,10,1,1,1
162,13,15,4,0,
162,13,21,1,28,
162,13,34,3,0,
162,13,38,3,0,
162,13,53,4,0,
162,13,57,4,0,
162,13,58,4,0,
162,13,59,4,0,
162,13,63,4,0,
162,13,70,4,0,
162,13,76,4,0,
162,13,85,4,0,
162,13,87,4,0,
162,13,91,4,0,
162,13,92,4,0,
162,13,98,1,1,3
162,13,98,1,7,
162,13,102,3,0,
162,13,104,4,0,
162,13,111,1,1,2
162,13,111,1,4,
162,13,133,1,59,
162,13,154,1,12,
162,13,156,1,48,
162,13,156,4,0,
162,13,164,3,0,
162,13,168,4,0,
162,13,182,4,0,
162,13,207,3,0,
162,13,213,4,0,
162,13,216,4,0,
162,13,218,4,0,
162,13,231,4,0,
162,13,237,4,0,
162,13,240,4,0,
162,13,241,4,0,
162,13,247,4,0,
162,13,249,4,0,
162,13,263,4,0,
162,13,264,4,0,
162,13,266,1,37,
162,13,270,1,19,
162,13,280,4,0,
162,13,290,4,0,
162,13,351,4,0,
162,13,352,4,0,
162,14,7,3,0,
162,14,8,3,0,
162,14,9,3,0,
162,14,10,1,1,1
162,14,15,4,0,
162,14,21,1,28,
162,14,53,4,0,
162,14,57,4,0,
162,14,58,4,0,
162,14,59,4,0,
162,14,63,4,0,
162,14,70,4,0,
162,14,76,4,0,
162,14,85,4,0,
162,14,87,4,0,
162,14,91,4,0,
162,14,92,4,0,
162,14,98,1,1,4
162,14,98,1,7,
162,14,104,4,0,
162,14,111,1,1,3
162,14,111,1,4,
162,14,133,1,42,
162,14,154,1,13,
162,14,156,1,32,
162,14,156,4,0,
162,14,162,3,0,
162,14,164,4,0,
162,14,168,4,0,
162,14,173,3,0,
162,14,182,4,0,
162,14,193,1,1,2
162,14,207,4,0,
162,14,213,4,0,
162,14,214,3,0,
162,14,216,4,0,
162,14,218,4,0,
162,14,226,1,46,
162,14,231,3,0,
162,14,237,4,0,
162,14,240,4,0,
162,14,241,4,0,
162,14,247,4,0,
162,14,249,4,0,
162,14,253,3,0,
162,14,263,4,0,
162,14,266,1,21,
162,14,270,1,17,
162,14,270,3,0,
162,14,271,3,0,
162,14,280,4,0,
162,14,282,3,0,
162,14,304,1,56,
162,14,304,3,0,
162,14,343,3,0,
162,14,369,4,0,
162,14,374,4,0,
162,14,382,1,50,
162,14,387,3,0,
162,14,389,1,36,
162,14,401,3,0,
162,14,411,4,0,
162,14,416,4,0,
162,14,421,4,0,
162,14,447,4,0,
162,14,451,4,0,
162,14,468,4,0,
162,14,496,4,0,
162,14,497,4,0,
162,14,514,4,0,
162,14,526,4,0,
162,15,10,1,1,1
162,15,15,4,0,
162,15,21,1,28,
162,15,53,4,0,
162,15,57,4,0,
162,15,58,4,0,
162,15,59,4,0,
162,15,63,4,0,
162,15,70,4,0,
162,15,76,4,0,
162,15,85,4,0,
162,15,87,4,0,
162,15,91,4,0,
162,15,92,4,0,
162,15,98,1,1,4
162,15,98,1,7,
162,15,104,4,0,
162,15,111,1,1,3
162,15,111,1,4,
162,15,133,1,42,
162,15,154,1,13,
162,15,156,1,32,
162,15,156,4,0,
162,15,164,4,0,
162,15,168,4,0,
162,15,182,4,0,
162,15,193,1,1,2
162,15,207,4,0,
162,15,213,4,0,
162,15,214,4,0,
162,15,216,4,0,
162,15,218,4,0,
162,15,226,1,46,
162,15,237,4,0,
162,15,240,4,0,
162,15,241,4,0,
162,15,247,4,0,
162,15,249,4,0,
162,15,263,4,0,
162,15,266,1,21,
162,15,270,1,17,
162,15,280,4,0,
162,15,304,1,56,
162,15,369,4,0,
162,15,374,4,0,
162,15,382,1,50,
162,15,389,1,36,
162,15,411,4,0,
162,15,416,4,0,
162,15,421,4,0,
162,15,447,4,0,
162,15,451,4,0,
162,15,468,4,0,
162,15,496,4,0,
162,15,497,4,0,
162,15,514,4,0,
162,15,590,4,0,
162,15,612,4,0,
162,16,7,3,0,
162,16,8,3,0,
162,16,9,3,0,
162,16,10,1,1,1
162,16,15,4,0,
162,16,21,1,28,1
162,16,53,4,0,
162,16,57,4,0,
162,16,58,4,0,
162,16,59,4,0,
162,16,63,4,0,
162,16,70,4,0,
162,16,76,4,0,
162,16,85,4,0,
162,16,87,4,0,
162,16,91,4,0,
162,16,92,4,0,
162,16,98,1,1,4
162,16,98,1,7,1
162,16,104,4,0,
162,16,111,1,1,3
162,16,111,1,4,1
162,16,133,1,42,1
162,16,154,1,13,1
162,16,156,1,32,1
162,16,156,4,0,
162,16,162,3,0,
162,16,164,4,0,
162,16,168,4,0,
162,16,173,3,0,
162,16,182,4,0,
162,16,193,1,1,2
162,16,207,4,0,
162,16,213,4,0,
162,16,214,4,0,
162,16,216,4,0,
162,16,218,4,0,
162,16,226,1,46,1
162,16,231,3,0,
162,16,237,4,0,
162,16,240,4,0,
162,16,241,4,0,
162,16,247,4,0,
162,16,249,4,0,
162,16,253,3,0,
162,16,263,4,0,
162,16,264,3,0,
162,16,266,1,21,1
162,16,270,1,17,1
162,16,270,3,0,
162,16,271,3,0,
162,16,280,4,0,
162,16,282,3,0,
162,16,290,4,0,
162,16,304,1,56,1
162,16,304,3,0,
162,16,343,3,0,
162,16,351,3,0,
162,16,352,3,0,
162,16,369,4,0,
162,16,374,4,0,
162,16,382,1,50,1
162,16,387,3,0,
162,16,389,1,36,1
162,16,401,3,0,
162,16,411,4,0,
162,16,416,4,0,
162,16,421,4,0,
162,16,447,4,0,
162,16,451,4,0,
162,16,468,4,0,
162,16,496,4,0,
162,16,497,4,0,
162,16,514,4,0,
162,16,590,4,0,
162,16,612,4,0,
162,17,10,1,1,3
162,17,21,1,28,
162,17,53,4,0,
162,17,57,4,0,
162,17,58,4,0,
162,17,59,4,0,
162,17,63,4,0,
162,17,76,4,0,
162,17,85,4,0,
162,17,87,4,0,
162,17,92,4,0,
162,17,97,1,0,
162,17,97,1,1,1
162,17,98,1,1,6
162,17,98,1,7,
162,17,104,4,0,
162,17,111,1,1,5
162,17,111,1,4,
162,17,133,1,42,
162,17,154,1,13,
162,17,156,1,32,
162,17,156,4,0,
162,17,164,4,0,
162,17,168,4,0,
162,17,182,4,0,
162,17,193,1,1,4
162,17,207,4,0,
162,17,213,4,0,
162,17,214,4,0,
162,17,216,4,0,
162,17,218,4,0,
162,17,226,1,46,
162,17,237,4,0,
162,17,240,4,0,
162,17,241,4,0,
162,17,247,4,0,
162,17,263,4,0,
162,17,266,1,21,
162,17,270,1,17,
162,17,280,4,0,
162,17,304,1,56,
162,17,369,4,0,
162,17,374,4,0,
162,17,382,1,50,
162,17,389,1,36,
162,17,411,4,0,
162,17,416,4,0,
162,17,421,4,0,
162,17,447,4,0,
162,17,451,4,0,
162,17,489,1,1,2
162,17,496,4,0,
162,17,497,4,0,
162,17,526,4,0,
162,17,590,4,0,
162,17,693,4,0,
162,18,10,1,1,3
162,18,21,1,28,
162,18,53,4,0,
162,18,57,4,0,
162,18,58,4,0,
162,18,59,4,0,
162,18,63,4,0,
162,18,76,4,0,
162,18,85,4,0,
162,18,87,4,0,
162,18,92,4,0,
162,18,97,1,0,
162,18,97,1,1,1
162,18,98,1,1,6
162,18,98,1,7,
162,18,104,4,0,
162,18,111,1,1,5
162,18,111,1,4,
162,18,133,1,42,
162,18,154,1,13,
162,18,156,1,32,
162,18,156,4,0,
162,18,164,4,0,
162,18,168,4,0,
162,18,182,4,0,
162,18,193,1,1,4
162,18,207,4,0,
162,18,213,4,0,
162,18,214,4,0,
162,18,216,4,0,
162,18,218,4,0,
162,18,226,1,46,
162,18,237,4,0,
162,18,240,4,0,
162,18,241,4,0,
162,18,247,4,0,
162,18,263,4,0,
162,18,266,1,21,
162,18,270,1,17,
162,18,280,4,0,
162,18,304,1,56,
162,18,369,4,0,
162,18,374,4,0,
162,18,382,1,50,
162,18,389,1,36,
162,18,411,4,0,
162,18,416,4,0,
162,18,421,4,0,
162,18,447,4,0,
162,18,451,4,0,
162,18,489,1,1,2
162,18,496,4,0,
162,18,497,4,0,
162,18,526,4,0,
162,18,590,4,0,
162,18,693,4,0,
163,3,17,2,0,
163,3,18,2,0,
163,3,19,4,0,
163,3,33,1,1,1
163,3,36,1,28,
163,3,45,1,1,2
163,3,48,2,0,
163,3,64,1,11,
163,3,92,4,0,
163,3,93,1,34,
163,3,95,1,16,
163,3,104,4,0,
163,3,115,1,22,
163,3,119,2,0,
163,3,129,4,0,
163,3,138,1,48,
163,3,138,4,0,
163,3,148,4,0,
163,3,156,4,0,
163,3,168,4,0,
163,3,171,4,0,
163,3,173,4,0,
163,3,174,4,0,
163,3,182,4,0,
163,3,185,2,0,
163,3,189,4,0,
163,3,193,1,6,
163,3,197,4,0,
163,3,203,4,0,
163,3,207,4,0,
163,3,211,4,0,
163,3,213,4,0,
163,3,214,4,0,
163,3,216,4,0,
163,3,218,4,0,
163,3,237,4,0,
163,3,241,4,0,
163,4,17,2,0,
163,4,18,2,0,
163,4,19,4,0,
163,4,33,1,1,1
163,4,36,1,28,
163,4,45,1,1,2
163,4,48,2,0,
163,4,64,1,11,
163,4,92,4,0,
163,4,93,1,34,
163,4,95,1,16,
163,4,104,4,0,
163,4,115,1,22,
163,4,119,2,0,
163,4,129,4,0,
163,4,138,1,48,
163,4,138,4,0,
163,4,143,2,0,
163,4,148,4,0,
163,4,156,4,0,
163,4,168,4,0,
163,4,171,4,0,
163,4,173,4,0,
163,4,174,4,0,
163,4,182,4,0,
163,4,185,2,0,
163,4,189,4,0,
163,4,193,1,6,
163,4,197,4,0,
163,4,203,4,0,
163,4,207,4,0,
163,4,211,4,0,
163,4,213,4,0,
163,4,214,4,0,
163,4,216,4,0,
163,4,218,4,0,
163,4,237,4,0,
163,4,241,4,0,
163,5,17,2,0,
163,5,18,2,0,
163,5,19,4,0,
163,5,33,1,1,1
163,5,36,1,28,
163,5,45,1,1,2
163,5,48,2,0,
163,5,64,1,11,
163,5,92,4,0,
163,5,93,1,34,
163,5,94,4,0,
163,5,95,1,16,
163,5,104,4,0,
163,5,115,1,22,
163,5,115,4,0,
163,5,119,2,0,
163,5,138,1,48,
163,5,143,2,0,
163,5,148,4,0,
163,5,156,4,0,
163,5,168,4,0,
163,5,182,4,0,
163,5,185,2,0,
163,5,193,1,6,
163,5,211,4,0,
163,5,213,4,0,
163,5,216,4,0,
163,5,218,4,0,
163,5,237,4,0,
163,5,240,4,0,
163,5,241,4,0,
163,5,247,4,0,
163,5,263,4,0,
163,5,290,4,0,
163,5,297,2,0,
163,5,332,4,0,
163,6,17,2,0,
163,6,18,2,0,
163,6,19,4,0,
163,6,33,1,1,1
163,6,36,1,28,
163,6,38,3,0,
163,6,45,1,1,2
163,6,48,2,0,
163,6,64,1,11,
163,6,92,4,0,
163,6,93,1,34,
163,6,94,4,0,
163,6,95,1,16,
163,6,102,3,0,
163,6,104,4,0,
163,6,115,1,22,
163,6,115,4,0,
163,6,119,2,0,
163,6,129,3,0,
163,6,138,1,48,
163,6,138,3,0,
163,6,143,2,0,
163,6,148,4,0,
163,6,156,4,0,
163,6,164,3,0,
163,6,168,4,0,
163,6,173,3,0,
163,6,182,4,0,
163,6,185,2,0,
163,6,189,3,0,
163,6,193,1,6,
163,6,203,3,0,
163,6,207,3,0,
163,6,211,4,0,
163,6,213,4,0,
163,6,214,3,0,
163,6,216,4,0,
163,6,218,4,0,
163,6,237,4,0,
163,6,240,4,0,
163,6,241,4,0,
163,6,247,4,0,
163,6,263,4,0,
163,6,290,4,0,
163,6,297,2,0,
163,6,332,4,0,
163,7,17,2,0,
163,7,18,2,0,
163,7,19,4,0,
163,7,33,1,1,1
163,7,36,1,28,
163,7,38,3,0,
163,7,45,1,1,2
163,7,48,2,0,
163,7,64,1,11,
163,7,92,4,0,
163,7,93,1,34,
163,7,94,4,0,
163,7,95,1,16,
163,7,102,3,0,
163,7,104,4,0,
163,7,115,1,22,
163,7,115,4,0,
163,7,119,2,0,
163,7,138,1,48,
163,7,138,3,0,
163,7,143,2,0,
163,7,148,4,0,
163,7,156,4,0,
163,7,164,3,0,
163,7,168,4,0,
163,7,182,4,0,
163,7,185,2,0,
163,7,193,1,6,
163,7,211,4,0,
163,7,213,4,0,
163,7,216,4,0,
163,7,218,4,0,
163,7,237,4,0,
163,7,240,4,0,
163,7,241,4,0,
163,7,247,4,0,
163,7,263,4,0,
163,7,290,4,0,
163,7,297,2,0,
163,7,332,4,0,
163,8,17,2,0,
163,8,18,2,0,
163,8,19,4,0,
163,8,33,1,1,1
163,8,36,1,25,
163,8,45,1,1,2
163,8,48,2,0,
163,8,64,1,13,
163,8,92,4,0,
163,8,93,1,21,
163,8,94,4,0,
163,8,95,1,9,
163,8,97,2,0,
163,8,101,2,0,
163,8,104,4,0,
163,8,115,1,17,
163,8,115,4,0,
163,8,119,2,0,
163,8,138,1,49,
163,8,138,4,0,
163,8,143,2,0,
163,8,156,4,0,
163,8,164,4,0,
163,8,168,4,0,
163,8,182,4,0,
163,8,185,2,0,
163,8,193,1,5,
163,8,203,4,0,
163,8,207,4,0,
163,8,211,4,0,
163,8,213,4,0,
163,8,214,4,0,
163,8,216,4,0,
163,8,218,4,0,
163,8,237,4,0,
163,8,240,4,0,
163,8,241,4,0,
163,8,244,4,0,
163,8,247,4,0,
163,8,263,4,0,
163,8,278,4,0,
163,8,290,4,0,
163,8,297,2,0,
163,8,318,4,0,
163,8,326,1,37,
163,8,332,4,0,
163,8,355,1,45,
163,8,355,4,0,
163,8,363,4,0,
163,8,365,4,0,
163,8,375,1,41,
163,8,403,1,29,
163,8,428,1,33,
163,8,432,4,0,
163,8,445,4,0,
163,9,17,2,0,
163,9,18,2,0,
163,9,19,4,0,
163,9,33,1,1,1
163,9,36,1,25,
163,9,45,1,1,2
163,9,48,2,0,
163,9,64,1,9,
163,9,92,4,0,
163,9,93,1,21,
163,9,94,4,0,
163,9,95,1,5,
163,9,97,2,0,
163,9,101,2,0,
163,9,104,4,0,
163,9,115,1,17,
163,9,115,4,0,
163,9,119,2,0,
163,9,129,3,0,
163,9,138,1,49,
163,9,138,4,0,
163,9,143,2,0,
163,9,156,4,0,
163,9,164,4,0,
163,9,168,4,0,
163,9,182,4,0,
163,9,185,2,0,
163,9,189,3,0,
163,9,193,1,1,3
163,9,203,4,0,
163,9,207,4,0,
163,9,211,4,0,
163,9,213,4,0,
163,9,214,4,0,
163,9,216,4,0,
163,9,218,4,0,
163,9,237,4,0,
163,9,239,3,0,
163,9,240,4,0,
163,9,241,4,0,
163,9,244,4,0,
163,9,247,4,0,
163,9,253,1,13,
163,9,253,3,0,
163,9,257,3,0,
163,9,263,4,0,
163,9,278,4,0,
163,9,290,4,0,
163,9,297,2,0,
163,9,314,3,0,
163,9,318,4,0,
163,9,326,1,37,
163,9,332,4,0,
163,9,355,1,45,
163,9,355,4,0,
163,9,363,4,0,
163,9,365,4,0,
163,9,375,1,41,
163,9,403,1,29,
163,9,428,1,33,
163,9,428,3,0,
163,9,432,4,0,
163,9,445,4,0,
163,9,466,3,0,
163,10,17,2,0,
163,10,18,2,0,
163,10,19,4,0,
163,10,33,1,1,1
163,10,36,1,25,
163,10,45,1,1,2
163,10,48,2,0,
163,10,64,1,9,
163,10,92,4,0,
163,10,93,1,21,
163,10,94,4,0,
163,10,95,1,5,
163,10,97,2,0,
163,10,101,2,0,
163,10,104,4,0,
163,10,115,1,17,
163,10,115,4,0,
163,10,119,2,0,
163,10,129,3,0,
163,10,138,1,49,
163,10,138,4,0,
163,10,143,2,0,
163,10,156,4,0,
163,10,164,4,0,
163,10,168,4,0,
163,10,182,4,0,
163,10,185,2,0,
163,10,189,3,0,
163,10,193,1,1,3
163,10,203,4,0,
163,10,207,4,0,
163,10,211,4,0,
163,10,213,4,0,
163,10,214,4,0,
163,10,216,4,0,
163,10,218,4,0,
163,10,237,4,0,
163,10,239,3,0,
163,10,240,4,0,
163,10,241,4,0,
163,10,244,4,0,
163,10,247,4,0,
163,10,253,1,13,
163,10,253,3,0,
163,10,257,3,0,
163,10,263,4,0,
163,10,277,3,0,
163,10,278,4,0,
163,10,290,4,0,
163,10,297,2,0,
163,10,314,3,0,
163,10,318,4,0,
163,10,326,1,37,
163,10,332,4,0,
163,10,355,1,45,
163,10,355,4,0,
163,10,363,4,0,
163,10,365,4,0,
163,10,366,3,0,
163,10,375,1,41,
163,10,403,1,29,
163,10,428,1,33,
163,10,428,3,0,
163,10,445,4,0,
163,10,466,3,0,
163,11,17,2,0,
163,11,18,2,0,
163,11,19,4,0,
163,11,33,1,1,1
163,11,36,1,29,
163,11,45,1,1,2
163,11,48,2,0,
163,11,64,1,9,
163,11,92,4,0,
163,11,93,1,21,
163,11,94,4,0,
163,11,95,1,5,
163,11,97,2,0,
163,11,101,2,0,
163,11,104,4,0,
163,11,115,1,17,
163,11,115,4,0,
163,11,119,2,0,
163,11,138,1,57,
163,11,138,4,0,
163,11,143,2,0,
163,11,156,4,0,
163,11,164,4,0,
163,11,168,4,0,
163,11,182,4,0,
163,11,185,2,0,
163,11,193,1,1,3
163,11,207,4,0,
163,11,213,4,0,
163,11,216,4,0,
163,11,218,4,0,
163,11,237,4,0,
163,11,240,4,0,
163,11,241,4,0,
163,11,244,4,0,
163,11,247,4,0,
163,11,253,1,13,
163,11,263,4,0,
163,11,297,2,0,
163,11,326,1,45,
163,11,332,4,0,
163,11,355,1,53,
163,11,365,4,0,
163,11,375,1,49,
163,11,403,1,33,
163,11,428,1,37,
163,11,432,2,0,
163,11,485,1,41,
163,11,496,4,0,
163,11,497,1,25,
163,11,497,4,0,
163,11,526,4,0,
163,12,19,4,0,
163,12,33,1,1,1
163,12,36,1,28,
163,12,45,1,1,2
163,12,64,1,11,
163,12,92,4,0,
163,12,93,1,34,
163,12,94,4,0,
163,12,95,1,16,
163,12,104,4,0,
163,12,115,1,22,
163,12,115,4,0,
163,12,138,1,48,
163,12,148,4,0,
163,12,156,4,0,
163,12,168,4,0,
163,12,182,4,0,
163,12,193,1,6,
163,12,211,4,0,
163,12,213,4,0,
163,12,216,4,0,
163,12,218,4,0,
163,12,237,4,0,
163,12,240,4,0,
163,12,241,4,0,
163,12,247,4,0,
163,12,263,4,0,
163,12,290,4,0,
163,12,332,4,0,
163,13,19,4,0,
163,13,33,1,1,1
163,13,36,1,28,
163,13,38,3,0,
163,13,45,1,1,2
163,13,64,1,11,
163,13,92,4,0,
163,13,93,1,34,
163,13,94,4,0,
163,13,95,1,16,
163,13,102,3,0,
163,13,104,4,0,
163,13,115,1,22,
163,13,115,4,0,
163,13,138,1,48,
163,13,138,3,0,
163,13,143,3,0,
163,13,148,4,0,
163,13,156,4,0,
163,13,164,3,0,
163,13,168,4,0,
163,13,171,3,0,
163,13,182,4,0,
163,13,193,1,6,
163,13,207,3,0,
163,13,211,4,0,
163,13,213,4,0,
163,13,216,4,0,
163,13,218,4,0,
163,13,237,4,0,
163,13,240,4,0,
163,13,241,4,0,
163,13,247,4,0,
163,13,263,4,0,
163,13,290,4,0,
163,13,332,4,0,
163,14,17,2,0,
163,14,18,2,0,
163,14,19,4,0,
163,14,33,1,1,1
163,14,36,1,29,
163,14,45,1,1,2
163,14,48,2,0,
163,14,64,1,9,
163,14,92,4,0,
163,14,93,1,21,
163,14,94,4,0,
163,14,95,1,5,
163,14,97,2,0,
163,14,101,2,0,
163,14,104,4,0,
163,14,115,1,17,
163,14,115,4,0,
163,14,119,2,0,
163,14,138,1,57,
163,14,138,4,0,
163,14,143,2,0,
163,14,143,3,0,
163,14,156,4,0,
163,14,164,4,0,
163,14,168,4,0,
163,14,182,4,0,
163,14,185,2,0,
163,14,193,1,1,3
163,14,207,4,0,
163,14,213,4,0,
163,14,214,3,0,
163,14,216,4,0,
163,14,218,4,0,
163,14,237,4,0,
163,14,240,4,0,
163,14,241,4,0,
163,14,244,4,0,
163,14,247,4,0,
163,14,253,1,13,
163,14,253,3,0,
163,14,257,3,0,
163,14,263,4,0,
163,14,277,3,0,
163,14,278,3,0,
163,14,297,2,0,
163,14,304,3,0,
163,14,326,1,45,
163,14,332,4,0,
163,14,355,1,53,
163,14,355,3,0,
163,14,365,4,0,
163,14,366,3,0,
163,14,375,1,49,
163,14,403,1,33,
163,14,428,1,37,
163,14,428,3,0,
163,14,432,2,0,
163,14,485,1,41,
163,14,496,4,0,
163,14,497,1,25,
163,14,497,4,0,
163,14,526,4,0,
163,15,17,2,0,
163,15,18,2,0,
163,15,19,4,0,
163,15,33,1,1,1
163,15,36,1,29,
163,15,45,1,1,2
163,15,48,2,0,
163,15,64,1,9,
163,15,92,4,0,
163,15,93,1,21,
163,15,94,4,0,
163,15,95,1,5,
163,15,97,2,0,
163,15,101,2,0,
163,15,104,4,0,
163,15,115,1,17,
163,15,115,4,0,
163,15,119,2,0,
163,15,138,1,57,
163,15,138,4,0,
163,15,143,2,0,
163,15,156,4,0,
163,15,164,4,0,
163,15,168,4,0,
163,15,182,4,0,
163,15,185,2,0,
163,15,193,1,1,3
163,15,207,4,0,
163,15,211,4,0,
163,15,213,4,0,
163,15,214,4,0,
163,15,216,4,0,
163,15,218,4,0,
163,15,237,4,0,
163,15,240,4,0,
163,15,241,4,0,
163,15,244,4,0,
163,15,247,4,0,
163,15,253,1,13,
163,15,263,4,0,
163,15,297,2,0,
163,15,326,1,45,
163,15,332,4,0,
163,15,355,1,53,
163,15,355,4,0,
163,15,375,1,49,
163,15,403,1,33,
163,15,428,1,37,
163,15,432,2,0,
163,15,485,1,41,
163,15,496,4,0,
163,15,497,1,25,
163,15,497,4,0,
163,15,590,4,0,
163,16,17,2,0,
163,16,18,2,0,
163,16,19,4,0,
163,16,33,1,1,1
163,16,36,1,29,1
163,16,45,1,1,2
163,16,48,2,0,
163,16,64,1,9,1
163,16,92,4,0,
163,16,93,1,21,1
163,16,94,4,0,
163,16,95,1,5,1
163,16,97,2,0,
163,16,101,2,0,
163,16,104,4,0,
163,16,115,1,17,1
163,16,115,4,0,
163,16,119,2,0,
163,16,138,1,57,1
163,16,138,4,0,
163,16,143,2,0,
163,16,143,3,0,
163,16,156,4,0,
163,16,164,4,0,
163,16,168,4,0,
163,16,173,3,0,
163,16,182,4,0,
163,16,185,2,0,
163,16,193,1,1,3
163,16,207,4,0,
163,16,211,4,0,
163,16,213,4,0,
163,16,214,4,0,
163,16,216,4,0,
163,16,218,4,0,
163,16,237,4,0,
163,16,240,4,0,
163,16,241,4,0,
163,16,244,4,0,
163,16,247,4,0,
163,16,253,1,13,1
163,16,253,3,0,
163,16,257,3,0,
163,16,263,4,0,
163,16,277,3,0,
163,16,278,3,0,
163,16,290,4,0,
163,16,297,2,0,
163,16,304,3,0,
163,16,326,1,45,1
163,16,332,4,0,
163,16,355,1,53,1
163,16,355,4,0,
163,16,366,3,0,
163,16,375,1,49,1
163,16,403,1,33,1
163,16,428,1,37,1
163,16,428,3,0,
163,16,432,2,0,
163,16,485,1,41,1
163,16,496,4,0,
163,16,497,1,25,1
163,16,497,4,0,
163,16,590,4,0,
163,17,17,2,0,
163,17,18,2,0,
163,17,19,4,0,
163,17,33,1,1,1
163,17,36,1,25,
163,17,45,1,1,2
163,17,48,2,0,
163,17,64,1,7,
163,17,92,4,0,
163,17,93,1,10,
163,17,94,4,0,
163,17,95,1,4,
163,17,97,2,0,
163,17,101,2,0,
163,17,104,4,0,
163,17,115,1,28,
163,17,115,4,0,
163,17,119,2,0,
163,17,138,1,46,
163,17,138,4,0,
163,17,143,2,0,
163,17,156,4,0,
163,17,164,4,0,
163,17,168,4,0,
163,17,182,4,0,
163,17,185,2,0,
163,17,193,1,1,3
163,17,207,4,0,
163,17,211,4,0,
163,17,213,4,0,
163,17,214,4,0,
163,17,216,4,0,
163,17,218,4,0,
163,17,237,4,0,
163,17,240,4,0,
163,17,241,4,0,
163,17,244,4,0,
163,17,247,4,0,
163,17,253,1,34,
163,17,263,4,0,
163,17,297,2,0,
163,17,326,1,22,
163,17,332,4,0,
163,17,355,1,37,
163,17,355,4,0,
163,17,375,1,19,
163,17,403,1,31,
163,17,428,1,16,
163,17,432,2,0,
163,17,485,1,43,
163,17,496,4,0,
163,17,497,1,13,
163,17,497,4,0,
163,17,526,4,0,
163,17,585,1,40,
163,17,590,4,0,
163,18,17,2,0,
163,18,18,2,0,
163,18,19,4,0,
163,18,33,1,1,1
163,18,36,1,25,
163,18,45,1,1,2
163,18,48,2,0,
163,18,64,1,7,
163,18,92,4,0,
163,18,93,1,10,
163,18,94,4,0,
163,18,95,1,4,
163,18,97,2,0,
163,18,101,2,0,
163,18,104,4,0,
163,18,115,1,28,
163,18,115,4,0,
163,18,119,2,0,
163,18,138,1,46,
163,18,138,4,0,
163,18,143,2,0,
163,18,156,4,0,
163,18,164,4,0,
163,18,168,4,0,
163,18,182,4,0,
163,18,185,2,0,
163,18,193,1,1,3
163,18,207,4,0,
163,18,211,4,0,
163,18,212,2,0,
163,18,213,4,0,
163,18,214,4,0,
163,18,216,4,0,
163,18,218,4,0,
163,18,237,4,0,
163,18,240,4,0,
163,18,241,4,0,
163,18,244,4,0,
163,18,247,4,0,
163,18,253,1,34,
163,18,263,4,0,
163,18,297,2,0,
163,18,326,1,22,
163,18,332,4,0,
163,18,355,1,37,
163,18,355,4,0,
163,18,375,1,19,
163,18,403,1,31,
163,18,428,1,16,
163,18,432,2,0,
163,18,485,1,43,
163,18,496,4,0,
163,18,497,1,13,
163,18,497,4,0,
163,18,526,4,0,
163,18,542,2,0,
163,18,585,1,40,
163,18,590,4,0,
164,3,19,4,0,
164,3,33,1,1,1
164,3,36,1,33,
164,3,45,1,1,2
164,3,63,4,0,
164,3,64,1,1,4
164,3,64,1,11,
164,3,92,4,0,
164,3,93,1,41,
164,3,95,1,16,
164,3,104,4,0,
164,3,115,1,25,
164,3,129,4,0,
164,3,138,1,57,
164,3,138,4,0,
164,3,148,4,0,
164,3,156,4,0,
164,3,168,4,0,
164,3,171,4,0,
164,3,173,4,0,
164,3,174,4,0,
164,3,182,4,0,
164,3,189,4,0,
164,3,193,1,1,3
164,3,193,1,6,
164,3,197,4,0,
164,3,203,4,0,
164,3,207,4,0,
164,3,211,4,0,
164,3,213,4,0,
164,3,214,4,0,
164,3,216,4,0,
164,3,218,4,0,
164,3,237,4,0,
164,3,241,4,0,
164,4,19,4,0,
164,4,33,1,1,1
164,4,36,1,33,
164,4,45,1,1,2
164,4,63,4,0,
164,4,64,1,1,4
164,4,64,1,11,
164,4,92,4,0,
164,4,93,1,41,
164,4,95,1,16,
164,4,104,4,0,
164,4,115,1,25,
164,4,129,4,0,
164,4,138,1,57,
164,4,138,4,0,
164,4,148,4,0,
164,4,156,4,0,
164,4,168,4,0,
164,4,171,4,0,
164,4,173,4,0,
164,4,174,4,0,
164,4,182,4,0,
164,4,189,4,0,
164,4,193,1,1,3
164,4,193,1,6,
164,4,197,4,0,
164,4,203,4,0,
164,4,207,4,0,
164,4,211,4,0,
164,4,213,4,0,
164,4,214,4,0,
164,4,216,4,0,
164,4,218,4,0,
164,4,237,4,0,
164,4,241,4,0,
164,5,19,4,0,
164,5,33,1,1,1
164,5,36,1,33,
164,5,45,1,1,2
164,5,63,4,0,
164,5,64,1,1,4
164,5,64,1,11,
164,5,92,4,0,
164,5,93,1,41,
164,5,94,4,0,
164,5,95,1,16,
164,5,104,4,0,
164,5,115,1,25,
164,5,115,4,0,
164,5,138,1,57,
164,5,148,4,0,
164,5,156,4,0,
164,5,168,4,0,
164,5,182,4,0,
164,5,193,1,1,3
164,5,193,1,6,
164,5,211,4,0,
164,5,213,4,0,
164,5,216,4,0,
164,5,218,4,0,
164,5,237,4,0,
164,5,240,4,0,
164,5,241,4,0,
164,5,247,4,0,
164,5,263,4,0,
164,5,290,4,0,
164,5,332,4,0,
164,6,19,4,0,
164,6,33,1,1,1
164,6,36,1,33,
164,6,38,3,0,
164,6,45,1,1,2
164,6,63,4,0,
164,6,64,1,1,4
164,6,64,1,11,
164,6,92,4,0,
164,6,93,1,41,
164,6,94,4,0,
164,6,95,1,16,
164,6,102,3,0,
164,6,104,4,0,
164,6,115,1,25,
164,6,115,4,0,
164,6,129,3,0,
164,6,138,1,57,
164,6,138,3,0,
164,6,148,4,0,
164,6,156,4,0,
164,6,164,3,0,
164,6,168,4,0,
164,6,173,3,0,
164,6,182,4,0,
164,6,189,3,0,
164,6,193,1,1,3
164,6,193,1,6,
164,6,203,3,0,
164,6,207,3,0,
164,6,211,4,0,
164,6,213,4,0,
164,6,214,3,0,
164,6,216,4,0,
164,6,218,4,0,
164,6,237,4,0,
164,6,240,4,0,
164,6,241,4,0,
164,6,247,4,0,
164,6,263,4,0,
164,6,290,4,0,
164,6,332,4,0,
164,7,19,4,0,
164,7,33,1,1,1
164,7,36,1,33,
164,7,38,3,0,
164,7,45,1,1,2
164,7,63,4,0,
164,7,64,1,1,4
164,7,64,1,11,
164,7,92,4,0,
164,7,93,1,41,
164,7,94,4,0,
164,7,95,1,16,
164,7,102,3,0,
164,7,104,4,0,
164,7,115,1,25,
164,7,115,4,0,
164,7,138,1,57,
164,7,138,3,0,
164,7,148,4,0,
164,7,156,4,0,
164,7,164,3,0,
164,7,168,4,0,
164,7,182,4,0,
164,7,193,1,1,3
164,7,193,1,6,
164,7,211,4,0,
164,7,213,4,0,
164,7,216,4,0,
164,7,218,4,0,
164,7,237,4,0,
164,7,240,4,0,
164,7,241,4,0,
164,7,247,4,0,
164,7,263,4,0,
164,7,290,4,0,
164,7,332,4,0,
164,8,19,4,0,
164,8,33,1,1,2
164,8,36,1,27,
164,8,45,1,1,3
164,8,63,4,0,
164,8,64,1,13,
164,8,92,4,0,
164,8,93,1,22,
164,8,94,4,0,
164,8,95,1,1,5
164,8,95,1,9,
164,8,104,4,0,
164,8,115,1,17,
164,8,115,4,0,
164,8,138,1,57,
164,8,138,4,0,
164,8,143,1,1,1
164,8,156,4,0,
164,8,164,4,0,
164,8,168,4,0,
164,8,182,4,0,
164,8,193,1,1,4
164,8,193,1,5,
164,8,203,4,0,
164,8,207,4,0,
164,8,211,4,0,
164,8,213,4,0,
164,8,214,4,0,
164,8,216,4,0,
164,8,218,4,0,
164,8,237,4,0,
164,8,240,4,0,
164,8,241,4,0,
164,8,244,4,0,
164,8,247,4,0,
164,8,263,4,0,
164,8,278,4,0,
164,8,290,4,0,
164,8,318,4,0,
164,8,326,1,42,
164,8,332,4,0,
164,8,355,1,52,
164,8,355,4,0,
164,8,363,4,0,
164,8,365,4,0,
164,8,375,1,47,
164,8,403,1,32,
164,8,416,4,0,
164,8,428,1,37,
164,8,432,4,0,
164,8,445,4,0,
164,9,19,4,0,
164,9,33,1,1,2
164,9,36,1,27,
164,9,45,1,1,3
164,9,63,4,0,
164,9,64,1,9,
164,9,92,4,0,
164,9,93,1,22,
164,9,94,4,0,
164,9,95,1,1,5
164,9,95,1,5,
164,9,104,4,0,
164,9,115,1,17,
164,9,115,4,0,
164,9,129,3,0,
164,9,138,1,57,
164,9,138,4,0,
164,9,143,1,1,1
164,9,156,4,0,
164,9,164,4,0,
164,9,168,4,0,
164,9,182,4,0,
164,9,189,3,0,
164,9,193,1,1,4
164,9,203,4,0,
164,9,207,4,0,
164,9,211,4,0,
164,9,213,4,0,
164,9,214,4,0,
164,9,216,4,0,
164,9,218,4,0,
164,9,237,4,0,
164,9,239,3,0,
164,9,240,4,0,
164,9,241,4,0,
164,9,244,4,0,
164,9,247,4,0,
164,9,253,1,13,
164,9,253,3,0,
164,9,257,3,0,
164,9,263,4,0,
164,9,278,4,0,
164,9,290,4,0,
164,9,314,3,0,
164,9,318,4,0,
164,9,326,1,42,
164,9,332,4,0,
164,9,355,1,52,
164,9,355,4,0,
164,9,363,4,0,
164,9,365,4,0,
164,9,375,1,47,
164,9,403,1,32,
164,9,416,4,0,
164,9,428,1,37,
164,9,428,3,0,
164,9,432,4,0,
164,9,445,4,0,
164,9,466,3,0,
164,10,19,4,0,
164,10,33,1,1,2
164,10,36,1,27,
164,10,45,1,1,3
164,10,63,4,0,
164,10,64,1,9,
164,10,92,4,0,
164,10,93,1,22,
164,10,94,4,0,
164,10,95,1,1,5
164,10,95,1,5,
164,10,104,4,0,
164,10,115,1,17,
164,10,115,4,0,
164,10,129,3,0,
164,10,138,1,57,
164,10,138,4,0,
164,10,143,1,1,1
164,10,143,3,0,
164,10,156,4,0,
164,10,164,4,0,
164,10,168,4,0,
164,10,182,4,0,
164,10,189,3,0,
164,10,193,1,1,4
164,10,203,4,0,
164,10,207,4,0,
164,10,211,4,0,
164,10,213,4,0,
164,10,214,4,0,
164,10,216,4,0,
164,10,218,4,0,
164,10,237,4,0,
164,10,239,3,0,
164,10,240,4,0,
164,10,241,4,0,
164,10,244,4,0,
164,10,247,4,0,
164,10,253,1,13,
164,10,253,3,0,
164,10,257,3,0,
164,10,263,4,0,
164,10,277,3,0,
164,10,278,4,0,
164,10,290,4,0,
164,10,314,3,0,
164,10,318,4,0,
164,10,326,1,42,
164,10,332,4,0,
164,10,355,1,52,
164,10,355,4,0,
164,10,363,4,0,
164,10,365,4,0,
164,10,366,3,0,
164,10,375,1,47,
164,10,403,1,32,
164,10,416,4,0,
164,10,428,1,37,
164,10,428,3,0,
164,10,445,4,0,
164,10,466,3,0,
164,11,19,4,0,
164,11,33,1,1,2
164,11,36,1,32,
164,11,45,1,1,3
164,11,63,4,0,
164,11,64,1,9,
164,11,92,4,0,
164,11,93,1,22,
164,11,94,4,0,
164,11,95,1,1,5
164,11,95,1,5,
164,11,104,4,0,
164,11,115,1,17,
164,11,115,4,0,
164,11,138,1,67,
164,11,138,4,0,
164,11,143,1,1,1
164,11,156,4,0,
164,11,164,4,0,
164,11,168,4,0,
164,11,182,4,0,
164,11,193,1,1,4
164,11,207,4,0,
164,11,213,4,0,
164,11,216,4,0,
164,11,218,4,0,
164,11,237,4,0,
164,11,240,4,0,
164,11,241,4,0,
164,11,244,4,0,
164,11,247,4,0,
164,11,253,1,13,
164,11,263,4,0,
164,11,326,1,52,
164,11,332,4,0,
164,11,355,1,62,
164,11,365,4,0,
164,11,375,1,57,
164,11,403,1,37,
164,11,416,4,0,
164,11,428,1,42,
164,11,485,1,47,
164,11,496,4,0,
164,11,497,1,27,
164,11,497,4,0,
164,11,526,4,0,
164,12,19,4,0,
164,12,33,1,1,1
164,12,36,1,33,
164,12,45,1,1,2
164,12,63,4,0,
164,12,64,1,1,4
164,12,64,1,11,
164,12,92,4,0,
164,12,93,1,41,
164,12,94,4,0,
164,12,95,1,16,
164,12,104,4,0,
164,12,115,1,25,
164,12,115,4,0,
164,12,138,1,57,
164,12,148,4,0,
164,12,156,4,0,
164,12,168,4,0,
164,12,182,4,0,
164,12,193,1,1,3
164,12,193,1,6,
164,12,211,4,0,
164,12,213,4,0,
164,12,216,4,0,
164,12,218,4,0,
164,12,237,4,0,
164,12,240,4,0,
164,12,241,4,0,
164,12,247,4,0,
164,12,263,4,0,
164,12,290,4,0,
164,12,332,4,0,
164,13,19,4,0,
164,13,33,1,1,1
164,13,36,1,33,
164,13,38,3,0,
164,13,45,1,1,2
164,13,63,4,0,
164,13,64,1,1,4
164,13,64,1,11,
164,13,92,4,0,
164,13,93,1,41,
164,13,94,4,0,
164,13,95,1,16,
164,13,102,3,0,
164,13,104,4,0,
164,13,115,1,25,
164,13,115,4,0,
164,13,138,1,57,
164,13,138,3,0,
164,13,143,3,0,
164,13,148,4,0,
164,13,156,4,0,
164,13,164,3,0,
164,13,168,4,0,
164,13,171,3,0,
164,13,182,4,0,
164,13,193,1,1,3
164,13,193,1,6,
164,13,207,3,0,
164,13,211,4,0,
164,13,213,4,0,
164,13,216,4,0,
164,13,218,4,0,
164,13,237,4,0,
164,13,240,4,0,
164,13,241,4,0,
164,13,247,4,0,
164,13,263,4,0,
164,13,290,4,0,
164,13,332,4,0,
164,14,19,4,0,
164,14,33,1,1,2
164,14,36,1,32,
164,14,45,1,1,3
164,14,63,4,0,
164,14,64,1,9,
164,14,92,4,0,
164,14,93,1,22,
164,14,94,4,0,
164,14,95,1,1,5
164,14,95,1,5,
164,14,104,4,0,
164,14,115,1,17,
164,14,115,4,0,
164,14,138,1,67,
164,14,138,4,0,
164,14,143,1,1,1
164,14,143,3,0,
164,14,156,4,0,
164,14,164,4,0,
164,14,168,4,0,
164,14,182,4,0,
164,14,193,1,1,4
164,14,207,4,0,
164,14,213,4,0,
164,14,214,3,0,
164,14,216,4,0,
164,14,218,4,0,
164,14,237,4,0,
164,14,240,4,0,
164,14,241,4,0,
164,14,244,4,0,
164,14,247,4,0,
164,14,253,1,13,
164,14,253,3,0,
164,14,257,3,0,
164,14,263,4,0,
164,14,277,3,0,
164,14,278,3,0,
164,14,304,3,0,
164,14,326,1,52,
164,14,332,4,0,
164,14,355,1,62,
164,14,355,3,0,
164,14,365,4,0,
164,14,366,3,0,
164,14,375,1,57,
164,14,403,1,37,
164,14,416,4,0,
164,14,428,1,42,
164,14,428,3,0,
164,14,485,1,47,
164,14,496,4,0,
164,14,497,1,27,
164,14,497,4,0,
164,14,526,4,0,
164,15,19,4,0,
164,15,33,1,1,3
164,15,36,1,32,
164,15,45,1,1,4
164,15,63,4,0,
164,15,64,1,9,
164,15,92,4,0,
164,15,93,1,22,
164,15,94,4,0,
164,15,95,1,1,6
164,15,95,1,5,
164,15,104,4,0,
164,15,115,1,17,
164,15,115,4,0,
164,15,138,1,1,1
164,15,138,1,67,
164,15,138,4,0,
164,15,143,1,1,2
164,15,156,4,0,
164,15,164,4,0,
164,15,168,4,0,
164,15,182,4,0,
164,15,193,1,1,5
164,15,207,4,0,
164,15,211,4,0,
164,15,213,4,0,
164,15,214,4,0,
164,15,216,4,0,
164,15,218,4,0,
164,15,237,4,0,
164,15,240,4,0,
164,15,241,4,0,
164,15,244,4,0,
164,15,247,4,0,
164,15,253,1,13,
164,15,263,4,0,
164,15,326,1,52,
164,15,332,4,0,
164,15,355,1,62,
164,15,355,4,0,
164,15,375,1,57,
164,15,403,1,37,
164,15,416,4,0,
164,15,428,1,42,
164,15,485,1,47,
164,15,496,4,0,
164,15,497,1,27,
164,15,497,4,0,
164,15,590,4,0,
164,16,19,4,0,
164,16,33,1,1,3
164,16,36,1,32,1
164,16,45,1,1,4
164,16,63,4,0,
164,16,64,1,9,1
164,16,92,4,0,
164,16,93,1,22,1
164,16,94,4,0,
164,16,95,1,1,6
164,16,95,1,5,1
164,16,104,4,0,
164,16,115,1,17,1
164,16,115,4,0,
164,16,138,1,1,1
164,16,138,1,67,1
164,16,138,4,0,
164,16,143,1,1,2
164,16,143,3,0,
164,16,156,4,0,
164,16,164,4,0,
164,16,168,4,0,
164,16,173,3,0,
164,16,182,4,0,
164,16,193,1,1,5
164,16,207,4,0,
164,16,211,4,0,
164,16,213,4,0,
164,16,214,4,0,
164,16,216,4,0,
164,16,218,4,0,
164,16,237,4,0,
164,16,240,4,0,
164,16,241,4,0,
164,16,244,4,0,
164,16,247,4,0,
164,16,253,1,13,1
164,16,253,3,0,
164,16,257,3,0,
164,16,263,4,0,
164,16,277,3,0,
164,16,278,3,0,
164,16,290,4,0,
164,16,304,3,0,
164,16,326,1,52,1
164,16,332,4,0,
164,16,355,1,62,1
164,16,355,4,0,
164,16,366,3,0,
164,16,375,1,57,1
164,16,403,1,37,1
164,16,416,4,0,
164,16,428,1,42,1
164,16,428,3,0,
164,16,485,1,47,1
164,16,496,4,0,
164,16,497,1,27,1
164,16,497,4,0,
164,16,590,4,0,
164,17,19,4,0,
164,17,33,1,1,3
164,17,36,1,27,
164,17,45,1,1,4
164,17,63,4,0,
164,17,64,1,7,
164,17,92,4,0,
164,17,93,1,10,
164,17,94,4,0,
164,17,95,1,1,6
164,17,95,1,4,
164,17,104,4,0,
164,17,115,1,31,
164,17,115,4,0,
164,17,138,1,1,1
164,17,138,1,55,
164,17,138,4,0,
164,17,143,1,1,2
164,17,156,4,0,
164,17,164,4,0,
164,17,168,4,0,
164,17,182,4,0,
164,17,193,1,1,5
164,17,207,4,0,
164,17,211,4,0,
164,17,213,4,0,
164,17,214,4,0,
164,17,216,4,0,
164,17,218,4,0,
164,17,237,4,0,
164,17,240,4,0,
164,17,241,4,0,
164,17,244,4,0,
164,17,247,4,0,
164,17,253,1,39,
164,17,263,4,0,
164,17,326,1,23,
164,17,332,4,0,
164,17,355,1,43,
164,17,355,4,0,
164,17,375,1,19,
164,17,403,1,35,
164,17,416,4,0,
164,17,428,1,16,
164,17,485,1,51,
164,17,496,4,0,
164,17,497,1,13,
164,17,497,4,0,
164,17,526,4,0,
164,17,585,1,47,
164,17,590,4,0,
164,18,19,4,0,
164,18,33,1,1,3
164,18,36,1,27,
164,18,45,1,1,4
164,18,63,4,0,
164,18,64,1,7,
164,18,92,4,0,
164,18,93,1,10,
164,18,94,4,0,
164,18,95,1,1,6
164,18,95,1,4,
164,18,104,4,0,
164,18,115,1,31,
164,18,115,4,0,
164,18,138,1,1,1
164,18,138,1,55,
164,18,138,4,0,
164,18,143,1,1,2
164,18,156,4,0,
164,18,164,4,0,
164,18,168,4,0,
164,18,182,4,0,
164,18,193,1,1,5
164,18,207,4,0,
164,18,211,4,0,
164,18,213,4,0,
164,18,214,4,0,
164,18,216,4,0,
164,18,218,4,0,
164,18,237,4,0,
164,18,240,4,0,
164,18,241,4,0,
164,18,244,4,0,
164,18,247,4,0,
164,18,253,1,39,
164,18,263,4,0,
164,18,326,1,23,
164,18,332,4,0,
164,18,355,1,43,
164,18,355,4,0,
164,18,375,1,19,
164,18,403,1,35,
164,18,416,4,0,
164,18,428,1,16,
164,18,485,1,51,
164,18,496,4,0,
164,18,497,1,13,
164,18,497,4,0,
164,18,526,4,0,
164,18,585,1,47,
164,18,590,4,0,
165,3,4,1,15,
165,3,8,4,0,
165,3,9,4,0,
165,3,29,4,0,
165,3,33,1,1,
165,3,38,1,50,
165,3,48,1,8,
165,3,60,2,0,
165,3,76,4,0,
165,3,91,4,0,
165,3,92,4,0,
165,3,97,1,43,
165,3,104,4,0,
165,3,113,1,22,1
165,3,113,2,0,
165,3,115,1,22,2
165,3,117,2,0,
165,3,129,1,36,
165,3,129,4,0,
165,3,148,4,0,
165,3,156,4,0,
165,3,168,4,0,
165,3,173,4,0,
165,3,174,4,0,
165,3,182,4,0,
165,3,202,4,0,
165,3,203,4,0,
165,3,205,4,0,
165,3,207,4,0,
165,3,213,4,0,
165,3,214,4,0,
165,3,216,4,0,
165,3,218,4,0,
165,3,219,1,22,3
165,3,223,4,0,
165,3,226,1,29,
165,3,230,4,0,
165,3,237,4,0,
165,3,241,4,0,
165,4,4,1,15,
165,4,8,4,0,
165,4,9,4,0,
165,4,29,4,0,
165,4,33,1,1,
165,4,38,1,50,
165,4,48,1,8,
165,4,60,2,0,
165,4,76,4,0,
165,4,91,4,0,
165,4,92,4,0,
165,4,97,1,43,
165,4,104,4,0,
165,4,113,1,22,1
165,4,113,2,0,
165,4,115,1,22,2
165,4,117,2,0,
165,4,129,1,36,
165,4,129,4,0,
165,4,148,4,0,
165,4,156,4,0,
165,4,168,4,0,
165,4,173,4,0,
165,4,174,4,0,
165,4,182,4,0,
165,4,202,4,0,
165,4,203,4,0,
165,4,205,4,0,
165,4,207,4,0,
165,4,213,4,0,
165,4,214,4,0,
165,4,216,4,0,
165,4,218,4,0,
165,4,219,1,22,3
165,4,223,4,0,
165,4,226,1,29,
165,4,230,4,0,
165,4,237,4,0,
165,4,241,4,0,
165,5,4,1,15,
165,5,33,1,1,
165,5,38,1,50,
165,5,48,1,8,
165,5,60,2,0,
165,5,76,4,0,
165,5,91,4,0,
165,5,92,4,0,
165,5,97,1,43,
165,5,104,4,0,
165,5,113,1,22,1
165,5,113,4,0,
165,5,115,1,22,2
165,5,115,4,0,
165,5,117,2,0,
165,5,129,1,36,
165,5,148,4,0,
165,5,156,4,0,
165,5,168,4,0,
165,5,182,4,0,
165,5,202,4,0,
165,5,213,4,0,
165,5,216,4,0,
165,5,218,4,0,
165,5,219,1,22,3
165,5,219,4,0,
165,5,226,1,29,
165,5,237,4,0,
165,5,241,4,0,
165,5,263,4,0,
165,5,264,4,0,
165,5,280,4,0,
165,5,290,4,0,
165,5,318,2,0,
165,5,332,4,0,
165,6,4,1,15,
165,6,5,3,0,
165,6,8,3,0,
165,6,9,3,0,
165,6,14,3,0,
165,6,33,1,1,
165,6,38,1,50,
165,6,38,3,0,
165,6,48,1,8,
165,6,60,2,0,
165,6,76,4,0,
165,6,91,4,0,
165,6,92,4,0,
165,6,97,1,43,
165,6,102,3,0,
165,6,104,4,0,
165,6,113,1,22,1
165,6,113,4,0,
165,6,115,1,22,2
165,6,115,4,0,
165,6,117,2,0,
165,6,129,1,36,
165,6,129,3,0,
165,6,148,4,0,
165,6,156,4,0,
165,6,164,3,0,
165,6,168,4,0,
165,6,173,3,0,
165,6,182,4,0,
165,6,202,4,0,
165,6,203,3,0,
165,6,205,3,0,
165,6,207,3,0,
165,6,213,4,0,
165,6,214,3,0,
165,6,216,4,0,
165,6,218,4,0,
165,6,219,1,22,3
165,6,219,4,0,
165,6,223,3,0,
165,6,226,1,29,
165,6,237,4,0,
165,6,241,4,0,
165,6,263,4,0,
165,6,264,4,0,
165,6,280,4,0,
165,6,290,4,0,
165,6,318,2,0,
165,6,332,4,0,
165,7,4,1,15,
165,7,5,3,0,
165,7,14,3,0,
165,7,33,1,1,
165,7,38,1,50,
165,7,38,3,0,
165,7,48,1,8,
165,7,60,2,0,
165,7,76,4,0,
165,7,91,4,0,
165,7,92,4,0,
165,7,97,1,43,
165,7,102,3,0,
165,7,104,4,0,
165,7,113,1,22,1
165,7,113,4,0,
165,7,115,1,22,2
165,7,115,4,0,
165,7,117,2,0,
165,7,129,1,36,
165,7,148,4,0,
165,7,156,4,0,
165,7,164,3,0,
165,7,168,4,0,
165,7,182,4,0,
165,7,202,4,0,
165,7,213,4,0,
165,7,216,4,0,
165,7,218,4,0,
165,7,219,1,22,3
165,7,219,4,0,
165,7,226,1,29,
165,7,237,4,0,
165,7,241,4,0,
165,7,263,4,0,
165,7,264,4,0,
165,7,280,4,0,
165,7,290,4,0,
165,7,318,2,0,
165,7,332,4,0,
165,8,4,1,9,
165,8,14,4,0,
165,8,33,1,1,
165,8,38,1,38,
165,8,48,1,6,
165,8,60,2,0,
165,8,76,4,0,
165,8,91,4,0,
165,8,92,4,0,
165,8,97,1,30,
165,8,103,2,0,
165,8,104,4,0,
165,8,113,1,14,1
165,8,113,4,0,
165,8,115,1,14,2
165,8,115,4,0,
165,8,117,2,0,
165,8,129,1,33,
165,8,148,4,0,
165,8,156,4,0,
165,8,164,4,0,
165,8,168,4,0,
165,8,182,4,0,
165,8,183,1,17,
165,8,202,4,0,
165,8,203,4,0,
165,8,207,4,0,
165,8,213,4,0,
165,8,214,4,0,
165,8,216,4,0,
165,8,218,4,0,
165,8,219,1,14,3
165,8,219,4,0,
165,8,226,1,22,
165,8,227,2,0,
165,8,237,4,0,
165,8,241,4,0,
165,8,263,4,0,
165,8,264,4,0,
165,8,280,4,0,
165,8,282,2,0,
165,8,290,4,0,
165,8,318,1,25,
165,8,318,2,0,
165,8,318,4,0,
165,8,332,4,0,
165,8,355,4,0,
165,8,363,4,0,
165,8,369,4,0,
165,8,374,4,0,
165,8,405,1,41,
165,8,405,2,0,
165,8,409,4,0,
165,8,445,4,0,
165,8,450,2,0,
165,9,4,1,9,
165,9,8,3,0,
165,9,9,3,0,
165,9,14,4,0,
165,9,33,1,1,
165,9,38,1,38,
165,9,48,1,6,
165,9,60,2,0,
165,9,76,4,0,
165,9,91,4,0,
165,9,92,4,0,
165,9,97,1,30,
165,9,103,2,0,
165,9,104,4,0,
165,9,113,1,14,1
165,9,113,4,0,
165,9,115,1,14,2
165,9,115,4,0,
165,9,117,2,0,
165,9,129,1,33,
165,9,129,3,0,
165,9,148,4,0,
165,9,156,4,0,
165,9,164,4,0,
165,9,168,4,0,
165,9,173,3,0,
165,9,182,4,0,
165,9,183,1,17,
165,9,202,4,0,
165,9,203,4,0,
165,9,205,3,0,
165,9,207,4,0,
165,9,213,4,0,
165,9,214,4,0,
165,9,216,4,0,
165,9,218,4,0,
165,9,219,1,14,3
165,9,219,4,0,
165,9,226,1,22,
165,9,227,2,0,
165,9,237,4,0,
165,9,241,4,0,
165,9,253,3,0,
165,9,263,4,0,
165,9,264,4,0,
165,9,280,4,0,
165,9,282,2,0,
165,9,282,3,0,
165,9,290,4,0,
165,9,314,3,0,
165,9,318,1,25,
165,9,318,2,0,
165,9,318,4,0,
165,9,332,4,0,
165,9,355,4,0,
165,9,363,4,0,
165,9,369,4,0,
165,9,374,4,0,
165,9,405,1,41,
165,9,405,2,0,
165,9,409,4,0,
165,9,445,4,0,
165,9,450,2,0,
165,9,466,3,0,
165,10,4,1,9,
165,10,8,3,0,
165,10,9,3,0,
165,10,14,4,0,
165,10,29,3,0,
165,10,33,1,1,
165,10,38,1,38,
165,10,48,1,6,
165,10,60,2,0,
165,10,76,4,0,
165,10,81,3,0,
165,10,91,4,0,
165,10,92,4,0,
165,10,97,1,30,
165,10,103,2,0,
165,10,104,4,0,
165,10,113,1,14,1
165,10,113,4,0,
165,10,115,1,14,2
165,10,115,4,0,
165,10,117,2,0,
165,10,129,1,33,
165,10,129,3,0,
165,10,148,4,0,
165,10,156,4,0,
165,10,164,4,0,
165,10,168,4,0,
165,10,173,3,0,
165,10,182,4,0,
165,10,183,1,17,
165,10,202,4,0,
165,10,203,4,0,
165,10,205,3,0,
165,10,207,4,0,
165,10,213,4,0,
165,10,214,4,0,
165,10,216,4,0,
165,10,218,4,0,
165,10,219,1,14,3
165,10,219,4,0,
165,10,226,1,22,
165,10,227,2,0,
165,10,237,4,0,
165,10,241,4,0,
165,10,253,3,0,
165,10,263,4,0,
165,10,264,4,0,
165,10,280,4,0,
165,10,282,2,0,
165,10,282,3,0,
165,10,290,4,0,
165,10,314,3,0,
165,10,318,1,25,
165,10,318,2,0,
165,10,318,4,0,
165,10,332,4,0,
165,10,355,4,0,
165,10,363,4,0,
165,10,366,3,0,
165,10,369,4,0,
165,10,374,4,0,
165,10,405,1,41,
165,10,405,2,0,
165,10,409,4,0,
165,10,445,4,0,
165,10,450,2,0,
165,10,450,3,0,
165,10,466,3,0,
165,11,4,1,9,
165,11,14,4,0,
165,11,33,1,1,
165,11,38,1,38,
165,11,48,1,6,
165,11,60,2,0,
165,11,76,4,0,
165,11,91,4,0,
165,11,92,4,0,
165,11,97,1,30,
165,11,103,2,0,
165,11,104,4,0,
165,11,113,1,14,1
165,11,113,4,0,
165,11,115,1,14,2
165,11,115,4,0,
165,11,117,2,0,
165,11,129,1,33,
165,11,146,2,0,
165,11,148,4,0,
165,11,156,4,0,
165,11,164,4,0,
165,11,168,4,0,
165,11,182,4,0,
165,11,183,1,17,
165,11,207,4,0,
165,11,213,4,0,
165,11,216,4,0,
165,11,218,4,0,
165,11,219,1,14,3
165,11,219,4,0,
165,11,226,1,22,
165,11,227,2,0,
165,11,237,4,0,
165,11,241,4,0,
165,11,263,4,0,
165,11,264,2,0,
165,11,280,4,0,
165,11,282,2,0,
165,11,318,1,25,
165,11,318,2,0,
165,11,332,4,0,
165,11,369,4,0,
165,11,374,4,0,
165,11,405,1,41,
165,11,405,2,0,
165,11,409,2,0,
165,11,450,2,0,
165,11,496,4,0,
165,11,512,4,0,
165,11,522,4,0,
165,12,4,1,15,
165,12,33,1,1,
165,12,38,1,50,
165,12,48,1,8,
165,12,76,4,0,
165,12,91,4,0,
165,12,92,4,0,
165,12,97,1,43,
165,12,104,4,0,
165,12,113,1,22,1
165,12,113,4,0,
165,12,115,1,22,2
165,12,115,4,0,
165,12,129,1,36,
165,12,148,4,0,
165,12,156,4,0,
165,12,168,4,0,
165,12,182,4,0,
165,12,202,4,0,
165,12,213,4,0,
165,12,216,4,0,
165,12,218,4,0,
165,12,219,1,22,3
165,12,219,4,0,
165,12,226,1,29,
165,12,237,4,0,
165,12,241,4,0,
165,12,263,4,0,
165,12,264,4,0,
165,12,280,4,0,
165,12,290,4,0,
165,12,332,4,0,
165,13,4,1,15,
165,13,33,1,1,
165,13,38,1,50,
165,13,38,3,0,
165,13,48,1,8,
165,13,76,4,0,
165,13,91,4,0,
165,13,92,4,0,
165,13,97,1,43,
165,13,102,3,0,
165,13,104,4,0,
165,13,113,1,22,1
165,13,113,4,0,
165,13,115,1,22,2
165,13,115,4,0,
165,13,129,1,36,
165,13,148,4,0,
165,13,156,4,0,
165,13,164,3,0,
165,13,168,4,0,
165,13,182,4,0,
165,13,202,4,0,
165,13,207,3,0,
165,13,213,4,0,
165,13,216,4,0,
165,13,218,4,0,
165,13,219,1,22,3
165,13,219,4,0,
165,13,226,1,29,
165,13,237,4,0,
165,13,241,4,0,
165,13,263,4,0,
165,13,264,4,0,
165,13,280,4,0,
165,13,290,4,0,
165,13,332,4,0,
165,14,4,1,9,
165,14,8,3,0,
165,14,9,3,0,
165,14,14,4,0,
165,14,33,1,1,
165,14,38,1,38,
165,14,48,1,6,
165,14,60,2,0,
165,14,76,4,0,
165,14,91,4,0,
165,14,92,4,0,
165,14,97,1,30,
165,14,103,2,0,
165,14,104,4,0,
165,14,113,1,14,1
165,14,113,4,0,
165,14,115,1,14,2
165,14,115,4,0,
165,14,117,2,0,
165,14,129,1,33,
165,14,146,2,0,
165,14,148,4,0,
165,14,156,4,0,
165,14,164,4,0,
165,14,168,4,0,
165,14,173,3,0,
165,14,182,4,0,
165,14,183,1,17,
165,14,202,3,0,
165,14,207,4,0,
165,14,213,4,0,
165,14,214,3,0,
165,14,216,4,0,
165,14,218,4,0,
165,14,219,1,14,3
165,14,219,4,0,
165,14,226,1,22,
165,14,227,2,0,
165,14,237,4,0,
165,14,241,4,0,
165,14,253,3,0,
165,14,263,4,0,
165,14,264,2,0,
165,14,280,4,0,
165,14,282,2,0,
165,14,282,3,0,
165,14,318,1,25,
165,14,318,2,0,
165,14,332,4,0,
165,14,355,3,0,
165,14,366,3,0,
165,14,369,4,0,
165,14,374,4,0,
165,14,405,1,41,
165,14,405,2,0,
165,14,409,2,0,
165,14,409,3,0,
165,14,450,2,0,
165,14,450,3,0,
165,14,496,4,0,
165,14,512,4,0,
165,14,522,4,0,
165,15,4,1,9,
165,15,14,4,0,
165,15,33,1,1,
165,15,38,1,38,
165,15,48,1,6,
165,15,60,2,0,
165,15,76,4,0,
165,15,91,4,0,
165,15,92,4,0,
165,15,97,1,30,
165,15,103,2,0,
165,15,104,4,0,
165,15,113,1,14,1
165,15,113,4,0,
165,15,115,1,14,2
165,15,115,4,0,
165,15,117,2,0,
165,15,129,1,33,
165,15,146,2,0,
165,15,148,4,0,
165,15,156,4,0,
165,15,164,4,0,
165,15,168,4,0,
165,15,182,4,0,
165,15,183,1,17,
165,15,207,4,0,
165,15,213,4,0,
165,15,214,4,0,
165,15,216,4,0,
165,15,218,4,0,
165,15,219,1,14,3
165,15,219,4,0,
165,15,226,1,22,
165,15,227,2,0,
165,15,237,4,0,
165,15,241,4,0,
165,15,263,4,0,
165,15,264,2,0,
165,15,280,4,0,
165,15,282,2,0,
165,15,318,1,25,
165,15,318,2,0,
165,15,332,4,0,
165,15,355,4,0,
165,15,366,2,0,
165,15,369,4,0,
165,15,374,4,0,
165,15,405,1,41,
165,15,405,2,0,
165,15,409,2,0,
165,15,450,2,0,
165,15,496,4,0,
165,15,512,4,0,
165,15,522,4,0,
165,15,590,4,0,
165,15,611,4,0,
165,15,612,4,0,
165,16,4,1,9,1
165,16,8,3,0,
165,16,9,3,0,
165,16,14,4,0,
165,16,33,1,1,1
165,16,38,1,38,1
165,16,48,1,6,1
165,16,60,2,0,
165,16,76,4,0,
165,16,91,4,0,
165,16,92,4,0,
165,16,97,1,30,1
165,16,103,2,0,
165,16,104,4,0,
165,16,113,1,14,1
165,16,113,4,0,
165,16,115,1,14,2
165,16,115,4,0,
165,16,117,2,0,
165,16,129,1,33,1
165,16,146,2,0,
165,16,148,4,0,
165,16,156,4,0,
165,16,164,4,0,
165,16,168,4,0,
165,16,173,3,0,
165,16,182,4,0,
165,16,183,1,17,1
165,16,202,3,0,
165,16,207,4,0,
165,16,213,4,0,
165,16,214,4,0,
165,16,216,4,0,
165,16,218,4,0,
165,16,219,1,14,3
165,16,219,4,0,
165,16,226,1,22,1
165,16,227,2,0,
165,16,237,4,0,
165,16,241,4,0,
165,16,253,3,0,
165,16,263,4,0,
165,16,264,2,0,
165,16,264,3,0,
165,16,280,4,0,
165,16,282,2,0,
165,16,282,3,0,
165,16,290,4,0,
165,16,318,1,25,1
165,16,318,2,0,
165,16,332,4,0,
165,16,355,4,0,
165,16,366,2,0,
165,16,366,3,0,
165,16,369,4,0,
165,16,374,4,0,
165,16,405,1,41,1
165,16,405,2,0,
165,16,409,2,0,
165,16,409,3,0,
165,16,450,2,0,
165,16,450,3,0,
165,16,496,4,0,
165,16,512,4,0,
165,16,522,4,0,
165,16,590,4,0,
165,16,611,4,0,
165,16,612,4,0,
165,17,4,1,22,
165,17,14,4,0,
165,17,33,1,1,
165,17,38,1,40,
165,17,48,1,5,
165,17,60,2,0,
165,17,76,4,0,
165,17,92,4,0,
165,17,97,1,29,
165,17,103,2,0,
165,17,104,4,0,
165,17,113,1,12,1
165,17,113,4,0,
165,17,115,1,12,2
165,17,115,4,0,
165,17,117,2,0,
165,17,129,1,8,
165,17,146,2,0,
165,17,156,4,0,
165,17,164,4,0,
165,17,168,4,0,
165,17,182,4,0,
165,17,183,1,15,
165,17,207,4,0,
165,17,213,4,0,
165,17,214,4,0,
165,17,216,4,0,
165,17,218,4,0,
165,17,219,1,12,3
165,17,219,4,0,
165,17,226,1,26,
165,17,227,2,0,
165,17,237,4,0,
165,17,241,4,0,
165,17,263,4,0,
165,17,264,2,0,
165,17,280,4,0,
165,17,282,2,0,
165,17,318,1,19,
165,17,318,2,0,
165,17,332,4,0,
165,17,355,4,0,
165,17,366,2,0,
165,17,369,4,0,
165,17,374,4,0,
165,17,403,1,36,
165,17,405,1,33,
165,17,405,2,0,
165,17,409,2,0,
165,17,450,2,0,
165,17,496,4,0,
165,17,512,4,0,
165,17,590,4,0,
165,17,611,4,0,
165,18,4,1,22,
165,18,14,4,0,
165,18,33,1,1,
165,18,38,1,40,
165,18,48,1,5,
165,18,60,2,0,
165,18,68,2,0,
165,18,76,4,0,
165,18,92,4,0,
165,18,97,1,29,
165,18,103,2,0,
165,18,104,4,0,
165,18,113,1,12,1
165,18,113,4,0,
165,18,115,1,12,2
165,18,115,4,0,
165,18,117,2,0,
165,18,129,1,8,
165,18,146,2,0,
165,18,156,4,0,
165,18,164,4,0,
165,18,168,4,0,
165,18,182,4,0,
165,18,183,1,15,
165,18,203,2,0,
165,18,207,4,0,
165,18,213,4,0,
165,18,214,4,0,
165,18,216,4,0,
165,18,218,4,0,
165,18,219,1,12,3
165,18,219,4,0,
165,18,226,1,26,
165,18,227,2,0,
165,18,237,4,0,
165,18,241,4,0,
165,18,263,4,0,
165,18,264,2,0,
165,18,280,4,0,
165,18,282,2,0,
165,18,318,1,19,
165,18,318,2,0,
165,18,332,4,0,
165,18,355,4,0,
165,18,366,2,0,
165,18,369,4,0,
165,18,374,4,0,
165,18,403,1,36,
165,18,405,1,33,
165,18,405,2,0,
165,18,409,2,0,
165,18,450,2,0,
165,18,496,4,0,
165,18,512,4,0,
165,18,590,4,0,
165,18,611,4,0,
166,3,4,1,15,
166,3,8,4,0,
166,3,9,4,0,
166,3,29,4,0,
166,3,33,1,1,1
166,3,38,1,60,
166,3,48,1,1,2
166,3,48,1,8,
166,3,63,4,0,
166,3,76,4,0,
166,3,91,4,0,
166,3,92,4,0,
166,3,97,1,51,
166,3,104,4,0,
166,3,113,1,24,
166,3,115,1,24,3
166,3,129,1,42,
166,3,129,4,0,
166,3,148,4,0,
166,3,156,4,0,
166,3,168,4,0,
166,3,173,4,0,
166,3,174,4,0,
166,3,182,4,0,
166,3,202,4,0,
166,3,203,4,0,
166,3,205,4,0,
166,3,207,4,0,
166,3,213,4,0,
166,3,214,4,0,
166,3,216,4,0,
166,3,218,4,0,
166,3,219,1,24,4
166,3,223,4,0,
166,3,226,1,33,
166,3,230,4,0,
166,3,237,4,0,
166,3,241,4,0,
166,4,4,1,15,
166,4,8,4,0,
166,4,9,4,0,
166,4,29,4,0,
166,4,33,1,1,1
166,4,38,1,60,
166,4,48,1,1,2
166,4,48,1,8,
166,4,63,4,0,
166,4,76,4,0,
166,4,91,4,0,
166,4,92,4,0,
166,4,97,1,51,
166,4,104,4,0,
166,4,113,1,24,
166,4,115,1,24,3
166,4,129,1,42,
166,4,129,4,0,
166,4,148,4,0,
166,4,156,4,0,
166,4,168,4,0,
166,4,173,4,0,
166,4,174,4,0,
166,4,182,4,0,
166,4,202,4,0,
166,4,203,4,0,
166,4,205,4,0,
166,4,207,4,0,
166,4,213,4,0,
166,4,214,4,0,
166,4,216,4,0,
166,4,218,4,0,
166,4,219,1,24,4
166,4,223,4,0,
166,4,226,1,33,
166,4,230,4,0,
166,4,237,4,0,
166,4,241,4,0,
166,5,4,1,15,
166,5,33,1,1,1
166,5,38,1,60,
166,5,48,1,1,2
166,5,48,1,8,
166,5,63,4,0,
166,5,76,4,0,
166,5,91,4,0,
166,5,92,4,0,
166,5,97,1,51,
166,5,104,4,0,
166,5,113,1,24,
166,5,113,4,0,
166,5,115,1,24,3
166,5,115,4,0,
166,5,129,1,42,
166,5,148,4,0,
166,5,156,4,0,
166,5,168,4,0,
166,5,182,4,0,
166,5,202,4,0,
166,5,213,4,0,
166,5,216,4,0,
166,5,218,4,0,
166,5,219,1,24,4
166,5,219,4,0,
166,5,226,1,33,
166,5,237,4,0,
166,5,241,4,0,
166,5,263,4,0,
166,5,264,4,0,
166,5,280,4,0,
166,5,290,4,0,
166,5,332,4,0,
166,6,4,1,15,
166,6,5,3,0,
166,6,8,3,0,
166,6,9,3,0,
166,6,14,3,0,
166,6,33,1,1,1
166,6,38,1,60,
166,6,38,3,0,
166,6,48,1,1,2
166,6,48,1,8,
166,6,63,4,0,
166,6,76,4,0,
166,6,91,4,0,
166,6,92,4,0,
166,6,97,1,51,
166,6,102,3,0,
166,6,104,4,0,
166,6,113,1,24,
166,6,113,4,0,
166,6,115,1,24,3
166,6,115,4,0,
166,6,129,1,42,
166,6,129,3,0,
166,6,148,4,0,
166,6,156,4,0,
166,6,164,3,0,
166,6,168,4,0,
166,6,173,3,0,
166,6,182,4,0,
166,6,202,4,0,
166,6,203,3,0,
166,6,205,3,0,
166,6,207,3,0,
166,6,213,4,0,
166,6,214,3,0,
166,6,216,4,0,
166,6,218,4,0,
166,6,219,1,24,4
166,6,219,4,0,
166,6,223,3,0,
166,6,226,1,33,
166,6,237,4,0,
166,6,241,4,0,
166,6,263,4,0,
166,6,264,4,0,
166,6,280,4,0,
166,6,290,4,0,
166,6,332,4,0,
166,7,4,1,15,
166,7,5,3,0,
166,7,14,3,0,
166,7,33,1,1,1
166,7,38,1,60,
166,7,38,3,0,
166,7,48,1,1,2
166,7,48,1,8,
166,7,63,4,0,
166,7,76,4,0,
166,7,91,4,0,
166,7,92,4,0,
166,7,97,1,51,
166,7,102,3,0,
166,7,104,4,0,
166,7,113,1,24,
166,7,113,4,0,
166,7,115,1,24,3
166,7,115,4,0,
166,7,129,1,42,
166,7,148,4,0,
166,7,156,4,0,
166,7,164,3,0,
166,7,168,4,0,
166,7,182,4,0,
166,7,202,4,0,
166,7,213,4,0,
166,7,216,4,0,
166,7,218,4,0,
166,7,219,1,24,4
166,7,219,4,0,
166,7,226,1,33,
166,7,237,4,0,
166,7,241,4,0,
166,7,263,4,0,
166,7,264,4,0,
166,7,280,4,0,
166,7,290,4,0,
166,7,332,4,0,
166,8,4,1,1,3
166,8,4,1,9,
166,8,14,4,0,
166,8,33,1,1,1
166,8,38,1,48,
166,8,48,1,1,2
166,8,48,1,6,
166,8,63,4,0,
166,8,70,4,0,
166,8,76,4,0,
166,8,91,4,0,
166,8,92,4,0,
166,8,97,1,36,
166,8,104,4,0,
166,8,113,1,14,1
166,8,113,4,0,
166,8,115,1,14,2
166,8,115,4,0,
166,8,129,1,41,
166,8,148,4,0,
166,8,156,4,0,
166,8,164,4,0,
166,8,168,4,0,
166,8,182,4,0,
166,8,183,1,17,
166,8,202,4,0,
166,8,203,4,0,
166,8,207,4,0,
166,8,213,4,0,
166,8,214,4,0,
166,8,216,4,0,
166,8,218,4,0,
166,8,219,1,14,3
166,8,219,4,0,
166,8,226,1,24,
166,8,237,4,0,
166,8,241,4,0,
166,8,249,4,0,
166,8,263,4,0,
166,8,264,4,0,
166,8,280,4,0,
166,8,290,4,0,
166,8,318,1,29,
166,8,318,4,0,
166,8,332,4,0,
166,8,355,4,0,
166,8,363,4,0,
166,8,369,4,0,
166,8,374,4,0,
166,8,405,1,53,
166,8,409,4,0,
166,8,411,4,0,
166,8,416,4,0,
166,8,445,4,0,
166,9,4,1,1,3
166,9,4,1,9,
166,9,8,3,0,
166,9,9,3,0,
166,9,14,4,0,
166,9,33,1,1,1
166,9,38,1,48,
166,9,48,1,1,2
166,9,48,1,6,
166,9,63,4,0,
166,9,70,4,0,
166,9,76,4,0,
166,9,91,4,0,
166,9,92,4,0,
166,9,97,1,36,
166,9,104,4,0,
166,9,113,1,14,1
166,9,113,4,0,
166,9,115,1,14,2
166,9,115,4,0,
166,9,129,1,41,
166,9,129,3,0,
166,9,148,4,0,
166,9,156,4,0,
166,9,164,4,0,
166,9,168,4,0,
166,9,173,3,0,
166,9,182,4,0,
166,9,183,1,17,
166,9,202,4,0,
166,9,203,4,0,
166,9,205,3,0,
166,9,207,4,0,
166,9,213,4,0,
166,9,214,4,0,
166,9,216,4,0,
166,9,218,4,0,
166,9,219,1,14,3
166,9,219,4,0,
166,9,226,1,24,
166,9,237,4,0,
166,9,241,4,0,
166,9,249,4,0,
166,9,253,3,0,
166,9,263,4,0,
166,9,264,4,0,
166,9,280,4,0,
166,9,282,3,0,
166,9,290,4,0,
166,9,314,3,0,
166,9,318,1,29,
166,9,318,4,0,
166,9,332,4,0,
166,9,355,4,0,
166,9,363,4,0,
166,9,369,4,0,
166,9,374,4,0,
166,9,405,1,53,
166,9,409,4,0,
166,9,411,4,0,
166,9,416,4,0,
166,9,445,4,0,
166,9,466,3,0,
166,10,4,1,1,3
166,10,4,1,9,
166,10,8,3,0,
166,10,9,3,0,
166,10,14,4,0,
166,10,29,3,0,
166,10,33,1,1,1
166,10,38,1,48,
166,10,48,1,1,2
166,10,48,1,6,
166,10,63,4,0,
166,10,70,4,0,
166,10,76,4,0,
166,10,81,3,0,
166,10,91,4,0,
166,10,92,4,0,
166,10,97,1,36,
166,10,104,4,0,
166,10,113,1,14,1
166,10,113,4,0,
166,10,115,1,14,2
166,10,115,4,0,
166,10,129,1,41,
166,10,129,3,0,
166,10,148,4,0,
166,10,156,4,0,
166,10,164,4,0,
166,10,168,4,0,
166,10,173,3,0,
166,10,182,4,0,
166,10,183,1,17,
166,10,202,4,0,
166,10,203,4,0,
166,10,205,3,0,
166,10,207,4,0,
166,10,213,4,0,
166,10,214,4,0,
166,10,216,4,0,
166,10,218,4,0,
166,10,219,1,14,3
166,10,219,4,0,
166,10,226,1,24,
166,10,237,4,0,
166,10,241,4,0,
166,10,249,4,0,
166,10,253,3,0,
166,10,263,4,0,
166,10,264,4,0,
166,10,280,4,0,
166,10,282,3,0,
166,10,290,4,0,
166,10,314,3,0,
166,10,318,1,29,
166,10,318,4,0,
166,10,332,4,0,
166,10,355,4,0,
166,10,363,4,0,
166,10,366,3,0,
166,10,369,4,0,
166,10,374,4,0,
166,10,405,1,53,
166,10,409,4,0,
166,10,411,4,0,
166,10,416,4,0,
166,10,445,4,0,
166,10,450,3,0,
166,10,466,3,0,
166,11,4,1,1,3
166,11,4,1,9,
166,11,14,4,0,
166,11,33,1,1,1
166,11,38,1,48,
166,11,48,1,1,2
166,11,48,1,6,
166,11,63,4,0,
166,11,70,4,0,
166,11,76,4,0,
166,11,91,4,0,
166,11,92,4,0,
166,11,97,1,36,
166,11,104,4,0,
166,11,113,1,14,1
166,11,113,4,0,
166,11,115,1,14,2
166,11,115,4,0,
166,11,129,1,41,
166,11,148,4,0,
166,11,156,4,0,
166,11,164,4,0,
166,11,168,4,0,
166,11,182,4,0,
166,11,183,1,17,
166,11,207,4,0,
166,11,213,4,0,
166,11,216,4,0,
166,11,218,4,0,
166,11,219,1,14,3
166,11,219,4,0,
166,11,226,1,24,
166,11,237,4,0,
166,11,241,4,0,
166,11,249,4,0,
166,11,263,4,0,
166,11,280,4,0,
166,11,318,1,29,
166,11,332,4,0,
166,11,369,4,0,
166,11,374,4,0,
166,11,405,1,53,
166,11,411,4,0,
166,11,416,4,0,
166,11,496,4,0,
166,11,512,4,0,
166,11,522,4,0,
166,12,4,1,15,
166,12,33,1,1,1
166,12,38,1,60,
166,12,48,1,1,2
166,12,48,1,8,
166,12,63,4,0,
166,12,76,4,0,
166,12,91,4,0,
166,12,92,4,0,
166,12,97,1,51,
166,12,104,4,0,
166,12,113,1,24,
166,12,113,4,0,
166,12,115,1,24,3
166,12,115,4,0,
166,12,129,1,42,
166,12,148,4,0,
166,12,156,4,0,
166,12,168,4,0,
166,12,182,4,0,
166,12,202,4,0,
166,12,213,4,0,
166,12,216,4,0,
166,12,218,4,0,
166,12,219,1,24,4
166,12,219,4,0,
166,12,226,1,33,
166,12,237,4,0,
166,12,241,4,0,
166,12,263,4,0,
166,12,264,4,0,
166,12,280,4,0,
166,12,290,4,0,
166,12,332,4,0,
166,13,4,1,15,
166,13,33,1,1,1
166,13,38,1,60,
166,13,38,3,0,
166,13,48,1,1,2
166,13,48,1,8,
166,13,63,4,0,
166,13,76,4,0,
166,13,91,4,0,
166,13,92,4,0,
166,13,97,1,51,
166,13,102,3,0,
166,13,104,4,0,
166,13,113,1,24,
166,13,113,4,0,
166,13,115,1,24,3
166,13,115,4,0,
166,13,129,1,42,
166,13,148,4,0,
166,13,156,4,0,
166,13,164,3,0,
166,13,168,4,0,
166,13,182,4,0,
166,13,202,4,0,
166,13,207,3,0,
166,13,213,4,0,
166,13,216,4,0,
166,13,218,4,0,
166,13,219,1,24,4
166,13,219,4,0,
166,13,226,1,33,
166,13,237,4,0,
166,13,241,4,0,
166,13,263,4,0,
166,13,264,4,0,
166,13,280,4,0,
166,13,290,4,0,
166,13,332,4,0,
166,14,4,1,1,3
166,14,4,1,9,
166,14,8,3,0,
166,14,9,3,0,
166,14,14,4,0,
166,14,33,1,1,1
166,14,38,1,48,
166,14,48,1,1,2
166,14,48,1,6,
166,14,63,4,0,
166,14,70,4,0,
166,14,76,4,0,
166,14,91,4,0,
166,14,92,4,0,
166,14,97,1,36,
166,14,104,4,0,
166,14,113,1,14,1
166,14,113,4,0,
166,14,115,1,14,2
166,14,115,4,0,
166,14,129,1,41,
166,14,148,4,0,
166,14,156,4,0,
166,14,164,4,0,
166,14,168,4,0,
166,14,173,3,0,
166,14,182,4,0,
166,14,183,1,17,
166,14,202,3,0,
166,14,207,4,0,
166,14,213,4,0,
166,14,214,3,0,
166,14,216,4,0,
166,14,218,4,0,
166,14,219,1,14,3
166,14,219,4,0,
166,14,226,1,24,
166,14,237,4,0,
166,14,241,4,0,
166,14,249,4,0,
166,14,253,3,0,
166,14,263,4,0,
166,14,280,4,0,
166,14,282,3,0,
166,14,318,1,29,
166,14,332,4,0,
166,14,355,3,0,
166,14,366,3,0,
166,14,369,4,0,
166,14,374,4,0,
166,14,405,1,53,
166,14,409,3,0,
166,14,411,4,0,
166,14,416,4,0,
166,14,450,3,0,
166,14,496,4,0,
166,14,512,4,0,
166,14,522,4,0,
166,15,4,1,1,3
166,15,4,1,9,
166,15,14,4,0,
166,15,33,1,1,1
166,15,38,1,48,
166,15,48,1,1,2
166,15,48,1,6,
166,15,63,4,0,
166,15,70,4,0,
166,15,76,4,0,
166,15,91,4,0,
166,15,92,4,0,
166,15,97,1,36,
166,15,104,4,0,
166,15,113,1,14,1
166,15,113,4,0,
166,15,115,1,14,2
166,15,115,4,0,
166,15,129,1,41,
166,15,148,4,0,
166,15,156,4,0,
166,15,164,4,0,
166,15,168,4,0,
166,15,182,4,0,
166,15,183,1,17,
166,15,207,4,0,
166,15,213,4,0,
166,15,214,4,0,
166,15,216,4,0,
166,15,218,4,0,
166,15,219,1,14,3
166,15,219,4,0,
166,15,226,1,24,
166,15,237,4,0,
166,15,241,4,0,
166,15,249,4,0,
166,15,263,4,0,
166,15,280,4,0,
166,15,318,1,29,
166,15,332,4,0,
166,15,355,4,0,
166,15,369,4,0,
166,15,374,4,0,
166,15,405,1,53,
166,15,411,4,0,
166,15,416,4,0,
166,15,496,4,0,
166,15,512,4,0,
166,15,522,4,0,
166,15,590,4,0,
166,15,611,4,0,
166,15,612,4,0,
166,16,4,1,1,3
166,16,4,1,9,1
166,16,8,3,0,
166,16,9,3,0,
166,16,14,4,0,
166,16,33,1,1,1
166,16,38,1,48,1
166,16,48,1,1,2
166,16,48,1,6,1
166,16,63,4,0,
166,16,70,4,0,
166,16,76,4,0,
166,16,91,4,0,
166,16,92,4,0,
166,16,97,1,36,1
166,16,104,4,0,
166,16,113,1,14,1
166,16,113,4,0,
166,16,115,1,14,2
166,16,115,4,0,
166,16,129,1,41,1
166,16,148,4,0,
166,16,156,4,0,
166,16,164,4,0,
166,16,168,4,0,
166,16,173,3,0,
166,16,182,4,0,
166,16,183,1,17,1
166,16,202,3,0,
166,16,207,4,0,
166,16,213,4,0,
166,16,214,4,0,
166,16,216,4,0,
166,16,218,4,0,
166,16,219,1,14,3
166,16,219,4,0,
166,16,226,1,24,1
166,16,237,4,0,
166,16,241,4,0,
166,16,249,4,0,
166,16,253,3,0,
166,16,263,4,0,
166,16,264,3,0,
166,16,280,4,0,
166,16,282,3,0,
166,16,290,4,0,
166,16,318,1,29,1
166,16,332,4,0,
166,16,355,4,0,
166,16,366,3,0,
166,16,369,4,0,
166,16,374,4,0,
166,16,405,1,53,1
166,16,409,3,0,
166,16,411,4,0,
166,16,416,4,0,
166,16,450,3,0,
166,16,496,4,0,
166,16,512,4,0,
166,16,522,4,0,
166,16,590,4,0,
166,16,611,4,0,
166,16,612,4,0,
166,17,4,1,24,
166,17,14,4,0,
166,17,33,1,1,1
166,17,38,1,47,
166,17,48,1,1,2
166,17,48,1,5,
166,17,63,4,0,
166,17,76,4,0,
166,17,92,4,0,
166,17,97,1,33,
166,17,104,4,0,
166,17,113,1,12,1
166,17,113,4,0,
166,17,115,1,12,2
166,17,115,4,0,
166,17,129,1,1,3
166,17,129,1,8,
166,17,156,4,0,
166,17,164,4,0,
166,17,168,4,0,
166,17,182,4,0,
166,17,183,1,15,
166,17,207,4,0,
166,17,213,4,0,
166,17,214,4,0,
166,17,216,4,0,
166,17,218,4,0,
166,17,219,1,12,3
166,17,219,4,0,
166,17,226,1,29,
166,17,237,4,0,
166,17,241,4,0,
166,17,263,4,0,
166,17,280,4,0,
166,17,318,1,20,
166,17,332,4,0,
166,17,355,4,0,
166,17,369,4,0,
166,17,374,4,0,
166,17,403,1,42,
166,17,405,1,38,
166,17,411,4,0,
166,17,416,4,0,
166,17,496,4,0,
166,17,512,4,0,
166,17,590,4,0,
166,17,611,4,0,
166,18,4,1,24,
166,18,14,4,0,
166,18,33,1,1,1
166,18,38,1,47,
166,18,48,1,1,2
166,18,48,1,5,
166,18,63,4,0,
166,18,76,4,0,
166,18,92,4,0,
166,18,97,1,33,
166,18,104,4,0,
166,18,113,1,12,1
166,18,113,4,0,
166,18,115,1,12,2
166,18,115,4,0,
166,18,129,1,1,3
166,18,129,1,8,
166,18,156,4,0,
166,18,164,4,0,
166,18,168,4,0,
166,18,182,4,0,
166,18,183,1,15,
166,18,207,4,0,
166,18,213,4,0,
166,18,214,4,0,
166,18,216,4,0,
166,18,218,4,0,
166,18,219,1,12,3
166,18,219,4,0,
166,18,226,1,29,
166,18,237,4,0,
166,18,241,4,0,
166,18,263,4,0,
166,18,280,4,0,
166,18,318,1,20,
166,18,332,4,0,
166,18,355,4,0,
166,18,369,4,0,
166,18,374,4,0,
166,18,403,1,42,
166,18,405,1,38,
166,18,411,4,0,
166,18,416,4,0,
166,18,496,4,0,
166,18,512,4,0,
166,18,590,4,0,
166,18,611,4,0,
167,3,40,1,1,1
167,3,49,2,0,
167,3,50,2,0,
167,3,60,2,0,
167,3,76,4,0,
167,3,81,1,1,2
167,3,91,4,0,
167,3,92,4,0,
167,3,94,1,53,
167,3,94,4,0,
167,3,101,1,17,
167,3,103,1,45,
167,3,104,4,0,
167,3,132,1,11,
167,3,141,1,23,
167,3,148,4,0,
167,3,154,1,30,
167,3,156,4,0,
167,3,168,4,0,
167,3,169,1,37,
167,3,173,4,0,
167,3,174,4,0,
167,3,182,4,0,
167,3,184,1,6,
167,3,188,4,0,
167,3,202,4,0,
167,3,203,4,0,
167,3,207,4,0,
167,3,213,4,0,
167,3,214,4,0,
167,3,216,4,0,
167,3,218,4,0,
167,3,226,2,0,
167,3,228,2,0,
167,3,237,4,0,
167,3,241,4,0,
167,4,40,1,1,1
167,4,49,2,0,
167,4,50,2,0,
167,4,60,2,0,
167,4,76,4,0,
167,4,81,1,1,2
167,4,91,4,0,
167,4,92,4,0,
167,4,94,1,53,
167,4,94,4,0,
167,4,97,1,45,
167,4,101,1,17,
167,4,104,4,0,
167,4,132,1,11,
167,4,141,1,23,
167,4,148,4,0,
167,4,154,1,30,
167,4,156,4,0,
167,4,168,4,0,
167,4,169,1,37,
167,4,173,4,0,
167,4,174,4,0,
167,4,182,4,0,
167,4,184,1,6,
167,4,188,4,0,
167,4,202,4,0,
167,4,203,4,0,
167,4,207,4,0,
167,4,213,4,0,
167,4,214,4,0,
167,4,216,4,0,
167,4,218,4,0,
167,4,226,2,0,
167,4,228,2,0,
167,4,237,4,0,
167,4,241,4,0,
167,5,40,1,1,1
167,5,49,2,0,
167,5,50,2,0,
167,5,60,2,0,
167,5,76,4,0,
167,5,81,1,1,2
167,5,91,4,0,
167,5,92,4,0,
167,5,94,1,53,
167,5,94,4,0,
167,5,97,1,45,
167,5,101,1,17,
167,5,104,4,0,
167,5,132,1,11,
167,5,141,1,23,
167,5,148,4,0,
167,5,154,1,30,
167,5,156,4,0,
167,5,168,4,0,
167,5,169,1,37,
167,5,182,4,0,
167,5,184,1,6,
167,5,188,4,0,
167,5,202,4,0,
167,5,213,4,0,
167,5,216,4,0,
167,5,218,4,0,
167,5,226,2,0,
167,5,228,2,0,
167,5,237,4,0,
167,5,241,4,0,
167,5,263,4,0,
167,5,290,4,0,
167,5,324,2,0,
167,6,34,3,0,
167,6,38,3,0,
167,6,40,1,1,1
167,6,49,2,0,
167,6,50,2,0,
167,6,60,2,0,
167,6,76,4,0,
167,6,81,1,1,2
167,6,91,4,0,
167,6,92,4,0,
167,6,94,1,53,
167,6,94,4,0,
167,6,97,1,45,
167,6,101,1,17,
167,6,102,3,0,
167,6,104,4,0,
167,6,132,1,11,
167,6,141,1,23,
167,6,148,4,0,
167,6,154,1,30,
167,6,156,4,0,
167,6,164,3,0,
167,6,168,4,0,
167,6,169,1,37,
167,6,173,3,0,
167,6,182,4,0,
167,6,184,1,6,
167,6,188,4,0,
167,6,202,4,0,
167,6,203,3,0,
167,6,207,3,0,
167,6,213,4,0,
167,6,214,3,0,
167,6,216,4,0,
167,6,218,4,0,
167,6,226,2,0,
167,6,228,2,0,
167,6,237,4,0,
167,6,241,4,0,
167,6,263,4,0,
167,6,290,4,0,
167,6,324,2,0,
167,7,34,3,0,
167,7,38,3,0,
167,7,40,1,1,1
167,7,49,2,0,
167,7,50,2,0,
167,7,60,2,0,
167,7,76,4,0,
167,7,81,1,1,2
167,7,91,4,0,
167,7,92,4,0,
167,7,94,1,53,
167,7,94,4,0,
167,7,97,1,45,
167,7,101,1,17,
167,7,102,3,0,
167,7,104,4,0,
167,7,132,1,11,
167,7,141,1,23,
167,7,148,4,0,
167,7,154,1,30,
167,7,156,4,0,
167,7,164,3,0,
167,7,168,4,0,
167,7,169,1,37,
167,7,182,4,0,
167,7,184,1,6,
167,7,188,4,0,
167,7,202,4,0,
167,7,213,4,0,
167,7,216,4,0,
167,7,218,4,0,
167,7,226,2,0,
167,7,228,2,0,
167,7,237,4,0,
167,7,241,4,0,
167,7,263,4,0,
167,7,290,4,0,
167,7,324,2,0,
167,8,40,1,1,1
167,8,42,1,36,
167,8,49,2,0,
167,8,50,2,0,
167,8,60,2,0,
167,8,76,4,0,
167,8,81,1,1,2
167,8,91,4,0,
167,8,92,4,0,
167,8,94,1,40,
167,8,94,4,0,
167,8,97,1,33,
167,8,101,1,15,
167,8,104,4,0,
167,8,132,1,8,
167,8,141,1,12,
167,8,148,4,0,
167,8,154,1,22,
167,8,156,4,0,
167,8,164,4,0,
167,8,168,4,0,
167,8,169,1,29,
167,8,182,4,0,
167,8,184,1,5,
167,8,188,4,0,
167,8,202,4,0,
167,8,203,4,0,
167,8,207,4,0,
167,8,213,4,0,
167,8,214,4,0,
167,8,216,4,0,
167,8,218,4,0,
167,8,226,2,0,
167,8,228,2,0,
167,8,237,4,0,
167,8,241,4,0,
167,8,263,4,0,
167,8,290,4,0,
167,8,324,2,0,
167,8,363,4,0,
167,8,389,1,26,
167,8,390,2,0,
167,8,398,1,43,
167,8,398,2,0,
167,8,398,4,0,
167,8,425,1,19,
167,8,445,4,0,
167,9,40,1,1,1
167,9,42,1,36,
167,9,49,2,0,
167,9,50,2,0,
167,9,60,2,0,
167,9,76,4,0,
167,9,81,1,1,2
167,9,91,4,0,
167,9,92,4,0,
167,9,94,1,40,
167,9,94,4,0,
167,9,97,1,33,
167,9,101,1,15,
167,9,104,4,0,
167,9,132,1,8,
167,9,141,1,12,
167,9,148,4,0,
167,9,154,1,22,
167,9,156,4,0,
167,9,164,4,0,
167,9,168,4,0,
167,9,169,1,29,
167,9,182,4,0,
167,9,184,1,5,
167,9,188,4,0,
167,9,202,4,0,
167,9,203,4,0,
167,9,207,4,0,
167,9,213,4,0,
167,9,214,4,0,
167,9,216,4,0,
167,9,218,4,0,
167,9,226,2,0,
167,9,228,2,0,
167,9,237,4,0,
167,9,241,4,0,
167,9,263,4,0,
167,9,290,4,0,
167,9,324,2,0,
167,9,324,3,0,
167,9,340,3,0,
167,9,363,4,0,
167,9,389,1,26,
167,9,389,3,0,
167,9,390,2,0,
167,9,398,1,43,
167,9,398,2,0,
167,9,398,4,0,
167,9,425,1,19,
167,9,445,4,0,
167,10,40,1,1,1
167,10,42,1,36,
167,10,49,2,0,
167,10,50,2,0,
167,10,60,2,0,
167,10,76,4,0,
167,10,81,1,1,2
167,10,81,3,0,
167,10,91,4,0,
167,10,92,4,0,
167,10,94,1,40,
167,10,94,4,0,
167,10,97,1,33,
167,10,101,1,15,
167,10,104,4,0,
167,10,132,1,8,
167,10,141,1,12,
167,10,148,4,0,
167,10,154,1,22,
167,10,156,4,0,
167,10,164,4,0,
167,10,168,4,0,
167,10,169,1,29,
167,10,182,4,0,
167,10,184,1,5,
167,10,188,4,0,
167,10,202,4,0,
167,10,203,4,0,
167,10,207,4,0,
167,10,213,4,0,
167,10,214,4,0,
167,10,216,4,0,
167,10,218,4,0,
167,10,226,2,0,
167,10,228,2,0,
167,10,237,4,0,
167,10,241,4,0,
167,10,263,4,0,
167,10,290,4,0,
167,10,324,2,0,
167,10,324,3,0,
167,10,340,3,0,
167,10,363,4,0,
167,10,389,1,26,
167,10,389,3,0,
167,10,390,2,0,
167,10,398,1,43,
167,10,398,2,0,
167,10,398,4,0,
167,10,425,1,19,
167,10,445,4,0,
167,10,450,3,0,
167,11,40,1,1,1
167,11,41,2,0,
167,11,42,1,36,
167,11,49,2,0,
167,11,50,2,0,
167,11,60,2,0,
167,11,76,4,0,
167,11,81,1,1,2
167,11,91,4,0,
167,11,92,4,0,
167,11,94,1,40,
167,11,94,4,0,
167,11,97,1,33,
167,11,101,1,15,
167,11,104,4,0,
167,11,132,1,8,
167,11,141,1,12,
167,11,148,4,0,
167,11,154,1,22,
167,11,156,4,0,
167,11,164,4,0,
167,11,168,4,0,
167,11,169,1,29,
167,11,182,4,0,
167,11,184,1,5,
167,11,188,4,0,
167,11,207,4,0,
167,11,213,4,0,
167,11,216,4,0,
167,11,218,4,0,
167,11,226,2,0,
167,11,228,2,0,
167,11,237,4,0,
167,11,241,4,0,
167,11,263,4,0,
167,11,324,2,0,
167,11,389,1,26,
167,11,390,2,0,
167,11,398,1,43,
167,11,398,4,0,
167,11,400,2,0,
167,11,425,1,19,
167,11,440,1,47,
167,11,468,4,0,
167,11,474,4,0,
167,11,476,2,0,
167,11,496,4,0,
167,11,522,4,0,
167,11,527,2,0,
167,12,40,1,1,1
167,12,76,4,0,
167,12,81,1,1,2
167,12,91,4,0,
167,12,92,4,0,
167,12,94,1,53,
167,12,94,4,0,
167,12,97,1,45,
167,12,101,1,17,
167,12,104,4,0,
167,12,132,1,11,
167,12,141,1,23,
167,12,148,4,0,
167,12,154,1,30,
167,12,156,4,0,
167,12,168,4,0,
167,12,169,1,37,
167,12,182,4,0,
167,12,184,1,6,
167,12,188,4,0,
167,12,202,4,0,
167,12,213,4,0,
167,12,216,4,0,
167,12,218,4,0,
167,12,237,4,0,
167,12,241,4,0,
167,12,263,4,0,
167,12,290,4,0,
167,13,34,3,0,
167,13,38,3,0,
167,13,40,1,1,1
167,13,76,4,0,
167,13,81,1,1,2
167,13,91,4,0,
167,13,92,4,0,
167,13,94,1,53,
167,13,94,4,0,
167,13,97,1,45,
167,13,101,1,17,
167,13,102,3,0,
167,13,104,4,0,
167,13,132,1,11,
167,13,141,1,23,
167,13,148,4,0,
167,13,154,1,30,
167,13,156,4,0,
167,13,164,3,0,
167,13,168,4,0,
167,13,169,1,37,
167,13,182,4,0,
167,13,184,1,6,
167,13,188,4,0,
167,13,202,4,0,
167,13,207,3,0,
167,13,213,4,0,
167,13,216,4,0,
167,13,218,4,0,
167,13,237,4,0,
167,13,241,4,0,
167,13,263,4,0,
167,13,290,4,0,
167,14,40,1,1,1
167,14,41,2,0,
167,14,42,1,36,
167,14,49,2,0,
167,14,50,2,0,
167,14,60,2,0,
167,14,76,4,0,
167,14,81,1,1,2
167,14,91,4,0,
167,14,92,4,0,
167,14,94,1,40,
167,14,94,4,0,
167,14,97,1,33,
167,14,101,1,15,
167,14,104,4,0,
167,14,132,1,8,
167,14,141,1,12,
167,14,148,4,0,
167,14,154,1,22,
167,14,156,4,0,
167,14,164,4,0,
167,14,168,4,0,
167,14,169,1,29,
167,14,182,4,0,
167,14,184,1,5,
167,14,188,4,0,
167,14,202,3,0,
167,14,207,4,0,
167,14,213,4,0,
167,14,214,3,0,
167,14,216,4,0,
167,14,218,4,0,
167,14,226,2,0,
167,14,228,2,0,
167,14,237,4,0,
167,14,241,4,0,
167,14,263,4,0,
167,14,324,2,0,
167,14,324,3,0,
167,14,340,3,0,
167,14,389,1,26,
167,14,390,2,0,
167,14,398,1,43,
167,14,398,4,0,
167,14,400,2,0,
167,14,404,4,0,
167,14,425,1,19,
167,14,440,1,47,
167,14,450,3,0,
167,14,468,4,0,
167,14,474,4,0,
167,14,476,2,0,
167,14,492,3,0,
167,14,496,4,0,
167,14,522,4,0,
167,14,527,2,0,
167,14,527,3,0,
167,15,40,1,1,1
167,15,41,2,0,
167,15,42,1,36,
167,15,49,2,0,
167,15,50,2,0,
167,15,60,2,0,
167,15,76,4,0,
167,15,81,1,1,2
167,15,91,4,0,
167,15,92,4,0,
167,15,94,1,40,
167,15,94,4,0,
167,15,97,1,33,
167,15,101,1,15,
167,15,104,4,0,
167,15,132,1,8,
167,15,141,1,12,
167,15,148,4,0,
167,15,154,1,22,
167,15,156,4,0,
167,15,164,4,0,
167,15,168,4,0,
167,15,169,1,29,
167,15,182,4,0,
167,15,184,1,5,
167,15,188,4,0,
167,15,207,4,0,
167,15,213,4,0,
167,15,214,4,0,
167,15,216,4,0,
167,15,218,4,0,
167,15,224,2,0,
167,15,226,2,0,
167,15,228,2,0,
167,15,237,4,0,
167,15,241,4,0,
167,15,263,4,0,
167,15,324,2,0,
167,15,389,1,26,
167,15,390,2,0,
167,15,398,1,43,
167,15,398,4,0,
167,15,400,2,0,
167,15,404,4,0,
167,15,425,1,19,
167,15,440,1,47,
167,15,468,4,0,
167,15,474,4,0,
167,15,476,2,0,
167,15,496,4,0,
167,15,522,4,0,
167,15,527,2,0,
167,15,564,1,50,
167,15,590,4,0,
167,15,611,4,0,
167,16,40,1,1,1
167,16,41,2,0,
167,16,42,1,36,1
167,16,49,2,0,
167,16,50,2,0,
167,16,60,2,0,
167,16,76,4,0,
167,16,81,1,1,2
167,16,91,4,0,
167,16,92,4,0,
167,16,94,1,40,1
167,16,94,4,0,
167,16,97,1,33,1
167,16,101,1,15,1
167,16,104,4,0,
167,16,132,1,8,1
167,16,141,1,12,1
167,16,148,4,0,
167,16,154,1,22,1
167,16,156,4,0,
167,16,164,4,0,
167,16,168,4,0,
167,16,169,1,29,1
167,16,173,3,0,
167,16,182,4,0,
167,16,184,1,5,1
167,16,188,4,0,
167,16,202,3,0,
167,16,207,4,0,
167,16,213,4,0,
167,16,214,4,0,
167,16,216,4,0,
167,16,218,4,0,
167,16,224,2,0,
167,16,226,2,0,
167,16,228,2,0,
167,16,237,4,0,
167,16,241,4,0,
167,16,263,4,0,
167,16,290,4,0,
167,16,324,2,0,
167,16,324,3,0,
167,16,340,3,0,
167,16,389,1,26,1
167,16,390,2,0,
167,16,398,1,43,1
167,16,398,4,0,
167,16,400,2,0,
167,16,404,4,0,
167,16,425,1,19,1
167,16,440,1,47,1
167,16,450,3,0,
167,16,468,4,0,
167,16,474,4,0,
167,16,476,2,0,
167,16,492,3,0,
167,16,496,4,0,
167,16,522,4,0,
167,16,527,2,0,
167,16,527,3,0,
167,16,564,1,50,1
167,16,590,4,0,
167,16,611,4,0,
167,17,40,1,1,1
167,17,41,2,0,
167,17,42,1,36,
167,17,49,2,0,
167,17,50,2,0,
167,17,60,2,0,
167,17,71,1,5,
167,17,76,4,0,
167,17,81,1,1,2
167,17,92,4,0,
167,17,94,1,40,
167,17,94,4,0,
167,17,97,1,33,
167,17,101,1,15,
167,17,104,4,0,
167,17,132,1,1,3
167,17,141,4,0,
167,17,154,1,22,
167,17,156,4,0,
167,17,164,4,0,
167,17,168,4,0,
167,17,169,1,29,
167,17,182,4,0,
167,17,184,1,12,
167,17,188,4,0,
167,17,207,4,0,
167,17,213,4,0,
167,17,214,4,0,
167,17,216,4,0,
167,17,218,4,0,
167,17,224,2,0,
167,17,226,2,0,
167,17,228,2,0,
167,17,237,4,0,
167,17,241,4,0,
167,17,263,4,0,
167,17,324,2,0,
167,17,389,1,26,
167,17,390,2,0,
167,17,398,1,43,
167,17,398,4,0,
167,17,400,2,0,
167,17,404,4,0,
167,17,425,1,19,
167,17,440,1,47,
167,17,474,4,0,
167,17,476,2,0,
167,17,496,4,0,
167,17,527,2,0,
167,17,564,1,50,
167,17,590,4,0,
167,17,611,1,8,
167,17,611,4,0,
167,17,672,1,54,
167,18,40,1,1,1
167,18,41,2,0,
167,18,42,1,36,
167,18,49,2,0,
167,18,50,2,0,
167,18,60,2,0,
167,18,71,1,5,
167,18,76,4,0,
167,18,81,1,1,2
167,18,92,4,0,
167,18,94,1,40,
167,18,94,4,0,
167,18,97,1,33,
167,18,101,1,15,
167,18,104,4,0,
167,18,132,1,1,3
167,18,141,4,0,
167,18,154,1,22,
167,18,156,4,0,
167,18,164,4,0,
167,18,168,4,0,
167,18,169,1,29,
167,18,182,4,0,
167,18,184,1,12,
167,18,188,4,0,
167,18,207,4,0,
167,18,213,4,0,
167,18,214,4,0,
167,18,216,4,0,
167,18,218,4,0,
167,18,224,2,0,
167,18,226,2,0,
167,18,228,2,0,
167,18,237,4,0,
167,18,241,4,0,
167,18,263,4,0,
167,18,324,2,0,
167,18,389,1,26,
167,18,390,2,0,
167,18,398,1,43,
167,18,398,4,0,
167,18,400,2,0,
167,18,404,4,0,
167,18,425,1,19,
167,18,440,1,47,
167,18,474,4,0,
167,18,476,2,0,
167,18,496,4,0,
167,18,527,2,0,
167,18,564,1,50,
167,18,590,4,0,
167,18,611,1,8,
167,18,611,4,0,
167,18,672,1,54,
167,18,679,2,0,
168,3,40,1,1,1
168,3,63,4,0,
168,3,76,4,0,
168,3,81,1,1,2
168,3,91,4,0,
168,3,92,4,0,
168,3,94,1,63,
168,3,94,4,0,
168,3,101,1,17,
168,3,103,1,53,
168,3,104,4,0,
168,3,132,1,1,4
168,3,132,1,11,
168,3,141,1,25,
168,3,148,4,0,
168,3,154,1,34,
168,3,156,4,0,
168,3,168,4,0,
168,3,169,1,43,
168,3,173,4,0,
168,3,174,4,0,
168,3,182,4,0,
168,3,184,1,1,3
168,3,184,1,6,
168,3,188,4,0,
168,3,202,4,0,
168,3,203,4,0,
168,3,207,4,0,
168,3,213,4,0,
168,3,214,4,0,
168,3,216,4,0,
168,3,218,4,0,
168,3,237,4,0,
168,3,241,4,0,
168,4,40,1,1,1
168,4,63,4,0,
168,4,76,4,0,
168,4,81,1,1,2
168,4,91,4,0,
168,4,92,4,0,
168,4,94,1,63,
168,4,94,4,0,
168,4,97,1,53,
168,4,101,1,17,
168,4,104,4,0,
168,4,132,1,1,4
168,4,132,1,11,
168,4,141,1,25,
168,4,148,4,0,
168,4,154,1,34,
168,4,156,4,0,
168,4,168,4,0,
168,4,169,1,43,
168,4,173,4,0,
168,4,174,4,0,
168,4,182,4,0,
168,4,184,1,1,3
168,4,184,1,6,
168,4,188,4,0,
168,4,202,4,0,
168,4,203,4,0,
168,4,207,4,0,
168,4,213,4,0,
168,4,214,4,0,
168,4,216,4,0,
168,4,218,4,0,
168,4,237,4,0,
168,4,241,4,0,
168,5,40,1,1,1
168,5,63,4,0,
168,5,76,4,0,
168,5,81,1,1,2
168,5,91,4,0,
168,5,92,4,0,
168,5,94,1,63,
168,5,94,4,0,
168,5,97,1,53,
168,5,101,1,17,
168,5,104,4,0,
168,5,132,1,1,4
168,5,132,1,11,
168,5,141,1,25,
168,5,148,4,0,
168,5,154,1,34,
168,5,156,4,0,
168,5,168,4,0,
168,5,169,1,43,
168,5,182,4,0,
168,5,184,1,1,3
168,5,184,1,6,
168,5,188,4,0,
168,5,202,4,0,
168,5,213,4,0,
168,5,216,4,0,
168,5,218,4,0,
168,5,237,4,0,
168,5,241,4,0,
168,5,263,4,0,
168,5,290,4,0,
168,6,34,3,0,
168,6,38,3,0,
168,6,40,1,1,1
168,6,63,4,0,
168,6,76,4,0,
168,6,81,1,1,2
168,6,91,4,0,
168,6,92,4,0,
168,6,94,1,63,
168,6,94,4,0,
168,6,97,1,53,
168,6,101,1,17,
168,6,102,3,0,
168,6,104,4,0,
168,6,132,1,1,4
168,6,132,1,11,
168,6,141,1,25,
168,6,148,4,0,
168,6,154,1,34,
168,6,156,4,0,
168,6,164,3,0,
168,6,168,4,0,
168,6,169,1,43,
168,6,173,3,0,
168,6,182,4,0,
168,6,184,1,1,3
168,6,184,1,6,
168,6,188,4,0,
168,6,202,4,0,
168,6,203,3,0,
168,6,207,3,0,
168,6,213,4,0,
168,6,214,3,0,
168,6,216,4,0,
168,6,218,4,0,
168,6,237,4,0,
168,6,241,4,0,
168,6,263,4,0,
168,6,290,4,0,
168,7,34,3,0,
168,7,38,3,0,
168,7,40,1,1,1
168,7,63,4,0,
168,7,76,4,0,
168,7,81,1,1,2
168,7,91,4,0,
168,7,92,4,0,
168,7,94,1,63,
168,7,94,4,0,
168,7,97,1,53,
168,7,101,1,17,
168,7,102,3,0,
168,7,104,4,0,
168,7,132,1,1,4
168,7,132,1,11,
168,7,141,1,25,
168,7,148,4,0,
168,7,154,1,34,
168,7,156,4,0,
168,7,164,3,0,
168,7,168,4,0,
168,7,169,1,43,
168,7,182,4,0,
168,7,184,1,1,3
168,7,184,1,6,
168,7,188,4,0,
168,7,202,4,0,
168,7,213,4,0,
168,7,216,4,0,
168,7,218,4,0,
168,7,237,4,0,
168,7,241,4,0,
168,7,263,4,0,
168,7,290,4,0,
168,8,40,1,1,2
168,8,42,1,41,
168,8,63,4,0,
168,8,76,4,0,
168,8,81,1,1,3
168,8,91,4,0,
168,8,92,4,0,
168,8,94,1,46,
168,8,94,4,0,
168,8,97,1,37,
168,8,101,1,15,
168,8,104,4,0,
168,8,132,1,1,5
168,8,132,1,8,
168,8,141,1,12,
168,8,148,4,0,
168,8,154,1,23,
168,8,156,4,0,
168,8,164,4,0,
168,8,168,4,0,
168,8,169,1,32,
168,8,182,4,0,
168,8,184,1,1,4
168,8,184,1,5,
168,8,188,4,0,
168,8,202,4,0,
168,8,203,4,0,
168,8,207,4,0,
168,8,213,4,0,
168,8,214,4,0,
168,8,216,4,0,
168,8,218,4,0,
168,8,237,4,0,
168,8,241,4,0,
168,8,263,4,0,
168,8,290,4,0,
168,8,363,4,0,
168,8,389,1,28,
168,8,398,1,50,
168,8,398,4,0,
168,8,416,4,0,
168,8,425,1,19,
168,8,445,4,0,
168,8,450,1,1,1
168,9,40,1,1,2
168,9,42,1,41,
168,9,63,4,0,
168,9,76,4,0,
168,9,81,1,1,3
168,9,91,4,0,
168,9,92,4,0,
168,9,94,1,46,
168,9,94,4,0,
168,9,97,1,37,
168,9,101,1,15,
168,9,104,4,0,
168,9,132,1,1,5
168,9,132,1,8,
168,9,141,1,12,
168,9,148,4,0,
168,9,154,1,23,
168,9,156,4,0,
168,9,164,4,0,
168,9,168,4,0,
168,9,169,1,32,
168,9,182,4,0,
168,9,184,1,1,4
168,9,184,1,5,
168,9,188,4,0,
168,9,202,4,0,
168,9,203,4,0,
168,9,207,4,0,
168,9,213,4,0,
168,9,214,4,0,
168,9,216,4,0,
168,9,218,4,0,
168,9,237,4,0,
168,9,241,4,0,
168,9,263,4,0,
168,9,290,4,0,
168,9,324,3,0,
168,9,340,3,0,
168,9,363,4,0,
168,9,389,1,28,
168,9,389,3,0,
168,9,398,1,50,
168,9,398,4,0,
168,9,416,4,0,
168,9,425,1,19,
168,9,445,4,0,
168,9,450,1,1,1
168,10,40,1,1,2
168,10,42,1,41,
168,10,63,4,0,
168,10,76,4,0,
168,10,81,1,1,3
168,10,81,3,0,
168,10,91,4,0,
168,10,92,4,0,
168,10,94,1,46,
168,10,94,4,0,
168,10,97,1,37,
168,10,101,1,15,
168,10,104,4,0,
168,10,132,1,1,5
168,10,132,1,8,
168,10,141,1,12,
168,10,148,4,0,
168,10,154,1,23,
168,10,156,4,0,
168,10,164,4,0,
168,10,168,4,0,
168,10,169,1,32,
168,10,182,4,0,
168,10,184,1,1,4
168,10,184,1,5,
168,10,188,4,0,
168,10,202,4,0,
168,10,203,4,0,
168,10,207,4,0,
168,10,213,4,0,
168,10,214,4,0,
168,10,216,4,0,
168,10,218,4,0,
168,10,237,4,0,
168,10,241,4,0,
168,10,263,4,0,
168,10,290,4,0,
168,10,324,3,0,
168,10,340,3,0,
168,10,363,4,0,
168,10,389,1,28,
168,10,389,3,0,
168,10,398,1,50,
168,10,398,4,0,
168,10,416,4,0,
168,10,425,1,19,
168,10,445,4,0,
168,10,450,1,1,1
168,10,450,3,0,
168,11,40,1,1,2
168,11,42,1,41,
168,11,63,4,0,
168,11,76,4,0,
168,11,81,1,1,3
168,11,91,4,0,
168,11,92,4,0,
168,11,94,1,46,
168,11,94,4,0,
168,11,97,1,37,
168,11,101,1,15,
168,11,104,4,0,
168,11,132,1,1,5
168,11,132,1,8,
168,11,141,1,12,
168,11,148,4,0,
168,11,154,1,23,
168,11,156,4,0,
168,11,164,4,0,
168,11,168,4,0,
168,11,169,1,32,
168,11,182,4,0,
168,11,184,1,1,4
168,11,184,1,5,
168,11,188,4,0,
168,11,207,4,0,
168,11,213,4,0,
168,11,216,4,0,
168,11,218,4,0,
168,11,237,4,0,
168,11,241,4,0,
168,11,263,4,0,
168,11,389,1,28,
168,11,398,1,50,
168,11,398,4,0,
168,11,416,4,0,
168,11,425,1,19,
168,11,440,1,55,
168,11,450,1,1,1
168,11,468,4,0,
168,11,474,4,0,
168,11,496,4,0,
168,11,522,4,0,
168,12,40,1,1,1
168,12,63,4,0,
168,12,76,4,0,
168,12,81,1,1,2
168,12,91,4,0,
168,12,92,4,0,
168,12,94,1,63,
168,12,94,4,0,
168,12,97,1,53,
168,12,101,1,17,
168,12,104,4,0,
168,12,132,1,1,4
168,12,132,1,11,
168,12,141,1,25,
168,12,148,4,0,
168,12,154,1,34,
168,12,156,4,0,
168,12,168,4,0,
168,12,169,1,43,
168,12,182,4,0,
168,12,184,1,1,3
168,12,184,1,6,
168,12,188,4,0,
168,12,202,4,0,
168,12,213,4,0,
168,12,216,4,0,
168,12,218,4,0,
168,12,237,4,0,
168,12,241,4,0,
168,12,263,4,0,
168,12,290,4,0,
168,13,34,3,0,
168,13,38,3,0,
168,13,40,1,1,1
168,13,63,4,0,
168,13,76,4,0,
168,13,81,1,1,2
168,13,91,4,0,
168,13,92,4,0,
168,13,94,1,63,
168,13,94,4,0,
168,13,97,1,53,
168,13,101,1,17,
168,13,102,3,0,
168,13,104,4,0,
168,13,132,1,1,4
168,13,132,1,11,
168,13,141,1,25,
168,13,148,4,0,
168,13,154,1,34,
168,13,156,4,0,
168,13,164,3,0,
168,13,168,4,0,
168,13,169,1,43,
168,13,182,4,0,
168,13,184,1,1,3
168,13,184,1,6,
168,13,188,4,0,
168,13,202,4,0,
168,13,207,3,0,
168,13,213,4,0,
168,13,216,4,0,
168,13,218,4,0,
168,13,237,4,0,
168,13,241,4,0,
168,13,263,4,0,
168,13,290,4,0,
168,14,40,1,1,2
168,14,42,1,41,
168,14,63,4,0,
168,14,76,4,0,
168,14,81,1,1,3
168,14,91,4,0,
168,14,92,4,0,
168,14,94,1,46,
168,14,94,4,0,
168,14,97,1,37,
168,14,101,1,15,
168,14,104,4,0,
168,14,132,1,1,5
168,14,132,1,8,
168,14,141,1,12,
168,14,148,4,0,
168,14,154,1,23,
168,14,156,4,0,
168,14,164,4,0,
168,14,168,4,0,
168,14,169,1,32,
168,14,182,4,0,
168,14,184,1,1,4
168,14,184,1,5,
168,14,188,4,0,
168,14,202,3,0,
168,14,207,4,0,
168,14,213,4,0,
168,14,214,3,0,
168,14,216,4,0,
168,14,218,4,0,
168,14,237,4,0,
168,14,241,4,0,
168,14,263,4,0,
168,14,324,3,0,
168,14,340,3,0,
168,14,389,1,28,
168,14,398,1,50,
168,14,398,4,0,
168,14,404,4,0,
168,14,416,4,0,
168,14,425,1,19,
168,14,440,1,55,
168,14,450,1,1,1
168,14,450,3,0,
168,14,468,4,0,
168,14,474,4,0,
168,14,492,3,0,
168,14,496,4,0,
168,14,522,4,0,
168,14,527,3,0,
168,15,40,1,1,4
168,15,42,1,41,
168,15,63,4,0,
168,15,76,4,0,
168,15,81,1,1,5
168,15,91,4,0,
168,15,92,4,0,
168,15,94,1,46,
168,15,94,4,0,
168,15,97,1,37,
168,15,101,1,15,
168,15,104,4,0,
168,15,132,1,1,7
168,15,132,1,8,
168,15,141,1,12,
168,15,148,4,0,
168,15,154,1,23,
168,15,156,4,0,
168,15,164,4,0,
168,15,168,4,0,
168,15,169,1,32,
168,15,182,4,0,
168,15,184,1,1,6
168,15,184,1,5,
168,15,188,4,0,
168,15,207,4,0,
168,15,213,4,0,
168,15,214,4,0,
168,15,216,4,0,
168,15,218,4,0,
168,15,237,4,0,
168,15,241,4,0,
168,15,263,4,0,
168,15,389,1,28,
168,15,398,1,50,
168,15,398,4,0,
168,15,404,4,0,
168,15,416,4,0,
168,15,425,1,19,
168,15,440,1,55,
168,15,450,1,1,3
168,15,468,4,0,
168,15,474,4,0,
168,15,496,4,0,
168,15,522,4,0,
168,15,564,1,58,
168,15,565,1,1,2
168,15,590,4,0,
168,15,599,1,1,1
168,15,611,4,0,
168,16,40,1,1,4
168,16,42,1,41,1
168,16,63,4,0,
168,16,76,4,0,
168,16,81,1,1,5
168,16,91,4,0,
168,16,92,4,0,
168,16,94,1,46,1
168,16,94,4,0,
168,16,97,1,37,1
168,16,101,1,15,1
168,16,104,4,0,
168,16,132,1,1,7
168,16,132,1,8,1
168,16,141,1,12,1
168,16,148,4,0,
168,16,154,1,23,1
168,16,156,4,0,
168,16,164,4,0,
168,16,168,4,0,
168,16,169,1,32,1
168,16,173,3,0,
168,16,182,4,0,
168,16,184,1,1,6
168,16,184,1,5,1
168,16,188,4,0,
168,16,202,3,0,
168,16,207,4,0,
168,16,213,4,0,
168,16,214,4,0,
168,16,216,4,0,
168,16,218,4,0,
168,16,237,4,0,
168,16,241,4,0,
168,16,263,4,0,
168,16,290,4,0,
168,16,324,3,0,
168,16,340,3,0,
168,16,389,1,28,1
168,16,398,1,50,1
168,16,398,4,0,
168,16,404,4,0,
168,16,416,4,0,
168,16,425,1,19,1
168,16,440,1,55,1
168,16,450,1,1,3
168,16,450,3,0,
168,16,468,4,0,
168,16,474,4,0,
168,16,492,3,0,
168,16,496,4,0,
168,16,522,4,0,
168,16,527,3,0,
168,16,564,1,58,1
168,16,565,1,1,2
168,16,590,4,0,
168,16,599,1,1,1
168,16,611,4,0,
168,17,14,1,0,
168,17,14,1,1,1
168,17,14,4,0,
168,17,40,1,1,6
168,17,42,1,41,
168,17,63,4,0,
168,17,71,1,1,9
168,17,71,1,5,
168,17,76,4,0,
168,17,81,1,1,7
168,17,92,4,0,
168,17,94,1,46,
168,17,94,4,0,
168,17,97,1,37,
168,17,101,1,15,
168,17,104,4,0,
168,17,116,1,1,2
168,17,132,1,1,8
168,17,141,4,0,
168,17,154,1,23,
168,17,156,4,0,
168,17,164,4,0,
168,17,168,4,0,
168,17,169,1,32,
168,17,182,4,0,
168,17,184,1,12,
168,17,188,4,0,
168,17,207,4,0,
168,17,213,4,0,
168,17,214,4,0,
168,17,216,4,0,
168,17,218,4,0,
168,17,237,4,0,
168,17,241,4,0,
168,17,263,4,0,
168,17,389,1,28,
168,17,398,1,50,
168,17,398,4,0,
168,17,404,4,0,
168,17,416,4,0,
168,17,425,1,19,
168,17,440,1,55,
168,17,450,1,1,5
168,17,474,4,0,
168,17,496,4,0,
168,17,564,1,58,
168,17,565,1,1,4
168,17,590,4,0,
168,17,599,1,1,3
168,17,611,1,8,
168,17,611,4,0,
168,17,672,1,63,
168,17,684,4,0,
168,18,14,1,0,
168,18,14,1,1,1
168,18,14,4,0,
168,18,40,1,1,6
168,18,42,1,41,
168,18,63,4,0,
168,18,71,1,1,9
168,18,71,1,5,
168,18,76,4,0,
168,18,81,1,1,7
168,18,92,4,0,
168,18,94,1,46,
168,18,94,4,0,
168,18,97,1,37,
168,18,101,1,15,
168,18,104,4,0,
168,18,116,1,1,2
168,18,132,1,1,8
168,18,141,4,0,
168,18,154,1,23,
168,18,156,4,0,
168,18,164,4,0,
168,18,168,4,0,
168,18,169,1,32,
168,18,182,4,0,
168,18,184,1,12,
168,18,188,4,0,
168,18,207,4,0,
168,18,213,4,0,
168,18,214,4,0,
168,18,216,4,0,
168,18,218,4,0,
168,18,237,4,0,
168,18,241,4,0,
168,18,263,4,0,
168,18,389,1,28,
168,18,398,1,50,
168,18,398,4,0,
168,18,404,4,0,
168,18,416,4,0,
168,18,425,1,19,
168,18,440,1,55,
168,18,450,1,1,5
168,18,474,4,0,
168,18,496,4,0,
168,18,564,1,58,
168,18,565,1,1,4
168,18,590,4,0,
168,18,599,1,1,3
168,18,611,1,8,
168,18,611,4,0,
168,18,672,1,63,
168,18,684,4,0,
169,3,17,1,30,
169,3,19,4,0,
169,3,44,1,12,
169,3,48,1,1,3
169,3,48,1,6,
169,3,63,4,0,
169,3,92,4,0,
169,3,103,1,1,1
169,3,104,4,0,
169,3,109,1,19,
169,3,114,1,55,
169,3,129,4,0,
169,3,141,1,1,2
169,3,156,4,0,
169,3,168,4,0,
169,3,173,4,0,
169,3,174,4,0,
169,3,182,4,0,
169,3,197,4,0,
169,3,202,4,0,
169,3,203,4,0,
169,3,207,4,0,
169,3,211,4,0,
169,3,212,1,42,
169,3,213,4,0,
169,3,214,4,0,
169,3,216,4,0,
169,3,218,4,0,
169,3,237,4,0,
169,3,241,4,0,
169,4,17,1,30,
169,4,19,4,0,
169,4,44,1,12,
169,4,48,1,1,3
169,4,48,1,6,
169,4,63,4,0,
169,4,92,4,0,
169,4,103,1,1,1
169,4,104,4,0,
169,4,109,1,19,
169,4,114,1,55,
169,4,129,4,0,
169,4,141,1,1,2
169,4,156,4,0,
169,4,168,4,0,
169,4,173,4,0,
169,4,174,4,0,
169,4,182,4,0,
169,4,197,4,0,
169,4,202,4,0,
169,4,203,4,0,
169,4,207,4,0,
169,4,211,4,0,
169,4,212,1,42,
169,4,213,4,0,
169,4,214,4,0,
169,4,216,4,0,
169,4,218,4,0,
169,4,237,4,0,
169,4,241,4,0,
169,5,17,1,21,
169,5,19,4,0,
169,5,44,1,16,
169,5,48,1,1,3
169,5,48,1,6,
169,5,63,4,0,
169,5,92,4,0,
169,5,103,1,1,1
169,5,104,4,0,
169,5,109,1,28,
169,5,114,1,56,
169,5,141,1,1,2
169,5,156,4,0,
169,5,168,4,0,
169,5,182,4,0,
169,5,188,4,0,
169,5,202,4,0,
169,5,211,4,0,
169,5,212,1,42,
169,5,213,4,0,
169,5,216,4,0,
169,5,218,4,0,
169,5,237,4,0,
169,5,240,4,0,
169,5,241,4,0,
169,5,247,4,0,
169,5,259,4,0,
169,5,263,4,0,
169,5,269,4,0,
169,5,289,4,0,
169,5,290,4,0,
169,5,305,1,49,
169,5,310,1,1,4
169,5,310,1,11,
169,5,314,1,35,
169,5,332,4,0,
169,6,17,1,21,
169,6,19,4,0,
169,6,38,3,0,
169,6,44,1,16,
169,6,48,1,1,3
169,6,48,1,6,
169,6,63,4,0,
169,6,92,4,0,
169,6,102,3,0,
169,6,103,1,1,1
169,6,104,4,0,
169,6,109,1,28,
169,6,114,1,56,
169,6,129,3,0,
169,6,141,1,1,2
169,6,156,4,0,
169,6,164,3,0,
169,6,168,4,0,
169,6,173,3,0,
169,6,182,4,0,
169,6,188,4,0,
169,6,202,4,0,
169,6,203,3,0,
169,6,207,3,0,
169,6,211,4,0,
169,6,212,1,42,
169,6,213,4,0,
169,6,214,3,0,
169,6,216,4,0,
169,6,218,4,0,
169,6,237,4,0,
169,6,240,4,0,
169,6,241,4,0,
169,6,247,4,0,
169,6,259,4,0,
169,6,263,4,0,
169,6,269,4,0,
169,6,289,4,0,
169,6,290,4,0,
169,6,305,1,49,
169,6,310,1,1,4
169,6,310,1,11,
169,6,314,1,35,
169,6,332,4,0,
169,7,17,1,21,
169,7,19,4,0,
169,7,38,3,0,
169,7,44,1,16,
169,7,48,1,1,4
169,7,48,1,11,
169,7,63,4,0,
169,7,92,4,0,
169,7,102,3,0,
169,7,103,1,1,1
169,7,104,4,0,
169,7,109,1,28,
169,7,114,1,56,
169,7,141,1,1,2
169,7,156,4,0,
169,7,164,3,0,
169,7,168,4,0,
169,7,182,4,0,
169,7,188,4,0,
169,7,202,4,0,
169,7,211,4,0,
169,7,212,1,42,
169,7,213,4,0,
169,7,216,4,0,
169,7,218,4,0,
169,7,237,4,0,
169,7,240,4,0,
169,7,241,4,0,
169,7,247,4,0,
169,7,259,4,0,
169,7,263,4,0,
169,7,269,4,0,
169,7,289,4,0,
169,7,290,4,0,
169,7,305,1,49,
169,7,310,1,1,3
169,7,310,1,6,
169,7,314,1,35,
169,7,332,4,0,
169,8,17,1,17,
169,8,19,4,0,
169,8,44,1,13,
169,8,48,1,1,4
169,8,48,1,5,
169,8,63,4,0,
169,8,92,4,0,
169,8,103,1,1,2
169,8,104,4,0,
169,8,109,1,21,
169,8,114,1,45,
169,8,141,1,1,3
169,8,156,4,0,
169,8,164,4,0,
169,8,168,4,0,
169,8,182,4,0,
169,8,188,4,0,
169,8,202,4,0,
169,8,203,4,0,
169,8,207,4,0,
169,8,211,4,0,
169,8,212,1,33,
169,8,213,4,0,
169,8,214,4,0,
169,8,216,4,0,
169,8,218,4,0,
169,8,237,4,0,
169,8,240,4,0,
169,8,241,4,0,
169,8,247,4,0,
169,8,259,4,0,
169,8,263,4,0,
169,8,269,4,0,
169,8,289,4,0,
169,8,290,4,0,
169,8,305,1,39,
169,8,310,1,1,5
169,8,310,1,9,
169,8,314,1,27,
169,8,332,4,0,
169,8,355,4,0,
169,8,363,4,0,
169,8,365,4,0,
169,8,369,4,0,
169,8,371,4,0,
169,8,399,4,0,
169,8,403,1,51,
169,8,404,4,0,
169,8,416,4,0,
169,8,432,4,0,
169,8,440,1,1,1
169,8,445,4,0,
169,9,17,1,17,
169,9,19,4,0,
169,9,44,1,13,
169,9,48,1,1,4
169,9,48,1,5,
169,9,63,4,0,
169,9,92,4,0,
169,9,103,1,1,2
169,9,104,4,0,
169,9,109,1,21,
169,9,114,1,45,
169,9,129,3,0,
169,9,141,1,1,3
169,9,156,4,0,
169,9,164,4,0,
169,9,168,4,0,
169,9,173,3,0,
169,9,182,4,0,
169,9,188,4,0,
169,9,202,4,0,
169,9,203,4,0,
169,9,207,4,0,
169,9,211,4,0,
169,9,212,1,33,
169,9,213,4,0,
169,9,214,4,0,
169,9,216,4,0,
169,9,218,4,0,
169,9,237,4,0,
169,9,239,3,0,
169,9,240,4,0,
169,9,241,4,0,
169,9,247,4,0,
169,9,253,3,0,
169,9,257,3,0,
169,9,259,4,0,
169,9,263,4,0,
169,9,269,4,0,
169,9,289,4,0,
169,9,290,4,0,
169,9,305,1,39,
169,9,310,1,1,5
169,9,310,1,9,
169,9,314,1,27,
169,9,314,3,0,
169,9,332,4,0,
169,9,355,4,0,
169,9,363,4,0,
169,9,365,4,0,
169,9,369,4,0,
169,9,371,4,0,
169,9,399,4,0,
169,9,403,1,51,
169,9,404,4,0,
169,9,416,4,0,
169,9,428,3,0,
169,9,432,4,0,
169,9,440,1,1,1
169,9,445,4,0,
169,9,466,3,0,
169,10,17,1,17,
169,10,19,4,0,
169,10,44,1,13,
169,10,48,1,1,4
169,10,48,1,5,
169,10,63,4,0,
169,10,92,4,0,
169,10,103,1,1,2
169,10,104,4,0,
169,10,109,1,21,
169,10,114,1,45,
169,10,129,3,0,
169,10,141,1,1,3
169,10,143,3,0,
169,10,156,4,0,
169,10,162,3,0,
169,10,164,4,0,
169,10,168,4,0,
169,10,173,3,0,
169,10,182,4,0,
169,10,188,4,0,
169,10,202,4,0,
169,10,203,4,0,
169,10,207,4,0,
169,10,211,4,0,
169,10,212,1,33,
169,10,213,4,0,
169,10,214,4,0,
169,10,216,4,0,
169,10,218,4,0,
169,10,237,4,0,
169,10,239,3,0,
169,10,240,4,0,
169,10,241,4,0,
169,10,247,4,0,
169,10,253,3,0,
169,10,257,3,0,
169,10,259,4,0,
169,10,263,4,0,
169,10,269,4,0,
169,10,289,4,0,
169,10,290,4,0,
169,10,305,1,39,
169,10,310,1,1,5
169,10,310,1,9,
169,10,314,1,27,
169,10,314,3,0,
169,10,332,4,0,
169,10,355,4,0,
169,10,363,4,0,
169,10,365,4,0,
169,10,366,3,0,
169,10,369,4,0,
169,10,371,4,0,
169,10,399,4,0,
169,10,403,1,51,
169,10,404,4,0,
169,10,416,4,0,
169,10,428,3,0,
169,10,440,1,1,1
169,10,445,4,0,
169,10,466,3,0,
169,11,17,1,17,
169,11,19,4,0,
169,11,44,1,13,
169,11,48,1,1,4
169,11,48,1,5,
169,11,63,4,0,
169,11,92,4,0,
169,11,103,1,1,2
169,11,104,4,0,
169,11,109,1,21,
169,11,114,1,51,
169,11,141,1,1,3
169,11,156,4,0,
169,11,164,4,0,
169,11,168,4,0,
169,11,182,4,0,
169,11,188,4,0,
169,11,207,4,0,
169,11,212,1,33,
169,11,213,4,0,
169,11,216,4,0,
169,11,218,4,0,
169,11,237,4,0,
169,11,240,4,0,
169,11,241,4,0,
169,11,247,4,0,
169,11,259,4,0,
169,11,263,4,0,
169,11,269,4,0,
169,11,305,1,45,
169,11,310,1,1,5
169,11,310,1,9,
169,11,314,1,27,
169,11,332,4,0,
169,11,365,4,0,
169,11,369,4,0,
169,11,371,4,0,
169,11,403,1,57,
169,11,404,4,0,
169,11,416,4,0,
169,11,440,1,1,1
169,11,474,4,0,
169,11,496,4,0,
169,11,512,1,39,
169,11,512,4,0,
169,12,17,1,21,
169,12,19,4,0,
169,12,44,1,16,
169,12,48,1,1,3
169,12,48,1,6,
169,12,63,4,0,
169,12,92,4,0,
169,12,103,1,1,1
169,12,104,4,0,
169,12,109,1,28,
169,12,114,1,56,
169,12,141,1,1,2
169,12,156,4,0,
169,12,168,4,0,
169,12,182,4,0,
169,12,188,4,0,
169,12,202,4,0,
169,12,211,4,0,
169,12,212,1,42,
169,12,213,4,0,
169,12,216,4,0,
169,12,218,4,0,
169,12,237,4,0,
169,12,240,4,0,
169,12,241,4,0,
169,12,247,4,0,
169,12,259,4,0,
169,12,263,4,0,
169,12,269,4,0,
169,12,289,4,0,
169,12,290,4,0,
169,12,305,1,49,
169,12,310,1,1,4
169,12,310,1,11,
169,12,314,1,35,
169,12,332,4,0,
169,13,17,1,21,
169,13,19,4,0,
169,13,38,3,0,
169,13,44,1,16,
169,13,48,1,1,3
169,13,48,1,6,
169,13,63,4,0,
169,13,92,4,0,
169,13,102,3,0,
169,13,103,1,1,1
169,13,104,4,0,
169,13,109,1,28,
169,13,114,1,56,
169,13,141,1,1,2
169,13,156,4,0,
169,13,164,3,0,
169,13,168,4,0,
169,13,182,4,0,
169,13,188,4,0,
169,13,202,4,0,
169,13,207,3,0,
169,13,211,4,0,
169,13,212,1,42,
169,13,213,4,0,
169,13,216,4,0,
169,13,218,4,0,
169,13,237,4,0,
169,13,240,4,0,
169,13,241,4,0,
169,13,247,4,0,
169,13,259,4,0,
169,13,263,4,0,
169,13,269,4,0,
169,13,289,4,0,
169,13,290,4,0,
169,13,305,1,49,
169,13,310,1,1,4
169,13,310,1,11,
169,13,314,1,35,
169,13,332,4,0,
169,14,17,1,15,
169,14,19,4,0,
169,14,44,1,12,
169,14,48,1,1,4
169,14,48,1,4,
169,14,63,4,0,
169,14,92,4,0,
169,14,103,1,1,2
169,14,104,4,0,
169,14,109,1,19,
169,14,114,1,47,
169,14,129,1,24,
169,14,141,1,1,3
169,14,143,3,0,
169,14,156,4,0,
169,14,162,3,0,
169,14,164,4,0,
169,14,168,4,0,
169,14,173,3,0,
169,14,182,4,0,
169,14,188,4,0,
169,14,202,3,0,
169,14,207,4,0,
169,14,212,1,38,
169,14,213,4,0,
169,14,214,3,0,
169,14,216,4,0,
169,14,218,4,0,
169,14,237,4,0,
169,14,240,4,0,
169,14,241,4,0,
169,14,247,4,0,
169,14,253,3,0,
169,14,257,3,0,
169,14,259,4,0,
169,14,263,4,0,
169,14,269,4,0,
169,14,289,3,0,
169,14,305,1,42,
169,14,310,1,1,5
169,14,310,1,8,
169,14,314,1,28,
169,14,332,4,0,
169,14,355,3,0,
169,14,365,4,0,
169,14,366,3,0,
169,14,369,4,0,
169,14,371,4,0,
169,14,399,3,0,
169,14,403,1,52,
169,14,404,4,0,
169,14,416,4,0,
169,14,428,3,0,
169,14,440,1,1,1
169,14,474,4,0,
169,14,496,4,0,
169,14,512,1,33,
169,14,512,4,0,
169,15,17,1,15,
169,15,19,4,0,
169,15,44,1,12,
169,15,48,1,1,4
169,15,48,1,4,
169,15,63,4,0,
169,15,92,4,0,
169,15,103,1,1,2
169,15,104,4,0,
169,15,109,1,19,
169,15,114,1,47,
169,15,129,1,24,
169,15,141,1,1,3
169,15,156,4,0,
169,15,164,4,0,
169,15,168,4,0,
169,15,182,4,0,
169,15,188,4,0,
169,15,207,4,0,
169,15,211,4,0,
169,15,212,1,38,
169,15,213,4,0,
169,15,214,4,0,
169,15,216,4,0,
169,15,218,4,0,
169,15,237,4,0,
169,15,240,4,0,
169,15,241,4,0,
169,15,247,4,0,
169,15,259,4,0,
169,15,263,4,0,
169,15,269,4,0,
169,15,305,1,42,
169,15,310,1,1,5
169,15,310,1,8,
169,15,314,1,28,
169,15,332,4,0,
169,15,355,4,0,
169,15,369,4,0,
169,15,371,4,0,
169,15,399,4,0,
169,15,403,1,52,
169,15,404,4,0,
169,15,416,4,0,
169,15,440,1,1,1
169,15,474,4,0,
169,15,496,4,0,
169,15,512,1,33,
169,15,512,4,0,
169,15,590,4,0,
169,16,17,1,13,1
169,16,19,4,0,
169,16,44,1,1,6
169,16,44,1,11,1
169,16,48,1,1,4
169,16,48,1,5,1
169,16,63,4,0,
169,16,92,4,0,
169,16,103,1,1,2
169,16,104,4,0,
169,16,109,1,17,1
169,16,114,1,40,1
169,16,129,1,24,1
169,16,141,1,1,3
169,16,143,3,0,
169,16,156,4,0,
169,16,162,3,0,
169,16,164,4,0,
169,16,168,4,0,
169,16,173,3,0,
169,16,182,4,0,
169,16,188,4,0,
169,16,202,3,0,
169,16,207,4,0,
169,16,211,4,0,
169,16,212,1,32,1
169,16,213,4,0,
169,16,214,4,0,
169,16,216,4,0,
169,16,218,4,0,
169,16,237,4,0,
169,16,240,4,0,
169,16,241,4,0,
169,16,247,4,0,
169,16,253,3,0,
169,16,257,3,0,
169,16,259,4,0,
169,16,263,4,0,
169,16,269,4,0,
169,16,289,3,0,
169,16,290,4,0,
169,16,305,1,27,1
169,16,310,1,1,5
169,16,310,1,7,1
169,16,314,1,19,1
169,16,332,4,0,
169,16,355,4,0,
169,16,366,3,0,
169,16,369,4,0,
169,16,371,4,0,
169,16,399,4,0,
169,16,403,1,48,1
169,16,404,4,0,
169,16,416,4,0,
169,16,428,3,0,
169,16,440,1,1,1
169,16,474,1,43,1
169,16,474,4,0,
169,16,496,4,0,
169,16,501,1,51,1
169,16,512,1,35,1
169,16,512,4,0,
169,16,590,4,0,
169,17,17,1,13,
169,17,19,4,0,
169,17,44,1,1,6
169,17,44,1,11,
169,17,48,1,1,4
169,17,48,1,5,
169,17,63,4,0,
169,17,71,1,1,3
169,17,92,4,0,
169,17,103,1,1,2
169,17,104,4,0,
169,17,109,1,17,
169,17,114,1,40,
169,17,129,1,24,
169,17,141,1,35,
169,17,141,4,0,
169,17,156,4,0,
169,17,164,4,0,
169,17,168,4,0,
169,17,182,4,0,
169,17,188,4,0,
169,17,207,4,0,
169,17,211,4,0,
169,17,212,1,32,
169,17,213,4,0,
169,17,214,4,0,
169,17,216,4,0,
169,17,218,4,0,
169,17,237,4,0,
169,17,240,4,0,
169,17,241,4,0,
169,17,247,4,0,
169,17,259,4,0,
169,17,263,4,0,
169,17,269,4,0,
169,17,305,1,27,
169,17,310,1,1,5
169,17,310,1,7,
169,17,314,1,19,
169,17,332,4,0,
169,17,355,4,0,
169,17,369,4,0,
169,17,371,4,0,
169,17,399,4,0,
169,17,403,1,48,
169,17,404,4,0,
169,17,416,4,0,
169,17,440,1,0,
169,17,440,1,1,1
169,17,474,1,43,
169,17,474,4,0,
169,17,496,4,0,
169,17,501,1,51,
169,17,512,4,0,
169,17,590,4,0,
169,18,17,1,13,
169,18,19,4,0,
169,18,44,1,1,6
169,18,44,1,11,
169,18,48,1,1,4
169,18,48,1,5,
169,18,63,4,0,
169,18,71,1,1,3
169,18,92,4,0,
169,18,103,1,1,2
169,18,104,4,0,
169,18,109,1,17,
169,18,114,1,40,
169,18,129,1,24,
169,18,141,1,35,
169,18,141,4,0,
169,18,156,4,0,
169,18,164,4,0,
169,18,168,4,0,
169,18,182,4,0,
169,18,188,4,0,
169,18,207,4,0,
169,18,211,4,0,
169,18,212,1,32,
169,18,213,4,0,
169,18,214,4,0,
169,18,216,4,0,
169,18,218,4,0,
169,18,237,4,0,
169,18,240,4,0,
169,18,241,4,0,
169,18,247,4,0,
169,18,259,4,0,
169,18,263,4,0,
169,18,269,4,0,
169,18,305,1,27,
169,18,310,1,1,5
169,18,310,1,7,
169,18,314,1,19,
169,18,332,4,0,
169,18,355,4,0,
169,18,369,4,0,
169,18,371,4,0,
169,18,399,4,0,
169,18,403,1,48,
169,18,404,4,0,
169,18,416,4,0,
169,18,440,1,0,
169,18,440,1,1,1
169,18,474,1,43,
169,18,474,4,0,
169,18,496,4,0,
169,18,501,1,51,
169,18,512,4,0,
169,18,590,4,0,
170,3,36,1,37,
170,3,48,1,5,
170,3,48,2,0,
170,3,55,1,17,
170,3,56,1,41,
170,3,57,4,0,
170,3,86,1,1,2
170,3,87,4,0,
170,3,92,4,0,
170,3,103,2,0,
170,3,104,4,0,
170,3,109,1,29,
170,3,127,4,0,
170,3,145,1,1,1
170,3,148,4,0,
170,3,156,4,0,
170,3,173,4,0,
170,3,174,4,0,
170,3,175,1,13,
170,3,175,2,0,
170,3,182,4,0,
170,3,192,4,0,
170,3,203,4,0,
170,3,207,4,0,
170,3,209,1,25,
170,3,213,4,0,
170,3,214,4,0,
170,3,216,4,0,
170,3,218,4,0,
170,3,237,4,0,
170,3,240,4,0,
170,3,250,4,0,
170,4,36,1,37,
170,4,48,1,5,
170,4,48,2,0,
170,4,55,1,17,
170,4,56,1,41,
170,4,57,4,0,
170,4,58,3,0,
170,4,85,3,0,
170,4,86,1,1,2
170,4,87,4,0,
170,4,92,4,0,
170,4,103,2,0,
170,4,104,4,0,
170,4,109,1,29,
170,4,127,4,0,
170,4,145,1,1,1
170,4,148,4,0,
170,4,156,4,0,
170,4,173,4,0,
170,4,174,4,0,
170,4,175,1,13,
170,4,175,2,0,
170,4,182,4,0,
170,4,192,4,0,
170,4,203,4,0,
170,4,207,4,0,
170,4,209,1,25,
170,4,213,4,0,
170,4,214,4,0,
170,4,216,4,0,
170,4,218,4,0,
170,4,237,4,0,
170,4,240,4,0,
170,4,250,4,0,
170,5,36,1,37,
170,5,48,1,5,
170,5,55,1,17,
170,5,56,1,41,
170,5,57,4,0,
170,5,58,4,0,
170,5,59,4,0,
170,5,85,4,0,
170,5,86,1,1,2
170,5,87,4,0,
170,5,92,4,0,
170,5,103,2,0,
170,5,104,4,0,
170,5,109,1,29,
170,5,127,4,0,
170,5,133,2,0,
170,5,145,1,1,1
170,5,148,4,0,
170,5,156,4,0,
170,5,175,1,13,
170,5,175,2,0,
170,5,182,4,0,
170,5,209,1,25,
170,5,213,4,0,
170,5,216,4,0,
170,5,218,4,0,
170,5,237,4,0,
170,5,240,4,0,
170,5,258,4,0,
170,5,263,4,0,
170,5,268,1,49,
170,5,290,4,0,
170,5,291,4,0,
170,5,351,4,0,
170,5,352,4,0,
170,6,36,1,37,
170,6,38,3,0,
170,6,48,1,5,
170,6,55,1,17,
170,6,56,1,41,
170,6,57,4,0,
170,6,58,4,0,
170,6,59,4,0,
170,6,85,4,0,
170,6,86,1,1,2
170,6,86,3,0,
170,6,87,4,0,
170,6,92,4,0,
170,6,102,3,0,
170,6,103,2,0,
170,6,104,4,0,
170,6,109,1,29,
170,6,127,4,0,
170,6,133,2,0,
170,6,145,1,1,1
170,6,148,4,0,
170,6,156,4,0,
170,6,164,3,0,
170,6,173,3,0,
170,6,175,1,13,
170,6,175,2,0,
170,6,182,4,0,
170,6,203,3,0,
170,6,207,3,0,
170,6,209,1,25,
170,6,213,4,0,
170,6,214,3,0,
170,6,216,4,0,
170,6,218,4,0,
170,6,237,4,0,
170,6,240,4,0,
170,6,258,4,0,
170,6,263,4,0,
170,6,268,1,49,
170,6,290,4,0,
170,6,291,4,0,
170,6,351,4,0,
170,6,352,4,0,
170,7,36,1,37,
170,7,38,3,0,
170,7,48,1,5,
170,7,55,1,17,
170,7,56,1,41,
170,7,57,4,0,
170,7,58,4,0,
170,7,59,4,0,
170,7,85,4,0,
170,7,86,1,1,2
170,7,86,3,0,
170,7,87,4,0,
170,7,92,4,0,
170,7,102,3,0,
170,7,103,2,0,
170,7,104,4,0,
170,7,109,1,29,
170,7,127,4,0,
170,7,133,2,0,
170,7,145,1,1,1
170,7,148,4,0,
170,7,156,4,0,
170,7,164,3,0,
170,7,175,1,13,
170,7,175,2,0,
170,7,182,4,0,
170,7,209,1,25,
170,7,213,4,0,
170,7,216,4,0,
170,7,218,4,0,
170,7,237,4,0,
170,7,240,4,0,
170,7,258,4,0,
170,7,263,4,0,
170,7,268,1,49,
170,7,290,4,0,
170,7,291,4,0,
170,7,351,4,0,
170,7,352,4,0,
170,8,36,1,23,
170,8,48,1,1,2
170,8,54,2,0,
170,8,55,1,12,
170,8,56,1,42,
170,8,57,4,0,
170,8,58,4,0,
170,8,59,4,0,
170,8,60,2,0,
170,8,61,1,28,
170,8,85,4,0,
170,8,86,1,6,
170,8,86,4,0,
170,8,87,4,0,
170,8,92,4,0,
170,8,97,2,0,
170,8,103,2,0,
170,8,104,4,0,
170,8,109,1,17,
170,8,127,4,0,
170,8,133,2,0,
170,8,145,1,1,1
170,8,148,4,0,
170,8,156,4,0,
170,8,164,4,0,
170,8,175,1,9,
170,8,175,2,0,
170,8,182,4,0,
170,8,203,4,0,
170,8,207,4,0,
170,8,209,1,20,
170,8,213,4,0,
170,8,214,4,0,
170,8,216,4,0,
170,8,218,4,0,
170,8,237,4,0,
170,8,240,4,0,
170,8,250,2,0,
170,8,258,4,0,
170,8,263,4,0,
170,8,268,1,45,
170,8,290,4,0,
170,8,324,1,31,
170,8,351,4,0,
170,8,352,4,0,
170,8,362,4,0,
170,8,363,4,0,
170,8,392,1,39,
170,8,435,1,34,
170,8,445,4,0,
170,8,451,4,0,
170,9,36,1,23,
170,9,48,1,1,2
170,9,54,2,0,
170,9,55,1,12,
170,9,56,1,42,
170,9,57,4,0,
170,9,58,4,0,
170,9,59,4,0,
170,9,60,2,0,
170,9,61,1,28,
170,9,85,4,0,
170,9,86,1,6,
170,9,86,4,0,
170,9,87,4,0,
170,9,92,4,0,
170,9,97,2,0,
170,9,103,2,0,
170,9,104,4,0,
170,9,109,1,17,
170,9,127,4,0,
170,9,133,2,0,
170,9,145,1,1,1
170,9,148,4,0,
170,9,156,4,0,
170,9,164,4,0,
170,9,173,3,0,
170,9,175,1,9,
170,9,175,2,0,
170,9,182,4,0,
170,9,196,3,0,
170,9,203,4,0,
170,9,207,4,0,
170,9,209,1,20,
170,9,213,4,0,
170,9,214,4,0,
170,9,216,4,0,
170,9,218,4,0,
170,9,237,4,0,
170,9,240,4,0,
170,9,250,2,0,
170,9,258,4,0,
170,9,263,4,0,
170,9,268,1,45,
170,9,290,4,0,
170,9,291,3,0,
170,9,324,1,31,
170,9,324,3,0,
170,9,340,3,0,
170,9,351,4,0,
170,9,352,4,0,
170,9,362,4,0,
170,9,363,4,0,
170,9,389,3,0,
170,9,392,1,39,
170,9,435,1,34,
170,9,445,4,0,
170,9,451,4,0,
170,10,36,1,23,
170,10,48,1,1,2
170,10,54,2,0,
170,10,55,1,12,
170,10,56,1,42,
170,10,57,4,0,
170,10,58,4,0,
170,10,59,4,0,
170,10,60,2,0,
170,10,61,1,28,
170,10,85,4,0,
170,10,86,1,6,
170,10,86,4,0,
170,10,87,4,0,
170,10,92,4,0,
170,10,97,2,0,
170,10,103,2,0,
170,10,104,4,0,
170,10,109,1,17,
170,10,127,4,0,
170,10,133,2,0,
170,10,145,1,1,1
170,10,148,4,0,
170,10,156,4,0,
170,10,164,4,0,
170,10,173,3,0,
170,10,175,1,9,
170,10,175,2,0,
170,10,182,4,0,
170,10,196,3,0,
170,10,203,4,0,
170,10,207,4,0,
170,10,209,1,20,
170,10,213,4,0,
170,10,214,4,0,
170,10,215,3,0,
170,10,216,4,0,
170,10,218,4,0,
170,10,237,4,0,
170,10,240,4,0,
170,10,250,2,0,
170,10,250,4,0,
170,10,258,4,0,
170,10,263,4,0,
170,10,268,1,45,
170,10,290,4,0,
170,10,291,3,0,
170,10,324,1,31,
170,10,324,3,0,
170,10,340,3,0,
170,10,351,4,0,
170,10,352,4,0,
170,10,362,4,0,
170,10,363,4,0,
170,10,389,3,0,
170,10,392,1,39,
170,10,435,1,34,
170,10,445,4,0,
170,10,451,4,0,
170,11,36,1,23,
170,11,48,1,1,1
170,11,54,2,0,
170,11,55,1,17,
170,11,56,1,45,
170,11,57,4,0,
170,11,58,4,0,
170,11,59,4,0,
170,11,60,2,0,
170,11,61,1,31,
170,11,85,4,0,
170,11,86,1,6,
170,11,86,4,0,
170,11,87,4,0,
170,11,92,4,0,
170,11,97,2,0,
170,11,103,2,0,
170,11,104,4,0,
170,11,109,1,12,
170,11,127,4,0,
170,11,133,2,0,
170,11,145,1,1,2
170,11,148,4,0,
170,11,156,4,0,
170,11,164,4,0,
170,11,175,1,9,
170,11,175,2,0,
170,11,182,4,0,
170,11,207,4,0,
170,11,209,1,20,
170,11,213,4,0,
170,11,216,4,0,
170,11,218,4,0,
170,11,237,4,0,
170,11,240,4,0,
170,11,250,2,0,
170,11,258,4,0,
170,11,263,4,0,
170,11,268,1,50,
170,11,291,4,0,
170,11,324,1,34,
170,11,351,2,0,
170,11,352,2,0,
170,11,362,2,0,
170,11,392,1,42,
170,11,435,1,39,
170,11,451,4,0,
170,11,486,1,28,
170,11,496,4,0,
170,11,503,4,0,
170,11,521,4,0,
170,11,528,4,0,
170,12,36,1,37,
170,12,48,1,5,
170,12,55,1,17,
170,12,56,1,41,
170,12,57,4,0,
170,12,58,4,0,
170,12,59,4,0,
170,12,85,4,0,
170,12,86,1,1,2
170,12,87,4,0,
170,12,92,4,0,
170,12,104,4,0,
170,12,109,1,29,
170,12,127,4,0,
170,12,145,1,1,1
170,12,148,4,0,
170,12,156,4,0,
170,12,175,1,13,
170,12,182,4,0,
170,12,209,1,25,
170,12,213,4,0,
170,12,216,4,0,
170,12,218,4,0,
170,12,237,4,0,
170,12,240,4,0,
170,12,258,4,0,
170,12,263,4,0,
170,12,268,1,49,
170,12,290,4,0,
170,12,291,4,0,
170,12,351,4,0,
170,12,352,4,0,
170,13,36,1,37,
170,13,38,3,0,
170,13,48,1,5,
170,13,55,1,17,
170,13,56,1,41,
170,13,57,4,0,
170,13,58,4,0,
170,13,59,4,0,
170,13,85,4,0,
170,13,86,1,1,2
170,13,86,3,0,
170,13,87,4,0,
170,13,92,4,0,
170,13,102,3,0,
170,13,104,4,0,
170,13,109,1,29,
170,13,127,4,0,
170,13,145,1,1,1
170,13,148,4,0,
170,13,156,4,0,
170,13,164,3,0,
170,13,175,1,13,
170,13,182,4,0,
170,13,207,3,0,
170,13,209,1,25,
170,13,213,4,0,
170,13,216,4,0,
170,13,218,4,0,
170,13,237,4,0,
170,13,240,4,0,
170,13,258,4,0,
170,13,263,4,0,
170,13,268,1,49,
170,13,290,4,0,
170,13,291,4,0,
170,13,351,4,0,
170,13,352,4,0,
170,14,36,1,23,
170,14,48,1,1,1
170,14,54,2,0,
170,14,55,1,17,
170,14,56,1,45,
170,14,57,4,0,
170,14,58,4,0,
170,14,59,4,0,
170,14,60,2,0,
170,14,61,1,31,
170,14,85,4,0,
170,14,86,1,6,
170,14,86,4,0,
170,14,87,4,0,
170,14,92,4,0,
170,14,97,2,0,
170,14,103,2,0,
170,14,104,4,0,
170,14,109,1,12,
170,14,127,4,0,
170,14,133,2,0,
170,14,145,1,1,2
170,14,148,4,0,
170,14,156,4,0,
170,14,164,4,0,
170,14,173,3,0,
170,14,175,1,9,
170,14,175,2,0,
170,14,182,4,0,
170,14,196,3,0,
170,14,207,4,0,
170,14,209,1,20,
170,14,213,4,0,
170,14,214,3,0,
170,14,215,3,0,
170,14,216,4,0,
170,14,218,4,0,
170,14,237,4,0,
170,14,240,4,0,
170,14,250,2,0,
170,14,258,4,0,
170,14,263,4,0,
170,14,268,1,50,
170,14,291,4,0,
170,14,324,1,34,
170,14,324,3,0,
170,14,340,3,0,
170,14,351,2,0,
170,14,352,2,0,
170,14,362,2,0,
170,14,392,1,42,
170,14,435,1,39,
170,14,451,4,0,
170,14,486,1,28,
170,14,496,4,0,
170,14,503,4,0,
170,14,521,4,0,
170,14,528,4,0,
170,15,36,1,23,
170,15,48,1,1,2
170,15,54,2,0,
170,15,55,1,1,1
170,15,56,1,45,
170,15,57,4,0,
170,15,58,4,0,
170,15,59,4,0,
170,15,60,2,0,
170,15,61,1,31,
170,15,85,4,0,
170,15,86,1,6,
170,15,86,4,0,
170,15,87,4,0,
170,15,92,4,0,
170,15,97,2,0,
170,15,103,2,0,
170,15,104,4,0,
170,15,109,1,17,
170,15,127,4,0,
170,15,133,2,0,
170,15,145,1,12,
170,15,148,4,0,
170,15,156,4,0,
170,15,164,4,0,
170,15,175,1,9,
170,15,175,2,0,
170,15,182,4,0,
170,15,207,4,0,
170,15,209,1,20,
170,15,213,4,0,
170,15,214,4,0,
170,15,216,4,0,
170,15,218,4,0,
170,15,237,4,0,
170,15,240,4,0,
170,15,250,2,0,
170,15,258,4,0,
170,15,263,4,0,
170,15,268,1,50,
170,15,324,1,34,
170,15,351,2,0,
170,15,352,2,0,
170,15,362,2,0,
170,15,392,1,42,
170,15,435,1,39,
170,15,451,4,0,
170,15,486,1,28,
170,15,487,2,0,
170,15,496,4,0,
170,15,503,4,0,
170,15,521,4,0,
170,15,528,4,0,
170,15,569,1,47,
170,15,590,4,0,
170,15,605,4,0,
170,16,36,1,39,1
170,16,48,1,1,2
170,16,54,2,0,
170,16,55,1,12,1
170,16,56,1,45,1
170,16,57,4,0,
170,16,58,4,0,
170,16,59,4,0,
170,16,60,2,0,
170,16,61,1,20,1
170,16,85,4,0,
170,16,86,1,6,1
170,16,86,4,0,
170,16,87,4,0,
170,16,92,4,0,
170,16,97,2,0,
170,16,103,2,0,
170,16,104,4,0,
170,16,109,1,17,1
170,16,127,4,0,
170,16,133,2,0,
170,16,145,1,1,1
170,16,148,4,0,
170,16,156,4,0,
170,16,164,4,0,
170,16,173,3,0,
170,16,175,1,31,1
170,16,175,2,0,
170,16,182,4,0,
170,16,196,3,0,
170,16,207,4,0,
170,16,209,1,23,1
170,16,213,4,0,
170,16,214,4,0,
170,16,215,3,0,
170,16,216,4,0,
170,16,218,4,0,
170,16,237,4,0,
170,16,240,4,0,
170,16,250,2,0,
170,16,258,4,0,
170,16,263,4,0,
170,16,268,1,50,1
170,16,290,4,0,
170,16,291,4,0,
170,16,324,1,28,1
170,16,324,3,0,
170,16,340,3,0,
170,16,351,2,0,
170,16,351,3,0,
170,16,352,2,0,
170,16,352,3,0,
170,16,362,2,0,
170,16,392,1,42,1
170,16,435,1,34,1
170,16,451,4,0,
170,16,486,1,9,1
170,16,487,2,0,
170,16,496,4,0,
170,16,503,4,0,
170,16,521,4,0,
170,16,528,4,0,
170,16,569,1,47,1
170,16,590,4,0,
170,16,605,4,0,
170,17,36,1,39,
170,17,48,1,1,2
170,17,54,2,0,
170,17,55,1,12,
170,17,56,1,45,
170,17,57,4,0,
170,17,58,4,0,
170,17,59,4,0,
170,17,60,2,0,
170,17,61,1,20,
170,17,85,4,0,
170,17,86,1,6,
170,17,86,4,0,
170,17,87,4,0,
170,17,92,4,0,
170,17,97,2,0,
170,17,103,2,0,
170,17,104,4,0,
170,17,109,1,17,
170,17,127,4,0,
170,17,133,2,0,
170,17,145,1,1,1
170,17,156,4,0,
170,17,164,4,0,
170,17,175,1,31,
170,17,175,2,0,
170,17,182,4,0,
170,17,207,4,0,
170,17,209,1,23,
170,17,213,4,0,
170,17,214,4,0,
170,17,216,4,0,
170,17,218,4,0,
170,17,237,4,0,
170,17,240,4,0,
170,17,250,2,0,
170,17,258,4,0,
170,17,263,4,0,
170,17,268,1,50,
170,17,324,1,28,
170,17,351,2,0,
170,17,352,2,0,
170,17,362,2,0,
170,17,392,1,42,
170,17,435,1,34,
170,17,451,4,0,
170,17,486,1,9,
170,17,487,2,0,
170,17,496,4,0,
170,17,503,4,0,
170,17,521,4,0,
170,17,528,4,0,
170,17,569,1,47,
170,17,590,4,0,
170,17,605,4,0,
170,18,36,1,39,
170,18,48,1,1,2
170,18,54,2,0,
170,18,55,1,12,
170,18,56,1,45,
170,18,57,4,0,
170,18,58,4,0,
170,18,59,4,0,
170,18,60,2,0,
170,18,61,1,20,
170,18,85,4,0,
170,18,86,1,6,
170,18,86,4,0,
170,18,87,4,0,
170,18,92,4,0,
170,18,97,2,0,
170,18,103,2,0,
170,18,104,4,0,
170,18,109,1,17,
170,18,127,4,0,
170,18,133,2,0,
170,18,145,1,1,1
170,18,156,4,0,
170,18,164,4,0,
170,18,175,1,31,
170,18,175,2,0,
170,18,182,4,0,
170,18,207,4,0,
170,18,209,1,23,
170,18,213,4,0,
170,18,214,4,0,
170,18,216,4,0,
170,18,218,4,0,
170,18,237,4,0,
170,18,240,4,0,
170,18,250,2,0,
170,18,258,4,0,
170,18,263,4,0,
170,18,268,1,50,
170,18,324,1,28,
170,18,351,2,0,
170,18,352,2,0,
170,18,362,2,0,
170,18,392,1,42,
170,18,435,1,34,
170,18,451,4,0,
170,18,486,1,9,
170,18,487,2,0,
170,18,496,4,0,
170,18,503,4,0,
170,18,521,4,0,
170,18,528,4,0,
170,18,569,1,47,
170,18,590,4,0,
170,18,605,4,0,
171,3,36,1,45,
171,3,48,1,1,3
171,3,48,1,5,
171,3,55,1,17,
171,3,56,1,53,
171,3,57,4,0,
171,3,63,4,0,
171,3,86,1,1,2
171,3,87,4,0,
171,3,92,4,0,
171,3,104,4,0,
171,3,109,1,33,
171,3,127,4,0,
171,3,145,1,1,1
171,3,148,4,0,
171,3,156,4,0,
171,3,173,4,0,
171,3,174,4,0,
171,3,175,1,13,
171,3,182,4,0,
171,3,192,4,0,
171,3,203,4,0,
171,3,207,4,0,
171,3,209,1,25,
171,3,213,4,0,
171,3,214,4,0,
171,3,216,4,0,
171,3,218,4,0,
171,3,237,4,0,
171,3,240,4,0,
171,3,250,4,0,
171,4,36,1,45,
171,4,48,1,1,3
171,4,48,1,5,
171,4,55,1,17,
171,4,56,1,53,
171,4,57,4,0,
171,4,58,3,0,
171,4,63,4,0,
171,4,85,3,0,
171,4,86,1,1,2
171,4,87,4,0,
171,4,92,4,0,
171,4,104,4,0,
171,4,109,1,33,
171,4,127,4,0,
171,4,145,1,1,1
171,4,148,4,0,
171,4,156,4,0,
171,4,173,4,0,
171,4,174,4,0,
171,4,175,1,13,
171,4,182,4,0,
171,4,192,4,0,
171,4,203,4,0,
171,4,207,4,0,
171,4,209,1,25,
171,4,213,4,0,
171,4,214,4,0,
171,4,216,4,0,
171,4,218,4,0,
171,4,237,4,0,
171,4,240,4,0,
171,4,250,4,0,
171,5,36,1,43,
171,5,48,1,1,3
171,5,48,1,5,
171,5,55,1,17,
171,5,56,1,50,
171,5,57,4,0,
171,5,58,4,0,
171,5,59,4,0,
171,5,63,4,0,
171,5,85,4,0,
171,5,86,1,1,2
171,5,87,4,0,
171,5,92,4,0,
171,5,104,4,0,
171,5,109,1,32,
171,5,127,4,0,
171,5,145,1,1,1
171,5,148,4,0,
171,5,156,4,0,
171,5,175,1,13,
171,5,182,4,0,
171,5,209,1,25,
171,5,213,4,0,
171,5,216,4,0,
171,5,218,4,0,
171,5,237,4,0,
171,5,240,4,0,
171,5,258,4,0,
171,5,263,4,0,
171,5,268,1,61,
171,5,290,4,0,
171,5,291,4,0,
171,5,351,4,0,
171,5,352,4,0,
171,6,36,1,43,
171,6,38,3,0,
171,6,48,1,1,3
171,6,48,1,5,
171,6,55,1,17,
171,6,56,1,50,
171,6,57,4,0,
171,6,58,4,0,
171,6,59,4,0,
171,6,63,4,0,
171,6,85,4,0,
171,6,86,1,1,2
171,6,86,3,0,
171,6,87,4,0,
171,6,92,4,0,
171,6,102,3,0,
171,6,104,4,0,
171,6,109,1,32,
171,6,127,4,0,
171,6,145,1,1,1
171,6,148,4,0,
171,6,156,4,0,
171,6,164,3,0,
171,6,173,3,0,
171,6,175,1,13,
171,6,182,4,0,
171,6,203,3,0,
171,6,207,3,0,
171,6,209,1,25,
171,6,213,4,0,
171,6,214,3,0,
171,6,216,4,0,
171,6,218,4,0,
171,6,237,4,0,
171,6,240,4,0,
171,6,258,4,0,
171,6,263,4,0,
171,6,268,1,61,
171,6,290,4,0,
171,6,291,4,0,
171,6,351,4,0,
171,6,352,4,0,
171,7,36,1,43,
171,7,38,3,0,
171,7,48,1,1,3
171,7,48,1,5,
171,7,55,1,17,
171,7,56,1,50,
171,7,57,4,0,
171,7,58,4,0,
171,7,59,4,0,
171,7,63,4,0,
171,7,85,4,0,
171,7,86,1,1,2
171,7,86,3,0,
171,7,87,4,0,
171,7,92,4,0,
171,7,102,3,0,
171,7,104,4,0,
171,7,109,1,32,
171,7,127,4,0,
171,7,145,1,1,1
171,7,148,4,0,
171,7,156,4,0,
171,7,164,3,0,
171,7,175,1,13,
171,7,182,4,0,
171,7,209,1,25,
171,7,213,4,0,
171,7,216,4,0,
171,7,218,4,0,
171,7,237,4,0,
171,7,240,4,0,
171,7,258,4,0,
171,7,263,4,0,
171,7,268,1,61,
171,7,290,4,0,
171,7,291,4,0,
171,7,351,4,0,
171,7,352,4,0,
171,8,36,1,23,
171,8,48,1,1,2
171,8,55,1,12,
171,8,56,1,52,
171,8,57,4,0,
171,8,58,4,0,
171,8,59,4,0,
171,8,61,1,30,
171,8,63,4,0,
171,8,85,4,0,
171,8,86,1,1,3
171,8,86,1,6,
171,8,86,4,0,
171,8,87,4,0,
171,8,92,4,0,
171,8,104,4,0,
171,8,109,1,17,
171,8,127,4,0,
171,8,145,1,1,1
171,8,148,4,0,
171,8,156,4,0,
171,8,164,4,0,
171,8,175,1,9,
171,8,182,4,0,
171,8,203,4,0,
171,8,207,4,0,
171,8,209,1,20,
171,8,213,4,0,
171,8,214,4,0,
171,8,216,4,0,
171,8,218,4,0,
171,8,237,4,0,
171,8,240,4,0,
171,8,254,1,27,1
171,8,255,1,27,3
171,8,256,1,27,2
171,8,258,4,0,
171,8,263,4,0,
171,8,268,1,57,
171,8,290,4,0,
171,8,324,1,35,
171,8,351,4,0,
171,8,352,4,0,
171,8,362,4,0,
171,8,363,4,0,
171,8,392,1,47,
171,8,416,4,0,
171,8,435,1,40,
171,8,445,4,0,
171,8,451,4,0,
171,9,36,1,23,
171,9,48,1,1,2
171,9,55,1,12,
171,9,56,1,52,
171,9,57,4,0,
171,9,58,4,0,
171,9,59,4,0,
171,9,61,1,30,
171,9,63,4,0,
171,9,85,4,0,
171,9,86,1,1,3
171,9,86,1,6,
171,9,86,4,0,
171,9,87,4,0,
171,9,92,4,0,
171,9,104,4,0,
171,9,109,1,17,
171,9,127,4,0,
171,9,145,1,1,1
171,9,148,4,0,
171,9,156,4,0,
171,9,164,4,0,
171,9,173,3,0,
171,9,175,1,9,
171,9,182,4,0,
171,9,196,3,0,
171,9,203,4,0,
171,9,207,4,0,
171,9,209,1,20,
171,9,213,4,0,
171,9,214,4,0,
171,9,216,4,0,
171,9,218,4,0,
171,9,237,4,0,
171,9,240,4,0,
171,9,254,1,27,1
171,9,255,1,27,3
171,9,256,1,27,2
171,9,258,4,0,
171,9,263,4,0,
171,9,268,1,57,
171,9,290,4,0,
171,9,291,3,0,
171,9,324,1,35,
171,9,324,3,0,
171,9,340,3,0,
171,9,351,4,0,
171,9,352,4,0,
171,9,362,4,0,
171,9,363,4,0,
171,9,389,3,0,
171,9,392,1,47,
171,9,401,3,0,
171,9,416,4,0,
171,9,435,1,40,
171,9,445,4,0,
171,9,451,4,0,
171,10,36,1,23,
171,10,48,1,1,2
171,10,55,1,12,
171,10,56,1,52,
171,10,57,4,0,
171,10,58,4,0,
171,10,59,4,0,
171,10,61,1,30,
171,10,63,4,0,
171,10,85,4,0,
171,10,86,1,1,3
171,10,86,1,6,
171,10,86,4,0,
171,10,87,4,0,
171,10,92,4,0,
171,10,104,4,0,
171,10,109,1,17,
171,10,127,4,0,
171,10,145,1,1,1
171,10,148,4,0,
171,10,156,4,0,
171,10,164,4,0,
171,10,173,3,0,
171,10,175,1,9,
171,10,182,4,0,
171,10,196,3,0,
171,10,203,4,0,
171,10,207,4,0,
171,10,209,1,20,
171,10,213,4,0,
171,10,214,4,0,
171,10,215,3,0,
171,10,216,4,0,
171,10,218,4,0,
171,10,237,4,0,
171,10,240,4,0,
171,10,250,4,0,
171,10,254,1,27,1
171,10,255,1,27,3
171,10,256,1,27,2
171,10,258,4,0,
171,10,263,4,0,
171,10,268,1,57,
171,10,290,4,0,
171,10,291,3,0,
171,10,324,1,35,
171,10,324,3,0,
171,10,340,3,0,
171,10,351,4,0,
171,10,352,4,0,
171,10,362,4,0,
171,10,363,4,0,
171,10,389,3,0,
171,10,392,1,47,
171,10,401,3,0,
171,10,416,4,0,
171,10,435,1,40,
171,10,445,4,0,
171,10,451,4,0,
171,11,36,1,23,
171,11,48,1,1,2
171,11,55,1,12,
171,11,56,1,57,
171,11,57,4,0,
171,11,58,4,0,
171,11,59,4,0,
171,11,61,1,35,
171,11,63,4,0,
171,11,85,4,0,
171,11,86,1,1,3
171,11,86,1,6,
171,11,86,4,0,
171,11,87,4,0,
171,11,92,4,0,
171,11,104,4,0,
171,11,109,1,17,
171,11,127,4,0,
171,11,145,1,1,1
171,11,148,4,0,
171,11,156,4,0,
171,11,164,4,0,
171,11,175,1,9,
171,11,182,4,0,
171,11,207,4,0,
171,11,209,1,20,
171,11,213,4,0,
171,11,216,4,0,
171,11,218,4,0,
171,11,237,4,0,
171,11,240,4,0,
171,11,254,1,27,1
171,11,255,1,27,3
171,11,256,1,27,2
171,11,258,4,0,
171,11,263,4,0,
171,11,268,1,64,
171,11,291,4,0,
171,11,324,1,40,
171,11,392,1,52,
171,11,416,4,0,
171,11,435,1,47,
171,11,451,4,0,
171,11,486,1,30,
171,11,496,4,0,
171,11,503,4,0,
171,11,521,4,0,
171,11,528,4,0,
171,12,36,1,43,
171,12,48,1,1,3
171,12,48,1,5,
171,12,55,1,17,
171,12,56,1,50,
171,12,57,4,0,
171,12,58,4,0,
171,12,59,4,0,
171,12,63,4,0,
171,12,85,4,0,
171,12,86,1,1,2
171,12,87,4,0,
171,12,92,4,0,
171,12,104,4,0,
171,12,109,1,32,
171,12,127,4,0,
171,12,145,1,1,1
171,12,148,4,0,
171,12,156,4,0,
171,12,175,1,13,
171,12,182,4,0,
171,12,209,1,25,
171,12,213,4,0,
171,12,216,4,0,
171,12,218,4,0,
171,12,237,4,0,
171,12,240,4,0,
171,12,258,4,0,
171,12,263,4,0,
171,12,268,1,61,
171,12,290,4,0,
171,12,291,4,0,
171,12,351,4,0,
171,12,352,4,0,
171,13,36,1,43,
171,13,38,3,0,
171,13,48,1,1,3
171,13,48,1,5,
171,13,55,1,17,
171,13,56,1,50,
171,13,57,4,0,
171,13,58,4,0,
171,13,59,4,0,
171,13,63,4,0,
171,13,85,4,0,
171,13,86,1,1,2
171,13,86,3,0,
171,13,87,4,0,
171,13,92,4,0,
171,13,102,3,0,
171,13,104,4,0,
171,13,109,1,32,
171,13,127,4,0,
171,13,145,1,1,1
171,13,148,4,0,
171,13,156,4,0,
171,13,164,3,0,
171,13,175,1,13,
171,13,182,4,0,
171,13,207,3,0,
171,13,209,1,25,
171,13,213,4,0,
171,13,216,4,0,
171,13,218,4,0,
171,13,237,4,0,
171,13,240,4,0,
171,13,258,4,0,
171,13,263,4,0,
171,13,268,1,61,
171,13,290,4,0,
171,13,291,4,0,
171,13,351,4,0,
171,13,352,4,0,
171,14,36,1,23,
171,14,48,1,1,2
171,14,55,1,12,
171,14,56,1,57,
171,14,57,4,0,
171,14,58,4,0,
171,14,59,4,0,
171,14,61,1,35,
171,14,63,4,0,
171,14,85,4,0,
171,14,86,1,1,3
171,14,86,1,6,
171,14,86,4,0,
171,14,87,4,0,
171,14,92,4,0,
171,14,104,4,0,
171,14,109,1,17,
171,14,127,4,0,
171,14,145,1,1,1
171,14,148,4,0,
171,14,156,4,0,
171,14,164,4,0,
171,14,173,3,0,
171,14,175,1,9,
171,14,182,4,0,
171,14,196,3,0,
171,14,207,4,0,
171,14,209,1,20,
171,14,213,4,0,
171,14,214,3,0,
171,14,215,3,0,
171,14,216,4,0,
171,14,218,4,0,
171,14,237,4,0,
171,14,240,4,0,
171,14,254,1,27,1
171,14,255,1,27,3
171,14,256,1,27,2
171,14,258,4,0,
171,14,263,4,0,
171,14,268,1,64,
171,14,291,4,0,
171,14,324,1,40,
171,14,324,3,0,
171,14,340,3,0,
171,14,392,1,52,
171,14,401,3,0,
171,14,416,4,0,
171,14,435,1,47,
171,14,451,4,0,
171,14,486,1,30,
171,14,496,4,0,
171,14,503,4,0,
171,14,521,4,0,
171,14,528,4,0,
171,15,36,1,23,
171,15,48,1,1,3
171,15,55,1,1,2
171,15,56,1,57,
171,15,57,4,0,
171,15,58,4,0,
171,15,59,4,0,
171,15,61,1,35,
171,15,63,4,0,
171,15,85,4,0,
171,15,86,1,1,4
171,15,86,1,6,
171,15,86,4,0,
171,15,87,4,0,
171,15,92,4,0,
171,15,104,4,0,
171,15,109,1,17,
171,15,127,4,0,
171,15,145,1,12,
171,15,148,4,0,
171,15,156,4,0,
171,15,164,4,0,
171,15,175,1,9,
171,15,182,4,0,
171,15,207,4,0,
171,15,209,1,20,
171,15,213,4,0,
171,15,214,4,0,
171,15,216,4,0,
171,15,218,4,0,
171,15,237,4,0,
171,15,240,4,0,
171,15,254,1,27,1
171,15,255,1,27,3
171,15,256,1,27,2
171,15,258,4,0,
171,15,263,4,0,
171,15,268,1,64,
171,15,324,1,40,
171,15,392,1,52,
171,15,416,4,0,
171,15,435,1,47,
171,15,451,4,0,
171,15,486,1,30,
171,15,496,4,0,
171,15,503,4,0,
171,15,521,4,0,
171,15,528,4,0,
171,15,569,1,60,
171,15,590,4,0,
171,15,598,1,1,1
171,15,605,4,0,
171,16,36,1,43,1
171,16,48,1,1,3
171,16,55,1,12,1
171,16,56,1,51,1
171,16,57,4,0,
171,16,58,4,0,
171,16,59,4,0,
171,16,61,1,20,1
171,16,63,4,0,
171,16,85,4,0,
171,16,86,1,1,4
171,16,86,1,6,1
171,16,86,4,0,
171,16,87,4,0,
171,16,92,4,0,
171,16,104,4,0,
171,16,109,1,17,1
171,16,127,4,0,
171,16,145,1,1,2
171,16,148,4,0,
171,16,156,4,0,
171,16,164,4,0,
171,16,173,3,0,
171,16,175,1,33,1
171,16,182,4,0,
171,16,196,3,0,
171,16,207,4,0,
171,16,209,1,23,1
171,16,213,4,0,
171,16,214,4,0,
171,16,215,3,0,
171,16,216,4,0,
171,16,218,4,0,
171,16,237,4,0,
171,16,240,4,0,
171,16,254,1,27,1
171,16,255,1,27,3
171,16,256,1,27,2
171,16,258,4,0,
171,16,263,4,0,
171,16,268,1,58,1
171,16,290,4,0,
171,16,291,4,0,
171,16,324,1,29,1
171,16,324,3,0,
171,16,340,3,0,
171,16,351,3,0,
171,16,352,3,0,
171,16,392,1,47,1
171,16,401,3,0,
171,16,416,4,0,
171,16,435,1,37,1
171,16,451,4,0,
171,16,486,1,1,5
171,16,486,1,9,1
171,16,496,4,0,
171,16,503,4,0,
171,16,521,4,0,
171,16,528,4,0,
171,16,569,1,54,1
171,16,590,4,0,
171,16,598,1,1,1
171,16,605,4,0,
171,17,36,1,43,
171,17,48,1,1,6
171,17,55,1,12,
171,17,56,1,51,
171,17,57,4,0,
171,17,58,4,0,
171,17,59,4,0,
171,17,61,1,20,
171,17,63,4,0,
171,17,85,4,0,
171,17,86,1,1,7
171,17,86,1,6,
171,17,86,4,0,
171,17,87,4,0,
171,17,92,4,0,
171,17,104,4,0,
171,17,109,1,17,
171,17,127,4,0,
171,17,145,1,1,5
171,17,156,4,0,
171,17,164,4,0,
171,17,175,1,33,
171,17,182,4,0,
171,17,207,4,0,
171,17,209,1,23,
171,17,213,4,0,
171,17,214,4,0,
171,17,216,4,0,
171,17,218,4,0,
171,17,237,4,0,
171,17,240,4,0,
171,17,254,1,0,
171,17,254,1,1,1
171,17,255,1,0,
171,17,255,1,1,3
171,17,256,1,0,
171,17,256,1,1,2
171,17,258,4,0,
171,17,263,4,0,
171,17,268,1,58,
171,17,324,1,29,
171,17,392,1,47,
171,17,416,4,0,
171,17,435,1,37,
171,17,451,4,0,
171,17,486,1,1,8
171,17,486,1,9,
171,17,496,4,0,
171,17,503,4,0,
171,17,521,4,0,
171,17,528,4,0,
171,17,569,1,54,
171,17,590,4,0,
171,17,598,1,1,4
171,17,605,4,0,
171,18,36,1,43,
171,18,48,1,1,7
171,18,55,1,12,
171,18,56,1,51,
171,18,57,4,0,
171,18,58,4,0,
171,18,59,4,0,
171,18,61,1,20,
171,18,63,4,0,
171,18,85,4,0,
171,18,86,1,1,8
171,18,86,1,6,
171,18,86,4,0,
171,18,87,4,0,
171,18,92,4,0,
171,18,104,4,0,
171,18,109,1,17,
171,18,127,4,0,
171,18,145,1,1,6
171,18,156,4,0,
171,18,164,4,0,
171,18,175,1,33,
171,18,182,4,0,
171,18,207,4,0,
171,18,209,1,23,
171,18,213,4,0,
171,18,214,4,0,
171,18,216,4,0,
171,18,218,4,0,
171,18,237,4,0,
171,18,240,4,0,
171,18,254,1,0,
171,18,254,1,1,1
171,18,255,1,0,
171,18,255,1,1,3
171,18,256,1,0,
171,18,256,1,1,2
171,18,258,4,0,
171,18,263,4,0,
171,18,268,1,58,
171,18,324,1,29,
171,18,392,1,47,
171,18,416,4,0,
171,18,435,1,37,
171,18,451,4,0,
171,18,486,1,1,9
171,18,486,1,9,
171,18,496,4,0,
171,18,503,4,0,
171,18,521,4,0,
171,18,528,4,0,
171,18,569,1,54,
171,18,590,4,0,
171,18,598,1,1,4
171,18,605,4,0,
171,18,671,1,1,5
172,3,3,2,0,
172,3,29,4,0,
172,3,39,1,6,
172,3,84,1,1,1
172,3,86,1,8,
172,3,87,4,0,
172,3,92,4,0,
172,3,104,4,0,
172,3,111,4,0,
172,3,117,2,0,
172,3,129,4,0,
172,3,148,4,0,
172,3,156,4,0,
172,3,173,4,0,
172,3,174,4,0,
172,3,179,2,0,
172,3,182,4,0,
172,3,186,1,11,
172,3,189,4,0,
172,3,192,4,0,
172,3,197,4,0,
172,3,203,4,0,
172,3,204,1,1,2
172,3,205,4,0,
172,3,207,4,0,
172,3,213,4,0,
172,3,214,4,0,
172,3,216,4,0,
172,3,217,2,0,
172,3,218,4,0,
172,3,227,2,0,
172,3,231,4,0,
172,3,237,4,0,
172,3,240,4,0,
172,4,3,2,0,
172,4,29,4,0,
172,4,39,1,6,
172,4,84,1,1,1
172,4,85,3,0,
172,4,86,1,8,
172,4,87,4,0,
172,4,92,4,0,
172,4,104,4,0,
172,4,111,4,0,
172,4,117,2,0,
172,4,129,4,0,
172,4,148,4,0,
172,4,156,4,0,
172,4,173,4,0,
172,4,174,4,0,
172,4,179,2,0,
172,4,182,4,0,
172,4,186,1,11,
172,4,189,4,0,
172,4,192,4,0,
172,4,197,4,0,
172,4,203,4,0,
172,4,204,1,1,2
172,4,205,4,0,
172,4,207,4,0,
172,4,213,4,0,
172,4,214,4,0,
172,4,216,4,0,
172,4,217,2,0,
172,4,218,4,0,
172,4,227,2,0,
172,4,231,4,0,
172,4,237,4,0,
172,4,240,4,0,
172,5,3,2,0,
172,5,39,1,6,
172,5,84,1,1,1
172,5,85,4,0,
172,5,86,1,8,
172,5,87,4,0,
172,5,92,4,0,
172,5,104,4,0,
172,5,113,4,0,
172,5,117,2,0,
172,5,148,4,0,
172,5,156,4,0,
172,5,179,2,0,
172,5,182,4,0,
172,5,186,1,11,
172,5,204,1,1,2
172,5,213,4,0,
172,5,216,4,0,
172,5,217,2,0,
172,5,218,4,0,
172,5,227,2,0,
172,5,231,4,0,
172,5,237,4,0,
172,5,240,4,0,
172,5,263,4,0,
172,5,268,2,0,
172,5,273,2,0,
172,5,290,4,0,
172,5,351,4,0,
172,6,3,2,0,
172,6,5,3,0,
172,6,25,3,0,
172,6,34,3,0,
172,6,38,3,0,
172,6,39,1,6,
172,6,68,3,0,
172,6,69,3,0,
172,6,84,1,1,1
172,6,85,4,0,
172,6,86,1,8,
172,6,86,3,0,
172,6,87,4,0,
172,6,92,4,0,
172,6,102,3,0,
172,6,104,4,0,
172,6,111,3,0,
172,6,113,4,0,
172,6,117,2,0,
172,6,129,3,0,
172,6,148,4,0,
172,6,156,4,0,
172,6,164,3,0,
172,6,173,3,0,
172,6,179,2,0,
172,6,182,4,0,
172,6,186,1,11,
172,6,189,3,0,
172,6,203,3,0,
172,6,204,1,1,2
172,6,205,3,0,
172,6,207,3,0,
172,6,213,4,0,
172,6,214,3,0,
172,6,216,4,0,
172,6,217,2,0,
172,6,218,4,0,
172,6,227,2,0,
172,6,231,4,0,
172,6,237,4,0,
172,6,240,4,0,
172,6,263,4,0,
172,6,268,2,0,
172,6,273,2,0,
172,6,290,4,0,
172,6,344,6,0,
172,6,351,4,0,
172,7,3,2,0,
172,7,5,3,0,
172,7,25,3,0,
172,7,34,3,0,
172,7,38,3,0,
172,7,39,1,6,
172,7,68,3,0,
172,7,69,3,0,
172,7,84,1,1,1
172,7,85,4,0,
172,7,86,1,8,
172,7,86,3,0,
172,7,87,4,0,
172,7,92,4,0,
172,7,102,3,0,
172,7,104,4,0,
172,7,113,4,0,
172,7,117,2,0,
172,7,148,4,0,
172,7,156,4,0,
172,7,164,3,0,
172,7,179,2,0,
172,7,182,4,0,
172,7,186,1,11,
172,7,204,1,1,2
172,7,213,4,0,
172,7,216,4,0,
172,7,217,2,0,
172,7,218,4,0,
172,7,227,2,0,
172,7,231,4,0,
172,7,237,4,0,
172,7,240,4,0,
172,7,263,4,0,
172,7,268,2,0,
172,7,273,2,0,
172,7,290,4,0,
172,7,351,4,0,
172,8,3,2,0,
172,8,9,2,0,
172,8,39,1,5,
172,8,84,1,1,1
172,8,85,4,0,
172,8,86,1,10,
172,8,86,4,0,
172,8,87,4,0,
172,8,92,4,0,
172,8,104,4,0,
172,8,113,4,0,
172,8,117,2,0,
172,8,148,4,0,
172,8,156,4,0,
172,8,164,4,0,
172,8,179,2,0,
172,8,182,4,0,
172,8,186,1,13,
172,8,203,4,0,
172,8,204,1,1,2
172,8,207,4,0,
172,8,213,4,0,
172,8,214,4,0,
172,8,216,4,0,
172,8,217,2,0,
172,8,218,4,0,
172,8,227,2,0,
172,8,231,4,0,
172,8,237,4,0,
172,8,240,4,0,
172,8,252,2,0,
172,8,263,4,0,
172,8,268,2,0,
172,8,273,2,0,
172,8,290,4,0,
172,8,321,2,0,
172,8,344,6,0,
172,8,351,4,0,
172,8,363,4,0,
172,8,374,4,0,
172,8,417,1,18,
172,8,445,4,0,
172,8,447,4,0,
172,8,451,4,0,
172,9,3,2,0,
172,9,9,2,0,
172,9,39,1,5,
172,9,84,1,1,1
172,9,85,4,0,
172,9,86,1,10,
172,9,86,4,0,
172,9,87,4,0,
172,9,92,4,0,
172,9,104,4,0,
172,9,113,4,0,
172,9,117,2,0,
172,9,129,3,0,
172,9,148,4,0,
172,9,156,4,0,
172,9,164,4,0,
172,9,173,3,0,
172,9,179,2,0,
172,9,182,4,0,
172,9,186,1,13,
172,9,189,3,0,
172,9,203,4,0,
172,9,204,1,1,2
172,9,205,3,0,
172,9,207,4,0,
172,9,213,4,0,
172,9,214,4,0,
172,9,216,4,0,
172,9,217,2,0,
172,9,218,4,0,
172,9,227,2,0,
172,9,231,4,0,
172,9,237,4,0,
172,9,240,4,0,
172,9,252,2,0,
172,9,253,3,0,
172,9,263,4,0,
172,9,268,2,0,
172,9,270,3,0,
172,9,273,2,0,
172,9,290,4,0,
172,9,321,2,0,
172,9,324,3,0,
172,9,344,6,0,
172,9,351,4,0,
172,9,363,4,0,
172,9,374,4,0,
172,9,393,3,0,
172,9,417,1,18,
172,9,445,4,0,
172,9,447,4,0,
172,9,451,4,0,
172,10,3,2,0,
172,10,9,2,0,
172,10,29,3,0,
172,10,39,1,5,
172,10,84,1,1,1
172,10,85,4,0,
172,10,86,1,10,
172,10,86,4,0,
172,10,87,4,0,
172,10,92,4,0,
172,10,104,4,0,
172,10,113,4,0,
172,10,117,2,0,
172,10,129,3,0,
172,10,148,4,0,
172,10,156,4,0,
172,10,164,4,0,
172,10,173,3,0,
172,10,175,2,0,
172,10,179,2,0,
172,10,182,4,0,
172,10,186,1,13,
172,10,189,3,0,
172,10,203,4,0,
172,10,204,1,1,2
172,10,205,3,0,
172,10,207,4,0,
172,10,213,4,0,
172,10,214,4,0,
172,10,216,4,0,
172,10,217,2,0,
172,10,218,4,0,
172,10,227,2,0,
172,10,231,4,0,
172,10,237,4,0,
172,10,240,4,0,
172,10,252,2,0,
172,10,253,3,0,
172,10,263,4,0,
172,10,268,2,0,
172,10,270,3,0,
172,10,273,2,0,
172,10,290,4,0,
172,10,321,2,0,
172,10,324,3,0,
172,10,344,6,0,
172,10,351,4,0,
172,10,363,4,0,
172,10,374,4,0,
172,10,393,3,0,
172,10,417,1,18,
172,10,445,4,0,
172,10,447,4,0,
172,10,451,4,0,
172,11,3,2,0,
172,11,9,2,0,
172,11,39,1,5,
172,11,84,1,1,1
172,11,85,4,0,
172,11,86,1,10,
172,11,86,4,0,
172,11,87,4,0,
172,11,92,4,0,
172,11,104,4,0,
172,11,113,4,0,
172,11,117,2,0,
172,11,148,4,0,
172,11,156,4,0,
172,11,164,4,0,
172,11,175,2,0,
172,11,179,2,0,
172,11,182,4,0,
172,11,186,1,13,
172,11,203,2,0,
172,11,204,1,1,2
172,11,207,4,0,
172,11,213,4,0,
172,11,216,4,0,
172,11,217,2,0,
172,11,218,4,0,
172,11,227,2,0,
172,11,237,4,0,
172,11,240,4,0,
172,11,252,2,0,
172,11,263,4,0,
172,11,268,2,0,
172,11,273,2,0,
172,11,321,2,0,
172,11,344,6,0,
172,11,374,4,0,
172,11,381,2,0,
172,11,417,1,18,
172,11,447,4,0,
172,11,451,4,0,
172,11,496,4,0,
172,11,497,4,0,
172,11,516,2,0,
172,11,521,4,0,
172,11,528,4,0,
172,12,39,1,6,
172,12,84,1,1,1
172,12,85,4,0,
172,12,86,1,8,
172,12,87,4,0,
172,12,92,4,0,
172,12,104,4,0,
172,12,113,4,0,
172,12,148,4,0,
172,12,156,4,0,
172,12,182,4,0,
172,12,186,1,11,
172,12,204,1,1,2
172,12,213,4,0,
172,12,216,4,0,
172,12,218,4,0,
172,12,231,4,0,
172,12,237,4,0,
172,12,240,4,0,
172,12,263,4,0,
172,12,290,4,0,
172,12,351,4,0,
172,13,34,3,0,
172,13,38,3,0,
172,13,39,1,6,
172,13,69,3,0,
172,13,84,1,1,1
172,13,85,4,0,
172,13,86,1,8,
172,13,86,3,0,
172,13,87,4,0,
172,13,92,4,0,
172,13,102,3,0,
172,13,104,4,0,
172,13,113,4,0,
172,13,148,4,0,
172,13,156,4,0,
172,13,164,3,0,
172,13,182,4,0,
172,13,186,1,11,
172,13,204,1,1,2
172,13,207,3,0,
172,13,213,4,0,
172,13,216,4,0,
172,13,218,4,0,
172,13,231,4,0,
172,13,237,4,0,
172,13,240,4,0,
172,13,263,4,0,
172,13,290,4,0,
172,13,351,4,0,
172,14,3,2,0,
172,14,9,2,0,
172,14,39,1,5,
172,14,84,1,1,1
172,14,85,4,0,
172,14,86,1,10,
172,14,86,4,0,
172,14,87,4,0,
172,14,92,4,0,
172,14,104,4,0,
172,14,113,4,0,
172,14,117,2,0,
172,14,148,4,0,
172,14,156,4,0,
172,14,164,4,0,
172,14,173,3,0,
172,14,175,2,0,
172,14,179,2,0,
172,14,182,4,0,
172,14,186,1,13,
172,14,203,2,0,
172,14,204,1,1,2
172,14,207,4,0,
172,14,213,4,0,
172,14,214,3,0,
172,14,216,4,0,
172,14,217,2,0,
172,14,218,4,0,
172,14,227,2,0,
172,14,231,3,0,
172,14,237,4,0,
172,14,240,4,0,
172,14,252,2,0,
172,14,253,3,0,
172,14,263,4,0,
172,14,268,2,0,
172,14,270,3,0,
172,14,273,2,0,
172,14,321,2,0,
172,14,324,3,0,
172,14,343,3,0,
172,14,344,6,0,
172,14,374,4,0,
172,14,381,2,0,
172,14,393,3,0,
172,14,417,1,18,
172,14,447,4,0,
172,14,451,4,0,
172,14,496,4,0,
172,14,497,4,0,
172,14,516,2,0,
172,14,521,4,0,
172,14,528,4,0,
172,15,3,2,0,
172,15,9,2,0,
172,15,39,1,5,
172,15,84,1,1,1
172,15,85,4,0,
172,15,86,1,13,
172,15,86,4,0,
172,15,87,4,0,
172,15,92,4,0,
172,15,104,4,0,
172,15,113,4,0,
172,15,117,2,0,
172,15,148,4,0,
172,15,156,4,0,
172,15,164,4,0,
172,15,175,2,0,
172,15,179,2,0,
172,15,182,4,0,
172,15,186,1,10,
172,15,203,2,0,
172,15,204,1,1,2
172,15,207,4,0,
172,15,213,4,0,
172,15,214,4,0,
172,15,216,4,0,
172,15,217,2,0,
172,15,218,4,0,
172,15,227,2,0,
172,15,237,4,0,
172,15,240,4,0,
172,15,252,2,0,
172,15,263,4,0,
172,15,268,2,0,
172,15,273,2,0,
172,15,321,2,0,
172,15,344,6,0,
172,15,374,4,0,
172,15,381,2,0,
172,15,417,1,18,
172,15,447,4,0,
172,15,451,4,0,
172,15,496,4,0,
172,15,497,4,0,
172,15,516,2,0,
172,15,521,4,0,
172,15,528,4,0,
172,15,574,2,0,
172,15,590,4,0,
172,16,3,2,0,
172,16,9,2,0,
172,16,9,3,0,
172,16,39,1,5,1
172,16,84,1,1,1
172,16,85,4,0,
172,16,86,1,18,1
172,16,86,4,0,
172,16,87,4,0,
172,16,92,4,0,
172,16,104,4,0,
172,16,113,4,0,
172,16,117,2,0,
172,16,148,4,0,
172,16,156,4,0,
172,16,164,4,0,
172,16,173,3,0,
172,16,175,2,0,
172,16,179,2,0,
172,16,182,4,0,
172,16,186,1,10,1
172,16,203,2,0,
172,16,204,1,1,2
172,16,207,4,0,
172,16,213,4,0,
172,16,214,4,0,
172,16,216,4,0,
172,16,217,2,0,
172,16,218,4,0,
172,16,227,2,0,
172,16,231,3,0,
172,16,237,4,0,
172,16,240,4,0,
172,16,252,2,0,
172,16,253,3,0,
172,16,263,4,0,
172,16,268,2,0,
172,16,270,3,0,
172,16,273,2,0,
172,16,290,4,0,
172,16,321,2,0,
172,16,324,3,0,
172,16,343,3,0,
172,16,344,6,0,
172,16,351,3,0,
172,16,374,4,0,
172,16,381,2,0,
172,16,393,3,0,
172,16,417,1,13,1
172,16,447,4,0,
172,16,451,4,0,
172,16,496,4,0,
172,16,497,4,0,
172,16,516,2,0,
172,16,521,4,0,
172,16,527,3,0,
172,16,528,4,0,
172,16,574,2,0,
172,16,590,4,0,
172,17,3,2,0,
172,17,9,2,0,
172,17,39,1,5,
172,17,84,1,1,1
172,17,85,4,0,
172,17,86,1,18,
172,17,86,4,0,
172,17,87,4,0,
172,17,92,4,0,
172,17,104,4,0,
172,17,113,4,0,
172,17,117,2,0,
172,17,156,4,0,
172,17,164,4,0,
172,17,175,2,0,
172,17,179,2,0,
172,17,182,4,0,
172,17,186,1,10,
172,17,203,2,0,
172,17,204,1,1,2
172,17,207,4,0,
172,17,213,4,0,
172,17,214,4,0,
172,17,216,4,0,
172,17,217,2,0,
172,17,218,4,0,
172,17,227,2,0,
172,17,237,4,0,
172,17,240,4,0,
172,17,252,2,0,
172,17,263,4,0,
172,17,268,2,0,
172,17,273,2,0,
172,17,321,2,0,
172,17,344,6,0,
172,17,374,4,0,
172,17,381,2,0,
172,17,417,1,13,
172,17,447,4,0,
172,17,451,4,0,
172,17,496,4,0,
172,17,497,4,0,
172,17,516,2,0,
172,17,521,4,0,
172,17,528,4,0,
172,17,574,2,0,
172,17,590,4,0,
172,17,604,2,0,
172,18,3,2,0,
172,18,9,2,0,
172,18,39,1,5,
172,18,84,1,1,1
172,18,85,4,0,
172,18,86,1,18,
172,18,86,4,0,
172,18,87,4,0,
172,18,92,4,0,
172,18,104,4,0,
172,18,113,4,0,
172,18,117,2,0,
172,18,156,4,0,
172,18,164,4,0,
172,18,175,2,0,
172,18,179,2,0,
172,18,182,4,0,
172,18,186,1,10,
172,18,203,2,0,
172,18,204,1,1,2
172,18,207,4,0,
172,18,213,4,0,
172,18,214,4,0,
172,18,216,4,0,
172,18,217,2,0,
172,18,218,4,0,
172,18,227,2,0,
172,18,237,4,0,
172,18,240,4,0,
172,18,252,2,0,
172,18,263,4,0,
172,18,268,2,0,
172,18,273,2,0,
172,18,321,2,0,
172,18,344,6,0,
172,18,374,4,0,
172,18,381,2,0,
172,18,417,1,13,
172,18,447,4,0,
172,18,451,4,0,
172,18,496,4,0,
172,18,497,4,0,
172,18,516,2,0,
172,18,521,4,0,
172,18,528,4,0,
172,18,574,2,0,
172,18,590,4,0,
172,18,604,2,0,
173,3,1,1,1,1
173,3,29,4,0,
173,3,47,1,8,
173,3,76,4,0,
173,3,92,4,0,
173,3,94,4,0,
173,3,102,2,0,
173,3,104,4,0,
173,3,111,4,0,
173,3,118,2,0,
173,3,126,4,0,
173,3,133,2,0,
173,3,138,4,0,
173,3,148,4,0,
173,3,150,2,0,
173,3,156,4,0,
173,3,171,4,0,
173,3,173,4,0,
173,3,174,4,0,
173,3,182,4,0,
173,3,186,1,13,
173,3,187,2,0,
173,3,189,4,0,
173,3,192,4,0,
173,3,196,4,0,
173,3,197,4,0,
173,3,203,4,0,
173,3,204,1,1,2
173,3,205,4,0,
173,3,207,4,0,
173,3,213,4,0,
173,3,214,4,0,
173,3,216,4,0,
173,3,217,2,0,
173,3,218,4,0,
173,3,227,1,4,
173,3,231,4,0,
173,3,237,4,0,
173,3,240,4,0,
173,3,241,4,0,
173,3,244,4,0,
173,3,247,4,0,
173,4,1,1,1,1
173,4,29,4,0,
173,4,47,1,8,
173,4,53,3,0,
173,4,76,4,0,
173,4,92,4,0,
173,4,94,4,0,
173,4,102,2,0,
173,4,104,4,0,
173,4,111,4,0,
173,4,118,2,0,
173,4,126,4,0,
173,4,133,2,0,
173,4,138,4,0,
173,4,148,4,0,
173,4,150,2,0,
173,4,156,4,0,
173,4,171,4,0,
173,4,173,4,0,
173,4,174,4,0,
173,4,182,4,0,
173,4,186,1,13,
173,4,187,2,0,
173,4,189,4,0,
173,4,192,4,0,
173,4,196,4,0,
173,4,197,4,0,
173,4,203,4,0,
173,4,204,1,1,2
173,4,205,4,0,
173,4,207,4,0,
173,4,213,4,0,
173,4,214,4,0,
173,4,216,4,0,
173,4,217,2,0,
173,4,218,4,0,
173,4,227,1,4,
173,4,231,4,0,
173,4,237,4,0,
173,4,240,4,0,
173,4,241,4,0,
173,4,244,4,0,
173,4,247,4,0,
173,5,1,1,1,1
173,5,47,1,8,
173,5,53,4,0,
173,5,76,4,0,
173,5,91,4,0,
173,5,92,4,0,
173,5,94,4,0,
173,5,102,2,0,
173,5,104,4,0,
173,5,113,4,0,
173,5,115,4,0,
173,5,118,2,0,
173,5,126,4,0,
173,5,133,2,0,
173,5,148,4,0,
173,5,150,2,0,
173,5,156,4,0,
173,5,164,2,0,
173,5,182,4,0,
173,5,186,1,13,
173,5,187,2,0,
173,5,204,1,1,2
173,5,213,4,0,
173,5,216,4,0,
173,5,217,2,0,
173,5,218,4,0,
173,5,219,4,0,
173,5,227,1,4,
173,5,231,4,0,
173,5,237,4,0,
173,5,240,4,0,
173,5,241,4,0,
173,5,247,4,0,
173,5,263,4,0,
173,5,273,2,0,
173,5,290,4,0,
173,5,351,4,0,
173,5,352,4,0,
173,6,1,1,1,1
173,6,5,3,0,
173,6,25,3,0,
173,6,34,3,0,
173,6,38,3,0,
173,6,47,1,8,
173,6,53,4,0,
173,6,68,3,0,
173,6,69,3,0,
173,6,76,4,0,
173,6,86,3,0,
173,6,91,4,0,
173,6,92,4,0,
173,6,94,4,0,
173,6,102,2,0,
173,6,102,3,0,
173,6,104,4,0,
173,6,111,3,0,
173,6,113,4,0,
173,6,115,4,0,
173,6,118,2,0,
173,6,118,3,0,
173,6,126,4,0,
173,6,133,2,0,
173,6,135,3,0,
173,6,138,3,0,
173,6,148,4,0,
173,6,150,2,0,
173,6,156,4,0,
173,6,164,2,0,
173,6,164,3,0,
173,6,173,3,0,
173,6,182,4,0,
173,6,186,1,13,
173,6,187,2,0,
173,6,189,3,0,
173,6,196,3,0,
173,6,203,3,0,
173,6,204,1,1,2
173,6,205,3,0,
173,6,207,3,0,
173,6,213,4,0,
173,6,214,3,0,
173,6,216,4,0,
173,6,217,2,0,
173,6,218,4,0,
173,6,219,4,0,
173,6,227,1,4,
173,6,231,4,0,
173,6,237,4,0,
173,6,240,4,0,
173,6,241,4,0,
173,6,244,3,0,
173,6,247,4,0,
173,6,263,4,0,
173,6,273,2,0,
173,6,290,4,0,
173,6,351,4,0,
173,6,352,4,0,
173,7,1,1,1,1
173,7,5,3,0,
173,7,25,3,0,
173,7,34,3,0,
173,7,38,3,0,
173,7,47,1,8,
173,7,53,4,0,
173,7,68,3,0,
173,7,69,3,0,
173,7,76,4,0,
173,7,86,3,0,
173,7,91,4,0,
173,7,92,4,0,
173,7,94,4,0,
173,7,102,2,0,
173,7,102,3,0,
173,7,104,4,0,
173,7,113,4,0,
173,7,115,4,0,
173,7,118,2,0,
173,7,118,3,0,
173,7,126,4,0,
173,7,133,2,0,
173,7,135,3,0,
173,7,138,3,0,
173,7,148,4,0,
173,7,150,2,0,
173,7,156,4,0,
173,7,164,2,0,
173,7,164,3,0,
173,7,182,4,0,
173,7,186,1,13,
173,7,187,2,0,
173,7,204,1,1,2
173,7,213,4,0,
173,7,216,4,0,
173,7,217,2,0,
173,7,218,4,0,
173,7,219,4,0,
173,7,227,1,4,
173,7,231,4,0,
173,7,237,4,0,
173,7,240,4,0,
173,7,241,4,0,
173,7,247,4,0,
173,7,263,4,0,
173,7,273,2,0,
173,7,290,4,0,
173,7,345,1,17,
173,7,351,4,0,
173,7,352,4,0,
173,8,1,1,1,1
173,8,47,1,7,
173,8,53,4,0,
173,8,76,4,0,
173,8,86,4,0,
173,8,91,4,0,
173,8,92,4,0,
173,8,94,4,0,
173,8,102,2,0,
173,8,104,4,0,
173,8,113,4,0,
173,8,115,4,0,
173,8,118,2,0,
173,8,126,4,0,
173,8,133,2,0,
173,8,138,4,0,
173,8,148,4,0,
173,8,150,2,0,
173,8,156,4,0,
173,8,164,2,0,
173,8,164,4,0,
173,8,182,4,0,
173,8,186,1,10,
173,8,187,2,0,
173,8,203,4,0,
173,8,204,1,1,2
173,8,207,4,0,
173,8,213,4,0,
173,8,214,4,0,
173,8,216,4,0,
173,8,217,2,0,
173,8,218,4,0,
173,8,219,4,0,
173,8,227,1,4,
173,8,231,4,0,
173,8,237,4,0,
173,8,240,4,0,
173,8,241,4,0,
173,8,244,4,0,
173,8,247,4,0,
173,8,263,4,0,
173,8,273,2,0,
173,8,278,4,0,
173,8,290,4,0,
173,8,313,2,0,
173,8,343,2,0,
173,8,345,1,16,
173,8,351,4,0,
173,8,352,4,0,
173,8,363,4,0,
173,8,374,4,0,
173,8,383,1,13,
173,8,445,4,0,
173,8,447,4,0,
173,9,1,1,1,1
173,9,47,1,7,
173,9,53,4,0,
173,9,76,4,0,
173,9,86,4,0,
173,9,91,4,0,
173,9,92,4,0,
173,9,94,4,0,
173,9,102,2,0,
173,9,104,4,0,
173,9,113,4,0,
173,9,115,4,0,
173,9,118,2,0,
173,9,126,4,0,
173,9,133,2,0,
173,9,138,4,0,
173,9,148,4,0,
173,9,150,2,0,
173,9,156,4,0,
173,9,164,2,0,
173,9,164,4,0,
173,9,173,3,0,
173,9,182,4,0,
173,9,186,1,10,
173,9,187,2,0,
173,9,189,3,0,
173,9,196,3,0,
173,9,203,4,0,
173,9,204,1,1,2
173,9,205,3,0,
173,9,207,4,0,
173,9,213,4,0,
173,9,214,4,0,
173,9,216,4,0,
173,9,217,2,0,
173,9,218,4,0,
173,9,219,4,0,
173,9,227,1,4,
173,9,231,4,0,
173,9,237,4,0,
173,9,240,4,0,
173,9,241,4,0,
173,9,244,4,0,
173,9,247,4,0,
173,9,253,3,0,
173,9,263,4,0,
173,9,270,3,0,
173,9,271,3,0,
173,9,273,2,0,
173,9,278,4,0,
173,9,283,3,0,
173,9,290,4,0,
173,9,313,2,0,
173,9,324,3,0,
173,9,343,2,0,
173,9,345,1,16,
173,9,351,4,0,
173,9,352,4,0,
173,9,363,4,0,
173,9,374,4,0,
173,9,383,1,13,
173,9,387,3,0,
173,9,428,3,0,
173,9,445,4,0,
173,9,447,4,0,
173,10,1,1,1,1
173,10,29,3,0,
173,10,47,1,7,
173,10,53,4,0,
173,10,76,4,0,
173,10,86,4,0,
173,10,91,4,0,
173,10,92,4,0,
173,10,94,4,0,
173,10,102,2,0,
173,10,104,4,0,
173,10,113,4,0,
173,10,115,4,0,
173,10,118,2,0,
173,10,126,4,0,
173,10,133,2,0,
173,10,138,4,0,
173,10,148,4,0,
173,10,150,2,0,
173,10,156,4,0,
173,10,164,2,0,
173,10,164,4,0,
173,10,173,3,0,
173,10,182,4,0,
173,10,186,1,10,
173,10,187,2,0,
173,10,189,3,0,
173,10,196,3,0,
173,10,203,4,0,
173,10,204,1,1,2
173,10,205,3,0,
173,10,207,4,0,
173,10,213,4,0,
173,10,214,4,0,
173,10,216,4,0,
173,10,217,2,0,
173,10,218,4,0,
173,10,219,4,0,
173,10,227,1,4,
173,10,231,4,0,
173,10,237,4,0,
173,10,240,4,0,
173,10,241,4,0,
173,10,244,4,0,
173,10,247,4,0,
173,10,253,3,0,
173,10,263,4,0,
173,10,270,3,0,
173,10,271,3,0,
173,10,272,3,0,
173,10,273,2,0,
173,10,277,3,0,
173,10,278,4,0,
173,10,283,3,0,
173,10,290,4,0,
173,10,312,2,0,
173,10,313,2,0,
173,10,324,3,0,
173,10,343,2,0,
173,10,345,1,16,
173,10,351,4,0,
173,10,352,4,0,
173,10,356,3,0,
173,10,363,4,0,
173,10,374,4,0,
173,10,383,1,13,
173,10,387,3,0,
173,10,428,3,0,
173,10,445,4,0,
173,10,447,4,0,
173,11,1,1,1,1
173,11,47,1,7,
173,11,53,4,0,
173,11,76,4,0,
173,11,86,4,0,
173,11,91,4,0,
173,11,92,4,0,
173,11,94,4,0,
173,11,102,2,0,
173,11,104,4,0,
173,11,113,4,0,
173,11,115,4,0,
173,11,118,2,0,
173,11,126,4,0,
173,11,133,2,0,
173,11,138,4,0,
173,11,148,4,0,
173,11,150,2,0,
173,11,156,4,0,
173,11,164,4,0,
173,11,182,4,0,
173,11,186,1,10,
173,11,187,2,0,
173,11,204,1,1,2
173,11,207,4,0,
173,11,213,4,0,
173,11,216,4,0,
173,11,217,2,0,
173,11,218,4,0,
173,11,219,4,0,
173,11,227,1,4,
173,11,237,4,0,
173,11,240,4,0,
173,11,241,4,0,
173,11,244,4,0,
173,11,247,4,0,
173,11,263,4,0,
173,11,273,2,0,
173,11,312,2,0,
173,11,313,2,0,
173,11,321,2,0,
173,11,343,2,0,
173,11,345,1,16,
173,11,374,4,0,
173,11,383,1,13,
173,11,447,4,0,
173,11,473,4,0,
173,11,477,4,0,
173,11,496,4,0,
173,11,497,4,0,
173,11,500,2,0,
173,11,510,4,0,
173,11,526,4,0,
173,12,1,1,1,1
173,12,47,1,8,
173,12,53,4,0,
173,12,76,4,0,
173,12,91,4,0,
173,12,92,4,0,
173,12,94,4,0,
173,12,104,4,0,
173,12,113,4,0,
173,12,115,4,0,
173,12,126,4,0,
173,12,148,4,0,
173,12,156,4,0,
173,12,182,4,0,
173,12,186,1,13,
173,12,204,1,1,2
173,12,213,4,0,
173,12,216,4,0,
173,12,218,4,0,
173,12,219,4,0,
173,12,227,1,4,
173,12,231,4,0,
173,12,237,4,0,
173,12,240,4,0,
173,12,241,4,0,
173,12,247,4,0,
173,12,263,4,0,
173,12,290,4,0,
173,12,351,4,0,
173,12,352,4,0,
173,13,1,1,1,1
173,13,34,3,0,
173,13,38,3,0,
173,13,47,1,8,
173,13,53,4,0,
173,13,69,3,0,
173,13,76,4,0,
173,13,86,3,0,
173,13,91,4,0,
173,13,92,4,0,
173,13,94,4,0,
173,13,102,3,0,
173,13,104,4,0,
173,13,113,4,0,
173,13,115,4,0,
173,13,126,4,0,
173,13,138,3,0,
173,13,148,4,0,
173,13,156,4,0,
173,13,164,3,0,
173,13,171,3,0,
173,13,182,4,0,
173,13,186,1,13,
173,13,196,3,0,
173,13,204,1,1,2
173,13,207,3,0,
173,13,213,4,0,
173,13,216,4,0,
173,13,218,4,0,
173,13,219,4,0,
173,13,227,1,4,
173,13,231,4,0,
173,13,237,4,0,
173,13,240,4,0,
173,13,241,4,0,
173,13,247,4,0,
173,13,263,4,0,
173,13,290,4,0,
173,13,351,4,0,
173,13,352,4,0,
173,14,1,1,1,1
173,14,47,1,7,
173,14,53,4,0,
173,14,76,4,0,
173,14,86,4,0,
173,14,91,4,0,
173,14,92,4,0,
173,14,94,4,0,
173,14,102,2,0,
173,14,104,4,0,
173,14,113,4,0,
173,14,115,4,0,
173,14,118,2,0,
173,14,126,4,0,
173,14,133,2,0,
173,14,138,4,0,
173,14,148,4,0,
173,14,150,2,0,
173,14,156,4,0,
173,14,164,4,0,
173,14,173,3,0,
173,14,182,4,0,
173,14,186,1,10,
173,14,187,2,0,
173,14,196,3,0,
173,14,204,1,1,2
173,14,207,4,0,
173,14,213,4,0,
173,14,214,3,0,
173,14,216,4,0,
173,14,217,2,0,
173,14,218,4,0,
173,14,219,4,0,
173,14,227,1,4,
173,14,231,3,0,
173,14,237,4,0,
173,14,240,4,0,
173,14,241,4,0,
173,14,244,4,0,
173,14,247,4,0,
173,14,253,3,0,
173,14,263,4,0,
173,14,270,3,0,
173,14,271,3,0,
173,14,272,3,0,
173,14,273,2,0,
173,14,277,3,0,
173,14,278,3,0,
173,14,283,3,0,
173,14,304,3,0,
173,14,312,2,0,
173,14,313,2,0,
173,14,321,2,0,
173,14,324,3,0,
173,14,343,2,0,
173,14,343,3,0,
173,14,345,1,16,
173,14,356,3,0,
173,14,374,4,0,
173,14,383,1,13,
173,14,387,3,0,
173,14,428,3,0,
173,14,447,4,0,
173,14,472,3,0,
173,14,473,4,0,
173,14,477,4,0,
173,14,495,3,0,
173,14,496,4,0,
173,14,497,4,0,
173,14,500,2,0,
173,14,510,4,0,
173,14,526,4,0,
173,15,1,1,1,1
173,15,47,1,7,
173,15,53,4,0,
173,15,76,4,0,
173,15,86,4,0,
173,15,91,4,0,
173,15,92,4,0,
173,15,94,4,0,
173,15,102,2,0,
173,15,104,4,0,
173,15,113,4,0,
173,15,115,4,0,
173,15,118,2,0,
173,15,126,4,0,
173,15,133,2,0,
173,15,138,4,0,
173,15,148,4,0,
173,15,150,2,0,
173,15,156,4,0,
173,15,164,4,0,
173,15,182,4,0,
173,15,186,1,10,
173,15,187,2,0,
173,15,204,1,1,2
173,15,207,4,0,
173,15,213,4,0,
173,15,214,4,0,
173,15,216,4,0,
173,15,217,2,0,
173,15,218,4,0,
173,15,219,4,0,
173,15,227,1,4,
173,15,237,4,0,
173,15,240,4,0,
173,15,241,4,0,
173,15,244,4,0,
173,15,247,4,0,
173,15,263,4,0,
173,15,273,2,0,
173,15,312,2,0,
173,15,313,2,0,
173,15,321,2,0,
173,15,343,2,0,
173,15,345,1,16,
173,15,374,4,0,
173,15,383,1,13,
173,15,447,4,0,
173,15,473,4,0,
173,15,496,4,0,
173,15,497,4,0,
173,15,500,2,0,
173,15,505,2,0,
173,15,510,4,0,
173,15,581,2,0,
173,15,590,4,0,
173,16,1,1,1,1
173,16,47,1,7,1
173,16,53,4,0,
173,16,76,4,0,
173,16,86,4,0,
173,16,91,4,0,
173,16,92,4,0,
173,16,94,4,0,
173,16,102,2,0,
173,16,104,4,0,
173,16,113,4,0,
173,16,115,4,0,
173,16,118,2,0,
173,16,126,4,0,
173,16,133,2,0,
173,16,138,4,0,
173,16,148,4,0,
173,16,150,2,0,
173,16,156,4,0,
173,16,164,4,0,
173,16,173,3,0,
173,16,182,4,0,
173,16,186,1,10,1
173,16,187,2,0,
173,16,196,3,0,
173,16,204,1,1,2
173,16,207,4,0,
173,16,213,4,0,
173,16,214,4,0,
173,16,216,4,0,
173,16,217,2,0,
173,16,218,4,0,
173,16,219,4,0,
173,16,227,1,4,1
173,16,231,3,0,
173,16,237,4,0,
173,16,240,4,0,
173,16,241,4,0,
173,16,244,4,0,
173,16,247,4,0,
173,16,253,3,0,
173,16,263,4,0,
173,16,270,3,0,
173,16,271,3,0,
173,16,272,3,0,
173,16,273,2,0,
173,16,277,3,0,
173,16,278,3,0,
173,16,283,3,0,
173,16,290,4,0,
173,16,304,3,0,
173,16,312,2,0,
173,16,313,2,0,
173,16,321,2,0,
173,16,324,3,0,
173,16,343,2,0,
173,16,343,3,0,
173,16,345,1,16,1
173,16,351,3,0,
173,16,352,3,0,
173,16,356,3,0,
173,16,374,4,0,
173,16,383,1,13,1
173,16,387,3,0,
173,16,428,3,0,
173,16,447,4,0,
173,16,472,3,0,
173,16,473,4,0,
173,16,495,3,0,
173,16,496,4,0,
173,16,497,4,0,
173,16,500,2,0,
173,16,505,2,0,
173,16,510,4,0,
173,16,581,2,0,
173,16,590,4,0,
173,17,1,1,1,1
173,17,47,1,7,
173,17,53,4,0,
173,17,76,4,0,
173,17,86,4,0,
173,17,92,4,0,
173,17,94,4,0,
173,17,102,2,0,
173,17,104,4,0,
173,17,113,4,0,
173,17,115,4,0,
173,17,118,2,0,
173,17,126,4,0,
173,17,133,2,0,
173,17,138,4,0,
173,17,150,2,0,
173,17,156,4,0,
173,17,164,4,0,
173,17,182,4,0,
173,17,186,1,10,
173,17,187,2,0,
173,17,204,1,1,2
173,17,207,4,0,
173,17,213,4,0,
173,17,214,4,0,
173,17,216,4,0,
173,17,217,2,0,
173,17,218,4,0,
173,17,219,4,0,
173,17,227,1,4,
173,17,237,4,0,
173,17,240,4,0,
173,17,241,4,0,
173,17,244,4,0,
173,17,247,4,0,
173,17,263,4,0,
173,17,273,2,0,
173,17,312,2,0,
173,17,313,2,0,
173,17,321,2,0,
173,17,343,2,0,
173,17,345,1,16,
173,17,374,4,0,
173,17,383,1,13,
173,17,447,4,0,
173,17,473,4,0,
173,17,496,4,0,
173,17,497,4,0,
173,17,500,2,0,
173,17,505,2,0,
173,17,526,4,0,
173,17,581,2,0,
173,17,590,4,0,
173,18,1,1,1,1
173,18,47,1,7,
173,18,53,4,0,
173,18,76,4,0,
173,18,86,4,0,
173,18,92,4,0,
173,18,94,4,0,
173,18,102,2,0,
173,18,104,4,0,
173,18,113,4,0,
173,18,115,4,0,
173,18,118,2,0,
173,18,126,4,0,
173,18,133,2,0,
173,18,138,4,0,
173,18,150,2,0,
173,18,156,4,0,
173,18,164,4,0,
173,18,182,4,0,
173,18,186,1,10,
173,18,187,2,0,
173,18,204,1,1,2
173,18,207,4,0,
173,18,213,4,0,
173,18,214,4,0,
173,18,216,4,0,
173,18,217,2,0,
173,18,218,4,0,
173,18,219,4,0,
173,18,227,1,4,
173,18,237,4,0,
173,18,240,4,0,
173,18,241,4,0,
173,18,244,4,0,
173,18,247,4,0,
173,18,263,4,0,
173,18,273,2,0,
173,18,312,2,0,
173,18,313,2,0,
173,18,321,2,0,
173,18,343,2,0,
173,18,345,1,16,
173,18,374,4,0,
173,18,383,1,13,
173,18,447,4,0,
173,18,473,4,0,
173,18,496,4,0,
173,18,497,4,0,
173,18,500,2,0,
173,18,505,2,0,
173,18,526,4,0,
173,18,581,2,0,
173,18,590,4,0,
174,3,1,1,9,
174,3,29,4,0,
174,3,47,1,1,1
174,3,76,4,0,
174,3,92,4,0,
174,3,94,4,0,
174,3,104,4,0,
174,3,111,1,4,
174,3,111,4,0,
174,3,126,4,0,
174,3,138,4,0,
174,3,148,4,0,
174,3,156,4,0,
174,3,171,4,0,
174,3,173,4,0,
174,3,174,4,0,
174,3,182,4,0,
174,3,185,2,0,
174,3,186,1,14,
174,3,189,4,0,
174,3,192,4,0,
174,3,195,2,0,
174,3,196,4,0,
174,3,197,4,0,
174,3,203,4,0,
174,3,204,1,1,2
174,3,205,4,0,
174,3,207,4,0,
174,3,213,4,0,
174,3,214,4,0,
174,3,216,4,0,
174,3,217,2,0,
174,3,218,4,0,
174,3,237,4,0,
174,3,240,4,0,
174,3,241,4,0,
174,3,244,4,0,
174,3,247,4,0,
174,4,1,1,9,
174,4,29,4,0,
174,4,47,1,1,1
174,4,53,3,0,
174,4,76,4,0,
174,4,92,4,0,
174,4,94,4,0,
174,4,104,4,0,
174,4,111,1,4,
174,4,111,4,0,
174,4,126,4,0,
174,4,138,4,0,
174,4,148,4,0,
174,4,156,4,0,
174,4,171,4,0,
174,4,173,4,0,
174,4,174,4,0,
174,4,182,4,0,
174,4,185,2,0,
174,4,186,1,14,
174,4,189,4,0,
174,4,192,4,0,
174,4,195,2,0,
174,4,196,4,0,
174,4,197,4,0,
174,4,203,4,0,
174,4,204,1,1,2
174,4,205,4,0,
174,4,207,4,0,
174,4,213,4,0,
174,4,214,4,0,
174,4,216,4,0,
174,4,217,2,0,
174,4,218,4,0,
174,4,237,4,0,
174,4,240,4,0,
174,4,241,4,0,
174,4,244,4,0,
174,4,247,4,0,
174,5,1,1,9,
174,5,47,1,1,1
174,5,53,4,0,
174,5,76,4,0,
174,5,91,4,0,
174,5,92,4,0,
174,5,94,4,0,
174,5,104,4,0,
174,5,111,1,4,
174,5,113,4,0,
174,5,115,4,0,
174,5,126,4,0,
174,5,148,4,0,
174,5,156,4,0,
174,5,182,4,0,
174,5,185,2,0,
174,5,186,1,14,
174,5,195,2,0,
174,5,204,1,1,2
174,5,213,4,0,
174,5,216,4,0,
174,5,217,2,0,
174,5,218,4,0,
174,5,219,4,0,
174,5,237,4,0,
174,5,240,4,0,
174,5,241,4,0,
174,5,247,4,0,
174,5,263,4,0,
174,5,273,2,0,
174,5,290,4,0,
174,5,313,2,0,
174,5,351,4,0,
174,5,352,4,0,
174,6,1,1,9,
174,6,5,3,0,
174,6,25,3,0,
174,6,34,3,0,
174,6,38,3,0,
174,6,47,1,1,1
174,6,53,4,0,
174,6,68,3,0,
174,6,69,3,0,
174,6,76,4,0,
174,6,86,3,0,
174,6,91,4,0,
174,6,92,4,0,
174,6,94,4,0,
174,6,102,3,0,
174,6,104,4,0,
174,6,111,1,4,
174,6,111,3,0,
174,6,113,4,0,
174,6,115,4,0,
174,6,126,4,0,
174,6,138,3,0,
174,6,148,4,0,
174,6,156,4,0,
174,6,164,3,0,
174,6,173,3,0,
174,6,182,4,0,
174,6,185,2,0,
174,6,186,1,14,
174,6,189,3,0,
174,6,195,2,0,
174,6,196,3,0,
174,6,203,3,0,
174,6,204,1,1,2
174,6,205,3,0,
174,6,207,3,0,
174,6,213,4,0,
174,6,214,3,0,
174,6,216,4,0,
174,6,217,2,0,
174,6,218,4,0,
174,6,219,4,0,
174,6,237,4,0,
174,6,240,4,0,
174,6,241,4,0,
174,6,244,3,0,
174,6,247,4,0,
174,6,263,4,0,
174,6,273,2,0,
174,6,290,4,0,
174,6,313,2,0,
174,6,351,4,0,
174,6,352,4,0,
174,7,1,1,9,
174,7,5,3,0,
174,7,25,3,0,
174,7,34,3,0,
174,7,38,3,0,
174,7,47,1,1,1
174,7,53,4,0,
174,7,68,3,0,
174,7,69,3,0,
174,7,76,4,0,
174,7,86,3,0,
174,7,91,4,0,
174,7,92,4,0,
174,7,94,4,0,
174,7,102,3,0,
174,7,104,4,0,
174,7,111,1,4,
174,7,113,4,0,
174,7,115,4,0,
174,7,126,4,0,
174,7,138,3,0,
174,7,148,4,0,
174,7,156,4,0,
174,7,164,3,0,
174,7,182,4,0,
174,7,185,2,0,
174,7,186,1,14,
174,7,195,2,0,
174,7,204,1,1,2
174,7,213,4,0,
174,7,216,4,0,
174,7,217,2,0,
174,7,218,4,0,
174,7,219,4,0,
174,7,237,4,0,
174,7,240,4,0,
174,7,241,4,0,
174,7,247,4,0,
174,7,263,4,0,
174,7,273,2,0,
174,7,290,4,0,
174,7,313,2,0,
174,7,351,4,0,
174,7,352,4,0,
174,8,1,1,9,
174,8,47,1,1,1
174,8,53,4,0,
174,8,76,4,0,
174,8,86,4,0,
174,8,91,4,0,
174,8,92,4,0,
174,8,94,4,0,
174,8,104,4,0,
174,8,111,1,5,
174,8,113,4,0,
174,8,115,4,0,
174,8,126,4,0,
174,8,138,4,0,
174,8,148,4,0,
174,8,156,4,0,
174,8,164,4,0,
174,8,182,4,0,
174,8,185,2,0,
174,8,186,1,13,
174,8,195,2,0,
174,8,203,4,0,
174,8,204,1,1,2
174,8,207,4,0,
174,8,213,4,0,
174,8,214,4,0,
174,8,216,4,0,
174,8,217,2,0,
174,8,218,4,0,
174,8,219,4,0,
174,8,237,4,0,
174,8,240,4,0,
174,8,241,4,0,
174,8,244,4,0,
174,8,247,4,0,
174,8,263,4,0,
174,8,273,2,0,
174,8,278,4,0,
174,8,290,4,0,
174,8,313,2,0,
174,8,343,2,0,
174,8,351,4,0,
174,8,352,4,0,
174,8,356,2,0,
174,8,363,4,0,
174,8,374,4,0,
174,8,383,1,17,
174,8,387,2,0,
174,8,445,4,0,
174,8,447,4,0,
174,9,1,1,9,
174,9,47,1,1,1
174,9,53,4,0,
174,9,76,4,0,
174,9,86,4,0,
174,9,91,4,0,
174,9,92,4,0,
174,9,94,4,0,
174,9,104,4,0,
174,9,111,1,5,
174,9,113,4,0,
174,9,115,4,0,
174,9,126,4,0,
174,9,138,4,0,
174,9,148,4,0,
174,9,156,4,0,
174,9,164,4,0,
174,9,173,3,0,
174,9,182,4,0,
174,9,185,2,0,
174,9,186,1,13,
174,9,189,3,0,
174,9,195,2,0,
174,9,196,3,0,
174,9,203,4,0,
174,9,204,1,1,2
174,9,205,3,0,
174,9,207,4,0,
174,9,213,4,0,
174,9,214,4,0,
174,9,216,4,0,
174,9,217,2,0,
174,9,218,4,0,
174,9,219,4,0,
174,9,237,4,0,
174,9,240,4,0,
174,9,241,4,0,
174,9,244,4,0,
174,9,247,4,0,
174,9,253,3,0,
174,9,263,4,0,
174,9,270,3,0,
174,9,273,2,0,
174,9,278,4,0,
174,9,283,3,0,
174,9,290,4,0,
174,9,313,2,0,
174,9,340,3,0,
174,9,343,2,0,
174,9,351,4,0,
174,9,352,4,0,
174,9,356,2,0,
174,9,363,4,0,
174,9,374,4,0,
174,9,383,1,17,
174,9,387,2,0,
174,9,387,3,0,
174,9,445,4,0,
174,9,447,4,0,
174,10,1,1,9,
174,10,29,3,0,
174,10,47,1,1,1
174,10,53,4,0,
174,10,76,4,0,
174,10,86,4,0,
174,10,91,4,0,
174,10,92,4,0,
174,10,94,4,0,
174,10,104,4,0,
174,10,111,1,5,
174,10,113,4,0,
174,10,115,4,0,
174,10,126,4,0,
174,10,138,4,0,
174,10,148,4,0,
174,10,156,4,0,
174,10,164,4,0,
174,10,173,3,0,
174,10,182,4,0,
174,10,185,2,0,
174,10,186,1,13,
174,10,189,3,0,
174,10,195,2,0,
174,10,196,3,0,
174,10,203,4,0,
174,10,204,1,1,2
174,10,205,3,0,
174,10,207,4,0,
174,10,213,4,0,
174,10,214,4,0,
174,10,215,3,0,
174,10,216,4,0,
174,10,217,2,0,
174,10,218,4,0,
174,10,219,4,0,
174,10,220,3,0,
174,10,237,4,0,
174,10,240,4,0,
174,10,241,4,0,
174,10,244,4,0,
174,10,247,4,0,
174,10,253,3,0,
174,10,263,4,0,
174,10,270,3,0,
174,10,272,3,0,
174,10,273,2,0,
174,10,277,3,0,
174,10,278,4,0,
174,10,283,3,0,
174,10,290,4,0,
174,10,313,2,0,
174,10,340,3,0,
174,10,343,2,0,
174,10,351,4,0,
174,10,352,4,0,
174,10,356,2,0,
174,10,356,3,0,
174,10,363,4,0,
174,10,374,4,0,
174,10,383,1,17,
174,10,387,2,0,
174,10,387,3,0,
174,10,445,4,0,
174,10,447,4,0,
174,11,1,1,9,
174,11,47,1,1,1
174,11,53,4,0,
174,11,76,4,0,
174,11,86,4,0,
174,11,91,4,0,
174,11,92,4,0,
174,11,94,4,0,
174,11,104,4,0,
174,11,111,1,5,
174,11,113,4,0,
174,11,115,4,0,
174,11,126,4,0,
174,11,138,4,0,
174,11,148,4,0,
174,11,156,4,0,
174,11,164,4,0,
174,11,182,4,0,
174,11,185,2,0,
174,11,186,1,13,
174,11,195,2,0,
174,11,204,1,1,2
174,11,207,4,0,
174,11,213,4,0,
174,11,214,2,0,
174,11,216,4,0,
174,11,217,2,0,
174,11,218,4,0,
174,11,219,4,0,
174,11,237,4,0,
174,11,240,4,0,
174,11,241,4,0,
174,11,244,4,0,
174,11,247,4,0,
174,11,263,4,0,
174,11,273,2,0,
174,11,313,2,0,
174,11,343,2,0,
174,11,356,2,0,
174,11,374,4,0,
174,11,383,1,17,
174,11,386,2,0,
174,11,387,2,0,
174,11,445,2,0,
174,11,447,4,0,
174,11,496,4,0,
174,11,497,4,0,
174,11,510,4,0,
174,11,526,4,0,
174,11,528,4,0,
174,12,1,1,9,
174,12,47,1,1,1
174,12,53,4,0,
174,12,76,4,0,
174,12,91,4,0,
174,12,92,4,0,
174,12,94,4,0,
174,12,104,4,0,
174,12,111,1,4,
174,12,113,4,0,
174,12,115,4,0,
174,12,126,4,0,
174,12,148,4,0,
174,12,156,4,0,
174,12,182,4,0,
174,12,186,1,14,
174,12,204,1,1,2
174,12,213,4,0,
174,12,216,4,0,
174,12,218,4,0,
174,12,219,4,0,
174,12,237,4,0,
174,12,240,4,0,
174,12,241,4,0,
174,12,247,4,0,
174,12,263,4,0,
174,12,290,4,0,
174,12,351,4,0,
174,12,352,4,0,
174,13,1,1,9,
174,13,34,3,0,
174,13,38,3,0,
174,13,47,1,1,1
174,13,53,4,0,
174,13,69,3,0,
174,13,76,4,0,
174,13,86,3,0,
174,13,91,4,0,
174,13,92,4,0,
174,13,94,4,0,
174,13,102,3,0,
174,13,104,4,0,
174,13,111,1,4,
174,13,113,4,0,
174,13,115,4,0,
174,13,126,4,0,
174,13,138,3,0,
174,13,148,4,0,
174,13,156,4,0,
174,13,164,3,0,
174,13,171,3,0,
174,13,182,4,0,
174,13,186,1,14,
174,13,196,3,0,
174,13,204,1,1,2
174,13,207,3,0,
174,13,213,4,0,
174,13,216,4,0,
174,13,218,4,0,
174,13,219,4,0,
174,13,237,4,0,
174,13,240,4,0,
174,13,241,4,0,
174,13,247,4,0,
174,13,263,4,0,
174,13,290,4,0,
174,13,351,4,0,
174,13,352,4,0,
174,14,1,1,9,
174,14,47,1,1,1
174,14,53,4,0,
174,14,76,4,0,
174,14,86,4,0,
174,14,91,4,0,
174,14,92,4,0,
174,14,94,4,0,
174,14,104,4,0,
174,14,111,1,5,
174,14,113,4,0,
174,14,115,4,0,
174,14,126,4,0,
174,14,138,4,0,
174,14,148,4,0,
174,14,156,4,0,
174,14,164,4,0,
174,14,173,3,0,
174,14,182,4,0,
174,14,185,2,0,
174,14,186,1,13,
174,14,195,2,0,
174,14,196,3,0,
174,14,204,1,1,2
174,14,207,4,0,
174,14,213,4,0,
174,14,214,2,0,
174,14,214,3,0,
174,14,215,3,0,
174,14,216,4,0,
174,14,217,2,0,
174,14,218,4,0,
174,14,219,4,0,
174,14,220,3,0,
174,14,237,4,0,
174,14,240,4,0,
174,14,241,4,0,
174,14,244,4,0,
174,14,247,4,0,
174,14,253,3,0,
174,14,263,4,0,
174,14,270,3,0,
174,14,272,3,0,
174,14,273,2,0,
174,14,277,3,0,
174,14,278,3,0,
174,14,283,3,0,
174,14,304,3,0,
174,14,313,2,0,
174,14,340,3,0,
174,14,343,2,0,
174,14,343,3,0,
174,14,356,2,0,
174,14,356,3,0,
174,14,374,4,0,
174,14,383,1,17,
174,14,386,2,0,
174,14,387,2,0,
174,14,387,3,0,
174,14,445,2,0,
174,14,447,4,0,
174,14,496,4,0,
174,14,497,4,0,
174,14,510,4,0,
174,14,526,4,0,
174,14,528,4,0,
174,15,1,1,9,
174,15,47,1,1,1
174,15,53,4,0,
174,15,76,4,0,
174,15,86,4,0,
174,15,91,4,0,
174,15,92,4,0,
174,15,94,4,0,
174,15,104,4,0,
174,15,111,1,5,
174,15,113,4,0,
174,15,115,4,0,
174,15,126,4,0,
174,15,138,4,0,
174,15,148,4,0,
174,15,156,4,0,
174,15,164,4,0,
174,15,182,4,0,
174,15,185,2,0,
174,15,186,1,13,
174,15,195,2,0,
174,15,204,1,1,2
174,15,207,4,0,
174,15,213,4,0,
174,15,214,2,0,
174,15,214,4,0,
174,15,216,4,0,
174,15,217,2,0,
174,15,218,4,0,
174,15,219,4,0,
174,15,237,4,0,
174,15,240,4,0,
174,15,241,4,0,
174,15,244,4,0,
174,15,247,4,0,
174,15,263,4,0,
174,15,273,2,0,
174,15,313,2,0,
174,15,343,2,0,
174,15,356,2,0,
174,15,374,4,0,
174,15,383,1,17,
174,15,386,2,0,
174,15,387,2,0,
174,15,445,2,0,
174,15,447,4,0,
174,15,496,4,0,
174,15,497,4,0,
174,15,505,2,0,
174,15,510,4,0,
174,15,528,4,0,
174,15,581,2,0,
174,15,590,4,0,
174,16,1,1,5,1
174,16,47,1,1,1
174,16,53,4,0,
174,16,76,4,0,
174,16,86,4,0,
174,16,91,4,0,
174,16,92,4,0,
174,16,94,4,0,
174,16,104,4,0,
174,16,111,1,3,1
174,16,113,4,0,
174,16,115,4,0,
174,16,126,4,0,
174,16,138,4,0,
174,16,148,4,0,
174,16,156,4,0,
174,16,164,4,0,
174,16,173,3,0,
174,16,182,4,0,
174,16,185,2,0,
174,16,186,1,9,1
174,16,195,2,0,
174,16,196,3,0,
174,16,204,1,1,2
174,16,207,4,0,
174,16,213,4,0,
174,16,214,2,0,
174,16,214,4,0,
174,16,215,3,0,
174,16,216,4,0,
174,16,217,2,0,
174,16,218,4,0,
174,16,219,4,0,
174,16,220,3,0,
174,16,237,4,0,
174,16,240,4,0,
174,16,241,4,0,
174,16,244,4,0,
174,16,247,4,0,
174,16,253,3,0,
174,16,263,4,0,
174,16,270,3,0,
174,16,272,3,0,
174,16,273,2,0,
174,16,277,3,0,
174,16,278,3,0,
174,16,283,3,0,
174,16,290,4,0,
174,16,304,3,0,
174,16,313,2,0,
174,16,340,3,0,
174,16,343,2,0,
174,16,343,3,0,
174,16,351,3,0,
174,16,352,3,0,
174,16,356,2,0,
174,16,356,3,0,
174,16,374,4,0,
174,16,383,1,11,1
174,16,386,2,0,
174,16,387,2,0,
174,16,387,3,0,
174,16,445,2,0,
174,16,447,4,0,
174,16,496,4,0,
174,16,497,4,0,
174,16,505,2,0,
174,16,510,4,0,
174,16,528,4,0,
174,16,581,2,0,
174,16,590,4,0,
174,17,1,1,5,
174,17,47,1,1,1
174,17,53,4,0,
174,17,76,4,0,
174,17,86,4,0,
174,17,92,4,0,
174,17,94,4,0,
174,17,104,4,0,
174,17,111,1,3,
174,17,113,4,0,
174,17,115,4,0,
174,17,126,4,0,
174,17,138,4,0,
174,17,156,4,0,
174,17,164,4,0,
174,17,182,4,0,
174,17,185,2,0,
174,17,186,1,9,
174,17,195,2,0,
174,17,204,1,1,2
174,17,207,4,0,
174,17,213,4,0,
174,17,214,2,0,
174,17,214,4,0,
174,17,216,4,0,
174,17,217,2,0,
174,17,218,4,0,
174,17,219,4,0,
174,17,237,4,0,
174,17,240,4,0,
174,17,241,4,0,
174,17,244,4,0,
174,17,247,4,0,
174,17,263,4,0,
174,17,273,2,0,
174,17,313,2,0,
174,17,343,2,0,
174,17,356,2,0,
174,17,374,4,0,
174,17,383,1,11,
174,17,386,2,0,
174,17,387,2,0,
174,17,445,2,0,
174,17,447,4,0,
174,17,496,4,0,
174,17,497,4,0,
174,17,505,2,0,
174,17,526,4,0,
174,17,528,4,0,
174,17,581,2,0,
174,17,590,4,0,
174,18,1,1,5,
174,18,47,1,1,1
174,18,53,4,0,
174,18,76,4,0,
174,18,86,4,0,
174,18,92,4,0,
174,18,94,4,0,
174,18,104,4,0,
174,18,111,1,3,
174,18,113,4,0,
174,18,115,4,0,
174,18,126,4,0,
174,18,138,4,0,
174,18,156,4,0,
174,18,164,4,0,
174,18,182,4,0,
174,18,185,2,0,
174,18,186,1,9,
174,18,195,2,0,
174,18,204,1,1,2
174,18,207,4,0,
174,18,213,4,0,
174,18,214,2,0,
174,18,214,4,0,
174,18,216,4,0,
174,18,217,2,0,
174,18,218,4,0,
174,18,219,4,0,
174,18,237,4,0,
174,18,240,4,0,
174,18,241,4,0,
174,18,244,4,0,
174,18,247,4,0,
174,18,263,4,0,
174,18,273,2,0,
174,18,313,2,0,
174,18,343,2,0,
174,18,356,2,0,
174,18,374,4,0,
174,18,383,1,11,
174,18,386,2,0,
174,18,387,2,0,
174,18,445,2,0,
174,18,447,4,0,
174,18,496,4,0,
174,18,497,4,0,
174,18,505,2,0,
174,18,526,4,0,
174,18,528,4,0,
174,18,581,2,0,
174,18,590,4,0,
175,3,29,4,0,
175,3,38,1,38,
175,3,45,1,1,1
175,3,64,2,0,
175,3,76,4,0,
175,3,92,4,0,
175,3,94,4,0,
175,3,104,4,0,
175,3,111,4,0,
175,3,118,1,7,
175,3,119,2,0,
175,3,126,4,0,
175,3,129,4,0,
175,3,138,4,0,
175,3,148,4,0,
175,3,156,4,0,
175,3,173,4,0,
175,3,174,4,0,
175,3,182,4,0,
175,3,186,1,18,
175,3,189,4,0,
175,3,192,4,0,
175,3,193,2,0,
175,3,197,4,0,
175,3,203,4,0,
175,3,204,1,1,2
175,3,205,4,0,
175,3,207,4,0,
175,3,213,4,0,
175,3,214,4,0,
175,3,216,4,0,
175,3,217,2,0,
175,3,218,4,0,
175,3,219,1,31,
175,3,227,1,25,
175,3,237,4,0,
175,3,240,4,0,
175,3,241,4,0,
175,3,244,4,0,
175,3,247,4,0,
175,3,248,2,0,
175,3,249,4,0,
175,4,29,4,0,
175,4,38,1,38,
175,4,45,1,1,1
175,4,53,3,0,
175,4,64,2,0,
175,4,76,4,0,
175,4,92,4,0,
175,4,94,4,0,
175,4,104,4,0,
175,4,111,4,0,
175,4,118,1,7,
175,4,119,2,0,
175,4,126,4,0,
175,4,129,4,0,
175,4,138,4,0,
175,4,148,4,0,
175,4,156,4,0,
175,4,173,4,0,
175,4,174,4,0,
175,4,182,4,0,
175,4,186,1,18,
175,4,189,4,0,
175,4,192,4,0,
175,4,193,2,0,
175,4,197,4,0,
175,4,203,4,0,
175,4,204,1,1,2
175,4,205,4,0,
175,4,207,4,0,
175,4,213,4,0,
175,4,214,4,0,
175,4,216,4,0,
175,4,217,2,0,
175,4,218,4,0,
175,4,219,1,31,
175,4,227,1,25,
175,4,237,4,0,
175,4,240,4,0,
175,4,241,4,0,
175,4,244,4,0,
175,4,247,4,0,
175,4,248,2,0,
175,4,249,4,0,
175,5,38,1,41,
175,5,45,1,1,1
175,5,53,4,0,
175,5,64,2,0,
175,5,76,4,0,
175,5,92,4,0,
175,5,94,4,0,
175,5,104,4,0,
175,5,113,4,0,
175,5,115,4,0,
175,5,118,1,6,
175,5,119,2,0,
175,5,126,4,0,
175,5,148,4,0,
175,5,156,4,0,
175,5,164,2,0,
175,5,182,4,0,
175,5,186,1,11,
175,5,193,2,0,
175,5,204,1,1,2
175,5,213,4,0,
175,5,216,4,0,
175,5,217,2,0,
175,5,218,4,0,
175,5,219,1,36,
175,5,219,4,0,
175,5,227,1,21,
175,5,237,4,0,
175,5,240,4,0,
175,5,241,4,0,
175,5,244,2,0,
175,5,247,4,0,
175,5,248,2,0,
175,5,249,4,0,
175,5,263,4,0,
175,5,266,1,26,
175,5,273,1,31,
175,5,281,1,16,
175,5,290,4,0,
175,5,351,4,0,
175,5,352,4,0,
175,6,5,3,0,
175,6,25,3,0,
175,6,34,3,0,
175,6,38,1,41,
175,6,38,3,0,
175,6,45,1,1,1
175,6,53,4,0,
175,6,64,2,0,
175,6,68,3,0,
175,6,69,3,0,
175,6,76,4,0,
175,6,86,3,0,
175,6,92,4,0,
175,6,94,4,0,
175,6,102,3,0,
175,6,104,4,0,
175,6,111,3,0,
175,6,113,4,0,
175,6,115,4,0,
175,6,118,1,6,
175,6,118,3,0,
175,6,119,2,0,
175,6,126,4,0,
175,6,129,3,0,
175,6,135,3,0,
175,6,138,3,0,
175,6,148,4,0,
175,6,156,4,0,
175,6,164,2,0,
175,6,164,3,0,
175,6,173,3,0,
175,6,182,4,0,
175,6,186,1,11,
175,6,189,3,0,
175,6,193,2,0,
175,6,203,3,0,
175,6,204,1,1,2
175,6,205,3,0,
175,6,207,3,0,
175,6,213,4,0,
175,6,214,3,0,
175,6,216,4,0,
175,6,217,2,0,
175,6,218,4,0,
175,6,219,1,36,
175,6,219,4,0,
175,6,227,1,21,
175,6,237,4,0,
175,6,240,4,0,
175,6,241,4,0,
175,6,244,2,0,
175,6,244,3,0,
175,6,247,4,0,
175,6,248,2,0,
175,6,249,4,0,
175,6,263,4,0,
175,6,266,1,26,
175,6,273,1,31,
175,6,281,1,16,
175,6,290,4,0,
175,6,351,4,0,
175,6,352,4,0,
175,7,5,3,0,
175,7,25,3,0,
175,7,34,3,0,
175,7,38,1,37,
175,7,38,3,0,
175,7,45,1,1,1
175,7,53,4,0,
175,7,64,2,0,
175,7,68,3,0,
175,7,69,3,0,
175,7,76,4,0,
175,7,86,3,0,
175,7,92,4,0,
175,7,94,4,0,
175,7,102,3,0,
175,7,104,4,0,
175,7,113,4,0,
175,7,115,4,0,
175,7,118,1,4,
175,7,118,3,0,
175,7,119,2,0,
175,7,126,4,0,
175,7,135,3,0,
175,7,138,3,0,
175,7,148,4,0,
175,7,156,4,0,
175,7,164,2,0,
175,7,164,3,0,
175,7,182,4,0,
175,7,186,1,9,
175,7,193,2,0,
175,7,204,1,1,2
175,7,213,4,0,
175,7,216,4,0,
175,7,217,2,0,
175,7,218,4,0,
175,7,219,1,33,
175,7,219,4,0,
175,7,226,1,41,
175,7,227,1,17,
175,7,237,4,0,
175,7,240,4,0,
175,7,241,4,0,
175,7,244,2,0,
175,7,246,1,21,
175,7,247,4,0,
175,7,248,2,0,
175,7,249,4,0,
175,7,263,4,0,
175,7,266,1,25,
175,7,273,1,29,
175,7,281,1,13,
175,7,290,4,0,
175,7,351,4,0,
175,7,352,4,0,
175,8,38,1,46,
175,8,45,1,1,1
175,8,53,4,0,
175,8,64,2,0,
175,8,76,4,0,
175,8,86,4,0,
175,8,92,4,0,
175,8,94,4,0,
175,8,104,4,0,
175,8,113,4,0,
175,8,115,4,0,
175,8,118,1,6,
175,8,119,2,0,
175,8,126,4,0,
175,8,138,4,0,
175,8,148,4,0,
175,8,156,4,0,
175,8,164,2,0,
175,8,164,4,0,
175,8,182,4,0,
175,8,186,1,10,
175,8,193,2,0,
175,8,203,4,0,
175,8,204,1,1,2
175,8,207,4,0,
175,8,213,4,0,
175,8,214,4,0,
175,8,216,4,0,
175,8,217,2,0,
175,8,218,4,0,
175,8,219,1,37,
175,8,219,4,0,
175,8,226,1,42,
175,8,227,1,19,
175,8,237,4,0,
175,8,240,4,0,
175,8,241,4,0,
175,8,244,2,0,
175,8,244,4,0,
175,8,246,1,33,
175,8,247,4,0,
175,8,248,2,0,
175,8,249,4,0,
175,8,263,4,0,
175,8,266,1,24,
175,8,273,1,28,
175,8,281,1,15,
175,8,290,4,0,
175,8,351,4,0,
175,8,352,4,0,
175,8,363,4,0,
175,8,374,4,0,
175,8,375,2,0,
175,8,381,2,0,
175,8,387,1,51,
175,8,417,2,0,
175,8,445,4,0,
175,8,447,4,0,
175,9,38,1,46,
175,9,45,1,1,1
175,9,53,4,0,
175,9,64,2,0,
175,9,76,4,0,
175,9,86,4,0,
175,9,92,4,0,
175,9,94,4,0,
175,9,104,4,0,
175,9,113,4,0,
175,9,115,4,0,
175,9,118,1,6,
175,9,119,2,0,
175,9,126,4,0,
175,9,129,3,0,
175,9,138,4,0,
175,9,148,4,0,
175,9,156,4,0,
175,9,164,2,0,
175,9,164,4,0,
175,9,173,3,0,
175,9,182,4,0,
175,9,186,1,10,
175,9,189,3,0,
175,9,193,2,0,
175,9,203,4,0,
175,9,204,1,1,2
175,9,205,3,0,
175,9,207,4,0,
175,9,213,4,0,
175,9,214,4,0,
175,9,216,4,0,
175,9,217,2,0,
175,9,218,4,0,
175,9,219,1,37,
175,9,219,4,0,
175,9,226,1,42,
175,9,227,1,19,
175,9,237,4,0,
175,9,240,4,0,
175,9,241,4,0,
175,9,244,2,0,
175,9,244,4,0,
175,9,246,1,33,
175,9,246,3,0,
175,9,247,4,0,
175,9,248,2,0,
175,9,249,4,0,
175,9,253,3,0,
175,9,263,4,0,
175,9,266,1,24,
175,9,271,3,0,
175,9,273,1,28,
175,9,281,1,15,
175,9,283,3,0,
175,9,290,4,0,
175,9,324,3,0,
175,9,351,4,0,
175,9,352,4,0,
175,9,363,4,0,
175,9,374,4,0,
175,9,375,2,0,
175,9,381,2,0,
175,9,387,1,51,
175,9,387,3,0,
175,9,417,2,0,
175,9,428,3,0,
175,9,445,4,0,
175,9,447,4,0,
175,10,29,3,0,
175,10,38,1,46,
175,10,45,1,1,1
175,10,53,4,0,
175,10,64,2,0,
175,10,76,4,0,
175,10,86,4,0,
175,10,92,4,0,
175,10,94,4,0,
175,10,104,4,0,
175,10,113,4,0,
175,10,115,4,0,
175,10,118,1,6,
175,10,119,2,0,
175,10,126,4,0,
175,10,129,3,0,
175,10,138,4,0,
175,10,148,4,0,
175,10,156,4,0,
175,10,164,2,0,
175,10,164,4,0,
175,10,173,3,0,
175,10,182,4,0,
175,10,186,1,10,
175,10,189,3,0,
175,10,193,2,0,
175,10,203,4,0,
175,10,204,1,1,2
175,10,205,3,0,
175,10,207,4,0,
175,10,213,4,0,
175,10,214,4,0,
175,10,215,3,0,
175,10,216,4,0,
175,10,217,2,0,
175,10,218,4,0,
175,10,219,1,37,
175,10,219,4,0,
175,10,226,1,42,
175,10,227,1,19,
175,10,237,4,0,
175,10,240,4,0,
175,10,241,4,0,
175,10,244,2,0,
175,10,244,4,0,
175,10,246,1,33,
175,10,246,3,0,
175,10,247,4,0,
175,10,248,2,0,
175,10,249,4,0,
175,10,253,3,0,
175,10,263,4,0,
175,10,266,1,24,
175,10,271,3,0,
175,10,273,1,28,
175,10,277,3,0,
175,10,281,1,15,
175,10,283,3,0,
175,10,290,4,0,
175,10,324,3,0,
175,10,326,2,0,
175,10,351,4,0,
175,10,352,4,0,
175,10,363,4,0,
175,10,374,4,0,
175,10,375,2,0,
175,10,381,2,0,
175,10,387,1,51,
175,10,387,3,0,
175,10,417,2,0,
175,10,428,3,0,
175,10,445,4,0,
175,10,447,4,0,
175,11,38,1,45,
175,11,45,1,1,1
175,11,53,4,0,
175,11,64,2,0,
175,11,76,4,0,
175,11,86,4,0,
175,11,92,4,0,
175,11,94,4,0,
175,11,104,4,0,
175,11,113,4,0,
175,11,115,4,0,
175,11,118,1,5,
175,11,119,2,0,
175,11,126,4,0,
175,11,138,4,0,
175,11,148,4,0,
175,11,156,4,0,
175,11,164,4,0,
175,11,182,4,0,
175,11,186,1,9,
175,11,193,2,0,
175,11,204,1,1,2
175,11,207,4,0,
175,11,213,4,0,
175,11,216,4,0,
175,11,217,2,0,
175,11,218,4,0,
175,11,219,1,37,
175,11,219,4,0,
175,11,226,1,41,
175,11,227,1,17,
175,11,234,2,0,
175,11,237,4,0,
175,11,240,4,0,
175,11,241,4,0,
175,11,244,4,0,
175,11,246,1,33,
175,11,247,4,0,
175,11,248,2,0,
175,11,249,4,0,
175,11,263,4,0,
175,11,266,1,21,
175,11,273,1,29,
175,11,281,1,13,
175,11,290,2,0,
175,11,326,2,0,
175,11,374,4,0,
175,11,375,2,0,
175,11,381,2,0,
175,11,387,1,49,
175,11,417,2,0,
175,11,447,4,0,
175,11,473,4,0,
175,11,477,4,0,
175,11,495,1,53,
175,11,496,4,0,
175,11,497,4,0,
175,11,500,2,0,
175,11,510,4,0,
175,11,516,1,25,
175,11,526,4,0,
175,12,38,1,41,
175,12,45,1,1,1
175,12,53,4,0,
175,12,76,4,0,
175,12,92,4,0,
175,12,94,4,0,
175,12,104,4,0,
175,12,113,4,0,
175,12,115,4,0,
175,12,118,1,6,
175,12,126,4,0,
175,12,148,4,0,
175,12,156,4,0,
175,12,182,4,0,
175,12,186,1,11,
175,12,204,1,1,2
175,12,213,4,0,
175,12,216,4,0,
175,12,218,4,0,
175,12,219,1,36,
175,12,219,4,0,
175,12,227,1,21,
175,12,237,4,0,
175,12,240,4,0,
175,12,241,4,0,
175,12,247,4,0,
175,12,249,4,0,
175,12,263,4,0,
175,12,266,1,26,
175,12,273,1,31,
175,12,281,1,16,
175,12,290,4,0,
175,12,351,4,0,
175,12,352,4,0,
175,13,34,3,0,
175,13,38,1,41,
175,13,38,3,0,
175,13,45,1,1,1
175,13,53,4,0,
175,13,69,3,0,
175,13,76,4,0,
175,13,86,3,0,
175,13,92,4,0,
175,13,94,4,0,
175,13,102,3,0,
175,13,104,4,0,
175,13,113,4,0,
175,13,115,4,0,
175,13,118,1,6,
175,13,126,4,0,
175,13,138,3,0,
175,13,148,4,0,
175,13,156,4,0,
175,13,164,3,0,
175,13,182,4,0,
175,13,186,1,11,
175,13,204,1,1,2
175,13,207,3,0,
175,13,213,4,0,
175,13,216,4,0,
175,13,218,4,0,
175,13,219,1,36,
175,13,219,4,0,
175,13,227,1,21,
175,13,237,4,0,
175,13,240,4,0,
175,13,241,4,0,
175,13,247,4,0,
175,13,249,4,0,
175,13,263,4,0,
175,13,266,1,26,
175,13,273,1,31,
175,13,281,1,16,
175,13,290,4,0,
175,13,351,4,0,
175,13,352,4,0,
175,14,38,1,45,
175,14,45,1,1,1
175,14,53,4,0,
175,14,64,2,0,
175,14,76,4,0,
175,14,86,4,0,
175,14,92,4,0,
175,14,94,4,0,
175,14,104,4,0,
175,14,113,4,0,
175,14,115,4,0,
175,14,118,1,5,
175,14,119,2,0,
175,14,126,4,0,
175,14,138,4,0,
175,14,148,4,0,
175,14,156,4,0,
175,14,164,4,0,
175,14,173,3,0,
175,14,182,4,0,
175,14,186,1,9,
175,14,193,2,0,
175,14,204,1,1,2
175,14,207,4,0,
175,14,213,4,0,
175,14,214,3,0,
175,14,215,3,0,
175,14,216,4,0,
175,14,217,2,0,
175,14,218,4,0,
175,14,219,1,37,
175,14,219,4,0,
175,14,226,1,41,
175,14,227,1,17,
175,14,234,2,0,
175,14,237,4,0,
175,14,240,4,0,
175,14,241,4,0,
175,14,244,4,0,
175,14,246,1,33,
175,14,247,4,0,
175,14,248,2,0,
175,14,249,4,0,
175,14,253,3,0,
175,14,263,4,0,
175,14,266,1,21,
175,14,271,3,0,
175,14,273,1,29,
175,14,277,3,0,
175,14,281,1,13,
175,14,283,3,0,
175,14,290,2,0,
175,14,304,3,0,
175,14,324,3,0,
175,14,326,2,0,
175,14,343,3,0,
175,14,374,4,0,
175,14,375,2,0,
175,14,381,2,0,
175,14,387,1,49,
175,14,387,3,0,
175,14,417,2,0,
175,14,428,3,0,
175,14,447,4,0,
175,14,473,4,0,
175,14,477,4,0,
175,14,495,1,53,
175,14,495,3,0,
175,14,496,4,0,
175,14,497,4,0,
175,14,500,2,0,
175,14,510,4,0,
175,14,516,1,25,
175,14,526,4,0,
175,15,38,1,45,
175,15,45,1,1,1
175,15,53,4,0,
175,15,64,2,0,
175,15,76,4,0,
175,15,86,4,0,
175,15,92,4,0,
175,15,94,4,0,
175,15,104,4,0,
175,15,113,4,0,
175,15,115,4,0,
175,15,118,1,5,
175,15,119,2,0,
175,15,126,4,0,
175,15,138,4,0,
175,15,148,4,0,
175,15,156,4,0,
175,15,164,4,0,
175,15,182,4,0,
175,15,186,1,9,
175,15,193,2,0,
175,15,204,1,1,2
175,15,207,4,0,
175,15,213,4,0,
175,15,214,4,0,
175,15,216,4,0,
175,15,217,2,0,
175,15,218,4,0,
175,15,219,1,37,
175,15,219,4,0,
175,15,226,1,41,
175,15,227,1,17,
175,15,234,2,0,
175,15,237,4,0,
175,15,240,4,0,
175,15,241,4,0,
175,15,244,4,0,
175,15,246,1,33,
175,15,247,4,0,
175,15,248,2,0,
175,15,249,4,0,
175,15,263,4,0,
175,15,266,1,21,
175,15,273,1,29,
175,15,281,1,13,
175,15,290,2,0,
175,15,326,2,0,
175,15,374,4,0,
175,15,375,2,0,
175,15,381,2,0,
175,15,387,1,49,
175,15,417,2,0,
175,15,447,4,0,
175,15,473,4,0,
175,15,495,1,53,
175,15,496,4,0,
175,15,497,4,0,
175,15,500,2,0,
175,15,510,4,0,
175,15,516,1,25,
175,15,590,4,0,
175,15,605,4,0,
175,16,38,1,45,1
175,16,45,1,1,1
175,16,53,4,0,
175,16,64,2,0,
175,16,76,4,0,
175,16,86,4,0,
175,16,92,4,0,
175,16,94,4,0,
175,16,104,4,0,
175,16,113,4,0,
175,16,115,4,0,
175,16,118,1,5,1
175,16,119,2,0,
175,16,126,4,0,
175,16,138,4,0,
175,16,148,4,0,
175,16,156,4,0,
175,16,164,4,0,
175,16,173,3,0,
175,16,182,4,0,
175,16,186,1,9,1
175,16,193,2,0,
175,16,204,1,1,2
175,16,207,4,0,
175,16,213,4,0,
175,16,214,4,0,
175,16,215,3,0,
175,16,216,4,0,
175,16,217,2,0,
175,16,218,4,0,
175,16,219,1,37,1
175,16,219,4,0,
175,16,226,1,41,1
175,16,227,1,17,1
175,16,234,2,0,
175,16,237,4,0,
175,16,240,4,0,
175,16,241,4,0,
175,16,244,4,0,
175,16,246,1,33,1
175,16,247,4,0,
175,16,248,2,0,
175,16,249,4,0,
175,16,253,3,0,
175,16,263,4,0,
175,16,266,1,21,1
175,16,271,3,0,
175,16,273,1,29,1
175,16,277,3,0,
175,16,281,1,13,1
175,16,283,3,0,
175,16,290,2,0,
175,16,290,4,0,
175,16,304,3,0,
175,16,324,3,0,
175,16,326,2,0,
175,16,343,3,0,
175,16,351,3,0,
175,16,352,3,0,
175,16,374,4,0,
175,16,375,2,0,
175,16,381,2,0,
175,16,387,1,49,1
175,16,387,3,0,
175,16,417,2,0,
175,16,428,3,0,
175,16,447,4,0,
175,16,473,4,0,
175,16,495,1,53,1
175,16,495,3,0,
175,16,496,4,0,
175,16,497,4,0,
175,16,500,2,0,
175,16,510,4,0,
175,16,516,1,25,1
175,16,590,4,0,
175,16,605,4,0,
175,17,38,1,45,
175,17,45,1,1,1
175,17,53,4,0,
175,17,64,2,0,
175,17,76,4,0,
175,17,86,4,0,
175,17,92,4,0,
175,17,94,4,0,
175,17,104,4,0,
175,17,113,4,0,
175,17,115,4,0,
175,17,118,1,5,
175,17,119,2,0,
175,17,126,4,0,
175,17,138,4,0,
175,17,156,4,0,
175,17,164,4,0,
175,17,182,4,0,
175,17,186,1,9,
175,17,193,2,0,
175,17,204,1,1,2
175,17,207,4,0,
175,17,213,4,0,
175,17,214,4,0,
175,17,216,4,0,
175,17,217,2,0,
175,17,218,4,0,
175,17,219,1,37,
175,17,219,4,0,
175,17,226,1,41,
175,17,227,1,17,
175,17,234,2,0,
175,17,237,4,0,
175,17,240,4,0,
175,17,241,4,0,
175,17,244,4,0,
175,17,246,1,33,
175,17,247,4,0,
175,17,248,2,0,
175,17,263,4,0,
175,17,266,1,21,
175,17,273,1,29,
175,17,281,1,13,
175,17,290,2,0,
175,17,326,2,0,
175,17,374,4,0,
175,17,375,2,0,
175,17,381,2,0,
175,17,387,1,49,
175,17,417,2,0,
175,17,447,4,0,
175,17,473,4,0,
175,17,495,1,53,
175,17,496,4,0,
175,17,497,4,0,
175,17,500,2,0,
175,17,516,1,25,
175,17,526,4,0,
175,17,590,4,0,
175,17,605,4,0,
175,18,38,1,45,
175,18,45,1,1,1
175,18,53,4,0,
175,18,64,2,0,
175,18,76,4,0,
175,18,86,4,0,
175,18,92,4,0,
175,18,94,4,0,
175,18,104,4,0,
175,18,113,4,0,
175,18,115,4,0,
175,18,118,1,5,
175,18,119,2,0,
175,18,126,4,0,
175,18,138,4,0,
175,18,156,4,0,
175,18,164,4,0,
175,18,182,4,0,
175,18,186,1,9,
175,18,193,2,0,
175,18,204,1,1,2
175,18,207,4,0,
175,18,213,4,0,
175,18,214,4,0,
175,18,216,4,0,
175,18,217,2,0,
175,18,218,4,0,
175,18,219,1,37,
175,18,219,4,0,
175,18,226,1,41,
175,18,227,1,17,
175,18,234,2,0,
175,18,237,4,0,
175,18,240,4,0,
175,18,241,4,0,
175,18,244,4,0,
175,18,246,1,33,
175,18,247,4,0,
175,18,248,2,0,
175,18,263,4,0,
175,18,266,1,21,
175,18,273,1,29,
175,18,281,1,13,
175,18,290,2,0,
175,18,326,2,0,
175,18,374,4,0,
175,18,375,2,0,
175,18,381,2,0,
175,18,387,1,49,
175,18,417,2,0,
175,18,447,4,0,
175,18,473,4,0,
175,18,495,1,53,
175,18,496,4,0,
175,18,497,4,0,
175,18,500,2,0,
175,18,516,1,25,
175,18,526,4,0,
175,18,590,4,0,
175,18,605,4,0,
176,3,19,4,0,
176,3,29,4,0,
176,3,38,1,38,
176,3,45,1,1,1
176,3,63,4,0,
176,3,76,4,0,
176,3,92,4,0,
176,3,94,4,0,
176,3,104,4,0,
176,3,111,4,0,
176,3,118,1,7,
176,3,126,4,0,
176,3,129,4,0,
176,3,138,4,0,
176,3,148,4,0,
176,3,156,4,0,
176,3,173,4,0,
176,3,174,4,0,
176,3,182,4,0,
176,3,186,1,18,
176,3,189,4,0,
176,3,192,4,0,
176,3,197,4,0,
176,3,203,4,0,
176,3,204,1,1,2
176,3,205,4,0,
176,3,207,4,0,
176,3,211,4,0,
176,3,213,4,0,
176,3,214,4,0,
176,3,216,4,0,
176,3,218,4,0,
176,3,219,1,31,
176,3,227,1,25,
176,3,237,4,0,
176,3,240,4,0,
176,3,241,4,0,
176,3,244,4,0,
176,3,247,4,0,
176,3,249,4,0,
176,4,19,4,0,
176,4,29,4,0,
176,4,38,1,38,
176,4,45,1,1,1
176,4,53,3,0,
176,4,63,4,0,
176,4,76,4,0,
176,4,92,4,0,
176,4,94,4,0,
176,4,104,4,0,
176,4,111,4,0,
176,4,118,1,7,
176,4,126,4,0,
176,4,129,4,0,
176,4,138,4,0,
176,4,148,4,0,
176,4,156,4,0,
176,4,173,4,0,
176,4,174,4,0,
176,4,182,4,0,
176,4,186,1,18,
176,4,189,4,0,
176,4,192,4,0,
176,4,197,4,0,
176,4,203,4,0,
176,4,204,1,1,2
176,4,205,4,0,
176,4,207,4,0,
176,4,211,4,0,
176,4,213,4,0,
176,4,214,4,0,
176,4,216,4,0,
176,4,218,4,0,
176,4,219,1,31,
176,4,227,1,25,
176,4,237,4,0,
176,4,240,4,0,
176,4,241,4,0,
176,4,244,4,0,
176,4,247,4,0,
176,4,249,4,0,
176,5,19,4,0,
176,5,38,1,41,
176,5,45,1,1,1
176,5,53,4,0,
176,5,63,4,0,
176,5,76,4,0,
176,5,92,4,0,
176,5,94,4,0,
176,5,104,4,0,
176,5,113,4,0,
176,5,115,4,0,
176,5,118,1,6,
176,5,126,4,0,
176,5,148,4,0,
176,5,156,4,0,
176,5,182,4,0,
176,5,186,1,11,
176,5,204,1,1,2
176,5,211,4,0,
176,5,213,4,0,
176,5,216,4,0,
176,5,218,4,0,
176,5,219,1,36,
176,5,219,4,0,
176,5,227,1,21,
176,5,237,4,0,
176,5,240,4,0,
176,5,241,4,0,
176,5,247,4,0,
176,5,249,4,0,
176,5,263,4,0,
176,5,264,4,0,
176,5,266,1,26,
176,5,273,1,31,
176,5,280,4,0,
176,5,281,1,16,
176,5,290,4,0,
176,5,332,4,0,
176,5,351,4,0,
176,5,352,4,0,
176,6,5,3,0,
176,6,19,4,0,
176,6,25,3,0,
176,6,34,3,0,
176,6,38,1,41,
176,6,38,3,0,
176,6,45,1,1,1
176,6,53,4,0,
176,6,63,4,0,
176,6,68,3,0,
176,6,69,3,0,
176,6,76,4,0,
176,6,86,3,0,
176,6,92,4,0,
176,6,94,4,0,
176,6,102,3,0,
176,6,104,4,0,
176,6,111,3,0,
176,6,113,4,0,
176,6,115,4,0,
176,6,118,1,6,
176,6,118,3,0,
176,6,126,4,0,
176,6,129,3,0,
176,6,135,3,0,
176,6,138,3,0,
176,6,148,4,0,
176,6,156,4,0,
176,6,164,3,0,
176,6,173,3,0,
176,6,182,4,0,
176,6,186,1,11,
176,6,189,3,0,
176,6,203,3,0,
176,6,204,1,1,2
176,6,205,3,0,
176,6,207,3,0,
176,6,211,4,0,
176,6,213,4,0,
176,6,214,3,0,
176,6,216,4,0,
176,6,218,4,0,
176,6,219,1,36,
176,6,219,4,0,
176,6,227,1,21,
176,6,237,4,0,
176,6,240,4,0,
176,6,241,4,0,
176,6,244,3,0,
176,6,247,4,0,
176,6,249,4,0,
176,6,263,4,0,
176,6,264,4,0,
176,6,266,1,26,
176,6,273,1,31,
176,6,280,4,0,
176,6,281,1,16,
176,6,290,4,0,
176,6,332,4,0,
176,6,351,4,0,
176,6,352,4,0,
176,7,5,3,0,
176,7,19,4,0,
176,7,25,3,0,
176,7,34,3,0,
176,7,38,1,37,
176,7,38,3,0,
176,7,45,1,1,2
176,7,53,4,0,
176,7,63,4,0,
176,7,68,3,0,
176,7,69,3,0,
176,7,76,4,0,
176,7,86,3,0,
176,7,92,4,0,
176,7,94,4,0,
176,7,102,3,0,
176,7,104,4,0,
176,7,113,4,0,
176,7,115,4,0,
176,7,118,1,1,4
176,7,118,1,4,
176,7,118,3,0,
176,7,126,4,0,
176,7,135,3,0,
176,7,138,3,0,
176,7,148,4,0,
176,7,156,4,0,
176,7,164,3,0,
176,7,182,4,0,
176,7,186,1,1,5
176,7,186,1,9,
176,7,204,1,1,3
176,7,211,4,0,
176,7,213,4,0,
176,7,216,4,0,
176,7,218,4,0,
176,7,219,1,33,
176,7,219,4,0,
176,7,226,1,41,
176,7,227,1,17,
176,7,237,4,0,
176,7,240,4,0,
176,7,241,4,0,
176,7,246,1,21,
176,7,247,4,0,
176,7,249,4,0,
176,7,263,4,0,
176,7,264,4,0,
176,7,266,1,25,
176,7,273,1,29,
176,7,280,4,0,
176,7,281,1,13,
176,7,290,4,0,
176,7,332,4,0,
176,7,345,1,1,1
176,7,351,4,0,
176,7,352,4,0,
176,8,19,4,0,
176,8,38,1,46,
176,8,45,1,1,2
176,8,53,4,0,
176,8,63,4,0,
176,8,76,4,0,
176,8,86,4,0,
176,8,92,4,0,
176,8,94,4,0,
176,8,104,4,0,
176,8,113,4,0,
176,8,115,4,0,
176,8,118,1,1,4
176,8,118,1,6,
176,8,126,4,0,
176,8,138,4,0,
176,8,148,4,0,
176,8,156,4,0,
176,8,164,4,0,
176,8,182,4,0,
176,8,186,1,1,5
176,8,186,1,10,
176,8,203,4,0,
176,8,204,1,1,3
176,8,207,4,0,
176,8,211,4,0,
176,8,213,4,0,
176,8,214,4,0,
176,8,216,4,0,
176,8,218,4,0,
176,8,219,1,37,
176,8,219,4,0,
176,8,226,1,42,
176,8,227,1,19,
176,8,237,4,0,
176,8,240,4,0,
176,8,241,4,0,
176,8,244,4,0,
176,8,246,1,33,
176,8,247,4,0,
176,8,249,4,0,
176,8,263,4,0,
176,8,264,4,0,
176,8,266,1,24,
176,8,273,1,28,
176,8,280,4,0,
176,8,281,1,15,
176,8,290,4,0,
176,8,318,4,0,
176,8,332,4,0,
176,8,345,1,1,1
176,8,351,4,0,
176,8,352,4,0,
176,8,355,4,0,
176,8,363,4,0,
176,8,374,4,0,
176,8,387,1,51,
176,8,409,4,0,
176,8,416,4,0,
176,8,432,4,0,
176,8,445,4,0,
176,8,447,4,0,
176,9,19,4,0,
176,9,38,1,46,
176,9,45,1,1,2
176,9,53,4,0,
176,9,63,4,0,
176,9,76,4,0,
176,9,86,4,0,
176,9,92,4,0,
176,9,94,4,0,
176,9,104,4,0,
176,9,113,4,0,
176,9,115,4,0,
176,9,118,1,1,4
176,9,118,1,6,
176,9,126,4,0,
176,9,129,3,0,
176,9,138,4,0,
176,9,148,4,0,
176,9,156,4,0,
176,9,164,4,0,
176,9,173,3,0,
176,9,182,4,0,
176,9,186,1,1,5
176,9,186,1,10,
176,9,189,3,0,
176,9,203,4,0,
176,9,204,1,1,3
176,9,205,3,0,
176,9,207,4,0,
176,9,211,4,0,
176,9,213,4,0,
176,9,214,4,0,
176,9,216,4,0,
176,9,218,4,0,
176,9,219,1,37,
176,9,219,4,0,
176,9,226,1,42,
176,9,227,1,19,
176,9,237,4,0,
176,9,239,3,0,
176,9,240,4,0,
176,9,241,4,0,
176,9,244,4,0,
176,9,246,1,33,
176,9,246,3,0,
176,9,247,4,0,
176,9,249,4,0,
176,9,257,3,0,
176,9,263,4,0,
176,9,264,4,0,
176,9,266,1,24,
176,9,271,3,0,
176,9,273,1,28,
176,9,280,4,0,
176,9,281,1,15,
176,9,283,3,0,
176,9,290,4,0,
176,9,314,3,0,
176,9,318,4,0,
176,9,324,3,0,
176,9,332,4,0,
176,9,345,1,1,1
176,9,351,4,0,
176,9,352,4,0,
176,9,355,4,0,
176,9,363,4,0,
176,9,374,4,0,
176,9,387,1,51,
176,9,387,3,0,
176,9,409,4,0,
176,9,416,4,0,
176,9,428,3,0,
176,9,432,4,0,
176,9,445,4,0,
176,9,447,4,0,
176,9,466,3,0,
176,10,19,4,0,
176,10,29,3,0,
176,10,38,1,46,
176,10,45,1,1,2
176,10,53,4,0,
176,10,63,4,0,
176,10,76,4,0,
176,10,86,4,0,
176,10,92,4,0,
176,10,94,4,0,
176,10,104,4,0,
176,10,113,4,0,
176,10,115,4,0,
176,10,118,1,1,4
176,10,118,1,6,
176,10,126,4,0,
176,10,129,3,0,
176,10,138,4,0,
176,10,148,4,0,
176,10,156,4,0,
176,10,164,4,0,
176,10,173,3,0,
176,10,182,4,0,
176,10,186,1,1,5
176,10,186,1,10,
176,10,189,3,0,
176,10,203,4,0,
176,10,204,1,1,3
176,10,205,3,0,
176,10,207,4,0,
176,10,211,4,0,
176,10,213,4,0,
176,10,214,4,0,
176,10,215,3,0,
176,10,216,4,0,
176,10,218,4,0,
176,10,219,1,37,
176,10,219,4,0,
176,10,226,1,42,
176,10,227,1,19,
176,10,237,4,0,
176,10,239,3,0,
176,10,240,4,0,
176,10,241,4,0,
176,10,244,4,0,
176,10,246,1,33,
176,10,246,3,0,
176,10,247,4,0,
176,10,249,4,0,
176,10,257,3,0,
176,10,263,4,0,
176,10,264,4,0,
176,10,266,1,24,
176,10,271,3,0,
176,10,273,1,28,
176,10,277,3,0,
176,10,280,4,0,
176,10,281,1,15,
176,10,283,3,0,
176,10,290,4,0,
176,10,314,3,0,
176,10,318,4,0,
176,10,324,3,0,
176,10,332,4,0,
176,10,345,1,1,1
176,10,351,4,0,
176,10,352,4,0,
176,10,355,4,0,
176,10,363,4,0,
176,10,366,3,0,
176,10,374,4,0,
176,10,387,1,51,
176,10,387,3,0,
176,10,409,4,0,
176,10,416,4,0,
176,10,428,3,0,
176,10,445,4,0,
176,10,447,4,0,
176,10,466,3,0,
176,11,19,4,0,
176,11,38,1,45,
176,11,45,1,1,2
176,11,53,4,0,
176,11,63,4,0,
176,11,76,4,0,
176,11,86,4,0,
176,11,92,4,0,
176,11,94,4,0,
176,11,104,4,0,
176,11,113,4,0,
176,11,115,4,0,
176,11,118,1,1,4
176,11,118,1,5,
176,11,126,4,0,
176,11,138,4,0,
176,11,148,4,0,
176,11,156,4,0,
176,11,164,4,0,
176,11,182,4,0,
176,11,186,1,1,5
176,11,186,1,9,
176,11,204,1,1,3
176,11,207,4,0,
176,11,213,4,0,
176,11,216,4,0,
176,11,218,4,0,
176,11,219,1,37,
176,11,219,4,0,
176,11,226,1,41,
176,11,227,1,17,
176,11,237,4,0,
176,11,240,4,0,
176,11,241,4,0,
176,11,244,4,0,
176,11,246,1,33,
176,11,247,4,0,
176,11,249,4,0,
176,11,263,4,0,
176,11,266,1,21,
176,11,273,1,29,
176,11,280,4,0,
176,11,281,1,13,
176,11,332,4,0,
176,11,345,1,1,1
176,11,374,4,0,
176,11,387,1,49,
176,11,416,4,0,
176,11,447,4,0,
176,11,473,4,0,
176,11,477,4,0,
176,11,495,1,53,
176,11,496,4,0,
176,11,497,4,0,
176,11,510,4,0,
176,11,514,4,0,
176,11,516,1,25,
176,11,526,4,0,
176,12,19,4,0,
176,12,38,1,41,
176,12,45,1,1,1
176,12,53,4,0,
176,12,63,4,0,
176,12,76,4,0,
176,12,92,4,0,
176,12,94,4,0,
176,12,104,4,0,
176,12,113,4,0,
176,12,115,4,0,
176,12,118,1,6,
176,12,126,4,0,
176,12,148,4,0,
176,12,156,4,0,
176,12,182,4,0,
176,12,186,1,11,
176,12,204,1,1,2
176,12,211,4,0,
176,12,213,4,0,
176,12,216,4,0,
176,12,218,4,0,
176,12,219,1,36,
176,12,219,4,0,
176,12,227,1,21,
176,12,237,4,0,
176,12,240,4,0,
176,12,241,4,0,
176,12,247,4,0,
176,12,249,4,0,
176,12,263,4,0,
176,12,264,4,0,
176,12,266,1,26,
176,12,273,1,31,
176,12,280,4,0,
176,12,281,1,16,
176,12,290,4,0,
176,12,332,4,0,
176,12,351,4,0,
176,12,352,4,0,
176,13,19,4,0,
176,13,34,3,0,
176,13,38,1,41,
176,13,38,3,0,
176,13,45,1,1,1
176,13,53,4,0,
176,13,63,4,0,
176,13,69,3,0,
176,13,76,4,0,
176,13,86,3,0,
176,13,92,4,0,
176,13,94,4,0,
176,13,102,3,0,
176,13,104,4,0,
176,13,113,4,0,
176,13,115,4,0,
176,13,118,1,6,
176,13,126,4,0,
176,13,138,3,0,
176,13,143,3,0,
176,13,148,4,0,
176,13,156,4,0,
176,13,164,3,0,
176,13,182,4,0,
176,13,186,1,11,
176,13,204,1,1,2
176,13,207,3,0,
176,13,211,4,0,
176,13,213,4,0,
176,13,216,4,0,
176,13,218,4,0,
176,13,219,1,36,
176,13,219,4,0,
176,13,227,1,21,
176,13,237,4,0,
176,13,240,4,0,
176,13,241,4,0,
176,13,247,4,0,
176,13,249,4,0,
176,13,263,4,0,
176,13,264,4,0,
176,13,266,1,26,
176,13,273,1,31,
176,13,280,4,0,
176,13,281,1,16,
176,13,290,4,0,
176,13,332,4,0,
176,13,351,4,0,
176,13,352,4,0,
176,14,19,4,0,
176,14,38,1,45,
176,14,45,1,1,2
176,14,53,4,0,
176,14,63,4,0,
176,14,76,4,0,
176,14,86,4,0,
176,14,92,4,0,
176,14,94,4,0,
176,14,104,4,0,
176,14,113,4,0,
176,14,115,4,0,
176,14,118,1,1,4
176,14,118,1,5,
176,14,126,4,0,
176,14,138,4,0,
176,14,148,4,0,
176,14,156,4,0,
176,14,164,4,0,
176,14,173,3,0,
176,14,182,4,0,
176,14,186,1,1,5
176,14,186,1,9,
176,14,204,1,1,3
176,14,207,4,0,
176,14,213,4,0,
176,14,214,3,0,
176,14,215,3,0,
176,14,216,4,0,
176,14,218,4,0,
176,14,219,1,37,
176,14,219,4,0,
176,14,226,1,41,
176,14,227,1,17,
176,14,237,4,0,
176,14,240,4,0,
176,14,241,4,0,
176,14,244,4,0,
176,14,246,1,33,
176,14,247,4,0,
176,14,249,4,0,
176,14,257,3,0,
176,14,263,4,0,
176,14,266,1,21,
176,14,271,3,0,
176,14,273,1,29,
176,14,277,3,0,
176,14,280,4,0,
176,14,281,1,13,
176,14,283,3,0,
176,14,304,3,0,
176,14,324,3,0,
176,14,332,4,0,
176,14,343,3,0,
176,14,345,1,1,1
176,14,355,3,0,
176,14,366,3,0,
176,14,374,4,0,
176,14,387,1,49,
176,14,387,3,0,
176,14,409,3,0,
176,14,416,4,0,
176,14,428,3,0,
176,14,447,4,0,
176,14,473,4,0,
176,14,477,4,0,
176,14,495,1,53,
176,14,495,3,0,
176,14,496,4,0,
176,14,497,4,0,
176,14,510,4,0,
176,14,514,4,0,
176,14,516,1,25,
176,14,526,4,0,
176,15,19,4,0,
176,15,38,1,45,
176,15,45,1,1,2
176,15,53,4,0,
176,15,63,4,0,
176,15,76,4,0,
176,15,86,4,0,
176,15,92,4,0,
176,15,94,4,0,
176,15,104,4,0,
176,15,113,4,0,
176,15,115,4,0,
176,15,118,1,1,4
176,15,118,1,5,
176,15,126,4,0,
176,15,138,4,0,
176,15,148,4,0,
176,15,156,4,0,
176,15,164,4,0,
176,15,182,4,0,
176,15,186,1,1,5
176,15,186,1,9,
176,15,204,1,1,3
176,15,207,4,0,
176,15,211,4,0,
176,15,213,4,0,
176,15,214,4,0,
176,15,216,4,0,
176,15,218,4,0,
176,15,219,1,37,
176,15,219,4,0,
176,15,226,1,41,
176,15,227,1,17,
176,15,237,4,0,
176,15,240,4,0,
176,15,241,4,0,
176,15,244,4,0,
176,15,246,1,33,
176,15,247,4,0,
176,15,249,4,0,
176,15,263,4,0,
176,15,266,1,21,
176,15,273,1,29,
176,15,280,4,0,
176,15,281,1,13,
176,15,332,4,0,
176,15,345,1,1,1
176,15,355,4,0,
176,15,374,4,0,
176,15,387,1,49,
176,15,416,4,0,
176,15,447,4,0,
176,15,473,4,0,
176,15,495,1,53,
176,15,496,4,0,
176,15,497,4,0,
176,15,510,4,0,
176,15,514,4,0,
176,15,516,1,25,
176,15,584,1,14,
176,15,590,4,0,
176,15,605,4,0,
176,16,19,4,0,
176,16,38,1,45,1
176,16,45,1,1,2
176,16,53,4,0,
176,16,63,4,0,
176,16,76,4,0,
176,16,86,4,0,
176,16,92,4,0,
176,16,94,4,0,
176,16,104,4,0,
176,16,113,4,0,
176,16,115,4,0,
176,16,118,1,1,4
176,16,118,1,5,1
176,16,126,4,0,
176,16,138,4,0,
176,16,148,4,0,
176,16,156,4,0,
176,16,164,4,0,
176,16,173,3,0,
176,16,182,4,0,
176,16,186,1,1,5
176,16,186,1,9,1
176,16,204,1,1,3
176,16,207,4,0,
176,16,211,4,0,
176,16,213,4,0,
176,16,214,4,0,
176,16,215,3,0,
176,16,216,4,0,
176,16,218,4,0,
176,16,219,1,37,1
176,16,219,4,0,
176,16,226,1,41,1
176,16,227,1,17,1
176,16,237,4,0,
176,16,240,4,0,
176,16,241,4,0,
176,16,244,4,0,
176,16,246,1,33,1
176,16,247,4,0,
176,16,249,4,0,
176,16,257,3,0,
176,16,263,4,0,
176,16,264,3,0,
176,16,266,1,21,1
176,16,271,3,0,
176,16,273,1,29,1
176,16,277,3,0,
176,16,280,4,0,
176,16,281,1,13,1
176,16,283,3,0,
176,16,290,4,0,
176,16,304,3,0,
176,16,324,3,0,
176,16,332,4,0,
176,16,343,3,0,
176,16,345,1,1,1
176,16,351,3,0,
176,16,352,3,0,
176,16,355,4,0,
176,16,366,3,0,
176,16,374,4,0,
176,16,387,1,49,1
176,16,387,3,0,
176,16,409,3,0,
176,16,416,4,0,
176,16,428,3,0,
176,16,447,4,0,
176,16,473,4,0,
176,16,495,1,53,1
176,16,495,3,0,
176,16,496,4,0,
176,16,497,4,0,
176,16,510,4,0,
176,16,514,4,0,
176,16,516,1,25,1
176,16,584,1,14,1
176,16,590,4,0,
176,16,605,4,0,
176,17,19,4,0,
176,17,38,1,45,
176,17,45,1,1,2
176,17,53,4,0,
176,17,63,4,0,
176,17,76,4,0,
176,17,86,4,0,
176,17,92,4,0,
176,17,94,4,0,
176,17,104,4,0,
176,17,113,4,0,
176,17,115,4,0,
176,17,118,1,1,4
176,17,118,1,5,
176,17,126,4,0,
176,17,138,4,0,
176,17,156,4,0,
176,17,164,4,0,
176,17,182,4,0,
176,17,186,1,1,5
176,17,186,1,9,
176,17,204,1,1,3
176,17,207,4,0,
176,17,211,4,0,
176,17,213,4,0,
176,17,214,4,0,
176,17,216,4,0,
176,17,218,4,0,
176,17,219,1,37,
176,17,219,4,0,
176,17,226,1,41,
176,17,227,1,17,
176,17,237,4,0,
176,17,240,4,0,
176,17,241,4,0,
176,17,244,4,0,
176,17,246,1,33,
176,17,247,4,0,
176,17,263,4,0,
176,17,266,1,21,
176,17,273,1,29,
176,17,280,4,0,
176,17,281,1,13,
176,17,332,4,0,
176,17,345,1,1,1
176,17,355,4,0,
176,17,374,4,0,
176,17,387,1,49,
176,17,416,4,0,
176,17,447,4,0,
176,17,473,4,0,
176,17,495,1,53,
176,17,496,4,0,
176,17,497,4,0,
176,17,516,1,25,
176,17,526,4,0,
176,17,584,1,14,
176,17,590,4,0,
176,17,605,4,0,
176,17,684,4,0,
176,18,19,4,0,
176,18,38,1,45,
176,18,45,1,1,2
176,18,53,4,0,
176,18,63,4,0,
176,18,76,4,0,
176,18,86,4,0,
176,18,92,4,0,
176,18,94,4,0,
176,18,104,4,0,
176,18,113,4,0,
176,18,115,4,0,
176,18,118,1,1,4
176,18,118,1,5,
176,18,126,4,0,
176,18,138,4,0,
176,18,156,4,0,
176,18,164,4,0,
176,18,182,4,0,
176,18,186,1,1,5
176,18,186,1,9,
176,18,204,1,1,3
176,18,207,4,0,
176,18,211,4,0,
176,18,213,4,0,
176,18,214,4,0,
176,18,216,4,0,
176,18,218,4,0,
176,18,219,1,37,
176,18,219,4,0,
176,18,226,1,41,
176,18,227,1,17,
176,18,237,4,0,
176,18,240,4,0,
176,18,241,4,0,
176,18,244,4,0,
176,18,246,1,33,
176,18,247,4,0,
176,18,263,4,0,
176,18,266,1,21,
176,18,273,1,29,
176,18,280,4,0,
176,18,281,1,13,
176,18,332,4,0,
176,18,345,1,1,1
176,18,355,4,0,
176,18,374,4,0,
176,18,387,1,49,
176,18,416,4,0,
176,18,447,4,0,
176,18,473,4,0,
176,18,495,1,53,
176,18,496,4,0,
176,18,497,4,0,
176,18,516,1,25,
176,18,526,4,0,
176,18,584,1,14,
176,18,590,4,0,
176,18,605,4,0,
176,18,684,4,0,
177,3,43,1,1,2
177,3,64,1,1,1
177,3,65,2,0,
177,3,76,4,0,
177,3,92,4,0,
177,3,94,1,50,
177,3,94,4,0,
177,3,98,2,0,
177,3,100,1,20,
177,3,101,1,10,
177,3,104,4,0,
177,3,109,1,40,
177,3,114,2,0,
177,3,129,4,0,
177,3,138,4,0,
177,3,148,4,0,
177,3,156,4,0,
177,3,168,4,0,
177,3,171,4,0,
177,3,173,4,0,
177,3,174,4,0,
177,3,182,4,0,
177,3,185,2,0,
177,3,197,4,0,
177,3,202,4,0,
177,3,203,4,0,
177,3,207,4,0,
177,3,211,2,0,
177,3,213,4,0,
177,3,214,4,0,
177,3,216,4,0,
177,3,218,4,0,
177,3,237,4,0,
177,3,241,4,0,
177,3,244,4,0,
177,3,248,1,30,
177,4,43,1,1,2
177,4,64,1,1,1
177,4,65,2,0,
177,4,76,4,0,
177,4,92,4,0,
177,4,94,1,50,
177,4,94,4,0,
177,4,98,2,0,
177,4,100,1,20,
177,4,101,1,10,
177,4,104,4,0,
177,4,109,1,40,
177,4,114,2,0,
177,4,129,4,0,
177,4,138,4,0,
177,4,148,4,0,
177,4,156,4,0,
177,4,168,4,0,
177,4,171,4,0,
177,4,173,4,0,
177,4,174,4,0,
177,4,182,4,0,
177,4,185,2,0,
177,4,197,4,0,
177,4,202,4,0,
177,4,203,4,0,
177,4,207,4,0,
177,4,211,2,0,
177,4,213,4,0,
177,4,214,4,0,
177,4,216,4,0,
177,4,218,4,0,
177,4,237,4,0,
177,4,241,4,0,
177,4,244,4,0,
177,4,248,1,30,
177,5,43,1,1,2
177,5,64,1,1,1
177,5,65,2,0,
177,5,76,4,0,
177,5,92,4,0,
177,5,94,1,50,
177,5,94,4,0,
177,5,98,2,0,
177,5,100,1,20,
177,5,101,1,10,
177,5,104,4,0,
177,5,109,1,40,
177,5,113,4,0,
177,5,114,2,0,
177,5,115,4,0,
177,5,148,4,0,
177,5,156,4,0,
177,5,168,4,0,
177,5,182,4,0,
177,5,185,2,0,
177,5,202,4,0,
177,5,211,2,0,
177,5,211,4,0,
177,5,213,4,0,
177,5,216,4,0,
177,5,218,4,0,
177,5,237,4,0,
177,5,240,4,0,
177,5,241,4,0,
177,5,244,2,0,
177,5,247,4,0,
177,5,248,1,30,3
177,5,263,4,0,
177,5,273,1,30,
177,5,285,4,0,
177,5,287,2,0,
177,5,290,4,0,
177,5,297,2,0,
177,5,332,4,0,
177,5,347,4,0,
177,6,38,3,0,
177,6,43,1,1,2
177,6,64,1,1,1
177,6,65,2,0,
177,6,76,4,0,
177,6,86,3,0,
177,6,92,4,0,
177,6,94,1,50,
177,6,94,4,0,
177,6,98,2,0,
177,6,100,1,20,
177,6,101,1,10,
177,6,102,3,0,
177,6,104,4,0,
177,6,109,1,40,
177,6,113,4,0,
177,6,114,2,0,
177,6,115,4,0,
177,6,129,3,0,
177,6,138,3,0,
177,6,148,4,0,
177,6,156,4,0,
177,6,164,3,0,
177,6,168,4,0,
177,6,173,3,0,
177,6,182,4,0,
177,6,185,2,0,
177,6,202,4,0,
177,6,203,3,0,
177,6,207,3,0,
177,6,211,2,0,
177,6,211,4,0,
177,6,213,4,0,
177,6,214,3,0,
177,6,216,4,0,
177,6,218,4,0,
177,6,237,4,0,
177,6,240,4,0,
177,6,241,4,0,
177,6,244,2,0,
177,6,244,3,0,
177,6,247,4,0,
177,6,248,1,30,3
177,6,263,4,0,
177,6,273,1,30,
177,6,285,4,0,
177,6,287,2,0,
177,6,290,4,0,
177,6,297,2,0,
177,6,332,4,0,
177,6,347,4,0,
177,7,38,3,0,
177,7,43,1,1,2
177,7,64,1,1,1
177,7,65,2,0,
177,7,76,4,0,
177,7,86,3,0,
177,7,92,4,0,
177,7,94,1,50,
177,7,94,4,0,
177,7,98,2,0,
177,7,100,1,20,
177,7,101,1,10,
177,7,102,3,0,
177,7,104,4,0,
177,7,109,1,40,
177,7,113,4,0,
177,7,114,2,0,
177,7,115,4,0,
177,7,138,3,0,
177,7,148,4,0,
177,7,156,4,0,
177,7,164,3,0,
177,7,168,4,0,
177,7,182,4,0,
177,7,185,2,0,
177,7,202,4,0,
177,7,211,2,0,
177,7,211,4,0,
177,7,213,4,0,
177,7,216,4,0,
177,7,218,4,0,
177,7,237,4,0,
177,7,240,4,0,
177,7,241,4,0,
177,7,244,2,0,
177,7,247,4,0,
177,7,248,1,30,3
177,7,263,4,0,
177,7,273,1,30,
177,7,285,4,0,
177,7,287,2,0,
177,7,290,4,0,
177,7,297,2,0,
177,7,332,4,0,
177,7,347,4,0,
177,8,43,1,1,2
177,8,64,1,1,1
177,8,65,2,0,
177,8,76,4,0,
177,8,86,4,0,
177,8,92,4,0,
177,8,94,1,47,
177,8,94,4,0,
177,8,98,2,0,
177,8,100,1,9,
177,8,101,1,6,
177,8,104,4,0,
177,8,109,1,23,
177,8,113,4,0,
177,8,114,2,0,
177,8,115,4,0,
177,8,138,4,0,
177,8,148,4,0,
177,8,156,4,0,
177,8,164,4,0,
177,8,168,4,0,
177,8,182,4,0,
177,8,185,2,0,
177,8,202,4,0,
177,8,203,4,0,
177,8,207,4,0,
177,8,211,2,0,
177,8,211,4,0,
177,8,213,4,0,
177,8,214,4,0,
177,8,216,4,0,
177,8,218,4,0,
177,8,237,4,0,
177,8,240,4,0,
177,8,241,4,0,
177,8,244,2,0,
177,8,244,4,0,
177,8,247,4,0,
177,8,248,1,36,
177,8,263,4,0,
177,8,273,1,28,
177,8,285,4,0,
177,8,287,2,0,
177,8,290,4,0,
177,8,297,2,0,
177,8,318,4,0,
177,8,332,4,0,
177,8,347,4,0,
177,8,355,4,0,
177,8,357,1,17,
177,8,363,4,0,
177,8,365,4,0,
177,8,369,4,0,
177,8,375,1,33,
177,8,381,1,12,
177,8,382,1,20,
177,8,384,1,44,1
177,8,385,1,44,2
177,8,389,2,0,
177,8,428,2,0,
177,8,433,4,0,
177,8,445,4,0,
177,8,447,4,0,
177,8,466,1,39,
177,9,43,1,1,2
177,9,64,1,1,1
177,9,65,2,0,
177,9,76,4,0,
177,9,86,4,0,
177,9,92,4,0,
177,9,94,1,47,
177,9,94,4,0,
177,9,98,2,0,
177,9,100,1,9,
177,9,101,1,6,
177,9,104,4,0,
177,9,109,1,23,
177,9,113,4,0,
177,9,114,2,0,
177,9,115,4,0,
177,9,129,3,0,
177,9,138,4,0,
177,9,148,4,0,
177,9,156,4,0,
177,9,164,4,0,
177,9,168,4,0,
177,9,173,3,0,
177,9,182,4,0,
177,9,185,2,0,
177,9,202,4,0,
177,9,203,4,0,
177,9,207,4,0,
177,9,211,2,0,
177,9,211,4,0,
177,9,213,4,0,
177,9,214,4,0,
177,9,216,4,0,
177,9,218,4,0,
177,9,237,4,0,
177,9,239,3,0,
177,9,240,4,0,
177,9,241,4,0,
177,9,244,2,0,
177,9,244,4,0,
177,9,247,4,0,
177,9,248,1,36,
177,9,257,3,0,
177,9,263,4,0,
177,9,271,3,0,
177,9,273,1,28,
177,9,285,4,0,
177,9,287,2,0,
177,9,290,4,0,
177,9,297,2,0,
177,9,314,3,0,
177,9,318,4,0,
177,9,324,3,0,
177,9,332,4,0,
177,9,347,4,0,
177,9,355,4,0,
177,9,357,1,17,
177,9,363,4,0,
177,9,365,4,0,
177,9,369,4,0,
177,9,375,1,33,
177,9,381,1,12,
177,9,382,1,20,
177,9,384,1,44,1
177,9,385,1,44,2
177,9,389,2,0,
177,9,389,3,0,
177,9,428,2,0,
177,9,428,3,0,
177,9,433,4,0,
177,9,445,4,0,
177,9,447,4,0,
177,9,466,1,39,
177,9,466,3,0,
177,10,43,1,1,2
177,10,64,1,1,1
177,10,65,2,0,
177,10,76,4,0,
177,10,86,4,0,
177,10,92,4,0,
177,10,94,1,47,
177,10,94,4,0,
177,10,98,2,0,
177,10,100,1,9,
177,10,101,1,6,
177,10,104,4,0,
177,10,109,1,23,
177,10,113,4,0,
177,10,114,2,0,
177,10,115,4,0,
177,10,129,3,0,
177,10,138,4,0,
177,10,148,4,0,
177,10,156,4,0,
177,10,164,4,0,
177,10,168,4,0,
177,10,173,3,0,
177,10,182,4,0,
177,10,185,2,0,
177,10,202,4,0,
177,10,203,4,0,
177,10,207,4,0,
177,10,211,2,0,
177,10,211,4,0,
177,10,213,4,0,
177,10,214,4,0,
177,10,216,4,0,
177,10,218,4,0,
177,10,220,3,0,
177,10,237,4,0,
177,10,239,3,0,
177,10,240,4,0,
177,10,241,4,0,
177,10,244,2,0,
177,10,244,4,0,
177,10,247,4,0,
177,10,248,1,36,
177,10,257,3,0,
177,10,263,4,0,
177,10,271,3,0,
177,10,273,1,28,
177,10,277,3,0,
177,10,285,4,0,
177,10,287,2,0,
177,10,290,4,0,
177,10,297,2,0,
177,10,314,3,0,
177,10,318,4,0,
177,10,324,3,0,
177,10,332,4,0,
177,10,347,4,0,
177,10,355,4,0,
177,10,357,1,17,
177,10,363,4,0,
177,10,365,4,0,
177,10,366,3,0,
177,10,369,4,0,
177,10,375,1,33,
177,10,381,1,12,
177,10,382,1,20,
177,10,384,1,44,1
177,10,385,1,44,2
177,10,389,2,0,
177,10,389,3,0,
177,10,428,2,0,
177,10,428,3,0,
177,10,433,4,0,
177,10,445,4,0,
177,10,447,4,0,
177,10,466,1,39,
177,10,466,3,0,
177,11,43,1,1,2
177,11,64,1,1,1
177,11,65,2,0,
177,11,76,4,0,
177,11,86,4,0,
177,11,92,4,0,
177,11,94,1,50,
177,11,94,4,0,
177,11,98,2,0,
177,11,100,1,9,
177,11,101,1,6,
177,11,104,4,0,
177,11,109,1,23,
177,11,113,4,0,
177,11,114,2,0,
177,11,115,4,0,
177,11,138,4,0,
177,11,148,4,0,
177,11,156,4,0,
177,11,164,4,0,
177,11,168,4,0,
177,11,182,4,0,
177,11,185,2,0,
177,11,207,4,0,
177,11,211,2,0,
177,11,213,4,0,
177,11,216,4,0,
177,11,218,4,0,
177,11,237,4,0,
177,11,240,4,0,
177,11,241,4,0,
177,11,244,4,0,
177,11,247,4,0,
177,11,248,1,36,
177,11,263,4,0,
177,11,273,1,28,
177,11,285,2,0,
177,11,287,2,0,
177,11,297,2,0,
177,11,332,4,0,
177,11,347,4,0,
177,11,355,2,0,
177,11,357,1,17,
177,11,365,4,0,
177,11,369,4,0,
177,11,375,1,33,
177,11,381,1,12,
177,11,382,1,20,
177,11,384,1,47,1
177,11,385,1,47,2
177,11,389,2,0,
177,11,428,2,0,
177,11,433,4,0,
177,11,447,4,0,
177,11,466,1,44,
177,11,473,4,0,
177,11,477,4,0,
177,11,485,2,0,
177,11,496,4,0,
177,11,500,1,39,
177,11,502,4,0,
177,12,43,1,1,2
177,12,64,1,1,1
177,12,76,4,0,
177,12,92,4,0,
177,12,94,1,50,
177,12,94,4,0,
177,12,100,1,20,
177,12,101,1,10,
177,12,104,4,0,
177,12,109,1,40,
177,12,113,4,0,
177,12,115,4,0,
177,12,148,4,0,
177,12,156,4,0,
177,12,168,4,0,
177,12,182,4,0,
177,12,202,4,0,
177,12,211,4,0,
177,12,213,4,0,
177,12,216,4,0,
177,12,218,4,0,
177,12,237,4,0,
177,12,240,4,0,
177,12,241,4,0,
177,12,247,4,0,
177,12,248,1,30,3
177,12,263,4,0,
177,12,273,1,30,
177,12,285,4,0,
177,12,290,4,0,
177,12,332,4,0,
177,12,347,4,0,
177,13,38,3,0,
177,13,43,1,1,2
177,13,64,1,1,1
177,13,76,4,0,
177,13,86,3,0,
177,13,92,4,0,
177,13,94,1,50,
177,13,94,4,0,
177,13,100,1,20,
177,13,101,1,10,
177,13,102,3,0,
177,13,104,4,0,
177,13,109,1,40,
177,13,113,4,0,
177,13,115,4,0,
177,13,138,3,0,
177,13,143,3,0,
177,13,148,4,0,
177,13,156,4,0,
177,13,164,3,0,
177,13,168,4,0,
177,13,171,3,0,
177,13,182,4,0,
177,13,202,4,0,
177,13,207,3,0,
177,13,211,4,0,
177,13,213,4,0,
177,13,216,4,0,
177,13,218,4,0,
177,13,237,4,0,
177,13,240,4,0,
177,13,241,4,0,
177,13,247,4,0,
177,13,248,1,30,3
177,13,263,4,0,
177,13,273,1,30,
177,13,285,4,0,
177,13,290,4,0,
177,13,332,4,0,
177,13,347,4,0,
177,14,43,1,1,2
177,14,64,1,1,1
177,14,65,2,0,
177,14,76,4,0,
177,14,86,4,0,
177,14,92,4,0,
177,14,94,1,50,
177,14,94,4,0,
177,14,98,2,0,
177,14,100,1,9,
177,14,101,1,6,
177,14,104,4,0,
177,14,109,1,23,
177,14,113,4,0,
177,14,114,2,0,
177,14,115,4,0,
177,14,138,4,0,
177,14,148,4,0,
177,14,156,4,0,
177,14,164,4,0,
177,14,168,4,0,
177,14,173,3,0,
177,14,182,4,0,
177,14,185,2,0,
177,14,202,3,0,
177,14,207,4,0,
177,14,211,2,0,
177,14,213,4,0,
177,14,214,3,0,
177,14,216,4,0,
177,14,218,4,0,
177,14,220,3,0,
177,14,237,4,0,
177,14,240,4,0,
177,14,241,4,0,
177,14,244,4,0,
177,14,247,4,0,
177,14,248,1,36,
177,14,257,3,0,
177,14,263,4,0,
177,14,271,3,0,
177,14,273,1,28,
177,14,277,3,0,
177,14,285,2,0,
177,14,285,3,0,
177,14,287,2,0,
177,14,297,2,0,
177,14,324,3,0,
177,14,332,4,0,
177,14,347,4,0,
177,14,355,2,0,
177,14,355,3,0,
177,14,357,1,17,
177,14,365,4,0,
177,14,366,3,0,
177,14,369,4,0,
177,14,375,1,33,
177,14,381,1,12,
177,14,382,1,20,
177,14,384,1,47,1
177,14,385,1,47,2
177,14,389,2,0,
177,14,428,2,0,
177,14,428,3,0,
177,14,433,4,0,
177,14,447,4,0,
177,14,466,1,44,
177,14,473,4,0,
177,14,477,4,0,
177,14,478,3,0,
177,14,485,2,0,
177,14,496,4,0,
177,14,500,1,39,
177,14,502,4,0,
177,15,43,1,1,2
177,15,64,1,1,1
177,15,65,2,0,
177,15,76,4,0,
177,15,86,4,0,
177,15,92,4,0,
177,15,94,1,50,
177,15,94,4,0,
177,15,98,2,0,
177,15,100,1,9,
177,15,101,1,6,
177,15,104,4,0,
177,15,109,1,23,
177,15,113,4,0,
177,15,114,2,0,
177,15,115,4,0,
177,15,138,4,0,
177,15,148,4,0,
177,15,156,4,0,
177,15,164,4,0,
177,15,168,4,0,
177,15,182,4,0,
177,15,185,2,0,
177,15,207,4,0,
177,15,211,2,0,
177,15,211,4,0,
177,15,213,4,0,
177,15,214,4,0,
177,15,216,4,0,
177,15,218,4,0,
177,15,237,4,0,
177,15,240,4,0,
177,15,241,4,0,
177,15,244,4,0,
177,15,247,4,0,
177,15,248,1,36,
177,15,263,4,0,
177,15,273,1,28,
177,15,285,2,0,
177,15,287,2,0,
177,15,297,2,0,
177,15,332,4,0,
177,15,347,4,0,
177,15,355,2,0,
177,15,355,4,0,
177,15,357,1,17,
177,15,369,4,0,
177,15,375,1,33,
177,15,381,1,12,
177,15,382,1,20,
177,15,384,1,47,1
177,15,385,1,47,2
177,15,389,2,0,
177,15,428,2,0,
177,15,433,4,0,
177,15,447,4,0,
177,15,466,1,44,
177,15,473,4,0,
177,15,485,2,0,
177,15,493,2,0,
177,15,496,4,0,
177,15,500,1,39,
177,15,502,2,0,
177,15,590,4,0,
177,15,605,4,0,
177,16,43,1,1,2
177,16,64,1,1,1
177,16,65,2,0,
177,16,76,4,0,
177,16,86,4,0,
177,16,92,4,0,
177,16,94,1,33,1
177,16,94,4,0,
177,16,98,2,0,
177,16,100,1,9,1
177,16,101,1,6,1
177,16,104,4,0,
177,16,109,1,23,1
177,16,113,4,0,
177,16,114,2,0,
177,16,115,4,0,
177,16,138,4,0,
177,16,143,3,0,
177,16,148,4,0,
177,16,156,4,0,
177,16,164,4,0,
177,16,168,4,0,
177,16,173,3,0,
177,16,182,4,0,
177,16,185,2,0,
177,16,202,3,0,
177,16,207,4,0,
177,16,211,2,0,
177,16,211,4,0,
177,16,213,4,0,
177,16,214,4,0,
177,16,216,4,0,
177,16,218,4,0,
177,16,220,3,0,
177,16,237,4,0,
177,16,240,4,0,
177,16,241,4,0,
177,16,244,4,0,
177,16,247,4,0,
177,16,248,1,44,1
177,16,257,3,0,
177,16,263,4,0,
177,16,271,3,0,
177,16,273,1,28,1
177,16,277,3,0,
177,16,285,2,0,
177,16,285,3,0,
177,16,287,2,0,
177,16,290,4,0,
177,16,297,2,0,
177,16,324,3,0,
177,16,332,4,0,
177,16,347,4,0,
177,16,355,2,0,
177,16,355,4,0,
177,16,357,1,36,1
177,16,366,3,0,
177,16,369,4,0,
177,16,375,1,39,1
177,16,381,1,12,1
177,16,382,1,50,1
177,16,384,1,47,1
177,16,385,1,47,2
177,16,389,2,0,
177,16,428,2,0,
177,16,428,3,0,
177,16,433,4,0,
177,16,447,4,0,
177,16,466,1,20,1
177,16,473,4,0,
177,16,478,3,0,
177,16,485,2,0,
177,16,493,2,0,
177,16,496,4,0,
177,16,500,1,17,1
177,16,502,2,0,
177,16,590,4,0,
177,16,605,4,0,
177,17,43,1,1,2
177,17,64,1,1,1
177,17,65,2,0,
177,17,76,4,0,
177,17,86,4,0,
177,17,92,4,0,
177,17,94,1,33,
177,17,94,4,0,
177,17,98,2,0,
177,17,100,1,9,
177,17,101,1,6,
177,17,104,4,0,
177,17,109,1,23,
177,17,113,4,0,
177,17,114,2,0,
177,17,115,4,0,
177,17,138,4,0,
177,17,156,4,0,
177,17,164,4,0,
177,17,168,4,0,
177,17,182,4,0,
177,17,185,2,0,
177,17,207,4,0,
177,17,211,2,0,
177,17,211,4,0,
177,17,213,4,0,
177,17,214,4,0,
177,17,216,4,0,
177,17,218,4,0,
177,17,237,4,0,
177,17,240,4,0,
177,17,241,4,0,
177,17,244,4,0,
177,17,247,4,0,
177,17,248,1,44,
177,17,263,4,0,
177,17,273,1,28,
177,17,285,2,0,
177,17,287,2,0,
177,17,297,2,0,
177,17,332,4,0,
177,17,347,4,0,
177,17,355,2,0,
177,17,355,4,0,
177,17,357,1,36,
177,17,369,4,0,
177,17,375,1,39,
177,17,381,1,12,
177,17,382,1,50,
177,17,384,1,47,1
177,17,385,1,47,2
177,17,389,2,0,
177,17,428,2,0,
177,17,433,4,0,
177,17,447,4,0,
177,17,466,1,20,
177,17,473,4,0,
177,17,485,2,0,
177,17,493,2,0,
177,17,496,4,0,
177,17,500,1,17,
177,17,502,2,0,
177,17,590,4,0,
177,17,605,4,0,
177,18,43,1,1,2
177,18,64,1,1,1
177,18,65,2,0,
177,18,76,4,0,
177,18,86,4,0,
177,18,92,4,0,
177,18,94,1,33,
177,18,94,4,0,
177,18,98,2,0,
177,18,100,1,9,
177,18,101,1,6,
177,18,104,4,0,
177,18,109,1,23,
177,18,113,4,0,
177,18,114,2,0,
177,18,115,4,0,
177,18,138,4,0,
177,18,156,4,0,
177,18,164,4,0,
177,18,168,4,0,
177,18,182,4,0,
177,18,185,2,0,
177,18,207,4,0,
177,18,211,2,0,
177,18,211,4,0,
177,18,213,4,0,
177,18,214,4,0,
177,18,216,4,0,
177,18,218,4,0,
177,18,237,4,0,
177,18,240,4,0,
177,18,241,4,0,
177,18,244,4,0,
177,18,247,4,0,
177,18,248,1,44,
177,18,263,4,0,
177,18,273,1,28,
177,18,285,2,0,
177,18,287,2,0,
177,18,297,2,0,
177,18,332,4,0,
177,18,347,4,0,
177,18,355,2,0,
177,18,355,4,0,
177,18,357,1,36,
177,18,369,4,0,
177,18,375,1,39,
177,18,381,1,12,
177,18,382,1,50,
177,18,384,1,47,1
177,18,385,1,47,2
177,18,389,2,0,
177,18,428,2,0,
177,18,433,4,0,
177,18,447,4,0,
177,18,466,1,20,
177,18,473,4,0,
177,18,485,2,0,
177,18,493,2,0,
177,18,496,4,0,
177,18,500,1,17,
177,18,502,2,0,
177,18,590,4,0,
177,18,605,4,0,
178,3,19,4,0,
178,3,43,1,1,2
178,3,63,4,0,
178,3,64,1,1,1
178,3,76,4,0,
178,3,92,4,0,
178,3,94,1,65,
178,3,94,4,0,
178,3,100,1,20,
178,3,101,1,1,3
178,3,101,1,10,
178,3,104,4,0,
178,3,109,1,50,
178,3,129,4,0,
178,3,138,4,0,
178,3,148,4,0,
178,3,156,4,0,
178,3,168,4,0,
178,3,171,4,0,
178,3,173,4,0,
178,3,174,4,0,
178,3,182,4,0,
178,3,197,4,0,
178,3,202,4,0,
178,3,203,4,0,
178,3,207,4,0,
178,3,213,4,0,
178,3,214,4,0,
178,3,216,4,0,
178,3,218,4,0,
178,3,237,4,0,
178,3,241,4,0,
178,3,244,4,0,
178,3,248,1,35,
178,4,19,4,0,
178,4,43,1,1,2
178,4,63,4,0,
178,4,64,1,1,1
178,4,76,4,0,
178,4,92,4,0,
178,4,94,1,65,
178,4,94,4,0,
178,4,100,1,20,
178,4,101,1,1,3
178,4,101,1,10,
178,4,104,4,0,
178,4,109,1,50,
178,4,129,4,0,
178,4,138,4,0,
178,4,148,4,0,
178,4,156,4,0,
178,4,168,4,0,
178,4,171,4,0,
178,4,173,4,0,
178,4,174,4,0,
178,4,182,4,0,
178,4,197,4,0,
178,4,202,4,0,
178,4,203,4,0,
178,4,207,4,0,
178,4,213,4,0,
178,4,214,4,0,
178,4,216,4,0,
178,4,218,4,0,
178,4,237,4,0,
178,4,241,4,0,
178,4,244,4,0,
178,4,248,1,35,
178,5,19,4,0,
178,5,43,1,1,2
178,5,63,4,0,
178,5,64,1,1,1
178,5,76,4,0,
178,5,92,4,0,
178,5,94,1,65,
178,5,94,4,0,
178,5,100,1,20,
178,5,101,1,10,
178,5,104,4,0,
178,5,109,1,50,
178,5,113,4,0,
178,5,115,4,0,
178,5,148,4,0,
178,5,156,4,0,
178,5,168,4,0,
178,5,182,4,0,
178,5,202,4,0,
178,5,211,4,0,
178,5,213,4,0,
178,5,216,4,0,
178,5,218,4,0,
178,5,237,4,0,
178,5,240,4,0,
178,5,241,4,0,
178,5,247,4,0,
178,5,248,1,35,3
178,5,263,4,0,
178,5,273,1,35,
178,5,285,4,0,
178,5,290,4,0,
178,5,332,4,0,
178,5,347,4,0,
178,6,19,4,0,
178,6,38,3,0,
178,6,43,1,1,2
178,6,63,4,0,
178,6,64,1,1,1
178,6,76,4,0,
178,6,86,3,0,
178,6,92,4,0,
178,6,94,1,65,
178,6,94,4,0,
178,6,100,1,20,
178,6,101,1,10,
178,6,102,3,0,
178,6,104,4,0,
178,6,109,1,50,
178,6,113,4,0,
178,6,115,4,0,
178,6,129,3,0,
178,6,138,3,0,
178,6,148,4,0,
178,6,156,4,0,
178,6,164,3,0,
178,6,168,4,0,
178,6,173,3,0,
178,6,182,4,0,
178,6,202,4,0,
178,6,203,3,0,
178,6,207,3,0,
178,6,211,4,0,
178,6,213,4,0,
178,6,214,3,0,
178,6,216,4,0,
178,6,218,4,0,
178,6,237,4,0,
178,6,240,4,0,
178,6,241,4,0,
178,6,244,3,0,
178,6,247,4,0,
178,6,248,1,35,3
178,6,263,4,0,
178,6,273,1,35,
178,6,285,4,0,
178,6,290,4,0,
178,6,332,4,0,
178,6,347,4,0,
178,7,19,4,0,
178,7,38,3,0,
178,7,43,1,1,2
178,7,63,4,0,
178,7,64,1,1,1
178,7,76,4,0,
178,7,86,3,0,
178,7,92,4,0,
178,7,94,1,65,
178,7,94,4,0,
178,7,100,1,20,
178,7,101,1,10,
178,7,102,3,0,
178,7,104,4,0,
178,7,109,1,50,
178,7,113,4,0,
178,7,115,4,0,
178,7,138,3,0,
178,7,148,4,0,
178,7,156,4,0,
178,7,164,3,0,
178,7,168,4,0,
178,7,182,4,0,
178,7,202,4,0,
178,7,211,4,0,
178,7,213,4,0,
178,7,216,4,0,
178,7,218,4,0,
178,7,237,4,0,
178,7,240,4,0,
178,7,241,4,0,
178,7,247,4,0,
178,7,248,1,35,3
178,7,263,4,0,
178,7,273,1,35,
178,7,285,4,0,
178,7,290,4,0,
178,7,332,4,0,
178,7,347,4,0,
178,8,19,4,0,
178,8,43,1,1,2
178,8,63,4,0,
178,8,64,1,1,1
178,8,76,4,0,
178,8,86,4,0,
178,8,92,4,0,
178,8,94,1,59,
178,8,94,4,0,
178,8,100,1,9,
178,8,101,1,6,
178,8,104,4,0,
178,8,109,1,23,
178,8,113,4,0,
178,8,115,4,0,
178,8,138,4,0,
178,8,148,4,0,
178,8,156,4,0,
178,8,164,4,0,
178,8,168,4,0,
178,8,182,4,0,
178,8,202,4,0,
178,8,203,4,0,
178,8,207,4,0,
178,8,211,4,0,
178,8,213,4,0,
178,8,214,4,0,
178,8,216,4,0,
178,8,218,4,0,
178,8,237,4,0,
178,8,240,4,0,
178,8,241,4,0,
178,8,244,4,0,
178,8,247,4,0,
178,8,248,1,42,
178,8,263,4,0,
178,8,273,1,30,
178,8,285,4,0,
178,8,290,4,0,
178,8,318,4,0,
178,8,332,4,0,
178,8,347,4,0,
178,8,355,4,0,
178,8,357,1,17,
178,8,363,4,0,
178,8,365,4,0,
178,8,366,1,27,
178,8,369,4,0,
178,8,375,1,37,
178,8,381,1,12,
178,8,382,1,20,
178,8,384,1,54,1
178,8,385,1,54,2
178,8,416,4,0,
178,8,432,4,0,
178,8,433,4,0,
178,8,445,4,0,
178,8,447,4,0,
178,8,466,1,47,
178,9,19,4,0,
178,9,43,1,1,2
178,9,63,4,0,
178,9,64,1,1,1
178,9,76,4,0,
178,9,86,4,0,
178,9,92,4,0,
178,9,94,1,59,
178,9,94,4,0,
178,9,100,1,9,
178,9,101,1,6,
178,9,104,4,0,
178,9,109,1,23,
178,9,113,4,0,
178,9,115,4,0,
178,9,129,3,0,
178,9,138,4,0,
178,9,148,4,0,
178,9,156,4,0,
178,9,164,4,0,
178,9,168,4,0,
178,9,173,3,0,
178,9,182,4,0,
178,9,202,4,0,
178,9,203,4,0,
178,9,207,4,0,
178,9,211,4,0,
178,9,213,4,0,
178,9,214,4,0,
178,9,216,4,0,
178,9,218,4,0,
178,9,237,4,0,
178,9,239,3,0,
178,9,240,4,0,
178,9,241,4,0,
178,9,244,4,0,
178,9,247,4,0,
178,9,248,1,42,
178,9,257,3,0,
178,9,263,4,0,
178,9,271,3,0,
178,9,273,1,30,
178,9,285,4,0,
178,9,290,4,0,
178,9,314,3,0,
178,9,318,4,0,
178,9,324,3,0,
178,9,332,4,0,
178,9,347,4,0,
178,9,355,4,0,
178,9,357,1,17,
178,9,363,4,0,
178,9,365,4,0,
178,9,366,1,27,
178,9,369,4,0,
178,9,375,1,37,
178,9,381,1,12,
178,9,382,1,20,
178,9,384,1,54,1
178,9,385,1,54,2
178,9,389,3,0,
178,9,416,4,0,
178,9,428,3,0,
178,9,432,4,0,
178,9,433,4,0,
178,9,445,4,0,
178,9,447,4,0,
178,9,466,1,47,
178,9,466,3,0,
178,10,19,4,0,
178,10,43,1,1,2
178,10,63,4,0,
178,10,64,1,1,1
178,10,76,4,0,
178,10,86,4,0,
178,10,92,4,0,
178,10,94,1,59,
178,10,94,4,0,
178,10,100,1,9,
178,10,101,1,6,
178,10,104,4,0,
178,10,109,1,23,
178,10,113,4,0,
178,10,115,4,0,
178,10,129,3,0,
178,10,138,4,0,
178,10,143,3,0,
178,10,148,4,0,
178,10,156,4,0,
178,10,164,4,0,
178,10,168,4,0,
178,10,173,3,0,
178,10,182,4,0,
178,10,202,4,0,
178,10,203,4,0,
178,10,207,4,0,
178,10,211,4,0,
178,10,213,4,0,
178,10,214,4,0,
178,10,216,4,0,
178,10,218,4,0,
178,10,220,3,0,
178,10,237,4,0,
178,10,239,3,0,
178,10,240,4,0,
178,10,241,4,0,
178,10,244,4,0,
178,10,247,4,0,
178,10,248,1,42,
178,10,257,3,0,
178,10,263,4,0,
178,10,271,3,0,
178,10,273,1,30,
178,10,277,3,0,
178,10,285,4,0,
178,10,290,4,0,
178,10,314,3,0,
178,10,318,4,0,
178,10,324,3,0,
178,10,332,4,0,
178,10,347,4,0,
178,10,355,4,0,
178,10,357,1,17,
178,10,363,4,0,
178,10,365,4,0,
178,10,366,1,27,
178,10,366,3,0,
178,10,369,4,0,
178,10,375,1,37,
178,10,381,1,12,
178,10,382,1,20,
178,10,384,1,54,1
178,10,385,1,54,2
178,10,389,3,0,
178,10,416,4,0,
178,10,428,3,0,
178,10,433,4,0,
178,10,445,4,0,
178,10,447,4,0,
178,10,466,1,47,
178,10,466,3,0,
178,11,19,4,0,
178,11,43,1,1,2
178,11,63,4,0,
178,11,64,1,1,1
178,11,76,4,0,
178,11,86,4,0,
178,11,92,4,0,
178,11,94,1,66,
178,11,94,4,0,
178,11,100,1,9,
178,11,101,1,6,
178,11,104,4,0,
178,11,109,1,23,
178,11,113,4,0,
178,11,115,4,0,
178,11,138,4,0,
178,11,148,4,0,
178,11,156,4,0,
178,11,164,4,0,
178,11,168,4,0,
178,11,182,4,0,
178,11,207,4,0,
178,11,213,4,0,
178,11,216,4,0,
178,11,218,4,0,
178,11,237,4,0,
178,11,240,4,0,
178,11,241,4,0,
178,11,244,4,0,
178,11,247,4,0,
178,11,248,1,42,
178,11,263,4,0,
178,11,273,1,30,
178,11,332,4,0,
178,11,347,4,0,
178,11,357,1,17,
178,11,365,4,0,
178,11,366,1,27,
178,11,369,4,0,
178,11,375,1,37,
178,11,381,1,12,
178,11,382,1,20,
178,11,384,1,54,2
178,11,385,1,59,
178,11,416,4,0,
178,11,433,4,0,
178,11,447,4,0,
178,11,466,1,54,1
178,11,473,4,0,
178,11,477,4,0,
178,11,496,4,0,
178,11,500,1,47,
178,11,502,4,0,
178,12,19,4,0,
178,12,43,1,1,2
178,12,63,4,0,
178,12,64,1,1,1
178,12,76,4,0,
178,12,92,4,0,
178,12,94,1,65,
178,12,94,4,0,
178,12,100,1,20,
178,12,101,1,10,
178,12,104,4,0,
178,12,109,1,50,
178,12,113,4,0,
178,12,115,4,0,
178,12,148,4,0,
178,12,156,4,0,
178,12,168,4,0,
178,12,182,4,0,
178,12,202,4,0,
178,12,211,4,0,
178,12,213,4,0,
178,12,216,4,0,
178,12,218,4,0,
178,12,237,4,0,
178,12,240,4,0,
178,12,241,4,0,
178,12,247,4,0,
178,12,248,1,35,3
178,12,263,4,0,
178,12,273,1,35,
178,12,285,4,0,
178,12,290,4,0,
178,12,332,4,0,
178,12,347,4,0,
178,13,19,4,0,
178,13,38,3,0,
178,13,43,1,1,2
178,13,63,4,0,
178,13,64,1,1,1
178,13,76,4,0,
178,13,86,3,0,
178,13,92,4,0,
178,13,94,1,65,
178,13,94,4,0,
178,13,100,1,20,
178,13,101,1,10,
178,13,102,3,0,
178,13,104,4,0,
178,13,109,1,50,
178,13,113,4,0,
178,13,115,4,0,
178,13,138,3,0,
178,13,143,3,0,
178,13,148,4,0,
178,13,156,4,0,
178,13,164,3,0,
178,13,168,4,0,
178,13,171,3,0,
178,13,182,4,0,
178,13,202,4,0,
178,13,207,3,0,
178,13,211,4,0,
178,13,213,4,0,
178,13,216,4,0,
178,13,218,4,0,
178,13,237,4,0,
178,13,240,4,0,
178,13,241,4,0,
178,13,247,4,0,
178,13,248,1,35,3
178,13,263,4,0,
178,13,273,1,35,
178,13,285,4,0,
178,13,290,4,0,
178,13,332,4,0,
178,13,347,4,0,
178,14,19,4,0,
178,14,43,1,1,2
178,14,63,4,0,
178,14,64,1,1,1
178,14,76,4,0,
178,14,86,4,0,
178,14,92,4,0,
178,14,94,1,66,
178,14,94,4,0,
178,14,100,1,9,
178,14,101,1,6,
178,14,104,4,0,
178,14,109,1,23,
178,14,113,4,0,
178,14,115,4,0,
178,14,138,4,0,
178,14,143,3,0,
178,14,148,4,0,
178,14,156,4,0,
178,14,164,4,0,
178,14,168,4,0,
178,14,173,3,0,
178,14,182,4,0,
178,14,202,3,0,
178,14,207,4,0,
178,14,213,4,0,
178,14,214,3,0,
178,14,216,4,0,
178,14,218,4,0,
178,14,220,3,0,
178,14,237,4,0,
178,14,240,4,0,
178,14,241,4,0,
178,14,244,4,0,
178,14,247,4,0,
178,14,248,1,42,
178,14,257,3,0,
178,14,263,4,0,
178,14,271,3,0,
178,14,273,1,30,
178,14,277,3,0,
178,14,285,3,0,
178,14,324,3,0,
178,14,332,4,0,
178,14,347,4,0,
178,14,355,3,0,
178,14,357,1,17,
178,14,365,4,0,
178,14,366,1,27,
178,14,366,3,0,
178,14,369,4,0,
178,14,375,1,37,
178,14,381,1,12,
178,14,382,1,20,
178,14,384,1,54,2
178,14,385,1,59,
178,14,416,4,0,
178,14,428,3,0,
178,14,433,4,0,
178,14,447,4,0,
178,14,466,1,54,1
178,14,473,4,0,
178,14,477,4,0,
178,14,478,3,0,
178,14,492,3,0,
178,14,496,4,0,
178,14,500,1,47,
178,14,502,4,0,
178,15,19,4,0,
178,15,43,1,1,2
178,15,63,4,0,
178,15,64,1,1,1
178,15,76,4,0,
178,15,86,4,0,
178,15,92,4,0,
178,15,94,1,66,
178,15,94,4,0,
178,15,100,1,9,
178,15,101,1,6,
178,15,104,4,0,
178,15,109,1,23,
178,15,113,4,0,
178,15,115,4,0,
178,15,138,4,0,
178,15,148,4,0,
178,15,156,4,0,
178,15,164,4,0,
178,15,168,4,0,
178,15,182,4,0,
178,15,207,4,0,
178,15,211,4,0,
178,15,213,4,0,
178,15,214,4,0,
178,15,216,4,0,
178,15,218,4,0,
178,15,237,4,0,
178,15,240,4,0,
178,15,241,4,0,
178,15,244,4,0,
178,15,247,4,0,
178,15,248,1,42,
178,15,263,4,0,
178,15,273,1,30,
178,15,332,4,0,
178,15,347,4,0,
178,15,355,4,0,
178,15,357,1,17,
178,15,366,1,27,
178,15,369,4,0,
178,15,375,1,37,
178,15,381,1,12,
178,15,382,1,20,
178,15,384,1,54,2
178,15,385,1,59,
178,15,416,4,0,
178,15,433,4,0,
178,15,447,4,0,
178,15,466,1,54,1
178,15,473,4,0,
178,15,496,4,0,
178,15,500,1,47,
178,15,590,4,0,
178,15,605,4,0,
178,16,19,4,0,
178,16,43,1,1,3
178,16,63,4,0,
178,16,64,1,1,2
178,16,76,4,0,
178,16,86,4,0,
178,16,92,4,0,
178,16,94,1,35,1
178,16,94,4,0,
178,16,100,1,1,5
178,16,100,1,9,1
178,16,101,1,1,4
178,16,101,1,6,1
178,16,104,4,0,
178,16,109,1,23,1
178,16,113,4,0,
178,16,115,4,0,
178,16,138,4,0,
178,16,143,3,0,
178,16,148,4,0,
178,16,156,4,0,
178,16,164,4,0,
178,16,168,4,0,
178,16,173,3,0,
178,16,182,4,0,
178,16,202,3,0,
178,16,207,4,0,
178,16,211,4,0,
178,16,213,4,0,
178,16,214,4,0,
178,16,216,4,0,
178,16,218,4,0,
178,16,220,3,0,
178,16,237,4,0,
178,16,240,4,0,
178,16,241,4,0,
178,16,244,4,0,
178,16,247,4,0,
178,16,248,1,49,1
178,16,257,3,0,
178,16,263,4,0,
178,16,271,3,0,
178,16,273,1,29,1
178,16,277,3,0,
178,16,285,3,0,
178,16,290,4,0,
178,16,324,3,0,
178,16,332,4,0,
178,16,347,4,0,
178,16,355,4,0,
178,16,357,1,39,1
178,16,366,1,1,1
178,16,366,3,0,
178,16,369,4,0,
178,16,375,1,43,1
178,16,381,1,12,1
178,16,382,1,57,1
178,16,384,1,53,1
178,16,385,1,53,2
178,16,403,1,25,1
178,16,416,4,0,
178,16,428,3,0,
178,16,433,4,0,
178,16,447,4,0,
178,16,466,1,20,1
178,16,473,4,0,
178,16,478,3,0,
178,16,492,3,0,
178,16,496,4,0,
178,16,500,1,17,1
178,16,590,4,0,
178,16,605,4,0,
178,17,19,4,0,
178,17,43,1,1,4
178,17,63,4,0,
178,17,64,1,1,3
178,17,76,4,0,
178,17,86,4,0,
178,17,92,4,0,
178,17,94,1,35,
178,17,94,4,0,
178,17,100,1,1,6
178,17,100,1,9,
178,17,101,1,1,5
178,17,101,1,6,
178,17,104,4,0,
178,17,109,1,23,
178,17,113,4,0,
178,17,115,4,0,
178,17,138,4,0,
178,17,156,4,0,
178,17,164,4,0,
178,17,168,4,0,
178,17,182,4,0,
178,17,207,4,0,
178,17,211,4,0,
178,17,213,4,0,
178,17,214,4,0,
178,17,216,4,0,
178,17,218,4,0,
178,17,237,4,0,
178,17,240,4,0,
178,17,241,4,0,
178,17,244,4,0,
178,17,247,4,0,
178,17,248,1,49,
178,17,263,4,0,
178,17,273,1,29,
178,17,332,4,0,
178,17,347,4,0,
178,17,355,4,0,
178,17,357,1,39,
178,17,366,1,1,2
178,17,369,4,0,
178,17,375,1,43,
178,17,381,1,12,
178,17,382,1,57,
178,17,384,1,53,1
178,17,385,1,53,2
178,17,403,1,0,
178,17,403,1,1,1
178,17,416,4,0,
178,17,433,4,0,
178,17,447,4,0,
178,17,466,1,20,
178,17,473,4,0,
178,17,496,4,0,
178,17,500,1,17,
178,17,590,4,0,
178,17,605,4,0,
178,18,19,4,0,
178,18,43,1,1,4
178,18,63,4,0,
178,18,64,1,1,3
178,18,76,4,0,
178,18,86,4,0,
178,18,92,4,0,
178,18,94,1,35,
178,18,94,4,0,
178,18,100,1,1,6
178,18,100,1,9,
178,18,101,1,1,5
178,18,101,1,6,
178,18,104,4,0,
178,18,109,1,23,
178,18,113,4,0,
178,18,115,4,0,
178,18,138,4,0,
178,18,156,4,0,
178,18,164,4,0,
178,18,168,4,0,
178,18,182,4,0,
178,18,207,4,0,
178,18,211,4,0,
178,18,213,4,0,
178,18,214,4,0,
178,18,216,4,0,
178,18,218,4,0,
178,18,237,4,0,
178,18,240,4,0,
178,18,241,4,0,
178,18,244,4,0,
178,18,247,4,0,
178,18,248,1,49,
178,18,263,4,0,
178,18,273,1,29,
178,18,332,4,0,
178,18,347,4,0,
178,18,355,4,0,
178,18,357,1,39,
178,18,366,1,1,2
178,18,369,4,0,
178,18,375,1,43,
178,18,381,1,12,
178,18,382,1,57,
178,18,384,1,53,1
178,18,385,1,53,2
178,18,403,1,0,
178,18,403,1,1,1
178,18,416,4,0,
178,18,433,4,0,
178,18,447,4,0,
178,18,466,1,20,
178,18,473,4,0,
178,18,496,4,0,
178,18,500,1,17,
178,18,590,4,0,
178,18,605,4,0,
179,3,29,4,0,
179,3,33,1,1,1
179,3,34,2,0,
179,3,36,2,0,
179,3,45,1,1,2
179,3,84,1,9,
179,3,85,2,0,
179,3,86,1,16,
179,3,87,1,37,
179,3,87,4,0,
179,3,92,4,0,
179,3,103,2,0,
179,3,104,4,0,
179,3,111,4,0,
179,3,113,1,30,
179,3,115,2,0,
179,3,129,4,0,
179,3,148,4,0,
179,3,156,4,0,
179,3,173,4,0,
179,3,174,4,0,
179,3,178,1,23,
179,3,182,4,0,
179,3,192,4,0,
179,3,203,4,0,
179,3,207,4,0,
179,3,213,4,0,
179,3,214,4,0,
179,3,216,4,0,
179,3,218,4,0,
179,3,219,2,0,
179,3,231,4,0,
179,3,237,4,0,
179,3,240,4,0,
179,4,29,4,0,
179,4,33,1,1,1
179,4,34,2,0,
179,4,36,2,0,
179,4,45,1,1,2
179,4,84,1,9,
179,4,85,2,0,
179,4,85,3,0,
179,4,86,1,16,
179,4,87,1,37,
179,4,87,4,0,
179,4,92,4,0,
179,4,103,2,0,
179,4,104,4,0,
179,4,111,4,0,
179,4,113,1,30,
179,4,115,2,0,
179,4,129,4,0,
179,4,148,4,0,
179,4,156,4,0,
179,4,173,4,0,
179,4,174,4,0,
179,4,178,1,23,
179,4,182,4,0,
179,4,192,4,0,
179,4,203,4,0,
179,4,207,4,0,
179,4,213,4,0,
179,4,214,4,0,
179,4,216,4,0,
179,4,218,4,0,
179,4,219,2,0,
179,4,231,4,0,
179,4,237,4,0,
179,4,240,4,0,
179,5,33,1,1,1
179,5,34,2,0,
179,5,36,2,0,
179,5,45,1,1,2
179,5,84,1,9,
179,5,85,4,0,
179,5,86,1,16,
179,5,87,1,37,
179,5,87,4,0,
179,5,92,4,0,
179,5,103,2,0,
179,5,104,4,0,
179,5,113,1,30,
179,5,113,4,0,
179,5,115,2,0,
179,5,148,4,0,
179,5,156,4,0,
179,5,178,1,23,
179,5,182,4,0,
179,5,213,4,0,
179,5,216,4,0,
179,5,218,4,0,
179,5,219,2,0,
179,5,231,4,0,
179,5,237,4,0,
179,5,240,4,0,
179,5,263,4,0,
179,5,268,2,0,
179,5,290,4,0,
179,5,316,2,0,
179,5,351,4,0,
179,6,33,1,1,1
179,6,34,2,0,
179,6,34,3,0,
179,6,36,2,0,
179,6,38,3,0,
179,6,45,1,1,2
179,6,84,1,9,
179,6,85,4,0,
179,6,86,1,16,
179,6,86,3,0,
179,6,87,1,37,
179,6,87,4,0,
179,6,92,4,0,
179,6,102,3,0,
179,6,103,2,0,
179,6,104,4,0,
179,6,111,3,0,
179,6,113,1,30,
179,6,113,4,0,
179,6,115,2,0,
179,6,129,3,0,
179,6,148,4,0,
179,6,156,4,0,
179,6,164,3,0,
179,6,173,3,0,
179,6,178,1,23,
179,6,182,4,0,
179,6,203,3,0,
179,6,207,3,0,
179,6,213,4,0,
179,6,214,3,0,
179,6,216,4,0,
179,6,218,4,0,
179,6,219,2,0,
179,6,231,4,0,
179,6,237,4,0,
179,6,240,4,0,
179,6,263,4,0,
179,6,268,2,0,
179,6,290,4,0,
179,6,316,2,0,
179,6,351,4,0,
179,7,33,1,1,1
179,7,34,2,0,
179,7,34,3,0,
179,7,36,2,0,
179,7,38,3,0,
179,7,45,1,1,2
179,7,84,1,9,
179,7,85,4,0,
179,7,86,1,16,
179,7,86,3,0,
179,7,87,1,37,
179,7,87,4,0,
179,7,92,4,0,
179,7,102,3,0,
179,7,103,2,0,
179,7,104,4,0,
179,7,113,1,30,
179,7,113,4,0,
179,7,115,2,0,
179,7,148,4,0,
179,7,156,4,0,
179,7,164,3,0,
179,7,178,1,23,
179,7,182,4,0,
179,7,213,4,0,
179,7,216,4,0,
179,7,218,4,0,
179,7,219,2,0,
179,7,231,4,0,
179,7,237,4,0,
179,7,240,4,0,
179,7,263,4,0,
179,7,268,2,0,
179,7,290,4,0,
179,7,316,2,0,
179,7,351,4,0,
179,8,28,2,0,
179,8,33,1,1,
179,8,34,2,0,
179,8,36,2,0,
179,8,45,1,5,
179,8,84,1,10,
179,8,85,4,0,
179,8,86,1,14,
179,8,86,4,0,
179,8,87,1,46,
179,8,87,4,0,
179,8,92,4,0,
179,8,103,2,0,
179,8,104,4,0,
179,8,113,1,37,
179,8,113,4,0,
179,8,115,2,0,
179,8,148,4,0,
179,8,156,4,0,
179,8,164,4,0,
179,8,178,1,19,
179,8,182,4,0,
179,8,203,4,0,
179,8,207,4,0,
179,8,213,4,0,
179,8,214,4,0,
179,8,216,4,0,
179,8,218,4,0,
179,8,219,2,0,
179,8,231,4,0,
179,8,237,4,0,
179,8,240,4,0,
179,8,260,2,0,
179,8,263,4,0,
179,8,268,1,23,
179,8,268,2,0,
179,8,290,4,0,
179,8,316,2,0,
179,8,324,1,32,
179,8,351,4,0,
179,8,363,4,0,
179,8,408,1,41,
179,8,435,1,28,
179,8,445,4,0,
179,8,451,4,0,
179,9,28,2,0,
179,9,33,1,1,
179,9,34,2,0,
179,9,36,2,0,
179,9,45,1,5,
179,9,84,1,10,
179,9,85,4,0,
179,9,86,1,14,
179,9,86,4,0,
179,9,87,1,46,
179,9,87,4,0,
179,9,92,4,0,
179,9,103,2,0,
179,9,104,4,0,
179,9,113,1,37,
179,9,113,4,0,
179,9,115,2,0,
179,9,129,3,0,
179,9,148,4,0,
179,9,156,4,0,
179,9,164,4,0,
179,9,173,3,0,
179,9,178,1,19,
179,9,182,4,0,
179,9,203,4,0,
179,9,207,4,0,
179,9,213,4,0,
179,9,214,4,0,
179,9,216,4,0,
179,9,218,4,0,
179,9,219,2,0,
179,9,231,4,0,
179,9,237,4,0,
179,9,240,4,0,
179,9,260,2,0,
179,9,263,4,0,
179,9,268,1,23,
179,9,268,2,0,
179,9,290,4,0,
179,9,316,2,0,
179,9,324,1,32,
179,9,324,3,0,
179,9,351,4,0,
179,9,363,4,0,
179,9,393,3,0,
179,9,408,1,41,
179,9,435,1,28,
179,9,445,4,0,
179,9,451,4,0,
179,10,28,2,0,
179,10,29,3,0,
179,10,33,1,1,
179,10,34,2,0,
179,10,36,2,0,
179,10,45,1,5,
179,10,84,1,10,
179,10,85,4,0,
179,10,86,1,14,
179,10,86,4,0,
179,10,87,1,46,
179,10,87,4,0,
179,10,92,4,0,
179,10,103,2,0,
179,10,104,4,0,
179,10,113,1,37,
179,10,113,4,0,
179,10,115,2,0,
179,10,129,3,0,
179,10,148,4,0,
179,10,156,4,0,
179,10,164,4,0,
179,10,173,3,0,
179,10,178,1,19,
179,10,182,4,0,
179,10,203,4,0,
179,10,207,4,0,
179,10,213,4,0,
179,10,214,4,0,
179,10,215,3,0,
179,10,216,4,0,
179,10,218,4,0,
179,10,219,2,0,
179,10,231,4,0,
179,10,237,4,0,
179,10,240,4,0,
179,10,260,2,0,
179,10,263,4,0,
179,10,268,1,23,
179,10,268,2,0,
179,10,290,4,0,
179,10,316,2,0,
179,10,324,1,32,
179,10,324,3,0,
179,10,351,4,0,
179,10,363,4,0,
179,10,393,3,0,
179,10,408,1,41,
179,10,435,1,28,
179,10,445,4,0,
179,10,451,4,0,
179,11,28,2,0,
179,11,33,1,1,
179,11,34,2,0,
179,11,36,2,0,
179,11,45,1,5,
179,11,84,1,10,
179,11,85,4,0,
179,11,86,1,14,
179,11,86,4,0,
179,11,87,1,55,
179,11,87,4,0,
179,11,92,4,0,
179,11,97,2,0,
179,11,103,2,0,
179,11,104,4,0,
179,11,113,1,46,
179,11,113,4,0,
179,11,148,4,0,
179,11,156,4,0,
179,11,164,4,0,
179,11,178,1,19,
179,11,182,4,0,
179,11,207,4,0,
179,11,213,4,0,
179,11,216,4,0,
179,11,218,4,0,
179,11,219,4,0,
179,11,231,2,0,
179,11,237,4,0,
179,11,240,4,0,
179,11,260,2,0,
179,11,263,4,0,
179,11,268,1,23,
179,11,268,2,0,
179,11,316,2,0,
179,11,324,1,41,
179,11,408,1,50,
179,11,435,1,37,
179,11,451,4,0,
179,11,486,1,28,
179,11,495,2,0,
179,11,496,4,0,
179,11,497,4,0,
179,11,528,4,0,
179,11,538,1,32,
179,12,33,1,1,1
179,12,45,1,1,2
179,12,84,1,9,
179,12,85,4,0,
179,12,86,1,16,
179,12,87,1,37,
179,12,87,4,0,
179,12,92,4,0,
179,12,104,4,0,
179,12,113,1,30,
179,12,113,4,0,
179,12,148,4,0,
179,12,156,4,0,
179,12,178,1,23,
179,12,182,4,0,
179,12,213,4,0,
179,12,216,4,0,
179,12,218,4,0,
179,12,231,4,0,
179,12,237,4,0,
179,12,240,4,0,
179,12,263,4,0,
179,12,290,4,0,
179,12,351,4,0,
179,13,33,1,1,1
179,13,34,3,0,
179,13,38,3,0,
179,13,45,1,1,2
179,13,84,1,9,
179,13,85,4,0,
179,13,86,1,16,
179,13,86,3,0,
179,13,87,1,37,
179,13,87,4,0,
179,13,92,4,0,
179,13,102,3,0,
179,13,104,4,0,
179,13,113,1,30,
179,13,113,4,0,
179,13,148,4,0,
179,13,156,4,0,
179,13,164,3,0,
179,13,178,1,23,
179,13,182,4,0,
179,13,207,3,0,
179,13,213,4,0,
179,13,216,4,0,
179,13,218,4,0,
179,13,231,4,0,
179,13,237,4,0,
179,13,240,4,0,
179,13,263,4,0,
179,13,290,4,0,
179,13,351,4,0,
179,14,28,2,0,
179,14,33,1,1,1
179,14,34,2,0,
179,14,36,1,18,
179,14,36,2,0,
179,14,45,1,1,2
179,14,84,1,8,
179,14,85,4,0,
179,14,86,1,4,
179,14,86,4,0,
179,14,87,1,46,
179,14,87,4,0,
179,14,92,4,0,
179,14,97,2,0,
179,14,103,2,0,
179,14,104,4,0,
179,14,109,1,25,
179,14,113,1,43,
179,14,113,4,0,
179,14,148,4,0,
179,14,156,4,0,
179,14,164,4,0,
179,14,173,3,0,
179,14,178,1,11,
179,14,182,4,0,
179,14,207,4,0,
179,14,213,4,0,
179,14,214,3,0,
179,14,215,3,0,
179,14,216,4,0,
179,14,218,4,0,
179,14,219,4,0,
179,14,231,2,0,
179,14,231,3,0,
179,14,237,4,0,
179,14,240,4,0,
179,14,260,2,0,
179,14,263,4,0,
179,14,268,1,15,
179,14,268,2,0,
179,14,316,2,0,
179,14,324,1,39,
179,14,324,3,0,
179,14,393,3,0,
179,14,408,1,29,
179,14,435,1,32,
179,14,451,4,0,
179,14,486,1,22,
179,14,495,2,0,
179,14,495,3,0,
179,14,496,4,0,
179,14,497,4,0,
179,14,528,4,0,
179,14,538,1,36,
179,15,28,2,0,
179,15,33,1,1,1
179,15,34,2,0,
179,15,36,1,18,
179,15,36,2,0,
179,15,45,1,1,2
179,15,84,1,8,
179,15,85,4,0,
179,15,86,1,4,
179,15,86,4,0,
179,15,87,1,46,
179,15,87,4,0,
179,15,92,4,0,
179,15,97,2,0,
179,15,103,2,0,
179,15,104,4,0,
179,15,109,1,25,
179,15,113,1,43,
179,15,113,4,0,
179,15,148,4,0,
179,15,156,4,0,
179,15,164,4,0,
179,15,178,1,11,
179,15,182,4,0,
179,15,207,4,0,
179,15,213,4,0,
179,15,214,4,0,
179,15,216,4,0,
179,15,218,4,0,
179,15,219,4,0,
179,15,231,2,0,
179,15,237,4,0,
179,15,240,4,0,
179,15,260,2,0,
179,15,263,4,0,
179,15,268,1,15,
179,15,268,2,0,
179,15,316,2,0,
179,15,324,1,39,
179,15,408,1,29,
179,15,435,1,32,
179,15,451,4,0,
179,15,486,1,22,
179,15,495,2,0,
179,15,496,4,0,
179,15,497,4,0,
179,15,528,4,0,
179,15,538,1,36,
179,15,590,4,0,
179,15,598,2,0,
179,15,604,2,0,
179,16,28,2,0,
179,16,33,1,1,1
179,16,34,2,0,
179,16,36,1,18,1
179,16,36,2,0,
179,16,45,1,1,2
179,16,84,1,8,1
179,16,85,4,0,
179,16,86,1,4,1
179,16,86,4,0,
179,16,87,1,46,1
179,16,87,4,0,
179,16,92,4,0,
179,16,97,2,0,
179,16,103,2,0,
179,16,104,4,0,
179,16,109,1,25,1
179,16,113,1,43,1
179,16,113,4,0,
179,16,148,4,0,
179,16,156,4,0,
179,16,164,4,0,
179,16,173,3,0,
179,16,178,1,11,1
179,16,182,4,0,
179,16,207,4,0,
179,16,213,4,0,
179,16,214,4,0,
179,16,215,3,0,
179,16,216,4,0,
179,16,218,4,0,
179,16,219,4,0,
179,16,231,2,0,
179,16,231,3,0,
179,16,237,4,0,
179,16,240,4,0,
179,16,260,2,0,
179,16,263,4,0,
179,16,268,1,15,1
179,16,268,2,0,
179,16,290,4,0,
179,16,316,2,0,
179,16,324,1,39,1
179,16,324,3,0,
179,16,351,3,0,
179,16,393,3,0,
179,16,408,1,29,1
179,16,435,1,32,1
179,16,451,4,0,
179,16,486,1,22,1
179,16,495,2,0,
179,16,495,3,0,
179,16,496,4,0,
179,16,497,4,0,
179,16,527,3,0,
179,16,528,4,0,
179,16,538,1,36,1
179,16,590,4,0,
179,16,598,2,0,
179,16,604,2,0,
179,17,28,2,0,
179,17,33,1,1,1
179,17,34,2,0,
179,17,36,1,18,
179,17,36,2,0,
179,17,45,1,1,2
179,17,84,1,8,
179,17,85,4,0,
179,17,86,1,4,
179,17,86,4,0,
179,17,87,1,46,
179,17,87,4,0,
179,17,92,4,0,
179,17,97,2,0,
179,17,103,2,0,
179,17,104,4,0,
179,17,109,1,25,
179,17,113,1,43,
179,17,113,4,0,
179,17,156,4,0,
179,17,164,4,0,
179,17,178,1,11,
179,17,182,4,0,
179,17,207,4,0,
179,17,213,4,0,
179,17,214,4,0,
179,17,216,4,0,
179,17,218,4,0,
179,17,219,4,0,
179,17,231,2,0,
179,17,237,4,0,
179,17,240,4,0,
179,17,260,2,0,
179,17,263,4,0,
179,17,268,1,15,
179,17,268,2,0,
179,17,316,2,0,
179,17,324,1,39,
179,17,408,1,29,
179,17,435,1,32,
179,17,451,4,0,
179,17,486,1,22,
179,17,495,2,0,
179,17,496,4,0,
179,17,497,4,0,
179,17,528,4,0,
179,17,538,1,36,
179,17,590,4,0,
179,17,598,2,0,
179,17,604,2,0,
179,18,28,2,0,
179,18,33,1,1,1
179,18,34,2,0,
179,18,36,1,18,
179,18,36,2,0,
179,18,45,1,1,2
179,18,84,1,8,
179,18,85,4,0,
179,18,86,1,4,
179,18,86,4,0,
179,18,87,1,46,
179,18,87,4,0,
179,18,92,4,0,
179,18,97,2,0,
179,18,103,2,0,
179,18,104,4,0,
179,18,109,1,25,
179,18,113,1,43,
179,18,113,4,0,
179,18,156,4,0,
179,18,164,4,0,
179,18,178,1,11,
179,18,182,4,0,
179,18,207,4,0,
179,18,213,4,0,
179,18,214,4,0,
179,18,216,4,0,
179,18,218,4,0,
179,18,219,4,0,
179,18,231,2,0,
179,18,237,4,0,
179,18,240,4,0,
179,18,260,2,0,
179,18,263,4,0,
179,18,268,1,15,
179,18,268,2,0,
179,18,316,2,0,
179,18,324,1,39,
179,18,408,1,29,
179,18,435,1,32,
179,18,451,4,0,
179,18,486,1,22,
179,18,495,2,0,
179,18,496,4,0,
179,18,497,4,0,
179,18,528,4,0,
179,18,538,1,36,
179,18,590,4,0,
179,18,598,2,0,
179,18,604,2,0,
180,3,7,4,0,
180,3,9,4,0,
180,3,29,4,0,
180,3,33,1,1,1
180,3,45,1,1,2
180,3,70,4,0,
180,3,84,1,1,3
180,3,84,1,9,
180,3,86,1,18,
180,3,87,1,45,
180,3,87,4,0,
180,3,92,4,0,
180,3,104,4,0,
180,3,111,4,0,
180,3,113,1,36,
180,3,129,4,0,
180,3,148,4,0,
180,3,156,4,0,
180,3,173,4,0,
180,3,174,4,0,
180,3,178,1,27,
180,3,182,4,0,
180,3,192,4,0,
180,3,203,4,0,
180,3,207,4,0,
180,3,213,4,0,
180,3,214,4,0,
180,3,216,4,0,
180,3,218,4,0,
180,3,223,4,0,
180,3,231,4,0,
180,3,237,4,0,
180,3,240,4,0,
180,3,249,4,0,
180,4,7,4,0,
180,4,9,4,0,
180,4,29,4,0,
180,4,33,1,1,1
180,4,45,1,1,2
180,4,70,4,0,
180,4,84,1,1,3
180,4,84,1,9,
180,4,85,3,0,
180,4,86,1,18,
180,4,87,1,45,
180,4,87,4,0,
180,4,92,4,0,
180,4,104,4,0,
180,4,111,4,0,
180,4,113,1,36,
180,4,129,4,0,
180,4,148,4,0,
180,4,156,4,0,
180,4,173,4,0,
180,4,174,4,0,
180,4,178,1,27,
180,4,182,4,0,
180,4,192,4,0,
180,4,203,4,0,
180,4,207,4,0,
180,4,213,4,0,
180,4,214,4,0,
180,4,216,4,0,
180,4,218,4,0,
180,4,223,4,0,
180,4,231,4,0,
180,4,237,4,0,
180,4,240,4,0,
180,4,249,4,0,
180,5,33,1,1,1
180,5,45,1,1,2
180,5,70,4,0,
180,5,84,1,1,3
180,5,84,1,9,
180,5,85,4,0,
180,5,86,1,18,
180,5,87,1,45,
180,5,87,4,0,
180,5,92,4,0,
180,5,104,4,0,
180,5,113,1,36,
180,5,113,4,0,
180,5,148,4,0,
180,5,156,4,0,
180,5,178,1,27,
180,5,182,4,0,
180,5,213,4,0,
180,5,216,4,0,
180,5,218,4,0,
180,5,231,4,0,
180,5,237,4,0,
180,5,240,4,0,
180,5,249,4,0,
180,5,263,4,0,
180,5,264,4,0,
180,5,280,4,0,
180,5,290,4,0,
180,5,351,4,0,
180,6,5,3,0,
180,6,7,3,0,
180,6,9,3,0,
180,6,25,3,0,
180,6,33,1,1,1
180,6,34,3,0,
180,6,38,3,0,
180,6,45,1,1,2
180,6,68,3,0,
180,6,69,3,0,
180,6,70,4,0,
180,6,84,1,1,3
180,6,84,1,9,
180,6,85,4,0,
180,6,86,1,18,
180,6,86,3,0,
180,6,87,1,45,
180,6,87,4,0,
180,6,92,4,0,
180,6,102,3,0,
180,6,104,4,0,
180,6,111,3,0,
180,6,113,1,36,
180,6,113,4,0,
180,6,129,3,0,
180,6,148,4,0,
180,6,156,4,0,
180,6,164,3,0,
180,6,173,3,0,
180,6,178,1,27,
180,6,182,4,0,
180,6,203,3,0,
180,6,207,3,0,
180,6,213,4,0,
180,6,214,3,0,
180,6,216,4,0,
180,6,218,4,0,
180,6,223,3,0,
180,6,231,4,0,
180,6,237,4,0,
180,6,240,4,0,
180,6,249,4,0,
180,6,263,4,0,
180,6,264,4,0,
180,6,280,4,0,
180,6,290,4,0,
180,6,351,4,0,
180,7,5,3,0,
180,7,25,3,0,
180,7,33,1,1,1
180,7,34,3,0,
180,7,38,3,0,
180,7,45,1,1,2
180,7,68,3,0,
180,7,69,3,0,
180,7,70,4,0,
180,7,84,1,1,3
180,7,84,1,9,
180,7,85,4,0,
180,7,86,1,18,
180,7,86,3,0,
180,7,87,1,45,
180,7,87,4,0,
180,7,92,4,0,
180,7,102,3,0,
180,7,104,4,0,
180,7,113,1,36,
180,7,113,4,0,
180,7,148,4,0,
180,7,156,4,0,
180,7,164,3,0,
180,7,178,1,27,
180,7,182,4,0,
180,7,213,4,0,
180,7,216,4,0,
180,7,218,4,0,
180,7,231,4,0,
180,7,237,4,0,
180,7,240,4,0,
180,7,249,4,0,
180,7,263,4,0,
180,7,264,4,0,
180,7,280,4,0,
180,7,290,4,0,
180,7,351,4,0,
180,8,33,1,1,1
180,8,45,1,1,2
180,8,45,1,5,
180,8,70,4,0,
180,8,84,1,1,3
180,8,84,1,10,
180,8,85,4,0,
180,8,86,1,14,
180,8,86,4,0,
180,8,87,1,53,
180,8,87,4,0,
180,8,92,4,0,
180,8,104,4,0,
180,8,113,1,42,
180,8,113,4,0,
180,8,148,4,0,
180,8,156,4,0,
180,8,164,4,0,
180,8,178,1,20,
180,8,182,4,0,
180,8,203,4,0,
180,8,207,4,0,
180,8,213,4,0,
180,8,214,4,0,
180,8,216,4,0,
180,8,218,4,0,
180,8,231,4,0,
180,8,237,4,0,
180,8,240,4,0,
180,8,249,4,0,
180,8,263,4,0,
180,8,264,4,0,
180,8,268,1,25,
180,8,280,4,0,
180,8,290,4,0,
180,8,324,1,36,
180,8,351,4,0,
180,8,363,4,0,
180,8,374,4,0,
180,8,408,1,47,
180,8,435,1,31,
180,8,445,4,0,
180,8,451,4,0,
180,9,7,3,0,
180,9,9,3,0,
180,9,33,1,1,1
180,9,45,1,1,2
180,9,45,1,5,
180,9,70,4,0,
180,9,84,1,1,3
180,9,84,1,10,
180,9,85,4,0,
180,9,86,1,14,
180,9,86,4,0,
180,9,87,1,53,
180,9,87,4,0,
180,9,92,4,0,
180,9,104,4,0,
180,9,113,1,42,
180,9,113,4,0,
180,9,129,3,0,
180,9,148,4,0,
180,9,156,4,0,
180,9,164,4,0,
180,9,173,3,0,
180,9,178,1,20,
180,9,182,4,0,
180,9,203,4,0,
180,9,207,4,0,
180,9,213,4,0,
180,9,214,4,0,
180,9,216,4,0,
180,9,218,4,0,
180,9,231,4,0,
180,9,237,4,0,
180,9,240,4,0,
180,9,249,4,0,
180,9,263,4,0,
180,9,264,4,0,
180,9,268,1,25,
180,9,280,4,0,
180,9,290,4,0,
180,9,324,1,36,
180,9,324,3,0,
180,9,351,4,0,
180,9,363,4,0,
180,9,374,4,0,
180,9,393,3,0,
180,9,408,1,47,
180,9,435,1,31,
180,9,445,4,0,
180,9,451,4,0,
180,10,7,3,0,
180,10,9,3,0,
180,10,29,3,0,
180,10,33,1,1,1
180,10,45,1,1,2
180,10,45,1,5,
180,10,70,4,0,
180,10,84,1,1,3
180,10,84,1,10,
180,10,85,4,0,
180,10,86,1,14,
180,10,86,4,0,
180,10,87,1,53,
180,10,87,4,0,
180,10,92,4,0,
180,10,104,4,0,
180,10,113,1,42,
180,10,113,4,0,
180,10,129,3,0,
180,10,148,4,0,
180,10,156,4,0,
180,10,164,4,0,
180,10,173,3,0,
180,10,178,1,20,
180,10,182,4,0,
180,10,203,4,0,
180,10,207,4,0,
180,10,213,4,0,
180,10,214,4,0,
180,10,215,3,0,
180,10,216,4,0,
180,10,218,4,0,
180,10,231,4,0,
180,10,237,4,0,
180,10,240,4,0,
180,10,249,4,0,
180,10,263,4,0,
180,10,264,4,0,
180,10,268,1,25,
180,10,280,4,0,
180,10,290,4,0,
180,10,324,1,36,
180,10,324,3,0,
180,10,351,4,0,
180,10,363,4,0,
180,10,374,4,0,
180,10,393,3,0,
180,10,408,1,47,
180,10,435,1,31,
180,10,445,4,0,
180,10,451,4,0,
180,11,33,1,1,1
180,11,45,1,1,2
180,11,45,1,5,
180,11,70,4,0,
180,11,84,1,1,3
180,11,84,1,10,
180,11,85,4,0,
180,11,86,1,14,
180,11,86,4,0,
180,11,87,1,65,
180,11,87,4,0,
180,11,92,4,0,
180,11,104,4,0,
180,11,113,1,53,
180,11,113,4,0,
180,11,148,4,0,
180,11,156,4,0,
180,11,164,4,0,
180,11,178,1,20,
180,11,182,4,0,
180,11,207,4,0,
180,11,213,4,0,
180,11,216,4,0,
180,11,218,4,0,
180,11,219,4,0,
180,11,237,4,0,
180,11,240,4,0,
180,11,249,4,0,
180,11,263,4,0,
180,11,268,1,25,
180,11,280,4,0,
180,11,324,1,47,
180,11,374,4,0,
180,11,408,1,59,
180,11,435,1,42,
180,11,451,4,0,
180,11,486,1,31,
180,11,496,4,0,
180,11,497,4,0,
180,11,521,4,0,
180,11,528,4,0,
180,11,538,1,36,
180,12,33,1,1,1
180,12,45,1,1,2
180,12,70,4,0,
180,12,84,1,1,3
180,12,84,1,9,
180,12,85,4,0,
180,12,86,1,18,
180,12,87,1,45,
180,12,87,4,0,
180,12,92,4,0,
180,12,104,4,0,
180,12,113,1,36,
180,12,113,4,0,
180,12,148,4,0,
180,12,156,4,0,
180,12,178,1,27,
180,12,182,4,0,
180,12,213,4,0,
180,12,216,4,0,
180,12,218,4,0,
180,12,231,4,0,
180,12,237,4,0,
180,12,240,4,0,
180,12,249,4,0,
180,12,263,4,0,
180,12,264,4,0,
180,12,280,4,0,
180,12,290,4,0,
180,12,351,4,0,
180,13,33,1,1,1
180,13,34,3,0,
180,13,38,3,0,
180,13,45,1,1,2
180,13,69,3,0,
180,13,70,4,0,
180,13,84,1,1,3
180,13,84,1,9,
180,13,85,4,0,
180,13,86,1,18,
180,13,86,3,0,
180,13,87,1,45,
180,13,87,4,0,
180,13,92,4,0,
180,13,102,3,0,
180,13,104,4,0,
180,13,113,1,36,
180,13,113,4,0,
180,13,148,4,0,
180,13,156,4,0,
180,13,164,3,0,
180,13,178,1,27,
180,13,182,4,0,
180,13,207,3,0,
180,13,213,4,0,
180,13,216,4,0,
180,13,218,4,0,
180,13,231,4,0,
180,13,237,4,0,
180,13,240,4,0,
180,13,249,4,0,
180,13,263,4,0,
180,13,264,4,0,
180,13,280,4,0,
180,13,290,4,0,
180,13,351,4,0,
180,14,7,3,0,
180,14,9,3,0,
180,14,33,1,1,1
180,14,36,1,20,
180,14,45,1,1,2
180,14,70,4,0,
180,14,84,1,1,4
180,14,84,1,8,
180,14,85,4,0,
180,14,86,1,1,3
180,14,86,1,4,
180,14,86,4,0,
180,14,87,1,56,
180,14,87,4,0,
180,14,92,4,0,
180,14,104,4,0,
180,14,109,1,29,
180,14,113,1,52,
180,14,113,4,0,
180,14,148,4,0,
180,14,156,4,0,
180,14,164,4,0,
180,14,173,3,0,
180,14,178,1,11,
180,14,182,4,0,
180,14,207,4,0,
180,14,213,4,0,
180,14,214,3,0,
180,14,215,3,0,
180,14,216,4,0,
180,14,218,4,0,
180,14,219,4,0,
180,14,231,3,0,
180,14,237,4,0,
180,14,240,4,0,
180,14,249,4,0,
180,14,263,4,0,
180,14,268,1,16,
180,14,280,4,0,
180,14,324,1,47,
180,14,324,3,0,
180,14,374,4,0,
180,14,393,3,0,
180,14,408,1,34,
180,14,435,1,38,
180,14,451,4,0,
180,14,486,1,25,
180,14,495,3,0,
180,14,496,4,0,
180,14,497,4,0,
180,14,521,4,0,
180,14,528,4,0,
180,14,538,1,43,
180,15,33,1,1,1
180,15,36,1,20,
180,15,45,1,1,2
180,15,70,4,0,
180,15,84,1,1,4
180,15,84,1,8,
180,15,85,4,0,
180,15,86,1,1,3
180,15,86,1,4,
180,15,86,4,0,
180,15,87,1,56,
180,15,87,4,0,
180,15,92,4,0,
180,15,104,4,0,
180,15,109,1,29,
180,15,113,1,52,
180,15,113,4,0,
180,15,148,4,0,
180,15,156,4,0,
180,15,164,4,0,
180,15,178,1,11,
180,15,182,4,0,
180,15,207,4,0,
180,15,213,4,0,
180,15,214,4,0,
180,15,216,4,0,
180,15,218,4,0,
180,15,219,4,0,
180,15,237,4,0,
180,15,240,4,0,
180,15,249,4,0,
180,15,263,4,0,
180,15,268,1,16,
180,15,280,4,0,
180,15,324,1,47,
180,15,374,4,0,
180,15,408,1,34,
180,15,435,1,38,
180,15,451,4,0,
180,15,486,1,25,
180,15,496,4,0,
180,15,497,4,0,
180,15,521,4,0,
180,15,528,4,0,
180,15,538,1,43,
180,15,590,4,0,
180,15,612,4,0,
180,16,7,3,0,
180,16,9,3,0,
180,16,33,1,1,1
180,16,36,1,20,1
180,16,45,1,1,2
180,16,70,4,0,
180,16,84,1,1,4
180,16,84,1,8,1
180,16,85,4,0,
180,16,86,1,1,3
180,16,86,1,4,1
180,16,86,4,0,
180,16,87,1,56,1
180,16,87,4,0,
180,16,92,4,0,
180,16,104,4,0,
180,16,109,1,29,1
180,16,113,1,52,1
180,16,113,4,0,
180,16,148,4,0,
180,16,156,4,0,
180,16,164,4,0,
180,16,173,3,0,
180,16,178,1,11,1
180,16,182,4,0,
180,16,207,4,0,
180,16,213,4,0,
180,16,214,4,0,
180,16,215,3,0,
180,16,216,4,0,
180,16,218,4,0,
180,16,219,4,0,
180,16,231,3,0,
180,16,237,4,0,
180,16,240,4,0,
180,16,249,4,0,
180,16,263,4,0,
180,16,264,3,0,
180,16,268,1,16,1
180,16,280,4,0,
180,16,290,4,0,
180,16,324,1,47,1
180,16,324,3,0,
180,16,351,3,0,
180,16,374,4,0,
180,16,393,3,0,
180,16,408,1,34,1
180,16,435,1,38,1
180,16,451,4,0,
180,16,486,1,25,1
180,16,495,3,0,
180,16,496,4,0,
180,16,497,4,0,
180,16,521,4,0,
180,16,527,3,0,
180,16,528,4,0,
180,16,538,1,43,1
180,16,590,4,0,
180,16,612,4,0,
180,17,33,1,1,1
180,17,36,1,20,
180,17,45,1,1,2
180,17,84,1,1,4
180,17,84,1,8,
180,17,85,4,0,
180,17,86,1,1,3
180,17,86,1,4,
180,17,86,4,0,
180,17,87,1,56,
180,17,87,4,0,
180,17,92,4,0,
180,17,104,4,0,
180,17,109,1,29,
180,17,113,1,52,
180,17,113,4,0,
180,17,156,4,0,
180,17,164,4,0,
180,17,178,1,11,
180,17,182,4,0,
180,17,207,4,0,
180,17,213,4,0,
180,17,214,4,0,
180,17,216,4,0,
180,17,218,4,0,
180,17,219,4,0,
180,17,237,4,0,
180,17,240,4,0,
180,17,263,4,0,
180,17,268,1,16,
180,17,280,4,0,
180,17,324,1,47,
180,17,374,4,0,
180,17,408,1,34,
180,17,435,1,38,
180,17,451,4,0,
180,17,486,1,25,
180,17,496,4,0,
180,17,497,4,0,
180,17,521,4,0,
180,17,528,4,0,
180,17,538,1,43,
180,17,590,4,0,
180,18,33,1,1,1
180,18,36,1,20,
180,18,45,1,1,2
180,18,84,1,1,4
180,18,84,1,8,
180,18,85,4,0,
180,18,86,1,1,3
180,18,86,1,4,
180,18,86,4,0,
180,18,87,1,56,
180,18,87,4,0,
180,18,92,4,0,
180,18,104,4,0,
180,18,109,1,29,
180,18,113,1,52,
180,18,113,4,0,
180,18,156,4,0,
180,18,164,4,0,
180,18,178,1,11,
180,18,182,4,0,
180,18,207,4,0,
180,18,213,4,0,
180,18,214,4,0,
180,18,216,4,0,
180,18,218,4,0,
180,18,219,4,0,
180,18,237,4,0,
180,18,240,4,0,
180,18,263,4,0,
180,18,268,1,16,
180,18,280,4,0,
180,18,324,1,47,
180,18,374,4,0,
180,18,408,1,34,
180,18,435,1,38,
180,18,451,4,0,
180,18,486,1,25,
180,18,496,4,0,
180,18,497,4,0,
180,18,521,4,0,
180,18,528,4,0,
180,18,538,1,43,
180,18,590,4,0,
181,3,7,4,0,
181,3,9,1,30,
181,3,9,4,0,
181,3,29,4,0,
181,3,33,1,1,1
181,3,45,1,1,2
181,3,63,4,0,
181,3,70,4,0,
181,3,84,1,1,3
181,3,84,1,9,
181,3,86,1,1,4
181,3,86,1,18,
181,3,87,1,57,
181,3,87,4,0,
181,3,92,4,0,
181,3,104,4,0,
181,3,111,4,0,
181,3,113,1,42,
181,3,129,4,0,
181,3,148,4,0,
181,3,156,4,0,
181,3,173,4,0,
181,3,174,4,0,
181,3,178,1,27,
181,3,182,4,0,
181,3,192,4,0,
181,3,203,4,0,
181,3,207,4,0,
181,3,213,4,0,
181,3,214,4,0,
181,3,216,4,0,
181,3,218,4,0,
181,3,223,4,0,
181,3,231,4,0,
181,3,237,4,0,
181,3,240,4,0,
181,3,249,4,0,
181,4,7,4,0,
181,4,9,1,30,
181,4,9,4,0,
181,4,29,4,0,
181,4,33,1,1,1
181,4,45,1,1,2
181,4,63,4,0,
181,4,70,4,0,
181,4,84,1,1,3
181,4,84,1,9,
181,4,85,3,0,
181,4,86,1,1,4
181,4,86,1,18,
181,4,87,1,57,
181,4,87,4,0,
181,4,92,4,0,
181,4,104,4,0,
181,4,111,4,0,
181,4,113,1,42,
181,4,129,4,0,
181,4,148,4,0,
181,4,156,4,0,
181,4,173,4,0,
181,4,174,4,0,
181,4,178,1,27,
181,4,182,4,0,
181,4,192,4,0,
181,4,203,4,0,
181,4,207,4,0,
181,4,213,4,0,
181,4,214,4,0,
181,4,216,4,0,
181,4,218,4,0,
181,4,223,4,0,
181,4,231,4,0,
181,4,237,4,0,
181,4,240,4,0,
181,4,249,4,0,
181,5,9,1,30,
181,5,33,1,1,1
181,5,45,1,1,2
181,5,63,4,0,
181,5,70,4,0,
181,5,84,1,1,3
181,5,84,1,9,
181,5,85,4,0,
181,5,86,1,1,4
181,5,86,1,18,
181,5,87,1,57,
181,5,87,4,0,
181,5,92,4,0,
181,5,104,4,0,
181,5,113,1,42,
181,5,113,4,0,
181,5,148,4,0,
181,5,156,4,0,
181,5,178,1,27,
181,5,182,4,0,
181,5,213,4,0,
181,5,216,4,0,
181,5,218,4,0,
181,5,231,4,0,
181,5,237,4,0,
181,5,240,4,0,
181,5,249,4,0,
181,5,263,4,0,
181,5,264,4,0,
181,5,280,4,0,
181,5,290,4,0,
181,5,351,4,0,
181,6,5,3,0,
181,6,7,3,0,
181,6,9,1,30,
181,6,9,3,0,
181,6,25,3,0,
181,6,33,1,1,1
181,6,34,3,0,
181,6,38,3,0,
181,6,45,1,1,2
181,6,63,4,0,
181,6,68,3,0,
181,6,69,3,0,
181,6,70,4,0,
181,6,84,1,1,3
181,6,84,1,9,
181,6,85,4,0,
181,6,86,1,1,4
181,6,86,1,18,
181,6,86,3,0,
181,6,87,1,57,
181,6,87,4,0,
181,6,92,4,0,
181,6,102,3,0,
181,6,104,4,0,
181,6,111,3,0,
181,6,113,1,42,
181,6,113,4,0,
181,6,129,3,0,
181,6,148,4,0,
181,6,156,4,0,
181,6,164,3,0,
181,6,173,3,0,
181,6,178,1,27,
181,6,182,4,0,
181,6,203,3,0,
181,6,207,3,0,
181,6,213,4,0,
181,6,214,3,0,
181,6,216,4,0,
181,6,218,4,0,
181,6,223,3,0,
181,6,231,4,0,
181,6,237,4,0,
181,6,240,4,0,
181,6,249,4,0,
181,6,263,4,0,
181,6,264,4,0,
181,6,280,4,0,
181,6,290,4,0,
181,6,351,4,0,
181,7,5,3,0,
181,7,9,1,30,
181,7,25,3,0,
181,7,33,1,1,1
181,7,34,3,0,
181,7,38,3,0,
181,7,45,1,1,2
181,7,63,4,0,
181,7,68,3,0,
181,7,69,3,0,
181,7,70,4,0,
181,7,84,1,1,3
181,7,84,1,9,
181,7,85,4,0,
181,7,86,1,1,4
181,7,86,1,18,
181,7,86,3,0,
181,7,87,1,57,
181,7,87,4,0,
181,7,92,4,0,
181,7,102,3,0,
181,7,104,4,0,
181,7,113,1,42,
181,7,113,4,0,
181,7,148,4,0,
181,7,156,4,0,
181,7,164,3,0,
181,7,178,1,27,
181,7,182,4,0,
181,7,213,4,0,
181,7,216,4,0,
181,7,218,4,0,
181,7,231,4,0,
181,7,237,4,0,
181,7,240,4,0,
181,7,249,4,0,
181,7,263,4,0,
181,7,264,4,0,
181,7,280,4,0,
181,7,290,4,0,
181,7,351,4,0,
181,8,7,1,1,1
181,8,9,1,30,
181,8,33,1,1,2
181,8,45,1,1,3
181,8,45,1,5,
181,8,63,4,0,
181,8,70,4,0,
181,8,84,1,1,4
181,8,84,1,10,
181,8,85,4,0,
181,8,86,1,1,5
181,8,86,1,14,
181,8,86,4,0,
181,8,87,1,68,
181,8,87,4,0,
181,8,92,4,0,
181,8,104,4,0,
181,8,113,1,51,
181,8,113,4,0,
181,8,148,4,0,
181,8,156,4,0,
181,8,164,4,0,
181,8,178,1,20,
181,8,182,4,0,
181,8,203,4,0,
181,8,207,4,0,
181,8,213,4,0,
181,8,214,4,0,
181,8,216,4,0,
181,8,218,4,0,
181,8,231,4,0,
181,8,237,4,0,
181,8,240,4,0,
181,8,249,4,0,
181,8,263,4,0,
181,8,264,4,0,
181,8,268,1,25,
181,8,280,4,0,
181,8,290,4,0,
181,8,324,1,42,
181,8,351,4,0,
181,8,363,4,0,
181,8,374,4,0,
181,8,408,1,59,
181,8,411,4,0,
181,8,416,4,0,
181,8,431,4,0,
181,8,435,1,34,
181,8,445,4,0,
181,8,451,4,0,
181,9,7,1,1,1
181,9,7,3,0,
181,9,9,1,30,
181,9,9,3,0,
181,9,33,1,1,2
181,9,45,1,1,3
181,9,45,1,5,
181,9,63,4,0,
181,9,70,4,0,
181,9,84,1,1,4
181,9,84,1,10,
181,9,85,4,0,
181,9,86,1,1,5
181,9,86,1,14,
181,9,86,4,0,
181,9,87,1,68,
181,9,87,4,0,
181,9,92,4,0,
181,9,104,4,0,
181,9,113,1,51,
181,9,113,4,0,
181,9,129,3,0,
181,9,148,4,0,
181,9,156,4,0,
181,9,164,4,0,
181,9,173,3,0,
181,9,178,1,20,
181,9,182,4,0,
181,9,200,3,0,
181,9,203,4,0,
181,9,207,4,0,
181,9,213,4,0,
181,9,214,4,0,
181,9,216,4,0,
181,9,218,4,0,
181,9,231,4,0,
181,9,237,4,0,
181,9,240,4,0,
181,9,249,4,0,
181,9,263,4,0,
181,9,264,4,0,
181,9,268,1,25,
181,9,280,4,0,
181,9,290,4,0,
181,9,324,1,42,
181,9,324,3,0,
181,9,351,4,0,
181,9,363,4,0,
181,9,374,4,0,
181,9,393,3,0,
181,9,408,1,59,
181,9,411,4,0,
181,9,416,4,0,
181,9,431,4,0,
181,9,435,1,34,
181,9,445,4,0,
181,9,451,4,0,
181,10,7,1,1,1
181,10,7,3,0,
181,10,9,1,30,
181,10,9,3,0,
181,10,29,3,0,
181,10,33,1,1,2
181,10,45,1,1,3
181,10,45,1,5,
181,10,63,4,0,
181,10,70,4,0,
181,10,84,1,1,4
181,10,84,1,10,
181,10,85,4,0,
181,10,86,1,1,5
181,10,86,1,14,
181,10,86,4,0,
181,10,87,1,68,
181,10,87,4,0,
181,10,92,4,0,
181,10,104,4,0,
181,10,113,1,51,
181,10,113,4,0,
181,10,129,3,0,
181,10,148,4,0,
181,10,156,4,0,
181,10,164,4,0,
181,10,173,3,0,
181,10,178,1,20,
181,10,182,4,0,
181,10,200,3,0,
181,10,203,4,0,
181,10,207,4,0,
181,10,213,4,0,
181,10,214,4,0,
181,10,215,3,0,
181,10,216,4,0,
181,10,218,4,0,
181,10,231,4,0,
181,10,237,4,0,
181,10,240,4,0,
181,10,249,4,0,
181,10,263,4,0,
181,10,264,4,0,
181,10,268,1,25,
181,10,280,4,0,
181,10,290,4,0,
181,10,324,1,42,
181,10,324,3,0,
181,10,351,4,0,
181,10,363,4,0,
181,10,374,4,0,
181,10,393,3,0,
181,10,408,1,59,
181,10,411,4,0,
181,10,416,4,0,
181,10,431,4,0,
181,10,435,1,34,
181,10,445,4,0,
181,10,451,4,0,
181,11,7,1,1,1
181,11,9,1,30,
181,11,33,1,1,2
181,11,45,1,1,3
181,11,45,1,5,
181,11,63,4,0,
181,11,70,4,0,
181,11,84,1,1,4
181,11,84,1,10,
181,11,85,4,0,
181,11,86,1,1,5
181,11,86,1,14,
181,11,86,4,0,
181,11,87,1,79,
181,11,87,4,0,
181,11,92,4,0,
181,11,104,4,0,
181,11,113,1,63,
181,11,113,4,0,
181,11,148,4,0,
181,11,156,4,0,
181,11,164,4,0,
181,11,178,1,20,
181,11,182,4,0,
181,11,207,4,0,
181,11,213,4,0,
181,11,216,4,0,
181,11,218,4,0,
181,11,219,4,0,
181,11,237,4,0,
181,11,240,4,0,
181,11,249,4,0,
181,11,263,4,0,
181,11,268,1,25,
181,11,280,4,0,
181,11,324,1,55,
181,11,374,4,0,
181,11,408,1,71,
181,11,411,4,0,
181,11,416,4,0,
181,11,435,1,48,
181,11,451,4,0,
181,11,486,1,33,
181,11,496,4,0,
181,11,497,4,0,
181,11,521,4,0,
181,11,523,4,0,
181,11,528,4,0,
181,11,538,1,40,
181,12,9,1,30,
181,12,33,1,1,1
181,12,45,1,1,2
181,12,63,4,0,
181,12,70,4,0,
181,12,84,1,1,3
181,12,84,1,9,
181,12,85,4,0,
181,12,86,1,1,4
181,12,86,1,18,
181,12,87,1,57,
181,12,87,4,0,
181,12,92,4,0,
181,12,104,4,0,
181,12,113,1,42,
181,12,113,4,0,
181,12,148,4,0,
181,12,156,4,0,
181,12,178,1,27,
181,12,182,4,0,
181,12,213,4,0,
181,12,216,4,0,
181,12,218,4,0,
181,12,231,4,0,
181,12,237,4,0,
181,12,240,4,0,
181,12,249,4,0,
181,12,263,4,0,
181,12,264,4,0,
181,12,280,4,0,
181,12,290,4,0,
181,12,351,4,0,
181,13,9,1,30,
181,13,33,1,1,1
181,13,34,3,0,
181,13,38,3,0,
181,13,45,1,1,2
181,13,63,4,0,
181,13,69,3,0,
181,13,70,4,0,
181,13,84,1,1,3
181,13,84,1,9,
181,13,85,4,0,
181,13,86,1,1,4
181,13,86,1,18,
181,13,86,3,0,
181,13,87,1,57,
181,13,87,4,0,
181,13,92,4,0,
181,13,102,3,0,
181,13,104,4,0,
181,13,113,1,42,
181,13,113,4,0,
181,13,148,4,0,
181,13,156,4,0,
181,13,164,3,0,
181,13,178,1,27,
181,13,182,4,0,
181,13,207,3,0,
181,13,213,4,0,
181,13,216,4,0,
181,13,218,4,0,
181,13,231,4,0,
181,13,237,4,0,
181,13,240,4,0,
181,13,249,4,0,
181,13,263,4,0,
181,13,264,4,0,
181,13,280,4,0,
181,13,290,4,0,
181,13,351,4,0,
181,14,7,1,1,1
181,14,7,3,0,
181,14,9,1,30,
181,14,9,3,0,
181,14,33,1,1,2
181,14,36,1,20,
181,14,45,1,1,3
181,14,63,4,0,
181,14,70,4,0,
181,14,84,1,1,5
181,14,84,1,8,
181,14,85,4,0,
181,14,86,1,1,4
181,14,86,1,4,
181,14,86,4,0,
181,14,87,1,62,
181,14,87,4,0,
181,14,92,4,0,
181,14,104,4,0,
181,14,109,1,29,
181,14,113,1,57,
181,14,113,4,0,
181,14,148,4,0,
181,14,156,4,0,
181,14,164,4,0,
181,14,173,3,0,
181,14,178,1,11,
181,14,182,4,0,
181,14,200,3,0,
181,14,207,4,0,
181,14,213,4,0,
181,14,214,3,0,
181,14,215,3,0,
181,14,216,4,0,
181,14,218,4,0,
181,14,219,4,0,
181,14,231,3,0,
181,14,237,4,0,
181,14,240,4,0,
181,14,249,4,0,
181,14,263,4,0,
181,14,268,1,16,
181,14,280,4,0,
181,14,324,1,51,
181,14,324,3,0,
181,14,374,4,0,
181,14,393,3,0,
181,14,408,1,35,
181,14,411,4,0,
181,14,416,4,0,
181,14,435,1,40,
181,14,451,4,0,
181,14,486,1,25,
181,14,495,3,0,
181,14,496,4,0,
181,14,497,4,0,
181,14,521,4,0,
181,14,523,4,0,
181,14,528,4,0,
181,14,538,1,46,
181,15,7,1,1,5
181,15,9,1,30,
181,15,33,1,1,6
181,15,36,1,20,
181,15,45,1,1,7
181,15,63,4,0,
181,15,70,4,0,
181,15,84,1,1,9
181,15,84,1,8,
181,15,85,4,0,
181,15,86,1,1,8
181,15,86,1,4,
181,15,86,4,0,
181,15,87,1,62,
181,15,87,4,0,
181,15,92,4,0,
181,15,104,4,0,
181,15,109,1,29,
181,15,113,1,57,
181,15,113,4,0,
181,15,148,4,0,
181,15,156,4,0,
181,15,164,4,0,
181,15,178,1,11,
181,15,182,4,0,
181,15,192,1,1,1
181,15,207,4,0,
181,15,213,4,0,
181,15,214,4,0,
181,15,216,4,0,
181,15,218,4,0,
181,15,219,4,0,
181,15,237,4,0,
181,15,240,4,0,
181,15,249,4,0,
181,15,263,4,0,
181,15,268,1,16,
181,15,280,4,0,
181,15,324,1,51,
181,15,374,4,0,
181,15,406,1,1,4
181,15,406,1,65,
181,15,408,1,35,
181,15,411,4,0,
181,15,416,4,0,
181,15,435,1,40,
181,15,451,4,0,
181,15,486,1,25,
181,15,496,4,0,
181,15,497,4,0,
181,15,521,4,0,
181,15,523,4,0,
181,15,528,4,0,
181,15,538,1,46,
181,15,569,1,1,3
181,15,590,4,0,
181,15,602,1,1,2
181,15,612,4,0,
181,16,7,1,1,5
181,16,7,3,0,
181,16,9,1,30,1
181,16,9,3,0,
181,16,33,1,1,6
181,16,36,1,20,1
181,16,45,1,1,7
181,16,63,4,0,
181,16,70,4,0,
181,16,84,1,1,9
181,16,84,1,8,1
181,16,85,4,0,
181,16,86,1,1,8
181,16,86,1,4,1
181,16,86,4,0,
181,16,87,1,62,1
181,16,87,4,0,
181,16,92,4,0,
181,16,104,4,0,
181,16,109,1,29,1
181,16,113,1,57,1
181,16,113,4,0,
181,16,148,4,0,
181,16,156,4,0,
181,16,164,4,0,
181,16,173,3,0,
181,16,178,1,11,1
181,16,182,4,0,
181,16,192,1,1,1
181,16,200,3,0,
181,16,207,4,0,
181,16,213,4,0,
181,16,214,4,0,
181,16,215,3,0,
181,16,216,4,0,
181,16,218,4,0,
181,16,219,4,0,
181,16,231,3,0,
181,16,237,4,0,
181,16,240,4,0,
181,16,249,4,0,
181,16,263,4,0,
181,16,264,3,0,
181,16,268,1,16,1
181,16,280,4,0,
181,16,290,4,0,
181,16,324,1,51,1
181,16,324,3,0,
181,16,351,3,0,
181,16,374,4,0,
181,16,393,3,0,
181,16,406,1,1,4
181,16,406,1,65,1
181,16,406,3,0,
181,16,408,1,35,1
181,16,411,4,0,
181,16,416,4,0,
181,16,435,1,40,1
181,16,451,4,0,
181,16,486,1,25,1
181,16,495,3,0,
181,16,496,4,0,
181,16,497,4,0,
181,16,521,4,0,
181,16,523,4,0,
181,16,527,3,0,
181,16,528,4,0,
181,16,538,1,46,1
181,16,569,1,1,3
181,16,590,4,0,
181,16,602,1,1,2
181,16,612,4,0,
181,17,7,1,1,6
181,17,9,1,0,
181,17,9,1,1,1
181,17,33,1,1,7
181,17,36,1,20,
181,17,45,1,1,8
181,17,63,4,0,
181,17,84,1,1,10
181,17,84,1,8,
181,17,85,4,0,
181,17,86,1,1,9
181,17,86,1,4,
181,17,86,4,0,
181,17,87,1,62,
181,17,87,4,0,
181,17,92,4,0,
181,17,104,4,0,
181,17,109,1,29,
181,17,113,1,57,
181,17,113,4,0,
181,17,156,4,0,
181,17,164,4,0,
181,17,178,1,11,
181,17,182,4,0,
181,17,192,1,1,2
181,17,207,4,0,
181,17,213,4,0,
181,17,214,4,0,
181,17,216,4,0,
181,17,218,4,0,
181,17,219,4,0,
181,17,237,4,0,
181,17,240,4,0,
181,17,263,4,0,
181,17,268,1,16,
181,17,280,4,0,
181,17,324,1,51,
181,17,374,4,0,
181,17,406,1,1,5
181,17,406,1,65,
181,17,408,1,35,
181,17,411,4,0,
181,17,416,4,0,
181,17,435,1,40,
181,17,451,4,0,
181,17,486,1,25,
181,17,496,4,0,
181,17,497,4,0,
181,17,521,4,0,
181,17,523,4,0,
181,17,528,4,0,
181,17,538,1,46,
181,17,569,1,1,4
181,17,590,4,0,
181,17,602,1,1,3
181,17,693,4,0,
181,18,7,1,1,6
181,18,9,1,0,
181,18,9,1,1,1
181,18,33,1,1,7
181,18,36,1,20,
181,18,45,1,1,8
181,18,63,4,0,
181,18,84,1,1,10
181,18,84,1,8,
181,18,85,4,0,
181,18,86,1,1,9
181,18,86,1,4,
181,18,86,4,0,
181,18,87,1,62,
181,18,87,4,0,
181,18,92,4,0,
181,18,104,4,0,
181,18,109,1,29,
181,18,113,1,57,
181,18,113,4,0,
181,18,156,4,0,
181,18,164,4,0,
181,18,178,1,11,
181,18,182,4,0,
181,18,192,1,1,2
181,18,207,4,0,
181,18,213,4,0,
181,18,214,4,0,
181,18,216,4,0,
181,18,218,4,0,
181,18,219,4,0,
181,18,237,4,0,
181,18,240,4,0,
181,18,263,4,0,
181,18,268,1,16,
181,18,280,4,0,
181,18,324,1,51,
181,18,374,4,0,
181,18,406,1,1,5
181,18,406,1,65,
181,18,408,1,35,
181,18,411,4,0,
181,18,416,4,0,
181,18,435,1,40,
181,18,451,4,0,
181,18,486,1,25,
181,18,496,4,0,
181,18,497,4,0,
181,18,521,4,0,
181,18,523,4,0,
181,18,528,4,0,
181,18,538,1,46,
181,18,569,1,1,4
181,18,590,4,0,
181,18,602,1,1,3
181,18,693,4,0,
182,3,15,4,0,
182,3,63,4,0,
182,3,71,1,1,1
182,3,76,1,55,
182,3,76,4,0,
182,3,78,1,1,3
182,3,80,1,1,4
182,3,92,4,0,
182,3,104,4,0,
182,3,148,4,0,
182,3,156,4,0,
182,3,173,4,0,
182,3,174,4,0,
182,3,182,4,0,
182,3,202,4,0,
182,3,203,4,0,
182,3,207,4,0,
182,3,213,4,0,
182,3,214,4,0,
182,3,216,4,0,
182,3,218,4,0,
182,3,230,1,1,2
182,3,230,4,0,
182,3,237,4,0,
182,3,241,4,0,
182,4,15,4,0,
182,4,63,4,0,
182,4,71,1,1,1
182,4,76,1,55,
182,4,76,4,0,
182,4,78,1,1,3
182,4,80,1,1,4
182,4,92,4,0,
182,4,104,4,0,
182,4,148,4,0,
182,4,156,4,0,
182,4,173,4,0,
182,4,174,4,0,
182,4,182,4,0,
182,4,202,4,0,
182,4,203,4,0,
182,4,207,4,0,
182,4,213,4,0,
182,4,214,4,0,
182,4,216,4,0,
182,4,218,4,0,
182,4,230,1,1,2
182,4,230,4,0,
182,4,237,4,0,
182,4,241,4,0,
182,5,15,4,0,
182,5,63,4,0,
182,5,71,1,1,1
182,5,76,1,55,
182,5,76,4,0,
182,5,78,1,1,3
182,5,80,1,44,
182,5,92,4,0,
182,5,104,4,0,
182,5,148,4,0,
182,5,156,4,0,
182,5,182,4,0,
182,5,188,4,0,
182,5,202,4,0,
182,5,213,4,0,
182,5,216,4,0,
182,5,218,4,0,
182,5,219,4,0,
182,5,230,1,1,2
182,5,237,4,0,
182,5,241,4,0,
182,5,263,4,0,
182,5,290,4,0,
182,5,331,4,0,
182,5,345,1,1,4
182,6,14,3,0,
182,6,15,4,0,
182,6,38,3,0,
182,6,63,4,0,
182,6,71,1,1,1
182,6,76,1,55,
182,6,76,4,0,
182,6,78,1,1,3
182,6,80,1,44,
182,6,92,4,0,
182,6,102,3,0,
182,6,104,4,0,
182,6,148,4,0,
182,6,156,4,0,
182,6,164,3,0,
182,6,173,3,0,
182,6,182,4,0,
182,6,188,4,0,
182,6,202,4,0,
182,6,203,3,0,
182,6,207,3,0,
182,6,213,4,0,
182,6,214,3,0,
182,6,216,4,0,
182,6,218,4,0,
182,6,219,4,0,
182,6,230,1,1,2
182,6,237,4,0,
182,6,241,4,0,
182,6,263,4,0,
182,6,290,4,0,
182,6,331,4,0,
182,6,345,1,1,4
182,7,14,3,0,
182,7,15,4,0,
182,7,38,3,0,
182,7,63,4,0,
182,7,71,1,1,1
182,7,76,1,55,
182,7,76,4,0,
182,7,78,1,1,3
182,7,80,1,44,
182,7,92,4,0,
182,7,102,3,0,
182,7,104,4,0,
182,7,148,4,0,
182,7,156,4,0,
182,7,164,3,0,
182,7,182,4,0,
182,7,188,4,0,
182,7,202,4,0,
182,7,213,4,0,
182,7,216,4,0,
182,7,218,4,0,
182,7,219,4,0,
182,7,230,1,1,2
182,7,237,4,0,
182,7,241,4,0,
182,7,263,4,0,
182,7,290,4,0,
182,7,331,4,0,
182,7,345,1,1,4
182,8,14,4,0,
182,8,15,4,0,
182,8,63,4,0,
182,8,72,1,1,1
182,8,76,4,0,
182,8,78,1,1,3
182,8,92,4,0,
182,8,104,4,0,
182,8,148,4,0,
182,8,156,4,0,
182,8,164,4,0,
182,8,182,4,0,
182,8,188,4,0,
182,8,202,4,0,
182,8,203,4,0,
182,8,207,4,0,
182,8,213,4,0,
182,8,214,4,0,
182,8,216,4,0,
182,8,218,4,0,
182,8,219,4,0,
182,8,230,1,1,2
182,8,237,4,0,
182,8,241,1,1,4
182,8,241,4,0,
182,8,263,4,0,
182,8,290,4,0,
182,8,331,4,0,
182,8,345,1,23,
182,8,363,4,0,
182,8,374,4,0,
182,8,409,4,0,
182,8,412,4,0,
182,8,416,4,0,
182,8,437,1,53,
182,8,445,4,0,
182,8,447,4,0,
182,9,14,4,0,
182,9,15,4,0,
182,9,63,4,0,
182,9,72,1,1,2
182,9,76,4,0,
182,9,78,1,1,4
182,9,92,4,0,
182,9,104,4,0,
182,9,148,4,0,
182,9,156,4,0,
182,9,164,4,0,
182,9,173,3,0,
182,9,182,4,0,
182,9,188,4,0,
182,9,202,4,0,
182,9,203,4,0,
182,9,207,4,0,
182,9,213,4,0,
182,9,214,4,0,
182,9,216,4,0,
182,9,218,4,0,
182,9,219,4,0,
182,9,230,1,1,3
182,9,235,3,0,
182,9,237,4,0,
182,9,241,1,1,5
182,9,241,4,0,
182,9,253,3,0,
182,9,263,4,0,
182,9,290,4,0,
182,9,331,4,0,
182,9,345,1,23,
182,9,348,1,1,1
182,9,363,4,0,
182,9,374,4,0,
182,9,380,3,0,
182,9,402,3,0,
182,9,409,4,0,
182,9,412,4,0,
182,9,416,4,0,
182,9,437,1,53,
182,9,445,4,0,
182,9,447,4,0,
182,10,14,4,0,
182,10,15,4,0,
182,10,63,4,0,
182,10,72,1,1,2
182,10,76,4,0,
182,10,78,1,1,4
182,10,92,4,0,
182,10,104,4,0,
182,10,148,4,0,
182,10,156,4,0,
182,10,164,4,0,
182,10,173,3,0,
182,10,182,4,0,
182,10,188,4,0,
182,10,202,4,0,
182,10,203,4,0,
182,10,207,4,0,
182,10,213,4,0,
182,10,214,4,0,
182,10,216,4,0,
182,10,218,4,0,
182,10,219,4,0,
182,10,230,1,1,3
182,10,235,3,0,
182,10,237,4,0,
182,10,241,1,1,5
182,10,241,4,0,
182,10,253,3,0,
182,10,263,4,0,
182,10,290,4,0,
182,10,331,4,0,
182,10,345,1,23,
182,10,348,1,1,1
182,10,363,4,0,
182,10,374,4,0,
182,10,380,3,0,
182,10,388,3,0,
182,10,402,3,0,
182,10,409,4,0,
182,10,412,4,0,
182,10,416,4,0,
182,10,437,1,53,
182,10,445,4,0,
182,10,447,4,0,
182,11,14,4,0,
182,11,15,4,0,
182,11,63,4,0,
182,11,72,1,1,2
182,11,76,4,0,
182,11,78,1,1,4
182,11,92,4,0,
182,11,104,4,0,
182,11,148,4,0,
182,11,156,4,0,
182,11,164,4,0,
182,11,182,4,0,
182,11,188,4,0,
182,11,207,4,0,
182,11,213,4,0,
182,11,216,4,0,
182,11,218,4,0,
182,11,219,4,0,
182,11,230,1,1,3
182,11,237,4,0,
182,11,241,1,1,5
182,11,241,4,0,
182,11,263,4,0,
182,11,345,1,23,
182,11,348,1,1,1
182,11,374,4,0,
182,11,412,4,0,
182,11,416,4,0,
182,11,437,1,53,
182,11,447,4,0,
182,11,474,4,0,
182,11,496,4,0,
182,12,15,4,0,
182,12,63,4,0,
182,12,71,1,1,1
182,12,76,1,55,
182,12,76,4,0,
182,12,78,1,1,3
182,12,80,1,44,
182,12,92,4,0,
182,12,104,4,0,
182,12,148,4,0,
182,12,156,4,0,
182,12,182,4,0,
182,12,188,4,0,
182,12,202,4,0,
182,12,213,4,0,
182,12,216,4,0,
182,12,218,4,0,
182,12,219,4,0,
182,12,230,1,1,2
182,12,237,4,0,
182,12,241,4,0,
182,12,263,4,0,
182,12,290,4,0,
182,12,331,4,0,
182,12,345,1,1,4
182,13,15,4,0,
182,13,38,3,0,
182,13,63,4,0,
182,13,71,1,1,1
182,13,76,1,55,
182,13,76,4,0,
182,13,78,1,1,3
182,13,80,1,44,
182,13,92,4,0,
182,13,102,3,0,
182,13,104,4,0,
182,13,148,4,0,
182,13,156,4,0,
182,13,164,3,0,
182,13,182,4,0,
182,13,188,4,0,
182,13,202,4,0,
182,13,207,3,0,
182,13,213,4,0,
182,13,216,4,0,
182,13,218,4,0,
182,13,219,4,0,
182,13,230,1,1,2
182,13,237,4,0,
182,13,241,4,0,
182,13,263,4,0,
182,13,290,4,0,
182,13,331,4,0,
182,13,345,1,1,4
182,14,14,4,0,
182,14,15,4,0,
182,14,63,4,0,
182,14,72,1,1,2
182,14,76,4,0,
182,14,78,1,1,4
182,14,92,4,0,
182,14,104,4,0,
182,14,148,4,0,
182,14,156,4,0,
182,14,164,4,0,
182,14,173,3,0,
182,14,182,4,0,
182,14,188,4,0,
182,14,202,3,0,
182,14,207,4,0,
182,14,213,4,0,
182,14,214,3,0,
182,14,216,4,0,
182,14,218,4,0,
182,14,219,4,0,
182,14,230,1,1,3
182,14,235,3,0,
182,14,237,4,0,
182,14,241,1,1,5
182,14,241,4,0,
182,14,253,3,0,
182,14,263,4,0,
182,14,345,1,23,
182,14,348,1,1,1
182,14,374,4,0,
182,14,380,3,0,
182,14,388,3,0,
182,14,402,3,0,
182,14,409,3,0,
182,14,412,4,0,
182,14,416,4,0,
182,14,437,1,53,
182,14,447,4,0,
182,14,474,4,0,
182,14,495,3,0,
182,14,496,4,0,
182,15,14,4,0,
182,15,15,4,0,
182,15,63,4,0,
182,15,72,1,1,3
182,15,76,4,0,
182,15,78,1,1,5
182,15,92,4,0,
182,15,104,4,0,
182,15,148,4,0,
182,15,156,4,0,
182,15,164,4,0,
182,15,182,4,0,
182,15,188,4,0,
182,15,207,4,0,
182,15,213,4,0,
182,15,214,4,0,
182,15,216,4,0,
182,15,218,4,0,
182,15,219,4,0,
182,15,230,1,1,4
182,15,237,4,0,
182,15,241,1,1,6
182,15,241,4,0,
182,15,263,4,0,
182,15,267,4,0,
182,15,345,1,23,
182,15,348,1,1,2
182,15,374,4,0,
182,15,412,4,0,
182,15,416,4,0,
182,15,437,1,1,1
182,15,437,1,53,
182,15,447,4,0,
182,15,474,4,0,
182,15,496,4,0,
182,15,572,1,50,
182,15,590,4,0,
182,15,605,4,0,
182,15,611,4,0,
182,16,14,4,0,
182,16,15,4,0,
182,16,63,4,0,
182,16,72,1,1,3
182,16,76,4,0,
182,16,78,1,1,5
182,16,92,4,0,
182,16,104,4,0,
182,16,148,4,0,
182,16,156,4,0,
182,16,164,4,0,
182,16,173,3,0,
182,16,182,4,0,
182,16,188,4,0,
182,16,202,3,0,
182,16,207,4,0,
182,16,213,4,0,
182,16,214,4,0,
182,16,216,4,0,
182,16,218,4,0,
182,16,219,4,0,
182,16,230,1,1,4
182,16,235,3,0,
182,16,237,4,0,
182,16,241,1,1,6
182,16,241,4,0,
182,16,253,3,0,
182,16,263,4,0,
182,16,267,4,0,
182,16,290,4,0,
182,16,345,1,24,1
182,16,348,1,1,2
182,16,374,4,0,
182,16,380,3,0,
182,16,388,3,0,
182,16,402,3,0,
182,16,409,3,0,
182,16,412,4,0,
182,16,416,4,0,
182,16,437,1,1,1
182,16,437,1,64,1
182,16,447,4,0,
182,16,474,4,0,
182,16,495,3,0,
182,16,496,4,0,
182,16,572,1,49,1
182,16,590,4,0,
182,16,605,4,0,
182,16,611,4,0,
182,17,14,4,0,
182,17,63,4,0,
182,17,72,1,1,4
182,17,76,4,0,
182,17,78,1,1,6
182,17,80,1,59,
182,17,92,4,0,
182,17,104,4,0,
182,17,156,4,0,
182,17,164,4,0,
182,17,182,4,0,
182,17,188,4,0,
182,17,207,4,0,
182,17,213,4,0,
182,17,214,4,0,
182,17,216,4,0,
182,17,218,4,0,
182,17,219,4,0,
182,17,230,1,1,5
182,17,237,4,0,
182,17,241,1,1,7
182,17,241,4,0,
182,17,263,4,0,
182,17,267,4,0,
182,17,345,1,0,
182,17,345,1,1,1
182,17,348,1,1,3
182,17,374,4,0,
182,17,412,4,0,
182,17,416,4,0,
182,17,437,1,1,2
182,17,437,1,69,
182,17,447,4,0,
182,17,474,4,0,
182,17,483,1,39,
182,17,496,4,0,
182,17,572,1,49,
182,17,590,4,0,
182,17,605,4,0,
182,17,611,4,0,
182,18,14,4,0,
182,18,63,4,0,
182,18,72,1,1,4
182,18,76,4,0,
182,18,78,1,1,6
182,18,80,1,59,
182,18,92,4,0,
182,18,104,4,0,
182,18,156,4,0,
182,18,164,4,0,
182,18,182,4,0,
182,18,188,4,0,
182,18,207,4,0,
182,18,213,4,0,
182,18,214,4,0,
182,18,216,4,0,
182,18,218,4,0,
182,18,219,4,0,
182,18,230,1,1,5
182,18,237,4,0,
182,18,241,1,1,7
182,18,241,4,0,
182,18,263,4,0,
182,18,267,4,0,
182,18,345,1,0,
182,18,345,1,1,1
182,18,348,1,1,3
182,18,374,4,0,
182,18,412,4,0,
182,18,416,4,0,
182,18,437,1,1,2
182,18,437,1,69,
182,18,447,4,0,
182,18,474,4,0,
182,18,483,1,39,
182,18,496,4,0,
182,18,572,1,49,
182,18,590,4,0,
182,18,605,4,0,
182,18,611,4,0,
183,3,8,4,0,
183,3,29,4,0,
183,3,33,1,1,
183,3,38,1,28,
183,3,39,1,6,
183,3,48,2,0,
183,3,55,1,10,
183,3,57,4,0,
183,3,59,4,0,
183,3,61,1,21,
183,3,92,4,0,
183,3,104,4,0,
183,3,111,1,3,
183,3,111,4,0,
183,3,113,2,0,
183,3,127,4,0,
183,3,129,4,0,
183,3,133,2,0,
183,3,156,4,0,
183,3,173,4,0,
183,3,174,4,0,
183,3,182,4,0,
183,3,187,2,0,
183,3,189,4,0,
183,3,193,2,0,
183,3,195,2,0,
183,3,196,4,0,
183,3,203,4,0,
183,3,205,1,15,
183,3,205,4,0,
183,3,207,4,0,
183,3,213,4,0,
183,3,214,4,0,
183,3,216,4,0,
183,3,217,2,0,
183,3,218,4,0,
183,3,223,4,0,
183,3,231,4,0,
183,3,237,4,0,
183,3,240,1,36,
183,3,240,4,0,
183,3,248,2,0,
183,3,250,4,0,
183,4,8,4,0,
183,4,29,4,0,
183,4,33,1,1,
183,4,38,1,28,
183,4,39,1,6,
183,4,48,2,0,
183,4,55,1,10,
183,4,57,4,0,
183,4,58,3,0,
183,4,59,4,0,
183,4,61,1,21,
183,4,92,4,0,
183,4,104,4,0,
183,4,111,1,3,
183,4,111,4,0,
183,4,113,2,0,
183,4,127,4,0,
183,4,129,4,0,
183,4,133,2,0,
183,4,156,4,0,
183,4,173,4,0,
183,4,174,4,0,
183,4,182,4,0,
183,4,187,2,0,
183,4,189,4,0,
183,4,193,2,0,
183,4,195,2,0,
183,4,196,4,0,
183,4,203,4,0,
183,4,205,1,15,
183,4,205,4,0,
183,4,207,4,0,
183,4,213,4,0,
183,4,214,4,0,
183,4,216,4,0,
183,4,217,2,0,
183,4,218,4,0,
183,4,223,4,0,
183,4,231,4,0,
183,4,237,4,0,
183,4,240,1,36,
183,4,240,4,0,
183,4,248,2,0,
183,4,250,4,0,
183,5,33,1,1,
183,5,38,1,28,
183,5,39,1,6,
183,5,48,2,0,
183,5,55,1,10,
183,5,56,1,45,
183,5,57,4,0,
183,5,58,4,0,
183,5,59,4,0,
183,5,61,1,21,
183,5,70,4,0,
183,5,91,4,0,
183,5,92,4,0,
183,5,104,4,0,
183,5,111,1,3,
183,5,113,2,0,
183,5,127,4,0,
183,5,133,2,0,
183,5,156,4,0,
183,5,164,2,0,
183,5,182,4,0,
183,5,187,2,0,
183,5,195,2,0,
183,5,205,1,15,
183,5,213,4,0,
183,5,216,4,0,
183,5,217,2,0,
183,5,218,4,0,
183,5,231,4,0,
183,5,237,4,0,
183,5,240,1,36,
183,5,240,4,0,
183,5,248,2,0,
183,5,249,4,0,
183,5,258,4,0,
183,5,263,4,0,
183,5,264,4,0,
183,5,280,4,0,
183,5,290,4,0,
183,5,291,4,0,
183,5,352,4,0,
183,6,5,3,0,
183,6,8,3,0,
183,6,25,3,0,
183,6,33,1,1,
183,6,34,3,0,
183,6,38,1,28,
183,6,38,3,0,
183,6,39,1,6,
183,6,48,2,0,
183,6,55,1,10,
183,6,56,1,45,
183,6,57,4,0,
183,6,58,4,0,
183,6,59,4,0,
183,6,61,1,21,
183,6,69,3,0,
183,6,70,4,0,
183,6,91,4,0,
183,6,92,4,0,
183,6,102,3,0,
183,6,104,4,0,
183,6,111,1,3,
183,6,111,3,0,
183,6,113,2,0,
183,6,127,4,0,
183,6,129,3,0,
183,6,133,2,0,
183,6,156,4,0,
183,6,164,2,0,
183,6,164,3,0,
183,6,173,3,0,
183,6,182,4,0,
183,6,187,2,0,
183,6,189,3,0,
183,6,195,2,0,
183,6,196,3,0,
183,6,203,3,0,
183,6,205,1,15,
183,6,205,3,0,
183,6,207,3,0,
183,6,213,4,0,
183,6,214,3,0,
183,6,216,4,0,
183,6,217,2,0,
183,6,218,4,0,
183,6,223,3,0,
183,6,231,4,0,
183,6,237,4,0,
183,6,240,1,36,
183,6,240,4,0,
183,6,248,2,0,
183,6,249,4,0,
183,6,258,4,0,
183,6,263,4,0,
183,6,264,4,0,
183,6,280,4,0,
183,6,290,4,0,
183,6,291,4,0,
183,6,352,4,0,
183,7,5,3,0,
183,7,25,3,0,
183,7,33,1,1,
183,7,34,3,0,
183,7,38,1,28,
183,7,38,3,0,
183,7,39,1,6,
183,7,48,2,0,
183,7,55,1,10,
183,7,56,1,45,
183,7,57,4,0,
183,7,58,4,0,
183,7,59,4,0,
183,7,61,1,21,
183,7,69,3,0,
183,7,70,4,0,
183,7,91,4,0,
183,7,92,4,0,
183,7,102,3,0,
183,7,104,4,0,
183,7,111,1,3,
183,7,113,2,0,
183,7,127,4,0,
183,7,133,2,0,
183,7,156,4,0,
183,7,164,2,0,
183,7,164,3,0,
183,7,182,4,0,
183,7,187,2,0,
183,7,195,2,0,
183,7,205,1,15,
183,7,213,4,0,
183,7,216,4,0,
183,7,217,2,0,
183,7,218,4,0,
183,7,231,4,0,
183,7,237,4,0,
183,7,240,1,36,
183,7,240,4,0,
183,7,248,2,0,
183,7,249,4,0,
183,7,258,4,0,
183,7,263,4,0,
183,7,264,4,0,
183,7,280,4,0,
183,7,290,4,0,
183,7,291,4,0,
183,7,352,4,0,
183,8,33,1,1,
183,8,38,1,27,
183,8,39,1,7,
183,8,48,2,0,
183,8,55,1,10,
183,8,56,1,42,
183,8,57,4,0,
183,8,58,4,0,
183,8,59,4,0,
183,8,61,1,18,
183,8,70,4,0,
183,8,91,4,0,
183,8,92,4,0,
183,8,104,4,0,
183,8,111,1,2,
183,8,113,2,0,
183,8,127,4,0,
183,8,133,2,0,
183,8,156,4,0,
183,8,164,2,0,
183,8,164,4,0,
183,8,182,4,0,
183,8,187,2,0,
183,8,195,2,0,
183,8,203,4,0,
183,8,205,1,15,
183,8,207,4,0,
183,8,213,4,0,
183,8,214,4,0,
183,8,216,4,0,
183,8,217,2,0,
183,8,218,4,0,
183,8,231,4,0,
183,8,237,4,0,
183,8,240,1,32,
183,8,240,4,0,
183,8,248,2,0,
183,8,249,4,0,
183,8,258,4,0,
183,8,263,4,0,
183,8,264,4,0,
183,8,276,2,0,
183,8,280,4,0,
183,8,287,2,0,
183,8,290,4,0,
183,8,352,4,0,
183,8,363,4,0,
183,8,374,4,0,
183,8,392,1,23,
183,8,401,1,37,
183,8,445,4,0,
183,8,447,4,0,
183,8,453,2,0,
183,9,8,3,0,
183,9,33,1,1,
183,9,38,1,27,
183,9,39,1,7,
183,9,48,2,0,
183,9,55,1,10,
183,9,56,1,42,
183,9,57,4,0,
183,9,58,4,0,
183,9,59,4,0,
183,9,61,1,18,
183,9,70,4,0,
183,9,91,4,0,
183,9,92,4,0,
183,9,104,4,0,
183,9,111,1,2,
183,9,113,2,0,
183,9,127,4,0,
183,9,129,3,0,
183,9,133,2,0,
183,9,156,4,0,
183,9,164,2,0,
183,9,164,4,0,
183,9,173,3,0,
183,9,182,4,0,
183,9,187,2,0,
183,9,189,3,0,
183,9,195,2,0,
183,9,196,3,0,
183,9,203,4,0,
183,9,205,1,15,
183,9,205,3,0,
183,9,207,4,0,
183,9,213,4,0,
183,9,214,4,0,
183,9,216,4,0,
183,9,217,2,0,
183,9,218,4,0,
183,9,231,4,0,
183,9,237,4,0,
183,9,240,1,32,
183,9,240,4,0,
183,9,248,2,0,
183,9,249,4,0,
183,9,258,4,0,
183,9,263,4,0,
183,9,264,4,0,
183,9,270,3,0,
183,9,276,2,0,
183,9,276,3,0,
183,9,280,4,0,
183,9,282,3,0,
183,9,287,2,0,
183,9,290,4,0,
183,9,291,3,0,
183,9,352,4,0,
183,9,363,4,0,
183,9,374,4,0,
183,9,392,1,23,
183,9,401,1,37,
183,9,401,3,0,
183,9,445,4,0,
183,9,447,4,0,
183,9,453,2,0,
183,10,8,3,0,
183,10,29,3,0,
183,10,33,1,1,
183,10,34,2,0,
183,10,38,1,27,
183,10,39,1,7,
183,10,48,2,0,
183,10,55,1,10,
183,10,56,1,42,
183,10,57,4,0,
183,10,58,4,0,
183,10,59,4,0,
183,10,61,1,18,
183,10,70,4,0,
183,10,91,4,0,
183,10,92,4,0,
183,10,104,4,0,
183,10,111,1,2,
183,10,113,2,0,
183,10,127,4,0,
183,10,129,3,0,
183,10,133,2,0,
183,10,156,4,0,
183,10,164,2,0,
183,10,164,4,0,
183,10,173,3,0,
183,10,182,4,0,
183,10,187,2,0,
183,10,189,3,0,
183,10,195,2,0,
183,10,196,3,0,
183,10,203,4,0,
183,10,205,1,15,
183,10,205,3,0,
183,10,207,4,0,
183,10,213,4,0,
183,10,214,4,0,
183,10,216,4,0,
183,10,217,2,0,
183,10,218,4,0,
183,10,231,4,0,
183,10,237,4,0,
183,10,240,1,32,
183,10,240,4,0,
183,10,248,2,0,
183,10,249,4,0,
183,10,250,4,0,
183,10,258,4,0,
183,10,263,4,0,
183,10,264,4,0,
183,10,270,3,0,
183,10,276,2,0,
183,10,276,3,0,
183,10,280,4,0,
183,10,282,3,0,
183,10,287,2,0,
183,10,290,4,0,
183,10,291,3,0,
183,10,352,4,0,
183,10,363,4,0,
183,10,374,4,0,
183,10,392,1,23,
183,10,401,1,37,
183,10,401,3,0,
183,10,445,4,0,
183,10,447,4,0,
183,10,453,2,0,
183,11,33,1,1,
183,11,34,2,0,
183,11,38,1,27,
183,11,39,1,7,
183,11,48,2,0,
183,11,55,1,10,
183,11,56,1,42,
183,11,57,4,0,
183,11,58,4,0,
183,11,59,4,0,
183,11,61,1,18,
183,11,70,4,0,
183,11,91,4,0,
183,11,92,4,0,
183,11,104,4,0,
183,11,111,1,2,
183,11,113,4,0,
183,11,127,4,0,
183,11,133,2,0,
183,11,156,4,0,
183,11,164,4,0,
183,11,182,4,0,
183,11,187,2,0,
183,11,195,2,0,
183,11,205,1,15,
183,11,207,4,0,
183,11,213,4,0,
183,11,216,4,0,
183,11,217,2,0,
183,11,218,4,0,
183,11,237,4,0,
183,11,240,1,32,
183,11,240,4,0,
183,11,248,2,0,
183,11,249,4,0,
183,11,258,4,0,
183,11,263,4,0,
183,11,276,2,0,
183,11,280,4,0,
183,11,287,2,0,
183,11,291,4,0,
183,11,330,2,0,
183,11,346,2,0,
183,11,374,4,0,
183,11,392,1,23,
183,11,401,1,37,
183,11,447,4,0,
183,11,453,2,0,
183,11,496,4,0,
183,11,503,4,0,
183,11,526,4,0,
183,12,33,1,1,
183,12,38,1,28,
183,12,39,1,6,
183,12,55,1,10,
183,12,56,1,45,
183,12,57,4,0,
183,12,58,4,0,
183,12,59,4,0,
183,12,61,1,21,
183,12,70,4,0,
183,12,91,4,0,
183,12,92,4,0,
183,12,104,4,0,
183,12,111,1,3,
183,12,127,4,0,
183,12,156,4,0,
183,12,182,4,0,
183,12,205,1,15,
183,12,213,4,0,
183,12,216,4,0,
183,12,218,4,0,
183,12,231,4,0,
183,12,237,4,0,
183,12,240,1,36,
183,12,240,4,0,
183,12,249,4,0,
183,12,258,4,0,
183,12,263,4,0,
183,12,264,4,0,
183,12,280,4,0,
183,12,290,4,0,
183,12,291,4,0,
183,12,352,4,0,
183,13,33,1,1,
183,13,34,3,0,
183,13,38,1,28,
183,13,38,3,0,
183,13,39,1,6,
183,13,55,1,10,
183,13,56,1,45,
183,13,57,4,0,
183,13,58,4,0,
183,13,59,4,0,
183,13,61,1,21,
183,13,69,3,0,
183,13,70,4,0,
183,13,91,4,0,
183,13,92,4,0,
183,13,102,3,0,
183,13,104,4,0,
183,13,111,1,3,
183,13,127,4,0,
183,13,156,4,0,
183,13,164,3,0,
183,13,182,4,0,
183,13,196,3,0,
183,13,205,1,15,
183,13,207,3,0,
183,13,213,4,0,
183,13,216,4,0,
183,13,218,4,0,
183,13,231,4,0,
183,13,237,4,0,
183,13,240,1,36,
183,13,240,4,0,
183,13,249,4,0,
183,13,258,4,0,
183,13,263,4,0,
183,13,264,4,0,
183,13,280,4,0,
183,13,290,4,0,
183,13,291,4,0,
183,13,352,4,0,
183,14,8,3,0,
183,14,33,1,1,1
183,14,34,2,0,
183,14,38,1,23,
183,14,39,1,2,
183,14,48,2,0,
183,14,55,1,7,
183,14,56,1,40,
183,14,57,4,0,
183,14,58,4,0,
183,14,59,4,0,
183,14,61,1,13,
183,14,70,4,0,
183,14,91,4,0,
183,14,92,4,0,
183,14,104,4,0,
183,14,111,1,10,1
183,14,113,4,0,
183,14,127,4,0,
183,14,133,2,0,
183,14,145,1,1,2
183,14,156,4,0,
183,14,164,4,0,
183,14,173,3,0,
183,14,182,4,0,
183,14,187,2,0,
183,14,195,2,0,
183,14,196,3,0,
183,14,205,1,10,2
183,14,207,4,0,
183,14,213,4,0,
183,14,214,3,0,
183,14,216,4,0,
183,14,217,2,0,
183,14,218,4,0,
183,14,231,3,0,
183,14,237,4,0,
183,14,240,1,31,
183,14,240,4,0,
183,14,248,2,0,
183,14,249,4,0,
183,14,258,4,0,
183,14,263,4,0,
183,14,270,1,16,
183,14,270,3,0,
183,14,276,1,37,
183,14,276,2,0,
183,14,276,3,0,
183,14,280,4,0,
183,14,282,3,0,
183,14,287,2,0,
183,14,291,4,0,
183,14,304,3,0,
183,14,330,2,0,
183,14,340,3,0,
183,14,343,3,0,
183,14,346,1,5,
183,14,346,2,0,
183,14,374,4,0,
183,14,392,1,28,
183,14,401,1,20,
183,14,401,3,0,
183,14,447,4,0,
183,14,453,2,0,
183,14,496,4,0,
183,14,503,4,0,
183,14,526,4,0,
183,15,33,1,1,1
183,15,34,2,0,
183,15,38,1,23,
183,15,39,1,2,
183,15,48,2,0,
183,15,55,1,1,2
183,15,56,1,40,
183,15,57,4,0,
183,15,58,4,0,
183,15,59,4,0,
183,15,61,1,13,
183,15,70,4,0,
183,15,91,4,0,
183,15,92,4,0,
183,15,104,4,0,
183,15,111,1,10,1
183,15,113,4,0,
183,15,127,4,0,
183,15,133,2,0,
183,15,145,1,7,
183,15,156,4,0,
183,15,164,4,0,
183,15,182,4,0,
183,15,187,2,0,
183,15,195,2,0,
183,15,205,1,10,2
183,15,207,4,0,
183,15,213,4,0,
183,15,214,4,0,
183,15,216,4,0,
183,15,217,2,0,
183,15,218,4,0,
183,15,237,4,0,
183,15,240,1,31,
183,15,240,4,0,
183,15,248,2,0,
183,15,249,4,0,
183,15,258,4,0,
183,15,263,4,0,
183,15,270,1,16,
183,15,276,1,37,
183,15,276,2,0,
183,15,280,4,0,
183,15,287,2,0,
183,15,293,2,0,
183,15,330,2,0,
183,15,346,1,5,
183,15,346,2,0,
183,15,374,4,0,
183,15,392,1,28,
183,15,401,1,20,
183,15,447,4,0,
183,15,453,2,0,
183,15,496,4,0,
183,15,503,4,0,
183,15,583,1,45,
183,15,590,4,0,
183,15,612,4,0,
183,16,8,3,0,
183,16,33,1,1,1
183,16,34,2,0,
183,16,38,1,37,1
183,16,39,1,2,1
183,16,48,2,0,
183,16,55,1,1,2
183,16,56,1,47,1
183,16,57,4,0,
183,16,58,4,0,
183,16,59,4,0,
183,16,61,1,13,1
183,16,70,4,0,
183,16,91,4,0,
183,16,92,4,0,
183,16,104,4,0,
183,16,111,1,10,1
183,16,113,4,0,
183,16,127,4,0,
183,16,133,2,0,
183,16,145,1,7,1
183,16,156,4,0,
183,16,164,4,0,
183,16,173,3,0,
183,16,182,4,0,
183,16,187,2,0,
183,16,195,2,0,
183,16,196,3,0,
183,16,205,1,10,2
183,16,207,4,0,
183,16,213,4,0,
183,16,214,4,0,
183,16,216,4,0,
183,16,217,2,0,
183,16,218,4,0,
183,16,231,3,0,
183,16,237,4,0,
183,16,240,1,31,1
183,16,240,4,0,
183,16,248,2,0,
183,16,249,4,0,
183,16,258,4,0,
183,16,263,4,0,
183,16,264,3,0,
183,16,270,1,16,1
183,16,270,3,0,
183,16,276,1,40,1
183,16,276,2,0,
183,16,276,3,0,
183,16,280,4,0,
183,16,282,3,0,
183,16,287,2,0,
183,16,290,4,0,
183,16,291,4,0,
183,16,293,2,0,
183,16,304,3,0,
183,16,330,2,0,
183,16,340,3,0,
183,16,343,3,0,
183,16,346,1,5,1
183,16,346,2,0,
183,16,352,3,0,
183,16,374,4,0,
183,16,392,1,28,1
183,16,401,1,20,1
183,16,401,3,0,
183,16,447,4,0,
183,16,453,2,0,
183,16,496,4,0,
183,16,503,4,0,
183,16,583,1,23,1
183,16,590,4,0,
183,16,612,4,0,
183,17,33,1,1,1
183,17,34,2,0,
183,17,38,1,37,
183,17,39,1,2,
183,17,48,2,0,
183,17,55,1,1,2
183,17,56,1,47,
183,17,57,4,0,
183,17,58,4,0,
183,17,59,4,0,
183,17,61,1,13,
183,17,92,4,0,
183,17,104,4,0,
183,17,111,1,10,1
183,17,113,4,0,
183,17,127,4,0,
183,17,133,2,0,
183,17,145,1,7,
183,17,156,4,0,
183,17,164,4,0,
183,17,182,4,0,
183,17,187,2,0,
183,17,195,2,0,
183,17,205,1,10,2
183,17,207,4,0,
183,17,213,4,0,
183,17,214,4,0,
183,17,216,4,0,
183,17,217,2,0,
183,17,218,4,0,
183,17,237,4,0,
183,17,240,1,31,
183,17,240,4,0,
183,17,248,2,0,
183,17,258,4,0,
183,17,263,4,0,
183,17,270,1,16,
183,17,276,1,40,
183,17,276,2,0,
183,17,280,4,0,
183,17,287,2,0,
183,17,293,2,0,
183,17,330,2,0,
183,17,346,1,5,
183,17,346,2,0,
183,17,374,4,0,
183,17,392,1,28,
183,17,401,1,20,
183,17,447,4,0,
183,17,453,2,0,
183,17,496,4,0,
183,17,503,4,0,
183,17,526,4,0,
183,17,583,1,23,
183,17,590,4,0,
183,18,33,1,1,1
183,18,34,2,0,
183,18,38,1,37,
183,18,39,1,2,
183,18,48,2,0,
183,18,55,1,1,2
183,18,56,1,47,
183,18,57,4,0,
183,18,58,4,0,
183,18,59,4,0,
183,18,61,1,13,
183,18,92,4,0,
183,18,104,4,0,
183,18,111,1,10,1
183,18,113,4,0,
183,18,127,4,0,
183,18,133,2,0,
183,18,145,1,7,
183,18,156,4,0,
183,18,164,4,0,
183,18,182,4,0,
183,18,187,2,0,
183,18,195,2,0,
183,18,205,1,10,2
183,18,207,4,0,
183,18,213,4,0,
183,18,214,4,0,
183,18,216,4,0,
183,18,217,2,0,
183,18,218,4,0,
183,18,237,4,0,
183,18,240,1,31,
183,18,240,4,0,
183,18,248,2,0,
183,18,258,4,0,
183,18,263,4,0,
183,18,270,1,16,
183,18,276,1,40,
183,18,276,2,0,
183,18,280,4,0,
183,18,287,2,0,
183,18,293,2,0,
183,18,330,2,0,
183,18,346,1,5,
183,18,346,2,0,
183,18,374,4,0,
183,18,392,1,28,
183,18,401,1,20,
183,18,447,4,0,
183,18,453,2,0,
183,18,496,4,0,
183,18,503,4,0,
183,18,526,4,0,
183,18,583,1,23,
183,18,590,4,0,
184,3,8,4,0,
184,3,29,4,0,
184,3,33,1,1,1
184,3,38,1,36,
184,3,39,1,1,3
184,3,39,1,6,
184,3,55,1,1,4
184,3,55,1,10,
184,3,57,4,0,
184,3,59,4,0,
184,3,61,1,25,
184,3,63,4,0,
184,3,70,4,0,
184,3,92,4,0,
184,3,104,4,0,
184,3,111,1,1,2
184,3,111,1,3,
184,3,111,4,0,
184,3,127,4,0,
184,3,129,4,0,
184,3,156,4,0,
184,3,173,4,0,
184,3,174,4,0,
184,3,182,4,0,
184,3,189,4,0,
184,3,196,4,0,
184,3,203,4,0,
184,3,205,1,15,
184,3,205,4,0,
184,3,207,4,0,
184,3,213,4,0,
184,3,214,4,0,
184,3,216,4,0,
184,3,218,4,0,
184,3,223,4,0,
184,3,231,4,0,
184,3,237,4,0,
184,3,240,1,48,
184,3,240,4,0,
184,3,249,4,0,
184,3,250,4,0,
184,4,8,4,0,
184,4,29,4,0,
184,4,33,1,1,1
184,4,38,1,36,
184,4,39,1,1,3
184,4,39,1,6,
184,4,55,1,1,4
184,4,55,1,10,
184,4,57,4,0,
184,4,58,3,0,
184,4,59,4,0,
184,4,61,1,25,
184,4,63,4,0,
184,4,70,4,0,
184,4,92,4,0,
184,4,104,4,0,
184,4,111,1,1,2
184,4,111,1,3,
184,4,111,4,0,
184,4,127,4,0,
184,4,129,4,0,
184,4,156,4,0,
184,4,173,4,0,
184,4,174,4,0,
184,4,182,4,0,
184,4,189,4,0,
184,4,196,4,0,
184,4,203,4,0,
184,4,205,1,15,
184,4,205,4,0,
184,4,207,4,0,
184,4,213,4,0,
184,4,214,4,0,
184,4,216,4,0,
184,4,218,4,0,
184,4,223,4,0,
184,4,231,4,0,
184,4,237,4,0,
184,4,240,1,48,
184,4,240,4,0,
184,4,249,4,0,
184,4,250,4,0,
184,5,33,1,1,1
184,5,38,1,34,
184,5,39,1,1,3
184,5,39,1,6,
184,5,55,1,1,4
184,5,55,1,10,
184,5,56,1,57,
184,5,57,4,0,
184,5,58,4,0,
184,5,59,4,0,
184,5,61,1,24,
184,5,63,4,0,
184,5,70,4,0,
184,5,91,4,0,
184,5,92,4,0,
184,5,104,4,0,
184,5,111,1,1,2
184,5,111,1,3,
184,5,127,4,0,
184,5,156,4,0,
184,5,182,4,0,
184,5,205,1,15,
184,5,213,4,0,
184,5,216,4,0,
184,5,218,4,0,
184,5,231,4,0,
184,5,237,4,0,
184,5,240,1,45,
184,5,240,4,0,
184,5,249,4,0,
184,5,258,4,0,
184,5,263,4,0,
184,5,264,4,0,
184,5,280,4,0,
184,5,290,4,0,
184,5,291,4,0,
184,5,352,4,0,
184,6,5,3,0,
184,6,8,3,0,
184,6,25,3,0,
184,6,33,1,1,1
184,6,34,3,0,
184,6,38,1,34,
184,6,38,3,0,
184,6,39,1,1,3
184,6,39,1,6,
184,6,55,1,1,4
184,6,55,1,10,
184,6,56,1,57,
184,6,57,4,0,
184,6,58,4,0,
184,6,59,4,0,
184,6,61,1,24,
184,6,63,4,0,
184,6,69,3,0,
184,6,70,4,0,
184,6,91,4,0,
184,6,92,4,0,
184,6,102,3,0,
184,6,104,4,0,
184,6,111,1,1,2
184,6,111,1,3,
184,6,111,3,0,
184,6,127,4,0,
184,6,129,3,0,
184,6,156,4,0,
184,6,164,3,0,
184,6,173,3,0,
184,6,182,4,0,
184,6,189,3,0,
184,6,196,3,0,
184,6,203,3,0,
184,6,205,1,15,
184,6,205,3,0,
184,6,207,3,0,
184,6,213,4,0,
184,6,214,3,0,
184,6,216,4,0,
184,6,218,4,0,
184,6,223,3,0,
184,6,231,4,0,
184,6,237,4,0,
184,6,240,1,45,
184,6,240,4,0,
184,6,249,4,0,
184,6,258,4,0,
184,6,263,4,0,
184,6,264,4,0,
184,6,280,4,0,
184,6,290,4,0,
184,6,291,4,0,
184,6,352,4,0,
184,7,5,3,0,
184,7,25,3,0,
184,7,33,1,1,1
184,7,34,3,0,
184,7,38,1,34,
184,7,38,3,0,
184,7,39,1,1,3
184,7,39,1,6,
184,7,55,1,1,4
184,7,55,1,10,
184,7,56,1,57,
184,7,57,4,0,
184,7,58,4,0,
184,7,59,4,0,
184,7,61,1,24,
184,7,63,4,0,
184,7,69,3,0,
184,7,70,4,0,
184,7,91,4,0,
184,7,92,4,0,
184,7,102,3,0,
184,7,104,4,0,
184,7,111,1,1,2
184,7,111,1,3,
184,7,127,4,0,
184,7,156,4,0,
184,7,164,3,0,
184,7,182,4,0,
184,7,205,1,15,
184,7,213,4,0,
184,7,216,4,0,
184,7,218,4,0,
184,7,231,4,0,
184,7,237,4,0,
184,7,240,1,45,
184,7,240,4,0,
184,7,249,4,0,
184,7,258,4,0,
184,7,263,4,0,
184,7,264,4,0,
184,7,280,4,0,
184,7,290,4,0,
184,7,291,4,0,
184,7,352,4,0,
184,8,33,1,1,1
184,8,38,1,33,
184,8,39,1,1,3
184,8,39,1,7,
184,8,55,1,1,4
184,8,55,1,10,
184,8,56,1,54,
184,8,57,4,0,
184,8,58,4,0,
184,8,59,4,0,
184,8,61,1,20,
184,8,63,4,0,
184,8,70,4,0,
184,8,91,4,0,
184,8,92,4,0,
184,8,104,4,0,
184,8,111,1,1,2
184,8,111,1,2,
184,8,127,4,0,
184,8,156,4,0,
184,8,164,4,0,
184,8,182,4,0,
184,8,203,4,0,
184,8,205,1,15,
184,8,207,4,0,
184,8,213,4,0,
184,8,214,4,0,
184,8,216,4,0,
184,8,218,4,0,
184,8,231,4,0,
184,8,237,4,0,
184,8,240,1,40,
184,8,240,4,0,
184,8,249,4,0,
184,8,258,4,0,
184,8,263,4,0,
184,8,264,4,0,
184,8,280,4,0,
184,8,290,4,0,
184,8,352,4,0,
184,8,363,4,0,
184,8,374,4,0,
184,8,392,1,27,
184,8,401,1,47,
184,8,411,4,0,
184,8,416,4,0,
184,8,445,4,0,
184,8,447,4,0,
184,9,8,3,0,
184,9,33,1,1,1
184,9,38,1,33,
184,9,39,1,1,3
184,9,39,1,7,
184,9,55,1,1,4
184,9,55,1,10,
184,9,56,1,54,
184,9,57,4,0,
184,9,58,4,0,
184,9,59,4,0,
184,9,61,1,20,
184,9,63,4,0,
184,9,70,4,0,
184,9,91,4,0,
184,9,92,4,0,
184,9,104,4,0,
184,9,111,1,1,2
184,9,111,1,2,
184,9,127,4,0,
184,9,129,3,0,
184,9,156,4,0,
184,9,164,4,0,
184,9,173,3,0,
184,9,182,4,0,
184,9,189,3,0,
184,9,196,3,0,
184,9,203,4,0,
184,9,205,1,15,
184,9,205,3,0,
184,9,207,4,0,
184,9,213,4,0,
184,9,214,4,0,
184,9,216,4,0,
184,9,218,4,0,
184,9,231,4,0,
184,9,237,4,0,
184,9,240,1,40,
184,9,240,4,0,
184,9,249,4,0,
184,9,258,4,0,
184,9,263,4,0,
184,9,264,4,0,
184,9,270,3,0,
184,9,276,3,0,
184,9,280,4,0,
184,9,282,3,0,
184,9,290,4,0,
184,9,291,3,0,
184,9,352,4,0,
184,9,363,4,0,
184,9,374,4,0,
184,9,392,1,27,
184,9,401,1,47,
184,9,401,3,0,
184,9,411,4,0,
184,9,416,4,0,
184,9,445,4,0,
184,9,447,4,0,
184,10,8,3,0,
184,10,29,3,0,
184,10,33,1,1,1
184,10,38,1,33,
184,10,39,1,1,3
184,10,39,1,7,
184,10,55,1,1,4
184,10,55,1,10,
184,10,56,1,54,
184,10,57,4,0,
184,10,58,4,0,
184,10,59,4,0,
184,10,61,1,20,
184,10,63,4,0,
184,10,70,4,0,
184,10,91,4,0,
184,10,92,4,0,
184,10,104,4,0,
184,10,111,1,1,2
184,10,111,1,2,
184,10,127,4,0,
184,10,129,3,0,
184,10,156,4,0,
184,10,164,4,0,
184,10,173,3,0,
184,10,182,4,0,
184,10,189,3,0,
184,10,196,3,0,
184,10,203,4,0,
184,10,205,1,15,
184,10,205,3,0,
184,10,207,4,0,
184,10,213,4,0,
184,10,214,4,0,
184,10,216,4,0,
184,10,218,4,0,
184,10,231,4,0,
184,10,237,4,0,
184,10,240,1,40,
184,10,240,4,0,
184,10,249,4,0,
184,10,250,4,0,
184,10,258,4,0,
184,10,263,4,0,
184,10,264,4,0,
184,10,270,3,0,
184,10,276,3,0,
184,10,280,4,0,
184,10,282,3,0,
184,10,290,4,0,
184,10,291,3,0,
184,10,352,4,0,
184,10,363,4,0,
184,10,374,4,0,
184,10,392,1,27,
184,10,401,1,47,
184,10,401,3,0,
184,10,411,4,0,
184,10,416,4,0,
184,10,445,4,0,
184,10,447,4,0,
184,11,33,1,1,1
184,11,38,1,33,
184,11,39,1,1,3
184,11,39,1,7,
184,11,55,1,1,4
184,11,55,1,10,
184,11,56,1,54,
184,11,57,4,0,
184,11,58,4,0,
184,11,59,4,0,
184,11,61,1,20,
184,11,63,4,0,
184,11,70,4,0,
184,11,91,4,0,
184,11,92,4,0,
184,11,104,4,0,
184,11,111,1,1,2
184,11,111,1,2,
184,11,113,4,0,
184,11,127,4,0,
184,11,156,4,0,
184,11,164,4,0,
184,11,182,4,0,
184,11,205,1,15,
184,11,207,4,0,
184,11,213,4,0,
184,11,216,4,0,
184,11,218,4,0,
184,11,237,4,0,
184,11,240,1,40,
184,11,240,4,0,
184,11,249,4,0,
184,11,258,4,0,
184,11,263,4,0,
184,11,280,4,0,
184,11,291,4,0,
184,11,374,4,0,
184,11,392,1,27,
184,11,401,1,47,
184,11,411,4,0,
184,11,416,4,0,
184,11,447,4,0,
184,11,496,4,0,
184,11,503,4,0,
184,11,523,4,0,
184,11,526,4,0,
184,12,33,1,1,1
184,12,38,1,34,
184,12,39,1,1,3
184,12,39,1,6,
184,12,55,1,1,4
184,12,55,1,10,
184,12,56,1,57,
184,12,57,4,0,
184,12,58,4,0,
184,12,59,4,0,
184,12,61,1,24,
184,12,63,4,0,
184,12,70,4,0,
184,12,91,4,0,
184,12,92,4,0,
184,12,104,4,0,
184,12,111,1,1,2
184,12,111,1,3,
184,12,127,4,0,
184,12,156,4,0,
184,12,182,4,0,
184,12,205,1,15,
184,12,213,4,0,
184,12,216,4,0,
184,12,218,4,0,
184,12,231,4,0,
184,12,237,4,0,
184,12,240,1,45,
184,12,240,4,0,
184,12,249,4,0,
184,12,258,4,0,
184,12,263,4,0,
184,12,264,4,0,
184,12,280,4,0,
184,12,290,4,0,
184,12,291,4,0,
184,12,352,4,0,
184,13,33,1,1,1
184,13,34,3,0,
184,13,38,1,34,
184,13,38,3,0,
184,13,39,1,1,3
184,13,39,1,6,
184,13,55,1,1,4
184,13,55,1,10,
184,13,56,1,57,
184,13,57,4,0,
184,13,58,4,0,
184,13,59,4,0,
184,13,61,1,24,
184,13,63,4,0,
184,13,69,3,0,
184,13,70,4,0,
184,13,91,4,0,
184,13,92,4,0,
184,13,102,3,0,
184,13,104,4,0,
184,13,111,1,1,2
184,13,111,1,3,
184,13,127,4,0,
184,13,156,4,0,
184,13,164,3,0,
184,13,182,4,0,
184,13,196,3,0,
184,13,205,1,15,
184,13,207,3,0,
184,13,213,4,0,
184,13,216,4,0,
184,13,218,4,0,
184,13,231,4,0,
184,13,237,4,0,
184,13,240,1,45,
184,13,240,4,0,
184,13,249,4,0,
184,13,258,4,0,
184,13,263,4,0,
184,13,264,4,0,
184,13,280,4,0,
184,13,290,4,0,
184,13,291,4,0,
184,13,352,4,0,
184,14,8,3,0,
184,14,33,1,1,1
184,14,38,1,25,
184,14,39,1,1,3
184,14,39,1,2,
184,14,55,1,7,
184,14,56,1,46,
184,14,57,4,0,
184,14,58,4,0,
184,14,59,4,0,
184,14,61,1,13,
184,14,63,4,0,
184,14,70,4,0,
184,14,91,4,0,
184,14,92,4,0,
184,14,104,4,0,
184,14,111,1,10,1
184,14,113,4,0,
184,14,127,4,0,
184,14,145,1,1,2
184,14,156,4,0,
184,14,164,4,0,
184,14,173,3,0,
184,14,182,4,0,
184,14,196,3,0,
184,14,205,1,10,2
184,14,207,4,0,
184,14,213,4,0,
184,14,214,3,0,
184,14,216,4,0,
184,14,218,4,0,
184,14,231,3,0,
184,14,237,4,0,
184,14,240,1,35,
184,14,240,4,0,
184,14,249,4,0,
184,14,258,4,0,
184,14,263,4,0,
184,14,270,1,16,
184,14,270,3,0,
184,14,276,1,42,
184,14,276,3,0,
184,14,280,4,0,
184,14,282,3,0,
184,14,291,4,0,
184,14,304,3,0,
184,14,340,3,0,
184,14,343,3,0,
184,14,346,1,1,4
184,14,346,1,5,
184,14,374,4,0,
184,14,392,1,31,
184,14,401,1,21,
184,14,401,3,0,
184,14,411,4,0,
184,14,416,4,0,
184,14,447,4,0,
184,14,496,4,0,
184,14,503,4,0,
184,14,523,4,0,
184,14,526,4,0,
184,15,33,1,1,1
184,15,38,1,25,
184,15,39,1,1,3
184,15,39,1,2,
184,15,55,1,1,2
184,15,56,1,46,
184,15,57,4,0,
184,15,58,4,0,
184,15,59,4,0,
184,15,61,1,13,
184,15,63,4,0,
184,15,70,4,0,
184,15,91,4,0,
184,15,92,4,0,
184,15,104,4,0,
184,15,111,1,10,1
184,15,113,4,0,
184,15,127,4,0,
184,15,145,1,7,
184,15,156,4,0,
184,15,164,4,0,
184,15,182,4,0,
184,15,205,1,10,2
184,15,207,4,0,
184,15,213,4,0,
184,15,214,4,0,
184,15,216,4,0,
184,15,218,4,0,
184,15,237,4,0,
184,15,240,1,35,
184,15,240,4,0,
184,15,249,4,0,
184,15,258,4,0,
184,15,263,4,0,
184,15,270,1,16,
184,15,276,1,42,
184,15,280,4,0,
184,15,346,1,1,4
184,15,346,1,5,
184,15,374,4,0,
184,15,392,1,31,
184,15,401,1,21,
184,15,411,4,0,
184,15,416,4,0,
184,15,447,4,0,
184,15,496,4,0,
184,15,503,4,0,
184,15,523,4,0,
184,15,583,1,50,
184,15,590,4,0,
184,15,612,4,0,
184,16,8,3,0,
184,16,33,1,1,1
184,16,38,1,42,1
184,16,39,1,1,3
184,16,39,1,2,1
184,16,55,1,1,2
184,16,56,1,55,1
184,16,57,4,0,
184,16,58,4,0,
184,16,59,4,0,
184,16,61,1,13,1
184,16,63,4,0,
184,16,70,4,0,
184,16,91,4,0,
184,16,92,4,0,
184,16,104,4,0,
184,16,111,1,10,1
184,16,113,4,0,
184,16,127,4,0,
184,16,145,1,7,1
184,16,156,4,0,
184,16,164,4,0,
184,16,173,3,0,
184,16,182,4,0,
184,16,196,3,0,
184,16,205,1,10,2
184,16,207,4,0,
184,16,213,4,0,
184,16,214,4,0,
184,16,216,4,0,
184,16,218,4,0,
184,16,231,3,0,
184,16,237,4,0,
184,16,240,1,35,1
184,16,240,4,0,
184,16,249,4,0,
184,16,258,4,0,
184,16,263,4,0,
184,16,264,3,0,
184,16,270,1,16,1
184,16,270,3,0,
184,16,276,1,46,1
184,16,276,3,0,
184,16,280,4,0,
184,16,282,3,0,
184,16,290,4,0,
184,16,291,4,0,
184,16,304,3,0,
184,16,340,3,0,
184,16,343,3,0,
184,16,346,1,1,4
184,16,346,1,5,1
184,16,352,3,0,
184,16,374,4,0,
184,16,392,1,31,1
184,16,401,1,21,1
184,16,401,3,0,
184,16,411,4,0,
184,16,416,4,0,
184,16,447,4,0,
184,16,496,4,0,
184,16,503,4,0,
184,16,523,4,0,
184,16,583,1,25,1
184,16,590,4,0,
184,16,612,4,0,
184,17,33,1,1,1
184,17,38,1,42,
184,17,39,1,1,3
184,17,39,1,2,
184,17,55,1,1,2
184,17,56,1,55,
184,17,57,4,0,
184,17,58,4,0,
184,17,59,4,0,
184,17,61,1,13,
184,17,63,4,0,
184,17,92,4,0,
184,17,104,4,0,
184,17,111,1,10,1
184,17,113,4,0,
184,17,127,4,0,
184,17,145,1,7,
184,17,156,4,0,
184,17,164,4,0,
184,17,182,4,0,
184,17,205,1,10,2
184,17,207,4,0,
184,17,213,4,0,
184,17,214,4,0,
184,17,216,4,0,
184,17,218,4,0,
184,17,237,4,0,
184,17,240,1,35,
184,17,240,4,0,
184,17,258,4,0,
184,17,263,4,0,
184,17,270,1,16,
184,17,276,1,46,
184,17,280,4,0,
184,17,346,1,1,4
184,17,346,1,5,
184,17,374,4,0,
184,17,392,1,31,
184,17,401,1,21,
184,17,411,4,0,
184,17,416,4,0,
184,17,447,4,0,
184,17,496,4,0,
184,17,503,4,0,
184,17,523,4,0,
184,17,526,4,0,
184,17,583,1,25,
184,17,590,4,0,
184,18,33,1,1,1
184,18,38,1,42,
184,18,39,1,1,3
184,18,39,1,2,
184,18,55,1,1,2
184,18,56,1,55,
184,18,57,4,0,
184,18,58,4,0,
184,18,59,4,0,
184,18,61,1,13,
184,18,63,4,0,
184,18,92,4,0,
184,18,104,4,0,
184,18,111,1,10,1
184,18,113,4,0,
184,18,127,4,0,
184,18,145,1,7,
184,18,156,4,0,
184,18,164,4,0,
184,18,182,4,0,
184,18,205,1,10,2
184,18,207,4,0,
184,18,213,4,0,
184,18,214,4,0,
184,18,216,4,0,
184,18,218,4,0,
184,18,237,4,0,
184,18,240,1,35,
184,18,240,4,0,
184,18,258,4,0,
184,18,263,4,0,
184,18,270,1,16,
184,18,276,1,46,
184,18,280,4,0,
184,18,346,1,1,4
184,18,346,1,5,
184,18,374,4,0,
184,18,392,1,31,
184,18,401,1,21,
184,18,411,4,0,
184,18,416,4,0,
184,18,447,4,0,
184,18,496,4,0,
184,18,503,4,0,
184,18,523,4,0,
184,18,526,4,0,
184,18,583,1,25,
184,18,590,4,0,
185,3,7,4,0,
185,3,8,4,0,
185,3,9,4,0,
185,3,21,1,46,
185,3,29,4,0,
185,3,67,1,19,
185,3,70,4,0,
185,3,88,1,1,1
185,3,89,4,0,
185,3,91,4,0,
185,3,92,4,0,
185,3,102,1,1,2
185,3,104,4,0,
185,3,111,4,0,
185,3,120,2,0,
185,3,156,4,0,
185,3,157,1,28,
185,3,168,4,0,
185,3,173,4,0,
185,3,174,4,0,
185,3,175,1,10,
185,3,182,4,0,
185,3,185,1,37,
185,3,189,4,0,
185,3,201,4,0,
185,3,203,4,0,
185,3,205,4,0,
185,3,207,4,0,
185,3,213,4,0,
185,3,214,4,0,
185,3,216,4,0,
185,3,218,4,0,
185,3,223,4,0,
185,3,237,4,0,
185,3,241,4,0,
185,3,244,4,0,
185,3,249,4,0,
185,4,7,4,0,
185,4,8,4,0,
185,4,9,4,0,
185,4,21,1,46,
185,4,29,4,0,
185,4,67,1,19,
185,4,70,4,0,
185,4,88,1,1,1
185,4,89,4,0,
185,4,91,4,0,
185,4,92,4,0,
185,4,102,1,1,2
185,4,104,4,0,
185,4,111,4,0,
185,4,120,2,0,
185,4,156,4,0,
185,4,157,1,28,
185,4,168,4,0,
185,4,173,4,0,
185,4,174,4,0,
185,4,175,1,10,
185,4,182,4,0,
185,4,185,1,37,
185,4,189,4,0,
185,4,201,4,0,
185,4,203,4,0,
185,4,205,4,0,
185,4,207,4,0,
185,4,213,4,0,
185,4,214,4,0,
185,4,216,4,0,
185,4,218,4,0,
185,4,223,4,0,
185,4,237,4,0,
185,4,241,4,0,
185,4,244,4,0,
185,4,249,4,0,
185,5,21,1,49,
185,5,38,1,57,
185,5,67,1,17,
185,5,70,4,0,
185,5,88,1,1,1
185,5,89,4,0,
185,5,91,4,0,
185,5,92,4,0,
185,5,102,1,1,2
185,5,104,4,0,
185,5,120,2,0,
185,5,156,4,0,
185,5,157,1,25,
185,5,168,4,0,
185,5,175,1,9,
185,5,182,4,0,
185,5,185,1,41,
185,5,201,4,0,
185,5,213,4,0,
185,5,216,4,0,
185,5,218,4,0,
185,5,237,4,0,
185,5,241,4,0,
185,5,249,4,0,
185,5,263,4,0,
185,5,264,4,0,
185,5,269,4,0,
185,5,280,4,0,
185,5,290,4,0,
185,5,317,4,0,
185,5,335,1,33,
185,5,347,4,0,
185,6,5,3,0,
185,6,7,3,0,
185,6,8,3,0,
185,6,9,3,0,
185,6,21,1,49,
185,6,25,3,0,
185,6,34,3,0,
185,6,38,1,57,
185,6,38,3,0,
185,6,67,1,17,
185,6,68,3,0,
185,6,69,3,0,
185,6,70,4,0,
185,6,88,1,1,1
185,6,89,4,0,
185,6,91,4,0,
185,6,92,4,0,
185,6,102,1,1,2
185,6,102,3,0,
185,6,104,4,0,
185,6,111,3,0,
185,6,120,2,0,
185,6,153,3,0,
185,6,156,4,0,
185,6,157,1,25,
185,6,157,3,0,
185,6,164,3,0,
185,6,168,4,0,
185,6,173,3,0,
185,6,175,1,9,
185,6,182,4,0,
185,6,185,1,41,
185,6,189,3,0,
185,6,201,4,0,
185,6,203,3,0,
185,6,205,3,0,
185,6,207,3,0,
185,6,213,4,0,
185,6,214,3,0,
185,6,216,4,0,
185,6,218,4,0,
185,6,223,3,0,
185,6,237,4,0,
185,6,241,4,0,
185,6,244,3,0,
185,6,249,4,0,
185,6,263,4,0,
185,6,264,4,0,
185,6,269,4,0,
185,6,280,4,0,
185,6,290,4,0,
185,6,317,4,0,
185,6,335,1,33,
185,6,347,4,0,
185,7,5,3,0,
185,7,21,1,49,
185,7,25,3,0,
185,7,34,3,0,
185,7,38,1,57,
185,7,38,3,0,
185,7,67,1,17,
185,7,68,3,0,
185,7,69,3,0,
185,7,70,4,0,
185,7,88,1,1,1
185,7,89,4,0,
185,7,91,4,0,
185,7,92,4,0,
185,7,102,1,1,2
185,7,102,3,0,
185,7,104,4,0,
185,7,120,2,0,
185,7,153,3,0,
185,7,156,4,0,
185,7,157,1,25,
185,7,157,3,0,
185,7,164,3,0,
185,7,168,4,0,
185,7,175,1,9,
185,7,182,4,0,
185,7,185,1,41,
185,7,201,4,0,
185,7,213,4,0,
185,7,216,4,0,
185,7,218,4,0,
185,7,237,4,0,
185,7,241,4,0,
185,7,249,4,0,
185,7,263,4,0,
185,7,264,4,0,
185,7,269,4,0,
185,7,280,4,0,
185,7,290,4,0,
185,7,317,4,0,
185,7,335,1,33,
185,7,347,4,0,
185,8,21,1,38,
185,8,29,2,0,
185,8,38,1,46,
185,8,67,1,1,4
185,8,67,1,9,
185,8,70,4,0,
185,8,88,1,1,5
185,8,88,1,14,
185,8,89,4,0,
185,8,91,4,0,
185,8,92,4,0,
185,8,102,1,17,
185,8,104,4,0,
185,8,106,2,0,
185,8,111,2,0,
185,8,120,2,0,
185,8,153,4,0,
185,8,156,4,0,
185,8,157,1,33,
185,8,157,4,0,
185,8,164,4,0,
185,8,168,4,0,
185,8,175,1,1,3
185,8,175,1,6,
185,8,182,4,0,
185,8,185,1,25,
185,8,201,4,0,
185,8,203,4,0,
185,8,205,2,0,
185,8,207,4,0,
185,8,213,4,0,
185,8,214,4,0,
185,8,216,4,0,
185,8,218,4,0,
185,8,237,4,0,
185,8,241,4,0,
185,8,244,4,0,
185,8,249,4,0,
185,8,259,4,0,
185,8,263,4,0,
185,8,264,4,0,
185,8,269,4,0,
185,8,280,4,0,
185,8,290,4,0,
185,8,317,1,30,
185,8,317,4,0,
185,8,328,2,0,
185,8,335,1,22,
185,8,347,4,0,
185,8,359,1,49,
185,8,363,4,0,
185,8,374,4,0,
185,8,383,1,1,2
185,8,389,1,41,
185,8,397,4,0,
185,8,444,4,0,
185,8,445,4,0,
185,8,446,4,0,
185,8,452,1,1,1
185,9,7,3,0,
185,9,8,3,0,
185,9,9,3,0,
185,9,21,1,38,
185,9,29,2,0,
185,9,38,1,46,
185,9,67,1,1,4
185,9,67,1,9,
185,9,70,4,0,
185,9,88,1,1,5
185,9,88,1,14,
185,9,89,4,0,
185,9,91,4,0,
185,9,92,4,0,
185,9,102,1,17,
185,9,104,4,0,
185,9,106,2,0,
185,9,111,2,0,
185,9,120,2,0,
185,9,153,4,0,
185,9,156,4,0,
185,9,157,1,33,
185,9,157,4,0,
185,9,164,4,0,
185,9,168,4,0,
185,9,173,3,0,
185,9,175,1,1,3
185,9,175,1,6,
185,9,182,4,0,
185,9,185,1,25,
185,9,189,3,0,
185,9,201,4,0,
185,9,203,4,0,
185,9,205,2,0,
185,9,205,3,0,
185,9,207,4,0,
185,9,213,4,0,
185,9,214,4,0,
185,9,216,4,0,
185,9,218,4,0,
185,9,237,4,0,
185,9,241,4,0,
185,9,244,4,0,
185,9,249,4,0,
185,9,259,4,0,
185,9,263,4,0,
185,9,264,4,0,
185,9,269,4,0,
185,9,270,3,0,
185,9,280,4,0,
185,9,290,4,0,
185,9,317,1,30,
185,9,317,4,0,
185,9,328,2,0,
185,9,335,1,22,
185,9,347,4,0,
185,9,359,1,49,
185,9,363,4,0,
185,9,374,4,0,
185,9,383,1,1,2
185,9,389,1,41,
185,9,389,3,0,
185,9,397,4,0,
185,9,414,3,0,
185,9,444,4,0,
185,9,445,4,0,
185,9,446,4,0,
185,9,452,1,1,1
185,10,7,3,0,
185,10,8,3,0,
185,10,9,3,0,
185,10,21,1,38,
185,10,29,2,0,
185,10,29,3,0,
185,10,38,1,46,
185,10,67,1,1,4
185,10,67,1,9,
185,10,67,3,0,
185,10,70,4,0,
185,10,88,1,1,5
185,10,88,1,14,
185,10,89,4,0,
185,10,91,4,0,
185,10,92,4,0,
185,10,102,1,17,
185,10,104,4,0,
185,10,106,2,0,
185,10,111,2,0,
185,10,120,2,0,
185,10,153,4,0,
185,10,156,4,0,
185,10,157,1,33,
185,10,157,4,0,
185,10,164,4,0,
185,10,168,4,0,
185,10,173,3,0,
185,10,175,1,1,3
185,10,175,1,6,
185,10,182,4,0,
185,10,185,1,25,
185,10,189,3,0,
185,10,201,4,0,
185,10,203,4,0,
185,10,205,2,0,
185,10,205,3,0,
185,10,207,4,0,
185,10,213,4,0,
185,10,214,4,0,
185,10,216,4,0,
185,10,218,4,0,
185,10,237,4,0,
185,10,241,4,0,
185,10,244,4,0,
185,10,249,4,0,
185,10,259,4,0,
185,10,263,4,0,
185,10,264,4,0,
185,10,269,4,0,
185,10,270,3,0,
185,10,272,3,0,
185,10,280,4,0,
185,10,290,4,0,
185,10,317,1,30,
185,10,317,4,0,
185,10,328,2,0,
185,10,335,1,22,
185,10,335,3,0,
185,10,347,4,0,
185,10,359,1,49,
185,10,363,4,0,
185,10,374,4,0,
185,10,383,1,1,2
185,10,389,1,41,
185,10,389,3,0,
185,10,397,4,0,
185,10,414,3,0,
185,10,444,4,0,
185,10,445,4,0,
185,10,446,4,0,
185,10,452,1,1,1
185,11,21,1,38,
185,11,29,2,0,
185,11,38,1,46,
185,11,67,1,1,4
185,11,67,1,9,
185,11,70,4,0,
185,11,88,1,1,5
185,11,88,1,14,
185,11,89,4,0,
185,11,91,4,0,
185,11,92,4,0,
185,11,102,1,17,
185,11,104,4,0,
185,11,106,2,0,
185,11,111,2,0,
185,11,120,2,0,
185,11,153,4,0,
185,11,156,4,0,
185,11,157,1,33,
185,11,157,4,0,
185,11,164,4,0,
185,11,168,4,0,
185,11,174,2,0,
185,11,175,1,1,3
185,11,175,1,6,
185,11,182,4,0,
185,11,185,1,25,
185,11,201,4,0,
185,11,203,2,0,
185,11,205,2,0,
185,11,207,4,0,
185,11,213,4,0,
185,11,216,4,0,
185,11,218,4,0,
185,11,237,4,0,
185,11,241,4,0,
185,11,244,4,0,
185,11,249,4,0,
185,11,259,4,0,
185,11,263,4,0,
185,11,269,4,0,
185,11,280,4,0,
185,11,317,1,30,
185,11,317,4,0,
185,11,328,2,0,
185,11,335,1,22,
185,11,347,4,0,
185,11,359,1,49,
185,11,374,4,0,
185,11,383,1,1,2
185,11,389,1,41,
185,11,397,4,0,
185,11,444,4,0,
185,11,446,2,0,
185,11,452,1,1,1
185,11,479,4,0,
185,11,496,4,0,
185,11,523,4,0,
185,12,21,1,49,
185,12,38,1,57,
185,12,67,1,17,
185,12,70,4,0,
185,12,88,1,1,1
185,12,89,4,0,
185,12,91,4,0,
185,12,92,4,0,
185,12,102,1,1,2
185,12,104,4,0,
185,12,156,4,0,
185,12,157,1,25,
185,12,168,4,0,
185,12,175,1,9,
185,12,182,4,0,
185,12,185,1,41,
185,12,201,4,0,
185,12,213,4,0,
185,12,216,4,0,
185,12,218,4,0,
185,12,237,4,0,
185,12,241,4,0,
185,12,249,4,0,
185,12,263,4,0,
185,12,264,4,0,
185,12,269,4,0,
185,12,280,4,0,
185,12,290,4,0,
185,12,317,4,0,
185,12,335,1,33,
185,12,347,4,0,
185,13,21,1,49,
185,13,34,3,0,
185,13,38,1,57,
185,13,38,3,0,
185,13,67,1,17,
185,13,69,3,0,
185,13,70,4,0,
185,13,88,1,1,1
185,13,89,4,0,
185,13,91,4,0,
185,13,92,4,0,
185,13,102,1,1,2
185,13,102,3,0,
185,13,104,4,0,
185,13,120,3,0,
185,13,156,4,0,
185,13,157,1,25,
185,13,164,3,0,
185,13,168,4,0,
185,13,175,1,9,
185,13,182,4,0,
185,13,185,1,41,
185,13,201,4,0,
185,13,207,3,0,
185,13,213,4,0,
185,13,216,4,0,
185,13,218,4,0,
185,13,237,4,0,
185,13,241,4,0,
185,13,249,4,0,
185,13,263,4,0,
185,13,264,4,0,
185,13,269,4,0,
185,13,280,4,0,
185,13,290,4,0,
185,13,317,4,0,
185,13,335,1,33,
185,13,347,4,0,
185,14,7,3,0,
185,14,8,3,0,
185,14,9,3,0,
185,14,21,1,15,2
185,14,29,2,0,
185,14,38,1,40,
185,14,67,1,1,4
185,14,67,1,8,
185,14,67,3,0,
185,14,68,1,33,
185,14,70,4,0,
185,14,88,1,1,5
185,14,88,1,12,
185,14,89,4,0,
185,14,91,4,0,
185,14,92,4,0,
185,14,102,1,15,1
185,14,104,4,0,
185,14,106,2,0,
185,14,111,2,0,
185,14,120,2,0,
185,14,153,4,0,
185,14,156,4,0,
185,14,157,1,29,
185,14,157,4,0,
185,14,164,4,0,
185,14,168,4,0,
185,14,173,3,0,
185,14,174,2,0,
185,14,175,1,1,3
185,14,175,1,5,
185,14,182,4,0,
185,14,185,1,19,
185,14,201,4,0,
185,14,203,2,0,
185,14,205,2,0,
185,14,207,4,0,
185,14,213,4,0,
185,14,214,3,0,
185,14,216,4,0,
185,14,218,4,0,
185,14,237,4,0,
185,14,241,4,0,
185,14,244,4,0,
185,14,249,4,0,
185,14,259,4,0,
185,14,263,4,0,
185,14,269,4,0,
185,14,270,3,0,
185,14,272,3,0,
185,14,280,4,0,
185,14,317,1,22,
185,14,317,4,0,
185,14,328,2,0,
185,14,335,1,26,
185,14,335,3,0,
185,14,343,3,0,
185,14,347,4,0,
185,14,359,1,47,
185,14,374,4,0,
185,14,383,1,1,2
185,14,389,1,36,
185,14,397,4,0,
185,14,414,3,0,
185,14,444,1,43,
185,14,444,4,0,
185,14,446,2,0,
185,14,446,3,0,
185,14,452,1,1,1
185,14,479,4,0,
185,14,492,3,0,
185,14,495,3,0,
185,14,496,4,0,
185,14,523,4,0,
185,15,21,1,15,2
185,15,29,2,0,
185,15,38,1,40,
185,15,67,1,1,4
185,15,67,1,8,
185,15,68,1,33,
185,15,70,4,0,
185,15,88,1,1,5
185,15,88,1,12,
185,15,89,4,0,
185,15,91,4,0,
185,15,92,4,0,
185,15,102,1,15,1
185,15,104,4,0,
185,15,106,2,0,
185,15,111,2,0,
185,15,120,2,0,
185,15,153,4,0,
185,15,156,4,0,
185,15,157,1,29,
185,15,157,4,0,
185,15,164,4,0,
185,15,168,4,0,
185,15,174,2,0,
185,15,175,1,1,3
185,15,175,1,5,
185,15,182,4,0,
185,15,185,1,19,
185,15,201,4,0,
185,15,203,2,0,
185,15,205,2,0,
185,15,207,4,0,
185,15,213,4,0,
185,15,214,4,0,
185,15,216,4,0,
185,15,218,4,0,
185,15,237,4,0,
185,15,241,4,0,
185,15,244,4,0,
185,15,249,4,0,
185,15,259,4,0,
185,15,263,4,0,
185,15,267,4,0,
185,15,269,4,0,
185,15,280,4,0,
185,15,317,1,22,
185,15,317,4,0,
185,15,328,2,0,
185,15,335,1,26,
185,15,347,4,0,
185,15,359,1,47,
185,15,374,4,0,
185,15,383,1,1,2
185,15,389,1,36,
185,15,397,4,0,
185,15,444,1,43,
185,15,444,4,0,
185,15,446,2,0,
185,15,452,1,1,1
185,15,479,4,0,
185,15,496,4,0,
185,15,523,4,0,
185,15,590,4,0,
185,15,612,4,0,
185,16,7,3,0,
185,16,8,3,0,
185,16,9,3,0,
185,16,21,1,15,2
185,16,29,2,0,
185,16,38,1,40,1
185,16,67,1,1,4
185,16,67,1,8,1
185,16,67,3,0,
185,16,68,1,33,1
185,16,70,4,0,
185,16,88,1,1,5
185,16,88,1,12,1
185,16,89,4,0,
185,16,91,4,0,
185,16,92,4,0,
185,16,102,1,15,1
185,16,104,4,0,
185,16,106,2,0,
185,16,111,2,0,
185,16,120,2,0,
185,16,153,4,0,
185,16,156,4,0,
185,16,157,1,29,1
185,16,157,4,0,
185,16,164,4,0,
185,16,168,4,0,
185,16,173,3,0,
185,16,174,2,0,
185,16,175,1,1,3
185,16,175,1,5,1
185,16,182,4,0,
185,16,185,1,19,1
185,16,201,4,0,
185,16,203,2,0,
185,16,205,2,0,
185,16,207,4,0,
185,16,213,4,0,
185,16,214,4,0,
185,16,216,4,0,
185,16,218,4,0,
185,16,237,4,0,
185,16,241,4,0,
185,16,244,4,0,
185,16,249,4,0,
185,16,259,4,0,
185,16,263,4,0,
185,16,264,3,0,
185,16,267,4,0,
185,16,269,4,0,
185,16,270,3,0,
185,16,272,3,0,
185,16,280,4,0,
185,16,290,4,0,
185,16,317,1,22,1
185,16,317,4,0,
185,16,328,2,0,
185,16,335,1,26,1
185,16,335,3,0,
185,16,343,3,0,
185,16,347,4,0,
185,16,359,1,47,1
185,16,374,4,0,
185,16,383,1,1,2
185,16,389,1,36,1
185,16,397,4,0,
185,16,414,3,0,
185,16,444,1,43,1
185,16,444,4,0,
185,16,446,2,0,
185,16,446,3,0,
185,16,452,1,1,1
185,16,479,4,0,
185,16,492,3,0,
185,16,495,3,0,
185,16,496,4,0,
185,16,523,4,0,
185,16,590,4,0,
185,16,612,4,0,
185,17,21,1,0,
185,17,21,1,1,1
185,17,29,2,0,
185,17,38,1,43,
185,17,67,1,1,5
185,17,67,1,8,
185,17,68,1,36,
185,17,88,1,1,6
185,17,88,1,12,
185,17,89,4,0,
185,17,92,4,0,
185,17,102,1,15,
185,17,104,4,0,
185,17,106,2,0,
185,17,111,2,0,
185,17,120,2,0,
185,17,153,4,0,
185,17,156,4,0,
185,17,157,1,33,
185,17,157,4,0,
185,17,164,4,0,
185,17,168,4,0,
185,17,174,2,0,
185,17,175,1,1,4
185,17,175,1,5,
185,17,182,4,0,
185,17,185,1,19,
185,17,201,4,0,
185,17,203,2,0,
185,17,205,2,0,
185,17,207,4,0,
185,17,213,4,0,
185,17,214,4,0,
185,17,216,4,0,
185,17,218,4,0,
185,17,237,4,0,
185,17,241,4,0,
185,17,244,4,0,
185,17,259,4,0,
185,17,263,4,0,
185,17,267,4,0,
185,17,269,4,0,
185,17,280,4,0,
185,17,317,1,26,
185,17,317,4,0,
185,17,328,2,0,
185,17,335,1,29,
185,17,347,4,0,
185,17,359,1,50,
185,17,374,4,0,
185,17,383,1,1,3
185,17,389,1,40,
185,17,397,4,0,
185,17,444,1,47,
185,17,444,4,0,
185,17,446,2,0,
185,17,452,1,1,2
185,17,457,1,54,
185,17,479,4,0,
185,17,496,4,0,
185,17,523,4,0,
185,17,590,4,0,
185,17,715,1,22,
185,18,21,1,0,
185,18,21,1,1,1
185,18,29,2,0,
185,18,38,1,43,
185,18,67,1,1,5
185,18,67,1,8,
185,18,68,1,36,
185,18,88,1,1,6
185,18,88,1,12,
185,18,89,4,0,
185,18,92,4,0,
185,18,102,1,15,
185,18,104,4,0,
185,18,106,2,0,
185,18,111,2,0,
185,18,120,2,0,
185,18,153,4,0,
185,18,156,4,0,
185,18,157,1,33,
185,18,157,4,0,
185,18,164,4,0,
185,18,168,4,0,
185,18,174,2,0,
185,18,175,1,1,4
185,18,175,1,5,
185,18,182,4,0,
185,18,185,1,19,
185,18,201,4,0,
185,18,203,2,0,
185,18,205,2,0,
185,18,207,4,0,
185,18,213,4,0,
185,18,214,4,0,
185,18,216,4,0,
185,18,218,4,0,
185,18,237,4,0,
185,18,241,4,0,
185,18,244,4,0,
185,18,259,4,0,
185,18,263,4,0,
185,18,267,4,0,
185,18,269,4,0,
185,18,280,4,0,
185,18,317,1,26,
185,18,317,4,0,
185,18,328,2,0,
185,18,335,1,29,
185,18,347,4,0,
185,18,359,1,50,
185,18,374,4,0,
185,18,383,1,1,3
185,18,389,1,40,
185,18,397,4,0,
185,18,444,1,47,
185,18,444,4,0,
185,18,446,2,0,
185,18,452,1,1,2
185,18,457,1,54,
185,18,479,4,0,
185,18,496,4,0,
185,18,523,4,0,
185,18,590,4,0,
185,18,715,1,22,
186,3,3,1,1,3
186,3,8,4,0,
186,3,29,4,0,
186,3,55,1,1,1
186,3,57,4,0,
186,3,59,4,0,
186,3,63,4,0,
186,3,70,4,0,
186,3,89,4,0,
186,3,92,4,0,
186,3,94,4,0,
186,3,95,1,1,2
186,3,104,4,0,
186,3,111,4,0,
186,3,127,4,0,
186,3,156,4,0,
186,3,168,4,0,
186,3,173,4,0,
186,3,174,4,0,
186,3,182,4,0,
186,3,189,4,0,
186,3,195,1,1,4
186,3,195,1,35,
186,3,196,4,0,
186,3,197,4,0,
186,3,203,4,0,
186,3,207,1,51,
186,3,207,4,0,
186,3,213,4,0,
186,3,214,4,0,
186,3,216,4,0,
186,3,218,4,0,
186,3,223,4,0,
186,3,237,4,0,
186,3,240,4,0,
186,3,249,4,0,
186,3,250,4,0,
186,4,3,1,1,3
186,4,8,4,0,
186,4,29,4,0,
186,4,55,1,1,1
186,4,57,4,0,
186,4,58,3,0,
186,4,59,4,0,
186,4,63,4,0,
186,4,70,4,0,
186,4,89,4,0,
186,4,92,4,0,
186,4,94,4,0,
186,4,95,1,1,2
186,4,104,4,0,
186,4,111,4,0,
186,4,127,4,0,
186,4,156,4,0,
186,4,168,4,0,
186,4,173,4,0,
186,4,174,4,0,
186,4,182,4,0,
186,4,189,4,0,
186,4,195,1,1,4
186,4,195,1,35,
186,4,196,4,0,
186,4,197,4,0,
186,4,203,4,0,
186,4,207,1,51,
186,4,207,4,0,
186,4,213,4,0,
186,4,214,4,0,
186,4,216,4,0,
186,4,218,4,0,
186,4,223,4,0,
186,4,237,4,0,
186,4,240,4,0,
186,4,249,4,0,
186,4,250,4,0,
186,5,3,1,1,3
186,5,55,1,1,1
186,5,57,4,0,
186,5,58,4,0,
186,5,59,4,0,
186,5,63,4,0,
186,5,70,4,0,
186,5,89,4,0,
186,5,91,4,0,
186,5,92,4,0,
186,5,94,4,0,
186,5,95,1,1,2
186,5,104,4,0,
186,5,127,4,0,
186,5,156,4,0,
186,5,168,4,0,
186,5,182,4,0,
186,5,195,1,1,4
186,5,195,1,35,
186,5,207,1,51,
186,5,213,4,0,
186,5,216,4,0,
186,5,218,4,0,
186,5,237,4,0,
186,5,240,4,0,
186,5,249,4,0,
186,5,258,4,0,
186,5,263,4,0,
186,5,264,4,0,
186,5,280,4,0,
186,5,290,4,0,
186,5,291,4,0,
186,5,352,4,0,
186,6,3,1,1,3
186,6,5,3,0,
186,6,25,3,0,
186,6,34,3,0,
186,6,38,3,0,
186,6,55,1,1,1
186,6,57,4,0,
186,6,58,4,0,
186,6,59,4,0,
186,6,63,4,0,
186,6,68,3,0,
186,6,69,3,0,
186,6,70,4,0,
186,6,89,4,0,
186,6,91,4,0,
186,6,92,4,0,
186,6,94,4,0,
186,6,95,1,1,2
186,6,102,3,0,
186,6,104,4,0,
186,6,111,3,0,
186,6,118,3,0,
186,6,127,4,0,
186,6,156,4,0,
186,6,164,3,0,
186,6,168,4,0,
186,6,173,3,0,
186,6,182,4,0,
186,6,189,3,0,
186,6,195,1,1,4
186,6,195,1,35,
186,6,196,3,0,
186,6,203,3,0,
186,6,207,1,51,
186,6,207,3,0,
186,6,213,4,0,
186,6,214,3,0,
186,6,216,4,0,
186,6,218,4,0,
186,6,223,3,0,
186,6,237,4,0,
186,6,240,4,0,
186,6,249,4,0,
186,6,258,4,0,
186,6,263,4,0,
186,6,264,4,0,
186,6,280,4,0,
186,6,290,4,0,
186,6,291,4,0,
186,6,352,4,0,
186,7,3,1,1,3
186,7,5,3,0,
186,7,25,3,0,
186,7,34,3,0,
186,7,38,3,0,
186,7,55,1,1,1
186,7,57,4,0,
186,7,58,4,0,
186,7,59,4,0,
186,7,63,4,0,
186,7,68,3,0,
186,7,69,3,0,
186,7,70,4,0,
186,7,89,4,0,
186,7,91,4,0,
186,7,92,4,0,
186,7,94,4,0,
186,7,95,1,1,2
186,7,102,3,0,
186,7,104,4,0,
186,7,118,3,0,
186,7,127,4,0,
186,7,156,4,0,
186,7,164,3,0,
186,7,168,4,0,
186,7,182,4,0,
186,7,195,1,1,4
186,7,195,1,35,
186,7,207,1,51,
186,7,213,4,0,
186,7,216,4,0,
186,7,218,4,0,
186,7,237,4,0,
186,7,240,4,0,
186,7,249,4,0,
186,7,258,4,0,
186,7,263,4,0,
186,7,264,4,0,
186,7,280,4,0,
186,7,290,4,0,
186,7,291,4,0,
186,7,352,4,0,
186,8,3,1,1,3
186,8,57,4,0,
186,8,58,4,0,
186,8,59,4,0,
186,8,61,1,1,1
186,8,63,4,0,
186,8,70,4,0,
186,8,89,4,0,
186,8,91,4,0,
186,8,92,4,0,
186,8,94,4,0,
186,8,95,1,1,2
186,8,104,4,0,
186,8,127,4,0,
186,8,156,4,0,
186,8,164,4,0,
186,8,168,4,0,
186,8,182,4,0,
186,8,195,1,1,4
186,8,203,4,0,
186,8,207,1,53,
186,8,207,4,0,
186,8,213,4,0,
186,8,214,4,0,
186,8,216,4,0,
186,8,218,4,0,
186,8,237,4,0,
186,8,240,4,0,
186,8,249,4,0,
186,8,258,4,0,
186,8,263,4,0,
186,8,264,4,0,
186,8,280,4,0,
186,8,290,4,0,
186,8,340,1,43,
186,8,352,4,0,
186,8,363,4,0,
186,8,371,4,0,
186,8,374,4,0,
186,8,411,4,0,
186,8,416,4,0,
186,8,445,4,0,
186,9,3,1,1,3
186,9,57,4,0,
186,9,58,4,0,
186,9,59,4,0,
186,9,61,1,1,1
186,9,63,4,0,
186,9,70,4,0,
186,9,89,4,0,
186,9,91,4,0,
186,9,92,4,0,
186,9,94,4,0,
186,9,95,1,1,2
186,9,104,4,0,
186,9,127,4,0,
186,9,156,4,0,
186,9,164,4,0,
186,9,168,4,0,
186,9,173,3,0,
186,9,182,4,0,
186,9,189,3,0,
186,9,195,1,1,4
186,9,196,3,0,
186,9,203,4,0,
186,9,207,1,27,
186,9,207,4,0,
186,9,213,4,0,
186,9,214,4,0,
186,9,216,4,0,
186,9,218,4,0,
186,9,237,4,0,
186,9,240,4,0,
186,9,249,4,0,
186,9,258,4,0,
186,9,263,4,0,
186,9,264,4,0,
186,9,270,3,0,
186,9,280,4,0,
186,9,290,4,0,
186,9,291,3,0,
186,9,304,1,48,
186,9,340,1,37,
186,9,340,3,0,
186,9,352,4,0,
186,9,363,4,0,
186,9,371,4,0,
186,9,374,4,0,
186,9,411,4,0,
186,9,416,4,0,
186,9,445,4,0,
186,10,3,1,1,3
186,10,29,3,0,
186,10,57,4,0,
186,10,58,4,0,
186,10,59,4,0,
186,10,61,1,1,1
186,10,63,4,0,
186,10,70,4,0,
186,10,89,4,0,
186,10,91,4,0,
186,10,92,4,0,
186,10,94,4,0,
186,10,95,1,1,2
186,10,104,4,0,
186,10,127,4,0,
186,10,156,4,0,
186,10,164,4,0,
186,10,168,4,0,
186,10,173,3,0,
186,10,182,4,0,
186,10,189,3,0,
186,10,195,1,1,4
186,10,196,3,0,
186,10,203,4,0,
186,10,207,1,27,
186,10,207,4,0,
186,10,213,4,0,
186,10,214,4,0,
186,10,216,4,0,
186,10,218,4,0,
186,10,237,4,0,
186,10,240,4,0,
186,10,249,4,0,
186,10,250,4,0,
186,10,258,4,0,
186,10,263,4,0,
186,10,264,4,0,
186,10,270,3,0,
186,10,280,4,0,
186,10,290,4,0,
186,10,291,3,0,
186,10,304,1,48,
186,10,340,1,37,
186,10,340,3,0,
186,10,352,4,0,
186,10,363,4,0,
186,10,371,4,0,
186,10,374,4,0,
186,10,411,4,0,
186,10,416,4,0,
186,10,445,4,0,
186,11,3,1,1,3
186,11,57,4,0,
186,11,58,4,0,
186,11,59,4,0,
186,11,61,1,1,1
186,11,63,4,0,
186,11,70,4,0,
186,11,89,4,0,
186,11,91,4,0,
186,11,92,4,0,
186,11,94,4,0,
186,11,95,1,1,2
186,11,104,4,0,
186,11,127,4,0,
186,11,156,4,0,
186,11,164,4,0,
186,11,168,4,0,
186,11,182,4,0,
186,11,195,1,1,4
186,11,207,1,27,
186,11,207,4,0,
186,11,213,4,0,
186,11,216,4,0,
186,11,218,4,0,
186,11,237,4,0,
186,11,240,4,0,
186,11,249,4,0,
186,11,258,4,0,
186,11,263,4,0,
186,11,280,4,0,
186,11,291,4,0,
186,11,304,1,48,
186,11,340,1,37,
186,11,371,4,0,
186,11,374,4,0,
186,11,411,4,0,
186,11,416,4,0,
186,11,496,4,0,
186,11,497,4,0,
186,11,503,4,0,
186,11,523,4,0,
186,12,3,1,1,3
186,12,55,1,1,1
186,12,57,4,0,
186,12,58,4,0,
186,12,59,4,0,
186,12,63,4,0,
186,12,70,4,0,
186,12,89,4,0,
186,12,91,4,0,
186,12,92,4,0,
186,12,94,4,0,
186,12,95,1,1,2
186,12,104,4,0,
186,12,127,4,0,
186,12,156,4,0,
186,12,168,4,0,
186,12,182,4,0,
186,12,195,1,1,4
186,12,195,1,35,
186,12,207,1,51,
186,12,213,4,0,
186,12,216,4,0,
186,12,218,4,0,
186,12,237,4,0,
186,12,240,4,0,
186,12,249,4,0,
186,12,258,4,0,
186,12,263,4,0,
186,12,264,4,0,
186,12,280,4,0,
186,12,290,4,0,
186,12,291,4,0,
186,12,352,4,0,
186,13,3,1,1,3
186,13,34,3,0,
186,13,38,3,0,
186,13,55,1,1,1
186,13,57,4,0,
186,13,58,4,0,
186,13,59,4,0,
186,13,63,4,0,
186,13,69,3,0,
186,13,70,4,0,
186,13,89,4,0,
186,13,91,4,0,
186,13,92,4,0,
186,13,94,4,0,
186,13,95,1,1,2
186,13,102,3,0,
186,13,104,4,0,
186,13,127,4,0,
186,13,156,4,0,
186,13,164,3,0,
186,13,168,4,0,
186,13,182,4,0,
186,13,195,1,1,4
186,13,195,1,35,
186,13,196,3,0,
186,13,207,1,51,
186,13,207,3,0,
186,13,213,4,0,
186,13,216,4,0,
186,13,218,4,0,
186,13,237,4,0,
186,13,240,4,0,
186,13,249,4,0,
186,13,258,4,0,
186,13,263,4,0,
186,13,264,4,0,
186,13,280,4,0,
186,13,290,4,0,
186,13,291,4,0,
186,13,352,4,0,
186,14,3,1,1,3
186,14,8,3,0,
186,14,57,4,0,
186,14,58,4,0,
186,14,59,4,0,
186,14,61,1,1,1
186,14,63,4,0,
186,14,70,4,0,
186,14,89,4,0,
186,14,91,4,0,
186,14,92,4,0,
186,14,94,4,0,
186,14,95,1,1,2
186,14,104,4,0,
186,14,127,4,0,
186,14,156,4,0,
186,14,164,4,0,
186,14,168,4,0,
186,14,173,3,0,
186,14,182,4,0,
186,14,195,1,1,4
186,14,196,3,0,
186,14,207,1,27,
186,14,207,4,0,
186,14,213,4,0,
186,14,214,3,0,
186,14,216,4,0,
186,14,218,4,0,
186,14,237,4,0,
186,14,240,4,0,
186,14,249,4,0,
186,14,258,4,0,
186,14,263,4,0,
186,14,270,3,0,
186,14,280,4,0,
186,14,283,3,0,
186,14,291,4,0,
186,14,304,1,48,
186,14,304,3,0,
186,14,340,1,37,
186,14,340,3,0,
186,14,371,4,0,
186,14,374,4,0,
186,14,411,4,0,
186,14,416,4,0,
186,14,496,4,0,
186,14,497,4,0,
186,14,503,4,0,
186,14,523,4,0,
186,15,3,1,1,3
186,15,57,4,0,
186,15,58,4,0,
186,15,59,4,0,
186,15,61,1,1,1
186,15,63,4,0,
186,15,70,4,0,
186,15,89,4,0,
186,15,91,4,0,
186,15,92,4,0,
186,15,94,4,0,
186,15,95,1,1,2
186,15,104,4,0,
186,15,127,4,0,
186,15,156,4,0,
186,15,164,4,0,
186,15,168,4,0,
186,15,182,4,0,
186,15,195,1,1,4
186,15,207,1,27,
186,15,207,4,0,
186,15,213,4,0,
186,15,214,4,0,
186,15,216,4,0,
186,15,218,4,0,
186,15,237,4,0,
186,15,240,4,0,
186,15,249,4,0,
186,15,258,4,0,
186,15,263,4,0,
186,15,280,4,0,
186,15,304,1,48,
186,15,340,1,37,
186,15,371,4,0,
186,15,374,4,0,
186,15,411,4,0,
186,15,416,4,0,
186,15,496,4,0,
186,15,497,4,0,
186,15,503,4,0,
186,15,523,4,0,
186,15,590,4,0,
186,15,612,4,0,
186,16,3,1,1,3
186,16,8,3,0,
186,16,57,4,0,
186,16,58,4,0,
186,16,59,4,0,
186,16,61,1,1,1
186,16,63,4,0,
186,16,70,4,0,
186,16,89,4,0,
186,16,91,4,0,
186,16,92,4,0,
186,16,94,4,0,
186,16,95,1,1,2
186,16,104,4,0,
186,16,127,4,0,
186,16,156,4,0,
186,16,164,4,0,
186,16,168,4,0,
186,16,173,3,0,
186,16,182,4,0,
186,16,195,1,1,4
186,16,196,3,0,
186,16,207,1,27,1
186,16,207,4,0,
186,16,213,4,0,
186,16,214,4,0,
186,16,216,4,0,
186,16,218,4,0,
186,16,237,4,0,
186,16,240,4,0,
186,16,249,4,0,
186,16,258,4,0,
186,16,263,4,0,
186,16,264,3,0,
186,16,270,3,0,
186,16,280,4,0,
186,16,283,3,0,
186,16,290,4,0,
186,16,291,4,0,
186,16,304,1,48,1
186,16,304,3,0,
186,16,340,1,37,1
186,16,340,3,0,
186,16,352,3,0,
186,16,371,4,0,
186,16,374,4,0,
186,16,411,4,0,
186,16,416,4,0,
186,16,496,4,0,
186,16,497,4,0,
186,16,503,4,0,
186,16,523,4,0,
186,16,590,4,0,
186,16,612,4,0,
186,17,3,1,1,3
186,17,57,4,0,
186,17,58,4,0,
186,17,59,4,0,
186,17,61,1,1,1
186,17,63,4,0,
186,17,89,4,0,
186,17,92,4,0,
186,17,94,4,0,
186,17,95,1,1,2
186,17,104,4,0,
186,17,127,4,0,
186,17,156,4,0,
186,17,164,4,0,
186,17,168,4,0,
186,17,182,4,0,
186,17,195,1,1,4
186,17,207,1,27,
186,17,207,4,0,
186,17,213,4,0,
186,17,214,4,0,
186,17,216,4,0,
186,17,218,4,0,
186,17,237,4,0,
186,17,240,4,0,
186,17,258,4,0,
186,17,263,4,0,
186,17,280,4,0,
186,17,304,1,48,
186,17,340,1,37,
186,17,371,4,0,
186,17,374,4,0,
186,17,411,4,0,
186,17,416,4,0,
186,17,496,4,0,
186,17,497,4,0,
186,17,503,4,0,
186,17,523,4,0,
186,17,590,4,0,
186,18,3,1,1,3
186,18,57,4,0,
186,18,58,4,0,
186,18,59,4,0,
186,18,61,1,1,1
186,18,63,4,0,
186,18,89,4,0,
186,18,92,4,0,
186,18,94,4,0,
186,18,95,1,1,2
186,18,104,4,0,
186,18,127,4,0,
186,18,156,4,0,
186,18,164,4,0,
186,18,168,4,0,
186,18,182,4,0,
186,18,195,1,1,4
186,18,207,1,27,
186,18,207,4,0,
186,18,213,4,0,
186,18,214,4,0,
186,18,216,4,0,
186,18,218,4,0,
186,18,237,4,0,
186,18,240,4,0,
186,18,258,4,0,
186,18,263,4,0,
186,18,280,4,0,
186,18,304,1,48,
186,18,340,1,37,
186,18,371,4,0,
186,18,374,4,0,
186,18,411,4,0,
186,18,416,4,0,
186,18,496,4,0,
186,18,497,4,0,
186,18,503,4,0,
186,18,523,4,0,
186,18,590,4,0,
187,3,6,2,0,
187,3,29,4,0,
187,3,33,1,10,
187,3,38,2,0,
187,3,39,1,5,
187,3,45,2,0,
187,3,72,1,30,
187,3,73,1,20,
187,3,76,4,0,
187,3,77,1,13,
187,3,78,1,15,
187,3,79,1,17,
187,3,92,4,0,
187,3,93,2,0,
187,3,104,4,0,
187,3,111,4,0,
187,3,115,2,0,
187,3,133,2,0,
187,3,148,4,0,
187,3,150,1,1,1
187,3,156,4,0,
187,3,173,4,0,
187,3,174,4,0,
187,3,178,1,25,
187,3,182,4,0,
187,3,202,4,0,
187,3,203,4,0,
187,3,207,4,0,
187,3,213,4,0,
187,3,214,4,0,
187,3,216,4,0,
187,3,218,4,0,
187,3,227,2,0,
187,3,230,4,0,
187,3,235,1,1,2
187,3,237,4,0,
187,3,241,4,0,
187,4,6,2,0,
187,4,29,4,0,
187,4,33,1,10,
187,4,38,2,0,
187,4,39,1,5,2
187,4,45,2,0,
187,4,72,1,30,
187,4,73,1,20,
187,4,76,4,0,
187,4,77,1,13,
187,4,78,1,15,
187,4,79,1,17,
187,4,92,4,0,
187,4,93,2,0,
187,4,104,4,0,
187,4,111,4,0,
187,4,115,2,0,
187,4,133,2,0,
187,4,148,4,0,
187,4,150,1,1,
187,4,156,4,0,
187,4,173,4,0,
187,4,174,4,0,
187,4,178,1,25,
187,4,182,4,0,
187,4,202,4,0,
187,4,203,4,0,
187,4,207,4,0,
187,4,213,4,0,
187,4,214,4,0,
187,4,216,4,0,
187,4,218,4,0,
187,4,227,2,0,
187,4,230,4,0,
187,4,235,1,5,1
187,4,237,4,0,
187,4,241,4,0,
187,5,33,1,10,
187,5,38,2,0,
187,5,39,1,5,2
187,5,72,1,30,
187,5,73,1,20,
187,5,76,4,0,
187,5,77,1,13,
187,5,78,1,15,
187,5,79,1,17,
187,5,92,4,0,
187,5,93,2,0,
187,5,104,4,0,
187,5,115,2,0,
187,5,133,2,0,
187,5,148,4,0,
187,5,150,1,1,
187,5,156,4,0,
187,5,178,1,25,
187,5,182,4,0,
187,5,202,4,0,
187,5,213,4,0,
187,5,216,4,0,
187,5,218,4,0,
187,5,227,2,0,
187,5,235,1,5,1
187,5,237,4,0,
187,5,241,4,0,
187,5,244,2,0,
187,5,263,4,0,
187,5,270,2,0,
187,5,290,4,0,
187,5,331,4,0,
187,5,332,4,0,
187,6,14,3,0,
187,6,33,1,10,
187,6,38,2,0,
187,6,38,3,0,
187,6,39,1,5,2
187,6,72,1,30,
187,6,73,1,20,
187,6,76,4,0,
187,6,77,1,13,
187,6,78,1,15,
187,6,79,1,17,
187,6,92,4,0,
187,6,93,2,0,
187,6,102,3,0,
187,6,104,4,0,
187,6,111,3,0,
187,6,115,2,0,
187,6,133,2,0,
187,6,148,4,0,
187,6,150,1,1,
187,6,156,4,0,
187,6,164,3,0,
187,6,173,3,0,
187,6,178,1,25,
187,6,182,4,0,
187,6,202,4,0,
187,6,203,3,0,
187,6,207,3,0,
187,6,213,4,0,
187,6,214,3,0,
187,6,216,4,0,
187,6,218,4,0,
187,6,227,2,0,
187,6,235,1,5,1
187,6,237,4,0,
187,6,241,4,0,
187,6,244,2,0,
187,6,263,4,0,
187,6,270,2,0,
187,6,290,4,0,
187,6,331,4,0,
187,6,332,4,0,
187,7,14,3,0,
187,7,33,1,10,
187,7,38,2,0,
187,7,38,3,0,
187,7,39,1,5,2
187,7,72,1,30,
187,7,73,1,20,
187,7,76,4,0,
187,7,77,1,13,
187,7,78,1,15,
187,7,79,1,17,
187,7,92,4,0,
187,7,93,2,0,
187,7,102,3,0,
187,7,104,4,0,
187,7,115,2,0,
187,7,133,2,0,
187,7,148,4,0,
187,7,150,1,1,
187,7,156,4,0,
187,7,164,3,0,
187,7,178,1,25,
187,7,182,4,0,
187,7,202,4,0,
187,7,213,4,0,
187,7,216,4,0,
187,7,218,4,0,
187,7,227,2,0,
187,7,235,1,5,1
187,7,237,4,0,
187,7,241,4,0,
187,7,244,2,0,
187,7,263,4,0,
187,7,270,2,0,
187,7,290,4,0,
187,7,331,4,0,
187,7,332,4,0,
187,8,14,4,0,
187,8,33,1,10,
187,8,38,2,0,
187,8,39,1,7,
187,8,72,1,25,
187,8,73,1,22,
187,8,76,4,0,
187,8,77,1,12,
187,8,78,1,14,
187,8,79,1,16,
187,8,92,4,0,
187,8,93,2,0,
187,8,104,4,0,
187,8,115,2,0,
187,8,133,2,0,
187,8,148,4,0,
187,8,150,1,1,
187,8,156,4,0,
187,8,164,4,0,
187,8,178,1,28,
187,8,182,4,0,
187,8,202,1,37,
187,8,202,4,0,
187,8,203,4,0,
187,8,207,4,0,
187,8,213,4,0,
187,8,214,4,0,
187,8,216,4,0,
187,8,218,4,0,
187,8,227,2,0,
187,8,235,1,4,
187,8,237,4,0,
187,8,241,4,0,
187,8,244,2,0,
187,8,262,1,43,
187,8,263,4,0,
187,8,270,2,0,
187,8,290,4,0,
187,8,312,2,0,
187,8,318,4,0,
187,8,331,1,19,
187,8,331,4,0,
187,8,332,4,0,
187,8,340,1,40,
187,8,363,4,0,
187,8,369,1,31,
187,8,369,4,0,
187,8,388,1,34,
187,8,388,2,0,
187,8,412,4,0,
187,8,445,4,0,
187,8,447,4,0,
187,9,14,4,0,
187,9,33,1,10,
187,9,38,2,0,
187,9,39,1,7,
187,9,72,1,25,
187,9,73,1,22,
187,9,76,4,0,
187,9,77,1,12,
187,9,78,1,14,
187,9,79,1,16,
187,9,92,4,0,
187,9,93,2,0,
187,9,104,4,0,
187,9,115,2,0,
187,9,133,2,0,
187,9,148,4,0,
187,9,150,1,1,
187,9,156,4,0,
187,9,164,4,0,
187,9,173,3,0,
187,9,178,1,28,
187,9,182,4,0,
187,9,202,1,37,
187,9,202,4,0,
187,9,203,4,0,
187,9,207,4,0,
187,9,213,4,0,
187,9,214,4,0,
187,9,216,4,0,
187,9,218,4,0,
187,9,227,2,0,
187,9,235,1,4,
187,9,235,3,0,
187,9,237,4,0,
187,9,241,4,0,
187,9,244,2,0,
187,9,262,1,43,
187,9,263,4,0,
187,9,270,2,0,
187,9,270,3,0,
187,9,290,4,0,
187,9,312,2,0,
187,9,318,4,0,
187,9,331,1,19,
187,9,331,4,0,
187,9,332,4,0,
187,9,340,1,40,
187,9,340,3,0,
187,9,363,4,0,
187,9,369,1,31,
187,9,369,4,0,
187,9,388,1,34,
187,9,388,2,0,
187,9,402,3,0,
187,9,412,4,0,
187,9,445,4,0,
187,9,447,4,0,
187,10,14,4,0,
187,10,29,3,0,
187,10,33,1,10,
187,10,38,2,0,
187,10,39,1,7,
187,10,72,1,25,
187,10,73,1,22,
187,10,76,4,0,
187,10,77,1,12,
187,10,78,1,14,
187,10,79,1,16,
187,10,92,4,0,
187,10,93,2,0,
187,10,104,4,0,
187,10,115,2,0,
187,10,133,2,0,
187,10,148,4,0,
187,10,150,1,1,
187,10,156,4,0,
187,10,164,4,0,
187,10,173,3,0,
187,10,178,1,28,
187,10,182,4,0,
187,10,202,1,37,
187,10,202,4,0,
187,10,203,4,0,
187,10,207,4,0,
187,10,213,4,0,
187,10,214,4,0,
187,10,216,4,0,
187,10,218,4,0,
187,10,227,2,0,
187,10,235,1,4,
187,10,235,3,0,
187,10,237,4,0,
187,10,241,4,0,
187,10,244,2,0,
187,10,262,1,43,
187,10,263,4,0,
187,10,270,2,0,
187,10,270,3,0,
187,10,290,4,0,
187,10,312,2,0,
187,10,318,4,0,
187,10,331,1,19,
187,10,331,4,0,
187,10,332,4,0,
187,10,340,1,40,
187,10,340,3,0,
187,10,363,4,0,
187,10,369,1,31,
187,10,369,4,0,
187,10,388,1,34,
187,10,388,2,0,
187,10,388,3,0,
187,10,402,3,0,
187,10,412,4,0,
187,10,445,4,0,
187,10,447,4,0,
187,11,14,4,0,
187,11,33,1,10,
187,11,38,2,0,
187,11,39,1,7,
187,11,72,1,25,
187,11,73,1,22,
187,11,76,4,0,
187,11,77,1,12,
187,11,78,1,14,
187,11,79,1,16,
187,11,92,4,0,
187,11,93,2,0,
187,11,104,4,0,
187,11,115,4,0,
187,11,133,2,0,
187,11,148,4,0,
187,11,150,1,1,
187,11,156,4,0,
187,11,164,4,0,
187,11,178,1,34,
187,11,182,4,0,
187,11,202,1,43,
187,11,203,2,0,
187,11,207,4,0,
187,11,213,4,0,
187,11,216,4,0,
187,11,218,4,0,
187,11,227,2,0,
187,11,235,1,4,
187,11,237,4,0,
187,11,241,4,0,
187,11,244,4,0,
187,11,262,1,49,
187,11,263,4,0,
187,11,270,2,0,
187,11,312,2,0,
187,11,331,1,19,
187,11,332,4,0,
187,11,340,1,46,
187,11,369,1,37,
187,11,369,4,0,
187,11,388,1,40,
187,11,388,2,0,
187,11,402,2,0,
187,11,412,4,0,
187,11,447,4,0,
187,11,476,1,31,
187,11,496,4,0,
187,11,512,1,28,
187,11,512,4,0,
187,11,538,2,0,
187,12,33,1,10,
187,12,39,1,5,2
187,12,72,1,30,
187,12,73,1,20,
187,12,76,4,0,
187,12,77,1,13,
187,12,78,1,15,
187,12,79,1,17,
187,12,92,4,0,
187,12,104,4,0,
187,12,148,4,0,
187,12,150,1,1,
187,12,156,4,0,
187,12,178,1,25,
187,12,182,4,0,
187,12,202,4,0,
187,12,213,4,0,
187,12,216,4,0,
187,12,218,4,0,
187,12,235,1,5,1
187,12,237,4,0,
187,12,241,4,0,
187,12,263,4,0,
187,12,290,4,0,
187,12,331,4,0,
187,12,332,4,0,
187,13,33,1,10,
187,13,38,3,0,
187,13,39,1,5,2
187,13,72,1,30,
187,13,73,1,20,
187,13,76,4,0,
187,13,77,1,13,
187,13,78,1,15,
187,13,79,1,17,
187,13,92,4,0,
187,13,102,3,0,
187,13,104,4,0,
187,13,148,4,0,
187,13,150,1,1,
187,13,156,4,0,
187,13,164,3,0,
187,13,178,1,25,
187,13,182,4,0,
187,13,202,4,0,
187,13,207,3,0,
187,13,213,4,0,
187,13,216,4,0,
187,13,218,4,0,
187,13,235,1,5,1
187,13,237,4,0,
187,13,241,4,0,
187,13,263,4,0,
187,13,290,4,0,
187,13,331,4,0,
187,13,332,4,0,
187,14,14,4,0,
187,14,33,1,10,
187,14,38,2,0,
187,14,39,1,7,
187,14,72,1,25,
187,14,73,1,22,
187,14,76,4,0,
187,14,77,1,12,
187,14,78,1,14,
187,14,79,1,16,
187,14,92,4,0,
187,14,93,2,0,
187,14,104,4,0,
187,14,115,4,0,
187,14,133,2,0,
187,14,148,4,0,
187,14,150,1,1,
187,14,156,4,0,
187,14,164,4,0,
187,14,173,3,0,
187,14,178,1,34,
187,14,182,4,0,
187,14,202,1,43,
187,14,202,3,0,
187,14,203,2,0,
187,14,207,4,0,
187,14,213,4,0,
187,14,214,3,0,
187,14,216,4,0,
187,14,218,4,0,
187,14,227,2,0,
187,14,235,1,4,
187,14,235,3,0,
187,14,237,4,0,
187,14,241,4,0,
187,14,244,4,0,
187,14,262,1,49,
187,14,263,4,0,
187,14,270,2,0,
187,14,270,3,0,
187,14,312,2,0,
187,14,331,1,19,
187,14,332,4,0,
187,14,340,1,46,
187,14,340,3,0,
187,14,369,1,37,
187,14,369,4,0,
187,14,388,1,40,
187,14,388,2,0,
187,14,388,3,0,
187,14,402,2,0,
187,14,402,3,0,
187,14,412,4,0,
187,14,447,4,0,
187,14,476,1,31,
187,14,496,4,0,
187,14,512,1,28,
187,14,512,4,0,
187,14,538,2,0,
187,15,14,4,0,
187,15,33,1,8,
187,15,38,2,0,
187,15,39,1,6,
187,15,72,1,25,
187,15,73,1,22,
187,15,76,4,0,
187,15,77,1,12,
187,15,78,1,14,
187,15,79,1,16,
187,15,92,4,0,
187,15,93,2,0,
187,15,104,4,0,
187,15,115,4,0,
187,15,133,2,0,
187,15,148,4,0,
187,15,150,1,1,
187,15,156,4,0,
187,15,164,4,0,
187,15,178,1,34,
187,15,182,4,0,
187,15,202,1,43,
187,15,203,2,0,
187,15,207,4,0,
187,15,213,4,0,
187,15,214,4,0,
187,15,216,4,0,
187,15,218,4,0,
187,15,227,2,0,
187,15,235,1,4,
187,15,237,4,0,
187,15,241,4,0,
187,15,244,4,0,
187,15,262,1,49,
187,15,263,4,0,
187,15,270,2,0,
187,15,312,2,0,
187,15,331,1,19,
187,15,332,4,0,
187,15,340,1,46,
187,15,369,1,37,
187,15,369,4,0,
187,15,388,1,40,
187,15,388,2,0,
187,15,402,2,0,
187,15,412,4,0,
187,15,447,4,0,
187,15,476,1,31,
187,15,496,4,0,
187,15,512,1,28,
187,15,512,4,0,
187,15,538,2,0,
187,15,580,2,0,
187,15,584,1,10,
187,15,590,4,0,
187,15,605,4,0,
187,15,611,4,0,
187,16,14,4,0,
187,16,33,1,8,1
187,16,38,2,0,
187,16,39,1,6,1
187,16,72,1,25,1
187,16,73,1,22,1
187,16,76,4,0,
187,16,77,1,12,1
187,16,78,1,14,1
187,16,79,1,16,1
187,16,92,4,0,
187,16,93,2,0,
187,16,104,4,0,
187,16,115,4,0,
187,16,133,2,0,
187,16,148,4,0,
187,16,150,1,1,1
187,16,156,4,0,
187,16,164,4,0,
187,16,173,3,0,
187,16,178,1,34,1
187,16,182,4,0,
187,16,202,1,43,1
187,16,202,3,0,
187,16,203,2,0,
187,16,207,4,0,
187,16,213,4,0,
187,16,214,4,0,
187,16,216,4,0,
187,16,218,4,0,
187,16,227,2,0,
187,16,235,1,4,1
187,16,235,3,0,
187,16,237,4,0,
187,16,241,4,0,
187,16,244,4,0,
187,16,262,1,49,1
187,16,263,4,0,
187,16,270,2,0,
187,16,270,3,0,
187,16,290,4,0,
187,16,312,2,0,
187,16,331,1,19,1
187,16,332,4,0,
187,16,340,1,46,1
187,16,340,3,0,
187,16,369,1,37,1
187,16,369,4,0,
187,16,388,1,40,1
187,16,388,2,0,
187,16,388,3,0,
187,16,402,2,0,
187,16,402,3,0,
187,16,412,4,0,
187,16,447,4,0,
187,16,476,1,31,1
187,16,496,4,0,
187,16,512,1,28,1
187,16,512,4,0,
187,16,538,2,0,
187,16,580,2,0,
187,16,584,1,10,1
187,16,590,4,0,
187,16,605,4,0,
187,16,611,4,0,
187,17,14,4,0,
187,17,33,1,8,
187,17,38,2,0,
187,17,39,1,6,
187,17,72,1,25,
187,17,73,1,22,
187,17,76,4,0,
187,17,77,1,12,
187,17,78,1,14,
187,17,79,1,16,
187,17,92,4,0,
187,17,93,2,0,
187,17,104,4,0,
187,17,115,4,0,
187,17,133,2,0,
187,17,150,1,1,
187,17,156,4,0,
187,17,164,4,0,
187,17,178,1,34,
187,17,182,4,0,
187,17,202,1,43,
187,17,203,2,0,
187,17,207,4,0,
187,17,213,4,0,
187,17,214,4,0,
187,17,216,4,0,
187,17,218,4,0,
187,17,227,2,0,
187,17,235,1,4,
187,17,237,4,0,
187,17,241,4,0,
187,17,244,4,0,
187,17,262,1,49,
187,17,263,4,0,
187,17,270,2,0,
187,17,312,2,0,
187,17,331,1,19,
187,17,332,4,0,
187,17,340,1,46,
187,17,369,1,37,
187,17,369,4,0,
187,17,388,1,40,
187,17,388,2,0,
187,17,402,2,0,
187,17,412,4,0,
187,17,447,4,0,
187,17,476,1,31,
187,17,496,4,0,
187,17,512,1,28,
187,17,512,4,0,
187,17,538,2,0,
187,17,580,2,0,
187,17,584,1,10,
187,17,590,4,0,
187,17,605,4,0,
187,17,611,4,0,
187,18,14,4,0,
187,18,33,1,8,
187,18,38,2,0,
187,18,39,1,6,
187,18,71,1,1,2
187,18,72,1,25,
187,18,73,1,22,
187,18,76,4,0,
187,18,77,1,12,
187,18,78,1,14,
187,18,79,1,16,
187,18,92,4,0,
187,18,93,2,0,
187,18,104,4,0,
187,18,115,4,0,
187,18,133,2,0,
187,18,150,1,1,1
187,18,156,4,0,
187,18,164,4,0,
187,18,178,1,34,
187,18,182,4,0,
187,18,202,1,43,
187,18,203,2,0,
187,18,207,4,0,
187,18,213,4,0,
187,18,214,4,0,
187,18,216,4,0,
187,18,218,4,0,
187,18,227,2,0,
187,18,235,1,4,
187,18,237,4,0,
187,18,241,4,0,
187,18,244,4,0,
187,18,262,1,49,
187,18,263,4,0,
187,18,270,2,0,
187,18,312,2,0,
187,18,331,1,19,
187,18,332,4,0,
187,18,340,1,46,
187,18,369,1,37,
187,18,369,4,0,
187,18,388,1,40,
187,18,388,2,0,
187,18,402,2,0,
187,18,412,4,0,
187,18,447,4,0,
187,18,476,1,31,
187,18,496,4,0,
187,18,512,1,28,
187,18,512,4,0,
187,18,538,2,0,
187,18,580,2,0,
187,18,584,1,10,
187,18,590,4,0,
187,18,605,4,0,
187,18,611,4,0,
187,18,668,2,0,
188,3,29,4,0,
188,3,33,1,1,4
188,3,33,1,10,
188,3,39,1,1,3
188,3,39,1,5,
188,3,72,1,36,
188,3,73,1,22,
188,3,76,4,0,
188,3,77,1,13,
188,3,78,1,15,
188,3,79,1,17,
188,3,92,4,0,
188,3,104,4,0,
188,3,111,4,0,
188,3,148,4,0,
188,3,150,1,1,1
188,3,156,4,0,
188,3,173,4,0,
188,3,174,4,0,
188,3,178,1,29,
188,3,182,4,0,
188,3,202,4,0,
188,3,203,4,0,
188,3,207,4,0,
188,3,213,4,0,
188,3,214,4,0,
188,3,216,4,0,
188,3,218,4,0,
188,3,230,4,0,
188,3,235,1,1,2
188,3,237,4,0,
188,3,241,4,0,
188,4,29,4,0,
188,4,33,1,1,4
188,4,33,1,10,
188,4,39,1,1,3
188,4,39,1,5,5
188,4,72,1,36,
188,4,73,1,22,
188,4,76,4,0,
188,4,77,1,13,
188,4,78,1,15,
188,4,79,1,17,
188,4,92,4,0,
188,4,104,4,0,
188,4,111,4,0,
188,4,148,4,0,
188,4,150,1,1,1
188,4,156,4,0,
188,4,173,4,0,
188,4,174,4,0,
188,4,178,1,29,
188,4,182,4,0,
188,4,202,4,0,
188,4,203,4,0,
188,4,207,4,0,
188,4,213,4,0,
188,4,214,4,0,
188,4,216,4,0,
188,4,218,4,0,
188,4,230,4,0,
188,4,235,1,1,2
188,4,235,1,5,
188,4,237,4,0,
188,4,241,4,0,
188,5,33,1,1,4
188,5,33,1,10,
188,5,39,1,1,3
188,5,39,1,5,5
188,5,72,1,36,
188,5,73,1,22,
188,5,76,4,0,
188,5,77,1,13,
188,5,78,1,15,
188,5,79,1,17,
188,5,92,4,0,
188,5,104,4,0,
188,5,148,4,0,
188,5,150,1,1,1
188,5,156,4,0,
188,5,178,1,29,
188,5,182,4,0,
188,5,202,4,0,
188,5,213,4,0,
188,5,216,4,0,
188,5,218,4,0,
188,5,235,1,1,2
188,5,235,1,5,
188,5,237,4,0,
188,5,241,4,0,
188,5,263,4,0,
188,5,290,4,0,
188,5,331,4,0,
188,5,332,4,0,
188,6,14,3,0,
188,6,33,1,1,4
188,6,33,1,10,
188,6,38,3,0,
188,6,39,1,1,3
188,6,39,1,5,5
188,6,72,1,36,
188,6,73,1,22,
188,6,76,4,0,
188,6,77,1,13,
188,6,78,1,15,
188,6,79,1,17,
188,6,92,4,0,
188,6,102,3,0,
188,6,104,4,0,
188,6,111,3,0,
188,6,148,4,0,
188,6,150,1,1,1
188,6,156,4,0,
188,6,164,3,0,
188,6,173,3,0,
188,6,178,1,29,
188,6,182,4,0,
188,6,202,4,0,
188,6,203,3,0,
188,6,207,3,0,
188,6,213,4,0,
188,6,214,3,0,
188,6,216,4,0,
188,6,218,4,0,
188,6,235,1,1,2
188,6,235,1,5,
188,6,237,4,0,
188,6,241,4,0,
188,6,263,4,0,
188,6,290,4,0,
188,6,331,4,0,
188,6,332,4,0,
188,7,14,3,0,
188,7,33,1,1,4
188,7,33,1,10,
188,7,38,3,0,
188,7,39,1,1,3
188,7,39,1,5,5
188,7,72,1,36,
188,7,73,1,22,
188,7,76,4,0,
188,7,77,1,13,
188,7,78,1,15,
188,7,79,1,17,
188,7,92,4,0,
188,7,102,3,0,
188,7,104,4,0,
188,7,148,4,0,
188,7,150,1,1,1
188,7,156,4,0,
188,7,164,3,0,
188,7,178,1,29,
188,7,182,4,0,
188,7,202,4,0,
188,7,213,4,0,
188,7,216,4,0,
188,7,218,4,0,
188,7,235,1,1,2
188,7,235,1,5,
188,7,237,4,0,
188,7,241,4,0,
188,7,263,4,0,
188,7,290,4,0,
188,7,331,4,0,
188,7,332,4,0,
188,8,14,4,0,
188,8,33,1,1,4
188,8,33,1,10,
188,8,39,1,1,3
188,8,39,1,7,
188,8,72,1,28,
188,8,73,1,24,
188,8,76,4,0,
188,8,77,1,12,
188,8,78,1,14,
188,8,79,1,16,
188,8,92,4,0,
188,8,104,4,0,
188,8,148,4,0,
188,8,150,1,1,1
188,8,156,4,0,
188,8,164,4,0,
188,8,178,1,32,
188,8,182,4,0,
188,8,202,1,44,
188,8,202,4,0,
188,8,203,4,0,
188,8,207,4,0,
188,8,213,4,0,
188,8,214,4,0,
188,8,216,4,0,
188,8,218,4,0,
188,8,235,1,1,2
188,8,235,1,4,
188,8,237,4,0,
188,8,241,4,0,
188,8,262,1,52,
188,8,263,4,0,
188,8,290,4,0,
188,8,318,4,0,
188,8,331,1,20,
188,8,331,4,0,
188,8,332,4,0,
188,8,340,1,48,
188,8,363,4,0,
188,8,369,1,36,
188,8,369,4,0,
188,8,388,1,40,
188,8,412,4,0,
188,8,445,4,0,
188,8,447,4,0,
188,9,14,4,0,
188,9,33,1,1,4
188,9,33,1,10,
188,9,39,1,1,3
188,9,39,1,7,
188,9,72,1,28,
188,9,73,1,24,
188,9,76,4,0,
188,9,77,1,12,
188,9,78,1,14,
188,9,79,1,16,
188,9,92,4,0,
188,9,104,4,0,
188,9,148,4,0,
188,9,150,1,1,1
188,9,156,4,0,
188,9,164,4,0,
188,9,173,3,0,
188,9,178,1,32,
188,9,182,4,0,
188,9,202,1,44,
188,9,202,4,0,
188,9,203,4,0,
188,9,207,4,0,
188,9,213,4,0,
188,9,214,4,0,
188,9,216,4,0,
188,9,218,4,0,
188,9,235,1,1,2
188,9,235,1,4,
188,9,235,3,0,
188,9,237,4,0,
188,9,241,4,0,
188,9,262,1,52,
188,9,263,4,0,
188,9,270,3,0,
188,9,290,4,0,
188,9,318,4,0,
188,9,331,1,20,
188,9,331,4,0,
188,9,332,4,0,
188,9,340,1,48,
188,9,340,3,0,
188,9,363,4,0,
188,9,369,1,36,
188,9,369,4,0,
188,9,388,1,40,
188,9,402,3,0,
188,9,412,4,0,
188,9,445,4,0,
188,9,447,4,0,
188,10,14,4,0,
188,10,29,3,0,
188,10,33,1,1,4
188,10,33,1,10,
188,10,39,1,1,3
188,10,39,1,7,
188,10,72,1,28,
188,10,73,1,24,
188,10,76,4,0,
188,10,77,1,12,
188,10,78,1,14,
188,10,79,1,16,
188,10,92,4,0,
188,10,104,4,0,
188,10,148,4,0,
188,10,150,1,1,1
188,10,156,4,0,
188,10,164,4,0,
188,10,173,3,0,
188,10,178,1,32,
188,10,182,4,0,
188,10,202,1,44,
188,10,202,4,0,
188,10,203,4,0,
188,10,207,4,0,
188,10,213,4,0,
188,10,214,4,0,
188,10,216,4,0,
188,10,218,4,0,
188,10,235,1,1,2
188,10,235,1,4,
188,10,235,3,0,
188,10,237,4,0,
188,10,241,4,0,
188,10,262,1,52,
188,10,263,4,0,
188,10,270,3,0,
188,10,290,4,0,
188,10,318,4,0,
188,10,331,1,20,
188,10,331,4,0,
188,10,332,4,0,
188,10,340,1,48,
188,10,340,3,0,
188,10,363,4,0,
188,10,369,1,36,
188,10,369,4,0,
188,10,388,1,40,
188,10,388,3,0,
188,10,402,3,0,
188,10,412,4,0,
188,10,445,4,0,
188,10,447,4,0,
188,11,14,4,0,
188,11,33,1,1,4
188,11,33,1,10,
188,11,39,1,1,3
188,11,39,1,7,
188,11,72,1,28,
188,11,73,1,24,
188,11,76,4,0,
188,11,77,1,12,
188,11,78,1,14,
188,11,79,1,16,
188,11,92,4,0,
188,11,104,4,0,
188,11,115,4,0,
188,11,148,4,0,
188,11,150,1,1,1
188,11,156,4,0,
188,11,164,4,0,
188,11,178,1,40,
188,11,182,4,0,
188,11,202,1,52,
188,11,207,4,0,
188,11,213,4,0,
188,11,216,4,0,
188,11,218,4,0,
188,11,235,1,1,2
188,11,235,1,4,
188,11,237,4,0,
188,11,241,4,0,
188,11,244,4,0,
188,11,262,1,60,
188,11,263,4,0,
188,11,331,1,20,
188,11,332,4,0,
188,11,340,1,56,
188,11,369,1,44,
188,11,369,4,0,
188,11,388,1,48,
188,11,412,4,0,
188,11,447,4,0,
188,11,476,1,36,
188,11,496,4,0,
188,11,512,1,32,
188,11,512,4,0,
188,12,33,1,1,4
188,12,33,1,10,
188,12,39,1,1,3
188,12,39,1,5,5
188,12,72,1,36,
188,12,73,1,22,
188,12,76,4,0,
188,12,77,1,13,
188,12,78,1,15,
188,12,79,1,17,
188,12,92,4,0,
188,12,104,4,0,
188,12,148,4,0,
188,12,150,1,1,1
188,12,156,4,0,
188,12,178,1,29,
188,12,182,4,0,
188,12,202,4,0,
188,12,213,4,0,
188,12,216,4,0,
188,12,218,4,0,
188,12,235,1,1,2
188,12,235,1,5,
188,12,237,4,0,
188,12,241,4,0,
188,12,263,4,0,
188,12,290,4,0,
188,12,331,4,0,
188,12,332,4,0,
188,13,33,1,1,4
188,13,33,1,10,
188,13,38,3,0,
188,13,39,1,1,3
188,13,39,1,5,5
188,13,72,1,36,
188,13,73,1,22,
188,13,76,4,0,
188,13,77,1,13,
188,13,78,1,15,
188,13,79,1,17,
188,13,92,4,0,
188,13,102,3,0,
188,13,104,4,0,
188,13,148,4,0,
188,13,150,1,1,1
188,13,156,4,0,
188,13,164,3,0,
188,13,178,1,29,
188,13,182,4,0,
188,13,202,4,0,
188,13,207,3,0,
188,13,213,4,0,
188,13,216,4,0,
188,13,218,4,0,
188,13,235,1,1,2
188,13,235,1,5,
188,13,237,4,0,
188,13,241,4,0,
188,13,263,4,0,
188,13,290,4,0,
188,13,331,4,0,
188,13,332,4,0,
188,14,14,4,0,
188,14,33,1,1,4
188,14,33,1,10,
188,14,39,1,1,3
188,14,39,1,7,
188,14,72,1,28,
188,14,73,1,24,
188,14,76,4,0,
188,14,77,1,12,
188,14,78,1,14,
188,14,79,1,16,
188,14,92,4,0,
188,14,104,4,0,
188,14,115,4,0,
188,14,148,4,0,
188,14,150,1,1,1
188,14,156,4,0,
188,14,164,4,0,
188,14,173,3,0,
188,14,178,1,40,
188,14,182,4,0,
188,14,202,1,52,
188,14,202,3,0,
188,14,207,4,0,
188,14,213,4,0,
188,14,214,3,0,
188,14,216,4,0,
188,14,218,4,0,
188,14,235,1,1,2
188,14,235,1,4,
188,14,235,3,0,
188,14,237,4,0,
188,14,241,4,0,
188,14,244,4,0,
188,14,262,1,60,
188,14,263,4,0,
188,14,270,3,0,
188,14,331,1,20,
188,14,332,4,0,
188,14,340,1,56,
188,14,340,3,0,
188,14,369,1,44,
188,14,369,4,0,
188,14,388,1,48,
188,14,388,3,0,
188,14,402,3,0,
188,14,412,4,0,
188,14,447,4,0,
188,14,476,1,36,
188,14,496,4,0,
188,14,512,1,32,
188,14,512,4,0,
188,15,14,4,0,
188,15,33,1,1,4
188,15,33,1,8,
188,15,39,1,1,3
188,15,39,1,6,
188,15,72,1,28,
188,15,73,1,24,
188,15,76,4,0,
188,15,77,1,12,
188,15,78,1,14,
188,15,79,1,16,
188,15,92,4,0,
188,15,104,4,0,
188,15,115,4,0,
188,15,148,4,0,
188,15,150,1,1,1
188,15,156,4,0,
188,15,164,4,0,
188,15,178,1,40,
188,15,182,4,0,
188,15,202,1,52,
188,15,207,4,0,
188,15,213,4,0,
188,15,214,4,0,
188,15,216,4,0,
188,15,218,4,0,
188,15,235,1,1,2
188,15,235,1,4,
188,15,237,4,0,
188,15,241,4,0,
188,15,244,4,0,
188,15,262,1,60,
188,15,263,4,0,
188,15,331,1,20,
188,15,332,4,0,
188,15,340,1,56,
188,15,369,1,44,
188,15,369,4,0,
188,15,388,1,48,
188,15,412,4,0,
188,15,447,4,0,
188,15,476,1,36,
188,15,496,4,0,
188,15,512,1,32,
188,15,512,4,0,
188,15,584,1,10,
188,15,590,4,0,
188,15,605,4,0,
188,15,611,4,0,
188,16,14,4,0,
188,16,33,1,1,4
188,16,33,1,8,1
188,16,39,1,1,3
188,16,39,1,6,1
188,16,72,1,28,1
188,16,73,1,24,1
188,16,76,4,0,
188,16,77,1,12,1
188,16,78,1,14,1
188,16,79,1,16,1
188,16,92,4,0,
188,16,104,4,0,
188,16,115,4,0,
188,16,148,4,0,
188,16,150,1,1,1
188,16,156,4,0,
188,16,164,4,0,
188,16,173,3,0,
188,16,178,1,40,1
188,16,182,4,0,
188,16,202,1,52,1
188,16,202,3,0,
188,16,207,4,0,
188,16,213,4,0,
188,16,214,4,0,
188,16,216,4,0,
188,16,218,4,0,
188,16,235,1,1,2
188,16,235,1,4,1
188,16,235,3,0,
188,16,237,4,0,
188,16,241,4,0,
188,16,244,4,0,
188,16,262,1,60,1
188,16,263,4,0,
188,16,270,3,0,
188,16,290,4,0,
188,16,331,1,20,1
188,16,332,4,0,
188,16,340,1,56,1
188,16,340,3,0,
188,16,369,1,44,1
188,16,369,4,0,
188,16,388,1,48,1
188,16,388,3,0,
188,16,402,3,0,
188,16,412,4,0,
188,16,447,4,0,
188,16,476,1,36,1
188,16,496,4,0,
188,16,512,1,32,1
188,16,512,4,0,
188,16,584,1,10,1
188,16,590,4,0,
188,16,605,4,0,
188,16,611,4,0,
188,17,14,4,0,
188,17,33,1,1,4
188,17,33,1,8,
188,17,39,1,1,3
188,17,39,1,6,
188,17,72,1,28,
188,17,73,1,24,
188,17,76,4,0,
188,17,77,1,12,
188,17,78,1,14,
188,17,79,1,16,
188,17,92,4,0,
188,17,104,4,0,
188,17,115,4,0,
188,17,150,1,1,1
188,17,156,4,0,
188,17,164,4,0,
188,17,178,1,40,
188,17,182,4,0,
188,17,202,1,52,
188,17,207,4,0,
188,17,213,4,0,
188,17,214,4,0,
188,17,216,4,0,
188,17,218,4,0,
188,17,235,1,1,2
188,17,235,1,4,
188,17,237,4,0,
188,17,241,4,0,
188,17,244,4,0,
188,17,262,1,60,
188,17,263,4,0,
188,17,331,1,20,
188,17,332,4,0,
188,17,340,1,56,
188,17,369,1,44,
188,17,369,4,0,
188,17,388,1,48,
188,17,412,4,0,
188,17,447,4,0,
188,17,476,1,36,
188,17,496,4,0,
188,17,512,1,32,
188,17,512,4,0,
188,17,584,1,10,
188,17,590,4,0,
188,17,605,4,0,
188,17,611,4,0,
188,18,14,4,0,
188,18,33,1,8,
188,18,39,1,1,4
188,18,39,1,6,
188,18,71,1,1,2
188,18,72,1,28,
188,18,73,1,24,
188,18,76,4,0,
188,18,77,1,12,
188,18,78,1,14,
188,18,79,1,16,
188,18,92,4,0,
188,18,104,4,0,
188,18,115,4,0,
188,18,150,1,1,1
188,18,156,4,0,
188,18,164,4,0,
188,18,178,1,40,
188,18,182,4,0,
188,18,202,1,52,
188,18,207,4,0,
188,18,213,4,0,
188,18,214,4,0,
188,18,216,4,0,
188,18,218,4,0,
188,18,235,1,1,3
188,18,235,1,4,
188,18,237,4,0,
188,18,241,4,0,
188,18,244,4,0,
188,18,262,1,60,
188,18,263,4,0,
188,18,331,1,20,
188,18,332,4,0,
188,18,340,1,56,
188,18,369,1,44,
188,18,369,4,0,
188,18,388,1,48,
188,18,412,4,0,
188,18,447,4,0,
188,18,476,1,36,
188,18,496,4,0,
188,18,512,1,32,
188,18,512,4,0,
188,18,584,1,10,
188,18,590,4,0,
188,18,605,4,0,
188,18,611,4,0,
189,3,29,4,0,
189,3,33,1,1,4
189,3,33,1,10,
189,3,39,1,1,3
189,3,39,1,5,
189,3,63,4,0,
189,3,72,1,44,
189,3,73,1,22,
189,3,76,4,0,
189,3,77,1,13,
189,3,78,1,15,
189,3,79,1,17,
189,3,92,4,0,
189,3,104,4,0,
189,3,111,4,0,
189,3,148,4,0,
189,3,150,1,1,1
189,3,156,4,0,
189,3,173,4,0,
189,3,174,4,0,
189,3,178,1,33,
189,3,182,4,0,
189,3,202,4,0,
189,3,203,4,0,
189,3,207,4,0,
189,3,213,4,0,
189,3,214,4,0,
189,3,216,4,0,
189,3,218,4,0,
189,3,230,4,0,
189,3,235,1,1,2
189,3,237,4,0,
189,3,241,4,0,
189,4,29,4,0,
189,4,33,1,1,4
189,4,33,1,10,
189,4,39,1,1,3
189,4,39,1,5,5
189,4,63,4,0,
189,4,72,1,44,
189,4,73,1,22,
189,4,76,4,0,
189,4,77,1,13,
189,4,78,1,15,
189,4,79,1,17,
189,4,92,4,0,
189,4,104,4,0,
189,4,111,4,0,
189,4,148,4,0,
189,4,150,1,1,1
189,4,156,4,0,
189,4,173,4,0,
189,4,174,4,0,
189,4,178,1,33,
189,4,182,4,0,
189,4,202,4,0,
189,4,203,4,0,
189,4,207,4,0,
189,4,213,4,0,
189,4,214,4,0,
189,4,216,4,0,
189,4,218,4,0,
189,4,230,4,0,
189,4,235,1,1,2
189,4,235,1,5,
189,4,237,4,0,
189,4,241,4,0,
189,5,33,1,1,4
189,5,33,1,10,
189,5,39,1,1,3
189,5,39,1,5,5
189,5,63,4,0,
189,5,72,1,44,
189,5,73,1,22,
189,5,76,4,0,
189,5,77,1,13,
189,5,78,1,15,
189,5,79,1,17,
189,5,92,4,0,
189,5,104,4,0,
189,5,148,4,0,
189,5,150,1,1,1
189,5,156,4,0,
189,5,178,1,33,
189,5,182,4,0,
189,5,202,4,0,
189,5,213,4,0,
189,5,216,4,0,
189,5,218,4,0,
189,5,235,1,1,2
189,5,235,1,5,
189,5,237,4,0,
189,5,241,4,0,
189,5,263,4,0,
189,5,290,4,0,
189,5,331,4,0,
189,5,332,4,0,
189,6,14,3,0,
189,6,33,1,1,4
189,6,33,1,10,
189,6,38,3,0,
189,6,39,1,1,3
189,6,39,1,5,5
189,6,63,4,0,
189,6,72,1,44,
189,6,73,1,22,
189,6,76,4,0,
189,6,77,1,13,
189,6,78,1,15,
189,6,79,1,17,
189,6,92,4,0,
189,6,102,3,0,
189,6,104,4,0,
189,6,111,3,0,
189,6,148,4,0,
189,6,150,1,1,1
189,6,156,4,0,
189,6,164,3,0,
189,6,173,3,0,
189,6,178,1,33,
189,6,182,4,0,
189,6,202,4,0,
189,6,203,3,0,
189,6,207,3,0,
189,6,213,4,0,
189,6,214,3,0,
189,6,216,4,0,
189,6,218,4,0,
189,6,235,1,1,2
189,6,235,1,5,
189,6,237,4,0,
189,6,241,4,0,
189,6,263,4,0,
189,6,290,4,0,
189,6,331,4,0,
189,6,332,4,0,
189,7,14,3,0,
189,7,33,1,1,4
189,7,33,1,10,
189,7,38,3,0,
189,7,39,1,1,3
189,7,39,1,5,5
189,7,63,4,0,
189,7,72,1,44,
189,7,73,1,22,
189,7,76,4,0,
189,7,77,1,13,
189,7,78,1,15,
189,7,79,1,17,
189,7,92,4,0,
189,7,102,3,0,
189,7,104,4,0,
189,7,148,4,0,
189,7,150,1,1,1
189,7,156,4,0,
189,7,164,3,0,
189,7,178,1,33,
189,7,182,4,0,
189,7,202,4,0,
189,7,213,4,0,
189,7,216,4,0,
189,7,218,4,0,
189,7,235,1,1,2
189,7,235,1,5,
189,7,237,4,0,
189,7,241,4,0,
189,7,263,4,0,
189,7,290,4,0,
189,7,331,4,0,
189,7,332,4,0,
189,8,14,4,0,
189,8,33,1,1,4
189,8,33,1,10,
189,8,39,1,1,3
189,8,39,1,7,
189,8,63,4,0,
189,8,72,1,28,
189,8,73,1,24,
189,8,76,4,0,
189,8,77,1,12,
189,8,78,1,14,
189,8,79,1,16,
189,8,92,4,0,
189,8,104,4,0,
189,8,148,4,0,
189,8,150,1,1,1
189,8,156,4,0,
189,8,164,4,0,
189,8,178,1,32,
189,8,182,4,0,
189,8,202,1,44,
189,8,202,4,0,
189,8,203,4,0,
189,8,207,4,0,
189,8,213,4,0,
189,8,214,4,0,
189,8,216,4,0,
189,8,218,4,0,
189,8,235,1,1,2
189,8,235,1,4,
189,8,237,4,0,
189,8,241,4,0,
189,8,262,1,52,
189,8,263,4,0,
189,8,290,4,0,
189,8,318,4,0,
189,8,331,1,20,
189,8,331,4,0,
189,8,332,4,0,
189,8,340,1,48,
189,8,363,4,0,
189,8,369,1,36,
189,8,369,4,0,
189,8,388,1,40,
189,8,412,4,0,
189,8,416,4,0,
189,8,445,4,0,
189,8,447,4,0,
189,9,14,4,0,
189,9,33,1,1,4
189,9,33,1,10,
189,9,39,1,1,3
189,9,39,1,7,
189,9,63,4,0,
189,9,72,1,28,
189,9,73,1,24,
189,9,76,4,0,
189,9,77,1,12,
189,9,78,1,14,
189,9,79,1,16,
189,9,92,4,0,
189,9,104,4,0,
189,9,148,4,0,
189,9,150,1,1,1
189,9,156,4,0,
189,9,164,4,0,
189,9,173,3,0,
189,9,178,1,32,
189,9,182,4,0,
189,9,202,1,44,
189,9,202,4,0,
189,9,203,4,0,
189,9,207,4,0,
189,9,213,4,0,
189,9,214,4,0,
189,9,216,4,0,
189,9,218,4,0,
189,9,235,1,1,2
189,9,235,1,4,
189,9,235,3,0,
189,9,237,4,0,
189,9,241,4,0,
189,9,262,1,52,
189,9,263,4,0,
189,9,270,3,0,
189,9,290,4,0,
189,9,318,4,0,
189,9,331,1,20,
189,9,331,4,0,
189,9,332,4,0,
189,9,340,1,48,
189,9,340,3,0,
189,9,363,4,0,
189,9,369,1,36,
189,9,369,4,0,
189,9,388,1,40,
189,9,402,3,0,
189,9,412,4,0,
189,9,416,4,0,
189,9,445,4,0,
189,9,447,4,0,
189,10,14,4,0,
189,10,29,3,0,
189,10,33,1,1,4
189,10,33,1,10,
189,10,39,1,1,3
189,10,39,1,7,
189,10,63,4,0,
189,10,72,1,28,
189,10,73,1,24,
189,10,76,4,0,
189,10,77,1,12,
189,10,78,1,14,
189,10,79,1,16,
189,10,92,4,0,
189,10,104,4,0,
189,10,148,4,0,
189,10,150,1,1,1
189,10,156,4,0,
189,10,164,4,0,
189,10,173,3,0,
189,10,178,1,32,
189,10,182,4,0,
189,10,202,1,44,
189,10,202,4,0,
189,10,203,4,0,
189,10,207,4,0,
189,10,213,4,0,
189,10,214,4,0,
189,10,216,4,0,
189,10,218,4,0,
189,10,235,1,1,2
189,10,235,1,4,
189,10,235,3,0,
189,10,237,4,0,
189,10,241,4,0,
189,10,262,1,52,
189,10,263,4,0,
189,10,270,3,0,
189,10,290,4,0,
189,10,318,4,0,
189,10,331,1,20,
189,10,331,4,0,
189,10,332,4,0,
189,10,340,1,48,
189,10,340,3,0,
189,10,363,4,0,
189,10,369,1,36,
189,10,369,4,0,
189,10,388,1,40,
189,10,388,3,0,
189,10,402,3,0,
189,10,412,4,0,
189,10,416,4,0,
189,10,445,4,0,
189,10,447,4,0,
189,11,14,4,0,
189,11,33,1,1,4
189,11,33,1,10,
189,11,39,1,1,3
189,11,39,1,7,
189,11,63,4,0,
189,11,72,1,29,
189,11,73,1,24,
189,11,76,4,0,
189,11,77,1,12,
189,11,78,1,14,
189,11,79,1,16,
189,11,92,4,0,
189,11,104,4,0,
189,11,115,4,0,
189,11,148,4,0,
189,11,150,1,1,1
189,11,156,4,0,
189,11,164,4,0,
189,11,178,1,44,
189,11,182,4,0,
189,11,202,1,59,
189,11,207,4,0,
189,11,213,4,0,
189,11,216,4,0,
189,11,218,4,0,
189,11,235,1,1,2
189,11,235,1,4,
189,11,237,4,0,
189,11,241,4,0,
189,11,244,4,0,
189,11,262,1,69,
189,11,263,4,0,
189,11,331,1,20,
189,11,332,4,0,
189,11,340,1,64,
189,11,369,1,49,
189,11,369,4,0,
189,11,388,1,54,
189,11,412,4,0,
189,11,416,4,0,
189,11,447,4,0,
189,11,476,1,39,
189,11,496,4,0,
189,11,512,1,34,
189,11,512,4,0,
189,12,33,1,1,4
189,12,33,1,10,
189,12,39,1,1,3
189,12,39,1,5,5
189,12,63,4,0,
189,12,72,1,44,
189,12,73,1,22,
189,12,76,4,0,
189,12,77,1,13,
189,12,78,1,15,
189,12,79,1,17,
189,12,92,4,0,
189,12,104,4,0,
189,12,148,4,0,
189,12,150,1,1,1
189,12,156,4,0,
189,12,178,1,33,
189,12,182,4,0,
189,12,202,4,0,
189,12,213,4,0,
189,12,216,4,0,
189,12,218,4,0,
189,12,235,1,1,2
189,12,235,1,5,
189,12,237,4,0,
189,12,241,4,0,
189,12,263,4,0,
189,12,290,4,0,
189,12,331,4,0,
189,12,332,4,0,
189,13,33,1,1,4
189,13,33,1,10,
189,13,38,3,0,
189,13,39,1,1,3
189,13,39,1,5,5
189,13,63,4,0,
189,13,72,1,44,
189,13,73,1,22,
189,13,76,4,0,
189,13,77,1,13,
189,13,78,1,15,
189,13,79,1,17,
189,13,92,4,0,
189,13,102,3,0,
189,13,104,4,0,
189,13,148,4,0,
189,13,150,1,1,1
189,13,156,4,0,
189,13,164,3,0,
189,13,178,1,33,
189,13,182,4,0,
189,13,202,4,0,
189,13,207,3,0,
189,13,213,4,0,
189,13,216,4,0,
189,13,218,4,0,
189,13,235,1,1,2
189,13,235,1,5,
189,13,237,4,0,
189,13,241,4,0,
189,13,263,4,0,
189,13,290,4,0,
189,13,331,4,0,
189,13,332,4,0,
189,14,14,4,0,
189,14,33,1,1,4
189,14,33,1,10,
189,14,39,1,1,3
189,14,39,1,7,
189,14,63,4,0,
189,14,72,1,29,
189,14,73,1,24,
189,14,76,4,0,
189,14,77,1,12,
189,14,78,1,14,
189,14,79,1,16,
189,14,92,4,0,
189,14,104,4,0,
189,14,115,4,0,
189,14,148,4,0,
189,14,150,1,1,1
189,14,156,4,0,
189,14,164,4,0,
189,14,173,3,0,
189,14,178,1,44,
189,14,182,4,0,
189,14,202,1,59,
189,14,202,3,0,
189,14,207,4,0,
189,14,213,4,0,
189,14,214,3,0,
189,14,216,4,0,
189,14,218,4,0,
189,14,235,1,1,2
189,14,235,1,4,
189,14,235,3,0,
189,14,237,4,0,
189,14,241,4,0,
189,14,244,4,0,
189,14,262,1,69,
189,14,263,4,0,
189,14,270,3,0,
189,14,331,1,20,
189,14,332,4,0,
189,14,340,1,64,
189,14,340,3,0,
189,14,369,1,49,
189,14,369,4,0,
189,14,388,1,54,
189,14,388,3,0,
189,14,402,3,0,
189,14,412,4,0,
189,14,416,4,0,
189,14,447,4,0,
189,14,476,1,39,
189,14,496,4,0,
189,14,512,1,34,
189,14,512,4,0,
189,15,14,4,0,
189,15,33,1,1,4
189,15,33,1,8,
189,15,39,1,1,3
189,15,39,1,6,
189,15,63,4,0,
189,15,72,1,29,
189,15,73,1,24,
189,15,76,4,0,
189,15,77,1,12,
189,15,78,1,14,
189,15,79,1,16,
189,15,92,4,0,
189,15,104,4,0,
189,15,115,4,0,
189,15,148,4,0,
189,15,150,1,1,1
189,15,156,4,0,
189,15,164,4,0,
189,15,178,1,44,
189,15,182,4,0,
189,15,202,1,59,
189,15,207,4,0,
189,15,213,4,0,
189,15,214,4,0,
189,15,216,4,0,
189,15,218,4,0,
189,15,235,1,1,2
189,15,235,1,4,
189,15,237,4,0,
189,15,241,4,0,
189,15,244,4,0,
189,15,262,1,69,
189,15,263,4,0,
189,15,331,1,20,
189,15,332,4,0,
189,15,340,1,64,
189,15,369,1,49,
189,15,369,4,0,
189,15,388,1,54,
189,15,412,4,0,
189,15,416,4,0,
189,15,447,4,0,
189,15,476,1,39,
189,15,496,4,0,
189,15,512,1,34,
189,15,512,4,0,
189,15,584,1,10,
189,15,590,4,0,
189,15,605,4,0,
189,15,611,4,0,
189,16,14,4,0,
189,16,33,1,1,4
189,16,33,1,8,1
189,16,39,1,1,3
189,16,39,1,6,1
189,16,63,4,0,
189,16,72,1,29,1
189,16,73,1,24,1
189,16,76,4,0,
189,16,77,1,12,1
189,16,78,1,14,1
189,16,79,1,16,1
189,16,92,4,0,
189,16,104,4,0,
189,16,115,4,0,
189,16,148,4,0,
189,16,150,1,1,1
189,16,156,4,0,
189,16,164,4,0,
189,16,173,3,0,
189,16,178,1,44,1
189,16,182,4,0,
189,16,202,1,59,1
189,16,202,3,0,
189,16,207,4,0,
189,16,213,4,0,
189,16,214,4,0,
189,16,216,4,0,
189,16,218,4,0,
189,16,235,1,1,2
189,16,235,1,4,1
189,16,235,3,0,
189,16,237,4,0,
189,16,241,4,0,
189,16,244,4,0,
189,16,262,1,69,1
189,16,263,4,0,
189,16,270,3,0,
189,16,290,4,0,
189,16,331,1,20,1
189,16,332,4,0,
189,16,340,1,64,1
189,16,340,3,0,
189,16,369,1,49,1
189,16,369,4,0,
189,16,388,1,54,1
189,16,388,3,0,
189,16,402,3,0,
189,16,412,4,0,
189,16,416,4,0,
189,16,447,4,0,
189,16,476,1,39,1
189,16,496,4,0,
189,16,512,1,34,1
189,16,512,4,0,
189,16,584,1,10,1
189,16,590,4,0,
189,16,605,4,0,
189,16,611,4,0,
189,17,14,4,0,
189,17,33,1,1,4
189,17,33,1,8,
189,17,39,1,1,3
189,17,39,1,6,
189,17,63,4,0,
189,17,72,1,29,
189,17,73,1,24,
189,17,76,4,0,
189,17,77,1,12,
189,17,78,1,14,
189,17,79,1,16,
189,17,92,4,0,
189,17,104,4,0,
189,17,115,4,0,
189,17,150,1,1,1
189,17,156,4,0,
189,17,164,4,0,
189,17,178,1,44,
189,17,182,4,0,
189,17,202,1,59,
189,17,207,4,0,
189,17,213,4,0,
189,17,214,4,0,
189,17,216,4,0,
189,17,218,4,0,
189,17,235,1,1,2
189,17,235,1,4,
189,17,237,4,0,
189,17,241,4,0,
189,17,244,4,0,
189,17,262,1,69,
189,17,263,4,0,
189,17,331,1,20,
189,17,332,4,0,
189,17,340,1,64,
189,17,369,1,49,
189,17,369,4,0,
189,17,388,1,54,
189,17,412,4,0,
189,17,416,4,0,
189,17,447,4,0,
189,17,476,1,39,
189,17,496,4,0,
189,17,512,1,34,
189,17,512,4,0,
189,17,584,1,10,
189,17,590,4,0,
189,17,605,4,0,
189,17,611,4,0,
189,18,14,4,0,
189,18,33,1,8,
189,18,39,1,1,4
189,18,39,1,6,
189,18,63,4,0,
189,18,71,1,1,2
189,18,72,1,29,
189,18,73,1,24,
189,18,76,4,0,
189,18,77,1,12,
189,18,78,1,14,
189,18,79,1,16,
189,18,92,4,0,
189,18,104,4,0,
189,18,115,4,0,
189,18,150,1,1,1
189,18,156,4,0,
189,18,164,4,0,
189,18,178,1,44,
189,18,182,4,0,
189,18,202,1,59,
189,18,207,4,0,
189,18,213,4,0,
189,18,214,4,0,
189,18,216,4,0,
189,18,218,4,0,
189,18,235,1,1,3
189,18,235,1,4,
189,18,237,4,0,
189,18,241,4,0,
189,18,244,4,0,
189,18,262,1,69,
189,18,263,4,0,
189,18,331,1,20,
189,18,332,4,0,
189,18,340,1,64,
189,18,369,1,49,
189,18,369,4,0,
189,18,388,1,54,
189,18,412,4,0,
189,18,416,4,0,
189,18,447,4,0,
189,18,476,1,39,
189,18,496,4,0,
189,18,512,1,34,
189,18,512,4,0,
189,18,584,1,10,
189,18,590,4,0,
189,18,605,4,0,
189,18,611,4,0,
190,3,3,2,0,
190,3,7,4,0,
190,3,8,4,0,
190,3,9,4,0,
190,3,10,1,1,1
190,3,15,4,0,
190,3,21,2,0,
190,3,28,1,6,
190,3,29,4,0,
190,3,39,1,1,2
190,3,68,2,0,
190,3,70,4,0,
190,3,87,4,0,
190,3,92,4,0,
190,3,97,1,46,
190,3,97,2,0,
190,3,103,1,36,
190,3,103,2,0,
190,3,104,4,0,
190,3,111,4,0,
190,3,129,1,27,
190,3,129,4,0,
190,3,138,4,0,
190,3,154,1,19,
190,3,156,4,0,
190,3,168,4,0,
190,3,171,4,0,
190,3,173,4,0,
190,3,174,4,0,
190,3,180,2,0,
190,3,182,4,0,
190,3,189,4,0,
190,3,192,4,0,
190,3,197,4,0,
190,3,203,4,0,
190,3,207,4,0,
190,3,210,4,0,
190,3,213,4,0,
190,3,214,4,0,
190,3,216,4,0,
190,3,218,4,0,
190,3,223,4,0,
190,3,226,1,12,
190,3,228,2,0,
190,3,231,4,0,
190,3,237,4,0,
190,3,241,4,0,
190,3,247,4,0,
190,3,249,4,0,
190,3,251,2,0,
190,4,3,2,0,
190,4,7,4,0,
190,4,8,4,0,
190,4,9,4,0,
190,4,10,1,1,1
190,4,15,4,0,
190,4,21,2,0,
190,4,28,1,6,
190,4,29,4,0,
190,4,39,1,1,2
190,4,68,2,0,
190,4,70,4,0,
190,4,85,3,0,
190,4,87,4,0,
190,4,92,4,0,
190,4,97,1,46,
190,4,97,2,0,
190,4,103,1,36,
190,4,103,2,0,
190,4,104,4,0,
190,4,111,4,0,
190,4,129,1,27,
190,4,129,4,0,
190,4,138,4,0,
190,4,154,1,19,
190,4,156,4,0,
190,4,168,4,0,
190,4,171,4,0,
190,4,173,4,0,
190,4,174,4,0,
190,4,180,2,0,
190,4,182,4,0,
190,4,189,4,0,
190,4,192,4,0,
190,4,197,4,0,
190,4,203,4,0,
190,4,207,4,0,
190,4,210,4,0,
190,4,213,4,0,
190,4,214,4,0,
190,4,216,4,0,
190,4,218,4,0,
190,4,223,4,0,
190,4,226,1,12,
190,4,228,2,0,
190,4,231,4,0,
190,4,237,4,0,
190,4,241,4,0,
190,4,247,4,0,
190,4,249,4,0,
190,4,251,2,0,
190,5,3,2,0,
190,5,10,1,1,1
190,5,15,4,0,
190,5,21,2,0,
190,5,28,1,6,
190,5,39,1,1,2
190,5,68,2,0,
190,5,70,4,0,
190,5,76,4,0,
190,5,85,4,0,
190,5,87,4,0,
190,5,91,4,0,
190,5,92,4,0,
190,5,97,1,50,
190,5,97,2,0,
190,5,103,1,43,
190,5,103,2,0,
190,5,104,4,0,
190,5,129,1,38,
190,5,154,1,31,
190,5,156,4,0,
190,5,168,4,0,
190,5,180,2,0,
190,5,182,4,0,
190,5,213,4,0,
190,5,216,4,0,
190,5,218,4,0,
190,5,226,1,18,
190,5,228,2,0,
190,5,231,4,0,
190,5,237,4,0,
190,5,240,4,0,
190,5,241,4,0,
190,5,247,4,0,
190,5,249,4,0,
190,5,251,2,0,
190,5,263,4,0,
190,5,264,4,0,
190,5,269,4,0,
190,5,280,4,0,
190,5,289,4,0,
190,5,290,4,0,
190,5,310,1,13,
190,5,321,1,25,
190,5,332,4,0,
190,5,351,4,0,
190,5,352,4,0,
190,6,3,2,0,
190,6,5,3,0,
190,6,7,3,0,
190,6,8,3,0,
190,6,9,3,0,
190,6,10,1,1,1
190,6,15,4,0,
190,6,21,2,0,
190,6,25,3,0,
190,6,28,1,6,
190,6,34,3,0,
190,6,38,3,0,
190,6,39,1,1,2
190,6,68,2,0,
190,6,68,3,0,
190,6,69,3,0,
190,6,70,4,0,
190,6,76,4,0,
190,6,85,4,0,
190,6,86,3,0,
190,6,87,4,0,
190,6,91,4,0,
190,6,92,4,0,
190,6,97,1,50,
190,6,97,2,0,
190,6,102,3,0,
190,6,103,1,43,
190,6,103,2,0,
190,6,104,4,0,
190,6,111,3,0,
190,6,118,3,0,
190,6,129,1,38,
190,6,129,3,0,
190,6,138,3,0,
190,6,154,1,31,
190,6,156,4,0,
190,6,164,3,0,
190,6,168,4,0,
190,6,173,3,0,
190,6,180,2,0,
190,6,182,4,0,
190,6,189,3,0,
190,6,203,3,0,
190,6,207,3,0,
190,6,210,3,0,
190,6,213,4,0,
190,6,214,3,0,
190,6,216,4,0,
190,6,218,4,0,
190,6,223,3,0,
190,6,226,1,18,
190,6,228,2,0,
190,6,231,4,0,
190,6,237,4,0,
190,6,240,4,0,
190,6,241,4,0,
190,6,247,4,0,
190,6,249,4,0,
190,6,251,2,0,
190,6,263,4,0,
190,6,264,4,0,
190,6,269,4,0,
190,6,280,4,0,
190,6,289,4,0,
190,6,290,4,0,
190,6,310,1,13,
190,6,321,1,25,
190,6,332,4,0,
190,6,351,4,0,
190,6,352,4,0,
190,7,3,2,0,
190,7,5,3,0,
190,7,10,1,1,1
190,7,15,4,0,
190,7,21,2,0,
190,7,25,3,0,
190,7,28,1,6,
190,7,34,3,0,
190,7,38,3,0,
190,7,39,1,1,2
190,7,68,2,0,
190,7,68,3,0,
190,7,69,3,0,
190,7,70,4,0,
190,7,76,4,0,
190,7,85,4,0,
190,7,86,3,0,
190,7,87,4,0,
190,7,91,4,0,
190,7,92,4,0,
190,7,97,1,50,
190,7,97,2,0,
190,7,102,3,0,
190,7,103,1,43,
190,7,103,2,0,
190,7,104,4,0,
190,7,118,3,0,
190,7,129,1,38,
190,7,138,3,0,
190,7,154,1,31,
190,7,156,4,0,
190,7,164,3,0,
190,7,168,4,0,
190,7,180,2,0,
190,7,182,4,0,
190,7,213,4,0,
190,7,216,4,0,
190,7,218,4,0,
190,7,226,1,18,
190,7,228,2,0,
190,7,231,4,0,
190,7,237,4,0,
190,7,240,4,0,
190,7,241,4,0,
190,7,247,4,0,
190,7,249,4,0,
190,7,251,2,0,
190,7,263,4,0,
190,7,264,4,0,
190,7,269,4,0,
190,7,280,4,0,
190,7,289,4,0,
190,7,290,4,0,
190,7,310,1,13,
190,7,321,1,25,
190,7,332,4,0,
190,7,351,4,0,
190,7,352,4,0,
190,8,3,2,0,
190,8,10,1,1,1
190,8,15,4,0,
190,8,21,2,0,
190,8,28,1,4,
190,8,39,1,1,2
190,8,68,2,0,
190,8,70,4,0,
190,8,76,4,0,
190,8,85,4,0,
190,8,86,4,0,
190,8,87,4,0,
190,8,91,4,0,
190,8,92,4,0,
190,8,97,1,29,
190,8,97,2,0,
190,8,103,1,25,
190,8,103,2,0,
190,8,104,4,0,
190,8,129,1,22,
190,8,138,4,0,
190,8,154,1,18,
190,8,156,4,0,
190,8,164,4,0,
190,8,168,4,0,
190,8,180,2,0,
190,8,182,4,0,
190,8,203,4,0,
190,8,207,4,0,
190,8,213,4,0,
190,8,214,4,0,
190,8,216,4,0,
190,8,218,4,0,
190,8,226,1,11,
190,8,228,2,0,
190,8,231,4,0,
190,8,237,4,0,
190,8,240,4,0,
190,8,241,4,0,
190,8,247,4,0,
190,8,249,4,0,
190,8,251,2,0,
190,8,252,2,0,
190,8,263,4,0,
190,8,264,4,0,
190,8,269,4,0,
190,8,280,4,0,
190,8,289,4,0,
190,8,290,4,0,
190,8,310,1,8,
190,8,321,1,15,
190,8,332,4,0,
190,8,340,2,0,
190,8,343,2,0,
190,8,351,4,0,
190,8,352,4,0,
190,8,363,4,0,
190,8,369,4,0,
190,8,371,4,0,
190,8,374,1,36,
190,8,374,4,0,
190,8,387,1,43,
190,8,417,1,39,
190,8,421,4,0,
190,8,445,4,0,
190,8,447,4,0,
190,8,458,1,32,
190,9,3,2,0,
190,9,7,3,0,
190,9,8,3,0,
190,9,9,3,0,
190,9,10,1,1,1
190,9,15,4,0,
190,9,21,2,0,
190,9,28,1,4,
190,9,39,1,1,2
190,9,68,2,0,
190,9,70,4,0,
190,9,76,4,0,
190,9,85,4,0,
190,9,86,4,0,
190,9,87,4,0,
190,9,91,4,0,
190,9,92,4,0,
190,9,97,1,29,
190,9,97,2,0,
190,9,103,1,25,
190,9,103,2,0,
190,9,104,4,0,
190,9,129,1,22,
190,9,129,3,0,
190,9,138,4,0,
190,9,154,1,18,
190,9,156,4,0,
190,9,164,4,0,
190,9,168,4,0,
190,9,173,3,0,
190,9,180,2,0,
190,9,180,3,0,
190,9,182,4,0,
190,9,189,3,0,
190,9,203,4,0,
190,9,207,4,0,
190,9,210,3,0,
190,9,213,4,0,
190,9,214,4,0,
190,9,216,4,0,
190,9,218,4,0,
190,9,226,1,11,
190,9,228,2,0,
190,9,231,4,0,
190,9,237,4,0,
190,9,240,4,0,
190,9,241,4,0,
190,9,247,4,0,
190,9,249,4,0,
190,9,251,2,0,
190,9,252,2,0,
190,9,253,3,0,
190,9,263,4,0,
190,9,264,4,0,
190,9,269,4,0,
190,9,280,4,0,
190,9,282,3,0,
190,9,289,4,0,
190,9,290,4,0,
190,9,310,1,8,
190,9,321,1,15,
190,9,332,4,0,
190,9,340,2,0,
190,9,340,3,0,
190,9,343,2,0,
190,9,351,4,0,
190,9,352,4,0,
190,9,363,4,0,
190,9,369,4,0,
190,9,371,4,0,
190,9,374,1,36,
190,9,374,4,0,
190,9,387,1,43,
190,9,387,3,0,
190,9,402,3,0,
190,9,417,1,39,
190,9,421,4,0,
190,9,441,3,0,
190,9,445,4,0,
190,9,447,4,0,
190,9,458,1,32,
190,10,3,2,0,
190,10,7,3,0,
190,10,8,3,0,
190,10,9,3,0,
190,10,10,1,1,1
190,10,15,4,0,
190,10,21,2,0,
190,10,28,1,4,
190,10,29,3,0,
190,10,39,1,1,2
190,10,67,3,0,
190,10,68,2,0,
190,10,70,4,0,
190,10,76,4,0,
190,10,85,4,0,
190,10,86,4,0,
190,10,87,4,0,
190,10,91,4,0,
190,10,92,4,0,
190,10,97,1,29,
190,10,97,2,0,
190,10,103,1,25,
190,10,103,2,0,
190,10,104,4,0,
190,10,129,1,22,
190,10,129,3,0,
190,10,138,4,0,
190,10,154,1,18,
190,10,156,4,0,
190,10,164,4,0,
190,10,168,4,0,
190,10,173,3,0,
190,10,180,2,0,
190,10,180,3,0,
190,10,182,4,0,
190,10,189,3,0,
190,10,203,4,0,
190,10,207,4,0,
190,10,210,3,0,
190,10,213,4,0,
190,10,214,4,0,
190,10,216,4,0,
190,10,218,4,0,
190,10,226,1,11,
190,10,228,2,0,
190,10,231,4,0,
190,10,237,4,0,
190,10,240,4,0,
190,10,241,4,0,
190,10,247,4,0,
190,10,249,4,0,
190,10,251,2,0,
190,10,252,2,0,
190,10,253,3,0,
190,10,263,4,0,
190,10,264,4,0,
190,10,269,4,0,
190,10,272,3,0,
190,10,280,4,0,
190,10,282,3,0,
190,10,289,4,0,
190,10,290,4,0,
190,10,310,1,8,
190,10,321,1,15,
190,10,332,4,0,
190,10,340,2,0,
190,10,340,3,0,
190,10,343,2,0,
190,10,351,4,0,
190,10,352,4,0,
190,10,363,4,0,
190,10,369,4,0,
190,10,371,4,0,
190,10,374,1,36,
190,10,374,4,0,
190,10,387,1,43,
190,10,387,3,0,
190,10,402,3,0,
190,10,417,1,39,
190,10,421,4,0,
190,10,441,3,0,
190,10,445,4,0,
190,10,447,4,0,
190,10,458,1,32,
190,11,3,2,0,
190,11,10,1,1,1
190,11,15,4,0,
190,11,21,2,0,
190,11,28,1,4,
190,11,39,1,1,2
190,11,68,2,0,
190,11,70,4,0,
190,11,76,4,0,
190,11,85,4,0,
190,11,86,4,0,
190,11,87,4,0,
190,11,91,4,0,
190,11,92,4,0,
190,11,97,1,29,
190,11,97,2,0,
190,11,103,1,25,
190,11,103,2,0,
190,11,104,4,0,
190,11,129,1,22,
190,11,138,4,0,
190,11,154,1,18,
190,11,156,4,0,
190,11,164,4,0,
190,11,168,4,0,
190,11,180,2,0,
190,11,182,4,0,
190,11,207,4,0,
190,11,213,4,0,
190,11,216,4,0,
190,11,218,4,0,
190,11,226,1,11,
190,11,228,2,0,
190,11,237,4,0,
190,11,240,4,0,
190,11,241,4,0,
190,11,247,4,0,
190,11,249,4,0,
190,11,251,2,0,
190,11,252,2,0,
190,11,263,4,0,
190,11,269,4,0,
190,11,279,2,0,
190,11,280,4,0,
190,11,310,1,8,
190,11,321,1,15,
190,11,332,4,0,
190,11,340,2,0,
190,11,343,2,0,
190,11,369,4,0,
190,11,371,4,0,
190,11,374,1,36,
190,11,374,4,0,
190,11,387,1,43,
190,11,415,2,0,
190,11,417,1,39,
190,11,421,4,0,
190,11,447,4,0,
190,11,458,1,32,
190,11,468,4,0,
190,11,490,4,0,
190,11,496,4,0,
190,11,512,4,0,
190,11,514,4,0,
190,11,526,4,0,
190,12,10,1,1,1
190,12,15,4,0,
190,12,28,1,6,
190,12,39,1,1,2
190,12,70,4,0,
190,12,76,4,0,
190,12,85,4,0,
190,12,87,4,0,
190,12,91,4,0,
190,12,92,4,0,
190,12,97,1,50,
190,12,103,1,43,
190,12,104,4,0,
190,12,129,1,38,
190,12,154,1,31,
190,12,156,4,0,
190,12,168,4,0,
190,12,182,4,0,
190,12,213,4,0,
190,12,216,4,0,
190,12,218,4,0,
190,12,226,1,18,
190,12,231,4,0,
190,12,237,4,0,
190,12,240,4,0,
190,12,241,4,0,
190,12,247,4,0,
190,12,249,4,0,
190,12,263,4,0,
190,12,264,4,0,
190,12,269,4,0,
190,12,280,4,0,
190,12,289,4,0,
190,12,290,4,0,
190,12,310,1,13,
190,12,321,1,25,
190,12,332,4,0,
190,12,351,4,0,
190,12,352,4,0,
190,13,10,1,1,1
190,13,15,4,0,
190,13,28,1,6,
190,13,34,3,0,
190,13,38,3,0,
190,13,39,1,1,2
190,13,69,3,0,
190,13,70,4,0,
190,13,76,4,0,
190,13,85,4,0,
190,13,86,3,0,
190,13,87,4,0,
190,13,91,4,0,
190,13,92,4,0,
190,13,97,1,50,
190,13,102,3,0,
190,13,103,1,43,
190,13,104,4,0,
190,13,129,1,38,
190,13,138,3,0,
190,13,154,1,31,
190,13,156,4,0,
190,13,164,3,0,
190,13,168,4,0,
190,13,171,3,0,
190,13,182,4,0,
190,13,207,3,0,
190,13,213,4,0,
190,13,216,4,0,
190,13,218,4,0,
190,13,226,1,18,
190,13,231,4,0,
190,13,237,4,0,
190,13,240,4,0,
190,13,241,4,0,
190,13,247,4,0,
190,13,249,4,0,
190,13,263,4,0,
190,13,264,4,0,
190,13,269,4,0,
190,13,280,4,0,
190,13,289,4,0,
190,13,290,4,0,
190,13,310,1,13,
190,13,321,1,25,
190,13,332,4,0,
190,13,351,4,0,
190,13,352,4,0,
190,14,3,2,0,
190,14,7,3,0,
190,14,8,3,0,
190,14,9,3,0,
190,14,10,1,1,1
190,14,15,4,0,
190,14,21,2,0,
190,14,28,1,4,
190,14,39,1,1,2
190,14,67,3,0,
190,14,68,2,0,
190,14,70,4,0,
190,14,76,4,0,
190,14,85,4,0,
190,14,86,4,0,
190,14,87,4,0,
190,14,91,4,0,
190,14,92,4,0,
190,14,97,1,29,
190,14,97,2,0,
190,14,103,1,25,
190,14,103,2,0,
190,14,104,4,0,
190,14,129,1,22,
190,14,138,4,0,
190,14,154,1,18,
190,14,156,4,0,
190,14,164,4,0,
190,14,168,4,0,
190,14,173,3,0,
190,14,180,2,0,
190,14,180,3,0,
190,14,182,4,0,
190,14,207,4,0,
190,14,213,4,0,
190,14,214,3,0,
190,14,216,4,0,
190,14,218,4,0,
190,14,226,1,11,
190,14,228,2,0,
190,14,231,3,0,
190,14,237,4,0,
190,14,240,4,0,
190,14,241,4,0,
190,14,247,4,0,
190,14,249,4,0,
190,14,251,2,0,
190,14,252,2,0,
190,14,253,3,0,
190,14,263,4,0,
190,14,269,4,0,
190,14,272,3,0,
190,14,279,2,0,
190,14,280,4,0,
190,14,282,3,0,
190,14,289,3,0,
190,14,310,1,8,
190,14,321,1,15,
190,14,332,4,0,
190,14,340,2,0,
190,14,340,3,0,
190,14,343,2,0,
190,14,343,3,0,
190,14,369,4,0,
190,14,371,4,0,
190,14,374,1,36,
190,14,374,4,0,
190,14,387,1,43,
190,14,387,3,0,
190,14,402,3,0,
190,14,415,2,0,
190,14,417,1,39,
190,14,421,4,0,
190,14,441,3,0,
190,14,447,4,0,
190,14,458,1,32,
190,14,468,4,0,
190,14,490,4,0,
190,14,492,3,0,
190,14,496,4,0,
190,14,512,4,0,
190,14,514,4,0,
190,14,526,4,0,
190,15,3,2,0,
190,15,10,1,1,1
190,15,15,4,0,
190,15,21,2,0,
190,15,28,1,4,
190,15,39,1,1,2
190,15,68,2,0,
190,15,70,4,0,
190,15,76,4,0,
190,15,85,4,0,
190,15,86,4,0,
190,15,87,4,0,
190,15,91,4,0,
190,15,92,4,0,
190,15,97,1,29,
190,15,97,2,0,
190,15,103,1,25,
190,15,103,2,0,
190,15,104,4,0,
190,15,129,1,22,
190,15,138,4,0,
190,15,154,1,18,
190,15,156,4,0,
190,15,164,4,0,
190,15,168,4,0,
190,15,180,2,0,
190,15,182,4,0,
190,15,207,4,0,
190,15,213,4,0,
190,15,214,4,0,
190,15,216,4,0,
190,15,218,4,0,
190,15,226,1,11,
190,15,228,2,0,
190,15,237,4,0,
190,15,240,4,0,
190,15,241,4,0,
190,15,247,4,0,
190,15,249,4,0,
190,15,251,2,0,
190,15,252,2,0,
190,15,263,4,0,
190,15,269,4,0,
190,15,279,2,0,
190,15,280,4,0,
190,15,310,1,8,
190,15,321,1,15,
190,15,332,4,0,
190,15,340,2,0,
190,15,343,2,0,
190,15,369,4,0,
190,15,371,4,0,
190,15,374,1,36,
190,15,374,4,0,
190,15,387,1,43,
190,15,415,2,0,
190,15,417,1,39,
190,15,421,4,0,
190,15,447,4,0,
190,15,458,1,32,
190,15,468,4,0,
190,15,490,4,0,
190,15,496,4,0,
190,15,501,2,0,
190,15,512,4,0,
190,15,514,4,0,
190,15,590,4,0,
190,15,612,4,0,
190,16,3,2,0,
190,16,7,3,0,
190,16,8,3,0,
190,16,9,3,0,
190,16,10,1,1,1
190,16,15,4,0,
190,16,21,2,0,
190,16,28,1,4,1
190,16,39,1,1,2
190,16,67,3,0,
190,16,68,2,0,
190,16,70,4,0,
190,16,76,4,0,
190,16,85,4,0,
190,16,86,4,0,
190,16,87,4,0,
190,16,91,4,0,
190,16,92,4,0,
190,16,97,1,29,1
190,16,97,2,0,
190,16,103,1,25,1
190,16,103,2,0,
190,16,104,4,0,
190,16,129,1,22,1
190,16,138,4,0,
190,16,154,1,18,1
190,16,156,4,0,
190,16,164,4,0,
190,16,168,4,0,
190,16,173,3,0,
190,16,180,2,0,
190,16,180,3,0,
190,16,182,4,0,
190,16,207,4,0,
190,16,213,4,0,
190,16,214,4,0,
190,16,216,4,0,
190,16,218,4,0,
190,16,226,1,11,1
190,16,228,2,0,
190,16,231,3,0,
190,16,237,4,0,
190,16,240,4,0,
190,16,241,4,0,
190,16,247,4,0,
190,16,249,4,0,
190,16,251,2,0,
190,16,252,2,0,
190,16,253,3,0,
190,16,263,4,0,
190,16,264,3,0,
190,16,269,4,0,
190,16,272,3,0,
190,16,279,2,0,
190,16,280,4,0,
190,16,282,3,0,
190,16,289,3,0,
190,16,290,4,0,
190,16,310,1,8,1
190,16,321,1,15,1
190,16,332,4,0,
190,16,340,2,0,
190,16,340,3,0,
190,16,343,2,0,
190,16,343,3,0,
190,16,351,3,0,
190,16,352,3,0,
190,16,369,4,0,
190,16,371,4,0,
190,16,374,1,36,1
190,16,374,4,0,
190,16,387,1,43,1
190,16,387,3,0,
190,16,402,3,0,
190,16,415,2,0,
190,16,417,1,39,1
190,16,421,4,0,
190,16,441,3,0,
190,16,447,4,0,
190,16,458,1,32,1
190,16,468,4,0,
190,16,490,4,0,
190,16,492,3,0,
190,16,496,4,0,
190,16,501,2,0,
190,16,512,4,0,
190,16,514,4,0,
190,16,590,4,0,
190,16,612,4,0,
190,17,3,2,0,
190,17,10,1,1,1
190,17,21,2,0,
190,17,28,1,4,
190,17,39,1,1,2
190,17,68,2,0,
190,17,76,4,0,
190,17,85,4,0,
190,17,86,4,0,
190,17,87,4,0,
190,17,92,4,0,
190,17,97,1,29,
190,17,97,2,0,
190,17,103,1,25,
190,17,103,2,0,
190,17,104,4,0,
190,17,129,1,22,
190,17,138,4,0,
190,17,154,1,18,
190,17,156,4,0,
190,17,164,4,0,
190,17,168,4,0,
190,17,180,2,0,
190,17,182,4,0,
190,17,207,4,0,
190,17,213,4,0,
190,17,214,4,0,
190,17,216,4,0,
190,17,218,4,0,
190,17,226,1,11,
190,17,228,2,0,
190,17,237,4,0,
190,17,240,4,0,
190,17,241,4,0,
190,17,247,4,0,
190,17,251,2,0,
190,17,252,2,0,
190,17,263,4,0,
190,17,269,4,0,
190,17,279,2,0,
190,17,280,4,0,
190,17,310,1,8,
190,17,321,1,15,
190,17,332,4,0,
190,17,340,2,0,
190,17,343,2,0,
190,17,369,4,0,
190,17,371,4,0,
190,17,374,1,36,
190,17,374,4,0,
190,17,387,1,43,
190,17,415,2,0,
190,17,417,1,39,
190,17,421,4,0,
190,17,447,4,0,
190,17,458,1,32,
190,17,490,4,0,
190,17,496,4,0,
190,17,501,2,0,
190,17,512,4,0,
190,17,526,4,0,
190,17,590,4,0,
190,18,3,2,0,
190,18,10,1,1,1
190,18,21,2,0,
190,18,28,1,4,
190,18,39,1,1,2
190,18,68,2,0,
190,18,76,4,0,
190,18,85,4,0,
190,18,86,4,0,
190,18,87,4,0,
190,18,92,4,0,
190,18,97,1,29,
190,18,97,2,0,
190,18,103,1,25,
190,18,103,2,0,
190,18,104,4,0,
190,18,129,1,22,
190,18,138,4,0,
190,18,154,1,18,
190,18,156,4,0,
190,18,164,4,0,
190,18,168,4,0,
190,18,180,2,0,
190,18,182,4,0,
190,18,207,4,0,
190,18,213,4,0,
190,18,214,4,0,
190,18,216,4,0,
190,18,218,4,0,
190,18,226,1,11,
190,18,228,2,0,
190,18,237,4,0,
190,18,240,4,0,
190,18,241,4,0,
190,18,247,4,0,
190,18,251,2,0,
190,18,252,2,0,
190,18,263,4,0,
190,18,269,4,0,
190,18,279,2,0,
190,18,280,4,0,
190,18,310,1,8,
190,18,321,1,15,
190,18,332,4,0,
190,18,340,2,0,
190,18,343,2,0,
190,18,369,4,0,
190,18,371,4,0,
190,18,374,1,36,
190,18,374,4,0,
190,18,387,1,43,
190,18,415,2,0,
190,18,417,1,39,
190,18,421,4,0,
190,18,447,4,0,
190,18,458,1,32,
190,18,490,4,0,
190,18,496,4,0,
190,18,501,2,0,
190,18,512,4,0,
190,18,526,4,0,
190,18,541,2,0,
190,18,590,4,0,
191,3,15,4,0,
191,3,71,1,1,
191,3,72,1,10,
191,3,74,1,4,
191,3,76,4,0,
191,3,92,4,0,
191,3,104,4,0,
191,3,148,4,0,
191,3,156,4,0,
191,3,173,4,0,
191,3,174,4,0,
191,3,182,4,0,
191,3,188,4,0,
191,3,202,1,46,
191,3,202,4,0,
191,3,203,4,0,
191,3,207,4,0,
191,3,213,4,0,
191,3,214,4,0,
191,3,216,4,0,
191,3,218,4,0,
191,3,230,4,0,
191,3,235,1,31,
191,3,237,4,0,
191,3,241,1,19,
191,3,241,4,0,
191,4,15,4,0,
191,4,71,1,1,
191,4,72,1,10,
191,4,74,1,4,
191,4,76,4,0,
191,4,92,4,0,
191,4,104,4,0,
191,4,148,4,0,
191,4,156,4,0,
191,4,173,4,0,
191,4,174,4,0,
191,4,182,4,0,
191,4,188,4,0,
191,4,202,1,46,
191,4,202,4,0,
191,4,203,4,0,
191,4,207,4,0,
191,4,213,4,0,
191,4,214,4,0,
191,4,216,4,0,
191,4,218,4,0,
191,4,230,4,0,
191,4,235,1,31,
191,4,237,4,0,
191,4,241,1,19,
191,4,241,4,0,
191,5,15,4,0,
191,5,71,1,1,
191,5,72,1,13,
191,5,73,2,0,
191,5,74,1,6,
191,5,76,4,0,
191,5,92,4,0,
191,5,104,4,0,
191,5,113,4,0,
191,5,148,4,0,
191,5,156,4,0,
191,5,174,2,0,
191,5,182,4,0,
191,5,188,4,0,
191,5,202,1,42,
191,5,202,4,0,
191,5,213,4,0,
191,5,216,4,0,
191,5,218,4,0,
191,5,219,4,0,
191,5,227,2,0,
191,5,235,1,37,
191,5,237,4,0,
191,5,241,1,30,
191,5,241,4,0,
191,5,263,4,0,
191,5,267,2,0,
191,5,270,2,0,
191,5,275,1,18,
191,5,283,1,25,
191,5,290,4,0,
191,5,320,2,0,
191,5,331,4,0,
191,6,14,3,0,
191,6,15,4,0,
191,6,38,3,0,
191,6,71,1,1,
191,6,72,1,13,
191,6,73,2,0,
191,6,74,1,6,
191,6,76,4,0,
191,6,92,4,0,
191,6,102,3,0,
191,6,104,4,0,
191,6,113,4,0,
191,6,148,4,0,
191,6,156,4,0,
191,6,164,3,0,
191,6,173,3,0,
191,6,174,2,0,
191,6,182,4,0,
191,6,188,4,0,
191,6,202,1,42,
191,6,202,4,0,
191,6,203,3,0,
191,6,207,3,0,
191,6,213,4,0,
191,6,214,3,0,
191,6,216,4,0,
191,6,218,4,0,
191,6,219,4,0,
191,6,227,2,0,
191,6,235,1,37,
191,6,237,4,0,
191,6,241,1,30,
191,6,241,4,0,
191,6,263,4,0,
191,6,267,2,0,
191,6,270,2,0,
191,6,275,1,18,
191,6,283,1,25,
191,6,290,4,0,
191,6,320,2,0,
191,6,331,4,0,
191,7,14,3,0,
191,7,15,4,0,
191,7,38,3,0,
191,7,71,1,1,
191,7,72,1,13,
191,7,73,2,0,
191,7,74,1,6,
191,7,76,4,0,
191,7,92,4,0,
191,7,102,3,0,
191,7,104,4,0,
191,7,113,4,0,
191,7,148,4,0,
191,7,156,4,0,
191,7,164,3,0,
191,7,174,2,0,
191,7,182,4,0,
191,7,188,4,0,
191,7,202,1,42,
191,7,202,4,0,
191,7,213,4,0,
191,7,216,4,0,
191,7,218,4,0,
191,7,219,4,0,
191,7,227,2,0,
191,7,235,1,37,
191,7,237,4,0,
191,7,241,1,30,
191,7,241,4,0,
191,7,263,4,0,
191,7,267,2,0,
191,7,270,2,0,
191,7,275,1,18,
191,7,283,1,25,
191,7,290,4,0,
191,7,320,2,0,
191,7,331,4,0,
191,8,14,4,0,
191,8,15,4,0,
191,8,71,1,1,1
191,8,72,1,5,
191,8,73,1,17,
191,8,73,2,0,
191,8,74,1,1,2
191,8,75,1,29,
191,8,76,4,0,
191,8,92,4,0,
191,8,104,4,0,
191,8,113,4,0,
191,8,148,4,0,
191,8,156,4,0,
191,8,164,4,0,
191,8,174,2,0,
191,8,182,4,0,
191,8,188,4,0,
191,8,202,1,41,
191,8,202,4,0,
191,8,203,4,0,
191,8,207,4,0,
191,8,213,4,0,
191,8,214,4,0,
191,8,216,4,0,
191,8,218,4,0,
191,8,219,4,0,
191,8,227,2,0,
191,8,230,2,0,
191,8,235,1,33,
191,8,237,4,0,
191,8,241,1,37,
191,8,241,4,0,
191,8,263,4,0,
191,8,267,2,0,
191,8,270,2,0,
191,8,275,1,9,
191,8,275,2,0,
191,8,283,1,21,
191,8,290,4,0,
191,8,320,1,13,
191,8,320,2,0,
191,8,331,4,0,
191,8,363,4,0,
191,8,388,1,25,
191,8,402,1,45,
191,8,412,4,0,
191,8,445,4,0,
191,8,447,4,0,
191,9,14,4,0,
191,9,15,4,0,
191,9,71,1,1,1
191,9,72,1,5,
191,9,73,1,17,
191,9,73,2,0,
191,9,74,1,1,2
191,9,75,1,29,
191,9,76,4,0,
191,9,92,4,0,
191,9,104,4,0,
191,9,113,4,0,
191,9,148,4,0,
191,9,156,4,0,
191,9,164,4,0,
191,9,173,3,0,
191,9,174,2,0,
191,9,182,4,0,
191,9,188,4,0,
191,9,202,1,41,
191,9,202,4,0,
191,9,203,4,0,
191,9,207,4,0,
191,9,213,4,0,
191,9,214,4,0,
191,9,216,4,0,
191,9,218,4,0,
191,9,219,4,0,
191,9,227,2,0,
191,9,230,2,0,
191,9,235,1,33,
191,9,235,3,0,
191,9,237,4,0,
191,9,241,1,37,
191,9,241,4,0,
191,9,253,3,0,
191,9,263,4,0,
191,9,267,2,0,
191,9,270,2,0,
191,9,270,3,0,
191,9,275,1,9,
191,9,275,2,0,
191,9,283,1,21,
191,9,283,3,0,
191,9,290,4,0,
191,9,320,1,13,
191,9,320,2,0,
191,9,331,4,0,
191,9,363,4,0,
191,9,388,1,25,
191,9,402,1,45,
191,9,402,3,0,
191,9,412,4,0,
191,9,445,4,0,
191,9,447,4,0,
191,10,14,4,0,
191,10,15,4,0,
191,10,71,1,1,1
191,10,72,1,5,
191,10,73,1,17,
191,10,73,2,0,
191,10,74,1,1,2
191,10,75,1,29,
191,10,76,4,0,
191,10,92,4,0,
191,10,104,4,0,
191,10,113,4,0,
191,10,148,4,0,
191,10,156,4,0,
191,10,164,4,0,
191,10,173,3,0,
191,10,174,2,0,
191,10,182,4,0,
191,10,188,4,0,
191,10,202,1,41,
191,10,202,4,0,
191,10,203,4,0,
191,10,207,4,0,
191,10,213,4,0,
191,10,214,4,0,
191,10,216,4,0,
191,10,218,4,0,
191,10,219,4,0,
191,10,227,2,0,
191,10,230,2,0,
191,10,235,1,33,
191,10,235,3,0,
191,10,237,4,0,
191,10,241,1,37,
191,10,241,4,0,
191,10,253,3,0,
191,10,263,4,0,
191,10,267,2,0,
191,10,270,2,0,
191,10,270,3,0,
191,10,275,1,9,
191,10,275,2,0,
191,10,283,1,21,
191,10,283,3,0,
191,10,290,4,0,
191,10,320,1,13,
191,10,320,2,0,
191,10,331,4,0,
191,10,363,4,0,
191,10,388,1,25,
191,10,388,3,0,
191,10,402,1,45,
191,10,402,3,0,
191,10,412,4,0,
191,10,414,3,0,
191,10,445,4,0,
191,10,447,4,0,
191,11,14,4,0,
191,11,15,4,0,
191,11,71,1,1,1
191,11,72,1,5,
191,11,73,1,17,
191,11,73,2,0,
191,11,74,1,1,2
191,11,75,1,29,
191,11,76,4,0,
191,11,92,4,0,
191,11,104,4,0,
191,11,113,4,0,
191,11,117,2,0,
191,11,148,4,0,
191,11,156,4,0,
191,11,164,4,0,
191,11,174,2,0,
191,11,182,4,0,
191,11,188,4,0,
191,11,202,1,41,
191,11,203,2,0,
191,11,207,4,0,
191,11,213,4,0,
191,11,216,4,0,
191,11,218,4,0,
191,11,219,4,0,
191,11,227,2,0,
191,11,230,2,0,
191,11,234,2,0,
191,11,235,1,33,
191,11,237,4,0,
191,11,241,1,37,
191,11,241,4,0,
191,11,263,4,0,
191,11,267,2,0,
191,11,270,2,0,
191,11,275,1,9,
191,11,275,2,0,
191,11,283,1,21,
191,11,320,1,13,
191,11,320,2,0,
191,11,363,2,0,
191,11,388,1,25,
191,11,402,1,45,
191,11,412,4,0,
191,11,447,4,0,
191,11,496,4,0,
191,12,15,4,0,
191,12,71,1,1,
191,12,72,1,13,
191,12,74,1,6,
191,12,76,4,0,
191,12,92,4,0,
191,12,104,4,0,
191,12,113,4,0,
191,12,148,4,0,
191,12,156,4,0,
191,12,182,4,0,
191,12,188,4,0,
191,12,202,1,42,
191,12,202,4,0,
191,12,213,4,0,
191,12,216,4,0,
191,12,218,4,0,
191,12,219,4,0,
191,12,235,1,37,
191,12,237,4,0,
191,12,241,1,30,
191,12,241,4,0,
191,12,263,4,0,
191,12,275,1,18,
191,12,283,1,25,
191,12,290,4,0,
191,12,331,4,0,
191,13,15,4,0,
191,13,38,3,0,
191,13,71,1,1,
191,13,72,1,13,
191,13,74,1,6,
191,13,76,4,0,
191,13,92,4,0,
191,13,102,3,0,
191,13,104,4,0,
191,13,113,4,0,
191,13,148,4,0,
191,13,156,4,0,
191,13,164,3,0,
191,13,182,4,0,
191,13,188,4,0,
191,13,202,1,42,
191,13,202,4,0,
191,13,207,3,0,
191,13,213,4,0,
191,13,216,4,0,
191,13,218,4,0,
191,13,219,4,0,
191,13,235,1,37,
191,13,237,4,0,
191,13,241,1,30,
191,13,241,4,0,
191,13,263,4,0,
191,13,275,1,18,
191,13,283,1,25,
191,13,290,4,0,
191,13,331,4,0,
191,14,14,4,0,
191,14,15,4,0,
191,14,38,1,37,
191,14,71,1,1,1
191,14,72,1,10,
191,14,73,1,13,
191,14,73,2,0,
191,14,74,1,1,2
191,14,75,1,16,
191,14,76,1,34,
191,14,76,4,0,
191,14,92,4,0,
191,14,104,4,0,
191,14,113,4,0,
191,14,117,2,0,
191,14,148,4,0,
191,14,156,4,0,
191,14,164,4,0,
191,14,173,3,0,
191,14,174,2,0,
191,14,182,4,0,
191,14,188,4,0,
191,14,202,1,22,
191,14,202,3,0,
191,14,203,2,0,
191,14,207,4,0,
191,14,213,4,0,
191,14,214,3,0,
191,14,216,4,0,
191,14,218,4,0,
191,14,219,4,0,
191,14,227,2,0,
191,14,230,2,0,
191,14,234,2,0,
191,14,235,1,28,
191,14,235,3,0,
191,14,237,4,0,
191,14,241,1,40,
191,14,241,4,0,
191,14,253,3,0,
191,14,263,4,0,
191,14,267,2,0,
191,14,270,2,0,
191,14,270,3,0,
191,14,275,1,4,
191,14,275,2,0,
191,14,283,1,25,
191,14,283,3,0,
191,14,320,1,7,
191,14,320,2,0,
191,14,363,1,31,
191,14,363,2,0,
191,14,388,1,19,
191,14,388,3,0,
191,14,402,1,43,
191,14,402,3,0,
191,14,412,4,0,
191,14,414,3,0,
191,14,447,4,0,
191,14,495,3,0,
191,14,496,4,0,
191,15,14,4,0,
191,15,15,4,0,
191,15,38,1,37,
191,15,71,1,1,1
191,15,72,1,10,
191,15,73,1,13,
191,15,73,2,0,
191,15,74,1,1,2
191,15,75,1,16,
191,15,76,1,34,
191,15,76,4,0,
191,15,92,4,0,
191,15,104,4,0,
191,15,113,4,0,
191,15,117,2,0,
191,15,148,4,0,
191,15,156,4,0,
191,15,164,4,0,
191,15,174,2,0,
191,15,182,4,0,
191,15,188,4,0,
191,15,202,1,22,
191,15,203,2,0,
191,15,207,4,0,
191,15,213,4,0,
191,15,214,4,0,
191,15,216,4,0,
191,15,218,4,0,
191,15,219,4,0,
191,15,227,2,0,
191,15,230,2,0,
191,15,234,2,0,
191,15,235,1,28,
191,15,237,4,0,
191,15,241,1,40,
191,15,241,4,0,
191,15,263,4,0,
191,15,267,2,0,
191,15,267,4,0,
191,15,270,2,0,
191,15,275,1,4,
191,15,275,2,0,
191,15,283,1,25,
191,15,320,1,7,
191,15,320,2,0,
191,15,363,1,31,
191,15,363,2,0,
191,15,388,1,19,
191,15,402,1,43,
191,15,412,4,0,
191,15,447,4,0,
191,15,496,4,0,
191,15,580,2,0,
191,15,590,4,0,
191,16,14,4,0,
191,16,15,4,0,
191,16,38,1,37,1
191,16,71,1,1,1
191,16,72,1,10,1
191,16,73,1,13,1
191,16,73,2,0,
191,16,74,1,1,2
191,16,75,1,16,1
191,16,76,1,34,1
191,16,76,4,0,
191,16,92,4,0,
191,16,104,4,0,
191,16,113,4,0,
191,16,117,2,0,
191,16,148,4,0,
191,16,156,4,0,
191,16,164,4,0,
191,16,173,3,0,
191,16,174,2,0,
191,16,182,4,0,
191,16,188,4,0,
191,16,202,1,22,1
191,16,202,3,0,
191,16,203,2,0,
191,16,207,4,0,
191,16,213,4,0,
191,16,214,4,0,
191,16,216,4,0,
191,16,218,4,0,
191,16,219,4,0,
191,16,227,2,0,
191,16,230,2,0,
191,16,234,2,0,
191,16,235,1,28,1
191,16,235,3,0,
191,16,237,4,0,
191,16,241,1,40,1
191,16,241,4,0,
191,16,253,3,0,
191,16,263,4,0,
191,16,267,2,0,
191,16,267,4,0,
191,16,270,2,0,
191,16,270,3,0,
191,16,275,1,4,1
191,16,275,2,0,
191,16,283,1,25,1
191,16,283,3,0,
191,16,290,4,0,
191,16,320,1,7,1
191,16,320,2,0,
191,16,363,1,31,1
191,16,363,2,0,
191,16,388,1,19,1
191,16,388,3,0,
191,16,402,1,43,1
191,16,402,3,0,
191,16,412,4,0,
191,16,414,3,0,
191,16,447,4,0,
191,16,495,3,0,
191,16,496,4,0,
191,16,580,2,0,
191,16,590,4,0,
191,17,14,4,0,
191,17,38,1,37,
191,17,71,1,1,1
191,17,72,1,10,
191,17,73,1,13,
191,17,73,2,0,
191,17,74,1,1,2
191,17,75,1,16,
191,17,76,1,34,
191,17,76,4,0,
191,17,92,4,0,
191,17,104,4,0,
191,17,113,4,0,
191,17,117,2,0,
191,17,156,4,0,
191,17,164,4,0,
191,17,174,2,0,
191,17,182,4,0,
191,17,188,4,0,
191,17,202,1,22,
191,17,203,2,0,
191,17,207,4,0,
191,17,213,4,0,
191,17,214,4,0,
191,17,216,4,0,
191,17,218,4,0,
191,17,219,4,0,
191,17,227,2,0,
191,17,230,2,0,
191,17,234,2,0,
191,17,235,1,28,
191,17,237,4,0,
191,17,241,1,40,
191,17,241,4,0,
191,17,263,4,0,
191,17,267,2,0,
191,17,267,4,0,
191,17,270,2,0,
191,17,275,1,4,
191,17,275,2,0,
191,17,283,1,25,
191,17,320,1,7,
191,17,320,2,0,
191,17,363,1,31,
191,17,363,2,0,
191,17,388,1,19,
191,17,402,1,43,
191,17,412,4,0,
191,17,447,4,0,
191,17,496,4,0,
191,17,580,2,0,
191,17,590,4,0,
191,18,14,4,0,
191,18,38,1,37,
191,18,71,1,1,1
191,18,72,1,10,
191,18,73,1,13,
191,18,73,2,0,
191,18,74,1,1,2
191,18,75,1,16,
191,18,76,1,34,
191,18,76,4,0,
191,18,92,4,0,
191,18,104,4,0,
191,18,113,4,0,
191,18,117,2,0,
191,18,156,4,0,
191,18,164,4,0,
191,18,174,2,0,
191,18,182,4,0,
191,18,188,4,0,
191,18,202,1,22,
191,18,203,2,0,
191,18,207,4,0,
191,18,213,4,0,
191,18,214,4,0,
191,18,216,4,0,
191,18,218,4,0,
191,18,219,4,0,
191,18,227,2,0,
191,18,230,2,0,
191,18,234,2,0,
191,18,235,1,28,
191,18,237,4,0,
191,18,241,1,40,
191,18,241,4,0,
191,18,263,4,0,
191,18,267,2,0,
191,18,267,4,0,
191,18,270,2,0,
191,18,275,1,4,
191,18,275,2,0,
191,18,283,1,25,
191,18,320,1,7,
191,18,320,2,0,
191,18,363,1,31,
191,18,363,2,0,
191,18,388,1,19,
191,18,402,1,43,
191,18,412,4,0,
191,18,447,4,0,
191,18,496,4,0,
191,18,580,2,0,
191,18,590,4,0,
192,3,1,1,1,2
192,3,15,4,0,
192,3,63,4,0,
192,3,71,1,1,1
192,3,74,1,4,
192,3,75,1,10,
192,3,76,1,46,
192,3,76,4,0,
192,3,80,1,31,
192,3,92,4,0,
192,3,104,4,0,
192,3,148,4,0,
192,3,156,4,0,
192,3,173,4,0,
192,3,174,4,0,
192,3,182,4,0,
192,3,188,4,0,
192,3,202,4,0,
192,3,203,4,0,
192,3,207,4,0,
192,3,213,4,0,
192,3,214,4,0,
192,3,216,4,0,
192,3,218,4,0,
192,3,230,4,0,
192,3,237,4,0,
192,3,241,1,19,
192,3,241,4,0,
192,4,1,1,1,2
192,4,15,4,0,
192,4,63,4,0,
192,4,71,1,1,1
192,4,74,1,4,
192,4,75,1,10,
192,4,76,1,46,
192,4,76,4,0,
192,4,80,1,31,
192,4,92,4,0,
192,4,104,4,0,
192,4,148,4,0,
192,4,156,4,0,
192,4,173,4,0,
192,4,174,4,0,
192,4,182,4,0,
192,4,188,4,0,
192,4,202,4,0,
192,4,203,4,0,
192,4,207,4,0,
192,4,213,4,0,
192,4,214,4,0,
192,4,216,4,0,
192,4,218,4,0,
192,4,230,4,0,
192,4,237,4,0,
192,4,241,1,19,
192,4,241,4,0,
192,5,1,1,1,2
192,5,15,4,0,
192,5,63,4,0,
192,5,71,1,1,1
192,5,74,1,6,
192,5,75,1,13,
192,5,76,1,42,
192,5,76,4,0,
192,5,80,1,37,
192,5,92,4,0,
192,5,104,4,0,
192,5,113,4,0,
192,5,148,4,0,
192,5,156,4,0,
192,5,182,4,0,
192,5,188,4,0,
192,5,202,4,0,
192,5,213,4,0,
192,5,216,4,0,
192,5,218,4,0,
192,5,219,4,0,
192,5,237,4,0,
192,5,241,1,30,
192,5,241,4,0,
192,5,263,4,0,
192,5,275,1,18,
192,5,290,4,0,
192,5,331,1,25,
192,5,331,4,0,
192,6,1,1,1,2
192,6,14,3,0,
192,6,15,4,0,
192,6,38,3,0,
192,6,63,4,0,
192,6,71,1,1,1
192,6,74,1,6,
192,6,75,1,13,
192,6,76,1,42,
192,6,76,4,0,
192,6,80,1,37,
192,6,92,4,0,
192,6,102,3,0,
192,6,104,4,0,
192,6,113,4,0,
192,6,148,4,0,
192,6,156,4,0,
192,6,164,3,0,
192,6,173,3,0,
192,6,182,4,0,
192,6,188,4,0,
192,6,202,4,0,
192,6,203,3,0,
192,6,207,3,0,
192,6,213,4,0,
192,6,214,3,0,
192,6,216,4,0,
192,6,218,4,0,
192,6,219,4,0,
192,6,237,4,0,
192,6,241,1,30,
192,6,241,4,0,
192,6,263,4,0,
192,6,275,1,18,
192,6,290,4,0,
192,6,331,1,25,
192,6,331,4,0,
192,7,1,1,1,2
192,7,14,3,0,
192,7,15,4,0,
192,7,38,3,0,
192,7,63,4,0,
192,7,71,1,1,1
192,7,74,1,6,
192,7,75,1,13,
192,7,76,1,42,
192,7,76,4,0,
192,7,80,1,37,
192,7,92,4,0,
192,7,102,3,0,
192,7,104,4,0,
192,7,113,4,0,
192,7,148,4,0,
192,7,156,4,0,
192,7,164,3,0,
192,7,182,4,0,
192,7,188,4,0,
192,7,202,4,0,
192,7,213,4,0,
192,7,216,4,0,
192,7,218,4,0,
192,7,219,4,0,
192,7,237,4,0,
192,7,241,1,30,
192,7,241,4,0,
192,7,263,4,0,
192,7,275,1,18,
192,7,290,4,0,
192,7,331,1,25,
192,7,331,4,0,
192,8,1,1,1,2
192,8,14,4,0,
192,8,15,4,0,
192,8,63,4,0,
192,8,71,1,1,1
192,8,72,1,5,
192,8,73,1,17,
192,8,74,1,1,3
192,8,75,1,29,
192,8,76,1,41,
192,8,76,4,0,
192,8,80,1,33,
192,8,92,4,0,
192,8,104,4,0,
192,8,113,4,0,
192,8,148,4,0,
192,8,156,4,0,
192,8,164,4,0,
192,8,182,4,0,
192,8,188,4,0,
192,8,202,4,0,
192,8,203,4,0,
192,8,207,4,0,
192,8,213,4,0,
192,8,214,4,0,
192,8,216,4,0,
192,8,218,4,0,
192,8,219,4,0,
192,8,237,4,0,
192,8,241,1,37,
192,8,241,4,0,
192,8,263,4,0,
192,8,275,1,9,
192,8,290,4,0,
192,8,320,1,13,
192,8,331,1,21,
192,8,331,4,0,
192,8,363,4,0,
192,8,388,1,25,
192,8,412,4,0,
192,8,416,4,0,
192,8,437,1,43,
192,8,445,4,0,
192,8,447,4,0,
192,9,1,1,1,2
192,9,14,4,0,
192,9,15,4,0,
192,9,63,4,0,
192,9,71,1,1,1
192,9,72,1,5,
192,9,73,1,17,
192,9,74,1,1,3
192,9,75,1,29,
192,9,76,1,41,
192,9,76,4,0,
192,9,80,1,33,
192,9,92,4,0,
192,9,104,4,0,
192,9,113,4,0,
192,9,148,4,0,
192,9,156,4,0,
192,9,164,4,0,
192,9,173,3,0,
192,9,182,4,0,
192,9,188,4,0,
192,9,202,4,0,
192,9,203,4,0,
192,9,207,4,0,
192,9,213,4,0,
192,9,214,4,0,
192,9,216,4,0,
192,9,218,4,0,
192,9,219,4,0,
192,9,235,3,0,
192,9,237,4,0,
192,9,241,1,37,
192,9,241,4,0,
192,9,253,3,0,
192,9,263,4,0,
192,9,270,3,0,
192,9,275,1,9,
192,9,283,3,0,
192,9,290,4,0,
192,9,320,1,13,
192,9,331,1,21,
192,9,331,4,0,
192,9,363,4,0,
192,9,388,1,25,
192,9,402,3,0,
192,9,412,4,0,
192,9,416,4,0,
192,9,437,1,43,
192,9,445,4,0,
192,9,447,4,0,
192,10,1,1,1,2
192,10,14,4,0,
192,10,15,4,0,
192,10,63,4,0,
192,10,71,1,1,1
192,10,72,1,5,
192,10,73,1,17,
192,10,74,1,1,3
192,10,75,1,29,
192,10,76,1,41,
192,10,76,4,0,
192,10,80,1,33,
192,10,92,4,0,
192,10,104,4,0,
192,10,113,4,0,
192,10,148,4,0,
192,10,156,4,0,
192,10,164,4,0,
192,10,173,3,0,
192,10,182,4,0,
192,10,188,4,0,
192,10,202,4,0,
192,10,203,4,0,
192,10,207,4,0,
192,10,213,4,0,
192,10,214,4,0,
192,10,216,4,0,
192,10,218,4,0,
192,10,219,4,0,
192,10,235,3,0,
192,10,237,4,0,
192,10,241,1,37,
192,10,241,4,0,
192,10,253,3,0,
192,10,263,4,0,
192,10,270,3,0,
192,10,275,1,9,
192,10,283,3,0,
192,10,290,4,0,
192,10,320,1,13,
192,10,331,1,21,
192,10,331,4,0,
192,10,363,4,0,
192,10,388,1,25,
192,10,388,3,0,
192,10,402,3,0,
192,10,412,4,0,
192,10,414,3,0,
192,10,416,4,0,
192,10,437,1,43,
192,10,445,4,0,
192,10,447,4,0,
192,11,1,1,1,2
192,11,14,4,0,
192,11,15,4,0,
192,11,63,4,0,
192,11,71,1,1,1
192,11,72,1,5,
192,11,73,1,17,
192,11,74,1,1,3
192,11,75,1,29,
192,11,76,1,41,
192,11,76,4,0,
192,11,80,1,33,
192,11,92,4,0,
192,11,104,4,0,
192,11,113,4,0,
192,11,148,4,0,
192,11,156,4,0,
192,11,164,4,0,
192,11,182,4,0,
192,11,188,4,0,
192,11,207,4,0,
192,11,213,4,0,
192,11,216,4,0,
192,11,218,4,0,
192,11,219,4,0,
192,11,237,4,0,
192,11,241,1,37,
192,11,241,4,0,
192,11,263,4,0,
192,11,275,1,9,
192,11,320,1,13,
192,11,331,1,21,
192,11,388,1,25,
192,11,412,4,0,
192,11,416,4,0,
192,11,437,1,45,
192,11,447,4,0,
192,11,496,4,0,
192,12,1,1,1,2
192,12,15,4,0,
192,12,63,4,0,
192,12,71,1,1,1
192,12,74,1,6,
192,12,75,1,13,
192,12,76,1,42,
192,12,76,4,0,
192,12,80,1,37,
192,12,92,4,0,
192,12,104,4,0,
192,12,113,4,0,
192,12,148,4,0,
192,12,156,4,0,
192,12,182,4,0,
192,12,188,4,0,
192,12,202,4,0,
192,12,213,4,0,
192,12,216,4,0,
192,12,218,4,0,
192,12,219,4,0,
192,12,237,4,0,
192,12,241,1,30,
192,12,241,4,0,
192,12,263,4,0,
192,12,275,1,18,
192,12,290,4,0,
192,12,331,1,25,
192,12,331,4,0,
192,13,1,1,1,2
192,13,15,4,0,
192,13,38,3,0,
192,13,63,4,0,
192,13,71,1,1,1
192,13,74,1,6,
192,13,75,1,13,
192,13,76,1,42,
192,13,76,4,0,
192,13,80,1,37,
192,13,92,4,0,
192,13,102,3,0,
192,13,104,4,0,
192,13,113,4,0,
192,13,148,4,0,
192,13,156,4,0,
192,13,164,3,0,
192,13,182,4,0,
192,13,188,4,0,
192,13,202,4,0,
192,13,207,3,0,
192,13,213,4,0,
192,13,216,4,0,
192,13,218,4,0,
192,13,219,4,0,
192,13,237,4,0,
192,13,241,1,30,
192,13,241,4,0,
192,13,263,4,0,
192,13,275,1,18,
192,13,290,4,0,
192,13,331,1,25,
192,13,331,4,0,
192,14,1,1,1,2
192,14,14,4,0,
192,14,15,4,0,
192,14,38,1,37,
192,14,63,4,0,
192,14,71,1,1,1
192,14,72,1,10,
192,14,73,1,13,
192,14,74,1,1,3
192,14,75,1,16,
192,14,76,1,34,
192,14,76,4,0,
192,14,80,1,28,
192,14,92,4,0,
192,14,104,4,0,
192,14,113,4,0,
192,14,148,4,0,
192,14,156,4,0,
192,14,164,4,0,
192,14,173,3,0,
192,14,182,4,0,
192,14,188,4,0,
192,14,202,1,22,
192,14,202,3,0,
192,14,207,4,0,
192,14,213,4,0,
192,14,214,3,0,
192,14,216,4,0,
192,14,218,4,0,
192,14,219,4,0,
192,14,235,3,0,
192,14,237,4,0,
192,14,241,1,40,
192,14,241,4,0,
192,14,253,3,0,
192,14,263,4,0,
192,14,270,3,0,
192,14,275,1,4,
192,14,283,3,0,
192,14,320,1,7,
192,14,331,1,25,
192,14,363,1,31,
192,14,388,1,19,
192,14,388,3,0,
192,14,402,3,0,
192,14,412,4,0,
192,14,414,3,0,
192,14,416,4,0,
192,14,437,1,43,
192,14,447,4,0,
192,14,495,3,0,
192,14,496,4,0,
192,15,1,1,1,3
192,15,14,4,0,
192,15,15,4,0,
192,15,38,1,37,
192,15,63,4,0,
192,15,71,1,1,2
192,15,72,1,10,
192,15,73,1,13,
192,15,74,1,1,4
192,15,75,1,16,
192,15,76,1,34,
192,15,76,4,0,
192,15,80,1,28,
192,15,92,4,0,
192,15,104,4,0,
192,15,113,4,0,
192,15,148,4,0,
192,15,156,4,0,
192,15,164,4,0,
192,15,182,4,0,
192,15,188,4,0,
192,15,202,1,22,
192,15,207,4,0,
192,15,213,4,0,
192,15,214,4,0,
192,15,216,4,0,
192,15,218,4,0,
192,15,219,4,0,
192,15,237,4,0,
192,15,241,1,40,
192,15,241,4,0,
192,15,263,4,0,
192,15,267,4,0,
192,15,275,1,4,
192,15,320,1,7,
192,15,331,1,25,
192,15,363,1,31,
192,15,388,1,19,
192,15,412,4,0,
192,15,416,4,0,
192,15,437,1,43,
192,15,447,4,0,
192,15,496,4,0,
192,15,572,1,50,
192,15,579,1,1,1
192,15,590,4,0,
192,16,1,1,1,3
192,16,14,4,0,
192,16,15,4,0,
192,16,38,1,37,1
192,16,63,4,0,
192,16,71,1,1,2
192,16,72,1,10,1
192,16,73,1,13,1
192,16,74,1,1,4
192,16,75,1,16,1
192,16,76,1,34,1
192,16,76,4,0,
192,16,80,1,28,1
192,16,92,4,0,
192,16,104,4,0,
192,16,113,4,0,
192,16,148,4,0,
192,16,156,4,0,
192,16,164,4,0,
192,16,173,3,0,
192,16,182,4,0,
192,16,188,4,0,
192,16,202,1,22,1
192,16,202,3,0,
192,16,207,4,0,
192,16,213,4,0,
192,16,214,4,0,
192,16,216,4,0,
192,16,218,4,0,
192,16,219,4,0,
192,16,235,3,0,
192,16,237,4,0,
192,16,241,1,40,1
192,16,241,4,0,
192,16,253,3,0,
192,16,263,4,0,
192,16,267,4,0,
192,16,270,3,0,
192,16,275,1,4,1
192,16,283,3,0,
192,16,290,4,0,
192,16,320,1,7,1
192,16,331,1,25,1
192,16,363,1,31,1
192,16,388,1,19,1
192,16,388,3,0,
192,16,402,3,0,
192,16,412,4,0,
192,16,414,3,0,
192,16,416,4,0,
192,16,437,1,43,1
192,16,447,4,0,
192,16,495,3,0,
192,16,496,4,0,
192,16,572,1,50,1
192,16,579,1,1,1
192,16,590,4,0,
192,17,1,1,1,3
192,17,14,4,0,
192,17,38,1,37,
192,17,63,4,0,
192,17,71,1,1,2
192,17,72,1,10,
192,17,73,1,13,
192,17,74,1,1,4
192,17,75,1,16,
192,17,76,1,34,
192,17,76,4,0,
192,17,80,1,28,
192,17,92,4,0,
192,17,104,4,0,
192,17,113,4,0,
192,17,156,4,0,
192,17,164,4,0,
192,17,182,4,0,
192,17,188,4,0,
192,17,202,1,22,
192,17,207,4,0,
192,17,213,4,0,
192,17,214,4,0,
192,17,216,4,0,
192,17,218,4,0,
192,17,219,4,0,
192,17,237,4,0,
192,17,241,1,40,
192,17,241,4,0,
192,17,263,4,0,
192,17,267,4,0,
192,17,275,1,4,
192,17,320,1,7,
192,17,331,1,25,
192,17,363,1,31,
192,17,388,1,19,
192,17,412,4,0,
192,17,416,4,0,
192,17,437,1,43,
192,17,447,4,0,
192,17,496,4,0,
192,17,572,1,50,
192,17,579,1,1,1
192,17,590,4,0,
192,18,1,1,1,3
192,18,14,4,0,
192,18,38,1,37,
192,18,63,4,0,
192,18,71,1,1,2
192,18,72,1,10,
192,18,73,1,13,
192,18,74,1,1,4
192,18,75,1,16,
192,18,76,1,34,
192,18,76,4,0,
192,18,80,1,28,
192,18,92,4,0,
192,18,104,4,0,
192,18,113,4,0,
192,18,156,4,0,
192,18,164,4,0,
192,18,182,4,0,
192,18,188,4,0,
192,18,202,1,22,
192,18,207,4,0,
192,18,213,4,0,
192,18,214,4,0,
192,18,216,4,0,
192,18,218,4,0,
192,18,219,4,0,
192,18,237,4,0,
192,18,241,1,40,
192,18,241,4,0,
192,18,263,4,0,
192,18,267,4,0,
192,18,275,1,4,
192,18,320,1,7,
192,18,331,1,25,
192,18,363,1,31,
192,18,388,1,19,
192,18,412,4,0,
192,18,416,4,0,
192,18,437,1,43,
192,18,447,4,0,
192,18,496,4,0,
192,18,572,1,50,
192,18,579,1,1,1
192,18,590,4,0,
193,3,18,2,0,
193,3,29,4,0,
193,3,33,1,1,1
193,3,48,1,31,
193,3,49,1,19,
193,3,76,4,0,
193,3,92,4,0,
193,3,98,1,7,
193,3,103,1,43,
193,3,104,1,13,
193,3,104,4,0,
193,3,129,1,37,
193,3,129,4,0,
193,3,141,2,0,
193,3,148,4,0,
193,3,156,4,0,
193,3,168,4,0,
193,3,173,4,0,
193,3,174,4,0,
193,3,179,2,0,
193,3,182,4,0,
193,3,193,1,1,2
193,3,197,1,25,
193,3,197,4,0,
193,3,202,4,0,
193,3,203,4,0,
193,3,207,4,0,
193,3,213,4,0,
193,3,214,4,0,
193,3,216,4,0,
193,3,218,4,0,
193,3,237,4,0,
193,3,241,4,0,
193,4,17,1,37,
193,4,18,2,0,
193,4,29,4,0,
193,4,33,1,1,1
193,4,48,1,31,
193,4,49,1,19,
193,4,76,4,0,
193,4,92,4,0,
193,4,98,1,7,
193,4,103,1,43,
193,4,104,1,13,
193,4,104,4,0,
193,4,129,4,0,
193,4,141,2,0,
193,4,148,4,0,
193,4,156,4,0,
193,4,168,4,0,
193,4,173,4,0,
193,4,174,4,0,
193,4,179,2,0,
193,4,182,4,0,
193,4,193,1,1,2
193,4,197,1,25,
193,4,197,4,0,
193,4,202,4,0,
193,4,203,4,0,
193,4,207,4,0,
193,4,213,4,0,
193,4,214,4,0,
193,4,216,4,0,
193,4,218,4,0,
193,4,237,4,0,
193,4,241,4,0,
193,5,17,1,43,
193,5,18,2,0,
193,5,33,1,1,1
193,5,48,1,31,
193,5,49,1,19,
193,5,76,4,0,
193,5,92,4,0,
193,5,94,4,0,
193,5,98,1,7,
193,5,103,1,49,
193,5,104,1,13,
193,5,104,4,0,
193,5,141,2,0,
193,5,148,4,0,
193,5,156,4,0,
193,5,168,4,0,
193,5,179,2,0,
193,5,182,4,0,
193,5,193,1,1,2
193,5,197,1,25,
193,5,202,4,0,
193,5,211,4,0,
193,5,213,4,0,
193,5,216,4,0,
193,5,218,4,0,
193,5,237,4,0,
193,5,241,4,0,
193,5,247,4,0,
193,5,253,1,37,
193,5,263,4,0,
193,5,290,4,0,
193,5,318,2,0,
193,5,324,2,0,
193,5,332,4,0,
193,6,17,1,43,
193,6,18,2,0,
193,6,33,1,1,1
193,6,38,3,0,
193,6,48,1,31,
193,6,49,1,19,
193,6,76,4,0,
193,6,92,4,0,
193,6,94,4,0,
193,6,98,1,7,
193,6,102,3,0,
193,6,103,1,49,
193,6,104,1,13,
193,6,104,4,0,
193,6,129,3,0,
193,6,138,3,0,
193,6,141,2,0,
193,6,148,4,0,
193,6,156,4,0,
193,6,164,3,0,
193,6,168,4,0,
193,6,173,3,0,
193,6,179,2,0,
193,6,182,4,0,
193,6,193,1,1,2
193,6,197,1,25,
193,6,202,4,0,
193,6,203,3,0,
193,6,207,3,0,
193,6,211,4,0,
193,6,213,4,0,
193,6,214,3,0,
193,6,216,4,0,
193,6,218,4,0,
193,6,237,4,0,
193,6,241,4,0,
193,6,247,4,0,
193,6,253,1,37,
193,6,263,4,0,
193,6,290,4,0,
193,6,318,2,0,
193,6,324,2,0,
193,6,332,4,0,
193,7,17,1,39,
193,7,18,2,0,
193,7,33,1,1,1
193,7,38,3,0,
193,7,48,1,45,
193,7,49,1,17,
193,7,76,4,0,
193,7,92,4,0,
193,7,94,4,0,
193,7,95,1,23,
193,7,98,1,6,
193,7,102,3,0,
193,7,103,1,50,
193,7,104,1,12,
193,7,104,4,0,
193,7,138,3,0,
193,7,141,2,0,
193,7,148,4,0,
193,7,156,4,0,
193,7,164,3,0,
193,7,168,4,0,
193,7,179,2,0,
193,7,182,4,0,
193,7,193,1,1,2
193,7,197,1,28,
193,7,202,4,0,
193,7,211,4,0,
193,7,213,4,0,
193,7,216,4,0,
193,7,218,4,0,
193,7,237,4,0,
193,7,241,4,0,
193,7,247,4,0,
193,7,253,1,34,
193,7,263,4,0,
193,7,290,4,0,
193,7,318,2,0,
193,7,324,2,0,
193,7,332,4,0,
193,8,17,1,43,
193,8,18,2,0,
193,8,33,1,1,1
193,8,48,1,22,
193,8,49,1,14,
193,8,76,4,0,
193,8,92,4,0,
193,8,94,4,0,
193,8,95,1,38,
193,8,98,1,6,
193,8,103,1,46,
193,8,104,1,11,
193,8,104,4,0,
193,8,138,4,0,
193,8,141,2,0,
193,8,148,4,0,
193,8,156,4,0,
193,8,164,4,0,
193,8,168,4,0,
193,8,179,2,0,
193,8,182,4,0,
193,8,185,2,0,
193,8,193,1,1,2
193,8,197,1,17,
193,8,202,4,0,
193,8,203,4,0,
193,8,207,4,0,
193,8,211,4,0,
193,8,213,4,0,
193,8,214,4,0,
193,8,216,4,0,
193,8,218,4,0,
193,8,228,1,30,
193,8,228,2,0,
193,8,237,4,0,
193,8,241,4,0,
193,8,246,1,33,
193,8,247,4,0,
193,8,253,1,27,
193,8,263,4,0,
193,8,290,4,0,
193,8,318,2,0,
193,8,318,4,0,
193,8,324,2,0,
193,8,332,4,0,
193,8,355,4,0,
193,8,363,4,0,
193,8,364,2,0,
193,8,369,1,49,
193,8,369,4,0,
193,8,403,1,54,
193,8,405,1,57,
193,8,432,4,0,
193,8,445,4,0,
193,9,17,1,43,
193,9,18,2,0,
193,9,33,1,1,1
193,9,48,1,22,
193,9,49,1,14,
193,9,76,4,0,
193,9,92,4,0,
193,9,94,4,0,
193,9,95,1,38,
193,9,98,1,6,
193,9,103,1,46,
193,9,104,1,11,
193,9,104,4,0,
193,9,129,3,0,
193,9,138,4,0,
193,9,141,2,0,
193,9,148,4,0,
193,9,156,4,0,
193,9,164,4,0,
193,9,168,4,0,
193,9,173,3,0,
193,9,179,2,0,
193,9,182,4,0,
193,9,185,2,0,
193,9,193,1,1,2
193,9,197,1,17,
193,9,202,4,0,
193,9,203,4,0,
193,9,207,4,0,
193,9,211,4,0,
193,9,213,4,0,
193,9,214,4,0,
193,9,216,4,0,
193,9,218,4,0,
193,9,228,1,30,
193,9,228,2,0,
193,9,237,4,0,
193,9,241,4,0,
193,9,246,1,33,
193,9,246,3,0,
193,9,247,4,0,
193,9,253,1,27,
193,9,253,3,0,
193,9,263,4,0,
193,9,290,4,0,
193,9,314,3,0,
193,9,318,2,0,
193,9,318,4,0,
193,9,324,2,0,
193,9,324,3,0,
193,9,332,4,0,
193,9,355,4,0,
193,9,363,4,0,
193,9,364,2,0,
193,9,369,1,49,
193,9,369,4,0,
193,9,403,1,54,
193,9,405,1,57,
193,9,432,4,0,
193,9,445,4,0,
193,9,466,3,0,
193,10,17,1,43,
193,10,18,2,0,
193,10,29,3,0,
193,10,33,1,1,1
193,10,48,1,22,
193,10,49,1,14,
193,10,76,4,0,
193,10,81,3,0,
193,10,92,4,0,
193,10,94,4,0,
193,10,95,1,38,
193,10,98,1,6,
193,10,103,1,46,
193,10,104,1,11,
193,10,104,4,0,
193,10,129,3,0,
193,10,138,4,0,
193,10,141,2,0,
193,10,148,4,0,
193,10,156,4,0,
193,10,164,4,0,
193,10,168,4,0,
193,10,173,3,0,
193,10,179,2,0,
193,10,182,4,0,
193,10,185,2,0,
193,10,193,1,1,2
193,10,197,1,17,
193,10,202,4,0,
193,10,203,4,0,
193,10,207,4,0,
193,10,211,4,0,
193,10,213,4,0,
193,10,214,4,0,
193,10,216,4,0,
193,10,218,4,0,
193,10,228,1,30,
193,10,228,2,0,
193,10,237,4,0,
193,10,241,4,0,
193,10,246,1,33,
193,10,246,3,0,
193,10,247,4,0,
193,10,253,1,27,
193,10,253,3,0,
193,10,263,4,0,
193,10,290,4,0,
193,10,314,3,0,
193,10,318,2,0,
193,10,318,4,0,
193,10,324,2,0,
193,10,324,3,0,
193,10,332,4,0,
193,10,355,4,0,
193,10,363,4,0,
193,10,364,2,0,
193,10,366,3,0,
193,10,369,1,49,
193,10,369,4,0,
193,10,403,1,54,
193,10,405,1,57,
193,10,445,4,0,
193,10,450,3,0,
193,10,466,3,0,
193,11,17,1,43,
193,11,18,2,0,
193,11,33,1,1,1
193,11,38,2,0,
193,11,48,1,22,
193,11,49,1,14,
193,11,76,4,0,
193,11,92,4,0,
193,11,94,4,0,
193,11,95,1,38,
193,11,98,1,6,
193,11,103,1,46,
193,11,104,1,11,
193,11,104,4,0,
193,11,138,4,0,
193,11,141,2,0,
193,11,148,4,0,
193,11,156,4,0,
193,11,164,4,0,
193,11,168,4,0,
193,11,179,2,0,
193,11,182,4,0,
193,11,185,2,0,
193,11,193,1,1,2
193,11,197,1,17,
193,11,207,4,0,
193,11,213,4,0,
193,11,216,4,0,
193,11,218,4,0,
193,11,228,1,30,
193,11,228,2,0,
193,11,237,4,0,
193,11,241,4,0,
193,11,246,1,33,
193,11,247,4,0,
193,11,253,1,27,
193,11,263,4,0,
193,11,290,2,0,
193,11,318,2,0,
193,11,324,2,0,
193,11,332,4,0,
193,11,364,2,0,
193,11,369,1,49,
193,11,369,4,0,
193,11,403,1,54,
193,11,405,1,57,
193,11,496,4,0,
193,12,17,1,43,
193,12,33,1,1,1
193,12,48,1,31,
193,12,49,1,19,
193,12,76,4,0,
193,12,92,4,0,
193,12,94,4,0,
193,12,98,1,7,
193,12,103,1,49,
193,12,104,1,13,
193,12,104,4,0,
193,12,148,4,0,
193,12,156,4,0,
193,12,168,4,0,
193,12,182,4,0,
193,12,193,1,1,2
193,12,197,1,25,
193,12,202,4,0,
193,12,211,4,0,
193,12,213,4,0,
193,12,216,4,0,
193,12,218,4,0,
193,12,237,4,0,
193,12,241,4,0,
193,12,247,4,0,
193,12,253,1,37,
193,12,263,4,0,
193,12,290,4,0,
193,12,332,4,0,
193,13,17,1,43,
193,13,33,1,1,1
193,13,38,3,0,
193,13,48,1,31,
193,13,49,1,19,
193,13,76,4,0,
193,13,92,4,0,
193,13,94,4,0,
193,13,98,1,7,
193,13,102,3,0,
193,13,103,1,49,
193,13,104,1,13,
193,13,104,4,0,
193,13,138,3,0,
193,13,148,4,0,
193,13,156,4,0,
193,13,164,3,0,
193,13,168,4,0,
193,13,182,4,0,
193,13,193,1,1,2
193,13,197,1,25,
193,13,202,4,0,
193,13,207,3,0,
193,13,211,4,0,
193,13,213,4,0,
193,13,216,4,0,
193,13,218,4,0,
193,13,237,4,0,
193,13,241,4,0,
193,13,247,4,0,
193,13,253,1,37,
193,13,263,4,0,
193,13,290,4,0,
193,13,332,4,0,
193,14,17,1,43,
193,14,18,2,0,
193,14,33,1,1,1
193,14,38,2,0,
193,14,48,1,22,
193,14,49,1,14,
193,14,76,4,0,
193,14,92,4,0,
193,14,94,4,0,
193,14,95,1,38,
193,14,98,1,6,
193,14,103,1,46,
193,14,104,1,11,
193,14,104,4,0,
193,14,138,4,0,
193,14,141,2,0,
193,14,148,4,0,
193,14,156,4,0,
193,14,164,4,0,
193,14,168,4,0,
193,14,173,3,0,
193,14,179,2,0,
193,14,182,4,0,
193,14,185,2,0,
193,14,193,1,1,2
193,14,197,1,17,
193,14,202,3,0,
193,14,207,4,0,
193,14,213,4,0,
193,14,214,3,0,
193,14,216,4,0,
193,14,218,4,0,
193,14,228,1,30,
193,14,228,2,0,
193,14,237,4,0,
193,14,241,4,0,
193,14,246,1,33,
193,14,247,4,0,
193,14,253,1,27,
193,14,253,3,0,
193,14,263,4,0,
193,14,290,2,0,
193,14,318,2,0,
193,14,324,2,0,
193,14,324,3,0,
193,14,332,4,0,
193,14,355,3,0,
193,14,364,2,0,
193,14,366,3,0,
193,14,369,1,49,
193,14,369,4,0,
193,14,403,1,54,
193,14,405,1,57,
193,14,450,3,0,
193,14,496,4,0,
193,15,17,1,43,
193,15,18,2,0,
193,15,33,1,1,1
193,15,38,2,0,
193,15,48,1,22,
193,15,49,1,14,
193,15,76,4,0,
193,15,92,4,0,
193,15,94,4,0,
193,15,95,1,38,
193,15,98,1,6,
193,15,103,1,46,
193,15,104,1,11,
193,15,104,4,0,
193,15,138,4,0,
193,15,141,2,0,
193,15,148,4,0,
193,15,156,4,0,
193,15,164,4,0,
193,15,168,4,0,
193,15,179,2,0,
193,15,182,4,0,
193,15,185,2,0,
193,15,193,1,1,2
193,15,197,1,17,
193,15,207,4,0,
193,15,211,4,0,
193,15,213,4,0,
193,15,214,4,0,
193,15,216,4,0,
193,15,218,4,0,
193,15,228,1,30,
193,15,228,2,0,
193,15,237,4,0,
193,15,241,4,0,
193,15,246,1,33,
193,15,247,4,0,
193,15,253,1,27,
193,15,263,4,0,
193,15,290,2,0,
193,15,318,2,0,
193,15,324,2,0,
193,15,332,4,0,
193,15,355,4,0,
193,15,364,2,0,
193,15,369,1,49,
193,15,369,4,0,
193,15,403,1,54,
193,15,405,1,57,
193,15,496,4,0,
193,15,590,4,0,
193,16,17,1,43,1
193,16,18,2,0,
193,16,33,1,1,1
193,16,38,2,0,
193,16,48,1,22,1
193,16,49,1,14,1
193,16,76,4,0,
193,16,92,4,0,
193,16,94,4,0,
193,16,95,1,38,1
193,16,98,1,6,1
193,16,103,1,46,1
193,16,104,1,11,1
193,16,104,4,0,
193,16,138,4,0,
193,16,141,2,0,
193,16,148,4,0,
193,16,156,4,0,
193,16,164,4,0,
193,16,168,4,0,
193,16,173,3,0,
193,16,179,2,0,
193,16,182,4,0,
193,16,185,2,0,
193,16,193,1,1,2
193,16,197,1,17,1
193,16,202,3,0,
193,16,207,4,0,
193,16,211,4,0,
193,16,213,4,0,
193,16,214,4,0,
193,16,216,4,0,
193,16,218,4,0,
193,16,228,1,30,1
193,16,228,2,0,
193,16,237,4,0,
193,16,241,4,0,
193,16,246,1,33,1
193,16,247,4,0,
193,16,253,1,27,1
193,16,253,3,0,
193,16,263,4,0,
193,16,290,2,0,
193,16,290,4,0,
193,16,318,2,0,
193,16,324,2,0,
193,16,324,3,0,
193,16,332,4,0,
193,16,355,4,0,
193,16,364,2,0,
193,16,366,3,0,
193,16,369,1,49,1
193,16,369,4,0,
193,16,403,1,54,1
193,16,405,1,57,1
193,16,450,3,0,
193,16,496,4,0,
193,16,590,4,0,
193,17,17,1,43,
193,17,18,2,0,
193,17,33,1,1,1
193,17,38,2,0,
193,17,48,1,22,
193,17,49,1,14,
193,17,76,4,0,
193,17,92,4,0,
193,17,94,4,0,
193,17,95,1,38,
193,17,98,1,6,
193,17,103,1,46,
193,17,104,1,11,
193,17,104,4,0,
193,17,138,4,0,
193,17,141,2,0,
193,17,156,4,0,
193,17,164,4,0,
193,17,168,4,0,
193,17,179,2,0,
193,17,182,4,0,
193,17,185,2,0,
193,17,193,1,1,2
193,17,197,1,17,
193,17,207,4,0,
193,17,211,4,0,
193,17,213,4,0,
193,17,214,4,0,
193,17,216,4,0,
193,17,218,4,0,
193,17,228,1,30,
193,17,228,2,0,
193,17,237,4,0,
193,17,241,4,0,
193,17,246,1,33,
193,17,247,4,0,
193,17,253,1,27,
193,17,263,4,0,
193,17,290,2,0,
193,17,318,2,0,
193,17,324,2,0,
193,17,332,4,0,
193,17,355,4,0,
193,17,364,2,0,
193,17,369,1,49,
193,17,369,4,0,
193,17,403,1,54,
193,17,405,1,57,
193,17,496,4,0,
193,17,590,4,0,
193,18,17,1,43,
193,18,18,2,0,
193,18,33,1,1,1
193,18,38,2,0,
193,18,48,1,22,
193,18,49,1,14,
193,18,76,4,0,
193,18,92,4,0,
193,18,94,4,0,
193,18,95,1,38,
193,18,98,1,6,
193,18,103,1,46,
193,18,104,1,11,
193,18,104,4,0,
193,18,138,4,0,
193,18,141,2,0,
193,18,156,4,0,
193,18,164,4,0,
193,18,168,4,0,
193,18,179,2,0,
193,18,182,4,0,
193,18,185,2,0,
193,18,193,1,1,2
193,18,197,1,17,
193,18,207,4,0,
193,18,211,4,0,
193,18,213,4,0,
193,18,214,4,0,
193,18,216,4,0,
193,18,218,4,0,
193,18,228,1,30,
193,18,228,2,0,
193,18,237,4,0,
193,18,241,4,0,
193,18,246,1,33,
193,18,247,4,0,
193,18,253,1,27,
193,18,263,4,0,
193,18,290,2,0,
193,18,318,2,0,
193,18,324,2,0,
193,18,332,4,0,
193,18,355,4,0,
193,18,364,2,0,
193,18,369,1,49,
193,18,369,4,0,
193,18,403,1,54,
193,18,405,1,57,
193,18,496,4,0,
193,18,590,4,0,
194,3,8,4,0,
194,3,21,1,11,
194,3,29,4,0,
194,3,34,2,0,
194,3,39,1,1,2
194,3,54,1,51,
194,3,55,1,1,1
194,3,57,4,0,
194,3,89,1,31,
194,3,89,4,0,
194,3,91,4,0,
194,3,92,4,0,
194,3,104,4,0,
194,3,111,4,0,
194,3,114,1,51,3
194,3,133,1,21,
194,3,148,4,0,
194,3,156,4,0,
194,3,173,4,0,
194,3,174,4,0,
194,3,182,4,0,
194,3,188,4,0,
194,3,189,4,0,
194,3,201,4,0,
194,3,203,4,0,
194,3,205,4,0,
194,3,207,4,0,
194,3,213,4,0,
194,3,214,4,0,
194,3,216,4,0,
194,3,218,4,0,
194,3,219,2,0,
194,3,223,4,0,
194,3,231,4,0,
194,3,237,4,0,
194,3,240,1,41,
194,3,240,4,0,
194,3,246,2,0,
194,3,249,4,0,
194,3,250,4,0,
194,4,8,4,0,
194,4,21,1,11,
194,4,29,4,0,
194,4,34,2,0,
194,4,39,1,1,2
194,4,54,1,51,
194,4,55,1,1,1
194,4,57,4,0,
194,4,58,3,0,
194,4,89,1,31,
194,4,89,4,0,
194,4,91,4,0,
194,4,92,4,0,
194,4,104,4,0,
194,4,111,4,0,
194,4,114,1,51,3
194,4,133,1,21,
194,4,148,4,0,
194,4,156,4,0,
194,4,173,4,0,
194,4,174,4,0,
194,4,182,4,0,
194,4,188,4,0,
194,4,189,4,0,
194,4,201,4,0,
194,4,203,4,0,
194,4,205,4,0,
194,4,207,4,0,
194,4,213,4,0,
194,4,214,4,0,
194,4,216,4,0,
194,4,218,4,0,
194,4,219,2,0,
194,4,223,4,0,
194,4,231,4,0,
194,4,237,4,0,
194,4,240,1,41,
194,4,240,4,0,
194,4,246,2,0,
194,4,249,4,0,
194,4,250,4,0,
194,5,21,1,11,
194,5,34,2,0,
194,5,39,1,1,2
194,5,54,1,51,
194,5,55,1,1,1
194,5,57,4,0,
194,5,58,4,0,
194,5,59,4,0,
194,5,89,1,36,
194,5,89,4,0,
194,5,91,4,0,
194,5,92,4,0,
194,5,104,4,0,
194,5,114,1,51,3
194,5,127,4,0,
194,5,133,1,21,
194,5,148,4,0,
194,5,156,4,0,
194,5,174,2,0,
194,5,182,4,0,
194,5,188,4,0,
194,5,201,4,0,
194,5,213,4,0,
194,5,216,4,0,
194,5,218,4,0,
194,5,219,2,0,
194,5,231,4,0,
194,5,237,4,0,
194,5,240,1,41,
194,5,240,4,0,
194,5,246,2,0,
194,5,249,4,0,
194,5,254,2,0,
194,5,255,2,0,
194,5,256,2,0,
194,5,258,4,0,
194,5,263,4,0,
194,5,281,1,31,
194,5,290,4,0,
194,5,291,4,0,
194,5,300,2,0,
194,5,341,1,16,
194,5,352,4,0,
194,6,8,3,0,
194,6,21,1,11,
194,6,34,2,0,
194,6,34,3,0,
194,6,38,3,0,
194,6,39,1,1,2
194,6,54,1,51,
194,6,55,1,1,1
194,6,57,4,0,
194,6,58,4,0,
194,6,59,4,0,
194,6,89,1,36,
194,6,89,4,0,
194,6,91,4,0,
194,6,92,4,0,
194,6,102,3,0,
194,6,104,4,0,
194,6,111,3,0,
194,6,114,1,51,3
194,6,127,4,0,
194,6,133,1,21,
194,6,148,4,0,
194,6,156,4,0,
194,6,164,3,0,
194,6,173,3,0,
194,6,174,2,0,
194,6,182,4,0,
194,6,188,4,0,
194,6,189,3,0,
194,6,201,4,0,
194,6,203,3,0,
194,6,205,3,0,
194,6,207,3,0,
194,6,213,4,0,
194,6,214,3,0,
194,6,216,4,0,
194,6,218,4,0,
194,6,219,2,0,
194,6,223,3,0,
194,6,231,4,0,
194,6,237,4,0,
194,6,240,1,41,
194,6,240,4,0,
194,6,246,2,0,
194,6,249,4,0,
194,6,254,2,0,
194,6,255,2,0,
194,6,256,2,0,
194,6,258,4,0,
194,6,263,4,0,
194,6,281,1,31,
194,6,290,4,0,
194,6,291,4,0,
194,6,300,2,0,
194,6,341,1,16,
194,6,352,4,0,
194,7,21,1,11,
194,7,34,2,0,
194,7,34,3,0,
194,7,38,3,0,
194,7,39,1,1,2
194,7,54,1,51,
194,7,55,1,1,1
194,7,57,4,0,
194,7,58,4,0,
194,7,59,4,0,
194,7,89,1,36,
194,7,89,4,0,
194,7,91,4,0,
194,7,92,4,0,
194,7,102,3,0,
194,7,104,4,0,
194,7,114,1,51,3
194,7,127,4,0,
194,7,133,1,21,
194,7,148,4,0,
194,7,156,4,0,
194,7,164,3,0,
194,7,174,2,0,
194,7,182,4,0,
194,7,188,4,0,
194,7,201,4,0,
194,7,213,4,0,
194,7,216,4,0,
194,7,218,4,0,
194,7,219,2,0,
194,7,231,4,0,
194,7,237,4,0,
194,7,240,1,41,
194,7,240,4,0,
194,7,246,2,0,
194,7,249,4,0,
194,7,254,2,0,
194,7,255,2,0,
194,7,256,2,0,
194,7,258,4,0,
194,7,263,4,0,
194,7,281,1,31,
194,7,290,4,0,
194,7,291,4,0,
194,7,300,2,0,
194,7,341,1,16,
194,7,352,4,0,
194,8,21,1,15,
194,8,24,2,0,
194,8,34,2,0,
194,8,39,1,1,2
194,8,54,1,43,1
194,8,55,1,1,1
194,8,57,4,0,
194,8,58,4,0,
194,8,59,4,0,
194,8,68,2,0,
194,8,89,1,33,
194,8,89,4,0,
194,8,91,4,0,
194,8,92,4,0,
194,8,104,4,0,
194,8,114,1,43,2
194,8,127,4,0,
194,8,133,1,23,
194,8,148,4,0,
194,8,156,4,0,
194,8,164,4,0,
194,8,174,2,0,
194,8,182,4,0,
194,8,188,4,0,
194,8,201,4,0,
194,8,203,4,0,
194,8,207,4,0,
194,8,213,4,0,
194,8,214,4,0,
194,8,216,4,0,
194,8,218,4,0,
194,8,219,2,0,
194,8,227,2,0,
194,8,231,4,0,
194,8,237,4,0,
194,8,240,1,37,
194,8,240,4,0,
194,8,246,2,0,
194,8,249,4,0,
194,8,254,2,0,
194,8,255,2,0,
194,8,256,2,0,
194,8,258,4,0,
194,8,263,4,0,
194,8,281,1,29,
194,8,290,4,0,
194,8,300,1,5,
194,8,300,2,0,
194,8,330,1,47,
194,8,341,1,9,
194,8,352,4,0,
194,8,363,4,0,
194,8,426,1,19,
194,8,445,4,0,
194,9,8,3,0,
194,9,21,1,15,
194,9,24,2,0,
194,9,34,2,0,
194,9,39,1,1,2
194,9,54,1,43,1
194,9,55,1,1,1
194,9,57,4,0,
194,9,58,4,0,
194,9,59,4,0,
194,9,68,2,0,
194,9,89,1,33,
194,9,89,4,0,
194,9,91,4,0,
194,9,92,4,0,
194,9,104,4,0,
194,9,114,1,43,2
194,9,127,4,0,
194,9,133,1,23,
194,9,148,4,0,
194,9,156,4,0,
194,9,164,4,0,
194,9,173,3,0,
194,9,174,2,0,
194,9,182,4,0,
194,9,188,4,0,
194,9,189,3,0,
194,9,196,3,0,
194,9,201,4,0,
194,9,203,4,0,
194,9,205,3,0,
194,9,207,4,0,
194,9,213,4,0,
194,9,214,4,0,
194,9,216,4,0,
194,9,218,4,0,
194,9,219,2,0,
194,9,227,2,0,
194,9,231,4,0,
194,9,237,4,0,
194,9,240,1,37,
194,9,240,4,0,
194,9,246,2,0,
194,9,246,3,0,
194,9,249,4,0,
194,9,254,2,0,
194,9,255,2,0,
194,9,256,2,0,
194,9,258,4,0,
194,9,263,4,0,
194,9,281,1,29,
194,9,290,4,0,
194,9,291,3,0,
194,9,300,1,5,
194,9,300,2,0,
194,9,330,1,47,
194,9,341,1,9,
194,9,352,4,0,
194,9,363,4,0,
194,9,401,3,0,
194,9,414,3,0,
194,9,426,1,19,
194,9,445,4,0,
194,10,8,3,0,
194,10,21,1,15,
194,10,24,2,0,
194,10,29,3,0,
194,10,34,2,0,
194,10,39,1,1,2
194,10,54,1,43,1
194,10,55,1,1,1
194,10,57,4,0,
194,10,58,4,0,
194,10,59,4,0,
194,10,68,2,0,
194,10,89,1,33,
194,10,89,4,0,
194,10,91,4,0,
194,10,92,4,0,
194,10,104,4,0,
194,10,105,2,0,
194,10,114,1,43,2
194,10,127,4,0,
194,10,133,1,23,
194,10,148,4,0,
194,10,156,4,0,
194,10,164,4,0,
194,10,173,3,0,
194,10,174,2,0,
194,10,182,4,0,
194,10,188,4,0,
194,10,189,3,0,
194,10,196,3,0,
194,10,201,4,0,
194,10,203,4,0,
194,10,205,3,0,
194,10,207,4,0,
194,10,213,4,0,
194,10,214,4,0,
194,10,216,4,0,
194,10,218,4,0,
194,10,219,2,0,
194,10,227,2,0,
194,10,231,4,0,
194,10,237,4,0,
194,10,240,1,37,
194,10,240,4,0,
194,10,246,2,0,
194,10,246,3,0,
194,10,249,4,0,
194,10,250,4,0,
194,10,254,2,0,
194,10,255,2,0,
194,10,256,2,0,
194,10,258,4,0,
194,10,263,4,0,
194,10,281,1,29,
194,10,290,4,0,
194,10,291,3,0,
194,10,300,1,5,
194,10,300,2,0,
194,10,330,1,47,
194,10,341,1,9,
194,10,352,4,0,
194,10,363,4,0,
194,10,401,3,0,
194,10,414,3,0,
194,10,426,1,19,
194,10,445,4,0,
194,11,21,1,15,
194,11,24,2,0,
194,11,34,2,0,
194,11,39,1,1,2
194,11,54,1,43,1
194,11,55,1,1,1
194,11,57,4,0,
194,11,58,4,0,
194,11,59,4,0,
194,11,68,2,0,
194,11,89,1,33,
194,11,89,4,0,
194,11,91,4,0,
194,11,92,4,0,
194,11,104,4,0,
194,11,105,2,0,
194,11,114,1,43,2
194,11,127,4,0,
194,11,133,1,23,
194,11,148,4,0,
194,11,156,4,0,
194,11,164,4,0,
194,11,174,2,0,
194,11,182,4,0,
194,11,188,4,0,
194,11,201,4,0,
194,11,207,4,0,
194,11,213,4,0,
194,11,214,2,0,
194,11,216,4,0,
194,11,218,4,0,
194,11,219,4,0,
194,11,227,2,0,
194,11,237,4,0,
194,11,240,1,37,
194,11,240,4,0,
194,11,246,2,0,
194,11,249,4,0,
194,11,254,2,0,
194,11,255,2,0,
194,11,256,2,0,
194,11,258,4,0,
194,11,263,4,0,
194,11,281,1,29,
194,11,291,4,0,
194,11,300,1,5,
194,11,300,2,0,
194,11,330,1,47,
194,11,341,1,9,
194,11,426,1,19,
194,11,482,4,0,
194,11,491,2,0,
194,11,495,2,0,
194,11,496,4,0,
194,11,503,4,0,
194,11,523,4,0,
194,12,21,1,11,
194,12,39,1,1,2
194,12,54,1,51,
194,12,55,1,1,1
194,12,57,4,0,
194,12,58,4,0,
194,12,59,4,0,
194,12,89,1,36,
194,12,89,4,0,
194,12,91,4,0,
194,12,92,4,0,
194,12,104,4,0,
194,12,114,1,51,3
194,12,127,4,0,
194,12,133,1,21,
194,12,148,4,0,
194,12,156,4,0,
194,12,182,4,0,
194,12,188,4,0,
194,12,201,4,0,
194,12,213,4,0,
194,12,216,4,0,
194,12,218,4,0,
194,12,231,4,0,
194,12,237,4,0,
194,12,240,1,41,
194,12,240,4,0,
194,12,249,4,0,
194,12,258,4,0,
194,12,263,4,0,
194,12,281,1,31,
194,12,290,4,0,
194,12,291,4,0,
194,12,341,1,16,
194,12,352,4,0,
194,13,21,1,11,
194,13,34,3,0,
194,13,38,3,0,
194,13,39,1,1,2
194,13,54,1,51,
194,13,55,1,1,1
194,13,57,4,0,
194,13,58,4,0,
194,13,59,4,0,
194,13,89,1,36,
194,13,89,4,0,
194,13,91,4,0,
194,13,92,4,0,
194,13,102,3,0,
194,13,104,4,0,
194,13,114,1,51,3
194,13,127,4,0,
194,13,133,1,21,
194,13,148,4,0,
194,13,156,4,0,
194,13,164,3,0,
194,13,182,4,0,
194,13,188,4,0,
194,13,201,4,0,
194,13,207,3,0,
194,13,213,4,0,
194,13,216,4,0,
194,13,218,4,0,
194,13,231,4,0,
194,13,237,4,0,
194,13,240,1,41,
194,13,240,4,0,
194,13,249,4,0,
194,13,258,4,0,
194,13,263,4,0,
194,13,281,1,31,
194,13,290,4,0,
194,13,291,4,0,
194,13,341,1,16,
194,13,352,4,0,
194,14,8,3,0,
194,14,21,1,15,
194,14,24,2,0,
194,14,34,2,0,
194,14,39,1,1,2
194,14,54,1,43,1
194,14,55,1,1,1
194,14,57,4,0,
194,14,58,4,0,
194,14,59,4,0,
194,14,68,2,0,
194,14,89,1,33,
194,14,89,4,0,
194,14,91,4,0,
194,14,92,4,0,
194,14,104,4,0,
194,14,105,2,0,
194,14,114,1,43,2
194,14,127,4,0,
194,14,133,1,23,
194,14,148,4,0,
194,14,156,4,0,
194,14,164,4,0,
194,14,173,3,0,
194,14,174,2,0,
194,14,182,4,0,
194,14,188,4,0,
194,14,196,3,0,
194,14,201,4,0,
194,14,207,4,0,
194,14,213,4,0,
194,14,214,2,0,
194,14,214,3,0,
194,14,216,4,0,
194,14,218,4,0,
194,14,219,4,0,
194,14,227,2,0,
194,14,231,3,0,
194,14,237,4,0,
194,14,240,1,37,
194,14,240,4,0,
194,14,246,2,0,
194,14,249,4,0,
194,14,254,2,0,
194,14,255,2,0,
194,14,256,2,0,
194,14,258,4,0,
194,14,263,4,0,
194,14,281,1,29,
194,14,291,4,0,
194,14,300,1,5,
194,14,300,2,0,
194,14,330,1,47,
194,14,341,1,9,
194,14,401,3,0,
194,14,414,3,0,
194,14,426,1,19,
194,14,482,4,0,
194,14,491,2,0,
194,14,495,2,0,
194,14,495,3,0,
194,14,496,4,0,
194,14,503,4,0,
194,14,523,4,0,
194,15,21,1,15,
194,15,24,2,0,
194,15,34,2,0,
194,15,39,1,1,2
194,15,54,1,43,1
194,15,55,1,1,1
194,15,57,4,0,
194,15,58,4,0,
194,15,59,4,0,
194,15,68,2,0,
194,15,89,1,33,
194,15,89,4,0,
194,15,91,4,0,
194,15,92,4,0,
194,15,104,4,0,
194,15,105,2,0,
194,15,114,1,43,2
194,15,127,4,0,
194,15,133,1,23,
194,15,148,4,0,
194,15,156,4,0,
194,15,164,4,0,
194,15,174,2,0,
194,15,182,4,0,
194,15,188,4,0,
194,15,201,4,0,
194,15,207,4,0,
194,15,213,4,0,
194,15,214,2,0,
194,15,214,4,0,
194,15,216,4,0,
194,15,218,4,0,
194,15,219,4,0,
194,15,227,2,0,
194,15,237,4,0,
194,15,240,1,37,
194,15,240,4,0,
194,15,246,2,0,
194,15,249,4,0,
194,15,254,2,0,
194,15,255,2,0,
194,15,256,2,0,
194,15,258,4,0,
194,15,263,4,0,
194,15,281,1,29,
194,15,300,1,5,
194,15,300,2,0,
194,15,330,1,47,
194,15,341,1,9,
194,15,385,2,0,
194,15,426,1,19,
194,15,482,4,0,
194,15,491,2,0,
194,15,495,2,0,
194,15,496,4,0,
194,15,503,4,0,
194,15,523,4,0,
194,15,590,4,0,
194,15,598,2,0,
194,15,611,4,0,
194,16,8,3,0,
194,16,21,1,15,1
194,16,24,2,0,
194,16,34,2,0,
194,16,39,1,1,2
194,16,54,1,43,1
194,16,55,1,1,1
194,16,57,4,0,
194,16,58,4,0,
194,16,59,4,0,
194,16,68,2,0,
194,16,89,1,33,1
194,16,89,4,0,
194,16,91,4,0,
194,16,92,4,0,
194,16,104,4,0,
194,16,105,2,0,
194,16,114,1,43,2
194,16,127,4,0,
194,16,133,1,23,1
194,16,148,4,0,
194,16,156,4,0,
194,16,164,4,0,
194,16,173,3,0,
194,16,174,2,0,
194,16,182,4,0,
194,16,188,4,0,
194,16,196,3,0,
194,16,201,4,0,
194,16,207,4,0,
194,16,213,4,0,
194,16,214,2,0,
194,16,214,4,0,
194,16,216,4,0,
194,16,218,4,0,
194,16,219,4,0,
194,16,227,2,0,
194,16,231,3,0,
194,16,237,4,0,
194,16,240,1,37,1
194,16,240,4,0,
194,16,246,2,0,
194,16,249,4,0,
194,16,254,2,0,
194,16,255,2,0,
194,16,256,2,0,
194,16,258,4,0,
194,16,263,4,0,
194,16,281,1,29,1
194,16,290,4,0,
194,16,291,4,0,
194,16,300,1,5,1
194,16,300,2,0,
194,16,330,1,47,1
194,16,341,1,9,1
194,16,352,3,0,
194,16,385,2,0,
194,16,401,3,0,
194,16,414,3,0,
194,16,426,1,19,1
194,16,482,4,0,
194,16,491,2,0,
194,16,495,2,0,
194,16,495,3,0,
194,16,496,4,0,
194,16,503,4,0,
194,16,523,4,0,
194,16,590,4,0,
194,16,598,2,0,
194,16,611,4,0,
194,17,21,1,15,
194,17,24,2,0,
194,17,34,2,0,
194,17,39,1,1,2
194,17,54,1,43,1
194,17,55,1,1,1
194,17,57,4,0,
194,17,58,4,0,
194,17,59,4,0,
194,17,68,2,0,
194,17,89,1,33,
194,17,89,4,0,
194,17,92,4,0,
194,17,104,4,0,
194,17,105,2,0,
194,17,114,1,43,2
194,17,127,4,0,
194,17,133,1,23,
194,17,156,4,0,
194,17,164,4,0,
194,17,174,2,0,
194,17,182,4,0,
194,17,188,4,0,
194,17,201,4,0,
194,17,207,4,0,
194,17,213,4,0,
194,17,214,2,0,
194,17,214,4,0,
194,17,216,4,0,
194,17,218,4,0,
194,17,219,4,0,
194,17,227,2,0,
194,17,237,4,0,
194,17,240,1,37,
194,17,240,4,0,
194,17,246,2,0,
194,17,254,2,0,
194,17,255,2,0,
194,17,256,2,0,
194,17,258,4,0,
194,17,263,4,0,
194,17,281,1,29,
194,17,300,1,5,
194,17,300,2,0,
194,17,330,1,47,
194,17,341,1,9,
194,17,385,2,0,
194,17,426,1,19,
194,17,482,4,0,
194,17,491,2,0,
194,17,495,2,0,
194,17,496,4,0,
194,17,503,4,0,
194,17,523,4,0,
194,17,590,4,0,
194,17,598,2,0,
194,17,611,4,0,
194,18,21,1,15,
194,18,24,2,0,
194,18,34,2,0,
194,18,39,1,1,2
194,18,54,1,43,1
194,18,55,1,1,1
194,18,57,4,0,
194,18,58,4,0,
194,18,59,4,0,
194,18,68,2,0,
194,18,89,1,33,
194,18,89,4,0,
194,18,92,4,0,
194,18,104,4,0,
194,18,105,2,0,
194,18,114,1,43,2
194,18,127,4,0,
194,18,133,1,23,
194,18,156,4,0,
194,18,164,4,0,
194,18,174,2,0,
194,18,182,4,0,
194,18,188,4,0,
194,18,201,4,0,
194,18,207,4,0,
194,18,213,4,0,
194,18,214,2,0,
194,18,214,4,0,
194,18,216,4,0,
194,18,218,4,0,
194,18,219,4,0,
194,18,227,2,0,
194,18,237,4,0,
194,18,240,1,37,
194,18,240,4,0,
194,18,246,2,0,
194,18,254,2,0,
194,18,255,2,0,
194,18,256,2,0,
194,18,258,4,0,
194,18,263,4,0,
194,18,281,1,29,
194,18,300,1,5,
194,18,300,2,0,
194,18,330,1,47,
194,18,341,1,9,
194,18,385,2,0,
194,18,426,1,19,
194,18,482,4,0,
194,18,491,2,0,
194,18,495,2,0,
194,18,496,4,0,
194,18,503,4,0,
194,18,523,4,0,
194,18,590,4,0,
194,18,598,2,0,
194,18,611,4,0,
194,18,612,2,0,
195,3,8,4,0,
195,3,21,1,11,
195,3,29,4,0,
195,3,39,1,1,2
195,3,54,1,59,
195,3,55,1,1,1
195,3,57,4,0,
195,3,63,4,0,
195,3,70,4,0,
195,3,89,1,35,
195,3,89,4,0,
195,3,91,4,0,
195,3,92,4,0,
195,3,104,4,0,
195,3,111,4,0,
195,3,114,1,59,3
195,3,133,1,23,
195,3,148,4,0,
195,3,156,4,0,
195,3,173,4,0,
195,3,174,4,0,
195,3,182,4,0,
195,3,188,4,0,
195,3,189,4,0,
195,3,201,4,0,
195,3,203,4,0,
195,3,205,4,0,
195,3,207,4,0,
195,3,213,4,0,
195,3,214,4,0,
195,3,216,4,0,
195,3,218,4,0,
195,3,223,4,0,
195,3,231,4,0,
195,3,237,4,0,
195,3,240,1,47,
195,3,240,4,0,
195,3,249,4,0,
195,3,250,4,0,
195,4,8,4,0,
195,4,21,1,11,
195,4,29,4,0,
195,4,39,1,1,2
195,4,54,1,59,
195,4,55,1,1,1
195,4,57,4,0,
195,4,58,3,0,
195,4,63,4,0,
195,4,70,4,0,
195,4,89,1,35,
195,4,89,4,0,
195,4,91,4,0,
195,4,92,4,0,
195,4,104,4,0,
195,4,111,4,0,
195,4,114,1,59,3
195,4,133,1,23,
195,4,148,4,0,
195,4,156,4,0,
195,4,173,4,0,
195,4,174,4,0,
195,4,182,4,0,
195,4,188,4,0,
195,4,189,4,0,
195,4,201,4,0,
195,4,203,4,0,
195,4,205,4,0,
195,4,207,4,0,
195,4,213,4,0,
195,4,214,4,0,
195,4,216,4,0,
195,4,218,4,0,
195,4,223,4,0,
195,4,231,4,0,
195,4,237,4,0,
195,4,240,1,47,
195,4,240,4,0,
195,4,249,4,0,
195,4,250,4,0,
195,5,21,1,11,
195,5,39,1,1,2
195,5,54,1,61,
195,5,55,1,1,1
195,5,57,4,0,
195,5,58,4,0,
195,5,59,4,0,
195,5,63,4,0,
195,5,70,4,0,
195,5,89,1,42,
195,5,89,4,0,
195,5,91,4,0,
195,5,92,4,0,
195,5,104,4,0,
195,5,114,1,61,3
195,5,127,4,0,
195,5,133,1,23,
195,5,148,4,0,
195,5,156,4,0,
195,5,182,4,0,
195,5,188,4,0,
195,5,201,4,0,
195,5,213,4,0,
195,5,216,4,0,
195,5,218,4,0,
195,5,231,4,0,
195,5,237,4,0,
195,5,240,1,49,
195,5,240,4,0,
195,5,249,4,0,
195,5,258,4,0,
195,5,263,4,0,
195,5,264,4,0,
195,5,280,4,0,
195,5,281,1,35,
195,5,290,4,0,
195,5,291,4,0,
195,5,317,4,0,
195,5,341,1,16,
195,5,352,4,0,
195,6,5,3,0,
195,6,8,3,0,
195,6,21,1,11,
195,6,25,3,0,
195,6,34,3,0,
195,6,38,3,0,
195,6,39,1,1,2
195,6,54,1,61,
195,6,55,1,1,1
195,6,57,4,0,
195,6,58,4,0,
195,6,59,4,0,
195,6,63,4,0,
195,6,68,3,0,
195,6,69,3,0,
195,6,70,4,0,
195,6,89,1,42,
195,6,89,4,0,
195,6,91,4,0,
195,6,92,4,0,
195,6,102,3,0,
195,6,104,4,0,
195,6,111,3,0,
195,6,114,1,61,3
195,6,127,4,0,
195,6,133,1,23,
195,6,148,4,0,
195,6,156,4,0,
195,6,164,3,0,
195,6,173,3,0,
195,6,182,4,0,
195,6,188,4,0,
195,6,189,3,0,
195,6,201,4,0,
195,6,203,3,0,
195,6,205,3,0,
195,6,207,3,0,
195,6,213,4,0,
195,6,214,3,0,
195,6,216,4,0,
195,6,218,4,0,
195,6,223,3,0,
195,6,231,4,0,
195,6,237,4,0,
195,6,240,1,49,
195,6,240,4,0,
195,6,249,4,0,
195,6,258,4,0,
195,6,263,4,0,
195,6,264,4,0,
195,6,280,4,0,
195,6,281,1,35,
195,6,290,4,0,
195,6,291,4,0,
195,6,317,4,0,
195,6,341,1,16,
195,6,352,4,0,
195,7,5,3,0,
195,7,21,1,11,
195,7,25,3,0,
195,7,34,3,0,
195,7,38,3,0,
195,7,39,1,1,2
195,7,54,1,61,
195,7,55,1,1,1
195,7,57,4,0,
195,7,58,4,0,
195,7,59,4,0,
195,7,63,4,0,
195,7,68,3,0,
195,7,69,3,0,
195,7,70,4,0,
195,7,89,1,42,
195,7,89,4,0,
195,7,91,4,0,
195,7,92,4,0,
195,7,102,3,0,
195,7,104,4,0,
195,7,114,1,61,3
195,7,127,4,0,
195,7,133,1,23,
195,7,148,4,0,
195,7,156,4,0,
195,7,164,3,0,
195,7,182,4,0,
195,7,188,4,0,
195,7,201,4,0,
195,7,213,4,0,
195,7,216,4,0,
195,7,218,4,0,
195,7,231,4,0,
195,7,237,4,0,
195,7,240,1,49,
195,7,240,4,0,
195,7,249,4,0,
195,7,258,4,0,
195,7,263,4,0,
195,7,264,4,0,
195,7,280,4,0,
195,7,281,1,35,
195,7,290,4,0,
195,7,291,4,0,
195,7,317,4,0,
195,7,341,1,16,
195,7,352,4,0,
195,8,21,1,15,
195,8,39,1,1,2
195,8,54,1,48,1
195,8,55,1,1,1
195,8,57,4,0,
195,8,58,4,0,
195,8,59,4,0,
195,8,63,4,0,
195,8,70,4,0,
195,8,89,1,36,
195,8,89,4,0,
195,8,91,4,0,
195,8,92,4,0,
195,8,104,4,0,
195,8,114,1,48,2
195,8,127,4,0,
195,8,133,1,24,
195,8,148,4,0,
195,8,156,4,0,
195,8,157,4,0,
195,8,164,4,0,
195,8,182,4,0,
195,8,188,4,0,
195,8,201,4,0,
195,8,203,4,0,
195,8,207,4,0,
195,8,213,4,0,
195,8,214,4,0,
195,8,216,4,0,
195,8,218,4,0,
195,8,231,4,0,
195,8,237,4,0,
195,8,240,1,41,
195,8,240,4,0,
195,8,249,4,0,
195,8,258,4,0,
195,8,263,4,0,
195,8,264,4,0,
195,8,280,4,0,
195,8,281,1,31,
195,8,290,4,0,
195,8,300,1,1,3
195,8,300,1,5,
195,8,317,4,0,
195,8,330,1,53,
195,8,341,1,9,
195,8,352,4,0,
195,8,363,4,0,
195,8,374,4,0,
195,8,411,4,0,
195,8,416,4,0,
195,8,426,1,19,
195,8,444,4,0,
195,8,445,4,0,
195,9,8,3,0,
195,9,21,1,15,
195,9,39,1,1,2
195,9,54,1,48,1
195,9,55,1,1,1
195,9,57,4,0,
195,9,58,4,0,
195,9,59,4,0,
195,9,63,4,0,
195,9,70,4,0,
195,9,89,1,36,
195,9,89,4,0,
195,9,91,4,0,
195,9,92,4,0,
195,9,104,4,0,
195,9,114,1,48,2
195,9,127,4,0,
195,9,133,1,24,
195,9,148,4,0,
195,9,156,4,0,
195,9,157,4,0,
195,9,164,4,0,
195,9,173,3,0,
195,9,182,4,0,
195,9,188,4,0,
195,9,189,3,0,
195,9,196,3,0,
195,9,201,4,0,
195,9,203,4,0,
195,9,205,3,0,
195,9,207,4,0,
195,9,213,4,0,
195,9,214,4,0,
195,9,216,4,0,
195,9,218,4,0,
195,9,231,4,0,
195,9,237,4,0,
195,9,240,1,41,
195,9,240,4,0,
195,9,246,3,0,
195,9,249,4,0,
195,9,258,4,0,
195,9,263,4,0,
195,9,264,4,0,
195,9,280,4,0,
195,9,281,1,31,
195,9,290,4,0,
195,9,291,3,0,
195,9,300,1,1,3
195,9,300,1,5,
195,9,317,4,0,
195,9,330,1,53,
195,9,341,1,9,
195,9,352,4,0,
195,9,363,4,0,
195,9,374,4,0,
195,9,401,3,0,
195,9,411,4,0,
195,9,414,3,0,
195,9,416,4,0,
195,9,426,1,19,
195,9,444,4,0,
195,9,445,4,0,
195,10,8,3,0,
195,10,21,1,15,
195,10,29,3,0,
195,10,39,1,1,2
195,10,54,1,48,1
195,10,55,1,1,1
195,10,57,4,0,
195,10,58,4,0,
195,10,59,4,0,
195,10,63,4,0,
195,10,70,4,0,
195,10,89,1,36,
195,10,89,4,0,
195,10,91,4,0,
195,10,92,4,0,
195,10,104,4,0,
195,10,114,1,48,2
195,10,127,4,0,
195,10,133,1,24,
195,10,148,4,0,
195,10,156,4,0,
195,10,157,4,0,
195,10,164,4,0,
195,10,173,3,0,
195,10,182,4,0,
195,10,188,4,0,
195,10,189,3,0,
195,10,196,3,0,
195,10,201,4,0,
195,10,203,4,0,
195,10,205,3,0,
195,10,207,4,0,
195,10,213,4,0,
195,10,214,4,0,
195,10,216,4,0,
195,10,218,4,0,
195,10,231,4,0,
195,10,237,4,0,
195,10,240,1,41,
195,10,240,4,0,
195,10,246,3,0,
195,10,249,4,0,
195,10,250,4,0,
195,10,258,4,0,
195,10,263,4,0,
195,10,264,4,0,
195,10,280,4,0,
195,10,281,1,31,
195,10,290,4,0,
195,10,291,3,0,
195,10,300,1,1,3
195,10,300,1,5,
195,10,317,4,0,
195,10,330,1,53,
195,10,341,1,9,
195,10,352,4,0,
195,10,363,4,0,
195,10,374,4,0,
195,10,401,3,0,
195,10,411,4,0,
195,10,414,3,0,
195,10,416,4,0,
195,10,426,1,19,
195,10,444,4,0,
195,10,445,4,0,
195,11,21,1,15,
195,11,39,1,1,2
195,11,54,1,48,1
195,11,55,1,1,1
195,11,57,4,0,
195,11,58,4,0,
195,11,59,4,0,
195,11,63,4,0,
195,11,70,4,0,
195,11,89,1,36,
195,11,89,4,0,
195,11,91,4,0,
195,11,92,4,0,
195,11,104,4,0,
195,11,114,1,48,2
195,11,127,4,0,
195,11,133,1,24,
195,11,148,4,0,
195,11,156,4,0,
195,11,157,4,0,
195,11,164,4,0,
195,11,168,4,0,
195,11,182,4,0,
195,11,188,4,0,
195,11,201,4,0,
195,11,207,4,0,
195,11,213,4,0,
195,11,216,4,0,
195,11,218,4,0,
195,11,219,4,0,
195,11,237,4,0,
195,11,240,1,41,
195,11,240,4,0,
195,11,249,4,0,
195,11,258,4,0,
195,11,263,4,0,
195,11,280,4,0,
195,11,281,1,31,
195,11,291,4,0,
195,11,300,1,1,3
195,11,300,1,5,
195,11,317,4,0,
195,11,330,1,53,
195,11,341,1,9,
195,11,374,4,0,
195,11,411,4,0,
195,11,416,4,0,
195,11,426,1,19,
195,11,444,4,0,
195,11,482,4,0,
195,11,496,4,0,
195,11,503,4,0,
195,11,523,4,0,
195,12,21,1,11,
195,12,39,1,1,2
195,12,54,1,61,
195,12,55,1,1,1
195,12,57,4,0,
195,12,58,4,0,
195,12,59,4,0,
195,12,63,4,0,
195,12,70,4,0,
195,12,89,1,42,
195,12,89,4,0,
195,12,91,4,0,
195,12,92,4,0,
195,12,104,4,0,
195,12,114,1,61,3
195,12,127,4,0,
195,12,133,1,23,
195,12,148,4,0,
195,12,156,4,0,
195,12,182,4,0,
195,12,188,4,0,
195,12,201,4,0,
195,12,213,4,0,
195,12,216,4,0,
195,12,218,4,0,
195,12,231,4,0,
195,12,237,4,0,
195,12,240,1,49,
195,12,240,4,0,
195,12,249,4,0,
195,12,258,4,0,
195,12,263,4,0,
195,12,264,4,0,
195,12,280,4,0,
195,12,281,1,35,
195,12,290,4,0,
195,12,291,4,0,
195,12,317,4,0,
195,12,341,1,16,
195,12,352,4,0,
195,13,21,1,11,
195,13,34,3,0,
195,13,38,3,0,
195,13,39,1,1,2
195,13,54,1,61,
195,13,55,1,1,1
195,13,57,4,0,
195,13,58,4,0,
195,13,59,4,0,
195,13,63,4,0,
195,13,69,3,0,
195,13,70,4,0,
195,13,89,1,42,
195,13,89,4,0,
195,13,91,4,0,
195,13,92,4,0,
195,13,102,3,0,
195,13,104,4,0,
195,13,114,1,61,3
195,13,127,4,0,
195,13,133,1,23,
195,13,148,4,0,
195,13,156,4,0,
195,13,164,3,0,
195,13,182,4,0,
195,13,188,4,0,
195,13,201,4,0,
195,13,207,3,0,
195,13,213,4,0,
195,13,216,4,0,
195,13,218,4,0,
195,13,231,4,0,
195,13,237,4,0,
195,13,240,1,49,
195,13,240,4,0,
195,13,249,4,0,
195,13,258,4,0,
195,13,263,4,0,
195,13,264,4,0,
195,13,280,4,0,
195,13,281,1,35,
195,13,290,4,0,
195,13,291,4,0,
195,13,317,4,0,
195,13,341,1,16,
195,13,352,4,0,
195,14,8,3,0,
195,14,21,1,15,
195,14,39,1,1,2
195,14,54,1,48,1
195,14,55,1,1,1
195,14,57,4,0,
195,14,58,4,0,
195,14,59,4,0,
195,14,63,4,0,
195,14,70,4,0,
195,14,89,1,36,
195,14,89,4,0,
195,14,91,4,0,
195,14,92,4,0,
195,14,104,4,0,
195,14,114,1,48,2
195,14,127,4,0,
195,14,133,1,24,
195,14,148,4,0,
195,14,156,4,0,
195,14,157,4,0,
195,14,164,4,0,
195,14,168,4,0,
195,14,173,3,0,
195,14,182,4,0,
195,14,188,4,0,
195,14,196,3,0,
195,14,201,4,0,
195,14,207,4,0,
195,14,213,4,0,
195,14,214,3,0,
195,14,216,4,0,
195,14,218,4,0,
195,14,219,4,0,
195,14,231,3,0,
195,14,237,4,0,
195,14,240,1,41,
195,14,240,4,0,
195,14,249,4,0,
195,14,258,4,0,
195,14,263,4,0,
195,14,280,4,0,
195,14,281,1,31,
195,14,291,4,0,
195,14,300,1,1,3
195,14,300,1,5,
195,14,317,4,0,
195,14,330,1,53,
195,14,341,1,9,
195,14,374,4,0,
195,14,401,3,0,
195,14,411,4,0,
195,14,414,3,0,
195,14,416,4,0,
195,14,426,1,19,
195,14,444,4,0,
195,14,482,4,0,
195,14,495,3,0,
195,14,496,4,0,
195,14,503,4,0,
195,14,523,4,0,
195,15,21,1,15,
195,15,39,1,1,2
195,15,54,1,48,1
195,15,55,1,1,1
195,15,57,4,0,
195,15,58,4,0,
195,15,59,4,0,
195,15,63,4,0,
195,15,70,4,0,
195,15,89,1,36,
195,15,89,4,0,
195,15,91,4,0,
195,15,92,4,0,
195,15,104,4,0,
195,15,114,1,48,2
195,15,127,4,0,
195,15,133,1,24,
195,15,148,4,0,
195,15,156,4,0,
195,15,157,4,0,
195,15,164,4,0,
195,15,168,4,0,
195,15,182,4,0,
195,15,188,4,0,
195,15,201,4,0,
195,15,207,4,0,
195,15,213,4,0,
195,15,214,4,0,
195,15,216,4,0,
195,15,218,4,0,
195,15,219,4,0,
195,15,237,4,0,
195,15,240,1,41,
195,15,240,4,0,
195,15,249,4,0,
195,15,258,4,0,
195,15,263,4,0,
195,15,280,4,0,
195,15,281,1,31,
195,15,300,1,1,3
195,15,300,1,5,
195,15,317,4,0,
195,15,330,1,53,
195,15,341,1,9,
195,15,374,4,0,
195,15,411,4,0,
195,15,416,4,0,
195,15,426,1,19,
195,15,444,4,0,
195,15,482,4,0,
195,15,496,4,0,
195,15,503,4,0,
195,15,523,4,0,
195,15,590,4,0,
195,15,611,4,0,
195,15,612,4,0,
195,16,8,3,0,
195,16,21,1,15,1
195,16,39,1,1,2
195,16,54,1,48,1
195,16,55,1,1,1
195,16,57,4,0,
195,16,58,4,0,
195,16,59,4,0,
195,16,63,4,0,
195,16,70,4,0,
195,16,89,1,36,1
195,16,89,4,0,
195,16,91,4,0,
195,16,92,4,0,
195,16,104,4,0,
195,16,114,1,48,2
195,16,127,4,0,
195,16,133,1,24,1
195,16,148,4,0,
195,16,156,4,0,
195,16,157,4,0,
195,16,164,4,0,
195,16,168,4,0,
195,16,173,3,0,
195,16,182,4,0,
195,16,188,4,0,
195,16,196,3,0,
195,16,201,4,0,
195,16,207,4,0,
195,16,213,4,0,
195,16,214,4,0,
195,16,216,4,0,
195,16,218,4,0,
195,16,219,4,0,
195,16,231,3,0,
195,16,237,4,0,
195,16,240,1,41,1
195,16,240,4,0,
195,16,249,4,0,
195,16,258,4,0,
195,16,263,4,0,
195,16,264,3,0,
195,16,280,4,0,
195,16,281,1,31,1
195,16,290,4,0,
195,16,291,4,0,
195,16,300,1,1,3
195,16,300,1,5,1
195,16,317,4,0,
195,16,330,1,53,1
195,16,341,1,9,1
195,16,352,3,0,
195,16,374,4,0,
195,16,401,3,0,
195,16,411,4,0,
195,16,414,3,0,
195,16,416,4,0,
195,16,426,1,19,1
195,16,444,4,0,
195,16,482,4,0,
195,16,495,3,0,
195,16,496,4,0,
195,16,503,4,0,
195,16,523,4,0,
195,16,590,4,0,
195,16,611,4,0,
195,16,612,4,0,
195,17,21,1,15,
195,17,39,1,1,2
195,17,54,1,48,1
195,17,55,1,1,1
195,17,57,4,0,
195,17,58,4,0,
195,17,59,4,0,
195,17,63,4,0,
195,17,89,1,36,
195,17,89,4,0,
195,17,92,4,0,
195,17,104,4,0,
195,17,114,1,48,2
195,17,127,4,0,
195,17,133,1,24,
195,17,156,4,0,
195,17,157,4,0,
195,17,164,4,0,
195,17,168,4,0,
195,17,182,4,0,
195,17,188,4,0,
195,17,201,4,0,
195,17,207,4,0,
195,17,213,4,0,
195,17,214,4,0,
195,17,216,4,0,
195,17,218,4,0,
195,17,219,4,0,
195,17,237,4,0,
195,17,240,1,41,
195,17,240,4,0,
195,17,258,4,0,
195,17,263,4,0,
195,17,280,4,0,
195,17,281,1,31,
195,17,300,1,1,3
195,17,300,1,5,
195,17,317,4,0,
195,17,330,1,53,
195,17,341,1,9,
195,17,374,4,0,
195,17,411,4,0,
195,17,416,4,0,
195,17,426,1,19,
195,17,444,4,0,
195,17,482,4,0,
195,17,496,4,0,
195,17,503,4,0,
195,17,523,4,0,
195,17,590,4,0,
195,17,611,4,0,
195,18,21,1,15,
195,18,39,1,1,2
195,18,54,1,48,1
195,18,55,1,1,1
195,18,57,4,0,
195,18,58,4,0,
195,18,59,4,0,
195,18,63,4,0,
195,18,89,1,36,
195,18,89,4,0,
195,18,92,4,0,
195,18,104,4,0,
195,18,114,1,48,2
195,18,127,4,0,
195,18,133,1,24,
195,18,156,4,0,
195,18,157,4,0,
195,18,164,4,0,
195,18,168,4,0,
195,18,182,4,0,
195,18,188,4,0,
195,18,201,4,0,
195,18,207,4,0,
195,18,213,4,0,
195,18,214,4,0,
195,18,216,4,0,
195,18,218,4,0,
195,18,219,4,0,
195,18,237,4,0,
195,18,240,1,41,
195,18,240,4,0,
195,18,258,4,0,
195,18,263,4,0,
195,18,280,4,0,
195,18,281,1,31,
195,18,300,1,1,3
195,18,300,1,5,
195,18,317,4,0,
195,18,330,1,53,
195,18,341,1,9,
195,18,374,4,0,
195,18,411,4,0,
195,18,416,4,0,
195,18,426,1,19,
195,18,444,4,0,
195,18,482,4,0,
195,18,496,4,0,
195,18,503,4,0,
195,18,523,4,0,
195,18,590,4,0,
195,18,611,4,0,
196,3,15,4,0,
196,3,28,1,8,
196,3,29,4,0,
196,3,33,1,1,1
196,3,39,1,1,2
196,3,60,1,36,
196,3,63,4,0,
196,3,92,4,0,
196,3,93,1,16,
196,3,94,1,47,
196,3,94,4,0,
196,3,98,1,23,
196,3,104,4,0,
196,3,129,1,30,
196,3,129,4,0,
196,3,138,4,0,
196,3,148,4,0,
196,3,156,4,0,
196,3,171,4,0,
196,3,173,4,0,
196,3,174,4,0,
196,3,182,4,0,
196,3,189,4,0,
196,3,192,4,0,
196,3,197,4,0,
196,3,203,4,0,
196,3,207,4,0,
196,3,213,4,0,
196,3,214,4,0,
196,3,216,4,0,
196,3,218,4,0,
196,3,231,4,0,
196,3,234,1,52,
196,3,237,4,0,
196,3,240,4,0,
196,3,241,4,0,
196,3,244,1,42,
196,3,244,4,0,
196,3,247,4,0,
196,4,15,4,0,
196,4,28,1,8,
196,4,29,4,0,
196,4,33,1,1,1
196,4,39,1,1,2
196,4,60,1,36,
196,4,63,4,0,
196,4,92,4,0,
196,4,93,1,16,
196,4,94,1,47,
196,4,94,4,0,
196,4,98,1,23,
196,4,104,4,0,
196,4,129,1,30,
196,4,129,4,0,
196,4,138,4,0,
196,4,148,4,0,
196,4,156,4,0,
196,4,171,4,0,
196,4,173,4,0,
196,4,174,4,0,
196,4,182,4,0,
196,4,189,4,0,
196,4,192,4,0,
196,4,197,4,0,
196,4,203,4,0,
196,4,207,4,0,
196,4,213,4,0,
196,4,214,4,0,
196,4,216,4,0,
196,4,218,4,0,
196,4,231,4,0,
196,4,234,1,52,
196,4,237,4,0,
196,4,240,4,0,
196,4,241,4,0,
196,4,244,1,42,
196,4,244,4,0,
196,4,247,4,0,
196,5,15,4,0,
196,5,28,1,8,
196,5,33,1,1,1
196,5,39,1,1,2
196,5,60,1,36,
196,5,63,4,0,
196,5,91,4,0,
196,5,92,4,0,
196,5,93,1,16,
196,5,94,1,47,
196,5,94,4,0,
196,5,98,1,23,
196,5,104,4,0,
196,5,113,4,0,
196,5,115,4,0,
196,5,129,1,30,
196,5,148,4,0,
196,5,156,4,0,
196,5,182,4,0,
196,5,213,4,0,
196,5,216,4,0,
196,5,218,4,0,
196,5,231,4,0,
196,5,234,1,52,
196,5,237,4,0,
196,5,240,4,0,
196,5,241,4,0,
196,5,244,1,42,
196,5,247,4,0,
196,5,263,4,0,
196,5,270,1,1,3
196,5,285,4,0,
196,5,290,4,0,
196,5,347,4,0,
196,6,15,4,0,
196,6,28,1,8,
196,6,33,1,1,1
196,6,34,3,0,
196,6,38,3,0,
196,6,39,1,1,2
196,6,60,1,36,
196,6,63,4,0,
196,6,91,4,0,
196,6,92,4,0,
196,6,93,1,16,
196,6,94,1,47,
196,6,94,4,0,
196,6,98,1,23,
196,6,102,3,0,
196,6,104,4,0,
196,6,113,4,0,
196,6,115,4,0,
196,6,129,1,30,
196,6,129,3,0,
196,6,138,3,0,
196,6,148,4,0,
196,6,156,4,0,
196,6,164,3,0,
196,6,173,3,0,
196,6,182,4,0,
196,6,189,3,0,
196,6,203,3,0,
196,6,207,3,0,
196,6,213,4,0,
196,6,214,3,0,
196,6,216,4,0,
196,6,218,4,0,
196,6,231,4,0,
196,6,234,1,52,
196,6,237,4,0,
196,6,240,4,0,
196,6,241,4,0,
196,6,244,1,42,
196,6,244,3,0,
196,6,247,4,0,
196,6,263,4,0,
196,6,270,1,1,3
196,6,285,4,0,
196,6,290,4,0,
196,6,347,4,0,
196,7,15,4,0,
196,7,28,1,8,
196,7,33,1,1,1
196,7,34,3,0,
196,7,38,3,0,
196,7,39,1,1,2
196,7,60,1,36,
196,7,63,4,0,
196,7,91,4,0,
196,7,92,4,0,
196,7,93,1,16,
196,7,94,1,47,
196,7,94,4,0,
196,7,98,1,23,
196,7,102,3,0,
196,7,104,4,0,
196,7,113,4,0,
196,7,115,4,0,
196,7,129,1,30,
196,7,138,3,0,
196,7,148,4,0,
196,7,156,4,0,
196,7,164,3,0,
196,7,182,4,0,
196,7,213,4,0,
196,7,216,4,0,
196,7,218,4,0,
196,7,231,4,0,
196,7,234,1,52,
196,7,237,4,0,
196,7,240,4,0,
196,7,241,4,0,
196,7,244,1,42,
196,7,247,4,0,
196,7,263,4,0,
196,7,270,1,1,3
196,7,285,4,0,
196,7,290,4,0,
196,7,347,4,0,
196,8,15,4,0,
196,8,28,1,8,
196,8,33,1,1,1
196,8,39,1,1,2
196,8,60,1,36,
196,8,63,4,0,
196,8,91,4,0,
196,8,92,4,0,
196,8,93,1,15,
196,8,94,1,64,
196,8,94,4,0,
196,8,98,1,22,
196,8,104,4,0,
196,8,113,4,0,
196,8,115,4,0,
196,8,129,1,29,
196,8,138,4,0,
196,8,148,4,0,
196,8,156,4,0,
196,8,164,4,0,
196,8,182,4,0,
196,8,203,4,0,
196,8,207,4,0,
196,8,213,4,0,
196,8,214,4,0,
196,8,216,4,0,
196,8,218,4,0,
196,8,231,4,0,
196,8,234,1,71,
196,8,237,4,0,
196,8,240,4,0,
196,8,241,4,0,
196,8,244,1,57,
196,8,244,4,0,
196,8,247,4,0,
196,8,248,1,43,
196,8,263,4,0,
196,8,270,1,1,3
196,8,285,4,0,
196,8,290,4,0,
196,8,347,4,0,
196,8,363,4,0,
196,8,387,1,50,
196,8,416,4,0,
196,8,433,4,0,
196,8,445,4,0,
196,8,447,4,0,
196,9,15,4,0,
196,9,28,1,8,
196,9,33,1,1,2
196,9,39,1,1,1
196,9,60,1,36,
196,9,63,4,0,
196,9,91,4,0,
196,9,92,4,0,
196,9,93,1,15,
196,9,94,1,64,
196,9,94,4,0,
196,9,98,1,22,
196,9,104,4,0,
196,9,113,4,0,
196,9,115,4,0,
196,9,129,1,29,
196,9,129,3,0,
196,9,138,4,0,
196,9,148,4,0,
196,9,156,4,0,
196,9,164,4,0,
196,9,173,3,0,
196,9,182,4,0,
196,9,189,3,0,
196,9,203,4,0,
196,9,207,4,0,
196,9,213,4,0,
196,9,214,4,0,
196,9,216,4,0,
196,9,218,4,0,
196,9,231,4,0,
196,9,234,1,71,
196,9,237,4,0,
196,9,240,4,0,
196,9,241,4,0,
196,9,244,1,57,
196,9,244,4,0,
196,9,247,4,0,
196,9,248,1,43,
196,9,263,4,0,
196,9,270,1,1,3
196,9,270,3,0,
196,9,271,3,0,
196,9,285,4,0,
196,9,290,4,0,
196,9,324,3,0,
196,9,347,4,0,
196,9,363,4,0,
196,9,384,1,78,
196,9,387,1,50,
196,9,387,3,0,
196,9,416,4,0,
196,9,428,3,0,
196,9,433,4,0,
196,9,445,4,0,
196,9,447,4,0,
196,10,15,4,0,
196,10,28,1,8,
196,10,29,3,0,
196,10,33,1,1,2
196,10,39,1,1,1
196,10,60,1,36,
196,10,63,4,0,
196,10,91,4,0,
196,10,92,4,0,
196,10,93,1,15,
196,10,94,1,64,
196,10,94,4,0,
196,10,98,1,22,
196,10,104,4,0,
196,10,113,4,0,
196,10,115,4,0,
196,10,129,1,29,
196,10,129,3,0,
196,10,138,4,0,
196,10,148,4,0,
196,10,156,4,0,
196,10,164,4,0,
196,10,173,3,0,
196,10,182,4,0,
196,10,189,3,0,
196,10,203,4,0,
196,10,207,4,0,
196,10,213,4,0,
196,10,214,4,0,
196,10,215,3,0,
196,10,216,4,0,
196,10,218,4,0,
196,10,231,4,0,
196,10,234,1,71,
196,10,237,4,0,
196,10,240,4,0,
196,10,241,4,0,
196,10,244,1,57,
196,10,244,4,0,
196,10,247,4,0,
196,10,248,1,43,
196,10,263,4,0,
196,10,270,1,1,3
196,10,270,3,0,
196,10,271,3,0,
196,10,277,3,0,
196,10,285,4,0,
196,10,290,4,0,
196,10,324,3,0,
196,10,347,4,0,
196,10,363,4,0,
196,10,384,1,78,
196,10,387,1,50,
196,10,387,3,0,
196,10,416,4,0,
196,10,428,3,0,
196,10,433,4,0,
196,10,445,4,0,
196,10,447,4,0,
196,11,15,4,0,
196,11,28,1,8,
196,11,33,1,1,2
196,11,39,1,1,1
196,11,60,1,36,
196,11,63,4,0,
196,11,91,4,0,
196,11,92,4,0,
196,11,93,1,15,
196,11,94,1,64,
196,11,94,4,0,
196,11,98,1,22,
196,11,104,4,0,
196,11,113,4,0,
196,11,115,4,0,
196,11,129,1,29,
196,11,138,4,0,
196,11,148,4,0,
196,11,156,4,0,
196,11,164,4,0,
196,11,182,4,0,
196,11,207,4,0,
196,11,213,4,0,
196,11,216,4,0,
196,11,218,4,0,
196,11,234,1,71,
196,11,237,4,0,
196,11,240,4,0,
196,11,241,4,0,
196,11,244,1,57,
196,11,244,4,0,
196,11,247,4,0,
196,11,248,1,43,
196,11,263,4,0,
196,11,270,1,1,3
196,11,347,4,0,
196,11,384,1,78,
196,11,387,1,50,
196,11,416,4,0,
196,11,433,4,0,
196,11,447,4,0,
196,11,473,4,0,
196,11,477,4,0,
196,11,496,4,0,
196,11,497,4,0,
196,11,514,4,0,
196,11,526,4,0,
196,12,15,4,0,
196,12,28,1,8,
196,12,33,1,1,1
196,12,39,1,1,2
196,12,60,1,36,
196,12,63,4,0,
196,12,91,4,0,
196,12,92,4,0,
196,12,93,1,16,
196,12,94,1,47,
196,12,94,4,0,
196,12,98,1,23,
196,12,104,4,0,
196,12,113,4,0,
196,12,115,4,0,
196,12,129,1,30,
196,12,148,4,0,
196,12,156,4,0,
196,12,182,4,0,
196,12,213,4,0,
196,12,216,4,0,
196,12,218,4,0,
196,12,231,4,0,
196,12,234,1,52,
196,12,237,4,0,
196,12,240,4,0,
196,12,241,4,0,
196,12,244,1,42,
196,12,247,4,0,
196,12,263,4,0,
196,12,270,1,1,3
196,12,285,4,0,
196,12,290,4,0,
196,12,347,4,0,
196,13,15,4,0,
196,13,28,1,8,
196,13,33,1,1,1
196,13,34,3,0,
196,13,38,3,0,
196,13,39,1,1,2
196,13,60,1,36,
196,13,63,4,0,
196,13,91,4,0,
196,13,92,4,0,
196,13,93,1,16,
196,13,94,1,47,
196,13,94,4,0,
196,13,98,1,23,
196,13,102,3,0,
196,13,104,4,0,
196,13,113,4,0,
196,13,115,4,0,
196,13,129,1,30,
196,13,138,3,0,
196,13,148,4,0,
196,13,156,4,0,
196,13,164,3,0,
196,13,171,3,0,
196,13,182,4,0,
196,13,207,3,0,
196,13,213,4,0,
196,13,216,4,0,
196,13,218,4,0,
196,13,231,4,0,
196,13,234,1,52,
196,13,237,4,0,
196,13,240,4,0,
196,13,241,4,0,
196,13,244,1,42,
196,13,247,4,0,
196,13,263,4,0,
196,13,270,1,1,3
196,13,285,4,0,
196,13,290,4,0,
196,13,347,4,0,
196,14,15,4,0,
196,14,28,1,5,
196,14,33,1,1,2
196,14,39,1,1,3
196,14,60,1,21,
196,14,63,4,0,
196,14,91,4,0,
196,14,92,4,0,
196,14,93,1,9,
196,14,94,1,37,
196,14,94,4,0,
196,14,98,1,13,
196,14,104,4,0,
196,14,113,4,0,
196,14,115,4,0,
196,14,129,1,17,
196,14,138,4,0,
196,14,148,4,0,
196,14,156,4,0,
196,14,164,4,0,
196,14,173,3,0,
196,14,182,4,0,
196,14,207,4,0,
196,14,213,4,0,
196,14,214,3,0,
196,14,215,3,0,
196,14,216,4,0,
196,14,218,4,0,
196,14,231,3,0,
196,14,234,1,33,
196,14,237,4,0,
196,14,240,4,0,
196,14,241,4,0,
196,14,244,1,29,
196,14,244,4,0,
196,14,247,4,0,
196,14,248,1,25,
196,14,263,4,0,
196,14,270,1,1,1
196,14,270,3,0,
196,14,271,3,0,
196,14,277,3,0,
196,14,285,3,0,
196,14,304,3,0,
196,14,324,3,0,
196,14,343,3,0,
196,14,347,4,0,
196,14,384,1,45,
196,14,387,1,41,
196,14,387,3,0,
196,14,416,4,0,
196,14,428,3,0,
196,14,433,4,0,
196,14,447,4,0,
196,14,473,4,0,
196,14,477,4,0,
196,14,478,3,0,
196,14,496,4,0,
196,14,497,4,0,
196,14,514,4,0,
196,14,526,4,0,
196,15,15,4,0,
196,15,28,1,5,
196,15,33,1,1,2
196,15,39,1,1,3
196,15,60,1,20,
196,15,63,4,0,
196,15,91,4,0,
196,15,92,4,0,
196,15,93,1,9,
196,15,94,1,37,
196,15,94,4,0,
196,15,98,1,13,
196,15,104,4,0,
196,15,113,4,0,
196,15,115,4,0,
196,15,129,1,17,
196,15,138,4,0,
196,15,148,4,0,
196,15,156,4,0,
196,15,164,4,0,
196,15,182,4,0,
196,15,207,4,0,
196,15,213,4,0,
196,15,214,4,0,
196,15,216,4,0,
196,15,218,4,0,
196,15,234,1,33,
196,15,237,4,0,
196,15,240,4,0,
196,15,241,4,0,
196,15,244,1,29,
196,15,244,4,0,
196,15,247,4,0,
196,15,248,1,25,
196,15,263,4,0,
196,15,270,1,1,1
196,15,347,4,0,
196,15,384,1,45,
196,15,387,1,41,
196,15,416,4,0,
196,15,433,4,0,
196,15,447,4,0,
196,15,473,4,0,
196,15,496,4,0,
196,15,497,4,0,
196,15,514,4,0,
196,15,590,4,0,
196,15,605,4,0,
196,16,15,4,0,
196,16,28,1,5,1
196,16,33,1,1,2
196,16,39,1,1,3
196,16,60,1,20,1
196,16,63,4,0,
196,16,91,4,0,
196,16,92,4,0,
196,16,93,1,9,1
196,16,94,1,37,1
196,16,94,4,0,
196,16,98,1,13,1
196,16,104,4,0,
196,16,113,4,0,
196,16,115,4,0,
196,16,129,1,17,1
196,16,138,4,0,
196,16,148,4,0,
196,16,156,4,0,
196,16,164,4,0,
196,16,173,3,0,
196,16,182,4,0,
196,16,207,4,0,
196,16,213,4,0,
196,16,214,4,0,
196,16,215,3,0,
196,16,216,4,0,
196,16,218,4,0,
196,16,231,3,0,
196,16,234,1,33,1
196,16,237,4,0,
196,16,240,4,0,
196,16,241,4,0,
196,16,244,1,29,1
196,16,244,4,0,
196,16,247,4,0,
196,16,248,1,25,1
196,16,263,4,0,
196,16,270,1,1,1
196,16,270,3,0,
196,16,271,3,0,
196,16,277,3,0,
196,16,285,3,0,
196,16,290,4,0,
196,16,304,3,0,
196,16,324,3,0,
196,16,343,3,0,
196,16,347,4,0,
196,16,384,1,45,1
196,16,387,1,41,1
196,16,387,3,0,
196,16,416,4,0,
196,16,428,3,0,
196,16,433,4,0,
196,16,447,4,0,
196,16,473,4,0,
196,16,478,3,0,
196,16,496,4,0,
196,16,497,4,0,
196,16,514,4,0,
196,16,590,4,0,
196,16,605,4,0,
196,17,28,1,5,
196,17,33,1,1,3
196,17,39,1,1,4
196,17,60,1,20,
196,17,63,4,0,
196,17,92,4,0,
196,17,93,1,0,
196,17,93,1,1,1
196,17,94,1,37,
196,17,94,4,0,
196,17,98,1,13,
196,17,104,4,0,
196,17,113,4,0,
196,17,115,4,0,
196,17,129,1,17,
196,17,138,4,0,
196,17,156,4,0,
196,17,164,4,0,
196,17,182,4,0,
196,17,207,4,0,
196,17,213,4,0,
196,17,214,4,0,
196,17,216,4,0,
196,17,218,4,0,
196,17,234,1,33,
196,17,237,4,0,
196,17,240,4,0,
196,17,241,4,0,
196,17,244,1,29,
196,17,244,4,0,
196,17,247,4,0,
196,17,248,1,25,
196,17,263,4,0,
196,17,270,1,1,2
196,17,347,4,0,
196,17,384,1,45,
196,17,387,1,41,
196,17,416,4,0,
196,17,433,4,0,
196,17,447,4,0,
196,17,473,4,0,
196,17,496,4,0,
196,17,497,4,0,
196,17,526,4,0,
196,17,590,4,0,
196,17,605,4,0,
196,17,608,1,9,
196,18,28,1,5,
196,18,33,1,1,3
196,18,39,1,1,4
196,18,60,1,20,
196,18,63,4,0,
196,18,92,4,0,
196,18,93,1,0,
196,18,93,1,1,1
196,18,94,1,37,
196,18,94,4,0,
196,18,98,1,13,
196,18,104,4,0,
196,18,113,4,0,
196,18,115,4,0,
196,18,129,1,17,
196,18,138,4,0,
196,18,156,4,0,
196,18,164,4,0,
196,18,182,4,0,
196,18,207,4,0,
196,18,213,4,0,
196,18,214,4,0,
196,18,216,4,0,
196,18,218,4,0,
196,18,234,1,33,
196,18,237,4,0,
196,18,240,4,0,
196,18,241,4,0,
196,18,244,1,29,
196,18,244,4,0,
196,18,247,4,0,
196,18,248,1,25,
196,18,263,4,0,
196,18,270,1,1,2
196,18,347,4,0,
196,18,384,1,45,
196,18,387,1,41,
196,18,416,4,0,
196,18,433,4,0,
196,18,447,4,0,
196,18,473,4,0,
196,18,496,4,0,
196,18,497,4,0,
196,18,526,4,0,
196,18,590,4,0,
196,18,605,4,0,
196,18,608,1,9,
197,3,15,4,0,
197,3,28,1,8,
197,3,29,4,0,
197,3,33,1,1,1
197,3,39,1,1,2
197,3,63,4,0,
197,3,92,4,0,
197,3,94,4,0,
197,3,98,1,23,
197,3,103,1,47,
197,3,104,4,0,
197,3,109,1,30,
197,3,129,4,0,
197,3,138,4,0,
197,3,148,4,0,
197,3,156,4,0,
197,3,171,4,0,
197,3,173,4,0,
197,3,174,4,0,
197,3,182,4,0,
197,3,185,1,36,
197,3,189,4,0,
197,3,192,4,0,
197,3,197,4,0,
197,3,203,4,0,
197,3,207,4,0,
197,3,212,1,42,
197,3,213,4,0,
197,3,214,4,0,
197,3,216,4,0,
197,3,218,4,0,
197,3,228,1,16,
197,3,231,4,0,
197,3,236,1,52,
197,3,237,4,0,
197,3,240,4,0,
197,3,241,4,0,
197,3,244,4,0,
197,3,247,4,0,
197,4,15,4,0,
197,4,28,1,8,
197,4,29,4,0,
197,4,33,1,1,1
197,4,39,1,1,2
197,4,63,4,0,
197,4,92,4,0,
197,4,94,4,0,
197,4,98,1,23,
197,4,103,1,47,
197,4,104,4,0,
197,4,109,1,30,
197,4,129,4,0,
197,4,138,4,0,
197,4,148,4,0,
197,4,156,4,0,
197,4,171,4,0,
197,4,173,4,0,
197,4,174,4,0,
197,4,182,4,0,
197,4,185,1,36,
197,4,189,4,0,
197,4,192,4,0,
197,4,197,4,0,
197,4,203,4,0,
197,4,207,4,0,
197,4,212,1,42,
197,4,213,4,0,
197,4,214,4,0,
197,4,216,4,0,
197,4,218,4,0,
197,4,228,1,16,
197,4,231,4,0,
197,4,236,1,52,
197,4,237,4,0,
197,4,240,4,0,
197,4,241,4,0,
197,4,244,4,0,
197,4,247,4,0,
197,5,15,4,0,
197,5,28,1,8,
197,5,33,1,1,1
197,5,39,1,1,2
197,5,63,4,0,
197,5,91,4,0,
197,5,92,4,0,
197,5,94,4,0,
197,5,98,1,23,
197,5,103,1,47,
197,5,104,4,0,
197,5,109,1,30,
197,5,148,4,0,
197,5,156,4,0,
197,5,182,4,0,
197,5,185,1,36,
197,5,212,1,42,
197,5,213,4,0,
197,5,216,4,0,
197,5,218,4,0,
197,5,228,1,16,
197,5,231,4,0,
197,5,236,1,52,
197,5,237,4,0,
197,5,240,4,0,
197,5,241,4,0,
197,5,247,4,0,
197,5,259,4,0,
197,5,263,4,0,
197,5,269,4,0,
197,5,270,1,1,3
197,5,289,4,0,
197,5,290,4,0,
197,6,15,4,0,
197,6,28,1,8,
197,6,33,1,1,1
197,6,34,3,0,
197,6,38,3,0,
197,6,39,1,1,2
197,6,63,4,0,
197,6,91,4,0,
197,6,92,4,0,
197,6,94,4,0,
197,6,98,1,23,
197,6,102,3,0,
197,6,103,1,47,
197,6,104,4,0,
197,6,109,1,30,
197,6,129,3,0,
197,6,138,3,0,
197,6,148,4,0,
197,6,156,4,0,
197,6,164,3,0,
197,6,173,3,0,
197,6,182,4,0,
197,6,185,1,36,
197,6,189,3,0,
197,6,203,3,0,
197,6,207,3,0,
197,6,212,1,42,
197,6,213,4,0,
197,6,214,3,0,
197,6,216,4,0,
197,6,218,4,0,
197,6,228,1,16,
197,6,231,4,0,
197,6,236,1,52,
197,6,237,4,0,
197,6,240,4,0,
197,6,241,4,0,
197,6,244,3,0,
197,6,247,4,0,
197,6,259,4,0,
197,6,263,4,0,
197,6,269,4,0,
197,6,270,1,1,3
197,6,289,4,0,
197,6,290,4,0,
197,7,15,4,0,
197,7,28,1,8,
197,7,33,1,1,1
197,7,34,3,0,
197,7,38,3,0,
197,7,39,1,1,2
197,7,63,4,0,
197,7,91,4,0,
197,7,92,4,0,
197,7,94,4,0,
197,7,98,1,23,
197,7,102,3,0,
197,7,103,1,47,
197,7,104,4,0,
197,7,109,1,30,
197,7,138,3,0,
197,7,148,4,0,
197,7,156,4,0,
197,7,164,3,0,
197,7,182,4,0,
197,7,185,1,36,
197,7,212,1,42,
197,7,213,4,0,
197,7,216,4,0,
197,7,218,4,0,
197,7,228,1,16,
197,7,231,4,0,
197,7,236,1,52,
197,7,237,4,0,
197,7,240,4,0,
197,7,241,4,0,
197,7,247,4,0,
197,7,259,4,0,
197,7,263,4,0,
197,7,269,4,0,
197,7,270,1,1,3
197,7,289,4,0,
197,7,290,4,0,
197,8,15,4,0,
197,8,28,1,8,
197,8,33,1,1,1
197,8,39,1,1,2
197,8,63,4,0,
197,8,91,4,0,
197,8,92,4,0,
197,8,94,4,0,
197,8,98,1,22,
197,8,103,1,64,
197,8,104,4,0,
197,8,109,1,29,
197,8,138,4,0,
197,8,148,4,0,
197,8,156,4,0,
197,8,164,4,0,
197,8,182,4,0,
197,8,185,1,36,
197,8,203,4,0,
197,8,207,4,0,
197,8,212,1,57,
197,8,213,4,0,
197,8,214,4,0,
197,8,216,4,0,
197,8,218,4,0,
197,8,228,1,15,
197,8,231,4,0,
197,8,236,1,71,
197,8,237,4,0,
197,8,240,4,0,
197,8,241,4,0,
197,8,244,4,0,
197,8,247,4,0,
197,8,259,4,0,
197,8,263,4,0,
197,8,269,4,0,
197,8,270,1,1,3
197,8,289,4,0,
197,8,290,4,0,
197,8,363,4,0,
197,8,371,4,0,
197,8,372,1,43,
197,8,387,1,50,
197,8,399,4,0,
197,8,416,4,0,
197,8,445,4,0,
197,9,15,4,0,
197,9,28,1,8,
197,9,33,1,1,2
197,9,39,1,1,1
197,9,63,4,0,
197,9,91,4,0,
197,9,92,4,0,
197,9,94,4,0,
197,9,98,1,22,
197,9,103,1,64,
197,9,104,4,0,
197,9,109,1,29,
197,9,129,3,0,
197,9,138,4,0,
197,9,148,4,0,
197,9,156,4,0,
197,9,164,4,0,
197,9,173,3,0,
197,9,180,3,0,
197,9,182,4,0,
197,9,185,1,36,
197,9,189,3,0,
197,9,203,4,0,
197,9,207,4,0,
197,9,212,1,57,
197,9,213,4,0,
197,9,214,4,0,
197,9,216,4,0,
197,9,218,4,0,
197,9,228,1,15,
197,9,231,4,0,
197,9,236,1,71,
197,9,237,4,0,
197,9,240,4,0,
197,9,241,4,0,
197,9,244,4,0,
197,9,247,4,0,
197,9,259,4,0,
197,9,263,4,0,
197,9,269,4,0,
197,9,270,1,1,3
197,9,270,3,0,
197,9,289,4,0,
197,9,290,4,0,
197,9,363,4,0,
197,9,371,4,0,
197,9,372,1,43,
197,9,385,1,78,
197,9,387,1,50,
197,9,387,3,0,
197,9,389,3,0,
197,9,399,4,0,
197,9,416,4,0,
197,9,445,4,0,
197,10,15,4,0,
197,10,28,1,8,
197,10,29,3,0,
197,10,33,1,1,2
197,10,39,1,1,1
197,10,63,4,0,
197,10,91,4,0,
197,10,92,4,0,
197,10,94,4,0,
197,10,98,1,22,
197,10,103,1,64,
197,10,104,4,0,
197,10,109,1,29,
197,10,129,3,0,
197,10,138,4,0,
197,10,148,4,0,
197,10,156,4,0,
197,10,164,4,0,
197,10,173,3,0,
197,10,180,3,0,
197,10,182,4,0,
197,10,185,1,36,
197,10,189,3,0,
197,10,203,4,0,
197,10,207,4,0,
197,10,212,1,57,
197,10,213,4,0,
197,10,214,4,0,
197,10,215,3,0,
197,10,216,4,0,
197,10,218,4,0,
197,10,228,1,15,
197,10,231,4,0,
197,10,236,1,71,
197,10,237,4,0,
197,10,240,4,0,
197,10,241,4,0,
197,10,244,4,0,
197,10,247,4,0,
197,10,259,4,0,
197,10,263,4,0,
197,10,269,4,0,
197,10,270,1,1,3
197,10,270,3,0,
197,10,289,4,0,
197,10,290,4,0,
197,10,363,4,0,
197,10,371,4,0,
197,10,372,1,43,
197,10,385,1,78,
197,10,387,1,50,
197,10,387,3,0,
197,10,389,3,0,
197,10,399,4,0,
197,10,416,4,0,
197,10,445,4,0,
197,11,15,4,0,
197,11,28,1,8,
197,11,33,1,1,2
197,11,39,1,1,1
197,11,63,4,0,
197,11,91,4,0,
197,11,92,4,0,
197,11,94,4,0,
197,11,98,1,22,
197,11,103,1,64,
197,11,104,4,0,
197,11,109,1,29,
197,11,138,4,0,
197,11,148,4,0,
197,11,156,4,0,
197,11,164,4,0,
197,11,182,4,0,
197,11,185,1,36,
197,11,207,4,0,
197,11,212,1,57,
197,11,213,4,0,
197,11,216,4,0,
197,11,218,4,0,
197,11,228,1,15,
197,11,236,1,71,
197,11,237,4,0,
197,11,240,4,0,
197,11,241,4,0,
197,11,244,4,0,
197,11,247,4,0,
197,11,259,4,0,
197,11,263,4,0,
197,11,269,4,0,
197,11,270,1,1,3
197,11,371,4,0,
197,11,372,1,43,
197,11,385,1,78,
197,11,387,1,50,
197,11,416,4,0,
197,11,496,4,0,
197,11,497,4,0,
197,11,514,4,0,
197,11,526,4,0,
197,11,555,4,0,
197,12,15,4,0,
197,12,28,1,8,
197,12,33,1,1,1
197,12,39,1,1,2
197,12,63,4,0,
197,12,91,4,0,
197,12,92,4,0,
197,12,94,4,0,
197,12,98,1,23,
197,12,103,1,47,
197,12,104,4,0,
197,12,109,1,30,
197,12,148,4,0,
197,12,156,4,0,
197,12,182,4,0,
197,12,185,1,36,
197,12,212,1,42,
197,12,213,4,0,
197,12,216,4,0,
197,12,218,4,0,
197,12,228,1,16,
197,12,231,4,0,
197,12,236,1,52,
197,12,237,4,0,
197,12,240,4,0,
197,12,241,4,0,
197,12,247,4,0,
197,12,259,4,0,
197,12,263,4,0,
197,12,269,4,0,
197,12,270,1,1,3
197,12,289,4,0,
197,12,290,4,0,
197,13,15,4,0,
197,13,28,1,8,
197,13,33,1,1,1
197,13,34,3,0,
197,13,38,3,0,
197,13,39,1,1,2
197,13,63,4,0,
197,13,91,4,0,
197,13,92,4,0,
197,13,94,4,0,
197,13,98,1,23,
197,13,102,3,0,
197,13,103,1,47,
197,13,104,4,0,
197,13,109,1,30,
197,13,138,3,0,
197,13,148,4,0,
197,13,156,4,0,
197,13,164,3,0,
197,13,171,3,0,
197,13,182,4,0,
197,13,185,1,36,
197,13,207,3,0,
197,13,212,1,42,
197,13,213,4,0,
197,13,216,4,0,
197,13,218,4,0,
197,13,228,1,16,
197,13,231,4,0,
197,13,236,1,52,
197,13,237,4,0,
197,13,240,4,0,
197,13,241,4,0,
197,13,247,4,0,
197,13,259,4,0,
197,13,263,4,0,
197,13,269,4,0,
197,13,270,1,1,3
197,13,289,4,0,
197,13,290,4,0,
197,14,15,4,0,
197,14,28,1,5,
197,14,33,1,1,2
197,14,39,1,1,3
197,14,63,4,0,
197,14,91,4,0,
197,14,92,4,0,
197,14,94,4,0,
197,14,98,1,13,
197,14,103,1,29,
197,14,104,4,0,
197,14,109,1,17,
197,14,138,4,0,
197,14,148,4,0,
197,14,156,4,0,
197,14,164,4,0,
197,14,173,3,0,
197,14,180,3,0,
197,14,182,4,0,
197,14,185,1,21,
197,14,207,4,0,
197,14,212,1,37,
197,14,213,4,0,
197,14,214,3,0,
197,14,215,3,0,
197,14,216,4,0,
197,14,218,4,0,
197,14,228,1,9,
197,14,231,3,0,
197,14,236,1,33,
197,14,237,4,0,
197,14,240,4,0,
197,14,241,4,0,
197,14,244,4,0,
197,14,247,4,0,
197,14,259,4,0,
197,14,263,4,0,
197,14,269,4,0,
197,14,270,1,1,1
197,14,270,3,0,
197,14,289,3,0,
197,14,304,3,0,
197,14,343,3,0,
197,14,371,4,0,
197,14,372,1,25,
197,14,385,1,45,
197,14,387,1,41,
197,14,387,3,0,
197,14,399,3,0,
197,14,416,4,0,
197,14,472,3,0,
197,14,492,3,0,
197,14,496,4,0,
197,14,497,4,0,
197,14,514,4,0,
197,14,526,4,0,
197,14,555,4,0,
197,15,15,4,0,
197,15,28,1,5,
197,15,33,1,1,2
197,15,39,1,1,3
197,15,63,4,0,
197,15,91,4,0,
197,15,92,4,0,
197,15,94,4,0,
197,15,98,1,13,
197,15,103,1,29,
197,15,104,4,0,
197,15,109,1,17,
197,15,138,4,0,
197,15,148,4,0,
197,15,156,4,0,
197,15,164,4,0,
197,15,182,4,0,
197,15,185,1,20,
197,15,207,4,0,
197,15,212,1,37,
197,15,213,4,0,
197,15,214,4,0,
197,15,216,4,0,
197,15,218,4,0,
197,15,228,1,9,
197,15,236,1,33,
197,15,237,4,0,
197,15,240,4,0,
197,15,241,4,0,
197,15,244,4,0,
197,15,247,4,0,
197,15,259,4,0,
197,15,263,4,0,
197,15,269,4,0,
197,15,270,1,1,1
197,15,371,4,0,
197,15,372,1,25,
197,15,385,1,45,
197,15,387,1,41,
197,15,399,4,0,
197,15,416,4,0,
197,15,496,4,0,
197,15,497,4,0,
197,15,514,4,0,
197,15,555,4,0,
197,15,590,4,0,
197,16,15,4,0,
197,16,28,1,5,1
197,16,33,1,1,2
197,16,39,1,1,3
197,16,63,4,0,
197,16,91,4,0,
197,16,92,4,0,
197,16,94,4,0,
197,16,98,1,13,1
197,16,103,1,29,1
197,16,104,4,0,
197,16,109,1,17,1
197,16,138,4,0,
197,16,148,4,0,
197,16,156,4,0,
197,16,164,4,0,
197,16,173,3,0,
197,16,180,3,0,
197,16,182,4,0,
197,16,185,1,20,1
197,16,207,4,0,
197,16,212,1,37,1
197,16,213,4,0,
197,16,214,4,0,
197,16,215,3,0,
197,16,216,4,0,
197,16,218,4,0,
197,16,228,1,9,1
197,16,231,3,0,
197,16,236,1,33,1
197,16,237,4,0,
197,16,240,4,0,
197,16,241,4,0,
197,16,244,4,0,
197,16,247,4,0,
197,16,259,4,0,
197,16,263,4,0,
197,16,269,4,0,
197,16,270,1,1,1
197,16,270,3,0,
197,16,289,3,0,
197,16,290,4,0,
197,16,304,3,0,
197,16,343,3,0,
197,16,371,4,0,
197,16,372,1,25,1
197,16,385,1,45,1
197,16,387,1,41,1
197,16,387,3,0,
197,16,399,4,0,
197,16,416,4,0,
197,16,472,3,0,
197,16,492,3,0,
197,16,496,4,0,
197,16,497,4,0,
197,16,514,4,0,
197,16,555,4,0,
197,16,590,4,0,
197,17,28,1,5,
197,17,33,1,1,3
197,17,39,1,1,4
197,17,63,4,0,
197,17,92,4,0,
197,17,94,4,0,
197,17,98,1,13,
197,17,103,1,29,
197,17,104,4,0,
197,17,109,1,17,
197,17,138,4,0,
197,17,156,4,0,
197,17,164,4,0,
197,17,182,4,0,
197,17,185,1,20,
197,17,207,4,0,
197,17,212,1,37,
197,17,213,4,0,
197,17,214,4,0,
197,17,216,4,0,
197,17,218,4,0,
197,17,228,1,0,
197,17,228,1,1,1
197,17,236,1,33,
197,17,237,4,0,
197,17,240,4,0,
197,17,241,4,0,
197,17,244,4,0,
197,17,247,4,0,
197,17,259,4,0,
197,17,263,4,0,
197,17,269,4,0,
197,17,270,1,1,2
197,17,371,4,0,
197,17,372,1,25,
197,17,385,1,45,
197,17,387,1,41,
197,17,399,4,0,
197,17,416,4,0,
197,17,496,4,0,
197,17,497,4,0,
197,17,526,4,0,
197,17,555,4,0,
197,17,590,4,0,
197,17,608,1,9,
197,18,28,1,5,
197,18,33,1,1,3
197,18,39,1,1,4
197,18,63,4,0,
197,18,92,4,0,
197,18,94,4,0,
197,18,98,1,13,
197,18,103,1,29,
197,18,104,4,0,
197,18,109,1,17,
197,18,138,4,0,
197,18,156,4,0,
197,18,164,4,0,
197,18,182,4,0,
197,18,185,1,20,
197,18,207,4,0,
197,18,212,1,37,
197,18,213,4,0,
197,18,214,4,0,
197,18,216,4,0,
197,18,218,4,0,
197,18,228,1,0,
197,18,228,1,1,1
197,18,236,1,33,
197,18,237,4,0,
197,18,240,4,0,
197,18,241,4,0,
197,18,244,4,0,
197,18,247,4,0,
197,18,259,4,0,
197,18,263,4,0,
197,18,269,4,0,
197,18,270,1,1,2
197,18,371,4,0,
197,18,372,1,25,
197,18,385,1,45,
197,18,387,1,41,
197,18,399,4,0,
197,18,416,4,0,
197,18,496,4,0,
197,18,497,4,0,
197,18,526,4,0,
197,18,555,4,0,
197,18,590,4,0,
197,18,608,1,9,
198,3,17,2,0,
198,3,18,2,0,
198,3,19,4,0,
198,3,64,1,1,
198,3,65,2,0,
198,3,92,4,0,
198,3,98,2,0,
198,3,101,1,26,
198,3,104,4,0,
198,3,114,1,16,
198,3,119,2,0,
198,3,129,4,0,
198,3,138,4,0,
198,3,156,4,0,
198,3,168,4,0,
198,3,171,4,0,
198,3,173,4,0,
198,3,174,4,0,
198,3,182,4,0,
198,3,185,1,31,
198,3,189,4,0,
198,3,196,4,0,
198,3,197,4,0,
198,3,203,4,0,
198,3,207,4,0,
198,3,211,4,0,
198,3,212,1,41,
198,3,213,4,0,
198,3,214,4,0,
198,3,216,4,0,
198,3,218,4,0,
198,3,228,1,11,
198,3,237,4,0,
198,3,241,4,0,
198,3,244,4,0,
198,3,247,4,0,
198,4,17,2,0,
198,4,18,2,0,
198,4,19,4,0,
198,4,64,1,1,
198,4,65,2,0,
198,4,92,4,0,
198,4,98,2,0,
198,4,101,1,26,
198,4,104,4,0,
198,4,114,1,16,
198,4,119,2,0,
198,4,129,4,0,
198,4,138,4,0,
198,4,143,2,0,
198,4,156,4,0,
198,4,168,4,0,
198,4,171,4,0,
198,4,173,4,0,
198,4,174,4,0,
198,4,182,4,0,
198,4,185,1,31,
198,4,189,4,0,
198,4,196,4,0,
198,4,197,4,0,
198,4,203,4,0,
198,4,207,4,0,
198,4,211,4,0,
198,4,212,1,41,
198,4,213,4,0,
198,4,214,4,0,
198,4,216,4,0,
198,4,218,4,0,
198,4,228,1,11,
198,4,237,4,0,
198,4,241,4,0,
198,4,244,4,0,
198,4,247,4,0,
198,5,17,2,0,
198,5,18,2,0,
198,5,19,4,0,
198,5,64,1,1,
198,5,65,2,0,
198,5,92,4,0,
198,5,101,1,27,
198,5,104,4,0,
198,5,109,2,0,
198,5,114,1,22,
198,5,119,2,0,
198,5,143,2,0,
198,5,156,4,0,
198,5,168,4,0,
198,5,182,4,0,
198,5,185,1,35,
198,5,195,2,0,
198,5,211,4,0,
198,5,212,1,48,
198,5,213,4,0,
198,5,216,4,0,
198,5,218,4,0,
198,5,228,1,14,
198,5,237,4,0,
198,5,240,4,0,
198,5,241,4,0,
198,5,247,4,0,
198,5,259,4,0,
198,5,263,4,0,
198,5,269,1,40,
198,5,269,4,0,
198,5,289,4,0,
198,5,290,4,0,
198,5,297,2,0,
198,5,310,1,9,
198,5,332,4,0,
198,5,347,4,0,
198,6,17,2,0,
198,6,18,2,0,
198,6,19,4,0,
198,6,38,3,0,
198,6,64,1,1,
198,6,65,2,0,
198,6,86,3,0,
198,6,92,4,0,
198,6,101,1,27,
198,6,102,3,0,
198,6,104,4,0,
198,6,109,2,0,
198,6,114,1,22,
198,6,119,2,0,
198,6,129,3,0,
198,6,138,3,0,
198,6,143,2,0,
198,6,156,4,0,
198,6,164,3,0,
198,6,168,4,0,
198,6,173,3,0,
198,6,182,4,0,
198,6,185,1,35,
198,6,189,3,0,
198,6,195,2,0,
198,6,196,3,0,
198,6,203,3,0,
198,6,207,3,0,
198,6,211,4,0,
198,6,212,1,48,
198,6,213,4,0,
198,6,214,3,0,
198,6,216,4,0,
198,6,218,4,0,
198,6,228,1,14,
198,6,237,4,0,
198,6,240,4,0,
198,6,241,4,0,
198,6,244,3,0,
198,6,247,4,0,
198,6,259,4,0,
198,6,263,4,0,
198,6,269,1,40,
198,6,269,4,0,
198,6,289,4,0,
198,6,290,4,0,
198,6,297,2,0,
198,6,310,1,9,
198,6,332,4,0,
198,6,347,4,0,
198,7,17,2,0,
198,7,18,2,0,
198,7,19,4,0,
198,7,38,3,0,
198,7,64,1,1,
198,7,65,2,0,
198,7,86,3,0,
198,7,92,4,0,
198,7,101,1,27,
198,7,102,3,0,
198,7,104,4,0,
198,7,109,2,0,
198,7,114,1,22,
198,7,119,2,0,
198,7,138,3,0,
198,7,143,2,0,
198,7,156,4,0,
198,7,164,3,0,
198,7,168,4,0,
198,7,182,4,0,
198,7,185,1,35,
198,7,195,2,0,
198,7,211,4,0,
198,7,212,1,48,
198,7,213,4,0,
198,7,216,4,0,
198,7,218,4,0,
198,7,228,1,14,
198,7,237,4,0,
198,7,240,4,0,
198,7,241,4,0,
198,7,247,4,0,
198,7,259,4,0,
198,7,263,4,0,
198,7,269,1,40,
198,7,269,4,0,
198,7,289,4,0,
198,7,290,4,0,
198,7,297,2,0,
198,7,310,1,9,
198,7,332,4,0,
198,7,347,4,0,
198,8,17,1,15,
198,8,17,2,0,
198,8,18,2,0,
198,8,19,4,0,
198,8,64,1,1,1
198,8,65,2,0,
198,8,86,4,0,
198,8,92,4,0,
198,8,94,4,0,
198,8,101,1,21,
198,8,103,2,0,
198,8,104,4,0,
198,8,109,2,0,
198,8,114,1,11,
198,8,119,2,0,
198,8,138,4,0,
198,8,143,2,0,
198,8,156,4,0,
198,8,164,4,0,
198,8,168,4,0,
198,8,182,4,0,
198,8,185,1,35,
198,8,185,2,0,
198,8,195,2,0,
198,8,203,4,0,
198,8,207,4,0,
198,8,211,4,0,
198,8,212,1,41,
198,8,213,4,0,
198,8,214,4,0,
198,8,216,4,0,
198,8,218,4,0,
198,8,228,1,5,
198,8,237,4,0,
198,8,240,4,0,
198,8,241,4,0,
198,8,244,4,0,
198,8,247,4,0,
198,8,259,4,0,
198,8,263,4,0,
198,8,269,1,31,
198,8,269,4,0,
198,8,289,4,0,
198,8,290,4,0,
198,8,297,2,0,
198,8,310,1,1,2
198,8,332,4,0,
198,8,347,4,0,
198,8,355,4,0,
198,8,363,4,0,
198,8,365,4,0,
198,8,371,4,0,
198,8,372,1,25,
198,8,373,4,0,
198,8,375,2,0,
198,8,389,1,45,
198,8,399,4,0,
198,8,432,4,0,
198,8,445,4,0,
198,9,17,1,15,
198,9,17,2,0,
198,9,18,2,0,
198,9,19,4,0,
198,9,64,1,1,1
198,9,65,2,0,
198,9,86,4,0,
198,9,92,4,0,
198,9,94,4,0,
198,9,101,1,21,
198,9,103,2,0,
198,9,104,4,0,
198,9,109,2,0,
198,9,114,1,11,
198,9,119,2,0,
198,9,129,3,0,
198,9,138,4,0,
198,9,143,2,0,
198,9,156,4,0,
198,9,164,4,0,
198,9,168,4,0,
198,9,180,3,0,
198,9,182,4,0,
198,9,185,1,35,
198,9,185,2,0,
198,9,189,3,0,
198,9,195,2,0,
198,9,196,3,0,
198,9,203,4,0,
198,9,207,4,0,
198,9,211,4,0,
198,9,212,1,41,
198,9,213,4,0,
198,9,214,4,0,
198,9,216,4,0,
198,9,218,4,0,
198,9,228,1,5,
198,9,237,4,0,
198,9,239,3,0,
198,9,240,4,0,
198,9,241,4,0,
198,9,244,4,0,
198,9,247,4,0,
198,9,253,3,0,
198,9,257,3,0,
198,9,259,4,0,
198,9,263,4,0,
198,9,269,1,31,
198,9,269,4,0,
198,9,289,4,0,
198,9,290,4,0,
198,9,297,2,0,
198,9,310,1,1,2
198,9,314,3,0,
198,9,332,4,0,
198,9,347,4,0,
198,9,355,4,0,
198,9,363,4,0,
198,9,365,4,0,
198,9,371,4,0,
198,9,372,1,25,
198,9,373,4,0,
198,9,375,2,0,
198,9,389,1,45,
198,9,389,3,0,
198,9,399,4,0,
198,9,432,4,0,
198,9,445,4,0,
198,9,466,3,0,
198,10,17,1,15,
198,10,17,2,0,
198,10,18,2,0,
198,10,19,4,0,
198,10,64,1,1,1
198,10,65,2,0,
198,10,86,4,0,
198,10,92,4,0,
198,10,94,4,0,
198,10,101,1,21,
198,10,103,2,0,
198,10,104,4,0,
198,10,109,2,0,
198,10,114,1,11,
198,10,119,2,0,
198,10,129,3,0,
198,10,138,4,0,
198,10,143,2,0,
198,10,156,4,0,
198,10,164,4,0,
198,10,168,4,0,
198,10,180,3,0,
198,10,182,4,0,
198,10,185,1,35,
198,10,185,2,0,
198,10,189,3,0,
198,10,195,2,0,
198,10,196,3,0,
198,10,203,4,0,
198,10,207,4,0,
198,10,211,4,0,
198,10,212,1,41,
198,10,213,4,0,
198,10,214,4,0,
198,10,216,4,0,
198,10,218,4,0,
198,10,228,1,5,
198,10,237,4,0,
198,10,239,3,0,
198,10,240,4,0,
198,10,241,4,0,
198,10,244,4,0,
198,10,247,4,0,
198,10,253,3,0,
198,10,257,3,0,
198,10,259,4,0,
198,10,263,4,0,
198,10,269,1,31,
198,10,269,4,0,
198,10,289,4,0,
198,10,290,4,0,
198,10,297,2,0,
198,10,310,1,1,2
198,10,314,3,0,
198,10,332,4,0,
198,10,347,4,0,
198,10,355,4,0,
198,10,363,4,0,
198,10,365,4,0,
198,10,366,3,0,
198,10,371,4,0,
198,10,372,1,25,
198,10,373,4,0,
198,10,375,2,0,
198,10,389,1,45,
198,10,389,3,0,
198,10,399,4,0,
198,10,413,2,0,
198,10,445,4,0,
198,10,466,3,0,
198,11,17,1,15,
198,11,17,2,0,
198,11,18,2,0,
198,11,19,4,0,
198,11,64,1,1,1
198,11,65,2,0,
198,11,86,4,0,
198,11,92,4,0,
198,11,94,4,0,
198,11,101,1,21,
198,11,103,2,0,
198,11,104,4,0,
198,11,109,2,0,
198,11,114,1,11,
198,11,119,2,0,
198,11,138,4,0,
198,11,143,2,0,
198,11,156,4,0,
198,11,164,4,0,
198,11,168,4,0,
198,11,182,4,0,
198,11,185,1,35,
198,11,185,2,0,
198,11,195,2,0,
198,11,207,4,0,
198,11,212,1,41,
198,11,213,4,0,
198,11,216,4,0,
198,11,218,4,0,
198,11,228,1,5,
198,11,237,4,0,
198,11,240,4,0,
198,11,241,4,0,
198,11,244,4,0,
198,11,247,4,0,
198,11,259,1,61,
198,11,259,4,0,
198,11,263,4,0,
198,11,269,1,31,
198,11,269,4,0,
198,11,297,2,0,
198,11,310,1,1,2
198,11,332,4,0,
198,11,347,4,0,
198,11,355,2,0,
198,11,365,4,0,
198,11,366,1,51,
198,11,371,4,0,
198,11,372,1,25,
198,11,372,2,0,
198,11,373,4,0,
198,11,375,2,0,
198,11,389,1,55,
198,11,413,2,0,
198,11,492,1,45,
198,11,496,4,0,
198,11,511,1,65,
198,11,511,4,0,
198,11,514,4,0,
198,11,555,4,0,
198,12,19,4,0,
198,12,64,1,1,
198,12,92,4,0,
198,12,101,1,27,
198,12,104,4,0,
198,12,114,1,22,
198,12,156,4,0,
198,12,168,4,0,
198,12,182,4,0,
198,12,185,1,35,
198,12,211,4,0,
198,12,212,1,48,
198,12,213,4,0,
198,12,216,4,0,
198,12,218,4,0,
198,12,228,1,14,
198,12,237,4,0,
198,12,240,4,0,
198,12,241,4,0,
198,12,247,4,0,
198,12,259,4,0,
198,12,263,4,0,
198,12,269,1,40,
198,12,269,4,0,
198,12,289,4,0,
198,12,290,4,0,
198,12,310,1,9,
198,12,332,4,0,
198,12,347,4,0,
198,13,19,4,0,
198,13,38,3,0,
198,13,64,1,1,
198,13,86,3,0,
198,13,92,4,0,
198,13,101,1,27,
198,13,102,3,0,
198,13,104,4,0,
198,13,114,1,22,
198,13,138,3,0,
198,13,143,3,0,
198,13,156,4,0,
198,13,164,3,0,
198,13,168,4,0,
198,13,171,3,0,
198,13,182,4,0,
198,13,185,1,35,
198,13,196,3,0,
198,13,207,3,0,
198,13,211,4,0,
198,13,212,1,48,
198,13,213,4,0,
198,13,216,4,0,
198,13,218,4,0,
198,13,228,1,14,
198,13,237,4,0,
198,13,240,4,0,
198,13,241,4,0,
198,13,247,4,0,
198,13,259,4,0,
198,13,263,4,0,
198,13,269,1,40,
198,13,269,4,0,
198,13,289,4,0,
198,13,290,4,0,
198,13,310,1,9,
198,13,332,4,0,
198,13,347,4,0,
198,14,17,1,15,
198,14,17,2,0,
198,14,18,2,0,
198,14,19,4,0,
198,14,64,1,1,1
198,14,65,2,0,
198,14,86,4,0,
198,14,92,4,0,
198,14,94,4,0,
198,14,101,1,21,
198,14,103,2,0,
198,14,104,4,0,
198,14,109,2,0,
198,14,114,1,11,
198,14,119,2,0,
198,14,138,4,0,
198,14,143,2,0,
198,14,143,3,0,
198,14,156,4,0,
198,14,164,4,0,
198,14,168,4,0,
198,14,180,3,0,
198,14,182,4,0,
198,14,185,1,35,
198,14,185,2,0,
198,14,195,2,0,
198,14,196,3,0,
198,14,207,4,0,
198,14,212,1,41,
198,14,213,4,0,
198,14,214,3,0,
198,14,216,4,0,
198,14,218,4,0,
198,14,228,1,5,
198,14,237,4,0,
198,14,240,4,0,
198,14,241,4,0,
198,14,244,4,0,
198,14,247,4,0,
198,14,253,3,0,
198,14,257,3,0,
198,14,259,1,61,
198,14,259,4,0,
198,14,263,4,0,
198,14,269,1,31,
198,14,269,4,0,
198,14,289,3,0,
198,14,297,2,0,
198,14,310,1,1,2
198,14,332,4,0,
198,14,347,4,0,
198,14,355,2,0,
198,14,355,3,0,
198,14,365,4,0,
198,14,366,1,51,
198,14,366,3,0,
198,14,371,4,0,
198,14,372,1,25,
198,14,372,2,0,
198,14,373,4,0,
198,14,375,2,0,
198,14,389,1,55,
198,14,399,3,0,
198,14,413,2,0,
198,14,492,1,45,
198,14,492,3,0,
198,14,496,4,0,
198,14,511,1,65,
198,14,511,4,0,
198,14,514,4,0,
198,14,555,4,0,
198,15,17,1,15,
198,15,17,2,0,
198,15,18,2,0,
198,15,19,4,0,
198,15,64,1,1,1
198,15,65,2,0,
198,15,86,4,0,
198,15,92,4,0,
198,15,94,4,0,
198,15,101,1,21,
198,15,103,2,0,
198,15,104,4,0,
198,15,109,2,0,
198,15,114,1,11,
198,15,119,2,0,
198,15,138,4,0,
198,15,143,2,0,
198,15,156,4,0,
198,15,164,4,0,
198,15,168,4,0,
198,15,182,4,0,
198,15,185,1,35,
198,15,185,2,0,
198,15,195,2,0,
198,15,207,4,0,
198,15,211,4,0,
198,15,212,1,41,
198,15,213,4,0,
198,15,214,4,0,
198,15,216,4,0,
198,15,218,4,0,
198,15,228,1,5,
198,15,237,4,0,
198,15,240,4,0,
198,15,241,4,0,
198,15,244,4,0,
198,15,247,4,0,
198,15,259,1,61,
198,15,259,4,0,
198,15,260,2,0,
198,15,263,4,0,
198,15,269,1,31,
198,15,269,4,0,
198,15,297,2,0,
198,15,310,1,1,2
198,15,332,4,0,
198,15,347,4,0,
198,15,355,2,0,
198,15,355,4,0,
198,15,366,1,50,
198,15,371,4,0,
198,15,372,1,25,
198,15,372,2,0,
198,15,373,4,0,
198,15,375,2,0,
198,15,389,1,55,
198,15,399,4,0,
198,15,413,2,0,
198,15,492,1,45,
198,15,496,4,0,
198,15,511,1,65,
198,15,511,4,0,
198,15,514,4,0,
198,15,555,4,0,
198,15,590,4,0,
198,16,17,1,15,1
198,16,17,2,0,
198,16,18,2,0,
198,16,19,4,0,
198,16,64,1,1,1
198,16,65,2,0,
198,16,86,4,0,
198,16,92,4,0,
198,16,94,4,0,
198,16,101,1,21,1
198,16,103,2,0,
198,16,104,4,0,
198,16,109,2,0,
198,16,114,1,11,1
198,16,119,2,0,
198,16,138,4,0,
198,16,143,2,0,
198,16,143,3,0,
198,16,156,4,0,
198,16,164,4,0,
198,16,168,4,0,
198,16,173,3,0,
198,16,180,3,0,
198,16,182,4,0,
198,16,185,1,35,1
198,16,185,2,0,
198,16,195,2,0,
198,16,196,3,0,
198,16,207,4,0,
198,16,211,4,0,
198,16,212,1,41,1
198,16,213,4,0,
198,16,214,4,0,
198,16,216,4,0,
198,16,218,4,0,
198,16,228,1,5,1
198,16,237,4,0,
198,16,240,4,0,
198,16,241,4,0,
198,16,244,4,0,
198,16,247,4,0,
198,16,253,3,0,
198,16,257,3,0,
198,16,259,1,61,1
198,16,259,4,0,
198,16,260,2,0,
198,16,263,4,0,
198,16,269,1,31,1
198,16,269,4,0,
198,16,289,3,0,
198,16,290,4,0,
198,16,297,2,0,
198,16,310,1,1,2
198,16,332,4,0,
198,16,347,4,0,
198,16,355,2,0,
198,16,355,4,0,
198,16,366,1,50,1
198,16,366,3,0,
198,16,371,4,0,
198,16,372,1,25,1
198,16,372,2,0,
198,16,373,4,0,
198,16,375,2,0,
198,16,389,1,55,1
198,16,399,4,0,
198,16,413,2,0,
198,16,492,1,45,1
198,16,492,3,0,
198,16,496,4,0,
198,16,511,1,65,1
198,16,511,4,0,
198,16,514,4,0,
198,16,555,4,0,
198,16,590,4,0,
198,17,17,1,15,
198,17,17,2,0,
198,17,18,2,0,
198,17,19,4,0,
198,17,64,1,1,1
198,17,65,2,0,
198,17,86,4,0,
198,17,92,4,0,
198,17,94,4,0,
198,17,101,1,21,
198,17,103,2,0,
198,17,104,4,0,
198,17,109,2,0,
198,17,114,1,11,
198,17,119,2,0,
198,17,138,4,0,
198,17,143,2,0,
198,17,156,4,0,
198,17,164,4,0,
198,17,168,4,0,
198,17,182,4,0,
198,17,185,1,35,
198,17,185,2,0,
198,17,195,2,0,
198,17,207,4,0,
198,17,211,4,0,
198,17,212,1,41,
198,17,213,4,0,
198,17,214,4,0,
198,17,216,4,0,
198,17,218,4,0,
198,17,228,1,5,
198,17,237,4,0,
198,17,240,4,0,
198,17,241,4,0,
198,17,244,4,0,
198,17,247,4,0,
198,17,259,1,61,
198,17,259,4,0,
198,17,260,2,0,
198,17,263,4,0,
198,17,269,1,31,
198,17,269,4,0,
198,17,297,2,0,
198,17,310,1,1,2
198,17,332,4,0,
198,17,347,4,0,
198,17,355,2,0,
198,17,355,4,0,
198,17,366,1,50,
198,17,371,4,0,
198,17,372,1,25,
198,17,372,2,0,
198,17,373,4,0,
198,17,375,2,0,
198,17,389,1,55,
198,17,399,4,0,
198,17,413,2,0,
198,17,492,1,45,
198,17,496,4,0,
198,17,511,1,65,
198,17,511,4,0,
198,17,555,4,0,
198,17,590,4,0,
198,18,17,1,15,
198,18,17,2,0,
198,18,18,2,0,
198,18,19,4,0,
198,18,64,1,1,1
198,18,65,2,0,
198,18,86,4,0,
198,18,92,4,0,
198,18,94,4,0,
198,18,101,1,21,
198,18,103,2,0,
198,18,104,4,0,
198,18,109,2,0,
198,18,114,1,11,
198,18,119,2,0,
198,18,138,4,0,
198,18,143,2,0,
198,18,156,4,0,
198,18,164,4,0,
198,18,168,4,0,
198,18,182,4,0,
198,18,185,1,35,
198,18,185,2,0,
198,18,195,2,0,
198,18,207,4,0,
198,18,211,4,0,
198,18,212,1,41,
198,18,213,4,0,
198,18,214,4,0,
198,18,216,4,0,
198,18,218,4,0,
198,18,228,1,5,
198,18,237,4,0,
198,18,240,4,0,
198,18,241,4,0,
198,18,244,4,0,
198,18,247,4,0,
198,18,259,1,61,
198,18,259,4,0,
198,18,260,2,0,
198,18,263,4,0,
198,18,269,1,31,
198,18,269,4,0,
198,18,297,2,0,
198,18,310,1,1,2
198,18,332,4,0,
198,18,347,4,0,
198,18,355,2,0,
198,18,355,4,0,
198,18,366,1,50,
198,18,371,4,0,
198,18,372,1,25,
198,18,372,2,0,
198,18,373,4,0,
198,18,375,2,0,
198,18,386,2,0,
198,18,389,1,55,
198,18,399,4,0,
198,18,413,2,0,
198,18,492,1,45,
198,18,496,4,0,
198,18,511,1,65,
198,18,511,4,0,
198,18,555,4,0,
198,18,590,4,0,
199,3,8,4,0,
199,3,29,1,34,
199,3,29,4,0,
199,3,33,1,1,2
199,3,45,1,6,
199,3,50,1,29,
199,3,55,1,15,
199,3,57,4,0,
199,3,59,4,0,
199,3,63,4,0,
199,3,70,4,0,
199,3,89,4,0,
199,3,91,4,0,
199,3,92,4,0,
199,3,93,1,20,
199,3,94,1,48,
199,3,94,4,0,
199,3,104,4,0,
199,3,126,4,0,
199,3,129,4,0,
199,3,138,4,0,
199,3,148,4,0,
199,3,156,4,0,
199,3,171,4,0,
199,3,173,4,0,
199,3,174,1,1,1
199,3,174,4,0,
199,3,182,4,0,
199,3,189,4,0,
199,3,192,4,0,
199,3,196,4,0,
199,3,203,4,0,
199,3,207,1,43,
199,3,207,4,0,
199,3,210,4,0,
199,3,213,4,0,
199,3,214,4,0,
199,3,216,4,0,
199,3,218,4,0,
199,3,223,4,0,
199,3,231,4,0,
199,3,237,4,0,
199,3,240,4,0,
199,3,241,4,0,
199,3,244,4,0,
199,3,247,4,0,
199,3,249,4,0,
199,3,250,4,0,
199,4,8,4,0,
199,4,29,1,34,
199,4,29,4,0,
199,4,33,1,1,2
199,4,45,1,6,
199,4,50,1,29,
199,4,53,3,0,
199,4,55,1,15,
199,4,57,4,0,
199,4,58,3,0,
199,4,59,4,0,
199,4,63,4,0,
199,4,70,4,0,
199,4,89,4,0,
199,4,91,4,0,
199,4,92,4,0,
199,4,93,1,20,
199,4,94,1,48,
199,4,94,4,0,
199,4,104,4,0,
199,4,126,4,0,
199,4,129,4,0,
199,4,138,4,0,
199,4,148,4,0,
199,4,156,4,0,
199,4,171,4,0,
199,4,173,4,0,
199,4,174,1,1,1
199,4,174,4,0,
199,4,182,4,0,
199,4,189,4,0,
199,4,192,4,0,
199,4,196,4,0,
199,4,203,4,0,
199,4,207,1,43,
199,4,207,4,0,
199,4,210,4,0,
199,4,213,4,0,
199,4,214,4,0,
199,4,216,4,0,
199,4,218,4,0,
199,4,223,4,0,
199,4,231,4,0,
199,4,237,4,0,
199,4,240,4,0,
199,4,241,4,0,
199,4,244,4,0,
199,4,247,4,0,
199,4,249,4,0,
199,4,250,4,0,
199,5,29,1,34,
199,5,33,1,1,3
199,5,45,1,6,
199,5,50,1,29,
199,5,53,4,0,
199,5,55,1,15,
199,5,57,4,0,
199,5,58,4,0,
199,5,59,4,0,
199,5,63,4,0,
199,5,70,4,0,
199,5,89,4,0,
199,5,91,4,0,
199,5,92,4,0,
199,5,93,1,20,
199,5,94,1,48,
199,5,94,4,0,
199,5,104,4,0,
199,5,126,4,0,
199,5,148,4,0,
199,5,156,4,0,
199,5,174,1,1,1
199,5,182,4,0,
199,5,207,1,43,
199,5,213,4,0,
199,5,216,4,0,
199,5,218,4,0,
199,5,219,4,0,
199,5,231,4,0,
199,5,237,4,0,
199,5,240,4,0,
199,5,241,4,0,
199,5,247,4,0,
199,5,249,4,0,
199,5,258,4,0,
199,5,263,4,0,
199,5,264,4,0,
199,5,280,4,0,
199,5,281,1,1,2
199,5,285,4,0,
199,5,290,4,0,
199,5,291,4,0,
199,5,347,4,0,
199,5,352,4,0,
199,6,5,3,0,
199,6,8,3,0,
199,6,25,3,0,
199,6,29,1,34,
199,6,33,1,1,3
199,6,34,3,0,
199,6,38,3,0,
199,6,45,1,6,
199,6,50,1,29,
199,6,53,4,0,
199,6,55,1,15,
199,6,57,4,0,
199,6,58,4,0,
199,6,59,4,0,
199,6,63,4,0,
199,6,68,3,0,
199,6,69,3,0,
199,6,70,4,0,
199,6,86,3,0,
199,6,89,4,0,
199,6,91,4,0,
199,6,92,4,0,
199,6,93,1,20,
199,6,94,1,48,
199,6,94,4,0,
199,6,102,3,0,
199,6,104,4,0,
199,6,126,4,0,
199,6,129,3,0,
199,6,138,3,0,
199,6,148,4,0,
199,6,156,4,0,
199,6,164,3,0,
199,6,173,3,0,
199,6,174,1,1,1
199,6,182,4,0,
199,6,189,3,0,
199,6,196,3,0,
199,6,203,3,0,
199,6,207,1,43,
199,6,207,3,0,
199,6,210,3,0,
199,6,213,4,0,
199,6,214,3,0,
199,6,216,4,0,
199,6,218,4,0,
199,6,219,4,0,
199,6,223,3,0,
199,6,231,4,0,
199,6,237,4,0,
199,6,240,4,0,
199,6,241,4,0,
199,6,244,3,0,
199,6,247,4,0,
199,6,249,4,0,
199,6,258,4,0,
199,6,263,4,0,
199,6,264,4,0,
199,6,280,4,0,
199,6,281,1,1,2
199,6,285,4,0,
199,6,290,4,0,
199,6,291,4,0,
199,6,347,4,0,
199,6,352,4,0,
199,7,5,3,0,
199,7,25,3,0,
199,7,29,1,29,
199,7,33,1,1,3
199,7,34,3,0,
199,7,38,3,0,
199,7,45,1,6,
199,7,50,1,24,
199,7,53,4,0,
199,7,55,1,13,
199,7,57,4,0,
199,7,58,4,0,
199,7,59,4,0,
199,7,63,4,0,
199,7,68,3,0,
199,7,69,3,0,
199,7,70,4,0,
199,7,86,3,0,
199,7,89,4,0,
199,7,91,4,0,
199,7,92,4,0,
199,7,93,1,17,
199,7,94,1,40,
199,7,94,4,0,
199,7,102,3,0,
199,7,104,4,0,
199,7,126,4,0,
199,7,138,3,0,
199,7,148,4,0,
199,7,156,4,0,
199,7,164,3,0,
199,7,174,1,1,1
199,7,182,4,0,
199,7,207,1,36,
199,7,213,4,0,
199,7,216,4,0,
199,7,218,4,0,
199,7,219,4,0,
199,7,231,4,0,
199,7,237,4,0,
199,7,240,4,0,
199,7,241,4,0,
199,7,244,1,47,
199,7,247,4,0,
199,7,249,4,0,
199,7,258,4,0,
199,7,263,4,0,
199,7,264,4,0,
199,7,280,4,0,
199,7,281,1,1,2
199,7,285,4,0,
199,7,290,4,0,
199,7,291,4,0,
199,7,347,4,0,
199,7,352,4,0,
199,8,29,1,25,
199,8,33,1,1,4
199,8,45,1,6,
199,8,50,1,20,
199,8,53,4,0,
199,8,55,1,11,
199,8,57,4,0,
199,8,58,4,0,
199,8,59,4,0,
199,8,63,4,0,
199,8,70,4,0,
199,8,86,4,0,
199,8,89,4,0,
199,8,91,4,0,
199,8,92,4,0,
199,8,93,1,15,
199,8,94,1,48,
199,8,94,4,0,
199,8,104,4,0,
199,8,113,4,0,
199,8,126,4,0,
199,8,138,4,0,
199,8,148,4,0,
199,8,156,4,0,
199,8,164,4,0,
199,8,174,1,1,2
199,8,182,4,0,
199,8,203,4,0,
199,8,207,1,43,
199,8,207,4,0,
199,8,213,4,0,
199,8,214,4,0,
199,8,216,4,0,
199,8,218,4,0,
199,8,219,4,0,
199,8,231,4,0,
199,8,237,1,1,1
199,8,237,4,0,
199,8,240,4,0,
199,8,241,4,0,
199,8,244,1,57,
199,8,244,4,0,
199,8,247,4,0,
199,8,249,4,0,
199,8,258,4,0,
199,8,263,4,0,
199,8,264,4,0,
199,8,278,4,0,
199,8,280,4,0,
199,8,281,1,1,3
199,8,285,4,0,
199,8,290,4,0,
199,8,347,4,0,
199,8,352,1,29,
199,8,352,4,0,
199,8,362,4,0,
199,8,363,4,0,
199,8,374,4,0,
199,8,376,1,53,
199,8,409,4,0,
199,8,411,4,0,
199,8,416,4,0,
199,8,417,1,39,
199,8,419,4,0,
199,8,428,1,34,
199,8,433,4,0,
199,8,445,4,0,
199,8,447,4,0,
199,9,8,3,0,
199,9,29,1,25,
199,9,33,1,1,5
199,9,45,1,6,
199,9,50,1,20,
199,9,53,4,0,
199,9,55,1,11,
199,9,57,4,0,
199,9,58,4,0,
199,9,59,4,0,
199,9,63,4,0,
199,9,70,4,0,
199,9,86,4,0,
199,9,89,4,0,
199,9,91,4,0,
199,9,92,4,0,
199,9,93,1,15,
199,9,94,1,48,
199,9,94,4,0,
199,9,104,4,0,
199,9,113,4,0,
199,9,126,4,0,
199,9,129,3,0,
199,9,138,4,0,
199,9,148,4,0,
199,9,156,4,0,
199,9,164,4,0,
199,9,173,3,0,
199,9,174,1,1,3
199,9,182,4,0,
199,9,189,3,0,
199,9,196,3,0,
199,9,203,4,0,
199,9,207,1,43,
199,9,207,4,0,
199,9,210,3,0,
199,9,213,4,0,
199,9,214,4,0,
199,9,216,4,0,
199,9,218,4,0,
199,9,219,4,0,
199,9,231,4,0,
199,9,237,1,1,2
199,9,237,4,0,
199,9,240,4,0,
199,9,241,4,0,
199,9,244,1,57,
199,9,244,4,0,
199,9,247,4,0,
199,9,249,4,0,
199,9,258,4,0,
199,9,263,4,0,
199,9,264,4,0,
199,9,271,3,0,
199,9,278,4,0,
199,9,280,4,0,
199,9,281,1,1,4
199,9,285,4,0,
199,9,290,4,0,
199,9,291,3,0,
199,9,324,3,0,
199,9,334,3,0,
199,9,347,4,0,
199,9,352,1,29,
199,9,352,4,0,
199,9,362,4,0,
199,9,363,4,0,
199,9,374,4,0,
199,9,376,1,53,
199,9,401,3,0,
199,9,408,1,1,1
199,9,409,4,0,
199,9,411,4,0,
199,9,416,4,0,
199,9,417,1,39,
199,9,419,4,0,
199,9,428,1,34,
199,9,428,3,0,
199,9,433,4,0,
199,9,445,4,0,
199,9,447,4,0,
199,10,8,3,0,
199,10,29,1,25,
199,10,29,3,0,
199,10,33,1,1,5
199,10,45,1,6,
199,10,50,1,20,
199,10,53,4,0,
199,10,55,1,11,
199,10,57,4,0,
199,10,58,4,0,
199,10,59,4,0,
199,10,63,4,0,
199,10,70,4,0,
199,10,86,4,0,
199,10,89,4,0,
199,10,91,4,0,
199,10,92,4,0,
199,10,93,1,15,
199,10,94,1,48,
199,10,94,4,0,
199,10,104,4,0,
199,10,113,4,0,
199,10,126,4,0,
199,10,129,3,0,
199,10,138,4,0,
199,10,148,4,0,
199,10,156,4,0,
199,10,164,4,0,
199,10,173,3,0,
199,10,174,1,1,3
199,10,182,4,0,
199,10,189,3,0,
199,10,196,3,0,
199,10,203,4,0,
199,10,207,1,43,
199,10,207,4,0,
199,10,210,3,0,
199,10,213,4,0,
199,10,214,4,0,
199,10,216,4,0,
199,10,218,4,0,
199,10,219,4,0,
199,10,231,4,0,
199,10,237,1,1,2
199,10,237,4,0,
199,10,240,4,0,
199,10,241,4,0,
199,10,244,1,57,
199,10,244,4,0,
199,10,247,4,0,
199,10,249,4,0,
199,10,250,4,0,
199,10,258,4,0,
199,10,263,4,0,
199,10,264,4,0,
199,10,271,3,0,
199,10,277,3,0,
199,10,278,4,0,
199,10,280,4,0,
199,10,281,1,1,4
199,10,285,4,0,
199,10,290,4,0,
199,10,291,3,0,
199,10,324,3,0,
199,10,334,3,0,
199,10,335,3,0,
199,10,347,4,0,
199,10,352,1,29,
199,10,352,4,0,
199,10,362,4,0,
199,10,363,4,0,
199,10,374,4,0,
199,10,376,1,53,
199,10,401,3,0,
199,10,408,1,1,1
199,10,409,4,0,
199,10,411,4,0,
199,10,416,4,0,
199,10,417,1,39,
199,10,419,4,0,
199,10,428,1,34,
199,10,428,3,0,
199,10,433,4,0,
199,10,445,4,0,
199,10,447,4,0,
199,11,29,1,23,
199,11,33,1,1,5
199,11,45,1,5,
199,11,50,1,19,
199,11,53,4,0,
199,11,55,1,9,
199,11,57,4,0,
199,11,58,4,0,
199,11,59,4,0,
199,11,63,4,0,
199,11,70,4,0,
199,11,86,4,0,
199,11,89,4,0,
199,11,91,4,0,
199,11,92,4,0,
199,11,93,1,14,
199,11,94,1,45,
199,11,94,4,0,
199,11,104,4,0,
199,11,113,4,0,
199,11,126,4,0,
199,11,138,4,0,
199,11,148,4,0,
199,11,156,4,0,
199,11,164,4,0,
199,11,174,1,1,3
199,11,182,4,0,
199,11,207,1,41,
199,11,207,4,0,
199,11,213,4,0,
199,11,216,4,0,
199,11,218,4,0,
199,11,219,4,0,
199,11,237,1,1,2
199,11,237,4,0,
199,11,240,4,0,
199,11,241,4,0,
199,11,244,1,54,
199,11,244,4,0,
199,11,247,4,0,
199,11,249,4,0,
199,11,258,4,0,
199,11,263,4,0,
199,11,280,4,0,
199,11,281,1,1,4
199,11,291,4,0,
199,11,347,4,0,
199,11,352,1,28,
199,11,374,4,0,
199,11,376,1,49,
199,11,408,1,1,1
199,11,411,4,0,
199,11,416,4,0,
199,11,417,1,36,
199,11,428,1,32,
199,11,433,4,0,
199,11,447,4,0,
199,11,473,4,0,
199,11,477,4,0,
199,11,496,4,0,
199,11,497,4,0,
199,11,503,4,0,
199,11,505,1,58,
199,11,510,4,0,
199,11,511,4,0,
199,11,523,4,0,
199,11,525,4,0,
199,12,29,1,34,
199,12,33,1,1,3
199,12,45,1,6,
199,12,50,1,29,
199,12,53,4,0,
199,12,55,1,15,
199,12,57,4,0,
199,12,58,4,0,
199,12,59,4,0,
199,12,63,4,0,
199,12,70,4,0,
199,12,89,4,0,
199,12,91,4,0,
199,12,92,4,0,
199,12,93,1,20,
199,12,94,1,48,
199,12,94,4,0,
199,12,104,4,0,
199,12,126,4,0,
199,12,148,4,0,
199,12,156,4,0,
199,12,174,1,1,1
199,12,182,4,0,
199,12,207,1,43,
199,12,213,4,0,
199,12,216,4,0,
199,12,218,4,0,
199,12,219,4,0,
199,12,231,4,0,
199,12,237,4,0,
199,12,240,4,0,
199,12,241,4,0,
199,12,247,4,0,
199,12,249,4,0,
199,12,258,4,0,
199,12,263,4,0,
199,12,264,4,0,
199,12,280,4,0,
199,12,281,1,1,2
199,12,285,4,0,
199,12,290,4,0,
199,12,291,4,0,
199,12,347,4,0,
199,12,352,4,0,
199,13,29,1,34,
199,13,33,1,1,3
199,13,34,3,0,
199,13,38,3,0,
199,13,45,1,6,
199,13,50,1,29,
199,13,53,4,0,
199,13,55,1,15,
199,13,57,4,0,
199,13,58,4,0,
199,13,59,4,0,
199,13,63,4,0,
199,13,69,3,0,
199,13,70,4,0,
199,13,86,3,0,
199,13,89,4,0,
199,13,91,4,0,
199,13,92,4,0,
199,13,93,1,20,
199,13,94,1,48,
199,13,94,4,0,
199,13,102,3,0,
199,13,104,4,0,
199,13,126,4,0,
199,13,138,3,0,
199,13,148,4,0,
199,13,156,4,0,
199,13,164,3,0,
199,13,171,3,0,
199,13,174,1,1,1
199,13,182,4,0,
199,13,196,3,0,
199,13,207,1,43,
199,13,207,3,0,
199,13,213,4,0,
199,13,216,4,0,
199,13,218,4,0,
199,13,219,4,0,
199,13,231,4,0,
199,13,237,4,0,
199,13,240,4,0,
199,13,241,4,0,
199,13,247,4,0,
199,13,249,4,0,
199,13,258,4,0,
199,13,263,4,0,
199,13,264,4,0,
199,13,280,4,0,
199,13,281,1,1,2
199,13,285,4,0,
199,13,290,4,0,
199,13,291,4,0,
199,13,347,4,0,
199,13,352,4,0,
199,14,8,3,0,
199,14,29,1,23,
199,14,33,1,1,5
199,14,45,1,5,
199,14,50,1,19,
199,14,53,4,0,
199,14,55,1,9,
199,14,57,4,0,
199,14,58,4,0,
199,14,59,4,0,
199,14,63,4,0,
199,14,70,4,0,
199,14,86,4,0,
199,14,89,4,0,
199,14,91,4,0,
199,14,92,4,0,
199,14,93,1,14,
199,14,94,1,45,
199,14,94,4,0,
199,14,104,4,0,
199,14,113,4,0,
199,14,126,4,0,
199,14,138,4,0,
199,14,148,4,0,
199,14,156,4,0,
199,14,164,4,0,
199,14,173,3,0,
199,14,174,1,1,3
199,14,182,4,0,
199,14,196,3,0,
199,14,207,1,41,
199,14,207,4,0,
199,14,213,4,0,
199,14,214,3,0,
199,14,216,4,0,
199,14,218,4,0,
199,14,219,4,0,
199,14,231,3,0,
199,14,237,1,1,2
199,14,237,4,0,
199,14,240,4,0,
199,14,241,4,0,
199,14,244,1,54,
199,14,244,4,0,
199,14,247,4,0,
199,14,249,4,0,
199,14,258,4,0,
199,14,263,4,0,
199,14,271,3,0,
199,14,277,3,0,
199,14,278,3,0,
199,14,280,4,0,
199,14,281,1,1,4
199,14,285,3,0,
199,14,291,4,0,
199,14,324,3,0,
199,14,334,3,0,
199,14,335,3,0,
199,14,347,4,0,
199,14,352,1,28,
199,14,374,4,0,
199,14,376,1,49,
199,14,401,3,0,
199,14,408,1,1,1
199,14,409,3,0,
199,14,411,4,0,
199,14,416,4,0,
199,14,417,1,36,
199,14,428,1,32,
199,14,428,3,0,
199,14,433,4,0,
199,14,447,4,0,
199,14,472,3,0,
199,14,473,4,0,
199,14,477,4,0,
199,14,492,3,0,
199,14,495,3,0,
199,14,496,4,0,
199,14,497,4,0,
199,14,503,4,0,
199,14,505,1,58,
199,14,510,4,0,
199,14,511,4,0,
199,14,523,4,0,
199,14,525,4,0,
199,15,29,1,23,
199,15,33,1,1,6
199,15,45,1,5,
199,15,50,1,19,
199,15,53,4,0,
199,15,55,1,9,
199,15,57,4,0,
199,15,58,4,0,
199,15,59,4,0,
199,15,63,4,0,
199,15,70,4,0,
199,15,86,4,0,
199,15,89,4,0,
199,15,91,4,0,
199,15,92,4,0,
199,15,93,1,14,
199,15,94,1,45,
199,15,94,4,0,
199,15,104,4,0,
199,15,113,4,0,
199,15,126,4,0,
199,15,138,4,0,
199,15,148,4,0,
199,15,156,4,0,
199,15,164,4,0,
199,15,174,1,1,4
199,15,182,4,0,
199,15,207,1,41,
199,15,207,4,0,
199,15,213,4,0,
199,15,214,4,0,
199,15,216,4,0,
199,15,218,4,0,
199,15,219,4,0,
199,15,237,1,1,3
199,15,237,4,0,
199,15,240,4,0,
199,15,241,4,0,
199,15,244,1,54,
199,15,244,4,0,
199,15,247,4,0,
199,15,249,4,0,
199,15,258,4,0,
199,15,263,4,0,
199,15,280,4,0,
199,15,281,1,1,5
199,15,347,4,0,
199,15,352,1,28,
199,15,374,4,0,
199,15,376,1,49,
199,15,408,1,1,2
199,15,411,4,0,
199,15,416,4,0,
199,15,417,1,36,
199,15,428,1,32,
199,15,433,4,0,
199,15,447,4,0,
199,15,473,4,0,
199,15,496,4,0,
199,15,497,4,0,
199,15,503,4,0,
199,15,505,1,1,1
199,15,505,1,58,
199,15,510,4,0,
199,15,511,4,0,
199,15,523,4,0,
199,15,525,4,0,
199,15,590,4,0,
199,15,612,4,0,
199,16,8,3,0,
199,16,29,1,23,1
199,16,33,1,1,6
199,16,45,1,5,1
199,16,50,1,19,1
199,16,53,4,0,
199,16,55,1,9,1
199,16,57,4,0,
199,16,58,4,0,
199,16,59,4,0,
199,16,63,4,0,
199,16,70,4,0,
199,16,86,4,0,
199,16,89,4,0,
199,16,91,4,0,
199,16,92,4,0,
199,16,93,1,14,1
199,16,94,1,45,1
199,16,94,4,0,
199,16,104,4,0,
199,16,113,4,0,
199,16,126,4,0,
199,16,138,4,0,
199,16,148,4,0,
199,16,156,4,0,
199,16,164,4,0,
199,16,173,3,0,
199,16,174,1,1,4
199,16,182,4,0,
199,16,196,3,0,
199,16,207,1,41,1
199,16,207,4,0,
199,16,213,4,0,
199,16,214,4,0,
199,16,216,4,0,
199,16,218,4,0,
199,16,219,4,0,
199,16,231,3,0,
199,16,237,1,1,3
199,16,237,4,0,
199,16,240,4,0,
199,16,241,4,0,
199,16,244,1,54,1
199,16,244,4,0,
199,16,247,4,0,
199,16,249,4,0,
199,16,258,4,0,
199,16,263,4,0,
199,16,264,3,0,
199,16,271,3,0,
199,16,277,3,0,
199,16,278,3,0,
199,16,280,4,0,
199,16,281,1,1,5
199,16,285,3,0,
199,16,290,4,0,
199,16,291,4,0,
199,16,324,3,0,
199,16,334,3,0,
199,16,335,3,0,
199,16,347,4,0,
199,16,352,1,28,1
199,16,352,3,0,
199,16,374,4,0,
199,16,376,1,49,1
199,16,401,3,0,
199,16,408,1,1,2
199,16,409,3,0,
199,16,411,4,0,
199,16,416,4,0,
199,16,417,1,36,1
199,16,428,1,32,1
199,16,428,3,0,
199,16,433,4,0,
199,16,447,4,0,
199,16,472,3,0,
199,16,473,4,0,
199,16,492,3,0,
199,16,495,3,0,
199,16,496,4,0,
199,16,497,4,0,
199,16,503,4,0,
199,16,505,1,1,1
199,16,505,1,58,1
199,16,510,4,0,
199,16,511,4,0,
199,16,523,4,0,
199,16,525,4,0,
199,16,590,4,0,
199,16,612,4,0,
199,17,29,1,23,
199,17,33,1,1,6
199,17,45,1,5,
199,17,50,1,19,
199,17,53,4,0,
199,17,55,1,9,
199,17,57,4,0,
199,17,58,4,0,
199,17,59,4,0,
199,17,63,4,0,
199,17,86,4,0,
199,17,89,4,0,
199,17,92,4,0,
199,17,93,1,14,
199,17,94,1,45,
199,17,94,4,0,
199,17,104,4,0,
199,17,113,4,0,
199,17,126,4,0,
199,17,138,4,0,
199,17,156,4,0,
199,17,164,4,0,
199,17,174,1,1,4
199,17,182,4,0,
199,17,207,1,41,
199,17,207,4,0,
199,17,213,4,0,
199,17,214,4,0,
199,17,216,4,0,
199,17,218,4,0,
199,17,219,4,0,
199,17,237,1,1,3
199,17,237,4,0,
199,17,240,4,0,
199,17,241,4,0,
199,17,244,1,54,
199,17,244,4,0,
199,17,247,4,0,
199,17,258,4,0,
199,17,263,4,0,
199,17,280,4,0,
199,17,281,1,1,5
199,17,347,4,0,
199,17,352,1,28,
199,17,374,4,0,
199,17,376,1,49,
199,17,408,1,1,2
199,17,411,4,0,
199,17,416,4,0,
199,17,417,1,36,
199,17,428,1,32,
199,17,433,4,0,
199,17,447,4,0,
199,17,473,4,0,
199,17,496,4,0,
199,17,497,4,0,
199,17,503,4,0,
199,17,505,1,1,1
199,17,505,1,58,
199,17,511,4,0,
199,17,523,4,0,
199,17,525,4,0,
199,17,590,4,0,
199,18,29,1,23,
199,18,33,1,1,6
199,18,45,1,5,
199,18,50,1,19,
199,18,53,4,0,
199,18,55,1,9,
199,18,57,4,0,
199,18,58,4,0,
199,18,59,4,0,
199,18,63,4,0,
199,18,86,4,0,
199,18,89,4,0,
199,18,92,4,0,
199,18,93,1,14,
199,18,94,1,45,
199,18,94,4,0,
199,18,104,4,0,
199,18,113,4,0,
199,18,126,4,0,
199,18,138,4,0,
199,18,156,4,0,
199,18,164,4,0,
199,18,174,1,1,4
199,18,182,4,0,
199,18,207,1,41,
199,18,207,4,0,
199,18,213,4,0,
199,18,214,4,0,
199,18,216,4,0,
199,18,218,4,0,
199,18,219,4,0,
199,18,237,1,1,3
199,18,237,4,0,
199,18,240,4,0,
199,18,241,4,0,
199,18,244,1,54,
199,18,244,4,0,
199,18,247,4,0,
199,18,258,4,0,
199,18,263,4,0,
199,18,280,4,0,
199,18,281,1,1,5
199,18,347,4,0,
199,18,352,1,28,
199,18,374,4,0,
199,18,376,1,49,
199,18,408,1,1,2
199,18,411,4,0,
199,18,416,4,0,
199,18,417,1,36,
199,18,428,1,32,
199,18,433,4,0,
199,18,447,4,0,
199,18,473,4,0,
199,18,496,4,0,
199,18,497,4,0,
199,18,503,4,0,
199,18,505,1,1,1
199,18,505,1,58,
199,18,511,4,0,
199,18,523,4,0,
199,18,525,4,0,
199,18,590,4,0,
200,3,29,4,0,
200,3,45,1,1,1
200,3,60,1,27,
200,3,87,4,0,
200,3,92,4,0,
200,3,94,4,0,
200,3,103,2,0,
200,3,104,4,0,
200,3,109,1,12,
200,3,111,4,0,
200,3,129,4,0,
200,3,138,4,0,
200,3,148,4,0,
200,3,149,1,1,2
200,3,156,4,0,
200,3,168,4,0,
200,3,171,4,0,
200,3,173,4,0,
200,3,174,4,0,
200,3,180,1,6,
200,3,182,4,0,
200,3,192,4,0,
200,3,194,2,0,
200,3,195,1,46,
200,3,203,4,0,
200,3,207,4,0,
200,3,212,1,19,
200,3,213,4,0,
200,3,214,4,0,
200,3,216,4,0,
200,3,218,4,0,
200,3,220,1,36,
200,3,237,4,0,
200,3,240,4,0,
200,3,241,4,0,
200,3,244,4,0,
200,3,247,4,0,
200,4,29,4,0,
200,4,45,1,1,1
200,4,60,1,27,
200,4,85,3,0,
200,4,87,4,0,
200,4,92,4,0,
200,4,94,4,0,
200,4,103,2,0,
200,4,104,4,0,
200,4,109,1,12,
200,4,111,4,0,
200,4,129,4,0,
200,4,138,4,0,
200,4,148,4,0,
200,4,149,1,1,2
200,4,156,4,0,
200,4,168,4,0,
200,4,171,4,0,
200,4,173,4,0,
200,4,174,4,0,
200,4,180,1,6,
200,4,182,4,0,
200,4,192,4,0,
200,4,194,2,0,
200,4,195,1,46,
200,4,203,4,0,
200,4,207,4,0,
200,4,212,1,19,
200,4,213,4,0,
200,4,214,4,0,
200,4,216,4,0,
200,4,218,4,0,
200,4,220,1,36,
200,4,237,4,0,
200,4,240,4,0,
200,4,241,4,0,
200,4,244,4,0,
200,4,247,4,0,
200,5,45,1,1,1
200,5,60,1,30,
200,5,85,4,0,
200,5,87,4,0,
200,5,92,4,0,
200,5,94,4,0,
200,5,103,2,0,
200,5,104,4,0,
200,5,109,1,17,
200,5,148,4,0,
200,5,149,1,1,2
200,5,156,4,0,
200,5,168,4,0,
200,5,180,1,6,
200,5,182,4,0,
200,5,194,2,0,
200,5,195,1,45,
200,5,212,1,23,
200,5,213,4,0,
200,5,216,4,0,
200,5,218,4,0,
200,5,220,1,37,
200,5,237,4,0,
200,5,240,4,0,
200,5,241,4,0,
200,5,244,2,0,
200,5,247,4,0,
200,5,259,4,0,
200,5,263,4,0,
200,5,269,4,0,
200,5,285,4,0,
200,5,286,2,0,
200,5,288,1,53,
200,5,289,4,0,
200,5,290,4,0,
200,5,310,1,11,
200,5,332,4,0,
200,5,347,4,0,
200,5,351,4,0,
200,6,38,3,0,
200,6,45,1,1,1
200,6,60,1,30,
200,6,85,4,0,
200,6,86,3,0,
200,6,87,4,0,
200,6,92,4,0,
200,6,94,4,0,
200,6,102,3,0,
200,6,103,2,0,
200,6,104,4,0,
200,6,109,1,17,
200,6,111,3,0,
200,6,129,3,0,
200,6,138,3,0,
200,6,148,4,0,
200,6,149,1,1,2
200,6,156,4,0,
200,6,164,3,0,
200,6,168,4,0,
200,6,173,3,0,
200,6,180,1,6,
200,6,182,4,0,
200,6,194,2,0,
200,6,195,1,45,
200,6,203,3,0,
200,6,207,3,0,
200,6,212,1,23,
200,6,213,4,0,
200,6,214,3,0,
200,6,216,4,0,
200,6,218,4,0,
200,6,220,1,37,
200,6,237,4,0,
200,6,240,4,0,
200,6,241,4,0,
200,6,244,2,0,
200,6,244,3,0,
200,6,247,4,0,
200,6,259,4,0,
200,6,263,4,0,
200,6,269,4,0,
200,6,285,4,0,
200,6,286,2,0,
200,6,288,1,53,
200,6,289,4,0,
200,6,290,4,0,
200,6,310,1,11,
200,6,332,4,0,
200,6,347,4,0,
200,6,351,4,0,
200,7,38,3,0,
200,7,45,1,1,1
200,7,60,1,30,
200,7,85,4,0,
200,7,86,3,0,
200,7,87,4,0,
200,7,92,4,0,
200,7,94,4,0,
200,7,102,3,0,
200,7,103,2,0,
200,7,104,4,0,
200,7,109,1,17,
200,7,138,3,0,
200,7,148,4,0,
200,7,149,1,1,2
200,7,156,4,0,
200,7,164,3,0,
200,7,168,4,0,
200,7,180,1,6,
200,7,182,4,0,
200,7,194,2,0,
200,7,195,1,45,
200,7,212,1,23,
200,7,213,4,0,
200,7,216,4,0,
200,7,218,4,0,
200,7,220,1,37,
200,7,237,4,0,
200,7,240,4,0,
200,7,241,4,0,
200,7,244,2,0,
200,7,247,4,0,
200,7,259,4,0,
200,7,263,4,0,
200,7,269,4,0,
200,7,285,4,0,
200,7,286,2,0,
200,7,288,1,53,
200,7,289,4,0,
200,7,290,4,0,
200,7,310,1,11,
200,7,332,4,0,
200,7,347,4,0,
200,7,351,4,0,
200,8,45,1,1,1
200,8,60,1,23,
200,8,85,4,0,
200,8,86,4,0,
200,8,87,4,0,
200,8,92,4,0,
200,8,94,4,0,
200,8,103,2,0,
200,8,104,4,0,
200,8,109,1,14,
200,8,138,4,0,
200,8,148,4,0,
200,8,149,1,1,2
200,8,156,4,0,
200,8,164,4,0,
200,8,168,4,0,
200,8,174,2,0,
200,8,180,1,5,
200,8,180,2,0,
200,8,182,4,0,
200,8,194,2,0,
200,8,195,1,41,
200,8,203,4,0,
200,8,207,4,0,
200,8,212,1,19,
200,8,213,4,0,
200,8,214,4,0,
200,8,216,4,0,
200,8,218,4,0,
200,8,220,1,28,
200,8,237,4,0,
200,8,240,4,0,
200,8,241,4,0,
200,8,244,2,0,
200,8,244,4,0,
200,8,247,1,37,
200,8,247,4,0,
200,8,259,4,0,
200,8,261,4,0,
200,8,262,2,0,
200,8,263,4,0,
200,8,269,4,0,
200,8,285,4,0,
200,8,286,2,0,
200,8,288,1,46,
200,8,289,4,0,
200,8,290,4,0,
200,8,310,1,10,
200,8,332,4,0,
200,8,347,4,0,
200,8,351,4,0,
200,8,363,4,0,
200,8,371,1,32,
200,8,371,4,0,
200,8,373,4,0,
200,8,389,2,0,
200,8,399,4,0,
200,8,425,2,0,
200,8,433,4,0,
200,8,445,4,0,
200,8,451,4,0,
200,8,466,2,0,
200,9,45,1,1,1
200,9,60,1,23,
200,9,85,4,0,
200,9,86,4,0,
200,9,87,4,0,
200,9,92,4,0,
200,9,94,4,0,
200,9,103,2,0,
200,9,104,4,0,
200,9,109,1,14,
200,9,129,3,0,
200,9,138,4,0,
200,9,148,4,0,
200,9,149,1,1,2
200,9,156,4,0,
200,9,164,4,0,
200,9,168,4,0,
200,9,173,3,0,
200,9,174,2,0,
200,9,180,1,5,
200,9,180,2,0,
200,9,180,3,0,
200,9,182,4,0,
200,9,194,2,0,
200,9,195,1,41,
200,9,196,3,0,
200,9,203,4,0,
200,9,207,4,0,
200,9,212,1,19,
200,9,213,4,0,
200,9,214,4,0,
200,9,216,4,0,
200,9,218,4,0,
200,9,220,1,28,
200,9,237,4,0,
200,9,240,4,0,
200,9,241,4,0,
200,9,244,2,0,
200,9,244,4,0,
200,9,247,1,37,
200,9,247,4,0,
200,9,253,3,0,
200,9,259,4,0,
200,9,261,4,0,
200,9,262,2,0,
200,9,263,4,0,
200,9,269,4,0,
200,9,271,3,0,
200,9,285,4,0,
200,9,286,2,0,
200,9,288,1,46,
200,9,289,4,0,
200,9,290,4,0,
200,9,310,1,10,
200,9,332,4,0,
200,9,347,4,0,
200,9,351,4,0,
200,9,363,4,0,
200,9,371,1,32,
200,9,371,4,0,
200,9,373,4,0,
200,9,389,2,0,
200,9,389,3,0,
200,9,399,4,0,
200,9,408,1,50,
200,9,425,2,0,
200,9,433,4,0,
200,9,445,4,0,
200,9,451,4,0,
200,9,466,2,0,
200,9,466,3,0,
200,10,29,3,0,
200,10,45,1,1,1
200,10,60,1,23,
200,10,85,4,0,
200,10,86,4,0,
200,10,87,4,0,
200,10,92,4,0,
200,10,94,4,0,
200,10,103,2,0,
200,10,104,4,0,
200,10,109,1,14,
200,10,129,3,0,
200,10,138,4,0,
200,10,148,4,0,
200,10,149,1,1,2
200,10,156,4,0,
200,10,164,4,0,
200,10,168,4,0,
200,10,173,3,0,
200,10,174,2,0,
200,10,180,1,5,
200,10,180,2,0,
200,10,180,3,0,
200,10,182,4,0,
200,10,194,2,0,
200,10,195,1,41,
200,10,196,3,0,
200,10,203,4,0,
200,10,207,4,0,
200,10,212,1,19,
200,10,213,4,0,
200,10,214,4,0,
200,10,215,3,0,
200,10,216,4,0,
200,10,218,4,0,
200,10,220,1,28,
200,10,220,3,0,
200,10,237,4,0,
200,10,240,4,0,
200,10,241,4,0,
200,10,244,2,0,
200,10,244,4,0,
200,10,247,1,37,
200,10,247,4,0,
200,10,253,3,0,
200,10,259,4,0,
200,10,261,4,0,
200,10,262,2,0,
200,10,263,4,0,
200,10,269,4,0,
200,10,271,3,0,
200,10,277,3,0,
200,10,285,4,0,
200,10,286,2,0,
200,10,288,1,46,
200,10,289,4,0,
200,10,290,4,0,
200,10,310,1,10,
200,10,332,4,0,
200,10,347,4,0,
200,10,351,4,0,
200,10,363,4,0,
200,10,371,1,32,
200,10,371,4,0,
200,10,373,4,0,
200,10,389,2,0,
200,10,389,3,0,
200,10,399,4,0,
200,10,408,1,50,
200,10,417,2,0,
200,10,425,2,0,
200,10,433,4,0,
200,10,445,4,0,
200,10,451,4,0,
200,10,466,2,0,
200,10,466,3,0,
200,11,45,1,1,1
200,11,60,1,28,
200,11,85,4,0,
200,11,86,4,0,
200,11,87,4,0,
200,11,92,4,0,
200,11,94,4,0,
200,11,103,2,0,
200,11,104,4,0,
200,11,109,1,14,
200,11,138,4,0,
200,11,148,4,0,
200,11,149,1,1,2
200,11,156,4,0,
200,11,164,4,0,
200,11,168,4,0,
200,11,174,2,0,
200,11,180,1,5,
200,11,180,2,0,
200,11,182,4,0,
200,11,194,2,0,
200,11,195,1,46,
200,11,207,4,0,
200,11,212,1,19,
200,11,213,4,0,
200,11,216,4,0,
200,11,218,4,0,
200,11,220,1,32,
200,11,237,4,0,
200,11,240,4,0,
200,11,241,4,0,
200,11,244,4,0,
200,11,247,1,41,
200,11,247,4,0,
200,11,259,4,0,
200,11,261,4,0,
200,11,262,2,0,
200,11,263,4,0,
200,11,269,4,0,
200,11,285,2,0,
200,11,286,2,0,
200,11,288,1,50,
200,11,310,1,10,
200,11,332,4,0,
200,11,347,4,0,
200,11,371,1,37,
200,11,371,4,0,
200,11,373,4,0,
200,11,389,2,0,
200,11,408,1,55,
200,11,417,2,0,
200,11,425,2,0,
200,11,433,4,0,
200,11,451,4,0,
200,11,466,2,0,
200,11,472,2,0,
200,11,477,4,0,
200,11,496,4,0,
200,11,497,4,0,
200,11,506,1,23,
200,12,45,1,1,1
200,12,60,1,30,
200,12,85,4,0,
200,12,87,4,0,
200,12,92,4,0,
200,12,94,4,0,
200,12,104,4,0,
200,12,109,1,17,
200,12,148,4,0,
200,12,149,1,1,2
200,12,156,4,0,
200,12,168,4,0,
200,12,180,1,6,
200,12,182,4,0,
200,12,195,1,45,
200,12,212,1,23,
200,12,213,4,0,
200,12,216,4,0,
200,12,218,4,0,
200,12,220,1,37,
200,12,237,4,0,
200,12,240,4,0,
200,12,241,4,0,
200,12,247,4,0,
200,12,259,4,0,
200,12,263,4,0,
200,12,269,4,0,
200,12,285,4,0,
200,12,288,1,53,
200,12,289,4,0,
200,12,290,4,0,
200,12,310,1,11,
200,12,332,4,0,
200,12,347,4,0,
200,12,351,4,0,
200,13,38,3,0,
200,13,45,1,1,1
200,13,60,1,30,
200,13,85,4,0,
200,13,86,3,0,
200,13,87,4,0,
200,13,92,4,0,
200,13,94,4,0,
200,13,102,3,0,
200,13,104,4,0,
200,13,109,1,17,
200,13,138,3,0,
200,13,148,4,0,
200,13,149,1,1,2
200,13,156,4,0,
200,13,164,3,0,
200,13,168,4,0,
200,13,171,3,0,
200,13,180,1,6,
200,13,182,4,0,
200,13,195,1,45,
200,13,207,3,0,
200,13,212,1,23,
200,13,213,4,0,
200,13,216,4,0,
200,13,218,4,0,
200,13,220,1,37,
200,13,237,4,0,
200,13,240,4,0,
200,13,241,4,0,
200,13,247,4,0,
200,13,259,4,0,
200,13,263,4,0,
200,13,269,4,0,
200,13,285,4,0,
200,13,288,1,53,
200,13,289,4,0,
200,13,290,4,0,
200,13,310,1,11,
200,13,332,4,0,
200,13,347,4,0,
200,13,351,4,0,
200,14,45,1,1,1
200,14,60,1,28,
200,14,85,4,0,
200,14,86,4,0,
200,14,87,4,0,
200,14,92,4,0,
200,14,94,4,0,
200,14,103,2,0,
200,14,104,4,0,
200,14,109,1,14,
200,14,138,4,0,
200,14,148,4,0,
200,14,149,1,1,2
200,14,156,4,0,
200,14,164,4,0,
200,14,168,4,0,
200,14,173,3,0,
200,14,174,2,0,
200,14,180,1,5,
200,14,180,2,0,
200,14,180,3,0,
200,14,182,4,0,
200,14,194,2,0,
200,14,195,1,46,
200,14,196,3,0,
200,14,207,4,0,
200,14,212,1,19,
200,14,213,4,0,
200,14,214,3,0,
200,14,215,3,0,
200,14,216,4,0,
200,14,218,4,0,
200,14,220,1,32,
200,14,220,3,0,
200,14,237,4,0,
200,14,240,4,0,
200,14,241,4,0,
200,14,244,4,0,
200,14,247,1,41,
200,14,247,4,0,
200,14,253,3,0,
200,14,259,4,0,
200,14,261,4,0,
200,14,262,2,0,
200,14,263,4,0,
200,14,269,4,0,
200,14,271,3,0,
200,14,277,3,0,
200,14,285,2,0,
200,14,285,3,0,
200,14,286,2,0,
200,14,288,1,50,
200,14,289,3,0,
200,14,304,3,0,
200,14,310,1,10,
200,14,332,4,0,
200,14,347,4,0,
200,14,371,1,37,
200,14,371,4,0,
200,14,373,4,0,
200,14,389,2,0,
200,14,399,3,0,
200,14,408,1,55,
200,14,417,2,0,
200,14,425,2,0,
200,14,433,4,0,
200,14,451,4,0,
200,14,466,2,0,
200,14,472,2,0,
200,14,472,3,0,
200,14,477,4,0,
200,14,478,3,0,
200,14,492,3,0,
200,14,496,4,0,
200,14,497,4,0,
200,14,506,1,23,
200,15,45,1,1,1
200,15,60,1,28,
200,15,85,4,0,
200,15,86,4,0,
200,15,87,4,0,
200,15,92,4,0,
200,15,94,4,0,
200,15,103,2,0,
200,15,104,4,0,
200,15,109,1,14,
200,15,138,4,0,
200,15,148,4,0,
200,15,149,1,1,2
200,15,156,4,0,
200,15,164,4,0,
200,15,168,4,0,
200,15,174,2,0,
200,15,180,1,5,
200,15,180,2,0,
200,15,182,4,0,
200,15,194,2,0,
200,15,195,1,46,
200,15,207,4,0,
200,15,212,1,19,
200,15,213,4,0,
200,15,214,4,0,
200,15,216,4,0,
200,15,218,4,0,
200,15,220,1,32,
200,15,237,4,0,
200,15,240,4,0,
200,15,241,4,0,
200,15,244,4,0,
200,15,247,1,41,
200,15,247,4,0,
200,15,259,4,0,
200,15,261,4,0,
200,15,262,2,0,
200,15,263,4,0,
200,15,269,4,0,
200,15,285,2,0,
200,15,286,2,0,
200,15,288,1,50,
200,15,310,1,10,
200,15,332,4,0,
200,15,347,4,0,
200,15,371,1,37,
200,15,371,4,0,
200,15,373,4,0,
200,15,382,2,0,
200,15,389,2,0,
200,15,399,4,0,
200,15,408,1,55,
200,15,417,2,0,
200,15,425,2,0,
200,15,433,4,0,
200,15,451,4,0,
200,15,466,2,0,
200,15,472,2,0,
200,15,496,4,0,
200,15,497,4,0,
200,15,506,1,23,
200,15,590,4,0,
200,15,605,4,0,
200,16,45,1,1,1
200,16,60,1,28,1
200,16,85,4,0,
200,16,86,4,0,
200,16,87,4,0,
200,16,92,4,0,
200,16,94,4,0,
200,16,103,2,0,
200,16,104,4,0,
200,16,109,1,14,1
200,16,138,4,0,
200,16,148,4,0,
200,16,149,1,1,2
200,16,156,4,0,
200,16,164,4,0,
200,16,168,4,0,
200,16,173,3,0,
200,16,174,2,0,
200,16,180,1,5,1
200,16,180,2,0,
200,16,180,3,0,
200,16,182,4,0,
200,16,194,2,0,
200,16,195,1,46,1
200,16,196,3,0,
200,16,207,4,0,
200,16,212,1,19,1
200,16,213,4,0,
200,16,214,4,0,
200,16,215,3,0,
200,16,216,4,0,
200,16,218,4,0,
200,16,220,1,32,1
200,16,220,3,0,
200,16,237,4,0,
200,16,240,4,0,
200,16,241,4,0,
200,16,244,4,0,
200,16,247,1,41,1
200,16,247,4,0,
200,16,253,3,0,
200,16,259,4,0,
200,16,261,4,0,
200,16,262,2,0,
200,16,263,4,0,
200,16,269,4,0,
200,16,271,3,0,
200,16,277,3,0,
200,16,285,2,0,
200,16,285,3,0,
200,16,286,2,0,
200,16,288,1,50,1
200,16,289,3,0,
200,16,290,4,0,
200,16,304,3,0,
200,16,310,1,10,1
200,16,332,4,0,
200,16,347,4,0,
200,16,351,3,0,
200,16,371,1,37,1
200,16,371,4,0,
200,16,373,4,0,
200,16,382,2,0,
200,16,389,2,0,
200,16,399,4,0,
200,16,408,1,55,1
200,16,417,2,0,
200,16,425,2,0,
200,16,433,4,0,
200,16,451,4,0,
200,16,466,2,0,
200,16,472,2,0,
200,16,472,3,0,
200,16,478,3,0,
200,16,492,3,0,
200,16,496,4,0,
200,16,497,4,0,
200,16,506,1,23,1
200,16,590,4,0,
200,16,605,4,0,
200,17,45,1,1,1
200,17,60,1,28,
200,17,85,4,0,
200,17,86,4,0,
200,17,87,4,0,
200,17,92,4,0,
200,17,94,4,0,
200,17,103,2,0,
200,17,104,4,0,
200,17,109,1,14,
200,17,138,4,0,
200,17,149,1,1,2
200,17,156,4,0,
200,17,164,4,0,
200,17,168,4,0,
200,17,174,2,0,
200,17,180,1,5,
200,17,180,2,0,
200,17,182,4,0,
200,17,194,2,0,
200,17,195,1,46,
200,17,207,4,0,
200,17,212,1,19,
200,17,213,4,0,
200,17,214,4,0,
200,17,216,4,0,
200,17,218,4,0,
200,17,220,1,32,
200,17,237,4,0,
200,17,240,4,0,
200,17,241,4,0,
200,17,244,4,0,
200,17,247,1,41,
200,17,247,4,0,
200,17,259,4,0,
200,17,261,4,0,
200,17,262,2,0,
200,17,263,4,0,
200,17,269,4,0,
200,17,285,2,0,
200,17,286,2,0,
200,17,288,1,50,
200,17,310,1,10,
200,17,332,4,0,
200,17,347,4,0,
200,17,371,1,37,
200,17,371,4,0,
200,17,373,4,0,
200,17,382,2,0,
200,17,389,2,0,
200,17,399,4,0,
200,17,408,1,55,
200,17,417,2,0,
200,17,425,2,0,
200,17,433,4,0,
200,17,451,4,0,
200,17,466,2,0,
200,17,472,2,0,
200,17,496,4,0,
200,17,497,4,0,
200,17,506,1,23,
200,17,590,4,0,
200,17,605,4,0,
200,18,45,1,1,1
200,18,60,1,28,
200,18,85,4,0,
200,18,86,4,0,
200,18,87,4,0,
200,18,92,4,0,
200,18,94,4,0,
200,18,103,2,0,
200,18,104,4,0,
200,18,109,1,14,
200,18,138,4,0,
200,18,149,1,1,2
200,18,156,4,0,
200,18,164,4,0,
200,18,168,4,0,
200,18,174,2,0,
200,18,180,1,5,
200,18,180,2,0,
200,18,182,4,0,
200,18,194,2,0,
200,18,195,1,46,
200,18,207,4,0,
200,18,212,1,19,
200,18,213,4,0,
200,18,214,4,0,
200,18,216,4,0,
200,18,218,4,0,
200,18,220,1,32,
200,18,237,4,0,
200,18,240,4,0,
200,18,241,4,0,
200,18,244,4,0,
200,18,247,1,41,
200,18,247,4,0,
200,18,259,4,0,
200,18,261,4,0,
200,18,262,2,0,
200,18,263,4,0,
200,18,269,4,0,
200,18,285,2,0,
200,18,286,2,0,
200,18,288,1,50,
200,18,310,1,10,
200,18,332,4,0,
200,18,347,4,0,
200,18,371,1,37,
200,18,371,4,0,
200,18,373,4,0,
200,18,382,2,0,
200,18,389,2,0,
200,18,399,4,0,
200,18,408,1,55,
200,18,417,2,0,
200,18,425,2,0,
200,18,433,4,0,
200,18,451,4,0,
200,18,466,2,0,
200,18,472,2,0,
200,18,496,4,0,
200,18,497,4,0,
200,18,506,1,23,
200,18,590,4,0,
200,18,605,4,0,
201,3,237,1,1,
201,4,237,1,1,
201,5,237,1,1,
201,6,237,1,1,
201,7,237,1,1,
201,8,237,1,1,
201,9,237,1,1,
201,10,237,1,1,
201,11,237,1,1,
201,12,237,1,1,
201,13,237,1,1,
201,14,237,1,1,
201,15,237,1,1,
201,15,237,4,0,
201,16,237,1,1,1
201,16,237,4,0,
201,17,237,1,1,
201,17,237,4,0,
201,18,237,1,1,
201,18,237,4,0,
202,3,68,1,1,1
202,3,194,1,1,4
202,3,219,1,1,3
202,3,243,1,1,2
202,4,68,1,1,1
202,4,194,1,1,4
202,4,219,1,1,3
202,4,243,1,1,2
202,5,68,1,1,1
202,5,194,1,1,4
202,5,219,1,1,3
202,5,243,1,1,2
202,6,68,1,1,1
202,6,194,1,1,4
202,6,219,1,1,3
202,6,243,1,1,2
202,7,68,1,1,1
202,7,194,1,1,4
202,7,219,1,1,3
202,7,243,1,1,2
202,8,68,1,1,1
202,8,194,1,1,4
202,8,219,1,1,3
202,8,243,1,1,2
202,9,68,1,1,1
202,9,194,1,1,4
202,9,219,1,1,3
202,9,243,1,1,2
202,10,68,1,1,1
202,10,194,1,1,4
202,10,219,1,1,3
202,10,243,1,1,2
202,11,68,1,1,1
202,11,194,1,1,4
202,11,219,1,1,3
202,11,243,1,1,2
202,12,68,1,1,1
202,12,194,1,1,4
202,12,219,1,1,3
202,12,243,1,1,2
202,13,68,1,1,1
202,13,194,1,1,4
202,13,219,1,1,3
202,13,243,1,1,2
202,14,68,1,1,1
202,14,194,1,1,4
202,14,219,1,1,3
202,14,243,1,1,2
202,15,68,1,1,1
202,15,194,1,1,4
202,15,219,1,1,3
202,15,219,4,0,
202,15,243,1,1,2
202,16,68,1,1,1
202,16,194,1,1,4
202,16,219,1,1,3
202,16,219,4,0,
202,16,243,1,1,2
202,17,68,1,1,1
202,17,194,1,1,4
202,17,219,1,1,3
202,17,219,4,0,
202,17,243,1,1,2
202,18,68,1,1,1
202,18,194,1,1,4
202,18,219,1,1,3
202,18,219,4,0,
202,18,243,1,1,2
203,3,23,1,1,4
203,3,23,1,13,
203,3,29,4,0,
203,3,33,1,1,1
203,3,36,2,0,
203,3,45,1,1,2
203,3,60,1,41,
203,3,70,4,0,
203,3,87,4,0,
203,3,89,4,0,
203,3,92,4,0,
203,3,93,1,1,3
203,3,93,1,7,
203,3,94,4,0,
203,3,97,1,20,
203,3,104,4,0,
203,3,129,4,0,
203,3,133,2,0,
203,3,138,4,0,
203,3,156,4,0,
203,3,168,4,0,
203,3,171,4,0,
203,3,173,4,0,
203,3,174,4,0,
203,3,182,4,0,
203,3,189,4,0,
203,3,192,4,0,
203,3,193,2,0,
203,3,203,4,0,
203,3,207,4,0,
203,3,213,4,0,
203,3,214,4,0,
203,3,216,4,0,
203,3,218,4,0,
203,3,226,1,30,
203,3,231,4,0,
203,3,237,4,0,
203,3,241,4,0,
203,3,242,1,54,
203,3,244,4,0,
203,3,247,4,0,
203,3,248,2,0,
203,3,249,4,0,
203,3,251,2,0,
203,4,23,1,1,4
203,4,23,1,13,
203,4,29,4,0,
203,4,33,1,1,1
203,4,36,2,0,
203,4,45,1,1,2
203,4,60,1,41,
203,4,70,4,0,
203,4,85,3,0,
203,4,87,4,0,
203,4,89,4,0,
203,4,92,4,0,
203,4,93,1,1,3
203,4,93,1,7,
203,4,94,4,0,
203,4,97,1,20,
203,4,104,4,0,
203,4,129,4,0,
203,4,133,2,0,
203,4,138,4,0,
203,4,156,4,0,
203,4,168,4,0,
203,4,171,4,0,
203,4,173,4,0,
203,4,174,4,0,
203,4,182,4,0,
203,4,189,4,0,
203,4,192,4,0,
203,4,193,2,0,
203,4,203,4,0,
203,4,207,4,0,
203,4,213,4,0,
203,4,214,4,0,
203,4,216,4,0,
203,4,218,4,0,
203,4,226,1,30,
203,4,231,4,0,
203,4,237,4,0,
203,4,241,4,0,
203,4,242,1,54,
203,4,244,4,0,
203,4,247,4,0,
203,4,248,2,0,
203,4,249,4,0,
203,4,251,2,0,
203,5,23,1,19,
203,5,33,1,1,1
203,5,36,2,0,
203,5,45,1,1,2
203,5,60,1,43,
203,5,70,4,0,
203,5,85,4,0,
203,5,87,4,0,
203,5,89,4,0,
203,5,92,4,0,
203,5,93,1,13,
203,5,94,4,0,
203,5,97,1,31,
203,5,104,4,0,
203,5,113,4,0,
203,5,115,4,0,
203,5,133,2,0,
203,5,148,4,0,
203,5,156,4,0,
203,5,168,4,0,
203,5,182,4,0,
203,5,193,2,0,
203,5,213,4,0,
203,5,216,4,0,
203,5,218,4,0,
203,5,226,1,37,
203,5,231,4,0,
203,5,237,4,0,
203,5,240,4,0,
203,5,241,4,0,
203,5,242,1,49,
203,5,244,2,0,
203,5,247,4,0,
203,5,248,2,0,
203,5,249,4,0,
203,5,251,2,0,
203,5,263,4,0,
203,5,273,2,0,
203,5,277,2,0,
203,5,285,4,0,
203,5,290,4,0,
203,5,310,1,7,
203,5,316,1,25,
203,5,347,4,0,
203,5,351,4,0,
203,6,23,1,19,
203,6,33,1,1,1
203,6,34,3,0,
203,6,36,2,0,
203,6,38,3,0,
203,6,45,1,1,2
203,6,60,1,43,
203,6,70,4,0,
203,6,85,4,0,
203,6,86,3,0,
203,6,87,4,0,
203,6,89,4,0,
203,6,92,4,0,
203,6,93,1,13,
203,6,94,4,0,
203,6,97,1,31,
203,6,102,3,0,
203,6,104,4,0,
203,6,113,4,0,
203,6,115,4,0,
203,6,129,3,0,
203,6,133,2,0,
203,6,138,3,0,
203,6,148,4,0,
203,6,156,4,0,
203,6,164,3,0,
203,6,168,4,0,
203,6,173,3,0,
203,6,182,4,0,
203,6,189,3,0,
203,6,193,2,0,
203,6,203,3,0,
203,6,207,3,0,
203,6,213,4,0,
203,6,214,3,0,
203,6,216,4,0,
203,6,218,4,0,
203,6,226,1,37,
203,6,231,4,0,
203,6,237,4,0,
203,6,240,4,0,
203,6,241,4,0,
203,6,242,1,49,
203,6,244,2,0,
203,6,244,3,0,
203,6,247,4,0,
203,6,248,2,0,
203,6,249,4,0,
203,6,251,2,0,
203,6,263,4,0,
203,6,273,2,0,
203,6,277,2,0,
203,6,285,4,0,
203,6,290,4,0,
203,6,310,1,7,
203,6,316,1,25,
203,6,347,4,0,
203,6,351,4,0,
203,7,23,1,19,
203,7,33,1,1,1
203,7,34,3,0,
203,7,36,2,0,
203,7,38,3,0,
203,7,45,1,1,2
203,7,60,1,43,
203,7,70,4,0,
203,7,85,4,0,
203,7,86,3,0,
203,7,87,4,0,
203,7,89,4,0,
203,7,92,4,0,
203,7,93,1,13,
203,7,94,4,0,
203,7,97,1,31,
203,7,102,3,0,
203,7,104,4,0,
203,7,113,4,0,
203,7,115,4,0,
203,7,133,2,0,
203,7,138,3,0,
203,7,148,4,0,
203,7,156,4,0,
203,7,164,3,0,
203,7,168,4,0,
203,7,182,4,0,
203,7,193,2,0,
203,7,213,4,0,
203,7,216,4,0,
203,7,218,4,0,
203,7,226,1,37,
203,7,231,4,0,
203,7,237,4,0,
203,7,240,4,0,
203,7,241,4,0,
203,7,242,1,49,
203,7,244,2,0,
203,7,247,4,0,
203,7,248,2,0,
203,7,249,4,0,
203,7,251,2,0,
203,7,263,4,0,
203,7,273,2,0,
203,7,277,2,0,
203,7,285,4,0,
203,7,290,4,0,
203,7,310,1,7,
203,7,316,1,25,
203,7,347,4,0,
203,7,351,4,0,
203,8,23,1,10,
203,8,24,2,0,
203,8,33,1,1,4
203,8,36,2,0,
203,8,45,1,1,5
203,8,60,1,19,
203,8,70,4,0,
203,8,85,4,0,
203,8,86,4,0,
203,8,87,4,0,
203,8,89,4,0,
203,8,92,4,0,
203,8,93,1,1,6
203,8,94,1,37,
203,8,94,4,0,
203,8,97,1,14,
203,8,104,4,0,
203,8,113,4,0,
203,8,115,4,0,
203,8,133,2,0,
203,8,138,4,0,
203,8,148,4,0,
203,8,156,4,0,
203,8,164,4,0,
203,8,168,4,0,
203,8,182,4,0,
203,8,193,2,0,
203,8,203,4,0,
203,8,207,4,0,
203,8,213,4,0,
203,8,214,4,0,
203,8,216,4,0,
203,8,218,4,0,
203,8,226,1,23,
203,8,231,4,0,
203,8,237,4,0,
203,8,240,4,0,
203,8,241,4,0,
203,8,242,1,46,
203,8,243,2,0,
203,8,244,2,0,
203,8,244,4,0,
203,8,247,4,0,
203,8,248,2,0,
203,8,249,4,0,
203,8,251,2,0,
203,8,263,4,0,
203,8,273,2,0,
203,8,277,2,0,
203,8,278,4,0,
203,8,285,4,0,
203,8,290,4,0,
203,8,310,1,1,3
203,8,316,1,5,
203,8,347,4,0,
203,8,351,4,0,
203,8,363,4,0,
203,8,372,1,28,
203,8,384,1,1,1
203,8,385,1,1,2
203,8,412,4,0,
203,8,428,1,41,
203,8,433,4,0,
203,8,445,4,0,
203,8,447,4,0,
203,8,451,4,0,
203,8,458,1,32,
203,9,23,1,10,
203,9,24,2,0,
203,9,33,1,1,4
203,9,36,2,0,
203,9,45,1,1,5
203,9,60,1,19,
203,9,70,4,0,
203,9,85,4,0,
203,9,86,4,0,
203,9,87,4,0,
203,9,89,4,0,
203,9,92,4,0,
203,9,93,1,1,6
203,9,94,1,37,
203,9,94,4,0,
203,9,97,1,14,
203,9,104,4,0,
203,9,113,4,0,
203,9,115,4,0,
203,9,129,3,0,
203,9,133,2,0,
203,9,138,4,0,
203,9,148,4,0,
203,9,156,4,0,
203,9,164,4,0,
203,9,168,4,0,
203,9,173,3,0,
203,9,182,4,0,
203,9,189,3,0,
203,9,193,2,0,
203,9,203,4,0,
203,9,207,4,0,
203,9,213,4,0,
203,9,214,4,0,
203,9,216,4,0,
203,9,218,4,0,
203,9,226,1,23,
203,9,231,4,0,
203,9,237,4,0,
203,9,240,4,0,
203,9,241,4,0,
203,9,242,1,46,
203,9,243,2,0,
203,9,244,2,0,
203,9,244,4,0,
203,9,247,4,0,
203,9,248,2,0,
203,9,249,4,0,
203,9,251,2,0,
203,9,253,3,0,
203,9,263,4,0,
203,9,271,3,0,
203,9,273,2,0,
203,9,277,2,0,
203,9,278,4,0,
203,9,285,4,0,
203,9,290,4,0,
203,9,310,1,1,3
203,9,316,1,5,
203,9,324,3,0,
203,9,347,4,0,
203,9,351,4,0,
203,9,363,4,0,
203,9,372,1,28,
203,9,384,1,1,1
203,9,385,1,1,2
203,9,389,3,0,
203,9,412,4,0,
203,9,428,1,41,
203,9,428,3,0,
203,9,433,4,0,
203,9,445,4,0,
203,9,447,4,0,
203,9,451,4,0,
203,9,458,1,32,
203,10,13,2,0,
203,10,23,1,10,
203,10,24,2,0,
203,10,29,3,0,
203,10,33,1,1,4
203,10,36,2,0,
203,10,45,1,1,5
203,10,60,1,19,
203,10,70,4,0,
203,10,85,4,0,
203,10,86,4,0,
203,10,87,4,0,
203,10,89,4,0,
203,10,92,4,0,
203,10,93,1,1,6
203,10,94,1,37,
203,10,94,4,0,
203,10,97,1,14,
203,10,104,4,0,
203,10,113,4,0,
203,10,115,4,0,
203,10,129,3,0,
203,10,133,2,0,
203,10,138,4,0,
203,10,148,4,0,
203,10,156,4,0,
203,10,164,4,0,
203,10,168,4,0,
203,10,173,3,0,
203,10,182,4,0,
203,10,189,3,0,
203,10,193,2,0,
203,10,203,4,0,
203,10,207,4,0,
203,10,213,4,0,
203,10,214,4,0,
203,10,216,4,0,
203,10,218,4,0,
203,10,226,1,23,
203,10,231,4,0,
203,10,237,4,0,
203,10,240,4,0,
203,10,241,4,0,
203,10,242,1,46,
203,10,243,2,0,
203,10,244,2,0,
203,10,244,4,0,
203,10,247,4,0,
203,10,248,2,0,
203,10,249,4,0,
203,10,251,2,0,
203,10,253,3,0,
203,10,263,4,0,
203,10,271,3,0,
203,10,273,2,0,
203,10,277,2,0,
203,10,277,3,0,
203,10,278,4,0,
203,10,285,4,0,
203,10,290,4,0,
203,10,310,1,1,3
203,10,316,1,5,
203,10,324,3,0,
203,10,347,4,0,
203,10,351,4,0,
203,10,356,3,0,
203,10,363,4,0,
203,10,372,1,28,
203,10,384,1,1,1
203,10,385,1,1,2
203,10,389,3,0,
203,10,412,4,0,
203,10,428,1,41,
203,10,428,3,0,
203,10,433,4,0,
203,10,445,4,0,
203,10,447,4,0,
203,10,451,4,0,
203,10,458,1,32,
203,11,13,2,0,
203,11,23,1,10,
203,11,24,2,0,
203,11,33,1,1,4
203,11,36,2,0,
203,11,45,1,1,5
203,11,60,1,19,
203,11,70,4,0,
203,11,85,4,0,
203,11,86,4,0,
203,11,87,4,0,
203,11,89,4,0,
203,11,92,4,0,
203,11,93,1,1,6
203,11,94,1,37,
203,11,94,4,0,
203,11,97,1,14,
203,11,104,4,0,
203,11,113,4,0,
203,11,115,4,0,
203,11,133,2,0,
203,11,138,4,0,
203,11,148,4,0,
203,11,156,4,0,
203,11,164,4,0,
203,11,168,4,0,
203,11,182,4,0,
203,11,193,2,0,
203,11,207,4,0,
203,11,212,2,0,
203,11,213,4,0,
203,11,216,4,0,
203,11,218,4,0,
203,11,226,1,23,
203,11,237,4,0,
203,11,240,4,0,
203,11,241,4,0,
203,11,242,1,46,
203,11,243,2,0,
203,11,244,4,0,
203,11,247,4,0,
203,11,248,2,0,
203,11,249,4,0,
203,11,251,2,0,
203,11,263,4,0,
203,11,273,2,0,
203,11,277,2,0,
203,11,285,2,0,
203,11,290,2,0,
203,11,310,1,1,3
203,11,316,1,5,
203,11,347,4,0,
203,11,372,1,28,
203,11,384,1,1,1
203,11,385,1,1,2
203,11,412,4,0,
203,11,428,1,41,
203,11,433,4,0,
203,11,447,4,0,
203,11,451,4,0,
203,11,458,1,32,
203,11,473,4,0,
203,11,477,4,0,
203,11,496,4,0,
203,11,497,4,0,
203,11,514,4,0,
203,11,523,4,0,
203,11,526,4,0,
203,12,23,1,19,
203,12,33,1,1,1
203,12,45,1,1,2
203,12,60,1,43,
203,12,70,4,0,
203,12,85,4,0,
203,12,87,4,0,
203,12,89,4,0,
203,12,92,4,0,
203,12,93,1,13,
203,12,94,4,0,
203,12,97,1,31,
203,12,104,4,0,
203,12,113,4,0,
203,12,115,4,0,
203,12,148,4,0,
203,12,156,4,0,
203,12,168,4,0,
203,12,182,4,0,
203,12,213,4,0,
203,12,216,4,0,
203,12,218,4,0,
203,12,226,1,37,
203,12,231,4,0,
203,12,237,4,0,
203,12,240,4,0,
203,12,241,4,0,
203,12,242,1,49,
203,12,247,4,0,
203,12,249,4,0,
203,12,263,4,0,
203,12,285,4,0,
203,12,290,4,0,
203,12,310,1,7,
203,12,316,1,25,
203,12,347,4,0,
203,12,351,4,0,
203,13,23,1,19,
203,13,33,1,1,1
203,13,34,3,0,
203,13,38,3,0,
203,13,45,1,1,2
203,13,60,1,43,
203,13,70,4,0,
203,13,85,4,0,
203,13,86,3,0,
203,13,87,4,0,
203,13,89,4,0,
203,13,92,4,0,
203,13,93,1,13,
203,13,94,4,0,
203,13,97,1,31,
203,13,102,3,0,
203,13,104,4,0,
203,13,113,4,0,
203,13,115,4,0,
203,13,138,3,0,
203,13,148,4,0,
203,13,156,4,0,
203,13,164,3,0,
203,13,168,4,0,
203,13,171,3,0,
203,13,182,4,0,
203,13,207,3,0,
203,13,213,4,0,
203,13,216,4,0,
203,13,218,4,0,
203,13,226,1,37,
203,13,231,4,0,
203,13,237,4,0,
203,13,240,4,0,
203,13,241,4,0,
203,13,242,1,49,
203,13,247,4,0,
203,13,249,4,0,
203,13,263,4,0,
203,13,285,4,0,
203,13,290,4,0,
203,13,310,1,7,
203,13,316,1,25,
203,13,347,4,0,
203,13,351,4,0,
203,14,13,2,0,
203,14,23,1,10,
203,14,24,2,0,
203,14,33,1,1,4
203,14,36,2,0,
203,14,45,1,1,5
203,14,60,1,19,
203,14,70,4,0,
203,14,85,4,0,
203,14,86,4,0,
203,14,87,4,0,
203,14,89,4,0,
203,14,92,4,0,
203,14,93,1,1,6
203,14,94,1,37,
203,14,94,4,0,
203,14,97,1,14,
203,14,104,4,0,
203,14,113,4,0,
203,14,115,4,0,
203,14,133,2,0,
203,14,138,4,0,
203,14,148,4,0,
203,14,156,4,0,
203,14,164,4,0,
203,14,168,4,0,
203,14,173,3,0,
203,14,182,4,0,
203,14,193,2,0,
203,14,207,4,0,
203,14,212,2,0,
203,14,213,4,0,
203,14,214,3,0,
203,14,216,4,0,
203,14,218,4,0,
203,14,226,1,23,
203,14,231,3,0,
203,14,237,4,0,
203,14,240,4,0,
203,14,241,4,0,
203,14,242,1,46,
203,14,243,2,0,
203,14,244,4,0,
203,14,247,4,0,
203,14,248,2,0,
203,14,249,4,0,
203,14,251,2,0,
203,14,253,3,0,
203,14,263,4,0,
203,14,271,3,0,
203,14,273,2,0,
203,14,277,2,0,
203,14,277,3,0,
203,14,278,3,0,
203,14,285,2,0,
203,14,285,3,0,
203,14,290,2,0,
203,14,304,3,0,
203,14,310,1,1,3
203,14,316,1,5,
203,14,324,3,0,
203,14,347,4,0,
203,14,356,3,0,
203,14,372,1,28,
203,14,384,1,1,1
203,14,385,1,1,2
203,14,412,4,0,
203,14,428,1,41,
203,14,428,3,0,
203,14,433,4,0,
203,14,447,4,0,
203,14,451,4,0,
203,14,458,1,32,
203,14,473,4,0,
203,14,477,4,0,
203,14,492,3,0,
203,14,496,4,0,
203,14,497,4,0,
203,14,514,4,0,
203,14,523,4,0,
203,14,526,4,0,
203,15,13,2,0,
203,15,23,1,10,
203,15,24,2,0,
203,15,33,1,1,4
203,15,36,2,0,
203,15,45,1,1,5
203,15,60,1,19,
203,15,70,4,0,
203,15,85,4,0,
203,15,86,4,0,
203,15,87,4,0,
203,15,89,4,0,
203,15,92,4,0,
203,15,93,1,1,6
203,15,94,1,37,
203,15,94,4,0,
203,15,97,1,14,
203,15,104,4,0,
203,15,113,4,0,
203,15,115,4,0,
203,15,133,2,0,
203,15,138,4,0,
203,15,148,4,0,
203,15,156,4,0,
203,15,164,4,0,
203,15,168,4,0,
203,15,182,4,0,
203,15,193,2,0,
203,15,207,4,0,
203,15,212,2,0,
203,15,213,4,0,
203,15,214,4,0,
203,15,216,4,0,
203,15,218,4,0,
203,15,226,1,23,
203,15,237,4,0,
203,15,240,4,0,
203,15,241,4,0,
203,15,242,1,46,
203,15,243,2,0,
203,15,244,4,0,
203,15,247,4,0,
203,15,248,2,0,
203,15,249,4,0,
203,15,251,2,0,
203,15,263,4,0,
203,15,273,2,0,
203,15,277,2,0,
203,15,285,2,0,
203,15,290,2,0,
203,15,310,1,1,3
203,15,316,1,5,
203,15,347,4,0,
203,15,372,1,28,
203,15,384,1,1,1
203,15,385,1,1,2
203,15,412,4,0,
203,15,428,1,41,
203,15,433,4,0,
203,15,447,4,0,
203,15,451,4,0,
203,15,458,1,32,
203,15,473,4,0,
203,15,496,4,0,
203,15,497,4,0,
203,15,514,4,0,
203,15,523,4,0,
203,15,590,4,0,
203,15,605,4,0,
203,16,13,2,0,
203,16,23,1,14,1
203,16,24,2,0,
203,16,33,1,1,4
203,16,36,2,0,
203,16,45,1,1,5
203,16,60,1,19,1
203,16,70,4,0,
203,16,85,4,0,
203,16,86,4,0,
203,16,87,4,0,
203,16,89,4,0,
203,16,92,4,0,
203,16,93,1,1,6
203,16,94,1,50,1
203,16,94,4,0,
203,16,97,1,23,1
203,16,104,4,0,
203,16,113,4,0,
203,16,115,4,0,
203,16,133,2,0,
203,16,138,4,0,
203,16,148,4,0,
203,16,156,4,0,
203,16,164,4,0,
203,16,168,4,0,
203,16,173,3,0,
203,16,182,4,0,
203,16,193,2,0,
203,16,207,4,0,
203,16,212,2,0,
203,16,213,4,0,
203,16,214,4,0,
203,16,216,4,0,
203,16,218,4,0,
203,16,226,1,41,1
203,16,231,3,0,
203,16,237,4,0,
203,16,240,4,0,
203,16,241,4,0,
203,16,242,1,37,1
203,16,243,2,0,
203,16,244,4,0,
203,16,247,4,0,
203,16,248,2,0,
203,16,249,4,0,
203,16,251,2,0,
203,16,253,3,0,
203,16,263,4,0,
203,16,271,3,0,
203,16,273,2,0,
203,16,277,2,0,
203,16,277,3,0,
203,16,278,3,0,
203,16,285,2,0,
203,16,285,3,0,
203,16,290,2,0,
203,16,290,4,0,
203,16,304,3,0,
203,16,310,1,1,3
203,16,316,1,5,1
203,16,324,3,0,
203,16,347,4,0,
203,16,351,3,0,
203,16,356,3,0,
203,16,372,1,10,1
203,16,384,1,1,1
203,16,385,1,1,2
203,16,412,4,0,
203,16,417,1,46,1
203,16,428,1,32,1
203,16,428,3,0,
203,16,433,4,0,
203,16,447,4,0,
203,16,451,4,0,
203,16,458,1,28,1
203,16,473,4,0,
203,16,492,3,0,
203,16,496,4,0,
203,16,497,4,0,
203,16,514,4,0,
203,16,523,4,0,
203,16,590,4,0,
203,16,605,4,0,
203,17,13,2,0,
203,17,23,1,14,
203,17,24,2,0,
203,17,33,1,1,4
203,17,36,2,0,
203,17,45,1,1,5
203,17,60,1,19,
203,17,85,4,0,
203,17,86,4,0,
203,17,87,4,0,
203,17,89,4,0,
203,17,92,4,0,
203,17,93,1,1,6
203,17,94,1,50,
203,17,94,4,0,
203,17,97,1,23,
203,17,104,4,0,
203,17,113,4,0,
203,17,115,4,0,
203,17,133,2,0,
203,17,138,4,0,
203,17,156,4,0,
203,17,164,4,0,
203,17,168,4,0,
203,17,182,4,0,
203,17,193,2,0,
203,17,207,4,0,
203,17,212,2,0,
203,17,213,4,0,
203,17,214,4,0,
203,17,216,4,0,
203,17,218,4,0,
203,17,226,1,41,
203,17,237,4,0,
203,17,240,4,0,
203,17,241,4,0,
203,17,242,1,37,
203,17,243,2,0,
203,17,244,4,0,
203,17,247,4,0,
203,17,248,2,0,
203,17,251,2,0,
203,17,263,4,0,
203,17,273,2,0,
203,17,277,2,0,
203,17,285,2,0,
203,17,290,2,0,
203,17,310,1,1,3
203,17,316,1,5,
203,17,347,4,0,
203,17,372,1,10,
203,17,384,1,1,1
203,17,385,1,1,2
203,17,412,4,0,
203,17,417,1,46,
203,17,428,1,32,
203,17,433,4,0,
203,17,447,4,0,
203,17,451,4,0,
203,17,458,1,28,
203,17,473,4,0,
203,17,496,4,0,
203,17,497,4,0,
203,17,523,4,0,
203,17,526,4,0,
203,17,590,4,0,
203,17,605,4,0,
203,18,13,2,0,
203,18,23,1,14,
203,18,24,2,0,
203,18,33,1,1,4
203,18,36,2,0,
203,18,45,1,1,5
203,18,60,1,19,
203,18,85,4,0,
203,18,86,4,0,
203,18,87,4,0,
203,18,89,4,0,
203,18,92,4,0,
203,18,93,1,1,6
203,18,94,1,50,
203,18,94,4,0,
203,18,97,1,23,
203,18,104,4,0,
203,18,113,4,0,
203,18,115,4,0,
203,18,133,2,0,
203,18,138,4,0,
203,18,156,4,0,
203,18,164,4,0,
203,18,168,4,0,
203,18,182,4,0,
203,18,193,2,0,
203,18,207,4,0,
203,18,212,2,0,
203,18,213,4,0,
203,18,214,4,0,
203,18,216,4,0,
203,18,218,4,0,
203,18,226,1,41,
203,18,237,4,0,
203,18,240,4,0,
203,18,241,4,0,
203,18,242,1,37,
203,18,243,2,0,
203,18,244,4,0,
203,18,247,4,0,
203,18,248,2,0,
203,18,251,2,0,
203,18,263,4,0,
203,18,273,2,0,
203,18,277,2,0,
203,18,285,2,0,
203,18,290,2,0,
203,18,310,1,1,3
203,18,316,1,5,
203,18,347,4,0,
203,18,372,1,10,
203,18,384,1,1,1
203,18,385,1,1,2
203,18,412,4,0,
203,18,417,1,46,
203,18,428,1,32,
203,18,433,4,0,
203,18,447,4,0,
203,18,451,4,0,
203,18,458,1,28,
203,18,473,4,0,
203,18,496,4,0,
203,18,497,4,0,
203,18,523,4,0,
203,18,526,4,0,
203,18,590,4,0,
203,18,605,4,0,
203,18,678,2,0,
203,18,706,2,0,
204,3,29,4,0,
204,3,33,1,1,1
204,3,36,1,15,
204,3,38,1,50,
204,3,42,2,0,
204,3,70,4,0,
204,3,76,4,0,
204,3,92,4,0,
204,3,104,4,0,
204,3,111,4,0,
204,3,115,2,0,
204,3,117,1,29,
204,3,120,1,8,
204,3,129,2,0,
204,3,153,1,36,
204,3,156,4,0,
204,3,173,4,0,
204,3,174,4,0,
204,3,175,2,0,
204,3,182,1,1,2
204,3,182,4,0,
204,3,191,1,43,
204,3,202,4,0,
204,3,203,4,0,
204,3,205,4,0,
204,3,207,4,0,
204,3,213,4,0,
204,3,214,4,0,
204,3,216,4,0,
204,3,218,4,0,
204,3,229,1,22,
204,3,230,4,0,
204,3,237,4,0,
204,3,241,4,0,
204,3,249,4,0,
204,4,29,4,0,
204,4,33,1,1,1
204,4,36,1,15,
204,4,38,1,50,
204,4,42,2,0,
204,4,70,4,0,
204,4,76,4,0,
204,4,92,4,0,
204,4,104,4,0,
204,4,111,4,0,
204,4,115,2,0,
204,4,117,1,29,
204,4,120,1,8,
204,4,129,2,0,
204,4,153,1,36,
204,4,156,4,0,
204,4,173,4,0,
204,4,174,4,0,
204,4,175,2,0,
204,4,182,1,1,2
204,4,182,4,0,
204,4,191,1,43,
204,4,202,4,0,
204,4,203,4,0,
204,4,205,4,0,
204,4,207,4,0,
204,4,213,4,0,
204,4,214,4,0,
204,4,216,4,0,
204,4,218,4,0,
204,4,229,1,22,
204,4,230,4,0,
204,4,237,4,0,
204,4,241,4,0,
204,4,249,4,0,
204,5,33,1,1,1
204,5,36,1,15,
204,5,38,1,50,
204,5,42,2,0,
204,5,68,2,0,
204,5,70,4,0,
204,5,76,4,0,
204,5,89,4,0,
204,5,91,4,0,
204,5,92,4,0,
204,5,104,4,0,
204,5,113,4,0,
204,5,115,2,0,
204,5,115,4,0,
204,5,117,1,29,
204,5,120,1,8,
204,5,129,2,0,
204,5,153,1,36,
204,5,156,4,0,
204,5,175,2,0,
204,5,182,1,1,2
204,5,182,4,0,
204,5,191,1,43,
204,5,201,4,0,
204,5,202,4,0,
204,5,213,4,0,
204,5,216,4,0,
204,5,218,4,0,
204,5,229,1,22,
204,5,237,4,0,
204,5,241,4,0,
204,5,249,4,0,
204,5,263,4,0,
204,5,290,4,0,
204,5,328,2,0,
204,6,33,1,1,1
204,6,34,3,0,
204,6,36,1,15,
204,6,38,1,50,
204,6,38,3,0,
204,6,42,2,0,
204,6,68,2,0,
204,6,68,3,0,
204,6,70,4,0,
204,6,76,4,0,
204,6,89,4,0,
204,6,91,4,0,
204,6,92,4,0,
204,6,102,3,0,
204,6,104,4,0,
204,6,111,3,0,
204,6,113,4,0,
204,6,115,2,0,
204,6,115,4,0,
204,6,117,1,29,
204,6,120,1,8,
204,6,129,2,0,
204,6,153,1,36,
204,6,153,3,0,
204,6,156,4,0,
204,6,157,3,0,
204,6,164,3,0,
204,6,173,3,0,
204,6,175,2,0,
204,6,182,1,1,2
204,6,182,4,0,
204,6,191,1,43,
204,6,201,4,0,
204,6,202,4,0,
204,6,203,3,0,
204,6,205,3,0,
204,6,207,3,0,
204,6,213,4,0,
204,6,214,3,0,
204,6,216,4,0,
204,6,218,4,0,
204,6,229,1,22,
204,6,237,4,0,
204,6,241,4,0,
204,6,249,4,0,
204,6,263,4,0,
204,6,290,4,0,
204,6,328,2,0,
204,7,33,1,1,1
204,7,34,3,0,
204,7,36,1,15,
204,7,38,1,50,
204,7,38,3,0,
204,7,42,2,0,
204,7,68,2,0,
204,7,68,3,0,
204,7,70,4,0,
204,7,76,4,0,
204,7,89,4,0,
204,7,91,4,0,
204,7,92,4,0,
204,7,102,3,0,
204,7,104,4,0,
204,7,113,4,0,
204,7,115,2,0,
204,7,115,4,0,
204,7,117,1,29,
204,7,120,1,8,
204,7,129,2,0,
204,7,153,1,36,
204,7,153,3,0,
204,7,156,4,0,
204,7,157,3,0,
204,7,164,3,0,
204,7,175,2,0,
204,7,182,1,1,2
204,7,182,4,0,
204,7,191,1,43,
204,7,201,4,0,
204,7,202,4,0,
204,7,213,4,0,
204,7,216,4,0,
204,7,218,4,0,
204,7,229,1,22,
204,7,237,4,0,
204,7,241,4,0,
204,7,249,4,0,
204,7,263,4,0,
204,7,290,4,0,
204,7,328,2,0,
204,8,33,1,1,1
204,8,36,1,9,
204,8,38,1,42,
204,8,38,2,0,
204,8,42,2,0,
204,8,68,2,0,
204,8,70,4,0,
204,8,76,4,0,
204,8,89,4,0,
204,8,91,4,0,
204,8,92,4,0,
204,8,104,4,0,
204,8,113,4,0,
204,8,115,2,0,
204,8,115,4,0,
204,8,117,1,17,
204,8,120,1,6,
204,8,129,2,0,
204,8,153,1,31,
204,8,153,4,0,
204,8,156,4,0,
204,8,157,4,0,
204,8,164,4,0,
204,8,175,2,0,
204,8,182,1,1,2
204,8,182,4,0,
204,8,191,1,23,
204,8,201,4,0,
204,8,202,4,0,
204,8,203,4,0,
204,8,207,4,0,
204,8,213,4,0,
204,8,214,4,0,
204,8,216,4,0,
204,8,218,4,0,
204,8,229,1,12,
204,8,237,4,0,
204,8,241,4,0,
204,8,249,4,0,
204,8,263,4,0,
204,8,279,2,0,
204,8,290,4,0,
204,8,317,4,0,
204,8,328,2,0,
204,8,334,1,34,
204,8,360,1,39,
204,8,360,4,0,
204,8,363,1,20,
204,8,363,4,0,
204,8,371,1,28,
204,8,371,4,0,
204,8,390,2,0,
204,8,445,4,0,
204,8,446,4,0,
204,9,33,1,1,1
204,9,36,1,12,
204,9,38,1,45,
204,9,38,2,0,
204,9,42,2,0,
204,9,68,2,0,
204,9,70,4,0,
204,9,76,4,0,
204,9,89,4,0,
204,9,91,4,0,
204,9,92,4,0,
204,9,104,4,0,
204,9,113,4,0,
204,9,115,2,0,
204,9,115,4,0,
204,9,117,1,20,
204,9,120,1,6,
204,9,129,2,0,
204,9,153,1,34,
204,9,153,4,0,
204,9,156,4,0,
204,9,157,4,0,
204,9,164,4,0,
204,9,173,3,0,
204,9,175,2,0,
204,9,182,1,1,2
204,9,182,4,0,
204,9,191,1,28,
204,9,201,4,0,
204,9,202,4,0,
204,9,203,4,0,
204,9,205,3,0,
204,9,207,4,0,
204,9,213,4,0,
204,9,214,4,0,
204,9,216,4,0,
204,9,218,4,0,
204,9,229,1,17,
204,9,237,4,0,
204,9,241,4,0,
204,9,249,4,0,
204,9,263,4,0,
204,9,279,2,0,
204,9,290,4,0,
204,9,317,4,0,
204,9,328,2,0,
204,9,334,1,39,
204,9,334,3,0,
204,9,360,1,42,
204,9,360,4,0,
204,9,363,1,23,
204,9,363,4,0,
204,9,371,1,31,
204,9,371,4,0,
204,9,390,2,0,
204,9,445,4,0,
204,9,446,4,0,
204,9,450,1,9,
204,10,29,3,0,
204,10,33,1,1,1
204,10,36,1,12,
204,10,38,1,45,
204,10,38,2,0,
204,10,42,2,0,
204,10,68,2,0,
204,10,70,4,0,
204,10,76,4,0,
204,10,81,3,0,
204,10,89,4,0,
204,10,91,4,0,
204,10,92,4,0,
204,10,104,4,0,
204,10,113,4,0,
204,10,115,2,0,
204,10,115,4,0,
204,10,117,1,20,
204,10,120,1,6,
204,10,129,2,0,
204,10,153,1,34,
204,10,153,4,0,
204,10,156,4,0,
204,10,157,4,0,
204,10,164,4,0,
204,10,173,3,0,
204,10,175,2,0,
204,10,182,1,1,2
204,10,182,4,0,
204,10,191,1,28,
204,10,201,4,0,
204,10,202,4,0,
204,10,203,4,0,
204,10,205,3,0,
204,10,207,4,0,
204,10,213,4,0,
204,10,214,4,0,
204,10,216,4,0,
204,10,218,4,0,
204,10,220,3,0,
204,10,229,1,17,
204,10,237,4,0,
204,10,241,4,0,
204,10,249,4,0,
204,10,263,4,0,
204,10,279,2,0,
204,10,290,4,0,
204,10,317,4,0,
204,10,328,2,0,
204,10,334,1,39,
204,10,334,3,0,
204,10,356,3,0,
204,10,360,1,42,
204,10,360,4,0,
204,10,363,1,23,
204,10,363,4,0,
204,10,371,1,31,
204,10,371,4,0,
204,10,379,2,0,
204,10,390,2,0,
204,10,445,4,0,
204,10,446,4,0,
204,10,450,1,9,
204,10,450,3,0,
204,11,33,1,1,1
204,11,36,1,12,
204,11,38,1,45,
204,11,38,2,0,
204,11,42,2,0,
204,11,68,2,0,
204,11,70,4,0,
204,11,76,4,0,
204,11,89,4,0,
204,11,91,4,0,
204,11,92,4,0,
204,11,104,4,0,
204,11,113,4,0,
204,11,115,4,0,
204,11,117,1,20,
204,11,120,1,6,
204,11,129,2,0,
204,11,153,1,34,
204,11,153,4,0,
204,11,156,4,0,
204,11,157,4,0,
204,11,164,4,0,
204,11,175,2,0,
204,11,182,1,1,2
204,11,182,4,0,
204,11,191,1,28,
204,11,201,4,0,
204,11,203,2,0,
204,11,207,4,0,
204,11,213,4,0,
204,11,216,4,0,
204,11,218,4,0,
204,11,229,1,17,
204,11,237,4,0,
204,11,241,4,0,
204,11,249,4,0,
204,11,263,4,0,
204,11,279,2,0,
204,11,317,4,0,
204,11,328,2,0,
204,11,334,1,39,
204,11,360,1,42,
204,11,360,4,0,
204,11,363,1,23,
204,11,371,1,31,
204,11,371,4,0,
204,11,379,2,0,
204,11,390,2,0,
204,11,446,2,0,
204,11,450,1,9,
204,11,474,4,0,
204,11,496,4,0,
204,11,522,4,0,
204,11,523,4,0,
204,12,33,1,1,1
204,12,36,1,15,
204,12,38,1,50,
204,12,70,4,0,
204,12,76,4,0,
204,12,89,4,0,
204,12,91,4,0,
204,12,92,4,0,
204,12,104,4,0,
204,12,113,4,0,
204,12,115,4,0,
204,12,117,1,29,
204,12,120,1,8,
204,12,153,1,36,
204,12,156,4,0,
204,12,182,1,1,2
204,12,182,4,0,
204,12,191,1,43,
204,12,201,4,0,
204,12,202,4,0,
204,12,213,4,0,
204,12,216,4,0,
204,12,218,4,0,
204,12,229,1,22,
204,12,237,4,0,
204,12,241,4,0,
204,12,249,4,0,
204,12,263,4,0,
204,12,290,4,0,
204,13,33,1,1,1
204,13,34,3,0,
204,13,36,1,15,
204,13,38,1,50,
204,13,38,3,0,
204,13,70,4,0,
204,13,76,4,0,
204,13,89,4,0,
204,13,91,4,0,
204,13,92,4,0,
204,13,102,3,0,
204,13,104,4,0,
204,13,113,4,0,
204,13,115,4,0,
204,13,117,1,29,
204,13,120,1,8,
204,13,120,3,0,
204,13,153,1,36,
204,13,156,4,0,
204,13,164,3,0,
204,13,182,1,1,2
204,13,182,4,0,
204,13,191,1,43,
204,13,201,4,0,
204,13,202,4,0,
204,13,207,3,0,
204,13,213,4,0,
204,13,216,4,0,
204,13,218,4,0,
204,13,229,1,22,
204,13,237,4,0,
204,13,241,4,0,
204,13,249,4,0,
204,13,263,4,0,
204,13,290,4,0,
204,14,33,1,1,1
204,14,36,1,12,
204,14,38,1,45,
204,14,38,2,0,
204,14,42,2,0,
204,14,68,2,0,
204,14,70,4,0,
204,14,76,4,0,
204,14,89,4,0,
204,14,91,4,0,
204,14,92,4,0,
204,14,104,4,0,
204,14,113,4,0,
204,14,115,4,0,
204,14,117,1,20,
204,14,120,1,6,
204,14,129,2,0,
204,14,153,1,34,
204,14,153,4,0,
204,14,156,4,0,
204,14,157,4,0,
204,14,164,4,0,
204,14,173,3,0,
204,14,175,2,0,
204,14,182,1,1,2
204,14,182,4,0,
204,14,191,1,28,
204,14,201,4,0,
204,14,202,3,0,
204,14,203,2,0,
204,14,207,4,0,
204,14,213,4,0,
204,14,214,3,0,
204,14,216,4,0,
204,14,218,4,0,
204,14,220,3,0,
204,14,229,1,17,
204,14,237,4,0,
204,14,241,4,0,
204,14,249,4,0,
204,14,263,4,0,
204,14,279,2,0,
204,14,317,4,0,
204,14,328,2,0,
204,14,334,1,39,
204,14,334,3,0,
204,14,356,3,0,
204,14,360,1,42,
204,14,360,4,0,
204,14,363,1,23,
204,14,371,1,31,
204,14,371,4,0,
204,14,379,2,0,
204,14,390,2,0,
204,14,446,2,0,
204,14,446,3,0,
204,14,450,1,9,
204,14,450,3,0,
204,14,474,4,0,
204,14,496,4,0,
204,14,522,4,0,
204,14,523,4,0,
204,14,529,3,0,
204,15,33,1,1,1
204,15,36,1,12,
204,15,38,1,45,
204,15,38,2,0,
204,15,42,2,0,
204,15,68,2,0,
204,15,70,4,0,
204,15,76,4,0,
204,15,89,4,0,
204,15,91,4,0,
204,15,92,4,0,
204,15,104,4,0,
204,15,113,4,0,
204,15,115,4,0,
204,15,117,1,20,
204,15,120,1,6,
204,15,129,2,0,
204,15,153,1,34,
204,15,153,4,0,
204,15,156,4,0,
204,15,157,4,0,
204,15,164,4,0,
204,15,175,2,0,
204,15,182,1,1,2
204,15,182,4,0,
204,15,191,1,28,
204,15,201,4,0,
204,15,203,2,0,
204,15,207,4,0,
204,15,213,4,0,
204,15,214,4,0,
204,15,216,4,0,
204,15,218,4,0,
204,15,229,1,17,
204,15,237,4,0,
204,15,241,4,0,
204,15,249,4,0,
204,15,263,4,0,
204,15,279,2,0,
204,15,317,4,0,
204,15,328,2,0,
204,15,334,1,39,
204,15,360,1,42,
204,15,360,4,0,
204,15,363,1,23,
204,15,371,1,31,
204,15,371,4,0,
204,15,379,2,0,
204,15,390,2,0,
204,15,446,2,0,
204,15,450,1,9,
204,15,474,4,0,
204,15,496,4,0,
204,15,522,4,0,
204,15,523,4,0,
204,15,590,4,0,
204,16,33,1,1,1
204,16,36,1,12,1
204,16,38,1,45,1
204,16,38,2,0,
204,16,42,2,0,
204,16,68,2,0,
204,16,70,4,0,
204,16,76,4,0,
204,16,89,4,0,
204,16,91,4,0,
204,16,92,4,0,
204,16,104,4,0,
204,16,113,4,0,
204,16,115,4,0,
204,16,117,1,20,1
204,16,120,1,6,1
204,16,129,2,0,
204,16,153,1,34,1
204,16,153,4,0,
204,16,156,4,0,
204,16,157,4,0,
204,16,164,4,0,
204,16,173,3,0,
204,16,175,2,0,
204,16,182,1,1,2
204,16,182,4,0,
204,16,191,1,28,1
204,16,201,4,0,
204,16,202,3,0,
204,16,203,2,0,
204,16,207,4,0,
204,16,213,4,0,
204,16,214,4,0,
204,16,216,4,0,
204,16,218,4,0,
204,16,220,3,0,
204,16,229,1,17,1
204,16,237,4,0,
204,16,241,4,0,
204,16,249,4,0,
204,16,263,4,0,
204,16,279,2,0,
204,16,290,4,0,
204,16,317,4,0,
204,16,328,2,0,
204,16,334,1,39,1
204,16,334,3,0,
204,16,356,3,0,
204,16,360,1,42,1
204,16,360,4,0,
204,16,363,1,23,1
204,16,371,1,31,1
204,16,371,4,0,
204,16,379,2,0,
204,16,390,2,0,
204,16,446,2,0,
204,16,446,3,0,
204,16,450,1,9,1
204,16,450,3,0,
204,16,474,4,0,
204,16,496,4,0,
204,16,522,4,0,
204,16,523,4,0,
204,16,529,3,0,
204,16,590,4,0,
204,17,33,1,1,1
204,17,36,1,12,
204,17,38,1,45,
204,17,38,2,0,
204,17,42,2,0,
204,17,68,2,0,
204,17,76,4,0,
204,17,89,4,0,
204,17,92,4,0,
204,17,104,4,0,
204,17,113,4,0,
204,17,115,4,0,
204,17,117,1,20,
204,17,120,1,6,
204,17,129,2,0,
204,17,153,1,34,
204,17,153,4,0,
204,17,156,4,0,
204,17,157,4,0,
204,17,164,4,0,
204,17,175,2,0,
204,17,182,1,1,2
204,17,182,4,0,
204,17,191,1,28,
204,17,201,4,0,
204,17,203,2,0,
204,17,207,4,0,
204,17,213,4,0,
204,17,214,4,0,
204,17,216,4,0,
204,17,218,4,0,
204,17,229,1,17,
204,17,237,4,0,
204,17,241,4,0,
204,17,263,4,0,
204,17,279,2,0,
204,17,317,4,0,
204,17,328,2,0,
204,17,334,1,39,
204,17,360,1,42,
204,17,360,4,0,
204,17,363,1,23,
204,17,371,1,31,
204,17,371,4,0,
204,17,379,2,0,
204,17,390,2,0,
204,17,446,2,0,
204,17,450,1,9,
204,17,474,4,0,
204,17,496,4,0,
204,17,523,4,0,
204,17,590,4,0,
204,18,33,1,1,1
204,18,36,1,12,
204,18,38,1,45,
204,18,38,2,0,
204,18,42,2,0,
204,18,68,2,0,
204,18,76,4,0,
204,18,89,4,0,
204,18,92,4,0,
204,18,104,4,0,
204,18,113,4,0,
204,18,115,4,0,
204,18,117,1,20,
204,18,120,1,6,
204,18,129,2,0,
204,18,153,1,34,
204,18,153,4,0,
204,18,156,4,0,
204,18,157,4,0,
204,18,164,4,0,
204,18,175,2,0,
204,18,182,1,1,2
204,18,182,4,0,
204,18,191,1,28,
204,18,201,4,0,
204,18,203,2,0,
204,18,207,4,0,
204,18,213,4,0,
204,18,214,4,0,
204,18,216,4,0,
204,18,218,4,0,
204,18,229,1,17,
204,18,237,4,0,
204,18,241,4,0,
204,18,263,4,0,
204,18,279,2,0,
204,18,317,4,0,
204,18,328,2,0,
204,18,334,1,39,
204,18,360,1,42,
204,18,360,4,0,
204,18,363,1,23,
204,18,371,1,31,
204,18,371,4,0,
204,18,379,2,0,
204,18,390,2,0,
204,18,446,2,0,
204,18,450,1,9,
204,18,474,4,0,
204,18,496,4,0,
204,18,523,4,0,
204,18,590,4,0,
205,3,29,4,0,
205,3,33,1,1,1
205,3,36,1,15,
205,3,38,1,59,
205,3,63,4,0,
205,3,70,4,0,
205,3,76,4,0,
205,3,92,4,0,
205,3,104,4,0,
205,3,111,4,0,
205,3,117,1,29,
205,3,120,1,1,3
205,3,120,1,8,
205,3,153,1,39,
205,3,156,4,0,
205,3,173,4,0,
205,3,174,4,0,
205,3,182,1,1,2
205,3,182,4,0,
205,3,191,1,49,
205,3,201,4,0,
205,3,202,4,0,
205,3,203,4,0,
205,3,205,4,0,
205,3,207,4,0,
205,3,213,4,0,
205,3,214,4,0,
205,3,216,4,0,
205,3,218,4,0,
205,3,229,1,22,
205,3,230,4,0,
205,3,237,4,0,
205,3,241,4,0,
205,3,249,4,0,
205,4,29,4,0,
205,4,33,1,1,1
205,4,36,1,15,
205,4,38,1,59,
205,4,63,4,0,
205,4,70,4,0,
205,4,76,4,0,
205,4,92,4,0,
205,4,104,4,0,
205,4,111,4,0,
205,4,117,1,29,
205,4,120,1,1,3
205,4,120,1,8,
205,4,153,1,39,
205,4,156,4,0,
205,4,173,4,0,
205,4,174,4,0,
205,4,182,1,1,2
205,4,182,4,0,
205,4,191,1,49,
205,4,201,4,0,
205,4,202,4,0,
205,4,203,4,0,
205,4,205,4,0,
205,4,207,4,0,
205,4,213,4,0,
205,4,214,4,0,
205,4,216,4,0,
205,4,218,4,0,
205,4,229,1,22,
205,4,230,4,0,
205,4,237,4,0,
205,4,241,4,0,
205,4,249,4,0,
205,5,33,1,1,1
205,5,36,1,15,
205,5,38,1,59,
205,5,63,4,0,
205,5,70,4,0,
205,5,76,4,0,
205,5,89,4,0,
205,5,91,4,0,
205,5,92,4,0,
205,5,104,4,0,
205,5,113,4,0,
205,5,115,4,0,
205,5,117,1,29,
205,5,120,1,1,3
205,5,120,1,8,
205,5,153,1,39,
205,5,156,4,0,
205,5,182,1,1,2
205,5,182,4,0,
205,5,191,1,49,
205,5,201,4,0,
205,5,202,4,0,
205,5,213,4,0,
205,5,216,4,0,
205,5,218,4,0,
205,5,229,1,22,
205,5,237,4,0,
205,5,241,4,0,
205,5,249,4,0,
205,5,263,4,0,
205,5,290,4,0,
205,6,33,1,1,1
205,6,34,3,0,
205,6,36,1,15,
205,6,38,1,59,
205,6,38,3,0,
205,6,63,4,0,
205,6,68,3,0,
205,6,70,4,0,
205,6,76,4,0,
205,6,89,4,0,
205,6,91,4,0,
205,6,92,4,0,
205,6,102,3,0,
205,6,104,4,0,
205,6,111,3,0,
205,6,113,4,0,
205,6,115,4,0,
205,6,117,1,29,
205,6,120,1,1,3
205,6,120,1,8,
205,6,153,1,39,
205,6,153,3,0,
205,6,156,4,0,
205,6,157,3,0,
205,6,164,3,0,
205,6,173,3,0,
205,6,182,1,1,2
205,6,182,4,0,
205,6,191,1,49,
205,6,201,4,0,
205,6,202,4,0,
205,6,203,3,0,
205,6,205,3,0,
205,6,207,3,0,
205,6,213,4,0,
205,6,214,3,0,
205,6,216,4,0,
205,6,218,4,0,
205,6,229,1,22,
205,6,237,4,0,
205,6,241,4,0,
205,6,249,4,0,
205,6,263,4,0,
205,6,290,4,0,
205,7,33,1,1,1
205,7,34,3,0,
205,7,36,1,15,
205,7,38,1,59,
205,7,38,3,0,
205,7,63,4,0,
205,7,68,3,0,
205,7,70,4,0,
205,7,76,4,0,
205,7,89,4,0,
205,7,91,4,0,
205,7,92,4,0,
205,7,102,3,0,
205,7,104,4,0,
205,7,113,4,0,
205,7,115,4,0,
205,7,117,1,29,
205,7,120,1,1,3
205,7,120,1,8,
205,7,153,1,39,
205,7,153,3,0,
205,7,156,4,0,
205,7,157,3,0,
205,7,164,3,0,
205,7,182,1,1,2
205,7,182,4,0,
205,7,191,1,49,
205,7,192,1,31,
205,7,201,4,0,
205,7,202,4,0,
205,7,213,4,0,
205,7,216,4,0,
205,7,218,4,0,
205,7,229,1,22,
205,7,237,4,0,
205,7,241,4,0,
205,7,249,4,0,
205,7,263,4,0,
205,7,290,4,0,
205,8,33,1,1,2
205,8,36,1,1,5
205,8,36,1,9,
205,8,38,1,50,
205,8,63,4,0,
205,8,70,4,0,
205,8,76,4,0,
205,8,89,4,0,
205,8,91,4,0,
205,8,92,4,0,
205,8,104,4,0,
205,8,113,4,0,
205,8,115,4,0,
205,8,117,1,17,
205,8,120,1,1,4
205,8,120,1,6,
205,8,153,1,33,
205,8,153,4,0,
205,8,156,4,0,
205,8,157,4,0,
205,8,164,4,0,
205,8,182,1,1,3
205,8,182,4,0,
205,8,191,1,23,
205,8,192,1,62,
205,8,201,4,0,
205,8,202,4,0,
205,8,203,4,0,
205,8,207,4,0,
205,8,213,4,0,
205,8,214,4,0,
205,8,216,4,0,
205,8,218,4,0,
205,8,229,1,12,
205,8,237,4,0,
205,8,241,4,0,
205,8,249,4,0,
205,8,263,4,0,
205,8,290,4,0,
205,8,317,4,0,
205,8,334,1,38,
205,8,360,1,45,
205,8,360,4,0,
205,8,363,1,20,
205,8,363,4,0,
205,8,371,1,28,
205,8,371,4,0,
205,8,390,1,1,1
205,8,393,1,57,
205,8,397,4,0,
205,8,416,4,0,
205,8,429,1,31,
205,8,430,4,0,
205,8,445,4,0,
205,8,446,4,0,
205,9,33,1,1,2
205,9,36,1,12,
205,9,38,1,55,
205,9,63,4,0,
205,9,70,4,0,
205,9,76,4,0,
205,9,89,4,0,
205,9,91,4,0,
205,9,92,4,0,
205,9,104,4,0,
205,9,113,4,0,
205,9,115,4,0,
205,9,117,1,20,
205,9,120,1,1,4
205,9,120,1,6,
205,9,153,1,38,
205,9,153,4,0,
205,9,156,4,0,
205,9,157,4,0,
205,9,164,4,0,
205,9,173,3,0,
205,9,182,1,1,3
205,9,182,4,0,
205,9,191,1,28,
205,9,192,1,67,
205,9,201,4,0,
205,9,202,4,0,
205,9,203,4,0,
205,9,205,3,0,
205,9,207,4,0,
205,9,213,4,0,
205,9,214,4,0,
205,9,216,4,0,
205,9,218,4,0,
205,9,229,1,17,
205,9,237,4,0,
205,9,241,4,0,
205,9,249,4,0,
205,9,263,4,0,
205,9,290,4,0,
205,9,317,4,0,
205,9,324,3,0,
205,9,334,1,45,
205,9,334,3,0,
205,9,360,1,50,
205,9,360,4,0,
205,9,363,1,23,
205,9,363,4,0,
205,9,371,1,33,
205,9,371,4,0,
205,9,390,1,1,1
205,9,393,1,62,
205,9,393,3,0,
205,9,397,4,0,
205,9,416,4,0,
205,9,429,1,31,
205,9,430,4,0,
205,9,445,4,0,
205,9,446,4,0,
205,9,450,1,1,5
205,9,450,1,9,
205,10,29,3,0,
205,10,33,1,1,2
205,10,36,1,12,
205,10,38,1,55,
205,10,63,4,0,
205,10,70,4,0,
205,10,76,4,0,
205,10,81,3,0,
205,10,89,4,0,
205,10,91,4,0,
205,10,92,4,0,
205,10,104,4,0,
205,10,113,4,0,
205,10,115,4,0,
205,10,117,1,20,
205,10,120,1,1,4
205,10,120,1,6,
205,10,153,1,38,
205,10,153,4,0,
205,10,156,4,0,
205,10,157,4,0,
205,10,164,4,0,
205,10,173,3,0,
205,10,182,1,1,3
205,10,182,4,0,
205,10,191,1,28,
205,10,192,1,67,
205,10,201,4,0,
205,10,202,4,0,
205,10,203,4,0,
205,10,205,3,0,
205,10,207,4,0,
205,10,213,4,0,
205,10,214,4,0,
205,10,216,4,0,
205,10,218,4,0,
205,10,220,3,0,
205,10,229,1,17,
205,10,237,4,0,
205,10,241,4,0,
205,10,249,4,0,
205,10,263,4,0,
205,10,290,4,0,
205,10,317,4,0,
205,10,324,3,0,
205,10,334,1,45,
205,10,334,3,0,
205,10,335,3,0,
205,10,356,3,0,
205,10,360,1,50,
205,10,360,4,0,
205,10,363,1,23,
205,10,363,4,0,
205,10,371,1,33,
205,10,371,4,0,
205,10,390,1,1,1
205,10,393,1,62,
205,10,393,3,0,
205,10,397,4,0,
205,10,416,4,0,
205,10,429,1,31,
205,10,430,4,0,
205,10,445,4,0,
205,10,446,4,0,
205,10,450,1,1,5
205,10,450,1,9,
205,10,450,3,0,
205,11,33,1,1,2
205,11,36,1,12,
205,11,38,1,56,
205,11,63,4,0,
205,11,70,4,0,
205,11,76,4,0,
205,11,89,4,0,
205,11,91,4,0,
205,11,92,4,0,
205,11,104,4,0,
205,11,113,4,0,
205,11,115,4,0,
205,11,117,1,20,
205,11,120,1,1,4
205,11,120,1,6,
205,11,153,1,42,
205,11,153,4,0,
205,11,156,4,0,
205,11,157,4,0,
205,11,164,4,0,
205,11,182,1,1,3
205,11,182,4,0,
205,11,191,1,28,
205,11,192,1,64,
205,11,201,4,0,
205,11,207,4,0,
205,11,213,4,0,
205,11,216,4,0,
205,11,218,4,0,
205,11,229,1,17,
205,11,237,4,0,
205,11,241,4,0,
205,11,249,4,0,
205,11,263,4,0,
205,11,317,4,0,
205,11,334,1,46,
205,11,360,1,50,
205,11,360,4,0,
205,11,363,1,23,
205,11,371,1,36,
205,11,371,4,0,
205,11,390,1,1,1
205,11,393,1,60,
205,11,397,4,0,
205,11,416,4,0,
205,11,429,1,31,
205,11,430,4,0,
205,11,450,1,1,5
205,11,450,1,9,
205,11,474,4,0,
205,11,475,1,32,
205,11,484,1,70,
205,11,496,4,0,
205,11,521,4,0,
205,11,522,4,0,
205,11,523,4,0,
205,12,33,1,1,1
205,12,36,1,15,
205,12,38,1,59,
205,12,63,4,0,
205,12,70,4,0,
205,12,76,4,0,
205,12,89,4,0,
205,12,91,4,0,
205,12,92,4,0,
205,12,104,4,0,
205,12,113,4,0,
205,12,115,4,0,
205,12,117,1,29,
205,12,120,1,1,3
205,12,120,1,8,
205,12,153,1,39,
205,12,156,4,0,
205,12,182,1,1,2
205,12,182,4,0,
205,12,191,1,49,
205,12,201,4,0,
205,12,202,4,0,
205,12,213,4,0,
205,12,216,4,0,
205,12,218,4,0,
205,12,229,1,22,
205,12,237,4,0,
205,12,241,4,0,
205,12,249,4,0,
205,12,263,4,0,
205,12,290,4,0,
205,13,33,1,1,1
205,13,34,3,0,
205,13,36,1,15,
205,13,38,1,59,
205,13,38,3,0,
205,13,63,4,0,
205,13,70,4,0,
205,13,76,4,0,
205,13,89,4,0,
205,13,91,4,0,
205,13,92,4,0,
205,13,102,3,0,
205,13,104,4,0,
205,13,113,4,0,
205,13,115,4,0,
205,13,117,1,29,
205,13,120,1,1,3
205,13,120,1,8,
205,13,120,3,0,
205,13,153,1,39,
205,13,156,4,0,
205,13,164,3,0,
205,13,182,1,1,2
205,13,182,4,0,
205,13,191,1,49,
205,13,201,4,0,
205,13,202,4,0,
205,13,207,3,0,
205,13,213,4,0,
205,13,216,4,0,
205,13,218,4,0,
205,13,229,1,22,
205,13,237,4,0,
205,13,241,4,0,
205,13,249,4,0,
205,13,263,4,0,
205,13,290,4,0,
205,14,33,1,1,2
205,14,36,1,12,
205,14,38,1,56,
205,14,63,4,0,
205,14,70,4,0,
205,14,76,4,0,
205,14,89,4,0,
205,14,91,4,0,
205,14,92,4,0,
205,14,104,4,0,
205,14,113,4,0,
205,14,115,4,0,
205,14,117,1,20,
205,14,120,1,1,4
205,14,120,1,6,
205,14,153,1,42,
205,14,153,4,0,
205,14,156,4,0,
205,14,157,4,0,
205,14,164,4,0,
205,14,173,3,0,
205,14,182,1,1,3
205,14,182,4,0,
205,14,191,1,28,
205,14,192,1,64,
205,14,201,4,0,
205,14,202,3,0,
205,14,207,4,0,
205,14,213,4,0,
205,14,214,3,0,
205,14,216,4,0,
205,14,218,4,0,
205,14,220,3,0,
205,14,229,1,17,
205,14,237,4,0,
205,14,241,4,0,
205,14,249,4,0,
205,14,263,4,0,
205,14,317,4,0,
205,14,324,3,0,
205,14,334,1,46,
205,14,334,3,0,
205,14,335,3,0,
205,14,356,3,0,
205,14,360,1,50,
205,14,360,4,0,
205,14,363,1,23,
205,14,371,1,36,
205,14,371,4,0,
205,14,390,1,1,1
205,14,393,1,60,
205,14,393,3,0,
205,14,397,4,0,
205,14,416,4,0,
205,14,429,1,31,
205,14,430,4,0,
205,14,446,3,0,
205,14,450,1,1,5
205,14,450,1,9,
205,14,450,3,0,
205,14,474,4,0,
205,14,475,1,32,
205,14,484,1,70,
205,14,496,4,0,
205,14,521,4,0,
205,14,522,4,0,
205,14,523,4,0,
205,14,529,3,0,
205,15,33,1,1,5
205,15,36,1,12,
205,15,38,1,56,
205,15,63,4,0,
205,15,70,4,0,
205,15,76,4,0,
205,15,89,4,0,
205,15,91,4,0,
205,15,92,4,0,
205,15,104,4,0,
205,15,113,4,0,
205,15,115,4,0,
205,15,117,1,20,
205,15,120,1,1,7
205,15,153,1,42,
205,15,153,4,0,
205,15,156,4,0,
205,15,157,4,0,
205,15,164,4,0,
205,15,182,1,1,6
205,15,182,4,0,
205,15,191,1,28,
205,15,192,1,1,2
205,15,192,1,64,
205,15,201,4,0,
205,15,207,4,0,
205,15,213,4,0,
205,15,214,4,0,
205,15,216,4,0,
205,15,218,4,0,
205,15,229,1,17,
205,15,237,4,0,
205,15,241,4,0,
205,15,249,4,0,
205,15,263,4,0,
205,15,317,4,0,
205,15,334,1,46,
205,15,360,1,50,
205,15,360,4,0,
205,15,363,1,23,
205,15,371,1,36,
205,15,371,4,0,
205,15,390,1,1,4
205,15,393,1,1,3
205,15,393,1,60,
205,15,397,4,0,
205,15,416,4,0,
205,15,429,1,31,
205,15,430,4,0,
205,15,450,1,1,8
205,15,474,4,0,
205,15,475,1,32,
205,15,484,1,1,1
205,15,484,1,70,
205,15,496,4,0,
205,15,521,4,0,
205,15,522,4,0,
205,15,523,4,0,
205,15,590,4,0,
205,16,33,1,1,5
205,16,36,1,12,1
205,16,38,1,56,1
205,16,63,4,0,
205,16,70,4,0,
205,16,76,4,0,
205,16,89,4,0,
205,16,91,4,0,
205,16,92,4,0,
205,16,104,4,0,
205,16,113,4,0,
205,16,115,4,0,
205,16,117,1,20,1
205,16,120,1,1,7
205,16,153,1,42,1
205,16,153,4,0,
205,16,156,4,0,
205,16,157,4,0,
205,16,164,4,0,
205,16,173,3,0,
205,16,182,1,1,6
205,16,182,4,0,
205,16,191,1,28,1
205,16,192,1,1,2
205,16,192,1,64,1
205,16,201,4,0,
205,16,202,3,0,
205,16,207,4,0,
205,16,213,4,0,
205,16,214,4,0,
205,16,216,4,0,
205,16,218,4,0,
205,16,220,3,0,
205,16,229,1,17,1
205,16,237,4,0,
205,16,241,4,0,
205,16,249,4,0,
205,16,263,4,0,
205,16,290,4,0,
205,16,317,4,0,
205,16,324,3,0,
205,16,334,1,46,1
205,16,334,3,0,
205,16,335,3,0,
205,16,356,3,0,
205,16,360,1,50,1
205,16,360,4,0,
205,16,363,1,23,1
205,16,371,1,36,1
205,16,371,4,0,
205,16,390,1,1,4
205,16,393,1,1,3
205,16,393,1,60,1
205,16,393,3,0,
205,16,397,4,0,
205,16,416,4,0,
205,16,429,1,31,1
205,16,430,4,0,
205,16,446,3,0,
205,16,450,1,1,8
205,16,450,3,0,
205,16,474,4,0,
205,16,475,1,32,1
205,16,484,1,1,1
205,16,484,1,70,1
205,16,496,4,0,
205,16,521,4,0,
205,16,522,4,0,
205,16,523,4,0,
205,16,529,3,0,
205,16,590,4,0,
205,17,33,1,1,7
205,17,36,1,12,
205,17,38,1,50,
205,17,63,4,0,
205,17,76,4,0,
205,17,89,4,0,
205,17,92,4,0,
205,17,104,4,0,
205,17,113,4,0,
205,17,115,4,0,
205,17,117,1,20,
205,17,120,1,1,9
205,17,120,1,6,
205,17,153,1,36,
205,17,153,4,0,
205,17,156,4,0,
205,17,157,4,0,
205,17,164,4,0,
205,17,182,1,1,8
205,17,182,4,0,
205,17,191,1,28,
205,17,192,1,1,4
205,17,192,1,60,
205,17,201,4,0,
205,17,207,4,0,
205,17,213,4,0,
205,17,214,4,0,
205,17,216,4,0,
205,17,218,4,0,
205,17,229,1,17,
205,17,237,4,0,
205,17,241,4,0,
205,17,263,4,0,
205,17,317,4,0,
205,17,334,1,42,
205,17,360,1,46,
205,17,360,4,0,
205,17,363,1,23,
205,17,371,1,32,
205,17,371,4,0,
205,17,390,1,1,6
205,17,393,1,1,5
205,17,393,1,56,
205,17,397,4,0,
205,17,416,4,0,
205,17,429,1,0,
205,17,429,1,1,1
205,17,430,4,0,
205,17,450,1,1,10
205,17,450,1,9,
205,17,474,4,0,
205,17,475,1,0,
205,17,475,1,1,2
205,17,484,1,1,3
205,17,484,1,64,
205,17,496,4,0,
205,17,521,4,0,
205,17,523,4,0,
205,17,590,4,0,
205,18,33,1,1,7
205,18,36,1,12,
205,18,38,1,50,
205,18,63,4,0,
205,18,76,4,0,
205,18,89,4,0,
205,18,92,4,0,
205,18,104,4,0,
205,18,113,4,0,
205,18,115,4,0,
205,18,117,1,20,
205,18,120,1,1,9
205,18,120,1,6,
205,18,153,1,36,
205,18,153,4,0,
205,18,156,4,0,
205,18,157,4,0,
205,18,164,4,0,
205,18,182,1,1,8
205,18,182,4,0,
205,18,191,1,28,
205,18,192,1,1,4
205,18,192,1,60,
205,18,201,4,0,
205,18,207,4,0,
205,18,213,4,0,
205,18,214,4,0,
205,18,216,4,0,
205,18,218,4,0,
205,18,229,1,17,
205,18,237,4,0,
205,18,241,4,0,
205,18,263,4,0,
205,18,317,4,0,
205,18,334,1,42,
205,18,360,1,46,
205,18,360,4,0,
205,18,363,1,23,
205,18,371,1,32,
205,18,371,4,0,
205,18,390,1,1,6
205,18,393,1,1,5
205,18,393,1,56,
205,18,397,4,0,
205,18,416,4,0,
205,18,429,1,0,
205,18,429,1,1,1
205,18,430,4,0,
205,18,450,1,1,10
205,18,450,1,9,
205,18,474,4,0,
205,18,475,1,0,
205,18,475,1,1,2
205,18,484,1,1,3
205,18,484,1,64,
205,18,496,4,0,
205,18,521,4,0,
205,18,523,4,0,
205,18,590,4,0,
206,3,29,4,0,
206,3,36,1,38,
206,3,44,2,0,
206,3,70,4,0,
206,3,76,4,0,
206,3,87,4,0,
206,3,91,4,0,
206,3,92,4,0,
206,3,99,1,1,
206,3,99,2,0,
206,3,103,1,30,
206,3,104,4,0,
206,3,111,1,5,
206,3,111,4,0,
206,3,117,2,0,
206,3,137,1,13,
206,3,138,4,0,
206,3,156,4,0,
206,3,157,2,0,
206,3,168,4,0,
206,3,171,4,0,
206,3,173,4,0,
206,3,174,4,0,
206,3,180,1,18,
206,3,182,4,0,
206,3,189,4,0,
206,3,192,4,0,
206,3,203,4,0,
206,3,205,4,0,
206,3,207,4,0,
206,3,213,4,0,
206,3,214,4,0,
206,3,216,4,0,
206,3,218,4,0,
206,3,228,1,26,
206,3,231,4,0,
206,3,237,4,0,
206,3,240,4,0,
206,3,241,4,0,
206,3,244,4,0,
206,3,246,2,0,
206,3,249,4,0,
206,4,29,4,0,
206,4,36,1,38,
206,4,44,2,0,
206,4,53,3,0,
206,4,70,4,0,
206,4,76,4,0,
206,4,85,3,0,
206,4,87,4,0,
206,4,91,4,0,
206,4,92,4,0,
206,4,99,1,1,
206,4,99,2,0,
206,4,103,1,30,
206,4,104,4,0,
206,4,111,1,5,
206,4,111,4,0,
206,4,117,2,0,
206,4,137,1,13,
206,4,138,4,0,
206,4,156,4,0,
206,4,157,2,0,
206,4,168,4,0,
206,4,171,4,0,
206,4,173,4,0,
206,4,174,4,0,
206,4,180,1,18,
206,4,182,4,0,
206,4,189,4,0,
206,4,192,4,0,
206,4,203,4,0,
206,4,205,4,0,
206,4,207,4,0,
206,4,213,4,0,
206,4,214,4,0,
206,4,216,4,0,
206,4,218,4,0,
206,4,228,1,26,
206,4,231,4,0,
206,4,237,4,0,
206,4,240,4,0,
206,4,241,4,0,
206,4,244,4,0,
206,4,246,2,0,
206,4,249,4,0,
206,5,29,2,0,
206,5,36,1,34,
206,5,44,2,0,
206,5,53,4,0,
206,5,58,4,0,
206,5,59,4,0,
206,5,70,4,0,
206,5,76,4,0,
206,5,85,4,0,
206,5,87,4,0,
206,5,89,4,0,
206,5,91,4,0,
206,5,92,4,0,
206,5,99,1,1,
206,5,103,1,31,
206,5,104,4,0,
206,5,111,1,4,
206,5,117,2,0,
206,5,126,4,0,
206,5,137,1,14,
206,5,156,4,0,
206,5,157,2,0,
206,5,168,4,0,
206,5,174,2,0,
206,5,180,1,21,
206,5,182,4,0,
206,5,213,4,0,
206,5,216,4,0,
206,5,218,4,0,
206,5,228,1,24,
206,5,231,4,0,
206,5,237,4,0,
206,5,240,4,0,
206,5,241,4,0,
206,5,246,2,0,
206,5,247,4,0,
206,5,249,4,0,
206,5,263,4,0,
206,5,281,1,11,
206,5,283,1,41,
206,5,290,4,0,
206,5,310,2,0,
206,5,317,4,0,
206,5,347,4,0,
206,5,351,4,0,
206,5,352,4,0,
206,6,29,2,0,
206,6,34,3,0,
206,6,36,1,34,
206,6,38,3,0,
206,6,44,2,0,
206,6,53,4,0,
206,6,58,4,0,
206,6,59,4,0,
206,6,68,3,0,
206,6,70,4,0,
206,6,76,4,0,
206,6,85,4,0,
206,6,86,3,0,
206,6,87,4,0,
206,6,89,4,0,
206,6,91,4,0,
206,6,92,4,0,
206,6,99,1,1,
206,6,102,3,0,
206,6,103,1,31,
206,6,104,4,0,
206,6,111,1,4,
206,6,111,3,0,
206,6,117,2,0,
206,6,126,4,0,
206,6,137,1,14,
206,6,138,3,0,
206,6,156,4,0,
206,6,157,2,0,
206,6,157,3,0,
206,6,164,3,0,
206,6,168,4,0,
206,6,173,3,0,
206,6,174,2,0,
206,6,180,1,21,
206,6,182,4,0,
206,6,189,3,0,
206,6,203,3,0,
206,6,205,3,0,
206,6,207,3,0,
206,6,213,4,0,
206,6,214,3,0,
206,6,216,4,0,
206,6,218,4,0,
206,6,228,1,24,
206,6,231,4,0,
206,6,237,4,0,
206,6,240,4,0,
206,6,241,4,0,
206,6,244,3,0,
206,6,246,2,0,
206,6,247,4,0,
206,6,249,4,0,
206,6,263,4,0,
206,6,281,1,11,
206,6,283,1,41,
206,6,290,4,0,
206,6,310,2,0,
206,6,317,4,0,
206,6,347,4,0,
206,6,351,4,0,
206,6,352,4,0,
206,7,29,2,0,
206,7,34,3,0,
206,7,36,1,41,
206,7,38,3,0,
206,7,44,2,0,
206,7,53,4,0,
206,7,58,4,0,
206,7,59,4,0,
206,7,68,3,0,
206,7,70,4,0,
206,7,76,4,0,
206,7,85,4,0,
206,7,86,3,0,
206,7,87,4,0,
206,7,89,4,0,
206,7,91,4,0,
206,7,92,4,0,
206,7,99,1,1,
206,7,102,3,0,
206,7,103,1,34,
206,7,104,4,0,
206,7,111,1,4,
206,7,117,2,0,
206,7,126,4,0,
206,7,137,1,14,
206,7,138,3,0,
206,7,156,4,0,
206,7,157,2,0,
206,7,157,3,0,
206,7,164,3,0,
206,7,168,4,0,
206,7,174,2,0,
206,7,175,1,44,
206,7,180,1,24,
206,7,182,4,0,
206,7,205,1,21,
206,7,213,4,0,
206,7,216,4,0,
206,7,218,4,0,
206,7,228,1,31,
206,7,231,4,0,
206,7,237,4,0,
206,7,240,4,0,
206,7,241,4,0,
206,7,246,2,0,
206,7,247,4,0,
206,7,249,4,0,
206,7,263,4,0,
206,7,281,1,11,
206,7,283,1,51,
206,7,290,4,0,
206,7,310,2,0,
206,7,317,4,0,
206,7,347,4,0,
206,7,351,4,0,
206,7,352,4,0,
206,8,29,2,0,
206,8,36,1,37,
206,8,44,2,0,
206,8,53,4,0,
206,8,58,4,0,
206,8,59,4,0,
206,8,70,4,0,
206,8,76,4,0,
206,8,85,4,0,
206,8,86,4,0,
206,8,87,4,0,
206,8,89,4,0,
206,8,91,1,45,
206,8,91,4,0,
206,8,92,4,0,
206,8,99,1,1,
206,8,103,1,29,
206,8,104,4,0,
206,8,111,1,5,
206,8,117,2,0,
206,8,126,4,0,
206,8,137,1,13,
206,8,138,4,0,
206,8,156,4,0,
206,8,157,2,0,
206,8,157,4,0,
206,8,164,4,0,
206,8,168,4,0,
206,8,173,2,0,
206,8,174,2,0,
206,8,175,1,53,
206,8,180,1,21,
206,8,182,4,0,
206,8,203,4,0,
206,8,205,1,17,
206,8,207,4,0,
206,8,213,4,0,
206,8,214,4,0,
206,8,216,4,0,
206,8,218,4,0,
206,8,228,1,25,
206,8,231,4,0,
206,8,237,4,0,
206,8,240,4,0,
206,8,241,4,0,
206,8,244,4,0,
206,8,246,1,41,
206,8,246,2,0,
206,8,247,4,0,
206,8,249,4,0,
206,8,263,4,0,
206,8,277,2,0,
206,8,281,1,9,
206,8,283,1,49,
206,8,290,4,0,
206,8,310,2,0,
206,8,317,4,0,
206,8,347,4,0,
206,8,351,4,0,
206,8,352,4,0,
206,8,355,1,33,
206,8,355,4,0,
206,8,360,4,0,
206,8,363,4,0,
206,8,376,2,0,
206,8,398,4,0,
206,8,445,4,0,
206,8,446,4,0,
206,8,451,4,0,
206,9,29,2,0,
206,9,36,1,37,
206,9,44,2,0,
206,9,53,4,0,
206,9,58,4,0,
206,9,59,4,0,
206,9,70,4,0,
206,9,76,4,0,
206,9,85,4,0,
206,9,86,4,0,
206,9,87,4,0,
206,9,89,4,0,
206,9,91,1,45,
206,9,91,4,0,
206,9,92,4,0,
206,9,99,1,1,
206,9,103,1,29,
206,9,104,4,0,
206,9,111,1,5,
206,9,117,2,0,
206,9,126,4,0,
206,9,137,1,13,
206,9,138,4,0,
206,9,156,4,0,
206,9,157,2,0,
206,9,157,4,0,
206,9,164,4,0,
206,9,168,4,0,
206,9,173,2,0,
206,9,173,3,0,
206,9,174,2,0,
206,9,175,1,53,
206,9,180,1,21,
206,9,180,3,0,
206,9,182,4,0,
206,9,189,3,0,
206,9,203,4,0,
206,9,205,1,17,
206,9,205,3,0,
206,9,207,4,0,
206,9,213,4,0,
206,9,214,4,0,
206,9,216,4,0,
206,9,218,4,0,
206,9,228,1,25,
206,9,231,4,0,
206,9,237,4,0,
206,9,240,4,0,
206,9,241,4,0,
206,9,244,4,0,
206,9,246,1,41,
206,9,246,2,0,
206,9,246,3,0,
206,9,247,4,0,
206,9,249,4,0,
206,9,263,4,0,
206,9,277,2,0,
206,9,281,1,9,
206,9,283,1,49,
206,9,283,3,0,
206,9,290,4,0,
206,9,310,2,0,
206,9,317,4,0,
206,9,347,4,0,
206,9,351,4,0,
206,9,352,4,0,
206,9,355,1,33,
206,9,355,4,0,
206,9,360,4,0,
206,9,363,4,0,
206,9,376,2,0,
206,9,387,3,0,
206,9,398,4,0,
206,9,401,3,0,
206,9,428,3,0,
206,9,445,4,0,
206,9,446,4,0,
206,9,451,4,0,
206,10,29,2,0,
206,10,29,3,0,
206,10,36,1,37,
206,10,44,2,0,
206,10,53,4,0,
206,10,58,4,0,
206,10,59,4,0,
206,10,70,4,0,
206,10,76,4,0,
206,10,85,4,0,
206,10,86,4,0,
206,10,87,4,0,
206,10,89,4,0,
206,10,91,1,45,
206,10,91,4,0,
206,10,92,4,0,
206,10,97,2,0,
206,10,99,1,1,
206,10,103,1,29,
206,10,104,4,0,
206,10,111,1,5,
206,10,117,2,0,
206,10,126,4,0,
206,10,137,1,13,
206,10,138,4,0,
206,10,156,4,0,
206,10,157,2,0,
206,10,157,4,0,
206,10,164,4,0,
206,10,168,4,0,
206,10,173,2,0,
206,10,173,3,0,
206,10,174,2,0,
206,10,175,1,53,
206,10,180,1,21,
206,10,180,3,0,
206,10,182,4,0,
206,10,189,3,0,
206,10,203,4,0,
206,10,205,1,17,
206,10,205,3,0,
206,10,207,4,0,
206,10,213,4,0,
206,10,214,4,0,
206,10,216,4,0,
206,10,218,4,0,
206,10,220,3,0,
206,10,228,1,25,
206,10,231,4,0,
206,10,237,4,0,
206,10,240,4,0,
206,10,241,4,0,
206,10,244,4,0,
206,10,246,1,41,
206,10,246,2,0,
206,10,246,3,0,
206,10,247,4,0,
206,10,249,4,0,
206,10,263,4,0,
206,10,277,2,0,
206,10,277,3,0,
206,10,281,1,9,
206,10,283,1,49,
206,10,283,3,0,
206,10,290,4,0,
206,10,310,2,0,
206,10,317,4,0,
206,10,347,4,0,
206,10,351,4,0,
206,10,352,4,0,
206,10,355,1,33,
206,10,355,4,0,
206,10,360,4,0,
206,10,363,4,0,
206,10,376,2,0,
206,10,387,3,0,
206,10,398,4,0,
206,10,401,3,0,
206,10,428,3,0,
206,10,445,4,0,
206,10,446,4,0,
206,10,451,4,0,
206,11,29,2,0,
206,11,36,1,38,
206,11,44,2,0,
206,11,53,4,0,
206,11,58,4,0,
206,11,59,4,0,
206,11,70,4,0,
206,11,76,4,0,
206,11,85,4,0,
206,11,86,4,0,
206,11,87,4,0,
206,11,89,4,0,
206,11,91,1,53,
206,11,91,4,0,
206,11,92,4,0,
206,11,97,2,0,
206,11,99,1,1,
206,11,103,1,28,
206,11,104,4,0,
206,11,111,1,4,
206,11,117,2,0,
206,11,126,4,0,
206,11,137,1,12,
206,11,138,4,0,
206,11,156,4,0,
206,11,157,4,0,
206,11,164,4,0,
206,11,168,4,0,
206,11,173,2,0,
206,11,174,2,0,
206,11,175,1,63,
206,11,180,1,20,
206,11,182,4,0,
206,11,205,1,16,
206,11,207,4,0,
206,11,213,4,0,
206,11,214,2,0,
206,11,216,4,0,
206,11,218,4,0,
206,11,228,1,24,
206,11,237,4,0,
206,11,240,4,0,
206,11,241,4,0,
206,11,244,4,0,
206,11,246,1,48,
206,11,246,2,0,
206,11,247,4,0,
206,11,249,4,0,
206,11,263,4,0,
206,11,277,2,0,
206,11,281,1,8,
206,11,283,1,58,
206,11,290,2,0,
206,11,310,2,0,
206,11,317,4,0,
206,11,347,4,0,
206,11,355,1,33,
206,11,360,4,0,
206,11,376,2,0,
206,11,398,4,0,
206,11,451,4,0,
206,11,489,1,43,
206,11,496,4,0,
206,11,506,2,0,
206,11,510,4,0,
206,11,514,4,0,
206,11,523,4,0,
206,11,528,4,0,
206,12,36,1,34,
206,12,53,4,0,
206,12,58,4,0,
206,12,59,4,0,
206,12,70,4,0,
206,12,76,4,0,
206,12,85,4,0,
206,12,87,4,0,
206,12,89,4,0,
206,12,91,4,0,
206,12,92,4,0,
206,12,99,1,1,
206,12,103,1,31,
206,12,104,4,0,
206,12,111,1,4,
206,12,126,4,0,
206,12,137,1,14,
206,12,156,4,0,
206,12,168,4,0,
206,12,180,1,21,
206,12,182,4,0,
206,12,213,4,0,
206,12,216,4,0,
206,12,218,4,0,
206,12,228,1,24,
206,12,231,4,0,
206,12,237,4,0,
206,12,240,4,0,
206,12,241,4,0,
206,12,247,4,0,
206,12,249,4,0,
206,12,263,4,0,
206,12,281,1,11,
206,12,283,1,41,
206,12,290,4,0,
206,12,317,4,0,
206,12,347,4,0,
206,12,351,4,0,
206,12,352,4,0,
206,13,34,3,0,
206,13,36,1,34,
206,13,38,3,0,
206,13,53,4,0,
206,13,58,4,0,
206,13,59,4,0,
206,13,70,4,0,
206,13,76,4,0,
206,13,85,4,0,
206,13,86,3,0,
206,13,87,4,0,
206,13,89,4,0,
206,13,91,4,0,
206,13,92,4,0,
206,13,99,1,1,
206,13,102,3,0,
206,13,103,1,31,
206,13,104,4,0,
206,13,111,1,4,
206,13,126,4,0,
206,13,137,1,14,
206,13,138,3,0,
206,13,156,4,0,
206,13,164,3,0,
206,13,168,4,0,
206,13,171,3,0,
206,13,180,1,21,
206,13,182,4,0,
206,13,207,3,0,
206,13,213,4,0,
206,13,216,4,0,
206,13,218,4,0,
206,13,228,1,24,
206,13,231,4,0,
206,13,237,4,0,
206,13,240,4,0,
206,13,241,4,0,
206,13,247,4,0,
206,13,249,4,0,
206,13,263,4,0,
206,13,281,1,11,
206,13,283,1,41,
206,13,290,4,0,
206,13,317,4,0,
206,13,347,4,0,
206,13,351,4,0,
206,13,352,4,0,
206,14,20,3,0,
206,14,29,2,0,
206,14,36,1,22,
206,14,38,1,34,
206,14,44,2,0,
206,14,53,4,0,
206,14,58,4,0,
206,14,59,4,0,
206,14,70,4,0,
206,14,76,4,0,
206,14,85,4,0,
206,14,86,4,0,
206,14,87,4,0,
206,14,89,4,0,
206,14,91,1,31,
206,14,91,4,0,
206,14,92,4,0,
206,14,97,2,0,
206,14,99,1,1,1
206,14,103,1,13,
206,14,104,4,0,
206,14,111,1,1,2
206,14,117,2,0,
206,14,126,4,0,
206,14,137,1,28,
206,14,138,4,0,
206,14,156,4,0,
206,14,157,4,0,
206,14,164,4,0,
206,14,168,4,0,
206,14,173,2,0,
206,14,173,3,0,
206,14,174,2,0,
206,14,175,1,49,
206,14,180,1,7,
206,14,180,3,0,
206,14,182,4,0,
206,14,203,1,40,
206,14,205,1,4,
206,14,207,4,0,
206,14,213,4,0,
206,14,214,2,0,
206,14,214,3,0,
206,14,216,4,0,
206,14,218,4,0,
206,14,220,3,0,
206,14,228,1,10,
206,14,231,3,0,
206,14,237,4,0,
206,14,240,4,0,
206,14,241,4,0,
206,14,244,4,0,
206,14,246,1,19,
206,14,246,2,0,
206,14,247,4,0,
206,14,249,4,0,
206,14,263,4,0,
206,14,277,2,0,
206,14,277,3,0,
206,14,281,1,16,
206,14,283,1,46,
206,14,283,3,0,
206,14,290,2,0,
206,14,310,2,0,
206,14,317,4,0,
206,14,347,4,0,
206,14,355,1,25,
206,14,355,3,0,
206,14,360,4,0,
206,14,376,2,0,
206,14,387,3,0,
206,14,398,4,0,
206,14,401,3,0,
206,14,428,3,0,
206,14,446,3,0,
206,14,451,4,0,
206,14,489,1,37,
206,14,496,4,0,
206,14,506,2,0,
206,14,510,4,0,
206,14,514,4,0,
206,14,523,4,0,
206,14,528,4,0,
206,14,529,1,43,
206,14,529,3,0,
206,15,29,2,0,
206,15,36,1,22,
206,15,38,1,34,
206,15,44,2,0,
206,15,53,4,0,
206,15,58,4,0,
206,15,59,4,0,
206,15,70,4,0,
206,15,76,4,0,
206,15,85,4,0,
206,15,86,4,0,
206,15,87,4,0,
206,15,89,4,0,
206,15,91,1,31,
206,15,91,4,0,
206,15,92,4,0,
206,15,97,2,0,
206,15,99,1,1,1
206,15,103,1,13,
206,15,104,4,0,
206,15,111,1,1,2
206,15,117,2,0,
206,15,126,4,0,
206,15,137,1,28,
206,15,138,4,0,
206,15,156,4,0,
206,15,157,4,0,
206,15,164,4,0,
206,15,168,4,0,
206,15,173,2,0,
206,15,174,2,0,
206,15,175,1,49,
206,15,180,1,7,
206,15,182,4,0,
206,15,203,1,40,
206,15,205,1,4,
206,15,207,4,0,
206,15,213,4,0,
206,15,214,2,0,
206,15,214,4,0,
206,15,216,4,0,
206,15,218,4,0,
206,15,228,1,10,
206,15,237,4,0,
206,15,240,4,0,
206,15,241,4,0,
206,15,244,4,0,
206,15,246,1,19,
206,15,246,2,0,
206,15,247,4,0,
206,15,249,4,0,
206,15,263,4,0,
206,15,277,2,0,
206,15,281,1,16,
206,15,283,1,46,
206,15,290,2,0,
206,15,310,2,0,
206,15,317,4,0,
206,15,347,4,0,
206,15,355,1,25,
206,15,355,4,0,
206,15,360,4,0,
206,15,376,2,0,
206,15,398,4,0,
206,15,451,4,0,
206,15,489,1,37,
206,15,496,4,0,
206,15,506,2,0,
206,15,510,4,0,
206,15,514,4,0,
206,15,523,4,0,
206,15,528,4,0,
206,15,529,1,43,
206,15,590,4,0,
206,16,20,3,0,
206,16,29,2,0,
206,16,36,1,22,1
206,16,38,1,34,1
206,16,44,2,0,
206,16,53,4,0,
206,16,58,4,0,
206,16,59,4,0,
206,16,70,4,0,
206,16,76,4,0,
206,16,85,4,0,
206,16,86,4,0,
206,16,87,4,0,
206,16,89,4,0,
206,16,91,1,31,1
206,16,91,4,0,
206,16,92,4,0,
206,16,97,2,0,
206,16,99,1,1,1
206,16,103,1,13,1
206,16,104,4,0,
206,16,111,1,1,2
206,16,117,2,0,
206,16,126,4,0,
206,16,137,1,28,1
206,16,138,4,0,
206,16,156,4,0,
206,16,157,4,0,
206,16,164,4,0,
206,16,168,4,0,
206,16,173,2,0,
206,16,173,3,0,
206,16,174,2,0,
206,16,175,1,49,1
206,16,180,1,7,1
206,16,180,3,0,
206,16,182,4,0,
206,16,203,1,40,1
206,16,205,1,4,1
206,16,207,4,0,
206,16,213,4,0,
206,16,214,2,0,
206,16,214,4,0,
206,16,216,4,0,
206,16,218,4,0,
206,16,220,3,0,
206,16,228,1,10,1
206,16,231,3,0,
206,16,237,4,0,
206,16,240,4,0,
206,16,241,4,0,
206,16,244,4,0,
206,16,246,1,19,1
206,16,246,2,0,
206,16,247,4,0,
206,16,249,4,0,
206,16,263,4,0,
206,16,277,2,0,
206,16,277,3,0,
206,16,281,1,16,1
206,16,283,1,46,1
206,16,283,3,0,
206,16,290,2,0,
206,16,290,4,0,
206,16,310,2,0,
206,16,317,4,0,
206,16,347,4,0,
206,16,351,3,0,
206,16,352,3,0,
206,16,355,1,25,1
206,16,355,4,0,
206,16,360,4,0,
206,16,376,2,0,
206,16,387,3,0,
206,16,398,4,0,
206,16,401,3,0,
206,16,428,3,0,
206,16,446,3,0,
206,16,451,4,0,
206,16,489,1,37,1
206,16,496,4,0,
206,16,506,2,0,
206,16,510,4,0,
206,16,514,4,0,
206,16,523,4,0,
206,16,528,4,0,
206,16,529,1,43,1
206,16,529,3,0,
206,16,590,4,0,
206,17,29,2,0,
206,17,34,1,18,
206,17,36,1,26,
206,17,38,1,36,
206,17,44,2,0,
206,17,53,4,0,
206,17,58,4,0,
206,17,59,4,0,
206,17,76,4,0,
206,17,85,4,0,
206,17,86,4,0,
206,17,87,4,0,
206,17,89,4,0,
206,17,91,1,31,
206,17,92,4,0,
206,17,97,2,0,
206,17,99,1,1,1
206,17,103,1,11,
206,17,104,4,0,
206,17,111,1,1,2
206,17,117,2,0,
206,17,126,4,0,
206,17,137,1,33,
206,17,138,4,0,
206,17,156,4,0,
206,17,157,4,0,
206,17,164,4,0,
206,17,168,4,0,
206,17,173,2,0,
206,17,174,2,0,
206,17,175,1,48,
206,17,180,1,6,
206,17,182,4,0,
206,17,203,1,46,
206,17,205,1,3,
206,17,207,4,0,
206,17,213,4,0,
206,17,214,2,0,
206,17,214,4,0,
206,17,216,4,0,
206,17,218,4,0,
206,17,228,1,8,
206,17,237,4,0,
206,17,240,4,0,
206,17,241,4,0,
206,17,244,4,0,
206,17,246,1,16,
206,17,246,2,0,
206,17,247,4,0,
206,17,263,4,0,
206,17,277,2,0,
206,17,281,1,13,
206,17,283,1,38,
206,17,290,2,0,
206,17,310,2,0,
206,17,317,4,0,
206,17,347,4,0,
206,17,355,1,23,
206,17,355,4,0,
206,17,360,4,0,
206,17,376,2,0,
206,17,398,4,0,
206,17,403,1,41,
206,17,407,1,43,
206,17,451,4,0,
206,17,489,1,28,
206,17,496,4,0,
206,17,506,2,0,
206,17,523,4,0,
206,17,528,4,0,
206,17,529,1,21,
206,17,590,4,0,
206,18,29,2,0,
206,18,34,1,21,
206,18,36,1,28,
206,18,38,1,38,
206,18,44,2,0,
206,18,53,4,0,
206,18,58,4,0,
206,18,59,4,0,
206,18,76,4,0,
206,18,85,4,0,
206,18,86,4,0,
206,18,87,4,0,
206,18,89,4,0,
206,18,91,1,33,
206,18,92,4,0,
206,18,97,2,0,
206,18,99,1,1,1
206,18,103,1,11,
206,18,104,4,0,
206,18,111,1,1,2
206,18,117,2,0,
206,18,126,4,0,
206,18,137,1,36,
206,18,138,4,0,
206,18,156,4,0,
206,18,157,4,0,
206,18,164,4,0,
206,18,168,4,0,
206,18,173,2,0,
206,18,174,2,0,
206,18,175,1,51,
206,18,180,1,6,
206,18,182,4,0,
206,18,189,1,13,
206,18,203,1,48,
206,18,205,1,3,
206,18,207,4,0,
206,18,213,4,0,
206,18,214,2,0,
206,18,214,4,0,
206,18,216,4,0,
206,18,218,4,0,
206,18,228,1,8,
206,18,237,4,0,
206,18,240,4,0,
206,18,241,4,0,
206,18,244,4,0,
206,18,246,1,18,
206,18,246,2,0,
206,18,247,4,0,
206,18,263,4,0,
206,18,277,2,0,
206,18,281,1,16,
206,18,283,1,41,
206,18,290,2,0,
206,18,310,2,0,
206,18,317,4,0,
206,18,347,4,0,
206,18,355,1,26,
206,18,355,4,0,
206,18,360,4,0,
206,18,376,2,0,
206,18,398,4,0,
206,18,403,1,43,
206,18,407,1,46,
206,18,451,4,0,
206,18,489,1,31,
206,18,496,4,0,
206,18,506,2,0,
206,18,523,4,0,
206,18,528,4,0,
206,18,529,1,23,
206,18,590,4,0,
207,3,12,1,52,
207,3,13,2,0,
207,3,15,4,0,
207,3,17,2,0,
207,3,28,1,6,
207,3,29,4,0,
207,3,40,1,1,
207,3,68,2,0,
207,3,70,4,0,
207,3,92,4,0,
207,3,98,1,20,
207,3,103,1,44,
207,3,104,4,0,
207,3,106,1,13,
207,3,129,4,0,
207,3,156,4,0,
207,3,163,1,36,
207,3,168,4,0,
207,3,173,4,0,
207,3,174,4,0,
207,3,182,4,0,
207,3,185,1,28,
207,3,188,4,0,
207,3,197,4,0,
207,3,201,4,0,
207,3,203,4,0,
207,3,207,4,0,
207,3,210,4,0,
207,3,213,4,0,
207,3,214,4,0,
207,3,216,4,0,
207,3,218,4,0,
207,3,231,4,0,
207,3,232,2,0,
207,3,237,4,0,
207,3,241,4,0,
207,3,249,4,0,
207,4,12,1,52,
207,4,13,2,0,
207,4,15,4,0,
207,4,17,2,0,
207,4,28,1,6,
207,4,29,4,0,
207,4,40,1,1,
207,4,68,2,0,
207,4,70,4,0,
207,4,92,4,0,
207,4,98,1,20,
207,4,103,1,44,
207,4,104,4,0,
207,4,106,1,13,
207,4,129,4,0,
207,4,156,4,0,
207,4,163,1,36,
207,4,168,4,0,
207,4,173,4,0,
207,4,174,4,0,
207,4,182,4,0,
207,4,185,1,28,
207,4,188,4,0,
207,4,197,4,0,
207,4,201,4,0,
207,4,203,4,0,
207,4,207,4,0,
207,4,210,4,0,
207,4,213,4,0,
207,4,214,4,0,
207,4,216,4,0,
207,4,218,4,0,
207,4,231,4,0,
207,4,232,2,0,
207,4,237,4,0,
207,4,241,4,0,
207,4,249,4,0,
207,5,12,1,52,
207,5,13,2,0,
207,5,15,4,0,
207,5,17,2,0,
207,5,28,1,6,
207,5,40,1,1,
207,5,68,2,0,
207,5,70,4,0,
207,5,89,4,0,
207,5,91,4,0,
207,5,92,4,0,
207,5,98,1,20,
207,5,103,1,44,
207,5,104,4,0,
207,5,106,1,13,
207,5,156,4,0,
207,5,163,1,36,
207,5,168,4,0,
207,5,182,4,0,
207,5,185,1,28,
207,5,188,4,0,
207,5,201,4,0,
207,5,211,4,0,
207,5,213,4,0,
207,5,216,4,0,
207,5,218,4,0,
207,5,231,4,0,
207,5,232,2,0,
207,5,237,4,0,
207,5,240,4,0,
207,5,241,4,0,
207,5,249,4,0,
207,5,263,4,0,
207,5,290,4,0,
207,5,317,4,0,
207,5,328,2,0,
207,5,332,4,0,
207,6,12,1,52,
207,6,13,2,0,
207,6,14,3,0,
207,6,15,4,0,
207,6,17,2,0,
207,6,28,1,6,
207,6,38,3,0,
207,6,40,1,1,
207,6,68,2,0,
207,6,68,3,0,
207,6,70,4,0,
207,6,89,4,0,
207,6,91,4,0,
207,6,92,4,0,
207,6,98,1,20,
207,6,102,3,0,
207,6,103,1,44,
207,6,104,4,0,
207,6,106,1,13,
207,6,129,3,0,
207,6,138,3,0,
207,6,156,4,0,
207,6,157,3,0,
207,6,163,1,36,
207,6,164,3,0,
207,6,168,4,0,
207,6,173,3,0,
207,6,182,4,0,
207,6,185,1,28,
207,6,188,4,0,
207,6,201,4,0,
207,6,203,3,0,
207,6,207,3,0,
207,6,210,3,0,
207,6,211,4,0,
207,6,213,4,0,
207,6,214,3,0,
207,6,216,4,0,
207,6,218,4,0,
207,6,231,4,0,
207,6,232,2,0,
207,6,237,4,0,
207,6,240,4,0,
207,6,241,4,0,
207,6,249,4,0,
207,6,263,4,0,
207,6,290,4,0,
207,6,317,4,0,
207,6,328,2,0,
207,6,332,4,0,
207,7,12,1,52,
207,7,13,2,0,
207,7,14,3,0,
207,7,15,4,0,
207,7,17,2,0,
207,7,28,1,6,
207,7,38,3,0,
207,7,40,1,1,
207,7,68,2,0,
207,7,68,3,0,
207,7,70,4,0,
207,7,89,4,0,
207,7,91,4,0,
207,7,92,4,0,
207,7,98,1,20,
207,7,102,3,0,
207,7,103,1,44,
207,7,104,4,0,
207,7,106,1,13,
207,7,138,3,0,
207,7,156,4,0,
207,7,157,3,0,
207,7,163,1,36,
207,7,164,3,0,
207,7,168,4,0,
207,7,182,4,0,
207,7,185,1,28,
207,7,188,4,0,
207,7,201,4,0,
207,7,211,4,0,
207,7,213,4,0,
207,7,216,4,0,
207,7,218,4,0,
207,7,231,4,0,
207,7,232,2,0,
207,7,237,4,0,
207,7,240,4,0,
207,7,241,4,0,
207,7,249,4,0,
207,7,263,4,0,
207,7,290,4,0,
207,7,317,4,0,
207,7,328,2,0,
207,7,332,4,0,
207,8,12,1,45,
207,8,13,2,0,
207,8,14,1,34,
207,8,14,4,0,
207,8,15,4,0,
207,8,17,2,0,
207,8,28,1,5,
207,8,38,2,0,
207,8,40,1,1,
207,8,68,2,0,
207,8,70,4,0,
207,8,89,4,0,
207,8,91,4,0,
207,8,92,4,0,
207,8,97,2,0,
207,8,98,1,16,
207,8,103,1,27,
207,8,104,4,0,
207,8,106,1,9,
207,8,156,4,0,
207,8,157,4,0,
207,8,163,1,31,
207,8,164,4,0,
207,8,168,4,0,
207,8,182,4,0,
207,8,185,1,23,
207,8,188,4,0,
207,8,201,4,0,
207,8,203,4,0,
207,8,206,4,0,
207,8,207,4,0,
207,8,210,1,20,
207,8,211,4,0,
207,8,213,4,0,
207,8,214,4,0,
207,8,216,4,0,
207,8,218,4,0,
207,8,226,2,0,
207,8,231,4,0,
207,8,232,2,0,
207,8,237,4,0,
207,8,240,4,0,
207,8,241,4,0,
207,8,249,4,0,
207,8,259,4,0,
207,8,263,4,0,
207,8,269,4,0,
207,8,280,4,0,
207,8,282,1,12,
207,8,290,4,0,
207,8,317,4,0,
207,8,328,2,0,
207,8,332,4,0,
207,8,355,4,0,
207,8,363,4,0,
207,8,364,2,0,
207,8,369,1,38,
207,8,369,4,0,
207,8,371,4,0,
207,8,374,4,0,
207,8,397,4,0,
207,8,398,4,0,
207,8,399,4,0,
207,8,400,2,0,
207,8,404,1,42,
207,8,404,4,0,
207,8,432,4,0,
207,8,440,2,0,
207,8,444,4,0,
207,8,445,4,0,
207,8,446,4,0,
207,9,12,1,45,
207,9,13,2,0,
207,9,14,1,34,
207,9,14,4,0,
207,9,15,4,0,
207,9,17,2,0,
207,9,28,1,5,
207,9,38,2,0,
207,9,40,1,1,
207,9,68,2,0,
207,9,70,4,0,
207,9,89,4,0,
207,9,91,4,0,
207,9,92,4,0,
207,9,97,2,0,
207,9,98,1,16,
207,9,103,1,27,
207,9,104,4,0,
207,9,106,1,9,
207,9,129,3,0,
207,9,156,4,0,
207,9,157,4,0,
207,9,163,1,31,
207,9,164,4,0,
207,9,168,4,0,
207,9,173,3,0,
207,9,182,4,0,
207,9,185,1,23,
207,9,188,4,0,
207,9,201,4,0,
207,9,203,4,0,
207,9,206,4,0,
207,9,207,4,0,
207,9,210,1,20,
207,9,210,3,0,
207,9,211,4,0,
207,9,213,4,0,
207,9,214,4,0,
207,9,216,4,0,
207,9,218,4,0,
207,9,226,2,0,
207,9,231,4,0,
207,9,232,2,0,
207,9,237,4,0,
207,9,240,4,0,
207,9,241,4,0,
207,9,249,4,0,
207,9,259,4,0,
207,9,263,4,0,
207,9,269,4,0,
207,9,280,4,0,
207,9,282,1,12,
207,9,282,3,0,
207,9,290,4,0,
207,9,317,4,0,
207,9,328,2,0,
207,9,332,4,0,
207,9,355,4,0,
207,9,363,4,0,
207,9,364,2,0,
207,9,369,1,38,
207,9,369,4,0,
207,9,371,4,0,
207,9,374,4,0,
207,9,397,4,0,
207,9,398,4,0,
207,9,399,4,0,
207,9,400,2,0,
207,9,401,3,0,
207,9,404,1,42,
207,9,404,4,0,
207,9,414,3,0,
207,9,432,4,0,
207,9,440,2,0,
207,9,444,4,0,
207,9,445,4,0,
207,9,446,4,0,
207,10,12,1,45,
207,10,13,2,0,
207,10,14,1,34,
207,10,14,4,0,
207,10,15,4,0,
207,10,17,2,0,
207,10,28,1,5,
207,10,29,3,0,
207,10,38,2,0,
207,10,40,1,1,
207,10,68,2,0,
207,10,70,4,0,
207,10,89,4,0,
207,10,91,4,0,
207,10,92,4,0,
207,10,97,2,0,
207,10,98,1,16,
207,10,103,1,27,
207,10,104,4,0,
207,10,106,1,9,
207,10,129,3,0,
207,10,156,4,0,
207,10,157,4,0,
207,10,163,1,31,
207,10,164,4,0,
207,10,168,4,0,
207,10,173,3,0,
207,10,182,4,0,
207,10,185,1,23,
207,10,188,4,0,
207,10,201,4,0,
207,10,203,4,0,
207,10,206,4,0,
207,10,207,4,0,
207,10,210,1,20,
207,10,210,3,0,
207,10,211,4,0,
207,10,213,4,0,
207,10,214,4,0,
207,10,216,4,0,
207,10,218,4,0,
207,10,226,2,0,
207,10,231,4,0,
207,10,232,2,0,
207,10,237,4,0,
207,10,240,4,0,
207,10,241,4,0,
207,10,249,4,0,
207,10,259,4,0,
207,10,263,4,0,
207,10,269,4,0,
207,10,280,4,0,
207,10,282,1,12,
207,10,282,3,0,
207,10,290,4,0,
207,10,317,4,0,
207,10,328,2,0,
207,10,332,4,0,
207,10,355,4,0,
207,10,363,4,0,
207,10,364,2,0,
207,10,366,3,0,
207,10,369,1,38,
207,10,369,4,0,
207,10,371,4,0,
207,10,374,4,0,
207,10,379,2,0,
207,10,397,4,0,
207,10,398,4,0,
207,10,399,4,0,
207,10,400,2,0,
207,10,401,3,0,
207,10,404,1,42,
207,10,404,4,0,
207,10,414,3,0,
207,10,440,2,0,
207,10,444,4,0,
207,10,445,4,0,
207,10,446,4,0,
207,11,12,1,49,
207,11,13,2,0,
207,11,14,1,38,
207,11,14,4,0,
207,11,15,4,0,
207,11,17,2,0,
207,11,28,1,5,
207,11,38,2,0,
207,11,40,1,1,
207,11,68,2,0,
207,11,70,4,0,
207,11,89,4,0,
207,11,91,4,0,
207,11,92,4,0,
207,11,97,2,0,
207,11,98,1,16,
207,11,103,1,31,
207,11,104,4,0,
207,11,106,1,9,
207,11,156,4,0,
207,11,157,4,0,
207,11,163,1,34,
207,11,164,4,0,
207,11,168,4,0,
207,11,182,4,0,
207,11,185,1,23,
207,11,188,4,0,
207,11,201,4,0,
207,11,206,4,0,
207,11,207,4,0,
207,11,210,1,20,
207,11,213,4,0,
207,11,216,4,0,
207,11,218,4,0,
207,11,226,2,0,
207,11,232,2,0,
207,11,237,4,0,
207,11,240,4,0,
207,11,241,4,0,
207,11,249,4,0,
207,11,259,4,0,
207,11,263,4,0,
207,11,269,4,0,
207,11,280,4,0,
207,11,282,1,12,
207,11,317,4,0,
207,11,328,2,0,
207,11,332,4,0,
207,11,342,2,0,
207,11,364,2,0,
207,11,369,1,42,
207,11,369,4,0,
207,11,371,4,0,
207,11,374,4,0,
207,11,379,2,0,
207,11,397,4,0,
207,11,398,4,0,
207,11,400,2,0,
207,11,404,1,45,
207,11,404,4,0,
207,11,431,2,0,
207,11,440,2,0,
207,11,444,4,0,
207,11,468,4,0,
207,11,474,4,0,
207,11,496,4,0,
207,11,512,1,27,
207,11,512,4,0,
207,11,522,4,0,
207,11,523,4,0,
207,12,12,1,52,
207,12,15,4,0,
207,12,28,1,6,
207,12,40,1,1,
207,12,70,4,0,
207,12,89,4,0,
207,12,91,4,0,
207,12,92,4,0,
207,12,98,1,20,
207,12,103,1,44,
207,12,104,4,0,
207,12,106,1,13,
207,12,156,4,0,
207,12,163,1,36,
207,12,168,4,0,
207,12,182,4,0,
207,12,185,1,28,
207,12,188,4,0,
207,12,201,4,0,
207,12,211,4,0,
207,12,213,4,0,
207,12,216,4,0,
207,12,218,4,0,
207,12,231,4,0,
207,12,237,4,0,
207,12,240,4,0,
207,12,241,4,0,
207,12,249,4,0,
207,12,263,4,0,
207,12,290,4,0,
207,12,317,4,0,
207,12,332,4,0,
207,13,12,1,52,
207,13,15,4,0,
207,13,28,1,6,
207,13,38,3,0,
207,13,40,1,1,
207,13,70,4,0,
207,13,89,4,0,
207,13,91,4,0,
207,13,92,4,0,
207,13,98,1,20,
207,13,102,3,0,
207,13,103,1,44,
207,13,104,4,0,
207,13,106,1,13,
207,13,138,3,0,
207,13,156,4,0,
207,13,163,1,36,
207,13,164,3,0,
207,13,168,4,0,
207,13,182,4,0,
207,13,185,1,28,
207,13,188,4,0,
207,13,201,4,0,
207,13,207,3,0,
207,13,211,4,0,
207,13,213,4,0,
207,13,216,4,0,
207,13,218,4,0,
207,13,231,4,0,
207,13,237,4,0,
207,13,240,4,0,
207,13,241,4,0,
207,13,249,4,0,
207,13,263,4,0,
207,13,290,4,0,
207,13,317,4,0,
207,13,332,4,0,
207,14,12,1,55,
207,14,13,2,0,
207,14,14,1,50,
207,14,14,4,0,
207,14,15,4,0,
207,14,17,2,0,
207,14,28,1,4,
207,14,38,2,0,
207,14,40,1,1,
207,14,68,2,0,
207,14,70,4,0,
207,14,89,4,0,
207,14,91,4,0,
207,14,92,4,0,
207,14,97,2,0,
207,14,98,1,13,
207,14,103,1,35,
207,14,104,4,0,
207,14,106,1,7,
207,14,156,4,0,
207,14,157,4,0,
207,14,163,1,27,
207,14,164,4,0,
207,14,168,4,0,
207,14,173,3,0,
207,14,182,4,0,
207,14,185,1,19,
207,14,188,4,0,
207,14,201,4,0,
207,14,206,4,0,
207,14,207,4,0,
207,14,210,1,16,
207,14,213,4,0,
207,14,214,3,0,
207,14,216,4,0,
207,14,218,4,0,
207,14,226,2,0,
207,14,231,3,0,
207,14,232,2,0,
207,14,237,4,0,
207,14,240,4,0,
207,14,241,4,0,
207,14,249,4,0,
207,14,259,4,0,
207,14,263,4,0,
207,14,269,4,0,
207,14,280,4,0,
207,14,282,1,10,
207,14,282,3,0,
207,14,317,4,0,
207,14,327,1,45,
207,14,328,2,0,
207,14,332,4,0,
207,14,342,2,0,
207,14,355,3,0,
207,14,364,2,0,
207,14,366,3,0,
207,14,369,1,30,
207,14,369,4,0,
207,14,371,4,0,
207,14,374,4,0,
207,14,379,2,0,
207,14,397,4,0,
207,14,398,4,0,
207,14,399,3,0,
207,14,400,2,0,
207,14,401,3,0,
207,14,404,1,40,
207,14,404,4,0,
207,14,414,3,0,
207,14,431,2,0,
207,14,440,2,0,
207,14,444,4,0,
207,14,446,3,0,
207,14,450,3,0,
207,14,468,4,0,
207,14,474,4,0,
207,14,496,4,0,
207,14,512,1,22,
207,14,512,4,0,
207,14,522,4,0,
207,14,523,4,0,
207,15,12,1,55,
207,15,13,2,0,
207,15,14,1,50,
207,15,14,4,0,
207,15,15,4,0,
207,15,17,2,0,
207,15,28,1,4,
207,15,38,2,0,
207,15,40,1,1,
207,15,68,2,0,
207,15,70,4,0,
207,15,89,4,0,
207,15,91,4,0,
207,15,92,4,0,
207,15,97,2,0,
207,15,98,1,13,
207,15,103,1,35,
207,15,104,4,0,
207,15,106,1,7,
207,15,156,4,0,
207,15,157,4,0,
207,15,163,1,27,
207,15,164,4,0,
207,15,168,4,0,
207,15,182,4,0,
207,15,185,1,19,
207,15,188,4,0,
207,15,201,4,0,
207,15,206,4,0,
207,15,207,4,0,
207,15,210,1,16,
207,15,211,4,0,
207,15,213,4,0,
207,15,214,4,0,
207,15,216,4,0,
207,15,218,4,0,
207,15,226,2,0,
207,15,232,2,0,
207,15,237,4,0,
207,15,240,4,0,
207,15,241,4,0,
207,15,249,4,0,
207,15,259,4,0,
207,15,263,4,0,
207,15,269,4,0,
207,15,280,4,0,
207,15,282,1,10,
207,15,317,4,0,
207,15,327,1,45,
207,15,328,2,0,
207,15,332,4,0,
207,15,342,2,0,
207,15,355,4,0,
207,15,364,2,0,
207,15,369,1,30,
207,15,369,4,0,
207,15,371,4,0,
207,15,374,4,0,
207,15,379,2,0,
207,15,397,4,0,
207,15,398,4,0,
207,15,399,4,0,
207,15,400,2,0,
207,15,404,1,40,
207,15,404,4,0,
207,15,431,2,0,
207,15,440,2,0,
207,15,444,4,0,
207,15,468,4,0,
207,15,474,4,0,
207,15,496,4,0,
207,15,512,1,22,
207,15,512,4,0,
207,15,522,4,0,
207,15,523,4,0,
207,15,590,4,0,
207,16,12,1,55,1
207,16,13,2,0,
207,16,14,1,50,1
207,16,14,4,0,
207,16,15,4,0,
207,16,17,2,0,
207,16,28,1,4,1
207,16,38,2,0,
207,16,40,1,1,1
207,16,68,2,0,
207,16,70,4,0,
207,16,89,4,0,
207,16,91,4,0,
207,16,92,4,0,
207,16,97,2,0,
207,16,98,1,13,1
207,16,103,1,35,1
207,16,104,4,0,
207,16,106,1,7,1
207,16,156,4,0,
207,16,157,4,0,
207,16,163,1,27,1
207,16,164,4,0,
207,16,168,4,0,
207,16,173,3,0,
207,16,182,4,0,
207,16,185,1,19,1
207,16,188,4,0,
207,16,201,4,0,
207,16,206,4,0,
207,16,207,4,0,
207,16,210,1,16,1
207,16,211,4,0,
207,16,213,4,0,
207,16,214,4,0,
207,16,216,4,0,
207,16,218,4,0,
207,16,226,2,0,
207,16,231,3,0,
207,16,232,2,0,
207,16,237,4,0,
207,16,240,4,0,
207,16,241,4,0,
207,16,249,4,0,
207,16,259,4,0,
207,16,263,4,0,
207,16,269,4,0,
207,16,280,4,0,
207,16,282,1,10,1
207,16,282,3,0,
207,16,290,4,0,
207,16,317,4,0,
207,16,327,1,45,1
207,16,328,2,0,
207,16,332,4,0,
207,16,342,2,0,
207,16,355,4,0,
207,16,364,2,0,
207,16,366,3,0,
207,16,369,1,30,1
207,16,369,4,0,
207,16,371,4,0,
207,16,374,4,0,
207,16,379,2,0,
207,16,397,4,0,
207,16,398,4,0,
207,16,399,4,0,
207,16,400,2,0,
207,16,401,3,0,
207,16,404,1,40,1
207,16,404,4,0,
207,16,414,3,0,
207,16,431,2,0,
207,16,440,2,0,
207,16,444,4,0,
207,16,446,3,0,
207,16,450,3,0,
207,16,468,4,0,
207,16,474,4,0,
207,16,496,4,0,
207,16,512,1,22,1
207,16,512,4,0,
207,16,522,4,0,
207,16,523,4,0,
207,16,590,4,0,
207,17,12,1,55,
207,17,13,2,0,
207,17,14,1,50,
207,17,14,4,0,
207,17,17,2,0,
207,17,28,1,4,
207,17,38,2,0,
207,17,40,1,1,
207,17,68,2,0,
207,17,89,4,0,
207,17,92,4,0,
207,17,97,2,0,
207,17,98,1,13,
207,17,103,1,35,
207,17,104,4,0,
207,17,106,1,7,
207,17,156,4,0,
207,17,157,4,0,
207,17,163,1,27,
207,17,164,4,0,
207,17,168,4,0,
207,17,182,4,0,
207,17,185,1,19,
207,17,188,4,0,
207,17,201,4,0,
207,17,206,4,0,
207,17,207,4,0,
207,17,210,1,16,
207,17,211,4,0,
207,17,213,4,0,
207,17,214,4,0,
207,17,216,4,0,
207,17,218,4,0,
207,17,226,2,0,
207,17,232,2,0,
207,17,237,4,0,
207,17,240,4,0,
207,17,241,4,0,
207,17,259,4,0,
207,17,263,4,0,
207,17,269,4,0,
207,17,280,4,0,
207,17,282,1,10,
207,17,317,4,0,
207,17,327,1,45,
207,17,328,2,0,
207,17,332,4,0,
207,17,342,2,0,
207,17,355,4,0,
207,17,364,2,0,
207,17,369,1,30,
207,17,369,4,0,
207,17,371,4,0,
207,17,374,4,0,
207,17,379,2,0,
207,17,397,4,0,
207,17,398,4,0,
207,17,399,4,0,
207,17,400,2,0,
207,17,404,1,40,
207,17,404,4,0,
207,17,431,2,0,
207,17,440,2,0,
207,17,444,4,0,
207,17,474,4,0,
207,17,496,4,0,
207,17,512,1,22,
207,17,512,4,0,
207,17,523,4,0,
207,17,590,4,0,
207,18,12,1,55,
207,18,13,2,0,
207,18,14,1,50,
207,18,14,4,0,
207,18,17,2,0,
207,18,28,1,4,
207,18,38,2,0,
207,18,40,1,1,
207,18,68,2,0,
207,18,89,4,0,
207,18,92,4,0,
207,18,97,2,0,
207,18,98,1,13,
207,18,103,1,35,
207,18,104,4,0,
207,18,106,1,7,
207,18,156,4,0,
207,18,157,4,0,
207,18,163,1,27,
207,18,164,4,0,
207,18,168,4,0,
207,18,182,4,0,
207,18,185,1,19,
207,18,188,4,0,
207,18,201,4,0,
207,18,206,4,0,
207,18,207,4,0,
207,18,210,1,16,
207,18,211,4,0,
207,18,213,4,0,
207,18,214,4,0,
207,18,216,4,0,
207,18,218,4,0,
207,18,226,2,0,
207,18,232,2,0,
207,18,237,4,0,
207,18,240,4,0,
207,18,241,4,0,
207,18,259,4,0,
207,18,263,4,0,
207,18,269,4,0,
207,18,280,4,0,
207,18,282,1,10,
207,18,317,4,0,
207,18,327,1,45,
207,18,328,2,0,
207,18,332,4,0,
207,18,342,2,0,
207,18,355,4,0,
207,18,364,2,0,
207,18,369,1,30,
207,18,369,4,0,
207,18,371,4,0,
207,18,374,4,0,
207,18,379,2,0,
207,18,397,4,0,
207,18,398,4,0,
207,18,399,4,0,
207,18,400,2,0,
207,18,404,1,40,
207,18,404,4,0,
207,18,431,2,0,
207,18,440,2,0,
207,18,444,4,0,
207,18,474,4,0,
207,18,496,4,0,
207,18,512,1,22,
207,18,512,4,0,
207,18,523,4,0,
207,18,590,4,0,
208,3,15,4,0,
208,3,20,1,10,
208,3,21,1,40,
208,3,29,4,0,
208,3,33,1,1,1
208,3,46,4,0,
208,3,63,4,0,
208,3,70,4,0,
208,3,88,1,14,
208,3,89,4,0,
208,3,91,4,0,
208,3,92,4,0,
208,3,99,1,27,
208,3,103,1,1,2
208,3,104,4,0,
208,3,106,1,23,
208,3,111,4,0,
208,3,156,4,0,
208,3,173,4,0,
208,3,174,4,0,
208,3,182,4,0,
208,3,189,4,0,
208,3,201,1,36,
208,3,201,4,0,
208,3,203,4,0,
208,3,205,4,0,
208,3,207,4,0,
208,3,213,4,0,
208,3,214,4,0,
208,3,216,4,0,
208,3,218,4,0,
208,3,225,4,0,
208,3,231,4,0,
208,3,237,4,0,
208,3,241,4,0,
208,3,242,1,49,
208,3,249,4,0,
208,4,15,4,0,
208,4,20,1,10,
208,4,21,1,40,
208,4,29,4,0,
208,4,33,1,1,1
208,4,46,4,0,
208,4,63,4,0,
208,4,70,4,0,
208,4,88,1,14,
208,4,89,4,0,
208,4,91,4,0,
208,4,92,4,0,
208,4,99,1,27,
208,4,103,1,1,2
208,4,104,4,0,
208,4,106,1,23,
208,4,111,4,0,
208,4,156,4,0,
208,4,173,4,0,
208,4,174,4,0,
208,4,182,4,0,
208,4,189,4,0,
208,4,201,1,36,
208,4,201,4,0,
208,4,203,4,0,
208,4,205,4,0,
208,4,207,4,0,
208,4,213,4,0,
208,4,214,4,0,
208,4,216,4,0,
208,4,218,4,0,
208,4,225,4,0,
208,4,231,4,0,
208,4,237,4,0,
208,4,241,4,0,
208,4,242,1,49,
208,4,249,4,0,
208,5,15,4,0,
208,5,20,1,9,
208,5,21,1,37,
208,5,33,1,1,1
208,5,38,1,57,
208,5,46,4,0,
208,5,63,4,0,
208,5,70,4,0,
208,5,88,1,13,
208,5,89,4,0,
208,5,91,4,0,
208,5,92,4,0,
208,5,99,1,25,
208,5,103,1,1,2
208,5,104,4,0,
208,5,106,1,21,
208,5,156,4,0,
208,5,182,4,0,
208,5,201,1,33,
208,5,201,4,0,
208,5,213,4,0,
208,5,216,4,0,
208,5,218,4,0,
208,5,231,1,45,
208,5,231,4,0,
208,5,237,4,0,
208,5,241,4,0,
208,5,242,1,49,
208,5,249,4,0,
208,5,259,4,0,
208,5,263,4,0,
208,5,269,4,0,
208,5,290,4,0,
208,5,317,4,0,
208,6,15,4,0,
208,6,20,1,9,
208,6,21,1,37,
208,6,33,1,1,1
208,6,34,3,0,
208,6,38,1,57,
208,6,38,3,0,
208,6,46,4,0,
208,6,63,4,0,
208,6,70,4,0,
208,6,88,1,13,
208,6,89,4,0,
208,6,91,4,0,
208,6,92,4,0,
208,6,99,1,25,
208,6,102,3,0,
208,6,103,1,1,2
208,6,104,4,0,
208,6,106,1,21,
208,6,111,3,0,
208,6,153,3,0,
208,6,156,4,0,
208,6,157,3,0,
208,6,164,3,0,
208,6,173,3,0,
208,6,182,4,0,
208,6,189,3,0,
208,6,201,1,33,
208,6,201,4,0,
208,6,203,3,0,
208,6,205,3,0,
208,6,207,3,0,
208,6,213,4,0,
208,6,214,3,0,
208,6,216,4,0,
208,6,218,4,0,
208,6,231,1,45,
208,6,231,4,0,
208,6,237,4,0,
208,6,241,4,0,
208,6,242,1,49,
208,6,249,4,0,
208,6,259,4,0,
208,6,263,4,0,
208,6,269,4,0,
208,6,290,4,0,
208,6,317,4,0,
208,7,15,4,0,
208,7,20,1,8,
208,7,21,1,41,
208,7,33,1,1,1
208,7,34,3,0,
208,7,38,1,56,
208,7,38,3,0,
208,7,46,4,0,
208,7,63,4,0,
208,7,70,4,0,
208,7,88,1,12,
208,7,89,4,0,
208,7,91,4,0,
208,7,92,4,0,
208,7,99,1,23,
208,7,102,3,0,
208,7,103,1,1,2
208,7,104,4,0,
208,7,106,1,19,
208,7,153,3,0,
208,7,156,4,0,
208,7,157,3,0,
208,7,164,3,0,
208,7,182,4,0,
208,7,201,1,34,
208,7,201,4,0,
208,7,213,4,0,
208,7,216,4,0,
208,7,218,4,0,
208,7,225,1,30,
208,7,231,1,45,
208,7,231,4,0,
208,7,237,4,0,
208,7,241,4,0,
208,7,242,1,52,
208,7,249,4,0,
208,7,259,4,0,
208,7,263,4,0,
208,7,269,4,0,
208,7,290,4,0,
208,7,317,4,0,
208,8,15,4,0,
208,8,20,1,1,7
208,8,21,1,25,
208,8,33,1,1,5
208,8,38,1,46,
208,8,46,4,0,
208,8,63,4,0,
208,8,70,4,0,
208,8,88,1,9,
208,8,89,4,0,
208,8,91,4,0,
208,8,92,4,0,
208,8,99,1,14,
208,8,103,1,6,
208,8,104,4,0,
208,8,106,1,1,6
208,8,153,4,0,
208,8,156,4,0,
208,8,157,4,0,
208,8,164,4,0,
208,8,182,4,0,
208,8,201,1,22,
208,8,201,4,0,
208,8,203,4,0,
208,8,207,4,0,
208,8,213,4,0,
208,8,214,4,0,
208,8,216,4,0,
208,8,218,4,0,
208,8,225,1,33,
208,8,231,1,38,
208,8,231,4,0,
208,8,237,4,0,
208,8,241,4,0,
208,8,242,1,41,
208,8,244,4,0,
208,8,249,4,0,
208,8,259,4,0,
208,8,263,4,0,
208,8,269,4,0,
208,8,290,4,0,
208,8,300,1,1,4
208,8,317,1,17,
208,8,317,4,0,
208,8,360,4,0,
208,8,363,4,0,
208,8,371,4,0,
208,8,397,1,30,
208,8,397,4,0,
208,8,399,4,0,
208,8,406,4,0,
208,8,416,4,0,
208,8,422,1,1,1
208,8,423,1,1,2
208,8,424,1,1,3
208,8,430,4,0,
208,8,431,4,0,
208,8,444,1,49,
208,8,444,4,0,
208,8,445,4,0,
208,8,446,4,0,
208,9,15,4,0,
208,9,20,1,1,7
208,9,21,1,25,
208,9,33,1,1,5
208,9,38,1,49,
208,9,46,4,0,
208,9,63,4,0,
208,9,70,4,0,
208,9,88,1,9,
208,9,89,4,0,
208,9,91,4,0,
208,9,92,4,0,
208,9,99,1,14,
208,9,103,1,6,
208,9,104,4,0,
208,9,106,1,1,6
208,9,153,4,0,
208,9,156,4,0,
208,9,157,4,0,
208,9,164,4,0,
208,9,173,3,0,
208,9,174,1,38,
208,9,182,4,0,
208,9,189,3,0,
208,9,201,1,22,
208,9,201,4,0,
208,9,203,4,0,
208,9,205,3,0,
208,9,207,4,0,
208,9,213,4,0,
208,9,214,4,0,
208,9,216,4,0,
208,9,218,4,0,
208,9,225,1,33,
208,9,231,1,41,
208,9,231,4,0,
208,9,237,4,0,
208,9,239,3,0,
208,9,241,4,0,
208,9,242,1,46,
208,9,244,4,0,
208,9,246,3,0,
208,9,249,4,0,
208,9,259,4,0,
208,9,263,4,0,
208,9,269,4,0,
208,9,290,4,0,
208,9,300,1,1,4
208,9,317,1,17,
208,9,317,4,0,
208,9,360,4,0,
208,9,363,4,0,
208,9,371,4,0,
208,9,393,3,0,
208,9,397,1,30,
208,9,397,4,0,
208,9,399,4,0,
208,9,401,3,0,
208,9,406,4,0,
208,9,414,3,0,
208,9,416,4,0,
208,9,422,1,1,1
208,9,423,1,1,2
208,9,424,1,1,3
208,9,430,4,0,
208,9,431,4,0,
208,9,442,3,0,
208,9,444,1,54,
208,9,444,4,0,
208,9,445,4,0,
208,9,446,4,0,
208,10,15,4,0,
208,10,20,1,1,7
208,10,21,1,25,
208,10,29,3,0,
208,10,33,1,1,5
208,10,38,1,49,
208,10,46,4,0,
208,10,63,4,0,
208,10,70,4,0,
208,10,88,1,9,
208,10,89,4,0,
208,10,91,4,0,
208,10,92,4,0,
208,10,99,1,14,
208,10,103,1,6,
208,10,104,4,0,
208,10,106,1,1,6
208,10,153,4,0,
208,10,156,4,0,
208,10,157,4,0,
208,10,164,4,0,
208,10,173,3,0,
208,10,174,1,38,
208,10,182,4,0,
208,10,189,3,0,
208,10,201,1,22,
208,10,201,4,0,
208,10,203,4,0,
208,10,205,3,0,
208,10,207,4,0,
208,10,213,4,0,
208,10,214,4,0,
208,10,216,4,0,
208,10,218,4,0,
208,10,225,1,33,
208,10,231,1,41,
208,10,231,4,0,
208,10,237,4,0,
208,10,239,3,0,
208,10,241,4,0,
208,10,242,1,46,
208,10,244,4,0,
208,10,246,3,0,
208,10,249,4,0,
208,10,259,4,0,
208,10,263,4,0,
208,10,269,4,0,
208,10,290,4,0,
208,10,300,1,1,4
208,10,317,1,17,
208,10,317,4,0,
208,10,335,3,0,
208,10,360,4,0,
208,10,363,4,0,
208,10,371,4,0,
208,10,393,3,0,
208,10,397,1,30,
208,10,397,4,0,
208,10,399,4,0,
208,10,401,3,0,
208,10,406,4,0,
208,10,414,3,0,
208,10,416,4,0,
208,10,422,1,1,1
208,10,423,1,1,2
208,10,424,1,1,3
208,10,430,4,0,
208,10,431,4,0,
208,10,442,3,0,
208,10,444,1,54,
208,10,444,4,0,
208,10,445,4,0,
208,10,446,4,0,
208,11,15,4,0,
208,11,20,1,1,7
208,11,21,1,33,
208,11,33,1,1,5
208,11,38,1,57,
208,11,46,4,0,
208,11,63,4,0,
208,11,70,4,0,
208,11,88,1,9,
208,11,89,4,0,
208,11,91,4,0,
208,11,92,4,0,
208,11,99,1,14,
208,11,103,1,6,
208,11,104,4,0,
208,11,106,1,1,6
208,11,153,4,0,
208,11,156,4,0,
208,11,157,4,0,
208,11,164,4,0,
208,11,174,1,46,
208,11,182,4,0,
208,11,201,1,25,
208,11,201,4,0,
208,11,207,4,0,
208,11,213,4,0,
208,11,216,4,0,
208,11,218,4,0,
208,11,225,1,41,
208,11,231,1,49,
208,11,237,4,0,
208,11,241,4,0,
208,11,242,1,54,
208,11,244,4,0,
208,11,249,4,0,
208,11,259,4,0,
208,11,263,4,0,
208,11,269,4,0,
208,11,300,1,1,4
208,11,317,1,17,
208,11,317,4,0,
208,11,360,4,0,
208,11,371,4,0,
208,11,397,4,0,
208,11,416,4,0,
208,11,422,1,1,1
208,11,423,1,1,2
208,11,424,1,1,3
208,11,430,4,0,
208,11,444,1,62,
208,11,444,4,0,
208,11,446,1,38,
208,11,475,1,30,
208,11,479,1,22,
208,11,479,4,0,
208,11,496,4,0,
208,11,523,4,0,
208,11,525,4,0,
208,12,15,4,0,
208,12,20,1,9,
208,12,21,1,37,
208,12,33,1,1,1
208,12,38,1,57,
208,12,46,4,0,
208,12,63,4,0,
208,12,70,4,0,
208,12,88,1,13,
208,12,89,4,0,
208,12,91,4,0,
208,12,92,4,0,
208,12,99,1,25,
208,12,103,1,1,2
208,12,104,4,0,
208,12,106,1,21,
208,12,156,4,0,
208,12,182,4,0,
208,12,201,1,33,
208,12,201,4,0,
208,12,213,4,0,
208,12,216,4,0,
208,12,218,4,0,
208,12,231,1,45,
208,12,231,4,0,
208,12,237,4,0,
208,12,241,4,0,
208,12,242,1,49,
208,12,249,4,0,
208,12,259,4,0,
208,12,263,4,0,
208,12,269,4,0,
208,12,290,4,0,
208,12,317,4,0,
208,13,15,4,0,
208,13,20,1,9,
208,13,21,1,37,
208,13,33,1,1,1
208,13,34,3,0,
208,13,38,1,57,
208,13,38,3,0,
208,13,46,4,0,
208,13,63,4,0,
208,13,70,4,0,
208,13,88,1,13,
208,13,89,4,0,
208,13,91,4,0,
208,13,92,4,0,
208,13,99,1,25,
208,13,102,3,0,
208,13,103,1,1,2
208,13,104,4,0,
208,13,106,1,21,
208,13,120,3,0,
208,13,156,4,0,
208,13,164,3,0,
208,13,182,4,0,
208,13,201,1,33,
208,13,201,4,0,
208,13,207,3,0,
208,13,213,4,0,
208,13,216,4,0,
208,13,218,4,0,
208,13,231,1,45,
208,13,231,4,0,
208,13,237,4,0,
208,13,241,4,0,
208,13,242,1,49,
208,13,249,4,0,
208,13,259,4,0,
208,13,263,4,0,
208,13,269,4,0,
208,13,290,4,0,
208,13,317,4,0,
208,14,15,4,0,
208,14,20,1,1,7
208,14,20,3,0,
208,14,21,1,28,
208,14,33,1,1,5
208,14,38,1,49,
208,14,46,4,0,
208,14,63,4,0,
208,14,70,4,0,
208,14,88,1,7,
208,14,89,4,0,
208,14,91,1,43,
208,14,91,4,0,
208,14,92,4,0,
208,14,99,1,10,
208,14,103,1,31,
208,14,104,4,0,
208,14,106,1,1,6
208,14,153,4,0,
208,14,156,4,0,
208,14,157,1,34,
208,14,157,4,0,
208,14,164,4,0,
208,14,173,3,0,
208,14,174,1,4,
208,14,182,4,0,
208,14,201,1,52,
208,14,201,4,0,
208,14,207,4,0,
208,14,213,4,0,
208,14,214,3,0,
208,14,216,4,0,
208,14,218,4,0,
208,14,225,1,25,
208,14,231,1,40,
208,14,231,3,0,
208,14,237,4,0,
208,14,241,4,0,
208,14,242,1,37,
208,14,244,4,0,
208,14,249,4,0,
208,14,259,4,0,
208,14,263,4,0,
208,14,269,4,0,
208,14,300,1,1,4
208,14,317,1,13,
208,14,317,4,0,
208,14,335,3,0,
208,14,360,4,0,
208,14,371,4,0,
208,14,393,3,0,
208,14,397,4,0,
208,14,399,3,0,
208,14,401,3,0,
208,14,406,3,0,
208,14,414,3,0,
208,14,416,4,0,
208,14,422,1,1,1
208,14,423,1,1,2
208,14,424,1,1,3
208,14,430,4,0,
208,14,442,3,0,
208,14,444,1,46,
208,14,444,4,0,
208,14,446,1,16,
208,14,446,3,0,
208,14,475,1,19,
208,14,479,1,22,
208,14,479,4,0,
208,14,496,4,0,
208,14,523,4,0,
208,14,525,4,0,
208,15,15,4,0,
208,15,20,1,1,7
208,15,21,1,28,
208,15,33,1,1,5
208,15,38,1,49,
208,15,46,4,0,
208,15,63,4,0,
208,15,70,4,0,
208,15,88,1,7,
208,15,89,4,0,
208,15,91,1,43,
208,15,91,4,0,
208,15,92,4,0,
208,15,99,1,13,
208,15,103,1,31,
208,15,104,4,0,
208,15,106,1,1,6
208,15,153,4,0,
208,15,156,4,0,
208,15,157,1,34,
208,15,157,4,0,
208,15,164,4,0,
208,15,174,1,4,
208,15,182,4,0,
208,15,201,1,52,
208,15,201,4,0,
208,15,207,4,0,
208,15,213,4,0,
208,15,214,4,0,
208,15,216,4,0,
208,15,218,4,0,
208,15,225,1,25,
208,15,231,1,40,
208,15,237,4,0,
208,15,241,4,0,
208,15,242,1,37,
208,15,244,4,0,
208,15,249,4,0,
208,15,259,4,0,
208,15,263,4,0,
208,15,267,4,0,
208,15,269,4,0,
208,15,300,1,1,4
208,15,317,1,10,
208,15,317,4,0,
208,15,360,1,20,
208,15,360,4,0,
208,15,371,4,0,
208,15,397,4,0,
208,15,399,4,0,
208,15,416,4,0,
208,15,422,1,1,1
208,15,423,1,1,2
208,15,424,1,1,3
208,15,430,4,0,
208,15,444,1,46,
208,15,444,4,0,
208,15,446,1,16,
208,15,475,1,19,
208,15,479,1,22,
208,15,479,4,0,
208,15,496,4,0,
208,15,523,4,0,
208,15,525,4,0,
208,15,590,4,0,
208,16,15,4,0,
208,16,20,1,1,7
208,16,20,3,0,
208,16,21,1,28,1
208,16,33,1,1,5
208,16,38,1,49,1
208,16,46,4,0,
208,16,63,4,0,
208,16,70,4,0,
208,16,88,1,7,1
208,16,89,4,0,
208,16,91,1,43,1
208,16,91,4,0,
208,16,92,4,0,
208,16,99,1,13,1
208,16,103,1,31,1
208,16,104,4,0,
208,16,106,1,1,6
208,16,153,4,0,
208,16,156,4,0,
208,16,157,1,34,1
208,16,157,4,0,
208,16,164,4,0,
208,16,173,3,0,
208,16,174,1,4,1
208,16,182,4,0,
208,16,201,1,52,1
208,16,201,4,0,
208,16,207,4,0,
208,16,213,4,0,
208,16,214,4,0,
208,16,216,4,0,
208,16,218,4,0,
208,16,225,1,25,1
208,16,231,1,40,1
208,16,231,3,0,
208,16,237,4,0,
208,16,241,4,0,
208,16,242,1,37,1
208,16,244,4,0,
208,16,249,4,0,
208,16,259,4,0,
208,16,263,4,0,
208,16,267,4,0,
208,16,269,4,0,
208,16,290,4,0,
208,16,300,1,1,4
208,16,317,1,10,1
208,16,317,4,0,
208,16,335,3,0,
208,16,360,1,20,1
208,16,360,4,0,
208,16,371,4,0,
208,16,393,3,0,
208,16,397,4,0,
208,16,399,4,0,
208,16,401,3,0,
208,16,406,3,0,
208,16,414,3,0,
208,16,416,4,0,
208,16,422,1,1,1
208,16,423,1,1,2
208,16,424,1,1,3
208,16,430,4,0,
208,16,442,3,0,
208,16,444,1,46,1
208,16,444,4,0,
208,16,446,1,16,1
208,16,446,3,0,
208,16,475,1,19,1
208,16,479,1,22,1
208,16,479,4,0,
208,16,496,4,0,
208,16,523,4,0,
208,16,525,4,0,
208,16,590,4,0,
208,17,20,1,1,7
208,17,21,1,28,
208,17,33,1,1,5
208,17,38,1,49,
208,17,46,4,0,
208,17,63,4,0,
208,17,88,1,7,
208,17,89,4,0,
208,17,91,1,43,
208,17,92,4,0,
208,17,99,1,13,
208,17,103,1,31,
208,17,104,4,0,
208,17,106,1,1,6
208,17,153,4,0,
208,17,156,4,0,
208,17,157,1,34,
208,17,157,4,0,
208,17,164,4,0,
208,17,174,1,4,
208,17,182,4,0,
208,17,201,1,52,
208,17,201,4,0,
208,17,207,4,0,
208,17,213,4,0,
208,17,214,4,0,
208,17,216,4,0,
208,17,218,4,0,
208,17,225,1,25,
208,17,231,1,40,
208,17,237,4,0,
208,17,241,4,0,
208,17,242,1,37,
208,17,244,4,0,
208,17,259,4,0,
208,17,263,4,0,
208,17,267,4,0,
208,17,269,4,0,
208,17,300,1,1,4
208,17,317,1,10,
208,17,317,4,0,
208,17,360,1,20,
208,17,360,4,0,
208,17,371,4,0,
208,17,397,4,0,
208,17,399,4,0,
208,17,416,4,0,
208,17,422,1,1,1
208,17,423,1,1,2
208,17,424,1,1,3
208,17,430,4,0,
208,17,444,1,46,
208,17,444,4,0,
208,17,446,1,16,
208,17,475,1,19,
208,17,479,1,22,
208,17,479,4,0,
208,17,496,4,0,
208,17,523,4,0,
208,17,525,4,0,
208,17,590,4,0,
208,17,693,4,0,
208,18,20,1,1,7
208,18,21,1,28,
208,18,33,1,1,5
208,18,38,1,49,
208,18,46,4,0,
208,18,63,4,0,
208,18,88,1,7,
208,18,89,4,0,
208,18,91,1,43,
208,18,92,4,0,
208,18,99,1,13,
208,18,103,1,31,
208,18,104,4,0,
208,18,106,1,1,6
208,18,153,4,0,
208,18,156,4,0,
208,18,157,1,34,
208,18,157,4,0,
208,18,164,4,0,
208,18,174,1,4,
208,18,182,4,0,
208,18,201,1,52,
208,18,201,4,0,
208,18,207,4,0,
208,18,213,4,0,
208,18,214,4,0,
208,18,216,4,0,
208,18,218,4,0,
208,18,225,1,25,
208,18,231,1,40,
208,18,237,4,0,
208,18,241,4,0,
208,18,242,1,37,
208,18,244,4,0,
208,18,259,4,0,
208,18,263,4,0,
208,18,267,4,0,
208,18,269,4,0,
208,18,300,1,1,4
208,18,317,1,10,
208,18,317,4,0,
208,18,360,1,20,
208,18,360,4,0,
208,18,371,4,0,
208,18,397,4,0,
208,18,399,4,0,
208,18,416,4,0,
208,18,422,1,1,1
208,18,423,1,1,2
208,18,424,1,1,3
208,18,430,4,0,
208,18,444,1,46,
208,18,444,4,0,
208,18,446,1,16,
208,18,475,1,19,
208,18,479,1,22,
208,18,479,4,0,
208,18,496,4,0,
208,18,523,4,0,
208,18,525,4,0,
208,18,590,4,0,
208,18,693,4,0,
209,3,7,4,0,
209,3,8,4,0,
209,3,9,4,0,
209,3,29,4,0,
209,3,33,1,1,1
209,3,36,1,43,
209,3,39,1,4,
209,3,43,2,0,
209,3,44,1,13,
209,3,46,1,26,
209,3,46,4,0,
209,3,70,4,0,
209,3,87,4,0,
209,3,92,4,0,
209,3,99,1,34,
209,3,104,4,0,
209,3,111,4,0,
209,3,115,2,0,
209,3,118,2,0,
209,3,122,1,19,
209,3,122,2,0,
209,3,156,4,0,
209,3,168,4,0,
209,3,173,4,0,
209,3,174,4,0,
209,3,182,4,0,
209,3,184,1,1,2
209,3,185,2,0,
209,3,188,4,0,
209,3,189,4,0,
209,3,192,4,0,
209,3,197,4,0,
209,3,203,4,0,
209,3,204,1,8,
209,3,207,4,0,
209,3,213,4,0,
209,3,214,4,0,
209,3,215,2,0,
209,3,216,4,0,
209,3,217,2,0,
209,3,218,4,0,
209,3,223,4,0,
209,3,237,4,0,
209,3,240,4,0,
209,3,241,4,0,
209,3,242,2,0,
209,3,247,4,0,
209,3,249,4,0,
209,4,7,4,0,
209,4,8,4,0,
209,4,9,4,0,
209,4,29,4,0,
209,4,33,1,1,1
209,4,36,1,43,
209,4,39,1,4,
209,4,43,2,0,
209,4,44,1,13,
209,4,46,1,26,
209,4,46,4,0,
209,4,70,4,0,
209,4,85,3,0,
209,4,87,4,0,
209,4,92,4,0,
209,4,99,1,34,
209,4,104,4,0,
209,4,111,4,0,
209,4,115,2,0,
209,4,118,2,0,
209,4,122,1,19,
209,4,122,2,0,
209,4,156,4,0,
209,4,168,4,0,
209,4,173,4,0,
209,4,174,4,0,
209,4,182,4,0,
209,4,184,1,1,2
209,4,185,2,0,
209,4,188,4,0,
209,4,189,4,0,
209,4,192,4,0,
209,4,197,4,0,
209,4,203,4,0,
209,4,204,1,8,
209,4,207,4,0,
209,4,213,4,0,
209,4,214,4,0,
209,4,215,2,0,
209,4,216,4,0,
209,4,217,2,0,
209,4,218,4,0,
209,4,223,4,0,
209,4,237,4,0,
209,4,240,4,0,
209,4,241,4,0,
209,4,242,2,0,
209,4,247,4,0,
209,4,249,4,0,
209,5,33,1,1,1
209,5,36,1,43,
209,5,39,1,4,
209,5,44,1,13,
209,5,46,1,26,
209,5,46,4,0,
209,5,53,4,0,
209,5,70,4,0,
209,5,76,4,0,
209,5,85,4,0,
209,5,87,4,0,
209,5,89,4,0,
209,5,91,4,0,
209,5,92,4,0,
209,5,99,1,34,
209,5,104,4,0,
209,5,115,2,0,
209,5,118,2,0,
209,5,122,1,19,
209,5,126,4,0,
209,5,156,4,0,
209,5,168,4,0,
209,5,173,2,0,
209,5,182,4,0,
209,5,184,1,1,2
209,5,185,2,0,
209,5,188,4,0,
209,5,204,1,8,
209,5,213,4,0,
209,5,215,2,0,
209,5,216,4,0,
209,5,217,2,0,
209,5,218,4,0,
209,5,237,4,0,
209,5,240,4,0,
209,5,241,4,0,
209,5,242,1,53,
209,5,242,2,0,
209,5,247,4,0,
209,5,249,4,0,
209,5,259,4,0,
209,5,263,4,0,
209,5,264,4,0,
209,5,265,2,0,
209,5,269,4,0,
209,5,280,4,0,
209,5,290,4,0,
209,5,315,4,0,
209,5,339,4,0,
209,5,351,4,0,
209,5,352,4,0,
209,6,5,3,0,
209,6,7,3,0,
209,6,8,3,0,
209,6,9,3,0,
209,6,25,3,0,
209,6,33,1,1,1
209,6,34,3,0,
209,6,36,1,43,
209,6,38,3,0,
209,6,39,1,4,
209,6,44,1,13,
209,6,46,1,26,
209,6,46,4,0,
209,6,53,4,0,
209,6,68,3,0,
209,6,69,3,0,
209,6,70,4,0,
209,6,76,4,0,
209,6,85,4,0,
209,6,86,3,0,
209,6,87,4,0,
209,6,89,4,0,
209,6,91,4,0,
209,6,92,4,0,
209,6,99,1,34,
209,6,102,3,0,
209,6,104,4,0,
209,6,111,3,0,
209,6,115,2,0,
209,6,118,2,0,
209,6,118,3,0,
209,6,122,1,19,
209,6,126,4,0,
209,6,156,4,0,
209,6,164,3,0,
209,6,168,4,0,
209,6,173,2,0,
209,6,173,3,0,
209,6,182,4,0,
209,6,184,1,1,2
209,6,185,2,0,
209,6,188,4,0,
209,6,189,3,0,
209,6,203,3,0,
209,6,204,1,8,
209,6,207,3,0,
209,6,213,4,0,
209,6,214,3,0,
209,6,215,2,0,
209,6,216,4,0,
209,6,217,2,0,
209,6,218,4,0,
209,6,223,3,0,
209,6,237,4,0,
209,6,240,4,0,
209,6,241,4,0,
209,6,242,1,53,
209,6,242,2,0,
209,6,247,4,0,
209,6,249,4,0,
209,6,259,4,0,
209,6,263,4,0,
209,6,264,4,0,
209,6,265,2,0,
209,6,269,4,0,
209,6,280,4,0,
209,6,290,4,0,
209,6,315,4,0,
209,6,339,4,0,
209,6,351,4,0,
209,6,352,4,0,
209,7,5,3,0,
209,7,25,3,0,
209,7,33,1,1,1
209,7,34,3,0,
209,7,36,1,43,
209,7,38,3,0,
209,7,39,1,4,
209,7,44,1,13,
209,7,46,1,26,
209,7,46,4,0,
209,7,53,4,0,
209,7,68,3,0,
209,7,69,3,0,
209,7,70,4,0,
209,7,76,4,0,
209,7,85,4,0,
209,7,86,3,0,
209,7,87,4,0,
209,7,89,4,0,
209,7,91,4,0,
209,7,92,4,0,
209,7,99,1,34,
209,7,102,3,0,
209,7,104,4,0,
209,7,115,2,0,
209,7,118,2,0,
209,7,118,3,0,
209,7,122,1,19,
209,7,126,4,0,
209,7,156,4,0,
209,7,164,3,0,
209,7,168,4,0,
209,7,173,2,0,
209,7,182,4,0,
209,7,184,1,1,2
209,7,185,2,0,
209,7,188,4,0,
209,7,204,1,8,
209,7,213,4,0,
209,7,215,2,0,
209,7,216,4,0,
209,7,217,2,0,
209,7,218,4,0,
209,7,237,4,0,
209,7,240,4,0,
209,7,241,4,0,
209,7,242,1,53,
209,7,242,2,0,
209,7,247,4,0,
209,7,249,4,0,
209,7,259,4,0,
209,7,263,4,0,
209,7,264,4,0,
209,7,265,2,0,
209,7,269,4,0,
209,7,280,4,0,
209,7,290,4,0,
209,7,315,4,0,
209,7,339,4,0,
209,7,351,4,0,
209,7,352,4,0,
209,8,29,1,19,
209,8,33,1,1,4
209,8,36,1,37,
209,8,39,1,1,6
209,8,44,1,7,
209,8,46,1,25,
209,8,46,4,0,
209,8,53,4,0,
209,8,70,4,0,
209,8,76,4,0,
209,8,85,4,0,
209,8,86,4,0,
209,8,87,4,0,
209,8,89,4,0,
209,8,91,4,0,
209,8,92,4,0,
209,8,99,1,31,
209,8,104,4,0,
209,8,115,2,0,
209,8,118,2,0,
209,8,122,1,13,
209,8,126,4,0,
209,8,156,4,0,
209,8,164,4,0,
209,8,168,4,0,
209,8,173,2,0,
209,8,182,4,0,
209,8,184,1,1,5
209,8,185,2,0,
209,8,188,4,0,
209,8,203,4,0,
209,8,204,1,1,7
209,8,207,4,0,
209,8,213,4,0,
209,8,214,4,0,
209,8,215,2,0,
209,8,216,4,0,
209,8,217,2,0,
209,8,218,4,0,
209,8,237,4,0,
209,8,240,4,0,
209,8,241,4,0,
209,8,242,1,49,
209,8,242,2,0,
209,8,247,4,0,
209,8,249,4,0,
209,8,259,4,0,
209,8,263,4,0,
209,8,264,4,0,
209,8,265,2,0,
209,8,269,4,0,
209,8,280,4,0,
209,8,290,4,0,
209,8,315,4,0,
209,8,339,4,0,
209,8,351,4,0,
209,8,352,4,0,
209,8,363,4,0,
209,8,370,2,0,
209,8,371,1,43,
209,8,371,4,0,
209,8,374,4,0,
209,8,422,1,1,3
209,8,422,2,0,
209,8,423,1,1,1
209,8,423,2,0,
209,8,424,1,1,2
209,8,424,2,0,
209,8,445,4,0,
209,9,7,3,0,
209,9,8,3,0,
209,9,9,3,0,
209,9,29,1,19,
209,9,33,1,1,4
209,9,36,1,37,
209,9,39,1,1,6
209,9,44,1,7,
209,9,46,1,25,
209,9,46,4,0,
209,9,53,4,0,
209,9,70,4,0,
209,9,76,4,0,
209,9,85,4,0,
209,9,86,4,0,
209,9,87,4,0,
209,9,89,4,0,
209,9,91,4,0,
209,9,92,4,0,
209,9,99,1,31,
209,9,104,4,0,
209,9,115,2,0,
209,9,118,2,0,
209,9,122,1,13,
209,9,126,4,0,
209,9,156,4,0,
209,9,164,4,0,
209,9,168,4,0,
209,9,173,2,0,
209,9,173,3,0,
209,9,182,4,0,
209,9,184,1,1,5
209,9,185,2,0,
209,9,188,4,0,
209,9,189,3,0,
209,9,203,4,0,
209,9,204,1,1,7
209,9,207,4,0,
209,9,213,4,0,
209,9,214,4,0,
209,9,215,2,0,
209,9,216,4,0,
209,9,217,2,0,
209,9,218,4,0,
209,9,237,4,0,
209,9,240,4,0,
209,9,241,4,0,
209,9,242,1,49,
209,9,242,2,0,
209,9,247,4,0,
209,9,249,4,0,
209,9,259,4,0,
209,9,263,4,0,
209,9,264,4,0,
209,9,265,2,0,
209,9,269,4,0,
209,9,276,3,0,
209,9,280,4,0,
209,9,290,4,0,
209,9,315,4,0,
209,9,339,4,0,
209,9,351,4,0,
209,9,352,4,0,
209,9,363,4,0,
209,9,370,2,0,
209,9,371,1,43,
209,9,371,4,0,
209,9,374,4,0,
209,9,387,3,0,
209,9,422,1,1,3
209,9,422,2,0,
209,9,423,1,1,1
209,9,423,2,0,
209,9,424,1,1,2
209,9,424,2,0,
209,9,445,4,0,
209,10,7,3,0,
209,10,8,3,0,
209,10,9,3,0,
209,10,29,1,19,
209,10,29,3,0,
209,10,33,1,1,4
209,10,36,1,37,
209,10,39,1,1,6
209,10,44,1,7,
209,10,46,1,25,
209,10,46,4,0,
209,10,53,4,0,
209,10,67,3,0,
209,10,70,4,0,
209,10,76,4,0,
209,10,85,4,0,
209,10,86,4,0,
209,10,87,4,0,
209,10,89,4,0,
209,10,91,4,0,
209,10,92,4,0,
209,10,99,1,31,
209,10,104,4,0,
209,10,115,2,0,
209,10,118,2,0,
209,10,122,1,13,
209,10,126,4,0,
209,10,156,4,0,
209,10,162,3,0,
209,10,164,4,0,
209,10,168,4,0,
209,10,173,2,0,
209,10,173,3,0,
209,10,182,4,0,
209,10,184,1,1,5
209,10,185,2,0,
209,10,188,4,0,
209,10,189,3,0,
209,10,203,4,0,
209,10,204,1,1,7
209,10,207,4,0,
209,10,213,4,0,
209,10,214,4,0,
209,10,215,2,0,
209,10,215,3,0,
209,10,216,4,0,
209,10,217,2,0,
209,10,218,4,0,
209,10,237,4,0,
209,10,240,4,0,
209,10,241,4,0,
209,10,242,1,49,
209,10,242,2,0,
209,10,247,4,0,
209,10,249,4,0,
209,10,259,4,0,
209,10,263,4,0,
209,10,264,4,0,
209,10,265,2,0,
209,10,269,4,0,
209,10,276,3,0,
209,10,280,4,0,
209,10,290,4,0,
209,10,315,4,0,
209,10,339,4,0,
209,10,351,4,0,
209,10,352,4,0,
209,10,363,4,0,
209,10,370,2,0,
209,10,371,1,43,
209,10,371,4,0,
209,10,374,4,0,
209,10,387,3,0,
209,10,422,1,1,3
209,10,422,2,0,
209,10,423,1,1,1
209,10,423,2,0,
209,10,424,1,1,2
209,10,424,2,0,
209,10,445,4,0,
209,11,29,1,19,
209,11,33,1,1,4
209,11,36,1,37,
209,11,38,2,0,
209,11,39,1,1,6
209,11,44,1,7,
209,11,46,1,25,
209,11,46,4,0,
209,11,53,4,0,
209,11,70,4,0,
209,11,76,4,0,
209,11,85,4,0,
209,11,86,4,0,
209,11,87,4,0,
209,11,89,4,0,
209,11,91,4,0,
209,11,92,4,0,
209,11,99,1,31,
209,11,102,2,0,
209,11,104,4,0,
209,11,115,4,0,
209,11,118,2,0,
209,11,122,1,13,
209,11,126,4,0,
209,11,156,4,0,
209,11,164,4,0,
209,11,168,4,0,
209,11,173,2,0,
209,11,182,4,0,
209,11,184,1,1,5
209,11,185,2,0,
209,11,188,4,0,
209,11,204,1,1,7
209,11,207,4,0,
209,11,213,4,0,
209,11,215,2,0,
209,11,216,4,0,
209,11,217,2,0,
209,11,218,4,0,
209,11,237,4,0,
209,11,240,4,0,
209,11,241,4,0,
209,11,242,1,49,
209,11,242,2,0,
209,11,247,4,0,
209,11,249,4,0,
209,11,259,4,0,
209,11,263,4,0,
209,11,264,2,0,
209,11,265,2,0,
209,11,269,4,0,
209,11,280,4,0,
209,11,315,4,0,
209,11,339,4,0,
209,11,370,2,0,
209,11,371,1,43,
209,11,371,4,0,
209,11,374,4,0,
209,11,422,1,1,3
209,11,422,2,0,
209,11,423,1,1,1
209,11,423,2,0,
209,11,424,1,1,2
209,11,424,2,0,
209,11,496,4,0,
209,11,510,4,0,
209,11,514,4,0,
209,11,523,4,0,
209,11,526,4,0,
209,11,528,4,0,
209,11,555,4,0,
209,12,33,1,1,1
209,12,36,1,43,
209,12,39,1,4,
209,12,44,1,13,
209,12,46,1,26,
209,12,46,4,0,
209,12,53,4,0,
209,12,70,4,0,
209,12,76,4,0,
209,12,85,4,0,
209,12,87,4,0,
209,12,89,4,0,
209,12,91,4,0,
209,12,92,4,0,
209,12,99,1,34,
209,12,104,4,0,
209,12,122,1,19,
209,12,126,4,0,
209,12,156,4,0,
209,12,168,4,0,
209,12,182,4,0,
209,12,184,1,1,2
209,12,188,4,0,
209,12,204,1,8,
209,12,213,4,0,
209,12,216,4,0,
209,12,218,4,0,
209,12,237,4,0,
209,12,240,4,0,
209,12,241,4,0,
209,12,242,1,53,
209,12,247,4,0,
209,12,249,4,0,
209,12,259,4,0,
209,12,263,4,0,
209,12,264,4,0,
209,12,269,4,0,
209,12,280,4,0,
209,12,290,4,0,
209,12,315,4,0,
209,12,339,4,0,
209,12,351,4,0,
209,12,352,4,0,
209,13,33,1,1,1
209,13,34,3,0,
209,13,36,1,43,
209,13,38,3,0,
209,13,39,1,4,
209,13,44,1,13,
209,13,46,1,26,
209,13,46,4,0,
209,13,53,4,0,
209,13,69,3,0,
209,13,70,4,0,
209,13,76,4,0,
209,13,85,4,0,
209,13,86,3,0,
209,13,87,4,0,
209,13,89,4,0,
209,13,91,4,0,
209,13,92,4,0,
209,13,99,1,34,
209,13,102,3,0,
209,13,104,4,0,
209,13,122,1,19,
209,13,126,4,0,
209,13,156,4,0,
209,13,164,3,0,
209,13,168,4,0,
209,13,182,4,0,
209,13,184,1,1,2
209,13,188,4,0,
209,13,204,1,8,
209,13,207,3,0,
209,13,213,4,0,
209,13,216,4,0,
209,13,218,4,0,
209,13,237,4,0,
209,13,240,4,0,
209,13,241,4,0,
209,13,242,1,53,
209,13,247,4,0,
209,13,249,4,0,
209,13,259,4,0,
209,13,263,4,0,
209,13,264,4,0,
209,13,269,4,0,
209,13,280,4,0,
209,13,290,4,0,
209,13,315,4,0,
209,13,339,4,0,
209,13,351,4,0,
209,13,352,4,0,
209,14,7,3,0,
209,14,8,3,0,
209,14,9,3,0,
209,14,29,1,19,
209,14,33,1,1,4
209,14,36,1,37,
209,14,38,2,0,
209,14,39,1,1,6
209,14,44,1,7,
209,14,46,1,25,
209,14,46,4,0,
209,14,53,4,0,
209,14,67,3,0,
209,14,70,4,0,
209,14,76,4,0,
209,14,85,4,0,
209,14,86,4,0,
209,14,87,4,0,
209,14,89,4,0,
209,14,91,4,0,
209,14,92,4,0,
209,14,99,1,31,
209,14,102,2,0,
209,14,104,4,0,
209,14,115,4,0,
209,14,118,2,0,
209,14,122,1,13,
209,14,126,4,0,
209,14,156,4,0,
209,14,162,3,0,
209,14,164,4,0,
209,14,168,4,0,
209,14,173,2,0,
209,14,173,3,0,
209,14,182,4,0,
209,14,184,1,1,5
209,14,185,2,0,
209,14,188,4,0,
209,14,204,1,1,7
209,14,207,4,0,
209,14,213,4,0,
209,14,214,3,0,
209,14,215,2,0,
209,14,215,3,0,
209,14,216,4,0,
209,14,217,2,0,
209,14,218,4,0,
209,14,237,4,0,
209,14,240,4,0,
209,14,241,4,0,
209,14,242,1,49,
209,14,242,2,0,
209,14,247,4,0,
209,14,249,4,0,
209,14,259,4,0,
209,14,263,4,0,
209,14,264,2,0,
209,14,265,2,0,
209,14,269,4,0,
209,14,276,3,0,
209,14,280,4,0,
209,14,304,3,0,
209,14,315,4,0,
209,14,339,4,0,
209,14,343,3,0,
209,14,370,2,0,
209,14,371,1,43,
209,14,371,4,0,
209,14,374,4,0,
209,14,387,3,0,
209,14,422,1,1,3
209,14,422,2,0,
209,14,423,1,1,1
209,14,423,2,0,
209,14,424,1,1,2
209,14,424,2,0,
209,14,496,4,0,
209,14,510,4,0,
209,14,514,4,0,
209,14,523,4,0,
209,14,526,4,0,
209,14,528,4,0,
209,14,555,4,0,
209,15,29,1,19,
209,15,33,1,1,4
209,15,38,2,0,
209,15,39,1,1,6
209,15,44,1,7,
209,15,46,1,25,
209,15,46,4,0,
209,15,53,4,0,
209,15,70,4,0,
209,15,76,4,0,
209,15,85,4,0,
209,15,86,4,0,
209,15,87,4,0,
209,15,89,4,0,
209,15,91,4,0,
209,15,92,4,0,
209,15,99,1,31,
209,15,102,2,0,
209,15,104,4,0,
209,15,115,4,0,
209,15,118,2,0,
209,15,122,1,13,
209,15,126,4,0,
209,15,156,4,0,
209,15,164,4,0,
209,15,168,4,0,
209,15,173,2,0,
209,15,182,4,0,
209,15,184,1,1,5
209,15,185,2,0,
209,15,188,4,0,
209,15,204,1,1,7
209,15,207,4,0,
209,15,213,4,0,
209,15,214,4,0,
209,15,215,2,0,
209,15,216,4,0,
209,15,217,2,0,
209,15,218,4,0,
209,15,237,4,0,
209,15,240,4,0,
209,15,241,4,0,
209,15,242,1,49,
209,15,242,2,0,
209,15,247,4,0,
209,15,249,4,0,
209,15,259,4,0,
209,15,263,4,0,
209,15,264,2,0,
209,15,265,2,0,
209,15,269,4,0,
209,15,280,4,0,
209,15,313,2,0,
209,15,315,4,0,
209,15,339,4,0,
209,15,370,2,0,
209,15,371,1,43,
209,15,371,4,0,
209,15,374,4,0,
209,15,422,1,1,3
209,15,422,2,0,
209,15,423,1,1,1
209,15,423,2,0,
209,15,424,1,1,2
209,15,424,2,0,
209,15,496,4,0,
209,15,510,4,0,
209,15,514,4,0,
209,15,523,4,0,
209,15,528,4,0,
209,15,555,4,0,
209,15,583,1,37,
209,15,590,4,0,
209,15,605,4,0,
209,15,612,4,0,
209,16,7,3,0,
209,16,8,3,0,
209,16,9,3,0,
209,16,29,1,19,1
209,16,33,1,1,4
209,16,38,2,0,
209,16,39,1,1,6
209,16,44,1,7,1
209,16,46,1,25,1
209,16,46,4,0,
209,16,53,4,0,
209,16,67,3,0,
209,16,70,4,0,
209,16,76,4,0,
209,16,85,4,0,
209,16,86,4,0,
209,16,87,4,0,
209,16,89,4,0,
209,16,91,4,0,
209,16,92,4,0,
209,16,99,1,31,1
209,16,102,2,0,
209,16,104,4,0,
209,16,115,4,0,
209,16,118,2,0,
209,16,122,1,13,1
209,16,126,4,0,
209,16,156,4,0,
209,16,162,3,0,
209,16,164,4,0,
209,16,168,4,0,
209,16,173,2,0,
209,16,173,3,0,
209,16,182,4,0,
209,16,184,1,1,5
209,16,185,2,0,
209,16,188,4,0,
209,16,204,1,1,7
209,16,207,4,0,
209,16,213,4,0,
209,16,214,4,0,
209,16,215,2,0,
209,16,215,3,0,
209,16,216,4,0,
209,16,217,2,0,
209,16,218,4,0,
209,16,237,4,0,
209,16,240,4,0,
209,16,241,4,0,
209,16,242,1,49,1
209,16,242,2,0,
209,16,247,4,0,
209,16,249,4,0,
209,16,259,4,0,
209,16,263,4,0,
209,16,264,2,0,
209,16,264,3,0,
209,16,265,2,0,
209,16,269,4,0,
209,16,276,3,0,
209,16,280,4,0,
209,16,290,4,0,
209,16,304,3,0,
209,16,313,2,0,
209,16,315,4,0,
209,16,339,4,0,
209,16,343,3,0,
209,16,351,3,0,
209,16,352,3,0,
209,16,370,2,0,
209,16,371,1,43,1
209,16,371,4,0,
209,16,374,4,0,
209,16,387,3,0,
209,16,422,1,1,3
209,16,422,2,0,
209,16,423,1,1,1
209,16,423,2,0,
209,16,424,1,1,2
209,16,424,2,0,
209,16,496,4,0,
209,16,510,4,0,
209,16,514,4,0,
209,16,523,4,0,
209,16,528,4,0,
209,16,555,4,0,
209,16,583,1,37,1
209,16,590,4,0,
209,16,605,4,0,
209,16,612,4,0,
209,17,29,1,19,
209,17,33,1,1,4
209,17,38,2,0,
209,17,39,1,1,6
209,17,44,1,7,
209,17,46,1,25,
209,17,46,4,0,
209,17,53,4,0,
209,17,76,4,0,
209,17,85,4,0,
209,17,86,4,0,
209,17,87,4,0,
209,17,89,4,0,
209,17,92,4,0,
209,17,99,1,31,
209,17,102,2,0,
209,17,104,4,0,
209,17,115,4,0,
209,17,118,2,0,
209,17,122,1,13,
209,17,126,4,0,
209,17,156,4,0,
209,17,164,4,0,
209,17,168,4,0,
209,17,173,2,0,
209,17,182,4,0,
209,17,184,1,1,5
209,17,185,2,0,
209,17,188,4,0,
209,17,204,1,1,7
209,17,207,4,0,
209,17,213,4,0,
209,17,214,4,0,
209,17,215,2,0,
209,17,216,4,0,
209,17,217,2,0,
209,17,218,4,0,
209,17,237,4,0,
209,17,240,4,0,
209,17,241,4,0,
209,17,242,1,49,
209,17,242,2,0,
209,17,247,4,0,
209,17,259,4,0,
209,17,263,4,0,
209,17,264,2,0,
209,17,265,2,0,
209,17,269,4,0,
209,17,280,4,0,
209,17,313,2,0,
209,17,315,4,0,
209,17,339,4,0,
209,17,370,2,0,
209,17,371,1,43,
209,17,371,4,0,
209,17,374,4,0,
209,17,422,1,1,3
209,17,422,2,0,
209,17,423,1,1,1
209,17,423,2,0,
209,17,424,1,1,2
209,17,424,2,0,
209,17,496,4,0,
209,17,523,4,0,
209,17,526,4,0,
209,17,528,4,0,
209,17,555,4,0,
209,17,583,1,37,
209,17,590,4,0,
209,17,605,4,0,
209,18,29,1,19,
209,18,33,1,1,4
209,18,38,2,0,
209,18,39,1,1,6
209,18,44,1,7,
209,18,46,1,25,
209,18,46,4,0,
209,18,53,4,0,
209,18,76,4,0,
209,18,85,4,0,
209,18,86,4,0,
209,18,87,4,0,
209,18,89,4,0,
209,18,92,4,0,
209,18,99,1,31,
209,18,102,2,0,
209,18,104,4,0,
209,18,115,4,0,
209,18,118,2,0,
209,18,122,1,13,
209,18,126,4,0,
209,18,156,4,0,
209,18,164,4,0,
209,18,168,4,0,
209,18,173,2,0,
209,18,182,4,0,
209,18,184,1,1,5
209,18,185,2,0,
209,18,188,4,0,
209,18,204,1,1,7
209,18,207,4,0,
209,18,213,4,0,
209,18,214,4,0,
209,18,215,2,0,
209,18,216,4,0,
209,18,217,2,0,
209,18,218,4,0,
209,18,237,4,0,
209,18,240,4,0,
209,18,241,4,0,
209,18,242,1,49,
209,18,242,2,0,
209,18,247,4,0,
209,18,259,4,0,
209,18,263,4,0,
209,18,264,2,0,
209,18,265,2,0,
209,18,269,4,0,
209,18,280,4,0,
209,18,313,2,0,
209,18,315,4,0,
209,18,339,4,0,
209,18,370,2,0,
209,18,371,1,43,
209,18,371,4,0,
209,18,374,4,0,
209,18,422,1,1,3
209,18,422,2,0,
209,18,423,1,1,1
209,18,423,2,0,
209,18,424,1,1,2
209,18,424,2,0,
209,18,496,4,0,
209,18,523,4,0,
209,18,526,4,0,
209,18,528,4,0,
209,18,555,4,0,
209,18,583,1,37,
209,18,590,4,0,
209,18,605,4,0,
210,3,7,4,0,
210,3,8,4,0,
210,3,9,4,0,
210,3,29,4,0,
210,3,33,1,1,1
210,3,36,1,51,
210,3,39,1,4,
210,3,44,1,13,
210,3,46,1,28,
210,3,46,4,0,
210,3,63,4,0,
210,3,70,4,0,
210,3,87,4,0,
210,3,92,4,0,
210,3,99,1,38,
210,3,104,4,0,
210,3,111,4,0,
210,3,122,1,19,
210,3,156,4,0,
210,3,168,4,0,
210,3,173,4,0,
210,3,174,4,0,
210,3,182,4,0,
210,3,184,1,1,2
210,3,188,4,0,
210,3,189,4,0,
210,3,192,4,0,
210,3,197,4,0,
210,3,203,4,0,
210,3,204,1,8,
210,3,207,4,0,
210,3,213,4,0,
210,3,214,4,0,
210,3,216,4,0,
210,3,218,4,0,
210,3,223,4,0,
210,3,237,4,0,
210,3,240,4,0,
210,3,241,4,0,
210,3,247,4,0,
210,3,249,4,0,
210,4,7,4,0,
210,4,8,4,0,
210,4,9,4,0,
210,4,29,4,0,
210,4,33,1,1,1
210,4,36,1,51,
210,4,39,1,4,
210,4,44,1,13,
210,4,46,1,28,
210,4,46,4,0,
210,4,63,4,0,
210,4,70,4,0,
210,4,85,3,0,
210,4,87,4,0,
210,4,92,4,0,
210,4,99,1,38,
210,4,104,4,0,
210,4,111,4,0,
210,4,122,1,19,
210,4,156,4,0,
210,4,168,4,0,
210,4,173,4,0,
210,4,174,4,0,
210,4,182,4,0,
210,4,184,1,1,2
210,4,188,4,0,
210,4,189,4,0,
210,4,192,4,0,
210,4,197,4,0,
210,4,203,4,0,
210,4,204,1,8,
210,4,207,4,0,
210,4,213,4,0,
210,4,214,4,0,
210,4,216,4,0,
210,4,218,4,0,
210,4,223,4,0,
210,4,237,4,0,
210,4,240,4,0,
210,4,241,4,0,
210,4,247,4,0,
210,4,249,4,0,
210,5,33,1,1,1
210,5,36,1,49,
210,5,39,1,4,
210,5,44,1,13,
210,5,46,1,28,
210,5,46,4,0,
210,5,53,4,0,
210,5,63,4,0,
210,5,70,4,0,
210,5,76,4,0,
210,5,85,4,0,
210,5,87,4,0,
210,5,89,4,0,
210,5,91,4,0,
210,5,92,4,0,
210,5,99,1,38,
210,5,104,4,0,
210,5,122,1,19,
210,5,126,4,0,
210,5,156,4,0,
210,5,168,4,0,
210,5,182,4,0,
210,5,184,1,1,2
210,5,188,4,0,
210,5,204,1,8,
210,5,213,4,0,
210,5,216,4,0,
210,5,218,4,0,
210,5,231,4,0,
210,5,237,4,0,
210,5,240,4,0,
210,5,241,4,0,
210,5,242,1,61,
210,5,247,4,0,
210,5,249,4,0,
210,5,259,4,0,
210,5,263,4,0,
210,5,264,4,0,
210,5,269,4,0,
210,5,280,4,0,
210,5,290,4,0,
210,5,315,4,0,
210,5,317,4,0,
210,5,339,4,0,
210,5,351,4,0,
210,5,352,4,0,
210,6,5,3,0,
210,6,7,3,0,
210,6,8,3,0,
210,6,9,3,0,
210,6,25,3,0,
210,6,33,1,1,1
210,6,34,3,0,
210,6,36,1,49,
210,6,38,3,0,
210,6,39,1,4,
210,6,44,1,13,
210,6,46,1,28,
210,6,46,4,0,
210,6,53,4,0,
210,6,63,4,0,
210,6,68,3,0,
210,6,69,3,0,
210,6,70,4,0,
210,6,76,4,0,
210,6,85,4,0,
210,6,86,3,0,
210,6,87,4,0,
210,6,89,4,0,
210,6,91,4,0,
210,6,92,4,0,
210,6,99,1,38,
210,6,102,3,0,
210,6,104,4,0,
210,6,111,3,0,
210,6,118,3,0,
210,6,122,1,19,
210,6,126,4,0,
210,6,156,4,0,
210,6,157,3,0,
210,6,164,3,0,
210,6,168,4,0,
210,6,173,3,0,
210,6,182,4,0,
210,6,184,1,1,2
210,6,188,4,0,
210,6,189,3,0,
210,6,203,3,0,
210,6,204,1,8,
210,6,207,3,0,
210,6,213,4,0,
210,6,214,3,0,
210,6,216,4,0,
210,6,218,4,0,
210,6,223,3,0,
210,6,231,4,0,
210,6,237,4,0,
210,6,240,4,0,
210,6,241,4,0,
210,6,242,1,61,
210,6,247,4,0,
210,6,249,4,0,
210,6,259,4,0,
210,6,263,4,0,
210,6,264,4,0,
210,6,269,4,0,
210,6,280,4,0,
210,6,290,4,0,
210,6,315,4,0,
210,6,317,4,0,
210,6,339,4,0,
210,6,351,4,0,
210,6,352,4,0,
210,7,5,3,0,
210,7,25,3,0,
210,7,33,1,1,1
210,7,34,3,0,
210,7,36,1,49,
210,7,38,3,0,
210,7,39,1,4,
210,7,44,1,13,
210,7,46,1,28,
210,7,46,4,0,
210,7,53,4,0,
210,7,63,4,0,
210,7,68,3,0,
210,7,69,3,0,
210,7,70,4,0,
210,7,76,4,0,
210,7,85,4,0,
210,7,86,3,0,
210,7,87,4,0,
210,7,89,4,0,
210,7,91,4,0,
210,7,92,4,0,
210,7,99,1,38,
210,7,102,3,0,
210,7,104,4,0,
210,7,118,3,0,
210,7,122,1,19,
210,7,126,4,0,
210,7,156,4,0,
210,7,157,3,0,
210,7,164,3,0,
210,7,168,4,0,
210,7,182,4,0,
210,7,184,1,1,2
210,7,188,4,0,
210,7,204,1,8,
210,7,213,4,0,
210,7,216,4,0,
210,7,218,4,0,
210,7,231,4,0,
210,7,237,4,0,
210,7,240,4,0,
210,7,241,4,0,
210,7,242,1,61,
210,7,247,4,0,
210,7,249,4,0,
210,7,259,4,0,
210,7,263,4,0,
210,7,264,4,0,
210,7,269,4,0,
210,7,280,4,0,
210,7,290,4,0,
210,7,315,4,0,
210,7,317,4,0,
210,7,339,4,0,
210,7,351,4,0,
210,7,352,4,0,
210,8,29,1,19,
210,8,33,1,1,4
210,8,36,1,43,
210,8,39,1,1,6
210,8,44,1,7,
210,8,46,1,27,
210,8,46,4,0,
210,8,53,4,0,
210,8,63,4,0,
210,8,70,4,0,
210,8,76,4,0,
210,8,85,4,0,
210,8,86,4,0,
210,8,87,4,0,
210,8,89,4,0,
210,8,91,4,0,
210,8,92,4,0,
210,8,99,1,35,
210,8,104,4,0,
210,8,122,1,13,
210,8,126,4,0,
210,8,156,4,0,
210,8,157,4,0,
210,8,164,4,0,
210,8,168,4,0,
210,8,182,4,0,
210,8,184,1,1,5
210,8,188,4,0,
210,8,203,4,0,
210,8,204,1,1,7
210,8,207,4,0,
210,8,213,4,0,
210,8,214,4,0,
210,8,216,4,0,
210,8,218,4,0,
210,8,231,4,0,
210,8,237,4,0,
210,8,240,4,0,
210,8,241,4,0,
210,8,242,1,59,
210,8,247,4,0,
210,8,249,4,0,
210,8,259,4,0,
210,8,263,4,0,
210,8,264,4,0,
210,8,269,4,0,
210,8,280,4,0,
210,8,290,4,0,
210,8,315,4,0,
210,8,317,4,0,
210,8,339,4,0,
210,8,351,4,0,
210,8,352,4,0,
210,8,363,4,0,
210,8,371,1,51,
210,8,371,4,0,
210,8,374,4,0,
210,8,411,4,0,
210,8,416,4,0,
210,8,422,1,1,3
210,8,423,1,1,1
210,8,424,1,1,2
210,8,431,4,0,
210,8,444,4,0,
210,8,445,4,0,
210,9,7,3,0,
210,9,8,3,0,
210,9,9,3,0,
210,9,29,1,19,
210,9,33,1,1,4
210,9,36,1,43,
210,9,39,1,1,6
210,9,44,1,7,
210,9,46,1,27,
210,9,46,4,0,
210,9,53,4,0,
210,9,63,4,0,
210,9,70,4,0,
210,9,76,4,0,
210,9,85,4,0,
210,9,86,4,0,
210,9,87,4,0,
210,9,89,4,0,
210,9,91,4,0,
210,9,92,4,0,
210,9,99,1,35,
210,9,104,4,0,
210,9,122,1,13,
210,9,126,4,0,
210,9,156,4,0,
210,9,157,4,0,
210,9,164,4,0,
210,9,168,4,0,
210,9,173,3,0,
210,9,182,4,0,
210,9,184,1,1,5
210,9,188,4,0,
210,9,189,3,0,
210,9,203,4,0,
210,9,204,1,1,7
210,9,207,4,0,
210,9,213,4,0,
210,9,214,4,0,
210,9,216,4,0,
210,9,218,4,0,
210,9,231,4,0,
210,9,237,4,0,
210,9,240,4,0,
210,9,241,4,0,
210,9,242,1,59,
210,9,247,4,0,
210,9,249,4,0,
210,9,259,4,0,
210,9,263,4,0,
210,9,264,4,0,
210,9,269,4,0,
210,9,276,3,0,
210,9,280,4,0,
210,9,290,4,0,
210,9,315,4,0,
210,9,317,4,0,
210,9,339,4,0,
210,9,351,4,0,
210,9,352,4,0,
210,9,363,4,0,
210,9,371,1,51,
210,9,371,4,0,
210,9,374,4,0,
210,9,387,3,0,
210,9,411,4,0,
210,9,416,4,0,
210,9,422,1,1,3
210,9,423,1,1,1
210,9,424,1,1,2
210,9,431,4,0,
210,9,444,4,0,
210,9,445,4,0,
210,10,7,3,0,
210,10,8,3,0,
210,10,9,3,0,
210,10,29,1,19,
210,10,29,3,0,
210,10,33,1,1,4
210,10,36,1,43,
210,10,39,1,1,6
210,10,44,1,7,
210,10,46,1,27,
210,10,46,4,0,
210,10,53,4,0,
210,10,63,4,0,
210,10,67,3,0,
210,10,70,4,0,
210,10,76,4,0,
210,10,85,4,0,
210,10,86,4,0,
210,10,87,4,0,
210,10,89,4,0,
210,10,91,4,0,
210,10,92,4,0,
210,10,99,1,35,
210,10,104,4,0,
210,10,122,1,13,
210,10,126,4,0,
210,10,156,4,0,
210,10,157,4,0,
210,10,162,3,0,
210,10,164,4,0,
210,10,168,4,0,
210,10,173,3,0,
210,10,182,4,0,
210,10,184,1,1,5
210,10,188,4,0,
210,10,189,3,0,
210,10,203,4,0,
210,10,204,1,1,7
210,10,207,4,0,
210,10,213,4,0,
210,10,214,4,0,
210,10,215,3,0,
210,10,216,4,0,
210,10,218,4,0,
210,10,231,4,0,
210,10,237,4,0,
210,10,240,4,0,
210,10,241,4,0,
210,10,242,1,59,
210,10,247,4,0,
210,10,249,4,0,
210,10,259,4,0,
210,10,263,4,0,
210,10,264,4,0,
210,10,269,4,0,
210,10,276,3,0,
210,10,280,4,0,
210,10,290,4,0,
210,10,315,4,0,
210,10,317,4,0,
210,10,339,4,0,
210,10,351,4,0,
210,10,352,4,0,
210,10,363,4,0,
210,10,371,1,51,
210,10,371,4,0,
210,10,374,4,0,
210,10,387,3,0,
210,10,411,4,0,
210,10,416,4,0,
210,10,422,1,1,3
210,10,423,1,1,1
210,10,424,1,1,2
210,10,431,4,0,
210,10,444,4,0,
210,10,445,4,0,
210,11,29,1,19,
210,11,33,1,1,4
210,11,36,1,43,
210,11,39,1,1,6
210,11,44,1,7,
210,11,46,1,27,
210,11,46,4,0,
210,11,53,4,0,
210,11,63,4,0,
210,11,70,4,0,
210,11,76,4,0,
210,11,85,4,0,
210,11,86,4,0,
210,11,87,4,0,
210,11,89,4,0,
210,11,91,4,0,
210,11,92,4,0,
210,11,99,1,35,
210,11,104,4,0,
210,11,115,4,0,
210,11,122,1,13,
210,11,126,4,0,
210,11,156,4,0,
210,11,157,4,0,
210,11,164,4,0,
210,11,168,4,0,
210,11,182,4,0,
210,11,184,1,1,5
210,11,188,4,0,
210,11,200,1,67,
210,11,204,1,1,7
210,11,207,4,0,
210,11,213,4,0,
210,11,216,4,0,
210,11,218,4,0,
210,11,237,4,0,
210,11,240,4,0,
210,11,241,4,0,
210,11,242,1,59,
210,11,247,4,0,
210,11,249,4,0,
210,11,259,4,0,
210,11,263,4,0,
210,11,269,4,0,
210,11,280,4,0,
210,11,315,4,0,
210,11,317,4,0,
210,11,339,4,0,
210,11,371,1,51,
210,11,371,4,0,
210,11,374,4,0,
210,11,411,4,0,
210,11,416,4,0,
210,11,422,1,1,3
210,11,423,1,1,1
210,11,424,1,1,2
210,11,444,4,0,
210,11,496,4,0,
210,11,510,4,0,
210,11,514,4,0,
210,11,523,4,0,
210,11,526,4,0,
210,11,528,4,0,
210,11,555,4,0,
210,12,33,1,1,1
210,12,36,1,49,
210,12,39,1,4,
210,12,44,1,13,
210,12,46,1,28,
210,12,46,4,0,
210,12,53,4,0,
210,12,63,4,0,
210,12,70,4,0,
210,12,76,4,0,
210,12,85,4,0,
210,12,87,4,0,
210,12,89,4,0,
210,12,91,4,0,
210,12,92,4,0,
210,12,99,1,38,
210,12,104,4,0,
210,12,122,1,19,
210,12,126,4,0,
210,12,156,4,0,
210,12,168,4,0,
210,12,182,4,0,
210,12,184,1,1,2
210,12,188,4,0,
210,12,204,1,8,
210,12,213,4,0,
210,12,216,4,0,
210,12,218,4,0,
210,12,231,4,0,
210,12,237,4,0,
210,12,240,4,0,
210,12,241,4,0,
210,12,242,1,61,
210,12,247,4,0,
210,12,249,4,0,
210,12,259,4,0,
210,12,263,4,0,
210,12,264,4,0,
210,12,269,4,0,
210,12,280,4,0,
210,12,290,4,0,
210,12,315,4,0,
210,12,317,4,0,
210,12,339,4,0,
210,12,351,4,0,
210,12,352,4,0,
210,13,33,1,1,1
210,13,34,3,0,
210,13,36,1,49,
210,13,38,3,0,
210,13,39,1,4,
210,13,44,1,13,
210,13,46,1,28,
210,13,46,4,0,
210,13,53,4,0,
210,13,63,4,0,
210,13,69,3,0,
210,13,70,4,0,
210,13,76,4,0,
210,13,85,4,0,
210,13,86,3,0,
210,13,87,4,0,
210,13,89,4,0,
210,13,91,4,0,
210,13,92,4,0,
210,13,99,1,38,
210,13,102,3,0,
210,13,104,4,0,
210,13,122,1,19,
210,13,126,4,0,
210,13,156,4,0,
210,13,164,3,0,
210,13,168,4,0,
210,13,182,4,0,
210,13,184,1,1,2
210,13,188,4,0,
210,13,204,1,8,
210,13,207,3,0,
210,13,213,4,0,
210,13,216,4,0,
210,13,218,4,0,
210,13,231,4,0,
210,13,237,4,0,
210,13,240,4,0,
210,13,241,4,0,
210,13,242,1,61,
210,13,247,4,0,
210,13,249,4,0,
210,13,259,4,0,
210,13,263,4,0,
210,13,264,4,0,
210,13,269,4,0,
210,13,280,4,0,
210,13,290,4,0,
210,13,315,4,0,
210,13,317,4,0,
210,13,339,4,0,
210,13,351,4,0,
210,13,352,4,0,
210,14,7,3,0,
210,14,8,3,0,
210,14,9,3,0,
210,14,29,1,19,
210,14,33,1,1,4
210,14,36,1,43,
210,14,39,1,1,6
210,14,44,1,7,
210,14,46,1,27,
210,14,46,4,0,
210,14,53,4,0,
210,14,63,4,0,
210,14,67,3,0,
210,14,70,4,0,
210,14,76,4,0,
210,14,85,4,0,
210,14,86,4,0,
210,14,87,4,0,
210,14,89,4,0,
210,14,91,4,0,
210,14,92,4,0,
210,14,99,1,35,
210,14,104,4,0,
210,14,115,4,0,
210,14,122,1,13,
210,14,126,4,0,
210,14,156,4,0,
210,14,157,4,0,
210,14,162,3,0,
210,14,164,4,0,
210,14,168,4,0,
210,14,173,3,0,
210,14,182,4,0,
210,14,184,1,1,5
210,14,188,4,0,
210,14,200,1,67,
210,14,200,3,0,
210,14,204,1,1,7
210,14,207,4,0,
210,14,213,4,0,
210,14,214,3,0,
210,14,215,3,0,
210,14,216,4,0,
210,14,218,4,0,
210,14,231,3,0,
210,14,237,4,0,
210,14,240,4,0,
210,14,241,4,0,
210,14,242,1,59,
210,14,247,4,0,
210,14,249,4,0,
210,14,259,4,0,
210,14,263,4,0,
210,14,269,4,0,
210,14,276,3,0,
210,14,280,4,0,
210,14,304,3,0,
210,14,315,4,0,
210,14,317,4,0,
210,14,339,4,0,
210,14,343,3,0,
210,14,371,1,51,
210,14,371,4,0,
210,14,374,4,0,
210,14,387,3,0,
210,14,411,4,0,
210,14,416,4,0,
210,14,422,1,1,3
210,14,423,1,1,1
210,14,424,1,1,2
210,14,444,4,0,
210,14,496,4,0,
210,14,510,4,0,
210,14,514,4,0,
210,14,523,4,0,
210,14,526,4,0,
210,14,528,4,0,
210,14,555,4,0,
210,15,29,1,19,
210,15,33,1,1,5
210,15,39,1,1,7
210,15,44,1,7,
210,15,46,1,27,
210,15,46,4,0,
210,15,53,4,0,
210,15,63,4,0,
210,15,70,4,0,
210,15,76,4,0,
210,15,85,4,0,
210,15,86,4,0,
210,15,87,4,0,
210,15,89,4,0,
210,15,91,4,0,
210,15,92,4,0,
210,15,99,1,35,
210,15,104,4,0,
210,15,115,4,0,
210,15,122,1,13,
210,15,126,4,0,
210,15,156,4,0,
210,15,157,4,0,
210,15,164,4,0,
210,15,168,4,0,
210,15,182,4,0,
210,15,184,1,1,6
210,15,188,4,0,
210,15,200,1,1,1
210,15,200,1,67,
210,15,204,1,1,8
210,15,207,4,0,
210,15,213,4,0,
210,15,214,4,0,
210,15,216,4,0,
210,15,218,4,0,
210,15,237,4,0,
210,15,240,4,0,
210,15,241,4,0,
210,15,242,1,59,
210,15,247,4,0,
210,15,249,4,0,
210,15,259,4,0,
210,15,263,4,0,
210,15,269,4,0,
210,15,280,4,0,
210,15,315,4,0,
210,15,317,4,0,
210,15,339,4,0,
210,15,371,1,51,
210,15,371,4,0,
210,15,374,4,0,
210,15,411,4,0,
210,15,416,4,0,
210,15,422,1,1,4
210,15,423,1,1,2
210,15,424,1,1,3
210,15,444,4,0,
210,15,496,4,0,
210,15,510,4,0,
210,15,514,4,0,
210,15,523,4,0,
210,15,528,4,0,
210,15,555,4,0,
210,15,583,1,43,
210,15,590,4,0,
210,15,605,4,0,
210,15,612,4,0,
210,16,7,3,0,
210,16,8,3,0,
210,16,9,3,0,
210,16,29,1,19,1
210,16,33,1,1,5
210,16,39,1,1,7
210,16,44,1,7,1
210,16,46,1,27,1
210,16,46,4,0,
210,16,53,4,0,
210,16,63,4,0,
210,16,67,3,0,
210,16,70,4,0,
210,16,76,4,0,
210,16,85,4,0,
210,16,86,4,0,
210,16,87,4,0,
210,16,89,4,0,
210,16,91,4,0,
210,16,92,4,0,
210,16,99,1,35,1
210,16,104,4,0,
210,16,115,4,0,
210,16,122,1,13,1
210,16,126,4,0,
210,16,156,4,0,
210,16,157,4,0,
210,16,162,3,0,
210,16,164,4,0,
210,16,168,4,0,
210,16,173,3,0,
210,16,182,4,0,
210,16,184,1,1,6
210,16,188,4,0,
210,16,200,1,1,1
210,16,200,1,67,1
210,16,200,3,0,
210,16,204,1,1,8
210,16,207,4,0,
210,16,213,4,0,
210,16,214,4,0,
210,16,215,3,0,
210,16,216,4,0,
210,16,218,4,0,
210,16,231,3,0,
210,16,237,4,0,
210,16,240,4,0,
210,16,241,4,0,
210,16,242,1,59,1
210,16,247,4,0,
210,16,249,4,0,
210,16,259,4,0,
210,16,263,4,0,
210,16,264,3,0,
210,16,269,4,0,
210,16,276,3,0,
210,16,280,4,0,
210,16,290,4,0,
210,16,304,3,0,
210,16,315,4,0,
210,16,317,4,0,
210,16,339,4,0,
210,16,343,3,0,
210,16,351,3,0,
210,16,352,3,0,
210,16,371,1,51,1
210,16,371,4,0,
210,16,374,4,0,
210,16,387,3,0,
210,16,411,4,0,
210,16,416,4,0,
210,16,422,1,1,4
210,16,423,1,1,2
210,16,424,1,1,3
210,16,444,4,0,
210,16,496,4,0,
210,16,510,4,0,
210,16,514,4,0,
210,16,523,4,0,
210,16,528,4,0,
210,16,555,4,0,
210,16,583,1,43,1
210,16,590,4,0,
210,16,605,4,0,
210,16,612,4,0,
210,17,29,1,19,
210,17,33,1,1,5
210,17,39,1,1,7
210,17,44,1,7,
210,17,46,1,27,
210,17,46,4,0,
210,17,53,4,0,
210,17,63,4,0,
210,17,76,4,0,
210,17,85,4,0,
210,17,86,4,0,
210,17,87,4,0,
210,17,89,4,0,
210,17,92,4,0,
210,17,99,1,35,
210,17,104,4,0,
210,17,115,4,0,
210,17,122,1,13,
210,17,126,4,0,
210,17,156,4,0,
210,17,157,4,0,
210,17,164,4,0,
210,17,168,4,0,
210,17,182,4,0,
210,17,184,1,1,6
210,17,188,4,0,
210,17,200,1,1,1
210,17,200,1,67,
210,17,204,1,1,8
210,17,207,4,0,
210,17,213,4,0,
210,17,214,4,0,
210,17,216,4,0,
210,17,218,4,0,
210,17,237,4,0,
210,17,240,4,0,
210,17,241,4,0,
210,17,242,1,59,
210,17,247,4,0,
210,17,259,4,0,
210,17,263,4,0,
210,17,269,4,0,
210,17,280,4,0,
210,17,315,4,0,
210,17,317,4,0,
210,17,339,4,0,
210,17,371,1,51,
210,17,371,4,0,
210,17,374,4,0,
210,17,411,4,0,
210,17,416,4,0,
210,17,422,1,1,4
210,17,423,1,1,2
210,17,424,1,1,3
210,17,444,4,0,
210,17,496,4,0,
210,17,523,4,0,
210,17,526,4,0,
210,17,528,4,0,
210,17,555,4,0,
210,17,583,1,43,
210,17,590,4,0,
210,17,605,4,0,
210,18,29,1,19,
210,18,33,1,1,5
210,18,39,1,1,7
210,18,44,1,7,
210,18,46,1,27,
210,18,46,4,0,
210,18,53,4,0,
210,18,63,4,0,
210,18,76,4,0,
210,18,85,4,0,
210,18,86,4,0,
210,18,87,4,0,
210,18,89,4,0,
210,18,92,4,0,
210,18,99,1,35,
210,18,104,4,0,
210,18,115,4,0,
210,18,122,1,13,
210,18,126,4,0,
210,18,156,4,0,
210,18,157,4,0,
210,18,164,4,0,
210,18,168,4,0,
210,18,182,4,0,
210,18,184,1,1,6
210,18,188,4,0,
210,18,200,1,1,1
210,18,200,1,67,
210,18,204,1,1,8
210,18,207,4,0,
210,18,213,4,0,
210,18,214,4,0,
210,18,216,4,0,
210,18,218,4,0,
210,18,237,4,0,
210,18,240,4,0,
210,18,241,4,0,
210,18,242,1,59,
210,18,247,4,0,
210,18,259,4,0,
210,18,263,4,0,
210,18,269,4,0,
210,18,280,4,0,
210,18,315,4,0,
210,18,317,4,0,
210,18,339,4,0,
210,18,371,1,51,
210,18,371,4,0,
210,18,374,4,0,
210,18,411,4,0,
210,18,416,4,0,
210,18,422,1,1,4
210,18,423,1,1,2
210,18,424,1,1,3
210,18,444,4,0,
210,18,496,4,0,
210,18,523,4,0,
210,18,526,4,0,
210,18,528,4,0,
210,18,555,4,0,
210,18,583,1,43,
210,18,590,4,0,
210,18,605,4,0,
211,3,29,4,0,
211,3,33,1,1,1
211,3,36,1,37,
211,3,40,1,1,2
211,3,42,1,28,
211,3,48,2,0,
211,3,55,1,19,
211,3,56,1,46,
211,3,57,4,0,
211,3,59,4,0,
211,3,61,2,0,
211,3,92,4,0,
211,3,104,4,0,
211,3,106,1,10,
211,3,107,1,10,3
211,3,111,4,0,
211,3,114,2,0,
211,3,127,4,0,
211,3,129,4,0,
211,3,156,4,0,
211,3,173,4,0,
211,3,174,4,0,
211,3,175,2,0,
211,3,182,4,0,
211,3,188,4,0,
211,3,196,4,0,
211,3,203,4,0,
211,3,205,4,0,
211,3,207,4,0,
211,3,213,4,0,
211,3,214,4,0,
211,3,216,4,0,
211,3,218,4,0,
211,3,237,4,0,
211,3,240,4,0,
211,3,250,4,0,
211,4,29,4,0,
211,4,33,1,1,2
211,4,36,1,37,
211,4,40,1,1,3
211,4,42,1,28,
211,4,48,2,0,
211,4,55,1,19,
211,4,56,1,46,
211,4,57,4,0,
211,4,58,3,0,
211,4,59,4,0,
211,4,61,2,0,
211,4,92,4,0,
211,4,104,4,0,
211,4,106,1,10,
211,4,107,1,10,4
211,4,111,4,0,
211,4,114,2,0,
211,4,127,4,0,
211,4,129,4,0,
211,4,156,4,0,
211,4,173,4,0,
211,4,174,4,0,
211,4,175,2,0,
211,4,182,4,0,
211,4,188,4,0,
211,4,191,1,1,1
211,4,196,4,0,
211,4,203,4,0,
211,4,205,4,0,
211,4,207,4,0,
211,4,213,4,0,
211,4,214,4,0,
211,4,216,4,0,
211,4,218,4,0,
211,4,237,4,0,
211,4,240,4,0,
211,4,250,4,0,
211,5,33,1,1,2
211,5,36,1,37,
211,5,40,1,1,3
211,5,42,1,28,
211,5,48,2,0,
211,5,55,1,19,
211,5,56,1,46,
211,5,57,4,0,
211,5,58,4,0,
211,5,59,4,0,
211,5,61,2,0,
211,5,92,4,0,
211,5,104,4,0,
211,5,106,1,10,
211,5,107,1,10,4
211,5,114,2,0,
211,5,127,4,0,
211,5,156,4,0,
211,5,175,2,0,
211,5,182,4,0,
211,5,188,4,0,
211,5,191,1,1,1
211,5,213,4,0,
211,5,216,4,0,
211,5,218,4,0,
211,5,237,4,0,
211,5,240,4,0,
211,5,247,4,0,
211,5,258,4,0,
211,5,263,4,0,
211,5,290,4,0,
211,5,291,4,0,
211,5,310,2,0,
211,5,351,4,0,
211,5,352,4,0,
211,6,14,3,0,
211,6,33,1,1,2
211,6,36,1,37,
211,6,38,3,0,
211,6,40,1,1,3
211,6,42,1,28,
211,6,48,2,0,
211,6,55,1,19,
211,6,56,1,46,
211,6,57,4,0,
211,6,58,4,0,
211,6,59,4,0,
211,6,61,2,0,
211,6,86,3,0,
211,6,92,4,0,
211,6,102,3,0,
211,6,104,4,0,
211,6,106,1,10,
211,6,107,1,10,4
211,6,111,3,0,
211,6,114,2,0,
211,6,127,4,0,
211,6,129,3,0,
211,6,156,4,0,
211,6,164,3,0,
211,6,173,3,0,
211,6,175,2,0,
211,6,182,4,0,
211,6,188,4,0,
211,6,191,1,1,1
211,6,196,3,0,
211,6,203,3,0,
211,6,205,3,0,
211,6,207,3,0,
211,6,213,4,0,
211,6,214,3,0,
211,6,216,4,0,
211,6,218,4,0,
211,6,237,4,0,
211,6,240,4,0,
211,6,247,4,0,
211,6,258,4,0,
211,6,263,4,0,
211,6,290,4,0,
211,6,291,4,0,
211,6,310,2,0,
211,6,351,4,0,
211,6,352,4,0,
211,7,14,3,0,
211,7,33,1,1,2
211,7,36,1,33,
211,7,38,3,0,
211,7,40,1,1,3
211,7,42,1,21,
211,7,48,2,0,
211,7,55,1,13,
211,7,56,1,37,
211,7,57,4,0,
211,7,58,4,0,
211,7,59,4,0,
211,7,61,2,0,
211,7,86,3,0,
211,7,92,4,0,
211,7,102,3,0,
211,7,104,4,0,
211,7,106,1,9,
211,7,107,1,9,4
211,7,114,2,0,
211,7,127,4,0,
211,7,156,4,0,
211,7,164,3,0,
211,7,175,2,0,
211,7,182,4,0,
211,7,188,4,0,
211,7,191,1,1,1
211,7,194,1,45,
211,7,213,4,0,
211,7,216,4,0,
211,7,218,4,0,
211,7,237,4,0,
211,7,240,4,0,
211,7,247,4,0,
211,7,258,4,0,
211,7,263,4,0,
211,7,279,1,25,
211,7,290,4,0,
211,7,291,4,0,
211,7,310,2,0,
211,7,351,4,0,
211,7,352,4,0,
211,8,33,1,1,2
211,8,36,1,41,
211,8,40,1,1,3
211,8,42,1,37,
211,8,48,2,0,
211,8,55,1,13,
211,8,56,1,57,
211,8,57,4,0,
211,8,58,4,0,
211,8,59,4,0,
211,8,61,2,0,
211,8,86,4,0,
211,8,92,4,0,
211,8,104,4,0,
211,8,106,1,9,1
211,8,107,1,9,2
211,8,114,2,0,
211,8,127,4,0,
211,8,153,4,0,
211,8,156,4,0,
211,8,164,4,0,
211,8,175,2,0,
211,8,182,4,0,
211,8,188,4,0,
211,8,191,1,1,1
211,8,194,1,53,
211,8,203,4,0,
211,8,205,1,17,
211,8,207,4,0,
211,8,213,4,0,
211,8,214,4,0,
211,8,216,4,0,
211,8,218,4,0,
211,8,237,4,0,
211,8,240,4,0,
211,8,247,4,0,
211,8,254,1,25,1
211,8,255,1,25,2
211,8,258,4,0,
211,8,263,4,0,
211,8,269,4,0,
211,8,279,1,29,
211,8,290,4,0,
211,8,310,2,0,
211,8,324,2,0,
211,8,351,4,0,
211,8,352,4,0,
211,8,360,4,0,
211,8,362,1,33,
211,8,362,4,0,
211,8,363,4,0,
211,8,371,4,0,
211,8,390,1,21,
211,8,398,1,49,
211,8,398,2,0,
211,8,398,4,0,
211,8,401,1,45,
211,8,445,4,0,
211,9,33,1,1,2
211,9,36,1,41,
211,9,40,1,1,3
211,9,42,1,37,
211,9,48,2,0,
211,9,55,1,13,
211,9,56,1,57,
211,9,57,4,0,
211,9,58,4,0,
211,9,59,4,0,
211,9,61,2,0,
211,9,86,4,0,
211,9,92,4,0,
211,9,104,4,0,
211,9,106,1,9,1
211,9,107,1,9,2
211,9,114,2,0,
211,9,127,4,0,
211,9,129,3,0,
211,9,153,4,0,
211,9,156,4,0,
211,9,164,4,0,
211,9,173,3,0,
211,9,175,2,0,
211,9,182,4,0,
211,9,188,4,0,
211,9,191,1,1,1
211,9,194,1,53,
211,9,196,3,0,
211,9,203,4,0,
211,9,205,1,17,
211,9,205,3,0,
211,9,207,4,0,
211,9,213,4,0,
211,9,214,4,0,
211,9,216,4,0,
211,9,218,4,0,
211,9,237,4,0,
211,9,240,4,0,
211,9,247,4,0,
211,9,254,1,25,1
211,9,255,1,25,2
211,9,258,4,0,
211,9,263,4,0,
211,9,269,4,0,
211,9,279,1,29,
211,9,290,4,0,
211,9,291,3,0,
211,9,310,2,0,
211,9,324,2,0,
211,9,324,3,0,
211,9,340,3,0,
211,9,351,4,0,
211,9,352,4,0,
211,9,360,4,0,
211,9,362,1,33,
211,9,362,4,0,
211,9,363,4,0,
211,9,371,4,0,
211,9,390,1,21,
211,9,398,1,49,
211,9,398,2,0,
211,9,398,4,0,
211,9,401,1,45,
211,9,401,3,0,
211,9,445,4,0,
211,10,29,3,0,
211,10,33,1,1,2
211,10,36,1,41,
211,10,40,1,1,3
211,10,42,1,37,
211,10,48,2,0,
211,10,55,1,13,
211,10,56,1,57,
211,10,57,4,0,
211,10,58,4,0,
211,10,59,4,0,
211,10,61,2,0,
211,10,86,4,0,
211,10,92,4,0,
211,10,104,4,0,
211,10,106,1,9,1
211,10,107,1,9,2
211,10,114,2,0,
211,10,127,4,0,
211,10,129,3,0,
211,10,153,4,0,
211,10,156,4,0,
211,10,164,4,0,
211,10,173,3,0,
211,10,175,2,0,
211,10,182,4,0,
211,10,188,4,0,
211,10,191,1,1,1
211,10,194,1,53,
211,10,196,3,0,
211,10,203,4,0,
211,10,205,1,17,
211,10,205,3,0,
211,10,207,4,0,
211,10,213,4,0,
211,10,214,4,0,
211,10,216,4,0,
211,10,218,4,0,
211,10,220,3,0,
211,10,237,4,0,
211,10,240,4,0,
211,10,247,4,0,
211,10,250,4,0,
211,10,254,1,25,1
211,10,255,1,25,2
211,10,258,4,0,
211,10,263,4,0,
211,10,269,4,0,
211,10,279,1,29,
211,10,290,4,0,
211,10,291,3,0,
211,10,310,2,0,
211,10,324,2,0,
211,10,324,3,0,
211,10,340,3,0,
211,10,351,4,0,
211,10,352,4,0,
211,10,360,4,0,
211,10,362,1,33,
211,10,362,4,0,
211,10,363,4,0,
211,10,371,4,0,
211,10,390,1,21,
211,10,398,1,49,
211,10,398,2,0,
211,10,398,4,0,
211,10,401,1,45,
211,10,401,3,0,
211,10,445,4,0,
211,10,453,2,0,
211,11,33,1,1,2
211,11,36,1,41,
211,11,40,1,1,3
211,11,42,1,37,
211,11,48,2,0,
211,11,55,1,13,
211,11,56,1,57,
211,11,57,4,0,
211,11,58,4,0,
211,11,59,4,0,
211,11,61,2,0,
211,11,86,4,0,
211,11,92,4,0,
211,11,104,4,0,
211,11,106,1,9,1
211,11,107,1,9,2
211,11,114,2,0,
211,11,127,4,0,
211,11,153,4,0,
211,11,156,4,0,
211,11,164,4,0,
211,11,175,2,0,
211,11,182,4,0,
211,11,188,4,0,
211,11,191,1,1,1
211,11,194,1,53,
211,11,205,1,17,
211,11,207,4,0,
211,11,213,4,0,
211,11,216,4,0,
211,11,218,4,0,
211,11,237,4,0,
211,11,240,4,0,
211,11,247,4,0,
211,11,254,1,25,1
211,11,255,1,25,2
211,11,258,4,0,
211,11,263,4,0,
211,11,269,4,0,
211,11,279,1,29,
211,11,291,4,0,
211,11,310,2,0,
211,11,324,2,0,
211,11,352,2,0,
211,11,360,4,0,
211,11,362,1,33,
211,11,362,2,0,
211,11,371,4,0,
211,11,390,1,21,
211,11,398,1,49,
211,11,398,4,0,
211,11,401,1,45,
211,11,453,2,0,
211,11,474,4,0,
211,11,482,4,0,
211,11,491,2,0,
211,11,496,4,0,
211,11,503,4,0,
211,12,33,1,1,2
211,12,36,1,37,
211,12,40,1,1,3
211,12,42,1,28,
211,12,55,1,19,
211,12,56,1,46,
211,12,57,4,0,
211,12,58,4,0,
211,12,59,4,0,
211,12,92,4,0,
211,12,104,4,0,
211,12,106,1,10,
211,12,107,1,10,4
211,12,127,4,0,
211,12,156,4,0,
211,12,182,4,0,
211,12,188,4,0,
211,12,191,1,1,1
211,12,213,4,0,
211,12,216,4,0,
211,12,218,4,0,
211,12,237,4,0,
211,12,240,4,0,
211,12,247,4,0,
211,12,258,4,0,
211,12,263,4,0,
211,12,290,4,0,
211,12,291,4,0,
211,12,351,4,0,
211,12,352,4,0,
211,13,33,1,1,2
211,13,36,1,37,
211,13,38,3,0,
211,13,40,1,1,3
211,13,42,1,28,
211,13,55,1,19,
211,13,56,1,46,
211,13,57,4,0,
211,13,58,4,0,
211,13,59,4,0,
211,13,86,3,0,
211,13,92,4,0,
211,13,102,3,0,
211,13,104,4,0,
211,13,106,1,10,
211,13,107,1,10,4
211,13,120,3,0,
211,13,127,4,0,
211,13,156,4,0,
211,13,164,3,0,
211,13,182,4,0,
211,13,188,4,0,
211,13,191,1,1,1
211,13,196,3,0,
211,13,207,3,0,
211,13,213,4,0,
211,13,216,4,0,
211,13,218,4,0,
211,13,237,4,0,
211,13,240,4,0,
211,13,247,4,0,
211,13,258,4,0,
211,13,263,4,0,
211,13,290,4,0,
211,13,291,4,0,
211,13,351,4,0,
211,13,352,4,0,
211,14,33,1,1,2
211,14,36,1,41,
211,14,40,1,1,3
211,14,42,1,37,
211,14,48,2,0,
211,14,55,1,13,
211,14,56,1,57,
211,14,57,4,0,
211,14,58,4,0,
211,14,59,4,0,
211,14,61,2,0,
211,14,86,4,0,
211,14,92,4,0,
211,14,104,4,0,
211,14,106,1,9,1
211,14,107,1,9,2
211,14,114,2,0,
211,14,127,4,0,
211,14,153,4,0,
211,14,156,4,0,
211,14,164,4,0,
211,14,173,3,0,
211,14,175,2,0,
211,14,182,4,0,
211,14,188,4,0,
211,14,191,1,1,1
211,14,194,1,53,
211,14,196,3,0,
211,14,205,1,17,
211,14,207,4,0,
211,14,213,4,0,
211,14,214,3,0,
211,14,216,4,0,
211,14,218,4,0,
211,14,220,3,0,
211,14,237,4,0,
211,14,240,4,0,
211,14,247,4,0,
211,14,254,1,25,1
211,14,255,1,25,2
211,14,258,4,0,
211,14,263,4,0,
211,14,269,4,0,
211,14,279,1,29,
211,14,291,4,0,
211,14,310,2,0,
211,14,324,2,0,
211,14,324,3,0,
211,14,340,3,0,
211,14,352,2,0,
211,14,360,4,0,
211,14,362,1,33,
211,14,362,2,0,
211,14,371,4,0,
211,14,390,1,21,
211,14,398,1,49,
211,14,398,4,0,
211,14,401,1,45,
211,14,401,3,0,
211,14,453,2,0,
211,14,474,4,0,
211,14,482,4,0,
211,14,491,2,0,
211,14,496,4,0,
211,14,503,4,0,
211,15,33,1,1,6
211,15,36,1,41,
211,15,40,1,1,7
211,15,42,1,37,
211,15,48,2,0,
211,15,55,1,1,4
211,15,56,1,1,2
211,15,56,1,57,
211,15,57,4,0,
211,15,58,4,0,
211,15,59,4,0,
211,15,61,2,0,
211,15,86,4,0,
211,15,92,4,0,
211,15,104,4,0,
211,15,106,1,9,1
211,15,107,1,9,2
211,15,114,2,0,
211,15,127,4,0,
211,15,145,1,13,
211,15,153,4,0,
211,15,156,4,0,
211,15,164,4,0,
211,15,175,2,0,
211,15,182,4,0,
211,15,188,4,0,
211,15,191,1,1,5
211,15,194,1,1,3
211,15,194,1,53,
211,15,205,1,17,
211,15,207,4,0,
211,15,213,4,0,
211,15,214,4,0,
211,15,216,4,0,
211,15,218,4,0,
211,15,237,4,0,
211,15,240,4,0,
211,15,247,4,0,
211,15,254,1,25,1
211,15,255,1,25,2
211,15,258,4,0,
211,15,263,4,0,
211,15,269,4,0,
211,15,279,1,29,
211,15,310,2,0,
211,15,324,2,0,
211,15,352,2,0,
211,15,360,4,0,
211,15,362,1,33,
211,15,362,2,0,
211,15,371,4,0,
211,15,390,1,21,
211,15,398,1,49,
211,15,398,4,0,
211,15,401,1,45,
211,15,453,2,0,
211,15,474,4,0,
211,15,482,4,0,
211,15,491,2,0,
211,15,496,4,0,
211,15,503,4,0,
211,15,565,1,1,1
211,15,565,1,60,
211,15,590,4,0,
211,16,33,1,1,6
211,16,36,1,41,1
211,16,40,1,1,7
211,16,42,1,37,1
211,16,48,2,0,
211,16,55,1,1,4
211,16,56,1,1,2
211,16,56,1,57,1
211,16,57,4,0,
211,16,58,4,0,
211,16,59,4,0,
211,16,61,2,0,
211,16,86,4,0,
211,16,92,4,0,
211,16,104,4,0,
211,16,106,1,9,1
211,16,107,1,9,2
211,16,114,2,0,
211,16,127,4,0,
211,16,145,1,13,1
211,16,153,4,0,
211,16,156,4,0,
211,16,164,4,0,
211,16,173,3,0,
211,16,175,2,0,
211,16,182,4,0,
211,16,188,4,0,
211,16,191,1,1,5
211,16,194,1,1,3
211,16,194,1,53,1
211,16,196,3,0,
211,16,205,1,17,1
211,16,207,4,0,
211,16,213,4,0,
211,16,214,4,0,
211,16,216,4,0,
211,16,218,4,0,
211,16,220,3,0,
211,16,237,4,0,
211,16,240,4,0,
211,16,247,4,0,
211,16,254,1,25,1
211,16,255,1,25,2
211,16,258,4,0,
211,16,263,4,0,
211,16,269,4,0,
211,16,279,1,29,1
211,16,290,4,0,
211,16,291,4,0,
211,16,310,2,0,
211,16,324,2,0,
211,16,324,3,0,
211,16,340,3,0,
211,16,351,3,0,
211,16,352,2,0,
211,16,352,3,0,
211,16,360,4,0,
211,16,362,1,33,1
211,16,362,2,0,
211,16,371,4,0,
211,16,390,1,21,1
211,16,398,1,49,1
211,16,398,4,0,
211,16,401,1,45,1
211,16,401,3,0,
211,16,453,2,0,
211,16,474,4,0,
211,16,482,4,0,
211,16,491,2,0,
211,16,496,4,0,
211,16,503,4,0,
211,16,565,1,1,1
211,16,565,1,60,1
211,16,590,4,0,
211,17,33,1,1,6
211,17,36,1,41,
211,17,40,1,1,7
211,17,42,1,37,
211,17,48,2,0,
211,17,55,1,1,4
211,17,56,1,1,2
211,17,56,1,57,
211,17,57,4,0,
211,17,58,4,0,
211,17,59,4,0,
211,17,61,2,0,
211,17,86,4,0,
211,17,92,4,0,
211,17,104,4,0,
211,17,106,1,9,1
211,17,107,1,9,2
211,17,114,2,0,
211,17,127,4,0,
211,17,145,1,13,
211,17,153,4,0,
211,17,156,4,0,
211,17,164,4,0,
211,17,175,2,0,
211,17,182,4,0,
211,17,188,4,0,
211,17,191,1,1,5
211,17,194,1,1,3
211,17,194,1,53,
211,17,205,1,17,
211,17,207,4,0,
211,17,213,4,0,
211,17,214,4,0,
211,17,216,4,0,
211,17,218,4,0,
211,17,237,4,0,
211,17,240,4,0,
211,17,247,4,0,
211,17,254,1,25,1
211,17,255,1,25,2
211,17,258,4,0,
211,17,263,4,0,
211,17,269,4,0,
211,17,279,1,29,
211,17,310,2,0,
211,17,324,2,0,
211,17,352,2,0,
211,17,360,4,0,
211,17,362,1,33,
211,17,362,2,0,
211,17,371,4,0,
211,17,390,1,21,
211,17,398,1,49,
211,17,398,4,0,
211,17,401,1,45,
211,17,453,2,0,
211,17,474,4,0,
211,17,482,4,0,
211,17,491,2,0,
211,17,496,4,0,
211,17,503,4,0,
211,17,565,1,1,1
211,17,565,1,60,
211,17,590,4,0,
211,18,33,1,1,6
211,18,36,1,41,
211,18,40,1,1,7
211,18,42,1,37,
211,18,48,2,0,
211,18,55,1,1,4
211,18,56,1,1,2
211,18,56,1,57,
211,18,57,4,0,
211,18,58,4,0,
211,18,59,4,0,
211,18,61,2,0,
211,18,86,4,0,
211,18,92,4,0,
211,18,104,4,0,
211,18,106,1,9,1
211,18,107,1,9,2
211,18,114,2,0,
211,18,127,4,0,
211,18,145,1,13,
211,18,153,4,0,
211,18,156,4,0,
211,18,164,4,0,
211,18,175,2,0,
211,18,182,4,0,
211,18,188,4,0,
211,18,191,1,1,5
211,18,194,1,1,3
211,18,194,1,53,
211,18,205,1,17,
211,18,207,4,0,
211,18,213,4,0,
211,18,214,4,0,
211,18,216,4,0,
211,18,218,4,0,
211,18,237,4,0,
211,18,240,4,0,
211,18,247,4,0,
211,18,254,1,25,1
211,18,255,1,25,2
211,18,258,4,0,
211,18,263,4,0,
211,18,269,4,0,
211,18,279,1,29,
211,18,310,2,0,
211,18,324,2,0,
211,18,352,2,0,
211,18,360,4,0,
211,18,362,1,33,
211,18,362,2,0,
211,18,371,4,0,
211,18,390,1,21,
211,18,398,1,49,
211,18,398,4,0,
211,18,401,1,45,
211,18,453,2,0,
211,18,474,4,0,
211,18,482,4,0,
211,18,491,2,0,
211,18,496,4,0,
211,18,503,4,0,
211,18,565,1,1,1
211,18,565,1,60,
211,18,590,4,0,
212,3,14,1,42,
212,3,15,4,0,
212,3,29,4,0,
212,3,43,1,1,2
212,3,63,4,0,
212,3,70,4,0,
212,3,92,4,0,
212,3,97,1,24,
212,3,98,1,1,1
212,3,104,1,48,
212,3,104,4,0,
212,3,116,1,6,
212,3,129,4,0,
212,3,156,4,0,
212,3,163,1,36,
212,3,168,4,0,
212,3,173,4,0,
212,3,174,4,0,
212,3,182,4,0,
212,3,197,4,0,
212,3,201,4,0,
212,3,203,4,0,
212,3,206,1,18,
212,3,207,4,0,
212,3,210,4,0,
212,3,211,4,0,
212,3,213,4,0,
212,3,214,4,0,
212,3,216,4,0,
212,3,218,4,0,
212,3,228,1,12,
212,3,232,1,30,
212,3,237,4,0,
212,3,241,4,0,
212,3,249,4,0,
212,4,14,1,42,
212,4,15,4,0,
212,4,29,4,0,
212,4,43,1,1,2
212,4,63,4,0,
212,4,70,4,0,
212,4,92,4,0,
212,4,97,1,24,
212,4,98,1,1,1
212,4,104,1,48,
212,4,104,4,0,
212,4,116,1,6,
212,4,129,4,0,
212,4,156,4,0,
212,4,163,1,36,
212,4,168,4,0,
212,4,173,4,0,
212,4,174,4,0,
212,4,182,4,0,
212,4,197,4,0,
212,4,201,4,0,
212,4,203,4,0,
212,4,206,1,18,
212,4,207,4,0,
212,4,210,4,0,
212,4,211,4,0,
212,4,213,4,0,
212,4,214,4,0,
212,4,216,4,0,
212,4,218,4,0,
212,4,228,1,12,
212,4,232,1,30,
212,4,237,4,0,
212,4,241,4,0,
212,4,249,4,0,
212,5,14,1,36,
212,5,15,4,0,
212,5,43,1,1,2
212,5,63,4,0,
212,5,70,4,0,
212,5,92,4,0,
212,5,97,1,21,
212,5,98,1,1,1
212,5,104,1,41,
212,5,104,4,0,
212,5,116,1,6,
212,5,156,4,0,
212,5,163,1,31,
212,5,168,4,0,
212,5,182,4,0,
212,5,201,4,0,
212,5,206,1,16,
212,5,210,1,46,
212,5,211,4,0,
212,5,213,4,0,
212,5,216,4,0,
212,5,218,4,0,
212,5,228,1,11,
212,5,232,1,26,
212,5,237,4,0,
212,5,240,4,0,
212,5,241,4,0,
212,5,249,4,0,
212,5,263,4,0,
212,5,290,4,0,
212,5,332,4,0,
212,6,14,1,36,
212,6,14,3,0,
212,6,15,4,0,
212,6,38,3,0,
212,6,43,1,1,2
212,6,63,4,0,
212,6,68,3,0,
212,6,70,4,0,
212,6,92,4,0,
212,6,97,1,21,
212,6,98,1,1,1
212,6,102,3,0,
212,6,104,1,41,
212,6,104,4,0,
212,6,116,1,6,
212,6,129,3,0,
212,6,156,4,0,
212,6,163,1,31,
212,6,164,3,0,
212,6,168,4,0,
212,6,173,3,0,
212,6,182,4,0,
212,6,201,4,0,
212,6,203,3,0,
212,6,206,1,16,
212,6,207,3,0,
212,6,210,1,46,
212,6,210,3,0,
212,6,211,4,0,
212,6,213,4,0,
212,6,214,3,0,
212,6,216,4,0,
212,6,218,4,0,
212,6,228,1,11,
212,6,232,1,26,
212,6,237,4,0,
212,6,240,4,0,
212,6,241,4,0,
212,6,249,4,0,
212,6,263,4,0,
212,6,290,4,0,
212,6,332,4,0,
212,7,14,1,36,
212,7,14,3,0,
212,7,15,4,0,
212,7,38,3,0,
212,7,43,1,1,2
212,7,63,4,0,
212,7,68,3,0,
212,7,70,4,0,
212,7,92,4,0,
212,7,97,1,21,
212,7,98,1,1,1
212,7,102,3,0,
212,7,104,4,0,
212,7,116,1,6,
212,7,156,4,0,
212,7,163,1,31,
212,7,164,3,0,
212,7,168,4,0,
212,7,182,4,0,
212,7,201,4,0,
212,7,206,1,16,
212,7,210,1,46,
212,7,211,4,0,
212,7,213,4,0,
212,7,216,4,0,
212,7,218,4,0,
212,7,228,1,11,
212,7,232,1,26,
212,7,237,4,0,
212,7,240,4,0,
212,7,241,4,0,
212,7,249,4,0,
212,7,263,4,0,
212,7,290,4,0,
212,7,332,4,0,
212,7,334,1,41,
212,8,13,1,33,
212,8,14,1,57,
212,8,14,4,0,
212,8,15,4,0,
212,8,43,1,1,2
212,8,63,4,0,
212,8,70,4,0,
212,8,92,4,0,
212,8,97,1,17,
212,8,98,1,1,1
212,8,104,4,0,
212,8,116,1,5,
212,8,156,4,0,
212,8,163,1,29,
212,8,164,4,0,
212,8,168,4,0,
212,8,182,4,0,
212,8,201,4,0,
212,8,203,4,0,
212,8,206,1,13,
212,8,206,4,0,
212,8,207,4,0,
212,8,210,1,25,
212,8,211,4,0,
212,8,213,4,0,
212,8,214,4,0,
212,8,216,4,0,
212,8,218,4,0,
212,8,228,1,9,
212,8,232,1,21,
212,8,237,4,0,
212,8,240,4,0,
212,8,241,4,0,
212,8,249,4,0,
212,8,263,4,0,
212,8,280,4,0,
212,8,290,4,0,
212,8,318,4,0,
212,8,332,4,0,
212,8,334,1,37,
212,8,355,4,0,
212,8,363,4,0,
212,8,369,4,0,
212,8,374,4,0,
212,8,400,1,45,
212,8,404,1,41,
212,8,404,4,0,
212,8,416,4,0,
212,8,430,4,0,
212,8,432,4,0,
212,8,442,1,53,
212,8,445,4,0,
212,8,458,1,49,
212,9,13,1,33,
212,9,14,1,57,
212,9,14,4,0,
212,9,15,4,0,
212,9,43,1,1,3
212,9,63,4,0,
212,9,70,4,0,
212,9,92,4,0,
212,9,97,1,17,
212,9,98,1,1,2
212,9,104,4,0,
212,9,116,1,5,
212,9,129,3,0,
212,9,156,4,0,
212,9,163,1,29,
212,9,164,4,0,
212,9,168,4,0,
212,9,173,3,0,
212,9,182,4,0,
212,9,201,4,0,
212,9,203,4,0,
212,9,206,1,13,
212,9,206,4,0,
212,9,207,4,0,
212,9,210,1,25,
212,9,210,3,0,
212,9,211,4,0,
212,9,213,4,0,
212,9,214,4,0,
212,9,216,4,0,
212,9,218,4,0,
212,9,228,1,9,
212,9,232,1,21,
212,9,237,4,0,
212,9,240,4,0,
212,9,241,4,0,
212,9,249,4,0,
212,9,263,4,0,
212,9,276,3,0,
212,9,280,4,0,
212,9,282,3,0,
212,9,290,4,0,
212,9,318,4,0,
212,9,332,4,0,
212,9,334,1,37,
212,9,334,3,0,
212,9,355,4,0,
212,9,363,4,0,
212,9,364,1,61,
212,9,369,4,0,
212,9,374,4,0,
212,9,400,1,45,
212,9,404,1,41,
212,9,404,4,0,
212,9,416,4,0,
212,9,418,1,1,1
212,9,430,4,0,
212,9,432,4,0,
212,9,442,1,53,
212,9,442,3,0,
212,9,445,4,0,
212,9,458,1,49,
212,9,466,3,0,
212,10,13,1,33,
212,10,14,1,57,
212,10,14,4,0,
212,10,15,4,0,
212,10,29,3,0,
212,10,43,1,1,3
212,10,63,4,0,
212,10,70,4,0,
212,10,92,4,0,
212,10,97,1,17,
212,10,98,1,1,2
212,10,104,4,0,
212,10,116,1,5,
212,10,129,3,0,
212,10,156,4,0,
212,10,163,1,29,
212,10,164,4,0,
212,10,168,4,0,
212,10,173,3,0,
212,10,182,4,0,
212,10,201,4,0,
212,10,203,4,0,
212,10,206,1,13,
212,10,206,4,0,
212,10,207,4,0,
212,10,210,1,25,
212,10,210,3,0,
212,10,211,4,0,
212,10,213,4,0,
212,10,214,4,0,
212,10,216,4,0,
212,10,218,4,0,
212,10,228,1,9,
212,10,232,1,21,
212,10,237,4,0,
212,10,240,4,0,
212,10,241,4,0,
212,10,249,4,0,
212,10,263,4,0,
212,10,276,3,0,
212,10,280,4,0,
212,10,282,3,0,
212,10,290,4,0,
212,10,318,4,0,
212,10,332,4,0,
212,10,334,1,37,
212,10,334,3,0,
212,10,355,4,0,
212,10,363,4,0,
212,10,364,1,61,
212,10,366,3,0,
212,10,369,4,0,
212,10,374,4,0,
212,10,400,1,45,
212,10,404,1,41,
212,10,404,4,0,
212,10,416,4,0,
212,10,418,1,1,1
212,10,430,4,0,
212,10,442,1,53,
212,10,442,3,0,
212,10,445,4,0,
212,10,450,3,0,
212,10,458,1,49,
212,10,466,3,0,
212,11,13,1,33,
212,11,14,1,57,
212,11,14,4,0,
212,11,15,4,0,
212,11,43,1,1,3
212,11,63,4,0,
212,11,70,4,0,
212,11,92,4,0,
212,11,97,1,17,
212,11,98,1,1,2
212,11,104,4,0,
212,11,113,4,0,
212,11,116,1,5,
212,11,156,4,0,
212,11,163,1,29,
212,11,164,4,0,
212,11,168,4,0,
212,11,182,4,0,
212,11,201,4,0,
212,11,206,1,13,
212,11,206,4,0,
212,11,207,4,0,
212,11,210,1,25,
212,11,213,4,0,
212,11,216,4,0,
212,11,218,4,0,
212,11,219,4,0,
212,11,228,1,9,
212,11,232,1,21,
212,11,237,4,0,
212,11,240,4,0,
212,11,241,4,0,
212,11,249,4,0,
212,11,263,4,0,
212,11,280,4,0,
212,11,332,4,0,
212,11,334,1,37,
212,11,364,1,61,
212,11,369,4,0,
212,11,374,4,0,
212,11,400,1,45,
212,11,404,1,41,
212,11,404,4,0,
212,11,416,4,0,
212,11,418,1,1,1
212,11,430,4,0,
212,11,442,1,53,
212,11,458,1,49,
212,11,474,4,0,
212,11,496,4,0,
212,11,512,4,0,
212,11,522,4,0,
212,12,14,1,36,
212,12,15,4,0,
212,12,43,1,1,2
212,12,63,4,0,
212,12,70,4,0,
212,12,92,4,0,
212,12,97,1,21,
212,12,98,1,1,1
212,12,104,1,41,
212,12,104,4,0,
212,12,116,1,6,
212,12,156,4,0,
212,12,163,1,31,
212,12,168,4,0,
212,12,182,4,0,
212,12,201,4,0,
212,12,206,1,16,
212,12,210,1,46,
212,12,211,4,0,
212,12,213,4,0,
212,12,216,4,0,
212,12,218,4,0,
212,12,228,1,11,
212,12,232,1,26,
212,12,237,4,0,
212,12,240,4,0,
212,12,241,4,0,
212,12,249,4,0,
212,12,263,4,0,
212,12,290,4,0,
212,12,332,4,0,
212,13,14,1,36,
212,13,15,4,0,
212,13,38,3,0,
212,13,43,1,1,2
212,13,63,4,0,
212,13,70,4,0,
212,13,92,4,0,
212,13,97,1,21,
212,13,98,1,1,1
212,13,102,3,0,
212,13,104,1,41,
212,13,104,4,0,
212,13,116,1,6,
212,13,156,4,0,
212,13,163,1,31,
212,13,164,3,0,
212,13,168,4,0,
212,13,182,4,0,
212,13,201,4,0,
212,13,206,1,16,
212,13,207,3,0,
212,13,210,1,46,
212,13,211,4,0,
212,13,213,4,0,
212,13,216,4,0,
212,13,218,4,0,
212,13,228,1,11,
212,13,232,1,26,
212,13,237,4,0,
212,13,240,4,0,
212,13,241,4,0,
212,13,249,4,0,
212,13,263,4,0,
212,13,290,4,0,
212,13,332,4,0,
212,14,13,1,33,
212,14,14,1,57,
212,14,14,4,0,
212,14,15,4,0,
212,14,43,1,1,3
212,14,63,4,0,
212,14,70,4,0,
212,14,92,4,0,
212,14,97,1,17,
212,14,98,1,1,2
212,14,104,4,0,
212,14,113,4,0,
212,14,116,1,5,
212,14,156,4,0,
212,14,163,1,29,
212,14,164,4,0,
212,14,168,4,0,
212,14,173,3,0,
212,14,182,4,0,
212,14,201,4,0,
212,14,206,1,13,
212,14,206,4,0,
212,14,207,4,0,
212,14,210,1,25,
212,14,213,4,0,
212,14,214,3,0,
212,14,216,4,0,
212,14,218,4,0,
212,14,219,4,0,
212,14,228,1,9,
212,14,232,1,21,
212,14,237,4,0,
212,14,240,4,0,
212,14,241,4,0,
212,14,249,4,0,
212,14,263,4,0,
212,14,276,3,0,
212,14,280,4,0,
212,14,282,3,0,
212,14,332,4,0,
212,14,334,1,37,
212,14,334,3,0,
212,14,355,3,0,
212,14,364,1,61,
212,14,366,3,0,
212,14,369,4,0,
212,14,374,4,0,
212,14,400,1,45,
212,14,404,1,41,
212,14,404,4,0,
212,14,416,4,0,
212,14,418,1,1,1
212,14,430,4,0,
212,14,442,1,53,
212,14,442,3,0,
212,14,450,3,0,
212,14,458,1,49,
212,14,474,4,0,
212,14,496,4,0,
212,14,512,4,0,
212,14,522,4,0,
212,15,13,1,33,
212,15,14,1,57,
212,15,14,4,0,
212,15,15,4,0,
212,15,43,1,1,4
212,15,63,4,0,
212,15,70,4,0,
212,15,92,4,0,
212,15,97,1,17,
212,15,98,1,1,3
212,15,104,4,0,
212,15,113,4,0,
212,15,116,1,5,
212,15,156,4,0,
212,15,163,1,29,
212,15,164,4,0,
212,15,168,4,0,
212,15,182,4,0,
212,15,201,4,0,
212,15,206,1,13,
212,15,206,4,0,
212,15,207,4,0,
212,15,210,1,25,
212,15,211,4,0,
212,15,213,4,0,
212,15,214,4,0,
212,15,216,4,0,
212,15,218,4,0,
212,15,219,4,0,
212,15,228,1,9,
212,15,232,1,21,
212,15,237,4,0,
212,15,240,4,0,
212,15,241,4,0,
212,15,249,4,0,
212,15,263,4,0,
212,15,280,4,0,
212,15,332,4,0,
212,15,334,1,37,
212,15,355,4,0,
212,15,364,1,1,1
212,15,364,1,61,
212,15,369,4,0,
212,15,374,4,0,
212,15,400,1,45,
212,15,404,1,41,
212,15,404,4,0,
212,15,416,4,0,
212,15,418,1,1,2
212,15,430,4,0,
212,15,442,1,50,
212,15,458,1,49,
212,15,474,4,0,
212,15,496,4,0,
212,15,512,4,0,
212,15,522,4,0,
212,15,590,4,0,
212,16,13,1,33,1
212,16,14,1,57,1
212,16,14,4,0,
212,16,15,4,0,
212,16,43,1,1,4
212,16,63,4,0,
212,16,70,4,0,
212,16,92,4,0,
212,16,97,1,17,1
212,16,98,1,1,3
212,16,104,4,0,
212,16,113,4,0,
212,16,116,1,5,1
212,16,156,4,0,
212,16,163,1,29,1
212,16,164,4,0,
212,16,168,4,0,
212,16,173,3,0,
212,16,182,4,0,
212,16,201,4,0,
212,16,206,1,13,1
212,16,206,4,0,
212,16,207,4,0,
212,16,210,1,25,1
212,16,211,4,0,
212,16,213,4,0,
212,16,214,4,0,
212,16,216,4,0,
212,16,218,4,0,
212,16,219,4,0,
212,16,228,1,9,1
212,16,232,1,21,1
212,16,237,4,0,
212,16,240,4,0,
212,16,241,4,0,
212,16,249,4,0,
212,16,263,4,0,
212,16,276,3,0,
212,16,280,4,0,
212,16,282,3,0,
212,16,290,4,0,
212,16,332,4,0,
212,16,334,1,37,1
212,16,334,3,0,
212,16,355,4,0,
212,16,364,1,1,1
212,16,364,1,61,1
212,16,366,3,0,
212,16,369,4,0,
212,16,374,4,0,
212,16,400,1,45,1
212,16,404,1,41,1
212,16,404,4,0,
212,16,416,4,0,
212,16,418,1,1,2
212,16,430,4,0,
212,16,442,1,50,1
212,16,442,3,0,
212,16,450,3,0,
212,16,458,1,49,1
212,16,474,4,0,
212,16,496,4,0,
212,16,512,4,0,
212,16,522,4,0,
212,16,590,4,0,
212,17,13,1,33,
212,17,14,1,57,
212,17,14,4,0,
212,17,43,1,1,4
212,17,63,4,0,
212,17,92,4,0,
212,17,97,1,17,
212,17,98,1,1,3
212,17,104,4,0,
212,17,113,4,0,
212,17,116,1,5,
212,17,156,4,0,
212,17,163,1,29,
212,17,164,4,0,
212,17,168,4,0,
212,17,182,4,0,
212,17,201,4,0,
212,17,206,1,13,
212,17,206,4,0,
212,17,207,4,0,
212,17,210,1,25,
212,17,211,4,0,
212,17,213,4,0,
212,17,214,4,0,
212,17,216,4,0,
212,17,218,4,0,
212,17,219,4,0,
212,17,228,1,9,
212,17,232,1,21,
212,17,237,4,0,
212,17,240,4,0,
212,17,241,4,0,
212,17,263,4,0,
212,17,280,4,0,
212,17,332,4,0,
212,17,334,1,37,
212,17,355,4,0,
212,17,364,1,1,1
212,17,364,1,61,
212,17,369,4,0,
212,17,374,4,0,
212,17,400,1,45,
212,17,404,1,41,
212,17,404,4,0,
212,17,416,4,0,
212,17,418,1,1,2
212,17,430,4,0,
212,17,442,1,50,
212,17,458,1,49,
212,17,474,4,0,
212,17,496,4,0,
212,17,512,4,0,
212,17,590,4,0,
212,17,693,4,0,
212,18,13,1,33,
212,18,14,1,57,
212,18,14,4,0,
212,18,43,1,1,4
212,18,63,4,0,
212,18,92,4,0,
212,18,97,1,17,
212,18,98,1,1,3
212,18,104,4,0,
212,18,113,4,0,
212,18,116,1,5,
212,18,156,4,0,
212,18,163,1,29,
212,18,164,4,0,
212,18,168,4,0,
212,18,182,4,0,
212,18,201,4,0,
212,18,206,1,13,
212,18,206,4,0,
212,18,207,4,0,
212,18,210,1,25,
212,18,211,4,0,
212,18,213,4,0,
212,18,214,4,0,
212,18,216,4,0,
212,18,218,4,0,
212,18,219,4,0,
212,18,228,1,9,
212,18,232,1,21,
212,18,237,4,0,
212,18,240,4,0,
212,18,241,4,0,
212,18,263,4,0,
212,18,280,4,0,
212,18,332,4,0,
212,18,334,1,37,
212,18,355,4,0,
212,18,364,1,1,1
212,18,364,1,61,
212,18,369,4,0,
212,18,374,4,0,
212,18,400,1,45,
212,18,404,1,41,
212,18,404,4,0,
212,18,416,4,0,
212,18,418,1,1,2
212,18,430,4,0,
212,18,442,1,50,
212,18,458,1,49,
212,18,474,4,0,
212,18,496,4,0,
212,18,512,4,0,
212,18,590,4,0,
212,18,693,4,0,
213,3,29,4,0,
213,3,35,1,9,
213,3,70,4,0,
213,3,89,4,0,
213,3,91,4,0,
213,3,92,4,0,
213,3,104,4,0,
213,3,110,1,1,2
213,3,111,4,0,
213,3,117,1,28,
213,3,132,1,1,1
213,3,148,4,0,
213,3,156,1,37,
213,3,156,4,0,
213,3,173,4,0,
213,3,174,4,0,
213,3,182,4,0,
213,3,188,4,0,
213,3,189,4,0,
213,3,201,4,0,
213,3,203,4,0,
213,3,205,4,0,
213,3,207,4,0,
213,3,213,4,0,
213,3,214,4,0,
213,3,216,4,0,
213,3,218,4,0,
213,3,219,1,23,
213,3,227,1,14,
213,3,230,2,0,
213,3,237,4,0,
213,3,241,4,0,
213,3,249,4,0,
213,4,29,4,0,
213,4,35,1,9,
213,4,70,4,0,
213,4,89,4,0,
213,4,91,4,0,
213,4,92,4,0,
213,4,104,4,0,
213,4,110,1,1,2
213,4,111,4,0,
213,4,117,1,28,
213,4,132,1,1,1
213,4,148,4,0,
213,4,156,1,37,
213,4,156,4,0,
213,4,173,4,0,
213,4,174,4,0,
213,4,182,4,0,
213,4,188,4,0,
213,4,189,4,0,
213,4,201,4,0,
213,4,203,4,0,
213,4,205,4,0,
213,4,207,4,0,
213,4,213,4,0,
213,4,214,4,0,
213,4,216,4,0,
213,4,218,4,0,
213,4,219,1,23,
213,4,227,1,14,
213,4,230,2,0,
213,4,237,4,0,
213,4,241,4,0,
213,4,249,4,0,
213,5,35,1,9,
213,5,70,4,0,
213,5,89,4,0,
213,5,91,4,0,
213,5,92,4,0,
213,5,104,4,0,
213,5,110,1,1,2
213,5,117,1,28,
213,5,132,1,1,1
213,5,148,4,0,
213,5,156,1,37,
213,5,156,4,0,
213,5,182,4,0,
213,5,188,4,0,
213,5,201,4,0,
213,5,213,4,0,
213,5,216,4,0,
213,5,218,4,0,
213,5,219,1,23,
213,5,219,4,0,
213,5,227,1,14,
213,5,230,2,0,
213,5,237,4,0,
213,5,241,4,0,
213,5,249,4,0,
213,5,263,4,0,
213,5,290,4,0,
213,5,317,4,0,
213,6,34,3,0,
213,6,35,1,9,
213,6,38,3,0,
213,6,70,4,0,
213,6,89,4,0,
213,6,91,4,0,
213,6,92,4,0,
213,6,102,3,0,
213,6,104,4,0,
213,6,110,1,1,2
213,6,111,3,0,
213,6,117,1,28,
213,6,132,1,1,1
213,6,148,4,0,
213,6,156,1,37,
213,6,156,4,0,
213,6,157,3,0,
213,6,164,3,0,
213,6,173,3,0,
213,6,182,4,0,
213,6,188,4,0,
213,6,189,3,0,
213,6,201,4,0,
213,6,203,3,0,
213,6,205,3,0,
213,6,207,3,0,
213,6,213,4,0,
213,6,214,3,0,
213,6,216,4,0,
213,6,218,4,0,
213,6,219,1,23,
213,6,219,4,0,
213,6,227,1,14,
213,6,230,2,0,
213,6,237,4,0,
213,6,241,4,0,
213,6,249,4,0,
213,6,263,4,0,
213,6,290,4,0,
213,6,317,4,0,
213,7,34,3,0,
213,7,35,1,9,
213,7,38,3,0,
213,7,70,4,0,
213,7,89,4,0,
213,7,91,4,0,
213,7,92,4,0,
213,7,102,3,0,
213,7,104,4,0,
213,7,110,1,1,2
213,7,117,1,28,
213,7,132,1,1,1
213,7,148,4,0,
213,7,156,1,37,
213,7,156,4,0,
213,7,157,3,0,
213,7,164,3,0,
213,7,182,4,0,
213,7,188,4,0,
213,7,201,4,0,
213,7,213,4,0,
213,7,216,4,0,
213,7,218,4,0,
213,7,219,1,23,
213,7,219,4,0,
213,7,227,1,14,
213,7,230,2,0,
213,7,237,4,0,
213,7,241,4,0,
213,7,249,4,0,
213,7,263,4,0,
213,7,290,4,0,
213,7,317,4,0,
213,8,35,1,27,
213,8,70,4,0,
213,8,89,4,0,
213,8,91,4,0,
213,8,92,4,0,
213,8,104,4,0,
213,8,110,1,1,1
213,8,117,1,9,
213,8,132,1,1,2
213,8,148,4,0,
213,8,156,1,35,
213,8,156,4,0,
213,8,157,4,0,
213,8,164,4,0,
213,8,182,4,0,
213,8,188,4,0,
213,8,189,2,0,
213,8,201,4,0,
213,8,203,4,0,
213,8,207,4,0,
213,8,213,4,0,
213,8,214,4,0,
213,8,216,4,0,
213,8,218,4,0,
213,8,219,1,22,
213,8,219,4,0,
213,8,227,1,14,
213,8,230,2,0,
213,8,237,4,0,
213,8,241,4,0,
213,8,249,4,0,
213,8,263,4,0,
213,8,270,2,0,
213,8,282,2,0,
213,8,290,4,0,
213,8,317,4,0,
213,8,328,2,0,
213,8,360,4,0,
213,8,363,4,0,
213,8,367,2,0,
213,8,379,1,48,
213,8,380,1,40,
213,8,397,4,0,
213,8,444,4,0,
213,8,445,4,0,
213,8,446,4,0,
213,9,35,1,22,
213,9,70,4,0,
213,9,89,4,0,
213,9,91,4,0,
213,9,92,4,0,
213,9,104,4,0,
213,9,110,1,1,1
213,9,117,1,1,3
213,9,132,1,1,2
213,9,148,4,0,
213,9,156,1,27,
213,9,156,4,0,
213,9,157,4,0,
213,9,164,4,0,
213,9,173,3,0,
213,9,182,4,0,
213,9,188,4,0,
213,9,189,2,0,
213,9,189,3,0,
213,9,201,4,0,
213,9,203,4,0,
213,9,205,3,0,
213,9,207,4,0,
213,9,213,4,0,
213,9,214,4,0,
213,9,216,4,0,
213,9,218,4,0,
213,9,219,1,14,
213,9,219,4,0,
213,9,227,1,9,
213,9,230,2,0,
213,9,237,4,0,
213,9,241,4,0,
213,9,246,3,0,
213,9,249,4,0,
213,9,263,4,0,
213,9,270,2,0,
213,9,270,3,0,
213,9,282,2,0,
213,9,290,4,0,
213,9,317,4,0,
213,9,328,2,0,
213,9,360,4,0,
213,9,363,4,0,
213,9,367,2,0,
213,9,379,1,48,
213,9,380,1,35,
213,9,380,3,0,
213,9,397,4,0,
213,9,414,3,0,
213,9,444,4,0,
213,9,445,4,0,
213,9,446,4,0,
213,9,450,1,40,
213,10,29,3,0,
213,10,35,1,22,
213,10,70,4,0,
213,10,81,3,0,
213,10,89,4,0,
213,10,91,4,0,
213,10,92,4,0,
213,10,104,4,0,
213,10,110,1,1,1
213,10,117,1,1,3
213,10,132,1,1,2
213,10,148,4,0,
213,10,156,1,27,
213,10,156,4,0,
213,10,157,4,0,
213,10,164,4,0,
213,10,173,3,0,
213,10,182,4,0,
213,10,188,4,0,
213,10,189,2,0,
213,10,189,3,0,
213,10,201,4,0,
213,10,203,4,0,
213,10,205,3,0,
213,10,207,4,0,
213,10,213,4,0,
213,10,214,4,0,
213,10,216,4,0,
213,10,218,4,0,
213,10,219,1,14,
213,10,219,4,0,
213,10,227,1,9,
213,10,230,2,0,
213,10,237,4,0,
213,10,241,4,0,
213,10,246,3,0,
213,10,249,4,0,
213,10,263,4,0,
213,10,270,2,0,
213,10,270,3,0,
213,10,282,2,0,
213,10,290,4,0,
213,10,317,4,0,
213,10,328,2,0,
213,10,360,4,0,
213,10,363,4,0,
213,10,367,2,0,
213,10,379,1,48,
213,10,380,1,35,
213,10,380,3,0,
213,10,397,4,0,
213,10,414,3,0,
213,10,444,4,0,
213,10,445,4,0,
213,10,446,4,0,
213,10,450,1,40,
213,10,450,3,0,
213,11,35,1,13,
213,11,51,2,0,
213,11,70,4,0,
213,11,89,4,0,
213,11,91,4,0,
213,11,92,4,0,
213,11,104,4,0,
213,11,110,1,1,1
213,11,117,1,1,3
213,11,132,1,1,2
213,11,148,4,0,
213,11,156,1,25,
213,11,156,4,0,
213,11,157,4,0,
213,11,164,4,0,
213,11,182,4,0,
213,11,188,4,0,
213,11,189,2,0,
213,11,201,4,0,
213,11,205,1,37,
213,11,207,4,0,
213,11,213,4,0,
213,11,216,4,0,
213,11,218,4,0,
213,11,219,1,19,
213,11,219,4,0,
213,11,227,1,7,
213,11,230,2,0,
213,11,237,4,0,
213,11,241,4,0,
213,11,249,4,0,
213,11,263,4,0,
213,11,270,2,0,
213,11,282,2,0,
213,11,317,4,0,
213,11,328,2,0,
213,11,350,2,0,
213,11,360,4,0,
213,11,367,2,0,
213,11,379,1,43,
213,11,380,1,31,
213,11,397,4,0,
213,11,444,4,0,
213,11,450,1,49,
213,11,470,1,55,2
213,11,471,1,55,1
213,11,474,4,0,
213,11,479,4,0,
213,11,482,4,0,
213,11,496,4,0,
213,11,515,2,0,
213,11,522,1,1,4
213,11,522,4,0,
213,11,523,4,0,
213,12,35,1,9,
213,12,70,4,0,
213,12,89,4,0,
213,12,91,4,0,
213,12,92,4,0,
213,12,104,4,0,
213,12,110,1,1,2
213,12,117,1,28,
213,12,132,1,1,1
213,12,148,4,0,
213,12,156,1,37,
213,12,156,4,0,
213,12,182,4,0,
213,12,188,4,0,
213,12,201,4,0,
213,12,213,4,0,
213,12,216,4,0,
213,12,218,4,0,
213,12,219,1,23,
213,12,219,4,0,
213,12,227,1,14,
213,12,237,4,0,
213,12,241,4,0,
213,12,249,4,0,
213,12,263,4,0,
213,12,290,4,0,
213,12,317,4,0,
213,13,34,3,0,
213,13,35,1,9,
213,13,38,3,0,
213,13,70,4,0,
213,13,89,4,0,
213,13,91,4,0,
213,13,92,4,0,
213,13,102,3,0,
213,13,104,4,0,
213,13,110,1,1,2
213,13,117,1,28,
213,13,132,1,1,1
213,13,148,4,0,
213,13,156,1,37,
213,13,156,4,0,
213,13,164,3,0,
213,13,182,4,0,
213,13,188,4,0,
213,13,201,4,0,
213,13,207,3,0,
213,13,213,4,0,
213,13,216,4,0,
213,13,218,4,0,
213,13,219,1,23,
213,13,219,4,0,
213,13,227,1,14,
213,13,237,4,0,
213,13,241,4,0,
213,13,249,4,0,
213,13,263,4,0,
213,13,290,4,0,
213,13,317,4,0,
213,14,20,3,0,
213,14,35,1,9,
213,14,51,2,0,
213,14,70,4,0,
213,14,88,1,23,
213,14,89,4,0,
213,14,91,4,0,
213,14,92,4,0,
213,14,104,4,0,
213,14,110,1,1,1
213,14,117,1,1,3
213,14,132,1,1,2
213,14,148,4,0,
213,14,156,1,20,
213,14,156,4,0,
213,14,157,1,38,
213,14,157,4,0,
213,14,164,4,0,
213,14,173,3,0,
213,14,182,4,0,
213,14,188,4,0,
213,14,189,2,0,
213,14,201,4,0,
213,14,205,1,1,4
213,14,207,4,0,
213,14,213,4,0,
213,14,214,3,0,
213,14,216,4,0,
213,14,218,4,0,
213,14,219,1,16,
213,14,219,4,0,
213,14,227,1,5,
213,14,230,2,0,
213,14,237,4,0,
213,14,241,4,0,
213,14,249,4,0,
213,14,263,4,0,
213,14,270,2,0,
213,14,270,3,0,
213,14,282,2,0,
213,14,282,3,0,
213,14,317,4,0,
213,14,328,2,0,
213,14,350,2,0,
213,14,360,4,0,
213,14,367,2,0,
213,14,379,1,31,
213,14,380,1,27,
213,14,380,3,0,
213,14,397,4,0,
213,14,414,3,0,
213,14,444,1,49,
213,14,444,4,0,
213,14,446,3,0,
213,14,450,1,42,
213,14,450,3,0,
213,14,470,1,45,2
213,14,471,1,45,1
213,14,474,4,0,
213,14,479,4,0,
213,14,482,4,0,
213,14,495,3,0,
213,14,496,4,0,
213,14,504,1,34,
213,14,515,2,0,
213,14,522,1,12,
213,14,522,4,0,
213,14,523,4,0,
213,15,35,1,9,
213,15,51,2,0,
213,15,70,4,0,
213,15,88,1,23,
213,15,89,4,0,
213,15,91,4,0,
213,15,92,4,0,
213,15,104,4,0,
213,15,110,1,1,2
213,15,117,1,1,4
213,15,132,1,1,3
213,15,148,4,0,
213,15,156,1,20,
213,15,156,4,0,
213,15,157,1,38,
213,15,157,4,0,
213,15,164,4,0,
213,15,182,4,0,
213,15,188,4,0,
213,15,189,2,0,
213,15,201,4,0,
213,15,205,1,1,5
213,15,207,4,0,
213,15,213,4,0,
213,15,214,4,0,
213,15,216,4,0,
213,15,218,4,0,
213,15,219,1,16,
213,15,219,4,0,
213,15,227,1,5,
213,15,230,2,0,
213,15,237,4,0,
213,15,241,4,0,
213,15,249,4,0,
213,15,263,4,0,
213,15,270,2,0,
213,15,282,2,0,
213,15,317,4,0,
213,15,328,2,0,
213,15,350,2,0,
213,15,360,4,0,
213,15,367,2,0,
213,15,379,1,31,
213,15,380,1,27,
213,15,397,4,0,
213,15,444,1,49,
213,15,444,4,0,
213,15,450,1,42,
213,15,470,1,45,2
213,15,471,1,45,1
213,15,474,4,0,
213,15,479,4,0,
213,15,482,4,0,
213,15,496,4,0,
213,15,504,1,34,
213,15,515,2,0,
213,15,522,1,12,
213,15,522,4,0,
213,15,523,4,0,
213,15,564,1,1,1
213,15,564,1,53,
213,15,590,4,0,
213,15,611,4,0,
213,16,20,3,0,
213,16,35,1,9,1
213,16,51,2,0,
213,16,70,4,0,
213,16,88,1,23,1
213,16,89,4,0,
213,16,91,4,0,
213,16,92,4,0,
213,16,104,4,0,
213,16,110,1,1,2
213,16,117,1,1,4
213,16,132,1,1,3
213,16,148,4,0,
213,16,156,1,20,1
213,16,156,4,0,
213,16,157,1,38,1
213,16,157,4,0,
213,16,164,4,0,
213,16,173,3,0,
213,16,182,4,0,
213,16,188,4,0,
213,16,189,2,0,
213,16,201,4,0,
213,16,205,1,1,5
213,16,207,4,0,
213,16,213,4,0,
213,16,214,4,0,
213,16,216,4,0,
213,16,218,4,0,
213,16,219,1,16,1
213,16,219,4,0,
213,16,227,1,5,1
213,16,230,2,0,
213,16,237,4,0,
213,16,241,4,0,
213,16,249,4,0,
213,16,263,4,0,
213,16,270,2,0,
213,16,270,3,0,
213,16,282,2,0,
213,16,282,3,0,
213,16,290,4,0,
213,16,317,4,0,
213,16,328,2,0,
213,16,350,2,0,
213,16,360,4,0,
213,16,367,2,0,
213,16,379,1,31,1
213,16,380,1,27,1
213,16,380,3,0,
213,16,397,4,0,
213,16,414,3,0,
213,16,444,1,49,1
213,16,444,4,0,
213,16,446,3,0,
213,16,450,1,42,1
213,16,450,3,0,
213,16,470,1,45,2
213,16,471,1,45,1
213,16,474,4,0,
213,16,479,4,0,
213,16,482,4,0,
213,16,495,3,0,
213,16,496,4,0,
213,16,504,1,34,1
213,16,515,2,0,
213,16,522,1,12,1
213,16,522,4,0,
213,16,523,4,0,
213,16,564,1,1,1
213,16,564,1,53,1
213,16,590,4,0,
213,16,611,4,0,
213,17,35,1,9,
213,17,51,2,0,
213,17,88,1,23,
213,17,89,4,0,
213,17,92,4,0,
213,17,104,4,0,
213,17,110,1,1,2
213,17,117,1,1,4
213,17,132,1,1,3
213,17,156,1,20,
213,17,156,4,0,
213,17,157,1,38,
213,17,157,4,0,
213,17,164,4,0,
213,17,182,4,0,
213,17,188,4,0,
213,17,189,2,0,
213,17,201,4,0,
213,17,205,1,1,5
213,17,207,4,0,
213,17,213,4,0,
213,17,214,4,0,
213,17,216,4,0,
213,17,218,4,0,
213,17,219,1,16,
213,17,219,4,0,
213,17,227,1,5,
213,17,230,2,0,
213,17,237,4,0,
213,17,241,4,0,
213,17,263,4,0,
213,17,270,2,0,
213,17,282,2,0,
213,17,317,4,0,
213,17,328,2,0,
213,17,350,2,0,
213,17,360,4,0,
213,17,367,2,0,
213,17,379,1,31,
213,17,380,1,27,
213,17,397,4,0,
213,17,444,1,49,
213,17,444,4,0,
213,17,450,1,42,
213,17,470,1,45,2
213,17,471,1,45,1
213,17,474,4,0,
213,17,479,4,0,
213,17,482,4,0,
213,17,496,4,0,
213,17,504,1,34,
213,17,515,2,0,
213,17,522,1,12,
213,17,523,4,0,
213,17,564,1,1,1
213,17,564,1,53,
213,17,590,4,0,
213,17,611,4,0,
213,18,35,1,9,
213,18,51,2,0,
213,18,88,1,23,
213,18,89,4,0,
213,18,92,4,0,
213,18,104,4,0,
213,18,110,1,1,2
213,18,117,1,1,4
213,18,132,1,1,3
213,18,156,1,20,
213,18,156,4,0,
213,18,157,1,38,
213,18,157,4,0,
213,18,164,4,0,
213,18,182,4,0,
213,18,188,4,0,
213,18,189,2,0,
213,18,201,4,0,
213,18,205,1,1,5
213,18,207,4,0,
213,18,213,4,0,
213,18,214,4,0,
213,18,216,4,0,
213,18,218,4,0,
213,18,219,1,16,
213,18,219,4,0,
213,18,227,1,5,
213,18,230,2,0,
213,18,237,4,0,
213,18,241,4,0,
213,18,263,4,0,
213,18,270,2,0,
213,18,282,2,0,
213,18,317,4,0,
213,18,328,2,0,
213,18,350,2,0,
213,18,360,4,0,
213,18,367,2,0,
213,18,379,1,31,
213,18,380,1,27,
213,18,397,4,0,
213,18,444,1,49,
213,18,444,4,0,
213,18,450,1,42,
213,18,470,1,45,2
213,18,471,1,45,1
213,18,474,4,0,
213,18,479,4,0,
213,18,482,4,0,
213,18,496,4,0,
213,18,504,1,34,
213,18,515,2,0,
213,18,522,1,12,
213,18,523,4,0,
213,18,564,1,1,1
213,18,564,1,53,
213,18,590,4,0,
213,18,611,4,0,
214,3,15,4,0,
214,3,29,4,0,
214,3,30,1,6,
214,3,31,1,19,
214,3,33,1,1,1
214,3,36,1,35,
214,3,43,1,1,2
214,3,68,1,27,
214,3,70,4,0,
214,3,89,4,0,
214,3,92,4,0,
214,3,104,4,0,
214,3,106,2,0,
214,3,117,2,0,
214,3,156,4,0,
214,3,168,4,0,
214,3,173,4,0,
214,3,174,4,0,
214,3,175,2,0,
214,3,179,1,44,
214,3,182,4,0,
214,3,197,4,0,
214,3,203,1,12,
214,3,203,4,0,
214,3,207,4,0,
214,3,210,4,0,
214,3,213,4,0,
214,3,214,4,0,
214,3,216,4,0,
214,3,218,4,0,
214,3,224,1,54,
214,3,237,4,0,
214,3,241,4,0,
214,3,249,4,0,
214,4,15,4,0,
214,4,29,4,0,
214,4,30,1,6,
214,4,31,1,19,
214,4,33,1,1,1
214,4,36,1,35,
214,4,43,1,1,2
214,4,68,1,27,
214,4,70,4,0,
214,4,89,4,0,
214,4,92,4,0,
214,4,104,4,0,
214,4,106,2,0,
214,4,117,2,0,
214,4,156,4,0,
214,4,168,4,0,
214,4,173,4,0,
214,4,174,4,0,
214,4,175,2,0,
214,4,179,1,44,
214,4,182,4,0,
214,4,197,4,0,
214,4,203,1,12,
214,4,203,4,0,
214,4,207,4,0,
214,4,210,4,0,
214,4,213,4,0,
214,4,214,4,0,
214,4,216,4,0,
214,4,218,4,0,
214,4,224,1,54,
214,4,237,4,0,
214,4,241,4,0,
214,4,249,4,0,
214,5,15,4,0,
214,5,30,1,6,
214,5,31,1,17,
214,5,33,1,1,1
214,5,36,1,37,
214,5,43,1,1,2
214,5,63,4,0,
214,5,68,1,30,
214,5,70,4,0,
214,5,89,4,0,
214,5,91,4,0,
214,5,92,4,0,
214,5,104,4,0,
214,5,106,2,0,
214,5,117,2,0,
214,5,156,4,0,
214,5,168,4,0,
214,5,175,2,0,
214,5,179,1,45,
214,5,182,4,0,
214,5,203,1,11,
214,5,206,2,0,
214,5,213,4,0,
214,5,216,4,0,
214,5,218,4,0,
214,5,224,1,53,
214,5,237,4,0,
214,5,240,4,0,
214,5,241,4,0,
214,5,249,4,0,
214,5,263,4,0,
214,5,264,4,0,
214,5,280,1,23,
214,5,280,4,0,
214,5,290,4,0,
214,5,317,4,0,
214,5,339,4,0,
214,6,14,3,0,
214,6,15,4,0,
214,6,30,1,6,
214,6,31,1,17,
214,6,33,1,1,1
214,6,34,3,0,
214,6,36,1,37,
214,6,38,3,0,
214,6,43,1,1,2
214,6,63,4,0,
214,6,68,1,30,
214,6,68,3,0,
214,6,69,3,0,
214,6,70,4,0,
214,6,89,4,0,
214,6,91,4,0,
214,6,92,4,0,
214,6,102,3,0,
214,6,104,4,0,
214,6,106,2,0,
214,6,117,2,0,
214,6,156,4,0,
214,6,157,3,0,
214,6,164,3,0,
214,6,168,4,0,
214,6,173,3,0,
214,6,175,2,0,
214,6,179,1,45,
214,6,182,4,0,
214,6,203,1,11,
214,6,203,3,0,
214,6,206,2,0,
214,6,207,3,0,
214,6,210,3,0,
214,6,213,4,0,
214,6,214,3,0,
214,6,216,4,0,
214,6,218,4,0,
214,6,224,1,53,
214,6,237,4,0,
214,6,240,4,0,
214,6,241,4,0,
214,6,249,4,0,
214,6,263,4,0,
214,6,264,4,0,
214,6,280,1,23,
214,6,280,4,0,
214,6,290,4,0,
214,6,317,4,0,
214,6,339,4,0,
214,7,14,3,0,
214,7,15,4,0,
214,7,30,1,6,
214,7,31,1,17,
214,7,33,1,1,1
214,7,34,3,0,
214,7,36,1,37,
214,7,38,3,0,
214,7,43,1,1,2
214,7,63,4,0,
214,7,68,1,30,
214,7,68,3,0,
214,7,69,3,0,
214,7,70,4,0,
214,7,89,4,0,
214,7,91,4,0,
214,7,92,4,0,
214,7,102,3,0,
214,7,104,4,0,
214,7,106,2,0,
214,7,117,2,0,
214,7,156,4,0,
214,7,157,3,0,
214,7,164,3,0,
214,7,168,4,0,
214,7,175,2,0,
214,7,179,1,45,
214,7,182,4,0,
214,7,203,1,11,
214,7,206,2,0,
214,7,213,4,0,
214,7,216,4,0,
214,7,218,4,0,
214,7,224,1,53,
214,7,237,4,0,
214,7,240,4,0,
214,7,241,4,0,
214,7,249,4,0,
214,7,263,4,0,
214,7,264,4,0,
214,7,280,1,23,
214,7,280,4,0,
214,7,290,4,0,
214,7,317,4,0,
214,7,339,4,0,
214,8,14,4,0,
214,8,15,4,0,
214,8,30,1,1,4
214,8,31,1,7,
214,8,33,1,1,2
214,8,36,1,31,
214,8,38,2,0,
214,8,43,1,1,3
214,8,63,4,0,
214,8,68,1,25,
214,8,70,4,0,
214,8,89,4,0,
214,8,91,4,0,
214,8,92,4,0,
214,8,104,4,0,
214,8,106,2,0,
214,8,117,2,0,
214,8,156,4,0,
214,8,157,4,0,
214,8,164,4,0,
214,8,168,4,0,
214,8,175,2,0,
214,8,179,1,43,
214,8,182,4,0,
214,8,203,1,1,5
214,8,203,4,0,
214,8,206,2,0,
214,8,207,4,0,
214,8,213,4,0,
214,8,214,4,0,
214,8,216,4,0,
214,8,218,4,0,
214,8,224,1,55,
214,8,228,2,0,
214,8,237,4,0,
214,8,240,4,0,
214,8,241,4,0,
214,8,249,4,0,
214,8,263,4,0,
214,8,264,4,0,
214,8,279,2,0,
214,8,280,1,19,
214,8,280,4,0,
214,8,290,4,0,
214,8,317,4,0,
214,8,332,1,13,
214,8,332,4,0,
214,8,339,4,0,
214,8,363,4,0,
214,8,364,1,49,
214,8,370,1,37,
214,8,374,4,0,
214,8,400,1,1,1
214,8,411,4,0,
214,8,416,4,0,
214,8,421,4,0,
214,8,444,4,0,
214,8,445,4,0,
214,9,14,4,0,
214,9,15,4,0,
214,9,30,1,1,4
214,9,31,1,7,
214,9,33,1,1,2
214,9,36,1,31,
214,9,38,2,0,
214,9,43,1,1,3
214,9,63,4,0,
214,9,68,1,25,
214,9,70,4,0,
214,9,89,4,0,
214,9,91,4,0,
214,9,92,4,0,
214,9,104,4,0,
214,9,106,2,0,
214,9,117,2,0,
214,9,156,4,0,
214,9,157,4,0,
214,9,164,4,0,
214,9,168,4,0,
214,9,173,3,0,
214,9,175,2,0,
214,9,179,1,43,
214,9,182,4,0,
214,9,203,1,1,5
214,9,203,4,0,
214,9,206,2,0,
214,9,207,4,0,
214,9,210,3,0,
214,9,213,4,0,
214,9,214,4,0,
214,9,216,4,0,
214,9,218,4,0,
214,9,224,1,55,
214,9,228,2,0,
214,9,237,4,0,
214,9,240,4,0,
214,9,241,4,0,
214,9,249,4,0,
214,9,263,4,0,
214,9,264,4,0,
214,9,270,3,0,
214,9,279,2,0,
214,9,280,1,19,
214,9,280,4,0,
214,9,282,3,0,
214,9,290,4,0,
214,9,317,4,0,
214,9,332,1,13,
214,9,332,4,0,
214,9,334,3,0,
214,9,339,4,0,
214,9,363,4,0,
214,9,364,1,49,
214,9,370,1,37,
214,9,374,4,0,
214,9,400,1,1,1
214,9,410,3,0,
214,9,411,4,0,
214,9,416,4,0,
214,9,421,4,0,
214,9,444,4,0,
214,9,445,4,0,
214,10,14,4,0,
214,10,15,4,0,
214,10,29,3,0,
214,10,30,1,1,4
214,10,31,1,7,
214,10,33,1,1,2
214,10,36,1,31,
214,10,38,2,0,
214,10,43,1,1,3
214,10,63,4,0,
214,10,67,3,0,
214,10,68,1,25,
214,10,70,4,0,
214,10,89,4,0,
214,10,91,4,0,
214,10,92,4,0,
214,10,104,4,0,
214,10,106,2,0,
214,10,117,2,0,
214,10,156,4,0,
214,10,157,4,0,
214,10,164,4,0,
214,10,168,4,0,
214,10,173,3,0,
214,10,175,2,0,
214,10,179,1,43,
214,10,182,4,0,
214,10,203,1,1,5
214,10,203,4,0,
214,10,206,2,0,
214,10,207,4,0,
214,10,210,3,0,
214,10,213,4,0,
214,10,214,4,0,
214,10,216,4,0,
214,10,218,4,0,
214,10,224,1,55,
214,10,228,2,0,
214,10,237,4,0,
214,10,240,4,0,
214,10,241,4,0,
214,10,249,4,0,
214,10,263,4,0,
214,10,264,4,0,
214,10,270,3,0,
214,10,279,2,0,
214,10,280,1,19,
214,10,280,4,0,
214,10,282,3,0,
214,10,290,4,0,
214,10,317,4,0,
214,10,332,1,13,
214,10,332,4,0,
214,10,334,3,0,
214,10,339,4,0,
214,10,363,4,0,
214,10,364,1,49,
214,10,370,1,37,
214,10,374,4,0,
214,10,400,1,1,1
214,10,410,3,0,
214,10,411,4,0,
214,10,416,4,0,
214,10,421,4,0,
214,10,444,4,0,
214,10,445,4,0,
214,10,450,3,0,
214,11,14,4,0,
214,11,15,4,0,
214,11,30,1,1,4
214,11,31,1,7,
214,11,33,1,1,2
214,11,36,1,31,
214,11,38,2,0,
214,11,43,1,1,3
214,11,63,4,0,
214,11,68,1,25,
214,11,69,2,0,
214,11,70,4,0,
214,11,89,4,0,
214,11,91,4,0,
214,11,92,4,0,
214,11,104,4,0,
214,11,106,2,0,
214,11,117,2,0,
214,11,156,4,0,
214,11,157,4,0,
214,11,164,4,0,
214,11,168,4,0,
214,11,175,2,0,
214,11,179,1,43,
214,11,182,4,0,
214,11,203,1,1,5
214,11,206,4,0,
214,11,207,4,0,
214,11,213,4,0,
214,11,216,4,0,
214,11,218,4,0,
214,11,224,1,55,
214,11,224,2,0,
214,11,228,2,0,
214,11,237,4,0,
214,11,240,4,0,
214,11,241,4,0,
214,11,249,4,0,
214,11,263,4,0,
214,11,264,2,0,
214,11,279,2,0,
214,11,280,1,19,
214,11,280,4,0,
214,11,317,4,0,
214,11,332,1,13,
214,11,332,4,0,
214,11,364,1,49,
214,11,370,1,37,
214,11,374,4,0,
214,11,400,1,1,1
214,11,411,4,0,
214,11,416,4,0,
214,11,421,4,0,
214,11,444,4,0,
214,11,474,4,0,
214,11,479,4,0,
214,11,496,4,0,
214,11,514,4,0,
214,11,522,4,0,
214,11,523,4,0,
214,11,526,4,0,
214,12,15,4,0,
214,12,30,1,6,
214,12,31,1,17,
214,12,33,1,1,1
214,12,36,1,37,
214,12,43,1,1,2
214,12,63,4,0,
214,12,68,1,30,
214,12,70,4,0,
214,12,89,4,0,
214,12,91,4,0,
214,12,92,4,0,
214,12,104,4,0,
214,12,156,4,0,
214,12,168,4,0,
214,12,179,1,45,
214,12,182,4,0,
214,12,203,1,11,
214,12,213,4,0,
214,12,216,4,0,
214,12,218,4,0,
214,12,224,1,53,
214,12,237,4,0,
214,12,240,4,0,
214,12,241,4,0,
214,12,249,4,0,
214,12,263,4,0,
214,12,264,4,0,
214,12,280,1,23,
214,12,280,4,0,
214,12,290,4,0,
214,12,317,4,0,
214,12,339,4,0,
214,13,15,4,0,
214,13,30,1,6,
214,13,31,1,17,
214,13,33,1,1,1
214,13,34,3,0,
214,13,36,1,37,
214,13,38,3,0,
214,13,43,1,1,2
214,13,63,4,0,
214,13,68,1,30,
214,13,69,3,0,
214,13,70,4,0,
214,13,89,4,0,
214,13,91,4,0,
214,13,92,4,0,
214,13,102,3,0,
214,13,104,4,0,
214,13,156,4,0,
214,13,164,3,0,
214,13,168,4,0,
214,13,179,1,45,
214,13,182,4,0,
214,13,203,1,11,
214,13,207,3,0,
214,13,213,4,0,
214,13,216,4,0,
214,13,218,4,0,
214,13,224,1,53,
214,13,237,4,0,
214,13,240,4,0,
214,13,241,4,0,
214,13,249,4,0,
214,13,263,4,0,
214,13,264,4,0,
214,13,280,1,23,
214,13,280,4,0,
214,13,290,4,0,
214,13,317,4,0,
214,13,339,4,0,
214,14,14,4,0,
214,14,15,4,0,
214,14,30,1,1,4
214,14,31,1,7,
214,14,33,1,1,2
214,14,36,1,28,
214,14,38,2,0,
214,14,43,1,1,3
214,14,63,4,0,
214,14,67,3,0,
214,14,68,1,19,
214,14,69,2,0,
214,14,70,4,0,
214,14,89,4,0,
214,14,91,4,0,
214,14,92,4,0,
214,14,104,4,0,
214,14,106,2,0,
214,14,117,2,0,
214,14,156,4,0,
214,14,157,4,0,
214,14,164,4,0,
214,14,168,4,0,
214,14,173,3,0,
214,14,175,2,0,
214,14,179,1,43,
214,14,182,4,0,
214,14,203,1,1,5
214,14,206,4,0,
214,14,207,4,0,
214,14,213,4,0,
214,14,214,3,0,
214,14,216,4,0,
214,14,218,4,0,
214,14,224,1,46,
214,14,224,2,0,
214,14,228,2,0,
214,14,237,4,0,
214,14,240,4,0,
214,14,241,4,0,
214,14,249,4,0,
214,14,263,4,0,
214,14,264,2,0,
214,14,270,3,0,
214,14,279,2,0,
214,14,280,1,25,
214,14,280,4,0,
214,14,282,3,0,
214,14,317,4,0,
214,14,332,1,10,
214,14,332,4,0,
214,14,334,3,0,
214,14,339,4,0,
214,14,364,1,37,
214,14,370,1,34,
214,14,374,4,0,
214,14,400,1,1,1
214,14,411,4,0,
214,14,416,4,0,
214,14,421,4,0,
214,14,444,4,0,
214,14,450,3,0,
214,14,474,4,0,
214,14,479,4,0,
214,14,496,4,0,
214,14,498,1,16,
214,14,514,4,0,
214,14,522,4,0,
214,14,523,4,0,
214,14,526,4,0,
214,15,14,4,0,
214,15,15,4,0,
214,15,30,1,1,6
214,15,31,1,7,
214,15,33,1,1,4
214,15,36,1,28,
214,15,38,2,0,
214,15,42,1,31,
214,15,43,1,1,5
214,15,63,4,0,
214,15,68,1,19,
214,15,69,2,0,
214,15,70,4,0,
214,15,89,4,0,
214,15,91,4,0,
214,15,92,4,0,
214,15,104,4,0,
214,15,106,2,0,
214,15,117,2,0,
214,15,156,4,0,
214,15,157,4,0,
214,15,164,4,0,
214,15,168,4,0,
214,15,175,2,0,
214,15,179,1,43,
214,15,182,4,0,
214,15,203,1,1,7
214,15,206,4,0,
214,15,207,4,0,
214,15,213,4,0,
214,15,214,4,0,
214,15,216,4,0,
214,15,218,4,0,
214,15,224,1,46,
214,15,224,2,0,
214,15,228,2,0,
214,15,237,4,0,
214,15,240,4,0,
214,15,241,4,0,
214,15,249,4,0,
214,15,263,4,0,
214,15,264,2,0,
214,15,279,2,0,
214,15,280,1,25,
214,15,280,4,0,
214,15,292,1,1,1
214,15,317,4,0,
214,15,331,1,1,2
214,15,332,1,10,
214,15,332,4,0,
214,15,339,4,0,
214,15,350,2,0,
214,15,364,1,37,
214,15,370,1,34,
214,15,374,4,0,
214,15,400,1,1,3
214,15,411,4,0,
214,15,416,4,0,
214,15,421,4,0,
214,15,444,4,0,
214,15,474,4,0,
214,15,479,4,0,
214,15,496,4,0,
214,15,498,1,16,
214,15,514,4,0,
214,15,522,4,0,
214,15,523,4,0,
214,15,590,4,0,
214,16,14,4,0,
214,16,15,4,0,
214,16,30,1,1,6
214,16,31,1,25,1
214,16,33,1,1,4
214,16,36,1,34,1
214,16,38,2,0,
214,16,42,1,31,1
214,16,43,1,1,5
214,16,63,4,0,
214,16,67,3,0,
214,16,68,1,19,1
214,16,69,2,0,
214,16,70,4,0,
214,16,89,4,0,
214,16,91,4,0,
214,16,92,4,0,
214,16,104,4,0,
214,16,106,2,0,
214,16,117,2,0,
214,16,156,4,0,
214,16,157,4,0,
214,16,164,4,0,
214,16,168,4,0,
214,16,173,3,0,
214,16,175,2,0,
214,16,179,1,46,1
214,16,182,4,0,
214,16,203,1,1,7
214,16,206,4,0,
214,16,207,4,0,
214,16,213,4,0,
214,16,214,4,0,
214,16,216,4,0,
214,16,218,4,0,
214,16,224,1,37,1
214,16,224,2,0,
214,16,228,2,0,
214,16,237,4,0,
214,16,240,4,0,
214,16,241,4,0,
214,16,249,4,0,
214,16,263,4,0,
214,16,264,2,0,
214,16,264,3,0,
214,16,270,3,0,
214,16,279,2,0,
214,16,280,1,28,1
214,16,280,4,0,
214,16,282,3,0,
214,16,290,4,0,
214,16,292,1,1,1
214,16,317,4,0,
214,16,331,1,1,2
214,16,332,1,10,1
214,16,332,4,0,
214,16,334,3,0,
214,16,339,4,0,
214,16,350,2,0,
214,16,364,1,7,1
214,16,370,1,43,1
214,16,374,4,0,
214,16,400,1,1,3
214,16,411,4,0,
214,16,416,4,0,
214,16,421,4,0,
214,16,444,4,0,
214,16,450,3,0,
214,16,474,4,0,
214,16,479,4,0,
214,16,496,4,0,
214,16,498,1,16,1
214,16,514,4,0,
214,16,522,4,0,
214,16,523,4,0,
214,16,590,4,0,
214,17,14,4,0,
214,17,30,1,1,6
214,17,31,1,25,
214,17,33,1,1,4
214,17,36,1,34,
214,17,38,2,0,
214,17,42,1,31,
214,17,43,1,1,5
214,17,63,4,0,
214,17,68,1,19,
214,17,69,2,0,
214,17,89,4,0,
214,17,92,4,0,
214,17,104,4,0,
214,17,106,2,0,
214,17,117,2,0,
214,17,156,4,0,
214,17,157,4,0,
214,17,164,4,0,
214,17,168,4,0,
214,17,175,2,0,
214,17,179,1,46,
214,17,182,4,0,
214,17,203,1,1,7
214,17,206,4,0,
214,17,207,4,0,
214,17,213,4,0,
214,17,214,4,0,
214,17,216,4,0,
214,17,218,4,0,
214,17,224,1,37,
214,17,224,2,0,
214,17,228,2,0,
214,17,237,4,0,
214,17,240,4,0,
214,17,241,4,0,
214,17,263,4,0,
214,17,264,2,0,
214,17,279,2,0,
214,17,280,1,28,
214,17,280,4,0,
214,17,292,1,1,1
214,17,317,4,0,
214,17,331,1,1,2
214,17,332,1,10,
214,17,332,4,0,
214,17,339,4,0,
214,17,350,2,0,
214,17,364,1,7,
214,17,370,1,43,
214,17,374,4,0,
214,17,400,1,1,3
214,17,411,4,0,
214,17,416,4,0,
214,17,421,4,0,
214,17,444,4,0,
214,17,474,4,0,
214,17,479,4,0,
214,17,496,4,0,
214,17,498,1,16,
214,17,523,4,0,
214,17,526,4,0,
214,17,590,4,0,
214,17,693,4,0,
214,18,14,4,0,
214,18,30,1,1,6
214,18,31,1,25,
214,18,33,1,1,4
214,18,36,1,34,
214,18,38,2,0,
214,18,42,1,31,
214,18,43,1,1,5
214,18,63,4,0,
214,18,68,1,19,
214,18,69,2,0,
214,18,89,4,0,
214,18,92,4,0,
214,18,104,4,0,
214,18,106,2,0,
214,18,117,2,0,
214,18,156,4,0,
214,18,157,4,0,
214,18,164,4,0,
214,18,168,4,0,
214,18,175,2,0,
214,18,179,1,46,
214,18,182,4,0,
214,18,203,1,1,7
214,18,206,4,0,
214,18,207,4,0,
214,18,213,4,0,
214,18,214,4,0,
214,18,216,4,0,
214,18,218,4,0,
214,18,224,1,37,
214,18,224,2,0,
214,18,228,2,0,
214,18,237,4,0,
214,18,240,4,0,
214,18,241,4,0,
214,18,263,4,0,
214,18,264,2,0,
214,18,279,2,0,
214,18,280,1,28,
214,18,280,4,0,
214,18,292,1,1,1
214,18,317,4,0,
214,18,331,1,1,2
214,18,332,1,10,
214,18,332,4,0,
214,18,339,4,0,
214,18,350,2,0,
214,18,364,1,7,
214,18,370,1,43,
214,18,374,4,0,
214,18,400,1,1,3
214,18,411,4,0,
214,18,416,4,0,
214,18,421,4,0,
214,18,444,4,0,
214,18,474,4,0,
214,18,479,4,0,
214,18,496,4,0,
214,18,498,1,16,
214,18,523,4,0,
214,18,526,4,0,
214,18,590,4,0,
214,18,693,4,0,
215,3,8,4,0,
215,3,10,1,1,1
215,3,15,4,0,
215,3,29,4,0,
215,3,43,1,1,2
215,3,44,2,0,
215,3,57,4,0,
215,3,59,4,0,
215,3,68,2,0,
215,3,70,4,0,
215,3,91,4,0,
215,3,92,4,0,
215,3,97,1,41,
215,3,98,1,9,
215,3,103,1,17,
215,3,104,4,0,
215,3,111,4,0,
215,3,115,2,0,
215,3,129,4,0,
215,3,138,4,0,
215,3,154,1,33,
215,3,156,4,0,
215,3,163,1,49,
215,3,168,4,0,
215,3,171,4,0,
215,3,173,4,0,
215,3,174,4,0,
215,3,180,2,0,
215,3,182,4,0,
215,3,185,1,25,
215,3,189,4,0,
215,3,193,2,0,
215,3,196,4,0,
215,3,197,4,0,
215,3,203,4,0,
215,3,207,4,0,
215,3,210,4,0,
215,3,213,4,0,
215,3,214,4,0,
215,3,216,4,0,
215,3,218,4,0,
215,3,223,4,0,
215,3,231,4,0,
215,3,237,4,0,
215,3,240,4,0,
215,3,244,4,0,
215,3,247,4,0,
215,3,249,4,0,
215,3,251,1,57,
215,4,8,4,0,
215,4,10,1,1,1
215,4,15,4,0,
215,4,29,4,0,
215,4,43,1,1,2
215,4,44,2,0,
215,4,57,4,0,
215,4,58,3,0,
215,4,59,4,0,
215,4,68,2,0,
215,4,70,4,0,
215,4,91,4,0,
215,4,92,4,0,
215,4,97,1,41,
215,4,98,1,9,
215,4,103,1,17,
215,4,104,4,0,
215,4,111,4,0,
215,4,115,2,0,
215,4,129,4,0,
215,4,138,4,0,
215,4,154,1,33,
215,4,156,4,0,
215,4,163,1,49,
215,4,168,4,0,
215,4,171,4,0,
215,4,173,4,0,
215,4,174,4,0,
215,4,180,2,0,
215,4,182,4,0,
215,4,185,1,25,
215,4,189,4,0,
215,4,193,2,0,
215,4,196,4,0,
215,4,197,4,0,
215,4,203,4,0,
215,4,207,4,0,
215,4,210,4,0,
215,4,213,4,0,
215,4,214,4,0,
215,4,216,4,0,
215,4,218,4,0,
215,4,223,4,0,
215,4,231,4,0,
215,4,232,1,65,
215,4,237,4,0,
215,4,240,4,0,
215,4,244,4,0,
215,4,247,4,0,
215,4,249,4,0,
215,4,251,1,57,
215,5,10,1,1,1
215,5,15,4,0,
215,5,43,1,1,2
215,5,44,2,0,
215,5,57,4,0,
215,5,58,4,0,
215,5,59,4,0,
215,5,68,2,0,
215,5,70,4,0,
215,5,91,4,0,
215,5,92,4,0,
215,5,97,1,36,
215,5,98,1,8,
215,5,103,1,15,
215,5,104,4,0,
215,5,115,2,0,
215,5,154,1,29,
215,5,156,4,0,
215,5,163,1,50,
215,5,168,4,0,
215,5,180,2,0,
215,5,182,4,0,
215,5,185,1,22,
215,5,193,2,0,
215,5,196,1,43,
215,5,213,4,0,
215,5,216,4,0,
215,5,218,4,0,
215,5,231,4,0,
215,5,232,1,64,
215,5,237,4,0,
215,5,240,4,0,
215,5,241,4,0,
215,5,247,4,0,
215,5,249,4,0,
215,5,251,1,57,
215,5,252,2,0,
215,5,258,4,0,
215,5,259,4,0,
215,5,263,4,0,
215,5,264,4,0,
215,5,269,1,1,3
215,5,269,4,0,
215,5,280,4,0,
215,5,289,4,0,
215,5,290,4,0,
215,5,306,2,0,
215,5,332,4,0,
215,5,347,4,0,
215,6,8,3,0,
215,6,10,1,1,1
215,6,14,3,0,
215,6,15,4,0,
215,6,38,3,0,
215,6,43,1,1,2
215,6,44,2,0,
215,6,57,4,0,
215,6,58,4,0,
215,6,59,4,0,
215,6,68,2,0,
215,6,68,3,0,
215,6,70,4,0,
215,6,91,4,0,
215,6,92,4,0,
215,6,97,1,36,
215,6,98,1,8,
215,6,102,3,0,
215,6,103,1,15,
215,6,104,4,0,
215,6,111,3,0,
215,6,115,2,0,
215,6,129,3,0,
215,6,138,3,0,
215,6,154,1,29,
215,6,156,4,0,
215,6,163,1,50,
215,6,164,3,0,
215,6,168,4,0,
215,6,173,3,0,
215,6,180,2,0,
215,6,182,4,0,
215,6,185,1,22,
215,6,189,3,0,
215,6,193,2,0,
215,6,196,1,43,
215,6,196,3,0,
215,6,203,3,0,
215,6,207,3,0,
215,6,210,3,0,
215,6,213,4,0,
215,6,214,3,0,
215,6,216,4,0,
215,6,218,4,0,
215,6,223,3,0,
215,6,231,4,0,
215,6,232,1,64,
215,6,237,4,0,
215,6,240,4,0,
215,6,241,4,0,
215,6,244,3,0,
215,6,247,4,0,
215,6,249,4,0,
215,6,251,1,57,
215,6,252,2,0,
215,6,258,4,0,
215,6,259,4,0,
215,6,263,4,0,
215,6,264,4,0,
215,6,269,1,1,3
215,6,269,4,0,
215,6,280,4,0,
215,6,289,4,0,
215,6,290,4,0,
215,6,306,2,0,
215,6,332,4,0,
215,6,347,4,0,
215,7,10,1,1,1
215,7,14,3,0,
215,7,15,4,0,
215,7,38,3,0,
215,7,43,1,1,2
215,7,44,2,0,
215,7,57,4,0,
215,7,58,4,0,
215,7,59,4,0,
215,7,68,2,0,
215,7,68,3,0,
215,7,70,4,0,
215,7,91,4,0,
215,7,92,4,0,
215,7,97,1,36,
215,7,98,1,8,
215,7,102,3,0,
215,7,103,1,15,
215,7,104,4,0,
215,7,115,2,0,
215,7,138,3,0,
215,7,154,1,29,
215,7,156,4,0,
215,7,163,1,50,
215,7,164,3,0,
215,7,168,4,0,
215,7,180,2,0,
215,7,182,4,0,
215,7,185,1,22,
215,7,193,2,0,
215,7,196,1,43,
215,7,213,4,0,
215,7,216,4,0,
215,7,218,4,0,
215,7,231,4,0,
215,7,232,1,64,
215,7,237,4,0,
215,7,240,4,0,
215,7,241,4,0,
215,7,247,4,0,
215,7,249,4,0,
215,7,251,1,57,
215,7,252,2,0,
215,7,258,4,0,
215,7,259,4,0,
215,7,263,4,0,
215,7,264,4,0,
215,7,269,1,1,3
215,7,269,4,0,
215,7,280,4,0,
215,7,289,4,0,
215,7,290,4,0,
215,7,306,2,0,
215,7,332,4,0,
215,7,347,4,0,
215,8,8,2,0,
215,8,10,1,1,1
215,8,14,4,0,
215,8,15,4,0,
215,8,43,1,1,2
215,8,44,2,0,
215,8,57,4,0,
215,8,58,4,0,
215,8,59,4,0,
215,8,68,2,0,
215,8,70,4,0,
215,8,91,4,0,
215,8,92,4,0,
215,8,97,1,24,
215,8,98,1,8,
215,8,103,1,10,
215,8,104,4,0,
215,8,115,2,0,
215,8,138,4,0,
215,8,154,1,21,
215,8,156,4,0,
215,8,163,1,35,
215,8,164,4,0,
215,8,168,4,0,
215,8,180,2,0,
215,8,182,4,0,
215,8,185,1,14,
215,8,193,2,0,
215,8,196,1,28,
215,8,203,4,0,
215,8,206,4,0,
215,8,207,4,0,
215,8,213,4,0,
215,8,214,4,0,
215,8,216,4,0,
215,8,218,4,0,
215,8,228,2,0,
215,8,231,4,0,
215,8,232,1,42,
215,8,237,4,0,
215,8,240,4,0,
215,8,241,4,0,
215,8,244,4,0,
215,8,247,4,0,
215,8,249,4,0,
215,8,251,1,38,
215,8,252,2,0,
215,8,258,4,0,
215,8,259,4,0,
215,8,263,4,0,
215,8,264,4,0,
215,8,269,1,1,3
215,8,269,4,0,
215,8,280,4,0,
215,8,289,4,0,
215,8,290,4,0,
215,8,306,2,0,
215,8,332,4,0,
215,8,347,4,0,
215,8,363,4,0,
215,8,371,4,0,
215,8,373,4,0,
215,8,374,4,0,
215,8,386,2,0,
215,8,398,4,0,
215,8,399,4,0,
215,8,404,4,0,
215,8,419,4,0,
215,8,420,1,49,
215,8,420,2,0,
215,8,421,4,0,
215,8,445,4,0,
215,8,458,2,0,
215,9,8,2,0,
215,9,8,3,0,
215,9,10,1,1,1
215,9,14,4,0,
215,9,15,4,0,
215,9,43,1,1,2
215,9,44,2,0,
215,9,57,4,0,
215,9,58,4,0,
215,9,59,4,0,
215,9,68,2,0,
215,9,70,4,0,
215,9,91,4,0,
215,9,92,4,0,
215,9,97,1,24,
215,9,98,1,8,
215,9,103,1,10,
215,9,104,4,0,
215,9,115,2,0,
215,9,129,3,0,
215,9,138,4,0,
215,9,154,1,21,
215,9,156,4,0,
215,9,163,1,35,
215,9,164,4,0,
215,9,168,4,0,
215,9,173,3,0,
215,9,180,2,0,
215,9,180,3,0,
215,9,182,4,0,
215,9,185,1,14,
215,9,189,3,0,
215,9,193,2,0,
215,9,196,1,28,
215,9,196,3,0,
215,9,203,4,0,
215,9,206,4,0,
215,9,207,4,0,
215,9,210,3,0,
215,9,213,4,0,
215,9,214,4,0,
215,9,216,4,0,
215,9,218,4,0,
215,9,228,2,0,
215,9,231,4,0,
215,9,232,1,42,
215,9,237,4,0,
215,9,240,4,0,
215,9,241,4,0,
215,9,244,4,0,
215,9,247,4,0,
215,9,249,4,0,
215,9,251,1,38,
215,9,252,2,0,
215,9,258,4,0,
215,9,259,4,0,
215,9,263,4,0,
215,9,264,4,0,
215,9,269,1,1,3
215,9,269,4,0,
215,9,280,4,0,
215,9,282,3,0,
215,9,289,4,0,
215,9,290,4,0,
215,9,306,2,0,
215,9,332,4,0,
215,9,347,4,0,
215,9,363,4,0,
215,9,371,4,0,
215,9,373,4,0,
215,9,374,4,0,
215,9,386,2,0,
215,9,398,4,0,
215,9,399,4,0,
215,9,404,4,0,
215,9,419,4,0,
215,9,420,1,49,
215,9,420,2,0,
215,9,421,4,0,
215,9,445,4,0,
215,9,458,2,0,
215,10,8,2,0,
215,10,8,3,0,
215,10,10,1,1,1
215,10,14,4,0,
215,10,15,4,0,
215,10,29,3,0,
215,10,43,1,1,2
215,10,44,2,0,
215,10,57,4,0,
215,10,58,4,0,
215,10,59,4,0,
215,10,67,3,0,
215,10,68,2,0,
215,10,70,4,0,
215,10,91,4,0,
215,10,92,4,0,
215,10,97,1,24,
215,10,98,1,8,
215,10,103,1,10,
215,10,104,4,0,
215,10,115,2,0,
215,10,129,3,0,
215,10,138,4,0,
215,10,154,1,21,
215,10,156,4,0,
215,10,163,1,35,
215,10,164,4,0,
215,10,168,4,0,
215,10,173,3,0,
215,10,180,2,0,
215,10,180,3,0,
215,10,182,4,0,
215,10,185,1,14,
215,10,189,3,0,
215,10,193,2,0,
215,10,196,1,28,
215,10,196,3,0,
215,10,203,4,0,
215,10,206,4,0,
215,10,207,4,0,
215,10,210,3,0,
215,10,213,4,0,
215,10,214,4,0,
215,10,216,4,0,
215,10,218,4,0,
215,10,228,2,0,
215,10,231,4,0,
215,10,232,1,42,
215,10,237,4,0,
215,10,240,4,0,
215,10,241,4,0,
215,10,244,4,0,
215,10,247,4,0,
215,10,249,4,0,
215,10,250,4,0,
215,10,251,1,38,
215,10,252,2,0,
215,10,258,4,0,
215,10,259,4,0,
215,10,263,4,0,
215,10,264,4,0,
215,10,269,1,1,3
215,10,269,4,0,
215,10,274,2,0,
215,10,280,4,0,
215,10,282,3,0,
215,10,289,4,0,
215,10,290,4,0,
215,10,306,2,0,
215,10,332,4,0,
215,10,347,4,0,
215,10,363,4,0,
215,10,371,4,0,
215,10,373,4,0,
215,10,374,4,0,
215,10,386,2,0,
215,10,398,4,0,
215,10,399,4,0,
215,10,404,4,0,
215,10,419,4,0,
215,10,420,1,49,
215,10,420,2,0,
215,10,421,4,0,
215,10,445,4,0,
215,10,458,2,0,
215,11,8,2,0,
215,11,10,1,1,1
215,11,14,4,0,
215,11,15,4,0,
215,11,43,1,1,2
215,11,44,2,0,
215,11,57,4,0,
215,11,58,4,0,
215,11,59,4,0,
215,11,68,2,0,
215,11,70,4,0,
215,11,91,4,0,
215,11,92,4,0,
215,11,97,1,24,
215,11,98,1,8,
215,11,103,1,10,
215,11,104,4,0,
215,11,115,4,0,
215,11,138,4,0,
215,11,154,1,21,
215,11,156,4,0,
215,11,163,1,38,
215,11,164,4,0,
215,11,168,4,0,
215,11,180,2,0,
215,11,182,4,0,
215,11,185,1,14,
215,11,193,2,0,
215,11,196,1,28,
215,11,206,4,0,
215,11,207,4,0,
215,11,213,4,0,
215,11,216,4,0,
215,11,218,4,0,
215,11,228,2,0,
215,11,232,1,49,
215,11,237,4,0,
215,11,240,4,0,
215,11,241,4,0,
215,11,244,4,0,
215,11,247,4,0,
215,11,249,4,0,
215,11,251,1,42,
215,11,252,2,0,
215,11,258,4,0,
215,11,259,4,0,
215,11,263,4,0,
215,11,269,1,1,3
215,11,269,4,0,
215,11,274,2,0,
215,11,280,4,0,
215,11,306,2,0,
215,11,332,4,0,
215,11,347,4,0,
215,11,364,2,0,
215,11,371,4,0,
215,11,373,4,0,
215,11,374,4,0,
215,11,386,2,0,
215,11,398,4,0,
215,11,404,4,0,
215,11,419,2,0,
215,11,420,1,51,
215,11,420,2,0,
215,11,421,4,0,
215,11,458,2,0,
215,11,468,1,35,
215,11,468,4,0,
215,11,490,4,0,
215,11,496,4,0,
215,11,514,4,0,
215,11,555,4,0,
215,12,10,1,1,1
215,12,15,4,0,
215,12,43,1,1,2
215,12,57,4,0,
215,12,58,4,0,
215,12,59,4,0,
215,12,70,4,0,
215,12,91,4,0,
215,12,92,4,0,
215,12,97,1,36,
215,12,98,1,8,
215,12,103,1,15,
215,12,104,4,0,
215,12,154,1,29,
215,12,156,4,0,
215,12,163,1,50,
215,12,168,4,0,
215,12,182,4,0,
215,12,185,1,22,
215,12,196,1,43,
215,12,213,4,0,
215,12,216,4,0,
215,12,218,4,0,
215,12,231,4,0,
215,12,232,1,64,
215,12,237,4,0,
215,12,240,4,0,
215,12,241,4,0,
215,12,247,4,0,
215,12,249,4,0,
215,12,251,1,57,
215,12,258,4,0,
215,12,259,4,0,
215,12,263,4,0,
215,12,264,4,0,
215,12,269,1,1,3
215,12,269,4,0,
215,12,280,4,0,
215,12,289,4,0,
215,12,290,4,0,
215,12,332,4,0,
215,12,347,4,0,
215,13,10,1,1,1
215,13,15,4,0,
215,13,38,3,0,
215,13,43,1,1,2
215,13,57,4,0,
215,13,58,4,0,
215,13,59,4,0,
215,13,70,4,0,
215,13,91,4,0,
215,13,92,4,0,
215,13,97,1,36,
215,13,98,1,8,
215,13,102,3,0,
215,13,103,1,15,
215,13,104,4,0,
215,13,138,3,0,
215,13,154,1,29,
215,13,156,4,0,
215,13,163,1,50,
215,13,164,3,0,
215,13,168,4,0,
215,13,171,3,0,
215,13,182,4,0,
215,13,185,1,22,
215,13,196,1,43,
215,13,196,3,0,
215,13,207,3,0,
215,13,213,4,0,
215,13,216,4,0,
215,13,218,4,0,
215,13,231,4,0,
215,13,232,1,64,
215,13,237,4,0,
215,13,240,4,0,
215,13,241,4,0,
215,13,247,4,0,
215,13,249,4,0,
215,13,251,1,57,
215,13,258,4,0,
215,13,259,4,0,
215,13,263,4,0,
215,13,264,4,0,
215,13,269,1,1,3
215,13,269,4,0,
215,13,280,4,0,
215,13,289,4,0,
215,13,290,4,0,
215,13,332,4,0,
215,13,347,4,0,
215,14,8,2,0,
215,14,8,3,0,
215,14,10,1,1,1
215,14,14,4,0,
215,14,15,4,0,
215,14,43,1,1,2
215,14,44,2,0,
215,14,57,4,0,
215,14,58,4,0,
215,14,59,4,0,
215,14,67,3,0,
215,14,68,2,0,
215,14,70,4,0,
215,14,91,4,0,
215,14,92,4,0,
215,14,97,1,20,
215,14,98,1,8,
215,14,103,1,32,
215,14,104,4,0,
215,14,115,4,0,
215,14,138,4,0,
215,14,154,1,16,
215,14,156,4,0,
215,14,163,1,35,
215,14,164,4,0,
215,14,168,4,0,
215,14,173,3,0,
215,14,180,2,0,
215,14,180,3,0,
215,14,182,4,0,
215,14,185,1,10,
215,14,193,2,0,
215,14,196,1,14,
215,14,196,3,0,
215,14,206,4,0,
215,14,207,4,0,
215,14,213,4,0,
215,14,214,3,0,
215,14,216,4,0,
215,14,218,4,0,
215,14,228,2,0,
215,14,231,3,0,
215,14,232,1,22,
215,14,237,4,0,
215,14,240,4,0,
215,14,241,4,0,
215,14,244,4,0,
215,14,247,4,0,
215,14,249,4,0,
215,14,251,1,28,
215,14,252,2,0,
215,14,258,4,0,
215,14,259,4,0,
215,14,263,4,0,
215,14,269,1,1,3
215,14,269,4,0,
215,14,274,2,0,
215,14,280,4,0,
215,14,282,3,0,
215,14,289,1,40,
215,14,289,3,0,
215,14,306,2,0,
215,14,332,4,0,
215,14,347,4,0,
215,14,364,2,0,
215,14,371,4,0,
215,14,373,4,0,
215,14,374,4,0,
215,14,386,1,44,
215,14,386,2,0,
215,14,398,4,0,
215,14,399,3,0,
215,14,404,4,0,
215,14,419,2,0,
215,14,420,1,47,
215,14,420,2,0,
215,14,421,4,0,
215,14,458,2,0,
215,14,468,1,25,
215,14,468,4,0,
215,14,490,4,0,
215,14,492,3,0,
215,14,496,4,0,
215,14,514,4,0,
215,14,555,4,0,
215,15,8,2,0,
215,15,10,1,1,1
215,15,14,4,0,
215,15,15,4,0,
215,15,43,1,1,2
215,15,44,2,0,
215,15,57,4,0,
215,15,58,4,0,
215,15,59,4,0,
215,15,68,2,0,
215,15,70,4,0,
215,15,91,4,0,
215,15,92,4,0,
215,15,97,1,20,
215,15,98,1,8,
215,15,103,1,32,
215,15,104,4,0,
215,15,115,4,0,
215,15,138,4,0,
215,15,154,1,16,
215,15,156,4,0,
215,15,163,1,35,
215,15,164,4,0,
215,15,168,4,0,
215,15,180,2,0,
215,15,182,4,0,
215,15,185,1,10,
215,15,193,2,0,
215,15,196,1,14,
215,15,206,4,0,
215,15,207,4,0,
215,15,213,4,0,
215,15,214,4,0,
215,15,216,4,0,
215,15,218,4,0,
215,15,228,2,0,
215,15,232,1,22,
215,15,237,4,0,
215,15,240,4,0,
215,15,241,4,0,
215,15,244,4,0,
215,15,247,4,0,
215,15,249,4,0,
215,15,251,1,28,
215,15,252,2,0,
215,15,258,4,0,
215,15,259,4,0,
215,15,263,4,0,
215,15,269,1,1,3
215,15,269,4,0,
215,15,274,2,0,
215,15,280,4,0,
215,15,289,1,40,
215,15,306,2,0,
215,15,332,4,0,
215,15,347,4,0,
215,15,364,2,0,
215,15,371,4,0,
215,15,373,4,0,
215,15,374,4,0,
215,15,386,1,44,
215,15,386,2,0,
215,15,398,4,0,
215,15,399,4,0,
215,15,404,4,0,
215,15,419,2,0,
215,15,420,1,47,
215,15,420,2,0,
215,15,421,4,0,
215,15,458,2,0,
215,15,468,1,25,
215,15,468,4,0,
215,15,490,4,0,
215,15,496,4,0,
215,15,514,4,0,
215,15,555,4,0,
215,15,556,2,0,
215,15,590,4,0,
215,15,612,4,0,
215,16,8,2,0,
215,16,8,3,0,
215,16,10,1,1,1
215,16,14,4,0,
215,16,15,4,0,
215,16,43,1,1,2
215,16,44,2,0,
215,16,57,4,0,
215,16,58,4,0,
215,16,59,4,0,
215,16,67,3,0,
215,16,68,2,0,
215,16,70,4,0,
215,16,91,4,0,
215,16,92,4,0,
215,16,97,1,20,1
215,16,98,1,8,1
215,16,103,1,32,1
215,16,104,4,0,
215,16,115,4,0,
215,16,138,4,0,
215,16,154,1,16,1
215,16,156,4,0,
215,16,163,1,35,1
215,16,164,4,0,
215,16,168,4,0,
215,16,173,3,0,
215,16,180,2,0,
215,16,180,3,0,
215,16,182,4,0,
215,16,185,1,10,1
215,16,193,2,0,
215,16,196,1,14,1
215,16,196,3,0,
215,16,206,4,0,
215,16,207,4,0,
215,16,213,4,0,
215,16,214,4,0,
215,16,216,4,0,
215,16,218,4,0,
215,16,228,2,0,
215,16,231,3,0,
215,16,232,1,22,1
215,16,237,4,0,
215,16,240,4,0,
215,16,241,4,0,
215,16,244,4,0,
215,16,247,4,0,
215,16,249,4,0,
215,16,251,1,28,1
215,16,252,2,0,
215,16,258,4,0,
215,16,259,4,0,
215,16,263,4,0,
215,16,264,3,0,
215,16,269,1,1,3
215,16,269,4,0,
215,16,274,2,0,
215,16,280,4,0,
215,16,282,3,0,
215,16,289,1,40,1
215,16,289,3,0,
215,16,290,4,0,
215,16,306,2,0,
215,16,332,4,0,
215,16,347,4,0,
215,16,364,2,0,
215,16,371,4,0,
215,16,373,4,0,
215,16,374,4,0,
215,16,386,1,44,1
215,16,386,2,0,
215,16,398,4,0,
215,16,399,4,0,
215,16,404,4,0,
215,16,419,2,0,
215,16,420,1,47,1
215,16,420,2,0,
215,16,421,4,0,
215,16,458,2,0,
215,16,468,1,25,1
215,16,468,4,0,
215,16,490,4,0,
215,16,492,3,0,
215,16,496,4,0,
215,16,514,4,0,
215,16,555,4,0,
215,16,556,2,0,
215,16,590,4,0,
215,16,612,4,0,
215,17,8,2,0,
215,17,10,1,1,1
215,17,14,4,0,
215,17,43,1,1,2
215,17,44,2,0,
215,17,57,4,0,
215,17,58,4,0,
215,17,59,4,0,
215,17,68,2,0,
215,17,92,4,0,
215,17,97,1,20,
215,17,98,1,8,
215,17,103,1,32,
215,17,104,4,0,
215,17,115,4,0,
215,17,138,4,0,
215,17,154,1,16,
215,17,156,4,0,
215,17,163,1,35,
215,17,164,4,0,
215,17,168,4,0,
215,17,180,2,0,
215,17,182,4,0,
215,17,185,1,10,
215,17,193,2,0,
215,17,196,1,14,
215,17,206,4,0,
215,17,207,4,0,
215,17,213,4,0,
215,17,214,4,0,
215,17,216,4,0,
215,17,218,4,0,
215,17,228,2,0,
215,17,232,1,22,
215,17,237,4,0,
215,17,240,4,0,
215,17,241,4,0,
215,17,244,4,0,
215,17,247,4,0,
215,17,251,1,28,
215,17,252,2,0,
215,17,258,4,0,
215,17,259,4,0,
215,17,263,4,0,
215,17,269,1,1,3
215,17,269,4,0,
215,17,274,2,0,
215,17,280,4,0,
215,17,289,1,40,
215,17,306,2,0,
215,17,332,4,0,
215,17,347,4,0,
215,17,364,2,0,
215,17,371,4,0,
215,17,373,4,0,
215,17,374,4,0,
215,17,386,1,44,
215,17,386,2,0,
215,17,398,4,0,
215,17,399,4,0,
215,17,404,4,0,
215,17,419,2,0,
215,17,420,1,47,
215,17,420,2,0,
215,17,421,4,0,
215,17,458,2,0,
215,17,468,1,25,
215,17,490,4,0,
215,17,496,4,0,
215,17,555,4,0,
215,17,556,2,0,
215,17,590,4,0,
215,17,675,2,0,
215,18,8,2,0,
215,18,10,1,1,1
215,18,14,4,0,
215,18,43,1,1,2
215,18,44,2,0,
215,18,57,4,0,
215,18,58,4,0,
215,18,59,4,0,
215,18,68,2,0,
215,18,92,4,0,
215,18,97,1,20,
215,18,98,1,8,
215,18,103,1,32,
215,18,104,4,0,
215,18,115,4,0,
215,18,138,4,0,
215,18,154,1,16,
215,18,156,4,0,
215,18,163,1,35,
215,18,164,4,0,
215,18,168,4,0,
215,18,180,2,0,
215,18,182,4,0,
215,18,185,1,10,
215,18,193,2,0,
215,18,196,1,14,
215,18,206,4,0,
215,18,207,4,0,
215,18,213,4,0,
215,18,214,4,0,
215,18,216,4,0,
215,18,218,4,0,
215,18,228,2,0,
215,18,232,1,22,
215,18,237,4,0,
215,18,240,4,0,
215,18,241,4,0,
215,18,244,4,0,
215,18,247,4,0,
215,18,251,1,28,
215,18,252,2,0,
215,18,258,4,0,
215,18,259,4,0,
215,18,263,4,0,
215,18,269,1,1,3
215,18,269,4,0,
215,18,274,2,0,
215,18,280,4,0,
215,18,289,1,40,
215,18,306,2,0,
215,18,332,4,0,
215,18,347,4,0,
215,18,364,2,0,
215,18,371,4,0,
215,18,373,4,0,
215,18,374,4,0,
215,18,386,1,44,
215,18,386,2,0,
215,18,398,4,0,
215,18,399,4,0,
215,18,404,4,0,
215,18,419,2,0,
215,18,420,1,47,
215,18,420,2,0,
215,18,421,4,0,
215,18,458,2,0,
215,18,468,1,25,
215,18,490,4,0,
215,18,496,4,0,
215,18,555,4,0,
215,18,556,2,0,
215,18,590,4,0,
215,18,675,2,0,
216,3,7,4,0,
216,3,8,4,0,
216,3,9,4,0,
216,3,10,1,1,1
216,3,15,4,0,
216,3,29,4,0,
216,3,36,2,0,
216,3,37,1,50,
216,3,43,1,1,2
216,3,46,4,0,
216,3,68,2,0,
216,3,69,2,0,
216,3,70,4,0,
216,3,89,4,0,
216,3,91,4,0,
216,3,92,4,0,
216,3,104,4,0,
216,3,111,4,0,
216,3,116,2,0,
216,3,122,1,8,
216,3,129,4,0,
216,3,154,1,15,
216,3,156,1,29,
216,3,156,4,0,
216,3,163,1,36,
216,3,168,4,0,
216,3,173,1,43,
216,3,173,4,0,
216,3,174,4,0,
216,3,182,4,0,
216,3,185,1,22,
216,3,189,4,0,
216,3,192,4,0,
216,3,203,4,0,
216,3,205,4,0,
216,3,207,4,0,
216,3,210,4,0,
216,3,213,4,0,
216,3,214,4,0,
216,3,216,4,0,
216,3,218,4,0,
216,3,223,4,0,
216,3,237,4,0,
216,3,241,4,0,
216,3,242,2,0,
216,3,249,4,0,
216,4,7,4,0,
216,4,8,4,0,
216,4,9,4,0,
216,4,10,1,1,1
216,4,15,4,0,
216,4,29,4,0,
216,4,36,2,0,
216,4,37,1,50,
216,4,43,1,1,2
216,4,46,4,0,
216,4,68,2,0,
216,4,69,2,0,
216,4,70,4,0,
216,4,89,4,0,
216,4,91,4,0,
216,4,92,4,0,
216,4,104,4,0,
216,4,111,4,0,
216,4,116,2,0,
216,4,122,1,8,
216,4,129,4,0,
216,4,154,1,15,
216,4,156,1,29,
216,4,156,4,0,
216,4,163,1,36,
216,4,168,4,0,
216,4,173,1,43,
216,4,173,4,0,
216,4,174,4,0,
216,4,182,4,0,
216,4,185,1,22,
216,4,189,4,0,
216,4,192,4,0,
216,4,203,4,0,
216,4,205,4,0,
216,4,207,4,0,
216,4,210,4,0,
216,4,213,4,0,
216,4,214,4,0,
216,4,216,4,0,
216,4,218,4,0,
216,4,223,4,0,
216,4,232,2,0,
216,4,237,4,0,
216,4,241,4,0,
216,4,242,2,0,
216,4,249,4,0,
216,5,10,1,1,1
216,5,15,4,0,
216,5,36,2,0,
216,5,37,1,49,
216,5,43,1,1,2
216,5,46,4,0,
216,5,68,2,0,
216,5,69,2,0,
216,5,70,4,0,
216,5,89,4,0,
216,5,91,4,0,
216,5,92,4,0,
216,5,104,4,0,
216,5,122,1,7,
216,5,154,1,13,
216,5,156,1,31,
216,5,156,4,0,
216,5,163,1,37,
216,5,168,4,0,
216,5,173,1,43,
216,5,182,4,0,
216,5,185,1,25,
216,5,213,4,0,
216,5,214,2,0,
216,5,216,4,0,
216,5,218,4,0,
216,5,232,2,0,
216,5,237,4,0,
216,5,240,4,0,
216,5,241,4,0,
216,5,242,2,0,
216,5,249,4,0,
216,5,259,4,0,
216,5,263,4,0,
216,5,264,4,0,
216,5,269,4,0,
216,5,280,4,0,
216,5,281,2,0,
216,5,290,4,0,
216,5,313,1,19,
216,5,313,2,0,
216,5,332,4,0,
216,5,339,4,0,
216,6,5,3,0,
216,6,7,3,0,
216,6,8,3,0,
216,6,9,3,0,
216,6,10,1,1,1
216,6,14,3,0,
216,6,15,4,0,
216,6,25,3,0,
216,6,34,3,0,
216,6,36,2,0,
216,6,37,1,49,
216,6,38,3,0,
216,6,43,1,1,2
216,6,46,4,0,
216,6,68,2,0,
216,6,68,3,0,
216,6,69,2,0,
216,6,69,3,0,
216,6,70,4,0,
216,6,89,4,0,
216,6,91,4,0,
216,6,92,4,0,
216,6,102,3,0,
216,6,104,4,0,
216,6,111,3,0,
216,6,118,3,0,
216,6,122,1,7,
216,6,129,3,0,
216,6,154,1,13,
216,6,156,1,31,
216,6,156,4,0,
216,6,163,1,37,
216,6,164,3,0,
216,6,168,4,0,
216,6,173,1,43,
216,6,173,3,0,
216,6,182,4,0,
216,6,185,1,25,
216,6,189,3,0,
216,6,203,3,0,
216,6,205,3,0,
216,6,207,3,0,
216,6,210,3,0,
216,6,213,4,0,
216,6,214,2,0,
216,6,214,3,0,
216,6,216,4,0,
216,6,218,4,0,
216,6,223,3,0,
216,6,232,2,0,
216,6,237,4,0,
216,6,240,4,0,
216,6,241,4,0,
216,6,242,2,0,
216,6,249,4,0,
216,6,259,4,0,
216,6,263,4,0,
216,6,264,4,0,
216,6,269,4,0,
216,6,280,4,0,
216,6,281,2,0,
216,6,290,4,0,
216,6,313,1,19,
216,6,313,2,0,
216,6,332,4,0,
216,6,339,4,0,
216,7,5,3,0,
216,7,10,1,1,1
216,7,14,3,0,
216,7,15,4,0,
216,7,25,3,0,
216,7,34,3,0,
216,7,36,2,0,
216,7,37,1,49,
216,7,38,3,0,
216,7,43,1,1,2
216,7,46,4,0,
216,7,68,2,0,
216,7,68,3,0,
216,7,69,2,0,
216,7,69,3,0,
216,7,70,4,0,
216,7,89,4,0,
216,7,91,4,0,
216,7,92,4,0,
216,7,102,3,0,
216,7,104,4,0,
216,7,118,3,0,
216,7,122,1,7,
216,7,154,1,13,
216,7,156,1,31,
216,7,156,4,0,
216,7,163,1,37,
216,7,164,3,0,
216,7,168,4,0,
216,7,173,1,43,
216,7,182,4,0,
216,7,185,1,25,
216,7,213,4,0,
216,7,214,2,0,
216,7,216,4,0,
216,7,218,4,0,
216,7,232,2,0,
216,7,237,4,0,
216,7,240,4,0,
216,7,241,4,0,
216,7,242,2,0,
216,7,249,4,0,
216,7,259,4,0,
216,7,263,4,0,
216,7,264,4,0,
216,7,269,4,0,
216,7,280,4,0,
216,7,281,2,0,
216,7,290,4,0,
216,7,313,1,19,
216,7,313,2,0,
216,7,332,4,0,
216,7,339,4,0,
216,8,10,1,1,2
216,8,14,4,0,
216,8,15,4,0,
216,8,36,2,0,
216,8,37,1,50,
216,8,38,2,0,
216,8,43,1,1,3
216,8,46,4,0,
216,8,68,2,0,
216,8,69,2,0,
216,8,70,4,0,
216,8,89,4,0,
216,8,91,4,0,
216,8,92,4,0,
216,8,104,4,0,
216,8,122,1,1,4
216,8,154,1,8,
216,8,156,1,43,1
216,8,156,4,0,
216,8,157,4,0,
216,8,163,1,29,
216,8,164,4,0,
216,8,168,4,0,
216,8,173,1,43,2
216,8,182,4,0,
216,8,185,1,15,
216,8,203,4,0,
216,8,204,1,36,
216,8,207,4,0,
216,8,213,4,0,
216,8,214,2,0,
216,8,214,4,0,
216,8,216,4,0,
216,8,218,4,0,
216,8,230,1,22,
216,8,232,2,0,
216,8,237,4,0,
216,8,238,2,0,
216,8,240,4,0,
216,8,241,4,0,
216,8,242,2,0,
216,8,249,4,0,
216,8,259,4,0,
216,8,263,4,0,
216,8,264,4,0,
216,8,269,4,0,
216,8,280,4,0,
216,8,281,2,0,
216,8,290,4,0,
216,8,313,1,1,5
216,8,313,2,0,
216,8,317,4,0,
216,8,332,4,0,
216,8,339,4,0,
216,8,343,1,1,1
216,8,363,4,0,
216,8,370,2,0,
216,8,371,4,0,
216,8,374,1,57,
216,8,374,4,0,
216,8,400,2,0,
216,8,421,4,0,
216,8,445,4,0,
216,9,7,3,0,
216,9,8,3,0,
216,9,9,3,0,
216,9,10,1,1,2
216,9,14,4,0,
216,9,15,4,0,
216,9,36,2,0,
216,9,37,1,50,
216,9,38,2,0,
216,9,43,1,1,3
216,9,46,4,0,
216,9,68,2,0,
216,9,69,2,0,
216,9,70,4,0,
216,9,89,4,0,
216,9,91,4,0,
216,9,92,4,0,
216,9,104,4,0,
216,9,122,1,1,4
216,9,129,3,0,
216,9,154,1,8,
216,9,156,1,43,1
216,9,156,4,0,
216,9,157,4,0,
216,9,163,1,29,
216,9,164,4,0,
216,9,168,4,0,
216,9,173,1,43,2
216,9,173,3,0,
216,9,182,4,0,
216,9,185,1,15,
216,9,189,3,0,
216,9,203,4,0,
216,9,204,1,36,
216,9,205,3,0,
216,9,207,4,0,
216,9,210,3,0,
216,9,213,4,0,
216,9,214,2,0,
216,9,214,4,0,
216,9,216,4,0,
216,9,218,4,0,
216,9,230,1,22,
216,9,232,2,0,
216,9,237,4,0,
216,9,238,2,0,
216,9,240,4,0,
216,9,241,4,0,
216,9,242,2,0,
216,9,249,4,0,
216,9,259,4,0,
216,9,263,4,0,
216,9,264,4,0,
216,9,269,4,0,
216,9,276,3,0,
216,9,280,4,0,
216,9,281,2,0,
216,9,290,4,0,
216,9,313,1,1,5
216,9,313,2,0,
216,9,317,4,0,
216,9,332,4,0,
216,9,339,4,0,
216,9,343,1,1,1
216,9,363,4,0,
216,9,370,2,0,
216,9,371,4,0,
216,9,374,1,57,
216,9,374,4,0,
216,9,387,3,0,
216,9,400,2,0,
216,9,402,3,0,
216,9,421,4,0,
216,9,441,3,0,
216,9,445,4,0,
216,10,7,3,0,
216,10,8,3,0,
216,10,9,3,0,
216,10,10,1,1,2
216,10,14,4,0,
216,10,15,4,0,
216,10,29,3,0,
216,10,36,2,0,
216,10,37,1,50,
216,10,38,2,0,
216,10,43,1,1,3
216,10,46,4,0,
216,10,68,2,0,
216,10,69,2,0,
216,10,70,4,0,
216,10,89,4,0,
216,10,91,4,0,
216,10,92,4,0,
216,10,104,4,0,
216,10,122,1,1,4
216,10,129,3,0,
216,10,154,1,8,
216,10,156,1,43,1
216,10,156,4,0,
216,10,157,4,0,
216,10,163,1,29,
216,10,164,4,0,
216,10,168,4,0,
216,10,173,1,43,2
216,10,173,3,0,
216,10,182,4,0,
216,10,185,1,15,
216,10,187,2,0,
216,10,189,3,0,
216,10,203,4,0,
216,10,204,1,36,
216,10,205,3,0,
216,10,207,4,0,
216,10,210,3,0,
216,10,213,4,0,
216,10,214,2,0,
216,10,214,4,0,
216,10,216,4,0,
216,10,218,4,0,
216,10,230,1,22,
216,10,232,2,0,
216,10,237,4,0,
216,10,238,2,0,
216,10,240,4,0,
216,10,241,4,0,
216,10,242,2,0,
216,10,249,4,0,
216,10,259,4,0,
216,10,263,4,0,
216,10,264,4,0,
216,10,269,4,0,
216,10,276,3,0,
216,10,280,4,0,
216,10,281,2,0,
216,10,290,4,0,
216,10,313,1,1,5
216,10,313,2,0,
216,10,317,4,0,
216,10,332,4,0,
216,10,339,4,0,
216,10,343,1,1,1
216,10,363,4,0,
216,10,370,2,0,
216,10,371,4,0,
216,10,374,1,57,
216,10,374,4,0,
216,10,387,3,0,
216,10,400,2,0,
216,10,402,3,0,
216,10,421,4,0,
216,10,441,3,0,
216,10,445,4,0,
216,11,10,1,1,2
216,11,14,4,0,
216,11,15,4,0,
216,11,36,2,0,
216,11,37,1,50,
216,11,38,2,0,
216,11,43,1,1,3
216,11,46,4,0,
216,11,68,2,0,
216,11,69,2,0,
216,11,70,4,0,
216,11,89,4,0,
216,11,91,4,0,
216,11,92,4,0,
216,11,104,4,0,
216,11,122,1,1,4
216,11,154,1,8,
216,11,156,1,43,1
216,11,156,4,0,
216,11,157,4,0,
216,11,163,1,29,
216,11,164,4,0,
216,11,168,4,0,
216,11,173,1,43,2
216,11,182,4,0,
216,11,185,1,15,
216,11,187,2,0,
216,11,204,1,36,
216,11,207,4,0,
216,11,213,4,0,
216,11,214,2,0,
216,11,216,4,0,
216,11,218,4,0,
216,11,230,1,22,
216,11,232,2,0,
216,11,237,4,0,
216,11,238,2,0,
216,11,240,4,0,
216,11,241,4,0,
216,11,242,2,0,
216,11,249,4,0,
216,11,259,4,0,
216,11,263,4,0,
216,11,269,4,0,
216,11,280,4,0,
216,11,281,2,0,
216,11,313,1,1,5
216,11,313,2,0,
216,11,317,4,0,
216,11,332,4,0,
216,11,339,4,0,
216,11,343,1,1,1
216,11,370,2,0,
216,11,371,4,0,
216,11,374,1,57,
216,11,374,4,0,
216,11,400,2,0,
216,11,421,4,0,
216,11,468,4,0,
216,11,496,4,0,
216,11,498,2,0,
216,11,514,4,0,
216,11,523,4,0,
216,11,526,4,0,
216,12,10,1,1,1
216,12,15,4,0,
216,12,37,1,49,
216,12,43,1,1,2
216,12,46,4,0,
216,12,70,4,0,
216,12,89,4,0,
216,12,91,4,0,
216,12,92,4,0,
216,12,104,4,0,
216,12,122,1,7,
216,12,154,1,13,
216,12,156,1,31,
216,12,156,4,0,
216,12,163,1,37,
216,12,168,4,0,
216,12,173,1,43,
216,12,182,4,0,
216,12,185,1,25,
216,12,213,4,0,
216,12,216,4,0,
216,12,218,4,0,
216,12,237,4,0,
216,12,240,4,0,
216,12,241,4,0,
216,12,249,4,0,
216,12,259,4,0,
216,12,263,4,0,
216,12,264,4,0,
216,12,269,4,0,
216,12,280,4,0,
216,12,290,4,0,
216,12,313,1,19,
216,12,332,4,0,
216,12,339,4,0,
216,13,10,1,1,1
216,13,15,4,0,
216,13,34,3,0,
216,13,37,1,49,
216,13,38,3,0,
216,13,43,1,1,2
216,13,46,4,0,
216,13,69,3,0,
216,13,70,4,0,
216,13,89,4,0,
216,13,91,4,0,
216,13,92,4,0,
216,13,102,3,0,
216,13,104,4,0,
216,13,122,1,7,
216,13,154,1,13,
216,13,156,1,31,
216,13,156,4,0,
216,13,163,1,37,
216,13,164,3,0,
216,13,168,4,0,
216,13,173,1,43,
216,13,182,4,0,
216,13,185,1,25,
216,13,207,3,0,
216,13,213,4,0,
216,13,216,4,0,
216,13,218,4,0,
216,13,237,4,0,
216,13,240,4,0,
216,13,241,4,0,
216,13,249,4,0,
216,13,259,4,0,
216,13,263,4,0,
216,13,264,4,0,
216,13,269,4,0,
216,13,280,4,0,
216,13,290,4,0,
216,13,313,1,19,
216,13,332,4,0,
216,13,339,4,0,
216,14,7,3,0,
216,14,8,3,0,
216,14,9,3,0,
216,14,10,1,1,2
216,14,14,4,0,
216,14,15,4,0,
216,14,36,2,0,
216,14,37,1,50,
216,14,38,2,0,
216,14,43,1,1,3
216,14,46,4,0,
216,14,68,2,0,
216,14,69,2,0,
216,14,70,4,0,
216,14,89,4,0,
216,14,91,4,0,
216,14,92,4,0,
216,14,104,4,0,
216,14,122,1,1,4
216,14,154,1,8,
216,14,156,1,43,1
216,14,156,4,0,
216,14,157,4,0,
216,14,163,1,29,
216,14,164,4,0,
216,14,168,4,0,
216,14,173,1,43,2
216,14,173,3,0,
216,14,182,4,0,
216,14,185,1,15,
216,14,187,2,0,
216,14,204,1,36,
216,14,207,4,0,
216,14,213,4,0,
216,14,214,2,0,
216,14,214,3,0,
216,14,216,4,0,
216,14,218,4,0,
216,14,230,1,22,
216,14,232,2,0,
216,14,237,4,0,
216,14,238,2,0,
216,14,240,4,0,
216,14,241,4,0,
216,14,242,2,0,
216,14,249,4,0,
216,14,259,4,0,
216,14,263,4,0,
216,14,269,4,0,
216,14,276,3,0,
216,14,280,4,0,
216,14,281,2,0,
216,14,304,3,0,
216,14,313,1,1,5
216,14,313,2,0,
216,14,317,4,0,
216,14,332,4,0,
216,14,339,4,0,
216,14,343,1,1,1
216,14,343,3,0,
216,14,370,2,0,
216,14,371,4,0,
216,14,374,1,57,
216,14,374,4,0,
216,14,387,3,0,
216,14,400,2,0,
216,14,402,3,0,
216,14,421,4,0,
216,14,441,3,0,
216,14,468,4,0,
216,14,496,4,0,
216,14,498,2,0,
216,14,514,4,0,
216,14,523,4,0,
216,14,526,4,0,
216,15,10,1,1,3
216,15,14,4,0,
216,15,15,4,0,
216,15,36,2,0,
216,15,37,1,50,
216,15,38,2,0,
216,15,46,4,0,
216,15,68,2,0,
216,15,69,2,0,
216,15,70,4,0,
216,15,89,4,0,
216,15,91,4,0,
216,15,92,4,0,
216,15,104,4,0,
216,15,122,1,1,5
216,15,154,1,8,
216,15,156,1,43,1
216,15,156,4,0,
216,15,157,4,0,
216,15,163,1,29,
216,15,164,4,0,
216,15,168,4,0,
216,15,173,1,43,2
216,15,182,4,0,
216,15,185,1,15,
216,15,187,2,0,
216,15,204,1,36,
216,15,207,4,0,
216,15,213,4,0,
216,15,214,2,0,
216,15,214,4,0,
216,15,216,4,0,
216,15,218,4,0,
216,15,230,1,22,
216,15,232,2,0,
216,15,237,4,0,
216,15,238,2,0,
216,15,240,4,0,
216,15,241,4,0,
216,15,242,2,0,
216,15,249,4,0,
216,15,259,4,0,
216,15,263,4,0,
216,15,269,4,0,
216,15,280,4,0,
216,15,281,2,0,
216,15,313,1,1,6
216,15,313,2,0,
216,15,317,4,0,
216,15,332,4,0,
216,15,339,4,0,
216,15,343,1,1,2
216,15,370,2,0,
216,15,371,4,0,
216,15,374,1,1,1
216,15,374,1,57,
216,15,374,4,0,
216,15,400,2,0,
216,15,421,4,0,
216,15,468,4,0,
216,15,496,4,0,
216,15,498,2,0,
216,15,514,4,0,
216,15,523,4,0,
216,15,583,2,0,
216,15,589,1,25,
216,15,590,4,0,
216,15,608,1,1,4
216,15,612,4,0,
216,16,7,3,0,
216,16,8,3,0,
216,16,9,3,0,
216,16,10,1,1,3
216,16,14,4,0,
216,16,15,4,0,
216,16,36,2,0,
216,16,37,1,50,1
216,16,38,2,0,
216,16,46,4,0,
216,16,68,2,0,
216,16,69,2,0,
216,16,70,4,0,
216,16,89,4,0,
216,16,91,4,0,
216,16,92,4,0,
216,16,104,4,0,
216,16,122,1,1,5
216,16,154,1,8,1
216,16,156,1,43,1
216,16,156,4,0,
216,16,157,4,0,
216,16,163,1,29,1
216,16,164,4,0,
216,16,168,4,0,
216,16,173,1,43,2
216,16,173,3,0,
216,16,182,4,0,
216,16,185,1,15,1
216,16,187,2,0,
216,16,204,1,36,1
216,16,207,4,0,
216,16,213,4,0,
216,16,214,2,0,
216,16,214,4,0,
216,16,216,4,0,
216,16,218,4,0,
216,16,230,1,22,1
216,16,232,2,0,
216,16,237,4,0,
216,16,238,2,0,
216,16,240,4,0,
216,16,241,4,0,
216,16,242,2,0,
216,16,249,4,0,
216,16,259,4,0,
216,16,263,4,0,
216,16,264,3,0,
216,16,269,4,0,
216,16,276,3,0,
216,16,280,4,0,
216,16,281,2,0,
216,16,290,4,0,
216,16,304,3,0,
216,16,313,1,1,6
216,16,313,2,0,
216,16,317,4,0,
216,16,332,4,0,
216,16,339,4,0,
216,16,343,1,1,2
216,16,343,3,0,
216,16,370,2,0,
216,16,371,4,0,
216,16,374,1,1,1
216,16,374,1,57,1
216,16,374,4,0,
216,16,387,3,0,
216,16,400,2,0,
216,16,402,3,0,
216,16,421,4,0,
216,16,441,3,0,
216,16,468,4,0,
216,16,496,4,0,
216,16,498,2,0,
216,16,514,4,0,
216,16,523,4,0,
216,16,583,2,0,
216,16,589,1,25,1
216,16,590,4,0,
216,16,608,1,1,4
216,16,612,4,0,
216,17,10,1,1,3
216,17,14,4,0,
216,17,36,2,0,
216,17,37,1,50,
216,17,38,2,0,
216,17,46,4,0,
216,17,68,2,0,
216,17,69,2,0,
216,17,89,4,0,
216,17,92,4,0,
216,17,104,4,0,
216,17,122,1,1,5
216,17,154,1,8,
216,17,156,1,43,1
216,17,156,4,0,
216,17,157,4,0,
216,17,163,1,29,
216,17,164,4,0,
216,17,168,4,0,
216,17,173,1,43,2
216,17,182,4,0,
216,17,185,1,15,
216,17,187,2,0,
216,17,204,1,36,
216,17,207,4,0,
216,17,213,4,0,
216,17,214,2,0,
216,17,214,4,0,
216,17,216,4,0,
216,17,218,4,0,
216,17,230,1,22,
216,17,232,2,0,
216,17,237,4,0,
216,17,238,2,0,
216,17,240,4,0,
216,17,241,4,0,
216,17,242,2,0,
216,17,259,4,0,
216,17,263,4,0,
216,17,269,4,0,
216,17,280,4,0,
216,17,281,2,0,
216,17,313,1,1,6
216,17,313,2,0,
216,17,317,4,0,
216,17,332,4,0,
216,17,339,4,0,
216,17,343,1,1,2
216,17,370,2,0,
216,17,371,4,0,
216,17,374,1,1,1
216,17,374,1,57,
216,17,374,4,0,
216,17,400,2,0,
216,17,421,4,0,
216,17,496,4,0,
216,17,498,2,0,
216,17,523,4,0,
216,17,526,4,0,
216,17,583,2,0,
216,17,589,1,25,
216,17,590,4,0,
216,17,608,1,1,4
216,18,10,1,1,3
216,18,14,4,0,
216,18,36,2,0,
216,18,37,1,50,
216,18,38,2,0,
216,18,46,4,0,
216,18,68,2,0,
216,18,69,2,0,
216,18,89,4,0,
216,18,92,4,0,
216,18,104,4,0,
216,18,122,1,1,5
216,18,154,1,8,
216,18,156,1,43,1
216,18,156,4,0,
216,18,157,4,0,
216,18,163,1,29,
216,18,164,4,0,
216,18,168,4,0,
216,18,173,1,43,2
216,18,182,4,0,
216,18,185,1,15,
216,18,187,2,0,
216,18,204,1,36,
216,18,207,4,0,
216,18,213,4,0,
216,18,214,2,0,
216,18,214,4,0,
216,18,216,4,0,
216,18,218,4,0,
216,18,230,1,22,
216,18,232,2,0,
216,18,237,4,0,
216,18,238,2,0,
216,18,240,4,0,
216,18,241,4,0,
216,18,242,2,0,
216,18,259,4,0,
216,18,263,4,0,
216,18,269,4,0,
216,18,280,4,0,
216,18,281,2,0,
216,18,313,1,1,6
216,18,313,2,0,
216,18,317,4,0,
216,18,332,4,0,
216,18,339,4,0,
216,18,343,1,1,2
216,18,370,2,0,
216,18,371,4,0,
216,18,374,1,1,1
216,18,374,1,57,
216,18,374,4,0,
216,18,400,2,0,
216,18,421,4,0,
216,18,496,4,0,
216,18,498,2,0,
216,18,523,4,0,
216,18,526,4,0,
216,18,583,2,0,
216,18,589,1,25,
216,18,590,4,0,
216,18,608,1,1,4
217,3,7,4,0,
217,3,8,4,0,
217,3,9,4,0,
217,3,10,1,1,1
217,3,15,4,0,
217,3,29,4,0,
217,3,37,1,59,
217,3,43,1,1,2
217,3,46,4,0,
217,3,63,4,0,
217,3,70,4,0,
217,3,89,4,0,
217,3,91,4,0,
217,3,92,4,0,
217,3,104,4,0,
217,3,111,4,0,
217,3,122,1,1,3
217,3,122,1,8,
217,3,129,4,0,
217,3,154,1,1,4
217,3,154,1,15,
217,3,156,1,29,
217,3,156,4,0,
217,3,163,1,39,
217,3,168,4,0,
217,3,173,1,49,
217,3,173,4,0,
217,3,174,4,0,
217,3,182,4,0,
217,3,185,1,22,
217,3,189,4,0,
217,3,192,4,0,
217,3,203,4,0,
217,3,205,4,0,
217,3,207,4,0,
217,3,210,4,0,
217,3,213,4,0,
217,3,214,4,0,
217,3,216,4,0,
217,3,218,4,0,
217,3,223,4,0,
217,3,237,4,0,
217,3,241,4,0,
217,3,249,4,0,
217,4,7,4,0,
217,4,8,4,0,
217,4,9,4,0,
217,4,10,1,1,1
217,4,15,4,0,
217,4,29,4,0,
217,4,37,1,59,
217,4,43,1,1,2
217,4,46,4,0,
217,4,63,4,0,
217,4,70,4,0,
217,4,89,4,0,
217,4,91,4,0,
217,4,92,4,0,
217,4,104,4,0,
217,4,111,4,0,
217,4,122,1,1,3
217,4,122,1,8,
217,4,129,4,0,
217,4,154,1,1,4
217,4,154,1,15,
217,4,156,1,29,
217,4,156,4,0,
217,4,163,1,39,
217,4,168,4,0,
217,4,173,1,49,
217,4,173,4,0,
217,4,174,4,0,
217,4,182,4,0,
217,4,185,1,22,
217,4,189,4,0,
217,4,192,4,0,
217,4,203,4,0,
217,4,205,4,0,
217,4,207,4,0,
217,4,210,4,0,
217,4,213,4,0,
217,4,214,4,0,
217,4,216,4,0,
217,4,218,4,0,
217,4,223,4,0,
217,4,237,4,0,
217,4,241,4,0,
217,4,249,4,0,
217,5,10,1,1,1
217,5,15,4,0,
217,5,37,1,49,
217,5,43,1,1,2
217,5,46,4,0,
217,5,63,4,0,
217,5,70,4,0,
217,5,89,4,0,
217,5,91,4,0,
217,5,92,4,0,
217,5,104,4,0,
217,5,122,1,1,3
217,5,122,1,7,
217,5,154,1,1,4
217,5,154,1,13,
217,5,156,1,31,
217,5,156,4,0,
217,5,163,1,37,
217,5,168,4,0,
217,5,173,1,43,
217,5,182,4,0,
217,5,185,1,25,
217,5,213,4,0,
217,5,216,4,0,
217,5,218,4,0,
217,5,237,4,0,
217,5,240,4,0,
217,5,241,4,0,
217,5,249,4,0,
217,5,259,4,0,
217,5,263,4,0,
217,5,264,4,0,
217,5,269,4,0,
217,5,280,4,0,
217,5,290,4,0,
217,5,313,1,19,
217,5,317,4,0,
217,5,332,4,0,
217,5,339,4,0,
217,6,5,3,0,
217,6,7,3,0,
217,6,8,3,0,
217,6,9,3,0,
217,6,10,1,1,1
217,6,14,3,0,
217,6,15,4,0,
217,6,25,3,0,
217,6,34,3,0,
217,6,37,1,49,
217,6,38,3,0,
217,6,43,1,1,2
217,6,46,4,0,
217,6,63,4,0,
217,6,68,3,0,
217,6,69,3,0,
217,6,70,4,0,
217,6,89,4,0,
217,6,91,4,0,
217,6,92,4,0,
217,6,102,3,0,
217,6,104,4,0,
217,6,111,3,0,
217,6,118,3,0,
217,6,122,1,1,3
217,6,122,1,7,
217,6,129,3,0,
217,6,154,1,1,4
217,6,154,1,13,
217,6,156,1,31,
217,6,156,4,0,
217,6,157,3,0,
217,6,163,1,37,
217,6,164,3,0,
217,6,168,4,0,
217,6,173,1,43,
217,6,173,3,0,
217,6,182,4,0,
217,6,185,1,25,
217,6,189,3,0,
217,6,203,3,0,
217,6,205,3,0,
217,6,207,3,0,
217,6,210,3,0,
217,6,213,4,0,
217,6,214,3,0,
217,6,216,4,0,
217,6,218,4,0,
217,6,223,3,0,
217,6,237,4,0,
217,6,240,4,0,
217,6,241,4,0,
217,6,249,4,0,
217,6,259,4,0,
217,6,263,4,0,
217,6,264,4,0,
217,6,269,4,0,
217,6,280,4,0,
217,6,290,4,0,
217,6,313,1,19,
217,6,317,4,0,
217,6,332,4,0,
217,6,339,4,0,
217,7,5,3,0,
217,7,10,1,1,1
217,7,14,3,0,
217,7,15,4,0,
217,7,25,3,0,
217,7,34,3,0,
217,7,37,1,49,
217,7,38,3,0,
217,7,43,1,1,2
217,7,46,4,0,
217,7,63,4,0,
217,7,68,3,0,
217,7,69,3,0,
217,7,70,4,0,
217,7,89,4,0,
217,7,91,4,0,
217,7,92,4,0,
217,7,102,3,0,
217,7,104,4,0,
217,7,118,3,0,
217,7,122,1,1,3
217,7,122,1,7,
217,7,154,1,1,4
217,7,154,1,13,
217,7,156,1,31,
217,7,156,4,0,
217,7,157,3,0,
217,7,163,1,37,
217,7,164,3,0,
217,7,168,4,0,
217,7,173,1,43,
217,7,182,4,0,
217,7,185,1,25,
217,7,213,4,0,
217,7,216,4,0,
217,7,218,4,0,
217,7,237,4,0,
217,7,240,4,0,
217,7,241,4,0,
217,7,249,4,0,
217,7,259,4,0,
217,7,263,4,0,
217,7,264,4,0,
217,7,269,4,0,
217,7,280,4,0,
217,7,290,4,0,
217,7,313,1,19,
217,7,317,4,0,
217,7,332,4,0,
217,7,339,4,0,
217,8,10,1,1,2
217,8,14,4,0,
217,8,15,4,0,
217,8,37,1,58,
217,8,43,1,1,3
217,8,46,4,0,
217,8,63,4,0,
217,8,70,4,0,
217,8,89,4,0,
217,8,91,4,0,
217,8,92,4,0,
217,8,104,4,0,
217,8,122,1,1,4
217,8,154,1,8,
217,8,156,1,47,
217,8,156,4,0,
217,8,157,4,0,
217,8,163,1,29,
217,8,164,4,0,
217,8,168,4,0,
217,8,173,1,49,
217,8,182,4,0,
217,8,184,1,38,
217,8,185,1,15,
217,8,203,4,0,
217,8,207,4,0,
217,8,213,4,0,
217,8,214,4,0,
217,8,216,4,0,
217,8,218,4,0,
217,8,230,1,22,
217,8,237,4,0,
217,8,240,4,0,
217,8,241,4,0,
217,8,249,4,0,
217,8,259,4,0,
217,8,263,4,0,
217,8,264,4,0,
217,8,269,4,0,
217,8,280,4,0,
217,8,290,4,0,
217,8,313,1,1,5
217,8,317,4,0,
217,8,332,4,0,
217,8,339,4,0,
217,8,343,1,1,1
217,8,359,1,67,
217,8,363,4,0,
217,8,371,4,0,
217,8,374,4,0,
217,8,411,4,0,
217,8,416,4,0,
217,8,419,4,0,
217,8,421,4,0,
217,8,431,4,0,
217,8,444,4,0,
217,8,445,4,0,
217,9,7,3,0,
217,9,8,3,0,
217,9,9,3,0,
217,9,10,1,1,2
217,9,14,4,0,
217,9,15,4,0,
217,9,37,1,58,
217,9,43,1,1,3
217,9,46,4,0,
217,9,63,4,0,
217,9,70,4,0,
217,9,89,4,0,
217,9,91,4,0,
217,9,92,4,0,
217,9,104,4,0,
217,9,122,1,1,4
217,9,129,3,0,
217,9,154,1,8,
217,9,156,1,47,
217,9,156,4,0,
217,9,157,4,0,
217,9,163,1,29,
217,9,164,4,0,
217,9,168,4,0,
217,9,173,1,49,
217,9,173,3,0,
217,9,182,4,0,
217,9,184,1,38,
217,9,185,1,15,
217,9,189,3,0,
217,9,203,4,0,
217,9,205,3,0,
217,9,207,4,0,
217,9,210,3,0,
217,9,213,4,0,
217,9,214,4,0,
217,9,216,4,0,
217,9,218,4,0,
217,9,230,1,22,
217,9,237,4,0,
217,9,240,4,0,
217,9,241,4,0,
217,9,249,4,0,
217,9,253,3,0,
217,9,259,4,0,
217,9,263,4,0,
217,9,264,4,0,
217,9,269,4,0,
217,9,276,3,0,
217,9,280,4,0,
217,9,290,4,0,
217,9,313,1,1,5
217,9,317,4,0,
217,9,332,4,0,
217,9,339,4,0,
217,9,343,1,1,1
217,9,359,1,67,
217,9,363,4,0,
217,9,371,4,0,
217,9,374,4,0,
217,9,387,3,0,
217,9,402,3,0,
217,9,411,4,0,
217,9,416,4,0,
217,9,419,4,0,
217,9,421,4,0,
217,9,431,4,0,
217,9,441,3,0,
217,9,444,4,0,
217,9,445,4,0,
217,10,7,3,0,
217,10,8,3,0,
217,10,9,3,0,
217,10,10,1,1,2
217,10,14,4,0,
217,10,15,4,0,
217,10,29,3,0,
217,10,37,1,58,
217,10,43,1,1,3
217,10,46,4,0,
217,10,63,4,0,
217,10,67,3,0,
217,10,70,4,0,
217,10,89,4,0,
217,10,91,4,0,
217,10,92,4,0,
217,10,104,4,0,
217,10,122,1,1,4
217,10,129,3,0,
217,10,154,1,8,
217,10,156,1,47,
217,10,156,4,0,
217,10,157,4,0,
217,10,163,1,29,
217,10,164,4,0,
217,10,168,4,0,
217,10,173,1,49,
217,10,173,3,0,
217,10,182,4,0,
217,10,184,1,38,
217,10,185,1,15,
217,10,189,3,0,
217,10,203,4,0,
217,10,205,3,0,
217,10,207,4,0,
217,10,210,3,0,
217,10,213,4,0,
217,10,214,4,0,
217,10,216,4,0,
217,10,218,4,0,
217,10,230,1,22,
217,10,237,4,0,
217,10,240,4,0,
217,10,241,4,0,
217,10,249,4,0,
217,10,253,3,0,
217,10,259,4,0,
217,10,263,4,0,
217,10,264,4,0,
217,10,269,4,0,
217,10,276,3,0,
217,10,280,4,0,
217,10,290,4,0,
217,10,313,1,1,5
217,10,317,4,0,
217,10,332,4,0,
217,10,339,4,0,
217,10,343,1,1,1
217,10,359,1,67,
217,10,363,4,0,
217,10,371,4,0,
217,10,374,4,0,
217,10,387,3,0,
217,10,402,3,0,
217,10,411,4,0,
217,10,416,4,0,
217,10,419,4,0,
217,10,421,4,0,
217,10,431,4,0,
217,10,441,3,0,
217,10,444,4,0,
217,10,445,4,0,
217,11,10,1,1,2
217,11,14,4,0,
217,11,15,4,0,
217,11,37,1,58,
217,11,43,1,1,3
217,11,46,4,0,
217,11,63,4,0,
217,11,70,4,0,
217,11,89,4,0,
217,11,91,4,0,
217,11,92,4,0,
217,11,104,4,0,
217,11,122,1,1,4
217,11,154,1,8,
217,11,156,1,47,
217,11,156,4,0,
217,11,157,4,0,
217,11,163,1,29,
217,11,164,4,0,
217,11,168,4,0,
217,11,173,1,49,
217,11,182,4,0,
217,11,184,1,38,
217,11,185,1,15,
217,11,207,4,0,
217,11,213,4,0,
217,11,216,4,0,
217,11,218,4,0,
217,11,230,1,22,
217,11,237,4,0,
217,11,240,4,0,
217,11,241,4,0,
217,11,249,4,0,
217,11,259,4,0,
217,11,263,4,0,
217,11,269,4,0,
217,11,280,4,0,
217,11,313,1,1,5
217,11,317,4,0,
217,11,332,4,0,
217,11,339,4,0,
217,11,343,1,1,1
217,11,359,1,67,
217,11,371,4,0,
217,11,374,4,0,
217,11,411,4,0,
217,11,416,4,0,
217,11,421,4,0,
217,11,444,4,0,
217,11,468,4,0,
217,11,479,4,0,
217,11,496,4,0,
217,11,514,4,0,
217,11,523,4,0,
217,11,526,4,0,
217,12,10,1,1,1
217,12,15,4,0,
217,12,37,1,49,
217,12,43,1,1,2
217,12,46,4,0,
217,12,63,4,0,
217,12,70,4,0,
217,12,89,4,0,
217,12,91,4,0,
217,12,92,4,0,
217,12,104,4,0,
217,12,122,1,1,3
217,12,122,1,7,
217,12,154,1,1,4
217,12,154,1,13,
217,12,156,1,31,
217,12,156,4,0,
217,12,163,1,37,
217,12,168,4,0,
217,12,173,1,43,
217,12,182,4,0,
217,12,185,1,25,
217,12,213,4,0,
217,12,216,4,0,
217,12,218,4,0,
217,12,237,4,0,
217,12,240,4,0,
217,12,241,4,0,
217,12,249,4,0,
217,12,259,4,0,
217,12,263,4,0,
217,12,264,4,0,
217,12,269,4,0,
217,12,280,4,0,
217,12,290,4,0,
217,12,313,1,19,
217,12,317,4,0,
217,12,332,4,0,
217,12,339,4,0,
217,13,10,1,1,1
217,13,15,4,0,
217,13,34,3,0,
217,13,37,1,49,
217,13,38,3,0,
217,13,43,1,1,2
217,13,46,4,0,
217,13,63,4,0,
217,13,69,3,0,
217,13,70,4,0,
217,13,89,4,0,
217,13,91,4,0,
217,13,92,4,0,
217,13,102,3,0,
217,13,104,4,0,
217,13,122,1,1,3
217,13,122,1,7,
217,13,154,1,1,4
217,13,154,1,13,
217,13,156,1,31,
217,13,156,4,0,
217,13,163,1,37,
217,13,164,3,0,
217,13,168,4,0,
217,13,173,1,43,
217,13,182,4,0,
217,13,185,1,25,
217,13,207,3,0,
217,13,213,4,0,
217,13,216,4,0,
217,13,218,4,0,
217,13,237,4,0,
217,13,240,4,0,
217,13,241,4,0,
217,13,249,4,0,
217,13,259,4,0,
217,13,263,4,0,
217,13,264,4,0,
217,13,269,4,0,
217,13,280,4,0,
217,13,290,4,0,
217,13,313,1,19,
217,13,317,4,0,
217,13,332,4,0,
217,13,339,4,0,
217,14,7,3,0,
217,14,8,3,0,
217,14,9,3,0,
217,14,10,1,1,2
217,14,14,4,0,
217,14,15,4,0,
217,14,37,1,58,
217,14,43,1,1,3
217,14,46,4,0,
217,14,63,4,0,
217,14,67,3,0,
217,14,70,4,0,
217,14,89,4,0,
217,14,91,4,0,
217,14,92,4,0,
217,14,104,4,0,
217,14,122,1,1,4
217,14,154,1,8,
217,14,156,1,47,
217,14,156,4,0,
217,14,157,4,0,
217,14,163,1,29,
217,14,164,4,0,
217,14,168,4,0,
217,14,173,1,49,
217,14,173,3,0,
217,14,182,4,0,
217,14,184,1,38,
217,14,185,1,15,
217,14,207,4,0,
217,14,213,4,0,
217,14,214,3,0,
217,14,216,4,0,
217,14,218,4,0,
217,14,230,1,22,
217,14,237,4,0,
217,14,240,4,0,
217,14,241,4,0,
217,14,249,4,0,
217,14,253,3,0,
217,14,259,4,0,
217,14,263,4,0,
217,14,269,4,0,
217,14,276,3,0,
217,14,280,4,0,
217,14,304,3,0,
217,14,313,1,1,5
217,14,317,4,0,
217,14,332,4,0,
217,14,339,4,0,
217,14,343,1,1,1
217,14,343,3,0,
217,14,359,1,67,
217,14,371,4,0,
217,14,374,4,0,
217,14,387,3,0,
217,14,402,3,0,
217,14,411,4,0,
217,14,416,4,0,
217,14,421,4,0,
217,14,441,3,0,
217,14,444,4,0,
217,14,468,4,0,
217,14,479,4,0,
217,14,496,4,0,
217,14,514,4,0,
217,14,523,4,0,
217,14,526,4,0,
217,15,10,1,1,3
217,15,14,4,0,
217,15,15,4,0,
217,15,37,1,58,
217,15,43,1,1,4
217,15,46,4,0,
217,15,63,4,0,
217,15,70,4,0,
217,15,89,4,0,
217,15,91,4,0,
217,15,92,4,0,
217,15,104,4,0,
217,15,122,1,1,5
217,15,154,1,8,
217,15,156,1,47,
217,15,156,4,0,
217,15,157,4,0,
217,15,163,1,29,
217,15,164,4,0,
217,15,168,4,0,
217,15,173,1,49,
217,15,182,4,0,
217,15,184,1,38,
217,15,185,1,15,
217,15,207,4,0,
217,15,213,4,0,
217,15,214,4,0,
217,15,216,4,0,
217,15,218,4,0,
217,15,230,1,22,
217,15,237,4,0,
217,15,240,4,0,
217,15,241,4,0,
217,15,249,4,0,
217,15,259,4,0,
217,15,263,4,0,
217,15,269,4,0,
217,15,280,4,0,
217,15,313,1,1,6
217,15,317,4,0,
217,15,332,4,0,
217,15,339,4,0,
217,15,343,1,1,2
217,15,359,1,1,1
217,15,359,1,67,
217,15,371,4,0,
217,15,374,4,0,
217,15,411,4,0,
217,15,416,4,0,
217,15,421,4,0,
217,15,444,4,0,
217,15,468,4,0,
217,15,479,4,0,
217,15,496,4,0,
217,15,514,4,0,
217,15,523,4,0,
217,15,589,1,25,
217,15,590,4,0,
217,15,612,4,0,
217,16,7,3,0,
217,16,8,3,0,
217,16,9,3,0,
217,16,10,1,1,3
217,16,14,4,0,
217,16,15,4,0,
217,16,37,1,58,1
217,16,43,1,1,4
217,16,46,4,0,
217,16,63,4,0,
217,16,67,3,0,
217,16,70,4,0,
217,16,89,4,0,
217,16,91,4,0,
217,16,92,4,0,
217,16,104,4,0,
217,16,122,1,1,5
217,16,154,1,8,1
217,16,156,1,47,1
217,16,156,4,0,
217,16,157,4,0,
217,16,163,1,29,1
217,16,164,4,0,
217,16,168,4,0,
217,16,173,1,49,1
217,16,173,3,0,
217,16,182,4,0,
217,16,184,1,38,1
217,16,185,1,15,1
217,16,207,4,0,
217,16,213,4,0,
217,16,214,4,0,
217,16,216,4,0,
217,16,218,4,0,
217,16,230,1,22,1
217,16,237,4,0,
217,16,240,4,0,
217,16,241,4,0,
217,16,249,4,0,
217,16,253,3,0,
217,16,259,4,0,
217,16,263,4,0,
217,16,264,3,0,
217,16,269,4,0,
217,16,276,3,0,
217,16,280,4,0,
217,16,290,4,0,
217,16,304,3,0,
217,16,313,1,1,6
217,16,317,4,0,
217,16,332,4,0,
217,16,339,4,0,
217,16,343,1,1,2
217,16,343,3,0,
217,16,359,1,1,1
217,16,359,1,67,1
217,16,371,4,0,
217,16,374,4,0,
217,16,387,3,0,
217,16,402,3,0,
217,16,411,4,0,
217,16,416,4,0,
217,16,421,4,0,
217,16,441,3,0,
217,16,444,4,0,
217,16,468,4,0,
217,16,479,4,0,
217,16,496,4,0,
217,16,514,4,0,
217,16,523,4,0,
217,16,589,1,25,1
217,16,590,4,0,
217,16,612,4,0,
217,17,10,1,1,3
217,17,14,4,0,
217,17,37,1,58,
217,17,43,1,1,4
217,17,46,4,0,
217,17,63,4,0,
217,17,89,4,0,
217,17,92,4,0,
217,17,104,4,0,
217,17,122,1,1,5
217,17,154,1,8,
217,17,156,1,47,
217,17,156,4,0,
217,17,157,4,0,
217,17,163,1,29,
217,17,164,4,0,
217,17,168,4,0,
217,17,173,1,49,
217,17,182,4,0,
217,17,184,1,38,
217,17,185,1,15,
217,17,207,4,0,
217,17,213,4,0,
217,17,214,4,0,
217,17,216,4,0,
217,17,218,4,0,
217,17,230,1,22,
217,17,237,4,0,
217,17,240,4,0,
217,17,241,4,0,
217,17,259,4,0,
217,17,263,4,0,
217,17,269,4,0,
217,17,280,4,0,
217,17,313,1,1,6
217,17,317,4,0,
217,17,332,4,0,
217,17,339,4,0,
217,17,343,1,1,2
217,17,359,1,1,1
217,17,359,1,67,
217,17,371,4,0,
217,17,374,4,0,
217,17,411,4,0,
217,17,416,4,0,
217,17,421,4,0,
217,17,444,4,0,
217,17,479,4,0,
217,17,496,4,0,
217,17,523,4,0,
217,17,526,4,0,
217,17,589,1,25,
217,17,590,4,0,
217,18,10,1,1,3
217,18,14,4,0,
217,18,37,1,58,
217,18,43,1,1,4
217,18,46,4,0,
217,18,63,4,0,
217,18,89,4,0,
217,18,92,4,0,
217,18,104,4,0,
217,18,122,1,1,5
217,18,154,1,8,
217,18,156,1,47,
217,18,156,4,0,
217,18,157,4,0,
217,18,163,1,29,
217,18,164,4,0,
217,18,168,4,0,
217,18,173,1,49,
217,18,182,4,0,
217,18,184,1,38,
217,18,185,1,15,
217,18,207,4,0,
217,18,213,4,0,
217,18,214,4,0,
217,18,216,4,0,
217,18,218,4,0,
217,18,230,1,22,
217,18,237,4,0,
217,18,240,4,0,
217,18,241,4,0,
217,18,259,4,0,
217,18,263,4,0,
217,18,269,4,0,
217,18,280,4,0,
217,18,313,1,1,6
217,18,317,4,0,
217,18,332,4,0,
217,18,339,4,0,
217,18,343,1,1,2
217,18,359,1,1,1
217,18,359,1,67,
217,18,371,4,0,
217,18,374,4,0,
217,18,411,4,0,
217,18,416,4,0,
217,18,421,4,0,
217,18,444,4,0,
217,18,479,4,0,
217,18,496,4,0,
217,18,523,4,0,
217,18,526,4,0,
217,18,589,1,25,
217,18,590,4,0,
218,3,34,1,50,
218,3,52,1,8,
218,3,53,1,36,
218,3,88,1,15,
218,3,92,4,0,
218,3,104,4,0,
218,3,106,1,22,
218,3,111,4,0,
218,3,123,1,1,
218,3,126,4,0,
218,3,133,1,29,
218,3,151,2,0,
218,3,156,4,0,
218,3,157,1,43,
218,3,173,4,0,
218,3,174,4,0,
218,3,182,4,0,
218,3,189,4,0,
218,3,203,4,0,
218,3,205,4,0,
218,3,207,4,0,
218,3,213,4,0,
218,3,214,4,0,
218,3,216,4,0,
218,3,218,4,0,
218,3,237,4,0,
218,3,241,4,0,
218,3,249,4,0,
218,4,34,1,50,
218,4,52,1,8,
218,4,53,1,36,
218,4,53,3,0,
218,4,88,1,15,
218,4,92,4,0,
218,4,104,4,0,
218,4,106,1,22,
218,4,111,4,0,
218,4,123,1,1,
218,4,126,4,0,
218,4,133,1,29,
218,4,151,2,0,
218,4,156,4,0,
218,4,157,1,43,
218,4,173,4,0,
218,4,174,4,0,
218,4,182,4,0,
218,4,189,4,0,
218,4,203,4,0,
218,4,205,4,0,
218,4,207,4,0,
218,4,213,4,0,
218,4,214,4,0,
218,4,216,4,0,
218,4,218,4,0,
218,4,237,4,0,
218,4,241,4,0,
218,4,249,4,0,
218,5,34,1,50,
218,5,52,1,8,
218,5,53,1,36,
218,5,53,4,0,
218,5,88,1,15,
218,5,92,4,0,
218,5,104,4,0,
218,5,106,1,22,
218,5,113,4,0,
218,5,115,4,0,
218,5,123,1,1,2
218,5,126,4,0,
218,5,133,1,29,
218,5,151,2,0,
218,5,156,4,0,
218,5,157,1,43,
218,5,182,4,0,
218,5,213,4,0,
218,5,216,4,0,
218,5,218,4,0,
218,5,237,4,0,
218,5,241,4,0,
218,5,249,4,0,
218,5,257,2,0,
218,5,263,4,0,
218,5,281,1,1,1
218,5,290,4,0,
218,5,315,4,0,
218,6,34,1,50,
218,6,34,3,0,
218,6,38,3,0,
218,6,52,1,8,
218,6,53,1,36,
218,6,53,4,0,
218,6,88,1,15,
218,6,92,4,0,
218,6,102,3,0,
218,6,104,4,0,
218,6,106,1,22,
218,6,111,3,0,
218,6,113,4,0,
218,6,115,4,0,
218,6,123,1,1,2
218,6,126,4,0,
218,6,133,1,29,
218,6,151,2,0,
218,6,156,4,0,
218,6,157,1,43,
218,6,157,3,0,
218,6,164,3,0,
218,6,173,3,0,
218,6,182,4,0,
218,6,189,3,0,
218,6,203,3,0,
218,6,205,3,0,
218,6,207,3,0,
218,6,213,4,0,
218,6,214,3,0,
218,6,216,4,0,
218,6,218,4,0,
218,6,237,4,0,
218,6,241,4,0,
218,6,249,4,0,
218,6,257,2,0,
218,6,263,4,0,
218,6,281,1,1,1
218,6,290,4,0,
218,6,315,4,0,
218,7,34,1,50,
218,7,34,3,0,
218,7,38,3,0,
218,7,52,1,8,
218,7,53,1,36,
218,7,53,4,0,
218,7,88,1,15,
218,7,92,4,0,
218,7,102,3,0,
218,7,104,4,0,
218,7,106,1,22,
218,7,113,4,0,
218,7,115,4,0,
218,7,123,1,1,2
218,7,126,4,0,
218,7,133,1,29,
218,7,151,2,0,
218,7,156,4,0,
218,7,157,1,43,
218,7,157,3,0,
218,7,164,3,0,
218,7,182,4,0,
218,7,213,4,0,
218,7,216,4,0,
218,7,218,4,0,
218,7,237,4,0,
218,7,241,4,0,
218,7,249,4,0,
218,7,257,2,0,
218,7,263,4,0,
218,7,281,1,1,1
218,7,290,4,0,
218,7,315,4,0,
218,8,34,1,46,
218,8,52,1,8,
218,8,53,1,53,
218,8,53,4,0,
218,8,88,1,11,
218,8,92,4,0,
218,8,104,4,0,
218,8,105,1,23,
218,8,106,1,16,
218,8,108,2,0,
218,8,113,4,0,
218,8,115,4,0,
218,8,123,1,1,2
218,8,126,4,0,
218,8,133,1,31,
218,8,151,2,0,
218,8,156,4,0,
218,8,157,1,41,
218,8,157,4,0,
218,8,164,4,0,
218,8,174,2,0,
218,8,182,4,0,
218,8,203,4,0,
218,8,207,4,0,
218,8,213,4,0,
218,8,214,4,0,
218,8,216,4,0,
218,8,218,4,0,
218,8,237,4,0,
218,8,241,4,0,
218,8,246,1,26,
218,8,249,4,0,
218,8,254,2,0,
218,8,255,2,0,
218,8,256,2,0,
218,8,257,2,0,
218,8,261,4,0,
218,8,262,2,0,
218,8,263,4,0,
218,8,281,1,1,1
218,8,290,4,0,
218,8,315,4,0,
218,8,317,4,0,
218,8,363,4,0,
218,8,414,1,56,
218,8,436,1,38,
218,8,445,4,0,
218,9,34,1,46,
218,9,52,1,8,
218,9,53,1,53,
218,9,53,4,0,
218,9,88,1,11,
218,9,92,4,0,
218,9,104,4,0,
218,9,105,1,23,
218,9,106,1,16,
218,9,108,2,0,
218,9,113,4,0,
218,9,115,4,0,
218,9,123,1,1,2
218,9,126,4,0,
218,9,133,1,31,
218,9,151,2,0,
218,9,156,4,0,
218,9,157,1,41,
218,9,157,4,0,
218,9,164,4,0,
218,9,173,3,0,
218,9,174,2,0,
218,9,182,4,0,
218,9,189,3,0,
218,9,203,4,0,
218,9,205,3,0,
218,9,207,4,0,
218,9,213,4,0,
218,9,214,4,0,
218,9,216,4,0,
218,9,218,4,0,
218,9,237,4,0,
218,9,241,4,0,
218,9,246,1,26,
218,9,246,3,0,
218,9,249,4,0,
218,9,254,2,0,
218,9,255,2,0,
218,9,256,2,0,
218,9,257,2,0,
218,9,257,3,0,
218,9,261,4,0,
218,9,262,2,0,
218,9,263,4,0,
218,9,281,1,1,1
218,9,290,4,0,
218,9,315,4,0,
218,9,317,4,0,
218,9,334,3,0,
218,9,363,4,0,
218,9,414,1,56,
218,9,414,3,0,
218,9,436,1,38,
218,9,445,4,0,
218,10,34,1,46,
218,10,52,1,8,
218,10,53,1,53,
218,10,53,4,0,
218,10,88,1,11,
218,10,92,4,0,
218,10,104,4,0,
218,10,105,1,23,
218,10,106,1,16,
218,10,108,2,0,
218,10,113,4,0,
218,10,115,4,0,
218,10,123,1,1,2
218,10,126,4,0,
218,10,133,1,31,
218,10,151,2,0,
218,10,156,4,0,
218,10,157,1,41,
218,10,157,4,0,
218,10,164,4,0,
218,10,173,3,0,
218,10,174,2,0,
218,10,182,4,0,
218,10,189,3,0,
218,10,203,4,0,
218,10,205,3,0,
218,10,207,4,0,
218,10,213,4,0,
218,10,214,4,0,
218,10,216,4,0,
218,10,218,4,0,
218,10,220,3,0,
218,10,237,4,0,
218,10,241,4,0,
218,10,246,1,26,
218,10,246,3,0,
218,10,249,4,0,
218,10,254,2,0,
218,10,255,2,0,
218,10,256,2,0,
218,10,257,2,0,
218,10,257,3,0,
218,10,261,4,0,
218,10,262,2,0,
218,10,263,4,0,
218,10,281,1,1,1
218,10,290,4,0,
218,10,315,4,0,
218,10,317,4,0,
218,10,334,3,0,
218,10,363,4,0,
218,10,414,1,56,
218,10,414,3,0,
218,10,436,1,38,
218,10,445,4,0,
218,11,34,1,46,
218,11,52,1,5,
218,11,53,1,50,
218,11,53,4,0,
218,11,88,1,10,
218,11,92,4,0,
218,11,104,4,0,
218,11,105,1,19,
218,11,106,1,14,
218,11,108,2,0,
218,11,113,4,0,
218,11,115,4,0,
218,11,123,1,1,2
218,11,126,4,0,
218,11,133,1,32,
218,11,151,2,0,
218,11,156,4,0,
218,11,157,1,41,
218,11,157,4,0,
218,11,164,4,0,
218,11,174,2,0,
218,11,182,4,0,
218,11,205,2,0,
218,11,207,4,0,
218,11,213,4,0,
218,11,216,4,0,
218,11,218,4,0,
218,11,237,4,0,
218,11,241,4,0,
218,11,246,1,28,
218,11,249,4,0,
218,11,254,2,0,
218,11,255,2,0,
218,11,256,2,0,
218,11,257,2,0,
218,11,261,4,0,
218,11,262,2,0,
218,11,263,4,0,
218,11,281,1,1,1
218,11,315,4,0,
218,11,317,4,0,
218,11,414,1,55,
218,11,414,2,0,
218,11,436,1,37,
218,11,481,1,23,
218,11,488,4,0,
218,11,496,4,0,
218,11,510,4,0,
218,11,517,2,0,
218,12,34,1,50,
218,12,52,1,8,
218,12,53,1,36,
218,12,53,4,0,
218,12,88,1,15,
218,12,92,4,0,
218,12,104,4,0,
218,12,106,1,22,
218,12,113,4,0,
218,12,115,4,0,
218,12,123,1,1,2
218,12,126,4,0,
218,12,133,1,29,
218,12,156,4,0,
218,12,157,1,43,
218,12,182,4,0,
218,12,213,4,0,
218,12,216,4,0,
218,12,218,4,0,
218,12,237,4,0,
218,12,241,4,0,
218,12,249,4,0,
218,12,263,4,0,
218,12,281,1,1,1
218,12,290,4,0,
218,12,315,4,0,
218,13,34,1,50,
218,13,34,3,0,
218,13,38,3,0,
218,13,52,1,8,
218,13,53,1,36,
218,13,53,4,0,
218,13,88,1,15,
218,13,92,4,0,
218,13,102,3,0,
218,13,104,4,0,
218,13,106,1,22,
218,13,113,4,0,
218,13,115,4,0,
218,13,120,3,0,
218,13,123,1,1,2
218,13,126,4,0,
218,13,133,1,29,
218,13,156,4,0,
218,13,157,1,43,
218,13,164,3,0,
218,13,182,4,0,
218,13,207,3,0,
218,13,213,4,0,
218,13,216,4,0,
218,13,218,4,0,
218,13,237,4,0,
218,13,241,4,0,
218,13,249,4,0,
218,13,263,4,0,
218,13,281,1,1,1
218,13,290,4,0,
218,13,315,4,0,
218,14,34,1,46,
218,14,52,1,5,
218,14,53,1,50,
218,14,53,4,0,
218,14,88,1,10,
218,14,92,4,0,
218,14,104,4,0,
218,14,105,1,19,
218,14,106,1,14,
218,14,108,2,0,
218,14,113,4,0,
218,14,115,4,0,
218,14,123,1,1,2
218,14,126,4,0,
218,14,133,1,32,
218,14,151,2,0,
218,14,156,4,0,
218,14,157,1,41,
218,14,157,4,0,
218,14,164,4,0,
218,14,173,3,0,
218,14,174,2,0,
218,14,182,4,0,
218,14,205,2,0,
218,14,207,4,0,
218,14,213,4,0,
218,14,214,3,0,
218,14,216,4,0,
218,14,218,4,0,
218,14,220,3,0,
218,14,237,4,0,
218,14,241,4,0,
218,14,246,1,28,
218,14,249,4,0,
218,14,254,2,0,
218,14,255,2,0,
218,14,256,2,0,
218,14,257,2,0,
218,14,257,3,0,
218,14,261,4,0,
218,14,262,2,0,
218,14,263,4,0,
218,14,281,1,1,1
218,14,315,4,0,
218,14,317,4,0,
218,14,334,3,0,
218,14,414,1,55,
218,14,414,2,0,
218,14,414,3,0,
218,14,436,1,37,
218,14,481,1,23,
218,14,488,4,0,
218,14,495,3,0,
218,14,496,4,0,
218,14,510,4,0,
218,14,517,2,0,
218,15,34,1,46,
218,15,52,1,5,
218,15,53,1,50,
218,15,53,4,0,
218,15,88,1,10,
218,15,92,4,0,
218,15,104,4,0,
218,15,105,1,19,
218,15,106,1,14,
218,15,108,2,0,
218,15,113,4,0,
218,15,115,4,0,
218,15,123,1,1,2
218,15,126,4,0,
218,15,133,1,32,
218,15,151,2,0,
218,15,156,4,0,
218,15,157,1,41,
218,15,157,4,0,
218,15,164,4,0,
218,15,174,2,0,
218,15,182,4,0,
218,15,205,2,0,
218,15,207,4,0,
218,15,213,4,0,
218,15,214,4,0,
218,15,216,4,0,
218,15,218,4,0,
218,15,237,4,0,
218,15,241,4,0,
218,15,246,1,28,
218,15,249,4,0,
218,15,254,2,0,
218,15,255,2,0,
218,15,256,2,0,
218,15,257,2,0,
218,15,261,4,0,
218,15,262,2,0,
218,15,263,4,0,
218,15,267,4,0,
218,15,281,1,1,1
218,15,315,4,0,
218,15,317,4,0,
218,15,385,2,0,
218,15,414,1,55,
218,15,414,2,0,
218,15,436,1,37,
218,15,481,1,23,
218,15,488,4,0,
218,15,496,4,0,
218,15,510,4,0,
218,15,517,2,0,
218,15,590,4,0,
218,15,611,4,0,
218,16,34,1,41,1
218,16,52,1,6,1
218,16,53,1,48,1
218,16,53,4,0,
218,16,88,1,8,1
218,16,92,4,0,
218,16,104,4,0,
218,16,105,1,43,1
218,16,106,1,13,1
218,16,108,2,0,
218,16,113,4,0,
218,16,115,4,0,
218,16,123,1,1,2
218,16,126,4,0,
218,16,133,1,36,1
218,16,151,2,0,
218,16,156,4,0,
218,16,157,1,29,1
218,16,157,4,0,
218,16,164,4,0,
218,16,173,3,0,
218,16,174,2,0,
218,16,182,4,0,
218,16,205,2,0,
218,16,207,4,0,
218,16,213,4,0,
218,16,214,4,0,
218,16,216,4,0,
218,16,218,4,0,
218,16,220,3,0,
218,16,237,4,0,
218,16,241,4,0,
218,16,246,1,22,1
218,16,249,4,0,
218,16,254,2,0,
218,16,255,2,0,
218,16,256,2,0,
218,16,257,2,0,
218,16,257,3,0,
218,16,261,4,0,
218,16,262,2,0,
218,16,263,4,0,
218,16,267,4,0,
218,16,281,1,1,1
218,16,290,4,0,
218,16,315,4,0,
218,16,317,4,0,
218,16,334,3,0,
218,16,385,2,0,
218,16,414,1,50,1
218,16,414,2,0,
218,16,414,3,0,
218,16,436,1,34,1
218,16,481,1,27,1
218,16,488,4,0,
218,16,495,3,0,
218,16,496,4,0,
218,16,499,1,20,1
218,16,510,1,15,1
218,16,510,4,0,
218,16,517,2,0,
218,16,590,4,0,
218,16,611,4,0,
218,17,34,1,41,
218,17,52,1,6,
218,17,53,1,48,
218,17,53,4,0,
218,17,88,1,8,
218,17,92,4,0,
218,17,104,4,0,
218,17,105,1,43,
218,17,106,1,13,
218,17,108,2,0,
218,17,113,4,0,
218,17,115,4,0,
218,17,123,1,1,2
218,17,126,4,0,
218,17,133,1,36,
218,17,151,2,0,
218,17,156,4,0,
218,17,157,1,29,
218,17,157,4,0,
218,17,164,4,0,
218,17,174,2,0,
218,17,182,4,0,
218,17,205,2,0,
218,17,207,4,0,
218,17,213,4,0,
218,17,214,4,0,
218,17,216,4,0,
218,17,218,4,0,
218,17,237,4,0,
218,17,241,4,0,
218,17,246,1,22,
218,17,254,2,0,
218,17,255,2,0,
218,17,256,2,0,
218,17,257,2,0,
218,17,261,4,0,
218,17,262,2,0,
218,17,263,4,0,
218,17,267,4,0,
218,17,281,1,1,1
218,17,315,4,0,
218,17,317,4,0,
218,17,385,2,0,
218,17,414,1,50,
218,17,414,2,0,
218,17,436,1,34,
218,17,481,1,27,
218,17,488,4,0,
218,17,496,4,0,
218,17,499,1,20,
218,17,510,1,15,
218,17,517,2,0,
218,17,590,4,0,
218,17,611,4,0,
218,18,34,1,41,
218,18,52,1,6,
218,18,53,1,48,
218,18,53,4,0,
218,18,88,1,8,
218,18,92,4,0,
218,18,104,4,0,
218,18,105,1,43,
218,18,106,1,13,
218,18,108,2,0,
218,18,113,4,0,
218,18,115,4,0,
218,18,123,1,1,2
218,18,126,4,0,
218,18,133,1,36,
218,18,151,2,0,
218,18,156,4,0,
218,18,157,1,29,
218,18,157,4,0,
218,18,164,4,0,
218,18,174,2,0,
218,18,182,4,0,
218,18,205,2,0,
218,18,207,4,0,
218,18,213,4,0,
218,18,214,4,0,
218,18,216,4,0,
218,18,218,4,0,
218,18,237,4,0,
218,18,241,4,0,
218,18,246,1,22,
218,18,254,2,0,
218,18,255,2,0,
218,18,256,2,0,
218,18,257,2,0,
218,18,261,4,0,
218,18,262,2,0,
218,18,263,4,0,
218,18,267,4,0,
218,18,281,1,1,1
218,18,315,4,0,
218,18,317,4,0,
218,18,385,2,0,
218,18,414,1,50,
218,18,414,2,0,
218,18,436,1,34,
218,18,481,1,27,
218,18,488,4,0,
218,18,496,4,0,
218,18,499,1,20,
218,18,510,1,15,
218,18,517,2,0,
218,18,590,4,0,
218,18,611,4,0,
219,3,34,1,60,
219,3,52,1,1,2
219,3,52,1,8,
219,3,53,1,36,
219,3,63,4,0,
219,3,70,4,0,
219,3,88,1,1,3
219,3,88,1,15,
219,3,89,4,0,
219,3,92,4,0,
219,3,104,4,0,
219,3,106,1,22,
219,3,111,4,0,
219,3,123,1,1,1
219,3,126,4,0,
219,3,133,1,29,
219,3,156,4,0,
219,3,157,1,48,
219,3,173,4,0,
219,3,174,4,0,
219,3,182,4,0,
219,3,189,4,0,
219,3,203,4,0,
219,3,205,4,0,
219,3,207,4,0,
219,3,213,4,0,
219,3,214,4,0,
219,3,216,4,0,
219,3,218,4,0,
219,3,237,4,0,
219,3,241,4,0,
219,3,249,4,0,
219,4,34,1,60,
219,4,52,1,1,2
219,4,52,1,8,
219,4,53,1,36,
219,4,53,3,0,
219,4,63,4,0,
219,4,70,4,0,
219,4,88,1,1,3
219,4,88,1,15,
219,4,89,4,0,
219,4,92,4,0,
219,4,104,4,0,
219,4,106,1,22,
219,4,111,4,0,
219,4,123,1,1,1
219,4,126,4,0,
219,4,133,1,29,
219,4,156,4,0,
219,4,157,1,48,
219,4,173,4,0,
219,4,174,4,0,
219,4,182,4,0,
219,4,189,4,0,
219,4,203,4,0,
219,4,205,4,0,
219,4,207,4,0,
219,4,213,4,0,
219,4,214,4,0,
219,4,216,4,0,
219,4,218,4,0,
219,4,237,4,0,
219,4,241,4,0,
219,4,249,4,0,
219,5,34,1,60,
219,5,52,1,1,3
219,5,52,1,8,
219,5,53,1,36,
219,5,53,4,0,
219,5,63,4,0,
219,5,70,4,0,
219,5,88,1,1,4
219,5,88,1,15,
219,5,89,4,0,
219,5,92,4,0,
219,5,104,4,0,
219,5,106,1,22,
219,5,113,4,0,
219,5,115,4,0,
219,5,123,1,1,2
219,5,126,4,0,
219,5,133,1,29,
219,5,156,4,0,
219,5,157,1,48,
219,5,182,4,0,
219,5,201,4,0,
219,5,213,4,0,
219,5,216,4,0,
219,5,218,4,0,
219,5,237,4,0,
219,5,241,4,0,
219,5,249,4,0,
219,5,263,4,0,
219,5,281,1,1,1
219,5,290,4,0,
219,5,315,4,0,
219,5,317,4,0,
219,6,34,1,60,
219,6,34,3,0,
219,6,38,3,0,
219,6,52,1,1,3
219,6,52,1,8,
219,6,53,1,36,
219,6,53,4,0,
219,6,63,4,0,
219,6,70,4,0,
219,6,88,1,1,4
219,6,88,1,15,
219,6,89,4,0,
219,6,92,4,0,
219,6,102,3,0,
219,6,104,4,0,
219,6,106,1,22,
219,6,111,3,0,
219,6,113,4,0,
219,6,115,4,0,
219,6,123,1,1,2
219,6,126,4,0,
219,6,133,1,29,
219,6,156,4,0,
219,6,157,1,48,
219,6,157,3,0,
219,6,164,3,0,
219,6,173,3,0,
219,6,182,4,0,
219,6,189,3,0,
219,6,201,4,0,
219,6,203,3,0,
219,6,205,3,0,
219,6,207,3,0,
219,6,213,4,0,
219,6,214,3,0,
219,6,216,4,0,
219,6,218,4,0,
219,6,237,4,0,
219,6,241,4,0,
219,6,249,4,0,
219,6,263,4,0,
219,6,281,1,1,1
219,6,290,4,0,
219,6,315,4,0,
219,6,317,4,0,
219,7,34,1,60,
219,7,34,3,0,
219,7,38,3,0,
219,7,52,1,1,3
219,7,52,1,8,
219,7,53,1,36,
219,7,53,4,0,
219,7,63,4,0,
219,7,70,4,0,
219,7,88,1,1,4
219,7,88,1,15,
219,7,89,4,0,
219,7,92,4,0,
219,7,102,3,0,
219,7,104,4,0,
219,7,106,1,22,
219,7,113,4,0,
219,7,115,4,0,
219,7,123,1,1,2
219,7,126,4,0,
219,7,133,1,29,
219,7,156,4,0,
219,7,157,1,48,
219,7,157,3,0,
219,7,164,3,0,
219,7,182,4,0,
219,7,201,4,0,
219,7,213,4,0,
219,7,216,4,0,
219,7,218,4,0,
219,7,237,4,0,
219,7,241,4,0,
219,7,249,4,0,
219,7,263,4,0,
219,7,281,1,1,1
219,7,290,4,0,
219,7,315,4,0,
219,7,317,4,0,
219,8,34,1,52,
219,8,52,1,1,3
219,8,52,1,8,
219,8,53,1,61,
219,8,53,4,0,
219,8,63,4,0,
219,8,70,4,0,
219,8,76,4,0,
219,8,88,1,1,4
219,8,88,1,11,
219,8,89,4,0,
219,8,92,4,0,
219,8,104,4,0,
219,8,105,1,23,
219,8,106,1,16,
219,8,113,4,0,
219,8,115,4,0,
219,8,123,1,1,2
219,8,126,4,0,
219,8,133,1,31,
219,8,153,4,0,
219,8,156,4,0,
219,8,157,1,45,
219,8,157,4,0,
219,8,164,4,0,
219,8,182,4,0,
219,8,201,4,0,
219,8,203,4,0,
219,8,207,4,0,
219,8,213,4,0,
219,8,214,4,0,
219,8,216,4,0,
219,8,218,4,0,
219,8,237,4,0,
219,8,241,4,0,
219,8,246,1,26,
219,8,249,4,0,
219,8,261,4,0,
219,8,263,4,0,
219,8,281,1,1,1
219,8,290,4,0,
219,8,315,4,0,
219,8,317,4,0,
219,8,360,4,0,
219,8,363,4,0,
219,8,397,4,0,
219,8,414,1,66,
219,8,416,4,0,
219,8,436,1,40,
219,8,444,4,0,
219,8,445,4,0,
219,8,446,4,0,
219,9,34,1,52,
219,9,52,1,1,3
219,9,52,1,8,
219,9,53,1,61,
219,9,53,4,0,
219,9,63,4,0,
219,9,70,4,0,
219,9,76,4,0,
219,9,88,1,1,4
219,9,88,1,11,
219,9,89,4,0,
219,9,92,4,0,
219,9,104,4,0,
219,9,105,1,23,
219,9,106,1,16,
219,9,113,4,0,
219,9,115,4,0,
219,9,123,1,1,2
219,9,126,4,0,
219,9,133,1,31,
219,9,153,4,0,
219,9,156,4,0,
219,9,157,1,45,
219,9,157,4,0,
219,9,164,4,0,
219,9,173,3,0,
219,9,182,4,0,
219,9,189,3,0,
219,9,201,4,0,
219,9,203,4,0,
219,9,205,3,0,
219,9,207,4,0,
219,9,213,4,0,
219,9,214,4,0,
219,9,216,4,0,
219,9,218,4,0,
219,9,237,4,0,
219,9,241,4,0,
219,9,246,1,26,
219,9,246,3,0,
219,9,249,4,0,
219,9,257,3,0,
219,9,261,4,0,
219,9,263,4,0,
219,9,281,1,1,1
219,9,290,4,0,
219,9,315,4,0,
219,9,317,4,0,
219,9,334,3,0,
219,9,360,4,0,
219,9,363,4,0,
219,9,397,4,0,
219,9,414,1,66,
219,9,414,3,0,
219,9,416,4,0,
219,9,436,1,40,
219,9,444,4,0,
219,9,445,4,0,
219,9,446,4,0,
219,10,34,1,52,
219,10,52,1,1,3
219,10,52,1,8,
219,10,53,1,61,
219,10,53,4,0,
219,10,63,4,0,
219,10,70,4,0,
219,10,76,4,0,
219,10,88,1,1,4
219,10,88,1,11,
219,10,89,4,0,
219,10,92,4,0,
219,10,104,4,0,
219,10,105,1,23,
219,10,106,1,16,
219,10,113,4,0,
219,10,115,4,0,
219,10,123,1,1,2
219,10,126,4,0,
219,10,133,1,31,
219,10,153,4,0,
219,10,156,4,0,
219,10,157,1,45,
219,10,157,4,0,
219,10,164,4,0,
219,10,173,3,0,
219,10,182,4,0,
219,10,189,3,0,
219,10,201,4,0,
219,10,203,4,0,
219,10,205,3,0,
219,10,207,4,0,
219,10,213,4,0,
219,10,214,4,0,
219,10,216,4,0,
219,10,218,4,0,
219,10,220,3,0,
219,10,237,4,0,
219,10,241,4,0,
219,10,246,1,26,
219,10,246,3,0,
219,10,249,4,0,
219,10,257,3,0,
219,10,261,4,0,
219,10,263,4,0,
219,10,281,1,1,1
219,10,290,4,0,
219,10,315,4,0,
219,10,317,4,0,
219,10,334,3,0,
219,10,360,4,0,
219,10,363,4,0,
219,10,397,4,0,
219,10,414,1,66,
219,10,414,3,0,
219,10,416,4,0,
219,10,436,1,40,
219,10,444,4,0,
219,10,445,4,0,
219,10,446,4,0,
219,11,34,1,52,
219,11,52,1,1,3
219,11,52,1,5,
219,11,53,1,59,
219,11,53,4,0,
219,11,63,4,0,
219,11,70,4,0,
219,11,76,4,0,
219,11,88,1,1,4
219,11,88,1,10,
219,11,89,4,0,
219,11,92,4,0,
219,11,104,4,0,
219,11,105,1,19,
219,11,106,1,14,
219,11,113,4,0,
219,11,115,4,0,
219,11,123,1,1,2
219,11,126,4,0,
219,11,133,1,32,
219,11,153,4,0,
219,11,156,4,0,
219,11,157,1,44,
219,11,157,4,0,
219,11,164,4,0,
219,11,182,4,0,
219,11,201,4,0,
219,11,207,4,0,
219,11,213,4,0,
219,11,216,4,0,
219,11,218,4,0,
219,11,237,4,0,
219,11,241,4,0,
219,11,246,1,28,
219,11,249,4,0,
219,11,261,4,0,
219,11,263,4,0,
219,11,281,1,1,1
219,11,315,4,0,
219,11,317,4,0,
219,11,360,4,0,
219,11,397,4,0,
219,11,414,1,67,
219,11,416,4,0,
219,11,436,1,37,
219,11,444,4,0,
219,11,479,4,0,
219,11,481,1,23,
219,11,488,4,0,
219,11,496,4,0,
219,11,504,1,38,
219,11,510,4,0,
219,11,523,4,0,
219,12,34,1,60,
219,12,52,1,1,3
219,12,52,1,8,
219,12,53,1,36,
219,12,53,4,0,
219,12,63,4,0,
219,12,70,4,0,
219,12,88,1,1,4
219,12,88,1,15,
219,12,89,4,0,
219,12,92,4,0,
219,12,104,4,0,
219,12,106,1,22,
219,12,113,4,0,
219,12,115,4,0,
219,12,123,1,1,2
219,12,126,4,0,
219,12,133,1,29,
219,12,156,4,0,
219,12,157,1,48,
219,12,182,4,0,
219,12,201,4,0,
219,12,213,4,0,
219,12,216,4,0,
219,12,218,4,0,
219,12,237,4,0,
219,12,241,4,0,
219,12,249,4,0,
219,12,263,4,0,
219,12,281,1,1,1
219,12,290,4,0,
219,12,315,4,0,
219,12,317,4,0,
219,13,34,1,60,
219,13,34,3,0,
219,13,38,3,0,
219,13,52,1,1,3
219,13,52,1,8,
219,13,53,1,36,
219,13,53,4,0,
219,13,63,4,0,
219,13,70,4,0,
219,13,88,1,1,4
219,13,88,1,15,
219,13,89,4,0,
219,13,92,4,0,
219,13,102,3,0,
219,13,104,4,0,
219,13,106,1,22,
219,13,113,4,0,
219,13,115,4,0,
219,13,120,3,0,
219,13,123,1,1,2
219,13,126,4,0,
219,13,133,1,29,
219,13,156,4,0,
219,13,157,1,48,
219,13,164,3,0,
219,13,182,4,0,
219,13,201,4,0,
219,13,207,3,0,
219,13,213,4,0,
219,13,216,4,0,
219,13,218,4,0,
219,13,237,4,0,
219,13,241,4,0,
219,13,249,4,0,
219,13,263,4,0,
219,13,281,1,1,1
219,13,290,4,0,
219,13,315,4,0,
219,13,317,4,0,
219,14,34,1,52,
219,14,52,1,1,3
219,14,52,1,5,
219,14,53,1,59,
219,14,53,4,0,
219,14,63,4,0,
219,14,70,4,0,
219,14,76,4,0,
219,14,88,1,1,4
219,14,88,1,10,
219,14,89,4,0,
219,14,92,4,0,
219,14,104,4,0,
219,14,105,1,19,
219,14,106,1,14,
219,14,113,4,0,
219,14,115,4,0,
219,14,123,1,1,2
219,14,126,4,0,
219,14,133,1,32,
219,14,153,4,0,
219,14,156,4,0,
219,14,157,1,44,
219,14,157,4,0,
219,14,164,4,0,
219,14,173,3,0,
219,14,182,4,0,
219,14,201,4,0,
219,14,207,4,0,
219,14,213,4,0,
219,14,214,3,0,
219,14,216,4,0,
219,14,218,4,0,
219,14,220,3,0,
219,14,237,4,0,
219,14,241,4,0,
219,14,246,1,28,
219,14,249,4,0,
219,14,257,3,0,
219,14,261,4,0,
219,14,263,4,0,
219,14,281,1,1,1
219,14,315,4,0,
219,14,317,4,0,
219,14,334,3,0,
219,14,360,4,0,
219,14,397,4,0,
219,14,414,1,67,
219,14,414,3,0,
219,14,416,4,0,
219,14,436,1,37,
219,14,444,4,0,
219,14,446,3,0,
219,14,479,4,0,
219,14,481,1,23,
219,14,488,4,0,
219,14,495,3,0,
219,14,496,4,0,
219,14,504,1,38,
219,14,510,4,0,
219,14,523,4,0,
219,15,34,1,52,
219,15,52,1,1,4
219,15,52,1,5,
219,15,53,1,59,
219,15,53,4,0,
219,15,63,4,0,
219,15,70,4,0,
219,15,76,4,0,
219,15,88,1,1,5
219,15,88,1,10,
219,15,89,4,0,
219,15,92,4,0,
219,15,104,4,0,
219,15,105,1,19,
219,15,106,1,14,
219,15,113,4,0,
219,15,115,4,0,
219,15,123,1,1,3
219,15,126,4,0,
219,15,133,1,32,
219,15,153,4,0,
219,15,156,4,0,
219,15,157,1,44,
219,15,157,4,0,
219,15,164,4,0,
219,15,182,4,0,
219,15,201,4,0,
219,15,207,4,0,
219,15,213,4,0,
219,15,214,4,0,
219,15,216,4,0,
219,15,218,4,0,
219,15,237,4,0,
219,15,241,4,0,
219,15,246,1,28,
219,15,249,4,0,
219,15,261,4,0,
219,15,263,4,0,
219,15,267,4,0,
219,15,281,1,1,2
219,15,315,4,0,
219,15,317,4,0,
219,15,360,4,0,
219,15,397,4,0,
219,15,414,1,1,1
219,15,414,1,67,
219,15,416,4,0,
219,15,436,1,37,
219,15,444,4,0,
219,15,479,4,0,
219,15,481,1,23,
219,15,488,4,0,
219,15,496,4,0,
219,15,504,1,38,
219,15,510,4,0,
219,15,523,4,0,
219,15,590,4,0,
219,15,611,4,0,
219,16,34,1,43,1
219,16,52,1,1,4
219,16,52,1,6,1
219,16,53,1,54,1
219,16,53,4,0,
219,16,63,4,0,
219,16,70,4,0,
219,16,76,4,0,
219,16,88,1,1,5
219,16,88,1,8,1
219,16,89,4,0,
219,16,92,4,0,
219,16,104,4,0,
219,16,105,1,47,1
219,16,106,1,13,1
219,16,113,4,0,
219,16,115,4,0,
219,16,123,1,1,3
219,16,126,4,0,
219,16,133,1,36,1
219,16,153,4,0,
219,16,156,4,0,
219,16,157,1,29,1
219,16,157,4,0,
219,16,164,4,0,
219,16,173,3,0,
219,16,182,4,0,
219,16,201,4,0,
219,16,207,4,0,
219,16,213,4,0,
219,16,214,4,0,
219,16,216,4,0,
219,16,218,4,0,
219,16,220,3,0,
219,16,237,4,0,
219,16,241,4,0,
219,16,246,1,22,1
219,16,249,4,0,
219,16,257,3,0,
219,16,261,4,0,
219,16,263,4,0,
219,16,267,4,0,
219,16,281,1,1,2
219,16,290,4,0,
219,16,315,4,0,
219,16,317,4,0,
219,16,334,3,0,
219,16,360,4,0,
219,16,397,4,0,
219,16,414,1,1,1
219,16,414,1,58,1
219,16,414,3,0,
219,16,416,4,0,
219,16,436,1,34,1
219,16,444,4,0,
219,16,446,3,0,
219,16,479,4,0,
219,16,481,1,27,1
219,16,488,4,0,
219,16,495,3,0,
219,16,496,4,0,
219,16,499,1,20,1
219,16,504,1,38,1
219,16,510,1,15,1
219,16,510,4,0,
219,16,523,4,0,
219,16,590,4,0,
219,16,611,4,0,
219,17,34,1,43,
219,17,52,1,1,5
219,17,52,1,6,
219,17,53,1,54,
219,17,53,4,0,
219,17,63,4,0,
219,17,76,4,0,
219,17,88,1,1,6
219,17,88,1,8,
219,17,89,4,0,
219,17,92,4,0,
219,17,104,4,0,
219,17,105,1,47,
219,17,106,1,13,
219,17,113,4,0,
219,17,115,4,0,
219,17,123,1,1,4
219,17,126,4,0,
219,17,133,1,36,
219,17,153,4,0,
219,17,156,4,0,
219,17,157,1,29,
219,17,157,4,0,
219,17,164,4,0,
219,17,182,4,0,
219,17,201,4,0,
219,17,207,4,0,
219,17,213,4,0,
219,17,214,4,0,
219,17,216,4,0,
219,17,218,4,0,
219,17,237,4,0,
219,17,241,4,0,
219,17,246,1,22,
219,17,261,4,0,
219,17,263,4,0,
219,17,267,4,0,
219,17,281,1,1,3
219,17,315,4,0,
219,17,317,4,0,
219,17,360,4,0,
219,17,397,4,0,
219,17,414,1,1,2
219,17,414,1,58,
219,17,416,4,0,
219,17,436,1,34,
219,17,444,4,0,
219,17,479,4,0,
219,17,481,1,27,
219,17,488,4,0,
219,17,496,4,0,
219,17,499,1,20,
219,17,504,1,0,
219,17,504,1,1,1
219,17,510,1,15,
219,17,523,4,0,
219,17,590,4,0,
219,17,611,4,0,
219,18,34,1,43,
219,18,52,1,1,5
219,18,52,1,6,
219,18,53,1,54,
219,18,53,4,0,
219,18,63,4,0,
219,18,76,4,0,
219,18,88,1,1,6
219,18,88,1,8,
219,18,89,4,0,
219,18,92,4,0,
219,18,104,4,0,
219,18,105,1,47,
219,18,106,1,13,
219,18,113,4,0,
219,18,115,4,0,
219,18,123,1,1,4
219,18,126,4,0,
219,18,133,1,36,
219,18,153,4,0,
219,18,156,4,0,
219,18,157,1,29,
219,18,157,4,0,
219,18,164,4,0,
219,18,182,4,0,
219,18,201,4,0,
219,18,207,4,0,
219,18,213,4,0,
219,18,214,4,0,
219,18,216,4,0,
219,18,218,4,0,
219,18,237,4,0,
219,18,241,4,0,
219,18,246,1,22,
219,18,261,4,0,
219,18,263,4,0,
219,18,267,4,0,
219,18,281,1,1,3
219,18,315,4,0,
219,18,317,4,0,
219,18,360,4,0,
219,18,397,4,0,
219,18,414,1,1,2
219,18,414,1,58,
219,18,416,4,0,
219,18,436,1,34,
219,18,444,4,0,
219,18,479,4,0,
219,18,481,1,27,
219,18,488,4,0,
219,18,496,4,0,
219,18,499,1,20,
219,18,504,1,0,
219,18,504,1,1,1
219,18,510,1,15,
219,18,523,4,0,
219,18,590,4,0,
219,18,611,4,0,
220,3,29,4,0,
220,3,33,1,1,
220,3,34,2,0,
220,3,36,1,28,
220,3,36,2,0,
220,3,44,2,0,
220,3,46,4,0,
220,3,54,1,37,
220,3,59,1,46,
220,3,59,4,0,
220,3,70,4,0,
220,3,89,4,0,
220,3,92,4,0,
220,3,104,4,0,
220,3,111,4,0,
220,3,156,4,0,
220,3,157,2,0,
220,3,173,4,0,
220,3,174,4,0,
220,3,181,1,10,
220,3,182,4,0,
220,3,189,4,0,
220,3,196,4,0,
220,3,197,4,0,
220,3,203,1,19,
220,3,203,4,0,
220,3,207,4,0,
220,3,213,4,0,
220,3,214,4,0,
220,3,216,4,0,
220,3,218,4,0,
220,3,237,4,0,
220,3,240,4,0,
220,3,246,2,0,
220,3,249,4,0,
220,4,29,4,0,
220,4,33,1,1,
220,4,34,2,0,
220,4,36,1,28,
220,4,36,2,0,
220,4,44,2,0,
220,4,46,4,0,
220,4,54,1,37,
220,4,58,3,0,
220,4,59,1,46,
220,4,59,4,0,
220,4,70,4,0,
220,4,89,4,0,
220,4,92,4,0,
220,4,104,4,0,
220,4,111,4,0,
220,4,133,1,55,
220,4,156,4,0,
220,4,157,2,0,
220,4,173,4,0,
220,4,174,4,0,
220,4,181,1,10,
220,4,182,4,0,
220,4,189,4,0,
220,4,196,4,0,
220,4,197,4,0,
220,4,203,1,19,
220,4,203,4,0,
220,4,207,4,0,
220,4,213,4,0,
220,4,214,4,0,
220,4,216,4,0,
220,4,218,4,0,
220,4,237,4,0,
220,4,240,4,0,
220,4,246,2,0,
220,4,249,4,0,
220,5,33,1,1,1
220,5,34,2,0,
220,5,36,1,28,
220,5,36,2,0,
220,5,38,2,0,
220,5,44,2,0,
220,5,46,4,0,
220,5,54,1,37,
220,5,58,4,0,
220,5,59,1,46,
220,5,59,4,0,
220,5,70,4,0,
220,5,89,4,0,
220,5,91,4,0,
220,5,92,4,0,
220,5,104,4,0,
220,5,113,4,0,
220,5,115,4,0,
220,5,133,1,55,
220,5,156,4,0,
220,5,157,2,0,
220,5,181,1,10,
220,5,182,4,0,
220,5,201,4,0,
220,5,203,1,19,
220,5,213,4,0,
220,5,216,4,0,
220,5,218,4,0,
220,5,237,4,0,
220,5,240,4,0,
220,5,246,2,0,
220,5,249,4,0,
220,5,258,4,0,
220,5,263,4,0,
220,5,290,4,0,
220,5,316,1,1,2
220,5,317,4,0,
220,5,333,2,0,
220,5,341,2,0,
220,6,33,1,1,1
220,6,34,2,0,
220,6,34,3,0,
220,6,36,1,28,
220,6,36,2,0,
220,6,38,2,0,
220,6,38,3,0,
220,6,44,2,0,
220,6,46,4,0,
220,6,54,1,37,
220,6,58,4,0,
220,6,59,1,46,
220,6,59,4,0,
220,6,70,4,0,
220,6,89,4,0,
220,6,91,4,0,
220,6,92,4,0,
220,6,102,3,0,
220,6,104,4,0,
220,6,111,3,0,
220,6,113,4,0,
220,6,115,4,0,
220,6,133,1,55,
220,6,156,4,0,
220,6,157,2,0,
220,6,157,3,0,
220,6,164,3,0,
220,6,173,3,0,
220,6,181,1,10,
220,6,182,4,0,
220,6,189,3,0,
220,6,196,3,0,
220,6,201,4,0,
220,6,203,1,19,
220,6,203,3,0,
220,6,207,3,0,
220,6,213,4,0,
220,6,214,3,0,
220,6,216,4,0,
220,6,218,4,0,
220,6,237,4,0,
220,6,240,4,0,
220,6,246,2,0,
220,6,249,4,0,
220,6,258,4,0,
220,6,263,4,0,
220,6,290,4,0,
220,6,316,1,1,2
220,6,317,4,0,
220,6,333,2,0,
220,6,341,2,0,
220,7,33,1,1,1
220,7,34,2,0,
220,7,34,3,0,
220,7,36,1,28,
220,7,36,2,0,
220,7,38,2,0,
220,7,38,3,0,
220,7,44,2,0,
220,7,46,4,0,
220,7,54,1,37,
220,7,58,4,0,
220,7,59,1,46,
220,7,59,4,0,
220,7,70,4,0,
220,7,89,4,0,
220,7,91,4,0,
220,7,92,4,0,
220,7,102,3,0,
220,7,104,4,0,
220,7,113,4,0,
220,7,115,4,0,
220,7,133,1,55,
220,7,156,4,0,
220,7,157,2,0,
220,7,157,3,0,
220,7,164,3,0,
220,7,181,1,10,
220,7,182,4,0,
220,7,201,4,0,
220,7,203,1,19,
220,7,213,4,0,
220,7,216,4,0,
220,7,218,4,0,
220,7,237,4,0,
220,7,240,4,0,
220,7,246,2,0,
220,7,249,4,0,
220,7,258,4,0,
220,7,263,4,0,
220,7,290,4,0,
220,7,316,1,1,2
220,7,317,4,0,
220,7,333,2,0,
220,7,341,2,0,
220,8,33,1,1,1
220,8,34,2,0,
220,8,36,1,32,
220,8,36,2,0,
220,8,38,2,0,
220,8,44,2,0,
220,8,46,4,0,
220,8,54,1,40,
220,8,58,4,0,
220,8,59,1,44,
220,8,59,4,0,
220,8,70,4,0,
220,8,89,1,37,
220,8,89,4,0,
220,8,90,2,0,
220,8,91,4,0,
220,8,92,4,0,
220,8,104,4,0,
220,8,113,4,0,
220,8,115,4,0,
220,8,133,1,49,
220,8,156,4,0,
220,8,157,2,0,
220,8,157,4,0,
220,8,164,4,0,
220,8,174,2,0,
220,8,181,1,8,
220,8,182,4,0,
220,8,189,1,13,
220,8,196,1,25,
220,8,201,4,0,
220,8,203,1,16,
220,8,203,4,0,
220,8,207,4,0,
220,8,213,4,0,
220,8,214,4,0,
220,8,216,4,0,
220,8,218,4,0,
220,8,237,4,0,
220,8,240,4,0,
220,8,246,2,0,
220,8,249,4,0,
220,8,258,4,0,
220,8,263,4,0,
220,8,290,4,0,
220,8,300,1,4,
220,8,316,1,1,2
220,8,317,4,0,
220,8,333,2,0,
220,8,341,2,0,
220,8,363,4,0,
220,8,420,1,28,
220,8,426,1,20,
220,8,445,4,0,
220,8,446,4,0,
220,9,33,1,1,1
220,9,34,2,0,
220,9,36,1,32,
220,9,36,2,0,
220,9,38,2,0,
220,9,44,2,0,
220,9,46,4,0,
220,9,54,1,40,
220,9,58,4,0,
220,9,59,1,44,
220,9,59,4,0,
220,9,70,4,0,
220,9,89,1,37,
220,9,89,4,0,
220,9,90,2,0,
220,9,91,4,0,
220,9,92,4,0,
220,9,104,4,0,
220,9,113,4,0,
220,9,115,4,0,
220,9,133,1,49,
220,9,156,4,0,
220,9,157,2,0,
220,9,157,4,0,
220,9,164,4,0,
220,9,173,3,0,
220,9,174,2,0,
220,9,181,1,8,
220,9,182,4,0,
220,9,189,1,13,
220,9,189,3,0,
220,9,196,1,25,
220,9,196,3,0,
220,9,201,4,0,
220,9,203,1,16,
220,9,203,4,0,
220,9,207,4,0,
220,9,213,4,0,
220,9,214,4,0,
220,9,216,4,0,
220,9,218,4,0,
220,9,237,4,0,
220,9,240,4,0,
220,9,246,2,0,
220,9,246,3,0,
220,9,249,4,0,
220,9,258,4,0,
220,9,263,4,0,
220,9,276,3,0,
220,9,283,3,0,
220,9,290,4,0,
220,9,300,1,4,
220,9,316,1,1,2
220,9,317,4,0,
220,9,333,2,0,
220,9,341,2,0,
220,9,363,4,0,
220,9,414,3,0,
220,9,420,1,28,
220,9,426,1,20,
220,9,445,4,0,
220,9,446,4,0,
220,10,29,3,0,
220,10,33,1,1,1
220,10,34,2,0,
220,10,36,1,32,
220,10,36,2,0,
220,10,38,2,0,
220,10,44,2,0,
220,10,46,4,0,
220,10,54,1,40,
220,10,58,4,0,
220,10,59,1,44,
220,10,59,4,0,
220,10,70,4,0,
220,10,89,1,37,
220,10,89,4,0,
220,10,90,2,0,
220,10,91,4,0,
220,10,92,4,0,
220,10,104,4,0,
220,10,113,4,0,
220,10,115,4,0,
220,10,133,1,49,
220,10,156,4,0,
220,10,157,2,0,
220,10,157,4,0,
220,10,164,4,0,
220,10,173,3,0,
220,10,174,2,0,
220,10,181,1,8,
220,10,182,4,0,
220,10,189,1,13,
220,10,189,3,0,
220,10,196,1,25,
220,10,196,3,0,
220,10,201,4,0,
220,10,203,1,16,
220,10,203,4,0,
220,10,207,4,0,
220,10,213,4,0,
220,10,214,4,0,
220,10,216,4,0,
220,10,218,4,0,
220,10,237,4,0,
220,10,240,4,0,
220,10,246,2,0,
220,10,246,3,0,
220,10,249,4,0,
220,10,258,4,0,
220,10,263,4,0,
220,10,276,3,0,
220,10,283,3,0,
220,10,290,4,0,
220,10,300,1,4,
220,10,316,1,1,2
220,10,317,4,0,
220,10,333,2,0,
220,10,341,2,0,
220,10,363,4,0,
220,10,414,3,0,
220,10,420,1,28,
220,10,426,1,20,
220,10,445,4,0,
220,10,446,4,0,
220,11,33,1,1,1
220,11,34,2,0,
220,11,36,1,32,
220,11,36,2,0,
220,11,38,2,0,
220,11,44,2,0,
220,11,46,4,0,
220,11,54,1,40,
220,11,58,4,0,
220,11,59,1,44,
220,11,59,4,0,
220,11,70,4,0,
220,11,89,1,37,
220,11,89,4,0,
220,11,90,2,0,
220,11,91,4,0,
220,11,92,4,0,
220,11,104,4,0,
220,11,113,4,0,
220,11,115,4,0,
220,11,133,1,49,
220,11,156,4,0,
220,11,157,4,0,
220,11,164,4,0,
220,11,174,2,0,
220,11,181,1,8,
220,11,182,4,0,
220,11,189,1,13,
220,11,196,1,25,
220,11,201,4,0,
220,11,203,1,16,
220,11,207,4,0,
220,11,213,4,0,
220,11,216,4,0,
220,11,218,4,0,
220,11,237,4,0,
220,11,240,4,0,
220,11,246,2,0,
220,11,249,4,0,
220,11,258,4,0,
220,11,263,4,0,
220,11,300,1,4,
220,11,316,1,1,2
220,11,317,4,0,
220,11,333,2,0,
220,11,341,2,0,
220,11,419,2,0,
220,11,420,1,28,
220,11,426,1,20,
220,11,446,2,0,
220,11,496,4,0,
220,11,523,4,0,
220,11,556,2,0,
220,12,33,1,1,1
220,12,36,1,28,
220,12,46,4,0,
220,12,54,1,37,
220,12,58,4,0,
220,12,59,1,46,
220,12,59,4,0,
220,12,70,4,0,
220,12,89,4,0,
220,12,91,4,0,
220,12,92,4,0,
220,12,104,4,0,
220,12,113,4,0,
220,12,115,4,0,
220,12,133,1,55,
220,12,156,4,0,
220,12,181,1,10,
220,12,182,4,0,
220,12,201,4,0,
220,12,203,1,19,
220,12,213,4,0,
220,12,216,4,0,
220,12,218,4,0,
220,12,237,4,0,
220,12,240,4,0,
220,12,249,4,0,
220,12,258,4,0,
220,12,263,4,0,
220,12,290,4,0,
220,12,316,1,1,2
220,12,317,4,0,
220,13,33,1,1,1
220,13,34,3,0,
220,13,36,1,28,
220,13,38,3,0,
220,13,46,4,0,
220,13,54,1,37,
220,13,58,4,0,
220,13,59,1,46,
220,13,59,4,0,
220,13,70,4,0,
220,13,89,4,0,
220,13,91,4,0,
220,13,92,4,0,
220,13,102,3,0,
220,13,104,4,0,
220,13,113,4,0,
220,13,115,4,0,
220,13,133,1,55,
220,13,156,4,0,
220,13,164,3,0,
220,13,181,1,10,
220,13,182,4,0,
220,13,196,3,0,
220,13,201,4,0,
220,13,203,1,19,
220,13,207,3,0,
220,13,213,4,0,
220,13,216,4,0,
220,13,218,4,0,
220,13,237,4,0,
220,13,240,4,0,
220,13,249,4,0,
220,13,258,4,0,
220,13,263,4,0,
220,13,290,4,0,
220,13,316,1,1,2
220,13,317,4,0,
220,14,33,1,1,1
220,14,34,2,0,
220,14,36,1,28,
220,14,36,2,0,
220,14,38,2,0,
220,14,44,2,0,
220,14,46,4,0,
220,14,54,1,35,
220,14,58,4,0,
220,14,59,1,44,
220,14,59,4,0,
220,14,70,4,0,
220,14,89,1,37,
220,14,89,4,0,
220,14,90,2,0,
220,14,91,4,0,
220,14,92,4,0,
220,14,104,4,0,
220,14,113,4,0,
220,14,115,4,0,
220,14,133,1,48,
220,14,156,4,0,
220,14,157,4,0,
220,14,164,4,0,
220,14,173,3,0,
220,14,174,2,0,
220,14,175,1,40,
220,14,181,1,8,
220,14,182,4,0,
220,14,189,1,11,
220,14,196,1,21,
220,14,196,3,0,
220,14,201,4,0,
220,14,203,1,14,
220,14,207,4,0,
220,14,213,4,0,
220,14,214,3,0,
220,14,216,4,0,
220,14,218,4,0,
220,14,237,4,0,
220,14,240,4,0,
220,14,246,2,0,
220,14,249,4,0,
220,14,258,4,0,
220,14,263,4,0,
220,14,276,3,0,
220,14,283,3,0,
220,14,300,1,5,
220,14,316,1,1,2
220,14,317,4,0,
220,14,333,2,0,
220,14,341,2,0,
220,14,414,3,0,
220,14,419,2,0,
220,14,420,1,24,
220,14,426,1,18,
220,14,446,2,0,
220,14,446,3,0,
220,14,496,4,0,
220,14,523,4,0,
220,14,556,2,0,
220,15,33,1,1,1
220,15,34,2,0,
220,15,36,1,28,
220,15,36,2,0,
220,15,38,2,0,
220,15,44,2,0,
220,15,46,4,0,
220,15,54,1,35,
220,15,58,4,0,
220,15,59,1,44,
220,15,59,4,0,
220,15,70,4,0,
220,15,89,1,37,
220,15,89,4,0,
220,15,90,2,0,
220,15,91,4,0,
220,15,92,4,0,
220,15,104,4,0,
220,15,113,4,0,
220,15,115,4,0,
220,15,133,1,48,
220,15,156,4,0,
220,15,157,4,0,
220,15,164,4,0,
220,15,174,2,0,
220,15,175,1,40,
220,15,181,1,8,
220,15,182,4,0,
220,15,189,1,11,
220,15,196,1,21,
220,15,201,4,0,
220,15,203,1,14,
220,15,207,4,0,
220,15,213,4,0,
220,15,214,4,0,
220,15,216,4,0,
220,15,218,4,0,
220,15,237,4,0,
220,15,240,4,0,
220,15,246,2,0,
220,15,249,4,0,
220,15,258,4,0,
220,15,263,4,0,
220,15,300,1,5,
220,15,316,1,1,2
220,15,317,4,0,
220,15,333,2,0,
220,15,341,2,0,
220,15,419,2,0,
220,15,420,1,24,
220,15,426,1,18,
220,15,446,2,0,
220,15,496,4,0,
220,15,523,4,0,
220,15,556,2,0,
220,15,573,2,0,
220,15,590,4,0,
220,16,33,1,1,1
220,16,34,2,0,
220,16,36,1,28,1
220,16,36,2,0,
220,16,38,2,0,
220,16,44,2,0,
220,16,46,4,0,
220,16,54,1,35,1
220,16,58,4,0,
220,16,59,1,44,1
220,16,59,4,0,
220,16,70,4,0,
220,16,89,1,37,1
220,16,89,4,0,
220,16,90,2,0,
220,16,91,4,0,
220,16,92,4,0,
220,16,104,4,0,
220,16,113,4,0,
220,16,115,4,0,
220,16,133,1,48,1
220,16,156,4,0,
220,16,157,4,0,
220,16,164,4,0,
220,16,173,3,0,
220,16,174,2,0,
220,16,175,1,40,1
220,16,181,1,8,1
220,16,182,4,0,
220,16,189,1,11,1
220,16,196,1,21,1
220,16,196,3,0,
220,16,201,4,0,
220,16,203,1,14,1
220,16,207,4,0,
220,16,213,4,0,
220,16,214,4,0,
220,16,216,4,0,
220,16,218,4,0,
220,16,237,4,0,
220,16,240,4,0,
220,16,246,2,0,
220,16,249,4,0,
220,16,258,4,0,
220,16,263,4,0,
220,16,276,3,0,
220,16,283,3,0,
220,16,290,4,0,
220,16,300,1,5,1
220,16,316,1,1,2
220,16,317,4,0,
220,16,333,2,0,
220,16,341,2,0,
220,16,414,3,0,
220,16,419,2,0,
220,16,420,1,24,1
220,16,426,1,18,1
220,16,446,2,0,
220,16,446,3,0,
220,16,496,4,0,
220,16,523,4,0,
220,16,556,2,0,
220,16,573,2,0,
220,16,590,4,0,
220,17,33,1,1,1
220,17,34,2,0,
220,17,36,1,28,
220,17,36,2,0,
220,17,38,2,0,
220,17,44,2,0,
220,17,46,4,0,
220,17,54,1,35,
220,17,58,4,0,
220,17,59,1,44,
220,17,59,4,0,
220,17,89,1,37,
220,17,89,4,0,
220,17,90,2,0,
220,17,92,4,0,
220,17,104,4,0,
220,17,113,4,0,
220,17,115,4,0,
220,17,133,1,48,
220,17,156,4,0,
220,17,157,4,0,
220,17,164,4,0,
220,17,174,2,0,
220,17,175,1,40,
220,17,181,1,8,
220,17,182,4,0,
220,17,189,1,11,
220,17,196,1,21,
220,17,201,4,0,
220,17,203,1,14,
220,17,207,4,0,
220,17,213,4,0,
220,17,214,4,0,
220,17,216,4,0,
220,17,218,4,0,
220,17,237,4,0,
220,17,240,4,0,
220,17,246,2,0,
220,17,258,4,0,
220,17,263,4,0,
220,17,300,1,5,
220,17,316,1,1,2
220,17,317,4,0,
220,17,333,2,0,
220,17,341,2,0,
220,17,419,2,0,
220,17,420,1,24,
220,17,426,1,18,
220,17,446,2,0,
220,17,496,4,0,
220,17,523,4,0,
220,17,556,2,0,
220,17,573,2,0,
220,17,590,4,0,
220,18,33,1,1,1
220,18,34,2,0,
220,18,36,1,28,
220,18,36,2,0,
220,18,38,2,0,
220,18,44,2,0,
220,18,46,4,0,
220,18,54,1,35,
220,18,58,4,0,
220,18,59,1,44,
220,18,59,4,0,
220,18,89,1,37,
220,18,89,4,0,
220,18,90,2,0,
220,18,92,4,0,
220,18,104,4,0,
220,18,113,4,0,
220,18,115,4,0,
220,18,133,1,48,
220,18,156,4,0,
220,18,157,4,0,
220,18,164,4,0,
220,18,174,2,0,
220,18,175,1,40,
220,18,181,1,8,
220,18,182,4,0,
220,18,189,1,11,
220,18,196,1,21,
220,18,201,4,0,
220,18,203,1,14,
220,18,207,4,0,
220,18,213,4,0,
220,18,214,4,0,
220,18,216,4,0,
220,18,218,4,0,
220,18,237,4,0,
220,18,240,4,0,
220,18,246,2,0,
220,18,258,4,0,
220,18,263,4,0,
220,18,300,1,5,
220,18,316,1,1,2
220,18,317,4,0,
220,18,333,2,0,
220,18,341,2,0,
220,18,419,2,0,
220,18,420,1,24,
220,18,426,1,18,
220,18,446,2,0,
220,18,496,4,0,
220,18,523,4,0,
220,18,556,2,0,
220,18,573,2,0,
220,18,590,4,0,
221,3,29,4,0,
221,3,30,1,1,1
221,3,31,1,33,
221,3,36,1,28,
221,3,46,4,0,
221,3,54,1,42,
221,3,59,1,56,
221,3,59,4,0,
221,3,63,4,0,
221,3,70,4,0,
221,3,89,4,0,
221,3,92,4,0,
221,3,104,4,0,
221,3,111,4,0,
221,3,156,4,0,
221,3,173,4,0,
221,3,174,4,0,
221,3,181,1,1,2
221,3,181,1,10,
221,3,182,4,0,
221,3,189,4,0,
221,3,196,4,0,
221,3,197,4,0,
221,3,203,1,1,3
221,3,203,1,19,
221,3,203,4,0,
221,3,207,4,0,
221,3,213,4,0,
221,3,214,4,0,
221,3,216,4,0,
221,3,218,4,0,
221,3,237,4,0,
221,3,240,4,0,
221,3,249,4,0,
221,4,29,4,0,
221,4,30,1,1,1
221,4,31,1,33,
221,4,36,1,28,
221,4,46,4,0,
221,4,54,1,42,
221,4,58,3,0,
221,4,59,1,56,
221,4,59,4,0,
221,4,63,4,0,
221,4,70,4,0,
221,4,89,4,0,
221,4,92,4,0,
221,4,104,4,0,
221,4,111,4,0,
221,4,133,1,70,
221,4,156,4,0,
221,4,173,4,0,
221,4,174,4,0,
221,4,181,1,1,2
221,4,181,1,10,
221,4,182,4,0,
221,4,189,4,0,
221,4,196,4,0,
221,4,197,4,0,
221,4,203,1,1,3
221,4,203,1,19,
221,4,203,4,0,
221,4,207,4,0,
221,4,213,4,0,
221,4,214,4,0,
221,4,216,4,0,
221,4,218,4,0,
221,4,237,4,0,
221,4,240,4,0,
221,4,249,4,0,
221,5,30,1,1,1
221,5,31,1,33,
221,5,36,1,28,
221,5,46,4,0,
221,5,54,1,42,
221,5,58,4,0,
221,5,59,1,56,
221,5,59,4,0,
221,5,63,4,0,
221,5,70,4,0,
221,5,89,4,0,
221,5,91,4,0,
221,5,92,4,0,
221,5,104,4,0,
221,5,113,4,0,
221,5,115,4,0,
221,5,133,1,70,
221,5,156,4,0,
221,5,181,1,1,3
221,5,181,1,10,
221,5,182,4,0,
221,5,201,4,0,
221,5,203,1,1,4
221,5,203,1,19,
221,5,213,4,0,
221,5,216,4,0,
221,5,218,4,0,
221,5,237,4,0,
221,5,240,4,0,
221,5,249,4,0,
221,5,258,4,0,
221,5,263,4,0,
221,5,290,4,0,
221,5,316,1,1,2
221,5,317,4,0,
221,6,30,1,1,1
221,6,31,1,33,
221,6,34,3,0,
221,6,36,1,28,
221,6,38,3,0,
221,6,46,4,0,
221,6,54,1,42,
221,6,58,4,0,
221,6,59,1,56,
221,6,59,4,0,
221,6,63,4,0,
221,6,70,4,0,
221,6,89,4,0,
221,6,91,4,0,
221,6,92,4,0,
221,6,102,3,0,
221,6,104,4,0,
221,6,111,3,0,
221,6,113,4,0,
221,6,115,4,0,
221,6,133,1,70,
221,6,156,4,0,
221,6,157,3,0,
221,6,164,3,0,
221,6,173,3,0,
221,6,181,1,1,3
221,6,181,1,10,
221,6,182,4,0,
221,6,189,3,0,
221,6,196,3,0,
221,6,201,4,0,
221,6,203,1,1,4
221,6,203,1,19,
221,6,203,3,0,
221,6,207,3,0,
221,6,213,4,0,
221,6,214,3,0,
221,6,216,4,0,
221,6,218,4,0,
221,6,237,4,0,
221,6,240,4,0,
221,6,249,4,0,
221,6,258,4,0,
221,6,263,4,0,
221,6,290,4,0,
221,6,316,1,1,2
221,6,317,4,0,
221,7,30,1,1,1
221,7,31,1,33,
221,7,34,3,0,
221,7,36,1,28,
221,7,38,3,0,
221,7,46,4,0,
221,7,54,1,42,
221,7,58,4,0,
221,7,59,1,56,
221,7,59,4,0,
221,7,63,4,0,
221,7,70,4,0,
221,7,89,4,0,
221,7,91,4,0,
221,7,92,4,0,
221,7,102,3,0,
221,7,104,4,0,
221,7,113,4,0,
221,7,115,4,0,
221,7,133,1,70,
221,7,156,4,0,
221,7,157,3,0,
221,7,164,3,0,
221,7,181,1,1,3
221,7,181,1,10,
221,7,182,4,0,
221,7,201,4,0,
221,7,203,1,1,4
221,7,203,1,19,
221,7,213,4,0,
221,7,216,4,0,
221,7,218,4,0,
221,7,237,4,0,
221,7,240,4,0,
221,7,249,4,0,
221,7,258,4,0,
221,7,263,4,0,
221,7,290,4,0,
221,7,316,1,1,2
221,7,317,4,0,
221,8,31,1,33,
221,8,36,1,32,
221,8,46,4,0,
221,8,54,1,48,
221,8,58,4,0,
221,8,59,1,56,
221,8,59,4,0,
221,8,63,4,0,
221,8,64,1,1,2
221,8,70,4,0,
221,8,89,1,40,
221,8,89,4,0,
221,8,91,4,0,
221,8,92,4,0,
221,8,104,4,0,
221,8,113,4,0,
221,8,115,4,0,
221,8,133,1,65,
221,8,156,4,0,
221,8,157,4,0,
221,8,164,4,0,
221,8,181,1,1,5
221,8,181,1,8,
221,8,182,4,0,
221,8,189,1,13,
221,8,196,1,25,
221,8,201,4,0,
221,8,203,1,16,
221,8,203,4,0,
221,8,207,4,0,
221,8,213,4,0,
221,8,214,4,0,
221,8,216,4,0,
221,8,218,4,0,
221,8,237,4,0,
221,8,240,4,0,
221,8,246,1,1,1
221,8,249,4,0,
221,8,258,4,0,
221,8,263,4,0,
221,8,290,4,0,
221,8,300,1,1,4
221,8,300,1,4,
221,8,316,1,1,3
221,8,317,4,0,
221,8,363,4,0,
221,8,416,4,0,
221,8,419,4,0,
221,8,423,1,28,
221,8,426,1,20,
221,8,444,4,0,
221,8,445,4,0,
221,8,446,4,0,
221,9,31,1,33,
221,9,36,1,32,
221,9,46,4,0,
221,9,54,1,48,
221,9,58,4,0,
221,9,59,1,56,
221,9,59,4,0,
221,9,63,4,0,
221,9,64,1,1,2
221,9,70,4,0,
221,9,89,1,40,
221,9,89,4,0,
221,9,91,4,0,
221,9,92,4,0,
221,9,104,4,0,
221,9,113,4,0,
221,9,115,4,0,
221,9,133,1,65,
221,9,156,4,0,
221,9,157,4,0,
221,9,164,4,0,
221,9,173,3,0,
221,9,181,1,1,5
221,9,181,1,8,
221,9,182,4,0,
221,9,189,1,13,
221,9,189,3,0,
221,9,196,1,25,
221,9,196,3,0,
221,9,201,4,0,
221,9,203,1,16,
221,9,203,4,0,
221,9,207,4,0,
221,9,213,4,0,
221,9,214,4,0,
221,9,216,4,0,
221,9,218,4,0,
221,9,237,4,0,
221,9,240,4,0,
221,9,246,1,1,1
221,9,246,3,0,
221,9,249,4,0,
221,9,258,4,0,
221,9,263,4,0,
221,9,276,3,0,
221,9,283,3,0,
221,9,290,4,0,
221,9,300,1,1,4
221,9,300,1,4,
221,9,316,1,1,3
221,9,317,4,0,
221,9,363,4,0,
221,9,414,3,0,
221,9,416,4,0,
221,9,419,4,0,
221,9,423,1,28,
221,9,426,1,20,
221,9,444,4,0,
221,9,445,4,0,
221,9,446,4,0,
221,10,29,3,0,
221,10,31,1,33,
221,10,36,1,32,
221,10,46,4,0,
221,10,54,1,48,
221,10,58,4,0,
221,10,59,1,56,
221,10,59,4,0,
221,10,63,4,0,
221,10,64,1,1,2
221,10,70,4,0,
221,10,89,1,40,
221,10,89,4,0,
221,10,91,4,0,
221,10,92,4,0,
221,10,104,4,0,
221,10,113,4,0,
221,10,115,4,0,
221,10,133,1,65,
221,10,156,4,0,
221,10,157,4,0,
221,10,164,4,0,
221,10,173,3,0,
221,10,181,1,1,5
221,10,181,1,8,
221,10,182,4,0,
221,10,189,1,13,
221,10,189,3,0,
221,10,196,1,25,
221,10,196,3,0,
221,10,201,4,0,
221,10,203,1,16,
221,10,203,4,0,
221,10,207,4,0,
221,10,213,4,0,
221,10,214,4,0,
221,10,216,4,0,
221,10,218,4,0,
221,10,237,4,0,
221,10,240,4,0,
221,10,246,1,1,1
221,10,246,3,0,
221,10,249,4,0,
221,10,258,4,0,
221,10,263,4,0,
221,10,276,3,0,
221,10,283,3,0,
221,10,290,4,0,
221,10,300,1,1,4
221,10,300,1,4,
221,10,316,1,1,3
221,10,317,4,0,
221,10,363,4,0,
221,10,414,3,0,
221,10,416,4,0,
221,10,419,4,0,
221,10,423,1,28,
221,10,426,1,20,
221,10,444,4,0,
221,10,445,4,0,
221,10,446,4,0,
221,11,31,1,33,
221,11,36,1,32,
221,11,46,4,0,
221,11,54,1,48,
221,11,58,4,0,
221,11,59,1,56,
221,11,59,4,0,
221,11,63,4,0,
221,11,64,1,1,2
221,11,70,4,0,
221,11,89,1,40,
221,11,89,4,0,
221,11,91,4,0,
221,11,92,4,0,
221,11,104,4,0,
221,11,113,4,0,
221,11,115,4,0,
221,11,133,1,65,
221,11,156,4,0,
221,11,157,4,0,
221,11,164,4,0,
221,11,181,1,1,5
221,11,181,1,8,
221,11,182,4,0,
221,11,189,1,13,
221,11,196,1,25,
221,11,201,4,0,
221,11,203,1,16,
221,11,207,4,0,
221,11,213,4,0,
221,11,216,4,0,
221,11,218,4,0,
221,11,237,4,0,
221,11,240,4,0,
221,11,246,1,1,1
221,11,249,4,0,
221,11,258,4,0,
221,11,263,4,0,
221,11,300,1,1,4
221,11,300,1,4,
221,11,316,1,1,3
221,11,317,4,0,
221,11,416,4,0,
221,11,423,1,28,
221,11,426,1,20,
221,11,444,4,0,
221,11,496,4,0,
221,11,523,4,0,
221,12,30,1,1,1
221,12,31,1,33,
221,12,36,1,28,
221,12,46,4,0,
221,12,54,1,42,
221,12,58,4,0,
221,12,59,1,56,
221,12,59,4,0,
221,12,63,4,0,
221,12,70,4,0,
221,12,89,4,0,
221,12,91,4,0,
221,12,92,4,0,
221,12,104,4,0,
221,12,113,4,0,
221,12,115,4,0,
221,12,133,1,70,
221,12,156,4,0,
221,12,181,1,1,3
221,12,181,1,10,
221,12,182,4,0,
221,12,201,4,0,
221,12,203,1,1,4
221,12,203,1,19,
221,12,213,4,0,
221,12,216,4,0,
221,12,218,4,0,
221,12,237,4,0,
221,12,240,4,0,
221,12,249,4,0,
221,12,258,4,0,
221,12,263,4,0,
221,12,290,4,0,
221,12,316,1,1,2
221,12,317,4,0,
221,13,30,1,1,1
221,13,31,1,33,
221,13,34,3,0,
221,13,36,1,28,
221,13,38,3,0,
221,13,46,4,0,
221,13,54,1,42,
221,13,58,4,0,
221,13,59,1,56,
221,13,59,4,0,
221,13,63,4,0,
221,13,70,4,0,
221,13,89,4,0,
221,13,91,4,0,
221,13,92,4,0,
221,13,102,3,0,
221,13,104,4,0,
221,13,113,4,0,
221,13,115,4,0,
221,13,133,1,70,
221,13,156,4,0,
221,13,164,3,0,
221,13,181,1,1,3
221,13,181,1,10,
221,13,182,4,0,
221,13,196,3,0,
221,13,201,4,0,
221,13,203,1,1,4
221,13,203,1,19,
221,13,207,3,0,
221,13,213,4,0,
221,13,216,4,0,
221,13,218,4,0,
221,13,237,4,0,
221,13,240,4,0,
221,13,249,4,0,
221,13,258,4,0,
221,13,263,4,0,
221,13,290,4,0,
221,13,316,1,1,2
221,13,317,4,0,
221,14,31,1,33,
221,14,36,1,28,
221,14,37,1,41,
221,14,46,4,0,
221,14,54,1,37,
221,14,58,4,0,
221,14,59,1,52,
221,14,59,4,0,
221,14,63,4,0,
221,14,64,1,1,2
221,14,70,4,0,
221,14,89,1,46,
221,14,89,4,0,
221,14,91,4,0,
221,14,92,4,0,
221,14,104,4,0,
221,14,113,4,0,
221,14,115,4,0,
221,14,133,1,58,
221,14,156,4,0,
221,14,157,4,0,
221,14,164,4,0,
221,14,173,3,0,
221,14,181,1,1,5
221,14,181,1,8,
221,14,182,4,0,
221,14,189,1,11,
221,14,196,1,21,
221,14,196,3,0,
221,14,201,4,0,
221,14,203,1,14,
221,14,207,4,0,
221,14,213,4,0,
221,14,214,3,0,
221,14,216,4,0,
221,14,218,4,0,
221,14,237,4,0,
221,14,240,4,0,
221,14,246,1,1,1
221,14,249,4,0,
221,14,258,4,0,
221,14,263,4,0,
221,14,276,3,0,
221,14,283,3,0,
221,14,300,1,1,4
221,14,300,1,5,
221,14,316,1,1,3
221,14,317,4,0,
221,14,414,3,0,
221,14,416,4,0,
221,14,423,1,24,
221,14,426,1,18,
221,14,444,4,0,
221,14,446,3,0,
221,14,496,4,0,
221,14,523,4,0,
221,15,31,1,33,
221,15,36,1,28,
221,15,37,1,41,
221,15,46,4,0,
221,15,54,1,37,
221,15,58,4,0,
221,15,59,1,52,
221,15,59,4,0,
221,15,63,4,0,
221,15,64,1,1,2
221,15,70,4,0,
221,15,89,1,46,
221,15,89,4,0,
221,15,91,4,0,
221,15,92,4,0,
221,15,104,4,0,
221,15,113,4,0,
221,15,115,4,0,
221,15,133,1,58,
221,15,156,4,0,
221,15,157,4,0,
221,15,164,4,0,
221,15,181,1,1,5
221,15,181,1,8,
221,15,182,4,0,
221,15,189,1,11,
221,15,196,1,21,
221,15,201,4,0,
221,15,203,1,14,
221,15,207,4,0,
221,15,213,4,0,
221,15,214,4,0,
221,15,216,4,0,
221,15,218,4,0,
221,15,237,4,0,
221,15,240,4,0,
221,15,246,1,1,1
221,15,249,4,0,
221,15,258,4,0,
221,15,263,4,0,
221,15,300,1,1,4
221,15,300,1,5,
221,15,316,1,1,3
221,15,317,4,0,
221,15,416,4,0,
221,15,423,1,24,
221,15,426,1,18,
221,15,444,4,0,
221,15,496,4,0,
221,15,523,4,0,
221,15,590,4,0,
221,16,31,1,33,1
221,16,36,1,28,1
221,16,37,1,41,1
221,16,46,4,0,
221,16,54,1,37,1
221,16,58,4,0,
221,16,59,1,52,1
221,16,59,4,0,
221,16,63,4,0,
221,16,64,1,1,2
221,16,70,4,0,
221,16,89,1,46,1
221,16,89,4,0,
221,16,91,4,0,
221,16,92,4,0,
221,16,104,4,0,
221,16,113,4,0,
221,16,115,4,0,
221,16,133,1,58,1
221,16,156,4,0,
221,16,157,4,0,
221,16,164,4,0,
221,16,173,3,0,
221,16,181,1,1,5
221,16,181,1,8,1
221,16,182,4,0,
221,16,189,1,11,1
221,16,196,1,21,1
221,16,196,3,0,
221,16,201,4,0,
221,16,203,1,14,1
221,16,207,4,0,
221,16,213,4,0,
221,16,214,4,0,
221,16,216,4,0,
221,16,218,4,0,
221,16,237,4,0,
221,16,240,4,0,
221,16,246,1,1,1
221,16,249,4,0,
221,16,258,4,0,
221,16,263,4,0,
221,16,276,3,0,
221,16,283,3,0,
221,16,290,4,0,
221,16,300,1,1,4
221,16,300,1,5,1
221,16,316,1,1,3
221,16,317,4,0,
221,16,414,3,0,
221,16,416,4,0,
221,16,423,1,24,1
221,16,426,1,18,1
221,16,444,4,0,
221,16,446,3,0,
221,16,496,4,0,
221,16,523,4,0,
221,16,590,4,0,
221,17,31,1,0,
221,17,31,1,1,1
221,17,36,1,28,
221,17,37,1,41,
221,17,46,4,0,
221,17,54,1,37,
221,17,58,4,0,
221,17,59,1,52,
221,17,59,4,0,
221,17,63,4,0,
221,17,64,1,1,3
221,17,89,1,46,
221,17,89,4,0,
221,17,92,4,0,
221,17,104,4,0,
221,17,113,4,0,
221,17,115,4,0,
221,17,133,1,58,
221,17,156,4,0,
221,17,157,4,0,
221,17,164,4,0,
221,17,181,1,1,6
221,17,181,1,8,
221,17,182,4,0,
221,17,189,1,11,
221,17,196,1,21,
221,17,201,4,0,
221,17,203,1,14,
221,17,207,4,0,
221,17,213,4,0,
221,17,214,4,0,
221,17,216,4,0,
221,17,218,4,0,
221,17,237,4,0,
221,17,240,4,0,
221,17,246,1,1,2
221,17,258,4,0,
221,17,263,4,0,
221,17,300,1,1,5
221,17,300,1,5,
221,17,316,1,1,4
221,17,317,4,0,
221,17,416,4,0,
221,17,423,1,24,
221,17,426,1,18,
221,17,444,4,0,
221,17,496,4,0,
221,17,523,4,0,
221,17,590,4,0,
221,18,31,1,0,
221,18,31,1,1,1
221,18,36,1,28,
221,18,37,1,41,
221,18,46,4,0,
221,18,54,1,37,
221,18,58,4,0,
221,18,59,1,52,
221,18,59,4,0,
221,18,63,4,0,
221,18,64,1,1,3
221,18,89,1,46,
221,18,89,4,0,
221,18,92,4,0,
221,18,104,4,0,
221,18,113,4,0,
221,18,115,4,0,
221,18,133,1,58,
221,18,156,4,0,
221,18,157,4,0,
221,18,164,4,0,
221,18,181,1,1,6
221,18,181,1,8,
221,18,182,4,0,
221,18,189,1,11,
221,18,196,1,21,
221,18,201,4,0,
221,18,203,1,14,
221,18,207,4,0,
221,18,213,4,0,
221,18,214,4,0,
221,18,216,4,0,
221,18,218,4,0,
221,18,237,4,0,
221,18,240,4,0,
221,18,246,1,1,2
221,18,258,4,0,
221,18,263,4,0,
221,18,300,1,1,5
221,18,300,1,5,
221,18,316,1,1,4
221,18,317,4,0,
221,18,416,4,0,
221,18,423,1,24,
221,18,426,1,18,
221,18,444,4,0,
221,18,496,4,0,
221,18,523,4,0,
221,18,590,4,0,
222,3,29,4,0,
222,3,33,1,1,
222,3,54,2,0,
222,3,57,4,0,
222,3,61,1,25,
222,3,70,4,0,
222,3,89,4,0,
222,3,92,4,0,
222,3,94,4,0,
222,3,103,2,0,
222,3,104,4,0,
222,3,105,1,19,
222,3,106,1,7,
222,3,111,4,0,
222,3,131,1,31,
222,3,133,2,0,
222,3,145,1,13,
222,3,156,4,0,
222,3,157,2,0,
222,3,173,4,0,
222,3,174,4,0,
222,3,182,4,0,
222,3,189,4,0,
222,3,201,4,0,
222,3,203,4,0,
222,3,205,4,0,
222,3,207,4,0,
222,3,213,4,0,
222,3,214,4,0,
222,3,216,4,0,
222,3,218,4,0,
222,3,219,2,0,
222,3,237,4,0,
222,3,240,4,0,
222,3,241,4,0,
222,3,243,1,37,
222,3,246,1,43,
222,3,249,4,0,
222,3,250,4,0,
222,4,29,4,0,
222,4,33,1,1,
222,4,54,2,0,
222,4,57,4,0,
222,4,58,3,0,
222,4,61,1,25,
222,4,70,4,0,
222,4,89,4,0,
222,4,92,4,0,
222,4,94,4,0,
222,4,103,2,0,
222,4,104,4,0,
222,4,105,1,19,
222,4,106,1,7,
222,4,111,4,0,
222,4,131,1,31,
222,4,133,2,0,
222,4,145,1,13,
222,4,156,4,0,
222,4,157,2,0,
222,4,173,4,0,
222,4,174,4,0,
222,4,182,4,0,
222,4,189,4,0,
222,4,201,4,0,
222,4,203,4,0,
222,4,205,4,0,
222,4,207,4,0,
222,4,213,4,0,
222,4,214,4,0,
222,4,216,4,0,
222,4,218,4,0,
222,4,219,2,0,
222,4,237,4,0,
222,4,240,4,0,
222,4,241,4,0,
222,4,243,1,37,
222,4,246,1,43,
222,4,249,4,0,
222,4,250,4,0,
222,5,33,1,1,
222,5,54,2,0,
222,5,57,4,0,
222,5,58,4,0,
222,5,59,4,0,
222,5,61,1,23,
222,5,70,4,0,
222,5,89,4,0,
222,5,91,4,0,
222,5,92,4,0,
222,5,94,4,0,
222,5,103,2,0,
222,5,104,4,0,
222,5,105,1,17,1
222,5,106,1,6,
222,5,109,2,0,
222,5,112,2,0,
222,5,113,4,0,
222,5,115,4,0,
222,5,131,1,28,
222,5,133,2,0,
222,5,145,1,12,
222,5,156,4,0,
222,5,157,2,0,
222,5,182,4,0,
222,5,201,4,0,
222,5,213,4,0,
222,5,216,4,0,
222,5,218,4,0,
222,5,219,4,0,
222,5,237,4,0,
222,5,240,4,0,
222,5,241,4,0,
222,5,243,1,39,
222,5,246,1,45,
222,5,247,4,0,
222,5,249,4,0,
222,5,258,4,0,
222,5,263,4,0,
222,5,275,2,0,
222,5,287,1,17,2
222,5,290,4,0,
222,5,317,4,0,
222,5,333,2,0,
222,5,347,4,0,
222,5,350,1,34,
222,5,352,4,0,
222,6,33,1,1,
222,6,34,3,0,
222,6,38,3,0,
222,6,54,2,0,
222,6,57,4,0,
222,6,58,4,0,
222,6,59,4,0,
222,6,61,1,23,
222,6,70,4,0,
222,6,89,4,0,
222,6,91,4,0,
222,6,92,4,0,
222,6,94,4,0,
222,6,102,3,0,
222,6,103,2,0,
222,6,104,4,0,
222,6,105,1,17,1
222,6,106,1,6,
222,6,109,2,0,
222,6,111,3,0,
222,6,112,2,0,
222,6,113,4,0,
222,6,115,4,0,
222,6,131,1,28,
222,6,133,2,0,
222,6,145,1,12,
222,6,153,3,0,
222,6,156,4,0,
222,6,157,2,0,
222,6,157,3,0,
222,6,164,3,0,
222,6,173,3,0,
222,6,182,4,0,
222,6,189,3,0,
222,6,201,4,0,
222,6,203,3,0,
222,6,205,3,0,
222,6,207,3,0,
222,6,213,4,0,
222,6,214,3,0,
222,6,216,4,0,
222,6,218,4,0,
222,6,219,4,0,
222,6,237,4,0,
222,6,240,4,0,
222,6,241,4,0,
222,6,243,1,39,
222,6,246,1,45,
222,6,247,4,0,
222,6,249,4,0,
222,6,258,4,0,
222,6,263,4,0,
222,6,275,2,0,
222,6,287,1,17,2
222,6,290,4,0,
222,6,317,4,0,
222,6,333,2,0,
222,6,347,4,0,
222,6,350,1,34,
222,6,352,4,0,
222,7,33,1,1,
222,7,34,3,0,
222,7,38,3,0,
222,7,54,2,0,
222,7,57,4,0,
222,7,58,4,0,
222,7,59,4,0,
222,7,61,1,23,
222,7,70,4,0,
222,7,89,4,0,
222,7,91,4,0,
222,7,92,4,0,
222,7,94,4,0,
222,7,102,3,0,
222,7,103,2,0,
222,7,104,4,0,
222,7,105,1,17,1
222,7,106,1,6,
222,7,109,2,0,
222,7,112,2,0,
222,7,113,4,0,
222,7,115,4,0,
222,7,131,1,28,
222,7,133,2,0,
222,7,145,1,12,
222,7,153,3,0,
222,7,156,4,0,
222,7,157,2,0,
222,7,157,3,0,
222,7,164,3,0,
222,7,182,4,0,
222,7,201,4,0,
222,7,213,4,0,
222,7,216,4,0,
222,7,218,4,0,
222,7,219,4,0,
222,7,237,4,0,
222,7,240,4,0,
222,7,241,4,0,
222,7,243,1,39,
222,7,246,1,45,
222,7,247,4,0,
222,7,249,4,0,
222,7,258,4,0,
222,7,263,4,0,
222,7,275,2,0,
222,7,287,1,17,2
222,7,290,4,0,
222,7,317,4,0,
222,7,333,2,0,
222,7,347,4,0,
222,7,350,1,34,
222,7,352,4,0,
222,8,33,1,1,
222,8,54,2,0,
222,8,57,4,0,
222,8,58,4,0,
222,8,59,4,0,
222,8,61,1,25,
222,8,70,4,0,
222,8,89,4,0,
222,8,91,4,0,
222,8,92,4,0,
222,8,94,4,0,
222,8,103,2,0,
222,8,104,4,0,
222,8,105,1,13,
222,8,106,1,4,
222,8,109,2,0,
222,8,112,2,0,
222,8,113,4,0,
222,8,115,4,0,
222,8,131,1,40,
222,8,133,2,0,
222,8,145,1,8,
222,8,153,4,0,
222,8,156,4,0,
222,8,157,2,0,
222,8,157,4,0,
222,8,164,4,0,
222,8,182,4,0,
222,8,201,4,0,
222,8,203,4,0,
222,8,207,4,0,
222,8,213,4,0,
222,8,214,4,0,
222,8,216,4,0,
222,8,218,4,0,
222,8,219,4,0,
222,8,237,4,0,
222,8,240,4,0,
222,8,241,4,0,
222,8,243,1,48,
222,8,246,1,32,
222,8,247,4,0,
222,8,249,4,0,
222,8,258,4,0,
222,8,263,4,0,
222,8,267,2,0,
222,8,275,2,0,
222,8,287,1,16,
222,8,290,4,0,
222,8,317,4,0,
222,8,333,2,0,
222,8,347,4,0,
222,8,350,1,20,
222,8,352,4,0,
222,8,362,4,0,
222,8,363,4,0,
222,8,381,1,28,
222,8,392,1,37,
222,8,392,2,0,
222,8,397,4,0,
222,8,408,1,44,
222,8,414,1,53,
222,8,444,4,0,
222,8,445,4,0,
222,8,446,4,0,
222,9,33,1,1,
222,9,54,2,0,
222,9,57,4,0,
222,9,58,4,0,
222,9,59,4,0,
222,9,61,1,25,
222,9,70,4,0,
222,9,89,4,0,
222,9,91,4,0,
222,9,92,4,0,
222,9,94,4,0,
222,9,103,2,0,
222,9,104,4,0,
222,9,105,1,13,
222,9,106,1,4,
222,9,109,2,0,
222,9,112,2,0,
222,9,113,4,0,
222,9,115,4,0,
222,9,131,1,40,
222,9,133,2,0,
222,9,145,1,8,
222,9,153,4,0,
222,9,156,4,0,
222,9,157,2,0,
222,9,157,4,0,
222,9,164,4,0,
222,9,173,3,0,
222,9,182,4,0,
222,9,189,3,0,
222,9,196,3,0,
222,9,201,4,0,
222,9,203,4,0,
222,9,205,3,0,
222,9,207,4,0,
222,9,213,4,0,
222,9,214,4,0,
222,9,216,4,0,
222,9,218,4,0,
222,9,219,4,0,
222,9,237,4,0,
222,9,240,4,0,
222,9,241,4,0,
222,9,243,1,48,
222,9,246,1,32,
222,9,246,3,0,
222,9,247,4,0,
222,9,249,4,0,
222,9,258,4,0,
222,9,263,4,0,
222,9,267,2,0,
222,9,275,2,0,
222,9,283,3,0,
222,9,287,1,16,
222,9,290,4,0,
222,9,317,4,0,
222,9,333,2,0,
222,9,347,4,0,
222,9,350,1,20,
222,9,352,4,0,
222,9,362,4,0,
222,9,363,4,0,
222,9,381,1,28,
222,9,389,3,0,
222,9,392,1,37,
222,9,392,2,0,
222,9,397,4,0,
222,9,408,1,44,
222,9,414,1,53,
222,9,414,3,0,
222,9,444,4,0,
222,9,445,4,0,
222,9,446,4,0,
222,10,29,3,0,
222,10,33,1,1,
222,10,54,2,0,
222,10,57,4,0,
222,10,58,4,0,
222,10,59,4,0,
222,10,61,1,25,
222,10,70,4,0,
222,10,89,4,0,
222,10,91,4,0,
222,10,92,4,0,
222,10,94,4,0,
222,10,103,2,0,
222,10,104,4,0,
222,10,105,1,13,
222,10,106,1,4,
222,10,109,2,0,
222,10,112,2,0,
222,10,113,4,0,
222,10,115,4,0,
222,10,131,1,40,
222,10,133,2,0,
222,10,145,1,8,
222,10,153,4,0,
222,10,156,4,0,
222,10,157,2,0,
222,10,157,4,0,
222,10,164,4,0,
222,10,173,3,0,
222,10,174,2,0,
222,10,182,4,0,
222,10,189,3,0,
222,10,196,3,0,
222,10,201,4,0,
222,10,203,4,0,
222,10,205,3,0,
222,10,207,4,0,
222,10,213,4,0,
222,10,214,4,0,
222,10,216,4,0,
222,10,218,4,0,
222,10,219,4,0,
222,10,237,4,0,
222,10,240,4,0,
222,10,241,4,0,
222,10,243,1,48,
222,10,246,1,32,
222,10,246,3,0,
222,10,247,4,0,
222,10,249,4,0,
222,10,250,4,0,
222,10,258,4,0,
222,10,263,4,0,
222,10,267,2,0,
222,10,275,2,0,
222,10,277,3,0,
222,10,283,3,0,
222,10,287,1,16,
222,10,290,4,0,
222,10,317,4,0,
222,10,333,2,0,
222,10,347,4,0,
222,10,350,1,20,
222,10,352,4,0,
222,10,362,4,0,
222,10,363,4,0,
222,10,381,1,28,
222,10,389,3,0,
222,10,392,1,37,
222,10,392,2,0,
222,10,397,4,0,
222,10,408,1,44,
222,10,414,1,53,
222,10,414,3,0,
222,10,444,4,0,
222,10,445,4,0,
222,10,446,4,0,
222,11,33,1,1,
222,11,54,2,0,
222,11,57,4,0,
222,11,58,4,0,
222,11,59,4,0,
222,11,61,1,25,
222,11,70,4,0,
222,11,89,4,0,
222,11,91,4,0,
222,11,92,4,0,
222,11,94,4,0,
222,11,103,2,0,
222,11,104,4,0,
222,11,105,1,13,
222,11,106,1,4,
222,11,109,2,0,
222,11,112,2,0,
222,11,113,4,0,
222,11,115,4,0,
222,11,117,2,0,
222,11,131,1,40,
222,11,133,2,0,
222,11,145,1,8,
222,11,153,4,0,
222,11,156,4,0,
222,11,157,4,0,
222,11,164,4,0,
222,11,174,2,0,
222,11,182,4,0,
222,11,201,4,0,
222,11,207,4,0,
222,11,213,4,0,
222,11,216,4,0,
222,11,218,4,0,
222,11,219,4,0,
222,11,237,4,0,
222,11,240,4,0,
222,11,241,4,0,
222,11,243,1,48,
222,11,246,1,32,
222,11,247,4,0,
222,11,249,4,0,
222,11,258,4,0,
222,11,263,4,0,
222,11,267,2,0,
222,11,275,2,0,
222,11,287,1,16,
222,11,317,4,0,
222,11,333,2,0,
222,11,347,4,0,
222,11,350,1,20,
222,11,352,2,0,
222,11,381,1,28,
222,11,392,1,37,
222,11,392,2,0,
222,11,397,4,0,
222,11,408,1,44,
222,11,414,1,53,
222,11,444,4,0,
222,11,457,2,0,
222,11,496,4,0,
222,11,503,4,0,
222,11,523,4,0,
222,12,33,1,1,
222,12,57,4,0,
222,12,58,4,0,
222,12,59,4,0,
222,12,61,1,23,
222,12,70,4,0,
222,12,89,4,0,
222,12,91,4,0,
222,12,92,4,0,
222,12,94,4,0,
222,12,104,4,0,
222,12,105,1,17,1
222,12,106,1,6,
222,12,113,4,0,
222,12,115,4,0,
222,12,131,1,28,
222,12,145,1,12,
222,12,156,4,0,
222,12,182,4,0,
222,12,201,4,0,
222,12,213,4,0,
222,12,216,4,0,
222,12,218,4,0,
222,12,219,4,0,
222,12,237,4,0,
222,12,240,4,0,
222,12,241,4,0,
222,12,243,1,39,
222,12,246,1,45,
222,12,247,4,0,
222,12,249,4,0,
222,12,258,4,0,
222,12,263,4,0,
222,12,287,1,17,2
222,12,290,4,0,
222,12,317,4,0,
222,12,347,4,0,
222,12,350,1,34,
222,12,352,4,0,
222,13,33,1,1,
222,13,34,3,0,
222,13,38,3,0,
222,13,57,4,0,
222,13,58,4,0,
222,13,59,4,0,
222,13,61,1,23,
222,13,70,4,0,
222,13,89,4,0,
222,13,91,4,0,
222,13,92,4,0,
222,13,94,4,0,
222,13,102,3,0,
222,13,104,4,0,
222,13,105,1,17,1
222,13,106,1,6,
222,13,113,4,0,
222,13,115,4,0,
222,13,120,3,0,
222,13,131,1,28,
222,13,145,1,12,
222,13,156,4,0,
222,13,164,3,0,
222,13,182,4,0,
222,13,201,4,0,
222,13,207,3,0,
222,13,213,4,0,
222,13,216,4,0,
222,13,218,4,0,
222,13,219,4,0,
222,13,237,4,0,
222,13,240,4,0,
222,13,241,4,0,
222,13,243,1,39,
222,13,246,1,45,
222,13,247,4,0,
222,13,249,4,0,
222,13,258,4,0,
222,13,263,4,0,
222,13,287,1,17,2
222,13,290,4,0,
222,13,317,4,0,
222,13,347,4,0,
222,13,350,1,34,
222,13,352,4,0,
222,14,33,1,1,
222,14,54,2,0,
222,14,57,4,0,
222,14,58,4,0,
222,14,59,4,0,
222,14,61,1,17,
222,14,70,4,0,
222,14,89,4,0,
222,14,91,4,0,
222,14,92,4,0,
222,14,94,4,0,
222,14,103,2,0,
222,14,104,4,0,
222,14,105,1,10,
222,14,106,1,4,
222,14,109,2,0,
222,14,112,2,0,
222,14,113,4,0,
222,14,115,4,0,
222,14,117,2,0,
222,14,131,1,27,
222,14,133,2,0,
222,14,145,1,8,
222,14,153,4,0,
222,14,156,4,0,
222,14,157,4,0,
222,14,164,4,0,
222,14,173,3,0,
222,14,174,2,0,
222,14,175,1,52,
222,14,182,4,0,
222,14,196,3,0,
222,14,201,4,0,
222,14,203,1,35,
222,14,207,4,0,
222,14,213,4,0,
222,14,214,3,0,
222,14,216,4,0,
222,14,218,4,0,
222,14,219,4,0,
222,14,237,4,0,
222,14,240,4,0,
222,14,241,4,0,
222,14,243,1,45,
222,14,246,1,20,
222,14,247,4,0,
222,14,249,4,0,
222,14,258,4,0,
222,14,263,4,0,
222,14,267,2,0,
222,14,275,2,0,
222,14,277,3,0,
222,14,283,3,0,
222,14,287,1,13,
222,14,317,4,0,
222,14,333,2,0,
222,14,334,1,29,
222,14,334,3,0,
222,14,347,4,0,
222,14,350,1,31,
222,14,352,2,0,
222,14,381,1,23,
222,14,392,1,38,
222,14,392,2,0,
222,14,397,4,0,
222,14,408,1,41,
222,14,414,1,47,
222,14,414,3,0,
222,14,444,4,0,
222,14,446,3,0,
222,14,457,2,0,
222,14,496,4,0,
222,14,503,4,0,
222,14,523,4,0,
222,15,33,1,1,
222,15,54,2,0,
222,15,57,4,0,
222,15,58,4,0,
222,15,59,4,0,
222,15,61,1,17,
222,15,70,4,0,
222,15,89,4,0,
222,15,91,4,0,
222,15,92,4,0,
222,15,94,4,0,
222,15,103,2,0,
222,15,104,4,0,
222,15,105,1,10,
222,15,106,1,4,
222,15,109,2,0,
222,15,112,2,0,
222,15,113,4,0,
222,15,115,4,0,
222,15,117,2,0,
222,15,131,1,27,
222,15,133,2,0,
222,15,145,1,8,
222,15,153,4,0,
222,15,156,4,0,
222,15,157,4,0,
222,15,164,4,0,
222,15,174,2,0,
222,15,175,1,50,
222,15,182,4,0,
222,15,201,4,0,
222,15,203,1,35,
222,15,207,4,0,
222,15,213,4,0,
222,15,214,4,0,
222,15,216,4,0,
222,15,218,4,0,
222,15,219,4,0,
222,15,237,4,0,
222,15,240,4,0,
222,15,241,4,0,
222,15,243,1,45,
222,15,246,1,20,
222,15,247,4,0,
222,15,249,4,0,
222,15,258,4,0,
222,15,263,4,0,
222,15,267,2,0,
222,15,267,4,0,
222,15,275,2,0,
222,15,287,1,13,
222,15,293,2,0,
222,15,317,4,0,
222,15,333,2,0,
222,15,334,1,29,
222,15,347,4,0,
222,15,350,1,31,
222,15,352,2,0,
222,15,381,1,23,
222,15,392,1,38,
222,15,392,2,0,
222,15,397,4,0,
222,15,408,1,41,
222,15,414,1,47,
222,15,444,4,0,
222,15,457,2,0,
222,15,496,4,0,
222,15,503,4,0,
222,15,523,4,0,
222,15,590,4,0,
222,16,33,1,1,1
222,16,54,2,0,
222,16,57,4,0,
222,16,58,4,0,
222,16,59,4,0,
222,16,61,1,10,1
222,16,70,4,0,
222,16,89,4,0,
222,16,91,4,0,
222,16,92,4,0,
222,16,94,4,0,
222,16,103,2,0,
222,16,104,4,0,
222,16,105,1,8,1
222,16,106,1,1,2
222,16,109,2,0,
222,16,112,2,0,
222,16,113,4,0,
222,16,115,4,0,
222,16,117,2,0,
222,16,131,1,20,1
222,16,133,2,0,
222,16,145,1,4,1
222,16,153,4,0,
222,16,156,4,0,
222,16,157,4,0,
222,16,164,4,0,
222,16,173,3,0,
222,16,174,2,0,
222,16,175,1,50,1
222,16,182,4,0,
222,16,196,3,0,
222,16,201,4,0,
222,16,203,1,35,1
222,16,207,4,0,
222,16,213,4,0,
222,16,214,4,0,
222,16,216,4,0,
222,16,218,4,0,
222,16,219,4,0,
222,16,237,4,0,
222,16,240,4,0,
222,16,241,4,0,
222,16,243,1,45,1
222,16,246,1,17,1
222,16,247,4,0,
222,16,249,4,0,
222,16,258,4,0,
222,16,263,4,0,
222,16,267,2,0,
222,16,267,4,0,
222,16,275,2,0,
222,16,277,3,0,
222,16,283,3,0,
222,16,287,1,13,1
222,16,290,4,0,
222,16,293,2,0,
222,16,317,4,0,
222,16,333,2,0,
222,16,334,1,29,1
222,16,334,3,0,
222,16,347,4,0,
222,16,350,1,31,1
222,16,352,2,0,
222,16,352,3,0,
222,16,362,1,27,1
222,16,381,1,23,1
222,16,392,1,38,1
222,16,392,2,0,
222,16,397,4,0,
222,16,408,1,41,1
222,16,414,1,47,1
222,16,414,3,0,
222,16,444,4,0,
222,16,446,3,0,
222,16,457,2,0,
222,16,496,4,0,
222,16,503,4,0,
222,16,523,4,0,
222,16,590,4,0,
222,17,33,1,1,1
222,17,54,2,0,
222,17,57,4,0,
222,17,58,4,0,
222,17,59,4,0,
222,17,61,1,10,
222,17,89,4,0,
222,17,92,4,0,
222,17,94,4,0,
222,17,103,2,0,
222,17,104,4,0,
222,17,105,1,8,
222,17,106,1,1,2
222,17,109,2,0,
222,17,112,2,0,
222,17,113,4,0,
222,17,115,4,0,
222,17,117,2,0,
222,17,131,1,20,
222,17,133,2,0,
222,17,145,1,4,
222,17,153,4,0,
222,17,156,4,0,
222,17,157,4,0,
222,17,164,4,0,
222,17,174,2,0,
222,17,175,1,50,
222,17,182,4,0,
222,17,201,4,0,
222,17,203,1,35,
222,17,207,4,0,
222,17,213,4,0,
222,17,214,4,0,
222,17,216,4,0,
222,17,218,4,0,
222,17,219,4,0,
222,17,237,4,0,
222,17,240,4,0,
222,17,241,4,0,
222,17,243,1,45,
222,17,246,1,17,
222,17,247,4,0,
222,17,258,4,0,
222,17,263,4,0,
222,17,267,2,0,
222,17,267,4,0,
222,17,275,2,0,
222,17,287,1,13,
222,17,293,2,0,
222,17,317,4,0,
222,17,333,2,0,
222,17,334,1,29,
222,17,347,4,0,
222,17,350,1,31,
222,17,352,2,0,
222,17,362,1,27,
222,17,381,1,23,
222,17,392,1,38,
222,17,392,2,0,
222,17,397,4,0,
222,17,408,1,41,
222,17,414,1,47,
222,17,444,4,0,
222,17,457,2,0,
222,17,496,4,0,
222,17,503,4,0,
222,17,523,4,0,
222,17,590,4,0,
222,17,710,2,0,
222,18,33,1,1,1
222,18,54,2,0,
222,18,57,4,0,
222,18,58,4,0,
222,18,59,4,0,
222,18,61,1,10,
222,18,89,4,0,
222,18,92,4,0,
222,18,94,4,0,
222,18,103,2,0,
222,18,104,4,0,
222,18,105,1,8,
222,18,106,1,1,2
222,18,109,2,0,
222,18,112,2,0,
222,18,113,4,0,
222,18,115,4,0,
222,18,117,2,0,
222,18,131,1,20,
222,18,133,2,0,
222,18,145,1,4,
222,18,153,4,0,
222,18,156,4,0,
222,18,157,4,0,
222,18,164,4,0,
222,18,174,2,0,
222,18,175,1,50,
222,18,182,4,0,
222,18,201,4,0,
222,18,203,1,35,
222,18,207,4,0,
222,18,213,4,0,
222,18,214,4,0,
222,18,216,4,0,
222,18,218,4,0,
222,18,219,4,0,
222,18,237,4,0,
222,18,240,4,0,
222,18,241,4,0,
222,18,243,1,45,
222,18,246,1,17,
222,18,247,4,0,
222,18,258,4,0,
222,18,263,4,0,
222,18,267,2,0,
222,18,267,4,0,
222,18,275,2,0,
222,18,287,1,13,
222,18,293,2,0,
222,18,317,4,0,
222,18,333,2,0,
222,18,334,1,29,
222,18,347,4,0,
222,18,350,1,31,
222,18,352,2,0,
222,18,362,1,27,
222,18,381,1,23,
222,18,392,1,38,
222,18,392,2,0,
222,18,397,4,0,
222,18,408,1,41,
222,18,414,1,47,
222,18,444,4,0,
222,18,457,2,0,
222,18,496,4,0,
222,18,503,4,0,
222,18,523,4,0,
222,18,590,4,0,
222,18,710,2,0,
223,3,48,2,0,
223,3,55,1,1,
223,3,57,4,0,
223,3,58,1,44,
223,3,60,1,22,1
223,3,61,1,22,3
223,3,62,1,22,2
223,3,62,2,0,
223,3,63,1,55,
223,3,63,4,0,
223,3,92,4,0,
223,3,103,2,0,
223,3,104,4,0,
223,3,111,4,0,
223,3,114,2,0,
223,3,116,1,33,
223,3,129,4,0,
223,3,156,4,0,
223,3,168,4,0,
223,3,173,4,0,
223,3,174,4,0,
223,3,182,4,0,
223,3,189,4,0,
223,3,190,2,0,
223,3,199,1,11,
223,3,203,4,0,
223,3,207,4,0,
223,3,213,4,0,
223,3,214,4,0,
223,3,216,4,0,
223,3,218,4,0,
223,3,237,4,0,
223,3,240,4,0,
223,3,250,4,0,
223,4,48,2,0,
223,4,53,3,0,
223,4,55,1,1,
223,4,57,4,0,
223,4,58,1,44,
223,4,58,3,0,
223,4,60,1,22,1
223,4,61,1,22,3
223,4,62,1,22,2
223,4,62,2,0,
223,4,63,1,55,
223,4,63,4,0,
223,4,92,4,0,
223,4,103,2,0,
223,4,104,4,0,
223,4,111,4,0,
223,4,114,2,0,
223,4,116,1,33,
223,4,129,4,0,
223,4,156,4,0,
223,4,168,4,0,
223,4,173,4,0,
223,4,174,4,0,
223,4,182,4,0,
223,4,189,4,0,
223,4,190,2,0,
223,4,199,1,11,
223,4,203,4,0,
223,4,207,4,0,
223,4,213,4,0,
223,4,214,4,0,
223,4,216,4,0,
223,4,218,4,0,
223,4,237,4,0,
223,4,240,4,0,
223,4,250,4,0,
223,5,48,2,0,
223,5,53,4,0,
223,5,55,1,1,
223,5,57,4,0,
223,5,58,1,44,
223,5,58,4,0,
223,5,59,4,0,
223,5,60,1,22,1
223,5,61,1,22,3
223,5,62,1,22,2
223,5,62,2,0,
223,5,63,1,55,
223,5,63,4,0,
223,5,86,2,0,
223,5,92,4,0,
223,5,94,4,0,
223,5,103,2,0,
223,5,104,4,0,
223,5,114,2,0,
223,5,116,1,33,
223,5,126,4,0,
223,5,127,4,0,
223,5,156,4,0,
223,5,168,4,0,
223,5,182,4,0,
223,5,190,2,0,
223,5,199,1,11,
223,5,213,4,0,
223,5,216,4,0,
223,5,218,4,0,
223,5,237,4,0,
223,5,240,4,0,
223,5,241,4,0,
223,5,263,4,0,
223,5,290,4,0,
223,5,291,4,0,
223,5,350,2,0,
223,5,352,4,0,
223,6,38,3,0,
223,6,48,2,0,
223,6,53,4,0,
223,6,55,1,1,
223,6,57,4,0,
223,6,58,1,44,
223,6,58,4,0,
223,6,59,4,0,
223,6,60,1,22,1
223,6,61,1,22,3
223,6,62,1,22,2
223,6,62,2,0,
223,6,63,1,55,
223,6,63,4,0,
223,6,86,2,0,
223,6,86,3,0,
223,6,92,4,0,
223,6,94,4,0,
223,6,102,3,0,
223,6,103,2,0,
223,6,104,4,0,
223,6,111,3,0,
223,6,114,2,0,
223,6,116,1,33,
223,6,126,4,0,
223,6,127,4,0,
223,6,129,3,0,
223,6,156,4,0,
223,6,164,3,0,
223,6,168,4,0,
223,6,173,3,0,
223,6,182,4,0,
223,6,189,3,0,
223,6,190,2,0,
223,6,199,1,11,
223,6,203,3,0,
223,6,207,3,0,
223,6,213,4,0,
223,6,214,3,0,
223,6,216,4,0,
223,6,218,4,0,
223,6,237,4,0,
223,6,240,4,0,
223,6,241,4,0,
223,6,263,4,0,
223,6,290,4,0,
223,6,291,4,0,
223,6,350,2,0,
223,6,352,4,0,
223,7,38,3,0,
223,7,48,2,0,
223,7,53,4,0,
223,7,55,1,1,
223,7,57,4,0,
223,7,58,1,44,
223,7,58,4,0,
223,7,59,4,0,
223,7,60,1,22,1
223,7,61,1,22,3
223,7,62,1,22,2
223,7,62,2,0,
223,7,63,1,55,
223,7,63,4,0,
223,7,86,2,0,
223,7,86,3,0,
223,7,92,4,0,
223,7,94,4,0,
223,7,102,3,0,
223,7,103,2,0,
223,7,104,4,0,
223,7,114,2,0,
223,7,116,1,33,
223,7,126,4,0,
223,7,127,4,0,
223,7,156,4,0,
223,7,164,3,0,
223,7,168,4,0,
223,7,182,4,0,
223,7,190,2,0,
223,7,199,1,11,
223,7,213,4,0,
223,7,216,4,0,
223,7,218,4,0,
223,7,237,4,0,
223,7,240,4,0,
223,7,241,4,0,
223,7,263,4,0,
223,7,290,4,0,
223,7,291,4,0,
223,7,350,2,0,
223,7,352,4,0,
223,8,48,2,0,
223,8,53,4,0,
223,8,55,1,1,
223,8,57,4,0,
223,8,58,1,40,
223,8,58,4,0,
223,8,59,4,0,
223,8,60,1,10,
223,8,61,1,19,
223,8,62,1,14,
223,8,62,2,0,
223,8,63,1,45,
223,8,63,4,0,
223,8,86,2,0,
223,8,86,4,0,
223,8,92,4,0,
223,8,94,4,0,
223,8,103,2,0,
223,8,104,4,0,
223,8,114,2,0,
223,8,116,1,23,
223,8,126,4,0,
223,8,127,4,0,
223,8,156,4,0,
223,8,164,4,0,
223,8,168,4,0,
223,8,173,2,0,
223,8,175,2,0,
223,8,182,4,0,
223,8,190,2,0,
223,8,199,1,6,
223,8,203,4,0,
223,8,207,4,0,
223,8,213,4,0,
223,8,214,4,0,
223,8,216,4,0,
223,8,218,4,0,
223,8,237,4,0,
223,8,240,4,0,
223,8,241,4,0,
223,8,263,4,0,
223,8,290,4,0,
223,8,324,1,36,
223,8,331,1,27,
223,8,331,4,0,
223,8,350,2,0,
223,8,352,1,32,
223,8,352,4,0,
223,8,362,4,0,
223,8,363,4,0,
223,8,445,4,0,
223,8,451,4,0,
223,9,48,2,0,
223,9,53,4,0,
223,9,55,1,1,
223,9,57,4,0,
223,9,58,1,40,
223,9,58,4,0,
223,9,59,4,0,
223,9,60,1,10,
223,9,61,1,19,
223,9,62,1,14,
223,9,62,2,0,
223,9,63,1,45,
223,9,63,4,0,
223,9,86,2,0,
223,9,86,4,0,
223,9,92,4,0,
223,9,94,4,0,
223,9,103,2,0,
223,9,104,4,0,
223,9,114,2,0,
223,9,116,1,23,
223,9,126,4,0,
223,9,127,4,0,
223,9,129,3,0,
223,9,156,4,0,
223,9,164,4,0,
223,9,168,4,0,
223,9,173,2,0,
223,9,173,3,0,
223,9,175,2,0,
223,9,182,4,0,
223,9,189,3,0,
223,9,190,2,0,
223,9,196,3,0,
223,9,199,1,6,
223,9,203,4,0,
223,9,207,4,0,
223,9,213,4,0,
223,9,214,4,0,
223,9,216,4,0,
223,9,218,4,0,
223,9,237,4,0,
223,9,240,4,0,
223,9,241,4,0,
223,9,263,4,0,
223,9,290,4,0,
223,9,291,3,0,
223,9,324,1,36,
223,9,324,3,0,
223,9,331,1,27,
223,9,331,4,0,
223,9,340,3,0,
223,9,350,2,0,
223,9,352,1,32,
223,9,352,4,0,
223,9,362,4,0,
223,9,363,4,0,
223,9,402,3,0,
223,9,441,3,0,
223,9,445,4,0,
223,9,451,4,0,
223,10,48,2,0,
223,10,53,4,0,
223,10,55,1,1,
223,10,57,4,0,
223,10,58,1,40,
223,10,58,4,0,
223,10,59,4,0,
223,10,60,1,10,
223,10,61,1,19,
223,10,62,1,14,
223,10,62,2,0,
223,10,63,1,45,
223,10,63,4,0,
223,10,81,3,0,
223,10,86,2,0,
223,10,86,4,0,
223,10,92,4,0,
223,10,94,4,0,
223,10,103,2,0,
223,10,104,4,0,
223,10,114,2,0,
223,10,116,1,23,
223,10,126,4,0,
223,10,127,4,0,
223,10,129,3,0,
223,10,156,4,0,
223,10,164,4,0,
223,10,168,4,0,
223,10,173,2,0,
223,10,173,3,0,
223,10,175,2,0,
223,10,182,4,0,
223,10,189,3,0,
223,10,190,2,0,
223,10,196,3,0,
223,10,199,1,6,
223,10,203,4,0,
223,10,207,4,0,
223,10,213,4,0,
223,10,214,4,0,
223,10,216,4,0,
223,10,218,4,0,
223,10,237,4,0,
223,10,240,4,0,
223,10,241,4,0,
223,10,250,4,0,
223,10,263,4,0,
223,10,290,4,0,
223,10,291,3,0,
223,10,323,2,0,
223,10,324,1,36,
223,10,324,3,0,
223,10,331,1,27,
223,10,331,4,0,
223,10,340,3,0,
223,10,350,2,0,
223,10,352,1,32,
223,10,352,4,0,
223,10,362,4,0,
223,10,363,4,0,
223,10,402,3,0,
223,10,441,3,0,
223,10,445,4,0,
223,10,451,4,0,
223,11,48,2,0,
223,11,53,4,0,
223,11,55,1,1,
223,11,57,4,0,
223,11,58,1,40,
223,11,58,4,0,
223,11,59,4,0,
223,11,60,1,10,
223,11,61,1,19,
223,11,62,1,14,
223,11,62,2,0,
223,11,63,1,45,
223,11,63,4,0,
223,11,86,4,0,
223,11,92,4,0,
223,11,94,4,0,
223,11,103,2,0,
223,11,104,4,0,
223,11,114,2,0,
223,11,116,1,23,
223,11,126,4,0,
223,11,127,4,0,
223,11,129,2,0,
223,11,156,4,0,
223,11,164,4,0,
223,11,168,4,0,
223,11,173,2,0,
223,11,175,2,0,
223,11,182,4,0,
223,11,190,2,0,
223,11,199,1,6,
223,11,207,4,0,
223,11,213,4,0,
223,11,216,4,0,
223,11,218,4,0,
223,11,237,4,0,
223,11,240,4,0,
223,11,241,4,0,
223,11,263,4,0,
223,11,291,4,0,
223,11,323,2,0,
223,11,324,1,36,
223,11,331,1,27,
223,11,341,2,0,
223,11,350,2,0,
223,11,352,1,32,
223,11,352,2,0,
223,11,451,4,0,
223,11,479,4,0,
223,11,487,1,49,
223,11,491,2,0,
223,11,496,4,0,
223,11,503,4,0,
223,11,510,4,0,
223,12,53,4,0,
223,12,55,1,1,
223,12,57,4,0,
223,12,58,1,44,
223,12,58,4,0,
223,12,59,4,0,
223,12,60,1,22,1
223,12,61,1,22,3
223,12,62,1,22,2
223,12,63,1,55,
223,12,63,4,0,
223,12,92,4,0,
223,12,94,4,0,
223,12,104,4,0,
223,12,116,1,33,
223,12,126,4,0,
223,12,127,4,0,
223,12,156,4,0,
223,12,168,4,0,
223,12,182,4,0,
223,12,199,1,11,
223,12,213,4,0,
223,12,216,4,0,
223,12,218,4,0,
223,12,237,4,0,
223,12,240,4,0,
223,12,241,4,0,
223,12,263,4,0,
223,12,290,4,0,
223,12,291,4,0,
223,12,352,4,0,
223,13,38,3,0,
223,13,53,4,0,
223,13,55,1,1,
223,13,57,4,0,
223,13,58,1,44,
223,13,58,4,0,
223,13,59,4,0,
223,13,60,1,22,1
223,13,61,1,22,3
223,13,62,1,22,2
223,13,63,1,55,
223,13,63,4,0,
223,13,86,3,0,
223,13,92,4,0,
223,13,94,4,0,
223,13,102,3,0,
223,13,104,4,0,
223,13,116,1,33,
223,13,126,4,0,
223,13,127,4,0,
223,13,156,4,0,
223,13,164,3,0,
223,13,168,4,0,
223,13,182,4,0,
223,13,199,1,11,
223,13,207,3,0,
223,13,213,4,0,
223,13,216,4,0,
223,13,218,4,0,
223,13,237,4,0,
223,13,240,4,0,
223,13,241,4,0,
223,13,263,4,0,
223,13,290,4,0,
223,13,291,4,0,
223,13,352,4,0,
223,14,48,2,0,
223,14,53,4,0,
223,14,55,1,1,
223,14,56,1,42,
223,14,57,4,0,
223,14,58,1,34,
223,14,58,4,0,
223,14,59,4,0,
223,14,60,1,10,
223,14,61,1,18,
223,14,62,1,14,
223,14,62,2,0,
223,14,63,1,46,
223,14,63,4,0,
223,14,86,4,0,
223,14,92,4,0,
223,14,94,4,0,
223,14,103,2,0,
223,14,104,4,0,
223,14,114,2,0,
223,14,116,1,22,
223,14,126,4,0,
223,14,127,4,0,
223,14,129,2,0,
223,14,156,4,0,
223,14,164,4,0,
223,14,168,4,0,
223,14,173,2,0,
223,14,173,3,0,
223,14,175,2,0,
223,14,182,4,0,
223,14,190,2,0,
223,14,196,3,0,
223,14,199,1,6,
223,14,207,4,0,
223,14,213,4,0,
223,14,214,3,0,
223,14,216,4,0,
223,14,218,4,0,
223,14,237,4,0,
223,14,240,4,0,
223,14,241,4,0,
223,14,263,4,0,
223,14,291,4,0,
223,14,323,2,0,
223,14,324,1,30,
223,14,324,3,0,
223,14,331,1,38,
223,14,340,3,0,
223,14,341,2,0,
223,14,350,2,0,
223,14,352,1,26,
223,14,352,2,0,
223,14,402,3,0,
223,14,441,3,0,
223,14,451,4,0,
223,14,479,4,0,
223,14,487,1,50,
223,14,491,2,0,
223,14,496,4,0,
223,14,503,4,0,
223,14,510,4,0,
223,15,48,2,0,
223,15,53,4,0,
223,15,55,1,1,
223,15,56,1,42,
223,15,57,4,0,
223,15,58,1,34,
223,15,58,4,0,
223,15,59,4,0,
223,15,60,1,10,
223,15,61,1,18,
223,15,62,1,14,
223,15,62,2,0,
223,15,63,1,46,
223,15,63,4,0,
223,15,86,4,0,
223,15,92,4,0,
223,15,94,4,0,
223,15,103,2,0,
223,15,104,4,0,
223,15,114,2,0,
223,15,116,1,22,
223,15,126,4,0,
223,15,127,4,0,
223,15,129,2,0,
223,15,156,4,0,
223,15,164,4,0,
223,15,168,4,0,
223,15,173,2,0,
223,15,175,2,0,
223,15,182,4,0,
223,15,190,2,0,
223,15,199,1,6,
223,15,207,4,0,
223,15,213,4,0,
223,15,214,4,0,
223,15,216,4,0,
223,15,218,4,0,
223,15,237,4,0,
223,15,240,4,0,
223,15,241,4,0,
223,15,263,4,0,
223,15,323,2,0,
223,15,324,1,30,
223,15,331,1,38,
223,15,341,2,0,
223,15,350,2,0,
223,15,352,1,26,
223,15,352,2,0,
223,15,451,4,0,
223,15,479,4,0,
223,15,487,1,50,
223,15,491,2,0,
223,15,494,2,0,
223,15,496,4,0,
223,15,503,4,0,
223,15,510,4,0,
223,15,590,4,0,
223,16,48,2,0,
223,16,53,4,0,
223,16,55,1,1,1
223,16,56,1,42,1
223,16,57,4,0,
223,16,58,1,34,1
223,16,58,4,0,
223,16,59,4,0,
223,16,60,1,10,1
223,16,61,1,18,1
223,16,62,1,14,1
223,16,62,2,0,
223,16,63,1,46,1
223,16,63,4,0,
223,16,86,4,0,
223,16,92,4,0,
223,16,94,4,0,
223,16,103,2,0,
223,16,104,4,0,
223,16,114,2,0,
223,16,116,1,22,1
223,16,126,4,0,
223,16,127,4,0,
223,16,129,2,0,
223,16,156,4,0,
223,16,164,4,0,
223,16,168,4,0,
223,16,173,2,0,
223,16,173,3,0,
223,16,175,2,0,
223,16,182,4,0,
223,16,190,2,0,
223,16,196,3,0,
223,16,199,1,6,1
223,16,207,4,0,
223,16,213,4,0,
223,16,214,4,0,
223,16,216,4,0,
223,16,218,4,0,
223,16,237,4,0,
223,16,240,4,0,
223,16,241,4,0,
223,16,263,4,0,
223,16,290,4,0,
223,16,291,4,0,
223,16,323,2,0,
223,16,324,1,30,1
223,16,324,3,0,
223,16,331,1,38,1
223,16,340,3,0,
223,16,341,2,0,
223,16,350,2,0,
223,16,352,1,26,1
223,16,352,2,0,
223,16,352,3,0,
223,16,402,3,0,
223,16,441,3,0,
223,16,451,4,0,
223,16,479,4,0,
223,16,487,1,50,1
223,16,491,2,0,
223,16,494,2,0,
223,16,496,4,0,
223,16,503,4,0,
223,16,510,4,0,
223,16,590,4,0,
223,17,48,2,0,
223,17,53,4,0,
223,17,55,1,1,
223,17,56,1,42,
223,17,57,4,0,
223,17,58,1,34,
223,17,58,4,0,
223,17,59,4,0,
223,17,60,1,10,
223,17,61,1,18,
223,17,62,1,14,
223,17,62,2,0,
223,17,63,1,46,
223,17,63,4,0,
223,17,86,4,0,
223,17,92,4,0,
223,17,94,4,0,
223,17,103,2,0,
223,17,104,4,0,
223,17,114,2,0,
223,17,116,1,22,
223,17,126,4,0,
223,17,127,4,0,
223,17,129,2,0,
223,17,156,4,0,
223,17,164,4,0,
223,17,168,4,0,
223,17,173,2,0,
223,17,175,2,0,
223,17,182,4,0,
223,17,190,2,0,
223,17,199,1,6,
223,17,207,4,0,
223,17,213,4,0,
223,17,214,4,0,
223,17,216,4,0,
223,17,218,4,0,
223,17,237,4,0,
223,17,240,4,0,
223,17,241,4,0,
223,17,263,4,0,
223,17,323,2,0,
223,17,324,1,30,
223,17,331,1,38,
223,17,341,2,0,
223,17,350,2,0,
223,17,352,1,26,
223,17,352,2,0,
223,17,451,4,0,
223,17,479,4,0,
223,17,487,1,50,
223,17,491,2,0,
223,17,494,2,0,
223,17,496,4,0,
223,17,503,4,0,
223,17,590,4,0,
223,18,48,2,0,
223,18,53,4,0,
223,18,55,1,1,
223,18,56,1,42,
223,18,57,4,0,
223,18,58,1,34,
223,18,58,4,0,
223,18,59,4,0,
223,18,60,1,10,
223,18,61,1,18,
223,18,62,1,14,
223,18,62,2,0,
223,18,63,1,46,
223,18,63,4,0,
223,18,86,4,0,
223,18,92,4,0,
223,18,94,4,0,
223,18,103,2,0,
223,18,104,4,0,
223,18,114,2,0,
223,18,116,1,22,
223,18,126,4,0,
223,18,127,4,0,
223,18,129,2,0,
223,18,156,4,0,
223,18,164,4,0,
223,18,168,4,0,
223,18,173,2,0,
223,18,175,2,0,
223,18,182,4,0,
223,18,190,2,0,
223,18,199,1,6,
223,18,207,4,0,
223,18,213,4,0,
223,18,214,4,0,
223,18,216,4,0,
223,18,218,4,0,
223,18,237,4,0,
223,18,240,4,0,
223,18,241,4,0,
223,18,263,4,0,
223,18,323,2,0,
223,18,324,1,30,
223,18,331,1,38,
223,18,341,2,0,
223,18,350,2,0,
223,18,352,1,26,
223,18,352,2,0,
223,18,451,4,0,
223,18,479,4,0,
223,18,487,1,50,
223,18,491,2,0,
223,18,494,2,0,
223,18,496,4,0,
223,18,503,4,0,
223,18,590,4,0,
224,3,55,1,1,
224,3,57,4,0,
224,3,58,1,54,
224,3,60,1,22,1
224,3,61,1,22,3
224,3,62,1,22,2
224,3,63,1,70,
224,3,63,4,0,
224,3,92,4,0,
224,3,104,4,0,
224,3,111,4,0,
224,3,116,1,38,
224,3,129,4,0,
224,3,132,1,11,
224,3,156,4,0,
224,3,168,4,0,
224,3,173,4,0,
224,3,174,4,0,
224,3,182,4,0,
224,3,189,4,0,
224,3,190,1,25,
224,3,203,4,0,
224,3,207,4,0,
224,3,213,4,0,
224,3,214,4,0,
224,3,216,4,0,
224,3,218,4,0,
224,3,237,4,0,
224,3,240,4,0,
224,3,250,4,0,
224,4,53,3,0,
224,4,55,1,1,
224,4,57,4,0,
224,4,58,1,54,
224,4,58,3,0,
224,4,60,1,22,1
224,4,61,1,22,3
224,4,62,1,22,2
224,4,63,1,70,
224,4,63,4,0,
224,4,92,4,0,
224,4,104,4,0,
224,4,111,4,0,
224,4,116,1,38,
224,4,129,4,0,
224,4,132,1,11,
224,4,156,4,0,
224,4,168,4,0,
224,4,173,4,0,
224,4,174,4,0,
224,4,182,4,0,
224,4,189,4,0,
224,4,190,1,25,
224,4,203,4,0,
224,4,207,4,0,
224,4,213,4,0,
224,4,214,4,0,
224,4,216,4,0,
224,4,218,4,0,
224,4,237,4,0,
224,4,240,4,0,
224,4,250,4,0,
224,5,53,4,0,
224,5,55,1,1,
224,5,57,4,0,
224,5,58,1,54,
224,5,58,4,0,
224,5,59,4,0,
224,5,60,1,22,1
224,5,61,1,22,3
224,5,62,1,22,2
224,5,63,1,70,
224,5,63,4,0,
224,5,92,4,0,
224,5,94,4,0,
224,5,104,4,0,
224,5,116,1,38,
224,5,126,4,0,
224,5,127,4,0,
224,5,132,1,11,
224,5,156,4,0,
224,5,168,4,0,
224,5,182,4,0,
224,5,188,4,0,
224,5,190,1,25,
224,5,213,4,0,
224,5,216,4,0,
224,5,218,4,0,
224,5,237,4,0,
224,5,240,4,0,
224,5,241,4,0,
224,5,263,4,0,
224,5,290,4,0,
224,5,291,4,0,
224,5,331,4,0,
224,5,352,4,0,
224,6,38,3,0,
224,6,53,4,0,
224,6,55,1,1,
224,6,57,4,0,
224,6,58,1,54,
224,6,58,4,0,
224,6,59,4,0,
224,6,60,1,22,1
224,6,61,1,22,3
224,6,62,1,22,2
224,6,63,1,70,
224,6,63,4,0,
224,6,69,3,0,
224,6,86,3,0,
224,6,92,4,0,
224,6,94,4,0,
224,6,102,3,0,
224,6,104,4,0,
224,6,111,3,0,
224,6,116,1,38,
224,6,126,4,0,
224,6,127,4,0,
224,6,129,3,0,
224,6,132,1,11,
224,6,156,4,0,
224,6,164,3,0,
224,6,168,4,0,
224,6,173,3,0,
224,6,182,4,0,
224,6,188,4,0,
224,6,189,3,0,
224,6,190,1,25,
224,6,203,3,0,
224,6,207,3,0,
224,6,213,4,0,
224,6,214,3,0,
224,6,216,4,0,
224,6,218,4,0,
224,6,237,4,0,
224,6,240,4,0,
224,6,241,4,0,
224,6,263,4,0,
224,6,290,4,0,
224,6,291,4,0,
224,6,331,4,0,
224,6,352,4,0,
224,7,38,3,0,
224,7,53,4,0,
224,7,55,1,1,
224,7,57,4,0,
224,7,58,1,54,
224,7,58,4,0,
224,7,59,4,0,
224,7,60,1,22,1
224,7,61,1,22,3
224,7,62,1,22,2
224,7,63,1,70,
224,7,63,4,0,
224,7,69,3,0,
224,7,86,3,0,
224,7,92,4,0,
224,7,94,4,0,
224,7,102,3,0,
224,7,104,4,0,
224,7,116,1,38,
224,7,126,4,0,
224,7,127,4,0,
224,7,132,1,11,
224,7,156,4,0,
224,7,164,3,0,
224,7,168,4,0,
224,7,182,4,0,
224,7,188,4,0,
224,7,190,1,25,
224,7,213,4,0,
224,7,216,4,0,
224,7,218,4,0,
224,7,237,4,0,
224,7,240,4,0,
224,7,241,4,0,
224,7,263,4,0,
224,7,290,4,0,
224,7,291,4,0,
224,7,331,4,0,
224,7,352,4,0,
224,8,53,4,0,
224,8,55,1,1,3
224,8,57,4,0,
224,8,58,1,48,
224,8,58,4,0,
224,8,59,4,0,
224,8,60,1,1,5
224,8,60,1,10,
224,8,61,1,19,
224,8,62,1,1,6
224,8,62,1,14,
224,8,63,1,55,
224,8,63,4,0,
224,8,86,4,0,
224,8,92,4,0,
224,8,94,4,0,
224,8,104,4,0,
224,8,116,1,23,
224,8,126,4,0,
224,8,127,4,0,
224,8,132,1,1,4
224,8,132,1,6,
224,8,156,4,0,
224,8,164,4,0,
224,8,168,4,0,
224,8,182,4,0,
224,8,188,4,0,
224,8,190,1,25,
224,8,203,4,0,
224,8,207,4,0,
224,8,213,4,0,
224,8,214,4,0,
224,8,216,4,0,
224,8,218,4,0,
224,8,237,4,0,
224,8,240,4,0,
224,8,241,4,0,
224,8,263,4,0,
224,8,290,4,0,
224,8,324,1,42,
224,8,331,1,29,
224,8,331,4,0,
224,8,350,1,1,2
224,8,352,4,0,
224,8,362,4,0,
224,8,363,4,0,
224,8,371,4,0,
224,8,378,1,36,
224,8,412,4,0,
224,8,416,4,0,
224,8,430,4,0,
224,8,441,1,1,1
224,8,445,4,0,
224,8,451,4,0,
224,9,53,4,0,
224,9,55,1,1,3
224,9,57,4,0,
224,9,58,1,48,
224,9,58,4,0,
224,9,59,4,0,
224,9,60,1,1,5
224,9,60,1,10,
224,9,61,1,19,
224,9,62,1,1,6
224,9,62,1,14,
224,9,63,1,55,
224,9,63,4,0,
224,9,86,4,0,
224,9,92,4,0,
224,9,94,4,0,
224,9,104,4,0,
224,9,116,1,23,
224,9,126,4,0,
224,9,127,4,0,
224,9,129,3,0,
224,9,132,1,1,4
224,9,132,1,6,
224,9,156,4,0,
224,9,164,4,0,
224,9,168,4,0,
224,9,173,3,0,
224,9,182,4,0,
224,9,188,4,0,
224,9,189,3,0,
224,9,190,1,25,
224,9,196,3,0,
224,9,203,4,0,
224,9,207,4,0,
224,9,213,4,0,
224,9,214,4,0,
224,9,216,4,0,
224,9,218,4,0,
224,9,237,4,0,
224,9,240,4,0,
224,9,241,4,0,
224,9,263,4,0,
224,9,290,4,0,
224,9,291,3,0,
224,9,324,1,42,
224,9,324,3,0,
224,9,331,1,29,
224,9,331,4,0,
224,9,340,3,0,
224,9,350,1,1,2
224,9,352,4,0,
224,9,362,4,0,
224,9,363,4,0,
224,9,371,4,0,
224,9,378,1,36,
224,9,402,3,0,
224,9,412,4,0,
224,9,416,4,0,
224,9,430,4,0,
224,9,441,1,1,1
224,9,441,3,0,
224,9,445,4,0,
224,9,451,4,0,
224,10,53,4,0,
224,10,55,1,1,3
224,10,57,4,0,
224,10,58,1,48,
224,10,58,4,0,
224,10,59,4,0,
224,10,60,1,1,5
224,10,60,1,10,
224,10,61,1,19,
224,10,62,1,1,6
224,10,62,1,14,
224,10,63,1,55,
224,10,63,4,0,
224,10,81,3,0,
224,10,86,4,0,
224,10,92,4,0,
224,10,94,4,0,
224,10,104,4,0,
224,10,116,1,23,
224,10,126,4,0,
224,10,127,4,0,
224,10,129,3,0,
224,10,132,1,1,4
224,10,132,1,6,
224,10,156,4,0,
224,10,164,4,0,
224,10,168,4,0,
224,10,173,3,0,
224,10,182,4,0,
224,10,188,4,0,
224,10,189,3,0,
224,10,190,1,25,
224,10,196,3,0,
224,10,203,4,0,
224,10,207,4,0,
224,10,213,4,0,
224,10,214,4,0,
224,10,216,4,0,
224,10,218,4,0,
224,10,237,4,0,
224,10,240,4,0,
224,10,241,4,0,
224,10,250,4,0,
224,10,263,4,0,
224,10,290,4,0,
224,10,291,3,0,
224,10,324,1,42,
224,10,324,3,0,
224,10,331,1,29,
224,10,331,4,0,
224,10,340,3,0,
224,10,350,1,1,2
224,10,352,4,0,
224,10,362,4,0,
224,10,363,4,0,
224,10,371,4,0,
224,10,378,1,36,
224,10,402,3,0,
224,10,412,4,0,
224,10,416,4,0,
224,10,430,4,0,
224,10,441,1,1,1
224,10,441,3,0,
224,10,445,4,0,
224,10,451,4,0,
224,11,53,4,0,
224,11,55,1,1,3
224,11,57,4,0,
224,11,58,1,48,
224,11,58,4,0,
224,11,59,4,0,
224,11,60,1,1,5
224,11,60,1,10,
224,11,61,1,19,
224,11,62,1,1,6
224,11,62,1,14,
224,11,63,1,55,
224,11,63,4,0,
224,11,86,4,0,
224,11,92,4,0,
224,11,94,4,0,
224,11,104,4,0,
224,11,116,1,23,
224,11,126,4,0,
224,11,127,4,0,
224,11,132,1,1,4
224,11,132,1,6,
224,11,156,4,0,
224,11,164,4,0,
224,11,168,4,0,
224,11,182,4,0,
224,11,188,4,0,
224,11,190,1,25,
224,11,207,4,0,
224,11,213,4,0,
224,11,216,4,0,
224,11,218,4,0,
224,11,237,4,0,
224,11,240,4,0,
224,11,241,4,0,
224,11,263,4,0,
224,11,291,4,0,
224,11,324,1,42,
224,11,331,1,29,
224,11,350,1,1,2
224,11,371,4,0,
224,11,378,1,36,
224,11,412,4,0,
224,11,416,4,0,
224,11,430,4,0,
224,11,441,1,1,1
224,11,451,4,0,
224,11,479,4,0,
224,11,482,4,0,
224,11,487,1,61,
224,11,496,4,0,
224,11,503,4,0,
224,11,510,4,0,
224,12,53,4,0,
224,12,55,1,1,
224,12,57,4,0,
224,12,58,1,54,
224,12,58,4,0,
224,12,59,4,0,
224,12,60,1,22,1
224,12,61,1,22,3
224,12,62,1,22,2
224,12,63,1,70,
224,12,63,4,0,
224,12,92,4,0,
224,12,94,4,0,
224,12,104,4,0,
224,12,116,1,38,
224,12,126,4,0,
224,12,127,4,0,
224,12,132,1,11,
224,12,156,4,0,
224,12,168,4,0,
224,12,182,4,0,
224,12,188,4,0,
224,12,190,1,25,
224,12,213,4,0,
224,12,216,4,0,
224,12,218,4,0,
224,12,237,4,0,
224,12,240,4,0,
224,12,241,4,0,
224,12,263,4,0,
224,12,290,4,0,
224,12,291,4,0,
224,12,331,4,0,
224,12,352,4,0,
224,13,38,3,0,
224,13,53,4,0,
224,13,55,1,1,
224,13,57,4,0,
224,13,58,1,54,
224,13,58,4,0,
224,13,59,4,0,
224,13,60,1,22,1
224,13,61,1,22,3
224,13,62,1,22,2
224,13,63,1,70,
224,13,63,4,0,
224,13,69,3,0,
224,13,86,3,0,
224,13,92,4,0,
224,13,94,4,0,
224,13,102,3,0,
224,13,104,4,0,
224,13,116,1,38,
224,13,126,4,0,
224,13,127,4,0,
224,13,132,1,11,
224,13,156,4,0,
224,13,164,3,0,
224,13,168,4,0,
224,13,182,4,0,
224,13,188,4,0,
224,13,190,1,25,
224,13,207,3,0,
224,13,213,4,0,
224,13,216,4,0,
224,13,218,4,0,
224,13,237,4,0,
224,13,240,4,0,
224,13,241,4,0,
224,13,263,4,0,
224,13,290,4,0,
224,13,291,4,0,
224,13,331,4,0,
224,13,352,4,0,
224,14,20,3,0,
224,14,53,4,0,
224,14,55,1,1,3
224,14,56,1,52,
224,14,57,4,0,
224,14,58,1,40,
224,14,58,4,0,
224,14,59,4,0,
224,14,60,1,1,5
224,14,60,1,10,
224,14,61,1,18,
224,14,62,1,1,6
224,14,62,1,14,
224,14,63,1,58,
224,14,63,4,0,
224,14,86,4,0,
224,14,92,4,0,
224,14,94,4,0,
224,14,104,4,0,
224,14,116,1,22,
224,14,126,4,0,
224,14,127,4,0,
224,14,132,1,1,4
224,14,132,1,6,
224,14,156,4,0,
224,14,164,4,0,
224,14,168,4,0,
224,14,173,3,0,
224,14,182,4,0,
224,14,188,4,0,
224,14,190,1,25,
224,14,196,3,0,
224,14,207,4,0,
224,14,213,4,0,
224,14,214,3,0,
224,14,216,4,0,
224,14,218,4,0,
224,14,237,4,0,
224,14,240,4,0,
224,14,241,4,0,
224,14,263,4,0,
224,14,291,4,0,
224,14,324,1,34,
224,14,324,3,0,
224,14,331,1,46,
224,14,340,3,0,
224,14,350,1,1,2
224,14,371,4,0,
224,14,378,1,28,
224,14,402,3,0,
224,14,412,4,0,
224,14,416,4,0,
224,14,430,4,0,
224,14,441,1,1,1
224,14,441,3,0,
224,14,451,4,0,
224,14,479,4,0,
224,14,482,4,0,
224,14,487,1,64,
224,14,496,4,0,
224,14,503,4,0,
224,14,510,4,0,
224,15,53,4,0,
224,15,55,1,1,3
224,15,56,1,52,
224,15,57,4,0,
224,15,58,1,40,
224,15,58,4,0,
224,15,59,4,0,
224,15,60,1,1,5
224,15,60,1,10,
224,15,61,1,18,
224,15,62,1,1,6
224,15,62,1,14,
224,15,63,1,58,
224,15,63,4,0,
224,15,86,4,0,
224,15,92,4,0,
224,15,94,4,0,
224,15,104,4,0,
224,15,116,1,22,
224,15,126,4,0,
224,15,127,4,0,
224,15,132,1,1,4
224,15,132,1,6,
224,15,156,4,0,
224,15,164,4,0,
224,15,168,4,0,
224,15,182,4,0,
224,15,188,4,0,
224,15,190,1,25,
224,15,207,4,0,
224,15,213,4,0,
224,15,214,4,0,
224,15,216,4,0,
224,15,218,4,0,
224,15,237,4,0,
224,15,240,4,0,
224,15,241,4,0,
224,15,263,4,0,
224,15,324,1,34,
224,15,331,1,46,
224,15,350,1,1,2
224,15,371,4,0,
224,15,378,1,28,
224,15,412,4,0,
224,15,416,4,0,
224,15,430,4,0,
224,15,441,1,1,1
224,15,451,4,0,
224,15,479,4,0,
224,15,482,4,0,
224,15,487,1,64,
224,15,496,4,0,
224,15,503,4,0,
224,15,510,4,0,
224,15,590,4,0,
224,16,20,3,0,
224,16,53,4,0,
224,16,55,1,1,3
224,16,56,1,52,1
224,16,57,4,0,
224,16,58,1,40,1
224,16,58,4,0,
224,16,59,4,0,
224,16,60,1,1,5
224,16,60,1,10,1
224,16,61,1,18,1
224,16,62,1,1,6
224,16,62,1,14,1
224,16,63,1,58,1
224,16,63,4,0,
224,16,86,4,0,
224,16,92,4,0,
224,16,94,4,0,
224,16,104,4,0,
224,16,116,1,22,1
224,16,126,4,0,
224,16,127,4,0,
224,16,132,1,1,4
224,16,132,1,6,1
224,16,156,4,0,
224,16,164,4,0,
224,16,168,4,0,
224,16,173,3,0,
224,16,182,4,0,
224,16,188,4,0,
224,16,190,1,25,1
224,16,196,3,0,
224,16,207,4,0,
224,16,213,4,0,
224,16,214,4,0,
224,16,216,4,0,
224,16,218,4,0,
224,16,237,4,0,
224,16,240,4,0,
224,16,241,4,0,
224,16,263,4,0,
224,16,290,4,0,
224,16,291,4,0,
224,16,324,1,34,1
224,16,324,3,0,
224,16,331,1,46,1
224,16,340,3,0,
224,16,350,1,1,2
224,16,352,3,0,
224,16,371,4,0,
224,16,378,1,28,1
224,16,402,3,0,
224,16,412,4,0,
224,16,416,4,0,
224,16,430,4,0,
224,16,441,1,1,1
224,16,441,3,0,
224,16,451,4,0,
224,16,479,4,0,
224,16,482,4,0,
224,16,487,1,64,1
224,16,496,4,0,
224,16,503,4,0,
224,16,510,4,0,
224,16,590,4,0,
224,17,53,4,0,
224,17,55,1,1,4
224,17,56,1,52,
224,17,57,4,0,
224,17,58,1,40,
224,17,58,4,0,
224,17,59,4,0,
224,17,60,1,1,6
224,17,60,1,10,
224,17,61,1,18,
224,17,62,1,1,7
224,17,62,1,14,
224,17,63,1,58,
224,17,63,4,0,
224,17,86,4,0,
224,17,92,4,0,
224,17,94,4,0,
224,17,104,4,0,
224,17,116,1,22,
224,17,126,4,0,
224,17,127,4,0,
224,17,132,1,1,5
224,17,132,1,6,
224,17,156,4,0,
224,17,164,4,0,
224,17,168,4,0,
224,17,182,4,0,
224,17,188,4,0,
224,17,190,1,0,
224,17,190,1,1,1
224,17,207,4,0,
224,17,213,4,0,
224,17,214,4,0,
224,17,216,4,0,
224,17,218,4,0,
224,17,237,4,0,
224,17,240,4,0,
224,17,241,4,0,
224,17,263,4,0,
224,17,324,1,34,
224,17,331,1,46,
224,17,350,1,1,3
224,17,371,4,0,
224,17,378,1,28,
224,17,412,4,0,
224,17,416,4,0,
224,17,430,4,0,
224,17,441,1,1,2
224,17,451,4,0,
224,17,479,4,0,
224,17,482,4,0,
224,17,487,1,64,
224,17,496,4,0,
224,17,503,4,0,
224,17,590,4,0,
224,18,53,4,0,
224,18,55,1,1,4
224,18,56,1,52,
224,18,57,4,0,
224,18,58,1,40,
224,18,58,4,0,
224,18,59,4,0,
224,18,60,1,1,6
224,18,60,1,10,
224,18,61,1,18,
224,18,62,1,1,7
224,18,62,1,14,
224,18,63,1,58,
224,18,63,4,0,
224,18,86,4,0,
224,18,92,4,0,
224,18,94,4,0,
224,18,104,4,0,
224,18,116,1,22,
224,18,126,4,0,
224,18,127,4,0,
224,18,132,1,1,5
224,18,132,1,6,
224,18,156,4,0,
224,18,164,4,0,
224,18,168,4,0,
224,18,182,4,0,
224,18,188,4,0,
224,18,190,1,0,
224,18,190,1,1,1
224,18,207,4,0,
224,18,213,4,0,
224,18,214,4,0,
224,18,216,4,0,
224,18,218,4,0,
224,18,237,4,0,
224,18,240,4,0,
224,18,241,4,0,
224,18,263,4,0,
224,18,324,1,34,
224,18,331,1,46,
224,18,350,1,1,3
224,18,371,4,0,
224,18,378,1,28,
224,18,412,4,0,
224,18,416,4,0,
224,18,430,4,0,
224,18,441,1,1,2
224,18,451,4,0,
224,18,479,4,0,
224,18,482,4,0,
224,18,487,1,64,
224,18,496,4,0,
224,18,503,4,0,
224,18,590,4,0,
225,3,19,4,0,
225,3,29,4,0,
225,3,59,4,0,
225,3,62,2,0,
225,3,92,4,0,
225,3,98,2,0,
225,3,104,4,0,
225,3,129,4,0,
225,3,150,2,0,
225,3,156,4,0,
225,3,168,4,0,
225,3,173,4,0,
225,3,174,4,0,
225,3,182,4,0,
225,3,189,4,0,
225,3,196,4,0,
225,3,197,4,0,
225,3,203,4,0,
225,3,207,4,0,
225,3,213,4,0,
225,3,214,4,0,
225,3,216,4,0,
225,3,217,1,1,
225,3,218,4,0,
225,3,229,2,0,
225,3,237,4,0,
225,3,240,4,0,
225,3,248,2,0,
225,4,19,4,0,
225,4,29,4,0,
225,4,58,3,0,
225,4,59,4,0,
225,4,62,2,0,
225,4,92,4,0,
225,4,98,2,0,
225,4,104,4,0,
225,4,129,4,0,
225,4,150,2,0,
225,4,156,4,0,
225,4,168,4,0,
225,4,173,4,0,
225,4,174,4,0,
225,4,182,4,0,
225,4,189,4,0,
225,4,196,4,0,
225,4,197,4,0,
225,4,203,4,0,
225,4,207,4,0,
225,4,213,4,0,
225,4,214,4,0,
225,4,216,4,0,
225,4,217,1,1,
225,4,218,4,0,
225,4,229,2,0,
225,4,237,4,0,
225,4,240,4,0,
225,4,248,2,0,
225,5,19,4,0,
225,5,58,4,0,
225,5,59,4,0,
225,5,62,2,0,
225,5,92,4,0,
225,5,98,2,0,
225,5,104,4,0,
225,5,150,2,0,
225,5,156,4,0,
225,5,168,4,0,
225,5,182,4,0,
225,5,213,4,0,
225,5,216,4,0,
225,5,217,1,1,
225,5,218,4,0,
225,5,229,2,0,
225,5,237,4,0,
225,5,240,4,0,
225,5,248,2,0,
225,5,258,4,0,
225,5,263,4,0,
225,5,264,4,0,
225,5,290,4,0,
225,5,301,2,0,
225,5,332,4,0,
225,5,352,4,0,
225,6,5,3,0,
225,6,19,4,0,
225,6,25,3,0,
225,6,34,3,0,
225,6,38,3,0,
225,6,58,4,0,
225,6,59,4,0,
225,6,62,2,0,
225,6,68,3,0,
225,6,69,3,0,
225,6,92,4,0,
225,6,98,2,0,
225,6,102,3,0,
225,6,104,4,0,
225,6,129,3,0,
225,6,150,2,0,
225,6,156,4,0,
225,6,164,3,0,
225,6,168,4,0,
225,6,173,3,0,
225,6,182,4,0,
225,6,189,3,0,
225,6,196,3,0,
225,6,203,3,0,
225,6,207,3,0,
225,6,213,4,0,
225,6,214,3,0,
225,6,216,4,0,
225,6,217,1,1,
225,6,218,4,0,
225,6,229,2,0,
225,6,237,4,0,
225,6,240,4,0,
225,6,248,2,0,
225,6,258,4,0,
225,6,263,4,0,
225,6,264,4,0,
225,6,290,4,0,
225,6,301,2,0,
225,6,332,4,0,
225,6,352,4,0,
225,7,5,3,0,
225,7,19,4,0,
225,7,25,3,0,
225,7,34,3,0,
225,7,38,3,0,
225,7,58,4,0,
225,7,59,4,0,
225,7,62,2,0,
225,7,68,3,0,
225,7,69,3,0,
225,7,92,4,0,
225,7,98,2,0,
225,7,102,3,0,
225,7,104,4,0,
225,7,150,2,0,
225,7,156,4,0,
225,7,164,3,0,
225,7,168,4,0,
225,7,182,4,0,
225,7,213,4,0,
225,7,216,4,0,
225,7,217,1,1,
225,7,218,4,0,
225,7,229,2,0,
225,7,237,4,0,
225,7,240,4,0,
225,7,248,2,0,
225,7,258,4,0,
225,7,263,4,0,
225,7,264,4,0,
225,7,290,4,0,
225,7,301,2,0,
225,7,332,4,0,
225,7,352,4,0,
225,8,8,2,0,
225,8,19,4,0,
225,8,58,4,0,
225,8,59,4,0,
225,8,62,2,0,
225,8,92,4,0,
225,8,98,2,0,
225,8,104,4,0,
225,8,150,2,0,
225,8,156,4,0,
225,8,164,4,0,
225,8,168,4,0,
225,8,182,4,0,
225,8,203,4,0,
225,8,207,4,0,
225,8,213,4,0,
225,8,214,4,0,
225,8,216,4,0,
225,8,217,1,1,
225,8,218,4,0,
225,8,229,2,0,
225,8,237,4,0,
225,8,240,4,0,
225,8,248,2,0,
225,8,258,4,0,
225,8,263,4,0,
225,8,264,4,0,
225,8,278,4,0,
225,8,280,4,0,
225,8,290,4,0,
225,8,301,2,0,
225,8,332,4,0,
225,8,352,4,0,
225,8,363,4,0,
225,8,365,4,0,
225,8,374,4,0,
225,8,419,4,0,
225,8,420,2,0,
225,8,432,4,0,
225,8,445,4,0,
225,9,8,2,0,
225,9,19,4,0,
225,9,58,4,0,
225,9,59,4,0,
225,9,62,2,0,
225,9,92,4,0,
225,9,98,2,0,
225,9,104,4,0,
225,9,129,3,0,
225,9,150,2,0,
225,9,156,4,0,
225,9,164,4,0,
225,9,168,4,0,
225,9,182,4,0,
225,9,189,3,0,
225,9,196,3,0,
225,9,203,4,0,
225,9,205,3,0,
225,9,207,4,0,
225,9,213,4,0,
225,9,214,4,0,
225,9,216,4,0,
225,9,217,1,1,
225,9,218,4,0,
225,9,229,2,0,
225,9,237,4,0,
225,9,240,4,0,
225,9,248,2,0,
225,9,258,4,0,
225,9,263,4,0,
225,9,264,4,0,
225,9,278,4,0,
225,9,280,4,0,
225,9,290,4,0,
225,9,301,2,0,
225,9,324,3,0,
225,9,332,4,0,
225,9,340,3,0,
225,9,352,4,0,
225,9,363,4,0,
225,9,365,4,0,
225,9,374,4,0,
225,9,402,3,0,
225,9,419,4,0,
225,9,420,2,0,
225,9,432,4,0,
225,9,441,3,0,
225,9,445,4,0,
225,10,8,2,0,
225,10,19,4,0,
225,10,29,3,0,
225,10,58,4,0,
225,10,59,4,0,
225,10,62,2,0,
225,10,92,4,0,
225,10,98,2,0,
225,10,104,4,0,
225,10,129,3,0,
225,10,143,3,0,
225,10,150,2,0,
225,10,156,4,0,
225,10,164,4,0,
225,10,168,4,0,
225,10,182,4,0,
225,10,189,3,0,
225,10,196,3,0,
225,10,203,4,0,
225,10,205,3,0,
225,10,207,4,0,
225,10,213,4,0,
225,10,214,4,0,
225,10,216,4,0,
225,10,217,1,1,
225,10,218,4,0,
225,10,229,2,0,
225,10,237,4,0,
225,10,240,4,0,
225,10,248,2,0,
225,10,252,2,0,
225,10,258,4,0,
225,10,263,4,0,
225,10,264,4,0,
225,10,278,4,0,
225,10,280,4,0,
225,10,290,4,0,
225,10,301,2,0,
225,10,324,3,0,
225,10,332,4,0,
225,10,340,3,0,
225,10,352,4,0,
225,10,363,4,0,
225,10,365,4,0,
225,10,374,4,0,
225,10,402,3,0,
225,10,419,4,0,
225,10,420,2,0,
225,10,441,3,0,
225,10,445,4,0,
225,11,8,2,0,
225,11,19,4,0,
225,11,58,4,0,
225,11,59,4,0,
225,11,62,2,0,
225,11,92,4,0,
225,11,98,2,0,
225,11,104,4,0,
225,11,150,2,0,
225,11,156,4,0,
225,11,164,4,0,
225,11,168,4,0,
225,11,182,4,0,
225,11,196,2,0,
225,11,207,4,0,
225,11,213,4,0,
225,11,216,4,0,
225,11,217,1,1,
225,11,218,4,0,
225,11,229,2,0,
225,11,237,4,0,
225,11,240,4,0,
225,11,248,2,0,
225,11,252,2,0,
225,11,258,4,0,
225,11,263,4,0,
225,11,280,4,0,
225,11,301,2,0,
225,11,332,4,0,
225,11,365,4,0,
225,11,374,4,0,
225,11,420,2,0,
225,11,496,4,0,
225,11,516,2,0,
225,11,524,4,0,
225,12,19,4,0,
225,12,58,4,0,
225,12,59,4,0,
225,12,92,4,0,
225,12,104,4,0,
225,12,156,4,0,
225,12,168,4,0,
225,12,182,4,0,
225,12,213,4,0,
225,12,216,4,0,
225,12,217,1,1,
225,12,218,4,0,
225,12,237,4,0,
225,12,240,4,0,
225,12,258,4,0,
225,12,263,4,0,
225,12,264,4,0,
225,12,290,4,0,
225,12,332,4,0,
225,12,352,4,0,
225,13,19,4,0,
225,13,34,3,0,
225,13,38,3,0,
225,13,58,4,0,
225,13,59,4,0,
225,13,69,3,0,
225,13,92,4,0,
225,13,102,3,0,
225,13,104,4,0,
225,13,143,3,0,
225,13,156,4,0,
225,13,164,3,0,
225,13,168,4,0,
225,13,182,4,0,
225,13,196,3,0,
225,13,207,3,0,
225,13,213,4,0,
225,13,216,4,0,
225,13,217,1,1,
225,13,218,4,0,
225,13,237,4,0,
225,13,240,4,0,
225,13,258,4,0,
225,13,263,4,0,
225,13,264,4,0,
225,13,290,4,0,
225,13,332,4,0,
225,13,352,4,0,
225,14,8,2,0,
225,14,8,3,0,
225,14,19,4,0,
225,14,58,4,0,
225,14,59,4,0,
225,14,62,2,0,
225,14,92,4,0,
225,14,98,2,0,
225,14,104,4,0,
225,14,143,3,0,
225,14,150,2,0,
225,14,156,4,0,
225,14,164,4,0,
225,14,168,4,0,
225,14,182,4,0,
225,14,196,2,0,
225,14,196,3,0,
225,14,207,4,0,
225,14,213,4,0,
225,14,214,3,0,
225,14,216,4,0,
225,14,217,1,1,
225,14,218,4,0,
225,14,229,2,0,
225,14,237,4,0,
225,14,240,4,0,
225,14,248,2,0,
225,14,252,2,0,
225,14,258,4,0,
225,14,263,4,0,
225,14,278,3,0,
225,14,280,4,0,
225,14,301,2,0,
225,14,324,3,0,
225,14,332,4,0,
225,14,340,3,0,
225,14,365,4,0,
225,14,374,4,0,
225,14,402,3,0,
225,14,420,2,0,
225,14,441,3,0,
225,14,496,4,0,
225,14,516,2,0,
225,14,524,4,0,
225,15,8,2,0,
225,15,19,4,0,
225,15,58,4,0,
225,15,59,4,0,
225,15,62,2,0,
225,15,92,4,0,
225,15,98,2,0,
225,15,104,4,0,
225,15,150,2,0,
225,15,156,4,0,
225,15,164,4,0,
225,15,168,4,0,
225,15,182,4,0,
225,15,191,2,0,
225,15,194,2,0,
225,15,196,2,0,
225,15,207,4,0,
225,15,213,4,0,
225,15,214,4,0,
225,15,216,4,0,
225,15,217,1,1,
225,15,218,4,0,
225,15,229,2,0,
225,15,237,4,0,
225,15,240,4,0,
225,15,248,2,0,
225,15,252,2,0,
225,15,258,4,0,
225,15,263,4,0,
225,15,280,4,0,
225,15,301,2,0,
225,15,332,4,0,
225,15,374,4,0,
225,15,420,2,0,
225,15,496,4,0,
225,15,516,2,0,
225,15,524,4,0,
225,15,573,2,0,
225,15,590,4,0,
225,15,612,4,0,
225,16,8,2,0,
225,16,8,3,0,
225,16,19,4,0,
225,16,58,4,0,
225,16,59,4,0,
225,16,62,2,0,
225,16,92,4,0,
225,16,98,2,0,
225,16,104,4,0,
225,16,143,3,0,
225,16,150,2,0,
225,16,156,4,0,
225,16,164,4,0,
225,16,168,4,0,
225,16,173,3,0,
225,16,182,4,0,
225,16,191,2,0,
225,16,194,2,0,
225,16,196,2,0,
225,16,196,3,0,
225,16,207,4,0,
225,16,213,4,0,
225,16,214,4,0,
225,16,216,4,0,
225,16,217,1,1,1
225,16,218,4,0,
225,16,229,2,0,
225,16,237,4,0,
225,16,240,4,0,
225,16,248,2,0,
225,16,252,2,0,
225,16,258,4,0,
225,16,263,4,0,
225,16,264,3,0,
225,16,278,3,0,
225,16,280,4,0,
225,16,290,4,0,
225,16,301,2,0,
225,16,324,3,0,
225,16,332,4,0,
225,16,340,3,0,
225,16,352,3,0,
225,16,374,4,0,
225,16,402,3,0,
225,16,420,2,0,
225,16,441,3,0,
225,16,496,4,0,
225,16,516,2,0,
225,16,524,4,0,
225,16,573,2,0,
225,16,590,4,0,
225,16,612,4,0,
225,17,8,2,0,
225,17,19,4,0,
225,17,58,4,0,
225,17,59,4,0,
225,17,62,2,0,
225,17,65,1,25,
225,17,92,4,0,
225,17,98,2,0,
225,17,104,4,0,
225,17,150,2,0,
225,17,156,4,0,
225,17,164,4,0,
225,17,168,4,0,
225,17,182,4,0,
225,17,191,2,0,
225,17,194,2,0,
225,17,196,2,0,
225,17,207,4,0,
225,17,213,4,0,
225,17,214,4,0,
225,17,216,4,0,
225,17,217,1,1,
225,17,218,4,0,
225,17,229,2,0,
225,17,237,4,0,
225,17,240,4,0,
225,17,248,2,0,
225,17,252,2,0,
225,17,258,4,0,
225,17,263,4,0,
225,17,280,4,0,
225,17,301,2,0,
225,17,332,4,0,
225,17,374,4,0,
225,17,420,2,0,
225,17,496,4,0,
225,17,516,2,0,
225,17,524,4,0,
225,17,573,2,0,
225,17,590,4,0,
225,17,693,4,0,
225,17,694,4,0,
225,18,8,2,0,
225,18,19,4,0,
225,18,58,4,0,
225,18,59,4,0,
225,18,62,2,0,
225,18,65,1,25,
225,18,68,2,0,
225,18,92,4,0,
225,18,98,2,0,
225,18,104,4,0,
225,18,150,2,0,
225,18,156,4,0,
225,18,164,4,0,
225,18,168,4,0,
225,18,182,4,0,
225,18,191,2,0,
225,18,194,2,0,
225,18,196,2,0,
225,18,207,4,0,
225,18,213,4,0,
225,18,214,4,0,
225,18,216,4,0,
225,18,217,1,1,
225,18,218,4,0,
225,18,229,2,0,
225,18,237,4,0,
225,18,240,4,0,
225,18,248,2,0,
225,18,252,2,0,
225,18,258,4,0,
225,18,263,4,0,
225,18,280,4,0,
225,18,301,2,0,
225,18,332,4,0,
225,18,374,4,0,
225,18,420,2,0,
225,18,496,4,0,
225,18,516,2,0,
225,18,524,4,0,
225,18,573,2,0,
225,18,590,4,0,
225,18,693,4,0,
225,18,694,4,0,
226,3,17,1,40,
226,3,21,2,0,
226,3,29,4,0,
226,3,33,1,1,1
226,3,36,1,25,
226,3,48,1,10,
226,3,56,2,0,
226,3,57,4,0,
226,3,59,4,0,
226,3,61,1,18,
226,3,92,4,0,
226,3,97,1,32,
226,3,104,4,0,
226,3,109,1,49,
226,3,114,2,0,
226,3,127,4,0,
226,3,129,4,0,
226,3,145,1,1,2
226,3,156,4,0,
226,3,173,4,0,
226,3,174,4,0,
226,3,182,4,0,
226,3,189,4,0,
226,3,196,4,0,
226,3,203,4,0,
226,3,207,4,0,
226,3,213,4,0,
226,3,214,4,0,
226,3,216,4,0,
226,3,218,4,0,
226,3,237,4,0,
226,3,239,2,0,
226,3,240,4,0,
226,3,250,4,0,
226,4,17,1,40,
226,4,21,2,0,
226,4,29,4,0,
226,4,33,1,1,1
226,4,36,1,25,
226,4,48,1,10,
226,4,56,2,0,
226,4,57,4,0,
226,4,58,3,0,
226,4,59,4,0,
226,4,61,1,18,
226,4,92,4,0,
226,4,97,1,32,
226,4,104,4,0,
226,4,109,1,49,
226,4,114,2,0,
226,4,127,4,0,
226,4,129,4,0,
226,4,145,1,1,2
226,4,156,4,0,
226,4,173,4,0,
226,4,174,4,0,
226,4,182,4,0,
226,4,189,4,0,
226,4,196,4,0,
226,4,203,4,0,
226,4,207,4,0,
226,4,213,4,0,
226,4,214,4,0,
226,4,216,4,0,
226,4,218,4,0,
226,4,237,4,0,
226,4,239,2,0,
226,4,240,4,0,
226,4,250,4,0,
226,5,17,1,36,
226,5,21,2,0,
226,5,33,1,1,1
226,5,36,1,22,
226,5,48,1,8,
226,5,56,2,0,
226,5,57,4,0,
226,5,58,4,0,
226,5,59,4,0,
226,5,61,1,15,
226,5,89,4,0,
226,5,92,4,0,
226,5,97,1,29,
226,5,104,4,0,
226,5,109,1,50,
226,5,114,2,0,
226,5,127,4,0,
226,5,145,1,1,2
226,5,156,4,0,
226,5,157,2,0,
226,5,182,4,0,
226,5,213,4,0,
226,5,216,4,0,
226,5,218,4,0,
226,5,237,4,0,
226,5,239,2,0,
226,5,240,4,0,
226,5,258,4,0,
226,5,263,4,0,
226,5,290,4,0,
226,5,291,4,0,
226,5,300,2,0,
226,5,332,4,0,
226,5,352,1,43,
226,5,352,4,0,
226,6,17,1,36,
226,6,21,2,0,
226,6,33,1,1,1
226,6,34,3,0,
226,6,36,1,22,
226,6,38,3,0,
226,6,48,1,8,
226,6,56,2,0,
226,6,57,4,0,
226,6,58,4,0,
226,6,59,4,0,
226,6,61,1,15,
226,6,89,4,0,
226,6,92,4,0,
226,6,97,1,29,
226,6,102,3,0,
226,6,104,4,0,
226,6,109,1,50,
226,6,114,2,0,
226,6,127,4,0,
226,6,129,3,0,
226,6,145,1,1,2
226,6,156,4,0,
226,6,157,2,0,
226,6,164,3,0,
226,6,173,3,0,
226,6,182,4,0,
226,6,189,3,0,
226,6,196,3,0,
226,6,203,3,0,
226,6,207,3,0,
226,6,213,4,0,
226,6,214,3,0,
226,6,216,4,0,
226,6,218,4,0,
226,6,237,4,0,
226,6,239,2,0,
226,6,240,4,0,
226,6,258,4,0,
226,6,263,4,0,
226,6,290,4,0,
226,6,291,4,0,
226,6,300,2,0,
226,6,332,4,0,
226,6,352,1,43,
226,6,352,4,0,
226,7,17,1,36,
226,7,21,2,0,
226,7,33,1,1,1
226,7,34,3,0,
226,7,36,1,22,
226,7,38,3,0,
226,7,48,1,8,
226,7,56,2,0,
226,7,57,4,0,
226,7,58,4,0,
226,7,59,4,0,
226,7,61,1,15,
226,7,89,4,0,
226,7,92,4,0,
226,7,97,1,29,
226,7,102,3,0,
226,7,104,4,0,
226,7,109,1,50,
226,7,114,2,0,
226,7,127,4,0,
226,7,145,1,1,2
226,7,156,4,0,
226,7,157,2,0,
226,7,164,3,0,
226,7,182,4,0,
226,7,213,4,0,
226,7,216,4,0,
226,7,218,4,0,
226,7,237,4,0,
226,7,239,2,0,
226,7,240,4,0,
226,7,258,4,0,
226,7,263,4,0,
226,7,290,4,0,
226,7,291,4,0,
226,7,300,2,0,
226,7,332,4,0,
226,7,352,1,43,
226,7,352,4,0,
226,8,17,1,22,
226,8,21,2,0,
226,8,29,1,13,
226,8,33,1,1,4
226,8,36,1,31,
226,8,48,1,1,6
226,8,48,1,4,
226,8,56,1,49,
226,8,56,2,0,
226,8,57,4,0,
226,8,58,4,0,
226,8,59,4,0,
226,8,60,1,1,1
226,8,61,1,1,7
226,8,61,1,10,
226,8,63,4,0,
226,8,89,4,0,
226,8,92,4,0,
226,8,97,1,19,
226,8,104,4,0,
226,8,109,1,37,
226,8,114,2,0,
226,8,127,4,0,
226,8,145,1,1,5
226,8,150,2,0,
226,8,156,4,0,
226,8,157,2,0,
226,8,157,4,0,
226,8,164,4,0,
226,8,182,4,0,
226,8,203,4,0,
226,8,207,4,0,
226,8,213,4,0,
226,8,214,4,0,
226,8,216,4,0,
226,8,218,4,0,
226,8,237,4,0,
226,8,239,2,0,
226,8,240,4,0,
226,8,243,2,0,
226,8,258,4,0,
226,8,263,4,0,
226,8,290,4,0,
226,8,300,2,0,
226,8,317,4,0,
226,8,324,1,1,3
226,8,331,1,1,2
226,8,331,4,0,
226,8,332,4,0,
226,8,340,1,40,
226,8,346,2,0,
226,8,352,1,28,
226,8,352,4,0,
226,8,362,4,0,
226,8,363,4,0,
226,8,392,1,46,
226,8,416,4,0,
226,8,432,4,0,
226,8,445,4,0,
226,9,17,1,22,
226,9,21,2,0,
226,9,29,1,13,
226,9,33,1,1,4
226,9,36,1,31,
226,9,48,1,1,6
226,9,48,1,4,
226,9,56,1,49,
226,9,56,2,0,
226,9,57,4,0,
226,9,58,4,0,
226,9,59,4,0,
226,9,60,1,1,1
226,9,61,1,1,7
226,9,61,1,10,
226,9,63,4,0,
226,9,89,4,0,
226,9,92,4,0,
226,9,97,1,19,
226,9,104,4,0,
226,9,109,1,37,
226,9,114,2,0,
226,9,127,4,0,
226,9,129,3,0,
226,9,145,1,1,5
226,9,150,2,0,
226,9,156,4,0,
226,9,157,2,0,
226,9,157,4,0,
226,9,164,4,0,
226,9,173,3,0,
226,9,182,4,0,
226,9,189,3,0,
226,9,196,3,0,
226,9,203,4,0,
226,9,207,4,0,
226,9,213,4,0,
226,9,214,4,0,
226,9,216,4,0,
226,9,218,4,0,
226,9,237,4,0,
226,9,239,2,0,
226,9,240,4,0,
226,9,243,2,0,
226,9,258,4,0,
226,9,263,4,0,
226,9,270,3,0,
226,9,290,4,0,
226,9,291,3,0,
226,9,300,2,0,
226,9,314,3,0,
226,9,317,4,0,
226,9,324,1,1,3
226,9,324,3,0,
226,9,331,1,1,2
226,9,331,4,0,
226,9,332,4,0,
226,9,340,1,40,
226,9,340,3,0,
226,9,346,2,0,
226,9,352,1,28,
226,9,352,4,0,
226,9,362,4,0,
226,9,363,4,0,
226,9,392,1,46,
226,9,401,3,0,
226,9,402,3,0,
226,9,416,4,0,
226,9,432,4,0,
226,9,441,3,0,
226,9,442,3,0,
226,9,445,4,0,
226,10,17,1,22,
226,10,21,2,0,
226,10,29,1,13,
226,10,29,3,0,
226,10,33,1,1,4
226,10,36,1,31,
226,10,48,1,1,6
226,10,48,1,4,
226,10,56,1,49,
226,10,56,2,0,
226,10,57,4,0,
226,10,58,4,0,
226,10,59,4,0,
226,10,60,1,1,1
226,10,61,1,1,7
226,10,61,1,10,
226,10,63,4,0,
226,10,81,3,0,
226,10,89,4,0,
226,10,92,4,0,
226,10,97,1,19,
226,10,104,4,0,
226,10,109,1,37,
226,10,114,2,0,
226,10,127,4,0,
226,10,129,3,0,
226,10,145,1,1,5
226,10,150,2,0,
226,10,156,4,0,
226,10,157,2,0,
226,10,157,4,0,
226,10,164,4,0,
226,10,173,3,0,
226,10,182,4,0,
226,10,189,3,0,
226,10,196,3,0,
226,10,203,4,0,
226,10,207,4,0,
226,10,213,4,0,
226,10,214,4,0,
226,10,216,4,0,
226,10,218,4,0,
226,10,237,4,0,
226,10,239,2,0,
226,10,240,4,0,
226,10,243,2,0,
226,10,250,4,0,
226,10,258,4,0,
226,10,263,4,0,
226,10,270,3,0,
226,10,290,4,0,
226,10,291,3,0,
226,10,300,2,0,
226,10,314,3,0,
226,10,317,4,0,
226,10,324,1,1,3
226,10,324,3,0,
226,10,331,1,1,2
226,10,331,4,0,
226,10,332,4,0,
226,10,340,1,40,
226,10,340,3,0,
226,10,346,2,0,
226,10,352,1,28,
226,10,352,4,0,
226,10,362,4,0,
226,10,363,4,0,
226,10,366,3,0,
226,10,392,1,46,
226,10,401,3,0,
226,10,402,3,0,
226,10,416,4,0,
226,10,441,3,0,
226,10,442,3,0,
226,10,445,4,0,
226,11,17,1,22,
226,11,21,2,0,
226,11,29,1,13,
226,11,33,1,1,4
226,11,36,1,31,
226,11,48,1,1,6
226,11,48,1,4,
226,11,56,1,49,
226,11,56,2,0,
226,11,57,4,0,
226,11,58,4,0,
226,11,59,4,0,
226,11,60,1,1,1
226,11,61,1,1,7
226,11,61,1,10,
226,11,63,4,0,
226,11,89,4,0,
226,11,92,4,0,
226,11,97,1,19,
226,11,104,4,0,
226,11,109,1,37,
226,11,114,2,0,
226,11,127,4,0,
226,11,133,2,0,
226,11,145,1,1,5
226,11,150,2,0,
226,11,156,4,0,
226,11,157,4,0,
226,11,164,4,0,
226,11,182,4,0,
226,11,207,4,0,
226,11,213,4,0,
226,11,216,4,0,
226,11,218,4,0,
226,11,237,4,0,
226,11,239,2,0,
226,11,240,4,0,
226,11,243,2,0,
226,11,258,4,0,
226,11,263,4,0,
226,11,291,4,0,
226,11,300,2,0,
226,11,317,4,0,
226,11,324,1,1,3
226,11,331,1,1,2
226,11,332,4,0,
226,11,340,1,40,
226,11,346,2,0,
226,11,352,1,28,
226,11,392,1,46,
226,11,416,4,0,
226,11,469,2,0,
226,11,496,4,0,
226,11,503,4,0,
226,11,512,4,0,
226,11,523,4,0,
226,12,17,1,36,
226,12,33,1,1,1
226,12,36,1,22,
226,12,48,1,8,
226,12,57,4,0,
226,12,58,4,0,
226,12,59,4,0,
226,12,61,1,15,
226,12,89,4,0,
226,12,92,4,0,
226,12,97,1,29,
226,12,104,4,0,
226,12,109,1,50,
226,12,127,4,0,
226,12,145,1,1,2
226,12,156,4,0,
226,12,182,4,0,
226,12,213,4,0,
226,12,216,4,0,
226,12,218,4,0,
226,12,237,4,0,
226,12,240,4,0,
226,12,258,4,0,
226,12,263,4,0,
226,12,290,4,0,
226,12,291,4,0,
226,12,332,4,0,
226,12,352,1,43,
226,12,352,4,0,
226,13,17,1,36,
226,13,33,1,1,1
226,13,34,3,0,
226,13,36,1,22,
226,13,38,3,0,
226,13,48,1,8,
226,13,57,4,0,
226,13,58,4,0,
226,13,59,4,0,
226,13,61,1,15,
226,13,89,4,0,
226,13,92,4,0,
226,13,97,1,29,
226,13,102,3,0,
226,13,104,4,0,
226,13,109,1,50,
226,13,127,4,0,
226,13,145,1,1,2
226,13,156,4,0,
226,13,164,3,0,
226,13,182,4,0,
226,13,196,3,0,
226,13,207,3,0,
226,13,213,4,0,
226,13,216,4,0,
226,13,218,4,0,
226,13,237,4,0,
226,13,240,4,0,
226,13,258,4,0,
226,13,263,4,0,
226,13,290,4,0,
226,13,291,4,0,
226,13,332,4,0,
226,13,352,1,43,
226,13,352,4,0,
226,14,17,1,14,
226,14,21,2,0,
226,14,29,1,16,
226,14,33,1,1,4
226,14,36,1,27,
226,14,48,1,1,6
226,14,48,1,3,
226,14,56,1,49,
226,14,56,2,0,
226,14,57,4,0,
226,14,58,4,0,
226,14,59,4,0,
226,14,60,1,1,1
226,14,61,1,1,7
226,14,61,1,7,
226,14,63,4,0,
226,14,89,4,0,
226,14,92,4,0,
226,14,97,1,32,
226,14,104,4,0,
226,14,109,1,11,
226,14,114,2,0,
226,14,127,4,0,
226,14,133,2,0,
226,14,145,1,1,5
226,14,150,2,0,
226,14,156,4,0,
226,14,157,4,0,
226,14,164,4,0,
226,14,173,3,0,
226,14,182,4,0,
226,14,196,3,0,
226,14,207,4,0,
226,14,213,4,0,
226,14,214,3,0,
226,14,216,4,0,
226,14,218,4,0,
226,14,237,4,0,
226,14,239,2,0,
226,14,240,4,0,
226,14,243,2,0,
226,14,258,4,0,
226,14,263,4,0,
226,14,270,3,0,
226,14,291,4,0,
226,14,300,2,0,
226,14,317,4,0,
226,14,324,1,1,3
226,14,324,3,0,
226,14,331,1,1,2
226,14,332,4,0,
226,14,340,1,46,
226,14,340,3,0,
226,14,346,2,0,
226,14,352,1,19,
226,14,366,3,0,
226,14,392,1,39,
226,14,401,3,0,
226,14,402,3,0,
226,14,403,1,36,
226,14,416,4,0,
226,14,441,3,0,
226,14,442,3,0,
226,14,469,1,23,
226,14,469,2,0,
226,14,496,4,0,
226,14,503,4,0,
226,14,512,4,0,
226,14,523,4,0,
226,15,17,1,14,
226,15,21,2,0,
226,15,29,1,16,
226,15,33,1,1,4
226,15,36,1,27,
226,15,48,1,1,6
226,15,48,1,3,
226,15,56,1,49,
226,15,56,2,0,
226,15,57,4,0,
226,15,58,4,0,
226,15,59,4,0,
226,15,60,1,1,1
226,15,61,1,1,7
226,15,61,1,7,
226,15,63,4,0,
226,15,89,4,0,
226,15,92,4,0,
226,15,97,1,32,
226,15,104,4,0,
226,15,109,1,11,
226,15,114,2,0,
226,15,127,4,0,
226,15,133,2,0,
226,15,145,1,1,5
226,15,150,2,0,
226,15,156,4,0,
226,15,157,4,0,
226,15,164,4,0,
226,15,182,4,0,
226,15,207,4,0,
226,15,213,4,0,
226,15,214,4,0,
226,15,216,4,0,
226,15,218,4,0,
226,15,237,4,0,
226,15,239,2,0,
226,15,240,4,0,
226,15,243,2,0,
226,15,258,4,0,
226,15,263,4,0,
226,15,300,2,0,
226,15,317,4,0,
226,15,324,1,1,3
226,15,331,1,1,2
226,15,332,4,0,
226,15,340,1,46,
226,15,346,2,0,
226,15,352,1,19,
226,15,392,1,39,
226,15,403,1,36,
226,15,416,4,0,
226,15,469,1,23,
226,15,469,2,0,
226,15,496,4,0,
226,15,503,4,0,
226,15,512,4,0,
226,15,523,4,0,
226,15,590,4,0,
226,16,17,1,14,1
226,16,21,2,0,
226,16,29,1,16,1
226,16,33,1,1,4
226,16,36,1,27,1
226,16,48,1,1,6
226,16,48,1,3,1
226,16,56,1,49,1
226,16,56,2,0,
226,16,57,4,0,
226,16,58,4,0,
226,16,59,4,0,
226,16,60,1,1,1
226,16,61,1,1,7
226,16,61,1,7,1
226,16,63,4,0,
226,16,89,4,0,
226,16,92,4,0,
226,16,97,1,32,1
226,16,104,4,0,
226,16,109,1,11,1
226,16,114,2,0,
226,16,127,4,0,
226,16,133,2,0,
226,16,145,1,1,5
226,16,150,2,0,
226,16,156,4,0,
226,16,157,4,0,
226,16,164,4,0,
226,16,173,3,0,
226,16,182,4,0,
226,16,196,3,0,
226,16,207,4,0,
226,16,213,4,0,
226,16,214,4,0,
226,16,216,4,0,
226,16,218,4,0,
226,16,237,4,0,
226,16,239,2,0,
226,16,240,4,0,
226,16,243,2,0,
226,16,258,4,0,
226,16,263,4,0,
226,16,270,3,0,
226,16,290,4,0,
226,16,291,4,0,
226,16,300,2,0,
226,16,317,4,0,
226,16,324,1,1,3
226,16,324,3,0,
226,16,331,1,1,2
226,16,332,4,0,
226,16,340,1,46,1
226,16,340,3,0,
226,16,346,2,0,
226,16,352,1,19,1
226,16,352,3,0,
226,16,366,3,0,
226,16,392,1,39,1
226,16,401,3,0,
226,16,402,3,0,
226,16,403,1,36,1
226,16,416,4,0,
226,16,441,3,0,
226,16,442,3,0,
226,16,469,1,23,1
226,16,469,2,0,
226,16,496,4,0,
226,16,503,4,0,
226,16,512,4,0,
226,16,523,4,0,
226,16,590,4,0,
226,17,17,1,14,
226,17,21,2,0,
226,17,29,1,16,
226,17,33,1,1,5
226,17,36,1,27,
226,17,48,1,1,7
226,17,48,1,3,
226,17,56,1,49,
226,17,56,2,0,
226,17,57,4,0,
226,17,58,4,0,
226,17,59,4,0,
226,17,60,1,1,1
226,17,61,1,1,8
226,17,61,1,7,
226,17,63,4,0,
226,17,89,4,0,
226,17,92,4,0,
226,17,97,1,32,
226,17,104,4,0,
226,17,109,1,11,
226,17,114,2,0,
226,17,127,4,0,
226,17,133,2,0,
226,17,145,1,1,6
226,17,150,2,0,
226,17,156,4,0,
226,17,157,4,0,
226,17,164,4,0,
226,17,182,4,0,
226,17,207,4,0,
226,17,213,4,0,
226,17,214,4,0,
226,17,216,4,0,
226,17,218,4,0,
226,17,237,4,0,
226,17,239,2,0,
226,17,240,4,0,
226,17,243,2,0,
226,17,258,4,0,
226,17,263,4,0,
226,17,300,2,0,
226,17,317,4,0,
226,17,324,1,1,3
226,17,331,1,1,2
226,17,332,4,0,
226,17,340,1,46,
226,17,346,2,0,
226,17,352,1,19,
226,17,355,1,1,4
226,17,355,4,0,
226,17,392,1,39,
226,17,403,1,36,
226,17,416,4,0,
226,17,469,1,23,
226,17,469,2,0,
226,17,496,4,0,
226,17,503,4,0,
226,17,512,4,0,
226,17,523,4,0,
226,17,590,4,0,
226,18,17,1,14,
226,18,21,2,0,
226,18,29,1,16,
226,18,33,1,1,5
226,18,36,1,27,
226,18,48,1,1,7
226,18,48,1,3,
226,18,56,1,49,
226,18,56,2,0,
226,18,57,4,0,
226,18,58,4,0,
226,18,59,4,0,
226,18,60,1,1,1
226,18,61,1,1,8
226,18,61,1,7,
226,18,63,4,0,
226,18,89,4,0,
226,18,92,4,0,
226,18,97,1,32,
226,18,104,4,0,
226,18,109,1,11,
226,18,114,2,0,
226,18,127,4,0,
226,18,133,2,0,
226,18,145,1,1,6
226,18,150,2,0,
226,18,156,4,0,
226,18,157,4,0,
226,18,164,4,0,
226,18,182,4,0,
226,18,207,4,0,
226,18,213,4,0,
226,18,214,4,0,
226,18,216,4,0,
226,18,218,4,0,
226,18,237,4,0,
226,18,239,2,0,
226,18,240,4,0,
226,18,243,2,0,
226,18,258,4,0,
226,18,263,4,0,
226,18,300,2,0,
226,18,317,4,0,
226,18,324,1,1,3
226,18,331,1,1,2
226,18,332,4,0,
226,18,340,1,46,
226,18,346,2,0,
226,18,352,1,19,
226,18,355,1,1,4
226,18,355,4,0,
226,18,392,1,39,
226,18,403,1,36,
226,18,416,4,0,
226,18,469,1,23,
226,18,469,2,0,
226,18,496,4,0,
226,18,503,4,0,
226,18,512,4,0,
226,18,523,4,0,
226,18,590,4,0,
227,3,15,4,0,
227,3,18,2,0,
227,3,19,4,0,
227,3,28,1,13,
227,3,31,1,37,
227,3,43,1,1,1
227,3,64,1,1,2
227,3,65,2,0,
227,3,92,4,0,
227,3,97,1,25,
227,3,104,4,0,
227,3,129,1,19,
227,3,129,4,0,
227,3,156,4,0,
227,3,168,4,0,
227,3,173,4,0,
227,3,174,4,0,
227,3,182,4,0,
227,3,189,4,0,
227,3,197,4,0,
227,3,201,4,0,
227,3,203,4,0,
227,3,207,4,0,
227,3,211,1,49,
227,3,211,4,0,
227,3,213,4,0,
227,3,214,4,0,
227,3,216,4,0,
227,3,218,4,0,
227,3,228,2,0,
227,3,237,4,0,
227,3,241,4,0,
227,4,15,4,0,
227,4,18,2,0,
227,4,19,4,0,
227,4,28,1,13,
227,4,31,1,37,
227,4,43,1,1,1
227,4,64,1,1,2
227,4,65,2,0,
227,4,92,4,0,
227,4,97,1,25,
227,4,104,4,0,
227,4,129,1,19,
227,4,129,4,0,
227,4,143,2,0,
227,4,156,4,0,
227,4,168,4,0,
227,4,173,4,0,
227,4,174,4,0,
227,4,182,4,0,
227,4,189,4,0,
227,4,197,4,0,
227,4,201,4,0,
227,4,203,4,0,
227,4,207,4,0,
227,4,211,1,49,
227,4,211,4,0,
227,4,213,4,0,
227,4,214,4,0,
227,4,216,4,0,
227,4,218,4,0,
227,4,228,2,0,
227,4,237,4,0,
227,4,241,4,0,
227,5,15,4,0,
227,5,18,2,0,
227,5,19,4,0,
227,5,28,1,10,
227,5,31,1,26,
227,5,43,1,1,1
227,5,46,4,0,
227,5,64,1,1,2
227,5,65,2,0,
227,5,92,4,0,
227,5,97,1,16,
227,5,104,4,0,
227,5,129,1,13,
227,5,143,2,0,
227,5,156,4,0,
227,5,168,4,0,
227,5,174,2,0,
227,5,182,4,0,
227,5,191,1,42,
227,5,201,4,0,
227,5,211,1,32,
227,5,211,4,0,
227,5,213,4,0,
227,5,216,4,0,
227,5,218,4,0,
227,5,228,2,0,
227,5,237,4,0,
227,5,241,4,0,
227,5,249,4,0,
227,5,259,4,0,
227,5,263,4,0,
227,5,269,4,0,
227,5,290,4,0,
227,5,314,1,29,
227,5,319,1,45,
227,5,332,4,0,
227,6,15,4,0,
227,6,18,2,0,
227,6,19,4,0,
227,6,28,1,10,
227,6,31,1,26,
227,6,38,3,0,
227,6,43,1,1,1
227,6,46,4,0,
227,6,64,1,1,2
227,6,65,2,0,
227,6,68,3,0,
227,6,92,4,0,
227,6,97,1,16,
227,6,102,3,0,
227,6,104,4,0,
227,6,129,1,13,
227,6,129,3,0,
227,6,143,2,0,
227,6,156,4,0,
227,6,157,3,0,
227,6,164,3,0,
227,6,168,4,0,
227,6,173,3,0,
227,6,174,2,0,
227,6,182,4,0,
227,6,189,3,0,
227,6,191,1,42,
227,6,201,4,0,
227,6,203,3,0,
227,6,207,3,0,
227,6,211,1,32,
227,6,211,4,0,
227,6,213,4,0,
227,6,214,3,0,
227,6,216,4,0,
227,6,218,4,0,
227,6,228,2,0,
227,6,237,4,0,
227,6,241,4,0,
227,6,249,4,0,
227,6,259,4,0,
227,6,263,4,0,
227,6,269,4,0,
227,6,290,4,0,
227,6,314,1,29,
227,6,319,1,45,
227,6,332,4,0,
227,7,15,4,0,
227,7,18,2,0,
227,7,19,4,0,
227,7,28,1,10,
227,7,31,1,26,
227,7,38,3,0,
227,7,43,1,1,1
227,7,46,4,0,
227,7,64,1,1,2
227,7,65,2,0,
227,7,68,3,0,
227,7,92,4,0,
227,7,97,1,16,
227,7,102,3,0,
227,7,104,4,0,
227,7,129,1,13,
227,7,143,2,0,
227,7,156,4,0,
227,7,157,3,0,
227,7,164,3,0,
227,7,168,4,0,
227,7,174,2,0,
227,7,182,4,0,
227,7,191,1,42,
227,7,201,4,0,
227,7,211,1,32,
227,7,211,4,0,
227,7,213,4,0,
227,7,216,4,0,
227,7,218,4,0,
227,7,228,2,0,
227,7,237,4,0,
227,7,241,4,0,
227,7,249,4,0,
227,7,259,4,0,
227,7,263,4,0,
227,7,269,4,0,
227,7,290,4,0,
227,7,314,1,29,
227,7,319,1,45,
227,7,332,4,0,
227,8,14,4,0,
227,8,15,4,0,
227,8,18,2,0,
227,8,19,4,0,
227,8,28,1,7,
227,8,31,1,20,
227,8,43,1,1,1
227,8,46,4,0,
227,8,64,1,1,2
227,8,65,2,0,
227,8,92,4,0,
227,8,97,1,14,
227,8,104,4,0,
227,8,129,1,11,
227,8,143,2,0,
227,8,148,4,0,
227,8,156,4,0,
227,8,157,4,0,
227,8,163,1,46,
227,8,164,4,0,
227,8,168,4,0,
227,8,174,2,0,
227,8,182,4,0,
227,8,191,1,27,
227,8,201,4,0,
227,8,203,4,0,
227,8,207,4,0,
227,8,211,1,38,
227,8,211,4,0,
227,8,213,4,0,
227,8,214,4,0,
227,8,216,4,0,
227,8,218,4,0,
227,8,228,2,0,
227,8,237,4,0,
227,8,241,4,0,
227,8,249,4,0,
227,8,259,4,0,
227,8,263,4,0,
227,8,269,4,0,
227,8,290,4,0,
227,8,314,1,24,
227,8,317,4,0,
227,8,319,1,33,
227,8,332,4,0,
227,8,355,4,0,
227,8,363,4,0,
227,8,365,4,0,
227,8,371,4,0,
227,8,372,2,0,
227,8,399,4,0,
227,8,400,1,50,
227,8,403,1,40,
227,8,404,4,0,
227,8,413,2,0,
227,8,430,4,0,
227,8,432,4,0,
227,8,445,4,0,
227,8,446,4,0,
227,9,14,4,0,
227,9,15,4,0,
227,9,18,2,0,
227,9,19,4,0,
227,9,28,1,6,
227,9,31,1,17,
227,9,43,1,1,1
227,9,46,4,0,
227,9,64,1,1,2
227,9,65,2,0,
227,9,92,4,0,
227,9,97,1,12,
227,9,104,4,0,
227,9,129,1,9,
227,9,129,3,0,
227,9,143,2,0,
227,9,148,4,0,
227,9,156,4,0,
227,9,157,4,0,
227,9,163,1,42,
227,9,164,4,0,
227,9,168,4,0,
227,9,173,3,0,
227,9,174,2,0,
227,9,182,4,0,
227,9,189,3,0,
227,9,191,1,28,
227,9,196,3,0,
227,9,201,4,0,
227,9,203,4,0,
227,9,207,4,0,
227,9,210,3,0,
227,9,211,1,34,
227,9,211,4,0,
227,9,213,4,0,
227,9,214,4,0,
227,9,216,4,0,
227,9,218,4,0,
227,9,228,2,0,
227,9,237,4,0,
227,9,239,3,0,
227,9,241,4,0,
227,9,249,4,0,
227,9,259,4,0,
227,9,263,4,0,
227,9,269,4,0,
227,9,290,4,0,
227,9,314,1,23,
227,9,314,3,0,
227,9,317,4,0,
227,9,319,1,31,
227,9,332,4,0,
227,9,334,3,0,
227,9,355,4,0,
227,9,363,4,0,
227,9,364,1,20,
227,9,365,4,0,
227,9,371,4,0,
227,9,372,2,0,
227,9,399,4,0,
227,9,400,1,45,
227,9,403,1,39,
227,9,404,4,0,
227,9,413,2,0,
227,9,430,4,0,
227,9,432,4,0,
227,9,445,4,0,
227,9,446,4,0,
227,9,466,3,0,
227,10,14,4,0,
227,10,15,4,0,
227,10,18,2,0,
227,10,19,4,0,
227,10,28,1,6,
227,10,31,1,17,
227,10,43,1,1,1
227,10,46,4,0,
227,10,64,1,1,2
227,10,65,2,0,
227,10,92,4,0,
227,10,97,1,12,
227,10,104,4,0,
227,10,129,1,9,
227,10,129,3,0,
227,10,143,2,0,
227,10,143,3,0,
227,10,148,4,0,
227,10,156,4,0,
227,10,157,4,0,
227,10,163,1,42,
227,10,164,4,0,
227,10,168,4,0,
227,10,173,3,0,
227,10,174,2,0,
227,10,182,4,0,
227,10,189,3,0,
227,10,191,1,28,
227,10,196,3,0,
227,10,201,4,0,
227,10,203,4,0,
227,10,207,4,0,
227,10,210,3,0,
227,10,211,1,34,
227,10,211,4,0,
227,10,213,4,0,
227,10,214,4,0,
227,10,216,4,0,
227,10,218,4,0,
227,10,228,2,0,
227,10,237,4,0,
227,10,239,3,0,
227,10,241,4,0,
227,10,249,4,0,
227,10,259,4,0,
227,10,263,4,0,
227,10,269,4,0,
227,10,290,4,0,
227,10,314,1,23,
227,10,314,3,0,
227,10,317,4,0,
227,10,319,1,31,
227,10,332,4,0,
227,10,334,3,0,
227,10,355,4,0,
227,10,363,4,0,
227,10,364,1,20,
227,10,365,4,0,
227,10,366,3,0,
227,10,371,4,0,
227,10,372,2,0,
227,10,385,2,0,
227,10,399,4,0,
227,10,400,1,45,
227,10,403,1,39,
227,10,404,4,0,
227,10,413,2,0,
227,10,430,4,0,
227,10,445,4,0,
227,10,446,4,0,
227,10,466,3,0,
227,11,14,4,0,
227,11,15,4,0,
227,11,18,2,0,
227,11,19,4,0,
227,11,28,1,6,
227,11,31,1,17,
227,11,43,1,1,1
227,11,46,4,0,
227,11,64,1,1,2
227,11,65,2,0,
227,11,92,4,0,
227,11,97,1,12,
227,11,104,4,0,
227,11,129,1,9,
227,11,143,2,0,
227,11,148,4,0,
227,11,156,4,0,
227,11,157,4,0,
227,11,163,1,45,
227,11,164,4,0,
227,11,168,4,0,
227,11,174,2,0,
227,11,182,4,0,
227,11,191,1,28,
227,11,201,4,0,
227,11,203,2,0,
227,11,207,4,0,
227,11,211,1,34,
227,11,213,4,0,
227,11,216,4,0,
227,11,218,4,0,
227,11,228,2,0,
227,11,237,4,0,
227,11,241,4,0,
227,11,249,4,0,
227,11,259,4,0,
227,11,263,4,0,
227,11,269,4,0,
227,11,314,1,23,
227,11,317,4,0,
227,11,319,1,31,
227,11,332,4,0,
227,11,364,1,20,
227,11,365,4,0,
227,11,371,4,0,
227,11,372,2,0,
227,11,385,2,0,
227,11,400,1,50,
227,11,403,1,42,
227,11,404,4,0,
227,11,413,2,0,
227,11,430,4,0,
227,11,446,2,0,
227,11,475,1,39,
227,11,496,4,0,
227,11,507,4,0,
227,12,15,4,0,
227,12,19,4,0,
227,12,28,1,10,
227,12,31,1,26,
227,12,43,1,1,1
227,12,46,4,0,
227,12,64,1,1,2
227,12,92,4,0,
227,12,97,1,16,
227,12,104,4,0,
227,12,129,1,13,
227,12,156,4,0,
227,12,168,4,0,
227,12,182,4,0,
227,12,191,1,42,
227,12,201,4,0,
227,12,211,1,32,
227,12,211,4,0,
227,12,213,4,0,
227,12,216,4,0,
227,12,218,4,0,
227,12,237,4,0,
227,12,241,4,0,
227,12,249,4,0,
227,12,259,4,0,
227,12,263,4,0,
227,12,269,4,0,
227,12,290,4,0,
227,12,314,1,29,
227,12,319,1,45,
227,12,332,4,0,
227,13,15,4,0,
227,13,19,4,0,
227,13,28,1,10,
227,13,31,1,26,
227,13,38,3,0,
227,13,43,1,1,1
227,13,46,4,0,
227,13,64,1,1,2
227,13,92,4,0,
227,13,97,1,16,
227,13,102,3,0,
227,13,104,4,0,
227,13,129,1,13,
227,13,143,3,0,
227,13,156,4,0,
227,13,164,3,0,
227,13,168,4,0,
227,13,182,4,0,
227,13,191,1,42,
227,13,201,4,0,
227,13,207,3,0,
227,13,211,1,32,
227,13,211,4,0,
227,13,213,4,0,
227,13,216,4,0,
227,13,218,4,0,
227,13,237,4,0,
227,13,241,4,0,
227,13,249,4,0,
227,13,259,4,0,
227,13,263,4,0,
227,13,269,4,0,
227,13,290,4,0,
227,13,314,1,29,
227,13,319,1,45,
227,13,332,4,0,
227,14,14,4,0,
227,14,15,4,0,
227,14,18,2,0,
227,14,19,4,0,
227,14,28,1,6,
227,14,31,1,17,
227,14,43,1,1,1
227,14,46,4,0,
227,14,64,1,1,2
227,14,65,2,0,
227,14,92,4,0,
227,14,97,1,12,
227,14,104,4,0,
227,14,129,1,9,
227,14,143,2,0,
227,14,143,3,0,
227,14,148,4,0,
227,14,156,4,0,
227,14,157,4,0,
227,14,163,1,45,
227,14,164,4,0,
227,14,168,4,0,
227,14,173,3,0,
227,14,174,2,0,
227,14,182,4,0,
227,14,191,1,28,
227,14,196,3,0,
227,14,201,4,0,
227,14,203,2,0,
227,14,207,4,0,
227,14,211,1,34,
227,14,213,4,0,
227,14,214,3,0,
227,14,216,4,0,
227,14,218,4,0,
227,14,228,2,0,
227,14,237,4,0,
227,14,241,4,0,
227,14,249,4,0,
227,14,259,4,0,
227,14,263,4,0,
227,14,269,4,0,
227,14,314,1,23,
227,14,317,4,0,
227,14,319,1,31,
227,14,332,4,0,
227,14,334,3,0,
227,14,355,3,0,
227,14,364,1,20,
227,14,365,4,0,
227,14,366,3,0,
227,14,371,4,0,
227,14,372,2,0,
227,14,385,2,0,
227,14,399,3,0,
227,14,400,1,50,
227,14,403,1,42,
227,14,404,4,0,
227,14,413,2,0,
227,14,430,4,0,
227,14,442,3,0,
227,14,446,2,0,
227,14,446,3,0,
227,14,475,1,39,
227,14,496,4,0,
227,14,507,4,0,
227,15,14,4,0,
227,15,15,4,0,
227,15,18,2,0,
227,15,19,4,0,
227,15,28,1,6,
227,15,31,1,17,
227,15,43,1,1,1
227,15,46,4,0,
227,15,64,1,1,2
227,15,65,2,0,
227,15,92,4,0,
227,15,97,1,12,
227,15,104,4,0,
227,15,129,1,9,
227,15,143,2,0,
227,15,148,4,0,
227,15,156,4,0,
227,15,157,4,0,
227,15,163,1,45,
227,15,164,4,0,
227,15,168,4,0,
227,15,174,2,0,
227,15,182,4,0,
227,15,191,1,28,
227,15,201,4,0,
227,15,203,2,0,
227,15,207,4,0,
227,15,211,1,34,
227,15,211,4,0,
227,15,213,4,0,
227,15,214,4,0,
227,15,216,4,0,
227,15,218,4,0,
227,15,228,2,0,
227,15,237,4,0,
227,15,241,4,0,
227,15,249,4,0,
227,15,259,4,0,
227,15,263,4,0,
227,15,269,4,0,
227,15,314,1,23,
227,15,317,4,0,
227,15,319,1,31,
227,15,332,4,0,
227,15,355,4,0,
227,15,364,1,20,
227,15,371,4,0,
227,15,372,2,0,
227,15,385,2,0,
227,15,399,4,0,
227,15,400,1,50,
227,15,403,1,42,
227,15,404,4,0,
227,15,413,2,0,
227,15,430,4,0,
227,15,446,2,0,
227,15,475,1,39,
227,15,496,4,0,
227,15,507,4,0,
227,15,590,4,0,
227,16,14,4,0,
227,16,15,4,0,
227,16,18,2,0,
227,16,19,4,0,
227,16,28,1,6,1
227,16,31,1,17,1
227,16,43,1,1,1
227,16,46,4,0,
227,16,64,1,1,2
227,16,65,2,0,
227,16,92,4,0,
227,16,97,1,31,1
227,16,104,4,0,
227,16,129,1,23,1
227,16,143,2,0,
227,16,143,3,0,
227,16,148,4,0,
227,16,156,4,0,
227,16,157,4,0,
227,16,163,1,39,1
227,16,164,4,0,
227,16,168,4,0,
227,16,173,3,0,
227,16,174,2,0,
227,16,182,4,0,
227,16,191,1,28,1
227,16,196,3,0,
227,16,201,4,0,
227,16,203,2,0,
227,16,207,4,0,
227,16,211,1,34,1
227,16,211,4,0,
227,16,213,4,0,
227,16,214,4,0,
227,16,216,4,0,
227,16,218,4,0,
227,16,228,2,0,
227,16,232,1,9,1
227,16,237,4,0,
227,16,241,4,0,
227,16,249,4,0,
227,16,259,4,0,
227,16,263,4,0,
227,16,269,4,0,
227,16,290,4,0,
227,16,314,1,12,1
227,16,317,4,0,
227,16,319,1,42,1
227,16,332,4,0,
227,16,334,3,0,
227,16,355,4,0,
227,16,364,1,20,1
227,16,366,3,0,
227,16,371,4,0,
227,16,372,2,0,
227,16,385,2,0,
227,16,399,4,0,
227,16,400,1,53,1
227,16,403,1,45,1
227,16,404,4,0,
227,16,413,2,0,
227,16,430,4,0,
227,16,442,3,0,
227,16,446,2,0,
227,16,446,3,0,
227,16,475,1,50,1
227,16,496,4,0,
227,16,507,4,0,
227,16,590,4,0,
227,17,14,4,0,
227,17,18,2,0,
227,17,19,4,0,
227,17,28,1,6,
227,17,31,1,17,
227,17,43,1,1,1
227,17,46,4,0,
227,17,64,1,1,2
227,17,65,2,0,
227,17,92,4,0,
227,17,97,1,31,
227,17,104,4,0,
227,17,129,1,23,
227,17,143,2,0,
227,17,156,4,0,
227,17,157,4,0,
227,17,163,1,39,
227,17,164,4,0,
227,17,168,4,0,
227,17,174,2,0,
227,17,182,4,0,
227,17,191,1,28,
227,17,201,4,0,
227,17,203,2,0,
227,17,207,4,0,
227,17,211,1,34,
227,17,211,4,0,
227,17,213,4,0,
227,17,214,4,0,
227,17,216,4,0,
227,17,218,4,0,
227,17,228,2,0,
227,17,232,1,9,
227,17,237,4,0,
227,17,241,4,0,
227,17,259,4,0,
227,17,263,4,0,
227,17,269,4,0,
227,17,314,1,12,
227,17,317,4,0,
227,17,319,1,42,
227,17,332,4,0,
227,17,355,4,0,
227,17,364,1,20,
227,17,371,4,0,
227,17,372,2,0,
227,17,385,2,0,
227,17,399,4,0,
227,17,400,1,53,
227,17,403,1,45,
227,17,404,4,0,
227,17,413,2,0,
227,17,430,4,0,
227,17,446,2,0,
227,17,475,1,50,
227,17,496,4,0,
227,17,507,4,0,
227,17,590,4,0,
227,18,14,4,0,
227,18,18,2,0,
227,18,19,4,0,
227,18,28,1,6,
227,18,31,1,17,
227,18,43,1,1,1
227,18,46,4,0,
227,18,64,1,1,2
227,18,65,2,0,
227,18,92,4,0,
227,18,97,1,31,
227,18,104,4,0,
227,18,129,1,23,
227,18,143,2,0,
227,18,156,4,0,
227,18,157,4,0,
227,18,163,1,39,
227,18,164,4,0,
227,18,168,4,0,
227,18,174,2,0,
227,18,182,4,0,
227,18,191,1,28,
227,18,201,4,0,
227,18,203,2,0,
227,18,207,4,0,
227,18,211,1,34,
227,18,211,4,0,
227,18,213,4,0,
227,18,214,4,0,
227,18,216,4,0,
227,18,218,4,0,
227,18,228,2,0,
227,18,232,1,9,
227,18,237,4,0,
227,18,241,4,0,
227,18,259,4,0,
227,18,263,4,0,
227,18,269,4,0,
227,18,314,1,12,
227,18,317,4,0,
227,18,319,1,42,
227,18,332,4,0,
227,18,355,4,0,
227,18,364,1,20,
227,18,371,4,0,
227,18,372,2,0,
227,18,385,2,0,
227,18,399,4,0,
227,18,400,1,53,
227,18,403,1,45,
227,18,404,4,0,
227,18,413,2,0,
227,18,430,4,0,
227,18,446,2,0,
227,18,475,1,50,
227,18,496,4,0,
227,18,507,4,0,
227,18,590,4,0,
228,3,29,4,0,
228,3,43,1,1,1
228,3,44,1,20,
228,3,46,1,7,
228,3,46,4,0,
228,3,52,1,1,2
228,3,53,1,35,
228,3,68,2,0,
228,3,76,4,0,
228,3,83,2,0,
228,3,92,4,0,
228,3,99,2,0,
228,3,104,4,0,
228,3,123,1,13,
228,3,126,4,0,
228,3,129,4,0,
228,3,138,4,0,
228,3,156,4,0,
228,3,168,4,0,
228,3,171,4,0,
228,3,173,4,0,
228,3,174,4,0,
228,3,179,2,0,
228,3,180,2,0,
228,3,182,4,0,
228,3,185,1,27,
228,3,188,4,0,
228,3,189,4,0,
228,3,197,4,0,
228,3,203,4,0,
228,3,207,4,0,
228,3,213,4,0,
228,3,214,4,0,
228,3,216,4,0,
228,3,218,4,0,
228,3,228,2,0,
228,3,231,4,0,
228,3,237,4,0,
228,3,241,4,0,
228,3,242,1,43,
228,3,247,4,0,
228,3,249,4,0,
228,3,251,2,0,
228,4,29,4,0,
228,4,43,1,1,1
228,4,44,1,20,
228,4,46,1,7,
228,4,46,4,0,
228,4,52,1,1,2
228,4,53,1,35,
228,4,53,3,0,
228,4,68,2,0,
228,4,76,4,0,
228,4,83,2,0,
228,4,92,4,0,
228,4,99,2,0,
228,4,104,4,0,
228,4,123,1,13,
228,4,126,4,0,
228,4,129,4,0,
228,4,138,4,0,
228,4,156,4,0,
228,4,168,4,0,
228,4,171,4,0,
228,4,173,4,0,
228,4,174,4,0,
228,4,179,2,0,
228,4,180,2,0,
228,4,182,4,0,
228,4,185,1,27,
228,4,188,4,0,
228,4,189,4,0,
228,4,197,4,0,
228,4,203,4,0,
228,4,207,4,0,
228,4,213,4,0,
228,4,214,4,0,
228,4,216,4,0,
228,4,218,4,0,
228,4,228,2,0,
228,4,231,4,0,
228,4,237,4,0,
228,4,241,4,0,
228,4,242,1,43,
228,4,247,4,0,
228,4,249,4,0,
228,4,251,2,0,
228,5,43,1,1,1
228,5,44,1,25,
228,5,46,1,19,
228,5,46,4,0,
228,5,52,1,1,2
228,5,53,1,43,
228,5,53,4,0,
228,5,68,2,0,
228,5,76,4,0,
228,5,83,2,0,
228,5,92,4,0,
228,5,99,2,0,
228,5,104,4,0,
228,5,123,1,13,
228,5,126,4,0,
228,5,156,4,0,
228,5,168,4,0,
228,5,179,2,0,
228,5,180,2,0,
228,5,182,4,0,
228,5,185,1,37,
228,5,188,4,0,
228,5,213,4,0,
228,5,216,4,0,
228,5,218,4,0,
228,5,228,2,0,
228,5,231,4,0,
228,5,237,4,0,
228,5,241,4,0,
228,5,242,1,49,
228,5,247,4,0,
228,5,249,4,0,
228,5,251,2,0,
228,5,259,4,0,
228,5,261,2,0,
228,5,263,4,0,
228,5,269,4,0,
228,5,289,4,0,
228,5,290,4,0,
228,5,315,4,0,
228,5,316,1,31,
228,5,336,1,7,
228,6,34,3,0,
228,6,38,3,0,
228,6,43,1,1,1
228,6,44,1,25,
228,6,46,1,19,
228,6,46,4,0,
228,6,52,1,1,2
228,6,53,1,43,
228,6,53,4,0,
228,6,68,2,0,
228,6,68,3,0,
228,6,76,4,0,
228,6,83,2,0,
228,6,92,4,0,
228,6,99,2,0,
228,6,102,3,0,
228,6,104,4,0,
228,6,123,1,13,
228,6,126,4,0,
228,6,129,3,0,
228,6,138,3,0,
228,6,156,4,0,
228,6,164,3,0,
228,6,168,4,0,
228,6,173,3,0,
228,6,179,2,0,
228,6,180,2,0,
228,6,182,4,0,
228,6,185,1,37,
228,6,188,4,0,
228,6,189,3,0,
228,6,203,3,0,
228,6,207,3,0,
228,6,213,4,0,
228,6,214,3,0,
228,6,216,4,0,
228,6,218,4,0,
228,6,228,2,0,
228,6,231,4,0,
228,6,237,4,0,
228,6,241,4,0,
228,6,242,1,49,
228,6,247,4,0,
228,6,249,4,0,
228,6,251,2,0,
228,6,259,4,0,
228,6,261,2,0,
228,6,263,4,0,
228,6,269,4,0,
228,6,289,4,0,
228,6,290,4,0,
228,6,315,4,0,
228,6,316,1,31,
228,6,336,1,7,
228,7,34,3,0,
228,7,38,3,0,
228,7,43,1,1,1
228,7,44,1,25,
228,7,46,1,19,
228,7,46,4,0,
228,7,52,1,1,2
228,7,53,1,43,
228,7,53,4,0,
228,7,68,2,0,
228,7,68,3,0,
228,7,76,4,0,
228,7,83,2,0,
228,7,92,4,0,
228,7,99,2,0,
228,7,102,3,0,
228,7,104,4,0,
228,7,123,1,13,
228,7,126,4,0,
228,7,138,3,0,
228,7,156,4,0,
228,7,164,3,0,
228,7,168,4,0,
228,7,179,2,0,
228,7,180,2,0,
228,7,182,4,0,
228,7,185,1,37,
228,7,188,4,0,
228,7,213,4,0,
228,7,216,4,0,
228,7,218,4,0,
228,7,228,2,0,
228,7,231,4,0,
228,7,237,4,0,
228,7,241,4,0,
228,7,242,1,49,
228,7,247,4,0,
228,7,249,4,0,
228,7,251,2,0,
228,7,259,4,0,
228,7,261,2,0,
228,7,263,4,0,
228,7,269,4,0,
228,7,289,4,0,
228,7,290,4,0,
228,7,315,4,0,
228,7,316,1,31,
228,7,336,1,7,
228,8,43,1,1,1
228,8,44,1,17,
228,8,46,1,14,
228,8,46,4,0,
228,8,52,1,1,2
228,8,53,1,43,
228,8,53,4,0,
228,8,68,2,0,
228,8,76,4,0,
228,8,83,2,0,
228,8,92,4,0,
228,8,99,2,0,
228,8,104,4,0,
228,8,123,1,9,
228,8,126,4,0,
228,8,138,4,0,
228,8,156,4,0,
228,8,164,4,0,
228,8,168,4,0,
228,8,179,2,0,
228,8,180,2,0,
228,8,182,4,0,
228,8,185,1,35,
228,8,188,4,0,
228,8,203,4,0,
228,8,207,4,0,
228,8,213,4,0,
228,8,214,4,0,
228,8,216,4,0,
228,8,218,4,0,
228,8,228,2,0,
228,8,231,4,0,
228,8,237,4,0,
228,8,241,4,0,
228,8,242,1,48,
228,8,247,4,0,
228,8,249,4,0,
228,8,251,1,27,
228,8,251,2,0,
228,8,259,4,0,
228,8,261,2,0,
228,8,261,4,0,
228,8,263,4,0,
228,8,269,4,0,
228,8,289,4,0,
228,8,290,4,0,
228,8,315,4,0,
228,8,316,1,22,
228,8,336,1,4,
228,8,363,4,0,
228,8,371,4,0,
228,8,373,1,40,
228,8,373,4,0,
228,8,386,2,0,
228,8,399,4,0,
228,8,417,1,53,
228,8,417,2,0,
228,8,422,2,0,
228,8,424,1,30,
228,8,424,2,0,
228,8,445,4,0,
228,9,43,1,1,1
228,9,44,1,17,
228,9,46,1,14,
228,9,46,4,0,
228,9,52,1,1,2
228,9,53,1,43,
228,9,53,4,0,
228,9,68,2,0,
228,9,76,4,0,
228,9,83,2,0,
228,9,92,4,0,
228,9,99,2,0,
228,9,104,4,0,
228,9,123,1,9,
228,9,126,4,0,
228,9,129,3,0,
228,9,138,4,0,
228,9,156,4,0,
228,9,164,4,0,
228,9,168,4,0,
228,9,173,3,0,
228,9,179,2,0,
228,9,180,2,0,
228,9,180,3,0,
228,9,182,4,0,
228,9,185,1,35,
228,9,188,4,0,
228,9,189,3,0,
228,9,203,4,0,
228,9,207,4,0,
228,9,213,4,0,
228,9,214,4,0,
228,9,216,4,0,
228,9,218,4,0,
228,9,228,2,0,
228,9,231,4,0,
228,9,237,4,0,
228,9,241,4,0,
228,9,242,1,48,
228,9,247,4,0,
228,9,249,4,0,
228,9,251,1,27,
228,9,251,2,0,
228,9,253,3,0,
228,9,257,3,0,
228,9,259,4,0,
228,9,261,2,0,
228,9,261,4,0,
228,9,263,4,0,
228,9,269,4,0,
228,9,289,4,0,
228,9,290,4,0,
228,9,315,4,0,
228,9,316,1,22,
228,9,336,1,4,
228,9,363,4,0,
228,9,371,4,0,
228,9,373,1,40,
228,9,373,4,0,
228,9,386,2,0,
228,9,389,3,0,
228,9,399,4,0,
228,9,417,1,53,
228,9,417,2,0,
228,9,422,2,0,
228,9,424,1,30,
228,9,424,2,0,
228,9,445,4,0,
228,10,29,3,0,
228,10,43,1,1,1
228,10,44,1,17,
228,10,46,1,14,
228,10,46,4,0,
228,10,52,1,1,2
228,10,53,1,43,
228,10,53,4,0,
228,10,68,2,0,
228,10,76,4,0,
228,10,83,2,0,
228,10,92,4,0,
228,10,99,2,0,
228,10,104,4,0,
228,10,123,1,9,
228,10,126,4,0,
228,10,129,3,0,
228,10,138,4,0,
228,10,156,4,0,
228,10,162,3,0,
228,10,164,4,0,
228,10,168,4,0,
228,10,173,3,0,
228,10,179,2,0,
228,10,180,2,0,
228,10,180,3,0,
228,10,182,4,0,
228,10,185,1,35,
228,10,188,4,0,
228,10,189,3,0,
228,10,203,4,0,
228,10,207,4,0,
228,10,213,4,0,
228,10,214,4,0,
228,10,216,4,0,
228,10,218,4,0,
228,10,228,2,0,
228,10,231,4,0,
228,10,237,4,0,
228,10,241,4,0,
228,10,242,1,48,
228,10,247,4,0,
228,10,249,4,0,
228,10,251,1,27,
228,10,251,2,0,
228,10,253,3,0,
228,10,257,3,0,
228,10,259,4,0,
228,10,261,2,0,
228,10,261,4,0,
228,10,263,4,0,
228,10,269,4,0,
228,10,272,3,0,
228,10,289,4,0,
228,10,290,4,0,
228,10,315,4,0,
228,10,316,1,22,
228,10,336,1,4,
228,10,363,4,0,
228,10,364,2,0,
228,10,371,4,0,
228,10,373,1,40,
228,10,373,4,0,
228,10,386,2,0,
228,10,389,3,0,
228,10,399,4,0,
228,10,417,1,53,
228,10,417,2,0,
228,10,422,2,0,
228,10,424,1,30,
228,10,424,2,0,
228,10,445,4,0,
228,11,43,1,1,1
228,11,44,1,16,
228,11,46,1,13,
228,11,46,4,0,
228,11,52,1,1,2
228,11,53,1,44,
228,11,53,4,0,
228,11,68,2,0,
228,11,76,4,0,
228,11,83,2,0,
228,11,92,4,0,
228,11,99,2,0,
228,11,104,4,0,
228,11,123,1,8,
228,11,126,4,0,
228,11,138,4,0,
228,11,156,4,0,
228,11,164,4,0,
228,11,168,4,0,
228,11,179,2,0,
228,11,180,2,0,
228,11,182,4,0,
228,11,185,1,32,
228,11,188,4,0,
228,11,207,4,0,
228,11,213,4,0,
228,11,216,4,0,
228,11,218,4,0,
228,11,228,2,0,
228,11,237,4,0,
228,11,241,4,0,
228,11,242,1,49,
228,11,247,4,0,
228,11,249,4,0,
228,11,251,1,25,
228,11,251,2,0,
228,11,259,4,0,
228,11,261,4,0,
228,11,263,4,0,
228,11,269,4,0,
228,11,315,4,0,
228,11,316,1,20,
228,11,336,1,4,
228,11,364,2,0,
228,11,371,4,0,
228,11,373,1,37,
228,11,373,4,0,
228,11,386,2,0,
228,11,389,2,0,
228,11,417,1,52,
228,11,417,2,0,
228,11,422,2,0,
228,11,424,1,28,
228,11,424,2,0,
228,11,488,4,0,
228,11,492,1,40,
228,11,496,4,0,
228,11,510,4,0,
228,11,514,4,0,
228,11,517,1,56,
228,11,555,4,0,
228,12,43,1,1,1
228,12,44,1,25,
228,12,46,1,19,
228,12,46,4,0,
228,12,52,1,1,2
228,12,53,1,43,
228,12,53,4,0,
228,12,76,4,0,
228,12,92,4,0,
228,12,104,4,0,
228,12,123,1,13,
228,12,126,4,0,
228,12,156,4,0,
228,12,168,4,0,
228,12,182,4,0,
228,12,185,1,37,
228,12,188,4,0,
228,12,213,4,0,
228,12,216,4,0,
228,12,218,4,0,
228,12,231,4,0,
228,12,237,4,0,
228,12,241,4,0,
228,12,242,1,49,
228,12,247,4,0,
228,12,249,4,0,
228,12,259,4,0,
228,12,263,4,0,
228,12,269,4,0,
228,12,289,4,0,
228,12,290,4,0,
228,12,315,4,0,
228,12,316,1,31,
228,12,336,1,7,
228,13,34,3,0,
228,13,38,3,0,
228,13,43,1,1,1
228,13,44,1,25,
228,13,46,1,19,
228,13,46,4,0,
228,13,52,1,1,2
228,13,53,1,43,
228,13,53,4,0,
228,13,76,4,0,
228,13,92,4,0,
228,13,102,3,0,
228,13,104,4,0,
228,13,123,1,13,
228,13,126,4,0,
228,13,138,3,0,
228,13,156,4,0,
228,13,164,3,0,
228,13,168,4,0,
228,13,171,3,0,
228,13,182,4,0,
228,13,185,1,37,
228,13,188,4,0,
228,13,207,3,0,
228,13,213,4,0,
228,13,216,4,0,
228,13,218,4,0,
228,13,231,4,0,
228,13,237,4,0,
228,13,241,4,0,
228,13,242,1,49,
228,13,247,4,0,
228,13,249,4,0,
228,13,259,4,0,
228,13,263,4,0,
228,13,269,4,0,
228,13,289,4,0,
228,13,290,4,0,
228,13,315,4,0,
228,13,316,1,31,
228,13,336,1,7,
228,14,43,1,1,1
228,14,44,1,16,
228,14,46,1,13,
228,14,46,4,0,
228,14,52,1,1,2
228,14,53,1,44,
228,14,53,4,0,
228,14,68,2,0,
228,14,76,4,0,
228,14,83,2,0,
228,14,92,4,0,
228,14,99,2,0,
228,14,104,4,0,
228,14,123,1,8,
228,14,126,4,0,
228,14,138,4,0,
228,14,156,4,0,
228,14,162,3,0,
228,14,164,4,0,
228,14,168,4,0,
228,14,173,3,0,
228,14,179,2,0,
228,14,180,2,0,
228,14,180,3,0,
228,14,182,4,0,
228,14,185,1,32,
228,14,188,4,0,
228,14,207,4,0,
228,14,213,4,0,
228,14,214,3,0,
228,14,216,4,0,
228,14,218,4,0,
228,14,228,2,0,
228,14,231,3,0,
228,14,237,4,0,
228,14,241,4,0,
228,14,242,1,49,
228,14,247,4,0,
228,14,249,4,0,
228,14,251,1,25,
228,14,251,2,0,
228,14,253,3,0,
228,14,257,3,0,
228,14,259,4,0,
228,14,261,4,0,
228,14,263,4,0,
228,14,269,4,0,
228,14,272,3,0,
228,14,289,3,0,
228,14,304,3,0,
228,14,315,4,0,
228,14,316,1,20,
228,14,336,1,4,
228,14,364,2,0,
228,14,371,4,0,
228,14,373,1,37,
228,14,373,4,0,
228,14,386,2,0,
228,14,389,2,0,
228,14,399,3,0,
228,14,417,1,52,
228,14,417,2,0,
228,14,422,2,0,
228,14,424,1,28,
228,14,424,2,0,
228,14,488,4,0,
228,14,492,1,40,
228,14,492,3,0,
228,14,496,4,0,
228,14,510,4,0,
228,14,514,4,0,
228,14,517,1,56,
228,14,555,4,0,
228,15,43,1,1,1
228,15,44,1,16,
228,15,46,1,13,
228,15,46,4,0,
228,15,52,1,1,2
228,15,53,1,44,
228,15,53,4,0,
228,15,68,2,0,
228,15,76,4,0,
228,15,83,2,0,
228,15,92,4,0,
228,15,99,2,0,
228,15,104,4,0,
228,15,123,1,8,
228,15,126,4,0,
228,15,138,4,0,
228,15,156,4,0,
228,15,164,4,0,
228,15,168,4,0,
228,15,179,2,0,
228,15,180,2,0,
228,15,182,4,0,
228,15,185,1,32,
228,15,188,4,0,
228,15,194,2,0,
228,15,207,4,0,
228,15,213,4,0,
228,15,214,4,0,
228,15,216,4,0,
228,15,218,4,0,
228,15,228,2,0,
228,15,237,4,0,
228,15,241,4,0,
228,15,242,1,49,
228,15,247,4,0,
228,15,249,4,0,
228,15,251,1,25,
228,15,251,2,0,
228,15,259,4,0,
228,15,261,4,0,
228,15,263,4,0,
228,15,269,4,0,
228,15,315,4,0,
228,15,316,1,20,
228,15,336,1,4,
228,15,364,2,0,
228,15,371,4,0,
228,15,373,1,37,
228,15,373,4,0,
228,15,386,2,0,
228,15,389,2,0,
228,15,399,4,0,
228,15,417,1,52,
228,15,417,2,0,
228,15,422,2,0,
228,15,424,1,28,
228,15,424,2,0,
228,15,488,4,0,
228,15,492,1,40,
228,15,496,4,0,
228,15,510,4,0,
228,15,514,4,0,
228,15,517,1,56,
228,15,555,4,0,
228,15,590,4,0,
228,16,43,1,1,1
228,16,44,1,16,1
228,16,46,1,13,1
228,16,46,4,0,
228,16,52,1,1,2
228,16,53,1,44,1
228,16,53,4,0,
228,16,68,2,0,
228,16,76,4,0,
228,16,83,2,0,
228,16,92,4,0,
228,16,99,2,0,
228,16,104,4,0,
228,16,123,1,8,1
228,16,126,4,0,
228,16,138,4,0,
228,16,156,4,0,
228,16,162,3,0,
228,16,164,4,0,
228,16,168,4,0,
228,16,173,3,0,
228,16,179,2,0,
228,16,180,2,0,
228,16,180,3,0,
228,16,182,4,0,
228,16,185,1,32,1
228,16,188,4,0,
228,16,194,2,0,
228,16,207,4,0,
228,16,213,4,0,
228,16,214,4,0,
228,16,216,4,0,
228,16,218,4,0,
228,16,228,2,0,
228,16,231,3,0,
228,16,237,4,0,
228,16,241,4,0,
228,16,242,1,49,1
228,16,247,4,0,
228,16,249,4,0,
228,16,251,1,25,1
228,16,251,2,0,
228,16,253,3,0,
228,16,257,3,0,
228,16,259,4,0,
228,16,261,4,0,
228,16,263,4,0,
228,16,269,4,0,
228,16,272,3,0,
228,16,289,3,0,
228,16,290,4,0,
228,16,304,3,0,
228,16,315,4,0,
228,16,316,1,20,1
228,16,336,1,4,1
228,16,364,2,0,
228,16,371,4,0,
228,16,373,1,37,1
228,16,373,4,0,
228,16,386,2,0,
228,16,389,2,0,
228,16,399,4,0,
228,16,417,1,52,1
228,16,417,2,0,
228,16,422,2,0,
228,16,424,1,28,1
228,16,424,2,0,
228,16,488,4,0,
228,16,492,1,40,1
228,16,492,3,0,
228,16,496,4,0,
228,16,510,4,0,
228,16,514,4,0,
228,16,517,1,56,1
228,16,555,4,0,
228,16,590,4,0,
228,17,43,1,1,1
228,17,44,1,16,
228,17,46,1,13,
228,17,46,4,0,
228,17,52,1,1,2
228,17,53,1,44,
228,17,53,4,0,
228,17,68,2,0,
228,17,76,4,0,
228,17,83,2,0,
228,17,92,4,0,
228,17,99,2,0,
228,17,104,4,0,
228,17,123,1,8,
228,17,126,4,0,
228,17,138,4,0,
228,17,156,4,0,
228,17,164,4,0,
228,17,168,4,0,
228,17,179,2,0,
228,17,180,2,0,
228,17,182,4,0,
228,17,185,1,32,
228,17,188,4,0,
228,17,194,2,0,
228,17,207,4,0,
228,17,213,4,0,
228,17,214,4,0,
228,17,216,4,0,
228,17,218,4,0,
228,17,228,2,0,
228,17,237,4,0,
228,17,241,4,0,
228,17,242,1,49,
228,17,247,4,0,
228,17,251,1,25,
228,17,251,2,0,
228,17,259,4,0,
228,17,261,4,0,
228,17,263,4,0,
228,17,269,4,0,
228,17,315,4,0,
228,17,316,1,20,
228,17,336,1,4,
228,17,364,2,0,
228,17,371,4,0,
228,17,373,1,37,
228,17,373,4,0,
228,17,386,2,0,
228,17,389,2,0,
228,17,399,4,0,
228,17,417,1,52,
228,17,417,2,0,
228,17,422,2,0,
228,17,424,1,28,
228,17,424,2,0,
228,17,488,4,0,
228,17,492,1,40,
228,17,496,4,0,
228,17,517,1,56,
228,17,555,4,0,
228,17,590,4,0,
228,18,43,1,1,1
228,18,44,1,16,
228,18,46,1,13,
228,18,46,4,0,
228,18,52,1,1,2
228,18,53,1,44,
228,18,53,4,0,
228,18,68,2,0,
228,18,76,4,0,
228,18,83,2,0,
228,18,92,4,0,
228,18,99,2,0,
228,18,104,4,0,
228,18,123,1,8,
228,18,126,4,0,
228,18,138,4,0,
228,18,156,4,0,
228,18,164,4,0,
228,18,168,4,0,
228,18,179,2,0,
228,18,180,2,0,
228,18,182,4,0,
228,18,185,1,32,
228,18,188,4,0,
228,18,194,2,0,
228,18,207,4,0,
228,18,213,4,0,
228,18,214,4,0,
228,18,216,4,0,
228,18,218,4,0,
228,18,228,2,0,
228,18,237,4,0,
228,18,241,4,0,
228,18,242,1,49,
228,18,247,4,0,
228,18,251,1,25,
228,18,251,2,0,
228,18,259,4,0,
228,18,261,4,0,
228,18,263,4,0,
228,18,269,4,0,
228,18,315,4,0,
228,18,316,1,20,
228,18,336,1,4,
228,18,364,2,0,
228,18,371,4,0,
228,18,373,1,37,
228,18,373,4,0,
228,18,386,2,0,
228,18,389,2,0,
228,18,399,4,0,
228,18,417,1,52,
228,18,417,2,0,
228,18,422,2,0,
228,18,424,1,28,
228,18,424,2,0,
228,18,488,4,0,
228,18,492,1,40,
228,18,496,4,0,
228,18,517,1,56,
228,18,555,4,0,
228,18,590,4,0,
229,3,29,4,0,
229,3,43,1,1,1
229,3,44,1,20,
229,3,46,1,7,
229,3,46,4,0,
229,3,52,1,1,2
229,3,53,1,41,
229,3,63,4,0,
229,3,70,4,0,
229,3,76,4,0,
229,3,92,4,0,
229,3,104,4,0,
229,3,123,1,13,
229,3,126,4,0,
229,3,129,4,0,
229,3,138,4,0,
229,3,156,4,0,
229,3,168,4,0,
229,3,171,4,0,
229,3,173,4,0,
229,3,174,4,0,
229,3,182,4,0,
229,3,185,1,30,
229,3,188,4,0,
229,3,189,4,0,
229,3,197,4,0,
229,3,203,4,0,
229,3,207,4,0,
229,3,213,4,0,
229,3,214,4,0,
229,3,216,4,0,
229,3,218,4,0,
229,3,231,4,0,
229,3,237,4,0,
229,3,241,4,0,
229,3,242,1,52,
229,3,247,4,0,
229,3,249,4,0,
229,4,29,4,0,
229,4,43,1,1,1
229,4,44,1,20,
229,4,46,1,7,
229,4,46,4,0,
229,4,52,1,1,2
229,4,53,1,41,
229,4,53,3,0,
229,4,63,4,0,
229,4,70,4,0,
229,4,76,4,0,
229,4,92,4,0,
229,4,104,4,0,
229,4,123,1,13,
229,4,126,4,0,
229,4,129,4,0,
229,4,138,4,0,
229,4,156,4,0,
229,4,168,4,0,
229,4,171,4,0,
229,4,173,4,0,
229,4,174,4,0,
229,4,182,4,0,
229,4,185,1,30,
229,4,188,4,0,
229,4,189,4,0,
229,4,197,4,0,
229,4,203,4,0,
229,4,207,4,0,
229,4,213,4,0,
229,4,214,4,0,
229,4,216,4,0,
229,4,218,4,0,
229,4,231,4,0,
229,4,237,4,0,
229,4,241,4,0,
229,4,242,1,52,
229,4,247,4,0,
229,4,249,4,0,
229,5,43,1,1,1
229,5,44,1,27,
229,5,46,1,19,
229,5,46,4,0,
229,5,52,1,1,2
229,5,53,1,51,
229,5,53,4,0,
229,5,63,4,0,
229,5,70,4,0,
229,5,76,4,0,
229,5,92,4,0,
229,5,104,4,0,
229,5,123,1,13,
229,5,126,4,0,
229,5,156,4,0,
229,5,168,4,0,
229,5,182,4,0,
229,5,185,1,43,
229,5,188,4,0,
229,5,213,4,0,
229,5,216,4,0,
229,5,218,4,0,
229,5,231,4,0,
229,5,237,4,0,
229,5,241,4,0,
229,5,242,1,59,
229,5,247,4,0,
229,5,249,4,0,
229,5,259,4,0,
229,5,263,4,0,
229,5,269,4,0,
229,5,289,4,0,
229,5,290,4,0,
229,5,315,4,0,
229,5,316,1,35,
229,5,336,1,1,3
229,5,336,1,7,
229,6,34,3,0,
229,6,38,3,0,
229,6,43,1,1,1
229,6,44,1,27,
229,6,46,1,19,
229,6,46,4,0,
229,6,52,1,1,2
229,6,53,1,51,
229,6,53,4,0,
229,6,63,4,0,
229,6,68,3,0,
229,6,70,4,0,
229,6,76,4,0,
229,6,92,4,0,
229,6,102,3,0,
229,6,104,4,0,
229,6,123,1,13,
229,6,126,4,0,
229,6,129,3,0,
229,6,138,3,0,
229,6,156,4,0,
229,6,164,3,0,
229,6,168,4,0,
229,6,173,3,0,
229,6,182,4,0,
229,6,185,1,43,
229,6,188,4,0,
229,6,189,3,0,
229,6,203,3,0,
229,6,207,3,0,
229,6,213,4,0,
229,6,214,3,0,
229,6,216,4,0,
229,6,218,4,0,
229,6,231,4,0,
229,6,237,4,0,
229,6,241,4,0,
229,6,242,1,59,
229,6,247,4,0,
229,6,249,4,0,
229,6,259,4,0,
229,6,263,4,0,
229,6,269,4,0,
229,6,289,4,0,
229,6,290,4,0,
229,6,315,4,0,
229,6,316,1,35,
229,6,336,1,1,3
229,6,336,1,7,
229,7,34,3,0,
229,7,38,3,0,
229,7,43,1,1,1
229,7,44,1,27,
229,7,46,1,19,
229,7,46,4,0,
229,7,52,1,1,2
229,7,53,1,51,
229,7,53,4,0,
229,7,63,4,0,
229,7,68,3,0,
229,7,70,4,0,
229,7,76,4,0,
229,7,92,4,0,
229,7,102,3,0,
229,7,104,4,0,
229,7,123,1,13,
229,7,126,4,0,
229,7,138,3,0,
229,7,156,4,0,
229,7,164,3,0,
229,7,168,4,0,
229,7,182,4,0,
229,7,185,1,43,
229,7,188,4,0,
229,7,213,4,0,
229,7,216,4,0,
229,7,218,4,0,
229,7,231,4,0,
229,7,237,4,0,
229,7,241,4,0,
229,7,242,1,59,
229,7,247,4,0,
229,7,249,4,0,
229,7,259,4,0,
229,7,263,4,0,
229,7,269,4,0,
229,7,289,4,0,
229,7,290,4,0,
229,7,315,4,0,
229,7,316,1,35,
229,7,336,1,1,3
229,7,336,1,7,
229,8,43,1,1,2
229,8,44,1,17,
229,8,46,1,14,
229,8,46,4,0,
229,8,52,1,1,3
229,8,53,1,48,
229,8,53,4,0,
229,8,63,4,0,
229,8,70,4,0,
229,8,76,4,0,
229,8,92,4,0,
229,8,104,4,0,
229,8,123,1,1,5
229,8,123,1,9,
229,8,126,4,0,
229,8,138,4,0,
229,8,156,4,0,
229,8,164,4,0,
229,8,168,4,0,
229,8,182,4,0,
229,8,185,1,38,
229,8,188,4,0,
229,8,203,4,0,
229,8,207,4,0,
229,8,213,4,0,
229,8,214,4,0,
229,8,216,4,0,
229,8,218,4,0,
229,8,231,4,0,
229,8,237,4,0,
229,8,241,4,0,
229,8,242,1,54,
229,8,247,4,0,
229,8,249,4,0,
229,8,251,1,28,
229,8,259,4,0,
229,8,261,4,0,
229,8,263,4,0,
229,8,269,4,0,
229,8,289,4,0,
229,8,290,4,0,
229,8,315,4,0,
229,8,316,1,22,
229,8,336,1,1,4
229,8,336,1,4,
229,8,363,4,0,
229,8,371,4,0,
229,8,373,1,44,
229,8,373,4,0,
229,8,399,4,0,
229,8,416,4,0,
229,8,417,1,60,
229,8,422,1,1,1
229,8,424,1,32,
229,8,445,4,0,
229,9,43,1,1,2
229,9,44,1,17,
229,9,46,1,14,
229,9,46,4,0,
229,9,52,1,1,3
229,9,53,1,48,
229,9,53,4,0,
229,9,63,4,0,
229,9,70,4,0,
229,9,76,4,0,
229,9,92,4,0,
229,9,104,4,0,
229,9,123,1,1,5
229,9,123,1,9,
229,9,126,4,0,
229,9,129,3,0,
229,9,138,4,0,
229,9,156,4,0,
229,9,164,4,0,
229,9,168,4,0,
229,9,173,3,0,
229,9,180,3,0,
229,9,182,4,0,
229,9,185,1,38,
229,9,188,4,0,
229,9,189,3,0,
229,9,203,4,0,
229,9,207,4,0,
229,9,213,4,0,
229,9,214,4,0,
229,9,216,4,0,
229,9,218,4,0,
229,9,231,4,0,
229,9,237,4,0,
229,9,241,4,0,
229,9,242,1,54,
229,9,247,4,0,
229,9,249,4,0,
229,9,251,1,28,
229,9,253,3,0,
229,9,257,3,0,
229,9,259,4,0,
229,9,261,4,0,
229,9,263,4,0,
229,9,269,4,0,
229,9,289,4,0,
229,9,290,4,0,
229,9,315,4,0,
229,9,316,1,22,
229,9,336,1,1,4
229,9,336,1,4,
229,9,363,4,0,
229,9,371,4,0,
229,9,373,1,44,
229,9,373,4,0,
229,9,389,3,0,
229,9,399,4,0,
229,9,416,4,0,
229,9,417,1,60,
229,9,422,1,1,1
229,9,424,1,32,
229,9,445,4,0,
229,10,29,3,0,
229,10,43,1,1,2
229,10,44,1,17,
229,10,46,1,14,
229,10,46,4,0,
229,10,52,1,1,3
229,10,53,1,48,
229,10,53,4,0,
229,10,63,4,0,
229,10,70,4,0,
229,10,76,4,0,
229,10,92,4,0,
229,10,104,4,0,
229,10,123,1,1,5
229,10,123,1,9,
229,10,126,4,0,
229,10,129,3,0,
229,10,138,4,0,
229,10,156,4,0,
229,10,162,3,0,
229,10,164,4,0,
229,10,168,4,0,
229,10,173,3,0,
229,10,180,3,0,
229,10,182,4,0,
229,10,185,1,38,
229,10,188,4,0,
229,10,189,3,0,
229,10,203,4,0,
229,10,207,4,0,
229,10,213,4,0,
229,10,214,4,0,
229,10,216,4,0,
229,10,218,4,0,
229,10,231,4,0,
229,10,237,4,0,
229,10,241,4,0,
229,10,242,1,54,
229,10,247,4,0,
229,10,249,4,0,
229,10,251,1,28,
229,10,253,3,0,
229,10,257,3,0,
229,10,259,4,0,
229,10,261,4,0,
229,10,263,4,0,
229,10,269,4,0,
229,10,272,3,0,
229,10,289,4,0,
229,10,290,4,0,
229,10,315,4,0,
229,10,316,1,22,
229,10,336,1,1,4
229,10,336,1,4,
229,10,363,4,0,
229,10,371,4,0,
229,10,373,1,44,
229,10,373,4,0,
229,10,389,3,0,
229,10,399,4,0,
229,10,416,4,0,
229,10,417,1,60,
229,10,422,1,1,1
229,10,424,1,32,
229,10,445,4,0,
229,11,43,1,1,2
229,11,44,1,16,
229,11,46,1,13,
229,11,46,4,0,
229,11,52,1,1,3
229,11,53,1,50,
229,11,53,4,0,
229,11,63,4,0,
229,11,70,4,0,
229,11,76,4,0,
229,11,92,4,0,
229,11,104,4,0,
229,11,123,1,1,5
229,11,123,1,8,
229,11,126,4,0,
229,11,138,4,0,
229,11,156,4,0,
229,11,164,4,0,
229,11,168,4,0,
229,11,182,4,0,
229,11,185,1,35,
229,11,188,4,0,
229,11,207,4,0,
229,11,213,4,0,
229,11,216,4,0,
229,11,218,4,0,
229,11,237,4,0,
229,11,241,4,0,
229,11,242,1,56,
229,11,247,4,0,
229,11,249,4,0,
229,11,251,1,26,
229,11,259,4,0,
229,11,261,4,0,
229,11,263,4,0,
229,11,269,4,0,
229,11,315,4,0,
229,11,316,1,20,
229,11,336,1,1,4
229,11,336,1,4,
229,11,371,4,0,
229,11,373,1,41,
229,11,373,4,0,
229,11,416,4,0,
229,11,417,1,60,
229,11,422,1,1,1
229,11,424,1,30,
229,11,488,4,0,
229,11,492,1,45,
229,11,496,4,0,
229,11,510,4,0,
229,11,514,4,0,
229,11,517,1,65,
229,11,555,4,0,
229,12,43,1,1,1
229,12,44,1,27,
229,12,46,1,19,
229,12,46,4,0,
229,12,52,1,1,2
229,12,53,1,51,
229,12,53,4,0,
229,12,63,4,0,
229,12,70,4,0,
229,12,76,4,0,
229,12,92,4,0,
229,12,104,4,0,
229,12,123,1,13,
229,12,126,4,0,
229,12,156,4,0,
229,12,168,4,0,
229,12,182,4,0,
229,12,185,1,43,
229,12,188,4,0,
229,12,213,4,0,
229,12,216,4,0,
229,12,218,4,0,
229,12,231,4,0,
229,12,237,4,0,
229,12,241,4,0,
229,12,242,1,59,
229,12,247,4,0,
229,12,249,4,0,
229,12,259,4,0,
229,12,263,4,0,
229,12,269,4,0,
229,12,289,4,0,
229,12,290,4,0,
229,12,315,4,0,
229,12,316,1,35,
229,12,336,1,1,3
229,12,336,1,7,
229,13,34,3,0,
229,13,38,3,0,
229,13,43,1,1,1
229,13,44,1,27,
229,13,46,1,19,
229,13,46,4,0,
229,13,52,1,1,2
229,13,53,1,51,
229,13,53,4,0,
229,13,63,4,0,
229,13,70,4,0,
229,13,76,4,0,
229,13,92,4,0,
229,13,102,3,0,
229,13,104,4,0,
229,13,123,1,13,
229,13,126,4,0,
229,13,138,3,0,
229,13,156,4,0,
229,13,164,3,0,
229,13,168,4,0,
229,13,171,3,0,
229,13,182,4,0,
229,13,185,1,43,
229,13,188,4,0,
229,13,207,3,0,
229,13,213,4,0,
229,13,216,4,0,
229,13,218,4,0,
229,13,231,4,0,
229,13,237,4,0,
229,13,241,4,0,
229,13,242,1,59,
229,13,247,4,0,
229,13,249,4,0,
229,13,259,4,0,
229,13,263,4,0,
229,13,269,4,0,
229,13,289,4,0,
229,13,290,4,0,
229,13,315,4,0,
229,13,316,1,35,
229,13,336,1,1,3
229,13,336,1,7,
229,14,43,1,1,2
229,14,44,1,16,
229,14,46,1,13,
229,14,46,4,0,
229,14,52,1,1,3
229,14,53,1,50,
229,14,53,4,0,
229,14,63,4,0,
229,14,70,4,0,
229,14,76,4,0,
229,14,92,4,0,
229,14,104,4,0,
229,14,123,1,1,5
229,14,123,1,8,
229,14,126,4,0,
229,14,138,4,0,
229,14,156,4,0,
229,14,162,3,0,
229,14,164,4,0,
229,14,168,4,0,
229,14,173,3,0,
229,14,180,3,0,
229,14,182,4,0,
229,14,185,1,35,
229,14,188,4,0,
229,14,207,4,0,
229,14,213,4,0,
229,14,214,3,0,
229,14,216,4,0,
229,14,218,4,0,
229,14,231,3,0,
229,14,237,4,0,
229,14,241,4,0,
229,14,242,1,56,
229,14,247,4,0,
229,14,249,4,0,
229,14,251,1,26,
229,14,253,3,0,
229,14,257,3,0,
229,14,259,4,0,
229,14,261,4,0,
229,14,263,4,0,
229,14,269,4,0,
229,14,272,3,0,
229,14,289,3,0,
229,14,304,3,0,
229,14,315,4,0,
229,14,316,1,20,
229,14,336,1,1,4
229,14,336,1,4,
229,14,371,4,0,
229,14,373,1,41,
229,14,373,4,0,
229,14,399,3,0,
229,14,416,4,0,
229,14,417,1,60,
229,14,422,1,1,1
229,14,424,1,30,
229,14,488,4,0,
229,14,492,1,45,
229,14,492,3,0,
229,14,496,4,0,
229,14,510,4,0,
229,14,514,4,0,
229,14,517,1,65,
229,14,555,4,0,
229,15,43,1,1,4
229,15,44,1,16,
229,15,46,1,13,
229,15,46,4,0,
229,15,52,1,1,5
229,15,53,1,50,
229,15,53,4,0,
229,15,63,4,0,
229,15,70,4,0,
229,15,76,4,0,
229,15,92,4,0,
229,15,104,4,0,
229,15,123,1,1,7
229,15,123,1,8,
229,15,126,4,0,
229,15,138,4,0,
229,15,156,4,0,
229,15,164,4,0,
229,15,168,4,0,
229,15,182,4,0,
229,15,185,1,35,
229,15,188,4,0,
229,15,207,4,0,
229,15,213,4,0,
229,15,214,4,0,
229,15,216,4,0,
229,15,218,4,0,
229,15,237,4,0,
229,15,241,4,0,
229,15,242,1,56,
229,15,247,4,0,
229,15,249,4,0,
229,15,251,1,26,
229,15,259,4,0,
229,15,261,4,0,
229,15,263,4,0,
229,15,269,4,0,
229,15,315,4,0,
229,15,316,1,20,
229,15,336,1,1,6
229,15,336,1,4,
229,15,371,4,0,
229,15,373,1,41,
229,15,373,4,0,
229,15,399,4,0,
229,15,416,4,0,
229,15,417,1,1,2
229,15,417,1,60,
229,15,422,1,1,3
229,15,424,1,30,
229,15,488,4,0,
229,15,492,1,45,
229,15,496,4,0,
229,15,510,4,0,
229,15,514,4,0,
229,15,517,1,1,1
229,15,517,1,65,
229,15,555,4,0,
229,15,590,4,0,
229,16,43,1,1,4
229,16,44,1,16,1
229,16,46,1,13,1
229,16,46,4,0,
229,16,52,1,1,5
229,16,53,1,50,1
229,16,53,4,0,
229,16,63,4,0,
229,16,70,4,0,
229,16,76,4,0,
229,16,92,4,0,
229,16,104,4,0,
229,16,123,1,1,7
229,16,123,1,8,1
229,16,126,4,0,
229,16,138,4,0,
229,16,156,4,0,
229,16,162,3,0,
229,16,164,4,0,
229,16,168,4,0,
229,16,173,3,0,
229,16,180,3,0,
229,16,182,4,0,
229,16,185,1,35,1
229,16,188,4,0,
229,16,207,4,0,
229,16,213,4,0,
229,16,214,4,0,
229,16,216,4,0,
229,16,218,4,0,
229,16,231,3,0,
229,16,237,4,0,
229,16,241,4,0,
229,16,242,1,56,1
229,16,247,4,0,
229,16,249,4,0,
229,16,251,1,26,1
229,16,253,3,0,
229,16,257,3,0,
229,16,259,4,0,
229,16,261,4,0,
229,16,263,4,0,
229,16,269,4,0,
229,16,272,3,0,
229,16,289,3,0,
229,16,290,4,0,
229,16,304,3,0,
229,16,315,4,0,
229,16,316,1,20,1
229,16,336,1,1,6
229,16,336,1,4,1
229,16,371,4,0,
229,16,373,1,41,1
229,16,373,4,0,
229,16,399,4,0,
229,16,416,4,0,
229,16,417,1,1,2
229,16,417,1,60,1
229,16,422,1,1,3
229,16,424,1,30,1
229,16,488,4,0,
229,16,492,1,45,1
229,16,492,3,0,
229,16,496,4,0,
229,16,510,4,0,
229,16,514,4,0,
229,16,517,1,1,1
229,16,517,1,65,1
229,16,555,4,0,
229,16,590,4,0,
229,17,43,1,1,4
229,17,44,1,16,
229,17,46,1,13,
229,17,46,4,0,
229,17,52,1,1,5
229,17,53,1,50,
229,17,53,4,0,
229,17,63,4,0,
229,17,76,4,0,
229,17,92,4,0,
229,17,104,4,0,
229,17,123,1,1,7
229,17,123,1,8,
229,17,126,4,0,
229,17,138,4,0,
229,17,156,4,0,
229,17,164,4,0,
229,17,168,4,0,
229,17,182,4,0,
229,17,185,1,35,
229,17,188,4,0,
229,17,207,4,0,
229,17,213,4,0,
229,17,214,4,0,
229,17,216,4,0,
229,17,218,4,0,
229,17,237,4,0,
229,17,241,4,0,
229,17,242,1,56,
229,17,247,4,0,
229,17,251,1,26,
229,17,259,4,0,
229,17,261,4,0,
229,17,263,4,0,
229,17,269,4,0,
229,17,315,4,0,
229,17,316,1,20,
229,17,336,1,1,6
229,17,336,1,4,
229,17,371,4,0,
229,17,373,1,41,
229,17,373,4,0,
229,17,399,4,0,
229,17,416,4,0,
229,17,417,1,1,2
229,17,417,1,60,
229,17,422,1,1,3
229,17,424,1,30,
229,17,488,4,0,
229,17,492,1,45,
229,17,496,4,0,
229,17,517,1,1,1
229,17,517,1,65,
229,17,555,4,0,
229,17,590,4,0,
229,18,43,1,1,4
229,18,44,1,16,
229,18,46,1,13,
229,18,46,4,0,
229,18,52,1,1,5
229,18,53,1,50,
229,18,53,4,0,
229,18,63,4,0,
229,18,76,4,0,
229,18,92,4,0,
229,18,104,4,0,
229,18,123,1,1,7
229,18,123,1,8,
229,18,126,4,0,
229,18,138,4,0,
229,18,156,4,0,
229,18,164,4,0,
229,18,168,4,0,
229,18,182,4,0,
229,18,185,1,35,
229,18,188,4,0,
229,18,207,4,0,
229,18,213,4,0,
229,18,214,4,0,
229,18,216,4,0,
229,18,218,4,0,
229,18,237,4,0,
229,18,241,4,0,
229,18,242,1,56,
229,18,247,4,0,
229,18,251,1,26,
229,18,259,4,0,
229,18,261,4,0,
229,18,263,4,0,
229,18,269,4,0,
229,18,315,4,0,
229,18,316,1,20,
229,18,336,1,1,6
229,18,336,1,4,
229,18,371,4,0,
229,18,373,1,41,
229,18,373,4,0,
229,18,399,4,0,
229,18,416,4,0,
229,18,417,1,1,2
229,18,417,1,60,
229,18,422,1,1,3
229,18,424,1,30,
229,18,488,4,0,
229,18,492,1,45,
229,18,496,4,0,
229,18,517,1,1,1
229,18,517,1,65,
229,18,555,4,0,
229,18,590,4,0,
230,3,29,4,0,
230,3,43,1,1,3
230,3,43,1,15,
230,3,55,1,1,4
230,3,55,1,22,
230,3,56,1,51,
230,3,57,4,0,
230,3,59,4,0,
230,3,63,4,0,
230,3,92,4,0,
230,3,97,1,40,
230,3,104,4,0,
230,3,108,1,1,2
230,3,108,1,8,
230,3,127,4,0,
230,3,129,4,0,
230,3,145,1,1,1
230,3,156,4,0,
230,3,173,4,0,
230,3,174,4,0,
230,3,182,4,0,
230,3,196,4,0,
230,3,203,4,0,
230,3,207,4,0,
230,3,213,4,0,
230,3,214,4,0,
230,3,216,4,0,
230,3,218,4,0,
230,3,225,4,0,
230,3,237,4,0,
230,3,239,1,29,
230,3,240,4,0,
230,3,250,4,0,
230,4,29,4,0,
230,4,43,1,1,3
230,4,43,1,15,
230,4,55,1,1,4
230,4,55,1,22,
230,4,56,1,51,
230,4,57,4,0,
230,4,58,3,0,
230,4,59,4,0,
230,4,63,4,0,
230,4,92,4,0,
230,4,97,1,40,
230,4,104,4,0,
230,4,108,1,1,2
230,4,108,1,8,
230,4,127,4,0,
230,4,129,4,0,
230,4,145,1,1,1
230,4,156,4,0,
230,4,173,4,0,
230,4,174,4,0,
230,4,182,4,0,
230,4,196,4,0,
230,4,203,4,0,
230,4,207,4,0,
230,4,213,4,0,
230,4,214,4,0,
230,4,216,4,0,
230,4,218,4,0,
230,4,225,4,0,
230,4,237,4,0,
230,4,239,1,29,
230,4,240,4,0,
230,4,250,4,0,
230,5,43,1,1,3
230,5,43,1,15,
230,5,55,1,1,4
230,5,55,1,22,
230,5,56,1,51,
230,5,57,4,0,
230,5,58,4,0,
230,5,59,4,0,
230,5,63,4,0,
230,5,92,4,0,
230,5,97,1,40,
230,5,104,4,0,
230,5,108,1,1,2
230,5,108,1,8,
230,5,127,4,0,
230,5,145,1,1,1
230,5,156,4,0,
230,5,182,4,0,
230,5,213,4,0,
230,5,216,4,0,
230,5,218,4,0,
230,5,237,4,0,
230,5,239,1,29,
230,5,240,4,0,
230,5,258,4,0,
230,5,263,4,0,
230,5,290,4,0,
230,5,291,4,0,
230,5,349,1,62,
230,5,352,4,0,
230,6,34,3,0,
230,6,38,3,0,
230,6,43,1,1,3
230,6,43,1,15,
230,6,55,1,1,4
230,6,55,1,22,
230,6,56,1,51,
230,6,57,4,0,
230,6,58,4,0,
230,6,59,4,0,
230,6,63,4,0,
230,6,92,4,0,
230,6,97,1,40,
230,6,102,3,0,
230,6,104,4,0,
230,6,108,1,1,2
230,6,108,1,8,
230,6,127,4,0,
230,6,129,3,0,
230,6,145,1,1,1
230,6,156,4,0,
230,6,164,3,0,
230,6,173,3,0,
230,6,182,4,0,
230,6,196,3,0,
230,6,203,3,0,
230,6,207,3,0,
230,6,213,4,0,
230,6,214,3,0,
230,6,216,4,0,
230,6,218,4,0,
230,6,237,4,0,
230,6,239,1,29,
230,6,240,4,0,
230,6,258,4,0,
230,6,263,4,0,
230,6,290,4,0,
230,6,291,4,0,
230,6,349,1,62,
230,6,352,4,0,
230,7,34,3,0,
230,7,38,3,0,
230,7,43,1,1,3
230,7,43,1,15,
230,7,55,1,1,4
230,7,55,1,22,
230,7,56,1,51,
230,7,57,4,0,
230,7,58,4,0,
230,7,59,4,0,
230,7,63,4,0,
230,7,92,4,0,
230,7,97,1,40,
230,7,102,3,0,
230,7,104,4,0,
230,7,108,1,1,2
230,7,108,1,8,
230,7,127,4,0,
230,7,145,1,1,1
230,7,156,4,0,
230,7,164,3,0,
230,7,182,4,0,
230,7,213,4,0,
230,7,216,4,0,
230,7,218,4,0,
230,7,237,4,0,
230,7,239,1,29,
230,7,240,4,0,
230,7,258,4,0,
230,7,263,4,0,
230,7,290,4,0,
230,7,291,4,0,
230,7,349,1,62,
230,7,352,4,0,
230,8,43,1,1,4
230,8,43,1,8,
230,8,55,1,1,5
230,8,55,1,11,
230,8,56,1,40,
230,8,57,4,0,
230,8,58,4,0,
230,8,59,4,0,
230,8,61,1,18,
230,8,63,4,0,
230,8,92,4,0,
230,8,97,1,23,
230,8,104,4,0,
230,8,108,1,1,3
230,8,108,1,4,
230,8,116,1,14,
230,8,127,4,0,
230,8,145,1,1,2
230,8,156,4,0,
230,8,164,4,0,
230,8,182,4,0,
230,8,203,4,0,
230,8,207,4,0,
230,8,213,4,0,
230,8,214,4,0,
230,8,216,4,0,
230,8,218,4,0,
230,8,237,4,0,
230,8,239,1,26,
230,8,240,4,0,
230,8,258,4,0,
230,8,263,4,0,
230,8,281,1,1,1
230,8,290,4,0,
230,8,349,1,48,
230,8,352,4,0,
230,8,362,1,30,
230,8,362,4,0,
230,8,363,4,0,
230,8,406,1,57,
230,8,406,4,0,
230,8,416,4,0,
230,8,430,4,0,
230,8,434,3,0,
230,8,445,4,0,
230,9,43,1,1,4
230,9,43,1,8,
230,9,55,1,1,5
230,9,55,1,11,
230,9,56,1,40,
230,9,57,4,0,
230,9,58,4,0,
230,9,59,4,0,
230,9,61,1,18,
230,9,63,4,0,
230,9,92,4,0,
230,9,97,1,23,
230,9,104,4,0,
230,9,108,1,1,3
230,9,108,1,4,
230,9,116,1,14,
230,9,127,4,0,
230,9,129,3,0,
230,9,145,1,1,2
230,9,156,4,0,
230,9,164,4,0,
230,9,173,3,0,
230,9,182,4,0,
230,9,196,3,0,
230,9,200,3,0,
230,9,203,4,0,
230,9,207,4,0,
230,9,213,4,0,
230,9,214,4,0,
230,9,216,4,0,
230,9,218,4,0,
230,9,237,4,0,
230,9,239,1,26,
230,9,239,3,0,
230,9,240,4,0,
230,9,258,4,0,
230,9,263,4,0,
230,9,281,1,1,1
230,9,290,4,0,
230,9,291,3,0,
230,9,324,3,0,
230,9,340,3,0,
230,9,349,1,48,
230,9,352,4,0,
230,9,362,1,30,
230,9,362,4,0,
230,9,363,4,0,
230,9,406,1,57,
230,9,406,4,0,
230,9,416,4,0,
230,9,430,4,0,
230,9,434,3,0,
230,9,442,3,0,
230,9,445,4,0,
230,10,29,3,0,
230,10,43,1,1,4
230,10,43,1,8,
230,10,55,1,1,5
230,10,55,1,11,
230,10,56,1,40,
230,10,57,4,0,
230,10,58,4,0,
230,10,59,4,0,
230,10,61,1,18,
230,10,63,4,0,
230,10,92,4,0,
230,10,97,1,23,
230,10,104,4,0,
230,10,108,1,1,3
230,10,108,1,4,
230,10,116,1,14,
230,10,127,4,0,
230,10,129,3,0,
230,10,145,1,1,2
230,10,156,4,0,
230,10,164,4,0,
230,10,173,3,0,
230,10,182,4,0,
230,10,196,3,0,
230,10,200,3,0,
230,10,203,4,0,
230,10,207,4,0,
230,10,213,4,0,
230,10,214,4,0,
230,10,216,4,0,
230,10,218,4,0,
230,10,237,4,0,
230,10,239,1,26,
230,10,239,3,0,
230,10,240,4,0,
230,10,250,4,0,
230,10,258,4,0,
230,10,263,4,0,
230,10,281,1,1,1
230,10,290,4,0,
230,10,291,3,0,
230,10,324,3,0,
230,10,340,3,0,
230,10,349,1,48,
230,10,352,4,0,
230,10,362,1,30,
230,10,362,4,0,
230,10,363,4,0,
230,10,406,1,57,
230,10,406,4,0,
230,10,416,4,0,
230,10,430,4,0,
230,10,434,3,0,
230,10,442,3,0,
230,10,445,4,0,
230,11,43,1,1,4
230,11,43,1,8,
230,11,55,1,1,5
230,11,55,1,11,
230,11,56,1,40,
230,11,57,4,0,
230,11,58,4,0,
230,11,59,4,0,
230,11,61,1,18,
230,11,63,4,0,
230,11,92,4,0,
230,11,97,1,23,
230,11,104,4,0,
230,11,108,1,1,3
230,11,108,1,4,
230,11,116,1,14,
230,11,127,4,0,
230,11,145,1,1,2
230,11,156,4,0,
230,11,164,4,0,
230,11,182,4,0,
230,11,207,4,0,
230,11,213,4,0,
230,11,216,4,0,
230,11,218,4,0,
230,11,237,4,0,
230,11,239,1,26,
230,11,240,4,0,
230,11,258,4,0,
230,11,263,4,0,
230,11,281,1,1,1
230,11,291,4,0,
230,11,349,1,48,
230,11,362,1,30,
230,11,406,1,57,
230,11,416,4,0,
230,11,430,4,0,
230,11,434,3,0,
230,11,496,4,0,
230,11,503,4,0,
230,11,511,4,0,
230,12,43,1,1,3
230,12,43,1,15,
230,12,55,1,1,4
230,12,55,1,22,
230,12,56,1,51,
230,12,57,4,0,
230,12,58,4,0,
230,12,59,4,0,
230,12,63,4,0,
230,12,92,4,0,
230,12,97,1,40,
230,12,104,4,0,
230,12,108,1,1,2
230,12,108,1,8,
230,12,127,4,0,
230,12,145,1,1,1
230,12,156,4,0,
230,12,182,4,0,
230,12,213,4,0,
230,12,216,4,0,
230,12,218,4,0,
230,12,237,4,0,
230,12,239,1,29,
230,12,240,4,0,
230,12,258,4,0,
230,12,263,4,0,
230,12,290,4,0,
230,12,291,4,0,
230,12,349,1,62,
230,12,352,4,0,
230,13,34,3,0,
230,13,38,3,0,
230,13,43,1,1,3
230,13,43,1,15,
230,13,55,1,1,4
230,13,55,1,22,
230,13,56,1,51,
230,13,57,4,0,
230,13,58,4,0,
230,13,59,4,0,
230,13,63,4,0,
230,13,92,4,0,
230,13,97,1,40,
230,13,102,3,0,
230,13,104,4,0,
230,13,108,1,1,2
230,13,108,1,8,
230,13,127,4,0,
230,13,145,1,1,1
230,13,156,4,0,
230,13,164,3,0,
230,13,182,4,0,
230,13,196,3,0,
230,13,207,3,0,
230,13,213,4,0,
230,13,216,4,0,
230,13,218,4,0,
230,13,237,4,0,
230,13,239,1,29,
230,13,240,4,0,
230,13,258,4,0,
230,13,263,4,0,
230,13,290,4,0,
230,13,291,4,0,
230,13,349,1,62,
230,13,352,4,0,
230,14,43,1,1,4
230,14,43,1,8,
230,14,55,1,1,5
230,14,55,1,11,
230,14,56,1,40,
230,14,57,4,0,
230,14,58,4,0,
230,14,59,4,0,
230,14,61,1,18,
230,14,63,4,0,
230,14,92,4,0,
230,14,97,1,23,
230,14,104,4,0,
230,14,108,1,1,3
230,14,108,1,4,
230,14,116,1,14,
230,14,127,4,0,
230,14,145,1,1,2
230,14,156,4,0,
230,14,164,4,0,
230,14,173,3,0,
230,14,182,4,0,
230,14,196,3,0,
230,14,200,3,0,
230,14,207,4,0,
230,14,213,4,0,
230,14,214,3,0,
230,14,216,4,0,
230,14,218,4,0,
230,14,237,4,0,
230,14,239,1,26,
230,14,240,4,0,
230,14,258,4,0,
230,14,263,4,0,
230,14,281,1,1,1
230,14,291,4,0,
230,14,324,3,0,
230,14,340,3,0,
230,14,349,1,48,
230,14,362,1,30,
230,14,406,1,57,
230,14,406,3,0,
230,14,416,4,0,
230,14,430,4,0,
230,14,434,3,0,
230,14,442,3,0,
230,14,496,4,0,
230,14,503,4,0,
230,14,511,4,0,
230,15,43,1,1,5
230,15,43,1,8,
230,15,55,1,1,3
230,15,56,1,40,
230,15,57,4,0,
230,15,58,4,0,
230,15,59,4,0,
230,15,61,1,18,
230,15,63,4,0,
230,15,92,4,0,
230,15,97,1,23,
230,15,104,4,0,
230,15,108,1,1,4
230,15,108,1,4,
230,15,116,1,14,
230,15,127,4,0,
230,15,145,1,1,6
230,15,145,1,11,
230,15,156,4,0,
230,15,164,4,0,
230,15,182,4,0,
230,15,207,4,0,
230,15,213,4,0,
230,15,214,4,0,
230,15,216,4,0,
230,15,218,4,0,
230,15,237,4,0,
230,15,239,1,26,
230,15,240,4,0,
230,15,258,4,0,
230,15,263,4,0,
230,15,281,1,1,2
230,15,349,1,48,
230,15,362,1,30,
230,15,406,1,1,1
230,15,406,1,57,
230,15,416,4,0,
230,15,430,4,0,
230,15,434,3,0,
230,15,496,4,0,
230,15,503,4,0,
230,15,511,4,0,
230,15,590,4,0,
230,16,43,1,1,5
230,16,43,1,9,1
230,16,55,1,1,6
230,16,55,1,13,1
230,16,56,1,1,1
230,16,56,1,60,1
230,16,57,4,0,
230,16,58,4,0,
230,16,59,4,0,
230,16,61,1,21,1
230,16,63,4,0,
230,16,92,4,0,
230,16,97,1,38,1
230,16,104,4,0,
230,16,108,1,1,4
230,16,108,1,5,1
230,16,116,1,26,1
230,16,127,4,0,
230,16,145,1,1,3
230,16,156,4,0,
230,16,164,4,0,
230,16,173,3,0,
230,16,182,4,0,
230,16,196,3,0,
230,16,200,3,0,
230,16,207,4,0,
230,16,213,4,0,
230,16,214,4,0,
230,16,216,4,0,
230,16,218,4,0,
230,16,237,4,0,
230,16,239,1,17,1
230,16,240,4,0,
230,16,258,4,0,
230,16,263,4,0,
230,16,281,1,1,2
230,16,290,4,0,
230,16,291,4,0,
230,16,324,3,0,
230,16,340,3,0,
230,16,349,1,52,1
230,16,352,3,0,
230,16,362,1,31,1
230,16,406,1,45,1
230,16,406,3,0,
230,16,416,4,0,
230,16,430,4,0,
230,16,434,3,0,
230,16,442,3,0,
230,16,496,4,0,
230,16,503,4,0,
230,16,511,4,0,
230,16,590,4,0,
230,17,43,1,1,5
230,17,43,1,9,
230,17,55,1,1,6
230,17,55,1,13,
230,17,56,1,1,1
230,17,56,1,60,
230,17,57,4,0,
230,17,58,4,0,
230,17,59,4,0,
230,17,61,1,21,
230,17,63,4,0,
230,17,92,4,0,
230,17,97,1,38,
230,17,104,4,0,
230,17,108,1,1,4
230,17,108,1,5,
230,17,116,1,26,
230,17,127,4,0,
230,17,145,1,1,3
230,17,156,4,0,
230,17,164,4,0,
230,17,182,4,0,
230,17,207,4,0,
230,17,213,4,0,
230,17,214,4,0,
230,17,216,4,0,
230,17,218,4,0,
230,17,237,4,0,
230,17,239,1,17,
230,17,240,4,0,
230,17,258,4,0,
230,17,263,4,0,
230,17,281,1,1,2
230,17,349,1,52,
230,17,362,1,31,
230,17,406,1,45,
230,17,416,4,0,
230,17,430,4,0,
230,17,434,3,0,
230,17,496,4,0,
230,17,503,4,0,
230,17,511,4,0,
230,17,590,4,0,
230,18,43,1,1,5
230,18,43,1,9,
230,18,55,1,1,6
230,18,55,1,13,
230,18,56,1,1,1
230,18,56,1,60,
230,18,57,4,0,
230,18,58,4,0,
230,18,59,4,0,
230,18,61,1,21,
230,18,63,4,0,
230,18,92,4,0,
230,18,97,1,38,
230,18,104,4,0,
230,18,108,1,1,4
230,18,108,1,5,
230,18,116,1,26,
230,18,127,4,0,
230,18,145,1,1,3
230,18,156,4,0,
230,18,164,4,0,
230,18,182,4,0,
230,18,207,4,0,
230,18,213,4,0,
230,18,214,4,0,
230,18,216,4,0,
230,18,218,4,0,
230,18,237,4,0,
230,18,239,1,17,
230,18,240,4,0,
230,18,258,4,0,
230,18,263,4,0,
230,18,281,1,1,2
230,18,349,1,52,
230,18,362,1,31,
230,18,406,1,45,
230,18,416,4,0,
230,18,430,4,0,
230,18,434,3,0,
230,18,496,4,0,
230,18,503,4,0,
230,18,511,4,0,
230,18,590,4,0,
231,3,29,4,0,
231,3,33,1,1,1
231,3,34,2,0,
231,3,36,1,25,
231,3,38,1,49,
231,3,45,1,1,2
231,3,46,4,0,
231,3,70,4,0,
231,3,89,4,0,
231,3,92,4,0,
231,3,104,4,0,
231,3,111,1,9,
231,3,111,4,0,
231,3,116,2,0,
231,3,156,4,0,
231,3,173,4,0,
231,3,174,4,0,
231,3,175,1,17,
231,3,182,4,0,
231,3,189,4,0,
231,3,201,4,0,
231,3,203,1,41,
231,3,203,4,0,
231,3,205,1,33,
231,3,205,4,0,
231,3,207,4,0,
231,3,213,4,0,
231,3,214,4,0,
231,3,216,4,0,
231,3,218,4,0,
231,3,237,4,0,
231,3,241,4,0,
231,3,246,2,0,
231,3,249,4,0,
231,4,29,4,0,
231,4,33,1,1,1
231,4,34,2,0,
231,4,36,1,25,
231,4,38,1,49,
231,4,45,1,1,2
231,4,46,4,0,
231,4,55,2,0,
231,4,70,4,0,
231,4,89,4,0,
231,4,92,4,0,
231,4,104,4,0,
231,4,111,1,9,
231,4,111,4,0,
231,4,116,2,0,
231,4,156,4,0,
231,4,173,4,0,
231,4,174,4,0,
231,4,175,1,17,
231,4,182,4,0,
231,4,189,4,0,
231,4,201,4,0,
231,4,203,1,41,
231,4,203,4,0,
231,4,205,1,33,
231,4,205,4,0,
231,4,207,4,0,
231,4,213,4,0,
231,4,214,4,0,
231,4,216,4,0,
231,4,218,4,0,
231,4,237,4,0,
231,4,241,4,0,
231,4,246,2,0,
231,4,249,4,0,
231,5,33,1,1,2
231,5,34,2,0,
231,5,36,1,25,
231,5,38,1,49,
231,5,45,1,1,3
231,5,46,4,0,
231,5,68,2,0,
231,5,70,4,0,
231,5,89,4,0,
231,5,90,2,0,
231,5,92,4,0,
231,5,104,4,0,
231,5,111,1,9,
231,5,116,2,0,
231,5,156,4,0,
231,5,173,2,0,
231,5,175,1,17,
231,5,182,4,0,
231,5,201,4,0,
231,5,203,1,41,
231,5,205,1,33,
231,5,213,4,0,
231,5,216,4,0,
231,5,218,4,0,
231,5,231,4,0,
231,5,237,4,0,
231,5,241,4,0,
231,5,246,2,0,
231,5,249,4,0,
231,5,263,4,0,
231,5,290,4,0,
231,5,316,1,1,1
231,5,317,4,0,
231,6,33,1,1,2
231,6,34,2,0,
231,6,34,3,0,
231,6,36,1,25,
231,6,38,1,49,
231,6,38,3,0,
231,6,45,1,1,3
231,6,46,4,0,
231,6,68,2,0,
231,6,68,3,0,
231,6,70,4,0,
231,6,89,4,0,
231,6,90,2,0,
231,6,92,4,0,
231,6,102,3,0,
231,6,104,4,0,
231,6,111,1,9,
231,6,111,3,0,
231,6,116,2,0,
231,6,156,4,0,
231,6,164,3,0,
231,6,173,2,0,
231,6,173,3,0,
231,6,175,1,17,
231,6,182,4,0,
231,6,189,3,0,
231,6,201,4,0,
231,6,203,1,41,
231,6,203,3,0,
231,6,205,1,33,
231,6,205,3,0,
231,6,207,3,0,
231,6,213,4,0,
231,6,214,3,0,
231,6,216,4,0,
231,6,218,4,0,
231,6,231,4,0,
231,6,237,4,0,
231,6,241,4,0,
231,6,246,2,0,
231,6,249,4,0,
231,6,263,4,0,
231,6,290,4,0,
231,6,316,1,1,1
231,6,317,4,0,
231,7,33,1,1,2
231,7,34,2,0,
231,7,34,3,0,
231,7,36,1,25,
231,7,38,1,49,
231,7,38,3,0,
231,7,45,1,1,3
231,7,46,4,0,
231,7,68,2,0,
231,7,68,3,0,
231,7,70,4,0,
231,7,89,4,0,
231,7,90,2,0,
231,7,92,4,0,
231,7,102,3,0,
231,7,104,4,0,
231,7,111,1,9,
231,7,116,2,0,
231,7,156,4,0,
231,7,164,3,0,
231,7,173,2,0,
231,7,175,1,17,
231,7,182,4,0,
231,7,201,4,0,
231,7,203,1,41,
231,7,205,1,33,
231,7,213,4,0,
231,7,216,4,0,
231,7,218,4,0,
231,7,231,4,0,
231,7,237,4,0,
231,7,241,4,0,
231,7,246,2,0,
231,7,249,4,0,
231,7,263,4,0,
231,7,290,4,0,
231,7,316,1,1,1
231,7,317,4,0,
231,8,21,1,24,
231,8,33,1,1,2
231,8,34,2,0,
231,8,36,1,10,
231,8,38,1,42,
231,8,45,1,1,3
231,8,46,4,0,
231,8,68,2,0,
231,8,70,4,0,
231,8,89,4,0,
231,8,90,2,0,
231,8,92,4,0,
231,8,104,4,0,
231,8,111,1,1,4
231,8,116,2,0,
231,8,156,4,0,
231,8,157,4,0,
231,8,164,4,0,
231,8,173,2,0,
231,8,175,1,6,
231,8,182,4,0,
231,8,201,4,0,
231,8,203,1,28,
231,8,203,4,0,
231,8,204,1,33,
231,8,205,1,15,
231,8,207,4,0,
231,8,213,4,0,
231,8,214,4,0,
231,8,216,4,0,
231,8,218,4,0,
231,8,231,4,0,
231,8,237,4,0,
231,8,241,4,0,
231,8,246,2,0,
231,8,249,4,0,
231,8,263,4,0,
231,8,283,2,0,
231,8,290,4,0,
231,8,316,1,1,1
231,8,317,4,0,
231,8,363,1,19,
231,8,363,4,0,
231,8,387,1,37,
231,8,420,2,0,
231,8,445,4,0,
231,8,446,4,0,
231,9,21,1,24,
231,9,33,1,1,2
231,9,34,2,0,
231,9,36,1,10,
231,9,38,1,42,
231,9,45,1,1,3
231,9,46,4,0,
231,9,68,2,0,
231,9,70,4,0,
231,9,89,4,0,
231,9,90,2,0,
231,9,92,4,0,
231,9,104,4,0,
231,9,111,1,1,4
231,9,116,2,0,
231,9,156,4,0,
231,9,157,4,0,
231,9,164,4,0,
231,9,173,2,0,
231,9,173,3,0,
231,9,175,1,6,
231,9,182,4,0,
231,9,189,3,0,
231,9,201,4,0,
231,9,203,1,28,
231,9,203,4,0,
231,9,204,1,33,
231,9,205,1,15,
231,9,205,3,0,
231,9,207,4,0,
231,9,213,4,0,
231,9,214,4,0,
231,9,216,4,0,
231,9,218,4,0,
231,9,231,4,0,
231,9,237,4,0,
231,9,241,4,0,
231,9,246,2,0,
231,9,246,3,0,
231,9,249,4,0,
231,9,263,4,0,
231,9,276,3,0,
231,9,282,3,0,
231,9,283,2,0,
231,9,283,3,0,
231,9,290,4,0,
231,9,316,1,1,1
231,9,317,4,0,
231,9,363,1,19,
231,9,363,4,0,
231,9,387,1,37,
231,9,402,3,0,
231,9,414,3,0,
231,9,420,2,0,
231,9,441,3,0,
231,9,445,4,0,
231,9,446,4,0,
231,10,21,1,24,
231,10,29,3,0,
231,10,33,1,1,2
231,10,34,2,0,
231,10,36,1,10,
231,10,38,1,42,
231,10,45,1,1,3
231,10,46,4,0,
231,10,68,2,0,
231,10,70,4,0,
231,10,89,4,0,
231,10,90,2,0,
231,10,92,4,0,
231,10,104,4,0,
231,10,111,1,1,4
231,10,116,2,0,
231,10,156,4,0,
231,10,157,4,0,
231,10,164,4,0,
231,10,173,2,0,
231,10,173,3,0,
231,10,175,1,6,
231,10,182,4,0,
231,10,189,3,0,
231,10,201,4,0,
231,10,203,1,28,
231,10,203,4,0,
231,10,204,1,33,
231,10,205,1,15,
231,10,205,3,0,
231,10,207,4,0,
231,10,213,4,0,
231,10,214,4,0,
231,10,216,4,0,
231,10,218,4,0,
231,10,231,4,0,
231,10,237,4,0,
231,10,241,4,0,
231,10,246,2,0,
231,10,246,3,0,
231,10,249,4,0,
231,10,263,4,0,
231,10,276,3,0,
231,10,282,3,0,
231,10,283,2,0,
231,10,283,3,0,
231,10,290,4,0,
231,10,316,1,1,1
231,10,317,4,0,
231,10,363,1,19,
231,10,363,4,0,
231,10,387,1,37,
231,10,402,3,0,
231,10,414,3,0,
231,10,420,2,0,
231,10,441,3,0,
231,10,445,4,0,
231,10,446,4,0,
231,10,457,2,0,
231,11,21,1,24,
231,11,33,1,1,2
231,11,34,2,0,
231,11,36,1,10,
231,11,38,1,42,
231,11,45,1,1,3
231,11,46,4,0,
231,11,68,2,0,
231,11,70,4,0,
231,11,89,4,0,
231,11,90,2,0,
231,11,92,4,0,
231,11,104,4,0,
231,11,111,1,1,4
231,11,116,2,0,
231,11,156,4,0,
231,11,157,4,0,
231,11,164,4,0,
231,11,173,2,0,
231,11,175,1,6,
231,11,182,4,0,
231,11,189,2,0,
231,11,201,4,0,
231,11,203,1,28,
231,11,204,1,33,
231,11,205,1,15,
231,11,207,4,0,
231,11,213,4,0,
231,11,216,4,0,
231,11,218,4,0,
231,11,237,4,0,
231,11,241,4,0,
231,11,246,2,0,
231,11,249,4,0,
231,11,263,4,0,
231,11,283,2,0,
231,11,316,1,1,1
231,11,317,4,0,
231,11,363,1,19,
231,11,387,1,37,
231,11,420,2,0,
231,11,457,2,0,
231,11,484,2,0,
231,11,496,4,0,
231,11,497,4,0,
231,11,523,4,0,
231,12,33,1,1,2
231,12,36,1,25,
231,12,38,1,49,
231,12,45,1,1,3
231,12,46,4,0,
231,12,70,4,0,
231,12,89,4,0,
231,12,92,4,0,
231,12,104,4,0,
231,12,111,1,9,
231,12,156,4,0,
231,12,175,1,17,
231,12,182,4,0,
231,12,201,4,0,
231,12,203,1,41,
231,12,205,1,33,
231,12,213,4,0,
231,12,216,4,0,
231,12,218,4,0,
231,12,231,4,0,
231,12,237,4,0,
231,12,241,4,0,
231,12,249,4,0,
231,12,263,4,0,
231,12,290,4,0,
231,12,316,1,1,1
231,12,317,4,0,
231,13,33,1,1,2
231,13,34,3,0,
231,13,36,1,25,
231,13,38,1,49,
231,13,38,3,0,
231,13,45,1,1,3
231,13,46,4,0,
231,13,70,4,0,
231,13,89,4,0,
231,13,92,4,0,
231,13,102,3,0,
231,13,104,4,0,
231,13,111,1,9,
231,13,156,4,0,
231,13,164,3,0,
231,13,175,1,17,
231,13,182,4,0,
231,13,201,4,0,
231,13,203,1,41,
231,13,205,1,33,
231,13,207,3,0,
231,13,213,4,0,
231,13,216,4,0,
231,13,218,4,0,
231,13,231,4,0,
231,13,237,4,0,
231,13,241,4,0,
231,13,249,4,0,
231,13,263,4,0,
231,13,290,4,0,
231,13,316,1,1,1
231,13,317,4,0,
231,14,21,1,24,
231,14,33,1,1,2
231,14,34,2,0,
231,14,36,1,10,
231,14,38,1,42,
231,14,45,1,1,3
231,14,46,4,0,
231,14,68,2,0,
231,14,70,4,0,
231,14,89,4,0,
231,14,90,2,0,
231,14,92,4,0,
231,14,104,4,0,
231,14,111,1,1,4
231,14,116,2,0,
231,14,156,4,0,
231,14,157,4,0,
231,14,164,4,0,
231,14,173,2,0,
231,14,173,3,0,
231,14,175,1,6,
231,14,182,4,0,
231,14,189,2,0,
231,14,201,4,0,
231,14,203,1,28,
231,14,204,1,33,
231,14,205,1,15,
231,14,207,4,0,
231,14,213,4,0,
231,14,214,3,0,
231,14,216,4,0,
231,14,218,4,0,
231,14,231,3,0,
231,14,237,4,0,
231,14,241,4,0,
231,14,246,2,0,
231,14,249,4,0,
231,14,263,4,0,
231,14,276,3,0,
231,14,282,3,0,
231,14,283,2,0,
231,14,283,3,0,
231,14,304,3,0,
231,14,316,1,1,1
231,14,317,4,0,
231,14,363,1,19,
231,14,387,1,37,
231,14,387,3,0,
231,14,402,3,0,
231,14,414,3,0,
231,14,420,2,0,
231,14,441,3,0,
231,14,446,3,0,
231,14,457,2,0,
231,14,484,2,0,
231,14,496,4,0,
231,14,497,4,0,
231,14,523,4,0,
231,15,21,1,24,
231,15,33,1,1,2
231,15,34,2,0,
231,15,36,1,10,
231,15,38,1,42,
231,15,45,1,1,3
231,15,46,4,0,
231,15,68,2,0,
231,15,70,4,0,
231,15,89,4,0,
231,15,90,2,0,
231,15,92,4,0,
231,15,104,4,0,
231,15,111,1,1,4
231,15,116,2,0,
231,15,156,4,0,
231,15,157,4,0,
231,15,164,4,0,
231,15,173,2,0,
231,15,175,1,6,
231,15,182,4,0,
231,15,189,2,0,
231,15,201,4,0,
231,15,203,1,28,
231,15,204,1,33,
231,15,205,1,15,
231,15,207,4,0,
231,15,213,4,0,
231,15,214,4,0,
231,15,216,4,0,
231,15,218,4,0,
231,15,237,4,0,
231,15,241,4,0,
231,15,246,2,0,
231,15,249,4,0,
231,15,263,4,0,
231,15,283,2,0,
231,15,316,1,1,1
231,15,317,4,0,
231,15,363,1,19,
231,15,387,1,37,
231,15,420,2,0,
231,15,457,2,0,
231,15,484,2,0,
231,15,496,4,0,
231,15,497,4,0,
231,15,523,4,0,
231,15,583,2,0,
231,15,590,4,0,
231,16,21,1,24,1
231,16,33,1,1,2
231,16,34,2,0,
231,16,36,1,28,1
231,16,38,1,42,1
231,16,45,1,1,3
231,16,46,4,0,
231,16,68,2,0,
231,16,70,4,0,
231,16,89,4,0,
231,16,90,2,0,
231,16,92,4,0,
231,16,104,4,0,
231,16,111,1,1,4
231,16,116,2,0,
231,16,156,4,0,
231,16,157,4,0,
231,16,164,4,0,
231,16,173,2,0,
231,16,173,3,0,
231,16,175,1,6,1
231,16,182,4,0,
231,16,189,2,0,
231,16,201,4,0,
231,16,203,1,19,1
231,16,204,1,33,1
231,16,205,1,10,1
231,16,207,4,0,
231,16,213,4,0,
231,16,214,4,0,
231,16,216,4,0,
231,16,218,4,0,
231,16,231,3,0,
231,16,237,4,0,
231,16,241,4,0,
231,16,246,2,0,
231,16,249,4,0,
231,16,263,4,0,
231,16,276,3,0,
231,16,282,3,0,
231,16,283,2,0,
231,16,283,3,0,
231,16,290,4,0,
231,16,304,3,0,
231,16,316,1,1,1
231,16,317,4,0,
231,16,363,1,15,1
231,16,387,1,37,1
231,16,387,3,0,
231,16,402,3,0,
231,16,414,3,0,
231,16,420,2,0,
231,16,441,3,0,
231,16,446,3,0,
231,16,457,2,0,
231,16,484,2,0,
231,16,496,4,0,
231,16,497,4,0,
231,16,523,4,0,
231,16,583,2,0,
231,16,590,4,0,
231,17,21,1,24,
231,17,33,1,1,2
231,17,34,2,0,
231,17,36,1,28,
231,17,38,1,42,
231,17,45,1,1,3
231,17,46,4,0,
231,17,68,2,0,
231,17,89,4,0,
231,17,90,2,0,
231,17,92,4,0,
231,17,104,4,0,
231,17,111,1,1,4
231,17,116,2,0,
231,17,156,4,0,
231,17,157,4,0,
231,17,164,4,0,
231,17,173,2,0,
231,17,175,1,6,
231,17,182,4,0,
231,17,189,2,0,
231,17,201,4,0,
231,17,203,1,19,
231,17,204,1,33,
231,17,205,1,10,
231,17,207,4,0,
231,17,213,4,0,
231,17,214,4,0,
231,17,216,4,0,
231,17,218,4,0,
231,17,237,4,0,
231,17,241,4,0,
231,17,246,2,0,
231,17,263,4,0,
231,17,283,2,0,
231,17,316,1,1,1
231,17,317,4,0,
231,17,363,1,15,
231,17,387,1,37,
231,17,420,2,0,
231,17,457,2,0,
231,17,484,2,0,
231,17,496,4,0,
231,17,497,4,0,
231,17,523,4,0,
231,17,583,2,0,
231,17,590,4,0,
231,18,21,1,24,
231,18,33,1,1,2
231,18,34,2,0,
231,18,36,1,28,
231,18,38,1,42,
231,18,45,1,1,3
231,18,46,4,0,
231,18,68,2,0,
231,18,89,4,0,
231,18,90,2,0,
231,18,92,4,0,
231,18,104,4,0,
231,18,111,1,1,4
231,18,116,2,0,
231,18,156,4,0,
231,18,157,4,0,
231,18,164,4,0,
231,18,173,2,0,
231,18,175,1,6,
231,18,182,4,0,
231,18,189,2,0,
231,18,201,4,0,
231,18,203,1,19,
231,18,204,1,33,
231,18,205,1,10,
231,18,207,4,0,
231,18,213,4,0,
231,18,214,4,0,
231,18,216,4,0,
231,18,218,4,0,
231,18,237,4,0,
231,18,241,4,0,
231,18,246,2,0,
231,18,263,4,0,
231,18,283,2,0,
231,18,316,1,1,1
231,18,317,4,0,
231,18,363,1,15,
231,18,387,1,37,
231,18,420,2,0,
231,18,457,2,0,
231,18,484,2,0,
231,18,496,4,0,
231,18,497,4,0,
231,18,523,4,0,
231,18,583,2,0,
231,18,590,4,0,
231,18,667,2,0,
232,3,29,4,0,
232,3,30,1,1,1
232,3,31,1,25,
232,3,45,1,1,2
232,3,46,4,0,
232,3,63,4,0,
232,3,70,4,0,
232,3,89,1,49,
232,3,89,4,0,
232,3,92,4,0,
232,3,104,4,0,
232,3,111,1,9,
232,3,111,4,0,
232,3,156,4,0,
232,3,173,4,0,
232,3,174,4,0,
232,3,175,1,17,
232,3,182,4,0,
232,3,189,4,0,
232,3,201,4,0,
232,3,203,4,0,
232,3,205,1,33,
232,3,205,4,0,
232,3,207,4,0,
232,3,213,4,0,
232,3,214,4,0,
232,3,216,4,0,
232,3,218,4,0,
232,3,229,1,41,
232,3,237,4,0,
232,3,241,4,0,
232,3,249,4,0,
232,4,29,4,0,
232,4,30,1,1,1
232,4,31,1,25,
232,4,45,1,1,2
232,4,46,4,0,
232,4,63,4,0,
232,4,70,4,0,
232,4,89,1,49,
232,4,89,4,0,
232,4,92,4,0,
232,4,104,4,0,
232,4,111,1,9,
232,4,111,4,0,
232,4,156,4,0,
232,4,173,4,0,
232,4,174,4,0,
232,4,175,1,17,
232,4,182,4,0,
232,4,189,4,0,
232,4,201,4,0,
232,4,203,4,0,
232,4,205,1,33,
232,4,205,4,0,
232,4,207,4,0,
232,4,213,4,0,
232,4,214,4,0,
232,4,216,4,0,
232,4,218,4,0,
232,4,229,1,41,
232,4,237,4,0,
232,4,241,4,0,
232,4,249,4,0,
232,5,30,1,1,2
232,5,31,1,25,
232,5,45,1,1,3
232,5,46,4,0,
232,5,63,4,0,
232,5,70,4,0,
232,5,89,1,49,
232,5,89,4,0,
232,5,92,4,0,
232,5,104,4,0,
232,5,111,1,9,
232,5,156,4,0,
232,5,175,1,17,
232,5,182,4,0,
232,5,201,4,0,
232,5,205,1,33,
232,5,213,4,0,
232,5,216,4,0,
232,5,218,4,0,
232,5,229,1,41,
232,5,231,4,0,
232,5,237,4,0,
232,5,241,4,0,
232,5,249,4,0,
232,5,263,4,0,
232,5,290,4,0,
232,5,316,1,1,1
232,5,317,4,0,
232,6,30,1,1,2
232,6,31,1,25,
232,6,34,3,0,
232,6,38,3,0,
232,6,45,1,1,3
232,6,46,4,0,
232,6,63,4,0,
232,6,68,3,0,
232,6,70,4,0,
232,6,89,1,49,
232,6,89,4,0,
232,6,92,4,0,
232,6,102,3,0,
232,6,104,4,0,
232,6,111,1,9,
232,6,111,3,0,
232,6,156,4,0,
232,6,157,3,0,
232,6,164,3,0,
232,6,173,3,0,
232,6,175,1,17,
232,6,182,4,0,
232,6,189,3,0,
232,6,201,4,0,
232,6,203,3,0,
232,6,205,1,33,
232,6,205,3,0,
232,6,207,3,0,
232,6,213,4,0,
232,6,214,3,0,
232,6,216,4,0,
232,6,218,4,0,
232,6,229,1,41,
232,6,231,4,0,
232,6,237,4,0,
232,6,241,4,0,
232,6,249,4,0,
232,6,263,4,0,
232,6,290,4,0,
232,6,316,1,1,1
232,6,317,4,0,
232,7,30,1,1,2
232,7,31,1,25,
232,7,34,3,0,
232,7,38,3,0,
232,7,45,1,1,3
232,7,46,4,0,
232,7,63,4,0,
232,7,68,3,0,
232,7,70,4,0,
232,7,89,1,49,
232,7,89,4,0,
232,7,92,4,0,
232,7,102,3,0,
232,7,104,4,0,
232,7,111,1,9,
232,7,156,4,0,
232,7,157,3,0,
232,7,164,3,0,
232,7,175,1,17,
232,7,182,4,0,
232,7,201,4,0,
232,7,205,1,33,
232,7,213,4,0,
232,7,216,4,0,
232,7,218,4,0,
232,7,229,1,41,
232,7,231,4,0,
232,7,237,4,0,
232,7,241,4,0,
232,7,249,4,0,
232,7,263,4,0,
232,7,290,4,0,
232,7,316,1,1,1
232,7,317,4,0,
232,8,21,1,24,
232,8,30,1,1,3
232,8,31,1,25,
232,8,45,1,1,4
232,8,46,4,0,
232,8,63,4,0,
232,8,70,4,0,
232,8,89,1,46,
232,8,89,4,0,
232,8,92,4,0,
232,8,104,4,0,
232,8,111,1,1,5
232,8,156,4,0,
232,8,157,4,0,
232,8,164,4,0,
232,8,175,1,1,6
232,8,182,4,0,
232,8,184,1,39,
232,8,201,4,0,
232,8,203,4,0,
232,8,205,1,15,
232,8,207,4,0,
232,8,213,4,0,
232,8,214,4,0,
232,8,216,4,0,
232,8,218,4,0,
232,8,222,1,19,
232,8,229,1,6,
232,8,231,4,0,
232,8,237,4,0,
232,8,241,4,0,
232,8,249,4,0,
232,8,263,4,0,
232,8,282,1,10,
232,8,290,4,0,
232,8,317,4,0,
232,8,360,4,0,
232,8,363,4,0,
232,8,372,1,31,
232,8,397,4,0,
232,8,398,4,0,
232,8,416,1,54,
232,8,416,4,0,
232,8,422,1,1,2
232,8,424,1,1,1
232,8,444,4,0,
232,8,445,4,0,
232,8,446,4,0,
232,9,21,1,24,
232,9,30,1,1,3
232,9,31,1,25,
232,9,45,1,1,4
232,9,46,4,0,
232,9,63,4,0,
232,9,70,4,0,
232,9,89,1,46,
232,9,89,4,0,
232,9,92,4,0,
232,9,104,4,0,
232,9,111,1,1,5
232,9,156,4,0,
232,9,157,4,0,
232,9,164,4,0,
232,9,173,3,0,
232,9,175,1,1,6
232,9,182,4,0,
232,9,184,1,39,
232,9,189,3,0,
232,9,201,4,0,
232,9,203,4,0,
232,9,205,1,15,
232,9,205,3,0,
232,9,207,4,0,
232,9,213,4,0,
232,9,214,4,0,
232,9,216,4,0,
232,9,218,4,0,
232,9,222,1,19,
232,9,229,1,6,
232,9,231,4,0,
232,9,237,4,0,
232,9,241,4,0,
232,9,246,3,0,
232,9,249,4,0,
232,9,263,4,0,
232,9,276,3,0,
232,9,282,1,10,
232,9,282,3,0,
232,9,283,3,0,
232,9,290,4,0,
232,9,317,4,0,
232,9,334,3,0,
232,9,340,3,0,
232,9,360,4,0,
232,9,363,4,0,
232,9,372,1,31,
232,9,397,4,0,
232,9,398,4,0,
232,9,402,3,0,
232,9,414,3,0,
232,9,416,1,54,
232,9,416,4,0,
232,9,422,1,1,2
232,9,424,1,1,1
232,9,441,3,0,
232,9,444,4,0,
232,9,445,4,0,
232,9,446,4,0,
232,10,21,1,24,
232,10,29,3,0,
232,10,30,1,1,3
232,10,31,1,25,
232,10,45,1,1,4
232,10,46,4,0,
232,10,63,4,0,
232,10,70,4,0,
232,10,89,1,46,
232,10,89,4,0,
232,10,92,4,0,
232,10,104,4,0,
232,10,111,1,1,5
232,10,156,4,0,
232,10,157,4,0,
232,10,164,4,0,
232,10,173,3,0,
232,10,175,1,1,6
232,10,182,4,0,
232,10,184,1,39,
232,10,189,3,0,
232,10,201,4,0,
232,10,203,4,0,
232,10,205,1,15,
232,10,205,3,0,
232,10,207,4,0,
232,10,213,4,0,
232,10,214,4,0,
232,10,216,4,0,
232,10,218,4,0,
232,10,222,1,19,
232,10,229,1,6,
232,10,231,4,0,
232,10,237,4,0,
232,10,241,4,0,
232,10,246,3,0,
232,10,249,4,0,
232,10,263,4,0,
232,10,276,3,0,
232,10,282,1,10,
232,10,282,3,0,
232,10,283,3,0,
232,10,290,4,0,
232,10,317,4,0,
232,10,334,3,0,
232,10,335,3,0,
232,10,340,3,0,
232,10,360,4,0,
232,10,363,4,0,
232,10,372,1,31,
232,10,397,4,0,
232,10,398,4,0,
232,10,402,3,0,
232,10,414,3,0,
232,10,416,1,54,
232,10,416,4,0,
232,10,422,1,1,2
232,10,424,1,1,1
232,10,441,3,0,
232,10,444,4,0,
232,10,445,4,0,
232,10,446,4,0,
232,11,21,1,24,
232,11,30,1,1,3
232,11,31,1,25,
232,11,45,1,1,4
232,11,46,4,0,
232,11,63,4,0,
232,11,70,4,0,
232,11,89,1,46,
232,11,89,4,0,
232,11,92,4,0,
232,11,104,4,0,
232,11,111,1,1,5
232,11,156,4,0,
232,11,157,4,0,
232,11,164,4,0,
232,11,182,4,0,
232,11,184,1,39,
232,11,201,4,0,
232,11,205,1,15,
232,11,207,4,0,
232,11,213,4,0,
232,11,216,4,0,
232,11,218,4,0,
232,11,222,1,19,
232,11,229,1,6,
232,11,237,4,0,
232,11,241,4,0,
232,11,249,4,0,
232,11,263,4,0,
232,11,282,1,10,
232,11,317,4,0,
232,11,360,4,0,
232,11,372,1,31,
232,11,397,4,0,
232,11,398,4,0,
232,11,416,1,54,
232,11,416,4,0,
232,11,422,1,1,2
232,11,424,1,1,1
232,11,444,4,0,
232,11,496,4,0,
232,11,497,4,0,
232,11,523,1,1,6
232,11,523,4,0,
232,12,30,1,1,2
232,12,31,1,25,
232,12,45,1,1,3
232,12,46,4,0,
232,12,63,4,0,
232,12,70,4,0,
232,12,89,1,49,
232,12,89,4,0,
232,12,92,4,0,
232,12,104,4,0,
232,12,111,1,9,
232,12,156,4,0,
232,12,175,1,17,
232,12,182,4,0,
232,12,201,4,0,
232,12,205,1,33,
232,12,213,4,0,
232,12,216,4,0,
232,12,218,4,0,
232,12,229,1,41,
232,12,231,4,0,
232,12,237,4,0,
232,12,241,4,0,
232,12,249,4,0,
232,12,263,4,0,
232,12,290,4,0,
232,12,316,1,1,1
232,12,317,4,0,
232,13,30,1,1,2
232,13,31,1,25,
232,13,34,3,0,
232,13,38,3,0,
232,13,45,1,1,3
232,13,46,4,0,
232,13,63,4,0,
232,13,70,4,0,
232,13,89,1,49,
232,13,89,4,0,
232,13,92,4,0,
232,13,102,3,0,
232,13,104,4,0,
232,13,111,1,9,
232,13,156,4,0,
232,13,164,3,0,
232,13,175,1,17,
232,13,182,4,0,
232,13,201,4,0,
232,13,205,1,33,
232,13,207,3,0,
232,13,213,4,0,
232,13,216,4,0,
232,13,218,4,0,
232,13,229,1,41,
232,13,231,4,0,
232,13,237,4,0,
232,13,241,4,0,
232,13,249,4,0,
232,13,263,4,0,
232,13,290,4,0,
232,13,316,1,1,1
232,13,317,4,0,
232,14,21,1,24,
232,14,30,1,1,3
232,14,31,1,25,
232,14,45,1,1,4
232,14,46,4,0,
232,14,63,4,0,
232,14,70,4,0,
232,14,89,1,46,
232,14,89,4,0,
232,14,92,4,0,
232,14,104,4,0,
232,14,111,1,1,5
232,14,156,4,0,
232,14,157,4,0,
232,14,164,4,0,
232,14,173,3,0,
232,14,182,4,0,
232,14,184,1,39,
232,14,201,4,0,
232,14,205,1,15,
232,14,207,4,0,
232,14,213,4,0,
232,14,214,3,0,
232,14,216,4,0,
232,14,218,4,0,
232,14,222,1,19,
232,14,229,1,6,
232,14,231,3,0,
232,14,237,4,0,
232,14,241,4,0,
232,14,249,4,0,
232,14,263,4,0,
232,14,276,3,0,
232,14,282,1,10,
232,14,282,3,0,
232,14,283,3,0,
232,14,304,3,0,
232,14,317,4,0,
232,14,334,3,0,
232,14,335,3,0,
232,14,340,3,0,
232,14,360,4,0,
232,14,372,1,31,
232,14,387,3,0,
232,14,397,4,0,
232,14,398,4,0,
232,14,402,3,0,
232,14,414,3,0,
232,14,416,1,54,
232,14,416,4,0,
232,14,422,1,1,2
232,14,424,1,1,1
232,14,441,3,0,
232,14,444,4,0,
232,14,446,3,0,
232,14,496,4,0,
232,14,497,4,0,
232,14,523,1,1,6
232,14,523,4,0,
232,15,21,1,24,
232,15,30,1,1,3
232,15,31,1,25,
232,15,45,1,1,4
232,15,46,4,0,
232,15,63,4,0,
232,15,70,4,0,
232,15,89,1,46,
232,15,89,4,0,
232,15,92,4,0,
232,15,104,4,0,
232,15,111,1,1,5
232,15,156,4,0,
232,15,157,4,0,
232,15,164,4,0,
232,15,182,4,0,
232,15,184,1,39,
232,15,201,4,0,
232,15,205,1,15,
232,15,207,4,0,
232,15,213,4,0,
232,15,214,4,0,
232,15,216,4,0,
232,15,218,4,0,
232,15,222,1,19,
232,15,229,1,6,
232,15,237,4,0,
232,15,241,4,0,
232,15,249,4,0,
232,15,263,4,0,
232,15,282,1,10,
232,15,317,4,0,
232,15,360,4,0,
232,15,372,1,31,
232,15,397,4,0,
232,15,398,4,0,
232,15,416,1,54,
232,15,416,4,0,
232,15,422,1,1,2
232,15,424,1,1,1
232,15,444,4,0,
232,15,496,4,0,
232,15,497,4,0,
232,15,523,1,1,6
232,15,523,4,0,
232,15,590,4,0,
232,16,21,1,24,1
232,16,30,1,1,3
232,16,31,1,25,1
232,16,45,1,1,5
232,16,46,4,0,
232,16,63,4,0,
232,16,70,4,0,
232,16,89,1,43,1
232,16,89,4,0,
232,16,92,4,0,
232,16,104,4,0,
232,16,111,1,1,6
232,16,156,4,0,
232,16,157,4,0,
232,16,164,4,0,
232,16,173,3,0,
232,16,182,4,0,
232,16,184,1,37,1
232,16,201,4,0,
232,16,205,1,10,1
232,16,207,4,0,
232,16,213,4,0,
232,16,214,4,0,
232,16,216,4,0,
232,16,218,4,0,
232,16,222,1,30,1
232,16,229,1,6,1
232,16,231,3,0,
232,16,237,4,0,
232,16,241,4,0,
232,16,249,4,0,
232,16,263,4,0,
232,16,276,3,0,
232,16,282,1,19,1
232,16,282,3,0,
232,16,283,3,0,
232,16,290,4,0,
232,16,304,3,0,
232,16,317,4,0,
232,16,334,3,0,
232,16,335,3,0,
232,16,340,3,0,
232,16,360,4,0,
232,16,372,1,15,1
232,16,387,3,0,
232,16,397,4,0,
232,16,398,4,0,
232,16,402,3,0,
232,16,414,3,0,
232,16,416,1,50,1
232,16,416,4,0,
232,16,422,1,1,2
232,16,424,1,1,1
232,16,441,3,0,
232,16,444,4,0,
232,16,446,3,0,
232,16,496,4,0,
232,16,497,4,0,
232,16,523,1,1,4
232,16,523,4,0,
232,16,590,4,0,
232,17,21,1,24,
232,17,30,1,1,4
232,17,31,1,0,
232,17,31,1,1,1
232,17,45,1,1,6
232,17,46,4,0,
232,17,63,4,0,
232,17,89,1,43,
232,17,89,4,0,
232,17,92,4,0,
232,17,104,4,0,
232,17,111,1,1,7
232,17,156,4,0,
232,17,157,4,0,
232,17,164,4,0,
232,17,182,4,0,
232,17,184,1,37,
232,17,201,4,0,
232,17,205,1,10,
232,17,207,4,0,
232,17,213,4,0,
232,17,214,4,0,
232,17,216,4,0,
232,17,218,4,0,
232,17,222,1,30,
232,17,229,1,6,
232,17,237,4,0,
232,17,241,4,0,
232,17,263,4,0,
232,17,282,1,19,
232,17,317,4,0,
232,17,360,4,0,
232,17,372,1,15,
232,17,397,4,0,
232,17,398,4,0,
232,17,416,1,50,
232,17,416,4,0,
232,17,422,1,1,3
232,17,424,1,1,2
232,17,444,4,0,
232,17,496,4,0,
232,17,497,4,0,
232,17,523,1,1,5
232,17,523,4,0,
232,17,590,4,0,
232,17,693,4,0,
232,18,21,1,24,
232,18,30,1,1,4
232,18,31,1,0,
232,18,31,1,1,1
232,18,45,1,1,6
232,18,46,4,0,
232,18,63,4,0,
232,18,89,1,43,
232,18,89,4,0,
232,18,92,4,0,
232,18,104,4,0,
232,18,111,1,1,7
232,18,156,4,0,
232,18,157,4,0,
232,18,164,4,0,
232,18,182,4,0,
232,18,184,1,37,
232,18,201,4,0,
232,18,205,1,10,
232,18,207,4,0,
232,18,213,4,0,
232,18,214,4,0,
232,18,216,4,0,
232,18,218,4,0,
232,18,222,1,30,
232,18,229,1,6,
232,18,237,4,0,
232,18,241,4,0,
232,18,263,4,0,
232,18,282,1,19,
232,18,317,4,0,
232,18,360,4,0,
232,18,372,1,15,
232,18,397,4,0,
232,18,398,4,0,
232,18,416,1,50,
232,18,416,4,0,
232,18,422,1,1,3
232,18,424,1,1,2
232,18,444,4,0,
232,18,496,4,0,
232,18,497,4,0,
232,18,523,1,1,5
232,18,523,4,0,
232,18,590,4,0,
232,18,693,4,0,
233,3,33,1,1,2
233,3,59,4,0,
233,3,60,1,12,
233,3,63,4,0,
233,3,87,4,0,
233,3,92,4,0,
233,3,94,4,0,
233,3,97,1,9,
233,3,104,4,0,
233,3,105,1,20,
233,3,111,1,24,
233,3,111,4,0,
233,3,129,4,0,
233,3,138,4,0,
233,3,148,4,0,
233,3,156,4,0,
233,3,160,1,1,3
233,3,161,1,36,
233,3,168,4,0,
233,3,171,4,0,
233,3,173,4,0,
233,3,174,4,0,
233,3,176,1,1,1
233,3,182,4,0,
233,3,192,1,44,
233,3,192,4,0,
233,3,196,4,0,
233,3,199,1,32,
233,3,203,4,0,
233,3,207,4,0,
233,3,214,4,0,
233,3,216,4,0,
233,3,218,4,0,
233,3,231,4,0,
233,3,237,4,0,
233,3,240,4,0,
233,3,241,4,0,
233,3,244,4,0,
233,4,33,1,1,2
233,4,58,3,0,
233,4,59,4,0,
233,4,60,1,12,
233,4,63,4,0,
233,4,85,3,0,
233,4,87,4,0,
233,4,92,4,0,
233,4,94,4,0,
233,4,97,1,9,
233,4,104,4,0,
233,4,105,1,20,
233,4,111,1,24,
233,4,111,4,0,
233,4,129,4,0,
233,4,138,4,0,
233,4,148,4,0,
233,4,156,4,0,
233,4,160,1,1,3
233,4,161,1,36,
233,4,168,4,0,
233,4,171,4,0,
233,4,173,4,0,
233,4,174,4,0,
233,4,176,1,1,1
233,4,182,4,0,
233,4,192,1,44,
233,4,192,4,0,
233,4,196,4,0,
233,4,199,1,32,
233,4,203,4,0,
233,4,207,4,0,
233,4,214,4,0,
233,4,216,4,0,
233,4,218,4,0,
233,4,231,4,0,
233,4,237,4,0,
233,4,240,4,0,
233,4,241,4,0,
233,4,244,4,0,
233,5,33,1,1,2
233,5,58,4,0,
233,5,59,4,0,
233,5,60,1,12,
233,5,63,4,0,
233,5,76,4,0,
233,5,85,4,0,
233,5,87,4,0,
233,5,92,4,0,
233,5,94,4,0,
233,5,97,1,9,
233,5,104,4,0,
233,5,105,1,20,
233,5,111,1,24,
233,5,148,4,0,
233,5,156,4,0,
233,5,160,1,1,3
233,5,161,1,36,
233,5,168,4,0,
233,5,176,1,1,1
233,5,182,4,0,
233,5,192,1,48,
233,5,199,1,32,
233,5,216,4,0,
233,5,218,4,0,
233,5,231,4,0,
233,5,237,4,0,
233,5,240,4,0,
233,5,241,4,0,
233,5,247,4,0,
233,5,263,4,0,
233,5,278,1,44,
233,5,290,4,0,
233,5,332,4,0,
233,5,351,4,0,
233,6,33,1,1,2
233,6,38,3,0,
233,6,58,4,0,
233,6,59,4,0,
233,6,60,1,12,
233,6,63,4,0,
233,6,76,4,0,
233,6,85,4,0,
233,6,86,3,0,
233,6,87,4,0,
233,6,92,4,0,
233,6,94,4,0,
233,6,97,1,9,
233,6,102,3,0,
233,6,104,4,0,
233,6,105,1,20,
233,6,111,1,24,
233,6,111,3,0,
233,6,129,3,0,
233,6,138,3,0,
233,6,148,4,0,
233,6,156,4,0,
233,6,160,1,1,3
233,6,161,1,36,
233,6,164,3,0,
233,6,168,4,0,
233,6,173,3,0,
233,6,176,1,1,1
233,6,182,4,0,
233,6,192,1,48,
233,6,196,3,0,
233,6,199,1,32,
233,6,203,3,0,
233,6,207,3,0,
233,6,214,3,0,
233,6,216,4,0,
233,6,218,4,0,
233,6,231,4,0,
233,6,237,4,0,
233,6,240,4,0,
233,6,241,4,0,
233,6,244,3,0,
233,6,247,4,0,
233,6,263,4,0,
233,6,278,1,44,
233,6,290,4,0,
233,6,332,4,0,
233,6,351,4,0,
233,7,33,1,1,2
233,7,38,3,0,
233,7,58,4,0,
233,7,59,4,0,
233,7,60,1,12,
233,7,63,4,0,
233,7,76,4,0,
233,7,85,4,0,
233,7,86,3,0,
233,7,87,4,0,
233,7,92,4,0,
233,7,94,4,0,
233,7,97,1,9,
233,7,102,3,0,
233,7,104,4,0,
233,7,105,1,20,
233,7,111,1,24,
233,7,138,3,0,
233,7,148,4,0,
233,7,156,4,0,
233,7,160,1,1,3
233,7,161,1,36,
233,7,164,3,0,
233,7,168,4,0,
233,7,176,1,1,1
233,7,182,4,0,
233,7,192,1,48,
233,7,199,1,32,
233,7,216,4,0,
233,7,218,4,0,
233,7,231,4,0,
233,7,237,4,0,
233,7,240,4,0,
233,7,241,4,0,
233,7,247,4,0,
233,7,263,4,0,
233,7,278,1,44,
233,7,290,4,0,
233,7,332,4,0,
233,7,351,4,0,
233,8,33,1,1,2
233,8,58,4,0,
233,8,59,4,0,
233,8,60,1,7,
233,8,63,1,67,
233,8,63,4,0,
233,8,76,4,0,
233,8,85,4,0,
233,8,86,4,0,
233,8,87,4,0,
233,8,92,4,0,
233,8,94,4,0,
233,8,97,1,12,
233,8,104,4,0,
233,8,105,1,18,
233,8,111,1,1,4
233,8,138,4,0,
233,8,148,4,0,
233,8,156,4,0,
233,8,160,1,1,3
233,8,161,1,51,
233,8,164,4,0,
233,8,168,4,0,
233,8,176,1,1,1
233,8,182,4,0,
233,8,192,1,62,
233,8,199,1,45,
233,8,203,4,0,
233,8,207,4,0,
233,8,214,4,0,
233,8,216,4,0,
233,8,218,4,0,
233,8,231,4,0,
233,8,237,4,0,
233,8,240,4,0,
233,8,241,4,0,
233,8,244,4,0,
233,8,247,4,0,
233,8,263,4,0,
233,8,277,1,56,
233,8,278,1,34,
233,8,278,4,0,
233,8,290,4,0,
233,8,324,1,29,
233,8,332,4,0,
233,8,351,4,0,
233,8,363,4,0,
233,8,393,1,23,
233,8,416,4,0,
233,8,433,4,0,
233,8,435,1,40,
233,8,451,4,0,
233,9,33,1,1,2
233,9,58,4,0,
233,9,59,4,0,
233,9,60,1,7,
233,9,63,1,67,
233,9,63,4,0,
233,9,76,4,0,
233,9,85,4,0,
233,9,86,4,0,
233,9,87,4,0,
233,9,92,4,0,
233,9,94,4,0,
233,9,97,1,12,
233,9,104,4,0,
233,9,105,1,18,
233,9,111,1,1,4
233,9,129,3,0,
233,9,138,4,0,
233,9,148,4,0,
233,9,156,4,0,
233,9,160,1,1,3
233,9,161,1,51,
233,9,164,4,0,
233,9,168,4,0,
233,9,173,3,0,
233,9,176,1,1,1
233,9,182,4,0,
233,9,192,1,62,
233,9,196,3,0,
233,9,199,1,45,
233,9,203,4,0,
233,9,207,4,0,
233,9,214,4,0,
233,9,216,4,0,
233,9,218,4,0,
233,9,231,4,0,
233,9,237,4,0,
233,9,240,4,0,
233,9,241,4,0,
233,9,244,4,0,
233,9,247,4,0,
233,9,263,4,0,
233,9,271,3,0,
233,9,277,1,56,
233,9,278,1,34,
233,9,278,4,0,
233,9,290,4,0,
233,9,324,1,29,
233,9,324,3,0,
233,9,332,4,0,
233,9,351,4,0,
233,9,363,4,0,
233,9,387,3,0,
233,9,393,1,23,
233,9,416,4,0,
233,9,428,3,0,
233,9,433,4,0,
233,9,435,1,40,
233,9,451,4,0,
233,10,33,1,1,2
233,10,58,4,0,
233,10,59,4,0,
233,10,60,1,7,
233,10,63,1,67,
233,10,63,4,0,
233,10,76,4,0,
233,10,85,4,0,
233,10,86,4,0,
233,10,87,4,0,
233,10,92,4,0,
233,10,94,4,0,
233,10,97,1,12,
233,10,104,4,0,
233,10,105,1,18,
233,10,111,1,1,4
233,10,129,3,0,
233,10,138,4,0,
233,10,148,4,0,
233,10,156,4,0,
233,10,160,1,1,3
233,10,161,1,51,
233,10,164,4,0,
233,10,168,4,0,
233,10,173,3,0,
233,10,176,1,1,1
233,10,182,4,0,
233,10,192,1,62,
233,10,196,3,0,
233,10,199,1,45,
233,10,203,4,0,
233,10,207,4,0,
233,10,214,4,0,
233,10,216,4,0,
233,10,218,4,0,
233,10,220,3,0,
233,10,231,4,0,
233,10,237,4,0,
233,10,240,4,0,
233,10,241,4,0,
233,10,244,4,0,
233,10,247,4,0,
233,10,263,4,0,
233,10,271,3,0,
233,10,277,1,56,
233,10,277,3,0,
233,10,278,1,34,
233,10,278,4,0,
233,10,290,4,0,
233,10,324,1,29,
233,10,324,3,0,
233,10,332,4,0,
233,10,351,4,0,
233,10,356,3,0,
233,10,363,4,0,
233,10,387,3,0,
233,10,393,1,23,
233,10,416,4,0,
233,10,428,3,0,
233,10,433,4,0,
233,10,435,1,40,
233,10,451,4,0,
233,11,33,1,1,2
233,11,58,4,0,
233,11,59,4,0,
233,11,60,1,7,
233,11,63,1,67,
233,11,63,4,0,
233,11,76,4,0,
233,11,85,4,0,
233,11,86,4,0,
233,11,87,4,0,
233,11,92,4,0,
233,11,94,4,0,
233,11,97,1,12,
233,11,104,4,0,
233,11,105,1,18,
233,11,111,1,1,4
233,11,138,4,0,
233,11,148,4,0,
233,11,156,4,0,
233,11,160,1,1,3
233,11,161,1,51,
233,11,164,4,0,
233,11,168,4,0,
233,11,176,1,1,1
233,11,182,4,0,
233,11,192,1,62,
233,11,199,1,45,
233,11,207,4,0,
233,11,216,4,0,
233,11,218,4,0,
233,11,237,4,0,
233,11,240,4,0,
233,11,241,4,0,
233,11,244,4,0,
233,11,247,4,0,
233,11,263,4,0,
233,11,277,1,56,
233,11,278,1,34,
233,11,324,1,29,
233,11,332,4,0,
233,11,393,1,23,
233,11,416,4,0,
233,11,433,4,0,
233,11,435,1,40,
233,11,451,4,0,
233,11,473,4,0,
233,11,496,4,0,
233,12,33,1,1,2
233,12,58,4,0,
233,12,59,4,0,
233,12,60,1,12,
233,12,63,4,0,
233,12,76,4,0,
233,12,85,4,0,
233,12,87,4,0,
233,12,92,4,0,
233,12,94,4,0,
233,12,97,1,9,
233,12,104,4,0,
233,12,105,1,20,
233,12,111,1,24,
233,12,148,4,0,
233,12,156,4,0,
233,12,160,1,1,3
233,12,161,1,36,
233,12,168,4,0,
233,12,176,1,1,1
233,12,182,4,0,
233,12,192,1,48,
233,12,199,1,32,
233,12,216,4,0,
233,12,218,4,0,
233,12,231,4,0,
233,12,237,4,0,
233,12,240,4,0,
233,12,241,4,0,
233,12,247,4,0,
233,12,263,4,0,
233,12,278,1,44,
233,12,290,4,0,
233,12,332,4,0,
233,12,351,4,0,
233,13,33,1,1,2
233,13,38,3,0,
233,13,58,4,0,
233,13,59,4,0,
233,13,60,1,12,
233,13,63,4,0,
233,13,76,4,0,
233,13,85,4,0,
233,13,86,3,0,
233,13,87,4,0,
233,13,92,4,0,
233,13,94,4,0,
233,13,97,1,9,
233,13,102,3,0,
233,13,104,4,0,
233,13,105,1,20,
233,13,111,1,24,
233,13,138,3,0,
233,13,148,4,0,
233,13,156,4,0,
233,13,160,1,1,3
233,13,161,1,36,
233,13,164,3,0,
233,13,168,4,0,
233,13,171,3,0,
233,13,176,1,1,1
233,13,182,4,0,
233,13,192,1,48,
233,13,196,3,0,
233,13,199,1,32,
233,13,207,3,0,
233,13,216,4,0,
233,13,218,4,0,
233,13,231,4,0,
233,13,237,4,0,
233,13,240,4,0,
233,13,241,4,0,
233,13,247,4,0,
233,13,263,4,0,
233,13,278,1,44,
233,13,290,4,0,
233,13,332,4,0,
233,13,351,4,0,
233,14,33,1,1,2
233,14,58,4,0,
233,14,59,4,0,
233,14,60,1,7,
233,14,63,1,67,
233,14,63,4,0,
233,14,76,4,0,
233,14,85,4,0,
233,14,86,4,0,
233,14,87,4,0,
233,14,92,4,0,
233,14,94,4,0,
233,14,97,1,12,
233,14,104,4,0,
233,14,105,1,18,
233,14,111,1,1,4
233,14,138,4,0,
233,14,148,4,0,
233,14,156,4,0,
233,14,160,1,1,3
233,14,161,1,51,
233,14,164,4,0,
233,14,168,4,0,
233,14,173,3,0,
233,14,176,1,1,1
233,14,182,4,0,
233,14,192,1,62,
233,14,196,3,0,
233,14,199,1,45,
233,14,207,4,0,
233,14,214,3,0,
233,14,216,4,0,
233,14,218,4,0,
233,14,220,3,0,
233,14,231,3,0,
233,14,237,4,0,
233,14,240,4,0,
233,14,241,4,0,
233,14,244,4,0,
233,14,247,4,0,
233,14,263,4,0,
233,14,271,3,0,
233,14,277,1,56,
233,14,277,3,0,
233,14,278,1,34,
233,14,278,3,0,
233,14,324,1,29,
233,14,324,3,0,
233,14,332,4,0,
233,14,356,3,0,
233,14,387,3,0,
233,14,393,1,23,
233,14,393,3,0,
233,14,416,4,0,
233,14,428,3,0,
233,14,433,4,0,
233,14,435,1,40,
233,14,451,4,0,
233,14,472,3,0,
233,14,473,4,0,
233,14,492,3,0,
233,14,496,4,0,
233,14,527,3,0,
233,15,33,1,1,4
233,15,58,4,0,
233,15,59,4,0,
233,15,60,1,7,
233,15,63,1,67,
233,15,63,4,0,
233,15,76,4,0,
233,15,85,4,0,
233,15,86,4,0,
233,15,87,4,0,
233,15,92,4,0,
233,15,94,4,0,
233,15,97,1,12,
233,15,104,4,0,
233,15,105,1,18,
233,15,111,1,1,6
233,15,138,4,0,
233,15,148,4,0,
233,15,156,4,0,
233,15,160,1,1,5
233,15,161,1,50,
233,15,164,4,0,
233,15,168,4,0,
233,15,176,1,1,3
233,15,182,4,0,
233,15,192,1,1,1
233,15,192,1,62,
233,15,199,1,45,
233,15,207,4,0,
233,15,214,4,0,
233,15,216,4,0,
233,15,218,4,0,
233,15,237,4,0,
233,15,240,4,0,
233,15,241,4,0,
233,15,244,4,0,
233,15,247,4,0,
233,15,263,4,0,
233,15,277,1,1,2
233,15,277,1,56,
233,15,278,1,34,
233,15,324,1,29,
233,15,332,4,0,
233,15,393,1,23,
233,15,416,4,0,
233,15,433,4,0,
233,15,435,1,40,
233,15,451,4,0,
233,15,473,4,0,
233,15,496,4,0,
233,15,590,4,0,
233,16,33,1,1,4
233,16,58,4,0,
233,16,59,4,0,
233,16,60,1,7,1
233,16,63,1,67,1
233,16,63,4,0,
233,16,76,4,0,
233,16,85,4,0,
233,16,86,4,0,
233,16,87,4,0,
233,16,92,4,0,
233,16,94,4,0,
233,16,97,1,12,1
233,16,104,4,0,
233,16,105,1,18,1
233,16,111,1,1,6
233,16,138,4,0,
233,16,148,4,0,
233,16,156,4,0,
233,16,160,1,1,5
233,16,161,1,50,1
233,16,164,4,0,
233,16,168,4,0,
233,16,173,3,0,
233,16,176,1,1,3
233,16,182,4,0,
233,16,192,1,1,1
233,16,192,1,62,1
233,16,196,3,0,
233,16,199,1,45,1
233,16,207,4,0,
233,16,214,4,0,
233,16,216,4,0,
233,16,218,4,0,
233,16,220,3,0,
233,16,231,3,0,
233,16,237,4,0,
233,16,240,4,0,
233,16,241,4,0,
233,16,244,4,0,
233,16,247,4,0,
233,16,263,4,0,
233,16,271,3,0,
233,16,277,1,1,2
233,16,277,1,56,1
233,16,277,3,0,
233,16,278,1,34,1
233,16,278,3,0,
233,16,290,4,0,
233,16,324,1,29,1
233,16,324,3,0,
233,16,332,4,0,
233,16,351,3,0,
233,16,356,3,0,
233,16,387,3,0,
233,16,393,1,23,1
233,16,393,3,0,
233,16,416,4,0,
233,16,428,3,0,
233,16,433,4,0,
233,16,435,1,40,1
233,16,451,4,0,
233,16,472,3,0,
233,16,473,4,0,
233,16,492,3,0,
233,16,496,4,0,
233,16,527,3,0,
233,16,590,4,0,
233,17,33,1,1,4
233,17,58,4,0,
233,17,59,4,0,
233,17,60,1,7,
233,17,63,1,67,
233,17,63,4,0,
233,17,76,4,0,
233,17,85,4,0,
233,17,86,4,0,
233,17,87,4,0,
233,17,92,4,0,
233,17,94,4,0,
233,17,97,1,12,
233,17,104,4,0,
233,17,105,1,18,
233,17,111,1,1,6
233,17,138,4,0,
233,17,156,4,0,
233,17,160,1,1,5
233,17,161,1,50,
233,17,164,4,0,
233,17,168,4,0,
233,17,176,1,1,3
233,17,182,4,0,
233,17,192,1,1,1
233,17,192,1,62,
233,17,199,1,45,
233,17,207,4,0,
233,17,214,4,0,
233,17,216,4,0,
233,17,218,4,0,
233,17,237,4,0,
233,17,240,4,0,
233,17,241,4,0,
233,17,244,4,0,
233,17,247,4,0,
233,17,263,4,0,
233,17,277,1,1,2
233,17,277,1,56,
233,17,278,1,34,
233,17,324,1,29,
233,17,332,4,0,
233,17,393,1,23,
233,17,416,4,0,
233,17,433,4,0,
233,17,435,1,40,
233,17,451,4,0,
233,17,473,4,0,
233,17,496,4,0,
233,17,590,4,0,
233,18,33,1,1,4
233,18,58,4,0,
233,18,59,4,0,
233,18,60,1,7,
233,18,63,1,67,
233,18,63,4,0,
233,18,76,4,0,
233,18,85,4,0,
233,18,86,4,0,
233,18,87,4,0,
233,18,92,4,0,
233,18,94,4,0,
233,18,97,1,12,
233,18,104,4,0,
233,18,105,1,18,
233,18,111,1,1,6
233,18,138,4,0,
233,18,156,4,0,
233,18,160,1,1,5
233,18,161,1,50,
233,18,164,4,0,
233,18,168,4,0,
233,18,176,1,1,3
233,18,182,4,0,
233,18,192,1,1,1
233,18,192,1,62,
233,18,199,1,45,
233,18,207,4,0,
233,18,214,4,0,
233,18,216,4,0,
233,18,218,4,0,
233,18,237,4,0,
233,18,240,4,0,
233,18,241,4,0,
233,18,244,4,0,
233,18,247,4,0,
233,18,263,4,0,
233,18,277,1,1,2
233,18,277,1,56,
233,18,278,1,34,
233,18,324,1,29,
233,18,332,4,0,
233,18,393,1,23,
233,18,416,4,0,
233,18,433,4,0,
233,18,435,1,40,
233,18,451,4,0,
233,18,473,4,0,
233,18,496,4,0,
233,18,590,4,0,
234,3,23,1,23,
234,3,28,1,31,
234,3,29,4,0,
234,3,33,1,1,
234,3,36,1,40,
234,3,43,1,8,
234,3,44,2,0,
234,3,46,4,0,
234,3,50,2,0,
234,3,89,4,0,
234,3,92,4,0,
234,3,94,4,0,
234,3,95,1,15,
234,3,104,4,0,
234,3,109,1,49,
234,3,113,2,0,
234,3,115,2,0,
234,3,129,4,0,
234,3,138,4,0,
234,3,148,4,0,
234,3,156,4,0,
234,3,168,4,0,
234,3,171,4,0,
234,3,173,4,0,
234,3,174,4,0,
234,3,180,2,0,
234,3,182,4,0,
234,3,189,4,0,
234,3,197,4,0,
234,3,203,4,0,
234,3,207,4,0,
234,3,213,4,0,
234,3,214,4,0,
234,3,216,4,0,
234,3,218,4,0,
234,3,237,4,0,
234,3,240,4,0,
234,3,241,4,0,
234,3,244,4,0,
234,4,23,1,23,
234,4,28,1,31,
234,4,29,4,0,
234,4,33,1,1,
234,4,36,1,40,
234,4,43,1,8,
234,4,44,2,0,
234,4,46,4,0,
234,4,50,2,0,
234,4,89,4,0,
234,4,92,4,0,
234,4,94,4,0,
234,4,95,1,15,
234,4,104,4,0,
234,4,109,1,49,
234,4,113,2,0,
234,4,115,2,0,
234,4,129,4,0,
234,4,138,4,0,
234,4,148,4,0,
234,4,156,4,0,
234,4,168,4,0,
234,4,171,4,0,
234,4,173,4,0,
234,4,174,4,0,
234,4,180,2,0,
234,4,182,4,0,
234,4,189,4,0,
234,4,197,4,0,
234,4,203,4,0,
234,4,207,4,0,
234,4,213,4,0,
234,4,214,4,0,
234,4,216,4,0,
234,4,218,4,0,
234,4,237,4,0,
234,4,240,4,0,
234,4,241,4,0,
234,4,244,4,0,
234,5,23,1,25,
234,5,28,1,31,
234,5,33,1,1,
234,5,36,1,37,
234,5,43,1,7,
234,5,44,2,0,
234,5,46,4,0,
234,5,50,2,0,
234,5,76,4,0,
234,5,85,4,0,
234,5,87,4,0,
234,5,89,4,0,
234,5,92,4,0,
234,5,94,4,0,
234,5,95,1,19,
234,5,104,4,0,
234,5,109,1,43,
234,5,113,4,0,
234,5,115,4,0,
234,5,148,4,0,
234,5,156,4,0,
234,5,168,4,0,
234,5,180,2,0,
234,5,182,4,0,
234,5,207,2,0,
234,5,213,4,0,
234,5,216,4,0,
234,5,218,4,0,
234,5,231,4,0,
234,5,237,4,0,
234,5,240,4,0,
234,5,241,4,0,
234,5,244,2,0,
234,5,247,4,0,
234,5,263,4,0,
234,5,285,4,0,
234,5,290,4,0,
234,5,310,1,13,
234,5,326,2,0,
234,5,347,1,49,
234,5,347,4,0,
234,5,351,4,0,
234,6,23,1,25,
234,6,28,1,31,
234,6,33,1,1,
234,6,34,3,0,
234,6,36,1,37,
234,6,38,3,0,
234,6,43,1,7,
234,6,44,2,0,
234,6,46,4,0,
234,6,50,2,0,
234,6,76,4,0,
234,6,85,4,0,
234,6,86,3,0,
234,6,87,4,0,
234,6,89,4,0,
234,6,92,4,0,
234,6,94,4,0,
234,6,95,1,19,
234,6,102,3,0,
234,6,104,4,0,
234,6,109,1,43,
234,6,113,4,0,
234,6,115,4,0,
234,6,129,3,0,
234,6,138,3,0,
234,6,148,4,0,
234,6,156,4,0,
234,6,164,3,0,
234,6,168,4,0,
234,6,173,3,0,
234,6,180,2,0,
234,6,182,4,0,
234,6,189,3,0,
234,6,203,3,0,
234,6,207,2,0,
234,6,207,3,0,
234,6,213,4,0,
234,6,214,3,0,
234,6,216,4,0,
234,6,218,4,0,
234,6,231,4,0,
234,6,237,4,0,
234,6,240,4,0,
234,6,241,4,0,
234,6,244,2,0,
234,6,244,3,0,
234,6,247,4,0,
234,6,263,4,0,
234,6,285,4,0,
234,6,290,4,0,
234,6,310,1,13,
234,6,326,2,0,
234,6,347,1,49,
234,6,347,4,0,
234,6,351,4,0,
234,7,23,1,21,
234,7,28,1,27,
234,7,33,1,1,
234,7,34,3,0,
234,7,36,1,37,
234,7,38,3,0,
234,7,43,1,7,
234,7,44,2,0,
234,7,46,4,0,
234,7,50,2,0,
234,7,76,4,0,
234,7,85,4,0,
234,7,86,3,0,
234,7,87,4,0,
234,7,89,4,0,
234,7,92,4,0,
234,7,94,4,0,
234,7,95,1,17,
234,7,102,3,0,
234,7,104,4,0,
234,7,109,1,41,
234,7,113,4,0,
234,7,115,4,0,
234,7,138,3,0,
234,7,148,4,0,
234,7,156,4,0,
234,7,164,3,0,
234,7,168,4,0,
234,7,180,2,0,
234,7,182,4,0,
234,7,207,2,0,
234,7,213,4,0,
234,7,216,4,0,
234,7,218,4,0,
234,7,231,4,0,
234,7,237,4,0,
234,7,240,4,0,
234,7,241,4,0,
234,7,244,2,0,
234,7,247,4,0,
234,7,263,4,0,
234,7,272,1,31,
234,7,285,4,0,
234,7,290,4,0,
234,7,310,1,11,
234,7,326,2,0,
234,7,347,1,47,
234,7,347,4,0,
234,7,351,4,0,
234,8,23,1,13,
234,8,24,2,0,
234,8,28,1,16,
234,8,33,1,1,
234,8,36,1,21,
234,8,37,2,0,
234,8,43,1,3,
234,8,44,2,0,
234,8,46,4,0,
234,8,50,2,0,
234,8,76,4,0,
234,8,85,4,0,
234,8,86,4,0,
234,8,87,4,0,
234,8,89,4,0,
234,8,92,4,0,
234,8,94,4,0,
234,8,95,1,10,
234,8,104,4,0,
234,8,109,1,23,
234,8,113,4,0,
234,8,115,4,0,
234,8,138,4,0,
234,8,148,4,0,
234,8,156,4,0,
234,8,164,4,0,
234,8,168,4,0,
234,8,180,2,0,
234,8,182,4,0,
234,8,203,4,0,
234,8,207,2,0,
234,8,207,4,0,
234,8,213,4,0,
234,8,214,4,0,
234,8,216,4,0,
234,8,218,4,0,
234,8,231,4,0,
234,8,237,4,0,
234,8,240,4,0,
234,8,241,4,0,
234,8,244,2,0,
234,8,244,4,0,
234,8,247,4,0,
234,8,263,4,0,
234,8,272,1,33,
234,8,285,4,0,
234,8,286,1,43,
234,8,290,4,0,
234,8,310,1,7,
234,8,326,2,0,
234,8,347,1,27,
234,8,347,4,0,
234,8,351,4,0,
234,8,363,4,0,
234,8,382,1,53,
234,8,412,4,0,
234,8,416,4,0,
234,8,428,1,38,
234,8,428,2,0,
234,8,433,4,0,
234,8,445,1,49,
234,8,445,4,0,
234,8,451,4,0,
234,9,23,1,13,
234,9,24,2,0,
234,9,28,1,16,
234,9,33,1,1,
234,9,36,1,21,
234,9,37,2,0,
234,9,43,1,3,
234,9,44,2,0,
234,9,46,4,0,
234,9,50,2,0,
234,9,76,4,0,
234,9,85,4,0,
234,9,86,4,0,
234,9,87,4,0,
234,9,89,4,0,
234,9,92,4,0,
234,9,94,4,0,
234,9,95,1,10,
234,9,104,4,0,
234,9,109,1,23,
234,9,113,4,0,
234,9,115,4,0,
234,9,129,3,0,
234,9,138,4,0,
234,9,148,4,0,
234,9,156,4,0,
234,9,164,4,0,
234,9,168,4,0,
234,9,173,3,0,
234,9,180,2,0,
234,9,180,3,0,
234,9,182,4,0,
234,9,189,3,0,
234,9,203,4,0,
234,9,207,2,0,
234,9,207,4,0,
234,9,213,4,0,
234,9,214,4,0,
234,9,216,4,0,
234,9,218,4,0,
234,9,231,4,0,
234,9,237,4,0,
234,9,240,4,0,
234,9,241,4,0,
234,9,244,2,0,
234,9,244,4,0,
234,9,247,4,0,
234,9,253,3,0,
234,9,263,4,0,
234,9,272,1,33,
234,9,285,4,0,
234,9,286,1,43,
234,9,290,4,0,
234,9,310,1,7,
234,9,324,3,0,
234,9,326,2,0,
234,9,340,3,0,
234,9,347,1,27,
234,9,347,4,0,
234,9,351,4,0,
234,9,363,4,0,
234,9,382,1,53,
234,9,387,3,0,
234,9,389,3,0,
234,9,412,4,0,
234,9,416,4,0,
234,9,428,1,38,
234,9,428,2,0,
234,9,428,3,0,
234,9,433,4,0,
234,9,445,1,49,
234,9,445,4,0,
234,9,451,4,0,
234,10,23,1,13,
234,10,24,2,0,
234,10,28,1,16,
234,10,29,3,0,
234,10,33,1,1,
234,10,36,1,21,
234,10,37,2,0,
234,10,43,1,3,
234,10,44,2,0,
234,10,46,4,0,
234,10,50,2,0,
234,10,76,4,0,
234,10,85,4,0,
234,10,86,4,0,
234,10,87,4,0,
234,10,89,4,0,
234,10,92,4,0,
234,10,94,4,0,
234,10,95,1,10,
234,10,104,4,0,
234,10,109,1,23,
234,10,113,4,0,
234,10,115,4,0,
234,10,129,3,0,
234,10,138,4,0,
234,10,148,4,0,
234,10,156,4,0,
234,10,164,4,0,
234,10,168,4,0,
234,10,173,3,0,
234,10,180,2,0,
234,10,180,3,0,
234,10,182,4,0,
234,10,189,3,0,
234,10,203,4,0,
234,10,207,2,0,
234,10,207,4,0,
234,10,213,4,0,
234,10,214,4,0,
234,10,216,4,0,
234,10,218,4,0,
234,10,224,2,0,
234,10,231,4,0,
234,10,237,4,0,
234,10,240,4,0,
234,10,241,4,0,
234,10,244,2,0,
234,10,244,4,0,
234,10,247,4,0,
234,10,253,3,0,
234,10,263,4,0,
234,10,272,1,33,
234,10,272,3,0,
234,10,285,4,0,
234,10,286,1,43,
234,10,290,4,0,
234,10,310,1,7,
234,10,324,3,0,
234,10,326,2,0,
234,10,340,3,0,
234,10,347,1,27,
234,10,347,4,0,
234,10,351,4,0,
234,10,356,3,0,
234,10,363,4,0,
234,10,382,1,53,
234,10,387,3,0,
234,10,389,3,0,
234,10,412,4,0,
234,10,416,4,0,
234,10,428,1,38,
234,10,428,2,0,
234,10,428,3,0,
234,10,433,4,0,
234,10,445,1,49,
234,10,445,4,0,
234,10,451,4,0,
234,11,23,1,13,
234,11,24,2,0,
234,11,26,1,43,
234,11,28,1,16,
234,11,33,1,1,
234,11,36,1,21,
234,11,37,2,0,
234,11,43,1,3,
234,11,44,2,0,
234,11,46,4,0,
234,11,50,2,0,
234,11,76,4,0,
234,11,85,4,0,
234,11,86,4,0,
234,11,87,4,0,
234,11,89,4,0,
234,11,92,4,0,
234,11,94,4,0,
234,11,95,1,10,
234,11,99,2,0,
234,11,104,4,0,
234,11,109,1,23,
234,11,113,4,0,
234,11,115,4,0,
234,11,138,4,0,
234,11,148,4,0,
234,11,156,4,0,
234,11,164,4,0,
234,11,168,4,0,
234,11,180,2,0,
234,11,182,4,0,
234,11,207,4,0,
234,11,213,4,0,
234,11,216,4,0,
234,11,218,4,0,
234,11,224,2,0,
234,11,237,4,0,
234,11,240,4,0,
234,11,241,4,0,
234,11,244,4,0,
234,11,247,4,0,
234,11,263,4,0,
234,11,272,1,33,
234,11,286,1,49,
234,11,300,2,0,
234,11,310,1,7,
234,11,326,2,0,
234,11,347,1,27,
234,11,347,4,0,
234,11,382,1,55,
234,11,382,2,0,
234,11,412,4,0,
234,11,416,4,0,
234,11,428,1,38,
234,11,428,2,0,
234,11,433,4,0,
234,11,445,1,53,
234,11,451,4,0,
234,11,473,4,0,
234,11,496,4,0,
234,11,514,4,0,
234,11,523,4,0,
234,11,526,4,0,
234,11,528,4,0,
234,12,23,1,25,
234,12,28,1,31,
234,12,33,1,1,
234,12,36,1,37,
234,12,43,1,7,
234,12,46,4,0,
234,12,76,4,0,
234,12,85,4,0,
234,12,87,4,0,
234,12,89,4,0,
234,12,92,4,0,
234,12,94,4,0,
234,12,95,1,19,
234,12,104,4,0,
234,12,109,1,43,
234,12,113,4,0,
234,12,115,4,0,
234,12,148,4,0,
234,12,156,4,0,
234,12,168,4,0,
234,12,182,4,0,
234,12,213,4,0,
234,12,216,4,0,
234,12,218,4,0,
234,12,231,4,0,
234,12,237,4,0,
234,12,240,4,0,
234,12,241,4,0,
234,12,247,4,0,
234,12,263,4,0,
234,12,285,4,0,
234,12,290,4,0,
234,12,310,1,13,
234,12,347,1,49,
234,12,347,4,0,
234,12,351,4,0,
234,13,23,1,25,
234,13,28,1,31,
234,13,33,1,1,
234,13,34,3,0,
234,13,36,1,37,
234,13,38,3,0,
234,13,43,1,7,
234,13,46,4,0,
234,13,76,4,0,
234,13,85,4,0,
234,13,86,3,0,
234,13,87,4,0,
234,13,89,4,0,
234,13,92,4,0,
234,13,94,4,0,
234,13,95,1,19,
234,13,102,3,0,
234,13,104,4,0,
234,13,109,1,43,
234,13,113,4,0,
234,13,115,4,0,
234,13,138,3,0,
234,13,148,4,0,
234,13,156,4,0,
234,13,164,3,0,
234,13,168,4,0,
234,13,171,3,0,
234,13,182,4,0,
234,13,207,3,0,
234,13,213,4,0,
234,13,216,4,0,
234,13,218,4,0,
234,13,231,4,0,
234,13,237,4,0,
234,13,240,4,0,
234,13,241,4,0,
234,13,247,4,0,
234,13,263,4,0,
234,13,285,4,0,
234,13,290,4,0,
234,13,310,1,13,
234,13,347,1,49,
234,13,347,4,0,
234,13,351,4,0,
234,14,23,1,13,
234,14,24,2,0,
234,14,26,1,43,
234,14,28,1,16,
234,14,33,1,1,
234,14,36,1,21,
234,14,37,2,0,
234,14,43,1,3,
234,14,44,2,0,
234,14,46,4,0,
234,14,50,2,0,
234,14,76,4,0,
234,14,85,4,0,
234,14,86,4,0,
234,14,87,4,0,
234,14,89,4,0,
234,14,92,4,0,
234,14,94,4,0,
234,14,95,1,10,
234,14,99,2,0,
234,14,104,4,0,
234,14,109,1,23,
234,14,113,4,0,
234,14,115,4,0,
234,14,138,4,0,
234,14,148,4,0,
234,14,156,4,0,
234,14,164,4,0,
234,14,168,4,0,
234,14,173,3,0,
234,14,180,2,0,
234,14,180,3,0,
234,14,182,4,0,
234,14,207,4,0,
234,14,213,4,0,
234,14,214,3,0,
234,14,216,4,0,
234,14,218,4,0,
234,14,224,2,0,
234,14,231,3,0,
234,14,237,4,0,
234,14,240,4,0,
234,14,241,4,0,
234,14,244,4,0,
234,14,247,4,0,
234,14,253,3,0,
234,14,263,4,0,
234,14,272,1,33,
234,14,272,3,0,
234,14,285,3,0,
234,14,286,1,49,
234,14,300,2,0,
234,14,310,1,7,
234,14,324,3,0,
234,14,326,2,0,
234,14,340,3,0,
234,14,347,1,27,
234,14,347,4,0,
234,14,356,3,0,
234,14,382,1,55,
234,14,382,2,0,
234,14,387,3,0,
234,14,412,4,0,
234,14,416,4,0,
234,14,428,1,38,
234,14,428,2,0,
234,14,428,3,0,
234,14,433,4,0,
234,14,445,1,53,
234,14,451,4,0,
234,14,473,4,0,
234,14,478,3,0,
234,14,496,4,0,
234,14,514,4,0,
234,14,523,4,0,
234,14,526,4,0,
234,14,528,4,0,
234,15,23,1,13,
234,15,24,2,0,
234,15,26,1,43,
234,15,28,1,16,
234,15,33,1,1,2
234,15,36,1,21,
234,15,37,2,0,
234,15,43,1,3,
234,15,44,2,0,
234,15,46,4,0,
234,15,50,2,0,
234,15,76,4,0,
234,15,85,4,0,
234,15,86,4,0,
234,15,87,4,0,
234,15,89,4,0,
234,15,92,4,0,
234,15,94,4,0,
234,15,95,1,10,
234,15,99,2,0,
234,15,104,4,0,
234,15,109,1,23,
234,15,113,4,0,
234,15,115,4,0,
234,15,138,4,0,
234,15,148,4,0,
234,15,156,4,0,
234,15,164,4,0,
234,15,168,4,0,
234,15,180,2,0,
234,15,182,4,0,
234,15,207,4,0,
234,15,213,4,0,
234,15,214,4,0,
234,15,216,4,0,
234,15,218,4,0,
234,15,224,2,0,
234,15,237,4,0,
234,15,240,4,0,
234,15,241,4,0,
234,15,244,4,0,
234,15,247,4,0,
234,15,263,4,0,
234,15,272,1,33,
234,15,286,1,49,
234,15,300,2,0,
234,15,310,1,7,
234,15,326,2,0,
234,15,347,1,27,
234,15,347,4,0,
234,15,382,1,1,1
234,15,382,1,55,
234,15,382,2,0,
234,15,412,4,0,
234,15,416,4,0,
234,15,428,1,38,
234,15,428,2,0,
234,15,433,4,0,
234,15,445,1,50,
234,15,451,4,0,
234,15,473,4,0,
234,15,496,4,0,
234,15,514,4,0,
234,15,523,4,0,
234,15,528,4,0,
234,15,590,4,0,
234,16,23,1,13,1
234,16,24,2,0,
234,16,26,1,43,1
234,16,28,1,16,1
234,16,33,1,1,2
234,16,36,1,21,1
234,16,37,2,0,
234,16,43,1,3,1
234,16,44,2,0,
234,16,46,4,0,
234,16,50,2,0,
234,16,76,4,0,
234,16,85,4,0,
234,16,86,4,0,
234,16,87,4,0,
234,16,89,4,0,
234,16,92,4,0,
234,16,94,4,0,
234,16,95,1,10,1
234,16,99,2,0,
234,16,104,4,0,
234,16,109,1,23,1
234,16,113,4,0,
234,16,115,4,0,
234,16,138,4,0,
234,16,148,4,0,
234,16,156,4,0,
234,16,164,4,0,
234,16,168,4,0,
234,16,173,3,0,
234,16,180,2,0,
234,16,180,3,0,
234,16,182,4,0,
234,16,207,4,0,
234,16,213,4,0,
234,16,214,4,0,
234,16,216,4,0,
234,16,218,4,0,
234,16,224,2,0,
234,16,231,3,0,
234,16,237,4,0,
234,16,240,4,0,
234,16,241,4,0,
234,16,244,4,0,
234,16,247,4,0,
234,16,253,3,0,
234,16,263,4,0,
234,16,272,1,33,1
234,16,272,3,0,
234,16,285,3,0,
234,16,286,1,49,1
234,16,290,4,0,
234,16,300,2,0,
234,16,310,1,7,1
234,16,324,3,0,
234,16,326,2,0,
234,16,340,3,0,
234,16,347,1,27,1
234,16,347,4,0,
234,16,351,3,0,
234,16,356,3,0,
234,16,382,1,1,1
234,16,382,1,55,1
234,16,382,2,0,
234,16,387,3,0,
234,16,412,4,0,
234,16,416,4,0,
234,16,428,1,38,1
234,16,428,2,0,
234,16,428,3,0,
234,16,433,4,0,
234,16,445,1,50,1
234,16,451,4,0,
234,16,473,4,0,
234,16,478,3,0,
234,16,496,4,0,
234,16,514,4,0,
234,16,523,4,0,
234,16,528,4,0,
234,16,590,4,0,
234,17,23,1,13,
234,17,24,2,0,
234,17,26,1,43,
234,17,28,1,16,
234,17,33,1,1,2
234,17,36,1,21,
234,17,37,2,0,
234,17,43,1,3,
234,17,44,2,0,
234,17,46,4,0,
234,17,50,2,0,
234,17,76,4,0,
234,17,85,4,0,
234,17,86,4,0,
234,17,87,4,0,
234,17,89,4,0,
234,17,92,4,0,
234,17,94,4,0,
234,17,95,1,10,
234,17,99,2,0,
234,17,104,4,0,
234,17,109,1,23,
234,17,113,4,0,
234,17,115,4,0,
234,17,138,4,0,
234,17,156,4,0,
234,17,164,4,0,
234,17,168,4,0,
234,17,180,2,0,
234,17,182,4,0,
234,17,207,4,0,
234,17,213,4,0,
234,17,214,4,0,
234,17,216,4,0,
234,17,218,4,0,
234,17,224,2,0,
234,17,237,4,0,
234,17,240,4,0,
234,17,241,4,0,
234,17,244,4,0,
234,17,247,4,0,
234,17,263,4,0,
234,17,272,1,33,
234,17,286,1,49,
234,17,300,2,0,
234,17,310,1,7,
234,17,326,2,0,
234,17,347,1,27,
234,17,347,4,0,
234,17,382,1,1,1
234,17,382,1,55,
234,17,382,2,0,
234,17,412,4,0,
234,17,416,4,0,
234,17,428,1,38,
234,17,428,2,0,
234,17,433,4,0,
234,17,445,1,50,
234,17,451,4,0,
234,17,473,4,0,
234,17,496,4,0,
234,17,523,4,0,
234,17,526,4,0,
234,17,528,4,0,
234,17,590,4,0,
234,18,23,1,13,
234,18,24,2,0,
234,18,26,1,43,
234,18,28,1,16,
234,18,33,1,1,2
234,18,36,1,21,
234,18,37,2,0,
234,18,43,1,3,
234,18,44,2,0,
234,18,46,4,0,
234,18,50,2,0,
234,18,76,4,0,
234,18,85,4,0,
234,18,86,4,0,
234,18,87,4,0,
234,18,89,4,0,
234,18,92,4,0,
234,18,94,4,0,
234,18,95,1,10,
234,18,99,2,0,
234,18,104,4,0,
234,18,109,1,23,
234,18,113,4,0,
234,18,115,4,0,
234,18,138,4,0,
234,18,156,4,0,
234,18,164,4,0,
234,18,168,4,0,
234,18,180,2,0,
234,18,182,4,0,
234,18,207,4,0,
234,18,213,4,0,
234,18,214,4,0,
234,18,216,4,0,
234,18,218,4,0,
234,18,224,2,0,
234,18,237,4,0,
234,18,240,4,0,
234,18,241,4,0,
234,18,244,4,0,
234,18,247,4,0,
234,18,263,4,0,
234,18,272,1,33,
234,18,286,1,49,
234,18,300,2,0,
234,18,310,1,7,
234,18,326,2,0,
234,18,347,1,27,
234,18,347,4,0,
234,18,382,1,1,1
234,18,382,1,55,
234,18,382,2,0,
234,18,412,4,0,
234,18,416,4,0,
234,18,428,1,38,
234,18,428,2,0,
234,18,433,4,0,
234,18,445,1,50,
234,18,451,4,0,
234,18,473,4,0,
234,18,496,4,0,
234,18,523,4,0,
234,18,526,4,0,
234,18,528,4,0,
234,18,590,4,0,
235,3,166,1,1,
235,3,166,1,11,
235,3,166,1,21,
235,3,166,1,31,
235,3,166,1,41,
235,3,166,1,51,
235,3,166,1,61,
235,3,166,1,71,
235,3,166,1,81,
235,3,166,1,91,
235,4,166,1,1,
235,4,166,1,11,
235,4,166,1,21,
235,4,166,1,31,
235,4,166,1,41,
235,4,166,1,51,
235,4,166,1,61,
235,4,166,1,71,
235,4,166,1,81,
235,4,166,1,91,
235,5,166,1,1,
235,5,166,1,11,
235,5,166,1,21,
235,5,166,1,31,
235,5,166,1,41,
235,5,166,1,51,
235,5,166,1,61,
235,5,166,1,71,
235,5,166,1,81,
235,5,166,1,91,
235,6,166,1,1,
235,6,166,1,11,
235,6,166,1,21,
235,6,166,1,31,
235,6,166,1,41,
235,6,166,1,51,
235,6,166,1,61,
235,6,166,1,71,
235,6,166,1,81,
235,6,166,1,91,
235,7,166,1,1,
235,7,166,1,11,
235,7,166,1,21,
235,7,166,1,31,
235,7,166,1,41,
235,7,166,1,51,
235,7,166,1,61,
235,7,166,1,71,
235,7,166,1,81,
235,7,166,1,91,
235,8,166,1,1,
235,8,166,1,11,
235,8,166,1,21,
235,8,166,1,31,
235,8,166,1,41,
235,8,166,1,51,
235,8,166,1,61,
235,8,166,1,71,
235,8,166,1,81,
235,8,166,1,91,
235,9,166,1,1,
235,9,166,1,11,
235,9,166,1,21,
235,9,166,1,31,
235,9,166,1,41,
235,9,166,1,51,
235,9,166,1,61,
235,9,166,1,71,
235,9,166,1,81,
235,9,166,1,91,
235,10,166,1,1,
235,10,166,1,11,
235,10,166,1,21,
235,10,166,1,31,
235,10,166,1,41,
235,10,166,1,51,
235,10,166,1,61,
235,10,166,1,71,
235,10,166,1,81,
235,10,166,1,91,
235,11,166,1,1,
235,11,166,1,11,
235,11,166,1,21,
235,11,166,1,31,
235,11,166,1,41,
235,11,166,1,51,
235,11,166,1,61,
235,11,166,1,71,
235,11,166,1,81,
235,11,166,1,91,
235,12,166,1,1,
235,12,166,1,11,
235,12,166,1,21,
235,12,166,1,31,
235,12,166,1,41,
235,12,166,1,51,
235,12,166,1,61,
235,12,166,1,71,
235,12,166,1,81,
235,12,166,1,91,
235,13,166,1,1,
235,13,166,1,11,
235,13,166,1,21,
235,13,166,1,31,
235,13,166,1,41,
235,13,166,1,51,
235,13,166,1,61,
235,13,166,1,71,
235,13,166,1,81,
235,13,166,1,91,
235,14,166,1,1,
235,14,166,1,11,
235,14,166,1,21,
235,14,166,1,31,
235,14,166,1,41,
235,14,166,1,51,
235,14,166,1,61,
235,14,166,1,71,
235,14,166,1,81,
235,14,166,1,91,
235,15,166,1,1,
235,15,166,1,11,
235,15,166,1,21,
235,15,166,1,31,
235,15,166,1,41,
235,15,166,1,51,
235,15,166,1,61,
235,15,166,1,71,
235,15,166,1,81,
235,15,166,1,91,
235,16,166,1,1,1
235,16,166,1,11,1
235,16,166,1,21,1
235,16,166,1,31,1
235,16,166,1,41,1
235,16,166,1,51,1
235,16,166,1,61,1
235,16,166,1,71,1
235,16,166,1,81,1
235,16,166,1,91,1
235,17,166,1,1,
235,17,166,1,11,
235,17,166,1,21,
235,17,166,1,31,
235,17,166,1,41,
235,17,166,1,51,
235,17,166,1,61,
235,17,166,1,71,
235,17,166,1,81,
235,17,166,1,91,
235,18,166,1,1,
235,18,166,1,11,
235,18,166,1,21,
235,18,166,1,31,
235,18,166,1,41,
235,18,166,1,51,
235,18,166,1,61,
235,18,166,1,71,
235,18,166,1,81,
235,18,166,1,91,
236,3,29,4,0,
236,3,33,1,1,
236,3,70,4,0,
236,3,92,4,0,
236,3,104,4,0,
236,3,129,4,0,
236,3,136,2,0,
236,3,156,4,0,
236,3,168,4,0,
236,3,170,2,0,
236,3,173,4,0,
236,3,174,4,0,
236,3,182,4,0,
236,3,183,2,0,
236,3,189,4,0,
236,3,197,4,0,
236,3,203,4,0,
236,3,207,4,0,
236,3,213,4,0,
236,3,214,4,0,
236,3,216,4,0,
236,3,218,4,0,
236,3,229,2,0,
236,3,237,4,0,
236,3,241,4,0,
236,3,249,4,0,
236,4,29,4,0,
236,4,33,1,1,
236,4,70,4,0,
236,4,92,4,0,
236,4,104,4,0,
236,4,129,4,0,
236,4,136,2,0,
236,4,156,4,0,
236,4,168,4,0,
236,4,170,2,0,
236,4,173,4,0,
236,4,174,4,0,
236,4,182,4,0,
236,4,183,2,0,
236,4,189,4,0,
236,4,197,4,0,
236,4,203,4,0,
236,4,207,4,0,
236,4,213,4,0,
236,4,214,4,0,
236,4,216,4,0,
236,4,218,4,0,
236,4,229,2,0,
236,4,237,4,0,
236,4,241,4,0,
236,4,249,4,0,
236,5,33,1,1,
236,5,70,4,0,
236,5,89,4,0,
236,5,92,4,0,
236,5,104,4,0,
236,5,136,2,0,
236,5,156,4,0,
236,5,168,4,0,
236,5,170,2,0,
236,5,182,4,0,
236,5,183,2,0,
236,5,213,4,0,
236,5,216,4,0,
236,5,218,4,0,
236,5,229,2,0,
236,5,237,4,0,
236,5,240,4,0,
236,5,241,4,0,
236,5,249,4,0,
236,5,263,4,0,
236,5,270,2,0,
236,5,280,4,0,
236,5,290,4,0,
236,5,339,4,0,
236,6,25,3,0,
236,6,33,1,1,
236,6,34,3,0,
236,6,38,3,0,
236,6,68,3,0,
236,6,69,3,0,
236,6,70,4,0,
236,6,89,4,0,
236,6,92,4,0,
236,6,102,3,0,
236,6,104,4,0,
236,6,129,3,0,
236,6,136,2,0,
236,6,156,4,0,
236,6,157,3,0,
236,6,164,3,0,
236,6,168,4,0,
236,6,170,2,0,
236,6,173,3,0,
236,6,182,4,0,
236,6,183,2,0,
236,6,189,3,0,
236,6,203,3,0,
236,6,207,3,0,
236,6,213,4,0,
236,6,214,3,0,
236,6,216,4,0,
236,6,218,4,0,
236,6,229,2,0,
236,6,237,4,0,
236,6,240,4,0,
236,6,241,4,0,
236,6,249,4,0,
236,6,263,4,0,
236,6,270,2,0,
236,6,280,4,0,
236,6,290,4,0,
236,6,339,4,0,
236,7,25,3,0,
236,7,33,1,1,
236,7,34,3,0,
236,7,38,3,0,
236,7,68,3,0,
236,7,69,3,0,
236,7,70,4,0,
236,7,89,4,0,
236,7,92,4,0,
236,7,102,3,0,
236,7,104,4,0,
236,7,136,2,0,
236,7,156,4,0,
236,7,157,3,0,
236,7,164,3,0,
236,7,168,4,0,
236,7,170,2,0,
236,7,182,4,0,
236,7,183,2,0,
236,7,213,4,0,
236,7,216,4,0,
236,7,218,4,0,
236,7,229,2,0,
236,7,237,4,0,
236,7,240,4,0,
236,7,241,4,0,
236,7,249,4,0,
236,7,263,4,0,
236,7,270,2,0,
236,7,280,4,0,
236,7,290,4,0,
236,7,339,4,0,
236,8,33,1,1,1
236,8,68,2,0,
236,8,70,4,0,
236,8,89,4,0,
236,8,92,4,0,
236,8,104,4,0,
236,8,136,2,0,
236,8,156,4,0,
236,8,157,4,0,
236,8,164,4,0,
236,8,168,4,0,
236,8,170,2,0,
236,8,182,4,0,
236,8,183,2,0,
236,8,203,4,0,
236,8,207,4,0,
236,8,213,4,0,
236,8,214,4,0,
236,8,216,4,0,
236,8,218,4,0,
236,8,229,2,0,
236,8,237,4,0,
236,8,240,4,0,
236,8,241,4,0,
236,8,249,4,0,
236,8,252,1,1,3
236,8,263,4,0,
236,8,270,1,1,2
236,8,270,2,0,
236,8,280,4,0,
236,8,290,4,0,
236,8,339,4,0,
236,8,363,4,0,
236,8,410,2,0,
236,8,418,2,0,
236,8,445,4,0,
236,9,33,1,1,1
236,9,68,2,0,
236,9,70,4,0,
236,9,89,4,0,
236,9,92,4,0,
236,9,104,4,0,
236,9,129,3,0,
236,9,136,2,0,
236,9,156,4,0,
236,9,157,4,0,
236,9,164,4,0,
236,9,168,4,0,
236,9,170,2,0,
236,9,173,3,0,
236,9,182,4,0,
236,9,183,2,0,
236,9,189,3,0,
236,9,193,1,1,4
236,9,203,4,0,
236,9,207,4,0,
236,9,213,4,0,
236,9,214,4,0,
236,9,216,4,0,
236,9,218,4,0,
236,9,229,2,0,
236,9,237,4,0,
236,9,240,4,0,
236,9,241,4,0,
236,9,249,4,0,
236,9,252,1,1,3
236,9,253,3,0,
236,9,263,4,0,
236,9,270,1,1,2
236,9,270,2,0,
236,9,270,3,0,
236,9,280,4,0,
236,9,290,4,0,
236,9,339,4,0,
236,9,363,4,0,
236,9,410,2,0,
236,9,410,3,0,
236,9,418,2,0,
236,9,445,4,0,
236,10,29,3,0,
236,10,33,1,1,1
236,10,67,3,0,
236,10,68,2,0,
236,10,70,4,0,
236,10,89,4,0,
236,10,92,4,0,
236,10,104,4,0,
236,10,129,3,0,
236,10,136,2,0,
236,10,156,4,0,
236,10,157,4,0,
236,10,164,4,0,
236,10,168,4,0,
236,10,170,2,0,
236,10,173,3,0,
236,10,182,4,0,
236,10,183,2,0,
236,10,189,3,0,
236,10,193,1,1,4
236,10,203,4,0,
236,10,207,4,0,
236,10,213,4,0,
236,10,214,4,0,
236,10,216,4,0,
236,10,218,4,0,
236,10,229,2,0,
236,10,237,4,0,
236,10,240,4,0,
236,10,241,4,0,
236,10,249,4,0,
236,10,252,1,1,3
236,10,253,3,0,
236,10,263,4,0,
236,10,270,1,1,2
236,10,270,2,0,
236,10,270,3,0,
236,10,272,3,0,
236,10,280,4,0,
236,10,290,4,0,
236,10,339,4,0,
236,10,363,4,0,
236,10,410,2,0,
236,10,410,3,0,
236,10,418,2,0,
236,10,445,4,0,
236,11,33,1,1,1
236,11,68,2,0,
236,11,70,4,0,
236,11,89,4,0,
236,11,92,4,0,
236,11,104,4,0,
236,11,136,2,0,
236,11,156,4,0,
236,11,157,4,0,
236,11,164,4,0,
236,11,168,4,0,
236,11,170,2,0,
236,11,182,4,0,
236,11,183,2,0,
236,11,193,1,1,4
236,11,203,2,0,
236,11,207,4,0,
236,11,213,4,0,
236,11,216,4,0,
236,11,218,4,0,
236,11,228,2,0,
236,11,229,2,0,
236,11,237,4,0,
236,11,240,4,0,
236,11,241,4,0,
236,11,249,4,0,
236,11,252,1,1,3
236,11,263,4,0,
236,11,270,1,1,2
236,11,270,2,0,
236,11,280,4,0,
236,11,339,4,0,
236,11,364,2,0,
236,11,410,2,0,
236,11,418,2,0,
236,11,490,4,0,
236,11,496,4,0,
236,11,514,4,0,
236,11,523,4,0,
236,11,526,4,0,
236,12,33,1,1,
236,12,70,4,0,
236,12,89,4,0,
236,12,92,4,0,
236,12,104,4,0,
236,12,156,4,0,
236,12,168,4,0,
236,12,182,4,0,
236,12,213,4,0,
236,12,216,4,0,
236,12,218,4,0,
236,12,237,4,0,
236,12,240,4,0,
236,12,241,4,0,
236,12,249,4,0,
236,12,263,4,0,
236,12,280,4,0,
236,12,290,4,0,
236,12,339,4,0,
236,13,33,1,1,
236,13,34,3,0,
236,13,38,3,0,
236,13,69,3,0,
236,13,70,4,0,
236,13,89,4,0,
236,13,92,4,0,
236,13,102,3,0,
236,13,104,4,0,
236,13,156,4,0,
236,13,164,3,0,
236,13,168,4,0,
236,13,182,4,0,
236,13,207,3,0,
236,13,213,4,0,
236,13,216,4,0,
236,13,218,4,0,
236,13,237,4,0,
236,13,240,4,0,
236,13,241,4,0,
236,13,249,4,0,
236,13,263,4,0,
236,13,280,4,0,
236,13,290,4,0,
236,13,339,4,0,
236,14,33,1,1,1
236,14,67,3,0,
236,14,68,2,0,
236,14,70,4,0,
236,14,89,4,0,
236,14,92,4,0,
236,14,104,4,0,
236,14,136,2,0,
236,14,156,4,0,
236,14,157,4,0,
236,14,164,4,0,
236,14,168,4,0,
236,14,170,2,0,
236,14,173,3,0,
236,14,182,4,0,
236,14,183,2,0,
236,14,193,1,1,4
236,14,203,2,0,
236,14,207,4,0,
236,14,213,4,0,
236,14,214,3,0,
236,14,216,4,0,
236,14,218,4,0,
236,14,228,2,0,
236,14,229,2,0,
236,14,237,4,0,
236,14,240,4,0,
236,14,241,4,0,
236,14,249,4,0,
236,14,252,1,1,3
236,14,253,3,0,
236,14,263,4,0,
236,14,270,1,1,2
236,14,270,2,0,
236,14,270,3,0,
236,14,272,3,0,
236,14,280,4,0,
236,14,339,4,0,
236,14,343,3,0,
236,14,364,2,0,
236,14,410,2,0,
236,14,418,2,0,
236,14,490,4,0,
236,14,496,4,0,
236,14,514,4,0,
236,14,523,4,0,
236,14,526,4,0,
236,15,33,1,1,1
236,15,68,2,0,
236,15,70,4,0,
236,15,89,4,0,
236,15,92,4,0,
236,15,104,4,0,
236,15,136,2,0,
236,15,156,4,0,
236,15,157,4,0,
236,15,164,4,0,
236,15,168,4,0,
236,15,170,2,0,
236,15,182,4,0,
236,15,183,2,0,
236,15,193,1,1,4
236,15,203,2,0,
236,15,207,4,0,
236,15,213,4,0,
236,15,214,4,0,
236,15,216,4,0,
236,15,218,4,0,
236,15,228,2,0,
236,15,229,2,0,
236,15,237,4,0,
236,15,240,4,0,
236,15,241,4,0,
236,15,249,4,0,
236,15,252,1,1,3
236,15,263,4,0,
236,15,270,1,1,2
236,15,270,2,0,
236,15,280,4,0,
236,15,339,4,0,
236,15,364,2,0,
236,15,410,2,0,
236,15,418,2,0,
236,15,490,4,0,
236,15,496,4,0,
236,15,502,2,0,
236,15,514,4,0,
236,15,523,4,0,
236,15,590,4,0,
236,16,33,1,1,1
236,16,67,3,0,
236,16,68,2,0,
236,16,70,4,0,
236,16,89,4,0,
236,16,92,4,0,
236,16,104,4,0,
236,16,136,2,0,
236,16,156,4,0,
236,16,157,4,0,
236,16,164,4,0,
236,16,168,4,0,
236,16,170,2,0,
236,16,173,3,0,
236,16,182,4,0,
236,16,183,2,0,
236,16,193,1,1,4
236,16,203,2,0,
236,16,207,4,0,
236,16,213,4,0,
236,16,214,4,0,
236,16,216,4,0,
236,16,218,4,0,
236,16,228,2,0,
236,16,229,2,0,
236,16,237,4,0,
236,16,240,4,0,
236,16,241,4,0,
236,16,249,4,0,
236,16,252,1,1,3
236,16,253,3,0,
236,16,263,4,0,
236,16,270,1,1,2
236,16,270,2,0,
236,16,270,3,0,
236,16,272,3,0,
236,16,280,4,0,
236,16,290,4,0,
236,16,339,4,0,
236,16,343,3,0,
236,16,364,2,0,
236,16,410,2,0,
236,16,418,2,0,
236,16,490,4,0,
236,16,496,4,0,
236,16,514,4,0,
236,16,523,4,0,
236,16,590,4,0,
236,17,33,1,1,1
236,17,68,2,0,
236,17,89,4,0,
236,17,92,4,0,
236,17,104,4,0,
236,17,136,2,0,
236,17,156,4,0,
236,17,157,4,0,
236,17,164,4,0,
236,17,168,4,0,
236,17,170,2,0,
236,17,182,4,0,
236,17,183,2,0,
236,17,193,1,1,4
236,17,203,2,0,
236,17,207,4,0,
236,17,213,4,0,
236,17,214,4,0,
236,17,216,4,0,
236,17,218,4,0,
236,17,228,2,0,
236,17,229,2,0,
236,17,237,4,0,
236,17,240,4,0,
236,17,241,4,0,
236,17,252,1,1,3
236,17,263,4,0,
236,17,270,1,1,2
236,17,270,2,0,
236,17,280,4,0,
236,17,339,4,0,
236,17,364,2,0,
236,17,410,2,0,
236,17,418,2,0,
236,17,490,4,0,
236,17,496,4,0,
236,17,523,4,0,
236,17,526,4,0,
236,17,590,4,0,
236,18,33,1,1,1
236,18,68,2,0,
236,18,89,4,0,
236,18,92,4,0,
236,18,104,4,0,
236,18,136,2,0,
236,18,156,4,0,
236,18,157,4,0,
236,18,164,4,0,
236,18,168,4,0,
236,18,170,2,0,
236,18,182,4,0,
236,18,183,2,0,
236,18,193,1,1,4
236,18,203,2,0,
236,18,207,4,0,
236,18,213,4,0,
236,18,214,4,0,
236,18,216,4,0,
236,18,218,4,0,
236,18,228,2,0,
236,18,229,2,0,
236,18,237,4,0,
236,18,240,4,0,
236,18,241,4,0,
236,18,252,1,1,3
236,18,263,4,0,
236,18,270,1,1,2
236,18,270,2,0,
236,18,280,4,0,
236,18,339,4,0,
236,18,364,2,0,
236,18,410,2,0,
236,18,418,2,0,
236,18,490,4,0,
236,18,496,4,0,
236,18,523,4,0,
236,18,526,4,0,
236,18,590,4,0,
237,3,27,1,1,
237,3,29,4,0,
237,3,68,1,31,
237,3,70,4,0,
237,3,91,4,0,
237,3,92,4,0,
237,3,97,1,37,
237,3,98,1,19,
237,3,104,4,0,
237,3,116,1,7,
237,3,129,4,0,
237,3,156,4,0,
237,3,167,1,49,
237,3,168,4,0,
237,3,173,4,0,
237,3,174,4,0,
237,3,182,4,0,
237,3,189,4,0,
237,3,197,1,43,
237,3,197,4,0,
237,3,203,4,0,
237,3,207,4,0,
237,3,213,4,0,
237,3,214,4,0,
237,3,216,4,0,
237,3,218,4,0,
237,3,228,1,13,
237,3,229,1,25,
237,3,237,4,0,
237,3,241,4,0,
237,3,249,4,0,
237,4,27,1,1,
237,4,29,4,0,
237,4,68,1,31,
237,4,70,4,0,
237,4,91,4,0,
237,4,92,4,0,
237,4,97,1,37,
237,4,98,1,19,
237,4,104,4,0,
237,4,116,1,7,
237,4,129,4,0,
237,4,156,4,0,
237,4,167,1,49,
237,4,168,4,0,
237,4,173,4,0,
237,4,174,4,0,
237,4,182,4,0,
237,4,189,4,0,
237,4,197,1,43,
237,4,197,4,0,
237,4,203,4,0,
237,4,207,4,0,
237,4,213,4,0,
237,4,214,4,0,
237,4,216,4,0,
237,4,218,4,0,
237,4,228,1,13,
237,4,229,1,25,
237,4,237,4,0,
237,4,241,4,0,
237,4,249,4,0,
237,5,27,1,1,2
237,5,68,1,31,
237,5,70,4,0,
237,5,89,4,0,
237,5,91,4,0,
237,5,92,4,0,
237,5,97,1,37,
237,5,98,1,19,
237,5,104,4,0,
237,5,116,1,7,
237,5,156,4,0,
237,5,167,1,20,
237,5,168,4,0,
237,5,182,4,0,
237,5,197,1,43,
237,5,201,4,0,
237,5,213,4,0,
237,5,216,4,0,
237,5,218,4,0,
237,5,228,1,13,
237,5,229,1,25,
237,5,237,4,0,
237,5,240,4,0,
237,5,241,4,0,
237,5,249,4,0,
237,5,263,4,0,
237,5,279,1,1,1
237,5,280,4,0,
237,5,283,1,49,
237,5,290,4,0,
237,5,339,4,0,
237,6,25,3,0,
237,6,27,1,1,2
237,6,34,3,0,
237,6,38,3,0,
237,6,68,1,31,
237,6,68,3,0,
237,6,69,3,0,
237,6,70,4,0,
237,6,89,4,0,
237,6,91,4,0,
237,6,92,4,0,
237,6,97,1,37,
237,6,98,1,19,
237,6,102,3,0,
237,6,104,4,0,
237,6,116,1,7,
237,6,129,3,0,
237,6,156,4,0,
237,6,157,3,0,
237,6,164,3,0,
237,6,167,1,20,
237,6,168,4,0,
237,6,173,3,0,
237,6,182,4,0,
237,6,189,3,0,
237,6,197,1,43,
237,6,201,4,0,
237,6,203,3,0,
237,6,207,3,0,
237,6,213,4,0,
237,6,214,3,0,
237,6,216,4,0,
237,6,218,4,0,
237,6,228,1,13,
237,6,229,1,25,
237,6,237,4,0,
237,6,240,4,0,
237,6,241,4,0,
237,6,249,4,0,
237,6,263,4,0,
237,6,279,1,1,1
237,6,280,4,0,
237,6,283,1,49,
237,6,290,4,0,
237,6,339,4,0,
237,7,25,3,0,
237,7,27,1,1,2
237,7,34,3,0,
237,7,38,3,0,
237,7,68,1,31,
237,7,68,3,0,
237,7,69,3,0,
237,7,70,4,0,
237,7,89,4,0,
237,7,91,4,0,
237,7,92,4,0,
237,7,97,1,37,
237,7,98,1,19,
237,7,102,3,0,
237,7,104,4,0,
237,7,116,1,7,
237,7,156,4,0,
237,7,157,3,0,
237,7,164,3,0,
237,7,167,1,20,
237,7,168,4,0,
237,7,182,4,0,
237,7,197,1,43,
237,7,201,4,0,
237,7,213,4,0,
237,7,216,4,0,
237,7,218,4,0,
237,7,228,1,13,
237,7,229,1,25,
237,7,237,4,0,
237,7,240,4,0,
237,7,241,4,0,
237,7,249,4,0,
237,7,263,4,0,
237,7,279,1,1,1
237,7,280,4,0,
237,7,283,1,49,
237,7,290,4,0,
237,7,339,4,0,
237,8,27,1,1,2
237,8,68,1,28,
237,8,70,4,0,
237,8,89,4,0,
237,8,91,4,0,
237,8,92,4,0,
237,8,97,1,37,
237,8,98,1,15,
237,8,104,4,0,
237,8,116,1,6,
237,8,156,4,0,
237,8,157,4,0,
237,8,164,4,0,
237,8,167,1,19,
237,8,168,4,0,
237,8,182,4,0,
237,8,197,1,46,
237,8,201,4,0,
237,8,203,4,0,
237,8,207,4,0,
237,8,213,4,0,
237,8,214,4,0,
237,8,216,4,0,
237,8,218,4,0,
237,8,228,1,10,
237,8,229,1,24,
237,8,237,4,0,
237,8,240,4,0,
237,8,241,4,0,
237,8,249,4,0,
237,8,263,4,0,
237,8,279,1,1,1
237,8,280,4,0,
237,8,283,1,55,
237,8,290,4,0,
237,8,332,4,0,
237,8,339,4,0,
237,8,360,1,42,
237,8,360,4,0,
237,8,363,4,0,
237,8,364,1,33,
237,8,370,1,51,
237,8,444,4,0,
237,8,445,4,0,
237,9,27,1,1,2
237,9,68,1,28,
237,9,70,4,0,
237,9,89,4,0,
237,9,91,4,0,
237,9,92,4,0,
237,9,97,1,37,
237,9,98,1,15,
237,9,104,4,0,
237,9,116,1,6,
237,9,129,3,0,
237,9,156,4,0,
237,9,157,4,0,
237,9,164,4,0,
237,9,167,1,19,
237,9,168,4,0,
237,9,173,3,0,
237,9,182,4,0,
237,9,189,3,0,
237,9,197,1,46,
237,9,201,4,0,
237,9,203,4,0,
237,9,205,3,0,
237,9,207,4,0,
237,9,213,4,0,
237,9,214,4,0,
237,9,216,4,0,
237,9,218,4,0,
237,9,228,1,10,
237,9,229,1,24,
237,9,237,4,0,
237,9,239,3,0,
237,9,240,4,0,
237,9,241,4,0,
237,9,249,4,0,
237,9,263,4,0,
237,9,270,3,0,
237,9,279,1,1,1
237,9,280,4,0,
237,9,283,1,55,
237,9,283,3,0,
237,9,290,4,0,
237,9,332,4,0,
237,9,339,4,0,
237,9,360,1,42,
237,9,360,4,0,
237,9,363,4,0,
237,9,364,1,33,
237,9,370,1,51,
237,9,389,3,0,
237,9,410,3,0,
237,9,444,4,0,
237,9,445,4,0,
237,10,27,1,1,2
237,10,29,3,0,
237,10,67,3,0,
237,10,68,1,28,
237,10,70,4,0,
237,10,89,4,0,
237,10,91,4,0,
237,10,92,4,0,
237,10,97,1,37,
237,10,98,1,15,
237,10,104,4,0,
237,10,116,1,6,
237,10,129,3,0,
237,10,156,4,0,
237,10,157,4,0,
237,10,164,4,0,
237,10,167,1,19,
237,10,168,4,0,
237,10,173,3,0,
237,10,182,4,0,
237,10,189,3,0,
237,10,197,1,46,
237,10,201,4,0,
237,10,203,4,0,
237,10,205,3,0,
237,10,207,4,0,
237,10,213,4,0,
237,10,214,4,0,
237,10,216,4,0,
237,10,218,4,0,
237,10,228,1,10,
237,10,229,1,24,
237,10,237,4,0,
237,10,239,3,0,
237,10,240,4,0,
237,10,241,4,0,
237,10,249,4,0,
237,10,263,4,0,
237,10,270,3,0,
237,10,272,3,0,
237,10,279,1,1,1
237,10,280,4,0,
237,10,283,1,55,
237,10,283,3,0,
237,10,290,4,0,
237,10,332,4,0,
237,10,339,4,0,
237,10,360,1,42,
237,10,360,4,0,
237,10,363,4,0,
237,10,364,1,33,
237,10,370,1,51,
237,10,389,3,0,
237,10,410,3,0,
237,10,444,4,0,
237,10,445,4,0,
237,11,27,1,1,2
237,11,68,1,28,
237,11,70,4,0,
237,11,89,4,0,
237,11,91,4,0,
237,11,92,4,0,
237,11,97,1,37,
237,11,98,1,15,
237,11,104,4,0,
237,11,116,1,6,
237,11,156,4,0,
237,11,157,4,0,
237,11,164,4,0,
237,11,167,1,19,
237,11,168,4,0,
237,11,182,4,0,
237,11,197,1,51,
237,11,201,4,0,
237,11,207,4,0,
237,11,213,4,0,
237,11,216,4,0,
237,11,218,4,0,
237,11,228,1,10,
237,11,229,1,24,
237,11,237,4,0,
237,11,240,4,0,
237,11,241,4,0,
237,11,249,4,0,
237,11,263,4,0,
237,11,279,1,1,1
237,11,280,4,0,
237,11,283,1,60,
237,11,332,4,0,
237,11,339,4,0,
237,11,360,1,42,
237,11,360,4,0,
237,11,364,1,33,
237,11,370,1,55,
237,11,444,4,0,
237,11,469,1,46,1
237,11,490,4,0,
237,11,496,4,0,
237,11,501,1,46,2
237,11,514,4,0,
237,11,523,4,0,
237,11,526,4,0,
237,12,27,1,1,2
237,12,68,1,31,
237,12,70,4,0,
237,12,89,4,0,
237,12,91,4,0,
237,12,92,4,0,
237,12,97,1,37,
237,12,98,1,19,
237,12,104,4,0,
237,12,116,1,7,
237,12,156,4,0,
237,12,167,1,20,
237,12,168,4,0,
237,12,182,4,0,
237,12,197,1,43,
237,12,201,4,0,
237,12,213,4,0,
237,12,216,4,0,
237,12,218,4,0,
237,12,228,1,13,
237,12,229,1,25,
237,12,237,4,0,
237,12,240,4,0,
237,12,241,4,0,
237,12,249,4,0,
237,12,263,4,0,
237,12,279,1,1,1
237,12,280,4,0,
237,12,283,1,49,
237,12,290,4,0,
237,12,339,4,0,
237,13,27,1,1,2
237,13,34,3,0,
237,13,38,3,0,
237,13,68,1,31,
237,13,69,3,0,
237,13,70,4,0,
237,13,89,4,0,
237,13,91,4,0,
237,13,92,4,0,
237,13,97,1,37,
237,13,98,1,19,
237,13,102,3,0,
237,13,104,4,0,
237,13,116,1,7,
237,13,156,4,0,
237,13,164,3,0,
237,13,167,1,20,
237,13,168,4,0,
237,13,182,4,0,
237,13,197,1,43,
237,13,201,4,0,
237,13,207,3,0,
237,13,213,4,0,
237,13,216,4,0,
237,13,218,4,0,
237,13,228,1,13,
237,13,229,1,25,
237,13,237,4,0,
237,13,240,4,0,
237,13,241,4,0,
237,13,249,4,0,
237,13,263,4,0,
237,13,279,1,1,1
237,13,280,4,0,
237,13,283,1,49,
237,13,290,4,0,
237,13,339,4,0,
237,14,27,1,1,2
237,14,67,3,0,
237,14,68,1,28,
237,14,70,4,0,
237,14,89,4,0,
237,14,91,4,0,
237,14,92,4,0,
237,14,97,1,37,
237,14,98,1,15,
237,14,104,4,0,
237,14,116,1,6,
237,14,156,4,0,
237,14,157,4,0,
237,14,164,4,0,
237,14,167,1,19,
237,14,168,4,0,
237,14,173,3,0,
237,14,182,4,0,
237,14,197,1,51,
237,14,201,4,0,
237,14,207,4,0,
237,14,213,4,0,
237,14,214,3,0,
237,14,216,4,0,
237,14,218,4,0,
237,14,228,1,10,
237,14,229,1,24,
237,14,237,4,0,
237,14,240,4,0,
237,14,241,4,0,
237,14,249,4,0,
237,14,263,4,0,
237,14,270,3,0,
237,14,272,3,0,
237,14,279,1,1,1
237,14,280,4,0,
237,14,283,1,60,
237,14,283,3,0,
237,14,332,4,0,
237,14,339,4,0,
237,14,343,3,0,
237,14,360,1,42,
237,14,360,4,0,
237,14,364,1,33,
237,14,370,1,55,
237,14,444,4,0,
237,14,469,1,46,1
237,14,490,4,0,
237,14,496,4,0,
237,14,501,1,46,2
237,14,514,4,0,
237,14,523,4,0,
237,14,526,4,0,
237,14,529,3,0,
237,15,27,1,1,4
237,15,68,1,28,
237,15,70,4,0,
237,15,89,4,0,
237,15,91,4,0,
237,15,92,4,0,
237,15,97,1,37,
237,15,98,1,15,
237,15,104,4,0,
237,15,116,1,6,
237,15,156,4,0,
237,15,157,4,0,
237,15,164,4,0,
237,15,167,1,19,
237,15,168,4,0,
237,15,182,4,0,
237,15,197,1,50,
237,15,201,4,0,
237,15,207,4,0,
237,15,213,4,0,
237,15,214,4,0,
237,15,216,4,0,
237,15,218,4,0,
237,15,228,1,10,
237,15,229,1,24,
237,15,237,4,0,
237,15,240,4,0,
237,15,241,4,0,
237,15,249,4,0,
237,15,263,4,0,
237,15,279,1,1,3
237,15,280,4,0,
237,15,283,1,1,1
237,15,283,1,60,
237,15,332,4,0,
237,15,339,4,0,
237,15,360,1,42,
237,15,360,4,0,
237,15,364,1,33,
237,15,370,1,1,2
237,15,370,1,55,
237,15,444,4,0,
237,15,469,1,46,1
237,15,490,4,0,
237,15,496,4,0,
237,15,501,1,46,2
237,15,514,4,0,
237,15,523,4,0,
237,15,590,4,0,
237,16,27,1,1,4
237,16,67,3,0,
237,16,68,1,28,1
237,16,70,4,0,
237,16,89,4,0,
237,16,91,4,0,
237,16,92,4,0,
237,16,97,1,37,1
237,16,98,1,15,1
237,16,104,4,0,
237,16,116,1,6,1
237,16,156,4,0,
237,16,157,4,0,
237,16,164,4,0,
237,16,167,1,19,1
237,16,168,4,0,
237,16,173,3,0,
237,16,182,4,0,
237,16,197,1,50,1
237,16,201,4,0,
237,16,207,4,0,
237,16,213,4,0,
237,16,214,4,0,
237,16,216,4,0,
237,16,218,4,0,
237,16,228,1,10,1
237,16,229,1,24,1
237,16,237,4,0,
237,16,240,4,0,
237,16,241,4,0,
237,16,249,4,0,
237,16,263,4,0,
237,16,270,3,0,
237,16,272,3,0,
237,16,279,1,1,3
237,16,280,4,0,
237,16,283,1,1,1
237,16,283,1,60,1
237,16,283,3,0,
237,16,290,4,0,
237,16,332,4,0,
237,16,339,4,0,
237,16,343,3,0,
237,16,360,1,42,1
237,16,360,4,0,
237,16,364,1,33,1
237,16,370,1,1,2
237,16,370,1,55,1
237,16,444,4,0,
237,16,469,1,46,1
237,16,490,4,0,
237,16,496,4,0,
237,16,501,1,46,2
237,16,514,4,0,
237,16,523,4,0,
237,16,590,4,0,
237,17,27,1,0,
237,17,27,1,1,1
237,17,68,1,28,
237,17,89,4,0,
237,17,92,4,0,
237,17,97,1,37,
237,17,98,1,1,8
237,17,98,1,15,
237,17,104,4,0,
237,17,116,1,1,6
237,17,116,1,6,
237,17,156,4,0,
237,17,157,4,0,
237,17,164,4,0,
237,17,167,1,33,
237,17,168,4,0,
237,17,182,4,0,
237,17,197,1,1,4
237,17,197,1,50,
237,17,201,4,0,
237,17,207,4,0,
237,17,213,4,0,
237,17,214,4,0,
237,17,216,4,0,
237,17,218,4,0,
237,17,228,1,1,7
237,17,228,1,10,
237,17,229,1,19,
237,17,237,4,0,
237,17,240,4,0,
237,17,241,4,0,
237,17,263,4,0,
237,17,279,1,1,5
237,17,280,4,0,
237,17,283,1,1,2
237,17,283,1,60,
237,17,332,4,0,
237,17,339,4,0,
237,17,360,1,42,
237,17,360,4,0,
237,17,364,1,24,
237,17,370,1,1,3
237,17,370,1,55,
237,17,444,4,0,
237,17,469,1,46,1
237,17,490,4,0,
237,17,496,4,0,
237,17,501,1,46,2
237,17,523,4,0,
237,17,526,4,0,
237,17,590,4,0,
237,18,27,1,0,
237,18,27,1,1,1
237,18,68,1,28,
237,18,89,4,0,
237,18,92,4,0,
237,18,97,1,37,
237,18,98,1,1,8
237,18,98,1,15,
237,18,104,4,0,
237,18,116,1,1,6
237,18,116,1,6,
237,18,156,4,0,
237,18,157,4,0,
237,18,164,4,0,
237,18,167,1,33,
237,18,168,4,0,
237,18,182,4,0,
237,18,197,1,1,4
237,18,197,1,50,
237,18,201,4,0,
237,18,207,4,0,
237,18,213,4,0,
237,18,214,4,0,
237,18,216,4,0,
237,18,218,4,0,
237,18,228,1,1,7
237,18,228,1,10,
237,18,229,1,19,
237,18,237,4,0,
237,18,240,4,0,
237,18,241,4,0,
237,18,263,4,0,
237,18,279,1,1,5
237,18,280,4,0,
237,18,283,1,1,2
237,18,283,1,60,
237,18,332,4,0,
237,18,339,4,0,
237,18,360,1,42,
237,18,360,4,0,
237,18,364,1,24,
237,18,370,1,1,3
237,18,370,1,55,
237,18,444,4,0,
237,18,469,1,46,1
237,18,490,4,0,
237,18,496,4,0,
237,18,501,1,46,2
237,18,523,4,0,
237,18,526,4,0,
237,18,590,4,0,
238,3,1,1,1,1
238,3,8,4,0,
238,3,47,1,25,
238,3,59,1,49,
238,3,59,4,0,
238,3,92,4,0,
238,3,93,1,21,
238,3,94,1,37,
238,3,94,4,0,
238,3,96,2,0,
238,3,104,4,0,
238,3,122,1,1,2
238,3,138,4,0,
238,3,142,2,0,
238,3,156,4,0,
238,3,168,4,0,
238,3,171,4,0,
238,3,173,4,0,
238,3,174,4,0,
238,3,181,1,13,
238,3,182,4,0,
238,3,186,1,9,
238,3,189,4,0,
238,3,195,1,45,
238,3,196,4,0,
238,3,203,4,0,
238,3,207,4,0,
238,3,212,1,33,
238,3,213,4,0,
238,3,214,4,0,
238,3,216,4,0,
238,3,218,4,0,
238,3,223,4,0,
238,3,230,4,0,
238,3,237,4,0,
238,3,240,4,0,
238,3,244,4,0,
238,3,247,4,0,
238,4,1,1,1,1
238,4,8,4,0,
238,4,47,1,25,
238,4,58,3,0,
238,4,59,1,49,
238,4,59,4,0,
238,4,92,4,0,
238,4,93,1,21,
238,4,94,1,37,
238,4,94,4,0,
238,4,96,2,0,
238,4,104,4,0,
238,4,122,1,1,2
238,4,138,4,0,
238,4,156,4,0,
238,4,168,4,0,
238,4,171,4,0,
238,4,173,4,0,
238,4,174,4,0,
238,4,181,1,13,
238,4,182,4,0,
238,4,186,1,9,
238,4,189,4,0,
238,4,195,1,45,
238,4,196,4,0,
238,4,203,4,0,
238,4,207,4,0,
238,4,212,1,33,
238,4,213,4,0,
238,4,214,4,0,
238,4,216,4,0,
238,4,218,4,0,
238,4,223,4,0,
238,4,230,4,0,
238,4,237,4,0,
238,4,240,4,0,
238,4,244,4,0,
238,4,247,4,0,
238,5,1,1,1,1
238,5,8,2,0,
238,5,47,1,25,
238,5,58,4,0,
238,5,59,1,57,
238,5,59,4,0,
238,5,92,4,0,
238,5,93,1,21,
238,5,94,1,45,
238,5,94,4,0,
238,5,96,2,0,
238,5,104,4,0,
238,5,113,4,0,
238,5,115,4,0,
238,5,122,1,1,2
238,5,148,4,0,
238,5,156,4,0,
238,5,168,4,0,
238,5,181,1,13,
238,5,182,4,0,
238,5,186,1,9,
238,5,195,1,49,
238,5,212,1,33,
238,5,213,4,0,
238,5,216,4,0,
238,5,218,4,0,
238,5,237,4,0,
238,5,240,4,0,
238,5,244,2,0,
238,5,247,4,0,
238,5,252,2,0,
238,5,258,4,0,
238,5,263,4,0,
238,5,273,2,0,
238,5,285,4,0,
238,5,290,4,0,
238,5,313,1,37,
238,5,347,4,0,
238,5,352,4,0,
238,6,1,1,1,1
238,6,5,3,0,
238,6,8,2,0,
238,6,8,3,0,
238,6,25,3,0,
238,6,34,3,0,
238,6,38,3,0,
238,6,47,1,25,
238,6,58,4,0,
238,6,59,1,57,
238,6,59,4,0,
238,6,68,3,0,
238,6,69,3,0,
238,6,92,4,0,
238,6,93,1,21,
238,6,94,1,45,
238,6,94,4,0,
238,6,96,2,0,
238,6,102,3,0,
238,6,104,4,0,
238,6,113,4,0,
238,6,115,4,0,
238,6,118,3,0,
238,6,122,1,1,2
238,6,138,3,0,
238,6,148,4,0,
238,6,156,4,0,
238,6,164,3,0,
238,6,168,4,0,
238,6,173,3,0,
238,6,181,1,13,
238,6,182,4,0,
238,6,186,1,9,
238,6,189,3,0,
238,6,195,1,49,
238,6,196,3,0,
238,6,203,3,0,
238,6,207,3,0,
238,6,212,1,33,
238,6,213,4,0,
238,6,214,3,0,
238,6,216,4,0,
238,6,218,4,0,
238,6,223,3,0,
238,6,237,4,0,
238,6,240,4,0,
238,6,244,2,0,
238,6,244,3,0,
238,6,247,4,0,
238,6,252,2,0,
238,6,258,4,0,
238,6,263,4,0,
238,6,273,2,0,
238,6,285,4,0,
238,6,290,4,0,
238,6,313,1,37,
238,6,347,4,0,
238,6,352,4,0,
238,7,1,1,1,1
238,7,5,3,0,
238,7,8,2,0,
238,7,25,3,0,
238,7,34,3,0,
238,7,38,3,0,
238,7,47,1,25,
238,7,58,4,0,
238,7,59,1,57,
238,7,59,4,0,
238,7,68,3,0,
238,7,69,3,0,
238,7,92,4,0,
238,7,93,1,21,
238,7,94,1,45,
238,7,94,4,0,
238,7,96,2,0,
238,7,102,3,0,
238,7,104,4,0,
238,7,113,4,0,
238,7,115,4,0,
238,7,118,3,0,
238,7,122,1,1,2
238,7,138,3,0,
238,7,148,4,0,
238,7,156,4,0,
238,7,164,3,0,
238,7,168,4,0,
238,7,181,1,13,
238,7,182,4,0,
238,7,186,1,9,
238,7,195,1,49,
238,7,212,1,33,
238,7,213,4,0,
238,7,216,4,0,
238,7,218,4,0,
238,7,237,4,0,
238,7,240,4,0,
238,7,244,2,0,
238,7,247,4,0,
238,7,252,2,0,
238,7,258,4,0,
238,7,263,4,0,
238,7,273,2,0,
238,7,285,4,0,
238,7,290,4,0,
238,7,313,1,37,
238,7,347,4,0,
238,7,352,4,0,
238,8,1,1,1,
238,8,8,2,0,
238,8,47,1,18,
238,8,58,4,0,
238,8,59,1,45,
238,8,59,4,0,
238,8,92,4,0,
238,8,93,1,15,
238,8,94,1,35,
238,8,94,4,0,
238,8,96,2,0,
238,8,104,4,0,
238,8,113,4,0,
238,8,115,4,0,
238,8,122,1,5,
238,8,138,4,0,
238,8,148,4,0,
238,8,156,4,0,
238,8,164,4,0,
238,8,168,4,0,
238,8,181,1,11,
238,8,182,4,0,
238,8,186,1,8,
238,8,195,1,41,
238,8,203,4,0,
238,8,207,4,0,
238,8,212,1,21,
238,8,213,4,0,
238,8,214,4,0,
238,8,216,4,0,
238,8,218,4,0,
238,8,237,4,0,
238,8,240,4,0,
238,8,244,2,0,
238,8,244,4,0,
238,8,247,4,0,
238,8,252,2,0,
238,8,258,4,0,
238,8,263,4,0,
238,8,273,2,0,
238,8,278,4,0,
238,8,285,4,0,
238,8,290,4,0,
238,8,313,1,25,
238,8,347,4,0,
238,8,352,4,0,
238,8,357,2,0,
238,8,363,4,0,
238,8,371,4,0,
238,8,374,4,0,
238,8,381,1,28,
238,8,383,1,38,
238,8,419,1,31,
238,8,419,4,0,
238,8,433,4,0,
238,8,445,4,0,
238,8,447,4,0,
238,9,1,1,1,
238,9,8,2,0,
238,9,8,3,0,
238,9,47,1,18,
238,9,58,4,0,
238,9,59,1,45,
238,9,59,4,0,
238,9,92,4,0,
238,9,93,1,15,
238,9,94,1,35,
238,9,94,4,0,
238,9,96,2,0,
238,9,104,4,0,
238,9,113,4,0,
238,9,115,4,0,
238,9,122,1,5,
238,9,138,4,0,
238,9,148,4,0,
238,9,156,4,0,
238,9,164,4,0,
238,9,168,4,0,
238,9,173,3,0,
238,9,181,1,11,
238,9,182,4,0,
238,9,186,1,8,
238,9,189,3,0,
238,9,195,1,41,
238,9,196,3,0,
238,9,203,4,0,
238,9,207,4,0,
238,9,212,1,21,
238,9,213,4,0,
238,9,214,4,0,
238,9,216,4,0,
238,9,218,4,0,
238,9,237,4,0,
238,9,240,4,0,
238,9,244,2,0,
238,9,244,4,0,
238,9,247,4,0,
238,9,252,2,0,
238,9,253,3,0,
238,9,258,4,0,
238,9,263,4,0,
238,9,270,3,0,
238,9,271,3,0,
238,9,273,2,0,
238,9,278,4,0,
238,9,285,4,0,
238,9,290,4,0,
238,9,313,1,25,
238,9,324,3,0,
238,9,347,4,0,
238,9,352,4,0,
238,9,357,2,0,
238,9,363,4,0,
238,9,371,4,0,
238,9,374,4,0,
238,9,381,1,28,
238,9,383,1,38,
238,9,419,1,31,
238,9,419,4,0,
238,9,428,3,0,
238,9,433,4,0,
238,9,445,4,0,
238,9,447,4,0,
238,10,1,1,1,
238,10,8,2,0,
238,10,8,3,0,
238,10,47,1,18,
238,10,58,4,0,
238,10,59,1,45,
238,10,59,4,0,
238,10,92,4,0,
238,10,93,1,15,
238,10,94,1,35,
238,10,94,4,0,
238,10,96,2,0,
238,10,104,4,0,
238,10,113,4,0,
238,10,115,4,0,
238,10,122,1,5,
238,10,138,4,0,
238,10,148,4,0,
238,10,156,4,0,
238,10,164,4,0,
238,10,168,4,0,
238,10,173,3,0,
238,10,181,1,11,
238,10,182,4,0,
238,10,186,1,8,
238,10,189,3,0,
238,10,195,1,41,
238,10,196,3,0,
238,10,203,4,0,
238,10,207,4,0,
238,10,212,1,21,
238,10,213,4,0,
238,10,214,4,0,
238,10,215,3,0,
238,10,216,4,0,
238,10,218,4,0,
238,10,237,4,0,
238,10,240,4,0,
238,10,244,2,0,
238,10,244,4,0,
238,10,247,4,0,
238,10,252,2,0,
238,10,253,3,0,
238,10,258,4,0,
238,10,263,4,0,
238,10,270,3,0,
238,10,271,3,0,
238,10,272,3,0,
238,10,273,2,0,
238,10,277,3,0,
238,10,278,4,0,
238,10,285,4,0,
238,10,290,4,0,
238,10,313,1,25,
238,10,324,3,0,
238,10,347,4,0,
238,10,352,4,0,
238,10,357,2,0,
238,10,363,4,0,
238,10,371,4,0,
238,10,374,4,0,
238,10,381,1,28,
238,10,383,1,38,
238,10,417,2,0,
238,10,419,1,31,
238,10,419,4,0,
238,10,428,3,0,
238,10,433,4,0,
238,10,445,4,0,
238,10,447,4,0,
238,11,1,1,1,
238,11,8,2,0,
238,11,47,1,18,
238,11,58,4,0,
238,11,59,1,48,
238,11,59,4,0,
238,11,92,4,0,
238,11,93,1,15,
238,11,94,1,38,
238,11,94,4,0,
238,11,96,2,0,
238,11,104,4,0,
238,11,113,4,0,
238,11,115,4,0,
238,11,122,1,5,
238,11,138,4,0,
238,11,148,4,0,
238,11,156,4,0,
238,11,164,4,0,
238,11,168,4,0,
238,11,181,1,11,
238,11,182,4,0,
238,11,186,1,8,
238,11,195,1,45,
238,11,207,4,0,
238,11,212,1,25,
238,11,213,4,0,
238,11,216,4,0,
238,11,218,4,0,
238,11,237,4,0,
238,11,240,4,0,
238,11,244,4,0,
238,11,247,4,0,
238,11,252,2,0,
238,11,258,4,0,
238,11,263,4,0,
238,11,273,2,0,
238,11,313,1,28,
238,11,347,4,0,
238,11,357,2,0,
238,11,358,2,0,
238,11,371,4,0,
238,11,374,4,0,
238,11,381,1,31,
238,11,383,1,41,
238,11,417,2,0,
238,11,419,1,35,
238,11,433,4,0,
238,11,445,2,0,
238,11,447,4,0,
238,11,473,4,0,
238,11,496,4,0,
238,11,497,4,0,
238,11,524,4,0,
238,11,531,1,21,
238,12,1,1,1,1
238,12,47,1,25,
238,12,58,4,0,
238,12,59,1,57,
238,12,59,4,0,
238,12,92,4,0,
238,12,93,1,21,
238,12,94,1,45,
238,12,94,4,0,
238,12,104,4,0,
238,12,113,4,0,
238,12,115,4,0,
238,12,122,1,1,2
238,12,148,4,0,
238,12,156,4,0,
238,12,168,4,0,
238,12,181,1,13,
238,12,182,4,0,
238,12,186,1,9,
238,12,195,1,49,
238,12,212,1,33,
238,12,213,4,0,
238,12,216,4,0,
238,12,218,4,0,
238,12,237,4,0,
238,12,240,4,0,
238,12,247,4,0,
238,12,258,4,0,
238,12,263,4,0,
238,12,285,4,0,
238,12,290,4,0,
238,12,313,1,37,
238,12,347,4,0,
238,12,352,4,0,
238,13,1,1,1,1
238,13,34,3,0,
238,13,38,3,0,
238,13,47,1,25,
238,13,58,4,0,
238,13,59,1,57,
238,13,59,4,0,
238,13,69,3,0,
238,13,92,4,0,
238,13,93,1,21,
238,13,94,1,45,
238,13,94,4,0,
238,13,102,3,0,
238,13,104,4,0,
238,13,113,4,0,
238,13,115,4,0,
238,13,122,1,1,2
238,13,138,3,0,
238,13,148,4,0,
238,13,156,4,0,
238,13,164,3,0,
238,13,168,4,0,
238,13,171,3,0,
238,13,181,1,13,
238,13,182,4,0,
238,13,186,1,9,
238,13,195,1,49,
238,13,196,3,0,
238,13,207,3,0,
238,13,212,1,33,
238,13,213,4,0,
238,13,216,4,0,
238,13,218,4,0,
238,13,237,4,0,
238,13,240,4,0,
238,13,247,4,0,
238,13,258,4,0,
238,13,263,4,0,
238,13,285,4,0,
238,13,290,4,0,
238,13,313,1,37,
238,13,347,4,0,
238,13,352,4,0,
238,14,1,1,1,
238,14,8,2,0,
238,14,8,3,0,
238,14,47,1,18,
238,14,58,4,0,
238,14,59,1,48,
238,14,59,4,0,
238,14,92,4,0,
238,14,93,1,15,
238,14,94,1,38,
238,14,94,4,0,
238,14,96,2,0,
238,14,104,4,0,
238,14,113,4,0,
238,14,115,4,0,
238,14,122,1,5,
238,14,138,4,0,
238,14,148,4,0,
238,14,156,4,0,
238,14,164,4,0,
238,14,168,4,0,
238,14,173,3,0,
238,14,181,1,11,
238,14,182,4,0,
238,14,186,1,8,
238,14,195,1,45,
238,14,196,3,0,
238,14,207,4,0,
238,14,212,1,25,
238,14,213,4,0,
238,14,214,3,0,
238,14,215,3,0,
238,14,216,4,0,
238,14,218,4,0,
238,14,237,4,0,
238,14,240,4,0,
238,14,244,4,0,
238,14,247,4,0,
238,14,252,2,0,
238,14,253,3,0,
238,14,258,4,0,
238,14,263,4,0,
238,14,270,3,0,
238,14,271,3,0,
238,14,272,3,0,
238,14,273,2,0,
238,14,277,3,0,
238,14,278,3,0,
238,14,285,3,0,
238,14,313,1,28,
238,14,324,3,0,
238,14,343,3,0,
238,14,347,4,0,
238,14,357,2,0,
238,14,358,2,0,
238,14,371,4,0,
238,14,374,4,0,
238,14,381,1,31,
238,14,383,1,41,
238,14,417,2,0,
238,14,419,1,35,
238,14,428,3,0,
238,14,433,4,0,
238,14,445,2,0,
238,14,447,4,0,
238,14,473,4,0,
238,14,478,3,0,
238,14,496,4,0,
238,14,497,4,0,
238,14,524,4,0,
238,14,531,1,21,
238,15,1,1,1,
238,15,8,2,0,
238,15,47,1,18,
238,15,58,4,0,
238,15,59,1,48,
238,15,59,4,0,
238,15,92,4,0,
238,15,93,1,15,
238,15,94,1,38,
238,15,94,4,0,
238,15,96,2,0,
238,15,104,4,0,
238,15,113,4,0,
238,15,115,4,0,
238,15,122,1,5,
238,15,138,4,0,
238,15,148,4,0,
238,15,156,4,0,
238,15,164,4,0,
238,15,168,4,0,
238,15,181,1,11,
238,15,182,4,0,
238,15,186,1,8,
238,15,195,1,45,
238,15,207,4,0,
238,15,212,1,25,
238,15,213,4,0,
238,15,214,4,0,
238,15,216,4,0,
238,15,218,4,0,
238,15,237,4,0,
238,15,240,4,0,
238,15,244,4,0,
238,15,247,4,0,
238,15,252,2,0,
238,15,258,4,0,
238,15,263,4,0,
238,15,273,2,0,
238,15,313,1,28,
238,15,347,4,0,
238,15,357,2,0,
238,15,358,2,0,
238,15,371,4,0,
238,15,374,4,0,
238,15,381,1,31,
238,15,383,1,41,
238,15,417,2,0,
238,15,419,1,35,
238,15,433,4,0,
238,15,445,2,0,
238,15,447,4,0,
238,15,473,4,0,
238,15,496,4,0,
238,15,497,4,0,
238,15,524,4,0,
238,15,531,1,21,
238,15,590,4,0,
238,16,1,1,1,1
238,16,8,2,0,
238,16,8,3,0,
238,16,47,1,18,1
238,16,58,4,0,
238,16,59,1,48,1
238,16,59,4,0,
238,16,92,4,0,
238,16,93,1,15,1
238,16,94,1,38,1
238,16,94,4,0,
238,16,96,2,0,
238,16,104,4,0,
238,16,113,4,0,
238,16,115,4,0,
238,16,122,1,5,1
238,16,138,4,0,
238,16,148,4,0,
238,16,156,4,0,
238,16,164,4,0,
238,16,168,4,0,
238,16,173,3,0,
238,16,181,1,11,1
238,16,182,4,0,
238,16,186,1,8,1
238,16,195,1,45,1
238,16,196,3,0,
238,16,207,4,0,
238,16,212,1,25,1
238,16,213,4,0,
238,16,214,4,0,
238,16,215,3,0,
238,16,216,4,0,
238,16,218,4,0,
238,16,237,4,0,
238,16,240,4,0,
238,16,244,4,0,
238,16,247,4,0,
238,16,252,2,0,
238,16,253,3,0,
238,16,258,4,0,
238,16,263,4,0,
238,16,270,3,0,
238,16,271,3,0,
238,16,272,3,0,
238,16,273,2,0,
238,16,277,3,0,
238,16,278,3,0,
238,16,285,3,0,
238,16,290,4,0,
238,16,313,1,28,1
238,16,324,3,0,
238,16,343,3,0,
238,16,347,4,0,
238,16,352,3,0,
238,16,357,2,0,
238,16,358,2,0,
238,16,371,4,0,
238,16,374,4,0,
238,16,381,1,31,1
238,16,383,1,41,1
238,16,417,2,0,
238,16,419,1,35,1
238,16,428,3,0,
238,16,433,4,0,
238,16,445,2,0,
238,16,447,4,0,
238,16,473,4,0,
238,16,478,3,0,
238,16,496,4,0,
238,16,497,4,0,
238,16,524,4,0,
238,16,531,1,21,1
238,16,590,4,0,
238,17,1,1,1,
238,17,8,2,0,
238,17,47,1,18,
238,17,58,4,0,
238,17,59,1,48,
238,17,59,4,0,
238,17,92,4,0,
238,17,93,1,15,
238,17,94,1,38,
238,17,94,4,0,
238,17,96,2,0,
238,17,104,4,0,
238,17,113,4,0,
238,17,115,4,0,
238,17,122,1,5,
238,17,138,4,0,
238,17,156,4,0,
238,17,164,4,0,
238,17,168,4,0,
238,17,181,1,11,
238,17,182,4,0,
238,17,186,1,8,
238,17,195,1,45,
238,17,207,4,0,
238,17,212,1,25,
238,17,213,4,0,
238,17,214,4,0,
238,17,216,4,0,
238,17,218,4,0,
238,17,237,4,0,
238,17,240,4,0,
238,17,244,4,0,
238,17,247,4,0,
238,17,252,2,0,
238,17,258,4,0,
238,17,263,4,0,
238,17,273,2,0,
238,17,313,1,28,
238,17,347,4,0,
238,17,357,2,0,
238,17,358,2,0,
238,17,371,4,0,
238,17,374,4,0,
238,17,381,1,31,
238,17,383,1,41,
238,17,417,2,0,
238,17,419,1,35,
238,17,433,4,0,
238,17,445,2,0,
238,17,447,4,0,
238,17,473,4,0,
238,17,496,4,0,
238,17,497,4,0,
238,17,524,4,0,
238,17,531,1,21,
238,17,590,4,0,
238,17,694,4,0,
238,18,1,1,1,
238,18,8,2,0,
238,18,47,1,18,
238,18,58,4,0,
238,18,59,1,48,
238,18,59,4,0,
238,18,92,4,0,
238,18,93,1,15,
238,18,94,1,38,
238,18,94,4,0,
238,18,96,2,0,
238,18,104,4,0,
238,18,113,4,0,
238,18,115,4,0,
238,18,122,1,5,
238,18,138,4,0,
238,18,156,4,0,
238,18,164,4,0,
238,18,168,4,0,
238,18,181,1,11,
238,18,182,4,0,
238,18,186,1,8,
238,18,195,1,45,
238,18,207,4,0,
238,18,212,1,25,
238,18,213,4,0,
238,18,214,4,0,
238,18,216,4,0,
238,18,218,4,0,
238,18,237,4,0,
238,18,240,4,0,
238,18,244,4,0,
238,18,247,4,0,
238,18,252,2,0,
238,18,258,4,0,
238,18,263,4,0,
238,18,273,2,0,
238,18,313,1,28,
238,18,347,4,0,
238,18,357,2,0,
238,18,358,2,0,
238,18,371,4,0,
238,18,374,4,0,
238,18,381,1,31,
238,18,383,1,41,
238,18,417,2,0,
238,18,419,1,35,
238,18,433,4,0,
238,18,445,2,0,
238,18,447,4,0,
238,18,473,4,0,
238,18,496,4,0,
238,18,497,4,0,
238,18,524,4,0,
238,18,531,1,21,
238,18,590,4,0,
238,18,694,4,0,
239,3,2,2,0,
239,3,7,4,0,
239,3,8,4,0,
239,3,9,1,9,
239,3,9,4,0,
239,3,27,2,0,
239,3,29,4,0,
239,3,43,1,1,2
239,3,85,1,41,
239,3,87,1,49,
239,3,87,4,0,
239,3,92,4,0,
239,3,94,4,0,
239,3,96,2,0,
239,3,98,1,1,1
239,3,103,1,33,
239,3,104,4,0,
239,3,112,2,0,
239,3,113,1,17,
239,3,129,1,25,
239,3,129,4,0,
239,3,148,4,0,
239,3,156,4,0,
239,3,168,4,0,
239,3,173,4,0,
239,3,174,4,0,
239,3,182,4,0,
239,3,189,4,0,
239,3,192,4,0,
239,3,197,4,0,
239,3,203,4,0,
239,3,207,4,0,
239,3,213,4,0,
239,3,214,4,0,
239,3,216,4,0,
239,3,218,4,0,
239,3,223,4,0,
239,3,237,4,0,
239,3,240,4,0,
239,4,2,2,0,
239,4,7,4,0,
239,4,8,4,0,
239,4,9,1,9,
239,4,9,4,0,
239,4,27,2,0,
239,4,29,4,0,
239,4,43,1,1,2
239,4,85,1,41,
239,4,85,3,0,
239,4,87,1,49,
239,4,87,4,0,
239,4,92,4,0,
239,4,94,4,0,
239,4,96,2,0,
239,4,98,1,1,1
239,4,103,1,33,
239,4,104,4,0,
239,4,112,2,0,
239,4,113,1,17,
239,4,129,1,25,
239,4,129,4,0,
239,4,148,4,0,
239,4,156,4,0,
239,4,168,4,0,
239,4,173,4,0,
239,4,174,4,0,
239,4,182,4,0,
239,4,189,4,0,
239,4,192,4,0,
239,4,197,4,0,
239,4,203,4,0,
239,4,207,4,0,
239,4,213,4,0,
239,4,214,4,0,
239,4,216,4,0,
239,4,218,4,0,
239,4,223,4,0,
239,4,237,4,0,
239,4,238,2,0,
239,4,240,4,0,
239,5,2,2,0,
239,5,7,2,0,
239,5,8,2,0,
239,5,9,1,9,
239,5,27,2,0,
239,5,43,1,1,2
239,5,85,1,41,
239,5,85,4,0,
239,5,87,1,49,
239,5,87,4,0,
239,5,92,4,0,
239,5,94,4,0,
239,5,96,2,0,
239,5,98,1,1,1
239,5,103,1,33,
239,5,104,4,0,
239,5,112,2,0,
239,5,113,1,17,
239,5,113,4,0,
239,5,129,1,25,
239,5,148,4,0,
239,5,156,4,0,
239,5,168,4,0,
239,5,182,4,0,
239,5,213,4,0,
239,5,216,4,0,
239,5,218,4,0,
239,5,237,4,0,
239,5,238,2,0,
239,5,240,4,0,
239,5,249,4,0,
239,5,263,4,0,
239,5,264,4,0,
239,5,280,4,0,
239,5,290,4,0,
239,5,351,4,0,
239,6,2,2,0,
239,6,5,3,0,
239,6,7,2,0,
239,6,7,3,0,
239,6,8,2,0,
239,6,8,3,0,
239,6,9,1,9,
239,6,9,3,0,
239,6,25,3,0,
239,6,27,2,0,
239,6,34,3,0,
239,6,38,3,0,
239,6,43,1,1,2
239,6,68,3,0,
239,6,69,3,0,
239,6,85,1,41,
239,6,85,4,0,
239,6,86,3,0,
239,6,87,1,49,
239,6,87,4,0,
239,6,92,4,0,
239,6,94,4,0,
239,6,96,2,0,
239,6,98,1,1,1
239,6,102,3,0,
239,6,103,1,33,
239,6,104,4,0,
239,6,112,2,0,
239,6,113,1,17,
239,6,113,4,0,
239,6,129,1,25,
239,6,129,3,0,
239,6,148,4,0,
239,6,156,4,0,
239,6,164,3,0,
239,6,168,4,0,
239,6,173,3,0,
239,6,182,4,0,
239,6,189,3,0,
239,6,203,3,0,
239,6,207,3,0,
239,6,213,4,0,
239,6,214,3,0,
239,6,216,4,0,
239,6,218,4,0,
239,6,223,3,0,
239,6,237,4,0,
239,6,238,2,0,
239,6,240,4,0,
239,6,249,4,0,
239,6,263,4,0,
239,6,264,4,0,
239,6,280,4,0,
239,6,290,4,0,
239,6,351,4,0,
239,7,2,2,0,
239,7,5,3,0,
239,7,7,2,0,
239,7,8,2,0,
239,7,9,1,9,
239,7,25,3,0,
239,7,27,2,0,
239,7,34,3,0,
239,7,38,3,0,
239,7,43,1,1,2
239,7,68,3,0,
239,7,69,3,0,
239,7,85,1,41,
239,7,85,4,0,
239,7,86,3,0,
239,7,87,1,49,
239,7,87,4,0,
239,7,92,4,0,
239,7,94,4,0,
239,7,96,2,0,
239,7,98,1,1,1
239,7,102,3,0,
239,7,103,1,33,
239,7,104,4,0,
239,7,112,2,0,
239,7,113,1,17,
239,7,113,4,0,
239,7,129,1,25,
239,7,148,4,0,
239,7,156,4,0,
239,7,164,3,0,
239,7,168,4,0,
239,7,182,4,0,
239,7,213,4,0,
239,7,216,4,0,
239,7,218,4,0,
239,7,237,4,0,
239,7,238,2,0,
239,7,240,4,0,
239,7,249,4,0,
239,7,263,4,0,
239,7,264,4,0,
239,7,280,4,0,
239,7,290,4,0,
239,7,351,4,0,
239,8,2,2,0,
239,8,7,2,0,
239,8,8,2,0,
239,8,9,1,28,
239,8,27,2,0,
239,8,43,1,1,2
239,8,67,1,10,
239,8,84,1,7,
239,8,85,1,37,
239,8,85,4,0,
239,8,86,4,0,
239,8,87,1,46,
239,8,87,4,0,
239,8,92,4,0,
239,8,94,4,0,
239,8,96,2,0,
239,8,98,1,1,1
239,8,103,1,43,
239,8,104,4,0,
239,8,112,2,0,
239,8,113,1,25,
239,8,113,4,0,
239,8,129,1,16,
239,8,148,4,0,
239,8,156,4,0,
239,8,164,4,0,
239,8,168,4,0,
239,8,182,4,0,
239,8,203,4,0,
239,8,207,4,0,
239,8,213,4,0,
239,8,214,4,0,
239,8,216,4,0,
239,8,218,4,0,
239,8,223,2,0,
239,8,237,4,0,
239,8,238,2,0,
239,8,240,4,0,
239,8,249,4,0,
239,8,263,4,0,
239,8,264,4,0,
239,8,280,4,0,
239,8,290,4,0,
239,8,351,1,19,
239,8,351,4,0,
239,8,363,4,0,
239,8,374,4,0,
239,8,435,1,34,
239,8,445,4,0,
239,8,451,4,0,
239,9,2,2,0,
239,9,7,2,0,
239,9,7,3,0,
239,9,8,2,0,
239,9,8,3,0,
239,9,9,1,28,
239,9,9,3,0,
239,9,27,2,0,
239,9,43,1,1,2
239,9,67,1,10,
239,9,84,1,7,
239,9,85,1,37,
239,9,85,4,0,
239,9,86,4,0,
239,9,87,1,46,
239,9,87,4,0,
239,9,92,4,0,
239,9,94,4,0,
239,9,96,2,0,
239,9,98,1,1,1
239,9,103,1,43,
239,9,104,4,0,
239,9,112,2,0,
239,9,113,1,25,
239,9,113,4,0,
239,9,129,1,16,
239,9,129,3,0,
239,9,148,4,0,
239,9,156,4,0,
239,9,164,4,0,
239,9,168,4,0,
239,9,173,3,0,
239,9,182,4,0,
239,9,189,3,0,
239,9,203,4,0,
239,9,207,4,0,
239,9,213,4,0,
239,9,214,4,0,
239,9,216,4,0,
239,9,218,4,0,
239,9,223,2,0,
239,9,237,4,0,
239,9,238,2,0,
239,9,240,4,0,
239,9,249,4,0,
239,9,253,3,0,
239,9,263,4,0,
239,9,264,4,0,
239,9,270,3,0,
239,9,280,4,0,
239,9,290,4,0,
239,9,324,3,0,
239,9,351,1,19,
239,9,351,4,0,
239,9,363,4,0,
239,9,374,4,0,
239,9,393,3,0,
239,9,435,1,34,
239,9,445,4,0,
239,9,451,4,0,
239,10,2,2,0,
239,10,7,2,0,
239,10,7,3,0,
239,10,8,2,0,
239,10,8,3,0,
239,10,9,1,28,
239,10,9,3,0,
239,10,27,2,0,
239,10,29,3,0,
239,10,43,1,1,2
239,10,67,1,10,
239,10,67,3,0,
239,10,84,1,7,
239,10,85,1,37,
239,10,85,4,0,
239,10,86,4,0,
239,10,87,1,46,
239,10,87,4,0,
239,10,92,4,0,
239,10,94,4,0,
239,10,96,2,0,
239,10,98,1,1,1
239,10,103,1,43,
239,10,104,4,0,
239,10,112,2,0,
239,10,113,1,25,
239,10,113,4,0,
239,10,129,1,16,
239,10,129,3,0,
239,10,148,4,0,
239,10,156,4,0,
239,10,164,4,0,
239,10,168,4,0,
239,10,173,3,0,
239,10,182,4,0,
239,10,189,3,0,
239,10,203,4,0,
239,10,207,4,0,
239,10,213,4,0,
239,10,214,4,0,
239,10,216,4,0,
239,10,218,4,0,
239,10,223,2,0,
239,10,237,4,0,
239,10,238,2,0,
239,10,240,4,0,
239,10,249,4,0,
239,10,253,3,0,
239,10,263,4,0,
239,10,264,4,0,
239,10,270,3,0,
239,10,280,4,0,
239,10,290,4,0,
239,10,324,3,0,
239,10,351,1,19,
239,10,351,4,0,
239,10,363,4,0,
239,10,364,2,0,
239,10,374,4,0,
239,10,393,3,0,
239,10,435,1,34,
239,10,445,4,0,
239,10,451,4,0,
239,11,2,2,0,
239,11,7,2,0,
239,11,8,2,0,
239,11,9,1,36,
239,11,27,2,0,
239,11,43,1,1,2
239,11,67,1,11,
239,11,84,1,6,
239,11,85,1,46,
239,11,85,4,0,
239,11,86,4,0,
239,11,87,1,56,
239,11,87,4,0,
239,11,92,4,0,
239,11,94,4,0,
239,11,96,2,0,
239,11,98,1,1,1
239,11,103,1,51,
239,11,104,4,0,
239,11,112,2,0,
239,11,113,1,26,
239,11,113,4,0,
239,11,129,1,16,
239,11,148,4,0,
239,11,156,4,0,
239,11,164,4,0,
239,11,168,4,0,
239,11,182,4,0,
239,11,207,4,0,
239,11,213,4,0,
239,11,216,4,0,
239,11,218,4,0,
239,11,223,2,0,
239,11,237,4,0,
239,11,238,2,0,
239,11,240,4,0,
239,11,249,4,0,
239,11,263,4,0,
239,11,264,2,0,
239,11,280,4,0,
239,11,351,1,21,
239,11,359,2,0,
239,11,364,2,0,
239,11,374,4,0,
239,11,435,1,41,
239,11,451,4,0,
239,11,486,1,31,
239,11,496,4,0,
239,11,521,4,0,
239,11,528,4,0,
239,12,9,1,9,
239,12,43,1,1,2
239,12,85,1,41,
239,12,85,4,0,
239,12,87,1,49,
239,12,87,4,0,
239,12,92,4,0,
239,12,94,4,0,
239,12,98,1,1,1
239,12,103,1,33,
239,12,104,4,0,
239,12,113,1,17,
239,12,113,4,0,
239,12,129,1,25,
239,12,148,4,0,
239,12,156,4,0,
239,12,168,4,0,
239,12,182,4,0,
239,12,213,4,0,
239,12,216,4,0,
239,12,218,4,0,
239,12,237,4,0,
239,12,240,4,0,
239,12,249,4,0,
239,12,263,4,0,
239,12,264,4,0,
239,12,280,4,0,
239,12,290,4,0,
239,12,351,4,0,
239,13,9,1,9,
239,13,34,3,0,
239,13,38,3,0,
239,13,43,1,1,2
239,13,69,3,0,
239,13,85,1,41,
239,13,85,4,0,
239,13,86,3,0,
239,13,87,1,49,
239,13,87,4,0,
239,13,92,4,0,
239,13,94,4,0,
239,13,98,1,1,1
239,13,102,3,0,
239,13,103,1,33,
239,13,104,4,0,
239,13,113,1,17,
239,13,113,4,0,
239,13,129,1,25,
239,13,148,4,0,
239,13,156,4,0,
239,13,164,3,0,
239,13,168,4,0,
239,13,182,4,0,
239,13,207,3,0,
239,13,213,4,0,
239,13,216,4,0,
239,13,218,4,0,
239,13,237,4,0,
239,13,240,4,0,
239,13,249,4,0,
239,13,263,4,0,
239,13,264,4,0,
239,13,280,4,0,
239,13,290,4,0,
239,13,351,4,0,
239,14,2,2,0,
239,14,7,2,0,
239,14,7,3,0,
239,14,8,2,0,
239,14,8,3,0,
239,14,9,1,29,
239,14,9,3,0,
239,14,27,2,0,
239,14,43,1,1,2
239,14,67,1,8,
239,14,67,3,0,
239,14,84,1,5,
239,14,85,1,40,
239,14,85,4,0,
239,14,86,1,19,
239,14,86,4,0,
239,14,87,1,43,
239,14,87,4,0,
239,14,92,4,0,
239,14,94,4,0,
239,14,96,2,0,
239,14,98,1,1,1
239,14,103,1,36,
239,14,104,4,0,
239,14,112,2,0,
239,14,113,1,26,
239,14,113,4,0,
239,14,129,1,12,
239,14,148,4,0,
239,14,156,4,0,
239,14,164,4,0,
239,14,168,4,0,
239,14,173,3,0,
239,14,182,4,0,
239,14,207,4,0,
239,14,213,4,0,
239,14,214,3,0,
239,14,216,4,0,
239,14,218,4,0,
239,14,223,2,0,
239,14,237,4,0,
239,14,238,2,0,
239,14,240,4,0,
239,14,249,4,0,
239,14,253,3,0,
239,14,263,4,0,
239,14,264,2,0,
239,14,270,3,0,
239,14,280,4,0,
239,14,324,3,0,
239,14,343,3,0,
239,14,351,1,15,
239,14,359,2,0,
239,14,364,2,0,
239,14,374,4,0,
239,14,393,3,0,
239,14,435,1,33,
239,14,451,4,0,
239,14,486,1,22,
239,14,496,4,0,
239,14,521,4,0,
239,14,527,3,0,
239,14,528,4,0,
239,14,530,3,0,
239,15,2,2,0,
239,15,7,2,0,
239,15,8,2,0,
239,15,9,1,29,
239,15,27,2,0,
239,15,43,1,1,2
239,15,67,1,8,
239,15,84,1,5,
239,15,85,1,40,
239,15,85,4,0,
239,15,86,1,19,
239,15,86,4,0,
239,15,87,1,43,
239,15,87,4,0,
239,15,92,4,0,
239,15,94,4,0,
239,15,96,2,0,
239,15,98,1,1,1
239,15,103,1,36,
239,15,104,4,0,
239,15,112,2,0,
239,15,113,1,26,
239,15,113,4,0,
239,15,129,1,12,
239,15,148,4,0,
239,15,156,4,0,
239,15,164,4,0,
239,15,168,4,0,
239,15,182,4,0,
239,15,207,4,0,
239,15,213,4,0,
239,15,214,4,0,
239,15,216,4,0,
239,15,218,4,0,
239,15,223,2,0,
239,15,237,4,0,
239,15,238,2,0,
239,15,240,4,0,
239,15,249,4,0,
239,15,263,4,0,
239,15,264,2,0,
239,15,280,4,0,
239,15,351,1,15,
239,15,359,2,0,
239,15,364,2,0,
239,15,374,4,0,
239,15,435,1,33,
239,15,451,4,0,
239,15,486,1,22,
239,15,496,4,0,
239,15,521,4,0,
239,15,528,4,0,
239,15,590,4,0,
239,15,612,4,0,
239,16,2,2,0,
239,16,7,2,0,
239,16,7,3,0,
239,16,8,2,0,
239,16,8,3,0,
239,16,9,1,29,1
239,16,9,3,0,
239,16,27,2,0,
239,16,43,1,1,2
239,16,67,1,8,1
239,16,67,3,0,
239,16,84,1,5,1
239,16,85,1,40,1
239,16,85,4,0,
239,16,86,1,19,1
239,16,86,4,0,
239,16,87,1,43,1
239,16,87,4,0,
239,16,92,4,0,
239,16,94,4,0,
239,16,96,2,0,
239,16,98,1,1,1
239,16,103,1,36,1
239,16,104,4,0,
239,16,112,2,0,
239,16,113,1,26,1
239,16,113,4,0,
239,16,129,1,12,1
239,16,148,4,0,
239,16,156,4,0,
239,16,164,4,0,
239,16,168,4,0,
239,16,173,3,0,
239,16,182,4,0,
239,16,207,4,0,
239,16,213,4,0,
239,16,214,4,0,
239,16,216,4,0,
239,16,218,4,0,
239,16,223,2,0,
239,16,237,4,0,
239,16,238,2,0,
239,16,240,4,0,
239,16,249,4,0,
239,16,253,3,0,
239,16,263,4,0,
239,16,264,2,0,
239,16,264,3,0,
239,16,270,3,0,
239,16,280,4,0,
239,16,290,4,0,
239,16,324,3,0,
239,16,343,3,0,
239,16,351,1,15,1
239,16,351,3,0,
239,16,359,2,0,
239,16,364,2,0,
239,16,374,4,0,
239,16,393,3,0,
239,16,435,1,33,1
239,16,451,4,0,
239,16,486,1,22,1
239,16,496,4,0,
239,16,521,4,0,
239,16,527,3,0,
239,16,528,4,0,
239,16,530,3,0,
239,16,590,4,0,
239,16,612,4,0,
239,17,2,2,0,
239,17,7,2,0,
239,17,8,2,0,
239,17,9,1,29,
239,17,27,2,0,
239,17,43,1,1,2
239,17,67,1,8,
239,17,84,1,5,
239,17,85,1,40,
239,17,85,4,0,
239,17,86,1,19,
239,17,86,4,0,
239,17,87,1,43,
239,17,87,4,0,
239,17,92,4,0,
239,17,94,4,0,
239,17,96,2,0,
239,17,98,1,1,1
239,17,103,1,36,
239,17,104,4,0,
239,17,112,2,0,
239,17,113,1,26,
239,17,113,4,0,
239,17,129,1,12,
239,17,156,4,0,
239,17,164,4,0,
239,17,168,4,0,
239,17,182,4,0,
239,17,207,4,0,
239,17,213,4,0,
239,17,214,4,0,
239,17,216,4,0,
239,17,218,4,0,
239,17,223,2,0,
239,17,237,4,0,
239,17,238,2,0,
239,17,240,4,0,
239,17,263,4,0,
239,17,264,2,0,
239,17,280,4,0,
239,17,351,1,15,
239,17,359,2,0,
239,17,364,2,0,
239,17,374,4,0,
239,17,435,1,33,
239,17,451,4,0,
239,17,486,1,22,
239,17,496,4,0,
239,17,521,4,0,
239,17,528,4,0,
239,17,590,4,0,
239,18,2,2,0,
239,18,7,2,0,
239,18,8,2,0,
239,18,9,1,29,
239,18,27,2,0,
239,18,43,1,1,2
239,18,67,1,8,
239,18,84,1,5,
239,18,85,1,40,
239,18,85,4,0,
239,18,86,1,19,
239,18,86,4,0,
239,18,87,1,43,
239,18,87,4,0,
239,18,92,4,0,
239,18,94,4,0,
239,18,96,2,0,
239,18,98,1,1,1
239,18,103,1,36,
239,18,104,4,0,
239,18,112,2,0,
239,18,113,1,26,
239,18,113,4,0,
239,18,129,1,12,
239,18,156,4,0,
239,18,164,4,0,
239,18,168,4,0,
239,18,182,4,0,
239,18,207,4,0,
239,18,213,4,0,
239,18,214,4,0,
239,18,216,4,0,
239,18,218,4,0,
239,18,223,2,0,
239,18,237,4,0,
239,18,238,2,0,
239,18,240,4,0,
239,18,263,4,0,
239,18,264,2,0,
239,18,280,4,0,
239,18,351,1,15,
239,18,359,2,0,
239,18,364,2,0,
239,18,374,4,0,
239,18,435,1,33,
239,18,451,4,0,
239,18,486,1,22,
239,18,496,4,0,
239,18,521,4,0,
239,18,528,4,0,
239,18,590,4,0,
240,3,2,2,0,
240,3,5,2,0,
240,3,7,1,19,
240,3,7,4,0,
240,3,9,4,0,
240,3,29,4,0,
240,3,43,1,7,
240,3,52,1,1,
240,3,53,1,37,
240,3,92,4,0,
240,3,94,4,0,
240,3,103,2,0,
240,3,104,4,0,
240,3,108,1,25,
240,3,109,1,43,
240,3,112,2,0,
240,3,123,1,13,
240,3,126,1,49,
240,3,126,4,0,
240,3,156,4,0,
240,3,168,4,0,
240,3,173,4,0,
240,3,174,4,0,
240,3,182,4,0,
240,3,189,4,0,
240,3,197,4,0,
240,3,203,4,0,
240,3,207,4,0,
240,3,213,4,0,
240,3,214,4,0,
240,3,216,4,0,
240,3,218,4,0,
240,3,223,4,0,
240,3,231,4,0,
240,3,237,4,0,
240,3,241,1,31,
240,3,241,4,0,
240,4,2,2,0,
240,4,5,2,0,
240,4,7,1,19,
240,4,7,4,0,
240,4,9,4,0,
240,4,29,4,0,
240,4,43,1,7,
240,4,52,1,1,
240,4,53,1,37,
240,4,53,3,0,
240,4,92,4,0,
240,4,94,4,0,
240,4,103,2,0,
240,4,104,4,0,
240,4,108,1,25,
240,4,109,1,43,
240,4,112,2,0,
240,4,123,1,13,
240,4,126,1,49,
240,4,126,4,0,
240,4,156,4,0,
240,4,168,4,0,
240,4,173,4,0,
240,4,174,4,0,
240,4,182,4,0,
240,4,189,4,0,
240,4,197,4,0,
240,4,203,4,0,
240,4,207,4,0,
240,4,213,4,0,
240,4,214,4,0,
240,4,216,4,0,
240,4,218,4,0,
240,4,223,4,0,
240,4,231,4,0,
240,4,237,4,0,
240,4,238,2,0,
240,4,241,1,31,
240,4,241,4,0,
240,5,2,2,0,
240,5,5,2,0,
240,5,7,1,19,
240,5,9,2,0,
240,5,43,1,7,
240,5,52,1,1,
240,5,53,1,37,
240,5,53,4,0,
240,5,92,4,0,
240,5,94,4,0,
240,5,103,2,0,
240,5,104,4,0,
240,5,108,1,25,
240,5,109,1,43,
240,5,112,2,0,
240,5,123,1,13,
240,5,126,1,49,
240,5,126,4,0,
240,5,156,4,0,
240,5,168,4,0,
240,5,182,4,0,
240,5,213,4,0,
240,5,216,4,0,
240,5,218,4,0,
240,5,231,4,0,
240,5,237,4,0,
240,5,238,2,0,
240,5,241,1,31,
240,5,241,4,0,
240,5,249,4,0,
240,5,263,4,0,
240,5,264,4,0,
240,5,280,4,0,
240,5,290,4,0,
240,6,2,2,0,
240,6,5,2,0,
240,6,5,3,0,
240,6,7,1,19,
240,6,7,3,0,
240,6,9,2,0,
240,6,9,3,0,
240,6,25,3,0,
240,6,34,3,0,
240,6,38,3,0,
240,6,43,1,7,
240,6,52,1,1,
240,6,53,1,37,
240,6,53,4,0,
240,6,68,3,0,
240,6,69,3,0,
240,6,92,4,0,
240,6,94,4,0,
240,6,102,3,0,
240,6,103,2,0,
240,6,104,4,0,
240,6,108,1,25,
240,6,109,1,43,
240,6,112,2,0,
240,6,123,1,13,
240,6,126,1,49,
240,6,126,4,0,
240,6,156,4,0,
240,6,164,3,0,
240,6,168,4,0,
240,6,173,3,0,
240,6,182,4,0,
240,6,189,3,0,
240,6,203,3,0,
240,6,207,3,0,
240,6,213,4,0,
240,6,214,3,0,
240,6,216,4,0,
240,6,218,4,0,
240,6,223,3,0,
240,6,231,4,0,
240,6,237,4,0,
240,6,238,2,0,
240,6,241,1,31,
240,6,241,4,0,
240,6,249,4,0,
240,6,263,4,0,
240,6,264,4,0,
240,6,280,4,0,
240,6,290,4,0,
240,7,2,2,0,
240,7,5,2,0,
240,7,5,3,0,
240,7,7,1,19,
240,7,9,2,0,
240,7,25,3,0,
240,7,34,3,0,
240,7,38,3,0,
240,7,43,1,7,
240,7,52,1,1,
240,7,53,1,37,
240,7,53,4,0,
240,7,68,3,0,
240,7,69,3,0,
240,7,92,4,0,
240,7,94,4,0,
240,7,102,3,0,
240,7,103,2,0,
240,7,104,4,0,
240,7,108,1,25,
240,7,109,1,43,
240,7,112,2,0,
240,7,123,1,13,
240,7,126,1,49,
240,7,126,4,0,
240,7,156,4,0,
240,7,164,3,0,
240,7,168,4,0,
240,7,182,4,0,
240,7,213,4,0,
240,7,216,4,0,
240,7,218,4,0,
240,7,231,4,0,
240,7,237,4,0,
240,7,238,2,0,
240,7,241,1,31,
240,7,241,4,0,
240,7,249,4,0,
240,7,263,4,0,
240,7,264,4,0,
240,7,280,4,0,
240,7,290,4,0,
240,8,2,2,0,
240,8,5,2,0,
240,8,7,1,28,
240,8,9,2,0,
240,8,43,1,1,2
240,8,52,1,7,
240,8,53,1,37,
240,8,53,4,0,
240,8,83,1,19,
240,8,92,4,0,
240,8,94,4,0,
240,8,103,2,0,
240,8,104,4,0,
240,8,108,1,10,
240,8,109,1,25,
240,8,112,2,0,
240,8,123,1,1,1
240,8,126,1,46,
240,8,126,4,0,
240,8,156,4,0,
240,8,164,4,0,
240,8,168,4,0,
240,8,182,4,0,
240,8,183,2,0,
240,8,185,1,16,
240,8,203,4,0,
240,8,207,4,0,
240,8,213,4,0,
240,8,214,4,0,
240,8,216,4,0,
240,8,218,4,0,
240,8,223,2,0,
240,8,231,4,0,
240,8,237,4,0,
240,8,238,2,0,
240,8,241,1,43,
240,8,241,4,0,
240,8,249,4,0,
240,8,261,4,0,
240,8,263,4,0,
240,8,264,4,0,
240,8,280,4,0,
240,8,290,4,0,
240,8,315,4,0,
240,8,363,4,0,
240,8,374,4,0,
240,8,394,2,0,
240,8,436,1,34,
240,8,445,4,0,
240,9,2,2,0,
240,9,5,2,0,
240,9,7,1,28,
240,9,7,3,0,
240,9,9,2,0,
240,9,9,3,0,
240,9,43,1,1,2
240,9,52,1,7,
240,9,53,1,37,
240,9,53,4,0,
240,9,83,1,19,
240,9,92,4,0,
240,9,94,4,0,
240,9,103,2,0,
240,9,104,4,0,
240,9,108,1,10,
240,9,109,1,25,
240,9,112,2,0,
240,9,123,1,1,1
240,9,126,1,46,
240,9,126,4,0,
240,9,156,4,0,
240,9,164,4,0,
240,9,168,4,0,
240,9,173,3,0,
240,9,182,4,0,
240,9,183,2,0,
240,9,185,1,16,
240,9,189,3,0,
240,9,203,4,0,
240,9,207,4,0,
240,9,213,4,0,
240,9,214,4,0,
240,9,216,4,0,
240,9,218,4,0,
240,9,223,2,0,
240,9,231,4,0,
240,9,237,4,0,
240,9,238,2,0,
240,9,241,1,43,
240,9,241,4,0,
240,9,249,4,0,
240,9,253,3,0,
240,9,257,3,0,
240,9,261,4,0,
240,9,263,4,0,
240,9,264,4,0,
240,9,270,3,0,
240,9,280,4,0,
240,9,290,4,0,
240,9,315,4,0,
240,9,363,4,0,
240,9,374,4,0,
240,9,394,2,0,
240,9,436,1,34,
240,9,445,4,0,
240,10,2,2,0,
240,10,5,2,0,
240,10,7,1,28,
240,10,7,3,0,
240,10,9,2,0,
240,10,9,3,0,
240,10,29,3,0,
240,10,43,1,1,2
240,10,52,1,7,
240,10,53,1,37,
240,10,53,4,0,
240,10,83,1,19,
240,10,92,4,0,
240,10,94,4,0,
240,10,103,2,0,
240,10,104,4,0,
240,10,108,1,10,
240,10,109,1,25,
240,10,112,2,0,
240,10,123,1,1,1
240,10,126,1,46,
240,10,126,4,0,
240,10,156,4,0,
240,10,164,4,0,
240,10,168,4,0,
240,10,173,3,0,
240,10,182,4,0,
240,10,183,2,0,
240,10,185,1,16,
240,10,187,2,0,
240,10,189,3,0,
240,10,203,4,0,
240,10,207,4,0,
240,10,213,4,0,
240,10,214,4,0,
240,10,216,4,0,
240,10,218,4,0,
240,10,223,2,0,
240,10,231,4,0,
240,10,237,4,0,
240,10,238,2,0,
240,10,241,1,43,
240,10,241,4,0,
240,10,249,4,0,
240,10,253,3,0,
240,10,257,3,0,
240,10,261,4,0,
240,10,263,4,0,
240,10,264,4,0,
240,10,270,3,0,
240,10,280,4,0,
240,10,290,4,0,
240,10,315,4,0,
240,10,363,4,0,
240,10,374,4,0,
240,10,394,2,0,
240,10,436,1,34,
240,10,445,4,0,
240,11,2,2,0,
240,11,5,2,0,
240,11,7,1,34,
240,11,9,2,0,
240,11,43,1,1,2
240,11,52,1,7,
240,11,53,1,43,
240,11,53,4,0,
240,11,83,1,19,
240,11,92,4,0,
240,11,94,4,0,
240,11,103,2,0,
240,11,104,4,0,
240,11,108,1,10,
240,11,109,1,25,
240,11,112,2,0,
240,11,116,2,0,
240,11,123,1,1,1
240,11,126,1,49,
240,11,126,4,0,
240,11,156,4,0,
240,11,164,4,0,
240,11,168,4,0,
240,11,182,4,0,
240,11,183,2,0,
240,11,185,1,16,
240,11,187,2,0,
240,11,207,4,0,
240,11,213,4,0,
240,11,216,4,0,
240,11,218,4,0,
240,11,223,2,0,
240,11,231,2,0,
240,11,237,4,0,
240,11,238,2,0,
240,11,241,1,46,
240,11,241,4,0,
240,11,249,4,0,
240,11,261,4,0,
240,11,263,4,0,
240,11,280,4,0,
240,11,315,4,0,
240,11,374,4,0,
240,11,394,2,0,
240,11,436,1,37,
240,11,481,1,28,
240,11,488,4,0,
240,11,496,4,0,
240,11,510,4,0,
240,12,7,1,19,
240,12,43,1,7,
240,12,52,1,1,
240,12,53,1,37,
240,12,53,4,0,
240,12,92,4,0,
240,12,94,4,0,
240,12,104,4,0,
240,12,108,1,25,
240,12,109,1,43,
240,12,123,1,13,
240,12,126,1,49,
240,12,126,4,0,
240,12,156,4,0,
240,12,168,4,0,
240,12,182,4,0,
240,12,213,4,0,
240,12,216,4,0,
240,12,218,4,0,
240,12,231,4,0,
240,12,237,4,0,
240,12,241,1,31,
240,12,241,4,0,
240,12,249,4,0,
240,12,263,4,0,
240,12,264,4,0,
240,12,280,4,0,
240,12,290,4,0,
240,13,7,1,19,
240,13,34,3,0,
240,13,38,3,0,
240,13,43,1,7,
240,13,52,1,1,
240,13,53,1,37,
240,13,53,4,0,
240,13,69,3,0,
240,13,92,4,0,
240,13,94,4,0,
240,13,102,3,0,
240,13,104,4,0,
240,13,108,1,25,
240,13,109,1,43,
240,13,123,1,13,
240,13,126,1,49,
240,13,126,4,0,
240,13,156,4,0,
240,13,164,3,0,
240,13,168,4,0,
240,13,182,4,0,
240,13,207,3,0,
240,13,213,4,0,
240,13,216,4,0,
240,13,218,4,0,
240,13,231,4,0,
240,13,237,4,0,
240,13,241,1,31,
240,13,241,4,0,
240,13,249,4,0,
240,13,263,4,0,
240,13,264,4,0,
240,13,280,4,0,
240,13,290,4,0,
240,14,2,2,0,
240,14,5,2,0,
240,14,7,1,29,
240,14,7,3,0,
240,14,9,2,0,
240,14,9,3,0,
240,14,43,1,1,2
240,14,52,1,5,
240,14,53,1,40,
240,14,53,4,0,
240,14,83,1,15,
240,14,92,4,0,
240,14,94,4,0,
240,14,103,2,0,
240,14,104,4,0,
240,14,108,1,8,
240,14,109,1,26,
240,14,112,2,0,
240,14,116,2,0,
240,14,123,1,1,1
240,14,126,1,43,
240,14,126,4,0,
240,14,156,4,0,
240,14,164,4,0,
240,14,168,4,0,
240,14,173,3,0,
240,14,182,4,0,
240,14,183,2,0,
240,14,185,1,12,
240,14,187,2,0,
240,14,207,4,0,
240,14,213,4,0,
240,14,214,3,0,
240,14,216,4,0,
240,14,218,4,0,
240,14,223,2,0,
240,14,231,2,0,
240,14,231,3,0,
240,14,237,4,0,
240,14,238,2,0,
240,14,241,1,36,
240,14,241,4,0,
240,14,249,4,0,
240,14,253,3,0,
240,14,257,3,0,
240,14,261,4,0,
240,14,263,4,0,
240,14,270,3,0,
240,14,280,4,0,
240,14,315,4,0,
240,14,343,3,0,
240,14,374,4,0,
240,14,394,2,0,
240,14,436,1,33,
240,14,481,1,22,
240,14,488,4,0,
240,14,496,4,0,
240,14,499,1,19,
240,14,510,4,0,
240,14,530,3,0,
240,15,2,2,0,
240,15,5,2,0,
240,15,7,1,29,
240,15,9,2,0,
240,15,43,1,1,2
240,15,52,1,5,
240,15,53,1,40,
240,15,53,4,0,
240,15,83,1,15,
240,15,92,4,0,
240,15,94,4,0,
240,15,103,2,0,
240,15,104,4,0,
240,15,108,1,8,
240,15,109,1,26,
240,15,112,2,0,
240,15,116,2,0,
240,15,123,1,1,1
240,15,126,1,43,
240,15,126,4,0,
240,15,156,4,0,
240,15,164,4,0,
240,15,168,4,0,
240,15,182,4,0,
240,15,183,2,0,
240,15,185,1,12,
240,15,187,2,0,
240,15,207,4,0,
240,15,213,4,0,
240,15,214,4,0,
240,15,216,4,0,
240,15,218,4,0,
240,15,223,2,0,
240,15,231,2,0,
240,15,237,4,0,
240,15,238,2,0,
240,15,241,1,36,
240,15,241,4,0,
240,15,249,4,0,
240,15,261,4,0,
240,15,263,4,0,
240,15,280,4,0,
240,15,315,4,0,
240,15,374,4,0,
240,15,384,2,0,
240,15,394,2,0,
240,15,436,1,33,
240,15,481,1,22,
240,15,488,4,0,
240,15,496,4,0,
240,15,499,1,19,
240,15,510,4,0,
240,15,562,2,0,
240,15,590,4,0,
240,15,612,4,0,
240,16,2,2,0,
240,16,5,2,0,
240,16,7,1,29,1
240,16,7,3,0,
240,16,9,2,0,
240,16,9,3,0,
240,16,43,1,1,2
240,16,52,1,5,1
240,16,53,1,40,1
240,16,53,4,0,
240,16,83,1,15,1
240,16,92,4,0,
240,16,94,4,0,
240,16,103,2,0,
240,16,104,4,0,
240,16,108,1,8,1
240,16,109,1,26,1
240,16,112,2,0,
240,16,116,2,0,
240,16,123,1,1,1
240,16,126,1,43,1
240,16,126,4,0,
240,16,156,4,0,
240,16,164,4,0,
240,16,168,4,0,
240,16,173,3,0,
240,16,182,4,0,
240,16,183,2,0,
240,16,185,1,12,1
240,16,187,2,0,
240,16,207,4,0,
240,16,213,4,0,
240,16,214,4,0,
240,16,216,4,0,
240,16,218,4,0,
240,16,223,2,0,
240,16,231,2,0,
240,16,231,3,0,
240,16,237,4,0,
240,16,238,2,0,
240,16,241,1,36,1
240,16,241,4,0,
240,16,249,4,0,
240,16,253,3,0,
240,16,257,3,0,
240,16,261,4,0,
240,16,263,4,0,
240,16,264,3,0,
240,16,270,3,0,
240,16,280,4,0,
240,16,290,4,0,
240,16,315,4,0,
240,16,343,3,0,
240,16,374,4,0,
240,16,384,2,0,
240,16,394,2,0,
240,16,436,1,33,1
240,16,481,1,22,1
240,16,488,4,0,
240,16,496,4,0,
240,16,499,1,19,1
240,16,510,4,0,
240,16,530,3,0,
240,16,562,2,0,
240,16,590,4,0,
240,16,612,4,0,
240,17,2,2,0,
240,17,5,2,0,
240,17,7,1,29,
240,17,9,2,0,
240,17,43,1,1,2
240,17,52,1,5,
240,17,53,1,40,
240,17,53,4,0,
240,17,83,1,15,
240,17,92,4,0,
240,17,94,4,0,
240,17,103,2,0,
240,17,104,4,0,
240,17,108,1,8,
240,17,109,1,26,
240,17,112,2,0,
240,17,116,2,0,
240,17,123,1,1,1
240,17,126,1,43,
240,17,126,4,0,
240,17,156,4,0,
240,17,164,4,0,
240,17,168,4,0,
240,17,182,4,0,
240,17,183,2,0,
240,17,185,1,12,
240,17,187,2,0,
240,17,207,4,0,
240,17,213,4,0,
240,17,214,4,0,
240,17,216,4,0,
240,17,218,4,0,
240,17,223,2,0,
240,17,231,2,0,
240,17,237,4,0,
240,17,238,2,0,
240,17,241,1,36,
240,17,241,4,0,
240,17,261,4,0,
240,17,263,4,0,
240,17,280,4,0,
240,17,315,4,0,
240,17,374,4,0,
240,17,384,2,0,
240,17,394,2,0,
240,17,436,1,33,
240,17,481,1,22,
240,17,488,4,0,
240,17,496,4,0,
240,17,499,1,19,
240,17,562,2,0,
240,17,590,4,0,
240,18,2,2,0,
240,18,5,2,0,
240,18,7,1,29,
240,18,9,2,0,
240,18,43,1,1,2
240,18,52,1,5,
240,18,53,1,40,
240,18,53,4,0,
240,18,83,1,15,
240,18,92,4,0,
240,18,94,4,0,
240,18,103,2,0,
240,18,104,4,0,
240,18,108,1,8,
240,18,109,1,26,
240,18,112,2,0,
240,18,116,2,0,
240,18,123,1,1,1
240,18,126,1,43,
240,18,126,4,0,
240,18,156,4,0,
240,18,164,4,0,
240,18,168,4,0,
240,18,182,4,0,
240,18,183,2,0,
240,18,185,1,12,
240,18,187,2,0,
240,18,207,4,0,
240,18,213,4,0,
240,18,214,4,0,
240,18,216,4,0,
240,18,218,4,0,
240,18,223,2,0,
240,18,231,2,0,
240,18,237,4,0,
240,18,238,2,0,
240,18,241,1,36,
240,18,241,4,0,
240,18,261,4,0,
240,18,263,4,0,
240,18,280,4,0,
240,18,315,4,0,
240,18,374,4,0,
240,18,384,2,0,
240,18,394,2,0,
240,18,436,1,33,
240,18,481,1,22,
240,18,488,4,0,
240,18,496,4,0,
240,18,499,1,19,
240,18,562,2,0,
240,18,590,4,0,
241,3,7,4,0,
241,3,8,4,0,
241,3,9,4,0,
241,3,23,1,13,
241,3,29,4,0,
241,3,33,1,1,
241,3,34,1,43,
241,3,45,1,4,
241,3,57,4,0,
241,3,59,4,0,
241,3,63,4,0,
241,3,69,2,0,
241,3,70,4,0,
241,3,87,4,0,
241,3,89,4,0,
241,3,92,4,0,
241,3,104,4,0,
241,3,111,1,8,
241,3,111,4,0,
241,3,117,1,26,
241,3,156,4,0,
241,3,173,4,0,
241,3,174,4,0,
241,3,179,2,0,
241,3,182,4,0,
241,3,189,4,0,
241,3,192,4,0,
241,3,196,4,0,
241,3,201,4,0,
241,3,203,4,0,
241,3,205,1,34,
241,3,205,4,0,
241,3,207,4,0,
241,3,208,1,19,
241,3,213,4,0,
241,3,214,4,0,
241,3,215,1,53,
241,3,216,4,0,
241,3,217,2,0,
241,3,218,4,0,
241,3,223,4,0,
241,3,230,4,0,
241,3,231,4,0,
241,3,237,4,0,
241,3,240,4,0,
241,3,241,4,0,
241,3,244,4,0,
241,3,247,4,0,
241,3,249,4,0,
241,4,7,4,0,
241,4,8,4,0,
241,4,9,4,0,
241,4,23,1,13,
241,4,29,4,0,
241,4,33,1,1,
241,4,34,1,43,
241,4,45,1,4,
241,4,57,4,0,
241,4,58,3,0,
241,4,59,4,0,
241,4,63,4,0,
241,4,69,2,0,
241,4,70,4,0,
241,4,85,3,0,
241,4,87,4,0,
241,4,89,4,0,
241,4,92,4,0,
241,4,104,4,0,
241,4,111,1,8,
241,4,111,4,0,
241,4,117,1,26,
241,4,156,4,0,
241,4,173,4,0,
241,4,174,4,0,
241,4,179,2,0,
241,4,182,4,0,
241,4,189,4,0,
241,4,192,4,0,
241,4,196,4,0,
241,4,201,4,0,
241,4,203,4,0,
241,4,205,1,34,
241,4,205,4,0,
241,4,207,4,0,
241,4,208,1,19,
241,4,213,4,0,
241,4,214,4,0,
241,4,215,1,53,
241,4,216,4,0,
241,4,217,2,0,
241,4,218,4,0,
241,4,223,4,0,
241,4,230,4,0,
241,4,231,4,0,
241,4,237,4,0,
241,4,240,4,0,
241,4,241,4,0,
241,4,244,4,0,
241,4,247,4,0,
241,4,249,4,0,
241,5,23,1,13,
241,5,33,1,1,
241,5,34,1,43,
241,5,45,1,4,
241,5,57,4,0,
241,5,58,4,0,
241,5,59,4,0,
241,5,63,4,0,
241,5,69,2,0,
241,5,70,4,0,
241,5,76,4,0,
241,5,85,4,0,
241,5,87,4,0,
241,5,89,4,0,
241,5,92,4,0,
241,5,104,4,0,
241,5,111,1,8,
241,5,117,1,26,
241,5,156,4,0,
241,5,174,2,0,
241,5,179,2,0,
241,5,182,4,0,
241,5,201,4,0,
241,5,203,2,0,
241,5,205,1,34,
241,5,208,1,19,
241,5,213,4,0,
241,5,214,2,0,
241,5,215,1,53,
241,5,216,4,0,
241,5,217,2,0,
241,5,218,4,0,
241,5,231,4,0,
241,5,237,4,0,
241,5,240,4,0,
241,5,241,4,0,
241,5,244,2,0,
241,5,247,4,0,
241,5,249,4,0,
241,5,263,4,0,
241,5,264,4,0,
241,5,270,2,0,
241,5,280,4,0,
241,5,290,4,0,
241,5,317,4,0,
241,5,351,4,0,
241,5,352,4,0,
241,6,5,3,0,
241,6,7,3,0,
241,6,8,3,0,
241,6,9,3,0,
241,6,23,1,13,
241,6,25,3,0,
241,6,33,1,1,
241,6,34,1,43,
241,6,34,3,0,
241,6,38,3,0,
241,6,45,1,4,
241,6,57,4,0,
241,6,58,4,0,
241,6,59,4,0,
241,6,63,4,0,
241,6,68,3,0,
241,6,69,2,0,
241,6,69,3,0,
241,6,70,4,0,
241,6,76,4,0,
241,6,85,4,0,
241,6,86,3,0,
241,6,87,4,0,
241,6,89,4,0,
241,6,92,4,0,
241,6,102,3,0,
241,6,104,4,0,
241,6,111,1,8,
241,6,111,3,0,
241,6,117,1,26,
241,6,118,3,0,
241,6,156,4,0,
241,6,157,3,0,
241,6,164,3,0,
241,6,173,3,0,
241,6,174,2,0,
241,6,179,2,0,
241,6,182,4,0,
241,6,189,3,0,
241,6,196,3,0,
241,6,201,4,0,
241,6,203,2,0,
241,6,203,3,0,
241,6,205,1,34,
241,6,205,3,0,
241,6,207,3,0,
241,6,208,1,19,
241,6,213,4,0,
241,6,214,2,0,
241,6,214,3,0,
241,6,215,1,53,
241,6,216,4,0,
241,6,217,2,0,
241,6,218,4,0,
241,6,223,3,0,
241,6,231,4,0,
241,6,237,4,0,
241,6,240,4,0,
241,6,241,4,0,
241,6,244,2,0,
241,6,244,3,0,
241,6,247,4,0,
241,6,249,4,0,
241,6,263,4,0,
241,6,264,4,0,
241,6,270,2,0,
241,6,280,4,0,
241,6,290,4,0,
241,6,317,4,0,
241,6,351,4,0,
241,6,352,4,0,
241,7,5,3,0,
241,7,23,1,13,
241,7,25,3,0,
241,7,33,1,1,
241,7,34,1,43,
241,7,34,3,0,
241,7,38,3,0,
241,7,45,1,4,
241,7,57,4,0,
241,7,58,4,0,
241,7,59,4,0,
241,7,63,4,0,
241,7,68,3,0,
241,7,69,2,0,
241,7,69,3,0,
241,7,70,4,0,
241,7,76,4,0,
241,7,85,4,0,
241,7,86,3,0,
241,7,87,4,0,
241,7,89,4,0,
241,7,92,4,0,
241,7,102,3,0,
241,7,104,4,0,
241,7,111,1,8,
241,7,117,1,26,
241,7,118,3,0,
241,7,156,4,0,
241,7,157,3,0,
241,7,164,3,0,
241,7,174,2,0,
241,7,179,2,0,
241,7,182,4,0,
241,7,201,4,0,
241,7,203,2,0,
241,7,205,1,34,
241,7,208,1,19,
241,7,213,4,0,
241,7,214,2,0,
241,7,215,1,53,
241,7,216,4,0,
241,7,217,2,0,
241,7,218,4,0,
241,7,231,4,0,
241,7,237,4,0,
241,7,240,4,0,
241,7,241,4,0,
241,7,244,2,0,
241,7,247,4,0,
241,7,249,4,0,
241,7,263,4,0,
241,7,264,4,0,
241,7,270,2,0,
241,7,280,4,0,
241,7,290,4,0,
241,7,317,4,0,
241,7,351,4,0,
241,7,352,4,0,
241,8,23,1,8,
241,8,33,1,1,
241,8,34,1,24,
241,8,38,2,0,
241,8,45,1,3,
241,8,57,4,0,
241,8,58,4,0,
241,8,59,4,0,
241,8,63,4,0,
241,8,69,2,0,
241,8,70,4,0,
241,8,76,4,0,
241,8,85,4,0,
241,8,86,4,0,
241,8,87,4,0,
241,8,89,4,0,
241,8,92,4,0,
241,8,104,4,0,
241,8,111,1,5,
241,8,117,1,15,
241,8,146,2,0,
241,8,156,4,0,
241,8,157,4,0,
241,8,164,4,0,
241,8,174,2,0,
241,8,179,2,0,
241,8,182,4,0,
241,8,201,4,0,
241,8,203,2,0,
241,8,203,4,0,
241,8,205,1,19,
241,8,207,4,0,
241,8,208,1,11,
241,8,213,4,0,
241,8,214,2,0,
241,8,214,4,0,
241,8,215,1,48,
241,8,216,4,0,
241,8,217,2,0,
241,8,218,4,0,
241,8,231,4,0,
241,8,237,4,0,
241,8,240,4,0,
241,8,241,4,0,
241,8,244,2,0,
241,8,244,4,0,
241,8,247,4,0,
241,8,249,4,0,
241,8,263,4,0,
241,8,264,4,0,
241,8,270,2,0,
241,8,280,4,0,
241,8,290,4,0,
241,8,317,4,0,
241,8,351,4,0,
241,8,352,4,0,
241,8,358,1,55,
241,8,359,2,0,
241,8,360,1,41,
241,8,360,4,0,
241,8,363,4,0,
241,8,374,4,0,
241,8,386,2,0,
241,8,411,4,0,
241,8,416,4,0,
241,8,428,1,29,
241,8,445,1,35,
241,8,445,4,0,
241,8,446,4,0,
241,9,7,3,0,
241,9,8,3,0,
241,9,9,3,0,
241,9,23,1,8,
241,9,33,1,1,
241,9,34,1,24,
241,9,38,2,0,
241,9,45,1,3,
241,9,57,4,0,
241,9,58,4,0,
241,9,59,4,0,
241,9,63,4,0,
241,9,69,2,0,
241,9,70,4,0,
241,9,76,4,0,
241,9,85,4,0,
241,9,86,4,0,
241,9,87,4,0,
241,9,89,4,0,
241,9,92,4,0,
241,9,104,4,0,
241,9,111,1,5,
241,9,117,1,15,
241,9,146,2,0,
241,9,156,4,0,
241,9,157,4,0,
241,9,164,4,0,
241,9,173,3,0,
241,9,174,2,0,
241,9,179,2,0,
241,9,182,4,0,
241,9,189,3,0,
241,9,196,3,0,
241,9,201,4,0,
241,9,203,2,0,
241,9,203,4,0,
241,9,205,1,19,
241,9,205,3,0,
241,9,207,4,0,
241,9,208,1,11,
241,9,213,4,0,
241,9,214,2,0,
241,9,214,4,0,
241,9,215,1,48,
241,9,216,4,0,
241,9,217,2,0,
241,9,218,4,0,
241,9,231,4,0,
241,9,237,4,0,
241,9,240,4,0,
241,9,241,4,0,
241,9,244,2,0,
241,9,244,4,0,
241,9,247,4,0,
241,9,249,4,0,
241,9,263,4,0,
241,9,264,4,0,
241,9,270,2,0,
241,9,270,3,0,
241,9,280,4,0,
241,9,290,4,0,
241,9,317,4,0,
241,9,351,4,0,
241,9,352,4,0,
241,9,358,1,55,
241,9,359,2,0,
241,9,360,1,41,
241,9,360,4,0,
241,9,363,4,0,
241,9,374,4,0,
241,9,386,2,0,
241,9,411,4,0,
241,9,416,4,0,
241,9,428,1,29,
241,9,428,3,0,
241,9,442,3,0,
241,9,445,1,35,
241,9,445,4,0,
241,9,446,4,0,
241,10,7,3,0,
241,10,8,3,0,
241,10,9,3,0,
241,10,23,1,8,
241,10,29,3,0,
241,10,33,1,1,
241,10,34,1,24,
241,10,38,2,0,
241,10,45,1,3,
241,10,57,4,0,
241,10,58,4,0,
241,10,59,4,0,
241,10,63,4,0,
241,10,69,2,0,
241,10,70,4,0,
241,10,76,4,0,
241,10,85,4,0,
241,10,86,4,0,
241,10,87,4,0,
241,10,89,4,0,
241,10,92,4,0,
241,10,104,4,0,
241,10,111,1,5,
241,10,117,1,15,
241,10,146,2,0,
241,10,156,4,0,
241,10,157,4,0,
241,10,164,4,0,
241,10,173,3,0,
241,10,174,2,0,
241,10,179,2,0,
241,10,182,4,0,
241,10,189,3,0,
241,10,196,3,0,
241,10,201,4,0,
241,10,203,2,0,
241,10,203,4,0,
241,10,205,1,19,
241,10,205,3,0,
241,10,207,4,0,
241,10,208,1,11,
241,10,213,4,0,
241,10,214,2,0,
241,10,214,4,0,
241,10,215,1,48,
241,10,215,3,0,
241,10,216,4,0,
241,10,217,2,0,
241,10,218,4,0,
241,10,231,4,0,
241,10,237,4,0,
241,10,240,4,0,
241,10,241,4,0,
241,10,244,2,0,
241,10,244,4,0,
241,10,247,4,0,
241,10,249,4,0,
241,10,250,4,0,
241,10,263,4,0,
241,10,264,4,0,
241,10,270,2,0,
241,10,270,3,0,
241,10,280,4,0,
241,10,290,4,0,
241,10,317,4,0,
241,10,335,3,0,
241,10,351,4,0,
241,10,352,4,0,
241,10,358,1,55,
241,10,359,2,0,
241,10,360,1,41,
241,10,360,4,0,
241,10,363,4,0,
241,10,374,4,0,
241,10,386,2,0,
241,10,411,4,0,
241,10,416,4,0,
241,10,428,1,29,
241,10,428,3,0,
241,10,442,3,0,
241,10,445,1,35,
241,10,445,4,0,
241,10,446,4,0,
241,11,23,1,8,
241,11,33,1,1,
241,11,34,1,24,
241,11,38,2,0,
241,11,45,1,3,
241,11,57,4,0,
241,11,58,4,0,
241,11,59,4,0,
241,11,63,4,0,
241,11,69,2,0,
241,11,70,4,0,
241,11,76,4,0,
241,11,85,4,0,
241,11,86,4,0,
241,11,87,4,0,
241,11,89,4,0,
241,11,92,4,0,
241,11,104,4,0,
241,11,111,1,5,
241,11,117,1,15,
241,11,146,2,0,
241,11,156,4,0,
241,11,157,4,0,
241,11,164,4,0,
241,11,174,2,0,
241,11,179,2,0,
241,11,182,4,0,
241,11,201,4,0,
241,11,203,2,0,
241,11,205,1,19,
241,11,207,4,0,
241,11,208,1,11,
241,11,213,4,0,
241,11,214,2,0,
241,11,215,1,48,
241,11,216,4,0,
241,11,217,2,0,
241,11,218,4,0,
241,11,237,4,0,
241,11,240,4,0,
241,11,241,4,0,
241,11,244,4,0,
241,11,247,4,0,
241,11,249,4,0,
241,11,263,4,0,
241,11,270,2,0,
241,11,280,4,0,
241,11,317,4,0,
241,11,358,1,55,
241,11,359,2,0,
241,11,360,1,41,
241,11,360,4,0,
241,11,363,2,0,
241,11,374,4,0,
241,11,386,2,0,
241,11,411,4,0,
241,11,416,4,0,
241,11,428,1,29,
241,11,445,1,35,
241,11,496,4,0,
241,11,497,4,0,
241,11,514,4,0,
241,11,523,4,0,
241,11,526,4,0,
241,11,531,2,0,
241,12,23,1,13,
241,12,33,1,1,
241,12,34,1,43,
241,12,45,1,4,
241,12,57,4,0,
241,12,58,4,0,
241,12,59,4,0,
241,12,63,4,0,
241,12,70,4,0,
241,12,76,4,0,
241,12,85,4,0,
241,12,87,4,0,
241,12,89,4,0,
241,12,92,4,0,
241,12,104,4,0,
241,12,111,1,8,
241,12,117,1,26,
241,12,156,4,0,
241,12,182,4,0,
241,12,201,4,0,
241,12,205,1,34,
241,12,208,1,19,
241,12,213,4,0,
241,12,215,1,53,
241,12,216,4,0,
241,12,218,4,0,
241,12,231,4,0,
241,12,237,4,0,
241,12,240,4,0,
241,12,241,4,0,
241,12,247,4,0,
241,12,249,4,0,
241,12,263,4,0,
241,12,264,4,0,
241,12,280,4,0,
241,12,290,4,0,
241,12,317,4,0,
241,12,351,4,0,
241,12,352,4,0,
241,13,23,1,13,
241,13,33,1,1,
241,13,34,1,43,
241,13,34,3,0,
241,13,38,3,0,
241,13,45,1,4,
241,13,57,4,0,
241,13,58,4,0,
241,13,59,4,0,
241,13,63,4,0,
241,13,69,3,0,
241,13,70,4,0,
241,13,76,4,0,
241,13,85,4,0,
241,13,86,3,0,
241,13,87,4,0,
241,13,89,4,0,
241,13,92,4,0,
241,13,102,3,0,
241,13,104,4,0,
241,13,111,1,8,
241,13,117,1,26,
241,13,156,4,0,
241,13,164,3,0,
241,13,182,4,0,
241,13,196,3,0,
241,13,201,4,0,
241,13,205,1,34,
241,13,207,3,0,
241,13,208,1,19,
241,13,213,4,0,
241,13,215,1,53,
241,13,216,4,0,
241,13,218,4,0,
241,13,231,4,0,
241,13,237,4,0,
241,13,240,4,0,
241,13,241,4,0,
241,13,247,4,0,
241,13,249,4,0,
241,13,263,4,0,
241,13,264,4,0,
241,13,280,4,0,
241,13,290,4,0,
241,13,317,4,0,
241,13,351,4,0,
241,13,352,4,0,
241,14,7,3,0,
241,14,8,3,0,
241,14,9,3,0,
241,14,23,1,8,
241,14,33,1,1,
241,14,34,1,24,
241,14,38,2,0,
241,14,45,1,3,
241,14,57,4,0,
241,14,58,4,0,
241,14,59,4,0,
241,14,63,4,0,
241,14,69,2,0,
241,14,70,4,0,
241,14,76,4,0,
241,14,85,4,0,
241,14,86,4,0,
241,14,87,4,0,
241,14,89,4,0,
241,14,92,4,0,
241,14,104,4,0,
241,14,111,1,5,
241,14,117,1,15,
241,14,146,2,0,
241,14,156,4,0,
241,14,157,4,0,
241,14,164,4,0,
241,14,173,3,0,
241,14,174,2,0,
241,14,179,2,0,
241,14,182,4,0,
241,14,196,3,0,
241,14,201,4,0,
241,14,203,2,0,
241,14,205,1,19,
241,14,207,4,0,
241,14,208,1,11,
241,14,213,4,0,
241,14,214,2,0,
241,14,214,3,0,
241,14,215,1,48,
241,14,215,3,0,
241,14,216,4,0,
241,14,217,2,0,
241,14,218,4,0,
241,14,231,3,0,
241,14,237,4,0,
241,14,240,4,0,
241,14,241,4,0,
241,14,244,4,0,
241,14,247,4,0,
241,14,249,4,0,
241,14,263,4,0,
241,14,270,2,0,
241,14,270,3,0,
241,14,280,4,0,
241,14,317,4,0,
241,14,335,3,0,
241,14,358,1,55,
241,14,359,2,0,
241,14,360,1,41,
241,14,360,4,0,
241,14,363,2,0,
241,14,374,4,0,
241,14,386,2,0,
241,14,411,4,0,
241,14,416,4,0,
241,14,428,1,29,
241,14,428,3,0,
241,14,442,3,0,
241,14,445,1,35,
241,14,446,3,0,
241,14,495,3,0,
241,14,496,4,0,
241,14,497,4,0,
241,14,514,4,0,
241,14,523,4,0,
241,14,526,4,0,
241,14,531,2,0,
241,15,23,1,8,
241,15,33,1,1,
241,15,34,1,24,
241,15,38,2,0,
241,15,45,1,3,
241,15,57,4,0,
241,15,58,4,0,
241,15,59,4,0,
241,15,63,4,0,
241,15,69,2,0,
241,15,70,4,0,
241,15,76,4,0,
241,15,85,4,0,
241,15,86,4,0,
241,15,87,4,0,
241,15,89,4,0,
241,15,92,4,0,
241,15,104,4,0,
241,15,111,1,5,
241,15,117,1,15,
241,15,146,2,0,
241,15,156,4,0,
241,15,157,4,0,
241,15,164,4,0,
241,15,174,2,0,
241,15,179,2,0,
241,15,182,4,0,
241,15,201,4,0,
241,15,203,2,0,
241,15,205,1,19,
241,15,207,4,0,
241,15,208,1,11,
241,15,213,4,0,
241,15,214,2,0,
241,15,214,4,0,
241,15,215,1,48,
241,15,216,4,0,
241,15,217,2,0,
241,15,218,4,0,
241,15,237,4,0,
241,15,240,4,0,
241,15,241,4,0,
241,15,244,4,0,
241,15,247,4,0,
241,15,249,4,0,
241,15,263,4,0,
241,15,270,2,0,
241,15,280,4,0,
241,15,317,4,0,
241,15,358,1,50,
241,15,359,2,0,
241,15,360,1,41,
241,15,360,4,0,
241,15,363,2,0,
241,15,374,4,0,
241,15,386,2,0,
241,15,411,4,0,
241,15,416,4,0,
241,15,428,1,29,
241,15,445,1,35,
241,15,496,4,0,
241,15,497,4,0,
241,15,514,4,0,
241,15,523,4,0,
241,15,531,2,0,
241,15,562,2,0,
241,15,590,4,0,
241,15,612,4,0,
241,16,7,3,0,
241,16,8,3,0,
241,16,9,3,0,
241,16,23,1,8,1
241,16,33,1,1,1
241,16,34,1,24,1
241,16,38,2,0,
241,16,45,1,3,1
241,16,57,4,0,
241,16,58,4,0,
241,16,59,4,0,
241,16,63,4,0,
241,16,69,2,0,
241,16,70,4,0,
241,16,76,4,0,
241,16,85,4,0,
241,16,86,4,0,
241,16,87,4,0,
241,16,89,4,0,
241,16,92,4,0,
241,16,104,4,0,
241,16,111,1,5,1
241,16,117,1,15,1
241,16,146,2,0,
241,16,156,4,0,
241,16,157,4,0,
241,16,164,4,0,
241,16,173,3,0,
241,16,174,2,0,
241,16,179,2,0,
241,16,182,4,0,
241,16,196,3,0,
241,16,201,4,0,
241,16,203,2,0,
241,16,205,1,19,1
241,16,207,4,0,
241,16,208,1,11,1
241,16,213,4,0,
241,16,214,2,0,
241,16,214,4,0,
241,16,215,1,48,1
241,16,215,3,0,
241,16,216,4,0,
241,16,217,2,0,
241,16,218,4,0,
241,16,231,3,0,
241,16,237,4,0,
241,16,240,4,0,
241,16,241,4,0,
241,16,244,4,0,
241,16,247,4,0,
241,16,249,4,0,
241,16,263,4,0,
241,16,264,3,0,
241,16,270,2,0,
241,16,270,3,0,
241,16,280,4,0,
241,16,290,4,0,
241,16,317,4,0,
241,16,335,3,0,
241,16,351,3,0,
241,16,352,3,0,
241,16,358,1,50,1
241,16,359,2,0,
241,16,360,1,41,1
241,16,360,4,0,
241,16,363,2,0,
241,16,374,4,0,
241,16,386,2,0,
241,16,411,4,0,
241,16,416,4,0,
241,16,428,1,29,1
241,16,428,3,0,
241,16,442,3,0,
241,16,445,1,35,1
241,16,446,3,0,
241,16,495,3,0,
241,16,496,4,0,
241,16,497,4,0,
241,16,514,4,0,
241,16,523,4,0,
241,16,531,2,0,
241,16,562,2,0,
241,16,590,4,0,
241,16,612,4,0,
241,17,23,1,8,
241,17,33,1,1,
241,17,34,1,24,
241,17,38,2,0,
241,17,45,1,3,
241,17,57,4,0,
241,17,58,4,0,
241,17,59,4,0,
241,17,63,4,0,
241,17,69,2,0,
241,17,76,4,0,
241,17,85,4,0,
241,17,86,4,0,
241,17,87,4,0,
241,17,89,4,0,
241,17,92,4,0,
241,17,104,4,0,
241,17,111,1,5,
241,17,117,1,15,
241,17,146,2,0,
241,17,156,4,0,
241,17,157,4,0,
241,17,164,4,0,
241,17,174,2,0,
241,17,179,2,0,
241,17,182,4,0,
241,17,201,4,0,
241,17,203,2,0,
241,17,205,1,19,
241,17,207,4,0,
241,17,208,1,11,
241,17,213,4,0,
241,17,214,2,0,
241,17,214,4,0,
241,17,215,1,48,
241,17,216,4,0,
241,17,217,2,0,
241,17,218,4,0,
241,17,237,4,0,
241,17,240,4,0,
241,17,241,4,0,
241,17,244,4,0,
241,17,247,4,0,
241,17,263,4,0,
241,17,270,2,0,
241,17,280,4,0,
241,17,317,4,0,
241,17,358,1,50,
241,17,359,2,0,
241,17,360,1,41,
241,17,360,4,0,
241,17,363,2,0,
241,17,374,4,0,
241,17,386,2,0,
241,17,411,4,0,
241,17,416,4,0,
241,17,428,1,29,
241,17,445,1,35,
241,17,496,4,0,
241,17,497,4,0,
241,17,523,4,0,
241,17,526,4,0,
241,17,531,2,0,
241,17,562,2,0,
241,17,590,4,0,
241,18,23,1,8,
241,18,33,1,1,
241,18,34,1,24,
241,18,38,2,0,
241,18,45,1,3,
241,18,57,4,0,
241,18,58,4,0,
241,18,59,4,0,
241,18,63,4,0,
241,18,69,2,0,
241,18,76,4,0,
241,18,85,4,0,
241,18,86,4,0,
241,18,87,4,0,
241,18,89,4,0,
241,18,92,4,0,
241,18,104,4,0,
241,18,111,1,5,
241,18,117,1,15,
241,18,146,2,0,
241,18,156,4,0,
241,18,157,4,0,
241,18,164,4,0,
241,18,174,2,0,
241,18,179,2,0,
241,18,182,4,0,
241,18,201,4,0,
241,18,203,2,0,
241,18,205,1,19,
241,18,207,4,0,
241,18,208,1,11,
241,18,213,4,0,
241,18,214,2,0,
241,18,214,4,0,
241,18,215,1,48,
241,18,216,4,0,
241,18,217,2,0,
241,18,218,4,0,
241,18,237,4,0,
241,18,240,4,0,
241,18,241,4,0,
241,18,244,4,0,
241,18,247,4,0,
241,18,263,4,0,
241,18,270,2,0,
241,18,280,4,0,
241,18,317,4,0,
241,18,358,1,50,
241,18,359,2,0,
241,18,360,1,41,
241,18,360,4,0,
241,18,363,2,0,
241,18,374,4,0,
241,18,386,2,0,
241,18,411,4,0,
241,18,416,4,0,
241,18,428,1,29,
241,18,445,1,35,
241,18,496,4,0,
241,18,497,4,0,
241,18,523,4,0,
241,18,526,4,0,
241,18,531,2,0,
241,18,562,2,0,
241,18,590,4,0,
242,3,1,1,1,
242,3,3,1,13,
242,3,29,4,0,
242,3,38,1,47,
242,3,39,1,7,
242,3,45,1,4,
242,3,47,1,23,
242,3,59,4,0,
242,3,63,4,0,
242,3,70,4,0,
242,3,76,4,0,
242,3,87,4,0,
242,3,92,4,0,
242,3,94,4,0,
242,3,104,4,0,
242,3,107,1,18,
242,3,111,1,33,
242,3,111,4,0,
242,3,113,1,40,
242,3,121,1,28,
242,3,126,4,0,
242,3,135,1,10,
242,3,138,4,0,
242,3,148,4,0,
242,3,156,4,0,
242,3,173,4,0,
242,3,174,4,0,
242,3,182,4,0,
242,3,189,4,0,
242,3,192,4,0,
242,3,196,4,0,
242,3,201,4,0,
242,3,203,4,0,
242,3,205,4,0,
242,3,207,4,0,
242,3,213,4,0,
242,3,214,4,0,
242,3,216,4,0,
242,3,218,4,0,
242,3,223,4,0,
242,3,237,4,0,
242,3,240,4,0,
242,3,241,4,0,
242,3,247,4,0,
242,3,249,4,0,
242,4,1,1,1,
242,4,3,1,13,
242,4,29,4,0,
242,4,38,1,47,
242,4,39,1,7,
242,4,45,1,4,
242,4,47,1,23,
242,4,53,3,0,
242,4,58,3,0,
242,4,59,4,0,
242,4,63,4,0,
242,4,70,4,0,
242,4,76,4,0,
242,4,85,3,0,
242,4,87,4,0,
242,4,92,4,0,
242,4,94,4,0,
242,4,104,4,0,
242,4,107,1,18,
242,4,111,1,33,
242,4,111,4,0,
242,4,113,1,40,
242,4,121,1,28,
242,4,126,4,0,
242,4,135,1,10,
242,4,138,4,0,
242,4,148,4,0,
242,4,156,4,0,
242,4,173,4,0,
242,4,174,4,0,
242,4,182,4,0,
242,4,189,4,0,
242,4,192,4,0,
242,4,196,4,0,
242,4,201,4,0,
242,4,203,4,0,
242,4,205,4,0,
242,4,207,4,0,
242,4,213,4,0,
242,4,214,4,0,
242,4,216,4,0,
242,4,218,4,0,
242,4,223,4,0,
242,4,237,4,0,
242,4,240,4,0,
242,4,241,4,0,
242,4,247,4,0,
242,4,249,4,0,
242,5,1,1,1,1
242,5,3,1,13,
242,5,38,1,47,
242,5,39,1,4,
242,5,45,1,1,2
242,5,47,1,23,
242,5,53,4,0,
242,5,58,4,0,
242,5,59,4,0,
242,5,63,4,0,
242,5,70,4,0,
242,5,76,4,0,
242,5,85,4,0,
242,5,87,4,0,
242,5,89,4,0,
242,5,92,4,0,
242,5,94,4,0,
242,5,104,4,0,
242,5,107,1,18,
242,5,111,1,33,
242,5,113,1,40,
242,5,113,4,0,
242,5,121,1,28,
242,5,126,4,0,
242,5,135,1,10,
242,5,148,4,0,
242,5,156,4,0,
242,5,182,4,0,
242,5,201,4,0,
242,5,213,4,0,
242,5,216,4,0,
242,5,218,4,0,
242,5,219,4,0,
242,5,231,4,0,
242,5,237,4,0,
242,5,240,4,0,
242,5,241,4,0,
242,5,247,4,0,
242,5,249,4,0,
242,5,258,4,0,
242,5,263,4,0,
242,5,264,4,0,
242,5,280,4,0,
242,5,285,4,0,
242,5,287,1,7,
242,5,289,4,0,
242,5,290,4,0,
242,5,317,4,0,
242,5,347,4,0,
242,5,351,4,0,
242,5,352,4,0,
242,6,1,1,1,1
242,6,3,1,13,
242,6,5,3,0,
242,6,25,3,0,
242,6,34,3,0,
242,6,38,1,47,
242,6,38,3,0,
242,6,39,1,4,
242,6,45,1,1,2
242,6,47,1,23,
242,6,53,4,0,
242,6,58,4,0,
242,6,59,4,0,
242,6,63,4,0,
242,6,68,3,0,
242,6,69,3,0,
242,6,70,4,0,
242,6,76,4,0,
242,6,85,4,0,
242,6,86,3,0,
242,6,87,4,0,
242,6,89,4,0,
242,6,92,4,0,
242,6,94,4,0,
242,6,102,3,0,
242,6,104,4,0,
242,6,107,1,18,
242,6,111,1,33,
242,6,111,3,0,
242,6,113,1,40,
242,6,113,4,0,
242,6,118,3,0,
242,6,121,1,28,
242,6,126,4,0,
242,6,135,1,10,
242,6,135,3,0,
242,6,138,3,0,
242,6,148,4,0,
242,6,156,4,0,
242,6,164,3,0,
242,6,173,3,0,
242,6,182,4,0,
242,6,189,3,0,
242,6,196,3,0,
242,6,201,4,0,
242,6,203,3,0,
242,6,205,3,0,
242,6,207,3,0,
242,6,213,4,0,
242,6,214,3,0,
242,6,216,4,0,
242,6,218,4,0,
242,6,219,4,0,
242,6,223,3,0,
242,6,231,4,0,
242,6,237,4,0,
242,6,240,4,0,
242,6,241,4,0,
242,6,247,4,0,
242,6,249,4,0,
242,6,258,4,0,
242,6,263,4,0,
242,6,264,4,0,
242,6,280,4,0,
242,6,285,4,0,
242,6,287,1,7,
242,6,289,4,0,
242,6,290,4,0,
242,6,317,4,0,
242,6,347,4,0,
242,6,351,4,0,
242,6,352,4,0,
242,7,1,1,1,1
242,7,3,1,13,
242,7,5,3,0,
242,7,25,3,0,
242,7,34,3,0,
242,7,38,1,47,
242,7,38,3,0,
242,7,39,1,4,
242,7,45,1,1,2
242,7,47,1,23,
242,7,53,4,0,
242,7,58,4,0,
242,7,59,4,0,
242,7,63,4,0,
242,7,68,3,0,
242,7,69,3,0,
242,7,70,4,0,
242,7,76,4,0,
242,7,85,4,0,
242,7,86,3,0,
242,7,87,4,0,
242,7,89,4,0,
242,7,92,4,0,
242,7,94,4,0,
242,7,102,3,0,
242,7,104,4,0,
242,7,107,1,18,
242,7,111,1,33,
242,7,113,1,40,
242,7,113,4,0,
242,7,118,3,0,
242,7,121,1,28,
242,7,126,4,0,
242,7,135,1,10,
242,7,135,3,0,
242,7,138,3,0,
242,7,148,4,0,
242,7,156,4,0,
242,7,164,3,0,
242,7,182,4,0,
242,7,201,4,0,
242,7,213,4,0,
242,7,216,4,0,
242,7,218,4,0,
242,7,219,4,0,
242,7,231,4,0,
242,7,237,4,0,
242,7,240,4,0,
242,7,241,4,0,
242,7,247,4,0,
242,7,249,4,0,
242,7,258,4,0,
242,7,263,4,0,
242,7,264,4,0,
242,7,280,4,0,
242,7,285,4,0,
242,7,287,1,7,
242,7,289,4,0,
242,7,290,4,0,
242,7,317,4,0,
242,7,347,4,0,
242,7,351,4,0,
242,7,352,4,0,
242,8,1,1,1,1
242,8,3,1,16,
242,8,38,1,46,
242,8,39,1,5,
242,8,45,1,1,2
242,8,47,1,23,
242,8,53,4,0,
242,8,58,4,0,
242,8,59,4,0,
242,8,63,4,0,
242,8,70,4,0,
242,8,76,4,0,
242,8,85,4,0,
242,8,86,4,0,
242,8,87,4,0,
242,8,89,4,0,
242,8,92,4,0,
242,8,94,4,0,
242,8,104,4,0,
242,8,107,1,20,
242,8,111,1,31,
242,8,113,1,34,
242,8,113,4,0,
242,8,121,1,38,
242,8,126,4,0,
242,8,135,1,12,
242,8,138,4,0,
242,8,148,4,0,
242,8,156,4,0,
242,8,157,4,0,
242,8,164,4,0,
242,8,182,4,0,
242,8,201,4,0,
242,8,203,4,0,
242,8,207,4,0,
242,8,213,4,0,
242,8,214,4,0,
242,8,216,4,0,
242,8,218,4,0,
242,8,219,4,0,
242,8,231,4,0,
242,8,237,4,0,
242,8,240,4,0,
242,8,241,4,0,
242,8,244,4,0,
242,8,247,4,0,
242,8,249,4,0,
242,8,258,4,0,
242,8,263,4,0,
242,8,264,4,0,
242,8,278,4,0,
242,8,280,4,0,
242,8,285,4,0,
242,8,287,1,9,
242,8,289,4,0,
242,8,290,4,0,
242,8,317,4,0,
242,8,347,4,0,
242,8,351,4,0,
242,8,352,4,0,
242,8,361,1,42,
242,8,363,4,0,
242,8,374,1,27,
242,8,374,4,0,
242,8,409,4,0,
242,8,411,4,0,
242,8,416,4,0,
242,8,419,4,0,
242,8,431,4,0,
242,8,445,4,0,
242,8,446,4,0,
242,8,447,4,0,
242,8,451,4,0,
242,9,1,1,1,1
242,9,3,1,16,
242,9,7,3,0,
242,9,8,3,0,
242,9,9,3,0,
242,9,38,1,46,
242,9,39,1,5,
242,9,45,1,1,2
242,9,47,1,23,
242,9,53,4,0,
242,9,58,4,0,
242,9,59,4,0,
242,9,63,4,0,
242,9,70,4,0,
242,9,76,4,0,
242,9,85,4,0,
242,9,86,4,0,
242,9,87,4,0,
242,9,89,4,0,
242,9,92,4,0,
242,9,94,4,0,
242,9,104,4,0,
242,9,107,1,20,
242,9,111,1,31,
242,9,113,1,34,
242,9,113,4,0,
242,9,121,1,38,
242,9,126,4,0,
242,9,135,1,12,
242,9,138,4,0,
242,9,148,4,0,
242,9,156,4,0,
242,9,157,4,0,
242,9,164,4,0,
242,9,173,3,0,
242,9,182,4,0,
242,9,189,3,0,
242,9,196,3,0,
242,9,201,4,0,
242,9,203,4,0,
242,9,205,3,0,
242,9,207,4,0,
242,9,213,4,0,
242,9,214,4,0,
242,9,216,4,0,
242,9,218,4,0,
242,9,219,4,0,
242,9,231,4,0,
242,9,237,4,0,
242,9,240,4,0,
242,9,241,4,0,
242,9,244,4,0,
242,9,247,4,0,
242,9,249,4,0,
242,9,258,4,0,
242,9,263,4,0,
242,9,264,4,0,
242,9,270,3,0,
242,9,278,4,0,
242,9,280,4,0,
242,9,283,3,0,
242,9,285,4,0,
242,9,287,1,9,
242,9,289,4,0,
242,9,290,4,0,
242,9,317,4,0,
242,9,347,4,0,
242,9,351,4,0,
242,9,352,4,0,
242,9,361,1,42,
242,9,363,4,0,
242,9,374,1,27,
242,9,374,4,0,
242,9,387,3,0,
242,9,409,4,0,
242,9,411,4,0,
242,9,416,4,0,
242,9,419,4,0,
242,9,428,3,0,
242,9,431,4,0,
242,9,445,4,0,
242,9,446,4,0,
242,9,447,4,0,
242,9,451,4,0,
242,10,1,1,1,1
242,10,3,1,16,
242,10,7,3,0,
242,10,8,3,0,
242,10,9,3,0,
242,10,29,3,0,
242,10,38,1,46,
242,10,39,1,5,
242,10,45,1,1,2
242,10,47,1,23,
242,10,53,4,0,
242,10,58,4,0,
242,10,59,4,0,
242,10,63,4,0,
242,10,70,4,0,
242,10,76,4,0,
242,10,85,4,0,
242,10,86,4,0,
242,10,87,4,0,
242,10,89,4,0,
242,10,92,4,0,
242,10,94,4,0,
242,10,104,4,0,
242,10,107,1,20,
242,10,111,1,31,
242,10,113,1,34,
242,10,113,4,0,
242,10,121,1,38,
242,10,126,4,0,
242,10,135,1,12,
242,10,138,4,0,
242,10,148,4,0,
242,10,156,4,0,
242,10,157,4,0,
242,10,164,4,0,
242,10,173,3,0,
242,10,182,4,0,
242,10,189,3,0,
242,10,196,3,0,
242,10,201,4,0,
242,10,203,4,0,
242,10,205,3,0,
242,10,207,4,0,
242,10,213,4,0,
242,10,214,4,0,
242,10,215,3,0,
242,10,216,4,0,
242,10,218,4,0,
242,10,219,4,0,
242,10,231,4,0,
242,10,237,4,0,
242,10,240,4,0,
242,10,241,4,0,
242,10,244,4,0,
242,10,247,4,0,
242,10,249,4,0,
242,10,258,4,0,
242,10,263,4,0,
242,10,264,4,0,
242,10,270,3,0,
242,10,278,4,0,
242,10,280,4,0,
242,10,283,3,0,
242,10,285,4,0,
242,10,287,1,9,
242,10,289,4,0,
242,10,290,4,0,
242,10,317,4,0,
242,10,335,3,0,
242,10,347,4,0,
242,10,351,4,0,
242,10,352,4,0,
242,10,356,3,0,
242,10,361,1,42,
242,10,363,4,0,
242,10,374,1,27,
242,10,374,4,0,
242,10,387,3,0,
242,10,409,4,0,
242,10,411,4,0,
242,10,416,4,0,
242,10,419,4,0,
242,10,428,3,0,
242,10,431,4,0,
242,10,445,4,0,
242,10,446,4,0,
242,10,447,4,0,
242,10,451,4,0,
242,11,1,1,1,2
242,11,3,1,12,
242,11,36,1,27,
242,11,38,1,54,
242,11,39,1,5,
242,11,45,1,1,3
242,11,47,1,31,
242,11,53,4,0,
242,11,58,4,0,
242,11,59,4,0,
242,11,63,4,0,
242,11,70,4,0,
242,11,76,4,0,
242,11,85,4,0,
242,11,86,4,0,
242,11,87,4,0,
242,11,89,4,0,
242,11,92,4,0,
242,11,94,4,0,
242,11,104,4,0,
242,11,107,1,23,
242,11,111,1,1,1
242,11,113,1,46,
242,11,113,4,0,
242,11,121,1,42,
242,11,126,4,0,
242,11,135,1,16,
242,11,138,4,0,
242,11,148,4,0,
242,11,156,4,0,
242,11,157,4,0,
242,11,164,4,0,
242,11,182,4,0,
242,11,201,4,0,
242,11,207,4,0,
242,11,213,4,0,
242,11,216,4,0,
242,11,218,4,0,
242,11,219,4,0,
242,11,237,4,0,
242,11,240,4,0,
242,11,241,4,0,
242,11,244,4,0,
242,11,247,4,0,
242,11,249,4,0,
242,11,258,4,0,
242,11,263,4,0,
242,11,280,4,0,
242,11,287,1,9,
242,11,317,4,0,
242,11,347,4,0,
242,11,361,1,50,
242,11,374,1,34,
242,11,374,4,0,
242,11,411,4,0,
242,11,416,4,0,
242,11,447,4,0,
242,11,451,4,0,
242,11,496,4,0,
242,11,497,4,0,
242,11,505,1,38,
242,11,510,4,0,
242,11,514,4,0,
242,11,516,1,20,
242,11,523,4,0,
242,11,526,4,0,
242,11,528,4,0,
242,12,1,1,1,1
242,12,3,1,13,
242,12,38,1,47,
242,12,39,1,4,
242,12,45,1,1,2
242,12,47,1,23,
242,12,53,4,0,
242,12,58,4,0,
242,12,59,4,0,
242,12,63,4,0,
242,12,70,4,0,
242,12,76,4,0,
242,12,85,4,0,
242,12,87,4,0,
242,12,89,4,0,
242,12,92,4,0,
242,12,94,4,0,
242,12,104,4,0,
242,12,107,1,18,
242,12,111,1,33,
242,12,113,1,40,
242,12,113,4,0,
242,12,121,1,28,
242,12,126,4,0,
242,12,135,1,10,
242,12,148,4,0,
242,12,156,4,0,
242,12,182,4,0,
242,12,201,4,0,
242,12,213,4,0,
242,12,216,4,0,
242,12,218,4,0,
242,12,219,4,0,
242,12,231,4,0,
242,12,237,4,0,
242,12,240,4,0,
242,12,241,4,0,
242,12,247,4,0,
242,12,249,4,0,
242,12,258,4,0,
242,12,263,4,0,
242,12,264,4,0,
242,12,280,4,0,
242,12,285,4,0,
242,12,287,1,7,
242,12,289,4,0,
242,12,290,4,0,
242,12,317,4,0,
242,12,347,4,0,
242,12,351,4,0,
242,12,352,4,0,
242,13,1,1,1,1
242,13,3,1,13,
242,13,34,3,0,
242,13,38,1,47,
242,13,38,3,0,
242,13,39,1,4,
242,13,45,1,1,2
242,13,47,1,23,
242,13,53,4,0,
242,13,58,4,0,
242,13,59,4,0,
242,13,63,4,0,
242,13,69,3,0,
242,13,70,4,0,
242,13,76,4,0,
242,13,85,4,0,
242,13,86,3,0,
242,13,87,4,0,
242,13,89,4,0,
242,13,92,4,0,
242,13,94,4,0,
242,13,102,3,0,
242,13,104,4,0,
242,13,107,1,18,
242,13,111,1,33,
242,13,113,1,40,
242,13,113,4,0,
242,13,121,1,28,
242,13,126,4,0,
242,13,135,1,10,
242,13,138,3,0,
242,13,148,4,0,
242,13,156,4,0,
242,13,164,3,0,
242,13,182,4,0,
242,13,196,3,0,
242,13,201,4,0,
242,13,207,3,0,
242,13,213,4,0,
242,13,216,4,0,
242,13,218,4,0,
242,13,219,4,0,
242,13,231,4,0,
242,13,237,4,0,
242,13,240,4,0,
242,13,241,4,0,
242,13,247,4,0,
242,13,249,4,0,
242,13,258,4,0,
242,13,263,4,0,
242,13,264,4,0,
242,13,280,4,0,
242,13,285,4,0,
242,13,287,1,7,
242,13,289,4,0,
242,13,290,4,0,
242,13,317,4,0,
242,13,347,4,0,
242,13,351,4,0,
242,13,352,4,0,
242,14,1,1,1,2
242,14,3,1,12,
242,14,7,3,0,
242,14,8,3,0,
242,14,9,3,0,
242,14,36,1,27,
242,14,38,1,54,
242,14,39,1,5,
242,14,45,1,1,3
242,14,47,1,31,
242,14,53,4,0,
242,14,58,4,0,
242,14,59,4,0,
242,14,63,4,0,
242,14,70,4,0,
242,14,76,4,0,
242,14,85,4,0,
242,14,86,4,0,
242,14,87,4,0,
242,14,89,4,0,
242,14,92,4,0,
242,14,94,4,0,
242,14,104,4,0,
242,14,107,1,23,
242,14,111,1,1,1
242,14,113,1,46,
242,14,113,4,0,
242,14,121,1,42,
242,14,126,4,0,
242,14,135,1,16,
242,14,138,4,0,
242,14,148,4,0,
242,14,156,4,0,
242,14,157,4,0,
242,14,164,4,0,
242,14,173,3,0,
242,14,182,4,0,
242,14,196,3,0,
242,14,201,4,0,
242,14,207,4,0,
242,14,213,4,0,
242,14,214,3,0,
242,14,215,3,0,
242,14,216,4,0,
242,14,218,4,0,
242,14,219,4,0,
242,14,231,3,0,
242,14,237,4,0,
242,14,240,4,0,
242,14,241,4,0,
242,14,244,4,0,
242,14,247,4,0,
242,14,249,4,0,
242,14,258,4,0,
242,14,263,4,0,
242,14,270,3,0,
242,14,278,3,0,
242,14,280,4,0,
242,14,283,3,0,
242,14,285,3,0,
242,14,287,1,9,
242,14,289,3,0,
242,14,304,3,0,
242,14,317,4,0,
242,14,335,3,0,
242,14,343,3,0,
242,14,347,4,0,
242,14,356,3,0,
242,14,361,1,50,
242,14,374,1,34,
242,14,374,4,0,
242,14,387,3,0,
242,14,409,3,0,
242,14,411,4,0,
242,14,416,4,0,
242,14,428,3,0,
242,14,446,3,0,
242,14,447,4,0,
242,14,451,4,0,
242,14,496,4,0,
242,14,497,4,0,
242,14,505,1,38,
242,14,510,4,0,
242,14,514,4,0,
242,14,516,1,20,
242,14,523,4,0,
242,14,526,4,0,
242,14,528,4,0,
242,15,1,1,1,3
242,15,3,1,12,
242,15,36,1,27,
242,15,38,1,1,1
242,15,38,1,54,
242,15,39,1,5,
242,15,45,1,1,4
242,15,47,1,31,
242,15,53,4,0,
242,15,58,4,0,
242,15,59,4,0,
242,15,63,4,0,
242,15,70,4,0,
242,15,76,4,0,
242,15,85,4,0,
242,15,86,4,0,
242,15,87,4,0,
242,15,89,4,0,
242,15,92,4,0,
242,15,94,4,0,
242,15,104,4,0,
242,15,107,1,23,
242,15,111,1,1,2
242,15,113,1,46,
242,15,113,4,0,
242,15,121,1,42,
242,15,126,4,0,
242,15,135,1,16,
242,15,138,4,0,
242,15,148,4,0,
242,15,156,4,0,
242,15,157,4,0,
242,15,164,4,0,
242,15,182,4,0,
242,15,201,4,0,
242,15,207,4,0,
242,15,213,4,0,
242,15,214,4,0,
242,15,216,4,0,
242,15,218,4,0,
242,15,219,4,0,
242,15,237,4,0,
242,15,240,4,0,
242,15,241,4,0,
242,15,244,4,0,
242,15,247,4,0,
242,15,249,4,0,
242,15,258,4,0,
242,15,263,4,0,
242,15,280,4,0,
242,15,287,1,9,
242,15,317,4,0,
242,15,347,4,0,
242,15,361,1,50,
242,15,374,1,34,
242,15,374,4,0,
242,15,411,4,0,
242,15,416,4,0,
242,15,447,4,0,
242,15,451,4,0,
242,15,496,4,0,
242,15,497,4,0,
242,15,505,1,38,
242,15,510,4,0,
242,15,514,4,0,
242,15,516,1,20,
242,15,523,4,0,
242,15,528,4,0,
242,15,590,4,0,
242,15,605,4,0,
242,15,612,4,0,
242,16,1,1,1,3
242,16,3,1,12,1
242,16,7,3,0,
242,16,8,3,0,
242,16,9,3,0,
242,16,36,1,27,1
242,16,38,1,1,1
242,16,38,1,54,1
242,16,39,1,5,1
242,16,45,1,1,4
242,16,47,1,31,1
242,16,53,4,0,
242,16,58,4,0,
242,16,59,4,0,
242,16,63,4,0,
242,16,70,4,0,
242,16,76,4,0,
242,16,85,4,0,
242,16,86,4,0,
242,16,87,4,0,
242,16,89,4,0,
242,16,92,4,0,
242,16,94,4,0,
242,16,104,4,0,
242,16,107,1,23,1
242,16,111,1,1,2
242,16,113,1,46,1
242,16,113,4,0,
242,16,121,1,42,1
242,16,126,4,0,
242,16,135,1,16,1
242,16,138,4,0,
242,16,148,4,0,
242,16,156,4,0,
242,16,157,4,0,
242,16,164,4,0,
242,16,173,3,0,
242,16,182,4,0,
242,16,196,3,0,
242,16,201,4,0,
242,16,207,4,0,
242,16,213,4,0,
242,16,214,4,0,
242,16,215,3,0,
242,16,216,4,0,
242,16,218,4,0,
242,16,219,4,0,
242,16,231,3,0,
242,16,237,4,0,
242,16,240,4,0,
242,16,241,4,0,
242,16,244,4,0,
242,16,247,4,0,
242,16,249,4,0,
242,16,258,4,0,
242,16,263,4,0,
242,16,264,3,0,
242,16,270,3,0,
242,16,278,3,0,
242,16,280,4,0,
242,16,283,3,0,
242,16,285,3,0,
242,16,287,1,9,1
242,16,289,3,0,
242,16,290,4,0,
242,16,304,3,0,
242,16,317,4,0,
242,16,335,3,0,
242,16,343,3,0,
242,16,347,4,0,
242,16,351,3,0,
242,16,352,3,0,
242,16,356,3,0,
242,16,361,1,50,1
242,16,374,1,34,1
242,16,374,4,0,
242,16,387,3,0,
242,16,409,3,0,
242,16,411,4,0,
242,16,416,4,0,
242,16,428,3,0,
242,16,446,3,0,
242,16,447,4,0,
242,16,451,4,0,
242,16,496,4,0,
242,16,497,4,0,
242,16,505,1,38,1
242,16,510,4,0,
242,16,514,4,0,
242,16,516,1,20,1
242,16,523,4,0,
242,16,528,4,0,
242,16,590,4,0,
242,16,605,4,0,
242,16,612,4,0,
242,17,1,1,1,3
242,17,3,1,12,
242,17,36,1,27,
242,17,38,1,1,1
242,17,38,1,54,
242,17,39,1,5,
242,17,45,1,1,4
242,17,47,1,31,
242,17,53,4,0,
242,17,58,4,0,
242,17,59,4,0,
242,17,63,4,0,
242,17,76,4,0,
242,17,85,4,0,
242,17,86,4,0,
242,17,87,4,0,
242,17,89,4,0,
242,17,92,4,0,
242,17,94,4,0,
242,17,104,4,0,
242,17,107,1,23,
242,17,111,1,1,2
242,17,113,1,46,
242,17,113,4,0,
242,17,121,1,42,
242,17,126,4,0,
242,17,135,1,16,
242,17,138,4,0,
242,17,156,4,0,
242,17,157,4,0,
242,17,164,4,0,
242,17,182,4,0,
242,17,201,4,0,
242,17,207,4,0,
242,17,213,4,0,
242,17,214,4,0,
242,17,216,4,0,
242,17,218,4,0,
242,17,219,4,0,
242,17,237,4,0,
242,17,240,4,0,
242,17,241,4,0,
242,17,244,4,0,
242,17,247,4,0,
242,17,258,4,0,
242,17,263,4,0,
242,17,280,4,0,
242,17,287,1,9,
242,17,317,4,0,
242,17,347,4,0,
242,17,361,1,50,
242,17,374,1,34,
242,17,374,4,0,
242,17,411,4,0,
242,17,416,4,0,
242,17,447,4,0,
242,17,451,4,0,
242,17,496,4,0,
242,17,497,4,0,
242,17,505,1,38,
242,17,516,1,20,
242,17,523,4,0,
242,17,526,4,0,
242,17,528,4,0,
242,17,590,4,0,
242,17,605,4,0,
242,18,1,1,1,3
242,18,3,1,12,
242,18,36,1,27,
242,18,38,1,1,1
242,18,38,1,65,
242,18,39,1,5,
242,18,45,1,1,4
242,18,47,1,31,
242,18,53,4,0,
242,18,58,4,0,
242,18,59,4,0,
242,18,63,4,0,
242,18,76,4,0,
242,18,85,4,0,
242,18,86,4,0,
242,18,87,4,0,
242,18,89,4,0,
242,18,92,4,0,
242,18,94,4,0,
242,18,104,4,0,
242,18,107,1,23,
242,18,111,1,1,2
242,18,113,1,50,
242,18,113,4,0,
242,18,121,1,44,
242,18,126,4,0,
242,18,135,1,16,
242,18,138,4,0,
242,18,156,4,0,
242,18,157,4,0,
242,18,164,4,0,
242,18,182,4,0,
242,18,201,4,0,
242,18,207,4,0,
242,18,213,4,0,
242,18,214,4,0,
242,18,216,4,0,
242,18,218,4,0,
242,18,219,4,0,
242,18,237,4,0,
242,18,240,4,0,
242,18,241,4,0,
242,18,244,4,0,
242,18,247,4,0,
242,18,258,4,0,
242,18,263,4,0,
242,18,280,4,0,
242,18,287,1,9,
242,18,317,4,0,
242,18,347,4,0,
242,18,361,1,57,
242,18,374,1,34,
242,18,374,4,0,
242,18,411,4,0,
242,18,416,4,0,
242,18,447,4,0,
242,18,451,4,0,
242,18,496,4,0,
242,18,497,4,0,
242,18,505,1,39,
242,18,516,1,20,
242,18,523,4,0,
242,18,526,4,0,
242,18,528,4,0,
242,18,590,4,0,
242,18,605,4,0,
243,3,15,4,0,
243,3,29,4,0,
243,3,43,1,1,2
243,3,44,1,1,1
243,3,46,1,21,
243,3,46,4,0,
243,3,63,4,0,
243,3,70,4,0,
243,3,84,1,11,
243,3,87,1,71,
243,3,87,4,0,
243,3,91,4,0,
243,3,92,4,0,
243,3,98,1,31,
243,3,104,4,0,
243,3,115,1,51,
243,3,129,4,0,
243,3,148,4,0,
243,3,156,4,0,
243,3,173,4,0,
243,3,174,4,0,
243,3,182,4,0,
243,3,189,4,0,
243,3,192,4,0,
243,3,197,4,0,
243,3,201,4,0,
243,3,203,4,0,
243,3,207,4,0,
243,3,209,1,41,
243,3,214,4,0,
243,3,216,4,0,
243,3,218,4,0,
243,3,231,4,0,
243,3,237,4,0,
243,3,240,4,0,
243,3,241,4,0,
243,3,242,1,61,
243,3,244,4,0,
243,3,249,4,0,
243,4,15,4,0,
243,4,29,4,0,
243,4,43,1,1,2
243,4,44,1,1,1
243,4,46,1,21,
243,4,46,4,0,
243,4,63,4,0,
243,4,70,4,0,
243,4,84,1,11,
243,4,85,3,0,
243,4,87,1,71,
243,4,87,4,0,
243,4,91,4,0,
243,4,92,4,0,
243,4,98,1,31,
243,4,104,4,0,
243,4,115,1,51,
243,4,129,4,0,
243,4,148,4,0,
243,4,156,4,0,
243,4,173,4,0,
243,4,174,4,0,
243,4,182,4,0,
243,4,189,4,0,
243,4,192,4,0,
243,4,197,4,0,
243,4,201,4,0,
243,4,203,4,0,
243,4,207,4,0,
243,4,209,1,41,
243,4,214,4,0,
243,4,216,4,0,
243,4,218,4,0,
243,4,231,4,0,
243,4,237,4,0,
243,4,240,4,0,
243,4,241,4,0,
243,4,242,1,61,
243,4,244,4,0,
243,4,249,4,0,
243,5,15,4,0,
243,5,43,1,1,2
243,5,44,1,1,1
243,5,46,1,21,
243,5,46,4,0,
243,5,63,4,0,
243,5,70,4,0,
243,5,84,1,11,
243,5,85,4,0,
243,5,87,1,71,
243,5,87,4,0,
243,5,91,4,0,
243,5,92,4,0,
243,5,98,1,31,
243,5,104,4,0,
243,5,115,1,51,
243,5,115,4,0,
243,5,148,4,0,
243,5,156,4,0,
243,5,182,4,0,
243,5,201,4,0,
243,5,209,1,41,
243,5,216,4,0,
243,5,218,4,0,
243,5,231,4,0,
243,5,237,4,0,
243,5,240,4,0,
243,5,241,4,0,
243,5,242,1,61,
243,5,249,4,0,
243,5,263,4,0,
243,5,290,4,0,
243,5,347,1,81,
243,5,347,4,0,
243,5,351,4,0,
243,6,15,4,0,
243,6,34,3,0,
243,6,38,3,0,
243,6,43,1,1,2
243,6,44,1,1,1
243,6,46,1,21,
243,6,46,4,0,
243,6,63,4,0,
243,6,70,4,0,
243,6,84,1,11,
243,6,85,4,0,
243,6,86,3,0,
243,6,87,1,71,
243,6,87,4,0,
243,6,91,4,0,
243,6,92,4,0,
243,6,98,1,31,
243,6,102,3,0,
243,6,104,4,0,
243,6,115,1,51,
243,6,115,4,0,
243,6,129,3,0,
243,6,148,4,0,
243,6,156,4,0,
243,6,164,3,0,
243,6,173,3,0,
243,6,182,4,0,
243,6,189,3,0,
243,6,201,4,0,
243,6,203,3,0,
243,6,207,3,0,
243,6,209,1,41,
243,6,214,3,0,
243,6,216,4,0,
243,6,218,4,0,
243,6,231,4,0,
243,6,237,4,0,
243,6,240,4,0,
243,6,241,4,0,
243,6,242,1,61,
243,6,244,3,0,
243,6,249,4,0,
243,6,263,4,0,
243,6,290,4,0,
243,6,347,1,81,
243,6,347,4,0,
243,6,351,4,0,
243,7,15,4,0,
243,7,34,3,0,
243,7,38,3,0,
243,7,43,1,1,2
243,7,44,1,1,1
243,7,46,1,21,
243,7,46,4,0,
243,7,63,4,0,
243,7,70,4,0,
243,7,84,1,11,
243,7,85,4,0,
243,7,86,3,0,
243,7,87,1,71,
243,7,87,4,0,
243,7,91,4,0,
243,7,92,4,0,
243,7,98,1,31,
243,7,102,3,0,
243,7,104,4,0,
243,7,115,1,51,
243,7,115,4,0,
243,7,148,4,0,
243,7,156,4,0,
243,7,164,3,0,
243,7,182,4,0,
243,7,201,4,0,
243,7,209,1,41,
243,7,216,4,0,
243,7,218,4,0,
243,7,231,4,0,
243,7,237,4,0,
243,7,240,4,0,
243,7,241,4,0,
243,7,242,1,61,
243,7,249,4,0,
243,7,263,4,0,
243,7,290,4,0,
243,7,347,1,81,
243,7,347,4,0,
243,7,351,4,0,
243,8,15,4,0,
243,8,43,1,1,2
243,8,44,1,1,1
243,8,46,1,15,
243,8,46,4,0,
243,8,63,4,0,
243,8,70,4,0,
243,8,84,1,8,
243,8,85,4,0,
243,8,86,4,0,
243,8,87,1,71,
243,8,87,4,0,
243,8,91,4,0,
243,8,92,4,0,
243,8,98,1,22,
243,8,104,4,0,
243,8,113,4,0,
243,8,115,1,36,
243,8,115,4,0,
243,8,148,4,0,
243,8,156,4,0,
243,8,164,4,0,
243,8,182,4,0,
243,8,201,4,0,
243,8,203,4,0,
243,8,207,4,0,
243,8,209,1,29,
243,8,214,4,0,
243,8,216,4,0,
243,8,218,4,0,
243,8,231,4,0,
243,8,237,4,0,
243,8,240,4,0,
243,8,241,4,0,
243,8,242,1,43,
243,8,244,4,0,
243,8,247,4,0,
243,8,249,4,0,
243,8,263,4,0,
243,8,290,4,0,
243,8,326,1,64,
243,8,347,1,78,
243,8,347,4,0,
243,8,351,4,0,
243,8,363,4,0,
243,8,416,4,0,
243,8,422,1,50,
243,8,431,4,0,
243,8,435,1,57,
243,8,451,4,0,
243,9,15,4,0,
243,9,43,1,1,2
243,9,44,1,1,1
243,9,46,1,15,
243,9,46,4,0,
243,9,63,4,0,
243,9,70,4,0,
243,9,84,1,8,
243,9,85,4,0,
243,9,86,4,0,
243,9,87,1,85,
243,9,87,4,0,
243,9,91,4,0,
243,9,92,4,0,
243,9,98,1,22,
243,9,104,4,0,
243,9,113,4,0,
243,9,115,1,36,
243,9,115,4,0,
243,9,129,3,0,
243,9,148,4,0,
243,9,156,4,0,
243,9,164,4,0,
243,9,173,3,0,
243,9,182,4,0,
243,9,189,3,0,
243,9,201,4,0,
243,9,203,4,0,
243,9,207,4,0,
243,9,209,1,29,
243,9,214,4,0,
243,9,216,4,0,
243,9,218,4,0,
243,9,231,4,0,
243,9,237,4,0,
243,9,240,1,71,
243,9,240,4,0,
243,9,241,4,0,
243,9,242,1,43,
243,9,244,4,0,
243,9,247,4,0,
243,9,249,4,0,
243,9,263,4,0,
243,9,290,4,0,
243,9,324,3,0,
243,9,326,1,64,
243,9,347,1,78,
243,9,347,4,0,
243,9,351,4,0,
243,9,363,4,0,
243,9,393,3,0,
243,9,416,4,0,
243,9,422,1,50,
243,9,431,4,0,
243,9,435,1,57,
243,9,442,3,0,
243,9,451,4,0,
243,10,15,4,0,
243,10,29,3,0,
243,10,43,1,1,2
243,10,44,1,1,1
243,10,46,1,15,
243,10,46,4,0,
243,10,63,4,0,
243,10,70,4,0,
243,10,84,1,8,
243,10,85,4,0,
243,10,86,4,0,
243,10,87,1,85,
243,10,87,4,0,
243,10,91,4,0,
243,10,92,4,0,
243,10,98,1,22,
243,10,104,4,0,
243,10,113,4,0,
243,10,115,1,36,
243,10,115,4,0,
243,10,129,3,0,
243,10,148,4,0,
243,10,156,4,0,
243,10,164,4,0,
243,10,173,3,0,
243,10,182,4,0,
243,10,189,3,0,
243,10,201,4,0,
243,10,203,4,0,
243,10,207,4,0,
243,10,209,1,29,
243,10,214,4,0,
243,10,216,4,0,
243,10,218,4,0,
243,10,231,4,0,
243,10,237,4,0,
243,10,240,1,71,
243,10,240,4,0,
243,10,241,4,0,
243,10,242,1,43,
243,10,244,4,0,
243,10,247,4,0,
243,10,249,4,0,
243,10,263,4,0,
243,10,290,4,0,
243,10,324,3,0,
243,10,326,1,64,
243,10,347,1,78,
243,10,347,4,0,
243,10,351,4,0,
243,10,363,4,0,
243,10,393,3,0,
243,10,416,4,0,
243,10,422,1,50,
243,10,431,4,0,
243,10,435,1,57,
243,10,442,3,0,
243,10,451,4,0,
243,11,15,4,0,
243,11,43,1,1,2
243,11,44,1,1,1
243,11,46,1,15,
243,11,46,4,0,
243,11,63,4,0,
243,11,70,4,0,
243,11,84,1,8,
243,11,85,4,0,
243,11,86,4,0,
243,11,87,1,85,
243,11,87,4,0,
243,11,91,4,0,
243,11,92,4,0,
243,11,98,1,22,
243,11,104,4,0,
243,11,113,4,0,
243,11,115,1,36,
243,11,115,4,0,
243,11,148,4,0,
243,11,156,4,0,
243,11,164,4,0,
243,11,182,4,0,
243,11,201,4,0,
243,11,207,4,0,
243,11,209,1,29,
243,11,216,4,0,
243,11,218,4,0,
243,11,237,4,0,
243,11,240,1,71,
243,11,240,4,0,
243,11,241,4,0,
243,11,242,1,43,
243,11,244,4,0,
243,11,247,4,0,
243,11,249,4,0,
243,11,263,4,0,
243,11,326,1,64,
243,11,347,1,78,
243,11,347,4,0,
243,11,416,4,0,
243,11,422,1,50,
243,11,435,1,57,
243,11,451,4,0,
243,11,496,4,0,
243,11,511,4,0,
243,11,521,4,0,
243,11,523,4,0,
243,11,528,4,0,
243,11,555,4,0,
243,12,15,4,0,
243,12,43,1,1,2
243,12,44,1,1,1
243,12,46,1,21,
243,12,46,4,0,
243,12,63,4,0,
243,12,70,4,0,
243,12,84,1,11,
243,12,85,4,0,
243,12,87,1,71,
243,12,87,4,0,
243,12,91,4,0,
243,12,92,4,0,
243,12,98,1,31,
243,12,104,4,0,
243,12,115,1,51,
243,12,115,4,0,
243,12,148,4,0,
243,12,156,4,0,
243,12,182,4,0,
243,12,201,4,0,
243,12,209,1,41,
243,12,216,4,0,
243,12,218,4,0,
243,12,231,4,0,
243,12,237,4,0,
243,12,240,4,0,
243,12,241,4,0,
243,12,242,1,61,
243,12,249,4,0,
243,12,263,4,0,
243,12,290,4,0,
243,12,347,1,81,
243,12,347,4,0,
243,12,351,4,0,
243,13,15,4,0,
243,13,34,3,0,
243,13,38,3,0,
243,13,43,1,1,2
243,13,44,1,1,1
243,13,46,1,21,
243,13,46,4,0,
243,13,63,4,0,
243,13,70,4,0,
243,13,84,1,11,
243,13,85,4,0,
243,13,86,3,0,
243,13,87,1,71,
243,13,87,4,0,
243,13,91,4,0,
243,13,92,4,0,
243,13,98,1,31,
243,13,102,3,0,
243,13,104,4,0,
243,13,115,1,51,
243,13,115,4,0,
243,13,148,4,0,
243,13,156,4,0,
243,13,164,3,0,
243,13,182,4,0,
243,13,201,4,0,
243,13,207,3,0,
243,13,209,1,41,
243,13,216,4,0,
243,13,218,4,0,
243,13,231,4,0,
243,13,237,4,0,
243,13,240,4,0,
243,13,241,4,0,
243,13,242,1,61,
243,13,249,4,0,
243,13,263,4,0,
243,13,290,4,0,
243,13,347,1,81,
243,13,347,4,0,
243,13,351,4,0,
243,14,15,4,0,
243,14,43,1,1,2
243,14,44,1,1,1
243,14,46,1,15,
243,14,46,4,0,
243,14,63,4,0,
243,14,70,4,0,
243,14,84,1,8,
243,14,85,4,0,
243,14,86,4,0,
243,14,87,1,85,
243,14,87,4,0,
243,14,91,4,0,
243,14,92,4,0,
243,14,98,1,22,
243,14,104,4,0,
243,14,113,4,0,
243,14,115,1,36,
243,14,115,4,0,
243,14,148,4,0,
243,14,156,4,0,
243,14,164,4,0,
243,14,173,3,0,
243,14,182,4,0,
243,14,201,4,0,
243,14,207,4,0,
243,14,209,1,29,
243,14,214,3,0,
243,14,216,4,0,
243,14,218,4,0,
243,14,231,3,0,
243,14,237,4,0,
243,14,240,1,71,
243,14,240,4,0,
243,14,241,4,0,
243,14,242,1,43,
243,14,244,4,0,
243,14,247,4,0,
243,14,249,4,0,
243,14,263,4,0,
243,14,324,3,0,
243,14,326,1,64,
243,14,347,1,78,
243,14,347,4,0,
243,14,393,3,0,
243,14,416,4,0,
243,14,422,1,50,
243,14,435,1,57,
243,14,442,3,0,
243,14,451,4,0,
243,14,496,4,0,
243,14,511,4,0,
243,14,521,4,0,
243,14,523,4,0,
243,14,528,4,0,
243,14,555,4,0,
243,15,15,4,0,
243,15,43,1,1,4
243,15,44,1,1,3
243,15,46,1,15,
243,15,46,4,0,
243,15,63,4,0,
243,15,70,4,0,
243,15,84,1,8,
243,15,85,4,0,
243,15,86,4,0,
243,15,87,1,85,
243,15,87,4,0,
243,15,91,4,0,
243,15,92,4,0,
243,15,98,1,22,
243,15,104,4,0,
243,15,113,4,0,
243,15,115,1,36,
243,15,115,4,0,
243,15,148,4,0,
243,15,156,4,0,
243,15,164,4,0,
243,15,182,4,0,
243,15,201,4,0,
243,15,207,4,0,
243,15,209,1,29,
243,15,214,4,0,
243,15,216,4,0,
243,15,218,4,0,
243,15,237,4,0,
243,15,240,1,71,
243,15,240,4,0,
243,15,241,4,0,
243,15,242,1,43,
243,15,244,4,0,
243,15,247,4,0,
243,15,249,4,0,
243,15,263,4,0,
243,15,326,1,1,1
243,15,326,1,64,
243,15,347,1,78,
243,15,347,4,0,
243,15,416,4,0,
243,15,422,1,50,
243,15,435,1,1,2
243,15,435,1,57,
243,15,451,4,0,
243,15,496,4,0,
243,15,511,4,0,
243,15,521,4,0,
243,15,523,4,0,
243,15,528,4,0,
243,15,555,4,0,
243,15,590,4,0,
243,16,15,4,0,
243,16,43,1,1,4
243,16,44,1,1,3
243,16,46,1,15,1
243,16,46,4,0,
243,16,63,4,0,
243,16,70,4,0,
243,16,84,1,8,1
243,16,85,4,0,
243,16,86,4,0,
243,16,87,1,85,1
243,16,87,4,0,
243,16,91,4,0,
243,16,92,4,0,
243,16,98,1,22,1
243,16,104,4,0,
243,16,113,4,0,
243,16,115,1,36,1
243,16,115,4,0,
243,16,148,4,0,
243,16,156,4,0,
243,16,164,4,0,
243,16,173,3,0,
243,16,182,4,0,
243,16,201,4,0,
243,16,207,4,0,
243,16,209,1,29,1
243,16,214,4,0,
243,16,216,4,0,
243,16,218,4,0,
243,16,231,3,0,
243,16,237,4,0,
243,16,240,1,71,1
243,16,240,4,0,
243,16,241,4,0,
243,16,242,1,43,1
243,16,244,4,0,
243,16,247,4,0,
243,16,249,4,0,
243,16,263,4,0,
243,16,290,4,0,
243,16,324,3,0,
243,16,326,1,1,1
243,16,326,1,64,1
243,16,347,1,78,1
243,16,347,4,0,
243,16,351,3,0,
243,16,393,3,0,
243,16,416,4,0,
243,16,422,1,50,1
243,16,435,1,1,2
243,16,435,1,57,1
243,16,442,3,0,
243,16,451,4,0,
243,16,496,4,0,
243,16,511,4,0,
243,16,521,4,0,
243,16,523,4,0,
243,16,528,4,0,
243,16,555,4,0,
243,16,590,4,0,
243,17,43,1,1,4
243,17,44,1,1,3
243,17,46,1,15,
243,17,46,4,0,
243,17,63,4,0,
243,17,84,1,8,
243,17,85,4,0,
243,17,86,4,0,
243,17,87,1,85,
243,17,87,4,0,
243,17,92,4,0,
243,17,98,1,22,
243,17,104,4,0,
243,17,113,4,0,
243,17,115,1,36,
243,17,115,4,0,
243,17,156,4,0,
243,17,164,4,0,
243,17,182,4,0,
243,17,201,4,0,
243,17,207,4,0,
243,17,209,1,29,
243,17,214,4,0,
243,17,216,4,0,
243,17,218,4,0,
243,17,237,4,0,
243,17,240,1,71,
243,17,240,4,0,
243,17,241,4,0,
243,17,242,1,43,
243,17,244,4,0,
243,17,247,4,0,
243,17,263,4,0,
243,17,326,1,1,1
243,17,326,1,64,
243,17,347,1,78,
243,17,347,4,0,
243,17,416,4,0,
243,17,422,1,50,
243,17,435,1,1,2
243,17,435,1,57,
243,17,451,4,0,
243,17,496,4,0,
243,17,511,4,0,
243,17,521,4,0,
243,17,523,4,0,
243,17,528,4,0,
243,17,555,4,0,
243,17,590,4,0,
243,18,43,1,1,4
243,18,44,1,1,3
243,18,46,1,15,
243,18,46,4,0,
243,18,63,4,0,
243,18,84,1,8,
243,18,85,4,0,
243,18,86,4,0,
243,18,87,1,85,
243,18,87,4,0,
243,18,92,4,0,
243,18,98,1,22,
243,18,104,4,0,
243,18,113,4,0,
243,18,115,1,36,
243,18,115,4,0,
243,18,156,4,0,
243,18,164,4,0,
243,18,182,4,0,
243,18,201,4,0,
243,18,207,4,0,
243,18,209,1,29,
243,18,214,4,0,
243,18,216,4,0,
243,18,218,4,0,
243,18,237,4,0,
243,18,240,1,71,
243,18,240,4,0,
243,18,241,4,0,
243,18,242,1,43,
243,18,244,4,0,
243,18,247,4,0,
243,18,263,4,0,
243,18,326,1,1,1
243,18,326,1,64,
243,18,347,1,78,
243,18,347,4,0,
243,18,416,4,0,
243,18,422,1,50,
243,18,435,1,1,2
243,18,435,1,57,
243,18,451,4,0,
243,18,496,4,0,
243,18,511,4,0,
243,18,521,4,0,
243,18,523,4,0,
243,18,528,4,0,
243,18,555,4,0,
243,18,590,4,0,
244,3,15,4,0,
244,3,23,1,41,
244,3,29,4,0,
244,3,43,1,1,2
244,3,44,1,1,1
244,3,46,1,21,
244,3,46,4,0,
244,3,52,1,11,
244,3,53,1,51,
244,3,63,4,0,
244,3,70,4,0,
244,3,76,4,0,
244,3,83,1,31,
244,3,91,4,0,
244,3,92,4,0,
244,3,104,4,0,
244,3,126,1,71,
244,3,126,4,0,
244,3,129,4,0,
244,3,148,4,0,
244,3,156,4,0,
244,3,173,4,0,
244,3,174,4,0,
244,3,182,4,0,
244,3,189,4,0,
244,3,197,4,0,
244,3,201,4,0,
244,3,203,4,0,
244,3,207,1,61,
244,3,207,4,0,
244,3,214,4,0,
244,3,216,4,0,
244,3,218,4,0,
244,3,231,4,0,
244,3,237,4,0,
244,3,240,4,0,
244,3,241,4,0,
244,3,244,4,0,
244,3,249,4,0,
244,4,15,4,0,
244,4,23,1,41,
244,4,29,4,0,
244,4,43,1,1,2
244,4,44,1,1,1
244,4,46,1,21,
244,4,46,4,0,
244,4,52,1,11,
244,4,53,1,51,
244,4,53,3,0,
244,4,63,4,0,
244,4,70,4,0,
244,4,76,4,0,
244,4,83,1,31,
244,4,91,4,0,
244,4,92,4,0,
244,4,104,4,0,
244,4,126,1,71,
244,4,126,4,0,
244,4,129,4,0,
244,4,148,4,0,
244,4,156,4,0,
244,4,173,4,0,
244,4,174,4,0,
244,4,182,4,0,
244,4,189,4,0,
244,4,197,4,0,
244,4,201,4,0,
244,4,203,4,0,
244,4,207,1,61,
244,4,207,4,0,
244,4,214,4,0,
244,4,216,4,0,
244,4,218,4,0,
244,4,231,4,0,
244,4,237,4,0,
244,4,240,4,0,
244,4,241,4,0,
244,4,244,4,0,
244,4,249,4,0,
244,5,15,4,0,
244,5,23,1,41,
244,5,43,1,1,2
244,5,44,1,1,1
244,5,46,1,21,
244,5,46,4,0,
244,5,52,1,11,
244,5,53,1,51,
244,5,53,4,0,
244,5,63,4,0,
244,5,70,4,0,
244,5,76,4,0,
244,5,83,1,31,
244,5,91,4,0,
244,5,92,4,0,
244,5,104,4,0,
244,5,115,4,0,
244,5,126,1,71,
244,5,126,4,0,
244,5,148,4,0,
244,5,156,4,0,
244,5,182,4,0,
244,5,201,4,0,
244,5,207,1,61,
244,5,216,4,0,
244,5,218,4,0,
244,5,231,4,0,
244,5,237,4,0,
244,5,240,4,0,
244,5,241,4,0,
244,5,249,4,0,
244,5,263,4,0,
244,5,290,4,0,
244,5,347,1,81,
244,5,347,4,0,
244,6,15,4,0,
244,6,23,1,41,
244,6,34,3,0,
244,6,38,3,0,
244,6,43,1,1,2
244,6,44,1,1,1
244,6,46,1,21,
244,6,46,4,0,
244,6,52,1,11,
244,6,53,1,51,
244,6,53,4,0,
244,6,63,4,0,
244,6,70,4,0,
244,6,76,4,0,
244,6,83,1,31,
244,6,91,4,0,
244,6,92,4,0,
244,6,102,3,0,
244,6,104,4,0,
244,6,115,4,0,
244,6,126,1,71,
244,6,126,4,0,
244,6,129,3,0,
244,6,148,4,0,
244,6,156,4,0,
244,6,164,3,0,
244,6,173,3,0,
244,6,182,4,0,
244,6,189,3,0,
244,6,201,4,0,
244,6,203,3,0,
244,6,207,1,61,
244,6,207,3,0,
244,6,214,3,0,
244,6,216,4,0,
244,6,218,4,0,
244,6,231,4,0,
244,6,237,4,0,
244,6,240,4,0,
244,6,241,4,0,
244,6,244,3,0,
244,6,249,4,0,
244,6,263,4,0,
244,6,290,4,0,
244,6,347,1,81,
244,6,347,4,0,
244,7,15,4,0,
244,7,23,1,41,
244,7,34,3,0,
244,7,38,3,0,
244,7,43,1,1,2
244,7,44,1,1,1
244,7,46,1,21,
244,7,46,4,0,
244,7,52,1,11,
244,7,53,1,51,
244,7,53,4,0,
244,7,63,4,0,
244,7,70,4,0,
244,7,76,4,0,
244,7,83,1,31,
244,7,91,4,0,
244,7,92,4,0,
244,7,102,3,0,
244,7,104,4,0,
244,7,115,4,0,
244,7,126,1,71,
244,7,126,4,0,
244,7,148,4,0,
244,7,156,4,0,
244,7,164,3,0,
244,7,182,4,0,
244,7,201,4,0,
244,7,207,1,61,
244,7,216,4,0,
244,7,218,4,0,
244,7,231,4,0,
244,7,237,4,0,
244,7,240,4,0,
244,7,241,4,0,
244,7,249,4,0,
244,7,263,4,0,
244,7,290,4,0,
244,7,347,1,81,
244,7,347,4,0,
244,8,15,4,0,
244,8,23,1,29,
244,8,43,1,1,2
244,8,44,1,1,1
244,8,46,1,15,
244,8,46,4,0,
244,8,52,1,8,
244,8,53,1,36,
244,8,53,4,0,
244,8,63,4,0,
244,8,70,4,0,
244,8,76,4,0,
244,8,83,1,22,
244,8,91,4,0,
244,8,92,4,0,
244,8,104,4,0,
244,8,115,4,0,
244,8,126,1,71,
244,8,126,4,0,
244,8,148,4,0,
244,8,156,4,0,
244,8,164,4,0,
244,8,182,4,0,
244,8,201,4,0,
244,8,203,4,0,
244,8,207,1,43,
244,8,207,4,0,
244,8,214,4,0,
244,8,216,4,0,
244,8,218,4,0,
244,8,231,4,0,
244,8,237,4,0,
244,8,240,4,0,
244,8,241,4,0,
244,8,244,4,0,
244,8,247,4,0,
244,8,249,4,0,
244,8,261,4,0,
244,8,263,4,0,
244,8,290,4,0,
244,8,315,4,0,
244,8,326,1,64,
244,8,347,1,78,
244,8,347,4,0,
244,8,363,4,0,
244,8,416,4,0,
244,8,424,1,50,
244,8,431,4,0,
244,8,436,1,57,
244,8,444,4,0,
244,9,15,4,0,
244,9,23,1,29,
244,9,43,1,1,2
244,9,44,1,1,1
244,9,46,1,15,
244,9,46,4,0,
244,9,52,1,8,
244,9,53,1,36,
244,9,53,4,0,
244,9,63,4,0,
244,9,70,4,0,
244,9,76,4,0,
244,9,83,1,22,
244,9,91,4,0,
244,9,92,4,0,
244,9,104,4,0,
244,9,115,4,0,
244,9,126,1,71,
244,9,126,4,0,
244,9,129,3,0,
244,9,148,4,0,
244,9,156,4,0,
244,9,164,4,0,
244,9,173,3,0,
244,9,182,4,0,
244,9,189,3,0,
244,9,201,4,0,
244,9,203,4,0,
244,9,207,1,43,
244,9,207,4,0,
244,9,214,4,0,
244,9,216,4,0,
244,9,218,4,0,
244,9,231,4,0,
244,9,237,4,0,
244,9,240,4,0,
244,9,241,4,0,
244,9,244,4,0,
244,9,247,4,0,
244,9,249,4,0,
244,9,257,3,0,
244,9,261,4,0,
244,9,263,4,0,
244,9,284,1,85,
244,9,290,4,0,
244,9,315,4,0,
244,9,326,1,64,
244,9,347,1,78,
244,9,347,4,0,
244,9,363,4,0,
244,9,416,4,0,
244,9,424,1,50,
244,9,431,4,0,
244,9,436,1,57,
244,9,442,3,0,
244,9,444,4,0,
244,10,15,4,0,
244,10,23,1,29,
244,10,29,3,0,
244,10,43,1,1,2
244,10,44,1,1,1
244,10,46,1,15,
244,10,46,4,0,
244,10,52,1,8,
244,10,53,1,36,
244,10,53,4,0,
244,10,63,4,0,
244,10,70,4,0,
244,10,76,4,0,
244,10,83,1,22,
244,10,91,4,0,
244,10,92,4,0,
244,10,104,4,0,
244,10,115,4,0,
244,10,126,1,71,
244,10,126,4,0,
244,10,129,3,0,
244,10,148,4,0,
244,10,156,4,0,
244,10,164,4,0,
244,10,173,3,0,
244,10,182,4,0,
244,10,189,3,0,
244,10,201,4,0,
244,10,203,4,0,
244,10,207,1,43,
244,10,207,4,0,
244,10,214,4,0,
244,10,216,4,0,
244,10,218,4,0,
244,10,231,4,0,
244,10,237,4,0,
244,10,240,4,0,
244,10,241,4,0,
244,10,244,4,0,
244,10,247,4,0,
244,10,249,4,0,
244,10,257,3,0,
244,10,261,4,0,
244,10,263,4,0,
244,10,284,1,85,
244,10,290,4,0,
244,10,315,4,0,
244,10,326,1,64,
244,10,347,1,78,
244,10,347,4,0,
244,10,363,4,0,
244,10,416,4,0,
244,10,424,1,50,
244,10,431,4,0,
244,10,436,1,57,
244,10,442,3,0,
244,10,444,4,0,
244,11,15,4,0,
244,11,23,1,29,
244,11,43,1,1,2
244,11,44,1,1,1
244,11,46,1,15,
244,11,46,4,0,
244,11,52,1,8,
244,11,53,1,36,
244,11,53,4,0,
244,11,63,4,0,
244,11,70,4,0,
244,11,76,4,0,
244,11,83,1,22,
244,11,91,4,0,
244,11,92,4,0,
244,11,104,4,0,
244,11,115,4,0,
244,11,126,1,71,
244,11,126,4,0,
244,11,148,4,0,
244,11,156,4,0,
244,11,164,4,0,
244,11,182,4,0,
244,11,201,4,0,
244,11,207,1,43,
244,11,207,4,0,
244,11,216,4,0,
244,11,218,4,0,
244,11,237,4,0,
244,11,240,4,0,
244,11,241,4,0,
244,11,244,4,0,
244,11,247,4,0,
244,11,249,4,0,
244,11,261,4,0,
244,11,263,4,0,
244,11,284,1,85,
244,11,315,4,0,
244,11,326,1,64,
244,11,347,1,78,
244,11,347,4,0,
244,11,416,4,0,
244,11,424,1,50,
244,11,436,1,57,
244,11,444,4,0,
244,11,488,4,0,
244,11,496,4,0,
244,11,510,4,0,
244,11,511,4,0,
244,11,523,4,0,
244,11,555,4,0,
244,12,15,4,0,
244,12,23,1,41,
244,12,43,1,1,2
244,12,44,1,1,1
244,12,46,1,21,
244,12,46,4,0,
244,12,52,1,11,
244,12,53,1,51,
244,12,53,4,0,
244,12,63,4,0,
244,12,70,4,0,
244,12,76,4,0,
244,12,83,1,31,
244,12,91,4,0,
244,12,92,4,0,
244,12,104,4,0,
244,12,115,4,0,
244,12,126,1,71,
244,12,126,4,0,
244,12,148,4,0,
244,12,156,4,0,
244,12,182,4,0,
244,12,201,4,0,
244,12,207,1,61,
244,12,216,4,0,
244,12,218,4,0,
244,12,231,4,0,
244,12,237,4,0,
244,12,240,4,0,
244,12,241,4,0,
244,12,249,4,0,
244,12,263,4,0,
244,12,290,4,0,
244,12,347,1,81,
244,12,347,4,0,
244,13,15,4,0,
244,13,23,1,41,
244,13,34,3,0,
244,13,38,3,0,
244,13,43,1,1,2
244,13,44,1,1,1
244,13,46,1,21,
244,13,46,4,0,
244,13,52,1,11,
244,13,53,1,51,
244,13,53,4,0,
244,13,63,4,0,
244,13,70,4,0,
244,13,76,4,0,
244,13,83,1,31,
244,13,91,4,0,
244,13,92,4,0,
244,13,102,3,0,
244,13,104,4,0,
244,13,115,4,0,
244,13,126,1,71,
244,13,126,4,0,
244,13,148,4,0,
244,13,156,4,0,
244,13,164,3,0,
244,13,182,4,0,
244,13,201,4,0,
244,13,207,1,61,
244,13,207,3,0,
244,13,216,4,0,
244,13,218,4,0,
244,13,231,4,0,
244,13,237,4,0,
244,13,240,4,0,
244,13,241,4,0,
244,13,249,4,0,
244,13,263,4,0,
244,13,290,4,0,
244,13,347,1,81,
244,13,347,4,0,
244,14,15,4,0,
244,14,23,1,29,
244,14,43,1,1,2
244,14,44,1,1,1
244,14,46,1,15,
244,14,46,4,0,
244,14,52,1,8,
244,14,53,1,36,
244,14,53,4,0,
244,14,63,4,0,
244,14,70,4,0,
244,14,76,4,0,
244,14,83,1,22,
244,14,91,4,0,
244,14,92,4,0,
244,14,104,4,0,
244,14,115,4,0,
244,14,126,1,71,
244,14,126,4,0,
244,14,148,4,0,
244,14,156,4,0,
244,14,164,4,0,
244,14,173,3,0,
244,14,182,4,0,
244,14,201,4,0,
244,14,207,1,43,
244,14,207,4,0,
244,14,214,3,0,
244,14,216,4,0,
244,14,218,4,0,
244,14,231,3,0,
244,14,237,4,0,
244,14,240,4,0,
244,14,241,4,0,
244,14,244,4,0,
244,14,247,4,0,
244,14,249,4,0,
244,14,257,3,0,
244,14,261,4,0,
244,14,263,4,0,
244,14,284,1,85,
244,14,315,4,0,
244,14,326,1,64,
244,14,347,1,78,
244,14,347,4,0,
244,14,416,4,0,
244,14,424,1,50,
244,14,436,1,57,
244,14,442,3,0,
244,14,444,4,0,
244,14,488,4,0,
244,14,496,4,0,
244,14,510,4,0,
244,14,511,4,0,
244,14,523,4,0,
244,14,555,4,0,
244,15,15,4,0,
244,15,23,1,29,
244,15,43,1,1,6
244,15,44,1,1,5
244,15,46,1,15,
244,15,46,4,0,
244,15,52,1,8,
244,15,53,1,36,
244,15,53,4,0,
244,15,63,4,0,
244,15,70,4,0,
244,15,76,4,0,
244,15,83,1,22,
244,15,91,4,0,
244,15,92,4,0,
244,15,104,4,0,
244,15,115,4,0,
244,15,126,1,71,
244,15,126,4,0,
244,15,148,4,0,
244,15,156,4,0,
244,15,164,4,0,
244,15,182,4,0,
244,15,201,4,0,
244,15,207,1,43,
244,15,207,4,0,
244,15,214,4,0,
244,15,216,4,0,
244,15,218,4,0,
244,15,221,1,1,1
244,15,237,4,0,
244,15,240,4,0,
244,15,241,4,0,
244,15,244,4,0,
244,15,247,4,0,
244,15,249,4,0,
244,15,261,4,0,
244,15,263,4,0,
244,15,284,1,1,2
244,15,284,1,85,
244,15,315,4,0,
244,15,326,1,1,3
244,15,326,1,64,
244,15,347,1,78,
244,15,347,4,0,
244,15,416,4,0,
244,15,424,1,50,
244,15,436,1,1,4
244,15,436,1,57,
244,15,444,4,0,
244,15,488,4,0,
244,15,496,4,0,
244,15,510,4,0,
244,15,511,4,0,
244,15,523,4,0,
244,15,555,4,0,
244,15,590,4,0,
244,16,15,4,0,
244,16,23,1,29,1
244,16,43,1,1,6
244,16,44,1,1,5
244,16,46,1,15,1
244,16,46,4,0,
244,16,52,1,8,1
244,16,53,1,36,1
244,16,53,4,0,
244,16,63,4,0,
244,16,70,4,0,
244,16,76,4,0,
244,16,83,1,22,1
244,16,91,4,0,
244,16,92,4,0,
244,16,104,4,0,
244,16,115,4,0,
244,16,126,1,71,1
244,16,126,4,0,
244,16,148,4,0,
244,16,156,4,0,
244,16,164,4,0,
244,16,173,3,0,
244,16,182,4,0,
244,16,201,4,0,
244,16,207,1,43,1
244,16,207,4,0,
244,16,214,4,0,
244,16,216,4,0,
244,16,218,4,0,
244,16,221,1,1,1
244,16,231,3,0,
244,16,237,4,0,
244,16,240,4,0,
244,16,241,4,0,
244,16,244,4,0,
244,16,247,4,0,
244,16,249,4,0,
244,16,257,3,0,
244,16,261,4,0,
244,16,263,4,0,
244,16,284,1,1,2
244,16,284,1,85,1
244,16,290,4,0,
244,16,315,4,0,
244,16,326,1,1,3
244,16,326,1,64,1
244,16,347,1,78,1
244,16,347,4,0,
244,16,416,4,0,
244,16,424,1,50,1
244,16,436,1,1,4
244,16,436,1,57,1
244,16,442,3,0,
244,16,444,4,0,
244,16,488,4,0,
244,16,496,4,0,
244,16,510,4,0,
244,16,511,4,0,
244,16,523,4,0,
244,16,555,4,0,
244,16,590,4,0,
244,17,23,1,29,
244,17,43,1,1,6
244,17,44,1,1,5
244,17,46,1,15,
244,17,46,4,0,
244,17,52,1,8,
244,17,53,1,36,
244,17,53,4,0,
244,17,63,4,0,
244,17,76,4,0,
244,17,83,1,22,
244,17,92,4,0,
244,17,104,4,0,
244,17,115,4,0,
244,17,126,1,71,
244,17,126,4,0,
244,17,156,4,0,
244,17,164,4,0,
244,17,182,4,0,
244,17,201,4,0,
244,17,207,1,43,
244,17,207,4,0,
244,17,214,4,0,
244,17,216,4,0,
244,17,218,4,0,
244,17,221,1,1,1
244,17,237,4,0,
244,17,240,4,0,
244,17,241,4,0,
244,17,244,4,0,
244,17,247,4,0,
244,17,261,4,0,
244,17,263,4,0,
244,17,284,1,1,2
244,17,284,1,85,
244,17,315,4,0,
244,17,326,1,1,3
244,17,326,1,64,
244,17,347,1,78,
244,17,347,4,0,
244,17,416,4,0,
244,17,424,1,50,
244,17,436,1,1,4
244,17,436,1,57,
244,17,444,4,0,
244,17,488,4,0,
244,17,496,4,0,
244,17,511,4,0,
244,17,523,4,0,
244,17,555,4,0,
244,17,590,4,0,
244,18,23,1,29,
244,18,43,1,1,6
244,18,44,1,1,5
244,18,46,1,15,
244,18,46,4,0,
244,18,52,1,8,
244,18,53,1,36,
244,18,53,4,0,
244,18,63,4,0,
244,18,76,4,0,
244,18,83,1,22,
244,18,92,4,0,
244,18,104,4,0,
244,18,115,4,0,
244,18,126,1,71,
244,18,126,4,0,
244,18,156,4,0,
244,18,164,4,0,
244,18,182,4,0,
244,18,201,4,0,
244,18,207,1,43,
244,18,207,4,0,
244,18,214,4,0,
244,18,216,4,0,
244,18,218,4,0,
244,18,221,1,1,1
244,18,237,4,0,
244,18,240,4,0,
244,18,241,4,0,
244,18,244,4,0,
244,18,247,4,0,
244,18,261,4,0,
244,18,263,4,0,
244,18,284,1,1,2
244,18,284,1,85,
244,18,315,4,0,
244,18,326,1,1,3
244,18,326,1,64,
244,18,347,1,78,
244,18,347,4,0,
244,18,416,4,0,
244,18,424,1,50,
244,18,436,1,1,4
244,18,436,1,57,
244,18,444,4,0,
244,18,488,4,0,
244,18,496,4,0,
244,18,511,4,0,
244,18,523,4,0,
244,18,555,4,0,
244,18,590,4,0,
245,3,15,4,0,
245,3,16,1,31,
245,3,29,4,0,
245,3,43,1,1,2
245,3,44,1,1,1
245,3,46,1,21,
245,3,46,4,0,
245,3,54,1,51,
245,3,55,1,11,
245,3,56,1,71,
245,3,57,4,0,
245,3,59,4,0,
245,3,61,1,41,
245,3,63,4,0,
245,3,91,4,0,
245,3,92,4,0,
245,3,104,4,0,
245,3,127,4,0,
245,3,129,4,0,
245,3,156,4,0,
245,3,173,4,0,
245,3,174,4,0,
245,3,182,4,0,
245,3,189,4,0,
245,3,196,4,0,
245,3,197,4,0,
245,3,201,4,0,
245,3,203,4,0,
245,3,207,4,0,
245,3,214,4,0,
245,3,216,4,0,
245,3,218,4,0,
245,3,231,4,0,
245,3,237,4,0,
245,3,240,4,0,
245,3,241,4,0,
245,3,243,1,61,
245,3,244,4,0,
245,3,249,4,0,
245,3,250,4,0,
245,4,15,4,0,
245,4,16,1,31,
245,4,29,4,0,
245,4,43,1,1,2
245,4,44,1,1,1
245,4,46,4,0,
245,4,54,1,51,
245,4,56,1,71,
245,4,57,4,0,
245,4,58,3,0,
245,4,59,4,0,
245,4,61,1,11,
245,4,62,1,41,
245,4,63,4,0,
245,4,91,4,0,
245,4,92,4,0,
245,4,104,4,0,
245,4,127,4,0,
245,4,129,4,0,
245,4,156,4,0,
245,4,173,4,0,
245,4,174,4,0,
245,4,182,4,0,
245,4,189,4,0,
245,4,196,4,0,
245,4,197,4,0,
245,4,201,4,0,
245,4,203,4,0,
245,4,207,4,0,
245,4,214,4,0,
245,4,216,4,0,
245,4,218,4,0,
245,4,231,4,0,
245,4,237,4,0,
245,4,240,1,21,
245,4,240,4,0,
245,4,241,4,0,
245,4,243,1,61,
245,4,244,4,0,
245,4,249,4,0,
245,4,250,4,0,
245,5,15,4,0,
245,5,16,1,31,
245,5,43,1,1,2
245,5,44,1,1,1
245,5,46,4,0,
245,5,54,1,51,
245,5,56,1,71,
245,5,57,4,0,
245,5,58,4,0,
245,5,59,4,0,
245,5,61,1,11,
245,5,62,1,41,
245,5,63,4,0,
245,5,91,4,0,
245,5,92,4,0,
245,5,104,4,0,
245,5,115,4,0,
245,5,127,4,0,
245,5,156,4,0,
245,5,182,4,0,
245,5,201,4,0,
245,5,216,4,0,
245,5,218,4,0,
245,5,231,4,0,
245,5,237,4,0,
245,5,240,1,21,
245,5,240,4,0,
245,5,241,4,0,
245,5,243,1,61,
245,5,249,4,0,
245,5,258,4,0,
245,5,263,4,0,
245,5,290,4,0,
245,5,291,4,0,
245,5,347,1,81,
245,5,347,4,0,
245,5,352,4,0,
245,6,15,4,0,
245,6,16,1,31,
245,6,34,3,0,
245,6,38,3,0,
245,6,43,1,1,2
245,6,44,1,1,1
245,6,46,4,0,
245,6,54,1,51,
245,6,56,1,71,
245,6,57,4,0,
245,6,58,4,0,
245,6,59,4,0,
245,6,61,1,11,
245,6,62,1,41,
245,6,63,4,0,
245,6,91,4,0,
245,6,92,4,0,
245,6,102,3,0,
245,6,104,4,0,
245,6,115,4,0,
245,6,127,4,0,
245,6,129,3,0,
245,6,156,4,0,
245,6,164,3,0,
245,6,173,3,0,
245,6,182,4,0,
245,6,189,3,0,
245,6,196,3,0,
245,6,201,4,0,
245,6,203,3,0,
245,6,207,3,0,
245,6,214,3,0,
245,6,216,4,0,
245,6,218,4,0,
245,6,231,4,0,
245,6,237,4,0,
245,6,240,1,21,
245,6,240,4,0,
245,6,241,4,0,
245,6,243,1,61,
245,6,244,3,0,
245,6,249,4,0,
245,6,258,4,0,
245,6,263,4,0,
245,6,290,4,0,
245,6,291,4,0,
245,6,347,1,81,
245,6,347,4,0,
245,6,352,4,0,
245,7,15,4,0,
245,7,16,1,31,
245,7,34,3,0,
245,7,38,3,0,
245,7,43,1,1,2
245,7,44,1,1,1
245,7,46,4,0,
245,7,54,1,51,
245,7,56,1,71,
245,7,57,4,0,
245,7,58,4,0,
245,7,59,4,0,
245,7,61,1,11,
245,7,62,1,41,
245,7,63,4,0,
245,7,91,4,0,
245,7,92,4,0,
245,7,102,3,0,
245,7,104,4,0,
245,7,115,4,0,
245,7,127,4,0,
245,7,156,4,0,
245,7,164,3,0,
245,7,182,4,0,
245,7,201,4,0,
245,7,216,4,0,
245,7,218,4,0,
245,7,231,4,0,
245,7,237,4,0,
245,7,240,1,21,
245,7,240,4,0,
245,7,241,4,0,
245,7,243,1,61,
245,7,249,4,0,
245,7,258,4,0,
245,7,263,4,0,
245,7,290,4,0,
245,7,291,4,0,
245,7,347,1,81,
245,7,347,4,0,
245,7,352,4,0,
245,8,15,4,0,
245,8,16,1,22,
245,8,43,1,1,2
245,8,44,1,1,1
245,8,46,4,0,
245,8,54,1,36,
245,8,56,1,71,
245,8,57,4,0,
245,8,58,4,0,
245,8,59,4,0,
245,8,61,1,8,
245,8,62,1,29,
245,8,63,4,0,
245,8,91,4,0,
245,8,92,4,0,
245,8,104,4,0,
245,8,115,4,0,
245,8,127,4,0,
245,8,156,4,0,
245,8,164,4,0,
245,8,182,4,0,
245,8,201,4,0,
245,8,203,4,0,
245,8,207,4,0,
245,8,214,4,0,
245,8,216,4,0,
245,8,218,4,0,
245,8,231,4,0,
245,8,237,4,0,
245,8,240,1,15,
245,8,240,4,0,
245,8,241,4,0,
245,8,243,1,43,
245,8,244,4,0,
245,8,247,4,0,
245,8,249,4,0,
245,8,258,4,0,
245,8,263,4,0,
245,8,290,4,0,
245,8,326,1,64,
245,8,347,1,78,
245,8,347,4,0,
245,8,352,4,0,
245,8,362,4,0,
245,8,363,4,0,
245,8,366,1,57,
245,8,416,4,0,
245,8,419,4,0,
245,8,423,1,50,
245,8,431,4,0,
245,9,15,4,0,
245,9,16,1,22,
245,9,43,1,1,2
245,9,44,1,1,1
245,9,46,4,0,
245,9,54,1,36,
245,9,56,1,71,
245,9,57,4,0,
245,9,58,4,0,
245,9,59,1,85,
245,9,59,4,0,
245,9,61,1,8,
245,9,62,1,29,
245,9,63,4,0,
245,9,91,4,0,
245,9,92,4,0,
245,9,104,4,0,
245,9,115,4,0,
245,9,127,4,0,
245,9,129,3,0,
245,9,156,4,0,
245,9,164,4,0,
245,9,173,3,0,
245,9,182,4,0,
245,9,189,3,0,
245,9,196,3,0,
245,9,201,4,0,
245,9,203,4,0,
245,9,207,4,0,
245,9,214,4,0,
245,9,216,4,0,
245,9,218,4,0,
245,9,231,4,0,
245,9,237,4,0,
245,9,240,1,15,
245,9,240,4,0,
245,9,241,4,0,
245,9,243,1,43,
245,9,244,4,0,
245,9,247,4,0,
245,9,249,4,0,
245,9,258,4,0,
245,9,263,4,0,
245,9,290,4,0,
245,9,291,3,0,
245,9,324,3,0,
245,9,326,1,64,
245,9,347,1,78,
245,9,347,4,0,
245,9,352,4,0,
245,9,362,4,0,
245,9,363,4,0,
245,9,366,1,57,
245,9,416,4,0,
245,9,419,4,0,
245,9,423,1,50,
245,9,431,4,0,
245,9,442,3,0,
245,9,466,3,0,
245,10,15,4,0,
245,10,16,1,22,
245,10,29,3,0,
245,10,43,1,1,2
245,10,44,1,1,1
245,10,46,4,0,
245,10,54,1,36,
245,10,56,1,71,
245,10,57,4,0,
245,10,58,4,0,
245,10,59,1,85,
245,10,59,4,0,
245,10,61,1,8,
245,10,62,1,29,
245,10,63,4,0,
245,10,91,4,0,
245,10,92,4,0,
245,10,104,4,0,
245,10,115,4,0,
245,10,127,4,0,
245,10,129,3,0,
245,10,156,4,0,
245,10,164,4,0,
245,10,173,3,0,
245,10,182,4,0,
245,10,189,3,0,
245,10,196,3,0,
245,10,201,4,0,
245,10,203,4,0,
245,10,207,4,0,
245,10,214,4,0,
245,10,216,4,0,
245,10,218,4,0,
245,10,231,4,0,
245,10,237,4,0,
245,10,240,1,15,
245,10,240,4,0,
245,10,241,4,0,
245,10,243,1,43,
245,10,244,4,0,
245,10,247,4,0,
245,10,249,4,0,
245,10,250,4,0,
245,10,258,4,0,
245,10,263,4,0,
245,10,290,4,0,
245,10,291,3,0,
245,10,324,3,0,
245,10,326,1,64,
245,10,347,1,78,
245,10,347,4,0,
245,10,352,4,0,
245,10,362,4,0,
245,10,363,4,0,
245,10,366,1,57,
245,10,366,3,0,
245,10,416,4,0,
245,10,419,4,0,
245,10,423,1,50,
245,10,431,4,0,
245,10,442,3,0,
245,10,466,3,0,
245,11,15,4,0,
245,11,16,1,22,
245,11,43,1,1,2
245,11,44,1,1,1
245,11,46,4,0,
245,11,54,1,36,
245,11,56,1,71,
245,11,57,4,0,
245,11,58,4,0,
245,11,59,1,85,
245,11,59,4,0,
245,11,61,1,8,
245,11,62,1,29,
245,11,63,4,0,
245,11,91,4,0,
245,11,92,4,0,
245,11,104,4,0,
245,11,115,4,0,
245,11,127,4,0,
245,11,156,4,0,
245,11,164,4,0,
245,11,182,4,0,
245,11,201,4,0,
245,11,207,4,0,
245,11,216,4,0,
245,11,218,4,0,
245,11,237,4,0,
245,11,240,1,15,
245,11,240,4,0,
245,11,241,4,0,
245,11,243,1,43,
245,11,244,4,0,
245,11,247,4,0,
245,11,249,4,0,
245,11,258,4,0,
245,11,263,4,0,
245,11,291,4,0,
245,11,326,1,64,
245,11,347,1,78,
245,11,347,4,0,
245,11,366,1,57,
245,11,416,4,0,
245,11,423,1,50,
245,11,496,4,0,
245,11,503,4,0,
245,11,511,4,0,
245,11,523,4,0,
245,11,555,4,0,
245,12,15,4,0,
245,12,16,1,31,
245,12,43,1,1,2
245,12,44,1,1,1
245,12,46,4,0,
245,12,54,1,51,
245,12,56,1,71,
245,12,57,4,0,
245,12,58,4,0,
245,12,59,4,0,
245,12,61,1,11,
245,12,62,1,41,
245,12,63,4,0,
245,12,91,4,0,
245,12,92,4,0,
245,12,104,4,0,
245,12,115,4,0,
245,12,127,4,0,
245,12,156,4,0,
245,12,182,4,0,
245,12,201,4,0,
245,12,216,4,0,
245,12,218,4,0,
245,12,231,4,0,
245,12,237,4,0,
245,12,240,1,21,
245,12,240,4,0,
245,12,241,4,0,
245,12,243,1,61,
245,12,249,4,0,
245,12,258,4,0,
245,12,263,4,0,
245,12,290,4,0,
245,12,291,4,0,
245,12,347,1,81,
245,12,347,4,0,
245,12,352,4,0,
245,13,15,4,0,
245,13,16,1,31,
245,13,34,3,0,
245,13,38,3,0,
245,13,43,1,1,2
245,13,44,1,1,1
245,13,46,4,0,
245,13,54,1,51,
245,13,56,1,71,
245,13,57,4,0,
245,13,58,4,0,
245,13,59,4,0,
245,13,61,1,11,
245,13,62,1,41,
245,13,63,4,0,
245,13,91,4,0,
245,13,92,4,0,
245,13,102,3,0,
245,13,104,4,0,
245,13,115,4,0,
245,13,127,4,0,
245,13,156,4,0,
245,13,164,3,0,
245,13,182,4,0,
245,13,196,3,0,
245,13,201,4,0,
245,13,207,3,0,
245,13,216,4,0,
245,13,218,4,0,
245,13,231,4,0,
245,13,237,4,0,
245,13,240,1,21,
245,13,240,4,0,
245,13,241,4,0,
245,13,243,1,61,
245,13,249,4,0,
245,13,258,4,0,
245,13,263,4,0,
245,13,290,4,0,
245,13,291,4,0,
245,13,347,1,81,
245,13,347,4,0,
245,13,352,4,0,
245,14,15,4,0,
245,14,16,1,22,
245,14,43,1,1,2
245,14,44,1,1,1
245,14,46,4,0,
245,14,54,1,36,
245,14,56,1,71,
245,14,57,4,0,
245,14,58,4,0,
245,14,59,1,85,
245,14,59,4,0,
245,14,61,1,8,
245,14,62,1,29,
245,14,63,4,0,
245,14,91,4,0,
245,14,92,4,0,
245,14,104,4,0,
245,14,115,4,0,
245,14,127,4,0,
245,14,156,4,0,
245,14,164,4,0,
245,14,173,3,0,
245,14,182,4,0,
245,14,196,3,0,
245,14,201,4,0,
245,14,207,4,0,
245,14,214,3,0,
245,14,216,4,0,
245,14,218,4,0,
245,14,231,3,0,
245,14,237,4,0,
245,14,240,1,15,
245,14,240,4,0,
245,14,241,4,0,
245,14,243,1,43,
245,14,244,4,0,
245,14,247,4,0,
245,14,249,4,0,
245,14,258,4,0,
245,14,263,4,0,
245,14,291,4,0,
245,14,324,3,0,
245,14,326,1,64,
245,14,347,1,78,
245,14,347,4,0,
245,14,366,1,57,
245,14,366,3,0,
245,14,416,4,0,
245,14,423,1,50,
245,14,442,3,0,
245,14,496,4,0,
245,14,503,4,0,
245,14,511,4,0,
245,14,523,4,0,
245,14,555,4,0,
245,15,15,4,0,
245,15,16,1,22,
245,15,43,1,1,5
245,15,44,1,1,4
245,15,46,4,0,
245,15,54,1,36,
245,15,56,1,1,1
245,15,56,1,71,
245,15,57,4,0,
245,15,58,4,0,
245,15,59,1,85,
245,15,59,4,0,
245,15,61,1,8,
245,15,62,1,29,
245,15,63,4,0,
245,15,91,4,0,
245,15,92,4,0,
245,15,104,4,0,
245,15,115,4,0,
245,15,127,4,0,
245,15,156,4,0,
245,15,164,4,0,
245,15,182,4,0,
245,15,201,4,0,
245,15,207,4,0,
245,15,214,4,0,
245,15,216,4,0,
245,15,218,4,0,
245,15,237,4,0,
245,15,240,1,15,
245,15,240,4,0,
245,15,241,4,0,
245,15,243,1,43,
245,15,244,4,0,
245,15,247,4,0,
245,15,249,4,0,
245,15,258,4,0,
245,15,263,4,0,
245,15,326,1,1,2
245,15,326,1,64,
245,15,347,1,78,
245,15,347,4,0,
245,15,366,1,1,3
245,15,366,1,57,
245,15,416,4,0,
245,15,423,1,50,
245,15,496,4,0,
245,15,503,4,0,
245,15,511,4,0,
245,15,523,4,0,
245,15,555,4,0,
245,15,590,4,0,
245,16,15,4,0,
245,16,16,1,22,1
245,16,43,1,1,5
245,16,44,1,1,4
245,16,46,4,0,
245,16,54,1,36,1
245,16,56,1,1,1
245,16,56,1,71,1
245,16,57,4,0,
245,16,58,4,0,
245,16,59,1,85,1
245,16,59,4,0,
245,16,61,1,8,1
245,16,62,1,29,1
245,16,63,4,0,
245,16,91,4,0,
245,16,92,4,0,
245,16,104,4,0,
245,16,115,4,0,
245,16,127,4,0,
245,16,156,4,0,
245,16,164,4,0,
245,16,173,3,0,
245,16,182,4,0,
245,16,196,3,0,
245,16,201,4,0,
245,16,207,4,0,
245,16,214,4,0,
245,16,216,4,0,
245,16,218,4,0,
245,16,231,3,0,
245,16,237,4,0,
245,16,240,1,15,1
245,16,240,4,0,
245,16,241,4,0,
245,16,243,1,43,1
245,16,244,4,0,
245,16,247,4,0,
245,16,249,4,0,
245,16,258,4,0,
245,16,263,4,0,
245,16,290,4,0,
245,16,291,4,0,
245,16,324,3,0,
245,16,326,1,1,2
245,16,326,1,64,1
245,16,347,1,78,1
245,16,347,4,0,
245,16,352,3,0,
245,16,366,1,1,3
245,16,366,1,57,1
245,16,366,3,0,
245,16,416,4,0,
245,16,423,1,50,1
245,16,442,3,0,
245,16,496,4,0,
245,16,503,4,0,
245,16,511,4,0,
245,16,523,4,0,
245,16,555,4,0,
245,16,590,4,0,
245,17,16,1,22,
245,17,43,1,1,3
245,17,44,1,1,2
245,17,46,4,0,
245,17,54,1,36,
245,17,56,1,71,
245,17,57,4,0,
245,17,58,4,0,
245,17,59,1,85,
245,17,59,4,0,
245,17,61,1,1,4
245,17,61,1,8,
245,17,62,1,29,
245,17,63,4,0,
245,17,92,4,0,
245,17,104,4,0,
245,17,115,4,0,
245,17,127,4,0,
245,17,156,4,0,
245,17,164,4,0,
245,17,182,4,0,
245,17,201,4,0,
245,17,207,4,0,
245,17,214,4,0,
245,17,216,4,0,
245,17,218,4,0,
245,17,237,4,0,
245,17,240,1,1,5
245,17,240,1,15,
245,17,240,4,0,
245,17,241,4,0,
245,17,243,1,43,
245,17,244,4,0,
245,17,247,4,0,
245,17,258,4,0,
245,17,263,4,0,
245,17,326,1,64,
245,17,329,1,1,1
245,17,347,1,78,
245,17,347,4,0,
245,17,366,1,57,
245,17,416,4,0,
245,17,423,1,50,
245,17,496,4,0,
245,17,503,4,0,
245,17,511,4,0,
245,17,523,4,0,
245,17,555,4,0,
245,17,590,4,0,
245,18,16,1,22,
245,18,43,1,1,3
245,18,44,1,1,2
245,18,46,4,0,
245,18,54,1,36,
245,18,56,1,71,
245,18,57,4,0,
245,18,58,4,0,
245,18,59,1,85,
245,18,59,4,0,
245,18,61,1,1,4
245,18,61,1,8,
245,18,62,1,29,
245,18,63,4,0,
245,18,92,4,0,
245,18,104,4,0,
245,18,115,4,0,
245,18,127,4,0,
245,18,156,4,0,
245,18,164,4,0,
245,18,182,4,0,
245,18,201,4,0,
245,18,207,4,0,
245,18,214,4,0,
245,18,216,4,0,
245,18,218,4,0,
245,18,237,4,0,
245,18,240,1,1,5
245,18,240,1,15,
245,18,240,4,0,
245,18,241,4,0,
245,18,243,1,43,
245,18,244,4,0,
245,18,247,4,0,
245,18,258,4,0,
245,18,263,4,0,
245,18,326,1,64,
245,18,329,1,1,1
245,18,347,1,78,
245,18,347,4,0,
245,18,366,1,57,
245,18,416,4,0,
245,18,423,1,50,
245,18,496,4,0,
245,18,503,4,0,
245,18,511,4,0,
245,18,523,4,0,
245,18,555,4,0,
245,18,590,4,0,
246,3,23,2,0,
246,3,29,4,0,
246,3,37,1,29,
246,3,43,1,1,2
246,3,44,1,1,1
246,3,63,1,57,
246,3,63,4,0,
246,3,89,1,50,
246,3,89,4,0,
246,3,91,4,0,
246,3,92,4,0,
246,3,103,1,15,
246,3,104,4,0,
246,3,116,2,0,
246,3,156,4,0,
246,3,157,1,22,
246,3,173,4,0,
246,3,174,4,0,
246,3,182,4,0,
246,3,184,1,36,
246,3,189,4,0,
246,3,197,4,0,
246,3,200,2,0,
246,3,201,1,8,
246,3,201,4,0,
246,3,203,4,0,
246,3,207,4,0,
246,3,213,4,0,
246,3,214,4,0,
246,3,216,4,0,
246,3,218,4,0,
246,3,228,2,0,
246,3,237,4,0,
246,3,240,4,0,
246,3,241,4,0,
246,3,242,1,43,
246,3,246,2,0,
246,4,23,2,0,
246,4,29,4,0,
246,4,37,1,29,
246,4,43,1,1,2
246,4,44,1,1,1
246,4,63,1,57,
246,4,63,4,0,
246,4,89,1,50,
246,4,89,4,0,
246,4,91,4,0,
246,4,92,4,0,
246,4,103,1,15,
246,4,104,4,0,
246,4,116,2,0,
246,4,156,4,0,
246,4,157,1,22,
246,4,173,4,0,
246,4,174,4,0,
246,4,182,4,0,
246,4,184,1,36,
246,4,189,4,0,
246,4,197,4,0,
246,4,200,2,0,
246,4,201,1,8,
246,4,201,4,0,
246,4,203,4,0,
246,4,207,4,0,
246,4,213,4,0,
246,4,214,4,0,
246,4,216,4,0,
246,4,218,4,0,
246,4,228,2,0,
246,4,237,4,0,
246,4,240,4,0,
246,4,241,4,0,
246,4,242,1,43,
246,4,246,2,0,
246,5,23,2,0,
246,5,37,1,29,
246,5,43,1,1,2
246,5,44,1,1,1
246,5,63,1,57,
246,5,63,4,0,
246,5,89,1,50,
246,5,89,4,0,
246,5,91,4,0,
246,5,92,4,0,
246,5,103,1,15,
246,5,104,4,0,
246,5,116,2,0,
246,5,156,4,0,
246,5,157,1,22,
246,5,174,2,0,
246,5,182,4,0,
246,5,184,1,36,
246,5,200,2,0,
246,5,201,1,8,
246,5,201,4,0,
246,5,213,4,0,
246,5,216,4,0,
246,5,218,4,0,
246,5,228,2,0,
246,5,237,4,0,
246,5,240,4,0,
246,5,241,4,0,
246,5,242,1,43,
246,5,246,2,0,
246,5,249,4,0,
246,5,259,4,0,
246,5,263,4,0,
246,5,269,4,0,
246,5,280,4,0,
246,5,290,4,0,
246,5,349,2,0,
246,6,23,2,0,
246,6,34,3,0,
246,6,37,1,29,
246,6,38,3,0,
246,6,43,1,1,2
246,6,44,1,1,1
246,6,63,1,57,
246,6,63,4,0,
246,6,89,1,50,
246,6,89,4,0,
246,6,91,4,0,
246,6,92,4,0,
246,6,102,3,0,
246,6,103,1,15,
246,6,104,4,0,
246,6,116,2,0,
246,6,156,4,0,
246,6,157,1,22,
246,6,157,3,0,
246,6,164,3,0,
246,6,173,3,0,
246,6,174,2,0,
246,6,182,4,0,
246,6,184,1,36,
246,6,189,3,0,
246,6,200,2,0,
246,6,201,1,8,
246,6,201,4,0,
246,6,203,3,0,
246,6,207,3,0,
246,6,213,4,0,
246,6,214,3,0,
246,6,216,4,0,
246,6,218,4,0,
246,6,228,2,0,
246,6,237,4,0,
246,6,240,4,0,
246,6,241,4,0,
246,6,242,1,43,
246,6,246,2,0,
246,6,249,4,0,
246,6,259,4,0,
246,6,263,4,0,
246,6,269,4,0,
246,6,280,4,0,
246,6,290,4,0,
246,6,349,2,0,
246,7,23,2,0,
246,7,34,3,0,
246,7,37,1,29,
246,7,38,3,0,
246,7,43,1,1,2
246,7,44,1,1,1
246,7,63,1,57,
246,7,63,4,0,
246,7,89,1,50,
246,7,89,4,0,
246,7,91,4,0,
246,7,92,4,0,
246,7,102,3,0,
246,7,103,1,15,
246,7,104,4,0,
246,7,116,2,0,
246,7,156,4,0,
246,7,157,1,22,
246,7,157,3,0,
246,7,164,3,0,
246,7,174,2,0,
246,7,182,4,0,
246,7,184,1,36,
246,7,200,2,0,
246,7,201,1,8,
246,7,201,4,0,
246,7,213,4,0,
246,7,216,4,0,
246,7,218,4,0,
246,7,228,2,0,
246,7,237,4,0,
246,7,240,4,0,
246,7,241,4,0,
246,7,242,1,43,
246,7,246,2,0,
246,7,249,4,0,
246,7,259,4,0,
246,7,263,4,0,
246,7,269,4,0,
246,7,280,4,0,
246,7,290,4,0,
246,7,349,2,0,
246,8,23,2,0,
246,8,37,1,23,
246,8,43,1,1,2
246,8,44,1,1,1
246,8,63,1,50,
246,8,63,4,0,
246,8,89,1,41,
246,8,89,4,0,
246,8,91,4,0,
246,8,92,4,0,
246,8,103,1,10,
246,8,104,4,0,
246,8,116,2,0,
246,8,156,4,0,
246,8,157,1,14,
246,8,157,4,0,
246,8,164,4,0,
246,8,174,2,0,
246,8,182,4,0,
246,8,184,1,19,
246,8,200,2,0,
246,8,201,1,5,
246,8,201,4,0,
246,8,203,4,0,
246,8,207,4,0,
246,8,213,4,0,
246,8,214,4,0,
246,8,216,4,0,
246,8,218,4,0,
246,8,228,2,0,
246,8,237,4,0,
246,8,240,4,0,
246,8,241,4,0,
246,8,242,1,37,
246,8,246,2,0,
246,8,249,4,0,
246,8,259,4,0,
246,8,263,4,0,
246,8,269,4,0,
246,8,280,4,0,
246,8,290,4,0,
246,8,317,4,0,
246,8,334,2,0,
246,8,349,2,0,
246,8,363,4,0,
246,8,371,1,32,
246,8,371,4,0,
246,8,372,2,0,
246,8,397,4,0,
246,8,399,1,28,
246,8,399,4,0,
246,8,442,2,0,
246,8,444,1,46,
246,8,444,4,0,
246,8,445,4,0,
246,8,446,4,0,
246,9,23,2,0,
246,9,37,1,23,
246,9,43,1,1,2
246,9,44,1,1,1
246,9,63,1,50,
246,9,63,4,0,
246,9,89,1,41,
246,9,89,4,0,
246,9,91,4,0,
246,9,92,4,0,
246,9,103,1,10,
246,9,104,4,0,
246,9,116,2,0,
246,9,156,4,0,
246,9,157,1,14,
246,9,157,4,0,
246,9,164,4,0,
246,9,173,3,0,
246,9,174,2,0,
246,9,180,3,0,
246,9,182,4,0,
246,9,184,1,19,
246,9,189,3,0,
246,9,200,2,0,
246,9,201,1,5,
246,9,201,4,0,
246,9,203,4,0,
246,9,207,4,0,
246,9,213,4,0,
246,9,214,4,0,
246,9,216,4,0,
246,9,218,4,0,
246,9,228,2,0,
246,9,237,4,0,
246,9,240,4,0,
246,9,241,4,0,
246,9,242,1,37,
246,9,246,2,0,
246,9,246,3,0,
246,9,249,4,0,
246,9,253,3,0,
246,9,259,4,0,
246,9,263,4,0,
246,9,269,4,0,
246,9,276,3,0,
246,9,280,4,0,
246,9,290,4,0,
246,9,317,4,0,
246,9,334,2,0,
246,9,349,2,0,
246,9,363,4,0,
246,9,371,1,32,
246,9,371,4,0,
246,9,372,2,0,
246,9,397,4,0,
246,9,399,1,28,
246,9,399,4,0,
246,9,414,3,0,
246,9,442,2,0,
246,9,442,3,0,
246,9,444,1,46,
246,9,444,4,0,
246,9,445,4,0,
246,9,446,4,0,
246,10,23,2,0,
246,10,29,3,0,
246,10,37,1,23,
246,10,43,1,1,2
246,10,44,1,1,1
246,10,63,1,50,
246,10,63,4,0,
246,10,89,1,41,
246,10,89,4,0,
246,10,91,4,0,
246,10,92,4,0,
246,10,103,1,10,
246,10,104,4,0,
246,10,116,2,0,
246,10,156,4,0,
246,10,157,1,14,
246,10,157,4,0,
246,10,164,4,0,
246,10,173,3,0,
246,10,174,2,0,
246,10,180,3,0,
246,10,182,4,0,
246,10,184,1,19,
246,10,189,3,0,
246,10,200,2,0,
246,10,201,1,5,
246,10,201,4,0,
246,10,203,4,0,
246,10,207,4,0,
246,10,213,4,0,
246,10,214,4,0,
246,10,216,4,0,
246,10,218,4,0,
246,10,228,2,0,
246,10,237,4,0,
246,10,240,4,0,
246,10,241,4,0,
246,10,242,1,37,
246,10,246,2,0,
246,10,246,3,0,
246,10,249,4,0,
246,10,253,3,0,
246,10,259,4,0,
246,10,263,4,0,
246,10,269,4,0,
246,10,276,3,0,
246,10,280,4,0,
246,10,290,4,0,
246,10,317,4,0,
246,10,334,2,0,
246,10,349,2,0,
246,10,363,4,0,
246,10,371,1,32,
246,10,371,4,0,
246,10,372,2,0,
246,10,397,4,0,
246,10,399,1,28,
246,10,399,4,0,
246,10,414,3,0,
246,10,442,2,0,
246,10,442,3,0,
246,10,444,1,46,
246,10,444,4,0,
246,10,445,4,0,
246,10,446,4,0,
246,11,23,2,0,
246,11,37,1,28,
246,11,43,1,1,2
246,11,44,1,1,1
246,11,63,1,55,
246,11,63,4,0,
246,11,89,1,46,
246,11,89,4,0,
246,11,91,4,0,
246,11,92,4,0,
246,11,103,1,10,
246,11,104,4,0,
246,11,116,2,0,
246,11,156,4,0,
246,11,157,1,19,
246,11,157,4,0,
246,11,164,4,0,
246,11,174,2,0,
246,11,182,4,0,
246,11,184,1,23,
246,11,200,2,0,
246,11,201,1,5,
246,11,201,4,0,
246,11,207,4,0,
246,11,213,4,0,
246,11,216,4,0,
246,11,218,4,0,
246,11,228,2,0,
246,11,231,2,0,
246,11,237,4,0,
246,11,240,4,0,
246,11,241,4,0,
246,11,242,1,41,
246,11,246,2,0,
246,11,249,4,0,
246,11,259,4,0,
246,11,263,4,0,
246,11,269,4,0,
246,11,280,4,0,
246,11,317,4,0,
246,11,334,2,0,
246,11,349,2,0,
246,11,371,1,37,
246,11,371,4,0,
246,11,372,2,0,
246,11,397,4,0,
246,11,399,1,32,
246,11,442,2,0,
246,11,444,1,50,
246,11,444,4,0,
246,11,446,2,0,
246,11,479,4,0,
246,11,496,4,0,
246,11,498,1,14,
246,11,514,4,0,
246,11,523,4,0,
246,11,555,4,0,
246,12,37,1,29,
246,12,43,1,1,2
246,12,44,1,1,1
246,12,63,1,57,
246,12,63,4,0,
246,12,89,1,50,
246,12,89,4,0,
246,12,91,4,0,
246,12,92,4,0,
246,12,103,1,15,
246,12,104,4,0,
246,12,156,4,0,
246,12,157,1,22,
246,12,182,4,0,
246,12,184,1,36,
246,12,201,1,8,
246,12,201,4,0,
246,12,213,4,0,
246,12,216,4,0,
246,12,218,4,0,
246,12,237,4,0,
246,12,240,4,0,
246,12,241,4,0,
246,12,242,1,43,
246,12,249,4,0,
246,12,259,4,0,
246,12,263,4,0,
246,12,269,4,0,
246,12,280,4,0,
246,12,290,4,0,
246,13,34,3,0,
246,13,37,1,29,
246,13,38,3,0,
246,13,43,1,1,2
246,13,44,1,1,1
246,13,63,1,57,
246,13,63,4,0,
246,13,89,1,50,
246,13,89,4,0,
246,13,91,4,0,
246,13,92,4,0,
246,13,102,3,0,
246,13,103,1,15,
246,13,104,4,0,
246,13,156,4,0,
246,13,157,1,22,
246,13,164,3,0,
246,13,182,4,0,
246,13,184,1,36,
246,13,201,1,8,
246,13,201,4,0,
246,13,207,3,0,
246,13,213,4,0,
246,13,216,4,0,
246,13,218,4,0,
246,13,237,4,0,
246,13,240,4,0,
246,13,241,4,0,
246,13,242,1,43,
246,13,249,4,0,
246,13,259,4,0,
246,13,263,4,0,
246,13,269,4,0,
246,13,280,4,0,
246,13,290,4,0,
246,14,23,2,0,
246,14,37,1,28,
246,14,43,1,1,2
246,14,44,1,1,1
246,14,63,1,55,
246,14,63,4,0,
246,14,89,1,46,
246,14,89,4,0,
246,14,91,4,0,
246,14,92,4,0,
246,14,103,1,10,
246,14,104,4,0,
246,14,116,2,0,
246,14,156,4,0,
246,14,157,1,19,
246,14,157,4,0,
246,14,164,4,0,
246,14,173,3,0,
246,14,174,2,0,
246,14,180,3,0,
246,14,182,4,0,
246,14,184,1,23,
246,14,200,2,0,
246,14,200,3,0,
246,14,201,1,5,
246,14,201,4,0,
246,14,207,4,0,
246,14,213,4,0,
246,14,214,3,0,
246,14,216,4,0,
246,14,218,4,0,
246,14,228,2,0,
246,14,231,2,0,
246,14,231,3,0,
246,14,237,4,0,
246,14,240,4,0,
246,14,241,4,0,
246,14,242,1,41,
246,14,246,2,0,
246,14,249,4,0,
246,14,253,3,0,
246,14,259,4,0,
246,14,263,4,0,
246,14,269,4,0,
246,14,276,3,0,
246,14,280,4,0,
246,14,317,4,0,
246,14,334,2,0,
246,14,334,3,0,
246,14,349,2,0,
246,14,371,1,37,
246,14,371,4,0,
246,14,372,2,0,
246,14,397,4,0,
246,14,399,1,32,
246,14,399,3,0,
246,14,414,3,0,
246,14,442,2,0,
246,14,442,3,0,
246,14,444,1,50,
246,14,444,4,0,
246,14,446,2,0,
246,14,446,3,0,
246,14,479,4,0,
246,14,496,4,0,
246,14,498,1,14,
246,14,514,4,0,
246,14,523,4,0,
246,14,555,4,0,
246,15,23,2,0,
246,15,37,1,28,
246,15,43,1,1,2
246,15,44,1,1,1
246,15,63,1,55,
246,15,63,4,0,
246,15,89,1,46,
246,15,89,4,0,
246,15,91,4,0,
246,15,92,4,0,
246,15,103,1,10,
246,15,104,4,0,
246,15,116,2,0,
246,15,156,4,0,
246,15,157,1,19,
246,15,157,4,0,
246,15,164,4,0,
246,15,174,2,0,
246,15,182,4,0,
246,15,184,1,23,
246,15,200,2,0,
246,15,201,1,5,
246,15,201,4,0,
246,15,207,4,0,
246,15,213,4,0,
246,15,214,4,0,
246,15,216,4,0,
246,15,218,4,0,
246,15,228,2,0,
246,15,231,2,0,
246,15,237,4,0,
246,15,240,4,0,
246,15,241,4,0,
246,15,242,1,41,
246,15,246,2,0,
246,15,249,4,0,
246,15,259,4,0,
246,15,263,4,0,
246,15,269,4,0,
246,15,280,4,0,
246,15,317,4,0,
246,15,334,2,0,
246,15,349,2,0,
246,15,371,1,37,
246,15,371,4,0,
246,15,372,2,0,
246,15,397,4,0,
246,15,399,1,32,
246,15,399,4,0,
246,15,442,2,0,
246,15,444,1,50,
246,15,444,4,0,
246,15,446,2,0,
246,15,479,4,0,
246,15,496,4,0,
246,15,498,1,14,
246,15,514,4,0,
246,15,523,4,0,
246,15,555,4,0,
246,15,590,4,0,
246,15,612,4,0,
246,16,23,2,0,
246,16,37,1,28,1
246,16,43,1,1,2
246,16,44,1,1,1
246,16,63,1,55,1
246,16,63,4,0,
246,16,89,1,46,1
246,16,89,4,0,
246,16,91,4,0,
246,16,92,4,0,
246,16,103,1,10,1
246,16,104,4,0,
246,16,116,2,0,
246,16,156,4,0,
246,16,157,1,19,1
246,16,157,4,0,
246,16,164,4,0,
246,16,173,3,0,
246,16,174,2,0,
246,16,180,3,0,
246,16,182,4,0,
246,16,184,1,23,1
246,16,200,2,0,
246,16,200,3,0,
246,16,201,1,5,1
246,16,201,4,0,
246,16,207,4,0,
246,16,213,4,0,
246,16,214,4,0,
246,16,216,4,0,
246,16,218,4,0,
246,16,228,2,0,
246,16,231,2,0,
246,16,231,3,0,
246,16,237,4,0,
246,16,240,4,0,
246,16,241,4,0,
246,16,242,1,41,1
246,16,246,2,0,
246,16,249,4,0,
246,16,253,3,0,
246,16,259,4,0,
246,16,263,4,0,
246,16,269,4,0,
246,16,276,3,0,
246,16,280,4,0,
246,16,290,4,0,
246,16,317,4,0,
246,16,334,2,0,
246,16,334,3,0,
246,16,349,2,0,
246,16,371,1,37,1
246,16,371,4,0,
246,16,372,2,0,
246,16,397,4,0,
246,16,399,1,32,1
246,16,399,4,0,
246,16,414,3,0,
246,16,442,2,0,
246,16,442,3,0,
246,16,444,1,50,1
246,16,444,4,0,
246,16,446,2,0,
246,16,446,3,0,
246,16,479,4,0,
246,16,496,4,0,
246,16,498,1,14,1
246,16,514,4,0,
246,16,523,4,0,
246,16,555,4,0,
246,16,590,4,0,
246,16,612,4,0,
246,17,23,2,0,
246,17,37,1,28,
246,17,43,1,1,2
246,17,44,1,1,1
246,17,63,1,55,
246,17,63,4,0,
246,17,89,1,46,
246,17,89,4,0,
246,17,92,4,0,
246,17,103,1,10,
246,17,104,4,0,
246,17,116,2,0,
246,17,156,4,0,
246,17,157,1,19,
246,17,157,4,0,
246,17,164,4,0,
246,17,174,2,0,
246,17,182,4,0,
246,17,184,1,23,
246,17,200,2,0,
246,17,201,1,5,
246,17,201,4,0,
246,17,207,4,0,
246,17,213,4,0,
246,17,214,4,0,
246,17,216,4,0,
246,17,218,4,0,
246,17,228,2,0,
246,17,231,2,0,
246,17,237,4,0,
246,17,240,4,0,
246,17,241,4,0,
246,17,242,1,41,
246,17,246,2,0,
246,17,259,4,0,
246,17,263,4,0,
246,17,269,4,0,
246,17,280,4,0,
246,17,317,4,0,
246,17,334,2,0,
246,17,349,2,0,
246,17,371,1,37,
246,17,371,4,0,
246,17,372,2,0,
246,17,397,4,0,
246,17,399,1,32,
246,17,399,4,0,
246,17,442,2,0,
246,17,444,1,50,
246,17,444,4,0,
246,17,446,2,0,
246,17,479,4,0,
246,17,496,4,0,
246,17,498,1,14,
246,17,523,4,0,
246,17,555,4,0,
246,17,590,4,0,
246,18,23,2,0,
246,18,37,1,28,
246,18,43,1,1,2
246,18,44,1,1,1
246,18,63,1,55,
246,18,63,4,0,
246,18,89,1,46,
246,18,89,4,0,
246,18,92,4,0,
246,18,103,1,10,
246,18,104,4,0,
246,18,116,2,0,
246,18,156,4,0,
246,18,157,1,19,
246,18,157,4,0,
246,18,164,4,0,
246,18,174,2,0,
246,18,182,4,0,
246,18,184,1,23,
246,18,200,2,0,
246,18,201,1,5,
246,18,201,4,0,
246,18,207,4,0,
246,18,213,4,0,
246,18,214,4,0,
246,18,216,4,0,
246,18,218,4,0,
246,18,228,2,0,
246,18,231,2,0,
246,18,237,4,0,
246,18,240,4,0,
246,18,241,4,0,
246,18,242,1,41,
246,18,246,2,0,
246,18,259,4,0,
246,18,263,4,0,
246,18,269,4,0,
246,18,280,4,0,
246,18,317,4,0,
246,18,334,2,0,
246,18,349,2,0,
246,18,371,1,37,
246,18,371,4,0,
246,18,372,2,0,
246,18,397,4,0,
246,18,399,1,32,
246,18,399,4,0,
246,18,442,2,0,
246,18,444,1,50,
246,18,444,4,0,
246,18,446,2,0,
246,18,479,4,0,
246,18,496,4,0,
246,18,498,1,14,
246,18,523,4,0,
246,18,555,4,0,
246,18,590,4,0,
247,3,29,4,0,
247,3,37,1,29,
247,3,43,1,1,2
247,3,44,1,1,1
247,3,63,1,65,
247,3,63,4,0,
247,3,89,1,56,
247,3,89,4,0,
247,3,91,4,0,
247,3,92,4,0,
247,3,103,1,1,4
247,3,103,1,15,
247,3,104,4,0,
247,3,156,4,0,
247,3,157,1,22,
247,3,173,4,0,
247,3,174,4,0,
247,3,182,4,0,
247,3,184,1,38,
247,3,189,4,0,
247,3,197,4,0,
247,3,201,1,1,3
247,3,201,1,8,
247,3,201,4,0,
247,3,203,4,0,
247,3,207,4,0,
247,3,213,4,0,
247,3,214,4,0,
247,3,216,4,0,
247,3,218,4,0,
247,3,237,4,0,
247,3,240,4,0,
247,3,241,4,0,
247,3,242,1,47,
247,4,29,4,0,
247,4,37,1,29,
247,4,43,1,1,2
247,4,44,1,1,1
247,4,63,1,65,
247,4,63,4,0,
247,4,89,1,56,
247,4,89,4,0,
247,4,91,4,0,
247,4,92,4,0,
247,4,103,1,1,4
247,4,103,1,15,
247,4,104,4,0,
247,4,156,4,0,
247,4,157,1,22,
247,4,173,4,0,
247,4,174,4,0,
247,4,182,4,0,
247,4,184,1,38,
247,4,189,4,0,
247,4,197,4,0,
247,4,201,1,1,3
247,4,201,1,8,
247,4,201,4,0,
247,4,203,4,0,
247,4,207,4,0,
247,4,213,4,0,
247,4,214,4,0,
247,4,216,4,0,
247,4,218,4,0,
247,4,237,4,0,
247,4,240,4,0,
247,4,241,4,0,
247,4,242,1,47,
247,5,37,1,29,
247,5,43,1,1,2
247,5,44,1,1,1
247,5,63,1,65,
247,5,63,4,0,
247,5,89,1,56,
247,5,89,4,0,
247,5,91,4,0,
247,5,92,4,0,
247,5,103,1,1,4
247,5,103,1,15,
247,5,104,4,0,
247,5,156,4,0,
247,5,157,1,22,
247,5,182,4,0,
247,5,184,1,38,
247,5,201,1,1,3
247,5,201,1,8,
247,5,201,4,0,
247,5,213,4,0,
247,5,216,4,0,
247,5,218,4,0,
247,5,237,4,0,
247,5,240,4,0,
247,5,241,4,0,
247,5,242,1,47,
247,5,249,4,0,
247,5,259,4,0,
247,5,263,4,0,
247,5,269,4,0,
247,5,280,4,0,
247,5,290,4,0,
247,6,34,3,0,
247,6,37,1,29,
247,6,38,3,0,
247,6,43,1,1,2
247,6,44,1,1,1
247,6,63,1,65,
247,6,63,4,0,
247,6,89,1,56,
247,6,89,4,0,
247,6,91,4,0,
247,6,92,4,0,
247,6,102,3,0,
247,6,103,1,1,4
247,6,103,1,15,
247,6,104,4,0,
247,6,156,4,0,
247,6,157,1,22,
247,6,157,3,0,
247,6,164,3,0,
247,6,173,3,0,
247,6,182,4,0,
247,6,184,1,38,
247,6,189,3,0,
247,6,201,1,1,3
247,6,201,1,8,
247,6,201,4,0,
247,6,203,3,0,
247,6,207,3,0,
247,6,213,4,0,
247,6,214,3,0,
247,6,216,4,0,
247,6,218,4,0,
247,6,237,4,0,
247,6,240,4,0,
247,6,241,4,0,
247,6,242,1,47,
247,6,249,4,0,
247,6,259,4,0,
247,6,263,4,0,
247,6,269,4,0,
247,6,280,4,0,
247,6,290,4,0,
247,7,34,3,0,
247,7,37,1,29,
247,7,38,3,0,
247,7,43,1,1,2
247,7,44,1,1,1
247,7,63,1,65,
247,7,63,4,0,
247,7,89,1,56,
247,7,89,4,0,
247,7,91,4,0,
247,7,92,4,0,
247,7,102,3,0,
247,7,103,1,1,4
247,7,103,1,15,
247,7,104,4,0,
247,7,156,4,0,
247,7,157,1,22,
247,7,157,3,0,
247,7,164,3,0,
247,7,182,4,0,
247,7,184,1,38,
247,7,201,1,1,3
247,7,201,1,8,
247,7,201,4,0,
247,7,213,4,0,
247,7,216,4,0,
247,7,218,4,0,
247,7,237,4,0,
247,7,240,4,0,
247,7,241,4,0,
247,7,242,1,47,
247,7,249,4,0,
247,7,259,4,0,
247,7,263,4,0,
247,7,269,4,0,
247,7,280,4,0,
247,7,290,4,0,
247,8,37,1,23,
247,8,43,1,1,2
247,8,44,1,1,1
247,8,63,1,60,
247,8,63,4,0,
247,8,89,1,47,
247,8,89,4,0,
247,8,91,4,0,
247,8,92,4,0,
247,8,103,1,1,4
247,8,103,1,10,
247,8,104,4,0,
247,8,156,4,0,
247,8,157,1,14,
247,8,157,4,0,
247,8,164,4,0,
247,8,182,4,0,
247,8,184,1,19,
247,8,201,1,1,3
247,8,201,1,5,
247,8,201,4,0,
247,8,203,4,0,
247,8,207,4,0,
247,8,213,4,0,
247,8,214,4,0,
247,8,216,4,0,
247,8,218,4,0,
247,8,237,4,0,
247,8,240,4,0,
247,8,241,4,0,
247,8,242,1,41,
247,8,249,4,0,
247,8,259,4,0,
247,8,263,4,0,
247,8,269,4,0,
247,8,280,4,0,
247,8,290,4,0,
247,8,317,4,0,
247,8,363,4,0,
247,8,371,1,34,
247,8,371,4,0,
247,8,397,4,0,
247,8,399,1,28,
247,8,399,4,0,
247,8,444,1,54,
247,8,444,4,0,
247,8,445,4,0,
247,8,446,4,0,
247,9,37,1,23,
247,9,43,1,1,2
247,9,44,1,1,1
247,9,63,1,60,
247,9,63,4,0,
247,9,89,1,47,
247,9,89,4,0,
247,9,91,4,0,
247,9,92,4,0,
247,9,103,1,1,4
247,9,103,1,10,
247,9,104,4,0,
247,9,156,4,0,
247,9,157,1,14,
247,9,157,4,0,
247,9,164,4,0,
247,9,173,3,0,
247,9,180,3,0,
247,9,182,4,0,
247,9,184,1,19,
247,9,189,3,0,
247,9,201,1,1,3
247,9,201,1,5,
247,9,201,4,0,
247,9,203,4,0,
247,9,207,4,0,
247,9,213,4,0,
247,9,214,4,0,
247,9,216,4,0,
247,9,218,4,0,
247,9,237,4,0,
247,9,240,4,0,
247,9,241,4,0,
247,9,242,1,41,
247,9,246,3,0,
247,9,249,4,0,
247,9,253,3,0,
247,9,259,4,0,
247,9,263,4,0,
247,9,269,4,0,
247,9,276,3,0,
247,9,280,4,0,
247,9,290,4,0,
247,9,317,4,0,
247,9,334,3,0,
247,9,363,4,0,
247,9,371,1,34,
247,9,371,4,0,
247,9,397,4,0,
247,9,399,1,28,
247,9,399,4,0,
247,9,414,3,0,
247,9,442,3,0,
247,9,444,1,54,
247,9,444,4,0,
247,9,445,4,0,
247,9,446,4,0,
247,10,29,3,0,
247,10,37,1,23,
247,10,43,1,1,2
247,10,44,1,1,1
247,10,63,1,60,
247,10,63,4,0,
247,10,89,1,47,
247,10,89,4,0,
247,10,91,4,0,
247,10,92,4,0,
247,10,103,1,1,4
247,10,103,1,10,
247,10,104,4,0,
247,10,156,4,0,
247,10,157,1,14,
247,10,157,4,0,
247,10,164,4,0,
247,10,173,3,0,
247,10,180,3,0,
247,10,182,4,0,
247,10,184,1,19,
247,10,189,3,0,
247,10,201,1,1,3
247,10,201,1,5,
247,10,201,4,0,
247,10,203,4,0,
247,10,207,4,0,
247,10,213,4,0,
247,10,214,4,0,
247,10,216,4,0,
247,10,218,4,0,
247,10,237,4,0,
247,10,240,4,0,
247,10,241,4,0,
247,10,242,1,41,
247,10,246,3,0,
247,10,249,4,0,
247,10,253,3,0,
247,10,259,4,0,
247,10,263,4,0,
247,10,269,4,0,
247,10,276,3,0,
247,10,280,4,0,
247,10,290,4,0,
247,10,317,4,0,
247,10,334,3,0,
247,10,363,4,0,
247,10,371,1,34,
247,10,371,4,0,
247,10,397,4,0,
247,10,399,1,28,
247,10,399,4,0,
247,10,414,3,0,
247,10,442,3,0,
247,10,444,1,54,
247,10,444,4,0,
247,10,445,4,0,
247,10,446,4,0,
247,11,37,1,28,
247,11,43,1,1,2
247,11,44,1,1,1
247,11,63,1,67,
247,11,63,4,0,
247,11,89,1,54,
247,11,89,4,0,
247,11,91,4,0,
247,11,92,4,0,
247,11,103,1,1,4
247,11,103,1,10,
247,11,104,4,0,
247,11,156,4,0,
247,11,157,1,19,
247,11,157,4,0,
247,11,164,4,0,
247,11,182,4,0,
247,11,184,1,23,
247,11,201,1,1,3
247,11,201,1,5,
247,11,201,4,0,
247,11,207,4,0,
247,11,213,4,0,
247,11,216,4,0,
247,11,218,4,0,
247,11,237,4,0,
247,11,240,4,0,
247,11,241,4,0,
247,11,242,1,47,
247,11,249,4,0,
247,11,259,4,0,
247,11,263,4,0,
247,11,269,4,0,
247,11,280,4,0,
247,11,317,4,0,
247,11,371,1,41,
247,11,371,4,0,
247,11,397,4,0,
247,11,399,1,34,
247,11,444,1,60,
247,11,444,4,0,
247,11,479,4,0,
247,11,496,4,0,
247,11,498,1,14,
247,11,514,4,0,
247,11,523,4,0,
247,11,555,4,0,
247,12,37,1,29,
247,12,43,1,1,2
247,12,44,1,1,1
247,12,63,1,65,
247,12,63,4,0,
247,12,89,1,56,
247,12,89,4,0,
247,12,91,4,0,
247,12,92,4,0,
247,12,103,1,1,4
247,12,103,1,15,
247,12,104,4,0,
247,12,156,4,0,
247,12,157,1,22,
247,12,182,4,0,
247,12,184,1,38,
247,12,201,1,1,3
247,12,201,1,8,
247,12,201,4,0,
247,12,213,4,0,
247,12,216,4,0,
247,12,218,4,0,
247,12,237,4,0,
247,12,240,4,0,
247,12,241,4,0,
247,12,242,1,47,
247,12,249,4,0,
247,12,259,4,0,
247,12,263,4,0,
247,12,269,4,0,
247,12,280,4,0,
247,12,290,4,0,
247,13,34,3,0,
247,13,37,1,29,
247,13,38,3,0,
247,13,43,1,1,2
247,13,44,1,1,1
247,13,63,1,65,
247,13,63,4,0,
247,13,89,1,56,
247,13,89,4,0,
247,13,91,4,0,
247,13,92,4,0,
247,13,102,3,0,
247,13,103,1,1,4
247,13,103,1,15,
247,13,104,4,0,
247,13,156,4,0,
247,13,157,1,22,
247,13,164,3,0,
247,13,182,4,0,
247,13,184,1,38,
247,13,201,1,1,3
247,13,201,1,8,
247,13,201,4,0,
247,13,207,3,0,
247,13,213,4,0,
247,13,216,4,0,
247,13,218,4,0,
247,13,237,4,0,
247,13,240,4,0,
247,13,241,4,0,
247,13,242,1,47,
247,13,249,4,0,
247,13,259,4,0,
247,13,263,4,0,
247,13,269,4,0,
247,13,280,4,0,
247,13,290,4,0,
247,14,37,1,28,
247,14,43,1,1,2
247,14,44,1,1,1
247,14,63,1,67,
247,14,63,4,0,
247,14,89,1,54,
247,14,89,4,0,
247,14,91,4,0,
247,14,92,4,0,
247,14,103,1,1,4
247,14,103,1,10,
247,14,104,4,0,
247,14,156,4,0,
247,14,157,1,19,
247,14,157,4,0,
247,14,164,4,0,
247,14,173,3,0,
247,14,180,3,0,
247,14,182,4,0,
247,14,184,1,23,
247,14,200,3,0,
247,14,201,1,1,3
247,14,201,1,5,
247,14,201,4,0,
247,14,207,4,0,
247,14,213,4,0,
247,14,214,3,0,
247,14,216,4,0,
247,14,218,4,0,
247,14,231,3,0,
247,14,237,4,0,
247,14,240,4,0,
247,14,241,4,0,
247,14,242,1,47,
247,14,249,4,0,
247,14,253,3,0,
247,14,259,4,0,
247,14,263,4,0,
247,14,269,4,0,
247,14,276,3,0,
247,14,280,4,0,
247,14,317,4,0,
247,14,334,3,0,
247,14,371,1,41,
247,14,371,4,0,
247,14,397,4,0,
247,14,399,1,34,
247,14,399,3,0,
247,14,414,3,0,
247,14,442,3,0,
247,14,444,1,60,
247,14,444,4,0,
247,14,446,3,0,
247,14,479,4,0,
247,14,496,4,0,
247,14,498,1,14,
247,14,514,4,0,
247,14,523,4,0,
247,14,555,4,0,
247,15,37,1,28,
247,15,43,1,1,2
247,15,44,1,1,1
247,15,63,1,67,
247,15,63,4,0,
247,15,89,1,54,
247,15,89,4,0,
247,15,91,4,0,
247,15,92,4,0,
247,15,103,1,1,4
247,15,103,1,10,
247,15,104,4,0,
247,15,156,4,0,
247,15,157,1,19,
247,15,157,4,0,
247,15,164,4,0,
247,15,182,4,0,
247,15,184,1,23,
247,15,201,1,1,3
247,15,201,1,5,
247,15,201,4,0,
247,15,207,4,0,
247,15,213,4,0,
247,15,214,4,0,
247,15,216,4,0,
247,15,218,4,0,
247,15,237,4,0,
247,15,240,4,0,
247,15,241,4,0,
247,15,242,1,47,
247,15,249,4,0,
247,15,259,4,0,
247,15,263,4,0,
247,15,269,4,0,
247,15,280,4,0,
247,15,317,4,0,
247,15,371,1,41,
247,15,371,4,0,
247,15,397,4,0,
247,15,399,1,34,
247,15,399,4,0,
247,15,444,1,60,
247,15,444,4,0,
247,15,479,4,0,
247,15,496,4,0,
247,15,498,1,14,
247,15,514,4,0,
247,15,523,4,0,
247,15,555,4,0,
247,15,590,4,0,
247,15,612,4,0,
247,16,37,1,28,1
247,16,43,1,1,2
247,16,44,1,1,1
247,16,63,1,67,1
247,16,63,4,0,
247,16,89,1,54,1
247,16,89,4,0,
247,16,91,4,0,
247,16,92,4,0,
247,16,103,1,1,4
247,16,103,1,10,1
247,16,104,4,0,
247,16,156,4,0,
247,16,157,1,19,1
247,16,157,4,0,
247,16,164,4,0,
247,16,173,3,0,
247,16,180,3,0,
247,16,182,4,0,
247,16,184,1,23,1
247,16,200,3,0,
247,16,201,1,1,3
247,16,201,1,5,1
247,16,201,4,0,
247,16,207,4,0,
247,16,213,4,0,
247,16,214,4,0,
247,16,216,4,0,
247,16,218,4,0,
247,16,231,3,0,
247,16,237,4,0,
247,16,240,4,0,
247,16,241,4,0,
247,16,242,1,47,1
247,16,249,4,0,
247,16,253,3,0,
247,16,259,4,0,
247,16,263,4,0,
247,16,269,4,0,
247,16,276,3,0,
247,16,280,4,0,
247,16,290,4,0,
247,16,317,4,0,
247,16,334,3,0,
247,16,371,1,41,1
247,16,371,4,0,
247,16,397,4,0,
247,16,399,1,34,1
247,16,399,4,0,
247,16,414,3,0,
247,16,442,3,0,
247,16,444,1,60,1
247,16,444,4,0,
247,16,446,3,0,
247,16,479,4,0,
247,16,496,4,0,
247,16,498,1,14,1
247,16,514,4,0,
247,16,523,4,0,
247,16,555,4,0,
247,16,590,4,0,
247,16,612,4,0,
247,17,37,1,28,
247,17,43,1,1,2
247,17,44,1,1,1
247,17,63,1,67,
247,17,63,4,0,
247,17,89,1,54,
247,17,89,4,0,
247,17,92,4,0,
247,17,103,1,1,4
247,17,103,1,10,
247,17,104,4,0,
247,17,156,4,0,
247,17,157,1,19,
247,17,157,4,0,
247,17,164,4,0,
247,17,182,4,0,
247,17,184,1,23,
247,17,201,1,1,3
247,17,201,1,5,
247,17,201,4,0,
247,17,207,4,0,
247,17,213,4,0,
247,17,214,4,0,
247,17,216,4,0,
247,17,218,4,0,
247,17,237,4,0,
247,17,240,4,0,
247,17,241,4,0,
247,17,242,1,47,
247,17,259,4,0,
247,17,263,4,0,
247,17,269,4,0,
247,17,280,4,0,
247,17,317,4,0,
247,17,371,1,41,
247,17,371,4,0,
247,17,397,4,0,
247,17,399,1,34,
247,17,399,4,0,
247,17,444,1,60,
247,17,444,4,0,
247,17,479,4,0,
247,17,496,4,0,
247,17,498,1,14,
247,17,523,4,0,
247,17,555,4,0,
247,17,590,4,0,
247,18,37,1,28,
247,18,43,1,1,2
247,18,44,1,1,1
247,18,63,1,67,
247,18,63,4,0,
247,18,89,1,54,
247,18,89,4,0,
247,18,92,4,0,
247,18,103,1,1,4
247,18,103,1,10,
247,18,104,4,0,
247,18,156,4,0,
247,18,157,1,19,
247,18,157,4,0,
247,18,164,4,0,
247,18,182,4,0,
247,18,184,1,23,
247,18,201,1,1,3
247,18,201,1,5,
247,18,201,4,0,
247,18,207,4,0,
247,18,213,4,0,
247,18,214,4,0,
247,18,216,4,0,
247,18,218,4,0,
247,18,237,4,0,
247,18,240,4,0,
247,18,241,4,0,
247,18,242,1,47,
247,18,259,4,0,
247,18,263,4,0,
247,18,269,4,0,
247,18,280,4,0,
247,18,317,4,0,
247,18,371,1,41,
247,18,371,4,0,
247,18,397,4,0,
247,18,399,1,34,
247,18,399,4,0,
247,18,444,1,60,
247,18,444,4,0,
247,18,479,4,0,
247,18,496,4,0,
247,18,498,1,14,
247,18,523,4,0,
247,18,555,4,0,
247,18,590,4,0,
248,3,7,4,0,
248,3,15,4,0,
248,3,29,4,0,
248,3,37,1,29,
248,3,43,1,1,2
248,3,44,1,1,1
248,3,46,4,0,
248,3,57,4,0,
248,3,63,1,75,
248,3,63,4,0,
248,3,70,4,0,
248,3,89,1,61,
248,3,89,4,0,
248,3,91,4,0,
248,3,92,4,0,
248,3,103,1,1,4
248,3,103,1,15,
248,3,104,4,0,
248,3,126,4,0,
248,3,156,4,0,
248,3,157,1,22,
248,3,171,4,0,
248,3,173,4,0,
248,3,174,4,0,
248,3,182,4,0,
248,3,184,1,38,
248,3,189,4,0,
248,3,197,4,0,
248,3,201,1,1,3
248,3,201,1,8,
248,3,201,4,0,
248,3,203,4,0,
248,3,207,4,0,
248,3,210,4,0,
248,3,213,4,0,
248,3,214,4,0,
248,3,216,4,0,
248,3,218,4,0,
248,3,223,4,0,
248,3,225,4,0,
248,3,231,4,0,
248,3,237,4,0,
248,3,240,4,0,
248,3,241,4,0,
248,3,242,1,47,
248,3,249,4,0,
248,4,7,4,0,
248,4,15,4,0,
248,4,29,4,0,
248,4,37,1,29,
248,4,43,1,1,2
248,4,44,1,1,1
248,4,46,4,0,
248,4,53,3,0,
248,4,57,4,0,
248,4,58,3,0,
248,4,63,1,75,
248,4,63,4,0,
248,4,70,4,0,
248,4,85,3,0,
248,4,89,1,61,
248,4,89,4,0,
248,4,91,4,0,
248,4,92,4,0,
248,4,103,1,1,4
248,4,103,1,15,
248,4,104,4,0,
248,4,126,4,0,
248,4,156,4,0,
248,4,157,1,22,
248,4,171,4,0,
248,4,173,4,0,
248,4,174,4,0,
248,4,182,4,0,
248,4,184,1,38,
248,4,189,4,0,
248,4,197,4,0,
248,4,201,1,1,3
248,4,201,1,8,
248,4,201,4,0,
248,4,203,4,0,
248,4,207,4,0,
248,4,210,4,0,
248,4,213,4,0,
248,4,214,4,0,
248,4,216,4,0,
248,4,218,4,0,
248,4,223,4,0,
248,4,225,4,0,
248,4,231,4,0,
248,4,237,4,0,
248,4,240,4,0,
248,4,241,4,0,
248,4,242,1,47,
248,4,249,4,0,
248,5,15,4,0,
248,5,37,1,29,
248,5,43,1,1,2
248,5,44,1,1,1
248,5,46,4,0,
248,5,53,4,0,
248,5,57,4,0,
248,5,58,4,0,
248,5,59,4,0,
248,5,63,1,75,
248,5,63,4,0,
248,5,70,4,0,
248,5,85,4,0,
248,5,87,4,0,
248,5,89,1,61,
248,5,89,4,0,
248,5,91,4,0,
248,5,92,4,0,
248,5,103,1,1,4
248,5,103,1,15,
248,5,104,4,0,
248,5,126,4,0,
248,5,156,4,0,
248,5,157,1,22,
248,5,182,4,0,
248,5,184,1,38,
248,5,201,1,1,3
248,5,201,1,8,
248,5,201,4,0,
248,5,213,4,0,
248,5,216,4,0,
248,5,218,4,0,
248,5,231,4,0,
248,5,237,4,0,
248,5,240,4,0,
248,5,241,4,0,
248,5,242,1,47,
248,5,249,4,0,
248,5,259,4,0,
248,5,263,4,0,
248,5,264,4,0,
248,5,269,4,0,
248,5,280,4,0,
248,5,290,4,0,
248,5,317,4,0,
248,5,332,4,0,
248,5,337,4,0,
248,5,351,4,0,
248,5,352,4,0,
248,6,5,3,0,
248,6,7,3,0,
248,6,15,4,0,
248,6,25,3,0,
248,6,34,3,0,
248,6,37,1,29,
248,6,38,3,0,
248,6,43,1,1,2
248,6,44,1,1,1
248,6,46,4,0,
248,6,53,4,0,
248,6,57,4,0,
248,6,58,4,0,
248,6,59,4,0,
248,6,63,1,75,
248,6,63,4,0,
248,6,68,3,0,
248,6,69,3,0,
248,6,70,4,0,
248,6,85,4,0,
248,6,86,3,0,
248,6,87,4,0,
248,6,89,1,61,
248,6,89,4,0,
248,6,91,4,0,
248,6,92,4,0,
248,6,102,3,0,
248,6,103,1,1,4
248,6,103,1,15,
248,6,104,4,0,
248,6,126,4,0,
248,6,156,4,0,
248,6,157,1,22,
248,6,157,3,0,
248,6,164,3,0,
248,6,173,3,0,
248,6,182,4,0,
248,6,184,1,38,
248,6,189,3,0,
248,6,201,1,1,3
248,6,201,1,8,
248,6,201,4,0,
248,6,203,3,0,
248,6,207,3,0,
248,6,210,3,0,
248,6,213,4,0,
248,6,214,3,0,
248,6,216,4,0,
248,6,218,4,0,
248,6,223,3,0,
248,6,231,4,0,
248,6,237,4,0,
248,6,240,4,0,
248,6,241,4,0,
248,6,242,1,47,
248,6,249,4,0,
248,6,259,4,0,
248,6,263,4,0,
248,6,264,4,0,
248,6,269,4,0,
248,6,280,4,0,
248,6,290,4,0,
248,6,317,4,0,
248,6,332,4,0,
248,6,337,4,0,
248,6,351,4,0,
248,6,352,4,0,
248,7,5,3,0,
248,7,15,4,0,
248,7,25,3,0,
248,7,34,3,0,
248,7,37,1,29,
248,7,38,3,0,
248,7,43,1,1,2
248,7,44,1,1,1
248,7,46,4,0,
248,7,53,4,0,
248,7,57,4,0,
248,7,58,4,0,
248,7,59,4,0,
248,7,63,1,75,
248,7,63,4,0,
248,7,68,3,0,
248,7,69,3,0,
248,7,70,4,0,
248,7,85,4,0,
248,7,86,3,0,
248,7,87,4,0,
248,7,89,1,61,
248,7,89,4,0,
248,7,91,4,0,
248,7,92,4,0,
248,7,102,3,0,
248,7,103,1,1,4
248,7,103,1,15,
248,7,104,4,0,
248,7,126,4,0,
248,7,156,4,0,
248,7,157,1,22,
248,7,157,3,0,
248,7,164,3,0,
248,7,182,4,0,
248,7,184,1,38,
248,7,201,1,1,3
248,7,201,1,8,
248,7,201,4,0,
248,7,213,4,0,
248,7,216,4,0,
248,7,218,4,0,
248,7,231,4,0,
248,7,237,4,0,
248,7,240,4,0,
248,7,241,4,0,
248,7,242,1,47,
248,7,249,4,0,
248,7,259,4,0,
248,7,263,4,0,
248,7,264,4,0,
248,7,269,4,0,
248,7,280,4,0,
248,7,290,4,0,
248,7,317,4,0,
248,7,332,4,0,
248,7,337,4,0,
248,7,351,4,0,
248,7,352,4,0,
248,8,15,4,0,
248,8,37,1,23,
248,8,43,1,1,5
248,8,44,1,1,4
248,8,46,4,0,
248,8,53,4,0,
248,8,57,4,0,
248,8,58,4,0,
248,8,59,4,0,
248,8,63,1,70,
248,8,63,4,0,
248,8,70,4,0,
248,8,85,4,0,
248,8,86,4,0,
248,8,87,4,0,
248,8,89,1,47,
248,8,89,4,0,
248,8,91,4,0,
248,8,92,4,0,
248,8,103,1,1,7
248,8,103,1,10,
248,8,104,4,0,
248,8,126,4,0,
248,8,156,4,0,
248,8,157,1,14,
248,8,157,4,0,
248,8,164,4,0,
248,8,182,4,0,
248,8,184,1,19,
248,8,201,1,1,6
248,8,201,1,5,
248,8,201,4,0,
248,8,203,4,0,
248,8,207,4,0,
248,8,213,4,0,
248,8,214,4,0,
248,8,216,4,0,
248,8,218,4,0,
248,8,231,4,0,
248,8,237,4,0,
248,8,240,4,0,
248,8,241,4,0,
248,8,242,1,41,
248,8,249,4,0,
248,8,259,4,0,
248,8,263,4,0,
248,8,264,4,0,
248,8,269,4,0,
248,8,280,4,0,
248,8,290,4,0,
248,8,317,4,0,
248,8,332,4,0,
248,8,337,4,0,
248,8,351,4,0,
248,8,352,4,0,
248,8,363,4,0,
248,8,371,1,34,
248,8,371,4,0,
248,8,374,4,0,
248,8,397,4,0,
248,8,399,1,28,
248,8,399,4,0,
248,8,406,4,0,
248,8,411,4,0,
248,8,416,4,0,
248,8,419,4,0,
248,8,421,4,0,
248,8,422,1,1,1
248,8,423,1,1,2
248,8,424,1,1,3
248,8,431,4,0,
248,8,444,1,54,
248,8,444,4,0,
248,8,445,4,0,
248,8,446,4,0,
248,9,7,3,0,
248,9,8,3,0,
248,9,9,3,0,
248,9,15,4,0,
248,9,37,1,23,
248,9,43,1,1,5
248,9,44,1,1,4
248,9,46,4,0,
248,9,53,4,0,
248,9,57,4,0,
248,9,58,4,0,
248,9,59,4,0,
248,9,63,1,70,
248,9,63,4,0,
248,9,70,4,0,
248,9,85,4,0,
248,9,86,4,0,
248,9,87,4,0,
248,9,89,1,47,
248,9,89,4,0,
248,9,91,4,0,
248,9,92,4,0,
248,9,103,1,1,7
248,9,103,1,10,
248,9,104,4,0,
248,9,126,4,0,
248,9,156,4,0,
248,9,157,1,14,
248,9,157,4,0,
248,9,164,4,0,
248,9,173,3,0,
248,9,180,3,0,
248,9,182,4,0,
248,9,184,1,19,
248,9,189,3,0,
248,9,200,3,0,
248,9,201,1,1,6
248,9,201,1,5,
248,9,201,4,0,
248,9,203,4,0,
248,9,207,4,0,
248,9,210,3,0,
248,9,213,4,0,
248,9,214,4,0,
248,9,216,4,0,
248,9,218,4,0,
248,9,231,4,0,
248,9,237,4,0,
248,9,240,4,0,
248,9,241,4,0,
248,9,242,1,41,
248,9,246,3,0,
248,9,249,4,0,
248,9,253,3,0,
248,9,259,4,0,
248,9,263,4,0,
248,9,264,4,0,
248,9,269,4,0,
248,9,276,3,0,
248,9,280,4,0,
248,9,290,4,0,
248,9,317,4,0,
248,9,332,4,0,
248,9,337,4,0,
248,9,351,4,0,
248,9,352,4,0,
248,9,363,4,0,
248,9,371,1,34,
248,9,371,4,0,
248,9,374,4,0,
248,9,397,4,0,
248,9,399,1,28,
248,9,399,4,0,
248,9,401,3,0,
248,9,406,4,0,
248,9,411,4,0,
248,9,414,3,0,
248,9,416,4,0,
248,9,419,4,0,
248,9,421,4,0,
248,9,422,1,1,1
248,9,423,1,1,2
248,9,424,1,1,3
248,9,431,4,0,
248,9,442,3,0,
248,9,444,1,54,
248,9,444,4,0,
248,9,445,4,0,
248,9,446,4,0,
248,10,7,3,0,
248,10,8,3,0,
248,10,9,3,0,
248,10,15,4,0,
248,10,29,3,0,
248,10,37,1,23,
248,10,43,1,1,5
248,10,44,1,1,4
248,10,46,4,0,
248,10,53,4,0,
248,10,57,4,0,
248,10,58,4,0,
248,10,59,4,0,
248,10,63,1,70,
248,10,63,4,0,
248,10,67,3,0,
248,10,70,4,0,
248,10,85,4,0,
248,10,86,4,0,
248,10,87,4,0,
248,10,89,1,47,
248,10,89,4,0,
248,10,91,4,0,
248,10,92,4,0,
248,10,103,1,1,7
248,10,103,1,10,
248,10,104,4,0,
248,10,126,4,0,
248,10,156,4,0,
248,10,157,1,14,
248,10,157,4,0,
248,10,164,4,0,
248,10,173,3,0,
248,10,180,3,0,
248,10,182,4,0,
248,10,184,1,19,
248,10,189,3,0,
248,10,200,3,0,
248,10,201,1,1,6
248,10,201,1,5,
248,10,201,4,0,
248,10,203,4,0,
248,10,207,4,0,
248,10,210,3,0,
248,10,213,4,0,
248,10,214,4,0,
248,10,216,4,0,
248,10,218,4,0,
248,10,231,4,0,
248,10,237,4,0,
248,10,240,4,0,
248,10,241,4,0,
248,10,242,1,41,
248,10,246,3,0,
248,10,249,4,0,
248,10,250,4,0,
248,10,253,3,0,
248,10,259,4,0,
248,10,263,4,0,
248,10,264,4,0,
248,10,269,4,0,
248,10,276,3,0,
248,10,280,4,0,
248,10,290,4,0,
248,10,317,4,0,
248,10,332,4,0,
248,10,335,3,0,
248,10,337,4,0,
248,10,351,4,0,
248,10,352,4,0,
248,10,363,4,0,
248,10,371,1,34,
248,10,371,4,0,
248,10,374,4,0,
248,10,397,4,0,
248,10,399,1,28,
248,10,399,4,0,
248,10,401,3,0,
248,10,406,4,0,
248,10,411,4,0,
248,10,414,3,0,
248,10,416,4,0,
248,10,419,4,0,
248,10,421,4,0,
248,10,422,1,1,1
248,10,423,1,1,2
248,10,424,1,1,3
248,10,431,4,0,
248,10,442,3,0,
248,10,444,1,54,
248,10,444,4,0,
248,10,445,4,0,
248,10,446,4,0,
248,11,15,4,0,
248,11,37,1,28,
248,11,43,1,1,5
248,11,44,1,1,4
248,11,46,4,0,
248,11,53,4,0,
248,11,57,4,0,
248,11,58,4,0,
248,11,59,4,0,
248,11,63,1,73,
248,11,63,4,0,
248,11,70,4,0,
248,11,85,4,0,
248,11,86,4,0,
248,11,87,4,0,
248,11,89,1,54,
248,11,89,4,0,
248,11,91,4,0,
248,11,92,4,0,
248,11,103,1,1,7
248,11,103,1,10,
248,11,104,4,0,
248,11,126,4,0,
248,11,156,4,0,
248,11,157,1,19,
248,11,157,4,0,
248,11,164,4,0,
248,11,182,4,0,
248,11,184,1,23,
248,11,201,1,1,6
248,11,201,1,5,
248,11,201,4,0,
248,11,207,4,0,
248,11,213,4,0,
248,11,216,4,0,
248,11,218,4,0,
248,11,237,4,0,
248,11,240,4,0,
248,11,241,4,0,
248,11,242,1,47,
248,11,249,4,0,
248,11,259,4,0,
248,11,263,4,0,
248,11,269,4,0,
248,11,280,4,0,
248,11,317,4,0,
248,11,332,4,0,
248,11,337,4,0,
248,11,371,1,41,
248,11,371,4,0,
248,11,374,4,0,
248,11,397,4,0,
248,11,399,1,34,
248,11,411,4,0,
248,11,416,1,82,
248,11,416,4,0,
248,11,421,4,0,
248,11,422,1,1,1
248,11,423,1,1,2
248,11,424,1,1,3
248,11,444,1,63,
248,11,444,4,0,
248,11,468,4,0,
248,11,479,4,0,
248,11,496,4,0,
248,11,498,1,14,
248,11,510,4,0,
248,11,514,4,0,
248,11,523,4,0,
248,11,525,4,0,
248,11,555,4,0,
248,12,15,4,0,
248,12,37,1,29,
248,12,43,1,1,2
248,12,44,1,1,1
248,12,46,4,0,
248,12,53,4,0,
248,12,57,4,0,
248,12,58,4,0,
248,12,59,4,0,
248,12,63,1,75,
248,12,63,4,0,
248,12,70,4,0,
248,12,85,4,0,
248,12,87,4,0,
248,12,89,1,61,
248,12,89,4,0,
248,12,91,4,0,
248,12,92,4,0,
248,12,103,1,1,4
248,12,103,1,15,
248,12,104,4,0,
248,12,126,4,0,
248,12,156,4,0,
248,12,157,1,22,
248,12,182,4,0,
248,12,184,1,38,
248,12,201,1,1,3
248,12,201,1,8,
248,12,201,4,0,
248,12,213,4,0,
248,12,216,4,0,
248,12,218,4,0,
248,12,231,4,0,
248,12,237,4,0,
248,12,240,4,0,
248,12,241,4,0,
248,12,242,1,47,
248,12,249,4,0,
248,12,259,4,0,
248,12,263,4,0,
248,12,264,4,0,
248,12,269,4,0,
248,12,280,4,0,
248,12,290,4,0,
248,12,317,4,0,
248,12,332,4,0,
248,12,337,4,0,
248,12,351,4,0,
248,12,352,4,0,
248,13,15,4,0,
248,13,34,3,0,
248,13,37,1,29,
248,13,38,3,0,
248,13,43,1,1,2
248,13,44,1,1,1
248,13,46,4,0,
248,13,53,4,0,
248,13,57,4,0,
248,13,58,4,0,
248,13,59,4,0,
248,13,63,1,75,
248,13,63,4,0,
248,13,69,3,0,
248,13,70,4,0,
248,13,85,4,0,
248,13,86,3,0,
248,13,87,4,0,
248,13,89,1,61,
248,13,89,4,0,
248,13,91,4,0,
248,13,92,4,0,
248,13,102,3,0,
248,13,103,1,1,4
248,13,103,1,15,
248,13,104,4,0,
248,13,126,4,0,
248,13,156,4,0,
248,13,157,1,22,
248,13,164,3,0,
248,13,171,3,0,
248,13,182,4,0,
248,13,184,1,38,
248,13,201,1,1,3
248,13,201,1,8,
248,13,201,4,0,
248,13,207,3,0,
248,13,213,4,0,
248,13,216,4,0,
248,13,218,4,0,
248,13,231,4,0,
248,13,237,4,0,
248,13,240,4,0,
248,13,241,4,0,
248,13,242,1,47,
248,13,249,4,0,
248,13,259,4,0,
248,13,263,4,0,
248,13,264,4,0,
248,13,269,4,0,
248,13,280,4,0,
248,13,290,4,0,
248,13,317,4,0,
248,13,332,4,0,
248,13,337,4,0,
248,13,351,4,0,
248,13,352,4,0,
248,14,7,3,0,
248,14,8,3,0,
248,14,9,3,0,
248,14,15,4,0,
248,14,37,1,28,
248,14,43,1,1,5
248,14,44,1,1,4
248,14,46,4,0,
248,14,53,4,0,
248,14,57,4,0,
248,14,58,4,0,
248,14,59,4,0,
248,14,63,1,73,
248,14,63,4,0,
248,14,67,3,0,
248,14,70,4,0,
248,14,85,4,0,
248,14,86,4,0,
248,14,87,4,0,
248,14,89,1,54,
248,14,89,4,0,
248,14,91,4,0,
248,14,92,4,0,
248,14,103,1,1,7
248,14,103,1,10,
248,14,104,4,0,
248,14,126,4,0,
248,14,156,4,0,
248,14,157,1,19,
248,14,157,4,0,
248,14,164,4,0,
248,14,173,3,0,
248,14,180,3,0,
248,14,182,4,0,
248,14,184,1,23,
248,14,200,3,0,
248,14,201,1,1,6
248,14,201,1,5,
248,14,201,4,0,
248,14,207,4,0,
248,14,213,4,0,
248,14,214,3,0,
248,14,216,4,0,
248,14,218,4,0,
248,14,231,3,0,
248,14,237,4,0,
248,14,240,4,0,
248,14,241,4,0,
248,14,242,1,47,
248,14,249,4,0,
248,14,253,3,0,
248,14,259,4,0,
248,14,263,4,0,
248,14,269,4,0,
248,14,276,3,0,
248,14,280,4,0,
248,14,317,4,0,
248,14,332,4,0,
248,14,334,3,0,
248,14,335,3,0,
248,14,337,4,0,
248,14,371,1,41,
248,14,371,4,0,
248,14,374,4,0,
248,14,397,4,0,
248,14,399,1,34,
248,14,399,3,0,
248,14,401,3,0,
248,14,406,3,0,
248,14,411,4,0,
248,14,414,3,0,
248,14,416,1,82,
248,14,416,4,0,
248,14,421,4,0,
248,14,422,1,1,1
248,14,423,1,1,2
248,14,424,1,1,3
248,14,442,3,0,
248,14,444,1,63,
248,14,444,4,0,
248,14,446,3,0,
248,14,468,4,0,
248,14,479,4,0,
248,14,492,3,0,
248,14,496,4,0,
248,14,498,1,14,
248,14,510,4,0,
248,14,514,4,0,
248,14,523,4,0,
248,14,525,4,0,
248,14,555,4,0,
248,15,15,4,0,
248,15,37,1,28,
248,15,43,1,1,5
248,15,44,1,1,4
248,15,46,4,0,
248,15,53,4,0,
248,15,57,4,0,
248,15,58,4,0,
248,15,59,4,0,
248,15,63,1,73,
248,15,63,4,0,
248,15,70,4,0,
248,15,85,4,0,
248,15,86,4,0,
248,15,87,4,0,
248,15,89,1,54,
248,15,89,4,0,
248,15,91,4,0,
248,15,92,4,0,
248,15,103,1,1,7
248,15,103,1,10,
248,15,104,4,0,
248,15,126,4,0,
248,15,156,4,0,
248,15,157,1,19,
248,15,157,4,0,
248,15,164,4,0,
248,15,182,4,0,
248,15,184,1,23,
248,15,201,1,1,6
248,15,201,1,5,
248,15,201,4,0,
248,15,207,4,0,
248,15,213,4,0,
248,15,214,4,0,
248,15,216,4,0,
248,15,218,4,0,
248,15,237,4,0,
248,15,240,4,0,
248,15,241,4,0,
248,15,242,1,47,
248,15,249,4,0,
248,15,259,4,0,
248,15,263,4,0,
248,15,269,4,0,
248,15,280,4,0,
248,15,317,4,0,
248,15,332,4,0,
248,15,337,4,0,
248,15,371,1,41,
248,15,371,4,0,
248,15,374,4,0,
248,15,397,4,0,
248,15,399,1,34,
248,15,399,4,0,
248,15,411,4,0,
248,15,416,1,82,
248,15,416,4,0,
248,15,421,4,0,
248,15,422,1,1,1
248,15,423,1,1,2
248,15,424,1,1,3
248,15,444,1,63,
248,15,444,4,0,
248,15,468,4,0,
248,15,479,4,0,
248,15,496,4,0,
248,15,498,1,14,
248,15,510,4,0,
248,15,514,4,0,
248,15,523,4,0,
248,15,525,4,0,
248,15,555,4,0,
248,15,590,4,0,
248,15,612,4,0,
248,16,7,3,0,
248,16,8,3,0,
248,16,9,3,0,
248,16,15,4,0,
248,16,37,1,28,1
248,16,43,1,1,5
248,16,44,1,1,4
248,16,46,4,0,
248,16,53,4,0,
248,16,57,4,0,
248,16,58,4,0,
248,16,59,4,0,
248,16,63,1,73,1
248,16,63,4,0,
248,16,67,3,0,
248,16,70,4,0,
248,16,85,4,0,
248,16,86,4,0,
248,16,87,4,0,
248,16,89,1,54,1
248,16,89,4,0,
248,16,91,4,0,
248,16,92,4,0,
248,16,103,1,1,7
248,16,103,1,10,1
248,16,104,4,0,
248,16,126,4,0,
248,16,156,4,0,
248,16,157,1,19,1
248,16,157,4,0,
248,16,164,4,0,
248,16,173,3,0,
248,16,180,3,0,
248,16,182,4,0,
248,16,184,1,23,1
248,16,200,3,0,
248,16,201,1,1,6
248,16,201,1,5,1
248,16,201,4,0,
248,16,207,4,0,
248,16,213,4,0,
248,16,214,4,0,
248,16,216,4,0,
248,16,218,4,0,
248,16,231,3,0,
248,16,237,4,0,
248,16,240,4,0,
248,16,241,4,0,
248,16,242,1,47,1
248,16,249,4,0,
248,16,253,3,0,
248,16,259,4,0,
248,16,263,4,0,
248,16,264,3,0,
248,16,269,4,0,
248,16,276,3,0,
248,16,280,4,0,
248,16,290,4,0,
248,16,317,4,0,
248,16,332,4,0,
248,16,334,3,0,
248,16,335,3,0,
248,16,337,4,0,
248,16,351,3,0,
248,16,352,3,0,
248,16,371,1,41,1
248,16,371,4,0,
248,16,374,4,0,
248,16,397,4,0,
248,16,399,1,34,1
248,16,399,4,0,
248,16,401,3,0,
248,16,406,3,0,
248,16,411,4,0,
248,16,414,3,0,
248,16,416,1,82,1
248,16,416,4,0,
248,16,421,4,0,
248,16,422,1,1,1
248,16,423,1,1,2
248,16,424,1,1,3
248,16,442,3,0,
248,16,444,1,63,1
248,16,444,4,0,
248,16,446,3,0,
248,16,468,4,0,
248,16,479,4,0,
248,16,492,3,0,
248,16,496,4,0,
248,16,498,1,14,1
248,16,510,4,0,
248,16,514,4,0,
248,16,523,4,0,
248,16,525,4,0,
248,16,555,4,0,
248,16,590,4,0,
248,16,612,4,0,
248,17,37,1,28,
248,17,43,1,1,5
248,17,44,1,1,4
248,17,46,4,0,
248,17,53,4,0,
248,17,57,4,0,
248,17,58,4,0,
248,17,59,4,0,
248,17,63,1,73,
248,17,63,4,0,
248,17,85,4,0,
248,17,86,4,0,
248,17,87,4,0,
248,17,89,1,54,
248,17,89,4,0,
248,17,92,4,0,
248,17,103,1,1,7
248,17,103,1,10,
248,17,104,4,0,
248,17,126,4,0,
248,17,156,4,0,
248,17,157,1,19,
248,17,157,4,0,
248,17,164,4,0,
248,17,182,4,0,
248,17,184,1,23,
248,17,201,1,1,6
248,17,201,1,5,
248,17,201,4,0,
248,17,207,4,0,
248,17,213,4,0,
248,17,214,4,0,
248,17,216,4,0,
248,17,218,4,0,
248,17,237,4,0,
248,17,240,4,0,
248,17,241,4,0,
248,17,242,1,47,
248,17,259,4,0,
248,17,263,4,0,
248,17,269,4,0,
248,17,280,4,0,
248,17,317,4,0,
248,17,332,4,0,
248,17,337,4,0,
248,17,371,1,41,
248,17,371,4,0,
248,17,374,4,0,
248,17,397,4,0,
248,17,399,1,34,
248,17,399,4,0,
248,17,411,4,0,
248,17,416,1,82,
248,17,416,4,0,
248,17,421,4,0,
248,17,422,1,1,1
248,17,423,1,1,2
248,17,424,1,1,3
248,17,444,1,63,
248,17,444,4,0,
248,17,479,4,0,
248,17,496,4,0,
248,17,498,1,14,
248,17,523,4,0,
248,17,525,4,0,
248,17,555,4,0,
248,17,590,4,0,
248,17,693,4,0,
248,18,37,1,28,
248,18,43,1,1,5
248,18,44,1,1,4
248,18,46,4,0,
248,18,53,4,0,
248,18,57,4,0,
248,18,58,4,0,
248,18,59,4,0,
248,18,63,1,73,
248,18,63,4,0,
248,18,85,4,0,
248,18,86,4,0,
248,18,87,4,0,
248,18,89,1,54,
248,18,89,4,0,
248,18,92,4,0,
248,18,103,1,1,7
248,18,103,1,10,
248,18,104,4,0,
248,18,126,4,0,
248,18,156,4,0,
248,18,157,1,19,
248,18,157,4,0,
248,18,164,4,0,
248,18,182,4,0,
248,18,184,1,23,
248,18,201,1,1,6
248,18,201,1,5,
248,18,201,4,0,
248,18,207,4,0,
248,18,213,4,0,
248,18,214,4,0,
248,18,216,4,0,
248,18,218,4,0,
248,18,237,4,0,
248,18,240,4,0,
248,18,241,4,0,
248,18,242,1,47,
248,18,259,4,0,
248,18,263,4,0,
248,18,269,4,0,
248,18,280,4,0,
248,18,317,4,0,
248,18,332,4,0,
248,18,337,4,0,
248,18,371,1,41,
248,18,371,4,0,
248,18,374,4,0,
248,18,397,4,0,
248,18,399,1,34,
248,18,399,4,0,
248,18,411,4,0,
248,18,416,1,82,
248,18,416,4,0,
248,18,421,4,0,
248,18,422,1,1,1
248,18,423,1,1,2
248,18,424,1,1,3
248,18,444,1,63,
248,18,444,4,0,
248,18,479,4,0,
248,18,496,4,0,
248,18,498,1,14,
248,18,523,4,0,
248,18,525,4,0,
248,18,555,4,0,
248,18,590,4,0,
248,18,693,4,0,
249,3,16,1,22,
249,3,18,1,77,
249,3,19,4,0,
249,3,29,4,0,
249,3,46,4,0,
249,3,56,1,44,
249,3,57,4,0,
249,3,59,4,0,
249,3,63,4,0,
249,3,70,4,0,
249,3,87,4,0,
249,3,89,4,0,
249,3,92,4,0,
249,3,94,4,0,
249,3,104,4,0,
249,3,105,1,33,
249,3,127,4,0,
249,3,129,1,66,
249,3,129,4,0,
249,3,138,4,0,
249,3,156,4,0,
249,3,171,4,0,
249,3,173,4,0,
249,3,174,4,0,
249,3,177,1,1,
249,3,182,4,0,
249,3,189,4,0,
249,3,192,4,0,
249,3,196,4,0,
249,3,197,4,0,
249,3,201,4,0,
249,3,202,4,0,
249,3,203,4,0,
249,3,207,4,0,
249,3,211,4,0,
249,3,214,4,0,
249,3,216,4,0,
249,3,218,4,0,
249,3,219,1,11,
249,3,225,4,0,
249,3,231,4,0,
249,3,237,4,0,
249,3,240,1,55,
249,3,240,4,0,
249,3,241,4,0,
249,3,244,4,0,
249,3,246,1,88,
249,3,247,4,0,
249,3,248,1,99,
249,3,249,4,0,
249,3,250,4,0,
249,4,16,1,22,
249,4,18,1,77,
249,4,19,4,0,
249,4,29,4,0,
249,4,46,4,0,
249,4,56,1,44,
249,4,57,4,0,
249,4,58,3,0,
249,4,59,4,0,
249,4,63,4,0,
249,4,70,4,0,
249,4,85,3,0,
249,4,87,4,0,
249,4,89,4,0,
249,4,92,4,0,
249,4,94,4,0,
249,4,104,4,0,
249,4,105,1,33,
249,4,127,4,0,
249,4,129,1,66,
249,4,129,4,0,
249,4,138,4,0,
249,4,156,4,0,
249,4,171,4,0,
249,4,173,4,0,
249,4,174,4,0,
249,4,177,1,1,
249,4,182,4,0,
249,4,189,4,0,
249,4,192,4,0,
249,4,196,4,0,
249,4,197,4,0,
249,4,201,4,0,
249,4,202,4,0,
249,4,203,4,0,
249,4,207,4,0,
249,4,211,4,0,
249,4,214,4,0,
249,4,216,4,0,
249,4,218,4,0,
249,4,219,1,11,
249,4,225,4,0,
249,4,231,4,0,
249,4,237,4,0,
249,4,240,1,55,
249,4,240,4,0,
249,4,241,4,0,
249,4,244,4,0,
249,4,246,1,88,
249,4,247,4,0,
249,4,248,1,99,
249,4,249,4,0,
249,4,250,4,0,
249,5,16,1,22,
249,5,18,1,1,
249,5,19,4,0,
249,5,46,4,0,
249,5,56,1,44,
249,5,57,4,0,
249,5,58,4,0,
249,5,59,4,0,
249,5,63,4,0,
249,5,70,4,0,
249,5,85,4,0,
249,5,87,4,0,
249,5,89,4,0,
249,5,92,4,0,
249,5,94,4,0,
249,5,104,4,0,
249,5,105,1,33,
249,5,113,4,0,
249,5,115,4,0,
249,5,127,4,0,
249,5,129,1,66,
249,5,156,4,0,
249,5,177,1,77,
249,5,182,4,0,
249,5,201,4,0,
249,5,202,4,0,
249,5,211,4,0,
249,5,216,4,0,
249,5,218,4,0,
249,5,219,1,11,
249,5,219,4,0,
249,5,231,4,0,
249,5,237,4,0,
249,5,240,1,55,
249,5,240,4,0,
249,5,241,4,0,
249,5,246,1,88,
249,5,247,4,0,
249,5,248,1,99,
249,5,249,4,0,
249,5,258,4,0,
249,5,263,4,0,
249,5,285,4,0,
249,5,290,4,0,
249,5,291,4,0,
249,5,332,4,0,
249,5,347,4,0,
249,5,351,4,0,
249,5,352,4,0,
249,6,16,1,22,
249,6,18,1,1,
249,6,19,4,0,
249,6,34,3,0,
249,6,38,3,0,
249,6,46,4,0,
249,6,56,1,44,
249,6,57,4,0,
249,6,58,4,0,
249,6,59,4,0,
249,6,63,4,0,
249,6,70,4,0,
249,6,85,4,0,
249,6,86,3,0,
249,6,87,4,0,
249,6,89,4,0,
249,6,92,4,0,
249,6,94,4,0,
249,6,102,3,0,
249,6,104,4,0,
249,6,105,1,33,
249,6,113,4,0,
249,6,115,4,0,
249,6,127,4,0,
249,6,129,1,66,
249,6,129,3,0,
249,6,138,3,0,
249,6,156,4,0,
249,6,164,3,0,
249,6,173,3,0,
249,6,177,1,77,
249,6,182,4,0,
249,6,189,3,0,
249,6,196,3,0,
249,6,201,4,0,
249,6,202,4,0,
249,6,203,3,0,
249,6,207,3,0,
249,6,211,4,0,
249,6,214,3,0,
249,6,216,4,0,
249,6,218,4,0,
249,6,219,1,11,
249,6,219,4,0,
249,6,231,4,0,
249,6,237,4,0,
249,6,240,1,55,
249,6,240,4,0,
249,6,241,4,0,
249,6,244,3,0,
249,6,246,1,88,
249,6,247,4,0,
249,6,248,1,99,
249,6,249,4,0,
249,6,258,4,0,
249,6,263,4,0,
249,6,285,4,0,
249,6,290,4,0,
249,6,291,4,0,
249,6,332,4,0,
249,6,347,4,0,
249,6,351,4,0,
249,6,352,4,0,
249,7,16,1,22,
249,7,18,1,1,
249,7,19,4,0,
249,7,34,3,0,
249,7,38,3,0,
249,7,46,4,0,
249,7,56,1,44,
249,7,57,4,0,
249,7,58,4,0,
249,7,59,4,0,
249,7,63,4,0,
249,7,70,4,0,
249,7,85,4,0,
249,7,86,3,0,
249,7,87,4,0,
249,7,89,4,0,
249,7,92,4,0,
249,7,94,4,0,
249,7,102,3,0,
249,7,104,4,0,
249,7,105,1,33,
249,7,113,4,0,
249,7,115,4,0,
249,7,127,4,0,
249,7,129,1,66,
249,7,138,3,0,
249,7,156,4,0,
249,7,164,3,0,
249,7,177,1,77,
249,7,182,4,0,
249,7,201,4,0,
249,7,202,4,0,
249,7,211,4,0,
249,7,216,4,0,
249,7,218,4,0,
249,7,219,1,11,
249,7,219,4,0,
249,7,231,4,0,
249,7,237,4,0,
249,7,240,1,55,
249,7,240,4,0,
249,7,241,4,0,
249,7,246,1,88,
249,7,247,4,0,
249,7,248,1,99,
249,7,249,4,0,
249,7,258,4,0,
249,7,263,4,0,
249,7,285,4,0,
249,7,290,4,0,
249,7,291,4,0,
249,7,332,4,0,
249,7,347,4,0,
249,7,351,4,0,
249,7,352,4,0,
249,8,16,1,15,
249,8,18,1,1,
249,8,19,4,0,
249,8,46,4,0,
249,8,56,1,29,
249,8,57,4,0,
249,8,58,4,0,
249,8,59,4,0,
249,8,63,4,0,
249,8,70,4,0,
249,8,85,4,0,
249,8,86,4,0,
249,8,87,4,0,
249,8,89,4,0,
249,8,92,4,0,
249,8,94,4,0,
249,8,104,4,0,
249,8,105,1,23,
249,8,113,4,0,
249,8,115,4,0,
249,8,127,4,0,
249,8,129,1,43,
249,8,138,4,0,
249,8,143,1,99,
249,8,148,4,0,
249,8,156,4,0,
249,8,164,4,0,
249,8,177,1,85,
249,8,182,4,0,
249,8,201,4,0,
249,8,202,4,0,
249,8,203,4,0,
249,8,207,4,0,
249,8,211,4,0,
249,8,214,4,0,
249,8,216,4,0,
249,8,218,4,0,
249,8,219,1,9,
249,8,219,4,0,
249,8,231,4,0,
249,8,237,4,0,
249,8,240,1,37,
249,8,240,4,0,
249,8,241,4,0,
249,8,244,4,0,
249,8,246,1,57,
249,8,247,4,0,
249,8,248,1,79,
249,8,249,4,0,
249,8,258,4,0,
249,8,263,4,0,
249,8,285,4,0,
249,8,290,4,0,
249,8,326,1,65,
249,8,332,4,0,
249,8,347,1,93,
249,8,347,4,0,
249,8,351,4,0,
249,8,352,4,0,
249,8,355,4,0,
249,8,362,4,0,
249,8,363,1,51,
249,8,363,4,0,
249,8,386,1,71,
249,8,406,4,0,
249,8,416,4,0,
249,8,419,4,0,
249,8,432,4,0,
249,8,451,4,0,
249,9,16,1,15,
249,9,18,1,1,
249,9,19,4,0,
249,9,46,4,0,
249,9,56,1,29,
249,9,57,4,0,
249,9,58,4,0,
249,9,59,4,0,
249,9,63,4,0,
249,9,70,4,0,
249,9,85,4,0,
249,9,86,4,0,
249,9,87,4,0,
249,9,89,4,0,
249,9,92,4,0,
249,9,94,4,0,
249,9,104,4,0,
249,9,105,1,23,
249,9,113,4,0,
249,9,115,4,0,
249,9,127,4,0,
249,9,129,1,43,
249,9,129,3,0,
249,9,138,4,0,
249,9,143,1,99,
249,9,148,4,0,
249,9,156,4,0,
249,9,164,4,0,
249,9,173,3,0,
249,9,177,1,85,
249,9,182,4,0,
249,9,189,3,0,
249,9,196,3,0,
249,9,201,4,0,
249,9,202,4,0,
249,9,203,4,0,
249,9,207,4,0,
249,9,211,4,0,
249,9,214,4,0,
249,9,216,4,0,
249,9,218,4,0,
249,9,219,1,9,
249,9,219,4,0,
249,9,231,4,0,
249,9,237,4,0,
249,9,239,3,0,
249,9,240,1,37,
249,9,240,4,0,
249,9,241,4,0,
249,9,244,4,0,
249,9,246,1,57,
249,9,246,3,0,
249,9,247,4,0,
249,9,248,1,79,
249,9,249,4,0,
249,9,258,4,0,
249,9,263,4,0,
249,9,271,3,0,
249,9,285,4,0,
249,9,290,4,0,
249,9,291,3,0,
249,9,314,3,0,
249,9,324,3,0,
249,9,326,1,65,
249,9,332,4,0,
249,9,347,1,93,
249,9,347,4,0,
249,9,351,4,0,
249,9,352,4,0,
249,9,355,4,0,
249,9,362,4,0,
249,9,363,1,51,
249,9,363,4,0,
249,9,386,1,71,
249,9,401,3,0,
249,9,406,4,0,
249,9,414,3,0,
249,9,416,4,0,
249,9,419,4,0,
249,9,428,3,0,
249,9,432,4,0,
249,9,442,3,0,
249,9,451,4,0,
249,9,466,3,0,
249,10,16,1,9,
249,10,18,1,1,1
249,10,19,4,0,
249,10,29,3,0,
249,10,46,4,0,
249,10,56,1,37,
249,10,57,4,0,
249,10,58,4,0,
249,10,59,4,0,
249,10,63,4,0,
249,10,70,4,0,
249,10,85,4,0,
249,10,86,4,0,
249,10,87,4,0,
249,10,89,4,0,
249,10,92,4,0,
249,10,94,4,0,
249,10,104,4,0,
249,10,105,1,71,
249,10,113,4,0,
249,10,115,4,0,
249,10,127,4,0,
249,10,129,3,0,
249,10,138,4,0,
249,10,143,1,99,
249,10,143,3,0,
249,10,148,4,0,
249,10,156,4,0,
249,10,164,4,0,
249,10,173,3,0,
249,10,177,1,43,
249,10,182,4,0,
249,10,189,3,0,
249,10,196,3,0,
249,10,201,4,0,
249,10,202,4,0,
249,10,203,4,0,
249,10,207,4,0,
249,10,211,4,0,
249,10,214,4,0,
249,10,216,4,0,
249,10,218,4,0,
249,10,219,1,65,
249,10,219,4,0,
249,10,231,4,0,
249,10,237,4,0,
249,10,239,3,0,
249,10,240,1,29,
249,10,240,4,0,
249,10,241,4,0,
249,10,244,4,0,
249,10,246,1,57,
249,10,246,3,0,
249,10,247,4,0,
249,10,248,1,79,
249,10,249,4,0,
249,10,250,4,0,
249,10,258,4,0,
249,10,263,4,0,
249,10,271,3,0,
249,10,285,4,0,
249,10,290,4,0,
249,10,291,3,0,
249,10,311,1,1,2
249,10,314,3,0,
249,10,324,3,0,
249,10,326,1,23,
249,10,332,4,0,
249,10,347,1,93,
249,10,347,4,0,
249,10,351,4,0,
249,10,352,4,0,
249,10,355,4,0,
249,10,362,4,0,
249,10,363,1,85,
249,10,363,4,0,
249,10,366,3,0,
249,10,386,1,50,
249,10,401,3,0,
249,10,406,4,0,
249,10,407,1,15,
249,10,414,3,0,
249,10,416,4,0,
249,10,419,4,0,
249,10,428,3,0,
249,10,442,3,0,
249,10,451,4,0,
249,10,466,3,0,
249,11,16,1,9,
249,11,18,1,1,1
249,11,19,4,0,
249,11,46,4,0,
249,11,56,1,37,
249,11,57,4,0,
249,11,58,4,0,
249,11,59,4,0,
249,11,63,4,0,
249,11,70,4,0,
249,11,85,4,0,
249,11,86,4,0,
249,11,87,4,0,
249,11,89,4,0,
249,11,92,4,0,
249,11,94,4,0,
249,11,104,4,0,
249,11,105,1,71,
249,11,113,4,0,
249,11,115,4,0,
249,11,127,4,0,
249,11,138,4,0,
249,11,143,1,99,
249,11,148,4,0,
249,11,156,4,0,
249,11,164,4,0,
249,11,177,1,43,
249,11,182,4,0,
249,11,201,4,0,
249,11,207,4,0,
249,11,216,4,0,
249,11,218,4,0,
249,11,219,1,65,
249,11,219,4,0,
249,11,237,4,0,
249,11,240,1,29,
249,11,240,4,0,
249,11,241,4,0,
249,11,244,4,0,
249,11,246,1,57,
249,11,247,4,0,
249,11,248,1,79,
249,11,249,4,0,
249,11,258,4,0,
249,11,263,4,0,
249,11,291,4,0,
249,11,311,1,1,2
249,11,326,1,23,
249,11,332,4,0,
249,11,347,1,93,
249,11,347,4,0,
249,11,363,1,85,
249,11,386,1,50,
249,11,407,1,15,
249,11,416,4,0,
249,11,451,4,0,
249,11,473,4,0,
249,11,477,4,0,
249,11,496,4,0,
249,11,497,4,0,
249,11,507,4,0,
249,11,523,4,0,
249,11,525,4,0,
249,12,16,1,22,
249,12,18,1,1,
249,12,19,4,0,
249,12,46,4,0,
249,12,56,1,44,
249,12,57,4,0,
249,12,58,4,0,
249,12,59,4,0,
249,12,63,4,0,
249,12,70,4,0,
249,12,85,4,0,
249,12,87,4,0,
249,12,89,4,0,
249,12,92,4,0,
249,12,94,4,0,
249,12,104,4,0,
249,12,105,1,33,
249,12,113,4,0,
249,12,115,4,0,
249,12,127,4,0,
249,12,129,1,66,
249,12,156,4,0,
249,12,177,1,77,
249,12,182,4,0,
249,12,201,4,0,
249,12,202,4,0,
249,12,211,4,0,
249,12,216,4,0,
249,12,218,4,0,
249,12,219,1,11,
249,12,219,4,0,
249,12,231,4,0,
249,12,237,4,0,
249,12,240,1,55,
249,12,240,4,0,
249,12,241,4,0,
249,12,246,1,88,
249,12,247,4,0,
249,12,248,1,99,
249,12,249,4,0,
249,12,258,4,0,
249,12,263,4,0,
249,12,285,4,0,
249,12,290,4,0,
249,12,291,4,0,
249,12,332,4,0,
249,12,347,4,0,
249,12,351,4,0,
249,12,352,4,0,
249,13,16,1,22,
249,13,18,1,1,
249,13,19,4,0,
249,13,34,3,0,
249,13,38,3,0,
249,13,46,4,0,
249,13,56,1,44,
249,13,57,4,0,
249,13,58,4,0,
249,13,59,4,0,
249,13,63,4,0,
249,13,70,4,0,
249,13,85,4,0,
249,13,86,3,0,
249,13,87,4,0,
249,13,89,4,0,
249,13,92,4,0,
249,13,94,4,0,
249,13,102,3,0,
249,13,104,4,0,
249,13,105,1,33,
249,13,113,4,0,
249,13,115,4,0,
249,13,127,4,0,
249,13,129,1,66,
249,13,138,3,0,
249,13,156,4,0,
249,13,164,3,0,
249,13,171,3,0,
249,13,177,1,77,
249,13,182,4,0,
249,13,196,3,0,
249,13,201,4,0,
249,13,202,4,0,
249,13,207,3,0,
249,13,211,4,0,
249,13,216,4,0,
249,13,218,4,0,
249,13,219,1,11,
249,13,219,4,0,
249,13,231,4,0,
249,13,237,4,0,
249,13,240,1,55,
249,13,240,4,0,
249,13,241,4,0,
249,13,246,1,88,
249,13,247,4,0,
249,13,248,1,99,
249,13,249,4,0,
249,13,258,4,0,
249,13,263,4,0,
249,13,285,4,0,
249,13,290,4,0,
249,13,291,4,0,
249,13,332,4,0,
249,13,347,4,0,
249,13,351,4,0,
249,13,352,4,0,
249,14,16,1,9,
249,14,18,1,1,1
249,14,19,4,0,
249,14,46,4,0,
249,14,56,1,37,
249,14,57,4,0,
249,14,58,4,0,
249,14,59,4,0,
249,14,63,4,0,
249,14,70,4,0,
249,14,85,4,0,
249,14,86,4,0,
249,14,87,4,0,
249,14,89,4,0,
249,14,92,4,0,
249,14,94,4,0,
249,14,104,4,0,
249,14,105,1,71,
249,14,113,4,0,
249,14,115,4,0,
249,14,127,4,0,
249,14,138,4,0,
249,14,143,1,99,
249,14,143,3,0,
249,14,148,4,0,
249,14,156,4,0,
249,14,164,4,0,
249,14,173,3,0,
249,14,177,1,43,
249,14,182,4,0,
249,14,196,3,0,
249,14,201,4,0,
249,14,202,3,0,
249,14,207,4,0,
249,14,214,3,0,
249,14,216,4,0,
249,14,218,4,0,
249,14,219,1,65,
249,14,219,4,0,
249,14,231,3,0,
249,14,237,4,0,
249,14,240,1,29,
249,14,240,4,0,
249,14,241,4,0,
249,14,244,4,0,
249,14,246,1,57,
249,14,247,4,0,
249,14,248,1,79,
249,14,249,4,0,
249,14,258,4,0,
249,14,263,4,0,
249,14,271,3,0,
249,14,285,3,0,
249,14,291,4,0,
249,14,304,3,0,
249,14,311,1,1,2
249,14,324,3,0,
249,14,326,1,23,
249,14,332,4,0,
249,14,347,1,93,
249,14,347,4,0,
249,14,355,3,0,
249,14,363,1,85,
249,14,366,3,0,
249,14,386,1,50,
249,14,401,3,0,
249,14,406,3,0,
249,14,407,1,15,
249,14,414,3,0,
249,14,416,4,0,
249,14,428,3,0,
249,14,442,3,0,
249,14,451,4,0,
249,14,472,3,0,
249,14,473,4,0,
249,14,477,4,0,
249,14,496,4,0,
249,14,497,4,0,
249,14,507,4,0,
249,14,523,4,0,
249,14,525,4,0,
249,15,16,1,9,
249,15,18,1,1,1
249,15,19,4,0,
249,15,46,4,0,
249,15,56,1,37,
249,15,57,4,0,
249,15,58,4,0,
249,15,59,4,0,
249,15,63,4,0,
249,15,70,4,0,
249,15,85,4,0,
249,15,86,4,0,
249,15,87,4,0,
249,15,89,4,0,
249,15,92,4,0,
249,15,94,4,0,
249,15,104,4,0,
249,15,105,1,71,
249,15,113,4,0,
249,15,115,4,0,
249,15,127,4,0,
249,15,138,4,0,
249,15,143,1,99,
249,15,148,4,0,
249,15,156,4,0,
249,15,164,4,0,
249,15,177,1,43,
249,15,182,4,0,
249,15,201,4,0,
249,15,207,4,0,
249,15,211,4,0,
249,15,214,4,0,
249,15,216,4,0,
249,15,218,4,0,
249,15,219,1,65,
249,15,219,4,0,
249,15,237,4,0,
249,15,240,1,29,
249,15,240,4,0,
249,15,241,4,0,
249,15,244,4,0,
249,15,246,1,57,
249,15,247,4,0,
249,15,248,1,79,
249,15,249,4,0,
249,15,258,4,0,
249,15,263,4,0,
249,15,311,1,1,2
249,15,326,1,23,
249,15,332,4,0,
249,15,347,1,93,
249,15,347,4,0,
249,15,355,4,0,
249,15,363,1,85,
249,15,386,1,50,
249,15,407,1,15,
249,15,416,4,0,
249,15,451,4,0,
249,15,473,4,0,
249,15,496,4,0,
249,15,497,4,0,
249,15,507,4,0,
249,15,523,4,0,
249,15,525,4,0,
249,15,590,4,0,
249,16,16,1,9,1
249,16,18,1,1,1
249,16,19,4,0,
249,16,46,4,0,
249,16,56,1,37,1
249,16,57,4,0,
249,16,58,4,0,
249,16,59,4,0,
249,16,63,4,0,
249,16,70,4,0,
249,16,85,4,0,
249,16,86,4,0,
249,16,87,4,0,
249,16,89,4,0,
249,16,92,4,0,
249,16,94,4,0,
249,16,104,4,0,
249,16,105,1,71,1
249,16,113,4,0,
249,16,115,4,0,
249,16,127,4,0,
249,16,138,4,0,
249,16,143,1,99,1
249,16,143,3,0,
249,16,148,4,0,
249,16,156,4,0,
249,16,164,4,0,
249,16,173,3,0,
249,16,177,1,43,1
249,16,182,4,0,
249,16,196,3,0,
249,16,201,4,0,
249,16,202,3,0,
249,16,207,4,0,
249,16,211,4,0,
249,16,214,4,0,
249,16,216,4,0,
249,16,218,4,0,
249,16,219,1,65,1
249,16,219,4,0,
249,16,231,3,0,
249,16,237,4,0,
249,16,240,1,29,1
249,16,240,4,0,
249,16,241,4,0,
249,16,244,4,0,
249,16,246,1,57,1
249,16,247,4,0,
249,16,248,1,79,1
249,16,249,4,0,
249,16,258,4,0,
249,16,263,4,0,
249,16,271,3,0,
249,16,285,3,0,
249,16,290,4,0,
249,16,291,4,0,
249,16,304,3,0,
249,16,311,1,1,2
249,16,324,3,0,
249,16,326,1,23,1
249,16,332,4,0,
249,16,347,1,93,1
249,16,347,4,0,
249,16,351,3,0,
249,16,352,3,0,
249,16,355,4,0,
249,16,363,1,85,1
249,16,366,3,0,
249,16,386,1,50,1
249,16,401,3,0,
249,16,406,3,0,
249,16,407,1,15,1
249,16,414,3,0,
249,16,416,4,0,
249,16,428,3,0,
249,16,442,3,0,
249,16,451,4,0,
249,16,472,3,0,
249,16,473,4,0,
249,16,496,4,0,
249,16,497,4,0,
249,16,507,4,0,
249,16,523,4,0,
249,16,525,4,0,
249,16,590,4,0,
249,17,16,1,9,
249,17,18,1,1,1
249,17,19,4,0,
249,17,46,4,0,
249,17,56,1,37,
249,17,57,4,0,
249,17,58,4,0,
249,17,59,4,0,
249,17,63,4,0,
249,17,85,4,0,
249,17,86,4,0,
249,17,87,4,0,
249,17,89,4,0,
249,17,92,4,0,
249,17,94,4,0,
249,17,104,4,0,
249,17,105,1,71,
249,17,113,4,0,
249,17,115,4,0,
249,17,127,4,0,
249,17,138,4,0,
249,17,143,1,99,
249,17,156,4,0,
249,17,164,4,0,
249,17,177,1,43,
249,17,182,4,0,
249,17,201,4,0,
249,17,207,4,0,
249,17,211,4,0,
249,17,214,4,0,
249,17,216,4,0,
249,17,218,4,0,
249,17,219,1,65,
249,17,219,4,0,
249,17,237,4,0,
249,17,240,1,29,
249,17,240,4,0,
249,17,241,4,0,
249,17,244,4,0,
249,17,246,1,57,
249,17,247,4,0,
249,17,248,1,79,
249,17,258,4,0,
249,17,263,4,0,
249,17,311,1,1,2
249,17,326,1,23,
249,17,332,4,0,
249,17,347,1,93,
249,17,347,4,0,
249,17,355,4,0,
249,17,363,1,85,
249,17,386,1,50,
249,17,407,1,15,
249,17,416,4,0,
249,17,451,4,0,
249,17,473,4,0,
249,17,496,4,0,
249,17,497,4,0,
249,17,507,4,0,
249,17,523,4,0,
249,17,525,4,0,
249,17,590,4,0,
249,18,16,1,9,
249,18,18,1,1,1
249,18,19,4,0,
249,18,46,4,0,
249,18,56,1,37,
249,18,57,4,0,
249,18,58,4,0,
249,18,59,4,0,
249,18,63,4,0,
249,18,85,4,0,
249,18,86,4,0,
249,18,87,4,0,
249,18,89,4,0,
249,18,92,4,0,
249,18,94,4,0,
249,18,104,4,0,
249,18,105,1,71,
249,18,113,4,0,
249,18,115,4,0,
249,18,127,4,0,
249,18,138,4,0,
249,18,143,1,99,
249,18,156,4,0,
249,18,164,4,0,
249,18,177,1,43,
249,18,182,4,0,
249,18,201,4,0,
249,18,207,4,0,
249,18,211,4,0,
249,18,214,4,0,
249,18,216,4,0,
249,18,218,4,0,
249,18,219,1,65,
249,18,219,4,0,
249,18,237,4,0,
249,18,240,1,29,
249,18,240,4,0,
249,18,241,4,0,
249,18,244,4,0,
249,18,246,1,57,
249,18,247,4,0,
249,18,248,1,79,
249,18,258,4,0,
249,18,263,4,0,
249,18,311,1,1,2
249,18,326,1,23,
249,18,332,4,0,
249,18,347,1,93,
249,18,347,4,0,
249,18,355,4,0,
249,18,363,1,85,
249,18,386,1,50,
249,18,407,1,15,
249,18,416,4,0,
249,18,451,4,0,
249,18,473,4,0,
249,18,496,4,0,
249,18,497,4,0,
249,18,507,4,0,
249,18,523,4,0,
249,18,525,4,0,
249,18,590,4,0,
250,3,16,1,22,
250,3,18,1,77,
250,3,19,4,0,
250,3,46,4,0,
250,3,63,4,0,
250,3,70,4,0,
250,3,76,4,0,
250,3,87,4,0,
250,3,89,4,0,
250,3,92,4,0,
250,3,94,4,0,
250,3,104,4,0,
250,3,105,1,33,
250,3,126,1,44,
250,3,126,4,0,
250,3,129,1,66,
250,3,129,4,0,
250,3,138,4,0,
250,3,148,4,0,
250,3,156,4,0,
250,3,171,4,0,
250,3,173,4,0,
250,3,174,4,0,
250,3,182,4,0,
250,3,189,4,0,
250,3,192,4,0,
250,3,197,4,0,
250,3,201,4,0,
250,3,202,4,0,
250,3,203,4,0,
250,3,207,4,0,
250,3,211,4,0,
250,3,214,4,0,
250,3,216,4,0,
250,3,218,4,0,
250,3,219,1,11,
250,3,221,1,1,
250,3,225,4,0,
250,3,237,4,0,
250,3,240,4,0,
250,3,241,1,55,
250,3,241,4,0,
250,3,244,4,0,
250,3,246,1,88,
250,3,247,4,0,
250,3,248,1,99,
250,3,249,4,0,
250,4,16,1,22,
250,4,18,1,77,
250,4,19,4,0,
250,4,46,4,0,
250,4,53,3,0,
250,4,63,4,0,
250,4,70,4,0,
250,4,76,4,0,
250,4,85,3,0,
250,4,87,4,0,
250,4,89,4,0,
250,4,92,4,0,
250,4,94,4,0,
250,4,104,4,0,
250,4,105,1,33,
250,4,126,1,44,
250,4,126,4,0,
250,4,129,1,66,
250,4,129,4,0,
250,4,138,4,0,
250,4,148,4,0,
250,4,156,4,0,
250,4,171,4,0,
250,4,173,4,0,
250,4,174,4,0,
250,4,182,4,0,
250,4,189,4,0,
250,4,192,4,0,
250,4,197,4,0,
250,4,201,4,0,
250,4,202,4,0,
250,4,203,4,0,
250,4,207,4,0,
250,4,211,4,0,
250,4,214,4,0,
250,4,216,4,0,
250,4,218,4,0,
250,4,219,1,11,
250,4,221,1,1,
250,4,225,4,0,
250,4,237,4,0,
250,4,240,4,0,
250,4,241,1,55,
250,4,241,4,0,
250,4,244,4,0,
250,4,246,1,88,
250,4,247,4,0,
250,4,248,1,99,
250,4,249,4,0,
250,5,16,1,22,
250,5,18,1,1,
250,5,19,4,0,
250,5,46,4,0,
250,5,53,4,0,
250,5,63,4,0,
250,5,70,4,0,
250,5,76,4,0,
250,5,85,4,0,
250,5,87,4,0,
250,5,89,4,0,
250,5,92,4,0,
250,5,94,4,0,
250,5,104,4,0,
250,5,105,1,33,
250,5,113,4,0,
250,5,115,4,0,
250,5,126,1,44,
250,5,126,4,0,
250,5,129,1,66,
250,5,148,4,0,
250,5,156,4,0,
250,5,182,4,0,
250,5,201,4,0,
250,5,202,4,0,
250,5,211,4,0,
250,5,216,4,0,
250,5,218,4,0,
250,5,219,1,11,
250,5,219,4,0,
250,5,221,1,77,
250,5,237,4,0,
250,5,240,4,0,
250,5,241,1,55,
250,5,241,4,0,
250,5,246,1,88,
250,5,247,4,0,
250,5,248,1,99,
250,5,249,4,0,
250,5,263,4,0,
250,5,290,4,0,
250,5,315,4,0,
250,5,332,4,0,
250,5,347,4,0,
250,5,351,4,0,
250,6,16,1,22,
250,6,18,1,1,
250,6,19,4,0,
250,6,38,3,0,
250,6,46,4,0,
250,6,53,4,0,
250,6,63,4,0,
250,6,70,4,0,
250,6,76,4,0,
250,6,85,4,0,
250,6,86,3,0,
250,6,87,4,0,
250,6,89,4,0,
250,6,92,4,0,
250,6,94,4,0,
250,6,102,3,0,
250,6,104,4,0,
250,6,105,1,33,
250,6,113,4,0,
250,6,115,4,0,
250,6,126,1,44,
250,6,126,4,0,
250,6,129,1,66,
250,6,129,3,0,
250,6,138,3,0,
250,6,148,4,0,
250,6,156,4,0,
250,6,164,3,0,
250,6,173,3,0,
250,6,182,4,0,
250,6,189,3,0,
250,6,201,4,0,
250,6,202,4,0,
250,6,203,3,0,
250,6,207,3,0,
250,6,211,4,0,
250,6,214,3,0,
250,6,216,4,0,
250,6,218,4,0,
250,6,219,1,11,
250,6,219,4,0,
250,6,221,1,77,
250,6,237,4,0,
250,6,240,4,0,
250,6,241,1,55,
250,6,241,4,0,
250,6,244,3,0,
250,6,246,1,88,
250,6,247,4,0,
250,6,248,1,99,
250,6,249,4,0,
250,6,263,4,0,
250,6,290,4,0,
250,6,315,4,0,
250,6,332,4,0,
250,6,347,4,0,
250,6,351,4,0,
250,7,16,1,22,
250,7,18,1,1,
250,7,19,4,0,
250,7,38,3,0,
250,7,46,4,0,
250,7,53,4,0,
250,7,63,4,0,
250,7,70,4,0,
250,7,76,4,0,
250,7,85,4,0,
250,7,86,3,0,
250,7,87,4,0,
250,7,89,4,0,
250,7,92,4,0,
250,7,94,4,0,
250,7,102,3,0,
250,7,104,4,0,
250,7,105,1,33,
250,7,113,4,0,
250,7,115,4,0,
250,7,126,1,44,
250,7,126,4,0,
250,7,129,1,66,
250,7,138,3,0,
250,7,148,4,0,
250,7,156,4,0,
250,7,164,3,0,
250,7,182,4,0,
250,7,201,4,0,
250,7,202,4,0,
250,7,211,4,0,
250,7,216,4,0,
250,7,218,4,0,
250,7,219,1,11,
250,7,219,4,0,
250,7,221,1,77,
250,7,237,4,0,
250,7,240,4,0,
250,7,241,1,55,
250,7,241,4,0,
250,7,246,1,88,
250,7,247,4,0,
250,7,248,1,99,
250,7,249,4,0,
250,7,263,4,0,
250,7,290,4,0,
250,7,315,4,0,
250,7,332,4,0,
250,7,347,4,0,
250,7,351,4,0,
250,8,16,1,15,
250,8,18,1,1,
250,8,19,4,0,
250,8,46,4,0,
250,8,53,4,0,
250,8,63,4,0,
250,8,70,4,0,
250,8,76,4,0,
250,8,85,4,0,
250,8,86,4,0,
250,8,87,4,0,
250,8,89,4,0,
250,8,92,4,0,
250,8,94,4,0,
250,8,104,4,0,
250,8,105,1,23,
250,8,113,4,0,
250,8,115,4,0,
250,8,126,1,29,
250,8,126,4,0,
250,8,129,1,43,
250,8,138,4,0,
250,8,143,1,99,
250,8,148,4,0,
250,8,156,4,0,
250,8,164,4,0,
250,8,182,4,0,
250,8,201,4,0,
250,8,202,4,0,
250,8,203,4,0,
250,8,207,4,0,
250,8,211,4,0,
250,8,214,4,0,
250,8,216,4,0,
250,8,218,4,0,
250,8,219,1,9,
250,8,219,4,0,
250,8,221,1,85,
250,8,237,4,0,
250,8,240,4,0,
250,8,241,1,37,
250,8,241,4,0,
250,8,244,4,0,
250,8,246,1,57,
250,8,247,4,0,
250,8,248,1,79,
250,8,249,4,0,
250,8,261,4,0,
250,8,263,4,0,
250,8,290,4,0,
250,8,315,4,0,
250,8,326,1,65,
250,8,332,4,0,
250,8,347,1,93,
250,8,347,4,0,
250,8,351,4,0,
250,8,355,4,0,
250,8,363,1,51,
250,8,363,4,0,
250,8,365,4,0,
250,8,386,1,71,
250,8,416,4,0,
250,8,432,4,0,
250,8,451,4,0,
250,9,16,1,15,
250,9,18,1,1,
250,9,19,4,0,
250,9,46,4,0,
250,9,53,4,0,
250,9,63,4,0,
250,9,70,4,0,
250,9,76,4,0,
250,9,85,4,0,
250,9,86,4,0,
250,9,87,4,0,
250,9,89,4,0,
250,9,92,4,0,
250,9,94,4,0,
250,9,104,4,0,
250,9,105,1,23,
250,9,113,4,0,
250,9,115,4,0,
250,9,126,1,29,
250,9,126,4,0,
250,9,129,1,43,
250,9,129,3,0,
250,9,138,4,0,
250,9,143,1,99,
250,9,148,4,0,
250,9,156,4,0,
250,9,164,4,0,
250,9,173,3,0,
250,9,182,4,0,
250,9,189,3,0,
250,9,201,4,0,
250,9,202,4,0,
250,9,203,4,0,
250,9,207,4,0,
250,9,211,4,0,
250,9,214,4,0,
250,9,216,4,0,
250,9,218,4,0,
250,9,219,1,9,
250,9,219,4,0,
250,9,221,1,85,
250,9,237,4,0,
250,9,239,3,0,
250,9,240,4,0,
250,9,241,1,37,
250,9,241,4,0,
250,9,244,4,0,
250,9,246,1,57,
250,9,246,3,0,
250,9,247,4,0,
250,9,248,1,79,
250,9,249,4,0,
250,9,257,3,0,
250,9,261,4,0,
250,9,263,4,0,
250,9,290,4,0,
250,9,314,3,0,
250,9,315,4,0,
250,9,324,3,0,
250,9,326,1,65,
250,9,332,4,0,
250,9,347,1,93,
250,9,347,4,0,
250,9,351,4,0,
250,9,355,4,0,
250,9,363,1,51,
250,9,363,4,0,
250,9,365,4,0,
250,9,386,1,71,
250,9,414,3,0,
250,9,416,4,0,
250,9,428,3,0,
250,9,432,4,0,
250,9,442,3,0,
250,9,451,4,0,
250,9,466,3,0,
250,10,16,1,9,
250,10,18,1,1,1
250,10,19,4,0,
250,10,46,4,0,
250,10,53,4,0,
250,10,63,4,0,
250,10,70,4,0,
250,10,76,4,0,
250,10,85,4,0,
250,10,86,4,0,
250,10,87,4,0,
250,10,89,4,0,
250,10,92,4,0,
250,10,94,4,0,
250,10,104,4,0,
250,10,105,1,71,
250,10,113,4,0,
250,10,115,4,0,
250,10,126,1,37,
250,10,126,4,0,
250,10,129,3,0,
250,10,138,4,0,
250,10,143,1,99,
250,10,143,3,0,
250,10,148,4,0,
250,10,156,4,0,
250,10,164,4,0,
250,10,173,3,0,
250,10,182,4,0,
250,10,189,3,0,
250,10,201,4,0,
250,10,202,4,0,
250,10,203,4,0,
250,10,207,4,0,
250,10,211,4,0,
250,10,214,4,0,
250,10,216,4,0,
250,10,218,4,0,
250,10,219,1,65,
250,10,219,4,0,
250,10,221,1,43,
250,10,237,4,0,
250,10,239,3,0,
250,10,240,4,0,
250,10,241,1,29,
250,10,241,4,0,
250,10,244,4,0,
250,10,246,1,57,
250,10,246,3,0,
250,10,247,4,0,
250,10,248,1,79,
250,10,249,4,0,
250,10,257,3,0,
250,10,261,4,0,
250,10,263,4,0,
250,10,290,4,0,
250,10,311,1,1,2
250,10,314,3,0,
250,10,315,4,0,
250,10,324,3,0,
250,10,326,1,23,
250,10,332,4,0,
250,10,347,1,93,
250,10,347,4,0,
250,10,351,4,0,
250,10,355,4,0,
250,10,363,1,85,
250,10,363,4,0,
250,10,365,4,0,
250,10,366,3,0,
250,10,386,1,50,
250,10,413,1,15,
250,10,414,3,0,
250,10,416,4,0,
250,10,428,3,0,
250,10,442,3,0,
250,10,451,4,0,
250,10,466,3,0,
250,11,16,1,9,
250,11,18,1,1,1
250,11,19,4,0,
250,11,46,4,0,
250,11,53,4,0,
250,11,63,4,0,
250,11,70,4,0,
250,11,76,4,0,
250,11,85,4,0,
250,11,86,4,0,
250,11,87,4,0,
250,11,89,4,0,
250,11,92,4,0,
250,11,94,4,0,
250,11,104,4,0,
250,11,105,1,71,
250,11,113,4,0,
250,11,115,4,0,
250,11,126,1,37,
250,11,126,4,0,
250,11,138,4,0,
250,11,143,1,99,
250,11,148,4,0,
250,11,156,4,0,
250,11,164,4,0,
250,11,182,4,0,
250,11,201,4,0,
250,11,207,4,0,
250,11,216,4,0,
250,11,218,4,0,
250,11,219,1,65,
250,11,219,4,0,
250,11,221,1,43,
250,11,237,4,0,
250,11,240,4,0,
250,11,241,1,29,
250,11,241,4,0,
250,11,244,4,0,
250,11,246,1,57,
250,11,247,4,0,
250,11,248,1,79,
250,11,249,4,0,
250,11,261,4,0,
250,11,263,4,0,
250,11,311,1,1,2
250,11,315,4,0,
250,11,326,1,23,
250,11,332,4,0,
250,11,347,1,93,
250,11,347,4,0,
250,11,363,1,85,
250,11,365,4,0,
250,11,386,1,50,
250,11,413,1,15,
250,11,416,4,0,
250,11,451,4,0,
250,11,488,4,0,
250,11,496,4,0,
250,11,497,4,0,
250,11,507,4,0,
250,11,510,4,0,
250,11,523,4,0,
250,12,16,1,22,
250,12,18,1,1,
250,12,19,4,0,
250,12,46,4,0,
250,12,53,4,0,
250,12,63,4,0,
250,12,70,4,0,
250,12,76,4,0,
250,12,85,4,0,
250,12,87,4,0,
250,12,89,4,0,
250,12,92,4,0,
250,12,94,4,0,
250,12,104,4,0,
250,12,105,1,33,
250,12,113,4,0,
250,12,115,4,0,
250,12,126,1,44,
250,12,126,4,0,
250,12,129,1,66,
250,12,148,4,0,
250,12,156,4,0,
250,12,182,4,0,
250,12,201,4,0,
250,12,202,4,0,
250,12,211,4,0,
250,12,216,4,0,
250,12,218,4,0,
250,12,219,1,11,
250,12,219,4,0,
250,12,221,1,77,
250,12,237,4,0,
250,12,240,4,0,
250,12,241,1,55,
250,12,241,4,0,
250,12,246,1,88,
250,12,247,4,0,
250,12,248,1,99,
250,12,249,4,0,
250,12,263,4,0,
250,12,290,4,0,
250,12,315,4,0,
250,12,332,4,0,
250,12,347,4,0,
250,12,351,4,0,
250,13,16,1,22,
250,13,18,1,1,
250,13,19,4,0,
250,13,38,3,0,
250,13,46,4,0,
250,13,53,4,0,
250,13,63,4,0,
250,13,70,4,0,
250,13,76,4,0,
250,13,85,4,0,
250,13,86,3,0,
250,13,87,4,0,
250,13,89,4,0,
250,13,92,4,0,
250,13,94,4,0,
250,13,102,3,0,
250,13,104,4,0,
250,13,105,1,33,
250,13,113,4,0,
250,13,115,4,0,
250,13,126,1,44,
250,13,126,4,0,
250,13,129,1,66,
250,13,138,3,0,
250,13,143,3,0,
250,13,148,4,0,
250,13,156,4,0,
250,13,164,3,0,
250,13,171,3,0,
250,13,182,4,0,
250,13,201,4,0,
250,13,202,4,0,
250,13,207,3,0,
250,13,211,4,0,
250,13,216,4,0,
250,13,218,4,0,
250,13,219,1,11,
250,13,219,4,0,
250,13,221,1,77,
250,13,237,4,0,
250,13,240,4,0,
250,13,241,1,55,
250,13,241,4,0,
250,13,246,1,88,
250,13,247,4,0,
250,13,248,1,99,
250,13,249,4,0,
250,13,263,4,0,
250,13,290,4,0,
250,13,315,4,0,
250,13,332,4,0,
250,13,347,4,0,
250,13,351,4,0,
250,14,16,1,9,
250,14,18,1,1,1
250,14,19,4,0,
250,14,46,4,0,
250,14,53,4,0,
250,14,63,4,0,
250,14,70,4,0,
250,14,76,4,0,
250,14,85,4,0,
250,14,86,4,0,
250,14,87,4,0,
250,14,89,4,0,
250,14,92,4,0,
250,14,94,4,0,
250,14,104,4,0,
250,14,105,1,71,
250,14,113,4,0,
250,14,115,4,0,
250,14,126,1,37,
250,14,126,4,0,
250,14,138,4,0,
250,14,143,1,99,
250,14,143,3,0,
250,14,148,4,0,
250,14,156,4,0,
250,14,164,4,0,
250,14,173,3,0,
250,14,182,4,0,
250,14,201,4,0,
250,14,202,3,0,
250,14,207,4,0,
250,14,214,3,0,
250,14,216,4,0,
250,14,218,4,0,
250,14,219,1,65,
250,14,219,4,0,
250,14,221,1,43,
250,14,237,4,0,
250,14,240,4,0,
250,14,241,1,29,
250,14,241,4,0,
250,14,244,4,0,
250,14,246,1,57,
250,14,247,4,0,
250,14,248,1,79,
250,14,249,4,0,
250,14,257,3,0,
250,14,261,4,0,
250,14,263,4,0,
250,14,304,3,0,
250,14,311,1,1,2
250,14,315,4,0,
250,14,324,3,0,
250,14,326,1,23,
250,14,332,4,0,
250,14,347,1,93,
250,14,347,4,0,
250,14,355,3,0,
250,14,363,1,85,
250,14,365,4,0,
250,14,366,3,0,
250,14,386,1,50,
250,14,413,1,15,
250,14,414,3,0,
250,14,416,4,0,
250,14,428,3,0,
250,14,442,3,0,
250,14,451,4,0,
250,14,488,4,0,
250,14,496,4,0,
250,14,497,4,0,
250,14,507,4,0,
250,14,510,4,0,
250,14,523,4,0,
250,15,16,1,9,
250,15,18,1,1,1
250,15,19,4,0,
250,15,46,4,0,
250,15,53,4,0,
250,15,63,4,0,
250,15,70,4,0,
250,15,76,4,0,
250,15,85,4,0,
250,15,86,4,0,
250,15,87,4,0,
250,15,89,4,0,
250,15,92,4,0,
250,15,94,4,0,
250,15,104,4,0,
250,15,105,1,71,
250,15,113,4,0,
250,15,115,4,0,
250,15,126,1,37,
250,15,126,4,0,
250,15,138,4,0,
250,15,143,1,99,
250,15,148,4,0,
250,15,156,4,0,
250,15,164,4,0,
250,15,182,4,0,
250,15,201,4,0,
250,15,207,4,0,
250,15,211,4,0,
250,15,214,4,0,
250,15,216,4,0,
250,15,218,4,0,
250,15,219,1,65,
250,15,219,4,0,
250,15,221,1,43,
250,15,237,4,0,
250,15,240,4,0,
250,15,241,1,29,
250,15,241,4,0,
250,15,244,4,0,
250,15,246,1,57,
250,15,247,4,0,
250,15,248,1,79,
250,15,249,4,0,
250,15,261,4,0,
250,15,263,4,0,
250,15,311,1,1,2
250,15,315,4,0,
250,15,326,1,23,
250,15,332,4,0,
250,15,347,1,93,
250,15,347,4,0,
250,15,355,4,0,
250,15,363,1,85,
250,15,386,1,50,
250,15,413,1,15,
250,15,416,4,0,
250,15,451,4,0,
250,15,488,4,0,
250,15,496,4,0,
250,15,497,4,0,
250,15,507,4,0,
250,15,510,4,0,
250,15,523,4,0,
250,15,590,4,0,
250,16,16,1,9,1
250,16,18,1,1,1
250,16,19,4,0,
250,16,46,4,0,
250,16,53,4,0,
250,16,63,4,0,
250,16,70,4,0,
250,16,76,4,0,
250,16,85,4,0,
250,16,86,4,0,
250,16,87,4,0,
250,16,89,4,0,
250,16,92,4,0,
250,16,94,4,0,
250,16,104,4,0,
250,16,105,1,71,1
250,16,113,4,0,
250,16,115,4,0,
250,16,126,1,37,1
250,16,126,4,0,
250,16,138,4,0,
250,16,143,1,99,1
250,16,143,3,0,
250,16,148,4,0,
250,16,156,4,0,
250,16,164,4,0,
250,16,173,3,0,
250,16,182,4,0,
250,16,201,4,0,
250,16,202,3,0,
250,16,207,4,0,
250,16,211,4,0,
250,16,214,4,0,
250,16,216,4,0,
250,16,218,4,0,
250,16,219,1,65,1
250,16,219,4,0,
250,16,221,1,43,1
250,16,237,4,0,
250,16,240,4,0,
250,16,241,1,29,1
250,16,241,4,0,
250,16,244,4,0,
250,16,246,1,57,1
250,16,247,4,0,
250,16,248,1,79,1
250,16,249,4,0,
250,16,257,3,0,
250,16,261,4,0,
250,16,263,4,0,
250,16,290,4,0,
250,16,304,3,0,
250,16,311,1,1,2
250,16,315,4,0,
250,16,324,3,0,
250,16,326,1,23,1
250,16,332,4,0,
250,16,347,1,93,1
250,16,347,4,0,
250,16,351,3,0,
250,16,355,4,0,
250,16,363,1,85,1
250,16,366,3,0,
250,16,386,1,50,1
250,16,413,1,15,1
250,16,414,3,0,
250,16,416,4,0,
250,16,428,3,0,
250,16,442,3,0,
250,16,451,4,0,
250,16,488,4,0,
250,16,496,4,0,
250,16,497,4,0,
250,16,507,4,0,
250,16,510,4,0,
250,16,523,4,0,
250,16,590,4,0,
250,17,16,1,9,
250,17,18,1,1,1
250,17,19,4,0,
250,17,46,4,0,
250,17,53,4,0,
250,17,63,4,0,
250,17,76,4,0,
250,17,85,4,0,
250,17,86,4,0,
250,17,87,4,0,
250,17,89,4,0,
250,17,92,4,0,
250,17,94,4,0,
250,17,104,4,0,
250,17,105,1,71,
250,17,113,4,0,
250,17,115,4,0,
250,17,126,1,37,
250,17,126,4,0,
250,17,138,4,0,
250,17,143,1,99,
250,17,156,4,0,
250,17,164,4,0,
250,17,182,4,0,
250,17,201,4,0,
250,17,207,4,0,
250,17,211,4,0,
250,17,214,4,0,
250,17,216,4,0,
250,17,218,4,0,
250,17,219,1,65,
250,17,219,4,0,
250,17,221,1,43,
250,17,237,4,0,
250,17,240,4,0,
250,17,241,1,29,
250,17,241,4,0,
250,17,244,4,0,
250,17,246,1,57,
250,17,247,4,0,
250,17,248,1,79,
250,17,261,4,0,
250,17,263,4,0,
250,17,311,1,1,2
250,17,315,4,0,
250,17,326,1,23,
250,17,332,4,0,
250,17,347,1,93,
250,17,347,4,0,
250,17,355,4,0,
250,17,363,1,85,
250,17,386,1,50,
250,17,413,1,15,
250,17,416,4,0,
250,17,451,4,0,
250,17,488,4,0,
250,17,496,4,0,
250,17,497,4,0,
250,17,507,4,0,
250,17,523,4,0,
250,17,590,4,0,
250,18,16,1,9,
250,18,18,1,1,1
250,18,19,4,0,
250,18,46,4,0,
250,18,53,4,0,
250,18,63,4,0,
250,18,76,4,0,
250,18,85,4,0,
250,18,86,4,0,
250,18,87,4,0,
250,18,89,4,0,
250,18,92,4,0,
250,18,94,4,0,
250,18,104,4,0,
250,18,105,1,71,
250,18,113,4,0,
250,18,115,4,0,
250,18,126,1,37,
250,18,126,4,0,
250,18,138,4,0,
250,18,143,1,99,
250,18,156,4,0,
250,18,164,4,0,
250,18,182,4,0,
250,18,201,4,0,
250,18,207,4,0,
250,18,211,4,0,
250,18,214,4,0,
250,18,216,4,0,
250,18,218,4,0,
250,18,219,1,65,
250,18,219,4,0,
250,18,221,1,43,
250,18,237,4,0,
250,18,240,4,0,
250,18,241,1,29,
250,18,241,4,0,
250,18,244,4,0,
250,18,246,1,57,
250,18,247,4,0,
250,18,248,1,79,
250,18,261,4,0,
250,18,263,4,0,
250,18,311,1,1,2
250,18,315,4,0,
250,18,326,1,23,
250,18,332,4,0,
250,18,347,1,93,
250,18,347,4,0,
250,18,355,4,0,
250,18,363,1,85,
250,18,386,1,50,
250,18,413,1,15,
250,18,416,4,0,
250,18,451,4,0,
250,18,488,4,0,
250,18,496,4,0,
250,18,497,4,0,
250,18,507,4,0,
250,18,523,4,0,
250,18,590,4,0,
251,3,63,4,0,
251,3,73,1,1,1
251,3,76,4,0,
251,3,92,4,0,
251,3,93,1,1,2
251,3,94,4,0,
251,3,104,4,0,
251,3,105,1,1,3
251,3,111,4,0,
251,3,129,4,0,
251,3,138,4,0,
251,3,148,4,0,
251,3,156,4,0,
251,3,171,4,0,
251,3,173,4,0,
251,3,174,4,0,
251,3,182,4,0,
251,3,189,4,0,
251,3,195,1,50,
251,3,197,4,0,
251,3,201,4,0,
251,3,202,4,0,
251,3,203,4,0,
251,3,207,4,0,
251,3,214,4,0,
251,3,215,1,1,4
251,3,216,4,0,
251,3,218,4,0,
251,3,219,1,10,
251,3,226,1,40,
251,3,230,4,0,
251,3,237,4,0,
251,3,240,4,0,
251,3,241,4,0,
251,3,244,4,0,
251,3,246,1,20,
251,3,247,4,0,
251,3,248,1,30,
251,4,63,4,0,
251,4,73,1,1,1
251,4,76,4,0,
251,4,92,4,0,
251,4,93,1,1,2
251,4,94,4,0,
251,4,104,4,0,
251,4,105,1,1,3
251,4,111,4,0,
251,4,129,4,0,
251,4,138,4,0,
251,4,148,4,0,
251,4,156,4,0,
251,4,171,4,0,
251,4,173,4,0,
251,4,174,4,0,
251,4,182,4,0,
251,4,189,4,0,
251,4,195,1,50,
251,4,197,4,0,
251,4,201,4,0,
251,4,202,4,0,
251,4,203,4,0,
251,4,207,4,0,
251,4,214,4,0,
251,4,215,1,1,4
251,4,216,4,0,
251,4,218,4,0,
251,4,219,1,10,
251,4,226,1,40,
251,4,230,4,0,
251,4,237,4,0,
251,4,240,4,0,
251,4,241,4,0,
251,4,244,4,0,
251,4,246,1,20,
251,4,247,4,0,
251,4,248,1,30,
251,5,15,4,0,
251,5,63,4,0,
251,5,73,1,1,1
251,5,76,4,0,
251,5,92,4,0,
251,5,93,1,1,2
251,5,94,4,0,
251,5,104,4,0,
251,5,105,1,1,3
251,5,113,4,0,
251,5,115,4,0,
251,5,148,4,0,
251,5,156,4,0,
251,5,182,4,0,
251,5,195,1,50,
251,5,201,4,0,
251,5,202,4,0,
251,5,215,1,1,4
251,5,216,4,0,
251,5,218,4,0,
251,5,219,1,10,
251,5,219,4,0,
251,5,226,1,40,
251,5,237,4,0,
251,5,240,4,0,
251,5,241,4,0,
251,5,246,1,20,
251,5,247,4,0,
251,5,248,1,30,
251,5,263,4,0,
251,5,285,4,0,
251,5,290,4,0,
251,5,332,4,0,
251,5,347,4,0,
251,5,351,4,0,
251,5,352,4,0,
251,6,14,3,0,
251,6,15,4,0,
251,6,38,3,0,
251,6,63,4,0,
251,6,73,1,1,1
251,6,76,4,0,
251,6,92,4,0,
251,6,93,1,1,2
251,6,94,4,0,
251,6,102,3,0,
251,6,104,4,0,
251,6,105,1,1,3
251,6,111,3,0,
251,6,113,4,0,
251,6,115,4,0,
251,6,118,3,0,
251,6,129,3,0,
251,6,138,3,0,
251,6,148,4,0,
251,6,156,4,0,
251,6,164,3,0,
251,6,173,3,0,
251,6,182,4,0,
251,6,189,3,0,
251,6,195,1,50,
251,6,201,4,0,
251,6,202,4,0,
251,6,203,3,0,
251,6,207,3,0,
251,6,214,3,0,
251,6,215,1,1,4
251,6,216,4,0,
251,6,218,4,0,
251,6,219,1,10,
251,6,219,4,0,
251,6,226,1,40,
251,6,237,4,0,
251,6,240,4,0,
251,6,241,4,0,
251,6,244,3,0,
251,6,246,1,20,
251,6,247,4,0,
251,6,248,1,30,
251,6,263,4,0,
251,6,285,4,0,
251,6,290,4,0,
251,6,332,4,0,
251,6,347,4,0,
251,6,351,4,0,
251,6,352,4,0,
251,7,14,3,0,
251,7,15,4,0,
251,7,38,3,0,
251,7,63,4,0,
251,7,73,1,1,1
251,7,76,4,0,
251,7,92,4,0,
251,7,93,1,1,2
251,7,94,4,0,
251,7,102,3,0,
251,7,104,4,0,
251,7,105,1,1,3
251,7,113,4,0,
251,7,115,4,0,
251,7,118,3,0,
251,7,138,3,0,
251,7,148,4,0,
251,7,156,4,0,
251,7,164,3,0,
251,7,182,4,0,
251,7,195,1,50,
251,7,201,4,0,
251,7,202,4,0,
251,7,215,1,1,4
251,7,216,4,0,
251,7,218,4,0,
251,7,219,1,10,
251,7,219,4,0,
251,7,226,1,40,
251,7,237,4,0,
251,7,240,4,0,
251,7,241,4,0,
251,7,246,1,20,
251,7,247,4,0,
251,7,248,1,30,
251,7,263,4,0,
251,7,285,4,0,
251,7,290,4,0,
251,7,332,4,0,
251,7,347,4,0,
251,7,351,4,0,
251,7,352,4,0,
251,8,14,4,0,
251,8,15,4,0,
251,8,63,4,0,
251,8,73,1,1,1
251,8,76,4,0,
251,8,86,4,0,
251,8,92,4,0,
251,8,93,1,1,2
251,8,94,4,0,
251,8,104,4,0,
251,8,105,1,1,3
251,8,113,4,0,
251,8,115,4,0,
251,8,138,4,0,
251,8,148,4,0,
251,8,156,4,0,
251,8,164,4,0,
251,8,182,4,0,
251,8,195,1,91,
251,8,201,4,0,
251,8,202,4,0,
251,8,203,4,0,
251,8,207,4,0,
251,8,214,4,0,
251,8,215,1,1,4
251,8,216,4,0,
251,8,218,4,0,
251,8,219,1,10,
251,8,219,4,0,
251,8,226,1,37,
251,8,237,4,0,
251,8,240,4,0,
251,8,241,4,0,
251,8,244,4,0,
251,8,246,1,28,
251,8,247,4,0,
251,8,248,1,64,
251,8,263,4,0,
251,8,285,4,0,
251,8,290,4,0,
251,8,318,4,0,
251,8,332,4,0,
251,8,345,1,19,
251,8,347,4,0,
251,8,351,4,0,
251,8,352,4,0,
251,8,361,1,73,
251,8,363,1,46,
251,8,363,4,0,
251,8,369,4,0,
251,8,374,4,0,
251,8,377,1,55,
251,8,412,4,0,
251,8,416,4,0,
251,8,433,4,0,
251,8,437,1,82,
251,8,446,4,0,
251,8,447,4,0,
251,8,451,4,0,
251,9,14,4,0,
251,9,15,4,0,
251,9,63,4,0,
251,9,73,1,1,1
251,9,76,4,0,
251,9,86,4,0,
251,9,92,4,0,
251,9,93,1,1,2
251,9,94,4,0,
251,9,104,4,0,
251,9,105,1,1,3
251,9,113,4,0,
251,9,115,4,0,
251,9,129,3,0,
251,9,138,4,0,
251,9,148,4,0,
251,9,156,4,0,
251,9,164,4,0,
251,9,173,3,0,
251,9,182,4,0,
251,9,189,3,0,
251,9,195,1,91,
251,9,201,4,0,
251,9,202,4,0,
251,9,203,4,0,
251,9,207,4,0,
251,9,214,4,0,
251,9,215,1,1,4
251,9,216,4,0,
251,9,218,4,0,
251,9,219,1,10,
251,9,219,4,0,
251,9,226,1,37,
251,9,235,3,0,
251,9,237,4,0,
251,9,240,4,0,
251,9,241,4,0,
251,9,244,4,0,
251,9,246,1,28,
251,9,246,3,0,
251,9,247,4,0,
251,9,248,1,64,
251,9,253,3,0,
251,9,263,4,0,
251,9,270,3,0,
251,9,271,3,0,
251,9,285,4,0,
251,9,290,4,0,
251,9,318,4,0,
251,9,324,3,0,
251,9,332,4,0,
251,9,345,1,19,
251,9,347,4,0,
251,9,351,4,0,
251,9,352,4,0,
251,9,361,1,73,
251,9,363,1,46,
251,9,363,4,0,
251,9,369,4,0,
251,9,374,4,0,
251,9,377,1,55,
251,9,387,3,0,
251,9,389,3,0,
251,9,402,3,0,
251,9,412,4,0,
251,9,414,3,0,
251,9,416,4,0,
251,9,428,3,0,
251,9,433,4,0,
251,9,437,1,82,
251,9,446,4,0,
251,9,447,4,0,
251,9,451,4,0,
251,10,14,4,0,
251,10,15,4,0,
251,10,63,4,0,
251,10,73,1,1,1
251,10,76,4,0,
251,10,86,4,0,
251,10,92,4,0,
251,10,93,1,1,2
251,10,94,4,0,
251,10,104,4,0,
251,10,105,1,1,3
251,10,113,4,0,
251,10,115,4,0,
251,10,129,3,0,
251,10,138,4,0,
251,10,148,4,0,
251,10,156,4,0,
251,10,164,4,0,
251,10,173,3,0,
251,10,182,4,0,
251,10,189,3,0,
251,10,195,1,91,
251,10,201,4,0,
251,10,202,4,0,
251,10,203,4,0,
251,10,207,4,0,
251,10,214,4,0,
251,10,215,1,1,4
251,10,215,3,0,
251,10,216,4,0,
251,10,218,4,0,
251,10,219,1,10,
251,10,219,4,0,
251,10,226,1,37,
251,10,235,3,0,
251,10,237,4,0,
251,10,240,4,0,
251,10,241,4,0,
251,10,244,4,0,
251,10,246,1,28,
251,10,246,3,0,
251,10,247,4,0,
251,10,248,1,64,
251,10,253,3,0,
251,10,263,4,0,
251,10,270,3,0,
251,10,271,3,0,
251,10,277,3,0,
251,10,285,4,0,
251,10,290,4,0,
251,10,318,4,0,
251,10,324,3,0,
251,10,332,4,0,
251,10,345,1,19,
251,10,347,4,0,
251,10,351,4,0,
251,10,352,4,0,
251,10,361,1,73,
251,10,363,1,46,
251,10,363,4,0,
251,10,369,4,0,
251,10,374,4,0,
251,10,377,1,55,
251,10,387,3,0,
251,10,388,3,0,
251,10,389,3,0,
251,10,402,3,0,
251,10,412,4,0,
251,10,414,3,0,
251,10,416,4,0,
251,10,428,3,0,
251,10,433,4,0,
251,10,437,1,82,
251,10,446,4,0,
251,10,447,4,0,
251,10,451,4,0,
251,11,14,4,0,
251,11,15,4,0,
251,11,63,4,0,
251,11,73,1,1,1
251,11,76,4,0,
251,11,86,4,0,
251,11,92,4,0,
251,11,93,1,1,2
251,11,94,4,0,
251,11,104,4,0,
251,11,105,1,1,3
251,11,113,4,0,
251,11,115,4,0,
251,11,138,4,0,
251,11,148,4,0,
251,11,156,4,0,
251,11,164,4,0,
251,11,182,4,0,
251,11,195,1,91,
251,11,201,4,0,
251,11,207,4,0,
251,11,215,1,1,4
251,11,216,4,0,
251,11,218,4,0,
251,11,219,1,10,
251,11,219,4,0,
251,11,226,1,37,
251,11,237,4,0,
251,11,240,4,0,
251,11,241,4,0,
251,11,244,4,0,
251,11,246,1,28,
251,11,247,4,0,
251,11,248,1,64,
251,11,263,4,0,
251,11,332,4,0,
251,11,345,1,19,
251,11,347,4,0,
251,11,361,1,73,
251,11,363,1,46,
251,11,369,4,0,
251,11,374,4,0,
251,11,377,1,55,
251,11,412,4,0,
251,11,416,4,0,
251,11,433,4,0,
251,11,437,1,82,
251,11,447,4,0,
251,11,451,4,0,
251,11,496,4,0,
251,11,497,4,0,
251,12,15,4,0,
251,12,63,4,0,
251,12,73,1,1,1
251,12,76,4,0,
251,12,92,4,0,
251,12,93,1,1,2
251,12,94,4,0,
251,12,104,4,0,
251,12,105,1,1,3
251,12,113,4,0,
251,12,115,4,0,
251,12,148,4,0,
251,12,156,4,0,
251,12,182,4,0,
251,12,195,1,50,
251,12,201,4,0,
251,12,202,4,0,
251,12,215,1,1,4
251,12,216,4,0,
251,12,218,4,0,
251,12,219,1,10,
251,12,219,4,0,
251,12,226,1,40,
251,12,237,4,0,
251,12,240,4,0,
251,12,241,4,0,
251,12,246,1,20,
251,12,247,4,0,
251,12,248,1,30,
251,12,263,4,0,
251,12,285,4,0,
251,12,290,4,0,
251,12,332,4,0,
251,12,347,4,0,
251,12,351,4,0,
251,12,352,4,0,
251,13,15,4,0,
251,13,38,3,0,
251,13,63,4,0,
251,13,73,1,1,1
251,13,76,4,0,
251,13,92,4,0,
251,13,93,1,1,2
251,13,94,4,0,
251,13,102,3,0,
251,13,104,4,0,
251,13,105,1,1,3
251,13,113,4,0,
251,13,115,4,0,
251,13,138,3,0,
251,13,148,4,0,
251,13,156,4,0,
251,13,164,3,0,
251,13,171,3,0,
251,13,182,4,0,
251,13,195,1,50,
251,13,201,4,0,
251,13,202,4,0,
251,13,207,3,0,
251,13,215,1,1,4
251,13,216,4,0,
251,13,218,4,0,
251,13,219,1,10,
251,13,219,4,0,
251,13,226,1,40,
251,13,237,4,0,
251,13,240,4,0,
251,13,241,4,0,
251,13,246,1,20,
251,13,247,4,0,
251,13,248,1,30,
251,13,263,4,0,
251,13,285,4,0,
251,13,290,4,0,
251,13,332,4,0,
251,13,347,4,0,
251,13,351,4,0,
251,13,352,4,0,
251,14,14,4,0,
251,14,15,4,0,
251,14,63,4,0,
251,14,73,1,1,1
251,14,76,4,0,
251,14,86,4,0,
251,14,92,4,0,
251,14,93,1,1,2
251,14,94,4,0,
251,14,104,4,0,
251,14,105,1,1,3
251,14,113,4,0,
251,14,115,4,0,
251,14,138,4,0,
251,14,148,4,0,
251,14,156,4,0,
251,14,164,4,0,
251,14,173,3,0,
251,14,182,4,0,
251,14,195,1,91,
251,14,201,4,0,
251,14,202,3,0,
251,14,207,4,0,
251,14,214,3,0,
251,14,215,1,1,4
251,14,215,3,0,
251,14,216,4,0,
251,14,218,4,0,
251,14,219,1,10,
251,14,219,4,0,
251,14,226,1,37,
251,14,235,3,0,
251,14,237,4,0,
251,14,240,4,0,
251,14,241,4,0,
251,14,244,4,0,
251,14,246,1,28,
251,14,247,4,0,
251,14,248,1,64,
251,14,253,3,0,
251,14,263,4,0,
251,14,270,3,0,
251,14,271,3,0,
251,14,277,3,0,
251,14,285,3,0,
251,14,324,3,0,
251,14,332,4,0,
251,14,345,1,19,
251,14,347,4,0,
251,14,361,1,73,
251,14,363,1,46,
251,14,369,4,0,
251,14,374,4,0,
251,14,377,1,55,
251,14,387,3,0,
251,14,388,3,0,
251,14,402,3,0,
251,14,412,4,0,
251,14,414,3,0,
251,14,416,4,0,
251,14,428,3,0,
251,14,433,4,0,
251,14,437,1,82,
251,14,446,3,0,
251,14,447,4,0,
251,14,451,4,0,
251,14,472,3,0,
251,14,478,3,0,
251,14,496,4,0,
251,14,497,4,0,
251,15,14,4,0,
251,15,15,4,0,
251,15,63,4,0,
251,15,73,1,1,1
251,15,76,4,0,
251,15,86,4,0,
251,15,92,4,0,
251,15,93,1,1,2
251,15,94,4,0,
251,15,104,4,0,
251,15,105,1,1,3
251,15,113,4,0,
251,15,115,4,0,
251,15,138,4,0,
251,15,148,4,0,
251,15,156,4,0,
251,15,164,4,0,
251,15,182,4,0,
251,15,195,1,91,
251,15,201,4,0,
251,15,207,4,0,
251,15,214,4,0,
251,15,215,1,1,4
251,15,216,4,0,
251,15,218,4,0,
251,15,219,1,10,
251,15,219,4,0,
251,15,226,1,37,
251,15,237,4,0,
251,15,240,4,0,
251,15,241,4,0,
251,15,244,4,0,
251,15,246,1,28,
251,15,247,4,0,
251,15,248,1,64,
251,15,263,4,0,
251,15,267,4,0,
251,15,332,4,0,
251,15,345,1,19,
251,15,347,4,0,
251,15,361,1,73,
251,15,363,1,46,
251,15,369,4,0,
251,15,374,4,0,
251,15,377,1,55,
251,15,412,4,0,
251,15,416,4,0,
251,15,433,4,0,
251,15,437,1,82,
251,15,447,4,0,
251,15,451,4,0,
251,15,496,4,0,
251,15,497,4,0,
251,15,590,4,0,
251,15,605,4,0,
251,16,14,4,0,
251,16,15,4,0,
251,16,63,4,0,
251,16,73,1,1,1
251,16,76,4,0,
251,16,86,4,0,
251,16,92,4,0,
251,16,93,1,1,2
251,16,94,4,0,
251,16,104,4,0,
251,16,105,1,1,3
251,16,113,4,0,
251,16,115,4,0,
251,16,138,4,0,
251,16,148,4,0,
251,16,156,4,0,
251,16,164,4,0,
251,16,173,3,0,
251,16,182,4,0,
251,16,195,1,91,1
251,16,201,4,0,
251,16,202,3,0,
251,16,207,4,0,
251,16,214,4,0,
251,16,215,1,1,4
251,16,215,3,0,
251,16,216,4,0,
251,16,218,4,0,
251,16,219,1,10,1
251,16,219,4,0,
251,16,226,1,37,1
251,16,235,3,0,
251,16,237,4,0,
251,16,240,4,0,
251,16,241,4,0,
251,16,244,4,0,
251,16,246,1,28,1
251,16,247,4,0,
251,16,248,1,64,1
251,16,253,3,0,
251,16,263,4,0,
251,16,267,4,0,
251,16,270,3,0,
251,16,271,3,0,
251,16,277,3,0,
251,16,285,3,0,
251,16,290,4,0,
251,16,324,3,0,
251,16,332,4,0,
251,16,345,1,19,1
251,16,347,4,0,
251,16,351,3,0,
251,16,352,3,0,
251,16,361,1,73,1
251,16,363,1,46,1
251,16,369,4,0,
251,16,374,4,0,
251,16,377,1,55,1
251,16,387,3,0,
251,16,388,3,0,
251,16,402,3,0,
251,16,412,4,0,
251,16,414,3,0,
251,16,416,4,0,
251,16,428,3,0,
251,16,433,4,0,
251,16,437,1,82,1
251,16,446,3,0,
251,16,447,4,0,
251,16,451,4,0,
251,16,472,3,0,
251,16,478,3,0,
251,16,496,4,0,
251,16,497,4,0,
251,16,590,4,0,
251,16,605,4,0,
251,17,14,4,0,
251,17,63,4,0,
251,17,73,1,1,1
251,17,76,4,0,
251,17,86,4,0,
251,17,92,4,0,
251,17,93,1,1,2
251,17,94,4,0,
251,17,104,4,0,
251,17,105,1,1,3
251,17,113,4,0,
251,17,115,4,0,
251,17,138,4,0,
251,17,156,4,0,
251,17,164,4,0,
251,17,182,4,0,
251,17,195,1,91,
251,17,201,4,0,
251,17,207,4,0,
251,17,214,4,0,
251,17,215,1,1,4
251,17,216,4,0,
251,17,218,4,0,
251,17,219,1,10,
251,17,219,4,0,
251,17,226,1,37,
251,17,237,4,0,
251,17,240,4,0,
251,17,241,4,0,
251,17,244,4,0,
251,17,246,1,28,
251,17,247,4,0,
251,17,248,1,64,
251,17,263,4,0,
251,17,267,4,0,
251,17,332,4,0,
251,17,345,1,19,
251,17,347,4,0,
251,17,361,1,73,
251,17,363,1,46,
251,17,369,4,0,
251,17,374,4,0,
251,17,377,1,55,
251,17,412,4,0,
251,17,416,4,0,
251,17,433,4,0,
251,17,437,1,82,
251,17,447,4,0,
251,17,451,4,0,
251,17,496,4,0,
251,17,497,4,0,
251,17,590,4,0,
251,17,605,4,0,
251,18,14,4,0,
251,18,63,4,0,
251,18,73,1,1,1
251,18,76,4,0,
251,18,86,4,0,
251,18,92,4,0,
251,18,93,1,1,2
251,18,94,4,0,
251,18,104,4,0,
251,18,105,1,1,3
251,18,113,4,0,
251,18,115,4,0,
251,18,138,4,0,
251,18,156,4,0,
251,18,164,4,0,
251,18,182,4,0,
251,18,195,1,91,
251,18,201,4,0,
251,18,207,4,0,
251,18,214,4,0,
251,18,215,1,1,4
251,18,216,4,0,
251,18,218,4,0,
251,18,219,1,10,
251,18,219,4,0,
251,18,226,1,37,
251,18,237,4,0,
251,18,240,4,0,
251,18,241,4,0,
251,18,244,4,0,
251,18,246,1,28,
251,18,247,4,0,
251,18,248,1,64,
251,18,263,4,0,
251,18,267,4,0,
251,18,332,4,0,
251,18,345,1,19,
251,18,347,4,0,
251,18,361,1,73,
251,18,363,1,46,
251,18,369,4,0,
251,18,374,4,0,
251,18,377,1,55,
251,18,412,4,0,
251,18,416,4,0,
251,18,433,4,0,
251,18,437,1,82,
251,18,447,4,0,
251,18,451,4,0,
251,18,496,4,0,
251,18,497,4,0,
251,18,590,4,0,
251,18,605,4,0,
252,5,1,1,1,1
252,5,15,4,0,
252,5,21,1,36,
252,5,43,1,1,2
252,5,70,4,0,
252,5,71,1,6,
252,5,72,1,26,
252,5,73,2,0,
252,5,76,4,0,
252,5,91,4,0,
252,5,92,4,0,
252,5,97,1,31,
252,5,98,1,11,
252,5,103,1,21,
252,5,104,4,0,
252,5,148,4,0,
252,5,156,4,0,
252,5,182,4,0,
252,5,197,1,41,
252,5,202,1,46,
252,5,202,4,0,
252,5,213,4,0,
252,5,216,4,0,
252,5,218,4,0,
252,5,219,4,0,
252,5,225,2,0,
252,5,228,1,16,
252,5,231,4,0,
252,5,237,4,0,
252,5,241,4,0,
252,5,242,2,0,
252,5,249,4,0,
252,5,263,4,0,
252,5,264,4,0,
252,5,280,4,0,
252,5,283,2,0,
252,5,290,4,0,
252,5,300,2,0,
252,5,306,2,0,
252,5,317,4,0,
252,5,331,4,0,
252,5,332,4,0,
252,6,1,1,1,1
252,6,5,3,0,
252,6,9,3,0,
252,6,14,3,0,
252,6,15,4,0,
252,6,21,1,36,
252,6,25,3,0,
252,6,34,3,0,
252,6,38,3,0,
252,6,43,1,1,2
252,6,68,3,0,
252,6,69,3,0,
252,6,70,4,0,
252,6,71,1,6,
252,6,72,1,26,
252,6,73,2,0,
252,6,76,4,0,
252,6,91,4,0,
252,6,92,4,0,
252,6,97,1,31,
252,6,98,1,11,
252,6,102,3,0,
252,6,103,1,21,
252,6,104,4,0,
252,6,129,3,0,
252,6,148,4,0,
252,6,156,4,0,
252,6,164,3,0,
252,6,173,3,0,
252,6,182,4,0,
252,6,189,3,0,
252,6,197,1,41,
252,6,202,1,46,
252,6,202,4,0,
252,6,203,3,0,
252,6,207,3,0,
252,6,210,3,0,
252,6,213,4,0,
252,6,214,3,0,
252,6,216,4,0,
252,6,218,4,0,
252,6,219,4,0,
252,6,223,3,0,
252,6,225,2,0,
252,6,228,1,16,
252,6,231,4,0,
252,6,237,4,0,
252,6,241,4,0,
252,6,242,2,0,
252,6,249,4,0,
252,6,263,4,0,
252,6,264,4,0,
252,6,280,4,0,
252,6,283,2,0,
252,6,290,4,0,
252,6,300,2,0,
252,6,306,2,0,
252,6,317,4,0,
252,6,331,4,0,
252,6,332,4,0,
252,7,1,1,1,1
252,7,5,3,0,
252,7,14,3,0,
252,7,15,4,0,
252,7,21,1,36,
252,7,25,3,0,
252,7,34,3,0,
252,7,38,3,0,
252,7,43,1,1,2
252,7,68,3,0,
252,7,69,3,0,
252,7,70,4,0,
252,7,71,1,6,
252,7,72,1,26,
252,7,73,2,0,
252,7,76,4,0,
252,7,91,4,0,
252,7,92,4,0,
252,7,97,1,31,
252,7,98,1,11,
252,7,102,3,0,
252,7,103,1,21,
252,7,104,4,0,
252,7,148,4,0,
252,7,156,4,0,
252,7,164,3,0,
252,7,182,4,0,
252,7,197,1,41,
252,7,202,1,46,
252,7,202,4,0,
252,7,213,4,0,
252,7,216,4,0,
252,7,218,4,0,
252,7,219,4,0,
252,7,225,2,0,
252,7,228,1,16,
252,7,231,4,0,
252,7,237,4,0,
252,7,241,4,0,
252,7,242,2,0,
252,7,249,4,0,
252,7,263,4,0,
252,7,264,4,0,
252,7,280,4,0,
252,7,283,2,0,
252,7,290,4,0,
252,7,300,2,0,
252,7,306,2,0,
252,7,317,4,0,
252,7,331,4,0,
252,7,332,4,0,
252,8,1,1,1,1
252,8,14,4,0,
252,8,15,4,0,
252,8,21,1,36,
252,8,24,2,0,
252,8,43,1,1,2
252,8,70,4,0,
252,8,71,1,6,
252,8,72,1,26,
252,8,73,2,0,
252,8,76,4,0,
252,8,91,4,0,
252,8,92,4,0,
252,8,97,1,31,
252,8,98,1,11,
252,8,103,1,21,
252,8,104,4,0,
252,8,148,4,0,
252,8,156,4,0,
252,8,157,4,0,
252,8,164,4,0,
252,8,182,4,0,
252,8,197,1,41,
252,8,202,1,46,
252,8,202,4,0,
252,8,203,4,0,
252,8,207,4,0,
252,8,213,4,0,
252,8,214,4,0,
252,8,216,4,0,
252,8,218,4,0,
252,8,219,4,0,
252,8,225,2,0,
252,8,228,1,16,
252,8,231,4,0,
252,8,235,2,0,
252,8,237,4,0,
252,8,241,4,0,
252,8,242,2,0,
252,8,249,4,0,
252,8,263,4,0,
252,8,264,4,0,
252,8,280,4,0,
252,8,283,2,0,
252,8,290,4,0,
252,8,300,2,0,
252,8,306,2,0,
252,8,317,4,0,
252,8,320,2,0,
252,8,331,4,0,
252,8,332,4,0,
252,8,345,2,0,
252,8,363,4,0,
252,8,374,4,0,
252,8,388,2,0,
252,8,409,4,0,
252,8,412,1,51,
252,8,412,4,0,
252,8,437,2,0,
252,8,445,4,0,
252,8,447,4,0,
252,9,1,1,1,1
252,9,9,3,0,
252,9,14,4,0,
252,9,15,4,0,
252,9,21,1,36,
252,9,24,2,0,
252,9,43,1,1,2
252,9,70,4,0,
252,9,71,1,6,
252,9,72,1,26,
252,9,73,2,0,
252,9,76,4,0,
252,9,91,4,0,
252,9,92,4,0,
252,9,97,1,31,
252,9,98,1,11,
252,9,103,1,21,
252,9,104,4,0,
252,9,129,3,0,
252,9,148,4,0,
252,9,156,4,0,
252,9,157,4,0,
252,9,164,4,0,
252,9,173,3,0,
252,9,182,4,0,
252,9,189,3,0,
252,9,197,1,41,
252,9,202,1,46,
252,9,202,4,0,
252,9,203,4,0,
252,9,207,4,0,
252,9,210,3,0,
252,9,213,4,0,
252,9,214,4,0,
252,9,216,4,0,
252,9,218,4,0,
252,9,219,4,0,
252,9,225,2,0,
252,9,228,1,16,
252,9,231,4,0,
252,9,235,2,0,
252,9,235,3,0,
252,9,237,4,0,
252,9,241,4,0,
252,9,242,2,0,
252,9,249,4,0,
252,9,263,4,0,
252,9,264,4,0,
252,9,280,4,0,
252,9,283,2,0,
252,9,283,3,0,
252,9,290,4,0,
252,9,300,2,0,
252,9,306,2,0,
252,9,317,4,0,
252,9,320,2,0,
252,9,331,4,0,
252,9,332,4,0,
252,9,345,2,0,
252,9,363,4,0,
252,9,374,4,0,
252,9,388,2,0,
252,9,402,3,0,
252,9,409,4,0,
252,9,412,1,51,
252,9,412,4,0,
252,9,437,2,0,
252,9,445,4,0,
252,9,447,4,0,
252,10,1,1,1,1
252,10,9,3,0,
252,10,13,2,0,
252,10,14,4,0,
252,10,15,4,0,
252,10,21,1,36,
252,10,24,2,0,
252,10,29,3,0,
252,10,43,1,1,2
252,10,67,3,0,
252,10,70,4,0,
252,10,71,1,6,
252,10,72,1,26,
252,10,73,2,0,
252,10,76,4,0,
252,10,91,4,0,
252,10,92,4,0,
252,10,97,1,31,
252,10,98,1,11,
252,10,103,1,21,
252,10,104,4,0,
252,10,129,3,0,
252,10,148,4,0,
252,10,156,4,0,
252,10,157,4,0,
252,10,164,4,0,
252,10,173,3,0,
252,10,182,4,0,
252,10,189,3,0,
252,10,197,1,41,
252,10,202,1,46,
252,10,202,4,0,
252,10,203,4,0,
252,10,207,4,0,
252,10,210,3,0,
252,10,213,4,0,
252,10,214,4,0,
252,10,216,4,0,
252,10,218,4,0,
252,10,219,4,0,
252,10,225,2,0,
252,10,228,1,16,
252,10,231,4,0,
252,10,235,2,0,
252,10,235,3,0,
252,10,237,4,0,
252,10,241,4,0,
252,10,242,2,0,
252,10,249,4,0,
252,10,263,4,0,
252,10,264,4,0,
252,10,280,4,0,
252,10,283,2,0,
252,10,283,3,0,
252,10,290,4,0,
252,10,300,2,0,
252,10,306,2,0,
252,10,317,4,0,
252,10,320,2,0,
252,10,331,4,0,
252,10,332,4,0,
252,10,345,2,0,
252,10,363,4,0,
252,10,374,4,0,
252,10,388,2,0,
252,10,388,3,0,
252,10,402,3,0,
252,10,409,4,0,
252,10,412,1,51,
252,10,412,4,0,
252,10,437,2,0,
252,10,445,4,0,
252,10,447,4,0,
252,11,1,1,1,1
252,11,13,2,0,
252,11,14,4,0,
252,11,15,4,0,
252,11,21,1,36,
252,11,24,2,0,
252,11,43,1,1,2
252,11,70,4,0,
252,11,71,1,6,
252,11,72,1,26,
252,11,73,2,0,
252,11,76,4,0,
252,11,91,4,0,
252,11,92,4,0,
252,11,97,1,31,
252,11,98,1,11,
252,11,103,1,21,
252,11,104,4,0,
252,11,148,4,0,
252,11,156,4,0,
252,11,157,4,0,
252,11,164,4,0,
252,11,182,4,0,
252,11,197,1,41,
252,11,202,1,46,
252,11,207,4,0,
252,11,213,4,0,
252,11,216,4,0,
252,11,218,4,0,
252,11,219,4,0,
252,11,225,2,0,
252,11,228,1,16,
252,11,235,2,0,
252,11,237,4,0,
252,11,241,4,0,
252,11,242,2,0,
252,11,249,4,0,
252,11,263,4,0,
252,11,280,4,0,
252,11,283,2,0,
252,11,300,2,0,
252,11,306,2,0,
252,11,317,4,0,
252,11,320,2,0,
252,11,331,2,0,
252,11,332,4,0,
252,11,345,2,0,
252,11,363,2,0,
252,11,374,4,0,
252,11,388,2,0,
252,11,412,1,51,
252,11,412,4,0,
252,11,437,2,0,
252,11,447,4,0,
252,11,496,4,0,
252,11,512,4,0,
252,11,520,3,0,
252,12,1,1,1,1
252,12,15,4,0,
252,12,21,1,36,
252,12,43,1,1,2
252,12,70,4,0,
252,12,71,1,6,
252,12,72,1,26,
252,12,76,4,0,
252,12,91,4,0,
252,12,92,4,0,
252,12,97,1,31,
252,12,98,1,11,
252,12,103,1,21,
252,12,104,4,0,
252,12,148,4,0,
252,12,156,4,0,
252,12,182,4,0,
252,12,197,1,41,
252,12,202,1,46,
252,12,202,4,0,
252,12,213,4,0,
252,12,216,4,0,
252,12,218,4,0,
252,12,219,4,0,
252,12,228,1,16,
252,12,231,4,0,
252,12,237,4,0,
252,12,241,4,0,
252,12,249,4,0,
252,12,263,4,0,
252,12,264,4,0,
252,12,280,4,0,
252,12,290,4,0,
252,12,317,4,0,
252,12,331,4,0,
252,12,332,4,0,
252,13,1,1,1,1
252,13,15,4,0,
252,13,21,1,36,
252,13,34,3,0,
252,13,38,3,0,
252,13,43,1,1,2
252,13,69,3,0,
252,13,70,4,0,
252,13,71,1,6,
252,13,72,1,26,
252,13,76,4,0,
252,13,91,4,0,
252,13,92,4,0,
252,13,97,1,31,
252,13,98,1,11,
252,13,102,3,0,
252,13,103,1,21,
252,13,104,4,0,
252,13,148,4,0,
252,13,156,4,0,
252,13,164,3,0,
252,13,182,4,0,
252,13,197,1,41,
252,13,202,1,46,
252,13,202,4,0,
252,13,207,3,0,
252,13,213,4,0,
252,13,216,4,0,
252,13,218,4,0,
252,13,219,4,0,
252,13,228,1,16,
252,13,231,4,0,
252,13,237,4,0,
252,13,241,4,0,
252,13,249,4,0,
252,13,263,4,0,
252,13,264,4,0,
252,13,280,4,0,
252,13,290,4,0,
252,13,317,4,0,
252,13,331,4,0,
252,13,332,4,0,
252,14,1,1,1,1
252,14,9,3,0,
252,14,13,2,0,
252,14,14,4,0,
252,14,15,4,0,
252,14,21,1,36,
252,14,24,2,0,
252,14,43,1,1,2
252,14,67,3,0,
252,14,70,4,0,
252,14,71,1,6,
252,14,72,1,26,
252,14,73,2,0,
252,14,76,4,0,
252,14,91,4,0,
252,14,92,4,0,
252,14,97,1,31,
252,14,98,1,11,
252,14,103,1,21,
252,14,104,4,0,
252,14,148,4,0,
252,14,156,4,0,
252,14,157,4,0,
252,14,164,4,0,
252,14,173,3,0,
252,14,182,4,0,
252,14,197,1,41,
252,14,202,1,46,
252,14,202,3,0,
252,14,207,4,0,
252,14,213,4,0,
252,14,214,3,0,
252,14,216,4,0,
252,14,218,4,0,
252,14,219,4,0,
252,14,225,2,0,
252,14,228,1,16,
252,14,231,3,0,
252,14,235,2,0,
252,14,235,3,0,
252,14,237,4,0,
252,14,241,4,0,
252,14,242,2,0,
252,14,249,4,0,
252,14,263,4,0,
252,14,280,4,0,
252,14,283,2,0,
252,14,283,3,0,
252,14,300,2,0,
252,14,306,2,0,
252,14,317,4,0,
252,14,320,2,0,
252,14,331,2,0,
252,14,332,4,0,
252,14,345,2,0,
252,14,363,2,0,
252,14,374,4,0,
252,14,388,2,0,
252,14,388,3,0,
252,14,402,3,0,
252,14,409,3,0,
252,14,412,1,51,
252,14,412,4,0,
252,14,437,2,0,
252,14,447,4,0,
252,14,496,4,0,
252,14,512,4,0,
252,14,520,3,0,
252,15,1,1,1,1
252,15,13,2,0,
252,15,14,4,0,
252,15,15,4,0,
252,15,21,1,36,
252,15,24,2,0,
252,15,43,1,1,2
252,15,70,4,0,
252,15,71,1,6,
252,15,72,1,26,
252,15,73,2,0,
252,15,76,4,0,
252,15,91,4,0,
252,15,92,4,0,
252,15,97,1,31,
252,15,98,1,11,
252,15,103,1,21,
252,15,104,4,0,
252,15,148,4,0,
252,15,156,4,0,
252,15,157,4,0,
252,15,164,4,0,
252,15,182,4,0,
252,15,197,1,41,
252,15,202,1,46,
252,15,207,4,0,
252,15,213,4,0,
252,15,214,4,0,
252,15,216,4,0,
252,15,218,4,0,
252,15,219,4,0,
252,15,225,2,0,
252,15,228,1,16,
252,15,235,2,0,
252,15,237,4,0,
252,15,241,4,0,
252,15,242,2,0,
252,15,249,4,0,
252,15,263,4,0,
252,15,267,4,0,
252,15,280,4,0,
252,15,283,2,0,
252,15,300,2,0,
252,15,306,2,0,
252,15,317,4,0,
252,15,320,2,0,
252,15,331,2,0,
252,15,332,4,0,
252,15,345,2,0,
252,15,363,2,0,
252,15,374,4,0,
252,15,388,2,0,
252,15,412,1,50,
252,15,412,4,0,
252,15,437,2,0,
252,15,447,4,0,
252,15,496,4,0,
252,15,512,4,0,
252,15,520,3,0,
252,15,580,2,0,
252,15,590,4,0,
252,15,612,4,0,
252,16,1,1,1,1
252,16,9,3,0,
252,16,13,2,0,
252,16,14,4,0,
252,16,15,4,0,
252,16,21,1,29,1
252,16,24,2,0,
252,16,43,1,1,2
252,16,67,3,0,
252,16,70,4,0,
252,16,71,1,5,1
252,16,72,1,13,1
252,16,73,2,0,
252,16,76,4,0,
252,16,91,4,0,
252,16,92,4,0,
252,16,97,1,25,1
252,16,98,1,9,1
252,16,103,1,49,1
252,16,104,4,0,
252,16,148,4,0,
252,16,156,4,0,
252,16,157,4,0,
252,16,164,4,0,
252,16,173,3,0,
252,16,182,4,0,
252,16,197,1,33,1
252,16,202,1,21,1
252,16,202,3,0,
252,16,207,4,0,
252,16,213,4,0,
252,16,214,4,0,
252,16,216,4,0,
252,16,218,4,0,
252,16,219,4,0,
252,16,225,2,0,
252,16,228,1,17,1
252,16,231,3,0,
252,16,235,2,0,
252,16,235,3,0,
252,16,237,4,0,
252,16,241,4,0,
252,16,242,2,0,
252,16,249,4,0,
252,16,263,4,0,
252,16,264,3,0,
252,16,267,4,0,
252,16,280,4,0,
252,16,283,1,45,1
252,16,283,2,0,
252,16,283,3,0,
252,16,290,4,0,
252,16,300,2,0,
252,16,306,2,0,
252,16,317,4,0,
252,16,320,2,0,
252,16,331,2,0,
252,16,332,4,0,
252,16,345,2,0,
252,16,363,2,0,
252,16,374,4,0,
252,16,388,2,0,
252,16,388,3,0,
252,16,402,3,0,
252,16,409,3,0,
252,16,412,1,37,1
252,16,412,4,0,
252,16,437,2,0,
252,16,447,4,0,
252,16,496,4,0,
252,16,501,1,41,1
252,16,512,4,0,
252,16,520,3,0,
252,16,580,2,0,
252,16,590,4,0,
252,16,612,4,0,
252,17,1,1,1,1
252,17,13,2,0,
252,17,14,4,0,
252,17,21,1,29,
252,17,24,2,0,
252,17,43,1,1,2
252,17,71,1,5,
252,17,72,1,13,
252,17,73,2,0,
252,17,76,4,0,
252,17,92,4,0,
252,17,97,1,25,
252,17,98,1,9,
252,17,103,1,49,
252,17,104,4,0,
252,17,156,4,0,
252,17,157,4,0,
252,17,164,4,0,
252,17,182,4,0,
252,17,197,1,33,
252,17,202,1,21,
252,17,207,4,0,
252,17,213,4,0,
252,17,214,4,0,
252,17,216,4,0,
252,17,218,4,0,
252,17,219,4,0,
252,17,225,2,0,
252,17,228,1,17,
252,17,235,2,0,
252,17,237,4,0,
252,17,241,4,0,
252,17,242,2,0,
252,17,263,4,0,
252,17,267,4,0,
252,17,280,4,0,
252,17,283,1,45,
252,17,283,2,0,
252,17,300,2,0,
252,17,306,2,0,
252,17,317,4,0,
252,17,320,2,0,
252,17,331,2,0,
252,17,332,4,0,
252,17,345,2,0,
252,17,363,2,0,
252,17,374,4,0,
252,17,388,2,0,
252,17,412,1,37,
252,17,412,4,0,
252,17,437,2,0,
252,17,447,4,0,
252,17,496,4,0,
252,17,501,1,41,
252,17,512,4,0,
252,17,520,3,0,
252,17,526,4,0,
252,17,580,2,0,
252,17,590,4,0,
252,18,1,1,1,1
252,18,13,2,0,
252,18,14,4,0,
252,18,21,1,29,
252,18,24,2,0,
252,18,43,1,1,2
252,18,71,1,5,
252,18,72,1,13,
252,18,73,2,0,
252,18,76,4,0,
252,18,92,4,0,
252,18,97,1,25,
252,18,98,1,9,
252,18,103,1,49,
252,18,104,4,0,
252,18,156,4,0,
252,18,157,4,0,
252,18,164,4,0,
252,18,182,4,0,
252,18,197,1,33,
252,18,202,1,21,
252,18,207,4,0,
252,18,213,4,0,
252,18,214,4,0,
252,18,216,4,0,
252,18,218,4,0,
252,18,219,4,0,
252,18,225,2,0,
252,18,228,1,17,
252,18,235,2,0,
252,18,237,4,0,
252,18,241,4,0,
252,18,242,2,0,
252,18,263,4,0,
252,18,267,4,0,
252,18,280,4,0,
252,18,283,1,45,
252,18,283,2,0,
252,18,300,2,0,
252,18,306,2,0,
252,18,317,4,0,
252,18,320,2,0,
252,18,331,2,0,
252,18,332,4,0,
252,18,345,2,0,
252,18,363,2,0,
252,18,374,4,0,
252,18,388,2,0,
252,18,412,1,37,
252,18,412,4,0,
252,18,437,2,0,
252,18,447,4,0,
252,18,496,4,0,
252,18,501,1,41,
252,18,512,4,0,
252,18,520,3,0,
252,18,526,4,0,
252,18,580,2,0,
252,18,590,4,0,
253,5,1,1,1,1
253,5,15,4,0,
253,5,21,1,41,
253,5,43,1,1,2
253,5,70,4,0,
253,5,71,1,1,3
253,5,71,1,6,
253,5,76,4,0,
253,5,91,4,0,
253,5,92,4,0,
253,5,97,1,35,
253,5,98,1,1,4
253,5,98,1,11,
253,5,103,1,23,
253,5,104,4,0,
253,5,148,4,0,
253,5,156,4,0,
253,5,182,4,0,
253,5,197,1,47,
253,5,202,4,0,
253,5,206,1,53,
253,5,210,1,16,
253,5,213,4,0,
253,5,216,4,0,
253,5,218,4,0,
253,5,219,4,0,
253,5,228,1,17,
253,5,231,4,0,
253,5,237,4,0,
253,5,241,4,0,
253,5,249,4,0,
253,5,263,4,0,
253,5,264,4,0,
253,5,280,4,0,
253,5,290,4,0,
253,5,317,4,0,
253,5,331,4,0,
253,5,332,4,0,
253,5,348,1,29,
253,6,1,1,1,1
253,6,5,3,0,
253,6,9,3,0,
253,6,14,3,0,
253,6,15,4,0,
253,6,21,1,41,
253,6,25,3,0,
253,6,34,3,0,
253,6,38,3,0,
253,6,43,1,1,2
253,6,68,3,0,
253,6,69,3,0,
253,6,70,4,0,
253,6,71,1,1,3
253,6,71,1,6,
253,6,76,4,0,
253,6,91,4,0,
253,6,92,4,0,
253,6,97,1,35,
253,6,98,1,1,4
253,6,98,1,11,
253,6,102,3,0,
253,6,103,1,23,
253,6,104,4,0,
253,6,129,3,0,
253,6,148,4,0,
253,6,156,4,0,
253,6,164,3,0,
253,6,173,3,0,
253,6,182,4,0,
253,6,189,3,0,
253,6,197,1,47,
253,6,202,4,0,
253,6,203,3,0,
253,6,206,1,53,
253,6,207,3,0,
253,6,210,1,16,
253,6,210,3,0,
253,6,213,4,0,
253,6,214,3,0,
253,6,216,4,0,
253,6,218,4,0,
253,6,219,4,0,
253,6,223,3,0,
253,6,228,1,17,
253,6,231,4,0,
253,6,237,4,0,
253,6,241,4,0,
253,6,249,4,0,
253,6,263,4,0,
253,6,264,4,0,
253,6,280,4,0,
253,6,290,4,0,
253,6,317,4,0,
253,6,331,4,0,
253,6,332,4,0,
253,6,348,1,29,
253,7,1,1,1,1
253,7,5,3,0,
253,7,14,3,0,
253,7,15,4,0,
253,7,21,1,41,
253,7,25,3,0,
253,7,34,3,0,
253,7,38,3,0,
253,7,43,1,1,2
253,7,68,3,0,
253,7,69,3,0,
253,7,70,4,0,
253,7,71,1,1,3
253,7,71,1,6,
253,7,76,4,0,
253,7,91,4,0,
253,7,92,4,0,
253,7,97,1,35,
253,7,98,1,1,4
253,7,98,1,11,
253,7,102,3,0,
253,7,103,1,23,
253,7,104,4,0,
253,7,148,4,0,
253,7,156,4,0,
253,7,164,3,0,
253,7,182,4,0,
253,7,197,1,47,
253,7,202,4,0,
253,7,206,1,53,
253,7,210,1,16,
253,7,213,4,0,
253,7,216,4,0,
253,7,218,4,0,
253,7,219,4,0,
253,7,228,1,17,
253,7,231,4,0,
253,7,237,4,0,
253,7,241,4,0,
253,7,249,4,0,
253,7,263,4,0,
253,7,264,4,0,
253,7,280,4,0,
253,7,290,4,0,
253,7,317,4,0,
253,7,331,4,0,
253,7,332,4,0,
253,7,348,1,29,
253,8,1,1,1,1
253,8,14,4,0,
253,8,15,4,0,
253,8,21,1,41,
253,8,43,1,1,2
253,8,70,4,0,
253,8,71,1,1,3
253,8,71,1,6,
253,8,76,4,0,
253,8,91,4,0,
253,8,92,4,0,
253,8,97,1,35,
253,8,98,1,1,4
253,8,98,1,11,
253,8,103,1,23,
253,8,104,4,0,
253,8,148,4,0,
253,8,156,4,0,
253,8,157,4,0,
253,8,164,4,0,
253,8,182,4,0,
253,8,197,1,47,
253,8,202,4,0,
253,8,203,4,0,
253,8,206,1,53,
253,8,206,4,0,
253,8,207,4,0,
253,8,210,1,16,
253,8,213,4,0,
253,8,214,4,0,
253,8,216,4,0,
253,8,218,4,0,
253,8,219,4,0,
253,8,228,1,17,
253,8,231,4,0,
253,8,237,4,0,
253,8,241,4,0,
253,8,249,4,0,
253,8,263,4,0,
253,8,264,4,0,
253,8,280,4,0,
253,8,290,4,0,
253,8,317,4,0,
253,8,331,4,0,
253,8,332,4,0,
253,8,348,1,29,
253,8,363,4,0,
253,8,374,4,0,
253,8,404,4,0,
253,8,409,4,0,
253,8,412,4,0,
253,8,437,1,59,
253,8,445,4,0,
253,8,447,4,0,
253,9,1,1,1,1
253,9,9,3,0,
253,9,14,4,0,
253,9,15,4,0,
253,9,21,1,41,
253,9,43,1,1,2
253,9,70,4,0,
253,9,71,1,1,3
253,9,71,1,6,
253,9,76,4,0,
253,9,91,4,0,
253,9,92,4,0,
253,9,97,1,35,
253,9,98,1,1,4
253,9,98,1,11,
253,9,103,1,23,
253,9,104,4,0,
253,9,129,3,0,
253,9,148,4,0,
253,9,156,4,0,
253,9,157,4,0,
253,9,164,4,0,
253,9,173,3,0,
253,9,182,4,0,
253,9,189,3,0,
253,9,197,1,47,
253,9,202,4,0,
253,9,203,4,0,
253,9,206,1,53,
253,9,206,4,0,
253,9,207,4,0,
253,9,210,1,16,
253,9,210,3,0,
253,9,213,4,0,
253,9,214,4,0,
253,9,216,4,0,
253,9,218,4,0,
253,9,219,4,0,
253,9,228,1,17,
253,9,231,4,0,
253,9,235,3,0,
253,9,237,4,0,
253,9,241,4,0,
253,9,249,4,0,
253,9,263,4,0,
253,9,264,4,0,
253,9,280,4,0,
253,9,283,3,0,
253,9,290,4,0,
253,9,317,4,0,
253,9,331,4,0,
253,9,332,4,0,
253,9,348,1,29,
253,9,363,4,0,
253,9,374,4,0,
253,9,402,3,0,
253,9,404,4,0,
253,9,409,4,0,
253,9,412,4,0,
253,9,437,1,59,
253,9,445,4,0,
253,9,447,4,0,
253,10,1,1,1,1
253,10,9,3,0,
253,10,14,4,0,
253,10,15,4,0,
253,10,21,1,41,
253,10,29,3,0,
253,10,43,1,1,2
253,10,67,3,0,
253,10,70,4,0,
253,10,71,1,1,3
253,10,71,1,6,
253,10,76,4,0,
253,10,91,4,0,
253,10,92,4,0,
253,10,97,1,35,
253,10,98,1,1,4
253,10,98,1,11,
253,10,103,1,23,
253,10,104,4,0,
253,10,129,3,0,
253,10,148,4,0,
253,10,156,4,0,
253,10,157,4,0,
253,10,164,4,0,
253,10,173,3,0,
253,10,182,4,0,
253,10,189,3,0,
253,10,197,1,47,
253,10,202,4,0,
253,10,203,4,0,
253,10,206,1,53,
253,10,206,4,0,
253,10,207,4,0,
253,10,210,1,16,
253,10,210,3,0,
253,10,213,4,0,
253,10,214,4,0,
253,10,216,4,0,
253,10,218,4,0,
253,10,219,4,0,
253,10,228,1,17,
253,10,231,4,0,
253,10,235,3,0,
253,10,237,4,0,
253,10,241,4,0,
253,10,249,4,0,
253,10,263,4,0,
253,10,264,4,0,
253,10,280,4,0,
253,10,283,3,0,
253,10,290,4,0,
253,10,317,4,0,
253,10,331,4,0,
253,10,332,4,0,
253,10,348,1,29,
253,10,363,4,0,
253,10,374,4,0,
253,10,388,3,0,
253,10,402,3,0,
253,10,404,4,0,
253,10,409,4,0,
253,10,412,4,0,
253,10,437,1,59,
253,10,445,4,0,
253,10,447,4,0,
253,11,1,1,1,1
253,11,14,4,0,
253,11,15,4,0,
253,11,21,1,41,
253,11,43,1,1,2
253,11,70,4,0,
253,11,71,1,1,3
253,11,71,1,6,
253,11,76,4,0,
253,11,91,4,0,
253,11,92,4,0,
253,11,97,1,35,
253,11,98,1,1,4
253,11,98,1,11,
253,11,103,1,23,
253,11,104,4,0,
253,11,148,4,0,
253,11,156,4,0,
253,11,157,4,0,
253,11,164,4,0,
253,11,182,4,0,
253,11,197,1,47,
253,11,206,1,53,
253,11,206,4,0,
253,11,207,4,0,
253,11,210,1,16,
253,11,213,4,0,
253,11,216,4,0,
253,11,218,4,0,
253,11,219,4,0,
253,11,228,1,17,
253,11,237,4,0,
253,11,241,4,0,
253,11,249,4,0,
253,11,263,4,0,
253,11,280,4,0,
253,11,317,4,0,
253,11,332,4,0,
253,11,348,1,29,
253,11,374,4,0,
253,11,404,4,0,
253,11,412,4,0,
253,11,437,1,59,
253,11,447,4,0,
253,11,490,4,0,
253,11,496,4,0,
253,11,512,4,0,
253,11,520,3,0,
253,12,1,1,1,1
253,12,15,4,0,
253,12,21,1,41,
253,12,43,1,1,2
253,12,70,4,0,
253,12,71,1,1,3
253,12,71,1,6,
253,12,76,4,0,
253,12,91,4,0,
253,12,92,4,0,
253,12,97,1,35,
253,12,98,1,1,4
253,12,98,1,11,
253,12,103,1,23,
253,12,104,4,0,
253,12,148,4,0,
253,12,156,4,0,
253,12,182,4,0,
253,12,197,1,47,
253,12,202,4,0,
253,12,206,1,53,
253,12,210,1,16,
253,12,213,4,0,
253,12,216,4,0,
253,12,218,4,0,
253,12,219,4,0,
253,12,228,1,17,
253,12,231,4,0,
253,12,237,4,0,
253,12,241,4,0,
253,12,249,4,0,
253,12,263,4,0,
253,12,264,4,0,
253,12,280,4,0,
253,12,290,4,0,
253,12,317,4,0,
253,12,331,4,0,
253,12,332,4,0,
253,12,348,1,29,
253,13,1,1,1,1
253,13,15,4,0,
253,13,21,1,41,
253,13,34,3,0,
253,13,38,3,0,
253,13,43,1,1,2
253,13,69,3,0,
253,13,70,4,0,
253,13,71,1,1,3
253,13,71,1,6,
253,13,76,4,0,
253,13,91,4,0,
253,13,92,4,0,
253,13,97,1,35,
253,13,98,1,1,4
253,13,98,1,11,
253,13,102,3,0,
253,13,103,1,23,
253,13,104,4,0,
253,13,148,4,0,
253,13,156,4,0,
253,13,164,3,0,
253,13,182,4,0,
253,13,197,1,47,
253,13,202,4,0,
253,13,206,1,53,
253,13,207,3,0,
253,13,210,1,16,
253,13,213,4,0,
253,13,216,4,0,
253,13,218,4,0,
253,13,219,4,0,
253,13,228,1,17,
253,13,231,4,0,
253,13,237,4,0,
253,13,241,4,0,
253,13,249,4,0,
253,13,263,4,0,
253,13,264,4,0,
253,13,280,4,0,
253,13,290,4,0,
253,13,317,4,0,
253,13,331,4,0,
253,13,332,4,0,
253,13,348,1,29,
253,14,1,1,1,1
253,14,9,3,0,
253,14,14,4,0,
253,14,15,4,0,
253,14,21,1,41,
253,14,43,1,1,2
253,14,67,3,0,
253,14,70,4,0,
253,14,71,1,1,3
253,14,71,1,6,
253,14,76,4,0,
253,14,91,4,0,
253,14,92,4,0,
253,14,97,1,35,
253,14,98,1,1,4
253,14,98,1,11,
253,14,103,1,23,
253,14,104,4,0,
253,14,148,4,0,
253,14,156,4,0,
253,14,157,4,0,
253,14,164,4,0,
253,14,173,3,0,
253,14,182,4,0,
253,14,197,1,47,
253,14,202,3,0,
253,14,206,1,53,
253,14,206,4,0,
253,14,207,4,0,
253,14,210,1,16,
253,14,213,4,0,
253,14,214,3,0,
253,14,216,4,0,
253,14,218,4,0,
253,14,219,4,0,
253,14,228,1,17,
253,14,231,3,0,
253,14,235,3,0,
253,14,237,4,0,
253,14,241,4,0,
253,14,249,4,0,
253,14,263,4,0,
253,14,280,4,0,
253,14,283,3,0,
253,14,317,4,0,
253,14,332,4,0,
253,14,348,1,29,
253,14,374,4,0,
253,14,388,3,0,
253,14,402,3,0,
253,14,404,4,0,
253,14,409,3,0,
253,14,412,4,0,
253,14,437,1,59,
253,14,447,4,0,
253,14,490,4,0,
253,14,496,4,0,
253,14,512,4,0,
253,14,520,3,0,
253,15,1,1,1,1
253,15,14,4,0,
253,15,15,4,0,
253,15,21,1,41,
253,15,43,1,1,2
253,15,70,4,0,
253,15,71,1,1,3
253,15,71,1,6,
253,15,76,4,0,
253,15,91,4,0,
253,15,92,4,0,
253,15,97,1,35,
253,15,98,1,1,4
253,15,98,1,11,
253,15,103,1,23,
253,15,104,4,0,
253,15,148,4,0,
253,15,156,4,0,
253,15,157,4,0,
253,15,164,4,0,
253,15,182,4,0,
253,15,197,1,47,
253,15,206,1,53,
253,15,206,4,0,
253,15,207,4,0,
253,15,210,1,16,
253,15,213,4,0,
253,15,214,4,0,
253,15,216,4,0,
253,15,218,4,0,
253,15,219,4,0,
253,15,228,1,17,
253,15,237,4,0,
253,15,241,4,0,
253,15,249,4,0,
253,15,263,4,0,
253,15,267,4,0,
253,15,280,4,0,
253,15,317,4,0,
253,15,332,4,0,
253,15,348,1,29,
253,15,374,4,0,
253,15,404,4,0,
253,15,412,4,0,
253,15,437,1,59,
253,15,447,4,0,
253,15,490,4,0,
253,15,496,4,0,
253,15,512,4,0,
253,15,520,3,0,
253,15,590,4,0,
253,15,612,4,0,
253,16,1,1,1,1
253,16,9,3,0,
253,16,14,4,0,
253,16,15,4,0,
253,16,21,1,33,1
253,16,43,1,1,2
253,16,67,3,0,
253,16,70,4,0,
253,16,71,1,1,3
253,16,71,1,5,1
253,16,72,1,13,1
253,16,76,4,0,
253,16,91,4,0,
253,16,92,4,0,
253,16,97,1,28,1
253,16,98,1,1,4
253,16,98,1,9,1
253,16,103,1,63,1
253,16,104,4,0,
253,16,148,4,0,
253,16,156,4,0,
253,16,157,4,0,
253,16,164,4,0,
253,16,173,3,0,
253,16,182,4,0,
253,16,197,1,38,1
253,16,202,3,0,
253,16,206,1,48,1
253,16,206,4,0,
253,16,207,4,0,
253,16,210,1,16,1
253,16,213,4,0,
253,16,214,4,0,
253,16,216,4,0,
253,16,218,4,0,
253,16,219,4,0,
253,16,228,1,18,1
253,16,231,3,0,
253,16,235,3,0,
253,16,237,4,0,
253,16,241,4,0,
253,16,249,4,0,
253,16,263,4,0,
253,16,264,3,0,
253,16,267,4,0,
253,16,280,4,0,
253,16,283,3,0,
253,16,290,4,0,
253,16,317,4,0,
253,16,332,4,0,
253,16,348,1,23,1
253,16,374,4,0,
253,16,388,3,0,
253,16,402,3,0,
253,16,404,1,43,1
253,16,404,4,0,
253,16,409,3,0,
253,16,412,4,0,
253,16,437,1,58,1
253,16,447,4,0,
253,16,490,4,0,
253,16,496,4,0,
253,16,501,1,53,1
253,16,512,4,0,
253,16,520,3,0,
253,16,590,4,0,
253,16,612,4,0,
253,17,1,1,1,2
253,17,14,4,0,
253,17,21,1,33,
253,17,43,1,1,3
253,17,71,1,1,4
253,17,71,1,5,
253,17,72,1,13,
253,17,76,4,0,
253,17,92,4,0,
253,17,97,1,28,
253,17,98,1,1,5
253,17,98,1,9,
253,17,103,1,63,
253,17,104,4,0,
253,17,156,4,0,
253,17,157,4,0,
253,17,164,4,0,
253,17,182,4,0,
253,17,197,1,38,
253,17,206,1,48,
253,17,206,4,0,
253,17,207,4,0,
253,17,210,1,0,
253,17,210,1,1,1
253,17,213,4,0,
253,17,214,4,0,
253,17,216,4,0,
253,17,218,4,0,
253,17,219,4,0,
253,17,228,1,18,
253,17,237,4,0,
253,17,241,4,0,
253,17,263,4,0,
253,17,267,4,0,
253,17,280,4,0,
253,17,317,4,0,
253,17,332,4,0,
253,17,348,1,23,
253,17,374,4,0,
253,17,404,1,43,
253,17,404,4,0,
253,17,412,4,0,
253,17,437,1,58,
253,17,447,4,0,
253,17,490,4,0,
253,17,496,4,0,
253,17,501,1,53,
253,17,512,4,0,
253,17,520,3,0,
253,17,526,4,0,
253,17,590,4,0,
253,18,1,1,1,2
253,18,14,4,0,
253,18,21,1,33,
253,18,43,1,1,3
253,18,71,1,1,4
253,18,71,1,5,
253,18,72,1,13,
253,18,76,4,0,
253,18,92,4,0,
253,18,97,1,28,
253,18,98,1,1,5
253,18,98,1,9,
253,18,103,1,63,
253,18,104,4,0,
253,18,156,4,0,
253,18,157,4,0,
253,18,164,4,0,
253,18,182,4,0,
253,18,197,1,38,
253,18,206,1,48,
253,18,206,4,0,
253,18,207,4,0,
253,18,210,1,0,
253,18,210,1,1,1
253,18,213,4,0,
253,18,214,4,0,
253,18,216,4,0,
253,18,218,4,0,
253,18,219,4,0,
253,18,228,1,18,
253,18,237,4,0,
253,18,241,4,0,
253,18,263,4,0,
253,18,267,4,0,
253,18,280,4,0,
253,18,317,4,0,
253,18,332,4,0,
253,18,348,1,23,
253,18,374,4,0,
253,18,404,1,43,
253,18,404,4,0,
253,18,412,4,0,
253,18,437,1,58,
253,18,447,4,0,
253,18,490,4,0,
253,18,496,4,0,
253,18,501,1,53,
253,18,512,4,0,
253,18,520,3,0,
253,18,526,4,0,
253,18,590,4,0,
254,5,1,1,1,1
254,5,15,4,0,
254,5,21,1,43,
254,5,43,1,1,2
254,5,46,4,0,
254,5,63,4,0,
254,5,70,4,0,
254,5,71,1,1,3
254,5,71,1,6,
254,5,76,4,0,
254,5,89,4,0,
254,5,91,4,0,
254,5,92,4,0,
254,5,97,1,35,
254,5,98,1,1,4
254,5,98,1,11,
254,5,103,1,23,
254,5,104,4,0,
254,5,148,4,0,
254,5,156,4,0,
254,5,182,4,0,
254,5,197,1,51,
254,5,202,4,0,
254,5,206,1,59,
254,5,210,1,16,
254,5,213,4,0,
254,5,216,4,0,
254,5,218,4,0,
254,5,219,4,0,
254,5,228,1,17,
254,5,231,4,0,
254,5,237,4,0,
254,5,241,4,0,
254,5,249,4,0,
254,5,263,4,0,
254,5,264,4,0,
254,5,280,4,0,
254,5,290,4,0,
254,5,317,4,0,
254,5,331,4,0,
254,5,332,4,0,
254,5,337,4,0,
254,5,348,1,29,
254,6,1,1,1,1
254,6,5,3,0,
254,6,9,3,0,
254,6,14,3,0,
254,6,15,4,0,
254,6,21,1,43,
254,6,25,3,0,
254,6,34,3,0,
254,6,38,3,0,
254,6,43,1,1,2
254,6,46,4,0,
254,6,63,4,0,
254,6,68,3,0,
254,6,69,3,0,
254,6,70,4,0,
254,6,71,1,1,3
254,6,71,1,6,
254,6,76,4,0,
254,6,89,4,0,
254,6,91,4,0,
254,6,92,4,0,
254,6,97,1,35,
254,6,98,1,1,4
254,6,98,1,11,
254,6,102,3,0,
254,6,103,1,23,
254,6,104,4,0,
254,6,129,3,0,
254,6,148,4,0,
254,6,156,4,0,
254,6,164,3,0,
254,6,173,3,0,
254,6,182,4,0,
254,6,189,3,0,
254,6,197,1,51,
254,6,202,4,0,
254,6,203,3,0,
254,6,206,1,59,
254,6,207,3,0,
254,6,210,1,16,
254,6,210,3,0,
254,6,213,4,0,
254,6,214,3,0,
254,6,216,4,0,
254,6,218,4,0,
254,6,219,4,0,
254,6,223,3,0,
254,6,228,1,17,
254,6,231,4,0,
254,6,237,4,0,
254,6,241,4,0,
254,6,249,4,0,
254,6,263,4,0,
254,6,264,4,0,
254,6,280,4,0,
254,6,290,4,0,
254,6,317,4,0,
254,6,331,4,0,
254,6,332,4,0,
254,6,337,4,0,
254,6,348,1,29,
254,7,1,1,1,1
254,7,5,3,0,
254,7,14,3,0,
254,7,15,4,0,
254,7,21,1,43,
254,7,25,3,0,
254,7,34,3,0,
254,7,38,3,0,
254,7,43,1,1,2
254,7,46,4,0,
254,7,63,4,0,
254,7,68,3,0,
254,7,69,3,0,
254,7,70,4,0,
254,7,71,1,1,3
254,7,71,1,6,
254,7,76,4,0,
254,7,89,4,0,
254,7,91,4,0,
254,7,92,4,0,
254,7,97,1,35,
254,7,98,1,1,4
254,7,98,1,11,
254,7,102,3,0,
254,7,103,1,23,
254,7,104,4,0,
254,7,148,4,0,
254,7,156,4,0,
254,7,164,3,0,
254,7,182,4,0,
254,7,197,1,51,
254,7,202,4,0,
254,7,206,1,59,
254,7,210,1,16,
254,7,213,4,0,
254,7,216,4,0,
254,7,218,4,0,
254,7,219,4,0,
254,7,228,1,17,
254,7,231,4,0,
254,7,237,4,0,
254,7,241,4,0,
254,7,249,4,0,
254,7,263,4,0,
254,7,264,4,0,
254,7,280,4,0,
254,7,290,4,0,
254,7,317,4,0,
254,7,331,4,0,
254,7,332,4,0,
254,7,337,4,0,
254,7,348,1,29,
254,8,1,1,1,2
254,8,14,4,0,
254,8,15,4,0,
254,8,21,1,43,
254,8,43,1,1,3
254,8,46,4,0,
254,8,63,4,0,
254,8,70,4,0,
254,8,71,1,1,4
254,8,71,1,6,
254,8,76,4,0,
254,8,89,4,0,
254,8,91,4,0,
254,8,92,4,0,
254,8,97,1,35,
254,8,98,1,1,5
254,8,98,1,11,
254,8,103,1,23,
254,8,104,4,0,
254,8,148,4,0,
254,8,156,4,0,
254,8,157,4,0,
254,8,164,4,0,
254,8,182,4,0,
254,8,197,1,51,
254,8,202,4,0,
254,8,203,4,0,
254,8,206,1,59,
254,8,206,4,0,
254,8,207,4,0,
254,8,213,4,0,
254,8,214,4,0,
254,8,216,4,0,
254,8,218,4,0,
254,8,219,4,0,
254,8,228,1,17,
254,8,231,4,0,
254,8,237,4,0,
254,8,241,4,0,
254,8,249,4,0,
254,8,263,4,0,
254,8,264,4,0,
254,8,280,4,0,
254,8,290,4,0,
254,8,317,4,0,
254,8,331,4,0,
254,8,332,4,0,
254,8,337,4,0,
254,8,338,3,0,
254,8,348,1,29,
254,8,363,4,0,
254,8,374,4,0,
254,8,400,1,1,1
254,8,404,1,16,
254,8,404,4,0,
254,8,406,4,0,
254,8,409,4,0,
254,8,411,4,0,
254,8,412,4,0,
254,8,416,4,0,
254,8,431,4,0,
254,8,437,1,67,
254,8,445,4,0,
254,8,447,4,0,
254,9,1,1,1,2
254,9,9,3,0,
254,9,14,4,0,
254,9,15,4,0,
254,9,21,1,43,
254,9,43,1,1,3
254,9,46,4,0,
254,9,63,4,0,
254,9,70,4,0,
254,9,71,1,1,4
254,9,71,1,6,
254,9,76,4,0,
254,9,89,4,0,
254,9,91,4,0,
254,9,92,4,0,
254,9,97,1,35,
254,9,98,1,1,5
254,9,98,1,11,
254,9,103,1,23,
254,9,104,4,0,
254,9,129,3,0,
254,9,148,4,0,
254,9,156,4,0,
254,9,157,4,0,
254,9,164,4,0,
254,9,173,3,0,
254,9,182,4,0,
254,9,189,3,0,
254,9,197,1,51,
254,9,200,3,0,
254,9,202,4,0,
254,9,203,4,0,
254,9,206,1,59,
254,9,206,4,0,
254,9,207,4,0,
254,9,210,3,0,
254,9,213,4,0,
254,9,214,4,0,
254,9,216,4,0,
254,9,218,4,0,
254,9,219,4,0,
254,9,228,1,17,
254,9,231,4,0,
254,9,235,3,0,
254,9,237,4,0,
254,9,241,4,0,
254,9,249,4,0,
254,9,263,4,0,
254,9,264,4,0,
254,9,280,4,0,
254,9,283,3,0,
254,9,290,4,0,
254,9,317,4,0,
254,9,331,4,0,
254,9,332,4,0,
254,9,337,4,0,
254,9,338,3,0,
254,9,348,1,29,
254,9,363,4,0,
254,9,374,4,0,
254,9,400,1,1,1
254,9,402,3,0,
254,9,404,1,16,
254,9,404,4,0,
254,9,406,4,0,
254,9,409,4,0,
254,9,411,4,0,
254,9,412,4,0,
254,9,416,4,0,
254,9,431,4,0,
254,9,437,1,67,
254,9,445,4,0,
254,9,447,4,0,
254,10,1,1,1,2
254,10,9,3,0,
254,10,14,4,0,
254,10,15,4,0,
254,10,21,1,43,
254,10,29,3,0,
254,10,43,1,1,3
254,10,46,4,0,
254,10,63,4,0,
254,10,67,3,0,
254,10,70,4,0,
254,10,71,1,1,4
254,10,71,1,6,
254,10,76,4,0,
254,10,89,4,0,
254,10,91,4,0,
254,10,92,4,0,
254,10,97,1,35,
254,10,98,1,1,5
254,10,98,1,11,
254,10,103,1,23,
254,10,104,4,0,
254,10,129,3,0,
254,10,148,4,0,
254,10,156,4,0,
254,10,157,4,0,
254,10,164,4,0,
254,10,173,3,0,
254,10,182,4,0,
254,10,189,3,0,
254,10,197,1,51,
254,10,200,3,0,
254,10,202,4,0,
254,10,203,4,0,
254,10,206,1,59,
254,10,206,4,0,
254,10,207,4,0,
254,10,210,3,0,
254,10,213,4,0,
254,10,214,4,0,
254,10,216,4,0,
254,10,218,4,0,
254,10,219,4,0,
254,10,228,1,17,
254,10,231,4,0,
254,10,235,3,0,
254,10,237,4,0,
254,10,241,4,0,
254,10,249,4,0,
254,10,263,4,0,
254,10,264,4,0,
254,10,280,4,0,
254,10,283,3,0,
254,10,290,4,0,
254,10,317,4,0,
254,10,331,4,0,
254,10,332,4,0,
254,10,337,4,0,
254,10,338,3,0,
254,10,348,1,29,
254,10,363,4,0,
254,10,374,4,0,
254,10,388,3,0,
254,10,400,1,1,1
254,10,402,3,0,
254,10,404,1,16,
254,10,404,4,0,
254,10,406,4,0,
254,10,409,4,0,
254,10,411,4,0,
254,10,412,4,0,
254,10,416,4,0,
254,10,431,4,0,
254,10,437,1,67,
254,10,445,4,0,
254,10,447,4,0,
254,11,1,1,1,2
254,11,14,4,0,
254,11,15,4,0,
254,11,21,1,43,
254,11,43,1,1,3
254,11,46,4,0,
254,11,63,4,0,
254,11,70,4,0,
254,11,71,1,1,4
254,11,71,1,6,
254,11,76,4,0,
254,11,89,4,0,
254,11,91,4,0,
254,11,92,4,0,
254,11,97,1,35,
254,11,98,1,1,5
254,11,98,1,11,
254,11,103,1,23,
254,11,104,4,0,
254,11,148,4,0,
254,11,156,4,0,
254,11,157,4,0,
254,11,164,4,0,
254,11,182,4,0,
254,11,197,1,51,
254,11,206,1,59,
254,11,206,4,0,
254,11,207,4,0,
254,11,213,4,0,
254,11,216,4,0,
254,11,218,4,0,
254,11,219,4,0,
254,11,228,1,17,
254,11,237,4,0,
254,11,241,4,0,
254,11,249,4,0,
254,11,263,4,0,
254,11,280,4,0,
254,11,317,4,0,
254,11,332,4,0,
254,11,337,4,0,
254,11,338,3,0,
254,11,348,1,29,
254,11,374,4,0,
254,11,400,1,1,1
254,11,404,1,16,
254,11,404,4,0,
254,11,411,4,0,
254,11,412,4,0,
254,11,416,4,0,
254,11,437,1,67,
254,11,447,4,0,
254,11,468,4,0,
254,11,490,4,0,
254,11,496,4,0,
254,11,512,4,0,
254,11,520,3,0,
254,11,523,4,0,
254,12,1,1,1,1
254,12,15,4,0,
254,12,21,1,43,
254,12,43,1,1,2
254,12,46,4,0,
254,12,63,4,0,
254,12,70,4,0,
254,12,71,1,1,3
254,12,71,1,6,
254,12,76,4,0,
254,12,89,4,0,
254,12,91,4,0,
254,12,92,4,0,
254,12,97,1,35,
254,12,98,1,1,4
254,12,98,1,11,
254,12,103,1,23,
254,12,104,4,0,
254,12,148,4,0,
254,12,156,4,0,
254,12,182,4,0,
254,12,197,1,51,
254,12,202,4,0,
254,12,206,1,59,
254,12,210,1,16,
254,12,213,4,0,
254,12,216,4,0,
254,12,218,4,0,
254,12,219,4,0,
254,12,228,1,17,
254,12,231,4,0,
254,12,237,4,0,
254,12,241,4,0,
254,12,249,4,0,
254,12,263,4,0,
254,12,264,4,0,
254,12,280,4,0,
254,12,290,4,0,
254,12,317,4,0,
254,12,331,4,0,
254,12,332,4,0,
254,12,337,4,0,
254,12,348,1,29,
254,13,1,1,1,1
254,13,15,4,0,
254,13,21,1,43,
254,13,34,3,0,
254,13,38,3,0,
254,13,43,1,1,2
254,13,46,4,0,
254,13,63,4,0,
254,13,69,3,0,
254,13,70,4,0,
254,13,71,1,1,3
254,13,71,1,6,
254,13,76,4,0,
254,13,89,4,0,
254,13,91,4,0,
254,13,92,4,0,
254,13,97,1,35,
254,13,98,1,1,4
254,13,98,1,11,
254,13,102,3,0,
254,13,103,1,23,
254,13,104,4,0,
254,13,148,4,0,
254,13,156,4,0,
254,13,164,3,0,
254,13,182,4,0,
254,13,197,1,51,
254,13,202,4,0,
254,13,206,1,59,
254,13,207,3,0,
254,13,210,1,16,
254,13,213,4,0,
254,13,216,4,0,
254,13,218,4,0,
254,13,219,4,0,
254,13,228,1,17,
254,13,231,4,0,
254,13,237,4,0,
254,13,241,4,0,
254,13,249,4,0,
254,13,263,4,0,
254,13,264,4,0,
254,13,280,4,0,
254,13,290,4,0,
254,13,317,4,0,
254,13,331,4,0,
254,13,332,4,0,
254,13,337,4,0,
254,13,348,1,29,
254,14,1,1,1,2
254,14,9,3,0,
254,14,14,4,0,
254,14,15,4,0,
254,14,21,1,43,
254,14,43,1,1,3
254,14,46,4,0,
254,14,63,4,0,
254,14,67,3,0,
254,14,70,4,0,
254,14,71,1,1,4
254,14,71,1,6,
254,14,76,4,0,
254,14,89,4,0,
254,14,91,4,0,
254,14,92,4,0,
254,14,97,1,35,
254,14,98,1,1,5
254,14,98,1,11,
254,14,103,1,23,
254,14,104,4,0,
254,14,148,4,0,
254,14,156,4,0,
254,14,157,4,0,
254,14,164,4,0,
254,14,173,3,0,
254,14,182,4,0,
254,14,197,1,51,
254,14,200,3,0,
254,14,202,3,0,
254,14,206,1,59,
254,14,206,4,0,
254,14,207,4,0,
254,14,213,4,0,
254,14,214,3,0,
254,14,216,4,0,
254,14,218,4,0,
254,14,219,4,0,
254,14,228,1,17,
254,14,231,3,0,
254,14,235,3,0,
254,14,237,4,0,
254,14,241,4,0,
254,14,249,4,0,
254,14,263,4,0,
254,14,280,4,0,
254,14,283,3,0,
254,14,317,4,0,
254,14,332,4,0,
254,14,337,4,0,
254,14,338,3,0,
254,14,348,1,29,
254,14,374,4,0,
254,14,388,3,0,
254,14,400,1,1,1
254,14,402,3,0,
254,14,404,1,16,
254,14,404,4,0,
254,14,406,3,0,
254,14,409,3,0,
254,14,411,4,0,
254,14,412,4,0,
254,14,416,4,0,
254,14,437,1,67,
254,14,447,4,0,
254,14,468,4,0,
254,14,490,4,0,
254,14,496,4,0,
254,14,512,4,0,
254,14,520,3,0,
254,14,523,4,0,
254,15,1,1,1,3
254,15,14,4,0,
254,15,15,4,0,
254,15,21,1,43,
254,15,43,1,1,4
254,15,46,4,0,
254,15,63,4,0,
254,15,70,4,0,
254,15,71,1,1,5
254,15,71,1,6,
254,15,76,4,0,
254,15,89,4,0,
254,15,91,4,0,
254,15,92,4,0,
254,15,97,1,35,
254,15,98,1,1,6
254,15,98,1,11,
254,15,103,1,23,
254,15,104,4,0,
254,15,148,4,0,
254,15,156,4,0,
254,15,157,4,0,
254,15,164,4,0,
254,15,182,4,0,
254,15,197,1,51,
254,15,206,1,59,
254,15,206,4,0,
254,15,207,4,0,
254,15,213,4,0,
254,15,214,4,0,
254,15,216,4,0,
254,15,218,4,0,
254,15,219,4,0,
254,15,228,1,17,
254,15,237,4,0,
254,15,241,4,0,
254,15,249,4,0,
254,15,263,4,0,
254,15,267,4,0,
254,15,280,4,0,
254,15,317,4,0,
254,15,332,4,0,
254,15,337,4,0,
254,15,338,3,0,
254,15,348,1,29,
254,15,374,4,0,
254,15,400,1,1,2
254,15,404,1,16,
254,15,404,4,0,
254,15,411,4,0,
254,15,412,4,0,
254,15,416,4,0,
254,15,437,1,1,1
254,15,437,1,67,
254,15,447,4,0,
254,15,468,4,0,
254,15,490,4,0,
254,15,496,4,0,
254,15,512,4,0,
254,15,520,3,0,
254,15,523,4,0,
254,15,590,4,0,
254,15,612,4,0,
254,16,1,1,1,3
254,16,9,3,0,
254,16,14,4,0,
254,16,15,4,0,
254,16,21,1,33,1
254,16,43,1,1,4
254,16,46,4,0,
254,16,63,4,0,
254,16,67,3,0,
254,16,70,4,0,
254,16,71,1,1,5
254,16,71,1,5,1
254,16,72,1,13,1
254,16,76,4,0,
254,16,89,4,0,
254,16,91,4,0,
254,16,92,4,0,
254,16,97,1,28,1
254,16,98,1,1,6
254,16,98,1,9,1
254,16,103,1,69,1
254,16,104,4,0,
254,16,148,4,0,
254,16,156,4,0,
254,16,157,4,0,
254,16,164,4,0,
254,16,173,3,0,
254,16,182,4,0,
254,16,197,1,39,1
254,16,200,3,0,
254,16,202,3,0,
254,16,206,1,51,1
254,16,206,4,0,
254,16,207,4,0,
254,16,210,1,16,1
254,16,213,4,0,
254,16,214,4,0,
254,16,216,4,0,
254,16,218,4,0,
254,16,219,4,0,
254,16,228,1,18,1
254,16,231,3,0,
254,16,235,3,0,
254,16,237,4,0,
254,16,241,4,0,
254,16,249,4,0,
254,16,263,4,0,
254,16,264,3,0,
254,16,267,4,0,
254,16,280,4,0,
254,16,283,3,0,
254,16,290,4,0,
254,16,317,4,0,
254,16,332,4,0,
254,16,337,4,0,
254,16,338,3,0,
254,16,348,1,23,1
254,16,374,4,0,
254,16,388,3,0,
254,16,400,1,1,2
254,16,402,3,0,
254,16,404,1,45,1
254,16,404,4,0,
254,16,406,3,0,
254,16,409,3,0,
254,16,411,4,0,
254,16,412,4,0,
254,16,416,4,0,
254,16,437,1,1,1
254,16,437,1,63,1
254,16,447,4,0,
254,16,468,4,0,
254,16,490,4,0,
254,16,496,4,0,
254,16,501,1,57,1
254,16,512,4,0,
254,16,520,3,0,
254,16,523,4,0,
254,16,530,1,36,1
254,16,530,3,0,
254,16,590,4,0,
254,16,612,4,0,
254,17,1,1,1,5
254,17,14,4,0,
254,17,21,1,33,
254,17,43,1,1,6
254,17,46,4,0,
254,17,63,4,0,
254,17,71,1,1,7
254,17,71,1,5,
254,17,72,1,13,
254,17,76,4,0,
254,17,89,4,0,
254,17,92,4,0,
254,17,97,1,28,
254,17,98,1,1,8
254,17,98,1,9,
254,17,103,1,69,
254,17,104,4,0,
254,17,156,4,0,
254,17,157,4,0,
254,17,164,4,0,
254,17,182,4,0,
254,17,197,1,39,
254,17,206,1,51,
254,17,206,4,0,
254,17,207,4,0,
254,17,210,1,1,2
254,17,213,4,0,
254,17,214,4,0,
254,17,216,4,0,
254,17,218,4,0,
254,17,219,4,0,
254,17,228,1,18,
254,17,237,4,0,
254,17,241,4,0,
254,17,263,4,0,
254,17,267,4,0,
254,17,280,4,0,
254,17,317,4,0,
254,17,332,4,0,
254,17,337,4,0,
254,17,338,3,0,
254,17,348,1,23,
254,17,374,4,0,
254,17,400,1,1,4
254,17,404,1,45,
254,17,404,4,0,
254,17,411,4,0,
254,17,412,4,0,
254,17,416,4,0,
254,17,437,1,1,3
254,17,437,1,63,
254,17,447,4,0,
254,17,490,4,0,
254,17,496,4,0,
254,17,501,1,57,
254,17,512,4,0,
254,17,520,3,0,
254,17,523,4,0,
254,17,526,4,0,
254,17,530,1,0,
254,17,530,1,1,1
254,17,590,4,0,
254,17,693,4,0,
254,18,1,1,1,5
254,18,14,4,0,
254,18,21,1,33,
254,18,43,1,1,6
254,18,46,4,0,
254,18,63,4,0,
254,18,71,1,1,7
254,18,71,1,5,
254,18,72,1,13,
254,18,76,4,0,
254,18,89,4,0,
254,18,92,4,0,
254,18,97,1,28,
254,18,98,1,1,8
254,18,98,1,9,
254,18,103,1,69,
254,18,104,4,0,
254,18,156,4,0,
254,18,157,4,0,
254,18,164,4,0,
254,18,182,4,0,
254,18,197,1,39,
254,18,206,1,51,
254,18,206,4,0,
254,18,207,4,0,
254,18,210,1,1,2
254,18,213,4,0,
254,18,214,4,0,
254,18,216,4,0,
254,18,218,4,0,
254,18,219,4,0,
254,18,228,1,18,
254,18,237,4,0,
254,18,241,4,0,
254,18,263,4,0,
254,18,267,4,0,
254,18,280,4,0,
254,18,317,4,0,
254,18,332,4,0,
254,18,337,4,0,
254,18,338,3,0,
254,18,348,1,23,
254,18,374,4,0,
254,18,400,1,1,4
254,18,404,1,45,
254,18,404,4,0,
254,18,411,4,0,
254,18,412,4,0,
254,18,416,4,0,
254,18,437,1,1,3
254,18,437,1,63,
254,18,447,4,0,
254,18,490,4,0,
254,18,496,4,0,
254,18,501,1,57,
254,18,512,4,0,
254,18,520,3,0,
254,18,523,4,0,
254,18,526,4,0,
254,18,530,1,0,
254,18,530,1,1,1
254,18,590,4,0,
254,18,693,4,0,
255,5,10,1,1,1
255,5,15,4,0,
255,5,28,1,19,
255,5,45,1,1,2
255,5,52,1,10,
255,5,53,1,43,
255,5,53,4,0,
255,5,64,1,16,
255,5,68,2,0,
255,5,70,4,0,
255,5,83,1,25,
255,5,91,4,0,
255,5,92,4,0,
255,5,98,1,28,
255,5,104,4,0,
255,5,116,1,7,
255,5,119,1,37,
255,5,126,4,0,
255,5,156,4,0,
255,5,157,2,0,
255,5,163,1,34,
255,5,179,2,0,
255,5,182,4,0,
255,5,203,2,0,
255,5,207,2,0,
255,5,213,4,0,
255,5,216,4,0,
255,5,218,4,0,
255,5,237,4,0,
255,5,241,4,0,
255,5,249,4,0,
255,5,263,4,0,
255,5,265,2,0,
255,5,290,4,0,
255,5,315,4,0,
255,5,317,4,0,
255,5,332,4,0,
255,6,5,3,0,
255,6,10,1,1,1
255,6,14,3,0,
255,6,15,4,0,
255,6,25,3,0,
255,6,28,1,19,
255,6,34,3,0,
255,6,38,3,0,
255,6,45,1,1,2
255,6,52,1,10,
255,6,53,1,43,
255,6,53,4,0,
255,6,64,1,16,
255,6,68,2,0,
255,6,68,3,0,
255,6,69,3,0,
255,6,70,4,0,
255,6,83,1,25,
255,6,91,4,0,
255,6,92,4,0,
255,6,98,1,28,
255,6,102,3,0,
255,6,104,4,0,
255,6,116,1,7,
255,6,119,1,37,
255,6,126,4,0,
255,6,129,3,0,
255,6,156,4,0,
255,6,157,2,0,
255,6,157,3,0,
255,6,163,1,34,
255,6,164,3,0,
255,6,173,3,0,
255,6,179,2,0,
255,6,182,4,0,
255,6,189,3,0,
255,6,203,2,0,
255,6,203,3,0,
255,6,207,2,0,
255,6,207,3,0,
255,6,213,4,0,
255,6,214,3,0,
255,6,216,4,0,
255,6,218,4,0,
255,6,237,4,0,
255,6,241,4,0,
255,6,249,4,0,
255,6,263,4,0,
255,6,265,2,0,
255,6,290,4,0,
255,6,315,4,0,
255,6,317,4,0,
255,6,332,4,0,
255,7,5,3,0,
255,7,10,1,1,1
255,7,14,3,0,
255,7,15,4,0,
255,7,25,3,0,
255,7,28,1,19,
255,7,34,3,0,
255,7,38,3,0,
255,7,45,1,1,2
255,7,52,1,10,
255,7,53,1,43,
255,7,53,4,0,
255,7,64,1,16,
255,7,68,2,0,
255,7,68,3,0,
255,7,69,3,0,
255,7,70,4,0,
255,7,83,1,25,
255,7,91,4,0,
255,7,92,4,0,
255,7,98,1,28,
255,7,102,3,0,
255,7,104,4,0,
255,7,116,1,7,
255,7,119,1,37,
255,7,126,4,0,
255,7,156,4,0,
255,7,157,2,0,
255,7,157,3,0,
255,7,163,1,34,
255,7,164,3,0,
255,7,179,2,0,
255,7,182,4,0,
255,7,203,2,0,
255,7,207,2,0,
255,7,213,4,0,
255,7,216,4,0,
255,7,218,4,0,
255,7,237,4,0,
255,7,241,4,0,
255,7,249,4,0,
255,7,263,4,0,
255,7,265,2,0,
255,7,290,4,0,
255,7,315,4,0,
255,7,317,4,0,
255,7,332,4,0,
255,8,10,1,1,1
255,8,14,4,0,
255,8,15,4,0,
255,8,28,1,19,
255,8,45,1,1,2
255,8,52,1,10,
255,8,53,1,43,
255,8,53,4,0,
255,8,64,1,16,
255,8,68,2,0,
255,8,70,4,0,
255,8,83,1,25,
255,8,91,4,0,
255,8,92,4,0,
255,8,97,2,0,
255,8,98,1,28,
255,8,104,4,0,
255,8,116,1,7,
255,8,119,1,37,
255,8,126,4,0,
255,8,156,4,0,
255,8,157,2,0,
255,8,157,4,0,
255,8,163,1,34,
255,8,164,4,0,
255,8,179,2,0,
255,8,182,4,0,
255,8,203,2,0,
255,8,203,4,0,
255,8,207,2,0,
255,8,207,4,0,
255,8,213,4,0,
255,8,214,4,0,
255,8,216,4,0,
255,8,218,4,0,
255,8,226,2,0,
255,8,237,4,0,
255,8,241,4,0,
255,8,249,4,0,
255,8,261,4,0,
255,8,263,4,0,
255,8,265,2,0,
255,8,290,4,0,
255,8,306,2,0,
255,8,315,4,0,
255,8,317,4,0,
255,8,332,4,0,
255,8,363,4,0,
255,8,364,2,0,
255,8,387,2,0,
255,8,400,2,0,
255,8,421,4,0,
255,8,445,4,0,
255,9,10,1,1,1
255,9,14,4,0,
255,9,15,4,0,
255,9,28,1,19,
255,9,45,1,1,2
255,9,52,1,10,
255,9,53,1,43,
255,9,53,4,0,
255,9,64,1,16,
255,9,68,2,0,
255,9,70,4,0,
255,9,83,1,25,
255,9,91,4,0,
255,9,92,4,0,
255,9,97,2,0,
255,9,98,1,28,
255,9,104,4,0,
255,9,116,1,7,
255,9,119,1,37,
255,9,126,4,0,
255,9,129,3,0,
255,9,156,4,0,
255,9,157,2,0,
255,9,157,4,0,
255,9,163,1,34,
255,9,164,4,0,
255,9,173,3,0,
255,9,179,2,0,
255,9,182,4,0,
255,9,189,3,0,
255,9,203,2,0,
255,9,203,4,0,
255,9,207,2,0,
255,9,207,4,0,
255,9,213,4,0,
255,9,214,4,0,
255,9,216,4,0,
255,9,218,4,0,
255,9,226,2,0,
255,9,237,4,0,
255,9,241,4,0,
255,9,249,4,0,
255,9,257,3,0,
255,9,261,4,0,
255,9,263,4,0,
255,9,265,2,0,
255,9,270,3,0,
255,9,290,4,0,
255,9,306,2,0,
255,9,315,4,0,
255,9,317,4,0,
255,9,332,4,0,
255,9,340,3,0,
255,9,363,4,0,
255,9,364,2,0,
255,9,387,2,0,
255,9,387,3,0,
255,9,400,2,0,
255,9,421,4,0,
255,9,445,4,0,
255,10,10,1,1,1
255,10,14,4,0,
255,10,15,4,0,
255,10,28,1,19,
255,10,29,3,0,
255,10,45,1,1,2
255,10,52,1,10,
255,10,53,1,43,
255,10,53,4,0,
255,10,64,1,16,
255,10,68,2,0,
255,10,70,4,0,
255,10,83,1,25,
255,10,91,4,0,
255,10,92,4,0,
255,10,97,2,0,
255,10,98,1,28,
255,10,104,4,0,
255,10,116,1,7,
255,10,119,1,37,
255,10,126,4,0,
255,10,129,3,0,
255,10,156,4,0,
255,10,157,2,0,
255,10,157,4,0,
255,10,163,1,34,
255,10,164,4,0,
255,10,173,3,0,
255,10,179,2,0,
255,10,182,4,0,
255,10,189,3,0,
255,10,203,2,0,
255,10,203,4,0,
255,10,207,2,0,
255,10,207,4,0,
255,10,213,4,0,
255,10,214,4,0,
255,10,216,4,0,
255,10,218,4,0,
255,10,226,2,0,
255,10,237,4,0,
255,10,241,4,0,
255,10,249,4,0,
255,10,257,3,0,
255,10,261,4,0,
255,10,263,4,0,
255,10,265,2,0,
255,10,270,3,0,
255,10,290,4,0,
255,10,297,2,0,
255,10,306,2,0,
255,10,315,4,0,
255,10,317,4,0,
255,10,332,4,0,
255,10,340,3,0,
255,10,363,4,0,
255,10,364,2,0,
255,10,387,2,0,
255,10,387,3,0,
255,10,400,2,0,
255,10,421,4,0,
255,10,445,4,0,
255,11,10,1,1,1
255,11,14,4,0,
255,11,15,4,0,
255,11,28,1,19,
255,11,45,1,1,2
255,11,52,1,10,
255,11,53,1,43,
255,11,53,4,0,
255,11,64,1,16,
255,11,67,2,0,
255,11,68,2,0,
255,11,70,4,0,
255,11,83,1,25,
255,11,91,4,0,
255,11,92,4,0,
255,11,97,2,0,
255,11,98,1,28,
255,11,104,4,0,
255,11,116,1,7,
255,11,119,1,37,
255,11,126,4,0,
255,11,156,4,0,
255,11,157,4,0,
255,11,163,1,34,
255,11,164,4,0,
255,11,174,2,0,
255,11,179,2,0,
255,11,182,4,0,
255,11,203,2,0,
255,11,207,4,0,
255,11,213,4,0,
255,11,216,4,0,
255,11,218,4,0,
255,11,226,2,0,
255,11,237,4,0,
255,11,241,4,0,
255,11,249,4,0,
255,11,261,4,0,
255,11,263,4,0,
255,11,265,2,0,
255,11,297,2,0,
255,11,306,2,0,
255,11,315,4,0,
255,11,317,4,0,
255,11,332,4,0,
255,11,364,2,0,
255,11,387,2,0,
255,11,400,2,0,
255,11,421,4,0,
255,11,468,4,0,
255,11,481,2,0,
255,11,488,4,0,
255,11,496,4,0,
255,11,497,4,0,
255,11,510,4,0,
255,11,519,3,0,
255,12,10,1,1,1
255,12,15,4,0,
255,12,28,1,19,
255,12,45,1,1,2
255,12,52,1,10,
255,12,53,1,43,
255,12,53,4,0,
255,12,64,1,16,
255,12,70,4,0,
255,12,83,1,25,
255,12,91,4,0,
255,12,92,4,0,
255,12,98,1,28,
255,12,104,4,0,
255,12,116,1,7,
255,12,119,1,37,
255,12,126,4,0,
255,12,156,4,0,
255,12,163,1,34,
255,12,182,4,0,
255,12,213,4,0,
255,12,216,4,0,
255,12,218,4,0,
255,12,237,4,0,
255,12,241,4,0,
255,12,249,4,0,
255,12,263,4,0,
255,12,290,4,0,
255,12,315,4,0,
255,12,317,4,0,
255,12,332,4,0,
255,13,10,1,1,1
255,13,15,4,0,
255,13,28,1,19,
255,13,34,3,0,
255,13,38,3,0,
255,13,45,1,1,2
255,13,52,1,10,
255,13,53,1,43,
255,13,53,4,0,
255,13,64,1,16,
255,13,69,3,0,
255,13,70,4,0,
255,13,83,1,25,
255,13,91,4,0,
255,13,92,4,0,
255,13,98,1,28,
255,13,102,3,0,
255,13,104,4,0,
255,13,116,1,7,
255,13,119,1,37,
255,13,126,4,0,
255,13,156,4,0,
255,13,163,1,34,
255,13,164,3,0,
255,13,182,4,0,
255,13,207,3,0,
255,13,213,4,0,
255,13,216,4,0,
255,13,218,4,0,
255,13,237,4,0,
255,13,241,4,0,
255,13,249,4,0,
255,13,263,4,0,
255,13,290,4,0,
255,13,315,4,0,
255,13,317,4,0,
255,13,332,4,0,
255,14,10,1,1,1
255,14,14,4,0,
255,14,15,4,0,
255,14,28,1,19,
255,14,45,1,1,2
255,14,52,1,10,
255,14,53,1,43,
255,14,53,4,0,
255,14,64,1,16,
255,14,67,2,0,
255,14,67,3,0,
255,14,68,2,0,
255,14,70,4,0,
255,14,83,1,25,
255,14,91,4,0,
255,14,92,4,0,
255,14,97,2,0,
255,14,98,1,28,
255,14,104,4,0,
255,14,116,1,7,
255,14,119,1,37,
255,14,126,4,0,
255,14,156,4,0,
255,14,157,4,0,
255,14,163,1,34,
255,14,164,4,0,
255,14,173,3,0,
255,14,174,2,0,
255,14,179,2,0,
255,14,182,4,0,
255,14,203,2,0,
255,14,207,4,0,
255,14,213,4,0,
255,14,214,3,0,
255,14,216,4,0,
255,14,218,4,0,
255,14,226,2,0,
255,14,237,4,0,
255,14,241,4,0,
255,14,249,4,0,
255,14,257,3,0,
255,14,261,4,0,
255,14,263,4,0,
255,14,265,2,0,
255,14,270,3,0,
255,14,297,2,0,
255,14,306,2,0,
255,14,315,4,0,
255,14,317,4,0,
255,14,332,4,0,
255,14,340,3,0,
255,14,364,2,0,
255,14,387,2,0,
255,14,387,3,0,
255,14,400,2,0,
255,14,421,4,0,
255,14,468,4,0,
255,14,481,2,0,
255,14,488,4,0,
255,14,496,4,0,
255,14,497,4,0,
255,14,510,4,0,
255,14,519,3,0,
255,15,10,1,1,1
255,15,14,4,0,
255,15,15,4,0,
255,15,28,1,19,
255,15,45,1,1,2
255,15,52,1,10,
255,15,53,1,43,
255,15,53,4,0,
255,15,64,1,16,
255,15,67,2,0,
255,15,68,2,0,
255,15,70,4,0,
255,15,83,1,25,
255,15,91,4,0,
255,15,92,4,0,
255,15,97,2,0,
255,15,98,1,28,
255,15,104,4,0,
255,15,116,1,7,
255,15,119,1,37,
255,15,126,4,0,
255,15,156,4,0,
255,15,157,4,0,
255,15,163,1,34,
255,15,164,4,0,
255,15,174,2,0,
255,15,179,2,0,
255,15,182,4,0,
255,15,203,2,0,
255,15,207,4,0,
255,15,213,4,0,
255,15,214,4,0,
255,15,216,4,0,
255,15,218,4,0,
255,15,226,2,0,
255,15,237,4,0,
255,15,241,4,0,
255,15,249,4,0,
255,15,261,4,0,
255,15,263,4,0,
255,15,265,2,0,
255,15,297,2,0,
255,15,306,2,0,
255,15,315,4,0,
255,15,317,4,0,
255,15,332,4,0,
255,15,364,2,0,
255,15,387,2,0,
255,15,400,2,0,
255,15,421,4,0,
255,15,468,4,0,
255,15,481,2,0,
255,15,488,4,0,
255,15,496,4,0,
255,15,497,4,0,
255,15,510,4,0,
255,15,519,3,0,
255,15,590,4,0,
255,16,10,1,1,1
255,16,14,4,0,
255,16,15,4,0,
255,16,28,1,10,1
255,16,45,1,1,2
255,16,52,1,5,1
255,16,53,1,46,1
255,16,53,4,0,
255,16,64,1,14,1
255,16,67,2,0,
255,16,67,3,0,
255,16,68,2,0,
255,16,70,4,0,
255,16,83,1,19,1
255,16,91,4,0,
255,16,92,4,0,
255,16,97,2,0,
255,16,98,1,23,1
255,16,104,4,0,
255,16,116,1,32,1
255,16,119,1,41,1
255,16,126,4,0,
255,16,156,4,0,
255,16,157,4,0,
255,16,163,1,37,1
255,16,164,4,0,
255,16,173,3,0,
255,16,174,2,0,
255,16,179,2,0,
255,16,182,4,0,
255,16,203,2,0,
255,16,207,4,0,
255,16,213,4,0,
255,16,214,4,0,
255,16,216,4,0,
255,16,218,4,0,
255,16,226,2,0,
255,16,237,4,0,
255,16,241,4,0,
255,16,249,4,0,
255,16,257,3,0,
255,16,261,4,0,
255,16,263,4,0,
255,16,265,2,0,
255,16,270,3,0,
255,16,290,4,0,
255,16,297,2,0,
255,16,306,2,0,
255,16,315,4,0,
255,16,317,4,0,
255,16,332,4,0,
255,16,340,3,0,
255,16,364,2,0,
255,16,387,2,0,
255,16,387,3,0,
255,16,400,2,0,
255,16,421,4,0,
255,16,468,4,0,
255,16,481,1,28,1
255,16,481,2,0,
255,16,488,4,0,
255,16,496,4,0,
255,16,497,4,0,
255,16,510,4,0,
255,16,519,3,0,
255,16,590,4,0,
255,17,10,1,1,1
255,17,14,4,0,
255,17,28,1,10,
255,17,45,1,1,2
255,17,52,1,5,
255,17,53,1,46,
255,17,53,4,0,
255,17,64,1,14,
255,17,67,2,0,
255,17,68,2,0,
255,17,83,1,19,
255,17,92,4,0,
255,17,97,2,0,
255,17,98,1,23,
255,17,104,4,0,
255,17,116,1,32,
255,17,119,1,41,
255,17,126,4,0,
255,17,156,4,0,
255,17,157,4,0,
255,17,163,1,37,
255,17,164,4,0,
255,17,174,2,0,
255,17,179,2,0,
255,17,182,4,0,
255,17,203,2,0,
255,17,207,4,0,
255,17,213,4,0,
255,17,214,4,0,
255,17,216,4,0,
255,17,218,4,0,
255,17,226,2,0,
255,17,237,4,0,
255,17,241,4,0,
255,17,261,4,0,
255,17,263,4,0,
255,17,265,2,0,
255,17,297,2,0,
255,17,306,2,0,
255,17,315,4,0,
255,17,317,4,0,
255,17,332,4,0,
255,17,364,2,0,
255,17,387,2,0,
255,17,400,2,0,
255,17,421,4,0,
255,17,481,1,28,
255,17,481,2,0,
255,17,488,4,0,
255,17,496,4,0,
255,17,497,4,0,
255,17,519,3,0,
255,17,526,4,0,
255,17,590,4,0,
255,18,10,1,1,1
255,18,14,4,0,
255,18,28,1,10,
255,18,45,1,1,2
255,18,52,1,5,
255,18,53,1,46,
255,18,53,4,0,
255,18,64,1,14,
255,18,67,2,0,
255,18,68,2,0,
255,18,83,1,19,
255,18,92,4,0,
255,18,97,2,0,
255,18,98,1,23,
255,18,104,4,0,
255,18,116,1,32,
255,18,119,1,41,
255,18,126,4,0,
255,18,156,4,0,
255,18,157,4,0,
255,18,163,1,37,
255,18,164,4,0,
255,18,174,2,0,
255,18,179,2,0,
255,18,182,4,0,
255,18,203,2,0,
255,18,207,4,0,
255,18,213,4,0,
255,18,214,4,0,
255,18,216,4,0,
255,18,218,4,0,
255,18,226,2,0,
255,18,237,4,0,
255,18,241,4,0,
255,18,261,4,0,
255,18,263,4,0,
255,18,265,2,0,
255,18,297,2,0,
255,18,306,2,0,
255,18,315,4,0,
255,18,317,4,0,
255,18,332,4,0,
255,18,364,2,0,
255,18,387,2,0,
255,18,400,2,0,
255,18,421,4,0,
255,18,481,1,28,
255,18,481,2,0,
255,18,488,4,0,
255,18,496,4,0,
255,18,497,4,0,
255,18,519,3,0,
255,18,526,4,0,
255,18,590,4,0,
256,5,10,1,1,1
256,5,15,4,0,
256,5,24,1,16,
256,5,28,1,21,
256,5,45,1,1,2
256,5,52,1,1,4
256,5,52,1,13,
256,5,53,4,0,
256,5,64,1,17,
256,5,70,4,0,
256,5,91,4,0,
256,5,92,4,0,
256,5,98,1,32,
256,5,104,4,0,
256,5,116,1,1,3
256,5,116,1,7,
256,5,119,1,43,
256,5,126,4,0,
256,5,156,4,0,
256,5,163,1,39,
256,5,182,4,0,
256,5,213,4,0,
256,5,216,4,0,
256,5,218,4,0,
256,5,237,4,0,
256,5,241,4,0,
256,5,249,4,0,
256,5,263,4,0,
256,5,264,4,0,
256,5,280,4,0,
256,5,290,4,0,
256,5,315,4,0,
256,5,317,4,0,
256,5,327,1,50,
256,5,332,4,0,
256,5,339,1,28,
256,5,339,4,0,
256,6,5,3,0,
256,6,7,3,0,
256,6,9,3,0,
256,6,10,1,1,1
256,6,14,3,0,
256,6,15,4,0,
256,6,24,1,16,
256,6,25,3,0,
256,6,28,1,21,
256,6,34,3,0,
256,6,38,3,0,
256,6,45,1,1,2
256,6,52,1,1,4
256,6,52,1,13,
256,6,53,4,0,
256,6,64,1,17,
256,6,68,3,0,
256,6,69,3,0,
256,6,70,4,0,
256,6,91,4,0,
256,6,92,4,0,
256,6,98,1,32,
256,6,102,3,0,
256,6,104,4,0,
256,6,116,1,1,3
256,6,116,1,7,
256,6,119,1,43,
256,6,126,4,0,
256,6,129,3,0,
256,6,156,4,0,
256,6,157,3,0,
256,6,163,1,39,
256,6,164,3,0,
256,6,173,3,0,
256,6,182,4,0,
256,6,189,3,0,
256,6,203,3,0,
256,6,207,3,0,
256,6,210,3,0,
256,6,213,4,0,
256,6,214,3,0,
256,6,216,4,0,
256,6,218,4,0,
256,6,223,3,0,
256,6,237,4,0,
256,6,241,4,0,
256,6,249,4,0,
256,6,263,4,0,
256,6,264,4,0,
256,6,280,4,0,
256,6,290,4,0,
256,6,315,4,0,
256,6,317,4,0,
256,6,327,1,50,
256,6,332,4,0,
256,6,339,1,28,
256,6,339,4,0,
256,7,5,3,0,
256,7,10,1,1,1
256,7,14,3,0,
256,7,15,4,0,
256,7,24,1,16,
256,7,25,3,0,
256,7,28,1,21,
256,7,34,3,0,
256,7,38,3,0,
256,7,45,1,1,2
256,7,52,1,1,4
256,7,52,1,13,
256,7,53,4,0,
256,7,64,1,17,
256,7,68,3,0,
256,7,69,3,0,
256,7,70,4,0,
256,7,91,4,0,
256,7,92,4,0,
256,7,98,1,32,
256,7,102,3,0,
256,7,104,4,0,
256,7,116,1,1,3
256,7,116,1,7,
256,7,119,1,43,
256,7,126,4,0,
256,7,156,4,0,
256,7,157,3,0,
256,7,163,1,39,
256,7,164,3,0,
256,7,182,4,0,
256,7,213,4,0,
256,7,216,4,0,
256,7,218,4,0,
256,7,237,4,0,
256,7,241,4,0,
256,7,249,4,0,
256,7,263,4,0,
256,7,264,4,0,
256,7,280,4,0,
256,7,290,4,0,
256,7,315,4,0,
256,7,317,4,0,
256,7,327,1,50,
256,7,332,4,0,
256,7,339,1,28,
256,7,339,4,0,
256,8,10,1,1,1
256,8,14,4,0,
256,8,15,4,0,
256,8,24,1,16,
256,8,28,1,21,
256,8,45,1,1,2
256,8,52,1,1,4
256,8,52,1,13,
256,8,53,4,0,
256,8,64,1,17,
256,8,70,4,0,
256,8,91,4,0,
256,8,92,4,0,
256,8,98,1,32,
256,8,104,4,0,
256,8,116,1,1,3
256,8,116,1,7,
256,8,119,1,43,
256,8,126,4,0,
256,8,156,4,0,
256,8,157,4,0,
256,8,163,1,39,
256,8,164,4,0,
256,8,182,4,0,
256,8,203,4,0,
256,8,207,4,0,
256,8,213,4,0,
256,8,214,4,0,
256,8,216,4,0,
256,8,218,4,0,
256,8,237,4,0,
256,8,241,4,0,
256,8,249,4,0,
256,8,261,4,0,
256,8,263,4,0,
256,8,264,4,0,
256,8,280,4,0,
256,8,290,4,0,
256,8,315,4,0,
256,8,317,4,0,
256,8,327,1,50,
256,8,332,4,0,
256,8,339,1,28,
256,8,339,4,0,
256,8,363,4,0,
256,8,374,4,0,
256,8,394,1,54,
256,8,398,4,0,
256,8,411,4,0,
256,8,421,4,0,
256,8,445,4,0,
256,9,7,3,0,
256,9,9,3,0,
256,9,10,1,1,1
256,9,14,4,0,
256,9,15,4,0,
256,9,24,1,16,
256,9,28,1,21,
256,9,45,1,1,2
256,9,52,1,1,4
256,9,52,1,13,
256,9,53,4,0,
256,9,64,1,17,
256,9,70,4,0,
256,9,91,4,0,
256,9,92,4,0,
256,9,98,1,32,
256,9,104,4,0,
256,9,116,1,1,3
256,9,116,1,7,
256,9,119,1,43,
256,9,126,4,0,
256,9,129,3,0,
256,9,156,4,0,
256,9,157,4,0,
256,9,163,1,39,
256,9,164,4,0,
256,9,173,3,0,
256,9,182,4,0,
256,9,189,3,0,
256,9,203,4,0,
256,9,207,4,0,
256,9,210,3,0,
256,9,213,4,0,
256,9,214,4,0,
256,9,216,4,0,
256,9,218,4,0,
256,9,237,4,0,
256,9,241,4,0,
256,9,249,4,0,
256,9,257,3,0,
256,9,261,4,0,
256,9,263,4,0,
256,9,264,4,0,
256,9,270,3,0,
256,9,280,4,0,
256,9,290,4,0,
256,9,315,4,0,
256,9,317,4,0,
256,9,327,1,50,
256,9,332,4,0,
256,9,339,1,28,
256,9,339,4,0,
256,9,340,3,0,
256,9,363,4,0,
256,9,374,4,0,
256,9,387,3,0,
256,9,394,1,54,
256,9,398,4,0,
256,9,410,3,0,
256,9,411,4,0,
256,9,421,4,0,
256,9,445,4,0,
256,10,7,3,0,
256,10,9,3,0,
256,10,10,1,1,1
256,10,14,4,0,
256,10,15,4,0,
256,10,24,1,16,
256,10,28,1,21,
256,10,29,3,0,
256,10,45,1,1,2
256,10,52,1,1,4
256,10,52,1,13,
256,10,53,4,0,
256,10,64,1,17,
256,10,67,3,0,
256,10,70,4,0,
256,10,91,4,0,
256,10,92,4,0,
256,10,98,1,32,
256,10,104,4,0,
256,10,116,1,1,3
256,10,116,1,7,
256,10,119,1,43,
256,10,126,4,0,
256,10,129,3,0,
256,10,156,4,0,
256,10,157,4,0,
256,10,163,1,39,
256,10,164,4,0,
256,10,173,3,0,
256,10,182,4,0,
256,10,189,3,0,
256,10,203,4,0,
256,10,207,4,0,
256,10,210,3,0,
256,10,213,4,0,
256,10,214,4,0,
256,10,216,4,0,
256,10,218,4,0,
256,10,237,4,0,
256,10,241,4,0,
256,10,249,4,0,
256,10,257,3,0,
256,10,261,4,0,
256,10,263,4,0,
256,10,264,4,0,
256,10,270,3,0,
256,10,280,4,0,
256,10,290,4,0,
256,10,315,4,0,
256,10,317,4,0,
256,10,327,1,50,
256,10,332,4,0,
256,10,339,1,28,
256,10,339,4,0,
256,10,340,3,0,
256,10,363,4,0,
256,10,374,4,0,
256,10,387,3,0,
256,10,394,1,54,
256,10,398,4,0,
256,10,410,3,0,
256,10,411,4,0,
256,10,421,4,0,
256,10,445,4,0,
256,11,10,1,1,1
256,11,14,4,0,
256,11,15,4,0,
256,11,24,1,16,
256,11,28,1,21,
256,11,45,1,1,2
256,11,52,1,1,4
256,11,52,1,13,
256,11,53,4,0,
256,11,64,1,17,
256,11,70,4,0,
256,11,91,4,0,
256,11,92,4,0,
256,11,98,1,32,
256,11,104,4,0,
256,11,116,1,1,3
256,11,116,1,7,
256,11,119,1,43,
256,11,126,4,0,
256,11,156,4,0,
256,11,157,4,0,
256,11,163,1,39,
256,11,164,4,0,
256,11,182,4,0,
256,11,207,4,0,
256,11,213,4,0,
256,11,216,4,0,
256,11,218,4,0,
256,11,237,4,0,
256,11,241,4,0,
256,11,249,4,0,
256,11,261,4,0,
256,11,263,4,0,
256,11,280,4,0,
256,11,315,4,0,
256,11,317,4,0,
256,11,327,1,50,
256,11,332,4,0,
256,11,339,1,28,
256,11,339,4,0,
256,11,374,4,0,
256,11,394,1,54,
256,11,398,4,0,
256,11,411,4,0,
256,11,421,4,0,
256,11,468,4,0,
256,11,488,4,0,
256,11,490,4,0,
256,11,496,4,0,
256,11,497,4,0,
256,11,510,4,0,
256,11,519,3,0,
256,11,526,4,0,
256,12,10,1,1,1
256,12,15,4,0,
256,12,24,1,16,
256,12,28,1,21,
256,12,45,1,1,2
256,12,52,1,1,4
256,12,52,1,13,
256,12,53,4,0,
256,12,64,1,17,
256,12,70,4,0,
256,12,91,4,0,
256,12,92,4,0,
256,12,98,1,32,
256,12,104,4,0,
256,12,116,1,1,3
256,12,116,1,7,
256,12,119,1,43,
256,12,126,4,0,
256,12,156,4,0,
256,12,163,1,39,
256,12,182,4,0,
256,12,213,4,0,
256,12,216,4,0,
256,12,218,4,0,
256,12,237,4,0,
256,12,241,4,0,
256,12,249,4,0,
256,12,263,4,0,
256,12,264,4,0,
256,12,280,4,0,
256,12,290,4,0,
256,12,315,4,0,
256,12,317,4,0,
256,12,327,1,50,
256,12,332,4,0,
256,12,339,1,28,
256,12,339,4,0,
256,13,10,1,1,1
256,13,15,4,0,
256,13,24,1,16,
256,13,28,1,21,
256,13,34,3,0,
256,13,38,3,0,
256,13,45,1,1,2
256,13,52,1,1,4
256,13,52,1,13,
256,13,53,4,0,
256,13,64,1,17,
256,13,69,3,0,
256,13,70,4,0,
256,13,91,4,0,
256,13,92,4,0,
256,13,98,1,32,
256,13,102,3,0,
256,13,104,4,0,
256,13,116,1,1,3
256,13,116,1,7,
256,13,119,1,43,
256,13,126,4,0,
256,13,156,4,0,
256,13,163,1,39,
256,13,164,3,0,
256,13,182,4,0,
256,13,207,3,0,
256,13,213,4,0,
256,13,216,4,0,
256,13,218,4,0,
256,13,237,4,0,
256,13,241,4,0,
256,13,249,4,0,
256,13,263,4,0,
256,13,264,4,0,
256,13,280,4,0,
256,13,290,4,0,
256,13,315,4,0,
256,13,317,4,0,
256,13,327,1,50,
256,13,332,4,0,
256,13,339,1,28,
256,13,339,4,0,
256,14,7,3,0,
256,14,9,3,0,
256,14,10,1,1,1
256,14,14,4,0,
256,14,15,4,0,
256,14,24,1,16,
256,14,28,1,21,
256,14,45,1,1,2
256,14,52,1,1,4
256,14,52,1,13,
256,14,53,4,0,
256,14,64,1,17,
256,14,67,3,0,
256,14,70,4,0,
256,14,91,4,0,
256,14,92,4,0,
256,14,98,1,32,
256,14,104,4,0,
256,14,116,1,1,3
256,14,116,1,7,
256,14,119,1,43,
256,14,126,4,0,
256,14,156,4,0,
256,14,157,4,0,
256,14,163,1,39,
256,14,164,4,0,
256,14,173,3,0,
256,14,182,4,0,
256,14,207,4,0,
256,14,213,4,0,
256,14,214,3,0,
256,14,216,4,0,
256,14,218,4,0,
256,14,237,4,0,
256,14,241,4,0,
256,14,249,4,0,
256,14,257,3,0,
256,14,261,4,0,
256,14,263,4,0,
256,14,270,3,0,
256,14,280,4,0,
256,14,315,4,0,
256,14,317,4,0,
256,14,327,1,50,
256,14,332,4,0,
256,14,339,1,28,
256,14,339,4,0,
256,14,340,3,0,
256,14,374,4,0,
256,14,387,3,0,
256,14,394,1,54,
256,14,398,4,0,
256,14,411,4,0,
256,14,421,4,0,
256,14,468,4,0,
256,14,488,4,0,
256,14,490,4,0,
256,14,496,4,0,
256,14,497,4,0,
256,14,510,4,0,
256,14,519,3,0,
256,14,526,4,0,
256,14,530,3,0,
256,15,10,1,1,1
256,15,14,4,0,
256,15,15,4,0,
256,15,24,1,16,
256,15,28,1,21,
256,15,45,1,1,2
256,15,52,1,1,4
256,15,52,1,13,
256,15,53,4,0,
256,15,64,1,17,
256,15,70,4,0,
256,15,91,4,0,
256,15,92,4,0,
256,15,98,1,32,
256,15,104,4,0,
256,15,116,1,1,3
256,15,116,1,7,
256,15,119,1,43,
256,15,126,4,0,
256,15,156,4,0,
256,15,157,4,0,
256,15,163,1,39,
256,15,164,4,0,
256,15,182,4,0,
256,15,207,4,0,
256,15,213,4,0,
256,15,214,4,0,
256,15,216,4,0,
256,15,218,4,0,
256,15,237,4,0,
256,15,241,4,0,
256,15,249,4,0,
256,15,261,4,0,
256,15,263,4,0,
256,15,280,4,0,
256,15,315,4,0,
256,15,317,4,0,
256,15,327,1,50,
256,15,332,4,0,
256,15,339,1,28,
256,15,339,4,0,
256,15,374,4,0,
256,15,394,1,54,
256,15,398,4,0,
256,15,411,4,0,
256,15,421,4,0,
256,15,468,4,0,
256,15,488,4,0,
256,15,490,4,0,
256,15,496,4,0,
256,15,497,4,0,
256,15,510,4,0,
256,15,519,3,0,
256,15,590,4,0,
256,15,612,4,0,
256,16,7,3,0,
256,16,9,3,0,
256,16,10,1,1,1
256,16,14,4,0,
256,16,15,4,0,
256,16,24,1,16,1
256,16,28,1,1,4
256,16,28,1,10,1
256,16,45,1,1,2
256,16,52,1,1,3
256,16,52,1,5,1
256,16,53,4,0,
256,16,64,1,14,1
256,16,67,3,0,
256,16,70,4,0,
256,16,91,4,0,
256,16,92,4,0,
256,16,98,1,25,1
256,16,104,4,0,
256,16,116,1,36,1
256,16,119,1,47,1
256,16,126,4,0,
256,16,156,4,0,
256,16,157,4,0,
256,16,163,1,42,1
256,16,164,4,0,
256,16,173,3,0,
256,16,182,4,0,
256,16,207,4,0,
256,16,213,4,0,
256,16,214,4,0,
256,16,216,4,0,
256,16,218,4,0,
256,16,237,4,0,
256,16,241,4,0,
256,16,249,4,0,
256,16,257,3,0,
256,16,261,4,0,
256,16,263,4,0,
256,16,264,3,0,
256,16,270,3,0,
256,16,280,4,0,
256,16,290,4,0,
256,16,315,4,0,
256,16,317,4,0,
256,16,327,1,53,1
256,16,332,4,0,
256,16,339,1,31,1
256,16,339,4,0,
256,16,340,3,0,
256,16,374,4,0,
256,16,387,3,0,
256,16,394,1,58,1
256,16,398,4,0,
256,16,411,4,0,
256,16,421,4,0,
256,16,468,4,0,
256,16,488,1,20,1
256,16,488,4,0,
256,16,490,4,0,
256,16,496,4,0,
256,16,497,4,0,
256,16,510,4,0,
256,16,519,3,0,
256,16,530,3,0,
256,16,590,4,0,
256,16,612,4,0,
256,17,10,1,1,2
256,17,14,4,0,
256,17,24,1,0,
256,17,24,1,1,1
256,17,28,1,1,5
256,17,28,1,10,
256,17,45,1,1,3
256,17,52,1,1,4
256,17,52,1,5,
256,17,53,4,0,
256,17,64,1,14,
256,17,92,4,0,
256,17,98,1,25,
256,17,104,4,0,
256,17,116,1,36,
256,17,119,1,47,
256,17,126,4,0,
256,17,156,4,0,
256,17,157,4,0,
256,17,163,1,42,
256,17,164,4,0,
256,17,182,4,0,
256,17,207,4,0,
256,17,213,4,0,
256,17,214,4,0,
256,17,216,4,0,
256,17,218,4,0,
256,17,237,4,0,
256,17,241,4,0,
256,17,261,4,0,
256,17,263,4,0,
256,17,280,4,0,
256,17,315,4,0,
256,17,317,4,0,
256,17,327,1,53,
256,17,332,4,0,
256,17,339,1,31,
256,17,339,4,0,
256,17,374,4,0,
256,17,394,1,58,
256,17,398,4,0,
256,17,411,4,0,
256,17,421,4,0,
256,17,488,1,20,
256,17,488,4,0,
256,17,490,4,0,
256,17,496,4,0,
256,17,497,4,0,
256,17,519,3,0,
256,17,526,4,0,
256,17,590,4,0,
256,18,10,1,1,2
256,18,14,4,0,
256,18,24,1,0,
256,18,24,1,1,1
256,18,28,1,1,5
256,18,28,1,10,
256,18,45,1,1,3
256,18,52,1,1,4
256,18,52,1,5,
256,18,53,4,0,
256,18,64,1,14,
256,18,92,4,0,
256,18,98,1,25,
256,18,104,4,0,
256,18,116,1,36,
256,18,119,1,47,
256,18,126,4,0,
256,18,156,4,0,
256,18,157,4,0,
256,18,163,1,42,
256,18,164,4,0,
256,18,182,4,0,
256,18,207,4,0,
256,18,213,4,0,
256,18,214,4,0,
256,18,216,4,0,
256,18,218,4,0,
256,18,237,4,0,
256,18,241,4,0,
256,18,261,4,0,
256,18,263,4,0,
256,18,280,4,0,
256,18,315,4,0,
256,18,317,4,0,
256,18,327,1,53,
256,18,332,4,0,
256,18,339,1,31,
256,18,339,4,0,
256,18,374,4,0,
256,18,394,1,58,
256,18,398,4,0,
256,18,411,4,0,
256,18,421,4,0,
256,18,488,1,20,
256,18,488,4,0,
256,18,490,4,0,
256,18,496,4,0,
256,18,497,4,0,
256,18,519,3,0,
256,18,526,4,0,
256,18,590,4,0,
257,5,7,1,1,1
257,5,10,1,1,2
257,5,15,4,0,
257,5,24,1,16,
257,5,28,1,21,
257,5,45,1,1,3
257,5,46,4,0,
257,5,52,1,1,5
257,5,52,1,13,
257,5,53,4,0,
257,5,63,4,0,
257,5,64,1,17,
257,5,70,4,0,
257,5,89,4,0,
257,5,91,4,0,
257,5,92,4,0,
257,5,98,1,32,
257,5,104,4,0,
257,5,116,1,1,4
257,5,116,1,7,
257,5,119,1,49,
257,5,126,4,0,
257,5,156,4,0,
257,5,163,1,42,
257,5,182,4,0,
257,5,213,4,0,
257,5,216,4,0,
257,5,218,4,0,
257,5,237,4,0,
257,5,241,4,0,
257,5,249,4,0,
257,5,263,4,0,
257,5,264,4,0,
257,5,280,4,0,
257,5,290,4,0,
257,5,299,1,36,
257,5,315,4,0,
257,5,317,4,0,
257,5,327,1,59,
257,5,332,4,0,
257,5,339,1,28,
257,5,339,4,0,
257,6,5,3,0,
257,6,7,1,1,1
257,6,7,3,0,
257,6,9,3,0,
257,6,10,1,1,2
257,6,14,3,0,
257,6,15,4,0,
257,6,24,1,16,
257,6,25,3,0,
257,6,28,1,21,
257,6,34,3,0,
257,6,38,3,0,
257,6,45,1,1,3
257,6,46,4,0,
257,6,52,1,1,5
257,6,52,1,13,
257,6,53,4,0,
257,6,63,4,0,
257,6,64,1,17,
257,6,68,3,0,
257,6,69,3,0,
257,6,70,4,0,
257,6,89,4,0,
257,6,91,4,0,
257,6,92,4,0,
257,6,98,1,32,
257,6,102,3,0,
257,6,104,4,0,
257,6,116,1,1,4
257,6,116,1,7,
257,6,119,1,49,
257,6,126,4,0,
257,6,129,3,0,
257,6,156,4,0,
257,6,157,3,0,
257,6,163,1,42,
257,6,164,3,0,
257,6,173,3,0,
257,6,182,4,0,
257,6,189,3,0,
257,6,203,3,0,
257,6,207,3,0,
257,6,210,3,0,
257,6,213,4,0,
257,6,214,3,0,
257,6,216,4,0,
257,6,218,4,0,
257,6,223,3,0,
257,6,237,4,0,
257,6,241,4,0,
257,6,249,4,0,
257,6,263,4,0,
257,6,264,4,0,
257,6,280,4,0,
257,6,290,4,0,
257,6,299,1,36,
257,6,315,4,0,
257,6,317,4,0,
257,6,327,1,59,
257,6,332,4,0,
257,6,339,1,28,
257,6,339,4,0,
257,7,5,3,0,
257,7,7,1,1,1
257,7,10,1,1,2
257,7,14,3,0,
257,7,15,4,0,
257,7,24,1,16,
257,7,25,3,0,
257,7,28,1,21,
257,7,34,3,0,
257,7,38,3,0,
257,7,45,1,1,3
257,7,46,4,0,
257,7,52,1,1,5
257,7,52,1,13,
257,7,53,4,0,
257,7,63,4,0,
257,7,64,1,17,
257,7,68,3,0,
257,7,69,3,0,
257,7,70,4,0,
257,7,89,4,0,
257,7,91,4,0,
257,7,92,4,0,
257,7,98,1,32,
257,7,102,3,0,
257,7,104,4,0,
257,7,116,1,1,4
257,7,116,1,7,
257,7,119,1,49,
257,7,126,4,0,
257,7,156,4,0,
257,7,157,3,0,
257,7,163,1,42,
257,7,164,3,0,
257,7,182,4,0,
257,7,213,4,0,
257,7,216,4,0,
257,7,218,4,0,
257,7,237,4,0,
257,7,241,4,0,
257,7,249,4,0,
257,7,263,4,0,
257,7,264,4,0,
257,7,280,4,0,
257,7,290,4,0,
257,7,299,1,36,
257,7,315,4,0,
257,7,317,4,0,
257,7,327,1,59,
257,7,332,4,0,
257,7,339,1,28,
257,7,339,4,0,
257,8,7,1,1,1
257,8,10,1,1,2
257,8,14,4,0,
257,8,15,4,0,
257,8,24,1,16,
257,8,28,1,21,
257,8,45,1,1,3
257,8,46,4,0,
257,8,52,1,1,5
257,8,52,1,13,
257,8,53,4,0,
257,8,63,4,0,
257,8,64,1,17,
257,8,70,4,0,
257,8,76,4,0,
257,8,89,4,0,
257,8,91,4,0,
257,8,92,4,0,
257,8,98,1,32,
257,8,104,4,0,
257,8,116,1,1,4
257,8,116,1,7,
257,8,126,4,0,
257,8,156,4,0,
257,8,157,4,0,
257,8,163,1,42,
257,8,164,4,0,
257,8,182,4,0,
257,8,203,4,0,
257,8,207,4,0,
257,8,213,4,0,
257,8,214,4,0,
257,8,216,4,0,
257,8,218,4,0,
257,8,237,4,0,
257,8,241,4,0,
257,8,249,4,0,
257,8,261,4,0,
257,8,263,4,0,
257,8,264,4,0,
257,8,280,4,0,
257,8,290,4,0,
257,8,299,1,36,
257,8,307,3,0,
257,8,315,4,0,
257,8,317,4,0,
257,8,327,1,59,
257,8,332,4,0,
257,8,339,1,28,
257,8,339,4,0,
257,8,363,4,0,
257,8,374,4,0,
257,8,394,1,66,
257,8,398,4,0,
257,8,411,4,0,
257,8,413,1,49,
257,8,416,4,0,
257,8,421,4,0,
257,8,431,4,0,
257,8,444,4,0,
257,8,445,4,0,
257,9,7,1,1,1
257,9,7,3,0,
257,9,9,3,0,
257,9,10,1,1,2
257,9,14,4,0,
257,9,15,4,0,
257,9,24,1,16,
257,9,28,1,21,
257,9,45,1,1,3
257,9,46,4,0,
257,9,52,1,1,5
257,9,52,1,13,
257,9,53,4,0,
257,9,63,4,0,
257,9,64,1,17,
257,9,70,4,0,
257,9,76,4,0,
257,9,89,4,0,
257,9,91,4,0,
257,9,92,4,0,
257,9,98,1,32,
257,9,104,4,0,
257,9,116,1,1,4
257,9,116,1,7,
257,9,126,4,0,
257,9,129,3,0,
257,9,156,4,0,
257,9,157,4,0,
257,9,163,1,42,
257,9,164,4,0,
257,9,173,3,0,
257,9,182,4,0,
257,9,189,3,0,
257,9,203,4,0,
257,9,207,4,0,
257,9,210,3,0,
257,9,213,4,0,
257,9,214,4,0,
257,9,216,4,0,
257,9,218,4,0,
257,9,237,4,0,
257,9,241,4,0,
257,9,249,4,0,
257,9,257,3,0,
257,9,261,4,0,
257,9,263,4,0,
257,9,264,4,0,
257,9,270,3,0,
257,9,276,3,0,
257,9,280,4,0,
257,9,282,3,0,
257,9,290,4,0,
257,9,299,1,36,
257,9,307,3,0,
257,9,315,4,0,
257,9,317,4,0,
257,9,327,1,59,
257,9,332,4,0,
257,9,339,1,28,
257,9,339,4,0,
257,9,340,3,0,
257,9,363,4,0,
257,9,374,4,0,
257,9,387,3,0,
257,9,394,1,66,
257,9,398,4,0,
257,9,410,3,0,
257,9,411,4,0,
257,9,413,1,49,
257,9,416,4,0,
257,9,421,4,0,
257,9,431,4,0,
257,9,444,4,0,
257,9,445,4,0,
257,10,7,1,1,1
257,10,7,3,0,
257,10,9,3,0,
257,10,10,1,1,2
257,10,14,4,0,
257,10,15,4,0,
257,10,24,1,16,
257,10,28,1,21,
257,10,29,3,0,
257,10,45,1,1,3
257,10,46,4,0,
257,10,52,1,1,5
257,10,52,1,13,
257,10,53,4,0,
257,10,63,4,0,
257,10,64,1,17,
257,10,67,3,0,
257,10,70,4,0,
257,10,76,4,0,
257,10,89,4,0,
257,10,91,4,0,
257,10,92,4,0,
257,10,98,1,32,
257,10,104,4,0,
257,10,116,1,1,4
257,10,116,1,7,
257,10,126,4,0,
257,10,129,3,0,
257,10,156,4,0,
257,10,157,4,0,
257,10,163,1,42,
257,10,164,4,0,
257,10,173,3,0,
257,10,182,4,0,
257,10,189,3,0,
257,10,203,4,0,
257,10,207,4,0,
257,10,210,3,0,
257,10,213,4,0,
257,10,214,4,0,
257,10,216,4,0,
257,10,218,4,0,
257,10,237,4,0,
257,10,241,4,0,
257,10,249,4,0,
257,10,257,3,0,
257,10,261,4,0,
257,10,263,4,0,
257,10,264,4,0,
257,10,270,3,0,
257,10,272,3,0,
257,10,276,3,0,
257,10,280,4,0,
257,10,282,3,0,
257,10,290,4,0,
257,10,299,1,36,
257,10,307,3,0,
257,10,315,4,0,
257,10,317,4,0,
257,10,327,1,59,
257,10,332,4,0,
257,10,339,1,28,
257,10,339,4,0,
257,10,340,3,0,
257,10,363,4,0,
257,10,374,4,0,
257,10,387,3,0,
257,10,394,1,66,
257,10,398,4,0,
257,10,410,3,0,
257,10,411,4,0,
257,10,413,1,49,
257,10,416,4,0,
257,10,421,4,0,
257,10,431,4,0,
257,10,444,4,0,
257,10,445,4,0,
257,11,7,1,1,1
257,11,10,1,1,3
257,11,14,4,0,
257,11,15,4,0,
257,11,24,1,16,
257,11,28,1,21,
257,11,45,1,1,4
257,11,46,4,0,
257,11,52,1,1,6
257,11,52,1,13,
257,11,53,4,0,
257,11,63,4,0,
257,11,64,1,17,
257,11,70,4,0,
257,11,76,4,0,
257,11,89,4,0,
257,11,91,4,0,
257,11,92,4,0,
257,11,98,1,32,
257,11,104,4,0,
257,11,116,1,1,5
257,11,116,1,7,
257,11,126,4,0,
257,11,136,1,1,2
257,11,156,4,0,
257,11,157,4,0,
257,11,163,1,42,
257,11,164,4,0,
257,11,182,4,0,
257,11,207,4,0,
257,11,213,4,0,
257,11,216,4,0,
257,11,218,4,0,
257,11,237,4,0,
257,11,241,4,0,
257,11,249,4,0,
257,11,261,4,0,
257,11,263,4,0,
257,11,280,4,0,
257,11,299,1,36,
257,11,307,3,0,
257,11,315,4,0,
257,11,317,4,0,
257,11,327,1,59,
257,11,332,4,0,
257,11,339,1,28,
257,11,339,4,0,
257,11,374,4,0,
257,11,394,1,66,
257,11,398,4,0,
257,11,411,4,0,
257,11,413,1,49,
257,11,416,4,0,
257,11,421,4,0,
257,11,444,4,0,
257,11,468,4,0,
257,11,488,4,0,
257,11,490,4,0,
257,11,496,4,0,
257,11,497,4,0,
257,11,510,4,0,
257,11,512,4,0,
257,11,519,3,0,
257,11,523,4,0,
257,11,526,4,0,
257,12,7,1,1,1
257,12,10,1,1,2
257,12,15,4,0,
257,12,24,1,16,
257,12,28,1,21,
257,12,45,1,1,3
257,12,46,4,0,
257,12,52,1,1,5
257,12,52,1,13,
257,12,53,4,0,
257,12,63,4,0,
257,12,64,1,17,
257,12,70,4,0,
257,12,89,4,0,
257,12,91,4,0,
257,12,92,4,0,
257,12,98,1,32,
257,12,104,4,0,
257,12,116,1,1,4
257,12,116,1,7,
257,12,119,1,49,
257,12,126,4,0,
257,12,156,4,0,
257,12,163,1,42,
257,12,182,4,0,
257,12,213,4,0,
257,12,216,4,0,
257,12,218,4,0,
257,12,237,4,0,
257,12,241,4,0,
257,12,249,4,0,
257,12,263,4,0,
257,12,264,4,0,
257,12,280,4,0,
257,12,290,4,0,
257,12,299,1,36,
257,12,315,4,0,
257,12,317,4,0,
257,12,327,1,59,
257,12,332,4,0,
257,12,339,1,28,
257,12,339,4,0,
257,13,7,1,1,1
257,13,10,1,1,2
257,13,15,4,0,
257,13,24,1,16,
257,13,28,1,21,
257,13,34,3,0,
257,13,38,3,0,
257,13,45,1,1,3
257,13,46,4,0,
257,13,52,1,1,5
257,13,52,1,13,
257,13,53,4,0,
257,13,63,4,0,
257,13,64,1,17,
257,13,69,3,0,
257,13,70,4,0,
257,13,89,4,0,
257,13,91,4,0,
257,13,92,4,0,
257,13,98,1,32,
257,13,102,3,0,
257,13,104,4,0,
257,13,116,1,1,4
257,13,116,1,7,
257,13,119,1,49,
257,13,126,4,0,
257,13,156,4,0,
257,13,163,1,42,
257,13,164,3,0,
257,13,182,4,0,
257,13,207,3,0,
257,13,213,4,0,
257,13,216,4,0,
257,13,218,4,0,
257,13,237,4,0,
257,13,241,4,0,
257,13,249,4,0,
257,13,263,4,0,
257,13,264,4,0,
257,13,280,4,0,
257,13,290,4,0,
257,13,299,1,36,
257,13,315,4,0,
257,13,317,4,0,
257,13,327,1,59,
257,13,332,4,0,
257,13,339,1,28,
257,13,339,4,0,
257,14,7,1,1,1
257,14,7,3,0,
257,14,9,3,0,
257,14,10,1,1,3
257,14,14,4,0,
257,14,15,4,0,
257,14,24,1,16,
257,14,28,1,21,
257,14,45,1,1,4
257,14,46,4,0,
257,14,52,1,1,6
257,14,52,1,13,
257,14,53,4,0,
257,14,63,4,0,
257,14,64,1,17,
257,14,67,3,0,
257,14,70,4,0,
257,14,76,4,0,
257,14,89,4,0,
257,14,91,4,0,
257,14,92,4,0,
257,14,98,1,32,
257,14,104,4,0,
257,14,116,1,1,5
257,14,116,1,7,
257,14,126,4,0,
257,14,136,1,1,2
257,14,156,4,0,
257,14,157,4,0,
257,14,163,1,42,
257,14,164,4,0,
257,14,173,3,0,
257,14,182,4,0,
257,14,207,4,0,
257,14,213,4,0,
257,14,214,3,0,
257,14,216,4,0,
257,14,218,4,0,
257,14,237,4,0,
257,14,241,4,0,
257,14,249,4,0,
257,14,257,3,0,
257,14,261,4,0,
257,14,263,4,0,
257,14,270,3,0,
257,14,272,3,0,
257,14,276,3,0,
257,14,280,4,0,
257,14,282,3,0,
257,14,299,1,36,
257,14,307,3,0,
257,14,315,4,0,
257,14,317,4,0,
257,14,327,1,59,
257,14,332,4,0,
257,14,339,1,28,
257,14,339,4,0,
257,14,340,3,0,
257,14,374,4,0,
257,14,387,3,0,
257,14,394,1,66,
257,14,398,4,0,
257,14,411,4,0,
257,14,413,1,49,
257,14,416,4,0,
257,14,421,4,0,
257,14,444,4,0,
257,14,468,4,0,
257,14,488,4,0,
257,14,490,4,0,
257,14,496,4,0,
257,14,497,4,0,
257,14,510,4,0,
257,14,512,4,0,
257,14,519,3,0,
257,14,523,4,0,
257,14,526,4,0,
257,14,530,3,0,
257,15,7,1,1,2
257,15,10,1,1,4
257,15,14,4,0,
257,15,15,4,0,
257,15,24,1,16,
257,15,28,1,21,
257,15,45,1,1,5
257,15,46,4,0,
257,15,52,1,1,7
257,15,52,1,13,
257,15,53,4,0,
257,15,63,4,0,
257,15,64,1,17,
257,15,70,4,0,
257,15,76,4,0,
257,15,89,4,0,
257,15,91,4,0,
257,15,92,4,0,
257,15,98,1,32,
257,15,104,4,0,
257,15,116,1,1,6
257,15,116,1,7,
257,15,126,4,0,
257,15,136,1,1,3
257,15,156,4,0,
257,15,157,4,0,
257,15,163,1,42,
257,15,164,4,0,
257,15,182,4,0,
257,15,207,4,0,
257,15,213,4,0,
257,15,214,4,0,
257,15,216,4,0,
257,15,218,4,0,
257,15,237,4,0,
257,15,241,4,0,
257,15,249,4,0,
257,15,261,4,0,
257,15,263,4,0,
257,15,280,4,0,
257,15,299,1,36,
257,15,307,3,0,
257,15,315,4,0,
257,15,317,4,0,
257,15,327,1,59,
257,15,332,4,0,
257,15,339,1,28,
257,15,339,4,0,
257,15,374,4,0,
257,15,394,1,1,1
257,15,394,1,66,
257,15,398,4,0,
257,15,411,4,0,
257,15,413,1,49,
257,15,416,4,0,
257,15,421,4,0,
257,15,444,4,0,
257,15,468,4,0,
257,15,488,4,0,
257,15,490,4,0,
257,15,496,4,0,
257,15,497,4,0,
257,15,510,4,0,
257,15,512,4,0,
257,15,519,3,0,
257,15,523,4,0,
257,15,590,4,0,
257,15,612,4,0,
257,16,7,1,1,2
257,16,7,3,0,
257,16,9,3,0,
257,16,10,1,1,4
257,16,14,4,0,
257,16,15,4,0,
257,16,24,1,16,1
257,16,28,1,1,7
257,16,28,1,10,1
257,16,45,1,1,5
257,16,46,4,0,
257,16,52,1,1,6
257,16,52,1,5,1
257,16,53,4,0,
257,16,63,4,0,
257,16,64,1,14,1
257,16,67,3,0,
257,16,70,4,0,
257,16,76,4,0,
257,16,89,4,0,
257,16,91,4,0,
257,16,92,4,0,
257,16,98,1,25,1
257,16,104,4,0,
257,16,116,1,37,1
257,16,126,4,0,
257,16,136,1,1,3
257,16,156,4,0,
257,16,157,4,0,
257,16,163,1,44,1
257,16,164,4,0,
257,16,173,3,0,
257,16,182,4,0,
257,16,207,4,0,
257,16,213,4,0,
257,16,214,4,0,
257,16,216,4,0,
257,16,218,4,0,
257,16,237,4,0,
257,16,241,4,0,
257,16,249,4,0,
257,16,257,3,0,
257,16,261,4,0,
257,16,263,4,0,
257,16,264,3,0,
257,16,270,3,0,
257,16,272,3,0,
257,16,276,3,0,
257,16,280,4,0,
257,16,282,3,0,
257,16,290,4,0,
257,16,299,1,36,1
257,16,307,3,0,
257,16,315,4,0,
257,16,317,4,0,
257,16,327,1,57,1
257,16,332,4,0,
257,16,339,1,31,1
257,16,339,4,0,
257,16,340,3,0,
257,16,374,4,0,
257,16,387,3,0,
257,16,394,1,1,1
257,16,394,1,63,1
257,16,398,4,0,
257,16,411,4,0,
257,16,413,1,50,1
257,16,416,4,0,
257,16,421,4,0,
257,16,444,4,0,
257,16,468,4,0,
257,16,488,1,20,1
257,16,488,4,0,
257,16,490,4,0,
257,16,496,4,0,
257,16,497,4,0,
257,16,510,4,0,
257,16,512,4,0,
257,16,519,3,0,
257,16,523,4,0,
257,16,530,3,0,
257,16,590,4,0,
257,16,612,4,0,
257,17,7,1,1,4
257,17,10,1,1,6
257,17,14,4,0,
257,17,24,1,1,2
257,17,28,1,1,9
257,17,28,1,10,
257,17,45,1,1,7
257,17,46,4,0,
257,17,52,1,1,8
257,17,52,1,5,
257,17,53,4,0,
257,17,63,4,0,
257,17,64,1,14,
257,17,76,4,0,
257,17,89,4,0,
257,17,92,4,0,
257,17,98,1,25,
257,17,104,4,0,
257,17,116,1,37,
257,17,126,4,0,
257,17,136,1,1,5
257,17,156,4,0,
257,17,157,4,0,
257,17,163,1,44,
257,17,164,4,0,
257,17,182,4,0,
257,17,207,4,0,
257,17,213,4,0,
257,17,214,4,0,
257,17,216,4,0,
257,17,218,4,0,
257,17,237,4,0,
257,17,241,4,0,
257,17,261,4,0,
257,17,263,4,0,
257,17,280,4,0,
257,17,299,1,0,
257,17,299,1,1,1
257,17,307,3,0,
257,17,315,4,0,
257,17,317,4,0,
257,17,327,1,57,
257,17,332,4,0,
257,17,339,1,31,
257,17,339,4,0,
257,17,374,4,0,
257,17,394,1,1,3
257,17,394,1,63,
257,17,398,4,0,
257,17,411,4,0,
257,17,413,1,50,
257,17,416,4,0,
257,17,421,4,0,
257,17,444,4,0,
257,17,488,1,20,
257,17,488,4,0,
257,17,490,4,0,
257,17,496,4,0,
257,17,497,4,0,
257,17,512,4,0,
257,17,519,3,0,
257,17,523,4,0,
257,17,526,4,0,
257,17,590,4,0,
257,18,7,1,1,4
257,18,10,1,1,6
257,18,14,4,0,
257,18,24,1,1,2
257,18,28,1,1,9
257,18,28,1,10,
257,18,45,1,1,7
257,18,46,4,0,
257,18,52,1,1,8
257,18,52,1,5,
257,18,53,4,0,
257,18,63,4,0,
257,18,64,1,14,
257,18,76,4,0,
257,18,89,4,0,
257,18,92,4,0,
257,18,98,1,25,
257,18,104,4,0,
257,18,116,1,37,
257,18,126,4,0,
257,18,136,1,1,5
257,18,156,4,0,
257,18,157,4,0,
257,18,163,1,44,
257,18,164,4,0,
257,18,182,4,0,
257,18,207,4,0,
257,18,213,4,0,
257,18,214,4,0,
257,18,216,4,0,
257,18,218,4,0,
257,18,237,4,0,
257,18,241,4,0,
257,18,261,4,0,
257,18,263,4,0,
257,18,280,4,0,
257,18,299,1,0,
257,18,299,1,1,1
257,18,307,3,0,
257,18,315,4,0,
257,18,317,4,0,
257,18,327,1,57,
257,18,332,4,0,
257,18,339,1,31,
257,18,339,4,0,
257,18,374,4,0,
257,18,394,1,1,3
257,18,394,1,63,
257,18,398,4,0,
257,18,411,4,0,
257,18,413,1,50,
257,18,416,4,0,
257,18,421,4,0,
257,18,444,4,0,
257,18,488,1,20,
257,18,488,4,0,
257,18,490,4,0,
257,18,496,4,0,
257,18,497,4,0,
257,18,512,4,0,
257,18,519,3,0,
257,18,523,4,0,
257,18,526,4,0,
257,18,590,4,0,
258,5,23,2,0,
258,5,33,1,1,1
258,5,36,1,28,
258,5,45,1,1,2
258,5,55,1,10,
258,5,56,1,42,
258,5,57,4,0,
258,5,58,4,0,
258,5,59,4,0,
258,5,70,4,0,
258,5,91,4,0,
258,5,92,4,0,
258,5,104,4,0,
258,5,117,1,15,
258,5,127,4,0,
258,5,156,4,0,
258,5,174,2,0,
258,5,182,1,37,
258,5,182,4,0,
258,5,189,1,6,
258,5,193,1,19,
258,5,213,4,0,
258,5,216,4,0,
258,5,218,4,0,
258,5,231,4,0,
258,5,237,4,0,
258,5,240,4,0,
258,5,243,2,0,
258,5,249,4,0,
258,5,250,1,33,
258,5,253,2,0,
258,5,258,4,0,
258,5,263,4,0,
258,5,283,1,46,
258,5,287,2,0,
258,5,290,4,0,
258,5,291,4,0,
258,5,300,1,24,
258,5,301,2,0,
258,5,317,4,0,
258,5,352,4,0,
258,6,23,2,0,
258,6,33,1,1,1
258,6,34,3,0,
258,6,36,1,28,
258,6,38,3,0,
258,6,45,1,1,2
258,6,55,1,10,
258,6,56,1,42,
258,6,57,4,0,
258,6,58,4,0,
258,6,59,4,0,
258,6,70,4,0,
258,6,91,4,0,
258,6,92,4,0,
258,6,102,3,0,
258,6,104,4,0,
258,6,111,3,0,
258,6,117,1,15,
258,6,127,4,0,
258,6,156,4,0,
258,6,164,3,0,
258,6,173,3,0,
258,6,174,2,0,
258,6,182,1,37,
258,6,182,4,0,
258,6,189,1,6,
258,6,189,3,0,
258,6,193,1,19,
258,6,196,3,0,
258,6,203,3,0,
258,6,205,3,0,
258,6,207,3,0,
258,6,213,4,0,
258,6,214,3,0,
258,6,216,4,0,
258,6,218,4,0,
258,6,231,4,0,
258,6,237,4,0,
258,6,240,4,0,
258,6,243,2,0,
258,6,249,4,0,
258,6,250,1,33,
258,6,253,2,0,
258,6,258,4,0,
258,6,263,4,0,
258,6,283,1,46,
258,6,287,2,0,
258,6,290,4,0,
258,6,291,4,0,
258,6,300,1,24,
258,6,301,2,0,
258,6,317,4,0,
258,6,352,4,0,
258,7,23,2,0,
258,7,33,1,1,1
258,7,34,3,0,
258,7,36,1,28,
258,7,38,3,0,
258,7,45,1,1,2
258,7,55,1,10,
258,7,56,1,42,
258,7,57,4,0,
258,7,58,4,0,
258,7,59,4,0,
258,7,70,4,0,
258,7,91,4,0,
258,7,92,4,0,
258,7,102,3,0,
258,7,104,4,0,
258,7,117,1,15,
258,7,127,4,0,
258,7,156,4,0,
258,7,164,3,0,
258,7,174,2,0,
258,7,182,1,37,
258,7,182,4,0,
258,7,189,1,6,
258,7,193,1,19,
258,7,213,4,0,
258,7,216,4,0,
258,7,218,4,0,
258,7,231,4,0,
258,7,237,4,0,
258,7,240,4,0,
258,7,243,2,0,
258,7,249,4,0,
258,7,250,1,33,
258,7,253,2,0,
258,7,258,4,0,
258,7,263,4,0,
258,7,283,1,46,
258,7,287,2,0,
258,7,290,4,0,
258,7,291,4,0,
258,7,300,1,24,
258,7,301,2,0,
258,7,317,4,0,
258,7,352,4,0,
258,8,23,2,0,
258,8,33,1,1,1
258,8,36,1,28,
258,8,38,2,0,
258,8,44,2,0,
258,8,45,1,1,2
258,8,55,1,10,
258,8,56,1,42,
258,8,57,4,0,
258,8,58,4,0,
258,8,59,4,0,
258,8,68,2,0,
258,8,70,4,0,
258,8,91,4,0,
258,8,92,4,0,
258,8,104,4,0,
258,8,117,1,15,
258,8,127,4,0,
258,8,156,4,0,
258,8,157,4,0,
258,8,164,4,0,
258,8,174,2,0,
258,8,182,1,37,
258,8,182,4,0,
258,8,189,1,6,
258,8,193,1,19,
258,8,203,4,0,
258,8,207,4,0,
258,8,213,4,0,
258,8,214,4,0,
258,8,216,4,0,
258,8,218,4,0,
258,8,231,4,0,
258,8,237,4,0,
258,8,240,4,0,
258,8,243,2,0,
258,8,246,2,0,
258,8,249,4,0,
258,8,250,1,33,
258,8,250,2,0,
258,8,253,2,0,
258,8,258,4,0,
258,8,263,4,0,
258,8,283,1,46,
258,8,287,2,0,
258,8,290,4,0,
258,8,300,1,24,
258,8,301,2,0,
258,8,317,4,0,
258,8,352,4,0,
258,8,363,4,0,
258,8,426,2,0,
258,8,445,4,0,
258,9,23,2,0,
258,9,33,1,1,1
258,9,36,1,28,
258,9,38,2,0,
258,9,44,2,0,
258,9,45,1,1,2
258,9,55,1,10,
258,9,56,1,42,
258,9,57,4,0,
258,9,58,4,0,
258,9,59,4,0,
258,9,68,2,0,
258,9,70,4,0,
258,9,91,4,0,
258,9,92,4,0,
258,9,104,4,0,
258,9,117,1,15,
258,9,127,4,0,
258,9,156,4,0,
258,9,157,4,0,
258,9,164,4,0,
258,9,173,3,0,
258,9,174,2,0,
258,9,182,1,37,
258,9,182,4,0,
258,9,189,1,6,
258,9,189,3,0,
258,9,193,1,19,
258,9,196,3,0,
258,9,203,4,0,
258,9,205,3,0,
258,9,207,4,0,
258,9,213,4,0,
258,9,214,4,0,
258,9,216,4,0,
258,9,218,4,0,
258,9,231,4,0,
258,9,237,4,0,
258,9,240,4,0,
258,9,243,2,0,
258,9,246,2,0,
258,9,246,3,0,
258,9,249,4,0,
258,9,250,1,33,
258,9,250,2,0,
258,9,253,2,0,
258,9,258,4,0,
258,9,263,4,0,
258,9,276,3,0,
258,9,283,1,46,
258,9,283,3,0,
258,9,287,2,0,
258,9,290,4,0,
258,9,291,3,0,
258,9,300,1,24,
258,9,301,2,0,
258,9,317,4,0,
258,9,352,4,0,
258,9,363,4,0,
258,9,401,3,0,
258,9,414,3,0,
258,9,426,2,0,
258,9,445,4,0,
258,10,23,2,0,
258,10,29,3,0,
258,10,33,1,1,1
258,10,36,1,28,
258,10,38,2,0,
258,10,44,2,0,
258,10,45,1,1,2
258,10,55,1,10,
258,10,56,1,42,
258,10,57,4,0,
258,10,58,4,0,
258,10,59,4,0,
258,10,67,3,0,
258,10,68,2,0,
258,10,70,4,0,
258,10,91,4,0,
258,10,92,4,0,
258,10,104,4,0,
258,10,117,1,15,
258,10,124,2,0,
258,10,127,4,0,
258,10,156,4,0,
258,10,157,4,0,
258,10,164,4,0,
258,10,173,3,0,
258,10,174,2,0,
258,10,182,1,37,
258,10,182,4,0,
258,10,189,1,6,
258,10,189,3,0,
258,10,193,1,19,
258,10,196,3,0,
258,10,203,4,0,
258,10,205,3,0,
258,10,207,4,0,
258,10,213,4,0,
258,10,214,4,0,
258,10,216,4,0,
258,10,218,4,0,
258,10,231,4,0,
258,10,237,4,0,
258,10,240,4,0,
258,10,243,2,0,
258,10,246,2,0,
258,10,246,3,0,
258,10,249,4,0,
258,10,250,1,33,
258,10,250,2,0,
258,10,250,4,0,
258,10,253,2,0,
258,10,258,4,0,
258,10,263,4,0,
258,10,276,3,0,
258,10,281,2,0,
258,10,283,1,46,
258,10,283,3,0,
258,10,287,2,0,
258,10,290,4,0,
258,10,291,3,0,
258,10,300,1,24,
258,10,301,2,0,
258,10,317,4,0,
258,10,352,4,0,
258,10,363,4,0,
258,10,401,3,0,
258,10,414,3,0,
258,10,426,2,0,
258,10,445,4,0,
258,11,23,2,0,
258,11,33,1,1,1
258,11,36,1,28,
258,11,38,2,0,
258,11,44,2,0,
258,11,45,1,1,2
258,11,55,1,10,
258,11,56,1,42,
258,11,57,4,0,
258,11,58,4,0,
258,11,59,4,0,
258,11,68,2,0,
258,11,70,4,0,
258,11,91,4,0,
258,11,92,4,0,
258,11,104,4,0,
258,11,117,1,15,
258,11,124,2,0,
258,11,127,4,0,
258,11,156,4,0,
258,11,157,4,0,
258,11,164,4,0,
258,11,174,2,0,
258,11,182,1,37,
258,11,182,4,0,
258,11,189,1,6,
258,11,193,1,19,
258,11,207,4,0,
258,11,213,4,0,
258,11,216,4,0,
258,11,218,4,0,
258,11,237,4,0,
258,11,240,4,0,
258,11,243,2,0,
258,11,246,2,0,
258,11,249,4,0,
258,11,250,1,33,
258,11,250,2,0,
258,11,253,2,0,
258,11,258,4,0,
258,11,263,4,0,
258,11,281,2,0,
258,11,283,1,46,
258,11,287,2,0,
258,11,291,4,0,
258,11,300,1,24,
258,11,301,2,0,
258,11,317,4,0,
258,11,419,2,0,
258,11,426,2,0,
258,11,469,2,0,
258,11,482,4,0,
258,11,496,4,0,
258,11,497,4,0,
258,11,503,4,0,
258,11,518,3,0,
258,12,33,1,1,1
258,12,36,1,28,
258,12,45,1,1,2
258,12,55,1,10,
258,12,56,1,42,
258,12,57,4,0,
258,12,58,4,0,
258,12,59,4,0,
258,12,70,4,0,
258,12,91,4,0,
258,12,92,4,0,
258,12,104,4,0,
258,12,117,1,15,
258,12,127,4,0,
258,12,156,4,0,
258,12,182,1,37,
258,12,182,4,0,
258,12,189,1,6,
258,12,193,1,19,
258,12,213,4,0,
258,12,216,4,0,
258,12,218,4,0,
258,12,231,4,0,
258,12,237,4,0,
258,12,240,4,0,
258,12,249,4,0,
258,12,250,1,33,
258,12,258,4,0,
258,12,263,4,0,
258,12,283,1,46,
258,12,290,4,0,
258,12,291,4,0,
258,12,300,1,24,
258,12,317,4,0,
258,12,352,4,0,
258,13,33,1,1,1
258,13,34,3,0,
258,13,36,1,28,
258,13,38,3,0,
258,13,45,1,1,2
258,13,55,1,10,
258,13,56,1,42,
258,13,57,4,0,
258,13,58,4,0,
258,13,59,4,0,
258,13,70,4,0,
258,13,91,4,0,
258,13,92,4,0,
258,13,102,3,0,
258,13,104,4,0,
258,13,117,1,15,
258,13,127,4,0,
258,13,156,4,0,
258,13,164,3,0,
258,13,182,1,37,
258,13,182,4,0,
258,13,189,1,6,
258,13,193,1,19,
258,13,196,3,0,
258,13,207,3,0,
258,13,213,4,0,
258,13,216,4,0,
258,13,218,4,0,
258,13,231,4,0,
258,13,237,4,0,
258,13,240,4,0,
258,13,249,4,0,
258,13,250,1,33,
258,13,258,4,0,
258,13,263,4,0,
258,13,283,1,46,
258,13,290,4,0,
258,13,291,4,0,
258,13,300,1,24,
258,13,317,4,0,
258,13,352,4,0,
258,14,23,2,0,
258,14,33,1,1,1
258,14,36,1,28,
258,14,38,2,0,
258,14,44,2,0,
258,14,45,1,1,2
258,14,55,1,10,
258,14,56,1,42,
258,14,57,4,0,
258,14,58,4,0,
258,14,59,4,0,
258,14,67,3,0,
258,14,68,2,0,
258,14,70,4,0,
258,14,91,4,0,
258,14,92,4,0,
258,14,104,4,0,
258,14,117,1,15,
258,14,124,2,0,
258,14,127,4,0,
258,14,156,4,0,
258,14,157,4,0,
258,14,164,4,0,
258,14,173,3,0,
258,14,174,2,0,
258,14,182,1,37,
258,14,182,4,0,
258,14,189,1,6,
258,14,193,1,19,
258,14,196,3,0,
258,14,207,4,0,
258,14,213,4,0,
258,14,214,3,0,
258,14,216,4,0,
258,14,218,4,0,
258,14,231,3,0,
258,14,237,4,0,
258,14,240,4,0,
258,14,243,2,0,
258,14,246,2,0,
258,14,249,4,0,
258,14,250,1,33,
258,14,250,2,0,
258,14,253,2,0,
258,14,258,4,0,
258,14,263,4,0,
258,14,276,3,0,
258,14,281,2,0,
258,14,283,1,46,
258,14,283,3,0,
258,14,287,2,0,
258,14,291,4,0,
258,14,300,1,24,
258,14,301,2,0,
258,14,317,4,0,
258,14,401,3,0,
258,14,414,3,0,
258,14,419,2,0,
258,14,426,2,0,
258,14,469,2,0,
258,14,482,4,0,
258,14,496,4,0,
258,14,497,4,0,
258,14,503,4,0,
258,14,518,3,0,
258,15,23,2,0,
258,15,33,1,1,1
258,15,36,1,28,
258,15,38,2,0,
258,15,44,2,0,
258,15,45,1,1,2
258,15,55,1,10,
258,15,56,1,42,
258,15,57,4,0,
258,15,58,4,0,
258,15,59,4,0,
258,15,68,2,0,
258,15,70,4,0,
258,15,91,4,0,
258,15,92,4,0,
258,15,104,4,0,
258,15,112,2,0,
258,15,117,1,15,
258,15,124,2,0,
258,15,127,4,0,
258,15,156,4,0,
258,15,157,4,0,
258,15,164,4,0,
258,15,174,2,0,
258,15,182,1,37,
258,15,182,4,0,
258,15,189,1,6,
258,15,193,1,19,
258,15,207,4,0,
258,15,213,4,0,
258,15,214,4,0,
258,15,216,4,0,
258,15,218,4,0,
258,15,237,4,0,
258,15,240,4,0,
258,15,243,2,0,
258,15,246,2,0,
258,15,249,4,0,
258,15,250,1,33,
258,15,250,2,0,
258,15,253,2,0,
258,15,258,4,0,
258,15,263,4,0,
258,15,281,2,0,
258,15,283,1,46,
258,15,287,2,0,
258,15,300,1,24,
258,15,301,2,0,
258,15,317,4,0,
258,15,419,2,0,
258,15,426,2,0,
258,15,469,2,0,
258,15,482,4,0,
258,15,496,4,0,
258,15,497,4,0,
258,15,503,4,0,
258,15,518,3,0,
258,15,590,4,0,
258,16,23,2,0,
258,16,33,1,1,1
258,16,36,1,36,1
258,16,38,2,0,
258,16,44,2,0,
258,16,45,1,1,2
258,16,55,1,4,1
258,16,56,1,41,1
258,16,57,4,0,
258,16,58,4,0,
258,16,59,4,0,
258,16,67,3,0,
258,16,68,2,0,
258,16,70,4,0,
258,16,88,1,25,1
258,16,91,4,0,
258,16,92,4,0,
258,16,104,4,0,
258,16,112,2,0,
258,16,117,1,17,1
258,16,124,2,0,
258,16,127,4,0,
258,16,156,4,0,
258,16,157,4,0,
258,16,164,4,0,
258,16,173,3,0,
258,16,174,2,0,
258,16,182,1,28,1
258,16,182,4,0,
258,16,189,1,9,1
258,16,193,1,12,1
258,16,196,3,0,
258,16,207,4,0,
258,16,213,4,0,
258,16,214,4,0,
258,16,216,4,0,
258,16,218,4,0,
258,16,231,3,0,
258,16,237,4,0,
258,16,240,4,0,
258,16,243,2,0,
258,16,246,2,0,
258,16,249,4,0,
258,16,250,1,33,1
258,16,250,2,0,
258,16,253,2,0,
258,16,253,3,0,
258,16,258,4,0,
258,16,263,4,0,
258,16,276,3,0,
258,16,281,2,0,
258,16,283,1,44,1
258,16,283,3,0,
258,16,287,2,0,
258,16,290,4,0,
258,16,291,4,0,
258,16,300,1,20,1
258,16,301,2,0,
258,16,317,4,0,
258,16,352,3,0,
258,16,401,3,0,
258,16,414,3,0,
258,16,419,2,0,
258,16,426,2,0,
258,16,469,2,0,
258,16,482,4,0,
258,16,496,4,0,
258,16,497,4,0,
258,16,503,4,0,
258,16,518,3,0,
258,16,590,4,0,
258,17,23,2,0,
258,17,33,1,1,1
258,17,36,1,36,
258,17,38,2,0,
258,17,44,2,0,
258,17,45,1,1,2
258,17,55,1,4,
258,17,56,1,41,
258,17,57,4,0,
258,17,58,4,0,
258,17,59,4,0,
258,17,68,2,0,
258,17,88,1,25,
258,17,92,4,0,
258,17,104,4,0,
258,17,112,2,0,
258,17,117,1,17,
258,17,124,2,0,
258,17,127,4,0,
258,17,156,4,0,
258,17,157,4,0,
258,17,164,4,0,
258,17,174,2,0,
258,17,182,1,28,
258,17,182,4,0,
258,17,189,1,9,
258,17,193,1,12,
258,17,207,4,0,
258,17,213,4,0,
258,17,214,4,0,
258,17,216,4,0,
258,17,218,4,0,
258,17,237,4,0,
258,17,240,4,0,
258,17,243,2,0,
258,17,246,2,0,
258,17,250,1,33,
258,17,250,2,0,
258,17,253,2,0,
258,17,258,4,0,
258,17,263,4,0,
258,17,281,2,0,
258,17,283,1,44,
258,17,287,2,0,
258,17,300,1,20,
258,17,301,2,0,
258,17,317,4,0,
258,17,419,2,0,
258,17,426,2,0,
258,17,469,2,0,
258,17,482,4,0,
258,17,496,4,0,
258,17,497,4,0,
258,17,503,4,0,
258,17,518,3,0,
258,17,526,4,0,
258,17,590,4,0,
258,18,23,2,0,
258,18,33,1,1,1
258,18,36,1,36,
258,18,38,2,0,
258,18,44,2,0,
258,18,45,1,1,2
258,18,55,1,4,
258,18,56,1,41,
258,18,57,4,0,
258,18,58,4,0,
258,18,59,4,0,
258,18,68,2,0,
258,18,88,1,25,
258,18,92,4,0,
258,18,104,4,0,
258,18,112,2,0,
258,18,117,1,17,
258,18,124,2,0,
258,18,127,4,0,
258,18,156,4,0,
258,18,157,4,0,
258,18,164,4,0,
258,18,174,2,0,
258,18,182,1,28,
258,18,182,4,0,
258,18,189,1,9,
258,18,193,1,12,
258,18,207,4,0,
258,18,213,4,0,
258,18,214,4,0,
258,18,216,4,0,
258,18,218,4,0,
258,18,237,4,0,
258,18,240,4,0,
258,18,243,2,0,
258,18,246,2,0,
258,18,250,1,33,
258,18,250,2,0,
258,18,253,2,0,
258,18,258,4,0,
258,18,263,4,0,
258,18,281,2,0,
258,18,283,1,44,
258,18,287,2,0,
258,18,300,1,20,
258,18,301,2,0,
258,18,317,4,0,
258,18,419,2,0,
258,18,426,2,0,
258,18,469,2,0,
258,18,482,4,0,
258,18,496,4,0,
258,18,497,4,0,
258,18,503,4,0,
258,18,518,3,0,
258,18,526,4,0,
258,18,590,4,0,
259,5,33,1,1,1
259,5,36,1,31,
259,5,45,1,1,2
259,5,55,1,1,4
259,5,55,1,10,
259,5,57,4,0,
259,5,58,4,0,
259,5,59,4,0,
259,5,70,4,0,
259,5,89,1,46,
259,5,89,4,0,
259,5,91,4,0,
259,5,92,4,0,
259,5,104,4,0,
259,5,117,1,15,
259,5,127,4,0,
259,5,156,4,0,
259,5,182,1,42,
259,5,182,4,0,
259,5,189,1,1,3
259,5,189,1,6,
259,5,193,1,20,
259,5,213,4,0,
259,5,216,4,0,
259,5,218,4,0,
259,5,231,4,0,
259,5,237,4,0,
259,5,240,4,0,
259,5,249,4,0,
259,5,258,4,0,
259,5,263,4,0,
259,5,283,1,53,
259,5,290,4,0,
259,5,291,4,0,
259,5,300,1,25,
259,5,317,4,0,
259,5,330,1,37,
259,5,341,1,16,
259,5,352,4,0,
259,6,5,3,0,
259,6,8,3,0,
259,6,25,3,0,
259,6,33,1,1,1
259,6,34,3,0,
259,6,36,1,31,
259,6,38,3,0,
259,6,45,1,1,2
259,6,55,1,1,4
259,6,55,1,10,
259,6,57,4,0,
259,6,58,4,0,
259,6,59,4,0,
259,6,68,3,0,
259,6,69,3,0,
259,6,70,4,0,
259,6,89,1,46,
259,6,89,4,0,
259,6,91,4,0,
259,6,92,4,0,
259,6,102,3,0,
259,6,104,4,0,
259,6,111,3,0,
259,6,117,1,15,
259,6,127,4,0,
259,6,156,4,0,
259,6,157,3,0,
259,6,164,3,0,
259,6,173,3,0,
259,6,182,1,42,
259,6,182,4,0,
259,6,189,1,1,3
259,6,189,1,6,
259,6,189,3,0,
259,6,193,1,20,
259,6,196,3,0,
259,6,203,3,0,
259,6,205,3,0,
259,6,207,3,0,
259,6,213,4,0,
259,6,214,3,0,
259,6,216,4,0,
259,6,218,4,0,
259,6,223,3,0,
259,6,231,4,0,
259,6,237,4,0,
259,6,240,4,0,
259,6,249,4,0,
259,6,258,4,0,
259,6,263,4,0,
259,6,283,1,53,
259,6,290,4,0,
259,6,291,4,0,
259,6,300,1,25,
259,6,317,4,0,
259,6,330,1,37,
259,6,341,1,16,
259,6,352,4,0,
259,7,5,3,0,
259,7,25,3,0,
259,7,33,1,1,1
259,7,34,3,0,
259,7,36,1,31,
259,7,38,3,0,
259,7,45,1,1,2
259,7,55,1,1,4
259,7,55,1,10,
259,7,57,4,0,
259,7,58,4,0,
259,7,59,4,0,
259,7,68,3,0,
259,7,69,3,0,
259,7,70,4,0,
259,7,89,1,46,
259,7,89,4,0,
259,7,91,4,0,
259,7,92,4,0,
259,7,102,3,0,
259,7,104,4,0,
259,7,117,1,15,
259,7,127,4,0,
259,7,156,4,0,
259,7,157,3,0,
259,7,164,3,0,
259,7,182,1,42,
259,7,182,4,0,
259,7,189,1,1,3
259,7,189,1,6,
259,7,193,1,20,
259,7,213,4,0,
259,7,216,4,0,
259,7,218,4,0,
259,7,231,4,0,
259,7,237,4,0,
259,7,240,4,0,
259,7,249,4,0,
259,7,258,4,0,
259,7,263,4,0,
259,7,283,1,53,
259,7,290,4,0,
259,7,291,4,0,
259,7,300,1,25,
259,7,317,4,0,
259,7,330,1,37,
259,7,341,1,16,
259,7,352,4,0,
259,8,33,1,1,1
259,8,36,1,31,
259,8,45,1,1,2
259,8,55,1,1,4
259,8,55,1,10,
259,8,57,4,0,
259,8,58,4,0,
259,8,59,4,0,
259,8,70,4,0,
259,8,89,1,46,
259,8,89,4,0,
259,8,91,4,0,
259,8,92,4,0,
259,8,104,4,0,
259,8,117,1,15,
259,8,127,4,0,
259,8,156,4,0,
259,8,157,4,0,
259,8,164,4,0,
259,8,182,1,42,
259,8,182,4,0,
259,8,189,1,1,3
259,8,189,1,6,
259,8,193,1,20,
259,8,203,4,0,
259,8,207,4,0,
259,8,213,4,0,
259,8,214,4,0,
259,8,216,4,0,
259,8,218,4,0,
259,8,231,4,0,
259,8,237,4,0,
259,8,240,4,0,
259,8,249,4,0,
259,8,258,4,0,
259,8,263,4,0,
259,8,280,4,0,
259,8,283,1,53,
259,8,290,4,0,
259,8,317,4,0,
259,8,330,1,37,
259,8,341,1,16,
259,8,352,4,0,
259,8,363,4,0,
259,8,374,4,0,
259,8,426,1,25,
259,8,445,4,0,
259,8,446,4,0,
259,9,8,3,0,
259,9,33,1,1,1
259,9,36,1,31,
259,9,45,1,1,2
259,9,55,1,1,4
259,9,55,1,10,
259,9,57,4,0,
259,9,58,4,0,
259,9,59,4,0,
259,9,70,4,0,
259,9,89,1,46,
259,9,89,4,0,
259,9,91,4,0,
259,9,92,4,0,
259,9,104,4,0,
259,9,117,1,15,
259,9,127,4,0,
259,9,156,4,0,
259,9,157,4,0,
259,9,164,4,0,
259,9,173,3,0,
259,9,182,1,42,
259,9,182,4,0,
259,9,189,1,1,3
259,9,189,1,6,
259,9,189,3,0,
259,9,193,1,20,
259,9,196,3,0,
259,9,203,4,0,
259,9,205,3,0,
259,9,207,4,0,
259,9,213,4,0,
259,9,214,4,0,
259,9,216,4,0,
259,9,218,4,0,
259,9,231,4,0,
259,9,237,4,0,
259,9,240,4,0,
259,9,246,3,0,
259,9,249,4,0,
259,9,258,4,0,
259,9,263,4,0,
259,9,276,3,0,
259,9,280,4,0,
259,9,283,1,53,
259,9,283,3,0,
259,9,290,4,0,
259,9,291,3,0,
259,9,317,4,0,
259,9,330,1,37,
259,9,341,1,16,
259,9,352,4,0,
259,9,363,4,0,
259,9,374,4,0,
259,9,401,3,0,
259,9,414,3,0,
259,9,426,1,25,
259,9,445,4,0,
259,9,446,4,0,
259,10,8,3,0,
259,10,29,3,0,
259,10,33,1,1,1
259,10,36,1,31,
259,10,45,1,1,2
259,10,55,1,1,4
259,10,55,1,10,
259,10,57,4,0,
259,10,58,4,0,
259,10,59,4,0,
259,10,67,3,0,
259,10,70,4,0,
259,10,89,1,46,
259,10,89,4,0,
259,10,91,4,0,
259,10,92,4,0,
259,10,104,4,0,
259,10,117,1,15,
259,10,127,4,0,
259,10,156,4,0,
259,10,157,4,0,
259,10,164,4,0,
259,10,173,3,0,
259,10,182,1,42,
259,10,182,4,0,
259,10,189,1,1,3
259,10,189,1,6,
259,10,189,3,0,
259,10,193,1,20,
259,10,196,3,0,
259,10,203,4,0,
259,10,205,3,0,
259,10,207,4,0,
259,10,213,4,0,
259,10,214,4,0,
259,10,216,4,0,
259,10,218,4,0,
259,10,231,4,0,
259,10,237,4,0,
259,10,240,4,0,
259,10,246,3,0,
259,10,249,4,0,
259,10,250,4,0,
259,10,258,4,0,
259,10,263,4,0,
259,10,276,3,0,
259,10,280,4,0,
259,10,283,1,53,
259,10,283,3,0,
259,10,290,4,0,
259,10,291,3,0,
259,10,317,4,0,
259,10,330,1,37,
259,10,341,1,16,
259,10,352,4,0,
259,10,363,4,0,
259,10,374,4,0,
259,10,401,3,0,
259,10,414,3,0,
259,10,426,1,25,
259,10,445,4,0,
259,10,446,4,0,
259,11,33,1,1,1
259,11,36,1,31,
259,11,45,1,1,2
259,11,55,1,1,4
259,11,55,1,10,
259,11,57,4,0,
259,11,58,4,0,
259,11,59,4,0,
259,11,70,4,0,
259,11,89,1,46,
259,11,89,4,0,
259,11,91,4,0,
259,11,92,4,0,
259,11,104,4,0,
259,11,117,1,15,
259,11,127,4,0,
259,11,156,4,0,
259,11,157,4,0,
259,11,164,4,0,
259,11,182,1,42,
259,11,182,4,0,
259,11,189,1,1,3
259,11,189,1,6,
259,11,193,1,20,
259,11,207,4,0,
259,11,213,4,0,
259,11,216,4,0,
259,11,218,4,0,
259,11,237,4,0,
259,11,240,4,0,
259,11,249,4,0,
259,11,258,4,0,
259,11,263,4,0,
259,11,280,4,0,
259,11,283,1,53,
259,11,291,4,0,
259,11,317,4,0,
259,11,330,1,37,
259,11,341,1,16,
259,11,374,4,0,
259,11,426,1,25,
259,11,482,4,0,
259,11,496,4,0,
259,11,497,4,0,
259,11,503,4,0,
259,11,518,3,0,
259,11,523,4,0,
259,12,33,1,1,1
259,12,36,1,31,
259,12,45,1,1,2
259,12,55,1,1,4
259,12,55,1,10,
259,12,57,4,0,
259,12,58,4,0,
259,12,59,4,0,
259,12,70,4,0,
259,12,89,1,46,
259,12,89,4,0,
259,12,91,4,0,
259,12,92,4,0,
259,12,104,4,0,
259,12,117,1,15,
259,12,127,4,0,
259,12,156,4,0,
259,12,182,1,42,
259,12,182,4,0,
259,12,189,1,1,3
259,12,189,1,6,
259,12,193,1,20,
259,12,213,4,0,
259,12,216,4,0,
259,12,218,4,0,
259,12,231,4,0,
259,12,237,4,0,
259,12,240,4,0,
259,12,249,4,0,
259,12,258,4,0,
259,12,263,4,0,
259,12,283,1,53,
259,12,290,4,0,
259,12,291,4,0,
259,12,300,1,25,
259,12,317,4,0,
259,12,330,1,37,
259,12,341,1,16,
259,12,352,4,0,
259,13,33,1,1,1
259,13,34,3,0,
259,13,36,1,31,
259,13,38,3,0,
259,13,45,1,1,2
259,13,55,1,1,4
259,13,55,1,10,
259,13,57,4,0,
259,13,58,4,0,
259,13,59,4,0,
259,13,69,3,0,
259,13,70,4,0,
259,13,89,1,46,
259,13,89,4,0,
259,13,91,4,0,
259,13,92,4,0,
259,13,102,3,0,
259,13,104,4,0,
259,13,117,1,15,
259,13,127,4,0,
259,13,156,4,0,
259,13,164,3,0,
259,13,182,1,42,
259,13,182,4,0,
259,13,189,1,1,3
259,13,189,1,6,
259,13,193,1,20,
259,13,196,3,0,
259,13,207,3,0,
259,13,213,4,0,
259,13,216,4,0,
259,13,218,4,0,
259,13,231,4,0,
259,13,237,4,0,
259,13,240,4,0,
259,13,249,4,0,
259,13,258,4,0,
259,13,263,4,0,
259,13,283,1,53,
259,13,290,4,0,
259,13,291,4,0,
259,13,300,1,25,
259,13,317,4,0,
259,13,330,1,37,
259,13,341,1,16,
259,13,352,4,0,
259,14,8,3,0,
259,14,33,1,1,1
259,14,36,1,31,
259,14,45,1,1,2
259,14,55,1,1,4
259,14,55,1,10,
259,14,57,4,0,
259,14,58,4,0,
259,14,59,4,0,
259,14,67,3,0,
259,14,70,4,0,
259,14,89,1,46,
259,14,89,4,0,
259,14,91,4,0,
259,14,92,4,0,
259,14,104,4,0,
259,14,117,1,15,
259,14,127,4,0,
259,14,156,4,0,
259,14,157,4,0,
259,14,164,4,0,
259,14,173,3,0,
259,14,182,1,42,
259,14,182,4,0,
259,14,189,1,1,3
259,14,189,1,6,
259,14,193,1,20,
259,14,196,3,0,
259,14,207,4,0,
259,14,213,4,0,
259,14,214,3,0,
259,14,216,4,0,
259,14,218,4,0,
259,14,231,3,0,
259,14,237,4,0,
259,14,240,4,0,
259,14,249,4,0,
259,14,258,4,0,
259,14,263,4,0,
259,14,276,3,0,
259,14,280,4,0,
259,14,283,1,53,
259,14,283,3,0,
259,14,291,4,0,
259,14,317,4,0,
259,14,330,1,37,
259,14,341,1,16,
259,14,374,4,0,
259,14,401,3,0,
259,14,414,3,0,
259,14,426,1,25,
259,14,446,3,0,
259,14,482,4,0,
259,14,496,4,0,
259,14,497,4,0,
259,14,503,4,0,
259,14,518,3,0,
259,14,523,4,0,
259,15,33,1,1,1
259,15,36,1,31,
259,15,45,1,1,2
259,15,55,1,1,4
259,15,55,1,10,
259,15,57,4,0,
259,15,58,4,0,
259,15,59,4,0,
259,15,70,4,0,
259,15,89,1,46,
259,15,89,4,0,
259,15,91,4,0,
259,15,92,4,0,
259,15,104,4,0,
259,15,117,1,15,
259,15,127,4,0,
259,15,156,4,0,
259,15,157,4,0,
259,15,164,4,0,
259,15,182,1,42,
259,15,182,4,0,
259,15,189,1,1,3
259,15,189,1,6,
259,15,193,1,20,
259,15,207,4,0,
259,15,213,4,0,
259,15,214,4,0,
259,15,216,4,0,
259,15,218,4,0,
259,15,237,4,0,
259,15,240,4,0,
259,15,249,4,0,
259,15,258,4,0,
259,15,263,4,0,
259,15,280,4,0,
259,15,283,1,53,
259,15,317,4,0,
259,15,330,1,37,
259,15,341,1,16,
259,15,374,4,0,
259,15,426,1,25,
259,15,482,4,0,
259,15,496,4,0,
259,15,497,4,0,
259,15,503,4,0,
259,15,518,3,0,
259,15,523,4,0,
259,15,590,4,0,
259,15,612,4,0,
259,16,8,3,0,
259,16,33,1,1,1
259,16,36,1,42,1
259,16,45,1,1,2
259,16,55,1,1,3
259,16,55,1,4,1
259,16,57,4,0,
259,16,58,4,0,
259,16,59,4,0,
259,16,67,3,0,
259,16,70,4,0,
259,16,89,1,48,1
259,16,89,4,0,
259,16,91,4,0,
259,16,92,4,0,
259,16,104,4,0,
259,16,117,1,18,1
259,16,127,4,0,
259,16,156,4,0,
259,16,157,1,28,1
259,16,157,4,0,
259,16,164,4,0,
259,16,173,3,0,
259,16,182,1,32,1
259,16,182,4,0,
259,16,189,1,1,4
259,16,189,1,9,1
259,16,193,1,12,1
259,16,196,3,0,
259,16,207,4,0,
259,16,213,4,0,
259,16,214,4,0,
259,16,216,4,0,
259,16,218,4,0,
259,16,231,3,0,
259,16,237,4,0,
259,16,240,4,0,
259,16,249,4,0,
259,16,253,3,0,
259,16,258,4,0,
259,16,263,4,0,
259,16,276,3,0,
259,16,280,4,0,
259,16,283,1,52,1
259,16,283,3,0,
259,16,290,4,0,
259,16,291,4,0,
259,16,317,4,0,
259,16,330,1,38,1
259,16,341,1,16,1
259,16,352,3,0,
259,16,374,4,0,
259,16,401,3,0,
259,16,414,3,0,
259,16,426,1,22,1
259,16,446,3,0,
259,16,482,4,0,
259,16,496,4,0,
259,16,497,4,0,
259,16,503,4,0,
259,16,518,3,0,
259,16,523,4,0,
259,16,590,4,0,
259,16,612,4,0,
259,17,33,1,1,2
259,17,36,1,42,
259,17,45,1,1,3
259,17,55,1,1,4
259,17,55,1,4,
259,17,57,4,0,
259,17,58,4,0,
259,17,59,4,0,
259,17,89,1,48,
259,17,89,4,0,
259,17,92,4,0,
259,17,104,4,0,
259,17,117,1,18,
259,17,127,4,0,
259,17,156,4,0,
259,17,157,1,28,
259,17,157,4,0,
259,17,164,4,0,
259,17,182,1,32,
259,17,182,4,0,
259,17,189,1,1,5
259,17,189,1,9,
259,17,193,1,12,
259,17,207,4,0,
259,17,213,4,0,
259,17,214,4,0,
259,17,216,4,0,
259,17,218,4,0,
259,17,237,4,0,
259,17,240,4,0,
259,17,258,4,0,
259,17,263,4,0,
259,17,280,4,0,
259,17,283,1,52,
259,17,317,4,0,
259,17,330,1,38,
259,17,341,1,0,
259,17,341,1,1,1
259,17,374,4,0,
259,17,426,1,22,
259,17,482,4,0,
259,17,496,4,0,
259,17,497,4,0,
259,17,503,4,0,
259,17,518,3,0,
259,17,523,4,0,
259,17,526,4,0,
259,17,590,4,0,
259,18,33,1,1,2
259,18,36,1,42,
259,18,45,1,1,3
259,18,55,1,1,4
259,18,55,1,4,
259,18,57,4,0,
259,18,58,4,0,
259,18,59,4,0,
259,18,89,1,48,
259,18,89,4,0,
259,18,92,4,0,
259,18,104,4,0,
259,18,117,1,18,
259,18,127,4,0,
259,18,156,4,0,
259,18,157,1,28,
259,18,157,4,0,
259,18,164,4,0,
259,18,182,1,32,
259,18,182,4,0,
259,18,189,1,1,5
259,18,189,1,9,
259,18,193,1,12,
259,18,207,4,0,
259,18,213,4,0,
259,18,214,4,0,
259,18,216,4,0,
259,18,218,4,0,
259,18,237,4,0,
259,18,240,4,0,
259,18,258,4,0,
259,18,263,4,0,
259,18,280,4,0,
259,18,283,1,52,
259,18,317,4,0,
259,18,330,1,38,
259,18,341,1,0,
259,18,341,1,1,1
259,18,374,4,0,
259,18,426,1,22,
259,18,482,4,0,
259,18,496,4,0,
259,18,497,4,0,
259,18,503,4,0,
259,18,518,3,0,
259,18,523,4,0,
259,18,526,4,0,
259,18,590,4,0,
260,5,33,1,1,1
260,5,36,1,31,
260,5,45,1,1,2
260,5,46,4,0,
260,5,55,1,1,4
260,5,55,1,10,
260,5,57,4,0,
260,5,58,4,0,
260,5,59,4,0,
260,5,63,4,0,
260,5,70,4,0,
260,5,89,1,52,
260,5,89,4,0,
260,5,91,4,0,
260,5,92,4,0,
260,5,104,4,0,
260,5,117,1,15,
260,5,127,4,0,
260,5,156,4,0,
260,5,182,1,46,
260,5,182,4,0,
260,5,189,1,1,3
260,5,189,1,6,
260,5,193,1,20,
260,5,213,4,0,
260,5,216,4,0,
260,5,218,4,0,
260,5,231,4,0,
260,5,237,4,0,
260,5,240,4,0,
260,5,249,4,0,
260,5,258,4,0,
260,5,263,4,0,
260,5,264,4,0,
260,5,280,4,0,
260,5,283,1,61,
260,5,290,4,0,
260,5,291,4,0,
260,5,300,1,25,
260,5,317,4,0,
260,5,330,1,39,
260,5,341,1,16,
260,5,352,4,0,
260,6,5,3,0,
260,6,8,3,0,
260,6,25,3,0,
260,6,33,1,1,1
260,6,34,3,0,
260,6,36,1,31,
260,6,38,3,0,
260,6,45,1,1,2
260,6,46,4,0,
260,6,55,1,1,4
260,6,55,1,10,
260,6,57,4,0,
260,6,58,4,0,
260,6,59,4,0,
260,6,63,4,0,
260,6,68,3,0,
260,6,69,3,0,
260,6,70,4,0,
260,6,89,1,52,
260,6,89,4,0,
260,6,91,4,0,
260,6,92,4,0,
260,6,102,3,0,
260,6,104,4,0,
260,6,111,3,0,
260,6,117,1,15,
260,6,127,4,0,
260,6,156,4,0,
260,6,157,3,0,
260,6,164,3,0,
260,6,173,3,0,
260,6,182,1,46,
260,6,182,4,0,
260,6,189,1,1,3
260,6,189,1,6,
260,6,189,3,0,
260,6,193,1,20,
260,6,196,3,0,
260,6,203,3,0,
260,6,205,3,0,
260,6,207,3,0,
260,6,213,4,0,
260,6,214,3,0,
260,6,216,4,0,
260,6,218,4,0,
260,6,223,3,0,
260,6,231,4,0,
260,6,237,4,0,
260,6,240,4,0,
260,6,249,4,0,
260,6,258,4,0,
260,6,263,4,0,
260,6,264,4,0,
260,6,280,4,0,
260,6,283,1,61,
260,6,290,4,0,
260,6,291,4,0,
260,6,300,1,25,
260,6,317,4,0,
260,6,330,1,39,
260,6,341,1,16,
260,6,352,4,0,
260,7,5,3,0,
260,7,25,3,0,
260,7,33,1,1,1
260,7,34,3,0,
260,7,36,1,31,
260,7,38,3,0,
260,7,45,1,1,2
260,7,46,4,0,
260,7,55,1,1,4
260,7,55,1,10,
260,7,57,4,0,
260,7,58,4,0,
260,7,59,4,0,
260,7,63,4,0,
260,7,68,3,0,
260,7,69,3,0,
260,7,70,4,0,
260,7,89,1,52,
260,7,89,4,0,
260,7,91,4,0,
260,7,92,4,0,
260,7,102,3,0,
260,7,104,4,0,
260,7,117,1,15,
260,7,127,4,0,
260,7,156,4,0,
260,7,157,3,0,
260,7,164,3,0,
260,7,182,1,46,
260,7,182,4,0,
260,7,189,1,1,3
260,7,189,1,6,
260,7,193,1,20,
260,7,213,4,0,
260,7,216,4,0,
260,7,218,4,0,
260,7,231,4,0,
260,7,237,4,0,
260,7,240,4,0,
260,7,249,4,0,
260,7,258,4,0,
260,7,263,4,0,
260,7,264,4,0,
260,7,280,4,0,
260,7,283,1,61,
260,7,290,4,0,
260,7,291,4,0,
260,7,300,1,25,
260,7,317,4,0,
260,7,330,1,39,
260,7,341,1,16,
260,7,352,4,0,
260,8,33,1,1,1
260,8,36,1,31,
260,8,45,1,1,2
260,8,46,4,0,
260,8,55,1,1,4
260,8,55,1,10,
260,8,57,4,0,
260,8,58,4,0,
260,8,59,4,0,
260,8,63,4,0,
260,8,70,4,0,
260,8,89,1,52,
260,8,89,4,0,
260,8,91,4,0,
260,8,92,4,0,
260,8,104,4,0,
260,8,117,1,15,
260,8,127,4,0,
260,8,156,4,0,
260,8,157,4,0,
260,8,164,4,0,
260,8,182,1,46,
260,8,182,4,0,
260,8,189,1,1,3
260,8,189,1,6,
260,8,193,1,20,
260,8,203,4,0,
260,8,207,4,0,
260,8,213,4,0,
260,8,214,4,0,
260,8,216,4,0,
260,8,218,4,0,
260,8,231,4,0,
260,8,237,4,0,
260,8,240,4,0,
260,8,249,4,0,
260,8,258,4,0,
260,8,263,4,0,
260,8,264,4,0,
260,8,280,4,0,
260,8,283,1,61,
260,8,290,4,0,
260,8,308,3,0,
260,8,317,4,0,
260,8,330,1,39,
260,8,341,1,16,
260,8,352,4,0,
260,8,359,1,69,
260,8,363,4,0,
260,8,374,4,0,
260,8,411,4,0,
260,8,416,4,0,
260,8,419,4,0,
260,8,426,1,25,
260,8,431,4,0,
260,8,444,4,0,
260,8,445,4,0,
260,8,446,4,0,
260,9,8,3,0,
260,9,33,1,1,1
260,9,36,1,31,
260,9,45,1,1,2
260,9,46,4,0,
260,9,55,1,1,4
260,9,55,1,10,
260,9,57,4,0,
260,9,58,4,0,
260,9,59,4,0,
260,9,63,4,0,
260,9,70,4,0,
260,9,89,1,52,
260,9,89,4,0,
260,9,91,4,0,
260,9,92,4,0,
260,9,104,4,0,
260,9,117,1,15,
260,9,127,4,0,
260,9,156,4,0,
260,9,157,4,0,
260,9,164,4,0,
260,9,173,3,0,
260,9,182,1,46,
260,9,182,4,0,
260,9,189,1,1,3
260,9,189,1,6,
260,9,189,3,0,
260,9,193,1,20,
260,9,196,3,0,
260,9,200,3,0,
260,9,203,4,0,
260,9,205,3,0,
260,9,207,4,0,
260,9,213,4,0,
260,9,214,4,0,
260,9,216,4,0,
260,9,218,4,0,
260,9,231,4,0,
260,9,237,4,0,
260,9,240,4,0,
260,9,246,3,0,
260,9,249,4,0,
260,9,258,4,0,
260,9,263,4,0,
260,9,264,4,0,
260,9,276,3,0,
260,9,280,4,0,
260,9,283,1,61,
260,9,283,3,0,
260,9,290,4,0,
260,9,291,3,0,
260,9,308,3,0,
260,9,317,4,0,
260,9,330,1,39,
260,9,341,1,16,
260,9,352,4,0,
260,9,359,1,69,
260,9,363,4,0,
260,9,374,4,0,
260,9,401,3,0,
260,9,411,4,0,
260,9,414,3,0,
260,9,416,4,0,
260,9,419,4,0,
260,9,426,1,25,
260,9,431,4,0,
260,9,444,4,0,
260,9,445,4,0,
260,9,446,4,0,
260,10,8,3,0,
260,10,29,3,0,
260,10,33,1,1,1
260,10,36,1,31,
260,10,45,1,1,2
260,10,46,4,0,
260,10,55,1,1,4
260,10,55,1,10,
260,10,57,4,0,
260,10,58,4,0,
260,10,59,4,0,
260,10,63,4,0,
260,10,67,3,0,
260,10,70,4,0,
260,10,89,1,52,
260,10,89,4,0,
260,10,91,4,0,
260,10,92,4,0,
260,10,104,4,0,
260,10,117,1,15,
260,10,127,4,0,
260,10,156,4,0,
260,10,157,4,0,
260,10,164,4,0,
260,10,173,3,0,
260,10,182,1,46,
260,10,182,4,0,
260,10,189,1,1,3
260,10,189,1,6,
260,10,189,3,0,
260,10,193,1,20,
260,10,196,3,0,
260,10,200,3,0,
260,10,203,4,0,
260,10,205,3,0,
260,10,207,4,0,
260,10,213,4,0,
260,10,214,4,0,
260,10,216,4,0,
260,10,218,4,0,
260,10,231,4,0,
260,10,237,4,0,
260,10,240,4,0,
260,10,246,3,0,
260,10,249,4,0,
260,10,250,4,0,
260,10,258,4,0,
260,10,263,4,0,
260,10,264,4,0,
260,10,276,3,0,
260,10,280,4,0,
260,10,283,1,61,
260,10,283,3,0,
260,10,290,4,0,
260,10,291,3,0,
260,10,308,3,0,
260,10,317,4,0,
260,10,330,1,39,
260,10,341,1,16,
260,10,352,4,0,
260,10,359,1,69,
260,10,363,4,0,
260,10,374,4,0,
260,10,401,3,0,
260,10,411,4,0,
260,10,414,3,0,
260,10,416,4,0,
260,10,419,4,0,
260,10,426,1,25,
260,10,431,4,0,
260,10,444,4,0,
260,10,445,4,0,
260,10,446,4,0,
260,11,33,1,1,1
260,11,36,1,31,
260,11,45,1,1,2
260,11,46,4,0,
260,11,55,1,1,4
260,11,55,1,10,
260,11,57,4,0,
260,11,58,4,0,
260,11,59,4,0,
260,11,63,4,0,
260,11,70,4,0,
260,11,89,1,52,
260,11,89,4,0,
260,11,91,4,0,
260,11,92,4,0,
260,11,104,4,0,
260,11,117,1,15,
260,11,127,4,0,
260,11,156,4,0,
260,11,157,4,0,
260,11,164,4,0,
260,11,182,1,46,
260,11,182,4,0,
260,11,189,1,1,3
260,11,189,1,6,
260,11,193,1,20,
260,11,207,4,0,
260,11,213,4,0,
260,11,216,4,0,
260,11,218,4,0,
260,11,237,4,0,
260,11,240,4,0,
260,11,249,4,0,
260,11,258,4,0,
260,11,263,4,0,
260,11,280,4,0,
260,11,283,1,61,
260,11,291,4,0,
260,11,308,3,0,
260,11,317,4,0,
260,11,330,1,39,
260,11,341,1,16,
260,11,359,1,69,
260,11,374,4,0,
260,11,411,4,0,
260,11,416,4,0,
260,11,426,1,25,
260,11,444,4,0,
260,11,482,4,0,
260,11,496,4,0,
260,11,497,4,0,
260,11,503,4,0,
260,11,518,3,0,
260,11,523,4,0,
260,12,33,1,1,1
260,12,36,1,31,
260,12,45,1,1,2
260,12,46,4,0,
260,12,55,1,1,4
260,12,55,1,10,
260,12,57,4,0,
260,12,58,4,0,
260,12,59,4,0,
260,12,63,4,0,
260,12,70,4,0,
260,12,89,1,52,
260,12,89,4,0,
260,12,91,4,0,
260,12,92,4,0,
260,12,104,4,0,
260,12,117,1,15,
260,12,127,4,0,
260,12,156,4,0,
260,12,182,1,46,
260,12,182,4,0,
260,12,189,1,1,3
260,12,189,1,6,
260,12,193,1,20,
260,12,213,4,0,
260,12,216,4,0,
260,12,218,4,0,
260,12,231,4,0,
260,12,237,4,0,
260,12,240,4,0,
260,12,249,4,0,
260,12,258,4,0,
260,12,263,4,0,
260,12,264,4,0,
260,12,280,4,0,
260,12,283,1,61,
260,12,290,4,0,
260,12,291,4,0,
260,12,300,1,25,
260,12,317,4,0,
260,12,330,1,39,
260,12,341,1,16,
260,12,352,4,0,
260,13,33,1,1,1
260,13,34,3,0,
260,13,36,1,31,
260,13,38,3,0,
260,13,45,1,1,2
260,13,46,4,0,
260,13,55,1,1,4
260,13,55,1,10,
260,13,57,4,0,
260,13,58,4,0,
260,13,59,4,0,
260,13,63,4,0,
260,13,69,3,0,
260,13,70,4,0,
260,13,89,1,52,
260,13,89,4,0,
260,13,91,4,0,
260,13,92,4,0,
260,13,102,3,0,
260,13,104,4,0,
260,13,117,1,15,
260,13,127,4,0,
260,13,156,4,0,
260,13,164,3,0,
260,13,182,1,46,
260,13,182,4,0,
260,13,189,1,1,3
260,13,189,1,6,
260,13,193,1,20,
260,13,196,3,0,
260,13,207,3,0,
260,13,213,4,0,
260,13,216,4,0,
260,13,218,4,0,
260,13,231,4,0,
260,13,237,4,0,
260,13,240,4,0,
260,13,249,4,0,
260,13,258,4,0,
260,13,263,4,0,
260,13,264,4,0,
260,13,280,4,0,
260,13,283,1,61,
260,13,290,4,0,
260,13,291,4,0,
260,13,300,1,25,
260,13,317,4,0,
260,13,330,1,39,
260,13,341,1,16,
260,13,352,4,0,
260,14,8,3,0,
260,14,33,1,1,1
260,14,36,1,31,
260,14,45,1,1,2
260,14,46,4,0,
260,14,55,1,1,4
260,14,55,1,10,
260,14,57,4,0,
260,14,58,4,0,
260,14,59,4,0,
260,14,63,4,0,
260,14,67,3,0,
260,14,70,4,0,
260,14,89,1,52,
260,14,89,4,0,
260,14,91,4,0,
260,14,92,4,0,
260,14,104,4,0,
260,14,117,1,15,
260,14,127,4,0,
260,14,156,4,0,
260,14,157,4,0,
260,14,164,4,0,
260,14,173,3,0,
260,14,182,1,46,
260,14,182,4,0,
260,14,189,1,1,3
260,14,189,1,6,
260,14,193,1,20,
260,14,196,3,0,
260,14,200,3,0,
260,14,207,4,0,
260,14,213,4,0,
260,14,214,3,0,
260,14,216,4,0,
260,14,218,4,0,
260,14,231,3,0,
260,14,237,4,0,
260,14,240,4,0,
260,14,249,4,0,
260,14,258,4,0,
260,14,263,4,0,
260,14,276,3,0,
260,14,280,4,0,
260,14,283,1,61,
260,14,283,3,0,
260,14,291,4,0,
260,14,308,3,0,
260,14,317,4,0,
260,14,330,1,39,
260,14,341,1,16,
260,14,359,1,69,
260,14,374,4,0,
260,14,401,3,0,
260,14,411,4,0,
260,14,414,3,0,
260,14,416,4,0,
260,14,426,1,25,
260,14,444,4,0,
260,14,446,3,0,
260,14,482,4,0,
260,14,496,4,0,
260,14,497,4,0,
260,14,503,4,0,
260,14,518,3,0,
260,14,523,4,0,
260,15,33,1,1,2
260,15,36,1,31,
260,15,45,1,1,3
260,15,46,4,0,
260,15,55,1,1,5
260,15,55,1,10,
260,15,57,4,0,
260,15,58,4,0,
260,15,59,4,0,
260,15,63,4,0,
260,15,70,4,0,
260,15,89,1,52,
260,15,89,4,0,
260,15,91,4,0,
260,15,92,4,0,
260,15,104,4,0,
260,15,117,1,15,
260,15,127,4,0,
260,15,156,4,0,
260,15,157,4,0,
260,15,164,4,0,
260,15,182,1,46,
260,15,182,4,0,
260,15,189,1,1,4
260,15,189,1,6,
260,15,193,1,20,
260,15,207,4,0,
260,15,213,4,0,
260,15,214,4,0,
260,15,216,4,0,
260,15,218,4,0,
260,15,237,4,0,
260,15,240,4,0,
260,15,249,4,0,
260,15,258,4,0,
260,15,263,4,0,
260,15,280,4,0,
260,15,283,1,61,
260,15,308,3,0,
260,15,317,4,0,
260,15,330,1,39,
260,15,341,1,16,
260,15,359,1,1,1
260,15,359,1,69,
260,15,374,4,0,
260,15,411,4,0,
260,15,416,4,0,
260,15,426,1,25,
260,15,444,4,0,
260,15,482,4,0,
260,15,496,4,0,
260,15,497,4,0,
260,15,503,4,0,
260,15,518,3,0,
260,15,523,4,0,
260,15,590,4,0,
260,15,612,4,0,
260,16,8,3,0,
260,16,33,1,1,2
260,16,36,1,44,1
260,16,45,1,1,3
260,16,46,4,0,
260,16,55,1,1,4
260,16,55,1,4,1
260,16,57,4,0,
260,16,58,4,0,
260,16,59,4,0,
260,16,63,4,0,
260,16,67,3,0,
260,16,70,4,0,
260,16,89,1,51,1
260,16,89,4,0,
260,16,91,4,0,
260,16,92,4,0,
260,16,104,4,0,
260,16,117,1,18,1
260,16,127,4,0,
260,16,156,4,0,
260,16,157,1,28,1
260,16,157,4,0,
260,16,164,4,0,
260,16,173,3,0,
260,16,182,1,32,1
260,16,182,4,0,
260,16,189,1,1,5
260,16,189,1,9,1
260,16,193,1,12,1
260,16,196,3,0,
260,16,200,3,0,
260,16,207,4,0,
260,16,213,4,0,
260,16,214,4,0,
260,16,216,4,0,
260,16,218,4,0,
260,16,231,3,0,
260,16,237,4,0,
260,16,240,4,0,
260,16,249,4,0,
260,16,253,3,0,
260,16,258,4,0,
260,16,263,4,0,
260,16,264,3,0,
260,16,276,3,0,
260,16,280,4,0,
260,16,283,1,56,1
260,16,283,3,0,
260,16,290,4,0,
260,16,291,4,0,
260,16,308,3,0,
260,16,317,4,0,
260,16,330,1,39,1
260,16,341,1,16,1
260,16,352,3,0,
260,16,359,1,1,1
260,16,359,1,63,1
260,16,374,4,0,
260,16,401,3,0,
260,16,411,4,0,
260,16,414,3,0,
260,16,416,4,0,
260,16,426,1,22,1
260,16,444,4,0,
260,16,446,3,0,
260,16,482,4,0,
260,16,496,4,0,
260,16,497,4,0,
260,16,503,4,0,
260,16,518,3,0,
260,16,523,4,0,
260,16,590,4,0,
260,16,612,4,0,
260,17,33,1,1,3
260,17,36,1,44,
260,17,45,1,1,4
260,17,46,4,0,
260,17,55,1,1,5
260,17,55,1,4,
260,17,57,4,0,
260,17,58,4,0,
260,17,59,4,0,
260,17,63,4,0,
260,17,89,1,51,
260,17,89,4,0,
260,17,92,4,0,
260,17,104,4,0,
260,17,117,1,18,
260,17,127,4,0,
260,17,156,4,0,
260,17,157,1,28,
260,17,157,4,0,
260,17,164,4,0,
260,17,182,1,32,
260,17,182,4,0,
260,17,189,1,1,6
260,17,189,1,9,
260,17,193,1,12,
260,17,207,4,0,
260,17,213,4,0,
260,17,214,4,0,
260,17,216,4,0,
260,17,218,4,0,
260,17,237,4,0,
260,17,240,4,0,
260,17,258,4,0,
260,17,263,4,0,
260,17,280,4,0,
260,17,283,1,56,
260,17,308,3,0,
260,17,317,4,0,
260,17,330,1,39,
260,17,341,1,1,1
260,17,359,1,1,2
260,17,359,1,63,
260,17,374,4,0,
260,17,411,4,0,
260,17,416,4,0,
260,17,426,1,22,
260,17,444,4,0,
260,17,482,4,0,
260,17,496,4,0,
260,17,497,4,0,
260,17,503,4,0,
260,17,518,3,0,
260,17,523,4,0,
260,17,526,4,0,
260,17,590,4,0,
260,18,33,1,1,3
260,18,36,1,44,
260,18,45,1,1,4
260,18,46,4,0,
260,18,55,1,1,5
260,18,55,1,4,
260,18,57,4,0,
260,18,58,4,0,
260,18,59,4,0,
260,18,63,4,0,
260,18,89,1,51,
260,18,89,4,0,
260,18,92,4,0,
260,18,104,4,0,
260,18,117,1,18,
260,18,127,4,0,
260,18,156,4,0,
260,18,157,1,28,
260,18,157,4,0,
260,18,164,4,0,
260,18,182,1,32,
260,18,182,4,0,
260,18,189,1,1,6
260,18,189,1,9,
260,18,193,1,12,
260,18,207,4,0,
260,18,213,4,0,
260,18,214,4,0,
260,18,216,4,0,
260,18,218,4,0,
260,18,237,4,0,
260,18,240,4,0,
260,18,258,4,0,
260,18,263,4,0,
260,18,280,4,0,
260,18,283,1,56,
260,18,308,3,0,
260,18,317,4,0,
260,18,330,1,39,
260,18,341,1,1,1
260,18,359,1,1,2
260,18,359,1,63,
260,18,374,4,0,
260,18,411,4,0,
260,18,416,4,0,
260,18,426,1,22,
260,18,444,4,0,
260,18,482,4,0,
260,18,496,4,0,
260,18,497,4,0,
260,18,503,4,0,
260,18,518,3,0,
260,18,523,4,0,
260,18,526,4,0,
260,18,590,4,0,
261,5,28,1,9,
261,5,33,1,1,
261,5,36,1,33,
261,5,43,2,0,
261,5,44,1,13,
261,5,46,1,21,
261,5,46,4,0,
261,5,91,4,0,
261,5,92,4,0,
261,5,104,4,0,
261,5,156,4,0,
261,5,168,1,45,
261,5,168,4,0,
261,5,182,4,0,
261,5,184,1,29,
261,5,207,1,25,
261,5,213,4,0,
261,5,216,4,0,
261,5,218,4,0,
261,5,231,4,0,
261,5,237,4,0,
261,5,240,4,0,
261,5,241,4,0,
261,5,242,1,41,
261,5,247,4,0,
261,5,249,4,0,
261,5,259,4,0,
261,5,263,4,0,
261,5,269,1,37,
261,5,269,4,0,
261,5,281,2,0,
261,5,289,4,0,
261,5,290,4,0,
261,5,305,2,0,
261,5,310,2,0,
261,5,316,1,17,
261,5,336,1,5,
261,5,343,2,0,
261,6,28,1,9,
261,6,33,1,1,
261,6,34,3,0,
261,6,36,1,33,
261,6,38,3,0,
261,6,43,2,0,
261,6,44,1,13,
261,6,46,1,21,
261,6,46,4,0,
261,6,68,3,0,
261,6,91,4,0,
261,6,92,4,0,
261,6,102,3,0,
261,6,104,4,0,
261,6,156,4,0,
261,6,164,3,0,
261,6,168,1,45,
261,6,168,4,0,
261,6,173,3,0,
261,6,182,4,0,
261,6,184,1,29,
261,6,189,3,0,
261,6,203,3,0,
261,6,207,1,25,
261,6,207,3,0,
261,6,213,4,0,
261,6,214,3,0,
261,6,216,4,0,
261,6,218,4,0,
261,6,231,4,0,
261,6,237,4,0,
261,6,240,4,0,
261,6,241,4,0,
261,6,242,1,41,
261,6,244,3,0,
261,6,247,4,0,
261,6,249,4,0,
261,6,259,4,0,
261,6,263,4,0,
261,6,269,1,37,
261,6,269,4,0,
261,6,281,2,0,
261,6,289,4,0,
261,6,290,4,0,
261,6,305,2,0,
261,6,310,2,0,
261,6,316,1,17,
261,6,336,1,5,
261,6,343,2,0,
261,7,28,1,9,
261,7,33,1,1,
261,7,34,3,0,
261,7,36,1,33,
261,7,38,3,0,
261,7,43,2,0,
261,7,44,1,13,
261,7,46,1,21,
261,7,46,4,0,
261,7,68,3,0,
261,7,91,4,0,
261,7,92,4,0,
261,7,102,3,0,
261,7,104,4,0,
261,7,156,4,0,
261,7,164,3,0,
261,7,168,1,45,
261,7,168,4,0,
261,7,182,4,0,
261,7,184,1,29,
261,7,207,1,25,
261,7,213,4,0,
261,7,216,4,0,
261,7,218,4,0,
261,7,231,4,0,
261,7,237,4,0,
261,7,240,4,0,
261,7,241,4,0,
261,7,242,1,41,
261,7,247,4,0,
261,7,249,4,0,
261,7,259,4,0,
261,7,263,4,0,
261,7,269,1,37,
261,7,269,4,0,
261,7,281,2,0,
261,7,289,4,0,
261,7,290,4,0,
261,7,305,2,0,
261,7,310,2,0,
261,7,316,1,17,
261,7,336,1,5,
261,7,343,2,0,
261,8,28,1,9,
261,8,33,1,1,
261,8,36,1,45,
261,8,43,2,0,
261,8,44,1,13,
261,8,46,1,21,
261,8,46,4,0,
261,8,91,4,0,
261,8,92,4,0,
261,8,104,4,0,
261,8,156,4,0,
261,8,164,4,0,
261,8,168,4,0,
261,8,182,4,0,
261,8,184,1,33,
261,8,203,4,0,
261,8,207,1,25,
261,8,207,4,0,
261,8,213,4,0,
261,8,214,4,0,
261,8,216,4,0,
261,8,218,4,0,
261,8,231,4,0,
261,8,237,4,0,
261,8,240,4,0,
261,8,241,4,0,
261,8,242,1,53,
261,8,247,4,0,
261,8,249,4,0,
261,8,259,4,0,
261,8,263,4,0,
261,8,269,1,37,
261,8,269,4,0,
261,8,281,2,0,
261,8,289,4,0,
261,8,290,4,0,
261,8,305,2,0,
261,8,310,2,0,
261,8,316,1,17,
261,8,336,1,5,
261,8,343,2,0,
261,8,363,4,0,
261,8,371,4,0,
261,8,372,1,29,
261,8,373,1,41,
261,8,373,4,0,
261,8,382,2,0,
261,8,389,1,49,
261,8,389,2,0,
261,8,399,4,0,
261,8,422,2,0,
261,8,423,2,0,
261,8,424,2,0,
261,8,445,4,0,
261,9,28,1,9,
261,9,33,1,1,
261,9,36,1,45,
261,9,43,2,0,
261,9,44,1,13,
261,9,46,1,21,
261,9,46,4,0,
261,9,91,4,0,
261,9,92,4,0,
261,9,104,4,0,
261,9,156,4,0,
261,9,164,4,0,
261,9,168,4,0,
261,9,173,3,0,
261,9,180,3,0,
261,9,182,4,0,
261,9,184,1,33,
261,9,189,3,0,
261,9,203,4,0,
261,9,207,1,25,
261,9,207,4,0,
261,9,213,4,0,
261,9,214,4,0,
261,9,216,4,0,
261,9,218,4,0,
261,9,231,4,0,
261,9,237,4,0,
261,9,240,4,0,
261,9,241,4,0,
261,9,242,1,53,
261,9,247,4,0,
261,9,249,4,0,
261,9,253,3,0,
261,9,259,4,0,
261,9,263,4,0,
261,9,269,1,37,
261,9,269,4,0,
261,9,281,2,0,
261,9,289,4,0,
261,9,290,4,0,
261,9,305,2,0,
261,9,310,2,0,
261,9,316,1,17,
261,9,336,1,5,
261,9,343,2,0,
261,9,363,4,0,
261,9,371,4,0,
261,9,372,1,29,
261,9,373,1,41,
261,9,373,4,0,
261,9,382,2,0,
261,9,389,1,49,
261,9,389,2,0,
261,9,389,3,0,
261,9,399,4,0,
261,9,422,2,0,
261,9,423,2,0,
261,9,424,2,0,
261,9,445,4,0,
261,10,28,1,9,
261,10,29,3,0,
261,10,33,1,1,
261,10,36,1,45,
261,10,43,2,0,
261,10,44,1,13,
261,10,46,1,21,
261,10,46,4,0,
261,10,91,4,0,
261,10,92,4,0,
261,10,104,4,0,
261,10,156,4,0,
261,10,162,3,0,
261,10,164,4,0,
261,10,168,4,0,
261,10,173,3,0,
261,10,180,3,0,
261,10,182,4,0,
261,10,184,1,33,
261,10,189,3,0,
261,10,203,4,0,
261,10,207,1,25,
261,10,207,4,0,
261,10,213,4,0,
261,10,214,4,0,
261,10,216,4,0,
261,10,218,4,0,
261,10,231,4,0,
261,10,237,4,0,
261,10,240,4,0,
261,10,241,4,0,
261,10,242,1,53,
261,10,247,4,0,
261,10,249,4,0,
261,10,253,3,0,
261,10,259,4,0,
261,10,263,4,0,
261,10,269,1,37,
261,10,269,4,0,
261,10,281,2,0,
261,10,289,4,0,
261,10,290,4,0,
261,10,305,2,0,
261,10,310,2,0,
261,10,316,1,17,
261,10,336,1,5,
261,10,343,2,0,
261,10,363,4,0,
261,10,371,4,0,
261,10,372,1,29,
261,10,373,1,41,
261,10,373,4,0,
261,10,382,2,0,
261,10,389,1,49,
261,10,389,2,0,
261,10,389,3,0,
261,10,399,4,0,
261,10,422,2,0,
261,10,423,2,0,
261,10,424,2,0,
261,10,445,4,0,
261,11,28,1,9,
261,11,33,1,1,
261,11,36,1,45,
261,11,43,2,0,
261,11,44,1,13,
261,11,46,1,21,
261,11,46,4,0,
261,11,91,4,0,
261,11,92,4,0,
261,11,104,4,0,
261,11,156,4,0,
261,11,164,4,0,
261,11,168,4,0,
261,11,182,4,0,
261,11,184,1,33,
261,11,207,1,25,
261,11,207,4,0,
261,11,213,4,0,
261,11,214,2,0,
261,11,216,4,0,
261,11,218,4,0,
261,11,237,4,0,
261,11,240,4,0,
261,11,241,4,0,
261,11,242,1,53,
261,11,247,4,0,
261,11,249,4,0,
261,11,259,4,0,
261,11,263,4,0,
261,11,269,1,37,
261,11,269,4,0,
261,11,281,2,0,
261,11,289,2,0,
261,11,305,2,0,
261,11,310,2,0,
261,11,316,1,17,
261,11,336,1,5,
261,11,343,2,0,
261,11,371,4,0,
261,11,372,1,29,
261,11,373,1,41,
261,11,373,4,0,
261,11,382,2,0,
261,11,389,1,49,
261,11,389,2,0,
261,11,422,2,0,
261,11,423,2,0,
261,11,424,2,0,
261,11,496,4,0,
261,11,510,4,0,
261,11,514,4,0,
261,11,555,4,0,
261,12,28,1,9,
261,12,33,1,1,
261,12,36,1,33,
261,12,44,1,13,
261,12,46,1,21,
261,12,46,4,0,
261,12,91,4,0,
261,12,92,4,0,
261,12,104,4,0,
261,12,156,4,0,
261,12,168,1,45,
261,12,168,4,0,
261,12,182,4,0,
261,12,184,1,29,
261,12,207,1,25,
261,12,213,4,0,
261,12,216,4,0,
261,12,218,4,0,
261,12,231,4,0,
261,12,237,4,0,
261,12,240,4,0,
261,12,241,4,0,
261,12,242,1,41,
261,12,247,4,0,
261,12,249,4,0,
261,12,259,4,0,
261,12,263,4,0,
261,12,269,1,37,
261,12,269,4,0,
261,12,289,4,0,
261,12,290,4,0,
261,12,316,1,17,
261,12,336,1,5,
261,13,28,1,9,
261,13,33,1,1,
261,13,34,3,0,
261,13,36,1,33,
261,13,38,3,0,
261,13,44,1,13,
261,13,46,1,21,
261,13,46,4,0,
261,13,91,4,0,
261,13,92,4,0,
261,13,102,3,0,
261,13,104,4,0,
261,13,156,4,0,
261,13,164,3,0,
261,13,168,1,45,
261,13,168,4,0,
261,13,182,4,0,
261,13,184,1,29,
261,13,207,1,25,
261,13,207,3,0,
261,13,213,4,0,
261,13,216,4,0,
261,13,218,4,0,
261,13,231,4,0,
261,13,237,4,0,
261,13,240,4,0,
261,13,241,4,0,
261,13,242,1,41,
261,13,247,4,0,
261,13,249,4,0,
261,13,259,4,0,
261,13,263,4,0,
261,13,269,1,37,
261,13,269,4,0,
261,13,289,4,0,
261,13,290,4,0,
261,13,316,1,17,
261,13,336,1,5,
261,14,28,1,9,
261,14,33,1,1,
261,14,36,1,45,
261,14,43,2,0,
261,14,44,1,13,
261,14,46,1,21,
261,14,46,4,0,
261,14,91,4,0,
261,14,92,4,0,
261,14,104,4,0,
261,14,156,4,0,
261,14,162,3,0,
261,14,164,4,0,
261,14,168,4,0,
261,14,173,3,0,
261,14,180,3,0,
261,14,182,4,0,
261,14,184,1,33,
261,14,207,1,25,
261,14,207,4,0,
261,14,213,4,0,
261,14,214,2,0,
261,14,214,3,0,
261,14,216,4,0,
261,14,218,4,0,
261,14,231,3,0,
261,14,237,4,0,
261,14,240,4,0,
261,14,241,4,0,
261,14,242,1,53,
261,14,247,4,0,
261,14,249,4,0,
261,14,253,3,0,
261,14,259,4,0,
261,14,263,4,0,
261,14,269,1,37,
261,14,269,4,0,
261,14,281,2,0,
261,14,289,2,0,
261,14,289,3,0,
261,14,304,3,0,
261,14,305,2,0,
261,14,310,2,0,
261,14,316,1,17,
261,14,336,1,5,
261,14,343,2,0,
261,14,343,3,0,
261,14,371,4,0,
261,14,372,1,29,
261,14,373,1,41,
261,14,373,4,0,
261,14,382,2,0,
261,14,389,1,49,
261,14,389,2,0,
261,14,399,3,0,
261,14,422,2,0,
261,14,423,2,0,
261,14,424,2,0,
261,14,492,3,0,
261,14,496,4,0,
261,14,510,4,0,
261,14,514,4,0,
261,14,555,4,0,
261,15,28,1,9,
261,15,33,1,1,
261,15,36,1,45,
261,15,43,2,0,
261,15,44,1,13,
261,15,46,1,21,
261,15,46,4,0,
261,15,91,4,0,
261,15,92,4,0,
261,15,104,4,0,
261,15,156,4,0,
261,15,164,4,0,
261,15,168,4,0,
261,15,182,4,0,
261,15,184,1,33,
261,15,207,1,25,
261,15,207,4,0,
261,15,213,4,0,
261,15,214,2,0,
261,15,214,4,0,
261,15,216,4,0,
261,15,218,4,0,
261,15,237,4,0,
261,15,240,4,0,
261,15,241,4,0,
261,15,242,1,53,
261,15,247,4,0,
261,15,249,4,0,
261,15,259,4,0,
261,15,263,4,0,
261,15,269,1,37,
261,15,269,4,0,
261,15,281,2,0,
261,15,289,2,0,
261,15,305,2,0,
261,15,310,2,0,
261,15,316,1,17,
261,15,336,1,5,
261,15,343,2,0,
261,15,371,4,0,
261,15,372,1,29,
261,15,373,1,41,
261,15,373,4,0,
261,15,382,2,0,
261,15,389,1,49,
261,15,389,2,0,
261,15,399,4,0,
261,15,422,2,0,
261,15,423,2,0,
261,15,424,2,0,
261,15,496,4,0,
261,15,510,4,0,
261,15,514,4,0,
261,15,555,4,0,
261,15,583,2,0,
261,15,590,4,0,
261,16,28,1,7,1
261,16,33,1,1,1
261,16,36,1,34,1
261,16,43,2,0,
261,16,44,1,10,1
261,16,46,1,16,1
261,16,46,4,0,
261,16,91,4,0,
261,16,92,4,0,
261,16,104,4,0,
261,16,156,4,0,
261,16,162,3,0,
261,16,164,4,0,
261,16,168,4,0,
261,16,173,3,0,
261,16,180,3,0,
261,16,182,4,0,
261,16,184,1,25,1
261,16,207,1,19,1
261,16,207,4,0,
261,16,213,4,0,
261,16,214,2,0,
261,16,214,4,0,
261,16,216,4,0,
261,16,218,4,0,
261,16,231,3,0,
261,16,237,4,0,
261,16,240,4,0,
261,16,241,4,0,
261,16,242,1,37,1
261,16,247,4,0,
261,16,249,4,0,
261,16,253,3,0,
261,16,259,4,0,
261,16,263,4,0,
261,16,269,1,31,1
261,16,269,4,0,
261,16,281,2,0,
261,16,289,2,0,
261,16,289,3,0,
261,16,290,4,0,
261,16,304,3,0,
261,16,305,2,0,
261,16,310,2,0,
261,16,316,1,13,1
261,16,336,1,4,1
261,16,343,2,0,
261,16,343,3,0,
261,16,371,4,0,
261,16,372,1,22,1
261,16,373,1,28,1
261,16,373,4,0,
261,16,382,2,0,
261,16,389,1,40,1
261,16,389,2,0,
261,16,399,4,0,
261,16,422,2,0,
261,16,423,2,0,
261,16,424,2,0,
261,16,492,3,0,
261,16,496,4,0,
261,16,510,4,0,
261,16,514,4,0,
261,16,555,4,0,
261,16,583,2,0,
261,16,590,4,0,
261,17,28,1,7,
261,17,33,1,1,
261,17,36,1,40,
261,17,43,2,0,
261,17,44,1,10,
261,17,46,1,16,
261,17,46,4,0,
261,17,92,4,0,
261,17,104,4,0,
261,17,156,4,0,
261,17,164,4,0,
261,17,168,4,0,
261,17,182,4,0,
261,17,184,1,25,
261,17,207,1,19,
261,17,207,4,0,
261,17,213,4,0,
261,17,214,2,0,
261,17,214,4,0,
261,17,216,4,0,
261,17,218,4,0,
261,17,237,4,0,
261,17,240,4,0,
261,17,241,4,0,
261,17,242,1,34,
261,17,247,4,0,
261,17,259,4,0,
261,17,263,4,0,
261,17,269,1,31,
261,17,269,4,0,
261,17,281,1,37,
261,17,281,2,0,
261,17,289,2,0,
261,17,305,2,0,
261,17,310,2,0,
261,17,316,1,13,
261,17,336,1,4,
261,17,343,2,0,
261,17,371,4,0,
261,17,372,1,22,
261,17,373,1,28,
261,17,373,4,0,
261,17,382,2,0,
261,17,389,1,43,
261,17,389,2,0,
261,17,399,4,0,
261,17,422,2,0,
261,17,423,2,0,
261,17,424,2,0,
261,17,496,4,0,
261,17,555,4,0,
261,17,583,1,46,
261,17,583,2,0,
261,17,590,4,0,
261,18,28,1,7,
261,18,33,1,1,
261,18,36,1,40,
261,18,43,2,0,
261,18,44,1,10,
261,18,46,1,16,
261,18,46,4,0,
261,18,92,4,0,
261,18,104,4,0,
261,18,156,4,0,
261,18,164,4,0,
261,18,168,4,0,
261,18,182,4,0,
261,18,184,1,25,
261,18,207,1,19,
261,18,207,4,0,
261,18,213,4,0,
261,18,214,2,0,
261,18,214,4,0,
261,18,216,4,0,
261,18,218,4,0,
261,18,237,4,0,
261,18,240,4,0,
261,18,241,4,0,
261,18,242,1,34,
261,18,247,4,0,
261,18,259,4,0,
261,18,263,4,0,
261,18,269,1,31,
261,18,269,4,0,
261,18,281,1,37,
261,18,281,2,0,
261,18,289,2,0,
261,18,305,2,0,
261,18,310,2,0,
261,18,316,1,13,
261,18,336,1,4,
261,18,343,2,0,
261,18,371,4,0,
261,18,372,1,22,
261,18,373,1,28,
261,18,373,4,0,
261,18,382,2,0,
261,18,389,1,43,
261,18,389,2,0,
261,18,399,4,0,
261,18,422,2,0,
261,18,423,2,0,
261,18,424,2,0,
261,18,496,4,0,
261,18,555,4,0,
261,18,583,1,46,
261,18,583,2,0,
261,18,590,4,0,
262,5,28,1,1,3
262,5,28,1,9,
262,5,33,1,1,1
262,5,36,1,37,
262,5,44,1,1,4
262,5,44,1,13,
262,5,46,1,22,
262,5,46,4,0,
262,5,63,4,0,
262,5,70,4,0,
262,5,91,4,0,
262,5,92,4,0,
262,5,104,4,0,
262,5,156,4,0,
262,5,168,1,52,
262,5,168,4,0,
262,5,182,4,0,
262,5,184,1,32,
262,5,207,1,27,
262,5,213,4,0,
262,5,216,4,0,
262,5,218,4,0,
262,5,231,4,0,
262,5,237,4,0,
262,5,240,4,0,
262,5,241,4,0,
262,5,242,1,47,
262,5,247,4,0,
262,5,249,4,0,
262,5,259,4,0,
262,5,263,4,0,
262,5,269,1,42,
262,5,269,4,0,
262,5,289,4,0,
262,5,290,4,0,
262,5,316,1,17,
262,5,336,1,1,2
262,5,336,1,5,
262,6,28,1,1,3
262,6,28,1,9,
262,6,33,1,1,1
262,6,34,3,0,
262,6,36,1,37,
262,6,38,3,0,
262,6,44,1,1,4
262,6,44,1,13,
262,6,46,1,22,
262,6,46,4,0,
262,6,63,4,0,
262,6,68,3,0,
262,6,70,4,0,
262,6,91,4,0,
262,6,92,4,0,
262,6,102,3,0,
262,6,104,4,0,
262,6,156,4,0,
262,6,164,3,0,
262,6,168,1,52,
262,6,168,4,0,
262,6,173,3,0,
262,6,182,4,0,
262,6,184,1,32,
262,6,189,3,0,
262,6,203,3,0,
262,6,207,1,27,
262,6,207,3,0,
262,6,213,4,0,
262,6,214,3,0,
262,6,216,4,0,
262,6,218,4,0,
262,6,231,4,0,
262,6,237,4,0,
262,6,240,4,0,
262,6,241,4,0,
262,6,242,1,47,
262,6,244,3,0,
262,6,247,4,0,
262,6,249,4,0,
262,6,259,4,0,
262,6,263,4,0,
262,6,269,1,42,
262,6,269,4,0,
262,6,289,4,0,
262,6,290,4,0,
262,6,316,1,17,
262,6,336,1,1,2
262,6,336,1,5,
262,7,28,1,1,3
262,7,28,1,9,
262,7,33,1,1,1
262,7,34,3,0,
262,7,36,1,37,
262,7,38,3,0,
262,7,44,1,1,4
262,7,44,1,13,
262,7,46,1,22,
262,7,46,4,0,
262,7,63,4,0,
262,7,68,3,0,
262,7,70,4,0,
262,7,91,4,0,
262,7,92,4,0,
262,7,102,3,0,
262,7,104,4,0,
262,7,156,4,0,
262,7,164,3,0,
262,7,168,1,52,
262,7,168,4,0,
262,7,182,4,0,
262,7,184,1,32,
262,7,207,1,27,
262,7,213,4,0,
262,7,216,4,0,
262,7,218,4,0,
262,7,231,4,0,
262,7,237,4,0,
262,7,240,4,0,
262,7,241,4,0,
262,7,242,1,47,
262,7,247,4,0,
262,7,249,4,0,
262,7,259,4,0,
262,7,263,4,0,
262,7,269,1,42,
262,7,269,4,0,
262,7,289,4,0,
262,7,290,4,0,
262,7,316,1,17,
262,7,336,1,1,2
262,7,336,1,5,
262,8,28,1,1,3
262,8,28,1,9,
262,8,33,1,1,1
262,8,36,1,52,
262,8,44,1,1,4
262,8,44,1,13,
262,8,46,1,22,
262,8,46,4,0,
262,8,63,4,0,
262,8,70,4,0,
262,8,91,4,0,
262,8,92,4,0,
262,8,104,4,0,
262,8,156,4,0,
262,8,164,4,0,
262,8,168,1,57,
262,8,168,4,0,
262,8,182,4,0,
262,8,184,1,37,
262,8,203,4,0,
262,8,207,1,27,
262,8,207,4,0,
262,8,213,4,0,
262,8,214,4,0,
262,8,216,4,0,
262,8,218,4,0,
262,8,231,4,0,
262,8,237,4,0,
262,8,240,4,0,
262,8,241,4,0,
262,8,247,4,0,
262,8,249,4,0,
262,8,259,4,0,
262,8,263,4,0,
262,8,269,1,42,
262,8,269,4,0,
262,8,289,4,0,
262,8,290,4,0,
262,8,316,1,17,
262,8,336,1,1,2
262,8,336,1,5,
262,8,363,4,0,
262,8,371,4,0,
262,8,372,1,32,
262,8,373,1,47,
262,8,373,4,0,
262,8,389,1,62,
262,8,399,4,0,
262,8,416,4,0,
262,8,445,4,0,
262,9,28,1,1,3
262,9,28,1,9,
262,9,33,1,1,1
262,9,36,1,52,
262,9,44,1,1,4
262,9,44,1,13,
262,9,46,1,22,
262,9,46,4,0,
262,9,63,4,0,
262,9,70,4,0,
262,9,91,4,0,
262,9,92,4,0,
262,9,104,4,0,
262,9,156,4,0,
262,9,164,4,0,
262,9,168,1,57,
262,9,168,4,0,
262,9,173,3,0,
262,9,180,3,0,
262,9,182,4,0,
262,9,184,1,37,
262,9,189,3,0,
262,9,203,4,0,
262,9,207,1,27,
262,9,207,4,0,
262,9,213,4,0,
262,9,214,4,0,
262,9,216,4,0,
262,9,218,4,0,
262,9,231,4,0,
262,9,237,4,0,
262,9,240,4,0,
262,9,241,4,0,
262,9,247,4,0,
262,9,249,4,0,
262,9,253,3,0,
262,9,259,4,0,
262,9,263,4,0,
262,9,269,1,42,
262,9,269,4,0,
262,9,289,4,0,
262,9,290,4,0,
262,9,316,1,17,
262,9,336,1,1,2
262,9,336,1,5,
262,9,363,4,0,
262,9,371,4,0,
262,9,372,1,32,
262,9,373,1,47,
262,9,373,4,0,
262,9,389,1,62,
262,9,389,3,0,
262,9,399,4,0,
262,9,416,4,0,
262,9,445,4,0,
262,10,28,1,1,3
262,10,28,1,9,
262,10,29,3,0,
262,10,33,1,1,1
262,10,36,1,52,
262,10,44,1,1,4
262,10,44,1,13,
262,10,46,1,22,
262,10,46,4,0,
262,10,63,4,0,
262,10,70,4,0,
262,10,91,4,0,
262,10,92,4,0,
262,10,104,4,0,
262,10,156,4,0,
262,10,162,3,0,
262,10,164,4,0,
262,10,168,1,57,
262,10,168,4,0,
262,10,173,3,0,
262,10,180,3,0,
262,10,182,4,0,
262,10,184,1,37,
262,10,189,3,0,
262,10,203,4,0,
262,10,207,1,27,
262,10,207,4,0,
262,10,213,4,0,
262,10,214,4,0,
262,10,216,4,0,
262,10,218,4,0,
262,10,231,4,0,
262,10,237,4,0,
262,10,240,4,0,
262,10,241,4,0,
262,10,247,4,0,
262,10,249,4,0,
262,10,253,3,0,
262,10,259,4,0,
262,10,263,4,0,
262,10,269,1,42,
262,10,269,4,0,
262,10,289,4,0,
262,10,290,4,0,
262,10,316,1,17,
262,10,336,1,1,2
262,10,336,1,5,
262,10,363,4,0,
262,10,371,4,0,
262,10,372,1,32,
262,10,373,1,47,
262,10,373,4,0,
262,10,389,1,62,
262,10,389,3,0,
262,10,399,4,0,
262,10,416,4,0,
262,10,445,4,0,
262,11,28,1,1,3
262,11,28,1,9,
262,11,33,1,1,1
262,11,36,1,52,
262,11,44,1,1,4
262,11,44,1,13,
262,11,46,1,22,
262,11,46,4,0,
262,11,63,4,0,
262,11,70,4,0,
262,11,91,4,0,
262,11,92,4,0,
262,11,104,4,0,
262,11,156,4,0,
262,11,164,4,0,
262,11,168,1,57,
262,11,168,4,0,
262,11,182,4,0,
262,11,184,1,37,
262,11,207,1,27,
262,11,207,4,0,
262,11,213,4,0,
262,11,216,4,0,
262,11,218,4,0,
262,11,237,4,0,
262,11,240,4,0,
262,11,241,4,0,
262,11,247,4,0,
262,11,249,4,0,
262,11,259,4,0,
262,11,263,4,0,
262,11,269,1,42,
262,11,269,4,0,
262,11,316,1,17,
262,11,336,1,1,2
262,11,336,1,5,
262,11,371,4,0,
262,11,372,1,32,
262,11,373,1,47,
262,11,373,4,0,
262,11,389,1,62,
262,11,416,4,0,
262,11,496,4,0,
262,11,510,4,0,
262,11,514,4,0,
262,11,555,4,0,
262,12,28,1,1,3
262,12,28,1,9,
262,12,33,1,1,1
262,12,36,1,37,
262,12,44,1,1,4
262,12,44,1,13,
262,12,46,1,22,
262,12,46,4,0,
262,12,63,4,0,
262,12,70,4,0,
262,12,91,4,0,
262,12,92,4,0,
262,12,104,4,0,
262,12,156,4,0,
262,12,168,1,52,
262,12,168,4,0,
262,12,182,4,0,
262,12,184,1,32,
262,12,207,1,27,
262,12,213,4,0,
262,12,216,4,0,
262,12,218,4,0,
262,12,231,4,0,
262,12,237,4,0,
262,12,240,4,0,
262,12,241,4,0,
262,12,242,1,47,
262,12,247,4,0,
262,12,249,4,0,
262,12,259,4,0,
262,12,263,4,0,
262,12,269,1,42,
262,12,269,4,0,
262,12,289,4,0,
262,12,290,4,0,
262,12,316,1,17,
262,12,336,1,1,2
262,12,336,1,5,
262,13,28,1,1,3
262,13,28,1,9,
262,13,33,1,1,1
262,13,34,3,0,
262,13,36,1,37,
262,13,38,3,0,
262,13,44,1,1,4
262,13,44,1,13,
262,13,46,1,22,
262,13,46,4,0,
262,13,63,4,0,
262,13,70,4,0,
262,13,91,4,0,
262,13,92,4,0,
262,13,102,3,0,
262,13,104,4,0,
262,13,156,4,0,
262,13,164,3,0,
262,13,168,1,52,
262,13,168,4,0,
262,13,182,4,0,
262,13,184,1,32,
262,13,207,1,27,
262,13,207,3,0,
262,13,213,4,0,
262,13,216,4,0,
262,13,218,4,0,
262,13,231,4,0,
262,13,237,4,0,
262,13,240,4,0,
262,13,241,4,0,
262,13,242,1,47,
262,13,247,4,0,
262,13,249,4,0,
262,13,259,4,0,
262,13,263,4,0,
262,13,269,1,42,
262,13,269,4,0,
262,13,289,4,0,
262,13,290,4,0,
262,13,316,1,17,
262,13,336,1,1,2
262,13,336,1,5,
262,14,28,1,1,3
262,14,28,1,9,
262,14,33,1,1,1
262,14,36,1,52,
262,14,44,1,1,4
262,14,44,1,13,
262,14,46,1,22,
262,14,46,4,0,
262,14,63,4,0,
262,14,70,4,0,
262,14,91,4,0,
262,14,92,4,0,
262,14,104,4,0,
262,14,156,4,0,
262,14,162,3,0,
262,14,164,4,0,
262,14,168,1,57,
262,14,168,4,0,
262,14,173,3,0,
262,14,180,3,0,
262,14,182,4,0,
262,14,184,1,37,
262,14,207,1,27,
262,14,207,4,0,
262,14,213,4,0,
262,14,214,3,0,
262,14,216,4,0,
262,14,218,4,0,
262,14,231,3,0,
262,14,237,4,0,
262,14,240,4,0,
262,14,241,4,0,
262,14,247,4,0,
262,14,249,4,0,
262,14,253,3,0,
262,14,259,4,0,
262,14,263,4,0,
262,14,269,1,42,
262,14,269,4,0,
262,14,289,3,0,
262,14,304,3,0,
262,14,316,1,17,
262,14,336,1,1,2
262,14,336,1,5,
262,14,343,3,0,
262,14,371,4,0,
262,14,372,1,32,
262,14,373,1,47,
262,14,373,4,0,
262,14,389,1,62,
262,14,399,3,0,
262,14,416,4,0,
262,14,492,3,0,
262,14,496,4,0,
262,14,510,4,0,
262,14,514,4,0,
262,14,555,4,0,
262,15,28,1,1,4
262,15,28,1,9,
262,15,33,1,1,2
262,15,36,1,52,
262,15,44,1,1,5
262,15,44,1,13,
262,15,46,1,22,
262,15,46,4,0,
262,15,63,4,0,
262,15,70,4,0,
262,15,91,4,0,
262,15,92,4,0,
262,15,104,4,0,
262,15,156,4,0,
262,15,164,4,0,
262,15,168,1,57,
262,15,168,4,0,
262,15,182,4,0,
262,15,184,1,37,
262,15,207,1,27,
262,15,207,4,0,
262,15,213,4,0,
262,15,214,4,0,
262,15,216,4,0,
262,15,218,4,0,
262,15,237,4,0,
262,15,240,4,0,
262,15,241,4,0,
262,15,242,1,1,1
262,15,242,1,65,
262,15,247,4,0,
262,15,249,4,0,
262,15,259,4,0,
262,15,263,4,0,
262,15,269,1,42,
262,15,269,4,0,
262,15,316,1,17,
262,15,336,1,1,3
262,15,336,1,5,
262,15,371,4,0,
262,15,372,1,32,
262,15,373,1,47,
262,15,373,4,0,
262,15,389,1,62,
262,15,399,4,0,
262,15,416,4,0,
262,15,496,4,0,
262,15,510,4,0,
262,15,514,4,0,
262,15,555,4,0,
262,15,590,4,0,
262,16,28,1,1,5
262,16,28,1,7,1
262,16,33,1,1,3
262,16,36,1,40,1
262,16,44,1,1,6
262,16,44,1,10,1
262,16,46,1,16,1
262,16,46,4,0,
262,16,63,4,0,
262,16,70,4,0,
262,16,91,4,0,
262,16,92,4,0,
262,16,104,4,0,
262,16,156,4,0,
262,16,162,3,0,
262,16,164,4,0,
262,16,168,1,1,2
262,16,168,4,0,
262,16,173,3,0,
262,16,180,3,0,
262,16,182,4,0,
262,16,184,1,28,1
262,16,207,1,20,1
262,16,207,4,0,
262,16,213,4,0,
262,16,214,4,0,
262,16,216,4,0,
262,16,218,4,0,
262,16,231,3,0,
262,16,237,4,0,
262,16,240,4,0,
262,16,241,4,0,
262,16,242,1,1,1
262,16,242,1,44,1
262,16,247,4,0,
262,16,249,4,0,
262,16,253,3,0,
262,16,259,4,0,
262,16,263,4,0,
262,16,269,1,36,1
262,16,269,4,0,
262,16,289,3,0,
262,16,290,4,0,
262,16,304,3,0,
262,16,316,1,13,1
262,16,336,1,1,4
262,16,336,1,4,1
262,16,343,3,0,
262,16,371,4,0,
262,16,372,1,24,1
262,16,373,1,32,1
262,16,373,4,0,
262,16,389,1,48,1
262,16,399,4,0,
262,16,416,4,0,
262,16,492,3,0,
262,16,496,4,0,
262,16,510,4,0,
262,16,514,4,0,
262,16,555,1,18,1
262,16,555,4,0,
262,16,590,4,0,
262,17,28,1,1,9
262,17,28,1,7,
262,17,33,1,1,7
262,17,36,1,48,
262,17,44,1,1,10
262,17,44,1,10,
262,17,46,1,16,
262,17,46,4,0,
262,17,63,4,0,
262,17,92,4,0,
262,17,104,4,0,
262,17,156,4,0,
262,17,164,4,0,
262,17,168,1,1,6
262,17,168,4,0,
262,17,182,4,0,
262,17,184,1,28,
262,17,207,1,20,
262,17,207,4,0,
262,17,213,4,0,
262,17,214,4,0,
262,17,216,4,0,
262,17,218,4,0,
262,17,237,4,0,
262,17,240,4,0,
262,17,241,4,0,
262,17,242,1,1,5
262,17,242,1,40,
262,17,247,4,0,
262,17,259,4,0,
262,17,263,4,0,
262,17,269,1,36,
262,17,269,4,0,
262,17,281,1,44,
262,17,316,1,13,
262,17,336,1,1,8
262,17,336,1,4,
262,17,371,4,0,
262,17,372,1,24,
262,17,373,1,32,
262,17,373,4,0,
262,17,389,1,52,
262,17,399,4,0,
262,17,416,4,0,
262,17,422,1,1,3
262,17,423,1,1,4
262,17,424,1,1,2
262,17,496,4,0,
262,17,555,1,0,
262,17,555,1,1,1
262,17,555,4,0,
262,17,583,1,56,
262,17,590,4,0,
262,18,28,1,1,9
262,18,28,1,7,
262,18,33,1,1,7
262,18,36,1,48,
262,18,44,1,1,10
262,18,44,1,10,
262,18,46,1,16,
262,18,46,4,0,
262,18,63,4,0,
262,18,92,4,0,
262,18,104,4,0,
262,18,156,4,0,
262,18,164,4,0,
262,18,168,1,1,6
262,18,168,4,0,
262,18,182,4,0,
262,18,184,1,28,
262,18,207,1,20,
262,18,207,4,0,
262,18,213,4,0,
262,18,214,4,0,
262,18,216,4,0,
262,18,218,4,0,
262,18,237,4,0,
262,18,240,4,0,
262,18,241,4,0,
262,18,242,1,1,5
262,18,242,1,40,
262,18,247,4,0,
262,18,259,4,0,
262,18,263,4,0,
262,18,269,1,36,
262,18,269,4,0,
262,18,281,1,44,
262,18,316,1,13,
262,18,336,1,1,8
262,18,336,1,4,
262,18,371,4,0,
262,18,372,1,24,
262,18,373,1,32,
262,18,373,4,0,
262,18,389,1,52,
262,18,399,4,0,
262,18,416,4,0,
262,18,422,1,1,3
262,18,423,1,1,4
262,18,424,1,1,2
262,18,496,4,0,
262,18,555,1,0,
262,18,555,1,1,1
262,18,555,4,0,
262,18,583,1,56,
262,18,590,4,0,
263,5,15,4,0,
263,5,28,1,13,
263,5,29,1,9,
263,5,33,1,1,1
263,5,39,1,5,
263,5,42,1,25,
263,5,45,1,1,2
263,5,57,4,0,
263,5,58,4,0,
263,5,59,4,0,
263,5,85,4,0,
263,5,87,4,0,
263,5,91,4,0,
263,5,92,4,0,
263,5,104,4,0,
263,5,156,1,37,
263,5,156,4,0,
263,5,164,2,0,
263,5,168,4,0,
263,5,175,1,33,
263,5,182,4,0,
263,5,187,1,41,
263,5,204,2,0,
263,5,213,4,0,
263,5,216,4,0,
263,5,218,4,0,
263,5,228,2,0,
263,5,231,4,0,
263,5,237,4,0,
263,5,240,4,0,
263,5,241,4,0,
263,5,247,4,0,
263,5,249,4,0,
263,5,263,4,0,
263,5,271,2,0,
263,5,290,4,0,
263,5,300,1,21,
263,5,316,1,17,
263,5,321,2,0,
263,5,343,1,29,
263,5,351,4,0,
263,5,352,4,0,
263,6,15,4,0,
263,6,28,1,13,
263,6,29,1,9,
263,6,33,1,1,1
263,6,34,3,0,
263,6,38,3,0,
263,6,39,1,5,
263,6,42,1,25,
263,6,45,1,1,2
263,6,57,4,0,
263,6,58,4,0,
263,6,59,4,0,
263,6,85,4,0,
263,6,86,3,0,
263,6,87,4,0,
263,6,91,4,0,
263,6,92,4,0,
263,6,102,3,0,
263,6,104,4,0,
263,6,111,3,0,
263,6,129,3,0,
263,6,156,1,37,
263,6,156,4,0,
263,6,164,2,0,
263,6,164,3,0,
263,6,168,4,0,
263,6,173,3,0,
263,6,175,1,33,
263,6,182,4,0,
263,6,187,1,41,
263,6,189,3,0,
263,6,196,3,0,
263,6,203,3,0,
263,6,204,2,0,
263,6,205,3,0,
263,6,207,3,0,
263,6,210,3,0,
263,6,213,4,0,
263,6,214,3,0,
263,6,216,4,0,
263,6,218,4,0,
263,6,228,2,0,
263,6,231,4,0,
263,6,237,4,0,
263,6,240,4,0,
263,6,241,4,0,
263,6,247,4,0,
263,6,249,4,0,
263,6,263,4,0,
263,6,271,2,0,
263,6,290,4,0,
263,6,300,1,21,
263,6,316,1,17,
263,6,321,2,0,
263,6,343,1,29,
263,6,351,4,0,
263,6,352,4,0,
263,7,15,4,0,
263,7,28,1,13,
263,7,29,1,9,
263,7,33,1,1,1
263,7,34,3,0,
263,7,38,3,0,
263,7,39,1,5,
263,7,42,1,25,
263,7,45,1,1,2
263,7,57,4,0,
263,7,58,4,0,
263,7,59,4,0,
263,7,85,4,0,
263,7,86,3,0,
263,7,87,4,0,
263,7,91,4,0,
263,7,92,4,0,
263,7,102,3,0,
263,7,104,4,0,
263,7,156,1,37,
263,7,156,4,0,
263,7,164,2,0,
263,7,164,3,0,
263,7,168,4,0,
263,7,175,1,33,
263,7,182,4,0,
263,7,187,1,41,
263,7,204,2,0,
263,7,213,4,0,
263,7,216,4,0,
263,7,218,4,0,
263,7,228,2,0,
263,7,231,4,0,
263,7,237,4,0,
263,7,240,4,0,
263,7,241,4,0,
263,7,247,4,0,
263,7,249,4,0,
263,7,263,4,0,
263,7,271,2,0,
263,7,290,4,0,
263,7,300,1,21,
263,7,316,1,17,
263,7,321,2,0,
263,7,343,1,29,
263,7,351,4,0,
263,7,352,4,0,
263,8,15,4,0,
263,8,28,1,13,
263,8,29,1,9,
263,8,33,1,1,1
263,8,39,1,5,
263,8,42,1,25,
263,8,45,1,1,2
263,8,57,4,0,
263,8,58,4,0,
263,8,59,4,0,
263,8,85,4,0,
263,8,86,4,0,
263,8,87,4,0,
263,8,91,4,0,
263,8,92,4,0,
263,8,104,4,0,
263,8,156,1,37,
263,8,156,4,0,
263,8,164,2,0,
263,8,164,4,0,
263,8,168,4,0,
263,8,175,1,33,
263,8,182,4,0,
263,8,187,1,41,
263,8,189,2,0,
263,8,203,4,0,
263,8,204,2,0,
263,8,207,4,0,
263,8,213,4,0,
263,8,214,4,0,
263,8,216,4,0,
263,8,218,4,0,
263,8,228,2,0,
263,8,231,4,0,
263,8,237,4,0,
263,8,240,4,0,
263,8,241,4,0,
263,8,247,4,0,
263,8,249,4,0,
263,8,263,4,0,
263,8,270,2,0,
263,8,271,2,0,
263,8,290,4,0,
263,8,300,1,21,
263,8,316,1,17,
263,8,321,2,0,
263,8,343,1,29,
263,8,351,4,0,
263,8,352,4,0,
263,8,363,4,0,
263,8,374,1,45,
263,8,374,4,0,
263,8,445,4,0,
263,8,447,4,0,
263,8,451,4,0,
263,9,15,4,0,
263,9,28,1,13,
263,9,29,1,9,
263,9,33,1,1,1
263,9,39,1,5,
263,9,42,1,25,
263,9,45,1,1,2
263,9,57,4,0,
263,9,58,4,0,
263,9,59,4,0,
263,9,85,4,0,
263,9,86,4,0,
263,9,87,4,0,
263,9,91,4,0,
263,9,92,4,0,
263,9,104,4,0,
263,9,129,3,0,
263,9,156,1,37,
263,9,156,4,0,
263,9,164,2,0,
263,9,164,4,0,
263,9,168,4,0,
263,9,173,3,0,
263,9,175,1,33,
263,9,182,4,0,
263,9,187,1,41,
263,9,189,2,0,
263,9,189,3,0,
263,9,196,3,0,
263,9,203,4,0,
263,9,204,2,0,
263,9,205,3,0,
263,9,207,4,0,
263,9,210,3,0,
263,9,213,4,0,
263,9,214,4,0,
263,9,216,4,0,
263,9,218,4,0,
263,9,228,2,0,
263,9,231,4,0,
263,9,237,4,0,
263,9,240,4,0,
263,9,241,4,0,
263,9,247,4,0,
263,9,249,4,0,
263,9,263,4,0,
263,9,270,2,0,
263,9,270,3,0,
263,9,271,2,0,
263,9,271,3,0,
263,9,290,4,0,
263,9,300,1,21,
263,9,316,1,17,
263,9,321,2,0,
263,9,343,1,29,
263,9,351,4,0,
263,9,352,4,0,
263,9,363,4,0,
263,9,374,1,45,
263,9,374,4,0,
263,9,387,3,0,
263,9,402,3,0,
263,9,441,3,0,
263,9,445,4,0,
263,9,447,4,0,
263,9,451,4,0,
263,10,15,4,0,
263,10,28,1,13,
263,10,29,1,9,
263,10,29,3,0,
263,10,33,1,1,1
263,10,39,1,5,
263,10,42,1,25,
263,10,45,1,1,2
263,10,57,4,0,
263,10,58,4,0,
263,10,59,4,0,
263,10,85,4,0,
263,10,86,4,0,
263,10,87,4,0,
263,10,91,4,0,
263,10,92,4,0,
263,10,104,4,0,
263,10,129,3,0,
263,10,156,1,37,
263,10,156,4,0,
263,10,162,3,0,
263,10,164,2,0,
263,10,164,4,0,
263,10,168,4,0,
263,10,173,3,0,
263,10,175,1,33,
263,10,182,4,0,
263,10,187,1,41,
263,10,189,2,0,
263,10,189,3,0,
263,10,196,3,0,
263,10,203,4,0,
263,10,204,2,0,
263,10,205,3,0,
263,10,207,4,0,
263,10,210,3,0,
263,10,213,4,0,
263,10,214,4,0,
263,10,216,4,0,
263,10,218,4,0,
263,10,228,2,0,
263,10,231,4,0,
263,10,237,4,0,
263,10,240,4,0,
263,10,241,4,0,
263,10,247,4,0,
263,10,249,4,0,
263,10,250,4,0,
263,10,263,4,0,
263,10,270,2,0,
263,10,270,3,0,
263,10,271,2,0,
263,10,271,3,0,
263,10,290,4,0,
263,10,300,1,21,
263,10,316,1,17,
263,10,321,2,0,
263,10,343,1,29,
263,10,351,4,0,
263,10,352,4,0,
263,10,363,4,0,
263,10,374,1,45,
263,10,374,4,0,
263,10,387,3,0,
263,10,402,3,0,
263,10,441,3,0,
263,10,445,4,0,
263,10,447,4,0,
263,10,451,4,0,
263,11,15,4,0,
263,11,28,1,13,
263,11,29,1,9,
263,11,33,1,1,1
263,11,39,1,5,
263,11,42,1,25,
263,11,45,1,1,2
263,11,57,4,0,
263,11,58,4,0,
263,11,59,4,0,
263,11,85,4,0,
263,11,86,4,0,
263,11,87,4,0,
263,11,91,4,0,
263,11,92,4,0,
263,11,104,4,0,
263,11,156,1,41,
263,11,156,4,0,
263,11,164,4,0,
263,11,168,4,0,
263,11,175,1,37,
263,11,182,4,0,
263,11,187,1,45,
263,11,189,2,0,
263,11,204,2,0,
263,11,207,4,0,
263,11,213,4,0,
263,11,214,2,0,
263,11,216,4,0,
263,11,218,4,0,
263,11,228,2,0,
263,11,237,4,0,
263,11,240,4,0,
263,11,241,4,0,
263,11,247,4,0,
263,11,249,4,0,
263,11,263,4,0,
263,11,270,2,0,
263,11,271,2,0,
263,11,300,1,21,
263,11,316,1,17,
263,11,321,2,0,
263,11,343,1,29,
263,11,374,1,49,
263,11,374,4,0,
263,11,431,2,0,
263,11,447,4,0,
263,11,451,4,0,
263,11,468,4,0,
263,11,493,2,0,
263,11,496,4,0,
263,11,497,4,0,
263,11,514,4,0,
263,11,516,1,33,
263,11,526,4,0,
263,12,15,4,0,
263,12,28,1,13,
263,12,29,1,9,
263,12,33,1,1,1
263,12,39,1,5,
263,12,42,1,25,
263,12,45,1,1,2
263,12,57,4,0,
263,12,58,4,0,
263,12,59,4,0,
263,12,85,4,0,
263,12,87,4,0,
263,12,91,4,0,
263,12,92,4,0,
263,12,104,4,0,
263,12,156,1,37,
263,12,156,4,0,
263,12,168,4,0,
263,12,175,1,33,
263,12,182,4,0,
263,12,187,1,41,
263,12,213,4,0,
263,12,216,4,0,
263,12,218,4,0,
263,12,231,4,0,
263,12,237,4,0,
263,12,240,4,0,
263,12,241,4,0,
263,12,247,4,0,
263,12,249,4,0,
263,12,263,4,0,
263,12,290,4,0,
263,12,300,1,21,
263,12,316,1,17,
263,12,343,1,29,
263,12,351,4,0,
263,12,352,4,0,
263,13,15,4,0,
263,13,28,1,13,
263,13,29,1,9,
263,13,33,1,1,1
263,13,34,3,0,
263,13,38,3,0,
263,13,39,1,5,
263,13,42,1,25,
263,13,45,1,1,2
263,13,57,4,0,
263,13,58,4,0,
263,13,59,4,0,
263,13,85,4,0,
263,13,86,3,0,
263,13,87,4,0,
263,13,91,4,0,
263,13,92,4,0,
263,13,102,3,0,
263,13,104,4,0,
263,13,156,1,37,
263,13,156,4,0,
263,13,164,3,0,
263,13,168,4,0,
263,13,175,1,33,
263,13,182,4,0,
263,13,187,1,41,
263,13,196,3,0,
263,13,207,3,0,
263,13,213,4,0,
263,13,216,4,0,
263,13,218,4,0,
263,13,231,4,0,
263,13,237,4,0,
263,13,240,4,0,
263,13,241,4,0,
263,13,247,4,0,
263,13,249,4,0,
263,13,263,4,0,
263,13,290,4,0,
263,13,300,1,21,
263,13,316,1,17,
263,13,343,1,29,
263,13,351,4,0,
263,13,352,4,0,
263,14,15,4,0,
263,14,28,1,13,
263,14,29,1,9,
263,14,33,1,1,1
263,14,39,1,5,
263,14,42,1,25,
263,14,45,1,1,2
263,14,57,4,0,
263,14,58,4,0,
263,14,59,4,0,
263,14,85,4,0,
263,14,86,4,0,
263,14,87,4,0,
263,14,91,4,0,
263,14,92,4,0,
263,14,104,4,0,
263,14,156,1,41,
263,14,156,4,0,
263,14,162,3,0,
263,14,164,4,0,
263,14,168,4,0,
263,14,173,3,0,
263,14,175,1,37,
263,14,182,4,0,
263,14,187,1,45,
263,14,189,2,0,
263,14,196,3,0,
263,14,204,2,0,
263,14,207,4,0,
263,14,213,4,0,
263,14,214,2,0,
263,14,214,3,0,
263,14,216,4,0,
263,14,218,4,0,
263,14,228,2,0,
263,14,231,3,0,
263,14,237,4,0,
263,14,240,4,0,
263,14,241,4,0,
263,14,247,4,0,
263,14,249,4,0,
263,14,263,4,0,
263,14,270,2,0,
263,14,270,3,0,
263,14,271,2,0,
263,14,271,3,0,
263,14,300,1,21,
263,14,304,3,0,
263,14,316,1,17,
263,14,321,2,0,
263,14,343,1,29,
263,14,343,3,0,
263,14,374,1,49,
263,14,374,4,0,
263,14,387,3,0,
263,14,402,3,0,
263,14,431,2,0,
263,14,441,3,0,
263,14,447,4,0,
263,14,451,4,0,
263,14,468,4,0,
263,14,493,2,0,
263,14,496,4,0,
263,14,497,4,0,
263,14,514,4,0,
263,14,516,1,33,
263,14,526,4,0,
263,15,15,4,0,
263,15,28,1,13,
263,15,29,1,9,
263,15,33,1,1,2
263,15,39,1,5,
263,15,42,1,25,
263,15,45,1,1,1
263,15,57,4,0,
263,15,58,4,0,
263,15,59,4,0,
263,15,85,4,0,
263,15,86,4,0,
263,15,87,4,0,
263,15,91,4,0,
263,15,92,4,0,
263,15,104,4,0,
263,15,156,1,41,
263,15,156,4,0,
263,15,164,4,0,
263,15,168,4,0,
263,15,175,1,37,
263,15,182,4,0,
263,15,187,1,45,
263,15,189,2,0,
263,15,204,2,0,
263,15,207,4,0,
263,15,213,4,0,
263,15,214,2,0,
263,15,214,4,0,
263,15,216,4,0,
263,15,218,4,0,
263,15,228,2,0,
263,15,237,4,0,
263,15,240,4,0,
263,15,241,4,0,
263,15,247,4,0,
263,15,249,4,0,
263,15,263,4,0,
263,15,270,2,0,
263,15,271,2,0,
263,15,300,1,21,
263,15,316,1,17,
263,15,321,2,0,
263,15,343,1,29,
263,15,374,1,49,
263,15,374,4,0,
263,15,431,2,0,
263,15,447,4,0,
263,15,451,4,0,
263,15,468,4,0,
263,15,493,2,0,
263,15,496,4,0,
263,15,497,4,0,
263,15,514,4,0,
263,15,516,1,33,
263,15,590,4,0,
263,15,608,1,11,
263,16,15,4,0,
263,16,28,1,7,1
263,16,29,1,11,1
263,16,33,1,1,1
263,16,36,1,31,1
263,16,39,1,5,1
263,16,42,1,19,1
263,16,45,1,1,2
263,16,57,4,0,
263,16,58,4,0,
263,16,59,4,0,
263,16,85,4,0,
263,16,86,4,0,
263,16,87,4,0,
263,16,91,4,0,
263,16,92,4,0,
263,16,104,4,0,
263,16,156,1,35,1
263,16,156,4,0,
263,16,162,3,0,
263,16,164,4,0,
263,16,168,4,0,
263,16,173,3,0,
263,16,175,1,29,1
263,16,182,4,0,
263,16,187,1,37,1
263,16,189,2,0,
263,16,196,3,0,
263,16,204,2,0,
263,16,207,4,0,
263,16,213,4,0,
263,16,214,2,0,
263,16,214,4,0,
263,16,216,4,0,
263,16,218,4,0,
263,16,228,2,0,
263,16,231,3,0,
263,16,237,4,0,
263,16,240,4,0,
263,16,241,4,0,
263,16,247,4,0,
263,16,249,4,0,
263,16,263,4,0,
263,16,270,2,0,
263,16,270,3,0,
263,16,271,2,0,
263,16,271,3,0,
263,16,290,4,0,
263,16,300,1,17,1
263,16,304,3,0,
263,16,316,1,13,1
263,16,321,2,0,
263,16,343,1,23,1
263,16,343,3,0,
263,16,351,3,0,
263,16,352,3,0,
263,16,374,1,41,1
263,16,374,4,0,
263,16,387,3,0,
263,16,402,3,0,
263,16,431,2,0,
263,16,441,3,0,
263,16,447,4,0,
263,16,451,4,0,
263,16,468,4,0,
263,16,493,2,0,
263,16,496,4,0,
263,16,497,4,0,
263,16,514,4,0,
263,16,516,1,25,1
263,16,590,4,0,
263,16,608,1,12,1
263,17,28,1,7,
263,17,29,1,11,
263,17,33,1,1,1
263,17,36,1,31,
263,17,39,1,5,
263,17,42,1,19,
263,17,45,1,1,2
263,17,57,4,0,
263,17,58,4,0,
263,17,59,4,0,
263,17,85,4,0,
263,17,86,4,0,
263,17,87,4,0,
263,17,92,4,0,
263,17,104,4,0,
263,17,156,1,35,
263,17,156,4,0,
263,17,164,4,0,
263,17,168,4,0,
263,17,175,1,29,
263,17,182,4,0,
263,17,187,1,37,
263,17,189,2,0,
263,17,204,2,0,
263,17,207,4,0,
263,17,213,4,0,
263,17,214,2,0,
263,17,214,4,0,
263,17,216,4,0,
263,17,218,4,0,
263,17,228,2,0,
263,17,237,4,0,
263,17,240,4,0,
263,17,241,4,0,
263,17,247,4,0,
263,17,263,4,0,
263,17,270,2,0,
263,17,271,2,0,
263,17,300,1,17,
263,17,316,1,13,
263,17,321,2,0,
263,17,343,1,23,
263,17,374,1,41,
263,17,374,4,0,
263,17,431,2,0,
263,17,447,4,0,
263,17,451,4,0,
263,17,493,2,0,
263,17,496,4,0,
263,17,497,4,0,
263,17,516,1,25,
263,17,526,4,0,
263,17,590,4,0,
263,17,608,1,12,
263,18,28,1,7,
263,18,29,1,11,
263,18,33,1,1,1
263,18,36,1,31,
263,18,39,1,5,
263,18,42,1,19,
263,18,45,1,1,2
263,18,57,4,0,
263,18,58,4,0,
263,18,59,4,0,
263,18,85,4,0,
263,18,86,4,0,
263,18,87,4,0,
263,18,92,4,0,
263,18,104,4,0,
263,18,156,1,35,
263,18,156,4,0,
263,18,164,4,0,
263,18,168,4,0,
263,18,175,1,29,
263,18,182,4,0,
263,18,187,1,37,
263,18,189,2,0,
263,18,204,2,0,
263,18,207,4,0,
263,18,213,4,0,
263,18,214,2,0,
263,18,214,4,0,
263,18,216,4,0,
263,18,218,4,0,
263,18,228,2,0,
263,18,237,4,0,
263,18,240,4,0,
263,18,241,4,0,
263,18,245,2,0,
263,18,247,4,0,
263,18,263,4,0,
263,18,270,2,0,
263,18,271,2,0,
263,18,300,1,17,
263,18,316,1,13,
263,18,321,2,0,
263,18,343,1,23,
263,18,374,1,41,
263,18,374,4,0,
263,18,431,2,0,
263,18,447,4,0,
263,18,451,4,0,
263,18,493,2,0,
263,18,496,4,0,
263,18,497,4,0,
263,18,516,1,25,
263,18,526,4,0,
263,18,590,4,0,
263,18,608,1,12,
264,5,15,4,0,
264,5,28,1,13,
264,5,29,1,1,4
264,5,29,1,9,
264,5,33,1,1,1
264,5,39,1,1,3
264,5,39,1,5,
264,5,45,1,1,2
264,5,46,4,0,
264,5,57,4,0,
264,5,58,4,0,
264,5,59,4,0,
264,5,63,4,0,
264,5,70,4,0,
264,5,85,4,0,
264,5,87,4,0,
264,5,91,4,0,
264,5,92,4,0,
264,5,104,4,0,
264,5,154,1,29,
264,5,156,1,47,
264,5,156,4,0,
264,5,163,1,41,
264,5,168,4,0,
264,5,182,4,0,
264,5,187,1,53,
264,5,213,4,0,
264,5,216,4,0,
264,5,218,4,0,
264,5,231,4,0,
264,5,237,4,0,
264,5,240,4,0,
264,5,241,4,0,
264,5,247,4,0,
264,5,249,4,0,
264,5,263,4,0,
264,5,290,4,0,
264,5,300,1,23,
264,5,316,1,17,
264,5,343,1,35,
264,5,351,4,0,
264,5,352,4,0,
264,6,15,4,0,
264,6,28,1,13,
264,6,29,1,1,4
264,6,29,1,9,
264,6,33,1,1,1
264,6,34,3,0,
264,6,38,3,0,
264,6,39,1,1,3
264,6,39,1,5,
264,6,45,1,1,2
264,6,46,4,0,
264,6,57,4,0,
264,6,58,4,0,
264,6,59,4,0,
264,6,63,4,0,
264,6,70,4,0,
264,6,85,4,0,
264,6,86,3,0,
264,6,87,4,0,
264,6,91,4,0,
264,6,92,4,0,
264,6,102,3,0,
264,6,104,4,0,
264,6,111,3,0,
264,6,129,3,0,
264,6,154,1,29,
264,6,156,1,47,
264,6,156,4,0,
264,6,163,1,41,
264,6,164,3,0,
264,6,168,4,0,
264,6,173,3,0,
264,6,182,4,0,
264,6,187,1,53,
264,6,189,3,0,
264,6,196,3,0,
264,6,203,3,0,
264,6,205,3,0,
264,6,207,3,0,
264,6,210,3,0,
264,6,213,4,0,
264,6,214,3,0,
264,6,216,4,0,
264,6,218,4,0,
264,6,231,4,0,
264,6,237,4,0,
264,6,240,4,0,
264,6,241,4,0,
264,6,247,4,0,
264,6,249,4,0,
264,6,263,4,0,
264,6,290,4,0,
264,6,300,1,23,
264,6,316,1,17,
264,6,343,1,35,
264,6,351,4,0,
264,6,352,4,0,
264,7,15,4,0,
264,7,28,1,13,
264,7,29,1,1,4
264,7,29,1,9,
264,7,33,1,1,1
264,7,34,3,0,
264,7,38,3,0,
264,7,39,1,1,3
264,7,39,1,5,
264,7,45,1,1,2
264,7,46,4,0,
264,7,57,4,0,
264,7,58,4,0,
264,7,59,4,0,
264,7,63,4,0,
264,7,70,4,0,
264,7,85,4,0,
264,7,86,3,0,
264,7,87,4,0,
264,7,91,4,0,
264,7,92,4,0,
264,7,102,3,0,
264,7,104,4,0,
264,7,154,1,29,
264,7,156,1,47,
264,7,156,4,0,
264,7,163,1,41,
264,7,164,3,0,
264,7,168,4,0,
264,7,182,4,0,
264,7,187,1,53,
264,7,213,4,0,
264,7,216,4,0,
264,7,218,4,0,
264,7,231,4,0,
264,7,237,4,0,
264,7,240,4,0,
264,7,241,4,0,
264,7,247,4,0,
264,7,249,4,0,
264,7,263,4,0,
264,7,290,4,0,
264,7,300,1,23,
264,7,316,1,17,
264,7,343,1,35,
264,7,351,4,0,
264,7,352,4,0,
264,8,15,4,0,
264,8,28,1,13,
264,8,29,1,1,5
264,8,29,1,9,
264,8,33,1,1,2
264,8,39,1,1,4
264,8,39,1,5,
264,8,45,1,1,3
264,8,46,4,0,
264,8,57,4,0,
264,8,58,4,0,
264,8,59,4,0,
264,8,63,4,0,
264,8,70,4,0,
264,8,85,4,0,
264,8,86,4,0,
264,8,87,4,0,
264,8,91,4,0,
264,8,92,4,0,
264,8,104,4,0,
264,8,154,1,29,
264,8,156,1,47,
264,8,156,4,0,
264,8,163,1,41,
264,8,164,4,0,
264,8,168,4,0,
264,8,182,4,0,
264,8,187,1,53,
264,8,203,4,0,
264,8,207,4,0,
264,8,213,4,0,
264,8,214,4,0,
264,8,216,4,0,
264,8,218,4,0,
264,8,231,4,0,
264,8,237,4,0,
264,8,240,4,0,
264,8,241,4,0,
264,8,247,4,0,
264,8,249,4,0,
264,8,263,4,0,
264,8,290,4,0,
264,8,300,1,23,
264,8,316,1,17,
264,8,343,1,35,
264,8,351,4,0,
264,8,352,4,0,
264,8,363,4,0,
264,8,374,1,59,
264,8,374,4,0,
264,8,415,1,1,1
264,8,416,4,0,
264,8,421,4,0,
264,8,445,4,0,
264,8,447,4,0,
264,8,451,4,0,
264,9,15,4,0,
264,9,28,1,13,
264,9,29,1,1,5
264,9,29,1,9,
264,9,33,1,1,2
264,9,39,1,1,4
264,9,39,1,5,
264,9,45,1,1,3
264,9,46,4,0,
264,9,57,4,0,
264,9,58,4,0,
264,9,59,4,0,
264,9,63,4,0,
264,9,70,4,0,
264,9,85,4,0,
264,9,86,4,0,
264,9,87,4,0,
264,9,91,4,0,
264,9,92,4,0,
264,9,104,4,0,
264,9,129,3,0,
264,9,154,1,29,
264,9,156,1,47,
264,9,156,4,0,
264,9,163,1,41,
264,9,164,4,0,
264,9,168,4,0,
264,9,173,3,0,
264,9,182,4,0,
264,9,187,1,53,
264,9,189,3,0,
264,9,196,3,0,
264,9,203,4,0,
264,9,205,3,0,
264,9,207,4,0,
264,9,210,3,0,
264,9,213,4,0,
264,9,214,4,0,
264,9,216,4,0,
264,9,218,4,0,
264,9,231,4,0,
264,9,237,4,0,
264,9,240,4,0,
264,9,241,4,0,
264,9,247,4,0,
264,9,249,4,0,
264,9,263,4,0,
264,9,270,3,0,
264,9,271,3,0,
264,9,290,4,0,
264,9,300,1,23,
264,9,316,1,17,
264,9,343,1,35,
264,9,351,4,0,
264,9,352,4,0,
264,9,363,4,0,
264,9,374,1,59,
264,9,374,4,0,
264,9,387,3,0,
264,9,402,3,0,
264,9,415,1,1,1
264,9,416,4,0,
264,9,421,4,0,
264,9,441,3,0,
264,9,445,4,0,
264,9,447,4,0,
264,9,451,4,0,
264,10,15,4,0,
264,10,28,1,13,
264,10,29,1,1,5
264,10,29,1,9,
264,10,29,3,0,
264,10,33,1,1,2
264,10,39,1,1,4
264,10,39,1,5,
264,10,45,1,1,3
264,10,46,4,0,
264,10,57,4,0,
264,10,58,4,0,
264,10,59,4,0,
264,10,63,4,0,
264,10,70,4,0,
264,10,85,4,0,
264,10,86,4,0,
264,10,87,4,0,
264,10,91,4,0,
264,10,92,4,0,
264,10,104,4,0,
264,10,129,3,0,
264,10,154,1,29,
264,10,156,1,47,
264,10,156,4,0,
264,10,162,3,0,
264,10,163,1,41,
264,10,164,4,0,
264,10,168,4,0,
264,10,173,3,0,
264,10,182,4,0,
264,10,187,1,53,
264,10,189,3,0,
264,10,196,3,0,
264,10,203,4,0,
264,10,205,3,0,
264,10,207,4,0,
264,10,210,3,0,
264,10,213,4,0,
264,10,214,4,0,
264,10,216,4,0,
264,10,218,4,0,
264,10,231,4,0,
264,10,237,4,0,
264,10,240,4,0,
264,10,241,4,0,
264,10,247,4,0,
264,10,249,4,0,
264,10,250,4,0,
264,10,263,4,0,
264,10,270,3,0,
264,10,271,3,0,
264,10,290,4,0,
264,10,300,1,23,
264,10,316,1,17,
264,10,343,1,35,
264,10,351,4,0,
264,10,352,4,0,
264,10,363,4,0,
264,10,374,1,59,
264,10,374,4,0,
264,10,387,3,0,
264,10,402,3,0,
264,10,415,1,1,1
264,10,416,4,0,
264,10,421,4,0,
264,10,441,3,0,
264,10,445,4,0,
264,10,447,4,0,
264,10,451,4,0,
264,11,15,4,0,
264,11,28,1,13,
264,11,29,1,1,5
264,11,29,1,9,
264,11,33,1,1,2
264,11,39,1,1,4
264,11,39,1,5,
264,11,45,1,1,3
264,11,46,4,0,
264,11,57,4,0,
264,11,58,4,0,
264,11,59,4,0,
264,11,63,4,0,
264,11,70,4,0,
264,11,85,4,0,
264,11,86,4,0,
264,11,87,4,0,
264,11,91,4,0,
264,11,92,4,0,
264,11,104,4,0,
264,11,154,1,29,
264,11,156,1,53,
264,11,156,4,0,
264,11,163,1,47,
264,11,164,4,0,
264,11,168,4,0,
264,11,182,4,0,
264,11,187,1,59,
264,11,207,4,0,
264,11,213,4,0,
264,11,216,4,0,
264,11,218,4,0,
264,11,237,4,0,
264,11,240,4,0,
264,11,241,4,0,
264,11,247,4,0,
264,11,249,4,0,
264,11,263,4,0,
264,11,300,1,23,
264,11,316,1,17,
264,11,343,1,35,
264,11,374,1,65,
264,11,374,4,0,
264,11,415,1,1,1
264,11,416,4,0,
264,11,421,4,0,
264,11,447,4,0,
264,11,451,4,0,
264,11,468,4,0,
264,11,496,4,0,
264,11,497,4,0,
264,11,514,4,0,
264,11,516,1,41,
264,11,526,4,0,
264,12,15,4,0,
264,12,28,1,13,
264,12,29,1,1,4
264,12,29,1,9,
264,12,33,1,1,1
264,12,39,1,1,3
264,12,39,1,5,
264,12,45,1,1,2
264,12,46,4,0,
264,12,57,4,0,
264,12,58,4,0,
264,12,59,4,0,
264,12,63,4,0,
264,12,70,4,0,
264,12,85,4,0,
264,12,87,4,0,
264,12,91,4,0,
264,12,92,4,0,
264,12,104,4,0,
264,12,154,1,29,
264,12,156,1,47,
264,12,156,4,0,
264,12,163,1,41,
264,12,168,4,0,
264,12,182,4,0,
264,12,187,1,53,
264,12,213,4,0,
264,12,216,4,0,
264,12,218,4,0,
264,12,231,4,0,
264,12,237,4,0,
264,12,240,4,0,
264,12,241,4,0,
264,12,247,4,0,
264,12,249,4,0,
264,12,263,4,0,
264,12,290,4,0,
264,12,300,1,23,
264,12,316,1,17,
264,12,343,1,35,
264,12,351,4,0,
264,12,352,4,0,
264,13,15,4,0,
264,13,28,1,13,
264,13,29,1,1,4
264,13,29,1,9,
264,13,33,1,1,1
264,13,34,3,0,
264,13,38,3,0,
264,13,39,1,1,3
264,13,39,1,5,
264,13,45,1,1,2
264,13,46,4,0,
264,13,57,4,0,
264,13,58,4,0,
264,13,59,4,0,
264,13,63,4,0,
264,13,70,4,0,
264,13,85,4,0,
264,13,86,3,0,
264,13,87,4,0,
264,13,91,4,0,
264,13,92,4,0,
264,13,102,3,0,
264,13,104,4,0,
264,13,154,1,29,
264,13,156,1,47,
264,13,156,4,0,
264,13,163,1,41,
264,13,164,3,0,
264,13,168,4,0,
264,13,182,4,0,
264,13,187,1,53,
264,13,196,3,0,
264,13,207,3,0,
264,13,213,4,0,
264,13,216,4,0,
264,13,218,4,0,
264,13,231,4,0,
264,13,237,4,0,
264,13,240,4,0,
264,13,241,4,0,
264,13,247,4,0,
264,13,249,4,0,
264,13,263,4,0,
264,13,290,4,0,
264,13,300,1,23,
264,13,316,1,17,
264,13,343,1,35,
264,13,351,4,0,
264,13,352,4,0,
264,14,15,4,0,
264,14,28,1,13,
264,14,29,1,1,5
264,14,29,1,9,
264,14,33,1,1,2
264,14,39,1,1,4
264,14,39,1,5,
264,14,45,1,1,3
264,14,46,4,0,
264,14,57,4,0,
264,14,58,4,0,
264,14,59,4,0,
264,14,63,4,0,
264,14,70,4,0,
264,14,85,4,0,
264,14,86,4,0,
264,14,87,4,0,
264,14,91,4,0,
264,14,92,4,0,
264,14,104,4,0,
264,14,154,1,29,
264,14,156,1,53,
264,14,156,4,0,
264,14,162,3,0,
264,14,163,1,47,
264,14,164,4,0,
264,14,168,4,0,
264,14,173,3,0,
264,14,182,4,0,
264,14,187,1,59,
264,14,196,3,0,
264,14,207,4,0,
264,14,213,4,0,
264,14,214,3,0,
264,14,216,4,0,
264,14,218,4,0,
264,14,231,3,0,
264,14,237,4,0,
264,14,240,4,0,
264,14,241,4,0,
264,14,247,4,0,
264,14,249,4,0,
264,14,263,4,0,
264,14,270,3,0,
264,14,271,3,0,
264,14,300,1,23,
264,14,304,3,0,
264,14,316,1,17,
264,14,343,1,35,
264,14,343,3,0,
264,14,374,1,65,
264,14,374,4,0,
264,14,387,3,0,
264,14,402,3,0,
264,14,415,1,1,1
264,14,416,4,0,
264,14,421,4,0,
264,14,441,3,0,
264,14,447,4,0,
264,14,451,4,0,
264,14,468,4,0,
264,14,496,4,0,
264,14,497,4,0,
264,14,514,4,0,
264,14,516,1,41,
264,14,526,4,0,
264,15,15,4,0,
264,15,28,1,13,
264,15,29,1,1,7
264,15,29,1,9,
264,15,33,1,1,4
264,15,39,1,1,6
264,15,39,1,5,
264,15,45,1,1,5
264,15,46,4,0,
264,15,57,4,0,
264,15,58,4,0,
264,15,59,4,0,
264,15,63,4,0,
264,15,70,4,0,
264,15,85,4,0,
264,15,86,4,0,
264,15,87,4,0,
264,15,91,4,0,
264,15,92,4,0,
264,15,104,4,0,
264,15,154,1,29,
264,15,156,1,53,
264,15,156,4,0,
264,15,163,1,47,
264,15,164,4,0,
264,15,168,4,0,
264,15,182,4,0,
264,15,187,1,59,
264,15,207,4,0,
264,15,213,4,0,
264,15,214,4,0,
264,15,216,4,0,
264,15,218,4,0,
264,15,237,4,0,
264,15,240,4,0,
264,15,241,4,0,
264,15,247,4,0,
264,15,249,4,0,
264,15,263,4,0,
264,15,300,1,23,
264,15,316,1,17,
264,15,343,1,35,
264,15,374,1,65,
264,15,374,4,0,
264,15,415,1,1,3
264,15,416,4,0,
264,15,421,4,0,
264,15,447,4,0,
264,15,451,4,0,
264,15,468,4,0,
264,15,496,4,0,
264,15,497,4,0,
264,15,514,4,0,
264,15,516,1,41,
264,15,563,1,1,2
264,15,583,1,1,1
264,15,590,4,0,
264,16,15,4,0,
264,16,28,1,1,7
264,16,28,1,7,1
264,16,29,1,11,1
264,16,33,1,1,4
264,16,38,1,35,1
264,16,39,1,1,6
264,16,39,1,5,1
264,16,45,1,1,5
264,16,46,4,0,
264,16,57,4,0,
264,16,58,4,0,
264,16,59,4,0,
264,16,63,4,0,
264,16,70,4,0,
264,16,85,4,0,
264,16,86,4,0,
264,16,87,4,0,
264,16,91,4,0,
264,16,92,4,0,
264,16,104,4,0,
264,16,154,1,19,1
264,16,156,1,40,1
264,16,156,4,0,
264,16,162,3,0,
264,16,163,1,32,1
264,16,164,4,0,
264,16,168,4,0,
264,16,173,3,0,
264,16,182,4,0,
264,16,187,1,43,1
264,16,196,3,0,
264,16,207,4,0,
264,16,213,4,0,
264,16,214,4,0,
264,16,216,4,0,
264,16,218,4,0,
264,16,231,3,0,
264,16,237,4,0,
264,16,240,4,0,
264,16,241,4,0,
264,16,247,4,0,
264,16,249,4,0,
264,16,263,4,0,
264,16,270,3,0,
264,16,271,3,0,
264,16,290,4,0,
264,16,300,1,17,1
264,16,304,3,0,
264,16,316,1,13,1
264,16,343,1,24,1
264,16,343,3,0,
264,16,351,3,0,
264,16,352,3,0,
264,16,374,1,48,1
264,16,374,4,0,
264,16,387,3,0,
264,16,402,3,0,
264,16,415,1,1,3
264,16,416,4,0,
264,16,421,4,0,
264,16,441,3,0,
264,16,447,4,0,
264,16,451,4,0,
264,16,468,4,0,
264,16,496,4,0,
264,16,497,4,0,
264,16,514,4,0,
264,16,516,1,27,1
264,16,563,1,1,2
264,16,583,1,1,1
264,16,590,4,0,
264,17,28,1,1,7
264,17,28,1,7,
264,17,29,1,11,
264,17,33,1,1,4
264,17,38,1,35,
264,17,39,1,1,6
264,17,39,1,5,
264,17,45,1,1,5
264,17,46,4,0,
264,17,57,4,0,
264,17,58,4,0,
264,17,59,4,0,
264,17,63,4,0,
264,17,85,4,0,
264,17,86,4,0,
264,17,87,4,0,
264,17,92,4,0,
264,17,104,4,0,
264,17,154,1,19,
264,17,156,1,40,
264,17,156,4,0,
264,17,163,1,32,
264,17,164,4,0,
264,17,168,4,0,
264,17,182,4,0,
264,17,187,1,43,
264,17,207,4,0,
264,17,213,4,0,
264,17,214,4,0,
264,17,216,4,0,
264,17,218,4,0,
264,17,237,4,0,
264,17,240,4,0,
264,17,241,4,0,
264,17,247,4,0,
264,17,263,4,0,
264,17,300,1,17,
264,17,316,1,13,
264,17,343,1,24,
264,17,374,1,48,
264,17,374,4,0,
264,17,415,1,1,3
264,17,416,4,0,
264,17,421,4,0,
264,17,447,4,0,
264,17,451,4,0,
264,17,496,4,0,
264,17,497,4,0,
264,17,516,1,27,
264,17,526,4,0,
264,17,563,1,1,2
264,17,583,1,1,1
264,17,590,4,0,
264,18,28,1,1,7
264,18,28,1,7,
264,18,29,1,11,
264,18,33,1,1,4
264,18,38,1,35,
264,18,39,1,1,6
264,18,39,1,5,
264,18,45,1,1,5
264,18,46,4,0,
264,18,57,4,0,
264,18,58,4,0,
264,18,59,4,0,
264,18,63,4,0,
264,18,85,4,0,
264,18,86,4,0,
264,18,87,4,0,
264,18,92,4,0,
264,18,104,4,0,
264,18,154,1,19,
264,18,156,1,40,
264,18,156,4,0,
264,18,163,1,32,
264,18,164,4,0,
264,18,168,4,0,
264,18,182,4,0,
264,18,187,1,43,
264,18,207,4,0,
264,18,213,4,0,
264,18,214,4,0,
264,18,216,4,0,
264,18,218,4,0,
264,18,237,4,0,
264,18,240,4,0,
264,18,241,4,0,
264,18,247,4,0,
264,18,263,4,0,
264,18,300,1,17,
264,18,316,1,13,
264,18,343,1,24,
264,18,374,1,48,
264,18,374,4,0,
264,18,415,1,1,3
264,18,416,4,0,
264,18,421,4,0,
264,18,447,4,0,
264,18,451,4,0,
264,18,496,4,0,
264,18,497,4,0,
264,18,516,1,27,
264,18,526,4,0,
264,18,563,1,1,2
264,18,583,1,1,1
264,18,590,4,0,
265,5,33,1,1,1
265,5,40,1,5,
265,5,81,1,1,2
265,6,33,1,1,1
265,6,40,1,5,
265,6,81,1,1,2
265,7,33,1,1,1
265,7,40,1,5,
265,7,81,1,1,2
265,8,33,1,1,1
265,8,40,1,5,
265,8,81,1,1,2
265,9,33,1,1,1
265,9,40,1,5,
265,9,81,1,1,2
265,9,173,3,0,
265,9,450,1,15,
265,10,33,1,1,1
265,10,40,1,5,
265,10,81,1,1,2
265,10,81,3,0,
265,10,173,3,0,
265,10,450,1,15,
265,10,450,3,0,
265,11,33,1,1,1
265,11,40,1,5,
265,11,81,1,1,2
265,11,450,1,15,
265,12,33,1,1,1
265,12,40,1,5,
265,12,81,1,1,2
265,13,33,1,1,1
265,13,40,1,5,
265,13,81,1,1,2
265,14,33,1,1,1
265,14,40,1,5,
265,14,81,1,1,2
265,14,173,3,0,
265,14,450,1,15,
265,14,450,3,0,
265,14,527,3,0,
265,15,33,1,1,1
265,15,40,1,5,
265,15,81,1,1,2
265,15,450,1,15,
265,16,33,1,1,1
265,16,40,1,5,1
265,16,81,1,1,2
265,16,173,3,0,
265,16,450,1,15,1
265,16,450,3,0,
265,16,527,3,0,
265,17,33,1,1,1
265,17,40,1,5,
265,17,81,1,1,2
265,17,450,1,15,
265,18,33,1,1,1
265,18,40,1,5,
265,18,81,1,1,2
265,18,450,1,15,
266,5,106,1,1,
266,5,106,1,7,
266,6,106,1,1,
266,6,106,1,7,
266,7,106,1,1,
266,7,106,1,7,
266,8,106,1,1,
266,8,106,1,7,
266,9,106,1,1,
266,9,106,1,7,
266,9,334,3,0,
266,10,81,3,0,
266,10,106,1,1,
266,10,106,1,7,
266,10,334,3,0,
266,10,450,3,0,
266,11,106,1,1,
266,11,106,1,7,
266,12,106,1,1,
266,12,106,1,7,
266,13,106,1,1,
266,13,106,1,7,
266,14,106,1,1,
266,14,106,1,7,
266,14,334,3,0,
266,14,450,3,0,
266,14,527,3,0,
266,15,106,1,1,
266,15,106,1,7,
266,16,106,1,1,1
266,16,106,1,7,1
266,16,334,3,0,
266,16,450,3,0,
266,16,527,3,0,
266,17,106,1,0,
266,17,106,1,1,
266,18,106,1,0,
266,18,106,1,1,
267,5,16,1,13,
267,5,18,1,27,
267,5,63,4,0,
267,5,71,1,1,
267,5,71,1,10,
267,5,72,1,24,
267,5,76,4,0,
267,5,78,1,17,
267,5,92,4,0,
267,5,94,4,0,
267,5,104,4,0,
267,5,148,4,0,
267,5,156,4,0,
267,5,168,4,0,
267,5,182,4,0,
267,5,202,1,38,
267,5,202,4,0,
267,5,213,1,31,
267,5,213,4,0,
267,5,216,4,0,
267,5,218,4,0,
267,5,219,4,0,
267,5,234,1,20,
267,5,237,4,0,
267,5,241,4,0,
267,5,247,4,0,
267,5,263,4,0,
267,5,290,4,0,
267,5,318,1,34,
267,5,332,4,0,
267,6,16,1,13,
267,6,18,1,27,
267,6,38,3,0,
267,6,63,4,0,
267,6,71,1,1,
267,6,71,1,10,
267,6,72,1,24,
267,6,76,4,0,
267,6,78,1,17,
267,6,92,4,0,
267,6,94,4,0,
267,6,102,3,0,
267,6,104,4,0,
267,6,129,3,0,
267,6,148,4,0,
267,6,156,4,0,
267,6,164,3,0,
267,6,168,4,0,
267,6,173,3,0,
267,6,182,4,0,
267,6,202,1,38,
267,6,202,4,0,
267,6,203,3,0,
267,6,207,3,0,
267,6,213,1,31,
267,6,213,4,0,
267,6,214,3,0,
267,6,216,4,0,
267,6,218,4,0,
267,6,219,4,0,
267,6,234,1,20,
267,6,237,4,0,
267,6,241,4,0,
267,6,247,4,0,
267,6,263,4,0,
267,6,290,4,0,
267,6,318,1,34,
267,6,332,4,0,
267,7,16,1,13,
267,7,18,1,27,
267,7,38,3,0,
267,7,63,4,0,
267,7,71,1,1,
267,7,71,1,10,
267,7,72,1,24,
267,7,76,4,0,
267,7,78,1,17,
267,7,92,4,0,
267,7,94,4,0,
267,7,102,3,0,
267,7,104,4,0,
267,7,148,4,0,
267,7,156,4,0,
267,7,164,3,0,
267,7,168,4,0,
267,7,182,4,0,
267,7,202,1,38,
267,7,202,4,0,
267,7,213,1,31,
267,7,213,4,0,
267,7,216,4,0,
267,7,218,4,0,
267,7,219,4,0,
267,7,234,1,20,
267,7,237,4,0,
267,7,241,4,0,
267,7,247,4,0,
267,7,263,4,0,
267,7,290,4,0,
267,7,318,1,34,
267,7,332,4,0,
267,8,16,1,13,
267,8,18,1,27,
267,8,63,4,0,
267,8,71,1,1,
267,8,71,1,10,
267,8,72,1,24,
267,8,76,4,0,
267,8,78,1,17,
267,8,92,4,0,
267,8,94,4,0,
267,8,104,4,0,
267,8,148,4,0,
267,8,156,4,0,
267,8,164,4,0,
267,8,168,4,0,
267,8,182,4,0,
267,8,202,1,38,
267,8,202,4,0,
267,8,203,4,0,
267,8,207,4,0,
267,8,213,1,31,
267,8,213,4,0,
267,8,214,4,0,
267,8,216,4,0,
267,8,218,4,0,
267,8,219,4,0,
267,8,234,1,20,
267,8,237,4,0,
267,8,241,4,0,
267,8,247,4,0,
267,8,263,4,0,
267,8,290,4,0,
267,8,318,1,34,
267,8,318,4,0,
267,8,332,4,0,
267,8,355,4,0,
267,8,363,4,0,
267,8,369,4,0,
267,8,405,1,41,
267,8,412,4,0,
267,8,416,4,0,
267,8,432,4,0,
267,8,445,4,0,
267,9,16,1,13,
267,9,18,1,27,
267,9,63,4,0,
267,9,71,1,1,
267,9,71,1,10,
267,9,72,1,24,
267,9,76,4,0,
267,9,78,1,17,
267,9,92,4,0,
267,9,94,4,0,
267,9,104,4,0,
267,9,129,3,0,
267,9,148,4,0,
267,9,156,4,0,
267,9,164,4,0,
267,9,168,4,0,
267,9,173,3,0,
267,9,182,4,0,
267,9,202,1,38,
267,9,202,4,0,
267,9,203,4,0,
267,9,207,4,0,
267,9,213,1,31,
267,9,213,4,0,
267,9,214,4,0,
267,9,216,4,0,
267,9,218,4,0,
267,9,219,4,0,
267,9,234,1,20,
267,9,237,4,0,
267,9,239,3,0,
267,9,241,4,0,
267,9,247,4,0,
267,9,263,4,0,
267,9,290,4,0,
267,9,314,3,0,
267,9,318,1,34,
267,9,318,4,0,
267,9,324,3,0,
267,9,332,4,0,
267,9,355,4,0,
267,9,363,4,0,
267,9,369,4,0,
267,9,405,1,41,
267,9,412,4,0,
267,9,416,4,0,
267,9,432,4,0,
267,9,445,4,0,
267,9,466,3,0,
267,10,16,1,13,
267,10,18,1,27,
267,10,63,4,0,
267,10,71,1,1,
267,10,71,1,10,
267,10,72,1,24,
267,10,76,4,0,
267,10,78,1,17,
267,10,81,3,0,
267,10,92,4,0,
267,10,94,4,0,
267,10,104,4,0,
267,10,129,3,0,
267,10,148,4,0,
267,10,156,4,0,
267,10,164,4,0,
267,10,168,4,0,
267,10,173,3,0,
267,10,182,4,0,
267,10,202,1,38,
267,10,202,4,0,
267,10,203,4,0,
267,10,207,4,0,
267,10,213,1,31,
267,10,213,4,0,
267,10,214,4,0,
267,10,216,4,0,
267,10,218,4,0,
267,10,219,4,0,
267,10,234,1,20,
267,10,237,4,0,
267,10,239,3,0,
267,10,241,4,0,
267,10,247,4,0,
267,10,263,4,0,
267,10,290,4,0,
267,10,314,3,0,
267,10,318,1,34,
267,10,318,4,0,
267,10,324,3,0,
267,10,332,4,0,
267,10,355,4,0,
267,10,363,4,0,
267,10,366,3,0,
267,10,369,4,0,
267,10,405,1,41,
267,10,412,4,0,
267,10,416,4,0,
267,10,445,4,0,
267,10,450,3,0,
267,10,466,3,0,
267,11,16,1,13,
267,11,18,1,27,
267,11,63,4,0,
267,11,71,1,1,
267,11,71,1,10,
267,11,72,1,24,
267,11,76,4,0,
267,11,78,1,17,
267,11,92,4,0,
267,11,94,4,0,
267,11,104,4,0,
267,11,148,4,0,
267,11,156,4,0,
267,11,164,4,0,
267,11,168,4,0,
267,11,182,4,0,
267,11,202,1,38,
267,11,207,4,0,
267,11,213,1,31,
267,11,213,4,0,
267,11,216,4,0,
267,11,218,4,0,
267,11,219,4,0,
267,11,234,1,20,
267,11,237,4,0,
267,11,241,4,0,
267,11,247,4,0,
267,11,263,4,0,
267,11,318,1,34,
267,11,332,4,0,
267,11,369,4,0,
267,11,405,1,41,
267,11,412,4,0,
267,11,416,4,0,
267,11,474,4,0,
267,11,483,1,45,
267,11,496,4,0,
267,11,512,4,0,
267,11,522,4,0,
267,12,16,1,13,
267,12,18,1,27,
267,12,63,4,0,
267,12,71,1,1,
267,12,71,1,10,
267,12,72,1,24,
267,12,76,4,0,
267,12,78,1,17,
267,12,92,4,0,
267,12,94,4,0,
267,12,104,4,0,
267,12,148,4,0,
267,12,156,4,0,
267,12,168,4,0,
267,12,182,4,0,
267,12,202,1,38,
267,12,202,4,0,
267,12,213,1,31,
267,12,213,4,0,
267,12,216,4,0,
267,12,218,4,0,
267,12,219,4,0,
267,12,234,1,20,
267,12,237,4,0,
267,12,241,4,0,
267,12,247,4,0,
267,12,263,4,0,
267,12,290,4,0,
267,12,318,1,34,
267,12,332,4,0,
267,13,16,1,13,
267,13,18,1,27,
267,13,38,3,0,
267,13,63,4,0,
267,13,71,1,1,
267,13,71,1,10,
267,13,72,1,24,
267,13,76,4,0,
267,13,78,1,17,
267,13,92,4,0,
267,13,94,4,0,
267,13,102,3,0,
267,13,104,4,0,
267,13,148,4,0,
267,13,156,4,0,
267,13,164,3,0,
267,13,168,4,0,
267,13,182,4,0,
267,13,202,1,38,
267,13,202,4,0,
267,13,207,3,0,
267,13,213,1,31,
267,13,213,4,0,
267,13,216,4,0,
267,13,218,4,0,
267,13,219,4,0,
267,13,234,1,20,
267,13,237,4,0,
267,13,241,4,0,
267,13,247,4,0,
267,13,263,4,0,
267,13,290,4,0,
267,13,318,1,34,
267,13,332,4,0,
267,14,16,1,13,
267,14,18,1,27,
267,14,63,4,0,
267,14,71,1,1,
267,14,71,1,10,
267,14,72,1,24,
267,14,76,4,0,
267,14,78,1,17,
267,14,92,4,0,
267,14,94,4,0,
267,14,104,4,0,
267,14,148,4,0,
267,14,156,4,0,
267,14,164,4,0,
267,14,168,4,0,
267,14,173,3,0,
267,14,182,4,0,
267,14,202,1,38,
267,14,202,3,0,
267,14,207,4,0,
267,14,213,1,31,
267,14,213,4,0,
267,14,214,3,0,
267,14,216,4,0,
267,14,218,4,0,
267,14,219,4,0,
267,14,234,1,20,
267,14,237,4,0,
267,14,241,4,0,
267,14,247,4,0,
267,14,263,4,0,
267,14,318,1,34,
267,14,324,3,0,
267,14,332,4,0,
267,14,355,3,0,
267,14,366,3,0,
267,14,369,4,0,
267,14,405,1,41,
267,14,412,4,0,
267,14,416,4,0,
267,14,450,3,0,
267,14,474,4,0,
267,14,483,1,45,
267,14,496,4,0,
267,14,512,4,0,
267,14,522,4,0,
267,14,527,3,0,
267,15,16,1,13,
267,15,18,1,27,
267,15,63,4,0,
267,15,71,1,1,
267,15,71,1,10,
267,15,72,1,24,
267,15,76,4,0,
267,15,78,1,17,
267,15,92,4,0,
267,15,94,4,0,
267,15,104,4,0,
267,15,148,4,0,
267,15,156,4,0,
267,15,164,4,0,
267,15,168,4,0,
267,15,182,4,0,
267,15,202,1,38,
267,15,207,4,0,
267,15,213,1,31,
267,15,213,4,0,
267,15,214,4,0,
267,15,216,4,0,
267,15,218,4,0,
267,15,219,4,0,
267,15,234,1,20,
267,15,237,4,0,
267,15,241,4,0,
267,15,247,4,0,
267,15,263,4,0,
267,15,318,1,34,
267,15,332,4,0,
267,15,355,4,0,
267,15,369,4,0,
267,15,405,1,41,
267,15,412,4,0,
267,15,416,4,0,
267,15,474,4,0,
267,15,483,1,45,
267,15,496,4,0,
267,15,512,4,0,
267,15,522,4,0,
267,15,590,4,0,
267,15,611,4,0,
267,16,16,1,1,1
267,16,16,1,10,1
267,16,18,1,30,1
267,16,63,4,0,
267,16,71,1,12,1
267,16,72,1,22,1
267,16,76,4,0,
267,16,78,1,15,1
267,16,92,4,0,
267,16,94,4,0,
267,16,99,1,37,1
267,16,104,4,0,
267,16,148,4,0,
267,16,156,4,0,
267,16,164,4,0,
267,16,168,4,0,
267,16,173,3,0,
267,16,182,4,0,
267,16,202,1,32,1
267,16,202,3,0,
267,16,207,4,0,
267,16,213,1,27,1
267,16,213,4,0,
267,16,214,4,0,
267,16,216,4,0,
267,16,218,4,0,
267,16,219,4,0,
267,16,234,1,17,1
267,16,237,4,0,
267,16,241,4,0,
267,16,247,4,0,
267,16,263,4,0,
267,16,290,4,0,
267,16,314,1,20,1
267,16,318,1,25,1
267,16,324,3,0,
267,16,332,4,0,
267,16,355,4,0,
267,16,366,3,0,
267,16,369,4,0,
267,16,405,1,35,1
267,16,412,4,0,
267,16,416,4,0,
267,16,450,3,0,
267,16,474,4,0,
267,16,483,1,40,1
267,16,496,4,0,
267,16,512,4,0,
267,16,522,4,0,
267,16,527,3,0,
267,16,590,4,0,
267,16,611,4,0,
267,17,16,1,0,
267,17,16,1,1,
267,17,18,1,30,
267,17,63,4,0,
267,17,71,1,12,
267,17,72,1,22,
267,17,76,4,0,
267,17,78,1,15,
267,17,92,4,0,
267,17,94,4,0,
267,17,99,1,37,
267,17,104,4,0,
267,17,156,4,0,
267,17,164,4,0,
267,17,168,4,0,
267,17,182,4,0,
267,17,202,1,32,
267,17,207,4,0,
267,17,213,1,27,
267,17,213,4,0,
267,17,214,4,0,
267,17,216,4,0,
267,17,218,4,0,
267,17,219,4,0,
267,17,234,1,17,
267,17,237,4,0,
267,17,241,4,0,
267,17,247,4,0,
267,17,263,4,0,
267,17,314,1,20,
267,17,318,1,25,
267,17,332,4,0,
267,17,355,4,0,
267,17,369,4,0,
267,17,405,1,35,
267,17,412,4,0,
267,17,416,4,0,
267,17,474,4,0,
267,17,483,1,40,
267,17,496,4,0,
267,17,512,4,0,
267,17,590,4,0,
267,17,611,4,0,
267,18,16,1,0,
267,18,16,1,1,
267,18,18,1,30,
267,18,63,4,0,
267,18,71,1,12,
267,18,72,1,22,
267,18,76,4,0,
267,18,78,1,15,
267,18,92,4,0,
267,18,94,4,0,
267,18,99,1,37,
267,18,104,4,0,
267,18,156,4,0,
267,18,164,4,0,
267,18,168,4,0,
267,18,182,4,0,
267,18,202,1,32,
267,18,207,4,0,
267,18,213,1,27,
267,18,213,4,0,
267,18,214,4,0,
267,18,216,4,0,
267,18,218,4,0,
267,18,219,4,0,
267,18,234,1,17,
267,18,237,4,0,
267,18,241,4,0,
267,18,247,4,0,
267,18,263,4,0,
267,18,314,1,20,
267,18,318,1,25,
267,18,332,4,0,
267,18,355,4,0,
267,18,369,4,0,
267,18,405,1,35,
267,18,412,4,0,
267,18,416,4,0,
267,18,474,4,0,
267,18,483,1,40,
267,18,496,4,0,
267,18,512,4,0,
267,18,590,4,0,
267,18,611,4,0,
268,5,106,1,1,
268,5,106,1,7,
268,6,106,1,1,
268,6,106,1,7,
268,7,106,1,1,
268,7,106,1,7,
268,8,106,1,1,
268,8,106,1,7,
268,9,106,1,1,
268,9,106,1,7,
268,9,334,3,0,
268,10,81,3,0,
268,10,106,1,1,
268,10,106,1,7,
268,10,334,3,0,
268,10,450,3,0,
268,11,106,1,1,
268,11,106,1,7,
268,12,106,1,1,
268,12,106,1,7,
268,13,106,1,1,
268,13,106,1,7,
268,14,106,1,1,
268,14,106,1,7,
268,14,334,3,0,
268,14,450,3,0,
268,14,527,3,0,
268,15,106,1,1,
268,15,106,1,7,
268,16,106,1,1,1
268,16,106,1,7,1
268,16,334,3,0,
268,16,450,3,0,
268,16,527,3,0,
268,17,106,1,0,
268,17,106,1,1,
268,18,106,1,0,
268,18,106,1,1,
269,5,16,1,13,
269,5,18,1,27,
269,5,60,1,24,
269,5,63,4,0,
269,5,76,4,0,
269,5,92,1,38,
269,5,92,4,0,
269,5,93,1,1,
269,5,93,1,10,
269,5,94,4,0,
269,5,104,4,0,
269,5,113,1,31,
269,5,113,4,0,
269,5,148,4,0,
269,5,156,4,0,
269,5,168,4,0,
269,5,182,1,17,
269,5,182,4,0,
269,5,188,4,0,
269,5,202,4,0,
269,5,213,4,0,
269,5,216,4,0,
269,5,218,4,0,
269,5,236,1,20,
269,5,237,4,0,
269,5,241,4,0,
269,5,247,4,0,
269,5,263,4,0,
269,5,290,4,0,
269,5,318,1,34,
269,5,332,4,0,
269,6,16,1,13,
269,6,18,1,27,
269,6,38,3,0,
269,6,60,1,24,
269,6,63,4,0,
269,6,76,4,0,
269,6,92,1,38,
269,6,92,4,0,
269,6,93,1,1,
269,6,93,1,10,
269,6,94,4,0,
269,6,102,3,0,
269,6,104,4,0,
269,6,113,1,31,
269,6,113,4,0,
269,6,129,3,0,
269,6,148,4,0,
269,6,156,4,0,
269,6,164,3,0,
269,6,168,4,0,
269,6,173,3,0,
269,6,182,1,17,
269,6,182,4,0,
269,6,188,4,0,
269,6,202,4,0,
269,6,203,3,0,
269,6,207,3,0,
269,6,213,4,0,
269,6,214,3,0,
269,6,216,4,0,
269,6,218,4,0,
269,6,236,1,20,
269,6,237,4,0,
269,6,241,4,0,
269,6,247,4,0,
269,6,263,4,0,
269,6,290,4,0,
269,6,318,1,34,
269,6,332,4,0,
269,7,16,1,13,
269,7,18,1,27,
269,7,38,3,0,
269,7,60,1,24,
269,7,63,4,0,
269,7,76,4,0,
269,7,92,1,38,
269,7,92,4,0,
269,7,93,1,1,
269,7,93,1,10,
269,7,94,4,0,
269,7,102,3,0,
269,7,104,4,0,
269,7,113,1,31,
269,7,113,4,0,
269,7,148,4,0,
269,7,156,4,0,
269,7,164,3,0,
269,7,168,4,0,
269,7,182,1,17,
269,7,182,4,0,
269,7,188,4,0,
269,7,202,4,0,
269,7,213,4,0,
269,7,216,4,0,
269,7,218,4,0,
269,7,236,1,20,
269,7,237,4,0,
269,7,241,4,0,
269,7,247,4,0,
269,7,263,4,0,
269,7,290,4,0,
269,7,318,1,34,
269,7,332,4,0,
269,8,16,1,13,
269,8,18,1,27,
269,8,60,1,24,
269,8,63,4,0,
269,8,76,4,0,
269,8,92,1,38,
269,8,92,4,0,
269,8,93,1,1,
269,8,93,1,10,
269,8,94,4,0,
269,8,104,4,0,
269,8,113,1,31,
269,8,113,4,0,
269,8,148,4,0,
269,8,156,4,0,
269,8,164,4,0,
269,8,168,4,0,
269,8,182,1,17,
269,8,182,4,0,
269,8,188,4,0,
269,8,202,4,0,
269,8,203,4,0,
269,8,207,4,0,
269,8,213,4,0,
269,8,214,4,0,
269,8,216,4,0,
269,8,218,4,0,
269,8,236,1,20,
269,8,237,4,0,
269,8,241,4,0,
269,8,247,4,0,
269,8,263,4,0,
269,8,290,4,0,
269,8,318,1,34,
269,8,318,4,0,
269,8,332,4,0,
269,8,355,4,0,
269,8,363,4,0,
269,8,369,4,0,
269,8,405,1,41,
269,8,412,4,0,
269,8,416,4,0,
269,8,432,4,0,
269,8,445,4,0,
269,9,16,1,13,
269,9,18,1,27,
269,9,60,1,24,
269,9,63,4,0,
269,9,76,4,0,
269,9,92,1,38,
269,9,92,4,0,
269,9,93,1,1,
269,9,93,1,10,
269,9,94,4,0,
269,9,104,4,0,
269,9,113,1,31,
269,9,113,4,0,
269,9,129,3,0,
269,9,148,4,0,
269,9,156,4,0,
269,9,164,4,0,
269,9,168,4,0,
269,9,173,3,0,
269,9,182,1,17,
269,9,182,4,0,
269,9,188,4,0,
269,9,202,4,0,
269,9,203,4,0,
269,9,207,4,0,
269,9,213,4,0,
269,9,214,4,0,
269,9,216,4,0,
269,9,218,4,0,
269,9,236,1,20,
269,9,237,4,0,
269,9,239,3,0,
269,9,241,4,0,
269,9,247,4,0,
269,9,263,4,0,
269,9,290,4,0,
269,9,314,3,0,
269,9,318,1,34,
269,9,318,4,0,
269,9,324,3,0,
269,9,332,4,0,
269,9,355,4,0,
269,9,363,4,0,
269,9,369,4,0,
269,9,405,1,41,
269,9,412,4,0,
269,9,416,4,0,
269,9,432,4,0,
269,9,445,4,0,
269,9,466,3,0,
269,10,16,1,13,
269,10,18,1,27,
269,10,60,1,24,
269,10,63,4,0,
269,10,76,4,0,
269,10,81,3,0,
269,10,92,1,38,
269,10,92,4,0,
269,10,93,1,1,
269,10,93,1,10,
269,10,94,4,0,
269,10,104,4,0,
269,10,113,1,31,
269,10,113,4,0,
269,10,129,3,0,
269,10,148,4,0,
269,10,156,4,0,
269,10,164,4,0,
269,10,168,4,0,
269,10,173,3,0,
269,10,182,1,17,
269,10,182,4,0,
269,10,188,4,0,
269,10,202,4,0,
269,10,203,4,0,
269,10,207,4,0,
269,10,213,4,0,
269,10,214,4,0,
269,10,216,4,0,
269,10,218,4,0,
269,10,236,1,20,
269,10,237,4,0,
269,10,239,3,0,
269,10,241,4,0,
269,10,247,4,0,
269,10,263,4,0,
269,10,290,4,0,
269,10,314,3,0,
269,10,318,1,34,
269,10,318,4,0,
269,10,324,3,0,
269,10,332,4,0,
269,10,355,4,0,
269,10,363,4,0,
269,10,366,3,0,
269,10,369,4,0,
269,10,405,1,41,
269,10,412,4,0,
269,10,416,4,0,
269,10,445,4,0,
269,10,450,3,0,
269,10,466,3,0,
269,11,16,1,13,
269,11,18,1,27,
269,11,60,1,24,
269,11,63,4,0,
269,11,76,4,0,
269,11,92,1,38,
269,11,92,4,0,
269,11,93,1,1,
269,11,93,1,10,
269,11,94,4,0,
269,11,104,4,0,
269,11,113,1,31,
269,11,113,4,0,
269,11,148,4,0,
269,11,156,4,0,
269,11,164,4,0,
269,11,168,4,0,
269,11,182,1,17,
269,11,182,4,0,
269,11,188,4,0,
269,11,207,4,0,
269,11,213,4,0,
269,11,216,4,0,
269,11,218,4,0,
269,11,236,1,20,
269,11,237,4,0,
269,11,241,4,0,
269,11,247,4,0,
269,11,263,4,0,
269,11,318,1,34,
269,11,332,4,0,
269,11,369,4,0,
269,11,405,1,41,
269,11,412,4,0,
269,11,416,4,0,
269,11,474,4,0,
269,11,483,1,45,
269,11,496,4,0,
269,11,512,4,0,
269,11,522,4,0,
269,12,16,1,13,
269,12,18,1,27,
269,12,60,1,24,
269,12,63,4,0,
269,12,76,4,0,
269,12,92,1,38,
269,12,92,4,0,
269,12,93,1,1,
269,12,93,1,10,
269,12,94,4,0,
269,12,104,4,0,
269,12,113,1,31,
269,12,113,4,0,
269,12,148,4,0,
269,12,156,4,0,
269,12,168,4,0,
269,12,182,1,17,
269,12,182,4,0,
269,12,188,4,0,
269,12,202,4,0,
269,12,213,4,0,
269,12,216,4,0,
269,12,218,4,0,
269,12,236,1,20,
269,12,237,4,0,
269,12,241,4,0,
269,12,247,4,0,
269,12,263,4,0,
269,12,290,4,0,
269,12,318,1,34,
269,12,332,4,0,
269,13,16,1,13,
269,13,18,1,27,
269,13,38,3,0,
269,13,60,1,24,
269,13,63,4,0,
269,13,76,4,0,
269,13,92,1,38,
269,13,92,4,0,
269,13,93,1,1,
269,13,93,1,10,
269,13,94,4,0,
269,13,102,3,0,
269,13,104,4,0,
269,13,113,1,31,
269,13,113,4,0,
269,13,148,4,0,
269,13,156,4,0,
269,13,164,3,0,
269,13,168,4,0,
269,13,182,1,17,
269,13,182,4,0,
269,13,188,4,0,
269,13,202,4,0,
269,13,207,3,0,
269,13,213,4,0,
269,13,216,4,0,
269,13,218,4,0,
269,13,236,1,20,
269,13,237,4,0,
269,13,241,4,0,
269,13,247,4,0,
269,13,263,4,0,
269,13,290,4,0,
269,13,318,1,34,
269,13,332,4,0,
269,14,16,1,13,
269,14,18,1,27,
269,14,60,1,24,
269,14,63,4,0,
269,14,76,4,0,
269,14,92,1,38,
269,14,92,4,0,
269,14,93,1,1,
269,14,93,1,10,
269,14,94,4,0,
269,14,104,4,0,
269,14,113,1,31,
269,14,113,4,0,
269,14,148,4,0,
269,14,156,4,0,
269,14,164,4,0,
269,14,168,4,0,
269,14,173,3,0,
269,14,182,1,17,
269,14,182,4,0,
269,14,188,4,0,
269,14,202,3,0,
269,14,207,4,0,
269,14,213,4,0,
269,14,214,3,0,
269,14,216,4,0,
269,14,218,4,0,
269,14,236,1,20,
269,14,237,4,0,
269,14,241,4,0,
269,14,247,4,0,
269,14,263,4,0,
269,14,318,1,34,
269,14,324,3,0,
269,14,332,4,0,
269,14,355,3,0,
269,14,366,3,0,
269,14,369,4,0,
269,14,405,1,41,
269,14,412,4,0,
269,14,416,4,0,
269,14,450,3,0,
269,14,474,4,0,
269,14,483,1,45,
269,14,496,4,0,
269,14,512,4,0,
269,14,522,4,0,
269,14,527,3,0,
269,15,16,1,13,
269,15,18,1,27,
269,15,60,1,24,
269,15,63,4,0,
269,15,76,4,0,
269,15,92,1,38,
269,15,92,4,0,
269,15,93,1,1,
269,15,93,1,10,
269,15,94,4,0,
269,15,104,4,0,
269,15,113,1,31,
269,15,113,4,0,
269,15,148,4,0,
269,15,156,4,0,
269,15,164,4,0,
269,15,168,4,0,
269,15,182,1,17,
269,15,182,4,0,
269,15,188,4,0,
269,15,207,4,0,
269,15,213,4,0,
269,15,214,4,0,
269,15,216,4,0,
269,15,218,4,0,
269,15,236,1,20,
269,15,237,4,0,
269,15,241,4,0,
269,15,247,4,0,
269,15,263,4,0,
269,15,318,1,34,
269,15,332,4,0,
269,15,355,4,0,
269,15,369,4,0,
269,15,405,1,41,
269,15,412,4,0,
269,15,416,4,0,
269,15,474,4,0,
269,15,483,1,45,
269,15,496,4,0,
269,15,512,4,0,
269,15,522,4,0,
269,15,590,4,0,
269,15,611,4,0,
269,16,16,1,1,1
269,16,16,1,10,1
269,16,18,1,30,1
269,16,60,1,22,1
269,16,63,4,0,
269,16,76,4,0,
269,16,77,1,15,1
269,16,92,1,32,1
269,16,92,4,0,
269,16,93,1,12,1
269,16,94,4,0,
269,16,104,4,0,
269,16,113,1,27,1
269,16,113,4,0,
269,16,148,4,0,
269,16,156,4,0,
269,16,164,4,0,
269,16,168,4,0,
269,16,173,3,0,
269,16,182,1,37,1
269,16,182,4,0,
269,16,188,4,0,
269,16,202,3,0,
269,16,207,4,0,
269,16,213,4,0,
269,16,214,4,0,
269,16,216,4,0,
269,16,218,4,0,
269,16,236,1,17,1
269,16,237,4,0,
269,16,241,4,0,
269,16,247,4,0,
269,16,263,4,0,
269,16,290,4,0,
269,16,318,1,25,1
269,16,324,3,0,
269,16,332,4,0,
269,16,355,4,0,
269,16,366,3,0,
269,16,369,4,0,
269,16,405,1,35,1
269,16,412,4,0,
269,16,416,4,0,
269,16,450,3,0,
269,16,474,1,20,1
269,16,474,4,0,
269,16,483,1,40,1
269,16,496,4,0,
269,16,512,4,0,
269,16,522,4,0,
269,16,527,3,0,
269,16,590,4,0,
269,16,611,4,0,
269,17,16,1,0,
269,17,16,1,1,
269,17,18,1,30,
269,17,60,1,22,
269,17,63,4,0,
269,17,76,4,0,
269,17,77,1,15,
269,17,92,1,32,
269,17,92,4,0,
269,17,93,1,12,
269,17,94,4,0,
269,17,104,4,0,
269,17,113,1,27,
269,17,113,4,0,
269,17,156,4,0,
269,17,164,4,0,
269,17,168,4,0,
269,17,182,1,37,
269,17,182,4,0,
269,17,188,4,0,
269,17,207,4,0,
269,17,213,4,0,
269,17,214,4,0,
269,17,216,4,0,
269,17,218,4,0,
269,17,236,1,17,
269,17,237,4,0,
269,17,241,4,0,
269,17,247,4,0,
269,17,263,4,0,
269,17,318,1,25,
269,17,332,4,0,
269,17,355,4,0,
269,17,369,4,0,
269,17,405,1,35,
269,17,412,4,0,
269,17,416,4,0,
269,17,474,1,20,
269,17,474,4,0,
269,17,483,1,40,
269,17,496,4,0,
269,17,512,4,0,
269,17,590,4,0,
269,17,611,4,0,
269,18,16,1,0,
269,18,16,1,1,
269,18,18,1,30,
269,18,60,1,22,
269,18,63,4,0,
269,18,76,4,0,
269,18,77,1,15,
269,18,92,1,32,
269,18,92,4,0,
269,18,93,1,12,
269,18,94,4,0,
269,18,104,4,0,
269,18,113,1,27,
269,18,113,4,0,
269,18,156,4,0,
269,18,164,4,0,
269,18,168,4,0,
269,18,182,1,37,
269,18,182,4,0,
269,18,188,4,0,
269,18,207,4,0,
269,18,213,4,0,
269,18,214,4,0,
269,18,216,4,0,
269,18,218,4,0,
269,18,236,1,17,
269,18,237,4,0,
269,18,241,4,0,
269,18,247,4,0,
269,18,263,4,0,
269,18,318,1,25,
269,18,332,4,0,
269,18,355,4,0,
269,18,369,4,0,
269,18,405,1,35,
269,18,412,4,0,
269,18,416,4,0,
269,18,474,1,20,
269,18,474,4,0,
269,18,483,1,40,
269,18,496,4,0,
269,18,512,4,0,
269,18,590,4,0,
269,18,611,4,0,
270,5,45,1,3,
270,5,54,1,21,
270,5,55,2,0,
270,5,57,4,0,
270,5,58,4,0,
270,5,59,4,0,
270,5,71,1,7,
270,5,72,1,43,
270,5,73,2,0,
270,5,75,2,0,
270,5,76,4,0,
270,5,92,4,0,
270,5,104,4,0,
270,5,148,4,0,
270,5,156,4,0,
270,5,168,4,0,
270,5,175,2,0,
270,5,182,4,0,
270,5,202,4,0,
270,5,213,4,0,
270,5,216,4,0,
270,5,218,4,0,
270,5,230,2,0,
270,5,235,2,0,
270,5,237,4,0,
270,5,240,1,31,
270,5,240,4,0,
270,5,241,4,0,
270,5,258,4,0,
270,5,263,4,0,
270,5,267,1,13,
270,5,290,4,0,
270,5,310,1,1,
270,5,331,4,0,
270,5,352,4,0,
270,6,14,3,0,
270,6,34,3,0,
270,6,38,3,0,
270,6,45,1,3,
270,6,54,1,21,
270,6,55,2,0,
270,6,57,4,0,
270,6,58,4,0,
270,6,59,4,0,
270,6,71,1,7,
270,6,72,1,43,
270,6,73,2,0,
270,6,75,2,0,
270,6,76,4,0,
270,6,92,4,0,
270,6,102,3,0,
270,6,104,4,0,
270,6,148,4,0,
270,6,156,4,0,
270,6,164,3,0,
270,6,168,4,0,
270,6,173,3,0,
270,6,175,2,0,
270,6,182,4,0,
270,6,196,3,0,
270,6,202,4,0,
270,6,203,3,0,
270,6,207,3,0,
270,6,213,4,0,
270,6,214,3,0,
270,6,216,4,0,
270,6,218,4,0,
270,6,230,2,0,
270,6,235,2,0,
270,6,237,4,0,
270,6,240,1,31,
270,6,240,4,0,
270,6,241,4,0,
270,6,258,4,0,
270,6,263,4,0,
270,6,267,1,13,
270,6,290,4,0,
270,6,310,1,1,
270,6,331,4,0,
270,6,352,4,0,
270,7,14,3,0,
270,7,34,3,0,
270,7,38,3,0,
270,7,45,1,3,
270,7,54,1,21,
270,7,55,2,0,
270,7,57,4,0,
270,7,58,4,0,
270,7,59,4,0,
270,7,71,1,7,
270,7,72,1,43,
270,7,73,2,0,
270,7,75,2,0,
270,7,76,4,0,
270,7,92,4,0,
270,7,102,3,0,
270,7,104,4,0,
270,7,148,4,0,
270,7,156,4,0,
270,7,164,3,0,
270,7,168,4,0,
270,7,175,2,0,
270,7,182,4,0,
270,7,202,4,0,
270,7,213,4,0,
270,7,216,4,0,
270,7,218,4,0,
270,7,230,2,0,
270,7,235,2,0,
270,7,237,4,0,
270,7,240,1,31,
270,7,240,4,0,
270,7,241,4,0,
270,7,258,4,0,
270,7,263,4,0,
270,7,267,1,13,
270,7,290,4,0,
270,7,310,1,1,
270,7,331,4,0,
270,7,352,4,0,
270,8,14,4,0,
270,8,45,1,3,
270,8,54,1,11,
270,8,55,2,0,
270,8,57,4,0,
270,8,58,4,0,
270,8,59,4,0,
270,8,71,1,5,
270,8,72,1,19,
270,8,73,2,0,
270,8,75,2,0,
270,8,76,4,0,
270,8,92,4,0,
270,8,104,4,0,
270,8,148,4,0,
270,8,156,4,0,
270,8,164,4,0,
270,8,168,4,0,
270,8,175,2,0,
270,8,182,4,0,
270,8,202,4,0,
270,8,203,4,0,
270,8,207,4,0,
270,8,213,4,0,
270,8,214,4,0,
270,8,216,4,0,
270,8,218,4,0,
270,8,230,2,0,
270,8,235,2,0,
270,8,237,4,0,
270,8,240,1,35,
270,8,240,4,0,
270,8,241,4,0,
270,8,258,4,0,
270,8,263,4,0,
270,8,267,1,7,
270,8,290,4,0,
270,8,310,1,1,
270,8,321,2,0,
270,8,331,4,0,
270,8,352,4,0,
270,8,363,1,15,
270,8,363,4,0,
270,8,412,1,43,
270,8,412,4,0,
270,8,428,1,27,
270,8,445,4,0,
270,8,447,4,0,
270,9,14,4,0,
270,9,45,1,3,
270,9,54,1,11,
270,9,55,2,0,
270,9,57,4,0,
270,9,58,4,0,
270,9,59,4,0,
270,9,61,1,25,
270,9,71,1,5,
270,9,72,1,19,
270,9,73,2,0,
270,9,75,2,0,
270,9,76,4,0,
270,9,92,4,0,
270,9,104,4,0,
270,9,148,4,0,
270,9,156,4,0,
270,9,164,4,0,
270,9,168,4,0,
270,9,173,3,0,
270,9,175,2,0,
270,9,182,4,0,
270,9,196,3,0,
270,9,202,4,0,
270,9,203,4,0,
270,9,207,4,0,
270,9,213,4,0,
270,9,214,4,0,
270,9,216,4,0,
270,9,218,4,0,
270,9,230,2,0,
270,9,235,2,0,
270,9,235,3,0,
270,9,237,4,0,
270,9,240,1,37,
270,9,240,4,0,
270,9,241,4,0,
270,9,253,3,0,
270,9,258,4,0,
270,9,263,4,0,
270,9,267,1,7,
270,9,290,4,0,
270,9,310,1,1,
270,9,321,2,0,
270,9,331,4,0,
270,9,352,4,0,
270,9,363,1,15,
270,9,363,4,0,
270,9,402,3,0,
270,9,412,1,45,
270,9,412,4,0,
270,9,428,1,31,
270,9,428,3,0,
270,9,445,4,0,
270,9,447,4,0,
270,10,14,4,0,
270,10,29,3,0,
270,10,45,1,3,
270,10,54,1,11,
270,10,55,2,0,
270,10,57,4,0,
270,10,58,4,0,
270,10,59,4,0,
270,10,61,1,25,
270,10,68,2,0,
270,10,71,1,5,
270,10,72,1,19,
270,10,73,2,0,
270,10,75,2,0,
270,10,76,4,0,
270,10,92,4,0,
270,10,104,4,0,
270,10,148,4,0,
270,10,156,4,0,
270,10,164,4,0,
270,10,168,4,0,
270,10,173,3,0,
270,10,175,2,0,
270,10,182,4,0,
270,10,196,3,0,
270,10,202,4,0,
270,10,203,4,0,
270,10,207,4,0,
270,10,213,4,0,
270,10,214,4,0,
270,10,216,4,0,
270,10,218,4,0,
270,10,230,2,0,
270,10,235,2,0,
270,10,235,3,0,
270,10,237,4,0,
270,10,240,1,37,
270,10,240,4,0,
270,10,241,4,0,
270,10,250,4,0,
270,10,253,3,0,
270,10,258,4,0,
270,10,263,4,0,
270,10,267,1,7,
270,10,290,4,0,
270,10,310,1,1,
270,10,321,2,0,
270,10,331,4,0,
270,10,352,4,0,
270,10,363,1,15,
270,10,363,4,0,
270,10,402,3,0,
270,10,412,1,45,
270,10,412,4,0,
270,10,428,1,31,
270,10,428,3,0,
270,10,445,4,0,
270,10,447,4,0,
270,11,14,4,0,
270,11,45,1,3,
270,11,54,1,11,
270,11,55,2,0,
270,11,57,4,0,
270,11,58,4,0,
270,11,59,4,0,
270,11,61,1,25,
270,11,68,2,0,
270,11,71,1,5,
270,11,72,1,19,
270,11,73,2,0,
270,11,75,2,0,
270,11,76,4,0,
270,11,92,4,0,
270,11,104,4,0,
270,11,148,4,0,
270,11,156,4,0,
270,11,164,4,0,
270,11,168,4,0,
270,11,175,2,0,
270,11,182,4,0,
270,11,202,2,0,
270,11,207,4,0,
270,11,213,4,0,
270,11,216,4,0,
270,11,218,4,0,
270,11,230,2,0,
270,11,235,2,0,
270,11,237,4,0,
270,11,240,1,37,
270,11,240,4,0,
270,11,241,4,0,
270,11,258,4,0,
270,11,263,4,0,
270,11,267,1,7,
270,11,298,2,0,
270,11,310,1,1,
270,11,321,2,0,
270,11,363,1,15,
270,11,412,1,45,
270,11,412,4,0,
270,11,428,1,31,
270,11,447,4,0,
270,11,496,4,0,
270,11,497,4,0,
270,11,503,4,0,
270,12,45,1,3,
270,12,54,1,21,
270,12,57,4,0,
270,12,58,4,0,
270,12,59,4,0,
270,12,71,1,7,
270,12,72,1,43,
270,12,76,4,0,
270,12,92,4,0,
270,12,104,4,0,
270,12,148,4,0,
270,12,156,4,0,
270,12,168,4,0,
270,12,182,4,0,
270,12,202,4,0,
270,12,213,4,0,
270,12,216,4,0,
270,12,218,4,0,
270,12,237,4,0,
270,12,240,1,31,
270,12,240,4,0,
270,12,241,4,0,
270,12,258,4,0,
270,12,263,4,0,
270,12,267,1,13,
270,12,290,4,0,
270,12,310,1,1,
270,12,331,4,0,
270,12,352,4,0,
270,13,34,3,0,
270,13,38,3,0,
270,13,45,1,3,
270,13,54,1,21,
270,13,57,4,0,
270,13,58,4,0,
270,13,59,4,0,
270,13,71,1,7,
270,13,72,1,43,
270,13,76,4,0,
270,13,92,4,0,
270,13,102,3,0,
270,13,104,4,0,
270,13,148,4,0,
270,13,156,4,0,
270,13,164,3,0,
270,13,168,4,0,
270,13,182,4,0,
270,13,196,3,0,
270,13,202,4,0,
270,13,207,3,0,
270,13,213,4,0,
270,13,216,4,0,
270,13,218,4,0,
270,13,237,4,0,
270,13,240,1,31,
270,13,240,4,0,
270,13,241,4,0,
270,13,258,4,0,
270,13,263,4,0,
270,13,267,1,13,
270,13,290,4,0,
270,13,310,1,1,
270,13,331,4,0,
270,13,352,4,0,
270,14,14,4,0,
270,14,45,1,3,
270,14,54,1,11,
270,14,55,2,0,
270,14,57,4,0,
270,14,58,4,0,
270,14,59,4,0,
270,14,61,1,25,
270,14,68,2,0,
270,14,71,1,5,
270,14,72,1,19,
270,14,73,2,0,
270,14,75,2,0,
270,14,76,4,0,
270,14,92,4,0,
270,14,104,4,0,
270,14,148,4,0,
270,14,156,4,0,
270,14,164,4,0,
270,14,168,4,0,
270,14,173,3,0,
270,14,175,2,0,
270,14,182,4,0,
270,14,196,3,0,
270,14,202,2,0,
270,14,202,3,0,
270,14,207,4,0,
270,14,213,4,0,
270,14,214,3,0,
270,14,216,4,0,
270,14,218,4,0,
270,14,230,2,0,
270,14,235,2,0,
270,14,235,3,0,
270,14,237,4,0,
270,14,240,1,37,
270,14,240,4,0,
270,14,241,4,0,
270,14,253,3,0,
270,14,258,4,0,
270,14,263,4,0,
270,14,267,1,7,
270,14,298,2,0,
270,14,310,1,1,
270,14,321,2,0,
270,14,363,1,15,
270,14,402,3,0,
270,14,412,1,45,
270,14,412,4,0,
270,14,428,1,31,
270,14,428,3,0,
270,14,447,4,0,
270,14,496,4,0,
270,14,497,4,0,
270,14,503,4,0,
270,15,14,4,0,
270,15,45,1,3,
270,15,54,1,11,
270,15,55,2,0,
270,15,57,4,0,
270,15,58,4,0,
270,15,59,4,0,
270,15,61,1,25,
270,15,68,2,0,
270,15,71,1,5,
270,15,72,1,19,
270,15,73,2,0,
270,15,75,2,0,
270,15,76,4,0,
270,15,92,4,0,
270,15,104,4,0,
270,15,148,4,0,
270,15,156,4,0,
270,15,164,4,0,
270,15,168,4,0,
270,15,175,2,0,
270,15,182,4,0,
270,15,202,2,0,
270,15,207,4,0,
270,15,213,4,0,
270,15,214,4,0,
270,15,216,4,0,
270,15,218,4,0,
270,15,230,2,0,
270,15,235,2,0,
270,15,237,4,0,
270,15,240,1,37,
270,15,240,4,0,
270,15,241,4,0,
270,15,258,4,0,
270,15,263,4,0,
270,15,267,1,7,
270,15,267,4,0,
270,15,298,2,0,
270,15,310,1,1,
270,15,321,2,0,
270,15,363,1,15,
270,15,412,1,45,
270,15,412,4,0,
270,15,428,1,31,
270,15,447,4,0,
270,15,496,4,0,
270,15,497,4,0,
270,15,503,4,0,
270,15,590,4,0,
270,16,14,4,0,
270,16,45,1,3,1
270,16,54,1,15,1
270,16,55,2,0,
270,16,57,4,0,
270,16,58,4,0,
270,16,59,4,0,
270,16,61,1,21,1
270,16,68,2,0,
270,16,71,1,6,1
270,16,72,1,18,1
270,16,73,2,0,
270,16,75,2,0,
270,16,76,4,0,
270,16,92,4,0,
270,16,104,4,0,
270,16,145,1,9,1
270,16,148,4,0,
270,16,156,4,0,
270,16,164,4,0,
270,16,168,4,0,
270,16,173,3,0,
270,16,175,2,0,
270,16,182,4,0,
270,16,196,3,0,
270,16,202,1,30,1
270,16,202,2,0,
270,16,202,3,0,
270,16,207,4,0,
270,16,213,4,0,
270,16,214,4,0,
270,16,216,4,0,
270,16,218,4,0,
270,16,230,2,0,
270,16,235,2,0,
270,16,235,3,0,
270,16,237,4,0,
270,16,240,1,27,1
270,16,240,4,0,
270,16,241,4,0,
270,16,253,3,0,
270,16,258,4,0,
270,16,263,4,0,
270,16,267,1,24,1
270,16,267,4,0,
270,16,290,4,0,
270,16,298,2,0,
270,16,310,1,1,1
270,16,321,2,0,
270,16,352,3,0,
270,16,363,1,12,1
270,16,402,3,0,
270,16,412,1,36,1
270,16,412,4,0,
270,16,428,1,33,1
270,16,428,3,0,
270,16,447,4,0,
270,16,496,4,0,
270,16,497,4,0,
270,16,503,4,0,
270,16,590,4,0,
270,17,14,4,0,
270,17,45,1,3,
270,17,54,1,15,
270,17,55,2,0,
270,17,57,4,0,
270,17,58,4,0,
270,17,59,4,0,
270,17,61,1,21,
270,17,68,2,0,
270,17,71,1,6,
270,17,72,1,18,
270,17,73,2,0,
270,17,75,2,0,
270,17,76,4,0,
270,17,92,4,0,
270,17,104,4,0,
270,17,145,1,9,
270,17,156,4,0,
270,17,164,4,0,
270,17,168,4,0,
270,17,175,2,0,
270,17,182,4,0,
270,17,202,1,30,
270,17,202,2,0,
270,17,207,4,0,
270,17,213,4,0,
270,17,214,4,0,
270,17,216,4,0,
270,17,218,4,0,
270,17,230,2,0,
270,17,235,2,0,
270,17,237,4,0,
270,17,240,1,27,
270,17,240,4,0,
270,17,241,4,0,
270,17,258,4,0,
270,17,263,4,0,
270,17,267,1,24,
270,17,267,4,0,
270,17,298,2,0,
270,17,310,1,1,
270,17,321,2,0,
270,17,363,1,12,
270,17,412,1,36,
270,17,412,4,0,
270,17,428,1,33,
270,17,447,4,0,
270,17,496,4,0,
270,17,497,4,0,
270,17,503,4,0,
270,17,590,4,0,
270,18,14,4,0,
270,18,45,1,3,
270,18,54,1,15,
270,18,55,2,0,
270,18,57,4,0,
270,18,58,4,0,
270,18,59,4,0,
270,18,61,1,21,
270,18,68,2,0,
270,18,71,1,6,
270,18,72,1,18,
270,18,73,2,0,
270,18,75,2,0,
270,18,76,4,0,
270,18,92,4,0,
270,18,104,4,0,
270,18,145,1,9,
270,18,156,4,0,
270,18,164,4,0,
270,18,168,4,0,
270,18,175,2,0,
270,18,182,4,0,
270,18,202,1,30,
270,18,202,2,0,
270,18,207,4,0,
270,18,213,4,0,
270,18,214,4,0,
270,18,216,4,0,
270,18,218,4,0,
270,18,230,2,0,
270,18,235,2,0,
270,18,237,4,0,
270,18,240,1,27,
270,18,240,4,0,
270,18,241,4,0,
270,18,258,4,0,
270,18,263,4,0,
270,18,267,1,24,
270,18,267,4,0,
270,18,298,2,0,
270,18,310,1,1,
270,18,321,2,0,
270,18,363,1,12,
270,18,412,1,36,
270,18,412,4,0,
270,18,428,1,33,
270,18,447,4,0,
270,18,496,4,0,
270,18,497,4,0,
270,18,503,4,0,
270,18,590,4,0,
271,5,45,1,3,
271,5,56,1,49,
271,5,57,4,0,
271,5,58,4,0,
271,5,59,4,0,
271,5,70,4,0,
271,5,71,1,7,
271,5,76,4,0,
271,5,92,4,0,
271,5,104,4,0,
271,5,127,4,0,
271,5,148,4,0,
271,5,154,1,25,
271,5,156,4,0,
271,5,168,1,37,
271,5,168,4,0,
271,5,182,4,0,
271,5,202,4,0,
271,5,213,4,0,
271,5,216,4,0,
271,5,218,4,0,
271,5,237,4,0,
271,5,240,4,0,
271,5,241,4,0,
271,5,249,4,0,
271,5,252,1,19,
271,5,253,1,43,
271,5,258,4,0,
271,5,263,4,0,
271,5,267,1,13,
271,5,280,4,0,
271,5,290,4,0,
271,5,291,4,0,
271,5,310,1,1,
271,5,331,4,0,
271,5,346,1,31,
271,5,352,4,0,
271,6,7,3,0,
271,6,8,3,0,
271,6,9,3,0,
271,6,14,3,0,
271,6,34,3,0,
271,6,38,3,0,
271,6,45,1,3,
271,6,56,1,49,
271,6,57,4,0,
271,6,58,4,0,
271,6,59,4,0,
271,6,70,4,0,
271,6,71,1,7,
271,6,76,4,0,
271,6,92,4,0,
271,6,102,3,0,
271,6,104,4,0,
271,6,127,4,0,
271,6,148,4,0,
271,6,154,1,25,
271,6,156,4,0,
271,6,164,3,0,
271,6,168,1,37,
271,6,168,4,0,
271,6,173,3,0,
271,6,182,4,0,
271,6,189,3,0,
271,6,196,3,0,
271,6,202,4,0,
271,6,203,3,0,
271,6,207,3,0,
271,6,213,4,0,
271,6,214,3,0,
271,6,216,4,0,
271,6,218,4,0,
271,6,223,3,0,
271,6,237,4,0,
271,6,240,4,0,
271,6,241,4,0,
271,6,249,4,0,
271,6,252,1,19,
271,6,253,1,43,
271,6,258,4,0,
271,6,263,4,0,
271,6,267,1,13,
271,6,280,4,0,
271,6,290,4,0,
271,6,291,4,0,
271,6,310,1,1,
271,6,331,4,0,
271,6,346,1,31,
271,6,352,4,0,
271,7,14,3,0,
271,7,34,3,0,
271,7,38,3,0,
271,7,45,1,3,
271,7,56,1,49,
271,7,57,4,0,
271,7,58,4,0,
271,7,59,4,0,
271,7,70,4,0,
271,7,71,1,7,
271,7,76,4,0,
271,7,92,4,0,
271,7,102,3,0,
271,7,104,4,0,
271,7,127,4,0,
271,7,148,4,0,
271,7,154,1,25,
271,7,156,4,0,
271,7,164,3,0,
271,7,168,1,37,
271,7,168,4,0,
271,7,182,4,0,
271,7,202,4,0,
271,7,213,4,0,
271,7,216,4,0,
271,7,218,4,0,
271,7,237,4,0,
271,7,240,4,0,
271,7,241,4,0,
271,7,249,4,0,
271,7,252,1,19,
271,7,253,1,43,
271,7,258,4,0,
271,7,263,4,0,
271,7,267,1,13,
271,7,280,4,0,
271,7,290,4,0,
271,7,291,4,0,
271,7,310,1,1,
271,7,331,4,0,
271,7,346,1,31,
271,7,352,4,0,
271,8,14,4,0,
271,8,45,1,3,
271,8,56,1,43,
271,8,57,4,0,
271,8,58,4,0,
271,8,59,4,0,
271,8,70,4,0,
271,8,71,1,5,
271,8,76,4,0,
271,8,92,4,0,
271,8,104,4,0,
271,8,127,4,0,
271,8,148,4,0,
271,8,154,1,15,
271,8,156,4,0,
271,8,164,4,0,
271,8,168,4,0,
271,8,182,4,0,
271,8,202,4,0,
271,8,203,4,0,
271,8,207,4,0,
271,8,213,4,0,
271,8,214,4,0,
271,8,216,4,0,
271,8,218,4,0,
271,8,237,4,0,
271,8,240,4,0,
271,8,241,4,0,
271,8,249,4,0,
271,8,252,1,11,
271,8,253,1,35,
271,8,258,4,0,
271,8,263,4,0,
271,8,267,1,7,
271,8,280,4,0,
271,8,290,4,0,
271,8,310,1,1,
271,8,331,4,0,
271,8,346,1,19,
271,8,352,4,0,
271,8,363,4,0,
271,8,374,4,0,
271,8,409,4,0,
271,8,412,4,0,
271,8,428,1,27,
271,8,445,4,0,
271,8,447,4,0,
271,9,7,3,0,
271,9,8,3,0,
271,9,9,3,0,
271,9,14,4,0,
271,9,45,1,3,
271,9,56,1,45,
271,9,57,4,0,
271,9,58,4,0,
271,9,59,4,0,
271,9,61,1,25,
271,9,70,4,0,
271,9,71,1,5,
271,9,76,4,0,
271,9,92,4,0,
271,9,104,4,0,
271,9,127,4,0,
271,9,148,4,0,
271,9,154,1,15,
271,9,156,4,0,
271,9,164,4,0,
271,9,168,4,0,
271,9,173,3,0,
271,9,182,4,0,
271,9,189,3,0,
271,9,196,3,0,
271,9,202,4,0,
271,9,203,4,0,
271,9,207,4,0,
271,9,213,4,0,
271,9,214,4,0,
271,9,216,4,0,
271,9,218,4,0,
271,9,235,3,0,
271,9,237,4,0,
271,9,240,4,0,
271,9,241,4,0,
271,9,249,4,0,
271,9,252,1,11,
271,9,253,1,37,
271,9,253,3,0,
271,9,258,4,0,
271,9,263,4,0,
271,9,267,1,7,
271,9,280,4,0,
271,9,290,4,0,
271,9,291,3,0,
271,9,310,1,1,
271,9,331,4,0,
271,9,346,1,19,
271,9,352,4,0,
271,9,363,4,0,
271,9,374,4,0,
271,9,402,3,0,
271,9,409,4,0,
271,9,412,4,0,
271,9,428,1,31,
271,9,428,3,0,
271,9,445,4,0,
271,9,447,4,0,
271,10,7,3,0,
271,10,8,3,0,
271,10,9,3,0,
271,10,14,4,0,
271,10,29,3,0,
271,10,45,1,3,
271,10,56,1,45,
271,10,57,4,0,
271,10,58,4,0,
271,10,59,4,0,
271,10,61,1,25,
271,10,70,4,0,
271,10,71,1,5,
271,10,76,4,0,
271,10,92,4,0,
271,10,104,4,0,
271,10,127,4,0,
271,10,148,4,0,
271,10,154,1,15,
271,10,156,4,0,
271,10,164,4,0,
271,10,168,4,0,
271,10,173,3,0,
271,10,182,4,0,
271,10,189,3,0,
271,10,196,3,0,
271,10,202,4,0,
271,10,203,4,0,
271,10,207,4,0,
271,10,213,4,0,
271,10,214,4,0,
271,10,216,4,0,
271,10,218,4,0,
271,10,235,3,0,
271,10,237,4,0,
271,10,240,4,0,
271,10,241,4,0,
271,10,249,4,0,
271,10,250,4,0,
271,10,252,1,11,
271,10,253,1,37,
271,10,253,3,0,
271,10,258,4,0,
271,10,263,4,0,
271,10,267,1,7,
271,10,280,4,0,
271,10,290,4,0,
271,10,291,3,0,
271,10,310,1,1,
271,10,331,4,0,
271,10,346,1,19,
271,10,352,4,0,
271,10,363,4,0,
271,10,374,4,0,
271,10,402,3,0,
271,10,409,4,0,
271,10,412,4,0,
271,10,428,1,31,
271,10,428,3,0,
271,10,445,4,0,
271,10,447,4,0,
271,11,14,4,0,
271,11,45,1,3,
271,11,56,1,45,
271,11,57,4,0,
271,11,58,4,0,
271,11,59,4,0,
271,11,61,1,25,
271,11,70,4,0,
271,11,71,1,5,
271,11,76,4,0,
271,11,92,4,0,
271,11,104,4,0,
271,11,127,4,0,
271,11,148,4,0,
271,11,154,1,15,
271,11,156,4,0,
271,11,164,4,0,
271,11,168,4,0,
271,11,182,4,0,
271,11,207,4,0,
271,11,213,4,0,
271,11,216,4,0,
271,11,218,4,0,
271,11,237,4,0,
271,11,240,4,0,
271,11,241,4,0,
271,11,249,4,0,
271,11,252,1,11,
271,11,253,1,37,
271,11,258,4,0,
271,11,263,4,0,
271,11,267,1,7,
271,11,280,4,0,
271,11,291,4,0,
271,11,310,1,1,
271,11,346,1,19,
271,11,374,4,0,
271,11,412,4,0,
271,11,428,1,31,
271,11,447,4,0,
271,11,468,4,0,
271,11,496,4,0,
271,11,497,4,0,
271,11,503,4,0,
271,12,45,1,3,
271,12,56,1,49,
271,12,57,4,0,
271,12,58,4,0,
271,12,59,4,0,
271,12,70,4,0,
271,12,71,1,7,
271,12,76,4,0,
271,12,92,4,0,
271,12,104,4,0,
271,12,127,4,0,
271,12,148,4,0,
271,12,154,1,25,
271,12,156,4,0,
271,12,168,1,37,
271,12,168,4,0,
271,12,182,4,0,
271,12,202,4,0,
271,12,213,4,0,
271,12,216,4,0,
271,12,218,4,0,
271,12,237,4,0,
271,12,240,4,0,
271,12,241,4,0,
271,12,249,4,0,
271,12,252,1,19,
271,12,253,1,43,
271,12,258,4,0,
271,12,263,4,0,
271,12,267,1,13,
271,12,280,4,0,
271,12,290,4,0,
271,12,291,4,0,
271,12,310,1,1,
271,12,331,4,0,
271,12,346,1,31,
271,12,352,4,0,
271,13,34,3,0,
271,13,38,3,0,
271,13,45,1,3,
271,13,56,1,49,
271,13,57,4,0,
271,13,58,4,0,
271,13,59,4,0,
271,13,70,4,0,
271,13,71,1,7,
271,13,76,4,0,
271,13,92,4,0,
271,13,102,3,0,
271,13,104,4,0,
271,13,127,4,0,
271,13,148,4,0,
271,13,154,1,25,
271,13,156,4,0,
271,13,164,3,0,
271,13,168,1,37,
271,13,168,4,0,
271,13,182,4,0,
271,13,196,3,0,
271,13,202,4,0,
271,13,207,3,0,
271,13,213,4,0,
271,13,216,4,0,
271,13,218,4,0,
271,13,237,4,0,
271,13,240,4,0,
271,13,241,4,0,
271,13,249,4,0,
271,13,252,1,19,
271,13,253,1,43,
271,13,258,4,0,
271,13,263,4,0,
271,13,267,1,13,
271,13,280,4,0,
271,13,290,4,0,
271,13,291,4,0,
271,13,310,1,1,
271,13,331,4,0,
271,13,346,1,31,
271,13,352,4,0,
271,14,7,3,0,
271,14,8,3,0,
271,14,9,3,0,
271,14,14,4,0,
271,14,45,1,3,
271,14,56,1,45,
271,14,57,4,0,
271,14,58,4,0,
271,14,59,4,0,
271,14,61,1,25,
271,14,70,4,0,
271,14,71,1,5,
271,14,76,4,0,
271,14,92,4,0,
271,14,104,4,0,
271,14,127,4,0,
271,14,148,4,0,
271,14,154,1,15,
271,14,156,4,0,
271,14,164,4,0,
271,14,168,4,0,
271,14,173,3,0,
271,14,182,4,0,
271,14,196,3,0,
271,14,202,3,0,
271,14,207,4,0,
271,14,213,4,0,
271,14,214,3,0,
271,14,216,4,0,
271,14,218,4,0,
271,14,235,3,0,
271,14,237,4,0,
271,14,240,4,0,
271,14,241,4,0,
271,14,249,4,0,
271,14,252,1,11,
271,14,253,1,37,
271,14,253,3,0,
271,14,258,4,0,
271,14,263,4,0,
271,14,267,1,7,
271,14,280,4,0,
271,14,291,4,0,
271,14,304,3,0,
271,14,310,1,1,
271,14,346,1,19,
271,14,374,4,0,
271,14,402,3,0,
271,14,409,3,0,
271,14,412,4,0,
271,14,428,1,31,
271,14,428,3,0,
271,14,447,4,0,
271,14,468,4,0,
271,14,496,4,0,
271,14,497,4,0,
271,14,503,4,0,
271,15,14,4,0,
271,15,45,1,3,
271,15,56,1,45,
271,15,57,4,0,
271,15,58,4,0,
271,15,59,4,0,
271,15,61,1,25,
271,15,70,4,0,
271,15,71,1,5,
271,15,76,4,0,
271,15,92,4,0,
271,15,104,4,0,
271,15,127,4,0,
271,15,148,4,0,
271,15,154,1,15,
271,15,156,4,0,
271,15,164,4,0,
271,15,168,4,0,
271,15,182,4,0,
271,15,207,4,0,
271,15,213,4,0,
271,15,214,4,0,
271,15,216,4,0,
271,15,218,4,0,
271,15,237,4,0,
271,15,240,4,0,
271,15,241,4,0,
271,15,249,4,0,
271,15,252,1,11,
271,15,253,1,37,
271,15,258,4,0,
271,15,263,4,0,
271,15,267,1,7,
271,15,267,4,0,
271,15,280,4,0,
271,15,310,1,1,
271,15,346,1,19,
271,15,374,4,0,
271,15,412,4,0,
271,15,428,1,31,
271,15,447,4,0,
271,15,468,4,0,
271,15,496,4,0,
271,15,497,4,0,
271,15,503,4,0,
271,15,590,4,0,
271,15,612,4,0,
271,16,7,3,0,
271,16,8,3,0,
271,16,9,3,0,
271,16,14,4,0,
271,16,45,1,3,1
271,16,56,1,44,1
271,16,57,4,0,
271,16,58,4,0,
271,16,59,4,0,
271,16,61,1,24,1
271,16,70,4,0,
271,16,71,1,6,1
271,16,76,4,0,
271,16,92,4,0,
271,16,104,4,0,
271,16,127,4,0,
271,16,145,1,9,1
271,16,148,4,0,
271,16,154,1,12,1
271,16,156,4,0,
271,16,164,4,0,
271,16,168,4,0,
271,16,173,3,0,
271,16,182,4,0,
271,16,196,3,0,
271,16,202,3,0,
271,16,207,4,0,
271,16,213,4,0,
271,16,214,4,0,
271,16,216,4,0,
271,16,218,4,0,
271,16,235,3,0,
271,16,237,4,0,
271,16,240,4,0,
271,16,241,4,0,
271,16,249,4,0,
271,16,252,1,16,1
271,16,253,1,32,1
271,16,253,3,0,
271,16,258,4,0,
271,16,263,4,0,
271,16,267,1,28,1
271,16,267,4,0,
271,16,280,4,0,
271,16,282,1,36,1
271,16,282,3,0,
271,16,290,4,0,
271,16,291,4,0,
271,16,304,3,0,
271,16,310,1,1,1
271,16,346,1,20,1
271,16,352,3,0,
271,16,374,4,0,
271,16,402,3,0,
271,16,409,3,0,
271,16,412,4,0,
271,16,428,1,40,1
271,16,428,3,0,
271,16,447,4,0,
271,16,468,4,0,
271,16,496,4,0,
271,16,497,4,0,
271,16,503,4,0,
271,16,590,4,0,
271,16,612,4,0,
271,17,14,4,0,
271,17,45,1,3,
271,17,56,1,44,
271,17,57,4,0,
271,17,58,4,0,
271,17,59,4,0,
271,17,61,1,24,
271,17,71,1,6,
271,17,76,4,0,
271,17,92,4,0,
271,17,104,4,0,
271,17,127,4,0,
271,17,145,1,9,
271,17,154,1,12,
271,17,156,4,0,
271,17,164,4,0,
271,17,168,4,0,
271,17,182,4,0,
271,17,207,4,0,
271,17,213,4,0,
271,17,214,4,0,
271,17,216,4,0,
271,17,218,4,0,
271,17,237,4,0,
271,17,240,4,0,
271,17,241,4,0,
271,17,252,1,16,
271,17,253,1,32,
271,17,258,4,0,
271,17,263,4,0,
271,17,267,1,28,
271,17,267,4,0,
271,17,280,4,0,
271,17,282,1,36,
271,17,310,1,1,
271,17,346,1,20,
271,17,374,4,0,
271,17,412,4,0,
271,17,428,1,40,
271,17,447,4,0,
271,17,496,4,0,
271,17,497,4,0,
271,17,503,4,0,
271,17,590,4,0,
271,18,14,4,0,
271,18,45,1,3,
271,18,56,1,44,
271,18,57,4,0,
271,18,58,4,0,
271,18,59,4,0,
271,18,61,1,24,
271,18,71,1,6,
271,18,76,4,0,
271,18,92,4,0,
271,18,104,4,0,
271,18,127,4,0,
271,18,145,1,9,
271,18,154,1,12,
271,18,156,4,0,
271,18,164,4,0,
271,18,168,4,0,
271,18,182,4,0,
271,18,207,4,0,
271,18,213,4,0,
271,18,214,4,0,
271,18,216,4,0,
271,18,218,4,0,
271,18,237,4,0,
271,18,240,4,0,
271,18,241,4,0,
271,18,252,1,16,
271,18,253,1,32,
271,18,258,4,0,
271,18,263,4,0,
271,18,267,1,28,
271,18,267,4,0,
271,18,280,4,0,
271,18,282,1,36,
271,18,310,1,1,
271,18,346,1,20,
271,18,374,4,0,
271,18,412,4,0,
271,18,428,1,40,
271,18,447,4,0,
271,18,496,4,0,
271,18,497,4,0,
271,18,503,4,0,
271,18,590,4,0,
272,5,45,1,1,2
272,5,57,4,0,
272,5,58,4,0,
272,5,59,4,0,
272,5,63,4,0,
272,5,70,4,0,
272,5,71,1,1,3
272,5,76,4,0,
272,5,92,4,0,
272,5,104,4,0,
272,5,127,4,0,
272,5,148,4,0,
272,5,156,4,0,
272,5,168,4,0,
272,5,182,4,0,
272,5,202,4,0,
272,5,213,4,0,
272,5,216,4,0,
272,5,218,4,0,
272,5,237,4,0,
272,5,240,4,0,
272,5,241,4,0,
272,5,249,4,0,
272,5,258,4,0,
272,5,263,4,0,
272,5,264,4,0,
272,5,267,1,1,4
272,5,280,4,0,
272,5,290,4,0,
272,5,291,4,0,
272,5,310,1,1,1
272,5,331,4,0,
272,5,352,4,0,
272,6,5,3,0,
272,6,7,3,0,
272,6,8,3,0,
272,6,9,3,0,
272,6,14,3,0,
272,6,25,3,0,
272,6,34,3,0,
272,6,38,3,0,
272,6,45,1,1,2
272,6,57,4,0,
272,6,58,4,0,
272,6,59,4,0,
272,6,63,4,0,
272,6,68,3,0,
272,6,69,3,0,
272,6,70,4,0,
272,6,71,1,1,3
272,6,76,4,0,
272,6,92,4,0,
272,6,102,3,0,
272,6,104,4,0,
272,6,118,3,0,
272,6,127,4,0,
272,6,148,4,0,
272,6,156,4,0,
272,6,164,3,0,
272,6,168,4,0,
272,6,173,3,0,
272,6,182,4,0,
272,6,189,3,0,
272,6,196,3,0,
272,6,202,4,0,
272,6,203,3,0,
272,6,207,3,0,
272,6,213,4,0,
272,6,214,3,0,
272,6,216,4,0,
272,6,218,4,0,
272,6,223,3,0,
272,6,237,4,0,
272,6,240,4,0,
272,6,241,4,0,
272,6,249,4,0,
272,6,258,4,0,
272,6,263,4,0,
272,6,264,4,0,
272,6,267,1,1,4
272,6,280,4,0,
272,6,290,4,0,
272,6,291,4,0,
272,6,310,1,1,1
272,6,331,4,0,
272,6,352,4,0,
272,7,5,3,0,
272,7,14,3,0,
272,7,25,3,0,
272,7,34,3,0,
272,7,38,3,0,
272,7,45,1,1,2
272,7,57,4,0,
272,7,58,4,0,
272,7,59,4,0,
272,7,63,4,0,
272,7,68,3,0,
272,7,69,3,0,
272,7,70,4,0,
272,7,71,1,1,3
272,7,76,4,0,
272,7,92,4,0,
272,7,102,3,0,
272,7,104,4,0,
272,7,118,3,0,
272,7,127,4,0,
272,7,148,4,0,
272,7,156,4,0,
272,7,164,3,0,
272,7,168,4,0,
272,7,182,4,0,
272,7,202,4,0,
272,7,213,4,0,
272,7,216,4,0,
272,7,218,4,0,
272,7,237,4,0,
272,7,240,4,0,
272,7,241,4,0,
272,7,249,4,0,
272,7,258,4,0,
272,7,263,4,0,
272,7,264,4,0,
272,7,267,1,1,4
272,7,280,4,0,
272,7,290,4,0,
272,7,291,4,0,
272,7,310,1,1,1
272,7,331,4,0,
272,7,352,4,0,
272,8,14,4,0,
272,8,45,1,1,2
272,8,57,4,0,
272,8,58,4,0,
272,8,59,4,0,
272,8,63,4,0,
272,8,70,4,0,
272,8,72,1,1,3
272,8,76,4,0,
272,8,92,4,0,
272,8,104,4,0,
272,8,127,4,0,
272,8,148,4,0,
272,8,156,4,0,
272,8,164,4,0,
272,8,168,4,0,
272,8,182,4,0,
272,8,202,4,0,
272,8,203,4,0,
272,8,207,4,0,
272,8,213,4,0,
272,8,214,4,0,
272,8,216,4,0,
272,8,218,4,0,
272,8,237,4,0,
272,8,240,4,0,
272,8,241,4,0,
272,8,249,4,0,
272,8,258,4,0,
272,8,263,4,0,
272,8,264,4,0,
272,8,267,1,1,4
272,8,280,4,0,
272,8,290,4,0,
272,8,310,1,1,1
272,8,331,4,0,
272,8,352,4,0,
272,8,363,4,0,
272,8,374,4,0,
272,8,409,4,0,
272,8,411,4,0,
272,8,412,4,0,
272,8,416,4,0,
272,8,431,4,0,
272,8,445,4,0,
272,8,447,4,0,
272,9,7,3,0,
272,9,8,3,0,
272,9,9,3,0,
272,9,14,4,0,
272,9,45,1,1,2
272,9,57,4,0,
272,9,58,4,0,
272,9,59,4,0,
272,9,63,4,0,
272,9,70,4,0,
272,9,72,1,1,3
272,9,76,4,0,
272,9,92,4,0,
272,9,104,4,0,
272,9,127,4,0,
272,9,148,4,0,
272,9,156,4,0,
272,9,164,4,0,
272,9,168,4,0,
272,9,173,3,0,
272,9,182,4,0,
272,9,189,3,0,
272,9,196,3,0,
272,9,202,4,0,
272,9,203,4,0,
272,9,207,4,0,
272,9,213,4,0,
272,9,214,4,0,
272,9,216,4,0,
272,9,218,4,0,
272,9,235,3,0,
272,9,237,4,0,
272,9,240,4,0,
272,9,241,4,0,
272,9,249,4,0,
272,9,253,3,0,
272,9,258,4,0,
272,9,263,4,0,
272,9,264,4,0,
272,9,267,1,1,4
272,9,280,4,0,
272,9,290,4,0,
272,9,291,3,0,
272,9,310,1,1,1
272,9,331,4,0,
272,9,352,4,0,
272,9,363,4,0,
272,9,374,4,0,
272,9,402,3,0,
272,9,409,4,0,
272,9,411,4,0,
272,9,412,4,0,
272,9,416,4,0,
272,9,428,3,0,
272,9,431,4,0,
272,9,445,4,0,
272,9,447,4,0,
272,10,7,3,0,
272,10,8,3,0,
272,10,9,3,0,
272,10,14,4,0,
272,10,29,3,0,
272,10,45,1,1,2
272,10,57,4,0,
272,10,58,4,0,
272,10,59,4,0,
272,10,63,4,0,
272,10,70,4,0,
272,10,72,1,1,3
272,10,76,4,0,
272,10,92,4,0,
272,10,104,4,0,
272,10,127,4,0,
272,10,148,4,0,
272,10,156,4,0,
272,10,164,4,0,
272,10,168,4,0,
272,10,173,3,0,
272,10,182,4,0,
272,10,189,3,0,
272,10,196,3,0,
272,10,202,4,0,
272,10,203,4,0,
272,10,207,4,0,
272,10,213,4,0,
272,10,214,4,0,
272,10,216,4,0,
272,10,218,4,0,
272,10,235,3,0,
272,10,237,4,0,
272,10,240,4,0,
272,10,241,4,0,
272,10,249,4,0,
272,10,250,4,0,
272,10,253,3,0,
272,10,258,4,0,
272,10,263,4,0,
272,10,264,4,0,
272,10,267,1,1,4
272,10,280,4,0,
272,10,290,4,0,
272,10,291,3,0,
272,10,310,1,1,1
272,10,331,4,0,
272,10,352,4,0,
272,10,363,4,0,
272,10,374,4,0,
272,10,402,3,0,
272,10,409,4,0,
272,10,411,4,0,
272,10,412,4,0,
272,10,416,4,0,
272,10,428,3,0,
272,10,431,4,0,
272,10,445,4,0,
272,10,447,4,0,
272,11,14,4,0,
272,11,45,1,1,2
272,11,57,4,0,
272,11,58,4,0,
272,11,59,4,0,
272,11,63,4,0,
272,11,70,4,0,
272,11,72,1,1,3
272,11,76,4,0,
272,11,92,4,0,
272,11,104,4,0,
272,11,127,4,0,
272,11,148,4,0,
272,11,156,4,0,
272,11,164,4,0,
272,11,168,4,0,
272,11,182,4,0,
272,11,207,4,0,
272,11,213,4,0,
272,11,216,4,0,
272,11,218,4,0,
272,11,237,4,0,
272,11,240,4,0,
272,11,241,4,0,
272,11,249,4,0,
272,11,258,4,0,
272,11,263,4,0,
272,11,267,1,1,4
272,11,280,4,0,
272,11,291,4,0,
272,11,310,1,1,1
272,11,374,4,0,
272,11,411,4,0,
272,11,412,4,0,
272,11,416,4,0,
272,11,447,4,0,
272,11,468,4,0,
272,11,496,4,0,
272,11,497,4,0,
272,11,503,4,0,
272,12,45,1,1,2
272,12,57,4,0,
272,12,58,4,0,
272,12,59,4,0,
272,12,63,4,0,
272,12,70,4,0,
272,12,71,1,1,3
272,12,76,4,0,
272,12,92,4,0,
272,12,104,4,0,
272,12,127,4,0,
272,12,148,4,0,
272,12,156,4,0,
272,12,168,4,0,
272,12,182,4,0,
272,12,202,4,0,
272,12,213,4,0,
272,12,216,4,0,
272,12,218,4,0,
272,12,237,4,0,
272,12,240,4,0,
272,12,241,4,0,
272,12,249,4,0,
272,12,258,4,0,
272,12,263,4,0,
272,12,264,4,0,
272,12,267,1,1,4
272,12,280,4,0,
272,12,290,4,0,
272,12,291,4,0,
272,12,310,1,1,1
272,12,331,4,0,
272,12,352,4,0,
272,13,34,3,0,
272,13,38,3,0,
272,13,45,1,1,2
272,13,57,4,0,
272,13,58,4,0,
272,13,59,4,0,
272,13,63,4,0,
272,13,69,3,0,
272,13,70,4,0,
272,13,71,1,1,3
272,13,76,4,0,
272,13,92,4,0,
272,13,102,3,0,
272,13,104,4,0,
272,13,127,4,0,
272,13,148,4,0,
272,13,156,4,0,
272,13,164,3,0,
272,13,168,4,0,
272,13,182,4,0,
272,13,196,3,0,
272,13,202,4,0,
272,13,207,3,0,
272,13,213,4,0,
272,13,216,4,0,
272,13,218,4,0,
272,13,237,4,0,
272,13,240,4,0,
272,13,241,4,0,
272,13,249,4,0,
272,13,258,4,0,
272,13,263,4,0,
272,13,264,4,0,
272,13,267,1,1,4
272,13,280,4,0,
272,13,290,4,0,
272,13,291,4,0,
272,13,310,1,1,1
272,13,331,4,0,
272,13,352,4,0,
272,14,7,3,0,
272,14,8,3,0,
272,14,9,3,0,
272,14,14,4,0,
272,14,45,1,1,2
272,14,57,4,0,
272,14,58,4,0,
272,14,59,4,0,
272,14,63,4,0,
272,14,70,4,0,
272,14,72,1,1,3
272,14,76,4,0,
272,14,92,4,0,
272,14,104,4,0,
272,14,127,4,0,
272,14,148,4,0,
272,14,156,4,0,
272,14,164,4,0,
272,14,168,4,0,
272,14,173,3,0,
272,14,182,4,0,
272,14,196,3,0,
272,14,202,3,0,
272,14,207,4,0,
272,14,213,4,0,
272,14,214,3,0,
272,14,216,4,0,
272,14,218,4,0,
272,14,235,3,0,
272,14,237,4,0,
272,14,240,4,0,
272,14,241,4,0,
272,14,249,4,0,
272,14,253,3,0,
272,14,258,4,0,
272,14,263,4,0,
272,14,267,1,1,4
272,14,280,4,0,
272,14,291,4,0,
272,14,304,3,0,
272,14,310,1,1,1
272,14,374,4,0,
272,14,402,3,0,
272,14,409,3,0,
272,14,411,4,0,
272,14,412,4,0,
272,14,416,4,0,
272,14,428,3,0,
272,14,447,4,0,
272,14,468,4,0,
272,14,496,4,0,
272,14,497,4,0,
272,14,503,4,0,
272,15,14,4,0,
272,15,45,1,1,2
272,15,57,4,0,
272,15,58,4,0,
272,15,59,4,0,
272,15,63,4,0,
272,15,70,4,0,
272,15,72,1,1,3
272,15,76,4,0,
272,15,92,4,0,
272,15,104,4,0,
272,15,127,4,0,
272,15,148,4,0,
272,15,156,4,0,
272,15,164,4,0,
272,15,168,4,0,
272,15,182,4,0,
272,15,207,4,0,
272,15,213,4,0,
272,15,214,4,0,
272,15,216,4,0,
272,15,218,4,0,
272,15,237,4,0,
272,15,240,4,0,
272,15,241,4,0,
272,15,249,4,0,
272,15,258,4,0,
272,15,263,4,0,
272,15,267,1,1,4
272,15,267,4,0,
272,15,280,4,0,
272,15,310,1,1,1
272,15,374,4,0,
272,15,411,4,0,
272,15,412,4,0,
272,15,416,4,0,
272,15,447,4,0,
272,15,468,4,0,
272,15,496,4,0,
272,15,497,4,0,
272,15,503,4,0,
272,15,590,4,0,
272,15,612,4,0,
272,16,7,3,0,
272,16,8,3,0,
272,16,9,3,0,
272,16,14,4,0,
272,16,45,1,1,2
272,16,57,4,0,
272,16,58,4,0,
272,16,59,4,0,
272,16,63,4,0,
272,16,70,4,0,
272,16,72,1,1,3
272,16,76,4,0,
272,16,92,4,0,
272,16,104,4,0,
272,16,127,4,0,
272,16,148,4,0,
272,16,156,4,0,
272,16,164,4,0,
272,16,168,4,0,
272,16,173,3,0,
272,16,182,4,0,
272,16,196,3,0,
272,16,202,3,0,
272,16,207,4,0,
272,16,213,4,0,
272,16,214,4,0,
272,16,216,4,0,
272,16,218,4,0,
272,16,235,3,0,
272,16,237,4,0,
272,16,240,4,0,
272,16,241,4,0,
272,16,249,4,0,
272,16,253,3,0,
272,16,258,4,0,
272,16,263,4,0,
272,16,264,3,0,
272,16,267,1,1,4
272,16,267,4,0,
272,16,280,4,0,
272,16,282,3,0,
272,16,290,4,0,
272,16,291,4,0,
272,16,304,3,0,
272,16,310,1,1,1
272,16,352,3,0,
272,16,374,4,0,
272,16,402,3,0,
272,16,409,3,0,
272,16,411,4,0,
272,16,412,4,0,
272,16,416,4,0,
272,16,428,3,0,
272,16,447,4,0,
272,16,468,4,0,
272,16,496,4,0,
272,16,497,4,0,
272,16,503,4,0,
272,16,590,4,0,
272,16,612,4,0,
272,17,14,4,0,
272,17,45,1,1,2
272,17,57,4,0,
272,17,58,4,0,
272,17,59,4,0,
272,17,63,4,0,
272,17,72,1,1,3
272,17,76,4,0,
272,17,92,4,0,
272,17,104,4,0,
272,17,127,4,0,
272,17,156,4,0,
272,17,164,4,0,
272,17,168,4,0,
272,17,182,4,0,
272,17,207,4,0,
272,17,213,4,0,
272,17,214,4,0,
272,17,216,4,0,
272,17,218,4,0,
272,17,237,4,0,
272,17,240,4,0,
272,17,241,4,0,
272,17,258,4,0,
272,17,263,4,0,
272,17,267,1,1,4
272,17,267,4,0,
272,17,280,4,0,
272,17,310,1,1,1
272,17,374,4,0,
272,17,411,4,0,
272,17,412,4,0,
272,17,416,4,0,
272,17,447,4,0,
272,17,496,4,0,
272,17,497,4,0,
272,17,503,4,0,
272,17,590,4,0,
272,18,14,4,0,
272,18,45,1,1,2
272,18,57,4,0,
272,18,58,4,0,
272,18,59,4,0,
272,18,63,4,0,
272,18,72,1,1,3
272,18,76,4,0,
272,18,92,4,0,
272,18,104,4,0,
272,18,127,4,0,
272,18,156,4,0,
272,18,164,4,0,
272,18,168,4,0,
272,18,182,4,0,
272,18,207,4,0,
272,18,213,4,0,
272,18,214,4,0,
272,18,216,4,0,
272,18,218,4,0,
272,18,237,4,0,
272,18,240,4,0,
272,18,241,4,0,
272,18,258,4,0,
272,18,263,4,0,
272,18,267,1,1,4
272,18,267,4,0,
272,18,280,4,0,
272,18,310,1,1,1
272,18,374,4,0,
272,18,411,4,0,
272,18,412,4,0,
272,18,416,4,0,
272,18,447,4,0,
272,18,496,4,0,
272,18,497,4,0,
272,18,503,4,0,
272,18,590,4,0,
273,5,13,2,0,
273,5,36,2,0,
273,5,73,2,0,
273,5,74,1,7,
273,5,76,4,0,
273,5,91,4,0,
273,5,92,4,0,
273,5,98,2,0,
273,5,104,4,0,
273,5,106,1,3,
273,5,117,1,1,
273,5,133,2,0,
273,5,148,4,0,
273,5,153,1,43,
273,5,156,4,0,
273,5,182,4,0,
273,5,202,4,0,
273,5,206,2,0,
273,5,213,4,0,
273,5,216,4,0,
273,5,218,4,0,
273,5,235,1,21,
273,5,237,4,0,
273,5,241,1,31,
273,5,241,4,0,
273,5,247,4,0,
273,5,249,4,0,
273,5,263,4,0,
273,5,267,1,13,
273,5,290,4,0,
273,5,331,4,0,
273,6,13,2,0,
273,6,14,3,0,
273,6,34,3,0,
273,6,36,2,0,
273,6,38,3,0,
273,6,73,2,0,
273,6,74,1,7,
273,6,76,4,0,
273,6,91,4,0,
273,6,92,4,0,
273,6,98,2,0,
273,6,102,3,0,
273,6,104,4,0,
273,6,106,1,3,
273,6,111,3,0,
273,6,117,1,1,
273,6,133,2,0,
273,6,148,4,0,
273,6,153,1,43,
273,6,153,3,0,
273,6,156,4,0,
273,6,164,3,0,
273,6,173,3,0,
273,6,182,4,0,
273,6,202,4,0,
273,6,203,3,0,
273,6,205,3,0,
273,6,206,2,0,
273,6,207,3,0,
273,6,213,4,0,
273,6,214,3,0,
273,6,216,4,0,
273,6,218,4,0,
273,6,235,1,21,
273,6,237,4,0,
273,6,241,1,31,
273,6,241,4,0,
273,6,247,4,0,
273,6,249,4,0,
273,6,263,4,0,
273,6,267,1,13,
273,6,290,4,0,
273,6,331,4,0,
273,7,13,2,0,
273,7,14,3,0,
273,7,34,3,0,
273,7,36,2,0,
273,7,38,3,0,
273,7,73,2,0,
273,7,74,1,7,
273,7,76,4,0,
273,7,91,4,0,
273,7,92,4,0,
273,7,98,2,0,
273,7,102,3,0,
273,7,104,4,0,
273,7,106,1,3,
273,7,117,1,1,
273,7,133,2,0,
273,7,148,4,0,
273,7,153,1,43,
273,7,153,3,0,
273,7,156,4,0,
273,7,164,3,0,
273,7,182,4,0,
273,7,202,4,0,
273,7,206,2,0,
273,7,213,4,0,
273,7,216,4,0,
273,7,218,4,0,
273,7,235,1,21,
273,7,237,4,0,
273,7,241,1,31,
273,7,241,4,0,
273,7,247,4,0,
273,7,249,4,0,
273,7,263,4,0,
273,7,267,1,13,
273,7,290,4,0,
273,7,331,4,0,
273,8,13,2,0,
273,8,14,4,0,
273,8,36,2,0,
273,8,73,2,0,
273,8,74,1,7,
273,8,76,4,0,
273,8,91,4,0,
273,8,92,4,0,
273,8,98,2,0,
273,8,104,4,0,
273,8,106,1,3,
273,8,117,1,1,
273,8,133,2,0,
273,8,148,4,0,
273,8,153,1,43,
273,8,153,4,0,
273,8,156,4,0,
273,8,164,4,0,
273,8,182,4,0,
273,8,202,4,0,
273,8,203,4,0,
273,8,206,2,0,
273,8,207,4,0,
273,8,213,4,0,
273,8,214,4,0,
273,8,216,4,0,
273,8,218,4,0,
273,8,235,1,21,
273,8,237,4,0,
273,8,241,1,31,
273,8,241,4,0,
273,8,247,4,0,
273,8,249,4,0,
273,8,263,4,0,
273,8,267,1,13,
273,8,290,4,0,
273,8,331,4,0,
273,8,363,4,0,
273,8,388,2,0,
273,8,412,4,0,
273,8,417,2,0,
273,8,445,4,0,
273,8,447,4,0,
273,9,13,2,0,
273,9,14,4,0,
273,9,36,2,0,
273,9,73,2,0,
273,9,74,1,7,
273,9,76,4,0,
273,9,91,4,0,
273,9,92,4,0,
273,9,98,2,0,
273,9,104,4,0,
273,9,106,1,3,
273,9,117,1,1,
273,9,133,2,0,
273,9,148,4,0,
273,9,153,1,43,
273,9,153,4,0,
273,9,156,4,0,
273,9,164,4,0,
273,9,173,3,0,
273,9,180,3,0,
273,9,182,4,0,
273,9,202,4,0,
273,9,203,4,0,
273,9,205,3,0,
273,9,206,2,0,
273,9,207,4,0,
273,9,213,4,0,
273,9,214,4,0,
273,9,216,4,0,
273,9,218,4,0,
273,9,235,1,21,
273,9,235,3,0,
273,9,237,4,0,
273,9,241,1,31,
273,9,241,4,0,
273,9,247,4,0,
273,9,249,4,0,
273,9,263,4,0,
273,9,267,1,13,
273,9,290,4,0,
273,9,331,4,0,
273,9,363,4,0,
273,9,388,2,0,
273,9,402,3,0,
273,9,412,4,0,
273,9,417,2,0,
273,9,445,4,0,
273,9,447,4,0,
273,10,13,2,0,
273,10,14,4,0,
273,10,29,3,0,
273,10,36,2,0,
273,10,73,2,0,
273,10,74,1,7,
273,10,76,4,0,
273,10,91,4,0,
273,10,92,4,0,
273,10,98,2,0,
273,10,104,4,0,
273,10,106,1,3,
273,10,117,1,1,
273,10,133,2,0,
273,10,148,4,0,
273,10,153,1,43,
273,10,153,4,0,
273,10,156,4,0,
273,10,164,4,0,
273,10,173,3,0,
273,10,180,3,0,
273,10,182,4,0,
273,10,202,4,0,
273,10,203,4,0,
273,10,205,3,0,
273,10,206,2,0,
273,10,207,4,0,
273,10,213,4,0,
273,10,214,4,0,
273,10,216,4,0,
273,10,218,4,0,
273,10,235,1,21,
273,10,235,3,0,
273,10,237,4,0,
273,10,241,1,31,
273,10,241,4,0,
273,10,247,4,0,
273,10,249,4,0,
273,10,263,4,0,
273,10,267,1,13,
273,10,290,4,0,
273,10,331,4,0,
273,10,363,4,0,
273,10,384,2,0,
273,10,388,2,0,
273,10,388,3,0,
273,10,402,3,0,
273,10,412,4,0,
273,10,417,2,0,
273,10,445,4,0,
273,10,447,4,0,
273,11,13,2,0,
273,11,14,4,0,
273,11,36,2,0,
273,11,73,2,0,
273,11,74,1,7,
273,11,76,4,0,
273,11,91,4,0,
273,11,92,4,0,
273,11,98,2,0,
273,11,104,4,0,
273,11,106,1,3,
273,11,117,1,1,
273,11,133,2,0,
273,11,148,4,0,
273,11,153,1,43,
273,11,153,4,0,
273,11,156,4,0,
273,11,164,4,0,
273,11,182,4,0,
273,11,206,4,0,
273,11,207,4,0,
273,11,213,4,0,
273,11,216,4,0,
273,11,218,4,0,
273,11,235,1,21,
273,11,237,4,0,
273,11,241,1,31,
273,11,241,4,0,
273,11,247,4,0,
273,11,249,4,0,
273,11,251,2,0,
273,11,263,4,0,
273,11,267,1,13,
273,11,331,2,0,
273,11,384,2,0,
273,11,388,2,0,
273,11,412,4,0,
273,11,417,2,0,
273,11,432,2,0,
273,11,447,4,0,
273,11,492,2,0,
273,11,496,4,0,
273,11,514,4,0,
273,12,74,1,7,
273,12,76,4,0,
273,12,91,4,0,
273,12,92,4,0,
273,12,104,4,0,
273,12,106,1,3,
273,12,117,1,1,
273,12,148,4,0,
273,12,153,1,43,
273,12,156,4,0,
273,12,182,4,0,
273,12,202,4,0,
273,12,213,4,0,
273,12,216,4,0,
273,12,218,4,0,
273,12,235,1,21,
273,12,237,4,0,
273,12,241,1,31,
273,12,241,4,0,
273,12,247,4,0,
273,12,249,4,0,
273,12,263,4,0,
273,12,267,1,13,
273,12,290,4,0,
273,12,331,4,0,
273,13,34,3,0,
273,13,38,3,0,
273,13,74,1,7,
273,13,76,4,0,
273,13,91,4,0,
273,13,92,4,0,
273,13,102,3,0,
273,13,104,4,0,
273,13,106,1,3,
273,13,117,1,1,
273,13,120,3,0,
273,13,148,4,0,
273,13,153,1,43,
273,13,156,4,0,
273,13,164,3,0,
273,13,182,4,0,
273,13,202,4,0,
273,13,207,3,0,
273,13,213,4,0,
273,13,216,4,0,
273,13,218,4,0,
273,13,235,1,21,
273,13,237,4,0,
273,13,241,1,31,
273,13,241,4,0,
273,13,247,4,0,
273,13,249,4,0,
273,13,263,4,0,
273,13,267,1,13,
273,13,290,4,0,
273,13,331,4,0,
273,14,13,2,0,
273,14,14,4,0,
273,14,36,2,0,
273,14,73,2,0,
273,14,74,1,7,
273,14,76,4,0,
273,14,91,4,0,
273,14,92,4,0,
273,14,98,2,0,
273,14,104,4,0,
273,14,106,1,3,
273,14,117,1,1,
273,14,133,2,0,
273,14,148,4,0,
273,14,153,1,43,
273,14,153,4,0,
273,14,156,4,0,
273,14,164,4,0,
273,14,173,3,0,
273,14,180,3,0,
273,14,182,4,0,
273,14,202,3,0,
273,14,206,4,0,
273,14,207,4,0,
273,14,213,4,0,
273,14,214,3,0,
273,14,216,4,0,
273,14,218,4,0,
273,14,235,1,21,
273,14,235,3,0,
273,14,237,4,0,
273,14,241,1,31,
273,14,241,4,0,
273,14,247,4,0,
273,14,249,4,0,
273,14,251,2,0,
273,14,263,4,0,
273,14,267,1,13,
273,14,331,2,0,
273,14,384,2,0,
273,14,388,2,0,
273,14,388,3,0,
273,14,402,3,0,
273,14,412,4,0,
273,14,417,2,0,
273,14,432,2,0,
273,14,447,4,0,
273,14,492,2,0,
273,14,492,3,0,
273,14,496,4,0,
273,14,514,4,0,
273,15,13,2,0,
273,15,14,4,0,
273,15,36,2,0,
273,15,73,2,0,
273,15,74,1,7,
273,15,76,4,0,
273,15,91,4,0,
273,15,92,4,0,
273,15,98,2,0,
273,15,104,4,0,
273,15,106,1,3,
273,15,117,1,1,
273,15,133,2,0,
273,15,148,4,0,
273,15,153,1,43,
273,15,153,4,0,
273,15,156,4,0,
273,15,164,4,0,
273,15,182,4,0,
273,15,206,4,0,
273,15,207,4,0,
273,15,213,4,0,
273,15,214,4,0,
273,15,216,4,0,
273,15,218,4,0,
273,15,235,1,21,
273,15,237,4,0,
273,15,241,1,31,
273,15,241,4,0,
273,15,247,4,0,
273,15,249,4,0,
273,15,251,2,0,
273,15,263,4,0,
273,15,267,1,13,
273,15,267,4,0,
273,15,331,2,0,
273,15,384,2,0,
273,15,388,2,0,
273,15,412,4,0,
273,15,417,2,0,
273,15,432,2,0,
273,15,447,4,0,
273,15,492,2,0,
273,15,496,4,0,
273,15,514,4,0,
273,15,580,2,0,
273,15,590,4,0,
273,16,13,2,0,
273,16,14,4,0,
273,16,36,2,0,
273,16,73,2,0,
273,16,74,1,9,1
273,16,76,4,0,
273,16,91,4,0,
273,16,92,4,0,
273,16,98,2,0,
273,16,104,4,0,
273,16,106,1,3,1
273,16,117,1,1,1
273,16,133,2,0,
273,16,148,4,0,
273,16,153,1,33,1
273,16,153,4,0,
273,16,156,4,0,
273,16,164,4,0,
273,16,173,3,0,
273,16,180,3,0,
273,16,182,4,0,
273,16,202,3,0,
273,16,206,4,0,
273,16,207,4,0,
273,16,213,4,0,
273,16,214,4,0,
273,16,216,4,0,
273,16,218,4,0,
273,16,235,1,21,1
273,16,235,3,0,
273,16,237,4,0,
273,16,241,1,27,1
273,16,241,4,0,
273,16,247,4,0,
273,16,249,4,0,
273,16,251,2,0,
273,16,263,4,0,
273,16,267,1,15,1
273,16,267,4,0,
273,16,290,4,0,
273,16,331,2,0,
273,16,384,2,0,
273,16,388,2,0,
273,16,388,3,0,
273,16,402,3,0,
273,16,412,4,0,
273,16,417,2,0,
273,16,432,2,0,
273,16,447,4,0,
273,16,492,2,0,
273,16,492,3,0,
273,16,496,4,0,
273,16,514,4,0,
273,16,580,2,0,
273,16,590,4,0,
273,17,13,2,0,
273,17,14,4,0,
273,17,36,2,0,
273,17,73,2,0,
273,17,74,1,9,
273,17,76,4,0,
273,17,92,4,0,
273,17,98,2,0,
273,17,104,4,0,
273,17,106,1,3,
273,17,117,1,1,
273,17,133,2,0,
273,17,153,1,33,
273,17,153,4,0,
273,17,156,4,0,
273,17,164,4,0,
273,17,182,4,0,
273,17,206,4,0,
273,17,207,4,0,
273,17,213,4,0,
273,17,214,4,0,
273,17,216,4,0,
273,17,218,4,0,
273,17,235,1,21,
273,17,237,4,0,
273,17,241,1,27,
273,17,241,4,0,
273,17,247,4,0,
273,17,251,2,0,
273,17,263,4,0,
273,17,267,1,15,
273,17,267,4,0,
273,17,331,2,0,
273,17,384,2,0,
273,17,388,2,0,
273,17,412,4,0,
273,17,417,2,0,
273,17,432,2,0,
273,17,447,4,0,
273,17,492,2,0,
273,17,496,4,0,
273,17,580,2,0,
273,17,590,4,0,
273,18,13,2,0,
273,18,14,4,0,
273,18,36,2,0,
273,18,73,2,0,
273,18,74,1,9,
273,18,76,4,0,
273,18,92,4,0,
273,18,98,2,0,
273,18,104,4,0,
273,18,106,1,3,
273,18,117,1,1,
273,18,133,2,0,
273,18,153,1,33,
273,18,153,4,0,
273,18,156,4,0,
273,18,164,4,0,
273,18,182,4,0,
273,18,206,4,0,
273,18,207,4,0,
273,18,213,4,0,
273,18,214,4,0,
273,18,216,4,0,
273,18,218,4,0,
273,18,235,1,21,
273,18,237,4,0,
273,18,241,1,27,
273,18,241,4,0,
273,18,247,4,0,
273,18,251,2,0,
273,18,263,4,0,
273,18,267,1,15,
273,18,267,4,0,
273,18,331,2,0,
273,18,384,2,0,
273,18,388,2,0,
273,18,412,4,0,
273,18,417,2,0,
273,18,432,2,0,
273,18,447,4,0,
273,18,492,2,0,
273,18,496,4,0,
273,18,580,2,0,
273,18,590,4,0,
274,5,1,1,1,
274,5,13,1,37,
274,5,15,4,0,
274,5,63,4,0,
274,5,70,4,0,
274,5,74,1,7,
274,5,76,4,0,
274,5,91,4,0,
274,5,92,4,0,
274,5,104,4,0,
274,5,106,1,3,
274,5,148,4,0,
274,5,156,4,0,
274,5,168,4,0,
274,5,182,4,0,
274,5,185,1,31,
274,5,202,4,0,
274,5,207,1,43,
274,5,213,4,0,
274,5,216,4,0,
274,5,218,4,0,
274,5,237,4,0,
274,5,241,4,0,
274,5,247,4,0,
274,5,249,4,0,
274,5,252,1,19,
274,5,259,1,25,
274,5,259,4,0,
274,5,263,4,0,
274,5,267,1,13,
274,5,280,4,0,
274,5,290,4,0,
274,5,317,4,0,
274,5,326,1,49,
274,5,331,4,0,
274,6,1,1,1,
274,6,13,1,37,
274,6,14,3,0,
274,6,15,4,0,
274,6,25,3,0,
274,6,34,3,0,
274,6,38,3,0,
274,6,63,4,0,
274,6,70,4,0,
274,6,74,1,7,
274,6,76,4,0,
274,6,91,4,0,
274,6,92,4,0,
274,6,102,3,0,
274,6,104,4,0,
274,6,106,1,3,
274,6,111,3,0,
274,6,129,3,0,
274,6,148,4,0,
274,6,153,3,0,
274,6,156,4,0,
274,6,164,3,0,
274,6,168,4,0,
274,6,173,3,0,
274,6,182,4,0,
274,6,185,1,31,
274,6,189,3,0,
274,6,202,4,0,
274,6,203,3,0,
274,6,205,3,0,
274,6,207,1,43,
274,6,207,3,0,
274,6,210,3,0,
274,6,213,4,0,
274,6,214,3,0,
274,6,216,4,0,
274,6,218,4,0,
274,6,237,4,0,
274,6,241,4,0,
274,6,244,3,0,
274,6,247,4,0,
274,6,249,4,0,
274,6,252,1,19,
274,6,259,1,25,
274,6,259,4,0,
274,6,263,4,0,
274,6,267,1,13,
274,6,280,4,0,
274,6,290,4,0,
274,6,317,4,0,
274,6,326,1,49,
274,6,331,4,0,
274,7,1,1,1,
274,7,13,1,37,
274,7,14,3,0,
274,7,15,4,0,
274,7,25,3,0,
274,7,34,3,0,
274,7,38,3,0,
274,7,63,4,0,
274,7,70,4,0,
274,7,74,1,7,
274,7,76,4,0,
274,7,91,4,0,
274,7,92,4,0,
274,7,102,3,0,
274,7,104,4,0,
274,7,106,1,3,
274,7,148,4,0,
274,7,153,3,0,
274,7,156,4,0,
274,7,164,3,0,
274,7,168,4,0,
274,7,182,4,0,
274,7,185,1,31,
274,7,202,4,0,
274,7,207,1,43,
274,7,213,4,0,
274,7,216,4,0,
274,7,218,4,0,
274,7,237,4,0,
274,7,241,4,0,
274,7,247,4,0,
274,7,249,4,0,
274,7,252,1,19,
274,7,259,1,25,
274,7,259,4,0,
274,7,263,4,0,
274,7,267,1,13,
274,7,280,4,0,
274,7,290,4,0,
274,7,317,4,0,
274,7,326,1,49,
274,7,331,4,0,
274,8,1,1,1,2
274,8,13,1,37,
274,8,14,4,0,
274,8,15,4,0,
274,8,63,4,0,
274,8,70,4,0,
274,8,74,1,7,
274,8,75,1,1,1
274,8,76,4,0,
274,8,91,4,0,
274,8,92,4,0,
274,8,104,4,0,
274,8,106,1,3,
274,8,148,4,0,
274,8,153,4,0,
274,8,156,4,0,
274,8,157,4,0,
274,8,164,4,0,
274,8,168,4,0,
274,8,182,4,0,
274,8,185,1,31,
274,8,202,4,0,
274,8,203,4,0,
274,8,207,1,43,
274,8,207,4,0,
274,8,213,4,0,
274,8,214,4,0,
274,8,216,4,0,
274,8,218,4,0,
274,8,237,4,0,
274,8,241,4,0,
274,8,244,4,0,
274,8,247,4,0,
274,8,249,4,0,
274,8,252,1,19,
274,8,259,1,25,
274,8,259,4,0,
274,8,263,4,0,
274,8,267,1,13,
274,8,280,4,0,
274,8,290,4,0,
274,8,317,4,0,
274,8,326,1,49,
274,8,331,4,0,
274,8,363,4,0,
274,8,371,4,0,
274,8,373,4,0,
274,8,374,4,0,
274,8,399,4,0,
274,8,412,4,0,
274,8,445,4,0,
274,8,447,4,0,
274,9,1,1,1,2
274,9,13,1,37,
274,9,14,4,0,
274,9,15,4,0,
274,9,63,4,0,
274,9,70,4,0,
274,9,74,1,7,
274,9,75,1,1,1
274,9,76,4,0,
274,9,91,4,0,
274,9,92,4,0,
274,9,104,4,0,
274,9,106,1,3,
274,9,129,3,0,
274,9,148,4,0,
274,9,153,4,0,
274,9,156,4,0,
274,9,157,4,0,
274,9,164,4,0,
274,9,168,4,0,
274,9,173,3,0,
274,9,180,3,0,
274,9,182,4,0,
274,9,185,1,31,
274,9,189,3,0,
274,9,202,4,0,
274,9,203,4,0,
274,9,205,3,0,
274,9,207,1,43,
274,9,207,4,0,
274,9,210,3,0,
274,9,213,4,0,
274,9,214,4,0,
274,9,216,4,0,
274,9,218,4,0,
274,9,235,3,0,
274,9,237,4,0,
274,9,241,4,0,
274,9,244,4,0,
274,9,247,4,0,
274,9,249,4,0,
274,9,252,1,19,
274,9,259,1,25,
274,9,259,4,0,
274,9,263,4,0,
274,9,267,1,13,
274,9,280,4,0,
274,9,290,4,0,
274,9,317,4,0,
274,9,326,1,49,
274,9,331,4,0,
274,9,363,4,0,
274,9,371,4,0,
274,9,373,4,0,
274,9,374,4,0,
274,9,399,4,0,
274,9,402,3,0,
274,9,412,4,0,
274,9,445,4,0,
274,9,447,4,0,
274,10,1,1,1,2
274,10,13,1,37,
274,10,14,4,0,
274,10,15,4,0,
274,10,29,3,0,
274,10,63,4,0,
274,10,67,3,0,
274,10,70,4,0,
274,10,74,1,7,
274,10,75,1,1,1
274,10,76,4,0,
274,10,91,4,0,
274,10,92,4,0,
274,10,104,4,0,
274,10,106,1,3,
274,10,129,3,0,
274,10,148,4,0,
274,10,153,4,0,
274,10,156,4,0,
274,10,157,4,0,
274,10,164,4,0,
274,10,168,4,0,
274,10,173,3,0,
274,10,180,3,0,
274,10,182,4,0,
274,10,185,1,31,
274,10,189,3,0,
274,10,202,4,0,
274,10,203,4,0,
274,10,205,3,0,
274,10,207,1,43,
274,10,207,4,0,
274,10,210,3,0,
274,10,213,4,0,
274,10,214,4,0,
274,10,216,4,0,
274,10,218,4,0,
274,10,235,3,0,
274,10,237,4,0,
274,10,241,4,0,
274,10,244,4,0,
274,10,247,4,0,
274,10,249,4,0,
274,10,252,1,19,
274,10,259,1,25,
274,10,259,4,0,
274,10,263,4,0,
274,10,267,1,13,
274,10,280,4,0,
274,10,290,4,0,
274,10,317,4,0,
274,10,326,1,49,
274,10,331,4,0,
274,10,363,4,0,
274,10,371,4,0,
274,10,373,4,0,
274,10,374,4,0,
274,10,388,3,0,
274,10,399,4,0,
274,10,402,3,0,
274,10,412,4,0,
274,10,445,4,0,
274,10,447,4,0,
274,11,1,1,1,2
274,11,13,1,37,
274,11,14,4,0,
274,11,15,4,0,
274,11,63,4,0,
274,11,70,4,0,
274,11,74,1,7,
274,11,75,1,1,1
274,11,76,4,0,
274,11,91,4,0,
274,11,92,4,0,
274,11,104,4,0,
274,11,106,1,3,
274,11,148,4,0,
274,11,153,4,0,
274,11,156,4,0,
274,11,157,4,0,
274,11,164,4,0,
274,11,168,4,0,
274,11,182,4,0,
274,11,185,1,31,
274,11,206,4,0,
274,11,207,1,43,
274,11,207,4,0,
274,11,213,4,0,
274,11,216,4,0,
274,11,218,4,0,
274,11,237,4,0,
274,11,241,4,0,
274,11,244,4,0,
274,11,247,4,0,
274,11,249,4,0,
274,11,252,1,19,
274,11,259,1,25,
274,11,259,4,0,
274,11,263,4,0,
274,11,267,1,13,
274,11,280,4,0,
274,11,317,4,0,
274,11,326,1,49,
274,11,371,4,0,
274,11,373,4,0,
274,11,374,4,0,
274,11,412,4,0,
274,11,447,4,0,
274,11,490,4,0,
274,11,496,4,0,
274,11,514,4,0,
274,11,555,4,0,
274,12,1,1,1,
274,12,13,1,37,
274,12,15,4,0,
274,12,63,4,0,
274,12,70,4,0,
274,12,74,1,7,
274,12,76,4,0,
274,12,91,4,0,
274,12,92,4,0,
274,12,104,4,0,
274,12,106,1,3,
274,12,148,4,0,
274,12,156,4,0,
274,12,168,4,0,
274,12,182,4,0,
274,12,185,1,31,
274,12,202,4,0,
274,12,207,1,43,
274,12,213,4,0,
274,12,216,4,0,
274,12,218,4,0,
274,12,237,4,0,
274,12,241,4,0,
274,12,247,4,0,
274,12,249,4,0,
274,12,252,1,19,
274,12,259,1,25,
274,12,259,4,0,
274,12,263,4,0,
274,12,267,1,13,
274,12,280,4,0,
274,12,290,4,0,
274,12,317,4,0,
274,12,326,1,49,
274,12,331,4,0,
274,13,1,1,1,
274,13,13,1,37,
274,13,15,4,0,
274,13,34,3,0,
274,13,38,3,0,
274,13,63,4,0,
274,13,70,4,0,
274,13,74,1,7,
274,13,76,4,0,
274,13,91,4,0,
274,13,92,4,0,
274,13,102,3,0,
274,13,104,4,0,
274,13,106,1,3,
274,13,120,3,0,
274,13,148,4,0,
274,13,156,4,0,
274,13,164,3,0,
274,13,168,4,0,
274,13,182,4,0,
274,13,185,1,31,
274,13,202,4,0,
274,13,207,1,43,
274,13,207,3,0,
274,13,213,4,0,
274,13,216,4,0,
274,13,218,4,0,
274,13,237,4,0,
274,13,241,4,0,
274,13,247,4,0,
274,13,249,4,0,
274,13,252,1,19,
274,13,259,1,25,
274,13,259,4,0,
274,13,263,4,0,
274,13,267,1,13,
274,13,280,4,0,
274,13,290,4,0,
274,13,317,4,0,
274,13,326,1,49,
274,13,331,4,0,
274,14,1,1,1,2
274,14,13,1,37,
274,14,14,4,0,
274,14,15,4,0,
274,14,63,4,0,
274,14,67,3,0,
274,14,70,4,0,
274,14,74,1,7,
274,14,75,1,1,1
274,14,76,4,0,
274,14,91,4,0,
274,14,92,4,0,
274,14,104,4,0,
274,14,106,1,3,
274,14,148,4,0,
274,14,153,4,0,
274,14,156,4,0,
274,14,157,4,0,
274,14,164,4,0,
274,14,168,4,0,
274,14,173,3,0,
274,14,180,3,0,
274,14,182,4,0,
274,14,185,1,31,
274,14,202,3,0,
274,14,206,4,0,
274,14,207,1,43,
274,14,207,4,0,
274,14,213,4,0,
274,14,214,3,0,
274,14,216,4,0,
274,14,218,4,0,
274,14,235,3,0,
274,14,237,4,0,
274,14,241,4,0,
274,14,244,4,0,
274,14,247,4,0,
274,14,249,4,0,
274,14,252,1,19,
274,14,259,1,25,
274,14,259,4,0,
274,14,263,4,0,
274,14,267,1,13,
274,14,280,4,0,
274,14,317,4,0,
274,14,326,1,49,
274,14,371,4,0,
274,14,373,4,0,
274,14,374,4,0,
274,14,388,3,0,
274,14,399,3,0,
274,14,402,3,0,
274,14,412,4,0,
274,14,447,4,0,
274,14,490,4,0,
274,14,492,3,0,
274,14,496,4,0,
274,14,514,4,0,
274,14,555,4,0,
274,15,1,1,1,2
274,15,13,1,37,
274,15,14,4,0,
274,15,15,4,0,
274,15,63,4,0,
274,15,70,4,0,
274,15,74,1,7,
274,15,75,1,1,1
274,15,76,4,0,
274,15,91,4,0,
274,15,92,4,0,
274,15,104,4,0,
274,15,106,1,3,
274,15,148,4,0,
274,15,153,4,0,
274,15,156,4,0,
274,15,157,4,0,
274,15,164,4,0,
274,15,168,4,0,
274,15,182,4,0,
274,15,185,1,31,
274,15,206,4,0,
274,15,207,1,43,
274,15,207,4,0,
274,15,213,4,0,
274,15,214,4,0,
274,15,216,4,0,
274,15,218,4,0,
274,15,237,4,0,
274,15,241,4,0,
274,15,244,4,0,
274,15,247,4,0,
274,15,249,4,0,
274,15,252,1,19,
274,15,259,1,25,
274,15,259,4,0,
274,15,263,4,0,
274,15,267,1,13,
274,15,267,4,0,
274,15,280,4,0,
274,15,317,4,0,
274,15,326,1,49,
274,15,371,4,0,
274,15,373,4,0,
274,15,374,4,0,
274,15,399,4,0,
274,15,412,4,0,
274,15,447,4,0,
274,15,490,4,0,
274,15,496,4,0,
274,15,514,4,0,
274,15,555,4,0,
274,15,590,4,0,
274,15,612,4,0,
274,16,1,1,1,1
274,16,13,1,20,1
274,16,14,4,0,
274,16,15,4,0,
274,16,63,4,0,
274,16,67,3,0,
274,16,70,4,0,
274,16,74,1,6,1
274,16,75,1,14,1
274,16,76,4,0,
274,16,91,4,0,
274,16,92,4,0,
274,16,104,4,0,
274,16,106,1,3,1
274,16,148,4,0,
274,16,153,4,0,
274,16,156,4,0,
274,16,157,4,0,
274,16,164,4,0,
274,16,168,4,0,
274,16,173,3,0,
274,16,180,3,0,
274,16,182,4,0,
274,16,185,1,24,1
274,16,202,3,0,
274,16,206,4,0,
274,16,207,1,32,1
274,16,207,4,0,
274,16,213,4,0,
274,16,214,4,0,
274,16,216,4,0,
274,16,218,4,0,
274,16,235,3,0,
274,16,237,4,0,
274,16,241,4,0,
274,16,244,4,0,
274,16,247,4,0,
274,16,249,4,0,
274,16,252,1,12,1
274,16,259,1,16,1
274,16,259,4,0,
274,16,263,4,0,
274,16,267,1,9,1
274,16,267,4,0,
274,16,280,4,0,
274,16,290,4,0,
274,16,317,4,0,
274,16,326,1,36,1
274,16,348,1,28,1
274,16,371,4,0,
274,16,373,4,0,
274,16,374,4,0,
274,16,388,3,0,
274,16,399,4,0,
274,16,402,3,0,
274,16,412,4,0,
274,16,447,4,0,
274,16,490,4,0,
274,16,492,3,0,
274,16,496,4,0,
274,16,514,4,0,
274,16,555,4,0,
274,16,590,4,0,
274,16,612,4,0,
274,17,1,1,1,2
274,17,13,1,20,
274,17,14,4,0,
274,17,63,4,0,
274,17,74,1,6,
274,17,75,1,0,
274,17,75,1,1,1
274,17,76,4,0,
274,17,92,4,0,
274,17,104,4,0,
274,17,106,1,3,
274,17,153,4,0,
274,17,156,4,0,
274,17,157,4,0,
274,17,164,4,0,
274,17,168,4,0,
274,17,182,4,0,
274,17,185,1,24,
274,17,206,4,0,
274,17,207,1,32,
274,17,207,4,0,
274,17,213,4,0,
274,17,214,4,0,
274,17,216,4,0,
274,17,218,4,0,
274,17,237,4,0,
274,17,241,4,0,
274,17,244,4,0,
274,17,247,4,0,
274,17,252,1,12,
274,17,259,1,9,
274,17,259,4,0,
274,17,263,4,0,
274,17,267,1,16,
274,17,267,4,0,
274,17,280,4,0,
274,17,317,4,0,
274,17,326,1,36,
274,17,348,1,28,
274,17,371,4,0,
274,17,373,4,0,
274,17,374,4,0,
274,17,399,4,0,
274,17,412,4,0,
274,17,447,4,0,
274,17,490,4,0,
274,17,496,4,0,
274,17,555,4,0,
274,17,590,4,0,
274,18,1,1,1,2
274,18,13,1,20,
274,18,14,4,0,
274,18,63,4,0,
274,18,74,1,6,
274,18,75,1,0,
274,18,75,1,1,1
274,18,76,4,0,
274,18,92,4,0,
274,18,104,4,0,
274,18,106,1,3,
274,18,153,4,0,
274,18,156,4,0,
274,18,157,4,0,
274,18,164,4,0,
274,18,168,4,0,
274,18,182,4,0,
274,18,185,1,24,
274,18,206,4,0,
274,18,207,1,32,
274,18,207,4,0,
274,18,213,4,0,
274,18,214,4,0,
274,18,216,4,0,
274,18,218,4,0,
274,18,237,4,0,
274,18,241,4,0,
274,18,244,4,0,
274,18,247,4,0,
274,18,252,1,12,
274,18,259,1,9,
274,18,259,4,0,
274,18,263,4,0,
274,18,267,1,16,
274,18,267,4,0,
274,18,280,4,0,
274,18,317,4,0,
274,18,326,1,36,
274,18,348,1,28,
274,18,371,4,0,
274,18,373,4,0,
274,18,374,4,0,
274,18,399,4,0,
274,18,412,4,0,
274,18,447,4,0,
274,18,490,4,0,
274,18,496,4,0,
274,18,555,4,0,
274,18,590,4,0,
275,5,1,1,1,1
275,5,15,4,0,
275,5,63,4,0,
275,5,70,4,0,
275,5,74,1,1,3
275,5,76,4,0,
275,5,91,4,0,
275,5,92,4,0,
275,5,104,4,0,
275,5,106,1,1,2
275,5,148,4,0,
275,5,156,4,0,
275,5,168,4,0,
275,5,182,4,0,
275,5,202,4,0,
275,5,213,4,0,
275,5,216,4,0,
275,5,218,4,0,
275,5,237,4,0,
275,5,241,4,0,
275,5,247,4,0,
275,5,249,4,0,
275,5,259,4,0,
275,5,263,4,0,
275,5,267,1,1,4
275,5,280,4,0,
275,5,290,4,0,
275,5,317,4,0,
275,5,331,4,0,
275,5,332,4,0,
275,6,1,1,1,1
275,6,14,3,0,
275,6,15,4,0,
275,6,25,3,0,
275,6,34,3,0,
275,6,38,3,0,
275,6,63,4,0,
275,6,70,4,0,
275,6,74,1,1,3
275,6,76,4,0,
275,6,91,4,0,
275,6,92,4,0,
275,6,102,3,0,
275,6,104,4,0,
275,6,106,1,1,2
275,6,111,3,0,
275,6,129,3,0,
275,6,148,4,0,
275,6,153,3,0,
275,6,156,4,0,
275,6,164,3,0,
275,6,168,4,0,
275,6,173,3,0,
275,6,182,4,0,
275,6,189,3,0,
275,6,202,4,0,
275,6,203,3,0,
275,6,205,3,0,
275,6,207,3,0,
275,6,210,3,0,
275,6,213,4,0,
275,6,214,3,0,
275,6,216,4,0,
275,6,218,4,0,
275,6,237,4,0,
275,6,241,4,0,
275,6,244,3,0,
275,6,247,4,0,
275,6,249,4,0,
275,6,259,4,0,
275,6,263,4,0,
275,6,267,1,1,4
275,6,280,4,0,
275,6,290,4,0,
275,6,317,4,0,
275,6,331,4,0,
275,6,332,4,0,
275,7,1,1,1,1
275,7,14,3,0,
275,7,15,4,0,
275,7,25,3,0,
275,7,34,3,0,
275,7,38,3,0,
275,7,63,4,0,
275,7,70,4,0,
275,7,74,1,1,3
275,7,76,4,0,
275,7,91,4,0,
275,7,92,4,0,
275,7,102,3,0,
275,7,104,4,0,
275,7,106,1,1,2
275,7,148,4,0,
275,7,153,3,0,
275,7,156,4,0,
275,7,164,3,0,
275,7,168,4,0,
275,7,182,4,0,
275,7,202,4,0,
275,7,213,4,0,
275,7,216,4,0,
275,7,218,4,0,
275,7,237,4,0,
275,7,241,4,0,
275,7,247,4,0,
275,7,249,4,0,
275,7,259,4,0,
275,7,263,4,0,
275,7,267,1,1,4
275,7,280,4,0,
275,7,290,4,0,
275,7,317,4,0,
275,7,331,4,0,
275,7,332,4,0,
275,8,14,4,0,
275,8,15,4,0,
275,8,18,1,1,2
275,8,63,4,0,
275,8,70,4,0,
275,8,75,1,1,4
275,8,76,4,0,
275,8,91,4,0,
275,8,92,4,0,
275,8,104,4,0,
275,8,148,4,0,
275,8,153,4,0,
275,8,156,4,0,
275,8,157,4,0,
275,8,164,4,0,
275,8,168,4,0,
275,8,182,4,0,
275,8,185,1,1,1
275,8,202,4,0,
275,8,203,4,0,
275,8,207,4,0,
275,8,213,4,0,
275,8,214,4,0,
275,8,216,4,0,
275,8,218,4,0,
275,8,237,4,0,
275,8,241,4,0,
275,8,244,4,0,
275,8,247,4,0,
275,8,249,4,0,
275,8,259,4,0,
275,8,263,4,0,
275,8,280,4,0,
275,8,290,4,0,
275,8,317,4,0,
275,8,318,4,0,
275,8,331,4,0,
275,8,332,4,0,
275,8,363,4,0,
275,8,371,4,0,
275,8,373,4,0,
275,8,374,4,0,
275,8,399,4,0,
275,8,404,4,0,
275,8,411,4,0,
275,8,412,4,0,
275,8,416,4,0,
275,8,417,1,1,3
275,8,432,4,0,
275,8,437,1,49,
275,8,445,4,0,
275,8,447,4,0,
275,9,14,4,0,
275,9,15,4,0,
275,9,18,1,1,2
275,9,63,4,0,
275,9,70,4,0,
275,9,75,1,1,4
275,9,76,4,0,
275,9,91,4,0,
275,9,92,4,0,
275,9,104,4,0,
275,9,129,3,0,
275,9,148,4,0,
275,9,153,4,0,
275,9,156,4,0,
275,9,157,4,0,
275,9,164,4,0,
275,9,168,4,0,
275,9,173,3,0,
275,9,180,3,0,
275,9,182,4,0,
275,9,185,1,1,1
275,9,189,3,0,
275,9,196,3,0,
275,9,202,4,0,
275,9,203,4,0,
275,9,205,3,0,
275,9,207,4,0,
275,9,210,3,0,
275,9,213,4,0,
275,9,214,4,0,
275,9,216,4,0,
275,9,218,4,0,
275,9,235,3,0,
275,9,237,4,0,
275,9,239,3,0,
275,9,241,4,0,
275,9,244,4,0,
275,9,247,4,0,
275,9,249,4,0,
275,9,259,4,0,
275,9,263,4,0,
275,9,280,4,0,
275,9,282,3,0,
275,9,290,4,0,
275,9,314,3,0,
275,9,317,4,0,
275,9,318,4,0,
275,9,331,4,0,
275,9,332,4,0,
275,9,340,3,0,
275,9,363,4,0,
275,9,371,4,0,
275,9,373,4,0,
275,9,374,4,0,
275,9,389,3,0,
275,9,399,4,0,
275,9,402,3,0,
275,9,404,4,0,
275,9,411,4,0,
275,9,412,4,0,
275,9,416,4,0,
275,9,417,1,1,3
275,9,432,4,0,
275,9,437,1,49,
275,9,445,4,0,
275,9,447,4,0,
275,9,466,3,0,
275,10,14,4,0,
275,10,15,4,0,
275,10,18,1,1,2
275,10,29,3,0,
275,10,63,4,0,
275,10,67,3,0,
275,10,70,4,0,
275,10,75,1,1,4
275,10,76,4,0,
275,10,91,4,0,
275,10,92,4,0,
275,10,104,4,0,
275,10,129,3,0,
275,10,148,4,0,
275,10,153,4,0,
275,10,156,4,0,
275,10,157,4,0,
275,10,164,4,0,
275,10,168,4,0,
275,10,173,3,0,
275,10,180,3,0,
275,10,182,4,0,
275,10,185,1,1,1
275,10,189,3,0,
275,10,196,3,0,
275,10,202,4,0,
275,10,203,4,0,
275,10,205,3,0,
275,10,207,4,0,
275,10,210,3,0,
275,10,213,4,0,
275,10,214,4,0,
275,10,216,4,0,
275,10,218,4,0,
275,10,235,3,0,
275,10,237,4,0,
275,10,239,3,0,
275,10,241,4,0,
275,10,244,4,0,
275,10,247,4,0,
275,10,249,4,0,
275,10,259,4,0,
275,10,263,4,0,
275,10,280,4,0,
275,10,282,3,0,
275,10,290,4,0,
275,10,314,3,0,
275,10,317,4,0,
275,10,318,4,0,
275,10,331,4,0,
275,10,332,4,0,
275,10,340,3,0,
275,10,363,4,0,
275,10,366,3,0,
275,10,371,4,0,
275,10,373,4,0,
275,10,374,4,0,
275,10,388,3,0,
275,10,389,3,0,
275,10,399,4,0,
275,10,402,3,0,
275,10,404,4,0,
275,10,411,4,0,
275,10,412,4,0,
275,10,416,4,0,
275,10,417,1,1,3
275,10,437,1,49,
275,10,445,4,0,
275,10,447,4,0,
275,10,466,3,0,
275,11,14,4,0,
275,11,15,4,0,
275,11,18,1,1,2
275,11,63,4,0,
275,11,70,4,0,
275,11,75,1,1,4
275,11,76,4,0,
275,11,91,4,0,
275,11,92,4,0,
275,11,104,4,0,
275,11,148,4,0,
275,11,153,4,0,
275,11,156,4,0,
275,11,157,4,0,
275,11,164,4,0,
275,11,168,4,0,
275,11,182,4,0,
275,11,185,1,1,1
275,11,206,4,0,
275,11,207,4,0,
275,11,213,4,0,
275,11,216,4,0,
275,11,218,4,0,
275,11,237,4,0,
275,11,241,4,0,
275,11,244,4,0,
275,11,247,4,0,
275,11,249,4,0,
275,11,259,4,0,
275,11,263,4,0,
275,11,280,4,0,
275,11,317,4,0,
275,11,332,4,0,
275,11,371,4,0,
275,11,373,4,0,
275,11,374,4,0,
275,11,404,4,0,
275,11,411,4,0,
275,11,412,4,0,
275,11,416,4,0,
275,11,417,1,1,3
275,11,437,1,49,
275,11,447,4,0,
275,11,490,4,0,
275,11,496,4,0,
275,11,514,4,0,
275,11,536,1,19,
275,11,555,4,0,
275,12,1,1,1,1
275,12,15,4,0,
275,12,63,4,0,
275,12,70,4,0,
275,12,74,1,1,3
275,12,76,4,0,
275,12,91,4,0,
275,12,92,4,0,
275,12,104,4,0,
275,12,106,1,1,2
275,12,148,4,0,
275,12,156,4,0,
275,12,168,4,0,
275,12,182,4,0,
275,12,202,4,0,
275,12,213,4,0,
275,12,216,4,0,
275,12,218,4,0,
275,12,237,4,0,
275,12,241,4,0,
275,12,247,4,0,
275,12,249,4,0,
275,12,259,4,0,
275,12,263,4,0,
275,12,267,1,1,4
275,12,280,4,0,
275,12,290,4,0,
275,12,317,4,0,
275,12,331,4,0,
275,12,332,4,0,
275,13,1,1,1,1
275,13,15,4,0,
275,13,34,3,0,
275,13,38,3,0,
275,13,63,4,0,
275,13,70,4,0,
275,13,74,1,1,3
275,13,76,4,0,
275,13,91,4,0,
275,13,92,4,0,
275,13,102,3,0,
275,13,104,4,0,
275,13,106,1,1,2
275,13,120,3,0,
275,13,148,4,0,
275,13,156,4,0,
275,13,164,3,0,
275,13,168,4,0,
275,13,182,4,0,
275,13,202,4,0,
275,13,207,3,0,
275,13,213,4,0,
275,13,216,4,0,
275,13,218,4,0,
275,13,237,4,0,
275,13,241,4,0,
275,13,247,4,0,
275,13,249,4,0,
275,13,259,4,0,
275,13,263,4,0,
275,13,267,1,1,4
275,13,280,4,0,
275,13,290,4,0,
275,13,317,4,0,
275,13,331,4,0,
275,13,332,4,0,
275,14,14,4,0,
275,14,15,4,0,
275,14,18,1,1,2
275,14,63,4,0,
275,14,67,3,0,
275,14,70,4,0,
275,14,75,1,1,4
275,14,76,4,0,
275,14,91,4,0,
275,14,92,4,0,
275,14,104,4,0,
275,14,148,4,0,
275,14,153,4,0,
275,14,156,4,0,
275,14,157,4,0,
275,14,164,4,0,
275,14,168,4,0,
275,14,173,3,0,
275,14,180,3,0,
275,14,182,4,0,
275,14,185,1,1,1
275,14,196,3,0,
275,14,202,3,0,
275,14,206,4,0,
275,14,207,4,0,
275,14,213,4,0,
275,14,214,3,0,
275,14,216,4,0,
275,14,218,4,0,
275,14,235,3,0,
275,14,237,4,0,
275,14,241,4,0,
275,14,244,4,0,
275,14,247,4,0,
275,14,249,4,0,
275,14,259,4,0,
275,14,263,4,0,
275,14,280,4,0,
275,14,282,3,0,
275,14,317,4,0,
275,14,332,4,0,
275,14,340,3,0,
275,14,366,3,0,
275,14,371,4,0,
275,14,373,4,0,
275,14,374,4,0,
275,14,388,3,0,
275,14,399,3,0,
275,14,402,3,0,
275,14,404,4,0,
275,14,411,4,0,
275,14,412,4,0,
275,14,416,4,0,
275,14,417,1,1,3
275,14,437,1,49,
275,14,447,4,0,
275,14,490,4,0,
275,14,492,3,0,
275,14,496,4,0,
275,14,514,4,0,
275,14,536,1,19,
275,14,555,4,0,
275,15,14,4,0,
275,15,15,4,0,
275,15,18,1,1,2
275,15,63,4,0,
275,15,70,4,0,
275,15,75,1,1,4
275,15,76,4,0,
275,15,91,4,0,
275,15,92,4,0,
275,15,104,4,0,
275,15,148,4,0,
275,15,153,4,0,
275,15,156,4,0,
275,15,157,4,0,
275,15,164,4,0,
275,15,168,4,0,
275,15,182,4,0,
275,15,185,1,1,1
275,15,206,4,0,
275,15,207,4,0,
275,15,213,4,0,
275,15,214,4,0,
275,15,216,4,0,
275,15,218,4,0,
275,15,237,4,0,
275,15,241,4,0,
275,15,244,4,0,
275,15,247,4,0,
275,15,249,4,0,
275,15,259,4,0,
275,15,263,4,0,
275,15,267,4,0,
275,15,280,4,0,
275,15,317,4,0,
275,15,332,4,0,
275,15,371,4,0,
275,15,373,4,0,
275,15,374,4,0,
275,15,399,4,0,
275,15,404,4,0,
275,15,411,4,0,
275,15,412,4,0,
275,15,416,4,0,
275,15,417,1,1,3
275,15,437,1,49,
275,15,447,4,0,
275,15,490,4,0,
275,15,496,4,0,
275,15,514,4,0,
275,15,536,1,19,
275,15,555,4,0,
275,15,590,4,0,
275,15,612,4,0,
275,16,14,4,0,
275,16,15,4,0,
275,16,18,1,1,3
275,16,63,4,0,
275,16,67,3,0,
275,16,70,4,0,
275,16,75,1,1,1
275,16,76,4,0,
275,16,91,4,0,
275,16,92,4,0,
275,16,104,4,0,
275,16,148,4,0,
275,16,153,4,0,
275,16,156,4,0,
275,16,157,4,0,
275,16,164,4,0,
275,16,168,4,0,
275,16,173,3,0,
275,16,180,3,0,
275,16,182,4,0,
275,16,185,1,1,2
275,16,196,3,0,
275,16,202,3,0,
275,16,206,4,0,
275,16,207,4,0,
275,16,213,4,0,
275,16,214,4,0,
275,16,216,4,0,
275,16,218,4,0,
275,16,235,3,0,
275,16,237,4,0,
275,16,241,4,0,
275,16,244,4,0,
275,16,247,4,0,
275,16,249,4,0,
275,16,259,4,0,
275,16,263,4,0,
275,16,267,4,0,
275,16,280,4,0,
275,16,282,3,0,
275,16,290,4,0,
275,16,317,4,0,
275,16,332,4,0,
275,16,340,3,0,
275,16,366,3,0,
275,16,371,4,0,
275,16,373,4,0,
275,16,374,4,0,
275,16,388,3,0,
275,16,399,4,0,
275,16,402,3,0,
275,16,404,4,0,
275,16,411,4,0,
275,16,412,4,0,
275,16,416,4,0,
275,16,417,1,1,4
275,16,437,1,44,1
275,16,447,4,0,
275,16,490,4,0,
275,16,492,3,0,
275,16,496,4,0,
275,16,514,4,0,
275,16,536,1,20,1
275,16,542,1,32,1
275,16,555,4,0,
275,16,590,4,0,
275,16,612,4,0,
275,17,14,4,0,
275,17,18,1,1,3
275,17,63,4,0,
275,17,75,1,1,1
275,17,76,4,0,
275,17,92,4,0,
275,17,104,4,0,
275,17,153,4,0,
275,17,156,4,0,
275,17,157,4,0,
275,17,164,4,0,
275,17,168,4,0,
275,17,182,4,0,
275,17,185,1,1,2
275,17,206,4,0,
275,17,207,4,0,
275,17,213,4,0,
275,17,214,4,0,
275,17,216,4,0,
275,17,218,4,0,
275,17,237,4,0,
275,17,241,4,0,
275,17,244,4,0,
275,17,247,4,0,
275,17,259,4,0,
275,17,263,4,0,
275,17,267,4,0,
275,17,280,4,0,
275,17,317,4,0,
275,17,332,4,0,
275,17,371,4,0,
275,17,373,4,0,
275,17,374,4,0,
275,17,399,4,0,
275,17,404,4,0,
275,17,411,4,0,
275,17,412,4,0,
275,17,416,4,0,
275,17,417,1,1,4
275,17,437,1,44,
275,17,447,4,0,
275,17,490,4,0,
275,17,496,4,0,
275,17,536,1,20,
275,17,542,1,32,
275,17,555,4,0,
275,17,590,4,0,
275,17,693,4,0,
275,18,14,4,0,
275,18,18,1,1,3
275,18,63,4,0,
275,18,75,1,1,1
275,18,76,4,0,
275,18,92,4,0,
275,18,104,4,0,
275,18,153,4,0,
275,18,156,4,0,
275,18,157,4,0,
275,18,164,4,0,
275,18,168,4,0,
275,18,182,4,0,
275,18,185,1,1,2
275,18,206,4,0,
275,18,207,4,0,
275,18,213,4,0,
275,18,214,4,0,
275,18,216,4,0,
275,18,218,4,0,
275,18,237,4,0,
275,18,241,4,0,
275,18,244,4,0,
275,18,247,4,0,
275,18,259,4,0,
275,18,263,4,0,
275,18,267,4,0,
275,18,280,4,0,
275,18,317,4,0,
275,18,332,4,0,
275,18,371,4,0,
275,18,373,4,0,
275,18,374,4,0,
275,18,399,4,0,
275,18,404,4,0,
275,18,411,4,0,
275,18,412,4,0,
275,18,416,4,0,
275,18,417,1,1,4
275,18,437,1,44,
275,18,447,4,0,
275,18,490,4,0,
275,18,496,4,0,
275,18,536,1,20,
275,18,542,1,32,
275,18,555,4,0,
275,18,590,4,0,
275,18,693,4,0,
276,5,17,1,13,
276,5,19,4,0,
276,5,45,1,1,2
276,5,48,2,0,
276,5,64,1,1,1
276,5,92,4,0,
276,5,97,1,43,
276,5,98,1,8,
276,5,99,2,0,
276,5,104,1,19,
276,5,104,4,0,
276,5,116,1,4,
276,5,119,2,0,
276,5,143,2,0,
276,5,156,4,0,
276,5,168,4,0,
276,5,182,4,0,
276,5,211,4,0,
276,5,213,4,0,
276,5,216,4,0,
276,5,218,4,0,
276,5,228,2,0,
276,5,237,4,0,
276,5,240,4,0,
276,5,241,4,0,
276,5,263,4,0,
276,5,283,1,26,
276,5,287,2,0,
276,5,290,4,0,
276,5,332,1,34,
276,5,332,4,0,
276,6,17,1,13,
276,6,19,4,0,
276,6,38,3,0,
276,6,45,1,1,2
276,6,48,2,0,
276,6,64,1,1,1
276,6,68,3,0,
276,6,92,4,0,
276,6,97,1,43,
276,6,98,1,8,
276,6,99,2,0,
276,6,102,3,0,
276,6,104,1,19,
276,6,104,4,0,
276,6,116,1,4,
276,6,119,2,0,
276,6,129,3,0,
276,6,143,2,0,
276,6,156,4,0,
276,6,164,3,0,
276,6,168,4,0,
276,6,173,3,0,
276,6,182,4,0,
276,6,189,3,0,
276,6,203,3,0,
276,6,207,3,0,
276,6,211,4,0,
276,6,213,4,0,
276,6,214,3,0,
276,6,216,4,0,
276,6,218,4,0,
276,6,228,2,0,
276,6,237,4,0,
276,6,240,4,0,
276,6,241,4,0,
276,6,263,4,0,
276,6,283,1,26,
276,6,287,2,0,
276,6,290,4,0,
276,6,332,1,34,
276,6,332,4,0,
276,7,17,1,13,
276,7,19,4,0,
276,7,38,3,0,
276,7,45,1,1,2
276,7,48,2,0,
276,7,64,1,1,1
276,7,68,3,0,
276,7,92,4,0,
276,7,97,1,43,
276,7,98,1,8,
276,7,99,2,0,
276,7,102,3,0,
276,7,104,1,19,
276,7,104,4,0,
276,7,116,1,4,
276,7,119,2,0,
276,7,143,2,0,
276,7,156,4,0,
276,7,164,3,0,
276,7,168,4,0,
276,7,182,4,0,
276,7,211,4,0,
276,7,213,4,0,
276,7,216,4,0,
276,7,218,4,0,
276,7,228,2,0,
276,7,237,4,0,
276,7,240,4,0,
276,7,241,4,0,
276,7,263,4,0,
276,7,283,1,26,
276,7,287,2,0,
276,7,290,4,0,
276,7,332,1,34,
276,7,332,4,0,
276,8,17,1,13,
276,8,18,2,0,
276,8,19,4,0,
276,8,45,1,1,2
276,8,48,2,0,
276,8,64,1,1,1
276,8,92,4,0,
276,8,97,1,43,
276,8,98,1,8,
276,8,99,2,0,
276,8,104,1,19,
276,8,104,4,0,
276,8,116,1,4,
276,8,119,2,0,
276,8,143,2,0,
276,8,156,4,0,
276,8,164,4,0,
276,8,168,4,0,
276,8,182,4,0,
276,8,203,4,0,
276,8,207,4,0,
276,8,211,4,0,
276,8,213,4,0,
276,8,214,4,0,
276,8,216,4,0,
276,8,218,4,0,
276,8,228,2,0,
276,8,237,4,0,
276,8,240,4,0,
276,8,241,4,0,
276,8,263,4,0,
276,8,283,1,26,
276,8,287,2,0,
276,8,290,4,0,
276,8,332,1,34,
276,8,332,4,0,
276,8,355,4,0,
276,8,363,4,0,
276,8,365,4,0,
276,8,369,4,0,
276,8,403,1,53,
276,8,413,2,0,
276,8,432,4,0,
276,8,445,4,0,
276,9,17,1,13,
276,9,18,2,0,
276,9,19,4,0,
276,9,45,1,1,2
276,9,48,2,0,
276,9,64,1,1,1
276,9,92,4,0,
276,9,97,1,43,
276,9,98,1,8,
276,9,99,2,0,
276,9,104,1,19,
276,9,104,4,0,
276,9,116,1,4,
276,9,119,2,0,
276,9,129,3,0,
276,9,143,2,0,
276,9,156,4,0,
276,9,164,4,0,
276,9,168,4,0,
276,9,173,3,0,
276,9,182,4,0,
276,9,189,3,0,
276,9,203,4,0,
276,9,207,4,0,
276,9,211,4,0,
276,9,213,4,0,
276,9,214,4,0,
276,9,216,4,0,
276,9,218,4,0,
276,9,228,2,0,
276,9,237,4,0,
276,9,239,3,0,
276,9,240,4,0,
276,9,241,4,0,
276,9,257,3,0,
276,9,263,4,0,
276,9,283,1,26,
276,9,283,3,0,
276,9,287,2,0,
276,9,290,4,0,
276,9,314,3,0,
276,9,332,1,34,
276,9,332,4,0,
276,9,355,4,0,
276,9,363,4,0,
276,9,365,4,0,
276,9,369,4,0,
276,9,403,1,53,
276,9,413,2,0,
276,9,432,4,0,
276,9,445,4,0,
276,9,466,3,0,
276,10,17,1,13,
276,10,18,2,0,
276,10,19,4,0,
276,10,45,1,1,2
276,10,48,2,0,
276,10,64,1,1,1
276,10,92,4,0,
276,10,97,1,43,
276,10,98,1,8,
276,10,99,2,0,
276,10,104,1,19,
276,10,104,4,0,
276,10,116,1,4,
276,10,119,2,0,
276,10,129,3,0,
276,10,143,2,0,
276,10,156,4,0,
276,10,164,4,0,
276,10,168,4,0,
276,10,173,3,0,
276,10,182,4,0,
276,10,189,3,0,
276,10,203,4,0,
276,10,207,4,0,
276,10,211,4,0,
276,10,213,4,0,
276,10,214,4,0,
276,10,216,4,0,
276,10,218,4,0,
276,10,228,2,0,
276,10,237,4,0,
276,10,239,3,0,
276,10,240,4,0,
276,10,241,4,0,
276,10,257,3,0,
276,10,263,4,0,
276,10,283,1,26,
276,10,283,3,0,
276,10,287,2,0,
276,10,290,4,0,
276,10,314,3,0,
276,10,332,1,34,
276,10,332,4,0,
276,10,355,4,0,
276,10,363,4,0,
276,10,365,4,0,
276,10,366,3,0,
276,10,369,4,0,
276,10,403,1,53,
276,10,413,2,0,
276,10,445,4,0,
276,10,466,3,0,
276,11,17,1,13,
276,11,18,2,0,
276,11,19,4,0,
276,11,45,1,1,2
276,11,48,2,0,
276,11,64,1,1,1
276,11,92,4,0,
276,11,97,1,43,
276,11,98,1,8,
276,11,99,2,0,
276,11,104,1,19,
276,11,104,4,0,
276,11,116,1,4,
276,11,119,2,0,
276,11,143,2,0,
276,11,156,4,0,
276,11,164,4,0,
276,11,168,4,0,
276,11,182,4,0,
276,11,207,4,0,
276,11,211,2,0,
276,11,213,4,0,
276,11,216,4,0,
276,11,218,4,0,
276,11,228,2,0,
276,11,237,4,0,
276,11,240,4,0,
276,11,241,4,0,
276,11,263,4,0,
276,11,283,1,26,
276,11,287,2,0,
276,11,332,1,34,
276,11,332,4,0,
276,11,355,2,0,
276,11,365,4,0,
276,11,369,4,0,
276,11,403,1,53,
276,11,413,2,0,
276,11,432,2,0,
276,11,496,4,0,
276,11,497,4,0,
276,11,526,4,0,
276,12,17,1,13,
276,12,19,4,0,
276,12,45,1,1,2
276,12,64,1,1,1
276,12,92,4,0,
276,12,97,1,43,
276,12,98,1,8,
276,12,104,1,19,
276,12,104,4,0,
276,12,116,1,4,
276,12,156,4,0,
276,12,168,4,0,
276,12,182,4,0,
276,12,211,4,0,
276,12,213,4,0,
276,12,216,4,0,
276,12,218,4,0,
276,12,237,4,0,
276,12,240,4,0,
276,12,241,4,0,
276,12,263,4,0,
276,12,283,1,26,
276,12,290,4,0,
276,12,332,1,34,
276,12,332,4,0,
276,13,17,1,13,
276,13,19,4,0,
276,13,38,3,0,
276,13,45,1,1,2
276,13,64,1,1,1
276,13,92,4,0,
276,13,97,1,43,
276,13,98,1,8,
276,13,102,3,0,
276,13,104,1,19,
276,13,104,4,0,
276,13,116,1,4,
276,13,143,3,0,
276,13,156,4,0,
276,13,164,3,0,
276,13,168,4,0,
276,13,182,4,0,
276,13,207,3,0,
276,13,211,4,0,
276,13,213,4,0,
276,13,216,4,0,
276,13,218,4,0,
276,13,237,4,0,
276,13,240,4,0,
276,13,241,4,0,
276,13,263,4,0,
276,13,283,1,26,
276,13,290,4,0,
276,13,332,1,34,
276,13,332,4,0,
276,14,17,1,13,
276,14,18,2,0,
276,14,19,4,0,
276,14,45,1,1,2
276,14,48,2,0,
276,14,64,1,1,1
276,14,92,4,0,
276,14,97,1,43,
276,14,98,1,8,
276,14,99,2,0,
276,14,104,1,19,
276,14,104,4,0,
276,14,116,1,4,
276,14,119,2,0,
276,14,143,2,0,
276,14,156,4,0,
276,14,164,4,0,
276,14,168,4,0,
276,14,173,3,0,
276,14,182,4,0,
276,14,207,4,0,
276,14,211,2,0,
276,14,213,4,0,
276,14,214,3,0,
276,14,216,4,0,
276,14,218,4,0,
276,14,228,2,0,
276,14,237,4,0,
276,14,240,4,0,
276,14,241,4,0,
276,14,257,3,0,
276,14,263,4,0,
276,14,283,1,26,
276,14,283,3,0,
276,14,287,2,0,
276,14,332,1,34,
276,14,332,4,0,
276,14,355,2,0,
276,14,355,3,0,
276,14,365,4,0,
276,14,366,3,0,
276,14,369,4,0,
276,14,403,1,53,
276,14,413,2,0,
276,14,432,2,0,
276,14,496,4,0,
276,14,497,4,0,
276,14,526,4,0,
276,15,17,1,13,
276,15,18,2,0,
276,15,19,4,0,
276,15,45,1,1,2
276,15,48,2,0,
276,15,64,1,1,1
276,15,92,4,0,
276,15,97,1,43,
276,15,98,1,7,
276,15,99,2,0,
276,15,104,1,19,
276,15,104,4,0,
276,15,116,1,4,
276,15,119,2,0,
276,15,143,2,0,
276,15,156,4,0,
276,15,164,4,0,
276,15,168,4,0,
276,15,182,4,0,
276,15,207,4,0,
276,15,211,2,0,
276,15,211,4,0,
276,15,213,4,0,
276,15,214,4,0,
276,15,216,4,0,
276,15,218,4,0,
276,15,228,2,0,
276,15,237,4,0,
276,15,240,4,0,
276,15,241,4,0,
276,15,263,4,0,
276,15,283,1,26,
276,15,287,2,0,
276,15,332,1,34,
276,15,332,4,0,
276,15,355,2,0,
276,15,355,4,0,
276,15,369,4,0,
276,15,403,1,53,
276,15,413,2,0,
276,15,432,2,0,
276,15,496,4,0,
276,15,497,4,0,
276,15,586,2,0,
276,15,590,4,0,
276,16,17,1,13,1
276,16,18,2,0,
276,16,19,4,0,
276,16,45,1,1,2
276,16,48,2,0,
276,16,64,1,1,1
276,16,92,4,0,
276,16,97,1,29,1
276,16,98,1,9,1
276,16,99,2,0,
276,16,104,1,17,1
276,16,104,4,0,
276,16,116,1,5,1
276,16,119,2,0,
276,16,143,2,0,
276,16,143,3,0,
276,16,156,4,0,
276,16,164,4,0,
276,16,168,4,0,
276,16,173,3,0,
276,16,182,4,0,
276,16,207,4,0,
276,16,211,2,0,
276,16,211,4,0,
276,16,213,4,0,
276,16,214,4,0,
276,16,216,4,0,
276,16,218,4,0,
276,16,228,2,0,
276,16,237,4,0,
276,16,240,4,0,
276,16,241,4,0,
276,16,257,3,0,
276,16,263,4,0,
276,16,283,1,37,1
276,16,283,3,0,
276,16,287,2,0,
276,16,290,4,0,
276,16,332,1,21,1
276,16,332,4,0,
276,16,355,2,0,
276,16,355,4,0,
276,16,366,3,0,
276,16,369,4,0,
276,16,403,1,33,1
276,16,413,1,41,1
276,16,413,2,0,
276,16,432,2,0,
276,16,496,4,0,
276,16,497,4,0,
276,16,501,1,25,1
276,16,586,2,0,
276,16,590,4,0,
276,17,17,1,13,
276,17,18,2,0,
276,17,19,4,0,
276,17,45,1,1,2
276,17,48,2,0,
276,17,64,1,1,1
276,17,92,4,0,
276,17,97,1,29,
276,17,98,1,9,
276,17,99,2,0,
276,17,104,1,17,
276,17,104,4,0,
276,17,116,1,5,
276,17,119,2,0,
276,17,143,2,0,
276,17,156,4,0,
276,17,164,4,0,
276,17,168,4,0,
276,17,179,1,45,
276,17,182,4,0,
276,17,207,4,0,
276,17,211,2,0,
276,17,211,4,0,
276,17,213,4,0,
276,17,214,4,0,
276,17,216,4,0,
276,17,218,4,0,
276,17,228,2,0,
276,17,237,4,0,
276,17,240,4,0,
276,17,241,4,0,
276,17,263,4,0,
276,17,283,1,37,
276,17,287,2,0,
276,17,332,1,21,
276,17,332,4,0,
276,17,355,2,0,
276,17,355,4,0,
276,17,369,4,0,
276,17,403,1,33,
276,17,413,1,41,
276,17,413,2,0,
276,17,432,2,0,
276,17,496,4,0,
276,17,497,4,0,
276,17,501,1,25,
276,17,526,4,0,
276,17,542,2,0,
276,17,586,2,0,
276,17,590,4,0,
276,18,17,1,13,
276,18,18,2,0,
276,18,19,4,0,
276,18,45,1,1,2
276,18,48,2,0,
276,18,64,1,1,1
276,18,92,4,0,
276,18,97,1,29,
276,18,98,1,9,
276,18,99,2,0,
276,18,104,1,17,
276,18,104,4,0,
276,18,116,1,5,
276,18,119,2,0,
276,18,143,2,0,
276,18,156,4,0,
276,18,164,4,0,
276,18,168,4,0,
276,18,179,1,45,
276,18,182,4,0,
276,18,207,4,0,
276,18,211,2,0,
276,18,211,4,0,
276,18,213,4,0,
276,18,214,4,0,
276,18,216,4,0,
276,18,218,4,0,
276,18,228,2,0,
276,18,237,4,0,
276,18,240,4,0,
276,18,241,4,0,
276,18,263,4,0,
276,18,283,1,37,
276,18,287,2,0,
276,18,332,1,21,
276,18,332,4,0,
276,18,355,2,0,
276,18,355,4,0,
276,18,369,4,0,
276,18,403,1,33,
276,18,413,1,41,
276,18,413,2,0,
276,18,432,2,0,
276,18,496,4,0,
276,18,497,4,0,
276,18,501,1,25,
276,18,526,4,0,
276,18,542,2,0,
276,18,586,2,0,
276,18,590,4,0,
277,5,17,1,13,
277,5,19,4,0,
277,5,45,1,1,2
277,5,63,4,0,
277,5,64,1,1,1
277,5,92,4,0,
277,5,97,1,49,
277,5,98,1,1,4
277,5,98,1,8,
277,5,104,1,19,
277,5,104,4,0,
277,5,116,1,1,3
277,5,116,1,4,
277,5,156,4,0,
277,5,168,4,0,
277,5,182,4,0,
277,5,211,4,0,
277,5,213,4,0,
277,5,216,4,0,
277,5,218,4,0,
277,5,237,4,0,
277,5,240,4,0,
277,5,241,4,0,
277,5,263,4,0,
277,5,283,1,28,
277,5,290,4,0,
277,5,332,1,38,
277,5,332,4,0,
277,6,17,1,13,
277,6,19,4,0,
277,6,38,3,0,
277,6,45,1,1,2
277,6,63,4,0,
277,6,64,1,1,1
277,6,68,3,0,
277,6,92,4,0,
277,6,97,1,49,
277,6,98,1,1,4
277,6,98,1,8,
277,6,102,3,0,
277,6,104,1,19,
277,6,104,4,0,
277,6,116,1,1,3
277,6,116,1,4,
277,6,129,3,0,
277,6,156,4,0,
277,6,164,3,0,
277,6,168,4,0,
277,6,173,3,0,
277,6,182,4,0,
277,6,189,3,0,
277,6,203,3,0,
277,6,207,3,0,
277,6,211,4,0,
277,6,213,4,0,
277,6,214,3,0,
277,6,216,4,0,
277,6,218,4,0,
277,6,237,4,0,
277,6,240,4,0,
277,6,241,4,0,
277,6,263,4,0,
277,6,283,1,28,
277,6,290,4,0,
277,6,332,1,38,
277,6,332,4,0,
277,7,17,1,13,
277,7,19,4,0,
277,7,38,3,0,
277,7,45,1,1,2
277,7,63,4,0,
277,7,64,1,1,1
277,7,68,3,0,
277,7,92,4,0,
277,7,97,1,49,
277,7,98,1,1,4
277,7,98,1,8,
277,7,102,3,0,
277,7,104,1,19,
277,7,104,4,0,
277,7,116,1,1,3
277,7,116,1,4,
277,7,156,4,0,
277,7,164,3,0,
277,7,168,4,0,
277,7,182,4,0,
277,7,211,4,0,
277,7,213,4,0,
277,7,216,4,0,
277,7,218,4,0,
277,7,237,4,0,
277,7,240,4,0,
277,7,241,4,0,
277,7,263,4,0,
277,7,283,1,28,
277,7,290,4,0,
277,7,332,1,38,
277,7,332,4,0,
277,8,17,1,13,
277,8,19,4,0,
277,8,45,1,1,3
277,8,63,4,0,
277,8,64,1,1,2
277,8,92,4,0,
277,8,97,1,49,
277,8,98,1,1,5
277,8,98,1,8,
277,8,104,1,19,
277,8,104,4,0,
277,8,116,1,1,4
277,8,116,1,4,
277,8,156,4,0,
277,8,164,4,0,
277,8,168,4,0,
277,8,182,4,0,
277,8,203,4,0,
277,8,207,4,0,
277,8,211,4,0,
277,8,213,4,0,
277,8,214,4,0,
277,8,216,4,0,
277,8,218,4,0,
277,8,237,4,0,
277,8,240,4,0,
277,8,241,4,0,
277,8,263,4,0,
277,8,283,1,28,
277,8,290,4,0,
277,8,332,1,38,
277,8,332,4,0,
277,8,355,4,0,
277,8,363,4,0,
277,8,365,1,1,1
277,8,365,4,0,
277,8,369,4,0,
277,8,403,1,61,
277,8,416,4,0,
277,8,432,4,0,
277,8,445,4,0,
277,9,17,1,13,
277,9,19,4,0,
277,9,45,1,1,3
277,9,63,4,0,
277,9,64,1,1,2
277,9,92,4,0,
277,9,97,1,49,
277,9,98,1,1,5
277,9,98,1,8,
277,9,104,1,19,
277,9,104,4,0,
277,9,116,1,1,4
277,9,116,1,4,
277,9,129,3,0,
277,9,156,4,0,
277,9,164,4,0,
277,9,168,4,0,
277,9,173,3,0,
277,9,182,4,0,
277,9,189,3,0,
277,9,203,4,0,
277,9,207,4,0,
277,9,211,4,0,
277,9,213,4,0,
277,9,214,4,0,
277,9,216,4,0,
277,9,218,4,0,
277,9,237,4,0,
277,9,239,3,0,
277,9,240,4,0,
277,9,241,4,0,
277,9,257,3,0,
277,9,263,4,0,
277,9,283,1,28,
277,9,283,3,0,
277,9,290,4,0,
277,9,314,3,0,
277,9,332,1,38,
277,9,332,4,0,
277,9,355,4,0,
277,9,363,4,0,
277,9,365,1,1,1
277,9,365,4,0,
277,9,369,4,0,
277,9,403,1,61,
277,9,416,4,0,
277,9,432,4,0,
277,9,445,4,0,
277,9,466,3,0,
277,10,17,1,13,
277,10,19,4,0,
277,10,45,1,1,3
277,10,63,4,0,
277,10,64,1,1,2
277,10,92,4,0,
277,10,97,1,49,
277,10,98,1,1,5
277,10,98,1,8,
277,10,104,1,19,
277,10,104,4,0,
277,10,116,1,1,4
277,10,116,1,4,
277,10,129,3,0,
277,10,143,3,0,
277,10,156,4,0,
277,10,164,4,0,
277,10,168,4,0,
277,10,173,3,0,
277,10,182,4,0,
277,10,189,3,0,
277,10,203,4,0,
277,10,207,4,0,
277,10,211,4,0,
277,10,213,4,0,
277,10,214,4,0,
277,10,216,4,0,
277,10,218,4,0,
277,10,237,4,0,
277,10,239,3,0,
277,10,240,4,0,
277,10,241,4,0,
277,10,257,3,0,
277,10,263,4,0,
277,10,283,1,28,
277,10,283,3,0,
277,10,290,4,0,
277,10,314,3,0,
277,10,332,1,38,
277,10,332,4,0,
277,10,355,4,0,
277,10,363,4,0,
277,10,365,1,1,1
277,10,365,4,0,
277,10,366,3,0,
277,10,369,4,0,
277,10,403,1,61,
277,10,416,4,0,
277,10,445,4,0,
277,10,466,3,0,
277,11,17,1,13,
277,11,19,4,0,
277,11,45,1,1,3
277,11,63,4,0,
277,11,64,1,1,2
277,11,92,4,0,
277,11,97,1,49,
277,11,98,1,1,5
277,11,98,1,8,
277,11,104,1,19,
277,11,104,4,0,
277,11,116,1,1,4
277,11,116,1,4,
277,11,156,4,0,
277,11,164,4,0,
277,11,168,4,0,
277,11,182,4,0,
277,11,207,4,0,
277,11,213,4,0,
277,11,216,4,0,
277,11,218,4,0,
277,11,237,4,0,
277,11,240,4,0,
277,11,241,4,0,
277,11,263,4,0,
277,11,283,1,28,
277,11,332,1,38,
277,11,332,4,0,
277,11,365,1,1,1
277,11,365,4,0,
277,11,369,4,0,
277,11,403,1,61,
277,11,416,4,0,
277,11,496,4,0,
277,11,497,4,0,
277,11,526,4,0,
277,12,17,1,13,
277,12,19,4,0,
277,12,45,1,1,2
277,12,63,4,0,
277,12,64,1,1,1
277,12,92,4,0,
277,12,97,1,49,
277,12,98,1,1,4
277,12,98,1,8,
277,12,104,1,19,
277,12,104,4,0,
277,12,116,1,1,3
277,12,116,1,4,
277,12,156,4,0,
277,12,168,4,0,
277,12,182,4,0,
277,12,211,4,0,
277,12,213,4,0,
277,12,216,4,0,
277,12,218,4,0,
277,12,237,4,0,
277,12,240,4,0,
277,12,241,4,0,
277,12,263,4,0,
277,12,283,1,28,
277,12,290,4,0,
277,12,332,1,38,
277,12,332,4,0,
277,13,17,1,13,
277,13,19,4,0,
277,13,38,3,0,
277,13,45,1,1,2
277,13,63,4,0,
277,13,64,1,1,1
277,13,92,4,0,
277,13,97,1,49,
277,13,98,1,1,4
277,13,98,1,8,
277,13,102,3,0,
277,13,104,1,19,
277,13,104,4,0,
277,13,116,1,1,3
277,13,116,1,4,
277,13,143,3,0,
277,13,156,4,0,
277,13,164,3,0,
277,13,168,4,0,
277,13,182,4,0,
277,13,207,3,0,
277,13,211,4,0,
277,13,213,4,0,
277,13,216,4,0,
277,13,218,4,0,
277,13,237,4,0,
277,13,240,4,0,
277,13,241,4,0,
277,13,263,4,0,
277,13,283,1,28,
277,13,290,4,0,
277,13,332,1,38,
277,13,332,4,0,
277,14,17,1,13,
277,14,19,4,0,
277,14,45,1,1,3
277,14,63,4,0,
277,14,64,1,1,2
277,14,92,4,0,
277,14,97,1,49,
277,14,98,1,1,5
277,14,98,1,8,
277,14,104,1,19,
277,14,104,4,0,
277,14,116,1,1,4
277,14,116,1,4,
277,14,143,3,0,
277,14,156,4,0,
277,14,164,4,0,
277,14,168,4,0,
277,14,173,3,0,
277,14,182,4,0,
277,14,207,4,0,
277,14,213,4,0,
277,14,214,3,0,
277,14,216,4,0,
277,14,218,4,0,
277,14,237,4,0,
277,14,240,4,0,
277,14,241,4,0,
277,14,257,3,0,
277,14,263,4,0,
277,14,283,1,28,
277,14,283,3,0,
277,14,332,1,38,
277,14,332,4,0,
277,14,355,3,0,
277,14,365,1,1,1
277,14,365,4,0,
277,14,366,3,0,
277,14,369,4,0,
277,14,403,1,61,
277,14,416,4,0,
277,14,496,4,0,
277,14,497,4,0,
277,14,526,4,0,
277,15,17,1,13,
277,15,19,4,0,
277,15,45,1,1,4
277,15,63,4,0,
277,15,64,1,1,3
277,15,92,4,0,
277,15,97,1,49,
277,15,98,1,1,6
277,15,98,1,7,
277,15,104,1,19,
277,15,104,4,0,
277,15,116,1,1,5
277,15,116,1,4,
277,15,156,4,0,
277,15,164,4,0,
277,15,168,4,0,
277,15,182,4,0,
277,15,207,4,0,
277,15,211,4,0,
277,15,213,4,0,
277,15,214,4,0,
277,15,216,4,0,
277,15,218,4,0,
277,15,237,4,0,
277,15,240,4,0,
277,15,241,4,0,
277,15,263,4,0,
277,15,283,1,28,
277,15,332,1,38,
277,15,332,4,0,
277,15,355,4,0,
277,15,365,1,1,2
277,15,369,4,0,
277,15,403,1,1,1
277,15,403,1,61,
277,15,416,4,0,
277,15,496,4,0,
277,15,497,4,0,
277,15,590,4,0,
277,16,17,1,13,1
277,16,19,4,0,
277,16,45,1,1,5
277,16,63,4,0,
277,16,64,1,1,4
277,16,92,4,0,
277,16,97,1,33,1
277,16,98,1,1,7
277,16,98,1,9,1
277,16,104,1,17,1
277,16,104,4,0,
277,16,116,1,1,6
277,16,116,1,5,1
277,16,143,3,0,
277,16,156,4,0,
277,16,164,4,0,
277,16,168,4,0,
277,16,173,3,0,
277,16,182,4,0,
277,16,207,4,0,
277,16,211,4,0,
277,16,213,4,0,
277,16,214,4,0,
277,16,216,4,0,
277,16,218,4,0,
277,16,237,4,0,
277,16,240,4,0,
277,16,241,4,0,
277,16,257,3,0,
277,16,263,4,0,
277,16,283,1,39,1
277,16,283,3,0,
277,16,290,4,0,
277,16,332,1,21,1
277,16,332,4,0,
277,16,355,4,0,
277,16,365,1,1,3
277,16,366,3,0,
277,16,369,4,0,
277,16,403,1,1,2
277,16,403,1,45,1
277,16,413,1,1,1
277,16,413,1,51,1
277,16,416,4,0,
277,16,496,4,0,
277,16,497,4,0,
277,16,501,1,27,1
277,16,590,4,0,
277,17,17,1,13,
277,17,19,4,0,
277,17,45,1,1,5
277,17,63,4,0,
277,17,64,1,1,4
277,17,92,4,0,
277,17,97,1,33,
277,17,98,1,1,7
277,17,98,1,9,
277,17,104,1,17,
277,17,104,4,0,
277,17,116,1,1,6
277,17,116,1,5,
277,17,156,4,0,
277,17,164,4,0,
277,17,168,4,0,
277,17,179,1,57,
277,17,182,4,0,
277,17,207,4,0,
277,17,211,4,0,
277,17,213,4,0,
277,17,214,4,0,
277,17,216,4,0,
277,17,218,4,0,
277,17,237,4,0,
277,17,240,4,0,
277,17,241,4,0,
277,17,263,4,0,
277,17,283,1,45,
277,17,332,1,21,
277,17,332,4,0,
277,17,355,4,0,
277,17,365,1,1,3
277,17,369,4,0,
277,17,403,1,1,2
277,17,403,1,39,
277,17,413,1,1,1
277,17,413,1,51,
277,17,416,4,0,
277,17,496,4,0,
277,17,497,4,0,
277,17,501,1,27,
277,17,526,4,0,
277,17,590,4,0,
277,18,17,1,13,
277,18,19,4,0,
277,18,45,1,1,5
277,18,63,4,0,
277,18,64,1,1,4
277,18,92,4,0,
277,18,97,1,33,
277,18,98,1,1,7
277,18,98,1,9,
277,18,104,1,17,
277,18,104,4,0,
277,18,116,1,1,6
277,18,116,1,5,
277,18,156,4,0,
277,18,164,4,0,
277,18,168,4,0,
277,18,179,1,57,
277,18,182,4,0,
277,18,207,4,0,
277,18,211,4,0,
277,18,213,4,0,
277,18,214,4,0,
277,18,216,4,0,
277,18,218,4,0,
277,18,237,4,0,
277,18,240,4,0,
277,18,241,4,0,
277,18,263,4,0,
277,18,283,1,45,
277,18,332,1,21,
277,18,332,4,0,
277,18,355,4,0,
277,18,365,1,1,3
277,18,369,4,0,
277,18,403,1,1,2
277,18,403,1,39,
277,18,413,1,1,1
277,18,413,1,51,
277,18,416,4,0,
277,18,496,4,0,
277,18,497,4,0,
277,18,501,1,27,
277,18,526,4,0,
277,18,590,4,0,
278,5,16,2,0,
278,5,17,1,13,
278,5,19,4,0,
278,5,45,1,1,1
278,5,48,1,7,
278,5,54,1,21,
278,5,54,2,0,
278,5,55,1,1,2
278,5,58,4,0,
278,5,59,4,0,
278,5,92,4,0,
278,5,97,1,55,
278,5,97,2,0,
278,5,98,1,31,
278,5,104,4,0,
278,5,156,4,0,
278,5,168,4,0,
278,5,182,4,0,
278,5,211,4,0,
278,5,213,4,0,
278,5,216,4,0,
278,5,218,4,0,
278,5,228,1,43,
278,5,237,4,0,
278,5,239,2,0,
278,5,240,4,0,
278,5,258,4,0,
278,5,263,4,0,
278,5,290,4,0,
278,5,332,4,0,
278,5,346,2,0,
278,5,351,4,0,
278,5,352,4,0,
278,6,16,2,0,
278,6,17,1,13,
278,6,19,4,0,
278,6,38,3,0,
278,6,45,1,1,1
278,6,48,1,7,
278,6,54,1,21,
278,6,54,2,0,
278,6,55,1,1,2
278,6,58,4,0,
278,6,59,4,0,
278,6,92,4,0,
278,6,97,1,55,
278,6,97,2,0,
278,6,98,1,31,
278,6,102,3,0,
278,6,104,4,0,
278,6,129,3,0,
278,6,156,4,0,
278,6,164,3,0,
278,6,168,4,0,
278,6,173,3,0,
278,6,182,4,0,
278,6,189,3,0,
278,6,196,3,0,
278,6,203,3,0,
278,6,207,3,0,
278,6,211,4,0,
278,6,213,4,0,
278,6,214,3,0,
278,6,216,4,0,
278,6,218,4,0,
278,6,228,1,43,
278,6,237,4,0,
278,6,239,2,0,
278,6,240,4,0,
278,6,258,4,0,
278,6,263,4,0,
278,6,290,4,0,
278,6,332,4,0,
278,6,346,2,0,
278,6,351,4,0,
278,6,352,4,0,
278,7,16,2,0,
278,7,17,1,13,
278,7,19,4,0,
278,7,38,3,0,
278,7,45,1,1,1
278,7,48,1,7,
278,7,54,1,21,
278,7,54,2,0,
278,7,55,1,1,2
278,7,58,4,0,
278,7,59,4,0,
278,7,92,4,0,
278,7,97,1,55,
278,7,97,2,0,
278,7,98,1,31,
278,7,102,3,0,
278,7,104,4,0,
278,7,156,4,0,
278,7,164,3,0,
278,7,168,4,0,
278,7,182,4,0,
278,7,211,4,0,
278,7,213,4,0,
278,7,216,4,0,
278,7,218,4,0,
278,7,228,1,43,
278,7,237,4,0,
278,7,239,2,0,
278,7,240,4,0,
278,7,258,4,0,
278,7,263,4,0,
278,7,290,4,0,
278,7,332,4,0,
278,7,346,2,0,
278,7,351,4,0,
278,7,352,4,0,
278,8,16,2,0,
278,8,17,1,11,
278,8,19,4,0,
278,8,45,1,1,1
278,8,48,1,6,
278,8,54,1,16,
278,8,54,2,0,
278,8,55,1,1,2
278,8,58,4,0,
278,8,59,4,0,
278,8,92,4,0,
278,8,97,1,37,
278,8,97,2,0,
278,8,98,1,24,
278,8,104,4,0,
278,8,156,4,0,
278,8,164,4,0,
278,8,168,4,0,
278,8,182,4,0,
278,8,203,4,0,
278,8,207,4,0,
278,8,211,4,0,
278,8,213,4,0,
278,8,214,4,0,
278,8,216,4,0,
278,8,218,4,0,
278,8,228,1,34,
278,8,237,4,0,
278,8,239,2,0,
278,8,240,4,0,
278,8,258,4,0,
278,8,263,4,0,
278,8,282,2,0,
278,8,290,4,0,
278,8,332,1,42,
278,8,332,4,0,
278,8,346,2,0,
278,8,351,4,0,
278,8,352,1,19,
278,8,352,4,0,
278,8,355,1,29,
278,8,355,4,0,
278,8,362,4,0,
278,8,363,4,0,
278,8,365,4,0,
278,8,369,4,0,
278,8,392,2,0,
278,8,403,1,47,
278,8,432,4,0,
278,8,445,4,0,
278,9,16,2,0,
278,9,17,1,11,
278,9,19,4,0,
278,9,45,1,1,1
278,9,48,1,6,
278,9,54,1,16,
278,9,54,2,0,
278,9,55,1,1,2
278,9,58,4,0,
278,9,59,4,0,
278,9,92,4,0,
278,9,97,1,37,
278,9,97,2,0,
278,9,98,1,24,
278,9,104,4,0,
278,9,129,3,0,
278,9,156,4,0,
278,9,164,4,0,
278,9,168,4,0,
278,9,173,3,0,
278,9,182,4,0,
278,9,189,3,0,
278,9,196,3,0,
278,9,203,4,0,
278,9,207,4,0,
278,9,211,4,0,
278,9,213,4,0,
278,9,214,4,0,
278,9,216,4,0,
278,9,218,4,0,
278,9,228,1,34,
278,9,237,4,0,
278,9,239,2,0,
278,9,239,3,0,
278,9,240,4,0,
278,9,253,3,0,
278,9,258,4,0,
278,9,263,4,0,
278,9,282,2,0,
278,9,282,3,0,
278,9,290,4,0,
278,9,314,3,0,
278,9,332,1,42,
278,9,332,4,0,
278,9,346,2,0,
278,9,351,4,0,
278,9,352,1,19,
278,9,352,4,0,
278,9,355,1,29,
278,9,355,4,0,
278,9,362,4,0,
278,9,363,4,0,
278,9,365,4,0,
278,9,369,4,0,
278,9,392,2,0,
278,9,403,1,47,
278,9,432,4,0,
278,9,445,4,0,
278,9,466,3,0,
278,10,16,2,0,
278,10,17,1,11,
278,10,19,4,0,
278,10,45,1,1,1
278,10,48,1,6,
278,10,54,1,16,
278,10,54,2,0,
278,10,55,1,1,2
278,10,58,4,0,
278,10,59,4,0,
278,10,92,4,0,
278,10,97,1,37,
278,10,97,2,0,
278,10,98,1,24,
278,10,104,4,0,
278,10,129,3,0,
278,10,156,4,0,
278,10,164,4,0,
278,10,168,4,0,
278,10,173,3,0,
278,10,182,4,0,
278,10,189,3,0,
278,10,196,3,0,
278,10,203,4,0,
278,10,207,4,0,
278,10,211,4,0,
278,10,213,4,0,
278,10,214,4,0,
278,10,216,4,0,
278,10,218,4,0,
278,10,228,1,34,
278,10,237,4,0,
278,10,239,2,0,
278,10,239,3,0,
278,10,240,4,0,
278,10,253,3,0,
278,10,258,4,0,
278,10,263,4,0,
278,10,282,2,0,
278,10,282,3,0,
278,10,290,4,0,
278,10,314,3,0,
278,10,332,1,42,
278,10,332,4,0,
278,10,346,2,0,
278,10,351,4,0,
278,10,352,1,19,
278,10,352,4,0,
278,10,355,1,29,
278,10,355,4,0,
278,10,362,4,0,
278,10,363,4,0,
278,10,365,4,0,
278,10,366,3,0,
278,10,369,4,0,
278,10,392,2,0,
278,10,403,1,47,
278,10,445,4,0,
278,10,466,3,0,
278,11,16,2,0,
278,11,17,1,11,
278,11,19,4,0,
278,11,45,1,1,1
278,11,48,1,6,
278,11,54,1,16,
278,11,54,2,0,
278,11,55,1,1,2
278,11,58,4,0,
278,11,59,4,0,
278,11,92,4,0,
278,11,97,1,37,
278,11,97,2,0,
278,11,98,1,24,
278,11,104,4,0,
278,11,156,4,0,
278,11,164,4,0,
278,11,168,4,0,
278,11,182,4,0,
278,11,207,4,0,
278,11,213,4,0,
278,11,216,4,0,
278,11,218,4,0,
278,11,228,1,34,
278,11,237,4,0,
278,11,239,2,0,
278,11,240,4,0,
278,11,258,4,0,
278,11,263,4,0,
278,11,282,2,0,
278,11,332,1,42,
278,11,332,4,0,
278,11,346,2,0,
278,11,352,1,19,
278,11,355,1,29,
278,11,355,2,0,
278,11,362,2,0,
278,11,365,4,0,
278,11,369,4,0,
278,11,392,2,0,
278,11,403,1,47,
278,11,496,4,0,
278,11,497,4,0,
278,11,503,4,0,
278,11,542,1,50,
278,12,17,1,13,
278,12,19,4,0,
278,12,45,1,1,1
278,12,48,1,7,
278,12,54,1,21,
278,12,55,1,1,2
278,12,58,4,0,
278,12,59,4,0,
278,12,92,4,0,
278,12,97,1,55,
278,12,98,1,31,
278,12,104,4,0,
278,12,156,4,0,
278,12,168,4,0,
278,12,182,4,0,
278,12,211,4,0,
278,12,213,4,0,
278,12,216,4,0,
278,12,218,4,0,
278,12,228,1,43,
278,12,237,4,0,
278,12,240,4,0,
278,12,258,4,0,
278,12,263,4,0,
278,12,290,4,0,
278,12,332,4,0,
278,12,351,4,0,
278,12,352,4,0,
278,13,17,1,13,
278,13,19,4,0,
278,13,38,3,0,
278,13,45,1,1,1
278,13,48,1,7,
278,13,54,1,21,
278,13,55,1,1,2
278,13,58,4,0,
278,13,59,4,0,
278,13,92,4,0,
278,13,97,1,55,
278,13,98,1,31,
278,13,102,3,0,
278,13,104,4,0,
278,13,143,3,0,
278,13,156,4,0,
278,13,164,3,0,
278,13,168,4,0,
278,13,182,4,0,
278,13,196,3,0,
278,13,207,3,0,
278,13,211,4,0,
278,13,213,4,0,
278,13,216,4,0,
278,13,218,4,0,
278,13,228,1,43,
278,13,237,4,0,
278,13,240,4,0,
278,13,258,4,0,
278,13,263,4,0,
278,13,290,4,0,
278,13,332,4,0,
278,13,351,4,0,
278,13,352,4,0,
278,14,16,2,0,
278,14,17,1,9,
278,14,19,4,0,
278,14,45,1,1,1
278,14,48,1,6,
278,14,54,1,14,
278,14,54,2,0,
278,14,55,1,1,2
278,14,58,4,0,
278,14,59,4,0,
278,14,92,4,0,
278,14,97,1,38,
278,14,97,2,0,
278,14,98,1,22,
278,14,104,4,0,
278,14,156,4,0,
278,14,164,4,0,
278,14,168,4,0,
278,14,173,3,0,
278,14,182,4,0,
278,14,196,3,0,
278,14,207,4,0,
278,14,213,4,0,
278,14,214,3,0,
278,14,216,4,0,
278,14,218,4,0,
278,14,228,1,30,
278,14,237,4,0,
278,14,239,2,0,
278,14,240,4,0,
278,14,253,3,0,
278,14,258,4,0,
278,14,263,4,0,
278,14,282,2,0,
278,14,282,3,0,
278,14,314,1,33,
278,14,332,1,42,
278,14,332,4,0,
278,14,346,2,0,
278,14,352,1,17,
278,14,355,1,26,
278,14,355,2,0,
278,14,355,3,0,
278,14,362,2,0,
278,14,365,4,0,
278,14,366,3,0,
278,14,369,4,0,
278,14,392,2,0,
278,14,403,1,46,
278,14,496,4,0,
278,14,497,4,0,
278,14,503,4,0,
278,14,542,1,49,
278,15,16,2,0,
278,15,17,1,9,
278,15,19,4,0,
278,15,45,1,1,1
278,15,48,1,6,
278,15,54,1,14,
278,15,54,2,0,
278,15,55,1,1,2
278,15,58,4,0,
278,15,59,4,0,
278,15,92,4,0,
278,15,97,1,38,
278,15,97,2,0,
278,15,98,1,22,
278,15,104,4,0,
278,15,156,4,0,
278,15,164,4,0,
278,15,168,4,0,
278,15,182,4,0,
278,15,207,4,0,
278,15,211,4,0,
278,15,213,4,0,
278,15,214,4,0,
278,15,216,4,0,
278,15,218,4,0,
278,15,228,1,30,
278,15,237,4,0,
278,15,239,2,0,
278,15,240,4,0,
278,15,258,4,0,
278,15,263,4,0,
278,15,282,2,0,
278,15,314,1,33,
278,15,332,1,42,
278,15,332,4,0,
278,15,346,2,0,
278,15,352,1,17,
278,15,355,1,26,
278,15,355,2,0,
278,15,355,4,0,
278,15,362,2,0,
278,15,369,4,0,
278,15,392,2,0,
278,15,403,1,46,
278,15,469,2,0,
278,15,487,2,0,
278,15,496,4,0,
278,15,497,4,0,
278,15,503,4,0,
278,15,542,1,49,
278,15,590,4,0,
278,16,16,2,0,
278,16,17,1,8,1
278,16,19,4,0,
278,16,45,1,1,1
278,16,48,1,5,1
278,16,54,1,12,1
278,16,54,2,0,
278,16,55,1,1,2
278,16,58,4,0,
278,16,59,4,0,
278,16,92,4,0,
278,16,97,1,36,1
278,16,97,2,0,
278,16,98,1,19,1
278,16,104,4,0,
278,16,143,3,0,
278,16,156,4,0,
278,16,164,4,0,
278,16,168,4,0,
278,16,173,3,0,
278,16,182,4,0,
278,16,196,3,0,
278,16,207,4,0,
278,16,211,4,0,
278,16,213,4,0,
278,16,214,4,0,
278,16,216,4,0,
278,16,218,4,0,
278,16,228,1,26,1
278,16,237,4,0,
278,16,239,2,0,
278,16,240,4,0,
278,16,253,3,0,
278,16,258,4,0,
278,16,263,4,0,
278,16,282,2,0,
278,16,282,3,0,
278,16,290,4,0,
278,16,314,1,22,1
278,16,332,1,29,1
278,16,332,4,0,
278,16,346,2,0,
278,16,351,3,0,
278,16,352,1,15,1
278,16,352,3,0,
278,16,355,1,33,1
278,16,355,2,0,
278,16,355,4,0,
278,16,362,2,0,
278,16,366,3,0,
278,16,369,4,0,
278,16,392,2,0,
278,16,403,1,40,1
278,16,469,2,0,
278,16,487,2,0,
278,16,496,4,0,
278,16,497,4,0,
278,16,503,4,0,
278,16,542,1,43,1
278,16,590,4,0,
278,17,16,2,0,
278,17,17,1,8,
278,17,19,4,0,
278,17,45,1,1,1
278,17,48,1,5,
278,17,54,1,12,
278,17,54,2,0,
278,17,55,1,1,2
278,17,58,4,0,
278,17,59,4,0,
278,17,92,4,0,
278,17,97,1,36,
278,17,97,2,0,
278,17,98,1,19,
278,17,104,4,0,
278,17,156,4,0,
278,17,164,4,0,
278,17,168,4,0,
278,17,182,4,0,
278,17,207,4,0,
278,17,211,4,0,
278,17,213,4,0,
278,17,214,4,0,
278,17,216,4,0,
278,17,218,4,0,
278,17,228,1,26,
278,17,237,4,0,
278,17,239,2,0,
278,17,240,4,0,
278,17,258,4,0,
278,17,263,4,0,
278,17,282,2,0,
278,17,314,1,22,
278,17,332,1,29,
278,17,332,4,0,
278,17,346,2,0,
278,17,352,1,15,
278,17,355,1,33,
278,17,355,2,0,
278,17,355,4,0,
278,17,362,2,0,
278,17,369,4,0,
278,17,392,2,0,
278,17,403,1,40,
278,17,469,2,0,
278,17,487,2,0,
278,17,496,4,0,
278,17,497,4,0,
278,17,503,4,0,
278,17,542,1,43,
278,17,590,4,0,
278,18,16,2,0,
278,18,17,1,8,
278,18,19,4,0,
278,18,45,1,1,1
278,18,48,1,5,
278,18,54,1,12,
278,18,54,2,0,
278,18,55,1,1,2
278,18,58,4,0,
278,18,59,4,0,
278,18,92,4,0,
278,18,97,1,36,
278,18,97,2,0,
278,18,98,1,19,
278,18,104,4,0,
278,18,156,4,0,
278,18,164,4,0,
278,18,168,4,0,
278,18,182,4,0,
278,18,207,4,0,
278,18,211,4,0,
278,18,213,4,0,
278,18,214,4,0,
278,18,216,4,0,
278,18,218,4,0,
278,18,228,1,26,
278,18,237,4,0,
278,18,239,2,0,
278,18,240,4,0,
278,18,258,4,0,
278,18,263,4,0,
278,18,282,2,0,
278,18,314,1,22,
278,18,332,1,29,
278,18,332,4,0,
278,18,346,2,0,
278,18,352,1,15,
278,18,355,1,33,
278,18,355,2,0,
278,18,355,4,0,
278,18,362,2,0,
278,18,369,4,0,
278,18,392,2,0,
278,18,403,1,40,
278,18,469,2,0,
278,18,487,2,0,
278,18,496,4,0,
278,18,497,4,0,
278,18,503,4,0,
278,18,542,1,43,
278,18,590,4,0,
279,5,17,1,1,4
279,5,17,1,13,
279,5,19,4,0,
279,5,45,1,1,1
279,5,48,1,7,
279,5,54,1,21,
279,5,55,1,1,2
279,5,55,1,3,
279,5,56,1,61,
279,5,57,4,0,
279,5,58,4,0,
279,5,59,4,0,
279,5,63,4,0,
279,5,92,4,0,
279,5,104,4,0,
279,5,156,4,0,
279,5,168,4,0,
279,5,182,1,25,
279,5,182,4,0,
279,5,211,4,0,
279,5,213,4,0,
279,5,216,4,0,
279,5,218,4,0,
279,5,237,4,0,
279,5,240,4,0,
279,5,254,1,33,
279,5,255,1,47,
279,5,256,1,33,5
279,5,258,4,0,
279,5,263,4,0,
279,5,290,4,0,
279,5,332,4,0,
279,5,346,1,1,3
279,5,351,4,0,
279,5,352,4,0,
279,6,17,1,1,4
279,6,17,1,13,
279,6,19,4,0,
279,6,38,3,0,
279,6,45,1,1,1
279,6,48,1,7,
279,6,54,1,21,
279,6,55,1,1,2
279,6,55,1,3,
279,6,56,1,61,
279,6,57,4,0,
279,6,58,4,0,
279,6,59,4,0,
279,6,63,4,0,
279,6,92,4,0,
279,6,102,3,0,
279,6,104,4,0,
279,6,129,3,0,
279,6,156,4,0,
279,6,164,3,0,
279,6,168,4,0,
279,6,173,3,0,
279,6,182,1,25,
279,6,182,4,0,
279,6,189,3,0,
279,6,196,3,0,
279,6,203,3,0,
279,6,207,3,0,
279,6,211,4,0,
279,6,213,4,0,
279,6,214,3,0,
279,6,216,4,0,
279,6,218,4,0,
279,6,237,4,0,
279,6,240,4,0,
279,6,254,1,33,
279,6,255,1,47,
279,6,256,1,33,5
279,6,258,4,0,
279,6,263,4,0,
279,6,290,4,0,
279,6,332,4,0,
279,6,346,1,1,3
279,6,351,4,0,
279,6,352,4,0,
279,7,17,1,1,4
279,7,17,1,13,
279,7,19,4,0,
279,7,38,3,0,
279,7,45,1,1,1
279,7,48,1,7,
279,7,54,1,21,
279,7,55,1,1,2
279,7,55,1,3,
279,7,56,1,61,
279,7,57,4,0,
279,7,58,4,0,
279,7,59,4,0,
279,7,63,4,0,
279,7,92,4,0,
279,7,102,3,0,
279,7,104,4,0,
279,7,156,4,0,
279,7,164,3,0,
279,7,168,4,0,
279,7,182,1,25,
279,7,182,4,0,
279,7,211,4,0,
279,7,213,4,0,
279,7,216,4,0,
279,7,218,4,0,
279,7,237,4,0,
279,7,240,4,0,
279,7,254,1,33,
279,7,255,1,47,
279,7,256,1,33,5
279,7,258,4,0,
279,7,263,4,0,
279,7,290,4,0,
279,7,332,4,0,
279,7,346,1,1,3
279,7,351,4,0,
279,7,352,4,0,
279,8,17,1,1,4
279,8,17,1,11,
279,8,19,4,0,
279,8,45,1,1,1
279,8,48,1,6,
279,8,54,1,16,
279,8,55,1,1,2
279,8,56,1,57,
279,8,57,4,0,
279,8,58,4,0,
279,8,59,4,0,
279,8,63,4,0,
279,8,92,4,0,
279,8,104,4,0,
279,8,156,4,0,
279,8,164,4,0,
279,8,168,4,0,
279,8,182,1,25,
279,8,182,4,0,
279,8,203,4,0,
279,8,207,4,0,
279,8,211,4,0,
279,8,213,4,0,
279,8,214,4,0,
279,8,216,4,0,
279,8,218,4,0,
279,8,237,4,0,
279,8,240,4,0,
279,8,254,1,38,1
279,8,255,1,38,3
279,8,256,1,38,2
279,8,258,4,0,
279,8,263,4,0,
279,8,290,4,0,
279,8,332,4,0,
279,8,346,1,1,3
279,8,351,4,0,
279,8,352,1,19,
279,8,352,4,0,
279,8,355,1,31,
279,8,355,4,0,
279,8,362,4,0,
279,8,363,4,0,
279,8,365,4,0,
279,8,366,1,50,
279,8,369,4,0,
279,8,371,1,24,
279,8,371,4,0,
279,8,374,1,43,
279,8,374,4,0,
279,8,416,4,0,
279,8,432,4,0,
279,8,445,4,0,
279,9,17,1,1,4
279,9,17,1,11,
279,9,19,4,0,
279,9,45,1,1,1
279,9,48,1,6,
279,9,54,1,16,
279,9,55,1,1,2
279,9,56,1,57,
279,9,57,4,0,
279,9,58,4,0,
279,9,59,4,0,
279,9,63,4,0,
279,9,92,4,0,
279,9,104,4,0,
279,9,129,3,0,
279,9,156,4,0,
279,9,164,4,0,
279,9,168,4,0,
279,9,173,3,0,
279,9,182,1,25,
279,9,182,4,0,
279,9,189,3,0,
279,9,196,3,0,
279,9,203,4,0,
279,9,207,4,0,
279,9,211,4,0,
279,9,213,4,0,
279,9,214,4,0,
279,9,216,4,0,
279,9,218,4,0,
279,9,237,4,0,
279,9,239,3,0,
279,9,240,4,0,
279,9,253,3,0,
279,9,254,1,38,1
279,9,255,1,38,3
279,9,256,1,38,2
279,9,258,4,0,
279,9,263,4,0,
279,9,282,3,0,
279,9,290,4,0,
279,9,314,3,0,
279,9,332,4,0,
279,9,346,1,1,3
279,9,351,4,0,
279,9,352,1,19,
279,9,352,4,0,
279,9,355,1,31,
279,9,355,4,0,
279,9,362,4,0,
279,9,363,4,0,
279,9,365,4,0,
279,9,366,1,50,
279,9,369,4,0,
279,9,371,1,24,
279,9,371,4,0,
279,9,374,1,43,
279,9,374,4,0,
279,9,402,3,0,
279,9,416,4,0,
279,9,432,4,0,
279,9,441,3,0,
279,9,445,4,0,
279,9,466,3,0,
279,10,17,1,1,4
279,10,17,1,11,
279,10,19,4,0,
279,10,45,1,1,1
279,10,48,1,6,
279,10,54,1,16,
279,10,55,1,1,2
279,10,56,1,57,
279,10,57,4,0,
279,10,58,4,0,
279,10,59,4,0,
279,10,63,4,0,
279,10,92,4,0,
279,10,104,4,0,
279,10,129,3,0,
279,10,143,3,0,
279,10,156,4,0,
279,10,164,4,0,
279,10,168,4,0,
279,10,173,3,0,
279,10,182,1,25,
279,10,182,4,0,
279,10,189,3,0,
279,10,196,3,0,
279,10,203,4,0,
279,10,207,4,0,
279,10,211,4,0,
279,10,213,4,0,
279,10,214,4,0,
279,10,216,4,0,
279,10,218,4,0,
279,10,237,4,0,
279,10,239,3,0,
279,10,240,4,0,
279,10,250,4,0,
279,10,253,3,0,
279,10,254,1,38,1
279,10,255,1,38,3
279,10,256,1,38,2
279,10,258,4,0,
279,10,263,4,0,
279,10,282,3,0,
279,10,290,4,0,
279,10,314,3,0,
279,10,332,4,0,
279,10,346,1,1,3
279,10,351,4,0,
279,10,352,1,19,
279,10,352,4,0,
279,10,355,1,31,
279,10,355,4,0,
279,10,362,4,0,
279,10,363,4,0,
279,10,365,4,0,
279,10,366,1,50,
279,10,366,3,0,
279,10,369,4,0,
279,10,371,1,24,
279,10,371,4,0,
279,10,374,1,43,
279,10,374,4,0,
279,10,402,3,0,
279,10,416,4,0,
279,10,441,3,0,
279,10,445,4,0,
279,10,466,3,0,
279,11,17,1,1,5
279,11,17,1,11,
279,11,19,4,0,
279,11,45,1,1,2
279,11,48,1,6,
279,11,54,1,16,
279,11,55,1,1,3
279,11,56,1,57,
279,11,57,4,0,
279,11,58,4,0,
279,11,59,4,0,
279,11,63,4,0,
279,11,92,4,0,
279,11,104,4,0,
279,11,156,4,0,
279,11,164,4,0,
279,11,168,4,0,
279,11,182,1,25,
279,11,182,4,0,
279,11,207,4,0,
279,11,213,4,0,
279,11,216,4,0,
279,11,218,4,0,
279,11,237,4,0,
279,11,240,4,0,
279,11,254,1,38,1
279,11,255,1,38,3
279,11,256,1,38,2
279,11,258,4,0,
279,11,263,4,0,
279,11,332,4,0,
279,11,346,1,1,4
279,11,352,1,19,
279,11,355,1,31,
279,11,365,4,0,
279,11,366,1,50,
279,11,369,4,0,
279,11,371,1,24,
279,11,371,4,0,
279,11,374,1,43,
279,11,374,4,0,
279,11,416,4,0,
279,11,487,1,1,1
279,11,496,4,0,
279,11,497,4,0,
279,11,503,4,0,
279,11,507,4,0,
279,11,542,1,63,
279,12,17,1,1,4
279,12,17,1,13,
279,12,19,4,0,
279,12,45,1,1,1
279,12,48,1,7,
279,12,54,1,21,
279,12,55,1,1,2
279,12,55,1,3,
279,12,56,1,61,
279,12,57,4,0,
279,12,58,4,0,
279,12,59,4,0,
279,12,63,4,0,
279,12,92,4,0,
279,12,104,4,0,
279,12,156,4,0,
279,12,168,4,0,
279,12,182,1,25,
279,12,182,4,0,
279,12,211,4,0,
279,12,213,4,0,
279,12,216,4,0,
279,12,218,4,0,
279,12,237,4,0,
279,12,240,4,0,
279,12,254,1,33,
279,12,255,1,47,
279,12,256,1,33,5
279,12,258,4,0,
279,12,263,4,0,
279,12,290,4,0,
279,12,332,4,0,
279,12,346,1,1,3
279,12,351,4,0,
279,12,352,4,0,
279,13,17,1,1,4
279,13,17,1,13,
279,13,19,4,0,
279,13,38,3,0,
279,13,45,1,1,1
279,13,48,1,7,
279,13,54,1,21,
279,13,55,1,1,2
279,13,55,1,3,
279,13,56,1,61,
279,13,57,4,0,
279,13,58,4,0,
279,13,59,4,0,
279,13,63,4,0,
279,13,92,4,0,
279,13,102,3,0,
279,13,104,4,0,
279,13,143,3,0,
279,13,156,4,0,
279,13,164,3,0,
279,13,168,4,0,
279,13,182,1,25,
279,13,182,4,0,
279,13,196,3,0,
279,13,207,3,0,
279,13,211,4,0,
279,13,213,4,0,
279,13,216,4,0,
279,13,218,4,0,
279,13,237,4,0,
279,13,240,4,0,
279,13,254,1,33,
279,13,255,1,47,
279,13,256,1,33,5
279,13,258,4,0,
279,13,263,4,0,
279,13,290,4,0,
279,13,332,4,0,
279,13,346,1,1,3
279,13,351,4,0,
279,13,352,4,0,
279,14,17,1,1,5
279,14,17,1,9,
279,14,19,4,0,
279,14,45,1,1,2
279,14,48,1,6,
279,14,54,1,14,
279,14,55,1,1,3
279,14,56,1,58,
279,14,57,4,0,
279,14,58,4,0,
279,14,59,4,0,
279,14,63,4,0,
279,14,92,4,0,
279,14,104,4,0,
279,14,143,3,0,
279,14,156,4,0,
279,14,164,4,0,
279,14,168,4,0,
279,14,173,3,0,
279,14,182,1,25,
279,14,182,4,0,
279,14,196,3,0,
279,14,207,4,0,
279,14,213,4,0,
279,14,214,3,0,
279,14,216,4,0,
279,14,218,4,0,
279,14,237,4,0,
279,14,240,4,0,
279,14,253,3,0,
279,14,254,1,39,1
279,14,255,1,39,3
279,14,256,1,39,2
279,14,258,4,0,
279,14,263,4,0,
279,14,282,3,0,
279,14,332,4,0,
279,14,346,1,1,4
279,14,352,1,17,
279,14,355,1,28,
279,14,355,3,0,
279,14,362,1,34,
279,14,365,4,0,
279,14,366,1,52,
279,14,366,3,0,
279,14,369,4,0,
279,14,371,1,22,
279,14,371,4,0,
279,14,374,1,46,
279,14,374,4,0,
279,14,402,3,0,
279,14,416,4,0,
279,14,441,3,0,
279,14,487,1,1,1
279,14,496,4,0,
279,14,497,4,0,
279,14,503,4,0,
279,14,507,4,0,
279,14,542,1,63,
279,15,17,1,1,7
279,15,17,1,9,
279,15,19,4,0,
279,15,45,1,1,4
279,15,48,1,6,
279,15,54,1,14,
279,15,55,1,1,5
279,15,56,1,1,1
279,15,56,1,58,
279,15,57,4,0,
279,15,58,4,0,
279,15,59,4,0,
279,15,63,4,0,
279,15,92,4,0,
279,15,104,4,0,
279,15,156,4,0,
279,15,164,4,0,
279,15,168,4,0,
279,15,182,1,25,
279,15,182,4,0,
279,15,207,4,0,
279,15,211,4,0,
279,15,213,4,0,
279,15,214,4,0,
279,15,216,4,0,
279,15,218,4,0,
279,15,237,4,0,
279,15,240,4,0,
279,15,254,1,39,1
279,15,255,1,39,3
279,15,256,1,39,2
279,15,258,4,0,
279,15,263,4,0,
279,15,332,4,0,
279,15,346,1,1,6
279,15,352,1,17,
279,15,355,1,28,
279,15,355,4,0,
279,15,362,1,34,
279,15,366,1,1,2
279,15,366,1,52,
279,15,369,4,0,
279,15,371,1,22,
279,15,371,4,0,
279,15,374,1,46,
279,15,374,4,0,
279,15,416,4,0,
279,15,487,1,1,3
279,15,496,4,0,
279,15,497,4,0,
279,15,503,4,0,
279,15,507,4,0,
279,15,542,1,63,
279,15,590,4,0,
279,16,17,1,1,8
279,16,17,1,8,1
279,16,19,4,0,
279,16,45,1,1,5
279,16,48,1,5,1
279,16,54,1,12,1
279,16,55,1,1,6
279,16,56,1,1,2
279,16,56,1,50,1
279,16,57,4,0,
279,16,58,4,0,
279,16,59,4,0,
279,16,63,4,0,
279,16,92,4,0,
279,16,104,4,0,
279,16,143,3,0,
279,16,156,4,0,
279,16,164,4,0,
279,16,168,4,0,
279,16,173,3,0,
279,16,182,1,25,1
279,16,182,4,0,
279,16,196,3,0,
279,16,207,4,0,
279,16,211,4,0,
279,16,213,4,0,
279,16,214,4,0,
279,16,216,4,0,
279,16,218,4,0,
279,16,237,4,0,
279,16,240,4,0,
279,16,253,3,0,
279,16,254,1,33,1
279,16,255,1,33,3
279,16,256,1,33,2
279,16,258,4,0,
279,16,263,4,0,
279,16,282,3,0,
279,16,290,4,0,
279,16,332,4,0,
279,16,346,1,1,7
279,16,351,3,0,
279,16,352,1,15,1
279,16,352,3,0,
279,16,355,1,22,1
279,16,355,4,0,
279,16,362,1,28,1
279,16,366,1,1,3
279,16,366,1,44,1
279,16,366,3,0,
279,16,369,4,0,
279,16,371,1,19,1
279,16,371,4,0,
279,16,374,1,39,1
279,16,374,4,0,
279,16,402,3,0,
279,16,416,4,0,
279,16,441,3,0,
279,16,487,1,1,4
279,16,496,4,0,
279,16,497,4,0,
279,16,503,4,0,
279,16,507,4,0,
279,16,542,1,1,1
279,16,542,1,55,1
279,16,590,4,0,
279,17,17,1,1,9
279,17,17,1,8,
279,17,19,4,0,
279,17,45,1,1,6
279,17,48,1,5,
279,17,54,1,12,
279,17,55,1,1,7
279,17,56,1,1,3
279,17,56,1,50,
279,17,57,4,0,
279,17,58,4,0,
279,17,59,4,0,
279,17,63,4,0,
279,17,92,4,0,
279,17,104,4,0,
279,17,156,4,0,
279,17,164,4,0,
279,17,168,4,0,
279,17,182,1,0,
279,17,182,1,1,1
279,17,182,4,0,
279,17,207,4,0,
279,17,211,4,0,
279,17,213,4,0,
279,17,214,4,0,
279,17,216,4,0,
279,17,218,4,0,
279,17,237,4,0,
279,17,240,4,0,
279,17,254,1,33,1
279,17,255,1,33,3
279,17,256,1,33,2
279,17,258,4,0,
279,17,263,4,0,
279,17,332,4,0,
279,17,346,1,1,8
279,17,352,1,15,
279,17,355,1,39,
279,17,355,4,0,
279,17,362,1,22,
279,17,366,1,1,4
279,17,366,1,44,
279,17,369,4,0,
279,17,371,1,19,
279,17,371,4,0,
279,17,374,1,28,
279,17,374,4,0,
279,17,416,4,0,
279,17,487,1,1,5
279,17,496,4,0,
279,17,497,4,0,
279,17,503,4,0,
279,17,507,4,0,
279,17,542,1,1,2
279,17,542,1,55,
279,17,590,4,0,
279,18,17,1,1,9
279,18,17,1,8,
279,18,19,4,0,
279,18,45,1,1,6
279,18,48,1,5,
279,18,54,1,12,
279,18,55,1,1,7
279,18,56,1,1,3
279,18,56,1,50,
279,18,57,4,0,
279,18,58,4,0,
279,18,59,4,0,
279,18,63,4,0,
279,18,92,4,0,
279,18,104,4,0,
279,18,156,4,0,
279,18,164,4,0,
279,18,168,4,0,
279,18,182,1,0,
279,18,182,1,1,1
279,18,182,4,0,
279,18,207,4,0,
279,18,211,4,0,
279,18,213,4,0,
279,18,214,4,0,
279,18,216,4,0,
279,18,218,4,0,
279,18,237,4,0,
279,18,240,4,0,
279,18,254,1,33,1
279,18,255,1,33,3
279,18,256,1,33,2
279,18,258,4,0,
279,18,263,4,0,
279,18,332,4,0,
279,18,346,1,1,8
279,18,352,1,15,
279,18,355,1,39,
279,18,355,4,0,
279,18,362,1,22,
279,18,366,1,1,4
279,18,366,1,44,
279,18,369,4,0,
279,18,371,1,19,
279,18,371,4,0,
279,18,374,1,28,
279,18,374,4,0,
279,18,416,4,0,
279,18,487,1,1,5
279,18,496,4,0,
279,18,497,4,0,
279,18,503,4,0,
279,18,507,4,0,
279,18,542,1,1,2
279,18,542,1,55,
279,18,590,4,0,
280,5,45,1,1,
280,5,50,2,0,
280,5,85,4,0,
280,5,92,4,0,
280,5,93,1,6,
280,5,94,1,26,
280,5,94,4,0,
280,5,95,1,41,
280,5,100,1,16,
280,5,104,1,11,
280,5,104,4,0,
280,5,113,4,0,
280,5,115,4,0,
280,5,138,1,46,
280,5,148,4,0,
280,5,156,4,0,
280,5,168,4,0,
280,5,182,4,0,
280,5,194,2,0,
280,5,212,2,0,
280,5,213,4,0,
280,5,216,4,0,
280,5,218,4,0,
280,5,219,4,0,
280,5,237,4,0,
280,5,240,4,0,
280,5,241,4,0,
280,5,247,4,0,
280,5,248,1,36,
280,5,259,4,0,
280,5,261,2,0,
280,5,262,2,0,
280,5,263,4,0,
280,5,269,4,0,
280,5,285,4,0,
280,5,286,1,31,
280,5,289,4,0,
280,5,290,4,0,
280,5,347,1,21,
280,5,347,4,0,
280,5,351,4,0,
280,6,7,3,0,
280,6,8,3,0,
280,6,9,3,0,
280,6,34,3,0,
280,6,38,3,0,
280,6,45,1,1,
280,6,50,2,0,
280,6,85,4,0,
280,6,86,3,0,
280,6,92,4,0,
280,6,93,1,6,
280,6,94,1,26,
280,6,94,4,0,
280,6,95,1,41,
280,6,100,1,16,
280,6,102,3,0,
280,6,104,1,11,
280,6,104,4,0,
280,6,111,3,0,
280,6,113,4,0,
280,6,115,4,0,
280,6,138,1,46,
280,6,138,3,0,
280,6,148,4,0,
280,6,156,4,0,
280,6,164,3,0,
280,6,168,4,0,
280,6,173,3,0,
280,6,182,4,0,
280,6,189,3,0,
280,6,194,2,0,
280,6,196,3,0,
280,6,203,3,0,
280,6,207,3,0,
280,6,212,2,0,
280,6,213,4,0,
280,6,214,3,0,
280,6,216,4,0,
280,6,218,4,0,
280,6,219,4,0,
280,6,237,4,0,
280,6,240,4,0,
280,6,241,4,0,
280,6,244,3,0,
280,6,247,4,0,
280,6,248,1,36,
280,6,259,4,0,
280,6,261,2,0,
280,6,262,2,0,
280,6,263,4,0,
280,6,269,4,0,
280,6,285,4,0,
280,6,286,1,31,
280,6,289,4,0,
280,6,290,4,0,
280,6,347,1,21,
280,6,347,4,0,
280,6,351,4,0,
280,7,34,3,0,
280,7,38,3,0,
280,7,45,1,1,
280,7,50,2,0,
280,7,85,4,0,
280,7,86,3,0,
280,7,92,4,0,
280,7,93,1,6,
280,7,94,1,26,
280,7,94,4,0,
280,7,95,1,41,
280,7,100,1,16,
280,7,102,3,0,
280,7,104,1,11,
280,7,104,4,0,
280,7,113,4,0,
280,7,115,4,0,
280,7,138,1,46,
280,7,138,3,0,
280,7,148,4,0,
280,7,156,4,0,
280,7,164,3,0,
280,7,168,4,0,
280,7,182,4,0,
280,7,194,2,0,
280,7,212,2,0,
280,7,213,4,0,
280,7,216,4,0,
280,7,218,4,0,
280,7,219,4,0,
280,7,237,4,0,
280,7,240,4,0,
280,7,241,4,0,
280,7,247,4,0,
280,7,248,1,36,
280,7,259,4,0,
280,7,261,2,0,
280,7,262,2,0,
280,7,263,4,0,
280,7,269,4,0,
280,7,285,4,0,
280,7,286,1,31,
280,7,289,4,0,
280,7,290,4,0,
280,7,347,1,21,
280,7,347,4,0,
280,7,351,4,0,
280,8,45,1,1,
280,8,50,2,0,
280,8,85,4,0,
280,8,86,4,0,
280,8,92,4,0,
280,8,93,1,6,
280,8,94,1,28,
280,8,94,4,0,
280,8,95,1,43,
280,8,100,1,12,
280,8,104,1,10,
280,8,104,4,0,
280,8,109,2,0,
280,8,113,4,0,
280,8,115,4,0,
280,8,138,1,45,
280,8,138,4,0,
280,8,148,4,0,
280,8,156,4,0,
280,8,164,4,0,
280,8,168,4,0,
280,8,182,4,0,
280,8,194,2,0,
280,8,203,4,0,
280,8,204,1,39,
280,8,207,4,0,
280,8,212,2,0,
280,8,213,4,0,
280,8,214,4,0,
280,8,216,4,0,
280,8,218,4,0,
280,8,219,4,0,
280,8,237,4,0,
280,8,240,4,0,
280,8,241,4,0,
280,8,244,4,0,
280,8,247,4,0,
280,8,248,1,34,
280,8,259,4,0,
280,8,261,2,0,
280,8,262,2,0,
280,8,263,4,0,
280,8,269,4,0,
280,8,278,4,0,
280,8,285,4,0,
280,8,286,1,32,
280,8,288,2,0,
280,8,289,4,0,
280,8,290,4,0,
280,8,345,1,21,
280,8,347,1,23,
280,8,347,4,0,
280,8,351,4,0,
280,8,363,4,0,
280,8,374,4,0,
280,8,381,1,17,
280,8,425,2,0,
280,8,433,4,0,
280,8,445,4,0,
280,8,447,4,0,
280,8,451,4,0,
280,9,7,3,0,
280,9,8,3,0,
280,9,9,3,0,
280,9,45,1,1,
280,9,50,2,0,
280,9,85,4,0,
280,9,86,4,0,
280,9,92,4,0,
280,9,93,1,6,
280,9,94,1,28,
280,9,94,4,0,
280,9,95,1,43,
280,9,100,1,12,
280,9,104,1,10,
280,9,104,4,0,
280,9,109,2,0,
280,9,113,4,0,
280,9,115,4,0,
280,9,129,3,0,
280,9,138,1,45,
280,9,138,4,0,
280,9,148,4,0,
280,9,156,4,0,
280,9,164,4,0,
280,9,168,4,0,
280,9,173,3,0,
280,9,182,4,0,
280,9,189,3,0,
280,9,194,2,0,
280,9,196,3,0,
280,9,203,4,0,
280,9,204,1,39,
280,9,207,4,0,
280,9,212,2,0,
280,9,213,4,0,
280,9,214,4,0,
280,9,216,4,0,
280,9,218,4,0,
280,9,219,4,0,
280,9,237,4,0,
280,9,240,4,0,
280,9,241,4,0,
280,9,244,4,0,
280,9,247,4,0,
280,9,248,1,34,
280,9,259,4,0,
280,9,261,2,0,
280,9,262,2,0,
280,9,263,4,0,
280,9,269,4,0,
280,9,270,3,0,
280,9,271,3,0,
280,9,278,4,0,
280,9,285,4,0,
280,9,286,1,32,
280,9,288,2,0,
280,9,289,4,0,
280,9,290,4,0,
280,9,324,3,0,
280,9,345,1,21,
280,9,347,1,23,
280,9,347,4,0,
280,9,351,4,0,
280,9,363,4,0,
280,9,374,4,0,
280,9,381,1,17,
280,9,425,2,0,
280,9,428,3,0,
280,9,433,4,0,
280,9,445,4,0,
280,9,447,4,0,
280,9,451,4,0,
280,10,7,3,0,
280,10,8,3,0,
280,10,9,3,0,
280,10,29,3,0,
280,10,45,1,1,
280,10,50,2,0,
280,10,85,4,0,
280,10,86,4,0,
280,10,92,4,0,
280,10,93,1,6,
280,10,94,1,28,
280,10,94,4,0,
280,10,95,1,43,
280,10,100,1,12,
280,10,104,1,10,
280,10,104,4,0,
280,10,109,2,0,
280,10,113,4,0,
280,10,115,4,0,
280,10,129,3,0,
280,10,138,1,45,
280,10,138,4,0,
280,10,148,4,0,
280,10,156,4,0,
280,10,164,4,0,
280,10,168,4,0,
280,10,173,3,0,
280,10,182,4,0,
280,10,189,3,0,
280,10,194,2,0,
280,10,196,3,0,
280,10,203,4,0,
280,10,204,1,39,
280,10,207,4,0,
280,10,212,2,0,
280,10,213,4,0,
280,10,214,4,0,
280,10,216,4,0,
280,10,218,4,0,
280,10,219,4,0,
280,10,220,3,0,
280,10,227,2,0,
280,10,237,4,0,
280,10,240,4,0,
280,10,241,4,0,
280,10,244,4,0,
280,10,247,4,0,
280,10,248,1,34,
280,10,259,4,0,
280,10,261,2,0,
280,10,262,2,0,
280,10,263,4,0,
280,10,269,4,0,
280,10,270,3,0,
280,10,271,3,0,
280,10,277,3,0,
280,10,278,4,0,
280,10,285,4,0,
280,10,286,1,32,
280,10,288,2,0,
280,10,289,4,0,
280,10,290,4,0,
280,10,324,3,0,
280,10,345,1,21,
280,10,347,1,23,
280,10,347,4,0,
280,10,351,4,0,
280,10,363,4,0,
280,10,374,4,0,
280,10,381,1,17,
280,10,425,2,0,
280,10,428,3,0,
280,10,433,4,0,
280,10,445,4,0,
280,10,447,4,0,
280,10,451,4,0,
280,11,45,1,1,
280,11,50,2,0,
280,11,85,4,0,
280,11,86,4,0,
280,11,92,4,0,
280,11,93,1,6,
280,11,94,1,32,
280,11,94,4,0,
280,11,95,1,45,
280,11,100,1,12,
280,11,104,1,10,
280,11,104,4,0,
280,11,109,2,0,
280,11,113,4,0,
280,11,115,4,0,
280,11,138,1,50,
280,11,138,4,0,
280,11,148,4,0,
280,11,156,4,0,
280,11,164,4,0,
280,11,168,4,0,
280,11,182,4,0,
280,11,194,2,0,
280,11,204,1,43,
280,11,207,4,0,
280,11,212,2,0,
280,11,213,4,0,
280,11,216,4,0,
280,11,218,4,0,
280,11,219,4,0,
280,11,227,2,0,
280,11,237,4,0,
280,11,240,4,0,
280,11,241,4,0,
280,11,244,4,0,
280,11,247,4,0,
280,11,248,1,39,
280,11,259,4,0,
280,11,261,4,0,
280,11,262,2,0,
280,11,263,4,0,
280,11,269,4,0,
280,11,285,2,0,
280,11,286,1,34,
280,11,288,2,0,
280,11,345,1,21,
280,11,347,1,28,
280,11,347,4,0,
280,11,374,4,0,
280,11,381,1,17,
280,11,425,2,0,
280,11,433,4,0,
280,11,447,4,0,
280,11,451,4,0,
280,11,473,4,0,
280,11,477,4,0,
280,11,485,2,0,
280,11,496,4,0,
280,11,497,4,0,
280,11,500,1,54,
280,11,502,4,0,
280,11,505,1,23,
280,12,45,1,1,
280,12,85,4,0,
280,12,92,4,0,
280,12,93,1,6,
280,12,94,1,26,
280,12,94,4,0,
280,12,95,1,41,
280,12,100,1,16,
280,12,104,1,11,
280,12,104,4,0,
280,12,113,4,0,
280,12,115,4,0,
280,12,138,1,46,
280,12,148,4,0,
280,12,156,4,0,
280,12,168,4,0,
280,12,182,4,0,
280,12,213,4,0,
280,12,216,4,0,
280,12,218,4,0,
280,12,219,4,0,
280,12,237,4,0,
280,12,240,4,0,
280,12,241,4,0,
280,12,247,4,0,
280,12,248,1,36,
280,12,259,4,0,
280,12,263,4,0,
280,12,269,4,0,
280,12,285,4,0,
280,12,286,1,31,
280,12,289,4,0,
280,12,290,4,0,
280,12,347,1,21,
280,12,347,4,0,
280,12,351,4,0,
280,13,34,3,0,
280,13,38,3,0,
280,13,45,1,1,
280,13,85,4,0,
280,13,86,3,0,
280,13,92,4,0,
280,13,93,1,6,
280,13,94,1,26,
280,13,94,4,0,
280,13,95,1,41,
280,13,100,1,16,
280,13,102,3,0,
280,13,104,1,11,
280,13,104,4,0,
280,13,113,4,0,
280,13,115,4,0,
280,13,138,1,46,
280,13,138,3,0,
280,13,148,4,0,
280,13,156,4,0,
280,13,164,3,0,
280,13,168,4,0,
280,13,171,3,0,
280,13,182,4,0,
280,13,196,3,0,
280,13,207,3,0,
280,13,213,4,0,
280,13,216,4,0,
280,13,218,4,0,
280,13,219,4,0,
280,13,237,4,0,
280,13,240,4,0,
280,13,241,4,0,
280,13,247,4,0,
280,13,248,1,36,
280,13,259,4,0,
280,13,263,4,0,
280,13,269,4,0,
280,13,285,4,0,
280,13,286,1,31,
280,13,289,4,0,
280,13,290,4,0,
280,13,347,1,21,
280,13,347,4,0,
280,13,351,4,0,
280,14,7,3,0,
280,14,8,3,0,
280,14,9,3,0,
280,14,45,1,1,
280,14,50,2,0,
280,14,85,4,0,
280,14,86,4,0,
280,14,92,4,0,
280,14,93,1,6,
280,14,94,1,32,
280,14,94,4,0,
280,14,95,1,45,
280,14,100,1,12,
280,14,104,1,10,
280,14,104,4,0,
280,14,109,2,0,
280,14,113,4,0,
280,14,115,4,0,
280,14,138,1,50,
280,14,138,4,0,
280,14,148,4,0,
280,14,156,4,0,
280,14,164,4,0,
280,14,168,4,0,
280,14,173,3,0,
280,14,182,4,0,
280,14,194,2,0,
280,14,196,3,0,
280,14,204,1,43,
280,14,207,4,0,
280,14,212,2,0,
280,14,213,4,0,
280,14,214,3,0,
280,14,216,4,0,
280,14,218,4,0,
280,14,219,4,0,
280,14,220,3,0,
280,14,227,2,0,
280,14,237,4,0,
280,14,240,4,0,
280,14,241,4,0,
280,14,244,4,0,
280,14,247,4,0,
280,14,248,1,39,
280,14,259,4,0,
280,14,261,4,0,
280,14,262,2,0,
280,14,263,4,0,
280,14,269,4,0,
280,14,270,3,0,
280,14,271,3,0,
280,14,277,3,0,
280,14,278,3,0,
280,14,285,2,0,
280,14,285,3,0,
280,14,286,1,34,
280,14,288,2,0,
280,14,289,3,0,
280,14,304,3,0,
280,14,324,3,0,
280,14,345,1,21,
280,14,347,1,28,
280,14,347,4,0,
280,14,374,4,0,
280,14,381,1,17,
280,14,425,2,0,
280,14,428,3,0,
280,14,433,4,0,
280,14,447,4,0,
280,14,451,4,0,
280,14,472,3,0,
280,14,473,4,0,
280,14,477,4,0,
280,14,478,3,0,
280,14,485,2,0,
280,14,496,4,0,
280,14,497,4,0,
280,14,500,1,54,
280,14,502,4,0,
280,14,505,1,23,
280,15,45,1,1,
280,15,50,2,0,
280,15,85,4,0,
280,15,86,4,0,
280,15,92,4,0,
280,15,93,1,6,
280,15,94,1,32,
280,15,94,4,0,
280,15,95,1,45,
280,15,100,1,12,
280,15,104,1,10,
280,15,104,4,0,
280,15,109,2,0,
280,15,113,4,0,
280,15,115,4,0,
280,15,138,1,49,
280,15,138,4,0,
280,15,148,4,0,
280,15,156,4,0,
280,15,164,4,0,
280,15,168,4,0,
280,15,182,4,0,
280,15,194,2,0,
280,15,204,1,43,
280,15,207,4,0,
280,15,212,2,0,
280,15,213,4,0,
280,15,214,4,0,
280,15,216,4,0,
280,15,218,4,0,
280,15,219,4,0,
280,15,227,2,0,
280,15,237,4,0,
280,15,240,4,0,
280,15,241,4,0,
280,15,244,4,0,
280,15,247,4,0,
280,15,248,1,39,
280,15,259,4,0,
280,15,261,4,0,
280,15,262,2,0,
280,15,263,4,0,
280,15,269,4,0,
280,15,285,2,0,
280,15,286,1,34,
280,15,288,2,0,
280,15,345,1,21,
280,15,347,1,28,
280,15,347,4,0,
280,15,374,4,0,
280,15,381,1,17,
280,15,425,2,0,
280,15,433,4,0,
280,15,447,4,0,
280,15,451,4,0,
280,15,473,4,0,
280,15,485,2,0,
280,15,496,4,0,
280,15,497,4,0,
280,15,500,1,54,
280,15,502,2,0,
280,15,505,1,23,
280,15,581,2,0,
280,15,590,4,0,
280,15,605,4,0,
280,16,7,3,0,
280,16,8,3,0,
280,16,9,3,0,
280,16,45,1,1,1
280,16,50,2,0,
280,16,85,4,0,
280,16,86,4,0,
280,16,92,4,0,
280,16,93,1,4,1
280,16,94,1,27,1
280,16,94,4,0,
280,16,95,1,37,1
280,16,100,1,9,1
280,16,104,1,6,1
280,16,104,4,0,
280,16,109,2,0,
280,16,113,4,0,
280,16,115,4,0,
280,16,138,1,39,1
280,16,138,4,0,
280,16,148,4,0,
280,16,156,4,0,
280,16,164,4,0,
280,16,168,4,0,
280,16,173,3,0,
280,16,182,4,0,
280,16,194,2,0,
280,16,196,3,0,
280,16,204,1,34,1
280,16,207,4,0,
280,16,212,2,0,
280,16,213,4,0,
280,16,214,4,0,
280,16,216,4,0,
280,16,218,4,0,
280,16,219,4,0,
280,16,220,3,0,
280,16,227,2,0,
280,16,237,4,0,
280,16,240,4,0,
280,16,241,4,0,
280,16,244,4,0,
280,16,247,4,0,
280,16,248,1,32,1
280,16,259,4,0,
280,16,261,4,0,
280,16,262,2,0,
280,16,263,4,0,
280,16,269,4,0,
280,16,270,3,0,
280,16,271,3,0,
280,16,277,3,0,
280,16,278,3,0,
280,16,285,2,0,
280,16,285,3,0,
280,16,286,1,29,1
280,16,288,2,0,
280,16,289,3,0,
280,16,290,4,0,
280,16,304,3,0,
280,16,324,3,0,
280,16,345,1,17,1
280,16,347,1,24,1
280,16,347,4,0,
280,16,351,3,0,
280,16,374,4,0,
280,16,381,1,14,1
280,16,425,2,0,
280,16,428,3,0,
280,16,433,4,0,
280,16,447,4,0,
280,16,451,4,0,
280,16,472,3,0,
280,16,473,4,0,
280,16,478,3,0,
280,16,485,2,0,
280,16,496,4,0,
280,16,497,4,0,
280,16,500,1,42,1
280,16,502,2,0,
280,16,505,1,19,1
280,16,574,1,11,1
280,16,577,1,22,1
280,16,581,2,0,
280,16,590,4,0,
280,16,605,4,0,
280,17,45,1,1,
280,17,50,2,0,
280,17,85,4,0,
280,17,86,4,0,
280,17,92,4,0,
280,17,93,1,4,
280,17,94,1,27,
280,17,94,4,0,
280,17,95,1,37,
280,17,100,1,9,
280,17,104,1,6,
280,17,104,4,0,
280,17,109,2,0,
280,17,113,4,0,
280,17,115,4,0,
280,17,138,1,39,
280,17,138,4,0,
280,17,156,4,0,
280,17,164,4,0,
280,17,168,4,0,
280,17,182,4,0,
280,17,194,2,0,
280,17,204,1,34,
280,17,207,4,0,
280,17,212,2,0,
280,17,213,4,0,
280,17,214,4,0,
280,17,216,4,0,
280,17,218,4,0,
280,17,219,4,0,
280,17,227,2,0,
280,17,237,4,0,
280,17,240,4,0,
280,17,241,4,0,
280,17,244,4,0,
280,17,247,4,0,
280,17,248,1,32,
280,17,259,4,0,
280,17,261,4,0,
280,17,262,2,0,
280,17,263,4,0,
280,17,269,4,0,
280,17,285,2,0,
280,17,286,1,29,
280,17,288,2,0,
280,17,345,1,17,
280,17,347,1,24,
280,17,347,4,0,
280,17,374,4,0,
280,17,381,1,14,
280,17,425,2,0,
280,17,433,4,0,
280,17,447,4,0,
280,17,451,4,0,
280,17,473,4,0,
280,17,485,2,0,
280,17,496,4,0,
280,17,497,4,0,
280,17,500,1,42,
280,17,502,2,0,
280,17,505,1,19,
280,17,574,1,11,
280,17,577,1,22,
280,17,581,2,0,
280,17,590,4,0,
280,17,605,4,0,
280,18,45,1,1,
280,18,50,2,0,
280,18,85,4,0,
280,18,86,4,0,
280,18,92,4,0,
280,18,93,1,4,
280,18,94,1,27,
280,18,94,4,0,
280,18,95,1,37,
280,18,100,1,9,
280,18,104,1,6,
280,18,104,4,0,
280,18,109,2,0,
280,18,113,4,0,
280,18,115,4,0,
280,18,138,1,39,
280,18,138,4,0,
280,18,156,4,0,
280,18,164,4,0,
280,18,168,4,0,
280,18,182,4,0,
280,18,194,2,0,
280,18,204,1,34,
280,18,207,4,0,
280,18,212,2,0,
280,18,213,4,0,
280,18,214,4,0,
280,18,216,4,0,
280,18,218,4,0,
280,18,219,4,0,
280,18,227,2,0,
280,18,237,4,0,
280,18,240,4,0,
280,18,241,4,0,
280,18,244,4,0,
280,18,247,4,0,
280,18,248,1,32,
280,18,259,4,0,
280,18,261,4,0,
280,18,262,2,0,
280,18,263,4,0,
280,18,269,4,0,
280,18,285,2,0,
280,18,286,1,29,
280,18,288,2,0,
280,18,345,1,17,
280,18,347,1,24,
280,18,347,4,0,
280,18,374,4,0,
280,18,381,1,14,
280,18,425,2,0,
280,18,433,4,0,
280,18,447,4,0,
280,18,451,4,0,
280,18,473,4,0,
280,18,485,2,0,
280,18,496,4,0,
280,18,497,4,0,
280,18,500,1,42,
280,18,502,2,0,
280,18,505,1,19,
280,18,574,1,11,
280,18,577,1,22,
280,18,581,2,0,
280,18,590,4,0,
280,18,605,4,0,
281,5,45,1,1,1
281,5,85,4,0,
281,5,92,4,0,
281,5,93,1,1,2
281,5,93,1,6,
281,5,94,1,26,
281,5,94,4,0,
281,5,95,1,47,
281,5,100,1,1,4
281,5,100,1,16,
281,5,104,1,1,3
281,5,104,1,11,
281,5,104,4,0,
281,5,113,4,0,
281,5,115,4,0,
281,5,138,1,54,
281,5,148,4,0,
281,5,156,4,0,
281,5,168,4,0,
281,5,182,4,0,
281,5,213,4,0,
281,5,216,4,0,
281,5,218,4,0,
281,5,219,4,0,
281,5,237,4,0,
281,5,240,4,0,
281,5,241,4,0,
281,5,247,4,0,
281,5,248,1,40,
281,5,259,4,0,
281,5,263,4,0,
281,5,269,4,0,
281,5,285,4,0,
281,5,286,1,33,
281,5,289,4,0,
281,5,290,4,0,
281,5,347,1,21,
281,5,347,4,0,
281,5,351,4,0,
281,6,7,3,0,
281,6,8,3,0,
281,6,9,3,0,
281,6,34,3,0,
281,6,38,3,0,
281,6,45,1,1,1
281,6,85,4,0,
281,6,86,3,0,
281,6,92,4,0,
281,6,93,1,1,2
281,6,93,1,6,
281,6,94,1,26,
281,6,94,4,0,
281,6,95,1,47,
281,6,100,1,1,4
281,6,100,1,16,
281,6,102,3,0,
281,6,104,1,1,3
281,6,104,1,11,
281,6,104,4,0,
281,6,111,3,0,
281,6,113,4,0,
281,6,115,4,0,
281,6,138,1,54,
281,6,138,3,0,
281,6,148,4,0,
281,6,156,4,0,
281,6,164,3,0,
281,6,168,4,0,
281,6,173,3,0,
281,6,182,4,0,
281,6,189,3,0,
281,6,196,3,0,
281,6,203,3,0,
281,6,207,3,0,
281,6,213,4,0,
281,6,214,3,0,
281,6,216,4,0,
281,6,218,4,0,
281,6,219,4,0,
281,6,237,4,0,
281,6,240,4,0,
281,6,241,4,0,
281,6,244,3,0,
281,6,247,4,0,
281,6,248,1,40,
281,6,259,4,0,
281,6,263,4,0,
281,6,269,4,0,
281,6,285,4,0,
281,6,286,1,33,
281,6,289,4,0,
281,6,290,4,0,
281,6,347,1,21,
281,6,347,4,0,
281,6,351,4,0,
281,7,34,3,0,
281,7,38,3,0,
281,7,45,1,1,2
281,7,85,4,0,
281,7,86,3,0,
281,7,92,4,0,
281,7,93,1,1,3
281,7,93,1,6,
281,7,94,1,26,
281,7,94,4,0,
281,7,95,1,47,
281,7,100,1,1,5
281,7,100,1,16,
281,7,102,3,0,
281,7,104,1,1,4
281,7,104,1,11,
281,7,104,4,0,
281,7,113,4,0,
281,7,115,4,0,
281,7,138,1,54,
281,7,138,3,0,
281,7,148,4,0,
281,7,156,4,0,
281,7,164,3,0,
281,7,168,4,0,
281,7,182,4,0,
281,7,213,4,0,
281,7,216,4,0,
281,7,218,4,0,
281,7,219,4,0,
281,7,237,4,0,
281,7,240,4,0,
281,7,241,4,0,
281,7,247,4,0,
281,7,248,1,40,
281,7,259,4,0,
281,7,263,4,0,
281,7,269,4,0,
281,7,285,4,0,
281,7,286,1,33,
281,7,289,4,0,
281,7,290,4,0,
281,7,345,1,1,1
281,7,347,1,21,
281,7,347,4,0,
281,7,351,4,0,
281,8,45,1,1,1
281,8,85,4,0,
281,8,86,4,0,
281,8,92,4,0,
281,8,93,1,1,2
281,8,93,1,6,
281,8,94,1,31,
281,8,94,4,0,
281,8,95,1,50,
281,8,100,1,1,4
281,8,100,1,12,
281,8,104,1,1,3
281,8,104,1,10,
281,8,104,4,0,
281,8,113,4,0,
281,8,115,4,0,
281,8,138,1,53,
281,8,138,4,0,
281,8,148,4,0,
281,8,156,4,0,
281,8,164,4,0,
281,8,168,4,0,
281,8,182,4,0,
281,8,203,4,0,
281,8,204,1,45,
281,8,207,4,0,
281,8,213,4,0,
281,8,214,4,0,
281,8,216,4,0,
281,8,218,4,0,
281,8,219,4,0,
281,8,237,4,0,
281,8,240,4,0,
281,8,241,4,0,
281,8,244,4,0,
281,8,247,4,0,
281,8,248,1,39,
281,8,259,4,0,
281,8,263,4,0,
281,8,269,4,0,
281,8,278,4,0,
281,8,285,4,0,
281,8,286,1,36,
281,8,289,4,0,
281,8,290,4,0,
281,8,345,1,22,
281,8,347,1,25,
281,8,347,4,0,
281,8,351,4,0,
281,8,363,4,0,
281,8,374,4,0,
281,8,381,1,17,
281,8,433,4,0,
281,8,445,4,0,
281,8,447,4,0,
281,8,451,4,0,
281,9,7,3,0,
281,9,8,3,0,
281,9,9,3,0,
281,9,45,1,1,1
281,9,85,4,0,
281,9,86,4,0,
281,9,92,4,0,
281,9,93,1,1,2
281,9,93,1,6,
281,9,94,1,31,
281,9,94,4,0,
281,9,95,1,50,
281,9,100,1,1,4
281,9,100,1,12,
281,9,104,1,1,3
281,9,104,1,10,
281,9,104,4,0,
281,9,113,4,0,
281,9,115,4,0,
281,9,129,3,0,
281,9,138,1,53,
281,9,138,4,0,
281,9,148,4,0,
281,9,156,4,0,
281,9,164,4,0,
281,9,168,4,0,
281,9,173,3,0,
281,9,182,4,0,
281,9,189,3,0,
281,9,196,3,0,
281,9,203,4,0,
281,9,204,1,45,
281,9,207,4,0,
281,9,213,4,0,
281,9,214,4,0,
281,9,216,4,0,
281,9,218,4,0,
281,9,219,4,0,
281,9,237,4,0,
281,9,240,4,0,
281,9,241,4,0,
281,9,244,4,0,
281,9,247,4,0,
281,9,248,1,39,
281,9,259,4,0,
281,9,263,4,0,
281,9,269,4,0,
281,9,270,3,0,
281,9,271,3,0,
281,9,278,4,0,
281,9,285,4,0,
281,9,286,1,36,
281,9,289,4,0,
281,9,290,4,0,
281,9,324,3,0,
281,9,345,1,22,
281,9,347,1,25,
281,9,347,4,0,
281,9,351,4,0,
281,9,363,4,0,
281,9,374,4,0,
281,9,381,1,17,
281,9,428,3,0,
281,9,433,4,0,
281,9,445,4,0,
281,9,447,4,0,
281,9,451,4,0,
281,10,7,3,0,
281,10,8,3,0,
281,10,9,3,0,
281,10,29,3,0,
281,10,45,1,1,1
281,10,85,4,0,
281,10,86,4,0,
281,10,92,4,0,
281,10,93,1,1,2
281,10,93,1,6,
281,10,94,1,31,
281,10,94,4,0,
281,10,95,1,50,
281,10,100,1,1,4
281,10,100,1,12,
281,10,104,1,1,3
281,10,104,1,10,
281,10,104,4,0,
281,10,113,4,0,
281,10,115,4,0,
281,10,129,3,0,
281,10,138,1,53,
281,10,138,4,0,
281,10,148,4,0,
281,10,156,4,0,
281,10,164,4,0,
281,10,168,4,0,
281,10,173,3,0,
281,10,182,4,0,
281,10,189,3,0,
281,10,196,3,0,
281,10,203,4,0,
281,10,204,1,45,
281,10,207,4,0,
281,10,213,4,0,
281,10,214,4,0,
281,10,216,4,0,
281,10,218,4,0,
281,10,219,4,0,
281,10,220,3,0,
281,10,237,4,0,
281,10,240,4,0,
281,10,241,4,0,
281,10,244,4,0,
281,10,247,4,0,
281,10,248,1,39,
281,10,259,4,0,
281,10,263,4,0,
281,10,269,4,0,
281,10,270,3,0,
281,10,271,3,0,
281,10,277,3,0,
281,10,278,4,0,
281,10,285,4,0,
281,10,286,1,36,
281,10,289,4,0,
281,10,290,4,0,
281,10,324,3,0,
281,10,345,1,22,
281,10,347,1,25,
281,10,347,4,0,
281,10,351,4,0,
281,10,363,4,0,
281,10,374,4,0,
281,10,381,1,17,
281,10,428,3,0,
281,10,433,4,0,
281,10,445,4,0,
281,10,447,4,0,
281,10,451,4,0,
281,11,45,1,1,1
281,11,85,4,0,
281,11,86,4,0,
281,11,92,4,0,
281,11,93,1,1,2
281,11,93,1,6,
281,11,94,1,36,
281,11,94,4,0,
281,11,95,1,53,
281,11,100,1,1,4
281,11,100,1,12,
281,11,104,1,1,3
281,11,104,1,10,
281,11,104,4,0,
281,11,113,4,0,
281,11,115,4,0,
281,11,138,1,59,
281,11,138,4,0,
281,11,148,4,0,
281,11,156,4,0,
281,11,164,4,0,
281,11,168,4,0,
281,11,182,4,0,
281,11,204,1,50,
281,11,207,4,0,
281,11,213,4,0,
281,11,216,4,0,
281,11,218,4,0,
281,11,219,4,0,
281,11,237,4,0,
281,11,240,4,0,
281,11,241,4,0,
281,11,244,4,0,
281,11,247,4,0,
281,11,248,1,45,
281,11,259,4,0,
281,11,261,4,0,
281,11,263,4,0,
281,11,269,4,0,
281,11,286,1,39,
281,11,345,1,22,
281,11,347,1,31,
281,11,347,4,0,
281,11,374,4,0,
281,11,381,1,17,
281,11,433,4,0,
281,11,447,4,0,
281,11,451,4,0,
281,11,473,4,0,
281,11,477,4,0,
281,11,496,4,0,
281,11,497,4,0,
281,11,500,1,64,
281,11,502,4,0,
281,11,505,1,25,
281,12,45,1,1,1
281,12,85,4,0,
281,12,92,4,0,
281,12,93,1,1,2
281,12,93,1,6,
281,12,94,1,26,
281,12,94,4,0,
281,12,95,1,47,
281,12,100,1,1,4
281,12,100,1,16,
281,12,104,1,1,3
281,12,104,1,11,
281,12,104,4,0,
281,12,113,4,0,
281,12,115,4,0,
281,12,138,1,54,
281,12,148,4,0,
281,12,156,4,0,
281,12,168,4,0,
281,12,182,4,0,
281,12,213,4,0,
281,12,216,4,0,
281,12,218,4,0,
281,12,219,4,0,
281,12,237,4,0,
281,12,240,4,0,
281,12,241,4,0,
281,12,247,4,0,
281,12,248,1,40,
281,12,259,4,0,
281,12,263,4,0,
281,12,269,4,0,
281,12,285,4,0,
281,12,286,1,33,
281,12,289,4,0,
281,12,290,4,0,
281,12,347,1,21,
281,12,347,4,0,
281,12,351,4,0,
281,13,34,3,0,
281,13,38,3,0,
281,13,45,1,1,1
281,13,85,4,0,
281,13,86,3,0,
281,13,92,4,0,
281,13,93,1,1,2
281,13,93,1,6,
281,13,94,1,26,
281,13,94,4,0,
281,13,95,1,47,
281,13,100,1,1,4
281,13,100,1,16,
281,13,102,3,0,
281,13,104,1,1,3
281,13,104,1,11,
281,13,104,4,0,
281,13,113,4,0,
281,13,115,4,0,
281,13,138,1,54,
281,13,138,3,0,
281,13,148,4,0,
281,13,156,4,0,
281,13,164,3,0,
281,13,168,4,0,
281,13,171,3,0,
281,13,182,4,0,
281,13,196,3,0,
281,13,207,3,0,
281,13,213,4,0,
281,13,216,4,0,
281,13,218,4,0,
281,13,219,4,0,
281,13,237,4,0,
281,13,240,4,0,
281,13,241,4,0,
281,13,247,4,0,
281,13,248,1,40,
281,13,259,4,0,
281,13,263,4,0,
281,13,269,4,0,
281,13,285,4,0,
281,13,286,1,33,
281,13,289,4,0,
281,13,290,4,0,
281,13,347,1,21,
281,13,347,4,0,
281,13,351,4,0,
281,14,7,3,0,
281,14,8,3,0,
281,14,9,3,0,
281,14,45,1,1,1
281,14,85,4,0,
281,14,86,4,0,
281,14,92,4,0,
281,14,93,1,1,2
281,14,93,1,6,
281,14,94,1,36,
281,14,94,4,0,
281,14,95,1,53,
281,14,100,1,1,4
281,14,100,1,12,
281,14,104,1,1,3
281,14,104,1,10,
281,14,104,4,0,
281,14,113,4,0,
281,14,115,4,0,
281,14,138,1,59,
281,14,138,4,0,
281,14,148,4,0,
281,14,156,4,0,
281,14,164,4,0,
281,14,168,4,0,
281,14,173,3,0,
281,14,182,4,0,
281,14,196,3,0,
281,14,204,1,50,
281,14,207,4,0,
281,14,213,4,0,
281,14,214,3,0,
281,14,216,4,0,
281,14,218,4,0,
281,14,219,4,0,
281,14,220,3,0,
281,14,237,4,0,
281,14,240,4,0,
281,14,241,4,0,
281,14,244,4,0,
281,14,247,4,0,
281,14,248,1,45,
281,14,259,4,0,
281,14,261,4,0,
281,14,263,4,0,
281,14,269,4,0,
281,14,270,3,0,
281,14,271,3,0,
281,14,277,3,0,
281,14,278,3,0,
281,14,285,3,0,
281,14,286,1,39,
281,14,289,3,0,
281,14,304,3,0,
281,14,324,3,0,
281,14,345,1,22,
281,14,347,1,31,
281,14,347,4,0,
281,14,374,4,0,
281,14,381,1,17,
281,14,428,3,0,
281,14,433,4,0,
281,14,447,4,0,
281,14,451,4,0,
281,14,472,3,0,
281,14,473,4,0,
281,14,477,4,0,
281,14,478,3,0,
281,14,496,4,0,
281,14,497,4,0,
281,14,500,1,64,
281,14,502,4,0,
281,14,505,1,25,
281,15,45,1,1,1
281,15,85,4,0,
281,15,86,4,0,
281,15,92,4,0,
281,15,93,1,1,2
281,15,93,1,6,
281,15,94,1,36,
281,15,94,4,0,
281,15,95,1,53,
281,15,100,1,1,4
281,15,100,1,12,
281,15,104,1,1,3
281,15,104,1,10,
281,15,104,4,0,
281,15,113,4,0,
281,15,115,4,0,
281,15,138,1,59,
281,15,138,4,0,
281,15,148,4,0,
281,15,156,4,0,
281,15,164,4,0,
281,15,168,4,0,
281,15,182,4,0,
281,15,204,1,50,
281,15,207,4,0,
281,15,213,4,0,
281,15,214,4,0,
281,15,216,4,0,
281,15,218,4,0,
281,15,219,4,0,
281,15,237,4,0,
281,15,240,4,0,
281,15,241,4,0,
281,15,244,4,0,
281,15,247,4,0,
281,15,248,1,45,
281,15,259,4,0,
281,15,261,4,0,
281,15,263,4,0,
281,15,269,4,0,
281,15,286,1,39,
281,15,345,1,22,
281,15,347,1,31,
281,15,347,4,0,
281,15,374,4,0,
281,15,381,1,17,
281,15,433,4,0,
281,15,447,4,0,
281,15,451,4,0,
281,15,473,4,0,
281,15,496,4,0,
281,15,497,4,0,
281,15,500,1,64,
281,15,505,1,25,
281,15,590,4,0,
281,15,605,4,0,
281,16,7,3,0,
281,16,8,3,0,
281,16,9,3,0,
281,16,45,1,1,1
281,16,85,4,0,
281,16,86,4,0,
281,16,92,4,0,
281,16,93,1,1,2
281,16,93,1,4,1
281,16,94,1,30,1
281,16,94,4,0,
281,16,95,1,44,1
281,16,100,1,1,4
281,16,100,1,9,1
281,16,104,1,1,3
281,16,104,1,6,1
281,16,104,4,0,
281,16,113,4,0,
281,16,115,4,0,
281,16,138,1,47,1
281,16,138,4,0,
281,16,148,4,0,
281,16,156,4,0,
281,16,164,4,0,
281,16,168,4,0,
281,16,173,3,0,
281,16,182,4,0,
281,16,196,3,0,
281,16,204,1,40,1
281,16,207,4,0,
281,16,213,4,0,
281,16,214,4,0,
281,16,216,4,0,
281,16,218,4,0,
281,16,219,4,0,
281,16,220,3,0,
281,16,237,4,0,
281,16,240,4,0,
281,16,241,4,0,
281,16,244,4,0,
281,16,247,4,0,
281,16,248,1,37,1
281,16,259,4,0,
281,16,261,4,0,
281,16,263,4,0,
281,16,269,4,0,
281,16,270,3,0,
281,16,271,3,0,
281,16,277,3,0,
281,16,278,3,0,
281,16,285,3,0,
281,16,286,1,33,1
281,16,289,3,0,
281,16,290,4,0,
281,16,304,3,0,
281,16,324,3,0,
281,16,345,1,17,1
281,16,347,1,26,1
281,16,347,4,0,
281,16,351,3,0,
281,16,374,4,0,
281,16,381,1,14,1
281,16,428,3,0,
281,16,433,4,0,
281,16,447,4,0,
281,16,451,4,0,
281,16,472,3,0,
281,16,473,4,0,
281,16,478,3,0,
281,16,496,4,0,
281,16,497,4,0,
281,16,500,1,51,1
281,16,505,1,19,1
281,16,574,1,11,1
281,16,577,1,23,1
281,16,590,4,0,
281,16,605,4,0,
281,17,45,1,1,1
281,17,85,4,0,
281,17,86,4,0,
281,17,92,4,0,
281,17,93,1,1,2
281,17,93,1,4,
281,17,94,1,30,
281,17,94,4,0,
281,17,95,1,44,
281,17,100,1,1,4
281,17,100,1,9,
281,17,104,1,1,3
281,17,104,1,6,
281,17,104,4,0,
281,17,113,4,0,
281,17,115,4,0,
281,17,138,1,47,
281,17,138,4,0,
281,17,156,4,0,
281,17,164,4,0,
281,17,168,4,0,
281,17,182,4,0,
281,17,204,1,40,
281,17,207,4,0,
281,17,213,4,0,
281,17,214,4,0,
281,17,216,4,0,
281,17,218,4,0,
281,17,219,4,0,
281,17,237,4,0,
281,17,240,4,0,
281,17,241,4,0,
281,17,244,4,0,
281,17,247,4,0,
281,17,248,1,37,
281,17,259,4,0,
281,17,261,4,0,
281,17,263,4,0,
281,17,269,4,0,
281,17,286,1,33,
281,17,345,1,17,
281,17,347,1,26,
281,17,347,4,0,
281,17,374,4,0,
281,17,381,1,14,
281,17,433,4,0,
281,17,447,4,0,
281,17,451,4,0,
281,17,473,4,0,
281,17,496,4,0,
281,17,497,4,0,
281,17,500,1,51,
281,17,505,1,19,
281,17,574,1,11,
281,17,577,1,23,
281,17,590,4,0,
281,17,605,4,0,
281,18,45,1,1,1
281,18,85,4,0,
281,18,86,4,0,
281,18,92,4,0,
281,18,93,1,1,2
281,18,93,1,4,
281,18,94,1,30,
281,18,94,4,0,
281,18,95,1,44,
281,18,100,1,1,4
281,18,100,1,9,
281,18,104,1,1,3
281,18,104,1,6,
281,18,104,4,0,
281,18,113,4,0,
281,18,115,4,0,
281,18,138,1,47,
281,18,138,4,0,
281,18,156,4,0,
281,18,164,4,0,
281,18,168,4,0,
281,18,182,4,0,
281,18,204,1,40,
281,18,207,4,0,
281,18,213,4,0,
281,18,214,4,0,
281,18,216,4,0,
281,18,218,4,0,
281,18,219,4,0,
281,18,237,4,0,
281,18,240,4,0,
281,18,241,4,0,
281,18,244,4,0,
281,18,247,4,0,
281,18,248,1,37,
281,18,259,4,0,
281,18,261,4,0,
281,18,263,4,0,
281,18,269,4,0,
281,18,286,1,33,
281,18,345,1,17,
281,18,347,1,26,
281,18,347,4,0,
281,18,374,4,0,
281,18,381,1,14,
281,18,433,4,0,
281,18,447,4,0,
281,18,451,4,0,
281,18,473,4,0,
281,18,496,4,0,
281,18,497,4,0,
281,18,500,1,51,
281,18,505,1,19,
281,18,574,1,11,
281,18,577,1,23,
281,18,590,4,0,
281,18,605,4,0,
282,5,45,1,1,1
282,5,63,4,0,
282,5,85,4,0,
282,5,92,4,0,
282,5,93,1,1,2
282,5,93,1,6,
282,5,94,1,26,
282,5,94,4,0,
282,5,95,1,51,
282,5,100,1,1,4
282,5,100,1,16,
282,5,104,1,1,3
282,5,104,1,11,
282,5,104,4,0,
282,5,113,4,0,
282,5,115,4,0,
282,5,138,1,60,
282,5,148,4,0,
282,5,156,4,0,
282,5,168,4,0,
282,5,182,4,0,
282,5,213,4,0,
282,5,216,4,0,
282,5,218,4,0,
282,5,219,4,0,
282,5,237,4,0,
282,5,240,4,0,
282,5,241,4,0,
282,5,247,4,0,
282,5,248,1,42,
282,5,259,4,0,
282,5,263,4,0,
282,5,269,4,0,
282,5,285,4,0,
282,5,286,1,33,
282,5,289,4,0,
282,5,290,4,0,
282,5,347,1,21,
282,5,347,4,0,
282,5,351,4,0,
282,6,7,3,0,
282,6,8,3,0,
282,6,9,3,0,
282,6,34,3,0,
282,6,38,3,0,
282,6,45,1,1,1
282,6,63,4,0,
282,6,85,4,0,
282,6,86,3,0,
282,6,92,4,0,
282,6,93,1,1,2
282,6,93,1,6,
282,6,94,1,26,
282,6,94,4,0,
282,6,95,1,51,
282,6,100,1,1,4
282,6,100,1,16,
282,6,102,3,0,
282,6,104,1,1,3
282,6,104,1,11,
282,6,104,4,0,
282,6,111,3,0,
282,6,113,4,0,
282,6,115,4,0,
282,6,138,1,60,
282,6,138,3,0,
282,6,148,4,0,
282,6,156,4,0,
282,6,164,3,0,
282,6,168,4,0,
282,6,173,3,0,
282,6,182,4,0,
282,6,189,3,0,
282,6,196,3,0,
282,6,203,3,0,
282,6,207,3,0,
282,6,213,4,0,
282,6,214,3,0,
282,6,216,4,0,
282,6,218,4,0,
282,6,219,4,0,
282,6,237,4,0,
282,6,240,4,0,
282,6,241,4,0,
282,6,244,3,0,
282,6,247,4,0,
282,6,248,1,42,
282,6,259,4,0,
282,6,263,4,0,
282,6,269,4,0,
282,6,285,4,0,
282,6,286,1,33,
282,6,289,4,0,
282,6,290,4,0,
282,6,347,1,21,
282,6,347,4,0,
282,6,351,4,0,
282,7,34,3,0,
282,7,38,3,0,
282,7,45,1,1,1
282,7,63,4,0,
282,7,85,4,0,
282,7,86,3,0,
282,7,92,4,0,
282,7,93,1,1,2
282,7,93,1,6,
282,7,94,1,26,
282,7,94,4,0,
282,7,95,1,51,
282,7,100,1,1,4
282,7,100,1,16,
282,7,102,3,0,
282,7,104,1,1,3
282,7,104,1,11,
282,7,104,4,0,
282,7,113,4,0,
282,7,115,4,0,
282,7,138,1,60,
282,7,138,3,0,
282,7,148,4,0,
282,7,156,4,0,
282,7,164,3,0,
282,7,168,4,0,
282,7,182,4,0,
282,7,213,4,0,
282,7,216,4,0,
282,7,218,4,0,
282,7,219,4,0,
282,7,237,4,0,
282,7,240,4,0,
282,7,241,4,0,
282,7,247,4,0,
282,7,248,1,42,
282,7,259,4,0,
282,7,263,4,0,
282,7,269,4,0,
282,7,285,4,0,
282,7,286,1,33,
282,7,289,4,0,
282,7,290,4,0,
282,7,347,1,21,
282,7,347,4,0,
282,7,351,4,0,
282,8,45,1,1,2
282,8,63,4,0,
282,8,85,4,0,
282,8,86,4,0,
282,8,92,4,0,
282,8,93,1,1,3
282,8,93,1,6,
282,8,94,1,33,
282,8,94,4,0,
282,8,95,1,60,
282,8,100,1,1,5
282,8,100,1,12,
282,8,104,1,1,4
282,8,104,1,10,
282,8,104,4,0,
282,8,113,4,0,
282,8,115,4,0,
282,8,138,1,65,
282,8,138,4,0,
282,8,148,4,0,
282,8,156,4,0,
282,8,164,4,0,
282,8,168,4,0,
282,8,182,4,0,
282,8,203,4,0,
282,8,207,4,0,
282,8,213,4,0,
282,8,214,4,0,
282,8,216,4,0,
282,8,218,4,0,
282,8,219,4,0,
282,8,237,4,0,
282,8,240,4,0,
282,8,241,4,0,
282,8,244,4,0,
282,8,247,4,0,
282,8,248,1,45,
282,8,259,4,0,
282,8,263,4,0,
282,8,269,4,0,
282,8,273,1,17,
282,8,278,4,0,
282,8,285,4,0,
282,8,286,1,40,
282,8,289,4,0,
282,8,290,4,0,
282,8,345,1,22,
282,8,347,1,25,
282,8,347,4,0,
282,8,351,4,0,
282,8,361,1,1,1
282,8,363,4,0,
282,8,374,4,0,
282,8,411,4,0,
282,8,412,4,0,
282,8,416,4,0,
282,8,433,4,0,
282,8,445,1,53,
282,8,445,4,0,
282,8,447,4,0,
282,8,451,4,0,
282,9,7,3,0,
282,9,8,3,0,
282,9,9,3,0,
282,9,45,1,1,2
282,9,63,4,0,
282,9,85,4,0,
282,9,86,4,0,
282,9,92,4,0,
282,9,93,1,1,3
282,9,93,1,6,
282,9,94,1,33,
282,9,94,4,0,
282,9,95,1,60,
282,9,100,1,1,5
282,9,100,1,12,
282,9,104,1,1,4
282,9,104,1,10,
282,9,104,4,0,
282,9,113,4,0,
282,9,115,4,0,
282,9,129,3,0,
282,9,138,1,65,
282,9,138,4,0,
282,9,148,4,0,
282,9,156,4,0,
282,9,164,4,0,
282,9,168,4,0,
282,9,173,3,0,
282,9,182,4,0,
282,9,189,3,0,
282,9,196,3,0,
282,9,203,4,0,
282,9,207,4,0,
282,9,213,4,0,
282,9,214,4,0,
282,9,216,4,0,
282,9,218,4,0,
282,9,219,4,0,
282,9,237,4,0,
282,9,240,4,0,
282,9,241,4,0,
282,9,244,4,0,
282,9,247,4,0,
282,9,248,1,45,
282,9,259,4,0,
282,9,263,4,0,
282,9,269,4,0,
282,9,270,3,0,
282,9,271,3,0,
282,9,273,1,17,
282,9,278,4,0,
282,9,285,4,0,
282,9,286,1,40,
282,9,289,4,0,
282,9,290,4,0,
282,9,324,3,0,
282,9,345,1,22,
282,9,347,1,25,
282,9,347,4,0,
282,9,351,4,0,
282,9,361,1,1,1
282,9,363,4,0,
282,9,374,4,0,
282,9,411,4,0,
282,9,412,4,0,
282,9,416,4,0,
282,9,428,3,0,
282,9,433,4,0,
282,9,445,1,53,
282,9,445,4,0,
282,9,447,4,0,
282,9,451,4,0,
282,10,7,3,0,
282,10,8,3,0,
282,10,9,3,0,
282,10,29,3,0,
282,10,45,1,1,2
282,10,63,4,0,
282,10,85,4,0,
282,10,86,4,0,
282,10,92,4,0,
282,10,93,1,1,3
282,10,93,1,6,
282,10,94,1,33,
282,10,94,4,0,
282,10,95,1,60,
282,10,100,1,1,5
282,10,100,1,12,
282,10,104,1,1,4
282,10,104,1,10,
282,10,104,4,0,
282,10,113,4,0,
282,10,115,4,0,
282,10,129,3,0,
282,10,138,1,65,
282,10,138,4,0,
282,10,148,4,0,
282,10,156,4,0,
282,10,164,4,0,
282,10,168,4,0,
282,10,173,3,0,
282,10,182,4,0,
282,10,189,3,0,
282,10,196,3,0,
282,10,203,4,0,
282,10,207,4,0,
282,10,213,4,0,
282,10,214,4,0,
282,10,215,3,0,
282,10,216,4,0,
282,10,218,4,0,
282,10,219,4,0,
282,10,220,3,0,
282,10,237,4,0,
282,10,240,4,0,
282,10,241,4,0,
282,10,244,4,0,
282,10,247,4,0,
282,10,248,1,45,
282,10,259,4,0,
282,10,263,4,0,
282,10,269,4,0,
282,10,270,3,0,
282,10,271,3,0,
282,10,273,1,17,
282,10,277,3,0,
282,10,278,4,0,
282,10,285,4,0,
282,10,286,1,40,
282,10,289,4,0,
282,10,290,4,0,
282,10,324,3,0,
282,10,345,1,22,
282,10,347,1,25,
282,10,347,4,0,
282,10,351,4,0,
282,10,361,1,1,1
282,10,363,4,0,
282,10,374,4,0,
282,10,411,4,0,
282,10,412,4,0,
282,10,416,4,0,
282,10,428,3,0,
282,10,433,4,0,
282,10,445,1,53,
282,10,445,4,0,
282,10,447,4,0,
282,10,451,4,0,
282,11,45,1,1,2
282,11,63,4,0,
282,11,85,4,0,
282,11,86,4,0,
282,11,92,4,0,
282,11,93,1,1,3
282,11,93,1,6,
282,11,94,1,40,
282,11,94,4,0,
282,11,95,1,65,
282,11,100,1,1,5
282,11,100,1,12,
282,11,104,1,1,4
282,11,104,1,10,
282,11,104,4,0,
282,11,113,4,0,
282,11,115,4,0,
282,11,138,1,73,
282,11,138,4,0,
282,11,148,4,0,
282,11,156,4,0,
282,11,164,4,0,
282,11,168,4,0,
282,11,182,4,0,
282,11,207,4,0,
282,11,213,4,0,
282,11,216,4,0,
282,11,218,4,0,
282,11,219,4,0,
282,11,237,4,0,
282,11,240,4,0,
282,11,241,4,0,
282,11,244,4,0,
282,11,247,4,0,
282,11,248,1,53,
282,11,259,4,0,
282,11,261,4,0,
282,11,263,4,0,
282,11,269,4,0,
282,11,273,1,17,
282,11,286,1,45,
282,11,345,1,22,
282,11,347,1,33,
282,11,347,4,0,
282,11,361,1,1,1
282,11,374,4,0,
282,11,411,4,0,
282,11,412,4,0,
282,11,416,4,0,
282,11,433,4,0,
282,11,445,1,60,
282,11,447,4,0,
282,11,451,4,0,
282,11,473,4,0,
282,11,477,4,0,
282,11,496,4,0,
282,11,497,4,0,
282,11,500,1,80,
282,11,502,4,0,
282,11,505,1,25,
282,12,45,1,1,1
282,12,63,4,0,
282,12,85,4,0,
282,12,92,4,0,
282,12,93,1,1,2
282,12,93,1,6,
282,12,94,1,26,
282,12,94,4,0,
282,12,95,1,51,
282,12,100,1,1,4
282,12,100,1,16,
282,12,104,1,1,3
282,12,104,1,11,
282,12,104,4,0,
282,12,113,4,0,
282,12,115,4,0,
282,12,138,1,60,
282,12,148,4,0,
282,12,156,4,0,
282,12,168,4,0,
282,12,182,4,0,
282,12,213,4,0,
282,12,216,4,0,
282,12,218,4,0,
282,12,219,4,0,
282,12,237,4,0,
282,12,240,4,0,
282,12,241,4,0,
282,12,247,4,0,
282,12,248,1,42,
282,12,259,4,0,
282,12,263,4,0,
282,12,269,4,0,
282,12,285,4,0,
282,12,286,1,33,
282,12,289,4,0,
282,12,290,4,0,
282,12,347,1,21,
282,12,347,4,0,
282,12,351,4,0,
282,13,34,3,0,
282,13,38,3,0,
282,13,45,1,1,1
282,13,63,4,0,
282,13,85,4,0,
282,13,86,3,0,
282,13,92,4,0,
282,13,93,1,1,2
282,13,93,1,6,
282,13,94,1,26,
282,13,94,4,0,
282,13,95,1,51,
282,13,100,1,1,4
282,13,100,1,16,
282,13,102,3,0,
282,13,104,1,1,3
282,13,104,1,11,
282,13,104,4,0,
282,13,113,4,0,
282,13,115,4,0,
282,13,138,1,60,
282,13,138,3,0,
282,13,148,4,0,
282,13,156,4,0,
282,13,164,3,0,
282,13,168,4,0,
282,13,171,3,0,
282,13,182,4,0,
282,13,196,3,0,
282,13,207,3,0,
282,13,213,4,0,
282,13,216,4,0,
282,13,218,4,0,
282,13,219,4,0,
282,13,237,4,0,
282,13,240,4,0,
282,13,241,4,0,
282,13,247,4,0,
282,13,248,1,42,
282,13,259,4,0,
282,13,263,4,0,
282,13,269,4,0,
282,13,285,4,0,
282,13,286,1,33,
282,13,289,4,0,
282,13,290,4,0,
282,13,347,1,21,
282,13,347,4,0,
282,13,351,4,0,
282,14,7,3,0,
282,14,8,3,0,
282,14,9,3,0,
282,14,45,1,1,2
282,14,63,4,0,
282,14,85,4,0,
282,14,86,4,0,
282,14,92,4,0,
282,14,93,1,1,3
282,14,93,1,6,
282,14,94,1,40,
282,14,94,4,0,
282,14,95,1,65,
282,14,100,1,1,5
282,14,100,1,12,
282,14,104,1,1,4
282,14,104,1,10,
282,14,104,4,0,
282,14,113,4,0,
282,14,115,4,0,
282,14,138,1,73,
282,14,138,4,0,
282,14,148,4,0,
282,14,156,4,0,
282,14,164,4,0,
282,14,168,4,0,
282,14,173,3,0,
282,14,182,4,0,
282,14,196,3,0,
282,14,207,4,0,
282,14,213,4,0,
282,14,214,3,0,
282,14,215,3,0,
282,14,216,4,0,
282,14,218,4,0,
282,14,219,4,0,
282,14,220,3,0,
282,14,237,4,0,
282,14,240,4,0,
282,14,241,4,0,
282,14,244,4,0,
282,14,247,4,0,
282,14,248,1,53,
282,14,259,4,0,
282,14,261,4,0,
282,14,263,4,0,
282,14,269,4,0,
282,14,270,3,0,
282,14,271,3,0,
282,14,273,1,17,
282,14,277,3,0,
282,14,278,3,0,
282,14,285,3,0,
282,14,286,1,45,
282,14,289,3,0,
282,14,304,3,0,
282,14,324,3,0,
282,14,345,1,22,
282,14,347,1,33,
282,14,347,4,0,
282,14,361,1,1,1
282,14,374,4,0,
282,14,411,4,0,
282,14,412,4,0,
282,14,416,4,0,
282,14,428,3,0,
282,14,433,4,0,
282,14,445,1,60,
282,14,447,4,0,
282,14,451,4,0,
282,14,472,3,0,
282,14,473,4,0,
282,14,477,4,0,
282,14,478,3,0,
282,14,496,4,0,
282,14,497,4,0,
282,14,500,1,80,
282,14,502,4,0,
282,14,505,1,25,
282,15,45,1,1,5
282,15,63,4,0,
282,15,85,4,0,
282,15,86,4,0,
282,15,92,4,0,
282,15,93,1,1,6
282,15,93,1,6,
282,15,94,1,40,
282,15,94,4,0,
282,15,95,1,65,
282,15,100,1,1,8
282,15,100,1,12,
282,15,104,1,1,7
282,15,104,1,10,
282,15,104,4,0,
282,15,113,4,0,
282,15,115,4,0,
282,15,138,1,73,
282,15,138,4,0,
282,15,148,4,0,
282,15,156,4,0,
282,15,164,4,0,
282,15,168,4,0,
282,15,182,4,0,
282,15,207,4,0,
282,15,213,4,0,
282,15,214,4,0,
282,15,216,4,0,
282,15,218,4,0,
282,15,219,4,0,
282,15,237,4,0,
282,15,240,4,0,
282,15,241,4,0,
282,15,244,4,0,
282,15,247,4,0,
282,15,248,1,53,
282,15,259,4,0,
282,15,261,4,0,
282,15,263,4,0,
282,15,269,4,0,
282,15,273,1,17,
282,15,286,1,45,
282,15,345,1,22,
282,15,347,1,33,
282,15,347,4,0,
282,15,361,1,1,4
282,15,374,4,0,
282,15,411,4,0,
282,15,412,4,0,
282,15,416,4,0,
282,15,433,4,0,
282,15,445,1,60,
282,15,447,4,0,
282,15,451,4,0,
282,15,473,4,0,
282,15,496,4,0,
282,15,497,4,0,
282,15,500,1,1,2
282,15,500,1,80,
282,15,505,1,25,
282,15,581,1,1,3
282,15,585,1,1,1
282,15,585,1,85,
282,15,590,4,0,
282,15,605,4,0,
282,16,7,3,0,
282,16,8,3,0,
282,16,9,3,0,
282,16,45,1,1,5
282,16,63,4,0,
282,16,85,4,0,
282,16,86,4,0,
282,16,92,4,0,
282,16,93,1,1,6
282,16,93,1,4,1
282,16,94,1,31,1
282,16,94,4,0,
282,16,95,1,49,1
282,16,100,1,1,8
282,16,100,1,9,1
282,16,104,1,1,7
282,16,104,1,6,1
282,16,104,4,0,
282,16,113,4,0,
282,16,115,4,0,
282,16,138,1,53,1
282,16,138,4,0,
282,16,148,4,0,
282,16,156,4,0,
282,16,164,4,0,
282,16,168,4,0,
282,16,173,3,0,
282,16,182,4,0,
282,16,196,3,0,
282,16,207,4,0,
282,16,213,4,0,
282,16,214,4,0,
282,16,215,3,0,
282,16,216,4,0,
282,16,218,4,0,
282,16,219,4,0,
282,16,220,3,0,
282,16,237,4,0,
282,16,240,4,0,
282,16,241,4,0,
282,16,244,4,0,
282,16,247,4,0,
282,16,248,1,40,1
282,16,259,4,0,
282,16,261,4,0,
282,16,263,4,0,
282,16,269,4,0,
282,16,270,3,0,
282,16,271,3,0,
282,16,273,1,14,1
282,16,277,3,0,
282,16,278,3,0,
282,16,285,3,0,
282,16,286,1,35,1
282,16,289,3,0,
282,16,290,4,0,
282,16,304,3,0,
282,16,324,3,0,
282,16,345,1,17,1
282,16,347,1,26,1
282,16,347,4,0,
282,16,351,3,0,
282,16,361,1,1,4
282,16,374,4,0,
282,16,411,4,0,
282,16,412,4,0,
282,16,416,4,0,
282,16,428,3,0,
282,16,433,4,0,
282,16,445,1,44,1
282,16,447,4,0,
282,16,451,4,0,
282,16,472,3,0,
282,16,473,4,0,
282,16,478,3,0,
282,16,496,4,0,
282,16,497,4,0,
282,16,500,1,1,2
282,16,500,1,58,1
282,16,505,1,19,1
282,16,574,1,11,1
282,16,577,1,23,1
282,16,581,1,1,3
282,16,585,1,1,1
282,16,585,1,62,1
282,16,590,4,0,
282,16,605,4,0,
282,17,45,1,1,5
282,17,63,4,0,
282,17,85,4,0,
282,17,86,4,0,
282,17,92,4,0,
282,17,93,1,1,6
282,17,93,1,4,
282,17,94,1,31,
282,17,94,4,0,
282,17,95,1,49,
282,17,100,1,1,8
282,17,100,1,9,
282,17,104,1,1,7
282,17,104,1,6,
282,17,104,4,0,
282,17,113,4,0,
282,17,115,4,0,
282,17,138,1,53,
282,17,138,4,0,
282,17,156,4,0,
282,17,164,4,0,
282,17,168,4,0,
282,17,182,4,0,
282,17,207,4,0,
282,17,213,4,0,
282,17,214,4,0,
282,17,216,4,0,
282,17,218,4,0,
282,17,219,4,0,
282,17,237,4,0,
282,17,240,4,0,
282,17,241,4,0,
282,17,244,4,0,
282,17,247,4,0,
282,17,248,1,40,
282,17,259,4,0,
282,17,261,4,0,
282,17,263,4,0,
282,17,269,4,0,
282,17,273,1,14,
282,17,286,1,35,
282,17,345,1,17,
282,17,347,1,26,
282,17,347,4,0,
282,17,361,1,1,4
282,17,374,4,0,
282,17,411,4,0,
282,17,412,4,0,
282,17,416,4,0,
282,17,433,4,0,
282,17,445,1,44,
282,17,447,4,0,
282,17,451,4,0,
282,17,473,4,0,
282,17,496,4,0,
282,17,497,4,0,
282,17,500,1,1,2
282,17,500,1,58,
282,17,505,1,19,
282,17,574,1,11,
282,17,577,1,23,
282,17,581,1,1,3
282,17,585,1,1,1
282,17,585,1,62,
282,17,590,4,0,
282,17,605,4,0,
282,18,45,1,1,5
282,18,63,4,0,
282,18,85,4,0,
282,18,86,4,0,
282,18,92,4,0,
282,18,93,1,1,6
282,18,93,1,4,
282,18,94,1,31,
282,18,94,4,0,
282,18,95,1,49,
282,18,100,1,1,8
282,18,100,1,9,
282,18,104,1,1,7
282,18,104,1,6,
282,18,104,4,0,
282,18,113,4,0,
282,18,115,4,0,
282,18,138,1,53,
282,18,138,4,0,
282,18,156,4,0,
282,18,164,4,0,
282,18,168,4,0,
282,18,182,4,0,
282,18,207,4,0,
282,18,213,4,0,
282,18,214,4,0,
282,18,216,4,0,
282,18,218,4,0,
282,18,219,4,0,
282,18,237,4,0,
282,18,240,4,0,
282,18,241,4,0,
282,18,244,4,0,
282,18,247,4,0,
282,18,248,1,40,
282,18,259,4,0,
282,18,261,4,0,
282,18,263,4,0,
282,18,269,4,0,
282,18,273,1,14,
282,18,286,1,35,
282,18,345,1,17,
282,18,347,1,26,
282,18,347,4,0,
282,18,361,1,1,4
282,18,374,4,0,
282,18,411,4,0,
282,18,412,4,0,
282,18,416,4,0,
282,18,433,4,0,
282,18,445,1,44,
282,18,447,4,0,
282,18,451,4,0,
282,18,473,4,0,
282,18,496,4,0,
282,18,497,4,0,
282,18,500,1,1,2
282,18,500,1,58,
282,18,505,1,19,
282,18,574,1,11,
282,18,577,1,23,
282,18,581,1,1,3
282,18,585,1,1,1
282,18,585,1,62,
282,18,590,4,0,
282,18,605,4,0,
283,5,54,1,37,1
283,5,56,2,0,
283,5,58,4,0,
283,5,59,4,0,
283,5,60,2,0,
283,5,61,1,25,
283,5,76,4,0,
283,5,92,4,0,
283,5,97,1,31,
283,5,98,1,7,
283,5,104,4,0,
283,5,114,1,37,2
283,5,145,1,1,
283,5,148,4,0,
283,5,156,4,0,
283,5,168,4,0,
283,5,170,2,0,
283,5,182,4,0,
283,5,193,2,0,
283,5,202,4,0,
283,5,213,4,0,
283,5,216,4,0,
283,5,218,4,0,
283,5,230,1,13,
283,5,237,4,0,
283,5,240,4,0,
283,5,241,4,0,
283,5,247,4,0,
283,5,263,4,0,
283,5,290,4,0,
283,5,341,2,0,
283,5,346,1,19,
283,5,352,4,0,
283,6,38,3,0,
283,6,54,1,37,1
283,6,56,2,0,
283,6,58,4,0,
283,6,59,4,0,
283,6,60,2,0,
283,6,61,1,25,
283,6,76,4,0,
283,6,92,4,0,
283,6,97,1,31,
283,6,98,1,7,
283,6,102,3,0,
283,6,104,4,0,
283,6,114,1,37,2
283,6,129,3,0,
283,6,145,1,1,
283,6,148,4,0,
283,6,156,4,0,
283,6,164,3,0,
283,6,168,4,0,
283,6,170,2,0,
283,6,173,3,0,
283,6,182,4,0,
283,6,193,2,0,
283,6,196,3,0,
283,6,202,4,0,
283,6,203,3,0,
283,6,207,3,0,
283,6,213,4,0,
283,6,214,3,0,
283,6,216,4,0,
283,6,218,4,0,
283,6,230,1,13,
283,6,237,4,0,
283,6,240,4,0,
283,6,241,4,0,
283,6,244,3,0,
283,6,247,4,0,
283,6,263,4,0,
283,6,290,4,0,
283,6,341,2,0,
283,6,346,1,19,
283,6,352,4,0,
283,7,38,3,0,
283,7,54,1,37,1
283,7,56,2,0,
283,7,58,4,0,
283,7,59,4,0,
283,7,60,2,0,
283,7,61,1,25,
283,7,76,4,0,
283,7,92,4,0,
283,7,97,1,31,
283,7,98,1,7,
283,7,102,3,0,
283,7,104,4,0,
283,7,114,1,37,2
283,7,145,1,1,
283,7,148,4,0,
283,7,156,4,0,
283,7,164,3,0,
283,7,168,4,0,
283,7,170,2,0,
283,7,182,4,0,
283,7,193,2,0,
283,7,202,4,0,
283,7,213,4,0,
283,7,216,4,0,
283,7,218,4,0,
283,7,230,1,13,
283,7,237,4,0,
283,7,240,4,0,
283,7,241,4,0,
283,7,247,4,0,
283,7,263,4,0,
283,7,290,4,0,
283,7,341,2,0,
283,7,346,1,19,
283,7,352,4,0,
283,8,54,1,37,1
283,8,56,2,0,
283,8,58,4,0,
283,8,59,4,0,
283,8,60,2,0,
283,8,61,1,25,
283,8,76,4,0,
283,8,92,4,0,
283,8,97,1,31,
283,8,98,1,7,
283,8,104,4,0,
283,8,114,1,37,2
283,8,145,1,1,
283,8,148,4,0,
283,8,156,4,0,
283,8,164,4,0,
283,8,168,4,0,
283,8,170,2,0,
283,8,182,4,0,
283,8,193,2,0,
283,8,202,4,0,
283,8,203,4,0,
283,8,207,4,0,
283,8,213,4,0,
283,8,214,4,0,
283,8,216,4,0,
283,8,218,4,0,
283,8,226,1,43,
283,8,230,1,13,
283,8,237,4,0,
283,8,240,4,0,
283,8,241,4,0,
283,8,244,4,0,
283,8,247,4,0,
283,8,263,4,0,
283,8,290,4,0,
283,8,324,2,0,
283,8,341,2,0,
283,8,346,1,19,
283,8,352,4,0,
283,8,363,4,0,
283,8,445,4,0,
283,8,450,2,0,
283,9,54,1,37,1
283,9,56,2,0,
283,9,58,4,0,
283,9,59,4,0,
283,9,60,2,0,
283,9,61,1,25,
283,9,76,4,0,
283,9,92,4,0,
283,9,97,1,31,
283,9,98,1,7,
283,9,104,4,0,
283,9,114,1,37,2
283,9,129,3,0,
283,9,145,1,1,
283,9,148,4,0,
283,9,156,4,0,
283,9,164,4,0,
283,9,168,4,0,
283,9,170,2,0,
283,9,173,3,0,
283,9,182,4,0,
283,9,189,3,0,
283,9,193,2,0,
283,9,196,3,0,
283,9,202,4,0,
283,9,203,4,0,
283,9,207,4,0,
283,9,213,4,0,
283,9,214,4,0,
283,9,216,4,0,
283,9,218,4,0,
283,9,226,1,43,
283,9,230,1,13,
283,9,237,4,0,
283,9,240,4,0,
283,9,241,4,0,
283,9,244,4,0,
283,9,247,4,0,
283,9,263,4,0,
283,9,290,4,0,
283,9,324,2,0,
283,9,324,3,0,
283,9,341,2,0,
283,9,346,1,19,
283,9,352,4,0,
283,9,363,4,0,
283,9,445,4,0,
283,9,450,2,0,
283,10,54,1,37,1
283,10,56,2,0,
283,10,58,4,0,
283,10,59,4,0,
283,10,60,2,0,
283,10,61,1,25,
283,10,76,4,0,
283,10,81,3,0,
283,10,92,4,0,
283,10,97,1,31,
283,10,98,1,7,
283,10,104,4,0,
283,10,114,1,37,2
283,10,129,3,0,
283,10,145,1,1,
283,10,148,4,0,
283,10,156,4,0,
283,10,164,4,0,
283,10,168,4,0,
283,10,170,2,0,
283,10,173,3,0,
283,10,182,4,0,
283,10,189,3,0,
283,10,193,2,0,
283,10,196,3,0,
283,10,202,4,0,
283,10,203,4,0,
283,10,207,4,0,
283,10,213,4,0,
283,10,214,4,0,
283,10,216,4,0,
283,10,218,4,0,
283,10,226,1,43,
283,10,230,1,13,
283,10,237,4,0,
283,10,240,4,0,
283,10,241,4,0,
283,10,244,4,0,
283,10,247,4,0,
283,10,263,4,0,
283,10,290,4,0,
283,10,324,2,0,
283,10,324,3,0,
283,10,341,2,0,
283,10,346,1,19,
283,10,352,4,0,
283,10,363,4,0,
283,10,445,4,0,
283,10,450,2,0,
283,10,450,3,0,
283,11,54,1,37,1
283,11,56,2,0,
283,11,58,4,0,
283,11,59,4,0,
283,11,60,2,0,
283,11,61,1,25,
283,11,76,4,0,
283,11,92,4,0,
283,11,97,1,31,
283,11,98,1,7,
283,11,104,4,0,
283,11,114,1,37,2
283,11,145,1,1,
283,11,148,4,0,
283,11,156,4,0,
283,11,164,4,0,
283,11,168,4,0,
283,11,170,2,0,
283,11,182,4,0,
283,11,193,2,0,
283,11,203,2,0,
283,11,207,4,0,
283,11,213,4,0,
283,11,216,4,0,
283,11,218,4,0,
283,11,226,1,43,
283,11,230,1,13,
283,11,237,4,0,
283,11,240,4,0,
283,11,241,4,0,
283,11,244,4,0,
283,11,247,4,0,
283,11,263,4,0,
283,11,324,2,0,
283,11,341,2,0,
283,11,346,1,19,
283,11,450,2,0,
283,11,453,2,0,
283,11,496,4,0,
283,11,503,4,0,
283,11,522,4,0,
283,12,54,1,37,1
283,12,58,4,0,
283,12,59,4,0,
283,12,61,1,25,
283,12,76,4,0,
283,12,92,4,0,
283,12,97,1,31,
283,12,98,1,7,
283,12,104,4,0,
283,12,114,1,37,2
283,12,145,1,1,
283,12,148,4,0,
283,12,156,4,0,
283,12,168,4,0,
283,12,182,4,0,
283,12,202,4,0,
283,12,213,4,0,
283,12,216,4,0,
283,12,218,4,0,
283,12,230,1,13,
283,12,237,4,0,
283,12,240,4,0,
283,12,241,4,0,
283,12,247,4,0,
283,12,263,4,0,
283,12,290,4,0,
283,12,346,1,19,
283,12,352,4,0,
283,13,38,3,0,
283,13,54,1,37,1
283,13,58,4,0,
283,13,59,4,0,
283,13,61,1,25,
283,13,76,4,0,
283,13,92,4,0,
283,13,97,1,31,
283,13,98,1,7,
283,13,102,3,0,
283,13,104,4,0,
283,13,114,1,37,2
283,13,145,1,1,
283,13,148,4,0,
283,13,156,4,0,
283,13,164,3,0,
283,13,168,4,0,
283,13,182,4,0,
283,13,196,3,0,
283,13,202,4,0,
283,13,207,3,0,
283,13,213,4,0,
283,13,216,4,0,
283,13,218,4,0,
283,13,230,1,13,
283,13,237,4,0,
283,13,240,4,0,
283,13,241,4,0,
283,13,247,4,0,
283,13,263,4,0,
283,13,290,4,0,
283,13,346,1,19,
283,13,352,4,0,
283,14,54,1,37,1
283,14,56,2,0,
283,14,58,4,0,
283,14,59,4,0,
283,14,60,2,0,
283,14,61,1,25,
283,14,76,4,0,
283,14,92,4,0,
283,14,97,1,31,
283,14,98,1,7,
283,14,104,4,0,
283,14,114,1,37,2
283,14,145,1,1,
283,14,148,4,0,
283,14,156,4,0,
283,14,164,4,0,
283,14,168,4,0,
283,14,170,2,0,
283,14,173,3,0,
283,14,182,4,0,
283,14,193,2,0,
283,14,196,3,0,
283,14,202,3,0,
283,14,203,2,0,
283,14,207,4,0,
283,14,213,4,0,
283,14,214,3,0,
283,14,216,4,0,
283,14,218,4,0,
283,14,226,1,43,
283,14,230,1,13,
283,14,237,4,0,
283,14,240,4,0,
283,14,241,4,0,
283,14,244,4,0,
283,14,247,4,0,
283,14,263,4,0,
283,14,324,2,0,
283,14,324,3,0,
283,14,341,2,0,
283,14,346,1,19,
283,14,450,2,0,
283,14,450,3,0,
283,14,453,2,0,
283,14,496,4,0,
283,14,503,4,0,
283,14,522,4,0,
283,15,54,1,37,1
283,15,56,2,0,
283,15,58,4,0,
283,15,59,4,0,
283,15,60,2,0,
283,15,61,1,25,
283,15,76,4,0,
283,15,92,4,0,
283,15,97,1,31,
283,15,98,1,7,
283,15,104,4,0,
283,15,114,1,37,2
283,15,145,1,1,
283,15,148,4,0,
283,15,156,4,0,
283,15,164,4,0,
283,15,168,4,0,
283,15,170,2,0,
283,15,182,4,0,
283,15,193,2,0,
283,15,203,2,0,
283,15,207,4,0,
283,15,213,4,0,
283,15,214,4,0,
283,15,216,4,0,
283,15,218,4,0,
283,15,226,1,43,
283,15,230,1,13,
283,15,237,4,0,
283,15,240,4,0,
283,15,241,4,0,
283,15,244,4,0,
283,15,247,4,0,
283,15,263,4,0,
283,15,324,2,0,
283,15,341,2,0,
283,15,346,1,19,
283,15,450,2,0,
283,15,453,2,0,
283,15,471,2,0,
283,15,496,4,0,
283,15,503,4,0,
283,15,522,4,0,
283,15,564,1,46,
283,15,565,2,0,
283,15,590,4,0,
283,15,611,4,0,
283,16,54,1,25,1
283,16,56,2,0,
283,16,58,4,0,
283,16,59,4,0,
283,16,60,2,0,
283,16,61,1,17,1
283,16,76,4,0,
283,16,92,4,0,
283,16,97,1,22,1
283,16,98,1,6,1
283,16,104,4,0,
283,16,114,1,25,2
283,16,145,1,1,1
283,16,148,4,0,
283,16,156,4,0,
283,16,164,4,0,
283,16,168,4,0,
283,16,170,2,0,
283,16,173,3,0,
283,16,182,4,0,
283,16,193,2,0,
283,16,196,3,0,
283,16,202,3,0,
283,16,203,2,0,
283,16,207,4,0,
283,16,213,4,0,
283,16,214,4,0,
283,16,216,4,0,
283,16,218,4,0,
283,16,226,1,35,1
283,16,230,1,9,1
283,16,237,4,0,
283,16,240,4,0,
283,16,241,4,0,
283,16,244,4,0,
283,16,247,4,0,
283,16,263,4,0,
283,16,290,4,0,
283,16,324,2,0,
283,16,324,3,0,
283,16,341,2,0,
283,16,346,1,14,1
283,16,352,3,0,
283,16,450,2,0,
283,16,450,3,0,
283,16,453,1,30,1
283,16,453,2,0,
283,16,471,2,0,
283,16,496,4,0,
283,16,503,4,0,
283,16,522,4,0,
283,16,564,1,38,1
283,16,565,2,0,
283,16,590,4,0,
283,16,611,4,0,
283,17,54,1,25,1
283,17,56,2,0,
283,17,58,4,0,
283,17,59,4,0,
283,17,60,2,0,
283,17,61,1,17,
283,17,76,4,0,
283,17,92,4,0,
283,17,97,1,22,
283,17,98,1,6,
283,17,104,4,0,
283,17,114,1,25,2
283,17,145,1,1,
283,17,156,4,0,
283,17,164,4,0,
283,17,168,4,0,
283,17,170,2,0,
283,17,182,4,0,
283,17,193,2,0,
283,17,203,2,0,
283,17,207,4,0,
283,17,213,4,0,
283,17,214,4,0,
283,17,216,4,0,
283,17,218,4,0,
283,17,226,1,35,
283,17,230,1,9,
283,17,237,4,0,
283,17,240,4,0,
283,17,241,4,0,
283,17,244,4,0,
283,17,247,4,0,
283,17,263,4,0,
283,17,324,2,0,
283,17,341,2,0,
283,17,346,1,14,
283,17,450,2,0,
283,17,453,1,30,
283,17,453,2,0,
283,17,471,2,0,
283,17,496,4,0,
283,17,503,4,0,
283,17,564,1,38,
283,17,565,2,0,
283,17,590,4,0,
283,17,611,4,0,
283,17,679,2,0,
283,18,54,1,25,1
283,18,56,2,0,
283,18,58,4,0,
283,18,59,4,0,
283,18,60,2,0,
283,18,61,1,17,
283,18,76,4,0,
283,18,92,4,0,
283,18,97,1,22,
283,18,98,1,6,
283,18,104,4,0,
283,18,114,1,25,2
283,18,145,1,1,
283,18,156,4,0,
283,18,164,4,0,
283,18,168,4,0,
283,18,170,2,0,
283,18,182,4,0,
283,18,193,2,0,
283,18,203,2,0,
283,18,207,4,0,
283,18,213,4,0,
283,18,214,4,0,
283,18,216,4,0,
283,18,218,4,0,
283,18,226,1,35,
283,18,230,1,9,
283,18,237,4,0,
283,18,240,4,0,
283,18,241,4,0,
283,18,244,4,0,
283,18,247,4,0,
283,18,263,4,0,
283,18,324,2,0,
283,18,341,2,0,
283,18,346,1,14,
283,18,450,2,0,
283,18,453,1,30,
283,18,453,2,0,
283,18,471,2,0,
283,18,496,4,0,
283,18,503,4,0,
283,18,564,1,38,
283,18,565,2,0,
283,18,590,4,0,
283,18,611,4,0,
283,18,679,2,0,
284,5,16,1,26,
284,5,18,1,53,
284,5,58,4,0,
284,5,59,4,0,
284,5,63,4,0,
284,5,76,4,0,
284,5,78,1,40,
284,5,92,4,0,
284,5,98,1,1,2
284,5,98,1,7,
284,5,104,4,0,
284,5,145,1,1,1
284,5,148,4,0,
284,5,156,4,0,
284,5,168,4,0,
284,5,182,4,0,
284,5,184,1,33,
284,5,202,4,0,
284,5,213,4,0,
284,5,216,4,0,
284,5,218,4,0,
284,5,230,1,1,3
284,5,230,1,13,
284,5,237,4,0,
284,5,240,4,0,
284,5,241,4,0,
284,5,247,4,0,
284,5,263,4,0,
284,5,290,4,0,
284,5,318,1,47,
284,5,332,4,0,
284,5,346,1,1,4
284,5,346,1,19,
284,5,352,4,0,
284,6,16,1,26,
284,6,18,1,53,
284,6,38,3,0,
284,6,58,4,0,
284,6,59,4,0,
284,6,63,4,0,
284,6,76,4,0,
284,6,78,1,40,
284,6,92,4,0,
284,6,98,1,1,2
284,6,98,1,7,
284,6,102,3,0,
284,6,104,4,0,
284,6,129,3,0,
284,6,145,1,1,1
284,6,148,4,0,
284,6,156,4,0,
284,6,164,3,0,
284,6,168,4,0,
284,6,173,3,0,
284,6,182,4,0,
284,6,184,1,33,
284,6,196,3,0,
284,6,202,4,0,
284,6,203,3,0,
284,6,207,3,0,
284,6,213,4,0,
284,6,214,3,0,
284,6,216,4,0,
284,6,218,4,0,
284,6,230,1,1,3
284,6,230,1,13,
284,6,237,4,0,
284,6,240,4,0,
284,6,241,4,0,
284,6,244,3,0,
284,6,247,4,0,
284,6,263,4,0,
284,6,290,4,0,
284,6,318,1,47,
284,6,332,4,0,
284,6,346,1,1,4
284,6,346,1,19,
284,6,352,4,0,
284,7,16,1,26,
284,7,18,1,53,
284,7,38,3,0,
284,7,58,4,0,
284,7,59,4,0,
284,7,63,4,0,
284,7,76,4,0,
284,7,78,1,40,
284,7,92,4,0,
284,7,98,1,1,2
284,7,98,1,7,
284,7,102,3,0,
284,7,104,4,0,
284,7,145,1,1,1
284,7,148,4,0,
284,7,156,4,0,
284,7,164,3,0,
284,7,168,4,0,
284,7,182,4,0,
284,7,184,1,33,
284,7,202,4,0,
284,7,213,4,0,
284,7,216,4,0,
284,7,218,4,0,
284,7,230,1,1,3
284,7,230,1,13,
284,7,237,4,0,
284,7,240,4,0,
284,7,241,4,0,
284,7,247,4,0,
284,7,263,4,0,
284,7,290,4,0,
284,7,318,1,47,
284,7,332,4,0,
284,7,346,1,1,4
284,7,346,1,19,
284,7,352,4,0,
284,8,16,1,22,
284,8,18,1,54,
284,8,58,4,0,
284,8,59,4,0,
284,8,63,4,0,
284,8,76,4,0,
284,8,78,1,33,
284,8,92,4,0,
284,8,98,1,1,3
284,8,98,1,7,
284,8,104,4,0,
284,8,145,1,1,2
284,8,148,4,0,
284,8,156,4,0,
284,8,164,4,0,
284,8,168,4,0,
284,8,182,4,0,
284,8,184,1,26,
284,8,202,4,0,
284,8,203,4,0,
284,8,207,4,0,
284,8,213,4,0,
284,8,214,4,0,
284,8,216,4,0,
284,8,218,4,0,
284,8,230,1,1,4
284,8,230,1,13,
284,8,237,4,0,
284,8,240,4,0,
284,8,241,4,0,
284,8,244,4,0,
284,8,247,4,0,
284,8,263,4,0,
284,8,290,4,0,
284,8,318,1,40,
284,8,318,4,0,
284,8,332,4,0,
284,8,346,1,1,5
284,8,346,1,19,
284,8,352,4,0,
284,8,355,4,0,
284,8,363,4,0,
284,8,369,4,0,
284,8,403,1,47,
284,8,405,1,61,
284,8,412,4,0,
284,8,416,4,0,
284,8,432,4,0,
284,8,445,4,0,
284,8,466,1,1,1
284,9,16,1,22,
284,9,18,1,54,
284,9,58,4,0,
284,9,59,4,0,
284,9,63,4,0,
284,9,76,4,0,
284,9,78,1,33,
284,9,92,4,0,
284,9,98,1,1,3
284,9,98,1,7,
284,9,104,4,0,
284,9,129,3,0,
284,9,145,1,1,2
284,9,148,4,0,
284,9,156,4,0,
284,9,164,4,0,
284,9,168,4,0,
284,9,173,3,0,
284,9,182,4,0,
284,9,184,1,26,
284,9,189,3,0,
284,9,196,3,0,
284,9,202,4,0,
284,9,203,4,0,
284,9,207,4,0,
284,9,213,4,0,
284,9,214,4,0,
284,9,216,4,0,
284,9,218,4,0,
284,9,230,1,1,4
284,9,230,1,13,
284,9,237,4,0,
284,9,239,3,0,
284,9,240,4,0,
284,9,241,4,0,
284,9,244,4,0,
284,9,247,4,0,
284,9,263,4,0,
284,9,290,4,0,
284,9,314,3,0,
284,9,318,1,40,
284,9,318,4,0,
284,9,324,3,0,
284,9,332,4,0,
284,9,346,1,1,5
284,9,346,1,19,
284,9,352,4,0,
284,9,355,4,0,
284,9,363,4,0,
284,9,369,4,0,
284,9,403,1,47,
284,9,405,1,61,
284,9,412,4,0,
284,9,416,4,0,
284,9,432,4,0,
284,9,445,4,0,
284,9,466,1,1,1
284,9,466,3,0,
284,10,16,1,22,
284,10,18,1,54,
284,10,58,4,0,
284,10,59,4,0,
284,10,63,4,0,
284,10,76,4,0,
284,10,78,1,33,
284,10,81,3,0,
284,10,92,4,0,
284,10,98,1,1,3
284,10,98,1,7,
284,10,104,4,0,
284,10,129,3,0,
284,10,145,1,1,2
284,10,148,4,0,
284,10,156,4,0,
284,10,164,4,0,
284,10,168,4,0,
284,10,173,3,0,
284,10,182,4,0,
284,10,184,1,26,
284,10,189,3,0,
284,10,196,3,0,
284,10,202,4,0,
284,10,203,4,0,
284,10,207,4,0,
284,10,213,4,0,
284,10,214,4,0,
284,10,216,4,0,
284,10,218,4,0,
284,10,230,1,1,4
284,10,230,1,13,
284,10,237,4,0,
284,10,239,3,0,
284,10,240,4,0,
284,10,241,4,0,
284,10,244,4,0,
284,10,247,4,0,
284,10,263,4,0,
284,10,290,4,0,
284,10,314,3,0,
284,10,318,1,40,
284,10,318,4,0,
284,10,324,3,0,
284,10,332,4,0,
284,10,346,1,1,5
284,10,346,1,19,
284,10,352,4,0,
284,10,355,4,0,
284,10,363,4,0,
284,10,366,3,0,
284,10,369,4,0,
284,10,403,1,47,
284,10,405,1,61,
284,10,412,4,0,
284,10,416,4,0,
284,10,445,4,0,
284,10,450,3,0,
284,10,466,1,1,1
284,10,466,3,0,
284,11,16,1,22,
284,11,18,1,54,
284,11,58,4,0,
284,11,59,4,0,
284,11,63,4,0,
284,11,76,4,0,
284,11,78,1,33,
284,11,92,4,0,
284,11,98,1,1,3
284,11,98,1,7,
284,11,104,4,0,
284,11,145,1,1,2
284,11,148,4,0,
284,11,156,4,0,
284,11,164,4,0,
284,11,168,4,0,
284,11,182,4,0,
284,11,184,1,26,
284,11,207,4,0,
284,11,213,4,0,
284,11,216,4,0,
284,11,218,4,0,
284,11,230,1,1,4
284,11,230,1,13,
284,11,237,4,0,
284,11,240,4,0,
284,11,241,4,0,
284,11,244,4,0,
284,11,247,4,0,
284,11,263,4,0,
284,11,318,1,40,
284,11,332,4,0,
284,11,346,1,1,5
284,11,346,1,19,
284,11,369,4,0,
284,11,403,1,47,
284,11,405,1,61,
284,11,412,4,0,
284,11,416,4,0,
284,11,466,1,1,1
284,11,483,1,68,
284,11,496,4,0,
284,11,503,4,0,
284,11,522,4,0,
284,12,16,1,26,
284,12,18,1,53,
284,12,58,4,0,
284,12,59,4,0,
284,12,63,4,0,
284,12,76,4,0,
284,12,78,1,40,
284,12,92,4,0,
284,12,98,1,1,2
284,12,98,1,7,
284,12,104,4,0,
284,12,145,1,1,1
284,12,148,4,0,
284,12,156,4,0,
284,12,168,4,0,
284,12,182,4,0,
284,12,184,1,33,
284,12,202,4,0,
284,12,213,4,0,
284,12,216,4,0,
284,12,218,4,0,
284,12,230,1,1,3
284,12,230,1,13,
284,12,237,4,0,
284,12,240,4,0,
284,12,241,4,0,
284,12,247,4,0,
284,12,263,4,0,
284,12,290,4,0,
284,12,318,1,47,
284,12,332,4,0,
284,12,346,1,1,4
284,12,346,1,19,
284,12,352,4,0,
284,13,16,1,26,
284,13,18,1,53,
284,13,38,3,0,
284,13,58,4,0,
284,13,59,4,0,
284,13,63,4,0,
284,13,76,4,0,
284,13,78,1,40,
284,13,92,4,0,
284,13,98,1,1,2
284,13,98,1,7,
284,13,102,3,0,
284,13,104,4,0,
284,13,145,1,1,1
284,13,148,4,0,
284,13,156,4,0,
284,13,164,3,0,
284,13,168,4,0,
284,13,171,3,0,
284,13,182,4,0,
284,13,184,1,33,
284,13,196,3,0,
284,13,202,4,0,
284,13,207,3,0,
284,13,213,4,0,
284,13,216,4,0,
284,13,218,4,0,
284,13,230,1,1,3
284,13,230,1,13,
284,13,237,4,0,
284,13,240,4,0,
284,13,241,4,0,
284,13,247,4,0,
284,13,263,4,0,
284,13,290,4,0,
284,13,318,1,47,
284,13,332,4,0,
284,13,346,1,1,4
284,13,346,1,19,
284,13,352,4,0,
284,14,16,1,22,
284,14,18,1,54,
284,14,58,4,0,
284,14,59,4,0,
284,14,63,4,0,
284,14,76,4,0,
284,14,78,1,33,
284,14,92,4,0,
284,14,98,1,1,3
284,14,98,1,7,
284,14,104,4,0,
284,14,145,1,1,2
284,14,148,4,0,
284,14,156,4,0,
284,14,164,4,0,
284,14,168,4,0,
284,14,173,3,0,
284,14,182,4,0,
284,14,184,1,26,
284,14,196,3,0,
284,14,202,3,0,
284,14,207,4,0,
284,14,213,4,0,
284,14,214,3,0,
284,14,216,4,0,
284,14,218,4,0,
284,14,230,1,1,4
284,14,230,1,13,
284,14,237,4,0,
284,14,240,4,0,
284,14,241,4,0,
284,14,244,4,0,
284,14,247,4,0,
284,14,263,4,0,
284,14,318,1,40,
284,14,324,3,0,
284,14,332,4,0,
284,14,346,1,1,5
284,14,346,1,19,
284,14,355,3,0,
284,14,366,3,0,
284,14,369,4,0,
284,14,403,1,47,
284,14,405,1,61,
284,14,412,4,0,
284,14,416,4,0,
284,14,450,3,0,
284,14,466,1,1,1
284,14,483,1,68,
284,14,496,4,0,
284,14,503,4,0,
284,14,522,4,0,
284,15,16,1,22,
284,15,18,1,1,3
284,15,18,1,54,
284,15,58,4,0,
284,15,59,4,0,
284,15,63,4,0,
284,15,76,4,0,
284,15,78,1,33,
284,15,92,4,0,
284,15,98,1,1,6
284,15,98,1,7,
284,15,104,4,0,
284,15,145,1,1,5
284,15,148,4,0,
284,15,156,4,0,
284,15,164,4,0,
284,15,168,4,0,
284,15,182,4,0,
284,15,184,1,26,
284,15,207,4,0,
284,15,213,4,0,
284,15,214,4,0,
284,15,216,4,0,
284,15,218,4,0,
284,15,230,1,1,7
284,15,230,1,13,
284,15,237,4,0,
284,15,240,4,0,
284,15,241,4,0,
284,15,244,4,0,
284,15,247,4,0,
284,15,263,4,0,
284,15,318,1,40,
284,15,332,4,0,
284,15,346,1,1,8
284,15,346,1,19,
284,15,355,4,0,
284,15,369,4,0,
284,15,403,1,47,
284,15,405,1,1,2
284,15,405,1,61,
284,15,412,4,0,
284,15,416,4,0,
284,15,466,1,1,4
284,15,483,1,1,1
284,15,483,1,68,
284,15,496,4,0,
284,15,503,4,0,
284,15,522,4,0,
284,15,590,4,0,
284,15,611,4,0,
284,16,16,1,17,1
284,16,18,1,1,2
284,16,18,1,48,1
284,16,58,4,0,
284,16,59,4,0,
284,16,63,4,0,
284,16,76,4,0,
284,16,78,1,26,1
284,16,92,4,0,
284,16,98,1,1,6
284,16,98,1,6,1
284,16,104,4,0,
284,16,145,1,1,5
284,16,148,4,0,
284,16,156,4,0,
284,16,164,4,0,
284,16,168,4,0,
284,16,173,3,0,
284,16,182,4,0,
284,16,184,1,22,1
284,16,196,3,0,
284,16,202,3,0,
284,16,207,4,0,
284,16,213,4,0,
284,16,214,4,0,
284,16,216,4,0,
284,16,218,4,0,
284,16,230,1,1,7
284,16,230,1,9,1
284,16,237,4,0,
284,16,240,4,0,
284,16,241,4,0,
284,16,244,4,0,
284,16,247,4,0,
284,16,263,4,0,
284,16,290,4,0,
284,16,314,1,22,2
284,16,318,1,32,1
284,16,324,3,0,
284,16,332,4,0,
284,16,346,1,1,8
284,16,346,1,14,1
284,16,352,3,0,
284,16,355,4,0,
284,16,366,3,0,
284,16,369,4,0,
284,16,403,1,38,1
284,16,405,1,1,3
284,16,405,1,42,1
284,16,412,4,0,
284,16,416,4,0,
284,16,450,3,0,
284,16,466,1,1,4
284,16,483,1,1,1
284,16,483,1,52,1
284,16,496,4,0,
284,16,503,4,0,
284,16,522,4,0,
284,16,590,4,0,
284,16,611,4,0,
284,17,16,1,17,
284,17,18,1,1,2
284,17,18,1,48,
284,17,58,4,0,
284,17,59,4,0,
284,17,63,4,0,
284,17,76,4,0,
284,17,78,1,26,
284,17,92,4,0,
284,17,98,1,1,6
284,17,98,1,6,
284,17,104,4,0,
284,17,145,1,1,5
284,17,156,4,0,
284,17,164,4,0,
284,17,168,4,0,
284,17,182,4,0,
284,17,184,1,22,1
284,17,207,4,0,
284,17,213,4,0,
284,17,214,4,0,
284,17,216,4,0,
284,17,218,4,0,
284,17,230,1,1,7
284,17,230,1,9,
284,17,237,4,0,
284,17,240,4,0,
284,17,241,4,0,
284,17,244,4,0,
284,17,247,4,0,
284,17,263,4,0,
284,17,314,1,22,2
284,17,318,1,32,
284,17,332,4,0,
284,17,346,1,1,8
284,17,346,1,14,
284,17,355,4,0,
284,17,369,4,0,
284,17,403,1,38,
284,17,405,1,1,3
284,17,405,1,42,
284,17,412,4,0,
284,17,416,4,0,
284,17,466,1,1,4
284,17,483,1,1,1
284,17,483,1,52,
284,17,496,4,0,
284,17,503,4,0,
284,17,590,4,0,
284,17,611,4,0,
284,18,16,1,17,
284,18,18,1,1,2
284,18,18,1,48,
284,18,58,4,0,
284,18,59,4,0,
284,18,63,4,0,
284,18,76,4,0,
284,18,78,1,26,
284,18,92,4,0,
284,18,98,1,1,6
284,18,98,1,6,
284,18,104,4,0,
284,18,145,1,1,5
284,18,156,4,0,
284,18,164,4,0,
284,18,168,4,0,
284,18,182,4,0,
284,18,184,1,22,1
284,18,207,4,0,
284,18,213,4,0,
284,18,214,4,0,
284,18,216,4,0,
284,18,218,4,0,
284,18,230,1,1,7
284,18,230,1,9,
284,18,237,4,0,
284,18,240,4,0,
284,18,241,4,0,
284,18,244,4,0,
284,18,247,4,0,
284,18,263,4,0,
284,18,314,1,22,2
284,18,318,1,32,
284,18,332,4,0,
284,18,346,1,1,8
284,18,346,1,14,
284,18,355,4,0,
284,18,369,4,0,
284,18,403,1,38,
284,18,405,1,1,3
284,18,405,1,42,
284,18,412,4,0,
284,18,416,4,0,
284,18,466,1,1,4
284,18,483,1,1,1
284,18,483,1,52,
284,18,496,4,0,
284,18,503,4,0,
284,18,590,4,0,
284,18,611,4,0,
285,5,29,1,22,
285,5,33,1,4,
285,5,71,1,1,
285,5,72,1,16,
285,5,73,1,10,
285,5,74,1,36,
285,5,76,4,0,
285,5,77,1,28,
285,5,78,1,7,
285,5,92,4,0,
285,5,104,4,0,
285,5,147,1,54,
285,5,148,4,0,
285,5,156,4,0,
285,5,182,4,0,
285,5,188,4,0,
285,5,202,1,45,
285,5,202,4,0,
285,5,204,2,0,
285,5,206,2,0,
285,5,207,2,0,
285,5,213,4,0,
285,5,216,4,0,
285,5,218,4,0,
285,5,219,4,0,
285,5,237,4,0,
285,5,241,4,0,
285,5,263,4,0,
285,5,270,2,0,
285,5,289,4,0,
285,5,290,4,0,
285,5,313,2,0,
285,5,331,4,0,
285,6,14,3,0,
285,6,29,1,22,
285,6,33,1,4,
285,6,34,3,0,
285,6,38,3,0,
285,6,71,1,1,
285,6,72,1,16,
285,6,73,1,10,
285,6,74,1,36,
285,6,76,4,0,
285,6,77,1,28,
285,6,78,1,7,
285,6,92,4,0,
285,6,102,3,0,
285,6,104,4,0,
285,6,147,1,54,
285,6,148,4,0,
285,6,156,4,0,
285,6,164,3,0,
285,6,173,3,0,
285,6,182,4,0,
285,6,188,4,0,
285,6,202,1,45,
285,6,202,4,0,
285,6,203,3,0,
285,6,204,2,0,
285,6,206,2,0,
285,6,207,2,0,
285,6,207,3,0,
285,6,213,4,0,
285,6,214,3,0,
285,6,216,4,0,
285,6,218,4,0,
285,6,219,4,0,
285,6,237,4,0,
285,6,241,4,0,
285,6,263,4,0,
285,6,270,2,0,
285,6,289,4,0,
285,6,290,4,0,
285,6,313,2,0,
285,6,331,4,0,
285,7,14,3,0,
285,7,29,1,22,
285,7,33,1,4,
285,7,34,3,0,
285,7,38,3,0,
285,7,71,1,1,
285,7,72,1,16,
285,7,73,1,10,
285,7,74,1,36,
285,7,76,4,0,
285,7,77,1,28,
285,7,78,1,7,
285,7,92,4,0,
285,7,102,3,0,
285,7,104,4,0,
285,7,147,1,54,
285,7,148,4,0,
285,7,156,4,0,
285,7,164,3,0,
285,7,182,4,0,
285,7,188,4,0,
285,7,202,1,45,
285,7,202,4,0,
285,7,204,2,0,
285,7,206,2,0,
285,7,207,2,0,
285,7,213,4,0,
285,7,216,4,0,
285,7,218,4,0,
285,7,219,4,0,
285,7,237,4,0,
285,7,241,4,0,
285,7,263,4,0,
285,7,270,2,0,
285,7,289,4,0,
285,7,290,4,0,
285,7,313,2,0,
285,7,331,4,0,
285,8,14,4,0,
285,8,29,1,21,
285,8,33,1,5,
285,8,71,1,1,
285,8,72,1,17,
285,8,73,1,13,
285,8,74,1,33,
285,8,76,4,0,
285,8,77,1,25,
285,8,78,1,9,
285,8,92,4,0,
285,8,104,4,0,
285,8,147,1,45,
285,8,148,4,0,
285,8,156,4,0,
285,8,164,4,0,
285,8,182,4,0,
285,8,188,4,0,
285,8,202,1,37,
285,8,202,4,0,
285,8,203,4,0,
285,8,204,2,0,
285,8,206,2,0,
285,8,207,2,0,
285,8,207,4,0,
285,8,213,4,0,
285,8,214,4,0,
285,8,216,4,0,
285,8,218,4,0,
285,8,219,4,0,
285,8,237,4,0,
285,8,241,4,0,
285,8,263,4,0,
285,8,270,2,0,
285,8,289,4,0,
285,8,290,4,0,
285,8,313,2,0,
285,8,331,4,0,
285,8,358,2,0,
285,8,363,4,0,
285,8,388,1,29,
285,8,388,2,0,
285,8,402,1,41,
285,8,402,2,0,
285,8,412,4,0,
285,8,445,4,0,
285,8,447,4,0,
285,9,14,4,0,
285,9,29,1,21,
285,9,33,1,5,
285,9,71,1,1,
285,9,72,1,17,
285,9,73,1,13,
285,9,74,1,33,
285,9,76,4,0,
285,9,77,1,25,
285,9,78,1,9,
285,9,92,4,0,
285,9,104,4,0,
285,9,147,1,45,
285,9,148,4,0,
285,9,156,4,0,
285,9,164,4,0,
285,9,173,3,0,
285,9,182,4,0,
285,9,188,4,0,
285,9,202,1,37,
285,9,202,4,0,
285,9,203,4,0,
285,9,204,2,0,
285,9,206,2,0,
285,9,207,2,0,
285,9,207,4,0,
285,9,213,4,0,
285,9,214,4,0,
285,9,216,4,0,
285,9,218,4,0,
285,9,219,4,0,
285,9,235,3,0,
285,9,237,4,0,
285,9,241,4,0,
285,9,263,4,0,
285,9,270,2,0,
285,9,270,3,0,
285,9,289,4,0,
285,9,290,4,0,
285,9,313,2,0,
285,9,331,4,0,
285,9,358,2,0,
285,9,363,4,0,
285,9,388,1,29,
285,9,388,2,0,
285,9,402,1,41,
285,9,402,2,0,
285,9,402,3,0,
285,9,412,4,0,
285,9,445,4,0,
285,9,447,4,0,
285,10,14,4,0,
285,10,29,1,21,
285,10,29,3,0,
285,10,33,1,5,
285,10,71,1,1,
285,10,72,1,17,
285,10,73,1,13,
285,10,74,1,33,
285,10,76,4,0,
285,10,77,1,25,
285,10,78,1,9,
285,10,92,4,0,
285,10,104,4,0,
285,10,147,1,45,
285,10,148,4,0,
285,10,156,4,0,
285,10,164,4,0,
285,10,173,3,0,
285,10,182,4,0,
285,10,188,4,0,
285,10,202,1,37,
285,10,202,4,0,
285,10,203,4,0,
285,10,204,2,0,
285,10,206,2,0,
285,10,207,2,0,
285,10,207,4,0,
285,10,213,4,0,
285,10,214,4,0,
285,10,216,4,0,
285,10,218,4,0,
285,10,219,4,0,
285,10,235,3,0,
285,10,237,4,0,
285,10,241,4,0,
285,10,263,4,0,
285,10,270,2,0,
285,10,270,3,0,
285,10,289,4,0,
285,10,290,4,0,
285,10,313,2,0,
285,10,331,4,0,
285,10,358,2,0,
285,10,363,4,0,
285,10,388,1,29,
285,10,388,2,0,
285,10,388,3,0,
285,10,402,1,41,
285,10,402,2,0,
285,10,402,3,0,
285,10,412,4,0,
285,10,445,4,0,
285,10,447,4,0,
285,11,14,4,0,
285,11,29,1,21,
285,11,33,1,5,
285,11,71,1,1,
285,11,72,1,17,
285,11,73,1,13,
285,11,74,1,33,
285,11,76,4,0,
285,11,77,1,25,
285,11,78,1,9,
285,11,92,4,0,
285,11,104,4,0,
285,11,147,1,45,
285,11,148,4,0,
285,11,156,4,0,
285,11,164,4,0,
285,11,182,4,0,
285,11,188,4,0,
285,11,202,1,37,
285,11,204,2,0,
285,11,206,4,0,
285,11,207,4,0,
285,11,213,4,0,
285,11,216,4,0,
285,11,218,4,0,
285,11,219,4,0,
285,11,237,4,0,
285,11,241,4,0,
285,11,263,4,0,
285,11,264,2,0,
285,11,270,2,0,
285,11,313,2,0,
285,11,331,2,0,
285,11,358,2,0,
285,11,363,2,0,
285,11,388,1,29,
285,11,388,2,0,
285,11,402,1,41,
285,11,402,2,0,
285,11,409,2,0,
285,11,412,4,0,
285,11,447,4,0,
285,11,474,4,0,
285,11,496,4,0,
285,12,29,1,22,
285,12,33,1,4,
285,12,71,1,1,
285,12,72,1,16,
285,12,73,1,10,
285,12,74,1,36,
285,12,76,4,0,
285,12,77,1,28,
285,12,78,1,7,
285,12,92,4,0,
285,12,104,4,0,
285,12,147,1,54,
285,12,148,4,0,
285,12,156,4,0,
285,12,182,4,0,
285,12,188,4,0,
285,12,202,1,45,
285,12,202,4,0,
285,12,213,4,0,
285,12,216,4,0,
285,12,218,4,0,
285,12,219,4,0,
285,12,237,4,0,
285,12,241,4,0,
285,12,263,4,0,
285,12,289,4,0,
285,12,290,4,0,
285,12,331,4,0,
285,13,29,1,22,
285,13,33,1,4,
285,13,34,3,0,
285,13,38,3,0,
285,13,71,1,1,
285,13,72,1,16,
285,13,73,1,10,
285,13,74,1,36,
285,13,76,4,0,
285,13,77,1,28,
285,13,78,1,7,
285,13,92,4,0,
285,13,102,3,0,
285,13,104,4,0,
285,13,147,1,54,
285,13,148,4,0,
285,13,156,4,0,
285,13,164,3,0,
285,13,182,4,0,
285,13,188,4,0,
285,13,202,1,45,
285,13,202,4,0,
285,13,207,3,0,
285,13,213,4,0,
285,13,216,4,0,
285,13,218,4,0,
285,13,219,4,0,
285,13,237,4,0,
285,13,241,4,0,
285,13,263,4,0,
285,13,289,4,0,
285,13,290,4,0,
285,13,331,4,0,
285,14,14,4,0,
285,14,29,1,21,
285,14,33,1,5,
285,14,71,1,1,
285,14,72,1,17,
285,14,73,1,13,
285,14,74,1,33,
285,14,76,4,0,
285,14,77,1,25,
285,14,78,1,9,
285,14,92,4,0,
285,14,104,4,0,
285,14,147,1,45,
285,14,148,4,0,
285,14,156,4,0,
285,14,164,4,0,
285,14,173,3,0,
285,14,182,4,0,
285,14,188,4,0,
285,14,202,1,37,
285,14,202,3,0,
285,14,204,2,0,
285,14,206,4,0,
285,14,207,4,0,
285,14,213,4,0,
285,14,214,3,0,
285,14,216,4,0,
285,14,218,4,0,
285,14,219,4,0,
285,14,235,3,0,
285,14,237,4,0,
285,14,241,4,0,
285,14,263,4,0,
285,14,264,2,0,
285,14,270,2,0,
285,14,270,3,0,
285,14,289,3,0,
285,14,313,2,0,
285,14,331,2,0,
285,14,358,2,0,
285,14,363,2,0,
285,14,388,1,29,
285,14,388,2,0,
285,14,388,3,0,
285,14,402,1,41,
285,14,402,2,0,
285,14,402,3,0,
285,14,409,2,0,
285,14,409,3,0,
285,14,412,4,0,
285,14,447,4,0,
285,14,474,4,0,
285,14,496,4,0,
285,15,14,4,0,
285,15,29,1,21,
285,15,33,1,5,
285,15,71,1,1,
285,15,72,1,17,
285,15,73,1,13,
285,15,74,1,33,
285,15,76,4,0,
285,15,77,1,25,
285,15,78,1,9,
285,15,92,4,0,
285,15,104,4,0,
285,15,147,1,45,
285,15,148,4,0,
285,15,156,4,0,
285,15,164,4,0,
285,15,182,4,0,
285,15,188,4,0,
285,15,202,1,37,
285,15,204,2,0,
285,15,206,4,0,
285,15,207,4,0,
285,15,213,4,0,
285,15,214,4,0,
285,15,216,4,0,
285,15,218,4,0,
285,15,219,4,0,
285,15,237,4,0,
285,15,241,4,0,
285,15,263,4,0,
285,15,264,2,0,
285,15,270,2,0,
285,15,313,2,0,
285,15,331,2,0,
285,15,358,2,0,
285,15,363,2,0,
285,15,388,1,29,
285,15,388,2,0,
285,15,402,1,41,
285,15,402,2,0,
285,15,409,2,0,
285,15,412,4,0,
285,15,447,4,0,
285,15,474,4,0,
285,15,496,4,0,
285,15,590,4,0,
285,16,14,4,0,
285,16,29,1,15,1
285,16,33,1,1,2
285,16,71,1,1,1
285,16,72,1,12,1
285,16,73,1,8,1
285,16,74,1,29,1
285,16,76,4,0,
285,16,77,1,19,1
285,16,78,1,5,1
285,16,92,1,33,1
285,16,92,4,0,
285,16,104,4,0,
285,16,147,1,40,1
285,16,148,4,0,
285,16,156,4,0,
285,16,164,4,0,
285,16,173,3,0,
285,16,182,4,0,
285,16,188,4,0,
285,16,202,1,26,1
285,16,202,3,0,
285,16,204,2,0,
285,16,206,4,0,
285,16,207,4,0,
285,16,213,4,0,
285,16,214,4,0,
285,16,216,4,0,
285,16,218,4,0,
285,16,219,4,0,
285,16,235,3,0,
285,16,237,4,0,
285,16,241,4,0,
285,16,263,4,0,
285,16,264,2,0,
285,16,264,3,0,
285,16,270,2,0,
285,16,270,3,0,
285,16,289,3,0,
285,16,290,4,0,
285,16,313,2,0,
285,16,331,2,0,
285,16,358,2,0,
285,16,363,2,0,
285,16,388,1,22,1
285,16,388,2,0,
285,16,388,3,0,
285,16,402,1,36,1
285,16,402,2,0,
285,16,402,3,0,
285,16,409,2,0,
285,16,409,3,0,
285,16,412,4,0,
285,16,447,4,0,
285,16,474,4,0,
285,16,496,4,0,
285,16,590,4,0,
285,17,14,4,0,
285,17,29,1,15,
285,17,33,1,1,2
285,17,71,1,1,1
285,17,72,1,12,
285,17,73,1,8,
285,17,74,1,29,
285,17,76,4,0,
285,17,77,1,19,
285,17,78,1,5,
285,17,92,1,33,
285,17,92,4,0,
285,17,104,4,0,
285,17,147,1,40,
285,17,156,4,0,
285,17,164,4,0,
285,17,182,4,0,
285,17,188,4,0,
285,17,202,1,26,
285,17,204,2,0,
285,17,206,4,0,
285,17,207,4,0,
285,17,213,4,0,
285,17,214,4,0,
285,17,216,4,0,
285,17,218,4,0,
285,17,219,4,0,
285,17,237,4,0,
285,17,241,4,0,
285,17,263,4,0,
285,17,264,2,0,
285,17,270,2,0,
285,17,313,2,0,
285,17,331,2,0,
285,17,358,2,0,
285,17,363,2,0,
285,17,388,1,22,
285,17,388,2,0,
285,17,402,1,36,
285,17,402,2,0,
285,17,409,2,0,
285,17,412,4,0,
285,17,447,4,0,
285,17,474,4,0,
285,17,496,4,0,
285,17,590,4,0,
285,18,14,4,0,
285,18,29,1,15,
285,18,33,1,1,2
285,18,71,1,1,1
285,18,72,1,12,
285,18,73,1,8,
285,18,74,1,29,
285,18,76,4,0,
285,18,77,1,19,
285,18,78,1,5,
285,18,92,1,33,
285,18,92,4,0,
285,18,104,4,0,
285,18,147,1,40,
285,18,156,4,0,
285,18,164,4,0,
285,18,182,4,0,
285,18,188,4,0,
285,18,202,1,26,
285,18,204,2,0,
285,18,206,4,0,
285,18,207,4,0,
285,18,213,4,0,
285,18,214,4,0,
285,18,216,4,0,
285,18,218,4,0,
285,18,219,4,0,
285,18,237,4,0,
285,18,241,4,0,
285,18,263,4,0,
285,18,264,2,0,
285,18,270,2,0,
285,18,313,2,0,
285,18,331,2,0,
285,18,358,2,0,
285,18,363,2,0,
285,18,388,1,22,
285,18,388,2,0,
285,18,402,1,36,
285,18,402,2,0,
285,18,409,2,0,
285,18,412,4,0,
285,18,447,4,0,
285,18,474,4,0,
285,18,496,4,0,
285,18,590,4,0,
286,5,15,4,0,
286,5,29,1,22,
286,5,33,1,1,2
286,5,33,1,4,
286,5,63,4,0,
286,5,68,1,28,
286,5,70,4,0,
286,5,71,1,1,1
286,5,72,1,16,
286,5,73,1,1,4
286,5,73,1,10,
286,5,76,4,0,
286,5,78,1,1,3
286,5,78,1,7,
286,5,92,4,0,
286,5,104,4,0,
286,5,148,4,0,
286,5,156,4,0,
286,5,170,1,45,
286,5,182,4,0,
286,5,183,1,23,
286,5,188,4,0,
286,5,202,4,0,
286,5,213,4,0,
286,5,216,4,0,
286,5,218,4,0,
286,5,219,4,0,
286,5,223,1,54,
286,5,231,4,0,
286,5,237,4,0,
286,5,241,4,0,
286,5,249,4,0,
286,5,263,4,0,
286,5,264,4,0,
286,5,280,4,0,
286,5,289,4,0,
286,5,290,4,0,
286,5,327,1,36,
286,5,331,4,0,
286,5,339,4,0,
286,6,5,3,0,
286,6,9,3,0,
286,6,14,3,0,
286,6,15,4,0,
286,6,25,3,0,
286,6,29,1,22,
286,6,33,1,1,2
286,6,33,1,4,
286,6,34,3,0,
286,6,38,3,0,
286,6,63,4,0,
286,6,68,1,28,
286,6,68,3,0,
286,6,69,3,0,
286,6,70,4,0,
286,6,71,1,1,1
286,6,72,1,16,
286,6,73,1,1,4
286,6,73,1,10,
286,6,76,4,0,
286,6,78,1,1,3
286,6,78,1,7,
286,6,92,4,0,
286,6,102,3,0,
286,6,104,4,0,
286,6,148,4,0,
286,6,156,4,0,
286,6,164,3,0,
286,6,170,1,45,
286,6,173,3,0,
286,6,182,4,0,
286,6,183,1,23,
286,6,188,4,0,
286,6,189,3,0,
286,6,202,4,0,
286,6,203,3,0,
286,6,207,3,0,
286,6,210,3,0,
286,6,213,4,0,
286,6,214,3,0,
286,6,216,4,0,
286,6,218,4,0,
286,6,219,4,0,
286,6,223,1,54,
286,6,223,3,0,
286,6,231,4,0,
286,6,237,4,0,
286,6,241,4,0,
286,6,249,4,0,
286,6,263,4,0,
286,6,264,4,0,
286,6,280,4,0,
286,6,289,4,0,
286,6,290,4,0,
286,6,327,1,36,
286,6,331,4,0,
286,6,339,4,0,
286,7,5,3,0,
286,7,14,3,0,
286,7,15,4,0,
286,7,25,3,0,
286,7,29,1,22,
286,7,33,1,1,2
286,7,33,1,4,
286,7,34,3,0,
286,7,38,3,0,
286,7,63,4,0,
286,7,68,1,28,
286,7,68,3,0,
286,7,69,3,0,
286,7,70,4,0,
286,7,71,1,1,1
286,7,72,1,16,
286,7,73,1,1,4
286,7,73,1,10,
286,7,76,4,0,
286,7,78,1,1,3
286,7,78,1,7,
286,7,92,4,0,
286,7,102,3,0,
286,7,104,4,0,
286,7,148,4,0,
286,7,156,4,0,
286,7,164,3,0,
286,7,170,1,45,
286,7,182,4,0,
286,7,183,1,23,
286,7,188,4,0,
286,7,202,4,0,
286,7,213,4,0,
286,7,216,4,0,
286,7,218,4,0,
286,7,219,4,0,
286,7,223,1,54,
286,7,231,4,0,
286,7,237,4,0,
286,7,241,4,0,
286,7,249,4,0,
286,7,263,4,0,
286,7,264,4,0,
286,7,280,4,0,
286,7,289,4,0,
286,7,290,4,0,
286,7,327,1,36,
286,7,331,4,0,
286,7,339,4,0,
286,8,14,4,0,
286,8,15,4,0,
286,8,29,1,21,
286,8,33,1,1,2
286,8,33,1,5,
286,8,63,4,0,
286,8,68,1,25,
286,8,70,4,0,
286,8,71,1,1,1
286,8,72,1,17,
286,8,73,1,1,4
286,8,73,1,13,
286,8,76,4,0,
286,8,78,1,1,3
286,8,78,1,9,
286,8,92,4,0,
286,8,104,4,0,
286,8,148,4,0,
286,8,156,4,0,
286,8,157,4,0,
286,8,164,4,0,
286,8,170,1,37,
286,8,182,4,0,
286,8,183,1,23,
286,8,188,4,0,
286,8,202,4,0,
286,8,203,4,0,
286,8,207,4,0,
286,8,213,4,0,
286,8,214,4,0,
286,8,216,4,0,
286,8,218,4,0,
286,8,219,4,0,
286,8,223,1,45,
286,8,231,4,0,
286,8,237,4,0,
286,8,241,4,0,
286,8,249,4,0,
286,8,263,4,0,
286,8,264,4,0,
286,8,280,4,0,
286,8,289,4,0,
286,8,290,4,0,
286,8,317,4,0,
286,8,327,1,33,
286,8,331,4,0,
286,8,339,4,0,
286,8,363,4,0,
286,8,374,4,0,
286,8,395,1,29,
286,8,402,1,41,
286,8,409,4,0,
286,8,411,4,0,
286,8,412,4,0,
286,8,416,4,0,
286,8,444,4,0,
286,8,445,4,0,
286,8,447,4,0,
286,9,9,3,0,
286,9,14,4,0,
286,9,15,4,0,
286,9,29,1,21,
286,9,33,1,1,2
286,9,33,1,5,
286,9,63,4,0,
286,9,68,1,25,
286,9,70,4,0,
286,9,71,1,1,1
286,9,72,1,17,
286,9,73,1,1,4
286,9,73,1,13,
286,9,76,4,0,
286,9,78,1,1,3
286,9,78,1,9,
286,9,92,4,0,
286,9,104,4,0,
286,9,148,4,0,
286,9,156,4,0,
286,9,157,4,0,
286,9,164,4,0,
286,9,170,1,37,
286,9,173,3,0,
286,9,182,4,0,
286,9,183,1,23,
286,9,188,4,0,
286,9,189,3,0,
286,9,202,4,0,
286,9,203,4,0,
286,9,207,4,0,
286,9,210,3,0,
286,9,213,4,0,
286,9,214,4,0,
286,9,216,4,0,
286,9,218,4,0,
286,9,219,4,0,
286,9,223,1,45,
286,9,231,4,0,
286,9,235,3,0,
286,9,237,4,0,
286,9,241,4,0,
286,9,249,4,0,
286,9,263,4,0,
286,9,264,4,0,
286,9,270,3,0,
286,9,276,3,0,
286,9,280,4,0,
286,9,289,4,0,
286,9,290,4,0,
286,9,317,4,0,
286,9,327,1,33,
286,9,331,4,0,
286,9,339,4,0,
286,9,363,4,0,
286,9,374,4,0,
286,9,395,1,29,
286,9,402,1,41,
286,9,402,3,0,
286,9,409,4,0,
286,9,410,3,0,
286,9,411,4,0,
286,9,412,4,0,
286,9,416,4,0,
286,9,444,4,0,
286,9,445,4,0,
286,9,447,4,0,
286,10,9,3,0,
286,10,14,4,0,
286,10,15,4,0,
286,10,29,1,21,
286,10,29,3,0,
286,10,33,1,1,2
286,10,33,1,5,
286,10,63,4,0,
286,10,68,1,25,
286,10,70,4,0,
286,10,71,1,1,1
286,10,72,1,17,
286,10,73,1,1,4
286,10,73,1,13,
286,10,76,4,0,
286,10,78,1,1,3
286,10,78,1,9,
286,10,92,4,0,
286,10,104,4,0,
286,10,148,4,0,
286,10,156,4,0,
286,10,157,4,0,
286,10,164,4,0,
286,10,170,1,37,
286,10,173,3,0,
286,10,182,4,0,
286,10,183,1,23,
286,10,188,4,0,
286,10,189,3,0,
286,10,202,4,0,
286,10,203,4,0,
286,10,207,4,0,
286,10,210,3,0,
286,10,213,4,0,
286,10,214,4,0,
286,10,216,4,0,
286,10,218,4,0,
286,10,219,4,0,
286,10,223,1,45,
286,10,231,4,0,
286,10,235,3,0,
286,10,237,4,0,
286,10,241,4,0,
286,10,249,4,0,
286,10,263,4,0,
286,10,264,4,0,
286,10,270,3,0,
286,10,276,3,0,
286,10,280,4,0,
286,10,289,4,0,
286,10,290,4,0,
286,10,317,4,0,
286,10,327,1,33,
286,10,331,4,0,
286,10,339,4,0,
286,10,363,4,0,
286,10,374,4,0,
286,10,388,3,0,
286,10,395,1,29,
286,10,402,1,41,
286,10,402,3,0,
286,10,409,4,0,
286,10,410,3,0,
286,10,411,4,0,
286,10,412,4,0,
286,10,416,4,0,
286,10,444,4,0,
286,10,445,4,0,
286,10,447,4,0,
286,11,14,4,0,
286,11,15,4,0,
286,11,29,1,21,
286,11,33,1,1,2
286,11,33,1,5,
286,11,63,4,0,
286,11,68,1,25,
286,11,70,4,0,
286,11,71,1,1,1
286,11,72,1,17,
286,11,73,1,1,4
286,11,73,1,13,
286,11,76,4,0,
286,11,78,1,1,3
286,11,78,1,9,
286,11,92,4,0,
286,11,104,4,0,
286,11,148,4,0,
286,11,156,4,0,
286,11,157,4,0,
286,11,164,4,0,
286,11,170,1,37,
286,11,182,4,0,
286,11,183,1,23,
286,11,188,4,0,
286,11,206,4,0,
286,11,207,4,0,
286,11,213,4,0,
286,11,216,4,0,
286,11,218,4,0,
286,11,219,4,0,
286,11,223,1,45,
286,11,237,4,0,
286,11,241,4,0,
286,11,249,4,0,
286,11,263,4,0,
286,11,280,4,0,
286,11,317,4,0,
286,11,327,1,33,
286,11,339,4,0,
286,11,374,4,0,
286,11,395,1,29,
286,11,402,1,41,
286,11,411,4,0,
286,11,412,4,0,
286,11,416,4,0,
286,11,444,4,0,
286,11,447,4,0,
286,11,474,4,0,
286,11,490,4,0,
286,11,496,4,0,
286,11,514,4,0,
286,11,526,4,0,
286,12,15,4,0,
286,12,29,1,22,
286,12,33,1,1,2
286,12,33,1,4,
286,12,63,4,0,
286,12,68,1,28,
286,12,70,4,0,
286,12,71,1,1,1
286,12,72,1,16,
286,12,73,1,1,4
286,12,73,1,10,
286,12,76,4,0,
286,12,78,1,1,3
286,12,78,1,7,
286,12,92,4,0,
286,12,104,4,0,
286,12,148,4,0,
286,12,156,4,0,
286,12,170,1,45,
286,12,182,4,0,
286,12,183,1,23,
286,12,188,4,0,
286,12,202,4,0,
286,12,213,4,0,
286,12,216,4,0,
286,12,218,4,0,
286,12,219,4,0,
286,12,223,1,54,
286,12,231,4,0,
286,12,237,4,0,
286,12,241,4,0,
286,12,249,4,0,
286,12,263,4,0,
286,12,264,4,0,
286,12,280,4,0,
286,12,289,4,0,
286,12,290,4,0,
286,12,327,1,36,
286,12,331,4,0,
286,12,339,4,0,
286,13,15,4,0,
286,13,29,1,22,
286,13,33,1,1,2
286,13,33,1,4,
286,13,34,3,0,
286,13,38,3,0,
286,13,63,4,0,
286,13,68,1,28,
286,13,69,3,0,
286,13,70,4,0,
286,13,71,1,1,1
286,13,72,1,16,
286,13,73,1,1,4
286,13,73,1,10,
286,13,76,4,0,
286,13,78,1,1,3
286,13,78,1,7,
286,13,92,4,0,
286,13,102,3,0,
286,13,104,4,0,
286,13,148,4,0,
286,13,156,4,0,
286,13,164,3,0,
286,13,170,1,45,
286,13,182,4,0,
286,13,183,1,23,
286,13,188,4,0,
286,13,202,4,0,
286,13,207,3,0,
286,13,213,4,0,
286,13,216,4,0,
286,13,218,4,0,
286,13,219,4,0,
286,13,223,1,54,
286,13,231,4,0,
286,13,237,4,0,
286,13,241,4,0,
286,13,249,4,0,
286,13,263,4,0,
286,13,264,4,0,
286,13,280,4,0,
286,13,289,4,0,
286,13,290,4,0,
286,13,327,1,36,
286,13,331,4,0,
286,13,339,4,0,
286,14,9,3,0,
286,14,14,4,0,
286,14,15,4,0,
286,14,29,1,21,
286,14,33,1,1,2
286,14,33,1,5,
286,14,63,4,0,
286,14,68,1,25,
286,14,70,4,0,
286,14,71,1,1,1
286,14,72,1,17,
286,14,73,1,1,4
286,14,73,1,13,
286,14,76,4,0,
286,14,78,1,1,3
286,14,78,1,9,
286,14,92,4,0,
286,14,104,4,0,
286,14,148,4,0,
286,14,156,4,0,
286,14,157,4,0,
286,14,164,4,0,
286,14,170,1,37,
286,14,173,3,0,
286,14,182,4,0,
286,14,183,1,23,
286,14,188,4,0,
286,14,202,3,0,
286,14,206,4,0,
286,14,207,4,0,
286,14,213,4,0,
286,14,214,3,0,
286,14,216,4,0,
286,14,218,4,0,
286,14,219,4,0,
286,14,223,1,45,
286,14,231,3,0,
286,14,235,3,0,
286,14,237,4,0,
286,14,241,4,0,
286,14,249,4,0,
286,14,263,4,0,
286,14,270,3,0,
286,14,276,3,0,
286,14,280,4,0,
286,14,289,3,0,
286,14,317,4,0,
286,14,327,1,33,
286,14,339,4,0,
286,14,374,4,0,
286,14,388,3,0,
286,14,395,1,29,
286,14,402,1,41,
286,14,402,3,0,
286,14,409,3,0,
286,14,411,4,0,
286,14,412,4,0,
286,14,416,4,0,
286,14,444,4,0,
286,14,447,4,0,
286,14,474,4,0,
286,14,490,4,0,
286,14,496,4,0,
286,14,514,4,0,
286,14,526,4,0,
286,15,14,4,0,
286,15,15,4,0,
286,15,29,1,21,
286,15,33,1,1,2
286,15,33,1,5,
286,15,63,4,0,
286,15,68,1,25,
286,15,70,4,0,
286,15,71,1,1,1
286,15,72,1,17,
286,15,73,1,1,4
286,15,73,1,13,
286,15,76,4,0,
286,15,78,1,1,3
286,15,78,1,9,
286,15,92,4,0,
286,15,104,4,0,
286,15,148,4,0,
286,15,156,4,0,
286,15,157,4,0,
286,15,164,4,0,
286,15,170,1,37,
286,15,182,4,0,
286,15,183,1,23,
286,15,188,4,0,
286,15,206,4,0,
286,15,207,4,0,
286,15,213,4,0,
286,15,214,4,0,
286,15,216,4,0,
286,15,218,4,0,
286,15,219,4,0,
286,15,223,1,45,
286,15,237,4,0,
286,15,241,4,0,
286,15,249,4,0,
286,15,263,4,0,
286,15,280,4,0,
286,15,317,4,0,
286,15,327,1,33,
286,15,339,4,0,
286,15,374,4,0,
286,15,395,1,29,
286,15,402,1,41,
286,15,411,4,0,
286,15,412,4,0,
286,15,416,4,0,
286,15,444,4,0,
286,15,447,4,0,
286,15,474,4,0,
286,15,490,4,0,
286,15,496,4,0,
286,15,514,4,0,
286,15,590,4,0,
286,15,612,4,0,
286,16,9,3,0,
286,16,14,4,0,
286,16,15,4,0,
286,16,29,1,15,1
286,16,33,1,1,2
286,16,63,4,0,
286,16,68,1,22,1
286,16,70,4,0,
286,16,71,1,1,1
286,16,72,1,12,1
286,16,73,1,1,4
286,16,73,1,8,1
286,16,76,4,0,
286,16,78,1,1,3
286,16,78,1,5,1
286,16,92,4,0,
286,16,104,4,0,
286,16,148,4,0,
286,16,156,4,0,
286,16,157,4,0,
286,16,164,4,0,
286,16,170,1,33,1
286,16,173,3,0,
286,16,182,4,0,
286,16,183,1,23,1
286,16,188,4,0,
286,16,202,3,0,
286,16,206,4,0,
286,16,207,4,0,
286,16,213,4,0,
286,16,214,4,0,
286,16,216,4,0,
286,16,218,4,0,
286,16,219,4,0,
286,16,223,1,50,1
286,16,231,3,0,
286,16,235,3,0,
286,16,237,4,0,
286,16,241,4,0,
286,16,249,4,0,
286,16,263,4,0,
286,16,264,3,0,
286,16,270,3,0,
286,16,276,3,0,
286,16,280,4,0,
286,16,289,3,0,
286,16,290,4,0,
286,16,317,4,0,
286,16,327,1,39,1
286,16,339,4,0,
286,16,364,1,19,1
286,16,374,4,0,
286,16,388,3,0,
286,16,395,1,28,1
286,16,402,1,44,1
286,16,402,3,0,
286,16,409,3,0,
286,16,411,4,0,
286,16,412,4,0,
286,16,416,4,0,
286,16,444,4,0,
286,16,447,4,0,
286,16,474,4,0,
286,16,490,4,0,
286,16,496,4,0,
286,16,514,4,0,
286,16,590,4,0,
286,16,612,4,0,
286,17,14,4,0,
286,17,29,1,15,
286,17,33,1,1,3
286,17,63,4,0,
286,17,68,1,22,
286,17,71,1,1,2
286,17,72,1,12,
286,17,73,1,1,5
286,17,73,1,8,
286,17,76,4,0,
286,17,78,1,1,4
286,17,78,1,5,
286,17,92,4,0,
286,17,104,4,0,
286,17,156,4,0,
286,17,157,4,0,
286,17,164,4,0,
286,17,170,1,33,
286,17,182,4,0,
286,17,183,1,0,
286,17,183,1,1,1
286,17,188,4,0,
286,17,206,4,0,
286,17,207,4,0,
286,17,213,4,0,
286,17,214,4,0,
286,17,216,4,0,
286,17,218,4,0,
286,17,219,4,0,
286,17,223,1,50,
286,17,237,4,0,
286,17,241,4,0,
286,17,263,4,0,
286,17,280,4,0,
286,17,317,4,0,
286,17,327,1,39,
286,17,339,4,0,
286,17,364,1,19,
286,17,374,4,0,
286,17,395,1,28,
286,17,402,1,44,
286,17,411,4,0,
286,17,412,4,0,
286,17,416,4,0,
286,17,444,4,0,
286,17,447,4,0,
286,17,474,4,0,
286,17,490,4,0,
286,17,496,4,0,
286,17,526,4,0,
286,17,590,4,0,
286,18,14,4,0,
286,18,29,1,15,
286,18,33,1,1,3
286,18,63,4,0,
286,18,68,1,22,
286,18,71,1,1,2
286,18,72,1,12,
286,18,73,1,1,5
286,18,73,1,8,
286,18,76,4,0,
286,18,78,1,1,4
286,18,78,1,5,
286,18,92,4,0,
286,18,104,4,0,
286,18,156,4,0,
286,18,157,4,0,
286,18,164,4,0,
286,18,170,1,33,
286,18,182,4,0,
286,18,183,1,0,
286,18,183,1,1,1
286,18,188,4,0,
286,18,206,4,0,
286,18,207,4,0,
286,18,213,4,0,
286,18,214,4,0,
286,18,216,4,0,
286,18,218,4,0,
286,18,219,4,0,
286,18,223,1,50,
286,18,237,4,0,
286,18,241,4,0,
286,18,263,4,0,
286,18,280,4,0,
286,18,317,4,0,
286,18,327,1,39,
286,18,339,4,0,
286,18,364,1,19,
286,18,374,4,0,
286,18,395,1,28,
286,18,402,1,44,
286,18,411,4,0,
286,18,412,4,0,
286,18,416,4,0,
286,18,444,4,0,
286,18,447,4,0,
286,18,474,4,0,
286,18,490,4,0,
286,18,496,4,0,
286,18,526,4,0,
286,18,590,4,0,
287,5,10,1,1,1
287,5,15,4,0,
287,5,34,2,0,
287,5,53,4,0,
287,5,58,4,0,
287,5,59,4,0,
287,5,68,1,37,
287,5,70,4,0,
287,5,76,4,0,
287,5,85,4,0,
287,5,87,4,0,
287,5,92,4,0,
287,5,104,4,0,
287,5,126,4,0,
287,5,133,1,25,
287,5,156,4,0,
287,5,163,2,0,
287,5,173,2,0,
287,5,174,2,0,
287,5,175,1,43,
287,5,182,4,0,
287,5,185,1,19,
287,5,213,4,0,
287,5,214,2,0,
287,5,216,4,0,
287,5,218,4,0,
287,5,227,1,7,
287,5,228,2,0,
287,5,237,4,0,
287,5,240,4,0,
287,5,241,4,0,
287,5,247,4,0,
287,5,249,4,0,
287,5,263,4,0,
287,5,264,4,0,
287,5,280,4,0,
287,5,281,1,1,2
287,5,290,4,0,
287,5,303,1,13,
287,5,306,2,0,
287,5,332,4,0,
287,5,339,4,0,
287,5,343,1,31,
287,5,351,4,0,
287,5,352,4,0,
287,6,5,3,0,
287,6,7,3,0,
287,6,8,3,0,
287,6,9,3,0,
287,6,10,1,1,1
287,6,15,4,0,
287,6,25,3,0,
287,6,34,2,0,
287,6,34,3,0,
287,6,38,3,0,
287,6,53,4,0,
287,6,58,4,0,
287,6,59,4,0,
287,6,68,1,37,
287,6,68,3,0,
287,6,69,3,0,
287,6,70,4,0,
287,6,76,4,0,
287,6,85,4,0,
287,6,87,4,0,
287,6,92,4,0,
287,6,102,3,0,
287,6,104,4,0,
287,6,126,4,0,
287,6,133,1,25,
287,6,156,4,0,
287,6,157,3,0,
287,6,163,2,0,
287,6,164,3,0,
287,6,173,2,0,
287,6,173,3,0,
287,6,174,2,0,
287,6,175,1,43,
287,6,182,4,0,
287,6,185,1,19,
287,6,189,3,0,
287,6,196,3,0,
287,6,203,3,0,
287,6,207,3,0,
287,6,210,3,0,
287,6,213,4,0,
287,6,214,2,0,
287,6,214,3,0,
287,6,216,4,0,
287,6,218,4,0,
287,6,223,3,0,
287,6,227,1,7,
287,6,228,2,0,
287,6,237,4,0,
287,6,240,4,0,
287,6,241,4,0,
287,6,247,4,0,
287,6,249,4,0,
287,6,263,4,0,
287,6,264,4,0,
287,6,280,4,0,
287,6,281,1,1,2
287,6,290,4,0,
287,6,303,1,13,
287,6,306,2,0,
287,6,332,4,0,
287,6,339,4,0,
287,6,343,1,31,
287,6,351,4,0,
287,6,352,4,0,
287,7,5,3,0,
287,7,10,1,1,1
287,7,15,4,0,
287,7,25,3,0,
287,7,34,2,0,
287,7,34,3,0,
287,7,38,3,0,
287,7,53,4,0,
287,7,58,4,0,
287,7,59,4,0,
287,7,68,1,37,
287,7,68,3,0,
287,7,69,3,0,
287,7,70,4,0,
287,7,76,4,0,
287,7,85,4,0,
287,7,87,4,0,
287,7,92,4,0,
287,7,102,3,0,
287,7,104,4,0,
287,7,126,4,0,
287,7,133,1,25,
287,7,156,4,0,
287,7,157,3,0,
287,7,163,2,0,
287,7,164,3,0,
287,7,173,2,0,
287,7,174,2,0,
287,7,175,1,43,
287,7,182,4,0,
287,7,185,1,19,
287,7,213,4,0,
287,7,214,2,0,
287,7,216,4,0,
287,7,218,4,0,
287,7,227,1,7,
287,7,228,2,0,
287,7,237,4,0,
287,7,240,4,0,
287,7,241,4,0,
287,7,247,4,0,
287,7,249,4,0,
287,7,263,4,0,
287,7,264,4,0,
287,7,280,4,0,
287,7,281,1,1,2
287,7,290,4,0,
287,7,303,1,13,
287,7,306,2,0,
287,7,332,4,0,
287,7,339,4,0,
287,7,343,1,31,
287,7,351,4,0,
287,7,352,4,0,
287,8,10,1,1,1
287,8,15,4,0,
287,8,34,2,0,
287,8,53,4,0,
287,8,58,4,0,
287,8,59,4,0,
287,8,68,1,37,
287,8,70,4,0,
287,8,76,4,0,
287,8,85,4,0,
287,8,87,4,0,
287,8,92,4,0,
287,8,104,4,0,
287,8,126,4,0,
287,8,133,1,25,
287,8,156,4,0,
287,8,157,4,0,
287,8,163,2,0,
287,8,164,4,0,
287,8,173,2,0,
287,8,174,2,0,
287,8,175,1,43,
287,8,182,4,0,
287,8,185,1,19,
287,8,203,4,0,
287,8,207,4,0,
287,8,213,4,0,
287,8,214,2,0,
287,8,214,4,0,
287,8,216,4,0,
287,8,218,4,0,
287,8,227,1,7,
287,8,228,2,0,
287,8,237,4,0,
287,8,240,4,0,
287,8,241,4,0,
287,8,247,4,0,
287,8,249,4,0,
287,8,263,4,0,
287,8,264,4,0,
287,8,280,4,0,
287,8,281,1,1,2
287,8,290,4,0,
287,8,303,1,13,
287,8,306,2,0,
287,8,317,4,0,
287,8,332,4,0,
287,8,339,4,0,
287,8,343,1,31,
287,8,351,4,0,
287,8,352,4,0,
287,8,359,2,0,
287,8,363,4,0,
287,8,374,4,0,
287,8,400,2,0,
287,8,421,4,0,
287,8,445,4,0,
287,9,7,3,0,
287,9,8,3,0,
287,9,9,3,0,
287,9,10,1,1,1
287,9,15,4,0,
287,9,34,2,0,
287,9,53,4,0,
287,9,58,4,0,
287,9,59,4,0,
287,9,68,1,37,
287,9,70,4,0,
287,9,76,4,0,
287,9,85,4,0,
287,9,87,4,0,
287,9,92,4,0,
287,9,104,4,0,
287,9,126,4,0,
287,9,133,1,25,
287,9,156,4,0,
287,9,157,4,0,
287,9,163,2,0,
287,9,164,4,0,
287,9,173,2,0,
287,9,173,3,0,
287,9,174,2,0,
287,9,175,1,43,
287,9,182,4,0,
287,9,185,1,19,
287,9,189,3,0,
287,9,196,3,0,
287,9,203,4,0,
287,9,207,4,0,
287,9,210,3,0,
287,9,213,4,0,
287,9,214,2,0,
287,9,214,4,0,
287,9,216,4,0,
287,9,218,4,0,
287,9,227,1,7,
287,9,228,2,0,
287,9,237,4,0,
287,9,240,4,0,
287,9,241,4,0,
287,9,247,4,0,
287,9,249,4,0,
287,9,263,4,0,
287,9,264,4,0,
287,9,280,4,0,
287,9,281,1,1,2
287,9,290,4,0,
287,9,303,1,13,
287,9,306,2,0,
287,9,317,4,0,
287,9,332,4,0,
287,9,339,4,0,
287,9,343,1,31,
287,9,351,4,0,
287,9,352,4,0,
287,9,359,2,0,
287,9,363,4,0,
287,9,374,4,0,
287,9,389,3,0,
287,9,400,2,0,
287,9,421,4,0,
287,9,441,3,0,
287,9,445,4,0,
287,10,7,3,0,
287,10,8,3,0,
287,10,9,3,0,
287,10,10,1,1,1
287,10,15,4,0,
287,10,29,3,0,
287,10,34,2,0,
287,10,53,4,0,
287,10,58,4,0,
287,10,59,4,0,
287,10,68,1,37,
287,10,70,4,0,
287,10,76,4,0,
287,10,85,4,0,
287,10,87,4,0,
287,10,92,4,0,
287,10,104,4,0,
287,10,126,4,0,
287,10,133,1,25,
287,10,156,4,0,
287,10,157,4,0,
287,10,163,2,0,
287,10,164,4,0,
287,10,173,2,0,
287,10,173,3,0,
287,10,174,2,0,
287,10,175,1,43,
287,10,182,4,0,
287,10,185,1,19,
287,10,189,3,0,
287,10,196,3,0,
287,10,203,4,0,
287,10,207,4,0,
287,10,210,3,0,
287,10,213,4,0,
287,10,214,2,0,
287,10,214,4,0,
287,10,216,4,0,
287,10,218,4,0,
287,10,227,1,7,
287,10,228,2,0,
287,10,237,4,0,
287,10,240,4,0,
287,10,241,4,0,
287,10,247,4,0,
287,10,249,4,0,
287,10,263,4,0,
287,10,264,4,0,
287,10,280,4,0,
287,10,281,1,1,2
287,10,290,4,0,
287,10,303,1,13,
287,10,306,2,0,
287,10,317,4,0,
287,10,332,4,0,
287,10,339,4,0,
287,10,343,1,31,
287,10,351,4,0,
287,10,352,4,0,
287,10,359,2,0,
287,10,363,4,0,
287,10,374,4,0,
287,10,389,3,0,
287,10,400,2,0,
287,10,421,4,0,
287,10,441,3,0,
287,10,445,4,0,
287,11,10,1,1,1
287,11,15,4,0,
287,11,34,2,0,
287,11,53,4,0,
287,11,58,4,0,
287,11,59,4,0,
287,11,68,1,43,
287,11,70,4,0,
287,11,76,4,0,
287,11,85,4,0,
287,11,87,4,0,
287,11,92,4,0,
287,11,104,4,0,
287,11,126,4,0,
287,11,133,1,25,
287,11,156,4,0,
287,11,157,4,0,
287,11,163,2,0,
287,11,164,4,0,
287,11,173,2,0,
287,11,174,2,0,
287,11,175,1,49,
287,11,182,4,0,
287,11,185,1,19,
287,11,207,4,0,
287,11,213,4,0,
287,11,214,2,0,
287,11,216,4,0,
287,11,218,4,0,
287,11,227,1,7,
287,11,228,2,0,
287,11,237,4,0,
287,11,240,4,0,
287,11,241,4,0,
287,11,247,4,0,
287,11,249,4,0,
287,11,263,4,0,
287,11,280,4,0,
287,11,281,1,1,2
287,11,303,1,13,
287,11,306,2,0,
287,11,317,4,0,
287,11,321,2,0,
287,11,332,4,0,
287,11,339,4,0,
287,11,343,1,31,
287,11,359,2,0,
287,11,374,4,0,
287,11,400,2,0,
287,11,421,4,0,
287,11,468,4,0,
287,11,495,2,0,
287,11,496,4,0,
287,11,498,1,37,
287,11,510,4,0,
287,11,514,4,0,
287,11,526,4,0,
287,12,10,1,1,1
287,12,15,4,0,
287,12,53,4,0,
287,12,58,4,0,
287,12,59,4,0,
287,12,68,1,37,
287,12,70,4,0,
287,12,76,4,0,
287,12,85,4,0,
287,12,87,4,0,
287,12,92,4,0,
287,12,104,4,0,
287,12,126,4,0,
287,12,133,1,25,
287,12,156,4,0,
287,12,175,1,43,
287,12,182,4,0,
287,12,185,1,19,
287,12,213,4,0,
287,12,216,4,0,
287,12,218,4,0,
287,12,227,1,7,
287,12,237,4,0,
287,12,240,4,0,
287,12,241,4,0,
287,12,247,4,0,
287,12,249,4,0,
287,12,263,4,0,
287,12,264,4,0,
287,12,280,4,0,
287,12,281,1,1,2
287,12,290,4,0,
287,12,303,1,13,
287,12,332,4,0,
287,12,339,4,0,
287,12,343,1,31,
287,12,351,4,0,
287,12,352,4,0,
287,13,10,1,1,1
287,13,15,4,0,
287,13,34,3,0,
287,13,38,3,0,
287,13,53,4,0,
287,13,58,4,0,
287,13,59,4,0,
287,13,68,1,37,
287,13,69,3,0,
287,13,70,4,0,
287,13,76,4,0,
287,13,85,4,0,
287,13,87,4,0,
287,13,92,4,0,
287,13,102,3,0,
287,13,104,4,0,
287,13,126,4,0,
287,13,133,1,25,
287,13,156,4,0,
287,13,164,3,0,
287,13,175,1,43,
287,13,182,4,0,
287,13,185,1,19,
287,13,196,3,0,
287,13,207,3,0,
287,13,213,4,0,
287,13,216,4,0,
287,13,218,4,0,
287,13,227,1,7,
287,13,237,4,0,
287,13,240,4,0,
287,13,241,4,0,
287,13,247,4,0,
287,13,249,4,0,
287,13,263,4,0,
287,13,264,4,0,
287,13,280,4,0,
287,13,281,1,1,2
287,13,290,4,0,
287,13,303,1,13,
287,13,332,4,0,
287,13,339,4,0,
287,13,343,1,31,
287,13,351,4,0,
287,13,352,4,0,
287,14,7,3,0,
287,14,8,3,0,
287,14,9,3,0,
287,14,10,1,1,1
287,14,15,4,0,
287,14,34,2,0,
287,14,53,4,0,
287,14,58,4,0,
287,14,59,4,0,
287,14,68,1,43,
287,14,70,4,0,
287,14,76,4,0,
287,14,85,4,0,
287,14,87,4,0,
287,14,92,4,0,
287,14,104,4,0,
287,14,126,4,0,
287,14,133,1,25,
287,14,156,4,0,
287,14,157,4,0,
287,14,163,2,0,
287,14,164,4,0,
287,14,173,2,0,
287,14,173,3,0,
287,14,174,2,0,
287,14,175,1,49,
287,14,182,4,0,
287,14,185,1,19,
287,14,196,3,0,
287,14,207,4,0,
287,14,213,4,0,
287,14,214,2,0,
287,14,214,3,0,
287,14,216,4,0,
287,14,218,4,0,
287,14,227,1,7,
287,14,228,2,0,
287,14,237,4,0,
287,14,240,4,0,
287,14,241,4,0,
287,14,247,4,0,
287,14,249,4,0,
287,14,263,4,0,
287,14,280,4,0,
287,14,281,1,1,2
287,14,303,1,13,
287,14,306,2,0,
287,14,317,4,0,
287,14,321,2,0,
287,14,332,4,0,
287,14,339,4,0,
287,14,343,1,31,
287,14,343,3,0,
287,14,359,2,0,
287,14,374,4,0,
287,14,400,2,0,
287,14,421,4,0,
287,14,441,3,0,
287,14,468,4,0,
287,14,495,2,0,
287,14,495,3,0,
287,14,496,4,0,
287,14,498,1,37,
287,14,510,4,0,
287,14,514,4,0,
287,14,526,4,0,
287,15,10,1,1,1
287,15,15,4,0,
287,15,34,2,0,
287,15,53,4,0,
287,15,58,4,0,
287,15,59,4,0,
287,15,68,1,43,
287,15,70,4,0,
287,15,76,4,0,
287,15,85,4,0,
287,15,87,4,0,
287,15,92,4,0,
287,15,104,4,0,
287,15,126,4,0,
287,15,133,1,25,
287,15,156,4,0,
287,15,157,4,0,
287,15,163,2,0,
287,15,164,4,0,
287,15,173,2,0,
287,15,174,2,0,
287,15,175,1,49,
287,15,182,4,0,
287,15,185,1,19,
287,15,207,4,0,
287,15,213,4,0,
287,15,214,2,0,
287,15,214,4,0,
287,15,216,4,0,
287,15,218,4,0,
287,15,227,1,7,
287,15,228,2,0,
287,15,237,4,0,
287,15,240,4,0,
287,15,241,4,0,
287,15,247,4,0,
287,15,249,4,0,
287,15,263,4,0,
287,15,280,4,0,
287,15,281,1,1,2
287,15,303,1,13,
287,15,306,2,0,
287,15,317,4,0,
287,15,321,2,0,
287,15,332,4,0,
287,15,339,4,0,
287,15,343,1,31,
287,15,359,2,0,
287,15,374,4,0,
287,15,400,2,0,
287,15,421,4,0,
287,15,468,4,0,
287,15,495,2,0,
287,15,496,4,0,
287,15,498,1,37,
287,15,510,4,0,
287,15,514,4,0,
287,15,590,4,0,
287,15,612,4,0,
287,16,7,3,0,
287,16,8,3,0,
287,16,9,3,0,
287,16,10,1,1,1
287,16,15,4,0,
287,16,34,2,0,
287,16,53,4,0,
287,16,58,4,0,
287,16,59,4,0,
287,16,68,1,30,1
287,16,70,4,0,
287,16,76,4,0,
287,16,85,4,0,
287,16,87,4,0,
287,16,92,4,0,
287,16,104,4,0,
287,16,126,4,0,
287,16,133,1,17,1
287,16,156,4,0,
287,16,157,4,0,
287,16,163,2,0,
287,16,164,4,0,
287,16,173,2,0,
287,16,173,3,0,
287,16,174,2,0,
287,16,175,1,33,1
287,16,182,4,0,
287,16,185,1,14,1
287,16,196,3,0,
287,16,207,4,0,
287,16,213,4,0,
287,16,214,2,0,
287,16,214,4,0,
287,16,216,4,0,
287,16,218,4,0,
287,16,227,1,6,1
287,16,228,2,0,
287,16,237,4,0,
287,16,240,4,0,
287,16,241,4,0,
287,16,247,4,0,
287,16,249,4,0,
287,16,263,4,0,
287,16,264,3,0,
287,16,280,4,0,
287,16,281,1,1,2
287,16,290,4,0,
287,16,303,1,9,1
287,16,306,2,0,
287,16,317,4,0,
287,16,321,2,0,
287,16,332,4,0,
287,16,339,4,0,
287,16,343,1,22,1
287,16,343,3,0,
287,16,351,3,0,
287,16,352,3,0,
287,16,359,2,0,
287,16,374,4,0,
287,16,400,2,0,
287,16,421,4,0,
287,16,441,3,0,
287,16,468,4,0,
287,16,495,2,0,
287,16,495,3,0,
287,16,496,4,0,
287,16,498,1,25,1
287,16,510,4,0,
287,16,514,4,0,
287,16,583,1,38,1
287,16,590,4,0,
287,16,612,4,0,
287,17,10,1,1,1
287,17,34,2,0,
287,17,53,4,0,
287,17,58,4,0,
287,17,59,4,0,
287,17,68,1,30,
287,17,76,4,0,
287,17,85,4,0,
287,17,87,4,0,
287,17,92,4,0,
287,17,104,4,0,
287,17,126,4,0,
287,17,133,1,17,
287,17,156,4,0,
287,17,157,4,0,
287,17,163,2,0,
287,17,164,4,0,
287,17,173,2,0,
287,17,174,2,0,
287,17,175,1,33,
287,17,182,4,0,
287,17,185,1,14,
287,17,207,4,0,
287,17,213,4,0,
287,17,214,2,0,
287,17,214,4,0,
287,17,216,4,0,
287,17,218,4,0,
287,17,227,1,6,
287,17,228,2,0,
287,17,237,4,0,
287,17,240,4,0,
287,17,241,4,0,
287,17,247,4,0,
287,17,263,4,0,
287,17,280,4,0,
287,17,281,1,1,2
287,17,303,1,9,
287,17,306,2,0,
287,17,317,4,0,
287,17,321,2,0,
287,17,332,4,0,
287,17,339,4,0,
287,17,343,1,22,
287,17,359,2,0,
287,17,374,4,0,
287,17,400,2,0,
287,17,421,4,0,
287,17,495,2,0,
287,17,496,4,0,
287,17,498,1,25,
287,17,526,4,0,
287,17,583,1,38,
287,17,590,4,0,
287,18,10,1,1,1
287,18,34,2,0,
287,18,53,4,0,
287,18,58,4,0,
287,18,59,4,0,
287,18,68,1,30,
287,18,76,4,0,
287,18,85,4,0,
287,18,87,4,0,
287,18,92,4,0,
287,18,104,4,0,
287,18,126,4,0,
287,18,133,1,17,
287,18,156,4,0,
287,18,157,4,0,
287,18,163,2,0,
287,18,164,4,0,
287,18,173,2,0,
287,18,174,2,0,
287,18,175,1,33,
287,18,182,4,0,
287,18,185,1,14,
287,18,207,4,0,
287,18,213,4,0,
287,18,214,2,0,
287,18,214,4,0,
287,18,216,4,0,
287,18,218,4,0,
287,18,227,1,6,
287,18,228,2,0,
287,18,237,4,0,
287,18,240,4,0,
287,18,241,4,0,
287,18,247,4,0,
287,18,263,4,0,
287,18,280,4,0,
287,18,281,1,1,2
287,18,303,1,9,
287,18,306,2,0,
287,18,317,4,0,
287,18,321,2,0,
287,18,332,4,0,
287,18,339,4,0,
287,18,343,1,22,
287,18,359,2,0,
287,18,374,4,0,
287,18,400,2,0,
287,18,421,4,0,
287,18,495,2,0,
287,18,496,4,0,
287,18,498,1,25,
287,18,526,4,0,
287,18,583,1,38,
287,18,590,4,0,
288,5,10,1,1,1
288,5,15,4,0,
288,5,46,4,0,
288,5,53,4,0,
288,5,58,4,0,
288,5,59,4,0,
288,5,68,1,37,
288,5,70,4,0,
288,5,76,4,0,
288,5,85,4,0,
288,5,87,4,0,
288,5,89,4,0,
288,5,92,4,0,
288,5,104,4,0,
288,5,116,1,1,2
288,5,126,4,0,
288,5,154,1,19,
288,5,156,4,0,
288,5,163,1,31,
288,5,179,1,49,
288,5,182,4,0,
288,5,203,1,25,
288,5,213,4,0,
288,5,216,4,0,
288,5,218,4,0,
288,5,227,1,1,3
288,5,227,1,7,
288,5,237,4,0,
288,5,240,4,0,
288,5,241,4,0,
288,5,247,4,0,
288,5,249,4,0,
288,5,253,1,1,4
288,5,253,1,13,
288,5,263,4,0,
288,5,264,1,43,
288,5,264,4,0,
288,5,269,4,0,
288,5,280,4,0,
288,5,290,4,0,
288,5,332,4,0,
288,5,339,4,0,
288,5,351,4,0,
288,5,352,4,0,
288,6,5,3,0,
288,6,7,3,0,
288,6,8,3,0,
288,6,9,3,0,
288,6,10,1,1,1
288,6,15,4,0,
288,6,25,3,0,
288,6,34,3,0,
288,6,38,3,0,
288,6,46,4,0,
288,6,53,4,0,
288,6,58,4,0,
288,6,59,4,0,
288,6,68,1,37,
288,6,68,3,0,
288,6,69,3,0,
288,6,70,4,0,
288,6,76,4,0,
288,6,85,4,0,
288,6,87,4,0,
288,6,89,4,0,
288,6,92,4,0,
288,6,102,3,0,
288,6,104,4,0,
288,6,116,1,1,2
288,6,126,4,0,
288,6,154,1,19,
288,6,156,4,0,
288,6,157,3,0,
288,6,163,1,31,
288,6,164,3,0,
288,6,173,3,0,
288,6,179,1,49,
288,6,182,4,0,
288,6,189,3,0,
288,6,196,3,0,
288,6,203,1,25,
288,6,203,3,0,
288,6,207,3,0,
288,6,210,3,0,
288,6,213,4,0,
288,6,214,3,0,
288,6,216,4,0,
288,6,218,4,0,
288,6,223,3,0,
288,6,227,1,1,3
288,6,227,1,7,
288,6,237,4,0,
288,6,240,4,0,
288,6,241,4,0,
288,6,247,4,0,
288,6,249,4,0,
288,6,253,1,1,4
288,6,253,1,13,
288,6,263,4,0,
288,6,264,1,43,
288,6,264,4,0,
288,6,269,4,0,
288,6,280,4,0,
288,6,290,4,0,
288,6,332,4,0,
288,6,339,4,0,
288,6,351,4,0,
288,6,352,4,0,
288,7,5,3,0,
288,7,10,1,1,1
288,7,15,4,0,
288,7,25,3,0,
288,7,34,3,0,
288,7,38,3,0,
288,7,46,4,0,
288,7,53,4,0,
288,7,58,4,0,
288,7,59,4,0,
288,7,68,1,37,
288,7,68,3,0,
288,7,69,3,0,
288,7,70,4,0,
288,7,76,4,0,
288,7,85,4,0,
288,7,87,4,0,
288,7,89,4,0,
288,7,92,4,0,
288,7,102,3,0,
288,7,104,4,0,
288,7,116,1,1,2
288,7,126,4,0,
288,7,154,1,19,
288,7,156,4,0,
288,7,157,3,0,
288,7,163,1,31,
288,7,164,3,0,
288,7,179,1,49,
288,7,182,4,0,
288,7,203,1,25,
288,7,213,4,0,
288,7,216,4,0,
288,7,218,4,0,
288,7,227,1,1,3
288,7,227,1,7,
288,7,237,4,0,
288,7,240,4,0,
288,7,241,4,0,
288,7,247,4,0,
288,7,249,4,0,
288,7,253,1,1,4
288,7,253,1,13,
288,7,263,4,0,
288,7,264,1,43,
288,7,264,4,0,
288,7,269,4,0,
288,7,280,4,0,
288,7,290,4,0,
288,7,332,4,0,
288,7,339,4,0,
288,7,351,4,0,
288,7,352,4,0,
288,8,10,1,1,1
288,8,15,4,0,
288,8,46,4,0,
288,8,53,4,0,
288,8,58,4,0,
288,8,59,4,0,
288,8,68,1,37,
288,8,70,4,0,
288,8,76,4,0,
288,8,85,4,0,
288,8,87,4,0,
288,8,89,4,0,
288,8,92,4,0,
288,8,104,4,0,
288,8,116,1,1,2
288,8,126,4,0,
288,8,154,1,19,
288,8,156,4,0,
288,8,157,4,0,
288,8,163,1,31,
288,8,164,4,0,
288,8,179,1,49,
288,8,182,4,0,
288,8,203,1,25,
288,8,203,4,0,
288,8,207,4,0,
288,8,213,4,0,
288,8,214,4,0,
288,8,216,4,0,
288,8,218,4,0,
288,8,227,1,1,3
288,8,227,1,7,
288,8,237,4,0,
288,8,240,4,0,
288,8,241,4,0,
288,8,247,4,0,
288,8,249,4,0,
288,8,253,1,1,4
288,8,253,1,13,
288,8,263,4,0,
288,8,264,1,43,
288,8,264,4,0,
288,8,269,4,0,
288,8,280,4,0,
288,8,290,4,0,
288,8,317,4,0,
288,8,332,4,0,
288,8,339,4,0,
288,8,351,4,0,
288,8,352,4,0,
288,8,363,4,0,
288,8,374,4,0,
288,8,411,4,0,
288,8,421,4,0,
288,8,431,4,0,
288,8,445,4,0,
288,9,7,3,0,
288,9,8,3,0,
288,9,9,3,0,
288,9,10,1,1,1
288,9,15,4,0,
288,9,46,4,0,
288,9,53,4,0,
288,9,58,4,0,
288,9,59,4,0,
288,9,68,1,37,
288,9,70,4,0,
288,9,76,4,0,
288,9,85,4,0,
288,9,87,4,0,
288,9,89,4,0,
288,9,92,4,0,
288,9,104,4,0,
288,9,116,1,1,2
288,9,126,4,0,
288,9,154,1,19,
288,9,156,4,0,
288,9,157,4,0,
288,9,163,1,31,
288,9,164,4,0,
288,9,179,1,49,
288,9,182,4,0,
288,9,189,3,0,
288,9,196,3,0,
288,9,203,1,25,
288,9,203,4,0,
288,9,207,4,0,
288,9,210,3,0,
288,9,213,4,0,
288,9,214,4,0,
288,9,216,4,0,
288,9,218,4,0,
288,9,227,1,1,3
288,9,227,1,7,
288,9,237,4,0,
288,9,240,4,0,
288,9,241,4,0,
288,9,247,4,0,
288,9,249,4,0,
288,9,253,1,1,4
288,9,253,1,13,
288,9,253,3,0,
288,9,263,4,0,
288,9,264,1,43,
288,9,264,4,0,
288,9,269,4,0,
288,9,280,4,0,
288,9,290,4,0,
288,9,317,4,0,
288,9,332,4,0,
288,9,339,4,0,
288,9,351,4,0,
288,9,352,4,0,
288,9,363,4,0,
288,9,374,4,0,
288,9,389,3,0,
288,9,411,4,0,
288,9,421,4,0,
288,9,431,4,0,
288,9,441,3,0,
288,9,445,4,0,
288,10,7,3,0,
288,10,8,3,0,
288,10,9,3,0,
288,10,10,1,1,1
288,10,15,4,0,
288,10,29,3,0,
288,10,46,4,0,
288,10,53,4,0,
288,10,58,4,0,
288,10,59,4,0,
288,10,67,3,0,
288,10,68,1,37,
288,10,70,4,0,
288,10,76,4,0,
288,10,85,4,0,
288,10,87,4,0,
288,10,89,4,0,
288,10,92,4,0,
288,10,104,4,0,
288,10,116,1,1,2
288,10,126,4,0,
288,10,154,1,19,
288,10,156,4,0,
288,10,157,4,0,
288,10,163,1,31,
288,10,164,4,0,
288,10,179,1,49,
288,10,182,4,0,
288,10,189,3,0,
288,10,196,3,0,
288,10,203,1,25,
288,10,203,4,0,
288,10,207,4,0,
288,10,210,3,0,
288,10,213,4,0,
288,10,214,4,0,
288,10,216,4,0,
288,10,218,4,0,
288,10,227,1,1,3
288,10,227,1,7,
288,10,237,4,0,
288,10,240,4,0,
288,10,241,4,0,
288,10,247,4,0,
288,10,249,4,0,
288,10,253,1,1,4
288,10,253,1,13,
288,10,253,3,0,
288,10,263,4,0,
288,10,264,1,43,
288,10,264,4,0,
288,10,269,4,0,
288,10,280,4,0,
288,10,290,4,0,
288,10,317,4,0,
288,10,332,4,0,
288,10,339,4,0,
288,10,351,4,0,
288,10,352,4,0,
288,10,363,4,0,
288,10,374,4,0,
288,10,389,3,0,
288,10,411,4,0,
288,10,421,4,0,
288,10,431,4,0,
288,10,441,3,0,
288,10,445,4,0,
288,11,10,1,1,1
288,11,15,4,0,
288,11,46,4,0,
288,11,53,4,0,
288,11,58,4,0,
288,11,59,4,0,
288,11,68,1,37,
288,11,70,4,0,
288,11,76,4,0,
288,11,85,4,0,
288,11,87,4,0,
288,11,89,4,0,
288,11,92,4,0,
288,11,104,4,0,
288,11,116,1,1,2
288,11,126,4,0,
288,11,154,1,19,
288,11,156,4,0,
288,11,157,4,0,
288,11,163,1,31,
288,11,164,4,0,
288,11,179,1,55,
288,11,182,4,0,
288,11,203,1,25,
288,11,207,4,0,
288,11,213,4,0,
288,11,216,4,0,
288,11,218,4,0,
288,11,227,1,1,3
288,11,227,1,7,
288,11,237,4,0,
288,11,240,4,0,
288,11,241,4,0,
288,11,247,4,0,
288,11,249,4,0,
288,11,253,1,1,4
288,11,253,1,13,
288,11,263,4,0,
288,11,264,1,49,
288,11,269,4,0,
288,11,280,4,0,
288,11,317,4,0,
288,11,332,4,0,
288,11,339,4,0,
288,11,374,4,0,
288,11,411,4,0,
288,11,421,4,0,
288,11,468,4,0,
288,11,490,4,0,
288,11,496,4,0,
288,11,498,1,43,
288,11,510,4,0,
288,11,514,4,0,
288,11,523,4,0,
288,11,526,4,0,
288,12,10,1,1,1
288,12,15,4,0,
288,12,46,4,0,
288,12,53,4,0,
288,12,58,4,0,
288,12,59,4,0,
288,12,68,1,37,
288,12,70,4,0,
288,12,76,4,0,
288,12,85,4,0,
288,12,87,4,0,
288,12,89,4,0,
288,12,92,4,0,
288,12,104,4,0,
288,12,116,1,1,2
288,12,126,4,0,
288,12,154,1,19,
288,12,156,4,0,
288,12,163,1,31,
288,12,179,1,49,
288,12,182,4,0,
288,12,203,1,25,
288,12,213,4,0,
288,12,216,4,0,
288,12,218,4,0,
288,12,227,1,1,3
288,12,227,1,7,
288,12,237,4,0,
288,12,240,4,0,
288,12,241,4,0,
288,12,247,4,0,
288,12,249,4,0,
288,12,253,1,1,4
288,12,253,1,13,
288,12,263,4,0,
288,12,264,1,43,
288,12,264,4,0,
288,12,269,4,0,
288,12,280,4,0,
288,12,290,4,0,
288,12,332,4,0,
288,12,339,4,0,
288,12,351,4,0,
288,12,352,4,0,
288,13,10,1,1,1
288,13,15,4,0,
288,13,34,3,0,
288,13,38,3,0,
288,13,46,4,0,
288,13,53,4,0,
288,13,58,4,0,
288,13,59,4,0,
288,13,68,1,37,
288,13,69,3,0,
288,13,70,4,0,
288,13,76,4,0,
288,13,85,4,0,
288,13,87,4,0,
288,13,89,4,0,
288,13,92,4,0,
288,13,102,3,0,
288,13,104,4,0,
288,13,116,1,1,2
288,13,126,4,0,
288,13,154,1,19,
288,13,156,4,0,
288,13,163,1,31,
288,13,164,3,0,
288,13,179,1,49,
288,13,182,4,0,
288,13,196,3,0,
288,13,203,1,25,
288,13,207,3,0,
288,13,213,4,0,
288,13,216,4,0,
288,13,218,4,0,
288,13,227,1,1,3
288,13,227,1,7,
288,13,237,4,0,
288,13,240,4,0,
288,13,241,4,0,
288,13,247,4,0,
288,13,249,4,0,
288,13,253,1,1,4
288,13,253,1,13,
288,13,263,4,0,
288,13,264,1,43,
288,13,264,4,0,
288,13,269,4,0,
288,13,280,4,0,
288,13,290,4,0,
288,13,332,4,0,
288,13,339,4,0,
288,13,351,4,0,
288,13,352,4,0,
288,14,7,3,0,
288,14,8,3,0,
288,14,9,3,0,
288,14,10,1,1,1
288,14,15,4,0,
288,14,46,4,0,
288,14,53,4,0,
288,14,58,4,0,
288,14,59,4,0,
288,14,67,3,0,
288,14,68,1,37,
288,14,70,4,0,
288,14,76,4,0,
288,14,85,4,0,
288,14,87,4,0,
288,14,89,4,0,
288,14,92,4,0,
288,14,104,4,0,
288,14,116,1,1,2
288,14,126,4,0,
288,14,154,1,19,
288,14,156,4,0,
288,14,157,4,0,
288,14,163,1,31,
288,14,164,4,0,
288,14,179,1,55,
288,14,182,4,0,
288,14,196,3,0,
288,14,203,1,25,
288,14,207,4,0,
288,14,213,4,0,
288,14,214,3,0,
288,14,216,4,0,
288,14,218,4,0,
288,14,227,1,1,3
288,14,227,1,7,
288,14,237,4,0,
288,14,240,4,0,
288,14,241,4,0,
288,14,247,4,0,
288,14,249,4,0,
288,14,253,1,1,4
288,14,253,1,13,
288,14,253,3,0,
288,14,263,4,0,
288,14,264,1,49,
288,14,269,4,0,
288,14,280,4,0,
288,14,317,4,0,
288,14,332,4,0,
288,14,339,4,0,
288,14,343,3,0,
288,14,374,4,0,
288,14,411,4,0,
288,14,421,4,0,
288,14,441,3,0,
288,14,468,4,0,
288,14,490,4,0,
288,14,495,3,0,
288,14,496,4,0,
288,14,498,1,43,
288,14,510,4,0,
288,14,514,4,0,
288,14,523,4,0,
288,14,526,4,0,
288,15,10,1,1,2
288,15,15,4,0,
288,15,46,4,0,
288,15,53,4,0,
288,15,58,4,0,
288,15,59,4,0,
288,15,68,1,37,
288,15,70,4,0,
288,15,76,4,0,
288,15,85,4,0,
288,15,87,4,0,
288,15,89,4,0,
288,15,92,4,0,
288,15,104,4,0,
288,15,116,1,1,3
288,15,126,4,0,
288,15,154,1,19,
288,15,156,4,0,
288,15,157,4,0,
288,15,163,1,31,
288,15,164,4,0,
288,15,179,1,1,1
288,15,179,1,55,
288,15,182,4,0,
288,15,203,1,25,
288,15,207,4,0,
288,15,213,4,0,
288,15,214,4,0,
288,15,216,4,0,
288,15,218,4,0,
288,15,227,1,1,4
288,15,227,1,7,
288,15,237,4,0,
288,15,240,4,0,
288,15,241,4,0,
288,15,247,4,0,
288,15,249,4,0,
288,15,253,1,1,5
288,15,253,1,13,
288,15,263,4,0,
288,15,264,1,49,
288,15,269,4,0,
288,15,280,4,0,
288,15,317,4,0,
288,15,332,4,0,
288,15,339,4,0,
288,15,374,4,0,
288,15,411,4,0,
288,15,421,4,0,
288,15,468,4,0,
288,15,490,4,0,
288,15,496,4,0,
288,15,498,1,43,
288,15,510,4,0,
288,15,514,4,0,
288,15,523,4,0,
288,15,590,4,0,
288,15,612,4,0,
288,16,7,3,0,
288,16,8,3,0,
288,16,9,3,0,
288,16,10,1,1,2
288,16,15,4,0,
288,16,46,4,0,
288,16,53,4,0,
288,16,58,4,0,
288,16,59,4,0,
288,16,67,3,0,
288,16,68,1,33,1
288,16,70,4,0,
288,16,76,4,0,
288,16,85,4,0,
288,16,87,4,0,
288,16,89,4,0,
288,16,92,4,0,
288,16,104,4,0,
288,16,116,1,1,3
288,16,126,4,0,
288,16,154,1,14,1
288,16,156,4,0,
288,16,157,4,0,
288,16,163,1,23,1
288,16,164,4,0,
288,16,173,3,0,
288,16,179,1,1,1
288,16,179,1,43,1
288,16,182,4,0,
288,16,196,3,0,
288,16,203,1,17,1
288,16,207,4,0,
288,16,213,4,0,
288,16,214,4,0,
288,16,216,4,0,
288,16,218,4,0,
288,16,227,1,1,4
288,16,227,1,6,1
288,16,237,4,0,
288,16,240,4,0,
288,16,241,4,0,
288,16,247,4,0,
288,16,249,4,0,
288,16,253,1,1,5
288,16,253,1,9,1
288,16,253,3,0,
288,16,263,4,0,
288,16,264,1,37,1
288,16,264,3,0,
288,16,269,4,0,
288,16,280,4,0,
288,16,290,4,0,
288,16,317,4,0,
288,16,332,4,0,
288,16,339,4,0,
288,16,343,3,0,
288,16,351,3,0,
288,16,352,3,0,
288,16,374,4,0,
288,16,411,4,0,
288,16,421,4,0,
288,16,441,3,0,
288,16,468,4,0,
288,16,490,4,0,
288,16,495,3,0,
288,16,496,4,0,
288,16,498,1,27,1
288,16,510,4,0,
288,16,514,4,0,
288,16,523,4,0,
288,16,590,4,0,
288,16,612,4,0,
288,17,10,1,1,2
288,17,46,4,0,
288,17,53,4,0,
288,17,58,4,0,
288,17,59,4,0,
288,17,68,1,33,
288,17,76,4,0,
288,17,85,4,0,
288,17,87,4,0,
288,17,89,4,0,
288,17,92,4,0,
288,17,104,4,0,
288,17,116,1,1,3
288,17,126,4,0,
288,17,154,1,14,
288,17,156,4,0,
288,17,157,4,0,
288,17,163,1,23,
288,17,164,4,0,
288,17,179,1,1,1
288,17,179,1,43,
288,17,182,4,0,
288,17,203,1,17,
288,17,207,4,0,
288,17,213,4,0,
288,17,214,4,0,
288,17,216,4,0,
288,17,218,4,0,
288,17,227,1,1,4
288,17,227,1,6,
288,17,237,4,0,
288,17,240,4,0,
288,17,241,4,0,
288,17,247,4,0,
288,17,253,1,1,5
288,17,253,1,9,
288,17,263,4,0,
288,17,264,1,37,
288,17,269,4,0,
288,17,280,4,0,
288,17,317,4,0,
288,17,332,4,0,
288,17,339,4,0,
288,17,374,4,0,
288,17,411,4,0,
288,17,421,4,0,
288,17,490,4,0,
288,17,496,4,0,
288,17,498,1,27,
288,17,523,4,0,
288,17,526,4,0,
288,17,590,4,0,
288,18,10,1,1,2
288,18,46,4,0,
288,18,53,4,0,
288,18,58,4,0,
288,18,59,4,0,
288,18,68,1,33,
288,18,76,4,0,
288,18,85,4,0,
288,18,87,4,0,
288,18,89,4,0,
288,18,92,4,0,
288,18,104,4,0,
288,18,116,1,1,3
288,18,126,4,0,
288,18,154,1,14,
288,18,156,4,0,
288,18,157,4,0,
288,18,163,1,23,
288,18,164,4,0,
288,18,179,1,1,1
288,18,179,1,43,
288,18,182,4,0,
288,18,203,1,17,
288,18,207,4,0,
288,18,213,4,0,
288,18,214,4,0,
288,18,216,4,0,
288,18,218,4,0,
288,18,227,1,1,4
288,18,227,1,6,
288,18,237,4,0,
288,18,240,4,0,
288,18,241,4,0,
288,18,247,4,0,
288,18,253,1,1,5
288,18,253,1,9,
288,18,263,4,0,
288,18,264,1,37,
288,18,269,4,0,
288,18,280,4,0,
288,18,317,4,0,
288,18,332,4,0,
288,18,339,4,0,
288,18,374,4,0,
288,18,411,4,0,
288,18,421,4,0,
288,18,490,4,0,
288,18,496,4,0,
288,18,498,1,27,
288,18,523,4,0,
288,18,526,4,0,
288,18,590,4,0,
289,5,10,1,1,1
289,5,15,4,0,
289,5,46,4,0,
289,5,53,4,0,
289,5,58,4,0,
289,5,59,4,0,
289,5,63,4,0,
289,5,68,1,37,
289,5,70,4,0,
289,5,76,4,0,
289,5,85,4,0,
289,5,87,4,0,
289,5,89,4,0,
289,5,92,4,0,
289,5,104,4,0,
289,5,126,4,0,
289,5,133,1,25,
289,5,156,4,0,
289,5,175,1,43,
289,5,182,4,0,
289,5,185,1,19,
289,5,207,1,36,
289,5,213,4,0,
289,5,216,4,0,
289,5,218,4,0,
289,5,227,1,1,3
289,5,227,1,7,
289,5,237,4,0,
289,5,240,4,0,
289,5,241,4,0,
289,5,247,4,0,
289,5,249,4,0,
289,5,263,4,0,
289,5,264,4,0,
289,5,269,4,0,
289,5,280,4,0,
289,5,281,1,1,2
289,5,290,4,0,
289,5,303,1,1,4
289,5,303,1,13,
289,5,332,4,0,
289,5,339,4,0,
289,5,343,1,31,
289,5,351,4,0,
289,5,352,4,0,
289,6,5,3,0,
289,6,7,3,0,
289,6,8,3,0,
289,6,9,3,0,
289,6,10,1,1,1
289,6,15,4,0,
289,6,25,3,0,
289,6,34,3,0,
289,6,38,3,0,
289,6,46,4,0,
289,6,53,4,0,
289,6,58,4,0,
289,6,59,4,0,
289,6,63,4,0,
289,6,68,1,37,
289,6,68,3,0,
289,6,69,3,0,
289,6,70,4,0,
289,6,76,4,0,
289,6,85,4,0,
289,6,87,4,0,
289,6,89,4,0,
289,6,92,4,0,
289,6,102,3,0,
289,6,104,4,0,
289,6,126,4,0,
289,6,133,1,25,
289,6,156,4,0,
289,6,157,3,0,
289,6,164,3,0,
289,6,173,3,0,
289,6,175,1,43,
289,6,182,4,0,
289,6,185,1,19,
289,6,189,3,0,
289,6,196,3,0,
289,6,203,3,0,
289,6,207,1,36,
289,6,207,3,0,
289,6,210,3,0,
289,6,213,4,0,
289,6,214,3,0,
289,6,216,4,0,
289,6,218,4,0,
289,6,223,3,0,
289,6,227,1,1,3
289,6,227,1,7,
289,6,237,4,0,
289,6,240,4,0,
289,6,241,4,0,
289,6,247,4,0,
289,6,249,4,0,
289,6,263,4,0,
289,6,264,4,0,
289,6,269,4,0,
289,6,280,4,0,
289,6,281,1,1,2
289,6,290,4,0,
289,6,303,1,1,4
289,6,303,1,13,
289,6,332,4,0,
289,6,339,4,0,
289,6,343,1,31,
289,6,351,4,0,
289,6,352,4,0,
289,7,5,3,0,
289,7,10,1,1,1
289,7,15,4,0,
289,7,25,3,0,
289,7,34,3,0,
289,7,38,3,0,
289,7,46,4,0,
289,7,53,4,0,
289,7,58,4,0,
289,7,59,4,0,
289,7,63,4,0,
289,7,68,1,37,
289,7,68,3,0,
289,7,69,3,0,
289,7,70,4,0,
289,7,76,4,0,
289,7,85,4,0,
289,7,87,4,0,
289,7,89,4,0,
289,7,92,4,0,
289,7,102,3,0,
289,7,104,4,0,
289,7,126,4,0,
289,7,133,1,25,
289,7,156,4,0,
289,7,157,3,0,
289,7,164,3,0,
289,7,175,1,43,
289,7,182,4,0,
289,7,185,1,19,
289,7,207,1,36,
289,7,213,4,0,
289,7,216,4,0,
289,7,218,4,0,
289,7,227,1,1,3
289,7,227,1,7,
289,7,237,4,0,
289,7,240,4,0,
289,7,241,4,0,
289,7,247,4,0,
289,7,249,4,0,
289,7,263,4,0,
289,7,264,4,0,
289,7,269,4,0,
289,7,280,4,0,
289,7,281,1,1,2
289,7,290,4,0,
289,7,303,1,1,4
289,7,303,1,13,
289,7,332,4,0,
289,7,339,4,0,
289,7,343,1,31,
289,7,351,4,0,
289,7,352,4,0,
289,8,10,1,1,1
289,8,15,4,0,
289,8,46,4,0,
289,8,53,4,0,
289,8,58,4,0,
289,8,59,4,0,
289,8,63,4,0,
289,8,68,1,37,
289,8,70,4,0,
289,8,76,4,0,
289,8,85,4,0,
289,8,87,4,0,
289,8,89,4,0,
289,8,92,4,0,
289,8,104,4,0,
289,8,126,4,0,
289,8,133,1,25,
289,8,156,4,0,
289,8,157,4,0,
289,8,164,4,0,
289,8,175,1,43,
289,8,182,4,0,
289,8,185,1,19,
289,8,203,4,0,
289,8,207,1,36,
289,8,207,4,0,
289,8,213,4,0,
289,8,214,4,0,
289,8,216,4,0,
289,8,218,4,0,
289,8,227,1,1,3
289,8,227,1,7,
289,8,237,4,0,
289,8,240,4,0,
289,8,241,4,0,
289,8,247,4,0,
289,8,249,4,0,
289,8,263,4,0,
289,8,264,4,0,
289,8,269,4,0,
289,8,280,4,0,
289,8,281,1,1,2
289,8,290,4,0,
289,8,303,1,1,4
289,8,303,1,13,
289,8,317,4,0,
289,8,332,4,0,
289,8,339,4,0,
289,8,343,1,31,
289,8,351,4,0,
289,8,352,4,0,
289,8,359,1,61,
289,8,363,4,0,
289,8,374,1,49,
289,8,374,4,0,
289,8,386,1,55,
289,8,411,4,0,
289,8,416,4,0,
289,8,421,4,0,
289,8,431,4,0,
289,8,445,4,0,
289,9,7,3,0,
289,9,8,3,0,
289,9,9,3,0,
289,9,10,1,1,1
289,9,15,4,0,
289,9,46,4,0,
289,9,53,4,0,
289,9,58,4,0,
289,9,59,4,0,
289,9,63,4,0,
289,9,68,1,37,
289,9,70,4,0,
289,9,76,4,0,
289,9,85,4,0,
289,9,87,4,0,
289,9,89,4,0,
289,9,92,4,0,
289,9,104,4,0,
289,9,126,4,0,
289,9,133,1,25,
289,9,156,4,0,
289,9,157,4,0,
289,9,164,4,0,
289,9,173,3,0,
289,9,175,1,43,
289,9,182,4,0,
289,9,185,1,19,
289,9,189,3,0,
289,9,196,3,0,
289,9,203,4,0,
289,9,207,1,36,
289,9,207,4,0,
289,9,210,3,0,
289,9,213,4,0,
289,9,214,4,0,
289,9,216,4,0,
289,9,218,4,0,
289,9,227,1,1,3
289,9,227,1,7,
289,9,237,4,0,
289,9,240,4,0,
289,9,241,4,0,
289,9,247,4,0,
289,9,249,4,0,
289,9,263,4,0,
289,9,264,4,0,
289,9,269,4,0,
289,9,280,4,0,
289,9,281,1,1,2
289,9,290,4,0,
289,9,303,1,1,4
289,9,303,1,13,
289,9,317,4,0,
289,9,332,4,0,
289,9,339,4,0,
289,9,343,1,31,
289,9,351,4,0,
289,9,352,4,0,
289,9,359,1,61,
289,9,363,4,0,
289,9,374,1,49,
289,9,374,4,0,
289,9,386,1,55,
289,9,389,3,0,
289,9,411,4,0,
289,9,416,4,0,
289,9,421,4,0,
289,9,431,4,0,
289,9,441,3,0,
289,9,445,4,0,
289,10,7,3,0,
289,10,8,3,0,
289,10,9,3,0,
289,10,10,1,1,1
289,10,15,4,0,
289,10,29,3,0,
289,10,46,4,0,
289,10,53,4,0,
289,10,58,4,0,
289,10,59,4,0,
289,10,63,4,0,
289,10,67,3,0,
289,10,68,1,37,
289,10,70,4,0,
289,10,76,4,0,
289,10,85,4,0,
289,10,87,4,0,
289,10,89,4,0,
289,10,92,4,0,
289,10,104,4,0,
289,10,126,4,0,
289,10,133,1,25,
289,10,156,4,0,
289,10,157,4,0,
289,10,164,4,0,
289,10,173,3,0,
289,10,175,1,43,
289,10,182,4,0,
289,10,185,1,19,
289,10,189,3,0,
289,10,196,3,0,
289,10,203,4,0,
289,10,207,1,36,
289,10,207,4,0,
289,10,210,3,0,
289,10,213,4,0,
289,10,214,4,0,
289,10,216,4,0,
289,10,218,4,0,
289,10,227,1,1,3
289,10,227,1,7,
289,10,237,4,0,
289,10,240,4,0,
289,10,241,4,0,
289,10,247,4,0,
289,10,249,4,0,
289,10,263,4,0,
289,10,264,4,0,
289,10,269,4,0,
289,10,280,4,0,
289,10,281,1,1,2
289,10,290,4,0,
289,10,303,1,1,4
289,10,303,1,13,
289,10,317,4,0,
289,10,332,4,0,
289,10,335,3,0,
289,10,339,4,0,
289,10,343,1,31,
289,10,351,4,0,
289,10,352,4,0,
289,10,359,1,61,
289,10,363,4,0,
289,10,374,1,49,
289,10,374,4,0,
289,10,386,1,55,
289,10,389,3,0,
289,10,411,4,0,
289,10,416,4,0,
289,10,421,4,0,
289,10,431,4,0,
289,10,441,3,0,
289,10,445,4,0,
289,11,10,1,1,1
289,11,15,4,0,
289,11,46,4,0,
289,11,53,4,0,
289,11,58,4,0,
289,11,59,4,0,
289,11,63,4,0,
289,11,68,1,43,
289,11,70,4,0,
289,11,76,4,0,
289,11,85,4,0,
289,11,87,4,0,
289,11,89,4,0,
289,11,92,4,0,
289,11,104,4,0,
289,11,126,4,0,
289,11,133,1,25,
289,11,156,4,0,
289,11,157,4,0,
289,11,164,4,0,
289,11,175,1,49,
289,11,182,4,0,
289,11,185,1,19,
289,11,207,1,36,
289,11,207,4,0,
289,11,213,4,0,
289,11,216,4,0,
289,11,218,4,0,
289,11,227,1,1,3
289,11,227,1,7,
289,11,237,4,0,
289,11,240,4,0,
289,11,241,4,0,
289,11,247,4,0,
289,11,249,4,0,
289,11,263,4,0,
289,11,269,4,0,
289,11,280,4,0,
289,11,281,1,1,2
289,11,303,1,1,4
289,11,303,1,13,
289,11,317,4,0,
289,11,332,4,0,
289,11,339,4,0,
289,11,343,1,31,
289,11,359,1,67,
289,11,374,1,55,
289,11,374,4,0,
289,11,386,1,61,
289,11,411,4,0,
289,11,416,4,0,
289,11,421,4,0,
289,11,468,4,0,
289,11,479,4,0,
289,11,490,4,0,
289,11,496,4,0,
289,11,498,1,37,
289,11,510,4,0,
289,11,511,4,0,
289,11,514,4,0,
289,11,523,4,0,
289,11,526,4,0,
289,12,10,1,1,1
289,12,15,4,0,
289,12,46,4,0,
289,12,53,4,0,
289,12,58,4,0,
289,12,59,4,0,
289,12,63,4,0,
289,12,68,1,37,
289,12,70,4,0,
289,12,76,4,0,
289,12,85,4,0,
289,12,87,4,0,
289,12,89,4,0,
289,12,92,4,0,
289,12,104,4,0,
289,12,126,4,0,
289,12,133,1,25,
289,12,156,4,0,
289,12,175,1,43,
289,12,182,4,0,
289,12,185,1,19,
289,12,207,1,36,
289,12,213,4,0,
289,12,216,4,0,
289,12,218,4,0,
289,12,227,1,1,3
289,12,227,1,7,
289,12,237,4,0,
289,12,240,4,0,
289,12,241,4,0,
289,12,247,4,0,
289,12,249,4,0,
289,12,263,4,0,
289,12,264,4,0,
289,12,269,4,0,
289,12,280,4,0,
289,12,281,1,1,2
289,12,290,4,0,
289,12,303,1,1,4
289,12,303,1,13,
289,12,332,4,0,
289,12,339,4,0,
289,12,343,1,31,
289,12,351,4,0,
289,12,352,4,0,
289,13,10,1,1,1
289,13,15,4,0,
289,13,34,3,0,
289,13,38,3,0,
289,13,46,4,0,
289,13,53,4,0,
289,13,58,4,0,
289,13,59,4,0,
289,13,63,4,0,
289,13,68,1,37,
289,13,69,3,0,
289,13,70,4,0,
289,13,76,4,0,
289,13,85,4,0,
289,13,87,4,0,
289,13,89,4,0,
289,13,92,4,0,
289,13,102,3,0,
289,13,104,4,0,
289,13,126,4,0,
289,13,133,1,25,
289,13,156,4,0,
289,13,164,3,0,
289,13,175,1,43,
289,13,182,4,0,
289,13,185,1,19,
289,13,196,3,0,
289,13,207,1,36,
289,13,207,3,0,
289,13,213,4,0,
289,13,216,4,0,
289,13,218,4,0,
289,13,227,1,1,3
289,13,227,1,7,
289,13,237,4,0,
289,13,240,4,0,
289,13,241,4,0,
289,13,247,4,0,
289,13,249,4,0,
289,13,263,4,0,
289,13,264,4,0,
289,13,269,4,0,
289,13,280,4,0,
289,13,281,1,1,2
289,13,290,4,0,
289,13,303,1,1,4
289,13,303,1,13,
289,13,332,4,0,
289,13,339,4,0,
289,13,343,1,31,
289,13,351,4,0,
289,13,352,4,0,
289,14,7,3,0,
289,14,8,3,0,
289,14,9,3,0,
289,14,10,1,1,1
289,14,15,4,0,
289,14,46,4,0,
289,14,53,4,0,
289,14,58,4,0,
289,14,59,4,0,
289,14,63,4,0,
289,14,67,3,0,
289,14,68,1,43,
289,14,70,4,0,
289,14,76,4,0,
289,14,85,4,0,
289,14,87,4,0,
289,14,89,4,0,
289,14,92,4,0,
289,14,104,4,0,
289,14,126,4,0,
289,14,133,1,25,
289,14,156,4,0,
289,14,157,4,0,
289,14,164,4,0,
289,14,173,3,0,
289,14,175,1,49,
289,14,182,4,0,
289,14,185,1,19,
289,14,196,3,0,
289,14,207,1,36,
289,14,207,4,0,
289,14,213,4,0,
289,14,214,3,0,
289,14,216,4,0,
289,14,218,4,0,
289,14,227,1,1,3
289,14,227,1,7,
289,14,237,4,0,
289,14,240,4,0,
289,14,241,4,0,
289,14,247,4,0,
289,14,249,4,0,
289,14,263,4,0,
289,14,269,4,0,
289,14,280,4,0,
289,14,281,1,1,2
289,14,303,1,1,4
289,14,303,1,13,
289,14,317,4,0,
289,14,332,4,0,
289,14,335,3,0,
289,14,339,4,0,
289,14,343,1,31,
289,14,343,3,0,
289,14,359,1,67,
289,14,374,1,55,
289,14,374,4,0,
289,14,386,1,61,
289,14,411,4,0,
289,14,416,4,0,
289,14,421,4,0,
289,14,441,3,0,
289,14,468,4,0,
289,14,479,4,0,
289,14,490,4,0,
289,14,495,3,0,
289,14,496,4,0,
289,14,498,1,37,
289,14,510,4,0,
289,14,511,4,0,
289,14,514,4,0,
289,14,523,4,0,
289,14,526,4,0,
289,15,10,1,1,4
289,15,15,4,0,
289,15,46,4,0,
289,15,53,4,0,
289,15,58,4,0,
289,15,59,4,0,
289,15,63,4,0,
289,15,68,1,43,
289,15,70,4,0,
289,15,76,4,0,
289,15,85,4,0,
289,15,87,4,0,
289,15,89,4,0,
289,15,92,4,0,
289,15,104,4,0,
289,15,126,4,0,
289,15,133,1,25,
289,15,156,4,0,
289,15,157,4,0,
289,15,164,4,0,
289,15,175,1,49,
289,15,182,4,0,
289,15,185,1,19,
289,15,207,1,36,
289,15,207,4,0,
289,15,213,4,0,
289,15,214,4,0,
289,15,216,4,0,
289,15,218,4,0,
289,15,227,1,1,6
289,15,227,1,7,
289,15,237,4,0,
289,15,240,4,0,
289,15,241,4,0,
289,15,247,4,0,
289,15,249,4,0,
289,15,263,4,0,
289,15,269,4,0,
289,15,280,4,0,
289,15,281,1,1,5
289,15,303,1,1,7
289,15,303,1,13,
289,15,317,4,0,
289,15,332,4,0,
289,15,339,4,0,
289,15,343,1,31,
289,15,359,1,1,1
289,15,359,1,67,
289,15,374,1,1,3
289,15,374,1,55,
289,15,374,4,0,
289,15,386,1,1,2
289,15,386,1,61,
289,15,411,4,0,
289,15,416,4,0,
289,15,421,4,0,
289,15,468,4,0,
289,15,479,4,0,
289,15,490,4,0,
289,15,496,4,0,
289,15,498,1,37,
289,15,510,4,0,
289,15,511,4,0,
289,15,514,4,0,
289,15,523,4,0,
289,15,590,4,0,
289,15,612,4,0,
289,16,7,3,0,
289,16,8,3,0,
289,16,9,3,0,
289,16,10,1,1,4
289,16,15,4,0,
289,16,46,4,0,
289,16,53,4,0,
289,16,58,4,0,
289,16,59,4,0,
289,16,63,4,0,
289,16,67,3,0,
289,16,68,1,33,1
289,16,70,4,0,
289,16,76,4,0,
289,16,85,4,0,
289,16,87,4,0,
289,16,89,4,0,
289,16,92,4,0,
289,16,104,4,0,
289,16,126,4,0,
289,16,133,1,17,1
289,16,156,4,0,
289,16,157,4,0,
289,16,164,4,0,
289,16,173,3,0,
289,16,175,1,39,1
289,16,182,4,0,
289,16,185,1,14,1
289,16,196,3,0,
289,16,207,1,36,1
289,16,207,4,0,
289,16,213,4,0,
289,16,214,4,0,
289,16,216,4,0,
289,16,218,4,0,
289,16,227,1,1,6
289,16,227,1,6,1
289,16,237,4,0,
289,16,240,4,0,
289,16,241,4,0,
289,16,247,4,0,
289,16,249,4,0,
289,16,263,4,0,
289,16,264,3,0,
289,16,269,4,0,
289,16,280,4,0,
289,16,281,1,1,5
289,16,290,4,0,
289,16,303,1,1,7
289,16,303,1,9,1
289,16,317,4,0,
289,16,332,4,0,
289,16,335,3,0,
289,16,339,4,0,
289,16,343,1,23,1
289,16,343,3,0,
289,16,351,3,0,
289,16,352,3,0,
289,16,359,1,1,1
289,16,359,1,61,1
289,16,374,1,1,3
289,16,374,1,47,1
289,16,374,4,0,
289,16,386,1,1,2
289,16,386,1,53,1
289,16,411,4,0,
289,16,416,4,0,
289,16,421,4,0,
289,16,441,3,0,
289,16,468,4,0,
289,16,479,4,0,
289,16,490,4,0,
289,16,495,3,0,
289,16,496,4,0,
289,16,498,1,27,1
289,16,510,4,0,
289,16,511,4,0,
289,16,514,4,0,
289,16,523,4,0,
289,16,590,4,0,
289,16,612,4,0,
289,17,10,1,1,5
289,17,46,4,0,
289,17,53,4,0,
289,17,58,4,0,
289,17,59,4,0,
289,17,63,4,0,
289,17,68,1,33,
289,17,76,4,0,
289,17,85,4,0,
289,17,87,4,0,
289,17,89,4,0,
289,17,92,4,0,
289,17,104,4,0,
289,17,126,4,0,
289,17,133,1,17,
289,17,156,4,0,
289,17,157,4,0,
289,17,164,4,0,
289,17,175,1,39,
289,17,182,4,0,
289,17,185,1,14,
289,17,207,1,0,
289,17,207,1,1,1
289,17,207,4,0,
289,17,213,4,0,
289,17,214,4,0,
289,17,216,4,0,
289,17,218,4,0,
289,17,227,1,1,7
289,17,227,1,6,
289,17,237,4,0,
289,17,240,4,0,
289,17,241,4,0,
289,17,247,4,0,
289,17,263,4,0,
289,17,269,4,0,
289,17,280,4,0,
289,17,281,1,1,6
289,17,303,1,1,8
289,17,303,1,9,
289,17,317,4,0,
289,17,332,4,0,
289,17,339,4,0,
289,17,343,1,23,
289,17,359,1,1,2
289,17,359,1,61,
289,17,374,1,1,4
289,17,374,1,47,
289,17,374,4,0,
289,17,386,1,1,3
289,17,386,1,53,
289,17,411,4,0,
289,17,416,4,0,
289,17,421,4,0,
289,17,479,4,0,
289,17,490,4,0,
289,17,496,4,0,
289,17,498,1,27,
289,17,511,4,0,
289,17,523,4,0,
289,17,526,4,0,
289,17,590,4,0,
289,18,10,1,1,5
289,18,46,4,0,
289,18,53,4,0,
289,18,58,4,0,
289,18,59,4,0,
289,18,63,4,0,
289,18,68,1,33,
289,18,76,4,0,
289,18,85,4,0,
289,18,87,4,0,
289,18,89,4,0,
289,18,92,4,0,
289,18,104,4,0,
289,18,126,4,0,
289,18,133,1,17,
289,18,156,4,0,
289,18,157,4,0,
289,18,164,4,0,
289,18,175,1,39,
289,18,182,4,0,
289,18,185,1,14,
289,18,207,1,0,
289,18,207,1,1,1
289,18,207,4,0,
289,18,213,4,0,
289,18,214,4,0,
289,18,216,4,0,
289,18,218,4,0,
289,18,227,1,1,7
289,18,227,1,6,
289,18,237,4,0,
289,18,240,4,0,
289,18,241,4,0,
289,18,247,4,0,
289,18,263,4,0,
289,18,269,4,0,
289,18,280,4,0,
289,18,281,1,1,6
289,18,303,1,1,8
289,18,303,1,9,
289,18,317,4,0,
289,18,332,4,0,
289,18,339,4,0,
289,18,343,1,23,
289,18,359,1,1,2
289,18,359,1,61,
289,18,374,1,1,4
289,18,374,1,47,
289,18,374,4,0,
289,18,386,1,1,3
289,18,386,1,53,
289,18,411,4,0,
289,18,416,4,0,
289,18,421,4,0,
289,18,479,4,0,
289,18,490,4,0,
289,18,496,4,0,
289,18,498,1,27,
289,18,511,4,0,
289,18,523,4,0,
289,18,526,4,0,
289,18,590,4,0,
290,5,10,1,1,1
290,5,15,4,0,
290,5,16,2,0,
290,5,28,1,9,
290,5,76,4,0,
290,5,91,1,45,
290,5,91,4,0,
290,5,92,4,0,
290,5,104,4,0,
290,5,106,1,1,2
290,5,141,1,5,
290,5,148,4,0,
290,5,154,1,14,
290,5,156,4,0,
290,5,170,1,19,
290,5,182,4,0,
290,5,185,2,0,
290,5,189,1,31,
290,5,201,4,0,
290,5,202,4,0,
290,5,203,2,0,
290,5,206,1,25,
290,5,216,4,0,
290,5,218,4,0,
290,5,232,1,38,
290,5,237,4,0,
290,5,241,4,0,
290,5,247,4,0,
290,5,263,4,0,
290,5,290,4,0,
290,5,318,2,0,
290,5,332,4,0,
290,6,10,1,1,1
290,6,15,4,0,
290,6,16,2,0,
290,6,28,1,9,
290,6,38,3,0,
290,6,76,4,0,
290,6,91,1,45,
290,6,91,4,0,
290,6,92,4,0,
290,6,102,3,0,
290,6,104,4,0,
290,6,106,1,1,2
290,6,141,1,5,
290,6,148,4,0,
290,6,154,1,14,
290,6,156,4,0,
290,6,164,3,0,
290,6,170,1,19,
290,6,173,3,0,
290,6,182,4,0,
290,6,185,2,0,
290,6,189,1,31,
290,6,189,3,0,
290,6,201,4,0,
290,6,202,4,0,
290,6,203,2,0,
290,6,203,3,0,
290,6,206,1,25,
290,6,207,3,0,
290,6,210,3,0,
290,6,214,3,0,
290,6,216,4,0,
290,6,218,4,0,
290,6,232,1,38,
290,6,237,4,0,
290,6,241,4,0,
290,6,247,4,0,
290,6,263,4,0,
290,6,290,4,0,
290,6,318,2,0,
290,6,332,4,0,
290,7,10,1,1,1
290,7,15,4,0,
290,7,16,2,0,
290,7,28,1,9,
290,7,38,3,0,
290,7,76,4,0,
290,7,91,1,45,
290,7,91,4,0,
290,7,92,4,0,
290,7,102,3,0,
290,7,104,4,0,
290,7,106,1,1,2
290,7,141,1,5,
290,7,148,4,0,
290,7,154,1,14,
290,7,156,4,0,
290,7,164,3,0,
290,7,170,1,19,
290,7,182,4,0,
290,7,185,2,0,
290,7,189,1,31,
290,7,201,4,0,
290,7,202,4,0,
290,7,203,2,0,
290,7,206,1,25,
290,7,216,4,0,
290,7,218,4,0,
290,7,232,1,38,
290,7,237,4,0,
290,7,241,4,0,
290,7,247,4,0,
290,7,263,4,0,
290,7,290,4,0,
290,7,318,2,0,
290,7,332,4,0,
290,8,10,1,1,1
290,8,15,4,0,
290,8,16,2,0,
290,8,28,1,9,
290,8,76,4,0,
290,8,91,1,45,
290,8,91,4,0,
290,8,92,4,0,
290,8,104,4,0,
290,8,106,1,1,2
290,8,141,1,5,
290,8,148,4,0,
290,8,154,1,14,
290,8,156,4,0,
290,8,164,4,0,
290,8,170,1,19,
290,8,182,4,0,
290,8,185,2,0,
290,8,189,1,31,
290,8,201,4,0,
290,8,202,4,0,
290,8,203,2,0,
290,8,203,4,0,
290,8,206,1,25,
290,8,206,4,0,
290,8,207,4,0,
290,8,214,4,0,
290,8,216,4,0,
290,8,218,4,0,
290,8,232,1,38,
290,8,237,4,0,
290,8,241,4,0,
290,8,247,4,0,
290,8,263,4,0,
290,8,290,4,0,
290,8,318,2,0,
290,8,332,4,0,
290,8,363,4,0,
290,8,400,2,0,
290,8,404,4,0,
290,8,405,2,0,
290,8,450,2,0,
290,9,10,1,1,1
290,9,15,4,0,
290,9,16,2,0,
290,9,28,1,9,
290,9,76,4,0,
290,9,91,1,45,
290,9,91,4,0,
290,9,92,4,0,
290,9,104,4,0,
290,9,106,1,1,2
290,9,141,1,5,
290,9,148,4,0,
290,9,154,1,14,
290,9,156,4,0,
290,9,164,4,0,
290,9,170,1,19,
290,9,173,3,0,
290,9,180,3,0,
290,9,182,4,0,
290,9,185,2,0,
290,9,189,1,31,
290,9,189,3,0,
290,9,201,4,0,
290,9,202,4,0,
290,9,203,2,0,
290,9,203,4,0,
290,9,206,1,25,
290,9,206,4,0,
290,9,207,4,0,
290,9,210,3,0,
290,9,214,4,0,
290,9,216,4,0,
290,9,218,4,0,
290,9,232,1,38,
290,9,237,4,0,
290,9,241,4,0,
290,9,247,4,0,
290,9,263,4,0,
290,9,290,4,0,
290,9,318,2,0,
290,9,332,4,0,
290,9,363,4,0,
290,9,400,2,0,
290,9,404,4,0,
290,9,405,2,0,
290,9,450,2,0,
290,10,10,1,1,1
290,10,15,4,0,
290,10,16,2,0,
290,10,28,1,9,
290,10,76,4,0,
290,10,81,3,0,
290,10,91,1,45,
290,10,91,4,0,
290,10,92,4,0,
290,10,104,4,0,
290,10,106,1,1,2
290,10,141,1,5,
290,10,148,4,0,
290,10,154,1,14,
290,10,156,4,0,
290,10,164,4,0,
290,10,170,1,19,
290,10,173,3,0,
290,10,180,3,0,
290,10,182,4,0,
290,10,185,2,0,
290,10,189,1,31,
290,10,189,3,0,
290,10,201,4,0,
290,10,202,4,0,
290,10,203,2,0,
290,10,203,4,0,
290,10,206,1,25,
290,10,206,4,0,
290,10,207,4,0,
290,10,210,3,0,
290,10,214,4,0,
290,10,216,4,0,
290,10,218,4,0,
290,10,232,1,38,
290,10,237,4,0,
290,10,241,4,0,
290,10,247,4,0,
290,10,263,4,0,
290,10,290,4,0,
290,10,318,2,0,
290,10,332,4,0,
290,10,363,4,0,
290,10,400,2,0,
290,10,404,4,0,
290,10,405,2,0,
290,10,450,2,0,
290,10,450,3,0,
290,11,10,1,1,1
290,11,15,4,0,
290,11,16,2,0,
290,11,28,1,9,
290,11,76,4,0,
290,11,91,1,45,
290,11,91,4,0,
290,11,92,4,0,
290,11,104,4,0,
290,11,106,1,1,2
290,11,141,1,5,
290,11,148,4,0,
290,11,154,1,14,
290,11,156,4,0,
290,11,164,4,0,
290,11,170,1,19,
290,11,182,4,0,
290,11,185,2,0,
290,11,189,1,31,
290,11,201,4,0,
290,11,203,2,0,
290,11,206,1,25,
290,11,206,4,0,
290,11,207,4,0,
290,11,216,4,0,
290,11,218,4,0,
290,11,232,1,38,
290,11,237,4,0,
290,11,241,4,0,
290,11,247,4,0,
290,11,263,4,0,
290,11,318,2,0,
290,11,332,4,0,
290,11,400,2,0,
290,11,404,4,0,
290,11,405,2,0,
290,11,450,2,0,
290,11,468,4,0,
290,11,496,4,0,
290,11,515,2,0,
290,11,522,4,0,
290,12,10,1,1,1
290,12,15,4,0,
290,12,28,1,9,
290,12,76,4,0,
290,12,91,1,45,
290,12,91,4,0,
290,12,92,4,0,
290,12,104,4,0,
290,12,106,1,1,2
290,12,141,1,5,
290,12,148,4,0,
290,12,154,1,14,
290,12,156,4,0,
290,12,170,1,19,
290,12,182,4,0,
290,12,189,1,31,
290,12,201,4,0,
290,12,202,4,0,
290,12,206,1,25,
290,12,216,4,0,
290,12,218,4,0,
290,12,232,1,38,
290,12,237,4,0,
290,12,241,4,0,
290,12,247,4,0,
290,12,263,4,0,
290,12,290,4,0,
290,12,332,4,0,
290,13,10,1,1,1
290,13,15,4,0,
290,13,28,1,9,
290,13,38,3,0,
290,13,76,4,0,
290,13,91,1,45,
290,13,91,4,0,
290,13,92,4,0,
290,13,102,3,0,
290,13,104,4,0,
290,13,106,1,1,2
290,13,141,1,5,
290,13,148,4,0,
290,13,154,1,14,
290,13,156,4,0,
290,13,164,3,0,
290,13,170,1,19,
290,13,182,4,0,
290,13,189,1,31,
290,13,201,4,0,
290,13,202,4,0,
290,13,206,1,25,
290,13,207,3,0,
290,13,216,4,0,
290,13,218,4,0,
290,13,232,1,38,
290,13,237,4,0,
290,13,241,4,0,
290,13,247,4,0,
290,13,263,4,0,
290,13,290,4,0,
290,13,332,4,0,
290,14,10,1,1,1
290,14,15,4,0,
290,14,16,2,0,
290,14,28,1,9,
290,14,76,4,0,
290,14,91,1,45,
290,14,91,4,0,
290,14,92,4,0,
290,14,104,4,0,
290,14,106,1,1,2
290,14,141,1,5,
290,14,148,4,0,
290,14,154,1,14,
290,14,156,4,0,
290,14,164,4,0,
290,14,170,1,19,
290,14,173,3,0,
290,14,180,3,0,
290,14,182,4,0,
290,14,185,2,0,
290,14,189,1,31,
290,14,201,4,0,
290,14,202,3,0,
290,14,203,2,0,
290,14,206,1,25,
290,14,206,4,0,
290,14,207,4,0,
290,14,214,3,0,
290,14,216,4,0,
290,14,218,4,0,
290,14,232,1,38,
290,14,237,4,0,
290,14,241,4,0,
290,14,247,4,0,
290,14,263,4,0,
290,14,318,2,0,
290,14,332,4,0,
290,14,400,2,0,
290,14,404,4,0,
290,14,405,2,0,
290,14,450,2,0,
290,14,450,3,0,
290,14,468,4,0,
290,14,496,4,0,
290,14,515,2,0,
290,14,522,4,0,
290,15,10,1,1,1
290,15,15,4,0,
290,15,16,2,0,
290,15,28,1,9,
290,15,76,4,0,
290,15,91,1,45,
290,15,91,4,0,
290,15,92,4,0,
290,15,104,4,0,
290,15,106,1,1,2
290,15,141,1,5,
290,15,148,4,0,
290,15,154,1,14,
290,15,156,4,0,
290,15,164,4,0,
290,15,170,1,19,
290,15,182,4,0,
290,15,185,2,0,
290,15,189,1,31,
290,15,201,4,0,
290,15,203,2,0,
290,15,206,1,25,
290,15,206,4,0,
290,15,207,4,0,
290,15,214,4,0,
290,15,216,4,0,
290,15,218,4,0,
290,15,232,1,38,
290,15,237,4,0,
290,15,241,4,0,
290,15,247,4,0,
290,15,263,4,0,
290,15,318,2,0,
290,15,332,4,0,
290,15,400,2,0,
290,15,404,4,0,
290,15,405,2,0,
290,15,450,2,0,
290,15,468,4,0,
290,15,496,4,0,
290,15,515,2,0,
290,15,522,4,0,
290,15,590,4,0,
290,16,10,1,1,1
290,16,15,4,0,
290,16,16,2,0,
290,16,28,1,9,1
290,16,76,4,0,
290,16,91,1,37,1
290,16,91,4,0,
290,16,92,4,0,
290,16,104,4,0,
290,16,106,1,1,2
290,16,117,1,29,1
290,16,141,1,5,1
290,16,148,4,0,
290,16,154,1,13,1
290,16,156,4,0,
290,16,164,4,0,
290,16,170,1,25,1
290,16,173,3,0,
290,16,180,3,0,
290,16,182,4,0,
290,16,185,2,0,
290,16,189,1,17,1
290,16,201,4,0,
290,16,202,3,0,
290,16,203,2,0,
290,16,206,1,33,1
290,16,206,4,0,
290,16,207,4,0,
290,16,214,4,0,
290,16,216,4,0,
290,16,218,4,0,
290,16,232,1,21,1
290,16,237,4,0,
290,16,241,4,0,
290,16,247,4,0,
290,16,263,4,0,
290,16,290,4,0,
290,16,318,2,0,
290,16,332,4,0,
290,16,400,2,0,
290,16,404,4,0,
290,16,405,2,0,
290,16,450,2,0,
290,16,450,3,0,
290,16,468,4,0,
290,16,496,4,0,
290,16,515,2,0,
290,16,522,4,0,
290,16,590,4,0,
290,17,10,1,1,1
290,17,16,2,0,
290,17,28,1,9,
290,17,71,1,5,
290,17,76,4,0,
290,17,91,1,37,
290,17,92,4,0,
290,17,104,4,0,
290,17,106,1,1,2
290,17,117,1,29,
290,17,141,4,0,
290,17,154,1,13,
290,17,156,4,0,
290,17,164,4,0,
290,17,170,1,25,
290,17,182,4,0,
290,17,185,2,0,
290,17,189,1,17,
290,17,201,4,0,
290,17,203,2,0,
290,17,206,1,33,
290,17,206,4,0,
290,17,207,4,0,
290,17,214,4,0,
290,17,216,4,0,
290,17,218,4,0,
290,17,232,1,21,
290,17,237,4,0,
290,17,241,4,0,
290,17,247,4,0,
290,17,263,4,0,
290,17,318,2,0,
290,17,332,4,0,
290,17,400,2,0,
290,17,404,4,0,
290,17,405,2,0,
290,17,450,2,0,
290,17,496,4,0,
290,17,515,2,0,
290,17,590,4,0,
290,18,10,1,1,1
290,18,16,2,0,
290,18,28,1,9,
290,18,71,1,5,
290,18,76,4,0,
290,18,91,1,37,
290,18,92,4,0,
290,18,104,4,0,
290,18,106,1,1,2
290,18,117,1,29,
290,18,141,4,0,
290,18,154,1,13,
290,18,156,4,0,
290,18,164,4,0,
290,18,170,1,25,
290,18,182,4,0,
290,18,185,2,0,
290,18,189,1,17,
290,18,201,4,0,
290,18,203,2,0,
290,18,206,1,33,
290,18,206,4,0,
290,18,207,4,0,
290,18,214,4,0,
290,18,216,4,0,
290,18,218,4,0,
290,18,232,1,21,
290,18,237,4,0,
290,18,241,4,0,
290,18,247,4,0,
290,18,263,4,0,
290,18,318,2,0,
290,18,332,4,0,
290,18,400,2,0,
290,18,404,4,0,
290,18,405,2,0,
290,18,450,2,0,
290,18,496,4,0,
290,18,515,2,0,
290,18,590,4,0,
291,5,10,1,1,1
291,5,14,1,25,
291,5,15,4,0,
291,5,28,1,1,4
291,5,28,1,9,
291,5,63,4,0,
291,5,76,4,0,
291,5,91,4,0,
291,5,92,4,0,
291,5,97,1,38,
291,5,103,1,20,6
291,5,104,1,20,
291,5,104,4,0,
291,5,106,1,1,2
291,5,141,1,1,3
291,5,141,1,5,
291,5,148,4,0,
291,5,154,1,14,
291,5,156,4,0,
291,5,163,1,31,
291,5,168,4,0,
291,5,170,1,19,
291,5,182,4,0,
291,5,201,4,0,
291,5,202,4,0,
291,5,210,1,20,5
291,5,213,4,0,
291,5,216,4,0,
291,5,218,4,0,
291,5,226,1,45,
291,5,237,4,0,
291,5,241,4,0,
291,5,247,4,0,
291,5,263,4,0,
291,5,290,4,0,
291,5,332,4,0,
291,6,10,1,1,1
291,6,14,1,25,
291,6,14,3,0,
291,6,15,4,0,
291,6,28,1,1,4
291,6,28,1,9,
291,6,38,3,0,
291,6,63,4,0,
291,6,76,4,0,
291,6,91,4,0,
291,6,92,4,0,
291,6,97,1,38,
291,6,102,3,0,
291,6,103,1,20,6
291,6,104,1,20,
291,6,104,4,0,
291,6,106,1,1,2
291,6,129,3,0,
291,6,141,1,1,3
291,6,141,1,5,
291,6,148,4,0,
291,6,154,1,14,
291,6,156,4,0,
291,6,163,1,31,
291,6,164,3,0,
291,6,168,4,0,
291,6,170,1,19,
291,6,173,3,0,
291,6,182,4,0,
291,6,189,3,0,
291,6,201,4,0,
291,6,202,4,0,
291,6,203,3,0,
291,6,207,3,0,
291,6,210,1,20,5
291,6,210,3,0,
291,6,213,4,0,
291,6,214,3,0,
291,6,216,4,0,
291,6,218,4,0,
291,6,226,1,45,
291,6,237,4,0,
291,6,241,4,0,
291,6,247,4,0,
291,6,263,4,0,
291,6,290,4,0,
291,6,332,4,0,
291,7,10,1,1,1
291,7,14,1,25,
291,7,14,3,0,
291,7,15,4,0,
291,7,28,1,1,4
291,7,28,1,9,
291,7,38,3,0,
291,7,63,4,0,
291,7,76,4,0,
291,7,91,4,0,
291,7,92,4,0,
291,7,97,1,38,
291,7,102,3,0,
291,7,103,1,20,6
291,7,104,1,20,
291,7,104,4,0,
291,7,106,1,1,2
291,7,141,1,1,3
291,7,141,1,5,
291,7,148,4,0,
291,7,154,1,14,
291,7,156,4,0,
291,7,163,1,31,
291,7,164,3,0,
291,7,168,4,0,
291,7,170,1,19,
291,7,182,4,0,
291,7,201,4,0,
291,7,202,4,0,
291,7,210,1,20,5
291,7,213,4,0,
291,7,216,4,0,
291,7,218,4,0,
291,7,226,1,45,
291,7,237,4,0,
291,7,241,4,0,
291,7,247,4,0,
291,7,263,4,0,
291,7,290,4,0,
291,7,332,4,0,
291,8,10,1,1,2
291,8,14,1,25,
291,8,14,4,0,
291,8,15,4,0,
291,8,28,1,1,5
291,8,28,1,9,
291,8,63,4,0,
291,8,76,4,0,
291,8,91,4,0,
291,8,92,4,0,
291,8,97,1,38,
291,8,103,1,20,3
291,8,104,1,20,1
291,8,104,4,0,
291,8,106,1,1,3
291,8,141,1,1,4
291,8,141,1,5,
291,8,148,4,0,
291,8,154,1,14,
291,8,156,4,0,
291,8,163,1,31,
291,8,164,4,0,
291,8,168,4,0,
291,8,170,1,19,
291,8,182,4,0,
291,8,201,4,0,
291,8,202,4,0,
291,8,203,4,0,
291,8,206,4,0,
291,8,207,4,0,
291,8,210,1,20,2
291,8,213,4,0,
291,8,214,4,0,
291,8,216,4,0,
291,8,218,4,0,
291,8,226,1,45,
291,8,237,4,0,
291,8,241,4,0,
291,8,247,4,0,
291,8,263,4,0,
291,8,290,4,0,
291,8,318,4,0,
291,8,332,4,0,
291,8,355,4,0,
291,8,363,4,0,
291,8,369,4,0,
291,8,404,1,52,
291,8,404,4,0,
291,8,416,4,0,
291,8,432,4,0,
291,8,445,4,0,
291,8,450,1,1,1
291,9,10,1,1,2
291,9,14,1,25,
291,9,14,4,0,
291,9,15,4,0,
291,9,28,1,1,5
291,9,28,1,9,
291,9,63,4,0,
291,9,76,4,0,
291,9,91,4,0,
291,9,92,4,0,
291,9,97,1,38,
291,9,103,1,20,3
291,9,104,1,20,1
291,9,104,4,0,
291,9,106,1,1,3
291,9,129,3,0,
291,9,141,1,1,4
291,9,141,1,5,
291,9,148,4,0,
291,9,154,1,14,
291,9,156,4,0,
291,9,163,1,31,
291,9,164,4,0,
291,9,168,4,0,
291,9,170,1,19,
291,9,173,3,0,
291,9,180,3,0,
291,9,182,4,0,
291,9,189,3,0,
291,9,201,4,0,
291,9,202,4,0,
291,9,203,4,0,
291,9,206,4,0,
291,9,207,4,0,
291,9,210,1,20,2
291,9,210,3,0,
291,9,213,4,0,
291,9,214,4,0,
291,9,216,4,0,
291,9,218,4,0,
291,9,226,1,45,
291,9,237,4,0,
291,9,241,4,0,
291,9,247,4,0,
291,9,253,3,0,
291,9,263,4,0,
291,9,290,4,0,
291,9,314,3,0,
291,9,318,4,0,
291,9,332,4,0,
291,9,355,4,0,
291,9,363,4,0,
291,9,369,4,0,
291,9,404,1,52,
291,9,404,4,0,
291,9,416,4,0,
291,9,432,4,0,
291,9,445,4,0,
291,9,450,1,1,1
291,9,466,3,0,
291,10,10,1,1,2
291,10,14,1,25,
291,10,14,4,0,
291,10,15,4,0,
291,10,28,1,1,5
291,10,28,1,9,
291,10,63,4,0,
291,10,76,4,0,
291,10,81,3,0,
291,10,91,4,0,
291,10,92,4,0,
291,10,97,1,38,
291,10,103,1,20,3
291,10,104,1,20,1
291,10,104,4,0,
291,10,106,1,1,3
291,10,129,3,0,
291,10,141,1,1,4
291,10,141,1,5,
291,10,148,4,0,
291,10,154,1,14,
291,10,156,4,0,
291,10,163,1,31,
291,10,164,4,0,
291,10,168,4,0,
291,10,170,1,19,
291,10,173,3,0,
291,10,180,3,0,
291,10,182,4,0,
291,10,189,3,0,
291,10,201,4,0,
291,10,202,4,0,
291,10,203,4,0,
291,10,206,4,0,
291,10,207,4,0,
291,10,210,1,20,2
291,10,210,3,0,
291,10,213,4,0,
291,10,214,4,0,
291,10,216,4,0,
291,10,218,4,0,
291,10,226,1,45,
291,10,237,4,0,
291,10,241,4,0,
291,10,247,4,0,
291,10,253,3,0,
291,10,263,4,0,
291,10,290,4,0,
291,10,314,3,0,
291,10,318,4,0,
291,10,332,4,0,
291,10,355,4,0,
291,10,363,4,0,
291,10,369,4,0,
291,10,404,1,52,
291,10,404,4,0,
291,10,416,4,0,
291,10,445,4,0,
291,10,450,1,1,1
291,10,450,3,0,
291,10,466,3,0,
291,11,10,1,1,2
291,11,14,1,25,
291,11,14,4,0,
291,11,15,4,0,
291,11,28,1,1,5
291,11,28,1,9,
291,11,63,4,0,
291,11,76,4,0,
291,11,91,4,0,
291,11,92,4,0,
291,11,97,1,38,
291,11,103,1,20,3
291,11,104,1,20,1
291,11,104,4,0,
291,11,106,1,1,3
291,11,141,1,1,4
291,11,141,1,5,
291,11,148,4,0,
291,11,154,1,14,
291,11,156,4,0,
291,11,163,1,31,
291,11,164,4,0,
291,11,168,4,0,
291,11,170,1,19,
291,11,182,4,0,
291,11,201,4,0,
291,11,206,4,0,
291,11,207,4,0,
291,11,210,1,20,2
291,11,213,4,0,
291,11,216,4,0,
291,11,218,4,0,
291,11,226,1,45,
291,11,237,4,0,
291,11,241,4,0,
291,11,247,4,0,
291,11,263,4,0,
291,11,332,4,0,
291,11,369,4,0,
291,11,404,1,52,
291,11,404,4,0,
291,11,416,4,0,
291,11,450,1,1,1
291,11,468,4,0,
291,11,496,4,0,
291,11,522,4,0,
291,12,10,1,1,1
291,12,14,1,25,
291,12,15,4,0,
291,12,28,1,1,4
291,12,28,1,9,
291,12,63,4,0,
291,12,76,4,0,
291,12,91,4,0,
291,12,92,4,0,
291,12,97,1,38,
291,12,103,1,20,6
291,12,104,1,20,
291,12,104,4,0,
291,12,106,1,1,2
291,12,141,1,1,3
291,12,141,1,5,
291,12,148,4,0,
291,12,154,1,14,
291,12,156,4,0,
291,12,163,1,31,
291,12,168,4,0,
291,12,170,1,19,
291,12,182,4,0,
291,12,201,4,0,
291,12,202,4,0,
291,12,210,1,20,5
291,12,213,4,0,
291,12,216,4,0,
291,12,218,4,0,
291,12,226,1,45,
291,12,237,4,0,
291,12,241,4,0,
291,12,247,4,0,
291,12,263,4,0,
291,12,290,4,0,
291,12,332,4,0,
291,13,10,1,1,1
291,13,14,1,25,
291,13,15,4,0,
291,13,28,1,1,4
291,13,28,1,9,
291,13,38,3,0,
291,13,63,4,0,
291,13,76,4,0,
291,13,91,4,0,
291,13,92,4,0,
291,13,97,1,38,
291,13,102,3,0,
291,13,103,1,20,6
291,13,104,1,20,
291,13,104,4,0,
291,13,106,1,1,2
291,13,141,1,1,3
291,13,141,1,5,
291,13,148,4,0,
291,13,154,1,14,
291,13,156,4,0,
291,13,163,1,31,
291,13,164,3,0,
291,13,168,4,0,
291,13,170,1,19,
291,13,182,4,0,
291,13,201,4,0,
291,13,202,4,0,
291,13,207,3,0,
291,13,210,1,20,5
291,13,213,4,0,
291,13,216,4,0,
291,13,218,4,0,
291,13,226,1,45,
291,13,237,4,0,
291,13,241,4,0,
291,13,247,4,0,
291,13,263,4,0,
291,13,290,4,0,
291,13,332,4,0,
291,14,10,1,1,2
291,14,14,1,25,
291,14,14,4,0,
291,14,15,4,0,
291,14,28,1,1,5
291,14,28,1,9,
291,14,63,4,0,
291,14,76,4,0,
291,14,91,4,0,
291,14,92,4,0,
291,14,97,1,38,
291,14,103,1,20,3
291,14,104,1,20,1
291,14,104,4,0,
291,14,106,1,1,3
291,14,141,1,1,4
291,14,141,1,5,
291,14,148,4,0,
291,14,154,1,14,
291,14,156,4,0,
291,14,163,1,31,
291,14,164,4,0,
291,14,168,4,0,
291,14,170,1,19,
291,14,173,3,0,
291,14,180,3,0,
291,14,182,4,0,
291,14,201,4,0,
291,14,202,3,0,
291,14,206,4,0,
291,14,207,4,0,
291,14,210,1,20,2
291,14,213,4,0,
291,14,214,3,0,
291,14,216,4,0,
291,14,218,4,0,
291,14,226,1,45,
291,14,237,4,0,
291,14,241,4,0,
291,14,247,4,0,
291,14,253,3,0,
291,14,263,4,0,
291,14,332,4,0,
291,14,355,3,0,
291,14,369,4,0,
291,14,404,1,52,
291,14,404,4,0,
291,14,416,4,0,
291,14,450,1,1,1
291,14,450,3,0,
291,14,468,4,0,
291,14,496,4,0,
291,14,522,4,0,
291,15,10,1,1,2
291,15,14,1,25,
291,15,14,4,0,
291,15,15,4,0,
291,15,28,1,1,5
291,15,28,1,9,
291,15,63,4,0,
291,15,76,4,0,
291,15,91,4,0,
291,15,92,4,0,
291,15,97,1,38,
291,15,103,1,20,3
291,15,104,1,20,1
291,15,104,4,0,
291,15,106,1,1,3
291,15,141,1,1,4
291,15,141,1,5,
291,15,148,4,0,
291,15,154,1,14,
291,15,156,4,0,
291,15,163,1,31,
291,15,164,4,0,
291,15,168,4,0,
291,15,170,1,19,
291,15,182,4,0,
291,15,201,4,0,
291,15,206,4,0,
291,15,207,4,0,
291,15,210,1,20,2
291,15,213,4,0,
291,15,214,4,0,
291,15,216,4,0,
291,15,218,4,0,
291,15,226,1,45,
291,15,237,4,0,
291,15,241,4,0,
291,15,247,4,0,
291,15,263,4,0,
291,15,332,4,0,
291,15,355,4,0,
291,15,369,4,0,
291,15,404,1,52,
291,15,404,4,0,
291,15,416,4,0,
291,15,450,1,1,1
291,15,468,4,0,
291,15,496,4,0,
291,15,522,4,0,
291,15,590,4,0,
291,16,10,1,1,2
291,16,14,1,41,1
291,16,14,4,0,
291,16,15,4,0,
291,16,28,1,1,5
291,16,28,1,9,1
291,16,63,4,0,
291,16,76,4,0,
291,16,91,4,0,
291,16,92,4,0,
291,16,97,1,17,1
291,16,103,1,20,3
291,16,104,1,20,1
291,16,104,4,0,
291,16,106,1,1,3
291,16,141,1,1,4
291,16,141,1,5,1
291,16,148,4,0,
291,16,154,1,13,1
291,16,156,4,0,
291,16,163,1,23,1
291,16,164,4,0,
291,16,168,4,0,
291,16,170,1,29,1
291,16,173,3,0,
291,16,180,3,0,
291,16,182,4,0,
291,16,201,4,0,
291,16,202,3,0,
291,16,206,4,0,
291,16,207,4,0,
291,16,210,1,20,2
291,16,213,4,0,
291,16,214,4,0,
291,16,216,4,0,
291,16,218,4,0,
291,16,226,1,35,1
291,16,237,4,0,
291,16,241,4,0,
291,16,247,4,0,
291,16,253,3,0,
291,16,263,4,0,
291,16,290,4,0,
291,16,332,4,0,
291,16,355,4,0,
291,16,369,4,0,
291,16,404,1,47,1
291,16,404,4,0,
291,16,416,4,0,
291,16,450,1,1,1
291,16,450,3,0,
291,16,468,4,0,
291,16,496,4,0,
291,16,522,4,0,
291,16,590,4,0,
291,17,10,1,1,5
291,17,14,1,41,
291,17,14,4,0,
291,17,28,1,1,8
291,17,28,1,9,
291,17,63,4,0,
291,17,71,1,1,7
291,17,71,1,5,
291,17,76,4,0,
291,17,92,4,0,
291,17,97,1,17,
291,17,103,1,0,
291,17,103,1,1,2
291,17,104,1,0,
291,17,104,1,1,1
291,17,104,4,0,
291,17,106,1,1,6
291,17,141,4,0,
291,17,154,1,13,
291,17,156,4,0,
291,17,163,1,23,
291,17,164,4,0,
291,17,168,4,0,
291,17,170,1,29,
291,17,182,4,0,
291,17,201,4,0,
291,17,206,4,0,
291,17,207,4,0,
291,17,210,1,0,
291,17,210,1,1,3
291,17,213,4,0,
291,17,214,4,0,
291,17,216,4,0,
291,17,218,4,0,
291,17,226,1,35,
291,17,237,4,0,
291,17,241,4,0,
291,17,247,4,0,
291,17,263,4,0,
291,17,332,4,0,
291,17,355,4,0,
291,17,369,4,0,
291,17,404,1,47,
291,17,404,4,0,
291,17,416,4,0,
291,17,450,1,1,4
291,17,496,4,0,
291,17,590,4,0,
291,18,10,1,1,5
291,18,14,1,41,
291,18,14,4,0,
291,18,28,1,1,8
291,18,28,1,9,
291,18,63,4,0,
291,18,71,1,1,7
291,18,71,1,5,
291,18,76,4,0,
291,18,92,4,0,
291,18,97,1,17,
291,18,103,1,0,
291,18,103,1,1,2
291,18,104,1,0,
291,18,104,1,1,1
291,18,104,4,0,
291,18,106,1,1,6
291,18,141,4,0,
291,18,154,1,13,
291,18,156,4,0,
291,18,163,1,23,
291,18,164,4,0,
291,18,168,4,0,
291,18,170,1,29,
291,18,182,4,0,
291,18,201,4,0,
291,18,206,4,0,
291,18,207,4,0,
291,18,210,1,0,
291,18,210,1,1,3
291,18,213,4,0,
291,18,214,4,0,
291,18,216,4,0,
291,18,218,4,0,
291,18,226,1,35,
291,18,237,4,0,
291,18,241,4,0,
291,18,247,4,0,
291,18,263,4,0,
291,18,332,4,0,
291,18,355,4,0,
291,18,369,4,0,
291,18,404,1,47,
291,18,404,4,0,
291,18,416,4,0,
291,18,450,1,1,4
291,18,496,4,0,
291,18,590,4,0,
292,5,10,1,1,1
292,5,15,4,0,
292,5,28,1,9,
292,5,63,4,0,
292,5,76,4,0,
292,5,91,4,0,
292,5,92,4,0,
292,5,104,4,0,
292,5,106,1,1,2
292,5,109,1,31,
292,5,141,1,5,
292,5,148,4,0,
292,5,154,1,14,
292,5,156,4,0,
292,5,168,4,0,
292,5,170,1,19,
292,5,180,1,25,
292,5,182,4,0,
292,5,201,4,0,
292,5,202,4,0,
292,5,216,4,0,
292,5,218,4,0,
292,5,237,4,0,
292,5,241,4,0,
292,5,247,1,38,
292,5,247,4,0,
292,5,263,4,0,
292,5,288,1,45,
292,5,290,4,0,
292,5,332,4,0,
292,6,10,1,1,1
292,6,15,4,0,
292,6,28,1,9,
292,6,38,3,0,
292,6,63,4,0,
292,6,76,4,0,
292,6,91,4,0,
292,6,92,4,0,
292,6,102,3,0,
292,6,104,4,0,
292,6,106,1,1,2
292,6,109,1,31,
292,6,138,3,0,
292,6,141,1,5,
292,6,148,4,0,
292,6,154,1,14,
292,6,156,4,0,
292,6,164,3,0,
292,6,168,4,0,
292,6,170,1,19,
292,6,173,3,0,
292,6,180,1,25,
292,6,182,4,0,
292,6,189,3,0,
292,6,201,4,0,
292,6,202,4,0,
292,6,203,3,0,
292,6,207,3,0,
292,6,210,3,0,
292,6,214,3,0,
292,6,216,4,0,
292,6,218,4,0,
292,6,237,4,0,
292,6,241,4,0,
292,6,247,1,38,
292,6,247,4,0,
292,6,263,4,0,
292,6,288,1,45,
292,6,290,4,0,
292,6,332,4,0,
292,7,10,1,1,1
292,7,15,4,0,
292,7,28,1,9,
292,7,38,3,0,
292,7,63,4,0,
292,7,76,4,0,
292,7,91,4,0,
292,7,92,4,0,
292,7,102,3,0,
292,7,104,4,0,
292,7,106,1,1,2
292,7,109,1,31,
292,7,138,3,0,
292,7,141,1,5,
292,7,148,4,0,
292,7,154,1,14,
292,7,156,4,0,
292,7,164,3,0,
292,7,168,4,0,
292,7,170,1,19,
292,7,180,1,25,
292,7,182,4,0,
292,7,201,4,0,
292,7,202,4,0,
292,7,216,4,0,
292,7,218,4,0,
292,7,237,4,0,
292,7,241,4,0,
292,7,247,1,38,
292,7,247,4,0,
292,7,263,4,0,
292,7,288,1,45,
292,7,290,4,0,
292,7,332,4,0,
292,8,10,1,1,1
292,8,15,4,0,
292,8,28,1,9,
292,8,63,4,0,
292,8,76,4,0,
292,8,91,4,0,
292,8,92,4,0,
292,8,104,4,0,
292,8,106,1,1,2
292,8,109,1,31,
292,8,138,4,0,
292,8,141,1,5,
292,8,148,4,0,
292,8,154,1,14,
292,8,156,4,0,
292,8,164,4,0,
292,8,168,4,0,
292,8,170,1,19,
292,8,180,1,25,
292,8,182,4,0,
292,8,201,4,0,
292,8,202,4,0,
292,8,203,4,0,
292,8,206,4,0,
292,8,207,4,0,
292,8,214,4,0,
292,8,216,4,0,
292,8,218,4,0,
292,8,237,4,0,
292,8,241,4,0,
292,8,247,1,59,
292,8,247,4,0,
292,8,261,4,0,
292,8,263,4,0,
292,8,288,1,45,
292,8,290,4,0,
292,8,332,4,0,
292,8,363,4,0,
292,8,377,1,52,
292,8,404,4,0,
292,8,416,4,0,
292,8,421,4,0,
292,8,425,1,38,
292,9,10,1,1,1
292,9,15,4,0,
292,9,28,1,9,
292,9,63,4,0,
292,9,76,4,0,
292,9,91,4,0,
292,9,92,4,0,
292,9,104,4,0,
292,9,106,1,1,2
292,9,109,1,31,
292,9,138,4,0,
292,9,141,1,5,
292,9,148,4,0,
292,9,154,1,14,
292,9,156,4,0,
292,9,164,4,0,
292,9,168,4,0,
292,9,170,1,19,
292,9,173,3,0,
292,9,180,1,25,
292,9,180,3,0,
292,9,182,4,0,
292,9,189,3,0,
292,9,201,4,0,
292,9,202,4,0,
292,9,203,4,0,
292,9,206,4,0,
292,9,207,4,0,
292,9,210,3,0,
292,9,214,4,0,
292,9,216,4,0,
292,9,218,4,0,
292,9,237,4,0,
292,9,241,4,0,
292,9,247,1,59,
292,9,247,4,0,
292,9,261,4,0,
292,9,263,4,0,
292,9,271,3,0,
292,9,288,1,45,
292,9,290,4,0,
292,9,332,4,0,
292,9,363,4,0,
292,9,377,1,52,
292,9,389,3,0,
292,9,404,4,0,
292,9,416,4,0,
292,9,421,4,0,
292,9,425,1,38,
292,10,10,1,1,1
292,10,15,4,0,
292,10,28,1,9,
292,10,63,4,0,
292,10,76,4,0,
292,10,81,3,0,
292,10,91,4,0,
292,10,92,4,0,
292,10,104,4,0,
292,10,106,1,1,2
292,10,109,1,31,
292,10,138,4,0,
292,10,141,1,5,
292,10,148,4,0,
292,10,154,1,14,
292,10,156,4,0,
292,10,164,4,0,
292,10,168,4,0,
292,10,170,1,19,
292,10,173,3,0,
292,10,180,1,25,
292,10,180,3,0,
292,10,182,4,0,
292,10,189,3,0,
292,10,201,4,0,
292,10,202,4,0,
292,10,203,4,0,
292,10,206,4,0,
292,10,207,4,0,
292,10,210,3,0,
292,10,214,4,0,
292,10,216,4,0,
292,10,218,4,0,
292,10,237,4,0,
292,10,241,4,0,
292,10,247,1,59,
292,10,247,4,0,
292,10,261,4,0,
292,10,263,4,0,
292,10,271,3,0,
292,10,288,1,45,
292,10,290,4,0,
292,10,332,4,0,
292,10,363,4,0,
292,10,377,1,52,
292,10,389,3,0,
292,10,404,4,0,
292,10,416,4,0,
292,10,421,4,0,
292,10,425,1,38,
292,10,450,3,0,
292,11,10,1,1,1
292,11,15,4,0,
292,11,28,1,9,
292,11,63,4,0,
292,11,76,4,0,
292,11,91,4,0,
292,11,92,4,0,
292,11,104,4,0,
292,11,106,1,1,2
292,11,109,1,31,
292,11,138,4,0,
292,11,141,1,5,
292,11,148,4,0,
292,11,154,1,14,
292,11,156,4,0,
292,11,164,4,0,
292,11,168,4,0,
292,11,170,1,19,
292,11,180,1,25,
292,11,182,4,0,
292,11,201,4,0,
292,11,206,4,0,
292,11,207,4,0,
292,11,216,4,0,
292,11,218,4,0,
292,11,237,4,0,
292,11,241,4,0,
292,11,247,1,59,
292,11,247,4,0,
292,11,261,4,0,
292,11,263,4,0,
292,11,288,1,45,
292,11,332,4,0,
292,11,377,1,52,
292,11,404,4,0,
292,11,416,4,0,
292,11,421,4,0,
292,11,425,1,38,
292,11,468,4,0,
292,11,477,4,0,
292,11,496,4,0,
292,11,522,4,0,
292,12,10,1,1,1
292,12,15,4,0,
292,12,28,1,9,
292,12,63,4,0,
292,12,76,4,0,
292,12,91,4,0,
292,12,92,4,0,
292,12,104,4,0,
292,12,106,1,1,2
292,12,109,1,31,
292,12,141,1,5,
292,12,148,4,0,
292,12,154,1,14,
292,12,156,4,0,
292,12,168,4,0,
292,12,170,1,19,
292,12,180,1,25,
292,12,182,4,0,
292,12,201,4,0,
292,12,202,4,0,
292,12,216,4,0,
292,12,218,4,0,
292,12,237,4,0,
292,12,241,4,0,
292,12,247,1,38,
292,12,247,4,0,
292,12,263,4,0,
292,12,288,1,45,
292,12,290,4,0,
292,12,332,4,0,
292,13,10,1,1,1
292,13,15,4,0,
292,13,28,1,9,
292,13,38,3,0,
292,13,63,4,0,
292,13,76,4,0,
292,13,91,4,0,
292,13,92,4,0,
292,13,102,3,0,
292,13,104,4,0,
292,13,106,1,1,2
292,13,109,1,31,
292,13,138,3,0,
292,13,141,1,5,
292,13,148,4,0,
292,13,154,1,14,
292,13,156,4,0,
292,13,164,3,0,
292,13,168,4,0,
292,13,170,1,19,
292,13,171,3,0,
292,13,180,1,25,
292,13,182,4,0,
292,13,201,4,0,
292,13,202,4,0,
292,13,207,3,0,
292,13,216,4,0,
292,13,218,4,0,
292,13,237,4,0,
292,13,241,4,0,
292,13,247,1,38,
292,13,247,4,0,
292,13,263,4,0,
292,13,288,1,45,
292,13,290,4,0,
292,13,332,4,0,
292,14,10,1,1,1
292,14,15,4,0,
292,14,28,1,9,
292,14,63,4,0,
292,14,76,4,0,
292,14,91,4,0,
292,14,92,4,0,
292,14,104,4,0,
292,14,106,1,1,2
292,14,109,1,31,
292,14,138,4,0,
292,14,141,1,5,
292,14,148,4,0,
292,14,154,1,14,
292,14,156,4,0,
292,14,164,4,0,
292,14,168,4,0,
292,14,170,1,19,
292,14,173,3,0,
292,14,180,1,25,
292,14,180,3,0,
292,14,182,4,0,
292,14,201,4,0,
292,14,202,3,0,
292,14,206,4,0,
292,14,207,4,0,
292,14,214,3,0,
292,14,216,4,0,
292,14,218,4,0,
292,14,237,4,0,
292,14,241,4,0,
292,14,247,1,59,
292,14,247,4,0,
292,14,261,4,0,
292,14,263,4,0,
292,14,271,3,0,
292,14,288,1,45,
292,14,332,4,0,
292,14,377,1,52,
292,14,404,4,0,
292,14,416,4,0,
292,14,421,4,0,
292,14,425,1,38,
292,14,450,3,0,
292,14,468,4,0,
292,14,477,4,0,
292,14,496,4,0,
292,14,522,4,0,
292,15,10,1,1,1
292,15,15,4,0,
292,15,28,1,9,
292,15,63,4,0,
292,15,76,4,0,
292,15,91,4,0,
292,15,92,4,0,
292,15,104,4,0,
292,15,106,1,1,2
292,15,109,1,31,
292,15,138,4,0,
292,15,141,1,5,
292,15,148,4,0,
292,15,154,1,14,
292,15,156,4,0,
292,15,164,4,0,
292,15,168,4,0,
292,15,170,1,19,
292,15,180,1,25,
292,15,182,4,0,
292,15,201,4,0,
292,15,206,4,0,
292,15,207,4,0,
292,15,214,4,0,
292,15,216,4,0,
292,15,218,4,0,
292,15,237,4,0,
292,15,241,4,0,
292,15,247,1,59,
292,15,247,4,0,
292,15,261,4,0,
292,15,263,4,0,
292,15,288,1,43,
292,15,332,4,0,
292,15,377,1,50,
292,15,404,4,0,
292,15,416,4,0,
292,15,421,4,0,
292,15,425,1,38,
292,15,468,4,0,
292,15,496,4,0,
292,15,522,4,0,
292,15,566,1,47,
292,15,590,4,0,
292,16,10,1,1,1
292,16,15,4,0,
292,16,28,1,9,1
292,16,63,4,0,
292,16,76,4,0,
292,16,91,4,0,
292,16,92,4,0,
292,16,104,4,0,
292,16,106,1,1,2
292,16,109,1,29,1
292,16,138,4,0,
292,16,141,1,5,1
292,16,148,4,0,
292,16,154,1,13,1
292,16,156,4,0,
292,16,164,4,0,
292,16,168,4,0,
292,16,170,1,25,1
292,16,173,3,0,
292,16,180,1,17,1
292,16,180,3,0,
292,16,182,4,0,
292,16,201,4,0,
292,16,202,3,0,
292,16,206,4,0,
292,16,207,4,0,
292,16,214,4,0,
292,16,216,4,0,
292,16,218,4,0,
292,16,237,4,0,
292,16,241,4,0,
292,16,247,1,33,1
292,16,247,4,0,
292,16,261,4,0,
292,16,263,4,0,
292,16,271,3,0,
292,16,288,1,37,1
292,16,290,4,0,
292,16,332,4,0,
292,16,377,1,41,1
292,16,404,4,0,
292,16,416,4,0,
292,16,421,4,0,
292,16,425,1,21,1
292,16,450,3,0,
292,16,468,4,0,
292,16,496,4,0,
292,16,522,4,0,
292,16,566,1,45,1
292,16,590,4,0,
292,17,10,1,1,1
292,17,28,1,1,4
292,17,28,1,9,
292,17,63,4,0,
292,17,71,1,1,3
292,17,71,1,5,
292,17,76,4,0,
292,17,92,4,0,
292,17,104,4,0,
292,17,106,1,1,2
292,17,109,1,29,
292,17,138,4,0,
292,17,141,4,0,
292,17,154,1,13,
292,17,156,4,0,
292,17,164,4,0,
292,17,168,4,0,
292,17,170,1,25,
292,17,180,1,17,
292,17,182,4,0,
292,17,201,4,0,
292,17,206,4,0,
292,17,207,4,0,
292,17,214,4,0,
292,17,216,4,0,
292,17,218,4,0,
292,17,237,4,0,
292,17,241,4,0,
292,17,247,1,33,
292,17,247,4,0,
292,17,261,4,0,
292,17,263,4,0,
292,17,288,1,37,
292,17,332,4,0,
292,17,377,1,41,
292,17,404,4,0,
292,17,416,4,0,
292,17,421,4,0,
292,17,425,1,21,
292,17,496,4,0,
292,17,566,1,45,
292,17,590,4,0,
292,18,10,1,1,1
292,18,28,1,1,4
292,18,28,1,9,
292,18,63,4,0,
292,18,71,1,1,3
292,18,71,1,5,
292,18,76,4,0,
292,18,92,4,0,
292,18,104,4,0,
292,18,106,1,1,2
292,18,109,1,29,
292,18,138,4,0,
292,18,141,4,0,
292,18,154,1,13,
292,18,156,4,0,
292,18,164,4,0,
292,18,168,4,0,
292,18,170,1,25,
292,18,180,1,17,
292,18,182,4,0,
292,18,201,4,0,
292,18,206,4,0,
292,18,207,4,0,
292,18,214,4,0,
292,18,216,4,0,
292,18,218,4,0,
292,18,237,4,0,
292,18,241,4,0,
292,18,247,1,33,
292,18,247,4,0,
292,18,261,4,0,
292,18,263,4,0,
292,18,288,1,37,
292,18,332,4,0,
292,18,377,1,41,
292,18,404,4,0,
292,18,416,4,0,
292,18,421,4,0,
292,18,425,1,21,
292,18,496,4,0,
292,18,566,1,45,
292,18,590,4,0,
293,5,1,1,1,
293,5,23,1,25,
293,5,36,2,0,
293,5,46,1,35,
293,5,46,4,0,
293,5,48,1,21,
293,5,53,4,0,
293,5,58,4,0,
293,5,59,4,0,
293,5,76,4,0,
293,5,92,4,0,
293,5,103,1,31,
293,5,104,4,0,
293,5,126,4,0,
293,5,156,1,41,1
293,5,156,4,0,
293,5,173,2,0,
293,5,182,4,0,
293,5,207,2,0,
293,5,213,4,0,
293,5,214,1,41,2
293,5,216,4,0,
293,5,218,4,0,
293,5,237,4,0,
293,5,240,4,0,
293,5,241,4,0,
293,5,247,4,0,
293,5,253,1,5,
293,5,263,4,0,
293,5,265,2,0,
293,5,290,4,0,
293,5,304,1,45,
293,5,310,1,11,
293,5,326,2,0,
293,5,336,1,15,
293,5,351,4,0,
293,5,352,4,0,
293,6,1,1,1,
293,6,5,3,0,
293,6,7,3,0,
293,6,8,3,0,
293,6,9,3,0,
293,6,23,1,25,
293,6,25,3,0,
293,6,34,3,0,
293,6,36,2,0,
293,6,38,3,0,
293,6,46,1,35,
293,6,46,4,0,
293,6,48,1,21,
293,6,53,4,0,
293,6,58,4,0,
293,6,59,4,0,
293,6,68,3,0,
293,6,69,3,0,
293,6,76,4,0,
293,6,92,4,0,
293,6,102,3,0,
293,6,103,1,31,
293,6,104,4,0,
293,6,111,3,0,
293,6,126,4,0,
293,6,156,1,41,1
293,6,156,4,0,
293,6,164,3,0,
293,6,173,2,0,
293,6,173,3,0,
293,6,182,4,0,
293,6,189,3,0,
293,6,196,3,0,
293,6,203,3,0,
293,6,205,3,0,
293,6,207,2,0,
293,6,207,3,0,
293,6,213,4,0,
293,6,214,1,41,2
293,6,214,3,0,
293,6,216,4,0,
293,6,218,4,0,
293,6,223,3,0,
293,6,237,4,0,
293,6,240,4,0,
293,6,241,4,0,
293,6,244,3,0,
293,6,247,4,0,
293,6,253,1,5,
293,6,263,4,0,
293,6,265,2,0,
293,6,290,4,0,
293,6,304,1,45,
293,6,310,1,11,
293,6,326,2,0,
293,6,336,1,15,
293,6,351,4,0,
293,6,352,4,0,
293,7,1,1,1,
293,7,5,3,0,
293,7,23,1,25,
293,7,25,3,0,
293,7,34,3,0,
293,7,36,2,0,
293,7,38,3,0,
293,7,46,1,35,
293,7,46,4,0,
293,7,48,1,21,
293,7,53,4,0,
293,7,58,4,0,
293,7,59,4,0,
293,7,68,3,0,
293,7,69,3,0,
293,7,76,4,0,
293,7,92,4,0,
293,7,102,3,0,
293,7,103,1,31,
293,7,104,4,0,
293,7,126,4,0,
293,7,156,1,41,1
293,7,156,4,0,
293,7,164,3,0,
293,7,173,2,0,
293,7,182,4,0,
293,7,207,2,0,
293,7,213,4,0,
293,7,214,1,41,2
293,7,216,4,0,
293,7,218,4,0,
293,7,237,4,0,
293,7,240,4,0,
293,7,241,4,0,
293,7,247,4,0,
293,7,253,1,5,
293,7,263,4,0,
293,7,265,2,0,
293,7,290,4,0,
293,7,304,1,45,
293,7,310,1,11,
293,7,326,2,0,
293,7,336,1,15,
293,7,351,4,0,
293,7,352,4,0,
293,8,1,1,1,
293,8,23,1,25,
293,8,36,2,0,
293,8,46,1,35,
293,8,46,4,0,
293,8,48,1,21,
293,8,53,4,0,
293,8,58,4,0,
293,8,59,4,0,
293,8,76,4,0,
293,8,92,4,0,
293,8,103,1,31,
293,8,104,4,0,
293,8,108,2,0,
293,8,126,4,0,
293,8,156,1,41,1
293,8,156,4,0,
293,8,164,4,0,
293,8,173,2,0,
293,8,182,4,0,
293,8,203,4,0,
293,8,207,2,0,
293,8,207,4,0,
293,8,213,4,0,
293,8,214,1,41,2
293,8,214,4,0,
293,8,216,4,0,
293,8,218,4,0,
293,8,237,4,0,
293,8,240,4,0,
293,8,241,4,0,
293,8,247,4,0,
293,8,253,1,5,
293,8,263,4,0,
293,8,265,2,0,
293,8,283,2,0,
293,8,290,4,0,
293,8,304,1,45,
293,8,310,1,11,
293,8,326,2,0,
293,8,336,1,15,
293,8,351,4,0,
293,8,352,4,0,
293,8,363,4,0,
293,8,374,4,0,
293,8,445,4,0,
293,9,1,1,1,
293,9,7,3,0,
293,9,8,3,0,
293,9,9,3,0,
293,9,23,1,25,
293,9,36,2,0,
293,9,46,1,35,
293,9,46,4,0,
293,9,48,1,21,
293,9,53,4,0,
293,9,58,4,0,
293,9,59,4,0,
293,9,76,4,0,
293,9,92,4,0,
293,9,103,1,31,
293,9,104,4,0,
293,9,108,2,0,
293,9,126,4,0,
293,9,156,1,41,1
293,9,156,4,0,
293,9,164,4,0,
293,9,173,2,0,
293,9,173,3,0,
293,9,182,4,0,
293,9,189,3,0,
293,9,196,3,0,
293,9,203,4,0,
293,9,205,3,0,
293,9,207,2,0,
293,9,207,4,0,
293,9,213,4,0,
293,9,214,1,41,2
293,9,214,4,0,
293,9,216,4,0,
293,9,218,4,0,
293,9,237,4,0,
293,9,240,4,0,
293,9,241,4,0,
293,9,247,4,0,
293,9,253,1,5,
293,9,253,3,0,
293,9,263,4,0,
293,9,265,2,0,
293,9,283,2,0,
293,9,290,4,0,
293,9,304,1,45,
293,9,310,1,11,
293,9,326,2,0,
293,9,336,1,15,
293,9,351,4,0,
293,9,352,4,0,
293,9,363,4,0,
293,9,374,4,0,
293,9,428,3,0,
293,9,445,4,0,
293,10,1,1,1,
293,10,7,3,0,
293,10,8,3,0,
293,10,9,3,0,
293,10,23,1,25,
293,10,29,3,0,
293,10,36,2,0,
293,10,46,1,35,
293,10,46,4,0,
293,10,48,1,21,
293,10,53,4,0,
293,10,58,4,0,
293,10,59,4,0,
293,10,76,4,0,
293,10,92,4,0,
293,10,103,1,31,
293,10,104,4,0,
293,10,108,2,0,
293,10,126,4,0,
293,10,156,1,41,1
293,10,156,4,0,
293,10,164,4,0,
293,10,173,2,0,
293,10,173,3,0,
293,10,182,4,0,
293,10,189,3,0,
293,10,196,3,0,
293,10,203,4,0,
293,10,205,3,0,
293,10,207,2,0,
293,10,207,4,0,
293,10,213,4,0,
293,10,214,1,41,2
293,10,214,4,0,
293,10,216,4,0,
293,10,218,4,0,
293,10,237,4,0,
293,10,240,4,0,
293,10,241,4,0,
293,10,247,4,0,
293,10,253,1,5,
293,10,253,3,0,
293,10,263,4,0,
293,10,265,2,0,
293,10,283,2,0,
293,10,290,4,0,
293,10,304,1,45,
293,10,310,1,11,
293,10,326,2,0,
293,10,336,1,15,
293,10,351,4,0,
293,10,352,4,0,
293,10,359,2,0,
293,10,363,4,0,
293,10,374,4,0,
293,10,428,3,0,
293,10,445,4,0,
293,11,1,1,1,
293,11,23,1,25,
293,11,36,2,0,
293,11,46,1,35,
293,11,46,4,0,
293,11,48,1,21,
293,11,53,4,0,
293,11,58,4,0,
293,11,59,4,0,
293,11,76,4,0,
293,11,92,4,0,
293,11,103,1,31,
293,11,104,4,0,
293,11,108,2,0,
293,11,126,4,0,
293,11,156,1,45,1
293,11,156,4,0,
293,11,164,4,0,
293,11,173,2,0,
293,11,182,4,0,
293,11,207,4,0,
293,11,213,4,0,
293,11,214,1,45,2
293,11,216,4,0,
293,11,218,4,0,
293,11,237,4,0,
293,11,240,4,0,
293,11,241,4,0,
293,11,247,4,0,
293,11,253,1,5,
293,11,263,4,0,
293,11,265,2,0,
293,11,283,2,0,
293,11,304,1,51,
293,11,310,1,11,
293,11,313,2,0,
293,11,326,2,0,
293,11,336,1,15,
293,11,359,2,0,
293,11,374,4,0,
293,11,485,1,41,
293,11,496,4,0,
293,11,497,4,0,
293,11,509,2,0,
293,11,510,4,0,
293,11,514,4,0,
293,11,526,4,0,
293,12,1,1,1,
293,12,23,1,25,
293,12,46,1,35,
293,12,46,4,0,
293,12,48,1,21,
293,12,53,4,0,
293,12,58,4,0,
293,12,59,4,0,
293,12,76,4,0,
293,12,92,4,0,
293,12,103,1,31,
293,12,104,4,0,
293,12,126,4,0,
293,12,156,1,41,1
293,12,156,4,0,
293,12,182,4,0,
293,12,213,4,0,
293,12,214,1,41,2
293,12,216,4,0,
293,12,218,4,0,
293,12,237,4,0,
293,12,240,4,0,
293,12,241,4,0,
293,12,247,4,0,
293,12,253,1,5,
293,12,263,4,0,
293,12,290,4,0,
293,12,304,1,45,
293,12,310,1,11,
293,12,336,1,15,
293,12,351,4,0,
293,12,352,4,0,
293,13,1,1,1,
293,13,23,1,25,
293,13,34,3,0,
293,13,38,3,0,
293,13,46,1,35,
293,13,46,4,0,
293,13,48,1,21,
293,13,53,4,0,
293,13,58,4,0,
293,13,59,4,0,
293,13,69,3,0,
293,13,76,4,0,
293,13,92,4,0,
293,13,102,3,0,
293,13,103,1,31,
293,13,104,4,0,
293,13,126,4,0,
293,13,156,1,41,1
293,13,156,4,0,
293,13,164,3,0,
293,13,182,4,0,
293,13,196,3,0,
293,13,207,3,0,
293,13,213,4,0,
293,13,214,1,41,2
293,13,216,4,0,
293,13,218,4,0,
293,13,237,4,0,
293,13,240,4,0,
293,13,241,4,0,
293,13,247,4,0,
293,13,253,1,5,
293,13,263,4,0,
293,13,290,4,0,
293,13,304,1,45,
293,13,310,1,11,
293,13,336,1,15,
293,13,351,4,0,
293,13,352,4,0,
293,14,1,1,1,
293,14,7,3,0,
293,14,8,3,0,
293,14,9,3,0,
293,14,23,1,25,
293,14,36,2,0,
293,14,46,1,35,
293,14,46,4,0,
293,14,48,1,21,
293,14,53,4,0,
293,14,58,4,0,
293,14,59,4,0,
293,14,76,4,0,
293,14,92,4,0,
293,14,103,1,31,
293,14,104,4,0,
293,14,108,2,0,
293,14,126,4,0,
293,14,156,1,45,1
293,14,156,4,0,
293,14,164,4,0,
293,14,173,2,0,
293,14,173,3,0,
293,14,182,4,0,
293,14,196,3,0,
293,14,207,4,0,
293,14,213,4,0,
293,14,214,1,45,2
293,14,214,3,0,
293,14,216,4,0,
293,14,218,4,0,
293,14,237,4,0,
293,14,240,4,0,
293,14,241,4,0,
293,14,247,4,0,
293,14,253,1,5,
293,14,253,3,0,
293,14,263,4,0,
293,14,265,2,0,
293,14,283,2,0,
293,14,283,3,0,
293,14,304,1,51,
293,14,304,3,0,
293,14,310,1,11,
293,14,313,2,0,
293,14,326,2,0,
293,14,336,1,15,
293,14,359,2,0,
293,14,374,4,0,
293,14,428,3,0,
293,14,485,1,41,
293,14,496,4,0,
293,14,497,4,0,
293,14,509,2,0,
293,14,510,4,0,
293,14,514,4,0,
293,14,526,4,0,
293,15,1,1,1,
293,15,23,1,25,
293,15,36,2,0,
293,15,46,1,35,
293,15,46,4,0,
293,15,48,1,21,
293,15,53,4,0,
293,15,58,4,0,
293,15,59,4,0,
293,15,76,4,0,
293,15,92,4,0,
293,15,103,1,31,
293,15,104,4,0,
293,15,108,2,0,
293,15,126,4,0,
293,15,156,1,45,1
293,15,156,4,0,
293,15,164,4,0,
293,15,173,2,0,
293,15,182,4,0,
293,15,207,4,0,
293,15,213,4,0,
293,15,214,1,45,2
293,15,214,4,0,
293,15,216,4,0,
293,15,218,4,0,
293,15,237,4,0,
293,15,240,4,0,
293,15,241,4,0,
293,15,247,4,0,
293,15,253,1,5,
293,15,263,4,0,
293,15,265,2,0,
293,15,283,2,0,
293,15,304,1,50,
293,15,310,1,11,
293,15,313,2,0,
293,15,326,2,0,
293,15,336,1,15,
293,15,359,2,0,
293,15,374,4,0,
293,15,485,1,41,
293,15,496,4,0,
293,15,497,4,0,
293,15,509,2,0,
293,15,510,4,0,
293,15,514,4,0,
293,15,574,2,0,
293,15,590,4,0,
293,16,1,1,1,1
293,16,7,3,0,
293,16,8,3,0,
293,16,9,3,0,
293,16,23,1,22,1
293,16,36,2,0,
293,16,46,1,29,1
293,16,46,4,0,
293,16,48,1,18,1
293,16,53,4,0,
293,16,58,4,0,
293,16,59,4,0,
293,16,76,4,0,
293,16,92,4,0,
293,16,103,1,15,1
293,16,104,4,0,
293,16,108,2,0,
293,16,126,4,0,
293,16,156,1,32,1
293,16,156,4,0,
293,16,164,4,0,
293,16,173,2,0,
293,16,173,3,0,
293,16,182,4,0,
293,16,196,3,0,
293,16,207,4,0,
293,16,213,4,0,
293,16,214,1,36,1
293,16,214,4,0,
293,16,216,4,0,
293,16,218,4,0,
293,16,237,4,0,
293,16,240,4,0,
293,16,241,4,0,
293,16,247,4,0,
293,16,253,1,25,1
293,16,253,3,0,
293,16,263,4,0,
293,16,265,2,0,
293,16,283,2,0,
293,16,283,3,0,
293,16,290,4,0,
293,16,304,1,39,1
293,16,304,3,0,
293,16,310,1,8,1
293,16,313,2,0,
293,16,326,2,0,
293,16,336,1,11,1
293,16,351,3,0,
293,16,352,3,0,
293,16,359,2,0,
293,16,374,4,0,
293,16,428,3,0,
293,16,485,1,43,1
293,16,496,4,0,
293,16,497,1,4,1
293,16,497,4,0,
293,16,509,2,0,
293,16,510,4,0,
293,16,514,4,0,
293,16,574,2,0,
293,16,590,4,0,
293,17,1,1,1,
293,17,23,1,22,
293,17,36,2,0,
293,17,46,1,29,
293,17,46,4,0,
293,17,48,1,18,
293,17,53,4,0,
293,17,58,4,0,
293,17,59,4,0,
293,17,76,4,0,
293,17,92,4,0,
293,17,103,1,15,
293,17,104,4,0,
293,17,108,2,0,
293,17,126,4,0,
293,17,156,1,32,
293,17,156,4,0,
293,17,164,4,0,
293,17,173,2,0,
293,17,182,4,0,
293,17,207,4,0,
293,17,213,4,0,
293,17,214,1,36,
293,17,214,4,0,
293,17,216,4,0,
293,17,218,4,0,
293,17,237,4,0,
293,17,240,4,0,
293,17,241,4,0,
293,17,247,4,0,
293,17,253,1,25,
293,17,263,4,0,
293,17,265,2,0,
293,17,283,2,0,
293,17,304,1,39,
293,17,310,1,8,
293,17,313,2,0,
293,17,326,2,0,
293,17,336,1,11,
293,17,359,2,0,
293,17,374,4,0,
293,17,485,1,43,
293,17,496,4,0,
293,17,497,1,4,
293,17,497,4,0,
293,17,509,2,0,
293,17,526,4,0,
293,17,574,2,0,
293,17,590,4,0,
293,18,1,1,1,
293,18,18,2,0,
293,18,23,1,22,
293,18,36,2,0,
293,18,46,1,29,
293,18,46,4,0,
293,18,48,1,18,
293,18,53,4,0,
293,18,58,4,0,
293,18,59,4,0,
293,18,76,4,0,
293,18,92,4,0,
293,18,103,1,15,
293,18,104,4,0,
293,18,108,2,0,
293,18,126,4,0,
293,18,156,1,32,
293,18,156,4,0,
293,18,164,4,0,
293,18,173,2,0,
293,18,182,4,0,
293,18,207,4,0,
293,18,213,4,0,
293,18,214,1,36,
293,18,214,4,0,
293,18,216,4,0,
293,18,218,4,0,
293,18,237,4,0,
293,18,240,4,0,
293,18,241,4,0,
293,18,247,4,0,
293,18,253,1,25,
293,18,263,4,0,
293,18,265,2,0,
293,18,283,2,0,
293,18,304,1,39,
293,18,310,1,8,
293,18,313,2,0,
293,18,326,2,0,
293,18,336,1,11,
293,18,359,2,0,
293,18,374,4,0,
293,18,485,1,43,
293,18,496,4,0,
293,18,497,1,4,
293,18,497,4,0,
293,18,509,2,0,
293,18,526,4,0,
293,18,574,2,0,
293,18,590,4,0,
294,5,1,1,1,1
294,5,23,1,29,
294,5,46,1,43,
294,5,46,4,0,
294,5,48,1,23,
294,5,53,4,0,
294,5,58,4,0,
294,5,59,4,0,
294,5,70,4,0,
294,5,76,4,0,
294,5,89,4,0,
294,5,92,4,0,
294,5,103,1,37,
294,5,104,4,0,
294,5,126,4,0,
294,5,156,1,51,
294,5,156,4,0,
294,5,182,4,0,
294,5,213,4,0,
294,5,214,1,51,5
294,5,216,4,0,
294,5,218,4,0,
294,5,237,4,0,
294,5,240,4,0,
294,5,241,4,0,
294,5,247,4,0,
294,5,249,4,0,
294,5,253,1,1,2
294,5,253,1,5,
294,5,259,4,0,
294,5,263,4,0,
294,5,269,4,0,
294,5,280,4,0,
294,5,290,4,0,
294,5,304,1,57,
294,5,310,1,1,3
294,5,310,1,11,
294,5,315,4,0,
294,5,336,1,1,4
294,5,336,1,15,
294,5,351,4,0,
294,5,352,4,0,
294,6,1,1,1,1
294,6,5,3,0,
294,6,7,3,0,
294,6,8,3,0,
294,6,9,3,0,
294,6,23,1,29,
294,6,25,3,0,
294,6,34,3,0,
294,6,38,3,0,
294,6,46,1,43,
294,6,46,4,0,
294,6,48,1,23,
294,6,53,4,0,
294,6,58,4,0,
294,6,59,4,0,
294,6,68,3,0,
294,6,69,3,0,
294,6,70,4,0,
294,6,76,4,0,
294,6,89,4,0,
294,6,92,4,0,
294,6,102,3,0,
294,6,103,1,37,
294,6,104,4,0,
294,6,111,3,0,
294,6,126,4,0,
294,6,156,1,51,
294,6,156,4,0,
294,6,157,3,0,
294,6,164,3,0,
294,6,173,3,0,
294,6,182,4,0,
294,6,189,3,0,
294,6,196,3,0,
294,6,203,3,0,
294,6,205,3,0,
294,6,207,3,0,
294,6,213,4,0,
294,6,214,1,51,5
294,6,214,3,0,
294,6,216,4,0,
294,6,218,4,0,
294,6,223,3,0,
294,6,237,4,0,
294,6,240,4,0,
294,6,241,4,0,
294,6,244,3,0,
294,6,247,4,0,
294,6,249,4,0,
294,6,253,1,1,2
294,6,253,1,5,
294,6,259,4,0,
294,6,263,4,0,
294,6,269,4,0,
294,6,280,4,0,
294,6,290,4,0,
294,6,304,1,57,
294,6,310,1,1,3
294,6,310,1,11,
294,6,315,4,0,
294,6,336,1,1,4
294,6,336,1,15,
294,6,351,4,0,
294,6,352,4,0,
294,7,1,1,1,1
294,7,5,3,0,
294,7,23,1,29,
294,7,25,3,0,
294,7,34,3,0,
294,7,38,3,0,
294,7,46,1,43,
294,7,46,4,0,
294,7,48,1,23,
294,7,53,4,0,
294,7,58,4,0,
294,7,59,4,0,
294,7,68,3,0,
294,7,69,3,0,
294,7,70,4,0,
294,7,76,4,0,
294,7,89,4,0,
294,7,92,4,0,
294,7,102,3,0,
294,7,103,1,37,
294,7,104,4,0,
294,7,126,4,0,
294,7,156,1,51,
294,7,156,4,0,
294,7,157,3,0,
294,7,164,3,0,
294,7,182,4,0,
294,7,213,4,0,
294,7,214,1,51,5
294,7,216,4,0,
294,7,218,4,0,
294,7,237,4,0,
294,7,240,4,0,
294,7,241,4,0,
294,7,247,4,0,
294,7,249,4,0,
294,7,253,1,1,2
294,7,253,1,5,
294,7,259,4,0,
294,7,263,4,0,
294,7,269,4,0,
294,7,280,4,0,
294,7,290,4,0,
294,7,304,1,57,
294,7,310,1,1,3
294,7,310,1,11,
294,7,315,4,0,
294,7,336,1,1,4
294,7,336,1,15,
294,7,351,4,0,
294,7,352,4,0,
294,8,1,1,1,1
294,8,23,1,29,
294,8,44,1,20,
294,8,46,1,43,
294,8,46,4,0,
294,8,48,1,23,
294,8,53,4,0,
294,8,58,4,0,
294,8,59,4,0,
294,8,70,4,0,
294,8,76,4,0,
294,8,89,4,0,
294,8,92,4,0,
294,8,103,1,37,
294,8,104,4,0,
294,8,126,4,0,
294,8,156,1,51,1
294,8,156,4,0,
294,8,157,4,0,
294,8,164,4,0,
294,8,182,4,0,
294,8,203,4,0,
294,8,207,4,0,
294,8,213,4,0,
294,8,214,1,51,2
294,8,214,4,0,
294,8,216,4,0,
294,8,218,4,0,
294,8,237,4,0,
294,8,240,4,0,
294,8,241,4,0,
294,8,247,4,0,
294,8,249,4,0,
294,8,253,1,1,2
294,8,253,1,5,
294,8,259,4,0,
294,8,263,4,0,
294,8,269,4,0,
294,8,280,4,0,
294,8,290,4,0,
294,8,304,1,57,
294,8,310,1,1,3
294,8,310,1,11,
294,8,315,4,0,
294,8,317,4,0,
294,8,336,1,1,4
294,8,336,1,15,
294,8,351,4,0,
294,8,352,4,0,
294,8,363,4,0,
294,8,374,4,0,
294,8,445,4,0,
294,9,1,1,1,1
294,9,7,3,0,
294,9,8,3,0,
294,9,9,3,0,
294,9,23,1,29,
294,9,44,1,20,
294,9,46,1,43,
294,9,46,4,0,
294,9,48,1,23,
294,9,53,4,0,
294,9,58,4,0,
294,9,59,4,0,
294,9,70,4,0,
294,9,76,4,0,
294,9,89,4,0,
294,9,92,4,0,
294,9,103,1,37,
294,9,104,4,0,
294,9,126,4,0,
294,9,156,1,51,1
294,9,156,4,0,
294,9,157,4,0,
294,9,164,4,0,
294,9,173,3,0,
294,9,182,4,0,
294,9,189,3,0,
294,9,196,3,0,
294,9,203,4,0,
294,9,205,3,0,
294,9,207,4,0,
294,9,213,4,0,
294,9,214,1,51,2
294,9,214,4,0,
294,9,216,4,0,
294,9,218,4,0,
294,9,237,4,0,
294,9,240,4,0,
294,9,241,4,0,
294,9,247,4,0,
294,9,249,4,0,
294,9,253,1,1,2
294,9,253,1,5,
294,9,253,3,0,
294,9,259,4,0,
294,9,263,4,0,
294,9,269,4,0,
294,9,280,4,0,
294,9,290,4,0,
294,9,304,1,57,
294,9,310,1,1,3
294,9,310,1,11,
294,9,315,4,0,
294,9,317,4,0,
294,9,336,1,1,4
294,9,336,1,15,
294,9,351,4,0,
294,9,352,4,0,
294,9,363,4,0,
294,9,374,4,0,
294,9,428,3,0,
294,9,445,4,0,
294,10,1,1,1,1
294,10,7,3,0,
294,10,8,3,0,
294,10,9,3,0,
294,10,23,1,29,
294,10,29,3,0,
294,10,44,1,20,
294,10,46,1,43,
294,10,46,4,0,
294,10,48,1,23,
294,10,53,4,0,
294,10,58,4,0,
294,10,59,4,0,
294,10,67,3,0,
294,10,70,4,0,
294,10,76,4,0,
294,10,89,4,0,
294,10,92,4,0,
294,10,103,1,37,
294,10,104,4,0,
294,10,126,4,0,
294,10,156,1,51,1
294,10,156,4,0,
294,10,157,4,0,
294,10,164,4,0,
294,10,173,3,0,
294,10,182,4,0,
294,10,189,3,0,
294,10,196,3,0,
294,10,203,4,0,
294,10,205,3,0,
294,10,207,4,0,
294,10,213,4,0,
294,10,214,1,51,2
294,10,214,4,0,
294,10,216,4,0,
294,10,218,4,0,
294,10,237,4,0,
294,10,240,4,0,
294,10,241,4,0,
294,10,247,4,0,
294,10,249,4,0,
294,10,253,1,1,2
294,10,253,1,5,
294,10,253,3,0,
294,10,259,4,0,
294,10,263,4,0,
294,10,269,4,0,
294,10,280,4,0,
294,10,290,4,0,
294,10,304,1,57,
294,10,310,1,1,3
294,10,310,1,11,
294,10,315,4,0,
294,10,317,4,0,
294,10,336,1,1,4
294,10,336,1,15,
294,10,351,4,0,
294,10,352,4,0,
294,10,363,4,0,
294,10,374,4,0,
294,10,428,3,0,
294,10,445,4,0,
294,11,1,1,1,1
294,11,23,1,29,
294,11,44,1,20,
294,11,46,1,43,
294,11,46,4,0,
294,11,48,1,23,
294,11,53,4,0,
294,11,58,4,0,
294,11,59,4,0,
294,11,70,4,0,
294,11,76,4,0,
294,11,89,4,0,
294,11,92,4,0,
294,11,103,1,37,
294,11,104,4,0,
294,11,126,4,0,
294,11,156,1,57,1
294,11,156,4,0,
294,11,157,4,0,
294,11,164,4,0,
294,11,182,4,0,
294,11,207,4,0,
294,11,213,4,0,
294,11,214,1,57,2
294,11,216,4,0,
294,11,218,4,0,
294,11,237,4,0,
294,11,240,4,0,
294,11,241,4,0,
294,11,247,4,0,
294,11,249,4,0,
294,11,253,1,1,2
294,11,253,1,5,
294,11,259,4,0,
294,11,263,4,0,
294,11,269,4,0,
294,11,280,4,0,
294,11,304,1,65,
294,11,310,1,1,3
294,11,310,1,11,
294,11,315,4,0,
294,11,317,4,0,
294,11,336,1,1,4
294,11,336,1,15,
294,11,374,4,0,
294,11,479,4,0,
294,11,485,1,51,
294,11,496,4,0,
294,11,497,4,0,
294,11,510,4,0,
294,11,514,4,0,
294,11,523,4,0,
294,11,526,4,0,
294,12,1,1,1,1
294,12,23,1,29,
294,12,46,1,43,
294,12,46,4,0,
294,12,48,1,23,
294,12,53,4,0,
294,12,58,4,0,
294,12,59,4,0,
294,12,70,4,0,
294,12,76,4,0,
294,12,89,4,0,
294,12,92,4,0,
294,12,103,1,37,
294,12,104,4,0,
294,12,126,4,0,
294,12,156,1,51,
294,12,156,4,0,
294,12,182,4,0,
294,12,213,4,0,
294,12,214,1,51,5
294,12,216,4,0,
294,12,218,4,0,
294,12,237,4,0,
294,12,240,4,0,
294,12,241,4,0,
294,12,247,4,0,
294,12,249,4,0,
294,12,253,1,1,2
294,12,253,1,5,
294,12,259,4,0,
294,12,263,4,0,
294,12,269,4,0,
294,12,280,4,0,
294,12,290,4,0,
294,12,304,1,57,
294,12,310,1,1,3
294,12,310,1,11,
294,12,315,4,0,
294,12,336,1,1,4
294,12,336,1,15,
294,12,351,4,0,
294,12,352,4,0,
294,13,1,1,1,1
294,13,23,1,29,
294,13,34,3,0,
294,13,38,3,0,
294,13,46,1,43,
294,13,46,4,0,
294,13,48,1,23,
294,13,53,4,0,
294,13,58,4,0,
294,13,59,4,0,
294,13,69,3,0,
294,13,70,4,0,
294,13,76,4,0,
294,13,89,4,0,
294,13,92,4,0,
294,13,102,3,0,
294,13,103,1,37,
294,13,104,4,0,
294,13,126,4,0,
294,13,156,1,51,
294,13,156,4,0,
294,13,164,3,0,
294,13,182,4,0,
294,13,196,3,0,
294,13,207,3,0,
294,13,213,4,0,
294,13,214,1,51,5
294,13,216,4,0,
294,13,218,4,0,
294,13,237,4,0,
294,13,240,4,0,
294,13,241,4,0,
294,13,247,4,0,
294,13,249,4,0,
294,13,253,1,1,2
294,13,253,1,5,
294,13,259,4,0,
294,13,263,4,0,
294,13,269,4,0,
294,13,280,4,0,
294,13,290,4,0,
294,13,304,1,57,
294,13,310,1,1,3
294,13,310,1,11,
294,13,315,4,0,
294,13,336,1,1,4
294,13,336,1,15,
294,13,351,4,0,
294,13,352,4,0,
294,14,1,1,1,1
294,14,7,3,0,
294,14,8,3,0,
294,14,9,3,0,
294,14,23,1,29,
294,14,44,1,20,
294,14,46,1,43,
294,14,46,4,0,
294,14,48,1,23,
294,14,53,4,0,
294,14,58,4,0,
294,14,59,4,0,
294,14,67,3,0,
294,14,70,4,0,
294,14,76,4,0,
294,14,89,4,0,
294,14,92,4,0,
294,14,103,1,37,
294,14,104,4,0,
294,14,126,4,0,
294,14,156,1,57,1
294,14,156,4,0,
294,14,157,4,0,
294,14,164,4,0,
294,14,173,3,0,
294,14,182,4,0,
294,14,196,3,0,
294,14,207,4,0,
294,14,213,4,0,
294,14,214,1,57,2
294,14,214,3,0,
294,14,216,4,0,
294,14,218,4,0,
294,14,237,4,0,
294,14,240,4,0,
294,14,241,4,0,
294,14,247,4,0,
294,14,249,4,0,
294,14,253,1,1,2
294,14,253,1,5,
294,14,253,3,0,
294,14,259,4,0,
294,14,263,4,0,
294,14,269,4,0,
294,14,280,4,0,
294,14,283,3,0,
294,14,304,1,65,
294,14,304,3,0,
294,14,310,1,1,3
294,14,310,1,11,
294,14,315,4,0,
294,14,317,4,0,
294,14,336,1,1,4
294,14,336,1,15,
294,14,374,4,0,
294,14,428,3,0,
294,14,479,4,0,
294,14,485,1,51,
294,14,496,4,0,
294,14,497,4,0,
294,14,510,4,0,
294,14,514,4,0,
294,14,523,4,0,
294,14,526,4,0,
294,15,1,1,1,1
294,15,23,1,29,
294,15,44,1,20,
294,15,46,1,43,
294,15,46,4,0,
294,15,48,1,23,
294,15,53,4,0,
294,15,58,4,0,
294,15,59,4,0,
294,15,70,4,0,
294,15,76,4,0,
294,15,89,4,0,
294,15,92,4,0,
294,15,103,1,37,
294,15,104,4,0,
294,15,126,4,0,
294,15,156,1,57,1
294,15,156,4,0,
294,15,157,4,0,
294,15,164,4,0,
294,15,182,4,0,
294,15,207,4,0,
294,15,213,4,0,
294,15,214,1,57,2
294,15,214,4,0,
294,15,216,4,0,
294,15,218,4,0,
294,15,237,4,0,
294,15,240,4,0,
294,15,241,4,0,
294,15,247,4,0,
294,15,249,4,0,
294,15,253,1,1,2
294,15,253,1,5,
294,15,259,4,0,
294,15,263,4,0,
294,15,269,4,0,
294,15,280,4,0,
294,15,304,1,65,
294,15,310,1,1,3
294,15,310,1,11,
294,15,315,4,0,
294,15,317,4,0,
294,15,336,1,1,4
294,15,336,1,15,
294,15,374,4,0,
294,15,479,4,0,
294,15,485,1,51,
294,15,496,4,0,
294,15,497,4,0,
294,15,510,4,0,
294,15,514,4,0,
294,15,523,4,0,
294,15,590,4,0,
294,15,612,4,0,
294,16,1,1,1,1
294,16,7,3,0,
294,16,8,3,0,
294,16,9,3,0,
294,16,23,1,23,1
294,16,44,1,20,1
294,16,46,1,32,1
294,16,46,4,0,
294,16,48,1,18,1
294,16,53,4,0,
294,16,58,4,0,
294,16,59,4,0,
294,16,67,3,0,
294,16,70,4,0,
294,16,76,4,0,
294,16,89,4,0,
294,16,92,4,0,
294,16,103,1,15,1
294,16,104,4,0,
294,16,126,4,0,
294,16,156,1,36,1
294,16,156,4,0,
294,16,157,4,0,
294,16,164,4,0,
294,16,173,3,0,
294,16,182,4,0,
294,16,196,3,0,
294,16,207,4,0,
294,16,213,4,0,
294,16,214,1,41,1
294,16,214,4,0,
294,16,216,4,0,
294,16,218,4,0,
294,16,237,4,0,
294,16,240,4,0,
294,16,241,4,0,
294,16,247,4,0,
294,16,249,4,0,
294,16,253,1,27,1
294,16,253,3,0,
294,16,259,4,0,
294,16,263,4,0,
294,16,269,4,0,
294,16,280,4,0,
294,16,283,3,0,
294,16,290,4,0,
294,16,304,1,45,1
294,16,304,3,0,
294,16,310,1,1,3
294,16,310,1,9,1
294,16,315,4,0,
294,16,317,4,0,
294,16,336,1,1,4
294,16,336,1,11,1
294,16,351,3,0,
294,16,352,3,0,
294,16,374,4,0,
294,16,428,3,0,
294,16,479,4,0,
294,16,485,1,50,1
294,16,496,4,0,
294,16,497,1,1,2
294,16,497,1,4,1
294,16,497,4,0,
294,16,510,4,0,
294,16,514,4,0,
294,16,523,4,0,
294,16,590,4,0,
294,16,612,4,0,
294,17,1,1,1,2
294,17,23,1,23,
294,17,44,1,0,
294,17,44,1,1,1
294,17,46,1,32,
294,17,46,4,0,
294,17,48,1,18,
294,17,53,4,0,
294,17,58,4,0,
294,17,59,4,0,
294,17,76,4,0,
294,17,89,4,0,
294,17,92,4,0,
294,17,103,1,15,
294,17,104,4,0,
294,17,126,4,0,
294,17,156,1,36,
294,17,156,4,0,
294,17,157,4,0,
294,17,164,4,0,
294,17,182,4,0,
294,17,207,4,0,
294,17,213,4,0,
294,17,214,1,41,
294,17,214,4,0,
294,17,216,4,0,
294,17,218,4,0,
294,17,237,4,0,
294,17,240,4,0,
294,17,241,4,0,
294,17,247,4,0,
294,17,253,1,27,
294,17,259,4,0,
294,17,263,4,0,
294,17,269,4,0,
294,17,280,4,0,
294,17,304,1,45,
294,17,310,1,1,4
294,17,310,1,9,
294,17,315,4,0,
294,17,317,4,0,
294,17,336,1,1,5
294,17,336,1,11,
294,17,374,4,0,
294,17,479,4,0,
294,17,485,1,50,
294,17,496,4,0,
294,17,497,1,1,3
294,17,497,1,4,
294,17,497,4,0,
294,17,523,4,0,
294,17,526,4,0,
294,17,590,4,0,
294,18,1,1,1,2
294,18,23,1,23,
294,18,44,1,0,
294,18,44,1,1,1
294,18,46,1,32,
294,18,46,4,0,
294,18,48,1,18,
294,18,53,4,0,
294,18,58,4,0,
294,18,59,4,0,
294,18,76,4,0,
294,18,89,4,0,
294,18,92,4,0,
294,18,103,1,15,
294,18,104,4,0,
294,18,126,4,0,
294,18,156,1,36,
294,18,156,4,0,
294,18,157,4,0,
294,18,164,4,0,
294,18,182,4,0,
294,18,207,4,0,
294,18,213,4,0,
294,18,214,1,41,
294,18,214,4,0,
294,18,216,4,0,
294,18,218,4,0,
294,18,237,4,0,
294,18,240,4,0,
294,18,241,4,0,
294,18,247,4,0,
294,18,253,1,27,
294,18,259,4,0,
294,18,263,4,0,
294,18,269,4,0,
294,18,280,4,0,
294,18,304,1,45,
294,18,310,1,1,4
294,18,310,1,9,
294,18,315,4,0,
294,18,317,4,0,
294,18,336,1,1,5
294,18,336,1,11,
294,18,374,4,0,
294,18,479,4,0,
294,18,485,1,50,
294,18,496,4,0,
294,18,497,1,1,3
294,18,497,1,4,
294,18,497,4,0,
294,18,523,4,0,
294,18,526,4,0,
294,18,590,4,0,
295,5,1,1,1,1
295,5,23,1,29,
295,5,46,1,45,
295,5,46,4,0,
295,5,48,1,23,
295,5,53,4,0,
295,5,58,4,0,
295,5,59,4,0,
295,5,63,1,40,
295,5,63,4,0,
295,5,70,4,0,
295,5,76,4,0,
295,5,89,4,0,
295,5,92,4,0,
295,5,103,1,37,
295,5,104,4,0,
295,5,126,4,0,
295,5,156,1,55,
295,5,156,4,0,
295,5,182,4,0,
295,5,213,4,0,
295,5,214,1,55,5
295,5,216,4,0,
295,5,218,4,0,
295,5,237,4,0,
295,5,240,4,0,
295,5,241,4,0,
295,5,247,4,0,
295,5,249,4,0,
295,5,253,1,1,2
295,5,253,1,5,
295,5,259,4,0,
295,5,263,4,0,
295,5,269,4,0,
295,5,280,4,0,
295,5,290,4,0,
295,5,304,1,63,
295,5,310,1,1,3
295,5,310,1,11,
295,5,315,4,0,
295,5,336,1,1,4
295,5,336,1,15,
295,5,351,4,0,
295,5,352,4,0,
295,6,1,1,1,1
295,6,5,3,0,
295,6,7,3,0,
295,6,8,3,0,
295,6,9,3,0,
295,6,23,1,29,
295,6,25,3,0,
295,6,34,3,0,
295,6,38,3,0,
295,6,46,1,45,
295,6,46,4,0,
295,6,48,1,23,
295,6,53,4,0,
295,6,58,4,0,
295,6,59,4,0,
295,6,63,1,40,
295,6,63,4,0,
295,6,68,3,0,
295,6,69,3,0,
295,6,70,4,0,
295,6,76,4,0,
295,6,89,4,0,
295,6,92,4,0,
295,6,102,3,0,
295,6,103,1,37,
295,6,104,4,0,
295,6,111,3,0,
295,6,126,4,0,
295,6,156,1,55,
295,6,156,4,0,
295,6,157,3,0,
295,6,164,3,0,
295,6,173,3,0,
295,6,182,4,0,
295,6,189,3,0,
295,6,196,3,0,
295,6,203,3,0,
295,6,205,3,0,
295,6,207,3,0,
295,6,213,4,0,
295,6,214,1,55,5
295,6,214,3,0,
295,6,216,4,0,
295,6,218,4,0,
295,6,223,3,0,
295,6,237,4,0,
295,6,240,4,0,
295,6,241,4,0,
295,6,244,3,0,
295,6,247,4,0,
295,6,249,4,0,
295,6,253,1,1,2
295,6,253,1,5,
295,6,259,4,0,
295,6,263,4,0,
295,6,269,4,0,
295,6,280,4,0,
295,6,290,4,0,
295,6,304,1,63,
295,6,310,1,1,3
295,6,310,1,11,
295,6,315,4,0,
295,6,336,1,1,4
295,6,336,1,15,
295,6,351,4,0,
295,6,352,4,0,
295,7,1,1,1,1
295,7,5,3,0,
295,7,23,1,29,
295,7,25,3,0,
295,7,34,3,0,
295,7,38,3,0,
295,7,46,1,45,
295,7,46,4,0,
295,7,48,1,23,
295,7,53,4,0,
295,7,58,4,0,
295,7,59,4,0,
295,7,63,1,40,
295,7,63,4,0,
295,7,68,3,0,
295,7,69,3,0,
295,7,70,4,0,
295,7,76,4,0,
295,7,89,4,0,
295,7,92,4,0,
295,7,102,3,0,
295,7,103,1,37,
295,7,104,4,0,
295,7,126,4,0,
295,7,156,1,55,
295,7,156,4,0,
295,7,157,3,0,
295,7,164,3,0,
295,7,182,4,0,
295,7,213,4,0,
295,7,214,1,55,5
295,7,216,4,0,
295,7,218,4,0,
295,7,237,4,0,
295,7,240,4,0,
295,7,241,4,0,
295,7,247,4,0,
295,7,249,4,0,
295,7,253,1,1,2
295,7,253,1,5,
295,7,259,4,0,
295,7,263,4,0,
295,7,269,4,0,
295,7,280,4,0,
295,7,290,4,0,
295,7,304,1,63,
295,7,310,1,1,3
295,7,310,1,11,
295,7,315,4,0,
295,7,336,1,1,4
295,7,336,1,15,
295,7,351,4,0,
295,7,352,4,0,
295,8,1,1,1,4
295,8,23,1,29,
295,8,44,1,20,
295,8,46,1,45,
295,8,46,4,0,
295,8,48,1,23,
295,8,53,4,0,
295,8,57,4,0,
295,8,58,4,0,
295,8,59,4,0,
295,8,63,1,71,
295,8,63,4,0,
295,8,70,4,0,
295,8,76,4,0,
295,8,89,4,0,
295,8,92,4,0,
295,8,103,1,37,
295,8,104,4,0,
295,8,126,4,0,
295,8,156,1,55,1
295,8,156,4,0,
295,8,157,4,0,
295,8,164,4,0,
295,8,182,4,0,
295,8,203,4,0,
295,8,207,4,0,
295,8,213,4,0,
295,8,214,1,55,2
295,8,214,4,0,
295,8,216,4,0,
295,8,218,4,0,
295,8,237,4,0,
295,8,240,4,0,
295,8,241,4,0,
295,8,242,1,40,
295,8,247,4,0,
295,8,249,4,0,
295,8,253,1,1,5
295,8,253,1,5,
295,8,259,4,0,
295,8,263,4,0,
295,8,269,4,0,
295,8,280,4,0,
295,8,290,4,0,
295,8,304,1,63,
295,8,310,1,1,6
295,8,310,1,11,
295,8,315,4,0,
295,8,317,4,0,
295,8,336,1,1,7
295,8,336,1,15,
295,8,351,4,0,
295,8,352,4,0,
295,8,363,4,0,
295,8,374,4,0,
295,8,411,4,0,
295,8,416,4,0,
295,8,419,4,0,
295,8,422,1,1,3
295,8,423,1,1,1
295,8,424,1,1,2
295,8,431,4,0,
295,8,445,4,0,
295,9,1,1,1,4
295,9,7,3,0,
295,9,8,3,0,
295,9,9,3,0,
295,9,23,1,29,
295,9,44,1,20,
295,9,46,1,45,
295,9,46,4,0,
295,9,48,1,23,
295,9,53,4,0,
295,9,57,4,0,
295,9,58,4,0,
295,9,59,4,0,
295,9,63,1,71,
295,9,63,4,0,
295,9,70,4,0,
295,9,76,4,0,
295,9,89,4,0,
295,9,92,4,0,
295,9,103,1,37,
295,9,104,4,0,
295,9,126,4,0,
295,9,156,1,55,1
295,9,156,4,0,
295,9,157,4,0,
295,9,164,4,0,
295,9,173,3,0,
295,9,182,4,0,
295,9,189,3,0,
295,9,196,3,0,
295,9,200,3,0,
295,9,203,4,0,
295,9,205,3,0,
295,9,207,4,0,
295,9,213,4,0,
295,9,214,1,55,2
295,9,214,4,0,
295,9,216,4,0,
295,9,218,4,0,
295,9,237,4,0,
295,9,240,4,0,
295,9,241,4,0,
295,9,242,1,40,
295,9,247,4,0,
295,9,249,4,0,
295,9,253,1,1,5
295,9,253,1,5,
295,9,253,3,0,
295,9,259,4,0,
295,9,263,4,0,
295,9,269,4,0,
295,9,280,4,0,
295,9,290,4,0,
295,9,304,1,63,
295,9,310,1,1,6
295,9,310,1,11,
295,9,315,4,0,
295,9,317,4,0,
295,9,336,1,1,7
295,9,336,1,15,
295,9,351,4,0,
295,9,352,4,0,
295,9,363,4,0,
295,9,374,4,0,
295,9,411,4,0,
295,9,416,4,0,
295,9,419,4,0,
295,9,422,1,1,3
295,9,423,1,1,1
295,9,424,1,1,2
295,9,428,3,0,
295,9,431,4,0,
295,9,445,4,0,
295,10,1,1,1,4
295,10,7,3,0,
295,10,8,3,0,
295,10,9,3,0,
295,10,23,1,29,
295,10,29,3,0,
295,10,44,1,20,
295,10,46,1,45,
295,10,46,4,0,
295,10,48,1,23,
295,10,53,4,0,
295,10,57,4,0,
295,10,58,4,0,
295,10,59,4,0,
295,10,63,1,71,
295,10,63,4,0,
295,10,67,3,0,
295,10,70,4,0,
295,10,76,4,0,
295,10,89,4,0,
295,10,92,4,0,
295,10,103,1,37,
295,10,104,4,0,
295,10,126,4,0,
295,10,156,1,55,1
295,10,156,4,0,
295,10,157,4,0,
295,10,164,4,0,
295,10,173,3,0,
295,10,182,4,0,
295,10,189,3,0,
295,10,196,3,0,
295,10,200,3,0,
295,10,203,4,0,
295,10,205,3,0,
295,10,207,4,0,
295,10,213,4,0,
295,10,214,1,55,2
295,10,214,4,0,
295,10,216,4,0,
295,10,218,4,0,
295,10,237,4,0,
295,10,240,4,0,
295,10,241,4,0,
295,10,242,1,40,
295,10,247,4,0,
295,10,249,4,0,
295,10,250,4,0,
295,10,253,1,1,5
295,10,253,1,5,
295,10,253,3,0,
295,10,259,4,0,
295,10,263,4,0,
295,10,269,4,0,
295,10,280,4,0,
295,10,290,4,0,
295,10,304,1,63,
295,10,310,1,1,6
295,10,310,1,11,
295,10,315,4,0,
295,10,317,4,0,
295,10,336,1,1,7
295,10,336,1,15,
295,10,351,4,0,
295,10,352,4,0,
295,10,363,4,0,
295,10,374,4,0,
295,10,411,4,0,
295,10,416,4,0,
295,10,419,4,0,
295,10,422,1,1,3
295,10,423,1,1,1
295,10,424,1,1,2
295,10,428,3,0,
295,10,431,4,0,
295,10,445,4,0,
295,11,1,1,1,4
295,11,23,1,29,
295,11,44,1,20,
295,11,46,1,45,
295,11,46,4,0,
295,11,48,1,23,
295,11,53,4,0,
295,11,57,4,0,
295,11,58,4,0,
295,11,59,4,0,
295,11,63,1,79,
295,11,63,4,0,
295,11,70,4,0,
295,11,76,4,0,
295,11,89,4,0,
295,11,92,4,0,
295,11,103,1,37,
295,11,104,4,0,
295,11,126,4,0,
295,11,156,1,55,2
295,11,156,4,0,
295,11,157,4,0,
295,11,164,4,0,
295,11,182,4,0,
295,11,207,4,0,
295,11,213,4,0,
295,11,214,1,63,
295,11,216,4,0,
295,11,218,4,0,
295,11,237,4,0,
295,11,240,4,0,
295,11,241,4,0,
295,11,242,1,40,
295,11,247,4,0,
295,11,249,4,0,
295,11,253,1,1,5
295,11,253,1,5,
295,11,259,4,0,
295,11,263,4,0,
295,11,269,4,0,
295,11,280,4,0,
295,11,304,1,71,
295,11,310,1,1,6
295,11,310,1,11,
295,11,315,4,0,
295,11,317,4,0,
295,11,336,1,1,7
295,11,336,1,15,
295,11,374,4,0,
295,11,411,4,0,
295,11,416,4,0,
295,11,422,1,1,3
295,11,423,1,1,1
295,11,424,1,1,2
295,11,479,4,0,
295,11,485,1,55,1
295,11,496,4,0,
295,11,497,4,0,
295,11,510,4,0,
295,11,514,4,0,
295,11,523,4,0,
295,11,526,4,0,
295,12,1,1,1,1
295,12,23,1,29,
295,12,46,1,45,
295,12,46,4,0,
295,12,48,1,23,
295,12,53,4,0,
295,12,58,4,0,
295,12,59,4,0,
295,12,63,1,40,
295,12,63,4,0,
295,12,70,4,0,
295,12,76,4,0,
295,12,89,4,0,
295,12,92,4,0,
295,12,103,1,37,
295,12,104,4,0,
295,12,126,4,0,
295,12,156,1,55,
295,12,156,4,0,
295,12,182,4,0,
295,12,213,4,0,
295,12,214,1,55,5
295,12,216,4,0,
295,12,218,4,0,
295,12,237,4,0,
295,12,240,4,0,
295,12,241,4,0,
295,12,247,4,0,
295,12,249,4,0,
295,12,253,1,1,2
295,12,253,1,5,
295,12,259,4,0,
295,12,263,4,0,
295,12,269,4,0,
295,12,280,4,0,
295,12,290,4,0,
295,12,304,1,63,
295,12,310,1,1,3
295,12,310,1,11,
295,12,315,4,0,
295,12,336,1,1,4
295,12,336,1,15,
295,12,351,4,0,
295,12,352,4,0,
295,13,1,1,1,1
295,13,23,1,29,
295,13,34,3,0,
295,13,38,3,0,
295,13,46,1,45,
295,13,46,4,0,
295,13,48,1,23,
295,13,53,4,0,
295,13,58,4,0,
295,13,59,4,0,
295,13,63,1,40,
295,13,63,4,0,
295,13,69,3,0,
295,13,70,4,0,
295,13,76,4,0,
295,13,89,4,0,
295,13,92,4,0,
295,13,102,3,0,
295,13,103,1,37,
295,13,104,4,0,
295,13,126,4,0,
295,13,156,1,55,
295,13,156,4,0,
295,13,164,3,0,
295,13,182,4,0,
295,13,196,3,0,
295,13,207,3,0,
295,13,213,4,0,
295,13,214,1,55,5
295,13,216,4,0,
295,13,218,4,0,
295,13,237,4,0,
295,13,240,4,0,
295,13,241,4,0,
295,13,247,4,0,
295,13,249,4,0,
295,13,253,1,1,2
295,13,253,1,5,
295,13,259,4,0,
295,13,263,4,0,
295,13,269,4,0,
295,13,280,4,0,
295,13,290,4,0,
295,13,304,1,63,
295,13,310,1,1,3
295,13,310,1,11,
295,13,315,4,0,
295,13,336,1,1,4
295,13,336,1,15,
295,13,351,4,0,
295,13,352,4,0,
295,14,1,1,1,4
295,14,7,3,0,
295,14,8,3,0,
295,14,9,3,0,
295,14,23,1,29,
295,14,44,1,20,
295,14,46,1,45,
295,14,46,4,0,
295,14,48,1,23,
295,14,53,4,0,
295,14,57,4,0,
295,14,58,4,0,
295,14,59,4,0,
295,14,63,1,79,
295,14,63,4,0,
295,14,67,3,0,
295,14,70,4,0,
295,14,76,4,0,
295,14,89,4,0,
295,14,92,4,0,
295,14,103,1,37,
295,14,104,4,0,
295,14,126,4,0,
295,14,156,1,55,2
295,14,156,4,0,
295,14,157,4,0,
295,14,164,4,0,
295,14,173,3,0,
295,14,182,4,0,
295,14,196,3,0,
295,14,200,3,0,
295,14,207,4,0,
295,14,213,4,0,
295,14,214,1,63,
295,14,214,3,0,
295,14,216,4,0,
295,14,218,4,0,
295,14,237,4,0,
295,14,240,4,0,
295,14,241,4,0,
295,14,242,1,40,
295,14,247,4,0,
295,14,249,4,0,
295,14,253,1,1,5
295,14,253,1,5,
295,14,253,3,0,
295,14,259,4,0,
295,14,263,4,0,
295,14,269,4,0,
295,14,280,4,0,
295,14,283,3,0,
295,14,304,1,71,
295,14,304,3,0,
295,14,310,1,1,6
295,14,310,1,11,
295,14,315,4,0,
295,14,317,4,0,
295,14,336,1,1,7
295,14,336,1,15,
295,14,374,4,0,
295,14,411,4,0,
295,14,416,4,0,
295,14,422,1,1,3
295,14,423,1,1,1
295,14,424,1,1,2
295,14,428,3,0,
295,14,479,4,0,
295,14,485,1,55,1
295,14,496,4,0,
295,14,497,4,0,
295,14,510,4,0,
295,14,514,4,0,
295,14,523,4,0,
295,14,526,4,0,
295,15,1,1,1,5
295,15,23,1,29,
295,15,44,1,20,
295,15,46,1,45,
295,15,46,4,0,
295,15,48,1,23,
295,15,53,4,0,
295,15,57,4,0,
295,15,58,4,0,
295,15,59,4,0,
295,15,63,1,79,
295,15,63,4,0,
295,15,70,4,0,
295,15,76,4,0,
295,15,89,4,0,
295,15,92,4,0,
295,15,103,1,37,
295,15,104,4,0,
295,15,126,4,0,
295,15,156,1,55,2
295,15,156,4,0,
295,15,157,4,0,
295,15,164,4,0,
295,15,182,4,0,
295,15,207,4,0,
295,15,213,4,0,
295,15,214,1,63,
295,15,214,4,0,
295,15,216,4,0,
295,15,218,4,0,
295,15,237,4,0,
295,15,240,4,0,
295,15,241,4,0,
295,15,242,1,40,
295,15,247,4,0,
295,15,249,4,0,
295,15,253,1,1,6
295,15,253,1,5,
295,15,259,4,0,
295,15,263,4,0,
295,15,269,4,0,
295,15,280,4,0,
295,15,304,1,71,
295,15,310,1,1,7
295,15,310,1,11,
295,15,315,4,0,
295,15,317,4,0,
295,15,336,1,1,8
295,15,336,1,15,
295,15,374,4,0,
295,15,411,4,0,
295,15,416,4,0,
295,15,422,1,1,4
295,15,423,1,1,2
295,15,424,1,1,3
295,15,479,4,0,
295,15,485,1,55,1
295,15,496,4,0,
295,15,497,4,0,
295,15,510,4,0,
295,15,514,4,0,
295,15,523,4,0,
295,15,586,1,1,1
295,15,586,1,85,
295,15,590,4,0,
295,15,612,4,0,
295,16,1,1,1,5
295,16,7,3,0,
295,16,8,3,0,
295,16,9,3,0,
295,16,23,1,23,1
295,16,44,1,20,1
295,16,46,1,32,1
295,16,46,4,0,
295,16,48,1,18,1
295,16,53,4,0,
295,16,57,4,0,
295,16,58,4,0,
295,16,59,4,0,
295,16,63,1,64,1
295,16,63,4,0,
295,16,67,3,0,
295,16,70,4,0,
295,16,76,4,0,
295,16,89,4,0,
295,16,92,4,0,
295,16,103,1,15,1
295,16,104,4,0,
295,16,126,4,0,
295,16,156,1,36,1
295,16,156,4,0,
295,16,157,4,0,
295,16,164,4,0,
295,16,173,3,0,
295,16,182,4,0,
295,16,196,3,0,
295,16,200,3,0,
295,16,207,4,0,
295,16,213,4,0,
295,16,214,1,42,1
295,16,214,4,0,
295,16,216,4,0,
295,16,218,4,0,
295,16,237,4,0,
295,16,240,4,0,
295,16,241,4,0,
295,16,242,1,40,1
295,16,247,4,0,
295,16,249,4,0,
295,16,253,1,27,1
295,16,253,3,0,
295,16,259,4,0,
295,16,263,4,0,
295,16,269,4,0,
295,16,280,4,0,
295,16,283,3,0,
295,16,290,4,0,
295,16,304,1,47,1
295,16,304,3,0,
295,16,310,1,1,7
295,16,310,1,9,1
295,16,315,4,0,
295,16,317,4,0,
295,16,336,1,1,8
295,16,336,1,11,1
295,16,351,3,0,
295,16,352,3,0,
295,16,374,4,0,
295,16,411,4,0,
295,16,416,4,0,
295,16,422,1,1,4
295,16,423,1,1,2
295,16,424,1,1,3
295,16,428,3,0,
295,16,479,4,0,
295,16,485,1,53,1
295,16,496,4,0,
295,16,497,1,1,6
295,16,497,1,4,1
295,16,497,4,0,
295,16,510,4,0,
295,16,514,4,0,
295,16,523,4,0,
295,16,586,1,1,1
295,16,586,1,58,1
295,16,590,4,0,
295,16,612,4,0,
295,17,1,1,1,7
295,17,23,1,23,
295,17,44,1,1,2
295,17,46,1,32,
295,17,46,4,0,
295,17,48,1,18,
295,17,53,4,0,
295,17,57,4,0,
295,17,58,4,0,
295,17,59,4,0,
295,17,63,1,64,
295,17,63,4,0,
295,17,76,4,0,
295,17,89,4,0,
295,17,92,4,0,
295,17,103,1,15,
295,17,104,4,0,
295,17,126,4,0,
295,17,156,1,36,
295,17,156,4,0,
295,17,157,4,0,
295,17,164,4,0,
295,17,182,4,0,
295,17,207,4,0,
295,17,213,4,0,
295,17,214,1,42,
295,17,214,4,0,
295,17,216,4,0,
295,17,218,4,0,
295,17,237,4,0,
295,17,240,4,0,
295,17,241,4,0,
295,17,242,1,0,
295,17,242,1,1,1
295,17,247,4,0,
295,17,253,1,27,
295,17,259,4,0,
295,17,263,4,0,
295,17,269,4,0,
295,17,280,4,0,
295,17,304,1,47,
295,17,310,1,1,9
295,17,310,1,9,
295,17,315,4,0,
295,17,317,4,0,
295,17,336,1,1,10
295,17,336,1,11,
295,17,374,4,0,
295,17,411,4,0,
295,17,416,4,0,
295,17,422,1,1,6
295,17,423,1,1,4
295,17,424,1,1,5
295,17,479,4,0,
295,17,485,1,53,
295,17,496,4,0,
295,17,497,1,1,8
295,17,497,1,4,
295,17,497,4,0,
295,17,523,4,0,
295,17,526,4,0,
295,17,586,1,1,3
295,17,586,1,58,
295,17,590,4,0,
295,18,1,1,1,7
295,18,23,1,23,
295,18,44,1,1,2
295,18,46,1,32,
295,18,46,4,0,
295,18,48,1,18,
295,18,53,4,0,
295,18,57,4,0,
295,18,58,4,0,
295,18,59,4,0,
295,18,63,1,64,
295,18,63,4,0,
295,18,76,4,0,
295,18,89,4,0,
295,18,92,4,0,
295,18,103,1,15,
295,18,104,4,0,
295,18,126,4,0,
295,18,156,1,36,
295,18,156,4,0,
295,18,157,4,0,
295,18,164,4,0,
295,18,182,4,0,
295,18,207,4,0,
295,18,213,4,0,
295,18,214,1,42,
295,18,214,4,0,
295,18,216,4,0,
295,18,218,4,0,
295,18,237,4,0,
295,18,240,4,0,
295,18,241,4,0,
295,18,242,1,0,
295,18,242,1,1,1
295,18,247,4,0,
295,18,253,1,27,
295,18,259,4,0,
295,18,263,4,0,
295,18,269,4,0,
295,18,280,4,0,
295,18,304,1,47,
295,18,310,1,1,9
295,18,310,1,9,
295,18,315,4,0,
295,18,317,4,0,
295,18,336,1,1,10
295,18,336,1,11,
295,18,374,4,0,
295,18,411,4,0,
295,18,416,4,0,
295,18,422,1,1,6
295,18,423,1,1,4
295,18,424,1,1,5
295,18,479,4,0,
295,18,485,1,53,
295,18,496,4,0,
295,18,497,1,1,8
295,18,497,1,4,
295,18,497,4,0,
295,18,523,4,0,
295,18,526,4,0,
295,18,586,1,1,3
295,18,586,1,58,
295,18,590,4,0,
296,5,18,1,22,
296,5,28,1,4,
296,5,33,1,1,1
296,5,57,4,0,
296,5,68,2,0,
296,5,69,1,46,
296,5,70,4,0,
296,5,89,4,0,
296,5,91,4,0,
296,5,92,4,0,
296,5,104,4,0,
296,5,116,1,1,2
296,5,156,4,0,
296,5,179,1,49,
296,5,182,4,0,
296,5,185,2,0,
296,5,187,1,37,
296,5,193,2,0,
296,5,197,2,0,
296,5,203,1,40,
296,5,213,4,0,
296,5,216,4,0,
296,5,218,4,0,
296,5,223,2,0,
296,5,233,1,13,
296,5,237,4,0,
296,5,238,2,0,
296,5,240,4,0,
296,5,241,4,0,
296,5,249,4,0,
296,5,252,1,19,
296,5,263,4,0,
296,5,264,4,0,
296,5,265,1,31,
296,5,270,2,0,
296,5,279,2,0,
296,5,280,4,0,
296,5,282,1,28,
296,5,290,4,0,
296,5,292,1,10,
296,5,317,4,0,
296,5,339,4,0,
296,6,5,3,0,
296,6,7,3,0,
296,6,8,3,0,
296,6,9,3,0,
296,6,18,1,22,
296,6,25,3,0,
296,6,28,1,4,
296,6,33,1,1,1
296,6,34,3,0,
296,6,38,3,0,
296,6,57,4,0,
296,6,68,2,0,
296,6,68,3,0,
296,6,69,1,46,
296,6,69,3,0,
296,6,70,4,0,
296,6,89,4,0,
296,6,91,4,0,
296,6,92,4,0,
296,6,102,3,0,
296,6,104,4,0,
296,6,116,1,1,2
296,6,118,3,0,
296,6,156,4,0,
296,6,157,3,0,
296,6,164,3,0,
296,6,173,3,0,
296,6,179,1,49,
296,6,182,4,0,
296,6,185,2,0,
296,6,187,1,37,
296,6,189,3,0,
296,6,193,2,0,
296,6,197,2,0,
296,6,203,1,40,
296,6,203,3,0,
296,6,207,3,0,
296,6,213,4,0,
296,6,214,3,0,
296,6,216,4,0,
296,6,218,4,0,
296,6,223,2,0,
296,6,223,3,0,
296,6,233,1,13,
296,6,237,4,0,
296,6,238,2,0,
296,6,240,4,0,
296,6,241,4,0,
296,6,249,4,0,
296,6,252,1,19,
296,6,263,4,0,
296,6,264,4,0,
296,6,265,1,31,
296,6,270,2,0,
296,6,279,2,0,
296,6,280,4,0,
296,6,282,1,28,
296,6,290,4,0,
296,6,292,1,10,
296,6,317,4,0,
296,6,339,4,0,
296,7,5,3,0,
296,7,18,1,22,
296,7,25,3,0,
296,7,28,1,4,
296,7,33,1,1,1
296,7,34,3,0,
296,7,38,3,0,
296,7,57,4,0,
296,7,68,2,0,
296,7,68,3,0,
296,7,69,1,46,
296,7,69,3,0,
296,7,70,4,0,
296,7,89,4,0,
296,7,91,4,0,
296,7,92,4,0,
296,7,102,3,0,
296,7,104,4,0,
296,7,116,1,1,2
296,7,118,3,0,
296,7,156,4,0,
296,7,157,3,0,
296,7,164,3,0,
296,7,179,1,49,
296,7,182,4,0,
296,7,185,2,0,
296,7,187,1,37,
296,7,193,2,0,
296,7,197,2,0,
296,7,203,1,40,
296,7,213,4,0,
296,7,216,4,0,
296,7,218,4,0,
296,7,223,2,0,
296,7,233,1,13,
296,7,237,4,0,
296,7,238,2,0,
296,7,240,4,0,
296,7,241,4,0,
296,7,249,4,0,
296,7,252,1,19,
296,7,263,4,0,
296,7,264,4,0,
296,7,265,1,31,
296,7,270,2,0,
296,7,279,2,0,
296,7,280,4,0,
296,7,282,1,28,
296,7,290,4,0,
296,7,292,1,10,
296,7,317,4,0,
296,7,339,4,0,
296,8,18,1,16,
296,8,28,1,4,
296,8,33,1,1,1
296,8,57,4,0,
296,8,68,2,0,
296,8,69,1,31,
296,8,70,4,0,
296,8,89,4,0,
296,8,91,4,0,
296,8,92,4,0,
296,8,104,4,0,
296,8,116,1,1,2
296,8,156,4,0,
296,8,157,4,0,
296,8,164,4,0,
296,8,179,1,43,
296,8,182,4,0,
296,8,185,2,0,
296,8,187,1,25,
296,8,193,2,0,
296,8,197,2,0,
296,8,203,1,37,
296,8,203,4,0,
296,8,207,4,0,
296,8,213,4,0,
296,8,214,4,0,
296,8,216,4,0,
296,8,218,4,0,
296,8,223,2,0,
296,8,233,1,10,
296,8,237,4,0,
296,8,238,2,0,
296,8,240,4,0,
296,8,241,4,0,
296,8,249,4,0,
296,8,252,1,13,
296,8,263,4,0,
296,8,264,4,0,
296,8,265,1,22,
296,8,270,2,0,
296,8,279,2,0,
296,8,280,4,0,
296,8,282,1,19,
296,8,290,4,0,
296,8,292,1,7,
296,8,317,4,0,
296,8,339,4,0,
296,8,358,1,34,
296,8,358,2,0,
296,8,363,4,0,
296,8,370,1,40,
296,8,374,4,0,
296,8,395,1,28,
296,8,398,4,0,
296,8,411,4,0,
296,8,418,2,0,
296,8,431,4,0,
296,8,445,4,0,
296,9,7,3,0,
296,9,8,3,0,
296,9,9,3,0,
296,9,18,1,16,
296,9,28,1,4,
296,9,33,1,1,1
296,9,57,4,0,
296,9,68,2,0,
296,9,69,1,31,
296,9,70,4,0,
296,9,89,4,0,
296,9,91,4,0,
296,9,92,4,0,
296,9,104,4,0,
296,9,116,1,1,2
296,9,156,4,0,
296,9,157,4,0,
296,9,164,4,0,
296,9,173,3,0,
296,9,179,1,43,
296,9,182,4,0,
296,9,185,2,0,
296,9,187,1,25,
296,9,189,3,0,
296,9,193,2,0,
296,9,197,2,0,
296,9,203,1,37,
296,9,203,4,0,
296,9,207,4,0,
296,9,213,4,0,
296,9,214,4,0,
296,9,216,4,0,
296,9,218,4,0,
296,9,223,2,0,
296,9,233,1,10,
296,9,237,4,0,
296,9,238,2,0,
296,9,240,4,0,
296,9,241,4,0,
296,9,249,4,0,
296,9,252,1,13,
296,9,263,4,0,
296,9,264,4,0,
296,9,265,1,22,
296,9,270,2,0,
296,9,270,3,0,
296,9,276,3,0,
296,9,279,2,0,
296,9,280,4,0,
296,9,282,1,19,
296,9,282,3,0,
296,9,290,4,0,
296,9,292,1,7,
296,9,317,4,0,
296,9,339,4,0,
296,9,358,1,34,
296,9,358,2,0,
296,9,363,4,0,
296,9,370,1,40,
296,9,374,4,0,
296,9,395,1,28,
296,9,398,4,0,
296,9,410,3,0,
296,9,411,4,0,
296,9,418,2,0,
296,9,431,4,0,
296,9,445,4,0,
296,10,7,3,0,
296,10,8,3,0,
296,10,9,3,0,
296,10,18,1,16,
296,10,28,1,4,
296,10,29,3,0,
296,10,33,1,1,1
296,10,57,4,0,
296,10,67,3,0,
296,10,68,2,0,
296,10,69,1,31,
296,10,70,4,0,
296,10,89,4,0,
296,10,91,4,0,
296,10,92,4,0,
296,10,104,4,0,
296,10,116,1,1,2
296,10,156,4,0,
296,10,157,4,0,
296,10,164,4,0,
296,10,173,3,0,
296,10,179,1,43,
296,10,182,4,0,
296,10,185,2,0,
296,10,187,1,25,
296,10,189,3,0,
296,10,193,2,0,
296,10,197,2,0,
296,10,203,1,37,
296,10,203,4,0,
296,10,207,4,0,
296,10,213,4,0,
296,10,214,4,0,
296,10,216,4,0,
296,10,218,4,0,
296,10,223,2,0,
296,10,233,1,10,
296,10,237,4,0,
296,10,238,2,0,
296,10,240,4,0,
296,10,241,4,0,
296,10,249,4,0,
296,10,250,4,0,
296,10,252,1,13,
296,10,263,4,0,
296,10,264,4,0,
296,10,265,1,22,
296,10,270,2,0,
296,10,270,3,0,
296,10,272,3,0,
296,10,276,3,0,
296,10,279,2,0,
296,10,280,4,0,
296,10,282,1,19,
296,10,282,3,0,
296,10,290,4,0,
296,10,292,1,7,
296,10,317,4,0,
296,10,339,4,0,
296,10,358,1,34,
296,10,358,2,0,
296,10,363,4,0,
296,10,364,2,0,
296,10,370,1,40,
296,10,374,4,0,
296,10,395,1,28,
296,10,398,4,0,
296,10,410,3,0,
296,10,411,4,0,
296,10,418,2,0,
296,10,431,4,0,
296,10,445,4,0,
296,11,18,1,16,
296,11,28,1,4,
296,11,33,1,1,1
296,11,57,4,0,
296,11,68,2,0,
296,11,69,1,31,
296,11,70,4,0,
296,11,89,4,0,
296,11,91,4,0,
296,11,92,4,0,
296,11,104,4,0,
296,11,116,1,1,2
296,11,156,4,0,
296,11,157,4,0,
296,11,164,4,0,
296,11,179,1,43,
296,11,182,4,0,
296,11,185,2,0,
296,11,187,1,25,
296,11,193,2,0,
296,11,197,2,0,
296,11,203,1,37,
296,11,207,4,0,
296,11,213,4,0,
296,11,216,4,0,
296,11,218,4,0,
296,11,223,2,0,
296,11,233,1,10,
296,11,237,4,0,
296,11,238,2,0,
296,11,240,4,0,
296,11,241,4,0,
296,11,249,4,0,
296,11,252,1,13,
296,11,263,4,0,
296,11,264,2,0,
296,11,265,1,22,
296,11,270,2,0,
296,11,279,2,0,
296,11,280,4,0,
296,11,282,1,19,
296,11,292,1,7,
296,11,317,4,0,
296,11,339,4,0,
296,11,358,1,34,
296,11,358,2,0,
296,11,364,2,0,
296,11,370,1,40,
296,11,374,4,0,
296,11,395,1,28,
296,11,398,4,0,
296,11,411,4,0,
296,11,418,2,0,
296,11,469,2,0,
296,11,479,4,0,
296,11,484,1,46,
296,11,490,4,0,
296,11,496,4,0,
296,11,498,2,0,
296,11,514,4,0,
296,11,523,4,0,
296,11,526,4,0,
296,12,18,1,22,
296,12,28,1,4,
296,12,33,1,1,1
296,12,57,4,0,
296,12,69,1,46,
296,12,70,4,0,
296,12,89,4,0,
296,12,91,4,0,
296,12,92,4,0,
296,12,104,4,0,
296,12,116,1,1,2
296,12,156,4,0,
296,12,179,1,49,
296,12,182,4,0,
296,12,187,1,37,
296,12,203,1,40,
296,12,213,4,0,
296,12,216,4,0,
296,12,218,4,0,
296,12,233,1,13,
296,12,237,4,0,
296,12,240,4,0,
296,12,241,4,0,
296,12,249,4,0,
296,12,252,1,19,
296,12,263,4,0,
296,12,264,4,0,
296,12,265,1,31,
296,12,280,4,0,
296,12,282,1,28,
296,12,290,4,0,
296,12,292,1,10,
296,12,317,4,0,
296,12,339,4,0,
296,13,18,1,22,
296,13,28,1,4,
296,13,33,1,1,1
296,13,34,3,0,
296,13,38,3,0,
296,13,57,4,0,
296,13,69,1,46,
296,13,69,3,0,
296,13,70,4,0,
296,13,89,4,0,
296,13,91,4,0,
296,13,92,4,0,
296,13,102,3,0,
296,13,104,4,0,
296,13,116,1,1,2
296,13,156,4,0,
296,13,164,3,0,
296,13,179,1,49,
296,13,182,4,0,
296,13,187,1,37,
296,13,203,1,40,
296,13,207,3,0,
296,13,213,4,0,
296,13,216,4,0,
296,13,218,4,0,
296,13,233,1,13,
296,13,237,4,0,
296,13,240,4,0,
296,13,241,4,0,
296,13,249,4,0,
296,13,252,1,19,
296,13,263,4,0,
296,13,264,4,0,
296,13,265,1,31,
296,13,280,4,0,
296,13,282,1,28,
296,13,290,4,0,
296,13,292,1,10,
296,13,317,4,0,
296,13,339,4,0,
296,14,7,3,0,
296,14,8,3,0,
296,14,9,3,0,
296,14,18,1,16,
296,14,28,1,4,
296,14,33,1,1,1
296,14,57,4,0,
296,14,67,3,0,
296,14,68,2,0,
296,14,69,1,31,
296,14,70,4,0,
296,14,89,4,0,
296,14,91,4,0,
296,14,92,4,0,
296,14,104,4,0,
296,14,116,1,1,2
296,14,156,4,0,
296,14,157,4,0,
296,14,164,4,0,
296,14,173,3,0,
296,14,179,1,43,
296,14,182,4,0,
296,14,185,2,0,
296,14,187,1,25,
296,14,193,2,0,
296,14,197,2,0,
296,14,203,1,37,
296,14,207,4,0,
296,14,213,4,0,
296,14,214,3,0,
296,14,216,4,0,
296,14,218,4,0,
296,14,223,2,0,
296,14,233,1,10,
296,14,237,4,0,
296,14,238,2,0,
296,14,240,4,0,
296,14,241,4,0,
296,14,249,4,0,
296,14,252,1,13,
296,14,263,4,0,
296,14,264,2,0,
296,14,265,1,22,
296,14,270,2,0,
296,14,270,3,0,
296,14,272,3,0,
296,14,276,3,0,
296,14,279,2,0,
296,14,280,4,0,
296,14,282,1,19,
296,14,282,3,0,
296,14,292,1,7,
296,14,317,4,0,
296,14,339,4,0,
296,14,358,1,34,
296,14,358,2,0,
296,14,364,2,0,
296,14,370,1,40,
296,14,374,4,0,
296,14,395,1,28,
296,14,398,4,0,
296,14,411,4,0,
296,14,418,2,0,
296,14,469,2,0,
296,14,479,4,0,
296,14,484,1,46,
296,14,490,4,0,
296,14,496,4,0,
296,14,498,2,0,
296,14,514,4,0,
296,14,523,4,0,
296,14,526,4,0,
296,15,18,1,16,
296,15,28,1,4,
296,15,33,1,1,1
296,15,57,4,0,
296,15,68,2,0,
296,15,69,1,31,
296,15,70,4,0,
296,15,89,4,0,
296,15,91,4,0,
296,15,92,4,0,
296,15,104,4,0,
296,15,116,1,1,2
296,15,156,4,0,
296,15,157,4,0,
296,15,164,4,0,
296,15,179,1,43,
296,15,182,4,0,
296,15,185,2,0,
296,15,187,1,25,
296,15,193,2,0,
296,15,197,2,0,
296,15,203,1,37,
296,15,207,4,0,
296,15,213,4,0,
296,15,214,4,0,
296,15,216,4,0,
296,15,218,4,0,
296,15,223,2,0,
296,15,233,1,10,
296,15,237,4,0,
296,15,238,2,0,
296,15,240,4,0,
296,15,241,4,0,
296,15,249,4,0,
296,15,252,1,13,
296,15,263,4,0,
296,15,264,2,0,
296,15,265,1,22,
296,15,270,2,0,
296,15,279,2,0,
296,15,280,4,0,
296,15,282,1,19,
296,15,292,1,7,
296,15,317,4,0,
296,15,339,4,0,
296,15,358,1,34,
296,15,358,2,0,
296,15,364,2,0,
296,15,370,1,40,
296,15,374,4,0,
296,15,395,1,28,
296,15,398,4,0,
296,15,411,4,0,
296,15,418,2,0,
296,15,469,2,0,
296,15,479,4,0,
296,15,484,1,46,
296,15,490,4,0,
296,15,496,4,0,
296,15,498,2,0,
296,15,514,4,0,
296,15,523,4,0,
296,15,590,4,0,
296,15,612,4,0,
296,16,7,3,0,
296,16,8,3,0,
296,16,9,3,0,
296,16,18,1,16,1
296,16,28,1,4,1
296,16,33,1,1,1
296,16,57,4,0,
296,16,67,3,0,
296,16,68,2,0,
296,16,69,1,31,1
296,16,70,4,0,
296,16,89,4,0,
296,16,91,4,0,
296,16,92,4,0,
296,16,104,4,0,
296,16,116,1,1,2
296,16,156,4,0,
296,16,157,4,0,
296,16,164,4,0,
296,16,173,3,0,
296,16,179,1,43,1
296,16,182,4,0,
296,16,185,2,0,
296,16,187,1,25,1
296,16,193,2,0,
296,16,197,2,0,
296,16,203,1,37,1
296,16,207,4,0,
296,16,213,4,0,
296,16,214,4,0,
296,16,216,4,0,
296,16,218,4,0,
296,16,223,2,0,
296,16,233,1,22,1
296,16,237,4,0,
296,16,238,2,0,
296,16,240,4,0,
296,16,241,4,0,
296,16,249,4,0,
296,16,252,1,10,1
296,16,263,4,0,
296,16,264,2,0,
296,16,264,3,0,
296,16,265,1,28,1
296,16,270,2,0,
296,16,270,3,0,
296,16,272,3,0,
296,16,276,3,0,
296,16,279,2,0,
296,16,280,4,0,
296,16,282,1,19,1
296,16,282,3,0,
296,16,290,4,0,
296,16,292,1,7,1
296,16,317,4,0,
296,16,339,4,0,
296,16,358,1,34,1
296,16,358,2,0,
296,16,364,2,0,
296,16,370,1,40,1
296,16,374,4,0,
296,16,395,1,13,1
296,16,398,4,0,
296,16,411,4,0,
296,16,418,2,0,
296,16,469,2,0,
296,16,479,4,0,
296,16,484,1,46,1
296,16,490,4,0,
296,16,496,4,0,
296,16,498,2,0,
296,16,514,4,0,
296,16,523,4,0,
296,16,590,4,0,
296,16,612,4,0,
296,17,18,1,16,
296,17,28,1,4,
296,17,33,1,1,1
296,17,57,4,0,
296,17,68,2,0,
296,17,69,1,31,
296,17,89,4,0,
296,17,92,4,0,
296,17,104,4,0,
296,17,116,1,1,2
296,17,156,4,0,
296,17,157,4,0,
296,17,164,4,0,
296,17,179,1,43,
296,17,182,4,0,
296,17,185,2,0,
296,17,187,1,25,
296,17,193,2,0,
296,17,197,2,0,
296,17,203,1,37,
296,17,207,4,0,
296,17,213,4,0,
296,17,214,4,0,
296,17,216,4,0,
296,17,218,4,0,
296,17,223,2,0,
296,17,233,1,22,
296,17,237,4,0,
296,17,238,2,0,
296,17,240,4,0,
296,17,241,4,0,
296,17,252,1,10,
296,17,263,4,0,
296,17,264,2,0,
296,17,265,1,28,
296,17,270,2,0,
296,17,279,2,0,
296,17,280,4,0,
296,17,282,1,19,
296,17,292,1,7,
296,17,317,4,0,
296,17,339,4,0,
296,17,358,1,34,
296,17,358,2,0,
296,17,364,2,0,
296,17,370,1,40,
296,17,374,4,0,
296,17,395,1,13,
296,17,398,4,0,
296,17,411,4,0,
296,17,418,2,0,
296,17,469,2,0,
296,17,479,4,0,
296,17,484,1,46,
296,17,490,4,0,
296,17,496,4,0,
296,17,498,2,0,
296,17,523,4,0,
296,17,526,4,0,
296,17,590,4,0,
296,18,18,1,16,
296,18,28,1,4,
296,18,33,1,1,1
296,18,57,4,0,
296,18,68,2,0,
296,18,69,1,31,
296,18,89,4,0,
296,18,92,4,0,
296,18,104,4,0,
296,18,116,1,1,2
296,18,156,4,0,
296,18,157,4,0,
296,18,164,4,0,
296,18,179,1,43,
296,18,182,4,0,
296,18,185,2,0,
296,18,187,1,25,
296,18,193,2,0,
296,18,197,2,0,
296,18,203,1,37,
296,18,207,4,0,
296,18,213,4,0,
296,18,214,4,0,
296,18,216,4,0,
296,18,218,4,0,
296,18,223,2,0,
296,18,233,1,22,
296,18,237,4,0,
296,18,238,2,0,
296,18,240,4,0,
296,18,241,4,0,
296,18,252,1,10,
296,18,263,4,0,
296,18,264,2,0,
296,18,265,1,28,
296,18,270,2,0,
296,18,279,2,0,
296,18,280,4,0,
296,18,282,1,19,
296,18,292,1,7,
296,18,317,4,0,
296,18,339,4,0,
296,18,358,1,34,
296,18,358,2,0,
296,18,364,2,0,
296,18,370,1,40,
296,18,374,4,0,
296,18,395,1,13,
296,18,398,4,0,
296,18,411,4,0,
296,18,418,2,0,
296,18,469,2,0,
296,18,479,4,0,
296,18,484,1,46,
296,18,490,4,0,
296,18,496,4,0,
296,18,498,2,0,
296,18,523,4,0,
296,18,526,4,0,
296,18,590,4,0,
297,5,18,1,22,
297,5,28,1,1,3
297,5,28,1,4,
297,5,33,1,1,1
297,5,57,4,0,
297,5,63,4,0,
297,5,69,1,51,
297,5,70,4,0,
297,5,89,4,0,
297,5,91,4,0,
297,5,92,4,0,
297,5,104,4,0,
297,5,116,1,1,2
297,5,156,4,0,
297,5,179,1,55,
297,5,182,4,0,
297,5,187,1,40,
297,5,203,1,44,
297,5,213,4,0,
297,5,216,4,0,
297,5,218,4,0,
297,5,233,1,13,
297,5,237,4,0,
297,5,240,4,0,
297,5,241,4,0,
297,5,249,4,0,
297,5,252,1,19,
297,5,263,4,0,
297,5,264,4,0,
297,5,265,1,33,
297,5,280,4,0,
297,5,282,1,29,
297,5,290,4,0,
297,5,292,1,1,4
297,5,292,1,10,
297,5,317,4,0,
297,5,339,4,0,
297,6,5,3,0,
297,6,7,3,0,
297,6,8,3,0,
297,6,9,3,0,
297,6,18,1,22,
297,6,25,3,0,
297,6,28,1,1,3
297,6,28,1,4,
297,6,33,1,1,1
297,6,34,3,0,
297,6,38,3,0,
297,6,57,4,0,
297,6,63,4,0,
297,6,68,3,0,
297,6,69,1,51,
297,6,69,3,0,
297,6,70,4,0,
297,6,89,4,0,
297,6,91,4,0,
297,6,92,4,0,
297,6,102,3,0,
297,6,104,4,0,
297,6,116,1,1,2
297,6,118,3,0,
297,6,156,4,0,
297,6,157,3,0,
297,6,164,3,0,
297,6,173,3,0,
297,6,179,1,55,
297,6,182,4,0,
297,6,187,1,40,
297,6,189,3,0,
297,6,203,1,44,
297,6,203,3,0,
297,6,207,3,0,
297,6,213,4,0,
297,6,214,3,0,
297,6,216,4,0,
297,6,218,4,0,
297,6,223,3,0,
297,6,233,1,13,
297,6,237,4,0,
297,6,240,4,0,
297,6,241,4,0,
297,6,249,4,0,
297,6,252,1,19,
297,6,263,4,0,
297,6,264,4,0,
297,6,265,1,33,
297,6,280,4,0,
297,6,282,1,29,
297,6,290,4,0,
297,6,292,1,1,4
297,6,292,1,10,
297,6,317,4,0,
297,6,339,4,0,
297,7,5,3,0,
297,7,18,1,22,
297,7,25,3,0,
297,7,28,1,1,3
297,7,28,1,4,
297,7,33,1,1,1
297,7,34,3,0,
297,7,38,3,0,
297,7,57,4,0,
297,7,63,4,0,
297,7,68,3,0,
297,7,69,1,51,
297,7,69,3,0,
297,7,70,4,0,
297,7,89,4,0,
297,7,91,4,0,
297,7,92,4,0,
297,7,102,3,0,
297,7,104,4,0,
297,7,116,1,1,2
297,7,118,3,0,
297,7,156,4,0,
297,7,157,3,0,
297,7,164,3,0,
297,7,179,1,55,
297,7,182,4,0,
297,7,187,1,40,
297,7,203,1,44,
297,7,213,4,0,
297,7,216,4,0,
297,7,218,4,0,
297,7,233,1,13,
297,7,237,4,0,
297,7,240,4,0,
297,7,241,4,0,
297,7,249,4,0,
297,7,252,1,19,
297,7,263,4,0,
297,7,264,4,0,
297,7,265,1,33,
297,7,280,4,0,
297,7,282,1,29,
297,7,290,4,0,
297,7,292,1,1,4
297,7,292,1,10,
297,7,317,4,0,
297,7,339,4,0,
297,8,18,1,16,
297,8,28,1,1,4
297,8,28,1,4,
297,8,33,1,1,2
297,8,57,4,0,
297,8,63,4,0,
297,8,69,1,37,
297,8,70,4,0,
297,8,89,4,0,
297,8,91,4,0,
297,8,92,4,0,
297,8,104,4,0,
297,8,116,1,1,3
297,8,156,4,0,
297,8,157,4,0,
297,8,164,4,0,
297,8,179,1,57,
297,8,182,4,0,
297,8,187,1,27,
297,8,203,1,47,
297,8,203,4,0,
297,8,207,4,0,
297,8,213,4,0,
297,8,214,4,0,
297,8,216,4,0,
297,8,218,4,0,
297,8,233,1,10,
297,8,237,4,0,
297,8,240,4,0,
297,8,241,4,0,
297,8,249,4,0,
297,8,252,1,13,
297,8,263,4,0,
297,8,264,4,0,
297,8,265,1,22,
297,8,280,4,0,
297,8,282,1,19,
297,8,290,4,0,
297,8,292,1,1,5
297,8,292,1,7,
297,8,317,4,0,
297,8,339,4,0,
297,8,358,1,42,
297,8,362,1,1,1
297,8,362,4,0,
297,8,363,4,0,
297,8,370,1,52,
297,8,371,4,0,
297,8,374,4,0,
297,8,395,1,32,
297,8,398,4,0,
297,8,411,4,0,
297,8,416,4,0,
297,8,431,4,0,
297,8,444,4,0,
297,8,445,4,0,
297,9,7,3,0,
297,9,8,3,0,
297,9,9,3,0,
297,9,18,1,16,
297,9,28,1,1,4
297,9,28,1,4,
297,9,33,1,1,2
297,9,57,4,0,
297,9,63,4,0,
297,9,69,1,37,
297,9,70,4,0,
297,9,89,4,0,
297,9,91,4,0,
297,9,92,4,0,
297,9,104,4,0,
297,9,116,1,1,3
297,9,156,4,0,
297,9,157,4,0,
297,9,164,4,0,
297,9,173,3,0,
297,9,179,1,57,
297,9,182,4,0,
297,9,187,1,27,
297,9,189,3,0,
297,9,203,1,47,
297,9,203,4,0,
297,9,207,4,0,
297,9,213,4,0,
297,9,214,4,0,
297,9,216,4,0,
297,9,218,4,0,
297,9,233,1,10,
297,9,237,4,0,
297,9,240,4,0,
297,9,241,4,0,
297,9,249,4,0,
297,9,252,1,13,
297,9,263,4,0,
297,9,264,4,0,
297,9,265,1,22,
297,9,270,3,0,
297,9,276,3,0,
297,9,280,4,0,
297,9,282,1,19,
297,9,282,3,0,
297,9,290,4,0,
297,9,292,1,1,5
297,9,292,1,7,
297,9,317,4,0,
297,9,339,4,0,
297,9,358,1,42,
297,9,362,1,1,1
297,9,362,4,0,
297,9,363,4,0,
297,9,370,1,52,
297,9,371,4,0,
297,9,374,4,0,
297,9,395,1,32,
297,9,398,4,0,
297,9,410,3,0,
297,9,411,4,0,
297,9,416,4,0,
297,9,431,4,0,
297,9,442,3,0,
297,9,444,4,0,
297,9,445,4,0,
297,10,7,3,0,
297,10,8,3,0,
297,10,9,3,0,
297,10,18,1,16,
297,10,28,1,1,4
297,10,28,1,4,
297,10,29,3,0,
297,10,33,1,1,2
297,10,57,4,0,
297,10,63,4,0,
297,10,67,3,0,
297,10,69,1,37,
297,10,70,4,0,
297,10,89,4,0,
297,10,91,4,0,
297,10,92,4,0,
297,10,104,4,0,
297,10,116,1,1,3
297,10,156,4,0,
297,10,157,4,0,
297,10,164,4,0,
297,10,173,3,0,
297,10,179,1,57,
297,10,182,4,0,
297,10,187,1,27,
297,10,189,3,0,
297,10,203,1,47,
297,10,203,4,0,
297,10,207,4,0,
297,10,213,4,0,
297,10,214,4,0,
297,10,216,4,0,
297,10,218,4,0,
297,10,233,1,10,
297,10,237,4,0,
297,10,240,4,0,
297,10,241,4,0,
297,10,249,4,0,
297,10,250,4,0,
297,10,252,1,13,
297,10,263,4,0,
297,10,264,4,0,
297,10,265,1,22,
297,10,270,3,0,
297,10,272,3,0,
297,10,276,3,0,
297,10,280,4,0,
297,10,282,1,19,
297,10,282,3,0,
297,10,290,4,0,
297,10,292,1,1,5
297,10,292,1,7,
297,10,317,4,0,
297,10,339,4,0,
297,10,358,1,42,
297,10,362,1,1,1
297,10,362,4,0,
297,10,363,4,0,
297,10,370,1,52,
297,10,371,4,0,
297,10,374,4,0,
297,10,395,1,32,
297,10,398,4,0,
297,10,410,3,0,
297,10,411,4,0,
297,10,416,4,0,
297,10,431,4,0,
297,10,442,3,0,
297,10,444,4,0,
297,10,445,4,0,
297,11,18,1,16,
297,11,28,1,1,4
297,11,28,1,4,
297,11,33,1,1,2
297,11,57,4,0,
297,11,63,4,0,
297,11,69,1,37,
297,11,70,4,0,
297,11,89,4,0,
297,11,91,4,0,
297,11,92,4,0,
297,11,104,4,0,
297,11,116,1,1,3
297,11,156,4,0,
297,11,157,4,0,
297,11,164,4,0,
297,11,179,1,57,
297,11,182,4,0,
297,11,187,1,27,
297,11,203,1,47,
297,11,207,4,0,
297,11,213,4,0,
297,11,216,4,0,
297,11,218,4,0,
297,11,233,1,10,
297,11,237,4,0,
297,11,240,4,0,
297,11,241,4,0,
297,11,249,4,0,
297,11,252,1,13,
297,11,263,4,0,
297,11,265,1,22,
297,11,280,4,0,
297,11,282,1,19,
297,11,292,1,1,5
297,11,292,1,7,
297,11,317,4,0,
297,11,339,4,0,
297,11,358,1,42,
297,11,362,1,1,1
297,11,370,1,52,
297,11,371,4,0,
297,11,374,4,0,
297,11,395,1,32,
297,11,398,4,0,
297,11,411,4,0,
297,11,416,4,0,
297,11,444,4,0,
297,11,479,4,0,
297,11,484,1,62,
297,11,490,4,0,
297,11,496,4,0,
297,11,514,4,0,
297,11,523,4,0,
297,11,526,4,0,
297,12,18,1,22,
297,12,28,1,1,3
297,12,28,1,4,
297,12,33,1,1,1
297,12,57,4,0,
297,12,63,4,0,
297,12,69,1,51,
297,12,70,4,0,
297,12,89,4,0,
297,12,91,4,0,
297,12,92,4,0,
297,12,104,4,0,
297,12,116,1,1,2
297,12,156,4,0,
297,12,179,1,55,
297,12,182,4,0,
297,12,187,1,40,
297,12,203,1,44,
297,12,213,4,0,
297,12,216,4,0,
297,12,218,4,0,
297,12,233,1,13,
297,12,237,4,0,
297,12,240,4,0,
297,12,241,4,0,
297,12,249,4,0,
297,12,252,1,19,
297,12,263,4,0,
297,12,264,4,0,
297,12,265,1,33,
297,12,280,4,0,
297,12,282,1,29,
297,12,290,4,0,
297,12,292,1,1,4
297,12,292,1,10,
297,12,317,4,0,
297,12,339,4,0,
297,13,18,1,22,
297,13,28,1,1,3
297,13,28,1,4,
297,13,33,1,1,1
297,13,34,3,0,
297,13,38,3,0,
297,13,57,4,0,
297,13,63,4,0,
297,13,69,1,51,
297,13,69,3,0,
297,13,70,4,0,
297,13,89,4,0,
297,13,91,4,0,
297,13,92,4,0,
297,13,102,3,0,
297,13,104,4,0,
297,13,116,1,1,2
297,13,156,4,0,
297,13,164,3,0,
297,13,179,1,55,
297,13,182,4,0,
297,13,187,1,40,
297,13,203,1,44,
297,13,207,3,0,
297,13,213,4,0,
297,13,216,4,0,
297,13,218,4,0,
297,13,233,1,13,
297,13,237,4,0,
297,13,240,4,0,
297,13,241,4,0,
297,13,249,4,0,
297,13,252,1,19,
297,13,263,4,0,
297,13,264,4,0,
297,13,265,1,33,
297,13,280,4,0,
297,13,282,1,29,
297,13,290,4,0,
297,13,292,1,1,4
297,13,292,1,10,
297,13,317,4,0,
297,13,339,4,0,
297,14,7,3,0,
297,14,8,3,0,
297,14,9,3,0,
297,14,18,1,16,
297,14,28,1,1,4
297,14,28,1,4,
297,14,33,1,1,2
297,14,57,4,0,
297,14,63,4,0,
297,14,67,3,0,
297,14,69,1,37,
297,14,70,4,0,
297,14,89,4,0,
297,14,91,4,0,
297,14,92,4,0,
297,14,104,4,0,
297,14,116,1,1,3
297,14,156,4,0,
297,14,157,4,0,
297,14,164,4,0,
297,14,173,3,0,
297,14,179,1,57,
297,14,182,4,0,
297,14,187,1,27,
297,14,203,1,47,
297,14,207,4,0,
297,14,213,4,0,
297,14,214,3,0,
297,14,216,4,0,
297,14,218,4,0,
297,14,233,1,10,
297,14,237,4,0,
297,14,240,4,0,
297,14,241,4,0,
297,14,249,4,0,
297,14,252,1,13,
297,14,263,4,0,
297,14,265,1,22,
297,14,270,3,0,
297,14,272,3,0,
297,14,276,3,0,
297,14,280,4,0,
297,14,282,1,19,
297,14,282,3,0,
297,14,292,1,1,5
297,14,292,1,7,
297,14,317,4,0,
297,14,339,4,0,
297,14,358,1,42,
297,14,362,1,1,1
297,14,370,1,52,
297,14,371,4,0,
297,14,374,4,0,
297,14,395,1,32,
297,14,398,4,0,
297,14,411,4,0,
297,14,416,4,0,
297,14,442,3,0,
297,14,444,4,0,
297,14,479,4,0,
297,14,484,1,62,
297,14,490,4,0,
297,14,496,4,0,
297,14,514,4,0,
297,14,523,4,0,
297,14,526,4,0,
297,15,18,1,16,
297,15,28,1,1,4
297,15,28,1,4,
297,15,33,1,1,2
297,15,57,4,0,
297,15,63,4,0,
297,15,69,1,37,
297,15,70,4,0,
297,15,89,4,0,
297,15,91,4,0,
297,15,92,4,0,
297,15,104,4,0,
297,15,116,1,1,3
297,15,156,4,0,
297,15,157,4,0,
297,15,164,4,0,
297,15,179,1,57,
297,15,182,4,0,
297,15,187,1,27,
297,15,203,1,47,
297,15,207,4,0,
297,15,213,4,0,
297,15,214,4,0,
297,15,216,4,0,
297,15,218,4,0,
297,15,233,1,10,
297,15,237,4,0,
297,15,240,4,0,
297,15,241,4,0,
297,15,249,4,0,
297,15,252,1,13,
297,15,263,4,0,
297,15,265,1,22,
297,15,280,4,0,
297,15,282,1,19,
297,15,292,1,1,5
297,15,292,1,7,
297,15,317,4,0,
297,15,339,4,0,
297,15,358,1,42,
297,15,362,1,1,1
297,15,370,1,52,
297,15,371,4,0,
297,15,374,4,0,
297,15,395,1,32,
297,15,398,4,0,
297,15,411,4,0,
297,15,416,4,0,
297,15,444,4,0,
297,15,479,4,0,
297,15,484,1,62,
297,15,490,4,0,
297,15,496,4,0,
297,15,514,4,0,
297,15,523,4,0,
297,15,590,4,0,
297,15,612,4,0,
297,16,7,3,0,
297,16,8,3,0,
297,16,9,3,0,
297,16,18,1,16,1
297,16,28,1,1,4
297,16,28,1,4,1
297,16,33,1,1,2
297,16,57,4,0,
297,16,63,4,0,
297,16,67,3,0,
297,16,69,1,34,1
297,16,70,4,0,
297,16,89,4,0,
297,16,91,4,0,
297,16,92,4,0,
297,16,104,4,0,
297,16,116,1,1,3
297,16,156,4,0,
297,16,157,4,0,
297,16,164,4,0,
297,16,173,3,0,
297,16,179,1,50,1
297,16,182,4,0,
297,16,187,1,26,1
297,16,203,1,42,1
297,16,207,4,0,
297,16,213,4,0,
297,16,214,4,0,
297,16,216,4,0,
297,16,218,4,0,
297,16,233,1,22,1
297,16,237,4,0,
297,16,240,4,0,
297,16,241,4,0,
297,16,249,4,0,
297,16,252,1,10,1
297,16,263,4,0,
297,16,264,3,0,
297,16,265,1,30,1
297,16,270,3,0,
297,16,272,3,0,
297,16,276,3,0,
297,16,280,4,0,
297,16,282,1,19,1
297,16,282,3,0,
297,16,290,4,0,
297,16,292,1,1,5
297,16,292,1,7,1
297,16,317,4,0,
297,16,339,4,0,
297,16,358,1,38,1
297,16,362,1,1,1
297,16,370,1,46,1
297,16,371,4,0,
297,16,374,4,0,
297,16,395,1,13,1
297,16,398,4,0,
297,16,411,4,0,
297,16,416,4,0,
297,16,442,3,0,
297,16,444,4,0,
297,16,479,4,0,
297,16,484,1,54,1
297,16,490,4,0,
297,16,496,4,0,
297,16,514,4,0,
297,16,523,4,0,
297,16,590,4,0,
297,16,612,4,0,
297,17,18,1,16,
297,17,28,1,1,4
297,17,28,1,4,
297,17,33,1,1,2
297,17,57,4,0,
297,17,63,4,0,
297,17,69,1,34,
297,17,89,4,0,
297,17,92,4,0,
297,17,104,4,0,
297,17,116,1,1,3
297,17,156,4,0,
297,17,157,4,0,
297,17,164,4,0,
297,17,179,1,50,
297,17,182,4,0,
297,17,187,1,26,
297,17,203,1,42,
297,17,207,4,0,
297,17,213,4,0,
297,17,214,4,0,
297,17,216,4,0,
297,17,218,4,0,
297,17,233,1,22,
297,17,237,4,0,
297,17,240,4,0,
297,17,241,4,0,
297,17,252,1,10,
297,17,263,4,0,
297,17,265,1,30,
297,17,280,4,0,
297,17,282,1,19,
297,17,292,1,1,5
297,17,292,1,7,
297,17,317,4,0,
297,17,339,4,0,
297,17,358,1,38,
297,17,362,1,1,1
297,17,370,1,46,
297,17,371,4,0,
297,17,374,4,0,
297,17,395,1,13,
297,17,398,4,0,
297,17,411,4,0,
297,17,416,4,0,
297,17,444,4,0,
297,17,479,4,0,
297,17,484,1,54,
297,17,490,4,0,
297,17,496,4,0,
297,17,523,4,0,
297,17,526,4,0,
297,17,590,4,0,
297,18,18,1,16,
297,18,28,1,1,4
297,18,28,1,4,
297,18,33,1,1,2
297,18,57,4,0,
297,18,63,4,0,
297,18,69,1,34,
297,18,89,4,0,
297,18,92,4,0,
297,18,104,4,0,
297,18,116,1,1,3
297,18,156,4,0,
297,18,157,4,0,
297,18,164,4,0,
297,18,179,1,50,
297,18,182,4,0,
297,18,187,1,26,
297,18,203,1,42,
297,18,207,4,0,
297,18,213,4,0,
297,18,214,4,0,
297,18,216,4,0,
297,18,218,4,0,
297,18,233,1,22,
297,18,237,4,0,
297,18,240,4,0,
297,18,241,4,0,
297,18,252,1,10,
297,18,263,4,0,
297,18,265,1,30,
297,18,280,4,0,
297,18,282,1,19,
297,18,292,1,1,5
297,18,292,1,7,
297,18,317,4,0,
297,18,339,4,0,
297,18,358,1,38,
297,18,362,1,1,1
297,18,370,1,46,
297,18,371,4,0,
297,18,374,4,0,
297,18,395,1,13,
297,18,398,4,0,
297,18,411,4,0,
297,18,416,4,0,
297,18,444,4,0,
297,18,479,4,0,
297,18,484,1,54,
297,18,490,4,0,
297,18,496,4,0,
297,18,523,4,0,
297,18,526,4,0,
297,18,590,4,0,
298,5,21,1,15,
298,5,21,2,0,
298,5,39,1,6,
298,5,47,2,0,
298,5,55,1,21,
298,5,57,4,0,
298,5,58,4,0,
298,5,59,4,0,
298,5,92,4,0,
298,5,104,4,0,
298,5,127,4,0,
298,5,145,1,10,
298,5,150,1,1,
298,5,156,4,0,
298,5,182,4,0,
298,5,204,1,3,
298,5,213,4,0,
298,5,216,4,0,
298,5,218,4,0,
298,5,227,2,0,
298,5,231,4,0,
298,5,237,4,0,
298,5,240,4,0,
298,5,258,4,0,
298,5,263,4,0,
298,5,287,2,0,
298,5,290,4,0,
298,5,321,2,0,
298,5,352,4,0,
298,6,21,1,15,
298,6,21,2,0,
298,6,34,3,0,
298,6,38,3,0,
298,6,39,1,6,
298,6,47,2,0,
298,6,55,1,21,
298,6,57,4,0,
298,6,58,4,0,
298,6,59,4,0,
298,6,92,4,0,
298,6,102,3,0,
298,6,104,4,0,
298,6,111,3,0,
298,6,127,4,0,
298,6,129,3,0,
298,6,145,1,10,
298,6,150,1,1,
298,6,156,4,0,
298,6,164,3,0,
298,6,173,3,0,
298,6,182,4,0,
298,6,189,3,0,
298,6,196,3,0,
298,6,203,3,0,
298,6,204,1,3,
298,6,205,3,0,
298,6,207,3,0,
298,6,213,4,0,
298,6,214,3,0,
298,6,216,4,0,
298,6,218,4,0,
298,6,227,2,0,
298,6,231,4,0,
298,6,237,4,0,
298,6,240,4,0,
298,6,258,4,0,
298,6,263,4,0,
298,6,287,2,0,
298,6,290,4,0,
298,6,321,2,0,
298,6,352,4,0,
298,7,21,1,15,
298,7,21,2,0,
298,7,34,3,0,
298,7,38,3,0,
298,7,39,1,6,
298,7,47,2,0,
298,7,55,1,21,
298,7,57,4,0,
298,7,58,4,0,
298,7,59,4,0,
298,7,92,4,0,
298,7,102,3,0,
298,7,104,4,0,
298,7,127,4,0,
298,7,145,1,10,
298,7,150,1,1,
298,7,156,4,0,
298,7,164,3,0,
298,7,182,4,0,
298,7,204,1,3,
298,7,213,4,0,
298,7,216,4,0,
298,7,218,4,0,
298,7,227,2,0,
298,7,231,4,0,
298,7,237,4,0,
298,7,240,4,0,
298,7,258,4,0,
298,7,263,4,0,
298,7,287,2,0,
298,7,290,4,0,
298,7,321,2,0,
298,7,352,4,0,
298,8,21,1,15,
298,8,21,2,0,
298,8,39,1,7,
298,8,47,2,0,
298,8,55,1,18,
298,8,57,4,0,
298,8,58,4,0,
298,8,59,4,0,
298,8,92,4,0,
298,8,104,4,0,
298,8,127,4,0,
298,8,145,1,10,
298,8,150,1,1,
298,8,156,4,0,
298,8,164,4,0,
298,8,182,4,0,
298,8,203,4,0,
298,8,204,1,2,
298,8,207,4,0,
298,8,213,4,0,
298,8,214,4,0,
298,8,216,4,0,
298,8,218,4,0,
298,8,227,2,0,
298,8,231,4,0,
298,8,237,4,0,
298,8,240,4,0,
298,8,258,4,0,
298,8,263,4,0,
298,8,287,2,0,
298,8,290,4,0,
298,8,313,2,0,
298,8,321,2,0,
298,8,352,4,0,
298,8,363,4,0,
298,8,445,4,0,
298,9,21,1,15,
298,9,21,2,0,
298,9,39,1,7,
298,9,47,2,0,
298,9,55,1,18,
298,9,57,4,0,
298,9,58,4,0,
298,9,59,4,0,
298,9,92,4,0,
298,9,104,4,0,
298,9,127,4,0,
298,9,129,3,0,
298,9,145,1,10,
298,9,150,1,1,
298,9,156,4,0,
298,9,164,4,0,
298,9,173,3,0,
298,9,182,4,0,
298,9,189,3,0,
298,9,196,3,0,
298,9,203,4,0,
298,9,204,1,2,
298,9,205,3,0,
298,9,207,4,0,
298,9,213,4,0,
298,9,214,4,0,
298,9,216,4,0,
298,9,218,4,0,
298,9,227,2,0,
298,9,231,4,0,
298,9,237,4,0,
298,9,240,4,0,
298,9,253,3,0,
298,9,258,4,0,
298,9,263,4,0,
298,9,270,3,0,
298,9,282,3,0,
298,9,287,2,0,
298,9,290,4,0,
298,9,313,2,0,
298,9,321,2,0,
298,9,352,4,0,
298,9,363,4,0,
298,9,445,4,0,
298,10,21,1,15,
298,10,21,2,0,
298,10,29,3,0,
298,10,34,2,0,
298,10,39,1,7,
298,10,47,2,0,
298,10,55,1,18,
298,10,57,4,0,
298,10,58,4,0,
298,10,59,4,0,
298,10,92,4,0,
298,10,104,4,0,
298,10,127,4,0,
298,10,129,3,0,
298,10,145,1,10,
298,10,150,1,1,
298,10,156,4,0,
298,10,164,4,0,
298,10,173,3,0,
298,10,182,4,0,
298,10,189,3,0,
298,10,196,3,0,
298,10,203,4,0,
298,10,204,1,2,
298,10,205,3,0,
298,10,207,4,0,
298,10,213,4,0,
298,10,214,4,0,
298,10,216,4,0,
298,10,218,4,0,
298,10,227,2,0,
298,10,231,4,0,
298,10,237,4,0,
298,10,240,4,0,
298,10,250,4,0,
298,10,253,3,0,
298,10,258,4,0,
298,10,263,4,0,
298,10,270,3,0,
298,10,282,3,0,
298,10,287,2,0,
298,10,290,4,0,
298,10,313,2,0,
298,10,321,2,0,
298,10,352,4,0,
298,10,363,4,0,
298,10,445,4,0,
298,11,21,1,15,
298,11,21,2,0,
298,11,34,2,0,
298,11,39,1,7,
298,11,47,2,0,
298,11,55,1,18,
298,11,57,4,0,
298,11,58,4,0,
298,11,59,4,0,
298,11,92,4,0,
298,11,104,4,0,
298,11,113,4,0,
298,11,127,4,0,
298,11,145,1,10,
298,11,150,1,1,
298,11,156,4,0,
298,11,164,4,0,
298,11,182,4,0,
298,11,204,1,2,
298,11,207,4,0,
298,11,213,4,0,
298,11,216,4,0,
298,11,218,4,0,
298,11,227,2,0,
298,11,237,4,0,
298,11,240,4,0,
298,11,258,4,0,
298,11,263,4,0,
298,11,287,2,0,
298,11,313,2,0,
298,11,321,2,0,
298,11,330,2,0,
298,11,346,2,0,
298,11,487,2,0,
298,11,496,4,0,
298,11,503,4,0,
298,11,526,4,0,
298,12,21,1,15,
298,12,39,1,6,
298,12,55,1,21,
298,12,57,4,0,
298,12,58,4,0,
298,12,59,4,0,
298,12,92,4,0,
298,12,104,4,0,
298,12,127,4,0,
298,12,145,1,10,
298,12,150,1,1,
298,12,156,4,0,
298,12,182,4,0,
298,12,204,1,3,
298,12,213,4,0,
298,12,216,4,0,
298,12,218,4,0,
298,12,231,4,0,
298,12,237,4,0,
298,12,240,4,0,
298,12,258,4,0,
298,12,263,4,0,
298,12,290,4,0,
298,12,352,4,0,
298,13,21,1,15,
298,13,34,3,0,
298,13,38,3,0,
298,13,39,1,6,
298,13,55,1,21,
298,13,57,4,0,
298,13,58,4,0,
298,13,59,4,0,
298,13,92,4,0,
298,13,102,3,0,
298,13,104,4,0,
298,13,127,4,0,
298,13,145,1,10,
298,13,150,1,1,
298,13,156,4,0,
298,13,164,3,0,
298,13,182,4,0,
298,13,196,3,0,
298,13,204,1,3,
298,13,207,3,0,
298,13,213,4,0,
298,13,216,4,0,
298,13,218,4,0,
298,13,231,4,0,
298,13,237,4,0,
298,13,240,4,0,
298,13,258,4,0,
298,13,263,4,0,
298,13,290,4,0,
298,13,352,4,0,
298,14,21,1,20,
298,14,21,2,0,
298,14,34,2,0,
298,14,39,1,2,
298,14,47,2,0,
298,14,55,1,7,
298,14,57,4,0,
298,14,58,4,0,
298,14,59,4,0,
298,14,61,1,13,
298,14,92,4,0,
298,14,104,4,0,
298,14,113,4,0,
298,14,127,4,0,
298,14,145,1,1,2
298,14,150,1,1,1
298,14,156,4,0,
298,14,164,4,0,
298,14,173,3,0,
298,14,182,4,0,
298,14,196,3,0,
298,14,204,1,10,
298,14,207,4,0,
298,14,213,4,0,
298,14,214,3,0,
298,14,216,4,0,
298,14,218,4,0,
298,14,227,2,0,
298,14,231,3,0,
298,14,237,4,0,
298,14,240,4,0,
298,14,253,3,0,
298,14,258,4,0,
298,14,263,4,0,
298,14,270,1,16,
298,14,270,3,0,
298,14,282,3,0,
298,14,287,2,0,
298,14,304,3,0,
298,14,313,2,0,
298,14,321,2,0,
298,14,330,2,0,
298,14,340,1,23,
298,14,340,3,0,
298,14,343,3,0,
298,14,346,1,5,
298,14,346,2,0,
298,14,487,2,0,
298,14,496,4,0,
298,14,503,4,0,
298,14,526,4,0,
298,15,21,1,20,
298,15,21,2,0,
298,15,34,2,0,
298,15,39,1,2,
298,15,47,2,0,
298,15,55,1,1,2
298,15,57,4,0,
298,15,58,4,0,
298,15,59,4,0,
298,15,61,1,13,
298,15,92,4,0,
298,15,104,4,0,
298,15,113,4,0,
298,15,127,4,0,
298,15,145,1,7,
298,15,150,1,1,1
298,15,156,4,0,
298,15,164,4,0,
298,15,182,4,0,
298,15,204,1,10,
298,15,207,4,0,
298,15,213,4,0,
298,15,214,4,0,
298,15,216,4,0,
298,15,218,4,0,
298,15,227,2,0,
298,15,237,4,0,
298,15,240,4,0,
298,15,258,4,0,
298,15,263,4,0,
298,15,270,1,16,
298,15,287,2,0,
298,15,293,2,0,
298,15,313,2,0,
298,15,321,2,0,
298,15,330,2,0,
298,15,340,1,23,
298,15,346,1,5,
298,15,346,2,0,
298,15,383,2,0,
298,15,487,2,0,
298,15,496,4,0,
298,15,503,4,0,
298,15,590,4,0,
298,16,21,1,20,1
298,16,21,2,0,
298,16,34,2,0,
298,16,39,1,2,1
298,16,47,2,0,
298,16,55,1,1,2
298,16,57,4,0,
298,16,58,4,0,
298,16,59,4,0,
298,16,61,1,13,1
298,16,92,4,0,
298,16,104,4,0,
298,16,113,4,0,
298,16,127,4,0,
298,16,145,1,7,1
298,16,150,1,1,1
298,16,156,4,0,
298,16,164,4,0,
298,16,173,3,0,
298,16,182,4,0,
298,16,196,3,0,
298,16,204,1,10,1
298,16,207,4,0,
298,16,213,4,0,
298,16,214,4,0,
298,16,216,4,0,
298,16,218,4,0,
298,16,227,2,0,
298,16,231,3,0,
298,16,237,4,0,
298,16,240,4,0,
298,16,253,3,0,
298,16,258,4,0,
298,16,263,4,0,
298,16,270,1,16,1
298,16,270,3,0,
298,16,282,3,0,
298,16,287,2,0,
298,16,290,4,0,
298,16,293,2,0,
298,16,304,3,0,
298,16,313,2,0,
298,16,321,2,0,
298,16,330,2,0,
298,16,340,1,23,1
298,16,340,3,0,
298,16,343,3,0,
298,16,346,1,5,1
298,16,346,2,0,
298,16,352,3,0,
298,16,383,2,0,
298,16,487,2,0,
298,16,496,4,0,
298,16,503,4,0,
298,16,590,4,0,
298,17,21,1,20,
298,17,21,2,0,
298,17,34,2,0,
298,17,39,1,2,
298,17,47,2,0,
298,17,55,1,1,2
298,17,57,4,0,
298,17,58,4,0,
298,17,59,4,0,
298,17,61,1,13,
298,17,92,4,0,
298,17,104,4,0,
298,17,113,4,0,
298,17,127,4,0,
298,17,145,1,7,
298,17,150,1,1,1
298,17,156,4,0,
298,17,164,4,0,
298,17,182,4,0,
298,17,204,1,10,
298,17,207,4,0,
298,17,213,4,0,
298,17,214,4,0,
298,17,216,4,0,
298,17,218,4,0,
298,17,227,2,0,
298,17,237,4,0,
298,17,240,4,0,
298,17,258,4,0,
298,17,263,4,0,
298,17,270,1,16,
298,17,287,2,0,
298,17,293,2,0,
298,17,313,2,0,
298,17,321,2,0,
298,17,330,2,0,
298,17,340,1,23,
298,17,346,1,5,
298,17,346,2,0,
298,17,383,2,0,
298,17,487,2,0,
298,17,496,4,0,
298,17,503,4,0,
298,17,526,4,0,
298,17,590,4,0,
298,18,21,1,20,
298,18,21,2,0,
298,18,34,2,0,
298,18,39,1,2,
298,18,47,2,0,
298,18,55,1,1,2
298,18,57,4,0,
298,18,58,4,0,
298,18,59,4,0,
298,18,61,1,13,
298,18,92,4,0,
298,18,104,4,0,
298,18,113,4,0,
298,18,127,4,0,
298,18,145,1,7,
298,18,150,1,1,1
298,18,156,4,0,
298,18,164,4,0,
298,18,182,4,0,
298,18,204,1,10,
298,18,207,4,0,
298,18,213,4,0,
298,18,214,4,0,
298,18,216,4,0,
298,18,218,4,0,
298,18,227,2,0,
298,18,237,4,0,
298,18,240,4,0,
298,18,258,4,0,
298,18,263,4,0,
298,18,270,1,16,
298,18,287,2,0,
298,18,293,2,0,
298,18,313,2,0,
298,18,321,2,0,
298,18,330,2,0,
298,18,340,1,23,
298,18,346,1,5,
298,18,346,2,0,
298,18,383,2,0,
298,18,487,2,0,
298,18,496,4,0,
298,18,503,4,0,
298,18,526,4,0,
298,18,590,4,0,
299,5,33,1,1,
299,5,70,4,0,
299,5,85,4,0,
299,5,86,1,22,
299,5,87,4,0,
299,5,88,1,13,
299,5,89,4,0,
299,5,92,4,0,
299,5,104,4,0,
299,5,106,1,7,
299,5,153,2,0,
299,5,156,1,37,
299,5,156,4,0,
299,5,157,1,28,
299,5,182,4,0,
299,5,192,1,43,
299,5,199,1,46,
299,5,201,1,31,
299,5,201,4,0,
299,5,205,2,0,
299,5,213,4,0,
299,5,216,4,0,
299,5,218,4,0,
299,5,222,2,0,
299,5,237,4,0,
299,5,241,4,0,
299,5,249,4,0,
299,5,259,4,0,
299,5,263,4,0,
299,5,269,4,0,
299,5,290,4,0,
299,5,317,4,0,
299,5,335,1,16,
299,5,351,4,0,
299,6,7,3,0,
299,6,8,3,0,
299,6,9,3,0,
299,6,33,1,1,
299,6,34,3,0,
299,6,38,3,0,
299,6,70,4,0,
299,6,85,4,0,
299,6,86,1,22,
299,6,86,3,0,
299,6,87,4,0,
299,6,88,1,13,
299,6,89,4,0,
299,6,92,4,0,
299,6,102,3,0,
299,6,104,4,0,
299,6,106,1,7,
299,6,111,3,0,
299,6,153,2,0,
299,6,153,3,0,
299,6,156,1,37,
299,6,156,4,0,
299,6,157,1,28,
299,6,157,3,0,
299,6,164,3,0,
299,6,173,3,0,
299,6,182,4,0,
299,6,189,3,0,
299,6,192,1,43,
299,6,199,1,46,
299,6,201,1,31,
299,6,201,4,0,
299,6,203,3,0,
299,6,205,2,0,
299,6,205,3,0,
299,6,207,3,0,
299,6,213,4,0,
299,6,214,3,0,
299,6,216,4,0,
299,6,218,4,0,
299,6,222,2,0,
299,6,223,3,0,
299,6,237,4,0,
299,6,241,4,0,
299,6,249,4,0,
299,6,259,4,0,
299,6,263,4,0,
299,6,269,4,0,
299,6,290,4,0,
299,6,317,4,0,
299,6,335,1,16,
299,6,351,4,0,
299,7,33,1,1,
299,7,34,3,0,
299,7,38,3,0,
299,7,70,4,0,
299,7,85,4,0,
299,7,86,1,22,
299,7,86,3,0,
299,7,87,4,0,
299,7,88,1,13,
299,7,89,4,0,
299,7,92,4,0,
299,7,102,3,0,
299,7,104,4,0,
299,7,106,1,7,
299,7,153,2,0,
299,7,153,3,0,
299,7,156,1,37,
299,7,156,4,0,
299,7,157,1,28,
299,7,157,3,0,
299,7,164,3,0,
299,7,182,4,0,
299,7,192,1,43,
299,7,199,1,46,
299,7,201,1,31,
299,7,201,4,0,
299,7,205,2,0,
299,7,213,4,0,
299,7,216,4,0,
299,7,218,4,0,
299,7,222,2,0,
299,7,237,4,0,
299,7,241,4,0,
299,7,249,4,0,
299,7,259,4,0,
299,7,263,4,0,
299,7,269,4,0,
299,7,290,4,0,
299,7,317,4,0,
299,7,335,1,16,
299,7,351,4,0,
299,8,33,1,1,
299,8,38,2,0,
299,8,70,4,0,
299,8,85,4,0,
299,8,86,1,25,
299,8,86,4,0,
299,8,87,4,0,
299,8,88,1,13,
299,8,89,4,0,
299,8,92,4,0,
299,8,104,4,0,
299,8,106,1,7,
299,8,153,2,0,
299,8,153,4,0,
299,8,156,1,43,
299,8,156,4,0,
299,8,157,1,31,
299,8,157,4,0,
299,8,164,4,0,
299,8,182,4,0,
299,8,192,1,61,
299,8,199,1,67,
299,8,201,1,37,
299,8,201,4,0,
299,8,203,4,0,
299,8,205,2,0,
299,8,207,4,0,
299,8,213,4,0,
299,8,214,4,0,
299,8,216,4,0,
299,8,218,4,0,
299,8,222,2,0,
299,8,237,4,0,
299,8,241,4,0,
299,8,249,4,0,
299,8,259,4,0,
299,8,263,4,0,
299,8,269,4,0,
299,8,290,4,0,
299,8,317,4,0,
299,8,335,1,19,
299,8,335,2,0,
299,8,351,4,0,
299,8,363,4,0,
299,8,397,4,0,
299,8,414,1,73,
299,8,435,1,49,
299,8,444,1,55,
299,8,444,4,0,
299,8,445,4,0,
299,8,446,4,0,
299,9,7,3,0,
299,9,8,3,0,
299,9,9,3,0,
299,9,33,1,1,
299,9,38,2,0,
299,9,70,4,0,
299,9,85,4,0,
299,9,86,1,25,
299,9,86,4,0,
299,9,87,4,0,
299,9,88,1,13,
299,9,89,4,0,
299,9,92,4,0,
299,9,104,4,0,
299,9,106,1,7,
299,9,153,2,0,
299,9,153,4,0,
299,9,156,1,43,
299,9,156,4,0,
299,9,157,1,31,
299,9,157,4,0,
299,9,164,4,0,
299,9,173,3,0,
299,9,182,4,0,
299,9,189,3,0,
299,9,192,1,67,
299,9,199,1,73,
299,9,201,1,37,
299,9,201,4,0,
299,9,203,4,0,
299,9,205,2,0,
299,9,205,3,0,
299,9,207,4,0,
299,9,213,4,0,
299,9,214,4,0,
299,9,216,4,0,
299,9,218,4,0,
299,9,222,2,0,
299,9,237,4,0,
299,9,241,4,0,
299,9,246,3,0,
299,9,249,4,0,
299,9,259,4,0,
299,9,263,4,0,
299,9,269,4,0,
299,9,290,4,0,
299,9,317,4,0,
299,9,334,3,0,
299,9,335,1,19,
299,9,335,2,0,
299,9,351,4,0,
299,9,363,4,0,
299,9,393,3,0,
299,9,397,4,0,
299,9,408,1,49,
299,9,414,1,79,
299,9,414,3,0,
299,9,435,1,55,
299,9,444,1,61,
299,9,444,4,0,
299,9,445,4,0,
299,9,446,4,0,
299,10,7,3,0,
299,10,8,3,0,
299,10,9,3,0,
299,10,29,3,0,
299,10,33,1,1,
299,10,38,2,0,
299,10,70,4,0,
299,10,85,4,0,
299,10,86,1,25,
299,10,86,4,0,
299,10,87,4,0,
299,10,88,1,13,
299,10,89,4,0,
299,10,92,4,0,
299,10,104,4,0,
299,10,106,1,7,
299,10,153,2,0,
299,10,153,4,0,
299,10,156,1,43,
299,10,156,4,0,
299,10,157,1,31,
299,10,157,4,0,
299,10,164,4,0,
299,10,173,3,0,
299,10,182,4,0,
299,10,189,3,0,
299,10,192,1,67,
299,10,199,1,73,
299,10,201,1,37,
299,10,201,4,0,
299,10,203,4,0,
299,10,205,2,0,
299,10,205,3,0,
299,10,207,4,0,
299,10,213,4,0,
299,10,214,4,0,
299,10,216,4,0,
299,10,218,4,0,
299,10,220,3,0,
299,10,222,2,0,
299,10,237,4,0,
299,10,241,4,0,
299,10,246,3,0,
299,10,249,4,0,
299,10,259,4,0,
299,10,263,4,0,
299,10,269,4,0,
299,10,277,3,0,
299,10,290,4,0,
299,10,317,4,0,
299,10,334,3,0,
299,10,335,1,19,
299,10,335,2,0,
299,10,335,3,0,
299,10,351,4,0,
299,10,356,3,0,
299,10,363,4,0,
299,10,393,3,0,
299,10,397,4,0,
299,10,408,1,49,
299,10,414,1,79,
299,10,414,3,0,
299,10,435,1,55,
299,10,444,1,61,
299,10,444,4,0,
299,10,445,4,0,
299,10,446,4,0,
299,10,457,2,0,
299,11,33,1,1,
299,11,38,2,0,
299,11,70,4,0,
299,11,85,4,0,
299,11,86,1,25,
299,11,86,4,0,
299,11,87,4,0,
299,11,88,1,13,
299,11,89,4,0,
299,11,92,4,0,
299,11,104,4,0,
299,11,106,1,7,
299,11,153,4,0,
299,11,156,1,43,
299,11,156,4,0,
299,11,157,1,31,
299,11,157,4,0,
299,11,164,4,0,
299,11,182,4,0,
299,11,192,1,67,
299,11,199,1,73,
299,11,201,1,37,
299,11,201,4,0,
299,11,203,2,0,
299,11,205,2,0,
299,11,207,4,0,
299,11,213,4,0,
299,11,216,4,0,
299,11,218,4,0,
299,11,222,2,0,
299,11,237,4,0,
299,11,241,4,0,
299,11,249,4,0,
299,11,259,4,0,
299,11,263,4,0,
299,11,269,4,0,
299,11,317,4,0,
299,11,335,1,19,
299,11,335,2,0,
299,11,397,4,0,
299,11,408,1,49,
299,11,414,1,79,
299,11,435,1,55,
299,11,444,1,61,
299,11,444,4,0,
299,11,446,2,0,
299,11,479,4,0,
299,11,496,4,0,
299,11,521,4,0,
299,11,523,4,0,
299,12,33,1,1,
299,12,70,4,0,
299,12,85,4,0,
299,12,86,1,22,
299,12,87,4,0,
299,12,88,1,13,
299,12,89,4,0,
299,12,92,4,0,
299,12,104,4,0,
299,12,106,1,7,
299,12,156,1,37,
299,12,156,4,0,
299,12,157,1,28,
299,12,182,4,0,
299,12,192,1,43,
299,12,199,1,46,
299,12,201,1,31,
299,12,201,4,0,
299,12,213,4,0,
299,12,216,4,0,
299,12,218,4,0,
299,12,237,4,0,
299,12,241,4,0,
299,12,249,4,0,
299,12,259,4,0,
299,12,263,4,0,
299,12,269,4,0,
299,12,290,4,0,
299,12,317,4,0,
299,12,335,1,16,
299,12,351,4,0,
299,13,33,1,1,
299,13,34,3,0,
299,13,38,3,0,
299,13,70,4,0,
299,13,85,4,0,
299,13,86,1,22,
299,13,86,3,0,
299,13,87,4,0,
299,13,88,1,13,
299,13,89,4,0,
299,13,92,4,0,
299,13,102,3,0,
299,13,104,4,0,
299,13,106,1,7,
299,13,120,3,0,
299,13,156,1,37,
299,13,156,4,0,
299,13,157,1,28,
299,13,164,3,0,
299,13,182,4,0,
299,13,192,1,43,
299,13,199,1,46,
299,13,201,1,31,
299,13,201,4,0,
299,13,207,3,0,
299,13,213,4,0,
299,13,216,4,0,
299,13,218,4,0,
299,13,237,4,0,
299,13,241,4,0,
299,13,249,4,0,
299,13,259,4,0,
299,13,263,4,0,
299,13,269,4,0,
299,13,290,4,0,
299,13,317,4,0,
299,13,335,1,16,
299,13,351,4,0,
299,14,7,3,0,
299,14,8,3,0,
299,14,9,3,0,
299,14,33,1,1,
299,14,38,2,0,
299,14,70,4,0,
299,14,85,4,0,
299,14,86,1,15,
299,14,86,4,0,
299,14,87,4,0,
299,14,88,1,11,
299,14,89,4,0,
299,14,92,4,0,
299,14,104,4,0,
299,14,106,1,4,
299,14,153,4,0,
299,14,156,1,22,
299,14,156,4,0,
299,14,157,1,29,
299,14,157,4,0,
299,14,164,4,0,
299,14,173,3,0,
299,14,182,4,0,
299,14,192,1,50,2
299,14,199,1,50,1
299,14,201,1,36,
299,14,201,4,0,
299,14,203,2,0,
299,14,205,2,0,
299,14,207,4,0,
299,14,209,1,25,
299,14,213,4,0,
299,14,214,3,0,
299,14,216,4,0,
299,14,218,4,0,
299,14,220,3,0,
299,14,222,2,0,
299,14,237,4,0,
299,14,241,4,0,
299,14,249,4,0,
299,14,259,4,0,
299,14,263,4,0,
299,14,269,4,0,
299,14,277,3,0,
299,14,317,4,0,
299,14,334,3,0,
299,14,335,1,8,
299,14,335,2,0,
299,14,335,3,0,
299,14,350,1,18,
299,14,356,3,0,
299,14,393,3,0,
299,14,397,4,0,
299,14,408,1,32,
299,14,414,1,43,
299,14,414,3,0,
299,14,435,1,39,
299,14,444,1,46,
299,14,444,4,0,
299,14,446,2,0,
299,14,446,3,0,
299,14,479,4,0,
299,14,496,4,0,
299,14,521,4,0,
299,14,523,4,0,
299,15,33,1,1,
299,15,38,2,0,
299,15,70,4,0,
299,15,85,4,0,
299,15,86,1,15,
299,15,86,4,0,
299,15,87,4,0,
299,15,88,1,11,
299,15,89,4,0,
299,15,92,4,0,
299,15,104,4,0,
299,15,106,1,4,
299,15,153,4,0,
299,15,156,1,22,
299,15,156,4,0,
299,15,157,1,29,
299,15,157,4,0,
299,15,164,4,0,
299,15,182,4,0,
299,15,192,1,50,2
299,15,199,1,50,1
299,15,201,1,36,
299,15,201,4,0,
299,15,203,2,0,
299,15,205,2,0,
299,15,207,4,0,
299,15,209,1,25,
299,15,213,4,0,
299,15,214,4,0,
299,15,216,4,0,
299,15,218,4,0,
299,15,222,2,0,
299,15,237,4,0,
299,15,241,4,0,
299,15,249,4,0,
299,15,259,4,0,
299,15,263,4,0,
299,15,269,4,0,
299,15,317,4,0,
299,15,335,1,8,
299,15,335,2,0,
299,15,350,1,18,
299,15,397,4,0,
299,15,408,1,32,
299,15,414,1,43,
299,15,435,1,39,
299,15,444,1,46,
299,15,444,4,0,
299,15,446,2,0,
299,15,469,2,0,
299,15,479,4,0,
299,15,496,4,0,
299,15,521,4,0,
299,15,523,4,0,
299,15,590,4,0,
299,15,605,4,0,
299,16,7,3,0,
299,16,8,3,0,
299,16,9,3,0,
299,16,33,1,1,1
299,16,38,2,0,
299,16,70,4,0,
299,16,85,4,0,
299,16,86,1,13,1
299,16,86,4,0,
299,16,87,4,0,
299,16,88,1,10,1
299,16,89,4,0,
299,16,92,4,0,
299,16,104,4,0,
299,16,106,1,4,1
299,16,153,4,0,
299,16,156,1,16,1
299,16,156,4,0,
299,16,157,1,22,1
299,16,157,4,0,
299,16,164,4,0,
299,16,173,3,0,
299,16,182,4,0,
299,16,192,1,43,2
299,16,199,1,43,1
299,16,201,1,34,1
299,16,201,4,0,
299,16,203,2,0,
299,16,205,2,0,
299,16,207,4,0,
299,16,209,1,19,1
299,16,213,4,0,
299,16,214,4,0,
299,16,216,4,0,
299,16,218,4,0,
299,16,220,3,0,
299,16,222,2,0,
299,16,237,4,0,
299,16,241,4,0,
299,16,249,4,0,
299,16,259,4,0,
299,16,263,4,0,
299,16,269,4,0,
299,16,277,3,0,
299,16,290,4,0,
299,16,317,4,0,
299,16,334,3,0,
299,16,335,1,7,1
299,16,335,2,0,
299,16,335,3,0,
299,16,350,1,28,1
299,16,351,3,0,
299,16,356,3,0,
299,16,393,3,0,
299,16,397,4,0,
299,16,408,1,25,1
299,16,414,1,37,1
299,16,414,3,0,
299,16,435,1,31,1
299,16,444,1,40,1
299,16,444,4,0,
299,16,446,2,0,
299,16,446,3,0,
299,16,469,2,0,
299,16,479,4,0,
299,16,496,4,0,
299,16,521,4,0,
299,16,523,4,0,
299,16,590,4,0,
299,16,605,4,0,
299,17,33,1,1,
299,17,38,2,0,
299,17,85,4,0,
299,17,86,1,13,
299,17,86,4,0,
299,17,87,4,0,
299,17,88,1,10,
299,17,89,4,0,
299,17,92,4,0,
299,17,104,4,0,
299,17,106,1,4,
299,17,153,4,0,
299,17,156,1,16,
299,17,156,4,0,
299,17,157,1,22,
299,17,157,4,0,
299,17,164,4,0,
299,17,182,4,0,
299,17,192,1,43,2
299,17,199,1,43,1
299,17,201,1,34,
299,17,201,4,0,
299,17,203,2,0,
299,17,205,2,0,
299,17,207,4,0,
299,17,209,1,19,
299,17,213,4,0,
299,17,214,4,0,
299,17,216,4,0,
299,17,218,4,0,
299,17,222,2,0,
299,17,237,4,0,
299,17,241,4,0,
299,17,259,4,0,
299,17,263,4,0,
299,17,269,4,0,
299,17,317,4,0,
299,17,335,1,7,
299,17,335,2,0,
299,17,350,1,28,
299,17,397,4,0,
299,17,408,1,25,
299,17,414,1,37,
299,17,435,1,31,
299,17,444,1,40,
299,17,444,4,0,
299,17,446,2,0,
299,17,469,2,0,
299,17,479,4,0,
299,17,496,4,0,
299,17,521,4,0,
299,17,523,4,0,
299,17,590,4,0,
299,17,605,4,0,
299,18,33,1,1,
299,18,38,2,0,
299,18,85,4,0,
299,18,86,1,13,
299,18,86,4,0,
299,18,87,4,0,
299,18,88,1,10,
299,18,89,4,0,
299,18,92,4,0,
299,18,104,4,0,
299,18,106,1,4,
299,18,153,4,0,
299,18,156,1,16,
299,18,156,4,0,
299,18,157,1,22,
299,18,157,4,0,
299,18,164,4,0,
299,18,182,4,0,
299,18,192,1,43,2
299,18,199,1,43,1
299,18,201,1,34,
299,18,201,4,0,
299,18,203,2,0,
299,18,205,2,0,
299,18,207,4,0,
299,18,209,1,19,
299,18,213,4,0,
299,18,214,4,0,
299,18,216,4,0,
299,18,218,4,0,
299,18,222,2,0,
299,18,237,4,0,
299,18,241,4,0,
299,18,259,4,0,
299,18,263,4,0,
299,18,269,4,0,
299,18,317,4,0,
299,18,335,1,7,
299,18,335,2,0,
299,18,350,1,28,
299,18,397,4,0,
299,18,408,1,25,
299,18,414,1,37,
299,18,435,1,31,
299,18,444,1,40,
299,18,444,4,0,
299,18,446,2,0,
299,18,469,2,0,
299,18,479,4,0,
299,18,496,4,0,
299,18,521,4,0,
299,18,523,4,0,
299,18,590,4,0,
299,18,605,4,0,
300,5,3,1,15,
300,5,33,1,1,2
300,5,38,1,39,
300,5,39,1,3,
300,5,45,1,1,1
300,5,47,1,13,
300,5,58,4,0,
300,5,59,4,0,
300,5,76,4,0,
300,5,85,4,0,
300,5,87,4,0,
300,5,91,4,0,
300,5,92,4,0,
300,5,104,4,0,
300,5,148,4,0,
300,5,156,4,0,
300,5,164,2,0,
300,5,182,4,0,
300,5,185,1,27,
300,5,204,1,25,
300,5,213,1,7,
300,5,213,4,0,
300,5,215,1,37,
300,5,216,4,0,
300,5,218,4,0,
300,5,219,4,0,
300,5,226,2,0,
300,5,231,4,0,
300,5,237,4,0,
300,5,240,4,0,
300,5,241,4,0,
300,5,244,2,0,
300,5,247,4,0,
300,5,253,2,0,
300,5,263,4,0,
300,5,270,2,0,
300,5,273,2,0,
300,5,274,1,19,
300,5,290,4,0,
300,5,313,2,0,
300,5,321,2,0,
300,5,343,1,31,
300,5,347,4,0,
300,5,351,4,0,
300,5,352,4,0,
300,6,3,1,15,
300,6,33,1,1,2
300,6,34,3,0,
300,6,38,1,39,
300,6,38,3,0,
300,6,39,1,3,
300,6,45,1,1,1
300,6,47,1,13,
300,6,58,4,0,
300,6,59,4,0,
300,6,76,4,0,
300,6,85,4,0,
300,6,86,3,0,
300,6,87,4,0,
300,6,91,4,0,
300,6,92,4,0,
300,6,102,3,0,
300,6,104,4,0,
300,6,111,3,0,
300,6,129,3,0,
300,6,138,3,0,
300,6,148,4,0,
300,6,156,4,0,
300,6,164,2,0,
300,6,164,3,0,
300,6,173,3,0,
300,6,182,4,0,
300,6,185,1,27,
300,6,189,3,0,
300,6,196,3,0,
300,6,203,3,0,
300,6,204,1,25,
300,6,205,3,0,
300,6,207,3,0,
300,6,213,1,7,
300,6,213,4,0,
300,6,214,3,0,
300,6,215,1,37,
300,6,216,4,0,
300,6,218,4,0,
300,6,219,4,0,
300,6,226,2,0,
300,6,231,4,0,
300,6,237,4,0,
300,6,240,4,0,
300,6,241,4,0,
300,6,244,2,0,
300,6,244,3,0,
300,6,247,4,0,
300,6,253,2,0,
300,6,263,4,0,
300,6,270,2,0,
300,6,273,2,0,
300,6,274,1,19,
300,6,290,4,0,
300,6,313,2,0,
300,6,321,2,0,
300,6,343,1,31,
300,6,347,4,0,
300,6,351,4,0,
300,6,352,4,0,
300,7,3,1,15,
300,7,33,1,1,2
300,7,34,3,0,
300,7,38,1,39,
300,7,38,3,0,
300,7,39,1,3,
300,7,45,1,1,1
300,7,47,1,13,
300,7,58,4,0,
300,7,59,4,0,
300,7,76,4,0,
300,7,85,4,0,
300,7,86,3,0,
300,7,87,4,0,
300,7,91,4,0,
300,7,92,4,0,
300,7,102,3,0,
300,7,104,4,0,
300,7,138,3,0,
300,7,148,4,0,
300,7,156,4,0,
300,7,164,2,0,
300,7,164,3,0,
300,7,182,4,0,
300,7,185,1,27,
300,7,204,1,25,
300,7,213,1,7,
300,7,213,4,0,
300,7,215,1,37,
300,7,216,4,0,
300,7,218,4,0,
300,7,219,4,0,
300,7,226,2,0,
300,7,231,4,0,
300,7,237,4,0,
300,7,240,4,0,
300,7,241,4,0,
300,7,244,2,0,
300,7,247,4,0,
300,7,253,2,0,
300,7,263,4,0,
300,7,270,2,0,
300,7,273,2,0,
300,7,274,1,19,
300,7,290,4,0,
300,7,313,2,0,
300,7,321,2,0,
300,7,343,1,31,
300,7,347,4,0,
300,7,351,4,0,
300,7,352,4,0,
300,8,3,1,15,
300,8,33,1,1,4
300,8,38,1,39,
300,8,39,1,1,3
300,8,45,1,1,2
300,8,47,1,8,
300,8,58,4,0,
300,8,59,4,0,
300,8,76,4,0,
300,8,85,4,0,
300,8,86,4,0,
300,8,87,4,0,
300,8,91,4,0,
300,8,92,4,0,
300,8,104,4,0,
300,8,138,4,0,
300,8,148,4,0,
300,8,156,4,0,
300,8,164,2,0,
300,8,164,4,0,
300,8,182,4,0,
300,8,185,1,25,
300,8,203,4,0,
300,8,204,1,22,
300,8,207,4,0,
300,8,213,1,4,
300,8,213,4,0,
300,8,214,4,0,
300,8,215,1,36,
300,8,216,4,0,
300,8,218,4,0,
300,8,219,4,0,
300,8,226,2,0,
300,8,231,4,0,
300,8,237,4,0,
300,8,240,4,0,
300,8,241,4,0,
300,8,244,2,0,
300,8,244,4,0,
300,8,247,4,0,
300,8,252,1,1,1
300,8,252,2,0,
300,8,253,2,0,
300,8,263,4,0,
300,8,270,2,0,
300,8,273,2,0,
300,8,274,1,18,
300,8,290,4,0,
300,8,313,2,0,
300,8,321,2,0,
300,8,343,1,32,
300,8,347,4,0,
300,8,351,4,0,
300,8,352,4,0,
300,8,358,1,29,
300,8,363,4,0,
300,8,371,4,0,
300,8,383,1,11,
300,8,387,2,0,
300,8,389,2,0,
300,8,428,2,0,
300,8,445,1,42,
300,8,445,4,0,
300,8,447,4,0,
300,8,451,4,0,
300,9,3,1,15,
300,9,33,1,1,4
300,9,38,1,42,
300,9,39,1,1,3
300,9,45,1,1,2
300,9,47,1,11,
300,9,58,4,0,
300,9,59,4,0,
300,9,76,4,0,
300,9,85,4,0,
300,9,86,4,0,
300,9,87,4,0,
300,9,91,4,0,
300,9,92,4,0,
300,9,104,4,0,
300,9,129,3,0,
300,9,138,4,0,
300,9,148,4,0,
300,9,156,4,0,
300,9,164,2,0,
300,9,164,4,0,
300,9,173,3,0,
300,9,182,4,0,
300,9,185,1,29,
300,9,189,3,0,
300,9,193,1,4,
300,9,196,3,0,
300,9,203,4,0,
300,9,204,1,25,
300,9,205,3,0,
300,9,207,4,0,
300,9,213,1,8,
300,9,213,4,0,
300,9,214,4,0,
300,9,215,1,39,
300,9,216,4,0,
300,9,218,4,0,
300,9,219,4,0,
300,9,226,2,0,
300,9,231,4,0,
300,9,237,4,0,
300,9,240,4,0,
300,9,241,4,0,
300,9,244,2,0,
300,9,244,4,0,
300,9,247,4,0,
300,9,252,1,1,1
300,9,252,2,0,
300,9,253,2,0,
300,9,263,4,0,
300,9,270,2,0,
300,9,270,3,0,
300,9,273,2,0,
300,9,274,1,22,
300,9,290,4,0,
300,9,313,2,0,
300,9,321,2,0,
300,9,343,1,36,
300,9,347,4,0,
300,9,351,4,0,
300,9,352,4,0,
300,9,358,1,32,
300,9,363,4,0,
300,9,371,4,0,
300,9,383,1,18,
300,9,387,2,0,
300,9,387,3,0,
300,9,389,2,0,
300,9,389,3,0,
300,9,428,2,0,
300,9,428,3,0,
300,9,445,1,46,
300,9,445,4,0,
300,9,447,4,0,
300,9,451,4,0,
300,10,3,1,15,
300,10,29,3,0,
300,10,33,1,1,4
300,10,38,1,42,
300,10,39,1,1,3
300,10,45,1,1,2
300,10,47,1,11,
300,10,58,4,0,
300,10,59,4,0,
300,10,76,4,0,
300,10,85,4,0,
300,10,86,4,0,
300,10,87,4,0,
300,10,91,4,0,
300,10,92,4,0,
300,10,104,4,0,
300,10,129,3,0,
300,10,138,4,0,
300,10,148,4,0,
300,10,156,4,0,
300,10,164,2,0,
300,10,164,4,0,
300,10,173,3,0,
300,10,182,4,0,
300,10,185,1,29,
300,10,189,3,0,
300,10,193,1,4,
300,10,196,3,0,
300,10,203,4,0,
300,10,204,1,25,
300,10,205,3,0,
300,10,207,4,0,
300,10,213,1,8,
300,10,213,4,0,
300,10,214,4,0,
300,10,215,1,39,
300,10,215,3,0,
300,10,216,4,0,
300,10,218,4,0,
300,10,219,4,0,
300,10,226,2,0,
300,10,231,4,0,
300,10,237,4,0,
300,10,240,4,0,
300,10,241,4,0,
300,10,244,2,0,
300,10,244,4,0,
300,10,247,4,0,
300,10,252,1,1,1
300,10,252,2,0,
300,10,253,2,0,
300,10,263,4,0,
300,10,270,2,0,
300,10,270,3,0,
300,10,273,2,0,
300,10,274,1,22,
300,10,290,4,0,
300,10,313,2,0,
300,10,321,2,0,
300,10,343,1,36,
300,10,347,4,0,
300,10,351,4,0,
300,10,352,4,0,
300,10,358,1,32,
300,10,363,4,0,
300,10,371,4,0,
300,10,383,1,18,
300,10,387,2,0,
300,10,387,3,0,
300,10,389,2,0,
300,10,389,3,0,
300,10,426,2,0,
300,10,428,2,0,
300,10,428,3,0,
300,10,445,1,46,
300,10,445,4,0,
300,10,447,4,0,
300,10,451,4,0,
300,11,3,1,15,
300,11,33,1,1,4
300,11,38,1,42,
300,11,39,1,1,3
300,11,45,1,1,2
300,11,47,1,11,
300,11,58,4,0,
300,11,59,4,0,
300,11,76,4,0,
300,11,85,4,0,
300,11,86,4,0,
300,11,87,4,0,
300,11,91,4,0,
300,11,92,4,0,
300,11,104,4,0,
300,11,138,4,0,
300,11,148,4,0,
300,11,156,4,0,
300,11,164,4,0,
300,11,182,4,0,
300,11,185,1,29,
300,11,193,1,4,
300,11,204,1,25,
300,11,207,4,0,
300,11,213,1,8,
300,11,213,4,0,
300,11,215,1,39,
300,11,216,4,0,
300,11,218,4,0,
300,11,219,4,0,
300,11,226,2,0,
300,11,237,4,0,
300,11,240,4,0,
300,11,241,4,0,
300,11,244,4,0,
300,11,247,4,0,
300,11,252,1,1,1
300,11,252,2,0,
300,11,253,2,0,
300,11,263,4,0,
300,11,270,2,0,
300,11,273,2,0,
300,11,274,1,22,
300,11,313,2,0,
300,11,321,2,0,
300,11,343,1,36,
300,11,347,4,0,
300,11,358,1,32,
300,11,371,4,0,
300,11,383,1,18,
300,11,387,2,0,
300,11,389,2,0,
300,11,426,2,0,
300,11,428,2,0,
300,11,445,1,46,
300,11,445,2,0,
300,11,447,4,0,
300,11,451,4,0,
300,11,493,2,0,
300,11,496,4,0,
300,11,497,4,0,
300,11,514,4,0,
300,11,526,4,0,
300,11,528,4,0,
300,12,3,1,15,
300,12,33,1,1,2
300,12,38,1,39,
300,12,39,1,3,
300,12,45,1,1,1
300,12,47,1,13,
300,12,58,4,0,
300,12,59,4,0,
300,12,76,4,0,
300,12,85,4,0,
300,12,87,4,0,
300,12,91,4,0,
300,12,92,4,0,
300,12,104,4,0,
300,12,148,4,0,
300,12,156,4,0,
300,12,182,4,0,
300,12,185,1,27,
300,12,204,1,25,
300,12,213,1,7,
300,12,213,4,0,
300,12,215,1,37,
300,12,216,4,0,
300,12,218,4,0,
300,12,219,4,0,
300,12,231,4,0,
300,12,237,4,0,
300,12,240,4,0,
300,12,241,4,0,
300,12,247,4,0,
300,12,263,4,0,
300,12,274,1,19,
300,12,290,4,0,
300,12,343,1,31,
300,12,347,4,0,
300,12,351,4,0,
300,12,352,4,0,
300,13,3,1,15,
300,13,33,1,1,2
300,13,34,3,0,
300,13,38,1,39,
300,13,38,3,0,
300,13,39,1,3,
300,13,45,1,1,1
300,13,47,1,13,
300,13,58,4,0,
300,13,59,4,0,
300,13,76,4,0,
300,13,85,4,0,
300,13,86,3,0,
300,13,87,4,0,
300,13,91,4,0,
300,13,92,4,0,
300,13,102,3,0,
300,13,104,4,0,
300,13,138,3,0,
300,13,148,4,0,
300,13,156,4,0,
300,13,164,3,0,
300,13,182,4,0,
300,13,185,1,27,
300,13,196,3,0,
300,13,204,1,25,
300,13,207,3,0,
300,13,213,1,7,
300,13,213,4,0,
300,13,215,1,37,
300,13,216,4,0,
300,13,218,4,0,
300,13,219,4,0,
300,13,231,4,0,
300,13,237,4,0,
300,13,240,4,0,
300,13,241,4,0,
300,13,247,4,0,
300,13,263,4,0,
300,13,274,1,19,
300,13,290,4,0,
300,13,343,1,31,
300,13,347,4,0,
300,13,351,4,0,
300,13,352,4,0,
300,14,3,1,15,
300,14,33,1,1,4
300,14,38,1,42,
300,14,39,1,1,3
300,14,45,1,1,2
300,14,47,1,11,
300,14,58,4,0,
300,14,59,4,0,
300,14,76,4,0,
300,14,85,4,0,
300,14,86,4,0,
300,14,87,4,0,
300,14,91,4,0,
300,14,92,4,0,
300,14,104,4,0,
300,14,138,4,0,
300,14,148,4,0,
300,14,156,4,0,
300,14,164,4,0,
300,14,173,3,0,
300,14,182,4,0,
300,14,185,1,29,
300,14,193,1,4,
300,14,196,3,0,
300,14,204,1,25,
300,14,207,4,0,
300,14,213,1,8,
300,14,213,4,0,
300,14,214,3,0,
300,14,215,1,39,
300,14,215,3,0,
300,14,216,4,0,
300,14,218,4,0,
300,14,219,4,0,
300,14,226,2,0,
300,14,231,3,0,
300,14,237,4,0,
300,14,240,4,0,
300,14,241,4,0,
300,14,244,4,0,
300,14,247,4,0,
300,14,252,1,1,1
300,14,252,2,0,
300,14,253,2,0,
300,14,253,3,0,
300,14,263,4,0,
300,14,270,2,0,
300,14,270,3,0,
300,14,273,2,0,
300,14,274,1,22,
300,14,304,3,0,
300,14,313,2,0,
300,14,321,2,0,
300,14,343,1,36,
300,14,343,3,0,
300,14,347,4,0,
300,14,358,1,32,
300,14,371,4,0,
300,14,383,1,18,
300,14,387,2,0,
300,14,387,3,0,
300,14,389,2,0,
300,14,426,2,0,
300,14,428,2,0,
300,14,428,3,0,
300,14,445,1,46,
300,14,445,2,0,
300,14,447,4,0,
300,14,451,4,0,
300,14,493,2,0,
300,14,496,4,0,
300,14,497,4,0,
300,14,514,4,0,
300,14,526,4,0,
300,14,528,4,0,
300,15,3,1,15,
300,15,33,1,1,4
300,15,38,1,42,
300,15,39,1,1,3
300,15,45,1,1,2
300,15,47,1,11,
300,15,58,4,0,
300,15,59,4,0,
300,15,76,4,0,
300,15,85,4,0,
300,15,86,4,0,
300,15,87,4,0,
300,15,91,4,0,
300,15,92,4,0,
300,15,104,4,0,
300,15,138,4,0,
300,15,148,4,0,
300,15,156,4,0,
300,15,164,4,0,
300,15,182,4,0,
300,15,185,1,29,
300,15,193,1,4,
300,15,204,1,25,
300,15,207,4,0,
300,15,213,1,8,
300,15,213,4,0,
300,15,214,4,0,
300,15,215,1,39,
300,15,216,4,0,
300,15,218,4,0,
300,15,219,4,0,
300,15,226,2,0,
300,15,237,4,0,
300,15,240,4,0,
300,15,241,4,0,
300,15,244,4,0,
300,15,247,4,0,
300,15,252,1,1,1
300,15,252,2,0,
300,15,253,2,0,
300,15,263,4,0,
300,15,270,2,0,
300,15,273,2,0,
300,15,274,1,22,
300,15,313,2,0,
300,15,321,2,0,
300,15,322,2,0,
300,15,343,1,36,
300,15,347,4,0,
300,15,358,1,32,
300,15,371,4,0,
300,15,383,1,18,
300,15,387,2,0,
300,15,389,2,0,
300,15,426,2,0,
300,15,428,2,0,
300,15,445,1,46,
300,15,445,2,0,
300,15,447,4,0,
300,15,451,4,0,
300,15,493,2,0,
300,15,496,4,0,
300,15,497,4,0,
300,15,514,4,0,
300,15,528,4,0,
300,15,583,1,49,
300,15,590,4,0,
300,16,3,1,16,1
300,16,33,1,1,4
300,16,38,1,40,1
300,16,39,1,1,3
300,16,45,1,1,2
300,16,47,1,7,1
300,16,58,4,0,
300,16,59,4,0,
300,16,76,4,0,
300,16,85,4,0,
300,16,86,4,0,
300,16,87,4,0,
300,16,91,4,0,
300,16,92,4,0,
300,16,104,4,0,
300,16,138,4,0,
300,16,148,4,0,
300,16,156,4,0,
300,16,164,4,0,
300,16,173,3,0,
300,16,182,4,0,
300,16,185,1,22,1
300,16,193,1,4,1
300,16,196,3,0,
300,16,204,1,25,1
300,16,207,4,0,
300,16,213,1,10,1
300,16,213,4,0,
300,16,214,4,0,
300,16,215,1,37,1
300,16,215,3,0,
300,16,216,4,0,
300,16,218,4,0,
300,16,219,4,0,
300,16,226,2,0,
300,16,231,3,0,
300,16,237,4,0,
300,16,240,4,0,
300,16,241,4,0,
300,16,244,4,0,
300,16,247,4,0,
300,16,252,1,1,1
300,16,252,2,0,
300,16,253,2,0,
300,16,253,3,0,
300,16,263,4,0,
300,16,270,2,0,
300,16,270,3,0,
300,16,273,2,0,
300,16,274,1,31,1
300,16,290,4,0,
300,16,304,3,0,
300,16,313,2,0,
300,16,321,2,0,
300,16,322,2,0,
300,16,343,1,34,1
300,16,343,3,0,
300,16,347,4,0,
300,16,351,3,0,
300,16,352,3,0,
300,16,358,1,28,1
300,16,371,4,0,
300,16,383,1,19,1
300,16,387,2,0,
300,16,387,3,0,
300,16,389,2,0,
300,16,426,2,0,
300,16,428,2,0,
300,16,428,3,0,
300,16,445,1,43,1
300,16,445,2,0,
300,16,447,4,0,
300,16,451,4,0,
300,16,493,2,0,
300,16,496,4,0,
300,16,497,4,0,
300,16,514,4,0,
300,16,528,4,0,
300,16,574,1,13,1
300,16,583,1,46,1
300,16,590,4,0,
300,17,3,1,16,
300,17,33,1,1,4
300,17,38,1,40,
300,17,39,1,1,3
300,17,45,1,1,2
300,17,47,1,7,
300,17,58,4,0,
300,17,59,4,0,
300,17,76,4,0,
300,17,85,4,0,
300,17,86,4,0,
300,17,87,4,0,
300,17,92,4,0,
300,17,104,4,0,
300,17,138,4,0,
300,17,156,4,0,
300,17,164,4,0,
300,17,182,4,0,
300,17,185,1,22,
300,17,193,1,4,
300,17,204,1,25,
300,17,207,4,0,
300,17,213,1,10,
300,17,213,4,0,
300,17,214,4,0,
300,17,215,1,37,
300,17,216,4,0,
300,17,218,4,0,
300,17,219,4,0,
300,17,226,2,0,
300,17,237,4,0,
300,17,240,4,0,
300,17,241,4,0,
300,17,244,4,0,
300,17,247,4,0,
300,17,252,1,1,1
300,17,252,2,0,
300,17,253,2,0,
300,17,263,4,0,
300,17,270,2,0,
300,17,273,2,0,
300,17,274,1,31,
300,17,313,2,0,
300,17,321,2,0,
300,17,322,2,0,
300,17,343,1,34,
300,17,347,4,0,
300,17,358,1,28,
300,17,371,4,0,
300,17,383,1,19,
300,17,387,2,0,
300,17,389,2,0,
300,17,426,2,0,
300,17,428,2,0,
300,17,445,1,43,
300,17,445,2,0,
300,17,447,4,0,
300,17,451,4,0,
300,17,493,2,0,
300,17,496,4,0,
300,17,497,4,0,
300,17,526,4,0,
300,17,528,4,0,
300,17,574,1,13,
300,17,583,1,46,
300,17,590,4,0,
300,18,3,1,16,
300,18,33,1,1,4
300,18,38,1,40,
300,18,39,1,1,3
300,18,45,1,1,2
300,18,47,1,7,
300,18,58,4,0,
300,18,59,4,0,
300,18,76,4,0,
300,18,85,4,0,
300,18,86,4,0,
300,18,87,4,0,
300,18,92,4,0,
300,18,104,4,0,
300,18,138,4,0,
300,18,156,4,0,
300,18,164,4,0,
300,18,182,4,0,
300,18,185,1,22,
300,18,193,1,4,
300,18,204,1,25,
300,18,207,4,0,
300,18,213,1,10,
300,18,213,4,0,
300,18,214,4,0,
300,18,215,1,37,
300,18,216,4,0,
300,18,218,4,0,
300,18,219,4,0,
300,18,226,2,0,
300,18,237,4,0,
300,18,240,4,0,
300,18,241,4,0,
300,18,244,4,0,
300,18,247,4,0,
300,18,252,1,1,1
300,18,252,2,0,
300,18,253,2,0,
300,18,263,4,0,
300,18,270,2,0,
300,18,273,2,0,
300,18,274,1,31,
300,18,313,2,0,
300,18,321,2,0,
300,18,322,2,0,
300,18,343,1,34,
300,18,347,4,0,
300,18,358,1,28,
300,18,371,4,0,
300,18,383,1,19,
300,18,387,2,0,
300,18,389,2,0,
300,18,426,2,0,
300,18,428,2,0,
300,18,445,1,43,
300,18,445,2,0,
300,18,447,4,0,
300,18,451,4,0,
300,18,493,2,0,
300,18,496,4,0,
300,18,497,4,0,
300,18,526,4,0,
300,18,528,4,0,
300,18,574,1,13,
300,18,583,1,46,
300,18,590,4,0,
301,5,3,1,1,4
301,5,45,1,1,1
301,5,47,1,1,3
301,5,58,4,0,
301,5,59,4,0,
301,5,63,4,0,
301,5,70,4,0,
301,5,76,4,0,
301,5,85,4,0,
301,5,87,4,0,
301,5,91,4,0,
301,5,92,4,0,
301,5,104,4,0,
301,5,148,4,0,
301,5,156,4,0,
301,5,182,4,0,
301,5,213,1,1,2
301,5,213,4,0,
301,5,216,4,0,
301,5,218,4,0,
301,5,219,4,0,
301,5,231,4,0,
301,5,237,4,0,
301,5,240,4,0,
301,5,241,4,0,
301,5,247,4,0,
301,5,249,4,0,
301,5,263,4,0,
301,5,290,4,0,
301,5,347,4,0,
301,5,351,4,0,
301,5,352,4,0,
301,6,3,1,1,4
301,6,34,3,0,
301,6,38,3,0,
301,6,45,1,1,1
301,6,47,1,1,3
301,6,58,4,0,
301,6,59,4,0,
301,6,63,4,0,
301,6,70,4,0,
301,6,76,4,0,
301,6,85,4,0,
301,6,86,3,0,
301,6,87,4,0,
301,6,91,4,0,
301,6,92,4,0,
301,6,102,3,0,
301,6,104,4,0,
301,6,111,3,0,
301,6,129,3,0,
301,6,138,3,0,
301,6,148,4,0,
301,6,156,4,0,
301,6,164,3,0,
301,6,173,3,0,
301,6,182,4,0,
301,6,189,3,0,
301,6,196,3,0,
301,6,203,3,0,
301,6,205,3,0,
301,6,207,3,0,
301,6,213,1,1,2
301,6,213,4,0,
301,6,214,3,0,
301,6,216,4,0,
301,6,218,4,0,
301,6,219,4,0,
301,6,231,4,0,
301,6,237,4,0,
301,6,240,4,0,
301,6,241,4,0,
301,6,244,3,0,
301,6,247,4,0,
301,6,249,4,0,
301,6,263,4,0,
301,6,290,4,0,
301,6,347,4,0,
301,6,351,4,0,
301,6,352,4,0,
301,7,3,1,1,4
301,7,34,3,0,
301,7,38,3,0,
301,7,45,1,1,1
301,7,47,1,1,3
301,7,58,4,0,
301,7,59,4,0,
301,7,63,4,0,
301,7,70,4,0,
301,7,76,4,0,
301,7,85,4,0,
301,7,86,3,0,
301,7,87,4,0,
301,7,91,4,0,
301,7,92,4,0,
301,7,102,3,0,
301,7,104,4,0,
301,7,138,3,0,
301,7,148,4,0,
301,7,156,4,0,
301,7,164,3,0,
301,7,182,4,0,
301,7,213,1,1,2
301,7,213,4,0,
301,7,216,4,0,
301,7,218,4,0,
301,7,219,4,0,
301,7,231,4,0,
301,7,237,4,0,
301,7,240,4,0,
301,7,241,4,0,
301,7,247,4,0,
301,7,249,4,0,
301,7,263,4,0,
301,7,290,4,0,
301,7,347,4,0,
301,7,351,4,0,
301,7,352,4,0,
301,8,3,1,1,4
301,8,47,1,1,3
301,8,58,4,0,
301,8,59,4,0,
301,8,63,4,0,
301,8,70,4,0,
301,8,76,4,0,
301,8,85,4,0,
301,8,86,4,0,
301,8,87,4,0,
301,8,91,4,0,
301,8,92,4,0,
301,8,104,4,0,
301,8,138,4,0,
301,8,148,4,0,
301,8,156,4,0,
301,8,164,4,0,
301,8,182,4,0,
301,8,203,4,0,
301,8,207,4,0,
301,8,213,1,1,2
301,8,213,4,0,
301,8,214,4,0,
301,8,216,4,0,
301,8,218,4,0,
301,8,219,4,0,
301,8,231,4,0,
301,8,237,4,0,
301,8,240,4,0,
301,8,241,4,0,
301,8,244,4,0,
301,8,247,4,0,
301,8,249,4,0,
301,8,252,1,1,1
301,8,263,4,0,
301,8,290,4,0,
301,8,347,4,0,
301,8,351,4,0,
301,8,352,4,0,
301,8,363,4,0,
301,8,371,4,0,
301,8,416,4,0,
301,8,445,4,0,
301,8,447,4,0,
301,8,451,4,0,
301,9,3,1,1,4
301,9,47,1,1,3
301,9,58,4,0,
301,9,59,4,0,
301,9,63,4,0,
301,9,70,4,0,
301,9,76,4,0,
301,9,85,4,0,
301,9,86,4,0,
301,9,87,4,0,
301,9,91,4,0,
301,9,92,4,0,
301,9,104,4,0,
301,9,129,3,0,
301,9,138,4,0,
301,9,148,4,0,
301,9,156,4,0,
301,9,164,4,0,
301,9,173,3,0,
301,9,182,4,0,
301,9,189,3,0,
301,9,196,3,0,
301,9,203,4,0,
301,9,205,3,0,
301,9,207,4,0,
301,9,213,1,1,2
301,9,213,4,0,
301,9,214,4,0,
301,9,216,4,0,
301,9,218,4,0,
301,9,219,4,0,
301,9,231,4,0,
301,9,237,4,0,
301,9,240,4,0,
301,9,241,4,0,
301,9,244,4,0,
301,9,247,4,0,
301,9,249,4,0,
301,9,252,1,1,1
301,9,263,4,0,
301,9,270,3,0,
301,9,290,4,0,
301,9,347,4,0,
301,9,351,4,0,
301,9,352,4,0,
301,9,363,4,0,
301,9,371,4,0,
301,9,387,3,0,
301,9,389,3,0,
301,9,416,4,0,
301,9,428,3,0,
301,9,445,4,0,
301,9,447,4,0,
301,9,451,4,0,
301,10,3,1,1,4
301,10,29,3,0,
301,10,47,1,1,3
301,10,58,4,0,
301,10,59,4,0,
301,10,63,4,0,
301,10,70,4,0,
301,10,76,4,0,
301,10,85,4,0,
301,10,86,4,0,
301,10,87,4,0,
301,10,91,4,0,
301,10,92,4,0,
301,10,104,4,0,
301,10,129,3,0,
301,10,138,4,0,
301,10,148,4,0,
301,10,156,4,0,
301,10,164,4,0,
301,10,173,3,0,
301,10,182,4,0,
301,10,189,3,0,
301,10,196,3,0,
301,10,203,4,0,
301,10,205,3,0,
301,10,207,4,0,
301,10,213,1,1,2
301,10,213,4,0,
301,10,214,4,0,
301,10,215,3,0,
301,10,216,4,0,
301,10,218,4,0,
301,10,219,4,0,
301,10,231,4,0,
301,10,237,4,0,
301,10,240,4,0,
301,10,241,4,0,
301,10,244,4,0,
301,10,247,4,0,
301,10,249,4,0,
301,10,252,1,1,1
301,10,263,4,0,
301,10,270,3,0,
301,10,290,4,0,
301,10,347,4,0,
301,10,351,4,0,
301,10,352,4,0,
301,10,363,4,0,
301,10,371,4,0,
301,10,387,3,0,
301,10,389,3,0,
301,10,416,4,0,
301,10,428,3,0,
301,10,445,4,0,
301,10,447,4,0,
301,10,451,4,0,
301,11,3,1,1,4
301,11,47,1,1,3
301,11,58,4,0,
301,11,59,4,0,
301,11,63,4,0,
301,11,70,4,0,
301,11,76,4,0,
301,11,85,4,0,
301,11,86,4,0,
301,11,87,4,0,
301,11,91,4,0,
301,11,92,4,0,
301,11,104,4,0,
301,11,138,4,0,
301,11,148,4,0,
301,11,156,4,0,
301,11,164,4,0,
301,11,182,4,0,
301,11,207,4,0,
301,11,213,1,1,2
301,11,213,4,0,
301,11,216,4,0,
301,11,218,4,0,
301,11,219,4,0,
301,11,237,4,0,
301,11,240,4,0,
301,11,241,4,0,
301,11,244,4,0,
301,11,247,4,0,
301,11,249,4,0,
301,11,252,1,1,1
301,11,263,4,0,
301,11,347,4,0,
301,11,371,4,0,
301,11,416,4,0,
301,11,447,4,0,
301,11,451,4,0,
301,11,496,4,0,
301,11,497,4,0,
301,11,514,4,0,
301,11,526,4,0,
301,11,528,4,0,
301,12,3,1,1,4
301,12,45,1,1,1
301,12,47,1,1,3
301,12,58,4,0,
301,12,59,4,0,
301,12,63,4,0,
301,12,70,4,0,
301,12,76,4,0,
301,12,85,4,0,
301,12,87,4,0,
301,12,91,4,0,
301,12,92,4,0,
301,12,104,4,0,
301,12,148,4,0,
301,12,156,4,0,
301,12,182,4,0,
301,12,213,1,1,2
301,12,213,4,0,
301,12,216,4,0,
301,12,218,4,0,
301,12,219,4,0,
301,12,231,4,0,
301,12,237,4,0,
301,12,240,4,0,
301,12,241,4,0,
301,12,247,4,0,
301,12,249,4,0,
301,12,263,4,0,
301,12,290,4,0,
301,12,347,4,0,
301,12,351,4,0,
301,12,352,4,0,
301,13,3,1,1,4
301,13,34,3,0,
301,13,38,3,0,
301,13,45,1,1,1
301,13,47,1,1,3
301,13,58,4,0,
301,13,59,4,0,
301,13,63,4,0,
301,13,70,4,0,
301,13,76,4,0,
301,13,85,4,0,
301,13,86,3,0,
301,13,87,4,0,
301,13,91,4,0,
301,13,92,4,0,
301,13,102,3,0,
301,13,104,4,0,
301,13,138,3,0,
301,13,148,4,0,
301,13,156,4,0,
301,13,164,3,0,
301,13,182,4,0,
301,13,196,3,0,
301,13,207,3,0,
301,13,213,1,1,2
301,13,213,4,0,
301,13,216,4,0,
301,13,218,4,0,
301,13,219,4,0,
301,13,231,4,0,
301,13,237,4,0,
301,13,240,4,0,
301,13,241,4,0,
301,13,247,4,0,
301,13,249,4,0,
301,13,263,4,0,
301,13,290,4,0,
301,13,347,4,0,
301,13,351,4,0,
301,13,352,4,0,
301,14,3,1,1,4
301,14,47,1,1,3
301,14,58,4,0,
301,14,59,4,0,
301,14,63,4,0,
301,14,70,4,0,
301,14,76,4,0,
301,14,85,4,0,
301,14,86,4,0,
301,14,87,4,0,
301,14,91,4,0,
301,14,92,4,0,
301,14,104,4,0,
301,14,138,4,0,
301,14,148,4,0,
301,14,156,4,0,
301,14,164,4,0,
301,14,173,3,0,
301,14,182,4,0,
301,14,196,3,0,
301,14,207,4,0,
301,14,213,1,1,2
301,14,213,4,0,
301,14,214,3,0,
301,14,215,3,0,
301,14,216,4,0,
301,14,218,4,0,
301,14,219,4,0,
301,14,231,3,0,
301,14,237,4,0,
301,14,240,4,0,
301,14,241,4,0,
301,14,244,4,0,
301,14,247,4,0,
301,14,249,4,0,
301,14,252,1,1,1
301,14,253,3,0,
301,14,263,4,0,
301,14,270,3,0,
301,14,304,3,0,
301,14,343,3,0,
301,14,347,4,0,
301,14,371,4,0,
301,14,387,3,0,
301,14,416,4,0,
301,14,428,3,0,
301,14,447,4,0,
301,14,451,4,0,
301,14,496,4,0,
301,14,497,4,0,
301,14,514,4,0,
301,14,526,4,0,
301,14,528,4,0,
301,15,3,1,1,4
301,15,47,1,1,3
301,15,58,4,0,
301,15,59,4,0,
301,15,63,4,0,
301,15,70,4,0,
301,15,76,4,0,
301,15,85,4,0,
301,15,86,4,0,
301,15,87,4,0,
301,15,91,4,0,
301,15,92,4,0,
301,15,104,4,0,
301,15,138,4,0,
301,15,148,4,0,
301,15,156,4,0,
301,15,164,4,0,
301,15,182,4,0,
301,15,207,4,0,
301,15,213,1,1,2
301,15,213,4,0,
301,15,214,4,0,
301,15,216,4,0,
301,15,218,4,0,
301,15,219,4,0,
301,15,237,4,0,
301,15,240,4,0,
301,15,241,4,0,
301,15,244,4,0,
301,15,247,4,0,
301,15,249,4,0,
301,15,252,1,1,1
301,15,263,4,0,
301,15,347,4,0,
301,15,371,4,0,
301,15,416,4,0,
301,15,447,4,0,
301,15,451,4,0,
301,15,496,4,0,
301,15,497,4,0,
301,15,514,4,0,
301,15,528,4,0,
301,15,590,4,0,
301,16,3,1,1,4
301,16,47,1,1,2
301,16,58,4,0,
301,16,59,4,0,
301,16,63,4,0,
301,16,70,4,0,
301,16,76,4,0,
301,16,85,4,0,
301,16,86,4,0,
301,16,87,4,0,
301,16,91,4,0,
301,16,92,4,0,
301,16,104,4,0,
301,16,138,4,0,
301,16,148,4,0,
301,16,156,4,0,
301,16,164,4,0,
301,16,173,3,0,
301,16,182,4,0,
301,16,196,3,0,
301,16,207,4,0,
301,16,213,1,1,3
301,16,213,4,0,
301,16,214,4,0,
301,16,215,3,0,
301,16,216,4,0,
301,16,218,4,0,
301,16,219,4,0,
301,16,231,3,0,
301,16,237,4,0,
301,16,240,4,0,
301,16,241,4,0,
301,16,244,4,0,
301,16,247,4,0,
301,16,249,4,0,
301,16,252,1,1,1
301,16,253,3,0,
301,16,263,4,0,
301,16,270,3,0,
301,16,290,4,0,
301,16,304,3,0,
301,16,343,3,0,
301,16,347,4,0,
301,16,351,3,0,
301,16,352,3,0,
301,16,371,4,0,
301,16,387,3,0,
301,16,416,4,0,
301,16,428,3,0,
301,16,447,4,0,
301,16,451,4,0,
301,16,496,4,0,
301,16,497,4,0,
301,16,514,4,0,
301,16,528,4,0,
301,16,590,4,0,
301,17,3,1,1,4
301,17,47,1,1,2
301,17,58,4,0,
301,17,59,4,0,
301,17,63,4,0,
301,17,76,4,0,
301,17,85,4,0,
301,17,86,4,0,
301,17,87,4,0,
301,17,92,4,0,
301,17,104,4,0,
301,17,138,4,0,
301,17,156,4,0,
301,17,164,4,0,
301,17,182,4,0,
301,17,207,4,0,
301,17,213,1,1,3
301,17,213,4,0,
301,17,214,4,0,
301,17,216,4,0,
301,17,218,4,0,
301,17,219,4,0,
301,17,237,4,0,
301,17,240,4,0,
301,17,241,4,0,
301,17,244,4,0,
301,17,247,4,0,
301,17,252,1,1,1
301,17,263,4,0,
301,17,347,4,0,
301,17,371,4,0,
301,17,416,4,0,
301,17,447,4,0,
301,17,451,4,0,
301,17,496,4,0,
301,17,497,4,0,
301,17,526,4,0,
301,17,528,4,0,
301,17,590,4,0,
301,18,3,1,1,4
301,18,47,1,1,2
301,18,58,4,0,
301,18,59,4,0,
301,18,63,4,0,
301,18,76,4,0,
301,18,85,4,0,
301,18,86,4,0,
301,18,87,4,0,
301,18,92,4,0,
301,18,104,4,0,
301,18,138,4,0,
301,18,156,4,0,
301,18,164,4,0,
301,18,182,4,0,
301,18,207,4,0,
301,18,213,1,1,3
301,18,213,4,0,
301,18,214,4,0,
301,18,216,4,0,
301,18,218,4,0,
301,18,219,4,0,
301,18,237,4,0,
301,18,240,4,0,
301,18,241,4,0,
301,18,244,4,0,
301,18,247,4,0,
301,18,252,1,1,1
301,18,263,4,0,
301,18,347,4,0,
301,18,371,4,0,
301,18,416,4,0,
301,18,447,4,0,
301,18,451,4,0,
301,18,496,4,0,
301,18,497,4,0,
301,18,526,4,0,
301,18,528,4,0,
301,18,590,4,0,
302,5,10,1,1,2
302,5,15,4,0,
302,5,43,1,1,1
302,5,91,4,0,
302,5,92,4,0,
302,5,94,4,0,
302,5,101,1,9,
302,5,104,4,0,
302,5,105,2,0,
302,5,109,1,37,
302,5,148,4,0,
302,5,154,1,17,
302,5,156,4,0,
302,5,168,4,0,
302,5,182,4,0,
302,5,185,1,29,
302,5,193,1,5,
302,5,197,1,25,
302,5,212,1,45,
302,5,213,4,0,
302,5,216,4,0,
302,5,218,4,0,
302,5,236,2,0,
302,5,237,4,0,
302,5,240,4,0,
302,5,241,4,0,
302,5,244,2,0,
302,5,247,1,41,
302,5,247,4,0,
302,5,249,4,0,
302,5,252,1,21,
302,5,259,4,0,
302,5,263,4,0,
302,5,264,4,0,
302,5,269,4,0,
302,5,280,4,0,
302,5,282,1,33,
302,5,289,4,0,
302,5,290,4,0,
302,5,310,1,13,
302,5,317,4,0,
302,5,332,4,0,
302,5,347,4,0,
302,5,351,4,0,
302,5,352,4,0,
302,6,5,3,0,
302,6,7,3,0,
302,6,8,3,0,
302,6,9,3,0,
302,6,10,1,1,2
302,6,15,4,0,
302,6,25,3,0,
302,6,34,3,0,
302,6,38,3,0,
302,6,43,1,1,1
302,6,68,3,0,
302,6,69,3,0,
302,6,91,4,0,
302,6,92,4,0,
302,6,94,4,0,
302,6,101,1,9,
302,6,102,3,0,
302,6,104,4,0,
302,6,105,2,0,
302,6,109,1,37,
302,6,118,3,0,
302,6,138,3,0,
302,6,148,4,0,
302,6,154,1,17,
302,6,156,4,0,
302,6,164,3,0,
302,6,168,4,0,
302,6,173,3,0,
302,6,182,4,0,
302,6,185,1,29,
302,6,189,3,0,
302,6,193,1,5,
302,6,197,1,25,
302,6,203,3,0,
302,6,207,3,0,
302,6,210,3,0,
302,6,212,1,45,
302,6,213,4,0,
302,6,214,3,0,
302,6,216,4,0,
302,6,218,4,0,
302,6,223,3,0,
302,6,236,2,0,
302,6,237,4,0,
302,6,240,4,0,
302,6,241,4,0,
302,6,244,2,0,
302,6,244,3,0,
302,6,247,1,41,
302,6,247,4,0,
302,6,249,4,0,
302,6,252,1,21,
302,6,259,4,0,
302,6,263,4,0,
302,6,264,4,0,
302,6,269,4,0,
302,6,280,4,0,
302,6,282,1,33,
302,6,289,4,0,
302,6,290,4,0,
302,6,310,1,13,
302,6,317,4,0,
302,6,332,4,0,
302,6,347,4,0,
302,6,351,4,0,
302,6,352,4,0,
302,7,5,3,0,
302,7,10,1,1,2
302,7,15,4,0,
302,7,25,3,0,
302,7,34,3,0,
302,7,38,3,0,
302,7,43,1,1,1
302,7,68,3,0,
302,7,69,3,0,
302,7,91,4,0,
302,7,92,4,0,
302,7,94,4,0,
302,7,101,1,9,
302,7,102,3,0,
302,7,104,4,0,
302,7,105,2,0,
302,7,109,1,37,
302,7,118,3,0,
302,7,138,3,0,
302,7,148,4,0,
302,7,154,1,17,
302,7,156,4,0,
302,7,164,3,0,
302,7,168,4,0,
302,7,182,4,0,
302,7,185,1,29,
302,7,193,1,5,
302,7,197,1,25,
302,7,212,1,45,
302,7,213,4,0,
302,7,216,4,0,
302,7,218,4,0,
302,7,236,2,0,
302,7,237,4,0,
302,7,240,4,0,
302,7,241,4,0,
302,7,244,2,0,
302,7,247,1,41,
302,7,247,4,0,
302,7,249,4,0,
302,7,252,1,21,
302,7,259,4,0,
302,7,263,4,0,
302,7,264,4,0,
302,7,269,4,0,
302,7,280,4,0,
302,7,282,1,33,
302,7,289,4,0,
302,7,290,4,0,
302,7,310,1,13,
302,7,317,4,0,
302,7,332,4,0,
302,7,347,4,0,
302,7,351,4,0,
302,7,352,4,0,
302,8,10,1,1,2
302,8,15,4,0,
302,8,43,1,1,1
302,8,91,4,0,
302,8,92,4,0,
302,8,94,4,0,
302,8,101,1,8,
302,8,104,4,0,
302,8,105,2,0,
302,8,109,1,46,
302,8,138,4,0,
302,8,148,4,0,
302,8,154,1,15,
302,8,156,4,0,
302,8,164,4,0,
302,8,168,4,0,
302,8,182,4,0,
302,8,185,1,32,
302,8,193,1,4,
302,8,197,1,22,
302,8,203,4,0,
302,8,207,4,0,
302,8,212,1,57,
302,8,213,4,0,
302,8,214,4,0,
302,8,216,4,0,
302,8,218,4,0,
302,8,236,2,0,
302,8,237,4,0,
302,8,240,4,0,
302,8,241,4,0,
302,8,244,2,0,
302,8,244,4,0,
302,8,247,1,53,
302,8,247,4,0,
302,8,249,4,0,
302,8,252,1,18,
302,8,259,4,0,
302,8,260,2,0,
302,8,261,4,0,
302,8,263,4,0,
302,8,264,4,0,
302,8,269,4,0,
302,8,280,4,0,
302,8,282,1,29,
302,8,289,4,0,
302,8,290,4,0,
302,8,310,1,11,
302,8,317,4,0,
302,8,332,4,0,
302,8,347,4,0,
302,8,351,4,0,
302,8,352,4,0,
302,8,363,4,0,
302,8,371,4,0,
302,8,373,4,0,
302,8,374,4,0,
302,8,386,1,36,
302,8,398,4,0,
302,8,399,4,0,
302,8,408,1,43,
302,8,417,2,0,
302,8,421,1,39,
302,8,421,4,0,
302,8,425,1,25,
302,8,428,1,50,
302,8,445,4,0,
302,9,7,3,0,
302,9,8,3,0,
302,9,9,3,0,
302,9,10,1,1,2
302,9,15,4,0,
302,9,43,1,1,1
302,9,91,4,0,
302,9,92,4,0,
302,9,94,4,0,
302,9,101,1,8,
302,9,104,4,0,
302,9,105,2,0,
302,9,109,1,46,
302,9,138,4,0,
302,9,148,4,0,
302,9,154,1,15,
302,9,156,4,0,
302,9,164,4,0,
302,9,168,4,0,
302,9,173,3,0,
302,9,180,3,0,
302,9,182,4,0,
302,9,185,1,32,
302,9,189,3,0,
302,9,193,1,4,
302,9,196,3,0,
302,9,197,1,22,
302,9,203,4,0,
302,9,207,4,0,
302,9,210,3,0,
302,9,212,1,57,
302,9,213,4,0,
302,9,214,4,0,
302,9,216,4,0,
302,9,218,4,0,
302,9,236,2,0,
302,9,237,4,0,
302,9,240,4,0,
302,9,241,4,0,
302,9,244,2,0,
302,9,244,4,0,
302,9,247,1,53,
302,9,247,4,0,
302,9,249,4,0,
302,9,252,1,18,
302,9,259,4,0,
302,9,260,2,0,
302,9,261,4,0,
302,9,263,4,0,
302,9,264,4,0,
302,9,269,4,0,
302,9,271,3,0,
302,9,280,4,0,
302,9,282,1,29,
302,9,282,3,0,
302,9,289,4,0,
302,9,290,4,0,
302,9,310,1,11,
302,9,317,4,0,
302,9,324,3,0,
302,9,332,4,0,
302,9,347,4,0,
302,9,351,4,0,
302,9,352,4,0,
302,9,363,4,0,
302,9,371,4,0,
302,9,373,4,0,
302,9,374,4,0,
302,9,386,1,36,
302,9,389,3,0,
302,9,398,4,0,
302,9,399,4,0,
302,9,408,1,43,
302,9,417,2,0,
302,9,421,1,39,
302,9,421,4,0,
302,9,425,1,25,
302,9,428,1,50,
302,9,428,3,0,
302,9,445,4,0,
302,9,466,3,0,
302,10,7,3,0,
302,10,8,3,0,
302,10,9,3,0,
302,10,10,1,1,2
302,10,15,4,0,
302,10,29,3,0,
302,10,43,1,1,1
302,10,67,3,0,
302,10,91,4,0,
302,10,92,4,0,
302,10,94,4,0,
302,10,101,1,8,
302,10,104,4,0,
302,10,105,2,0,
302,10,109,1,46,
302,10,138,4,0,
302,10,148,4,0,
302,10,154,1,15,
302,10,156,4,0,
302,10,164,4,0,
302,10,168,4,0,
302,10,173,3,0,
302,10,180,3,0,
302,10,182,4,0,
302,10,185,1,32,
302,10,189,3,0,
302,10,193,1,4,
302,10,196,3,0,
302,10,197,1,22,
302,10,203,4,0,
302,10,207,4,0,
302,10,210,3,0,
302,10,212,1,57,
302,10,213,4,0,
302,10,214,4,0,
302,10,216,4,0,
302,10,218,4,0,
302,10,220,3,0,
302,10,236,2,0,
302,10,237,4,0,
302,10,240,4,0,
302,10,241,4,0,
302,10,244,2,0,
302,10,244,4,0,
302,10,247,1,53,
302,10,247,4,0,
302,10,249,4,0,
302,10,252,1,18,
302,10,259,4,0,
302,10,260,2,0,
302,10,261,4,0,
302,10,263,4,0,
302,10,264,4,0,
302,10,269,4,0,
302,10,271,3,0,
302,10,272,3,0,
302,10,277,3,0,
302,10,280,4,0,
302,10,282,1,29,
302,10,282,3,0,
302,10,289,4,0,
302,10,290,4,0,
302,10,310,1,11,
302,10,317,4,0,
302,10,324,3,0,
302,10,332,4,0,
302,10,347,4,0,
302,10,351,4,0,
302,10,352,4,0,
302,10,356,3,0,
302,10,363,4,0,
302,10,364,2,0,
302,10,371,4,0,
302,10,373,4,0,
302,10,374,4,0,
302,10,386,1,36,
302,10,389,3,0,
302,10,398,4,0,
302,10,399,4,0,
302,10,408,1,43,
302,10,417,2,0,
302,10,421,1,39,
302,10,421,4,0,
302,10,425,1,25,
302,10,428,1,50,
302,10,428,3,0,
302,10,445,4,0,
302,10,466,3,0,
302,11,10,1,1,2
302,11,15,4,0,
302,11,43,1,1,1
302,11,91,4,0,
302,11,92,4,0,
302,11,94,4,0,
302,11,101,1,8,
302,11,104,4,0,
302,11,105,2,0,
302,11,109,1,46,
302,11,138,4,0,
302,11,148,4,0,
302,11,154,1,15,
302,11,156,4,0,
302,11,164,4,0,
302,11,168,4,0,
302,11,182,4,0,
302,11,185,1,32,
302,11,193,1,4,
302,11,197,1,22,
302,11,207,4,0,
302,11,212,1,60,
302,11,212,2,0,
302,11,213,4,0,
302,11,216,4,0,
302,11,218,4,0,
302,11,236,2,0,
302,11,237,4,0,
302,11,240,4,0,
302,11,241,4,0,
302,11,244,4,0,
302,11,247,1,57,
302,11,247,4,0,
302,11,249,4,0,
302,11,252,1,18,
302,11,259,4,0,
302,11,260,2,0,
302,11,261,4,0,
302,11,263,4,0,
302,11,269,4,0,
302,11,271,2,0,
302,11,280,4,0,
302,11,282,1,29,
302,11,310,1,11,
302,11,317,4,0,
302,11,332,4,0,
302,11,347,4,0,
302,11,364,2,0,
302,11,368,2,0,
302,11,371,4,0,
302,11,373,4,0,
302,11,374,4,0,
302,11,386,1,36,
302,11,389,2,0,
302,11,398,4,0,
302,11,408,1,43,
302,11,417,2,0,
302,11,421,1,39,
302,11,421,4,0,
302,11,425,1,25,
302,11,428,1,53,
302,11,445,2,0,
302,11,468,4,0,
302,11,477,4,0,
302,11,490,4,0,
302,11,492,1,50,
302,11,496,4,0,
302,11,510,4,0,
302,11,514,4,0,
302,11,555,4,0,
302,12,10,1,1,2
302,12,15,4,0,
302,12,43,1,1,1
302,12,91,4,0,
302,12,92,4,0,
302,12,94,4,0,
302,12,101,1,9,
302,12,104,4,0,
302,12,109,1,37,
302,12,148,4,0,
302,12,154,1,17,
302,12,156,4,0,
302,12,168,4,0,
302,12,182,4,0,
302,12,185,1,29,
302,12,193,1,5,
302,12,197,1,25,
302,12,212,1,45,
302,12,213,4,0,
302,12,216,4,0,
302,12,218,4,0,
302,12,237,4,0,
302,12,240,4,0,
302,12,241,4,0,
302,12,247,1,41,
302,12,247,4,0,
302,12,249,4,0,
302,12,252,1,21,
302,12,259,4,0,
302,12,263,4,0,
302,12,264,4,0,
302,12,269,4,0,
302,12,280,4,0,
302,12,282,1,33,
302,12,289,4,0,
302,12,290,4,0,
302,12,310,1,13,
302,12,317,4,0,
302,12,332,4,0,
302,12,347,4,0,
302,12,351,4,0,
302,12,352,4,0,
302,13,10,1,1,2
302,13,15,4,0,
302,13,34,3,0,
302,13,38,3,0,
302,13,43,1,1,1
302,13,69,3,0,
302,13,91,4,0,
302,13,92,4,0,
302,13,94,4,0,
302,13,101,1,9,
302,13,102,3,0,
302,13,104,4,0,
302,13,109,1,37,
302,13,138,3,0,
302,13,148,4,0,
302,13,154,1,17,
302,13,156,4,0,
302,13,164,3,0,
302,13,168,4,0,
302,13,171,3,0,
302,13,182,4,0,
302,13,185,1,29,
302,13,193,1,5,
302,13,197,1,25,
302,13,207,3,0,
302,13,212,1,45,
302,13,213,4,0,
302,13,216,4,0,
302,13,218,4,0,
302,13,237,4,0,
302,13,240,4,0,
302,13,241,4,0,
302,13,247,1,41,
302,13,247,4,0,
302,13,249,4,0,
302,13,252,1,21,
302,13,259,4,0,
302,13,263,4,0,
302,13,264,4,0,
302,13,269,4,0,
302,13,280,4,0,
302,13,282,1,33,
302,13,289,4,0,
302,13,290,4,0,
302,13,310,1,13,
302,13,317,4,0,
302,13,332,4,0,
302,13,347,4,0,
302,13,351,4,0,
302,13,352,4,0,
302,14,7,3,0,
302,14,8,3,0,
302,14,9,3,0,
302,14,10,1,1,2
302,14,15,4,0,
302,14,43,1,1,1
302,14,67,3,0,
302,14,91,4,0,
302,14,92,4,0,
302,14,94,4,0,
302,14,101,1,8,
302,14,104,4,0,
302,14,105,2,0,
302,14,109,1,46,
302,14,138,4,0,
302,14,148,4,0,
302,14,154,1,15,
302,14,156,4,0,
302,14,164,4,0,
302,14,168,4,0,
302,14,173,3,0,
302,14,180,3,0,
302,14,182,4,0,
302,14,185,1,32,
302,14,193,1,4,
302,14,196,3,0,
302,14,197,1,22,
302,14,207,4,0,
302,14,212,1,60,
302,14,212,2,0,
302,14,213,4,0,
302,14,214,3,0,
302,14,216,4,0,
302,14,218,4,0,
302,14,236,2,0,
302,14,237,4,0,
302,14,240,4,0,
302,14,241,4,0,
302,14,244,4,0,
302,14,247,1,57,
302,14,247,4,0,
302,14,249,4,0,
302,14,252,1,18,
302,14,259,4,0,
302,14,260,2,0,
302,14,261,4,0,
302,14,263,4,0,
302,14,269,4,0,
302,14,271,2,0,
302,14,271,3,0,
302,14,272,3,0,
302,14,277,3,0,
302,14,280,4,0,
302,14,282,1,29,
302,14,282,3,0,
302,14,289,3,0,
302,14,310,1,11,
302,14,317,4,0,
302,14,324,3,0,
302,14,332,4,0,
302,14,347,4,0,
302,14,356,3,0,
302,14,364,2,0,
302,14,368,2,0,
302,14,371,4,0,
302,14,373,4,0,
302,14,374,4,0,
302,14,386,1,36,
302,14,389,2,0,
302,14,398,4,0,
302,14,399,3,0,
302,14,408,1,43,
302,14,417,2,0,
302,14,421,1,39,
302,14,421,4,0,
302,14,425,1,25,
302,14,428,1,53,
302,14,428,3,0,
302,14,445,2,0,
302,14,468,4,0,
302,14,472,3,0,
302,14,477,4,0,
302,14,490,4,0,
302,14,492,1,50,
302,14,492,3,0,
302,14,496,4,0,
302,14,510,4,0,
302,14,514,4,0,
302,14,555,4,0,
302,15,10,1,1,4
302,15,15,4,0,
302,15,43,1,1,3
302,15,91,4,0,
302,15,92,4,0,
302,15,94,4,0,
302,15,101,1,8,
302,15,104,4,0,
302,15,105,2,0,
302,15,109,1,46,
302,15,138,4,0,
302,15,148,4,0,
302,15,154,1,15,
302,15,156,4,0,
302,15,164,4,0,
302,15,168,4,0,
302,15,182,4,0,
302,15,185,1,32,
302,15,193,1,4,
302,15,197,1,22,
302,15,207,4,0,
302,15,212,1,1,1
302,15,212,1,60,
302,15,212,2,0,
302,15,213,4,0,
302,15,214,4,0,
302,15,216,4,0,
302,15,218,4,0,
302,15,236,2,0,
302,15,237,4,0,
302,15,240,4,0,
302,15,241,4,0,
302,15,244,4,0,
302,15,247,1,57,
302,15,247,4,0,
302,15,249,4,0,
302,15,252,1,18,
302,15,259,4,0,
302,15,260,2,0,
302,15,261,4,0,
302,15,263,4,0,
302,15,269,4,0,
302,15,271,2,0,
302,15,280,4,0,
302,15,282,1,29,
302,15,286,2,0,
302,15,310,1,11,
302,15,317,4,0,
302,15,332,4,0,
302,15,347,4,0,
302,15,364,2,0,
302,15,368,2,0,
302,15,371,4,0,
302,15,373,4,0,
302,15,374,4,0,
302,15,386,1,36,
302,15,389,2,0,
302,15,398,4,0,
302,15,399,4,0,
302,15,408,1,43,
302,15,417,2,0,
302,15,421,1,39,
302,15,421,4,0,
302,15,425,1,25,
302,15,428,1,1,2
302,15,428,1,53,
302,15,445,2,0,
302,15,468,4,0,
302,15,490,4,0,
302,15,492,1,50,
302,15,496,4,0,
302,15,510,4,0,
302,15,514,4,0,
302,15,555,4,0,
302,15,590,4,0,
302,15,605,4,0,
302,15,612,4,0,
302,16,7,3,0,
302,16,8,3,0,
302,16,9,3,0,
302,16,10,1,1,2
302,16,15,4,0,
302,16,43,1,1,1
302,16,67,3,0,
302,16,91,4,0,
302,16,92,4,0,
302,16,94,4,0,
302,16,101,1,6,1
302,16,104,4,0,
302,16,105,2,0,
302,16,109,1,31,1
302,16,138,4,0,
302,16,148,4,0,
302,16,154,1,11,1
302,16,156,4,0,
302,16,164,4,0,
302,16,168,4,0,
302,16,173,3,0,
302,16,180,3,0,
302,16,182,4,0,
302,16,185,1,19,1
302,16,193,1,4,1
302,16,196,3,0,
302,16,197,1,14,1
302,16,207,4,0,
302,16,212,1,46,1
302,16,212,2,0,
302,16,213,4,0,
302,16,214,4,0,
302,16,216,4,0,
302,16,218,4,0,
302,16,220,3,0,
302,16,236,2,0,
302,16,237,4,0,
302,16,240,4,0,
302,16,241,4,0,
302,16,244,4,0,
302,16,247,1,39,1
302,16,247,4,0,
302,16,249,4,0,
302,16,252,1,21,1
302,16,259,4,0,
302,16,260,2,0,
302,16,261,4,0,
302,16,263,4,0,
302,16,264,3,0,
302,16,269,4,0,
302,16,271,2,0,
302,16,271,3,0,
302,16,272,3,0,
302,16,277,3,0,
302,16,280,4,0,
302,16,282,1,26,1
302,16,282,3,0,
302,16,286,2,0,
302,16,289,3,0,
302,16,290,4,0,
302,16,310,1,9,1
302,16,317,4,0,
302,16,324,3,0,
302,16,332,4,0,
302,16,347,4,0,
302,16,351,3,0,
302,16,352,3,0,
302,16,356,3,0,
302,16,364,2,0,
302,16,368,2,0,
302,16,371,4,0,
302,16,373,4,0,
302,16,374,4,0,
302,16,386,1,24,1
302,16,389,2,0,
302,16,398,4,0,
302,16,399,4,0,
302,16,408,1,36,1
302,16,417,2,0,
302,16,421,1,29,1
302,16,421,4,0,
302,16,425,1,16,1
302,16,428,1,34,1
302,16,428,3,0,
302,16,445,2,0,
302,16,468,4,0,
302,16,472,3,0,
302,16,490,4,0,
302,16,492,1,41,1
302,16,492,3,0,
302,16,496,4,0,
302,16,510,4,0,
302,16,511,1,44,1
302,16,511,4,0,
302,16,514,4,0,
302,16,555,4,0,
302,16,590,4,0,
302,16,605,4,0,
302,16,612,4,0,
302,17,10,1,1,2
302,17,43,1,1,1
302,17,92,4,0,
302,17,94,4,0,
302,17,101,1,6,
302,17,104,4,0,
302,17,105,2,0,
302,17,109,1,31,
302,17,138,4,0,
302,17,154,1,11,
302,17,156,4,0,
302,17,164,4,0,
302,17,168,4,0,
302,17,182,4,0,
302,17,185,1,19,
302,17,193,1,4,
302,17,197,1,14,
302,17,207,4,0,
302,17,212,1,46,
302,17,212,2,0,
302,17,213,4,0,
302,17,214,4,0,
302,17,216,4,0,
302,17,218,4,0,
302,17,236,2,0,
302,17,237,4,0,
302,17,240,4,0,
302,17,241,4,0,
302,17,244,4,0,
302,17,247,1,39,
302,17,247,4,0,
302,17,252,1,21,
302,17,259,4,0,
302,17,260,2,0,
302,17,261,4,0,
302,17,263,4,0,
302,17,269,4,0,
302,17,271,2,0,
302,17,280,4,0,
302,17,282,1,26,
302,17,286,2,0,
302,17,310,1,9,
302,17,317,4,0,
302,17,332,4,0,
302,17,347,4,0,
302,17,364,2,0,
302,17,368,2,0,
302,17,371,4,0,
302,17,373,4,0,
302,17,374,4,0,
302,17,386,1,24,
302,17,389,2,0,
302,17,398,4,0,
302,17,399,4,0,
302,17,408,1,36,
302,17,417,2,0,
302,17,421,1,29,
302,17,421,4,0,
302,17,425,1,16,
302,17,428,1,34,
302,17,445,2,0,
302,17,490,4,0,
302,17,492,1,41,
302,17,496,4,0,
302,17,511,1,44,
302,17,511,4,0,
302,17,555,4,0,
302,17,590,4,0,
302,17,605,4,0,
302,18,10,1,1,2
302,18,43,1,1,1
302,18,92,4,0,
302,18,94,4,0,
302,18,101,1,6,
302,18,104,4,0,
302,18,105,2,0,
302,18,109,1,31,
302,18,138,4,0,
302,18,154,1,11,
302,18,156,4,0,
302,18,164,4,0,
302,18,168,4,0,
302,18,182,4,0,
302,18,185,1,19,
302,18,193,1,4,
302,18,197,1,14,
302,18,207,4,0,
302,18,212,1,46,
302,18,212,2,0,
302,18,213,4,0,
302,18,214,4,0,
302,18,216,4,0,
302,18,218,4,0,
302,18,236,2,0,
302,18,237,4,0,
302,18,240,4,0,
302,18,241,4,0,
302,18,244,4,0,
302,18,247,1,39,
302,18,247,4,0,
302,18,252,1,21,
302,18,259,4,0,
302,18,260,2,0,
302,18,261,4,0,
302,18,263,4,0,
302,18,269,4,0,
302,18,271,2,0,
302,18,280,4,0,
302,18,282,1,26,
302,18,286,2,0,
302,18,310,1,9,
302,18,317,4,0,
302,18,332,4,0,
302,18,347,4,0,
302,18,364,2,0,
302,18,368,2,0,
302,18,371,4,0,
302,18,373,4,0,
302,18,374,4,0,
302,18,386,1,24,
302,18,389,2,0,
302,18,398,4,0,
302,18,399,4,0,
302,18,408,1,36,
302,18,417,2,0,
302,18,421,1,29,
302,18,421,4,0,
302,18,425,1,16,
302,18,428,1,34,
302,18,445,2,0,
302,18,490,4,0,
302,18,492,1,41,
302,18,496,4,0,
302,18,511,1,44,
302,18,511,4,0,
302,18,555,4,0,
302,18,590,4,0,
302,18,605,4,0,
303,5,11,1,21,
303,5,14,2,0,
303,5,44,1,11,
303,5,53,4,0,
303,5,58,4,0,
303,5,63,4,0,
303,5,70,4,0,
303,5,76,4,0,
303,5,92,4,0,
303,5,104,4,0,
303,5,126,4,0,
303,5,156,4,0,
303,5,182,4,0,
303,5,185,1,26,
303,5,188,4,0,
303,5,201,4,0,
303,5,206,2,0,
303,5,213,4,0,
303,5,216,4,0,
303,5,218,4,0,
303,5,226,1,31,
303,5,230,1,16,
303,5,237,4,0,
303,5,240,4,0,
303,5,241,4,0,
303,5,242,1,36,
303,5,244,2,0,
303,5,246,2,0,
303,5,249,4,0,
303,5,254,1,46,1
303,5,255,1,46,3
303,5,256,1,46,2
303,5,259,4,0,
303,5,263,4,0,
303,5,264,4,0,
303,5,269,4,0,
303,5,280,4,0,
303,5,290,4,0,
303,5,305,2,0,
303,5,310,1,1,
303,5,313,1,6,
303,5,317,4,0,
303,5,321,2,0,
303,5,334,1,41,
303,6,5,3,0,
303,6,8,3,0,
303,6,9,3,0,
303,6,11,1,21,
303,6,14,2,0,
303,6,14,3,0,
303,6,25,3,0,
303,6,34,3,0,
303,6,38,3,0,
303,6,44,1,11,
303,6,53,4,0,
303,6,58,4,0,
303,6,63,4,0,
303,6,68,3,0,
303,6,69,3,0,
303,6,70,4,0,
303,6,76,4,0,
303,6,92,4,0,
303,6,102,3,0,
303,6,104,4,0,
303,6,126,4,0,
303,6,156,4,0,
303,6,157,3,0,
303,6,164,3,0,
303,6,173,3,0,
303,6,182,4,0,
303,6,185,1,26,
303,6,188,4,0,
303,6,189,3,0,
303,6,196,3,0,
303,6,201,4,0,
303,6,203,3,0,
303,6,206,2,0,
303,6,207,3,0,
303,6,213,4,0,
303,6,214,3,0,
303,6,216,4,0,
303,6,218,4,0,
303,6,223,3,0,
303,6,226,1,31,
303,6,230,1,16,
303,6,237,4,0,
303,6,240,4,0,
303,6,241,4,0,
303,6,242,1,36,
303,6,244,2,0,
303,6,244,3,0,
303,6,246,2,0,
303,6,249,4,0,
303,6,254,1,46,1
303,6,255,1,46,3
303,6,256,1,46,2
303,6,259,4,0,
303,6,263,4,0,
303,6,264,4,0,
303,6,269,4,0,
303,6,280,4,0,
303,6,290,4,0,
303,6,305,2,0,
303,6,310,1,1,
303,6,313,1,6,
303,6,317,4,0,
303,6,321,2,0,
303,6,334,1,41,
303,7,5,3,0,
303,7,11,1,21,
303,7,14,2,0,
303,7,14,3,0,
303,7,25,3,0,
303,7,34,3,0,
303,7,38,3,0,
303,7,44,1,11,
303,7,53,4,0,
303,7,58,4,0,
303,7,63,4,0,
303,7,68,3,0,
303,7,69,3,0,
303,7,70,4,0,
303,7,76,4,0,
303,7,92,4,0,
303,7,102,3,0,
303,7,104,4,0,
303,7,126,4,0,
303,7,156,4,0,
303,7,157,3,0,
303,7,164,3,0,
303,7,182,4,0,
303,7,185,1,26,
303,7,188,4,0,
303,7,201,4,0,
303,7,206,2,0,
303,7,213,4,0,
303,7,216,4,0,
303,7,218,4,0,
303,7,226,1,31,
303,7,230,1,16,
303,7,237,4,0,
303,7,240,4,0,
303,7,241,4,0,
303,7,242,1,36,
303,7,244,2,0,
303,7,246,2,0,
303,7,249,4,0,
303,7,254,1,46,1
303,7,255,1,46,3
303,7,256,1,46,2
303,7,259,4,0,
303,7,263,4,0,
303,7,264,4,0,
303,7,269,4,0,
303,7,280,4,0,
303,7,290,4,0,
303,7,305,2,0,
303,7,310,1,1,
303,7,313,1,6,
303,7,317,4,0,
303,7,321,2,0,
303,7,334,1,41,
303,8,11,1,21,
303,8,14,2,0,
303,8,14,4,0,
303,8,44,1,11,
303,8,53,4,0,
303,8,58,4,0,
303,8,63,4,0,
303,8,70,4,0,
303,8,76,4,0,
303,8,92,4,0,
303,8,104,4,0,
303,8,126,4,0,
303,8,156,4,0,
303,8,157,4,0,
303,8,164,4,0,
303,8,182,4,0,
303,8,185,1,26,
303,8,188,4,0,
303,8,201,4,0,
303,8,203,4,0,
303,8,206,2,0,
303,8,207,4,0,
303,8,213,4,0,
303,8,214,4,0,
303,8,216,4,0,
303,8,218,4,0,
303,8,226,1,31,
303,8,230,1,16,
303,8,237,4,0,
303,8,240,4,0,
303,8,241,4,0,
303,8,242,1,36,
303,8,244,2,0,
303,8,246,2,0,
303,8,247,4,0,
303,8,249,4,0,
303,8,254,1,51,1
303,8,255,1,51,3
303,8,256,1,51,2
303,8,259,4,0,
303,8,263,4,0,
303,8,264,4,0,
303,8,269,4,0,
303,8,280,4,0,
303,8,290,4,0,
303,8,305,2,0,
303,8,310,1,1,
303,8,313,1,6,
303,8,317,4,0,
303,8,321,2,0,
303,8,334,1,41,
303,8,363,4,0,
303,8,371,4,0,
303,8,373,4,0,
303,8,374,4,0,
303,8,386,2,0,
303,8,389,1,46,
303,8,389,2,0,
303,8,399,4,0,
303,8,411,4,0,
303,8,416,4,0,
303,8,422,2,0,
303,8,423,2,0,
303,8,424,2,0,
303,8,430,4,0,
303,8,442,1,56,
303,8,445,4,0,
303,8,447,4,0,
303,8,451,4,0,
303,9,8,3,0,
303,9,9,3,0,
303,9,11,1,21,
303,9,14,2,0,
303,9,14,4,0,
303,9,44,1,11,
303,9,53,4,0,
303,9,58,4,0,
303,9,63,4,0,
303,9,70,4,0,
303,9,76,4,0,
303,9,92,4,0,
303,9,104,4,0,
303,9,126,4,0,
303,9,156,4,0,
303,9,157,4,0,
303,9,164,4,0,
303,9,173,3,0,
303,9,182,4,0,
303,9,185,1,26,
303,9,188,4,0,
303,9,189,3,0,
303,9,196,3,0,
303,9,201,4,0,
303,9,203,4,0,
303,9,206,2,0,
303,9,207,4,0,
303,9,213,4,0,
303,9,214,4,0,
303,9,216,4,0,
303,9,218,4,0,
303,9,226,1,31,
303,9,230,1,16,
303,9,237,4,0,
303,9,240,4,0,
303,9,241,4,0,
303,9,242,1,36,
303,9,244,2,0,
303,9,246,2,0,
303,9,246,3,0,
303,9,247,4,0,
303,9,249,4,0,
303,9,254,1,51,1
303,9,255,1,51,3
303,9,256,1,51,2
303,9,259,4,0,
303,9,263,4,0,
303,9,264,4,0,
303,9,269,4,0,
303,9,280,4,0,
303,9,282,3,0,
303,9,290,4,0,
303,9,305,2,0,
303,9,310,1,1,
303,9,313,1,6,
303,9,317,4,0,
303,9,321,2,0,
303,9,334,1,41,
303,9,334,3,0,
303,9,363,4,0,
303,9,371,4,0,
303,9,373,4,0,
303,9,374,4,0,
303,9,386,2,0,
303,9,389,1,46,
303,9,389,2,0,
303,9,389,3,0,
303,9,393,3,0,
303,9,399,4,0,
303,9,411,4,0,
303,9,416,4,0,
303,9,422,2,0,
303,9,423,2,0,
303,9,424,2,0,
303,9,430,4,0,
303,9,442,1,56,
303,9,442,3,0,
303,9,445,4,0,
303,9,447,4,0,
303,9,451,4,0,
303,10,8,3,0,
303,10,9,3,0,
303,10,11,1,21,
303,10,14,2,0,
303,10,14,4,0,
303,10,29,3,0,
303,10,44,1,11,
303,10,53,4,0,
303,10,58,4,0,
303,10,63,4,0,
303,10,70,4,0,
303,10,76,4,0,
303,10,92,4,0,
303,10,104,4,0,
303,10,126,4,0,
303,10,156,4,0,
303,10,157,4,0,
303,10,162,3,0,
303,10,164,4,0,
303,10,173,3,0,
303,10,182,4,0,
303,10,185,1,26,
303,10,188,4,0,
303,10,189,3,0,
303,10,196,3,0,
303,10,201,4,0,
303,10,203,4,0,
303,10,206,2,0,
303,10,207,4,0,
303,10,213,4,0,
303,10,214,4,0,
303,10,216,4,0,
303,10,218,4,0,
303,10,220,3,0,
303,10,226,1,31,
303,10,230,1,16,
303,10,237,4,0,
303,10,240,4,0,
303,10,241,4,0,
303,10,242,1,36,
303,10,244,2,0,
303,10,246,2,0,
303,10,246,3,0,
303,10,247,4,0,
303,10,249,4,0,
303,10,254,1,51,1
303,10,255,1,51,3
303,10,256,1,51,2
303,10,259,4,0,
303,10,263,4,0,
303,10,264,4,0,
303,10,269,4,0,
303,10,280,4,0,
303,10,282,3,0,
303,10,290,4,0,
303,10,305,2,0,
303,10,310,1,1,
303,10,313,1,6,
303,10,317,4,0,
303,10,321,2,0,
303,10,334,1,41,
303,10,334,3,0,
303,10,363,4,0,
303,10,371,4,0,
303,10,373,4,0,
303,10,374,4,0,
303,10,385,2,0,
303,10,386,2,0,
303,10,389,1,46,
303,10,389,2,0,
303,10,389,3,0,
303,10,393,3,0,
303,10,399,4,0,
303,10,411,4,0,
303,10,416,4,0,
303,10,422,2,0,
303,10,423,2,0,
303,10,424,2,0,
303,10,430,4,0,
303,10,442,1,56,
303,10,442,3,0,
303,10,445,4,0,
303,10,447,4,0,
303,10,451,4,0,
303,11,11,1,21,
303,11,14,4,0,
303,11,21,2,0,
303,11,44,1,11,
303,11,53,4,0,
303,11,58,4,0,
303,11,63,4,0,
303,11,70,4,0,
303,11,76,4,0,
303,11,92,4,0,
303,11,104,4,0,
303,11,126,4,0,
303,11,156,4,0,
303,11,157,4,0,
303,11,164,4,0,
303,11,182,4,0,
303,11,185,1,26,
303,11,188,4,0,
303,11,201,4,0,
303,11,206,4,0,
303,11,207,4,0,
303,11,213,4,0,
303,11,216,4,0,
303,11,218,4,0,
303,11,226,1,31,
303,11,230,1,16,
303,11,237,4,0,
303,11,240,4,0,
303,11,241,4,0,
303,11,242,1,36,
303,11,244,4,0,
303,11,246,2,0,
303,11,247,4,0,
303,11,249,4,0,
303,11,254,1,51,1
303,11,255,1,51,3
303,11,256,1,51,2
303,11,259,4,0,
303,11,263,4,0,
303,11,269,4,0,
303,11,280,4,0,
303,11,305,2,0,
303,11,310,1,1,
303,11,313,1,6,
303,11,317,4,0,
303,11,321,2,0,
303,11,334,1,41,
303,11,368,2,0,
303,11,371,4,0,
303,11,373,4,0,
303,11,374,4,0,
303,11,385,2,0,
303,11,386,2,0,
303,11,389,1,46,
303,11,389,2,0,
303,11,411,4,0,
303,11,416,4,0,
303,11,422,2,0,
303,11,423,2,0,
303,11,424,2,0,
303,11,430,4,0,
303,11,442,1,56,
303,11,444,4,0,
303,11,445,2,0,
303,11,447,4,0,
303,11,451,4,0,
303,11,496,4,0,
303,11,510,4,0,
303,12,11,1,21,
303,12,44,1,11,
303,12,53,4,0,
303,12,58,4,0,
303,12,63,4,0,
303,12,70,4,0,
303,12,76,4,0,
303,12,92,4,0,
303,12,104,4,0,
303,12,126,4,0,
303,12,156,4,0,
303,12,182,4,0,
303,12,185,1,26,
303,12,188,4,0,
303,12,201,4,0,
303,12,213,4,0,
303,12,216,4,0,
303,12,218,4,0,
303,12,226,1,31,
303,12,230,1,16,
303,12,237,4,0,
303,12,240,4,0,
303,12,241,4,0,
303,12,242,1,36,
303,12,249,4,0,
303,12,254,1,46,1
303,12,255,1,46,3
303,12,256,1,46,2
303,12,259,4,0,
303,12,263,4,0,
303,12,264,4,0,
303,12,269,4,0,
303,12,280,4,0,
303,12,290,4,0,
303,12,310,1,1,
303,12,313,1,6,
303,12,317,4,0,
303,12,334,1,41,
303,13,11,1,21,
303,13,34,3,0,
303,13,38,3,0,
303,13,44,1,11,
303,13,53,4,0,
303,13,58,4,0,
303,13,63,4,0,
303,13,69,3,0,
303,13,70,4,0,
303,13,76,4,0,
303,13,92,4,0,
303,13,102,3,0,
303,13,104,4,0,
303,13,126,4,0,
303,13,156,4,0,
303,13,164,3,0,
303,13,182,4,0,
303,13,185,1,26,
303,13,188,4,0,
303,13,196,3,0,
303,13,201,4,0,
303,13,207,3,0,
303,13,213,4,0,
303,13,216,4,0,
303,13,218,4,0,
303,13,226,1,31,
303,13,230,1,16,
303,13,237,4,0,
303,13,240,4,0,
303,13,241,4,0,
303,13,242,1,36,
303,13,249,4,0,
303,13,254,1,46,1
303,13,255,1,46,3
303,13,256,1,46,2
303,13,259,4,0,
303,13,263,4,0,
303,13,264,4,0,
303,13,269,4,0,
303,13,280,4,0,
303,13,290,4,0,
303,13,310,1,1,
303,13,313,1,6,
303,13,317,4,0,
303,13,334,1,41,
303,14,8,3,0,
303,14,9,3,0,
303,14,11,1,21,
303,14,14,4,0,
303,14,21,2,0,
303,14,44,1,11,
303,14,53,4,0,
303,14,58,4,0,
303,14,63,4,0,
303,14,70,4,0,
303,14,76,4,0,
303,14,92,4,0,
303,14,104,4,0,
303,14,126,4,0,
303,14,156,4,0,
303,14,157,4,0,
303,14,162,3,0,
303,14,164,4,0,
303,14,173,3,0,
303,14,182,4,0,
303,14,185,1,26,
303,14,188,4,0,
303,14,196,3,0,
303,14,201,4,0,
303,14,206,4,0,
303,14,207,4,0,
303,14,213,4,0,
303,14,214,3,0,
303,14,216,4,0,
303,14,218,4,0,
303,14,220,3,0,
303,14,226,1,31,
303,14,230,1,16,
303,14,237,4,0,
303,14,240,4,0,
303,14,241,4,0,
303,14,242,1,36,
303,14,244,4,0,
303,14,246,2,0,
303,14,247,4,0,
303,14,249,4,0,
303,14,254,1,51,1
303,14,255,1,51,3
303,14,256,1,51,2
303,14,259,4,0,
303,14,263,4,0,
303,14,269,4,0,
303,14,280,4,0,
303,14,282,3,0,
303,14,289,3,0,
303,14,305,2,0,
303,14,310,1,1,
303,14,313,1,6,
303,14,317,4,0,
303,14,321,2,0,
303,14,334,1,41,
303,14,334,3,0,
303,14,368,2,0,
303,14,371,4,0,
303,14,373,4,0,
303,14,374,4,0,
303,14,385,2,0,
303,14,386,2,0,
303,14,387,3,0,
303,14,389,1,46,
303,14,389,2,0,
303,14,393,3,0,
303,14,399,3,0,
303,14,411,4,0,
303,14,416,4,0,
303,14,422,2,0,
303,14,423,2,0,
303,14,424,2,0,
303,14,430,4,0,
303,14,442,1,56,
303,14,442,3,0,
303,14,444,4,0,
303,14,445,2,0,
303,14,446,3,0,
303,14,447,4,0,
303,14,451,4,0,
303,14,492,3,0,
303,14,496,4,0,
303,14,510,4,0,
303,15,11,1,21,
303,15,14,4,0,
303,15,21,2,0,
303,15,44,1,11,
303,15,45,1,1,4
303,15,53,4,0,
303,15,58,4,0,
303,15,63,4,0,
303,15,69,2,0,
303,15,70,4,0,
303,15,76,4,0,
303,15,92,4,0,
303,15,104,4,0,
303,15,126,4,0,
303,15,156,4,0,
303,15,157,4,0,
303,15,164,4,0,
303,15,182,4,0,
303,15,185,1,26,
303,15,188,4,0,
303,15,201,4,0,
303,15,206,4,0,
303,15,207,4,0,
303,15,213,4,0,
303,15,214,4,0,
303,15,216,4,0,
303,15,218,4,0,
303,15,226,1,31,
303,15,230,1,16,
303,15,237,4,0,
303,15,240,4,0,
303,15,241,4,0,
303,15,242,1,36,
303,15,244,4,0,
303,15,246,2,0,
303,15,247,4,0,
303,15,249,4,0,
303,15,254,1,50,1
303,15,255,1,50,3
303,15,256,1,50,2
303,15,259,4,0,
303,15,263,4,0,
303,15,269,1,1,3
303,15,269,4,0,
303,15,280,4,0,
303,15,305,2,0,
303,15,310,1,1,6
303,15,313,1,6,
303,15,317,4,0,
303,15,321,2,0,
303,15,334,1,41,
303,15,368,2,0,
303,15,371,4,0,
303,15,373,4,0,
303,15,374,4,0,
303,15,385,2,0,
303,15,386,2,0,
303,15,389,1,46,
303,15,389,2,0,
303,15,399,4,0,
303,15,411,4,0,
303,15,416,4,0,
303,15,422,2,0,
303,15,423,2,0,
303,15,424,2,0,
303,15,430,4,0,
303,15,442,1,1,2
303,15,442,1,56,
303,15,444,4,0,
303,15,445,2,0,
303,15,447,4,0,
303,15,451,4,0,
303,15,496,4,0,
303,15,510,4,0,
303,15,581,2,0,
303,15,583,1,1,1
303,15,583,1,60,
303,15,584,1,1,5
303,15,590,4,0,
303,15,612,4,0,
303,16,8,3,0,
303,16,9,3,0,
303,16,11,1,17,1
303,16,14,4,0,
303,16,21,2,0,
303,16,44,1,9,1
303,16,45,1,1,4
303,16,53,4,0,
303,16,58,4,0,
303,16,63,4,0,
303,16,69,2,0,
303,16,70,4,0,
303,16,76,4,0,
303,16,92,4,0,
303,16,104,4,0,
303,16,126,4,0,
303,16,156,4,0,
303,16,157,4,0,
303,16,162,3,0,
303,16,164,4,0,
303,16,173,3,0,
303,16,182,4,0,
303,16,185,1,21,1
303,16,188,4,0,
303,16,196,3,0,
303,16,201,4,0,
303,16,206,4,0,
303,16,207,4,0,
303,16,213,4,0,
303,16,214,4,0,
303,16,216,4,0,
303,16,218,4,0,
303,16,220,3,0,
303,16,226,1,25,1
303,16,230,1,13,1
303,16,237,4,0,
303,16,240,4,0,
303,16,241,4,0,
303,16,242,1,29,1
303,16,244,4,0,
303,16,246,2,0,
303,16,247,4,0,
303,16,249,4,0,
303,16,254,1,41,1
303,16,255,1,41,3
303,16,256,1,41,2
303,16,259,4,0,
303,16,263,4,0,
303,16,264,3,0,
303,16,269,1,1,3
303,16,269,4,0,
303,16,280,4,0,
303,16,282,3,0,
303,16,289,3,0,
303,16,290,4,0,
303,16,305,2,0,
303,16,310,1,1,6
303,16,313,1,5,1
303,16,317,4,0,
303,16,321,2,0,
303,16,334,1,33,1
303,16,334,3,0,
303,16,368,2,0,
303,16,371,4,0,
303,16,373,4,0,
303,16,374,4,0,
303,16,385,2,0,
303,16,386,2,0,
303,16,387,3,0,
303,16,389,1,37,1
303,16,389,2,0,
303,16,393,3,0,
303,16,399,4,0,
303,16,411,4,0,
303,16,416,4,0,
303,16,422,2,0,
303,16,423,2,0,
303,16,424,2,0,
303,16,430,4,0,
303,16,442,1,1,2
303,16,442,1,45,1
303,16,442,3,0,
303,16,444,4,0,
303,16,445,2,0,
303,16,446,3,0,
303,16,447,4,0,
303,16,451,4,0,
303,16,492,3,0,
303,16,496,4,0,
303,16,510,4,0,
303,16,581,2,0,
303,16,583,1,1,1
303,16,583,1,49,1
303,16,584,1,1,5
303,16,590,4,0,
303,16,612,4,0,
303,17,11,1,17,
303,17,14,4,0,
303,17,21,2,0,
303,17,44,1,9,
303,17,45,1,1,4
303,17,53,4,0,
303,17,58,4,0,
303,17,63,4,0,
303,17,69,2,0,
303,17,76,4,0,
303,17,92,4,0,
303,17,104,4,0,
303,17,126,4,0,
303,17,156,4,0,
303,17,157,4,0,
303,17,164,4,0,
303,17,182,4,0,
303,17,185,1,21,
303,17,188,4,0,
303,17,201,4,0,
303,17,206,4,0,
303,17,207,4,0,
303,17,213,4,0,
303,17,214,4,0,
303,17,216,4,0,
303,17,218,4,0,
303,17,226,1,25,
303,17,230,1,13,
303,17,237,4,0,
303,17,240,4,0,
303,17,241,4,0,
303,17,242,1,29,
303,17,244,4,0,
303,17,246,2,0,
303,17,247,4,0,
303,17,254,1,41,1
303,17,255,1,41,3
303,17,256,1,41,2
303,17,259,4,0,
303,17,263,4,0,
303,17,269,1,1,3
303,17,269,4,0,
303,17,280,4,0,
303,17,305,2,0,
303,17,310,1,1,6
303,17,313,1,5,
303,17,317,4,0,
303,17,321,2,0,
303,17,334,1,33,
303,17,368,2,0,
303,17,371,4,0,
303,17,373,4,0,
303,17,374,4,0,
303,17,385,2,0,
303,17,386,2,0,
303,17,389,1,37,
303,17,389,2,0,
303,17,399,4,0,
303,17,411,4,0,
303,17,416,4,0,
303,17,422,2,0,
303,17,423,2,0,
303,17,424,2,0,
303,17,430,4,0,
303,17,442,1,1,2
303,17,442,1,45,
303,17,444,4,0,
303,17,445,2,0,
303,17,447,4,0,
303,17,451,4,0,
303,17,496,4,0,
303,17,581,2,0,
303,17,583,1,1,1
303,17,583,1,49,
303,17,584,1,1,5
303,17,590,4,0,
303,18,11,1,17,
303,18,14,4,0,
303,18,21,2,0,
303,18,44,1,9,
303,18,45,1,1,4
303,18,53,4,0,
303,18,58,4,0,
303,18,63,4,0,
303,18,69,2,0,
303,18,76,4,0,
303,18,92,4,0,
303,18,104,4,0,
303,18,126,4,0,
303,18,156,4,0,
303,18,157,4,0,
303,18,164,4,0,
303,18,182,4,0,
303,18,185,1,21,
303,18,188,4,0,
303,18,201,4,0,
303,18,206,4,0,
303,18,207,4,0,
303,18,213,4,0,
303,18,214,4,0,
303,18,216,4,0,
303,18,218,4,0,
303,18,226,1,25,
303,18,230,1,13,
303,18,237,4,0,
303,18,240,4,0,
303,18,241,4,0,
303,18,242,1,29,
303,18,244,4,0,
303,18,246,2,0,
303,18,247,4,0,
303,18,254,1,41,1
303,18,255,1,41,3
303,18,256,1,41,2
303,18,259,4,0,
303,18,263,4,0,
303,18,269,1,1,3
303,18,269,4,0,
303,18,280,4,0,
303,18,305,2,0,
303,18,310,1,1,6
303,18,313,1,5,
303,18,317,4,0,
303,18,321,2,0,
303,18,334,1,33,
303,18,368,2,0,
303,18,371,4,0,
303,18,373,4,0,
303,18,374,4,0,
303,18,385,2,0,
303,18,386,2,0,
303,18,389,1,37,
303,18,389,2,0,
303,18,399,4,0,
303,18,411,4,0,
303,18,416,4,0,
303,18,422,2,0,
303,18,423,2,0,
303,18,424,2,0,
303,18,430,4,0,
303,18,442,1,1,2
303,18,442,1,45,
303,18,444,4,0,
303,18,445,2,0,
303,18,447,4,0,
303,18,451,4,0,
303,18,496,4,0,
303,18,581,2,0,
303,18,583,1,1,1
303,18,583,1,49,
303,18,584,1,1,5
303,18,590,4,0,
303,18,612,2,0,
304,5,15,4,0,
304,5,23,2,0,
304,5,29,1,10,
304,5,33,1,1,
304,5,34,2,0,
304,5,36,1,25,
304,5,38,1,44,
304,5,46,1,21,
304,5,46,4,0,
304,5,70,4,0,
304,5,89,4,0,
304,5,91,4,0,
304,5,92,4,0,
304,5,104,4,0,
304,5,106,1,4,
304,5,156,4,0,
304,5,182,1,34,
304,5,182,4,0,
304,5,189,1,7,
304,5,201,4,0,
304,5,213,4,0,
304,5,216,4,0,
304,5,218,4,0,
304,5,231,1,29,
304,5,231,4,0,
304,5,232,1,13,
304,5,237,4,0,
304,5,240,4,0,
304,5,241,4,0,
304,5,249,4,0,
304,5,263,4,0,
304,5,265,2,0,
304,5,283,2,0,
304,5,290,4,0,
304,5,317,4,0,
304,5,319,1,39,
304,5,332,4,0,
304,5,334,1,17,
304,5,351,4,0,
304,5,352,4,0,
304,6,15,4,0,
304,6,23,2,0,
304,6,29,1,10,
304,6,33,1,1,
304,6,34,2,0,
304,6,34,3,0,
304,6,36,1,25,
304,6,38,1,44,
304,6,38,3,0,
304,6,46,1,21,
304,6,46,4,0,
304,6,70,4,0,
304,6,89,4,0,
304,6,91,4,0,
304,6,92,4,0,
304,6,102,3,0,
304,6,104,4,0,
304,6,106,1,4,
304,6,111,3,0,
304,6,156,4,0,
304,6,157,3,0,
304,6,164,3,0,
304,6,173,3,0,
304,6,182,1,34,
304,6,182,4,0,
304,6,189,1,7,
304,6,189,3,0,
304,6,201,4,0,
304,6,203,3,0,
304,6,205,3,0,
304,6,207,3,0,
304,6,210,3,0,
304,6,213,4,0,
304,6,214,3,0,
304,6,216,4,0,
304,6,218,4,0,
304,6,231,1,29,
304,6,231,4,0,
304,6,232,1,13,
304,6,237,4,0,
304,6,240,4,0,
304,6,241,4,0,
304,6,249,4,0,
304,6,263,4,0,
304,6,265,2,0,
304,6,283,2,0,
304,6,290,4,0,
304,6,317,4,0,
304,6,319,1,39,
304,6,332,4,0,
304,6,334,1,17,
304,6,351,4,0,
304,6,352,4,0,
304,7,15,4,0,
304,7,23,2,0,
304,7,29,1,10,
304,7,33,1,1,
304,7,34,2,0,
304,7,34,3,0,
304,7,36,1,25,
304,7,38,1,44,
304,7,38,3,0,
304,7,46,1,21,
304,7,46,4,0,
304,7,70,4,0,
304,7,89,4,0,
304,7,91,4,0,
304,7,92,4,0,
304,7,102,3,0,
304,7,104,4,0,
304,7,106,1,4,
304,7,156,4,0,
304,7,157,3,0,
304,7,164,3,0,
304,7,182,1,34,
304,7,182,4,0,
304,7,189,1,7,
304,7,201,4,0,
304,7,213,4,0,
304,7,216,4,0,
304,7,218,4,0,
304,7,231,1,29,
304,7,231,4,0,
304,7,232,1,13,
304,7,237,4,0,
304,7,240,4,0,
304,7,241,4,0,
304,7,249,4,0,
304,7,263,4,0,
304,7,265,2,0,
304,7,283,2,0,
304,7,290,4,0,
304,7,317,4,0,
304,7,319,1,39,
304,7,332,4,0,
304,7,334,1,17,
304,7,351,4,0,
304,7,352,4,0,
304,8,15,4,0,
304,8,23,2,0,
304,8,29,1,11,
304,8,33,1,1,
304,8,34,2,0,
304,8,36,1,25,
304,8,38,1,43,
304,8,46,1,22,
304,8,46,4,0,
304,8,70,4,0,
304,8,89,4,0,
304,8,91,4,0,
304,8,92,4,0,
304,8,103,2,0,
304,8,104,4,0,
304,8,106,1,4,
304,8,156,4,0,
304,8,157,4,0,
304,8,164,4,0,
304,8,174,2,0,
304,8,182,1,32,
304,8,182,4,0,
304,8,189,1,8,
304,8,201,4,0,
304,8,203,4,0,
304,8,207,4,0,
304,8,213,4,0,
304,8,214,4,0,
304,8,216,4,0,
304,8,218,4,0,
304,8,231,1,39,
304,8,231,4,0,
304,8,232,1,15,
304,8,237,4,0,
304,8,240,4,0,
304,8,241,4,0,
304,8,249,4,0,
304,8,263,4,0,
304,8,265,2,0,
304,8,283,2,0,
304,8,290,4,0,
304,8,317,4,0,
304,8,319,1,36,
304,8,332,4,0,
304,8,334,1,18,
304,8,351,4,0,
304,8,352,4,0,
304,8,363,4,0,
304,8,368,1,46,
304,8,397,4,0,
304,8,407,2,0,
304,8,421,4,0,
304,8,442,1,29,
304,8,442,2,0,
304,8,445,4,0,
304,8,446,4,0,
304,9,15,4,0,
304,9,23,2,0,
304,9,29,1,11,
304,9,33,1,1,
304,9,34,2,0,
304,9,36,1,25,
304,9,38,1,43,
304,9,46,1,22,
304,9,46,4,0,
304,9,70,4,0,
304,9,89,4,0,
304,9,91,4,0,
304,9,92,4,0,
304,9,103,2,0,
304,9,104,4,0,
304,9,106,1,4,
304,9,156,4,0,
304,9,157,4,0,
304,9,164,4,0,
304,9,173,3,0,
304,9,174,2,0,
304,9,180,3,0,
304,9,182,1,32,
304,9,182,4,0,
304,9,189,1,8,
304,9,189,3,0,
304,9,201,4,0,
304,9,203,4,0,
304,9,205,3,0,
304,9,207,4,0,
304,9,210,3,0,
304,9,213,4,0,
304,9,214,4,0,
304,9,216,4,0,
304,9,218,4,0,
304,9,231,1,39,
304,9,231,4,0,
304,9,232,1,15,
304,9,237,4,0,
304,9,240,4,0,
304,9,241,4,0,
304,9,246,3,0,
304,9,249,4,0,
304,9,253,3,0,
304,9,263,4,0,
304,9,265,2,0,
304,9,276,3,0,
304,9,283,2,0,
304,9,283,3,0,
304,9,290,4,0,
304,9,317,4,0,
304,9,319,1,36,
304,9,332,4,0,
304,9,334,1,18,
304,9,334,3,0,
304,9,351,4,0,
304,9,352,4,0,
304,9,363,4,0,
304,9,368,1,46,
304,9,393,3,0,
304,9,397,4,0,
304,9,407,2,0,
304,9,414,3,0,
304,9,421,4,0,
304,9,442,1,29,
304,9,442,2,0,
304,9,442,3,0,
304,9,445,4,0,
304,9,446,4,0,
304,10,15,4,0,
304,10,23,2,0,
304,10,29,1,11,
304,10,29,3,0,
304,10,33,1,1,
304,10,34,2,0,
304,10,36,1,25,
304,10,38,1,43,
304,10,46,1,22,
304,10,46,4,0,
304,10,70,4,0,
304,10,89,4,0,
304,10,91,4,0,
304,10,92,4,0,
304,10,103,2,0,
304,10,104,4,0,
304,10,106,1,4,
304,10,156,4,0,
304,10,157,4,0,
304,10,164,4,0,
304,10,173,3,0,
304,10,174,2,0,
304,10,180,3,0,
304,10,182,1,32,
304,10,182,4,0,
304,10,189,1,8,
304,10,189,3,0,
304,10,201,4,0,
304,10,203,4,0,
304,10,205,3,0,
304,10,207,4,0,
304,10,210,3,0,
304,10,213,4,0,
304,10,214,4,0,
304,10,216,4,0,
304,10,218,4,0,
304,10,231,1,39,
304,10,231,4,0,
304,10,232,1,15,
304,10,237,4,0,
304,10,240,4,0,
304,10,241,4,0,
304,10,246,3,0,
304,10,249,4,0,
304,10,253,3,0,
304,10,263,4,0,
304,10,265,2,0,
304,10,276,3,0,
304,10,283,2,0,
304,10,283,3,0,
304,10,290,4,0,
304,10,317,4,0,
304,10,319,1,36,
304,10,332,4,0,
304,10,334,1,18,
304,10,334,3,0,
304,10,351,4,0,
304,10,352,4,0,
304,10,363,4,0,
304,10,368,1,46,
304,10,393,3,0,
304,10,397,4,0,
304,10,407,2,0,
304,10,414,3,0,
304,10,421,4,0,
304,10,442,1,29,
304,10,442,2,0,
304,10,442,3,0,
304,10,445,4,0,
304,10,446,4,0,
304,10,457,2,0,
304,11,15,4,0,
304,11,23,2,0,
304,11,29,1,11,
304,11,33,1,1,
304,11,34,2,0,
304,11,36,1,25,
304,11,38,1,50,
304,11,46,1,22,
304,11,46,4,0,
304,11,70,4,0,
304,11,89,4,0,
304,11,91,4,0,
304,11,92,4,0,
304,11,103,2,0,
304,11,104,4,0,
304,11,106,1,4,
304,11,156,4,0,
304,11,157,4,0,
304,11,164,4,0,
304,11,174,2,0,
304,11,182,1,32,
304,11,182,4,0,
304,11,189,1,8,
304,11,201,4,0,
304,11,207,4,0,
304,11,213,4,0,
304,11,216,4,0,
304,11,218,4,0,
304,11,231,1,39,
304,11,232,1,15,
304,11,237,4,0,
304,11,240,4,0,
304,11,241,4,0,
304,11,249,4,0,
304,11,263,4,0,
304,11,265,2,0,
304,11,276,2,0,
304,11,283,2,0,
304,11,317,4,0,
304,11,319,1,36,
304,11,332,4,0,
304,11,334,1,18,
304,11,368,1,53,
304,11,397,4,0,
304,11,407,2,0,
304,11,421,4,0,
304,11,442,1,29,
304,11,442,2,0,
304,11,446,2,0,
304,11,457,2,0,
304,11,468,4,0,
304,11,475,1,43,
304,11,484,1,46,
304,11,496,4,0,
304,11,523,4,0,
304,12,15,4,0,
304,12,29,1,10,
304,12,33,1,1,
304,12,36,1,25,
304,12,38,1,44,
304,12,46,1,21,
304,12,46,4,0,
304,12,70,4,0,
304,12,89,4,0,
304,12,91,4,0,
304,12,92,4,0,
304,12,104,4,0,
304,12,106,1,4,
304,12,156,4,0,
304,12,182,1,34,
304,12,182,4,0,
304,12,189,1,7,
304,12,201,4,0,
304,12,213,4,0,
304,12,216,4,0,
304,12,218,4,0,
304,12,231,1,29,
304,12,231,4,0,
304,12,232,1,13,
304,12,237,4,0,
304,12,240,4,0,
304,12,241,4,0,
304,12,249,4,0,
304,12,263,4,0,
304,12,290,4,0,
304,12,317,4,0,
304,12,319,1,39,
304,12,332,4,0,
304,12,334,1,17,
304,12,351,4,0,
304,12,352,4,0,
304,13,15,4,0,
304,13,29,1,10,
304,13,33,1,1,
304,13,34,3,0,
304,13,36,1,25,
304,13,38,1,44,
304,13,38,3,0,
304,13,46,1,21,
304,13,46,4,0,
304,13,70,4,0,
304,13,89,4,0,
304,13,91,4,0,
304,13,92,4,0,
304,13,102,3,0,
304,13,104,4,0,
304,13,106,1,4,
304,13,156,4,0,
304,13,164,3,0,
304,13,182,1,34,
304,13,182,4,0,
304,13,189,1,7,
304,13,201,4,0,
304,13,207,3,0,
304,13,213,4,0,
304,13,216,4,0,
304,13,218,4,0,
304,13,231,1,29,
304,13,231,4,0,
304,13,232,1,13,
304,13,237,4,0,
304,13,240,4,0,
304,13,241,4,0,
304,13,249,4,0,
304,13,263,4,0,
304,13,290,4,0,
304,13,317,4,0,
304,13,319,1,39,
304,13,332,4,0,
304,13,334,1,17,
304,13,351,4,0,
304,13,352,4,0,
304,14,15,4,0,
304,14,23,2,0,
304,14,29,1,8,
304,14,33,1,1,1
304,14,34,2,0,
304,14,36,1,22,
304,14,38,1,46,
304,14,46,1,18,
304,14,46,4,0,
304,14,70,4,0,
304,14,89,4,0,
304,14,91,4,0,
304,14,92,4,0,
304,14,103,2,0,
304,14,104,4,0,
304,14,106,1,1,2
304,14,156,4,0,
304,14,157,4,0,
304,14,164,4,0,
304,14,173,3,0,
304,14,174,2,0,
304,14,180,3,0,
304,14,182,1,29,
304,14,182,4,0,
304,14,189,1,4,
304,14,201,4,0,
304,14,207,4,0,
304,14,213,4,0,
304,14,214,3,0,
304,14,216,4,0,
304,14,218,4,0,
304,14,231,1,36,
304,14,231,3,0,
304,14,232,1,11,
304,14,237,4,0,
304,14,240,4,0,
304,14,241,4,0,
304,14,249,4,0,
304,14,253,3,0,
304,14,263,4,0,
304,14,265,2,0,
304,14,276,2,0,
304,14,276,3,0,
304,14,283,2,0,
304,14,283,3,0,
304,14,317,4,0,
304,14,319,1,32,
304,14,332,4,0,
304,14,334,1,15,
304,14,334,3,0,
304,14,368,1,50,
304,14,393,3,0,
304,14,397,4,0,
304,14,407,2,0,
304,14,414,3,0,
304,14,421,4,0,
304,14,442,1,25,
304,14,442,2,0,
304,14,442,3,0,
304,14,446,2,0,
304,14,446,3,0,
304,14,457,2,0,
304,14,468,4,0,
304,14,475,1,39,
304,14,484,1,43,
304,14,496,4,0,
304,14,523,4,0,
304,15,15,4,0,
304,15,23,2,0,
304,15,29,1,8,
304,15,33,1,1,1
304,15,34,2,0,
304,15,36,1,22,
304,15,38,1,46,
304,15,46,1,18,
304,15,46,4,0,
304,15,70,4,0,
304,15,89,4,0,
304,15,91,4,0,
304,15,92,4,0,
304,15,103,2,0,
304,15,104,4,0,
304,15,106,1,1,2
304,15,156,4,0,
304,15,157,4,0,
304,15,164,4,0,
304,15,174,2,0,
304,15,179,2,0,
304,15,182,1,29,
304,15,182,4,0,
304,15,189,1,4,
304,15,201,4,0,
304,15,207,4,0,
304,15,213,4,0,
304,15,214,4,0,
304,15,216,4,0,
304,15,218,4,0,
304,15,231,1,36,
304,15,232,1,11,
304,15,237,4,0,
304,15,240,4,0,
304,15,241,4,0,
304,15,249,4,0,
304,15,263,4,0,
304,15,265,2,0,
304,15,276,2,0,
304,15,283,2,0,
304,15,317,4,0,
304,15,319,1,32,
304,15,332,4,0,
304,15,334,1,15,
304,15,368,1,50,
304,15,397,4,0,
304,15,407,2,0,
304,15,421,4,0,
304,15,442,1,25,
304,15,442,2,0,
304,15,446,2,0,
304,15,457,2,0,
304,15,468,4,0,
304,15,475,1,39,
304,15,484,1,43,
304,15,496,4,0,
304,15,523,4,0,
304,15,590,4,0,
304,16,15,4,0,
304,16,23,2,0,
304,16,29,1,7,1
304,16,33,1,1,1
304,16,34,2,0,
304,16,36,1,28,1
304,16,38,1,40,1
304,16,46,1,19,1
304,16,46,4,0,
304,16,70,4,0,
304,16,89,4,0,
304,16,91,4,0,
304,16,92,4,0,
304,16,103,2,0,
304,16,104,4,0,
304,16,106,1,1,2
304,16,156,4,0,
304,16,157,1,25,1
304,16,157,4,0,
304,16,164,4,0,
304,16,173,3,0,
304,16,174,2,0,
304,16,179,2,0,
304,16,180,3,0,
304,16,182,1,16,1
304,16,182,4,0,
304,16,189,1,4,1
304,16,201,4,0,
304,16,207,4,0,
304,16,213,4,0,
304,16,214,4,0,
304,16,216,4,0,
304,16,218,4,0,
304,16,231,1,34,1
304,16,231,3,0,
304,16,232,1,10,1
304,16,237,4,0,
304,16,240,4,0,
304,16,241,4,0,
304,16,249,4,0,
304,16,253,3,0,
304,16,263,4,0,
304,16,265,2,0,
304,16,276,2,0,
304,16,276,3,0,
304,16,283,2,0,
304,16,283,3,0,
304,16,290,4,0,
304,16,317,1,13,1
304,16,317,4,0,
304,16,319,1,31,1
304,16,332,4,0,
304,16,334,1,37,1
304,16,334,3,0,
304,16,351,3,0,
304,16,352,3,0,
304,16,368,1,49,1
304,16,393,3,0,
304,16,397,4,0,
304,16,407,2,0,
304,16,414,3,0,
304,16,421,4,0,
304,16,442,1,22,1
304,16,442,2,0,
304,16,442,3,0,
304,16,446,2,0,
304,16,446,3,0,
304,16,457,2,0,
304,16,468,4,0,
304,16,475,1,43,1
304,16,484,1,46,1
304,16,496,4,0,
304,16,523,4,0,
304,16,590,4,0,
304,17,23,2,0,
304,17,29,1,7,
304,17,33,1,1,1
304,17,34,2,0,
304,17,36,1,28,
304,17,38,1,40,
304,17,46,1,19,
304,17,46,4,0,
304,17,89,4,0,
304,17,92,4,0,
304,17,103,2,0,
304,17,104,4,0,
304,17,106,1,1,2
304,17,156,4,0,
304,17,157,1,25,
304,17,157,4,0,
304,17,164,4,0,
304,17,174,2,0,
304,17,179,2,0,
304,17,182,1,16,
304,17,182,4,0,
304,17,189,1,4,
304,17,201,4,0,
304,17,207,4,0,
304,17,213,4,0,
304,17,214,4,0,
304,17,216,4,0,
304,17,218,4,0,
304,17,231,1,34,
304,17,232,1,10,
304,17,237,4,0,
304,17,240,4,0,
304,17,241,4,0,
304,17,263,4,0,
304,17,265,2,0,
304,17,276,2,0,
304,17,283,2,0,
304,17,317,1,13,
304,17,317,4,0,
304,17,319,1,31,
304,17,332,4,0,
304,17,334,1,37,
304,17,368,1,49,
304,17,397,4,0,
304,17,407,2,0,
304,17,421,4,0,
304,17,442,1,22,
304,17,442,2,0,
304,17,446,2,0,
304,17,457,2,0,
304,17,475,1,43,
304,17,484,1,46,
304,17,496,4,0,
304,17,523,4,0,
304,17,590,4,0,
304,18,23,2,0,
304,18,29,1,7,
304,18,33,1,1,1
304,18,34,2,0,
304,18,36,1,28,
304,18,38,1,40,
304,18,46,1,19,
304,18,46,4,0,
304,18,89,4,0,
304,18,92,4,0,
304,18,103,2,0,
304,18,104,4,0,
304,18,106,1,1,2
304,18,156,4,0,
304,18,157,1,25,
304,18,157,4,0,
304,18,164,4,0,
304,18,174,2,0,
304,18,179,2,0,
304,18,182,1,16,
304,18,182,4,0,
304,18,189,1,4,
304,18,201,4,0,
304,18,207,4,0,
304,18,213,4,0,
304,18,214,4,0,
304,18,216,4,0,
304,18,218,4,0,
304,18,231,1,34,
304,18,232,1,10,
304,18,237,4,0,
304,18,240,4,0,
304,18,241,4,0,
304,18,263,4,0,
304,18,265,2,0,
304,18,276,2,0,
304,18,283,2,0,
304,18,317,1,13,
304,18,317,4,0,
304,18,319,1,31,
304,18,332,4,0,
304,18,334,1,37,
304,18,368,1,49,
304,18,397,4,0,
304,18,407,2,0,
304,18,421,4,0,
304,18,442,1,22,
304,18,442,2,0,
304,18,446,2,0,
304,18,457,2,0,
304,18,475,1,43,
304,18,484,1,46,
304,18,496,4,0,
304,18,523,4,0,
304,18,590,4,0,
305,5,15,4,0,
305,5,29,1,1,4
305,5,29,1,10,
305,5,33,1,1,1
305,5,36,1,25,
305,5,38,1,53,
305,5,46,1,21,
305,5,46,4,0,
305,5,70,4,0,
305,5,89,4,0,
305,5,91,4,0,
305,5,92,4,0,
305,5,104,4,0,
305,5,106,1,1,2
305,5,106,1,4,
305,5,156,4,0,
305,5,182,1,37,
305,5,182,4,0,
305,5,189,1,1,3
305,5,189,1,7,
305,5,201,4,0,
305,5,213,4,0,
305,5,216,4,0,
305,5,218,4,0,
305,5,231,1,29,
305,5,231,4,0,
305,5,232,1,13,
305,5,237,4,0,
305,5,240,4,0,
305,5,241,4,0,
305,5,249,4,0,
305,5,263,4,0,
305,5,290,4,0,
305,5,317,4,0,
305,5,319,1,45,
305,5,332,4,0,
305,5,334,1,17,
305,5,351,4,0,
305,5,352,4,0,
305,6,15,4,0,
305,6,29,1,1,4
305,6,29,1,10,
305,6,33,1,1,1
305,6,34,3,0,
305,6,36,1,25,
305,6,38,1,53,
305,6,38,3,0,
305,6,46,1,21,
305,6,46,4,0,
305,6,70,4,0,
305,6,89,4,0,
305,6,91,4,0,
305,6,92,4,0,
305,6,102,3,0,
305,6,104,4,0,
305,6,106,1,1,2
305,6,106,1,4,
305,6,111,3,0,
305,6,156,4,0,
305,6,157,3,0,
305,6,164,3,0,
305,6,173,3,0,
305,6,182,1,37,
305,6,182,4,0,
305,6,189,1,1,3
305,6,189,1,7,
305,6,189,3,0,
305,6,201,4,0,
305,6,203,3,0,
305,6,205,3,0,
305,6,207,3,0,
305,6,210,3,0,
305,6,213,4,0,
305,6,214,3,0,
305,6,216,4,0,
305,6,218,4,0,
305,6,231,1,29,
305,6,231,4,0,
305,6,232,1,13,
305,6,237,4,0,
305,6,240,4,0,
305,6,241,4,0,
305,6,249,4,0,
305,6,263,4,0,
305,6,290,4,0,
305,6,317,4,0,
305,6,319,1,45,
305,6,332,4,0,
305,6,334,1,17,
305,6,351,4,0,
305,6,352,4,0,
305,7,15,4,0,
305,7,29,1,1,4
305,7,29,1,10,
305,7,33,1,1,1
305,7,34,3,0,
305,7,36,1,25,
305,7,38,1,53,
305,7,38,3,0,
305,7,46,1,21,
305,7,46,4,0,
305,7,70,4,0,
305,7,89,4,0,
305,7,91,4,0,
305,7,92,4,0,
305,7,102,3,0,
305,7,104,4,0,
305,7,106,1,1,2
305,7,106,1,4,
305,7,156,4,0,
305,7,157,3,0,
305,7,164,3,0,
305,7,182,1,37,
305,7,182,4,0,
305,7,189,1,1,3
305,7,189,1,7,
305,7,201,4,0,
305,7,213,4,0,
305,7,216,4,0,
305,7,218,4,0,
305,7,231,1,29,
305,7,231,4,0,
305,7,232,1,13,
305,7,237,4,0,
305,7,240,4,0,
305,7,241,4,0,
305,7,249,4,0,
305,7,263,4,0,
305,7,290,4,0,
305,7,317,4,0,
305,7,319,1,45,
305,7,332,4,0,
305,7,334,1,17,
305,7,351,4,0,
305,7,352,4,0,
305,8,15,4,0,
305,8,29,1,1,4
305,8,29,1,11,
305,8,33,1,1,1
305,8,36,1,25,
305,8,38,1,51,
305,8,46,1,22,
305,8,46,4,0,
305,8,70,4,0,
305,8,89,4,0,
305,8,91,4,0,
305,8,92,4,0,
305,8,104,4,0,
305,8,106,1,1,2
305,8,106,1,4,
305,8,156,4,0,
305,8,157,4,0,
305,8,164,4,0,
305,8,182,1,34,
305,8,182,4,0,
305,8,189,1,1,3
305,8,189,1,8,
305,8,201,4,0,
305,8,203,4,0,
305,8,207,4,0,
305,8,213,4,0,
305,8,214,4,0,
305,8,216,4,0,
305,8,218,4,0,
305,8,231,1,45,
305,8,231,4,0,
305,8,232,1,15,
305,8,237,4,0,
305,8,240,4,0,
305,8,241,4,0,
305,8,249,4,0,
305,8,263,4,0,
305,8,290,4,0,
305,8,317,4,0,
305,8,319,1,40,
305,8,332,4,0,
305,8,334,1,18,
305,8,351,4,0,
305,8,352,4,0,
305,8,363,4,0,
305,8,368,1,56,
305,8,397,4,0,
305,8,421,4,0,
305,8,442,1,29,
305,8,444,4,0,
305,8,445,4,0,
305,8,446,4,0,
305,9,15,4,0,
305,9,29,1,1,4
305,9,29,1,11,
305,9,33,1,1,1
305,9,36,1,25,
305,9,38,1,51,
305,9,46,1,22,
305,9,46,4,0,
305,9,70,4,0,
305,9,89,4,0,
305,9,91,4,0,
305,9,92,4,0,
305,9,104,4,0,
305,9,106,1,1,2
305,9,106,1,4,
305,9,156,4,0,
305,9,157,4,0,
305,9,164,4,0,
305,9,173,3,0,
305,9,180,3,0,
305,9,182,1,34,
305,9,182,4,0,
305,9,189,1,1,3
305,9,189,1,8,
305,9,189,3,0,
305,9,201,4,0,
305,9,203,4,0,
305,9,205,3,0,
305,9,207,4,0,
305,9,210,3,0,
305,9,213,4,0,
305,9,214,4,0,
305,9,216,4,0,
305,9,218,4,0,
305,9,231,1,45,
305,9,231,4,0,
305,9,232,1,15,
305,9,237,4,0,
305,9,240,4,0,
305,9,241,4,0,
305,9,246,3,0,
305,9,249,4,0,
305,9,253,3,0,
305,9,263,4,0,
305,9,276,3,0,
305,9,283,3,0,
305,9,290,4,0,
305,9,317,4,0,
305,9,319,1,40,
305,9,332,4,0,
305,9,334,1,18,
305,9,334,3,0,
305,9,351,4,0,
305,9,352,4,0,
305,9,363,4,0,
305,9,368,1,56,
305,9,393,3,0,
305,9,397,4,0,
305,9,414,3,0,
305,9,421,4,0,
305,9,442,1,29,
305,9,442,3,0,
305,9,444,4,0,
305,9,445,4,0,
305,9,446,4,0,
305,10,15,4,0,
305,10,29,1,1,4
305,10,29,1,11,
305,10,29,3,0,
305,10,33,1,1,1
305,10,36,1,25,
305,10,38,1,51,
305,10,46,1,22,
305,10,46,4,0,
305,10,70,4,0,
305,10,89,4,0,
305,10,91,4,0,
305,10,92,4,0,
305,10,104,4,0,
305,10,106,1,1,2
305,10,106,1,4,
305,10,156,4,0,
305,10,157,4,0,
305,10,164,4,0,
305,10,173,3,0,
305,10,180,3,0,
305,10,182,1,34,
305,10,182,4,0,
305,10,189,1,1,3
305,10,189,1,8,
305,10,189,3,0,
305,10,201,4,0,
305,10,203,4,0,
305,10,205,3,0,
305,10,207,4,0,
305,10,210,3,0,
305,10,213,4,0,
305,10,214,4,0,
305,10,216,4,0,
305,10,218,4,0,
305,10,231,1,45,
305,10,231,4,0,
305,10,232,1,15,
305,10,237,4,0,
305,10,240,4,0,
305,10,241,4,0,
305,10,246,3,0,
305,10,249,4,0,
305,10,253,3,0,
305,10,263,4,0,
305,10,276,3,0,
305,10,283,3,0,
305,10,290,4,0,
305,10,317,4,0,
305,10,319,1,40,
305,10,332,4,0,
305,10,334,1,18,
305,10,334,3,0,
305,10,351,4,0,
305,10,352,4,0,
305,10,363,4,0,
305,10,368,1,56,
305,10,393,3,0,
305,10,397,4,0,
305,10,414,3,0,
305,10,421,4,0,
305,10,442,1,29,
305,10,442,3,0,
305,10,444,4,0,
305,10,445,4,0,
305,10,446,4,0,
305,11,15,4,0,
305,11,29,1,1,4
305,11,29,1,11,
305,11,33,1,1,1
305,11,36,1,25,
305,11,38,1,62,
305,11,46,1,22,
305,11,46,4,0,
305,11,70,4,0,
305,11,89,4,0,
305,11,91,4,0,
305,11,92,4,0,
305,11,104,4,0,
305,11,106,1,1,2
305,11,106,1,4,
305,11,156,4,0,
305,11,157,4,0,
305,11,164,4,0,
305,11,182,1,34,
305,11,182,4,0,
305,11,189,1,1,3
305,11,189,1,8,
305,11,201,4,0,
305,11,207,4,0,
305,11,213,4,0,
305,11,216,4,0,
305,11,218,4,0,
305,11,231,1,45,
305,11,232,1,15,
305,11,237,4,0,
305,11,240,4,0,
305,11,241,4,0,
305,11,249,4,0,
305,11,263,4,0,
305,11,317,4,0,
305,11,319,1,40,
305,11,332,4,0,
305,11,334,1,18,
305,11,368,1,67,
305,11,397,4,0,
305,11,421,4,0,
305,11,442,1,29,
305,11,444,4,0,
305,11,468,4,0,
305,11,475,1,51,
305,11,484,1,56,
305,11,496,4,0,
305,11,523,4,0,
305,12,15,4,0,
305,12,29,1,1,4
305,12,29,1,10,
305,12,33,1,1,1
305,12,36,1,25,
305,12,38,1,53,
305,12,46,1,21,
305,12,46,4,0,
305,12,70,4,0,
305,12,89,4,0,
305,12,91,4,0,
305,12,92,4,0,
305,12,104,4,0,
305,12,106,1,1,2
305,12,106,1,4,
305,12,156,4,0,
305,12,182,1,37,
305,12,182,4,0,
305,12,189,1,1,3
305,12,189,1,7,
305,12,201,4,0,
305,12,213,4,0,
305,12,216,4,0,
305,12,218,4,0,
305,12,231,1,29,
305,12,231,4,0,
305,12,232,1,13,
305,12,237,4,0,
305,12,240,4,0,
305,12,241,4,0,
305,12,249,4,0,
305,12,263,4,0,
305,12,290,4,0,
305,12,317,4,0,
305,12,319,1,45,
305,12,332,4,0,
305,12,334,1,17,
305,12,351,4,0,
305,12,352,4,0,
305,13,15,4,0,
305,13,29,1,1,4
305,13,29,1,10,
305,13,33,1,1,1
305,13,34,3,0,
305,13,36,1,25,
305,13,38,1,53,
305,13,38,3,0,
305,13,46,1,21,
305,13,46,4,0,
305,13,70,4,0,
305,13,89,4,0,
305,13,91,4,0,
305,13,92,4,0,
305,13,102,3,0,
305,13,104,4,0,
305,13,106,1,1,2
305,13,106,1,4,
305,13,156,4,0,
305,13,164,3,0,
305,13,182,1,37,
305,13,182,4,0,
305,13,189,1,1,3
305,13,189,1,7,
305,13,201,4,0,
305,13,207,3,0,
305,13,213,4,0,
305,13,216,4,0,
305,13,218,4,0,
305,13,231,1,29,
305,13,231,4,0,
305,13,232,1,13,
305,13,237,4,0,
305,13,240,4,0,
305,13,241,4,0,
305,13,249,4,0,
305,13,263,4,0,
305,13,290,4,0,
305,13,317,4,0,
305,13,319,1,45,
305,13,332,4,0,
305,13,334,1,17,
305,13,351,4,0,
305,13,352,4,0,
305,14,15,4,0,
305,14,29,1,1,4
305,14,29,1,8,
305,14,33,1,1,1
305,14,36,1,22,
305,14,38,1,56,
305,14,46,1,18,
305,14,46,4,0,
305,14,70,4,0,
305,14,89,4,0,
305,14,91,4,0,
305,14,92,4,0,
305,14,104,4,0,
305,14,106,1,1,2
305,14,156,4,0,
305,14,157,4,0,
305,14,164,4,0,
305,14,173,3,0,
305,14,180,3,0,
305,14,182,1,29,
305,14,182,4,0,
305,14,189,1,1,3
305,14,189,1,4,
305,14,201,4,0,
305,14,207,4,0,
305,14,213,4,0,
305,14,214,3,0,
305,14,216,4,0,
305,14,218,4,0,
305,14,231,1,40,
305,14,231,3,0,
305,14,232,1,11,
305,14,237,4,0,
305,14,240,4,0,
305,14,241,4,0,
305,14,249,4,0,
305,14,253,3,0,
305,14,263,4,0,
305,14,276,3,0,
305,14,283,3,0,
305,14,317,4,0,
305,14,319,1,34,
305,14,332,4,0,
305,14,334,1,15,
305,14,334,3,0,
305,14,368,1,62,
305,14,393,3,0,
305,14,397,4,0,
305,14,414,3,0,
305,14,421,4,0,
305,14,442,1,25,
305,14,442,3,0,
305,14,444,4,0,
305,14,446,3,0,
305,14,468,4,0,
305,14,475,1,45,
305,14,484,1,51,
305,14,496,4,0,
305,14,523,4,0,
305,15,15,4,0,
305,15,29,1,1,4
305,15,29,1,8,
305,15,33,1,1,1
305,15,36,1,22,
305,15,38,1,56,
305,15,46,1,18,
305,15,46,4,0,
305,15,70,4,0,
305,15,89,4,0,
305,15,91,4,0,
305,15,92,4,0,
305,15,104,4,0,
305,15,106,1,1,2
305,15,156,4,0,
305,15,157,4,0,
305,15,164,4,0,
305,15,182,1,29,
305,15,182,4,0,
305,15,189,1,1,3
305,15,189,1,4,
305,15,201,4,0,
305,15,207,4,0,
305,15,213,4,0,
305,15,214,4,0,
305,15,216,4,0,
305,15,218,4,0,
305,15,231,1,40,
305,15,232,1,11,
305,15,237,4,0,
305,15,240,4,0,
305,15,241,4,0,
305,15,249,4,0,
305,15,263,4,0,
305,15,317,4,0,
305,15,319,1,34,
305,15,332,4,0,
305,15,334,1,15,
305,15,368,1,62,
305,15,397,4,0,
305,15,421,4,0,
305,15,442,1,25,
305,15,444,4,0,
305,15,468,4,0,
305,15,475,1,45,
305,15,484,1,51,
305,15,496,4,0,
305,15,523,4,0,
305,15,590,4,0,
305,16,15,4,0,
305,16,29,1,1,4
305,16,29,1,7,1
305,16,33,1,1,1
305,16,36,1,28,1
305,16,38,1,43,1
305,16,46,1,19,1
305,16,46,4,0,
305,16,70,4,0,
305,16,89,4,0,
305,16,91,4,0,
305,16,92,4,0,
305,16,104,4,0,
305,16,106,1,1,2
305,16,156,4,0,
305,16,157,1,25,1
305,16,157,4,0,
305,16,164,4,0,
305,16,173,3,0,
305,16,180,3,0,
305,16,182,1,16,1
305,16,182,4,0,
305,16,189,1,1,3
305,16,189,1,4,1
305,16,201,4,0,
305,16,207,4,0,
305,16,213,4,0,
305,16,214,4,0,
305,16,216,4,0,
305,16,218,4,0,
305,16,231,1,35,1
305,16,231,3,0,
305,16,232,1,10,1
305,16,237,4,0,
305,16,240,4,0,
305,16,241,4,0,
305,16,249,4,0,
305,16,253,3,0,
305,16,263,4,0,
305,16,276,3,0,
305,16,283,3,0,
305,16,290,4,0,
305,16,317,1,13,1
305,16,317,4,0,
305,16,319,1,31,1
305,16,332,4,0,
305,16,334,1,39,1
305,16,334,3,0,
305,16,351,3,0,
305,16,352,3,0,
305,16,368,1,55,1
305,16,393,3,0,
305,16,397,4,0,
305,16,414,3,0,
305,16,421,4,0,
305,16,442,1,22,1
305,16,442,3,0,
305,16,444,4,0,
305,16,446,3,0,
305,16,468,4,0,
305,16,475,1,47,1
305,16,484,1,51,1
305,16,496,4,0,
305,16,523,4,0,
305,16,590,4,0,
305,17,29,1,1,4
305,17,29,1,7,
305,17,33,1,1,1
305,17,36,1,28,
305,17,38,1,43,
305,17,46,1,19,
305,17,46,4,0,
305,17,89,4,0,
305,17,92,4,0,
305,17,104,4,0,
305,17,106,1,1,2
305,17,156,4,0,
305,17,157,1,25,
305,17,157,4,0,
305,17,164,4,0,
305,17,182,1,16,
305,17,182,4,0,
305,17,189,1,1,3
305,17,189,1,4,
305,17,201,4,0,
305,17,207,4,0,
305,17,213,4,0,
305,17,214,4,0,
305,17,216,4,0,
305,17,218,4,0,
305,17,231,1,35,
305,17,232,1,10,
305,17,237,4,0,
305,17,240,4,0,
305,17,241,4,0,
305,17,263,4,0,
305,17,317,1,13,
305,17,317,4,0,
305,17,319,1,31,
305,17,332,4,0,
305,17,334,1,39,
305,17,368,1,55,
305,17,397,4,0,
305,17,421,4,0,
305,17,442,1,22,
305,17,444,4,0,
305,17,475,1,47,
305,17,484,1,51,
305,17,496,4,0,
305,17,523,4,0,
305,17,590,4,0,
305,18,29,1,1,4
305,18,29,1,7,
305,18,33,1,1,1
305,18,36,1,28,
305,18,38,1,43,
305,18,46,1,19,
305,18,46,4,0,
305,18,89,4,0,
305,18,92,4,0,
305,18,104,4,0,
305,18,106,1,1,2
305,18,156,4,0,
305,18,157,1,25,
305,18,157,4,0,
305,18,164,4,0,
305,18,182,1,16,
305,18,182,4,0,
305,18,189,1,1,3
305,18,189,1,4,
305,18,201,4,0,
305,18,207,4,0,
305,18,213,4,0,
305,18,214,4,0,
305,18,216,4,0,
305,18,218,4,0,
305,18,231,1,35,
305,18,232,1,10,
305,18,237,4,0,
305,18,240,4,0,
305,18,241,4,0,
305,18,263,4,0,
305,18,317,1,13,
305,18,317,4,0,
305,18,319,1,31,
305,18,332,4,0,
305,18,334,1,39,
305,18,368,1,55,
305,18,397,4,0,
305,18,421,4,0,
305,18,442,1,22,
305,18,444,4,0,
305,18,475,1,47,
305,18,484,1,51,
305,18,496,4,0,
305,18,523,4,0,
305,18,590,4,0,
306,5,15,4,0,
306,5,29,1,1,4
306,5,29,1,10,
306,5,33,1,1,1
306,5,36,1,25,
306,5,38,1,63,
306,5,46,1,21,
306,5,46,4,0,
306,5,53,4,0,
306,5,57,4,0,
306,5,58,4,0,
306,5,59,4,0,
306,5,63,4,0,
306,5,70,4,0,
306,5,76,4,0,
306,5,85,4,0,
306,5,87,4,0,
306,5,89,4,0,
306,5,91,4,0,
306,5,92,4,0,
306,5,104,4,0,
306,5,106,1,1,2
306,5,106,1,4,
306,5,126,4,0,
306,5,156,4,0,
306,5,182,1,37,
306,5,182,4,0,
306,5,189,1,1,3
306,5,189,1,7,
306,5,201,4,0,
306,5,213,4,0,
306,5,216,4,0,
306,5,218,4,0,
306,5,231,1,29,
306,5,231,4,0,
306,5,232,1,13,
306,5,237,4,0,
306,5,240,4,0,
306,5,241,4,0,
306,5,249,4,0,
306,5,263,4,0,
306,5,264,4,0,
306,5,269,4,0,
306,5,280,4,0,
306,5,290,4,0,
306,5,317,4,0,
306,5,319,1,50,
306,5,332,4,0,
306,5,334,1,17,
306,5,337,4,0,
306,5,351,4,0,
306,5,352,4,0,
306,6,5,3,0,
306,6,7,3,0,
306,6,8,3,0,
306,6,9,3,0,
306,6,15,4,0,
306,6,25,3,0,
306,6,29,1,1,4
306,6,29,1,10,
306,6,33,1,1,1
306,6,34,3,0,
306,6,36,1,25,
306,6,38,1,63,
306,6,38,3,0,
306,6,46,1,21,
306,6,46,4,0,
306,6,53,4,0,
306,6,57,4,0,
306,6,58,4,0,
306,6,59,4,0,
306,6,63,4,0,
306,6,68,3,0,
306,6,69,3,0,
306,6,70,4,0,
306,6,76,4,0,
306,6,85,4,0,
306,6,86,3,0,
306,6,87,4,0,
306,6,89,4,0,
306,6,91,4,0,
306,6,92,4,0,
306,6,102,3,0,
306,6,104,4,0,
306,6,106,1,1,2
306,6,106,1,4,
306,6,111,3,0,
306,6,126,4,0,
306,6,156,4,0,
306,6,157,3,0,
306,6,164,3,0,
306,6,173,3,0,
306,6,182,1,37,
306,6,182,4,0,
306,6,189,1,1,3
306,6,189,1,7,
306,6,189,3,0,
306,6,196,3,0,
306,6,201,4,0,
306,6,203,3,0,
306,6,205,3,0,
306,6,207,3,0,
306,6,210,3,0,
306,6,213,4,0,
306,6,214,3,0,
306,6,216,4,0,
306,6,218,4,0,
306,6,223,3,0,
306,6,231,1,29,
306,6,231,4,0,
306,6,232,1,13,
306,6,237,4,0,
306,6,240,4,0,
306,6,241,4,0,
306,6,249,4,0,
306,6,263,4,0,
306,6,264,4,0,
306,6,269,4,0,
306,6,280,4,0,
306,6,290,4,0,
306,6,317,4,0,
306,6,319,1,50,
306,6,332,4,0,
306,6,334,1,17,
306,6,337,4,0,
306,6,351,4,0,
306,6,352,4,0,
306,7,5,3,0,
306,7,15,4,0,
306,7,25,3,0,
306,7,29,1,1,4
306,7,29,1,10,
306,7,33,1,1,1
306,7,34,3,0,
306,7,36,1,25,
306,7,38,1,63,
306,7,38,3,0,
306,7,46,1,21,
306,7,46,4,0,
306,7,53,4,0,
306,7,57,4,0,
306,7,58,4,0,
306,7,59,4,0,
306,7,63,4,0,
306,7,68,3,0,
306,7,69,3,0,
306,7,70,4,0,
306,7,76,4,0,
306,7,85,4,0,
306,7,86,3,0,
306,7,87,4,0,
306,7,89,4,0,
306,7,91,4,0,
306,7,92,4,0,
306,7,102,3,0,
306,7,104,4,0,
306,7,106,1,1,2
306,7,106,1,4,
306,7,126,4,0,
306,7,156,4,0,
306,7,157,3,0,
306,7,164,3,0,
306,7,182,1,37,
306,7,182,4,0,
306,7,189,1,1,3
306,7,189,1,7,
306,7,201,4,0,
306,7,213,4,0,
306,7,216,4,0,
306,7,218,4,0,
306,7,231,1,29,
306,7,231,4,0,
306,7,232,1,13,
306,7,237,4,0,
306,7,240,4,0,
306,7,241,4,0,
306,7,249,4,0,
306,7,263,4,0,
306,7,264,4,0,
306,7,269,4,0,
306,7,280,4,0,
306,7,290,4,0,
306,7,317,4,0,
306,7,319,1,50,
306,7,332,4,0,
306,7,334,1,17,
306,7,337,4,0,
306,7,351,4,0,
306,7,352,4,0,
306,8,15,4,0,
306,8,29,1,1,4
306,8,29,1,11,
306,8,33,1,1,1
306,8,36,1,25,
306,8,38,1,57,
306,8,46,1,22,
306,8,46,4,0,
306,8,53,4,0,
306,8,57,4,0,
306,8,58,4,0,
306,8,59,4,0,
306,8,63,4,0,
306,8,70,4,0,
306,8,76,4,0,
306,8,85,4,0,
306,8,86,4,0,
306,8,87,4,0,
306,8,89,4,0,
306,8,91,4,0,
306,8,92,4,0,
306,8,104,4,0,
306,8,106,1,1,2
306,8,106,1,4,
306,8,126,4,0,
306,8,156,4,0,
306,8,157,4,0,
306,8,164,4,0,
306,8,182,1,34,
306,8,182,4,0,
306,8,189,1,1,3
306,8,189,1,8,
306,8,201,4,0,
306,8,203,4,0,
306,8,207,4,0,
306,8,213,4,0,
306,8,214,4,0,
306,8,216,4,0,
306,8,218,4,0,
306,8,231,1,48,
306,8,231,4,0,
306,8,232,1,15,
306,8,237,4,0,
306,8,240,4,0,
306,8,241,4,0,
306,8,249,4,0,
306,8,263,4,0,
306,8,264,4,0,
306,8,269,4,0,
306,8,280,4,0,
306,8,290,4,0,
306,8,317,4,0,
306,8,319,1,40,
306,8,332,4,0,
306,8,334,1,18,
306,8,337,4,0,
306,8,351,4,0,
306,8,352,4,0,
306,8,363,4,0,
306,8,368,1,65,
306,8,371,4,0,
306,8,374,4,0,
306,8,397,4,0,
306,8,399,4,0,
306,8,406,4,0,
306,8,411,4,0,
306,8,416,4,0,
306,8,419,4,0,
306,8,421,4,0,
306,8,430,4,0,
306,8,431,4,0,
306,8,442,1,29,
306,8,444,4,0,
306,8,445,4,0,
306,8,446,4,0,
306,9,7,3,0,
306,9,8,3,0,
306,9,9,3,0,
306,9,15,4,0,
306,9,29,1,1,4
306,9,29,1,11,
306,9,33,1,1,1
306,9,36,1,25,
306,9,38,1,57,
306,9,46,1,22,
306,9,46,4,0,
306,9,53,4,0,
306,9,57,4,0,
306,9,58,4,0,
306,9,59,4,0,
306,9,63,4,0,
306,9,70,4,0,
306,9,76,4,0,
306,9,85,4,0,
306,9,86,4,0,
306,9,87,4,0,
306,9,89,4,0,
306,9,91,4,0,
306,9,92,4,0,
306,9,104,4,0,
306,9,106,1,1,2
306,9,106,1,4,
306,9,126,4,0,
306,9,156,4,0,
306,9,157,4,0,
306,9,164,4,0,
306,9,173,3,0,
306,9,180,3,0,
306,9,182,1,34,
306,9,182,4,0,
306,9,189,1,1,3
306,9,189,1,8,
306,9,189,3,0,
306,9,196,3,0,
306,9,200,3,0,
306,9,201,4,0,
306,9,203,4,0,
306,9,205,3,0,
306,9,207,4,0,
306,9,210,3,0,
306,9,213,4,0,
306,9,214,4,0,
306,9,216,4,0,
306,9,218,4,0,
306,9,231,1,48,
306,9,231,4,0,
306,9,232,1,15,
306,9,237,4,0,
306,9,240,4,0,
306,9,241,4,0,
306,9,246,3,0,
306,9,249,4,0,
306,9,253,3,0,
306,9,263,4,0,
306,9,264,4,0,
306,9,269,4,0,
306,9,276,3,0,
306,9,280,4,0,
306,9,283,3,0,
306,9,290,4,0,
306,9,317,4,0,
306,9,319,1,40,
306,9,332,4,0,
306,9,334,1,18,
306,9,334,3,0,
306,9,337,4,0,
306,9,351,4,0,
306,9,352,4,0,
306,9,363,4,0,
306,9,368,1,65,
306,9,371,4,0,
306,9,374,4,0,
306,9,393,3,0,
306,9,397,4,0,
306,9,399,4,0,
306,9,401,3,0,
306,9,406,4,0,
306,9,411,4,0,
306,9,414,3,0,
306,9,416,4,0,
306,9,419,4,0,
306,9,421,4,0,
306,9,430,4,0,
306,9,431,4,0,
306,9,442,1,29,
306,9,442,3,0,
306,9,444,4,0,
306,9,445,4,0,
306,9,446,4,0,
306,10,7,3,0,
306,10,8,3,0,
306,10,9,3,0,
306,10,15,4,0,
306,10,29,1,1,4
306,10,29,1,11,
306,10,29,3,0,
306,10,33,1,1,1
306,10,36,1,25,
306,10,38,1,57,
306,10,46,1,22,
306,10,46,4,0,
306,10,53,4,0,
306,10,57,4,0,
306,10,58,4,0,
306,10,59,4,0,
306,10,63,4,0,
306,10,67,3,0,
306,10,70,4,0,
306,10,76,4,0,
306,10,85,4,0,
306,10,86,4,0,
306,10,87,4,0,
306,10,89,4,0,
306,10,91,4,0,
306,10,92,4,0,
306,10,104,4,0,
306,10,106,1,1,2
306,10,106,1,4,
306,10,126,4,0,
306,10,156,4,0,
306,10,157,4,0,
306,10,164,4,0,
306,10,173,3,0,
306,10,180,3,0,
306,10,182,1,34,
306,10,182,4,0,
306,10,189,1,1,3
306,10,189,1,8,
306,10,189,3,0,
306,10,196,3,0,
306,10,200,3,0,
306,10,201,4,0,
306,10,203,4,0,
306,10,205,3,0,
306,10,207,4,0,
306,10,210,3,0,
306,10,213,4,0,
306,10,214,4,0,
306,10,216,4,0,
306,10,218,4,0,
306,10,231,1,48,
306,10,231,4,0,
306,10,232,1,15,
306,10,237,4,0,
306,10,240,4,0,
306,10,241,4,0,
306,10,246,3,0,
306,10,249,4,0,
306,10,250,4,0,
306,10,253,3,0,
306,10,263,4,0,
306,10,264,4,0,
306,10,269,4,0,
306,10,276,3,0,
306,10,280,4,0,
306,10,283,3,0,
306,10,290,4,0,
306,10,317,4,0,
306,10,319,1,40,
306,10,332,4,0,
306,10,334,1,18,
306,10,334,3,0,
306,10,335,3,0,
306,10,337,4,0,
306,10,351,4,0,
306,10,352,4,0,
306,10,363,4,0,
306,10,368,1,65,
306,10,371,4,0,
306,10,374,4,0,
306,10,393,3,0,
306,10,397,4,0,
306,10,399,4,0,
306,10,401,3,0,
306,10,406,4,0,
306,10,411,4,0,
306,10,414,3,0,
306,10,416,4,0,
306,10,419,4,0,
306,10,421,4,0,
306,10,430,4,0,
306,10,431,4,0,
306,10,442,1,29,
306,10,442,3,0,
306,10,444,4,0,
306,10,445,4,0,
306,10,446,4,0,
306,11,15,4,0,
306,11,29,1,1,4
306,11,29,1,11,
306,11,33,1,1,1
306,11,36,1,25,
306,11,38,1,74,
306,11,46,1,22,
306,11,46,4,0,
306,11,53,4,0,
306,11,57,4,0,
306,11,58,4,0,
306,11,59,4,0,
306,11,63,4,0,
306,11,70,4,0,
306,11,76,4,0,
306,11,85,4,0,
306,11,86,4,0,
306,11,87,4,0,
306,11,89,4,0,
306,11,91,4,0,
306,11,92,4,0,
306,11,104,4,0,
306,11,106,1,1,2
306,11,106,1,4,
306,11,126,4,0,
306,11,156,4,0,
306,11,157,4,0,
306,11,164,4,0,
306,11,182,1,34,
306,11,182,4,0,
306,11,189,1,1,3
306,11,189,1,8,
306,11,201,4,0,
306,11,207,4,0,
306,11,213,4,0,
306,11,216,4,0,
306,11,218,4,0,
306,11,231,1,48,
306,11,232,1,15,
306,11,237,4,0,
306,11,240,4,0,
306,11,241,4,0,
306,11,249,4,0,
306,11,263,4,0,
306,11,269,4,0,
306,11,280,4,0,
306,11,317,4,0,
306,11,319,1,40,
306,11,332,4,0,
306,11,334,1,18,
306,11,337,4,0,
306,11,368,1,82,
306,11,371,4,0,
306,11,374,4,0,
306,11,397,4,0,
306,11,411,4,0,
306,11,416,4,0,
306,11,421,4,0,
306,11,430,4,0,
306,11,442,1,29,
306,11,444,4,0,
306,11,468,4,0,
306,11,475,1,57,
306,11,479,4,0,
306,11,484,1,65,
306,11,496,4,0,
306,11,510,4,0,
306,11,523,4,0,
306,11,525,4,0,
306,12,15,4,0,
306,12,29,1,1,4
306,12,29,1,10,
306,12,33,1,1,1
306,12,36,1,25,
306,12,38,1,63,
306,12,46,1,21,
306,12,46,4,0,
306,12,53,4,0,
306,12,57,4,0,
306,12,58,4,0,
306,12,59,4,0,
306,12,63,4,0,
306,12,70,4,0,
306,12,76,4,0,
306,12,85,4,0,
306,12,87,4,0,
306,12,89,4,0,
306,12,91,4,0,
306,12,92,4,0,
306,12,104,4,0,
306,12,106,1,1,2
306,12,106,1,4,
306,12,126,4,0,
306,12,156,4,0,
306,12,182,1,37,
306,12,182,4,0,
306,12,189,1,1,3
306,12,189,1,7,
306,12,201,4,0,
306,12,213,4,0,
306,12,216,4,0,
306,12,218,4,0,
306,12,231,1,29,
306,12,231,4,0,
306,12,232,1,13,
306,12,237,4,0,
306,12,240,4,0,
306,12,241,4,0,
306,12,249,4,0,
306,12,263,4,0,
306,12,264,4,0,
306,12,269,4,0,
306,12,280,4,0,
306,12,290,4,0,
306,12,317,4,0,
306,12,319,1,50,
306,12,332,4,0,
306,12,334,1,17,
306,12,337,4,0,
306,12,351,4,0,
306,12,352,4,0,
306,13,15,4,0,
306,13,29,1,1,4
306,13,29,1,10,
306,13,33,1,1,1
306,13,34,3,0,
306,13,36,1,25,
306,13,38,1,63,
306,13,38,3,0,
306,13,46,1,21,
306,13,46,4,0,
306,13,53,4,0,
306,13,57,4,0,
306,13,58,4,0,
306,13,59,4,0,
306,13,63,4,0,
306,13,69,3,0,
306,13,70,4,0,
306,13,76,4,0,
306,13,85,4,0,
306,13,86,3,0,
306,13,87,4,0,
306,13,89,4,0,
306,13,91,4,0,
306,13,92,4,0,
306,13,102,3,0,
306,13,104,4,0,
306,13,106,1,1,2
306,13,106,1,4,
306,13,126,4,0,
306,13,156,4,0,
306,13,164,3,0,
306,13,182,1,37,
306,13,182,4,0,
306,13,189,1,1,3
306,13,189,1,7,
306,13,196,3,0,
306,13,201,4,0,
306,13,207,3,0,
306,13,213,4,0,
306,13,216,4,0,
306,13,218,4,0,
306,13,231,1,29,
306,13,231,4,0,
306,13,232,1,13,
306,13,237,4,0,
306,13,240,4,0,
306,13,241,4,0,
306,13,249,4,0,
306,13,263,4,0,
306,13,264,4,0,
306,13,269,4,0,
306,13,280,4,0,
306,13,290,4,0,
306,13,317,4,0,
306,13,319,1,50,
306,13,332,4,0,
306,13,334,1,17,
306,13,337,4,0,
306,13,351,4,0,
306,13,352,4,0,
306,14,7,3,0,
306,14,8,3,0,
306,14,9,3,0,
306,14,15,4,0,
306,14,29,1,1,4
306,14,29,1,8,
306,14,33,1,1,1
306,14,36,1,22,
306,14,38,1,65,
306,14,46,1,18,
306,14,46,4,0,
306,14,53,4,0,
306,14,57,4,0,
306,14,58,4,0,
306,14,59,4,0,
306,14,63,4,0,
306,14,67,3,0,
306,14,70,4,0,
306,14,76,4,0,
306,14,85,4,0,
306,14,86,4,0,
306,14,87,4,0,
306,14,89,4,0,
306,14,91,4,0,
306,14,92,4,0,
306,14,104,4,0,
306,14,106,1,1,2
306,14,126,4,0,
306,14,156,4,0,
306,14,157,4,0,
306,14,164,4,0,
306,14,173,3,0,
306,14,180,3,0,
306,14,182,1,29,
306,14,182,4,0,
306,14,189,1,1,3
306,14,189,1,4,
306,14,196,3,0,
306,14,200,3,0,
306,14,201,4,0,
306,14,207,4,0,
306,14,213,4,0,
306,14,214,3,0,
306,14,216,4,0,
306,14,218,4,0,
306,14,231,1,40,
306,14,231,3,0,
306,14,232,1,11,
306,14,237,4,0,
306,14,240,4,0,
306,14,241,4,0,
306,14,249,4,0,
306,14,253,3,0,
306,14,263,4,0,
306,14,269,4,0,
306,14,276,3,0,
306,14,280,4,0,
306,14,283,3,0,
306,14,317,4,0,
306,14,319,1,34,
306,14,332,4,0,
306,14,334,1,15,
306,14,334,3,0,
306,14,335,3,0,
306,14,337,4,0,
306,14,368,1,74,
306,14,371,4,0,
306,14,374,4,0,
306,14,393,3,0,
306,14,397,4,0,
306,14,399,3,0,
306,14,401,3,0,
306,14,406,3,0,
306,14,411,4,0,
306,14,414,3,0,
306,14,416,4,0,
306,14,421,4,0,
306,14,430,4,0,
306,14,442,1,25,
306,14,442,3,0,
306,14,444,4,0,
306,14,446,3,0,
306,14,468,4,0,
306,14,475,1,48,
306,14,479,4,0,
306,14,484,1,57,
306,14,496,4,0,
306,14,510,4,0,
306,14,523,4,0,
306,14,525,4,0,
306,15,15,4,0,
306,15,29,1,1,4
306,15,29,1,8,
306,15,33,1,1,1
306,15,36,1,22,
306,15,38,1,65,
306,15,46,1,18,
306,15,46,4,0,
306,15,53,4,0,
306,15,57,4,0,
306,15,58,4,0,
306,15,59,4,0,
306,15,63,4,0,
306,15,70,4,0,
306,15,76,4,0,
306,15,85,4,0,
306,15,86,4,0,
306,15,87,4,0,
306,15,89,4,0,
306,15,91,4,0,
306,15,92,4,0,
306,15,104,4,0,
306,15,106,1,1,2
306,15,126,4,0,
306,15,156,4,0,
306,15,157,4,0,
306,15,164,4,0,
306,15,182,1,29,
306,15,182,4,0,
306,15,189,1,1,3
306,15,189,1,4,
306,15,201,4,0,
306,15,207,4,0,
306,15,213,4,0,
306,15,214,4,0,
306,15,216,4,0,
306,15,218,4,0,
306,15,231,1,40,
306,15,232,1,11,
306,15,237,4,0,
306,15,240,4,0,
306,15,241,4,0,
306,15,249,4,0,
306,15,263,4,0,
306,15,269,4,0,
306,15,280,4,0,
306,15,317,4,0,
306,15,319,1,34,
306,15,332,4,0,
306,15,334,1,15,
306,15,337,4,0,
306,15,368,1,74,
306,15,371,4,0,
306,15,374,4,0,
306,15,397,4,0,
306,15,399,4,0,
306,15,411,4,0,
306,15,416,4,0,
306,15,421,4,0,
306,15,430,4,0,
306,15,442,1,25,
306,15,444,4,0,
306,15,468,4,0,
306,15,475,1,48,
306,15,479,4,0,
306,15,484,1,57,
306,15,496,4,0,
306,15,510,4,0,
306,15,523,4,0,
306,15,525,4,0,
306,15,590,4,0,
306,15,612,4,0,
306,16,7,3,0,
306,16,8,3,0,
306,16,9,3,0,
306,16,15,4,0,
306,16,29,1,1,4
306,16,29,1,7,1
306,16,33,1,1,1
306,16,36,1,28,1
306,16,38,1,45,1
306,16,46,1,19,1
306,16,46,4,0,
306,16,53,4,0,
306,16,57,4,0,
306,16,58,4,0,
306,16,59,4,0,
306,16,63,4,0,
306,16,67,3,0,
306,16,70,4,0,
306,16,76,4,0,
306,16,85,4,0,
306,16,86,4,0,
306,16,87,4,0,
306,16,89,4,0,
306,16,91,4,0,
306,16,92,4,0,
306,16,104,4,0,
306,16,106,1,1,2
306,16,126,4,0,
306,16,156,4,0,
306,16,157,1,25,1
306,16,157,4,0,
306,16,164,4,0,
306,16,173,3,0,
306,16,180,3,0,
306,16,182,1,16,1
306,16,182,4,0,
306,16,189,1,1,3
306,16,189,1,4,1
306,16,196,3,0,
306,16,200,3,0,
306,16,201,4,0,
306,16,207,4,0,
306,16,213,4,0,
306,16,214,4,0,
306,16,216,4,0,
306,16,218,4,0,
306,16,231,1,35,1
306,16,231,3,0,
306,16,232,1,10,1
306,16,237,4,0,
306,16,240,4,0,
306,16,241,4,0,
306,16,249,4,0,
306,16,253,3,0,
306,16,263,4,0,
306,16,264,3,0,
306,16,269,4,0,
306,16,276,3,0,
306,16,280,4,0,
306,16,283,3,0,
306,16,290,4,0,
306,16,317,1,13,1
306,16,317,4,0,
306,16,319,1,31,1
306,16,332,4,0,
306,16,334,1,39,1
306,16,334,3,0,
306,16,335,3,0,
306,16,337,4,0,
306,16,351,3,0,
306,16,352,3,0,
306,16,368,1,63,1
306,16,371,4,0,
306,16,374,4,0,
306,16,393,3,0,
306,16,397,4,0,
306,16,399,4,0,
306,16,401,3,0,
306,16,406,3,0,
306,16,411,4,0,
306,16,414,3,0,
306,16,416,4,0,
306,16,421,4,0,
306,16,430,4,0,
306,16,442,1,22,1
306,16,442,3,0,
306,16,444,4,0,
306,16,446,3,0,
306,16,468,4,0,
306,16,475,1,51,1
306,16,479,4,0,
306,16,484,1,57,1
306,16,496,4,0,
306,16,510,4,0,
306,16,523,4,0,
306,16,525,4,0,
306,16,590,4,0,
306,16,612,4,0,
306,17,29,1,1,4
306,17,29,1,7,
306,17,33,1,1,1
306,17,36,1,28,
306,17,38,1,45,
306,17,46,1,19,
306,17,46,4,0,
306,17,53,4,0,
306,17,57,4,0,
306,17,58,4,0,
306,17,59,4,0,
306,17,63,4,0,
306,17,76,4,0,
306,17,85,4,0,
306,17,86,4,0,
306,17,87,4,0,
306,17,89,4,0,
306,17,92,4,0,
306,17,104,4,0,
306,17,106,1,1,2
306,17,126,4,0,
306,17,156,4,0,
306,17,157,1,25,
306,17,157,4,0,
306,17,164,4,0,
306,17,182,1,16,
306,17,182,4,0,
306,17,189,1,1,3
306,17,189,1,4,
306,17,201,4,0,
306,17,207,4,0,
306,17,213,4,0,
306,17,214,4,0,
306,17,216,4,0,
306,17,218,4,0,
306,17,231,1,35,
306,17,232,1,10,
306,17,237,4,0,
306,17,240,4,0,
306,17,241,4,0,
306,17,263,4,0,
306,17,269,4,0,
306,17,280,4,0,
306,17,317,1,13,
306,17,317,4,0,
306,17,319,1,31,
306,17,332,4,0,
306,17,334,1,39,
306,17,337,4,0,
306,17,368,1,63,
306,17,371,4,0,
306,17,374,4,0,
306,17,397,4,0,
306,17,399,4,0,
306,17,411,4,0,
306,17,416,4,0,
306,17,421,4,0,
306,17,430,4,0,
306,17,442,1,22,
306,17,444,4,0,
306,17,475,1,51,
306,17,479,4,0,
306,17,484,1,57,
306,17,496,4,0,
306,17,523,4,0,
306,17,525,4,0,
306,17,590,4,0,
306,17,684,4,0,
306,17,693,4,0,
306,18,29,1,1,4
306,18,29,1,7,
306,18,33,1,1,1
306,18,36,1,28,
306,18,38,1,45,
306,18,46,1,19,
306,18,46,4,0,
306,18,53,4,0,
306,18,57,4,0,
306,18,58,4,0,
306,18,59,4,0,
306,18,63,4,0,
306,18,76,4,0,
306,18,85,4,0,
306,18,86,4,0,
306,18,87,4,0,
306,18,89,4,0,
306,18,92,4,0,
306,18,104,4,0,
306,18,106,1,1,2
306,18,126,4,0,
306,18,156,4,0,
306,18,157,1,25,
306,18,157,4,0,
306,18,164,4,0,
306,18,182,1,16,
306,18,182,4,0,
306,18,189,1,1,3
306,18,189,1,4,
306,18,201,4,0,
306,18,207,4,0,
306,18,213,4,0,
306,18,214,4,0,
306,18,216,4,0,
306,18,218,4,0,
306,18,231,1,35,
306,18,232,1,10,
306,18,237,4,0,
306,18,240,4,0,
306,18,241,4,0,
306,18,263,4,0,
306,18,269,4,0,
306,18,280,4,0,
306,18,317,1,13,
306,18,317,4,0,
306,18,319,1,31,
306,18,332,4,0,
306,18,334,1,39,
306,18,337,4,0,
306,18,368,1,63,
306,18,371,4,0,
306,18,374,4,0,
306,18,397,4,0,
306,18,399,4,0,
306,18,411,4,0,
306,18,416,4,0,
306,18,421,4,0,
306,18,430,4,0,
306,18,442,1,22,
306,18,444,4,0,
306,18,475,1,51,
306,18,479,4,0,
306,18,484,1,57,
306,18,496,4,0,
306,18,523,4,0,
306,18,525,4,0,
306,18,590,4,0,
306,18,684,4,0,
306,18,693,4,0,
307,5,7,2,0,
307,5,8,2,0,
307,5,9,2,0,
307,5,70,4,0,
307,5,92,4,0,
307,5,93,1,9,
307,5,94,4,0,
307,5,96,1,4,
307,5,104,4,0,
307,5,105,1,48,
307,5,113,4,0,
307,5,115,4,0,
307,5,117,1,1,
307,5,136,1,32,
307,5,148,4,0,
307,5,156,4,0,
307,5,170,1,22,
307,5,179,1,42,
307,5,182,4,0,
307,5,193,2,0,
307,5,197,1,12,
307,5,213,4,0,
307,5,216,4,0,
307,5,218,4,0,
307,5,223,2,0,
307,5,226,2,0,
307,5,237,1,18,
307,5,237,4,0,
307,5,240,4,0,
307,5,241,4,0,
307,5,244,1,38,
307,5,247,4,0,
307,5,249,4,0,
307,5,252,2,0,
307,5,263,4,0,
307,5,264,4,0,
307,5,280,4,0,
307,5,290,4,0,
307,5,317,4,0,
307,5,339,4,0,
307,5,347,1,28,
307,5,347,4,0,
307,6,5,3,0,
307,6,7,2,0,
307,6,7,3,0,
307,6,8,2,0,
307,6,8,3,0,
307,6,9,2,0,
307,6,9,3,0,
307,6,25,3,0,
307,6,34,3,0,
307,6,38,3,0,
307,6,68,3,0,
307,6,69,3,0,
307,6,70,4,0,
307,6,92,4,0,
307,6,93,1,9,
307,6,94,4,0,
307,6,96,1,4,
307,6,102,3,0,
307,6,104,4,0,
307,6,105,1,48,
307,6,113,4,0,
307,6,115,4,0,
307,6,117,1,1,
307,6,118,3,0,
307,6,129,3,0,
307,6,136,1,32,
307,6,138,3,0,
307,6,148,4,0,
307,6,156,4,0,
307,6,164,3,0,
307,6,170,1,22,
307,6,173,3,0,
307,6,179,1,42,
307,6,182,4,0,
307,6,189,3,0,
307,6,193,2,0,
307,6,197,1,12,
307,6,203,3,0,
307,6,207,3,0,
307,6,213,4,0,
307,6,214,3,0,
307,6,216,4,0,
307,6,218,4,0,
307,6,223,2,0,
307,6,223,3,0,
307,6,226,2,0,
307,6,237,1,18,
307,6,237,4,0,
307,6,240,4,0,
307,6,241,4,0,
307,6,244,1,38,
307,6,244,3,0,
307,6,247,4,0,
307,6,249,4,0,
307,6,252,2,0,
307,6,263,4,0,
307,6,264,4,0,
307,6,280,4,0,
307,6,290,4,0,
307,6,317,4,0,
307,6,339,4,0,
307,6,347,1,28,
307,6,347,4,0,
307,7,5,3,0,
307,7,7,2,0,
307,7,8,2,0,
307,7,9,2,0,
307,7,25,3,0,
307,7,34,3,0,
307,7,38,3,0,
307,7,68,3,0,
307,7,69,3,0,
307,7,70,4,0,
307,7,92,4,0,
307,7,93,1,9,
307,7,94,4,0,
307,7,96,1,4,
307,7,102,3,0,
307,7,104,4,0,
307,7,105,1,44,
307,7,113,4,0,
307,7,115,4,0,
307,7,117,1,1,
307,7,118,3,0,
307,7,136,1,33,
307,7,138,3,0,
307,7,148,4,0,
307,7,156,4,0,
307,7,164,3,0,
307,7,170,1,25,
307,7,179,1,41,
307,7,182,4,0,
307,7,193,2,0,
307,7,197,1,12,
307,7,207,1,20,
307,7,213,4,0,
307,7,216,4,0,
307,7,218,4,0,
307,7,223,2,0,
307,7,226,2,0,
307,7,237,1,17,
307,7,237,4,0,
307,7,240,4,0,
307,7,241,4,0,
307,7,244,1,36,
307,7,247,4,0,
307,7,249,4,0,
307,7,252,2,0,
307,7,263,4,0,
307,7,264,4,0,
307,7,280,4,0,
307,7,290,4,0,
307,7,317,4,0,
307,7,339,4,0,
307,7,347,1,28,
307,7,347,4,0,
307,8,7,2,0,
307,8,8,2,0,
307,8,9,2,0,
307,8,70,4,0,
307,8,92,4,0,
307,8,93,1,8,
307,8,94,4,0,
307,8,96,1,4,
307,8,104,4,0,
307,8,105,1,46,
307,8,113,4,0,
307,8,115,4,0,
307,8,117,1,1,
307,8,136,1,32,
307,8,138,4,0,
307,8,148,4,0,
307,8,156,4,0,
307,8,157,4,0,
307,8,164,4,0,
307,8,170,1,18,
307,8,179,1,43,
307,8,182,4,0,
307,8,193,2,0,
307,8,197,1,11,
307,8,203,4,0,
307,8,207,4,0,
307,8,213,4,0,
307,8,214,4,0,
307,8,216,4,0,
307,8,218,4,0,
307,8,223,2,0,
307,8,226,2,0,
307,8,237,1,15,
307,8,237,4,0,
307,8,240,4,0,
307,8,241,4,0,
307,8,244,1,36,
307,8,244,4,0,
307,8,247,4,0,
307,8,249,4,0,
307,8,252,2,0,
307,8,263,4,0,
307,8,264,4,0,
307,8,278,4,0,
307,8,280,4,0,
307,8,290,4,0,
307,8,317,4,0,
307,8,339,4,0,
307,8,347,1,25,
307,8,347,4,0,
307,8,363,4,0,
307,8,364,1,22,
307,8,374,4,0,
307,8,379,1,39,
307,8,384,2,0,
307,8,385,2,0,
307,8,395,1,29,
307,8,398,4,0,
307,8,409,4,0,
307,8,411,4,0,
307,8,418,2,0,
307,8,427,2,0,
307,8,445,4,0,
307,8,447,4,0,
307,9,7,2,0,
307,9,7,3,0,
307,9,8,2,0,
307,9,8,3,0,
307,9,9,2,0,
307,9,9,3,0,
307,9,70,4,0,
307,9,92,4,0,
307,9,93,1,8,
307,9,94,4,0,
307,9,96,1,4,
307,9,104,4,0,
307,9,105,1,46,
307,9,113,4,0,
307,9,115,4,0,
307,9,117,1,1,
307,9,129,3,0,
307,9,136,1,32,
307,9,138,4,0,
307,9,148,4,0,
307,9,156,4,0,
307,9,157,4,0,
307,9,164,4,0,
307,9,170,1,18,
307,9,173,3,0,
307,9,179,1,43,
307,9,182,4,0,
307,9,189,3,0,
307,9,193,2,0,
307,9,197,1,11,
307,9,203,4,0,
307,9,207,4,0,
307,9,213,4,0,
307,9,214,4,0,
307,9,216,4,0,
307,9,218,4,0,
307,9,223,2,0,
307,9,226,2,0,
307,9,237,1,15,
307,9,237,4,0,
307,9,240,4,0,
307,9,241,4,0,
307,9,244,1,36,
307,9,244,4,0,
307,9,247,4,0,
307,9,249,4,0,
307,9,252,2,0,
307,9,263,4,0,
307,9,264,4,0,
307,9,270,3,0,
307,9,271,3,0,
307,9,278,4,0,
307,9,280,4,0,
307,9,290,4,0,
307,9,317,4,0,
307,9,324,3,0,
307,9,339,4,0,
307,9,347,1,25,
307,9,347,4,0,
307,9,363,4,0,
307,9,364,1,22,
307,9,374,4,0,
307,9,379,1,39,
307,9,384,2,0,
307,9,385,2,0,
307,9,395,1,29,
307,9,398,4,0,
307,9,409,4,0,
307,9,410,3,0,
307,9,411,4,0,
307,9,418,2,0,
307,9,427,2,0,
307,9,428,3,0,
307,9,445,4,0,
307,9,447,4,0,
307,10,7,2,0,
307,10,7,3,0,
307,10,8,2,0,
307,10,8,3,0,
307,10,9,2,0,
307,10,9,3,0,
307,10,29,3,0,
307,10,67,3,0,
307,10,70,4,0,
307,10,92,4,0,
307,10,93,1,8,
307,10,94,4,0,
307,10,96,1,4,
307,10,104,4,0,
307,10,105,1,46,
307,10,113,4,0,
307,10,115,4,0,
307,10,117,1,1,
307,10,129,3,0,
307,10,136,1,32,
307,10,138,4,0,
307,10,148,4,0,
307,10,156,4,0,
307,10,157,4,0,
307,10,164,4,0,
307,10,170,1,18,
307,10,173,3,0,
307,10,179,1,43,
307,10,182,4,0,
307,10,189,3,0,
307,10,193,2,0,
307,10,197,1,11,
307,10,203,4,0,
307,10,207,4,0,
307,10,213,4,0,
307,10,214,4,0,
307,10,216,4,0,
307,10,218,4,0,
307,10,220,3,0,
307,10,223,2,0,
307,10,226,2,0,
307,10,237,1,15,
307,10,237,4,0,
307,10,240,4,0,
307,10,241,4,0,
307,10,244,1,36,
307,10,244,4,0,
307,10,247,4,0,
307,10,249,4,0,
307,10,252,2,0,
307,10,263,4,0,
307,10,264,4,0,
307,10,270,3,0,
307,10,271,3,0,
307,10,272,3,0,
307,10,277,3,0,
307,10,278,4,0,
307,10,280,4,0,
307,10,290,4,0,
307,10,317,4,0,
307,10,324,3,0,
307,10,339,4,0,
307,10,347,1,25,
307,10,347,4,0,
307,10,356,3,0,
307,10,363,4,0,
307,10,364,1,22,
307,10,374,4,0,
307,10,379,1,39,
307,10,384,2,0,
307,10,385,2,0,
307,10,395,1,29,
307,10,398,4,0,
307,10,409,4,0,
307,10,410,3,0,
307,10,411,4,0,
307,10,418,2,0,
307,10,427,2,0,
307,10,428,3,0,
307,10,445,4,0,
307,10,447,4,0,
307,11,7,2,0,
307,11,8,2,0,
307,11,9,2,0,
307,11,70,4,0,
307,11,92,4,0,
307,11,93,1,8,
307,11,94,4,0,
307,11,96,1,4,
307,11,104,4,0,
307,11,105,1,50,
307,11,113,4,0,
307,11,115,4,0,
307,11,117,1,1,
307,11,136,1,32,
307,11,138,4,0,
307,11,148,4,0,
307,11,156,4,0,
307,11,157,4,0,
307,11,164,4,0,
307,11,170,1,18,
307,11,179,1,46,
307,11,182,4,0,
307,11,193,2,0,
307,11,197,1,11,
307,11,207,4,0,
307,11,213,4,0,
307,11,216,4,0,
307,11,218,4,0,
307,11,223,2,0,
307,11,226,2,0,
307,11,237,1,15,
307,11,237,4,0,
307,11,240,4,0,
307,11,241,4,0,
307,11,244,1,36,
307,11,244,4,0,
307,11,247,4,0,
307,11,249,4,0,
307,11,252,2,0,
307,11,263,4,0,
307,11,280,4,0,
307,11,290,2,0,
307,11,317,4,0,
307,11,339,4,0,
307,11,347,1,25,
307,11,347,4,0,
307,11,364,1,22,
307,11,367,1,39,
307,11,374,4,0,
307,11,379,1,43,
307,11,384,2,0,
307,11,385,2,0,
307,11,395,1,29,
307,11,398,4,0,
307,11,409,2,0,
307,11,411,4,0,
307,11,418,2,0,
307,11,427,2,0,
307,11,447,4,0,
307,11,473,4,0,
307,11,477,4,0,
307,11,490,4,0,
307,11,496,4,0,
307,11,514,4,0,
307,11,526,4,0,
307,12,70,4,0,
307,12,92,4,0,
307,12,93,1,9,
307,12,94,4,0,
307,12,96,1,4,
307,12,104,4,0,
307,12,105,1,48,
307,12,113,4,0,
307,12,115,4,0,
307,12,117,1,1,
307,12,136,1,32,
307,12,148,4,0,
307,12,156,4,0,
307,12,170,1,22,
307,12,179,1,42,
307,12,182,4,0,
307,12,197,1,12,
307,12,213,4,0,
307,12,216,4,0,
307,12,218,4,0,
307,12,237,1,18,
307,12,237,4,0,
307,12,240,4,0,
307,12,241,4,0,
307,12,244,1,38,
307,12,247,4,0,
307,12,249,4,0,
307,12,263,4,0,
307,12,264,4,0,
307,12,280,4,0,
307,12,290,4,0,
307,12,317,4,0,
307,12,339,4,0,
307,12,347,1,28,
307,12,347,4,0,
307,13,34,3,0,
307,13,38,3,0,
307,13,69,3,0,
307,13,70,4,0,
307,13,92,4,0,
307,13,93,1,9,
307,13,94,4,0,
307,13,96,1,4,
307,13,102,3,0,
307,13,104,4,0,
307,13,105,1,48,
307,13,113,4,0,
307,13,115,4,0,
307,13,117,1,1,
307,13,136,1,32,
307,13,138,3,0,
307,13,148,4,0,
307,13,156,4,0,
307,13,164,3,0,
307,13,170,1,22,
307,13,179,1,42,
307,13,182,4,0,
307,13,197,1,12,
307,13,207,3,0,
307,13,213,4,0,
307,13,216,4,0,
307,13,218,4,0,
307,13,237,1,18,
307,13,237,4,0,
307,13,240,4,0,
307,13,241,4,0,
307,13,244,1,38,
307,13,247,4,0,
307,13,249,4,0,
307,13,263,4,0,
307,13,264,4,0,
307,13,280,4,0,
307,13,290,4,0,
307,13,317,4,0,
307,13,339,4,0,
307,13,347,1,28,
307,13,347,4,0,
307,14,7,2,0,
307,14,7,3,0,
307,14,8,2,0,
307,14,8,3,0,
307,14,9,2,0,
307,14,9,3,0,
307,14,67,3,0,
307,14,70,4,0,
307,14,92,4,0,
307,14,93,1,8,
307,14,94,4,0,
307,14,96,1,4,
307,14,104,4,0,
307,14,105,1,50,
307,14,113,4,0,
307,14,115,4,0,
307,14,117,1,1,
307,14,136,1,32,
307,14,138,4,0,
307,14,148,4,0,
307,14,156,4,0,
307,14,157,4,0,
307,14,164,4,0,
307,14,170,1,18,
307,14,173,3,0,
307,14,179,1,46,
307,14,182,4,0,
307,14,193,2,0,
307,14,197,1,11,
307,14,207,4,0,
307,14,213,4,0,
307,14,214,3,0,
307,14,216,4,0,
307,14,218,4,0,
307,14,220,3,0,
307,14,223,2,0,
307,14,226,2,0,
307,14,237,1,15,
307,14,237,4,0,
307,14,240,4,0,
307,14,241,4,0,
307,14,244,1,36,
307,14,244,4,0,
307,14,247,4,0,
307,14,249,4,0,
307,14,252,2,0,
307,14,263,4,0,
307,14,270,3,0,
307,14,271,3,0,
307,14,272,3,0,
307,14,277,3,0,
307,14,278,3,0,
307,14,280,4,0,
307,14,290,2,0,
307,14,317,4,0,
307,14,324,3,0,
307,14,339,4,0,
307,14,347,1,25,
307,14,347,4,0,
307,14,356,3,0,
307,14,364,1,22,
307,14,367,1,39,
307,14,374,4,0,
307,14,379,1,43,
307,14,384,2,0,
307,14,385,2,0,
307,14,395,1,29,
307,14,398,4,0,
307,14,409,2,0,
307,14,409,3,0,
307,14,411,4,0,
307,14,418,2,0,
307,14,427,2,0,
307,14,428,3,0,
307,14,447,4,0,
307,14,473,4,0,
307,14,477,4,0,
307,14,490,4,0,
307,14,496,4,0,
307,14,514,4,0,
307,14,526,4,0,
307,15,7,2,0,
307,15,8,2,0,
307,15,9,2,0,
307,15,70,4,0,
307,15,92,4,0,
307,15,93,1,8,
307,15,94,4,0,
307,15,96,1,4,
307,15,104,4,0,
307,15,105,1,50,
307,15,113,4,0,
307,15,115,4,0,
307,15,117,1,1,
307,15,136,1,32,
307,15,138,4,0,
307,15,148,4,0,
307,15,156,4,0,
307,15,157,4,0,
307,15,164,4,0,
307,15,170,1,18,
307,15,179,1,46,
307,15,182,4,0,
307,15,193,2,0,
307,15,197,1,11,
307,15,207,4,0,
307,15,213,4,0,
307,15,214,4,0,
307,15,216,4,0,
307,15,218,4,0,
307,15,223,2,0,
307,15,226,2,0,
307,15,237,1,15,
307,15,237,4,0,
307,15,240,4,0,
307,15,241,4,0,
307,15,244,1,36,
307,15,244,4,0,
307,15,247,4,0,
307,15,249,4,0,
307,15,252,2,0,
307,15,263,4,0,
307,15,280,4,0,
307,15,290,2,0,
307,15,317,4,0,
307,15,339,4,0,
307,15,347,1,25,
307,15,347,4,0,
307,15,364,1,22,
307,15,367,1,39,
307,15,374,4,0,
307,15,379,1,43,
307,15,384,2,0,
307,15,385,2,0,
307,15,395,1,29,
307,15,398,4,0,
307,15,409,2,0,
307,15,411,4,0,
307,15,418,2,0,
307,15,427,2,0,
307,15,447,4,0,
307,15,473,4,0,
307,15,490,4,0,
307,15,496,4,0,
307,15,501,2,0,
307,15,514,4,0,
307,15,590,4,0,
307,15,612,4,0,
307,16,7,2,0,
307,16,7,3,0,
307,16,8,2,0,
307,16,8,3,0,
307,16,9,2,0,
307,16,9,3,0,
307,16,67,3,0,
307,16,68,1,44,1
307,16,70,4,0,
307,16,92,4,0,
307,16,93,1,7,1
307,16,94,4,0,
307,16,96,1,4,1
307,16,104,4,0,
307,16,105,1,41,1
307,16,113,4,0,
307,16,115,4,0,
307,16,117,1,1,1
307,16,136,1,28,1
307,16,138,4,0,
307,16,148,4,0,
307,16,156,4,0,
307,16,157,4,0,
307,16,164,4,0,
307,16,170,1,25,1
307,16,173,3,0,
307,16,179,1,39,1
307,16,182,4,0,
307,16,193,2,0,
307,16,197,1,9,1
307,16,203,1,12,1
307,16,207,4,0,
307,16,213,4,0,
307,16,214,4,0,
307,16,216,4,0,
307,16,218,4,0,
307,16,220,3,0,
307,16,223,2,0,
307,16,226,2,0,
307,16,237,1,20,1
307,16,237,4,0,
307,16,240,4,0,
307,16,241,4,0,
307,16,244,1,31,1
307,16,244,4,0,
307,16,247,4,0,
307,16,249,4,0,
307,16,252,2,0,
307,16,263,4,0,
307,16,264,3,0,
307,16,270,3,0,
307,16,271,3,0,
307,16,272,3,0,
307,16,277,3,0,
307,16,278,3,0,
307,16,280,4,0,
307,16,290,2,0,
307,16,290,4,0,
307,16,317,4,0,
307,16,324,3,0,
307,16,339,4,0,
307,16,347,1,23,1
307,16,347,4,0,
307,16,356,3,0,
307,16,364,1,15,1
307,16,367,1,33,1
307,16,374,4,0,
307,16,379,1,36,1
307,16,384,2,0,
307,16,385,2,0,
307,16,395,1,17,1
307,16,398,4,0,
307,16,409,2,0,
307,16,409,3,0,
307,16,411,4,0,
307,16,418,2,0,
307,16,427,2,0,
307,16,428,3,0,
307,16,447,4,0,
307,16,473,4,0,
307,16,490,4,0,
307,16,496,4,0,
307,16,501,2,0,
307,16,514,4,0,
307,16,590,4,0,
307,16,612,4,0,
307,17,7,2,0,
307,17,8,2,0,
307,17,9,2,0,
307,17,68,1,44,
307,17,92,4,0,
307,17,93,1,7,
307,17,94,4,0,
307,17,96,1,4,
307,17,104,4,0,
307,17,105,1,41,
307,17,113,4,0,
307,17,115,4,0,
307,17,117,1,1,
307,17,136,1,28,
307,17,138,4,0,
307,17,156,4,0,
307,17,157,4,0,
307,17,164,4,0,
307,17,170,1,25,
307,17,179,1,39,
307,17,182,4,0,
307,17,193,2,0,
307,17,197,1,9,
307,17,203,1,12,
307,17,207,4,0,
307,17,213,4,0,
307,17,214,4,0,
307,17,216,4,0,
307,17,218,4,0,
307,17,223,2,0,
307,17,226,2,0,
307,17,237,1,20,
307,17,237,4,0,
307,17,240,4,0,
307,17,241,4,0,
307,17,244,1,31,
307,17,244,4,0,
307,17,247,4,0,
307,17,252,2,0,
307,17,263,4,0,
307,17,280,4,0,
307,17,290,2,0,
307,17,317,4,0,
307,17,339,4,0,
307,17,347,1,23,
307,17,347,4,0,
307,17,364,1,15,
307,17,367,1,33,
307,17,374,4,0,
307,17,379,1,36,
307,17,384,2,0,
307,17,385,2,0,
307,17,395,1,17,
307,17,398,4,0,
307,17,409,2,0,
307,17,411,4,0,
307,17,418,2,0,
307,17,427,2,0,
307,17,447,4,0,
307,17,473,4,0,
307,17,490,4,0,
307,17,496,4,0,
307,17,501,2,0,
307,17,526,4,0,
307,17,590,4,0,
307,18,7,2,0,
307,18,8,2,0,
307,18,9,2,0,
307,18,68,1,44,
307,18,92,4,0,
307,18,93,1,7,
307,18,94,4,0,
307,18,96,1,4,
307,18,104,4,0,
307,18,105,1,41,
307,18,113,4,0,
307,18,115,4,0,
307,18,117,1,1,
307,18,136,1,28,
307,18,138,4,0,
307,18,156,4,0,
307,18,157,4,0,
307,18,164,4,0,
307,18,170,1,25,
307,18,179,1,39,
307,18,182,4,0,
307,18,193,2,0,
307,18,197,1,9,
307,18,203,1,12,
307,18,207,4,0,
307,18,213,4,0,
307,18,214,4,0,
307,18,216,4,0,
307,18,218,4,0,
307,18,223,2,0,
307,18,226,2,0,
307,18,237,1,20,
307,18,237,4,0,
307,18,240,4,0,
307,18,241,4,0,
307,18,244,1,31,
307,18,244,4,0,
307,18,247,4,0,
307,18,252,2,0,
307,18,263,4,0,
307,18,280,4,0,
307,18,290,2,0,
307,18,317,4,0,
307,18,339,4,0,
307,18,347,1,23,
307,18,347,4,0,
307,18,364,1,15,
307,18,367,1,33,
307,18,374,4,0,
307,18,379,1,36,
307,18,384,2,0,
307,18,385,2,0,
307,18,395,1,17,
307,18,398,4,0,
307,18,409,2,0,
307,18,411,4,0,
307,18,418,2,0,
307,18,427,2,0,
307,18,447,4,0,
307,18,473,4,0,
307,18,490,4,0,
307,18,496,4,0,
307,18,501,2,0,
307,18,526,4,0,
307,18,590,4,0,
308,5,7,1,1,1
308,5,8,1,1,3
308,5,9,1,1,2
308,5,63,4,0,
308,5,70,4,0,
308,5,92,4,0,
308,5,93,1,1,6
308,5,93,1,9,
308,5,94,4,0,
308,5,96,1,1,5
308,5,96,1,4,
308,5,104,4,0,
308,5,105,1,54,
308,5,113,4,0,
308,5,115,4,0,
308,5,117,1,1,4
308,5,136,1,32,
308,5,148,4,0,
308,5,156,4,0,
308,5,170,1,22,
308,5,179,1,46,
308,5,182,4,0,
308,5,197,1,1,7
308,5,197,1,12,
308,5,213,4,0,
308,5,216,4,0,
308,5,218,4,0,
308,5,237,1,18,
308,5,237,4,0,
308,5,240,4,0,
308,5,241,4,0,
308,5,244,1,40,
308,5,247,4,0,
308,5,249,4,0,
308,5,263,4,0,
308,5,264,4,0,
308,5,280,4,0,
308,5,290,4,0,
308,5,317,4,0,
308,5,339,4,0,
308,5,347,1,28,
308,5,347,4,0,
308,6,5,3,0,
308,6,7,1,1,1
308,6,7,3,0,
308,6,8,1,1,3
308,6,8,3,0,
308,6,9,1,1,2
308,6,9,3,0,
308,6,25,3,0,
308,6,34,3,0,
308,6,38,3,0,
308,6,63,4,0,
308,6,68,3,0,
308,6,69,3,0,
308,6,70,4,0,
308,6,92,4,0,
308,6,93,1,1,6
308,6,93,1,9,
308,6,94,4,0,
308,6,96,1,1,5
308,6,96,1,4,
308,6,102,3,0,
308,6,104,4,0,
308,6,105,1,54,
308,6,113,4,0,
308,6,115,4,0,
308,6,117,1,1,4
308,6,118,3,0,
308,6,129,3,0,
308,6,136,1,32,
308,6,138,3,0,
308,6,148,4,0,
308,6,156,4,0,
308,6,157,3,0,
308,6,164,3,0,
308,6,170,1,22,
308,6,173,3,0,
308,6,179,1,46,
308,6,182,4,0,
308,6,189,3,0,
308,6,197,1,1,7
308,6,197,1,12,
308,6,203,3,0,
308,6,207,3,0,
308,6,213,4,0,
308,6,214,3,0,
308,6,216,4,0,
308,6,218,4,0,
308,6,223,3,0,
308,6,237,1,18,
308,6,237,4,0,
308,6,240,4,0,
308,6,241,4,0,
308,6,244,1,40,
308,6,244,3,0,
308,6,247,4,0,
308,6,249,4,0,
308,6,263,4,0,
308,6,264,4,0,
308,6,280,4,0,
308,6,290,4,0,
308,6,317,4,0,
308,6,339,4,0,
308,6,347,1,28,
308,6,347,4,0,
308,7,5,3,0,
308,7,7,1,1,1
308,7,8,1,1,3
308,7,9,1,1,2
308,7,25,3,0,
308,7,34,3,0,
308,7,38,3,0,
308,7,63,4,0,
308,7,68,3,0,
308,7,69,3,0,
308,7,70,4,0,
308,7,92,4,0,
308,7,93,1,1,6
308,7,93,1,9,
308,7,94,4,0,
308,7,96,1,1,5
308,7,96,1,4,
308,7,102,3,0,
308,7,104,4,0,
308,7,105,1,56,
308,7,113,4,0,
308,7,115,4,0,
308,7,117,1,1,4
308,7,118,3,0,
308,7,136,1,33,
308,7,138,3,0,
308,7,148,4,0,
308,7,156,4,0,
308,7,157,3,0,
308,7,164,3,0,
308,7,170,1,25,
308,7,179,1,47,
308,7,182,4,0,
308,7,197,1,1,7
308,7,197,1,12,
308,7,207,1,20,
308,7,213,4,0,
308,7,216,4,0,
308,7,218,4,0,
308,7,237,1,17,
308,7,237,4,0,
308,7,240,4,0,
308,7,241,4,0,
308,7,244,1,36,
308,7,247,4,0,
308,7,249,4,0,
308,7,263,4,0,
308,7,264,4,0,
308,7,280,4,0,
308,7,290,4,0,
308,7,317,4,0,
308,7,339,4,0,
308,7,347,1,28,
308,7,347,4,0,
308,8,7,1,1,1
308,8,8,1,1,3
308,8,9,1,1,2
308,8,63,4,0,
308,8,70,4,0,
308,8,92,4,0,
308,8,93,1,1,6
308,8,93,1,8,
308,8,94,4,0,
308,8,96,1,1,5
308,8,96,1,4,
308,8,104,4,0,
308,8,105,1,55,
308,8,113,4,0,
308,8,115,4,0,
308,8,117,1,1,4
308,8,136,1,32,
308,8,138,4,0,
308,8,148,4,0,
308,8,156,4,0,
308,8,157,4,0,
308,8,164,4,0,
308,8,170,1,18,
308,8,179,1,49,
308,8,182,4,0,
308,8,197,1,1,7
308,8,197,1,11,
308,8,203,4,0,
308,8,207,4,0,
308,8,213,4,0,
308,8,214,4,0,
308,8,216,4,0,
308,8,218,4,0,
308,8,237,1,15,
308,8,237,4,0,
308,8,240,4,0,
308,8,241,4,0,
308,8,244,1,36,
308,8,244,4,0,
308,8,247,4,0,
308,8,249,4,0,
308,8,263,4,0,
308,8,264,4,0,
308,8,278,4,0,
308,8,280,4,0,
308,8,290,4,0,
308,8,317,4,0,
308,8,339,4,0,
308,8,347,1,25,
308,8,347,4,0,
308,8,363,4,0,
308,8,364,1,22,
308,8,374,4,0,
308,8,379,1,42,
308,8,395,1,29,
308,8,398,4,0,
308,8,409,4,0,
308,8,411,4,0,
308,8,412,4,0,
308,8,416,4,0,
308,8,445,4,0,
308,8,447,4,0,
308,9,7,1,1,1
308,9,7,3,0,
308,9,8,1,1,3
308,9,8,3,0,
308,9,9,1,1,2
308,9,9,3,0,
308,9,63,4,0,
308,9,70,4,0,
308,9,92,4,0,
308,9,93,1,1,6
308,9,93,1,8,
308,9,94,4,0,
308,9,96,1,1,5
308,9,96,1,4,
308,9,104,4,0,
308,9,105,1,55,
308,9,113,4,0,
308,9,115,4,0,
308,9,117,1,1,4
308,9,129,3,0,
308,9,136,1,32,
308,9,138,4,0,
308,9,148,4,0,
308,9,156,4,0,
308,9,157,4,0,
308,9,164,4,0,
308,9,170,1,18,
308,9,173,3,0,
308,9,179,1,49,
308,9,182,4,0,
308,9,189,3,0,
308,9,197,1,1,7
308,9,197,1,11,
308,9,203,4,0,
308,9,207,4,0,
308,9,213,4,0,
308,9,214,4,0,
308,9,216,4,0,
308,9,218,4,0,
308,9,237,1,15,
308,9,237,4,0,
308,9,240,4,0,
308,9,241,4,0,
308,9,244,1,36,
308,9,244,4,0,
308,9,247,4,0,
308,9,249,4,0,
308,9,263,4,0,
308,9,264,4,0,
308,9,270,3,0,
308,9,271,3,0,
308,9,278,4,0,
308,9,280,4,0,
308,9,290,4,0,
308,9,317,4,0,
308,9,324,3,0,
308,9,339,4,0,
308,9,347,1,25,
308,9,347,4,0,
308,9,363,4,0,
308,9,364,1,22,
308,9,374,4,0,
308,9,379,1,42,
308,9,395,1,29,
308,9,398,4,0,
308,9,409,4,0,
308,9,410,3,0,
308,9,411,4,0,
308,9,412,4,0,
308,9,416,4,0,
308,9,428,3,0,
308,9,445,4,0,
308,9,447,4,0,
308,10,7,1,1,1
308,10,7,3,0,
308,10,8,1,1,3
308,10,8,3,0,
308,10,9,1,1,2
308,10,9,3,0,
308,10,29,3,0,
308,10,63,4,0,
308,10,67,3,0,
308,10,70,4,0,
308,10,92,4,0,
308,10,93,1,1,6
308,10,93,1,8,
308,10,94,4,0,
308,10,96,1,1,5
308,10,96,1,4,
308,10,104,4,0,
308,10,105,1,55,
308,10,113,4,0,
308,10,115,4,0,
308,10,117,1,1,4
308,10,129,3,0,
308,10,136,1,32,
308,10,138,4,0,
308,10,148,4,0,
308,10,156,4,0,
308,10,157,4,0,
308,10,164,4,0,
308,10,170,1,18,
308,10,173,3,0,
308,10,179,1,49,
308,10,182,4,0,
308,10,189,3,0,
308,10,197,1,1,7
308,10,197,1,11,
308,10,203,4,0,
308,10,207,4,0,
308,10,213,4,0,
308,10,214,4,0,
308,10,216,4,0,
308,10,218,4,0,
308,10,220,3,0,
308,10,237,1,15,
308,10,237,4,0,
308,10,240,4,0,
308,10,241,4,0,
308,10,244,1,36,
308,10,244,4,0,
308,10,247,4,0,
308,10,249,4,0,
308,10,263,4,0,
308,10,264,4,0,
308,10,270,3,0,
308,10,271,3,0,
308,10,272,3,0,
308,10,277,3,0,
308,10,278,4,0,
308,10,280,4,0,
308,10,290,4,0,
308,10,317,4,0,
308,10,324,3,0,
308,10,339,4,0,
308,10,347,1,25,
308,10,347,4,0,
308,10,356,3,0,
308,10,363,4,0,
308,10,364,1,22,
308,10,374,4,0,
308,10,379,1,42,
308,10,395,1,29,
308,10,398,4,0,
308,10,409,4,0,
308,10,410,3,0,
308,10,411,4,0,
308,10,412,4,0,
308,10,416,4,0,
308,10,428,3,0,
308,10,445,4,0,
308,10,447,4,0,
308,11,7,1,1,1
308,11,8,1,1,3
308,11,9,1,1,2
308,11,63,4,0,
308,11,70,4,0,
308,11,92,4,0,
308,11,93,1,1,6
308,11,93,1,8,
308,11,94,4,0,
308,11,96,1,1,5
308,11,96,1,4,
308,11,104,4,0,
308,11,105,1,62,
308,11,113,4,0,
308,11,115,4,0,
308,11,117,1,1,4
308,11,136,1,32,
308,11,138,4,0,
308,11,148,4,0,
308,11,156,4,0,
308,11,157,4,0,
308,11,164,4,0,
308,11,170,1,18,
308,11,179,1,55,
308,11,182,4,0,
308,11,197,1,1,7
308,11,197,1,11,
308,11,207,4,0,
308,11,213,4,0,
308,11,216,4,0,
308,11,218,4,0,
308,11,237,1,15,
308,11,237,4,0,
308,11,240,4,0,
308,11,241,4,0,
308,11,244,1,36,
308,11,244,4,0,
308,11,247,4,0,
308,11,249,4,0,
308,11,263,4,0,
308,11,280,4,0,
308,11,317,4,0,
308,11,339,4,0,
308,11,347,1,25,
308,11,347,4,0,
308,11,364,1,22,
308,11,367,1,42,
308,11,374,4,0,
308,11,379,1,49,
308,11,395,1,29,
308,11,398,4,0,
308,11,411,4,0,
308,11,412,4,0,
308,11,416,4,0,
308,11,447,4,0,
308,11,473,4,0,
308,11,477,4,0,
308,11,490,4,0,
308,11,496,4,0,
308,11,514,4,0,
308,11,526,4,0,
308,12,7,1,1,1
308,12,8,1,1,3
308,12,9,1,1,2
308,12,63,4,0,
308,12,70,4,0,
308,12,92,4,0,
308,12,93,1,1,6
308,12,93,1,9,
308,12,94,4,0,
308,12,96,1,1,5
308,12,96,1,4,
308,12,104,4,0,
308,12,105,1,54,
308,12,113,4,0,
308,12,115,4,0,
308,12,117,1,1,4
308,12,136,1,32,
308,12,148,4,0,
308,12,156,4,0,
308,12,170,1,22,
308,12,179,1,46,
308,12,182,4,0,
308,12,197,1,1,7
308,12,197,1,12,
308,12,213,4,0,
308,12,216,4,0,
308,12,218,4,0,
308,12,237,1,18,
308,12,237,4,0,
308,12,240,4,0,
308,12,241,4,0,
308,12,244,1,40,
308,12,247,4,0,
308,12,249,4,0,
308,12,263,4,0,
308,12,264,4,0,
308,12,280,4,0,
308,12,290,4,0,
308,12,317,4,0,
308,12,339,4,0,
308,12,347,1,28,
308,12,347,4,0,
308,13,7,1,1,1
308,13,8,1,1,3
308,13,9,1,1,2
308,13,34,3,0,
308,13,38,3,0,
308,13,63,4,0,
308,13,69,3,0,
308,13,70,4,0,
308,13,92,4,0,
308,13,93,1,1,6
308,13,93,1,9,
308,13,94,4,0,
308,13,96,1,1,5
308,13,96,1,4,
308,13,102,3,0,
308,13,104,4,0,
308,13,105,1,54,
308,13,113,4,0,
308,13,115,4,0,
308,13,117,1,1,4
308,13,136,1,32,
308,13,138,3,0,
308,13,148,4,0,
308,13,156,4,0,
308,13,164,3,0,
308,13,170,1,22,
308,13,179,1,46,
308,13,182,4,0,
308,13,197,1,1,7
308,13,197,1,12,
308,13,207,3,0,
308,13,213,4,0,
308,13,216,4,0,
308,13,218,4,0,
308,13,237,1,18,
308,13,237,4,0,
308,13,240,4,0,
308,13,241,4,0,
308,13,244,1,40,
308,13,247,4,0,
308,13,249,4,0,
308,13,263,4,0,
308,13,264,4,0,
308,13,280,4,0,
308,13,290,4,0,
308,13,317,4,0,
308,13,339,4,0,
308,13,347,1,28,
308,13,347,4,0,
308,14,7,1,1,1
308,14,7,3,0,
308,14,8,1,1,3
308,14,8,3,0,
308,14,9,1,1,2
308,14,9,3,0,
308,14,63,4,0,
308,14,67,3,0,
308,14,70,4,0,
308,14,92,4,0,
308,14,93,1,1,6
308,14,93,1,8,
308,14,94,4,0,
308,14,96,1,1,5
308,14,96,1,4,
308,14,104,4,0,
308,14,105,1,62,
308,14,113,4,0,
308,14,115,4,0,
308,14,117,1,1,4
308,14,136,1,32,
308,14,138,4,0,
308,14,148,4,0,
308,14,156,4,0,
308,14,157,4,0,
308,14,164,4,0,
308,14,170,1,18,
308,14,173,3,0,
308,14,179,1,55,
308,14,182,4,0,
308,14,197,1,1,7
308,14,197,1,11,
308,14,207,4,0,
308,14,213,4,0,
308,14,214,3,0,
308,14,216,4,0,
308,14,218,4,0,
308,14,220,3,0,
308,14,237,1,15,
308,14,237,4,0,
308,14,240,4,0,
308,14,241,4,0,
308,14,244,1,36,
308,14,244,4,0,
308,14,247,4,0,
308,14,249,4,0,
308,14,263,4,0,
308,14,270,3,0,
308,14,271,3,0,
308,14,272,3,0,
308,14,277,3,0,
308,14,278,3,0,
308,14,280,4,0,
308,14,317,4,0,
308,14,324,3,0,
308,14,339,4,0,
308,14,347,1,25,
308,14,347,4,0,
308,14,356,3,0,
308,14,364,1,22,
308,14,367,1,42,
308,14,374,4,0,
308,14,379,1,49,
308,14,395,1,29,
308,14,398,4,0,
308,14,409,3,0,
308,14,411,4,0,
308,14,412,4,0,
308,14,416,4,0,
308,14,428,3,0,
308,14,447,4,0,
308,14,473,4,0,
308,14,477,4,0,
308,14,490,4,0,
308,14,496,4,0,
308,14,514,4,0,
308,14,526,4,0,
308,15,7,1,1,2
308,15,8,1,1,4
308,15,9,1,1,3
308,15,63,4,0,
308,15,70,4,0,
308,15,92,4,0,
308,15,93,1,1,7
308,15,93,1,8,
308,15,94,4,0,
308,15,96,1,1,6
308,15,96,1,4,
308,15,104,4,0,
308,15,105,1,62,
308,15,113,4,0,
308,15,115,4,0,
308,15,117,1,1,5
308,15,136,1,32,
308,15,138,4,0,
308,15,148,4,0,
308,15,156,4,0,
308,15,157,4,0,
308,15,164,4,0,
308,15,170,1,18,
308,15,179,1,55,
308,15,182,4,0,
308,15,197,1,1,8
308,15,197,1,11,
308,15,207,4,0,
308,15,213,4,0,
308,15,214,4,0,
308,15,216,4,0,
308,15,218,4,0,
308,15,237,1,15,
308,15,237,4,0,
308,15,240,4,0,
308,15,241,4,0,
308,15,244,1,36,
308,15,244,4,0,
308,15,247,4,0,
308,15,249,4,0,
308,15,263,4,0,
308,15,280,4,0,
308,15,317,4,0,
308,15,339,4,0,
308,15,347,1,25,
308,15,347,4,0,
308,15,364,1,22,
308,15,367,1,42,
308,15,374,4,0,
308,15,379,1,49,
308,15,395,1,29,
308,15,398,4,0,
308,15,411,4,0,
308,15,412,4,0,
308,15,416,4,0,
308,15,428,1,1,1
308,15,447,4,0,
308,15,473,4,0,
308,15,490,4,0,
308,15,496,4,0,
308,15,514,4,0,
308,15,590,4,0,
308,15,612,4,0,
308,16,7,1,1,2
308,16,7,3,0,
308,16,8,1,1,4
308,16,8,3,0,
308,16,9,1,1,3
308,16,9,3,0,
308,16,63,4,0,
308,16,67,3,0,
308,16,68,1,53,1
308,16,70,4,0,
308,16,92,4,0,
308,16,93,1,1,7
308,16,93,1,7,1
308,16,94,4,0,
308,16,96,1,1,6
308,16,96,1,4,1
308,16,104,4,0,
308,16,105,1,47,1
308,16,113,4,0,
308,16,115,4,0,
308,16,117,1,1,5
308,16,136,1,28,1
308,16,138,4,0,
308,16,148,4,0,
308,16,156,4,0,
308,16,157,4,0,
308,16,164,4,0,
308,16,170,1,25,1
308,16,173,3,0,
308,16,179,1,42,1
308,16,182,4,0,
308,16,197,1,1,8
308,16,197,1,9,1
308,16,203,1,12,1
308,16,207,4,0,
308,16,213,4,0,
308,16,214,4,0,
308,16,216,4,0,
308,16,218,4,0,
308,16,220,3,0,
308,16,237,1,20,1
308,16,237,4,0,
308,16,240,4,0,
308,16,241,4,0,
308,16,244,1,31,1
308,16,244,4,0,
308,16,247,4,0,
308,16,249,4,0,
308,16,263,4,0,
308,16,264,3,0,
308,16,270,3,0,
308,16,271,3,0,
308,16,272,3,0,
308,16,277,3,0,
308,16,278,3,0,
308,16,280,4,0,
308,16,290,4,0,
308,16,317,4,0,
308,16,324,3,0,
308,16,339,4,0,
308,16,347,1,23,1
308,16,347,4,0,
308,16,356,3,0,
308,16,364,1,15,1
308,16,367,1,33,1
308,16,374,4,0,
308,16,379,1,36,1
308,16,395,1,17,1
308,16,398,4,0,
308,16,409,3,0,
308,16,411,4,0,
308,16,412,4,0,
308,16,416,4,0,
308,16,428,1,1,1
308,16,428,3,0,
308,16,447,4,0,
308,16,473,4,0,
308,16,490,4,0,
308,16,496,4,0,
308,16,514,4,0,
308,16,590,4,0,
308,16,612,4,0,
308,17,7,1,1,2
308,17,8,1,1,4
308,17,9,1,1,3
308,17,63,4,0,
308,17,68,1,53,
308,17,92,4,0,
308,17,93,1,1,7
308,17,93,1,7,
308,17,94,4,0,
308,17,96,1,1,6
308,17,96,1,4,
308,17,104,4,0,
308,17,105,1,47,
308,17,113,4,0,
308,17,115,4,0,
308,17,117,1,1,5
308,17,136,1,28,
308,17,138,4,0,
308,17,156,4,0,
308,17,157,4,0,
308,17,164,4,0,
308,17,170,1,25,
308,17,179,1,42,
308,17,182,4,0,
308,17,197,1,1,8
308,17,197,1,9,
308,17,203,1,12,
308,17,207,4,0,
308,17,213,4,0,
308,17,214,4,0,
308,17,216,4,0,
308,17,218,4,0,
308,17,237,1,20,
308,17,237,4,0,
308,17,240,4,0,
308,17,241,4,0,
308,17,244,1,31,
308,17,244,4,0,
308,17,247,4,0,
308,17,263,4,0,
308,17,280,4,0,
308,17,317,4,0,
308,17,339,4,0,
308,17,347,1,23,
308,17,347,4,0,
308,17,364,1,15,
308,17,367,1,33,
308,17,374,4,0,
308,17,379,1,36,
308,17,395,1,17,
308,17,398,4,0,
308,17,411,4,0,
308,17,412,4,0,
308,17,416,4,0,
308,17,428,1,1,1
308,17,447,4,0,
308,17,473,4,0,
308,17,490,4,0,
308,17,496,4,0,
308,17,526,4,0,
308,17,590,4,0,
308,18,7,1,1,2
308,18,8,1,1,4
308,18,9,1,1,3
308,18,63,4,0,
308,18,68,1,53,
308,18,92,4,0,
308,18,93,1,1,7
308,18,93,1,7,
308,18,94,4,0,
308,18,96,1,1,6
308,18,96,1,4,
308,18,104,4,0,
308,18,105,1,47,
308,18,113,4,0,
308,18,115,4,0,
308,18,117,1,1,5
308,18,136,1,28,
308,18,138,4,0,
308,18,156,4,0,
308,18,157,4,0,
308,18,164,4,0,
308,18,170,1,25,
308,18,179,1,42,
308,18,182,4,0,
308,18,197,1,1,8
308,18,197,1,9,
308,18,203,1,12,
308,18,207,4,0,
308,18,213,4,0,
308,18,214,4,0,
308,18,216,4,0,
308,18,218,4,0,
308,18,237,1,20,
308,18,237,4,0,
308,18,240,4,0,
308,18,241,4,0,
308,18,244,1,31,
308,18,244,4,0,
308,18,247,4,0,
308,18,263,4,0,
308,18,280,4,0,
308,18,317,4,0,
308,18,339,4,0,
308,18,347,1,23,
308,18,347,4,0,
308,18,364,1,15,
308,18,367,1,33,
308,18,374,4,0,
308,18,379,1,36,
308,18,395,1,17,
308,18,398,4,0,
308,18,411,4,0,
308,18,412,4,0,
308,18,416,4,0,
308,18,428,1,1,1
308,18,447,4,0,
308,18,473,4,0,
308,18,490,4,0,
308,18,496,4,0,
308,18,526,4,0,
308,18,590,4,0,
309,5,29,2,0,
309,5,33,1,1,
309,5,43,1,9,
309,5,44,1,33,
309,5,46,1,28,
309,5,46,4,0,
309,5,70,4,0,
309,5,85,4,0,
309,5,86,1,4,
309,5,87,1,36,
309,5,87,4,0,
309,5,92,4,0,
309,5,98,1,17,
309,5,104,4,0,
309,5,129,2,0,
309,5,148,4,0,
309,5,156,4,0,
309,5,168,4,0,
309,5,174,2,0,
309,5,182,4,0,
309,5,209,1,20,
309,5,213,4,0,
309,5,216,4,0,
309,5,218,4,0,
309,5,231,4,0,
309,5,237,4,0,
309,5,240,4,0,
309,5,242,2,0,
309,5,253,2,0,
309,5,263,4,0,
309,5,268,1,41,
309,5,290,4,0,
309,5,316,1,25,
309,5,336,1,12,
309,5,351,4,0,
309,6,29,2,0,
309,6,33,1,1,
309,6,34,3,0,
309,6,38,3,0,
309,6,43,1,9,
309,6,44,1,33,
309,6,46,1,28,
309,6,46,4,0,
309,6,70,4,0,
309,6,85,4,0,
309,6,86,1,4,
309,6,86,3,0,
309,6,87,1,36,
309,6,87,4,0,
309,6,92,4,0,
309,6,98,1,17,
309,6,102,3,0,
309,6,104,4,0,
309,6,129,2,0,
309,6,129,3,0,
309,6,148,4,0,
309,6,156,4,0,
309,6,164,3,0,
309,6,168,4,0,
309,6,173,3,0,
309,6,174,2,0,
309,6,182,4,0,
309,6,189,3,0,
309,6,203,3,0,
309,6,207,3,0,
309,6,209,1,20,
309,6,213,4,0,
309,6,214,3,0,
309,6,216,4,0,
309,6,218,4,0,
309,6,231,4,0,
309,6,237,4,0,
309,6,240,4,0,
309,6,242,2,0,
309,6,253,2,0,
309,6,263,4,0,
309,6,268,1,41,
309,6,290,4,0,
309,6,316,1,25,
309,6,336,1,12,
309,6,351,4,0,
309,7,29,2,0,
309,7,33,1,1,
309,7,34,3,0,
309,7,38,3,0,
309,7,43,1,9,
309,7,44,1,33,
309,7,46,1,28,
309,7,46,4,0,
309,7,70,4,0,
309,7,85,4,0,
309,7,86,1,4,
309,7,86,3,0,
309,7,87,1,36,
309,7,87,4,0,
309,7,92,4,0,
309,7,98,1,17,
309,7,102,3,0,
309,7,104,4,0,
309,7,129,2,0,
309,7,148,4,0,
309,7,156,4,0,
309,7,164,3,0,
309,7,168,4,0,
309,7,174,2,0,
309,7,182,4,0,
309,7,209,1,20,
309,7,213,4,0,
309,7,216,4,0,
309,7,218,4,0,
309,7,231,4,0,
309,7,237,4,0,
309,7,240,4,0,
309,7,242,2,0,
309,7,253,2,0,
309,7,263,4,0,
309,7,268,1,41,
309,7,290,4,0,
309,7,316,1,25,
309,7,336,1,12,
309,7,351,4,0,
309,8,29,2,0,
309,8,33,1,1,
309,8,43,1,9,
309,8,44,1,28,
309,8,46,1,36,
309,8,46,4,0,
309,8,53,4,0,
309,8,70,4,0,
309,8,85,4,0,
309,8,86,1,4,
309,8,86,4,0,
309,8,87,1,49,
309,8,87,4,0,
309,8,92,4,0,
309,8,98,1,17,
309,8,104,4,0,
309,8,113,4,0,
309,8,129,2,0,
309,8,148,4,0,
309,8,156,4,0,
309,8,164,4,0,
309,8,168,4,0,
309,8,174,2,0,
309,8,182,4,0,
309,8,203,4,0,
309,8,207,4,0,
309,8,209,1,20,
309,8,213,4,0,
309,8,214,4,0,
309,8,216,4,0,
309,8,218,4,0,
309,8,231,4,0,
309,8,237,4,0,
309,8,240,4,0,
309,8,242,2,0,
309,8,253,2,0,
309,8,263,4,0,
309,8,268,1,44,
309,8,290,4,0,
309,8,316,1,25,
309,8,336,1,12,
309,8,351,4,0,
309,8,363,4,0,
309,8,422,1,33,
309,8,422,2,0,
309,8,423,2,0,
309,8,424,2,0,
309,8,435,1,41,
309,8,435,2,0,
309,8,445,4,0,
309,8,451,4,0,
309,9,29,2,0,
309,9,33,1,1,
309,9,43,1,9,
309,9,44,1,28,
309,9,46,1,36,
309,9,46,4,0,
309,9,53,4,0,
309,9,70,4,0,
309,9,85,4,0,
309,9,86,1,4,
309,9,86,4,0,
309,9,87,1,49,
309,9,87,4,0,
309,9,92,4,0,
309,9,98,1,17,
309,9,104,4,0,
309,9,113,4,0,
309,9,129,2,0,
309,9,129,3,0,
309,9,148,4,0,
309,9,156,4,0,
309,9,164,4,0,
309,9,168,4,0,
309,9,173,3,0,
309,9,174,2,0,
309,9,182,4,0,
309,9,189,3,0,
309,9,203,4,0,
309,9,207,4,0,
309,9,209,1,20,
309,9,213,4,0,
309,9,214,4,0,
309,9,216,4,0,
309,9,218,4,0,
309,9,231,4,0,
309,9,237,4,0,
309,9,240,4,0,
309,9,242,2,0,
309,9,253,2,0,
309,9,263,4,0,
309,9,268,1,44,
309,9,290,4,0,
309,9,316,1,25,
309,9,324,3,0,
309,9,336,1,12,
309,9,351,4,0,
309,9,363,4,0,
309,9,393,3,0,
309,9,422,1,33,
309,9,422,2,0,
309,9,423,2,0,
309,9,424,2,0,
309,9,435,1,41,
309,9,435,2,0,
309,9,445,4,0,
309,9,451,4,0,
309,10,29,2,0,
309,10,29,3,0,
309,10,33,1,1,
309,10,43,1,9,
309,10,44,1,28,
309,10,46,1,36,
309,10,46,4,0,
309,10,53,4,0,
309,10,70,4,0,
309,10,85,4,0,
309,10,86,1,4,
309,10,86,4,0,
309,10,87,1,49,
309,10,87,4,0,
309,10,92,4,0,
309,10,98,1,17,
309,10,104,4,0,
309,10,113,4,0,
309,10,129,2,0,
309,10,129,3,0,
309,10,148,4,0,
309,10,156,4,0,
309,10,164,4,0,
309,10,168,4,0,
309,10,173,3,0,
309,10,174,2,0,
309,10,182,4,0,
309,10,189,3,0,
309,10,203,4,0,
309,10,207,4,0,
309,10,209,1,20,
309,10,213,4,0,
309,10,214,4,0,
309,10,216,4,0,
309,10,218,4,0,
309,10,231,4,0,
309,10,237,4,0,
309,10,240,4,0,
309,10,242,2,0,
309,10,253,2,0,
309,10,263,4,0,
309,10,268,1,44,
309,10,290,4,0,
309,10,316,1,25,
309,10,324,3,0,
309,10,336,1,12,
309,10,351,4,0,
309,10,363,4,0,
309,10,393,3,0,
309,10,415,2,0,
309,10,422,1,33,
309,10,422,2,0,
309,10,423,2,0,
309,10,424,2,0,
309,10,435,1,41,
309,10,435,2,0,
309,10,445,4,0,
309,10,451,4,0,
309,11,29,2,0,
309,11,33,1,1,
309,11,43,1,9,
309,11,44,1,28,
309,11,46,1,36,
309,11,46,4,0,
309,11,53,4,0,
309,11,70,4,0,
309,11,85,4,0,
309,11,86,1,4,
309,11,86,4,0,
309,11,87,1,52,
309,11,87,4,0,
309,11,92,4,0,
309,11,98,1,17,
309,11,104,4,0,
309,11,113,4,0,
309,11,129,2,0,
309,11,148,4,0,
309,11,156,4,0,
309,11,164,4,0,
309,11,168,4,0,
309,11,174,2,0,
309,11,182,4,0,
309,11,207,4,0,
309,11,209,1,20,
309,11,213,4,0,
309,11,216,4,0,
309,11,218,4,0,
309,11,237,4,0,
309,11,240,4,0,
309,11,242,2,0,
309,11,253,2,0,
309,11,263,4,0,
309,11,268,1,44,
309,11,316,1,25,
309,11,336,1,12,
309,11,351,2,0,
309,11,415,2,0,
309,11,422,1,33,
309,11,422,2,0,
309,11,423,2,0,
309,11,424,2,0,
309,11,435,1,41,
309,11,435,2,0,
309,11,451,4,0,
309,11,481,2,0,
309,11,486,2,0,
309,11,496,4,0,
309,11,521,4,0,
309,11,528,1,49,
309,11,528,4,0,
309,11,555,4,0,
309,12,33,1,1,
309,12,43,1,9,
309,12,44,1,33,
309,12,46,1,28,
309,12,46,4,0,
309,12,70,4,0,
309,12,85,4,0,
309,12,86,1,4,
309,12,87,1,36,
309,12,87,4,0,
309,12,92,4,0,
309,12,98,1,17,
309,12,104,4,0,
309,12,148,4,0,
309,12,156,4,0,
309,12,168,4,0,
309,12,182,4,0,
309,12,209,1,20,
309,12,213,4,0,
309,12,216,4,0,
309,12,218,4,0,
309,12,231,4,0,
309,12,237,4,0,
309,12,240,4,0,
309,12,263,4,0,
309,12,268,1,41,
309,12,290,4,0,
309,12,316,1,25,
309,12,336,1,12,
309,12,351,4,0,
309,13,33,1,1,
309,13,34,3,0,
309,13,38,3,0,
309,13,43,1,9,
309,13,44,1,33,
309,13,46,1,28,
309,13,46,4,0,
309,13,70,4,0,
309,13,85,4,0,
309,13,86,1,4,
309,13,86,3,0,
309,13,87,1,36,
309,13,87,4,0,
309,13,92,4,0,
309,13,98,1,17,
309,13,102,3,0,
309,13,104,4,0,
309,13,148,4,0,
309,13,156,4,0,
309,13,164,3,0,
309,13,168,4,0,
309,13,182,4,0,
309,13,207,3,0,
309,13,209,1,20,
309,13,213,4,0,
309,13,216,4,0,
309,13,218,4,0,
309,13,231,4,0,
309,13,237,4,0,
309,13,240,4,0,
309,13,263,4,0,
309,13,268,1,41,
309,13,290,4,0,
309,13,316,1,25,
309,13,336,1,12,
309,13,351,4,0,
309,14,29,2,0,
309,14,33,1,1,
309,14,43,1,9,
309,14,44,1,28,
309,14,46,1,36,
309,14,46,4,0,
309,14,53,4,0,
309,14,70,4,0,
309,14,85,4,0,
309,14,86,1,4,
309,14,86,4,0,
309,14,87,1,52,
309,14,87,4,0,
309,14,92,4,0,
309,14,98,1,17,
309,14,104,4,0,
309,14,113,4,0,
309,14,129,2,0,
309,14,148,4,0,
309,14,156,4,0,
309,14,164,4,0,
309,14,168,4,0,
309,14,173,3,0,
309,14,174,2,0,
309,14,182,4,0,
309,14,207,4,0,
309,14,209,1,20,
309,14,213,4,0,
309,14,214,3,0,
309,14,216,4,0,
309,14,218,4,0,
309,14,231,3,0,
309,14,237,4,0,
309,14,240,4,0,
309,14,242,2,0,
309,14,253,2,0,
309,14,253,3,0,
309,14,263,4,0,
309,14,268,1,44,
309,14,316,1,25,
309,14,324,3,0,
309,14,336,1,12,
309,14,351,2,0,
309,14,393,3,0,
309,14,415,2,0,
309,14,422,1,33,
309,14,422,2,0,
309,14,423,2,0,
309,14,424,2,0,
309,14,435,1,41,
309,14,435,2,0,
309,14,451,4,0,
309,14,481,2,0,
309,14,486,2,0,
309,14,496,4,0,
309,14,521,4,0,
309,14,528,1,49,
309,14,528,4,0,
309,14,555,4,0,
309,15,29,2,0,
309,15,33,1,1,
309,15,43,1,9,
309,15,44,1,28,
309,15,46,1,36,
309,15,46,4,0,
309,15,53,4,0,
309,15,70,4,0,
309,15,85,4,0,
309,15,86,1,4,
309,15,86,4,0,
309,15,87,1,52,
309,15,87,4,0,
309,15,92,4,0,
309,15,98,1,17,
309,15,104,4,0,
309,15,113,4,0,
309,15,129,2,0,
309,15,148,4,0,
309,15,156,4,0,
309,15,164,4,0,
309,15,168,4,0,
309,15,174,2,0,
309,15,182,4,0,
309,15,207,4,0,
309,15,209,1,20,
309,15,213,4,0,
309,15,214,4,0,
309,15,216,4,0,
309,15,218,4,0,
309,15,237,4,0,
309,15,240,4,0,
309,15,242,2,0,
309,15,253,2,0,
309,15,263,4,0,
309,15,268,1,44,
309,15,316,1,25,
309,15,336,1,12,
309,15,351,2,0,
309,15,415,2,0,
309,15,422,1,33,
309,15,422,2,0,
309,15,423,2,0,
309,15,424,2,0,
309,15,435,1,41,
309,15,435,2,0,
309,15,451,4,0,
309,15,481,2,0,
309,15,486,2,0,
309,15,496,4,0,
309,15,521,4,0,
309,15,528,1,49,
309,15,528,4,0,
309,15,555,4,0,
309,15,590,4,0,
309,15,598,2,0,
309,16,29,2,0,
309,16,33,1,1,1
309,16,43,1,4,1
309,16,44,1,24,1
309,16,46,1,34,1
309,16,46,4,0,
309,16,53,4,0,
309,16,70,4,0,
309,16,85,4,0,
309,16,86,1,1,2
309,16,86,4,0,
309,16,87,1,49,1
309,16,87,4,0,
309,16,92,4,0,
309,16,98,1,10,1
309,16,104,4,0,
309,16,113,4,0,
309,16,129,2,0,
309,16,148,4,0,
309,16,156,4,0,
309,16,164,4,0,
309,16,168,4,0,
309,16,173,3,0,
309,16,174,2,0,
309,16,182,4,0,
309,16,207,4,0,
309,16,209,1,13,1
309,16,213,4,0,
309,16,214,4,0,
309,16,216,4,0,
309,16,218,4,0,
309,16,231,3,0,
309,16,237,4,0,
309,16,240,4,0,
309,16,242,2,0,
309,16,253,2,0,
309,16,253,3,0,
309,16,263,4,0,
309,16,268,1,44,1
309,16,290,4,0,
309,16,316,1,16,1
309,16,324,3,0,
309,16,336,1,7,1
309,16,351,2,0,
309,16,351,3,0,
309,16,393,3,0,
309,16,415,2,0,
309,16,422,1,19,1
309,16,422,2,0,
309,16,423,2,0,
309,16,424,2,0,
309,16,435,1,29,1
309,16,435,2,0,
309,16,451,4,0,
309,16,481,2,0,
309,16,486,2,0,
309,16,496,4,0,
309,16,521,4,0,
309,16,528,1,39,1
309,16,528,4,0,
309,16,555,4,0,
309,16,590,4,0,
309,16,598,2,0,
309,17,29,2,0,
309,17,33,1,1,1
309,17,43,1,4,
309,17,44,1,24,
309,17,46,1,34,
309,17,46,4,0,
309,17,53,4,0,
309,17,85,4,0,
309,17,86,1,1,2
309,17,86,4,0,
309,17,87,1,49,
309,17,87,4,0,
309,17,92,4,0,
309,17,98,1,10,
309,17,104,4,0,
309,17,113,4,0,
309,17,129,2,0,
309,17,156,4,0,
309,17,164,4,0,
309,17,168,4,0,
309,17,174,2,0,
309,17,182,4,0,
309,17,207,4,0,
309,17,209,1,13,
309,17,213,4,0,
309,17,214,4,0,
309,17,216,4,0,
309,17,218,4,0,
309,17,237,4,0,
309,17,240,4,0,
309,17,242,2,0,
309,17,253,2,0,
309,17,263,4,0,
309,17,268,1,44,
309,17,316,1,16,
309,17,336,1,7,
309,17,351,2,0,
309,17,415,2,0,
309,17,422,1,19,
309,17,422,2,0,
309,17,423,2,0,
309,17,424,2,0,
309,17,435,1,29,
309,17,435,2,0,
309,17,451,4,0,
309,17,481,2,0,
309,17,486,2,0,
309,17,496,4,0,
309,17,521,4,0,
309,17,528,1,39,
309,17,528,4,0,
309,17,555,4,0,
309,17,590,4,0,
309,17,598,2,0,
309,18,29,2,0,
309,18,33,1,1,1
309,18,43,1,4,
309,18,44,1,24,
309,18,46,1,34,
309,18,46,4,0,
309,18,53,4,0,
309,18,85,4,0,
309,18,86,1,1,2
309,18,86,4,0,
309,18,87,1,49,
309,18,87,4,0,
309,18,92,4,0,
309,18,98,1,10,
309,18,104,4,0,
309,18,113,4,0,
309,18,129,2,0,
309,18,156,4,0,
309,18,164,4,0,
309,18,168,4,0,
309,18,174,2,0,
309,18,182,4,0,
309,18,207,4,0,
309,18,209,1,13,
309,18,213,4,0,
309,18,214,4,0,
309,18,216,4,0,
309,18,218,4,0,
309,18,237,4,0,
309,18,240,4,0,
309,18,242,2,0,
309,18,253,2,0,
309,18,263,4,0,
309,18,268,1,44,
309,18,316,1,16,
309,18,336,1,7,
309,18,351,2,0,
309,18,415,2,0,
309,18,422,1,19,
309,18,422,2,0,
309,18,423,2,0,
309,18,424,2,0,
309,18,435,1,29,
309,18,435,2,0,
309,18,451,4,0,
309,18,481,2,0,
309,18,486,2,0,
309,18,496,4,0,
309,18,521,4,0,
309,18,528,1,39,
309,18,528,4,0,
309,18,555,4,0,
309,18,590,4,0,
309,18,598,2,0,
310,5,33,1,1,1
310,5,43,1,1,3
310,5,43,1,9,
310,5,44,1,39,
310,5,46,1,31,
310,5,46,4,0,
310,5,63,4,0,
310,5,70,4,0,
310,5,85,4,0,
310,5,86,1,1,2
310,5,86,1,4,
310,5,87,1,45,
310,5,87,4,0,
310,5,92,4,0,
310,5,98,1,17,
310,5,104,4,0,
310,5,148,4,0,
310,5,156,4,0,
310,5,168,4,0,
310,5,182,4,0,
310,5,209,1,20,
310,5,213,4,0,
310,5,216,4,0,
310,5,218,4,0,
310,5,231,4,0,
310,5,237,4,0,
310,5,240,4,0,
310,5,263,4,0,
310,5,268,1,53,
310,5,290,4,0,
310,5,316,1,25,
310,5,336,1,1,4
310,5,336,1,12,
310,5,351,4,0,
310,6,33,1,1,1
310,6,34,3,0,
310,6,38,3,0,
310,6,43,1,1,3
310,6,43,1,9,
310,6,44,1,39,
310,6,46,1,31,
310,6,46,4,0,
310,6,63,4,0,
310,6,70,4,0,
310,6,85,4,0,
310,6,86,1,1,2
310,6,86,1,4,
310,6,86,3,0,
310,6,87,1,45,
310,6,87,4,0,
310,6,92,4,0,
310,6,98,1,17,
310,6,102,3,0,
310,6,104,4,0,
310,6,129,3,0,
310,6,148,4,0,
310,6,156,4,0,
310,6,164,3,0,
310,6,168,4,0,
310,6,173,3,0,
310,6,182,4,0,
310,6,189,3,0,
310,6,203,3,0,
310,6,207,3,0,
310,6,209,1,20,
310,6,213,4,0,
310,6,214,3,0,
310,6,216,4,0,
310,6,218,4,0,
310,6,231,4,0,
310,6,237,4,0,
310,6,240,4,0,
310,6,263,4,0,
310,6,268,1,53,
310,6,290,4,0,
310,6,316,1,25,
310,6,336,1,1,4
310,6,336,1,12,
310,6,351,4,0,
310,7,33,1,1,1
310,7,34,3,0,
310,7,38,3,0,
310,7,43,1,1,3
310,7,43,1,9,
310,7,44,1,39,
310,7,46,1,31,
310,7,46,4,0,
310,7,63,4,0,
310,7,70,4,0,
310,7,85,4,0,
310,7,86,1,1,2
310,7,86,1,4,
310,7,86,3,0,
310,7,87,1,45,
310,7,87,4,0,
310,7,92,4,0,
310,7,98,1,17,
310,7,102,3,0,
310,7,104,4,0,
310,7,148,4,0,
310,7,156,4,0,
310,7,164,3,0,
310,7,168,4,0,
310,7,182,4,0,
310,7,209,1,20,
310,7,213,4,0,
310,7,216,4,0,
310,7,218,4,0,
310,7,231,4,0,
310,7,237,4,0,
310,7,240,4,0,
310,7,263,4,0,
310,7,268,1,53,
310,7,290,4,0,
310,7,316,1,25,
310,7,336,1,1,4
310,7,336,1,12,
310,7,351,4,0,
310,8,33,1,1,2
310,8,43,1,1,4
310,8,43,1,9,
310,8,44,1,30,
310,8,46,1,42,
310,8,46,4,0,
310,8,53,4,0,
310,8,63,4,0,
310,8,70,4,0,
310,8,85,4,0,
310,8,86,1,1,3
310,8,86,1,4,
310,8,86,4,0,
310,8,87,1,61,
310,8,87,4,0,
310,8,92,4,0,
310,8,98,1,17,
310,8,104,4,0,
310,8,113,4,0,
310,8,148,4,0,
310,8,156,4,0,
310,8,164,4,0,
310,8,168,4,0,
310,8,182,4,0,
310,8,203,4,0,
310,8,207,4,0,
310,8,209,1,20,
310,8,213,4,0,
310,8,214,4,0,
310,8,216,4,0,
310,8,218,4,0,
310,8,231,4,0,
310,8,237,4,0,
310,8,240,4,0,
310,8,263,4,0,
310,8,268,1,54,
310,8,290,4,0,
310,8,315,4,0,
310,8,316,1,25,
310,8,336,1,1,5
310,8,336,1,12,
310,8,351,4,0,
310,8,363,4,0,
310,8,416,4,0,
310,8,422,1,37,
310,8,424,1,1,1
310,8,435,1,49,
310,8,445,4,0,
310,8,451,4,0,
310,9,33,1,1,2
310,9,43,1,1,4
310,9,43,1,9,
310,9,44,1,30,
310,9,46,1,42,
310,9,46,4,0,
310,9,53,4,0,
310,9,63,4,0,
310,9,70,4,0,
310,9,85,4,0,
310,9,86,1,1,3
310,9,86,1,4,
310,9,86,4,0,
310,9,87,1,61,
310,9,87,4,0,
310,9,92,4,0,
310,9,98,1,17,
310,9,104,4,0,
310,9,113,4,0,
310,9,129,3,0,
310,9,148,4,0,
310,9,156,4,0,
310,9,164,4,0,
310,9,168,4,0,
310,9,173,3,0,
310,9,182,4,0,
310,9,189,3,0,
310,9,203,4,0,
310,9,207,4,0,
310,9,209,1,20,
310,9,213,4,0,
310,9,214,4,0,
310,9,216,4,0,
310,9,218,4,0,
310,9,231,4,0,
310,9,237,4,0,
310,9,240,4,0,
310,9,263,4,0,
310,9,268,1,54,
310,9,290,4,0,
310,9,315,4,0,
310,9,316,1,25,
310,9,324,3,0,
310,9,336,1,1,5
310,9,336,1,12,
310,9,351,4,0,
310,9,363,4,0,
310,9,393,3,0,
310,9,416,4,0,
310,9,422,1,37,
310,9,424,1,1,1
310,9,435,1,49,
310,9,445,4,0,
310,9,451,4,0,
310,10,29,3,0,
310,10,33,1,1,2
310,10,43,1,1,4
310,10,43,1,9,
310,10,44,1,30,
310,10,46,1,42,
310,10,46,4,0,
310,10,53,4,0,
310,10,63,4,0,
310,10,70,4,0,
310,10,85,4,0,
310,10,86,1,1,3
310,10,86,1,4,
310,10,86,4,0,
310,10,87,1,61,
310,10,87,4,0,
310,10,92,4,0,
310,10,98,1,17,
310,10,104,4,0,
310,10,113,4,0,
310,10,129,3,0,
310,10,148,4,0,
310,10,156,4,0,
310,10,164,4,0,
310,10,168,4,0,
310,10,173,3,0,
310,10,182,4,0,
310,10,189,3,0,
310,10,203,4,0,
310,10,207,4,0,
310,10,209,1,20,
310,10,213,4,0,
310,10,214,4,0,
310,10,216,4,0,
310,10,218,4,0,
310,10,231,4,0,
310,10,237,4,0,
310,10,240,4,0,
310,10,263,4,0,
310,10,268,1,54,
310,10,290,4,0,
310,10,315,4,0,
310,10,316,1,25,
310,10,324,3,0,
310,10,336,1,1,5
310,10,336,1,12,
310,10,351,4,0,
310,10,363,4,0,
310,10,393,3,0,
310,10,416,4,0,
310,10,422,1,37,
310,10,424,1,1,1
310,10,435,1,49,
310,10,445,4,0,
310,10,451,4,0,
310,11,33,1,1,2
310,11,43,1,1,4
310,11,43,1,9,
310,11,44,1,30,
310,11,46,1,42,
310,11,46,4,0,
310,11,53,4,0,
310,11,63,4,0,
310,11,70,4,0,
310,11,85,4,0,
310,11,86,1,1,3
310,11,86,1,4,
310,11,86,4,0,
310,11,87,1,66,
310,11,87,4,0,
310,11,92,4,0,
310,11,98,1,17,
310,11,104,4,0,
310,11,113,4,0,
310,11,148,4,0,
310,11,156,4,0,
310,11,164,4,0,
310,11,168,4,0,
310,11,182,4,0,
310,11,207,4,0,
310,11,209,1,20,
310,11,213,4,0,
310,11,216,4,0,
310,11,218,4,0,
310,11,237,4,0,
310,11,240,4,0,
310,11,263,4,0,
310,11,268,1,54,
310,11,315,4,0,
310,11,316,1,25,
310,11,336,1,1,5
310,11,336,1,12,
310,11,416,4,0,
310,11,422,1,37,
310,11,424,1,1,1
310,11,435,1,49,
310,11,451,4,0,
310,11,496,4,0,
310,11,521,4,0,
310,11,528,1,61,
310,11,528,4,0,
310,11,555,4,0,
310,12,33,1,1,1
310,12,43,1,1,3
310,12,43,1,9,
310,12,44,1,39,
310,12,46,1,31,
310,12,46,4,0,
310,12,63,4,0,
310,12,70,4,0,
310,12,85,4,0,
310,12,86,1,1,2
310,12,86,1,4,
310,12,87,1,45,
310,12,87,4,0,
310,12,92,4,0,
310,12,98,1,17,
310,12,104,4,0,
310,12,148,4,0,
310,12,156,4,0,
310,12,168,4,0,
310,12,182,4,0,
310,12,209,1,20,
310,12,213,4,0,
310,12,216,4,0,
310,12,218,4,0,
310,12,231,4,0,
310,12,237,4,0,
310,12,240,4,0,
310,12,263,4,0,
310,12,268,1,53,
310,12,290,4,0,
310,12,316,1,25,
310,12,336,1,1,4
310,12,336,1,12,
310,12,351,4,0,
310,13,33,1,1,1
310,13,34,3,0,
310,13,38,3,0,
310,13,43,1,1,3
310,13,43,1,9,
310,13,44,1,39,
310,13,46,1,31,
310,13,46,4,0,
310,13,63,4,0,
310,13,70,4,0,
310,13,85,4,0,
310,13,86,1,1,2
310,13,86,1,4,
310,13,86,3,0,
310,13,87,1,45,
310,13,87,4,0,
310,13,92,4,0,
310,13,98,1,17,
310,13,102,3,0,
310,13,104,4,0,
310,13,148,4,0,
310,13,156,4,0,
310,13,164,3,0,
310,13,168,4,0,
310,13,182,4,0,
310,13,207,3,0,
310,13,209,1,20,
310,13,213,4,0,
310,13,216,4,0,
310,13,218,4,0,
310,13,231,4,0,
310,13,237,4,0,
310,13,240,4,0,
310,13,263,4,0,
310,13,268,1,53,
310,13,290,4,0,
310,13,316,1,25,
310,13,336,1,1,4
310,13,336,1,12,
310,13,351,4,0,
310,14,33,1,1,2
310,14,43,1,1,4
310,14,43,1,9,
310,14,44,1,30,
310,14,46,1,42,
310,14,46,4,0,
310,14,53,4,0,
310,14,63,4,0,
310,14,70,4,0,
310,14,85,4,0,
310,14,86,1,1,3
310,14,86,1,4,
310,14,86,4,0,
310,14,87,1,66,
310,14,87,4,0,
310,14,92,4,0,
310,14,98,1,17,
310,14,104,4,0,
310,14,113,4,0,
310,14,148,4,0,
310,14,156,4,0,
310,14,164,4,0,
310,14,168,4,0,
310,14,173,3,0,
310,14,182,4,0,
310,14,207,4,0,
310,14,209,1,20,
310,14,213,4,0,
310,14,214,3,0,
310,14,216,4,0,
310,14,218,4,0,
310,14,231,3,0,
310,14,237,4,0,
310,14,240,4,0,
310,14,253,3,0,
310,14,263,4,0,
310,14,268,1,54,
310,14,315,4,0,
310,14,316,1,25,
310,14,324,3,0,
310,14,336,1,1,5
310,14,336,1,12,
310,14,393,3,0,
310,14,416,4,0,
310,14,422,1,37,
310,14,424,1,1,1
310,14,435,1,49,
310,14,451,4,0,
310,14,496,4,0,
310,14,521,4,0,
310,14,528,1,61,
310,14,528,4,0,
310,14,555,4,0,
310,15,33,1,1,3
310,15,43,1,1,5
310,15,43,1,9,
310,15,44,1,30,
310,15,46,1,42,
310,15,46,4,0,
310,15,53,4,0,
310,15,63,4,0,
310,15,70,4,0,
310,15,85,4,0,
310,15,86,1,1,4
310,15,86,1,4,
310,15,86,4,0,
310,15,87,1,66,
310,15,87,4,0,
310,15,92,4,0,
310,15,98,1,17,
310,15,104,4,0,
310,15,113,4,0,
310,15,148,4,0,
310,15,156,4,0,
310,15,164,4,0,
310,15,168,4,0,
310,15,182,4,0,
310,15,207,4,0,
310,15,209,1,20,
310,15,213,4,0,
310,15,214,4,0,
310,15,216,4,0,
310,15,218,4,0,
310,15,237,4,0,
310,15,240,4,0,
310,15,263,4,0,
310,15,268,1,54,
310,15,315,4,0,
310,15,316,1,25,
310,15,336,1,1,6
310,15,336,1,12,
310,15,416,4,0,
310,15,422,1,37,
310,15,424,1,1,2
310,15,435,1,49,
310,15,451,4,0,
310,15,496,4,0,
310,15,521,4,0,
310,15,528,1,61,
310,15,528,4,0,
310,15,555,4,0,
310,15,590,4,0,
310,15,604,1,1,1
310,15,604,1,70,
310,16,33,1,1,3
310,16,43,1,1,5
310,16,43,1,4,1
310,16,44,1,24,1
310,16,46,1,36,1
310,16,46,4,0,
310,16,53,4,0,
310,16,63,4,0,
310,16,70,4,0,
310,16,85,4,0,
310,16,86,1,1,4
310,16,86,4,0,
310,16,87,1,54,1
310,16,87,4,0,
310,16,92,4,0,
310,16,98,1,10,1
310,16,104,4,0,
310,16,113,4,0,
310,16,148,4,0,
310,16,156,4,0,
310,16,164,4,0,
310,16,168,4,0,
310,16,173,3,0,
310,16,182,4,0,
310,16,207,4,0,
310,16,209,1,13,1
310,16,213,4,0,
310,16,214,4,0,
310,16,216,4,0,
310,16,218,4,0,
310,16,231,3,0,
310,16,237,4,0,
310,16,240,4,0,
310,16,253,3,0,
310,16,263,4,0,
310,16,268,1,48,1
310,16,290,4,0,
310,16,315,4,0,
310,16,316,1,16,1
310,16,324,3,0,
310,16,336,1,1,6
310,16,336,1,7,1
310,16,351,3,0,
310,16,393,3,0,
310,16,416,4,0,
310,16,422,1,19,1
310,16,424,1,1,2
310,16,435,1,30,1
310,16,451,4,0,
310,16,496,4,0,
310,16,521,4,0,
310,16,528,1,42,1
310,16,528,4,0,
310,16,555,4,0,
310,16,590,4,0,
310,16,604,1,1,1
310,16,604,1,60,1
310,17,33,1,1,3
310,17,43,1,1,5
310,17,43,1,4,
310,17,44,1,24,
310,17,46,1,36,
310,17,46,4,0,
310,17,53,4,0,
310,17,63,4,0,
310,17,85,4,0,
310,17,86,1,1,4
310,17,86,4,0,
310,17,87,1,54,
310,17,87,4,0,
310,17,92,4,0,
310,17,98,1,10,
310,17,104,4,0,
310,17,113,4,0,
310,17,156,4,0,
310,17,164,4,0,
310,17,168,4,0,
310,17,182,4,0,
310,17,207,4,0,
310,17,209,1,13,
310,17,213,4,0,
310,17,214,4,0,
310,17,216,4,0,
310,17,218,4,0,
310,17,237,4,0,
310,17,240,4,0,
310,17,263,4,0,
310,17,268,1,48,
310,17,315,4,0,
310,17,316,1,16,
310,17,336,1,1,6
310,17,336,1,7,
310,17,416,4,0,
310,17,422,1,19,
310,17,424,1,1,2
310,17,435,1,30,
310,17,451,4,0,
310,17,496,4,0,
310,17,521,4,0,
310,17,528,1,42,
310,17,528,4,0,
310,17,555,4,0,
310,17,590,4,0,
310,17,604,1,1,1
310,17,604,1,60,
310,18,33,1,1,3
310,18,43,1,1,5
310,18,43,1,4,
310,18,44,1,24,
310,18,46,1,36,
310,18,46,4,0,
310,18,53,4,0,
310,18,63,4,0,
310,18,85,4,0,
310,18,86,1,1,4
310,18,86,4,0,
310,18,87,1,54,
310,18,87,4,0,
310,18,92,4,0,
310,18,98,1,10,
310,18,104,4,0,
310,18,113,4,0,
310,18,156,4,0,
310,18,164,4,0,
310,18,168,4,0,
310,18,182,4,0,
310,18,207,4,0,
310,18,209,1,13,
310,18,213,4,0,
310,18,214,4,0,
310,18,216,4,0,
310,18,218,4,0,
310,18,237,4,0,
310,18,240,4,0,
310,18,263,4,0,
310,18,268,1,48,
310,18,315,4,0,
310,18,316,1,16,
310,18,336,1,1,6
310,18,336,1,7,
310,18,416,4,0,
310,18,422,1,19,
310,18,424,1,1,2
310,18,435,1,30,
310,18,451,4,0,
310,18,496,4,0,
310,18,521,4,0,
310,18,528,1,42,
310,18,528,4,0,
310,18,555,4,0,
310,18,590,4,0,
310,18,604,1,1,1
310,18,604,1,60,
311,5,45,1,1,
311,5,85,4,0,
311,5,86,1,4,
311,5,87,1,37,
311,5,87,4,0,
311,5,92,4,0,
311,5,97,1,47,
311,5,98,1,10,
311,5,104,4,0,
311,5,113,4,0,
311,5,148,4,0,
311,5,156,4,0,
311,5,164,2,0,
311,5,182,4,0,
311,5,209,1,19,
311,5,213,4,0,
311,5,216,4,0,
311,5,218,4,0,
311,5,226,1,40,
311,5,227,1,22,
311,5,231,4,0,
311,5,237,4,0,
311,5,240,4,0,
311,5,263,4,0,
311,5,268,1,31,
311,5,270,1,13,
311,5,273,2,0,
311,5,290,4,0,
311,5,313,1,28,
311,5,351,4,0,
311,6,5,3,0,
311,6,9,3,0,
311,6,25,3,0,
311,6,34,3,0,
311,6,38,3,0,
311,6,45,1,1,
311,6,68,3,0,
311,6,69,3,0,
311,6,85,4,0,
311,6,86,1,4,
311,6,86,3,0,
311,6,87,1,37,
311,6,87,4,0,
311,6,92,4,0,
311,6,97,1,47,
311,6,98,1,10,
311,6,102,3,0,
311,6,104,4,0,
311,6,111,3,0,
311,6,113,4,0,
311,6,118,3,0,
311,6,129,3,0,
311,6,148,4,0,
311,6,156,4,0,
311,6,164,2,0,
311,6,164,3,0,
311,6,173,3,0,
311,6,182,4,0,
311,6,189,3,0,
311,6,203,3,0,
311,6,205,3,0,
311,6,207,3,0,
311,6,209,1,19,
311,6,213,4,0,
311,6,214,3,0,
311,6,216,4,0,
311,6,218,4,0,
311,6,223,3,0,
311,6,226,1,40,
311,6,227,1,22,
311,6,231,4,0,
311,6,237,4,0,
311,6,240,4,0,
311,6,263,4,0,
311,6,268,1,31,
311,6,270,1,13,
311,6,273,2,0,
311,6,290,4,0,
311,6,313,1,28,
311,6,351,4,0,
311,7,5,3,0,
311,7,25,3,0,
311,7,34,3,0,
311,7,38,3,0,
311,7,45,1,1,
311,7,68,3,0,
311,7,69,3,0,
311,7,85,4,0,
311,7,86,1,4,
311,7,86,3,0,
311,7,87,1,37,
311,7,87,4,0,
311,7,92,4,0,
311,7,97,1,47,
311,7,98,1,10,
311,7,102,3,0,
311,7,104,4,0,
311,7,113,4,0,
311,7,118,3,0,
311,7,148,4,0,
311,7,156,4,0,
311,7,164,2,0,
311,7,164,3,0,
311,7,182,4,0,
311,7,209,1,19,
311,7,213,4,0,
311,7,216,4,0,
311,7,218,4,0,
311,7,226,1,40,
311,7,227,1,22,
311,7,231,4,0,
311,7,237,4,0,
311,7,240,4,0,
311,7,263,4,0,
311,7,268,1,31,
311,7,270,1,13,
311,7,273,2,0,
311,7,290,4,0,
311,7,313,1,28,
311,7,351,4,0,
311,8,45,1,1,
311,8,47,2,0,
311,8,85,4,0,
311,8,86,1,3,
311,8,86,4,0,
311,8,87,1,38,
311,8,87,4,0,
311,8,92,4,0,
311,8,97,1,44,
311,8,98,1,7,
311,8,104,4,0,
311,8,113,4,0,
311,8,129,1,29,
311,8,148,4,0,
311,8,156,4,0,
311,8,164,2,0,
311,8,164,4,0,
311,8,182,4,0,
311,8,186,2,0,
311,8,203,4,0,
311,8,207,4,0,
311,8,209,1,15,
311,8,213,4,0,
311,8,214,4,0,
311,8,216,4,0,
311,8,218,4,0,
311,8,226,1,42,
311,8,227,1,17,
311,8,231,4,0,
311,8,237,4,0,
311,8,240,4,0,
311,8,263,4,0,
311,8,268,1,35,
311,8,270,1,10,
311,8,273,2,0,
311,8,290,4,0,
311,8,313,1,21,
311,8,313,1,31,
311,8,351,4,0,
311,8,363,4,0,
311,8,374,4,0,
311,8,383,1,24,
311,8,387,1,48,
311,8,417,1,51,
311,8,445,4,0,
311,8,447,4,0,
311,8,451,4,0,
311,9,9,3,0,
311,9,45,1,1,
311,9,47,2,0,
311,9,85,4,0,
311,9,86,1,3,
311,9,86,4,0,
311,9,87,1,38,
311,9,87,4,0,
311,9,92,4,0,
311,9,97,1,44,
311,9,98,1,7,
311,9,104,4,0,
311,9,113,4,0,
311,9,129,1,29,
311,9,129,3,0,
311,9,148,4,0,
311,9,156,4,0,
311,9,164,2,0,
311,9,164,4,0,
311,9,173,3,0,
311,9,182,4,0,
311,9,186,2,0,
311,9,189,3,0,
311,9,203,4,0,
311,9,205,3,0,
311,9,207,4,0,
311,9,209,1,15,
311,9,213,4,0,
311,9,214,4,0,
311,9,216,4,0,
311,9,218,4,0,
311,9,226,1,42,
311,9,227,1,17,
311,9,231,4,0,
311,9,237,4,0,
311,9,240,4,0,
311,9,253,3,0,
311,9,263,4,0,
311,9,268,1,35,
311,9,270,1,10,
311,9,270,3,0,
311,9,273,2,0,
311,9,290,4,0,
311,9,313,1,21,
311,9,313,1,31,
311,9,324,3,0,
311,9,351,4,0,
311,9,363,4,0,
311,9,374,4,0,
311,9,383,1,24,
311,9,387,1,48,
311,9,387,3,0,
311,9,393,3,0,
311,9,417,1,51,
311,9,445,4,0,
311,9,447,4,0,
311,9,451,4,0,
311,10,9,3,0,
311,10,29,3,0,
311,10,45,1,1,
311,10,47,2,0,
311,10,85,4,0,
311,10,86,1,3,
311,10,86,4,0,
311,10,87,1,38,
311,10,87,4,0,
311,10,92,4,0,
311,10,97,1,44,
311,10,98,1,7,
311,10,104,4,0,
311,10,113,4,0,
311,10,129,1,29,
311,10,129,3,0,
311,10,148,4,0,
311,10,156,4,0,
311,10,164,2,0,
311,10,164,4,0,
311,10,173,3,0,
311,10,182,4,0,
311,10,186,2,0,
311,10,189,3,0,
311,10,203,4,0,
311,10,205,3,0,
311,10,207,4,0,
311,10,209,1,15,
311,10,213,4,0,
311,10,214,4,0,
311,10,216,4,0,
311,10,218,4,0,
311,10,226,1,42,
311,10,227,1,17,
311,10,231,4,0,
311,10,237,4,0,
311,10,240,4,0,
311,10,253,3,0,
311,10,263,4,0,
311,10,268,1,35,
311,10,270,1,10,
311,10,270,3,0,
311,10,273,2,0,
311,10,290,4,0,
311,10,313,1,21,
311,10,313,1,31,
311,10,324,3,0,
311,10,351,4,0,
311,10,363,4,0,
311,10,374,4,0,
311,10,383,1,24,
311,10,387,1,48,
311,10,387,3,0,
311,10,393,3,0,
311,10,417,1,51,
311,10,435,2,0,
311,10,445,4,0,
311,10,447,4,0,
311,10,451,4,0,
311,11,45,1,1,
311,11,47,2,0,
311,11,85,4,0,
311,11,86,1,3,
311,11,86,4,0,
311,11,87,1,42,
311,11,87,4,0,
311,11,92,4,0,
311,11,97,1,48,
311,11,98,1,7,
311,11,104,4,0,
311,11,113,4,0,
311,11,129,1,31,
311,11,148,4,0,
311,11,156,4,0,
311,11,164,4,0,
311,11,182,4,0,
311,11,186,2,0,
311,11,207,4,0,
311,11,209,1,15,
311,11,213,4,0,
311,11,216,4,0,
311,11,218,4,0,
311,11,226,1,44,
311,11,227,1,17,
311,11,237,4,0,
311,11,240,4,0,
311,11,263,4,0,
311,11,268,1,38,
311,11,270,1,10,
311,11,273,2,0,
311,11,313,1,21,
311,11,313,1,35,
311,11,374,4,0,
311,11,381,2,0,
311,11,383,1,24,
311,11,387,1,51,
311,11,417,1,56,
311,11,435,2,0,
311,11,447,4,0,
311,11,451,4,0,
311,11,486,1,29,
311,11,494,1,63,
311,11,496,4,0,
311,11,497,4,0,
311,11,521,4,0,
311,11,528,4,0,
311,12,45,1,1,
311,12,85,4,0,
311,12,86,1,4,
311,12,87,1,37,
311,12,87,4,0,
311,12,92,4,0,
311,12,97,1,47,
311,12,98,1,10,
311,12,104,4,0,
311,12,113,4,0,
311,12,148,4,0,
311,12,156,4,0,
311,12,182,4,0,
311,12,209,1,19,
311,12,213,4,0,
311,12,216,4,0,
311,12,218,4,0,
311,12,226,1,40,
311,12,227,1,22,
311,12,231,4,0,
311,12,237,4,0,
311,12,240,4,0,
311,12,263,4,0,
311,12,268,1,31,
311,12,270,1,13,
311,12,290,4,0,
311,12,313,1,28,
311,12,351,4,0,
311,13,34,3,0,
311,13,38,3,0,
311,13,45,1,1,
311,13,69,3,0,
311,13,85,4,0,
311,13,86,1,4,
311,13,86,3,0,
311,13,87,1,37,
311,13,87,4,0,
311,13,92,4,0,
311,13,97,1,47,
311,13,98,1,10,
311,13,102,3,0,
311,13,104,4,0,
311,13,113,4,0,
311,13,148,4,0,
311,13,156,4,0,
311,13,164,3,0,
311,13,182,4,0,
311,13,207,3,0,
311,13,209,1,19,
311,13,213,4,0,
311,13,216,4,0,
311,13,218,4,0,
311,13,226,1,40,
311,13,227,1,22,
311,13,231,4,0,
311,13,237,4,0,
311,13,240,4,0,
311,13,263,4,0,
311,13,268,1,31,
311,13,270,1,13,
311,13,290,4,0,
311,13,313,1,28,
311,13,351,4,0,
311,14,9,3,0,
311,14,45,1,1,
311,14,47,2,0,
311,14,85,4,0,
311,14,86,1,3,
311,14,86,4,0,
311,14,87,1,42,
311,14,87,4,0,
311,14,92,4,0,
311,14,97,1,48,
311,14,98,1,7,
311,14,104,4,0,
311,14,113,4,0,
311,14,129,1,31,
311,14,148,4,0,
311,14,156,4,0,
311,14,164,4,0,
311,14,173,3,0,
311,14,182,4,0,
311,14,186,2,0,
311,14,207,4,0,
311,14,209,1,15,
311,14,213,4,0,
311,14,214,3,0,
311,14,216,4,0,
311,14,218,4,0,
311,14,226,1,44,
311,14,227,1,17,
311,14,231,3,0,
311,14,237,4,0,
311,14,240,4,0,
311,14,253,3,0,
311,14,263,4,0,
311,14,268,1,38,
311,14,270,1,10,
311,14,270,3,0,
311,14,273,2,0,
311,14,313,1,21,
311,14,313,1,35,
311,14,324,3,0,
311,14,374,4,0,
311,14,381,2,0,
311,14,383,1,24,
311,14,387,1,51,
311,14,387,3,0,
311,14,393,3,0,
311,14,417,1,56,
311,14,435,2,0,
311,14,447,4,0,
311,14,451,4,0,
311,14,486,1,29,
311,14,494,1,63,
311,14,496,4,0,
311,14,497,4,0,
311,14,521,4,0,
311,14,528,4,0,
311,15,45,1,1,5
311,15,47,2,0,
311,15,85,4,0,
311,15,86,1,3,
311,15,86,4,0,
311,15,87,1,42,
311,15,87,4,0,
311,15,92,4,0,
311,15,97,1,48,
311,15,98,1,7,
311,15,104,4,0,
311,15,113,4,0,
311,15,129,1,31,
311,15,148,4,0,
311,15,156,4,0,
311,15,164,4,0,
311,15,182,4,0,
311,15,186,2,0,
311,15,207,4,0,
311,15,209,1,15,
311,15,213,4,0,
311,15,214,4,0,
311,15,216,4,0,
311,15,218,4,0,
311,15,226,1,44,
311,15,227,1,17,
311,15,237,4,0,
311,15,240,4,0,
311,15,263,4,0,
311,15,268,1,38,
311,15,270,1,10,
311,15,273,2,0,
311,15,313,1,35,
311,15,374,4,0,
311,15,381,2,0,
311,15,383,1,24,
311,15,387,1,50,
311,15,417,1,1,1
311,15,417,1,56,
311,15,435,2,0,
311,15,447,4,0,
311,15,451,4,0,
311,15,486,1,29,
311,15,494,1,1,3
311,15,494,1,63,
311,15,496,4,0,
311,15,497,4,0,
311,15,521,4,0,
311,15,528,4,0,
311,15,589,1,1,4
311,15,589,1,21,
311,15,590,4,0,
311,15,609,1,1,2
311,16,9,3,0,
311,16,45,1,1,3
311,16,47,2,0,
311,16,85,4,0,
311,16,86,1,1,4
311,16,86,4,0,
311,16,87,1,43,1
311,16,87,4,0,
311,16,92,4,0,
311,16,97,1,37,1
311,16,98,1,1,5
311,16,104,4,0,
311,16,113,4,0,
311,16,129,1,16,1
311,16,148,4,0,
311,16,156,4,0,
311,16,164,4,0,
311,16,173,3,0,
311,16,182,4,0,
311,16,186,2,0,
311,16,204,1,25,1
311,16,204,2,0,
311,16,207,4,0,
311,16,209,1,7,1
311,16,213,4,0,
311,16,214,4,0,
311,16,216,4,0,
311,16,218,4,0,
311,16,226,1,34,1
311,16,227,1,10,1
311,16,231,3,0,
311,16,237,4,0,
311,16,240,4,0,
311,16,253,3,0,
311,16,263,4,0,
311,16,268,1,28,1
311,16,270,1,4,1
311,16,270,3,0,
311,16,273,2,0,
311,16,290,4,0,
311,16,313,2,0,
311,16,324,3,0,
311,16,351,3,0,
311,16,374,4,0,
311,16,381,2,0,
311,16,383,1,22,1
311,16,387,1,40,1
311,16,387,3,0,
311,16,393,3,0,
311,16,417,1,46,1
311,16,435,1,31,1
311,16,435,2,0,
311,16,447,4,0,
311,16,451,4,0,
311,16,486,1,19,1
311,16,494,1,49,1
311,16,496,4,0,
311,16,497,4,0,
311,16,516,1,13,1
311,16,521,4,0,
311,16,527,3,0,
311,16,528,4,0,
311,16,589,1,1,2
311,16,590,4,0,
311,16,609,1,1,1
311,17,45,1,1,3
311,17,47,2,0,
311,17,85,4,0,
311,17,86,1,1,4
311,17,86,4,0,
311,17,87,1,43,
311,17,87,4,0,
311,17,92,4,0,
311,17,97,1,37,
311,17,98,1,1,5
311,17,104,4,0,
311,17,113,4,0,
311,17,129,1,16,
311,17,156,4,0,
311,17,164,4,0,
311,17,182,4,0,
311,17,186,2,0,
311,17,204,1,25,
311,17,204,2,0,
311,17,207,4,0,
311,17,209,1,7,
311,17,213,4,0,
311,17,214,4,0,
311,17,216,4,0,
311,17,218,4,0,
311,17,226,1,34,
311,17,227,1,10,
311,17,237,4,0,
311,17,240,4,0,
311,17,263,4,0,
311,17,268,1,28,
311,17,270,1,4,
311,17,273,2,0,
311,17,313,2,0,
311,17,374,4,0,
311,17,381,2,0,
311,17,383,1,22,
311,17,387,1,40,
311,17,417,1,46,
311,17,435,1,31,
311,17,435,2,0,
311,17,447,4,0,
311,17,451,4,0,
311,17,486,1,19,
311,17,494,1,49,
311,17,496,4,0,
311,17,497,4,0,
311,17,516,1,13,
311,17,521,4,0,
311,17,528,4,0,
311,17,589,1,1,2
311,17,590,4,0,
311,17,609,1,1,1
311,18,45,1,1,3
311,18,47,2,0,
311,18,85,4,0,
311,18,86,1,1,4
311,18,86,4,0,
311,18,87,1,43,
311,18,87,4,0,
311,18,92,4,0,
311,18,97,1,37,
311,18,98,1,1,5
311,18,104,4,0,
311,18,113,4,0,
311,18,129,1,16,
311,18,156,4,0,
311,18,164,4,0,
311,18,182,4,0,
311,18,186,2,0,
311,18,204,1,25,
311,18,204,2,0,
311,18,207,4,0,
311,18,209,1,7,
311,18,213,4,0,
311,18,214,4,0,
311,18,216,4,0,
311,18,218,4,0,
311,18,226,1,34,
311,18,227,1,10,
311,18,237,4,0,
311,18,240,4,0,
311,18,263,4,0,
311,18,268,1,28,
311,18,270,1,4,
311,18,273,2,0,
311,18,313,2,0,
311,18,374,4,0,
311,18,381,2,0,
311,18,383,1,22,
311,18,387,1,40,
311,18,417,1,46,
311,18,435,1,31,
311,18,435,2,0,
311,18,447,4,0,
311,18,451,4,0,
311,18,486,1,19,
311,18,494,1,49,
311,18,496,4,0,
311,18,497,4,0,
311,18,516,1,13,
311,18,521,4,0,
311,18,528,4,0,
311,18,589,1,1,2
311,18,590,4,0,
311,18,609,1,1,1
311,18,715,2,0,
312,5,45,1,1,
312,5,85,4,0,
312,5,86,1,4,
312,5,87,1,37,
312,5,87,4,0,
312,5,92,4,0,
312,5,97,1,47,
312,5,98,1,10,
312,5,104,4,0,
312,5,113,4,0,
312,5,148,4,0,
312,5,156,4,0,
312,5,164,2,0,
312,5,182,4,0,
312,5,204,1,28,
312,5,209,1,19,
312,5,213,4,0,
312,5,216,4,0,
312,5,218,4,0,
312,5,226,1,40,
312,5,227,1,22,
312,5,231,4,0,
312,5,237,4,0,
312,5,240,4,0,
312,5,263,4,0,
312,5,268,1,31,
312,5,270,1,13,
312,5,273,2,0,
312,5,290,4,0,
312,5,351,4,0,
312,6,5,3,0,
312,6,9,3,0,
312,6,25,3,0,
312,6,34,3,0,
312,6,38,3,0,
312,6,45,1,1,
312,6,68,3,0,
312,6,69,3,0,
312,6,85,4,0,
312,6,86,1,4,
312,6,86,3,0,
312,6,87,1,37,
312,6,87,4,0,
312,6,92,4,0,
312,6,97,1,47,
312,6,98,1,10,
312,6,102,3,0,
312,6,104,4,0,
312,6,111,3,0,
312,6,113,4,0,
312,6,118,3,0,
312,6,129,3,0,
312,6,148,4,0,
312,6,156,4,0,
312,6,164,2,0,
312,6,164,3,0,
312,6,173,3,0,
312,6,182,4,0,
312,6,189,3,0,
312,6,203,3,0,
312,6,204,1,28,
312,6,205,3,0,
312,6,207,3,0,
312,6,209,1,19,
312,6,213,4,0,
312,6,214,3,0,
312,6,216,4,0,
312,6,218,4,0,
312,6,223,3,0,
312,6,226,1,40,
312,6,227,1,22,
312,6,231,4,0,
312,6,237,4,0,
312,6,240,4,0,
312,6,263,4,0,
312,6,268,1,31,
312,6,270,1,13,
312,6,273,2,0,
312,6,290,4,0,
312,6,351,4,0,
312,7,5,3,0,
312,7,25,3,0,
312,7,34,3,0,
312,7,38,3,0,
312,7,45,1,1,
312,7,68,3,0,
312,7,69,3,0,
312,7,85,4,0,
312,7,86,1,4,
312,7,86,3,0,
312,7,87,1,37,
312,7,87,4,0,
312,7,92,4,0,
312,7,97,1,47,
312,7,98,1,10,
312,7,102,3,0,
312,7,104,4,0,
312,7,113,4,0,
312,7,118,3,0,
312,7,148,4,0,
312,7,156,4,0,
312,7,164,2,0,
312,7,164,3,0,
312,7,182,4,0,
312,7,204,1,28,
312,7,209,1,19,
312,7,213,4,0,
312,7,216,4,0,
312,7,218,4,0,
312,7,226,1,40,
312,7,227,1,22,
312,7,231,4,0,
312,7,237,4,0,
312,7,240,4,0,
312,7,263,4,0,
312,7,268,1,31,
312,7,270,1,13,
312,7,273,2,0,
312,7,290,4,0,
312,7,351,4,0,
312,8,45,1,1,
312,8,47,2,0,
312,8,85,4,0,
312,8,86,1,3,
312,8,86,4,0,
312,8,87,1,38,
312,8,87,4,0,
312,8,92,4,0,
312,8,97,1,44,
312,8,98,1,7,
312,8,104,4,0,
312,8,113,4,0,
312,8,129,1,29,
312,8,148,4,0,
312,8,156,4,0,
312,8,164,2,0,
312,8,164,4,0,
312,8,182,4,0,
312,8,186,2,0,
312,8,203,4,0,
312,8,204,1,21,
312,8,207,4,0,
312,8,209,1,15,
312,8,213,4,0,
312,8,214,4,0,
312,8,216,4,0,
312,8,218,4,0,
312,8,226,1,42,
312,8,227,1,17,
312,8,231,4,0,
312,8,237,4,0,
312,8,240,4,0,
312,8,263,4,0,
312,8,268,1,35,
312,8,270,1,10,
312,8,273,2,0,
312,8,290,4,0,
312,8,313,1,31,
312,8,351,4,0,
312,8,363,4,0,
312,8,374,4,0,
312,8,376,1,48,
312,8,383,1,24,
312,8,417,1,51,
312,8,445,4,0,
312,8,447,4,0,
312,8,451,4,0,
312,9,9,3,0,
312,9,45,1,1,
312,9,47,2,0,
312,9,85,4,0,
312,9,86,1,3,
312,9,86,4,0,
312,9,87,1,38,
312,9,87,4,0,
312,9,92,4,0,
312,9,97,1,44,
312,9,98,1,7,
312,9,104,4,0,
312,9,113,4,0,
312,9,129,1,29,
312,9,129,3,0,
312,9,148,4,0,
312,9,156,4,0,
312,9,164,2,0,
312,9,164,4,0,
312,9,173,3,0,
312,9,182,4,0,
312,9,186,2,0,
312,9,189,3,0,
312,9,203,4,0,
312,9,204,1,21,
312,9,205,3,0,
312,9,207,4,0,
312,9,209,1,15,
312,9,213,4,0,
312,9,214,4,0,
312,9,216,4,0,
312,9,218,4,0,
312,9,226,1,42,
312,9,227,1,17,
312,9,231,4,0,
312,9,237,4,0,
312,9,240,4,0,
312,9,253,3,0,
312,9,263,4,0,
312,9,268,1,35,
312,9,270,1,10,
312,9,270,3,0,
312,9,273,2,0,
312,9,290,4,0,
312,9,313,1,31,
312,9,324,3,0,
312,9,351,4,0,
312,9,363,4,0,
312,9,374,4,0,
312,9,376,1,48,
312,9,383,1,24,
312,9,387,3,0,
312,9,393,3,0,
312,9,417,1,51,
312,9,445,4,0,
312,9,447,4,0,
312,9,451,4,0,
312,10,9,3,0,
312,10,29,3,0,
312,10,45,1,1,
312,10,47,2,0,
312,10,85,4,0,
312,10,86,1,3,
312,10,86,4,0,
312,10,87,1,38,
312,10,87,4,0,
312,10,92,4,0,
312,10,97,1,44,
312,10,98,1,7,
312,10,104,4,0,
312,10,113,4,0,
312,10,129,1,29,
312,10,129,3,0,
312,10,148,4,0,
312,10,156,4,0,
312,10,164,2,0,
312,10,164,4,0,
312,10,173,3,0,
312,10,182,4,0,
312,10,186,2,0,
312,10,189,3,0,
312,10,203,4,0,
312,10,204,1,21,
312,10,205,3,0,
312,10,207,4,0,
312,10,209,1,15,
312,10,213,4,0,
312,10,214,4,0,
312,10,216,4,0,
312,10,218,4,0,
312,10,226,1,42,
312,10,227,1,17,
312,10,231,4,0,
312,10,237,4,0,
312,10,240,4,0,
312,10,253,3,0,
312,10,263,4,0,
312,10,268,1,35,
312,10,270,1,10,
312,10,270,3,0,
312,10,273,2,0,
312,10,290,4,0,
312,10,313,1,31,
312,10,324,3,0,
312,10,351,4,0,
312,10,363,4,0,
312,10,374,4,0,
312,10,376,1,48,
312,10,383,1,24,
312,10,387,3,0,
312,10,393,3,0,
312,10,417,1,51,
312,10,435,2,0,
312,10,445,4,0,
312,10,447,4,0,
312,10,451,4,0,
312,11,45,1,1,
312,11,47,2,0,
312,11,85,4,0,
312,11,86,1,3,
312,11,86,4,0,
312,11,87,1,42,
312,11,87,4,0,
312,11,92,4,0,
312,11,97,1,48,
312,11,98,1,7,
312,11,104,4,0,
312,11,113,4,0,
312,11,129,1,31,
312,11,148,4,0,
312,11,156,4,0,
312,11,164,4,0,
312,11,182,4,0,
312,11,186,2,0,
312,11,204,1,21,
312,11,207,4,0,
312,11,209,1,15,
312,11,213,4,0,
312,11,216,4,0,
312,11,218,4,0,
312,11,226,1,44,
312,11,227,1,17,
312,11,237,4,0,
312,11,240,4,0,
312,11,263,4,0,
312,11,268,1,38,
312,11,270,1,10,
312,11,273,2,0,
312,11,313,1,35,
312,11,374,4,0,
312,11,376,1,51,
312,11,381,2,0,
312,11,383,1,24,
312,11,417,1,56,
312,11,435,2,0,
312,11,447,4,0,
312,11,451,4,0,
312,11,486,1,29,
312,11,494,1,63,
312,11,496,4,0,
312,11,497,4,0,
312,11,521,4,0,
312,11,528,4,0,
312,12,45,1,1,
312,12,85,4,0,
312,12,86,1,4,
312,12,87,1,37,
312,12,87,4,0,
312,12,92,4,0,
312,12,97,1,47,
312,12,98,1,10,
312,12,104,4,0,
312,12,113,4,0,
312,12,148,4,0,
312,12,156,4,0,
312,12,182,4,0,
312,12,204,1,28,
312,12,209,1,19,
312,12,213,4,0,
312,12,216,4,0,
312,12,218,4,0,
312,12,226,1,40,
312,12,227,1,22,
312,12,231,4,0,
312,12,237,4,0,
312,12,240,4,0,
312,12,263,4,0,
312,12,268,1,31,
312,12,270,1,13,
312,12,290,4,0,
312,12,351,4,0,
312,13,34,3,0,
312,13,38,3,0,
312,13,45,1,1,
312,13,69,3,0,
312,13,85,4,0,
312,13,86,1,4,
312,13,86,3,0,
312,13,87,1,37,
312,13,87,4,0,
312,13,92,4,0,
312,13,97,1,47,
312,13,98,1,10,
312,13,102,3,0,
312,13,104,4,0,
312,13,113,4,0,
312,13,148,4,0,
312,13,156,4,0,
312,13,164,3,0,
312,13,182,4,0,
312,13,204,1,28,
312,13,207,3,0,
312,13,209,1,19,
312,13,213,4,0,
312,13,216,4,0,
312,13,218,4,0,
312,13,226,1,40,
312,13,227,1,22,
312,13,231,4,0,
312,13,237,4,0,
312,13,240,4,0,
312,13,263,4,0,
312,13,268,1,31,
312,13,270,1,13,
312,13,290,4,0,
312,13,351,4,0,
312,14,9,3,0,
312,14,45,1,1,
312,14,47,2,0,
312,14,85,4,0,
312,14,86,1,3,
312,14,86,4,0,
312,14,87,1,42,
312,14,87,4,0,
312,14,92,4,0,
312,14,97,1,48,
312,14,98,1,7,
312,14,104,4,0,
312,14,113,4,0,
312,14,129,1,31,
312,14,148,4,0,
312,14,156,4,0,
312,14,164,4,0,
312,14,173,3,0,
312,14,182,4,0,
312,14,186,2,0,
312,14,204,1,21,
312,14,207,4,0,
312,14,209,1,15,
312,14,213,4,0,
312,14,214,3,0,
312,14,216,4,0,
312,14,218,4,0,
312,14,226,1,44,
312,14,227,1,17,
312,14,231,3,0,
312,14,237,4,0,
312,14,240,4,0,
312,14,253,3,0,
312,14,263,4,0,
312,14,268,1,38,
312,14,270,1,10,
312,14,270,3,0,
312,14,273,2,0,
312,14,313,1,35,
312,14,324,3,0,
312,14,374,4,0,
312,14,376,1,51,
312,14,381,2,0,
312,14,383,1,24,
312,14,387,3,0,
312,14,393,3,0,
312,14,417,1,56,
312,14,435,2,0,
312,14,447,4,0,
312,14,451,4,0,
312,14,486,1,29,
312,14,494,1,63,
312,14,496,4,0,
312,14,497,4,0,
312,14,521,4,0,
312,14,528,4,0,
312,15,45,1,1,5
312,15,47,2,0,
312,15,85,4,0,
312,15,86,1,3,
312,15,86,4,0,
312,15,87,1,42,
312,15,87,4,0,
312,15,92,4,0,
312,15,97,1,48,
312,15,98,1,7,
312,15,104,4,0,
312,15,113,4,0,
312,15,129,1,31,
312,15,148,4,0,
312,15,156,4,0,
312,15,164,4,0,
312,15,182,4,0,
312,15,186,2,0,
312,15,204,1,21,
312,15,207,4,0,
312,15,209,1,15,
312,15,213,4,0,
312,15,214,4,0,
312,15,216,4,0,
312,15,218,4,0,
312,15,226,1,44,
312,15,227,1,17,
312,15,237,4,0,
312,15,240,4,0,
312,15,263,4,0,
312,15,268,1,38,
312,15,270,1,10,
312,15,273,2,0,
312,15,313,1,35,
312,15,374,4,0,
312,15,376,1,51,
312,15,381,2,0,
312,15,383,1,24,
312,15,417,1,1,1
312,15,417,1,56,
312,15,435,2,0,
312,15,447,4,0,
312,15,451,4,0,
312,15,486,1,29,
312,15,494,1,1,3
312,15,494,1,63,
312,15,496,4,0,
312,15,497,4,0,
312,15,521,4,0,
312,15,528,4,0,
312,15,589,1,1,4
312,15,590,4,0,
312,15,609,1,1,2
312,16,9,3,0,
312,16,45,1,1,3
312,16,47,2,0,
312,16,85,4,0,
312,16,86,1,1,4
312,16,86,4,0,
312,16,87,1,43,1
312,16,87,4,0,
312,16,92,4,0,
312,16,97,1,37,1
312,16,98,1,1,5
312,16,104,4,0,
312,16,113,4,0,
312,16,129,1,16,1
312,16,148,4,0,
312,16,156,4,0,
312,16,164,4,0,
312,16,173,3,0,
312,16,182,4,0,
312,16,186,2,0,
312,16,204,2,0,
312,16,207,4,0,
312,16,209,1,7,1
312,16,213,4,0,
312,16,214,4,0,
312,16,216,4,0,
312,16,218,4,0,
312,16,226,1,34,1
312,16,227,1,10,1
312,16,231,3,0,
312,16,237,4,0,
312,16,240,4,0,
312,16,253,3,0,
312,16,263,4,0,
312,16,268,1,28,1
312,16,270,1,4,1
312,16,270,3,0,
312,16,273,2,0,
312,16,290,4,0,
312,16,313,1,25,1
312,16,313,2,0,
312,16,324,3,0,
312,16,351,3,0,
312,16,374,4,0,
312,16,376,1,40,1
312,16,381,2,0,
312,16,383,1,22,1
312,16,387,3,0,
312,16,393,3,0,
312,16,415,1,13,1
312,16,417,1,46,1
312,16,435,1,31,1
312,16,435,2,0,
312,16,447,4,0,
312,16,451,4,0,
312,16,486,1,19,1
312,16,494,1,49,1
312,16,496,4,0,
312,16,497,4,0,
312,16,521,4,0,
312,16,527,3,0,
312,16,528,4,0,
312,16,589,1,1,2
312,16,590,4,0,
312,16,609,1,1,1
312,17,45,1,1,3
312,17,47,2,0,
312,17,85,4,0,
312,17,86,1,1,4
312,17,86,4,0,
312,17,87,1,43,
312,17,87,4,0,
312,17,92,4,0,
312,17,97,1,37,
312,17,98,1,1,5
312,17,104,4,0,
312,17,113,4,0,
312,17,129,1,16,
312,17,156,4,0,
312,17,164,4,0,
312,17,182,4,0,
312,17,186,2,0,
312,17,204,2,0,
312,17,207,4,0,
312,17,209,1,7,
312,17,213,4,0,
312,17,214,4,0,
312,17,216,4,0,
312,17,218,4,0,
312,17,226,1,34,
312,17,227,1,10,
312,17,237,4,0,
312,17,240,4,0,
312,17,263,4,0,
312,17,268,1,28,
312,17,270,1,4,
312,17,273,2,0,
312,17,313,1,25,
312,17,313,2,0,
312,17,374,4,0,
312,17,376,1,40,
312,17,381,2,0,
312,17,383,1,22,
312,17,415,1,13,
312,17,417,1,46,
312,17,435,1,31,
312,17,435,2,0,
312,17,447,4,0,
312,17,451,4,0,
312,17,486,1,19,
312,17,494,1,49,
312,17,496,4,0,
312,17,497,4,0,
312,17,521,4,0,
312,17,528,4,0,
312,17,589,1,1,2
312,17,590,4,0,
312,17,609,1,1,1
312,18,45,1,1,3
312,18,47,2,0,
312,18,85,4,0,
312,18,86,1,1,4
312,18,86,4,0,
312,18,87,1,43,
312,18,87,4,0,
312,18,92,4,0,
312,18,97,1,37,
312,18,98,1,1,5
312,18,104,4,0,
312,18,113,4,0,
312,18,129,1,16,
312,18,156,4,0,
312,18,164,4,0,
312,18,182,4,0,
312,18,186,2,0,
312,18,204,2,0,
312,18,207,4,0,
312,18,209,1,7,
312,18,213,4,0,
312,18,214,4,0,
312,18,216,4,0,
312,18,218,4,0,
312,18,226,1,34,
312,18,227,1,10,
312,18,237,4,0,
312,18,240,4,0,
312,18,263,4,0,
312,18,268,1,28,
312,18,270,1,4,
312,18,273,2,0,
312,18,313,1,25,
312,18,313,2,0,
312,18,374,4,0,
312,18,376,1,40,
312,18,381,2,0,
312,18,383,1,22,
312,18,415,1,13,
312,18,417,1,46,
312,18,435,1,31,
312,18,435,2,0,
312,18,447,4,0,
312,18,451,4,0,
312,18,486,1,19,
312,18,494,1,49,
312,18,496,4,0,
312,18,497,4,0,
312,18,521,4,0,
312,18,528,4,0,
312,18,589,1,1,2
312,18,590,4,0,
312,18,609,1,1,1
312,18,715,2,0,
313,5,33,1,1,
313,5,38,1,37,
313,5,76,4,0,
313,5,85,4,0,
313,5,87,4,0,
313,5,92,4,0,
313,5,98,1,17,
313,5,104,1,9,
313,5,104,4,0,
313,5,109,1,5,
313,5,113,4,0,
313,5,148,4,0,
313,5,156,4,0,
313,5,168,4,0,
313,5,182,1,29,
313,5,182,4,0,
313,5,202,4,0,
313,5,213,4,0,
313,5,216,4,0,
313,5,218,4,0,
313,5,226,2,0,
313,5,236,1,13,
313,5,237,4,0,
313,5,240,4,0,
313,5,241,4,0,
313,5,247,4,0,
313,5,263,4,0,
313,5,264,4,0,
313,5,270,1,33,
313,5,271,2,0,
313,5,280,4,0,
313,5,290,4,0,
313,5,294,1,21,
313,5,318,2,0,
313,5,324,1,25,
313,5,332,4,0,
313,5,351,4,0,
313,5,352,4,0,
313,6,5,3,0,
313,6,8,3,0,
313,6,9,3,0,
313,6,25,3,0,
313,6,33,1,1,
313,6,34,3,0,
313,6,38,1,37,
313,6,38,3,0,
313,6,68,3,0,
313,6,69,3,0,
313,6,76,4,0,
313,6,85,4,0,
313,6,86,3,0,
313,6,87,4,0,
313,6,92,4,0,
313,6,98,1,17,
313,6,102,3,0,
313,6,104,1,9,
313,6,104,4,0,
313,6,109,1,5,
313,6,113,4,0,
313,6,118,3,0,
313,6,129,3,0,
313,6,148,4,0,
313,6,156,4,0,
313,6,164,3,0,
313,6,168,4,0,
313,6,173,3,0,
313,6,182,1,29,
313,6,182,4,0,
313,6,189,3,0,
313,6,202,4,0,
313,6,203,3,0,
313,6,207,3,0,
313,6,213,4,0,
313,6,214,3,0,
313,6,216,4,0,
313,6,218,4,0,
313,6,223,3,0,
313,6,226,2,0,
313,6,236,1,13,
313,6,237,4,0,
313,6,240,4,0,
313,6,241,4,0,
313,6,244,3,0,
313,6,247,4,0,
313,6,263,4,0,
313,6,264,4,0,
313,6,270,1,33,
313,6,271,2,0,
313,6,280,4,0,
313,6,290,4,0,
313,6,294,1,21,
313,6,318,2,0,
313,6,324,1,25,
313,6,332,4,0,
313,6,351,4,0,
313,6,352,4,0,
313,7,5,3,0,
313,7,25,3,0,
313,7,33,1,1,
313,7,34,3,0,
313,7,38,1,37,
313,7,38,3,0,
313,7,68,3,0,
313,7,69,3,0,
313,7,76,4,0,
313,7,85,4,0,
313,7,86,3,0,
313,7,87,4,0,
313,7,92,4,0,
313,7,98,1,17,
313,7,102,3,0,
313,7,104,1,9,
313,7,104,4,0,
313,7,109,1,5,
313,7,113,4,0,
313,7,118,3,0,
313,7,148,4,0,
313,7,156,4,0,
313,7,164,3,0,
313,7,168,4,0,
313,7,182,1,29,
313,7,182,4,0,
313,7,202,4,0,
313,7,213,4,0,
313,7,216,4,0,
313,7,218,4,0,
313,7,226,2,0,
313,7,236,1,13,
313,7,237,4,0,
313,7,240,4,0,
313,7,241,4,0,
313,7,247,4,0,
313,7,263,4,0,
313,7,264,4,0,
313,7,270,1,33,
313,7,271,2,0,
313,7,280,4,0,
313,7,290,4,0,
313,7,294,1,21,
313,7,318,2,0,
313,7,324,1,25,
313,7,332,4,0,
313,7,351,4,0,
313,7,352,4,0,
313,8,33,1,1,2
313,8,38,1,45,
313,8,76,4,0,
313,8,85,4,0,
313,8,86,4,0,
313,8,87,4,0,
313,8,92,4,0,
313,8,98,1,17,
313,8,104,1,5,
313,8,104,4,0,
313,8,109,1,9,
313,8,113,4,0,
313,8,148,1,1,1
313,8,148,4,0,
313,8,156,4,0,
313,8,164,4,0,
313,8,168,4,0,
313,8,182,1,29,
313,8,182,4,0,
313,8,202,4,0,
313,8,203,4,0,
313,8,207,4,0,
313,8,213,4,0,
313,8,214,4,0,
313,8,216,4,0,
313,8,218,4,0,
313,8,226,2,0,
313,8,227,2,0,
313,8,236,1,13,
313,8,237,4,0,
313,8,240,4,0,
313,8,241,4,0,
313,8,244,4,0,
313,8,247,4,0,
313,8,263,4,0,
313,8,264,4,0,
313,8,270,1,33,
313,8,271,2,0,
313,8,280,4,0,
313,8,290,4,0,
313,8,294,1,21,
313,8,318,2,0,
313,8,318,4,0,
313,8,324,1,25,
313,8,332,4,0,
313,8,351,4,0,
313,8,352,4,0,
313,8,355,4,0,
313,8,363,4,0,
313,8,369,4,0,
313,8,374,4,0,
313,8,405,1,41,
313,8,405,2,0,
313,8,428,1,37,
313,8,445,4,0,
313,8,451,4,0,
313,9,8,3,0,
313,9,9,3,0,
313,9,33,1,1,2
313,9,38,1,45,
313,9,76,4,0,
313,9,85,4,0,
313,9,86,4,0,
313,9,87,4,0,
313,9,92,4,0,
313,9,98,1,17,
313,9,104,1,5,
313,9,104,4,0,
313,9,109,1,9,
313,9,113,4,0,
313,9,129,3,0,
313,9,148,1,1,1
313,9,148,4,0,
313,9,156,4,0,
313,9,164,4,0,
313,9,168,4,0,
313,9,173,3,0,
313,9,182,1,29,
313,9,182,4,0,
313,9,189,3,0,
313,9,202,4,0,
313,9,203,4,0,
313,9,207,4,0,
313,9,213,4,0,
313,9,214,4,0,
313,9,216,4,0,
313,9,218,4,0,
313,9,226,2,0,
313,9,227,2,0,
313,9,236,1,13,
313,9,237,4,0,
313,9,240,4,0,
313,9,241,4,0,
313,9,244,4,0,
313,9,247,4,0,
313,9,263,4,0,
313,9,264,4,0,
313,9,270,1,33,
313,9,270,3,0,
313,9,271,2,0,
313,9,280,4,0,
313,9,290,4,0,
313,9,294,1,21,
313,9,314,3,0,
313,9,318,2,0,
313,9,318,4,0,
313,9,324,1,25,
313,9,324,3,0,
313,9,332,4,0,
313,9,351,4,0,
313,9,352,4,0,
313,9,355,4,0,
313,9,363,4,0,
313,9,369,4,0,
313,9,374,4,0,
313,9,405,1,41,
313,9,405,2,0,
313,9,428,1,37,
313,9,428,3,0,
313,9,445,4,0,
313,9,451,4,0,
313,9,466,3,0,
313,10,8,3,0,
313,10,9,3,0,
313,10,33,1,1,2
313,10,38,1,45,
313,10,76,4,0,
313,10,81,3,0,
313,10,85,4,0,
313,10,86,4,0,
313,10,87,4,0,
313,10,92,4,0,
313,10,98,1,17,
313,10,104,1,5,
313,10,104,4,0,
313,10,109,1,9,
313,10,113,4,0,
313,10,129,3,0,
313,10,148,1,1,1
313,10,148,4,0,
313,10,156,4,0,
313,10,164,4,0,
313,10,168,4,0,
313,10,173,3,0,
313,10,182,1,29,
313,10,182,4,0,
313,10,189,3,0,
313,10,202,4,0,
313,10,203,4,0,
313,10,207,4,0,
313,10,213,4,0,
313,10,214,4,0,
313,10,216,4,0,
313,10,218,4,0,
313,10,226,2,0,
313,10,227,2,0,
313,10,236,1,13,
313,10,237,4,0,
313,10,240,4,0,
313,10,241,4,0,
313,10,244,4,0,
313,10,247,4,0,
313,10,263,4,0,
313,10,264,4,0,
313,10,270,1,33,
313,10,270,3,0,
313,10,271,2,0,
313,10,280,4,0,
313,10,290,4,0,
313,10,294,1,21,
313,10,314,3,0,
313,10,318,2,0,
313,10,318,4,0,
313,10,324,1,25,
313,10,324,3,0,
313,10,332,4,0,
313,10,351,4,0,
313,10,352,4,0,
313,10,355,4,0,
313,10,363,4,0,
313,10,366,3,0,
313,10,369,4,0,
313,10,374,4,0,
313,10,405,1,41,
313,10,405,2,0,
313,10,428,1,37,
313,10,428,3,0,
313,10,445,4,0,
313,10,450,3,0,
313,10,451,4,0,
313,10,466,3,0,
313,11,33,1,1,2
313,11,38,1,45,
313,11,69,2,0,
313,11,76,4,0,
313,11,85,4,0,
313,11,86,4,0,
313,11,87,4,0,
313,11,92,4,0,
313,11,98,1,17,
313,11,104,1,5,
313,11,104,4,0,
313,11,109,1,9,
313,11,113,4,0,
313,11,146,2,0,
313,11,148,1,1,1
313,11,148,4,0,
313,11,156,4,0,
313,11,164,4,0,
313,11,168,4,0,
313,11,182,1,29,
313,11,182,4,0,
313,11,207,4,0,
313,11,213,4,0,
313,11,216,4,0,
313,11,218,4,0,
313,11,226,2,0,
313,11,227,2,0,
313,11,236,1,13,
313,11,237,4,0,
313,11,240,4,0,
313,11,241,4,0,
313,11,244,4,0,
313,11,247,4,0,
313,11,263,4,0,
313,11,270,1,33,
313,11,271,2,0,
313,11,280,4,0,
313,11,294,1,21,
313,11,318,2,0,
313,11,324,1,25,
313,11,332,4,0,
313,11,369,4,0,
313,11,374,4,0,
313,11,405,1,41,
313,11,405,2,0,
313,11,428,1,37,
313,11,451,4,0,
313,11,496,4,0,
313,11,512,4,0,
313,11,522,4,0,
313,12,33,1,1,
313,12,38,1,37,
313,12,76,4,0,
313,12,85,4,0,
313,12,87,4,0,
313,12,92,4,0,
313,12,98,1,17,
313,12,104,1,9,
313,12,104,4,0,
313,12,109,1,5,
313,12,113,4,0,
313,12,148,4,0,
313,12,156,4,0,
313,12,168,4,0,
313,12,182,1,29,
313,12,182,4,0,
313,12,202,4,0,
313,12,213,4,0,
313,12,216,4,0,
313,12,218,4,0,
313,12,236,1,13,
313,12,237,4,0,
313,12,240,4,0,
313,12,241,4,0,
313,12,247,4,0,
313,12,263,4,0,
313,12,264,4,0,
313,12,270,1,33,
313,12,280,4,0,
313,12,290,4,0,
313,12,294,1,21,
313,12,324,1,25,
313,12,332,4,0,
313,12,351,4,0,
313,12,352,4,0,
313,13,33,1,1,
313,13,34,3,0,
313,13,38,1,37,
313,13,38,3,0,
313,13,69,3,0,
313,13,76,4,0,
313,13,85,4,0,
313,13,86,3,0,
313,13,87,4,0,
313,13,92,4,0,
313,13,98,1,17,
313,13,102,3,0,
313,13,104,1,9,
313,13,104,4,0,
313,13,109,1,5,
313,13,113,4,0,
313,13,148,4,0,
313,13,156,4,0,
313,13,164,3,0,
313,13,168,4,0,
313,13,182,1,29,
313,13,182,4,0,
313,13,202,4,0,
313,13,207,3,0,
313,13,213,4,0,
313,13,216,4,0,
313,13,218,4,0,
313,13,236,1,13,
313,13,237,4,0,
313,13,240,4,0,
313,13,241,4,0,
313,13,247,4,0,
313,13,263,4,0,
313,13,264,4,0,
313,13,270,1,33,
313,13,280,4,0,
313,13,290,4,0,
313,13,294,1,21,
313,13,324,1,25,
313,13,332,4,0,
313,13,351,4,0,
313,13,352,4,0,
313,14,8,3,0,
313,14,9,3,0,
313,14,33,1,1,2
313,14,38,1,45,
313,14,69,2,0,
313,14,76,4,0,
313,14,85,4,0,
313,14,86,4,0,
313,14,87,4,0,
313,14,92,4,0,
313,14,98,1,17,
313,14,104,1,5,
313,14,104,4,0,
313,14,109,1,9,
313,14,113,4,0,
313,14,146,2,0,
313,14,148,1,1,1
313,14,148,4,0,
313,14,156,4,0,
313,14,164,4,0,
313,14,168,4,0,
313,14,173,3,0,
313,14,182,1,29,
313,14,182,4,0,
313,14,202,3,0,
313,14,207,4,0,
313,14,213,4,0,
313,14,214,3,0,
313,14,216,4,0,
313,14,218,4,0,
313,14,226,2,0,
313,14,227,2,0,
313,14,236,1,13,
313,14,237,4,0,
313,14,240,4,0,
313,14,241,4,0,
313,14,244,4,0,
313,14,247,4,0,
313,14,263,4,0,
313,14,270,1,33,
313,14,270,3,0,
313,14,271,2,0,
313,14,271,3,0,
313,14,280,4,0,
313,14,294,1,21,
313,14,318,2,0,
313,14,324,1,25,
313,14,324,3,0,
313,14,332,4,0,
313,14,355,3,0,
313,14,366,3,0,
313,14,369,4,0,
313,14,374,4,0,
313,14,405,1,41,
313,14,405,2,0,
313,14,428,1,37,
313,14,428,3,0,
313,14,450,3,0,
313,14,451,4,0,
313,14,496,4,0,
313,14,512,4,0,
313,14,522,4,0,
313,15,33,1,1,2
313,15,38,1,45,
313,15,69,2,0,
313,15,76,4,0,
313,15,85,4,0,
313,15,86,4,0,
313,15,87,4,0,
313,15,92,4,0,
313,15,98,1,17,
313,15,104,1,5,
313,15,104,4,0,
313,15,109,1,9,
313,15,113,4,0,
313,15,146,2,0,
313,15,148,1,1,1
313,15,148,4,0,
313,15,156,4,0,
313,15,164,4,0,
313,15,168,4,0,
313,15,182,1,29,
313,15,182,4,0,
313,15,207,4,0,
313,15,213,4,0,
313,15,214,4,0,
313,15,216,4,0,
313,15,218,4,0,
313,15,226,2,0,
313,15,227,2,0,
313,15,236,1,13,
313,15,237,4,0,
313,15,240,4,0,
313,15,241,4,0,
313,15,244,4,0,
313,15,247,4,0,
313,15,263,4,0,
313,15,270,1,33,
313,15,271,2,0,
313,15,280,4,0,
313,15,294,1,21,
313,15,318,2,0,
313,15,324,1,25,
313,15,332,4,0,
313,15,355,4,0,
313,15,369,4,0,
313,15,374,4,0,
313,15,405,1,41,
313,15,405,2,0,
313,15,428,1,37,
313,15,451,4,0,
313,15,496,4,0,
313,15,512,4,0,
313,15,522,4,0,
313,15,590,4,0,
313,15,605,4,0,
313,15,612,4,0,
313,16,8,3,0,
313,16,9,3,0,
313,16,33,1,1,2
313,16,38,1,47,1
313,16,69,2,0,
313,16,76,4,0,
313,16,85,4,0,
313,16,86,4,0,
313,16,87,4,0,
313,16,92,4,0,
313,16,98,1,12,1
313,16,104,1,5,1
313,16,104,4,0,
313,16,109,1,8,1
313,16,113,4,0,
313,16,146,2,0,
313,16,148,1,1,1
313,16,148,4,0,
313,16,156,4,0,
313,16,164,4,0,
313,16,168,4,0,
313,16,173,3,0,
313,16,182,1,29,1
313,16,182,4,0,
313,16,202,3,0,
313,16,207,4,0,
313,16,213,4,0,
313,16,214,4,0,
313,16,216,4,0,
313,16,218,4,0,
313,16,226,2,0,
313,16,227,2,0,
313,16,236,1,19,1
313,16,237,4,0,
313,16,240,4,0,
313,16,241,4,0,
313,16,244,4,0,
313,16,247,4,0,
313,16,263,4,0,
313,16,264,3,0,
313,16,270,1,36,1
313,16,270,3,0,
313,16,271,2,0,
313,16,271,3,0,
313,16,280,4,0,
313,16,290,4,0,
313,16,294,1,22,1
313,16,318,2,0,
313,16,324,1,26,1
313,16,324,3,0,
313,16,332,4,0,
313,16,351,3,0,
313,16,352,3,0,
313,16,355,4,0,
313,16,366,3,0,
313,16,369,4,0,
313,16,374,4,0,
313,16,405,1,40,1
313,16,405,2,0,
313,16,428,1,33,1
313,16,428,3,0,
313,16,450,3,0,
313,16,451,4,0,
313,16,496,4,0,
313,16,512,4,0,
313,16,522,1,15,1
313,16,522,4,0,
313,16,583,1,43,1
313,16,590,4,0,
313,16,605,4,0,
313,16,612,4,0,
313,17,33,1,1,2
313,17,38,1,47,
313,17,69,2,0,
313,17,76,4,0,
313,17,85,4,0,
313,17,86,4,0,
313,17,87,4,0,
313,17,92,4,0,
313,17,98,1,12,
313,17,104,1,5,
313,17,104,4,0,
313,17,109,1,8,
313,17,113,4,0,
313,17,146,2,0,
313,17,148,1,1,1
313,17,156,4,0,
313,17,164,4,0,
313,17,168,4,0,
313,17,182,1,29,
313,17,182,4,0,
313,17,207,4,0,
313,17,213,4,0,
313,17,214,4,0,
313,17,216,4,0,
313,17,218,4,0,
313,17,226,2,0,
313,17,227,2,0,
313,17,236,1,19,
313,17,237,4,0,
313,17,240,4,0,
313,17,241,4,0,
313,17,244,4,0,
313,17,247,4,0,
313,17,263,4,0,
313,17,270,1,36,
313,17,271,2,0,
313,17,280,4,0,
313,17,294,1,22,
313,17,318,2,0,
313,17,324,1,26,
313,17,332,4,0,
313,17,355,4,0,
313,17,369,4,0,
313,17,374,4,0,
313,17,405,1,40,
313,17,405,2,0,
313,17,428,1,33,
313,17,451,4,0,
313,17,496,4,0,
313,17,512,4,0,
313,17,522,1,15,
313,17,583,1,43,
313,17,590,4,0,
313,17,605,4,0,
313,17,611,1,50,
313,17,611,4,0,
313,18,33,1,1,2
313,18,38,1,47,
313,18,69,2,0,
313,18,76,4,0,
313,18,85,4,0,
313,18,86,4,0,
313,18,87,4,0,
313,18,92,4,0,
313,18,98,1,12,
313,18,104,1,5,
313,18,104,4,0,
313,18,109,1,8,
313,18,113,4,0,
313,18,146,2,0,
313,18,148,1,1,1
313,18,156,4,0,
313,18,164,4,0,
313,18,168,4,0,
313,18,182,1,29,
313,18,182,4,0,
313,18,207,4,0,
313,18,213,4,0,
313,18,214,4,0,
313,18,216,4,0,
313,18,218,4,0,
313,18,226,2,0,
313,18,227,2,0,
313,18,236,1,19,
313,18,237,4,0,
313,18,240,4,0,
313,18,241,4,0,
313,18,244,4,0,
313,18,247,4,0,
313,18,263,4,0,
313,18,270,1,36,
313,18,271,2,0,
313,18,280,4,0,
313,18,294,1,22,
313,18,318,2,0,
313,18,324,1,26,
313,18,332,4,0,
313,18,355,4,0,
313,18,369,4,0,
313,18,374,4,0,
313,18,405,1,40,
313,18,405,2,0,
313,18,428,1,33,
313,18,451,4,0,
313,18,496,4,0,
313,18,512,4,0,
313,18,522,1,15,
313,18,583,1,43,
313,18,590,4,0,
313,18,605,4,0,
313,18,611,1,50,
313,18,611,4,0,
313,18,679,2,0,
314,5,33,1,1,
314,5,74,2,0,
314,5,76,4,0,
314,5,85,4,0,
314,5,87,4,0,
314,5,92,4,0,
314,5,98,1,17,
314,5,104,4,0,
314,5,113,4,0,
314,5,148,4,0,
314,5,156,4,0,
314,5,168,4,0,
314,5,182,4,0,
314,5,202,4,0,
314,5,204,1,9,
314,5,213,4,0,
314,5,216,4,0,
314,5,218,4,0,
314,5,226,2,0,
314,5,227,1,25,
314,5,230,1,5,
314,5,236,1,13,
314,5,237,4,0,
314,5,240,4,0,
314,5,241,4,0,
314,5,247,4,0,
314,5,260,1,29,
314,5,263,4,0,
314,5,264,4,0,
314,5,270,1,33,
314,5,273,1,21,
314,5,280,4,0,
314,5,290,4,0,
314,5,318,2,0,
314,5,332,4,0,
314,5,343,1,37,
314,5,351,4,0,
314,5,352,4,0,
314,6,5,3,0,
314,6,8,3,0,
314,6,9,3,0,
314,6,25,3,0,
314,6,33,1,1,
314,6,34,3,0,
314,6,38,3,0,
314,6,68,3,0,
314,6,69,3,0,
314,6,74,2,0,
314,6,76,4,0,
314,6,85,4,0,
314,6,86,3,0,
314,6,87,4,0,
314,6,92,4,0,
314,6,98,1,17,
314,6,102,3,0,
314,6,104,4,0,
314,6,113,4,0,
314,6,118,3,0,
314,6,129,3,0,
314,6,148,4,0,
314,6,156,4,0,
314,6,164,3,0,
314,6,168,4,0,
314,6,173,3,0,
314,6,182,4,0,
314,6,189,3,0,
314,6,202,4,0,
314,6,203,3,0,
314,6,204,1,9,
314,6,207,3,0,
314,6,213,4,0,
314,6,214,3,0,
314,6,216,4,0,
314,6,218,4,0,
314,6,223,3,0,
314,6,226,2,0,
314,6,227,1,25,
314,6,230,1,5,
314,6,236,1,13,
314,6,237,4,0,
314,6,240,4,0,
314,6,241,4,0,
314,6,244,3,0,
314,6,247,4,0,
314,6,260,1,29,
314,6,263,4,0,
314,6,264,4,0,
314,6,270,1,33,
314,6,273,1,21,
314,6,280,4,0,
314,6,290,4,0,
314,6,318,2,0,
314,6,332,4,0,
314,6,343,1,37,
314,6,351,4,0,
314,6,352,4,0,
314,7,5,3,0,
314,7,25,3,0,
314,7,33,1,1,
314,7,34,3,0,
314,7,38,3,0,
314,7,68,3,0,
314,7,69,3,0,
314,7,74,2,0,
314,7,76,4,0,
314,7,85,4,0,
314,7,86,3,0,
314,7,87,4,0,
314,7,92,4,0,
314,7,98,1,17,
314,7,102,3,0,
314,7,104,4,0,
314,7,113,4,0,
314,7,118,3,0,
314,7,148,4,0,
314,7,156,4,0,
314,7,164,3,0,
314,7,168,4,0,
314,7,182,4,0,
314,7,202,4,0,
314,7,204,1,9,
314,7,213,4,0,
314,7,216,4,0,
314,7,218,4,0,
314,7,226,2,0,
314,7,227,1,25,
314,7,230,1,5,
314,7,236,1,13,
314,7,237,4,0,
314,7,240,4,0,
314,7,241,4,0,
314,7,247,4,0,
314,7,260,1,29,
314,7,263,4,0,
314,7,264,4,0,
314,7,270,1,33,
314,7,273,1,21,
314,7,280,4,0,
314,7,290,4,0,
314,7,318,2,0,
314,7,332,4,0,
314,7,343,1,37,
314,7,351,4,0,
314,7,352,4,0,
314,8,33,1,1,
314,8,74,2,0,
314,8,76,4,0,
314,8,85,4,0,
314,8,86,4,0,
314,8,87,4,0,
314,8,92,4,0,
314,8,98,1,17,
314,8,104,4,0,
314,8,113,4,0,
314,8,148,4,0,
314,8,156,4,0,
314,8,164,4,0,
314,8,168,4,0,
314,8,182,4,0,
314,8,202,4,0,
314,8,203,4,0,
314,8,204,1,9,
314,8,207,4,0,
314,8,213,4,0,
314,8,214,4,0,
314,8,216,4,0,
314,8,218,4,0,
314,8,226,2,0,
314,8,227,1,25,
314,8,227,2,0,
314,8,230,1,5,
314,8,236,1,13,
314,8,237,4,0,
314,8,240,4,0,
314,8,241,4,0,
314,8,244,4,0,
314,8,247,4,0,
314,8,260,1,29,
314,8,263,4,0,
314,8,264,4,0,
314,8,270,1,33,
314,8,273,1,21,
314,8,280,4,0,
314,8,290,4,0,
314,8,318,2,0,
314,8,318,4,0,
314,8,332,4,0,
314,8,343,1,45,
314,8,351,4,0,
314,8,352,4,0,
314,8,355,4,0,
314,8,363,4,0,
314,8,369,4,0,
314,8,374,4,0,
314,8,405,1,41,
314,8,405,2,0,
314,8,428,1,37,
314,8,445,4,0,
314,8,451,4,0,
314,9,8,3,0,
314,9,9,3,0,
314,9,33,1,1,
314,9,74,2,0,
314,9,76,4,0,
314,9,85,4,0,
314,9,86,4,0,
314,9,87,4,0,
314,9,92,4,0,
314,9,98,1,17,
314,9,104,4,0,
314,9,113,4,0,
314,9,129,3,0,
314,9,148,4,0,
314,9,156,4,0,
314,9,164,4,0,
314,9,168,4,0,
314,9,173,3,0,
314,9,182,4,0,
314,9,189,3,0,
314,9,202,4,0,
314,9,203,4,0,
314,9,204,1,9,
314,9,207,4,0,
314,9,213,4,0,
314,9,214,4,0,
314,9,216,4,0,
314,9,218,4,0,
314,9,226,2,0,
314,9,227,1,25,
314,9,227,2,0,
314,9,230,1,5,
314,9,236,1,13,
314,9,237,4,0,
314,9,240,4,0,
314,9,241,4,0,
314,9,244,4,0,
314,9,247,4,0,
314,9,260,1,29,
314,9,263,4,0,
314,9,264,4,0,
314,9,270,1,33,
314,9,270,3,0,
314,9,273,1,21,
314,9,280,4,0,
314,9,290,4,0,
314,9,314,3,0,
314,9,318,2,0,
314,9,318,4,0,
314,9,332,4,0,
314,9,343,1,45,
314,9,351,4,0,
314,9,352,4,0,
314,9,355,4,0,
314,9,363,4,0,
314,9,369,4,0,
314,9,374,4,0,
314,9,405,1,41,
314,9,405,2,0,
314,9,428,1,37,
314,9,428,3,0,
314,9,445,4,0,
314,9,451,4,0,
314,9,466,3,0,
314,10,8,3,0,
314,10,9,3,0,
314,10,33,1,1,
314,10,74,2,0,
314,10,76,4,0,
314,10,81,3,0,
314,10,85,4,0,
314,10,86,4,0,
314,10,87,4,0,
314,10,92,4,0,
314,10,98,1,17,
314,10,104,4,0,
314,10,113,4,0,
314,10,129,3,0,
314,10,148,4,0,
314,10,156,4,0,
314,10,164,4,0,
314,10,168,4,0,
314,10,173,3,0,
314,10,182,4,0,
314,10,189,3,0,
314,10,202,4,0,
314,10,203,4,0,
314,10,204,1,9,
314,10,207,4,0,
314,10,213,4,0,
314,10,214,4,0,
314,10,216,4,0,
314,10,218,4,0,
314,10,226,2,0,
314,10,227,1,25,
314,10,227,2,0,
314,10,230,1,5,
314,10,236,1,13,
314,10,237,4,0,
314,10,240,4,0,
314,10,241,4,0,
314,10,244,4,0,
314,10,247,4,0,
314,10,260,1,29,
314,10,263,4,0,
314,10,264,4,0,
314,10,270,1,33,
314,10,270,3,0,
314,10,273,1,21,
314,10,280,4,0,
314,10,290,4,0,
314,10,314,3,0,
314,10,318,2,0,
314,10,318,4,0,
314,10,332,4,0,
314,10,343,1,45,
314,10,351,4,0,
314,10,352,4,0,
314,10,355,4,0,
314,10,363,4,0,
314,10,366,3,0,
314,10,369,4,0,
314,10,374,4,0,
314,10,405,1,41,
314,10,405,2,0,
314,10,428,1,37,
314,10,428,3,0,
314,10,445,4,0,
314,10,450,3,0,
314,10,451,4,0,
314,10,466,3,0,
314,11,33,1,1,
314,11,74,2,0,
314,11,76,4,0,
314,11,85,4,0,
314,11,86,4,0,
314,11,87,4,0,
314,11,92,4,0,
314,11,98,1,17,
314,11,104,4,0,
314,11,109,2,0,
314,11,113,4,0,
314,11,148,4,0,
314,11,156,4,0,
314,11,164,4,0,
314,11,168,4,0,
314,11,182,4,0,
314,11,204,1,9,
314,11,207,4,0,
314,11,213,4,0,
314,11,216,4,0,
314,11,218,4,0,
314,11,226,2,0,
314,11,227,1,25,
314,11,227,2,0,
314,11,230,1,5,
314,11,236,1,13,
314,11,237,4,0,
314,11,240,4,0,
314,11,241,4,0,
314,11,244,4,0,
314,11,247,4,0,
314,11,260,1,29,
314,11,263,4,0,
314,11,270,1,33,
314,11,273,1,21,
314,11,280,4,0,
314,11,313,2,0,
314,11,318,2,0,
314,11,332,4,0,
314,11,343,1,45,
314,11,369,4,0,
314,11,374,4,0,
314,11,405,1,41,
314,11,405,2,0,
314,11,428,1,37,
314,11,445,2,0,
314,11,451,4,0,
314,11,496,4,0,
314,11,512,4,0,
314,11,522,4,0,
314,12,33,1,1,
314,12,76,4,0,
314,12,85,4,0,
314,12,87,4,0,
314,12,92,4,0,
314,12,98,1,17,
314,12,104,4,0,
314,12,113,4,0,
314,12,148,4,0,
314,12,156,4,0,
314,12,168,4,0,
314,12,182,4,0,
314,12,202,4,0,
314,12,204,1,9,
314,12,213,4,0,
314,12,216,4,0,
314,12,218,4,0,
314,12,227,1,25,
314,12,230,1,5,
314,12,236,1,13,
314,12,237,4,0,
314,12,240,4,0,
314,12,241,4,0,
314,12,247,4,0,
314,12,260,1,29,
314,12,263,4,0,
314,12,264,4,0,
314,12,270,1,33,
314,12,273,1,21,
314,12,280,4,0,
314,12,290,4,0,
314,12,332,4,0,
314,12,343,1,37,
314,12,351,4,0,
314,12,352,4,0,
314,13,33,1,1,
314,13,34,3,0,
314,13,38,3,0,
314,13,69,3,0,
314,13,76,4,0,
314,13,85,4,0,
314,13,86,3,0,
314,13,87,4,0,
314,13,92,4,0,
314,13,98,1,17,
314,13,102,3,0,
314,13,104,4,0,
314,13,113,4,0,
314,13,148,4,0,
314,13,156,4,0,
314,13,164,3,0,
314,13,168,4,0,
314,13,182,4,0,
314,13,202,4,0,
314,13,204,1,9,
314,13,207,3,0,
314,13,213,4,0,
314,13,216,4,0,
314,13,218,4,0,
314,13,227,1,25,
314,13,230,1,5,
314,13,236,1,13,
314,13,237,4,0,
314,13,240,4,0,
314,13,241,4,0,
314,13,247,4,0,
314,13,260,1,29,
314,13,263,4,0,
314,13,264,4,0,
314,13,270,1,33,
314,13,273,1,21,
314,13,280,4,0,
314,13,290,4,0,
314,13,332,4,0,
314,13,343,1,37,
314,13,351,4,0,
314,13,352,4,0,
314,14,8,3,0,
314,14,9,3,0,
314,14,33,1,1,
314,14,74,2,0,
314,14,76,4,0,
314,14,85,4,0,
314,14,86,4,0,
314,14,87,4,0,
314,14,92,4,0,
314,14,98,1,17,
314,14,104,4,0,
314,14,109,2,0,
314,14,113,4,0,
314,14,148,4,0,
314,14,156,4,0,
314,14,164,4,0,
314,14,168,4,0,
314,14,173,3,0,
314,14,182,4,0,
314,14,202,3,0,
314,14,204,1,9,
314,14,207,4,0,
314,14,213,4,0,
314,14,214,3,0,
314,14,216,4,0,
314,14,218,4,0,
314,14,226,2,0,
314,14,227,1,25,
314,14,227,2,0,
314,14,230,1,5,
314,14,236,1,13,
314,14,237,4,0,
314,14,240,4,0,
314,14,241,4,0,
314,14,244,4,0,
314,14,247,4,0,
314,14,260,1,29,
314,14,263,4,0,
314,14,270,1,33,
314,14,270,3,0,
314,14,273,1,21,
314,14,280,4,0,
314,14,313,2,0,
314,14,318,2,0,
314,14,332,4,0,
314,14,343,1,45,
314,14,343,3,0,
314,14,355,3,0,
314,14,366,3,0,
314,14,369,4,0,
314,14,374,4,0,
314,14,405,1,41,
314,14,405,2,0,
314,14,428,1,37,
314,14,428,3,0,
314,14,445,2,0,
314,14,450,3,0,
314,14,451,4,0,
314,14,496,4,0,
314,14,512,4,0,
314,14,522,4,0,
314,15,33,1,1,1
314,15,74,2,0,
314,15,76,4,0,
314,15,85,4,0,
314,15,86,4,0,
314,15,87,4,0,
314,15,92,4,0,
314,15,98,1,17,
314,15,104,4,0,
314,15,109,2,0,
314,15,113,4,0,
314,15,148,4,0,
314,15,156,4,0,
314,15,164,4,0,
314,15,168,4,0,
314,15,182,4,0,
314,15,204,1,9,
314,15,207,4,0,
314,15,213,4,0,
314,15,214,4,0,
314,15,216,4,0,
314,15,218,4,0,
314,15,226,2,0,
314,15,227,1,25,
314,15,227,2,0,
314,15,230,1,5,
314,15,236,1,13,
314,15,237,4,0,
314,15,240,4,0,
314,15,241,4,0,
314,15,244,4,0,
314,15,247,4,0,
314,15,260,1,29,
314,15,263,4,0,
314,15,270,1,33,
314,15,273,1,21,
314,15,280,4,0,
314,15,313,2,0,
314,15,318,2,0,
314,15,332,4,0,
314,15,343,1,45,
314,15,355,4,0,
314,15,369,4,0,
314,15,374,4,0,
314,15,405,1,41,
314,15,405,2,0,
314,15,428,1,37,
314,15,445,2,0,
314,15,451,4,0,
314,15,496,4,0,
314,15,512,4,0,
314,15,522,4,0,
314,15,589,1,1,2
314,15,590,4,0,
314,15,605,4,0,
314,15,612,4,0,
314,16,8,3,0,
314,16,9,3,0,
314,16,33,1,1,2
314,16,74,2,0,
314,16,76,4,0,
314,16,85,4,0,
314,16,86,4,0,
314,16,87,4,0,
314,16,92,4,0,
314,16,98,1,12,1
314,16,104,4,0,
314,16,109,2,0,
314,16,113,4,0,
314,16,148,4,0,
314,16,156,4,0,
314,16,164,4,0,
314,16,168,4,0,
314,16,173,3,0,
314,16,182,4,0,
314,16,202,3,0,
314,16,204,1,9,1
314,16,207,4,0,
314,16,213,4,0,
314,16,214,4,0,
314,16,216,4,0,
314,16,218,4,0,
314,16,226,2,0,
314,16,227,1,26,1
314,16,227,2,0,
314,16,230,1,5,1
314,16,236,1,19,1
314,16,237,4,0,
314,16,240,4,0,
314,16,241,4,0,
314,16,244,4,0,
314,16,247,4,0,
314,16,260,1,29,1
314,16,263,4,0,
314,16,264,3,0,
314,16,270,1,36,1
314,16,270,3,0,
314,16,273,1,22,1
314,16,280,4,0,
314,16,290,4,0,
314,16,313,2,0,
314,16,318,2,0,
314,16,332,4,0,
314,16,343,1,47,1
314,16,343,3,0,
314,16,351,3,0,
314,16,352,3,0,
314,16,355,4,0,
314,16,366,3,0,
314,16,369,4,0,
314,16,374,4,0,
314,16,405,1,40,1
314,16,405,2,0,
314,16,428,1,33,1
314,16,428,3,0,
314,16,445,2,0,
314,16,450,3,0,
314,16,451,4,0,
314,16,496,4,0,
314,16,512,4,0,
314,16,522,1,15,1
314,16,522,4,0,
314,16,583,1,43,1
314,16,589,1,1,1
314,16,590,4,0,
314,16,605,4,0,
314,16,612,4,0,
314,17,33,1,1,2
314,17,74,2,0,
314,17,76,4,0,
314,17,85,4,0,
314,17,86,4,0,
314,17,87,4,0,
314,17,92,4,0,
314,17,98,1,12,
314,17,104,4,0,
314,17,109,2,0,
314,17,113,4,0,
314,17,156,4,0,
314,17,164,4,0,
314,17,168,4,0,
314,17,182,4,0,
314,17,204,1,9,
314,17,207,4,0,
314,17,213,4,0,
314,17,214,4,0,
314,17,216,4,0,
314,17,218,4,0,
314,17,226,2,0,
314,17,227,1,26,
314,17,227,2,0,
314,17,230,1,5,
314,17,236,1,19,
314,17,237,4,0,
314,17,240,4,0,
314,17,241,4,0,
314,17,244,4,0,
314,17,247,4,0,
314,17,260,1,29,
314,17,263,4,0,
314,17,270,1,36,
314,17,273,1,22,
314,17,280,4,0,
314,17,313,2,0,
314,17,318,2,0,
314,17,332,4,0,
314,17,343,1,47,
314,17,355,4,0,
314,17,369,4,0,
314,17,374,4,0,
314,17,405,1,40,
314,17,405,2,0,
314,17,428,1,33,
314,17,445,2,0,
314,17,451,4,0,
314,17,496,4,0,
314,17,512,4,0,
314,17,522,1,15,
314,17,583,1,43,
314,17,589,1,1,1
314,17,590,4,0,
314,17,605,4,0,
314,17,611,1,50,
314,17,611,4,0,
314,18,33,1,1,2
314,18,74,2,0,
314,18,76,4,0,
314,18,85,4,0,
314,18,86,4,0,
314,18,87,4,0,
314,18,92,4,0,
314,18,98,1,12,
314,18,104,4,0,
314,18,109,2,0,
314,18,113,4,0,
314,18,156,4,0,
314,18,164,4,0,
314,18,168,4,0,
314,18,182,4,0,
314,18,204,1,9,
314,18,207,4,0,
314,18,213,4,0,
314,18,214,4,0,
314,18,216,4,0,
314,18,218,4,0,
314,18,226,2,0,
314,18,227,1,26,
314,18,227,2,0,
314,18,230,1,5,
314,18,236,1,19,
314,18,237,4,0,
314,18,240,4,0,
314,18,241,4,0,
314,18,244,4,0,
314,18,247,4,0,
314,18,260,1,29,
314,18,263,4,0,
314,18,270,1,36,
314,18,273,1,22,
314,18,280,4,0,
314,18,312,2,0,
314,18,313,2,0,
314,18,318,2,0,
314,18,332,4,0,
314,18,343,1,47,
314,18,355,4,0,
314,18,369,4,0,
314,18,374,4,0,
314,18,405,1,40,
314,18,405,2,0,
314,18,428,1,33,
314,18,445,2,0,
314,18,451,4,0,
314,18,496,4,0,
314,18,512,4,0,
314,18,522,1,15,
314,18,583,1,43,
314,18,589,1,1,1
314,18,590,4,0,
314,18,605,4,0,
314,18,611,1,50,
314,18,611,4,0,
315,5,15,4,0,
315,5,40,1,9,
315,5,42,2,0,
315,5,71,1,1,
315,5,72,1,17,
315,5,73,1,21,
315,5,74,1,5,
315,5,76,4,0,
315,5,78,1,13,
315,5,80,1,49,
315,5,92,1,45,
315,5,92,4,0,
315,5,104,4,0,
315,5,148,4,0,
315,5,156,4,0,
315,5,178,2,0,
315,5,182,4,0,
315,5,188,4,0,
315,5,191,2,0,
315,5,202,1,33,
315,5,202,4,0,
315,5,213,4,0,
315,5,216,4,0,
315,5,218,4,0,
315,5,230,1,37,
315,5,235,1,57,
315,5,235,2,0,
315,5,237,4,0,
315,5,241,4,0,
315,5,247,4,0,
315,5,263,4,0,
315,5,275,1,41,
315,5,290,4,0,
315,5,312,1,53,
315,5,320,1,29,
315,5,331,4,0,
315,5,345,1,25,
315,6,14,3,0,
315,6,15,4,0,
315,6,34,3,0,
315,6,38,3,0,
315,6,40,1,9,
315,6,42,2,0,
315,6,71,1,1,
315,6,72,1,17,
315,6,73,1,21,
315,6,74,1,5,
315,6,76,4,0,
315,6,78,1,13,
315,6,80,1,49,
315,6,92,1,45,
315,6,92,4,0,
315,6,102,3,0,
315,6,104,4,0,
315,6,129,3,0,
315,6,148,4,0,
315,6,156,4,0,
315,6,164,3,0,
315,6,173,3,0,
315,6,178,2,0,
315,6,182,4,0,
315,6,188,4,0,
315,6,189,3,0,
315,6,191,2,0,
315,6,202,1,33,
315,6,202,4,0,
315,6,203,3,0,
315,6,207,3,0,
315,6,210,3,0,
315,6,213,4,0,
315,6,214,3,0,
315,6,216,4,0,
315,6,218,4,0,
315,6,230,1,37,
315,6,235,1,57,
315,6,235,2,0,
315,6,237,4,0,
315,6,241,4,0,
315,6,244,3,0,
315,6,247,4,0,
315,6,263,4,0,
315,6,275,1,41,
315,6,290,4,0,
315,6,312,1,53,
315,6,320,1,29,
315,6,331,4,0,
315,6,345,1,25,
315,7,14,3,0,
315,7,15,4,0,
315,7,34,3,0,
315,7,38,3,0,
315,7,40,1,9,
315,7,42,2,0,
315,7,71,1,1,
315,7,72,1,17,
315,7,73,1,21,
315,7,74,1,5,
315,7,76,4,0,
315,7,78,1,13,
315,7,80,1,49,
315,7,92,1,45,
315,7,92,4,0,
315,7,102,3,0,
315,7,104,4,0,
315,7,148,4,0,
315,7,156,4,0,
315,7,164,3,0,
315,7,178,2,0,
315,7,182,4,0,
315,7,188,4,0,
315,7,191,2,0,
315,7,202,1,33,
315,7,202,4,0,
315,7,213,4,0,
315,7,216,4,0,
315,7,218,4,0,
315,7,230,1,37,
315,7,235,1,57,
315,7,235,2,0,
315,7,237,4,0,
315,7,241,4,0,
315,7,247,4,0,
315,7,263,4,0,
315,7,275,1,41,
315,7,290,4,0,
315,7,312,1,53,
315,7,320,1,29,
315,7,331,4,0,
315,7,345,1,25,
315,8,14,4,0,
315,8,15,4,0,
315,8,40,1,7,
315,8,42,2,0,
315,8,71,1,1,
315,8,72,1,13,
315,8,73,1,16,
315,8,74,1,4,
315,8,75,2,0,
315,8,76,4,0,
315,8,78,1,10,
315,8,79,2,0,
315,8,80,1,40,
315,8,92,1,37,
315,8,92,4,0,
315,8,104,4,0,
315,8,148,4,0,
315,8,156,4,0,
315,8,164,4,0,
315,8,170,2,0,
315,8,178,2,0,
315,8,182,4,0,
315,8,188,4,0,
315,8,191,2,0,
315,8,202,1,25,
315,8,202,4,0,
315,8,203,4,0,
315,8,207,4,0,
315,8,213,4,0,
315,8,214,4,0,
315,8,216,4,0,
315,8,218,4,0,
315,8,230,1,31,
315,8,235,1,46,
315,8,235,2,0,
315,8,237,4,0,
315,8,240,4,0,
315,8,241,4,0,
315,8,244,4,0,
315,8,247,4,0,
315,8,263,4,0,
315,8,275,1,34,
315,8,290,4,0,
315,8,312,1,43,
315,8,320,1,22,
315,8,331,4,0,
315,8,345,1,19,
315,8,363,4,0,
315,8,390,1,28,
315,8,398,4,0,
315,8,412,4,0,
315,8,437,2,0,
315,8,445,4,0,
315,8,447,4,0,
315,9,14,4,0,
315,9,15,4,0,
315,9,40,1,7,
315,9,42,2,0,
315,9,71,1,1,
315,9,72,1,13,
315,9,73,1,16,
315,9,74,1,4,
315,9,75,2,0,
315,9,76,4,0,
315,9,78,1,10,
315,9,79,2,0,
315,9,80,1,40,
315,9,92,1,37,
315,9,92,4,0,
315,9,104,4,0,
315,9,129,3,0,
315,9,148,4,0,
315,9,156,4,0,
315,9,164,4,0,
315,9,170,2,0,
315,9,173,3,0,
315,9,178,2,0,
315,9,182,4,0,
315,9,188,4,0,
315,9,189,3,0,
315,9,191,2,0,
315,9,202,1,25,
315,9,202,4,0,
315,9,203,4,0,
315,9,207,4,0,
315,9,210,3,0,
315,9,213,4,0,
315,9,214,4,0,
315,9,216,4,0,
315,9,218,4,0,
315,9,230,1,31,
315,9,235,1,46,
315,9,235,2,0,
315,9,235,3,0,
315,9,237,4,0,
315,9,240,4,0,
315,9,241,4,0,
315,9,244,4,0,
315,9,247,4,0,
315,9,263,4,0,
315,9,275,1,34,
315,9,290,4,0,
315,9,312,1,43,
315,9,320,1,22,
315,9,331,4,0,
315,9,345,1,19,
315,9,363,4,0,
315,9,390,1,28,
315,9,398,4,0,
315,9,402,3,0,
315,9,412,4,0,
315,9,437,2,0,
315,9,445,4,0,
315,9,447,4,0,
315,10,14,4,0,
315,10,15,4,0,
315,10,40,1,7,
315,10,42,2,0,
315,10,71,1,1,
315,10,72,1,13,
315,10,73,1,16,
315,10,74,1,4,
315,10,75,2,0,
315,10,76,4,0,
315,10,78,1,10,
315,10,79,2,0,
315,10,80,1,40,
315,10,92,1,37,
315,10,92,4,0,
315,10,104,4,0,
315,10,129,3,0,
315,10,148,4,0,
315,10,156,4,0,
315,10,164,4,0,
315,10,170,2,0,
315,10,173,3,0,
315,10,178,2,0,
315,10,182,4,0,
315,10,188,4,0,
315,10,189,3,0,
315,10,191,2,0,
315,10,202,1,25,
315,10,202,4,0,
315,10,203,4,0,
315,10,207,4,0,
315,10,210,3,0,
315,10,213,4,0,
315,10,214,4,0,
315,10,216,4,0,
315,10,218,4,0,
315,10,230,1,31,
315,10,235,1,46,
315,10,235,2,0,
315,10,235,3,0,
315,10,237,4,0,
315,10,240,4,0,
315,10,241,4,0,
315,10,244,4,0,
315,10,247,4,0,
315,10,263,4,0,
315,10,275,1,34,
315,10,290,4,0,
315,10,312,1,43,
315,10,320,1,22,
315,10,331,4,0,
315,10,345,1,19,
315,10,363,4,0,
315,10,388,3,0,
315,10,390,1,28,
315,10,398,4,0,
315,10,402,3,0,
315,10,412,4,0,
315,10,437,2,0,
315,10,445,4,0,
315,10,447,4,0,
315,11,14,4,0,
315,11,15,4,0,
315,11,40,1,7,
315,11,42,2,0,
315,11,71,1,1,
315,11,72,1,13,
315,11,73,1,16,
315,11,74,1,4,
315,11,75,2,0,
315,11,76,4,0,
315,11,78,1,10,
315,11,79,2,0,
315,11,80,1,40,
315,11,92,1,37,
315,11,92,4,0,
315,11,104,4,0,
315,11,148,4,0,
315,11,156,4,0,
315,11,164,4,0,
315,11,170,2,0,
315,11,178,2,0,
315,11,182,4,0,
315,11,188,4,0,
315,11,191,2,0,
315,11,202,1,25,
315,11,202,2,0,
315,11,207,4,0,
315,11,213,4,0,
315,11,216,4,0,
315,11,218,4,0,
315,11,230,1,31,
315,11,235,1,46,
315,11,235,2,0,
315,11,237,4,0,
315,11,240,4,0,
315,11,241,4,0,
315,11,244,4,0,
315,11,247,4,0,
315,11,263,4,0,
315,11,275,1,34,
315,11,312,1,43,
315,11,320,1,22,
315,11,320,2,0,
315,11,345,1,19,
315,11,363,2,0,
315,11,390,1,28,
315,11,398,4,0,
315,11,402,2,0,
315,11,412,4,0,
315,11,437,2,0,
315,11,447,4,0,
315,11,474,4,0,
315,11,496,4,0,
315,12,15,4,0,
315,12,40,1,9,
315,12,71,1,1,
315,12,72,1,17,
315,12,73,1,21,
315,12,74,1,5,
315,12,76,4,0,
315,12,78,1,13,
315,12,80,1,49,
315,12,92,1,45,
315,12,92,4,0,
315,12,104,4,0,
315,12,148,4,0,
315,12,156,4,0,
315,12,182,4,0,
315,12,188,4,0,
315,12,202,1,33,
315,12,202,4,0,
315,12,213,4,0,
315,12,216,4,0,
315,12,218,4,0,
315,12,230,1,37,
315,12,235,1,57,
315,12,237,4,0,
315,12,241,4,0,
315,12,247,4,0,
315,12,263,4,0,
315,12,275,1,41,
315,12,290,4,0,
315,12,312,1,53,
315,12,320,1,29,
315,12,331,4,0,
315,12,345,1,25,
315,13,15,4,0,
315,13,34,3,0,
315,13,38,3,0,
315,13,40,1,9,
315,13,71,1,1,
315,13,72,1,17,
315,13,73,1,21,
315,13,74,1,5,
315,13,76,4,0,
315,13,78,1,13,
315,13,80,1,49,
315,13,92,1,45,
315,13,92,4,0,
315,13,102,3,0,
315,13,104,4,0,
315,13,148,4,0,
315,13,156,4,0,
315,13,164,3,0,
315,13,171,3,0,
315,13,182,4,0,
315,13,188,4,0,
315,13,202,1,33,
315,13,202,4,0,
315,13,207,3,0,
315,13,213,4,0,
315,13,216,4,0,
315,13,218,4,0,
315,13,230,1,37,
315,13,235,1,57,
315,13,237,4,0,
315,13,241,4,0,
315,13,247,4,0,
315,13,263,4,0,
315,13,275,1,41,
315,13,290,4,0,
315,13,312,1,53,
315,13,320,1,29,
315,13,331,4,0,
315,13,345,1,25,
315,14,14,4,0,
315,14,15,4,0,
315,14,40,1,7,
315,14,42,2,0,
315,14,71,1,1,
315,14,72,1,13,
315,14,73,1,16,
315,14,74,1,4,
315,14,75,2,0,
315,14,76,4,0,
315,14,78,1,10,
315,14,79,2,0,
315,14,80,1,37,
315,14,92,1,40,
315,14,92,4,0,
315,14,104,4,0,
315,14,148,4,0,
315,14,156,4,0,
315,14,164,4,0,
315,14,170,2,0,
315,14,173,3,0,
315,14,178,2,0,
315,14,182,4,0,
315,14,188,4,0,
315,14,191,2,0,
315,14,202,1,25,
315,14,202,2,0,
315,14,202,3,0,
315,14,207,4,0,
315,14,213,4,0,
315,14,214,3,0,
315,14,216,4,0,
315,14,218,4,0,
315,14,230,1,31,
315,14,235,1,46,
315,14,235,2,0,
315,14,235,3,0,
315,14,237,4,0,
315,14,240,4,0,
315,14,241,4,0,
315,14,244,4,0,
315,14,247,4,0,
315,14,263,4,0,
315,14,275,1,34,
315,14,312,1,43,
315,14,320,1,22,
315,14,320,2,0,
315,14,343,3,0,
315,14,345,1,19,
315,14,363,2,0,
315,14,388,3,0,
315,14,390,1,28,
315,14,398,4,0,
315,14,402,2,0,
315,14,402,3,0,
315,14,412,4,0,
315,14,437,2,0,
315,14,447,4,0,
315,14,474,4,0,
315,14,496,4,0,
315,15,14,4,0,
315,15,15,4,0,
315,15,40,1,7,
315,15,42,2,0,
315,15,71,1,1,
315,15,72,1,13,
315,15,73,1,16,
315,15,74,1,4,
315,15,75,2,0,
315,15,76,4,0,
315,15,78,1,10,
315,15,79,2,0,
315,15,80,1,37,
315,15,92,1,40,
315,15,92,4,0,
315,15,104,4,0,
315,15,148,4,0,
315,15,156,4,0,
315,15,164,4,0,
315,15,170,2,0,
315,15,178,2,0,
315,15,182,4,0,
315,15,188,4,0,
315,15,191,2,0,
315,15,202,1,25,
315,15,202,2,0,
315,15,207,4,0,
315,15,213,4,0,
315,15,214,4,0,
315,15,216,4,0,
315,15,218,4,0,
315,15,230,1,31,
315,15,235,1,46,
315,15,235,2,0,
315,15,237,4,0,
315,15,240,4,0,
315,15,241,4,0,
315,15,244,4,0,
315,15,247,4,0,
315,15,263,4,0,
315,15,267,4,0,
315,15,275,1,34,
315,15,312,1,43,
315,15,320,1,22,
315,15,320,2,0,
315,15,331,2,0,
315,15,345,1,19,
315,15,363,2,0,
315,15,390,1,28,
315,15,398,4,0,
315,15,402,2,0,
315,15,412,4,0,
315,15,437,2,0,
315,15,447,4,0,
315,15,474,4,0,
315,15,496,4,0,
315,15,572,1,50,
315,15,590,4,0,
315,15,605,4,0,
315,16,14,4,0,
315,16,15,4,0,
315,16,40,1,7,1
315,16,42,2,0,
315,16,71,1,1,1
315,16,72,1,13,1
315,16,73,1,16,1
315,16,74,1,4,1
315,16,75,2,0,
315,16,76,4,0,
315,16,78,1,10,1
315,16,79,2,0,
315,16,80,1,50,1
315,16,92,1,40,1
315,16,92,4,0,
315,16,104,4,0,
315,16,148,4,0,
315,16,156,4,0,
315,16,164,4,0,
315,16,170,2,0,
315,16,173,3,0,
315,16,178,2,0,
315,16,182,4,0,
315,16,188,4,0,
315,16,191,2,0,
315,16,202,1,25,1
315,16,202,2,0,
315,16,202,3,0,
315,16,207,4,0,
315,16,213,4,0,
315,16,214,4,0,
315,16,216,4,0,
315,16,218,4,0,
315,16,230,1,31,1
315,16,235,1,46,1
315,16,235,2,0,
315,16,235,3,0,
315,16,237,4,0,
315,16,240,4,0,
315,16,241,4,0,
315,16,244,4,0,
315,16,247,4,0,
315,16,263,4,0,
315,16,267,4,0,
315,16,275,1,34,1
315,16,290,4,0,
315,16,312,1,43,1
315,16,320,1,22,1
315,16,320,2,0,
315,16,331,2,0,
315,16,343,3,0,
315,16,345,1,19,1
315,16,363,2,0,
315,16,388,3,0,
315,16,390,1,28,1
315,16,398,4,0,
315,16,402,2,0,
315,16,402,3,0,
315,16,412,4,0,
315,16,437,2,0,
315,16,447,4,0,
315,16,474,4,0,
315,16,496,4,0,
315,16,572,1,37,1
315,16,590,4,0,
315,16,605,4,0,
315,17,14,4,0,
315,17,40,1,7,
315,17,42,2,0,
315,17,71,1,1,
315,17,72,1,13,
315,17,73,1,16,
315,17,74,1,4,
315,17,75,2,0,
315,17,76,4,0,
315,17,78,1,10,
315,17,79,2,0,
315,17,80,1,50,
315,17,92,1,40,
315,17,92,4,0,
315,17,104,4,0,
315,17,156,4,0,
315,17,164,4,0,
315,17,170,2,0,
315,17,178,2,0,
315,17,182,4,0,
315,17,188,4,0,
315,17,191,2,0,
315,17,202,1,25,
315,17,202,2,0,
315,17,207,4,0,
315,17,213,4,0,
315,17,214,4,0,
315,17,216,4,0,
315,17,218,4,0,
315,17,230,1,31,
315,17,235,1,46,
315,17,235,2,0,
315,17,237,4,0,
315,17,240,4,0,
315,17,241,4,0,
315,17,244,4,0,
315,17,247,4,0,
315,17,263,4,0,
315,17,267,4,0,
315,17,275,1,34,
315,17,312,1,43,
315,17,320,1,22,
315,17,320,2,0,
315,17,331,2,0,
315,17,345,1,19,
315,17,363,2,0,
315,17,390,1,28,
315,17,398,4,0,
315,17,402,2,0,
315,17,412,4,0,
315,17,437,2,0,
315,17,447,4,0,
315,17,474,4,0,
315,17,496,4,0,
315,17,572,1,37,
315,17,590,4,0,
315,17,605,4,0,
315,18,14,4,0,
315,18,40,1,7,
315,18,42,2,0,
315,18,71,1,1,
315,18,72,1,13,
315,18,73,1,16,
315,18,74,1,4,
315,18,75,2,0,
315,18,76,4,0,
315,18,78,1,10,
315,18,79,2,0,
315,18,80,1,50,
315,18,92,1,40,
315,18,92,4,0,
315,18,104,4,0,
315,18,156,4,0,
315,18,164,4,0,
315,18,170,2,0,
315,18,178,2,0,
315,18,182,4,0,
315,18,188,4,0,
315,18,191,2,0,
315,18,202,1,25,
315,18,202,2,0,
315,18,207,4,0,
315,18,213,4,0,
315,18,214,4,0,
315,18,216,4,0,
315,18,218,4,0,
315,18,230,1,31,
315,18,235,1,46,
315,18,235,2,0,
315,18,237,4,0,
315,18,240,4,0,
315,18,241,4,0,
315,18,244,4,0,
315,18,247,4,0,
315,18,263,4,0,
315,18,267,4,0,
315,18,275,1,34,
315,18,312,1,43,
315,18,320,1,22,
315,18,320,2,0,
315,18,331,2,0,
315,18,345,1,19,
315,18,363,2,0,
315,18,390,1,28,
315,18,398,4,0,
315,18,402,2,0,
315,18,412,4,0,
315,18,437,2,0,
315,18,438,2,0,
315,18,447,4,0,
315,18,474,4,0,
315,18,496,4,0,
315,18,572,1,37,
315,18,590,4,0,
315,18,605,4,0,
316,5,1,1,1,
316,5,58,4,0,
316,5,70,4,0,
316,5,76,4,0,
316,5,92,1,28,
316,5,92,4,0,
316,5,104,4,0,
316,5,123,2,0,
316,5,124,1,14,
316,5,133,1,17,
316,5,138,2,0,
316,5,139,1,9,
316,5,151,2,0,
316,5,156,4,0,
316,5,182,4,0,
316,5,188,1,39,
316,5,188,4,0,
316,5,202,4,0,
316,5,213,4,0,
316,5,216,4,0,
316,5,218,4,0,
316,5,220,2,0,
316,5,227,1,23,
316,5,237,4,0,
316,5,240,4,0,
316,5,241,4,0,
316,5,247,4,0,
316,5,249,4,0,
316,5,254,1,34,1
316,5,255,1,34,2
316,5,256,1,34,3
316,5,263,4,0,
316,5,281,1,6,
316,5,289,4,0,
316,5,290,4,0,
316,5,331,4,0,
316,5,351,4,0,
316,5,352,4,0,
316,6,1,1,1,
316,6,7,3,0,
316,6,8,3,0,
316,6,9,3,0,
316,6,34,3,0,
316,6,38,3,0,
316,6,58,4,0,
316,6,68,3,0,
316,6,70,4,0,
316,6,76,4,0,
316,6,92,1,28,
316,6,92,4,0,
316,6,102,3,0,
316,6,104,4,0,
316,6,111,3,0,
316,6,123,2,0,
316,6,124,1,14,
316,6,133,1,17,
316,6,138,2,0,
316,6,138,3,0,
316,6,139,1,9,
316,6,151,2,0,
316,6,153,3,0,
316,6,156,4,0,
316,6,164,3,0,
316,6,173,3,0,
316,6,182,4,0,
316,6,188,1,39,
316,6,188,4,0,
316,6,189,3,0,
316,6,202,4,0,
316,6,203,3,0,
316,6,205,3,0,
316,6,207,3,0,
316,6,213,4,0,
316,6,214,3,0,
316,6,216,4,0,
316,6,218,4,0,
316,6,220,2,0,
316,6,223,3,0,
316,6,227,1,23,
316,6,237,4,0,
316,6,240,4,0,
316,6,241,4,0,
316,6,247,4,0,
316,6,249,4,0,
316,6,254,1,34,1
316,6,255,1,34,2
316,6,256,1,34,3
316,6,263,4,0,
316,6,281,1,6,
316,6,289,4,0,
316,6,290,4,0,
316,6,331,4,0,
316,6,351,4,0,
316,6,352,4,0,
316,7,1,1,1,
316,7,34,3,0,
316,7,38,3,0,
316,7,58,4,0,
316,7,68,3,0,
316,7,70,4,0,
316,7,76,4,0,
316,7,92,1,28,
316,7,92,4,0,
316,7,102,3,0,
316,7,104,4,0,
316,7,123,2,0,
316,7,124,1,14,
316,7,133,1,17,
316,7,138,2,0,
316,7,138,3,0,
316,7,139,1,9,
316,7,151,2,0,
316,7,153,3,0,
316,7,156,4,0,
316,7,164,3,0,
316,7,182,4,0,
316,7,188,1,39,
316,7,188,4,0,
316,7,202,4,0,
316,7,213,4,0,
316,7,216,4,0,
316,7,218,4,0,
316,7,220,2,0,
316,7,227,1,23,
316,7,237,4,0,
316,7,240,4,0,
316,7,241,4,0,
316,7,247,4,0,
316,7,249,4,0,
316,7,254,1,34,1
316,7,255,1,34,2
316,7,256,1,34,3
316,7,263,4,0,
316,7,281,1,6,
316,7,289,4,0,
316,7,290,4,0,
316,7,331,4,0,
316,7,351,4,0,
316,7,352,4,0,
316,8,1,1,1,
316,8,58,4,0,
316,8,70,4,0,
316,8,76,4,0,
316,8,92,1,28,
316,8,92,4,0,
316,8,104,4,0,
316,8,123,2,0,
316,8,124,1,14,
316,8,133,1,17,
316,8,138,2,0,
316,8,138,4,0,
316,8,139,1,9,
316,8,151,2,0,
316,8,153,4,0,
316,8,156,4,0,
316,8,164,4,0,
316,8,174,2,0,
316,8,182,4,0,
316,8,188,1,39,
316,8,188,4,0,
316,8,194,2,0,
316,8,202,4,0,
316,8,203,4,0,
316,8,207,4,0,
316,8,213,4,0,
316,8,214,4,0,
316,8,216,4,0,
316,8,218,4,0,
316,8,220,2,0,
316,8,227,1,23,
316,8,237,4,0,
316,8,240,4,0,
316,8,241,4,0,
316,8,247,4,0,
316,8,249,4,0,
316,8,254,1,34,1
316,8,255,1,34,2
316,8,256,1,34,3
316,8,263,4,0,
316,8,281,1,6,
316,8,289,4,0,
316,8,290,4,0,
316,8,331,4,0,
316,8,351,4,0,
316,8,352,4,0,
316,8,363,4,0,
316,8,378,1,49,
316,8,380,1,44,
316,8,441,1,54,
316,8,445,4,0,
316,9,1,1,1,
316,9,7,3,0,
316,9,8,3,0,
316,9,9,3,0,
316,9,58,4,0,
316,9,70,4,0,
316,9,76,4,0,
316,9,92,1,28,
316,9,92,4,0,
316,9,104,4,0,
316,9,123,2,0,
316,9,124,1,14,
316,9,133,1,17,
316,9,138,2,0,
316,9,138,4,0,
316,9,139,1,9,
316,9,151,2,0,
316,9,153,4,0,
316,9,156,4,0,
316,9,164,4,0,
316,9,173,3,0,
316,9,174,2,0,
316,9,182,4,0,
316,9,188,1,39,
316,9,188,4,0,
316,9,189,3,0,
316,9,194,2,0,
316,9,202,4,0,
316,9,203,4,0,
316,9,205,3,0,
316,9,207,4,0,
316,9,213,4,0,
316,9,214,4,0,
316,9,216,4,0,
316,9,218,4,0,
316,9,220,2,0,
316,9,227,1,23,
316,9,237,4,0,
316,9,240,4,0,
316,9,241,4,0,
316,9,247,4,0,
316,9,249,4,0,
316,9,254,1,34,1
316,9,255,1,34,2
316,9,256,1,34,3
316,9,263,4,0,
316,9,281,1,6,
316,9,289,4,0,
316,9,290,4,0,
316,9,331,4,0,
316,9,351,4,0,
316,9,352,4,0,
316,9,363,4,0,
316,9,378,1,49,
316,9,380,1,44,
316,9,380,3,0,
316,9,402,3,0,
316,9,441,1,54,
316,9,441,3,0,
316,9,445,4,0,
316,10,1,1,1,
316,10,7,3,0,
316,10,8,3,0,
316,10,9,3,0,
316,10,29,3,0,
316,10,58,4,0,
316,10,70,4,0,
316,10,76,4,0,
316,10,92,1,28,
316,10,92,4,0,
316,10,104,4,0,
316,10,123,2,0,
316,10,124,1,14,
316,10,133,1,17,
316,10,138,2,0,
316,10,138,4,0,
316,10,139,1,9,
316,10,151,2,0,
316,10,153,4,0,
316,10,156,4,0,
316,10,164,4,0,
316,10,173,3,0,
316,10,174,2,0,
316,10,182,4,0,
316,10,188,1,39,
316,10,188,4,0,
316,10,189,3,0,
316,10,194,2,0,
316,10,202,4,0,
316,10,203,4,0,
316,10,205,3,0,
316,10,207,4,0,
316,10,213,4,0,
316,10,214,4,0,
316,10,216,4,0,
316,10,218,4,0,
316,10,220,2,0,
316,10,220,3,0,
316,10,227,1,23,
316,10,237,4,0,
316,10,240,4,0,
316,10,241,4,0,
316,10,247,4,0,
316,10,249,4,0,
316,10,254,1,34,1
316,10,255,1,34,2
316,10,256,1,34,3
316,10,263,4,0,
316,10,281,1,6,
316,10,289,4,0,
316,10,290,4,0,
316,10,331,4,0,
316,10,351,4,0,
316,10,352,4,0,
316,10,363,4,0,
316,10,378,1,49,
316,10,380,1,44,
316,10,380,3,0,
316,10,402,3,0,
316,10,441,1,54,
316,10,441,3,0,
316,10,445,4,0,
316,11,1,1,1,
316,11,58,4,0,
316,11,70,4,0,
316,11,76,4,0,
316,11,92,1,28,
316,11,92,4,0,
316,11,104,4,0,
316,11,123,2,0,
316,11,124,1,14,
316,11,133,1,17,
316,11,138,4,0,
316,11,139,1,9,
316,11,151,2,0,
316,11,153,4,0,
316,11,156,4,0,
316,11,164,4,0,
316,11,174,2,0,
316,11,182,4,0,
316,11,188,1,44,
316,11,188,4,0,
316,11,189,2,0,
316,11,194,2,0,
316,11,207,4,0,
316,11,213,4,0,
316,11,216,4,0,
316,11,218,4,0,
316,11,220,2,0,
316,11,227,1,23,
316,11,237,4,0,
316,11,240,4,0,
316,11,241,4,0,
316,11,247,4,0,
316,11,249,4,0,
316,11,254,1,39,1
316,11,255,1,39,2
316,11,256,1,39,3
316,11,263,4,0,
316,11,281,1,6,
316,11,378,1,54,
316,11,380,1,49,
316,11,441,1,59,
316,11,441,2,0,
316,11,474,4,0,
316,11,482,4,0,
316,11,491,1,34,
316,11,496,4,0,
316,12,1,1,1,
316,12,58,4,0,
316,12,70,4,0,
316,12,76,4,0,
316,12,92,1,28,
316,12,92,4,0,
316,12,104,4,0,
316,12,124,1,14,
316,12,133,1,17,
316,12,139,1,9,
316,12,156,4,0,
316,12,182,4,0,
316,12,188,1,39,
316,12,188,4,0,
316,12,202,4,0,
316,12,213,4,0,
316,12,216,4,0,
316,12,218,4,0,
316,12,227,1,23,
316,12,237,4,0,
316,12,240,4,0,
316,12,241,4,0,
316,12,247,4,0,
316,12,249,4,0,
316,12,254,1,34,1
316,12,255,1,34,2
316,12,256,1,34,3
316,12,263,4,0,
316,12,281,1,6,
316,12,289,4,0,
316,12,290,4,0,
316,12,331,4,0,
316,12,351,4,0,
316,12,352,4,0,
316,13,1,1,1,
316,13,34,3,0,
316,13,38,3,0,
316,13,58,4,0,
316,13,70,4,0,
316,13,76,4,0,
316,13,92,1,28,
316,13,92,4,0,
316,13,102,3,0,
316,13,104,4,0,
316,13,120,3,0,
316,13,124,1,14,
316,13,133,1,17,
316,13,138,3,0,
316,13,139,1,9,
316,13,156,4,0,
316,13,164,3,0,
316,13,171,3,0,
316,13,182,4,0,
316,13,188,1,39,
316,13,188,4,0,
316,13,202,4,0,
316,13,207,3,0,
316,13,213,4,0,
316,13,216,4,0,
316,13,218,4,0,
316,13,227,1,23,
316,13,237,4,0,
316,13,240,4,0,
316,13,241,4,0,
316,13,247,4,0,
316,13,249,4,0,
316,13,254,1,34,1
316,13,255,1,34,2
316,13,256,1,34,3
316,13,263,4,0,
316,13,281,1,6,
316,13,289,4,0,
316,13,290,4,0,
316,13,331,4,0,
316,13,351,4,0,
316,13,352,4,0,
316,14,1,1,1,
316,14,7,3,0,
316,14,8,3,0,
316,14,9,3,0,
316,14,58,4,0,
316,14,70,4,0,
316,14,76,4,0,
316,14,92,1,28,
316,14,92,4,0,
316,14,104,4,0,
316,14,123,2,0,
316,14,124,1,14,
316,14,133,1,17,
316,14,138,4,0,
316,14,139,1,9,
316,14,151,2,0,
316,14,153,4,0,
316,14,156,4,0,
316,14,164,4,0,
316,14,173,3,0,
316,14,174,2,0,
316,14,182,4,0,
316,14,188,1,44,
316,14,188,4,0,
316,14,189,2,0,
316,14,194,2,0,
316,14,202,3,0,
316,14,207,4,0,
316,14,213,4,0,
316,14,214,3,0,
316,14,216,4,0,
316,14,218,4,0,
316,14,220,2,0,
316,14,220,3,0,
316,14,227,1,23,
316,14,237,4,0,
316,14,240,4,0,
316,14,241,4,0,
316,14,247,4,0,
316,14,249,4,0,
316,14,254,1,39,1
316,14,255,1,39,2
316,14,256,1,39,3
316,14,263,4,0,
316,14,281,1,6,
316,14,289,3,0,
316,14,378,1,54,
316,14,380,1,49,
316,14,380,3,0,
316,14,402,3,0,
316,14,441,1,59,
316,14,441,2,0,
316,14,441,3,0,
316,14,474,4,0,
316,14,482,4,0,
316,14,491,1,34,
316,14,496,4,0,
316,15,1,1,1,
316,15,58,4,0,
316,15,70,4,0,
316,15,76,4,0,
316,15,92,1,28,
316,15,92,4,0,
316,15,104,4,0,
316,15,123,2,0,
316,15,124,1,14,
316,15,133,1,17,
316,15,138,4,0,
316,15,139,1,9,
316,15,151,2,0,
316,15,153,4,0,
316,15,156,4,0,
316,15,164,4,0,
316,15,174,2,0,
316,15,182,4,0,
316,15,188,1,44,
316,15,188,4,0,
316,15,189,2,0,
316,15,194,2,0,
316,15,207,4,0,
316,15,213,4,0,
316,15,214,4,0,
316,15,216,4,0,
316,15,218,4,0,
316,15,220,2,0,
316,15,227,1,23,
316,15,237,4,0,
316,15,240,4,0,
316,15,241,4,0,
316,15,247,4,0,
316,15,249,4,0,
316,15,254,1,39,1
316,15,255,1,39,2
316,15,256,1,39,3
316,15,263,4,0,
316,15,281,1,6,
316,15,378,1,54,
316,15,380,1,49,
316,15,441,1,59,
316,15,441,2,0,
316,15,474,4,0,
316,15,482,4,0,
316,15,491,1,34,
316,15,496,4,0,
316,15,562,1,40,
316,15,590,4,0,
316,15,599,2,0,
316,15,611,4,0,
316,15,612,4,0,
316,16,1,1,1,1
316,16,7,3,0,
316,16,8,3,0,
316,16,9,3,0,
316,16,58,4,0,
316,16,70,4,0,
316,16,76,4,0,
316,16,92,1,25,1
316,16,92,4,0,
316,16,104,4,0,
316,16,123,2,0,
316,16,124,1,10,1
316,16,133,1,12,1
316,16,138,4,0,
316,16,139,1,8,1
316,16,151,2,0,
316,16,153,4,0,
316,16,156,4,0,
316,16,164,4,0,
316,16,173,3,0,
316,16,174,2,0,
316,16,182,4,0,
316,16,188,1,33,1
316,16,188,4,0,
316,16,189,2,0,
316,16,194,2,0,
316,16,202,3,0,
316,16,207,4,0,
316,16,213,4,0,
316,16,214,4,0,
316,16,216,4,0,
316,16,218,4,0,
316,16,220,2,0,
316,16,220,3,0,
316,16,227,1,20,1
316,16,237,4,0,
316,16,240,4,0,
316,16,241,4,0,
316,16,247,4,0,
316,16,249,4,0,
316,16,254,1,28,1
316,16,255,1,28,2
316,16,256,1,28,3
316,16,263,4,0,
316,16,281,1,5,1
316,16,289,3,0,
316,16,290,4,0,
316,16,351,3,0,
316,16,352,3,0,
316,16,378,1,44,1
316,16,380,1,36,1
316,16,380,3,0,
316,16,402,3,0,
316,16,441,1,49,1
316,16,441,2,0,
316,16,441,3,0,
316,16,474,4,0,
316,16,482,4,0,
316,16,491,1,17,1
316,16,496,4,0,
316,16,562,1,41,1
316,16,590,4,0,
316,16,599,2,0,
316,16,611,4,0,
316,16,612,4,0,
316,17,1,1,1,
316,17,58,4,0,
316,17,76,4,0,
316,17,92,1,25,
316,17,92,4,0,
316,17,104,4,0,
316,17,123,2,0,
316,17,124,1,10,
316,17,133,1,12,
316,17,138,4,0,
316,17,139,1,8,
316,17,151,2,0,
316,17,153,4,0,
316,17,156,4,0,
316,17,164,4,0,
316,17,174,2,0,
316,17,182,4,0,
316,17,188,1,33,
316,17,188,4,0,
316,17,189,2,0,
316,17,194,2,0,
316,17,207,4,0,
316,17,213,4,0,
316,17,214,4,0,
316,17,216,4,0,
316,17,218,4,0,
316,17,220,2,0,
316,17,227,1,20,
316,17,237,4,0,
316,17,240,4,0,
316,17,241,4,0,
316,17,247,4,0,
316,17,254,1,28,1
316,17,255,1,28,2
316,17,256,1,28,3
316,17,263,4,0,
316,17,281,1,5,
316,17,378,1,44,
316,17,380,1,36,
316,17,441,1,49,
316,17,441,2,0,
316,17,474,4,0,
316,17,482,4,0,
316,17,491,1,17,
316,17,496,4,0,
316,17,562,1,41,
316,17,590,4,0,
316,17,599,2,0,
316,17,611,4,0,
316,18,1,1,1,
316,18,58,4,0,
316,18,76,4,0,
316,18,92,1,25,
316,18,92,4,0,
316,18,104,4,0,
316,18,123,2,0,
316,18,124,1,10,
316,18,133,1,12,
316,18,138,4,0,
316,18,139,1,8,
316,18,151,2,0,
316,18,153,4,0,
316,18,156,4,0,
316,18,164,4,0,
316,18,174,2,0,
316,18,182,4,0,
316,18,188,1,33,
316,18,188,4,0,
316,18,189,2,0,
316,18,194,2,0,
316,18,207,4,0,
316,18,213,4,0,
316,18,214,4,0,
316,18,216,4,0,
316,18,218,4,0,
316,18,220,2,0,
316,18,227,1,20,
316,18,237,4,0,
316,18,240,4,0,
316,18,241,4,0,
316,18,247,4,0,
316,18,254,1,28,1
316,18,255,1,28,2
316,18,256,1,28,3
316,18,263,4,0,
316,18,281,1,5,
316,18,378,1,44,
316,18,380,1,36,
316,18,441,1,49,
316,18,441,2,0,
316,18,474,4,0,
316,18,482,4,0,
316,18,491,1,17,
316,18,496,4,0,
316,18,562,1,41,
316,18,590,4,0,
316,18,599,2,0,
316,18,611,4,0,
317,5,1,1,1,1
317,5,34,1,26,
317,5,58,4,0,
317,5,63,4,0,
317,5,70,4,0,
317,5,76,4,0,
317,5,92,1,31,
317,5,92,4,0,
317,5,104,4,0,
317,5,124,1,1,4
317,5,124,1,14,
317,5,133,1,17,
317,5,139,1,1,3
317,5,139,1,9,
317,5,156,4,0,
317,5,182,4,0,
317,5,188,1,48,
317,5,188,4,0,
317,5,202,4,0,
317,5,213,4,0,
317,5,216,4,0,
317,5,218,4,0,
317,5,227,1,23,
317,5,237,4,0,
317,5,240,4,0,
317,5,241,4,0,
317,5,247,4,0,
317,5,249,4,0,
317,5,254,1,40,
317,5,255,1,40,5
317,5,256,1,40,6
317,5,263,4,0,
317,5,281,1,1,2
317,5,281,1,6,
317,5,289,4,0,
317,5,290,4,0,
317,5,331,4,0,
317,5,351,4,0,
317,5,352,4,0,
317,6,1,1,1,1
317,6,7,3,0,
317,6,8,3,0,
317,6,9,3,0,
317,6,34,1,26,
317,6,34,3,0,
317,6,38,3,0,
317,6,58,4,0,
317,6,63,4,0,
317,6,68,3,0,
317,6,70,4,0,
317,6,76,4,0,
317,6,92,1,31,
317,6,92,4,0,
317,6,102,3,0,
317,6,104,4,0,
317,6,111,3,0,
317,6,124,1,1,4
317,6,124,1,14,
317,6,133,1,17,
317,6,138,3,0,
317,6,139,1,1,3
317,6,139,1,9,
317,6,153,3,0,
317,6,156,4,0,
317,6,164,3,0,
317,6,173,3,0,
317,6,182,4,0,
317,6,188,1,48,
317,6,188,4,0,
317,6,189,3,0,
317,6,202,4,0,
317,6,203,3,0,
317,6,205,3,0,
317,6,207,3,0,
317,6,213,4,0,
317,6,214,3,0,
317,6,216,4,0,
317,6,218,4,0,
317,6,223,3,0,
317,6,227,1,23,
317,6,237,4,0,
317,6,240,4,0,
317,6,241,4,0,
317,6,247,4,0,
317,6,249,4,0,
317,6,254,1,40,
317,6,255,1,40,5
317,6,256,1,40,6
317,6,263,4,0,
317,6,281,1,1,2
317,6,281,1,6,
317,6,289,4,0,
317,6,290,4,0,
317,6,331,4,0,
317,6,351,4,0,
317,6,352,4,0,
317,7,1,1,1,1
317,7,34,1,26,
317,7,34,3,0,
317,7,38,3,0,
317,7,58,4,0,
317,7,63,4,0,
317,7,68,3,0,
317,7,70,4,0,
317,7,76,4,0,
317,7,92,1,31,
317,7,92,4,0,
317,7,102,3,0,
317,7,104,4,0,
317,7,124,1,1,4
317,7,124,1,14,
317,7,133,1,17,
317,7,138,3,0,
317,7,139,1,1,3
317,7,139,1,9,
317,7,153,3,0,
317,7,156,4,0,
317,7,164,3,0,
317,7,182,4,0,
317,7,188,1,48,
317,7,188,4,0,
317,7,202,4,0,
317,7,213,4,0,
317,7,216,4,0,
317,7,218,4,0,
317,7,227,1,23,
317,7,237,4,0,
317,7,240,4,0,
317,7,241,4,0,
317,7,247,4,0,
317,7,249,4,0,
317,7,254,1,40,
317,7,255,1,40,5
317,7,256,1,40,6
317,7,263,4,0,
317,7,281,1,1,2
317,7,281,1,6,
317,7,289,4,0,
317,7,290,4,0,
317,7,331,4,0,
317,7,351,4,0,
317,7,352,4,0,
317,8,1,1,1,1
317,8,34,1,26,
317,8,58,4,0,
317,8,63,4,0,
317,8,70,4,0,
317,8,76,4,0,
317,8,89,4,0,
317,8,92,1,30,
317,8,92,4,0,
317,8,104,4,0,
317,8,124,1,1,4
317,8,124,1,14,
317,8,133,1,17,
317,8,138,4,0,
317,8,139,1,1,3
317,8,139,1,9,
317,8,153,4,0,
317,8,156,4,0,
317,8,164,4,0,
317,8,182,4,0,
317,8,188,1,45,
317,8,188,4,0,
317,8,202,4,0,
317,8,203,4,0,
317,8,207,4,0,
317,8,213,4,0,
317,8,214,4,0,
317,8,216,4,0,
317,8,218,4,0,
317,8,227,1,23,
317,8,237,4,0,
317,8,240,4,0,
317,8,241,4,0,
317,8,247,4,0,
317,8,249,4,0,
317,8,254,1,38,1
317,8,255,1,38,2
317,8,256,1,38,3
317,8,263,4,0,
317,8,281,1,1,2
317,8,281,1,6,
317,8,289,4,0,
317,8,290,4,0,
317,8,331,4,0,
317,8,351,4,0,
317,8,352,4,0,
317,8,363,4,0,
317,8,378,1,59,
317,8,380,1,52,
317,8,416,4,0,
317,8,441,1,66,
317,8,445,4,0,
317,9,1,1,1,1
317,9,7,3,0,
317,9,8,3,0,
317,9,9,3,0,
317,9,34,1,26,
317,9,58,4,0,
317,9,63,4,0,
317,9,70,4,0,
317,9,76,4,0,
317,9,89,4,0,
317,9,92,1,30,
317,9,92,4,0,
317,9,104,4,0,
317,9,124,1,1,4
317,9,124,1,14,
317,9,133,1,17,
317,9,138,4,0,
317,9,139,1,1,3
317,9,139,1,9,
317,9,153,4,0,
317,9,156,4,0,
317,9,164,4,0,
317,9,173,3,0,
317,9,182,4,0,
317,9,188,1,45,
317,9,188,4,0,
317,9,189,3,0,
317,9,202,4,0,
317,9,203,4,0,
317,9,205,3,0,
317,9,207,4,0,
317,9,213,4,0,
317,9,214,4,0,
317,9,216,4,0,
317,9,218,4,0,
317,9,227,1,23,
317,9,237,4,0,
317,9,240,4,0,
317,9,241,4,0,
317,9,247,4,0,
317,9,249,4,0,
317,9,254,1,38,1
317,9,255,1,38,2
317,9,256,1,38,3
317,9,263,4,0,
317,9,281,1,1,2
317,9,281,1,6,
317,9,289,4,0,
317,9,290,4,0,
317,9,331,4,0,
317,9,351,4,0,
317,9,352,4,0,
317,9,363,4,0,
317,9,378,1,59,
317,9,380,1,52,
317,9,380,3,0,
317,9,402,3,0,
317,9,416,4,0,
317,9,441,1,66,
317,9,441,3,0,
317,9,445,4,0,
317,10,1,1,1,1
317,10,7,3,0,
317,10,8,3,0,
317,10,9,3,0,
317,10,29,3,0,
317,10,34,1,26,
317,10,58,4,0,
317,10,63,4,0,
317,10,70,4,0,
317,10,76,4,0,
317,10,89,4,0,
317,10,92,1,30,
317,10,92,4,0,
317,10,104,4,0,
317,10,124,1,1,4
317,10,124,1,14,
317,10,133,1,17,
317,10,138,4,0,
317,10,139,1,1,3
317,10,139,1,9,
317,10,153,4,0,
317,10,156,4,0,
317,10,164,4,0,
317,10,173,3,0,
317,10,182,4,0,
317,10,188,1,45,
317,10,188,4,0,
317,10,189,3,0,
317,10,202,4,0,
317,10,203,4,0,
317,10,205,3,0,
317,10,207,4,0,
317,10,213,4,0,
317,10,214,4,0,
317,10,216,4,0,
317,10,218,4,0,
317,10,220,3,0,
317,10,227,1,23,
317,10,237,4,0,
317,10,240,4,0,
317,10,241,4,0,
317,10,247,4,0,
317,10,249,4,0,
317,10,254,1,38,1
317,10,255,1,38,2
317,10,256,1,38,3
317,10,263,4,0,
317,10,281,1,1,2
317,10,281,1,6,
317,10,289,4,0,
317,10,290,4,0,
317,10,331,4,0,
317,10,335,3,0,
317,10,351,4,0,
317,10,352,4,0,
317,10,363,4,0,
317,10,378,1,59,
317,10,380,1,52,
317,10,380,3,0,
317,10,402,3,0,
317,10,416,4,0,
317,10,441,1,66,
317,10,441,3,0,
317,10,445,4,0,
317,11,1,1,1,1
317,11,34,1,26,
317,11,58,4,0,
317,11,63,4,0,
317,11,70,4,0,
317,11,76,4,0,
317,11,89,4,0,
317,11,92,1,30,
317,11,92,4,0,
317,11,104,4,0,
317,11,124,1,1,4
317,11,124,1,14,
317,11,133,1,17,
317,11,138,4,0,
317,11,139,1,1,3
317,11,139,1,9,
317,11,153,4,0,
317,11,156,4,0,
317,11,164,4,0,
317,11,182,4,0,
317,11,188,1,52,
317,11,188,4,0,
317,11,207,4,0,
317,11,213,4,0,
317,11,216,4,0,
317,11,218,4,0,
317,11,227,1,23,
317,11,237,4,0,
317,11,240,4,0,
317,11,241,4,0,
317,11,247,4,0,
317,11,249,4,0,
317,11,254,1,45,1
317,11,255,1,45,2
317,11,256,1,45,3
317,11,263,4,0,
317,11,281,1,1,2
317,11,281,1,6,
317,11,378,1,66,
317,11,380,1,59,
317,11,416,4,0,
317,11,441,1,73,
317,11,474,4,0,
317,11,482,4,0,
317,11,491,1,38,
317,11,496,4,0,
317,11,523,4,0,
317,12,1,1,1,1
317,12,34,1,26,
317,12,58,4,0,
317,12,63,4,0,
317,12,70,4,0,
317,12,76,4,0,
317,12,92,1,31,
317,12,92,4,0,
317,12,104,4,0,
317,12,124,1,1,4
317,12,124,1,14,
317,12,133,1,17,
317,12,139,1,1,3
317,12,139,1,9,
317,12,156,4,0,
317,12,182,4,0,
317,12,188,1,48,
317,12,188,4,0,
317,12,202,4,0,
317,12,213,4,0,
317,12,216,4,0,
317,12,218,4,0,
317,12,227,1,23,
317,12,237,4,0,
317,12,240,4,0,
317,12,241,4,0,
317,12,247,4,0,
317,12,249,4,0,
317,12,254,1,40,
317,12,255,1,40,5
317,12,256,1,40,6
317,12,263,4,0,
317,12,281,1,1,2
317,12,281,1,6,
317,12,289,4,0,
317,12,290,4,0,
317,12,331,4,0,
317,12,351,4,0,
317,12,352,4,0,
317,13,1,1,1,1
317,13,34,1,26,
317,13,34,3,0,
317,13,38,3,0,
317,13,58,4,0,
317,13,63,4,0,
317,13,70,4,0,
317,13,76,4,0,
317,13,92,1,31,
317,13,92,4,0,
317,13,102,3,0,
317,13,104,4,0,
317,13,120,3,0,
317,13,124,1,1,4
317,13,124,1,14,
317,13,133,1,17,
317,13,138,3,0,
317,13,139,1,1,3
317,13,139,1,9,
317,13,156,4,0,
317,13,164,3,0,
317,13,171,3,0,
317,13,182,4,0,
317,13,188,1,48,
317,13,188,4,0,
317,13,202,4,0,
317,13,207,3,0,
317,13,213,4,0,
317,13,216,4,0,
317,13,218,4,0,
317,13,227,1,23,
317,13,237,4,0,
317,13,240,4,0,
317,13,241,4,0,
317,13,247,4,0,
317,13,249,4,0,
317,13,254,1,40,
317,13,255,1,40,5
317,13,256,1,40,6
317,13,263,4,0,
317,13,281,1,1,2
317,13,281,1,6,
317,13,289,4,0,
317,13,290,4,0,
317,13,331,4,0,
317,13,351,4,0,
317,13,352,4,0,
317,14,1,1,1,1
317,14,7,3,0,
317,14,8,3,0,
317,14,9,3,0,
317,14,34,1,26,
317,14,58,4,0,
317,14,63,4,0,
317,14,70,4,0,
317,14,76,4,0,
317,14,89,4,0,
317,14,92,1,30,
317,14,92,4,0,
317,14,104,4,0,
317,14,124,1,1,4
317,14,124,1,14,
317,14,133,1,17,
317,14,138,4,0,
317,14,139,1,1,3
317,14,139,1,9,
317,14,153,4,0,
317,14,156,4,0,
317,14,164,4,0,
317,14,173,3,0,
317,14,182,4,0,
317,14,188,1,52,
317,14,188,4,0,
317,14,202,3,0,
317,14,207,4,0,
317,14,213,4,0,
317,14,214,3,0,
317,14,216,4,0,
317,14,218,4,0,
317,14,220,3,0,
317,14,227,1,23,
317,14,237,4,0,
317,14,240,4,0,
317,14,241,4,0,
317,14,247,4,0,
317,14,249,4,0,
317,14,254,1,45,1
317,14,255,1,45,2
317,14,256,1,45,3
317,14,263,4,0,
317,14,281,1,1,2
317,14,281,1,6,
317,14,289,3,0,
317,14,335,3,0,
317,14,378,1,66,
317,14,380,1,59,
317,14,380,3,0,
317,14,402,3,0,
317,14,416,4,0,
317,14,441,1,73,
317,14,441,3,0,
317,14,474,4,0,
317,14,482,4,0,
317,14,491,1,38,
317,14,496,4,0,
317,14,523,4,0,
317,15,1,1,1,3
317,15,34,1,26,
317,15,58,4,0,
317,15,63,4,0,
317,15,70,4,0,
317,15,76,4,0,
317,15,89,4,0,
317,15,92,1,30,
317,15,92,4,0,
317,15,104,4,0,
317,15,124,1,1,6
317,15,124,1,14,
317,15,133,1,17,
317,15,138,4,0,
317,15,139,1,1,5
317,15,139,1,9,
317,15,153,4,0,
317,15,156,4,0,
317,15,164,4,0,
317,15,182,4,0,
317,15,188,1,52,
317,15,188,4,0,
317,15,207,4,0,
317,15,213,4,0,
317,15,214,4,0,
317,15,216,4,0,
317,15,218,4,0,
317,15,227,1,23,
317,15,237,4,0,
317,15,240,4,0,
317,15,241,4,0,
317,15,247,4,0,
317,15,249,4,0,
317,15,254,1,45,1
317,15,255,1,45,2
317,15,256,1,45,3
317,15,263,4,0,
317,15,281,1,1,4
317,15,281,1,6,
317,15,378,1,1,2
317,15,378,1,66,
317,15,380,1,59,
317,15,416,4,0,
317,15,441,1,1,1
317,15,441,1,73,
317,15,474,4,0,
317,15,482,4,0,
317,15,491,1,38,
317,15,496,4,0,
317,15,523,4,0,
317,15,562,1,46,
317,15,590,4,0,
317,15,611,4,0,
317,15,612,4,0,
317,16,1,1,1,3
317,16,7,3,0,
317,16,8,3,0,
317,16,9,3,0,
317,16,34,1,26,1
317,16,58,4,0,
317,16,63,4,0,
317,16,70,4,0,
317,16,76,4,0,
317,16,89,4,0,
317,16,92,1,25,1
317,16,92,4,0,
317,16,104,4,0,
317,16,124,1,1,6
317,16,124,1,10,1
317,16,133,1,12,1
317,16,138,4,0,
317,16,139,1,1,5
317,16,139,1,8,1
317,16,153,4,0,
317,16,156,4,0,
317,16,164,4,0,
317,16,173,3,0,
317,16,182,4,0,
317,16,188,1,37,1
317,16,188,4,0,
317,16,202,3,0,
317,16,207,4,0,
317,16,213,4,0,
317,16,214,4,0,
317,16,216,4,0,
317,16,218,4,0,
317,16,220,3,0,
317,16,227,1,20,1
317,16,237,4,0,
317,16,240,4,0,
317,16,241,4,0,
317,16,247,4,0,
317,16,249,4,0,
317,16,254,1,30,1
317,16,255,1,30,2
317,16,256,1,30,3
317,16,263,4,0,
317,16,281,1,1,4
317,16,281,1,5,1
317,16,289,3,0,
317,16,290,4,0,
317,16,335,3,0,
317,16,351,3,0,
317,16,352,3,0,
317,16,378,1,1,2
317,16,378,1,54,1
317,16,380,1,42,1
317,16,380,3,0,
317,16,402,3,0,
317,16,416,4,0,
317,16,441,1,1,1
317,16,441,1,61,1
317,16,441,3,0,
317,16,474,4,0,
317,16,482,4,0,
317,16,491,1,17,1
317,16,496,4,0,
317,16,523,4,0,
317,16,562,1,49,1
317,16,590,4,0,
317,16,611,4,0,
317,16,612,4,0,
317,17,1,1,1,5
317,17,34,1,0,
317,17,34,1,1,1
317,17,58,4,0,
317,17,63,4,0,
317,17,76,4,0,
317,17,89,4,0,
317,17,92,1,25,
317,17,92,4,0,
317,17,104,4,0,
317,17,124,1,1,8
317,17,124,1,10,
317,17,133,1,12,
317,17,138,4,0,
317,17,139,1,1,7
317,17,139,1,8,
317,17,153,4,0,
317,17,156,4,0,
317,17,164,4,0,
317,17,182,4,0,
317,17,188,1,37,
317,17,188,4,0,
317,17,207,4,0,
317,17,213,4,0,
317,17,214,4,0,
317,17,216,4,0,
317,17,218,4,0,
317,17,227,1,20,
317,17,237,4,0,
317,17,240,4,0,
317,17,241,4,0,
317,17,247,4,0,
317,17,254,1,30,1
317,17,255,1,30,2
317,17,256,1,30,3
317,17,263,4,0,
317,17,281,1,1,6
317,17,281,1,5,
317,17,378,1,1,4
317,17,378,1,54,
317,17,380,1,42,
317,17,416,4,0,
317,17,441,1,1,3
317,17,441,1,61,
317,17,474,4,0,
317,17,482,4,0,
317,17,491,1,17,
317,17,496,4,0,
317,17,523,4,0,
317,17,562,1,49,
317,17,590,4,0,
317,17,599,1,1,2
317,17,611,4,0,
317,18,1,1,1,5
317,18,34,1,0,
317,18,34,1,1,1
317,18,58,4,0,
317,18,63,4,0,
317,18,76,4,0,
317,18,89,4,0,
317,18,92,1,25,
317,18,92,4,0,
317,18,104,4,0,
317,18,124,1,1,8
317,18,124,1,10,
317,18,133,1,12,
317,18,138,4,0,
317,18,139,1,1,7
317,18,139,1,8,
317,18,153,4,0,
317,18,156,4,0,
317,18,164,4,0,
317,18,182,4,0,
317,18,188,1,37,
317,18,188,4,0,
317,18,207,4,0,
317,18,213,4,0,
317,18,214,4,0,
317,18,216,4,0,
317,18,218,4,0,
317,18,227,1,20,
317,18,237,4,0,
317,18,240,4,0,
317,18,241,4,0,
317,18,247,4,0,
317,18,254,1,30,1
317,18,255,1,30,2
317,18,256,1,30,3
317,18,263,4,0,
317,18,281,1,1,6
317,18,281,1,5,
317,18,378,1,1,4
317,18,378,1,54,
317,18,380,1,42,
317,18,416,4,0,
317,18,441,1,1,3
317,18,441,1,61,
317,18,474,4,0,
317,18,482,4,0,
317,18,491,1,17,
317,18,496,4,0,
317,18,523,4,0,
317,18,562,1,49,
317,18,590,4,0,
317,18,599,1,1,2
317,18,611,4,0,
318,5,36,1,31,
318,5,37,2,0,
318,5,38,2,0,
318,5,43,1,1,1
318,5,44,1,1,2
318,5,56,2,0,
318,5,57,4,0,
318,5,58,4,0,
318,5,59,4,0,
318,5,92,4,0,
318,5,97,1,43,
318,5,99,1,7,
318,5,103,1,28,
318,5,104,4,0,
318,5,116,1,13,
318,5,127,4,0,
318,5,156,4,0,
318,5,168,4,0,
318,5,182,4,0,
318,5,184,1,16,
318,5,207,1,37,
318,5,213,4,0,
318,5,216,4,0,
318,5,218,4,0,
318,5,237,4,0,
318,5,240,4,0,
318,5,242,1,22,
318,5,258,4,0,
318,5,259,4,0,
318,5,263,4,0,
318,5,269,4,0,
318,5,290,4,0,
318,5,291,4,0,
318,5,352,4,0,
318,6,36,1,31,
318,6,37,2,0,
318,6,38,2,0,
318,6,38,3,0,
318,6,43,1,1,1
318,6,44,1,1,2
318,6,56,2,0,
318,6,57,4,0,
318,6,58,4,0,
318,6,59,4,0,
318,6,92,4,0,
318,6,97,1,43,
318,6,99,1,7,
318,6,102,3,0,
318,6,103,1,28,
318,6,104,4,0,
318,6,116,1,13,
318,6,127,4,0,
318,6,129,3,0,
318,6,156,4,0,
318,6,164,3,0,
318,6,168,4,0,
318,6,173,3,0,
318,6,182,4,0,
318,6,184,1,16,
318,6,189,3,0,
318,6,196,3,0,
318,6,203,3,0,
318,6,207,1,37,
318,6,207,3,0,
318,6,210,3,0,
318,6,213,4,0,
318,6,214,3,0,
318,6,216,4,0,
318,6,218,4,0,
318,6,237,4,0,
318,6,240,4,0,
318,6,242,1,22,
318,6,258,4,0,
318,6,259,4,0,
318,6,263,4,0,
318,6,269,4,0,
318,6,290,4,0,
318,6,291,4,0,
318,6,352,4,0,
318,7,36,1,31,
318,7,37,2,0,
318,7,38,2,0,
318,7,38,3,0,
318,7,43,1,1,1
318,7,44,1,1,2
318,7,56,2,0,
318,7,57,4,0,
318,7,58,4,0,
318,7,59,4,0,
318,7,92,4,0,
318,7,97,1,43,
318,7,99,1,7,
318,7,102,3,0,
318,7,103,1,28,
318,7,104,4,0,
318,7,116,1,13,
318,7,127,4,0,
318,7,156,4,0,
318,7,164,3,0,
318,7,168,4,0,
318,7,182,4,0,
318,7,184,1,16,
318,7,207,1,37,
318,7,213,4,0,
318,7,216,4,0,
318,7,218,4,0,
318,7,237,4,0,
318,7,240,4,0,
318,7,242,1,22,
318,7,258,4,0,
318,7,259,4,0,
318,7,263,4,0,
318,7,269,4,0,
318,7,290,4,0,
318,7,291,4,0,
318,7,352,4,0,
318,8,36,1,38,
318,8,37,2,0,
318,8,38,2,0,
318,8,43,1,1,1
318,8,44,1,1,2
318,8,56,2,0,
318,8,57,4,0,
318,8,58,4,0,
318,8,59,4,0,
318,8,92,4,0,
318,8,97,1,36,
318,8,99,1,6,
318,8,103,1,18,
318,8,104,4,0,
318,8,116,1,8,
318,8,127,4,0,
318,8,156,4,0,
318,8,164,4,0,
318,8,168,4,0,
318,8,182,4,0,
318,8,184,1,11,
318,8,203,4,0,
318,8,207,1,21,
318,8,207,4,0,
318,8,213,4,0,
318,8,214,4,0,
318,8,216,4,0,
318,8,218,4,0,
318,8,237,4,0,
318,8,240,4,0,
318,8,242,1,28,
318,8,246,2,0,
318,8,258,4,0,
318,8,259,4,0,
318,8,263,4,0,
318,8,269,4,0,
318,8,290,4,0,
318,8,352,4,0,
318,8,362,4,0,
318,8,363,4,0,
318,8,371,4,0,
318,8,372,1,26,
318,8,399,4,0,
318,8,423,1,16,
318,8,445,4,0,
318,8,453,1,31,
318,9,36,1,38,
318,9,37,2,0,
318,9,38,2,0,
318,9,43,1,1,1
318,9,44,1,1,2
318,9,56,2,0,
318,9,57,4,0,
318,9,58,4,0,
318,9,59,4,0,
318,9,92,4,0,
318,9,97,1,36,
318,9,99,1,6,
318,9,103,1,18,
318,9,104,4,0,
318,9,116,1,8,
318,9,127,4,0,
318,9,129,3,0,
318,9,156,4,0,
318,9,164,4,0,
318,9,168,4,0,
318,9,173,3,0,
318,9,180,3,0,
318,9,182,4,0,
318,9,184,1,11,
318,9,189,3,0,
318,9,196,3,0,
318,9,203,4,0,
318,9,207,1,21,
318,9,207,4,0,
318,9,210,3,0,
318,9,213,4,0,
318,9,214,4,0,
318,9,216,4,0,
318,9,218,4,0,
318,9,237,4,0,
318,9,240,4,0,
318,9,242,1,28,
318,9,246,2,0,
318,9,246,3,0,
318,9,253,3,0,
318,9,258,4,0,
318,9,259,4,0,
318,9,263,4,0,
318,9,269,4,0,
318,9,290,4,0,
318,9,291,3,0,
318,9,340,3,0,
318,9,352,4,0,
318,9,362,4,0,
318,9,363,4,0,
318,9,371,4,0,
318,9,372,1,26,
318,9,399,4,0,
318,9,423,1,16,
318,9,428,3,0,
318,9,445,4,0,
318,9,453,1,31,
318,10,36,1,38,
318,10,37,2,0,
318,10,38,2,0,
318,10,43,1,1,1
318,10,44,1,1,2
318,10,56,2,0,
318,10,57,4,0,
318,10,58,4,0,
318,10,59,4,0,
318,10,92,4,0,
318,10,97,1,36,
318,10,99,1,6,
318,10,103,1,18,
318,10,104,4,0,
318,10,116,1,8,
318,10,127,4,0,
318,10,129,3,0,
318,10,156,4,0,
318,10,162,3,0,
318,10,164,4,0,
318,10,168,4,0,
318,10,173,3,0,
318,10,180,3,0,
318,10,182,4,0,
318,10,184,1,11,
318,10,189,3,0,
318,10,196,3,0,
318,10,203,4,0,
318,10,207,1,21,
318,10,207,4,0,
318,10,210,3,0,
318,10,213,4,0,
318,10,214,4,0,
318,10,216,4,0,
318,10,218,4,0,
318,10,237,4,0,
318,10,240,4,0,
318,10,242,1,28,
318,10,246,2,0,
318,10,246,3,0,
318,10,250,4,0,
318,10,253,3,0,
318,10,258,4,0,
318,10,259,4,0,
318,10,263,4,0,
318,10,269,4,0,
318,10,290,4,0,
318,10,291,3,0,
318,10,340,3,0,
318,10,352,4,0,
318,10,362,4,0,
318,10,363,4,0,
318,10,371,4,0,
318,10,372,1,26,
318,10,399,4,0,
318,10,423,1,16,
318,10,428,3,0,
318,10,445,4,0,
318,10,453,1,31,
318,11,36,1,38,
318,11,37,2,0,
318,11,38,2,0,
318,11,43,1,1,1
318,11,44,1,1,2
318,11,56,2,0,
318,11,57,4,0,
318,11,58,4,0,
318,11,59,4,0,
318,11,92,4,0,
318,11,97,1,36,
318,11,99,1,6,
318,11,103,1,18,
318,11,104,4,0,
318,11,116,1,8,
318,11,127,4,0,
318,11,129,2,0,
318,11,156,4,0,
318,11,164,4,0,
318,11,168,4,0,
318,11,182,4,0,
318,11,184,1,11,
318,11,207,1,21,
318,11,207,4,0,
318,11,213,4,0,
318,11,216,4,0,
318,11,218,4,0,
318,11,237,4,0,
318,11,240,4,0,
318,11,242,1,28,
318,11,246,2,0,
318,11,258,4,0,
318,11,259,4,0,
318,11,263,4,0,
318,11,269,4,0,
318,11,291,4,0,
318,11,362,2,0,
318,11,371,4,0,
318,11,372,1,26,
318,11,423,1,16,
318,11,453,1,31,
318,11,496,4,0,
318,11,503,4,0,
318,11,514,4,0,
318,11,555,4,0,
318,12,36,1,31,
318,12,43,1,1,1
318,12,44,1,1,2
318,12,57,4,0,
318,12,58,4,0,
318,12,59,4,0,
318,12,92,4,0,
318,12,97,1,43,
318,12,99,1,7,
318,12,103,1,28,
318,12,104,4,0,
318,12,116,1,13,
318,12,127,4,0,
318,12,156,4,0,
318,12,168,4,0,
318,12,182,4,0,
318,12,184,1,16,
318,12,207,1,37,
318,12,213,4,0,
318,12,216,4,0,
318,12,218,4,0,
318,12,237,4,0,
318,12,240,4,0,
318,12,242,1,22,
318,12,258,4,0,
318,12,259,4,0,
318,12,263,4,0,
318,12,269,4,0,
318,12,290,4,0,
318,12,291,4,0,
318,12,352,4,0,
318,13,36,1,31,
318,13,38,3,0,
318,13,43,1,1,1
318,13,44,1,1,2
318,13,57,4,0,
318,13,58,4,0,
318,13,59,4,0,
318,13,92,4,0,
318,13,97,1,43,
318,13,99,1,7,
318,13,102,3,0,
318,13,103,1,28,
318,13,104,4,0,
318,13,116,1,13,
318,13,127,4,0,
318,13,156,4,0,
318,13,164,3,0,
318,13,168,4,0,
318,13,182,4,0,
318,13,184,1,16,
318,13,196,3,0,
318,13,207,1,37,
318,13,207,3,0,
318,13,213,4,0,
318,13,216,4,0,
318,13,218,4,0,
318,13,237,4,0,
318,13,240,4,0,
318,13,242,1,22,
318,13,258,4,0,
318,13,259,4,0,
318,13,263,4,0,
318,13,269,4,0,
318,13,290,4,0,
318,13,291,4,0,
318,13,352,4,0,
318,14,36,1,38,
318,14,37,2,0,
318,14,38,2,0,
318,14,43,1,1,1
318,14,44,1,1,2
318,14,56,2,0,
318,14,57,4,0,
318,14,58,4,0,
318,14,59,4,0,
318,14,92,4,0,
318,14,97,1,36,
318,14,99,1,6,
318,14,103,1,18,
318,14,104,4,0,
318,14,116,1,8,
318,14,127,4,0,
318,14,129,2,0,
318,14,156,4,0,
318,14,162,3,0,
318,14,164,4,0,
318,14,168,4,0,
318,14,173,3,0,
318,14,180,3,0,
318,14,182,4,0,
318,14,184,1,11,
318,14,196,3,0,
318,14,207,1,21,
318,14,207,4,0,
318,14,213,4,0,
318,14,214,3,0,
318,14,216,4,0,
318,14,218,4,0,
318,14,237,4,0,
318,14,240,4,0,
318,14,242,1,28,
318,14,246,2,0,
318,14,253,3,0,
318,14,258,4,0,
318,14,259,4,0,
318,14,263,4,0,
318,14,269,4,0,
318,14,291,4,0,
318,14,340,3,0,
318,14,362,2,0,
318,14,371,4,0,
318,14,372,1,26,
318,14,399,3,0,
318,14,423,1,16,
318,14,428,3,0,
318,14,453,1,31,
318,14,496,4,0,
318,14,503,4,0,
318,14,514,4,0,
318,14,555,4,0,
318,15,36,1,38,
318,15,37,2,0,
318,15,38,2,0,
318,15,43,1,1,1
318,15,44,1,1,2
318,15,56,2,0,
318,15,57,4,0,
318,15,58,4,0,
318,15,59,4,0,
318,15,92,4,0,
318,15,97,1,36,
318,15,99,1,6,
318,15,103,1,18,
318,15,104,4,0,
318,15,116,1,8,
318,15,127,4,0,
318,15,129,2,0,
318,15,156,4,0,
318,15,164,4,0,
318,15,168,4,0,
318,15,182,4,0,
318,15,184,1,11,
318,15,194,2,0,
318,15,207,1,21,
318,15,207,4,0,
318,15,213,4,0,
318,15,214,4,0,
318,15,216,4,0,
318,15,218,4,0,
318,15,237,4,0,
318,15,240,4,0,
318,15,242,1,28,
318,15,246,2,0,
318,15,258,4,0,
318,15,259,4,0,
318,15,263,4,0,
318,15,269,4,0,
318,15,362,2,0,
318,15,371,4,0,
318,15,372,1,26,
318,15,399,4,0,
318,15,423,1,16,
318,15,453,1,31,
318,15,496,4,0,
318,15,503,4,0,
318,15,514,4,0,
318,15,555,4,0,
318,15,590,4,0,
318,16,36,1,43,1
318,16,37,2,0,
318,16,38,2,0,
318,16,43,1,1,1
318,16,44,1,1,2
318,16,56,2,0,
318,16,57,4,0,
318,16,58,4,0,
318,16,59,4,0,
318,16,92,4,0,
318,16,97,1,39,1
318,16,99,1,4,1
318,16,103,1,18,1
318,16,104,4,0,
318,16,116,1,8,1
318,16,127,4,0,
318,16,129,2,0,
318,16,156,4,0,
318,16,162,3,0,
318,16,164,4,0,
318,16,168,4,0,
318,16,173,3,0,
318,16,180,3,0,
318,16,182,4,0,
318,16,184,1,29,1
318,16,194,2,0,
318,16,196,3,0,
318,16,207,1,22,1
318,16,207,4,0,
318,16,213,4,0,
318,16,214,4,0,
318,16,216,4,0,
318,16,218,4,0,
318,16,237,4,0,
318,16,240,4,0,
318,16,242,1,36,1
318,16,246,2,0,
318,16,253,3,0,
318,16,258,4,0,
318,16,259,4,0,
318,16,263,4,0,
318,16,269,4,0,
318,16,290,4,0,
318,16,291,4,0,
318,16,305,1,32,1
318,16,340,3,0,
318,16,352,3,0,
318,16,362,2,0,
318,16,371,4,0,
318,16,372,1,15,1
318,16,399,4,0,
318,16,423,1,25,1
318,16,428,3,0,
318,16,453,1,11,1
318,16,496,4,0,
318,16,503,4,0,
318,16,514,4,0,
318,16,555,4,0,
318,16,590,4,0,
318,17,36,1,43,
318,17,37,2,0,
318,17,38,2,0,
318,17,43,1,1,1
318,17,44,1,1,2
318,17,56,2,0,
318,17,57,4,0,
318,17,58,4,0,
318,17,59,4,0,
318,17,92,4,0,
318,17,97,1,39,
318,17,99,1,4,
318,17,103,1,18,
318,17,104,4,0,
318,17,116,1,8,
318,17,127,4,0,
318,17,129,2,0,
318,17,156,4,0,
318,17,164,4,0,
318,17,168,4,0,
318,17,182,4,0,
318,17,184,1,29,
318,17,194,2,0,
318,17,207,1,22,
318,17,207,4,0,
318,17,213,4,0,
318,17,214,4,0,
318,17,216,4,0,
318,17,218,4,0,
318,17,237,4,0,
318,17,240,4,0,
318,17,242,1,36,
318,17,246,2,0,
318,17,258,4,0,
318,17,259,4,0,
318,17,263,4,0,
318,17,269,4,0,
318,17,305,1,32,
318,17,362,2,0,
318,17,371,4,0,
318,17,372,1,15,
318,17,399,4,0,
318,17,423,1,25,
318,17,453,1,11,
318,17,496,4,0,
318,17,503,4,0,
318,17,555,4,0,
318,17,590,4,0,
318,17,706,2,0,
318,18,36,1,43,
318,18,37,2,0,
318,18,38,2,0,
318,18,43,1,1,1
318,18,44,1,1,2
318,18,56,2,0,
318,18,57,4,0,
318,18,58,4,0,
318,18,59,4,0,
318,18,92,4,0,
318,18,97,1,39,
318,18,99,1,4,
318,18,103,1,18,
318,18,104,4,0,
318,18,116,1,8,
318,18,127,4,0,
318,18,129,2,0,
318,18,156,4,0,
318,18,164,4,0,
318,18,168,4,0,
318,18,182,4,0,
318,18,184,1,29,
318,18,194,2,0,
318,18,207,1,22,
318,18,207,4,0,
318,18,213,4,0,
318,18,214,4,0,
318,18,216,4,0,
318,18,218,4,0,
318,18,237,4,0,
318,18,240,4,0,
318,18,242,1,36,
318,18,246,2,0,
318,18,258,4,0,
318,18,259,4,0,
318,18,263,4,0,
318,18,269,4,0,
318,18,305,1,32,
318,18,362,2,0,
318,18,371,4,0,
318,18,372,1,15,
318,18,399,4,0,
318,18,423,1,25,
318,18,453,1,11,
318,18,496,4,0,
318,18,503,4,0,
318,18,555,4,0,
318,18,590,4,0,
318,18,706,2,0,
319,5,43,1,1,1
319,5,44,1,1,2
319,5,46,4,0,
319,5,57,4,0,
319,5,58,4,0,
319,5,59,4,0,
319,5,63,4,0,
319,5,70,4,0,
319,5,89,4,0,
319,5,92,4,0,
319,5,97,1,53,
319,5,99,1,1,3
319,5,99,1,7,
319,5,103,1,28,
319,5,104,4,0,
319,5,116,1,1,4
319,5,116,1,13,
319,5,127,4,0,
319,5,130,1,48,
319,5,156,4,0,
319,5,163,1,33,
319,5,168,4,0,
319,5,182,4,0,
319,5,184,1,16,
319,5,207,1,43,
319,5,213,4,0,
319,5,216,4,0,
319,5,218,4,0,
319,5,237,4,0,
319,5,240,4,0,
319,5,242,1,22,
319,5,249,4,0,
319,5,258,4,0,
319,5,259,4,0,
319,5,263,4,0,
319,5,269,1,38,
319,5,269,4,0,
319,5,290,4,0,
319,5,291,4,0,
319,5,317,4,0,
319,5,352,4,0,
319,6,38,3,0,
319,6,43,1,1,1
319,6,44,1,1,2
319,6,46,4,0,
319,6,57,4,0,
319,6,58,4,0,
319,6,59,4,0,
319,6,63,4,0,
319,6,70,4,0,
319,6,89,4,0,
319,6,92,4,0,
319,6,97,1,53,
319,6,99,1,1,3
319,6,99,1,7,
319,6,102,3,0,
319,6,103,1,28,
319,6,104,4,0,
319,6,116,1,1,4
319,6,116,1,13,
319,6,127,4,0,
319,6,129,3,0,
319,6,130,1,48,
319,6,156,4,0,
319,6,163,1,33,
319,6,164,3,0,
319,6,168,4,0,
319,6,173,3,0,
319,6,182,4,0,
319,6,184,1,16,
319,6,189,3,0,
319,6,196,3,0,
319,6,203,3,0,
319,6,207,1,43,
319,6,207,3,0,
319,6,210,3,0,
319,6,213,4,0,
319,6,214,3,0,
319,6,216,4,0,
319,6,218,4,0,
319,6,237,4,0,
319,6,240,4,0,
319,6,242,1,22,
319,6,249,4,0,
319,6,258,4,0,
319,6,259,4,0,
319,6,263,4,0,
319,6,269,1,38,
319,6,269,4,0,
319,6,290,4,0,
319,6,291,4,0,
319,6,317,4,0,
319,6,352,4,0,
319,7,38,3,0,
319,7,43,1,1,1
319,7,44,1,1,2
319,7,46,4,0,
319,7,57,4,0,
319,7,58,4,0,
319,7,59,4,0,
319,7,63,4,0,
319,7,70,4,0,
319,7,89,4,0,
319,7,92,4,0,
319,7,97,1,53,
319,7,99,1,1,3
319,7,99,1,7,
319,7,102,3,0,
319,7,103,1,28,
319,7,104,4,0,
319,7,116,1,1,4
319,7,116,1,13,
319,7,127,4,0,
319,7,130,1,48,
319,7,156,4,0,
319,7,163,1,33,
319,7,164,3,0,
319,7,168,4,0,
319,7,182,4,0,
319,7,184,1,16,
319,7,207,1,43,
319,7,213,4,0,
319,7,216,4,0,
319,7,218,4,0,
319,7,237,4,0,
319,7,240,4,0,
319,7,242,1,22,
319,7,249,4,0,
319,7,258,4,0,
319,7,259,4,0,
319,7,263,4,0,
319,7,269,1,38,
319,7,269,4,0,
319,7,290,4,0,
319,7,291,4,0,
319,7,317,4,0,
319,7,352,4,0,
319,8,43,1,1,1
319,8,44,1,1,2
319,8,46,4,0,
319,8,57,4,0,
319,8,58,4,0,
319,8,59,4,0,
319,8,63,4,0,
319,8,70,4,0,
319,8,89,4,0,
319,8,92,4,0,
319,8,97,1,45,
319,8,99,1,1,3
319,8,99,1,6,
319,8,103,1,18,
319,8,104,4,0,
319,8,116,1,1,4
319,8,116,1,8,
319,8,127,4,0,
319,8,130,1,50,
319,8,156,4,0,
319,8,163,1,30,
319,8,164,4,0,
319,8,168,4,0,
319,8,182,4,0,
319,8,184,1,11,
319,8,203,4,0,
319,8,207,1,21,
319,8,207,4,0,
319,8,213,4,0,
319,8,214,4,0,
319,8,216,4,0,
319,8,218,4,0,
319,8,237,4,0,
319,8,240,4,0,
319,8,242,1,28,
319,8,249,4,0,
319,8,258,4,0,
319,8,259,4,0,
319,8,263,4,0,
319,8,269,1,40,
319,8,269,4,0,
319,8,290,4,0,
319,8,317,4,0,
319,8,352,4,0,
319,8,362,4,0,
319,8,363,4,0,
319,8,371,4,0,
319,8,372,1,26,
319,8,398,4,0,
319,8,399,4,0,
319,8,400,1,56,
319,8,416,4,0,
319,8,419,4,0,
319,8,423,1,16,
319,8,445,4,0,
319,8,453,1,34,
319,9,43,1,1,2
319,9,44,1,1,3
319,9,46,4,0,
319,9,57,4,0,
319,9,58,4,0,
319,9,59,4,0,
319,9,63,4,0,
319,9,70,4,0,
319,9,89,4,0,
319,9,92,4,0,
319,9,97,1,45,
319,9,99,1,1,4
319,9,99,1,6,
319,9,103,1,18,
319,9,104,4,0,
319,9,116,1,1,5
319,9,116,1,8,
319,9,127,4,0,
319,9,129,3,0,
319,9,130,1,50,
319,9,156,4,0,
319,9,163,1,30,
319,9,164,4,0,
319,9,168,4,0,
319,9,173,3,0,
319,9,180,3,0,
319,9,182,4,0,
319,9,184,1,11,
319,9,189,3,0,
319,9,196,3,0,
319,9,203,4,0,
319,9,207,1,21,
319,9,207,4,0,
319,9,210,3,0,
319,9,213,4,0,
319,9,214,4,0,
319,9,216,4,0,
319,9,218,4,0,
319,9,237,4,0,
319,9,240,4,0,
319,9,242,1,28,
319,9,246,3,0,
319,9,249,4,0,
319,9,253,3,0,
319,9,258,4,0,
319,9,259,4,0,
319,9,263,4,0,
319,9,269,1,40,
319,9,269,4,0,
319,9,290,4,0,
319,9,291,3,0,
319,9,317,4,0,
319,9,340,3,0,
319,9,352,4,0,
319,9,362,4,0,
319,9,363,4,0,
319,9,364,1,1,1
319,9,371,4,0,
319,9,372,1,26,
319,9,398,4,0,
319,9,399,4,0,
319,9,400,1,56,
319,9,416,4,0,
319,9,419,4,0,
319,9,423,1,16,
319,9,428,3,0,
319,9,445,4,0,
319,9,453,1,34,
319,10,43,1,1,2
319,10,44,1,1,3
319,10,46,4,0,
319,10,57,4,0,
319,10,58,4,0,
319,10,59,4,0,
319,10,63,4,0,
319,10,70,4,0,
319,10,89,4,0,
319,10,92,4,0,
319,10,97,1,45,
319,10,99,1,1,4
319,10,99,1,6,
319,10,103,1,18,
319,10,104,4,0,
319,10,116,1,1,5
319,10,116,1,8,
319,10,127,4,0,
319,10,129,3,0,
319,10,130,1,50,
319,10,156,4,0,
319,10,162,3,0,
319,10,163,1,30,
319,10,164,4,0,
319,10,168,4,0,
319,10,173,3,0,
319,10,180,3,0,
319,10,182,4,0,
319,10,184,1,11,
319,10,189,3,0,
319,10,196,3,0,
319,10,203,4,0,
319,10,207,1,21,
319,10,207,4,0,
319,10,210,3,0,
319,10,213,4,0,
319,10,214,4,0,
319,10,216,4,0,
319,10,218,4,0,
319,10,237,4,0,
319,10,240,4,0,
319,10,242,1,28,
319,10,246,3,0,
319,10,249,4,0,
319,10,250,4,0,
319,10,253,3,0,
319,10,258,4,0,
319,10,259,4,0,
319,10,263,4,0,
319,10,269,1,40,
319,10,269,4,0,
319,10,290,4,0,
319,10,291,3,0,
319,10,317,4,0,
319,10,340,3,0,
319,10,352,4,0,
319,10,362,4,0,
319,10,363,4,0,
319,10,364,1,1,1
319,10,371,4,0,
319,10,372,1,26,
319,10,398,4,0,
319,10,399,4,0,
319,10,400,1,56,
319,10,416,4,0,
319,10,419,4,0,
319,10,423,1,16,
319,10,428,3,0,
319,10,445,4,0,
319,10,453,1,34,
319,11,43,1,1,2
319,11,44,1,1,3
319,11,46,4,0,
319,11,57,4,0,
319,11,58,4,0,
319,11,59,4,0,
319,11,63,4,0,
319,11,70,4,0,
319,11,89,4,0,
319,11,92,4,0,
319,11,97,1,45,
319,11,99,1,1,4
319,11,99,1,6,
319,11,103,1,18,
319,11,104,4,0,
319,11,116,1,1,5
319,11,116,1,8,
319,11,127,4,0,
319,11,130,1,50,
319,11,156,4,0,
319,11,163,1,30,
319,11,164,4,0,
319,11,168,4,0,
319,11,182,4,0,
319,11,184,1,11,
319,11,207,1,21,
319,11,207,4,0,
319,11,213,4,0,
319,11,216,4,0,
319,11,218,4,0,
319,11,237,4,0,
319,11,240,4,0,
319,11,242,1,28,
319,11,249,4,0,
319,11,258,4,0,
319,11,259,4,0,
319,11,263,4,0,
319,11,269,1,40,
319,11,269,4,0,
319,11,291,4,0,
319,11,317,4,0,
319,11,364,1,1,1
319,11,371,4,0,
319,11,372,1,26,
319,11,398,4,0,
319,11,400,1,56,
319,11,416,4,0,
319,11,423,1,16,
319,11,453,1,34,
319,11,496,4,0,
319,11,503,4,0,
319,11,514,4,0,
319,11,523,4,0,
319,11,555,4,0,
319,12,43,1,1,1
319,12,44,1,1,2
319,12,46,4,0,
319,12,57,4,0,
319,12,58,4,0,
319,12,59,4,0,
319,12,63,4,0,
319,12,70,4,0,
319,12,89,4,0,
319,12,92,4,0,
319,12,97,1,53,
319,12,99,1,1,3
319,12,99,1,7,
319,12,103,1,28,
319,12,104,4,0,
319,12,116,1,1,4
319,12,116,1,13,
319,12,127,4,0,
319,12,130,1,48,
319,12,156,4,0,
319,12,163,1,33,
319,12,168,4,0,
319,12,182,4,0,
319,12,184,1,16,
319,12,207,1,43,
319,12,213,4,0,
319,12,216,4,0,
319,12,218,4,0,
319,12,237,4,0,
319,12,240,4,0,
319,12,242,1,22,
319,12,249,4,0,
319,12,258,4,0,
319,12,259,4,0,
319,12,263,4,0,
319,12,269,1,38,
319,12,269,4,0,
319,12,290,4,0,
319,12,291,4,0,
319,12,317,4,0,
319,12,352,4,0,
319,13,38,3,0,
319,13,43,1,1,1
319,13,44,1,1,2
319,13,46,4,0,
319,13,57,4,0,
319,13,58,4,0,
319,13,59,4,0,
319,13,63,4,0,
319,13,70,4,0,
319,13,89,4,0,
319,13,92,4,0,
319,13,97,1,53,
319,13,99,1,1,3
319,13,99,1,7,
319,13,102,3,0,
319,13,103,1,28,
319,13,104,4,0,
319,13,116,1,1,4
319,13,116,1,13,
319,13,127,4,0,
319,13,130,1,48,
319,13,156,4,0,
319,13,163,1,33,
319,13,164,3,0,
319,13,168,4,0,
319,13,182,4,0,
319,13,184,1,16,
319,13,196,3,0,
319,13,207,1,43,
319,13,207,3,0,
319,13,213,4,0,
319,13,216,4,0,
319,13,218,4,0,
319,13,237,4,0,
319,13,240,4,0,
319,13,242,1,22,
319,13,249,4,0,
319,13,258,4,0,
319,13,259,4,0,
319,13,263,4,0,
319,13,269,1,38,
319,13,269,4,0,
319,13,290,4,0,
319,13,291,4,0,
319,13,317,4,0,
319,13,352,4,0,
319,14,43,1,1,2
319,14,44,1,1,3
319,14,46,4,0,
319,14,57,4,0,
319,14,58,4,0,
319,14,59,4,0,
319,14,63,4,0,
319,14,70,4,0,
319,14,89,4,0,
319,14,92,4,0,
319,14,97,1,45,
319,14,99,1,1,4
319,14,99,1,6,
319,14,103,1,18,
319,14,104,4,0,
319,14,116,1,1,5
319,14,116,1,8,
319,14,127,4,0,
319,14,130,1,50,
319,14,156,4,0,
319,14,162,3,0,
319,14,163,1,30,
319,14,164,4,0,
319,14,168,4,0,
319,14,173,3,0,
319,14,180,3,0,
319,14,182,4,0,
319,14,184,1,11,
319,14,196,3,0,
319,14,207,1,21,
319,14,207,4,0,
319,14,213,4,0,
319,14,214,3,0,
319,14,216,4,0,
319,14,218,4,0,
319,14,237,4,0,
319,14,240,4,0,
319,14,242,1,28,
319,14,249,4,0,
319,14,253,3,0,
319,14,258,4,0,
319,14,259,4,0,
319,14,263,4,0,
319,14,269,1,40,
319,14,269,4,0,
319,14,291,4,0,
319,14,317,4,0,
319,14,340,3,0,
319,14,364,1,1,1
319,14,371,4,0,
319,14,372,1,26,
319,14,398,4,0,
319,14,399,3,0,
319,14,400,1,56,
319,14,416,4,0,
319,14,423,1,16,
319,14,428,3,0,
319,14,453,1,34,
319,14,496,4,0,
319,14,503,4,0,
319,14,514,4,0,
319,14,523,4,0,
319,14,555,4,0,
319,15,43,1,1,3
319,15,44,1,1,4
319,15,46,4,0,
319,15,57,4,0,
319,15,58,4,0,
319,15,59,4,0,
319,15,63,4,0,
319,15,70,4,0,
319,15,89,4,0,
319,15,92,4,0,
319,15,97,1,45,
319,15,99,1,1,5
319,15,99,1,6,
319,15,103,1,18,
319,15,104,4,0,
319,15,116,1,1,6
319,15,116,1,8,
319,15,127,4,0,
319,15,130,1,50,
319,15,156,4,0,
319,15,163,1,30,
319,15,164,4,0,
319,15,168,4,0,
319,15,182,4,0,
319,15,184,1,11,
319,15,207,1,21,
319,15,207,4,0,
319,15,213,4,0,
319,15,214,4,0,
319,15,216,4,0,
319,15,218,4,0,
319,15,237,4,0,
319,15,240,4,0,
319,15,242,1,28,
319,15,249,4,0,
319,15,258,4,0,
319,15,259,4,0,
319,15,263,4,0,
319,15,269,1,40,
319,15,269,4,0,
319,15,317,4,0,
319,15,364,1,1,2
319,15,371,4,0,
319,15,372,1,26,
319,15,398,4,0,
319,15,399,4,0,
319,15,400,1,1,1
319,15,400,1,56,
319,15,416,4,0,
319,15,423,1,16,
319,15,453,1,34,
319,15,496,4,0,
319,15,503,4,0,
319,15,514,4,0,
319,15,523,4,0,
319,15,555,4,0,
319,15,590,4,0,
319,16,43,1,1,3
319,16,44,1,1,4
319,16,46,4,0,
319,16,57,4,0,
319,16,58,4,0,
319,16,59,4,0,
319,16,63,4,0,
319,16,70,4,0,
319,16,89,4,0,
319,16,92,4,0,
319,16,97,1,45,1
319,16,99,1,1,5
319,16,99,1,4,1
319,16,103,1,18,1
319,16,104,4,0,
319,16,116,1,1,6
319,16,116,1,8,1
319,16,127,4,0,
319,16,130,1,51,1
319,16,156,4,0,
319,16,162,3,0,
319,16,163,1,30,1
319,16,164,4,0,
319,16,168,4,0,
319,16,173,3,0,
319,16,180,3,0,
319,16,182,4,0,
319,16,184,1,29,1
319,16,196,3,0,
319,16,207,1,22,1
319,16,207,4,0,
319,16,213,4,0,
319,16,214,4,0,
319,16,216,4,0,
319,16,218,4,0,
319,16,237,4,0,
319,16,240,4,0,
319,16,242,1,40,1
319,16,249,4,0,
319,16,253,3,0,
319,16,258,4,0,
319,16,259,4,0,
319,16,263,4,0,
319,16,269,1,56,1
319,16,269,4,0,
319,16,290,4,0,
319,16,291,4,0,
319,16,305,1,34,1
319,16,317,4,0,
319,16,340,3,0,
319,16,352,3,0,
319,16,364,1,1,2
319,16,371,4,0,
319,16,372,1,15,1
319,16,398,4,0,
319,16,399,4,0,
319,16,400,1,1,1
319,16,400,1,62,1
319,16,416,4,0,
319,16,423,1,25,1
319,16,428,3,0,
319,16,453,1,11,1
319,16,496,4,0,
319,16,503,4,0,
319,16,514,4,0,
319,16,523,4,0,
319,16,555,4,0,
319,16,590,4,0,
319,17,43,1,1,4
319,17,44,1,1,5
319,17,46,4,0,
319,17,57,4,0,
319,17,58,4,0,
319,17,59,4,0,
319,17,63,4,0,
319,17,89,4,0,
319,17,92,4,0,
319,17,97,1,45,
319,17,99,1,1,6
319,17,99,1,4,
319,17,103,1,18,
319,17,104,4,0,
319,17,116,1,1,7
319,17,116,1,8,
319,17,127,4,0,
319,17,130,1,51,
319,17,156,4,0,
319,17,163,1,0,
319,17,163,1,1,1
319,17,164,4,0,
319,17,168,4,0,
319,17,182,4,0,
319,17,184,1,29,
319,17,207,1,22,
319,17,207,4,0,
319,17,213,4,0,
319,17,214,4,0,
319,17,216,4,0,
319,17,218,4,0,
319,17,237,4,0,
319,17,240,4,0,
319,17,242,1,40,
319,17,258,4,0,
319,17,259,4,0,
319,17,263,4,0,
319,17,269,1,56,
319,17,269,4,0,
319,17,305,1,34,
319,17,317,4,0,
319,17,364,1,1,3
319,17,371,4,0,
319,17,372,1,15,
319,17,398,4,0,
319,17,399,4,0,
319,17,400,1,1,2
319,17,400,1,62,
319,17,416,4,0,
319,17,423,1,25,
319,17,453,1,11,
319,17,496,4,0,
319,17,503,4,0,
319,17,523,4,0,
319,17,555,4,0,
319,17,590,4,0,
319,18,43,1,1,4
319,18,44,1,1,5
319,18,46,4,0,
319,18,57,4,0,
319,18,58,4,0,
319,18,59,4,0,
319,18,63,4,0,
319,18,89,4,0,
319,18,92,4,0,
319,18,97,1,45,
319,18,99,1,1,6
319,18,99,1,4,
319,18,103,1,18,
319,18,104,4,0,
319,18,116,1,1,7
319,18,116,1,8,
319,18,127,4,0,
319,18,130,1,51,
319,18,156,4,0,
319,18,163,1,0,
319,18,163,1,1,1
319,18,164,4,0,
319,18,168,4,0,
319,18,182,4,0,
319,18,184,1,29,
319,18,207,1,22,
319,18,207,4,0,
319,18,213,4,0,
319,18,214,4,0,
319,18,216,4,0,
319,18,218,4,0,
319,18,237,4,0,
319,18,240,4,0,
319,18,242,1,40,
319,18,258,4,0,
319,18,259,4,0,
319,18,263,4,0,
319,18,269,1,56,
319,18,269,4,0,
319,18,305,1,34,
319,18,317,4,0,
319,18,364,1,1,3
319,18,371,4,0,
319,18,372,1,15,
319,18,398,4,0,
319,18,399,4,0,
319,18,400,1,1,2
319,18,400,1,62,
319,18,416,4,0,
319,18,423,1,25,
319,18,453,1,11,
319,18,496,4,0,
319,18,503,4,0,
319,18,523,4,0,
319,18,555,4,0,
319,18,590,4,0,
320,5,37,2,0,
320,5,38,2,0,
320,5,45,1,5,
320,5,46,4,0,
320,5,54,1,32,
320,5,55,1,10,
320,5,56,1,50,
320,5,57,4,0,
320,5,58,4,0,
320,5,59,4,0,
320,5,70,4,0,
320,5,89,4,0,
320,5,90,2,0,
320,5,92,4,0,
320,5,104,4,0,
320,5,127,4,0,
320,5,133,1,46,
320,5,150,1,1,
320,5,156,1,37,
320,5,156,4,0,
320,5,173,2,0,
320,5,174,2,0,
320,5,182,4,0,
320,5,205,1,14,
320,5,207,2,0,
320,5,213,4,0,
320,5,214,2,0,
320,5,216,4,0,
320,5,218,4,0,
320,5,237,4,0,
320,5,240,4,0,
320,5,249,4,0,
320,5,250,1,19,
320,5,258,4,0,
320,5,263,4,0,
320,5,290,4,0,
320,5,291,4,0,
320,5,310,1,23,
320,5,317,4,0,
320,5,321,2,0,
320,5,323,1,41,
320,5,352,1,28,
320,5,352,4,0,
320,6,34,3,0,
320,6,37,2,0,
320,6,38,2,0,
320,6,38,3,0,
320,6,45,1,5,
320,6,46,4,0,
320,6,54,1,32,
320,6,55,1,10,
320,6,56,1,50,
320,6,57,4,0,
320,6,58,4,0,
320,6,59,4,0,
320,6,70,4,0,
320,6,89,4,0,
320,6,90,2,0,
320,6,92,4,0,
320,6,102,3,0,
320,6,104,4,0,
320,6,111,3,0,
320,6,127,4,0,
320,6,133,1,46,
320,6,150,1,1,
320,6,156,1,37,
320,6,156,4,0,
320,6,164,3,0,
320,6,173,2,0,
320,6,173,3,0,
320,6,174,2,0,
320,6,182,4,0,
320,6,196,3,0,
320,6,203,3,0,
320,6,205,1,14,
320,6,205,3,0,
320,6,207,2,0,
320,6,207,3,0,
320,6,213,4,0,
320,6,214,2,0,
320,6,214,3,0,
320,6,216,4,0,
320,6,218,4,0,
320,6,237,4,0,
320,6,240,4,0,
320,6,249,4,0,
320,6,250,1,19,
320,6,258,4,0,
320,6,263,4,0,
320,6,290,4,0,
320,6,291,4,0,
320,6,310,1,23,
320,6,317,4,0,
320,6,321,2,0,
320,6,323,1,41,
320,6,352,1,28,
320,6,352,4,0,
320,7,34,3,0,
320,7,37,2,0,
320,7,38,2,0,
320,7,38,3,0,
320,7,45,1,5,
320,7,46,4,0,
320,7,54,1,32,
320,7,55,1,10,
320,7,56,1,50,
320,7,57,4,0,
320,7,58,4,0,
320,7,59,4,0,
320,7,70,4,0,
320,7,89,4,0,
320,7,90,2,0,
320,7,92,4,0,
320,7,102,3,0,
320,7,104,4,0,
320,7,127,4,0,
320,7,133,1,46,
320,7,150,1,1,
320,7,156,1,37,
320,7,156,4,0,
320,7,164,3,0,
320,7,173,2,0,
320,7,174,2,0,
320,7,182,4,0,
320,7,205,1,14,
320,7,207,2,0,
320,7,213,4,0,
320,7,214,2,0,
320,7,216,4,0,
320,7,218,4,0,
320,7,237,4,0,
320,7,240,4,0,
320,7,249,4,0,
320,7,250,1,19,
320,7,258,4,0,
320,7,263,4,0,
320,7,290,4,0,
320,7,291,4,0,
320,7,310,1,23,
320,7,317,4,0,
320,7,321,2,0,
320,7,323,1,41,
320,7,352,1,28,
320,7,352,4,0,
320,8,34,2,0,
320,8,37,2,0,
320,8,38,2,0,
320,8,45,1,4,
320,8,46,4,0,
320,8,54,1,24,
320,8,55,1,7,
320,8,56,1,47,
320,8,57,4,0,
320,8,58,4,0,
320,8,59,4,0,
320,8,70,4,0,
320,8,89,4,0,
320,8,90,2,0,
320,8,92,4,0,
320,8,104,4,0,
320,8,111,2,0,
320,8,127,4,0,
320,8,133,1,37,
320,8,150,1,1,
320,8,156,1,27,
320,8,156,4,0,
320,8,164,4,0,
320,8,173,2,0,
320,8,174,2,0,
320,8,182,4,0,
320,8,203,4,0,
320,8,205,1,11,
320,8,207,2,0,
320,8,207,4,0,
320,8,213,4,0,
320,8,214,2,0,
320,8,214,4,0,
320,8,216,4,0,
320,8,218,4,0,
320,8,237,4,0,
320,8,240,4,0,
320,8,249,4,0,
320,8,250,1,14,
320,8,258,4,0,
320,8,263,4,0,
320,8,290,4,0,
320,8,291,1,41,
320,8,310,1,17,
320,8,317,4,0,
320,8,321,2,0,
320,8,323,1,34,
320,8,340,1,44,
320,8,352,1,21,
320,8,352,4,0,
320,8,362,1,31,
320,8,362,4,0,
320,8,363,4,0,
320,8,419,4,0,
320,8,445,4,0,
320,9,34,2,0,
320,9,37,2,0,
320,9,38,2,0,
320,9,45,1,4,
320,9,46,4,0,
320,9,54,1,24,
320,9,55,1,7,
320,9,56,1,47,
320,9,57,4,0,
320,9,58,4,0,
320,9,59,4,0,
320,9,70,4,0,
320,9,89,4,0,
320,9,90,2,0,
320,9,92,4,0,
320,9,104,4,0,
320,9,111,2,0,
320,9,127,4,0,
320,9,133,1,37,
320,9,150,1,1,
320,9,156,1,27,
320,9,156,4,0,
320,9,164,4,0,
320,9,173,2,0,
320,9,173,3,0,
320,9,174,2,0,
320,9,182,4,0,
320,9,196,3,0,
320,9,203,4,0,
320,9,205,1,11,
320,9,205,3,0,
320,9,207,2,0,
320,9,207,4,0,
320,9,213,4,0,
320,9,214,2,0,
320,9,214,4,0,
320,9,216,4,0,
320,9,218,4,0,
320,9,237,4,0,
320,9,240,4,0,
320,9,249,4,0,
320,9,250,1,14,
320,9,258,4,0,
320,9,263,4,0,
320,9,290,4,0,
320,9,291,1,41,
320,9,291,3,0,
320,9,310,1,17,
320,9,317,4,0,
320,9,321,2,0,
320,9,323,1,34,
320,9,340,1,44,
320,9,340,3,0,
320,9,352,1,21,
320,9,352,4,0,
320,9,362,1,31,
320,9,362,4,0,
320,9,363,4,0,
320,9,419,4,0,
320,9,445,4,0,
320,10,29,3,0,
320,10,34,2,0,
320,10,37,2,0,
320,10,38,2,0,
320,10,45,1,4,
320,10,46,4,0,
320,10,54,1,24,
320,10,55,1,7,
320,10,56,1,47,
320,10,57,4,0,
320,10,58,4,0,
320,10,59,4,0,
320,10,70,4,0,
320,10,89,4,0,
320,10,90,2,0,
320,10,92,4,0,
320,10,104,4,0,
320,10,111,2,0,
320,10,127,4,0,
320,10,133,1,37,
320,10,150,1,1,
320,10,156,1,27,
320,10,156,4,0,
320,10,164,4,0,
320,10,173,2,0,
320,10,173,3,0,
320,10,174,2,0,
320,10,182,4,0,
320,10,196,3,0,
320,10,203,4,0,
320,10,205,1,11,
320,10,205,3,0,
320,10,207,2,0,
320,10,207,4,0,
320,10,213,4,0,
320,10,214,2,0,
320,10,214,4,0,
320,10,216,4,0,
320,10,218,4,0,
320,10,237,4,0,
320,10,240,4,0,
320,10,249,4,0,
320,10,250,1,14,
320,10,250,4,0,
320,10,258,4,0,
320,10,263,4,0,
320,10,290,4,0,
320,10,291,1,41,
320,10,291,3,0,
320,10,310,1,17,
320,10,317,4,0,
320,10,321,2,0,
320,10,323,1,34,
320,10,340,1,44,
320,10,340,3,0,
320,10,352,1,21,
320,10,352,4,0,
320,10,362,1,31,
320,10,362,4,0,
320,10,363,4,0,
320,10,392,2,0,
320,10,419,4,0,
320,10,445,4,0,
320,11,34,2,0,
320,11,37,2,0,
320,11,38,2,0,
320,11,45,1,4,
320,11,46,4,0,
320,11,54,1,24,
320,11,55,1,7,
320,11,56,1,47,
320,11,57,4,0,
320,11,58,4,0,
320,11,59,4,0,
320,11,70,4,0,
320,11,89,4,0,
320,11,90,2,0,
320,11,92,4,0,
320,11,104,4,0,
320,11,111,2,0,
320,11,127,4,0,
320,11,133,1,37,
320,11,150,1,1,
320,11,156,1,27,
320,11,156,4,0,
320,11,164,4,0,
320,11,173,2,0,
320,11,174,2,0,
320,11,182,4,0,
320,11,205,1,11,
320,11,207,4,0,
320,11,213,4,0,
320,11,214,2,0,
320,11,216,4,0,
320,11,218,4,0,
320,11,237,4,0,
320,11,240,4,0,
320,11,249,4,0,
320,11,250,1,14,
320,11,258,4,0,
320,11,263,4,0,
320,11,291,1,41,
320,11,291,4,0,
320,11,310,1,17,
320,11,317,4,0,
320,11,321,2,0,
320,11,323,1,34,
320,11,340,1,44,
320,11,352,1,21,
320,11,362,1,31,
320,11,392,2,0,
320,11,428,2,0,
320,11,484,1,50,
320,11,487,2,0,
320,11,496,4,0,
320,11,497,4,0,
320,11,503,4,0,
320,11,523,4,0,
320,12,45,1,5,
320,12,46,4,0,
320,12,54,1,32,
320,12,55,1,10,
320,12,56,1,50,
320,12,57,4,0,
320,12,58,4,0,
320,12,59,4,0,
320,12,70,4,0,
320,12,89,4,0,
320,12,92,4,0,
320,12,104,4,0,
320,12,127,4,0,
320,12,133,1,46,
320,12,150,1,1,
320,12,156,1,37,
320,12,156,4,0,
320,12,182,4,0,
320,12,205,1,14,
320,12,213,4,0,
320,12,216,4,0,
320,12,218,4,0,
320,12,237,4,0,
320,12,240,4,0,
320,12,249,4,0,
320,12,250,1,19,
320,12,258,4,0,
320,12,263,4,0,
320,12,290,4,0,
320,12,291,4,0,
320,12,310,1,23,
320,12,317,4,0,
320,12,323,1,41,
320,12,352,1,28,
320,12,352,4,0,
320,13,34,3,0,
320,13,38,3,0,
320,13,45,1,5,
320,13,46,4,0,
320,13,54,1,32,
320,13,55,1,10,
320,13,56,1,50,
320,13,57,4,0,
320,13,58,4,0,
320,13,59,4,0,
320,13,70,4,0,
320,13,89,4,0,
320,13,92,4,0,
320,13,102,3,0,
320,13,104,4,0,
320,13,120,3,0,
320,13,127,4,0,
320,13,133,1,46,
320,13,150,1,1,
320,13,156,1,37,
320,13,156,4,0,
320,13,164,3,0,
320,13,182,4,0,
320,13,196,3,0,
320,13,205,1,14,
320,13,207,3,0,
320,13,213,4,0,
320,13,216,4,0,
320,13,218,4,0,
320,13,237,4,0,
320,13,240,4,0,
320,13,249,4,0,
320,13,250,1,19,
320,13,258,4,0,
320,13,263,4,0,
320,13,290,4,0,
320,13,291,4,0,
320,13,310,1,23,
320,13,317,4,0,
320,13,323,1,41,
320,13,352,1,28,
320,13,352,4,0,
320,14,34,2,0,
320,14,37,2,0,
320,14,38,2,0,
320,14,45,1,4,
320,14,46,4,0,
320,14,54,1,24,
320,14,55,1,7,
320,14,56,1,47,
320,14,57,4,0,
320,14,58,4,0,
320,14,59,4,0,
320,14,70,4,0,
320,14,89,4,0,
320,14,90,2,0,
320,14,92,4,0,
320,14,104,4,0,
320,14,111,2,0,
320,14,127,4,0,
320,14,133,1,37,
320,14,150,1,1,
320,14,156,1,27,
320,14,156,4,0,
320,14,164,4,0,
320,14,173,2,0,
320,14,173,3,0,
320,14,174,2,0,
320,14,182,4,0,
320,14,196,3,0,
320,14,205,1,11,
320,14,207,4,0,
320,14,213,4,0,
320,14,214,2,0,
320,14,214,3,0,
320,14,216,4,0,
320,14,218,4,0,
320,14,237,4,0,
320,14,240,4,0,
320,14,249,4,0,
320,14,250,1,14,
320,14,258,4,0,
320,14,263,4,0,
320,14,291,1,41,
320,14,291,4,0,
320,14,304,3,0,
320,14,310,1,17,
320,14,317,4,0,
320,14,321,2,0,
320,14,323,1,34,
320,14,340,1,44,
320,14,340,3,0,
320,14,352,1,21,
320,14,362,1,31,
320,14,392,2,0,
320,14,428,2,0,
320,14,428,3,0,
320,14,484,1,50,
320,14,487,2,0,
320,14,496,4,0,
320,14,497,4,0,
320,14,503,4,0,
320,14,523,4,0,
320,15,34,2,0,
320,15,37,2,0,
320,15,38,2,0,
320,15,45,1,4,
320,15,46,4,0,
320,15,54,1,24,
320,15,55,1,7,
320,15,56,1,47,
320,15,57,4,0,
320,15,58,4,0,
320,15,59,4,0,
320,15,70,4,0,
320,15,89,4,0,
320,15,90,2,0,
320,15,92,4,0,
320,15,104,4,0,
320,15,111,2,0,
320,15,127,4,0,
320,15,133,1,37,
320,15,150,1,1,
320,15,156,1,27,
320,15,156,4,0,
320,15,164,4,0,
320,15,173,2,0,
320,15,174,2,0,
320,15,182,4,0,
320,15,205,1,11,
320,15,207,4,0,
320,15,213,4,0,
320,15,214,2,0,
320,15,214,4,0,
320,15,216,4,0,
320,15,218,4,0,
320,15,237,4,0,
320,15,240,4,0,
320,15,249,4,0,
320,15,250,1,14,
320,15,258,4,0,
320,15,263,4,0,
320,15,291,1,41,
320,15,310,1,17,
320,15,317,4,0,
320,15,321,2,0,
320,15,323,1,34,
320,15,340,1,44,
320,15,352,1,21,
320,15,362,1,31,
320,15,392,2,0,
320,15,428,2,0,
320,15,484,1,50,
320,15,487,2,0,
320,15,496,4,0,
320,15,497,4,0,
320,15,499,2,0,
320,15,503,4,0,
320,15,523,4,0,
320,15,590,4,0,
320,16,34,2,0,
320,16,37,2,0,
320,16,38,2,0,
320,16,45,1,4,1
320,16,46,4,0,
320,16,54,1,22,1
320,16,55,1,7,1
320,16,56,1,49,1
320,16,57,4,0,
320,16,58,4,0,
320,16,59,4,0,
320,16,70,4,0,
320,16,89,4,0,
320,16,90,2,0,
320,16,92,4,0,
320,16,104,4,0,
320,16,111,2,0,
320,16,127,4,0,
320,16,133,1,37,1
320,16,150,1,1,1
320,16,156,1,29,1
320,16,156,4,0,
320,16,164,4,0,
320,16,173,2,0,
320,16,173,3,0,
320,16,174,2,0,
320,16,182,4,0,
320,16,196,3,0,
320,16,205,1,10,1
320,16,207,4,0,
320,16,213,4,0,
320,16,214,2,0,
320,16,214,4,0,
320,16,216,4,0,
320,16,218,4,0,
320,16,237,4,0,
320,16,240,4,0,
320,16,249,4,0,
320,16,250,1,13,1
320,16,258,4,0,
320,16,263,4,0,
320,16,290,4,0,
320,16,291,1,33,1
320,16,291,4,0,
320,16,304,3,0,
320,16,310,1,16,1
320,16,317,4,0,
320,16,321,2,0,
320,16,323,1,41,1
320,16,340,1,45,1
320,16,340,3,0,
320,16,352,1,19,1
320,16,352,3,0,
320,16,362,1,25,1
320,16,392,2,0,
320,16,428,2,0,
320,16,428,3,0,
320,16,484,1,53,1
320,16,487,2,0,
320,16,496,4,0,
320,16,497,4,0,
320,16,499,2,0,
320,16,503,4,0,
320,16,523,4,0,
320,16,590,4,0,
320,17,34,2,0,
320,17,37,2,0,
320,17,38,2,0,
320,17,45,1,4,
320,17,46,4,0,
320,17,54,1,22,
320,17,55,1,7,
320,17,56,1,49,
320,17,57,4,0,
320,17,58,4,0,
320,17,59,4,0,
320,17,89,4,0,
320,17,90,2,0,
320,17,92,4,0,
320,17,104,4,0,
320,17,111,2,0,
320,17,127,4,0,
320,17,133,1,37,
320,17,150,1,1,
320,17,156,1,29,
320,17,156,4,0,
320,17,164,4,0,
320,17,173,2,0,
320,17,174,2,0,
320,17,182,4,0,
320,17,205,1,10,
320,17,207,4,0,
320,17,213,4,0,
320,17,214,2,0,
320,17,214,4,0,
320,17,216,4,0,
320,17,218,4,0,
320,17,237,4,0,
320,17,240,4,0,
320,17,250,1,13,
320,17,258,4,0,
320,17,263,4,0,
320,17,291,1,41,
320,17,310,1,16,
320,17,317,4,0,
320,17,321,2,0,
320,17,323,1,33,
320,17,340,1,45,
320,17,352,1,19,
320,17,362,1,25,
320,17,392,2,0,
320,17,428,2,0,
320,17,484,1,53,
320,17,487,2,0,
320,17,496,4,0,
320,17,497,4,0,
320,17,499,2,0,
320,17,503,4,0,
320,17,523,4,0,
320,17,590,4,0,
320,18,34,2,0,
320,18,37,2,0,
320,18,38,2,0,
320,18,45,1,4,
320,18,46,4,0,
320,18,54,1,22,
320,18,55,1,7,
320,18,56,1,49,
320,18,57,4,0,
320,18,58,4,0,
320,18,59,4,0,
320,18,89,4,0,
320,18,90,2,0,
320,18,92,4,0,
320,18,104,4,0,
320,18,111,2,0,
320,18,127,4,0,
320,18,133,1,37,
320,18,150,1,1,
320,18,156,1,29,
320,18,156,4,0,
320,18,164,4,0,
320,18,173,2,0,
320,18,174,2,0,
320,18,182,4,0,
320,18,205,1,10,
320,18,207,4,0,
320,18,213,4,0,
320,18,214,2,0,
320,18,214,4,0,
320,18,216,4,0,
320,18,218,4,0,
320,18,237,4,0,
320,18,240,4,0,
320,18,250,1,13,
320,18,258,4,0,
320,18,263,4,0,
320,18,291,1,41,
320,18,310,1,16,
320,18,317,4,0,
320,18,321,2,0,
320,18,323,1,33,
320,18,340,1,45,
320,18,352,1,19,
320,18,362,1,25,
320,18,392,2,0,
320,18,428,2,0,
320,18,484,1,53,
320,18,487,2,0,
320,18,496,4,0,
320,18,497,4,0,
320,18,499,2,0,
320,18,503,4,0,
320,18,523,4,0,
320,18,590,4,0,
321,5,45,1,1,2
321,5,45,1,5,
321,5,46,4,0,
321,5,54,1,32,
321,5,55,1,1,3
321,5,55,1,10,
321,5,56,1,59,
321,5,57,4,0,
321,5,58,4,0,
321,5,59,4,0,
321,5,63,4,0,
321,5,70,4,0,
321,5,89,4,0,
321,5,92,4,0,
321,5,104,4,0,
321,5,127,4,0,
321,5,133,1,52,
321,5,150,1,1,1
321,5,156,1,37,
321,5,156,4,0,
321,5,182,4,0,
321,5,205,1,1,4
321,5,205,1,14,
321,5,213,4,0,
321,5,216,4,0,
321,5,218,4,0,
321,5,237,4,0,
321,5,240,4,0,
321,5,249,4,0,
321,5,250,1,19,
321,5,258,4,0,
321,5,263,4,0,
321,5,290,4,0,
321,5,291,4,0,
321,5,310,1,23,
321,5,317,4,0,
321,5,323,1,44,
321,5,352,1,28,
321,5,352,4,0,
321,6,34,3,0,
321,6,38,3,0,
321,6,45,1,1,2
321,6,45,1,5,
321,6,46,4,0,
321,6,54,1,32,
321,6,55,1,1,3
321,6,55,1,10,
321,6,56,1,59,
321,6,57,4,0,
321,6,58,4,0,
321,6,59,4,0,
321,6,63,4,0,
321,6,70,4,0,
321,6,89,4,0,
321,6,92,4,0,
321,6,102,3,0,
321,6,104,4,0,
321,6,111,3,0,
321,6,127,4,0,
321,6,133,1,52,
321,6,150,1,1,1
321,6,156,1,37,
321,6,156,4,0,
321,6,164,3,0,
321,6,173,3,0,
321,6,182,4,0,
321,6,196,3,0,
321,6,203,3,0,
321,6,205,1,1,4
321,6,205,1,14,
321,6,205,3,0,
321,6,207,3,0,
321,6,213,4,0,
321,6,214,3,0,
321,6,216,4,0,
321,6,218,4,0,
321,6,237,4,0,
321,6,240,4,0,
321,6,249,4,0,
321,6,250,1,19,
321,6,258,4,0,
321,6,263,4,0,
321,6,290,4,0,
321,6,291,4,0,
321,6,310,1,23,
321,6,317,4,0,
321,6,323,1,44,
321,6,352,1,28,
321,6,352,4,0,
321,7,34,3,0,
321,7,38,3,0,
321,7,45,1,1,2
321,7,45,1,5,
321,7,46,4,0,
321,7,54,1,32,
321,7,55,1,1,3
321,7,55,1,10,
321,7,56,1,59,
321,7,57,4,0,
321,7,58,4,0,
321,7,59,4,0,
321,7,63,4,0,
321,7,70,4,0,
321,7,89,4,0,
321,7,92,4,0,
321,7,102,3,0,
321,7,104,4,0,
321,7,127,4,0,
321,7,133,1,52,
321,7,150,1,1,1
321,7,156,1,37,
321,7,156,4,0,
321,7,164,3,0,
321,7,182,4,0,
321,7,205,1,1,4
321,7,205,1,14,
321,7,213,4,0,
321,7,216,4,0,
321,7,218,4,0,
321,7,237,4,0,
321,7,240,4,0,
321,7,249,4,0,
321,7,250,1,19,
321,7,258,4,0,
321,7,263,4,0,
321,7,290,4,0,
321,7,291,4,0,
321,7,310,1,23,
321,7,317,4,0,
321,7,323,1,44,
321,7,352,1,28,
321,7,352,4,0,
321,8,45,1,1,2
321,8,45,1,4,
321,8,46,4,0,
321,8,54,1,24,
321,8,55,1,1,3
321,8,55,1,7,
321,8,56,1,62,
321,8,57,4,0,
321,8,58,4,0,
321,8,59,4,0,
321,8,63,4,0,
321,8,70,4,0,
321,8,89,4,0,
321,8,92,4,0,
321,8,104,4,0,
321,8,127,4,0,
321,8,133,1,37,
321,8,150,1,1,1
321,8,156,1,27,
321,8,156,4,0,
321,8,164,4,0,
321,8,182,4,0,
321,8,203,4,0,
321,8,205,1,1,4
321,8,205,1,11,
321,8,207,4,0,
321,8,213,4,0,
321,8,214,4,0,
321,8,216,4,0,
321,8,218,4,0,
321,8,237,4,0,
321,8,240,4,0,
321,8,249,4,0,
321,8,250,1,14,
321,8,258,4,0,
321,8,263,4,0,
321,8,290,4,0,
321,8,291,1,46,
321,8,310,1,17,
321,8,317,4,0,
321,8,323,1,34,
321,8,340,1,54,
321,8,352,1,21,
321,8,352,4,0,
321,8,362,1,31,
321,8,362,4,0,
321,8,363,4,0,
321,8,416,4,0,
321,8,419,4,0,
321,8,445,4,0,
321,9,45,1,1,2
321,9,45,1,4,
321,9,46,4,0,
321,9,54,1,24,
321,9,55,1,1,3
321,9,55,1,7,
321,9,56,1,62,
321,9,57,4,0,
321,9,58,4,0,
321,9,59,4,0,
321,9,63,4,0,
321,9,70,4,0,
321,9,89,4,0,
321,9,92,4,0,
321,9,104,4,0,
321,9,127,4,0,
321,9,133,1,37,
321,9,150,1,1,1
321,9,156,1,27,
321,9,156,4,0,
321,9,164,4,0,
321,9,173,3,0,
321,9,182,4,0,
321,9,196,3,0,
321,9,203,4,0,
321,9,205,1,1,4
321,9,205,1,11,
321,9,205,3,0,
321,9,207,4,0,
321,9,213,4,0,
321,9,214,4,0,
321,9,216,4,0,
321,9,218,4,0,
321,9,237,4,0,
321,9,240,4,0,
321,9,249,4,0,
321,9,250,1,14,
321,9,258,4,0,
321,9,263,4,0,
321,9,290,4,0,
321,9,291,1,46,
321,9,291,3,0,
321,9,310,1,17,
321,9,317,4,0,
321,9,323,1,34,
321,9,340,1,54,
321,9,340,3,0,
321,9,352,1,21,
321,9,352,4,0,
321,9,362,1,31,
321,9,362,4,0,
321,9,363,4,0,
321,9,416,4,0,
321,9,419,4,0,
321,9,442,3,0,
321,9,445,4,0,
321,10,29,3,0,
321,10,45,1,1,2
321,10,45,1,4,
321,10,46,4,0,
321,10,54,1,24,
321,10,55,1,1,3
321,10,55,1,7,
321,10,56,1,62,
321,10,57,4,0,
321,10,58,4,0,
321,10,59,4,0,
321,10,63,4,0,
321,10,70,4,0,
321,10,89,4,0,
321,10,92,4,0,
321,10,104,4,0,
321,10,127,4,0,
321,10,133,1,37,
321,10,150,1,1,1
321,10,156,1,27,
321,10,156,4,0,
321,10,164,4,0,
321,10,173,3,0,
321,10,182,4,0,
321,10,196,3,0,
321,10,203,4,0,
321,10,205,1,1,4
321,10,205,1,11,
321,10,205,3,0,
321,10,207,4,0,
321,10,213,4,0,
321,10,214,4,0,
321,10,216,4,0,
321,10,218,4,0,
321,10,237,4,0,
321,10,240,4,0,
321,10,249,4,0,
321,10,250,1,14,
321,10,250,4,0,
321,10,258,4,0,
321,10,263,4,0,
321,10,290,4,0,
321,10,291,1,46,
321,10,291,3,0,
321,10,310,1,17,
321,10,317,4,0,
321,10,323,1,34,
321,10,335,3,0,
321,10,340,1,54,
321,10,340,3,0,
321,10,352,1,21,
321,10,352,4,0,
321,10,362,1,31,
321,10,362,4,0,
321,10,363,4,0,
321,10,416,4,0,
321,10,419,4,0,
321,10,442,3,0,
321,10,445,4,0,
321,11,45,1,1,2
321,11,45,1,4,
321,11,46,4,0,
321,11,54,1,24,
321,11,55,1,1,3
321,11,55,1,7,
321,11,56,1,62,
321,11,57,4,0,
321,11,58,4,0,
321,11,59,4,0,
321,11,63,4,0,
321,11,70,4,0,
321,11,89,4,0,
321,11,92,4,0,
321,11,104,4,0,
321,11,127,4,0,
321,11,133,1,37,
321,11,150,1,1,1
321,11,156,1,27,
321,11,156,4,0,
321,11,164,4,0,
321,11,182,4,0,
321,11,205,1,1,4
321,11,205,1,11,
321,11,207,4,0,
321,11,213,4,0,
321,11,216,4,0,
321,11,218,4,0,
321,11,237,4,0,
321,11,240,4,0,
321,11,249,4,0,
321,11,250,1,14,
321,11,258,4,0,
321,11,263,4,0,
321,11,291,1,46,
321,11,291,4,0,
321,11,310,1,17,
321,11,317,4,0,
321,11,323,1,34,
321,11,340,1,54,
321,11,352,1,21,
321,11,362,1,31,
321,11,416,4,0,
321,11,484,1,70,
321,11,496,4,0,
321,11,497,4,0,
321,11,503,4,0,
321,11,523,4,0,
321,12,45,1,1,2
321,12,45,1,5,
321,12,46,4,0,
321,12,54,1,32,
321,12,55,1,1,3
321,12,55,1,10,
321,12,56,1,59,
321,12,57,4,0,
321,12,58,4,0,
321,12,59,4,0,
321,12,63,4,0,
321,12,70,4,0,
321,12,89,4,0,
321,12,92,4,0,
321,12,104,4,0,
321,12,127,4,0,
321,12,133,1,52,
321,12,150,1,1,1
321,12,156,1,37,
321,12,156,4,0,
321,12,182,4,0,
321,12,205,1,1,4
321,12,205,1,14,
321,12,213,4,0,
321,12,216,4,0,
321,12,218,4,0,
321,12,237,4,0,
321,12,240,4,0,
321,12,249,4,0,
321,12,250,1,19,
321,12,258,4,0,
321,12,263,4,0,
321,12,290,4,0,
321,12,291,4,0,
321,12,310,1,23,
321,12,317,4,0,
321,12,323,1,44,
321,12,352,1,28,
321,12,352,4,0,
321,13,34,3,0,
321,13,38,3,0,
321,13,45,1,1,2
321,13,45,1,5,
321,13,46,4,0,
321,13,54,1,32,
321,13,55,1,1,3
321,13,55,1,10,
321,13,56,1,59,
321,13,57,4,0,
321,13,58,4,0,
321,13,59,4,0,
321,13,63,4,0,
321,13,70,4,0,
321,13,89,4,0,
321,13,92,4,0,
321,13,102,3,0,
321,13,104,4,0,
321,13,120,3,0,
321,13,127,4,0,
321,13,133,1,52,
321,13,150,1,1,1
321,13,156,1,37,
321,13,156,4,0,
321,13,164,3,0,
321,13,182,4,0,
321,13,196,3,0,
321,13,205,1,1,4
321,13,205,1,14,
321,13,207,3,0,
321,13,213,4,0,
321,13,216,4,0,
321,13,218,4,0,
321,13,237,4,0,
321,13,240,4,0,
321,13,249,4,0,
321,13,250,1,19,
321,13,258,4,0,
321,13,263,4,0,
321,13,290,4,0,
321,13,291,4,0,
321,13,310,1,23,
321,13,317,4,0,
321,13,323,1,44,
321,13,352,1,28,
321,13,352,4,0,
321,14,45,1,1,2
321,14,45,1,4,
321,14,46,4,0,
321,14,54,1,24,
321,14,55,1,1,3
321,14,55,1,7,
321,14,56,1,62,
321,14,57,4,0,
321,14,58,4,0,
321,14,59,4,0,
321,14,63,4,0,
321,14,70,4,0,
321,14,89,4,0,
321,14,92,4,0,
321,14,104,4,0,
321,14,127,4,0,
321,14,133,1,37,
321,14,150,1,1,1
321,14,156,1,27,
321,14,156,4,0,
321,14,164,4,0,
321,14,173,3,0,
321,14,182,4,0,
321,14,196,3,0,
321,14,205,1,1,4
321,14,205,1,11,
321,14,207,4,0,
321,14,213,4,0,
321,14,214,3,0,
321,14,216,4,0,
321,14,218,4,0,
321,14,237,4,0,
321,14,240,4,0,
321,14,249,4,0,
321,14,250,1,14,
321,14,258,4,0,
321,14,263,4,0,
321,14,291,1,46,
321,14,291,4,0,
321,14,304,3,0,
321,14,310,1,17,
321,14,317,4,0,
321,14,323,1,34,
321,14,335,3,0,
321,14,340,1,54,
321,14,340,3,0,
321,14,352,1,21,
321,14,362,1,31,
321,14,416,4,0,
321,14,428,3,0,
321,14,442,3,0,
321,14,484,1,70,
321,14,496,4,0,
321,14,497,4,0,
321,14,503,4,0,
321,14,523,4,0,
321,15,45,1,1,2
321,15,45,1,4,
321,15,46,4,0,
321,15,54,1,24,
321,15,55,1,1,3
321,15,55,1,7,
321,15,56,1,62,
321,15,57,4,0,
321,15,58,4,0,
321,15,59,4,0,
321,15,63,4,0,
321,15,70,4,0,
321,15,89,4,0,
321,15,92,4,0,
321,15,104,4,0,
321,15,127,4,0,
321,15,133,1,37,
321,15,150,1,1,1
321,15,156,1,27,
321,15,156,4,0,
321,15,164,4,0,
321,15,182,4,0,
321,15,205,1,1,4
321,15,205,1,11,
321,15,207,4,0,
321,15,213,4,0,
321,15,214,4,0,
321,15,216,4,0,
321,15,218,4,0,
321,15,237,4,0,
321,15,240,4,0,
321,15,249,4,0,
321,15,250,1,14,
321,15,258,4,0,
321,15,263,4,0,
321,15,291,1,46,
321,15,310,1,17,
321,15,317,4,0,
321,15,323,1,34,
321,15,340,1,54,
321,15,352,1,21,
321,15,362,1,31,
321,15,416,4,0,
321,15,484,1,70,
321,15,496,4,0,
321,15,497,4,0,
321,15,503,4,0,
321,15,523,4,0,
321,15,590,4,0,
321,16,45,1,1,3
321,16,45,1,4,1
321,16,46,4,0,
321,16,54,1,22,1
321,16,55,1,1,4
321,16,55,1,7,1
321,16,56,1,58,1
321,16,57,4,0,
321,16,58,4,0,
321,16,59,4,0,
321,16,63,4,0,
321,16,70,4,0,
321,16,89,4,0,
321,16,92,4,0,
321,16,104,4,0,
321,16,127,4,0,
321,16,133,1,37,1
321,16,150,1,1,2
321,16,156,1,25,1
321,16,156,4,0,
321,16,164,4,0,
321,16,173,3,0,
321,16,182,4,0,
321,16,196,3,0,
321,16,205,1,1,5
321,16,205,1,10,1
321,16,207,4,0,
321,16,213,4,0,
321,16,214,4,0,
321,16,216,4,0,
321,16,218,4,0,
321,16,237,4,0,
321,16,240,4,0,
321,16,249,4,0,
321,16,250,1,13,1
321,16,258,4,0,
321,16,263,4,0,
321,16,290,4,0,
321,16,291,1,44,1
321,16,291,4,0,
321,16,304,3,0,
321,16,310,1,16,1
321,16,317,4,0,
321,16,323,1,33,1
321,16,335,3,0,
321,16,340,1,51,1
321,16,340,3,0,
321,16,352,1,19,1
321,16,352,3,0,
321,16,362,1,29,1
321,16,416,4,0,
321,16,428,3,0,
321,16,442,3,0,
321,16,484,1,1,1
321,16,484,1,65,1
321,16,496,4,0,
321,16,497,4,0,
321,16,503,4,0,
321,16,523,4,0,
321,16,590,4,0,
321,17,45,1,1,5
321,17,45,1,4,
321,17,46,4,0,
321,17,54,1,22,
321,17,55,1,1,6
321,17,55,1,7,
321,17,56,1,58,
321,17,57,4,0,
321,17,58,4,0,
321,17,59,4,0,
321,17,63,4,0,
321,17,89,4,0,
321,17,92,4,0,
321,17,104,4,0,
321,17,127,4,0,
321,17,133,1,37,
321,17,150,1,1,4
321,17,156,1,29,
321,17,156,4,0,
321,17,164,4,0,
321,17,182,4,0,
321,17,205,1,1,7
321,17,205,1,10,
321,17,207,4,0,
321,17,213,4,0,
321,17,214,4,0,
321,17,216,4,0,
321,17,218,4,0,
321,17,237,4,0,
321,17,240,4,0,
321,17,250,1,13,
321,17,258,4,0,
321,17,263,4,0,
321,17,291,1,44,
321,17,310,1,16,
321,17,317,4,0,
321,17,323,1,33,
321,17,340,1,51,
321,17,352,1,19,
321,17,362,1,25,
321,17,416,4,0,
321,17,484,1,1,3
321,17,484,1,65,
321,17,487,1,1,1
321,17,496,4,0,
321,17,497,4,0,
321,17,503,4,0,
321,17,523,4,0,
321,17,568,1,1,2
321,17,590,4,0,
321,18,45,1,1,5
321,18,45,1,4,
321,18,46,4,0,
321,18,54,1,22,
321,18,55,1,1,6
321,18,55,1,7,
321,18,56,1,58,
321,18,57,4,0,
321,18,58,4,0,
321,18,59,4,0,
321,18,63,4,0,
321,18,89,4,0,
321,18,92,4,0,
321,18,104,4,0,
321,18,127,4,0,
321,18,133,1,37,
321,18,150,1,1,4
321,18,156,1,29,
321,18,156,4,0,
321,18,164,4,0,
321,18,182,4,0,
321,18,205,1,1,7
321,18,205,1,10,
321,18,207,4,0,
321,18,213,4,0,
321,18,214,4,0,
321,18,216,4,0,
321,18,218,4,0,
321,18,237,4,0,
321,18,240,4,0,
321,18,250,1,13,
321,18,258,4,0,
321,18,263,4,0,
321,18,291,1,44,
321,18,310,1,16,
321,18,317,4,0,
321,18,323,1,33,
321,18,340,1,51,
321,18,352,1,19,
321,18,362,1,25,
321,18,416,4,0,
321,18,484,1,1,3
321,18,484,1,65,
321,18,487,1,1,1
321,18,496,4,0,
321,18,497,4,0,
321,18,503,4,0,
321,18,523,4,0,
321,18,568,1,1,2
321,18,590,4,0,
322,5,23,2,0,
322,5,33,1,1,2
322,5,34,2,0,
322,5,36,1,29,
322,5,38,1,49,
322,5,45,1,1,1
322,5,52,1,11,
322,5,53,1,41,
322,5,53,4,0,
322,5,70,4,0,
322,5,89,1,35,
322,5,89,4,0,
322,5,91,4,0,
322,5,92,4,0,
322,5,104,4,0,
322,5,111,2,0,
322,5,116,1,25,
322,5,126,4,0,
322,5,133,1,31,
322,5,156,4,0,
322,5,182,4,0,
322,5,184,2,0,
322,5,201,4,0,
322,5,205,2,0,
322,5,213,4,0,
322,5,216,4,0,
322,5,218,4,0,
322,5,222,1,19,
322,5,237,4,0,
322,5,241,4,0,
322,5,249,4,0,
322,5,263,4,0,
322,5,290,4,0,
322,5,315,4,0,
322,5,317,4,0,
322,5,336,2,0,
322,6,23,2,0,
322,6,33,1,1,2
322,6,34,2,0,
322,6,34,3,0,
322,6,36,1,29,
322,6,38,1,49,
322,6,38,3,0,
322,6,45,1,1,1
322,6,52,1,11,
322,6,53,1,41,
322,6,53,4,0,
322,6,70,4,0,
322,6,89,1,35,
322,6,89,4,0,
322,6,91,4,0,
322,6,92,4,0,
322,6,102,3,0,
322,6,104,4,0,
322,6,111,2,0,
322,6,111,3,0,
322,6,116,1,25,
322,6,126,4,0,
322,6,133,1,31,
322,6,156,4,0,
322,6,157,3,0,
322,6,164,3,0,
322,6,173,3,0,
322,6,182,4,0,
322,6,184,2,0,
322,6,189,3,0,
322,6,201,4,0,
322,6,203,3,0,
322,6,205,2,0,
322,6,205,3,0,
322,6,207,3,0,
322,6,213,4,0,
322,6,214,3,0,
322,6,216,4,0,
322,6,218,4,0,
322,6,222,1,19,
322,6,237,4,0,
322,6,241,4,0,
322,6,249,4,0,
322,6,263,4,0,
322,6,290,4,0,
322,6,315,4,0,
322,6,317,4,0,
322,6,336,2,0,
322,7,23,2,0,
322,7,33,1,1,2
322,7,34,2,0,
322,7,34,3,0,
322,7,36,1,29,
322,7,38,1,49,
322,7,38,3,0,
322,7,45,1,1,1
322,7,52,1,11,
322,7,53,1,41,
322,7,53,4,0,
322,7,70,4,0,
322,7,89,1,35,
322,7,89,4,0,
322,7,91,4,0,
322,7,92,4,0,
322,7,102,3,0,
322,7,104,4,0,
322,7,111,2,0,
322,7,116,1,25,
322,7,126,4,0,
322,7,133,1,31,
322,7,156,4,0,
322,7,157,3,0,
322,7,164,3,0,
322,7,182,4,0,
322,7,184,2,0,
322,7,201,4,0,
322,7,205,2,0,
322,7,213,4,0,
322,7,216,4,0,
322,7,218,4,0,
322,7,222,1,19,
322,7,237,4,0,
322,7,241,4,0,
322,7,249,4,0,
322,7,263,4,0,
322,7,290,4,0,
322,7,315,4,0,
322,7,317,4,0,
322,7,336,2,0,
322,8,23,2,0,
322,8,33,1,1,2
322,8,34,2,0,
322,8,36,1,21,
322,8,38,1,51,
322,8,45,1,1,1
322,8,52,1,5,
322,8,53,1,45,
322,8,53,4,0,
322,8,70,4,0,
322,8,89,1,41,
322,8,89,4,0,
322,8,91,4,0,
322,8,92,4,0,
322,8,104,4,0,
322,8,111,2,0,
322,8,116,1,15,
322,8,126,4,0,
322,8,133,1,25,
322,8,156,4,0,
322,8,157,4,0,
322,8,164,4,0,
322,8,182,4,0,
322,8,184,2,0,
322,8,201,4,0,
322,8,203,4,0,
322,8,205,2,0,
322,8,207,4,0,
322,8,213,4,0,
322,8,214,4,0,
322,8,216,4,0,
322,8,218,4,0,
322,8,222,1,11,
322,8,237,4,0,
322,8,241,4,0,
322,8,246,2,0,
322,8,249,4,0,
322,8,257,2,0,
322,8,261,4,0,
322,8,263,4,0,
322,8,281,2,0,
322,8,290,4,0,
322,8,315,4,0,
322,8,317,4,0,
322,8,336,2,0,
322,8,363,4,0,
322,8,414,1,35,
322,8,426,2,0,
322,8,436,1,31,
322,8,445,4,0,
322,8,446,4,0,
322,9,23,2,0,
322,9,33,1,1,2
322,9,34,2,0,
322,9,36,1,21,
322,9,38,1,51,
322,9,45,1,1,1
322,9,52,1,5,
322,9,53,1,45,
322,9,53,4,0,
322,9,70,4,0,
322,9,89,1,41,
322,9,89,4,0,
322,9,91,4,0,
322,9,92,4,0,
322,9,104,4,0,
322,9,111,2,0,
322,9,116,1,15,
322,9,126,4,0,
322,9,133,1,25,
322,9,156,4,0,
322,9,157,4,0,
322,9,164,4,0,
322,9,173,3,0,
322,9,182,4,0,
322,9,184,2,0,
322,9,189,3,0,
322,9,201,4,0,
322,9,203,4,0,
322,9,205,2,0,
322,9,205,3,0,
322,9,207,4,0,
322,9,213,4,0,
322,9,214,4,0,
322,9,216,4,0,
322,9,218,4,0,
322,9,222,1,11,
322,9,237,4,0,
322,9,241,4,0,
322,9,246,2,0,
322,9,249,4,0,
322,9,257,2,0,
322,9,257,3,0,
322,9,261,4,0,
322,9,263,4,0,
322,9,281,2,0,
322,9,290,4,0,
322,9,315,4,0,
322,9,317,4,0,
322,9,336,2,0,
322,9,363,4,0,
322,9,414,1,35,
322,9,414,3,0,
322,9,426,2,0,
322,9,436,1,31,
322,9,445,4,0,
322,9,446,4,0,
322,10,23,2,0,
322,10,29,3,0,
322,10,33,1,1,2
322,10,34,2,0,
322,10,36,1,21,
322,10,38,1,51,
322,10,45,1,1,1
322,10,52,1,5,
322,10,53,1,45,
322,10,53,4,0,
322,10,70,4,0,
322,10,89,1,41,
322,10,89,4,0,
322,10,91,4,0,
322,10,92,4,0,
322,10,104,4,0,
322,10,111,2,0,
322,10,116,1,15,
322,10,126,4,0,
322,10,133,1,25,
322,10,156,4,0,
322,10,157,4,0,
322,10,164,4,0,
322,10,173,3,0,
322,10,182,4,0,
322,10,184,2,0,
322,10,189,3,0,
322,10,201,4,0,
322,10,203,4,0,
322,10,205,2,0,
322,10,205,3,0,
322,10,207,4,0,
322,10,213,4,0,
322,10,214,4,0,
322,10,216,4,0,
322,10,218,4,0,
322,10,222,1,11,
322,10,237,4,0,
322,10,241,4,0,
322,10,246,2,0,
322,10,249,4,0,
322,10,254,2,0,
322,10,255,2,0,
322,10,256,2,0,
322,10,257,2,0,
322,10,257,3,0,
322,10,261,4,0,
322,10,263,4,0,
322,10,281,2,0,
322,10,290,4,0,
322,10,315,4,0,
322,10,317,4,0,
322,10,336,2,0,
322,10,363,4,0,
322,10,414,1,35,
322,10,414,3,0,
322,10,426,2,0,
322,10,436,1,31,
322,10,445,4,0,
322,10,446,4,0,
322,11,23,2,0,
322,11,33,1,1,2
322,11,34,2,0,
322,11,36,1,25,
322,11,38,1,55,
322,11,45,1,1,1
322,11,52,1,5,
322,11,53,1,51,
322,11,53,4,0,
322,11,70,4,0,
322,11,89,1,45,
322,11,89,4,0,
322,11,91,4,0,
322,11,92,4,0,
322,11,104,4,0,
322,11,111,2,0,
322,11,116,1,15,
322,11,126,4,0,
322,11,133,1,31,
322,11,156,4,0,
322,11,157,4,0,
322,11,164,4,0,
322,11,182,4,0,
322,11,184,2,0,
322,11,201,4,0,
322,11,203,2,0,
322,11,205,2,0,
322,11,207,4,0,
322,11,213,4,0,
322,11,216,4,0,
322,11,218,4,0,
322,11,222,1,11,
322,11,237,4,0,
322,11,241,4,0,
322,11,246,2,0,
322,11,249,4,0,
322,11,254,2,0,
322,11,255,2,0,
322,11,256,2,0,
322,11,257,2,0,
322,11,261,4,0,
322,11,263,4,0,
322,11,281,2,0,
322,11,315,4,0,
322,11,317,4,0,
322,11,336,2,0,
322,11,414,1,41,
322,11,426,2,0,
322,11,436,1,35,
322,11,442,2,0,
322,11,481,1,21,
322,11,488,4,0,
322,11,496,4,0,
322,11,497,4,0,
322,11,510,4,0,
322,11,523,4,0,
322,12,33,1,1,2
322,12,36,1,29,
322,12,38,1,49,
322,12,45,1,1,1
322,12,52,1,11,
322,12,53,1,41,
322,12,53,4,0,
322,12,70,4,0,
322,12,89,1,35,
322,12,89,4,0,
322,12,91,4,0,
322,12,92,4,0,
322,12,104,4,0,
322,12,116,1,25,
322,12,126,4,0,
322,12,133,1,31,
322,12,156,4,0,
322,12,182,4,0,
322,12,201,4,0,
322,12,213,4,0,
322,12,216,4,0,
322,12,218,4,0,
322,12,222,1,19,
322,12,237,4,0,
322,12,241,4,0,
322,12,249,4,0,
322,12,263,4,0,
322,12,290,4,0,
322,12,315,4,0,
322,12,317,4,0,
322,13,33,1,1,2
322,13,34,3,0,
322,13,36,1,29,
322,13,38,1,49,
322,13,38,3,0,
322,13,45,1,1,1
322,13,52,1,11,
322,13,53,1,41,
322,13,53,4,0,
322,13,70,4,0,
322,13,89,1,35,
322,13,89,4,0,
322,13,91,4,0,
322,13,92,4,0,
322,13,102,3,0,
322,13,104,4,0,
322,13,116,1,25,
322,13,126,4,0,
322,13,133,1,31,
322,13,156,4,0,
322,13,164,3,0,
322,13,182,4,0,
322,13,201,4,0,
322,13,207,3,0,
322,13,213,4,0,
322,13,216,4,0,
322,13,218,4,0,
322,13,222,1,19,
322,13,237,4,0,
322,13,241,4,0,
322,13,249,4,0,
322,13,263,4,0,
322,13,290,4,0,
322,13,315,4,0,
322,13,317,4,0,
322,14,23,2,0,
322,14,33,1,1,2
322,14,34,2,0,
322,14,36,1,31,
322,14,38,1,47,
322,14,45,1,1,1
322,14,52,1,5,
322,14,53,1,43,
322,14,53,4,0,
322,14,70,4,0,
322,14,89,1,40,
322,14,89,4,0,
322,14,91,4,0,
322,14,92,4,0,
322,14,104,4,0,
322,14,111,2,0,
322,14,116,1,12,
322,14,126,4,0,
322,14,133,1,19,
322,14,156,4,0,
322,14,157,4,0,
322,14,164,4,0,
322,14,173,3,0,
322,14,174,1,29,
322,14,182,4,0,
322,14,184,2,0,
322,14,201,4,0,
322,14,203,2,0,
322,14,205,2,0,
322,14,207,4,0,
322,14,213,4,0,
322,14,214,3,0,
322,14,216,4,0,
322,14,218,4,0,
322,14,222,1,8,
322,14,237,4,0,
322,14,241,4,0,
322,14,246,2,0,
322,14,249,4,0,
322,14,254,2,0,
322,14,255,2,0,
322,14,256,2,0,
322,14,257,2,0,
322,14,257,3,0,
322,14,261,4,0,
322,14,263,4,0,
322,14,281,1,36,
322,14,281,2,0,
322,14,315,4,0,
322,14,317,4,0,
322,14,336,2,0,
322,14,414,1,26,
322,14,414,3,0,
322,14,426,2,0,
322,14,436,1,22,
322,14,442,2,0,
322,14,442,3,0,
322,14,446,3,0,
322,14,481,1,15,
322,14,488,4,0,
322,14,495,3,0,
322,14,496,4,0,
322,14,497,4,0,
322,14,510,4,0,
322,14,523,4,0,
322,15,23,2,0,
322,15,33,1,1,2
322,15,34,2,0,
322,15,36,1,31,
322,15,38,1,47,
322,15,45,1,1,1
322,15,52,1,5,
322,15,53,1,43,
322,15,53,4,0,
322,15,70,4,0,
322,15,74,2,0,
322,15,89,1,40,
322,15,89,4,0,
322,15,91,4,0,
322,15,92,4,0,
322,15,104,4,0,
322,15,111,2,0,
322,15,116,1,12,
322,15,126,4,0,
322,15,133,1,19,
322,15,156,4,0,
322,15,157,4,0,
322,15,164,4,0,
322,15,174,1,29,
322,15,182,4,0,
322,15,184,2,0,
322,15,201,4,0,
322,15,203,2,0,
322,15,205,2,0,
322,15,207,4,0,
322,15,213,4,0,
322,15,214,4,0,
322,15,216,4,0,
322,15,218,4,0,
322,15,222,1,8,
322,15,237,4,0,
322,15,241,4,0,
322,15,246,2,0,
322,15,249,4,0,
322,15,254,2,0,
322,15,255,2,0,
322,15,256,2,0,
322,15,257,2,0,
322,15,261,4,0,
322,15,263,4,0,
322,15,267,4,0,
322,15,281,1,36,
322,15,281,2,0,
322,15,315,4,0,
322,15,317,4,0,
322,15,336,2,0,
322,15,414,1,26,
322,15,426,2,0,
322,15,436,1,22,
322,15,442,2,0,
322,15,481,1,15,
322,15,488,4,0,
322,15,496,4,0,
322,15,497,4,0,
322,15,510,4,0,
322,15,523,4,0,
322,15,590,4,0,
322,16,23,2,0,
322,16,33,1,1,2
322,16,34,2,0,
322,16,36,1,31,1
322,16,38,1,47,1
322,16,45,1,1,1
322,16,52,1,5,1
322,16,53,1,43,1
322,16,53,4,0,
322,16,70,4,0,
322,16,74,2,0,
322,16,89,1,40,1
322,16,89,4,0,
322,16,91,4,0,
322,16,92,4,0,
322,16,104,4,0,
322,16,111,2,0,
322,16,116,1,8,1
322,16,126,4,0,
322,16,133,1,19,1
322,16,156,4,0,
322,16,157,4,0,
322,16,164,4,0,
322,16,173,3,0,
322,16,174,1,29,1
322,16,182,4,0,
322,16,184,2,0,
322,16,201,4,0,
322,16,203,2,0,
322,16,205,2,0,
322,16,207,4,0,
322,16,213,4,0,
322,16,214,4,0,
322,16,216,4,0,
322,16,218,4,0,
322,16,222,1,12,1
322,16,237,4,0,
322,16,241,4,0,
322,16,246,2,0,
322,16,249,4,0,
322,16,254,2,0,
322,16,255,2,0,
322,16,256,2,0,
322,16,257,2,0,
322,16,257,3,0,
322,16,261,4,0,
322,16,263,4,0,
322,16,267,4,0,
322,16,281,1,36,1
322,16,281,2,0,
322,16,290,4,0,
322,16,315,4,0,
322,16,317,4,0,
322,16,336,2,0,
322,16,414,1,26,1
322,16,414,3,0,
322,16,426,2,0,
322,16,436,1,22,1
322,16,442,2,0,
322,16,442,3,0,
322,16,446,3,0,
322,16,481,1,15,1
322,16,488,4,0,
322,16,495,3,0,
322,16,496,4,0,
322,16,497,4,0,
322,16,510,4,0,
322,16,523,4,0,
322,16,590,4,0,
322,17,23,2,0,
322,17,33,1,1,2
322,17,34,2,0,
322,17,36,1,31,
322,17,38,1,47,
322,17,45,1,1,1
322,17,52,1,5,
322,17,53,1,43,
322,17,53,4,0,
322,17,74,2,0,
322,17,89,1,40,
322,17,89,4,0,
322,17,92,4,0,
322,17,104,4,0,
322,17,111,2,0,
322,17,116,1,8,
322,17,126,4,0,
322,17,133,1,19,
322,17,156,4,0,
322,17,157,4,0,
322,17,164,4,0,
322,17,174,1,29,
322,17,182,4,0,
322,17,184,2,0,
322,17,201,4,0,
322,17,203,2,0,
322,17,205,2,0,
322,17,207,4,0,
322,17,213,4,0,
322,17,214,4,0,
322,17,216,4,0,
322,17,218,4,0,
322,17,222,1,12,
322,17,237,4,0,
322,17,241,4,0,
322,17,246,2,0,
322,17,254,2,0,
322,17,255,2,0,
322,17,256,2,0,
322,17,257,2,0,
322,17,261,4,0,
322,17,263,4,0,
322,17,267,4,0,
322,17,281,1,36,
322,17,281,2,0,
322,17,315,4,0,
322,17,317,4,0,
322,17,336,2,0,
322,17,414,1,26,
322,17,426,2,0,
322,17,436,1,22,
322,17,442,2,0,
322,17,481,1,15,
322,17,488,4,0,
322,17,496,4,0,
322,17,497,4,0,
322,17,523,4,0,
322,17,590,4,0,
322,18,23,2,0,
322,18,33,1,1,2
322,18,34,2,0,
322,18,36,1,31,
322,18,38,1,47,
322,18,45,1,1,1
322,18,52,1,5,
322,18,53,1,43,
322,18,53,4,0,
322,18,74,2,0,
322,18,89,1,40,
322,18,89,4,0,
322,18,92,4,0,
322,18,104,4,0,
322,18,111,2,0,
322,18,116,1,8,
322,18,126,4,0,
322,18,133,1,19,
322,18,156,4,0,
322,18,157,4,0,
322,18,164,4,0,
322,18,174,1,29,
322,18,182,4,0,
322,18,184,2,0,
322,18,201,4,0,
322,18,203,2,0,
322,18,205,2,0,
322,18,207,4,0,
322,18,213,4,0,
322,18,214,4,0,
322,18,216,4,0,
322,18,218,4,0,
322,18,222,1,12,
322,18,237,4,0,
322,18,241,4,0,
322,18,246,2,0,
322,18,254,2,0,
322,18,255,2,0,
322,18,256,2,0,
322,18,257,2,0,
322,18,261,4,0,
322,18,263,4,0,
322,18,267,4,0,
322,18,281,1,36,
322,18,281,2,0,
322,18,315,4,0,
322,18,317,4,0,
322,18,336,2,0,
322,18,414,1,26,
322,18,426,2,0,
322,18,436,1,22,
322,18,442,2,0,
322,18,481,1,15,
322,18,484,2,0,
322,18,488,4,0,
322,18,496,4,0,
322,18,497,4,0,
322,18,523,4,0,
322,18,590,4,0,
323,5,33,1,1,2
323,5,36,1,29,
323,5,45,1,1,1
323,5,46,4,0,
323,5,52,1,1,3
323,5,52,1,11,
323,5,53,4,0,
323,5,63,4,0,
323,5,70,4,0,
323,5,89,1,37,
323,5,89,4,0,
323,5,90,1,55,
323,5,91,4,0,
323,5,92,4,0,
323,5,104,4,0,
323,5,116,1,25,
323,5,126,4,0,
323,5,133,1,31,
323,5,156,4,0,
323,5,157,1,33,
323,5,182,4,0,
323,5,201,4,0,
323,5,213,4,0,
323,5,216,4,0,
323,5,218,4,0,
323,5,222,1,1,4
323,5,222,1,19,
323,5,237,4,0,
323,5,241,4,0,
323,5,249,4,0,
323,5,263,4,0,
323,5,284,1,45,
323,5,290,4,0,
323,5,315,4,0,
323,5,317,4,0,
323,6,33,1,1,2
323,6,34,3,0,
323,6,36,1,29,
323,6,38,3,0,
323,6,45,1,1,1
323,6,46,4,0,
323,6,52,1,1,3
323,6,52,1,11,
323,6,53,4,0,
323,6,63,4,0,
323,6,70,4,0,
323,6,89,1,37,
323,6,89,4,0,
323,6,90,1,55,
323,6,91,4,0,
323,6,92,4,0,
323,6,102,3,0,
323,6,104,4,0,
323,6,111,3,0,
323,6,116,1,25,
323,6,126,4,0,
323,6,133,1,31,
323,6,153,3,0,
323,6,156,4,0,
323,6,157,1,33,
323,6,157,3,0,
323,6,164,3,0,
323,6,173,3,0,
323,6,182,4,0,
323,6,189,3,0,
323,6,201,4,0,
323,6,203,3,0,
323,6,205,3,0,
323,6,207,3,0,
323,6,213,4,0,
323,6,214,3,0,
323,6,216,4,0,
323,6,218,4,0,
323,6,222,1,1,4
323,6,222,1,19,
323,6,237,4,0,
323,6,241,4,0,
323,6,249,4,0,
323,6,263,4,0,
323,6,284,1,45,
323,6,290,4,0,
323,6,315,4,0,
323,6,317,4,0,
323,7,33,1,1,2
323,7,34,3,0,
323,7,36,1,29,
323,7,38,3,0,
323,7,45,1,1,1
323,7,46,4,0,
323,7,52,1,1,3
323,7,52,1,11,
323,7,53,4,0,
323,7,63,4,0,
323,7,70,4,0,
323,7,89,1,37,
323,7,89,4,0,
323,7,90,1,55,
323,7,91,4,0,
323,7,92,4,0,
323,7,102,3,0,
323,7,104,4,0,
323,7,116,1,25,
323,7,126,4,0,
323,7,133,1,31,
323,7,153,3,0,
323,7,156,4,0,
323,7,157,1,33,
323,7,157,3,0,
323,7,164,3,0,
323,7,182,4,0,
323,7,201,4,0,
323,7,213,4,0,
323,7,216,4,0,
323,7,218,4,0,
323,7,222,1,1,4
323,7,222,1,19,
323,7,237,4,0,
323,7,241,4,0,
323,7,249,4,0,
323,7,263,4,0,
323,7,284,1,45,
323,7,290,4,0,
323,7,315,4,0,
323,7,317,4,0,
323,8,33,1,1,2
323,8,36,1,21,
323,8,45,1,1,1
323,8,46,4,0,
323,8,52,1,1,3
323,8,52,1,5,
323,8,53,4,0,
323,8,63,4,0,
323,8,70,4,0,
323,8,76,4,0,
323,8,89,1,49,
323,8,89,4,0,
323,8,90,1,67,
323,8,91,4,0,
323,8,92,4,0,
323,8,104,4,0,
323,8,116,1,15,
323,8,126,4,0,
323,8,133,1,25,
323,8,153,4,0,
323,8,156,4,0,
323,8,157,1,33,
323,8,157,4,0,
323,8,164,4,0,
323,8,182,4,0,
323,8,201,4,0,
323,8,203,4,0,
323,8,207,4,0,
323,8,213,4,0,
323,8,214,4,0,
323,8,216,4,0,
323,8,218,4,0,
323,8,222,1,1,4
323,8,222,1,11,
323,8,237,4,0,
323,8,241,4,0,
323,8,249,4,0,
323,8,261,4,0,
323,8,263,4,0,
323,8,284,1,57,
323,8,290,4,0,
323,8,315,4,0,
323,8,317,4,0,
323,8,363,4,0,
323,8,397,4,0,
323,8,414,1,39,
323,8,416,4,0,
323,8,430,4,0,
323,8,436,1,31,
323,8,444,4,0,
323,8,445,4,0,
323,8,446,4,0,
323,9,33,1,1,2
323,9,36,1,21,
323,9,45,1,1,1
323,9,46,4,0,
323,9,52,1,1,3
323,9,52,1,5,
323,9,53,4,0,
323,9,63,4,0,
323,9,70,4,0,
323,9,76,4,0,
323,9,89,1,49,
323,9,89,4,0,
323,9,90,1,67,
323,9,91,4,0,
323,9,92,4,0,
323,9,104,4,0,
323,9,116,1,15,
323,9,126,4,0,
323,9,133,1,25,
323,9,153,4,0,
323,9,156,4,0,
323,9,157,1,33,
323,9,157,4,0,
323,9,164,4,0,
323,9,173,3,0,
323,9,182,4,0,
323,9,189,3,0,
323,9,201,4,0,
323,9,203,4,0,
323,9,205,3,0,
323,9,207,4,0,
323,9,213,4,0,
323,9,214,4,0,
323,9,216,4,0,
323,9,218,4,0,
323,9,222,1,1,4
323,9,222,1,11,
323,9,237,4,0,
323,9,241,4,0,
323,9,249,4,0,
323,9,257,3,0,
323,9,261,4,0,
323,9,263,4,0,
323,9,284,1,57,
323,9,290,4,0,
323,9,315,4,0,
323,9,317,4,0,
323,9,363,4,0,
323,9,397,4,0,
323,9,414,1,39,
323,9,414,3,0,
323,9,416,4,0,
323,9,430,4,0,
323,9,436,1,31,
323,9,442,3,0,
323,9,444,4,0,
323,9,445,4,0,
323,9,446,4,0,
323,10,29,3,0,
323,10,33,1,1,2
323,10,36,1,21,
323,10,45,1,1,1
323,10,46,4,0,
323,10,52,1,1,3
323,10,52,1,5,
323,10,53,4,0,
323,10,63,4,0,
323,10,70,4,0,
323,10,76,4,0,
323,10,89,1,49,
323,10,89,4,0,
323,10,90,1,67,
323,10,91,4,0,
323,10,92,4,0,
323,10,104,4,0,
323,10,116,1,15,
323,10,126,4,0,
323,10,133,1,25,
323,10,153,4,0,
323,10,156,4,0,
323,10,157,1,33,
323,10,157,4,0,
323,10,164,4,0,
323,10,173,3,0,
323,10,182,4,0,
323,10,189,3,0,
323,10,201,4,0,
323,10,203,4,0,
323,10,205,3,0,
323,10,207,4,0,
323,10,213,4,0,
323,10,214,4,0,
323,10,216,4,0,
323,10,218,4,0,
323,10,222,1,1,4
323,10,222,1,11,
323,10,237,4,0,
323,10,241,4,0,
323,10,249,4,0,
323,10,257,3,0,
323,10,261,4,0,
323,10,263,4,0,
323,10,284,1,57,
323,10,290,4,0,
323,10,315,4,0,
323,10,317,4,0,
323,10,363,4,0,
323,10,397,4,0,
323,10,414,1,39,
323,10,414,3,0,
323,10,416,4,0,
323,10,430,4,0,
323,10,436,1,31,
323,10,442,3,0,
323,10,444,4,0,
323,10,445,4,0,
323,10,446,4,0,
323,11,33,1,1,2
323,11,36,1,25,
323,11,45,1,1,1
323,11,46,4,0,
323,11,52,1,1,3
323,11,52,1,5,
323,11,53,4,0,
323,11,63,4,0,
323,11,70,4,0,
323,11,76,4,0,
323,11,89,1,57,
323,11,89,4,0,
323,11,90,1,75,
323,11,91,4,0,
323,11,92,4,0,
323,11,104,4,0,
323,11,116,1,15,
323,11,126,4,0,
323,11,133,1,31,
323,11,153,4,0,
323,11,156,4,0,
323,11,157,1,39,
323,11,157,4,0,
323,11,164,4,0,
323,11,182,4,0,
323,11,201,4,0,
323,11,207,4,0,
323,11,213,4,0,
323,11,216,4,0,
323,11,218,4,0,
323,11,222,1,1,4
323,11,222,1,11,
323,11,237,4,0,
323,11,241,4,0,
323,11,249,4,0,
323,11,261,4,0,
323,11,263,4,0,
323,11,284,1,67,
323,11,315,4,0,
323,11,317,4,0,
323,11,397,4,0,
323,11,414,1,49,
323,11,416,4,0,
323,11,430,4,0,
323,11,436,1,33,
323,11,444,4,0,
323,11,481,1,21,
323,11,488,4,0,
323,11,496,4,0,
323,11,497,4,0,
323,11,510,4,0,
323,11,523,4,0,
323,12,33,1,1,2
323,12,36,1,29,
323,12,45,1,1,1
323,12,46,4,0,
323,12,52,1,1,3
323,12,52,1,11,
323,12,53,4,0,
323,12,63,4,0,
323,12,70,4,0,
323,12,89,1,37,
323,12,89,4,0,
323,12,90,1,55,
323,12,91,4,0,
323,12,92,4,0,
323,12,104,4,0,
323,12,116,1,25,
323,12,126,4,0,
323,12,133,1,31,
323,12,156,4,0,
323,12,157,1,33,
323,12,182,4,0,
323,12,201,4,0,
323,12,213,4,0,
323,12,216,4,0,
323,12,218,4,0,
323,12,222,1,1,4
323,12,222,1,19,
323,12,237,4,0,
323,12,241,4,0,
323,12,249,4,0,
323,12,263,4,0,
323,12,284,1,45,
323,12,290,4,0,
323,12,315,4,0,
323,12,317,4,0,
323,13,33,1,1,2
323,13,34,3,0,
323,13,36,1,29,
323,13,38,3,0,
323,13,45,1,1,1
323,13,46,4,0,
323,13,52,1,1,3
323,13,52,1,11,
323,13,53,4,0,
323,13,63,4,0,
323,13,70,4,0,
323,13,89,1,37,
323,13,89,4,0,
323,13,90,1,55,
323,13,91,4,0,
323,13,92,4,0,
323,13,102,3,0,
323,13,104,4,0,
323,13,116,1,25,
323,13,120,3,0,
323,13,126,4,0,
323,13,133,1,31,
323,13,156,4,0,
323,13,157,1,33,
323,13,164,3,0,
323,13,182,4,0,
323,13,201,4,0,
323,13,207,3,0,
323,13,213,4,0,
323,13,216,4,0,
323,13,218,4,0,
323,13,222,1,1,4
323,13,222,1,19,
323,13,237,4,0,
323,13,241,4,0,
323,13,249,4,0,
323,13,263,4,0,
323,13,284,1,45,
323,13,290,4,0,
323,13,315,4,0,
323,13,317,4,0,
323,14,33,1,1,2
323,14,36,1,31,
323,14,45,1,1,1
323,14,46,4,0,
323,14,52,1,1,3
323,14,52,1,5,
323,14,53,4,0,
323,14,63,4,0,
323,14,70,4,0,
323,14,76,4,0,
323,14,89,1,46,
323,14,89,4,0,
323,14,90,1,59,
323,14,91,4,0,
323,14,92,4,0,
323,14,104,4,0,
323,14,116,1,12,
323,14,126,4,0,
323,14,133,1,19,
323,14,153,4,0,
323,14,156,4,0,
323,14,157,1,33,
323,14,157,4,0,
323,14,164,4,0,
323,14,173,3,0,
323,14,174,1,29,
323,14,182,4,0,
323,14,201,4,0,
323,14,207,4,0,
323,14,213,4,0,
323,14,214,3,0,
323,14,216,4,0,
323,14,218,4,0,
323,14,222,1,1,4
323,14,222,1,8,
323,14,237,4,0,
323,14,241,4,0,
323,14,249,4,0,
323,14,257,3,0,
323,14,261,4,0,
323,14,263,4,0,
323,14,281,1,39,
323,14,284,1,52,
323,14,315,4,0,
323,14,317,4,0,
323,14,397,4,0,
323,14,414,1,26,
323,14,414,3,0,
323,14,416,4,0,
323,14,430,4,0,
323,14,436,1,22,
323,14,442,3,0,
323,14,444,4,0,
323,14,446,3,0,
323,14,481,1,15,
323,14,488,4,0,
323,14,495,3,0,
323,14,496,4,0,
323,14,497,4,0,
323,14,510,4,0,
323,14,523,4,0,
323,15,33,1,1,4
323,15,36,1,31,
323,15,45,1,1,3
323,15,46,4,0,
323,15,52,1,1,5
323,15,52,1,5,
323,15,53,4,0,
323,15,63,4,0,
323,15,70,4,0,
323,15,76,4,0,
323,15,89,1,46,
323,15,89,4,0,
323,15,90,1,1,1
323,15,90,1,59,
323,15,91,4,0,
323,15,92,4,0,
323,15,104,4,0,
323,15,116,1,12,
323,15,126,4,0,
323,15,133,1,19,
323,15,153,4,0,
323,15,156,4,0,
323,15,157,1,33,
323,15,157,4,0,
323,15,164,4,0,
323,15,174,1,29,
323,15,182,4,0,
323,15,201,4,0,
323,15,207,4,0,
323,15,213,4,0,
323,15,214,4,0,
323,15,216,4,0,
323,15,218,4,0,
323,15,222,1,1,6
323,15,222,1,8,
323,15,237,4,0,
323,15,241,4,0,
323,15,249,4,0,
323,15,261,4,0,
323,15,263,4,0,
323,15,267,4,0,
323,15,281,1,39,
323,15,284,1,1,2
323,15,284,1,52,
323,15,315,4,0,
323,15,317,4,0,
323,15,397,4,0,
323,15,414,1,26,
323,15,416,4,0,
323,15,430,4,0,
323,15,436,1,22,
323,15,444,4,0,
323,15,481,1,15,
323,15,488,4,0,
323,15,496,4,0,
323,15,497,4,0,
323,15,510,4,0,
323,15,523,4,0,
323,15,590,4,0,
323,16,33,1,1,4
323,16,36,1,31,1
323,16,45,1,1,3
323,16,46,4,0,
323,16,52,1,1,5
323,16,52,1,5,1
323,16,53,4,0,
323,16,63,4,0,
323,16,70,4,0,
323,16,76,4,0,
323,16,89,1,46,1
323,16,89,4,0,
323,16,90,1,1,1
323,16,90,1,59,1
323,16,91,4,0,
323,16,92,4,0,
323,16,104,4,0,
323,16,116,1,1,6
323,16,116,1,8,1
323,16,126,4,0,
323,16,133,1,19,1
323,16,153,4,0,
323,16,156,4,0,
323,16,157,1,33,1
323,16,157,4,0,
323,16,164,4,0,
323,16,173,3,0,
323,16,174,1,29,1
323,16,182,4,0,
323,16,201,4,0,
323,16,207,4,0,
323,16,213,4,0,
323,16,214,4,0,
323,16,216,4,0,
323,16,218,4,0,
323,16,222,1,12,1
323,16,237,4,0,
323,16,241,4,0,
323,16,249,4,0,
323,16,257,3,0,
323,16,261,4,0,
323,16,263,4,0,
323,16,267,4,0,
323,16,281,1,39,1
323,16,284,1,1,2
323,16,284,1,52,1
323,16,290,4,0,
323,16,315,4,0,
323,16,317,4,0,
323,16,397,4,0,
323,16,414,1,26,1
323,16,414,3,0,
323,16,416,4,0,
323,16,430,4,0,
323,16,436,1,22,1
323,16,442,3,0,
323,16,444,4,0,
323,16,446,3,0,
323,16,481,1,15,1
323,16,488,4,0,
323,16,495,3,0,
323,16,496,4,0,
323,16,497,4,0,
323,16,510,4,0,
323,16,523,4,0,
323,16,590,4,0,
323,17,33,1,1,5
323,17,36,1,31,
323,17,45,1,1,4
323,17,46,4,0,
323,17,52,1,1,6
323,17,52,1,8,1
323,17,53,4,0,
323,17,63,4,0,
323,17,76,4,0,
323,17,89,1,46,
323,17,89,4,0,
323,17,90,1,1,2
323,17,90,1,59,
323,17,92,4,0,
323,17,104,4,0,
323,17,116,1,1,7
323,17,116,1,8,2
323,17,126,4,0,
323,17,133,1,19,
323,17,153,4,0,
323,17,156,4,0,
323,17,157,1,0,
323,17,157,1,1,1
323,17,157,4,0,
323,17,164,4,0,
323,17,174,1,29,
323,17,182,4,0,
323,17,201,4,0,
323,17,207,4,0,
323,17,213,4,0,
323,17,214,4,0,
323,17,216,4,0,
323,17,218,4,0,
323,17,222,1,12,
323,17,237,4,0,
323,17,241,4,0,
323,17,261,4,0,
323,17,263,4,0,
323,17,267,4,0,
323,17,281,1,39,
323,17,284,1,1,3
323,17,284,1,52,
323,17,315,4,0,
323,17,317,4,0,
323,17,397,4,0,
323,17,414,1,26,
323,17,416,4,0,
323,17,430,4,0,
323,17,436,1,22,
323,17,444,4,0,
323,17,481,1,15,
323,17,488,4,0,
323,17,496,4,0,
323,17,497,4,0,
323,17,523,4,0,
323,17,590,4,0,
323,18,33,1,1,5
323,18,36,1,31,
323,18,45,1,1,4
323,18,46,4,0,
323,18,52,1,1,6
323,18,52,1,8,1
323,18,53,4,0,
323,18,63,4,0,
323,18,76,4,0,
323,18,89,1,46,
323,18,89,4,0,
323,18,90,1,1,2
323,18,90,1,59,
323,18,92,4,0,
323,18,104,4,0,
323,18,116,1,1,7
323,18,116,1,8,2
323,18,126,4,0,
323,18,133,1,19,
323,18,153,4,0,
323,18,156,4,0,
323,18,157,1,0,
323,18,157,1,1,1
323,18,157,4,0,
323,18,164,4,0,
323,18,174,1,29,
323,18,182,4,0,
323,18,201,4,0,
323,18,207,4,0,
323,18,213,4,0,
323,18,214,4,0,
323,18,216,4,0,
323,18,218,4,0,
323,18,222,1,12,
323,18,237,4,0,
323,18,241,4,0,
323,18,261,4,0,
323,18,263,4,0,
323,18,267,4,0,
323,18,281,1,39,
323,18,284,1,1,3
323,18,284,1,52,
323,18,315,4,0,
323,18,317,4,0,
323,18,397,4,0,
323,18,414,1,26,
323,18,416,4,0,
323,18,430,4,0,
323,18,436,1,22,
323,18,444,4,0,
323,18,481,1,15,
323,18,488,4,0,
323,18,496,4,0,
323,18,497,4,0,
323,18,523,4,0,
323,18,590,4,0,
324,5,34,1,20,
324,5,52,1,1,
324,5,53,1,30,
324,5,53,4,0,
324,5,70,4,0,
324,5,83,1,17,
324,5,92,4,0,
324,5,104,4,0,
324,5,108,1,14,
324,5,123,1,4,
324,5,126,4,0,
324,5,133,1,40,
324,5,156,4,0,
324,5,174,1,7,
324,5,175,1,43,
324,5,182,1,27,
324,5,182,4,0,
324,5,188,4,0,
324,5,203,2,0,
324,5,213,4,0,
324,5,214,2,0,
324,5,216,4,0,
324,5,218,4,0,
324,5,231,4,0,
324,5,237,4,0,
324,5,241,4,0,
324,5,249,4,0,
324,5,257,1,46,
324,5,263,4,0,
324,5,281,2,0,
324,5,284,2,0,
324,5,290,4,0,
324,5,315,4,0,
324,5,334,1,33,
324,6,34,1,20,
324,6,34,3,0,
324,6,38,3,0,
324,6,52,1,1,
324,6,53,1,30,
324,6,53,4,0,
324,6,70,4,0,
324,6,83,1,17,
324,6,92,4,0,
324,6,102,3,0,
324,6,104,4,0,
324,6,108,1,14,
324,6,123,1,4,
324,6,126,4,0,
324,6,133,1,40,
324,6,153,3,0,
324,6,156,4,0,
324,6,157,3,0,
324,6,164,3,0,
324,6,173,3,0,
324,6,174,1,7,
324,6,175,1,43,
324,6,182,1,27,
324,6,182,4,0,
324,6,188,4,0,
324,6,189,3,0,
324,6,203,2,0,
324,6,203,3,0,
324,6,207,3,0,
324,6,213,4,0,
324,6,214,2,0,
324,6,214,3,0,
324,6,216,4,0,
324,6,218,4,0,
324,6,231,4,0,
324,6,237,4,0,
324,6,241,4,0,
324,6,249,4,0,
324,6,257,1,46,
324,6,263,4,0,
324,6,281,2,0,
324,6,284,2,0,
324,6,290,4,0,
324,6,315,4,0,
324,6,334,1,33,
324,7,34,1,20,
324,7,34,3,0,
324,7,38,3,0,
324,7,52,1,1,
324,7,53,1,30,
324,7,53,4,0,
324,7,70,4,0,
324,7,83,1,17,
324,7,92,4,0,
324,7,102,3,0,
324,7,104,4,0,
324,7,108,1,14,
324,7,123,1,4,
324,7,126,4,0,
324,7,133,1,40,
324,7,153,3,0,
324,7,156,4,0,
324,7,157,3,0,
324,7,164,3,0,
324,7,174,1,7,
324,7,175,1,43,
324,7,182,1,27,
324,7,182,4,0,
324,7,188,4,0,
324,7,203,2,0,
324,7,213,4,0,
324,7,214,2,0,
324,7,216,4,0,
324,7,218,4,0,
324,7,231,4,0,
324,7,237,4,0,
324,7,241,4,0,
324,7,249,4,0,
324,7,257,1,46,
324,7,263,4,0,
324,7,281,2,0,
324,7,284,2,0,
324,7,290,4,0,
324,7,315,4,0,
324,7,334,1,33,
324,8,34,1,33,
324,8,52,1,1,
324,8,53,1,28,
324,8,53,4,0,
324,8,63,4,0,
324,8,70,4,0,
324,8,76,4,0,
324,8,83,1,17,
324,8,89,2,0,
324,8,89,4,0,
324,8,90,2,0,
324,8,92,4,0,
324,8,104,4,0,
324,8,108,1,20,
324,8,110,1,7,
324,8,123,1,4,
324,8,126,4,0,
324,8,133,1,49,
324,8,153,4,0,
324,8,156,4,0,
324,8,157,4,0,
324,8,164,4,0,
324,8,174,1,12,
324,8,175,1,52,
324,8,182,1,36,
324,8,182,4,0,
324,8,188,4,0,
324,8,203,2,0,
324,8,203,4,0,
324,8,207,4,0,
324,8,213,4,0,
324,8,214,2,0,
324,8,214,4,0,
324,8,216,4,0,
324,8,218,4,0,
324,8,229,1,23,
324,8,231,4,0,
324,8,237,4,0,
324,8,241,4,0,
324,8,249,4,0,
324,8,257,1,55,
324,8,261,4,0,
324,8,263,4,0,
324,8,281,2,0,
324,8,284,2,0,
324,8,290,4,0,
324,8,315,4,0,
324,8,317,4,0,
324,8,334,1,44,
324,8,360,4,0,
324,8,363,4,0,
324,8,416,4,0,
324,8,436,1,39,
324,8,444,4,0,
324,8,445,4,0,
324,8,446,4,0,
324,9,34,1,33,
324,9,52,1,1,
324,9,53,1,28,
324,9,53,4,0,
324,9,63,4,0,
324,9,70,4,0,
324,9,76,4,0,
324,9,83,1,17,
324,9,89,2,0,
324,9,89,4,0,
324,9,90,2,0,
324,9,92,4,0,
324,9,104,4,0,
324,9,108,1,20,
324,9,110,1,7,
324,9,123,1,4,
324,9,126,4,0,
324,9,133,1,49,
324,9,153,4,0,
324,9,156,4,0,
324,9,157,4,0,
324,9,164,4,0,
324,9,173,3,0,
324,9,174,1,12,
324,9,175,1,52,
324,9,182,1,36,
324,9,182,4,0,
324,9,188,4,0,
324,9,189,3,0,
324,9,203,2,0,
324,9,203,4,0,
324,9,205,3,0,
324,9,207,4,0,
324,9,213,4,0,
324,9,214,2,0,
324,9,214,4,0,
324,9,216,4,0,
324,9,218,4,0,
324,9,229,1,23,
324,9,231,4,0,
324,9,237,4,0,
324,9,241,4,0,
324,9,249,4,0,
324,9,257,1,55,
324,9,257,3,0,
324,9,261,4,0,
324,9,263,4,0,
324,9,281,2,0,
324,9,284,2,0,
324,9,290,4,0,
324,9,315,4,0,
324,9,317,4,0,
324,9,334,1,44,
324,9,334,3,0,
324,9,360,4,0,
324,9,363,4,0,
324,9,414,3,0,
324,9,416,4,0,
324,9,436,1,39,
324,9,444,4,0,
324,9,445,4,0,
324,9,446,4,0,
324,10,29,3,0,
324,10,34,1,33,
324,10,52,1,1,
324,10,53,1,28,
324,10,53,4,0,
324,10,63,4,0,
324,10,70,4,0,
324,10,76,4,0,
324,10,83,1,17,
324,10,89,2,0,
324,10,89,4,0,
324,10,90,2,0,
324,10,92,4,0,
324,10,104,4,0,
324,10,108,1,20,
324,10,110,1,7,
324,10,123,1,4,
324,10,126,4,0,
324,10,130,2,0,
324,10,133,1,49,
324,10,153,4,0,
324,10,156,4,0,
324,10,157,4,0,
324,10,164,4,0,
324,10,173,3,0,
324,10,174,1,12,
324,10,175,1,52,
324,10,182,1,36,
324,10,182,4,0,
324,10,188,4,0,
324,10,189,3,0,
324,10,203,2,0,
324,10,203,4,0,
324,10,205,3,0,
324,10,207,4,0,
324,10,213,4,0,
324,10,214,2,0,
324,10,214,4,0,
324,10,216,4,0,
324,10,218,4,0,
324,10,229,1,23,
324,10,231,4,0,
324,10,237,4,0,
324,10,241,4,0,
324,10,249,4,0,
324,10,257,1,55,
324,10,257,3,0,
324,10,261,4,0,
324,10,263,4,0,
324,10,281,2,0,
324,10,284,2,0,
324,10,290,4,0,
324,10,315,4,0,
324,10,317,4,0,
324,10,334,1,44,
324,10,334,3,0,
324,10,360,4,0,
324,10,363,4,0,
324,10,414,3,0,
324,10,416,4,0,
324,10,436,1,39,
324,10,444,4,0,
324,10,445,4,0,
324,10,446,4,0,
324,11,34,1,33,
324,11,52,1,1,
324,11,53,1,28,
324,11,53,4,0,
324,11,63,4,0,
324,11,70,4,0,
324,11,76,4,0,
324,11,83,1,17,
324,11,89,4,0,
324,11,90,2,0,
324,11,92,4,0,
324,11,104,4,0,
324,11,108,1,20,
324,11,110,1,7,
324,11,123,1,4,
324,11,126,4,0,
324,11,130,2,0,
324,11,133,1,49,
324,11,153,4,0,
324,11,156,4,0,
324,11,157,4,0,
324,11,164,4,0,
324,11,174,1,12,
324,11,175,1,52,
324,11,182,1,36,
324,11,182,4,0,
324,11,188,4,0,
324,11,203,2,0,
324,11,207,4,0,
324,11,213,4,0,
324,11,214,2,0,
324,11,216,4,0,
324,11,218,4,0,
324,11,229,1,23,
324,11,237,4,0,
324,11,241,4,0,
324,11,249,4,0,
324,11,257,1,55,
324,11,261,4,0,
324,11,263,4,0,
324,11,281,2,0,
324,11,284,2,0,
324,11,315,4,0,
324,11,317,4,0,
324,11,334,1,44,
324,11,360,4,0,
324,11,416,4,0,
324,11,436,1,39,
324,11,444,4,0,
324,11,481,2,0,
324,11,488,4,0,
324,11,496,4,0,
324,11,499,2,0,
324,11,504,1,65,
324,11,510,4,0,
324,11,517,1,60,
324,11,523,4,0,
324,12,34,1,20,
324,12,52,1,1,
324,12,53,1,30,
324,12,53,4,0,
324,12,70,4,0,
324,12,83,1,17,
324,12,92,4,0,
324,12,104,4,0,
324,12,108,1,14,
324,12,123,1,4,
324,12,126,4,0,
324,12,133,1,40,
324,12,156,4,0,
324,12,174,1,7,
324,12,175,1,43,
324,12,182,1,27,
324,12,182,4,0,
324,12,188,4,0,
324,12,213,4,0,
324,12,216,4,0,
324,12,218,4,0,
324,12,231,4,0,
324,12,237,4,0,
324,12,241,4,0,
324,12,249,4,0,
324,12,257,1,46,
324,12,263,4,0,
324,12,290,4,0,
324,12,315,4,0,
324,12,334,1,33,
324,13,34,1,20,
324,13,34,3,0,
324,13,38,3,0,
324,13,52,1,1,
324,13,53,1,30,
324,13,53,4,0,
324,13,70,4,0,
324,13,83,1,17,
324,13,92,4,0,
324,13,102,3,0,
324,13,104,4,0,
324,13,108,1,14,
324,13,120,3,0,
324,13,123,1,4,
324,13,126,4,0,
324,13,133,1,40,
324,13,156,4,0,
324,13,164,3,0,
324,13,174,1,7,
324,13,175,1,43,
324,13,182,1,27,
324,13,182,4,0,
324,13,188,4,0,
324,13,207,3,0,
324,13,213,4,0,
324,13,216,4,0,
324,13,218,4,0,
324,13,231,4,0,
324,13,237,4,0,
324,13,241,4,0,
324,13,249,4,0,
324,13,257,1,46,
324,13,263,4,0,
324,13,290,4,0,
324,13,315,4,0,
324,13,334,1,33,
324,14,34,1,33,
324,14,52,1,1,
324,14,53,1,28,
324,14,53,4,0,
324,14,63,4,0,
324,14,70,4,0,
324,14,76,4,0,
324,14,83,1,17,
324,14,89,4,0,
324,14,90,2,0,
324,14,92,4,0,
324,14,104,4,0,
324,14,108,1,20,
324,14,110,1,7,
324,14,123,1,4,
324,14,126,4,0,
324,14,130,2,0,
324,14,133,1,49,
324,14,153,4,0,
324,14,156,4,0,
324,14,157,4,0,
324,14,164,4,0,
324,14,173,3,0,
324,14,174,1,12,
324,14,175,1,52,
324,14,182,1,36,
324,14,182,4,0,
324,14,188,4,0,
324,14,203,2,0,
324,14,207,4,0,
324,14,213,4,0,
324,14,214,2,0,
324,14,214,3,0,
324,14,216,4,0,
324,14,218,4,0,
324,14,229,1,23,
324,14,231,3,0,
324,14,237,4,0,
324,14,241,4,0,
324,14,249,4,0,
324,14,257,1,55,
324,14,257,3,0,
324,14,261,4,0,
324,14,263,4,0,
324,14,281,2,0,
324,14,284,2,0,
324,14,315,4,0,
324,14,317,4,0,
324,14,334,1,44,
324,14,334,3,0,
324,14,360,4,0,
324,14,414,3,0,
324,14,416,4,0,
324,14,436,1,39,
324,14,444,4,0,
324,14,446,3,0,
324,14,481,2,0,
324,14,488,4,0,
324,14,495,3,0,
324,14,496,4,0,
324,14,499,2,0,
324,14,504,1,65,
324,14,510,4,0,
324,14,517,1,60,
324,14,523,4,0,
324,15,34,1,33,
324,15,52,1,1,6
324,15,53,1,28,
324,15,53,4,0,
324,15,63,4,0,
324,15,70,4,0,
324,15,76,4,0,
324,15,83,1,17,
324,15,89,4,0,
324,15,90,2,0,
324,15,92,4,0,
324,15,104,4,0,
324,15,108,1,20,
324,15,110,1,7,
324,15,123,1,4,
324,15,126,4,0,
324,15,130,2,0,
324,15,133,1,49,
324,15,153,4,0,
324,15,156,4,0,
324,15,157,4,0,
324,15,164,4,0,
324,15,172,1,23,
324,15,174,1,12,
324,15,175,1,1,4
324,15,175,1,52,
324,15,182,1,1,3
324,15,182,1,36,
324,15,182,4,0,
324,15,188,4,0,
324,15,203,2,0,
324,15,207,4,0,
324,15,213,4,0,
324,15,214,2,0,
324,15,214,4,0,
324,15,216,4,0,
324,15,218,4,0,
324,15,229,1,25,
324,15,237,4,0,
324,15,241,4,0,
324,15,249,4,0,
324,15,257,1,1,2
324,15,257,1,55,
324,15,261,4,0,
324,15,263,4,0,
324,15,267,4,0,
324,15,276,2,0,
324,15,281,2,0,
324,15,284,2,0,
324,15,315,4,0,
324,15,317,4,0,
324,15,334,1,44,
324,15,360,4,0,
324,15,416,4,0,
324,15,436,1,39,
324,15,444,4,0,
324,15,481,2,0,
324,15,488,4,0,
324,15,496,4,0,
324,15,499,2,0,
324,15,504,1,1,5
324,15,504,1,65,
324,15,510,4,0,
324,15,517,1,1,1
324,15,517,1,60,
324,15,523,4,0,
324,15,590,4,0,
324,16,34,1,27,1
324,16,52,1,1,1
324,16,53,1,34,1
324,16,53,4,0,
324,16,63,4,0,
324,16,70,4,0,
324,16,76,4,0,
324,16,83,1,13,1
324,16,89,4,0,
324,16,90,2,0,
324,16,92,4,0,
324,16,104,4,0,
324,16,108,1,15,1
324,16,110,1,7,1
324,16,123,1,4,1
324,16,126,4,0,
324,16,130,2,0,
324,16,133,1,40,1
324,16,153,4,0,
324,16,156,4,0,
324,16,157,4,0,
324,16,164,4,0,
324,16,172,1,18,1
324,16,173,3,0,
324,16,174,1,22,1
324,16,175,1,42,1
324,16,182,1,30,1
324,16,182,4,0,
324,16,188,4,0,
324,16,203,2,0,
324,16,207,4,0,
324,16,213,4,0,
324,16,214,2,0,
324,16,214,4,0,
324,16,216,4,0,
324,16,218,4,0,
324,16,229,1,10,1
324,16,231,3,0,
324,16,237,4,0,
324,16,241,4,0,
324,16,249,4,0,
324,16,257,1,45,1
324,16,257,3,0,
324,16,261,4,0,
324,16,263,4,0,
324,16,267,4,0,
324,16,276,2,0,
324,16,276,3,0,
324,16,281,2,0,
324,16,284,2,0,
324,16,290,4,0,
324,16,315,4,0,
324,16,317,4,0,
324,16,334,1,38,1
324,16,334,3,0,
324,16,360,4,0,
324,16,414,3,0,
324,16,416,4,0,
324,16,436,1,25,1
324,16,444,4,0,
324,16,446,3,0,
324,16,481,2,0,
324,16,488,4,0,
324,16,495,3,0,
324,16,496,4,0,
324,16,499,2,0,
324,16,504,1,47,1
324,16,510,4,0,
324,16,517,1,50,1
324,16,523,4,0,
324,16,590,4,0,
324,17,34,1,27,
324,17,52,1,1,
324,17,53,1,34,
324,17,53,4,0,
324,17,63,4,0,
324,17,76,4,0,
324,17,83,1,13,
324,17,89,4,0,
324,17,90,2,0,
324,17,92,4,0,
324,17,104,4,0,
324,17,108,1,15,
324,17,110,1,7,
324,17,123,1,4,
324,17,126,4,0,
324,17,130,2,0,
324,17,133,1,40,
324,17,153,4,0,
324,17,156,4,0,
324,17,157,4,0,
324,17,164,4,0,
324,17,172,1,18,
324,17,174,1,22,
324,17,175,1,42,
324,17,182,1,30,
324,17,182,4,0,
324,17,188,4,0,
324,17,203,2,0,
324,17,207,4,0,
324,17,213,4,0,
324,17,214,2,0,
324,17,214,4,0,
324,17,216,4,0,
324,17,218,4,0,
324,17,229,1,10,
324,17,237,4,0,
324,17,241,4,0,
324,17,257,1,45,
324,17,261,4,0,
324,17,263,4,0,
324,17,267,4,0,
324,17,276,2,0,
324,17,281,2,0,
324,17,284,2,0,
324,17,315,4,0,
324,17,317,4,0,
324,17,334,1,38,
324,17,360,4,0,
324,17,416,4,0,
324,17,436,1,25,
324,17,444,4,0,
324,17,481,2,0,
324,17,488,4,0,
324,17,496,4,0,
324,17,499,2,0,
324,17,504,1,47,
324,17,517,1,50,
324,17,523,4,0,
324,17,590,4,0,
324,18,34,1,27,
324,18,52,1,1,
324,18,53,1,34,
324,18,53,4,0,
324,18,63,4,0,
324,18,76,4,0,
324,18,83,1,13,
324,18,89,4,0,
324,18,90,2,0,
324,18,92,4,0,
324,18,104,4,0,
324,18,108,1,15,
324,18,110,1,7,
324,18,123,1,4,
324,18,126,4,0,
324,18,130,2,0,
324,18,133,1,40,
324,18,153,4,0,
324,18,156,4,0,
324,18,157,4,0,
324,18,164,4,0,
324,18,172,1,18,
324,18,174,1,22,
324,18,175,1,42,
324,18,182,1,30,
324,18,182,4,0,
324,18,188,4,0,
324,18,203,2,0,
324,18,207,4,0,
324,18,213,4,0,
324,18,214,2,0,
324,18,214,4,0,
324,18,216,4,0,
324,18,218,4,0,
324,18,229,1,10,
324,18,237,4,0,
324,18,241,4,0,
324,18,257,1,45,
324,18,261,4,0,
324,18,263,4,0,
324,18,267,4,0,
324,18,276,2,0,
324,18,281,2,0,
324,18,284,2,0,
324,18,315,4,0,
324,18,317,4,0,
324,18,334,1,38,
324,18,360,4,0,
324,18,416,4,0,
324,18,436,1,25,
324,18,444,4,0,
324,18,481,2,0,
324,18,488,4,0,
324,18,496,4,0,
324,18,499,2,0,
324,18,504,1,47,
324,18,517,1,50,
324,18,523,4,0,
324,18,590,4,0,
325,5,60,1,16,
325,5,92,4,0,
325,5,94,1,34,
325,5,94,4,0,
325,5,104,4,0,
325,5,109,1,25,
325,5,113,4,0,
325,5,115,4,0,
325,5,148,4,0,
325,5,149,1,7,
325,5,150,1,1,
325,5,156,1,37,1
325,5,156,4,0,
325,5,164,2,0,
325,5,168,4,0,
325,5,173,1,37,2
325,5,182,4,0,
325,5,213,4,0,
325,5,216,4,0,
325,5,218,4,0,
325,5,231,4,0,
325,5,237,4,0,
325,5,240,4,0,
325,5,241,4,0,
325,5,244,1,19,
325,5,247,4,0,
325,5,248,2,0,
325,5,259,4,0,
325,5,263,4,0,
325,5,269,4,0,
325,5,271,2,0,
325,5,277,1,28,
325,5,285,4,0,
325,5,289,4,0,
325,5,290,4,0,
325,5,316,1,10,
325,5,326,2,0,
325,5,340,1,43,
325,5,347,4,0,
325,5,351,4,0,
325,6,34,3,0,
325,6,38,3,0,
325,6,60,1,16,
325,6,92,4,0,
325,6,94,1,34,
325,6,94,4,0,
325,6,102,3,0,
325,6,104,4,0,
325,6,109,1,25,
325,6,113,4,0,
325,6,115,4,0,
325,6,129,3,0,
325,6,138,3,0,
325,6,148,4,0,
325,6,149,1,7,
325,6,150,1,1,
325,6,156,1,37,1
325,6,156,4,0,
325,6,164,2,0,
325,6,164,3,0,
325,6,168,4,0,
325,6,173,1,37,2
325,6,173,3,0,
325,6,182,4,0,
325,6,196,3,0,
325,6,203,3,0,
325,6,207,3,0,
325,6,213,4,0,
325,6,214,3,0,
325,6,216,4,0,
325,6,218,4,0,
325,6,231,4,0,
325,6,237,4,0,
325,6,240,4,0,
325,6,241,4,0,
325,6,244,1,19,
325,6,244,3,0,
325,6,247,4,0,
325,6,248,2,0,
325,6,259,4,0,
325,6,263,4,0,
325,6,269,4,0,
325,6,271,2,0,
325,6,277,1,28,
325,6,285,4,0,
325,6,289,4,0,
325,6,290,4,0,
325,6,316,1,10,
325,6,326,2,0,
325,6,340,1,43,
325,6,347,4,0,
325,6,351,4,0,
325,7,34,3,0,
325,7,38,3,0,
325,7,60,1,16,
325,7,92,4,0,
325,7,94,1,34,
325,7,94,4,0,
325,7,102,3,0,
325,7,104,4,0,
325,7,109,1,25,
325,7,113,4,0,
325,7,115,4,0,
325,7,138,3,0,
325,7,148,4,0,
325,7,149,1,7,
325,7,150,1,1,
325,7,156,1,37,1
325,7,156,4,0,
325,7,164,2,0,
325,7,164,3,0,
325,7,168,4,0,
325,7,173,1,37,2
325,7,182,4,0,
325,7,213,4,0,
325,7,216,4,0,
325,7,218,4,0,
325,7,231,4,0,
325,7,237,4,0,
325,7,240,4,0,
325,7,241,4,0,
325,7,244,1,19,
325,7,247,4,0,
325,7,248,2,0,
325,7,259,4,0,
325,7,263,4,0,
325,7,269,4,0,
325,7,271,2,0,
325,7,277,1,28,
325,7,285,4,0,
325,7,289,4,0,
325,7,290,4,0,
325,7,316,1,10,
325,7,326,2,0,
325,7,340,1,43,
325,7,347,4,0,
325,7,351,4,0,
325,8,60,1,14,
325,8,86,4,0,
325,8,92,4,0,
325,8,94,1,41,
325,8,94,4,0,
325,8,104,4,0,
325,8,109,1,18,
325,8,113,4,0,
325,8,115,4,0,
325,8,133,2,0,
325,8,138,4,0,
325,8,148,4,0,
325,8,149,1,7,
325,8,150,1,1,
325,8,156,1,29,1
325,8,156,4,0,
325,8,164,2,0,
325,8,164,4,0,
325,8,168,4,0,
325,8,173,1,29,2
325,8,182,4,0,
325,8,203,4,0,
325,8,207,4,0,
325,8,213,4,0,
325,8,214,4,0,
325,8,216,4,0,
325,8,218,4,0,
325,8,231,4,0,
325,8,237,4,0,
325,8,240,4,0,
325,8,241,4,0,
325,8,243,2,0,
325,8,244,1,15,
325,8,244,4,0,
325,8,247,4,0,
325,8,248,2,0,
325,8,259,4,0,
325,8,263,4,0,
325,8,269,4,0,
325,8,271,2,0,
325,8,277,1,21,
325,8,278,4,0,
325,8,285,4,0,
325,8,289,4,0,
325,8,290,4,0,
325,8,316,1,10,
325,8,326,2,0,
325,8,340,1,48,
325,8,347,4,0,
325,8,351,4,0,
325,8,363,4,0,
325,8,371,1,34,
325,8,371,4,0,
325,8,408,1,46,
325,8,428,1,26,
325,8,428,2,0,
325,8,433,4,0,
325,8,445,4,0,
325,8,447,4,0,
325,8,451,4,0,
325,9,60,1,14,
325,9,86,4,0,
325,9,92,4,0,
325,9,94,1,41,
325,9,94,4,0,
325,9,104,4,0,
325,9,109,1,18,
325,9,113,4,0,
325,9,115,4,0,
325,9,129,3,0,
325,9,133,2,0,
325,9,138,4,0,
325,9,148,4,0,
325,9,149,1,7,
325,9,150,1,1,
325,9,156,1,29,1
325,9,156,4,0,
325,9,164,2,0,
325,9,164,4,0,
325,9,168,4,0,
325,9,173,1,29,2
325,9,173,3,0,
325,9,182,4,0,
325,9,196,3,0,
325,9,203,4,0,
325,9,207,4,0,
325,9,213,4,0,
325,9,214,4,0,
325,9,216,4,0,
325,9,218,4,0,
325,9,231,4,0,
325,9,237,4,0,
325,9,240,4,0,
325,9,241,4,0,
325,9,243,2,0,
325,9,244,1,15,
325,9,244,4,0,
325,9,247,4,0,
325,9,248,2,0,
325,9,259,4,0,
325,9,263,4,0,
325,9,269,4,0,
325,9,271,2,0,
325,9,271,3,0,
325,9,277,1,21,
325,9,278,4,0,
325,9,285,4,0,
325,9,289,4,0,
325,9,290,4,0,
325,9,316,1,10,
325,9,324,3,0,
325,9,326,2,0,
325,9,340,1,48,
325,9,340,3,0,
325,9,347,4,0,
325,9,351,4,0,
325,9,363,4,0,
325,9,371,1,34,
325,9,371,4,0,
325,9,408,1,46,
325,9,428,1,26,
325,9,428,2,0,
325,9,428,3,0,
325,9,433,4,0,
325,9,445,4,0,
325,9,447,4,0,
325,9,451,4,0,
325,10,29,3,0,
325,10,60,1,14,
325,10,86,4,0,
325,10,92,4,0,
325,10,94,1,41,
325,10,94,4,0,
325,10,104,4,0,
325,10,109,1,18,
325,10,113,4,0,
325,10,115,4,0,
325,10,129,3,0,
325,10,133,2,0,
325,10,138,4,0,
325,10,148,4,0,
325,10,149,1,7,
325,10,150,1,1,
325,10,156,1,29,1
325,10,156,4,0,
325,10,164,2,0,
325,10,164,4,0,
325,10,168,4,0,
325,10,173,1,29,2
325,10,173,3,0,
325,10,182,4,0,
325,10,196,3,0,
325,10,203,4,0,
325,10,207,4,0,
325,10,213,4,0,
325,10,214,4,0,
325,10,215,3,0,
325,10,216,4,0,
325,10,218,4,0,
325,10,231,4,0,
325,10,237,4,0,
325,10,240,4,0,
325,10,241,4,0,
325,10,243,2,0,
325,10,244,1,15,
325,10,244,4,0,
325,10,247,4,0,
325,10,248,2,0,
325,10,259,4,0,
325,10,263,4,0,
325,10,269,4,0,
325,10,271,2,0,
325,10,271,3,0,
325,10,272,3,0,
325,10,277,1,21,
325,10,277,3,0,
325,10,278,4,0,
325,10,285,4,0,
325,10,289,4,0,
325,10,290,4,0,
325,10,316,1,10,
325,10,324,3,0,
325,10,326,2,0,
325,10,340,1,48,
325,10,340,3,0,
325,10,347,4,0,
325,10,351,4,0,
325,10,363,4,0,
325,10,371,1,34,
325,10,371,4,0,
325,10,408,1,46,
325,10,428,1,26,
325,10,428,2,0,
325,10,428,3,0,
325,10,433,4,0,
325,10,445,4,0,
325,10,447,4,0,
325,10,451,4,0,
325,11,18,2,0,
325,11,60,1,14,
325,11,86,4,0,
325,11,92,4,0,
325,11,94,1,46,
325,11,94,4,0,
325,11,104,4,0,
325,11,109,1,18,
325,11,113,4,0,
325,11,115,4,0,
325,11,133,2,0,
325,11,138,4,0,
325,11,148,4,0,
325,11,149,1,7,
325,11,150,1,1,
325,11,156,1,29,1
325,11,156,4,0,
325,11,164,4,0,
325,11,168,4,0,
325,11,173,1,29,2
325,11,182,4,0,
325,11,203,2,0,
325,11,207,4,0,
325,11,213,4,0,
325,11,216,4,0,
325,11,218,4,0,
325,11,237,4,0,
325,11,240,4,0,
325,11,241,4,0,
325,11,243,2,0,
325,11,244,1,15,
325,11,244,4,0,
325,11,247,4,0,
325,11,248,2,0,
325,11,259,4,0,
325,11,263,4,0,
325,11,269,4,0,
325,11,271,2,0,
325,11,277,1,21,
325,11,285,2,0,
325,11,316,1,10,
325,11,326,2,0,
325,11,340,1,53,
325,11,347,4,0,
325,11,371,1,41,
325,11,371,4,0,
325,11,381,2,0,
325,11,408,1,48,
325,11,428,1,26,
325,11,428,2,0,
325,11,433,4,0,
325,11,447,4,0,
325,11,451,4,0,
325,11,473,1,34,
325,11,473,4,0,
325,11,477,4,0,
325,11,496,4,0,
325,12,60,1,16,
325,12,92,4,0,
325,12,94,1,34,
325,12,94,4,0,
325,12,104,4,0,
325,12,109,1,25,
325,12,113,4,0,
325,12,115,4,0,
325,12,148,4,0,
325,12,149,1,7,
325,12,150,1,1,
325,12,156,1,37,1
325,12,156,4,0,
325,12,168,4,0,
325,12,173,1,37,2
325,12,182,4,0,
325,12,213,4,0,
325,12,216,4,0,
325,12,218,4,0,
325,12,231,4,0,
325,12,237,4,0,
325,12,240,4,0,
325,12,241,4,0,
325,12,244,1,19,
325,12,247,4,0,
325,12,259,4,0,
325,12,263,4,0,
325,12,269,4,0,
325,12,277,1,28,
325,12,285,4,0,
325,12,289,4,0,
325,12,290,4,0,
325,12,316,1,10,
325,12,340,1,43,
325,12,347,4,0,
325,12,351,4,0,
325,13,34,3,0,
325,13,38,3,0,
325,13,60,1,16,
325,13,92,4,0,
325,13,94,1,34,
325,13,94,4,0,
325,13,102,3,0,
325,13,104,4,0,
325,13,109,1,25,
325,13,113,4,0,
325,13,115,4,0,
325,13,138,3,0,
325,13,148,4,0,
325,13,149,1,7,
325,13,150,1,1,
325,13,156,1,37,1
325,13,156,4,0,
325,13,164,3,0,
325,13,168,4,0,
325,13,173,1,37,2
325,13,182,4,0,
325,13,196,3,0,
325,13,207,3,0,
325,13,213,4,0,
325,13,216,4,0,
325,13,218,4,0,
325,13,231,4,0,
325,13,237,4,0,
325,13,240,4,0,
325,13,241,4,0,
325,13,244,1,19,
325,13,247,4,0,
325,13,259,4,0,
325,13,263,4,0,
325,13,269,4,0,
325,13,277,1,28,
325,13,285,4,0,
325,13,289,4,0,
325,13,290,4,0,
325,13,316,1,10,
325,13,340,1,43,
325,13,347,4,0,
325,13,351,4,0,
325,14,18,2,0,
325,14,60,1,14,
325,14,86,4,0,
325,14,92,4,0,
325,14,94,1,44,
325,14,94,4,0,
325,14,104,4,0,
325,14,109,1,18,
325,14,113,4,0,
325,14,115,4,0,
325,14,133,2,0,
325,14,138,4,0,
325,14,148,4,0,
325,14,149,1,7,
325,14,150,1,1,
325,14,156,1,29,1
325,14,156,4,0,
325,14,164,4,0,
325,14,168,4,0,
325,14,173,1,29,2
325,14,173,3,0,
325,14,182,4,0,
325,14,196,3,0,
325,14,203,2,0,
325,14,207,4,0,
325,14,213,4,0,
325,14,214,3,0,
325,14,215,3,0,
325,14,216,4,0,
325,14,218,4,0,
325,14,231,3,0,
325,14,237,4,0,
325,14,240,4,0,
325,14,241,4,0,
325,14,243,2,0,
325,14,244,1,15,
325,14,244,4,0,
325,14,247,4,0,
325,14,248,2,0,
325,14,259,4,0,
325,14,263,4,0,
325,14,269,4,0,
325,14,271,2,0,
325,14,271,3,0,
325,14,272,3,0,
325,14,277,1,21,
325,14,277,3,0,
325,14,278,3,0,
325,14,285,2,0,
325,14,285,3,0,
325,14,289,3,0,
325,14,316,1,10,
325,14,324,3,0,
325,14,326,2,0,
325,14,340,1,50,
325,14,340,3,0,
325,14,343,3,0,
325,14,347,4,0,
325,14,371,1,40,
325,14,371,4,0,
325,14,381,2,0,
325,14,408,1,33,
325,14,428,1,26,
325,14,428,2,0,
325,14,428,3,0,
325,14,433,4,0,
325,14,447,4,0,
325,14,451,4,0,
325,14,473,1,38,
325,14,473,4,0,
325,14,477,4,0,
325,14,496,4,0,
325,15,18,2,0,
325,15,60,1,14,
325,15,86,4,0,
325,15,92,4,0,
325,15,94,1,44,
325,15,94,4,0,
325,15,104,4,0,
325,15,109,1,18,
325,15,113,4,0,
325,15,115,4,0,
325,15,133,2,0,
325,15,138,4,0,
325,15,148,4,0,
325,15,149,1,7,
325,15,150,1,1,
325,15,156,1,29,1
325,15,156,4,0,
325,15,164,4,0,
325,15,168,4,0,
325,15,173,1,29,2
325,15,182,4,0,
325,15,203,2,0,
325,15,207,4,0,
325,15,213,4,0,
325,15,214,4,0,
325,15,216,4,0,
325,15,218,4,0,
325,15,237,4,0,
325,15,240,4,0,
325,15,241,4,0,
325,15,243,2,0,
325,15,244,1,15,
325,15,244,4,0,
325,15,247,4,0,
325,15,248,2,0,
325,15,259,4,0,
325,15,263,4,0,
325,15,269,4,0,
325,15,271,2,0,
325,15,277,1,21,
325,15,285,2,0,
325,15,316,1,10,
325,15,326,2,0,
325,15,340,1,50,
325,15,347,4,0,
325,15,371,1,40,
325,15,371,4,0,
325,15,381,2,0,
325,15,408,1,33,
325,15,428,1,26,
325,15,428,2,0,
325,15,433,4,0,
325,15,447,4,0,
325,15,451,4,0,
325,15,473,1,38,
325,15,473,4,0,
325,15,493,2,0,
325,15,496,4,0,
325,15,590,4,0,
325,16,18,2,0,
325,16,60,1,14,1
325,16,86,4,0,
325,16,92,4,0,
325,16,94,1,44,1
325,16,94,4,0,
325,16,104,4,0,
325,16,109,1,18,1
325,16,113,4,0,
325,16,115,4,0,
325,16,133,2,0,
325,16,138,4,0,
325,16,148,4,0,
325,16,149,1,7,1
325,16,150,1,1,1
325,16,156,1,29,2
325,16,156,4,0,
325,16,164,4,0,
325,16,168,4,0,
325,16,173,1,33,1
325,16,173,3,0,
325,16,182,4,0,
325,16,196,3,0,
325,16,203,2,0,
325,16,207,4,0,
325,16,213,4,0,
325,16,214,4,0,
325,16,215,3,0,
325,16,216,4,0,
325,16,218,4,0,
325,16,231,3,0,
325,16,237,4,0,
325,16,240,4,0,
325,16,241,4,0,
325,16,243,2,0,
325,16,244,1,15,1
325,16,244,4,0,
325,16,247,4,0,
325,16,248,2,0,
325,16,259,4,0,
325,16,263,4,0,
325,16,269,4,0,
325,16,271,2,0,
325,16,271,3,0,
325,16,272,3,0,
325,16,277,1,21,1
325,16,277,3,0,
325,16,278,3,0,
325,16,285,2,0,
325,16,285,3,0,
325,16,289,3,0,
325,16,290,4,0,
325,16,316,1,10,1
325,16,324,3,0,
325,16,326,2,0,
325,16,340,1,50,1
325,16,340,3,0,
325,16,343,3,0,
325,16,347,4,0,
325,16,351,3,0,
325,16,371,1,40,1
325,16,371,4,0,
325,16,381,2,0,
325,16,408,1,29,1
325,16,428,1,26,1
325,16,428,2,0,
325,16,428,3,0,
325,16,433,4,0,
325,16,447,4,0,
325,16,451,4,0,
325,16,473,1,38,1
325,16,473,4,0,
325,16,493,2,0,
325,16,496,4,0,
325,16,590,4,0,
325,17,18,2,0,
325,17,60,1,14,
325,17,86,4,0,
325,17,92,4,0,
325,17,94,1,44,
325,17,94,4,0,
325,17,104,4,0,
325,17,109,1,18,
325,17,113,4,0,
325,17,115,4,0,
325,17,133,2,0,
325,17,138,4,0,
325,17,149,1,7,
325,17,150,1,1,
325,17,156,1,29,2
325,17,156,4,0,
325,17,164,4,0,
325,17,168,4,0,
325,17,173,1,33,
325,17,182,4,0,
325,17,203,2,0,
325,17,207,4,0,
325,17,213,4,0,
325,17,214,4,0,
325,17,216,4,0,
325,17,218,4,0,
325,17,237,4,0,
325,17,240,4,0,
325,17,241,4,0,
325,17,243,2,0,
325,17,244,1,15,
325,17,244,4,0,
325,17,247,4,0,
325,17,248,2,0,
325,17,259,4,0,
325,17,263,4,0,
325,17,269,4,0,
325,17,271,2,0,
325,17,277,1,21,
325,17,285,2,0,
325,17,316,1,10,
325,17,326,2,0,
325,17,340,1,50,
325,17,347,4,0,
325,17,371,1,40,
325,17,371,4,0,
325,17,381,2,0,
325,17,408,1,29,1
325,17,428,1,26,
325,17,428,2,0,
325,17,433,4,0,
325,17,447,4,0,
325,17,451,4,0,
325,17,473,1,38,
325,17,473,4,0,
325,17,493,2,0,
325,17,496,4,0,
325,17,590,4,0,
325,18,18,2,0,
325,18,60,1,14,
325,18,86,4,0,
325,18,92,4,0,
325,18,94,1,44,
325,18,94,4,0,
325,18,104,4,0,
325,18,109,1,18,
325,18,113,4,0,
325,18,115,4,0,
325,18,133,2,0,
325,18,138,4,0,
325,18,149,1,7,
325,18,150,1,1,
325,18,156,1,29,2
325,18,156,4,0,
325,18,164,4,0,
325,18,168,4,0,
325,18,173,1,33,
325,18,182,4,0,
325,18,203,2,0,
325,18,207,4,0,
325,18,213,4,0,
325,18,214,4,0,
325,18,216,4,0,
325,18,218,4,0,
325,18,237,4,0,
325,18,240,4,0,
325,18,241,4,0,
325,18,243,2,0,
325,18,244,1,15,
325,18,244,4,0,
325,18,247,4,0,
325,18,248,2,0,
325,18,259,4,0,
325,18,263,4,0,
325,18,269,4,0,
325,18,271,2,0,
325,18,277,1,21,
325,18,285,2,0,
325,18,316,1,10,
325,18,326,2,0,
325,18,340,1,50,
325,18,347,4,0,
325,18,371,1,40,
325,18,371,4,0,
325,18,381,2,0,
325,18,408,1,29,1
325,18,428,1,26,
325,18,428,2,0,
325,18,433,4,0,
325,18,447,4,0,
325,18,451,4,0,
325,18,473,1,38,
325,18,473,4,0,
325,18,493,2,0,
325,18,496,4,0,
325,18,590,4,0,
326,5,60,1,1,4
326,5,60,1,16,
326,5,63,4,0,
326,5,92,4,0,
326,5,94,1,37,
326,5,94,4,0,
326,5,104,4,0,
326,5,109,1,25,
326,5,113,4,0,
326,5,115,4,0,
326,5,148,4,0,
326,5,149,1,1,2
326,5,149,1,7,
326,5,150,1,1,1
326,5,156,1,43,
326,5,156,4,0,
326,5,168,4,0,
326,5,173,1,43,5
326,5,182,4,0,
326,5,213,4,0,
326,5,216,4,0,
326,5,218,4,0,
326,5,231,4,0,
326,5,237,4,0,
326,5,240,4,0,
326,5,241,4,0,
326,5,244,1,19,
326,5,247,4,0,
326,5,259,4,0,
326,5,263,4,0,
326,5,264,4,0,
326,5,269,4,0,
326,5,277,1,28,
326,5,285,4,0,
326,5,289,4,0,
326,5,290,4,0,
326,5,316,1,1,3
326,5,316,1,10,
326,5,340,1,55,
326,5,347,4,0,
326,5,351,4,0,
326,6,5,3,0,
326,6,7,3,0,
326,6,8,3,0,
326,6,9,3,0,
326,6,25,3,0,
326,6,34,3,0,
326,6,38,3,0,
326,6,60,1,1,4
326,6,60,1,16,
326,6,63,4,0,
326,6,68,3,0,
326,6,69,3,0,
326,6,92,4,0,
326,6,94,1,37,
326,6,94,4,0,
326,6,102,3,0,
326,6,104,4,0,
326,6,109,1,25,
326,6,113,4,0,
326,6,115,4,0,
326,6,129,3,0,
326,6,138,3,0,
326,6,148,4,0,
326,6,149,1,1,2
326,6,149,1,7,
326,6,150,1,1,1
326,6,156,1,43,
326,6,156,4,0,
326,6,164,3,0,
326,6,168,4,0,
326,6,173,1,43,5
326,6,173,3,0,
326,6,182,4,0,
326,6,189,3,0,
326,6,196,3,0,
326,6,203,3,0,
326,6,207,3,0,
326,6,213,4,0,
326,6,214,3,0,
326,6,216,4,0,
326,6,218,4,0,
326,6,223,3,0,
326,6,231,4,0,
326,6,237,4,0,
326,6,240,4,0,
326,6,241,4,0,
326,6,244,1,19,
326,6,244,3,0,
326,6,247,4,0,
326,6,259,4,0,
326,6,263,4,0,
326,6,264,4,0,
326,6,269,4,0,
326,6,277,1,28,
326,6,285,4,0,
326,6,289,4,0,
326,6,290,4,0,
326,6,316,1,1,3
326,6,316,1,10,
326,6,340,1,55,
326,6,347,4,0,
326,6,351,4,0,
326,7,5,3,0,
326,7,25,3,0,
326,7,34,3,0,
326,7,38,3,0,
326,7,60,1,1,4
326,7,60,1,16,
326,7,63,4,0,
326,7,68,3,0,
326,7,69,3,0,
326,7,92,4,0,
326,7,94,1,37,
326,7,94,4,0,
326,7,102,3,0,
326,7,104,4,0,
326,7,109,1,25,
326,7,113,4,0,
326,7,115,4,0,
326,7,138,3,0,
326,7,148,4,0,
326,7,149,1,1,2
326,7,149,1,7,
326,7,150,1,1,1
326,7,156,1,43,
326,7,156,4,0,
326,7,164,3,0,
326,7,168,4,0,
326,7,173,1,43,5
326,7,182,4,0,
326,7,213,4,0,
326,7,216,4,0,
326,7,218,4,0,
326,7,231,4,0,
326,7,237,4,0,
326,7,240,4,0,
326,7,241,4,0,
326,7,244,1,19,
326,7,247,4,0,
326,7,259,4,0,
326,7,263,4,0,
326,7,264,4,0,
326,7,269,4,0,
326,7,277,1,28,
326,7,285,4,0,
326,7,289,4,0,
326,7,290,4,0,
326,7,316,1,1,3
326,7,316,1,10,
326,7,340,1,55,
326,7,347,4,0,
326,7,351,4,0,
326,8,60,1,1,4
326,8,60,1,14,
326,8,63,4,0,
326,8,86,4,0,
326,8,92,4,0,
326,8,94,1,47,
326,8,94,4,0,
326,8,104,4,0,
326,8,109,1,18,
326,8,113,4,0,
326,8,115,4,0,
326,8,138,4,0,
326,8,148,4,0,
326,8,149,1,1,2
326,8,149,1,7,
326,8,150,1,1,1
326,8,156,1,29,1
326,8,156,4,0,
326,8,164,4,0,
326,8,168,4,0,
326,8,173,1,29,2
326,8,182,4,0,
326,8,203,4,0,
326,8,207,4,0,
326,8,213,4,0,
326,8,214,4,0,
326,8,216,4,0,
326,8,218,4,0,
326,8,231,4,0,
326,8,237,4,0,
326,8,240,4,0,
326,8,241,4,0,
326,8,244,1,15,
326,8,244,4,0,
326,8,247,4,0,
326,8,259,4,0,
326,8,263,4,0,
326,8,264,4,0,
326,8,269,4,0,
326,8,277,1,21,
326,8,278,4,0,
326,8,280,4,0,
326,8,285,4,0,
326,8,289,4,0,
326,8,290,4,0,
326,8,316,1,1,3
326,8,316,1,10,
326,8,340,1,60,
326,8,347,4,0,
326,8,351,4,0,
326,8,363,4,0,
326,8,371,1,37,
326,8,371,4,0,
326,8,374,4,0,
326,8,408,1,55,
326,8,409,4,0,
326,8,411,4,0,
326,8,412,4,0,
326,8,416,4,0,
326,8,428,1,26,
326,8,433,4,0,
326,8,445,4,0,
326,8,447,4,0,
326,8,451,4,0,
326,9,7,3,0,
326,9,8,3,0,
326,9,9,3,0,
326,9,60,1,1,4
326,9,60,1,14,
326,9,63,4,0,
326,9,86,4,0,
326,9,92,4,0,
326,9,94,1,47,
326,9,94,4,0,
326,9,104,4,0,
326,9,109,1,18,
326,9,113,4,0,
326,9,115,4,0,
326,9,129,3,0,
326,9,138,4,0,
326,9,148,4,0,
326,9,149,1,1,2
326,9,149,1,7,
326,9,150,1,1,1
326,9,156,1,29,1
326,9,156,4,0,
326,9,164,4,0,
326,9,168,4,0,
326,9,173,1,29,2
326,9,173,3,0,
326,9,182,4,0,
326,9,189,3,0,
326,9,196,3,0,
326,9,203,4,0,
326,9,207,4,0,
326,9,213,4,0,
326,9,214,4,0,
326,9,216,4,0,
326,9,218,4,0,
326,9,231,4,0,
326,9,237,4,0,
326,9,240,4,0,
326,9,241,4,0,
326,9,244,1,15,
326,9,244,4,0,
326,9,247,4,0,
326,9,259,4,0,
326,9,263,4,0,
326,9,264,4,0,
326,9,269,4,0,
326,9,271,3,0,
326,9,277,1,21,
326,9,278,4,0,
326,9,280,4,0,
326,9,285,4,0,
326,9,289,4,0,
326,9,290,4,0,
326,9,316,1,1,3
326,9,316,1,10,
326,9,324,3,0,
326,9,340,1,60,
326,9,340,3,0,
326,9,347,4,0,
326,9,351,4,0,
326,9,363,4,0,
326,9,371,1,37,
326,9,371,4,0,
326,9,374,4,0,
326,9,408,1,55,
326,9,409,4,0,
326,9,411,4,0,
326,9,412,4,0,
326,9,416,4,0,
326,9,428,1,26,
326,9,428,3,0,
326,9,433,4,0,
326,9,445,4,0,
326,9,447,4,0,
326,9,451,4,0,
326,10,7,3,0,
326,10,8,3,0,
326,10,9,3,0,
326,10,29,3,0,
326,10,60,1,1,4
326,10,60,1,14,
326,10,63,4,0,
326,10,86,4,0,
326,10,92,4,0,
326,10,94,1,47,
326,10,94,4,0,
326,10,104,4,0,
326,10,109,1,18,
326,10,113,4,0,
326,10,115,4,0,
326,10,129,3,0,
326,10,138,4,0,
326,10,148,4,0,
326,10,149,1,1,2
326,10,149,1,7,
326,10,150,1,1,1
326,10,156,1,29,1
326,10,156,4,0,
326,10,164,4,0,
326,10,168,4,0,
326,10,173,1,29,2
326,10,173,3,0,
326,10,182,4,0,
326,10,189,3,0,
326,10,196,3,0,
326,10,203,4,0,
326,10,207,4,0,
326,10,213,4,0,
326,10,214,4,0,
326,10,215,3,0,
326,10,216,4,0,
326,10,218,4,0,
326,10,231,4,0,
326,10,237,4,0,
326,10,240,4,0,
326,10,241,4,0,
326,10,244,1,15,
326,10,244,4,0,
326,10,247,4,0,
326,10,259,4,0,
326,10,263,4,0,
326,10,264,4,0,
326,10,269,4,0,
326,10,271,3,0,
326,10,272,3,0,
326,10,277,1,21,
326,10,277,3,0,
326,10,278,4,0,
326,10,280,4,0,
326,10,285,4,0,
326,10,289,4,0,
326,10,290,4,0,
326,10,316,1,1,3
326,10,316,1,10,
326,10,324,3,0,
326,10,340,1,60,
326,10,340,3,0,
326,10,347,4,0,
326,10,351,4,0,
326,10,363,4,0,
326,10,371,1,37,
326,10,371,4,0,
326,10,374,4,0,
326,10,408,1,55,
326,10,409,4,0,
326,10,411,4,0,
326,10,412,4,0,
326,10,416,4,0,
326,10,428,1,26,
326,10,428,3,0,
326,10,433,4,0,
326,10,445,4,0,
326,10,447,4,0,
326,10,451,4,0,
326,11,60,1,1,4
326,11,60,1,14,
326,11,63,4,0,
326,11,86,4,0,
326,11,92,4,0,
326,11,94,1,55,
326,11,94,4,0,
326,11,104,4,0,
326,11,109,1,18,
326,11,113,4,0,
326,11,115,4,0,
326,11,138,4,0,
326,11,148,4,0,
326,11,149,1,1,2
326,11,149,1,7,
326,11,150,1,1,1
326,11,156,1,29,1
326,11,156,4,0,
326,11,164,4,0,
326,11,168,4,0,
326,11,173,1,29,2
326,11,182,4,0,
326,11,207,4,0,
326,11,213,4,0,
326,11,216,4,0,
326,11,218,4,0,
326,11,237,4,0,
326,11,240,4,0,
326,11,241,4,0,
326,11,244,1,15,
326,11,244,4,0,
326,11,247,4,0,
326,11,259,4,0,
326,11,263,4,0,
326,11,269,4,0,
326,11,277,1,21,
326,11,280,4,0,
326,11,316,1,1,3
326,11,316,1,10,
326,11,340,1,68,
326,11,347,4,0,
326,11,371,1,47,
326,11,371,4,0,
326,11,374,4,0,
326,11,408,1,60,
326,11,411,4,0,
326,11,412,4,0,
326,11,416,4,0,
326,11,428,1,26,
326,11,433,4,0,
326,11,447,4,0,
326,11,451,4,0,
326,11,473,1,37,
326,11,473,4,0,
326,11,477,4,0,
326,11,496,4,0,
326,11,523,4,0,
326,12,60,1,1,4
326,12,60,1,16,
326,12,63,4,0,
326,12,92,4,0,
326,12,94,1,37,
326,12,94,4,0,
326,12,104,4,0,
326,12,109,1,25,
326,12,113,4,0,
326,12,115,4,0,
326,12,148,4,0,
326,12,149,1,1,2
326,12,149,1,7,
326,12,150,1,1,1
326,12,156,1,43,
326,12,156,4,0,
326,12,168,4,0,
326,12,173,1,43,5
326,12,182,4,0,
326,12,213,4,0,
326,12,216,4,0,
326,12,218,4,0,
326,12,231,4,0,
326,12,237,4,0,
326,12,240,4,0,
326,12,241,4,0,
326,12,244,1,19,
326,12,247,4,0,
326,12,259,4,0,
326,12,263,4,0,
326,12,264,4,0,
326,12,269,4,0,
326,12,277,1,28,
326,12,285,4,0,
326,12,289,4,0,
326,12,290,4,0,
326,12,316,1,1,3
326,12,316,1,10,
326,12,340,1,55,
326,12,347,4,0,
326,12,351,4,0,
326,13,34,3,0,
326,13,38,3,0,
326,13,60,1,1,4
326,13,60,1,16,
326,13,63,4,0,
326,13,69,3,0,
326,13,92,4,0,
326,13,94,1,37,
326,13,94,4,0,
326,13,102,3,0,
326,13,104,4,0,
326,13,109,1,25,
326,13,113,4,0,
326,13,115,4,0,
326,13,138,3,0,
326,13,148,4,0,
326,13,149,1,1,2
326,13,149,1,7,
326,13,150,1,1,1
326,13,156,1,43,
326,13,156,4,0,
326,13,164,3,0,
326,13,168,4,0,
326,13,173,1,43,5
326,13,182,4,0,
326,13,196,3,0,
326,13,207,3,0,
326,13,213,4,0,
326,13,216,4,0,
326,13,218,4,0,
326,13,231,4,0,
326,13,237,4,0,
326,13,240,4,0,
326,13,241,4,0,
326,13,244,1,19,
326,13,247,4,0,
326,13,259,4,0,
326,13,263,4,0,
326,13,264,4,0,
326,13,269,4,0,
326,13,277,1,28,
326,13,285,4,0,
326,13,289,4,0,
326,13,290,4,0,
326,13,316,1,1,3
326,13,316,1,10,
326,13,340,1,55,
326,13,347,4,0,
326,13,351,4,0,
326,14,7,3,0,
326,14,8,3,0,
326,14,9,3,0,
326,14,60,1,1,4
326,14,60,1,14,
326,14,63,4,0,
326,14,86,4,0,
326,14,92,4,0,
326,14,94,1,52,
326,14,94,4,0,
326,14,104,4,0,
326,14,109,1,18,
326,14,113,4,0,
326,14,115,4,0,
326,14,138,4,0,
326,14,148,4,0,
326,14,149,1,1,2
326,14,149,1,7,
326,14,150,1,1,1
326,14,156,1,29,1
326,14,156,4,0,
326,14,164,4,0,
326,14,168,4,0,
326,14,173,1,29,2
326,14,173,3,0,
326,14,182,4,0,
326,14,196,3,0,
326,14,207,4,0,
326,14,213,4,0,
326,14,214,3,0,
326,14,215,3,0,
326,14,216,4,0,
326,14,218,4,0,
326,14,231,3,0,
326,14,237,4,0,
326,14,240,4,0,
326,14,241,4,0,
326,14,244,1,15,
326,14,244,4,0,
326,14,247,4,0,
326,14,259,4,0,
326,14,263,4,0,
326,14,269,4,0,
326,14,271,3,0,
326,14,272,3,0,
326,14,277,1,21,
326,14,277,3,0,
326,14,278,3,0,
326,14,280,4,0,
326,14,285,3,0,
326,14,289,3,0,
326,14,316,1,1,3
326,14,316,1,10,
326,14,324,3,0,
326,14,340,1,60,
326,14,340,3,0,
326,14,343,3,0,
326,14,347,4,0,
326,14,371,1,46,
326,14,371,4,0,
326,14,374,4,0,
326,14,408,1,35,
326,14,409,3,0,
326,14,411,4,0,
326,14,412,4,0,
326,14,416,4,0,
326,14,428,1,26,
326,14,428,3,0,
326,14,433,4,0,
326,14,447,4,0,
326,14,451,4,0,
326,14,473,1,42,
326,14,473,4,0,
326,14,477,4,0,
326,14,496,4,0,
326,14,523,4,0,
326,15,60,1,1,4
326,15,60,1,14,
326,15,63,4,0,
326,15,86,4,0,
326,15,92,4,0,
326,15,94,1,52,
326,15,94,4,0,
326,15,104,4,0,
326,15,109,1,18,
326,15,113,4,0,
326,15,115,4,0,
326,15,138,4,0,
326,15,148,4,0,
326,15,149,1,1,2
326,15,149,1,7,
326,15,150,1,1,1
326,15,156,1,29,1
326,15,156,4,0,
326,15,164,4,0,
326,15,168,4,0,
326,15,173,1,29,2
326,15,182,4,0,
326,15,207,4,0,
326,15,213,4,0,
326,15,214,4,0,
326,15,216,4,0,
326,15,218,4,0,
326,15,237,4,0,
326,15,240,4,0,
326,15,241,4,0,
326,15,244,1,15,
326,15,244,4,0,
326,15,247,4,0,
326,15,259,4,0,
326,15,263,4,0,
326,15,269,4,0,
326,15,277,1,21,
326,15,280,4,0,
326,15,316,1,1,3
326,15,316,1,10,
326,15,340,1,60,
326,15,347,4,0,
326,15,371,1,46,
326,15,371,4,0,
326,15,374,4,0,
326,15,408,1,35,
326,15,411,4,0,
326,15,412,4,0,
326,15,416,4,0,
326,15,428,1,26,
326,15,433,4,0,
326,15,447,4,0,
326,15,451,4,0,
326,15,473,1,42,
326,15,473,4,0,
326,15,496,4,0,
326,15,523,4,0,
326,15,590,4,0,
326,15,612,4,0,
326,16,7,3,0,
326,16,8,3,0,
326,16,9,3,0,
326,16,60,1,1,4
326,16,60,1,14,1
326,16,63,4,0,
326,16,86,4,0,
326,16,92,4,0,
326,16,94,1,52,1
326,16,94,4,0,
326,16,104,4,0,
326,16,109,1,18,1
326,16,113,4,0,
326,16,115,4,0,
326,16,138,4,0,
326,16,148,4,0,
326,16,149,1,1,2
326,16,149,1,7,1
326,16,150,1,1,1
326,16,156,1,35,1
326,16,156,4,0,
326,16,164,4,0,
326,16,168,4,0,
326,16,173,1,35,2
326,16,173,3,0,
326,16,182,4,0,
326,16,196,3,0,
326,16,207,4,0,
326,16,213,4,0,
326,16,214,4,0,
326,16,215,3,0,
326,16,216,4,0,
326,16,218,4,0,
326,16,231,3,0,
326,16,237,4,0,
326,16,240,4,0,
326,16,241,4,0,
326,16,244,1,15,1
326,16,244,4,0,
326,16,247,4,0,
326,16,259,4,0,
326,16,263,4,0,
326,16,264,3,0,
326,16,269,4,0,
326,16,271,3,0,
326,16,272,3,0,
326,16,277,1,21,1
326,16,277,3,0,
326,16,278,3,0,
326,16,280,4,0,
326,16,285,3,0,
326,16,289,3,0,
326,16,290,4,0,
326,16,298,1,32,1
326,16,316,1,1,3
326,16,316,1,10,1
326,16,324,3,0,
326,16,340,1,60,1
326,16,340,3,0,
326,16,343,3,0,
326,16,347,4,0,
326,16,351,3,0,
326,16,371,1,46,1
326,16,371,4,0,
326,16,374,4,0,
326,16,408,1,29,1
326,16,409,3,0,
326,16,411,4,0,
326,16,412,4,0,
326,16,416,4,0,
326,16,428,1,26,1
326,16,428,3,0,
326,16,433,4,0,
326,16,447,4,0,
326,16,451,4,0,
326,16,473,1,42,1
326,16,473,4,0,
326,16,496,4,0,
326,16,523,4,0,
326,16,590,4,0,
326,16,612,4,0,
326,17,60,1,1,6
326,17,60,1,14,
326,17,63,4,0,
326,17,86,4,0,
326,17,92,4,0,
326,17,94,1,52,
326,17,94,4,0,
326,17,104,4,0,
326,17,109,1,18,
326,17,113,4,0,
326,17,115,4,0,
326,17,138,4,0,
326,17,149,1,1,4
326,17,149,1,7,
326,17,150,1,1,3
326,17,156,1,35,1
326,17,156,4,0,
326,17,164,4,0,
326,17,168,4,0,
326,17,173,1,35,2
326,17,182,4,0,
326,17,207,4,0,
326,17,213,4,0,
326,17,214,4,0,
326,17,216,4,0,
326,17,218,4,0,
326,17,237,4,0,
326,17,240,4,0,
326,17,241,4,0,
326,17,244,1,15,
326,17,244,4,0,
326,17,247,4,0,
326,17,259,4,0,
326,17,263,4,0,
326,17,269,4,0,
326,17,277,1,21,
326,17,280,4,0,
326,17,298,1,0,
326,17,298,1,1,1
326,17,316,1,1,5
326,17,316,1,10,
326,17,340,1,60,
326,17,347,4,0,
326,17,371,1,46,
326,17,371,4,0,
326,17,374,4,0,
326,17,408,1,29,
326,17,411,4,0,
326,17,412,4,0,
326,17,416,4,0,
326,17,428,1,26,
326,17,433,4,0,
326,17,447,4,0,
326,17,451,4,0,
326,17,473,1,42,
326,17,473,4,0,
326,17,496,4,0,
326,17,523,4,0,
326,17,562,1,1,2
326,17,590,4,0,
326,18,60,1,1,6
326,18,60,1,14,
326,18,63,4,0,
326,18,86,4,0,
326,18,92,4,0,
326,18,94,1,52,
326,18,94,4,0,
326,18,104,4,0,
326,18,109,1,18,
326,18,113,4,0,
326,18,115,4,0,
326,18,138,4,0,
326,18,149,1,1,4
326,18,149,1,7,
326,18,150,1,1,3
326,18,156,1,35,1
326,18,156,4,0,
326,18,164,4,0,
326,18,168,4,0,
326,18,173,1,35,2
326,18,182,4,0,
326,18,207,4,0,
326,18,213,4,0,
326,18,214,4,0,
326,18,216,4,0,
326,18,218,4,0,
326,18,237,4,0,
326,18,240,4,0,
326,18,241,4,0,
326,18,244,1,15,
326,18,244,4,0,
326,18,247,4,0,
326,18,259,4,0,
326,18,263,4,0,
326,18,269,4,0,
326,18,277,1,21,
326,18,280,4,0,
326,18,298,1,0,
326,18,298,1,1,1
326,18,316,1,1,5
326,18,316,1,10,
326,18,340,1,60,
326,18,347,4,0,
326,18,371,1,46,
326,18,371,4,0,
326,18,374,4,0,
326,18,408,1,29,
326,18,411,4,0,
326,18,412,4,0,
326,18,416,4,0,
326,18,428,1,26,
326,18,433,4,0,
326,18,447,4,0,
326,18,451,4,0,
326,18,473,1,42,
326,18,473,4,0,
326,18,496,4,0,
326,18,523,4,0,
326,18,562,1,1,2
326,18,590,4,0,
327,5,33,1,1,
327,5,37,1,56,
327,5,38,1,45,
327,5,50,2,0,
327,5,60,1,16,
327,5,70,4,0,
327,5,91,4,0,
327,5,92,4,0,
327,5,94,4,0,
327,5,95,1,23,
327,5,104,4,0,
327,5,146,1,27,
327,5,148,4,0,
327,5,156,4,0,
327,5,157,2,0,
327,5,168,4,0,
327,5,175,1,49,
327,5,182,4,0,
327,5,185,1,12,
327,5,213,4,0,
327,5,216,4,0,
327,5,218,4,0,
327,5,219,4,0,
327,5,226,2,0,
327,5,227,2,0,
327,5,237,4,0,
327,5,240,4,0,
327,5,241,4,0,
327,5,244,1,38,
327,5,247,4,0,
327,5,249,4,0,
327,5,253,1,5,
327,5,263,4,0,
327,5,264,4,0,
327,5,265,2,0,
327,5,271,2,0,
327,5,273,2,0,
327,5,274,2,0,
327,5,280,4,0,
327,5,285,4,0,
327,5,289,4,0,
327,5,290,4,0,
327,5,298,1,34,
327,5,317,4,0,
327,5,347,4,0,
327,5,351,4,0,
327,5,352,4,0,
327,6,5,3,0,
327,6,7,3,0,
327,6,8,3,0,
327,6,9,3,0,
327,6,25,3,0,
327,6,33,1,1,
327,6,34,3,0,
327,6,37,1,56,
327,6,38,1,45,
327,6,38,3,0,
327,6,50,2,0,
327,6,60,1,16,
327,6,68,3,0,
327,6,69,3,0,
327,6,70,4,0,
327,6,91,4,0,
327,6,92,4,0,
327,6,94,4,0,
327,6,95,1,23,
327,6,102,3,0,
327,6,104,4,0,
327,6,111,3,0,
327,6,118,3,0,
327,6,129,3,0,
327,6,138,3,0,
327,6,146,1,27,
327,6,148,4,0,
327,6,156,4,0,
327,6,157,2,0,
327,6,157,3,0,
327,6,164,3,0,
327,6,168,4,0,
327,6,173,3,0,
327,6,175,1,49,
327,6,182,4,0,
327,6,185,1,12,
327,6,189,3,0,
327,6,196,3,0,
327,6,203,3,0,
327,6,205,3,0,
327,6,207,3,0,
327,6,213,4,0,
327,6,214,3,0,
327,6,216,4,0,
327,6,218,4,0,
327,6,219,4,0,
327,6,223,3,0,
327,6,226,2,0,
327,6,227,2,0,
327,6,237,4,0,
327,6,240,4,0,
327,6,241,4,0,
327,6,244,1,38,
327,6,244,3,0,
327,6,247,4,0,
327,6,249,4,0,
327,6,253,1,5,
327,6,263,4,0,
327,6,264,4,0,
327,6,265,2,0,
327,6,271,2,0,
327,6,273,2,0,
327,6,274,2,0,
327,6,280,4,0,
327,6,285,4,0,
327,6,289,4,0,
327,6,290,4,0,
327,6,298,1,34,
327,6,317,4,0,
327,6,347,4,0,
327,6,351,4,0,
327,6,352,4,0,
327,7,5,3,0,
327,7,25,3,0,
327,7,33,1,1,
327,7,34,3,0,
327,7,37,1,56,
327,7,38,1,45,
327,7,38,3,0,
327,7,50,2,0,
327,7,60,1,16,
327,7,68,3,0,
327,7,69,3,0,
327,7,70,4,0,
327,7,91,4,0,
327,7,92,4,0,
327,7,94,4,0,
327,7,95,1,23,
327,7,102,3,0,
327,7,104,4,0,
327,7,118,3,0,
327,7,138,3,0,
327,7,146,1,27,
327,7,148,4,0,
327,7,156,4,0,
327,7,157,2,0,
327,7,157,3,0,
327,7,164,3,0,
327,7,168,4,0,
327,7,175,1,49,
327,7,182,4,0,
327,7,185,1,12,
327,7,213,4,0,
327,7,216,4,0,
327,7,218,4,0,
327,7,219,4,0,
327,7,226,2,0,
327,7,227,2,0,
327,7,237,4,0,
327,7,240,4,0,
327,7,241,4,0,
327,7,244,1,38,
327,7,247,4,0,
327,7,249,4,0,
327,7,253,1,5,
327,7,263,4,0,
327,7,264,4,0,
327,7,265,2,0,
327,7,271,2,0,
327,7,273,2,0,
327,7,274,2,0,
327,7,280,4,0,
327,7,285,4,0,
327,7,289,4,0,
327,7,290,4,0,
327,7,298,1,34,
327,7,317,4,0,
327,7,347,4,0,
327,7,351,4,0,
327,7,352,4,0,
327,8,33,1,1,
327,8,37,1,55,
327,8,38,1,46,
327,8,50,2,0,
327,8,60,1,19,
327,8,70,4,0,
327,8,91,4,0,
327,8,92,4,0,
327,8,94,4,0,
327,8,95,1,23,
327,8,104,4,0,
327,8,138,4,0,
327,8,146,1,28,
327,8,148,4,0,
327,8,156,4,0,
327,8,157,2,0,
327,8,157,4,0,
327,8,164,4,0,
327,8,168,4,0,
327,8,175,1,50,
327,8,182,4,0,
327,8,185,1,14,
327,8,203,4,0,
327,8,207,4,0,
327,8,213,4,0,
327,8,214,4,0,
327,8,216,4,0,
327,8,218,4,0,
327,8,219,4,0,
327,8,226,2,0,
327,8,227,2,0,
327,8,237,4,0,
327,8,240,4,0,
327,8,241,4,0,
327,8,244,1,41,
327,8,244,4,0,
327,8,247,4,0,
327,8,249,4,0,
327,8,252,2,0,
327,8,253,1,5,
327,8,263,4,0,
327,8,264,4,0,
327,8,265,2,0,
327,8,271,2,0,
327,8,272,2,0,
327,8,273,2,0,
327,8,274,2,0,
327,8,278,4,0,
327,8,280,4,0,
327,8,285,4,0,
327,8,289,4,0,
327,8,290,4,0,
327,8,298,1,37,
327,8,317,4,0,
327,8,347,4,0,
327,8,351,4,0,
327,8,352,4,0,
327,8,363,4,0,
327,8,374,4,0,
327,8,383,1,10,
327,8,389,1,32,
327,8,409,4,0,
327,8,427,2,0,
327,8,433,4,0,
327,8,445,4,0,
327,9,7,3,0,
327,9,8,3,0,
327,9,9,3,0,
327,9,33,1,1,
327,9,37,1,55,
327,9,38,1,46,
327,9,50,2,0,
327,9,60,1,19,
327,9,70,4,0,
327,9,91,4,0,
327,9,92,4,0,
327,9,94,4,0,
327,9,95,1,23,
327,9,104,4,0,
327,9,129,3,0,
327,9,138,4,0,
327,9,146,1,28,
327,9,148,4,0,
327,9,156,4,0,
327,9,157,2,0,
327,9,157,4,0,
327,9,164,4,0,
327,9,168,4,0,
327,9,173,3,0,
327,9,175,1,50,
327,9,182,4,0,
327,9,185,1,14,
327,9,189,3,0,
327,9,196,3,0,
327,9,203,4,0,
327,9,205,3,0,
327,9,207,4,0,
327,9,213,4,0,
327,9,214,4,0,
327,9,216,4,0,
327,9,218,4,0,
327,9,219,4,0,
327,9,226,2,0,
327,9,227,2,0,
327,9,237,4,0,
327,9,240,4,0,
327,9,241,4,0,
327,9,244,1,41,
327,9,244,4,0,
327,9,247,4,0,
327,9,249,4,0,
327,9,252,2,0,
327,9,253,1,5,
327,9,253,3,0,
327,9,263,4,0,
327,9,264,4,0,
327,9,265,2,0,
327,9,270,3,0,
327,9,271,2,0,
327,9,272,2,0,
327,9,273,2,0,
327,9,274,2,0,
327,9,278,4,0,
327,9,280,4,0,
327,9,285,4,0,
327,9,289,4,0,
327,9,290,4,0,
327,9,298,1,37,
327,9,317,4,0,
327,9,347,4,0,
327,9,351,4,0,
327,9,352,4,0,
327,9,363,4,0,
327,9,374,4,0,
327,9,383,1,10,
327,9,387,3,0,
327,9,389,1,32,
327,9,389,3,0,
327,9,409,4,0,
327,9,427,2,0,
327,9,428,3,0,
327,9,433,4,0,
327,9,445,4,0,
327,10,7,3,0,
327,10,8,3,0,
327,10,9,3,0,
327,10,29,3,0,
327,10,33,1,1,
327,10,37,1,55,
327,10,38,1,46,
327,10,50,2,0,
327,10,60,1,19,
327,10,67,3,0,
327,10,70,4,0,
327,10,91,4,0,
327,10,92,4,0,
327,10,94,4,0,
327,10,95,1,23,
327,10,104,4,0,
327,10,129,3,0,
327,10,138,4,0,
327,10,146,1,28,
327,10,148,4,0,
327,10,156,4,0,
327,10,157,2,0,
327,10,157,4,0,
327,10,164,4,0,
327,10,168,4,0,
327,10,173,3,0,
327,10,175,1,50,
327,10,182,4,0,
327,10,185,1,14,
327,10,189,3,0,
327,10,196,3,0,
327,10,203,4,0,
327,10,205,3,0,
327,10,207,4,0,
327,10,213,4,0,
327,10,214,4,0,
327,10,216,4,0,
327,10,218,4,0,
327,10,219,4,0,
327,10,226,2,0,
327,10,227,2,0,
327,10,237,4,0,
327,10,240,4,0,
327,10,241,4,0,
327,10,244,1,41,
327,10,244,4,0,
327,10,247,4,0,
327,10,249,4,0,
327,10,252,2,0,
327,10,253,1,5,
327,10,253,3,0,
327,10,263,4,0,
327,10,264,4,0,
327,10,265,2,0,
327,10,270,3,0,
327,10,271,2,0,
327,10,272,2,0,
327,10,272,3,0,
327,10,273,2,0,
327,10,274,2,0,
327,10,278,4,0,
327,10,280,4,0,
327,10,285,4,0,
327,10,289,4,0,
327,10,290,4,0,
327,10,298,1,37,
327,10,317,4,0,
327,10,347,4,0,
327,10,351,4,0,
327,10,352,4,0,
327,10,363,4,0,
327,10,374,4,0,
327,10,383,1,10,
327,10,387,3,0,
327,10,389,1,32,
327,10,389,3,0,
327,10,409,4,0,
327,10,427,2,0,
327,10,428,3,0,
327,10,433,4,0,
327,10,445,4,0,
327,11,33,1,1,
327,11,37,1,55,
327,11,38,1,46,
327,11,50,2,0,
327,11,60,1,19,
327,11,70,4,0,
327,11,91,4,0,
327,11,92,4,0,
327,11,94,4,0,
327,11,95,1,23,
327,11,104,4,0,
327,11,138,4,0,
327,11,146,1,28,
327,11,148,4,0,
327,11,156,4,0,
327,11,157,4,0,
327,11,164,4,0,
327,11,168,4,0,
327,11,175,1,50,
327,11,182,4,0,
327,11,185,1,14,
327,11,196,2,0,
327,11,207,4,0,
327,11,213,4,0,
327,11,216,4,0,
327,11,218,4,0,
327,11,219,4,0,
327,11,226,2,0,
327,11,227,2,0,
327,11,229,2,0,
327,11,237,4,0,
327,11,240,4,0,
327,11,241,4,0,
327,11,244,1,41,
327,11,244,4,0,
327,11,247,4,0,
327,11,249,4,0,
327,11,252,2,0,
327,11,253,1,5,
327,11,263,4,0,
327,11,265,2,0,
327,11,271,2,0,
327,11,272,2,0,
327,11,273,2,0,
327,11,274,2,0,
327,11,280,4,0,
327,11,298,1,37,
327,11,313,2,0,
327,11,317,4,0,
327,11,347,4,0,
327,11,352,2,0,
327,11,374,4,0,
327,11,383,1,10,
327,11,389,1,32,
327,11,427,2,0,
327,11,433,4,0,
327,11,496,4,0,
327,11,514,4,0,
327,11,526,4,0,
327,11,528,4,0,
327,12,33,1,1,
327,12,37,1,56,
327,12,38,1,45,
327,12,60,1,16,
327,12,70,4,0,
327,12,91,4,0,
327,12,92,4,0,
327,12,94,4,0,
327,12,95,1,23,
327,12,104,4,0,
327,12,146,1,27,
327,12,148,4,0,
327,12,156,4,0,
327,12,168,4,0,
327,12,175,1,49,
327,12,182,4,0,
327,12,185,1,12,
327,12,213,4,0,
327,12,216,4,0,
327,12,218,4,0,
327,12,219,4,0,
327,12,237,4,0,
327,12,240,4,0,
327,12,241,4,0,
327,12,244,1,38,
327,12,247,4,0,
327,12,249,4,0,
327,12,253,1,5,
327,12,263,4,0,
327,12,264,4,0,
327,12,280,4,0,
327,12,285,4,0,
327,12,289,4,0,
327,12,290,4,0,
327,12,298,1,34,
327,12,317,4,0,
327,12,347,4,0,
327,12,351,4,0,
327,12,352,4,0,
327,13,33,1,1,
327,13,34,3,0,
327,13,37,1,56,
327,13,38,1,45,
327,13,38,3,0,
327,13,60,1,16,
327,13,69,3,0,
327,13,70,4,0,
327,13,91,4,0,
327,13,92,4,0,
327,13,94,4,0,
327,13,95,1,23,
327,13,102,3,0,
327,13,104,4,0,
327,13,138,3,0,
327,13,146,1,27,
327,13,148,4,0,
327,13,156,4,0,
327,13,164,3,0,
327,13,168,4,0,
327,13,171,3,0,
327,13,175,1,49,
327,13,182,4,0,
327,13,185,1,12,
327,13,196,3,0,
327,13,207,3,0,
327,13,213,4,0,
327,13,216,4,0,
327,13,218,4,0,
327,13,219,4,0,
327,13,237,4,0,
327,13,240,4,0,
327,13,241,4,0,
327,13,244,1,38,
327,13,247,4,0,
327,13,249,4,0,
327,13,253,1,5,
327,13,263,4,0,
327,13,264,4,0,
327,13,280,4,0,
327,13,285,4,0,
327,13,289,4,0,
327,13,290,4,0,
327,13,298,1,34,
327,13,317,4,0,
327,13,347,4,0,
327,13,351,4,0,
327,13,352,4,0,
327,14,7,3,0,
327,14,8,3,0,
327,14,9,3,0,
327,14,33,1,1,
327,14,37,1,55,
327,14,38,1,46,
327,14,50,2,0,
327,14,60,1,19,
327,14,67,3,0,
327,14,70,4,0,
327,14,91,4,0,
327,14,92,4,0,
327,14,94,4,0,
327,14,95,1,23,
327,14,104,4,0,
327,14,138,4,0,
327,14,146,1,28,
327,14,148,4,0,
327,14,156,4,0,
327,14,157,4,0,
327,14,164,4,0,
327,14,168,4,0,
327,14,173,3,0,
327,14,175,1,50,
327,14,182,4,0,
327,14,185,1,14,
327,14,196,2,0,
327,14,196,3,0,
327,14,207,4,0,
327,14,213,4,0,
327,14,214,3,0,
327,14,216,4,0,
327,14,218,4,0,
327,14,219,4,0,
327,14,226,2,0,
327,14,227,2,0,
327,14,229,2,0,
327,14,237,4,0,
327,14,240,4,0,
327,14,241,4,0,
327,14,244,1,41,
327,14,244,4,0,
327,14,247,4,0,
327,14,249,4,0,
327,14,252,2,0,
327,14,253,1,5,
327,14,253,3,0,
327,14,263,4,0,
327,14,265,2,0,
327,14,270,3,0,
327,14,271,2,0,
327,14,271,3,0,
327,14,272,2,0,
327,14,272,3,0,
327,14,273,2,0,
327,14,274,2,0,
327,14,278,3,0,
327,14,280,4,0,
327,14,285,3,0,
327,14,289,3,0,
327,14,298,1,37,
327,14,304,3,0,
327,14,313,2,0,
327,14,317,4,0,
327,14,343,3,0,
327,14,347,4,0,
327,14,352,2,0,
327,14,374,4,0,
327,14,383,1,10,
327,14,387,3,0,
327,14,389,1,32,
327,14,409,3,0,
327,14,427,2,0,
327,14,428,3,0,
327,14,433,4,0,
327,14,496,4,0,
327,14,514,4,0,
327,14,526,4,0,
327,14,528,4,0,
327,15,33,1,1,
327,15,37,1,50,2
327,15,38,1,46,
327,15,50,2,0,
327,15,60,1,19,
327,15,70,4,0,
327,15,91,4,0,
327,15,92,4,0,
327,15,94,4,0,
327,15,95,1,23,
327,15,104,4,0,
327,15,138,4,0,
327,15,146,1,28,
327,15,148,4,0,
327,15,156,4,0,
327,15,157,4,0,
327,15,164,4,0,
327,15,168,4,0,
327,15,175,1,50,1
327,15,182,4,0,
327,15,185,1,14,
327,15,196,2,0,
327,15,207,4,0,
327,15,213,4,0,
327,15,216,4,0,
327,15,218,4,0,
327,15,219,4,0,
327,15,226,2,0,
327,15,227,2,0,
327,15,229,2,0,
327,15,237,4,0,
327,15,240,4,0,
327,15,241,4,0,
327,15,244,1,41,
327,15,244,4,0,
327,15,247,4,0,
327,15,249,4,0,
327,15,252,2,0,
327,15,253,1,5,
327,15,263,4,0,
327,15,265,2,0,
327,15,271,2,0,
327,15,272,2,0,
327,15,273,2,0,
327,15,274,2,0,
327,15,280,4,0,
327,15,298,1,37,
327,15,313,2,0,
327,15,317,4,0,
327,15,347,4,0,
327,15,352,2,0,
327,15,374,4,0,
327,15,375,2,0,
327,15,383,1,10,
327,15,389,1,32,
327,15,427,2,0,
327,15,433,4,0,
327,15,470,2,0,
327,15,496,4,0,
327,15,514,4,0,
327,15,528,4,0,
327,15,590,4,0,
327,15,612,4,0,
327,16,7,3,0,
327,16,8,3,0,
327,16,9,3,0,
327,16,33,1,1,1
327,16,37,1,55,1
327,16,38,1,46,1
327,16,50,2,0,
327,16,60,1,14,1
327,16,67,3,0,
327,16,70,4,0,
327,16,91,4,0,
327,16,92,4,0,
327,16,94,4,0,
327,16,95,1,19,1
327,16,104,4,0,
327,16,138,4,0,
327,16,146,1,23,1
327,16,148,4,0,
327,16,156,4,0,
327,16,157,4,0,
327,16,164,4,0,
327,16,168,4,0,
327,16,173,3,0,
327,16,175,1,50,1
327,16,182,4,0,
327,16,185,1,10,1
327,16,196,2,0,
327,16,196,3,0,
327,16,207,4,0,
327,16,213,4,0,
327,16,214,4,0,
327,16,216,4,0,
327,16,218,4,0,
327,16,219,4,0,
327,16,226,2,0,
327,16,227,2,0,
327,16,229,2,0,
327,16,237,4,0,
327,16,240,4,0,
327,16,241,4,0,
327,16,244,1,41,1
327,16,244,4,0,
327,16,247,4,0,
327,16,249,4,0,
327,16,252,2,0,
327,16,253,1,37,1
327,16,253,3,0,
327,16,263,4,0,
327,16,264,3,0,
327,16,265,2,0,
327,16,270,3,0,
327,16,271,2,0,
327,16,271,3,0,
327,16,272,2,0,
327,16,272,3,0,
327,16,273,2,0,
327,16,274,2,0,
327,16,278,3,0,
327,16,280,4,0,
327,16,285,3,0,
327,16,289,3,0,
327,16,290,4,0,
327,16,298,1,32,1
327,16,304,3,0,
327,16,313,2,0,
327,16,317,4,0,
327,16,343,3,0,
327,16,347,4,0,
327,16,351,3,0,
327,16,352,2,0,
327,16,352,3,0,
327,16,374,4,0,
327,16,375,2,0,
327,16,383,1,5,1
327,16,387,3,0,
327,16,389,1,28,1
327,16,409,3,0,
327,16,427,2,0,
327,16,428,3,0,
327,16,433,4,0,
327,16,470,2,0,
327,16,496,4,0,
327,16,514,4,0,
327,16,528,4,0,
327,16,590,4,0,
327,16,612,4,0,
327,17,33,1,1,
327,17,37,1,55,
327,17,38,1,46,
327,17,50,2,0,
327,17,60,1,14,
327,17,92,4,0,
327,17,94,4,0,
327,17,95,1,19,
327,17,104,4,0,
327,17,138,4,0,
327,17,146,1,23,
327,17,156,4,0,
327,17,157,4,0,
327,17,164,4,0,
327,17,168,4,0,
327,17,175,1,50,
327,17,182,4,0,
327,17,185,1,10,
327,17,196,2,0,
327,17,207,4,0,
327,17,213,4,0,
327,17,214,4,0,
327,17,216,4,0,
327,17,218,4,0,
327,17,219,4,0,
327,17,226,2,0,
327,17,227,2,0,
327,17,229,2,0,
327,17,237,4,0,
327,17,240,4,0,
327,17,241,4,0,
327,17,244,1,41,
327,17,244,4,0,
327,17,247,4,0,
327,17,252,2,0,
327,17,253,1,37,
327,17,263,4,0,
327,17,265,2,0,
327,17,271,2,0,
327,17,272,2,0,
327,17,273,2,0,
327,17,274,2,0,
327,17,280,4,0,
327,17,298,1,32,
327,17,313,2,0,
327,17,317,4,0,
327,17,347,4,0,
327,17,352,2,0,
327,17,374,4,0,
327,17,375,2,0,
327,17,383,1,5,
327,17,389,1,28,
327,17,427,2,0,
327,17,433,4,0,
327,17,470,2,0,
327,17,496,4,0,
327,17,526,4,0,
327,17,528,4,0,
327,17,590,4,0,
327,17,671,2,0,
327,18,33,1,1,
327,18,37,1,55,
327,18,38,1,46,
327,18,50,2,0,
327,18,60,1,14,
327,18,92,4,0,
327,18,94,4,0,
327,18,95,1,19,
327,18,104,4,0,
327,18,138,4,0,
327,18,146,1,23,
327,18,156,4,0,
327,18,157,4,0,
327,18,164,4,0,
327,18,168,4,0,
327,18,175,1,50,
327,18,182,4,0,
327,18,185,1,10,
327,18,196,2,0,
327,18,207,4,0,
327,18,213,4,0,
327,18,214,4,0,
327,18,216,4,0,
327,18,218,4,0,
327,18,219,4,0,
327,18,226,2,0,
327,18,227,2,0,
327,18,229,2,0,
327,18,237,4,0,
327,18,240,4,0,
327,18,241,4,0,
327,18,244,1,41,
327,18,244,4,0,
327,18,247,4,0,
327,18,252,2,0,
327,18,253,1,37,
327,18,263,4,0,
327,18,265,2,0,
327,18,271,2,0,
327,18,272,2,0,
327,18,273,2,0,
327,18,274,2,0,
327,18,280,4,0,
327,18,298,1,32,
327,18,313,2,0,
327,18,317,4,0,
327,18,347,4,0,
327,18,352,2,0,
327,18,374,4,0,
327,18,375,2,0,
327,18,383,1,5,
327,18,389,1,28,
327,18,427,2,0,
327,18,433,4,0,
327,18,470,2,0,
327,18,496,4,0,
327,18,526,4,0,
327,18,528,4,0,
327,18,590,4,0,
327,18,671,2,0,
328,5,16,2,0,
328,5,28,1,9,
328,5,44,1,1,
328,5,63,1,57,
328,5,63,4,0,
328,5,70,4,0,
328,5,76,4,0,
328,5,89,4,0,
328,5,91,1,41,
328,5,91,4,0,
328,5,92,4,0,
328,5,98,2,0,
328,5,104,4,0,
328,5,116,2,0,
328,5,156,4,0,
328,5,182,4,0,
328,5,185,1,17,
328,5,201,1,49,
328,5,201,4,0,
328,5,202,4,0,
328,5,213,4,0,
328,5,216,4,0,
328,5,218,4,0,
328,5,237,4,0,
328,5,241,4,0,
328,5,242,1,33,
328,5,249,4,0,
328,5,263,4,0,
328,5,290,4,0,
328,5,317,4,0,
328,5,328,1,25,
328,6,16,2,0,
328,6,28,1,9,
328,6,34,3,0,
328,6,38,3,0,
328,6,44,1,1,
328,6,63,1,57,
328,6,63,4,0,
328,6,70,4,0,
328,6,76,4,0,
328,6,89,4,0,
328,6,91,1,41,
328,6,91,4,0,
328,6,92,4,0,
328,6,98,2,0,
328,6,102,3,0,
328,6,104,4,0,
328,6,116,2,0,
328,6,156,4,0,
328,6,157,3,0,
328,6,164,3,0,
328,6,173,3,0,
328,6,182,4,0,
328,6,185,1,17,
328,6,189,3,0,
328,6,201,1,49,
328,6,201,4,0,
328,6,202,4,0,
328,6,203,3,0,
328,6,207,3,0,
328,6,213,4,0,
328,6,214,3,0,
328,6,216,4,0,
328,6,218,4,0,
328,6,237,4,0,
328,6,241,4,0,
328,6,242,1,33,
328,6,249,4,0,
328,6,263,4,0,
328,6,290,4,0,
328,6,317,4,0,
328,6,328,1,25,
328,7,16,2,0,
328,7,28,1,9,
328,7,34,3,0,
328,7,38,3,0,
328,7,44,1,1,
328,7,63,1,57,
328,7,63,4,0,
328,7,70,4,0,
328,7,76,4,0,
328,7,89,4,0,
328,7,91,1,41,
328,7,91,4,0,
328,7,92,4,0,
328,7,98,2,0,
328,7,102,3,0,
328,7,104,4,0,
328,7,116,2,0,
328,7,156,4,0,
328,7,157,3,0,
328,7,164,3,0,
328,7,182,4,0,
328,7,185,1,17,
328,7,201,1,49,
328,7,201,4,0,
328,7,202,4,0,
328,7,213,4,0,
328,7,216,4,0,
328,7,218,4,0,
328,7,237,4,0,
328,7,241,4,0,
328,7,242,1,33,
328,7,249,4,0,
328,7,263,4,0,
328,7,290,4,0,
328,7,317,4,0,
328,7,328,1,25,
328,8,16,2,0,
328,8,28,1,9,
328,8,44,1,1,
328,8,63,1,57,
328,8,63,4,0,
328,8,70,4,0,
328,8,76,4,0,
328,8,89,1,73,
328,8,89,4,0,
328,8,90,1,89,
328,8,91,1,41,
328,8,91,4,0,
328,8,92,4,0,
328,8,98,2,0,
328,8,104,4,0,
328,8,116,2,0,
328,8,156,4,0,
328,8,157,4,0,
328,8,164,4,0,
328,8,175,2,0,
328,8,182,4,0,
328,8,185,1,17,
328,8,201,1,49,
328,8,201,4,0,
328,8,202,4,0,
328,8,203,4,0,
328,8,207,4,0,
328,8,210,2,0,
328,8,213,4,0,
328,8,214,4,0,
328,8,216,4,0,
328,8,218,4,0,
328,8,237,4,0,
328,8,241,4,0,
328,8,242,1,33,
328,8,249,4,0,
328,8,263,4,0,
328,8,290,4,0,
328,8,317,4,0,
328,8,328,1,25,
328,8,363,4,0,
328,8,364,1,81,
328,8,414,1,65,
328,8,445,4,0,
328,9,16,2,0,
328,9,28,1,9,
328,9,44,1,1,
328,9,63,1,57,
328,9,63,4,0,
328,9,70,4,0,
328,9,76,4,0,
328,9,89,1,73,
328,9,89,4,0,
328,9,90,1,89,
328,9,91,1,41,
328,9,91,4,0,
328,9,92,4,0,
328,9,98,2,0,
328,9,104,4,0,
328,9,116,2,0,
328,9,156,4,0,
328,9,157,4,0,
328,9,164,4,0,
328,9,173,3,0,
328,9,175,2,0,
328,9,182,4,0,
328,9,185,1,17,
328,9,189,3,0,
328,9,201,1,49,
328,9,201,4,0,
328,9,202,4,0,
328,9,203,4,0,
328,9,207,4,0,
328,9,210,2,0,
328,9,210,3,0,
328,9,213,4,0,
328,9,214,4,0,
328,9,216,4,0,
328,9,218,4,0,
328,9,237,4,0,
328,9,241,4,0,
328,9,242,1,33,
328,9,249,4,0,
328,9,263,4,0,
328,9,290,4,0,
328,9,317,4,0,
328,9,328,1,25,
328,9,363,4,0,
328,9,364,1,81,
328,9,414,1,65,
328,9,414,3,0,
328,9,445,4,0,
328,10,16,2,0,
328,10,28,1,9,
328,10,29,3,0,
328,10,44,1,1,
328,10,63,1,57,
328,10,63,4,0,
328,10,70,4,0,
328,10,76,4,0,
328,10,89,1,73,
328,10,89,4,0,
328,10,90,1,89,
328,10,91,1,41,
328,10,91,4,0,
328,10,92,4,0,
328,10,98,2,0,
328,10,104,4,0,
328,10,116,2,0,
328,10,156,4,0,
328,10,157,4,0,
328,10,164,4,0,
328,10,173,3,0,
328,10,175,2,0,
328,10,182,4,0,
328,10,185,1,17,
328,10,189,3,0,
328,10,201,1,49,
328,10,201,4,0,
328,10,202,4,0,
328,10,203,4,0,
328,10,207,4,0,
328,10,210,2,0,
328,10,210,3,0,
328,10,213,4,0,
328,10,214,4,0,
328,10,216,4,0,
328,10,218,4,0,
328,10,237,4,0,
328,10,241,4,0,
328,10,242,1,33,
328,10,249,4,0,
328,10,263,4,0,
328,10,290,4,0,
328,10,317,4,0,
328,10,328,1,25,
328,10,341,2,0,
328,10,363,4,0,
328,10,364,1,81,
328,10,414,1,65,
328,10,414,3,0,
328,10,445,4,0,
328,10,450,3,0,
328,11,16,2,0,
328,11,28,1,9,
328,11,44,1,1,
328,11,63,1,57,
328,11,63,4,0,
328,11,70,4,0,
328,11,76,4,0,
328,11,89,1,73,
328,11,89,4,0,
328,11,90,1,89,
328,11,91,1,41,
328,11,91,4,0,
328,11,92,4,0,
328,11,98,2,0,
328,11,104,4,0,
328,11,116,2,0,
328,11,156,4,0,
328,11,157,4,0,
328,11,164,4,0,
328,11,175,2,0,
328,11,182,4,0,
328,11,185,1,17,
328,11,201,1,49,
328,11,201,4,0,
328,11,203,2,0,
328,11,207,4,0,
328,11,210,2,0,
328,11,213,4,0,
328,11,216,4,0,
328,11,218,4,0,
328,11,237,4,0,
328,11,241,4,0,
328,11,242,1,33,
328,11,249,4,0,
328,11,263,4,0,
328,11,317,4,0,
328,11,324,2,0,
328,11,328,1,25,
328,11,341,2,0,
328,11,364,1,81,
328,11,414,1,65,
328,11,414,2,0,
328,11,450,2,0,
328,11,496,4,0,
328,11,522,4,0,
328,11,523,4,0,
328,12,28,1,9,
328,12,44,1,1,
328,12,63,1,57,
328,12,63,4,0,
328,12,70,4,0,
328,12,76,4,0,
328,12,89,4,0,
328,12,91,1,41,
328,12,91,4,0,
328,12,92,4,0,
328,12,104,4,0,
328,12,156,4,0,
328,12,182,4,0,
328,12,185,1,17,
328,12,201,1,49,
328,12,201,4,0,
328,12,202,4,0,
328,12,213,4,0,
328,12,216,4,0,
328,12,218,4,0,
328,12,237,4,0,
328,12,241,4,0,
328,12,242,1,33,
328,12,249,4,0,
328,12,263,4,0,
328,12,290,4,0,
328,12,317,4,0,
328,12,328,1,25,
328,13,28,1,9,
328,13,34,3,0,
328,13,38,3,0,
328,13,44,1,1,
328,13,63,1,57,
328,13,63,4,0,
328,13,70,4,0,
328,13,76,4,0,
328,13,89,4,0,
328,13,91,1,41,
328,13,91,4,0,
328,13,92,4,0,
328,13,102,3,0,
328,13,104,4,0,
328,13,156,4,0,
328,13,164,3,0,
328,13,182,4,0,
328,13,185,1,17,
328,13,201,1,49,
328,13,201,4,0,
328,13,202,4,0,
328,13,207,3,0,
328,13,213,4,0,
328,13,216,4,0,
328,13,218,4,0,
328,13,237,4,0,
328,13,241,4,0,
328,13,242,1,33,
328,13,249,4,0,
328,13,263,4,0,
328,13,290,4,0,
328,13,317,4,0,
328,13,328,1,25,
328,14,16,2,0,
328,14,28,1,4,
328,14,44,1,1,
328,14,63,1,49,
328,14,63,4,0,
328,14,70,4,0,
328,14,76,4,0,
328,14,89,1,55,
328,14,89,4,0,
328,14,90,1,73,
328,14,91,1,29,
328,14,91,4,0,
328,14,92,4,0,
328,14,98,2,0,
328,14,104,4,0,
328,14,116,2,0,
328,14,117,1,17,
328,14,156,4,0,
328,14,157,1,25,
328,14,157,4,0,
328,14,164,4,0,
328,14,173,3,0,
328,14,175,2,0,
328,14,182,4,0,
328,14,185,1,7,
328,14,189,1,13,
328,14,201,1,44,
328,14,201,4,0,
328,14,202,3,0,
328,14,203,2,0,
328,14,207,4,0,
328,14,210,2,0,
328,14,213,4,0,
328,14,214,3,0,
328,14,216,4,0,
328,14,218,4,0,
328,14,237,4,0,
328,14,241,4,0,
328,14,242,1,34,
328,14,249,4,0,
328,14,263,4,0,
328,14,276,1,67,
328,14,276,3,0,
328,14,317,4,0,
328,14,324,2,0,
328,14,324,3,0,
328,14,328,1,10,
328,14,341,2,0,
328,14,364,1,61,
328,14,414,1,39,
328,14,414,2,0,
328,14,414,3,0,
328,14,450,2,0,
328,14,450,3,0,
328,14,496,4,0,
328,14,522,4,0,
328,14,523,1,21,
328,14,523,4,0,
328,15,16,2,0,
328,15,28,1,4,
328,15,44,1,1,4
328,15,63,1,49,
328,15,63,4,0,
328,15,70,4,0,
328,15,76,4,0,
328,15,89,1,55,
328,15,89,4,0,
328,15,90,1,1,1
328,15,90,1,73,
328,15,91,1,29,
328,15,91,4,0,
328,15,92,4,0,
328,15,98,2,0,
328,15,104,4,0,
328,15,116,2,0,
328,15,117,1,17,
328,15,156,4,0,
328,15,157,1,25,
328,15,157,4,0,
328,15,164,4,0,
328,15,175,2,0,
328,15,182,4,0,
328,15,185,1,7,
328,15,189,1,13,
328,15,201,1,44,
328,15,201,4,0,
328,15,203,2,0,
328,15,207,4,0,
328,15,210,2,0,
328,15,213,4,0,
328,15,214,4,0,
328,15,216,4,0,
328,15,218,4,0,
328,15,237,4,0,
328,15,241,4,0,
328,15,242,1,34,
328,15,249,4,0,
328,15,263,4,0,
328,15,276,1,1,2
328,15,276,1,67,
328,15,317,4,0,
328,15,324,2,0,
328,15,328,1,10,
328,15,341,2,0,
328,15,364,1,1,3
328,15,364,1,61,
328,15,414,1,39,
328,15,414,2,0,
328,15,450,2,0,
328,15,496,4,0,
328,15,522,4,0,
328,15,523,1,21,
328,15,523,4,0,
328,15,590,4,0,
328,16,16,2,0,
328,16,28,1,1,1
328,16,44,1,1,2
328,16,63,1,43,1
328,16,63,4,0,
328,16,70,4,0,
328,16,76,4,0,
328,16,89,1,33,1
328,16,89,4,0,
328,16,90,1,47,1
328,16,91,1,19,1
328,16,91,4,0,
328,16,92,4,0,
328,16,98,2,0,
328,16,104,4,0,
328,16,116,2,0,
328,16,117,1,1,4
328,16,156,4,0,
328,16,157,1,15,1
328,16,157,4,0,
328,16,164,4,0,
328,16,173,3,0,
328,16,175,2,0,
328,16,182,4,0,
328,16,185,1,1,3
328,16,189,1,5,1
328,16,201,1,36,1
328,16,201,4,0,
328,16,202,3,0,
328,16,203,2,0,
328,16,207,4,0,
328,16,210,2,0,
328,16,213,4,0,
328,16,214,4,0,
328,16,216,4,0,
328,16,218,4,0,
328,16,237,4,0,
328,16,241,4,0,
328,16,242,1,22,1
328,16,249,4,0,
328,16,263,4,0,
328,16,276,1,40,1
328,16,276,3,0,
328,16,290,4,0,
328,16,317,4,0,
328,16,324,2,0,
328,16,324,3,0,
328,16,328,1,12,1
328,16,341,2,0,
328,16,364,1,29,1
328,16,414,1,26,1
328,16,414,2,0,
328,16,414,3,0,
328,16,450,2,0,
328,16,450,3,0,
328,16,496,4,0,
328,16,522,4,0,
328,16,523,1,8,1
328,16,523,4,0,
328,16,590,4,0,
328,17,16,2,0,
328,17,28,1,1,1
328,17,44,1,1,2
328,17,63,1,43,
328,17,63,4,0,
328,17,76,4,0,
328,17,89,1,33,
328,17,89,4,0,
328,17,90,1,47,
328,17,91,1,19,
328,17,92,4,0,
328,17,98,2,0,
328,17,104,4,0,
328,17,116,2,0,
328,17,117,1,1,4
328,17,156,4,0,
328,17,157,1,15,
328,17,157,4,0,
328,17,164,4,0,
328,17,175,2,0,
328,17,182,4,0,
328,17,185,1,1,3
328,17,189,1,5,
328,17,201,1,36,
328,17,201,4,0,
328,17,203,2,0,
328,17,207,4,0,
328,17,210,2,0,
328,17,213,4,0,
328,17,214,4,0,
328,17,216,4,0,
328,17,218,4,0,
328,17,237,4,0,
328,17,241,4,0,
328,17,242,1,22,
328,17,263,4,0,
328,17,276,1,40,
328,17,317,4,0,
328,17,324,2,0,
328,17,328,1,12,
328,17,341,2,0,
328,17,364,1,29,
328,17,414,1,26,
328,17,414,2,0,
328,17,450,2,0,
328,17,496,4,0,
328,17,523,1,8,
328,17,523,4,0,
328,17,590,4,0,
328,18,16,2,0,
328,18,28,1,1,1
328,18,44,1,1,2
328,18,63,1,43,
328,18,63,4,0,
328,18,76,4,0,
328,18,89,1,33,
328,18,89,4,0,
328,18,90,1,47,
328,18,91,1,19,
328,18,92,4,0,
328,18,98,2,0,
328,18,104,4,0,
328,18,116,2,0,
328,18,117,1,1,4
328,18,156,4,0,
328,18,157,1,15,
328,18,157,4,0,
328,18,164,4,0,
328,18,175,2,0,
328,18,182,4,0,
328,18,185,1,1,3
328,18,189,1,5,
328,18,201,1,36,
328,18,201,4,0,
328,18,203,2,0,
328,18,207,4,0,
328,18,210,2,0,
328,18,213,4,0,
328,18,214,4,0,
328,18,216,4,0,
328,18,218,4,0,
328,18,237,4,0,
328,18,241,4,0,
328,18,242,1,22,
328,18,263,4,0,
328,18,276,1,40,
328,18,317,4,0,
328,18,324,2,0,
328,18,328,1,12,
328,18,341,2,0,
328,18,364,1,29,
328,18,414,1,26,
328,18,414,2,0,
328,18,450,2,0,
328,18,496,4,0,
328,18,523,1,8,
328,18,523,4,0,
328,18,590,4,0,
329,5,19,4,0,
329,5,28,1,1,2
329,5,28,1,9,
329,5,44,1,1,1
329,5,63,1,57,
329,5,63,4,0,
329,5,70,4,0,
329,5,76,4,0,
329,5,89,4,0,
329,5,91,4,0,
329,5,92,4,0,
329,5,103,1,41,
329,5,104,4,0,
329,5,156,4,0,
329,5,182,4,0,
329,5,185,1,1,3
329,5,185,1,17,
329,5,201,1,49,
329,5,201,4,0,
329,5,202,4,0,
329,5,211,4,0,
329,5,213,4,0,
329,5,216,4,0,
329,5,218,4,0,
329,5,225,1,35,
329,5,237,4,0,
329,5,241,4,0,
329,5,242,1,33,
329,5,249,4,0,
329,5,263,4,0,
329,5,290,4,0,
329,5,317,4,0,
329,5,328,1,1,4
329,5,328,1,25,
329,6,19,4,0,
329,6,28,1,1,2
329,6,28,1,9,
329,6,34,3,0,
329,6,38,3,0,
329,6,44,1,1,1
329,6,63,1,57,
329,6,63,4,0,
329,6,70,4,0,
329,6,76,4,0,
329,6,89,4,0,
329,6,91,4,0,
329,6,92,4,0,
329,6,102,3,0,
329,6,103,1,41,
329,6,104,4,0,
329,6,129,3,0,
329,6,156,4,0,
329,6,157,3,0,
329,6,164,3,0,
329,6,173,3,0,
329,6,182,4,0,
329,6,185,1,1,3
329,6,185,1,17,
329,6,189,3,0,
329,6,201,1,49,
329,6,201,4,0,
329,6,202,4,0,
329,6,203,3,0,
329,6,207,3,0,
329,6,211,4,0,
329,6,213,4,0,
329,6,214,3,0,
329,6,216,4,0,
329,6,218,4,0,
329,6,225,1,35,
329,6,237,4,0,
329,6,241,4,0,
329,6,242,1,33,
329,6,249,4,0,
329,6,263,4,0,
329,6,290,4,0,
329,6,317,4,0,
329,6,328,1,1,4
329,6,328,1,25,
329,7,19,4,0,
329,7,28,1,1,2
329,7,28,1,9,
329,7,34,3,0,
329,7,38,3,0,
329,7,44,1,1,1
329,7,63,1,57,
329,7,63,4,0,
329,7,70,4,0,
329,7,76,4,0,
329,7,89,4,0,
329,7,91,4,0,
329,7,92,4,0,
329,7,102,3,0,
329,7,103,1,41,
329,7,104,4,0,
329,7,156,4,0,
329,7,157,3,0,
329,7,164,3,0,
329,7,182,4,0,
329,7,185,1,1,3
329,7,185,1,17,
329,7,201,1,49,
329,7,201,4,0,
329,7,202,4,0,
329,7,211,4,0,
329,7,213,4,0,
329,7,216,4,0,
329,7,218,4,0,
329,7,225,1,35,
329,7,237,4,0,
329,7,241,4,0,
329,7,242,1,33,
329,7,249,4,0,
329,7,263,4,0,
329,7,290,4,0,
329,7,317,4,0,
329,7,328,1,1,4
329,7,328,1,25,
329,8,19,4,0,
329,8,28,1,1,2
329,8,28,1,9,
329,8,48,1,33,
329,8,49,1,1,1
329,8,63,1,57,
329,8,63,4,0,
329,8,70,4,0,
329,8,76,4,0,
329,8,89,4,0,
329,8,91,4,0,
329,8,92,4,0,
329,8,103,1,41,
329,8,104,4,0,
329,8,156,4,0,
329,8,157,4,0,
329,8,164,4,0,
329,8,182,4,0,
329,8,185,1,1,3
329,8,185,1,17,
329,8,201,1,49,
329,8,201,4,0,
329,8,202,4,0,
329,8,203,4,0,
329,8,207,4,0,
329,8,211,4,0,
329,8,213,4,0,
329,8,214,4,0,
329,8,216,4,0,
329,8,218,4,0,
329,8,225,1,35,
329,8,237,4,0,
329,8,241,4,0,
329,8,249,4,0,
329,8,263,4,0,
329,8,290,4,0,
329,8,317,4,0,
329,8,318,4,0,
329,8,328,1,1,4
329,8,328,1,25,
329,8,355,4,0,
329,8,363,4,0,
329,8,369,4,0,
329,8,406,4,0,
329,8,432,4,0,
329,8,434,3,0,
329,8,445,4,0,
329,9,19,4,0,
329,9,28,1,1,2
329,9,28,1,9,
329,9,48,1,33,
329,9,49,1,1,1
329,9,63,1,57,
329,9,63,4,0,
329,9,70,4,0,
329,9,76,4,0,
329,9,89,4,0,
329,9,91,4,0,
329,9,92,4,0,
329,9,103,1,41,
329,9,104,4,0,
329,9,129,3,0,
329,9,156,4,0,
329,9,157,4,0,
329,9,164,4,0,
329,9,173,3,0,
329,9,182,4,0,
329,9,185,1,1,3
329,9,185,1,17,
329,9,189,3,0,
329,9,200,3,0,
329,9,201,1,49,
329,9,201,4,0,
329,9,202,4,0,
329,9,203,4,0,
329,9,207,4,0,
329,9,210,3,0,
329,9,211,4,0,
329,9,213,4,0,
329,9,214,4,0,
329,9,216,4,0,
329,9,218,4,0,
329,9,225,1,35,
329,9,237,4,0,
329,9,239,3,0,
329,9,241,4,0,
329,9,249,4,0,
329,9,257,3,0,
329,9,263,4,0,
329,9,290,4,0,
329,9,314,3,0,
329,9,317,4,0,
329,9,318,4,0,
329,9,328,1,1,4
329,9,328,1,25,
329,9,355,4,0,
329,9,363,4,0,
329,9,369,4,0,
329,9,406,4,0,
329,9,414,3,0,
329,9,432,4,0,
329,9,434,3,0,
329,9,445,4,0,
329,9,466,3,0,
329,10,19,4,0,
329,10,28,1,1,2
329,10,28,1,9,
329,10,29,3,0,
329,10,48,1,33,
329,10,49,1,1,1
329,10,63,1,57,
329,10,63,4,0,
329,10,70,4,0,
329,10,76,4,0,
329,10,89,4,0,
329,10,91,4,0,
329,10,92,4,0,
329,10,103,1,41,
329,10,104,4,0,
329,10,129,3,0,
329,10,156,4,0,
329,10,157,4,0,
329,10,164,4,0,
329,10,173,3,0,
329,10,182,4,0,
329,10,185,1,1,3
329,10,185,1,17,
329,10,189,3,0,
329,10,200,3,0,
329,10,201,1,49,
329,10,201,4,0,
329,10,202,4,0,
329,10,203,4,0,
329,10,207,4,0,
329,10,210,3,0,
329,10,211,4,0,
329,10,213,4,0,
329,10,214,4,0,
329,10,216,4,0,
329,10,218,4,0,
329,10,225,1,35,
329,10,237,4,0,
329,10,239,3,0,
329,10,241,4,0,
329,10,249,4,0,
329,10,257,3,0,
329,10,263,4,0,
329,10,290,4,0,
329,10,314,3,0,
329,10,317,4,0,
329,10,318,4,0,
329,10,328,1,1,4
329,10,328,1,25,
329,10,355,4,0,
329,10,363,4,0,
329,10,366,3,0,
329,10,369,4,0,
329,10,406,4,0,
329,10,414,3,0,
329,10,434,3,0,
329,10,445,4,0,
329,10,450,3,0,
329,10,466,3,0,
329,11,19,4,0,
329,11,28,1,1,2
329,11,28,1,9,
329,11,48,1,33,
329,11,49,1,1,1
329,11,63,1,57,
329,11,63,4,0,
329,11,70,4,0,
329,11,76,4,0,
329,11,89,4,0,
329,11,91,4,0,
329,11,92,4,0,
329,11,103,1,41,
329,11,104,4,0,
329,11,156,4,0,
329,11,157,4,0,
329,11,164,4,0,
329,11,182,4,0,
329,11,185,1,1,3
329,11,185,1,17,
329,11,201,1,49,
329,11,201,4,0,
329,11,207,4,0,
329,11,213,4,0,
329,11,216,4,0,
329,11,218,4,0,
329,11,225,1,35,
329,11,237,4,0,
329,11,241,4,0,
329,11,249,4,0,
329,11,263,4,0,
329,11,317,4,0,
329,11,328,1,1,4
329,11,328,1,25,
329,11,369,4,0,
329,11,434,3,0,
329,11,496,4,0,
329,11,522,4,0,
329,11,523,4,0,
329,12,19,4,0,
329,12,28,1,1,2
329,12,28,1,9,
329,12,44,1,1,1
329,12,63,1,57,
329,12,63,4,0,
329,12,70,4,0,
329,12,76,4,0,
329,12,89,4,0,
329,12,91,4,0,
329,12,92,4,0,
329,12,103,1,41,
329,12,104,4,0,
329,12,156,4,0,
329,12,182,4,0,
329,12,185,1,1,3
329,12,185,1,17,
329,12,201,1,49,
329,12,201,4,0,
329,12,202,4,0,
329,12,211,4,0,
329,12,213,4,0,
329,12,216,4,0,
329,12,218,4,0,
329,12,225,1,35,
329,12,237,4,0,
329,12,241,4,0,
329,12,242,1,33,
329,12,249,4,0,
329,12,263,4,0,
329,12,290,4,0,
329,12,317,4,0,
329,12,328,1,1,4
329,12,328,1,25,
329,13,19,4,0,
329,13,28,1,1,2
329,13,28,1,9,
329,13,34,3,0,
329,13,38,3,0,
329,13,44,1,1,1
329,13,63,1,57,
329,13,63,4,0,
329,13,70,4,0,
329,13,76,4,0,
329,13,89,4,0,
329,13,91,4,0,
329,13,92,4,0,
329,13,102,3,0,
329,13,103,1,41,
329,13,104,4,0,
329,13,156,4,0,
329,13,164,3,0,
329,13,182,4,0,
329,13,185,1,1,3
329,13,185,1,17,
329,13,201,1,49,
329,13,201,4,0,
329,13,202,4,0,
329,13,207,3,0,
329,13,211,4,0,
329,13,213,4,0,
329,13,216,4,0,
329,13,218,4,0,
329,13,225,1,35,
329,13,237,4,0,
329,13,241,4,0,
329,13,242,1,33,
329,13,249,4,0,
329,13,263,4,0,
329,13,290,4,0,
329,13,317,4,0,
329,13,328,1,1,4
329,13,328,1,25,
329,14,19,4,0,
329,14,28,1,1,2
329,14,28,1,4,
329,14,48,1,29,
329,14,49,1,1,1
329,14,63,1,49,
329,14,63,4,0,
329,14,70,4,0,
329,14,76,4,0,
329,14,89,4,0,
329,14,91,4,0,
329,14,92,4,0,
329,14,103,1,34,
329,14,104,4,0,
329,14,117,1,17,
329,14,156,4,0,
329,14,157,1,25,
329,14,157,4,0,
329,14,164,4,0,
329,14,173,3,0,
329,14,182,4,0,
329,14,185,1,1,3
329,14,185,1,7,
329,14,189,1,13,
329,14,200,3,0,
329,14,201,1,44,
329,14,201,4,0,
329,14,202,3,0,
329,14,207,4,0,
329,14,213,4,0,
329,14,214,3,0,
329,14,216,4,0,
329,14,218,4,0,
329,14,225,1,35,
329,14,237,4,0,
329,14,241,4,0,
329,14,249,4,0,
329,14,257,3,0,
329,14,263,4,0,
329,14,276,3,0,
329,14,317,4,0,
329,14,324,3,0,
329,14,328,1,1,4
329,14,328,1,10,
329,14,355,3,0,
329,14,366,3,0,
329,14,369,4,0,
329,14,406,3,0,
329,14,414,1,39,
329,14,414,3,0,
329,14,434,3,0,
329,14,450,3,0,
329,14,496,4,0,
329,14,522,4,0,
329,14,523,1,21,
329,14,523,4,0,
329,15,19,4,0,
329,15,28,1,1,2
329,15,28,1,4,
329,15,48,1,29,
329,15,49,1,1,1
329,15,63,1,49,
329,15,63,4,0,
329,15,70,4,0,
329,15,76,4,0,
329,15,89,4,0,
329,15,91,4,0,
329,15,92,4,0,
329,15,103,1,34,
329,15,104,4,0,
329,15,117,1,17,
329,15,156,4,0,
329,15,157,1,25,
329,15,157,4,0,
329,15,164,4,0,
329,15,182,4,0,
329,15,185,1,1,3
329,15,185,1,7,
329,15,189,1,13,
329,15,201,1,44,
329,15,201,4,0,
329,15,207,4,0,
329,15,211,4,0,
329,15,213,4,0,
329,15,214,4,0,
329,15,216,4,0,
329,15,218,4,0,
329,15,225,1,35,
329,15,237,4,0,
329,15,241,4,0,
329,15,249,4,0,
329,15,263,4,0,
329,15,317,4,0,
329,15,328,1,1,4
329,15,328,1,10,
329,15,355,4,0,
329,15,369,4,0,
329,15,414,1,39,
329,15,434,3,0,
329,15,496,4,0,
329,15,522,4,0,
329,15,523,1,21,
329,15,523,4,0,
329,15,590,4,0,
329,16,19,4,0,
329,16,28,1,1,1
329,16,48,1,19,1
329,16,49,1,1,2
329,16,63,1,43,1
329,16,63,4,0,
329,16,70,4,0,
329,16,76,4,0,
329,16,89,1,33,1
329,16,89,4,0,
329,16,91,4,0,
329,16,92,4,0,
329,16,103,1,22,1
329,16,104,4,0,
329,16,117,1,1,4
329,16,156,4,0,
329,16,157,1,15,1
329,16,157,4,0,
329,16,164,4,0,
329,16,173,3,0,
329,16,182,4,0,
329,16,185,1,1,3
329,16,189,1,5,1
329,16,200,3,0,
329,16,201,1,36,1
329,16,201,4,0,
329,16,202,3,0,
329,16,207,4,0,
329,16,211,4,0,
329,16,213,4,0,
329,16,214,4,0,
329,16,216,4,0,
329,16,218,4,0,
329,16,225,1,35,1
329,16,237,4,0,
329,16,241,4,0,
329,16,249,4,0,
329,16,253,1,40,1
329,16,253,3,0,
329,16,257,3,0,
329,16,263,4,0,
329,16,276,3,0,
329,16,290,4,0,
329,16,317,4,0,
329,16,324,3,0,
329,16,328,1,12,1
329,16,355,4,0,
329,16,366,3,0,
329,16,369,4,0,
329,16,405,1,29,1
329,16,406,3,0,
329,16,414,1,26,1
329,16,414,3,0,
329,16,434,3,0,
329,16,450,3,0,
329,16,496,4,0,
329,16,522,4,0,
329,16,523,1,8,1
329,16,523,4,0,
329,16,586,1,47,1
329,16,590,4,0,
329,17,19,4,0,
329,17,28,1,1,2
329,17,48,1,19,
329,17,49,1,1,3
329,17,63,1,43,
329,17,63,4,0,
329,17,76,4,0,
329,17,89,1,33,
329,17,89,4,0,
329,17,92,4,0,
329,17,103,1,22,
329,17,104,4,0,
329,17,117,1,1,5
329,17,156,4,0,
329,17,157,1,15,
329,17,157,4,0,
329,17,164,4,0,
329,17,182,4,0,
329,17,185,1,1,4
329,17,189,1,5,
329,17,201,1,36,
329,17,201,4,0,
329,17,207,4,0,
329,17,211,4,0,
329,17,213,4,0,
329,17,214,4,0,
329,17,216,4,0,
329,17,218,4,0,
329,17,225,1,0,
329,17,225,1,1,1
329,17,237,4,0,
329,17,241,4,0,
329,17,253,1,40,
329,17,263,4,0,
329,17,317,4,0,
329,17,328,1,12,
329,17,355,4,0,
329,17,369,4,0,
329,17,405,1,29,
329,17,414,1,26,
329,17,434,3,0,
329,17,496,4,0,
329,17,523,1,8,
329,17,523,4,0,
329,17,586,1,47,
329,17,590,4,0,
329,18,19,4,0,
329,18,28,1,1,2
329,18,48,1,19,
329,18,49,1,1,3
329,18,63,1,43,
329,18,63,4,0,
329,18,76,4,0,
329,18,89,1,33,
329,18,89,4,0,
329,18,92,4,0,
329,18,103,1,22,
329,18,104,4,0,
329,18,117,1,1,5
329,18,156,4,0,
329,18,157,1,15,
329,18,157,4,0,
329,18,164,4,0,
329,18,182,4,0,
329,18,185,1,1,4
329,18,189,1,5,
329,18,201,1,36,
329,18,201,4,0,
329,18,207,4,0,
329,18,211,4,0,
329,18,213,4,0,
329,18,214,4,0,
329,18,216,4,0,
329,18,218,4,0,
329,18,225,1,0,
329,18,225,1,1,1
329,18,237,4,0,
329,18,241,4,0,
329,18,253,1,40,
329,18,263,4,0,
329,18,317,4,0,
329,18,328,1,12,
329,18,355,4,0,
329,18,369,4,0,
329,18,405,1,29,
329,18,414,1,26,
329,18,434,3,0,
329,18,496,4,0,
329,18,523,1,8,
329,18,523,4,0,
329,18,586,1,47,
329,18,590,4,0,
330,5,19,4,0,
330,5,28,1,1,2
330,5,28,1,9,
330,5,44,1,1,1
330,5,53,4,0,
330,5,63,1,65,
330,5,63,4,0,
330,5,70,4,0,
330,5,76,4,0,
330,5,89,4,0,
330,5,91,4,0,
330,5,92,4,0,
330,5,103,1,41,
330,5,104,4,0,
330,5,126,4,0,
330,5,156,4,0,
330,5,182,4,0,
330,5,185,1,1,3
330,5,185,1,17,
330,5,201,1,53,
330,5,201,4,0,
330,5,202,4,0,
330,5,211,4,0,
330,5,213,4,0,
330,5,216,4,0,
330,5,218,4,0,
330,5,225,1,35,
330,5,231,4,0,
330,5,237,4,0,
330,5,241,4,0,
330,5,242,1,33,
330,5,249,4,0,
330,5,263,4,0,
330,5,290,4,0,
330,5,317,4,0,
330,5,328,1,1,4
330,5,328,1,25,
330,5,337,4,0,
330,6,7,3,0,
330,6,19,4,0,
330,6,28,1,1,2
330,6,28,1,9,
330,6,34,3,0,
330,6,38,3,0,
330,6,44,1,1,1
330,6,53,4,0,
330,6,63,1,65,
330,6,63,4,0,
330,6,70,4,0,
330,6,76,4,0,
330,6,89,4,0,
330,6,91,4,0,
330,6,92,4,0,
330,6,102,3,0,
330,6,103,1,41,
330,6,104,4,0,
330,6,126,4,0,
330,6,129,3,0,
330,6,156,4,0,
330,6,157,3,0,
330,6,164,3,0,
330,6,173,3,0,
330,6,182,4,0,
330,6,185,1,1,3
330,6,185,1,17,
330,6,189,3,0,
330,6,201,1,53,
330,6,201,4,0,
330,6,202,4,0,
330,6,203,3,0,
330,6,207,3,0,
330,6,210,3,0,
330,6,211,4,0,
330,6,213,4,0,
330,6,214,3,0,
330,6,216,4,0,
330,6,218,4,0,
330,6,225,1,35,
330,6,231,4,0,
330,6,237,4,0,
330,6,241,4,0,
330,6,242,1,33,
330,6,249,4,0,
330,6,263,4,0,
330,6,290,4,0,
330,6,317,4,0,
330,6,328,1,1,4
330,6,328,1,25,
330,6,337,4,0,
330,7,19,4,0,
330,7,28,1,1,2
330,7,28,1,9,
330,7,34,3,0,
330,7,38,3,0,
330,7,44,1,1,1
330,7,53,4,0,
330,7,63,1,65,
330,7,63,4,0,
330,7,70,4,0,
330,7,76,4,0,
330,7,89,4,0,
330,7,91,4,0,
330,7,92,4,0,
330,7,102,3,0,
330,7,103,1,41,
330,7,104,4,0,
330,7,126,4,0,
330,7,156,4,0,
330,7,157,3,0,
330,7,164,3,0,
330,7,182,4,0,
330,7,185,1,1,3
330,7,185,1,17,
330,7,201,1,53,
330,7,201,4,0,
330,7,202,4,0,
330,7,211,4,0,
330,7,213,4,0,
330,7,216,4,0,
330,7,218,4,0,
330,7,225,1,35,
330,7,231,4,0,
330,7,237,4,0,
330,7,241,4,0,
330,7,242,1,33,
330,7,249,4,0,
330,7,263,4,0,
330,7,290,4,0,
330,7,317,4,0,
330,7,328,1,1,4
330,7,328,1,25,
330,7,337,4,0,
330,8,19,4,0,
330,8,28,1,1,2
330,8,28,1,9,
330,8,48,1,33,
330,8,49,1,1,1
330,8,53,4,0,
330,8,63,1,57,
330,8,63,4,0,
330,8,70,4,0,
330,8,76,4,0,
330,8,89,4,0,
330,8,91,4,0,
330,8,92,4,0,
330,8,103,1,41,
330,8,104,4,0,
330,8,126,4,0,
330,8,156,4,0,
330,8,157,4,0,
330,8,164,4,0,
330,8,182,4,0,
330,8,185,1,1,3
330,8,185,1,17,
330,8,201,1,49,
330,8,201,4,0,
330,8,202,4,0,
330,8,203,4,0,
330,8,207,4,0,
330,8,211,4,0,
330,8,213,4,0,
330,8,214,4,0,
330,8,216,4,0,
330,8,218,4,0,
330,8,225,1,35,
330,8,231,4,0,
330,8,237,4,0,
330,8,241,4,0,
330,8,249,4,0,
330,8,263,4,0,
330,8,290,4,0,
330,8,317,4,0,
330,8,318,4,0,
330,8,328,1,1,4
330,8,328,1,25,
330,8,332,4,0,
330,8,337,1,45,
330,8,337,4,0,
330,8,355,4,0,
330,8,363,4,0,
330,8,369,4,0,
330,8,406,4,0,
330,8,416,4,0,
330,8,432,4,0,
330,8,434,3,0,
330,8,444,4,0,
330,8,445,4,0,
330,9,7,3,0,
330,9,9,3,0,
330,9,19,4,0,
330,9,28,1,1,2
330,9,28,1,9,
330,9,48,1,33,
330,9,49,1,1,1
330,9,53,4,0,
330,9,63,1,57,
330,9,63,4,0,
330,9,70,4,0,
330,9,76,4,0,
330,9,89,4,0,
330,9,91,4,0,
330,9,92,4,0,
330,9,103,1,41,
330,9,104,4,0,
330,9,126,4,0,
330,9,129,3,0,
330,9,156,4,0,
330,9,157,4,0,
330,9,164,4,0,
330,9,173,3,0,
330,9,182,4,0,
330,9,185,1,1,3
330,9,185,1,17,
330,9,189,3,0,
330,9,200,3,0,
330,9,201,1,49,
330,9,201,4,0,
330,9,202,4,0,
330,9,203,4,0,
330,9,207,4,0,
330,9,210,3,0,
330,9,211,4,0,
330,9,213,4,0,
330,9,214,4,0,
330,9,216,4,0,
330,9,218,4,0,
330,9,225,1,35,
330,9,231,4,0,
330,9,237,4,0,
330,9,239,3,0,
330,9,241,4,0,
330,9,249,4,0,
330,9,257,3,0,
330,9,263,4,0,
330,9,290,4,0,
330,9,314,3,0,
330,9,317,4,0,
330,9,318,4,0,
330,9,328,1,1,4
330,9,328,1,25,
330,9,332,4,0,
330,9,337,1,45,
330,9,337,4,0,
330,9,355,4,0,
330,9,363,4,0,
330,9,369,4,0,
330,9,406,4,0,
330,9,414,3,0,
330,9,416,4,0,
330,9,432,4,0,
330,9,434,3,0,
330,9,444,4,0,
330,9,445,4,0,
330,9,466,3,0,
330,10,7,3,0,
330,10,9,3,0,
330,10,19,4,0,
330,10,28,1,1,2
330,10,28,1,9,
330,10,29,3,0,
330,10,48,1,33,
330,10,49,1,1,1
330,10,53,4,0,
330,10,63,1,57,
330,10,63,4,0,
330,10,70,4,0,
330,10,76,4,0,
330,10,89,4,0,
330,10,91,4,0,
330,10,92,4,0,
330,10,103,1,41,
330,10,104,4,0,
330,10,126,4,0,
330,10,129,3,0,
330,10,156,4,0,
330,10,157,4,0,
330,10,164,4,0,
330,10,173,3,0,
330,10,182,4,0,
330,10,185,1,1,3
330,10,185,1,17,
330,10,189,3,0,
330,10,200,3,0,
330,10,201,1,49,
330,10,201,4,0,
330,10,202,4,0,
330,10,203,4,0,
330,10,207,4,0,
330,10,210,3,0,
330,10,211,4,0,
330,10,213,4,0,
330,10,214,4,0,
330,10,216,4,0,
330,10,218,4,0,
330,10,225,1,35,
330,10,231,4,0,
330,10,237,4,0,
330,10,239,3,0,
330,10,241,4,0,
330,10,249,4,0,
330,10,257,3,0,
330,10,263,4,0,
330,10,290,4,0,
330,10,314,3,0,
330,10,317,4,0,
330,10,318,4,0,
330,10,328,1,1,4
330,10,328,1,25,
330,10,332,4,0,
330,10,337,1,45,
330,10,337,4,0,
330,10,355,4,0,
330,10,363,4,0,
330,10,366,3,0,
330,10,369,4,0,
330,10,406,4,0,
330,10,414,3,0,
330,10,416,4,0,
330,10,434,3,0,
330,10,444,4,0,
330,10,445,4,0,
330,10,450,3,0,
330,10,466,3,0,
330,11,19,4,0,
330,11,28,1,1,2
330,11,28,1,9,
330,11,48,1,33,
330,11,49,1,1,1
330,11,53,4,0,
330,11,63,1,57,
330,11,63,4,0,
330,11,70,4,0,
330,11,76,4,0,
330,11,89,4,0,
330,11,91,4,0,
330,11,92,4,0,
330,11,103,1,41,
330,11,104,4,0,
330,11,126,4,0,
330,11,156,4,0,
330,11,157,4,0,
330,11,164,4,0,
330,11,182,4,0,
330,11,185,1,1,3
330,11,185,1,17,
330,11,201,1,49,
330,11,201,4,0,
330,11,207,4,0,
330,11,213,4,0,
330,11,216,4,0,
330,11,218,4,0,
330,11,225,1,35,
330,11,237,4,0,
330,11,241,4,0,
330,11,249,4,0,
330,11,263,4,0,
330,11,317,4,0,
330,11,328,1,1,4
330,11,328,1,25,
330,11,332,4,0,
330,11,337,1,45,
330,11,337,4,0,
330,11,369,4,0,
330,11,416,4,0,
330,11,434,3,0,
330,11,444,4,0,
330,11,468,4,0,
330,11,496,4,0,
330,11,510,4,0,
330,11,522,4,0,
330,11,523,4,0,
330,11,525,1,65,
330,11,525,4,0,
330,12,19,4,0,
330,12,28,1,1,2
330,12,28,1,9,
330,12,44,1,1,1
330,12,53,4,0,
330,12,63,1,65,
330,12,63,4,0,
330,12,70,4,0,
330,12,76,4,0,
330,12,89,4,0,
330,12,91,4,0,
330,12,92,4,0,
330,12,103,1,41,
330,12,104,4,0,
330,12,126,4,0,
330,12,156,4,0,
330,12,182,4,0,
330,12,185,1,1,3
330,12,185,1,17,
330,12,201,1,53,
330,12,201,4,0,
330,12,202,4,0,
330,12,211,4,0,
330,12,213,4,0,
330,12,216,4,0,
330,12,218,4,0,
330,12,225,1,35,
330,12,231,4,0,
330,12,237,4,0,
330,12,241,4,0,
330,12,242,1,33,
330,12,249,4,0,
330,12,263,4,0,
330,12,290,4,0,
330,12,317,4,0,
330,12,328,1,1,4
330,12,328,1,25,
330,12,337,4,0,
330,13,19,4,0,
330,13,28,1,1,2
330,13,28,1,9,
330,13,34,3,0,
330,13,38,3,0,
330,13,44,1,1,1
330,13,53,4,0,
330,13,63,1,65,
330,13,63,4,0,
330,13,70,4,0,
330,13,76,4,0,
330,13,89,4,0,
330,13,91,4,0,
330,13,92,4,0,
330,13,102,3,0,
330,13,103,1,41,
330,13,104,4,0,
330,13,126,4,0,
330,13,156,4,0,
330,13,164,3,0,
330,13,182,4,0,
330,13,185,1,1,3
330,13,185,1,17,
330,13,201,1,53,
330,13,201,4,0,
330,13,202,4,0,
330,13,207,3,0,
330,13,211,4,0,
330,13,213,4,0,
330,13,216,4,0,
330,13,218,4,0,
330,13,225,1,35,
330,13,231,4,0,
330,13,237,4,0,
330,13,241,4,0,
330,13,242,1,33,
330,13,249,4,0,
330,13,263,4,0,
330,13,290,4,0,
330,13,317,4,0,
330,13,328,1,1,4
330,13,328,1,25,
330,13,337,4,0,
330,14,7,3,0,
330,14,9,3,0,
330,14,19,4,0,
330,14,28,1,1,2
330,14,28,1,4,
330,14,48,1,29,
330,14,49,1,1,1
330,14,53,4,0,
330,14,63,1,49,
330,14,63,4,0,
330,14,70,4,0,
330,14,76,4,0,
330,14,89,4,0,
330,14,91,4,0,
330,14,92,4,0,
330,14,103,1,34,
330,14,104,4,0,
330,14,117,1,17,
330,14,126,4,0,
330,14,156,4,0,
330,14,157,1,25,
330,14,157,4,0,
330,14,164,4,0,
330,14,173,3,0,
330,14,182,4,0,
330,14,185,1,1,3
330,14,185,1,7,
330,14,189,1,13,
330,14,200,3,0,
330,14,201,1,44,
330,14,201,4,0,
330,14,202,3,0,
330,14,207,4,0,
330,14,213,4,0,
330,14,214,3,0,
330,14,216,4,0,
330,14,218,4,0,
330,14,225,1,35,
330,14,231,3,0,
330,14,237,4,0,
330,14,241,4,0,
330,14,249,4,0,
330,14,257,3,0,
330,14,263,4,0,
330,14,276,3,0,
330,14,317,4,0,
330,14,324,3,0,
330,14,328,1,1,4
330,14,328,1,10,
330,14,332,4,0,
330,14,337,1,55,
330,14,337,4,0,
330,14,355,3,0,
330,14,366,3,0,
330,14,369,4,0,
330,14,406,3,0,
330,14,414,1,39,
330,14,414,3,0,
330,14,416,4,0,
330,14,434,3,0,
330,14,444,4,0,
330,14,450,3,0,
330,14,468,4,0,
330,14,496,4,0,
330,14,510,4,0,
330,14,522,4,0,
330,14,523,1,21,
330,14,523,4,0,
330,14,525,1,45,
330,14,525,4,0,
330,15,19,4,0,
330,15,28,1,1,2
330,15,28,1,4,
330,15,48,1,29,
330,15,49,1,1,1
330,15,53,4,0,
330,15,63,1,49,
330,15,63,4,0,
330,15,70,4,0,
330,15,76,4,0,
330,15,89,4,0,
330,15,91,4,0,
330,15,92,4,0,
330,15,103,1,34,
330,15,104,4,0,
330,15,117,1,17,
330,15,126,4,0,
330,15,156,4,0,
330,15,157,1,25,
330,15,157,4,0,
330,15,164,4,0,
330,15,182,4,0,
330,15,185,1,1,3
330,15,185,1,7,
330,15,189,1,13,
330,15,201,1,44,
330,15,201,4,0,
330,15,207,4,0,
330,15,211,4,0,
330,15,213,4,0,
330,15,214,4,0,
330,15,216,4,0,
330,15,218,4,0,
330,15,225,1,35,
330,15,237,4,0,
330,15,241,4,0,
330,15,249,4,0,
330,15,263,4,0,
330,15,317,4,0,
330,15,328,1,1,4
330,15,328,1,10,
330,15,332,4,0,
330,15,337,1,55,
330,15,337,4,0,
330,15,355,4,0,
330,15,369,4,0,
330,15,414,1,39,
330,15,416,4,0,
330,15,434,3,0,
330,15,444,4,0,
330,15,468,4,0,
330,15,496,4,0,
330,15,510,4,0,
330,15,522,4,0,
330,15,523,1,21,
330,15,523,4,0,
330,15,525,1,45,
330,15,525,4,0,
330,15,590,4,0,
330,15,612,4,0,
330,16,7,3,0,
330,16,9,3,0,
330,16,19,4,0,
330,16,28,1,1,1
330,16,48,1,19,1
330,16,49,1,1,2
330,16,53,4,0,
330,16,63,1,43,1
330,16,63,4,0,
330,16,70,4,0,
330,16,76,4,0,
330,16,89,1,33,1
330,16,89,4,0,
330,16,91,4,0,
330,16,92,4,0,
330,16,103,1,22,1
330,16,104,4,0,
330,16,117,1,1,4
330,16,126,4,0,
330,16,156,4,0,
330,16,157,1,15,1
330,16,157,4,0,
330,16,164,4,0,
330,16,173,3,0,
330,16,182,4,0,
330,16,185,1,1,3
330,16,189,1,5,1
330,16,200,3,0,
330,16,201,1,36,1
330,16,201,4,0,
330,16,202,3,0,
330,16,207,4,0,
330,16,211,4,0,
330,16,213,4,0,
330,16,214,4,0,
330,16,216,4,0,
330,16,218,4,0,
330,16,225,1,35,1
330,16,231,3,0,
330,16,237,4,0,
330,16,241,4,0,
330,16,249,4,0,
330,16,253,1,40,1
330,16,253,3,0,
330,16,257,3,0,
330,16,263,4,0,
330,16,276,3,0,
330,16,290,4,0,
330,16,317,4,0,
330,16,324,3,0,
330,16,328,1,12,1
330,16,332,4,0,
330,16,337,1,45,1
330,16,337,4,0,
330,16,355,4,0,
330,16,366,3,0,
330,16,369,4,0,
330,16,406,3,0,
330,16,407,1,47,1
330,16,414,1,26,1
330,16,414,3,0,
330,16,416,4,0,
330,16,434,3,0,
330,16,444,4,0,
330,16,450,3,0,
330,16,468,4,0,
330,16,496,4,0,
330,16,510,4,0,
330,16,522,4,0,
330,16,523,1,8,1
330,16,523,4,0,
330,16,525,1,29,1
330,16,525,4,0,
330,16,590,4,0,
330,16,612,4,0,
330,17,19,4,0,
330,17,28,1,1,4
330,17,48,1,19,
330,17,49,1,1,5
330,17,53,4,0,
330,17,63,1,43,
330,17,63,4,0,
330,17,76,4,0,
330,17,89,1,33,
330,17,89,4,0,
330,17,92,4,0,
330,17,103,1,22,
330,17,104,4,0,
330,17,117,1,1,7
330,17,126,4,0,
330,17,156,4,0,
330,17,157,1,15,
330,17,157,4,0,
330,17,164,4,0,
330,17,182,4,0,
330,17,185,1,1,6
330,17,189,1,5,
330,17,201,1,36,
330,17,201,4,0,
330,17,207,4,0,
330,17,211,4,0,
330,17,213,4,0,
330,17,214,4,0,
330,17,216,4,0,
330,17,218,4,0,
330,17,225,1,1,2
330,17,237,4,0,
330,17,241,4,0,
330,17,253,1,40,
330,17,263,4,0,
330,17,317,4,0,
330,17,328,1,12,
330,17,332,4,0,
330,17,337,1,0,
330,17,337,1,1,1
330,17,337,4,0,
330,17,349,1,1,3
330,17,355,4,0,
330,17,369,4,0,
330,17,407,1,47,
330,17,414,1,26,
330,17,416,4,0,
330,17,434,3,0,
330,17,444,4,0,
330,17,496,4,0,
330,17,523,1,8,
330,17,523,4,0,
330,17,525,1,29,
330,17,525,4,0,
330,17,590,4,0,
330,17,693,4,0,
330,18,19,4,0,
330,18,28,1,1,4
330,18,48,1,19,
330,18,49,1,1,5
330,18,53,4,0,
330,18,63,1,43,
330,18,63,4,0,
330,18,76,4,0,
330,18,89,1,33,
330,18,89,4,0,
330,18,92,4,0,
330,18,103,1,22,
330,18,104,4,0,
330,18,117,1,1,7
330,18,126,4,0,
330,18,156,4,0,
330,18,157,1,15,
330,18,157,4,0,
330,18,164,4,0,
330,18,182,4,0,
330,18,185,1,1,6
330,18,189,1,5,
330,18,201,1,36,
330,18,201,4,0,
330,18,207,4,0,
330,18,211,4,0,
330,18,213,4,0,
330,18,214,4,0,
330,18,216,4,0,
330,18,218,4,0,
330,18,225,1,1,2
330,18,237,4,0,
330,18,241,4,0,
330,18,253,1,40,
330,18,263,4,0,
330,18,317,4,0,
330,18,328,1,12,
330,18,332,4,0,
330,18,337,1,0,
330,18,337,1,1,1
330,18,337,4,0,
330,18,349,1,1,3
330,18,355,4,0,
330,18,369,4,0,
330,18,407,1,47,
330,18,414,1,26,
330,18,416,4,0,
330,18,434,3,0,
330,18,444,4,0,
330,18,496,4,0,
330,18,523,1,8,
330,18,523,4,0,
330,18,525,1,29,
330,18,525,4,0,
330,18,590,4,0,
330,18,693,4,0,
331,5,15,4,0,
331,5,28,1,17,
331,5,40,1,1,1
331,5,42,1,21,
331,5,43,1,1,2
331,5,51,2,0,
331,5,68,2,0,
331,5,71,1,5,
331,5,73,1,13,
331,5,74,1,9,
331,5,76,4,0,
331,5,92,4,0,
331,5,104,4,0,
331,5,148,4,0,
331,5,156,4,0,
331,5,178,1,41,
331,5,182,4,0,
331,5,185,1,29,
331,5,191,1,33,
331,5,201,1,45,
331,5,201,4,0,
331,5,202,4,0,
331,5,213,4,0,
331,5,216,4,0,
331,5,218,4,0,
331,5,223,2,0,
331,5,237,4,0,
331,5,241,4,0,
331,5,263,4,0,
331,5,264,4,0,
331,5,275,1,25,
331,5,290,4,0,
331,5,298,2,0,
331,5,302,1,37,
331,5,320,2,0,
331,5,331,4,0,
331,6,5,3,0,
331,6,9,3,0,
331,6,14,3,0,
331,6,15,4,0,
331,6,28,1,17,
331,6,34,3,0,
331,6,38,3,0,
331,6,40,1,1,1
331,6,42,1,21,
331,6,43,1,1,2
331,6,51,2,0,
331,6,68,2,0,
331,6,68,3,0,
331,6,69,3,0,
331,6,71,1,5,
331,6,73,1,13,
331,6,74,1,9,
331,6,76,4,0,
331,6,92,4,0,
331,6,102,3,0,
331,6,104,4,0,
331,6,148,4,0,
331,6,156,4,0,
331,6,164,3,0,
331,6,173,3,0,
331,6,178,1,41,
331,6,182,4,0,
331,6,185,1,29,
331,6,189,3,0,
331,6,191,1,33,
331,6,201,1,45,
331,6,201,4,0,
331,6,202,4,0,
331,6,203,3,0,
331,6,207,3,0,
331,6,210,3,0,
331,6,213,4,0,
331,6,214,3,0,
331,6,216,4,0,
331,6,218,4,0,
331,6,223,2,0,
331,6,223,3,0,
331,6,237,4,0,
331,6,241,4,0,
331,6,263,4,0,
331,6,264,4,0,
331,6,275,1,25,
331,6,290,4,0,
331,6,298,2,0,
331,6,302,1,37,
331,6,320,2,0,
331,6,331,4,0,
331,7,5,3,0,
331,7,14,3,0,
331,7,15,4,0,
331,7,28,1,17,
331,7,34,3,0,
331,7,38,3,0,
331,7,40,1,1,1
331,7,42,1,21,
331,7,43,1,1,2
331,7,51,2,0,
331,7,68,2,0,
331,7,68,3,0,
331,7,69,3,0,
331,7,71,1,5,
331,7,73,1,13,
331,7,74,1,9,
331,7,76,4,0,
331,7,92,4,0,
331,7,102,3,0,
331,7,104,4,0,
331,7,148,4,0,
331,7,156,4,0,
331,7,164,3,0,
331,7,178,1,41,
331,7,182,4,0,
331,7,185,1,29,
331,7,191,1,33,
331,7,194,1,49,
331,7,201,1,45,
331,7,201,4,0,
331,7,202,4,0,
331,7,213,4,0,
331,7,216,4,0,
331,7,218,4,0,
331,7,223,2,0,
331,7,237,4,0,
331,7,241,4,0,
331,7,263,4,0,
331,7,264,4,0,
331,7,275,1,25,
331,7,290,4,0,
331,7,298,2,0,
331,7,302,1,37,
331,7,320,2,0,
331,7,331,4,0,
331,8,14,4,0,
331,8,15,4,0,
331,8,28,1,17,
331,8,40,1,1,1
331,8,42,1,21,
331,8,43,1,1,2
331,8,51,2,0,
331,8,67,2,0,
331,8,68,2,0,
331,8,71,1,5,
331,8,73,1,13,
331,8,74,1,9,
331,8,76,4,0,
331,8,92,4,0,
331,8,104,4,0,
331,8,148,4,0,
331,8,156,4,0,
331,8,164,4,0,
331,8,178,1,49,
331,8,182,4,0,
331,8,185,1,29,
331,8,191,1,33,
331,8,194,1,57,
331,8,201,1,53,
331,8,201,4,0,
331,8,202,4,0,
331,8,203,4,0,
331,8,207,4,0,
331,8,213,4,0,
331,8,214,4,0,
331,8,216,4,0,
331,8,218,4,0,
331,8,223,2,0,
331,8,237,4,0,
331,8,241,4,0,
331,8,263,4,0,
331,8,264,4,0,
331,8,265,2,0,
331,8,275,1,25,
331,8,280,4,0,
331,8,290,4,0,
331,8,298,2,0,
331,8,302,1,45,
331,8,320,2,0,
331,8,331,4,0,
331,8,345,2,0,
331,8,363,4,0,
331,8,371,1,41,
331,8,371,4,0,
331,8,374,4,0,
331,8,389,1,37,
331,8,398,4,0,
331,8,399,4,0,
331,8,402,2,0,
331,8,409,4,0,
331,8,412,4,0,
331,8,445,4,0,
331,8,447,4,0,
331,9,9,3,0,
331,9,14,4,0,
331,9,15,4,0,
331,9,28,1,17,
331,9,40,1,1,1
331,9,42,1,21,
331,9,43,1,1,2
331,9,51,2,0,
331,9,67,2,0,
331,9,68,2,0,
331,9,71,1,5,
331,9,73,1,13,
331,9,74,1,9,
331,9,76,4,0,
331,9,92,4,0,
331,9,104,4,0,
331,9,148,4,0,
331,9,156,4,0,
331,9,164,4,0,
331,9,173,3,0,
331,9,178,1,49,
331,9,180,3,0,
331,9,182,4,0,
331,9,185,1,29,
331,9,189,3,0,
331,9,191,1,33,
331,9,194,1,57,
331,9,201,1,53,
331,9,201,4,0,
331,9,202,4,0,
331,9,203,4,0,
331,9,207,4,0,
331,9,210,3,0,
331,9,213,4,0,
331,9,214,4,0,
331,9,216,4,0,
331,9,218,4,0,
331,9,223,2,0,
331,9,235,3,0,
331,9,237,4,0,
331,9,241,4,0,
331,9,263,4,0,
331,9,264,4,0,
331,9,265,2,0,
331,9,275,1,25,
331,9,280,4,0,
331,9,290,4,0,
331,9,298,2,0,
331,9,302,1,45,
331,9,320,2,0,
331,9,331,4,0,
331,9,345,2,0,
331,9,363,4,0,
331,9,371,1,41,
331,9,371,4,0,
331,9,374,4,0,
331,9,389,1,37,
331,9,389,3,0,
331,9,398,4,0,
331,9,399,4,0,
331,9,402,2,0,
331,9,402,3,0,
331,9,409,4,0,
331,9,412,4,0,
331,9,445,4,0,
331,9,447,4,0,
331,10,9,3,0,
331,10,14,4,0,
331,10,15,4,0,
331,10,28,1,17,
331,10,29,3,0,
331,10,40,1,1,1
331,10,42,1,21,
331,10,43,1,1,2
331,10,51,2,0,
331,10,67,2,0,
331,10,67,3,0,
331,10,68,2,0,
331,10,71,1,5,
331,10,73,1,13,
331,10,74,1,9,
331,10,76,4,0,
331,10,92,4,0,
331,10,104,4,0,
331,10,148,4,0,
331,10,156,4,0,
331,10,164,4,0,
331,10,173,3,0,
331,10,178,1,49,
331,10,180,3,0,
331,10,182,4,0,
331,10,185,1,29,
331,10,189,3,0,
331,10,191,1,33,
331,10,194,1,57,
331,10,201,1,53,
331,10,201,4,0,
331,10,202,4,0,
331,10,203,4,0,
331,10,207,4,0,
331,10,210,3,0,
331,10,213,4,0,
331,10,214,4,0,
331,10,216,4,0,
331,10,218,4,0,
331,10,223,2,0,
331,10,235,3,0,
331,10,237,4,0,
331,10,241,4,0,
331,10,263,4,0,
331,10,264,4,0,
331,10,265,2,0,
331,10,272,3,0,
331,10,275,1,25,
331,10,280,4,0,
331,10,290,4,0,
331,10,298,2,0,
331,10,302,1,45,
331,10,320,2,0,
331,10,331,4,0,
331,10,345,2,0,
331,10,363,4,0,
331,10,371,1,41,
331,10,371,4,0,
331,10,374,4,0,
331,10,388,3,0,
331,10,389,1,37,
331,10,389,3,0,
331,10,398,4,0,
331,10,399,4,0,
331,10,402,2,0,
331,10,402,3,0,
331,10,409,4,0,
331,10,412,4,0,
331,10,417,2,0,
331,10,445,4,0,
331,10,447,4,0,
331,11,14,4,0,
331,11,15,4,0,
331,11,28,1,17,
331,11,40,1,1,1
331,11,42,1,21,
331,11,43,1,1,2
331,11,50,2,0,
331,11,51,2,0,
331,11,67,2,0,
331,11,68,2,0,
331,11,71,1,5,
331,11,73,1,13,
331,11,74,1,9,
331,11,76,4,0,
331,11,92,4,0,
331,11,104,4,0,
331,11,148,4,0,
331,11,156,4,0,
331,11,164,4,0,
331,11,178,1,49,
331,11,182,4,0,
331,11,185,1,29,
331,11,191,1,33,
331,11,194,1,57,
331,11,201,1,53,
331,11,201,4,0,
331,11,207,4,0,
331,11,213,4,0,
331,11,216,4,0,
331,11,218,4,0,
331,11,223,2,0,
331,11,237,4,0,
331,11,241,4,0,
331,11,263,4,0,
331,11,265,2,0,
331,11,275,1,25,
331,11,280,4,0,
331,11,298,2,0,
331,11,302,1,45,
331,11,320,2,0,
331,11,335,2,0,
331,11,345,2,0,
331,11,371,1,41,
331,11,371,4,0,
331,11,374,4,0,
331,11,388,2,0,
331,11,389,1,37,
331,11,398,4,0,
331,11,402,2,0,
331,11,412,4,0,
331,11,415,2,0,
331,11,417,2,0,
331,11,447,4,0,
331,11,474,4,0,
331,11,496,4,0,
331,12,15,4,0,
331,12,28,1,17,
331,12,40,1,1,1
331,12,42,1,21,
331,12,43,1,1,2
331,12,71,1,5,
331,12,73,1,13,
331,12,74,1,9,
331,12,76,4,0,
331,12,92,4,0,
331,12,104,4,0,
331,12,148,4,0,
331,12,156,4,0,
331,12,178,1,41,
331,12,182,4,0,
331,12,185,1,29,
331,12,191,1,33,
331,12,201,1,45,
331,12,201,4,0,
331,12,202,4,0,
331,12,213,4,0,
331,12,216,4,0,
331,12,218,4,0,
331,12,237,4,0,
331,12,241,4,0,
331,12,263,4,0,
331,12,264,4,0,
331,12,275,1,25,
331,12,290,4,0,
331,12,302,1,37,
331,12,331,4,0,
331,13,15,4,0,
331,13,28,1,17,
331,13,34,3,0,
331,13,38,3,0,
331,13,40,1,1,1
331,13,42,1,21,
331,13,43,1,1,2
331,13,69,3,0,
331,13,71,1,5,
331,13,73,1,13,
331,13,74,1,9,
331,13,76,4,0,
331,13,92,4,0,
331,13,102,3,0,
331,13,104,4,0,
331,13,148,4,0,
331,13,156,4,0,
331,13,164,3,0,
331,13,178,1,41,
331,13,182,4,0,
331,13,185,1,29,
331,13,191,1,33,
331,13,201,1,45,
331,13,201,4,0,
331,13,202,4,0,
331,13,207,3,0,
331,13,213,4,0,
331,13,216,4,0,
331,13,218,4,0,
331,13,237,4,0,
331,13,241,4,0,
331,13,263,4,0,
331,13,264,4,0,
331,13,275,1,25,
331,13,290,4,0,
331,13,302,1,37,
331,13,331,4,0,
331,14,9,3,0,
331,14,14,4,0,
331,14,15,4,0,
331,14,28,1,17,
331,14,40,1,1,1
331,14,42,1,21,
331,14,43,1,1,2
331,14,50,2,0,
331,14,51,2,0,
331,14,67,2,0,
331,14,67,3,0,
331,14,68,2,0,
331,14,71,1,5,
331,14,73,1,13,
331,14,74,1,9,
331,14,76,4,0,
331,14,92,4,0,
331,14,104,4,0,
331,14,148,4,0,
331,14,156,4,0,
331,14,164,4,0,
331,14,173,3,0,
331,14,178,1,49,
331,14,180,3,0,
331,14,182,4,0,
331,14,185,1,29,
331,14,191,1,33,
331,14,194,1,57,
331,14,201,1,53,
331,14,201,4,0,
331,14,202,3,0,
331,14,207,4,0,
331,14,213,4,0,
331,14,214,3,0,
331,14,216,4,0,
331,14,218,4,0,
331,14,223,2,0,
331,14,235,3,0,
331,14,237,4,0,
331,14,241,4,0,
331,14,263,4,0,
331,14,265,2,0,
331,14,272,3,0,
331,14,275,1,25,
331,14,280,4,0,
331,14,298,2,0,
331,14,302,1,45,
331,14,320,2,0,
331,14,335,2,0,
331,14,335,3,0,
331,14,345,2,0,
331,14,371,1,41,
331,14,371,4,0,
331,14,374,4,0,
331,14,388,2,0,
331,14,388,3,0,
331,14,389,1,37,
331,14,398,4,0,
331,14,399,3,0,
331,14,402,2,0,
331,14,402,3,0,
331,14,409,3,0,
331,14,412,4,0,
331,14,415,2,0,
331,14,417,2,0,
331,14,447,4,0,
331,14,474,4,0,
331,14,496,4,0,
331,15,14,4,0,
331,15,15,4,0,
331,15,28,1,17,
331,15,40,1,1,1
331,15,42,1,21,
331,15,43,1,1,2
331,15,50,2,0,
331,15,51,2,0,
331,15,67,2,0,
331,15,68,2,0,
331,15,71,1,5,
331,15,73,1,13,
331,15,74,1,9,
331,15,76,4,0,
331,15,92,4,0,
331,15,104,4,0,
331,15,148,4,0,
331,15,156,4,0,
331,15,164,4,0,
331,15,178,1,49,
331,15,182,4,0,
331,15,185,1,29,
331,15,191,1,33,
331,15,194,1,57,
331,15,201,1,53,
331,15,201,4,0,
331,15,207,4,0,
331,15,213,4,0,
331,15,214,4,0,
331,15,216,4,0,
331,15,218,4,0,
331,15,223,2,0,
331,15,237,4,0,
331,15,241,4,0,
331,15,263,4,0,
331,15,265,2,0,
331,15,267,4,0,
331,15,275,1,25,
331,15,280,4,0,
331,15,298,2,0,
331,15,302,1,45,
331,15,320,2,0,
331,15,335,2,0,
331,15,345,2,0,
331,15,371,1,41,
331,15,371,4,0,
331,15,374,4,0,
331,15,388,2,0,
331,15,389,1,37,
331,15,398,4,0,
331,15,399,4,0,
331,15,402,2,0,
331,15,412,4,0,
331,15,415,2,0,
331,15,417,2,0,
331,15,447,4,0,
331,15,474,4,0,
331,15,496,4,0,
331,15,562,2,0,
331,15,563,2,0,
331,15,565,2,0,
331,15,590,4,0,
331,15,612,4,0,
331,16,9,3,0,
331,16,14,4,0,
331,16,15,4,0,
331,16,28,1,13,1
331,16,40,1,1,1
331,16,42,1,38,1
331,16,43,1,1,2
331,16,50,2,0,
331,16,51,2,0,
331,16,67,2,0,
331,16,67,3,0,
331,16,68,2,0,
331,16,71,1,4,1
331,16,73,1,10,1
331,16,74,1,7,1
331,16,76,4,0,
331,16,92,4,0,
331,16,104,4,0,
331,16,148,4,0,
331,16,156,4,0,
331,16,164,4,0,
331,16,173,3,0,
331,16,178,1,46,1
331,16,180,3,0,
331,16,182,4,0,
331,16,185,1,19,1
331,16,191,1,30,1
331,16,194,1,54,1
331,16,201,1,50,1
331,16,201,4,0,
331,16,202,3,0,
331,16,207,4,0,
331,16,213,4,0,
331,16,214,4,0,
331,16,216,4,0,
331,16,218,4,0,
331,16,223,2,0,
331,16,235,3,0,
331,16,237,4,0,
331,16,241,4,0,
331,16,263,4,0,
331,16,264,3,0,
331,16,265,2,0,
331,16,267,4,0,
331,16,272,3,0,
331,16,275,1,22,1
331,16,280,4,0,
331,16,290,4,0,
331,16,298,2,0,
331,16,302,1,16,1
331,16,320,2,0,
331,16,335,2,0,
331,16,335,3,0,
331,16,345,2,0,
331,16,371,1,26,1
331,16,371,4,0,
331,16,374,4,0,
331,16,388,2,0,
331,16,388,3,0,
331,16,389,1,34,1
331,16,398,4,0,
331,16,399,4,0,
331,16,402,2,0,
331,16,402,3,0,
331,16,409,3,0,
331,16,412,1,42,1
331,16,412,4,0,
331,16,415,2,0,
331,16,417,2,0,
331,16,447,4,0,
331,16,474,4,0,
331,16,496,4,0,
331,16,562,2,0,
331,16,563,2,0,
331,16,565,2,0,
331,16,590,4,0,
331,16,612,4,0,
331,17,14,4,0,
331,17,28,1,13,
331,17,40,1,1,1
331,17,42,1,38,
331,17,43,1,1,2
331,17,50,2,0,
331,17,51,2,0,
331,17,67,2,0,
331,17,68,2,0,
331,17,71,1,4,
331,17,73,1,10,
331,17,74,1,7,
331,17,76,4,0,
331,17,92,4,0,
331,17,104,4,0,
331,17,156,4,0,
331,17,164,4,0,
331,17,178,1,46,
331,17,182,4,0,
331,17,185,1,19,
331,17,191,1,30,
331,17,194,1,54,
331,17,201,1,50,
331,17,201,4,0,
331,17,207,4,0,
331,17,213,4,0,
331,17,214,4,0,
331,17,216,4,0,
331,17,218,4,0,
331,17,223,2,0,
331,17,237,4,0,
331,17,241,4,0,
331,17,263,4,0,
331,17,265,2,0,
331,17,267,4,0,
331,17,275,1,22,
331,17,280,4,0,
331,17,298,2,0,
331,17,302,1,16,
331,17,320,2,0,
331,17,335,2,0,
331,17,345,2,0,
331,17,371,1,26,
331,17,371,4,0,
331,17,374,4,0,
331,17,388,2,0,
331,17,389,1,34,
331,17,398,4,0,
331,17,399,4,0,
331,17,402,2,0,
331,17,412,1,42,
331,17,412,4,0,
331,17,415,2,0,
331,17,417,2,0,
331,17,447,4,0,
331,17,474,4,0,
331,17,496,4,0,
331,17,562,2,0,
331,17,563,2,0,
331,17,565,2,0,
331,17,590,4,0,
331,18,14,4,0,
331,18,28,1,13,
331,18,40,1,1,1
331,18,42,1,38,
331,18,43,1,1,2
331,18,50,2,0,
331,18,51,2,0,
331,18,67,2,0,
331,18,68,2,0,
331,18,71,1,4,
331,18,73,1,10,
331,18,74,1,7,
331,18,76,4,0,
331,18,92,4,0,
331,18,104,4,0,
331,18,156,4,0,
331,18,164,4,0,
331,18,178,1,46,
331,18,182,4,0,
331,18,185,1,19,
331,18,191,1,30,
331,18,194,1,54,
331,18,201,1,50,
331,18,201,4,0,
331,18,207,4,0,
331,18,213,4,0,
331,18,214,4,0,
331,18,216,4,0,
331,18,218,4,0,
331,18,223,2,0,
331,18,237,4,0,
331,18,241,4,0,
331,18,263,4,0,
331,18,265,2,0,
331,18,267,4,0,
331,18,275,1,22,
331,18,280,4,0,
331,18,298,2,0,
331,18,302,1,16,
331,18,320,2,0,
331,18,335,2,0,
331,18,345,2,0,
331,18,371,1,26,
331,18,371,4,0,
331,18,374,4,0,
331,18,388,2,0,
331,18,389,1,34,
331,18,398,4,0,
331,18,399,4,0,
331,18,402,2,0,
331,18,412,1,42,
331,18,412,4,0,
331,18,415,2,0,
331,18,417,2,0,
331,18,447,4,0,
331,18,474,4,0,
331,18,496,4,0,
331,18,562,2,0,
331,18,563,2,0,
331,18,565,2,0,
331,18,590,4,0,
331,18,612,2,0,
332,5,15,4,0,
332,5,28,1,17,
332,5,40,1,1,1
332,5,42,1,21,
332,5,43,1,1,2
332,5,63,4,0,
332,5,70,4,0,
332,5,71,1,1,3
332,5,71,1,5,
332,5,73,1,13,
332,5,74,1,1,4
332,5,74,1,9,
332,5,76,4,0,
332,5,92,4,0,
332,5,104,4,0,
332,5,148,4,0,
332,5,156,4,0,
332,5,178,1,47,
332,5,182,4,0,
332,5,185,1,29,
332,5,191,1,35,
332,5,201,1,53,
332,5,201,4,0,
332,5,202,4,0,
332,5,213,4,0,
332,5,216,4,0,
332,5,218,4,0,
332,5,237,4,0,
332,5,241,4,0,
332,5,263,4,0,
332,5,264,4,0,
332,5,275,1,25,
332,5,290,4,0,
332,5,302,1,41,
332,5,331,4,0,
332,6,5,3,0,
332,6,9,3,0,
332,6,14,3,0,
332,6,15,4,0,
332,6,25,3,0,
332,6,28,1,17,
332,6,34,3,0,
332,6,38,3,0,
332,6,40,1,1,1
332,6,42,1,21,
332,6,43,1,1,2
332,6,63,4,0,
332,6,68,3,0,
332,6,69,3,0,
332,6,70,4,0,
332,6,71,1,1,3
332,6,71,1,5,
332,6,73,1,13,
332,6,74,1,1,4
332,6,74,1,9,
332,6,76,4,0,
332,6,92,4,0,
332,6,102,3,0,
332,6,104,4,0,
332,6,148,4,0,
332,6,156,4,0,
332,6,164,3,0,
332,6,173,3,0,
332,6,178,1,47,
332,6,182,4,0,
332,6,185,1,29,
332,6,189,3,0,
332,6,191,1,35,
332,6,201,1,53,
332,6,201,4,0,
332,6,202,4,0,
332,6,203,3,0,
332,6,207,3,0,
332,6,210,3,0,
332,6,213,4,0,
332,6,214,3,0,
332,6,216,4,0,
332,6,218,4,0,
332,6,223,3,0,
332,6,237,4,0,
332,6,241,4,0,
332,6,263,4,0,
332,6,264,4,0,
332,6,275,1,25,
332,6,290,4,0,
332,6,302,1,41,
332,6,331,4,0,
332,7,5,3,0,
332,7,14,3,0,
332,7,15,4,0,
332,7,25,3,0,
332,7,28,1,17,
332,7,34,3,0,
332,7,38,3,0,
332,7,40,1,1,2
332,7,42,1,21,
332,7,43,1,1,3
332,7,63,4,0,
332,7,68,3,0,
332,7,69,3,0,
332,7,70,4,0,
332,7,71,1,1,4
332,7,71,1,5,
332,7,73,1,13,
332,7,74,1,1,5
332,7,74,1,9,
332,7,76,4,0,
332,7,92,4,0,
332,7,102,3,0,
332,7,104,4,0,
332,7,148,4,0,
332,7,156,4,0,
332,7,164,3,0,
332,7,178,1,47,
332,7,182,4,0,
332,7,185,1,29,
332,7,191,1,35,
332,7,194,1,59,
332,7,201,1,53,
332,7,201,4,0,
332,7,202,4,0,
332,7,213,4,0,
332,7,216,4,0,
332,7,218,4,0,
332,7,237,4,0,
332,7,241,4,0,
332,7,263,4,0,
332,7,264,4,0,
332,7,275,1,25,
332,7,279,1,1,1
332,7,290,4,0,
332,7,302,1,41,
332,7,331,4,0,
332,8,14,4,0,
332,8,15,4,0,
332,8,28,1,17,
332,8,40,1,1,2
332,8,42,1,21,
332,8,43,1,1,3
332,8,63,4,0,
332,8,70,4,0,
332,8,71,1,1,4
332,8,71,1,5,
332,8,73,1,13,
332,8,74,1,1,5
332,8,74,1,9,
332,8,76,4,0,
332,8,92,4,0,
332,8,104,4,0,
332,8,148,4,0,
332,8,156,4,0,
332,8,164,4,0,
332,8,178,1,59,
332,8,182,4,0,
332,8,185,1,29,
332,8,191,1,35,
332,8,194,1,71,
332,8,201,1,65,
332,8,201,4,0,
332,8,202,4,0,
332,8,203,4,0,
332,8,207,4,0,
332,8,213,4,0,
332,8,214,4,0,
332,8,216,4,0,
332,8,218,4,0,
332,8,237,4,0,
332,8,241,4,0,
332,8,263,4,0,
332,8,264,4,0,
332,8,275,1,25,
332,8,279,1,1,1
332,8,280,4,0,
332,8,290,4,0,
332,8,302,1,53,
332,8,331,4,0,
332,8,363,4,0,
332,8,371,1,47,
332,8,371,4,0,
332,8,373,4,0,
332,8,374,4,0,
332,8,389,1,41,
332,8,398,4,0,
332,8,399,4,0,
332,8,409,4,0,
332,8,411,4,0,
332,8,412,4,0,
332,8,416,4,0,
332,8,445,4,0,
332,8,447,4,0,
332,9,9,3,0,
332,9,14,4,0,
332,9,15,4,0,
332,9,28,1,17,
332,9,40,1,1,2
332,9,42,1,21,
332,9,43,1,1,3
332,9,63,4,0,
332,9,70,4,0,
332,9,71,1,1,4
332,9,71,1,5,
332,9,73,1,13,
332,9,74,1,1,5
332,9,74,1,9,
332,9,76,4,0,
332,9,92,4,0,
332,9,104,4,0,
332,9,148,4,0,
332,9,156,4,0,
332,9,164,4,0,
332,9,173,3,0,
332,9,178,1,59,
332,9,180,3,0,
332,9,182,4,0,
332,9,185,1,29,
332,9,189,3,0,
332,9,191,1,35,
332,9,194,1,71,
332,9,201,1,65,
332,9,201,4,0,
332,9,202,4,0,
332,9,203,4,0,
332,9,207,4,0,
332,9,210,3,0,
332,9,213,4,0,
332,9,214,4,0,
332,9,216,4,0,
332,9,218,4,0,
332,9,235,3,0,
332,9,237,4,0,
332,9,241,4,0,
332,9,263,4,0,
332,9,264,4,0,
332,9,275,1,25,
332,9,276,3,0,
332,9,279,1,1,1
332,9,280,4,0,
332,9,290,4,0,
332,9,302,1,53,
332,9,331,4,0,
332,9,363,4,0,
332,9,371,1,47,
332,9,371,4,0,
332,9,373,4,0,
332,9,374,4,0,
332,9,389,1,41,
332,9,389,3,0,
332,9,398,4,0,
332,9,399,4,0,
332,9,402,3,0,
332,9,409,4,0,
332,9,411,4,0,
332,9,412,4,0,
332,9,416,4,0,
332,9,445,4,0,
332,9,447,4,0,
332,10,9,3,0,
332,10,14,4,0,
332,10,15,4,0,
332,10,28,1,17,
332,10,29,3,0,
332,10,40,1,1,2
332,10,42,1,21,
332,10,43,1,1,3
332,10,63,4,0,
332,10,67,3,0,
332,10,70,4,0,
332,10,71,1,1,4
332,10,71,1,5,
332,10,73,1,13,
332,10,74,1,1,5
332,10,74,1,9,
332,10,76,4,0,
332,10,92,4,0,
332,10,104,4,0,
332,10,148,4,0,
332,10,156,4,0,
332,10,164,4,0,
332,10,173,3,0,
332,10,178,1,59,
332,10,180,3,0,
332,10,182,4,0,
332,10,185,1,29,
332,10,189,3,0,
332,10,191,1,35,
332,10,194,1,71,
332,10,201,1,65,
332,10,201,4,0,
332,10,202,4,0,
332,10,203,4,0,
332,10,207,4,0,
332,10,210,3,0,
332,10,213,4,0,
332,10,214,4,0,
332,10,216,4,0,
332,10,218,4,0,
332,10,235,3,0,
332,10,237,4,0,
332,10,241,4,0,
332,10,263,4,0,
332,10,264,4,0,
332,10,272,3,0,
332,10,275,1,25,
332,10,276,3,0,
332,10,279,1,1,1
332,10,280,4,0,
332,10,290,4,0,
332,10,302,1,53,
332,10,331,4,0,
332,10,363,4,0,
332,10,371,1,47,
332,10,371,4,0,
332,10,373,4,0,
332,10,374,4,0,
332,10,388,3,0,
332,10,389,1,41,
332,10,389,3,0,
332,10,398,4,0,
332,10,399,4,0,
332,10,402,3,0,
332,10,409,4,0,
332,10,411,4,0,
332,10,412,4,0,
332,10,416,4,0,
332,10,445,4,0,
332,10,447,4,0,
332,11,14,4,0,
332,11,15,4,0,
332,11,28,1,17,
332,11,40,1,1,2
332,11,42,1,21,
332,11,43,1,1,3
332,11,63,4,0,
332,11,70,4,0,
332,11,71,1,1,4
332,11,71,1,5,
332,11,73,1,13,
332,11,74,1,1,5
332,11,74,1,9,
332,11,76,4,0,
332,11,92,4,0,
332,11,104,4,0,
332,11,148,4,0,
332,11,156,4,0,
332,11,164,4,0,
332,11,178,1,59,
332,11,182,4,0,
332,11,185,1,29,
332,11,191,1,35,
332,11,194,1,71,
332,11,201,1,65,
332,11,201,4,0,
332,11,207,4,0,
332,11,213,4,0,
332,11,216,4,0,
332,11,218,4,0,
332,11,237,4,0,
332,11,241,4,0,
332,11,263,4,0,
332,11,275,1,25,
332,11,279,1,1,1
332,11,280,4,0,
332,11,302,1,53,
332,11,371,1,47,
332,11,371,4,0,
332,11,373,4,0,
332,11,374,4,0,
332,11,389,1,41,
332,11,398,4,0,
332,11,411,4,0,
332,11,412,4,0,
332,11,416,4,0,
332,11,447,4,0,
332,11,474,4,0,
332,11,496,4,0,
332,11,514,4,0,
332,12,15,4,0,
332,12,28,1,17,
332,12,40,1,1,1
332,12,42,1,21,
332,12,43,1,1,2
332,12,63,4,0,
332,12,70,4,0,
332,12,71,1,1,3
332,12,71,1,5,
332,12,73,1,13,
332,12,74,1,1,4
332,12,74,1,9,
332,12,76,4,0,
332,12,92,4,0,
332,12,104,4,0,
332,12,148,4,0,
332,12,156,4,0,
332,12,178,1,47,
332,12,182,4,0,
332,12,185,1,29,
332,12,191,1,35,
332,12,201,1,53,
332,12,201,4,0,
332,12,202,4,0,
332,12,213,4,0,
332,12,216,4,0,
332,12,218,4,0,
332,12,237,4,0,
332,12,241,4,0,
332,12,263,4,0,
332,12,264,4,0,
332,12,275,1,25,
332,12,290,4,0,
332,12,302,1,41,
332,12,331,4,0,
332,13,15,4,0,
332,13,28,1,17,
332,13,34,3,0,
332,13,38,3,0,
332,13,40,1,1,1
332,13,42,1,21,
332,13,43,1,1,2
332,13,63,4,0,
332,13,69,3,0,
332,13,70,4,0,
332,13,71,1,1,3
332,13,71,1,5,
332,13,73,1,13,
332,13,74,1,1,4
332,13,74,1,9,
332,13,76,4,0,
332,13,92,4,0,
332,13,102,3,0,
332,13,104,4,0,
332,13,148,4,0,
332,13,156,4,0,
332,13,164,3,0,
332,13,178,1,47,
332,13,182,4,0,
332,13,185,1,29,
332,13,191,1,35,
332,13,201,1,53,
332,13,201,4,0,
332,13,202,4,0,
332,13,207,3,0,
332,13,213,4,0,
332,13,216,4,0,
332,13,218,4,0,
332,13,237,4,0,
332,13,241,4,0,
332,13,263,4,0,
332,13,264,4,0,
332,13,275,1,25,
332,13,290,4,0,
332,13,302,1,41,
332,13,331,4,0,
332,14,9,3,0,
332,14,14,4,0,
332,14,15,4,0,
332,14,28,1,17,
332,14,40,1,1,2
332,14,42,1,21,
332,14,43,1,1,3
332,14,63,4,0,
332,14,67,3,0,
332,14,70,4,0,
332,14,71,1,1,4
332,14,71,1,5,
332,14,73,1,13,
332,14,74,1,1,5
332,14,74,1,9,
332,14,76,4,0,
332,14,92,4,0,
332,14,104,4,0,
332,14,148,4,0,
332,14,156,4,0,
332,14,164,4,0,
332,14,173,3,0,
332,14,178,1,59,
332,14,180,3,0,
332,14,182,4,0,
332,14,185,1,29,
332,14,191,1,35,
332,14,194,1,71,
332,14,201,1,65,
332,14,201,4,0,
332,14,202,3,0,
332,14,207,4,0,
332,14,213,4,0,
332,14,214,3,0,
332,14,216,4,0,
332,14,218,4,0,
332,14,235,3,0,
332,14,237,4,0,
332,14,241,4,0,
332,14,263,4,0,
332,14,272,3,0,
332,14,275,1,25,
332,14,276,3,0,
332,14,279,1,1,1
332,14,280,4,0,
332,14,302,1,53,
332,14,335,3,0,
332,14,371,1,47,
332,14,371,4,0,
332,14,373,4,0,
332,14,374,4,0,
332,14,388,3,0,
332,14,389,1,41,
332,14,398,4,0,
332,14,399,3,0,
332,14,402,3,0,
332,14,409,3,0,
332,14,411,4,0,
332,14,412,4,0,
332,14,416,4,0,
332,14,447,4,0,
332,14,474,4,0,
332,14,492,3,0,
332,14,496,4,0,
332,14,514,4,0,
332,15,14,4,0,
332,15,15,4,0,
332,15,28,1,17,
332,15,40,1,1,3
332,15,42,1,21,
332,15,43,1,1,4
332,15,63,4,0,
332,15,70,4,0,
332,15,71,1,1,5
332,15,71,1,5,
332,15,73,1,13,
332,15,74,1,1,6
332,15,74,1,9,
332,15,76,4,0,
332,15,92,4,0,
332,15,104,4,0,
332,15,148,4,0,
332,15,156,4,0,
332,15,164,4,0,
332,15,178,1,59,
332,15,182,4,0,
332,15,185,1,29,
332,15,191,1,35,
332,15,194,1,1,1
332,15,194,1,71,
332,15,201,1,65,
332,15,201,4,0,
332,15,207,4,0,
332,15,213,4,0,
332,15,214,4,0,
332,15,216,4,0,
332,15,218,4,0,
332,15,237,4,0,
332,15,241,4,0,
332,15,263,4,0,
332,15,267,4,0,
332,15,275,1,25,
332,15,279,1,1,2
332,15,280,4,0,
332,15,302,1,53,
332,15,371,1,47,
332,15,371,4,0,
332,15,373,4,0,
332,15,374,4,0,
332,15,389,1,41,
332,15,398,4,0,
332,15,399,4,0,
332,15,411,4,0,
332,15,412,4,0,
332,15,416,4,0,
332,15,447,4,0,
332,15,474,4,0,
332,15,496,4,0,
332,15,514,4,0,
332,15,590,4,0,
332,15,612,4,0,
332,16,9,3,0,
332,16,14,4,0,
332,16,15,4,0,
332,16,28,1,13,1
332,16,40,1,1,3
332,16,42,1,38,1
332,16,43,1,1,4
332,16,63,4,0,
332,16,67,3,0,
332,16,70,4,0,
332,16,71,1,1,5
332,16,71,1,4,1
332,16,73,1,10,1
332,16,74,1,1,6
332,16,74,1,7,1
332,16,76,4,0,
332,16,92,4,0,
332,16,104,4,0,
332,16,148,4,0,
332,16,156,4,0,
332,16,164,4,0,
332,16,173,3,0,
332,16,178,1,49,1
332,16,180,3,0,
332,16,182,4,0,
332,16,185,1,19,1
332,16,191,1,30,1
332,16,194,1,1,1
332,16,194,1,59,1
332,16,201,1,54,1
332,16,201,4,0,
332,16,202,3,0,
332,16,207,4,0,
332,16,213,4,0,
332,16,214,4,0,
332,16,216,4,0,
332,16,218,4,0,
332,16,235,3,0,
332,16,237,4,0,
332,16,241,4,0,
332,16,263,4,0,
332,16,264,3,0,
332,16,267,4,0,
332,16,272,3,0,
332,16,275,1,22,1
332,16,276,3,0,
332,16,279,1,1,2
332,16,280,4,0,
332,16,290,4,0,
332,16,302,1,16,1
332,16,335,3,0,
332,16,371,1,26,1
332,16,371,4,0,
332,16,373,4,0,
332,16,374,4,0,
332,16,388,3,0,
332,16,389,1,35,1
332,16,398,4,0,
332,16,399,4,0,
332,16,402,3,0,
332,16,409,3,0,
332,16,411,4,0,
332,16,412,1,44,1
332,16,412,4,0,
332,16,416,4,0,
332,16,447,4,0,
332,16,474,4,0,
332,16,492,3,0,
332,16,496,4,0,
332,16,514,4,0,
332,16,590,4,0,
332,16,596,1,32,1
332,16,612,4,0,
332,17,14,4,0,
332,17,28,1,13,
332,17,40,1,1,4
332,17,42,1,38,
332,17,43,1,1,5
332,17,63,4,0,
332,17,71,1,1,6
332,17,71,1,4,
332,17,73,1,10,
332,17,74,1,1,7
332,17,74,1,7,
332,17,76,4,0,
332,17,92,4,0,
332,17,104,4,0,
332,17,156,4,0,
332,17,164,4,0,
332,17,178,1,49,
332,17,182,4,0,
332,17,185,1,19,
332,17,191,1,30,
332,17,194,1,1,2
332,17,194,1,59,
332,17,201,1,54,
332,17,201,4,0,
332,17,207,4,0,
332,17,213,4,0,
332,17,214,4,0,
332,17,216,4,0,
332,17,218,4,0,
332,17,237,4,0,
332,17,241,4,0,
332,17,263,4,0,
332,17,267,4,0,
332,17,275,1,22,
332,17,279,1,1,3
332,17,280,4,0,
332,17,302,1,16,
332,17,371,1,26,
332,17,371,4,0,
332,17,373,4,0,
332,17,374,4,0,
332,17,389,1,35,
332,17,398,4,0,
332,17,399,4,0,
332,17,411,4,0,
332,17,412,1,44,
332,17,412,4,0,
332,17,416,4,0,
332,17,447,4,0,
332,17,474,4,0,
332,17,496,4,0,
332,17,590,4,0,
332,17,596,1,0,
332,17,596,1,1,1
332,18,14,4,0,
332,18,28,1,13,
332,18,40,1,1,4
332,18,42,1,38,
332,18,43,1,1,5
332,18,63,4,0,
332,18,71,1,1,6
332,18,71,1,4,
332,18,73,1,10,
332,18,74,1,1,7
332,18,74,1,7,
332,18,76,4,0,
332,18,92,4,0,
332,18,104,4,0,
332,18,156,4,0,
332,18,164,4,0,
332,18,178,1,49,
332,18,182,4,0,
332,18,185,1,19,
332,18,191,1,30,
332,18,194,1,1,2
332,18,194,1,59,
332,18,201,1,54,
332,18,201,4,0,
332,18,207,4,0,
332,18,213,4,0,
332,18,214,4,0,
332,18,216,4,0,
332,18,218,4,0,
332,18,237,4,0,
332,18,241,4,0,
332,18,263,4,0,
332,18,267,4,0,
332,18,275,1,22,
332,18,279,1,1,3
332,18,280,4,0,
332,18,302,1,16,
332,18,371,1,26,
332,18,371,4,0,
332,18,373,4,0,
332,18,374,4,0,
332,18,389,1,35,
332,18,398,4,0,
332,18,399,4,0,
332,18,411,4,0,
332,18,412,1,44,
332,18,412,4,0,
332,18,416,4,0,
332,18,447,4,0,
332,18,474,4,0,
332,18,496,4,0,
332,18,590,4,0,
332,18,596,1,0,
332,18,596,1,1,1
333,5,19,4,0,
333,5,31,1,18,
333,5,36,1,31,
333,5,45,1,1,2
333,5,47,1,11,
333,5,54,1,28,
333,5,58,4,0,
333,5,64,1,1,1
333,5,76,4,0,
333,5,92,4,0,
333,5,97,2,0,
333,5,99,2,0,
333,5,104,4,0,
333,5,114,2,0,
333,5,119,1,38,
333,5,156,4,0,
333,5,168,4,0,
333,5,182,4,0,
333,5,195,1,48,
333,5,211,4,0,
333,5,213,4,0,
333,5,216,4,0,
333,5,218,4,0,
333,5,219,1,21,
333,5,219,4,0,
333,5,228,2,0,
333,5,237,4,0,
333,5,240,4,0,
333,5,241,4,0,
333,5,263,4,0,
333,5,287,1,41,
333,5,290,4,0,
333,5,310,1,8,
333,5,332,4,0,
333,6,19,4,0,
333,6,31,1,18,
333,6,34,3,0,
333,6,36,1,31,
333,6,38,3,0,
333,6,45,1,1,2
333,6,47,1,11,
333,6,54,1,28,
333,6,58,4,0,
333,6,64,1,1,1
333,6,76,4,0,
333,6,92,4,0,
333,6,97,2,0,
333,6,99,2,0,
333,6,102,3,0,
333,6,104,4,0,
333,6,114,2,0,
333,6,119,1,38,
333,6,129,3,0,
333,6,138,3,0,
333,6,156,4,0,
333,6,164,3,0,
333,6,168,4,0,
333,6,173,3,0,
333,6,182,4,0,
333,6,189,3,0,
333,6,195,1,48,
333,6,203,3,0,
333,6,207,3,0,
333,6,211,4,0,
333,6,213,4,0,
333,6,214,3,0,
333,6,216,4,0,
333,6,218,4,0,
333,6,219,1,21,
333,6,219,4,0,
333,6,228,2,0,
333,6,237,4,0,
333,6,240,4,0,
333,6,241,4,0,
333,6,244,3,0,
333,6,263,4,0,
333,6,287,1,41,
333,6,290,4,0,
333,6,310,1,8,
333,6,332,4,0,
333,7,19,4,0,
333,7,31,1,18,
333,7,34,3,0,
333,7,36,1,31,
333,7,38,3,0,
333,7,45,1,1,2
333,7,47,1,11,
333,7,54,1,28,
333,7,58,4,0,
333,7,64,1,1,1
333,7,76,4,0,
333,7,92,4,0,
333,7,97,2,0,
333,7,99,2,0,
333,7,102,3,0,
333,7,104,4,0,
333,7,114,2,0,
333,7,119,1,38,
333,7,138,3,0,
333,7,156,4,0,
333,7,164,3,0,
333,7,168,4,0,
333,7,182,4,0,
333,7,195,1,48,
333,7,211,4,0,
333,7,213,4,0,
333,7,216,4,0,
333,7,218,4,0,
333,7,219,1,21,
333,7,219,4,0,
333,7,228,2,0,
333,7,237,4,0,
333,7,240,4,0,
333,7,241,4,0,
333,7,263,4,0,
333,7,287,1,41,
333,7,290,4,0,
333,7,310,1,8,
333,7,332,4,0,
333,8,19,4,0,
333,8,31,1,13,
333,8,36,1,28,
333,8,45,1,1,2
333,8,47,1,9,
333,8,54,1,23,
333,8,58,4,0,
333,8,64,1,1,1
333,8,76,4,0,
333,8,92,4,0,
333,8,97,2,0,
333,8,99,2,0,
333,8,104,4,0,
333,8,114,2,0,
333,8,119,1,36,
333,8,138,4,0,
333,8,156,4,0,
333,8,164,4,0,
333,8,168,4,0,
333,8,182,4,0,
333,8,195,1,50,
333,8,203,4,0,
333,8,207,4,0,
333,8,211,4,0,
333,8,213,4,0,
333,8,214,4,0,
333,8,216,4,0,
333,8,218,4,0,
333,8,219,1,18,
333,8,219,4,0,
333,8,228,2,0,
333,8,237,4,0,
333,8,240,4,0,
333,8,241,4,0,
333,8,244,4,0,
333,8,263,4,0,
333,8,287,1,40,
333,8,290,4,0,
333,8,297,2,0,
333,8,310,1,5,
333,8,332,4,0,
333,8,355,4,0,
333,8,363,1,32,
333,8,363,4,0,
333,8,365,4,0,
333,8,406,1,45,
333,8,406,4,0,
333,8,407,2,0,
333,8,445,4,0,
333,9,19,4,0,
333,9,31,1,13,
333,9,36,1,28,
333,9,45,1,1,2
333,9,47,1,9,
333,9,54,1,23,
333,9,58,4,0,
333,9,64,1,1,1
333,9,76,4,0,
333,9,92,4,0,
333,9,97,2,0,
333,9,99,2,0,
333,9,104,4,0,
333,9,114,2,0,
333,9,119,1,36,
333,9,129,3,0,
333,9,138,4,0,
333,9,156,4,0,
333,9,164,4,0,
333,9,168,4,0,
333,9,173,3,0,
333,9,182,4,0,
333,9,189,3,0,
333,9,195,1,50,
333,9,200,3,0,
333,9,203,4,0,
333,9,207,4,0,
333,9,211,4,0,
333,9,213,4,0,
333,9,214,4,0,
333,9,216,4,0,
333,9,218,4,0,
333,9,219,1,18,
333,9,219,4,0,
333,9,228,2,0,
333,9,237,4,0,
333,9,239,3,0,
333,9,240,4,0,
333,9,241,4,0,
333,9,244,4,0,
333,9,253,3,0,
333,9,257,3,0,
333,9,263,4,0,
333,9,287,1,40,
333,9,290,4,0,
333,9,297,2,0,
333,9,310,1,5,
333,9,314,3,0,
333,9,332,4,0,
333,9,355,4,0,
333,9,363,1,32,
333,9,363,4,0,
333,9,365,4,0,
333,9,406,1,45,
333,9,406,4,0,
333,9,407,2,0,
333,9,445,4,0,
333,9,466,3,0,
333,10,19,4,0,
333,10,31,1,13,
333,10,36,1,28,
333,10,45,1,1,2
333,10,47,1,9,
333,10,54,1,23,
333,10,58,4,0,
333,10,64,1,1,1
333,10,76,4,0,
333,10,92,4,0,
333,10,97,2,0,
333,10,99,2,0,
333,10,104,4,0,
333,10,114,2,0,
333,10,119,1,36,
333,10,129,3,0,
333,10,138,4,0,
333,10,156,4,0,
333,10,164,4,0,
333,10,168,4,0,
333,10,173,3,0,
333,10,182,4,0,
333,10,189,3,0,
333,10,195,1,50,
333,10,200,3,0,
333,10,203,4,0,
333,10,207,4,0,
333,10,211,4,0,
333,10,213,4,0,
333,10,214,4,0,
333,10,215,3,0,
333,10,216,4,0,
333,10,218,4,0,
333,10,219,1,18,
333,10,219,4,0,
333,10,228,2,0,
333,10,237,4,0,
333,10,239,3,0,
333,10,240,4,0,
333,10,241,4,0,
333,10,244,4,0,
333,10,253,3,0,
333,10,257,3,0,
333,10,263,4,0,
333,10,287,1,40,
333,10,290,4,0,
333,10,297,2,0,
333,10,310,1,5,
333,10,314,3,0,
333,10,332,4,0,
333,10,355,4,0,
333,10,363,1,32,
333,10,363,4,0,
333,10,365,4,0,
333,10,366,3,0,
333,10,384,2,0,
333,10,406,1,45,
333,10,406,4,0,
333,10,407,2,0,
333,10,445,4,0,
333,10,466,3,0,
333,11,19,4,0,
333,11,31,1,13,
333,11,36,1,28,
333,11,45,1,1,2
333,11,47,1,9,
333,11,54,1,23,
333,11,58,4,0,
333,11,64,1,1,1
333,11,76,4,0,
333,11,92,4,0,
333,11,97,2,0,
333,11,99,2,0,
333,11,104,4,0,
333,11,114,2,0,
333,11,119,1,36,
333,11,138,4,0,
333,11,156,4,0,
333,11,164,4,0,
333,11,168,4,0,
333,11,182,4,0,
333,11,195,1,55,
333,11,207,4,0,
333,11,211,2,0,
333,11,213,4,0,
333,11,216,4,0,
333,11,218,4,0,
333,11,219,1,18,
333,11,219,4,0,
333,11,228,2,0,
333,11,237,4,0,
333,11,240,4,0,
333,11,241,4,0,
333,11,244,4,0,
333,11,263,4,0,
333,11,287,1,45,
333,11,297,2,0,
333,11,304,2,0,
333,11,310,1,5,
333,11,332,4,0,
333,11,355,2,0,
333,11,363,1,32,
333,11,365,4,0,
333,11,384,2,0,
333,11,406,1,50,
333,11,407,2,0,
333,11,496,4,0,
333,11,497,4,0,
333,11,538,1,40,
333,12,19,4,0,
333,12,31,1,18,
333,12,36,1,31,
333,12,45,1,1,2
333,12,47,1,11,
333,12,54,1,28,
333,12,58,4,0,
333,12,64,1,1,1
333,12,76,4,0,
333,12,92,4,0,
333,12,104,4,0,
333,12,119,1,38,
333,12,156,4,0,
333,12,168,4,0,
333,12,182,4,0,
333,12,195,1,48,
333,12,211,4,0,
333,12,213,4,0,
333,12,216,4,0,
333,12,218,4,0,
333,12,219,1,21,
333,12,219,4,0,
333,12,237,4,0,
333,12,240,4,0,
333,12,241,4,0,
333,12,263,4,0,
333,12,287,1,41,
333,12,290,4,0,
333,12,310,1,8,
333,12,332,4,0,
333,13,19,4,0,
333,13,31,1,18,
333,13,34,3,0,
333,13,36,1,31,
333,13,38,3,0,
333,13,45,1,1,2
333,13,47,1,11,
333,13,54,1,28,
333,13,58,4,0,
333,13,64,1,1,1
333,13,76,4,0,
333,13,92,4,0,
333,13,102,3,0,
333,13,104,4,0,
333,13,119,1,38,
333,13,138,3,0,
333,13,143,3,0,
333,13,156,4,0,
333,13,164,3,0,
333,13,168,4,0,
333,13,182,4,0,
333,13,195,1,48,
333,13,207,3,0,
333,13,211,4,0,
333,13,213,4,0,
333,13,216,4,0,
333,13,218,4,0,
333,13,219,1,21,
333,13,219,4,0,
333,13,237,4,0,
333,13,240,4,0,
333,13,241,4,0,
333,13,263,4,0,
333,13,287,1,41,
333,13,290,4,0,
333,13,310,1,8,
333,13,332,4,0,
333,14,19,4,0,
333,14,31,1,10,
333,14,36,1,25,
333,14,45,1,1,2
333,14,47,1,8,
333,14,54,1,15,
333,14,58,4,0,
333,14,64,1,1,1
333,14,76,4,0,
333,14,92,4,0,
333,14,97,2,0,
333,14,99,2,0,
333,14,104,4,0,
333,14,114,2,0,
333,14,119,1,34,
333,14,138,4,0,
333,14,156,4,0,
333,14,164,4,0,
333,14,168,4,0,
333,14,173,3,0,
333,14,182,4,0,
333,14,195,1,48,
333,14,200,3,0,
333,14,207,4,0,
333,14,211,2,0,
333,14,213,4,0,
333,14,214,3,0,
333,14,215,3,0,
333,14,216,4,0,
333,14,218,4,0,
333,14,219,1,13,
333,14,219,4,0,
333,14,228,2,0,
333,14,237,4,0,
333,14,240,4,0,
333,14,241,4,0,
333,14,244,4,0,
333,14,253,3,0,
333,14,257,3,0,
333,14,263,4,0,
333,14,287,1,29,
333,14,297,2,0,
333,14,304,2,0,
333,14,304,3,0,
333,14,310,1,4,
333,14,332,4,0,
333,14,355,2,0,
333,14,355,3,0,
333,14,363,1,21,
333,14,365,4,0,
333,14,366,3,0,
333,14,384,2,0,
333,14,406,1,42,
333,14,406,3,0,
333,14,407,2,0,
333,14,496,1,18,
333,14,496,4,0,
333,14,497,4,0,
333,14,538,1,39,
333,15,19,4,0,
333,15,31,1,10,
333,15,36,1,25,
333,15,45,1,1,2
333,15,47,1,8,
333,15,54,1,15,
333,15,58,4,0,
333,15,64,1,1,1
333,15,76,4,0,
333,15,92,4,0,
333,15,97,2,0,
333,15,99,2,0,
333,15,104,4,0,
333,15,114,2,0,
333,15,119,1,34,
333,15,138,4,0,
333,15,156,4,0,
333,15,164,4,0,
333,15,168,4,0,
333,15,182,4,0,
333,15,195,1,48,
333,15,207,4,0,
333,15,211,2,0,
333,15,211,4,0,
333,15,213,4,0,
333,15,214,4,0,
333,15,216,4,0,
333,15,218,4,0,
333,15,219,1,13,
333,15,219,4,0,
333,15,228,2,0,
333,15,237,4,0,
333,15,240,4,0,
333,15,241,4,0,
333,15,244,4,0,
333,15,263,4,0,
333,15,287,1,29,
333,15,297,2,0,
333,15,304,2,0,
333,15,310,1,4,
333,15,332,4,0,
333,15,355,2,0,
333,15,355,4,0,
333,15,363,1,21,
333,15,384,2,0,
333,15,406,1,42,
333,15,407,2,0,
333,15,496,1,18,
333,15,496,4,0,
333,15,497,4,0,
333,15,538,1,39,
333,15,585,1,50,
333,15,590,4,0,
333,15,605,4,0,
333,16,19,4,0,
333,16,31,1,7,1
333,16,36,1,23,1
333,16,45,1,1,2
333,16,47,1,5,1
333,16,54,1,14,1
333,16,58,4,0,
333,16,64,1,1,1
333,16,76,4,0,
333,16,92,4,0,
333,16,97,2,0,
333,16,99,2,0,
333,16,104,4,0,
333,16,114,2,0,
333,16,119,1,30,1
333,16,138,4,0,
333,16,143,3,0,
333,16,156,4,0,
333,16,164,4,0,
333,16,168,4,0,
333,16,173,3,0,
333,16,182,4,0,
333,16,195,1,42,1
333,16,200,3,0,
333,16,207,4,0,
333,16,211,2,0,
333,16,211,4,0,
333,16,213,4,0,
333,16,214,4,0,
333,16,215,3,0,
333,16,216,4,0,
333,16,218,4,0,
333,16,219,1,9,1
333,16,219,4,0,
333,16,228,2,0,
333,16,237,4,0,
333,16,240,4,0,
333,16,241,4,0,
333,16,244,4,0,
333,16,253,3,0,
333,16,257,3,0,
333,16,263,4,0,
333,16,287,1,26,1
333,16,290,4,0,
333,16,297,2,0,
333,16,304,2,0,
333,16,304,3,0,
333,16,310,1,3,1
333,16,332,4,0,
333,16,355,2,0,
333,16,355,4,0,
333,16,363,1,20,1
333,16,366,3,0,
333,16,384,2,0,
333,16,406,1,38,1
333,16,406,3,0,
333,16,407,2,0,
333,16,496,1,17,1
333,16,496,4,0,
333,16,497,4,0,
333,16,538,1,34,1
333,16,574,1,11,1
333,16,585,1,46,1
333,16,590,4,0,
333,16,605,4,0,
333,17,19,4,0,
333,17,31,1,7,
333,17,36,1,23,
333,17,45,1,1,2
333,17,47,1,5,
333,17,54,1,14,
333,17,58,4,0,
333,17,64,1,1,1
333,17,76,4,0,
333,17,92,4,0,
333,17,97,2,0,
333,17,99,2,0,
333,17,104,4,0,
333,17,114,2,0,
333,17,119,1,30,
333,17,138,4,0,
333,17,156,4,0,
333,17,164,4,0,
333,17,168,4,0,
333,17,182,4,0,
333,17,195,1,42,
333,17,207,4,0,
333,17,211,2,0,
333,17,211,4,0,
333,17,213,4,0,
333,17,214,4,0,
333,17,216,4,0,
333,17,218,4,0,
333,17,219,1,9,
333,17,219,4,0,
333,17,228,2,0,
333,17,237,4,0,
333,17,240,4,0,
333,17,241,4,0,
333,17,244,4,0,
333,17,263,4,0,
333,17,287,1,26,
333,17,297,2,0,
333,17,304,2,0,
333,17,310,1,3,
333,17,332,4,0,
333,17,355,2,0,
333,17,355,4,0,
333,17,363,1,20,
333,17,384,2,0,
333,17,406,1,38,
333,17,407,2,0,
333,17,496,1,17,
333,17,496,4,0,
333,17,497,4,0,
333,17,538,1,34,
333,17,574,1,11,
333,17,585,1,46,
333,17,590,4,0,
333,17,605,4,0,
333,18,19,4,0,
333,18,31,1,7,
333,18,36,1,23,
333,18,45,1,1,2
333,18,47,1,5,
333,18,54,1,14,
333,18,58,4,0,
333,18,64,1,1,1
333,18,76,4,0,
333,18,92,4,0,
333,18,97,2,0,
333,18,99,2,0,
333,18,104,4,0,
333,18,114,2,0,
333,18,119,1,30,
333,18,138,4,0,
333,18,156,4,0,
333,18,164,4,0,
333,18,168,4,0,
333,18,182,4,0,
333,18,195,1,42,
333,18,207,4,0,
333,18,211,2,0,
333,18,211,4,0,
333,18,213,4,0,
333,18,214,4,0,
333,18,216,4,0,
333,18,218,4,0,
333,18,219,1,9,
333,18,219,4,0,
333,18,228,2,0,
333,18,237,4,0,
333,18,240,4,0,
333,18,241,4,0,
333,18,244,4,0,
333,18,263,4,0,
333,18,287,1,26,
333,18,297,2,0,
333,18,304,2,0,
333,18,310,1,3,
333,18,332,4,0,
333,18,355,2,0,
333,18,355,4,0,
333,18,363,1,20,
333,18,384,2,0,
333,18,406,1,38,
333,18,407,2,0,
333,18,496,1,17,
333,18,496,4,0,
333,18,497,4,0,
333,18,538,1,34,
333,18,574,1,11,
333,18,583,2,0,
333,18,585,1,46,
333,18,590,4,0,
333,18,605,4,0,
334,5,19,4,0,
334,5,31,1,18,
334,5,36,1,31,
334,5,45,1,1,2
334,5,46,4,0,
334,5,47,1,1,4
334,5,47,1,11,
334,5,53,4,0,
334,5,54,1,28,
334,5,58,4,0,
334,5,63,4,0,
334,5,64,1,1,1
334,5,76,4,0,
334,5,89,4,0,
334,5,92,4,0,
334,5,104,4,0,
334,5,126,4,0,
334,5,143,1,59,
334,5,156,4,0,
334,5,168,4,0,
334,5,182,4,0,
334,5,195,1,54,
334,5,211,4,0,
334,5,213,4,0,
334,5,216,4,0,
334,5,218,4,0,
334,5,219,1,21,
334,5,219,4,0,
334,5,225,1,35,
334,5,231,4,0,
334,5,237,4,0,
334,5,240,4,0,
334,5,241,4,0,
334,5,249,4,0,
334,5,263,4,0,
334,5,287,1,45,
334,5,290,4,0,
334,5,310,1,1,3
334,5,310,1,8,
334,5,332,4,0,
334,5,337,4,0,
334,5,349,1,40,
334,6,19,4,0,
334,6,31,1,18,
334,6,34,3,0,
334,6,36,1,31,
334,6,38,3,0,
334,6,45,1,1,2
334,6,46,4,0,
334,6,47,1,1,4
334,6,47,1,11,
334,6,53,4,0,
334,6,54,1,28,
334,6,58,4,0,
334,6,63,4,0,
334,6,64,1,1,1
334,6,76,4,0,
334,6,89,4,0,
334,6,92,4,0,
334,6,102,3,0,
334,6,104,4,0,
334,6,126,4,0,
334,6,129,3,0,
334,6,138,3,0,
334,6,143,1,59,
334,6,156,4,0,
334,6,164,3,0,
334,6,168,4,0,
334,6,173,3,0,
334,6,182,4,0,
334,6,189,3,0,
334,6,195,1,54,
334,6,203,3,0,
334,6,207,3,0,
334,6,211,4,0,
334,6,213,4,0,
334,6,214,3,0,
334,6,216,4,0,
334,6,218,4,0,
334,6,219,1,21,
334,6,219,4,0,
334,6,225,1,35,
334,6,231,4,0,
334,6,237,4,0,
334,6,240,4,0,
334,6,241,4,0,
334,6,244,3,0,
334,6,249,4,0,
334,6,263,4,0,
334,6,287,1,45,
334,6,290,4,0,
334,6,310,1,1,3
334,6,310,1,8,
334,6,332,4,0,
334,6,337,4,0,
334,6,349,1,40,
334,7,19,4,0,
334,7,31,1,18,
334,7,34,3,0,
334,7,36,1,31,
334,7,38,3,0,
334,7,45,1,1,2
334,7,46,4,0,
334,7,47,1,1,4
334,7,47,1,11,
334,7,53,4,0,
334,7,54,1,28,
334,7,58,4,0,
334,7,63,4,0,
334,7,64,1,1,1
334,7,76,4,0,
334,7,89,4,0,
334,7,92,4,0,
334,7,102,3,0,
334,7,104,4,0,
334,7,126,4,0,
334,7,138,3,0,
334,7,143,1,59,
334,7,156,4,0,
334,7,164,3,0,
334,7,168,4,0,
334,7,182,4,0,
334,7,195,1,54,
334,7,211,4,0,
334,7,213,4,0,
334,7,216,4,0,
334,7,218,4,0,
334,7,219,1,21,
334,7,219,4,0,
334,7,225,1,35,
334,7,231,4,0,
334,7,237,4,0,
334,7,240,4,0,
334,7,241,4,0,
334,7,249,4,0,
334,7,263,4,0,
334,7,287,1,45,
334,7,290,4,0,
334,7,310,1,1,3
334,7,310,1,8,
334,7,332,4,0,
334,7,337,4,0,
334,7,349,1,40,
334,8,19,4,0,
334,8,31,1,13,
334,8,36,1,28,
334,8,45,1,1,3
334,8,46,4,0,
334,8,47,1,1,5
334,8,47,1,9,
334,8,53,4,0,
334,8,54,1,23,
334,8,58,4,0,
334,8,63,4,0,
334,8,64,1,1,2
334,8,76,4,0,
334,8,89,4,0,
334,8,92,4,0,
334,8,104,4,0,
334,8,126,4,0,
334,8,138,4,0,
334,8,143,1,70,
334,8,156,4,0,
334,8,164,4,0,
334,8,168,4,0,
334,8,182,4,0,
334,8,195,1,62,
334,8,203,4,0,
334,8,207,4,0,
334,8,211,4,0,
334,8,213,4,0,
334,8,214,4,0,
334,8,216,4,0,
334,8,218,4,0,
334,8,219,1,18,
334,8,219,4,0,
334,8,225,1,35,
334,8,231,4,0,
334,8,237,4,0,
334,8,240,4,0,
334,8,241,4,0,
334,8,244,4,0,
334,8,249,4,0,
334,8,263,4,0,
334,8,287,1,46,
334,8,290,4,0,
334,8,310,1,1,4
334,8,310,1,5,
334,8,332,4,0,
334,8,337,4,0,
334,8,349,1,39,
334,8,355,4,0,
334,8,363,1,32,
334,8,363,4,0,
334,8,365,1,1,1
334,8,365,4,0,
334,8,406,1,54,
334,8,406,4,0,
334,8,416,4,0,
334,8,434,3,0,
334,8,445,4,0,
334,9,19,4,0,
334,9,31,1,13,
334,9,36,1,28,
334,9,45,1,1,3
334,9,46,4,0,
334,9,47,1,1,5
334,9,47,1,9,
334,9,53,4,0,
334,9,54,1,23,
334,9,58,4,0,
334,9,63,4,0,
334,9,64,1,1,2
334,9,76,4,0,
334,9,89,4,0,
334,9,92,4,0,
334,9,104,4,0,
334,9,126,4,0,
334,9,129,3,0,
334,9,138,4,0,
334,9,143,1,70,
334,9,156,4,0,
334,9,164,4,0,
334,9,168,4,0,
334,9,173,3,0,
334,9,182,4,0,
334,9,189,3,0,
334,9,195,1,62,
334,9,200,3,0,
334,9,203,4,0,
334,9,207,4,0,
334,9,211,4,0,
334,9,213,4,0,
334,9,214,4,0,
334,9,216,4,0,
334,9,218,4,0,
334,9,219,1,18,
334,9,219,4,0,
334,9,225,1,35,
334,9,231,4,0,
334,9,237,4,0,
334,9,239,3,0,
334,9,240,4,0,
334,9,241,4,0,
334,9,244,4,0,
334,9,249,4,0,
334,9,253,3,0,
334,9,257,3,0,
334,9,263,4,0,
334,9,287,1,46,
334,9,290,4,0,
334,9,310,1,1,4
334,9,310,1,5,
334,9,314,3,0,
334,9,332,4,0,
334,9,337,4,0,
334,9,349,1,39,
334,9,355,4,0,
334,9,363,1,32,
334,9,363,4,0,
334,9,365,1,1,1
334,9,365,4,0,
334,9,406,1,54,
334,9,406,4,0,
334,9,416,4,0,
334,9,434,3,0,
334,9,445,4,0,
334,9,466,3,0,
334,10,19,4,0,
334,10,31,1,13,
334,10,36,1,28,
334,10,45,1,1,3
334,10,46,4,0,
334,10,47,1,1,5
334,10,47,1,9,
334,10,53,4,0,
334,10,54,1,23,
334,10,58,4,0,
334,10,63,4,0,
334,10,64,1,1,2
334,10,76,4,0,
334,10,89,4,0,
334,10,92,4,0,
334,10,104,4,0,
334,10,126,4,0,
334,10,129,3,0,
334,10,138,4,0,
334,10,143,1,70,
334,10,143,3,0,
334,10,156,4,0,
334,10,164,4,0,
334,10,168,4,0,
334,10,173,3,0,
334,10,182,4,0,
334,10,189,3,0,
334,10,195,1,62,
334,10,200,3,0,
334,10,203,4,0,
334,10,207,4,0,
334,10,211,4,0,
334,10,213,4,0,
334,10,214,4,0,
334,10,215,3,0,
334,10,216,4,0,
334,10,218,4,0,
334,10,219,1,18,
334,10,219,4,0,
334,10,225,1,35,
334,10,231,4,0,
334,10,237,4,0,
334,10,239,3,0,
334,10,240,4,0,
334,10,241,4,0,
334,10,244,4,0,
334,10,249,4,0,
334,10,253,3,0,
334,10,257,3,0,
334,10,263,4,0,
334,10,287,1,46,
334,10,290,4,0,
334,10,310,1,1,4
334,10,310,1,5,
334,10,314,3,0,
334,10,332,4,0,
334,10,337,4,0,
334,10,349,1,39,
334,10,355,4,0,
334,10,363,1,32,
334,10,363,4,0,
334,10,365,1,1,1
334,10,365,4,0,
334,10,366,3,0,
334,10,406,1,54,
334,10,406,4,0,
334,10,416,4,0,
334,10,434,3,0,
334,10,445,4,0,
334,10,466,3,0,
334,11,19,4,0,
334,11,31,1,13,
334,11,36,1,28,
334,11,45,1,1,3
334,11,46,4,0,
334,11,47,1,1,5
334,11,47,1,9,
334,11,53,4,0,
334,11,54,1,23,
334,11,58,4,0,
334,11,63,4,0,
334,11,64,1,1,2
334,11,76,4,0,
334,11,89,4,0,
334,11,92,4,0,
334,11,104,4,0,
334,11,126,4,0,
334,11,138,4,0,
334,11,143,1,77,
334,11,156,4,0,
334,11,164,4,0,
334,11,168,4,0,
334,11,182,4,0,
334,11,195,1,70,
334,11,207,4,0,
334,11,213,4,0,
334,11,216,4,0,
334,11,218,4,0,
334,11,219,1,18,
334,11,219,4,0,
334,11,225,1,35,
334,11,237,4,0,
334,11,240,4,0,
334,11,241,4,0,
334,11,244,4,0,
334,11,249,4,0,
334,11,263,4,0,
334,11,287,1,54,
334,11,310,1,1,4
334,11,310,1,5,
334,11,332,4,0,
334,11,337,4,0,
334,11,349,1,39,
334,11,363,1,32,
334,11,365,1,1,1
334,11,365,4,0,
334,11,406,1,62,
334,11,416,4,0,
334,11,434,3,0,
334,11,468,4,0,
334,11,496,4,0,
334,11,497,4,0,
334,11,510,4,0,
334,11,523,4,0,
334,11,538,1,46,
334,12,19,4,0,
334,12,31,1,18,
334,12,36,1,31,
334,12,45,1,1,2
334,12,46,4,0,
334,12,47,1,1,4
334,12,47,1,11,
334,12,53,4,0,
334,12,54,1,28,
334,12,58,4,0,
334,12,63,4,0,
334,12,64,1,1,1
334,12,76,4,0,
334,12,89,4,0,
334,12,92,4,0,
334,12,104,4,0,
334,12,126,4,0,
334,12,143,1,59,
334,12,156,4,0,
334,12,168,4,0,
334,12,182,4,0,
334,12,195,1,54,
334,12,211,4,0,
334,12,213,4,0,
334,12,216,4,0,
334,12,218,4,0,
334,12,219,1,21,
334,12,219,4,0,
334,12,225,1,35,
334,12,231,4,0,
334,12,237,4,0,
334,12,240,4,0,
334,12,241,4,0,
334,12,249,4,0,
334,12,263,4,0,
334,12,287,1,45,
334,12,290,4,0,
334,12,310,1,1,3
334,12,310,1,8,
334,12,332,4,0,
334,12,337,4,0,
334,12,349,1,40,
334,13,19,4,0,
334,13,31,1,18,
334,13,34,3,0,
334,13,36,1,31,
334,13,38,3,0,
334,13,45,1,1,2
334,13,46,4,0,
334,13,47,1,1,4
334,13,47,1,11,
334,13,53,4,0,
334,13,54,1,28,
334,13,58,4,0,
334,13,63,4,0,
334,13,64,1,1,1
334,13,76,4,0,
334,13,89,4,0,
334,13,92,4,0,
334,13,102,3,0,
334,13,104,4,0,
334,13,126,4,0,
334,13,138,3,0,
334,13,143,1,59,
334,13,143,3,0,
334,13,156,4,0,
334,13,164,3,0,
334,13,168,4,0,
334,13,182,4,0,
334,13,195,1,54,
334,13,207,3,0,
334,13,211,4,0,
334,13,213,4,0,
334,13,216,4,0,
334,13,218,4,0,
334,13,219,1,21,
334,13,219,4,0,
334,13,225,1,35,
334,13,231,4,0,
334,13,237,4,0,
334,13,240,4,0,
334,13,241,4,0,
334,13,249,4,0,
334,13,263,4,0,
334,13,287,1,45,
334,13,290,4,0,
334,13,310,1,1,3
334,13,310,1,8,
334,13,332,4,0,
334,13,337,4,0,
334,13,349,1,40,
334,14,19,4,0,
334,14,31,1,10,
334,14,36,1,25,
334,14,45,1,1,3
334,14,46,4,0,
334,14,47,1,1,5
334,14,47,1,8,
334,14,53,4,0,
334,14,54,1,15,
334,14,58,4,0,
334,14,63,4,0,
334,14,64,1,1,2
334,14,76,4,0,
334,14,89,4,0,
334,14,92,4,0,
334,14,104,4,0,
334,14,126,4,0,
334,14,138,4,0,
334,14,143,1,64,
334,14,143,3,0,
334,14,156,4,0,
334,14,164,4,0,
334,14,168,4,0,
334,14,173,3,0,
334,14,182,4,0,
334,14,195,1,57,
334,14,200,3,0,
334,14,207,4,0,
334,14,213,4,0,
334,14,214,3,0,
334,14,215,3,0,
334,14,216,4,0,
334,14,218,4,0,
334,14,219,1,13,
334,14,219,4,0,
334,14,225,1,35,
334,14,231,3,0,
334,14,237,4,0,
334,14,240,4,0,
334,14,241,4,0,
334,14,244,4,0,
334,14,249,4,0,
334,14,253,3,0,
334,14,257,3,0,
334,14,263,4,0,
334,14,287,1,29,
334,14,304,3,0,
334,14,310,1,1,4
334,14,310,1,4,
334,14,332,4,0,
334,14,337,4,0,
334,14,349,1,34,
334,14,355,3,0,
334,14,363,1,21,
334,14,365,1,1,1
334,14,365,4,0,
334,14,366,3,0,
334,14,406,1,48,
334,14,406,3,0,
334,14,416,4,0,
334,14,434,3,0,
334,14,468,4,0,
334,14,472,3,0,
334,14,496,1,18,
334,14,496,4,0,
334,14,497,4,0,
334,14,510,4,0,
334,14,523,4,0,
334,14,538,1,42,
334,15,19,4,0,
334,15,31,1,10,
334,15,36,1,25,
334,15,45,1,1,4
334,15,46,4,0,
334,15,47,1,1,6
334,15,47,1,8,
334,15,53,4,0,
334,15,54,1,15,
334,15,58,4,0,
334,15,63,4,0,
334,15,64,1,1,3
334,15,76,4,0,
334,15,89,4,0,
334,15,92,4,0,
334,15,104,4,0,
334,15,126,4,0,
334,15,138,4,0,
334,15,143,1,1,1
334,15,143,1,64,
334,15,156,4,0,
334,15,164,4,0,
334,15,168,4,0,
334,15,182,4,0,
334,15,195,1,57,
334,15,207,4,0,
334,15,211,4,0,
334,15,213,4,0,
334,15,214,4,0,
334,15,216,4,0,
334,15,218,4,0,
334,15,219,1,13,
334,15,219,4,0,
334,15,225,1,35,
334,15,237,4,0,
334,15,240,4,0,
334,15,241,4,0,
334,15,244,4,0,
334,15,249,4,0,
334,15,263,4,0,
334,15,287,1,29,
334,15,310,1,1,5
334,15,310,1,4,
334,15,332,4,0,
334,15,337,4,0,
334,15,349,1,34,
334,15,355,4,0,
334,15,363,1,21,
334,15,365,1,1,2
334,15,406,1,48,
334,15,416,4,0,
334,15,434,3,0,
334,15,468,4,0,
334,15,496,1,18,
334,15,496,4,0,
334,15,497,4,0,
334,15,510,4,0,
334,15,523,4,0,
334,15,538,1,42,
334,15,585,1,60,
334,15,590,4,0,
334,15,605,4,0,
334,16,19,4,0,
334,16,31,1,7,1
334,16,36,1,23,1
334,16,45,1,1,4
334,16,46,4,0,
334,16,47,1,1,6
334,16,47,1,5,1
334,16,53,4,0,
334,16,54,1,14,1
334,16,58,4,0,
334,16,63,4,0,
334,16,64,1,1,3
334,16,76,4,0,
334,16,89,4,0,
334,16,92,4,0,
334,16,104,4,0,
334,16,126,4,0,
334,16,138,4,0,
334,16,143,1,1,1
334,16,143,1,59,1
334,16,143,3,0,
334,16,156,4,0,
334,16,164,4,0,
334,16,168,4,0,
334,16,173,3,0,
334,16,182,4,0,
334,16,195,1,46,1
334,16,200,3,0,
334,16,207,4,0,
334,16,211,4,0,
334,16,213,4,0,
334,16,214,4,0,
334,16,215,3,0,
334,16,216,4,0,
334,16,218,4,0,
334,16,219,1,9,1
334,16,219,4,0,
334,16,225,1,35,1
334,16,231,3,0,
334,16,237,4,0,
334,16,240,4,0,
334,16,241,4,0,
334,16,244,4,0,
334,16,249,4,0,
334,16,253,3,0,
334,16,257,3,0,
334,16,263,4,0,
334,16,287,1,26,1
334,16,290,4,0,
334,16,304,3,0,
334,16,310,1,1,5
334,16,310,1,3,1
334,16,332,4,0,
334,16,337,4,0,
334,16,349,1,30,1
334,16,355,4,0,
334,16,363,1,20,1
334,16,365,1,1,2
334,16,366,3,0,
334,16,406,1,40,1
334,16,406,3,0,
334,16,416,4,0,
334,16,434,3,0,
334,16,468,4,0,
334,16,472,3,0,
334,16,496,1,17,1
334,16,496,4,0,
334,16,497,4,0,
334,16,510,4,0,
334,16,523,4,0,
334,16,538,1,34,1
334,16,574,1,11,1
334,16,585,1,52,1
334,16,590,4,0,
334,16,605,4,0,
334,17,19,4,0,
334,17,31,1,7,
334,17,36,1,23,
334,17,45,1,1,5
334,17,46,4,0,
334,17,47,1,1,7
334,17,47,1,5,
334,17,53,4,0,
334,17,54,1,14,
334,17,58,4,0,
334,17,63,4,0,
334,17,64,1,1,4
334,17,76,4,0,
334,17,89,4,0,
334,17,92,4,0,
334,17,104,4,0,
334,17,126,4,0,
334,17,138,4,0,
334,17,143,1,1,2
334,17,143,1,59,
334,17,156,4,0,
334,17,164,4,0,
334,17,168,4,0,
334,17,182,4,0,
334,17,195,1,46,
334,17,207,4,0,
334,17,211,4,0,
334,17,213,4,0,
334,17,214,4,0,
334,17,216,4,0,
334,17,218,4,0,
334,17,219,1,9,
334,17,219,4,0,
334,17,225,1,0,
334,17,225,1,1,1
334,17,237,4,0,
334,17,240,4,0,
334,17,241,4,0,
334,17,244,4,0,
334,17,263,4,0,
334,17,287,1,26,
334,17,310,1,1,6
334,17,310,1,3,
334,17,332,4,0,
334,17,337,4,0,
334,17,349,1,30,
334,17,355,4,0,
334,17,363,1,20,
334,17,365,1,1,3
334,17,406,1,40,
334,17,416,4,0,
334,17,434,3,0,
334,17,496,1,17,
334,17,496,4,0,
334,17,497,4,0,
334,17,523,4,0,
334,17,538,1,34,
334,17,574,1,11,
334,17,585,1,52,
334,17,590,4,0,
334,17,605,4,0,
334,18,19,4,0,
334,18,31,1,7,
334,18,36,1,23,
334,18,45,1,1,5
334,18,46,4,0,
334,18,47,1,1,7
334,18,47,1,5,
334,18,53,4,0,
334,18,54,1,14,
334,18,58,4,0,
334,18,63,4,0,
334,18,64,1,1,4
334,18,76,4,0,
334,18,89,4,0,
334,18,92,4,0,
334,18,104,4,0,
334,18,126,4,0,
334,18,138,4,0,
334,18,143,1,1,2
334,18,143,1,59,
334,18,156,4,0,
334,18,164,4,0,
334,18,168,4,0,
334,18,182,4,0,
334,18,195,1,46,
334,18,207,4,0,
334,18,211,4,0,
334,18,213,4,0,
334,18,214,4,0,
334,18,216,4,0,
334,18,218,4,0,
334,18,219,1,9,
334,18,219,4,0,
334,18,225,1,0,
334,18,225,1,1,1
334,18,237,4,0,
334,18,240,4,0,
334,18,241,4,0,
334,18,244,4,0,
334,18,263,4,0,
334,18,287,1,26,
334,18,310,1,1,6
334,18,310,1,3,
334,18,332,4,0,
334,18,337,4,0,
334,18,349,1,30,
334,18,355,4,0,
334,18,363,1,20,
334,18,365,1,1,3
334,18,406,1,40,
334,18,416,4,0,
334,18,434,3,0,
334,18,496,1,17,
334,18,496,4,0,
334,18,497,4,0,
334,18,523,4,0,
334,18,538,1,34,
334,18,574,1,11,
334,18,585,1,52,
334,18,590,4,0,
334,18,605,4,0,
335,5,10,1,1,
335,5,13,2,0,
335,5,14,1,10,
335,5,24,2,0,
335,5,43,1,4,
335,5,46,2,0,
335,5,46,4,0,
335,5,53,4,0,
335,5,58,4,0,
335,5,59,4,0,
335,5,68,2,0,
335,5,70,4,0,
335,5,76,4,0,
335,5,85,4,0,
335,5,87,4,0,
335,5,91,4,0,
335,5,92,4,0,
335,5,98,1,7,
335,5,104,4,0,
335,5,126,4,0,
335,5,156,4,0,
335,5,163,1,19,
335,5,168,4,0,
335,5,174,2,0,
335,5,175,2,0,
335,5,182,4,0,
335,5,197,1,46,
335,5,202,4,0,
335,5,206,1,55,
335,5,210,1,13,
335,5,213,4,0,
335,5,216,4,0,
335,5,218,4,0,
335,5,228,1,25,
335,5,231,4,0,
335,5,237,4,0,
335,5,240,4,0,
335,5,241,4,0,
335,5,247,4,0,
335,5,249,4,0,
335,5,263,4,0,
335,5,264,4,0,
335,5,269,1,37,
335,5,269,4,0,
335,5,280,4,0,
335,5,290,4,0,
335,5,306,1,31,
335,5,332,4,0,
335,5,351,4,0,
335,5,352,4,0,
335,6,5,3,0,
335,6,7,3,0,
335,6,8,3,0,
335,6,9,3,0,
335,6,10,1,1,
335,6,13,2,0,
335,6,14,1,10,
335,6,14,3,0,
335,6,24,2,0,
335,6,25,3,0,
335,6,34,3,0,
335,6,38,3,0,
335,6,43,1,4,
335,6,46,2,0,
335,6,46,4,0,
335,6,53,4,0,
335,6,58,4,0,
335,6,59,4,0,
335,6,68,2,0,
335,6,68,3,0,
335,6,69,3,0,
335,6,70,4,0,
335,6,76,4,0,
335,6,85,4,0,
335,6,86,3,0,
335,6,87,4,0,
335,6,91,4,0,
335,6,92,4,0,
335,6,98,1,7,
335,6,102,3,0,
335,6,104,4,0,
335,6,111,3,0,
335,6,126,4,0,
335,6,129,3,0,
335,6,156,4,0,
335,6,157,3,0,
335,6,163,1,19,
335,6,164,3,0,
335,6,168,4,0,
335,6,173,3,0,
335,6,174,2,0,
335,6,175,2,0,
335,6,182,4,0,
335,6,189,3,0,
335,6,196,3,0,
335,6,197,1,46,
335,6,202,4,0,
335,6,203,3,0,
335,6,205,3,0,
335,6,206,1,55,
335,6,207,3,0,
335,6,210,1,13,
335,6,210,3,0,
335,6,213,4,0,
335,6,214,3,0,
335,6,216,4,0,
335,6,218,4,0,
335,6,223,3,0,
335,6,228,1,25,
335,6,231,4,0,
335,6,237,4,0,
335,6,240,4,0,
335,6,241,4,0,
335,6,247,4,0,
335,6,249,4,0,
335,6,263,4,0,
335,6,264,4,0,
335,6,269,1,37,
335,6,269,4,0,
335,6,280,4,0,
335,6,290,4,0,
335,6,306,1,31,
335,6,332,4,0,
335,6,351,4,0,
335,6,352,4,0,
335,7,5,3,0,
335,7,10,1,1,
335,7,13,2,0,
335,7,14,1,10,
335,7,14,3,0,
335,7,24,2,0,
335,7,25,3,0,
335,7,34,3,0,
335,7,38,3,0,
335,7,43,1,4,
335,7,46,2,0,
335,7,46,4,0,
335,7,53,4,0,
335,7,58,4,0,
335,7,59,4,0,
335,7,68,2,0,
335,7,68,3,0,
335,7,69,3,0,
335,7,70,4,0,
335,7,76,4,0,
335,7,85,4,0,
335,7,86,3,0,
335,7,87,4,0,
335,7,91,4,0,
335,7,92,4,0,
335,7,98,1,7,
335,7,102,3,0,
335,7,104,4,0,
335,7,126,4,0,
335,7,156,4,0,
335,7,157,3,0,
335,7,163,1,19,
335,7,164,3,0,
335,7,168,4,0,
335,7,174,2,0,
335,7,175,2,0,
335,7,182,4,0,
335,7,197,1,46,
335,7,202,4,0,
335,7,206,1,55,
335,7,210,1,13,
335,7,213,4,0,
335,7,216,4,0,
335,7,218,4,0,
335,7,228,1,25,
335,7,231,4,0,
335,7,237,4,0,
335,7,240,4,0,
335,7,241,4,0,
335,7,247,4,0,
335,7,249,4,0,
335,7,263,4,0,
335,7,264,4,0,
335,7,269,1,37,
335,7,269,4,0,
335,7,280,4,0,
335,7,290,4,0,
335,7,306,1,31,
335,7,332,4,0,
335,7,351,4,0,
335,7,352,4,0,
335,8,10,1,1,1
335,8,13,2,0,
335,8,14,1,9,
335,8,14,4,0,
335,8,24,2,0,
335,8,43,1,1,2
335,8,46,2,0,
335,8,46,4,0,
335,8,53,4,0,
335,8,58,4,0,
335,8,59,4,0,
335,8,68,2,0,
335,8,70,4,0,
335,8,76,4,0,
335,8,85,4,0,
335,8,87,4,0,
335,8,91,4,0,
335,8,92,4,0,
335,8,98,1,5,
335,8,104,4,0,
335,8,126,4,0,
335,8,154,2,0,
335,8,156,4,0,
335,8,157,4,0,
335,8,163,1,18,
335,8,164,4,0,
335,8,168,4,0,
335,8,174,2,0,
335,8,175,2,0,
335,8,182,4,0,
335,8,197,1,40,
335,8,202,4,0,
335,8,203,4,0,
335,8,206,1,44,
335,8,206,4,0,
335,8,207,4,0,
335,8,210,1,14,
335,8,213,4,0,
335,8,214,4,0,
335,8,216,4,0,
335,8,218,4,0,
335,8,228,1,22,
335,8,231,4,0,
335,8,232,2,0,
335,8,237,4,0,
335,8,240,4,0,
335,8,241,4,0,
335,8,247,4,0,
335,8,249,4,0,
335,8,263,4,0,
335,8,264,4,0,
335,8,269,1,35,
335,8,269,4,0,
335,8,280,4,0,
335,8,290,4,0,
335,8,306,1,31,
335,8,317,4,0,
335,8,332,4,0,
335,8,351,4,0,
335,8,352,4,0,
335,8,363,4,0,
335,8,370,1,53,
335,8,371,4,0,
335,8,373,1,27,
335,8,373,4,0,
335,8,374,4,0,
335,8,398,4,0,
335,8,400,2,0,
335,8,404,1,48,
335,8,404,4,0,
335,8,411,4,0,
335,8,421,4,0,
335,8,431,4,0,
335,8,445,4,0,
335,8,458,2,0,
335,9,7,3,0,
335,9,8,3,0,
335,9,9,3,0,
335,9,10,1,1,1
335,9,13,2,0,
335,9,14,1,9,
335,9,14,4,0,
335,9,24,2,0,
335,9,43,1,1,2
335,9,46,2,0,
335,9,46,4,0,
335,9,53,4,0,
335,9,58,4,0,
335,9,59,4,0,
335,9,68,2,0,
335,9,70,4,0,
335,9,76,4,0,
335,9,85,4,0,
335,9,87,4,0,
335,9,91,4,0,
335,9,92,4,0,
335,9,98,1,5,
335,9,104,4,0,
335,9,126,4,0,
335,9,129,3,0,
335,9,154,2,0,
335,9,156,4,0,
335,9,157,4,0,
335,9,163,1,18,
335,9,164,4,0,
335,9,168,4,0,
335,9,173,3,0,
335,9,174,2,0,
335,9,175,2,0,
335,9,182,4,0,
335,9,189,3,0,
335,9,196,3,0,
335,9,197,1,40,
335,9,202,4,0,
335,9,203,4,0,
335,9,205,3,0,
335,9,206,1,44,
335,9,206,4,0,
335,9,207,4,0,
335,9,210,1,14,
335,9,210,3,0,
335,9,213,4,0,
335,9,214,4,0,
335,9,216,4,0,
335,9,218,4,0,
335,9,228,1,22,
335,9,231,4,0,
335,9,232,2,0,
335,9,237,4,0,
335,9,240,4,0,
335,9,241,4,0,
335,9,247,4,0,
335,9,249,4,0,
335,9,263,4,0,
335,9,264,4,0,
335,9,269,1,35,
335,9,269,4,0,
335,9,280,4,0,
335,9,282,3,0,
335,9,283,3,0,
335,9,290,4,0,
335,9,306,1,31,
335,9,317,4,0,
335,9,332,4,0,
335,9,351,4,0,
335,9,352,4,0,
335,9,363,4,0,
335,9,370,1,53,
335,9,371,4,0,
335,9,373,1,27,
335,9,373,4,0,
335,9,374,4,0,
335,9,387,3,0,
335,9,398,4,0,
335,9,400,2,0,
335,9,404,1,48,
335,9,404,4,0,
335,9,411,4,0,
335,9,421,4,0,
335,9,431,4,0,
335,9,445,4,0,
335,9,458,2,0,
335,10,7,3,0,
335,10,8,3,0,
335,10,9,3,0,
335,10,10,1,1,1
335,10,13,2,0,
335,10,14,1,9,
335,10,14,4,0,
335,10,24,2,0,
335,10,29,3,0,
335,10,43,1,1,2
335,10,46,2,0,
335,10,46,4,0,
335,10,50,2,0,
335,10,53,4,0,
335,10,58,4,0,
335,10,59,4,0,
335,10,67,3,0,
335,10,68,2,0,
335,10,70,4,0,
335,10,76,4,0,
335,10,85,4,0,
335,10,87,4,0,
335,10,91,4,0,
335,10,92,4,0,
335,10,98,1,5,
335,10,104,4,0,
335,10,126,4,0,
335,10,129,3,0,
335,10,154,2,0,
335,10,156,4,0,
335,10,157,4,0,
335,10,163,1,18,
335,10,164,4,0,
335,10,168,4,0,
335,10,173,3,0,
335,10,174,2,0,
335,10,175,2,0,
335,10,182,4,0,
335,10,189,3,0,
335,10,196,3,0,
335,10,197,1,40,
335,10,202,4,0,
335,10,203,4,0,
335,10,205,3,0,
335,10,206,1,44,
335,10,206,4,0,
335,10,207,4,0,
335,10,210,1,14,
335,10,210,3,0,
335,10,213,4,0,
335,10,214,4,0,
335,10,216,4,0,
335,10,218,4,0,
335,10,228,1,22,
335,10,231,4,0,
335,10,232,2,0,
335,10,237,4,0,
335,10,240,4,0,
335,10,241,4,0,
335,10,247,4,0,
335,10,249,4,0,
335,10,263,4,0,
335,10,264,4,0,
335,10,269,1,35,
335,10,269,4,0,
335,10,280,4,0,
335,10,282,3,0,
335,10,283,3,0,
335,10,290,4,0,
335,10,306,1,31,
335,10,317,4,0,
335,10,332,4,0,
335,10,351,4,0,
335,10,352,4,0,
335,10,363,4,0,
335,10,370,1,53,
335,10,371,4,0,
335,10,373,1,27,
335,10,373,4,0,
335,10,374,4,0,
335,10,387,3,0,
335,10,398,4,0,
335,10,400,2,0,
335,10,404,1,48,
335,10,404,4,0,
335,10,411,4,0,
335,10,421,4,0,
335,10,431,4,0,
335,10,445,4,0,
335,10,458,2,0,
335,11,10,1,1,1
335,11,13,2,0,
335,11,14,1,9,
335,11,14,4,0,
335,11,24,2,0,
335,11,43,1,1,2
335,11,46,4,0,
335,11,50,2,0,
335,11,53,4,0,
335,11,58,4,0,
335,11,59,4,0,
335,11,68,2,0,
335,11,70,4,0,
335,11,76,4,0,
335,11,85,4,0,
335,11,87,4,0,
335,11,91,4,0,
335,11,92,4,0,
335,11,98,1,5,
335,11,104,4,0,
335,11,126,4,0,
335,11,154,2,0,
335,11,156,4,0,
335,11,157,4,0,
335,11,163,1,18,
335,11,164,4,0,
335,11,168,4,0,
335,11,174,2,0,
335,11,175,2,0,
335,11,182,4,0,
335,11,197,1,40,
335,11,206,1,44,
335,11,206,4,0,
335,11,207,4,0,
335,11,210,1,14,
335,11,213,4,0,
335,11,216,4,0,
335,11,218,4,0,
335,11,228,1,22,
335,11,231,2,0,
335,11,232,2,0,
335,11,237,4,0,
335,11,240,4,0,
335,11,241,4,0,
335,11,247,4,0,
335,11,249,4,0,
335,11,263,4,0,
335,11,269,1,35,
335,11,269,4,0,
335,11,280,4,0,
335,11,306,1,31,
335,11,317,4,0,
335,11,332,4,0,
335,11,364,2,0,
335,11,370,1,53,
335,11,371,4,0,
335,11,373,1,27,
335,11,373,4,0,
335,11,374,4,0,
335,11,398,4,0,
335,11,400,2,0,
335,11,404,1,48,
335,11,404,4,0,
335,11,411,4,0,
335,11,421,4,0,
335,11,458,2,0,
335,11,468,4,0,
335,11,496,4,0,
335,11,510,4,0,
335,11,514,4,0,
335,11,515,2,0,
335,11,526,4,0,
335,12,10,1,1,
335,12,14,1,10,
335,12,43,1,4,
335,12,46,4,0,
335,12,53,4,0,
335,12,58,4,0,
335,12,59,4,0,
335,12,70,4,0,
335,12,76,4,0,
335,12,85,4,0,
335,12,87,4,0,
335,12,91,4,0,
335,12,92,4,0,
335,12,98,1,7,
335,12,104,4,0,
335,12,126,4,0,
335,12,156,4,0,
335,12,163,1,19,
335,12,168,4,0,
335,12,182,4,0,
335,12,197,1,46,
335,12,202,4,0,
335,12,206,1,55,
335,12,210,1,13,
335,12,213,4,0,
335,12,216,4,0,
335,12,218,4,0,
335,12,228,1,25,
335,12,231,4,0,
335,12,237,4,0,
335,12,240,4,0,
335,12,241,4,0,
335,12,247,4,0,
335,12,249,4,0,
335,12,263,4,0,
335,12,264,4,0,
335,12,269,1,37,
335,12,269,4,0,
335,12,280,4,0,
335,12,290,4,0,
335,12,306,1,31,
335,12,332,4,0,
335,12,351,4,0,
335,12,352,4,0,
335,13,10,1,1,
335,13,14,1,10,
335,13,34,3,0,
335,13,38,3,0,
335,13,43,1,4,
335,13,46,4,0,
335,13,53,4,0,
335,13,58,4,0,
335,13,59,4,0,
335,13,69,3,0,
335,13,70,4,0,
335,13,76,4,0,
335,13,85,4,0,
335,13,86,3,0,
335,13,87,4,0,
335,13,91,4,0,
335,13,92,4,0,
335,13,98,1,7,
335,13,102,3,0,
335,13,104,4,0,
335,13,126,4,0,
335,13,156,4,0,
335,13,163,1,19,
335,13,164,3,0,
335,13,168,4,0,
335,13,182,4,0,
335,13,196,3,0,
335,13,197,1,46,
335,13,202,4,0,
335,13,206,1,55,
335,13,207,3,0,
335,13,210,1,13,
335,13,213,4,0,
335,13,216,4,0,
335,13,218,4,0,
335,13,228,1,25,
335,13,231,4,0,
335,13,237,4,0,
335,13,240,4,0,
335,13,241,4,0,
335,13,247,4,0,
335,13,249,4,0,
335,13,263,4,0,
335,13,264,4,0,
335,13,269,1,37,
335,13,269,4,0,
335,13,280,4,0,
335,13,290,4,0,
335,13,306,1,31,
335,13,332,4,0,
335,13,351,4,0,
335,13,352,4,0,
335,14,7,3,0,
335,14,8,3,0,
335,14,9,3,0,
335,14,10,1,1,1
335,14,13,2,0,
335,14,14,1,43,
335,14,14,4,0,
335,14,24,2,0,
335,14,43,1,1,2
335,14,46,4,0,
335,14,50,2,0,
335,14,53,4,0,
335,14,58,4,0,
335,14,59,4,0,
335,14,67,3,0,
335,14,68,2,0,
335,14,70,4,0,
335,14,76,4,0,
335,14,85,4,0,
335,14,87,4,0,
335,14,91,4,0,
335,14,92,4,0,
335,14,98,1,5,
335,14,104,4,0,
335,14,126,4,0,
335,14,154,2,0,
335,14,156,4,0,
335,14,157,4,0,
335,14,163,1,15,
335,14,164,4,0,
335,14,168,4,0,
335,14,173,3,0,
335,14,174,2,0,
335,14,175,2,0,
335,14,182,4,0,
335,14,196,3,0,
335,14,197,1,33,
335,14,202,3,0,
335,14,206,1,29,
335,14,206,4,0,
335,14,207,4,0,
335,14,210,1,8,
335,14,213,4,0,
335,14,214,3,0,
335,14,216,4,0,
335,14,218,4,0,
335,14,228,1,12,
335,14,231,2,0,
335,14,231,3,0,
335,14,232,2,0,
335,14,237,4,0,
335,14,240,4,0,
335,14,241,4,0,
335,14,247,4,0,
335,14,249,4,0,
335,14,263,4,0,
335,14,269,1,40,
335,14,269,4,0,
335,14,279,1,26,
335,14,280,4,0,
335,14,282,3,0,
335,14,283,3,0,
335,14,306,1,22,
335,14,317,4,0,
335,14,332,4,0,
335,14,364,2,0,
335,14,370,1,47,
335,14,371,4,0,
335,14,373,1,19,
335,14,373,4,0,
335,14,374,4,0,
335,14,387,3,0,
335,14,398,4,0,
335,14,400,2,0,
335,14,404,1,36,
335,14,404,4,0,
335,14,411,4,0,
335,14,421,4,0,
335,14,458,2,0,
335,14,468,4,0,
335,14,496,4,0,
335,14,510,4,0,
335,14,514,4,0,
335,14,515,2,0,
335,14,526,4,0,
335,15,10,1,1,1
335,15,13,2,0,
335,15,14,1,43,
335,15,14,4,0,
335,15,24,2,0,
335,15,43,1,1,2
335,15,46,4,0,
335,15,50,2,0,
335,15,53,4,0,
335,15,58,4,0,
335,15,59,4,0,
335,15,68,2,0,
335,15,70,4,0,
335,15,76,4,0,
335,15,85,4,0,
335,15,87,4,0,
335,15,91,4,0,
335,15,92,4,0,
335,15,98,1,5,
335,15,104,4,0,
335,15,126,4,0,
335,15,154,2,0,
335,15,156,4,0,
335,15,157,4,0,
335,15,163,1,15,
335,15,164,4,0,
335,15,168,4,0,
335,15,174,2,0,
335,15,175,2,0,
335,15,182,4,0,
335,15,197,1,33,
335,15,206,1,29,
335,15,206,4,0,
335,15,207,4,0,
335,15,210,1,8,
335,15,213,4,0,
335,15,214,4,0,
335,15,216,4,0,
335,15,218,4,0,
335,15,228,1,12,
335,15,231,2,0,
335,15,232,2,0,
335,15,237,4,0,
335,15,240,4,0,
335,15,241,4,0,
335,15,247,4,0,
335,15,249,4,0,
335,15,263,4,0,
335,15,269,1,40,
335,15,269,4,0,
335,15,279,1,26,
335,15,280,4,0,
335,15,306,1,22,
335,15,317,4,0,
335,15,332,4,0,
335,15,364,2,0,
335,15,370,1,47,
335,15,371,4,0,
335,15,373,1,19,
335,15,373,4,0,
335,15,374,4,0,
335,15,398,4,0,
335,15,400,2,0,
335,15,404,1,36,
335,15,404,4,0,
335,15,411,4,0,
335,15,421,4,0,
335,15,458,2,0,
335,15,468,4,0,
335,15,496,4,0,
335,15,501,2,0,
335,15,510,4,0,
335,15,514,4,0,
335,15,515,2,0,
335,15,590,4,0,
335,15,612,4,0,
335,16,7,3,0,
335,16,8,3,0,
335,16,9,3,0,
335,16,10,1,1,1
335,16,13,2,0,
335,16,14,1,47,1
335,16,14,4,0,
335,16,24,2,0,
335,16,43,1,1,2
335,16,46,4,0,
335,16,50,2,0,
335,16,53,4,0,
335,16,58,4,0,
335,16,59,4,0,
335,16,67,3,0,
335,16,68,2,0,
335,16,70,4,0,
335,16,76,4,0,
335,16,85,4,0,
335,16,87,4,0,
335,16,91,4,0,
335,16,92,4,0,
335,16,98,1,5,1
335,16,104,4,0,
335,16,126,4,0,
335,16,154,2,0,
335,16,156,4,0,
335,16,157,4,0,
335,16,163,1,19,1
335,16,164,4,0,
335,16,168,4,0,
335,16,173,3,0,
335,16,174,2,0,
335,16,175,2,0,
335,16,182,4,0,
335,16,196,3,0,
335,16,197,1,36,1
335,16,202,3,0,
335,16,206,1,29,1
335,16,206,4,0,
335,16,207,4,0,
335,16,210,1,8,1
335,16,213,4,0,
335,16,214,4,0,
335,16,216,4,0,
335,16,218,4,0,
335,16,228,1,12,1
335,16,231,2,0,
335,16,231,3,0,
335,16,232,2,0,
335,16,237,4,0,
335,16,240,4,0,
335,16,241,4,0,
335,16,247,4,0,
335,16,249,4,0,
335,16,263,4,0,
335,16,264,3,0,
335,16,269,1,43,1
335,16,269,4,0,
335,16,279,1,22,1
335,16,280,4,0,
335,16,282,3,0,
335,16,283,3,0,
335,16,290,4,0,
335,16,306,1,26,1
335,16,317,4,0,
335,16,332,4,0,
335,16,351,3,0,
335,16,352,3,0,
335,16,364,2,0,
335,16,370,1,50,1
335,16,371,4,0,
335,16,373,1,33,1
335,16,373,4,0,
335,16,374,4,0,
335,16,387,3,0,
335,16,398,4,0,
335,16,400,2,0,
335,16,404,1,40,1
335,16,404,4,0,
335,16,411,4,0,
335,16,421,4,0,
335,16,458,2,0,
335,16,468,1,15,1
335,16,468,4,0,
335,16,496,4,0,
335,16,501,2,0,
335,16,510,4,0,
335,16,514,4,0,
335,16,515,2,0,
335,16,590,4,0,
335,16,612,4,0,
335,17,10,1,1,1
335,17,13,2,0,
335,17,14,1,47,
335,17,14,4,0,
335,17,24,2,0,
335,17,43,1,1,2
335,17,46,4,0,
335,17,50,2,0,
335,17,53,4,0,
335,17,58,4,0,
335,17,59,4,0,
335,17,68,2,0,
335,17,76,4,0,
335,17,85,4,0,
335,17,87,4,0,
335,17,92,4,0,
335,17,98,1,5,
335,17,104,4,0,
335,17,126,4,0,
335,17,154,2,0,
335,17,156,4,0,
335,17,157,4,0,
335,17,163,1,19,
335,17,164,4,0,
335,17,168,4,0,
335,17,174,2,0,
335,17,175,2,0,
335,17,182,4,0,
335,17,197,1,36,
335,17,206,1,29,
335,17,206,4,0,
335,17,207,4,0,
335,17,210,1,8,
335,17,213,4,0,
335,17,214,4,0,
335,17,216,4,0,
335,17,218,4,0,
335,17,228,1,12,
335,17,231,2,0,
335,17,232,2,0,
335,17,237,4,0,
335,17,240,4,0,
335,17,241,4,0,
335,17,247,4,0,
335,17,263,4,0,
335,17,269,1,43,
335,17,269,4,0,
335,17,279,1,22,
335,17,280,4,0,
335,17,306,1,26,
335,17,317,4,0,
335,17,332,4,0,
335,17,364,2,0,
335,17,370,1,50,
335,17,371,4,0,
335,17,373,1,33,
335,17,373,4,0,
335,17,374,4,0,
335,17,398,4,0,
335,17,400,2,0,
335,17,404,1,40,
335,17,404,4,0,
335,17,411,4,0,
335,17,421,4,0,
335,17,458,2,0,
335,17,468,1,15,
335,17,496,4,0,
335,17,501,2,0,
335,17,515,2,0,
335,17,526,4,0,
335,17,590,4,0,
335,18,10,1,1,1
335,18,13,2,0,
335,18,14,1,47,
335,18,14,4,0,
335,18,24,2,0,
335,18,43,1,1,2
335,18,46,4,0,
335,18,50,2,0,
335,18,53,4,0,
335,18,58,4,0,
335,18,59,4,0,
335,18,68,2,0,
335,18,76,4,0,
335,18,85,4,0,
335,18,87,4,0,
335,18,92,4,0,
335,18,98,1,5,
335,18,104,4,0,
335,18,126,4,0,
335,18,154,2,0,
335,18,156,4,0,
335,18,157,4,0,
335,18,163,1,19,
335,18,164,4,0,
335,18,168,4,0,
335,18,174,2,0,
335,18,175,2,0,
335,18,182,4,0,
335,18,187,2,0,
335,18,197,1,36,
335,18,206,1,29,
335,18,206,4,0,
335,18,207,4,0,
335,18,210,1,8,
335,18,213,4,0,
335,18,214,4,0,
335,18,216,4,0,
335,18,218,4,0,
335,18,228,1,12,
335,18,231,2,0,
335,18,232,2,0,
335,18,237,4,0,
335,18,240,4,0,
335,18,241,4,0,
335,18,247,4,0,
335,18,263,4,0,
335,18,269,1,43,
335,18,269,4,0,
335,18,279,1,22,
335,18,280,4,0,
335,18,306,1,26,
335,18,317,4,0,
335,18,332,4,0,
335,18,364,2,0,
335,18,370,1,50,
335,18,371,4,0,
335,18,373,1,33,
335,18,373,4,0,
335,18,374,4,0,
335,18,398,4,0,
335,18,400,2,0,
335,18,404,1,40,
335,18,404,4,0,
335,18,411,4,0,
335,18,421,4,0,
335,18,458,2,0,
335,18,468,1,15,
335,18,496,4,0,
335,18,501,2,0,
335,18,515,2,0,
335,18,526,4,0,
335,18,590,4,0,
336,5,34,2,0,
336,5,35,1,1,
336,5,44,1,10,
336,5,53,4,0,
336,5,70,4,0,
336,5,89,4,0,
336,5,91,4,0,
336,5,92,4,0,
336,5,103,1,19,
336,5,104,4,0,
336,5,114,1,43,
336,5,122,1,7,
336,5,137,1,25,
336,5,156,4,0,
336,5,168,4,0,
336,5,182,4,0,
336,5,188,4,0,
336,5,202,4,0,
336,5,207,1,37,
336,5,213,4,0,
336,5,216,4,0,
336,5,218,4,0,
336,5,231,4,0,
336,5,237,4,0,
336,5,240,4,0,
336,5,241,4,0,
336,5,242,1,28,
336,5,249,4,0,
336,5,254,2,0,
336,5,255,2,0,
336,5,256,2,0,
336,5,263,4,0,
336,5,269,4,0,
336,5,289,4,0,
336,5,290,4,0,
336,5,305,1,34,
336,5,342,1,16,
336,6,34,2,0,
336,6,34,3,0,
336,6,35,1,1,
336,6,38,3,0,
336,6,44,1,10,
336,6,53,4,0,
336,6,70,4,0,
336,6,89,4,0,
336,6,91,4,0,
336,6,92,4,0,
336,6,102,3,0,
336,6,103,1,19,
336,6,104,4,0,
336,6,114,1,43,
336,6,122,1,7,
336,6,129,3,0,
336,6,137,1,25,
336,6,156,4,0,
336,6,164,3,0,
336,6,168,4,0,
336,6,173,3,0,
336,6,182,4,0,
336,6,188,4,0,
336,6,189,3,0,
336,6,202,4,0,
336,6,203,3,0,
336,6,207,1,37,
336,6,207,3,0,
336,6,210,3,0,
336,6,213,4,0,
336,6,214,3,0,
336,6,216,4,0,
336,6,218,4,0,
336,6,231,4,0,
336,6,237,4,0,
336,6,240,4,0,
336,6,241,4,0,
336,6,242,1,28,
336,6,249,4,0,
336,6,254,2,0,
336,6,255,2,0,
336,6,256,2,0,
336,6,263,4,0,
336,6,269,4,0,
336,6,289,4,0,
336,6,290,4,0,
336,6,305,1,34,
336,6,342,1,16,
336,7,34,2,0,
336,7,34,3,0,
336,7,35,1,1,
336,7,38,3,0,
336,7,44,1,10,
336,7,53,4,0,
336,7,70,4,0,
336,7,89,4,0,
336,7,91,4,0,
336,7,92,4,0,
336,7,102,3,0,
336,7,103,1,19,
336,7,104,4,0,
336,7,114,1,43,
336,7,122,1,7,
336,7,137,1,25,
336,7,156,4,0,
336,7,164,3,0,
336,7,168,4,0,
336,7,182,4,0,
336,7,188,4,0,
336,7,202,4,0,
336,7,207,1,37,
336,7,213,4,0,
336,7,216,4,0,
336,7,218,4,0,
336,7,231,4,0,
336,7,237,4,0,
336,7,240,4,0,
336,7,241,4,0,
336,7,242,1,28,
336,7,249,4,0,
336,7,254,2,0,
336,7,255,2,0,
336,7,256,2,0,
336,7,263,4,0,
336,7,269,4,0,
336,7,289,4,0,
336,7,290,4,0,
336,7,305,1,34,
336,7,342,1,16,
336,8,34,2,0,
336,8,35,1,1,
336,8,44,1,10,
336,8,53,4,0,
336,8,70,4,0,
336,8,89,4,0,
336,8,91,4,0,
336,8,92,4,0,
336,8,103,1,19,
336,8,104,4,0,
336,8,114,1,43,
336,8,122,1,7,
336,8,137,1,25,
336,8,156,4,0,
336,8,164,4,0,
336,8,168,4,0,
336,8,182,4,0,
336,8,184,2,0,
336,8,188,4,0,
336,8,202,4,0,
336,8,203,4,0,
336,8,207,1,37,
336,8,207,4,0,
336,8,213,4,0,
336,8,214,4,0,
336,8,216,4,0,
336,8,218,4,0,
336,8,231,4,0,
336,8,237,4,0,
336,8,240,4,0,
336,8,241,4,0,
336,8,242,1,28,
336,8,249,4,0,
336,8,254,2,0,
336,8,255,2,0,
336,8,256,2,0,
336,8,263,4,0,
336,8,269,4,0,
336,8,289,4,0,
336,8,290,4,0,
336,8,305,1,34,
336,8,342,1,16,
336,8,363,4,0,
336,8,371,4,0,
336,8,372,2,0,
336,8,378,1,55,
336,8,398,1,52,
336,8,398,4,0,
336,8,399,4,0,
336,8,400,1,46,
336,8,400,2,0,
336,8,404,4,0,
336,8,445,4,0,
336,9,34,2,0,
336,9,35,1,1,
336,9,44,1,10,
336,9,53,4,0,
336,9,70,4,0,
336,9,89,4,0,
336,9,91,4,0,
336,9,92,4,0,
336,9,103,1,19,
336,9,104,4,0,
336,9,114,1,43,
336,9,122,1,7,
336,9,129,3,0,
336,9,137,1,25,
336,9,156,4,0,
336,9,164,4,0,
336,9,168,4,0,
336,9,173,3,0,
336,9,182,4,0,
336,9,184,2,0,
336,9,188,4,0,
336,9,189,3,0,
336,9,202,4,0,
336,9,203,4,0,
336,9,207,1,37,
336,9,207,4,0,
336,9,210,3,0,
336,9,213,4,0,
336,9,214,4,0,
336,9,216,4,0,
336,9,218,4,0,
336,9,231,4,0,
336,9,237,4,0,
336,9,240,4,0,
336,9,241,4,0,
336,9,242,1,28,
336,9,249,4,0,
336,9,254,2,0,
336,9,255,2,0,
336,9,256,2,0,
336,9,263,4,0,
336,9,269,4,0,
336,9,282,3,0,
336,9,289,4,0,
336,9,290,4,0,
336,9,305,1,34,
336,9,342,1,16,
336,9,363,4,0,
336,9,371,4,0,
336,9,372,2,0,
336,9,378,1,55,
336,9,389,3,0,
336,9,398,1,52,
336,9,398,4,0,
336,9,399,4,0,
336,9,400,1,46,
336,9,400,2,0,
336,9,401,3,0,
336,9,404,4,0,
336,9,445,4,0,
336,10,29,3,0,
336,10,34,2,0,
336,10,35,1,1,
336,10,44,1,10,
336,10,53,4,0,
336,10,70,4,0,
336,10,89,4,0,
336,10,91,4,0,
336,10,92,4,0,
336,10,103,1,19,
336,10,104,4,0,
336,10,114,1,43,
336,10,122,1,7,
336,10,129,3,0,
336,10,137,1,25,
336,10,156,4,0,
336,10,164,4,0,
336,10,168,4,0,
336,10,173,3,0,
336,10,182,4,0,
336,10,184,2,0,
336,10,188,4,0,
336,10,189,3,0,
336,10,202,4,0,
336,10,203,4,0,
336,10,207,1,37,
336,10,207,4,0,
336,10,210,3,0,
336,10,213,4,0,
336,10,214,4,0,
336,10,216,4,0,
336,10,218,4,0,
336,10,231,4,0,
336,10,237,4,0,
336,10,240,4,0,
336,10,241,4,0,
336,10,242,1,28,
336,10,249,4,0,
336,10,254,2,0,
336,10,255,2,0,
336,10,256,2,0,
336,10,263,4,0,
336,10,269,4,0,
336,10,282,3,0,
336,10,289,4,0,
336,10,290,4,0,
336,10,305,1,34,
336,10,342,1,16,
336,10,363,4,0,
336,10,371,4,0,
336,10,372,2,0,
336,10,378,1,55,
336,10,389,3,0,
336,10,398,1,52,
336,10,398,4,0,
336,10,399,4,0,
336,10,400,1,46,
336,10,400,2,0,
336,10,401,3,0,
336,10,404,4,0,
336,10,415,2,0,
336,10,445,4,0,
336,11,34,2,0,
336,11,35,1,1,
336,11,44,1,10,
336,11,53,4,0,
336,11,70,4,0,
336,11,89,4,0,
336,11,91,4,0,
336,11,92,4,0,
336,11,103,1,19,
336,11,104,4,0,
336,11,114,1,43,
336,11,122,1,7,
336,11,137,1,25,
336,11,156,4,0,
336,11,164,4,0,
336,11,168,4,0,
336,11,182,4,0,
336,11,184,2,0,
336,11,188,4,0,
336,11,207,1,37,
336,11,207,4,0,
336,11,213,4,0,
336,11,216,4,0,
336,11,218,4,0,
336,11,231,2,0,
336,11,237,4,0,
336,11,240,4,0,
336,11,241,4,0,
336,11,242,1,28,
336,11,249,4,0,
336,11,254,2,0,
336,11,255,2,0,
336,11,256,2,0,
336,11,263,4,0,
336,11,269,4,0,
336,11,305,1,34,
336,11,342,1,16,
336,11,371,4,0,
336,11,372,2,0,
336,11,378,1,61,
336,11,378,2,0,
336,11,386,2,0,
336,11,398,1,52,
336,11,398,4,0,
336,11,400,1,46,
336,11,400,2,0,
336,11,404,4,0,
336,11,415,2,0,
336,11,474,1,55,
336,11,474,4,0,
336,11,482,4,0,
336,11,489,1,64,
336,11,496,4,0,
336,11,514,4,0,
336,11,515,2,0,
336,11,523,4,0,
336,11,525,4,0,
336,12,35,1,1,
336,12,44,1,10,
336,12,53,4,0,
336,12,70,4,0,
336,12,89,4,0,
336,12,91,4,0,
336,12,92,4,0,
336,12,103,1,19,
336,12,104,4,0,
336,12,114,1,43,
336,12,122,1,7,
336,12,137,1,25,
336,12,156,4,0,
336,12,168,4,0,
336,12,182,4,0,
336,12,188,4,0,
336,12,202,4,0,
336,12,207,1,37,
336,12,213,4,0,
336,12,216,4,0,
336,12,218,4,0,
336,12,231,4,0,
336,12,237,4,0,
336,12,240,4,0,
336,12,241,4,0,
336,12,242,1,28,
336,12,249,4,0,
336,12,263,4,0,
336,12,269,4,0,
336,12,289,4,0,
336,12,290,4,0,
336,12,305,1,34,
336,12,342,1,16,
336,13,34,3,0,
336,13,35,1,1,
336,13,38,3,0,
336,13,44,1,10,
336,13,53,4,0,
336,13,70,4,0,
336,13,89,4,0,
336,13,91,4,0,
336,13,92,4,0,
336,13,102,3,0,
336,13,103,1,19,
336,13,104,4,0,
336,13,114,1,43,
336,13,122,1,7,
336,13,137,1,25,
336,13,156,4,0,
336,13,164,3,0,
336,13,168,4,0,
336,13,182,4,0,
336,13,188,4,0,
336,13,202,4,0,
336,13,207,1,37,
336,13,207,3,0,
336,13,213,4,0,
336,13,216,4,0,
336,13,218,4,0,
336,13,231,4,0,
336,13,237,4,0,
336,13,240,4,0,
336,13,241,4,0,
336,13,242,1,28,
336,13,249,4,0,
336,13,263,4,0,
336,13,269,4,0,
336,13,289,4,0,
336,13,290,4,0,
336,13,305,1,34,
336,13,342,1,16,
336,14,20,3,0,
336,14,34,2,0,
336,14,35,1,1,1
336,14,44,1,5,
336,14,53,4,0,
336,14,70,4,0,
336,14,89,4,0,
336,14,91,4,0,
336,14,92,4,0,
336,14,103,1,16,
336,14,104,4,0,
336,14,114,1,38,
336,14,122,1,1,2
336,14,137,1,23,
336,14,156,4,0,
336,14,164,4,0,
336,14,168,4,0,
336,14,173,3,0,
336,14,182,4,0,
336,14,184,2,0,
336,14,188,4,0,
336,14,202,3,0,
336,14,207,1,9,
336,14,207,4,0,
336,14,213,4,0,
336,14,214,3,0,
336,14,216,4,0,
336,14,218,4,0,
336,14,231,2,0,
336,14,231,3,0,
336,14,237,4,0,
336,14,240,4,0,
336,14,241,4,0,
336,14,242,1,45,
336,14,249,4,0,
336,14,254,2,0,
336,14,255,2,0,
336,14,256,2,0,
336,14,263,4,0,
336,14,269,4,0,
336,14,282,3,0,
336,14,289,3,0,
336,14,305,1,27,
336,14,342,1,12,
336,14,371,4,0,
336,14,372,2,0,
336,14,378,1,53,
336,14,378,2,0,
336,14,380,1,34,
336,14,380,3,0,
336,14,386,2,0,
336,14,398,1,42,
336,14,398,4,0,
336,14,399,3,0,
336,14,400,1,31,
336,14,400,2,0,
336,14,401,3,0,
336,14,404,4,0,
336,14,415,2,0,
336,14,474,1,20,
336,14,474,4,0,
336,14,482,4,0,
336,14,489,1,49,
336,14,496,4,0,
336,14,514,4,0,
336,14,515,2,0,
336,14,523,4,0,
336,14,525,4,0,
336,15,34,2,0,
336,15,35,1,1,1
336,15,44,1,5,
336,15,53,4,0,
336,15,70,4,0,
336,15,89,4,0,
336,15,91,4,0,
336,15,92,4,0,
336,15,103,1,16,
336,15,104,4,0,
336,15,114,1,38,
336,15,122,1,9,
336,15,137,1,23,
336,15,156,4,0,
336,15,164,4,0,
336,15,168,4,0,
336,15,182,4,0,
336,15,184,2,0,
336,15,188,4,0,
336,15,207,1,1,2
336,15,207,4,0,
336,15,213,4,0,
336,15,214,4,0,
336,15,216,4,0,
336,15,218,4,0,
336,15,231,2,0,
336,15,237,4,0,
336,15,240,4,0,
336,15,241,4,0,
336,15,242,1,45,
336,15,249,4,0,
336,15,254,2,0,
336,15,255,2,0,
336,15,256,2,0,
336,15,263,4,0,
336,15,269,4,0,
336,15,305,1,27,
336,15,342,1,12,
336,15,371,4,0,
336,15,372,2,0,
336,15,378,1,50,
336,15,378,2,0,
336,15,380,1,34,
336,15,386,2,0,
336,15,398,1,42,
336,15,398,4,0,
336,15,399,4,0,
336,15,400,1,31,
336,15,400,2,0,
336,15,404,4,0,
336,15,415,2,0,
336,15,474,1,20,
336,15,474,4,0,
336,15,482,4,0,
336,15,489,1,48,
336,15,496,4,0,
336,15,514,4,0,
336,15,515,2,0,
336,15,523,4,0,
336,15,525,4,0,
336,15,562,1,46,
336,15,590,4,0,
336,15,599,1,28,
336,15,611,4,0,
336,16,20,3,0,
336,16,34,2,0,
336,16,35,1,1,1
336,16,44,1,4,1
336,16,53,4,0,
336,16,70,4,0,
336,16,89,4,0,
336,16,91,4,0,
336,16,92,4,0,
336,16,103,1,13,1
336,16,104,4,0,
336,16,114,1,37,1
336,16,122,1,7,1
336,16,137,1,19,1
336,16,156,4,0,
336,16,164,4,0,
336,16,168,4,0,
336,16,173,3,0,
336,16,182,4,0,
336,16,184,2,0,
336,16,188,4,0,
336,16,202,3,0,
336,16,207,1,1,2
336,16,207,4,0,
336,16,213,4,0,
336,16,214,4,0,
336,16,216,4,0,
336,16,218,4,0,
336,16,231,2,0,
336,16,231,3,0,
336,16,237,4,0,
336,16,240,4,0,
336,16,241,4,0,
336,16,242,1,40,1
336,16,249,4,0,
336,16,254,2,0,
336,16,255,2,0,
336,16,256,2,0,
336,16,263,4,0,
336,16,269,4,0,
336,16,282,3,0,
336,16,289,3,0,
336,16,290,4,0,
336,16,305,1,22,1
336,16,342,1,10,1
336,16,371,4,0,
336,16,372,2,0,
336,16,378,1,49,1
336,16,378,2,0,
336,16,380,1,31,1
336,16,380,3,0,
336,16,386,2,0,
336,16,398,1,34,1
336,16,398,4,0,
336,16,399,4,0,
336,16,400,1,28,1
336,16,400,2,0,
336,16,401,3,0,
336,16,404,4,0,
336,16,415,2,0,
336,16,474,1,16,1
336,16,474,4,0,
336,16,482,4,0,
336,16,489,1,46,1
336,16,496,4,0,
336,16,514,4,0,
336,16,515,2,0,
336,16,523,4,0,
336,16,525,4,0,
336,16,562,1,43,1
336,16,590,4,0,
336,16,599,1,25,1
336,16,611,4,0,
336,17,14,1,36,
336,17,14,4,0,
336,17,34,2,0,
336,17,35,1,1,1
336,17,44,1,4,
336,17,53,4,0,
336,17,89,4,0,
336,17,92,4,0,
336,17,103,1,14,
336,17,104,4,0,
336,17,114,1,34,
336,17,122,1,6,
336,17,137,1,19,
336,17,156,4,0,
336,17,164,4,0,
336,17,168,4,0,
336,17,182,4,0,
336,17,184,2,0,
336,17,188,4,0,
336,17,207,1,1,2
336,17,207,4,0,
336,17,213,4,0,
336,17,214,4,0,
336,17,216,4,0,
336,17,218,4,0,
336,17,231,2,0,
336,17,237,4,0,
336,17,240,4,0,
336,17,241,4,0,
336,17,242,1,39,
336,17,254,2,0,
336,17,255,2,0,
336,17,256,2,0,
336,17,263,4,0,
336,17,269,4,0,
336,17,305,1,21,
336,17,342,1,9,
336,17,364,1,11,
336,17,371,4,0,
336,17,372,2,0,
336,17,378,1,46,
336,17,378,2,0,
336,17,380,1,29,
336,17,386,2,0,
336,17,398,1,31,
336,17,398,4,0,
336,17,399,4,0,
336,17,400,1,26,
336,17,400,2,0,
336,17,404,4,0,
336,17,415,2,0,
336,17,474,1,16,
336,17,474,4,0,
336,17,482,4,0,
336,17,489,1,44,
336,17,496,4,0,
336,17,515,2,0,
336,17,523,4,0,
336,17,525,4,0,
336,17,562,1,41,
336,17,590,4,0,
336,17,599,1,24,
336,17,611,4,0,
336,17,693,4,0,
336,18,14,1,36,
336,18,14,4,0,
336,18,34,2,0,
336,18,35,1,1,1
336,18,44,1,4,
336,18,53,4,0,
336,18,89,4,0,
336,18,92,4,0,
336,18,103,1,14,
336,18,104,4,0,
336,18,114,1,34,
336,18,122,1,6,
336,18,137,1,19,
336,18,156,4,0,
336,18,164,4,0,
336,18,168,4,0,
336,18,182,4,0,
336,18,184,2,0,
336,18,188,4,0,
336,18,207,1,1,2
336,18,207,4,0,
336,18,213,4,0,
336,18,214,4,0,
336,18,216,4,0,
336,18,218,4,0,
336,18,231,2,0,
336,18,237,4,0,
336,18,240,4,0,
336,18,241,4,0,
336,18,242,1,39,
336,18,254,2,0,
336,18,255,2,0,
336,18,256,2,0,
336,18,263,4,0,
336,18,269,4,0,
336,18,305,1,21,
336,18,342,1,9,
336,18,364,1,11,
336,18,371,4,0,
336,18,372,2,0,
336,18,378,1,46,
336,18,378,2,0,
336,18,380,1,29,
336,18,386,2,0,
336,18,398,1,31,
336,18,398,4,0,
336,18,399,4,0,
336,18,400,1,26,
336,18,400,2,0,
336,18,404,4,0,
336,18,415,2,0,
336,18,474,1,16,
336,18,474,4,0,
336,18,482,4,0,
336,18,489,1,44,
336,18,496,4,0,
336,18,515,2,0,
336,18,523,4,0,
336,18,525,4,0,
336,18,562,1,41,
336,18,590,4,0,
336,18,599,1,24,
336,18,611,4,0,
336,18,693,4,0,
337,5,33,1,1,1
337,5,58,4,0,
337,5,63,4,0,
337,5,88,1,13,
337,5,89,4,0,
337,5,92,4,0,
337,5,93,1,7,
337,5,94,1,37,
337,5,94,4,0,
337,5,95,1,19,
337,5,104,4,0,
337,5,106,1,1,2
337,5,113,4,0,
337,5,115,4,0,
337,5,148,4,0,
337,5,149,1,25,
337,5,153,1,49,
337,5,156,4,0,
337,5,182,4,0,
337,5,201,4,0,
337,5,216,4,0,
337,5,218,4,0,
337,5,219,4,0,
337,5,237,4,0,
337,5,240,4,0,
337,5,247,4,0,
337,5,248,1,43,
337,5,263,4,0,
337,5,285,4,0,
337,5,290,4,0,
337,5,317,4,0,
337,5,322,1,31,
337,5,347,4,0,
337,6,33,1,1,1
337,6,34,3,0,
337,6,38,3,0,
337,6,58,4,0,
337,6,63,4,0,
337,6,88,1,13,
337,6,89,4,0,
337,6,92,4,0,
337,6,93,1,7,
337,6,94,1,37,
337,6,94,4,0,
337,6,95,1,19,
337,6,102,3,0,
337,6,104,4,0,
337,6,106,1,1,2
337,6,111,3,0,
337,6,113,4,0,
337,6,115,4,0,
337,6,129,3,0,
337,6,138,3,0,
337,6,148,4,0,
337,6,149,1,25,
337,6,153,1,49,
337,6,153,3,0,
337,6,156,4,0,
337,6,157,3,0,
337,6,164,3,0,
337,6,173,3,0,
337,6,182,4,0,
337,6,201,4,0,
337,6,203,3,0,
337,6,205,3,0,
337,6,207,3,0,
337,6,214,3,0,
337,6,216,4,0,
337,6,218,4,0,
337,6,219,4,0,
337,6,237,4,0,
337,6,240,4,0,
337,6,244,3,0,
337,6,247,4,0,
337,6,248,1,43,
337,6,263,4,0,
337,6,285,4,0,
337,6,290,4,0,
337,6,317,4,0,
337,6,322,1,31,
337,6,347,4,0,
337,7,33,1,1,1
337,7,34,3,0,
337,7,38,3,0,
337,7,58,4,0,
337,7,63,4,0,
337,7,88,1,13,
337,7,89,4,0,
337,7,92,4,0,
337,7,93,1,7,
337,7,94,1,37,
337,7,94,4,0,
337,7,95,1,19,
337,7,102,3,0,
337,7,104,4,0,
337,7,106,1,1,2
337,7,113,4,0,
337,7,115,4,0,
337,7,138,3,0,
337,7,148,4,0,
337,7,149,1,25,
337,7,153,1,49,
337,7,153,3,0,
337,7,156,4,0,
337,7,157,3,0,
337,7,164,3,0,
337,7,182,4,0,
337,7,201,4,0,
337,7,216,4,0,
337,7,218,4,0,
337,7,219,4,0,
337,7,237,4,0,
337,7,240,4,0,
337,7,247,4,0,
337,7,248,1,43,
337,7,263,4,0,
337,7,285,4,0,
337,7,290,4,0,
337,7,317,4,0,
337,7,322,1,31,
337,7,347,4,0,
337,8,33,1,1,1
337,8,58,4,0,
337,8,59,4,0,
337,8,63,4,0,
337,8,88,1,9,
337,8,89,4,0,
337,8,92,4,0,
337,8,93,1,1,3
337,8,94,1,45,
337,8,94,4,0,
337,8,95,1,12,
337,8,104,4,0,
337,8,106,1,1,2
337,8,113,4,0,
337,8,115,4,0,
337,8,138,4,0,
337,8,148,4,0,
337,8,149,1,23,
337,8,153,1,56,
337,8,153,4,0,
337,8,156,4,0,
337,8,157,4,0,
337,8,164,4,0,
337,8,182,4,0,
337,8,201,4,0,
337,8,203,4,0,
337,8,207,4,0,
337,8,214,4,0,
337,8,216,4,0,
337,8,218,4,0,
337,8,219,4,0,
337,8,237,4,0,
337,8,240,4,0,
337,8,244,4,0,
337,8,247,4,0,
337,8,248,1,53,
337,8,263,4,0,
337,8,278,4,0,
337,8,285,4,0,
337,8,290,4,0,
337,8,317,4,0,
337,8,322,1,34,
337,8,347,4,0,
337,8,360,4,0,
337,8,363,4,0,
337,8,373,1,31,
337,8,373,4,0,
337,8,377,1,42,
337,8,397,1,20,
337,8,397,4,0,
337,8,416,4,0,
337,8,433,4,0,
337,8,444,4,0,
337,8,446,4,0,
337,8,447,4,0,
337,8,451,4,0,
337,9,33,1,1,1
337,9,58,4,0,
337,9,59,4,0,
337,9,63,4,0,
337,9,88,1,9,
337,9,89,4,0,
337,9,92,4,0,
337,9,93,1,1,3
337,9,94,1,45,
337,9,94,4,0,
337,9,95,1,12,
337,9,104,4,0,
337,9,106,1,1,2
337,9,113,4,0,
337,9,115,4,0,
337,9,129,3,0,
337,9,138,4,0,
337,9,148,4,0,
337,9,149,1,23,
337,9,153,1,56,
337,9,153,4,0,
337,9,156,4,0,
337,9,157,4,0,
337,9,164,4,0,
337,9,173,3,0,
337,9,182,4,0,
337,9,201,4,0,
337,9,203,4,0,
337,9,205,3,0,
337,9,207,4,0,
337,9,214,4,0,
337,9,216,4,0,
337,9,218,4,0,
337,9,219,4,0,
337,9,237,4,0,
337,9,240,4,0,
337,9,244,4,0,
337,9,246,3,0,
337,9,247,4,0,
337,9,248,1,53,
337,9,263,4,0,
337,9,270,3,0,
337,9,278,4,0,
337,9,285,4,0,
337,9,290,4,0,
337,9,317,4,0,
337,9,322,1,34,
337,9,324,3,0,
337,9,347,4,0,
337,9,360,4,0,
337,9,363,4,0,
337,9,373,1,31,
337,9,373,4,0,
337,9,377,1,42,
337,9,397,1,20,
337,9,397,4,0,
337,9,414,3,0,
337,9,416,4,0,
337,9,428,3,0,
337,9,433,4,0,
337,9,442,3,0,
337,9,444,4,0,
337,9,446,4,0,
337,9,447,4,0,
337,9,451,4,0,
337,10,33,1,1,1
337,10,58,4,0,
337,10,59,4,0,
337,10,63,4,0,
337,10,88,1,9,
337,10,89,4,0,
337,10,92,4,0,
337,10,93,1,1,3
337,10,94,1,45,
337,10,94,4,0,
337,10,95,1,12,
337,10,104,4,0,
337,10,106,1,1,2
337,10,113,4,0,
337,10,115,4,0,
337,10,129,3,0,
337,10,138,4,0,
337,10,148,4,0,
337,10,149,1,23,
337,10,153,1,56,
337,10,153,4,0,
337,10,156,4,0,
337,10,157,4,0,
337,10,164,4,0,
337,10,173,3,0,
337,10,182,4,0,
337,10,201,4,0,
337,10,203,4,0,
337,10,205,3,0,
337,10,207,4,0,
337,10,214,4,0,
337,10,216,4,0,
337,10,218,4,0,
337,10,219,4,0,
337,10,220,3,0,
337,10,237,4,0,
337,10,240,4,0,
337,10,244,4,0,
337,10,246,3,0,
337,10,247,4,0,
337,10,248,1,53,
337,10,263,4,0,
337,10,270,3,0,
337,10,277,3,0,
337,10,278,4,0,
337,10,285,4,0,
337,10,290,4,0,
337,10,317,4,0,
337,10,322,1,34,
337,10,324,3,0,
337,10,347,4,0,
337,10,356,3,0,
337,10,360,4,0,
337,10,363,4,0,
337,10,373,1,31,
337,10,373,4,0,
337,10,377,1,42,
337,10,397,1,20,
337,10,397,4,0,
337,10,414,3,0,
337,10,416,4,0,
337,10,428,3,0,
337,10,433,4,0,
337,10,442,3,0,
337,10,444,4,0,
337,10,446,4,0,
337,10,447,4,0,
337,10,451,4,0,
337,11,33,1,1,1
337,11,58,4,0,
337,11,59,4,0,
337,11,63,4,0,
337,11,88,1,9,
337,11,89,4,0,
337,11,92,4,0,
337,11,93,1,1,3
337,11,94,1,45,
337,11,94,4,0,
337,11,95,1,12,
337,11,104,4,0,
337,11,106,1,1,2
337,11,113,4,0,
337,11,115,4,0,
337,11,138,4,0,
337,11,148,4,0,
337,11,149,1,23,
337,11,153,1,56,
337,11,153,4,0,
337,11,156,4,0,
337,11,157,4,0,
337,11,164,4,0,
337,11,182,4,0,
337,11,201,4,0,
337,11,207,4,0,
337,11,216,4,0,
337,11,218,4,0,
337,11,219,4,0,
337,11,237,4,0,
337,11,240,4,0,
337,11,244,4,0,
337,11,247,4,0,
337,11,248,1,53,
337,11,263,4,0,
337,11,317,4,0,
337,11,322,1,34,
337,11,347,4,0,
337,11,360,4,0,
337,11,373,1,31,
337,11,373,4,0,
337,11,377,1,42,
337,11,397,1,20,
337,11,397,4,0,
337,11,416,4,0,
337,11,433,4,0,
337,11,444,4,0,
337,11,447,4,0,
337,11,451,4,0,
337,11,473,4,0,
337,11,477,4,0,
337,11,478,1,64,
337,11,479,4,0,
337,11,496,4,0,
337,11,512,4,0,
337,11,523,4,0,
337,12,33,1,1,1
337,12,58,4,0,
337,12,63,4,0,
337,12,88,1,13,
337,12,89,4,0,
337,12,92,4,0,
337,12,93,1,7,
337,12,94,1,37,
337,12,94,4,0,
337,12,95,1,19,
337,12,104,4,0,
337,12,106,1,1,2
337,12,113,4,0,
337,12,115,4,0,
337,12,148,4,0,
337,12,149,1,25,
337,12,153,1,49,
337,12,156,4,0,
337,12,182,4,0,
337,12,201,4,0,
337,12,216,4,0,
337,12,218,4,0,
337,12,219,4,0,
337,12,237,4,0,
337,12,240,4,0,
337,12,247,4,0,
337,12,248,1,43,
337,12,263,4,0,
337,12,285,4,0,
337,12,290,4,0,
337,12,317,4,0,
337,12,322,1,31,
337,12,347,4,0,
337,13,33,1,1,1
337,13,34,3,0,
337,13,38,3,0,
337,13,58,4,0,
337,13,63,4,0,
337,13,88,1,13,
337,13,89,4,0,
337,13,92,4,0,
337,13,93,1,7,
337,13,94,1,37,
337,13,94,4,0,
337,13,95,1,19,
337,13,102,3,0,
337,13,104,4,0,
337,13,106,1,1,2
337,13,113,4,0,
337,13,115,4,0,
337,13,120,3,0,
337,13,138,3,0,
337,13,148,4,0,
337,13,149,1,25,
337,13,153,1,49,
337,13,156,4,0,
337,13,164,3,0,
337,13,182,4,0,
337,13,201,4,0,
337,13,207,3,0,
337,13,216,4,0,
337,13,218,4,0,
337,13,219,4,0,
337,13,237,4,0,
337,13,240,4,0,
337,13,247,4,0,
337,13,248,1,43,
337,13,263,4,0,
337,13,285,4,0,
337,13,290,4,0,
337,13,317,4,0,
337,13,322,1,31,
337,13,347,4,0,
337,14,33,1,1,1
337,14,58,4,0,
337,14,59,4,0,
337,14,63,4,0,
337,14,88,1,5,
337,14,89,4,0,
337,14,92,4,0,
337,14,93,1,1,3
337,14,94,1,33,
337,14,94,4,0,
337,14,95,1,9,
337,14,104,4,0,
337,14,106,1,1,2
337,14,113,4,0,
337,14,115,4,0,
337,14,138,4,0,
337,14,148,4,0,
337,14,149,1,17,
337,14,153,1,49,
337,14,153,4,0,
337,14,156,4,0,
337,14,157,1,25,
337,14,157,4,0,
337,14,164,4,0,
337,14,173,3,0,
337,14,182,4,0,
337,14,196,3,0,
337,14,201,4,0,
337,14,207,4,0,
337,14,214,3,0,
337,14,216,4,0,
337,14,218,4,0,
337,14,219,4,0,
337,14,220,3,0,
337,14,237,4,0,
337,14,240,4,0,
337,14,244,4,0,
337,14,247,4,0,
337,14,248,1,45,
337,14,263,4,0,
337,14,270,3,0,
337,14,277,3,0,
337,14,278,3,0,
337,14,285,3,0,
337,14,317,4,0,
337,14,322,1,29,
337,14,324,3,0,
337,14,347,4,0,
337,14,356,3,0,
337,14,360,4,0,
337,14,373,1,21,
337,14,373,4,0,
337,14,377,1,37,
337,14,397,1,13,
337,14,397,4,0,
337,14,414,3,0,
337,14,416,4,0,
337,14,428,3,0,
337,14,433,4,0,
337,14,442,3,0,
337,14,444,1,41,
337,14,444,4,0,
337,14,446,3,0,
337,14,447,4,0,
337,14,451,4,0,
337,14,473,4,0,
337,14,477,4,0,
337,14,478,1,53,
337,14,478,3,0,
337,14,479,4,0,
337,14,496,4,0,
337,14,512,4,0,
337,14,523,4,0,
337,15,33,1,1,3
337,15,58,4,0,
337,15,59,4,0,
337,15,63,4,0,
337,15,88,1,1,2
337,15,88,1,5,
337,15,89,4,0,
337,15,92,4,0,
337,15,93,1,1,5
337,15,94,1,33,
337,15,94,4,0,
337,15,95,1,9,
337,15,104,4,0,
337,15,106,1,1,4
337,15,113,4,0,
337,15,115,4,0,
337,15,138,4,0,
337,15,148,4,0,
337,15,149,1,17,
337,15,153,1,49,
337,15,153,4,0,
337,15,156,4,0,
337,15,157,1,25,
337,15,157,4,0,
337,15,164,4,0,
337,15,182,4,0,
337,15,201,4,0,
337,15,207,4,0,
337,15,214,4,0,
337,15,216,4,0,
337,15,218,4,0,
337,15,219,4,0,
337,15,237,4,0,
337,15,240,4,0,
337,15,244,4,0,
337,15,247,4,0,
337,15,248,1,45,
337,15,263,4,0,
337,15,317,4,0,
337,15,322,1,29,
337,15,347,4,0,
337,15,360,4,0,
337,15,373,1,21,
337,15,373,4,0,
337,15,377,1,37,
337,15,397,1,13,
337,15,397,4,0,
337,15,416,4,0,
337,15,433,4,0,
337,15,444,1,41,
337,15,444,4,0,
337,15,447,4,0,
337,15,451,4,0,
337,15,473,4,0,
337,15,478,1,1,1
337,15,478,1,53,
337,15,479,4,0,
337,15,496,4,0,
337,15,512,4,0,
337,15,523,4,0,
337,15,585,1,50,
337,15,590,4,0,
337,16,33,1,1,2
337,16,58,4,0,
337,16,59,4,0,
337,16,63,4,0,
337,16,88,1,1,5
337,16,89,4,0,
337,16,92,4,0,
337,16,93,1,1,4
337,16,94,1,29,1
337,16,94,4,0,
337,16,95,1,5,1
337,16,104,4,0,
337,16,106,1,1,3
337,16,113,4,0,
337,16,115,4,0,
337,16,138,4,0,
337,16,148,4,0,
337,16,149,1,13,1
337,16,153,1,45,1
337,16,153,4,0,
337,16,156,4,0,
337,16,157,1,21,1
337,16,157,4,0,
337,16,164,4,0,
337,16,173,3,0,
337,16,182,4,0,
337,16,196,3,0,
337,16,201,4,0,
337,16,207,4,0,
337,16,214,4,0,
337,16,216,4,0,
337,16,218,4,0,
337,16,219,4,0,
337,16,220,3,0,
337,16,237,4,0,
337,16,240,4,0,
337,16,244,4,0,
337,16,247,4,0,
337,16,248,1,41,1
337,16,263,4,0,
337,16,270,3,0,
337,16,277,3,0,
337,16,278,3,0,
337,16,285,3,0,
337,16,290,4,0,
337,16,317,4,0,
337,16,322,1,25,1
337,16,324,3,0,
337,16,347,4,0,
337,16,356,3,0,
337,16,360,4,0,
337,16,373,1,17,1
337,16,373,4,0,
337,16,377,1,33,1
337,16,397,1,9,1
337,16,397,4,0,
337,16,414,3,0,
337,16,416,4,0,
337,16,428,3,0,
337,16,433,4,0,
337,16,442,3,0,
337,16,444,1,37,1
337,16,444,4,0,
337,16,446,3,0,
337,16,447,4,0,
337,16,451,4,0,
337,16,473,4,0,
337,16,478,1,49,1
337,16,478,3,0,
337,16,479,4,0,
337,16,496,4,0,
337,16,512,4,0,
337,16,523,4,0,
337,16,585,1,1,1
337,16,590,4,0,
337,17,33,1,1,4
337,17,58,4,0,
337,17,59,4,0,
337,17,63,4,0,
337,17,88,1,1,7
337,17,89,4,0,
337,17,92,4,0,
337,17,93,1,1,6
337,17,94,1,29,
337,17,94,4,0,
337,17,95,1,5,
337,17,104,4,0,
337,17,106,1,1,5
337,17,113,4,0,
337,17,115,4,0,
337,17,138,4,0,
337,17,149,1,13,
337,17,153,1,45,
337,17,153,4,0,
337,17,156,4,0,
337,17,157,1,21,
337,17,157,4,0,
337,17,164,4,0,
337,17,182,4,0,
337,17,201,4,0,
337,17,207,4,0,
337,17,214,4,0,
337,17,216,4,0,
337,17,218,4,0,
337,17,219,4,0,
337,17,237,4,0,
337,17,240,4,0,
337,17,244,4,0,
337,17,247,4,0,
337,17,248,1,41,
337,17,263,4,0,
337,17,317,4,0,
337,17,322,1,25,
337,17,347,4,0,
337,17,360,4,0,
337,17,373,1,17,
337,17,373,4,0,
337,17,377,1,33,
337,17,397,1,9,
337,17,397,4,0,
337,17,408,1,1,1
337,17,416,4,0,
337,17,433,4,0,
337,17,444,1,37,
337,17,444,4,0,
337,17,447,4,0,
337,17,451,4,0,
337,17,473,1,1,2
337,17,473,4,0,
337,17,478,1,49,
337,17,479,4,0,
337,17,496,4,0,
337,17,512,4,0,
337,17,523,4,0,
337,17,585,1,1,3
337,17,590,4,0,
337,18,33,1,1,4
337,18,58,4,0,
337,18,59,4,0,
337,18,63,4,0,
337,18,88,1,1,7
337,18,89,4,0,
337,18,92,4,0,
337,18,93,1,1,6
337,18,94,1,29,
337,18,94,4,0,
337,18,95,1,5,
337,18,104,4,0,
337,18,106,1,1,5
337,18,113,4,0,
337,18,115,4,0,
337,18,138,4,0,
337,18,149,1,13,
337,18,153,1,45,
337,18,153,4,0,
337,18,156,4,0,
337,18,157,1,21,
337,18,157,4,0,
337,18,164,4,0,
337,18,182,4,0,
337,18,201,4,0,
337,18,207,4,0,
337,18,214,4,0,
337,18,216,4,0,
337,18,218,4,0,
337,18,219,4,0,
337,18,237,4,0,
337,18,240,4,0,
337,18,244,4,0,
337,18,247,4,0,
337,18,248,1,41,
337,18,263,4,0,
337,18,317,4,0,
337,18,322,1,25,
337,18,347,4,0,
337,18,360,4,0,
337,18,373,1,17,
337,18,373,4,0,
337,18,377,1,33,
337,18,397,1,9,
337,18,397,4,0,
337,18,408,1,1,1
337,18,416,4,0,
337,18,433,4,0,
337,18,444,1,37,
337,18,444,4,0,
337,18,447,4,0,
337,18,451,4,0,
337,18,473,1,1,2
337,18,473,4,0,
337,18,478,1,49,
337,18,479,4,0,
337,18,496,4,0,
337,18,512,4,0,
337,18,523,4,0,
337,18,585,1,1,3
337,18,590,4,0,
338,5,33,1,1,1
338,5,53,4,0,
338,5,63,4,0,
338,5,76,1,43,
338,5,76,4,0,
338,5,83,1,19,
338,5,88,1,13,
338,5,89,4,0,
338,5,92,4,0,
338,5,93,1,7,
338,5,94,4,0,
338,5,104,4,0,
338,5,106,1,1,2
338,5,113,4,0,
338,5,115,4,0,
338,5,126,4,0,
338,5,148,4,0,
338,5,149,1,25,
338,5,153,1,49,
338,5,156,4,0,
338,5,157,1,37,
338,5,182,4,0,
338,5,201,4,0,
338,5,216,4,0,
338,5,218,4,0,
338,5,219,4,0,
338,5,237,4,0,
338,5,241,4,0,
338,5,247,4,0,
338,5,263,4,0,
338,5,285,4,0,
338,5,290,4,0,
338,5,315,4,0,
338,5,317,4,0,
338,5,322,1,31,
338,5,347,4,0,
338,6,33,1,1,1
338,6,34,3,0,
338,6,38,3,0,
338,6,53,4,0,
338,6,63,4,0,
338,6,76,1,43,
338,6,76,4,0,
338,6,83,1,19,
338,6,88,1,13,
338,6,89,4,0,
338,6,92,4,0,
338,6,93,1,7,
338,6,94,4,0,
338,6,102,3,0,
338,6,104,4,0,
338,6,106,1,1,2
338,6,111,3,0,
338,6,113,4,0,
338,6,115,4,0,
338,6,126,4,0,
338,6,129,3,0,
338,6,138,3,0,
338,6,148,4,0,
338,6,149,1,25,
338,6,153,1,49,
338,6,153,3,0,
338,6,156,4,0,
338,6,157,1,37,
338,6,157,3,0,
338,6,164,3,0,
338,6,173,3,0,
338,6,182,4,0,
338,6,201,4,0,
338,6,203,3,0,
338,6,205,3,0,
338,6,207,3,0,
338,6,214,3,0,
338,6,216,4,0,
338,6,218,4,0,
338,6,219,4,0,
338,6,237,4,0,
338,6,241,4,0,
338,6,244,3,0,
338,6,247,4,0,
338,6,263,4,0,
338,6,285,4,0,
338,6,290,4,0,
338,6,315,4,0,
338,6,317,4,0,
338,6,322,1,31,
338,6,347,4,0,
338,7,33,1,1,1
338,7,34,3,0,
338,7,38,3,0,
338,7,53,4,0,
338,7,63,4,0,
338,7,76,1,43,
338,7,76,4,0,
338,7,83,1,19,
338,7,88,1,13,
338,7,89,4,0,
338,7,92,4,0,
338,7,93,1,7,
338,7,94,4,0,
338,7,102,3,0,
338,7,104,4,0,
338,7,106,1,1,2
338,7,113,4,0,
338,7,115,4,0,
338,7,126,4,0,
338,7,138,3,0,
338,7,148,4,0,
338,7,149,1,25,
338,7,153,1,49,
338,7,153,3,0,
338,7,156,4,0,
338,7,157,1,37,
338,7,157,3,0,
338,7,164,3,0,
338,7,182,4,0,
338,7,201,4,0,
338,7,216,4,0,
338,7,218,4,0,
338,7,219,4,0,
338,7,237,4,0,
338,7,241,4,0,
338,7,247,4,0,
338,7,263,4,0,
338,7,285,4,0,
338,7,290,4,0,
338,7,315,4,0,
338,7,317,4,0,
338,7,322,1,31,
338,7,347,4,0,
338,8,33,1,1,1
338,8,53,4,0,
338,8,63,4,0,
338,8,76,1,53,
338,8,76,4,0,
338,8,83,1,12,
338,8,88,1,9,
338,8,89,4,0,
338,8,92,4,0,
338,8,93,1,1,3
338,8,94,4,0,
338,8,104,4,0,
338,8,106,1,1,2
338,8,113,4,0,
338,8,115,4,0,
338,8,126,4,0,
338,8,138,4,0,
338,8,148,4,0,
338,8,149,1,23,
338,8,153,1,56,
338,8,153,4,0,
338,8,156,4,0,
338,8,157,1,45,
338,8,157,4,0,
338,8,164,4,0,
338,8,182,4,0,
338,8,201,4,0,
338,8,203,4,0,
338,8,207,4,0,
338,8,214,4,0,
338,8,216,4,0,
338,8,218,4,0,
338,8,219,4,0,
338,8,237,4,0,
338,8,241,4,0,
338,8,244,4,0,
338,8,247,4,0,
338,8,261,4,0,
338,8,263,4,0,
338,8,278,4,0,
338,8,285,4,0,
338,8,290,4,0,
338,8,315,4,0,
338,8,317,4,0,
338,8,322,1,34,
338,8,347,4,0,
338,8,360,4,0,
338,8,363,4,0,
338,8,373,1,31,
338,8,373,4,0,
338,8,377,1,42,
338,8,397,1,20,
338,8,397,4,0,
338,8,416,4,0,
338,8,433,4,0,
338,8,444,4,0,
338,8,446,4,0,
338,8,447,4,0,
338,8,451,4,0,
338,9,33,1,1,1
338,9,53,4,0,
338,9,63,4,0,
338,9,76,1,53,
338,9,76,4,0,
338,9,83,1,12,
338,9,88,1,9,
338,9,89,4,0,
338,9,92,4,0,
338,9,93,1,1,3
338,9,94,4,0,
338,9,104,4,0,
338,9,106,1,1,2
338,9,113,4,0,
338,9,115,4,0,
338,9,126,4,0,
338,9,129,3,0,
338,9,138,4,0,
338,9,148,4,0,
338,9,149,1,23,
338,9,153,1,56,
338,9,153,4,0,
338,9,156,4,0,
338,9,157,1,45,
338,9,157,4,0,
338,9,164,4,0,
338,9,173,3,0,
338,9,182,4,0,
338,9,201,4,0,
338,9,203,4,0,
338,9,205,3,0,
338,9,207,4,0,
338,9,214,4,0,
338,9,216,4,0,
338,9,218,4,0,
338,9,219,4,0,
338,9,237,4,0,
338,9,241,4,0,
338,9,244,4,0,
338,9,246,3,0,
338,9,247,4,0,
338,9,261,4,0,
338,9,263,4,0,
338,9,270,3,0,
338,9,278,4,0,
338,9,285,4,0,
338,9,290,4,0,
338,9,315,4,0,
338,9,317,4,0,
338,9,322,1,34,
338,9,324,3,0,
338,9,334,3,0,
338,9,347,4,0,
338,9,360,4,0,
338,9,363,4,0,
338,9,373,1,31,
338,9,373,4,0,
338,9,377,1,42,
338,9,397,1,20,
338,9,397,4,0,
338,9,414,3,0,
338,9,416,4,0,
338,9,428,3,0,
338,9,433,4,0,
338,9,442,3,0,
338,9,444,4,0,
338,9,446,4,0,
338,9,447,4,0,
338,9,451,4,0,
338,10,33,1,1,1
338,10,53,4,0,
338,10,63,4,0,
338,10,76,1,53,
338,10,76,4,0,
338,10,83,1,12,
338,10,88,1,9,
338,10,89,4,0,
338,10,92,4,0,
338,10,93,1,1,3
338,10,94,4,0,
338,10,104,4,0,
338,10,106,1,1,2
338,10,113,4,0,
338,10,115,4,0,
338,10,126,4,0,
338,10,129,3,0,
338,10,138,4,0,
338,10,148,4,0,
338,10,149,1,23,
338,10,153,1,56,
338,10,153,4,0,
338,10,156,4,0,
338,10,157,1,45,
338,10,157,4,0,
338,10,164,4,0,
338,10,173,3,0,
338,10,182,4,0,
338,10,201,4,0,
338,10,203,4,0,
338,10,205,3,0,
338,10,207,4,0,
338,10,214,4,0,
338,10,216,4,0,
338,10,218,4,0,
338,10,219,4,0,
338,10,220,3,0,
338,10,237,4,0,
338,10,241,4,0,
338,10,244,4,0,
338,10,246,3,0,
338,10,247,4,0,
338,10,261,4,0,
338,10,263,4,0,
338,10,270,3,0,
338,10,277,3,0,
338,10,278,4,0,
338,10,285,4,0,
338,10,290,4,0,
338,10,315,4,0,
338,10,317,4,0,
338,10,322,1,34,
338,10,324,3,0,
338,10,334,3,0,
338,10,347,4,0,
338,10,356,3,0,
338,10,360,4,0,
338,10,363,4,0,
338,10,373,1,31,
338,10,373,4,0,
338,10,377,1,42,
338,10,397,1,20,
338,10,397,4,0,
338,10,414,3,0,
338,10,416,4,0,
338,10,428,3,0,
338,10,433,4,0,
338,10,442,3,0,
338,10,444,4,0,
338,10,446,4,0,
338,10,447,4,0,
338,10,451,4,0,
338,11,33,1,1,1
338,11,53,4,0,
338,11,63,4,0,
338,11,76,1,53,
338,11,76,4,0,
338,11,83,1,12,
338,11,88,1,9,
338,11,89,4,0,
338,11,92,4,0,
338,11,93,1,1,3
338,11,94,4,0,
338,11,104,4,0,
338,11,106,1,1,2
338,11,113,4,0,
338,11,115,4,0,
338,11,126,4,0,
338,11,138,4,0,
338,11,148,4,0,
338,11,149,1,23,
338,11,153,1,56,
338,11,153,4,0,
338,11,156,4,0,
338,11,157,1,45,
338,11,157,4,0,
338,11,164,4,0,
338,11,182,4,0,
338,11,201,4,0,
338,11,207,4,0,
338,11,216,4,0,
338,11,218,4,0,
338,11,219,4,0,
338,11,237,4,0,
338,11,241,4,0,
338,11,244,4,0,
338,11,247,4,0,
338,11,261,4,0,
338,11,263,4,0,
338,11,315,4,0,
338,11,317,4,0,
338,11,322,1,34,
338,11,347,4,0,
338,11,360,4,0,
338,11,373,1,31,
338,11,373,4,0,
338,11,377,1,42,
338,11,397,1,20,
338,11,397,4,0,
338,11,416,4,0,
338,11,433,4,0,
338,11,444,4,0,
338,11,447,4,0,
338,11,451,4,0,
338,11,472,1,64,
338,11,473,4,0,
338,11,477,4,0,
338,11,479,4,0,
338,11,496,4,0,
338,11,510,4,0,
338,11,512,4,0,
338,11,523,4,0,
338,12,33,1,1,1
338,12,53,4,0,
338,12,63,4,0,
338,12,76,1,43,
338,12,76,4,0,
338,12,83,1,19,
338,12,88,1,13,
338,12,89,4,0,
338,12,92,4,0,
338,12,93,1,7,
338,12,94,4,0,
338,12,104,4,0,
338,12,106,1,1,2
338,12,113,4,0,
338,12,115,4,0,
338,12,126,4,0,
338,12,148,4,0,
338,12,149,1,25,
338,12,153,1,49,
338,12,156,4,0,
338,12,157,1,37,
338,12,182,4,0,
338,12,201,4,0,
338,12,216,4,0,
338,12,218,4,0,
338,12,219,4,0,
338,12,237,4,0,
338,12,241,4,0,
338,12,247,4,0,
338,12,263,4,0,
338,12,285,4,0,
338,12,290,4,0,
338,12,315,4,0,
338,12,317,4,0,
338,12,322,1,31,
338,12,347,4,0,
338,13,33,1,1,1
338,13,34,3,0,
338,13,38,3,0,
338,13,53,4,0,
338,13,63,4,0,
338,13,76,1,43,
338,13,76,4,0,
338,13,83,1,19,
338,13,88,1,13,
338,13,89,4,0,
338,13,92,4,0,
338,13,93,1,7,
338,13,94,4,0,
338,13,102,3,0,
338,13,104,4,0,
338,13,106,1,1,2
338,13,113,4,0,
338,13,115,4,0,
338,13,120,3,0,
338,13,126,4,0,
338,13,138,3,0,
338,13,148,4,0,
338,13,149,1,25,
338,13,153,1,49,
338,13,156,4,0,
338,13,157,1,37,
338,13,164,3,0,
338,13,182,4,0,
338,13,201,4,0,
338,13,207,3,0,
338,13,216,4,0,
338,13,218,4,0,
338,13,219,4,0,
338,13,237,4,0,
338,13,241,4,0,
338,13,247,4,0,
338,13,263,4,0,
338,13,285,4,0,
338,13,290,4,0,
338,13,315,4,0,
338,13,317,4,0,
338,13,322,1,31,
338,13,347,4,0,
338,14,33,1,1,1
338,14,53,4,0,
338,14,63,4,0,
338,14,76,1,45,
338,14,76,4,0,
338,14,83,1,9,
338,14,88,1,5,
338,14,89,4,0,
338,14,92,4,0,
338,14,93,1,1,3
338,14,94,1,33,
338,14,94,4,0,
338,14,104,4,0,
338,14,106,1,1,2
338,14,113,4,0,
338,14,115,4,0,
338,14,126,4,0,
338,14,138,4,0,
338,14,148,4,0,
338,14,149,1,17,
338,14,153,1,49,
338,14,153,4,0,
338,14,156,4,0,
338,14,157,1,25,
338,14,157,4,0,
338,14,164,4,0,
338,14,173,3,0,
338,14,182,4,0,
338,14,201,4,0,
338,14,207,4,0,
338,14,214,3,0,
338,14,216,4,0,
338,14,218,4,0,
338,14,219,4,0,
338,14,220,3,0,
338,14,237,4,0,
338,14,241,4,0,
338,14,244,4,0,
338,14,247,4,0,
338,14,257,3,0,
338,14,261,4,0,
338,14,263,4,0,
338,14,270,3,0,
338,14,277,3,0,
338,14,278,3,0,
338,14,285,3,0,
338,14,315,4,0,
338,14,317,4,0,
338,14,322,1,29,
338,14,324,3,0,
338,14,334,3,0,
338,14,347,4,0,
338,14,356,3,0,
338,14,360,4,0,
338,14,373,1,21,
338,14,373,4,0,
338,14,377,1,37,
338,14,397,1,13,
338,14,397,4,0,
338,14,414,3,0,
338,14,416,4,0,
338,14,428,3,0,
338,14,433,4,0,
338,14,442,3,0,
338,14,444,1,41,
338,14,444,4,0,
338,14,446,3,0,
338,14,447,4,0,
338,14,451,4,0,
338,14,472,1,53,
338,14,472,3,0,
338,14,473,4,0,
338,14,477,4,0,
338,14,479,4,0,
338,14,496,4,0,
338,14,510,4,0,
338,14,512,4,0,
338,14,523,4,0,
338,15,33,1,1,3
338,15,53,4,0,
338,15,63,4,0,
338,15,76,1,45,
338,15,76,4,0,
338,15,83,1,9,
338,15,88,1,1,2
338,15,88,1,5,
338,15,89,4,0,
338,15,92,4,0,
338,15,93,1,1,5
338,15,94,1,33,
338,15,94,4,0,
338,15,104,4,0,
338,15,106,1,1,4
338,15,113,4,0,
338,15,115,4,0,
338,15,126,4,0,
338,15,138,4,0,
338,15,148,4,0,
338,15,149,1,17,
338,15,153,1,49,
338,15,153,4,0,
338,15,156,4,0,
338,15,157,1,25,
338,15,157,4,0,
338,15,164,4,0,
338,15,182,4,0,
338,15,201,4,0,
338,15,207,4,0,
338,15,214,4,0,
338,15,216,4,0,
338,15,218,4,0,
338,15,219,4,0,
338,15,237,4,0,
338,15,241,4,0,
338,15,244,4,0,
338,15,247,4,0,
338,15,261,4,0,
338,15,263,4,0,
338,15,315,4,0,
338,15,317,4,0,
338,15,322,1,29,
338,15,347,4,0,
338,15,360,4,0,
338,15,373,1,21,
338,15,373,4,0,
338,15,377,1,37,
338,15,397,1,13,
338,15,397,4,0,
338,15,416,4,0,
338,15,433,4,0,
338,15,444,1,41,
338,15,444,4,0,
338,15,447,4,0,
338,15,451,4,0,
338,15,472,1,1,1
338,15,472,1,53,
338,15,473,4,0,
338,15,479,4,0,
338,15,496,4,0,
338,15,510,4,0,
338,15,512,4,0,
338,15,523,4,0,
338,15,590,4,0,
338,16,33,1,1,1
338,16,53,4,0,
338,16,63,4,0,
338,16,76,1,41,1
338,16,76,4,0,
338,16,83,1,5,1
338,16,88,1,1,4
338,16,89,4,0,
338,16,92,4,0,
338,16,93,1,1,3
338,16,94,1,29,1
338,16,94,4,0,
338,16,104,4,0,
338,16,106,1,1,2
338,16,113,4,0,
338,16,115,4,0,
338,16,126,4,0,
338,16,138,4,0,
338,16,148,4,0,
338,16,149,1,13,1
338,16,153,1,45,1
338,16,153,4,0,
338,16,156,4,0,
338,16,157,1,21,1
338,16,157,4,0,
338,16,164,4,0,
338,16,173,3,0,
338,16,182,4,0,
338,16,201,4,0,
338,16,207,4,0,
338,16,214,4,0,
338,16,216,4,0,
338,16,218,4,0,
338,16,219,4,0,
338,16,220,3,0,
338,16,237,4,0,
338,16,241,4,0,
338,16,244,4,0,
338,16,247,4,0,
338,16,257,3,0,
338,16,261,4,0,
338,16,263,4,0,
338,16,270,3,0,
338,16,277,3,0,
338,16,278,3,0,
338,16,285,3,0,
338,16,290,4,0,
338,16,315,4,0,
338,16,317,4,0,
338,16,322,1,25,1
338,16,324,3,0,
338,16,334,3,0,
338,16,347,4,0,
338,16,356,3,0,
338,16,360,4,0,
338,16,373,1,17,1
338,16,373,4,0,
338,16,377,1,33,1
338,16,397,1,9,1
338,16,397,4,0,
338,16,414,3,0,
338,16,416,4,0,
338,16,428,3,0,
338,16,433,4,0,
338,16,442,3,0,
338,16,444,1,37,1
338,16,444,4,0,
338,16,446,3,0,
338,16,447,4,0,
338,16,451,4,0,
338,16,472,1,49,1
338,16,472,3,0,
338,16,473,4,0,
338,16,479,4,0,
338,16,496,4,0,
338,16,510,4,0,
338,16,512,4,0,
338,16,523,4,0,
338,16,590,4,0,
338,17,33,1,1,2
338,17,53,4,0,
338,17,63,4,0,
338,17,76,1,41,
338,17,76,4,0,
338,17,83,1,5,
338,17,88,1,1,5
338,17,89,4,0,
338,17,92,4,0,
338,17,93,1,1,4
338,17,94,1,29,
338,17,94,4,0,
338,17,104,4,0,
338,17,106,1,1,3
338,17,113,4,0,
338,17,115,4,0,
338,17,126,4,0,
338,17,138,4,0,
338,17,149,1,13,
338,17,153,1,45,
338,17,153,4,0,
338,17,156,4,0,
338,17,157,1,21,
338,17,157,4,0,
338,17,164,4,0,
338,17,182,4,0,
338,17,201,4,0,
338,17,207,4,0,
338,17,214,4,0,
338,17,216,4,0,
338,17,218,4,0,
338,17,219,4,0,
338,17,237,4,0,
338,17,241,4,0,
338,17,244,4,0,
338,17,247,4,0,
338,17,261,4,0,
338,17,263,4,0,
338,17,315,4,0,
338,17,317,4,0,
338,17,322,1,25,
338,17,347,4,0,
338,17,360,4,0,
338,17,373,1,17,
338,17,373,4,0,
338,17,377,1,33,
338,17,394,1,1,1
338,17,397,1,9,
338,17,397,4,0,
338,17,416,4,0,
338,17,433,4,0,
338,17,444,1,37,
338,17,444,4,0,
338,17,447,4,0,
338,17,451,4,0,
338,17,472,1,49,
338,17,473,4,0,
338,17,479,4,0,
338,17,496,4,0,
338,17,512,4,0,
338,17,523,4,0,
338,17,590,4,0,
338,18,33,1,1,2
338,18,53,4,0,
338,18,63,4,0,
338,18,76,1,41,
338,18,76,4,0,
338,18,83,1,5,
338,18,88,1,1,5
338,18,89,4,0,
338,18,92,4,0,
338,18,93,1,1,4
338,18,94,1,29,
338,18,94,4,0,
338,18,104,4,0,
338,18,106,1,1,3
338,18,113,4,0,
338,18,115,4,0,
338,18,126,4,0,
338,18,138,4,0,
338,18,149,1,13,
338,18,153,1,45,
338,18,153,4,0,
338,18,156,4,0,
338,18,157,1,21,
338,18,157,4,0,
338,18,164,4,0,
338,18,182,4,0,
338,18,201,4,0,
338,18,207,4,0,
338,18,214,4,0,
338,18,216,4,0,
338,18,218,4,0,
338,18,219,4,0,
338,18,237,4,0,
338,18,241,4,0,
338,18,244,4,0,
338,18,247,4,0,
338,18,261,4,0,
338,18,263,4,0,
338,18,315,4,0,
338,18,317,4,0,
338,18,322,1,25,
338,18,347,4,0,
338,18,360,4,0,
338,18,373,1,17,
338,18,373,4,0,
338,18,377,1,33,
338,18,394,1,1,1
338,18,397,1,9,
338,18,397,4,0,
338,18,416,4,0,
338,18,433,4,0,
338,18,444,1,37,
338,18,444,4,0,
338,18,447,4,0,
338,18,451,4,0,
338,18,472,1,49,
338,18,473,4,0,
338,18,479,4,0,
338,18,496,4,0,
338,18,512,4,0,
338,18,523,4,0,
338,18,590,4,0,
339,5,37,2,0,
339,5,55,1,11,
339,5,57,4,0,
339,5,58,4,0,
339,5,59,4,0,
339,5,89,1,31,
339,5,89,4,0,
339,5,90,1,41,
339,5,92,4,0,
339,5,104,4,0,
339,5,127,4,0,
339,5,133,1,21,
339,5,156,1,26,
339,5,156,4,0,
339,5,173,1,26,3
339,5,182,4,0,
339,5,189,1,1,
339,5,201,4,0,
339,5,209,2,0,
339,5,213,4,0,
339,5,216,4,0,
339,5,218,4,0,
339,5,222,1,16,
339,5,237,4,0,
339,5,240,4,0,
339,5,248,1,36,
339,5,250,2,0,
339,5,258,4,0,
339,5,263,4,0,
339,5,290,4,0,
339,5,291,4,0,
339,5,300,1,6,1
339,5,317,4,0,
339,5,346,1,6,2
339,5,352,4,0,
339,6,37,2,0,
339,6,38,3,0,
339,6,55,1,11,
339,6,57,4,0,
339,6,58,4,0,
339,6,59,4,0,
339,6,89,1,31,
339,6,89,4,0,
339,6,90,1,41,
339,6,92,4,0,
339,6,102,3,0,
339,6,104,4,0,
339,6,127,4,0,
339,6,133,1,21,
339,6,156,1,26,
339,6,156,4,0,
339,6,164,3,0,
339,6,173,1,26,3
339,6,173,3,0,
339,6,182,4,0,
339,6,189,1,1,
339,6,189,3,0,
339,6,196,3,0,
339,6,201,4,0,
339,6,203,3,0,
339,6,207,3,0,
339,6,209,2,0,
339,6,213,4,0,
339,6,214,3,0,
339,6,216,4,0,
339,6,218,4,0,
339,6,222,1,16,
339,6,237,4,0,
339,6,240,4,0,
339,6,248,1,36,
339,6,250,2,0,
339,6,258,4,0,
339,6,263,4,0,
339,6,290,4,0,
339,6,291,4,0,
339,6,300,1,6,1
339,6,317,4,0,
339,6,346,1,6,2
339,6,352,4,0,
339,7,37,2,0,
339,7,38,3,0,
339,7,55,1,11,
339,7,57,4,0,
339,7,58,4,0,
339,7,59,4,0,
339,7,89,1,31,
339,7,89,4,0,
339,7,90,1,41,
339,7,92,4,0,
339,7,102,3,0,
339,7,104,4,0,
339,7,127,4,0,
339,7,133,1,21,
339,7,156,1,26,
339,7,156,4,0,
339,7,164,3,0,
339,7,173,1,26,3
339,7,182,4,0,
339,7,189,1,1,
339,7,201,4,0,
339,7,209,2,0,
339,7,213,4,0,
339,7,216,4,0,
339,7,218,4,0,
339,7,222,1,16,
339,7,237,4,0,
339,7,240,4,0,
339,7,248,1,36,
339,7,250,2,0,
339,7,258,4,0,
339,7,263,4,0,
339,7,290,4,0,
339,7,291,4,0,
339,7,300,1,6,1
339,7,317,4,0,
339,7,346,1,6,2
339,7,352,4,0,
339,8,36,2,0,
339,8,37,2,0,
339,8,55,1,10,
339,8,56,2,0,
339,8,57,4,0,
339,8,58,4,0,
339,8,59,4,0,
339,8,89,1,39,
339,8,89,4,0,
339,8,90,1,47,
339,8,92,4,0,
339,8,104,4,0,
339,8,127,4,0,
339,8,133,1,18,
339,8,156,1,31,1
339,8,156,4,0,
339,8,164,4,0,
339,8,173,1,31,2
339,8,175,2,0,
339,8,182,4,0,
339,8,189,1,1,
339,8,201,4,0,
339,8,203,4,0,
339,8,207,4,0,
339,8,209,2,0,
339,8,213,4,0,
339,8,214,4,0,
339,8,216,4,0,
339,8,218,4,0,
339,8,222,1,26,
339,8,237,4,0,
339,8,240,4,0,
339,8,248,1,43,
339,8,250,2,0,
339,8,258,4,0,
339,8,263,4,0,
339,8,290,4,0,
339,8,300,1,6,1
339,8,317,4,0,
339,8,346,1,6,2
339,8,352,1,22,
339,8,352,4,0,
339,8,363,4,0,
339,8,401,1,35,
339,8,426,1,14,
339,8,445,4,0,
339,9,36,2,0,
339,9,37,2,0,
339,9,55,1,10,
339,9,56,2,0,
339,9,57,4,0,
339,9,58,4,0,
339,9,59,4,0,
339,9,89,1,39,
339,9,89,4,0,
339,9,90,1,47,
339,9,92,4,0,
339,9,104,4,0,
339,9,127,4,0,
339,9,133,1,18,
339,9,156,1,31,1
339,9,156,4,0,
339,9,164,4,0,
339,9,173,1,31,2
339,9,173,3,0,
339,9,175,2,0,
339,9,182,4,0,
339,9,189,1,1,
339,9,189,3,0,
339,9,196,3,0,
339,9,201,4,0,
339,9,203,4,0,
339,9,207,4,0,
339,9,209,2,0,
339,9,213,4,0,
339,9,214,4,0,
339,9,216,4,0,
339,9,218,4,0,
339,9,222,1,26,
339,9,237,4,0,
339,9,240,4,0,
339,9,248,1,43,
339,9,250,2,0,
339,9,258,4,0,
339,9,263,4,0,
339,9,290,4,0,
339,9,291,3,0,
339,9,300,1,6,1
339,9,317,4,0,
339,9,340,3,0,
339,9,346,1,6,2
339,9,352,1,22,
339,9,352,4,0,
339,9,363,4,0,
339,9,401,1,35,
339,9,401,3,0,
339,9,414,3,0,
339,9,426,1,14,
339,9,445,4,0,
339,10,29,3,0,
339,10,36,2,0,
339,10,37,2,0,
339,10,55,1,10,
339,10,56,2,0,
339,10,57,4,0,
339,10,58,4,0,
339,10,59,4,0,
339,10,89,1,39,
339,10,89,4,0,
339,10,90,1,47,
339,10,92,4,0,
339,10,104,4,0,
339,10,127,4,0,
339,10,133,1,18,
339,10,156,1,31,1
339,10,156,4,0,
339,10,164,4,0,
339,10,173,1,31,2
339,10,173,3,0,
339,10,175,2,0,
339,10,182,4,0,
339,10,189,1,1,
339,10,189,3,0,
339,10,196,3,0,
339,10,201,4,0,
339,10,203,4,0,
339,10,207,4,0,
339,10,209,2,0,
339,10,213,4,0,
339,10,214,4,0,
339,10,216,4,0,
339,10,218,4,0,
339,10,222,1,26,
339,10,237,4,0,
339,10,240,4,0,
339,10,248,1,43,
339,10,250,2,0,
339,10,250,4,0,
339,10,258,4,0,
339,10,263,4,0,
339,10,290,4,0,
339,10,291,3,0,
339,10,300,1,6,1
339,10,317,4,0,
339,10,340,3,0,
339,10,346,1,6,2
339,10,349,2,0,
339,10,352,1,22,
339,10,352,4,0,
339,10,363,4,0,
339,10,401,1,35,
339,10,401,3,0,
339,10,414,3,0,
339,10,426,1,14,
339,10,445,4,0,
339,11,36,2,0,
339,11,37,2,0,
339,11,55,1,10,
339,11,56,2,0,
339,11,57,4,0,
339,11,58,4,0,
339,11,59,4,0,
339,11,89,1,39,
339,11,89,4,0,
339,11,90,1,47,
339,11,92,4,0,
339,11,104,4,0,
339,11,127,4,0,
339,11,133,1,18,
339,11,156,1,31,1
339,11,156,4,0,
339,11,164,4,0,
339,11,173,1,31,2
339,11,175,2,0,
339,11,182,4,0,
339,11,189,1,1,
339,11,201,4,0,
339,11,207,4,0,
339,11,209,2,0,
339,11,213,4,0,
339,11,216,4,0,
339,11,218,4,0,
339,11,222,1,26,
339,11,237,4,0,
339,11,240,4,0,
339,11,248,1,43,
339,11,250,2,0,
339,11,258,4,0,
339,11,263,4,0,
339,11,291,4,0,
339,11,300,1,6,1
339,11,317,4,0,
339,11,330,2,0,
339,11,341,2,0,
339,11,346,1,6,2
339,11,349,2,0,
339,11,352,1,22,
339,11,401,1,35,
339,11,414,2,0,
339,11,426,1,14,
339,11,496,4,0,
339,11,503,4,0,
339,11,523,4,0,
339,12,55,1,11,
339,12,57,4,0,
339,12,58,4,0,
339,12,59,4,0,
339,12,89,1,31,
339,12,89,4,0,
339,12,90,1,41,
339,12,92,4,0,
339,12,104,4,0,
339,12,127,4,0,
339,12,133,1,21,
339,12,156,1,26,
339,12,156,4,0,
339,12,173,1,26,3
339,12,182,4,0,
339,12,189,1,1,
339,12,201,4,0,
339,12,213,4,0,
339,12,216,4,0,
339,12,218,4,0,
339,12,222,1,16,
339,12,237,4,0,
339,12,240,4,0,
339,12,248,1,36,
339,12,258,4,0,
339,12,263,4,0,
339,12,290,4,0,
339,12,291,4,0,
339,12,300,1,6,1
339,12,317,4,0,
339,12,346,1,6,2
339,12,352,4,0,
339,13,38,3,0,
339,13,55,1,11,
339,13,57,4,0,
339,13,58,4,0,
339,13,59,4,0,
339,13,89,1,31,
339,13,89,4,0,
339,13,90,1,41,
339,13,92,4,0,
339,13,102,3,0,
339,13,104,4,0,
339,13,127,4,0,
339,13,133,1,21,
339,13,156,1,26,
339,13,156,4,0,
339,13,164,3,0,
339,13,173,1,26,3
339,13,182,4,0,
339,13,189,1,1,
339,13,196,3,0,
339,13,201,4,0,
339,13,207,3,0,
339,13,213,4,0,
339,13,216,4,0,
339,13,218,4,0,
339,13,222,1,16,
339,13,237,4,0,
339,13,240,4,0,
339,13,248,1,36,
339,13,258,4,0,
339,13,263,4,0,
339,13,290,4,0,
339,13,291,4,0,
339,13,300,1,6,1
339,13,317,4,0,
339,13,346,1,6,2
339,13,352,4,0,
339,14,36,2,0,
339,14,37,2,0,
339,14,55,1,10,
339,14,56,2,0,
339,14,57,4,0,
339,14,58,4,0,
339,14,59,4,0,
339,14,89,1,39,
339,14,89,4,0,
339,14,90,1,47,
339,14,92,4,0,
339,14,104,4,0,
339,14,127,4,0,
339,14,133,1,18,
339,14,156,1,31,1
339,14,156,4,0,
339,14,164,4,0,
339,14,173,1,31,2
339,14,173,3,0,
339,14,175,2,0,
339,14,182,4,0,
339,14,189,1,1,
339,14,196,3,0,
339,14,201,4,0,
339,14,207,4,0,
339,14,209,2,0,
339,14,213,4,0,
339,14,214,3,0,
339,14,216,4,0,
339,14,218,4,0,
339,14,222,1,26,
339,14,237,4,0,
339,14,240,4,0,
339,14,248,1,43,
339,14,250,2,0,
339,14,258,4,0,
339,14,263,4,0,
339,14,291,4,0,
339,14,300,1,6,1
339,14,317,4,0,
339,14,330,2,0,
339,14,340,3,0,
339,14,341,2,0,
339,14,346,1,6,2
339,14,349,2,0,
339,14,352,1,22,
339,14,401,1,35,
339,14,401,3,0,
339,14,414,2,0,
339,14,414,3,0,
339,14,426,1,14,
339,14,496,4,0,
339,14,503,4,0,
339,14,523,4,0,
339,15,36,2,0,
339,15,37,2,0,
339,15,55,1,10,
339,15,56,2,0,
339,15,57,4,0,
339,15,58,4,0,
339,15,59,4,0,
339,15,89,1,39,
339,15,89,4,0,
339,15,90,1,47,
339,15,92,4,0,
339,15,104,4,0,
339,15,127,4,0,
339,15,133,1,18,
339,15,156,1,31,1
339,15,156,4,0,
339,15,164,4,0,
339,15,173,1,31,2
339,15,175,2,0,
339,15,182,4,0,
339,15,189,1,1,
339,15,201,4,0,
339,15,207,4,0,
339,15,209,2,0,
339,15,213,4,0,
339,15,214,4,0,
339,15,216,4,0,
339,15,218,4,0,
339,15,222,1,26,
339,15,237,4,0,
339,15,240,4,0,
339,15,248,1,43,
339,15,250,2,0,
339,15,258,4,0,
339,15,263,4,0,
339,15,300,1,6,1
339,15,317,4,0,
339,15,330,2,0,
339,15,341,2,0,
339,15,346,1,6,2
339,15,349,2,0,
339,15,352,1,22,
339,15,401,1,35,
339,15,414,2,0,
339,15,426,1,14,
339,15,496,4,0,
339,15,503,4,0,
339,15,523,4,0,
339,15,590,4,0,
339,16,36,2,0,
339,16,37,2,0,
339,16,55,1,9,1
339,16,56,2,0,
339,16,57,4,0,
339,16,58,4,0,
339,16,59,4,0,
339,16,89,1,32,1
339,16,89,4,0,
339,16,90,1,44,1
339,16,92,4,0,
339,16,104,4,0,
339,16,127,4,0,
339,16,133,1,15,1
339,16,156,1,25,1
339,16,156,4,0,
339,16,164,4,0,
339,16,173,1,25,2
339,16,173,3,0,
339,16,175,2,0,
339,16,182,4,0,
339,16,189,1,1,1
339,16,196,3,0,
339,16,201,4,0,
339,16,207,4,0,
339,16,209,2,0,
339,16,213,4,0,
339,16,214,4,0,
339,16,216,4,0,
339,16,218,4,0,
339,16,222,1,20,1
339,16,237,4,0,
339,16,240,4,0,
339,16,248,1,39,1
339,16,250,2,0,
339,16,258,4,0,
339,16,263,4,0,
339,16,290,4,0,
339,16,291,4,0,
339,16,300,1,6,1
339,16,317,4,0,
339,16,330,1,35,1
339,16,330,2,0,
339,16,340,3,0,
339,16,341,2,0,
339,16,346,1,6,2
339,16,349,2,0,
339,16,352,1,17,1
339,16,352,3,0,
339,16,401,1,28,1
339,16,401,3,0,
339,16,414,2,0,
339,16,414,3,0,
339,16,426,1,13,1
339,16,496,4,0,
339,16,503,4,0,
339,16,523,4,0,
339,16,590,4,0,
339,17,36,2,0,
339,17,37,2,0,
339,17,55,1,9,
339,17,56,2,0,
339,17,57,4,0,
339,17,58,4,0,
339,17,59,4,0,
339,17,89,1,32,
339,17,89,4,0,
339,17,90,1,44,
339,17,92,4,0,
339,17,104,4,0,
339,17,127,4,0,
339,17,133,1,15,
339,17,156,1,25,1
339,17,156,4,0,
339,17,164,4,0,
339,17,173,1,25,2
339,17,175,2,0,
339,17,182,4,0,
339,17,189,1,1,
339,17,201,4,0,
339,17,207,4,0,
339,17,209,2,0,
339,17,213,4,0,
339,17,214,4,0,
339,17,216,4,0,
339,17,218,4,0,
339,17,222,1,20,
339,17,237,4,0,
339,17,240,4,0,
339,17,248,1,39,
339,17,250,2,0,
339,17,258,4,0,
339,17,263,4,0,
339,17,300,1,6,1
339,17,317,4,0,
339,17,330,1,35,
339,17,330,2,0,
339,17,341,2,0,
339,17,346,1,6,2
339,17,349,2,0,
339,17,352,1,17,
339,17,401,1,28,
339,17,414,2,0,
339,17,426,1,13,
339,17,496,4,0,
339,17,503,4,0,
339,17,523,4,0,
339,17,590,4,0,
339,18,36,2,0,
339,18,37,2,0,
339,18,55,1,9,
339,18,56,2,0,
339,18,57,4,0,
339,18,58,4,0,
339,18,59,4,0,
339,18,89,1,32,
339,18,89,4,0,
339,18,90,1,44,
339,18,92,4,0,
339,18,104,4,0,
339,18,127,4,0,
339,18,133,1,15,
339,18,156,1,25,1
339,18,156,4,0,
339,18,164,4,0,
339,18,173,1,25,2
339,18,175,2,0,
339,18,182,4,0,
339,18,189,1,1,
339,18,201,4,0,
339,18,207,4,0,
339,18,209,2,0,
339,18,213,4,0,
339,18,214,4,0,
339,18,216,4,0,
339,18,218,4,0,
339,18,222,1,20,
339,18,237,4,0,
339,18,240,4,0,
339,18,248,1,39,
339,18,250,2,0,
339,18,258,4,0,
339,18,263,4,0,
339,18,300,1,6,1
339,18,317,4,0,
339,18,330,1,35,
339,18,330,2,0,
339,18,341,2,0,
339,18,346,1,6,2
339,18,349,2,0,
339,18,352,1,17,
339,18,401,1,28,
339,18,414,2,0,
339,18,426,1,13,
339,18,496,4,0,
339,18,503,4,0,
339,18,523,4,0,
339,18,590,4,0,
340,5,55,1,11,
340,5,57,4,0,
340,5,58,4,0,
340,5,59,4,0,
340,5,63,4,0,
340,5,70,4,0,
340,5,89,1,36,
340,5,89,4,0,
340,5,90,1,56,
340,5,92,4,0,
340,5,104,4,0,
340,5,127,4,0,
340,5,133,1,21,
340,5,156,1,26,
340,5,156,4,0,
340,5,173,1,26,6
340,5,182,4,0,
340,5,189,1,1,2
340,5,201,4,0,
340,5,213,4,0,
340,5,216,4,0,
340,5,218,4,0,
340,5,222,1,16,
340,5,237,4,0,
340,5,240,4,0,
340,5,248,1,46,
340,5,249,4,0,
340,5,258,4,0,
340,5,263,4,0,
340,5,290,4,0,
340,5,291,4,0,
340,5,300,1,1,3
340,5,300,1,6,
340,5,317,4,0,
340,5,321,1,1,1
340,5,346,1,1,4
340,5,346,1,6,5
340,5,352,4,0,
340,6,38,3,0,
340,6,55,1,11,
340,6,57,4,0,
340,6,58,4,0,
340,6,59,4,0,
340,6,63,4,0,
340,6,70,4,0,
340,6,89,1,36,
340,6,89,4,0,
340,6,90,1,56,
340,6,92,4,0,
340,6,102,3,0,
340,6,104,4,0,
340,6,127,4,0,
340,6,133,1,21,
340,6,156,1,26,
340,6,156,4,0,
340,6,157,3,0,
340,6,164,3,0,
340,6,173,1,26,6
340,6,173,3,0,
340,6,182,4,0,
340,6,189,1,1,2
340,6,189,3,0,
340,6,196,3,0,
340,6,201,4,0,
340,6,203,3,0,
340,6,207,3,0,
340,6,213,4,0,
340,6,214,3,0,
340,6,216,4,0,
340,6,218,4,0,
340,6,222,1,16,
340,6,237,4,0,
340,6,240,4,0,
340,6,248,1,46,
340,6,249,4,0,
340,6,258,4,0,
340,6,263,4,0,
340,6,290,4,0,
340,6,291,4,0,
340,6,300,1,1,3
340,6,300,1,6,
340,6,317,4,0,
340,6,321,1,1,1
340,6,346,1,1,4
340,6,346,1,6,5
340,6,352,4,0,
340,7,38,3,0,
340,7,55,1,11,
340,7,57,4,0,
340,7,58,4,0,
340,7,59,4,0,
340,7,63,4,0,
340,7,70,4,0,
340,7,89,1,36,
340,7,89,4,0,
340,7,90,1,56,
340,7,92,4,0,
340,7,102,3,0,
340,7,104,4,0,
340,7,127,4,0,
340,7,133,1,21,
340,7,156,1,26,
340,7,156,4,0,
340,7,157,3,0,
340,7,164,3,0,
340,7,173,1,26,6
340,7,182,4,0,
340,7,189,1,1,2
340,7,201,4,0,
340,7,213,4,0,
340,7,216,4,0,
340,7,218,4,0,
340,7,222,1,16,
340,7,237,4,0,
340,7,240,4,0,
340,7,248,1,46,
340,7,249,4,0,
340,7,258,4,0,
340,7,263,4,0,
340,7,290,4,0,
340,7,291,4,0,
340,7,300,1,1,3
340,7,300,1,6,
340,7,317,4,0,
340,7,321,1,1,1
340,7,346,1,1,4
340,7,346,1,6,5
340,7,352,4,0,
340,8,55,1,10,
340,8,57,4,0,
340,8,58,4,0,
340,8,59,4,0,
340,8,63,4,0,
340,8,70,4,0,
340,8,89,1,45,
340,8,89,4,0,
340,8,90,1,57,
340,8,92,4,0,
340,8,104,4,0,
340,8,127,4,0,
340,8,133,1,18,
340,8,156,1,33,1
340,8,156,4,0,
340,8,157,4,0,
340,8,164,4,0,
340,8,173,1,33,2
340,8,182,4,0,
340,8,189,1,1,3
340,8,201,4,0,
340,8,203,4,0,
340,8,207,4,0,
340,8,213,4,0,
340,8,214,4,0,
340,8,216,4,0,
340,8,218,4,0,
340,8,222,1,26,
340,8,237,4,0,
340,8,240,4,0,
340,8,248,1,51,
340,8,249,4,0,
340,8,258,4,0,
340,8,263,4,0,
340,8,290,4,0,
340,8,300,1,1,4
340,8,300,1,6,1
340,8,317,4,0,
340,8,321,1,1,2
340,8,346,1,1,5
340,8,346,1,6,2
340,8,352,1,22,
340,8,352,4,0,
340,8,363,4,0,
340,8,401,1,39,
340,8,416,4,0,
340,8,426,1,14,
340,8,428,1,1,1
340,8,444,4,0,
340,8,445,4,0,
340,9,55,1,10,
340,9,57,4,0,
340,9,58,4,0,
340,9,59,4,0,
340,9,63,4,0,
340,9,70,4,0,
340,9,89,1,45,
340,9,89,4,0,
340,9,90,1,57,
340,9,92,4,0,
340,9,104,4,0,
340,9,127,4,0,
340,9,133,1,18,
340,9,156,1,33,1
340,9,156,4,0,
340,9,157,4,0,
340,9,164,4,0,
340,9,173,1,33,2
340,9,173,3,0,
340,9,182,4,0,
340,9,189,1,1,3
340,9,189,3,0,
340,9,196,3,0,
340,9,201,4,0,
340,9,203,4,0,
340,9,207,4,0,
340,9,213,4,0,
340,9,214,4,0,
340,9,216,4,0,
340,9,218,4,0,
340,9,222,1,26,
340,9,237,4,0,
340,9,240,4,0,
340,9,248,1,51,
340,9,249,4,0,
340,9,258,4,0,
340,9,263,4,0,
340,9,290,4,0,
340,9,291,3,0,
340,9,300,1,1,4
340,9,300,1,6,1
340,9,317,4,0,
340,9,321,1,1,2
340,9,340,3,0,
340,9,346,1,1,5
340,9,346,1,6,2
340,9,352,1,22,
340,9,352,4,0,
340,9,363,4,0,
340,9,401,1,39,
340,9,401,3,0,
340,9,414,3,0,
340,9,416,4,0,
340,9,426,1,14,
340,9,428,1,1,1
340,9,428,3,0,
340,9,444,4,0,
340,9,445,4,0,
340,10,29,3,0,
340,10,55,1,10,
340,10,57,4,0,
340,10,58,4,0,
340,10,59,4,0,
340,10,63,4,0,
340,10,70,4,0,
340,10,89,1,45,
340,10,89,4,0,
340,10,90,1,57,
340,10,92,4,0,
340,10,104,4,0,
340,10,127,4,0,
340,10,133,1,18,
340,10,156,1,33,1
340,10,156,4,0,
340,10,157,4,0,
340,10,164,4,0,
340,10,173,1,33,2
340,10,173,3,0,
340,10,182,4,0,
340,10,189,1,1,3
340,10,189,3,0,
340,10,196,3,0,
340,10,201,4,0,
340,10,203,4,0,
340,10,207,4,0,
340,10,213,4,0,
340,10,214,4,0,
340,10,216,4,0,
340,10,218,4,0,
340,10,222,1,26,
340,10,237,4,0,
340,10,240,4,0,
340,10,248,1,51,
340,10,249,4,0,
340,10,250,4,0,
340,10,258,4,0,
340,10,263,4,0,
340,10,290,4,0,
340,10,291,3,0,
340,10,300,1,1,4
340,10,300,1,6,1
340,10,317,4,0,
340,10,321,1,1,2
340,10,340,3,0,
340,10,346,1,1,5
340,10,346,1,6,2
340,10,352,1,22,
340,10,352,4,0,
340,10,363,4,0,
340,10,401,1,39,
340,10,401,3,0,
340,10,414,3,0,
340,10,416,4,0,
340,10,426,1,14,
340,10,428,1,1,1
340,10,428,3,0,
340,10,444,4,0,
340,10,445,4,0,
340,11,55,1,10,
340,11,57,4,0,
340,11,58,4,0,
340,11,59,4,0,
340,11,63,4,0,
340,11,70,4,0,
340,11,89,1,45,
340,11,89,4,0,
340,11,90,1,57,
340,11,92,4,0,
340,11,104,4,0,
340,11,127,4,0,
340,11,133,1,18,
340,11,156,1,33,1
340,11,156,4,0,
340,11,157,4,0,
340,11,164,4,0,
340,11,173,1,33,2
340,11,182,4,0,
340,11,189,1,1,3
340,11,201,4,0,
340,11,207,4,0,
340,11,213,4,0,
340,11,216,4,0,
340,11,218,4,0,
340,11,222,1,26,
340,11,237,4,0,
340,11,240,4,0,
340,11,248,1,51,
340,11,249,4,0,
340,11,258,4,0,
340,11,263,4,0,
340,11,291,4,0,
340,11,300,1,1,4
340,11,300,1,6,1
340,11,317,4,0,
340,11,321,1,1,2
340,11,346,1,1,5
340,11,346,1,6,2
340,11,352,1,22,
340,11,401,1,39,
340,11,416,4,0,
340,11,426,1,14,
340,11,428,1,1,1
340,11,444,4,0,
340,11,496,4,0,
340,11,503,4,0,
340,11,523,4,0,
340,12,55,1,11,
340,12,57,4,0,
340,12,58,4,0,
340,12,59,4,0,
340,12,63,4,0,
340,12,70,4,0,
340,12,89,1,36,
340,12,89,4,0,
340,12,90,1,56,
340,12,92,4,0,
340,12,104,4,0,
340,12,127,4,0,
340,12,133,1,21,
340,12,156,1,26,
340,12,156,4,0,
340,12,173,1,26,6
340,12,182,4,0,
340,12,189,1,1,2
340,12,201,4,0,
340,12,213,4,0,
340,12,216,4,0,
340,12,218,4,0,
340,12,222,1,16,
340,12,237,4,0,
340,12,240,4,0,
340,12,248,1,46,
340,12,249,4,0,
340,12,258,4,0,
340,12,263,4,0,
340,12,290,4,0,
340,12,291,4,0,
340,12,300,1,1,3
340,12,300,1,6,
340,12,317,4,0,
340,12,321,1,1,1
340,12,346,1,1,4
340,12,346,1,6,5
340,12,352,4,0,
340,13,38,3,0,
340,13,55,1,11,
340,13,57,4,0,
340,13,58,4,0,
340,13,59,4,0,
340,13,63,4,0,
340,13,70,4,0,
340,13,89,1,36,
340,13,89,4,0,
340,13,90,1,56,
340,13,92,4,0,
340,13,102,3,0,
340,13,104,4,0,
340,13,127,4,0,
340,13,133,1,21,
340,13,156,1,26,
340,13,156,4,0,
340,13,164,3,0,
340,13,173,1,26,6
340,13,182,4,0,
340,13,189,1,1,2
340,13,196,3,0,
340,13,201,4,0,
340,13,207,3,0,
340,13,213,4,0,
340,13,216,4,0,
340,13,218,4,0,
340,13,222,1,16,
340,13,237,4,0,
340,13,240,4,0,
340,13,248,1,46,
340,13,249,4,0,
340,13,258,4,0,
340,13,263,4,0,
340,13,290,4,0,
340,13,291,4,0,
340,13,300,1,1,3
340,13,300,1,6,
340,13,317,4,0,
340,13,321,1,1,1
340,13,346,1,1,4
340,13,346,1,6,5
340,13,352,4,0,
340,14,55,1,10,
340,14,57,4,0,
340,14,58,4,0,
340,14,59,4,0,
340,14,63,4,0,
340,14,70,4,0,
340,14,89,1,45,
340,14,89,4,0,
340,14,90,1,57,
340,14,92,4,0,
340,14,104,4,0,
340,14,127,4,0,
340,14,133,1,18,
340,14,156,1,33,1
340,14,156,4,0,
340,14,157,4,0,
340,14,164,4,0,
340,14,173,1,33,2
340,14,173,3,0,
340,14,182,4,0,
340,14,189,1,1,3
340,14,196,3,0,
340,14,201,4,0,
340,14,207,4,0,
340,14,213,4,0,
340,14,214,3,0,
340,14,216,4,0,
340,14,218,4,0,
340,14,222,1,26,
340,14,237,4,0,
340,14,240,4,0,
340,14,248,1,51,
340,14,249,4,0,
340,14,258,4,0,
340,14,263,4,0,
340,14,291,4,0,
340,14,300,1,1,4
340,14,300,1,6,1
340,14,317,4,0,
340,14,321,1,1,2
340,14,340,3,0,
340,14,346,1,1,5
340,14,346,1,6,2
340,14,352,1,22,
340,14,401,1,39,
340,14,401,3,0,
340,14,414,3,0,
340,14,416,4,0,
340,14,426,1,14,
340,14,428,1,1,1
340,14,428,3,0,
340,14,444,4,0,
340,14,496,4,0,
340,14,503,4,0,
340,14,523,4,0,
340,15,55,1,10,
340,15,57,4,0,
340,15,58,4,0,
340,15,59,4,0,
340,15,63,4,0,
340,15,70,4,0,
340,15,89,1,45,
340,15,89,4,0,
340,15,90,1,57,
340,15,92,4,0,
340,15,104,4,0,
340,15,127,4,0,
340,15,133,1,18,
340,15,156,1,33,1
340,15,156,4,0,
340,15,157,4,0,
340,15,164,4,0,
340,15,173,1,33,2
340,15,182,4,0,
340,15,189,1,1,3
340,15,201,4,0,
340,15,207,4,0,
340,15,213,4,0,
340,15,214,4,0,
340,15,216,4,0,
340,15,218,4,0,
340,15,222,1,26,
340,15,237,4,0,
340,15,240,4,0,
340,15,248,1,51,
340,15,249,4,0,
340,15,258,4,0,
340,15,263,4,0,
340,15,300,1,1,4
340,15,300,1,6,1
340,15,317,4,0,
340,15,321,1,1,2
340,15,346,1,1,5
340,15,346,1,6,2
340,15,352,1,22,
340,15,401,1,39,
340,15,416,4,0,
340,15,426,1,14,
340,15,428,1,1,1
340,15,444,4,0,
340,15,496,4,0,
340,15,503,4,0,
340,15,523,4,0,
340,15,590,4,0,
340,16,55,1,1,5
340,16,55,1,9,1
340,16,57,4,0,
340,16,58,4,0,
340,16,59,4,0,
340,16,63,4,0,
340,16,70,4,0,
340,16,89,1,34,1
340,16,89,4,0,
340,16,90,1,52,1
340,16,92,4,0,
340,16,104,4,0,
340,16,127,4,0,
340,16,133,1,15,1
340,16,156,1,25,1
340,16,156,4,0,
340,16,157,4,0,
340,16,164,4,0,
340,16,173,1,25,2
340,16,173,3,0,
340,16,182,4,0,
340,16,189,1,1,2
340,16,196,3,0,
340,16,201,4,0,
340,16,207,4,0,
340,16,213,4,0,
340,16,214,4,0,
340,16,216,4,0,
340,16,218,4,0,
340,16,222,1,20,1
340,16,237,4,0,
340,16,240,4,0,
340,16,248,1,45,1
340,16,249,4,0,
340,16,258,4,0,
340,16,263,4,0,
340,16,290,4,0,
340,16,291,4,0,
340,16,300,1,1,3
340,16,300,1,6,1
340,16,317,4,0,
340,16,321,1,1,1
340,16,330,1,39,1
340,16,340,3,0,
340,16,346,1,1,4
340,16,346,1,6,2
340,16,352,1,17,1
340,16,352,3,0,
340,16,401,1,28,1
340,16,401,3,0,
340,16,414,3,0,
340,16,416,4,0,
340,16,426,1,13,1
340,16,428,1,30,1
340,16,428,3,0,
340,16,444,4,0,
340,16,496,4,0,
340,16,503,4,0,
340,16,523,4,0,
340,16,590,4,0,
340,17,37,1,0,
340,17,37,1,1,1
340,17,55,1,1,8
340,17,55,1,9,
340,17,57,4,0,
340,17,58,4,0,
340,17,59,4,0,
340,17,63,4,0,
340,17,89,1,34,
340,17,89,4,0,
340,17,90,1,52,
340,17,92,4,0,
340,17,104,4,0,
340,17,127,4,0,
340,17,133,1,15,
340,17,156,1,25,1
340,17,156,4,0,
340,17,157,4,0,
340,17,164,4,0,
340,17,173,1,25,2
340,17,182,4,0,
340,17,189,1,1,5
340,17,201,4,0,
340,17,207,4,0,
340,17,213,4,0,
340,17,214,4,0,
340,17,216,4,0,
340,17,218,4,0,
340,17,222,1,20,
340,17,237,4,0,
340,17,240,4,0,
340,17,248,1,45,
340,17,258,4,0,
340,17,263,4,0,
340,17,300,1,1,6
340,17,300,1,6,1
340,17,317,4,0,
340,17,321,1,1,4
340,17,330,1,39,
340,17,346,1,1,7
340,17,346,1,6,2
340,17,352,1,17,
340,17,401,1,28,
340,17,416,4,0,
340,17,426,1,13,
340,17,428,1,1,3
340,17,444,4,0,
340,17,496,4,0,
340,17,503,4,0,
340,17,523,4,0,
340,17,562,1,1,2
340,17,590,4,0,
340,18,37,1,0,
340,18,37,1,1,1
340,18,55,1,1,8
340,18,55,1,9,
340,18,57,4,0,
340,18,58,4,0,
340,18,59,4,0,
340,18,63,4,0,
340,18,89,1,34,
340,18,89,4,0,
340,18,90,1,52,
340,18,92,4,0,
340,18,104,4,0,
340,18,127,4,0,
340,18,133,1,15,
340,18,156,1,25,1
340,18,156,4,0,
340,18,157,4,0,
340,18,164,4,0,
340,18,173,1,25,2
340,18,182,4,0,
340,18,189,1,1,5
340,18,201,4,0,
340,18,207,4,0,
340,18,213,4,0,
340,18,214,4,0,
340,18,216,4,0,
340,18,218,4,0,
340,18,222,1,20,
340,18,237,4,0,
340,18,240,4,0,
340,18,248,1,45,
340,18,258,4,0,
340,18,263,4,0,
340,18,300,1,1,6
340,18,300,1,6,1
340,18,317,4,0,
340,18,321,1,1,4
340,18,330,1,39,
340,18,346,1,1,7
340,18,346,1,6,2
340,18,352,1,17,
340,18,401,1,28,
340,18,416,4,0,
340,18,426,1,13,
340,18,428,1,1,3
340,18,444,4,0,
340,18,496,4,0,
340,18,503,4,0,
340,18,523,4,0,
340,18,562,1,1,2
340,18,590,4,0,
341,5,11,1,10,
341,5,12,1,44,
341,5,14,1,38,
341,5,15,4,0,
341,5,34,2,0,
341,5,43,1,13,
341,5,57,4,0,
341,5,58,4,0,
341,5,59,4,0,
341,5,61,1,20,
341,5,70,4,0,
341,5,91,4,0,
341,5,92,4,0,
341,5,104,4,0,
341,5,106,1,7,
341,5,127,4,0,
341,5,145,1,1,
341,5,152,1,35,
341,5,156,4,0,
341,5,182,1,23,
341,5,182,4,0,
341,5,188,4,0,
341,5,213,4,0,
341,5,216,4,0,
341,5,218,4,0,
341,5,237,4,0,
341,5,240,4,0,
341,5,246,2,0,
341,5,249,4,0,
341,5,258,4,0,
341,5,263,4,0,
341,5,269,1,32,
341,5,269,4,0,
341,5,280,4,0,
341,5,282,1,26,
341,5,283,2,0,
341,5,290,4,0,
341,5,300,2,0,
341,5,317,4,0,
341,5,332,4,0,
341,5,352,4,0,
341,6,11,1,10,
341,6,12,1,44,
341,6,14,1,38,
341,6,14,3,0,
341,6,15,4,0,
341,6,34,2,0,
341,6,34,3,0,
341,6,38,3,0,
341,6,43,1,13,
341,6,57,4,0,
341,6,58,4,0,
341,6,59,4,0,
341,6,61,1,20,
341,6,68,3,0,
341,6,70,4,0,
341,6,91,4,0,
341,6,92,4,0,
341,6,102,3,0,
341,6,104,4,0,
341,6,106,1,7,
341,6,127,4,0,
341,6,145,1,1,
341,6,152,1,35,
341,6,156,4,0,
341,6,164,3,0,
341,6,173,3,0,
341,6,182,1,23,
341,6,182,4,0,
341,6,188,4,0,
341,6,189,3,0,
341,6,196,3,0,
341,6,203,3,0,
341,6,207,3,0,
341,6,210,3,0,
341,6,213,4,0,
341,6,214,3,0,
341,6,216,4,0,
341,6,218,4,0,
341,6,237,4,0,
341,6,240,4,0,
341,6,246,2,0,
341,6,249,4,0,
341,6,258,4,0,
341,6,263,4,0,
341,6,269,1,32,
341,6,269,4,0,
341,6,280,4,0,
341,6,282,1,26,
341,6,283,2,0,
341,6,290,4,0,
341,6,300,2,0,
341,6,317,4,0,
341,6,332,4,0,
341,6,352,4,0,
341,7,11,1,10,
341,7,12,1,46,
341,7,14,1,37,
341,7,14,3,0,
341,7,15,4,0,
341,7,34,2,0,
341,7,34,3,0,
341,7,38,3,0,
341,7,43,1,13,
341,7,57,4,0,
341,7,58,4,0,
341,7,59,4,0,
341,7,61,1,19,
341,7,68,3,0,
341,7,70,4,0,
341,7,91,4,0,
341,7,92,4,0,
341,7,102,3,0,
341,7,104,4,0,
341,7,106,1,7,
341,7,127,4,0,
341,7,145,1,1,
341,7,152,1,34,
341,7,156,4,0,
341,7,164,3,0,
341,7,182,1,22,
341,7,182,4,0,
341,7,188,4,0,
341,7,213,4,0,
341,7,216,4,0,
341,7,218,4,0,
341,7,237,4,0,
341,7,240,4,0,
341,7,242,1,43,
341,7,246,2,0,
341,7,249,4,0,
341,7,258,4,0,
341,7,263,4,0,
341,7,269,1,31,
341,7,269,4,0,
341,7,280,4,0,
341,7,282,1,25,
341,7,283,2,0,
341,7,290,4,0,
341,7,300,2,0,
341,7,317,4,0,
341,7,332,4,0,
341,7,352,4,0,
341,8,11,1,10,
341,8,12,1,53,
341,8,14,1,44,
341,8,14,4,0,
341,8,15,4,0,
341,8,34,2,0,
341,8,43,1,13,
341,8,57,4,0,
341,8,58,4,0,
341,8,59,4,0,
341,8,61,1,20,
341,8,70,4,0,
341,8,91,4,0,
341,8,92,4,0,
341,8,104,4,0,
341,8,106,1,7,
341,8,127,4,0,
341,8,145,1,1,
341,8,152,1,38,
341,8,156,4,0,
341,8,157,4,0,
341,8,164,4,0,
341,8,182,1,23,
341,8,182,4,0,
341,8,188,4,0,
341,8,203,4,0,
341,8,206,4,0,
341,8,207,4,0,
341,8,213,4,0,
341,8,214,4,0,
341,8,216,4,0,
341,8,218,4,0,
341,8,232,2,0,
341,8,237,4,0,
341,8,240,4,0,
341,8,242,1,47,
341,8,246,2,0,
341,8,249,4,0,
341,8,258,4,0,
341,8,263,4,0,
341,8,269,1,32,
341,8,269,4,0,
341,8,276,2,0,
341,8,280,4,0,
341,8,282,1,26,
341,8,282,2,0,
341,8,283,2,0,
341,8,290,4,0,
341,8,300,2,0,
341,8,317,4,0,
341,8,332,4,0,
341,8,352,4,0,
341,8,363,4,0,
341,8,371,4,0,
341,8,374,4,0,
341,8,400,1,35,
341,8,404,4,0,
341,8,445,4,0,
341,9,11,1,10,
341,9,12,1,53,
341,9,14,1,44,
341,9,14,4,0,
341,9,15,4,0,
341,9,34,2,0,
341,9,43,1,13,
341,9,57,4,0,
341,9,58,4,0,
341,9,59,4,0,
341,9,61,1,20,
341,9,70,4,0,
341,9,91,4,0,
341,9,92,4,0,
341,9,104,4,0,
341,9,106,1,7,
341,9,127,4,0,
341,9,145,1,1,
341,9,152,1,38,
341,9,156,4,0,
341,9,157,4,0,
341,9,164,4,0,
341,9,173,3,0,
341,9,180,3,0,
341,9,182,1,23,
341,9,182,4,0,
341,9,188,4,0,
341,9,189,3,0,
341,9,196,3,0,
341,9,203,4,0,
341,9,206,4,0,
341,9,207,4,0,
341,9,210,3,0,
341,9,213,4,0,
341,9,214,4,0,
341,9,216,4,0,
341,9,218,4,0,
341,9,232,2,0,
341,9,237,4,0,
341,9,240,4,0,
341,9,242,1,47,
341,9,246,2,0,
341,9,246,3,0,
341,9,249,4,0,
341,9,258,4,0,
341,9,263,4,0,
341,9,269,1,32,
341,9,269,4,0,
341,9,276,2,0,
341,9,276,3,0,
341,9,280,4,0,
341,9,282,1,26,
341,9,282,2,0,
341,9,282,3,0,
341,9,283,2,0,
341,9,283,3,0,
341,9,290,4,0,
341,9,300,2,0,
341,9,317,4,0,
341,9,332,4,0,
341,9,334,3,0,
341,9,352,4,0,
341,9,363,4,0,
341,9,371,4,0,
341,9,374,4,0,
341,9,400,1,35,
341,9,404,4,0,
341,9,445,4,0,
341,10,11,1,10,
341,10,12,1,53,
341,10,14,1,44,
341,10,14,4,0,
341,10,15,4,0,
341,10,34,2,0,
341,10,43,1,13,
341,10,57,4,0,
341,10,58,4,0,
341,10,59,4,0,
341,10,61,1,20,
341,10,70,4,0,
341,10,91,4,0,
341,10,92,4,0,
341,10,104,4,0,
341,10,106,1,7,
341,10,127,4,0,
341,10,145,1,1,
341,10,152,1,38,
341,10,156,4,0,
341,10,157,4,0,
341,10,164,4,0,
341,10,173,3,0,
341,10,180,3,0,
341,10,182,1,23,
341,10,182,4,0,
341,10,188,4,0,
341,10,189,3,0,
341,10,196,3,0,
341,10,203,4,0,
341,10,206,4,0,
341,10,207,4,0,
341,10,210,3,0,
341,10,213,4,0,
341,10,214,4,0,
341,10,216,4,0,
341,10,218,4,0,
341,10,232,2,0,
341,10,237,4,0,
341,10,240,4,0,
341,10,242,1,47,
341,10,246,2,0,
341,10,246,3,0,
341,10,249,4,0,
341,10,250,4,0,
341,10,258,4,0,
341,10,263,4,0,
341,10,269,1,32,
341,10,269,4,0,
341,10,276,2,0,
341,10,276,3,0,
341,10,280,4,0,
341,10,282,1,26,
341,10,282,2,0,
341,10,282,3,0,
341,10,283,2,0,
341,10,283,3,0,
341,10,290,4,0,
341,10,300,2,0,
341,10,317,4,0,
341,10,332,4,0,
341,10,334,3,0,
341,10,349,2,0,
341,10,352,4,0,
341,10,363,4,0,
341,10,371,4,0,
341,10,374,4,0,
341,10,400,1,35,
341,10,404,4,0,
341,10,445,4,0,
341,11,11,1,10,
341,11,12,1,53,
341,11,14,1,44,
341,11,14,4,0,
341,11,15,4,0,
341,11,34,2,0,
341,11,38,2,0,
341,11,43,1,13,
341,11,57,4,0,
341,11,58,4,0,
341,11,59,4,0,
341,11,61,1,20,
341,11,70,4,0,
341,11,91,4,0,
341,11,92,4,0,
341,11,104,4,0,
341,11,106,1,7,
341,11,127,4,0,
341,11,145,1,1,
341,11,152,1,38,
341,11,156,4,0,
341,11,157,4,0,
341,11,164,4,0,
341,11,182,1,23,
341,11,182,4,0,
341,11,188,4,0,
341,11,206,4,0,
341,11,207,4,0,
341,11,213,4,0,
341,11,216,4,0,
341,11,218,4,0,
341,11,232,2,0,
341,11,237,4,0,
341,11,240,4,0,
341,11,242,1,47,
341,11,246,2,0,
341,11,249,4,0,
341,11,258,4,0,
341,11,263,4,0,
341,11,269,1,32,
341,11,269,4,0,
341,11,276,2,0,
341,11,280,4,0,
341,11,282,1,26,
341,11,282,2,0,
341,11,283,2,0,
341,11,300,2,0,
341,11,317,4,0,
341,11,332,4,0,
341,11,349,2,0,
341,11,371,4,0,
341,11,374,4,0,
341,11,376,2,0,
341,11,400,1,35,
341,11,404,4,0,
341,11,468,4,0,
341,11,496,4,0,
341,11,498,2,0,
341,11,503,4,0,
341,12,11,1,10,
341,12,12,1,44,
341,12,14,1,38,
341,12,15,4,0,
341,12,43,1,13,
341,12,57,4,0,
341,12,58,4,0,
341,12,59,4,0,
341,12,61,1,20,
341,12,70,4,0,
341,12,91,4,0,
341,12,92,4,0,
341,12,104,4,0,
341,12,106,1,7,
341,12,127,4,0,
341,12,145,1,1,
341,12,152,1,35,
341,12,156,4,0,
341,12,182,1,23,
341,12,182,4,0,
341,12,188,4,0,
341,12,213,4,0,
341,12,216,4,0,
341,12,218,4,0,
341,12,237,4,0,
341,12,240,4,0,
341,12,249,4,0,
341,12,258,4,0,
341,12,263,4,0,
341,12,269,1,32,
341,12,269,4,0,
341,12,280,4,0,
341,12,282,1,26,
341,12,290,4,0,
341,12,317,4,0,
341,12,332,4,0,
341,12,352,4,0,
341,13,11,1,10,
341,13,12,1,44,
341,13,14,1,38,
341,13,15,4,0,
341,13,34,3,0,
341,13,38,3,0,
341,13,43,1,13,
341,13,57,4,0,
341,13,58,4,0,
341,13,59,4,0,
341,13,61,1,20,
341,13,70,4,0,
341,13,91,4,0,
341,13,92,4,0,
341,13,102,3,0,
341,13,104,4,0,
341,13,106,1,7,
341,13,127,4,0,
341,13,145,1,1,
341,13,152,1,35,
341,13,156,4,0,
341,13,164,3,0,
341,13,182,1,23,
341,13,182,4,0,
341,13,188,4,0,
341,13,196,3,0,
341,13,207,3,0,
341,13,213,4,0,
341,13,216,4,0,
341,13,218,4,0,
341,13,237,4,0,
341,13,240,4,0,
341,13,249,4,0,
341,13,258,4,0,
341,13,263,4,0,
341,13,269,1,32,
341,13,269,4,0,
341,13,280,4,0,
341,13,282,1,26,
341,13,290,4,0,
341,13,317,4,0,
341,13,332,4,0,
341,13,352,4,0,
341,14,11,1,10,
341,14,12,1,53,
341,14,14,1,44,
341,14,14,4,0,
341,14,15,4,0,
341,14,34,2,0,
341,14,38,2,0,
341,14,43,1,13,
341,14,57,4,0,
341,14,58,4,0,
341,14,59,4,0,
341,14,61,1,20,
341,14,70,4,0,
341,14,91,4,0,
341,14,92,4,0,
341,14,104,4,0,
341,14,106,1,7,
341,14,127,4,0,
341,14,145,1,1,
341,14,152,1,38,
341,14,156,4,0,
341,14,157,4,0,
341,14,164,4,0,
341,14,173,3,0,
341,14,180,3,0,
341,14,182,1,23,
341,14,182,4,0,
341,14,188,4,0,
341,14,196,3,0,
341,14,206,4,0,
341,14,207,4,0,
341,14,213,4,0,
341,14,214,3,0,
341,14,216,4,0,
341,14,218,4,0,
341,14,232,2,0,
341,14,237,4,0,
341,14,240,4,0,
341,14,242,1,47,
341,14,246,2,0,
341,14,249,4,0,
341,14,258,4,0,
341,14,263,4,0,
341,14,269,1,32,
341,14,269,4,0,
341,14,276,2,0,
341,14,276,3,0,
341,14,280,4,0,
341,14,282,1,26,
341,14,282,2,0,
341,14,282,3,0,
341,14,283,2,0,
341,14,283,3,0,
341,14,300,2,0,
341,14,317,4,0,
341,14,332,4,0,
341,14,334,3,0,
341,14,349,2,0,
341,14,371,4,0,
341,14,374,4,0,
341,14,376,2,0,
341,14,400,1,35,
341,14,404,4,0,
341,14,468,4,0,
341,14,496,4,0,
341,14,498,2,0,
341,14,503,4,0,
341,15,11,1,10,
341,15,12,1,53,
341,15,14,1,44,
341,15,14,4,0,
341,15,15,4,0,
341,15,34,2,0,
341,15,38,2,0,
341,15,43,1,13,
341,15,57,4,0,
341,15,58,4,0,
341,15,59,4,0,
341,15,61,1,20,
341,15,70,4,0,
341,15,91,4,0,
341,15,92,4,0,
341,15,104,4,0,
341,15,106,1,7,
341,15,127,4,0,
341,15,145,1,1,
341,15,152,1,38,
341,15,156,4,0,
341,15,157,4,0,
341,15,164,4,0,
341,15,182,1,23,
341,15,182,4,0,
341,15,188,4,0,
341,15,206,4,0,
341,15,207,4,0,
341,15,213,4,0,
341,15,214,4,0,
341,15,216,4,0,
341,15,218,4,0,
341,15,232,2,0,
341,15,237,4,0,
341,15,240,4,0,
341,15,242,1,47,
341,15,246,2,0,
341,15,249,4,0,
341,15,258,4,0,
341,15,263,4,0,
341,15,269,1,32,
341,15,269,4,0,
341,15,276,2,0,
341,15,280,4,0,
341,15,282,1,26,
341,15,282,2,0,
341,15,283,2,0,
341,15,300,2,0,
341,15,317,4,0,
341,15,332,4,0,
341,15,349,2,0,
341,15,371,4,0,
341,15,374,4,0,
341,15,376,2,0,
341,15,400,1,35,
341,15,404,4,0,
341,15,415,2,0,
341,15,453,2,0,
341,15,468,4,0,
341,15,496,4,0,
341,15,498,2,0,
341,15,503,4,0,
341,15,590,4,0,
341,16,11,1,7,1
341,16,12,1,48,1
341,16,14,1,37,1
341,16,14,4,0,
341,16,15,4,0,
341,16,34,2,0,
341,16,38,2,0,
341,16,43,1,10,1
341,16,57,4,0,
341,16,58,4,0,
341,16,59,4,0,
341,16,61,1,14,1
341,16,70,4,0,
341,16,91,4,0,
341,16,92,4,0,
341,16,104,4,0,
341,16,106,1,5,1
341,16,127,4,0,
341,16,145,1,1,1
341,16,152,1,43,1
341,16,156,4,0,
341,16,157,4,0,
341,16,164,4,0,
341,16,173,3,0,
341,16,180,3,0,
341,16,182,1,17,1
341,16,182,4,0,
341,16,188,4,0,
341,16,196,3,0,
341,16,206,4,0,
341,16,207,4,0,
341,16,213,4,0,
341,16,214,4,0,
341,16,216,4,0,
341,16,218,4,0,
341,16,232,2,0,
341,16,237,4,0,
341,16,240,4,0,
341,16,242,1,39,1
341,16,246,2,0,
341,16,249,4,0,
341,16,258,4,0,
341,16,263,4,0,
341,16,269,1,34,1
341,16,269,4,0,
341,16,276,2,0,
341,16,276,3,0,
341,16,280,4,0,
341,16,282,1,23,1
341,16,282,2,0,
341,16,282,3,0,
341,16,283,2,0,
341,16,283,3,0,
341,16,290,4,0,
341,16,300,2,0,
341,16,317,4,0,
341,16,332,4,0,
341,16,334,3,0,
341,16,349,2,0,
341,16,352,3,0,
341,16,371,4,0,
341,16,374,4,0,
341,16,376,2,0,
341,16,400,1,26,1
341,16,404,4,0,
341,16,415,2,0,
341,16,453,2,0,
341,16,458,1,20,1
341,16,468,4,0,
341,16,496,4,0,
341,16,498,2,0,
341,16,503,4,0,
341,16,534,1,31,1
341,16,590,4,0,
341,17,11,1,7,
341,17,12,1,48,
341,17,14,1,37,
341,17,14,4,0,
341,17,34,2,0,
341,17,38,2,0,
341,17,43,1,10,
341,17,57,4,0,
341,17,58,4,0,
341,17,59,4,0,
341,17,61,1,14,
341,17,92,4,0,
341,17,104,4,0,
341,17,106,1,5,
341,17,127,4,0,
341,17,145,1,1,
341,17,152,1,43,
341,17,156,4,0,
341,17,157,4,0,
341,17,164,4,0,
341,17,182,1,17,
341,17,182,4,0,
341,17,188,4,0,
341,17,206,4,0,
341,17,207,4,0,
341,17,213,4,0,
341,17,214,4,0,
341,17,216,4,0,
341,17,218,4,0,
341,17,232,2,0,
341,17,237,4,0,
341,17,240,4,0,
341,17,242,1,39,
341,17,246,2,0,
341,17,258,4,0,
341,17,263,4,0,
341,17,269,1,34,
341,17,269,4,0,
341,17,276,2,0,
341,17,280,4,0,
341,17,282,1,23,
341,17,282,2,0,
341,17,283,2,0,
341,17,300,2,0,
341,17,317,4,0,
341,17,332,4,0,
341,17,349,2,0,
341,17,371,4,0,
341,17,374,4,0,
341,17,376,2,0,
341,17,400,1,26,
341,17,404,4,0,
341,17,415,2,0,
341,17,453,2,0,
341,17,458,1,20,
341,17,496,4,0,
341,17,498,2,0,
341,17,503,4,0,
341,17,534,1,31,
341,17,590,4,0,
341,18,11,1,7,
341,18,12,1,48,
341,18,14,1,37,
341,18,14,4,0,
341,18,34,2,0,
341,18,38,2,0,
341,18,43,1,10,
341,18,57,4,0,
341,18,58,4,0,
341,18,59,4,0,
341,18,61,1,14,
341,18,92,4,0,
341,18,104,4,0,
341,18,106,1,5,
341,18,127,4,0,
341,18,145,1,1,
341,18,152,1,43,
341,18,156,4,0,
341,18,157,4,0,
341,18,164,4,0,
341,18,182,1,17,
341,18,182,4,0,
341,18,188,4,0,
341,18,206,4,0,
341,18,207,4,0,
341,18,213,4,0,
341,18,214,4,0,
341,18,216,4,0,
341,18,218,4,0,
341,18,232,2,0,
341,18,237,4,0,
341,18,240,4,0,
341,18,242,1,39,
341,18,246,2,0,
341,18,258,4,0,
341,18,263,4,0,
341,18,269,1,34,
341,18,269,4,0,
341,18,276,2,0,
341,18,280,4,0,
341,18,282,1,23,
341,18,282,2,0,
341,18,283,2,0,
341,18,300,2,0,
341,18,317,4,0,
341,18,332,4,0,
341,18,349,2,0,
341,18,371,4,0,
341,18,374,4,0,
341,18,376,2,0,
341,18,400,1,26,
341,18,404,4,0,
341,18,415,2,0,
341,18,453,2,0,
341,18,458,1,20,
341,18,496,4,0,
341,18,498,2,0,
341,18,503,4,0,
341,18,534,1,31,
341,18,590,4,0,
342,5,11,1,1,3
342,5,11,1,10,
342,5,12,1,52,
342,5,14,1,44,
342,5,15,4,0,
342,5,43,1,1,4
342,5,43,1,13,
342,5,57,4,0,
342,5,58,4,0,
342,5,59,4,0,
342,5,61,1,20,
342,5,63,4,0,
342,5,70,4,0,
342,5,91,4,0,
342,5,92,4,0,
342,5,104,4,0,
342,5,106,1,1,2
342,5,106,1,7,
342,5,127,4,0,
342,5,145,1,1,1
342,5,152,1,39,
342,5,156,4,0,
342,5,182,1,23,
342,5,182,4,0,
342,5,188,4,0,
342,5,213,4,0,
342,5,216,4,0,
342,5,218,4,0,
342,5,237,4,0,
342,5,240,4,0,
342,5,249,4,0,
342,5,258,4,0,
342,5,263,4,0,
342,5,269,1,34,
342,5,269,4,0,
342,5,280,4,0,
342,5,282,1,26,
342,5,290,4,0,
342,5,291,4,0,
342,5,317,4,0,
342,5,332,4,0,
342,5,352,4,0,
342,6,11,1,1,3
342,6,11,1,10,
342,6,12,1,52,
342,6,14,1,44,
342,6,14,3,0,
342,6,15,4,0,
342,6,34,3,0,
342,6,38,3,0,
342,6,43,1,1,4
342,6,43,1,13,
342,6,57,4,0,
342,6,58,4,0,
342,6,59,4,0,
342,6,61,1,20,
342,6,63,4,0,
342,6,68,3,0,
342,6,70,4,0,
342,6,91,4,0,
342,6,92,4,0,
342,6,102,3,0,
342,6,104,4,0,
342,6,106,1,1,2
342,6,106,1,7,
342,6,127,4,0,
342,6,129,3,0,
342,6,145,1,1,1
342,6,152,1,39,
342,6,156,4,0,
342,6,164,3,0,
342,6,173,3,0,
342,6,182,1,23,
342,6,182,4,0,
342,6,188,4,0,
342,6,189,3,0,
342,6,196,3,0,
342,6,203,3,0,
342,6,207,3,0,
342,6,210,3,0,
342,6,213,4,0,
342,6,214,3,0,
342,6,216,4,0,
342,6,218,4,0,
342,6,237,4,0,
342,6,240,4,0,
342,6,249,4,0,
342,6,258,4,0,
342,6,263,4,0,
342,6,269,1,34,
342,6,269,4,0,
342,6,280,4,0,
342,6,282,1,26,
342,6,290,4,0,
342,6,291,4,0,
342,6,317,4,0,
342,6,332,4,0,
342,6,352,4,0,
342,7,11,1,1,3
342,7,11,1,10,
342,7,12,1,56,
342,7,14,1,43,
342,7,14,3,0,
342,7,15,4,0,
342,7,34,3,0,
342,7,38,3,0,
342,7,43,1,1,4
342,7,43,1,13,
342,7,57,4,0,
342,7,58,4,0,
342,7,59,4,0,
342,7,61,1,19,
342,7,63,4,0,
342,7,68,3,0,
342,7,70,4,0,
342,7,91,4,0,
342,7,92,4,0,
342,7,102,3,0,
342,7,104,4,0,
342,7,106,1,1,2
342,7,106,1,7,
342,7,127,4,0,
342,7,145,1,1,1
342,7,152,1,38,
342,7,156,4,0,
342,7,164,3,0,
342,7,182,1,22,
342,7,182,4,0,
342,7,188,4,0,
342,7,213,4,0,
342,7,216,4,0,
342,7,218,4,0,
342,7,237,4,0,
342,7,240,4,0,
342,7,242,1,51,
342,7,249,4,0,
342,7,258,4,0,
342,7,263,4,0,
342,7,269,1,33,
342,7,269,4,0,
342,7,280,4,0,
342,7,282,1,25,
342,7,290,4,0,
342,7,291,4,0,
342,7,317,4,0,
342,7,332,4,0,
342,7,352,4,0,
342,8,11,1,1,3
342,8,11,1,10,
342,8,12,1,65,
342,8,14,1,52,
342,8,14,4,0,
342,8,15,4,0,
342,8,43,1,1,4
342,8,43,1,13,
342,8,57,4,0,
342,8,58,4,0,
342,8,59,4,0,
342,8,61,1,20,
342,8,63,4,0,
342,8,70,4,0,
342,8,91,4,0,
342,8,92,4,0,
342,8,104,4,0,
342,8,106,1,1,2
342,8,106,1,7,
342,8,127,4,0,
342,8,129,1,30,
342,8,145,1,1,1
342,8,152,1,44,
342,8,156,4,0,
342,8,157,4,0,
342,8,164,4,0,
342,8,182,1,23,
342,8,182,4,0,
342,8,188,4,0,
342,8,203,4,0,
342,8,206,4,0,
342,8,207,4,0,
342,8,213,4,0,
342,8,214,4,0,
342,8,216,4,0,
342,8,218,4,0,
342,8,237,4,0,
342,8,240,4,0,
342,8,242,1,57,
342,8,249,4,0,
342,8,258,4,0,
342,8,263,4,0,
342,8,269,1,34,
342,8,269,4,0,
342,8,280,4,0,
342,8,282,1,26,
342,8,290,4,0,
342,8,317,4,0,
342,8,332,4,0,
342,8,352,4,0,
342,8,363,4,0,
342,8,371,4,0,
342,8,374,4,0,
342,8,399,4,0,
342,8,400,1,39,
342,8,404,4,0,
342,8,416,4,0,
342,8,419,4,0,
342,8,445,4,0,
342,9,11,1,1,3
342,9,11,1,10,
342,9,12,1,65,
342,9,14,1,52,
342,9,14,4,0,
342,9,15,4,0,
342,9,43,1,1,4
342,9,43,1,13,
342,9,57,4,0,
342,9,58,4,0,
342,9,59,4,0,
342,9,61,1,20,
342,9,63,4,0,
342,9,70,4,0,
342,9,91,4,0,
342,9,92,4,0,
342,9,104,4,0,
342,9,106,1,1,2
342,9,106,1,7,
342,9,127,4,0,
342,9,129,1,30,
342,9,129,3,0,
342,9,145,1,1,1
342,9,152,1,44,
342,9,156,4,0,
342,9,157,4,0,
342,9,164,4,0,
342,9,173,3,0,
342,9,180,3,0,
342,9,182,1,23,
342,9,182,4,0,
342,9,188,4,0,
342,9,189,3,0,
342,9,196,3,0,
342,9,203,4,0,
342,9,206,4,0,
342,9,207,4,0,
342,9,210,3,0,
342,9,213,4,0,
342,9,214,4,0,
342,9,216,4,0,
342,9,218,4,0,
342,9,237,4,0,
342,9,240,4,0,
342,9,242,1,57,
342,9,246,3,0,
342,9,249,4,0,
342,9,258,4,0,
342,9,263,4,0,
342,9,269,1,34,
342,9,269,4,0,
342,9,276,3,0,
342,9,280,4,0,
342,9,282,1,26,
342,9,282,3,0,
342,9,283,3,0,
342,9,290,4,0,
342,9,291,3,0,
342,9,317,4,0,
342,9,332,4,0,
342,9,334,3,0,
342,9,352,4,0,
342,9,363,4,0,
342,9,371,4,0,
342,9,374,4,0,
342,9,399,4,0,
342,9,400,1,39,
342,9,404,4,0,
342,9,416,4,0,
342,9,419,4,0,
342,9,445,4,0,
342,10,11,1,1,3
342,10,11,1,10,
342,10,12,1,65,
342,10,14,1,52,
342,10,14,4,0,
342,10,15,4,0,
342,10,43,1,1,4
342,10,43,1,13,
342,10,57,4,0,
342,10,58,4,0,
342,10,59,4,0,
342,10,61,1,20,
342,10,63,4,0,
342,10,70,4,0,
342,10,91,4,0,
342,10,92,4,0,
342,10,104,4,0,
342,10,106,1,1,2
342,10,106,1,7,
342,10,127,4,0,
342,10,129,1,30,
342,10,129,3,0,
342,10,145,1,1,1
342,10,152,1,44,
342,10,156,4,0,
342,10,157,4,0,
342,10,164,4,0,
342,10,173,3,0,
342,10,180,3,0,
342,10,182,1,23,
342,10,182,4,0,
342,10,188,4,0,
342,10,189,3,0,
342,10,196,3,0,
342,10,203,4,0,
342,10,206,4,0,
342,10,207,4,0,
342,10,210,3,0,
342,10,213,4,0,
342,10,214,4,0,
342,10,216,4,0,
342,10,218,4,0,
342,10,237,4,0,
342,10,240,4,0,
342,10,242,1,57,
342,10,246,3,0,
342,10,249,4,0,
342,10,250,4,0,
342,10,258,4,0,
342,10,263,4,0,
342,10,269,1,34,
342,10,269,4,0,
342,10,276,3,0,
342,10,280,4,0,
342,10,282,1,26,
342,10,282,3,0,
342,10,283,3,0,
342,10,290,4,0,
342,10,291,3,0,
342,10,317,4,0,
342,10,332,4,0,
342,10,334,3,0,
342,10,352,4,0,
342,10,363,4,0,
342,10,371,4,0,
342,10,374,4,0,
342,10,399,4,0,
342,10,400,1,39,
342,10,404,4,0,
342,10,416,4,0,
342,10,419,4,0,
342,10,445,4,0,
342,11,11,1,1,3
342,11,11,1,10,
342,11,12,1,65,
342,11,14,1,52,
342,11,14,4,0,
342,11,15,4,0,
342,11,43,1,1,4
342,11,43,1,13,
342,11,57,4,0,
342,11,58,4,0,
342,11,59,4,0,
342,11,61,1,20,
342,11,63,4,0,
342,11,70,4,0,
342,11,91,4,0,
342,11,92,4,0,
342,11,104,4,0,
342,11,106,1,1,2
342,11,106,1,7,
342,11,127,4,0,
342,11,129,1,30,
342,11,145,1,1,1
342,11,152,1,44,
342,11,156,4,0,
342,11,157,4,0,
342,11,164,4,0,
342,11,182,1,23,
342,11,182,4,0,
342,11,188,4,0,
342,11,206,4,0,
342,11,207,4,0,
342,11,213,4,0,
342,11,216,4,0,
342,11,218,4,0,
342,11,237,4,0,
342,11,240,4,0,
342,11,242,1,57,
342,11,249,4,0,
342,11,258,4,0,
342,11,263,4,0,
342,11,269,1,34,
342,11,269,4,0,
342,11,280,4,0,
342,11,282,1,26,
342,11,291,4,0,
342,11,317,4,0,
342,11,332,4,0,
342,11,371,4,0,
342,11,374,4,0,
342,11,400,1,39,
342,11,404,4,0,
342,11,416,4,0,
342,11,468,4,0,
342,11,482,4,0,
342,11,496,4,0,
342,11,503,4,0,
342,11,514,4,0,
342,11,555,4,0,
342,12,11,1,1,3
342,12,11,1,10,
342,12,12,1,52,
342,12,14,1,44,
342,12,15,4,0,
342,12,43,1,1,4
342,12,43,1,13,
342,12,57,4,0,
342,12,58,4,0,
342,12,59,4,0,
342,12,61,1,20,
342,12,63,4,0,
342,12,70,4,0,
342,12,91,4,0,
342,12,92,4,0,
342,12,104,4,0,
342,12,106,1,1,2
342,12,106,1,7,
342,12,127,4,0,
342,12,145,1,1,1
342,12,152,1,39,
342,12,156,4,0,
342,12,182,1,23,
342,12,182,4,0,
342,12,188,4,0,
342,12,213,4,0,
342,12,216,4,0,
342,12,218,4,0,
342,12,237,4,0,
342,12,240,4,0,
342,12,249,4,0,
342,12,258,4,0,
342,12,263,4,0,
342,12,269,1,34,
342,12,269,4,0,
342,12,280,4,0,
342,12,282,1,26,
342,12,290,4,0,
342,12,291,4,0,
342,12,317,4,0,
342,12,332,4,0,
342,12,352,4,0,
342,13,11,1,1,3
342,13,11,1,10,
342,13,12,1,52,
342,13,14,1,44,
342,13,15,4,0,
342,13,34,3,0,
342,13,38,3,0,
342,13,43,1,1,4
342,13,43,1,13,
342,13,57,4,0,
342,13,58,4,0,
342,13,59,4,0,
342,13,61,1,20,
342,13,63,4,0,
342,13,70,4,0,
342,13,91,4,0,
342,13,92,4,0,
342,13,102,3,0,
342,13,104,4,0,
342,13,106,1,1,2
342,13,106,1,7,
342,13,127,4,0,
342,13,145,1,1,1
342,13,152,1,39,
342,13,156,4,0,
342,13,164,3,0,
342,13,182,1,23,
342,13,182,4,0,
342,13,188,4,0,
342,13,196,3,0,
342,13,207,3,0,
342,13,213,4,0,
342,13,216,4,0,
342,13,218,4,0,
342,13,237,4,0,
342,13,240,4,0,
342,13,249,4,0,
342,13,258,4,0,
342,13,263,4,0,
342,13,269,1,34,
342,13,269,4,0,
342,13,280,4,0,
342,13,282,1,26,
342,13,290,4,0,
342,13,291,4,0,
342,13,317,4,0,
342,13,332,4,0,
342,13,352,4,0,
342,14,11,1,1,3
342,14,11,1,10,
342,14,12,1,65,
342,14,14,1,52,
342,14,14,4,0,
342,14,15,4,0,
342,14,43,1,1,4
342,14,43,1,13,
342,14,57,4,0,
342,14,58,4,0,
342,14,59,4,0,
342,14,61,1,20,
342,14,63,4,0,
342,14,70,4,0,
342,14,91,4,0,
342,14,92,4,0,
342,14,104,4,0,
342,14,106,1,1,2
342,14,106,1,7,
342,14,127,4,0,
342,14,129,1,30,
342,14,145,1,1,1
342,14,152,1,44,
342,14,156,4,0,
342,14,157,4,0,
342,14,164,4,0,
342,14,173,3,0,
342,14,180,3,0,
342,14,182,1,23,
342,14,182,4,0,
342,14,188,4,0,
342,14,196,3,0,
342,14,206,4,0,
342,14,207,4,0,
342,14,213,4,0,
342,14,214,3,0,
342,14,216,4,0,
342,14,218,4,0,
342,14,237,4,0,
342,14,240,4,0,
342,14,242,1,57,
342,14,249,4,0,
342,14,258,4,0,
342,14,263,4,0,
342,14,269,1,34,
342,14,269,4,0,
342,14,276,3,0,
342,14,280,4,0,
342,14,282,1,26,
342,14,282,3,0,
342,14,283,3,0,
342,14,291,4,0,
342,14,317,4,0,
342,14,332,4,0,
342,14,334,3,0,
342,14,371,4,0,
342,14,374,4,0,
342,14,399,3,0,
342,14,400,1,39,
342,14,404,4,0,
342,14,416,4,0,
342,14,468,4,0,
342,14,482,4,0,
342,14,496,4,0,
342,14,503,4,0,
342,14,514,4,0,
342,14,555,4,0,
342,15,11,1,1,4
342,15,11,1,10,
342,15,12,1,1,1
342,15,12,1,65,
342,15,14,1,52,
342,15,14,4,0,
342,15,15,4,0,
342,15,43,1,1,5
342,15,43,1,13,
342,15,57,4,0,
342,15,58,4,0,
342,15,59,4,0,
342,15,61,1,20,
342,15,63,4,0,
342,15,70,4,0,
342,15,91,4,0,
342,15,92,4,0,
342,15,104,4,0,
342,15,106,1,1,3
342,15,106,1,7,
342,15,127,4,0,
342,15,129,1,30,
342,15,145,1,1,2
342,15,152,1,44,
342,15,156,4,0,
342,15,157,4,0,
342,15,164,4,0,
342,15,182,1,23,
342,15,182,4,0,
342,15,188,4,0,
342,15,206,4,0,
342,15,207,4,0,
342,15,213,4,0,
342,15,214,4,0,
342,15,216,4,0,
342,15,218,4,0,
342,15,237,4,0,
342,15,240,4,0,
342,15,242,1,57,
342,15,249,4,0,
342,15,258,4,0,
342,15,263,4,0,
342,15,267,4,0,
342,15,269,1,34,
342,15,269,4,0,
342,15,280,4,0,
342,15,282,1,26,
342,15,317,4,0,
342,15,332,4,0,
342,15,371,4,0,
342,15,374,4,0,
342,15,399,4,0,
342,15,400,1,39,
342,15,404,4,0,
342,15,416,4,0,
342,15,468,4,0,
342,15,482,4,0,
342,15,496,4,0,
342,15,503,4,0,
342,15,514,4,0,
342,15,555,4,0,
342,15,590,4,0,
342,16,11,1,1,3
342,16,11,1,7,1
342,16,12,1,54,1
342,16,14,1,40,1
342,16,14,4,0,
342,16,15,4,0,
342,16,43,1,1,4
342,16,43,1,10,1
342,16,57,4,0,
342,16,58,4,0,
342,16,59,4,0,
342,16,61,1,14,1
342,16,63,4,0,
342,16,70,4,0,
342,16,91,4,0,
342,16,92,4,0,
342,16,104,4,0,
342,16,106,1,1,2
342,16,106,1,5,1
342,16,127,4,0,
342,16,129,1,30,1
342,16,145,1,1,1
342,16,152,1,48,1
342,16,156,4,0,
342,16,157,4,0,
342,16,164,4,0,
342,16,173,3,0,
342,16,180,3,0,
342,16,182,1,17,1
342,16,182,4,0,
342,16,188,4,0,
342,16,196,3,0,
342,16,206,4,0,
342,16,207,4,0,
342,16,213,4,0,
342,16,214,4,0,
342,16,216,4,0,
342,16,218,4,0,
342,16,237,4,0,
342,16,240,4,0,
342,16,242,1,43,1
342,16,249,4,0,
342,16,258,4,0,
342,16,263,4,0,
342,16,267,4,0,
342,16,269,1,36,1
342,16,269,4,0,
342,16,276,3,0,
342,16,280,4,0,
342,16,282,1,23,1
342,16,282,3,0,
342,16,283,3,0,
342,16,290,4,0,
342,16,291,4,0,
342,16,317,4,0,
342,16,332,4,0,
342,16,334,3,0,
342,16,352,3,0,
342,16,371,4,0,
342,16,374,4,0,
342,16,399,4,0,
342,16,400,1,26,1
342,16,404,4,0,
342,16,416,4,0,
342,16,458,1,20,1
342,16,468,4,0,
342,16,482,4,0,
342,16,496,4,0,
342,16,503,4,0,
342,16,514,4,0,
342,16,534,1,32,1
342,16,555,4,0,
342,16,590,4,0,
342,17,11,1,1,4
342,17,11,1,7,
342,17,12,1,54,
342,17,14,1,40,
342,17,14,4,0,
342,17,43,1,1,5
342,17,43,1,10,
342,17,57,4,0,
342,17,58,4,0,
342,17,59,4,0,
342,17,61,1,14,
342,17,63,4,0,
342,17,92,4,0,
342,17,104,4,0,
342,17,106,1,1,3
342,17,106,1,5,
342,17,127,4,0,
342,17,129,1,0,
342,17,129,1,1,1
342,17,145,1,1,2
342,17,152,1,48,
342,17,156,4,0,
342,17,157,4,0,
342,17,164,4,0,
342,17,182,1,17,
342,17,182,4,0,
342,17,188,4,0,
342,17,206,4,0,
342,17,207,4,0,
342,17,213,4,0,
342,17,214,4,0,
342,17,216,4,0,
342,17,218,4,0,
342,17,237,4,0,
342,17,240,4,0,
342,17,242,1,43,
342,17,258,4,0,
342,17,263,4,0,
342,17,267,4,0,
342,17,269,1,36,
342,17,269,4,0,
342,17,280,4,0,
342,17,282,1,23,
342,17,317,4,0,
342,17,332,4,0,
342,17,371,4,0,
342,17,374,4,0,
342,17,399,4,0,
342,17,400,1,26,
342,17,404,4,0,
342,17,416,4,0,
342,17,458,1,20,
342,17,482,4,0,
342,17,496,4,0,
342,17,503,4,0,
342,17,534,1,32,
342,17,555,4,0,
342,17,590,4,0,
342,18,11,1,1,4
342,18,11,1,7,
342,18,12,1,54,
342,18,14,1,40,
342,18,14,4,0,
342,18,43,1,1,5
342,18,43,1,10,
342,18,57,4,0,
342,18,58,4,0,
342,18,59,4,0,
342,18,61,1,14,
342,18,63,4,0,
342,18,92,4,0,
342,18,104,4,0,
342,18,106,1,1,3
342,18,106,1,5,
342,18,127,4,0,
342,18,129,1,0,
342,18,129,1,1,1
342,18,145,1,1,2
342,18,152,1,48,
342,18,156,4,0,
342,18,157,4,0,
342,18,164,4,0,
342,18,182,1,17,
342,18,182,4,0,
342,18,188,4,0,
342,18,206,4,0,
342,18,207,4,0,
342,18,213,4,0,
342,18,214,4,0,
342,18,216,4,0,
342,18,218,4,0,
342,18,237,4,0,
342,18,240,4,0,
342,18,242,1,43,
342,18,258,4,0,
342,18,263,4,0,
342,18,267,4,0,
342,18,269,1,36,
342,18,269,4,0,
342,18,280,4,0,
342,18,282,1,23,
342,18,317,4,0,
342,18,332,4,0,
342,18,371,4,0,
342,18,374,4,0,
342,18,399,4,0,
342,18,400,1,26,
342,18,404,4,0,
342,18,416,4,0,
342,18,458,1,20,
342,18,482,4,0,
342,18,496,4,0,
342,18,503,4,0,
342,18,534,1,32,
342,18,555,4,0,
342,18,590,4,0,
343,5,58,4,0,
343,5,60,1,11,
343,5,76,4,0,
343,5,89,4,0,
343,5,91,4,0,
343,5,92,4,0,
343,5,93,1,1,
343,5,94,4,0,
343,5,104,4,0,
343,5,106,1,3,
343,5,113,4,0,
343,5,115,4,0,
343,5,120,1,19,
343,5,148,4,0,
343,5,153,1,45,
343,5,156,4,0,
343,5,182,4,0,
343,5,189,1,7,
343,5,201,1,31,
343,5,201,4,0,
343,5,216,4,0,
343,5,218,4,0,
343,5,229,1,5,
343,5,237,4,0,
343,5,240,4,0,
343,5,241,4,0,
343,5,246,1,25,
343,5,247,4,0,
343,5,263,4,0,
343,5,285,4,0,
343,5,290,4,0,
343,5,317,1,15,
343,5,317,4,0,
343,5,322,1,37,
343,6,38,3,0,
343,6,58,4,0,
343,6,60,1,11,
343,6,76,4,0,
343,6,89,4,0,
343,6,91,4,0,
343,6,92,4,0,
343,6,93,1,1,
343,6,94,4,0,
343,6,102,3,0,
343,6,104,4,0,
343,6,106,1,3,
343,6,113,4,0,
343,6,115,4,0,
343,6,120,1,19,
343,6,138,3,0,
343,6,148,4,0,
343,6,153,1,45,
343,6,153,3,0,
343,6,156,4,0,
343,6,157,3,0,
343,6,164,3,0,
343,6,173,3,0,
343,6,182,4,0,
343,6,189,1,7,
343,6,189,3,0,
343,6,201,1,31,
343,6,201,4,0,
343,6,203,3,0,
343,6,207,3,0,
343,6,214,3,0,
343,6,216,4,0,
343,6,218,4,0,
343,6,229,1,5,
343,6,237,4,0,
343,6,240,4,0,
343,6,241,4,0,
343,6,244,3,0,
343,6,246,1,25,
343,6,247,4,0,
343,6,263,4,0,
343,6,285,4,0,
343,6,290,4,0,
343,6,317,1,15,
343,6,317,4,0,
343,6,322,1,37,
343,7,38,3,0,
343,7,58,4,0,
343,7,60,1,11,
343,7,76,4,0,
343,7,89,4,0,
343,7,91,4,0,
343,7,92,4,0,
343,7,93,1,1,
343,7,94,4,0,
343,7,102,3,0,
343,7,104,4,0,
343,7,106,1,3,
343,7,113,4,0,
343,7,115,4,0,
343,7,120,1,19,
343,7,138,3,0,
343,7,148,4,0,
343,7,153,1,45,
343,7,153,3,0,
343,7,156,4,0,
343,7,157,3,0,
343,7,164,3,0,
343,7,182,4,0,
343,7,189,1,7,
343,7,201,1,31,
343,7,201,4,0,
343,7,216,4,0,
343,7,218,4,0,
343,7,229,1,5,
343,7,237,4,0,
343,7,240,4,0,
343,7,241,4,0,
343,7,246,1,25,
343,7,247,4,0,
343,7,263,4,0,
343,7,285,4,0,
343,7,290,4,0,
343,7,317,1,15,
343,7,317,4,0,
343,7,322,1,37,
343,8,58,4,0,
343,8,60,1,11,
343,8,76,4,0,
343,8,89,4,0,
343,8,91,4,0,
343,8,92,4,0,
343,8,93,1,1,
343,8,94,4,0,
343,8,104,4,0,
343,8,106,1,3,
343,8,113,4,0,
343,8,115,4,0,
343,8,120,1,19,
343,8,138,4,0,
343,8,148,4,0,
343,8,153,1,71,
343,8,153,4,0,
343,8,156,4,0,
343,8,157,4,0,
343,8,164,4,0,
343,8,182,4,0,
343,8,189,1,7,
343,8,201,1,37,
343,8,201,4,0,
343,8,203,4,0,
343,8,207,4,0,
343,8,214,4,0,
343,8,216,4,0,
343,8,218,4,0,
343,8,219,4,0,
343,8,229,1,5,
343,8,237,4,0,
343,8,240,4,0,
343,8,241,4,0,
343,8,244,4,0,
343,8,246,1,25,
343,8,247,4,0,
343,8,263,4,0,
343,8,278,4,0,
343,8,285,4,0,
343,8,290,4,0,
343,8,317,1,15,
343,8,317,4,0,
343,8,322,1,45,
343,8,347,4,0,
343,8,360,4,0,
343,8,363,4,0,
343,8,377,1,61,
343,8,379,1,31,
343,8,397,4,0,
343,8,414,1,53,
343,8,433,4,0,
343,8,446,4,0,
343,8,447,4,0,
343,8,451,4,0,
343,9,58,4,0,
343,9,60,1,11,
343,9,76,4,0,
343,9,89,4,0,
343,9,91,4,0,
343,9,92,4,0,
343,9,93,1,1,
343,9,94,4,0,
343,9,104,4,0,
343,9,106,1,3,
343,9,113,4,0,
343,9,115,4,0,
343,9,120,1,19,
343,9,138,4,0,
343,9,148,4,0,
343,9,153,1,71,
343,9,153,4,0,
343,9,156,4,0,
343,9,157,4,0,
343,9,164,4,0,
343,9,173,3,0,
343,9,182,4,0,
343,9,189,1,7,
343,9,189,3,0,
343,9,201,1,37,
343,9,201,4,0,
343,9,203,4,0,
343,9,207,4,0,
343,9,214,4,0,
343,9,216,4,0,
343,9,218,4,0,
343,9,219,4,0,
343,9,229,1,5,
343,9,237,4,0,
343,9,240,4,0,
343,9,241,4,0,
343,9,244,4,0,
343,9,246,1,25,
343,9,246,3,0,
343,9,247,4,0,
343,9,263,4,0,
343,9,271,3,0,
343,9,278,4,0,
343,9,285,4,0,
343,9,290,4,0,
343,9,317,1,15,
343,9,317,4,0,
343,9,322,1,45,
343,9,324,3,0,
343,9,347,4,0,
343,9,360,4,0,
343,9,363,4,0,
343,9,377,1,61,
343,9,379,1,31,
343,9,397,4,0,
343,9,414,1,53,
343,9,414,3,0,
343,9,428,3,0,
343,9,433,4,0,
343,9,446,4,0,
343,9,447,4,0,
343,9,451,4,0,
343,10,29,3,0,
343,10,58,4,0,
343,10,60,1,11,
343,10,76,4,0,
343,10,89,4,0,
343,10,91,4,0,
343,10,92,4,0,
343,10,93,1,1,
343,10,94,4,0,
343,10,104,4,0,
343,10,106,1,3,
343,10,113,4,0,
343,10,115,4,0,
343,10,120,1,19,
343,10,138,4,0,
343,10,148,4,0,
343,10,153,1,71,
343,10,153,4,0,
343,10,156,4,0,
343,10,157,4,0,
343,10,164,4,0,
343,10,173,3,0,
343,10,182,4,0,
343,10,189,1,7,
343,10,189,3,0,
343,10,201,1,37,
343,10,201,4,0,
343,10,203,4,0,
343,10,207,4,0,
343,10,214,4,0,
343,10,216,4,0,
343,10,218,4,0,
343,10,219,4,0,
343,10,229,1,5,
343,10,237,4,0,
343,10,240,4,0,
343,10,241,4,0,
343,10,244,4,0,
343,10,246,1,25,
343,10,246,3,0,
343,10,247,4,0,
343,10,263,4,0,
343,10,271,3,0,
343,10,277,3,0,
343,10,278,4,0,
343,10,285,4,0,
343,10,290,4,0,
343,10,317,1,15,
343,10,317,4,0,
343,10,322,1,45,
343,10,324,3,0,
343,10,347,4,0,
343,10,356,3,0,
343,10,360,4,0,
343,10,363,4,0,
343,10,377,1,61,
343,10,379,1,31,
343,10,397,4,0,
343,10,414,1,53,
343,10,414,3,0,
343,10,428,3,0,
343,10,433,4,0,
343,10,446,4,0,
343,10,447,4,0,
343,10,451,4,0,
343,11,58,4,0,
343,11,60,1,15,
343,11,76,4,0,
343,11,89,4,0,
343,11,91,4,0,
343,11,92,4,0,
343,11,93,1,1,
343,11,94,4,0,
343,11,104,4,0,
343,11,106,1,4,
343,11,113,4,0,
343,11,115,4,0,
343,11,120,1,21,
343,11,138,4,0,
343,11,148,4,0,
343,11,153,1,60,
343,11,153,4,0,
343,11,156,4,0,
343,11,157,4,0,
343,11,164,4,0,
343,11,182,4,0,
343,11,189,1,11,
343,11,201,1,34,
343,11,201,4,0,
343,11,207,4,0,
343,11,216,4,0,
343,11,218,4,0,
343,11,219,4,0,
343,11,229,1,7,
343,11,237,4,0,
343,11,240,4,0,
343,11,241,4,0,
343,11,244,4,0,
343,11,246,1,26,
343,11,247,4,0,
343,11,263,4,0,
343,11,317,1,18,
343,11,317,4,0,
343,11,322,1,37,
343,11,326,1,43,
343,11,347,4,0,
343,11,360,4,0,
343,11,377,1,54,
343,11,379,1,31,
343,11,397,4,0,
343,11,414,1,51,
343,11,433,4,0,
343,11,447,4,0,
343,11,451,4,0,
343,11,470,1,48,1
343,11,471,1,48,2
343,11,473,4,0,
343,11,477,4,0,
343,11,479,4,0,
343,11,496,4,0,
343,11,502,4,0,
343,11,523,4,0,
343,12,58,4,0,
343,12,60,1,11,
343,12,76,4,0,
343,12,89,4,0,
343,12,91,4,0,
343,12,92,4,0,
343,12,93,1,1,
343,12,94,4,0,
343,12,104,4,0,
343,12,106,1,3,
343,12,113,4,0,
343,12,115,4,0,
343,12,120,1,19,
343,12,148,4,0,
343,12,153,1,45,
343,12,156,4,0,
343,12,182,4,0,
343,12,189,1,7,
343,12,201,1,31,
343,12,201,4,0,
343,12,216,4,0,
343,12,218,4,0,
343,12,229,1,5,
343,12,237,4,0,
343,12,240,4,0,
343,12,241,4,0,
343,12,246,1,25,
343,12,247,4,0,
343,12,263,4,0,
343,12,285,4,0,
343,12,290,4,0,
343,12,317,1,15,
343,12,317,4,0,
343,12,322,1,37,
343,13,38,3,0,
343,13,58,4,0,
343,13,60,1,11,
343,13,76,4,0,
343,13,89,4,0,
343,13,91,4,0,
343,13,92,4,0,
343,13,93,1,1,
343,13,94,4,0,
343,13,102,3,0,
343,13,104,4,0,
343,13,106,1,3,
343,13,113,4,0,
343,13,115,4,0,
343,13,120,1,19,
343,13,120,3,0,
343,13,138,3,0,
343,13,148,4,0,
343,13,153,1,45,
343,13,156,4,0,
343,13,164,3,0,
343,13,182,4,0,
343,13,189,1,7,
343,13,201,1,31,
343,13,201,4,0,
343,13,207,3,0,
343,13,216,4,0,
343,13,218,4,0,
343,13,229,1,5,
343,13,237,4,0,
343,13,240,4,0,
343,13,241,4,0,
343,13,246,1,25,
343,13,247,4,0,
343,13,263,4,0,
343,13,285,4,0,
343,13,290,4,0,
343,13,317,1,15,
343,13,317,4,0,
343,13,322,1,37,
343,14,58,4,0,
343,14,60,1,13,
343,14,76,4,0,
343,14,89,4,0,
343,14,91,4,0,
343,14,92,4,0,
343,14,93,1,1,2
343,14,94,4,0,
343,14,104,4,0,
343,14,106,1,1,1
343,14,113,4,0,
343,14,115,4,0,
343,14,120,1,25,
343,14,138,4,0,
343,14,148,4,0,
343,14,153,1,49,
343,14,153,4,0,
343,14,156,4,0,
343,14,157,4,0,
343,14,164,4,0,
343,14,173,3,0,
343,14,182,4,0,
343,14,189,1,7,
343,14,201,1,41,
343,14,201,4,0,
343,14,207,4,0,
343,14,214,3,0,
343,14,216,4,0,
343,14,218,4,0,
343,14,219,4,0,
343,14,229,1,4,
343,14,237,4,0,
343,14,240,4,0,
343,14,241,4,0,
343,14,244,4,0,
343,14,246,1,21,
343,14,247,4,0,
343,14,263,4,0,
343,14,271,3,0,
343,14,277,3,0,
343,14,278,3,0,
343,14,285,3,0,
343,14,317,1,10,
343,14,317,4,0,
343,14,322,1,31,
343,14,324,3,0,
343,14,326,1,28,
343,14,347,4,0,
343,14,356,3,0,
343,14,360,4,0,
343,14,377,1,45,
343,14,379,1,17,
343,14,397,4,0,
343,14,414,1,37,
343,14,414,3,0,
343,14,428,3,0,
343,14,433,4,0,
343,14,446,3,0,
343,14,447,4,0,
343,14,451,4,0,
343,14,470,1,34,1
343,14,471,1,34,2
343,14,472,3,0,
343,14,473,4,0,
343,14,477,4,0,
343,14,479,4,0,
343,14,496,4,0,
343,14,502,4,0,
343,14,523,4,0,
343,14,529,3,0,
343,15,58,4,0,
343,15,60,1,13,
343,15,76,4,0,
343,15,89,4,0,
343,15,91,4,0,
343,15,92,4,0,
343,15,93,1,1,2
343,15,94,4,0,
343,15,104,4,0,
343,15,106,1,1,1
343,15,113,4,0,
343,15,115,4,0,
343,15,120,1,25,
343,15,138,4,0,
343,15,148,4,0,
343,15,153,1,49,
343,15,153,4,0,
343,15,156,4,0,
343,15,157,4,0,
343,15,164,4,0,
343,15,182,4,0,
343,15,189,1,7,
343,15,201,1,41,
343,15,201,4,0,
343,15,207,4,0,
343,15,214,4,0,
343,15,216,4,0,
343,15,218,4,0,
343,15,219,4,0,
343,15,229,1,4,
343,15,237,4,0,
343,15,240,4,0,
343,15,241,4,0,
343,15,244,4,0,
343,15,246,1,21,
343,15,247,4,0,
343,15,263,4,0,
343,15,317,1,10,
343,15,317,4,0,
343,15,322,1,31,
343,15,326,1,28,
343,15,347,4,0,
343,15,360,4,0,
343,15,377,1,45,
343,15,379,1,17,
343,15,397,4,0,
343,15,414,1,37,
343,15,433,4,0,
343,15,447,4,0,
343,15,451,4,0,
343,15,470,1,34,1
343,15,471,1,34,2
343,15,473,4,0,
343,15,479,4,0,
343,15,496,4,0,
343,15,523,4,0,
343,15,590,4,0,
343,15,605,4,0,
343,16,58,4,0,
343,16,60,1,16,1
343,16,76,4,0,
343,16,89,4,0,
343,16,91,4,0,
343,16,92,4,0,
343,16,93,1,1,2
343,16,94,4,0,
343,16,104,4,0,
343,16,106,1,1,1
343,16,113,4,0,
343,16,115,4,0,
343,16,120,1,28,1
343,16,138,4,0,
343,16,148,4,0,
343,16,153,1,46,1
343,16,153,4,0,
343,16,156,4,0,
343,16,157,4,0,
343,16,164,4,0,
343,16,173,3,0,
343,16,182,4,0,
343,16,189,1,7,1
343,16,201,1,40,1
343,16,201,4,0,
343,16,207,4,0,
343,16,214,4,0,
343,16,216,4,0,
343,16,218,4,0,
343,16,219,4,0,
343,16,229,1,4,1
343,16,237,4,0,
343,16,240,4,0,
343,16,241,4,0,
343,16,244,4,0,
343,16,246,1,19,1
343,16,247,4,0,
343,16,263,4,0,
343,16,271,3,0,
343,16,277,3,0,
343,16,278,3,0,
343,16,285,3,0,
343,16,286,1,43,1
343,16,290,4,0,
343,16,317,1,13,1
343,16,317,4,0,
343,16,322,1,22,1
343,16,324,3,0,
343,16,326,1,31,1
343,16,347,4,0,
343,16,356,3,0,
343,16,360,4,0,
343,16,377,1,10,1
343,16,379,1,25,1
343,16,397,4,0,
343,16,414,1,37,1
343,16,414,3,0,
343,16,428,3,0,
343,16,433,4,0,
343,16,446,3,0,
343,16,447,4,0,
343,16,451,4,0,
343,16,470,1,34,1
343,16,471,1,34,2
343,16,472,3,0,
343,16,473,4,0,
343,16,479,4,0,
343,16,496,4,0,
343,16,523,4,0,
343,16,529,3,0,
343,16,590,4,0,
343,16,605,4,0,
343,17,58,4,0,
343,17,60,1,16,
343,17,76,4,0,
343,17,89,4,0,
343,17,92,4,0,
343,17,93,1,1,2
343,17,94,4,0,
343,17,104,4,0,
343,17,106,1,1,1
343,17,113,4,0,
343,17,115,4,0,
343,17,120,1,28,
343,17,138,4,0,
343,17,153,1,46,
343,17,153,4,0,
343,17,156,4,0,
343,17,157,4,0,
343,17,164,4,0,
343,17,182,4,0,
343,17,189,1,7,
343,17,201,1,40,
343,17,201,4,0,
343,17,207,4,0,
343,17,214,4,0,
343,17,216,4,0,
343,17,218,4,0,
343,17,219,4,0,
343,17,229,1,4,
343,17,237,4,0,
343,17,240,4,0,
343,17,241,4,0,
343,17,244,4,0,
343,17,246,1,19,
343,17,247,4,0,
343,17,263,4,0,
343,17,286,1,43,
343,17,317,1,13,
343,17,317,4,0,
343,17,322,1,22,
343,17,326,1,31,
343,17,347,4,0,
343,17,360,4,0,
343,17,377,1,10,
343,17,379,1,25,
343,17,397,4,0,
343,17,414,1,37,
343,17,433,4,0,
343,17,447,4,0,
343,17,451,4,0,
343,17,470,1,34,1
343,17,471,1,34,2
343,17,473,4,0,
343,17,479,4,0,
343,17,496,4,0,
343,17,523,4,0,
343,17,590,4,0,
343,17,605,4,0,
343,18,58,4,0,
343,18,60,1,16,
343,18,76,4,0,
343,18,89,4,0,
343,18,92,4,0,
343,18,93,1,1,2
343,18,94,4,0,
343,18,104,4,0,
343,18,106,1,1,1
343,18,113,4,0,
343,18,115,4,0,
343,18,120,1,28,
343,18,138,4,0,
343,18,153,1,46,
343,18,153,4,0,
343,18,156,4,0,
343,18,157,4,0,
343,18,164,4,0,
343,18,182,4,0,
343,18,189,1,7,
343,18,201,1,40,
343,18,201,4,0,
343,18,207,4,0,
343,18,214,4,0,
343,18,216,4,0,
343,18,218,4,0,
343,18,219,4,0,
343,18,229,1,4,
343,18,237,4,0,
343,18,240,4,0,
343,18,241,4,0,
343,18,244,4,0,
343,18,246,1,19,
343,18,247,4,0,
343,18,263,4,0,
343,18,286,1,43,
343,18,317,1,13,
343,18,317,4,0,
343,18,322,1,22,
343,18,326,1,31,
343,18,347,4,0,
343,18,360,4,0,
343,18,377,1,10,
343,18,379,1,25,
343,18,397,4,0,
343,18,414,1,37,
343,18,433,4,0,
343,18,447,4,0,
343,18,451,4,0,
343,18,470,1,34,1
343,18,471,1,34,2
343,18,473,4,0,
343,18,479,4,0,
343,18,496,4,0,
343,18,523,4,0,
343,18,590,4,0,
343,18,605,4,0,
344,5,58,4,0,
344,5,60,1,11,
344,5,63,1,36,
344,5,63,4,0,
344,5,70,4,0,
344,5,76,4,0,
344,5,89,4,0,
344,5,91,4,0,
344,5,92,4,0,
344,5,93,1,1,2
344,5,94,4,0,
344,5,100,1,1,1
344,5,104,4,0,
344,5,106,1,1,3
344,5,106,1,3,
344,5,113,4,0,
344,5,115,4,0,
344,5,120,1,19,
344,5,148,4,0,
344,5,153,1,55,
344,5,156,4,0,
344,5,182,4,0,
344,5,189,1,7,
344,5,201,1,31,
344,5,201,4,0,
344,5,216,4,0,
344,5,218,4,0,
344,5,229,1,1,4
344,5,229,1,5,
344,5,237,4,0,
344,5,240,4,0,
344,5,241,4,0,
344,5,246,1,25,
344,5,247,4,0,
344,5,249,4,0,
344,5,263,4,0,
344,5,285,4,0,
344,5,290,4,0,
344,5,317,1,15,
344,5,317,4,0,
344,5,322,1,42,
344,6,38,3,0,
344,6,58,4,0,
344,6,60,1,11,
344,6,63,1,36,
344,6,63,4,0,
344,6,70,4,0,
344,6,76,4,0,
344,6,89,4,0,
344,6,91,4,0,
344,6,92,4,0,
344,6,93,1,1,2
344,6,94,4,0,
344,6,100,1,1,1
344,6,102,3,0,
344,6,104,4,0,
344,6,106,1,1,3
344,6,106,1,3,
344,6,113,4,0,
344,6,115,4,0,
344,6,120,1,19,
344,6,138,3,0,
344,6,148,4,0,
344,6,153,1,55,
344,6,153,3,0,
344,6,156,4,0,
344,6,157,3,0,
344,6,164,3,0,
344,6,173,3,0,
344,6,182,4,0,
344,6,189,1,7,
344,6,189,3,0,
344,6,201,1,31,
344,6,201,4,0,
344,6,203,3,0,
344,6,207,3,0,
344,6,214,3,0,
344,6,216,4,0,
344,6,218,4,0,
344,6,229,1,1,4
344,6,229,1,5,
344,6,237,4,0,
344,6,240,4,0,
344,6,241,4,0,
344,6,244,3,0,
344,6,246,1,25,
344,6,247,4,0,
344,6,249,4,0,
344,6,263,4,0,
344,6,285,4,0,
344,6,290,4,0,
344,6,317,1,15,
344,6,317,4,0,
344,6,322,1,42,
344,7,38,3,0,
344,7,58,4,0,
344,7,60,1,11,
344,7,63,1,36,
344,7,63,4,0,
344,7,70,4,0,
344,7,76,4,0,
344,7,89,4,0,
344,7,91,4,0,
344,7,92,4,0,
344,7,93,1,1,2
344,7,94,4,0,
344,7,100,1,1,1
344,7,102,3,0,
344,7,104,4,0,
344,7,106,1,1,3
344,7,106,1,3,
344,7,113,4,0,
344,7,115,4,0,
344,7,120,1,19,
344,7,138,3,0,
344,7,148,4,0,
344,7,153,1,55,
344,7,153,3,0,
344,7,156,4,0,
344,7,157,3,0,
344,7,164,3,0,
344,7,182,4,0,
344,7,189,1,7,
344,7,201,1,31,
344,7,201,4,0,
344,7,216,4,0,
344,7,218,4,0,
344,7,229,1,1,4
344,7,229,1,5,
344,7,237,4,0,
344,7,240,4,0,
344,7,241,4,0,
344,7,246,1,25,
344,7,247,4,0,
344,7,249,4,0,
344,7,263,4,0,
344,7,285,4,0,
344,7,290,4,0,
344,7,317,1,15,
344,7,317,4,0,
344,7,322,1,42,
344,8,58,4,0,
344,8,60,1,11,
344,8,63,1,36,
344,8,63,4,0,
344,8,70,4,0,
344,8,76,4,0,
344,8,89,4,0,
344,8,91,4,0,
344,8,92,4,0,
344,8,93,1,1,2
344,8,94,4,0,
344,8,100,1,1,1
344,8,104,4,0,
344,8,106,1,1,3
344,8,106,1,3,
344,8,113,4,0,
344,8,115,4,0,
344,8,120,1,19,
344,8,138,4,0,
344,8,148,4,0,
344,8,153,1,86,
344,8,153,4,0,
344,8,156,4,0,
344,8,157,4,0,
344,8,164,4,0,
344,8,182,4,0,
344,8,189,1,7,
344,8,201,1,40,
344,8,201,4,0,
344,8,203,4,0,
344,8,207,4,0,
344,8,214,4,0,
344,8,216,4,0,
344,8,218,4,0,
344,8,219,4,0,
344,8,229,1,1,4
344,8,229,1,5,
344,8,237,4,0,
344,8,240,4,0,
344,8,241,4,0,
344,8,244,4,0,
344,8,246,1,25,
344,8,247,4,0,
344,8,249,4,0,
344,8,263,4,0,
344,8,278,4,0,
344,8,285,4,0,
344,8,290,4,0,
344,8,317,1,15,
344,8,317,4,0,
344,8,322,1,51,
344,8,347,4,0,
344,8,360,4,0,
344,8,363,4,0,
344,8,377,1,73,
344,8,379,1,31,
344,8,397,4,0,
344,8,414,1,62,
344,8,416,4,0,
344,8,433,4,0,
344,8,444,4,0,
344,8,446,4,0,
344,8,447,4,0,
344,8,451,4,0,
344,9,58,4,0,
344,9,60,1,11,
344,9,63,1,36,
344,9,63,4,0,
344,9,70,4,0,
344,9,76,4,0,
344,9,89,4,0,
344,9,91,4,0,
344,9,92,4,0,
344,9,93,1,1,2
344,9,94,4,0,
344,9,100,1,1,1
344,9,104,4,0,
344,9,106,1,1,3
344,9,106,1,3,
344,9,113,4,0,
344,9,115,4,0,
344,9,120,1,19,
344,9,138,4,0,
344,9,148,4,0,
344,9,153,1,86,
344,9,153,4,0,
344,9,156,4,0,
344,9,157,4,0,
344,9,164,4,0,
344,9,173,3,0,
344,9,182,4,0,
344,9,189,1,7,
344,9,189,3,0,
344,9,201,1,40,
344,9,201,4,0,
344,9,203,4,0,
344,9,207,4,0,
344,9,214,4,0,
344,9,216,4,0,
344,9,218,4,0,
344,9,219,4,0,
344,9,229,1,1,4
344,9,229,1,5,
344,9,237,4,0,
344,9,240,4,0,
344,9,241,4,0,
344,9,244,4,0,
344,9,246,1,25,
344,9,246,3,0,
344,9,247,4,0,
344,9,249,4,0,
344,9,263,4,0,
344,9,271,3,0,
344,9,278,4,0,
344,9,285,4,0,
344,9,290,4,0,
344,9,317,1,15,
344,9,317,4,0,
344,9,322,1,51,
344,9,324,3,0,
344,9,347,4,0,
344,9,360,4,0,
344,9,363,4,0,
344,9,377,1,73,
344,9,379,1,31,
344,9,397,4,0,
344,9,414,1,62,
344,9,414,3,0,
344,9,416,4,0,
344,9,428,3,0,
344,9,433,4,0,
344,9,444,4,0,
344,9,446,4,0,
344,9,447,4,0,
344,9,451,4,0,
344,10,29,3,0,
344,10,58,4,0,
344,10,60,1,11,
344,10,63,1,36,
344,10,63,4,0,
344,10,70,4,0,
344,10,76,4,0,
344,10,89,4,0,
344,10,91,4,0,
344,10,92,4,0,
344,10,93,1,1,2
344,10,94,4,0,
344,10,100,1,1,1
344,10,104,4,0,
344,10,106,1,1,3
344,10,106,1,3,
344,10,113,4,0,
344,10,115,4,0,
344,10,120,1,19,
344,10,138,4,0,
344,10,148,4,0,
344,10,153,1,86,
344,10,153,4,0,
344,10,156,4,0,
344,10,157,4,0,
344,10,164,4,0,
344,10,173,3,0,
344,10,182,4,0,
344,10,189,1,7,
344,10,189,3,0,
344,10,201,1,40,
344,10,201,4,0,
344,10,203,4,0,
344,10,207,4,0,
344,10,214,4,0,
344,10,216,4,0,
344,10,218,4,0,
344,10,219,4,0,
344,10,229,1,1,4
344,10,229,1,5,
344,10,237,4,0,
344,10,240,4,0,
344,10,241,4,0,
344,10,244,4,0,
344,10,246,1,25,
344,10,246,3,0,
344,10,247,4,0,
344,10,249,4,0,
344,10,263,4,0,
344,10,271,3,0,
344,10,277,3,0,
344,10,278,4,0,
344,10,285,4,0,
344,10,290,4,0,
344,10,317,1,15,
344,10,317,4,0,
344,10,322,1,51,
344,10,324,3,0,
344,10,347,4,0,
344,10,356,3,0,
344,10,360,4,0,
344,10,363,4,0,
344,10,377,1,73,
344,10,379,1,31,
344,10,397,4,0,
344,10,414,1,62,
344,10,414,3,0,
344,10,416,4,0,
344,10,428,3,0,
344,10,433,4,0,
344,10,444,4,0,
344,10,446,4,0,
344,10,447,4,0,
344,10,451,4,0,
344,11,58,4,0,
344,11,60,1,15,
344,11,63,1,36,
344,11,63,4,0,
344,11,70,4,0,
344,11,76,4,0,
344,11,89,4,0,
344,11,91,4,0,
344,11,92,4,0,
344,11,93,1,1,2
344,11,94,4,0,
344,11,100,1,1,1
344,11,104,4,0,
344,11,106,1,1,3
344,11,106,1,4,
344,11,113,4,0,
344,11,115,4,0,
344,11,120,1,21,
344,11,138,4,0,
344,11,148,4,0,
344,11,153,1,72,
344,11,153,4,0,
344,11,156,4,0,
344,11,157,4,0,
344,11,164,4,0,
344,11,182,4,0,
344,11,189,1,11,
344,11,201,1,34,
344,11,201,4,0,
344,11,207,4,0,
344,11,216,4,0,
344,11,218,4,0,
344,11,219,4,0,
344,11,229,1,1,4
344,11,229,1,7,
344,11,237,4,0,
344,11,240,4,0,
344,11,241,4,0,
344,11,244,4,0,
344,11,246,1,26,
344,11,247,4,0,
344,11,249,4,0,
344,11,263,4,0,
344,11,317,1,18,
344,11,317,4,0,
344,11,322,1,47,
344,11,326,1,39,
344,11,347,4,0,
344,11,360,4,0,
344,11,377,1,64,
344,11,379,1,31,
344,11,397,4,0,
344,11,414,1,59,
344,11,416,4,0,
344,11,433,4,0,
344,11,444,4,0,
344,11,447,4,0,
344,11,451,4,0,
344,11,470,1,54,1
344,11,471,1,54,2
344,11,473,4,0,
344,11,477,4,0,
344,11,479,4,0,
344,11,496,4,0,
344,11,502,4,0,
344,11,523,4,0,
344,12,58,4,0,
344,12,60,1,11,
344,12,63,1,36,
344,12,63,4,0,
344,12,70,4,0,
344,12,76,4,0,
344,12,89,4,0,
344,12,91,4,0,
344,12,92,4,0,
344,12,93,1,1,2
344,12,94,4,0,
344,12,100,1,1,1
344,12,104,4,0,
344,12,106,1,1,3
344,12,106,1,3,
344,12,113,4,0,
344,12,115,4,0,
344,12,120,1,19,
344,12,148,4,0,
344,12,153,1,55,
344,12,156,4,0,
344,12,182,4,0,
344,12,189,1,7,
344,12,201,1,31,
344,12,201,4,0,
344,12,216,4,0,
344,12,218,4,0,
344,12,229,1,1,4
344,12,229,1,5,
344,12,237,4,0,
344,12,240,4,0,
344,12,241,4,0,
344,12,246,1,25,
344,12,247,4,0,
344,12,249,4,0,
344,12,263,4,0,
344,12,285,4,0,
344,12,290,4,0,
344,12,317,1,15,
344,12,317,4,0,
344,12,322,1,42,
344,13,38,3,0,
344,13,58,4,0,
344,13,60,1,11,
344,13,63,1,36,
344,13,63,4,0,
344,13,70,4,0,
344,13,76,4,0,
344,13,89,4,0,
344,13,91,4,0,
344,13,92,4,0,
344,13,93,1,1,2
344,13,94,4,0,
344,13,100,1,1,1
344,13,102,3,0,
344,13,104,4,0,
344,13,106,1,1,3
344,13,106,1,3,
344,13,113,4,0,
344,13,115,4,0,
344,13,120,1,19,
344,13,120,3,0,
344,13,138,3,0,
344,13,148,4,0,
344,13,153,1,55,
344,13,156,4,0,
344,13,164,3,0,
344,13,182,4,0,
344,13,189,1,7,
344,13,201,1,31,
344,13,201,4,0,
344,13,207,3,0,
344,13,216,4,0,
344,13,218,4,0,
344,13,229,1,1,4
344,13,229,1,5,
344,13,237,4,0,
344,13,240,4,0,
344,13,241,4,0,
344,13,246,1,25,
344,13,247,4,0,
344,13,249,4,0,
344,13,263,4,0,
344,13,285,4,0,
344,13,290,4,0,
344,13,317,1,15,
344,13,317,4,0,
344,13,322,1,42,
344,14,58,4,0,
344,14,60,1,13,
344,14,63,1,36,
344,14,63,4,0,
344,14,70,4,0,
344,14,76,4,0,
344,14,89,4,0,
344,14,91,4,0,
344,14,92,4,0,
344,14,93,1,1,3
344,14,94,4,0,
344,14,100,1,1,1
344,14,104,4,0,
344,14,106,1,1,2
344,14,113,4,0,
344,14,115,4,0,
344,14,120,1,25,
344,14,138,4,0,
344,14,148,4,0,
344,14,153,1,61,
344,14,153,4,0,
344,14,156,4,0,
344,14,157,4,0,
344,14,164,4,0,
344,14,173,3,0,
344,14,182,4,0,
344,14,189,1,7,
344,14,201,1,47,
344,14,201,4,0,
344,14,207,4,0,
344,14,214,3,0,
344,14,216,4,0,
344,14,218,4,0,
344,14,219,4,0,
344,14,229,1,1,4
344,14,229,1,4,
344,14,237,4,0,
344,14,240,4,0,
344,14,241,4,0,
344,14,244,4,0,
344,14,246,1,21,
344,14,247,4,0,
344,14,249,4,0,
344,14,263,4,0,
344,14,271,3,0,
344,14,277,3,0,
344,14,278,3,0,
344,14,285,3,0,
344,14,317,1,10,
344,14,317,4,0,
344,14,322,1,31,
344,14,324,3,0,
344,14,326,1,28,
344,14,347,4,0,
344,14,356,3,0,
344,14,360,4,0,
344,14,377,1,54,
344,14,379,1,17,
344,14,397,4,0,
344,14,414,1,40,
344,14,414,3,0,
344,14,416,4,0,
344,14,428,3,0,
344,14,433,4,0,
344,14,444,4,0,
344,14,446,3,0,
344,14,447,4,0,
344,14,451,4,0,
344,14,470,1,34,1
344,14,471,1,34,2
344,14,472,3,0,
344,14,473,4,0,
344,14,477,4,0,
344,14,479,4,0,
344,14,496,4,0,
344,14,502,4,0,
344,14,523,4,0,
344,14,529,3,0,
344,15,58,4,0,
344,15,60,1,13,
344,15,63,1,36,
344,15,63,4,0,
344,15,70,4,0,
344,15,76,4,0,
344,15,89,4,0,
344,15,91,4,0,
344,15,92,4,0,
344,15,93,1,1,3
344,15,94,4,0,
344,15,100,1,1,1
344,15,104,4,0,
344,15,106,1,1,2
344,15,113,4,0,
344,15,115,4,0,
344,15,120,1,25,
344,15,138,4,0,
344,15,148,4,0,
344,15,153,1,61,
344,15,153,4,0,
344,15,156,4,0,
344,15,157,4,0,
344,15,164,4,0,
344,15,182,4,0,
344,15,189,1,7,
344,15,201,1,47,
344,15,201,4,0,
344,15,207,4,0,
344,15,214,4,0,
344,15,216,4,0,
344,15,218,4,0,
344,15,219,4,0,
344,15,229,1,1,4
344,15,229,1,4,
344,15,237,4,0,
344,15,240,4,0,
344,15,241,4,0,
344,15,244,4,0,
344,15,246,1,21,
344,15,247,4,0,
344,15,249,4,0,
344,15,263,4,0,
344,15,317,1,10,
344,15,317,4,0,
344,15,322,1,31,
344,15,326,1,28,
344,15,347,4,0,
344,15,360,4,0,
344,15,377,1,54,
344,15,379,1,17,
344,15,397,4,0,
344,15,414,1,40,
344,15,416,4,0,
344,15,433,4,0,
344,15,444,4,0,
344,15,447,4,0,
344,15,451,4,0,
344,15,470,1,34,1
344,15,471,1,34,2
344,15,473,4,0,
344,15,479,4,0,
344,15,496,4,0,
344,15,523,4,0,
344,15,590,4,0,
344,15,605,4,0,
344,16,58,4,0,
344,16,60,1,16,1
344,16,63,1,36,1
344,16,63,4,0,
344,16,70,4,0,
344,16,76,4,0,
344,16,89,4,0,
344,16,91,4,0,
344,16,92,4,0,
344,16,93,1,1,3
344,16,94,4,0,
344,16,100,1,1,1
344,16,104,4,0,
344,16,106,1,1,2
344,16,113,4,0,
344,16,115,4,0,
344,16,120,1,28,1
344,16,138,4,0,
344,16,148,4,0,
344,16,153,1,58,1
344,16,153,4,0,
344,16,156,4,0,
344,16,157,4,0,
344,16,164,4,0,
344,16,173,3,0,
344,16,182,4,0,
344,16,189,1,7,1
344,16,201,1,46,1
344,16,201,4,0,
344,16,207,4,0,
344,16,214,4,0,
344,16,216,4,0,
344,16,218,4,0,
344,16,219,4,0,
344,16,229,1,1,4
344,16,229,1,4,1
344,16,237,4,0,
344,16,240,4,0,
344,16,241,4,0,
344,16,244,4,0,
344,16,246,1,19,1
344,16,247,4,0,
344,16,249,4,0,
344,16,263,4,0,
344,16,271,3,0,
344,16,277,3,0,
344,16,278,3,0,
344,16,285,3,0,
344,16,286,1,52,1
344,16,290,4,0,
344,16,317,1,13,1
344,16,317,4,0,
344,16,322,1,22,1
344,16,324,3,0,
344,16,326,1,31,1
344,16,347,4,0,
344,16,356,3,0,
344,16,360,4,0,
344,16,377,1,10,1
344,16,379,1,25,1
344,16,397,4,0,
344,16,414,1,40,1
344,16,414,3,0,
344,16,416,4,0,
344,16,428,3,0,
344,16,433,4,0,
344,16,444,4,0,
344,16,446,3,0,
344,16,447,4,0,
344,16,451,4,0,
344,16,470,1,34,1
344,16,471,1,34,2
344,16,472,3,0,
344,16,473,4,0,
344,16,479,4,0,
344,16,496,4,0,
344,16,523,4,0,
344,16,529,3,0,
344,16,590,4,0,
344,16,605,4,0,
344,17,58,4,0,
344,17,60,1,16,
344,17,63,1,0,
344,17,63,1,1,1
344,17,63,4,0,
344,17,76,4,0,
344,17,89,4,0,
344,17,92,4,0,
344,17,93,1,1,4
344,17,94,4,0,
344,17,100,1,1,2
344,17,104,4,0,
344,17,106,1,1,3
344,17,113,4,0,
344,17,115,4,0,
344,17,120,1,28,
344,17,138,4,0,
344,17,153,1,58,
344,17,153,4,0,
344,17,156,4,0,
344,17,157,4,0,
344,17,164,4,0,
344,17,182,4,0,
344,17,189,1,7,
344,17,201,1,46,
344,17,201,4,0,
344,17,207,4,0,
344,17,214,4,0,
344,17,216,4,0,
344,17,218,4,0,
344,17,219,4,0,
344,17,229,1,1,5
344,17,229,1,4,
344,17,237,4,0,
344,17,240,4,0,
344,17,241,4,0,
344,17,244,4,0,
344,17,246,1,19,
344,17,247,4,0,
344,17,263,4,0,
344,17,286,1,52,
344,17,317,1,13,
344,17,317,4,0,
344,17,322,1,22,
344,17,326,1,31,
344,17,347,4,0,
344,17,360,4,0,
344,17,377,1,10,
344,17,379,1,25,
344,17,397,4,0,
344,17,414,1,40,
344,17,416,4,0,
344,17,433,4,0,
344,17,444,4,0,
344,17,447,4,0,
344,17,451,4,0,
344,17,470,1,34,1
344,17,471,1,34,2
344,17,473,4,0,
344,17,479,4,0,
344,17,496,4,0,
344,17,523,4,0,
344,17,590,4,0,
344,17,605,4,0,
344,18,58,4,0,
344,18,60,1,16,
344,18,63,1,0,
344,18,63,1,1,1
344,18,63,4,0,
344,18,76,4,0,
344,18,89,4,0,
344,18,92,4,0,
344,18,93,1,1,4
344,18,94,4,0,
344,18,100,1,1,2
344,18,104,4,0,
344,18,106,1,1,3
344,18,113,4,0,
344,18,115,4,0,
344,18,120,1,28,
344,18,138,4,0,
344,18,153,1,58,
344,18,153,4,0,
344,18,156,4,0,
344,18,157,4,0,
344,18,164,4,0,
344,18,182,4,0,
344,18,189,1,7,
344,18,201,1,46,
344,18,201,4,0,
344,18,207,4,0,
344,18,214,4,0,
344,18,216,4,0,
344,18,218,4,0,
344,18,219,4,0,
344,18,229,1,1,5
344,18,229,1,4,
344,18,237,4,0,
344,18,240,4,0,
344,18,241,4,0,
344,18,244,4,0,
344,18,246,1,19,
344,18,247,4,0,
344,18,263,4,0,
344,18,286,1,52,
344,18,317,1,13,
344,18,317,4,0,
344,18,322,1,22,
344,18,326,1,31,
344,18,347,4,0,
344,18,360,4,0,
344,18,377,1,10,
344,18,379,1,25,
344,18,397,4,0,
344,18,414,1,40,
344,18,416,4,0,
344,18,433,4,0,
344,18,444,4,0,
344,18,447,4,0,
344,18,451,4,0,
344,18,470,1,34,1
344,18,471,1,34,2
344,18,473,4,0,
344,18,479,4,0,
344,18,496,4,0,
344,18,523,4,0,
344,18,590,4,0,
344,18,605,4,0,
345,5,51,1,15,
345,5,76,4,0,
345,5,92,4,0,
345,5,104,4,0,
345,5,105,2,0,
345,5,109,1,29,
345,5,112,2,0,
345,5,132,1,8,
345,5,133,1,36,
345,5,156,4,0,
345,5,157,2,0,
345,5,182,4,0,
345,5,188,4,0,
345,5,201,4,0,
345,5,202,4,0,
345,5,213,4,0,
345,5,216,4,0,
345,5,218,4,0,
345,5,237,4,0,
345,5,241,4,0,
345,5,243,2,0,
345,5,246,1,43,
345,5,254,1,50,1
345,5,255,1,50,2
345,5,256,1,50,3
345,5,263,4,0,
345,5,275,1,22,
345,5,290,4,0,
345,5,310,1,1,
345,5,331,4,0,
345,6,34,3,0,
345,6,38,3,0,
345,6,51,1,15,
345,6,76,4,0,
345,6,92,4,0,
345,6,102,3,0,
345,6,104,4,0,
345,6,105,2,0,
345,6,109,1,29,
345,6,112,2,0,
345,6,132,1,8,
345,6,133,1,36,
345,6,156,4,0,
345,6,157,2,0,
345,6,157,3,0,
345,6,164,3,0,
345,6,173,3,0,
345,6,182,4,0,
345,6,188,4,0,
345,6,189,3,0,
345,6,201,4,0,
345,6,202,4,0,
345,6,203,3,0,
345,6,207,3,0,
345,6,213,4,0,
345,6,214,3,0,
345,6,216,4,0,
345,6,218,4,0,
345,6,237,4,0,
345,6,241,4,0,
345,6,243,2,0,
345,6,244,3,0,
345,6,246,1,43,
345,6,254,1,50,1
345,6,255,1,50,2
345,6,256,1,50,3
345,6,263,4,0,
345,6,275,1,22,
345,6,290,4,0,
345,6,310,1,1,
345,6,331,4,0,
345,7,34,3,0,
345,7,38,3,0,
345,7,51,1,15,
345,7,76,4,0,
345,7,92,4,0,
345,7,102,3,0,
345,7,104,4,0,
345,7,105,2,0,
345,7,109,1,29,
345,7,112,2,0,
345,7,132,1,8,
345,7,133,1,36,
345,7,156,4,0,
345,7,157,2,0,
345,7,157,3,0,
345,7,164,3,0,
345,7,182,4,0,
345,7,188,4,0,
345,7,201,4,0,
345,7,202,4,0,
345,7,213,4,0,
345,7,216,4,0,
345,7,218,4,0,
345,7,237,4,0,
345,7,241,4,0,
345,7,243,2,0,
345,7,246,1,43,
345,7,254,1,50,1
345,7,255,1,50,2
345,7,256,1,50,3
345,7,263,4,0,
345,7,275,1,22,
345,7,290,4,0,
345,7,310,1,1,
345,7,331,4,0,
345,8,14,4,0,
345,8,51,1,8,
345,8,76,4,0,
345,8,92,4,0,
345,8,104,4,0,
345,8,105,2,0,
345,8,109,1,22,
345,8,112,2,0,
345,8,132,1,1,2
345,8,133,1,29,
345,8,148,4,0,
345,8,156,4,0,
345,8,157,2,0,
345,8,157,4,0,
345,8,164,4,0,
345,8,182,4,0,
345,8,188,4,0,
345,8,201,4,0,
345,8,202,4,0,
345,8,203,4,0,
345,8,207,4,0,
345,8,213,4,0,
345,8,214,4,0,
345,8,216,4,0,
345,8,218,4,0,
345,8,237,4,0,
345,8,241,4,0,
345,8,243,2,0,
345,8,246,1,43,
345,8,254,1,57,1
345,8,255,1,57,2
345,8,256,1,57,3
345,8,263,4,0,
345,8,275,1,15,
345,8,290,4,0,
345,8,310,1,1,1
345,8,317,4,0,
345,8,321,2,0,
345,8,331,4,0,
345,8,363,4,0,
345,8,378,1,64,
345,8,378,2,0,
345,8,380,1,36,
345,8,397,4,0,
345,8,412,1,50,
345,8,412,4,0,
345,8,445,4,0,
345,8,446,4,0,
345,8,447,4,0,
345,9,14,4,0,
345,9,51,1,8,
345,9,76,4,0,
345,9,92,4,0,
345,9,104,4,0,
345,9,105,2,0,
345,9,109,1,22,
345,9,112,2,0,
345,9,132,1,1,2
345,9,133,1,29,
345,9,148,4,0,
345,9,156,4,0,
345,9,157,2,0,
345,9,157,4,0,
345,9,164,4,0,
345,9,173,3,0,
345,9,182,4,0,
345,9,188,4,0,
345,9,189,3,0,
345,9,201,4,0,
345,9,202,4,0,
345,9,203,4,0,
345,9,207,4,0,
345,9,213,4,0,
345,9,214,4,0,
345,9,216,4,0,
345,9,218,4,0,
345,9,235,3,0,
345,9,237,4,0,
345,9,241,4,0,
345,9,243,2,0,
345,9,246,1,43,
345,9,246,3,0,
345,9,254,1,57,1
345,9,255,1,57,2
345,9,256,1,57,3
345,9,263,4,0,
345,9,275,1,15,
345,9,290,4,0,
345,9,310,1,1,1
345,9,317,4,0,
345,9,321,2,0,
345,9,331,4,0,
345,9,363,4,0,
345,9,378,1,64,
345,9,378,2,0,
345,9,380,1,36,
345,9,380,3,0,
345,9,397,4,0,
345,9,402,3,0,
345,9,412,1,50,
345,9,412,4,0,
345,9,414,3,0,
345,9,445,4,0,
345,9,446,4,0,
345,9,447,4,0,
345,10,14,4,0,
345,10,51,1,8,
345,10,76,4,0,
345,10,81,3,0,
345,10,92,4,0,
345,10,104,4,0,
345,10,105,2,0,
345,10,109,1,22,
345,10,112,2,0,
345,10,132,1,1,2
345,10,133,1,29,
345,10,148,4,0,
345,10,156,4,0,
345,10,157,2,0,
345,10,157,4,0,
345,10,164,4,0,
345,10,173,3,0,
345,10,174,2,0,
345,10,182,4,0,
345,10,188,4,0,
345,10,189,3,0,
345,10,201,4,0,
345,10,202,4,0,
345,10,203,4,0,
345,10,207,4,0,
345,10,213,4,0,
345,10,214,4,0,
345,10,216,4,0,
345,10,218,4,0,
345,10,220,3,0,
345,10,235,3,0,
345,10,237,4,0,
345,10,241,4,0,
345,10,243,2,0,
345,10,246,1,43,
345,10,246,3,0,
345,10,254,1,57,1
345,10,255,1,57,2
345,10,256,1,57,3
345,10,263,4,0,
345,10,275,1,15,
345,10,290,4,0,
345,10,310,1,1,1
345,10,317,4,0,
345,10,321,2,0,
345,10,331,4,0,
345,10,363,4,0,
345,10,378,1,64,
345,10,378,2,0,
345,10,380,1,36,
345,10,380,3,0,
345,10,388,3,0,
345,10,397,4,0,
345,10,402,3,0,
345,10,412,1,50,
345,10,412,4,0,
345,10,414,3,0,
345,10,445,4,0,
345,10,446,4,0,
345,10,447,4,0,
345,11,14,4,0,
345,11,51,1,8,
345,11,72,2,0,
345,11,76,4,0,
345,11,92,4,0,
345,11,104,4,0,
345,11,105,2,0,
345,11,109,1,22,
345,11,112,2,0,
345,11,132,1,1,2
345,11,133,1,29,
345,11,148,4,0,
345,11,156,4,0,
345,11,157,4,0,
345,11,164,4,0,
345,11,174,2,0,
345,11,182,4,0,
345,11,188,4,0,
345,11,201,4,0,
345,11,203,2,0,
345,11,207,4,0,
345,11,213,4,0,
345,11,216,4,0,
345,11,218,4,0,
345,11,237,4,0,
345,11,241,4,0,
345,11,243,2,0,
345,11,246,1,43,
345,11,254,1,57,1
345,11,255,1,57,2
345,11,256,1,57,3
345,11,263,4,0,
345,11,275,1,15,
345,11,310,1,1,1
345,11,317,4,0,
345,11,321,2,0,
345,11,378,1,64,
345,11,378,2,0,
345,11,380,1,36,
345,11,397,4,0,
345,11,412,1,50,
345,11,412,4,0,
345,11,446,2,0,
345,11,447,4,0,
345,11,479,4,0,
345,11,496,4,0,
345,12,51,1,15,
345,12,76,4,0,
345,12,92,4,0,
345,12,104,4,0,
345,12,109,1,29,
345,12,132,1,8,
345,12,133,1,36,
345,12,156,4,0,
345,12,182,4,0,
345,12,188,4,0,
345,12,201,4,0,
345,12,202,4,0,
345,12,213,4,0,
345,12,216,4,0,
345,12,218,4,0,
345,12,237,4,0,
345,12,241,4,0,
345,12,246,1,43,
345,12,254,1,50,1
345,12,255,1,50,2
345,12,256,1,50,3
345,12,263,4,0,
345,12,275,1,22,
345,12,290,4,0,
345,12,310,1,1,
345,12,331,4,0,
345,13,34,3,0,
345,13,38,3,0,
345,13,51,1,15,
345,13,76,4,0,
345,13,92,4,0,
345,13,102,3,0,
345,13,104,4,0,
345,13,109,1,29,
345,13,132,1,8,
345,13,133,1,36,
345,13,156,4,0,
345,13,164,3,0,
345,13,182,4,0,
345,13,188,4,0,
345,13,201,4,0,
345,13,202,4,0,
345,13,207,3,0,
345,13,213,4,0,
345,13,216,4,0,
345,13,218,4,0,
345,13,237,4,0,
345,13,241,4,0,
345,13,246,1,43,
345,13,254,1,50,1
345,13,255,1,50,2
345,13,256,1,50,3
345,13,263,4,0,
345,13,275,1,22,
345,13,290,4,0,
345,13,310,1,1,
345,13,331,4,0,
345,14,14,4,0,
345,14,20,3,0,
345,14,51,1,8,
345,14,72,2,0,
345,14,76,4,0,
345,14,92,4,0,
345,14,104,4,0,
345,14,105,2,0,
345,14,109,1,22,
345,14,112,2,0,
345,14,132,1,1,2
345,14,133,1,29,
345,14,148,4,0,
345,14,156,4,0,
345,14,157,4,0,
345,14,164,4,0,
345,14,173,3,0,
345,14,174,2,0,
345,14,182,4,0,
345,14,188,4,0,
345,14,201,4,0,
345,14,202,3,0,
345,14,203,2,0,
345,14,207,4,0,
345,14,213,4,0,
345,14,214,3,0,
345,14,216,4,0,
345,14,218,4,0,
345,14,220,3,0,
345,14,235,3,0,
345,14,237,4,0,
345,14,241,4,0,
345,14,243,2,0,
345,14,246,1,43,
345,14,254,1,57,1
345,14,255,1,57,2
345,14,256,1,57,3
345,14,263,4,0,
345,14,275,1,15,
345,14,310,1,1,1
345,14,317,4,0,
345,14,321,2,0,
345,14,378,1,64,
345,14,378,2,0,
345,14,380,1,36,
345,14,380,3,0,
345,14,388,3,0,
345,14,397,4,0,
345,14,402,3,0,
345,14,412,1,50,
345,14,412,4,0,
345,14,414,3,0,
345,14,446,2,0,
345,14,446,3,0,
345,14,447,4,0,
345,14,479,4,0,
345,14,496,4,0,
345,15,14,4,0,
345,15,51,1,8,
345,15,72,2,0,
345,15,76,4,0,
345,15,92,4,0,
345,15,104,4,0,
345,15,105,2,0,
345,15,109,1,22,
345,15,112,2,0,
345,15,132,1,1,2
345,15,133,1,29,
345,15,148,4,0,
345,15,156,4,0,
345,15,157,4,0,
345,15,164,4,0,
345,15,174,2,0,
345,15,182,4,0,
345,15,188,4,0,
345,15,201,4,0,
345,15,203,2,0,
345,15,207,4,0,
345,15,213,4,0,
345,15,214,4,0,
345,15,216,4,0,
345,15,218,4,0,
345,15,237,4,0,
345,15,241,4,0,
345,15,243,2,0,
345,15,246,1,43,
345,15,254,1,57,1
345,15,255,1,57,2
345,15,256,1,57,3
345,15,263,4,0,
345,15,275,1,15,
345,15,310,1,1,1
345,15,317,4,0,
345,15,321,2,0,
345,15,378,1,64,
345,15,378,2,0,
345,15,380,1,36,
345,15,397,4,0,
345,15,412,1,50,
345,15,412,4,0,
345,15,446,2,0,
345,15,447,4,0,
345,15,479,4,0,
345,15,496,4,0,
345,15,590,4,0,
345,15,611,4,0,
345,16,14,4,0,
345,16,20,3,0,
345,16,51,1,5,1
345,16,72,2,0,
345,16,76,4,0,
345,16,92,4,0,
345,16,104,4,0,
345,16,105,2,0,
345,16,109,1,13,1
345,16,112,2,0,
345,16,132,1,1,2
345,16,133,1,36,1
345,16,148,4,0,
345,16,156,4,0,
345,16,157,4,0,
345,16,164,4,0,
345,16,173,3,0,
345,16,174,2,0,
345,16,182,4,0,
345,16,188,4,0,
345,16,201,4,0,
345,16,202,1,26,1
345,16,202,3,0,
345,16,203,2,0,
345,16,207,4,0,
345,16,213,4,0,
345,16,214,4,0,
345,16,216,4,0,
345,16,218,4,0,
345,16,220,3,0,
345,16,235,3,0,
345,16,237,4,0,
345,16,241,4,0,
345,16,243,2,0,
345,16,246,1,17,1
345,16,254,1,46,1
345,16,255,1,46,2
345,16,256,1,46,3
345,16,263,4,0,
345,16,275,1,9,1
345,16,290,4,0,
345,16,310,1,1,1
345,16,317,4,0,
345,16,321,2,0,
345,16,362,1,21,1
345,16,378,1,52,1
345,16,378,2,0,
345,16,380,1,31,1
345,16,380,3,0,
345,16,388,3,0,
345,16,397,4,0,
345,16,402,3,0,
345,16,412,1,41,1
345,16,412,4,0,
345,16,414,3,0,
345,16,446,2,0,
345,16,446,3,0,
345,16,447,4,0,
345,16,479,4,0,
345,16,496,4,0,
345,16,590,4,0,
345,16,611,4,0,
345,17,14,4,0,
345,17,51,1,5,
345,17,72,2,0,
345,17,76,4,0,
345,17,92,4,0,
345,17,104,4,0,
345,17,105,2,0,
345,17,109,1,13,
345,17,112,2,0,
345,17,132,1,1,2
345,17,133,1,36,
345,17,156,4,0,
345,17,157,4,0,
345,17,164,4,0,
345,17,174,2,0,
345,17,182,4,0,
345,17,188,4,0,
345,17,201,4,0,
345,17,202,1,26,
345,17,203,2,0,
345,17,207,4,0,
345,17,213,4,0,
345,17,214,4,0,
345,17,216,4,0,
345,17,218,4,0,
345,17,237,4,0,
345,17,241,4,0,
345,17,243,2,0,
345,17,246,1,17,
345,17,254,1,46,1
345,17,255,1,46,2
345,17,256,1,46,3
345,17,263,4,0,
345,17,275,1,9,
345,17,310,1,1,1
345,17,317,4,0,
345,17,321,2,0,
345,17,362,1,21,
345,17,378,1,52,
345,17,378,2,0,
345,17,380,1,31,
345,17,397,4,0,
345,17,412,1,41,
345,17,412,4,0,
345,17,446,2,0,
345,17,447,4,0,
345,17,479,4,0,
345,17,496,4,0,
345,17,590,4,0,
345,17,611,4,0,
345,18,14,4,0,
345,18,51,1,5,
345,18,72,2,0,
345,18,76,4,0,
345,18,92,4,0,
345,18,104,4,0,
345,18,105,2,0,
345,18,109,1,13,
345,18,112,2,0,
345,18,132,1,1,2
345,18,133,1,36,
345,18,156,4,0,
345,18,157,4,0,
345,18,164,4,0,
345,18,174,2,0,
345,18,182,4,0,
345,18,188,4,0,
345,18,201,4,0,
345,18,202,1,26,
345,18,203,2,0,
345,18,207,4,0,
345,18,213,4,0,
345,18,214,4,0,
345,18,216,4,0,
345,18,218,4,0,
345,18,237,4,0,
345,18,241,4,0,
345,18,243,2,0,
345,18,246,1,17,
345,18,254,1,46,1
345,18,255,1,46,2
345,18,256,1,46,3
345,18,263,4,0,
345,18,275,1,9,
345,18,310,1,1,1
345,18,317,4,0,
345,18,321,2,0,
345,18,362,1,21,
345,18,378,1,52,
345,18,378,2,0,
345,18,380,1,31,
345,18,397,4,0,
345,18,412,1,41,
345,18,412,4,0,
345,18,446,2,0,
345,18,447,4,0,
345,18,479,4,0,
345,18,496,4,0,
345,18,590,4,0,
345,18,611,4,0,
346,5,51,1,1,3
346,5,51,1,15,
346,5,63,4,0,
346,5,70,4,0,
346,5,76,4,0,
346,5,89,4,0,
346,5,92,4,0,
346,5,104,4,0,
346,5,109,1,29,
346,5,132,1,1,2
346,5,132,1,8,
346,5,133,1,36,
346,5,156,4,0,
346,5,182,4,0,
346,5,188,4,0,
346,5,201,4,0,
346,5,202,4,0,
346,5,213,4,0,
346,5,216,4,0,
346,5,218,4,0,
346,5,237,4,0,
346,5,241,4,0,
346,5,246,1,48,
346,5,249,4,0,
346,5,254,1,60,
346,5,255,1,60,5
346,5,256,1,60,6
346,5,263,4,0,
346,5,275,1,1,4
346,5,275,1,22,
346,5,290,4,0,
346,5,310,1,1,1
346,5,317,4,0,
346,5,331,4,0,
346,6,34,3,0,
346,6,38,3,0,
346,6,51,1,1,3
346,6,51,1,15,
346,6,63,4,0,
346,6,70,4,0,
346,6,76,4,0,
346,6,89,4,0,
346,6,92,4,0,
346,6,102,3,0,
346,6,104,4,0,
346,6,109,1,29,
346,6,132,1,1,2
346,6,132,1,8,
346,6,133,1,36,
346,6,156,4,0,
346,6,157,3,0,
346,6,164,3,0,
346,6,173,3,0,
346,6,182,4,0,
346,6,188,4,0,
346,6,189,3,0,
346,6,201,4,0,
346,6,202,4,0,
346,6,203,3,0,
346,6,207,3,0,
346,6,213,4,0,
346,6,214,3,0,
346,6,216,4,0,
346,6,218,4,0,
346,6,237,4,0,
346,6,241,4,0,
346,6,244,3,0,
346,6,246,1,48,
346,6,249,4,0,
346,6,254,1,60,
346,6,255,1,60,5
346,6,256,1,60,6
346,6,263,4,0,
346,6,275,1,1,4
346,6,275,1,22,
346,6,290,4,0,
346,6,310,1,1,1
346,6,317,4,0,
346,6,331,4,0,
346,7,34,3,0,
346,7,38,3,0,
346,7,51,1,1,3
346,7,51,1,15,
346,7,63,4,0,
346,7,70,4,0,
346,7,76,4,0,
346,7,89,4,0,
346,7,92,4,0,
346,7,102,3,0,
346,7,104,4,0,
346,7,109,1,29,
346,7,132,1,1,2
346,7,132,1,8,
346,7,133,1,36,
346,7,156,4,0,
346,7,157,3,0,
346,7,164,3,0,
346,7,182,4,0,
346,7,188,4,0,
346,7,201,4,0,
346,7,202,4,0,
346,7,213,4,0,
346,7,216,4,0,
346,7,218,4,0,
346,7,237,4,0,
346,7,241,4,0,
346,7,246,1,48,
346,7,249,4,0,
346,7,254,1,60,
346,7,255,1,60,5
346,7,256,1,60,6
346,7,263,4,0,
346,7,275,1,1,4
346,7,275,1,22,
346,7,290,4,0,
346,7,310,1,1,1
346,7,317,4,0,
346,7,331,4,0,
346,8,14,4,0,
346,8,51,1,1,3
346,8,51,1,8,
346,8,63,4,0,
346,8,70,4,0,
346,8,76,4,0,
346,8,89,4,0,
346,8,92,4,0,
346,8,104,4,0,
346,8,109,1,22,
346,8,132,1,1,2
346,8,133,1,29,
346,8,148,4,0,
346,8,156,4,0,
346,8,157,4,0,
346,8,164,4,0,
346,8,182,4,0,
346,8,188,4,0,
346,8,201,4,0,
346,8,202,4,0,
346,8,203,4,0,
346,8,207,4,0,
346,8,213,4,0,
346,8,214,4,0,
346,8,216,4,0,
346,8,218,4,0,
346,8,237,4,0,
346,8,241,4,0,
346,8,246,1,36,
346,8,249,4,0,
346,8,254,1,66,1
346,8,255,1,66,2
346,8,256,1,66,3
346,8,263,4,0,
346,8,275,1,1,4
346,8,275,1,15,
346,8,290,4,0,
346,8,310,1,1,1
346,8,317,4,0,
346,8,331,4,0,
346,8,363,4,0,
346,8,378,1,76,
346,8,380,1,46,
346,8,397,4,0,
346,8,412,1,56,
346,8,412,4,0,
346,8,416,4,0,
346,8,444,4,0,
346,8,445,4,0,
346,8,446,4,0,
346,8,447,4,0,
346,9,14,4,0,
346,9,51,1,1,3
346,9,51,1,8,
346,9,63,4,0,
346,9,70,4,0,
346,9,76,4,0,
346,9,89,4,0,
346,9,92,4,0,
346,9,104,4,0,
346,9,109,1,22,
346,9,132,1,1,2
346,9,133,1,29,
346,9,148,4,0,
346,9,156,4,0,
346,9,157,4,0,
346,9,164,4,0,
346,9,173,3,0,
346,9,182,4,0,
346,9,188,4,0,
346,9,189,3,0,
346,9,201,4,0,
346,9,202,4,0,
346,9,203,4,0,
346,9,207,4,0,
346,9,213,4,0,
346,9,214,4,0,
346,9,216,4,0,
346,9,218,4,0,
346,9,235,3,0,
346,9,237,4,0,
346,9,241,4,0,
346,9,246,1,36,
346,9,246,3,0,
346,9,249,4,0,
346,9,254,1,66,1
346,9,255,1,66,2
346,9,256,1,66,3
346,9,263,4,0,
346,9,275,1,1,4
346,9,275,1,15,
346,9,290,4,0,
346,9,310,1,1,1
346,9,317,4,0,
346,9,331,4,0,
346,9,363,4,0,
346,9,378,1,76,
346,9,380,1,46,
346,9,380,3,0,
346,9,397,4,0,
346,9,402,3,0,
346,9,412,1,56,
346,9,412,4,0,
346,9,414,3,0,
346,9,416,4,0,
346,9,444,4,0,
346,9,445,4,0,
346,9,446,4,0,
346,9,447,4,0,
346,10,14,4,0,
346,10,29,3,0,
346,10,51,1,1,3
346,10,51,1,8,
346,10,63,4,0,
346,10,70,4,0,
346,10,76,4,0,
346,10,81,3,0,
346,10,89,4,0,
346,10,92,4,0,
346,10,104,4,0,
346,10,109,1,22,
346,10,132,1,1,2
346,10,133,1,29,
346,10,148,4,0,
346,10,156,4,0,
346,10,157,4,0,
346,10,164,4,0,
346,10,173,3,0,
346,10,182,4,0,
346,10,188,4,0,
346,10,189,3,0,
346,10,201,4,0,
346,10,202,4,0,
346,10,203,4,0,
346,10,207,4,0,
346,10,213,4,0,
346,10,214,4,0,
346,10,216,4,0,
346,10,218,4,0,
346,10,220,3,0,
346,10,235,3,0,
346,10,237,4,0,
346,10,241,4,0,
346,10,246,1,36,
346,10,246,3,0,
346,10,249,4,0,
346,10,254,1,66,1
346,10,255,1,66,2
346,10,256,1,66,3
346,10,263,4,0,
346,10,275,1,1,4
346,10,275,1,15,
346,10,290,4,0,
346,10,310,1,1,1
346,10,317,4,0,
346,10,331,4,0,
346,10,335,3,0,
346,10,363,4,0,
346,10,378,1,76,
346,10,380,1,46,
346,10,380,3,0,
346,10,388,3,0,
346,10,397,4,0,
346,10,402,3,0,
346,10,412,1,56,
346,10,412,4,0,
346,10,414,3,0,
346,10,416,4,0,
346,10,444,4,0,
346,10,445,4,0,
346,10,446,4,0,
346,10,447,4,0,
346,11,14,4,0,
346,11,51,1,1,3
346,11,51,1,8,
346,11,63,4,0,
346,11,70,4,0,
346,11,76,4,0,
346,11,89,4,0,
346,11,92,4,0,
346,11,104,4,0,
346,11,109,1,22,
346,11,132,1,1,2
346,11,133,1,29,
346,11,148,4,0,
346,11,156,4,0,
346,11,157,4,0,
346,11,164,4,0,
346,11,182,4,0,
346,11,188,4,0,
346,11,201,4,0,
346,11,207,4,0,
346,11,213,4,0,
346,11,216,4,0,
346,11,218,4,0,
346,11,237,4,0,
346,11,241,4,0,
346,11,246,1,36,
346,11,249,4,0,
346,11,254,1,66,1
346,11,255,1,66,2
346,11,256,1,66,3
346,11,263,4,0,
346,11,275,1,1,4
346,11,275,1,15,
346,11,310,1,1,1
346,11,317,4,0,
346,11,378,1,76,
346,11,380,1,46,
346,11,397,4,0,
346,11,412,1,56,
346,11,412,4,0,
346,11,416,4,0,
346,11,444,4,0,
346,11,447,4,0,
346,11,479,4,0,
346,11,482,4,0,
346,11,496,4,0,
346,11,523,4,0,
346,12,51,1,1,3
346,12,51,1,15,
346,12,63,4,0,
346,12,70,4,0,
346,12,76,4,0,
346,12,89,4,0,
346,12,92,4,0,
346,12,104,4,0,
346,12,109,1,29,
346,12,132,1,1,2
346,12,132,1,8,
346,12,133,1,36,
346,12,156,4,0,
346,12,182,4,0,
346,12,188,4,0,
346,12,201,4,0,
346,12,202,4,0,
346,12,213,4,0,
346,12,216,4,0,
346,12,218,4,0,
346,12,237,4,0,
346,12,241,4,0,
346,12,246,1,48,
346,12,249,4,0,
346,12,254,1,60,
346,12,255,1,60,5
346,12,256,1,60,6
346,12,263,4,0,
346,12,275,1,1,4
346,12,275,1,22,
346,12,290,4,0,
346,12,310,1,1,1
346,12,317,4,0,
346,12,331,4,0,
346,13,34,3,0,
346,13,38,3,0,
346,13,51,1,1,3
346,13,51,1,15,
346,13,63,4,0,
346,13,70,4,0,
346,13,76,4,0,
346,13,89,4,0,
346,13,92,4,0,
346,13,102,3,0,
346,13,104,4,0,
346,13,109,1,29,
346,13,132,1,1,2
346,13,132,1,8,
346,13,133,1,36,
346,13,156,4,0,
346,13,164,3,0,
346,13,182,4,0,
346,13,188,4,0,
346,13,201,4,0,
346,13,202,4,0,
346,13,207,3,0,
346,13,213,4,0,
346,13,216,4,0,
346,13,218,4,0,
346,13,237,4,0,
346,13,241,4,0,
346,13,246,1,48,
346,13,249,4,0,
346,13,254,1,60,
346,13,255,1,60,5
346,13,256,1,60,6
346,13,263,4,0,
346,13,275,1,1,4
346,13,275,1,22,
346,13,290,4,0,
346,13,310,1,1,1
346,13,317,4,0,
346,13,331,4,0,
346,14,14,4,0,
346,14,20,3,0,
346,14,51,1,1,3
346,14,51,1,8,
346,14,63,4,0,
346,14,70,4,0,
346,14,76,4,0,
346,14,89,4,0,
346,14,92,4,0,
346,14,104,4,0,
346,14,109,1,22,
346,14,132,1,1,2
346,14,133,1,29,
346,14,148,4,0,
346,14,156,4,0,
346,14,157,4,0,
346,14,164,4,0,
346,14,173,3,0,
346,14,182,4,0,
346,14,188,4,0,
346,14,201,4,0,
346,14,202,3,0,
346,14,207,4,0,
346,14,213,4,0,
346,14,214,3,0,
346,14,216,4,0,
346,14,218,4,0,
346,14,220,3,0,
346,14,235,3,0,
346,14,237,4,0,
346,14,241,4,0,
346,14,246,1,36,
346,14,249,4,0,
346,14,254,1,66,1
346,14,255,1,66,2
346,14,256,1,66,3
346,14,263,4,0,
346,14,275,1,1,4
346,14,275,1,15,
346,14,310,1,1,1
346,14,317,4,0,
346,14,335,3,0,
346,14,378,1,76,
346,14,380,1,46,
346,14,380,3,0,
346,14,388,3,0,
346,14,397,4,0,
346,14,402,3,0,
346,14,412,1,56,
346,14,412,4,0,
346,14,414,3,0,
346,14,416,4,0,
346,14,444,4,0,
346,14,446,3,0,
346,14,447,4,0,
346,14,479,4,0,
346,14,482,4,0,
346,14,496,4,0,
346,14,523,4,0,
346,15,14,4,0,
346,15,51,1,1,7
346,15,51,1,8,
346,15,63,4,0,
346,15,70,4,0,
346,15,76,4,0,
346,15,89,4,0,
346,15,92,4,0,
346,15,104,4,0,
346,15,109,1,22,
346,15,132,1,1,6
346,15,133,1,29,
346,15,148,4,0,
346,15,156,4,0,
346,15,157,4,0,
346,15,164,4,0,
346,15,182,4,0,
346,15,188,4,0,
346,15,201,4,0,
346,15,207,4,0,
346,15,213,4,0,
346,15,214,4,0,
346,15,216,4,0,
346,15,218,4,0,
346,15,237,4,0,
346,15,241,4,0,
346,15,246,1,36,
346,15,249,4,0,
346,15,254,1,1,4
346,15,254,1,66,1
346,15,255,1,1,3
346,15,255,1,66,2
346,15,256,1,1,2
346,15,256,1,66,3
346,15,263,4,0,
346,15,275,1,1,8
346,15,275,1,15,
346,15,310,1,1,5
346,15,317,4,0,
346,15,378,1,1,1
346,15,378,1,76,
346,15,380,1,46,
346,15,397,4,0,
346,15,412,1,56,
346,15,412,4,0,
346,15,416,4,0,
346,15,444,4,0,
346,15,447,4,0,
346,15,479,4,0,
346,15,482,4,0,
346,15,496,4,0,
346,15,523,4,0,
346,15,590,4,0,
346,15,611,4,0,
346,16,14,4,0,
346,16,20,3,0,
346,16,51,1,1,4
346,16,51,1,5,1
346,16,63,4,0,
346,16,70,4,0,
346,16,76,4,0,
346,16,89,4,0,
346,16,92,4,0,
346,16,104,4,0,
346,16,109,1,13,1
346,16,132,1,1,3
346,16,133,1,36,1
346,16,148,4,0,
346,16,156,4,0,
346,16,157,4,0,
346,16,164,4,0,
346,16,173,3,0,
346,16,182,4,0,
346,16,188,4,0,
346,16,201,4,0,
346,16,202,1,26,1
346,16,202,3,0,
346,16,207,4,0,
346,16,213,4,0,
346,16,214,4,0,
346,16,216,4,0,
346,16,218,4,0,
346,16,220,3,0,
346,16,235,3,0,
346,16,237,4,0,
346,16,241,4,0,
346,16,246,1,17,1
346,16,249,4,0,
346,16,254,1,52,1
346,16,255,1,52,2
346,16,256,1,52,3
346,16,263,4,0,
346,16,275,1,1,5
346,16,275,1,9,1
346,16,290,4,0,
346,16,310,1,1,2
346,16,317,4,0,
346,16,335,3,0,
346,16,362,1,21,1
346,16,378,1,1,1
346,16,378,1,61,1
346,16,380,1,31,1
346,16,380,3,0,
346,16,388,3,0,
346,16,397,4,0,
346,16,402,3,0,
346,16,412,1,44,1
346,16,412,4,0,
346,16,414,3,0,
346,16,416,4,0,
346,16,444,4,0,
346,16,446,3,0,
346,16,447,4,0,
346,16,479,4,0,
346,16,482,4,0,
346,16,496,4,0,
346,16,523,4,0,
346,16,590,4,0,
346,16,611,4,0,
346,17,14,4,0,
346,17,51,1,1,4
346,17,51,1,5,
346,17,63,4,0,
346,17,76,4,0,
346,17,89,4,0,
346,17,92,4,0,
346,17,104,4,0,
346,17,109,1,13,
346,17,132,1,1,3
346,17,133,1,36,
346,17,156,4,0,
346,17,157,4,0,
346,17,164,4,0,
346,17,182,4,0,
346,17,188,4,0,
346,17,201,4,0,
346,17,202,1,26,
346,17,207,4,0,
346,17,213,4,0,
346,17,214,4,0,
346,17,216,4,0,
346,17,218,4,0,
346,17,237,4,0,
346,17,241,4,0,
346,17,246,1,17,
346,17,254,1,52,1
346,17,255,1,52,2
346,17,256,1,52,3
346,17,263,4,0,
346,17,275,1,1,5
346,17,275,1,9,
346,17,310,1,1,2
346,17,317,4,0,
346,17,362,1,21,
346,17,378,1,1,1
346,17,378,1,61,
346,17,380,1,31,
346,17,397,4,0,
346,17,412,1,44,
346,17,412,4,0,
346,17,416,4,0,
346,17,444,4,0,
346,17,447,4,0,
346,17,479,4,0,
346,17,482,4,0,
346,17,496,4,0,
346,17,523,4,0,
346,17,590,4,0,
346,17,611,4,0,
346,18,14,4,0,
346,18,51,1,1,4
346,18,51,1,5,
346,18,63,4,0,
346,18,76,4,0,
346,18,89,4,0,
346,18,92,4,0,
346,18,104,4,0,
346,18,109,1,13,
346,18,132,1,1,3
346,18,133,1,36,
346,18,156,4,0,
346,18,157,4,0,
346,18,164,4,0,
346,18,182,4,0,
346,18,188,4,0,
346,18,201,4,0,
346,18,202,1,26,
346,18,207,4,0,
346,18,213,4,0,
346,18,214,4,0,
346,18,216,4,0,
346,18,218,4,0,
346,18,237,4,0,
346,18,241,4,0,
346,18,246,1,17,
346,18,254,1,52,1
346,18,255,1,52,2
346,18,256,1,52,3
346,18,263,4,0,
346,18,275,1,1,5
346,18,275,1,9,
346,18,310,1,1,2
346,18,317,4,0,
346,18,362,1,21,
346,18,378,1,1,1
346,18,378,1,61,
346,18,380,1,31,
346,18,397,4,0,
346,18,412,1,44,
346,18,412,4,0,
346,18,416,4,0,
346,18,444,4,0,
346,18,447,4,0,
346,18,479,4,0,
346,18,482,4,0,
346,18,496,4,0,
346,18,523,4,0,
346,18,590,4,0,
346,18,611,4,0,
347,5,10,1,1,
347,5,14,2,0,
347,5,15,4,0,
347,5,55,1,19,
347,5,91,4,0,
347,5,92,4,0,
347,5,104,4,0,
347,5,106,1,7,
347,5,156,4,0,
347,5,157,2,0,
347,5,163,1,49,
347,5,182,1,31,
347,5,182,4,0,
347,5,201,4,0,
347,5,210,1,43,
347,5,213,4,0,
347,5,216,4,0,
347,5,218,4,0,
347,5,229,2,0,
347,5,232,1,25,
347,5,237,4,0,
347,5,241,4,0,
347,5,246,1,37,
347,5,249,4,0,
347,5,263,4,0,
347,5,280,4,0,
347,5,282,2,0,
347,5,290,4,0,
347,5,300,1,13,
347,5,317,4,0,
347,5,332,4,0,
347,5,350,1,55,
347,5,352,4,0,
347,6,10,1,1,
347,6,14,2,0,
347,6,14,3,0,
347,6,15,4,0,
347,6,34,3,0,
347,6,38,3,0,
347,6,55,1,19,
347,6,91,4,0,
347,6,92,4,0,
347,6,102,3,0,
347,6,104,4,0,
347,6,106,1,7,
347,6,156,4,0,
347,6,157,2,0,
347,6,157,3,0,
347,6,163,1,49,
347,6,164,3,0,
347,6,173,3,0,
347,6,182,1,31,
347,6,182,4,0,
347,6,189,3,0,
347,6,201,4,0,
347,6,203,3,0,
347,6,207,3,0,
347,6,210,1,43,
347,6,210,3,0,
347,6,213,4,0,
347,6,214,3,0,
347,6,216,4,0,
347,6,218,4,0,
347,6,229,2,0,
347,6,232,1,25,
347,6,237,4,0,
347,6,241,4,0,
347,6,246,1,37,
347,6,249,4,0,
347,6,263,4,0,
347,6,280,4,0,
347,6,282,2,0,
347,6,290,4,0,
347,6,300,1,13,
347,6,317,4,0,
347,6,332,4,0,
347,6,350,1,55,
347,6,352,4,0,
347,7,10,1,1,
347,7,14,2,0,
347,7,14,3,0,
347,7,15,4,0,
347,7,34,3,0,
347,7,38,3,0,
347,7,55,1,19,
347,7,91,4,0,
347,7,92,4,0,
347,7,102,3,0,
347,7,104,4,0,
347,7,106,1,7,
347,7,156,4,0,
347,7,157,2,0,
347,7,157,3,0,
347,7,163,1,49,
347,7,164,3,0,
347,7,182,1,31,
347,7,182,4,0,
347,7,201,4,0,
347,7,210,1,43,
347,7,213,4,0,
347,7,216,4,0,
347,7,218,4,0,
347,7,229,2,0,
347,7,232,1,25,
347,7,237,4,0,
347,7,241,4,0,
347,7,246,1,37,
347,7,249,4,0,
347,7,263,4,0,
347,7,280,4,0,
347,7,282,2,0,
347,7,290,4,0,
347,7,300,1,13,
347,7,317,4,0,
347,7,332,4,0,
347,7,350,1,55,
347,7,352,4,0,
347,8,10,1,1,1
347,8,14,2,0,
347,8,14,4,0,
347,8,15,4,0,
347,8,28,2,0,
347,8,55,1,13,
347,8,91,4,0,
347,8,92,4,0,
347,8,103,2,0,
347,8,104,4,0,
347,8,106,1,1,2
347,8,156,4,0,
347,8,157,2,0,
347,8,157,4,0,
347,8,163,1,43,
347,8,164,4,0,
347,8,182,1,25,
347,8,182,4,0,
347,8,201,4,0,
347,8,203,4,0,
347,8,206,4,0,
347,8,207,4,0,
347,8,210,1,37,
347,8,213,4,0,
347,8,214,4,0,
347,8,216,4,0,
347,8,218,4,0,
347,8,229,2,0,
347,8,232,1,19,
347,8,237,4,0,
347,8,241,4,0,
347,8,246,1,31,
347,8,249,4,0,
347,8,263,4,0,
347,8,280,4,0,
347,8,282,2,0,
347,8,290,4,0,
347,8,300,1,7,
347,8,306,1,55,
347,8,317,4,0,
347,8,332,4,0,
347,8,350,1,49,
347,8,352,4,0,
347,8,363,4,0,
347,8,397,4,0,
347,8,404,1,61,
347,8,404,4,0,
347,8,440,2,0,
347,8,445,4,0,
347,8,446,4,0,
347,9,10,1,1,1
347,9,14,2,0,
347,9,14,4,0,
347,9,15,4,0,
347,9,28,2,0,
347,9,55,1,13,
347,9,91,4,0,
347,9,92,4,0,
347,9,103,2,0,
347,9,104,4,0,
347,9,106,1,1,2
347,9,156,4,0,
347,9,157,2,0,
347,9,157,4,0,
347,9,163,1,43,
347,9,164,4,0,
347,9,173,3,0,
347,9,182,1,25,
347,9,182,4,0,
347,9,189,3,0,
347,9,201,4,0,
347,9,203,4,0,
347,9,206,4,0,
347,9,207,4,0,
347,9,210,1,37,
347,9,210,3,0,
347,9,213,4,0,
347,9,214,4,0,
347,9,216,4,0,
347,9,218,4,0,
347,9,229,2,0,
347,9,232,1,19,
347,9,237,4,0,
347,9,241,4,0,
347,9,246,1,31,
347,9,246,3,0,
347,9,249,4,0,
347,9,263,4,0,
347,9,280,4,0,
347,9,282,2,0,
347,9,282,3,0,
347,9,290,4,0,
347,9,300,1,7,
347,9,306,1,55,
347,9,317,4,0,
347,9,332,4,0,
347,9,334,3,0,
347,9,350,1,49,
347,9,352,4,0,
347,9,363,4,0,
347,9,397,4,0,
347,9,404,1,61,
347,9,404,4,0,
347,9,414,3,0,
347,9,440,2,0,
347,9,445,4,0,
347,9,446,4,0,
347,10,10,1,1,1
347,10,14,2,0,
347,10,14,4,0,
347,10,15,4,0,
347,10,28,2,0,
347,10,29,3,0,
347,10,55,1,13,
347,10,81,3,0,
347,10,91,4,0,
347,10,92,4,0,
347,10,103,2,0,
347,10,104,4,0,
347,10,106,1,1,2
347,10,156,4,0,
347,10,157,2,0,
347,10,157,4,0,
347,10,163,1,43,
347,10,164,4,0,
347,10,173,3,0,
347,10,174,2,0,
347,10,182,1,25,
347,10,182,4,0,
347,10,189,3,0,
347,10,201,4,0,
347,10,203,4,0,
347,10,206,4,0,
347,10,207,4,0,
347,10,210,1,37,
347,10,210,3,0,
347,10,213,4,0,
347,10,214,4,0,
347,10,216,4,0,
347,10,218,4,0,
347,10,229,2,0,
347,10,232,1,19,
347,10,237,4,0,
347,10,241,4,0,
347,10,246,1,31,
347,10,246,3,0,
347,10,249,4,0,
347,10,263,4,0,
347,10,280,4,0,
347,10,282,2,0,
347,10,282,3,0,
347,10,290,4,0,
347,10,300,1,7,
347,10,306,1,55,
347,10,317,4,0,
347,10,332,4,0,
347,10,334,3,0,
347,10,350,1,49,
347,10,352,4,0,
347,10,363,4,0,
347,10,397,4,0,
347,10,404,1,61,
347,10,404,4,0,
347,10,414,3,0,
347,10,440,2,0,
347,10,445,4,0,
347,10,446,4,0,
347,11,10,1,1,1
347,11,14,4,0,
347,11,15,4,0,
347,11,28,2,0,
347,11,55,1,13,
347,11,91,4,0,
347,11,92,4,0,
347,11,103,2,0,
347,11,104,4,0,
347,11,106,1,1,2
347,11,156,4,0,
347,11,157,4,0,
347,11,163,1,43,
347,11,164,4,0,
347,11,174,2,0,
347,11,182,1,25,
347,11,182,4,0,
347,11,201,4,0,
347,11,206,4,0,
347,11,207,4,0,
347,11,210,1,37,
347,11,213,4,0,
347,11,216,4,0,
347,11,218,4,0,
347,11,229,2,0,
347,11,232,1,19,
347,11,237,4,0,
347,11,241,4,0,
347,11,246,1,31,
347,11,249,4,0,
347,11,263,4,0,
347,11,280,4,0,
347,11,282,2,0,
347,11,300,1,7,
347,11,306,1,55,
347,11,317,4,0,
347,11,332,4,0,
347,11,334,2,0,
347,11,350,1,49,
347,11,352,2,0,
347,11,397,4,0,
347,11,404,1,61,
347,11,404,4,0,
347,11,440,2,0,
347,11,468,4,0,
347,11,479,4,0,
347,11,496,4,0,
347,11,522,4,0,
347,12,10,1,1,
347,12,15,4,0,
347,12,55,1,19,
347,12,91,4,0,
347,12,92,4,0,
347,12,104,4,0,
347,12,106,1,7,
347,12,156,4,0,
347,12,163,1,49,
347,12,182,1,31,
347,12,182,4,0,
347,12,201,4,0,
347,12,210,1,43,
347,12,213,4,0,
347,12,216,4,0,
347,12,218,4,0,
347,12,232,1,25,
347,12,237,4,0,
347,12,241,4,0,
347,12,246,1,37,
347,12,249,4,0,
347,12,263,4,0,
347,12,280,4,0,
347,12,290,4,0,
347,12,300,1,13,
347,12,317,4,0,
347,12,332,4,0,
347,12,350,1,55,
347,12,352,4,0,
347,13,10,1,1,
347,13,15,4,0,
347,13,34,3,0,
347,13,38,3,0,
347,13,55,1,19,
347,13,91,4,0,
347,13,92,4,0,
347,13,102,3,0,
347,13,104,4,0,
347,13,106,1,7,
347,13,156,4,0,
347,13,163,1,49,
347,13,164,3,0,
347,13,182,1,31,
347,13,182,4,0,
347,13,201,4,0,
347,13,207,3,0,
347,13,210,1,43,
347,13,213,4,0,
347,13,216,4,0,
347,13,218,4,0,
347,13,232,1,25,
347,13,237,4,0,
347,13,241,4,0,
347,13,246,1,37,
347,13,249,4,0,
347,13,263,4,0,
347,13,280,4,0,
347,13,290,4,0,
347,13,300,1,13,
347,13,317,4,0,
347,13,332,4,0,
347,13,350,1,55,
347,13,352,4,0,
347,14,10,1,1,1
347,14,14,4,0,
347,14,15,4,0,
347,14,28,2,0,
347,14,55,1,13,
347,14,91,4,0,
347,14,92,4,0,
347,14,103,2,0,
347,14,104,4,0,
347,14,106,1,1,2
347,14,156,4,0,
347,14,157,4,0,
347,14,163,1,43,
347,14,164,4,0,
347,14,173,3,0,
347,14,174,2,0,
347,14,182,1,25,
347,14,182,4,0,
347,14,201,4,0,
347,14,206,4,0,
347,14,207,4,0,
347,14,210,1,37,
347,14,213,4,0,
347,14,214,3,0,
347,14,216,4,0,
347,14,218,4,0,
347,14,229,2,0,
347,14,232,1,19,
347,14,237,4,0,
347,14,241,4,0,
347,14,246,1,31,
347,14,249,4,0,
347,14,263,4,0,
347,14,280,4,0,
347,14,282,2,0,
347,14,282,3,0,
347,14,300,1,7,
347,14,306,1,55,
347,14,317,4,0,
347,14,332,4,0,
347,14,334,2,0,
347,14,334,3,0,
347,14,350,1,49,
347,14,352,2,0,
347,14,397,4,0,
347,14,404,1,61,
347,14,404,4,0,
347,14,414,3,0,
347,14,440,2,0,
347,14,446,3,0,
347,14,450,3,0,
347,14,468,4,0,
347,14,479,4,0,
347,14,496,4,0,
347,14,522,4,0,
347,15,10,1,1,1
347,15,14,4,0,
347,15,15,4,0,
347,15,28,2,0,
347,15,55,1,13,
347,15,91,4,0,
347,15,92,4,0,
347,15,103,2,0,
347,15,104,4,0,
347,15,106,1,1,2
347,15,156,4,0,
347,15,157,4,0,
347,15,163,1,43,
347,15,164,4,0,
347,15,174,2,0,
347,15,182,1,25,
347,15,182,4,0,
347,15,201,4,0,
347,15,206,4,0,
347,15,207,4,0,
347,15,210,1,37,
347,15,213,4,0,
347,15,214,4,0,
347,15,216,4,0,
347,15,218,4,0,
347,15,229,2,0,
347,15,232,1,19,
347,15,237,4,0,
347,15,241,4,0,
347,15,246,1,31,
347,15,249,4,0,
347,15,263,4,0,
347,15,280,4,0,
347,15,282,2,0,
347,15,300,1,7,
347,15,306,1,55,
347,15,317,4,0,
347,15,332,4,0,
347,15,334,2,0,
347,15,350,1,49,
347,15,352,2,0,
347,15,397,4,0,
347,15,404,1,61,
347,15,404,4,0,
347,15,440,2,0,
347,15,453,2,0,
347,15,468,4,0,
347,15,479,4,0,
347,15,496,4,0,
347,15,522,4,0,
347,15,590,4,0,
347,16,10,1,1,1
347,16,14,4,0,
347,16,15,4,0,
347,16,28,2,0,
347,16,55,1,7,1
347,16,91,4,0,
347,16,92,4,0,
347,16,103,2,0,
347,16,104,4,0,
347,16,106,1,1,2
347,16,156,4,0,
347,16,157,4,0,
347,16,163,1,34,1
347,16,164,4,0,
347,16,173,3,0,
347,16,174,2,0,
347,16,182,1,49,1
347,16,182,4,0,
347,16,201,4,0,
347,16,206,4,0,
347,16,207,4,0,
347,16,210,1,10,1
347,16,213,4,0,
347,16,214,4,0,
347,16,216,4,0,
347,16,218,4,0,
347,16,229,2,0,
347,16,232,1,17,1
347,16,237,4,0,
347,16,241,4,0,
347,16,246,1,21,1
347,16,249,4,0,
347,16,263,4,0,
347,16,280,4,0,
347,16,282,2,0,
347,16,282,3,0,
347,16,290,4,0,
347,16,300,1,4,1
347,16,306,1,39,1
347,16,317,4,0,
347,16,332,4,0,
347,16,334,2,0,
347,16,334,3,0,
347,16,350,1,55,1
347,16,352,2,0,
347,16,352,3,0,
347,16,362,1,29,1
347,16,397,4,0,
347,16,404,1,44,1
347,16,404,4,0,
347,16,414,3,0,
347,16,440,2,0,
347,16,446,3,0,
347,16,450,1,25,1
347,16,450,3,0,
347,16,453,2,0,
347,16,468,4,0,
347,16,479,1,13,1
347,16,479,4,0,
347,16,496,4,0,
347,16,522,4,0,
347,16,590,4,0,
347,17,10,1,1,1
347,17,14,4,0,
347,17,28,2,0,
347,17,55,1,7,
347,17,92,4,0,
347,17,103,2,0,
347,17,104,4,0,
347,17,106,1,1,2
347,17,156,4,0,
347,17,157,4,0,
347,17,163,1,34,
347,17,164,4,0,
347,17,174,2,0,
347,17,182,1,49,
347,17,182,4,0,
347,17,201,4,0,
347,17,206,4,0,
347,17,207,4,0,
347,17,210,1,10,
347,17,213,4,0,
347,17,214,4,0,
347,17,216,4,0,
347,17,218,4,0,
347,17,229,2,0,
347,17,232,1,17,
347,17,237,4,0,
347,17,241,4,0,
347,17,246,1,21,
347,17,263,4,0,
347,17,280,4,0,
347,17,282,2,0,
347,17,300,1,4,
347,17,306,1,39,
347,17,317,4,0,
347,17,332,4,0,
347,17,334,2,0,
347,17,350,1,55,
347,17,352,2,0,
347,17,362,1,29,
347,17,397,4,0,
347,17,404,1,44,
347,17,404,4,0,
347,17,440,2,0,
347,17,450,1,25,
347,17,453,2,0,
347,17,479,1,13,
347,17,479,4,0,
347,17,496,4,0,
347,17,590,4,0,
347,18,10,1,1,1
347,18,14,4,0,
347,18,28,2,0,
347,18,55,1,7,
347,18,92,4,0,
347,18,103,2,0,
347,18,104,4,0,
347,18,106,1,1,2
347,18,156,4,0,
347,18,157,4,0,
347,18,163,1,34,
347,18,164,4,0,
347,18,174,2,0,
347,18,182,1,49,
347,18,182,4,0,
347,18,201,4,0,
347,18,206,4,0,
347,18,207,4,0,
347,18,210,1,10,
347,18,213,4,0,
347,18,214,4,0,
347,18,216,4,0,
347,18,218,4,0,
347,18,229,2,0,
347,18,232,1,17,
347,18,237,4,0,
347,18,241,4,0,
347,18,246,1,21,
347,18,263,4,0,
347,18,280,4,0,
347,18,282,2,0,
347,18,300,1,4,
347,18,306,1,39,
347,18,317,4,0,
347,18,332,4,0,
347,18,334,2,0,
347,18,350,1,55,
347,18,352,2,0,
347,18,362,1,29,
347,18,397,4,0,
347,18,404,1,44,
347,18,404,4,0,
347,18,440,2,0,
347,18,450,1,25,
347,18,453,2,0,
347,18,479,1,13,
347,18,479,4,0,
347,18,496,4,0,
347,18,590,4,0,
348,5,10,1,1,1
348,5,15,4,0,
348,5,55,1,1,4
348,5,55,1,19,
348,5,63,4,0,
348,5,70,4,0,
348,5,89,4,0,
348,5,91,4,0,
348,5,92,4,0,
348,5,104,4,0,
348,5,106,1,1,2
348,5,106,1,7,
348,5,156,4,0,
348,5,163,1,55,
348,5,182,1,31,
348,5,182,4,0,
348,5,201,4,0,
348,5,210,1,46,
348,5,213,4,0,
348,5,216,4,0,
348,5,218,4,0,
348,5,231,4,0,
348,5,232,1,25,
348,5,237,4,0,
348,5,241,4,0,
348,5,246,1,37,
348,5,249,4,0,
348,5,263,4,0,
348,5,280,4,0,
348,5,290,4,0,
348,5,300,1,1,3
348,5,300,1,13,
348,5,317,4,0,
348,5,332,4,0,
348,5,350,1,64,
348,5,352,4,0,
348,6,10,1,1,1
348,6,14,3,0,
348,6,15,4,0,
348,6,34,3,0,
348,6,38,3,0,
348,6,55,1,1,4
348,6,55,1,19,
348,6,63,4,0,
348,6,69,3,0,
348,6,70,4,0,
348,6,89,4,0,
348,6,91,4,0,
348,6,92,4,0,
348,6,102,3,0,
348,6,104,4,0,
348,6,106,1,1,2
348,6,106,1,7,
348,6,156,4,0,
348,6,157,3,0,
348,6,163,1,55,
348,6,164,3,0,
348,6,173,3,0,
348,6,182,1,31,
348,6,182,4,0,
348,6,189,3,0,
348,6,201,4,0,
348,6,203,3,0,
348,6,207,3,0,
348,6,210,1,46,
348,6,210,3,0,
348,6,213,4,0,
348,6,214,3,0,
348,6,216,4,0,
348,6,218,4,0,
348,6,231,4,0,
348,6,232,1,25,
348,6,237,4,0,
348,6,241,4,0,
348,6,246,1,37,
348,6,249,4,0,
348,6,263,4,0,
348,6,280,4,0,
348,6,290,4,0,
348,6,300,1,1,3
348,6,300,1,13,
348,6,317,4,0,
348,6,332,4,0,
348,6,350,1,64,
348,6,352,4,0,
348,7,10,1,1,1
348,7,14,3,0,
348,7,15,4,0,
348,7,34,3,0,
348,7,38,3,0,
348,7,55,1,1,4
348,7,55,1,19,
348,7,63,4,0,
348,7,69,3,0,
348,7,70,4,0,
348,7,89,4,0,
348,7,91,4,0,
348,7,92,4,0,
348,7,102,3,0,
348,7,104,4,0,
348,7,106,1,1,2
348,7,106,1,7,
348,7,156,4,0,
348,7,157,3,0,
348,7,163,1,55,
348,7,164,3,0,
348,7,182,1,31,
348,7,182,4,0,
348,7,201,4,0,
348,7,210,1,46,
348,7,213,4,0,
348,7,216,4,0,
348,7,218,4,0,
348,7,231,4,0,
348,7,232,1,25,
348,7,237,4,0,
348,7,241,4,0,
348,7,246,1,37,
348,7,249,4,0,
348,7,263,4,0,
348,7,280,4,0,
348,7,290,4,0,
348,7,300,1,1,3
348,7,300,1,13,
348,7,317,4,0,
348,7,332,4,0,
348,7,350,1,64,
348,7,352,4,0,
348,8,10,1,1,1
348,8,14,4,0,
348,8,15,4,0,
348,8,55,1,1,4
348,8,55,1,13,
348,8,63,4,0,
348,8,70,4,0,
348,8,89,4,0,
348,8,91,4,0,
348,8,92,4,0,
348,8,104,4,0,
348,8,106,1,1,2
348,8,156,4,0,
348,8,157,4,0,
348,8,163,1,46,
348,8,164,4,0,
348,8,182,1,25,
348,8,182,4,0,
348,8,201,4,0,
348,8,203,4,0,
348,8,206,4,0,
348,8,207,4,0,
348,8,210,1,37,
348,8,213,4,0,
348,8,214,4,0,
348,8,216,4,0,
348,8,218,4,0,
348,8,231,4,0,
348,8,232,1,19,
348,8,237,4,0,
348,8,241,4,0,
348,8,246,1,31,
348,8,249,4,0,
348,8,263,4,0,
348,8,280,4,0,
348,8,290,4,0,
348,8,300,1,1,3
348,8,300,1,7,
348,8,306,1,67,
348,8,317,4,0,
348,8,332,4,0,
348,8,350,1,55,
348,8,352,4,0,
348,8,363,4,0,
348,8,397,4,0,
348,8,404,1,73,
348,8,404,4,0,
348,8,416,4,0,
348,8,430,4,0,
348,8,444,4,0,
348,8,445,4,0,
348,8,446,4,0,
348,9,10,1,1,1
348,9,14,4,0,
348,9,15,4,0,
348,9,55,1,1,4
348,9,55,1,13,
348,9,63,4,0,
348,9,70,4,0,
348,9,89,4,0,
348,9,91,4,0,
348,9,92,4,0,
348,9,104,4,0,
348,9,106,1,1,2
348,9,156,4,0,
348,9,157,4,0,
348,9,163,1,46,
348,9,164,4,0,
348,9,173,3,0,
348,9,182,1,25,
348,9,182,4,0,
348,9,189,3,0,
348,9,201,4,0,
348,9,203,4,0,
348,9,206,4,0,
348,9,207,4,0,
348,9,210,1,37,
348,9,210,3,0,
348,9,213,4,0,
348,9,214,4,0,
348,9,216,4,0,
348,9,218,4,0,
348,9,231,4,0,
348,9,232,1,19,
348,9,237,4,0,
348,9,241,4,0,
348,9,246,1,31,
348,9,246,3,0,
348,9,249,4,0,
348,9,263,4,0,
348,9,276,3,0,
348,9,280,4,0,
348,9,282,3,0,
348,9,290,4,0,
348,9,300,1,1,3
348,9,300,1,7,
348,9,306,1,67,
348,9,317,4,0,
348,9,332,4,0,
348,9,334,3,0,
348,9,350,1,55,
348,9,352,4,0,
348,9,363,4,0,
348,9,397,4,0,
348,9,401,3,0,
348,9,404,1,73,
348,9,404,4,0,
348,9,414,3,0,
348,9,416,4,0,
348,9,430,4,0,
348,9,444,4,0,
348,9,445,4,0,
348,9,446,4,0,
348,10,10,1,1,1
348,10,14,4,0,
348,10,15,4,0,
348,10,29,3,0,
348,10,55,1,1,4
348,10,55,1,13,
348,10,63,4,0,
348,10,67,3,0,
348,10,70,4,0,
348,10,81,3,0,
348,10,89,4,0,
348,10,91,4,0,
348,10,92,4,0,
348,10,104,4,0,
348,10,106,1,1,2
348,10,156,4,0,
348,10,157,4,0,
348,10,163,1,46,
348,10,164,4,0,
348,10,173,3,0,
348,10,182,1,25,
348,10,182,4,0,
348,10,189,3,0,
348,10,201,4,0,
348,10,203,4,0,
348,10,206,4,0,
348,10,207,4,0,
348,10,210,1,37,
348,10,210,3,0,
348,10,213,4,0,
348,10,214,4,0,
348,10,216,4,0,
348,10,218,4,0,
348,10,231,4,0,
348,10,232,1,19,
348,10,237,4,0,
348,10,241,4,0,
348,10,246,1,31,
348,10,246,3,0,
348,10,249,4,0,
348,10,263,4,0,
348,10,276,3,0,
348,10,280,4,0,
348,10,282,3,0,
348,10,290,4,0,
348,10,300,1,1,3
348,10,300,1,7,
348,10,306,1,67,
348,10,317,4,0,
348,10,332,4,0,
348,10,334,3,0,
348,10,335,3,0,
348,10,350,1,55,
348,10,352,4,0,
348,10,363,4,0,
348,10,397,4,0,
348,10,401,3,0,
348,10,404,1,73,
348,10,404,4,0,
348,10,414,3,0,
348,10,416,4,0,
348,10,430,4,0,
348,10,444,4,0,
348,10,445,4,0,
348,10,446,4,0,
348,11,10,1,1,1
348,11,14,4,0,
348,11,15,4,0,
348,11,55,1,1,4
348,11,55,1,13,
348,11,63,4,0,
348,11,70,4,0,
348,11,89,4,0,
348,11,91,4,0,
348,11,92,4,0,
348,11,104,4,0,
348,11,106,1,1,2
348,11,156,4,0,
348,11,157,4,0,
348,11,163,1,46,
348,11,164,4,0,
348,11,182,1,25,
348,11,182,4,0,
348,11,201,4,0,
348,11,206,4,0,
348,11,207,4,0,
348,11,210,1,37,
348,11,213,4,0,
348,11,216,4,0,
348,11,218,4,0,
348,11,232,1,19,
348,11,237,4,0,
348,11,241,4,0,
348,11,246,1,31,
348,11,249,4,0,
348,11,263,4,0,
348,11,280,4,0,
348,11,300,1,1,3
348,11,300,1,7,
348,11,306,1,67,
348,11,317,4,0,
348,11,332,4,0,
348,11,350,1,55,
348,11,397,4,0,
348,11,404,1,73,
348,11,404,4,0,
348,11,416,4,0,
348,11,430,4,0,
348,11,444,4,0,
348,11,468,4,0,
348,11,479,4,0,
348,11,496,4,0,
348,11,522,4,0,
348,11,523,4,0,
348,12,10,1,1,1
348,12,15,4,0,
348,12,55,1,1,4
348,12,55,1,19,
348,12,63,4,0,
348,12,70,4,0,
348,12,89,4,0,
348,12,91,4,0,
348,12,92,4,0,
348,12,104,4,0,
348,12,106,1,1,2
348,12,106,1,7,
348,12,156,4,0,
348,12,163,1,55,
348,12,182,1,31,
348,12,182,4,0,
348,12,201,4,0,
348,12,210,1,46,
348,12,213,4,0,
348,12,216,4,0,
348,12,218,4,0,
348,12,231,4,0,
348,12,232,1,25,
348,12,237,4,0,
348,12,241,4,0,
348,12,246,1,37,
348,12,249,4,0,
348,12,263,4,0,
348,12,280,4,0,
348,12,290,4,0,
348,12,300,1,1,3
348,12,300,1,13,
348,12,317,4,0,
348,12,332,4,0,
348,12,350,1,64,
348,12,352,4,0,
348,13,10,1,1,1
348,13,15,4,0,
348,13,34,3,0,
348,13,38,3,0,
348,13,55,1,1,4
348,13,55,1,19,
348,13,63,4,0,
348,13,69,3,0,
348,13,70,4,0,
348,13,89,4,0,
348,13,91,4,0,
348,13,92,4,0,
348,13,102,3,0,
348,13,104,4,0,
348,13,106,1,1,2
348,13,106,1,7,
348,13,156,4,0,
348,13,163,1,55,
348,13,164,3,0,
348,13,182,1,31,
348,13,182,4,0,
348,13,201,4,0,
348,13,207,3,0,
348,13,210,1,46,
348,13,213,4,0,
348,13,216,4,0,
348,13,218,4,0,
348,13,231,4,0,
348,13,232,1,25,
348,13,237,4,0,
348,13,241,4,0,
348,13,246,1,37,
348,13,249,4,0,
348,13,263,4,0,
348,13,280,4,0,
348,13,290,4,0,
348,13,300,1,1,3
348,13,300,1,13,
348,13,317,4,0,
348,13,332,4,0,
348,13,350,1,64,
348,13,352,4,0,
348,14,10,1,1,1
348,14,14,4,0,
348,14,15,4,0,
348,14,55,1,1,4
348,14,55,1,13,
348,14,63,4,0,
348,14,67,3,0,
348,14,70,4,0,
348,14,89,4,0,
348,14,91,4,0,
348,14,92,4,0,
348,14,104,4,0,
348,14,106,1,1,2
348,14,156,4,0,
348,14,157,4,0,
348,14,163,1,46,
348,14,164,4,0,
348,14,173,3,0,
348,14,182,1,25,
348,14,182,4,0,
348,14,201,4,0,
348,14,206,4,0,
348,14,207,4,0,
348,14,210,1,37,
348,14,213,4,0,
348,14,214,3,0,
348,14,216,4,0,
348,14,218,4,0,
348,14,231,3,0,
348,14,232,1,19,
348,14,237,4,0,
348,14,241,4,0,
348,14,246,1,31,
348,14,249,4,0,
348,14,263,4,0,
348,14,276,3,0,
348,14,280,4,0,
348,14,282,3,0,
348,14,300,1,1,3
348,14,300,1,7,
348,14,306,1,67,
348,14,317,4,0,
348,14,332,4,0,
348,14,334,3,0,
348,14,335,3,0,
348,14,350,1,55,
348,14,397,4,0,
348,14,401,3,0,
348,14,404,1,73,
348,14,404,4,0,
348,14,414,3,0,
348,14,416,4,0,
348,14,430,4,0,
348,14,444,4,0,
348,14,446,3,0,
348,14,450,3,0,
348,14,468,4,0,
348,14,479,4,0,
348,14,496,4,0,
348,14,522,4,0,
348,14,523,4,0,
348,15,10,1,1,2
348,15,14,4,0,
348,15,15,4,0,
348,15,55,1,1,5
348,15,55,1,13,
348,15,63,4,0,
348,15,70,4,0,
348,15,89,4,0,
348,15,91,4,0,
348,15,92,4,0,
348,15,104,4,0,
348,15,106,1,1,3
348,15,156,4,0,
348,15,157,4,0,
348,15,163,1,46,
348,15,164,4,0,
348,15,182,1,25,
348,15,182,4,0,
348,15,201,4,0,
348,15,206,4,0,
348,15,207,4,0,
348,15,210,1,37,
348,15,213,4,0,
348,15,214,4,0,
348,15,216,4,0,
348,15,218,4,0,
348,15,232,1,19,
348,15,237,4,0,
348,15,241,4,0,
348,15,246,1,31,
348,15,249,4,0,
348,15,263,4,0,
348,15,280,4,0,
348,15,300,1,1,4
348,15,300,1,7,
348,15,306,1,1,1
348,15,306,1,67,
348,15,317,4,0,
348,15,332,4,0,
348,15,350,1,55,
348,15,397,4,0,
348,15,404,1,73,
348,15,404,4,0,
348,15,416,4,0,
348,15,430,4,0,
348,15,444,4,0,
348,15,468,4,0,
348,15,479,4,0,
348,15,496,4,0,
348,15,522,4,0,
348,15,523,4,0,
348,15,590,4,0,
348,16,10,1,1,1
348,16,14,4,0,
348,16,15,4,0,
348,16,55,1,1,4
348,16,55,1,7,1
348,16,63,4,0,
348,16,67,3,0,
348,16,70,4,0,
348,16,89,4,0,
348,16,91,4,0,
348,16,92,4,0,
348,16,104,4,0,
348,16,106,1,1,2
348,16,156,4,0,
348,16,157,4,0,
348,16,163,1,25,1
348,16,163,1,34,1
348,16,164,4,0,
348,16,173,3,0,
348,16,182,1,53,1
348,16,182,4,0,
348,16,201,4,0,
348,16,206,4,0,
348,16,207,4,0,
348,16,210,1,10,1
348,16,213,4,0,
348,16,214,4,0,
348,16,216,4,0,
348,16,218,4,0,
348,16,231,3,0,
348,16,232,1,17,1
348,16,237,4,0,
348,16,241,4,0,
348,16,246,1,21,1
348,16,249,4,0,
348,16,263,4,0,
348,16,276,3,0,
348,16,280,4,0,
348,16,282,3,0,
348,16,290,4,0,
348,16,300,1,1,3
348,16,300,1,4,1
348,16,306,1,39,1
348,16,317,4,0,
348,16,332,4,0,
348,16,334,3,0,
348,16,335,3,0,
348,16,350,1,61,1
348,16,352,3,0,
348,16,362,1,29,1
348,16,397,4,0,
348,16,401,3,0,
348,16,404,1,46,1
348,16,404,4,0,
348,16,414,3,0,
348,16,416,4,0,
348,16,430,4,0,
348,16,444,4,0,
348,16,446,3,0,
348,16,450,3,0,
348,16,468,4,0,
348,16,479,1,13,1
348,16,479,4,0,
348,16,496,4,0,
348,16,522,4,0,
348,16,523,4,0,
348,16,590,4,0,
348,17,10,1,1,1
348,17,14,4,0,
348,17,55,1,1,4
348,17,55,1,7,
348,17,63,4,0,
348,17,89,4,0,
348,17,92,4,0,
348,17,104,4,0,
348,17,106,1,1,2
348,17,156,4,0,
348,17,157,4,0,
348,17,163,1,25,
348,17,163,1,34,
348,17,164,4,0,
348,17,182,1,53,
348,17,182,4,0,
348,17,201,4,0,
348,17,206,4,0,
348,17,207,4,0,
348,17,210,1,10,
348,17,213,4,0,
348,17,214,4,0,
348,17,216,4,0,
348,17,218,4,0,
348,17,232,1,17,
348,17,237,4,0,
348,17,241,4,0,
348,17,246,1,21,
348,17,263,4,0,
348,17,280,4,0,
348,17,300,1,1,3
348,17,300,1,4,
348,17,306,1,39,
348,17,317,4,0,
348,17,332,4,0,
348,17,350,1,61,
348,17,362,1,29,
348,17,397,4,0,
348,17,404,1,46,
348,17,404,4,0,
348,17,416,4,0,
348,17,430,4,0,
348,17,444,4,0,
348,17,479,1,13,
348,17,479,4,0,
348,17,496,4,0,
348,17,523,4,0,
348,17,590,4,0,
348,17,693,4,0,
348,18,10,1,1,1
348,18,14,4,0,
348,18,55,1,1,4
348,18,55,1,7,
348,18,63,4,0,
348,18,89,4,0,
348,18,92,4,0,
348,18,104,4,0,
348,18,106,1,1,2
348,18,156,4,0,
348,18,157,4,0,
348,18,163,1,34,
348,18,164,4,0,
348,18,182,1,53,
348,18,182,4,0,
348,18,201,4,0,
348,18,206,4,0,
348,18,207,4,0,
348,18,210,1,10,
348,18,213,4,0,
348,18,214,4,0,
348,18,216,4,0,
348,18,218,4,0,
348,18,232,1,17,
348,18,237,4,0,
348,18,241,4,0,
348,18,246,1,21,
348,18,263,4,0,
348,18,280,4,0,
348,18,300,1,1,3
348,18,300,1,4,
348,18,306,1,39,
348,18,317,4,0,
348,18,332,4,0,
348,18,350,1,61,
348,18,362,1,29,
348,18,397,4,0,
348,18,404,1,46,
348,18,404,4,0,
348,18,416,4,0,
348,18,430,4,0,
348,18,444,4,0,
348,18,450,1,25,
348,18,479,1,13,
348,18,479,4,0,
348,18,496,4,0,
348,18,523,4,0,
348,18,590,4,0,
348,18,693,4,0,
349,5,33,1,15,
349,5,57,4,0,
349,5,58,4,0,
349,5,59,4,0,
349,5,92,4,0,
349,5,95,2,0,
349,5,104,4,0,
349,5,109,2,0,
349,5,113,2,0,
349,5,127,4,0,
349,5,150,1,1,
349,5,156,4,0,
349,5,175,1,30,
349,5,182,4,0,
349,5,213,4,0,
349,5,216,4,0,
349,5,218,4,0,
349,5,225,2,0,
349,5,237,4,0,
349,5,240,4,0,
349,5,243,2,0,
349,5,258,4,0,
349,5,263,4,0,
349,5,290,4,0,
349,5,291,4,0,
349,5,300,2,0,
349,5,352,4,0,
349,6,33,1,15,
349,6,38,3,0,
349,6,57,4,0,
349,6,58,4,0,
349,6,59,4,0,
349,6,92,4,0,
349,6,95,2,0,
349,6,102,3,0,
349,6,104,4,0,
349,6,109,2,0,
349,6,113,2,0,
349,6,127,4,0,
349,6,129,3,0,
349,6,150,1,1,
349,6,156,4,0,
349,6,164,3,0,
349,6,173,3,0,
349,6,175,1,30,
349,6,182,4,0,
349,6,196,3,0,
349,6,203,3,0,
349,6,207,3,0,
349,6,213,4,0,
349,6,214,3,0,
349,6,216,4,0,
349,6,218,4,0,
349,6,225,2,0,
349,6,237,4,0,
349,6,240,4,0,
349,6,243,2,0,
349,6,258,4,0,
349,6,263,4,0,
349,6,290,4,0,
349,6,291,4,0,
349,6,300,2,0,
349,6,352,4,0,
349,7,33,1,15,
349,7,38,3,0,
349,7,57,4,0,
349,7,58,4,0,
349,7,59,4,0,
349,7,92,4,0,
349,7,95,2,0,
349,7,102,3,0,
349,7,104,4,0,
349,7,109,2,0,
349,7,113,2,0,
349,7,127,4,0,
349,7,150,1,1,
349,7,156,4,0,
349,7,164,3,0,
349,7,175,1,30,
349,7,182,4,0,
349,7,213,4,0,
349,7,216,4,0,
349,7,218,4,0,
349,7,225,2,0,
349,7,237,4,0,
349,7,240,4,0,
349,7,243,2,0,
349,7,258,4,0,
349,7,263,4,0,
349,7,290,4,0,
349,7,291,4,0,
349,7,300,2,0,
349,7,352,4,0,
349,8,33,1,15,
349,8,54,2,0,
349,8,57,4,0,
349,8,58,4,0,
349,8,59,4,0,
349,8,92,4,0,
349,8,95,2,0,
349,8,104,4,0,
349,8,109,2,0,
349,8,113,2,0,
349,8,114,2,0,
349,8,127,4,0,
349,8,150,1,1,
349,8,156,4,0,
349,8,164,4,0,
349,8,175,1,30,
349,8,182,4,0,
349,8,203,4,0,
349,8,207,4,0,
349,8,213,4,0,
349,8,214,4,0,
349,8,216,4,0,
349,8,218,4,0,
349,8,225,2,0,
349,8,237,4,0,
349,8,240,4,0,
349,8,243,2,0,
349,8,258,4,0,
349,8,263,4,0,
349,8,290,4,0,
349,8,300,2,0,
349,8,321,2,0,
349,8,352,4,0,
349,8,363,4,0,
349,8,445,4,0,
349,9,33,1,15,
349,9,54,2,0,
349,9,57,4,0,
349,9,58,4,0,
349,9,59,4,0,
349,9,92,4,0,
349,9,95,2,0,
349,9,104,4,0,
349,9,109,2,0,
349,9,113,2,0,
349,9,114,2,0,
349,9,127,4,0,
349,9,129,3,0,
349,9,150,1,1,
349,9,156,4,0,
349,9,164,4,0,
349,9,173,3,0,
349,9,175,1,30,
349,9,182,4,0,
349,9,196,3,0,
349,9,203,4,0,
349,9,207,4,0,
349,9,213,4,0,
349,9,214,4,0,
349,9,216,4,0,
349,9,218,4,0,
349,9,225,2,0,
349,9,237,4,0,
349,9,240,4,0,
349,9,243,2,0,
349,9,258,4,0,
349,9,263,4,0,
349,9,290,4,0,
349,9,291,3,0,
349,9,300,2,0,
349,9,321,2,0,
349,9,352,4,0,
349,9,363,4,0,
349,9,445,4,0,
349,10,33,1,15,
349,10,54,2,0,
349,10,57,4,0,
349,10,58,4,0,
349,10,59,4,0,
349,10,92,4,0,
349,10,95,2,0,
349,10,104,4,0,
349,10,109,2,0,
349,10,113,2,0,
349,10,114,2,0,
349,10,127,4,0,
349,10,129,3,0,
349,10,150,1,1,
349,10,156,4,0,
349,10,164,4,0,
349,10,173,3,0,
349,10,175,1,30,
349,10,182,4,0,
349,10,196,3,0,
349,10,203,4,0,
349,10,207,4,0,
349,10,213,4,0,
349,10,214,4,0,
349,10,216,4,0,
349,10,218,4,0,
349,10,225,2,0,
349,10,237,4,0,
349,10,240,4,0,
349,10,243,2,0,
349,10,250,4,0,
349,10,258,4,0,
349,10,263,4,0,
349,10,290,4,0,
349,10,291,3,0,
349,10,300,2,0,
349,10,321,2,0,
349,10,352,4,0,
349,10,363,4,0,
349,10,445,4,0,
349,11,33,1,15,
349,11,54,2,0,
349,11,57,4,0,
349,11,58,4,0,
349,11,59,4,0,
349,11,92,4,0,
349,11,95,2,0,
349,11,104,4,0,
349,11,109,2,0,
349,11,113,4,0,
349,11,114,2,0,
349,11,127,4,0,
349,11,150,1,1,
349,11,156,4,0,
349,11,164,4,0,
349,11,175,1,30,
349,11,182,4,0,
349,11,207,4,0,
349,11,213,4,0,
349,11,216,4,0,
349,11,218,4,0,
349,11,225,2,0,
349,11,231,2,0,
349,11,237,4,0,
349,11,240,4,0,
349,11,243,2,0,
349,11,258,4,0,
349,11,263,4,0,
349,11,291,4,0,
349,11,300,2,0,
349,11,321,2,0,
349,11,362,2,0,
349,11,406,2,0,
349,11,445,2,0,
349,11,496,4,0,
349,11,503,4,0,
349,12,33,1,15,
349,12,57,4,0,
349,12,58,4,0,
349,12,59,4,0,
349,12,92,4,0,
349,12,104,4,0,
349,12,127,4,0,
349,12,150,1,1,
349,12,156,4,0,
349,12,175,1,30,
349,12,182,4,0,
349,12,213,4,0,
349,12,216,4,0,
349,12,218,4,0,
349,12,237,4,0,
349,12,240,4,0,
349,12,258,4,0,
349,12,263,4,0,
349,12,290,4,0,
349,12,291,4,0,
349,12,352,4,0,
349,13,33,1,15,
349,13,38,3,0,
349,13,57,4,0,
349,13,58,4,0,
349,13,59,4,0,
349,13,92,4,0,
349,13,102,3,0,
349,13,104,4,0,
349,13,127,4,0,
349,13,150,1,1,
349,13,156,4,0,
349,13,164,3,0,
349,13,175,1,30,
349,13,182,4,0,
349,13,196,3,0,
349,13,207,3,0,
349,13,213,4,0,
349,13,216,4,0,
349,13,218,4,0,
349,13,237,4,0,
349,13,240,4,0,
349,13,258,4,0,
349,13,263,4,0,
349,13,290,4,0,
349,13,291,4,0,
349,13,352,4,0,
349,14,33,1,15,
349,14,54,2,0,
349,14,57,4,0,
349,14,58,4,0,
349,14,59,4,0,
349,14,92,4,0,
349,14,95,2,0,
349,14,104,4,0,
349,14,109,2,0,
349,14,113,4,0,
349,14,114,2,0,
349,14,127,4,0,
349,14,150,1,1,
349,14,156,4,0,
349,14,164,4,0,
349,14,173,3,0,
349,14,175,1,30,
349,14,182,4,0,
349,14,196,3,0,
349,14,207,4,0,
349,14,213,4,0,
349,14,214,3,0,
349,14,216,4,0,
349,14,218,4,0,
349,14,225,2,0,
349,14,231,2,0,
349,14,231,3,0,
349,14,237,4,0,
349,14,240,4,0,
349,14,243,2,0,
349,14,258,4,0,
349,14,263,4,0,
349,14,291,4,0,
349,14,300,2,0,
349,14,321,2,0,
349,14,362,2,0,
349,14,406,2,0,
349,14,406,3,0,
349,14,445,2,0,
349,14,496,4,0,
349,14,503,4,0,
349,15,33,1,15,
349,15,54,2,0,
349,15,57,4,0,
349,15,58,4,0,
349,15,59,4,0,
349,15,92,4,0,
349,15,95,2,0,
349,15,104,4,0,
349,15,109,2,0,
349,15,113,4,0,
349,15,114,2,0,
349,15,127,4,0,
349,15,150,1,1,
349,15,156,4,0,
349,15,164,4,0,
349,15,175,1,30,
349,15,182,4,0,
349,15,207,4,0,
349,15,213,4,0,
349,15,214,4,0,
349,15,216,4,0,
349,15,218,4,0,
349,15,225,2,0,
349,15,231,2,0,
349,15,237,4,0,
349,15,240,4,0,
349,15,243,2,0,
349,15,258,4,0,
349,15,263,4,0,
349,15,300,2,0,
349,15,321,2,0,
349,15,362,2,0,
349,15,406,2,0,
349,15,445,2,0,
349,15,496,4,0,
349,15,503,4,0,
349,15,590,4,0,
349,16,33,1,15,1
349,16,54,2,0,
349,16,57,4,0,
349,16,58,4,0,
349,16,59,4,0,
349,16,92,4,0,
349,16,95,2,0,
349,16,104,4,0,
349,16,109,2,0,
349,16,113,4,0,
349,16,114,2,0,
349,16,127,4,0,
349,16,150,1,1,1
349,16,156,4,0,
349,16,164,4,0,
349,16,173,3,0,
349,16,175,1,30,1
349,16,182,4,0,
349,16,196,3,0,
349,16,207,4,0,
349,16,213,4,0,
349,16,214,4,0,
349,16,216,4,0,
349,16,218,4,0,
349,16,225,2,0,
349,16,231,2,0,
349,16,231,3,0,
349,16,237,4,0,
349,16,240,4,0,
349,16,243,2,0,
349,16,258,4,0,
349,16,263,4,0,
349,16,290,4,0,
349,16,291,4,0,
349,16,300,2,0,
349,16,321,2,0,
349,16,352,3,0,
349,16,362,2,0,
349,16,406,2,0,
349,16,406,3,0,
349,16,445,2,0,
349,16,496,4,0,
349,16,503,4,0,
349,16,590,4,0,
349,17,33,1,15,
349,17,54,2,0,
349,17,57,4,0,
349,17,58,4,0,
349,17,59,4,0,
349,17,92,4,0,
349,17,95,2,0,
349,17,104,4,0,
349,17,109,2,0,
349,17,113,4,0,
349,17,114,2,0,
349,17,127,4,0,
349,17,150,1,1,
349,17,156,4,0,
349,17,164,4,0,
349,17,175,1,30,
349,17,182,4,0,
349,17,207,4,0,
349,17,213,4,0,
349,17,214,4,0,
349,17,216,4,0,
349,17,218,4,0,
349,17,225,2,0,
349,17,231,2,0,
349,17,237,4,0,
349,17,240,4,0,
349,17,243,2,0,
349,17,258,4,0,
349,17,263,4,0,
349,17,300,2,0,
349,17,321,2,0,
349,17,362,2,0,
349,17,406,2,0,
349,17,445,2,0,
349,17,496,4,0,
349,17,503,4,0,
349,17,590,4,0,
349,18,33,1,15,
349,18,54,2,0,
349,18,57,4,0,
349,18,58,4,0,
349,18,59,4,0,
349,18,92,4,0,
349,18,95,2,0,
349,18,104,4,0,
349,18,109,2,0,
349,18,113,4,0,
349,18,114,2,0,
349,18,127,4,0,
349,18,150,1,1,
349,18,156,4,0,
349,18,164,4,0,
349,18,175,1,30,
349,18,182,4,0,
349,18,207,4,0,
349,18,213,4,0,
349,18,214,4,0,
349,18,216,4,0,
349,18,218,4,0,
349,18,225,2,0,
349,18,231,2,0,
349,18,237,4,0,
349,18,240,4,0,
349,18,243,2,0,
349,18,258,4,0,
349,18,263,4,0,
349,18,300,2,0,
349,18,321,2,0,
349,18,362,2,0,
349,18,406,2,0,
349,18,445,2,0,
349,18,496,4,0,
349,18,503,4,0,
349,18,590,4,0,
350,5,35,1,5,
350,5,55,1,1,
350,5,56,1,40,
350,5,57,4,0,
350,5,58,4,0,
350,5,59,4,0,
350,5,63,4,0,
350,5,92,4,0,
350,5,104,4,0,
350,5,105,1,30,
350,5,127,4,0,
350,5,156,4,0,
350,5,182,4,0,
350,5,213,1,45,
350,5,213,4,0,
350,5,216,4,0,
350,5,218,4,0,
350,5,219,1,50,
350,5,219,4,0,
350,5,231,4,0,
350,5,237,4,0,
350,5,239,1,25,
350,5,240,1,35,
350,5,240,4,0,
350,5,258,4,0,
350,5,263,4,0,
350,5,287,1,15,
350,5,290,4,0,
350,5,291,4,0,
350,5,346,1,10,
350,5,352,1,20,
350,5,352,4,0,
350,6,34,3,0,
350,6,35,1,5,
350,6,38,3,0,
350,6,55,1,1,
350,6,56,1,40,
350,6,57,4,0,
350,6,58,4,0,
350,6,59,4,0,
350,6,63,4,0,
350,6,92,4,0,
350,6,102,3,0,
350,6,104,4,0,
350,6,105,1,30,
350,6,127,4,0,
350,6,129,3,0,
350,6,156,4,0,
350,6,164,3,0,
350,6,173,3,0,
350,6,182,4,0,
350,6,189,3,0,
350,6,196,3,0,
350,6,203,3,0,
350,6,207,3,0,
350,6,213,1,45,
350,6,213,4,0,
350,6,214,3,0,
350,6,216,4,0,
350,6,218,4,0,
350,6,219,1,50,
350,6,219,4,0,
350,6,231,4,0,
350,6,237,4,0,
350,6,239,1,25,
350,6,240,1,35,
350,6,240,4,0,
350,6,244,3,0,
350,6,258,4,0,
350,6,263,4,0,
350,6,287,1,15,
350,6,290,4,0,
350,6,291,4,0,
350,6,346,1,10,
350,6,352,1,20,
350,6,352,4,0,
350,7,34,3,0,
350,7,35,1,5,
350,7,38,3,0,
350,7,55,1,1,
350,7,56,1,40,
350,7,57,4,0,
350,7,58,4,0,
350,7,59,4,0,
350,7,63,4,0,
350,7,92,4,0,
350,7,102,3,0,
350,7,104,4,0,
350,7,105,1,30,
350,7,127,4,0,
350,7,156,4,0,
350,7,164,3,0,
350,7,182,4,0,
350,7,213,1,45,
350,7,213,4,0,
350,7,216,4,0,
350,7,218,4,0,
350,7,219,1,50,
350,7,219,4,0,
350,7,231,4,0,
350,7,237,4,0,
350,7,239,1,25,
350,7,240,1,35,
350,7,240,4,0,
350,7,258,4,0,
350,7,263,4,0,
350,7,287,1,15,
350,7,290,4,0,
350,7,291,4,0,
350,7,346,1,10,
350,7,352,1,20,
350,7,352,4,0,
350,8,35,1,1,2
350,8,55,1,1,1
350,8,56,1,37,
350,8,57,4,0,
350,8,58,4,0,
350,8,59,4,0,
350,8,63,4,0,
350,8,92,4,0,
350,8,104,4,0,
350,8,105,1,21,
350,8,127,4,0,
350,8,156,4,0,
350,8,164,4,0,
350,8,182,4,0,
350,8,203,4,0,
350,8,207,4,0,
350,8,213,1,41,
350,8,213,4,0,
350,8,214,4,0,
350,8,216,4,0,
350,8,218,4,0,
350,8,219,1,45,
350,8,219,4,0,
350,8,231,4,0,
350,8,237,4,0,
350,8,239,1,17,
350,8,240,1,33,
350,8,240,4,0,
350,8,244,4,0,
350,8,258,4,0,
350,8,263,4,0,
350,8,287,1,9,
350,8,290,4,0,
350,8,346,1,5,
350,8,352,1,13,
350,8,352,4,0,
350,8,363,4,0,
350,8,392,1,49,
350,8,401,1,29,
350,8,406,4,0,
350,8,416,4,0,
350,8,419,4,0,
350,8,445,1,25,
350,8,445,4,0,
350,9,35,1,1,2
350,9,55,1,1,1
350,9,56,1,37,
350,9,57,4,0,
350,9,58,4,0,
350,9,59,4,0,
350,9,63,4,0,
350,9,92,4,0,
350,9,104,4,0,
350,9,105,1,21,
350,9,127,4,0,
350,9,129,3,0,
350,9,156,4,0,
350,9,164,4,0,
350,9,173,3,0,
350,9,182,4,0,
350,9,189,3,0,
350,9,196,3,0,
350,9,203,4,0,
350,9,207,4,0,
350,9,213,1,41,
350,9,213,4,0,
350,9,214,4,0,
350,9,216,4,0,
350,9,218,4,0,
350,9,219,1,45,
350,9,219,4,0,
350,9,231,4,0,
350,9,237,4,0,
350,9,239,1,17,
350,9,239,3,0,
350,9,240,1,33,
350,9,240,4,0,
350,9,244,4,0,
350,9,258,4,0,
350,9,263,4,0,
350,9,287,1,9,
350,9,290,4,0,
350,9,291,3,0,
350,9,346,1,5,
350,9,352,1,13,
350,9,352,4,0,
350,9,363,4,0,
350,9,392,1,49,
350,9,401,1,29,
350,9,401,3,0,
350,9,406,4,0,
350,9,416,4,0,
350,9,419,4,0,
350,9,442,3,0,
350,9,445,1,25,
350,9,445,4,0,
350,10,35,1,1,2
350,10,55,1,1,1
350,10,56,1,37,
350,10,57,4,0,
350,10,58,4,0,
350,10,59,4,0,
350,10,63,4,0,
350,10,92,4,0,
350,10,104,4,0,
350,10,105,1,21,
350,10,127,4,0,
350,10,129,3,0,
350,10,156,4,0,
350,10,164,4,0,
350,10,173,3,0,
350,10,182,4,0,
350,10,189,3,0,
350,10,196,3,0,
350,10,203,4,0,
350,10,207,4,0,
350,10,213,1,41,
350,10,213,4,0,
350,10,214,4,0,
350,10,216,4,0,
350,10,218,4,0,
350,10,219,1,45,
350,10,219,4,0,
350,10,231,4,0,
350,10,237,4,0,
350,10,239,1,17,
350,10,239,3,0,
350,10,240,1,33,
350,10,240,4,0,
350,10,244,4,0,
350,10,250,4,0,
350,10,258,4,0,
350,10,263,4,0,
350,10,277,3,0,
350,10,287,1,9,
350,10,290,4,0,
350,10,291,3,0,
350,10,346,1,5,
350,10,352,1,13,
350,10,352,4,0,
350,10,363,4,0,
350,10,392,1,49,
350,10,401,1,29,
350,10,401,3,0,
350,10,406,4,0,
350,10,416,4,0,
350,10,419,4,0,
350,10,442,3,0,
350,10,445,1,25,
350,10,445,4,0,
350,11,35,1,1,2
350,11,55,1,1,1
350,11,56,1,37,
350,11,57,4,0,
350,11,58,4,0,
350,11,59,4,0,
350,11,63,4,0,
350,11,92,4,0,
350,11,104,4,0,
350,11,105,1,21,
350,11,113,4,0,
350,11,127,4,0,
350,11,156,4,0,
350,11,164,4,0,
350,11,182,4,0,
350,11,207,4,0,
350,11,213,1,41,
350,11,213,4,0,
350,11,216,4,0,
350,11,218,4,0,
350,11,219,1,45,
350,11,219,4,0,
350,11,237,4,0,
350,11,239,1,17,
350,11,240,1,33,
350,11,240,4,0,
350,11,244,4,0,
350,11,258,4,0,
350,11,263,4,0,
350,11,287,1,9,
350,11,291,4,0,
350,11,346,1,5,
350,11,352,1,13,
350,11,392,1,49,
350,11,401,1,29,
350,11,416,4,0,
350,11,445,1,25,
350,11,496,4,0,
350,11,503,4,0,
350,11,523,4,0,
350,11,525,4,0,
350,12,35,1,5,
350,12,55,1,1,
350,12,56,1,40,
350,12,57,4,0,
350,12,58,4,0,
350,12,59,4,0,
350,12,63,4,0,
350,12,92,4,0,
350,12,104,4,0,
350,12,105,1,30,
350,12,127,4,0,
350,12,156,4,0,
350,12,182,4,0,
350,12,213,1,45,
350,12,213,4,0,
350,12,216,4,0,
350,12,218,4,0,
350,12,219,1,50,
350,12,219,4,0,
350,12,231,4,0,
350,12,237,4,0,
350,12,239,1,25,
350,12,240,1,35,
350,12,240,4,0,
350,12,258,4,0,
350,12,263,4,0,
350,12,287,1,15,
350,12,290,4,0,
350,12,291,4,0,
350,12,346,1,10,
350,12,352,1,20,
350,12,352,4,0,
350,13,34,3,0,
350,13,35,1,5,
350,13,38,3,0,
350,13,55,1,1,
350,13,56,1,40,
350,13,57,4,0,
350,13,58,4,0,
350,13,59,4,0,
350,13,63,4,0,
350,13,92,4,0,
350,13,102,3,0,
350,13,104,4,0,
350,13,105,1,30,
350,13,127,4,0,
350,13,156,4,0,
350,13,164,3,0,
350,13,182,4,0,
350,13,196,3,0,
350,13,207,3,0,
350,13,213,1,45,
350,13,213,4,0,
350,13,216,4,0,
350,13,218,4,0,
350,13,219,1,50,
350,13,219,4,0,
350,13,231,4,0,
350,13,237,4,0,
350,13,239,1,25,
350,13,240,1,35,
350,13,240,4,0,
350,13,258,4,0,
350,13,263,4,0,
350,13,287,1,15,
350,13,290,4,0,
350,13,291,4,0,
350,13,346,1,10,
350,13,352,1,20,
350,13,352,4,0,
350,14,20,3,0,
350,14,35,1,1,2
350,14,55,1,1,1
350,14,56,1,37,
350,14,57,4,0,
350,14,58,4,0,
350,14,59,4,0,
350,14,63,4,0,
350,14,92,4,0,
350,14,104,4,0,
350,14,105,1,21,
350,14,113,4,0,
350,14,127,4,0,
350,14,156,4,0,
350,14,164,4,0,
350,14,173,3,0,
350,14,182,4,0,
350,14,196,3,0,
350,14,207,4,0,
350,14,213,1,41,
350,14,213,4,0,
350,14,214,3,0,
350,14,216,4,0,
350,14,218,4,0,
350,14,219,1,45,
350,14,219,4,0,
350,14,231,3,0,
350,14,237,4,0,
350,14,239,1,17,
350,14,240,1,33,
350,14,240,4,0,
350,14,244,4,0,
350,14,258,4,0,
350,14,263,4,0,
350,14,277,3,0,
350,14,287,1,9,
350,14,291,4,0,
350,14,346,1,5,
350,14,352,1,13,
350,14,392,1,49,
350,14,401,1,29,
350,14,401,3,0,
350,14,406,3,0,
350,14,416,4,0,
350,14,442,3,0,
350,14,445,1,25,
350,14,496,4,0,
350,14,503,4,0,
350,14,523,4,0,
350,14,525,4,0,
350,15,35,1,1,2
350,15,55,1,1,1
350,15,56,1,37,
350,15,57,4,0,
350,15,58,4,0,
350,15,59,4,0,
350,15,63,4,0,
350,15,92,4,0,
350,15,104,4,0,
350,15,105,1,21,
350,15,113,4,0,
350,15,127,4,0,
350,15,156,4,0,
350,15,164,4,0,
350,15,182,4,0,
350,15,207,4,0,
350,15,213,1,41,
350,15,213,4,0,
350,15,214,4,0,
350,15,216,4,0,
350,15,218,4,0,
350,15,219,1,45,
350,15,219,4,0,
350,15,237,4,0,
350,15,239,1,17,
350,15,240,1,33,
350,15,240,4,0,
350,15,244,4,0,
350,15,258,4,0,
350,15,263,4,0,
350,15,287,1,9,
350,15,346,1,5,
350,15,352,1,13,
350,15,392,1,49,
350,15,401,1,29,
350,15,416,4,0,
350,15,445,1,25,
350,15,496,4,0,
350,15,503,4,0,
350,15,523,4,0,
350,15,525,4,0,
350,15,590,4,0,
350,16,20,3,0,
350,16,35,1,1,1
350,16,55,1,1,2
350,16,56,1,47,1
350,16,57,4,0,
350,16,58,4,0,
350,16,59,4,0,
350,16,63,4,0,
350,16,92,4,0,
350,16,104,4,0,
350,16,105,1,31,1
350,16,113,4,0,
350,16,127,4,0,
350,16,156,4,0,
350,16,164,4,0,
350,16,173,3,0,
350,16,182,4,0,
350,16,196,3,0,
350,16,207,4,0,
350,16,213,1,37,1
350,16,213,4,0,
350,16,214,4,0,
350,16,216,4,0,
350,16,218,4,0,
350,16,219,1,41,1
350,16,219,4,0,
350,16,231,3,0,
350,16,237,4,0,
350,16,239,1,14,1
350,16,240,1,51,1
350,16,240,4,0,
350,16,244,4,0,
350,16,258,4,0,
350,16,263,4,0,
350,16,277,3,0,
350,16,287,1,7,1
350,16,290,4,0,
350,16,291,4,0,
350,16,346,1,4,1
350,16,352,1,17,1
350,16,352,3,0,
350,16,392,1,21,1
350,16,401,1,34,1
350,16,401,3,0,
350,16,406,3,0,
350,16,416,4,0,
350,16,442,3,0,
350,16,445,1,24,1
350,16,489,1,44,1
350,16,496,4,0,
350,16,503,4,0,
350,16,523,4,0,
350,16,525,1,27,1
350,16,525,4,0,
350,16,574,1,11,1
350,16,590,4,0,
350,17,35,1,1,2
350,17,55,1,1,3
350,17,56,1,44,
350,17,57,4,0,
350,17,58,4,0,
350,17,59,4,0,
350,17,63,4,0,
350,17,92,4,0,
350,17,104,4,0,
350,17,105,1,27,
350,17,113,4,0,
350,17,127,4,0,
350,17,156,4,0,
350,17,164,4,0,
350,17,182,4,0,
350,17,207,4,0,
350,17,213,1,34,
350,17,213,4,0,
350,17,214,4,0,
350,17,216,4,0,
350,17,218,4,0,
350,17,219,1,37,
350,17,219,4,0,
350,17,237,4,0,
350,17,239,1,14,
350,17,240,1,47,
350,17,240,4,0,
350,17,244,4,0,
350,17,258,4,0,
350,17,263,4,0,
350,17,287,1,1,5
350,17,287,1,7,
350,17,346,1,1,4
350,17,346,1,4,
350,17,352,1,0,
350,17,352,1,1,1
350,17,392,1,17,
350,17,401,1,31,
350,17,416,4,0,
350,17,445,1,21,
350,17,489,1,41,
350,17,496,4,0,
350,17,503,4,0,
350,17,523,4,0,
350,17,525,1,24,
350,17,525,4,0,
350,17,574,1,11,
350,17,590,4,0,
350,17,693,4,0,
350,18,35,1,1,2
350,18,55,1,1,3
350,18,56,1,44,
350,18,57,4,0,
350,18,58,4,0,
350,18,59,4,0,
350,18,63,4,0,
350,18,92,4,0,
350,18,104,4,0,
350,18,105,1,27,
350,18,113,4,0,
350,18,127,4,0,
350,18,156,4,0,
350,18,164,4,0,
350,18,182,4,0,
350,18,207,4,0,
350,18,213,1,34,
350,18,213,4,0,
350,18,214,4,0,
350,18,216,4,0,
350,18,218,4,0,
350,18,219,1,37,
350,18,219,4,0,
350,18,237,4,0,
350,18,239,1,14,
350,18,240,1,47,
350,18,240,4,0,
350,18,244,4,0,
350,18,258,4,0,
350,18,263,4,0,
350,18,287,1,1,5
350,18,287,1,7,
350,18,346,1,1,4
350,18,346,1,4,
350,18,352,1,0,
350,18,352,1,1,1
350,18,392,1,17,
350,18,401,1,31,
350,18,416,4,0,
350,18,445,1,21,
350,18,489,1,41,
350,18,496,4,0,
350,18,503,4,0,
350,18,523,4,0,
350,18,525,1,24,
350,18,525,4,0,
350,18,574,1,11,
350,18,590,4,0,
350,18,693,4,0,
351,5,33,1,1,
351,5,52,1,10,2
351,5,53,4,0,
351,5,55,1,10,1
351,5,58,4,0,
351,5,59,4,0,
351,5,76,4,0,
351,5,85,4,0,
351,5,87,4,0,
351,5,92,4,0,
351,5,104,4,0,
351,5,126,4,0,
351,5,148,4,0,
351,5,156,4,0,
351,5,168,4,0,
351,5,181,1,10,3
351,5,182,4,0,
351,5,201,4,0,
351,5,213,4,0,
351,5,216,4,0,
351,5,218,4,0,
351,5,237,4,0,
351,5,240,1,20,
351,5,240,4,0,
351,5,241,1,20,4
351,5,241,4,0,
351,5,244,2,0,
351,5,247,4,0,
351,5,248,2,0,
351,5,258,1,20,5
351,5,258,4,0,
351,5,263,4,0,
351,5,290,4,0,
351,5,311,1,30,
351,5,351,4,0,
351,5,352,4,0,
351,6,33,1,1,
351,6,34,3,0,
351,6,38,3,0,
351,6,52,1,10,2
351,6,53,4,0,
351,6,55,1,10,1
351,6,58,4,0,
351,6,59,4,0,
351,6,76,4,0,
351,6,85,4,0,
351,6,86,3,0,
351,6,87,4,0,
351,6,92,4,0,
351,6,102,3,0,
351,6,104,4,0,
351,6,111,3,0,
351,6,126,4,0,
351,6,129,3,0,
351,6,148,4,0,
351,6,156,4,0,
351,6,164,3,0,
351,6,168,4,0,
351,6,173,3,0,
351,6,181,1,10,3
351,6,182,4,0,
351,6,196,3,0,
351,6,201,4,0,
351,6,203,3,0,
351,6,207,3,0,
351,6,213,4,0,
351,6,214,3,0,
351,6,216,4,0,
351,6,218,4,0,
351,6,237,4,0,
351,6,240,1,20,
351,6,240,4,0,
351,6,241,1,20,4
351,6,241,4,0,
351,6,244,2,0,
351,6,244,3,0,
351,6,247,4,0,
351,6,248,2,0,
351,6,258,1,20,5
351,6,258,4,0,
351,6,263,4,0,
351,6,290,4,0,
351,6,311,1,30,
351,6,351,4,0,
351,6,352,4,0,
351,7,33,1,1,
351,7,34,3,0,
351,7,38,3,0,
351,7,52,1,10,2
351,7,53,4,0,
351,7,55,1,10,1
351,7,58,4,0,
351,7,59,4,0,
351,7,76,4,0,
351,7,85,4,0,
351,7,86,3,0,
351,7,87,4,0,
351,7,92,4,0,
351,7,102,3,0,
351,7,104,4,0,
351,7,126,4,0,
351,7,148,4,0,
351,7,156,4,0,
351,7,164,3,0,
351,7,168,4,0,
351,7,181,1,10,3
351,7,182,4,0,
351,7,201,4,0,
351,7,213,4,0,
351,7,216,4,0,
351,7,218,4,0,
351,7,237,4,0,
351,7,240,1,20,
351,7,240,4,0,
351,7,241,1,20,4
351,7,241,4,0,
351,7,244,2,0,
351,7,247,4,0,
351,7,248,2,0,
351,7,258,1,20,5
351,7,258,4,0,
351,7,263,4,0,
351,7,290,4,0,
351,7,311,1,30,
351,7,351,4,0,
351,7,352,4,0,
351,8,33,1,1,
351,8,50,2,0,
351,8,52,1,10,2
351,8,53,4,0,
351,8,55,1,10,1
351,8,58,4,0,
351,8,59,4,0,
351,8,76,4,0,
351,8,85,4,0,
351,8,86,4,0,
351,8,87,4,0,
351,8,92,4,0,
351,8,104,4,0,
351,8,126,4,0,
351,8,133,2,0,
351,8,148,4,0,
351,8,156,4,0,
351,8,164,4,0,
351,8,168,4,0,
351,8,181,1,10,3
351,8,182,4,0,
351,8,201,4,0,
351,8,203,4,0,
351,8,207,4,0,
351,8,213,4,0,
351,8,214,4,0,
351,8,216,4,0,
351,8,218,4,0,
351,8,237,4,0,
351,8,240,1,20,1
351,8,240,4,0,
351,8,241,1,20,2
351,8,241,4,0,
351,8,244,2,0,
351,8,244,4,0,
351,8,247,4,0,
351,8,248,2,0,
351,8,258,1,20,3
351,8,258,4,0,
351,8,263,4,0,
351,8,290,4,0,
351,8,311,1,30,
351,8,351,4,0,
351,8,352,4,0,
351,8,363,4,0,
351,8,381,2,0,
351,8,412,4,0,
351,8,419,4,0,
351,8,445,4,0,
351,8,466,2,0,
351,9,33,1,1,
351,9,50,2,0,
351,9,52,1,10,2
351,9,53,4,0,
351,9,55,1,10,1
351,9,58,4,0,
351,9,59,4,0,
351,9,76,4,0,
351,9,85,4,0,
351,9,86,4,0,
351,9,87,4,0,
351,9,92,4,0,
351,9,104,4,0,
351,9,126,4,0,
351,9,129,3,0,
351,9,133,2,0,
351,9,148,4,0,
351,9,156,4,0,
351,9,164,4,0,
351,9,168,4,0,
351,9,173,3,0,
351,9,181,1,10,3
351,9,182,4,0,
351,9,196,3,0,
351,9,201,4,0,
351,9,203,4,0,
351,9,207,4,0,
351,9,213,4,0,
351,9,214,4,0,
351,9,216,4,0,
351,9,218,4,0,
351,9,237,4,0,
351,9,240,1,20,1
351,9,240,4,0,
351,9,241,1,20,2
351,9,241,4,0,
351,9,244,2,0,
351,9,244,4,0,
351,9,247,4,0,
351,9,248,2,0,
351,9,258,1,20,3
351,9,258,4,0,
351,9,263,4,0,
351,9,290,4,0,
351,9,311,1,30,
351,9,351,4,0,
351,9,352,4,0,
351,9,363,4,0,
351,9,381,2,0,
351,9,387,3,0,
351,9,412,4,0,
351,9,419,4,0,
351,9,445,4,0,
351,9,466,2,0,
351,9,466,3,0,
351,10,33,1,1,
351,10,50,2,0,
351,10,52,1,10,2
351,10,53,4,0,
351,10,55,1,10,1
351,10,58,4,0,
351,10,59,4,0,
351,10,76,4,0,
351,10,85,4,0,
351,10,86,4,0,
351,10,87,4,0,
351,10,92,4,0,
351,10,104,4,0,
351,10,126,4,0,
351,10,129,3,0,
351,10,133,2,0,
351,10,148,4,0,
351,10,156,4,0,
351,10,164,4,0,
351,10,168,4,0,
351,10,173,3,0,
351,10,181,1,10,3
351,10,182,4,0,
351,10,196,3,0,
351,10,201,4,0,
351,10,203,4,0,
351,10,207,4,0,
351,10,213,4,0,
351,10,214,4,0,
351,10,216,4,0,
351,10,218,4,0,
351,10,237,4,0,
351,10,240,1,20,1
351,10,240,4,0,
351,10,241,1,20,2
351,10,241,4,0,
351,10,244,2,0,
351,10,244,4,0,
351,10,247,4,0,
351,10,248,2,0,
351,10,258,1,20,3
351,10,258,4,0,
351,10,263,4,0,
351,10,290,4,0,
351,10,311,1,30,
351,10,351,4,0,
351,10,352,4,0,
351,10,363,4,0,
351,10,366,3,0,
351,10,381,2,0,
351,10,387,3,0,
351,10,412,4,0,
351,10,419,4,0,
351,10,445,4,0,
351,10,466,2,0,
351,10,466,3,0,
351,11,29,1,20,
351,11,33,1,1,
351,11,50,2,0,
351,11,52,1,10,2
351,11,53,4,0,
351,11,55,1,10,1
351,11,56,1,50,1
351,11,58,4,0,
351,11,59,1,50,3
351,11,59,4,0,
351,11,76,4,0,
351,11,85,4,0,
351,11,86,4,0,
351,11,87,4,0,
351,11,92,4,0,
351,11,104,4,0,
351,11,126,1,50,2
351,11,126,4,0,
351,11,133,2,0,
351,11,148,4,0,
351,11,156,4,0,
351,11,164,4,0,
351,11,168,4,0,
351,11,181,1,10,3
351,11,182,4,0,
351,11,201,4,0,
351,11,207,4,0,
351,11,213,4,0,
351,11,216,4,0,
351,11,218,4,0,
351,11,237,4,0,
351,11,240,1,30,1
351,11,240,4,0,
351,11,241,1,30,2
351,11,241,4,0,
351,11,244,4,0,
351,11,247,4,0,
351,11,248,2,0,
351,11,258,1,30,3
351,11,258,4,0,
351,11,263,4,0,
351,11,311,1,40,
351,11,381,2,0,
351,11,412,4,0,
351,11,466,2,0,
351,11,496,4,0,
351,11,499,2,0,
351,11,503,4,0,
351,11,506,2,0,
351,11,510,4,0,
351,11,514,4,0,
351,11,526,4,0,
351,12,33,1,1,
351,12,52,1,10,2
351,12,53,4,0,
351,12,55,1,10,1
351,12,58,4,0,
351,12,59,4,0,
351,12,76,4,0,
351,12,85,4,0,
351,12,87,4,0,
351,12,92,4,0,
351,12,104,4,0,
351,12,126,4,0,
351,12,148,4,0,
351,12,156,4,0,
351,12,168,4,0,
351,12,181,1,10,3
351,12,182,4,0,
351,12,201,4,0,
351,12,213,4,0,
351,12,216,4,0,
351,12,218,4,0,
351,12,237,4,0,
351,12,240,1,20,
351,12,240,4,0,
351,12,241,1,20,4
351,12,241,4,0,
351,12,247,4,0,
351,12,258,1,20,5
351,12,258,4,0,
351,12,263,4,0,
351,12,290,4,0,
351,12,311,1,30,
351,12,351,4,0,
351,12,352,4,0,
351,13,33,1,1,
351,13,34,3,0,
351,13,38,3,0,
351,13,52,1,10,2
351,13,53,4,0,
351,13,55,1,10,1
351,13,58,4,0,
351,13,59,4,0,
351,13,76,4,0,
351,13,85,4,0,
351,13,86,3,0,
351,13,87,4,0,
351,13,92,4,0,
351,13,102,3,0,
351,13,104,4,0,
351,13,126,4,0,
351,13,148,4,0,
351,13,156,4,0,
351,13,164,3,0,
351,13,168,4,0,
351,13,181,1,10,3
351,13,182,4,0,
351,13,196,3,0,
351,13,201,4,0,
351,13,207,3,0,
351,13,213,4,0,
351,13,216,4,0,
351,13,218,4,0,
351,13,237,4,0,
351,13,240,1,20,
351,13,240,4,0,
351,13,241,1,20,4
351,13,241,4,0,
351,13,247,4,0,
351,13,258,1,20,5
351,13,258,4,0,
351,13,263,4,0,
351,13,290,4,0,
351,13,311,1,30,
351,13,351,4,0,
351,13,352,4,0,
351,14,29,1,15,
351,14,33,1,1,
351,14,50,2,0,
351,14,52,1,10,2
351,14,53,4,0,
351,14,55,1,10,1
351,14,56,1,40,1
351,14,58,4,0,
351,14,59,1,40,3
351,14,59,4,0,
351,14,76,4,0,
351,14,85,4,0,
351,14,86,4,0,
351,14,87,4,0,
351,14,92,4,0,
351,14,104,4,0,
351,14,126,1,40,2
351,14,126,4,0,
351,14,133,2,0,
351,14,148,4,0,
351,14,156,4,0,
351,14,164,4,0,
351,14,168,4,0,
351,14,173,3,0,
351,14,181,1,10,3
351,14,182,4,0,
351,14,196,3,0,
351,14,201,4,0,
351,14,207,4,0,
351,14,213,4,0,
351,14,214,3,0,
351,14,216,4,0,
351,14,218,4,0,
351,14,237,4,0,
351,14,240,1,20,1
351,14,240,4,0,
351,14,241,1,20,2
351,14,241,4,0,
351,14,244,4,0,
351,14,247,4,0,
351,14,248,2,0,
351,14,258,1,20,3
351,14,258,4,0,
351,14,263,4,0,
351,14,311,1,30,
351,14,366,3,0,
351,14,381,2,0,
351,14,387,3,0,
351,14,412,4,0,
351,14,466,2,0,
351,14,496,4,0,
351,14,499,2,0,
351,14,503,4,0,
351,14,506,2,0,
351,14,510,4,0,
351,14,514,4,0,
351,14,526,4,0,
351,15,29,1,15,
351,15,33,1,1,
351,15,50,2,0,
351,15,52,1,10,2
351,15,53,4,0,
351,15,55,1,10,1
351,15,56,1,40,1
351,15,58,4,0,
351,15,59,1,40,3
351,15,59,4,0,
351,15,76,4,0,
351,15,85,4,0,
351,15,86,4,0,
351,15,87,4,0,
351,15,92,4,0,
351,15,104,4,0,
351,15,126,1,40,2
351,15,126,4,0,
351,15,133,2,0,
351,15,148,4,0,
351,15,156,4,0,
351,15,164,4,0,
351,15,168,4,0,
351,15,181,1,10,3
351,15,182,4,0,
351,15,201,4,0,
351,15,207,4,0,
351,15,213,4,0,
351,15,214,4,0,
351,15,216,4,0,
351,15,218,4,0,
351,15,237,4,0,
351,15,240,1,20,1
351,15,240,4,0,
351,15,241,1,20,2
351,15,241,4,0,
351,15,244,4,0,
351,15,247,4,0,
351,15,248,2,0,
351,15,258,1,20,3
351,15,258,4,0,
351,15,263,4,0,
351,15,311,1,30,
351,15,322,2,0,
351,15,381,2,0,
351,15,385,2,0,
351,15,412,4,0,
351,15,466,2,0,
351,15,496,4,0,
351,15,499,2,0,
351,15,503,4,0,
351,15,506,2,0,
351,15,510,4,0,
351,15,513,2,0,
351,15,514,4,0,
351,15,590,4,0,
351,16,29,1,15,1
351,16,33,1,1,1
351,16,50,2,0,
351,16,52,1,10,2
351,16,53,4,0,
351,16,55,1,10,1
351,16,56,1,35,1
351,16,58,4,0,
351,16,59,1,35,3
351,16,59,4,0,
351,16,76,4,0,
351,16,85,4,0,
351,16,86,4,0,
351,16,87,4,0,
351,16,92,4,0,
351,16,104,4,0,
351,16,126,1,35,2
351,16,126,4,0,
351,16,133,2,0,
351,16,148,4,0,
351,16,156,4,0,
351,16,164,4,0,
351,16,168,4,0,
351,16,173,3,0,
351,16,181,1,10,3
351,16,182,4,0,
351,16,196,3,0,
351,16,201,4,0,
351,16,207,4,0,
351,16,213,4,0,
351,16,214,4,0,
351,16,216,4,0,
351,16,218,4,0,
351,16,237,4,0,
351,16,240,1,20,1
351,16,240,4,0,
351,16,241,1,20,2
351,16,241,4,0,
351,16,244,4,0,
351,16,247,4,0,
351,16,248,2,0,
351,16,258,1,20,3
351,16,258,4,0,
351,16,263,4,0,
351,16,290,4,0,
351,16,311,1,25,1
351,16,322,2,0,
351,16,351,3,0,
351,16,352,3,0,
351,16,366,3,0,
351,16,381,2,0,
351,16,385,2,0,
351,16,387,3,0,
351,16,412,4,0,
351,16,466,2,0,
351,16,496,4,0,
351,16,499,2,0,
351,16,503,4,0,
351,16,506,2,0,
351,16,510,4,0,
351,16,513,2,0,
351,16,514,4,0,
351,16,542,1,45,1
351,16,590,4,0,
351,17,29,1,15,
351,17,33,1,1,
351,17,50,2,0,
351,17,52,1,10,2
351,17,53,4,0,
351,17,55,1,10,1
351,17,56,1,35,1
351,17,58,4,0,
351,17,59,1,35,3
351,17,59,4,0,
351,17,76,4,0,
351,17,85,4,0,
351,17,86,4,0,
351,17,87,4,0,
351,17,92,4,0,
351,17,104,4,0,
351,17,126,1,35,2
351,17,126,4,0,
351,17,133,2,0,
351,17,156,4,0,
351,17,164,4,0,
351,17,168,4,0,
351,17,181,1,10,3
351,17,182,4,0,
351,17,201,4,0,
351,17,207,4,0,
351,17,213,4,0,
351,17,214,4,0,
351,17,216,4,0,
351,17,218,4,0,
351,17,237,4,0,
351,17,240,1,20,1
351,17,240,4,0,
351,17,241,1,20,2
351,17,241,4,0,
351,17,244,4,0,
351,17,247,4,0,
351,17,248,2,0,
351,17,258,1,20,3
351,17,258,4,0,
351,17,263,4,0,
351,17,311,1,25,
351,17,322,2,0,
351,17,381,2,0,
351,17,385,2,0,
351,17,412,4,0,
351,17,466,2,0,
351,17,496,4,0,
351,17,499,2,0,
351,17,503,4,0,
351,17,506,2,0,
351,17,513,2,0,
351,17,526,4,0,
351,17,542,1,45,
351,17,590,4,0,
351,18,29,1,15,
351,18,33,1,1,
351,18,50,2,0,
351,18,52,1,10,2
351,18,53,4,0,
351,18,55,1,10,1
351,18,56,1,35,1
351,18,58,4,0,
351,18,59,1,35,3
351,18,59,4,0,
351,18,76,4,0,
351,18,85,4,0,
351,18,86,4,0,
351,18,87,4,0,
351,18,92,4,0,
351,18,104,4,0,
351,18,126,1,35,2
351,18,126,4,0,
351,18,133,2,0,
351,18,156,4,0,
351,18,164,4,0,
351,18,168,4,0,
351,18,181,1,10,3
351,18,182,4,0,
351,18,201,4,0,
351,18,207,4,0,
351,18,213,4,0,
351,18,214,4,0,
351,18,216,4,0,
351,18,218,4,0,
351,18,237,4,0,
351,18,240,1,20,1
351,18,240,4,0,
351,18,241,1,20,2
351,18,241,4,0,
351,18,244,4,0,
351,18,247,4,0,
351,18,248,2,0,
351,18,258,1,20,3
351,18,258,4,0,
351,18,263,4,0,
351,18,311,1,25,
351,18,322,2,0,
351,18,381,2,0,
351,18,385,2,0,
351,18,412,4,0,
351,18,466,2,0,
351,18,496,4,0,
351,18,499,2,0,
351,18,503,4,0,
351,18,506,2,0,
351,18,513,2,0,
351,18,526,4,0,
351,18,542,1,45,
351,18,590,4,0,
352,5,10,1,1,5
352,5,15,4,0,
352,5,20,1,4,
352,5,39,1,1,2
352,5,50,2,0,
352,5,53,4,0,
352,5,58,4,0,
352,5,59,4,0,
352,5,60,1,17,
352,5,70,4,0,
352,5,76,4,0,
352,5,85,4,0,
352,5,87,4,0,
352,5,91,4,0,
352,5,92,4,0,
352,5,103,1,24,
352,5,104,4,0,
352,5,122,1,1,4
352,5,126,4,0,
352,5,148,4,0,
352,5,154,1,12,
352,5,156,4,0,
352,5,163,1,31,
352,5,164,1,40,
352,5,168,1,1,1
352,5,168,4,0,
352,5,182,4,0,
352,5,185,1,7,
352,5,213,4,0,
352,5,216,4,0,
352,5,218,4,0,
352,5,231,4,0,
352,5,237,4,0,
352,5,240,4,0,
352,5,241,4,0,
352,5,246,1,49,
352,5,247,4,0,
352,5,249,4,0,
352,5,263,4,0,
352,5,264,4,0,
352,5,271,2,0,
352,5,277,2,0,
352,5,280,4,0,
352,5,285,4,0,
352,5,289,4,0,
352,5,290,4,0,
352,5,310,1,1,3
352,5,317,4,0,
352,5,332,4,0,
352,5,351,4,0,
352,5,352,4,0,
352,6,5,3,0,
352,6,7,3,0,
352,6,8,3,0,
352,6,9,3,0,
352,6,10,1,1,5
352,6,15,4,0,
352,6,20,1,4,
352,6,25,3,0,
352,6,34,3,0,
352,6,38,3,0,
352,6,39,1,1,2
352,6,50,2,0,
352,6,53,4,0,
352,6,58,4,0,
352,6,59,4,0,
352,6,60,1,17,
352,6,68,3,0,
352,6,69,3,0,
352,6,70,4,0,
352,6,76,4,0,
352,6,85,4,0,
352,6,86,3,0,
352,6,87,4,0,
352,6,91,4,0,
352,6,92,4,0,
352,6,102,3,0,
352,6,103,1,24,
352,6,104,4,0,
352,6,111,3,0,
352,6,118,3,0,
352,6,122,1,1,4
352,6,126,4,0,
352,6,129,3,0,
352,6,148,4,0,
352,6,154,1,12,
352,6,156,4,0,
352,6,157,3,0,
352,6,163,1,31,
352,6,164,1,40,
352,6,164,3,0,
352,6,168,1,1,1
352,6,168,4,0,
352,6,173,3,0,
352,6,182,4,0,
352,6,185,1,7,
352,6,189,3,0,
352,6,196,3,0,
352,6,203,3,0,
352,6,205,3,0,
352,6,207,3,0,
352,6,210,3,0,
352,6,213,4,0,
352,6,214,3,0,
352,6,216,4,0,
352,6,218,4,0,
352,6,223,3,0,
352,6,231,4,0,
352,6,237,4,0,
352,6,240,4,0,
352,6,241,4,0,
352,6,244,3,0,
352,6,246,1,49,
352,6,247,4,0,
352,6,249,4,0,
352,6,263,4,0,
352,6,264,4,0,
352,6,271,2,0,
352,6,277,2,0,
352,6,280,4,0,
352,6,285,4,0,
352,6,289,4,0,
352,6,290,4,0,
352,6,310,1,1,3
352,6,317,4,0,
352,6,332,4,0,
352,6,351,4,0,
352,6,352,4,0,
352,7,5,3,0,
352,7,10,1,1,5
352,7,15,4,0,
352,7,20,1,4,
352,7,25,3,0,
352,7,34,3,0,
352,7,38,3,0,
352,7,39,1,1,2
352,7,50,2,0,
352,7,53,4,0,
352,7,58,4,0,
352,7,59,4,0,
352,7,60,1,17,
352,7,68,3,0,
352,7,69,3,0,
352,7,70,4,0,
352,7,76,4,0,
352,7,85,4,0,
352,7,86,3,0,
352,7,87,4,0,
352,7,91,4,0,
352,7,92,4,0,
352,7,102,3,0,
352,7,103,1,24,
352,7,104,4,0,
352,7,118,3,0,
352,7,122,1,1,4
352,7,126,4,0,
352,7,148,4,0,
352,7,154,1,12,
352,7,156,4,0,
352,7,157,3,0,
352,7,163,1,31,
352,7,164,1,40,
352,7,164,3,0,
352,7,168,1,1,1
352,7,168,4,0,
352,7,182,4,0,
352,7,185,1,7,
352,7,213,4,0,
352,7,216,4,0,
352,7,218,4,0,
352,7,231,4,0,
352,7,237,4,0,
352,7,240,4,0,
352,7,241,4,0,
352,7,246,1,49,
352,7,247,4,0,
352,7,249,4,0,
352,7,263,4,0,
352,7,264,4,0,
352,7,271,2,0,
352,7,277,2,0,
352,7,280,4,0,
352,7,285,4,0,
352,7,289,4,0,
352,7,290,4,0,
352,7,310,1,1,3
352,7,317,4,0,
352,7,332,4,0,
352,7,351,4,0,
352,7,352,4,0,
352,8,10,1,1,5
352,8,15,4,0,
352,8,20,1,4,
352,8,39,1,1,2
352,8,50,2,0,
352,8,53,4,0,
352,8,58,4,0,
352,8,59,4,0,
352,8,60,1,15,
352,8,70,4,0,
352,8,76,4,0,
352,8,85,4,0,
352,8,86,4,0,
352,8,87,4,0,
352,8,91,4,0,
352,8,92,4,0,
352,8,103,1,32,
352,8,104,4,0,
352,8,122,1,1,4
352,8,126,4,0,
352,8,146,2,0,
352,8,148,4,0,
352,8,154,1,10,
352,8,156,4,0,
352,8,157,4,0,
352,8,163,1,25,
352,8,164,1,39,
352,8,164,4,0,
352,8,168,1,1,1
352,8,168,4,0,
352,8,182,4,0,
352,8,185,1,7,
352,8,203,4,0,
352,8,207,4,0,
352,8,213,4,0,
352,8,214,4,0,
352,8,216,4,0,
352,8,218,4,0,
352,8,231,4,0,
352,8,237,4,0,
352,8,240,4,0,
352,8,241,4,0,
352,8,244,4,0,
352,8,246,1,64,
352,8,247,4,0,
352,8,249,4,0,
352,8,252,2,0,
352,8,263,4,0,
352,8,264,4,0,
352,8,271,2,0,
352,8,277,2,0,
352,8,278,4,0,
352,8,280,4,0,
352,8,285,4,0,
352,8,289,4,0,
352,8,290,4,0,
352,8,310,1,1,3
352,8,317,4,0,
352,8,332,4,0,
352,8,351,4,0,
352,8,352,4,0,
352,8,363,4,0,
352,8,374,4,0,
352,8,389,1,46,
352,8,409,4,0,
352,8,417,2,0,
352,8,421,1,55,
352,8,421,4,0,
352,8,425,1,20,
352,8,433,4,0,
352,8,445,4,0,
352,8,446,4,0,
352,8,447,4,0,
352,8,451,4,0,
352,9,7,3,0,
352,9,8,3,0,
352,9,9,3,0,
352,9,10,1,1,5
352,9,15,4,0,
352,9,20,1,4,
352,9,39,1,1,2
352,9,50,2,0,
352,9,53,4,0,
352,9,58,4,0,
352,9,59,4,0,
352,9,60,1,18,
352,9,70,4,0,
352,9,76,4,0,
352,9,85,4,0,
352,9,86,4,0,
352,9,87,4,0,
352,9,91,4,0,
352,9,92,4,0,
352,9,103,1,32,
352,9,104,4,0,
352,9,122,1,1,4
352,9,126,4,0,
352,9,129,3,0,
352,9,146,2,0,
352,9,148,4,0,
352,9,154,1,10,
352,9,156,4,0,
352,9,157,4,0,
352,9,163,1,27,
352,9,164,1,37,
352,9,164,4,0,
352,9,168,1,1,1
352,9,168,4,0,
352,9,173,3,0,
352,9,182,4,0,
352,9,185,1,7,
352,9,189,3,0,
352,9,196,3,0,
352,9,203,4,0,
352,9,205,3,0,
352,9,207,4,0,
352,9,210,3,0,
352,9,213,4,0,
352,9,214,4,0,
352,9,216,4,0,
352,9,218,4,0,
352,9,231,4,0,
352,9,237,4,0,
352,9,240,4,0,
352,9,241,4,0,
352,9,244,4,0,
352,9,246,1,55,
352,9,246,3,0,
352,9,247,4,0,
352,9,249,4,0,
352,9,252,2,0,
352,9,263,4,0,
352,9,264,4,0,
352,9,271,2,0,
352,9,271,3,0,
352,9,277,2,0,
352,9,278,4,0,
352,9,280,4,0,
352,9,282,3,0,
352,9,285,4,0,
352,9,289,4,0,
352,9,290,4,0,
352,9,310,1,1,3
352,9,317,4,0,
352,9,332,4,0,
352,9,351,4,0,
352,9,352,4,0,
352,9,363,4,0,
352,9,364,1,14,
352,9,374,4,0,
352,9,387,3,0,
352,9,389,1,43,
352,9,389,3,0,
352,9,401,3,0,
352,9,409,4,0,
352,9,417,2,0,
352,9,421,1,49,
352,9,421,4,0,
352,9,425,1,22,
352,9,433,4,0,
352,9,445,4,0,
352,9,446,4,0,
352,9,447,4,0,
352,9,451,4,0,
352,10,7,3,0,
352,10,8,3,0,
352,10,9,3,0,
352,10,10,1,1,5
352,10,15,4,0,
352,10,20,1,4,
352,10,29,3,0,
352,10,39,1,1,2
352,10,50,2,0,
352,10,53,4,0,
352,10,58,4,0,
352,10,59,4,0,
352,10,60,1,18,
352,10,67,3,0,
352,10,70,4,0,
352,10,76,4,0,
352,10,85,4,0,
352,10,86,4,0,
352,10,87,4,0,
352,10,91,4,0,
352,10,92,4,0,
352,10,103,1,32,
352,10,104,4,0,
352,10,105,2,0,
352,10,122,1,1,4
352,10,126,4,0,
352,10,129,3,0,
352,10,146,2,0,
352,10,148,4,0,
352,10,154,1,10,
352,10,156,4,0,
352,10,157,4,0,
352,10,163,1,27,
352,10,164,1,37,
352,10,164,4,0,
352,10,168,1,1,1
352,10,168,4,0,
352,10,173,3,0,
352,10,182,4,0,
352,10,185,1,7,
352,10,189,3,0,
352,10,196,3,0,
352,10,203,4,0,
352,10,205,3,0,
352,10,207,4,0,
352,10,210,3,0,
352,10,213,4,0,
352,10,214,4,0,
352,10,216,4,0,
352,10,218,4,0,
352,10,231,4,0,
352,10,237,4,0,
352,10,240,4,0,
352,10,241,4,0,
352,10,244,4,0,
352,10,246,1,55,
352,10,246,3,0,
352,10,247,4,0,
352,10,249,4,0,
352,10,252,2,0,
352,10,263,4,0,
352,10,264,4,0,
352,10,271,2,0,
352,10,271,3,0,
352,10,272,3,0,
352,10,277,2,0,
352,10,277,3,0,
352,10,278,4,0,
352,10,280,4,0,
352,10,282,3,0,
352,10,285,4,0,
352,10,289,4,0,
352,10,290,4,0,
352,10,310,1,1,3
352,10,317,4,0,
352,10,332,4,0,
352,10,351,4,0,
352,10,352,4,0,
352,10,363,4,0,
352,10,364,1,14,
352,10,374,4,0,
352,10,387,3,0,
352,10,389,1,43,
352,10,389,3,0,
352,10,401,3,0,
352,10,409,4,0,
352,10,417,2,0,
352,10,421,1,49,
352,10,421,4,0,
352,10,425,1,22,
352,10,433,4,0,
352,10,445,4,0,
352,10,446,4,0,
352,10,447,4,0,
352,10,451,4,0,
352,11,10,1,1,5
352,11,15,4,0,
352,11,20,1,4,
352,11,39,1,1,2
352,11,50,2,0,
352,11,53,4,0,
352,11,58,4,0,
352,11,59,4,0,
352,11,60,1,18,
352,11,70,4,0,
352,11,76,4,0,
352,11,85,4,0,
352,11,86,4,0,
352,11,87,4,0,
352,11,91,4,0,
352,11,92,4,0,
352,11,103,1,32,
352,11,104,4,0,
352,11,105,2,0,
352,11,122,1,1,4
352,11,126,4,0,
352,11,146,2,0,
352,11,148,4,0,
352,11,154,1,10,
352,11,156,4,0,
352,11,157,4,0,
352,11,163,1,27,
352,11,164,1,37,
352,11,164,4,0,
352,11,168,1,1,1
352,11,168,4,0,
352,11,182,4,0,
352,11,185,1,7,
352,11,207,4,0,
352,11,213,4,0,
352,11,216,4,0,
352,11,218,4,0,
352,11,237,4,0,
352,11,240,4,0,
352,11,241,4,0,
352,11,244,4,0,
352,11,246,1,55,
352,11,247,4,0,
352,11,249,4,0,
352,11,252,2,0,
352,11,263,4,0,
352,11,271,2,0,
352,11,277,2,0,
352,11,280,4,0,
352,11,285,2,0,
352,11,289,2,0,
352,11,310,1,1,3
352,11,317,4,0,
352,11,332,4,0,
352,11,364,1,14,
352,11,374,4,0,
352,11,389,1,43,
352,11,417,2,0,
352,11,421,1,49,
352,11,421,4,0,
352,11,425,1,22,
352,11,433,4,0,
352,11,447,4,0,
352,11,451,4,0,
352,11,468,4,0,
352,11,485,1,58,
352,11,492,2,0,
352,11,496,4,0,
352,11,510,4,0,
352,11,514,4,0,
352,11,526,4,0,
352,12,10,1,1,5
352,12,15,4,0,
352,12,20,1,4,
352,12,39,1,1,2
352,12,53,4,0,
352,12,58,4,0,
352,12,59,4,0,
352,12,60,1,17,
352,12,70,4,0,
352,12,76,4,0,
352,12,85,4,0,
352,12,87,4,0,
352,12,91,4,0,
352,12,92,4,0,
352,12,103,1,24,
352,12,104,4,0,
352,12,122,1,1,4
352,12,126,4,0,
352,12,148,4,0,
352,12,154,1,12,
352,12,156,4,0,
352,12,163,1,31,
352,12,164,1,40,
352,12,168,1,1,1
352,12,168,4,0,
352,12,182,4,0,
352,12,185,1,7,
352,12,213,4,0,
352,12,216,4,0,
352,12,218,4,0,
352,12,231,4,0,
352,12,237,4,0,
352,12,240,4,0,
352,12,241,4,0,
352,12,246,1,49,
352,12,247,4,0,
352,12,249,4,0,
352,12,263,4,0,
352,12,264,4,0,
352,12,280,4,0,
352,12,285,4,0,
352,12,289,4,0,
352,12,290,4,0,
352,12,310,1,1,3
352,12,317,4,0,
352,12,332,4,0,
352,12,351,4,0,
352,12,352,4,0,
352,13,10,1,1,5
352,13,15,4,0,
352,13,20,1,4,
352,13,34,3,0,
352,13,38,3,0,
352,13,39,1,1,2
352,13,53,4,0,
352,13,58,4,0,
352,13,59,4,0,
352,13,60,1,17,
352,13,69,3,0,
352,13,70,4,0,
352,13,76,4,0,
352,13,85,4,0,
352,13,86,3,0,
352,13,87,4,0,
352,13,91,4,0,
352,13,92,4,0,
352,13,102,3,0,
352,13,103,1,24,
352,13,104,4,0,
352,13,122,1,1,4
352,13,126,4,0,
352,13,148,4,0,
352,13,154,1,12,
352,13,156,4,0,
352,13,163,1,31,
352,13,164,1,40,
352,13,164,3,0,
352,13,168,1,1,1
352,13,168,4,0,
352,13,182,4,0,
352,13,185,1,7,
352,13,196,3,0,
352,13,207,3,0,
352,13,213,4,0,
352,13,216,4,0,
352,13,218,4,0,
352,13,231,4,0,
352,13,237,4,0,
352,13,240,4,0,
352,13,241,4,0,
352,13,246,1,49,
352,13,247,4,0,
352,13,249,4,0,
352,13,263,4,0,
352,13,264,4,0,
352,13,280,4,0,
352,13,285,4,0,
352,13,289,4,0,
352,13,290,4,0,
352,13,310,1,1,3
352,13,317,4,0,
352,13,332,4,0,
352,13,351,4,0,
352,13,352,4,0,
352,14,7,3,0,
352,14,8,3,0,
352,14,9,3,0,
352,14,10,1,1,5
352,14,15,4,0,
352,14,20,1,4,
352,14,20,3,0,
352,14,39,1,1,2
352,14,50,2,0,
352,14,53,4,0,
352,14,58,4,0,
352,14,59,4,0,
352,14,60,1,18,
352,14,67,3,0,
352,14,70,4,0,
352,14,76,4,0,
352,14,85,4,0,
352,14,86,4,0,
352,14,87,4,0,
352,14,91,4,0,
352,14,92,4,0,
352,14,103,1,32,
352,14,104,4,0,
352,14,105,2,0,
352,14,122,1,1,4
352,14,126,4,0,
352,14,146,2,0,
352,14,148,4,0,
352,14,154,1,10,
352,14,156,4,0,
352,14,157,4,0,
352,14,163,1,27,
352,14,164,1,37,
352,14,164,4,0,
352,14,168,1,1,1
352,14,168,4,0,
352,14,173,3,0,
352,14,182,4,0,
352,14,185,1,7,
352,14,196,3,0,
352,14,207,4,0,
352,14,213,4,0,
352,14,214,3,0,
352,14,216,4,0,
352,14,218,4,0,
352,14,231,3,0,
352,14,237,4,0,
352,14,240,4,0,
352,14,241,4,0,
352,14,244,4,0,
352,14,246,1,55,
352,14,247,4,0,
352,14,249,4,0,
352,14,252,2,0,
352,14,263,4,0,
352,14,271,2,0,
352,14,271,3,0,
352,14,272,3,0,
352,14,277,2,0,
352,14,277,3,0,
352,14,278,3,0,
352,14,280,4,0,
352,14,282,3,0,
352,14,285,2,0,
352,14,285,3,0,
352,14,289,2,0,
352,14,289,3,0,
352,14,310,1,1,3
352,14,317,4,0,
352,14,332,4,0,
352,14,364,1,14,
352,14,374,4,0,
352,14,387,3,0,
352,14,389,1,43,
352,14,401,3,0,
352,14,409,3,0,
352,14,417,2,0,
352,14,421,1,49,
352,14,421,4,0,
352,14,425,1,22,
352,14,433,4,0,
352,14,446,3,0,
352,14,447,4,0,
352,14,451,4,0,
352,14,468,4,0,
352,14,472,3,0,
352,14,485,1,58,
352,14,492,2,0,
352,14,492,3,0,
352,14,495,3,0,
352,14,496,4,0,
352,14,510,4,0,
352,14,514,4,0,
352,14,526,4,0,
352,15,10,1,1,7
352,15,15,4,0,
352,15,20,1,4,
352,15,39,1,1,4
352,15,50,2,0,
352,15,53,4,0,
352,15,58,4,0,
352,15,59,4,0,
352,15,60,1,18,
352,15,70,4,0,
352,15,76,4,0,
352,15,85,4,0,
352,15,86,4,0,
352,15,87,4,0,
352,15,91,4,0,
352,15,92,4,0,
352,15,103,1,32,
352,15,104,4,0,
352,15,105,2,0,
352,15,122,1,1,6
352,15,126,4,0,
352,15,146,2,0,
352,15,148,4,0,
352,15,154,1,10,
352,15,156,4,0,
352,15,157,4,0,
352,15,163,1,27,
352,15,164,1,37,
352,15,164,4,0,
352,15,168,1,1,3
352,15,168,4,0,
352,15,182,4,0,
352,15,185,1,7,
352,15,207,4,0,
352,15,213,4,0,
352,15,214,4,0,
352,15,216,4,0,
352,15,218,4,0,
352,15,237,4,0,
352,15,240,4,0,
352,15,241,4,0,
352,15,244,4,0,
352,15,246,1,1,2
352,15,246,1,55,
352,15,247,4,0,
352,15,249,4,0,
352,15,252,2,0,
352,15,263,4,0,
352,15,271,2,0,
352,15,277,2,0,
352,15,280,4,0,
352,15,285,2,0,
352,15,289,2,0,
352,15,293,2,0,
352,15,310,1,1,5
352,15,317,4,0,
352,15,332,4,0,
352,15,364,1,14,
352,15,374,4,0,
352,15,389,1,43,
352,15,417,2,0,
352,15,421,1,49,
352,15,421,4,0,
352,15,425,1,22,
352,15,433,4,0,
352,15,447,4,0,
352,15,451,4,0,
352,15,468,4,0,
352,15,485,1,1,1
352,15,485,1,58,
352,15,492,2,0,
352,15,496,4,0,
352,15,510,4,0,
352,15,514,4,0,
352,15,590,4,0,
352,15,612,4,0,
352,16,7,3,0,
352,16,8,3,0,
352,16,9,3,0,
352,16,10,1,1,5
352,16,15,4,0,
352,16,20,1,4,1
352,16,20,3,0,
352,16,39,1,1,2
352,16,50,2,0,
352,16,53,4,0,
352,16,58,4,0,
352,16,59,4,0,
352,16,60,1,18,1
352,16,67,3,0,
352,16,70,4,0,
352,16,76,4,0,
352,16,85,4,0,
352,16,86,4,0,
352,16,87,4,0,
352,16,91,4,0,
352,16,92,4,0,
352,16,103,1,38,1
352,16,104,4,0,
352,16,105,2,0,
352,16,122,1,1,4
352,16,126,4,0,
352,16,146,2,0,
352,16,148,4,0,
352,16,154,1,13,1
352,16,156,4,0,
352,16,157,4,0,
352,16,163,1,25,1
352,16,164,1,42,1
352,16,164,4,0,
352,16,168,1,1,1
352,16,168,4,0,
352,16,173,3,0,
352,16,182,4,0,
352,16,185,1,16,1
352,16,196,3,0,
352,16,207,4,0,
352,16,213,4,0,
352,16,214,4,0,
352,16,216,4,0,
352,16,218,4,0,
352,16,231,3,0,
352,16,237,4,0,
352,16,240,4,0,
352,16,241,4,0,
352,16,244,4,0,
352,16,246,1,21,1
352,16,247,4,0,
352,16,249,4,0,
352,16,252,2,0,
352,16,263,4,0,
352,16,264,3,0,
352,16,271,2,0,
352,16,271,3,0,
352,16,272,3,0,
352,16,277,2,0,
352,16,277,3,0,
352,16,278,3,0,
352,16,280,4,0,
352,16,282,3,0,
352,16,285,2,0,
352,16,285,3,0,
352,16,289,2,0,
352,16,289,3,0,
352,16,290,4,0,
352,16,293,1,30,1
352,16,293,2,0,
352,16,310,1,1,3
352,16,317,4,0,
352,16,332,4,0,
352,16,351,3,0,
352,16,352,3,0,
352,16,364,1,10,1
352,16,374,4,0,
352,16,387,3,0,
352,16,389,1,46,1
352,16,401,3,0,
352,16,409,3,0,
352,16,417,2,0,
352,16,421,1,33,1
352,16,421,4,0,
352,16,425,1,7,1
352,16,433,4,0,
352,16,446,3,0,
352,16,447,4,0,
352,16,451,4,0,
352,16,468,4,0,
352,16,472,3,0,
352,16,485,1,50,1
352,16,492,2,0,
352,16,492,3,0,
352,16,495,3,0,
352,16,496,4,0,
352,16,510,4,0,
352,16,514,4,0,
352,16,590,4,0,
352,16,612,4,0,
352,17,10,1,1,5
352,17,20,1,4,
352,17,39,1,1,2
352,17,50,2,0,
352,17,53,4,0,
352,17,58,4,0,
352,17,59,4,0,
352,17,60,1,18,
352,17,76,4,0,
352,17,85,4,0,
352,17,86,4,0,
352,17,87,4,0,
352,17,92,4,0,
352,17,103,1,38,
352,17,104,4,0,
352,17,105,2,0,
352,17,122,1,1,4
352,17,126,4,0,
352,17,146,2,0,
352,17,154,1,13,
352,17,156,4,0,
352,17,157,4,0,
352,17,163,1,25,
352,17,164,1,42,
352,17,164,4,0,
352,17,168,1,1,1
352,17,168,4,0,
352,17,182,4,0,
352,17,185,1,16,
352,17,207,4,0,
352,17,213,4,0,
352,17,214,4,0,
352,17,216,4,0,
352,17,218,4,0,
352,17,237,4,0,
352,17,240,4,0,
352,17,241,4,0,
352,17,244,4,0,
352,17,246,1,21,
352,17,247,4,0,
352,17,252,2,0,
352,17,263,4,0,
352,17,271,2,0,
352,17,277,2,0,
352,17,280,4,0,
352,17,285,2,0,
352,17,289,2,0,
352,17,293,1,30,
352,17,293,2,0,
352,17,310,1,1,3
352,17,317,4,0,
352,17,332,4,0,
352,17,364,1,10,
352,17,374,4,0,
352,17,389,1,46,
352,17,417,2,0,
352,17,421,1,33,
352,17,421,4,0,
352,17,425,1,7,
352,17,433,4,0,
352,17,447,4,0,
352,17,451,4,0,
352,17,485,1,50,
352,17,492,2,0,
352,17,496,4,0,
352,17,526,4,0,
352,17,590,4,0,
352,18,10,1,1,5
352,18,20,1,4,
352,18,39,1,1,2
352,18,50,2,0,
352,18,53,4,0,
352,18,58,4,0,
352,18,59,4,0,
352,18,60,1,18,
352,18,76,4,0,
352,18,85,4,0,
352,18,86,4,0,
352,18,87,4,0,
352,18,92,4,0,
352,18,103,1,38,
352,18,104,4,0,
352,18,105,2,0,
352,18,122,1,1,4
352,18,126,4,0,
352,18,146,2,0,
352,18,154,1,13,
352,18,156,4,0,
352,18,157,4,0,
352,18,163,1,25,
352,18,164,1,42,
352,18,164,4,0,
352,18,168,1,1,1
352,18,168,4,0,
352,18,182,4,0,
352,18,185,1,16,
352,18,207,4,0,
352,18,213,4,0,
352,18,214,4,0,
352,18,216,4,0,
352,18,218,4,0,
352,18,237,4,0,
352,18,240,4,0,
352,18,241,4,0,
352,18,244,4,0,
352,18,246,1,21,
352,18,247,4,0,
352,18,252,2,0,
352,18,263,4,0,
352,18,271,2,0,
352,18,277,2,0,
352,18,280,4,0,
352,18,285,2,0,
352,18,289,2,0,
352,18,293,1,30,
352,18,293,2,0,
352,18,310,1,1,3
352,18,317,4,0,
352,18,332,4,0,
352,18,364,1,10,
352,18,374,4,0,
352,18,389,1,46,
352,18,417,2,0,
352,18,421,1,33,
352,18,421,4,0,
352,18,425,1,7,
352,18,433,4,0,
352,18,447,4,0,
352,18,451,4,0,
352,18,485,1,50,
352,18,492,2,0,
352,18,496,4,0,
352,18,526,4,0,
352,18,590,4,0,
352,18,612,2,0,
353,5,50,2,0,
353,5,85,4,0,
353,5,87,4,0,
353,5,92,4,0,
353,5,94,4,0,
353,5,101,1,13,
353,5,103,1,8,
353,5,104,4,0,
353,5,148,4,0,
353,5,156,4,0,
353,5,168,4,0,
353,5,174,1,20,
353,5,180,1,25,
353,5,182,4,0,
353,5,185,1,37,
353,5,193,2,0,
353,5,194,2,0,
353,5,213,4,0,
353,5,216,4,0,
353,5,218,4,0,
353,5,237,4,0,
353,5,240,4,0,
353,5,241,4,0,
353,5,247,1,44,
353,5,247,4,0,
353,5,259,4,0,
353,5,261,1,32,
353,5,263,4,0,
353,5,269,4,0,
353,5,282,1,1,
353,5,285,4,0,
353,5,286,2,0,
353,5,288,1,56,
353,5,289,1,49,
353,5,289,4,0,
353,5,290,4,0,
353,5,310,2,0,
353,5,347,4,0,
353,5,351,4,0,
353,6,34,3,0,
353,6,38,3,0,
353,6,50,2,0,
353,6,85,4,0,
353,6,86,3,0,
353,6,87,4,0,
353,6,92,4,0,
353,6,94,4,0,
353,6,101,1,13,
353,6,102,3,0,
353,6,103,1,8,
353,6,104,4,0,
353,6,138,3,0,
353,6,148,4,0,
353,6,156,4,0,
353,6,164,3,0,
353,6,168,4,0,
353,6,173,3,0,
353,6,174,1,20,
353,6,180,1,25,
353,6,182,4,0,
353,6,185,1,37,
353,6,193,2,0,
353,6,194,2,0,
353,6,196,3,0,
353,6,203,3,0,
353,6,207,3,0,
353,6,213,4,0,
353,6,214,3,0,
353,6,216,4,0,
353,6,218,4,0,
353,6,237,4,0,
353,6,240,4,0,
353,6,241,4,0,
353,6,244,3,0,
353,6,247,1,44,
353,6,247,4,0,
353,6,259,4,0,
353,6,261,1,32,
353,6,263,4,0,
353,6,269,4,0,
353,6,282,1,1,
353,6,285,4,0,
353,6,286,2,0,
353,6,288,1,56,
353,6,289,1,49,
353,6,289,4,0,
353,6,290,4,0,
353,6,310,2,0,
353,6,347,4,0,
353,6,351,4,0,
353,7,34,3,0,
353,7,38,3,0,
353,7,50,2,0,
353,7,85,4,0,
353,7,86,3,0,
353,7,87,4,0,
353,7,92,4,0,
353,7,94,4,0,
353,7,101,1,13,
353,7,102,3,0,
353,7,103,1,8,
353,7,104,4,0,
353,7,138,3,0,
353,7,148,4,0,
353,7,156,4,0,
353,7,164,3,0,
353,7,168,4,0,
353,7,174,1,20,
353,7,180,1,25,
353,7,182,4,0,
353,7,185,1,37,
353,7,193,2,0,
353,7,194,2,0,
353,7,213,4,0,
353,7,216,4,0,
353,7,218,4,0,
353,7,237,4,0,
353,7,240,4,0,
353,7,241,4,0,
353,7,247,1,44,
353,7,247,4,0,
353,7,259,4,0,
353,7,261,1,32,
353,7,263,4,0,
353,7,269,4,0,
353,7,282,1,1,
353,7,285,4,0,
353,7,286,2,0,
353,7,288,1,56,
353,7,289,1,49,
353,7,289,4,0,
353,7,290,4,0,
353,7,310,2,0,
353,7,347,4,0,
353,7,351,4,0,
353,8,50,2,0,
353,8,85,4,0,
353,8,86,4,0,
353,8,87,4,0,
353,8,92,4,0,
353,8,94,4,0,
353,8,101,1,8,
353,8,103,1,5,
353,8,104,4,0,
353,8,109,2,0,
353,8,138,4,0,
353,8,148,4,0,
353,8,156,4,0,
353,8,164,4,0,
353,8,168,4,0,
353,8,174,1,13,
353,8,180,1,16,
353,8,182,4,0,
353,8,185,1,28,
353,8,193,2,0,
353,8,194,2,0,
353,8,203,4,0,
353,8,207,4,0,
353,8,213,4,0,
353,8,214,4,0,
353,8,216,4,0,
353,8,218,4,0,
353,8,228,2,0,
353,8,237,4,0,
353,8,240,4,0,
353,8,241,4,0,
353,8,244,4,0,
353,8,247,1,31,
353,8,247,4,0,
353,8,259,4,0,
353,8,261,1,23,
353,8,261,4,0,
353,8,263,4,0,
353,8,269,4,0,
353,8,271,1,50,
353,8,282,1,1,
353,8,285,4,0,
353,8,286,2,0,
353,8,288,1,46,
353,8,289,1,43,
353,8,289,4,0,
353,8,290,4,0,
353,8,310,2,0,
353,8,347,4,0,
353,8,351,4,0,
353,8,363,4,0,
353,8,371,2,0,
353,8,371,4,0,
353,8,373,1,38,
353,8,373,4,0,
353,8,389,1,35,
353,8,399,4,0,
353,8,425,1,20,
353,8,425,2,0,
353,8,433,4,0,
353,8,445,4,0,
353,8,451,4,0,
353,9,50,2,0,
353,9,85,4,0,
353,9,86,4,0,
353,9,87,4,0,
353,9,92,4,0,
353,9,94,4,0,
353,9,101,1,8,
353,9,103,1,5,
353,9,104,4,0,
353,9,109,2,0,
353,9,138,4,0,
353,9,148,4,0,
353,9,156,4,0,
353,9,164,4,0,
353,9,168,4,0,
353,9,174,1,13,
353,9,180,1,16,
353,9,180,3,0,
353,9,182,4,0,
353,9,185,1,28,
353,9,193,2,0,
353,9,194,2,0,
353,9,196,3,0,
353,9,203,4,0,
353,9,207,4,0,
353,9,213,4,0,
353,9,214,4,0,
353,9,216,4,0,
353,9,218,4,0,
353,9,228,2,0,
353,9,237,4,0,
353,9,240,4,0,
353,9,241,4,0,
353,9,244,4,0,
353,9,247,1,31,
353,9,247,4,0,
353,9,259,4,0,
353,9,261,1,23,
353,9,261,4,0,
353,9,263,4,0,
353,9,269,4,0,
353,9,271,1,50,
353,9,271,3,0,
353,9,282,1,1,
353,9,282,3,0,
353,9,285,4,0,
353,9,286,2,0,
353,9,288,1,46,
353,9,289,1,43,
353,9,289,4,0,
353,9,290,4,0,
353,9,310,2,0,
353,9,347,4,0,
353,9,351,4,0,
353,9,363,4,0,
353,9,371,2,0,
353,9,371,4,0,
353,9,373,1,38,
353,9,373,4,0,
353,9,389,1,35,
353,9,389,3,0,
353,9,399,4,0,
353,9,425,1,20,
353,9,425,2,0,
353,9,433,4,0,
353,9,445,4,0,
353,9,451,4,0,
353,9,466,3,0,
353,10,29,3,0,
353,10,50,2,0,
353,10,85,4,0,
353,10,86,4,0,
353,10,87,4,0,
353,10,92,4,0,
353,10,94,4,0,
353,10,101,1,8,
353,10,103,1,5,
353,10,104,4,0,
353,10,109,2,0,
353,10,138,4,0,
353,10,148,4,0,
353,10,156,4,0,
353,10,164,4,0,
353,10,168,4,0,
353,10,174,1,13,
353,10,180,1,16,
353,10,180,3,0,
353,10,182,4,0,
353,10,185,1,28,
353,10,193,2,0,
353,10,194,2,0,
353,10,196,3,0,
353,10,203,4,0,
353,10,207,4,0,
353,10,213,4,0,
353,10,214,4,0,
353,10,216,4,0,
353,10,218,4,0,
353,10,220,3,0,
353,10,228,2,0,
353,10,237,4,0,
353,10,240,4,0,
353,10,241,4,0,
353,10,244,4,0,
353,10,247,1,31,
353,10,247,4,0,
353,10,259,4,0,
353,10,261,1,23,
353,10,261,4,0,
353,10,263,4,0,
353,10,269,4,0,
353,10,271,1,50,
353,10,271,3,0,
353,10,272,3,0,
353,10,277,3,0,
353,10,282,1,1,
353,10,282,3,0,
353,10,285,4,0,
353,10,286,2,0,
353,10,288,1,46,
353,10,289,1,43,
353,10,289,4,0,
353,10,290,4,0,
353,10,310,2,0,
353,10,347,4,0,
353,10,351,4,0,
353,10,363,4,0,
353,10,371,2,0,
353,10,371,4,0,
353,10,373,1,38,
353,10,373,4,0,
353,10,389,1,35,
353,10,389,3,0,
353,10,399,4,0,
353,10,425,1,20,
353,10,425,2,0,
353,10,433,4,0,
353,10,445,4,0,
353,10,451,4,0,
353,10,466,3,0,
353,11,50,2,0,
353,11,85,4,0,
353,11,86,4,0,
353,11,87,4,0,
353,11,92,4,0,
353,11,94,4,0,
353,11,101,1,8,
353,11,103,1,5,
353,11,104,4,0,
353,11,109,2,0,
353,11,138,4,0,
353,11,148,4,0,
353,11,156,4,0,
353,11,164,4,0,
353,11,168,4,0,
353,11,174,1,13,
353,11,180,1,16,
353,11,182,4,0,
353,11,185,1,28,
353,11,193,2,0,
353,11,194,2,0,
353,11,207,4,0,
353,11,213,4,0,
353,11,216,4,0,
353,11,218,4,0,
353,11,228,2,0,
353,11,237,4,0,
353,11,240,4,0,
353,11,241,4,0,
353,11,244,4,0,
353,11,247,1,35,
353,11,247,4,0,
353,11,259,4,0,
353,11,261,1,23,
353,11,261,4,0,
353,11,263,4,0,
353,11,269,4,0,
353,11,271,1,55,
353,11,282,1,1,
353,11,286,2,0,
353,11,288,1,50,
353,11,289,1,46,
353,11,310,2,0,
353,11,347,4,0,
353,11,371,4,0,
353,11,373,1,43,
353,11,373,4,0,
353,11,389,1,38,
353,11,425,1,20,
353,11,425,2,0,
353,11,433,4,0,
353,11,441,2,0,
353,11,451,4,0,
353,11,466,2,0,
353,11,477,4,0,
353,11,496,4,0,
353,11,506,1,31,
353,12,85,4,0,
353,12,87,4,0,
353,12,92,4,0,
353,12,94,4,0,
353,12,101,1,13,
353,12,103,1,8,
353,12,104,4,0,
353,12,148,4,0,
353,12,156,4,0,
353,12,168,4,0,
353,12,174,1,20,
353,12,180,1,25,
353,12,182,4,0,
353,12,185,1,37,
353,12,213,4,0,
353,12,216,4,0,
353,12,218,4,0,
353,12,237,4,0,
353,12,240,4,0,
353,12,241,4,0,
353,12,247,1,44,
353,12,247,4,0,
353,12,259,4,0,
353,12,261,1,32,
353,12,263,4,0,
353,12,269,4,0,
353,12,282,1,1,
353,12,285,4,0,
353,12,288,1,56,
353,12,289,1,49,
353,12,289,4,0,
353,12,290,4,0,
353,12,347,4,0,
353,12,351,4,0,
353,13,34,3,0,
353,13,38,3,0,
353,13,85,4,0,
353,13,86,3,0,
353,13,87,4,0,
353,13,92,4,0,
353,13,94,4,0,
353,13,101,1,13,
353,13,102,3,0,
353,13,103,1,8,
353,13,104,4,0,
353,13,138,3,0,
353,13,148,4,0,
353,13,156,4,0,
353,13,164,3,0,
353,13,168,4,0,
353,13,171,3,0,
353,13,174,1,20,
353,13,180,1,25,
353,13,182,4,0,
353,13,185,1,37,
353,13,196,3,0,
353,13,207,3,0,
353,13,213,4,0,
353,13,216,4,0,
353,13,218,4,0,
353,13,237,4,0,
353,13,240,4,0,
353,13,241,4,0,
353,13,247,1,44,
353,13,247,4,0,
353,13,259,4,0,
353,13,261,1,32,
353,13,263,4,0,
353,13,269,4,0,
353,13,282,1,1,
353,13,285,4,0,
353,13,288,1,56,
353,13,289,1,49,
353,13,289,4,0,
353,13,290,4,0,
353,13,347,4,0,
353,13,351,4,0,
353,14,50,2,0,
353,14,85,4,0,
353,14,86,4,0,
353,14,87,4,0,
353,14,92,4,0,
353,14,94,4,0,
353,14,101,1,7,
353,14,103,1,4,
353,14,104,4,0,
353,14,109,2,0,
353,14,138,4,0,
353,14,148,4,0,
353,14,156,4,0,
353,14,164,4,0,
353,14,168,4,0,
353,14,174,1,19,
353,14,180,1,10,
353,14,180,3,0,
353,14,182,4,0,
353,14,185,1,22,
353,14,193,2,0,
353,14,194,2,0,
353,14,196,3,0,
353,14,207,4,0,
353,14,213,4,0,
353,14,214,3,0,
353,14,216,4,0,
353,14,218,4,0,
353,14,220,3,0,
353,14,228,2,0,
353,14,237,4,0,
353,14,240,4,0,
353,14,241,4,0,
353,14,244,4,0,
353,14,247,1,30,
353,14,247,4,0,
353,14,259,4,0,
353,14,261,1,13,
353,14,261,4,0,
353,14,263,4,0,
353,14,269,4,0,
353,14,271,1,50,
353,14,271,3,0,
353,14,272,3,0,
353,14,277,3,0,
353,14,282,1,1,
353,14,282,3,0,
353,14,285,3,0,
353,14,286,2,0,
353,14,288,1,46,
353,14,289,1,42,
353,14,289,3,0,
353,14,310,2,0,
353,14,347,4,0,
353,14,371,4,0,
353,14,373,1,38,
353,14,373,4,0,
353,14,389,1,34,
353,14,399,3,0,
353,14,425,1,16,
353,14,425,2,0,
353,14,433,4,0,
353,14,441,2,0,
353,14,451,4,0,
353,14,466,2,0,
353,14,477,4,0,
353,14,478,3,0,
353,14,492,3,0,
353,14,496,4,0,
353,14,506,1,26,
353,15,50,2,0,
353,15,85,4,0,
353,15,86,4,0,
353,15,87,4,0,
353,15,92,4,0,
353,15,94,4,0,
353,15,101,1,7,
353,15,103,1,4,
353,15,104,4,0,
353,15,109,2,0,
353,15,138,4,0,
353,15,148,4,0,
353,15,156,4,0,
353,15,164,4,0,
353,15,168,4,0,
353,15,174,1,19,
353,15,180,1,10,
353,15,182,4,0,
353,15,185,1,22,
353,15,193,2,0,
353,15,194,2,0,
353,15,207,4,0,
353,15,213,4,0,
353,15,214,4,0,
353,15,216,4,0,
353,15,218,4,0,
353,15,228,2,0,
353,15,237,4,0,
353,15,240,4,0,
353,15,241,4,0,
353,15,244,4,0,
353,15,247,1,30,
353,15,247,4,0,
353,15,259,4,0,
353,15,261,1,13,
353,15,261,4,0,
353,15,263,4,0,
353,15,269,4,0,
353,15,271,1,50,
353,15,282,1,1,
353,15,286,2,0,
353,15,288,1,46,
353,15,289,1,42,
353,15,310,2,0,
353,15,347,4,0,
353,15,371,4,0,
353,15,373,1,38,
353,15,373,4,0,
353,15,389,1,34,
353,15,399,4,0,
353,15,425,1,16,
353,15,425,2,0,
353,15,433,4,0,
353,15,441,2,0,
353,15,451,4,0,
353,15,466,2,0,
353,15,496,4,0,
353,15,506,1,26,
353,15,566,2,0,
353,15,590,4,0,
353,15,605,4,0,
353,16,50,2,0,
353,16,85,4,0,
353,16,86,4,0,
353,16,87,4,0,
353,16,92,4,0,
353,16,94,4,0,
353,16,101,1,7,1
353,16,103,1,4,1
353,16,104,4,0,
353,16,109,2,0,
353,16,138,4,0,
353,16,148,4,0,
353,16,156,4,0,
353,16,164,4,0,
353,16,168,4,0,
353,16,173,3,0,
353,16,174,1,26,1
353,16,180,1,10,1
353,16,180,3,0,
353,16,182,4,0,
353,16,185,1,19,1
353,16,193,2,0,
353,16,194,2,0,
353,16,196,3,0,
353,16,207,4,0,
353,16,213,4,0,
353,16,214,4,0,
353,16,216,4,0,
353,16,218,4,0,
353,16,220,3,0,
353,16,228,2,0,
353,16,237,4,0,
353,16,240,4,0,
353,16,241,4,0,
353,16,244,4,0,
353,16,247,1,30,1
353,16,247,4,0,
353,16,259,4,0,
353,16,261,1,16,1
353,16,261,4,0,
353,16,263,4,0,
353,16,269,4,0,
353,16,271,1,50,1
353,16,271,3,0,
353,16,272,3,0,
353,16,277,3,0,
353,16,282,1,1,1
353,16,282,3,0,
353,16,285,3,0,
353,16,286,2,0,
353,16,288,1,46,1
353,16,289,1,42,1
353,16,289,3,0,
353,16,290,4,0,
353,16,310,2,0,
353,16,347,4,0,
353,16,351,3,0,
353,16,371,4,0,
353,16,373,1,34,1
353,16,373,4,0,
353,16,389,1,38,1
353,16,399,4,0,
353,16,425,1,13,1
353,16,425,2,0,
353,16,433,4,0,
353,16,441,2,0,
353,16,451,4,0,
353,16,466,2,0,
353,16,478,3,0,
353,16,492,3,0,
353,16,496,4,0,
353,16,506,1,22,1
353,16,566,1,54,1
353,16,566,2,0,
353,16,590,4,0,
353,16,605,4,0,
353,17,50,2,0,
353,17,85,4,0,
353,17,86,4,0,
353,17,87,4,0,
353,17,92,4,0,
353,17,94,4,0,
353,17,101,1,7,
353,17,103,1,4,
353,17,104,4,0,
353,17,109,2,0,
353,17,138,4,0,
353,17,156,4,0,
353,17,164,4,0,
353,17,168,4,0,
353,17,174,1,26,
353,17,180,1,10,
353,17,182,4,0,
353,17,185,1,19,
353,17,193,2,0,
353,17,194,2,0,
353,17,207,4,0,
353,17,213,4,0,
353,17,214,4,0,
353,17,216,4,0,
353,17,218,4,0,
353,17,228,2,0,
353,17,237,4,0,
353,17,240,4,0,
353,17,241,4,0,
353,17,244,4,0,
353,17,247,1,30,
353,17,247,4,0,
353,17,259,4,0,
353,17,261,1,16,
353,17,261,4,0,
353,17,263,4,0,
353,17,269,4,0,
353,17,271,1,50,
353,17,282,1,1,
353,17,286,2,0,
353,17,288,1,46,
353,17,289,1,42,
353,17,310,2,0,
353,17,347,4,0,
353,17,371,4,0,
353,17,373,1,34,
353,17,373,4,0,
353,17,389,1,38,
353,17,399,4,0,
353,17,425,1,13,
353,17,425,2,0,
353,17,433,4,0,
353,17,441,2,0,
353,17,451,4,0,
353,17,466,2,0,
353,17,496,4,0,
353,17,506,1,22,
353,17,566,1,54,
353,17,566,2,0,
353,17,590,4,0,
353,17,605,4,0,
353,18,50,2,0,
353,18,85,4,0,
353,18,86,4,0,
353,18,87,4,0,
353,18,92,4,0,
353,18,94,4,0,
353,18,101,1,7,
353,18,103,1,4,
353,18,104,4,0,
353,18,109,2,0,
353,18,138,4,0,
353,18,156,4,0,
353,18,164,4,0,
353,18,168,4,0,
353,18,174,1,26,
353,18,180,1,10,
353,18,182,4,0,
353,18,185,1,19,
353,18,193,2,0,
353,18,194,2,0,
353,18,207,4,0,
353,18,213,4,0,
353,18,214,4,0,
353,18,216,4,0,
353,18,218,4,0,
353,18,228,2,0,
353,18,237,4,0,
353,18,240,4,0,
353,18,241,4,0,
353,18,244,4,0,
353,18,247,1,30,
353,18,247,4,0,
353,18,259,4,0,
353,18,261,1,16,
353,18,261,4,0,
353,18,263,4,0,
353,18,269,4,0,
353,18,271,1,50,
353,18,282,1,1,
353,18,286,2,0,
353,18,288,1,46,
353,18,289,1,42,
353,18,310,2,0,
353,18,347,4,0,
353,18,371,4,0,
353,18,373,1,34,
353,18,373,4,0,
353,18,389,1,38,
353,18,399,4,0,
353,18,425,1,13,
353,18,425,2,0,
353,18,433,4,0,
353,18,441,2,0,
353,18,451,4,0,
353,18,466,2,0,
353,18,496,4,0,
353,18,506,1,22,
353,18,566,1,54,
353,18,566,2,0,
353,18,590,4,0,
353,18,605,4,0,
354,5,63,4,0,
354,5,85,4,0,
354,5,87,4,0,
354,5,92,4,0,
354,5,94,4,0,
354,5,101,1,1,3
354,5,101,1,13,
354,5,103,1,1,2
354,5,103,1,8,
354,5,104,4,0,
354,5,148,4,0,
354,5,156,4,0,
354,5,168,4,0,
354,5,174,1,1,4
354,5,174,1,20,
354,5,180,1,25,
354,5,182,4,0,
354,5,185,1,39,
354,5,213,4,0,
354,5,216,4,0,
354,5,218,4,0,
354,5,237,4,0,
354,5,240,4,0,
354,5,241,4,0,
354,5,247,1,48,
354,5,247,4,0,
354,5,259,4,0,
354,5,261,1,32,
354,5,263,4,0,
354,5,269,4,0,
354,5,282,1,1,1
354,5,285,4,0,
354,5,288,1,64,
354,5,289,1,55,
354,5,289,4,0,
354,5,290,4,0,
354,5,347,4,0,
354,5,351,4,0,
354,6,34,3,0,
354,6,38,3,0,
354,6,63,4,0,
354,6,85,4,0,
354,6,86,3,0,
354,6,87,4,0,
354,6,92,4,0,
354,6,94,4,0,
354,6,101,1,1,3
354,6,101,1,13,
354,6,102,3,0,
354,6,103,1,1,2
354,6,103,1,8,
354,6,104,4,0,
354,6,118,3,0,
354,6,138,3,0,
354,6,148,4,0,
354,6,156,4,0,
354,6,164,3,0,
354,6,168,4,0,
354,6,173,3,0,
354,6,174,1,1,4
354,6,174,1,20,
354,6,180,1,25,
354,6,182,4,0,
354,6,185,1,39,
354,6,189,3,0,
354,6,196,3,0,
354,6,203,3,0,
354,6,207,3,0,
354,6,213,4,0,
354,6,214,3,0,
354,6,216,4,0,
354,6,218,4,0,
354,6,237,4,0,
354,6,240,4,0,
354,6,241,4,0,
354,6,244,3,0,
354,6,247,1,48,
354,6,247,4,0,
354,6,259,4,0,
354,6,261,1,32,
354,6,263,4,0,
354,6,269,4,0,
354,6,282,1,1,1
354,6,285,4,0,
354,6,288,1,64,
354,6,289,1,55,
354,6,289,4,0,
354,6,290,4,0,
354,6,347,4,0,
354,6,351,4,0,
354,7,34,3,0,
354,7,38,3,0,
354,7,63,4,0,
354,7,85,4,0,
354,7,86,3,0,
354,7,87,4,0,
354,7,92,4,0,
354,7,94,4,0,
354,7,101,1,1,3
354,7,101,1,13,
354,7,102,3,0,
354,7,103,1,1,2
354,7,103,1,8,
354,7,104,4,0,
354,7,118,3,0,
354,7,138,3,0,
354,7,148,4,0,
354,7,156,4,0,
354,7,164,3,0,
354,7,168,4,0,
354,7,174,1,1,4
354,7,174,1,20,
354,7,180,1,25,
354,7,182,4,0,
354,7,185,1,39,
354,7,213,4,0,
354,7,216,4,0,
354,7,218,4,0,
354,7,237,4,0,
354,7,240,4,0,
354,7,241,4,0,
354,7,247,1,48,
354,7,247,4,0,
354,7,259,4,0,
354,7,261,1,32,
354,7,263,4,0,
354,7,269,4,0,
354,7,282,1,1,1
354,7,285,4,0,
354,7,288,1,64,
354,7,289,1,55,
354,7,289,4,0,
354,7,290,4,0,
354,7,347,4,0,
354,7,351,4,0,
354,8,63,4,0,
354,8,85,4,0,
354,8,86,4,0,
354,8,87,4,0,
354,8,92,4,0,
354,8,94,4,0,
354,8,101,1,1,3
354,8,101,1,5,
354,8,103,1,1,2
354,8,103,1,8,
354,8,104,4,0,
354,8,138,4,0,
354,8,148,4,0,
354,8,156,4,0,
354,8,164,4,0,
354,8,168,4,0,
354,8,174,1,1,4
354,8,174,1,13,
354,8,180,1,16,
354,8,182,4,0,
354,8,185,1,28,
354,8,203,4,0,
354,8,207,4,0,
354,8,213,4,0,
354,8,214,4,0,
354,8,216,4,0,
354,8,218,4,0,
354,8,237,4,0,
354,8,240,4,0,
354,8,241,4,0,
354,8,244,4,0,
354,8,247,1,31,
354,8,247,4,0,
354,8,259,4,0,
354,8,261,1,23,
354,8,261,4,0,
354,8,263,4,0,
354,8,269,4,0,
354,8,271,1,66,
354,8,282,1,1,1
354,8,285,4,0,
354,8,288,1,58,
354,8,289,1,51,
354,8,289,4,0,
354,8,290,4,0,
354,8,347,4,0,
354,8,351,4,0,
354,8,363,4,0,
354,8,371,4,0,
354,8,373,1,42,
354,8,373,4,0,
354,8,374,4,0,
354,8,389,1,35,
354,8,399,4,0,
354,8,416,4,0,
354,8,421,4,0,
354,8,425,1,20,
354,8,433,4,0,
354,8,445,4,0,
354,8,451,4,0,
354,9,63,4,0,
354,9,85,4,0,
354,9,86,4,0,
354,9,87,4,0,
354,9,92,4,0,
354,9,94,4,0,
354,9,101,1,1,3
354,9,101,1,5,
354,9,103,1,1,2
354,9,103,1,8,
354,9,104,4,0,
354,9,138,4,0,
354,9,148,4,0,
354,9,156,4,0,
354,9,164,4,0,
354,9,168,4,0,
354,9,174,1,1,4
354,9,174,1,13,
354,9,180,1,16,
354,9,180,3,0,
354,9,182,4,0,
354,9,185,1,28,
354,9,189,3,0,
354,9,196,3,0,
354,9,203,4,0,
354,9,207,4,0,
354,9,213,4,0,
354,9,214,4,0,
354,9,216,4,0,
354,9,218,4,0,
354,9,237,4,0,
354,9,240,4,0,
354,9,241,4,0,
354,9,244,4,0,
354,9,247,1,31,
354,9,247,4,0,
354,9,259,4,0,
354,9,261,1,23,
354,9,261,4,0,
354,9,263,4,0,
354,9,269,4,0,
354,9,271,1,66,
354,9,271,3,0,
354,9,282,1,1,1
354,9,282,3,0,
354,9,285,4,0,
354,9,288,1,58,
354,9,289,1,51,
354,9,289,4,0,
354,9,290,4,0,
354,9,347,4,0,
354,9,351,4,0,
354,9,363,4,0,
354,9,371,4,0,
354,9,373,1,42,
354,9,373,4,0,
354,9,374,4,0,
354,9,389,1,35,
354,9,389,3,0,
354,9,399,4,0,
354,9,416,4,0,
354,9,421,4,0,
354,9,425,1,20,
354,9,433,4,0,
354,9,445,4,0,
354,9,451,4,0,
354,9,466,3,0,
354,10,29,3,0,
354,10,63,4,0,
354,10,85,4,0,
354,10,86,4,0,
354,10,87,4,0,
354,10,92,4,0,
354,10,94,4,0,
354,10,101,1,1,3
354,10,101,1,5,
354,10,103,1,1,2
354,10,103,1,8,
354,10,104,4,0,
354,10,138,4,0,
354,10,148,4,0,
354,10,156,4,0,
354,10,164,4,0,
354,10,168,4,0,
354,10,174,1,1,4
354,10,174,1,13,
354,10,180,1,16,
354,10,180,3,0,
354,10,182,4,0,
354,10,185,1,28,
354,10,189,3,0,
354,10,196,3,0,
354,10,203,4,0,
354,10,207,4,0,
354,10,213,4,0,
354,10,214,4,0,
354,10,216,4,0,
354,10,218,4,0,
354,10,220,3,0,
354,10,237,4,0,
354,10,240,4,0,
354,10,241,4,0,
354,10,244,4,0,
354,10,247,1,31,
354,10,247,4,0,
354,10,259,4,0,
354,10,261,1,23,
354,10,261,4,0,
354,10,263,4,0,
354,10,269,4,0,
354,10,271,1,66,
354,10,271,3,0,
354,10,272,3,0,
354,10,277,3,0,
354,10,282,1,1,1
354,10,282,3,0,
354,10,285,4,0,
354,10,288,1,58,
354,10,289,1,51,
354,10,289,4,0,
354,10,290,4,0,
354,10,347,4,0,
354,10,351,4,0,
354,10,363,4,0,
354,10,371,4,0,
354,10,373,1,42,
354,10,373,4,0,
354,10,374,4,0,
354,10,389,1,35,
354,10,389,3,0,
354,10,399,4,0,
354,10,416,4,0,
354,10,421,4,0,
354,10,425,1,20,
354,10,433,4,0,
354,10,445,4,0,
354,10,451,4,0,
354,10,466,3,0,
354,11,63,4,0,
354,11,85,4,0,
354,11,86,4,0,
354,11,87,4,0,
354,11,92,4,0,
354,11,94,4,0,
354,11,101,1,1,3
354,11,101,1,8,
354,11,103,1,1,2
354,11,103,1,5,
354,11,104,4,0,
354,11,138,4,0,
354,11,148,4,0,
354,11,156,4,0,
354,11,164,4,0,
354,11,168,4,0,
354,11,174,1,1,4
354,11,174,1,13,
354,11,180,1,16,
354,11,182,4,0,
354,11,185,1,28,
354,11,207,4,0,
354,11,213,4,0,
354,11,216,4,0,
354,11,218,4,0,
354,11,237,4,0,
354,11,240,4,0,
354,11,241,4,0,
354,11,244,4,0,
354,11,247,1,35,
354,11,247,4,0,
354,11,259,4,0,
354,11,261,1,23,
354,11,261,4,0,
354,11,263,4,0,
354,11,269,4,0,
354,11,271,1,75,
354,11,282,1,1,1
354,11,288,1,66,
354,11,289,1,58,
354,11,347,4,0,
354,11,371,4,0,
354,11,373,1,51,
354,11,373,4,0,
354,11,374,4,0,
354,11,389,1,42,
354,11,416,4,0,
354,11,421,4,0,
354,11,425,1,20,
354,11,433,4,0,
354,11,451,4,0,
354,11,477,4,0,
354,11,496,4,0,
354,11,506,1,31,
354,12,63,4,0,
354,12,85,4,0,
354,12,87,4,0,
354,12,92,4,0,
354,12,94,4,0,
354,12,101,1,1,3
354,12,101,1,13,
354,12,103,1,1,2
354,12,103,1,8,
354,12,104,4,0,
354,12,148,4,0,
354,12,156,4,0,
354,12,168,4,0,
354,12,174,1,1,4
354,12,174,1,20,
354,12,180,1,25,
354,12,182,4,0,
354,12,185,1,39,
354,12,213,4,0,
354,12,216,4,0,
354,12,218,4,0,
354,12,237,4,0,
354,12,240,4,0,
354,12,241,4,0,
354,12,247,1,48,
354,12,247,4,0,
354,12,259,4,0,
354,12,261,1,32,
354,12,263,4,0,
354,12,269,4,0,
354,12,282,1,1,1
354,12,285,4,0,
354,12,288,1,64,
354,12,289,1,55,
354,12,289,4,0,
354,12,290,4,0,
354,12,347,4,0,
354,12,351,4,0,
354,13,34,3,0,
354,13,38,3,0,
354,13,63,4,0,
354,13,85,4,0,
354,13,86,3,0,
354,13,87,4,0,
354,13,92,4,0,
354,13,94,4,0,
354,13,101,1,1,3
354,13,101,1,13,
354,13,102,3,0,
354,13,103,1,1,2
354,13,103,1,8,
354,13,104,4,0,
354,13,138,3,0,
354,13,148,4,0,
354,13,156,4,0,
354,13,164,3,0,
354,13,168,4,0,
354,13,171,3,0,
354,13,174,1,1,4
354,13,174,1,20,
354,13,180,1,25,
354,13,182,4,0,
354,13,185,1,39,
354,13,196,3,0,
354,13,207,3,0,
354,13,213,4,0,
354,13,216,4,0,
354,13,218,4,0,
354,13,237,4,0,
354,13,240,4,0,
354,13,241,4,0,
354,13,247,1,48,
354,13,247,4,0,
354,13,259,4,0,
354,13,261,1,32,
354,13,263,4,0,
354,13,269,4,0,
354,13,282,1,1,1
354,13,285,4,0,
354,13,288,1,64,
354,13,289,1,55,
354,13,289,4,0,
354,13,290,4,0,
354,13,347,4,0,
354,13,351,4,0,
354,14,63,4,0,
354,14,85,4,0,
354,14,86,4,0,
354,14,87,4,0,
354,14,92,4,0,
354,14,94,4,0,
354,14,101,1,1,3
354,14,101,1,7,
354,14,103,1,1,2
354,14,103,1,4,
354,14,104,4,0,
354,14,138,4,0,
354,14,148,4,0,
354,14,156,4,0,
354,14,164,4,0,
354,14,168,4,0,
354,14,174,1,1,4
354,14,174,1,19,
354,14,180,1,10,
354,14,180,3,0,
354,14,182,4,0,
354,14,185,1,22,
354,14,196,3,0,
354,14,207,4,0,
354,14,213,4,0,
354,14,214,3,0,
354,14,216,4,0,
354,14,218,4,0,
354,14,220,3,0,
354,14,237,4,0,
354,14,240,4,0,
354,14,241,4,0,
354,14,244,4,0,
354,14,247,1,30,
354,14,247,4,0,
354,14,259,4,0,
354,14,261,1,13,
354,14,261,4,0,
354,14,263,4,0,
354,14,269,4,0,
354,14,271,1,58,
354,14,271,3,0,
354,14,272,3,0,
354,14,277,3,0,
354,14,282,1,1,1
354,14,282,3,0,
354,14,285,3,0,
354,14,288,1,52,
354,14,289,1,46,
354,14,289,3,0,
354,14,347,4,0,
354,14,371,4,0,
354,14,373,1,40,
354,14,373,4,0,
354,14,374,4,0,
354,14,389,1,34,
354,14,399,3,0,
354,14,416,4,0,
354,14,421,4,0,
354,14,425,1,16,
354,14,433,4,0,
354,14,451,4,0,
354,14,477,4,0,
354,14,478,3,0,
354,14,492,3,0,
354,14,496,4,0,
354,14,506,1,26,
354,15,63,4,0,
354,15,85,4,0,
354,15,86,4,0,
354,15,87,4,0,
354,15,92,4,0,
354,15,94,4,0,
354,15,101,1,1,3
354,15,101,1,7,
354,15,103,1,1,2
354,15,103,1,4,
354,15,104,4,0,
354,15,138,4,0,
354,15,148,4,0,
354,15,156,4,0,
354,15,164,4,0,
354,15,168,4,0,
354,15,174,1,1,4
354,15,174,1,19,
354,15,180,1,10,
354,15,182,4,0,
354,15,185,1,22,
354,15,207,4,0,
354,15,213,4,0,
354,15,214,4,0,
354,15,216,4,0,
354,15,218,4,0,
354,15,237,4,0,
354,15,240,4,0,
354,15,241,4,0,
354,15,244,4,0,
354,15,247,1,30,
354,15,247,4,0,
354,15,259,4,0,
354,15,261,1,13,
354,15,261,4,0,
354,15,263,4,0,
354,15,269,4,0,
354,15,271,1,58,
354,15,282,1,1,1
354,15,288,1,52,
354,15,289,1,46,
354,15,347,4,0,
354,15,371,4,0,
354,15,373,1,40,
354,15,373,4,0,
354,15,374,4,0,
354,15,389,1,34,
354,15,399,4,0,
354,15,416,4,0,
354,15,421,4,0,
354,15,425,1,16,
354,15,433,4,0,
354,15,451,4,0,
354,15,496,4,0,
354,15,506,1,26,
354,15,590,4,0,
354,15,605,4,0,
354,15,611,4,0,
354,16,63,4,0,
354,16,85,4,0,
354,16,86,4,0,
354,16,87,4,0,
354,16,92,4,0,
354,16,94,4,0,
354,16,101,1,1,4
354,16,101,1,7,1
354,16,103,1,1,3
354,16,103,1,4,1
354,16,104,4,0,
354,16,138,4,0,
354,16,148,4,0,
354,16,156,4,0,
354,16,164,4,0,
354,16,168,4,0,
354,16,173,3,0,
354,16,174,1,26,1
354,16,180,1,1,5
354,16,180,1,10,1
354,16,180,3,0,
354,16,182,4,0,
354,16,185,1,19,1
354,16,196,3,0,
354,16,207,4,0,
354,16,213,4,0,
354,16,214,4,0,
354,16,216,4,0,
354,16,218,4,0,
354,16,220,3,0,
354,16,237,4,0,
354,16,240,4,0,
354,16,241,4,0,
354,16,244,4,0,
354,16,247,1,30,1
354,16,247,4,0,
354,16,259,4,0,
354,16,261,1,16,1
354,16,261,4,0,
354,16,263,4,0,
354,16,269,4,0,
354,16,271,1,58,1
354,16,271,3,0,
354,16,272,3,0,
354,16,277,3,0,
354,16,282,1,1,2
354,16,282,3,0,
354,16,285,3,0,
354,16,288,1,52,1
354,16,289,1,46,1
354,16,289,3,0,
354,16,290,4,0,
354,16,347,4,0,
354,16,351,3,0,
354,16,371,4,0,
354,16,373,1,34,1
354,16,373,4,0,
354,16,374,4,0,
354,16,389,1,40,1
354,16,399,4,0,
354,16,416,4,0,
354,16,421,4,0,
354,16,425,1,13,1
354,16,433,4,0,
354,16,451,4,0,
354,16,478,3,0,
354,16,492,3,0,
354,16,496,4,0,
354,16,506,1,22,1
354,16,566,1,1,1
354,16,566,1,64,1
354,16,590,4,0,
354,16,605,4,0,
354,16,611,4,0,
354,17,63,4,0,
354,17,85,4,0,
354,17,86,4,0,
354,17,87,4,0,
354,17,92,4,0,
354,17,94,4,0,
354,17,101,1,1,4
354,17,101,1,7,
354,17,103,1,1,3
354,17,103,1,4,
354,17,104,4,0,
354,17,138,4,0,
354,17,156,4,0,
354,17,164,4,0,
354,17,168,4,0,
354,17,174,1,26,
354,17,180,1,1,5
354,17,180,1,10,
354,17,182,4,0,
354,17,185,1,19,
354,17,207,4,0,
354,17,213,4,0,
354,17,214,4,0,
354,17,216,4,0,
354,17,218,4,0,
354,17,237,4,0,
354,17,240,4,0,
354,17,241,4,0,
354,17,244,4,0,
354,17,247,1,30,
354,17,247,4,0,
354,17,259,4,0,
354,17,261,1,16,
354,17,261,4,0,
354,17,263,4,0,
354,17,269,4,0,
354,17,271,1,58,
354,17,282,1,1,2
354,17,288,1,52,
354,17,289,1,46,
354,17,347,4,0,
354,17,371,4,0,
354,17,373,1,34,
354,17,373,4,0,
354,17,374,4,0,
354,17,389,1,40,
354,17,399,4,0,
354,17,416,4,0,
354,17,421,4,0,
354,17,425,1,13,
354,17,433,4,0,
354,17,451,4,0,
354,17,496,4,0,
354,17,506,1,22,
354,17,566,1,1,1
354,17,566,1,64,
354,17,590,4,0,
354,17,605,4,0,
354,17,611,4,0,
354,18,63,4,0,
354,18,85,4,0,
354,18,86,4,0,
354,18,87,4,0,
354,18,92,4,0,
354,18,94,4,0,
354,18,101,1,1,4
354,18,101,1,7,
354,18,103,1,1,3
354,18,103,1,4,
354,18,104,4,0,
354,18,138,4,0,
354,18,156,4,0,
354,18,164,4,0,
354,18,168,4,0,
354,18,174,1,26,
354,18,180,1,1,5
354,18,180,1,10,
354,18,182,4,0,
354,18,185,1,19,
354,18,207,4,0,
354,18,213,4,0,
354,18,214,4,0,
354,18,216,4,0,
354,18,218,4,0,
354,18,237,4,0,
354,18,240,4,0,
354,18,241,4,0,
354,18,244,4,0,
354,18,247,1,30,
354,18,247,4,0,
354,18,259,4,0,
354,18,261,1,16,
354,18,261,4,0,
354,18,263,4,0,
354,18,269,4,0,
354,18,271,1,58,
354,18,282,1,1,2
354,18,288,1,52,
354,18,289,1,46,
354,18,347,4,0,
354,18,371,4,0,
354,18,373,1,34,
354,18,373,4,0,
354,18,374,4,0,
354,18,389,1,40,
354,18,399,4,0,
354,18,416,4,0,
354,18,421,4,0,
354,18,425,1,13,
354,18,433,4,0,
354,18,451,4,0,
354,18,496,4,0,
354,18,506,1,22,
354,18,566,1,1,1
354,18,566,1,64,
354,18,590,4,0,
354,18,605,4,0,
354,18,611,4,0,
355,5,43,1,1,1
355,5,50,1,5,
355,5,58,4,0,
355,5,59,4,0,
355,5,92,4,0,
355,5,94,4,0,
355,5,101,1,1,2
355,5,104,4,0,
355,5,109,1,23,
355,5,148,4,0,
355,5,156,4,0,
355,5,168,4,0,
355,5,174,1,34,
355,5,182,4,0,
355,5,185,2,0,
355,5,193,1,12,
355,5,194,2,0,
355,5,212,1,45,
355,5,213,4,0,
355,5,216,4,0,
355,5,218,4,0,
355,5,220,2,0,
355,5,228,1,27,
355,5,237,4,0,
355,5,240,4,0,
355,5,241,4,0,
355,5,247,4,0,
355,5,248,1,49,
355,5,259,4,0,
355,5,261,1,38,
355,5,262,2,0,
355,5,263,4,0,
355,5,269,4,0,
355,5,285,4,0,
355,5,286,2,0,
355,5,288,2,0,
355,5,289,4,0,
355,5,290,4,0,
355,5,310,1,16,
355,5,347,4,0,
355,6,34,3,0,
355,6,38,3,0,
355,6,43,1,1,1
355,6,50,1,5,
355,6,58,4,0,
355,6,59,4,0,
355,6,92,4,0,
355,6,94,4,0,
355,6,101,1,1,2
355,6,102,3,0,
355,6,104,4,0,
355,6,109,1,23,
355,6,138,3,0,
355,6,148,4,0,
355,6,156,4,0,
355,6,164,3,0,
355,6,168,4,0,
355,6,173,3,0,
355,6,174,1,34,
355,6,182,4,0,
355,6,185,2,0,
355,6,193,1,12,
355,6,194,2,0,
355,6,196,3,0,
355,6,203,3,0,
355,6,207,3,0,
355,6,212,1,45,
355,6,213,4,0,
355,6,214,3,0,
355,6,216,4,0,
355,6,218,4,0,
355,6,220,2,0,
355,6,228,1,27,
355,6,237,4,0,
355,6,240,4,0,
355,6,241,4,0,
355,6,244,3,0,
355,6,247,4,0,
355,6,248,1,49,
355,6,259,4,0,
355,6,261,1,38,
355,6,262,2,0,
355,6,263,4,0,
355,6,269,4,0,
355,6,285,4,0,
355,6,286,2,0,
355,6,288,2,0,
355,6,289,4,0,
355,6,290,4,0,
355,6,310,1,16,
355,6,347,4,0,
355,7,34,3,0,
355,7,38,3,0,
355,7,43,1,1,1
355,7,50,1,5,
355,7,58,4,0,
355,7,59,4,0,
355,7,92,4,0,
355,7,94,4,0,
355,7,101,1,1,2
355,7,102,3,0,
355,7,104,4,0,
355,7,109,1,23,
355,7,138,3,0,
355,7,148,4,0,
355,7,156,4,0,
355,7,164,3,0,
355,7,168,4,0,
355,7,174,1,34,
355,7,182,4,0,
355,7,185,2,0,
355,7,193,1,12,
355,7,194,2,0,
355,7,212,1,45,
355,7,213,4,0,
355,7,216,4,0,
355,7,218,4,0,
355,7,220,2,0,
355,7,228,1,27,
355,7,237,4,0,
355,7,240,4,0,
355,7,241,4,0,
355,7,247,4,0,
355,7,248,1,49,
355,7,259,4,0,
355,7,261,1,38,
355,7,262,2,0,
355,7,263,4,0,
355,7,269,4,0,
355,7,285,4,0,
355,7,286,2,0,
355,7,288,2,0,
355,7,289,4,0,
355,7,290,4,0,
355,7,310,1,16,
355,7,347,4,0,
355,8,43,1,1,1
355,8,50,1,6,
355,8,58,4,0,
355,8,59,4,0,
355,8,92,4,0,
355,8,94,4,0,
355,8,101,1,1,2
355,8,104,4,0,
355,8,109,1,17,
355,8,138,4,0,
355,8,148,4,0,
355,8,156,4,0,
355,8,164,4,0,
355,8,168,4,0,
355,8,174,1,30,
355,8,182,4,0,
355,8,185,2,0,
355,8,193,1,9,
355,8,194,2,0,
355,8,203,4,0,
355,8,207,4,0,
355,8,212,1,38,
355,8,213,4,0,
355,8,214,4,0,
355,8,216,4,0,
355,8,218,4,0,
355,8,220,2,0,
355,8,228,1,25,
355,8,237,4,0,
355,8,240,4,0,
355,8,241,4,0,
355,8,244,4,0,
355,8,247,4,0,
355,8,248,1,46,
355,8,259,4,0,
355,8,261,1,33,
355,8,261,4,0,
355,8,262,2,0,
355,8,263,4,0,
355,8,269,4,0,
355,8,285,4,0,
355,8,286,2,0,
355,8,288,2,0,
355,8,289,4,0,
355,8,290,4,0,
355,8,310,1,14,
355,8,347,4,0,
355,8,363,4,0,
355,8,371,1,41,
355,8,371,4,0,
355,8,373,4,0,
355,8,374,4,0,
355,8,399,4,0,
355,8,425,1,22,
355,8,433,4,0,
355,8,445,4,0,
355,8,451,4,0,
355,8,466,2,0,
355,9,43,1,1,1
355,9,50,1,6,
355,9,58,4,0,
355,9,59,4,0,
355,9,92,4,0,
355,9,94,4,0,
355,9,101,1,1,2
355,9,104,4,0,
355,9,109,1,17,
355,9,138,4,0,
355,9,148,4,0,
355,9,156,4,0,
355,9,164,4,0,
355,9,168,4,0,
355,9,173,3,0,
355,9,174,1,30,
355,9,180,3,0,
355,9,182,4,0,
355,9,185,2,0,
355,9,193,1,9,
355,9,194,2,0,
355,9,196,3,0,
355,9,203,4,0,
355,9,207,4,0,
355,9,212,1,38,
355,9,213,4,0,
355,9,214,4,0,
355,9,216,4,0,
355,9,218,4,0,
355,9,220,2,0,
355,9,228,1,25,
355,9,237,4,0,
355,9,240,4,0,
355,9,241,4,0,
355,9,244,4,0,
355,9,247,4,0,
355,9,248,1,46,
355,9,259,4,0,
355,9,261,1,33,
355,9,261,4,0,
355,9,262,2,0,
355,9,263,4,0,
355,9,269,4,0,
355,9,271,3,0,
355,9,285,4,0,
355,9,286,2,0,
355,9,288,2,0,
355,9,289,4,0,
355,9,290,4,0,
355,9,310,1,14,
355,9,347,4,0,
355,9,363,4,0,
355,9,371,1,41,
355,9,371,4,0,
355,9,373,4,0,
355,9,374,4,0,
355,9,389,3,0,
355,9,399,4,0,
355,9,425,1,22,
355,9,433,4,0,
355,9,445,4,0,
355,9,451,4,0,
355,9,466,2,0,
355,9,466,3,0,
355,10,29,3,0,
355,10,43,1,1,1
355,10,50,1,6,
355,10,58,4,0,
355,10,59,4,0,
355,10,92,4,0,
355,10,94,4,0,
355,10,101,1,1,2
355,10,104,4,0,
355,10,109,1,17,
355,10,138,4,0,
355,10,148,4,0,
355,10,156,4,0,
355,10,164,4,0,
355,10,168,4,0,
355,10,173,3,0,
355,10,174,1,30,
355,10,180,3,0,
355,10,182,4,0,
355,10,185,2,0,
355,10,193,1,9,
355,10,194,2,0,
355,10,196,3,0,
355,10,203,4,0,
355,10,207,4,0,
355,10,212,1,38,
355,10,213,4,0,
355,10,214,4,0,
355,10,216,4,0,
355,10,218,4,0,
355,10,220,2,0,
355,10,220,3,0,
355,10,228,1,25,
355,10,237,4,0,
355,10,240,4,0,
355,10,241,4,0,
355,10,244,4,0,
355,10,247,4,0,
355,10,248,1,46,
355,10,259,4,0,
355,10,261,1,33,
355,10,261,4,0,
355,10,262,2,0,
355,10,263,4,0,
355,10,269,4,0,
355,10,271,3,0,
355,10,285,4,0,
355,10,286,2,0,
355,10,288,2,0,
355,10,289,4,0,
355,10,290,4,0,
355,10,310,1,14,
355,10,347,4,0,
355,10,356,3,0,
355,10,363,4,0,
355,10,371,1,41,
355,10,371,4,0,
355,10,373,4,0,
355,10,374,4,0,
355,10,389,3,0,
355,10,399,4,0,
355,10,425,1,22,
355,10,433,4,0,
355,10,445,4,0,
355,10,451,4,0,
355,10,466,2,0,
355,10,466,3,0,
355,11,43,1,1,1
355,11,50,1,6,
355,11,58,4,0,
355,11,59,4,0,
355,11,92,4,0,
355,11,94,4,0,
355,11,101,1,1,2
355,11,104,4,0,
355,11,109,1,17,
355,11,138,4,0,
355,11,148,4,0,
355,11,156,4,0,
355,11,164,4,0,
355,11,168,4,0,
355,11,174,1,30,
355,11,182,4,0,
355,11,185,2,0,
355,11,193,1,9,
355,11,194,2,0,
355,11,207,4,0,
355,11,212,1,41,
355,11,213,4,0,
355,11,216,4,0,
355,11,218,4,0,
355,11,220,2,0,
355,11,228,1,25,
355,11,237,4,0,
355,11,240,4,0,
355,11,241,4,0,
355,11,244,4,0,
355,11,247,4,0,
355,11,248,1,49,
355,11,259,4,0,
355,11,261,1,33,
355,11,261,4,0,
355,11,262,2,0,
355,11,263,4,0,
355,11,269,4,0,
355,11,285,2,0,
355,11,286,2,0,
355,11,288,2,0,
355,11,310,1,14,
355,11,347,4,0,
355,11,371,1,46,
355,11,371,4,0,
355,11,373,4,0,
355,11,374,4,0,
355,11,399,2,0,
355,11,425,1,22,
355,11,433,4,0,
355,11,451,4,0,
355,11,466,2,0,
355,11,477,4,0,
355,11,496,4,0,
355,11,506,1,38,
355,12,43,1,1,1
355,12,50,1,5,
355,12,58,4,0,
355,12,59,4,0,
355,12,92,4,0,
355,12,94,4,0,
355,12,101,1,1,2
355,12,104,4,0,
355,12,109,1,23,
355,12,148,4,0,
355,12,156,4,0,
355,12,168,4,0,
355,12,174,1,34,
355,12,182,4,0,
355,12,193,1,12,
355,12,212,1,45,
355,12,213,4,0,
355,12,216,4,0,
355,12,218,4,0,
355,12,228,1,27,
355,12,237,4,0,
355,12,240,4,0,
355,12,241,4,0,
355,12,247,4,0,
355,12,248,1,49,
355,12,259,4,0,
355,12,261,1,38,
355,12,263,4,0,
355,12,269,4,0,
355,12,285,4,0,
355,12,289,4,0,
355,12,290,4,0,
355,12,310,1,16,
355,12,347,4,0,
355,13,34,3,0,
355,13,38,3,0,
355,13,43,1,1,1
355,13,50,1,5,
355,13,58,4,0,
355,13,59,4,0,
355,13,92,4,0,
355,13,94,4,0,
355,13,101,1,1,2
355,13,102,3,0,
355,13,104,4,0,
355,13,109,1,23,
355,13,138,3,0,
355,13,148,4,0,
355,13,156,4,0,
355,13,164,3,0,
355,13,168,4,0,
355,13,171,3,0,
355,13,174,1,34,
355,13,182,4,0,
355,13,193,1,12,
355,13,196,3,0,
355,13,207,3,0,
355,13,212,1,45,
355,13,213,4,0,
355,13,216,4,0,
355,13,218,4,0,
355,13,228,1,27,
355,13,237,4,0,
355,13,240,4,0,
355,13,241,4,0,
355,13,247,4,0,
355,13,248,1,49,
355,13,259,4,0,
355,13,261,1,38,
355,13,263,4,0,
355,13,269,4,0,
355,13,285,4,0,
355,13,289,4,0,
355,13,290,4,0,
355,13,310,1,16,
355,13,347,4,0,
355,14,43,1,1,1
355,14,50,1,6,
355,14,58,4,0,
355,14,59,4,0,
355,14,92,4,0,
355,14,94,4,0,
355,14,101,1,1,2
355,14,104,4,0,
355,14,109,1,17,
355,14,138,4,0,
355,14,148,4,0,
355,14,156,4,0,
355,14,164,4,0,
355,14,168,4,0,
355,14,173,3,0,
355,14,174,1,30,
355,14,180,3,0,
355,14,182,4,0,
355,14,185,2,0,
355,14,193,1,9,
355,14,194,2,0,
355,14,196,3,0,
355,14,207,4,0,
355,14,212,1,41,
355,14,213,4,0,
355,14,214,3,0,
355,14,216,4,0,
355,14,218,4,0,
355,14,220,2,0,
355,14,220,3,0,
355,14,228,1,25,
355,14,237,4,0,
355,14,240,4,0,
355,14,241,4,0,
355,14,244,4,0,
355,14,247,4,0,
355,14,248,1,49,
355,14,259,4,0,
355,14,261,1,33,
355,14,261,4,0,
355,14,262,2,0,
355,14,263,4,0,
355,14,269,4,0,
355,14,271,3,0,
355,14,285,2,0,
355,14,285,3,0,
355,14,286,2,0,
355,14,288,2,0,
355,14,289,3,0,
355,14,310,1,14,
355,14,347,4,0,
355,14,356,3,0,
355,14,371,1,46,
355,14,371,4,0,
355,14,373,4,0,
355,14,374,4,0,
355,14,399,2,0,
355,14,399,3,0,
355,14,425,1,22,
355,14,433,4,0,
355,14,451,4,0,
355,14,466,2,0,
355,14,472,3,0,
355,14,477,4,0,
355,14,496,4,0,
355,14,506,1,38,
355,15,43,1,1,1
355,15,50,1,6,
355,15,58,4,0,
355,15,59,4,0,
355,15,92,4,0,
355,15,94,4,0,
355,15,101,1,1,2
355,15,104,4,0,
355,15,109,1,17,
355,15,114,2,0,
355,15,138,4,0,
355,15,148,4,0,
355,15,156,4,0,
355,15,164,4,0,
355,15,168,4,0,
355,15,174,1,30,
355,15,182,4,0,
355,15,185,2,0,
355,15,193,1,9,
355,15,194,2,0,
355,15,207,4,0,
355,15,212,1,41,
355,15,213,4,0,
355,15,214,4,0,
355,15,216,4,0,
355,15,218,4,0,
355,15,220,2,0,
355,15,228,1,25,
355,15,237,4,0,
355,15,240,4,0,
355,15,241,4,0,
355,15,244,4,0,
355,15,247,4,0,
355,15,248,1,49,
355,15,259,4,0,
355,15,261,1,33,
355,15,261,4,0,
355,15,262,2,0,
355,15,263,4,0,
355,15,269,4,0,
355,15,285,2,0,
355,15,286,2,0,
355,15,288,2,0,
355,15,310,1,14,
355,15,347,4,0,
355,15,371,1,46,
355,15,371,4,0,
355,15,373,4,0,
355,15,374,4,0,
355,15,399,2,0,
355,15,399,4,0,
355,15,425,1,22,
355,15,433,4,0,
355,15,451,4,0,
355,15,466,2,0,
355,15,496,4,0,
355,15,506,1,38,
355,15,590,4,0,
355,15,611,4,0,
355,16,43,1,1,1
355,16,50,1,6,1
355,16,58,4,0,
355,16,59,4,0,
355,16,92,4,0,
355,16,94,4,0,
355,16,101,1,1,2
355,16,104,4,0,
355,16,109,1,30,1
355,16,114,2,0,
355,16,138,4,0,
355,16,148,4,0,
355,16,156,4,0,
355,16,164,4,0,
355,16,168,4,0,
355,16,173,3,0,
355,16,174,1,33,1
355,16,180,3,0,
355,16,182,4,0,
355,16,185,2,0,
355,16,193,1,14,1
355,16,194,2,0,
355,16,196,3,0,
355,16,207,4,0,
355,16,212,1,46,1
355,16,213,4,0,
355,16,214,4,0,
355,16,216,4,0,
355,16,218,4,0,
355,16,220,2,0,
355,16,220,3,0,
355,16,228,1,22,1
355,16,237,4,0,
355,16,240,4,0,
355,16,241,4,0,
355,16,244,4,0,
355,16,247,1,41,1
355,16,247,4,0,
355,16,248,1,54,1
355,16,259,4,0,
355,16,261,1,25,1
355,16,261,4,0,
355,16,262,2,0,
355,16,263,4,0,
355,16,269,4,0,
355,16,271,3,0,
355,16,285,2,0,
355,16,285,3,0,
355,16,286,2,0,
355,16,288,2,0,
355,16,289,3,0,
355,16,290,4,0,
355,16,310,1,9,1
355,16,347,4,0,
355,16,356,3,0,
355,16,371,1,49,1
355,16,371,4,0,
355,16,373,4,0,
355,16,374,4,0,
355,16,399,2,0,
355,16,399,4,0,
355,16,425,1,17,1
355,16,433,4,0,
355,16,451,4,0,
355,16,466,2,0,
355,16,472,3,0,
355,16,496,4,0,
355,16,506,1,38,1
355,16,590,4,0,
355,16,611,4,0,
355,17,43,1,1,1
355,17,50,1,6,
355,17,58,4,0,
355,17,59,4,0,
355,17,92,4,0,
355,17,94,4,0,
355,17,101,1,1,2
355,17,104,4,0,
355,17,109,1,30,
355,17,114,2,0,
355,17,138,4,0,
355,17,156,4,0,
355,17,164,4,0,
355,17,168,4,0,
355,17,174,1,33,
355,17,182,4,0,
355,17,185,2,0,
355,17,193,1,14,
355,17,194,2,0,
355,17,207,4,0,
355,17,212,1,46,
355,17,213,4,0,
355,17,214,4,0,
355,17,216,4,0,
355,17,218,4,0,
355,17,220,2,0,
355,17,228,1,22,
355,17,237,4,0,
355,17,240,4,0,
355,17,241,4,0,
355,17,244,4,0,
355,17,247,1,41,
355,17,247,4,0,
355,17,248,1,54,
355,17,259,4,0,
355,17,261,1,25,
355,17,261,4,0,
355,17,262,2,0,
355,17,263,4,0,
355,17,269,4,0,
355,17,285,2,0,
355,17,286,2,0,
355,17,288,2,0,
355,17,310,1,9,
355,17,347,4,0,
355,17,371,1,49,
355,17,371,4,0,
355,17,373,4,0,
355,17,374,4,0,
355,17,399,2,0,
355,17,399,4,0,
355,17,425,1,17,
355,17,433,4,0,
355,17,451,4,0,
355,17,466,2,0,
355,17,496,4,0,
355,17,506,1,38,
355,17,590,4,0,
355,17,611,4,0,
355,18,43,1,1,1
355,18,50,1,6,
355,18,58,4,0,
355,18,59,4,0,
355,18,92,4,0,
355,18,94,4,0,
355,18,101,1,1,2
355,18,104,4,0,
355,18,109,1,30,
355,18,114,2,0,
355,18,138,4,0,
355,18,156,4,0,
355,18,164,4,0,
355,18,168,4,0,
355,18,174,1,33,
355,18,182,4,0,
355,18,185,2,0,
355,18,193,1,14,
355,18,194,2,0,
355,18,207,4,0,
355,18,212,1,46,
355,18,213,4,0,
355,18,214,4,0,
355,18,216,4,0,
355,18,218,4,0,
355,18,220,2,0,
355,18,228,1,22,
355,18,237,4,0,
355,18,240,4,0,
355,18,241,4,0,
355,18,244,4,0,
355,18,247,1,41,
355,18,247,4,0,
355,18,248,1,54,
355,18,259,4,0,
355,18,261,1,25,
355,18,261,4,0,
355,18,262,2,0,
355,18,263,4,0,
355,18,269,4,0,
355,18,285,2,0,
355,18,286,2,0,
355,18,288,2,0,
355,18,310,1,9,
355,18,347,4,0,
355,18,371,1,49,
355,18,371,4,0,
355,18,373,4,0,
355,18,374,4,0,
355,18,399,2,0,
355,18,399,4,0,
355,18,425,1,17,
355,18,433,4,0,
355,18,451,4,0,
355,18,466,2,0,
355,18,496,4,0,
355,18,506,1,38,
355,18,590,4,0,
355,18,611,4,0,
356,5,20,1,1,1
356,5,43,1,1,2
356,5,50,1,1,4
356,5,50,1,5,
356,5,58,4,0,
356,5,59,4,0,
356,5,63,4,0,
356,5,70,4,0,
356,5,89,4,0,
356,5,92,4,0,
356,5,94,4,0,
356,5,101,1,1,3
356,5,104,4,0,
356,5,109,1,23,
356,5,148,4,0,
356,5,156,4,0,
356,5,168,4,0,
356,5,174,1,34,
356,5,182,4,0,
356,5,193,1,12,
356,5,212,1,51,
356,5,213,4,0,
356,5,216,4,0,
356,5,218,4,0,
356,5,228,1,27,
356,5,237,4,0,
356,5,240,4,0,
356,5,241,4,0,
356,5,247,4,0,
356,5,248,1,58,
356,5,249,4,0,
356,5,259,4,0,
356,5,261,1,41,
356,5,263,4,0,
356,5,264,4,0,
356,5,269,4,0,
356,5,285,4,0,
356,5,289,4,0,
356,5,290,4,0,
356,5,310,1,16,
356,5,317,4,0,
356,5,325,1,37,
356,5,347,4,0,
356,6,5,3,0,
356,6,7,3,0,
356,6,8,3,0,
356,6,9,3,0,
356,6,20,1,1,1
356,6,25,3,0,
356,6,34,3,0,
356,6,38,3,0,
356,6,43,1,1,2
356,6,50,1,1,4
356,6,50,1,5,
356,6,58,4,0,
356,6,59,4,0,
356,6,63,4,0,
356,6,68,3,0,
356,6,69,3,0,
356,6,70,4,0,
356,6,89,4,0,
356,6,92,4,0,
356,6,94,4,0,
356,6,101,1,1,3
356,6,102,3,0,
356,6,104,4,0,
356,6,109,1,23,
356,6,118,3,0,
356,6,138,3,0,
356,6,148,4,0,
356,6,156,4,0,
356,6,157,3,0,
356,6,164,3,0,
356,6,168,4,0,
356,6,173,3,0,
356,6,174,1,34,
356,6,182,4,0,
356,6,189,3,0,
356,6,193,1,12,
356,6,196,3,0,
356,6,203,3,0,
356,6,207,3,0,
356,6,212,1,51,
356,6,213,4,0,
356,6,214,3,0,
356,6,216,4,0,
356,6,218,4,0,
356,6,223,3,0,
356,6,228,1,27,
356,6,237,4,0,
356,6,240,4,0,
356,6,241,4,0,
356,6,244,3,0,
356,6,247,4,0,
356,6,248,1,58,
356,6,249,4,0,
356,6,259,4,0,
356,6,261,1,41,
356,6,263,4,0,
356,6,264,4,0,
356,6,269,4,0,
356,6,285,4,0,
356,6,289,4,0,
356,6,290,4,0,
356,6,310,1,16,
356,6,317,4,0,
356,6,325,1,37,
356,6,347,4,0,
356,7,5,3,0,
356,7,20,1,1,1
356,7,25,3,0,
356,7,34,3,0,
356,7,38,3,0,
356,7,43,1,1,2
356,7,50,1,1,4
356,7,50,1,5,
356,7,58,4,0,
356,7,59,4,0,
356,7,63,4,0,
356,7,68,3,0,
356,7,69,3,0,
356,7,70,4,0,
356,7,89,4,0,
356,7,92,4,0,
356,7,94,4,0,
356,7,101,1,1,3
356,7,102,3,0,
356,7,104,4,0,
356,7,109,1,23,
356,7,118,3,0,
356,7,138,3,0,
356,7,148,4,0,
356,7,156,4,0,
356,7,157,3,0,
356,7,164,3,0,
356,7,168,4,0,
356,7,174,1,34,
356,7,182,4,0,
356,7,193,1,12,
356,7,212,1,51,
356,7,213,4,0,
356,7,216,4,0,
356,7,218,4,0,
356,7,228,1,27,
356,7,237,4,0,
356,7,240,4,0,
356,7,241,4,0,
356,7,247,4,0,
356,7,248,1,58,
356,7,249,4,0,
356,7,259,4,0,
356,7,261,1,41,
356,7,263,4,0,
356,7,264,4,0,
356,7,269,4,0,
356,7,285,4,0,
356,7,289,4,0,
356,7,290,4,0,
356,7,310,1,16,
356,7,317,4,0,
356,7,325,1,37,
356,7,347,4,0,
356,8,7,1,1,1
356,8,8,1,1,2
356,8,9,1,1,3
356,8,20,1,1,5
356,8,43,1,1,6
356,8,50,1,1,8
356,8,50,1,6,
356,8,58,4,0,
356,8,59,4,0,
356,8,63,4,0,
356,8,70,4,0,
356,8,89,4,0,
356,8,92,4,0,
356,8,94,4,0,
356,8,101,1,1,7
356,8,104,4,0,
356,8,109,1,17,
356,8,138,4,0,
356,8,148,4,0,
356,8,156,4,0,
356,8,157,4,0,
356,8,164,4,0,
356,8,168,4,0,
356,8,174,1,30,
356,8,182,4,0,
356,8,193,1,9,
356,8,203,4,0,
356,8,207,4,0,
356,8,212,1,43,
356,8,213,4,0,
356,8,214,4,0,
356,8,216,4,0,
356,8,218,4,0,
356,8,228,1,25,
356,8,237,4,0,
356,8,240,4,0,
356,8,241,4,0,
356,8,244,4,0,
356,8,247,4,0,
356,8,248,1,61,
356,8,249,4,0,
356,8,259,4,0,
356,8,261,1,33,
356,8,261,4,0,
356,8,263,4,0,
356,8,264,4,0,
356,8,269,4,0,
356,8,280,4,0,
356,8,285,4,0,
356,8,289,4,0,
356,8,290,4,0,
356,8,310,1,14,
356,8,317,4,0,
356,8,325,1,37,
356,8,347,4,0,
356,8,356,1,1,4
356,8,363,4,0,
356,8,371,1,51,
356,8,371,4,0,
356,8,373,4,0,
356,8,374,4,0,
356,8,399,4,0,
356,8,416,4,0,
356,8,425,1,22,
356,8,433,4,0,
356,8,445,4,0,
356,8,451,4,0,
356,9,7,1,1,1
356,9,7,3,0,
356,9,8,1,1,2
356,9,8,3,0,
356,9,9,1,1,3
356,9,9,3,0,
356,9,20,1,1,5
356,9,43,1,1,6
356,9,50,1,1,8
356,9,50,1,6,
356,9,58,4,0,
356,9,59,4,0,
356,9,63,4,0,
356,9,70,4,0,
356,9,89,4,0,
356,9,92,4,0,
356,9,94,4,0,
356,9,101,1,1,7
356,9,104,4,0,
356,9,109,1,17,
356,9,138,4,0,
356,9,148,4,0,
356,9,156,4,0,
356,9,157,4,0,
356,9,164,4,0,
356,9,168,4,0,
356,9,173,3,0,
356,9,174,1,30,
356,9,180,3,0,
356,9,182,4,0,
356,9,189,3,0,
356,9,193,1,9,
356,9,196,3,0,
356,9,203,4,0,
356,9,207,4,0,
356,9,212,1,43,
356,9,213,4,0,
356,9,214,4,0,
356,9,216,4,0,
356,9,218,4,0,
356,9,228,1,25,
356,9,237,4,0,
356,9,240,4,0,
356,9,241,4,0,
356,9,244,4,0,
356,9,247,4,0,
356,9,248,1,61,
356,9,249,4,0,
356,9,259,4,0,
356,9,261,1,33,
356,9,261,4,0,
356,9,263,4,0,
356,9,264,4,0,
356,9,269,4,0,
356,9,271,3,0,
356,9,280,4,0,
356,9,285,4,0,
356,9,289,4,0,
356,9,290,4,0,
356,9,310,1,14,
356,9,317,4,0,
356,9,325,1,37,
356,9,347,4,0,
356,9,356,1,1,4
356,9,363,4,0,
356,9,371,1,51,
356,9,371,4,0,
356,9,373,4,0,
356,9,374,4,0,
356,9,389,3,0,
356,9,399,4,0,
356,9,416,4,0,
356,9,425,1,22,
356,9,433,4,0,
356,9,445,4,0,
356,9,451,4,0,
356,9,466,3,0,
356,10,7,1,1,1
356,10,7,3,0,
356,10,8,1,1,2
356,10,8,3,0,
356,10,9,1,1,3
356,10,9,3,0,
356,10,20,1,1,5
356,10,29,3,0,
356,10,43,1,1,6
356,10,50,1,1,8
356,10,50,1,6,
356,10,58,4,0,
356,10,59,4,0,
356,10,63,4,0,
356,10,70,4,0,
356,10,89,4,0,
356,10,92,4,0,
356,10,94,4,0,
356,10,101,1,1,7
356,10,104,4,0,
356,10,109,1,17,
356,10,138,4,0,
356,10,148,4,0,
356,10,156,4,0,
356,10,157,4,0,
356,10,164,4,0,
356,10,168,4,0,
356,10,173,3,0,
356,10,174,1,30,
356,10,180,3,0,
356,10,182,4,0,
356,10,189,3,0,
356,10,193,1,9,
356,10,196,3,0,
356,10,203,4,0,
356,10,207,4,0,
356,10,212,1,43,
356,10,213,4,0,
356,10,214,4,0,
356,10,216,4,0,
356,10,218,4,0,
356,10,220,3,0,
356,10,228,1,25,
356,10,237,4,0,
356,10,240,4,0,
356,10,241,4,0,
356,10,244,4,0,
356,10,247,4,0,
356,10,248,1,61,
356,10,249,4,0,
356,10,259,4,0,
356,10,261,1,33,
356,10,261,4,0,
356,10,263,4,0,
356,10,264,4,0,
356,10,269,4,0,
356,10,271,3,0,
356,10,280,4,0,
356,10,285,4,0,
356,10,289,4,0,
356,10,290,4,0,
356,10,310,1,14,
356,10,317,4,0,
356,10,325,1,37,
356,10,347,4,0,
356,10,356,1,1,4
356,10,356,3,0,
356,10,363,4,0,
356,10,371,1,51,
356,10,371,4,0,
356,10,373,4,0,
356,10,374,4,0,
356,10,389,3,0,
356,10,399,4,0,
356,10,416,4,0,
356,10,425,1,22,
356,10,433,4,0,
356,10,445,4,0,
356,10,451,4,0,
356,10,466,3,0,
356,11,7,1,1,1
356,11,8,1,1,2
356,11,9,1,1,3
356,11,20,1,1,5
356,11,43,1,1,6
356,11,50,1,1,8
356,11,50,1,6,
356,11,58,4,0,
356,11,59,4,0,
356,11,63,4,0,
356,11,70,4,0,
356,11,89,4,0,
356,11,92,4,0,
356,11,94,4,0,
356,11,101,1,1,7
356,11,104,4,0,
356,11,109,1,17,
356,11,138,4,0,
356,11,148,4,0,
356,11,156,4,0,
356,11,157,4,0,
356,11,164,4,0,
356,11,168,4,0,
356,11,174,1,30,
356,11,182,4,0,
356,11,193,1,9,
356,11,207,4,0,
356,11,212,1,49,
356,11,213,4,0,
356,11,216,4,0,
356,11,218,4,0,
356,11,228,1,25,
356,11,237,4,0,
356,11,240,4,0,
356,11,241,4,0,
356,11,244,4,0,
356,11,247,4,0,
356,11,248,1,61,
356,11,249,4,0,
356,11,259,4,0,
356,11,261,1,33,
356,11,261,4,0,
356,11,263,4,0,
356,11,269,4,0,
356,11,280,4,0,
356,11,310,1,14,
356,11,317,4,0,
356,11,325,1,37,
356,11,347,4,0,
356,11,356,1,1,4
356,11,371,1,58,
356,11,371,4,0,
356,11,373,4,0,
356,11,374,4,0,
356,11,416,4,0,
356,11,425,1,22,
356,11,433,4,0,
356,11,451,4,0,
356,11,477,4,0,
356,11,496,4,0,
356,11,506,1,42,
356,11,523,4,0,
356,12,20,1,1,1
356,12,43,1,1,2
356,12,50,1,1,4
356,12,50,1,5,
356,12,58,4,0,
356,12,59,4,0,
356,12,63,4,0,
356,12,70,4,0,
356,12,89,4,0,
356,12,92,4,0,
356,12,94,4,0,
356,12,101,1,1,3
356,12,104,4,0,
356,12,109,1,23,
356,12,148,4,0,
356,12,156,4,0,
356,12,168,4,0,
356,12,174,1,34,
356,12,182,4,0,
356,12,193,1,12,
356,12,212,1,51,
356,12,213,4,0,
356,12,216,4,0,
356,12,218,4,0,
356,12,228,1,27,
356,12,237,4,0,
356,12,240,4,0,
356,12,241,4,0,
356,12,247,4,0,
356,12,248,1,58,
356,12,249,4,0,
356,12,259,4,0,
356,12,261,1,41,
356,12,263,4,0,
356,12,264,4,0,
356,12,269,4,0,
356,12,285,4,0,
356,12,289,4,0,
356,12,290,4,0,
356,12,310,1,16,
356,12,317,4,0,
356,12,325,1,37,
356,12,347,4,0,
356,13,20,1,1,1
356,13,34,3,0,
356,13,38,3,0,
356,13,43,1,1,2
356,13,50,1,1,4
356,13,50,1,5,
356,13,58,4,0,
356,13,59,4,0,
356,13,63,4,0,
356,13,69,3,0,
356,13,70,4,0,
356,13,89,4,0,
356,13,92,4,0,
356,13,94,4,0,
356,13,101,1,1,3
356,13,102,3,0,
356,13,104,4,0,
356,13,109,1,23,
356,13,138,3,0,
356,13,148,4,0,
356,13,156,4,0,
356,13,164,3,0,
356,13,168,4,0,
356,13,171,3,0,
356,13,174,1,34,
356,13,182,4,0,
356,13,193,1,12,
356,13,196,3,0,
356,13,207,3,0,
356,13,212,1,51,
356,13,213,4,0,
356,13,216,4,0,
356,13,218,4,0,
356,13,228,1,27,
356,13,237,4,0,
356,13,240,4,0,
356,13,241,4,0,
356,13,247,4,0,
356,13,248,1,58,
356,13,249,4,0,
356,13,259,4,0,
356,13,261,1,41,
356,13,263,4,0,
356,13,264,4,0,
356,13,269,4,0,
356,13,285,4,0,
356,13,289,4,0,
356,13,290,4,0,
356,13,310,1,16,
356,13,317,4,0,
356,13,325,1,37,
356,13,347,4,0,
356,14,7,1,1,1
356,14,7,3,0,
356,14,8,1,1,2
356,14,8,3,0,
356,14,9,1,1,3
356,14,9,3,0,
356,14,20,1,1,5
356,14,20,3,0,
356,14,43,1,1,6
356,14,50,1,1,8
356,14,50,1,6,
356,14,58,4,0,
356,14,59,4,0,
356,14,63,4,0,
356,14,70,4,0,
356,14,89,4,0,
356,14,92,4,0,
356,14,94,4,0,
356,14,101,1,1,7
356,14,104,4,0,
356,14,109,1,17,
356,14,138,4,0,
356,14,148,4,0,
356,14,156,4,0,
356,14,157,4,0,
356,14,164,4,0,
356,14,168,4,0,
356,14,173,3,0,
356,14,174,1,30,
356,14,180,3,0,
356,14,182,4,0,
356,14,193,1,9,
356,14,196,3,0,
356,14,207,4,0,
356,14,212,1,49,
356,14,213,4,0,
356,14,214,3,0,
356,14,216,4,0,
356,14,218,4,0,
356,14,220,3,0,
356,14,228,1,25,
356,14,237,4,0,
356,14,240,4,0,
356,14,241,4,0,
356,14,244,4,0,
356,14,247,4,0,
356,14,248,1,61,
356,14,249,4,0,
356,14,259,4,0,
356,14,261,1,33,
356,14,261,4,0,
356,14,263,4,0,
356,14,269,4,0,
356,14,271,3,0,
356,14,280,4,0,
356,14,285,3,0,
356,14,289,3,0,
356,14,310,1,14,
356,14,317,4,0,
356,14,325,1,37,
356,14,347,4,0,
356,14,356,1,1,4
356,14,356,3,0,
356,14,371,1,58,
356,14,371,4,0,
356,14,373,4,0,
356,14,374,4,0,
356,14,399,3,0,
356,14,416,4,0,
356,14,425,1,22,
356,14,433,4,0,
356,14,451,4,0,
356,14,472,3,0,
356,14,477,4,0,
356,14,496,4,0,
356,14,506,1,42,
356,14,523,4,0,
356,15,7,1,1,1
356,15,8,1,1,2
356,15,9,1,1,3
356,15,20,1,1,5
356,15,43,1,1,6
356,15,50,1,1,8
356,15,50,1,6,
356,15,58,4,0,
356,15,59,4,0,
356,15,63,4,0,
356,15,70,4,0,
356,15,89,4,0,
356,15,92,4,0,
356,15,94,4,0,
356,15,101,1,1,7
356,15,104,4,0,
356,15,109,1,17,
356,15,138,4,0,
356,15,148,4,0,
356,15,156,4,0,
356,15,157,4,0,
356,15,164,4,0,
356,15,168,4,0,
356,15,174,1,30,
356,15,182,4,0,
356,15,193,1,9,
356,15,207,4,0,
356,15,212,1,49,
356,15,213,4,0,
356,15,214,4,0,
356,15,216,4,0,
356,15,218,4,0,
356,15,228,1,25,
356,15,237,4,0,
356,15,240,4,0,
356,15,241,4,0,
356,15,244,4,0,
356,15,247,4,0,
356,15,248,1,61,
356,15,249,4,0,
356,15,259,4,0,
356,15,261,1,33,
356,15,261,4,0,
356,15,263,4,0,
356,15,269,4,0,
356,15,280,4,0,
356,15,310,1,14,
356,15,317,4,0,
356,15,325,1,37,
356,15,347,4,0,
356,15,356,1,1,4
356,15,371,1,58,
356,15,371,4,0,
356,15,373,4,0,
356,15,374,4,0,
356,15,399,4,0,
356,15,416,4,0,
356,15,425,1,22,
356,15,433,4,0,
356,15,451,4,0,
356,15,496,4,0,
356,15,506,1,42,
356,15,523,4,0,
356,15,590,4,0,
356,15,611,4,0,
356,15,612,4,0,
356,16,7,1,1,2
356,16,7,3,0,
356,16,8,1,1,3
356,16,8,3,0,
356,16,9,1,1,4
356,16,9,3,0,
356,16,20,1,1,6
356,16,20,3,0,
356,16,43,1,1,7
356,16,50,1,1,9
356,16,50,1,6,1
356,16,58,4,0,
356,16,59,4,0,
356,16,63,4,0,
356,16,70,4,0,
356,16,89,4,0,
356,16,92,4,0,
356,16,94,4,0,
356,16,101,1,1,8
356,16,104,4,0,
356,16,109,1,30,1
356,16,138,4,0,
356,16,148,4,0,
356,16,156,4,0,
356,16,157,4,0,
356,16,164,4,0,
356,16,168,4,0,
356,16,173,3,0,
356,16,174,1,33,1
356,16,180,3,0,
356,16,182,4,0,
356,16,193,1,14,1
356,16,196,3,0,
356,16,207,4,0,
356,16,212,1,52,1
356,16,213,4,0,
356,16,214,4,0,
356,16,216,4,0,
356,16,218,4,0,
356,16,220,3,0,
356,16,228,1,22,1
356,16,237,4,0,
356,16,240,4,0,
356,16,241,4,0,
356,16,244,4,0,
356,16,247,1,45,1
356,16,247,4,0,
356,16,248,1,1,1
356,16,248,1,64,1
356,16,249,4,0,
356,16,259,4,0,
356,16,261,1,25,1
356,16,261,4,0,
356,16,263,4,0,
356,16,264,3,0,
356,16,269,4,0,
356,16,271,3,0,
356,16,280,4,0,
356,16,285,3,0,
356,16,289,3,0,
356,16,290,4,0,
356,16,310,1,1,10
356,16,310,1,9,1
356,16,317,4,0,
356,16,325,1,37,1
356,16,347,4,0,
356,16,356,1,1,5
356,16,356,3,0,
356,16,371,1,57,1
356,16,371,4,0,
356,16,373,4,0,
356,16,374,4,0,
356,16,399,4,0,
356,16,416,4,0,
356,16,425,1,17,1
356,16,433,4,0,
356,16,451,4,0,
356,16,472,3,0,
356,16,496,4,0,
356,16,506,1,40,1
356,16,523,4,0,
356,16,590,4,0,
356,16,611,4,0,
356,16,612,4,0,
356,17,7,1,1,3
356,17,8,1,1,4
356,17,9,1,1,5
356,17,20,1,1,7
356,17,43,1,1,8
356,17,50,1,1,10
356,17,50,1,6,
356,17,58,4,0,
356,17,59,4,0,
356,17,63,4,0,
356,17,89,4,0,
356,17,92,4,0,
356,17,94,4,0,
356,17,101,1,1,9
356,17,104,4,0,
356,17,109,1,30,
356,17,138,4,0,
356,17,156,4,0,
356,17,157,4,0,
356,17,164,4,0,
356,17,168,4,0,
356,17,174,1,33,
356,17,182,4,0,
356,17,193,1,14,
356,17,207,4,0,
356,17,212,1,52,
356,17,213,4,0,
356,17,214,4,0,
356,17,216,4,0,
356,17,218,4,0,
356,17,228,1,22,
356,17,237,4,0,
356,17,240,4,0,
356,17,241,4,0,
356,17,244,4,0,
356,17,247,1,45,
356,17,247,4,0,
356,17,248,1,1,2
356,17,248,1,64,
356,17,259,4,0,
356,17,261,1,25,
356,17,261,4,0,
356,17,263,4,0,
356,17,269,4,0,
356,17,280,4,0,
356,17,310,1,1,11
356,17,310,1,9,
356,17,317,4,0,
356,17,325,1,0,
356,17,325,1,1,1
356,17,347,4,0,
356,17,356,1,1,6
356,17,371,1,57,
356,17,371,4,0,
356,17,373,4,0,
356,17,374,4,0,
356,17,399,4,0,
356,17,416,4,0,
356,17,425,1,17,
356,17,433,4,0,
356,17,451,4,0,
356,17,496,4,0,
356,17,506,1,40,
356,17,523,4,0,
356,17,590,4,0,
356,17,611,4,0,
356,18,7,1,1,3
356,18,8,1,1,4
356,18,9,1,1,5
356,18,20,1,1,7
356,18,43,1,1,8
356,18,50,1,1,10
356,18,50,1,6,
356,18,58,4,0,
356,18,59,4,0,
356,18,63,4,0,
356,18,89,4,0,
356,18,92,4,0,
356,18,94,4,0,
356,18,101,1,1,9
356,18,104,4,0,
356,18,109,1,30,
356,18,138,4,0,
356,18,156,4,0,
356,18,157,4,0,
356,18,164,4,0,
356,18,168,4,0,
356,18,174,1,33,
356,18,182,4,0,
356,18,193,1,14,
356,18,207,4,0,
356,18,212,1,52,
356,18,213,4,0,
356,18,214,4,0,
356,18,216,4,0,
356,18,218,4,0,
356,18,228,1,22,
356,18,237,4,0,
356,18,240,4,0,
356,18,241,4,0,
356,18,244,4,0,
356,18,247,1,45,
356,18,247,4,0,
356,18,248,1,1,2
356,18,248,1,64,
356,18,259,4,0,
356,18,261,1,25,
356,18,261,4,0,
356,18,263,4,0,
356,18,269,4,0,
356,18,280,4,0,
356,18,310,1,1,11
356,18,310,1,9,
356,18,317,4,0,
356,18,325,1,0,
356,18,325,1,1,1
356,18,347,4,0,
356,18,356,1,1,6
356,18,371,1,57,
356,18,371,4,0,
356,18,373,4,0,
356,18,374,4,0,
356,18,399,4,0,
356,18,416,4,0,
356,18,425,1,17,
356,18,433,4,0,
356,18,451,4,0,
356,18,496,4,0,
356,18,506,1,40,
356,18,523,4,0,
356,18,590,4,0,
356,18,611,4,0,
357,5,13,2,0,
357,5,15,4,0,
357,5,16,1,1,2
357,5,18,1,27,
357,5,19,4,0,
357,5,21,2,0,
357,5,23,1,17,
357,5,29,2,0,
357,5,34,1,37,
357,5,43,1,1,1
357,5,46,4,0,
357,5,63,4,0,
357,5,70,4,0,
357,5,73,2,0,
357,5,74,1,7,
357,5,75,1,11,
357,5,76,1,41,
357,5,76,4,0,
357,5,89,4,0,
357,5,92,4,0,
357,5,104,4,0,
357,5,148,4,0,
357,5,156,4,0,
357,5,182,4,0,
357,5,202,4,0,
357,5,211,4,0,
357,5,213,4,0,
357,5,216,4,0,
357,5,218,4,0,
357,5,219,4,0,
357,5,230,1,21,
357,5,235,1,47,
357,5,237,4,0,
357,5,241,4,0,
357,5,249,4,0,
357,5,263,4,0,
357,5,267,2,0,
357,5,290,4,0,
357,5,331,4,0,
357,5,332,4,0,
357,5,345,1,31,
357,6,13,2,0,
357,6,14,3,0,
357,6,15,4,0,
357,6,16,1,1,2
357,6,18,1,27,
357,6,19,4,0,
357,6,21,2,0,
357,6,23,1,17,
357,6,29,2,0,
357,6,34,1,37,
357,6,34,3,0,
357,6,38,3,0,
357,6,43,1,1,1
357,6,46,4,0,
357,6,63,4,0,
357,6,70,4,0,
357,6,73,2,0,
357,6,74,1,7,
357,6,75,1,11,
357,6,76,1,41,
357,6,76,4,0,
357,6,89,4,0,
357,6,92,4,0,
357,6,102,3,0,
357,6,104,4,0,
357,6,148,4,0,
357,6,156,4,0,
357,6,164,3,0,
357,6,173,3,0,
357,6,182,4,0,
357,6,189,3,0,
357,6,202,4,0,
357,6,203,3,0,
357,6,207,3,0,
357,6,210,3,0,
357,6,211,4,0,
357,6,213,4,0,
357,6,214,3,0,
357,6,216,4,0,
357,6,218,4,0,
357,6,219,4,0,
357,6,230,1,21,
357,6,235,1,47,
357,6,237,4,0,
357,6,241,4,0,
357,6,249,4,0,
357,6,263,4,0,
357,6,267,2,0,
357,6,290,4,0,
357,6,331,4,0,
357,6,332,4,0,
357,6,345,1,31,
357,7,13,2,0,
357,7,14,3,0,
357,7,15,4,0,
357,7,16,1,1,2
357,7,18,1,27,
357,7,19,4,0,
357,7,21,2,0,
357,7,23,1,17,
357,7,29,2,0,
357,7,34,1,37,
357,7,34,3,0,
357,7,38,3,0,
357,7,43,1,1,1
357,7,46,4,0,
357,7,63,4,0,
357,7,70,4,0,
357,7,73,2,0,
357,7,74,1,7,
357,7,75,1,11,
357,7,76,1,41,
357,7,76,4,0,
357,7,89,4,0,
357,7,92,4,0,
357,7,102,3,0,
357,7,104,4,0,
357,7,148,4,0,
357,7,156,4,0,
357,7,164,3,0,
357,7,182,4,0,
357,7,202,4,0,
357,7,211,4,0,
357,7,213,4,0,
357,7,216,4,0,
357,7,218,4,0,
357,7,219,4,0,
357,7,230,1,21,
357,7,235,1,47,
357,7,237,4,0,
357,7,241,4,0,
357,7,249,4,0,
357,7,263,4,0,
357,7,267,2,0,
357,7,290,4,0,
357,7,331,4,0,
357,7,332,4,0,
357,7,345,1,31,
357,8,13,2,0,
357,8,14,4,0,
357,8,15,4,0,
357,8,16,1,1,2
357,8,18,1,27,
357,8,19,4,0,
357,8,21,2,0,
357,8,23,1,17,
357,8,29,2,0,
357,8,34,1,37,
357,8,43,1,1,1
357,8,46,4,0,
357,8,63,4,0,
357,8,70,4,0,
357,8,73,2,0,
357,8,74,1,7,
357,8,75,1,11,
357,8,76,1,51,
357,8,76,4,0,
357,8,89,4,0,
357,8,92,4,0,
357,8,104,4,0,
357,8,148,4,0,
357,8,156,4,0,
357,8,164,4,0,
357,8,174,2,0,
357,8,182,4,0,
357,8,202,4,0,
357,8,203,4,0,
357,8,207,4,0,
357,8,211,4,0,
357,8,213,4,0,
357,8,214,4,0,
357,8,216,4,0,
357,8,218,4,0,
357,8,219,4,0,
357,8,230,1,21,
357,8,235,1,41,
357,8,235,2,0,
357,8,237,4,0,
357,8,241,4,0,
357,8,249,4,0,
357,8,263,4,0,
357,8,267,2,0,
357,8,290,4,0,
357,8,318,4,0,
357,8,331,4,0,
357,8,332,4,0,
357,8,345,1,31,
357,8,348,2,0,
357,8,355,4,0,
357,8,363,1,57,
357,8,363,4,0,
357,8,403,1,47,
357,8,412,4,0,
357,8,416,4,0,
357,8,432,4,0,
357,8,437,1,61,
357,8,437,2,0,
357,8,445,4,0,
357,8,447,4,0,
357,9,13,2,0,
357,9,14,4,0,
357,9,15,4,0,
357,9,16,1,1,2
357,9,18,1,27,
357,9,19,4,0,
357,9,21,2,0,
357,9,23,1,17,
357,9,29,2,0,
357,9,34,1,37,
357,9,43,1,1,1
357,9,46,4,0,
357,9,63,4,0,
357,9,70,4,0,
357,9,73,2,0,
357,9,74,1,7,
357,9,75,1,11,
357,9,76,1,51,
357,9,76,4,0,
357,9,89,4,0,
357,9,92,4,0,
357,9,104,4,0,
357,9,148,4,0,
357,9,156,4,0,
357,9,164,4,0,
357,9,173,3,0,
357,9,174,2,0,
357,9,182,4,0,
357,9,189,3,0,
357,9,200,3,0,
357,9,202,4,0,
357,9,203,4,0,
357,9,207,4,0,
357,9,210,3,0,
357,9,211,4,0,
357,9,213,4,0,
357,9,214,4,0,
357,9,216,4,0,
357,9,218,4,0,
357,9,219,4,0,
357,9,230,1,21,
357,9,235,1,41,
357,9,235,2,0,
357,9,235,3,0,
357,9,237,4,0,
357,9,239,3,0,
357,9,241,4,0,
357,9,249,4,0,
357,9,263,4,0,
357,9,267,2,0,
357,9,290,4,0,
357,9,314,3,0,
357,9,318,4,0,
357,9,331,4,0,
357,9,332,4,0,
357,9,345,1,31,
357,9,348,2,0,
357,9,355,4,0,
357,9,363,1,57,
357,9,363,4,0,
357,9,403,1,47,
357,9,412,4,0,
357,9,416,4,0,
357,9,432,4,0,
357,9,437,1,61,
357,9,437,2,0,
357,9,445,4,0,
357,9,447,4,0,
357,9,466,3,0,
357,10,13,2,0,
357,10,14,4,0,
357,10,15,4,0,
357,10,16,1,1,2
357,10,18,1,27,
357,10,19,4,0,
357,10,21,2,0,
357,10,23,1,17,
357,10,29,2,0,
357,10,29,3,0,
357,10,34,1,37,
357,10,43,1,1,1
357,10,46,4,0,
357,10,63,4,0,
357,10,70,4,0,
357,10,73,2,0,
357,10,74,1,7,
357,10,75,1,11,
357,10,76,1,51,
357,10,76,4,0,
357,10,89,4,0,
357,10,92,4,0,
357,10,104,4,0,
357,10,148,4,0,
357,10,156,4,0,
357,10,164,4,0,
357,10,173,3,0,
357,10,174,2,0,
357,10,182,4,0,
357,10,189,3,0,
357,10,200,3,0,
357,10,202,4,0,
357,10,203,4,0,
357,10,207,4,0,
357,10,210,3,0,
357,10,211,4,0,
357,10,213,4,0,
357,10,214,4,0,
357,10,216,4,0,
357,10,218,4,0,
357,10,219,4,0,
357,10,230,1,21,
357,10,235,1,41,
357,10,235,2,0,
357,10,235,3,0,
357,10,237,4,0,
357,10,239,3,0,
357,10,241,4,0,
357,10,249,4,0,
357,10,263,4,0,
357,10,267,2,0,
357,10,290,4,0,
357,10,314,3,0,
357,10,318,4,0,
357,10,331,4,0,
357,10,332,4,0,
357,10,345,1,31,
357,10,348,2,0,
357,10,349,2,0,
357,10,355,4,0,
357,10,363,1,57,
357,10,363,4,0,
357,10,366,3,0,
357,10,388,3,0,
357,10,403,1,47,
357,10,412,4,0,
357,10,416,4,0,
357,10,437,1,61,
357,10,437,2,0,
357,10,445,4,0,
357,10,447,4,0,
357,10,466,3,0,
357,11,13,2,0,
357,11,14,4,0,
357,11,15,4,0,
357,11,16,1,1,2
357,11,18,1,27,
357,11,19,4,0,
357,11,21,2,0,
357,11,23,1,17,
357,11,29,2,0,
357,11,34,1,37,
357,11,43,1,1,1
357,11,46,4,0,
357,11,63,4,0,
357,11,70,4,0,
357,11,73,2,0,
357,11,74,1,7,
357,11,75,1,11,
357,11,76,1,61,
357,11,76,4,0,
357,11,89,4,0,
357,11,92,4,0,
357,11,104,4,0,
357,11,148,4,0,
357,11,156,4,0,
357,11,164,4,0,
357,11,174,2,0,
357,11,182,4,0,
357,11,207,4,0,
357,11,213,4,0,
357,11,216,4,0,
357,11,218,4,0,
357,11,219,4,0,
357,11,230,1,21,
357,11,235,1,41,
357,11,235,2,0,
357,11,237,4,0,
357,11,241,4,0,
357,11,249,4,0,
357,11,263,4,0,
357,11,267,2,0,
357,11,331,2,0,
357,11,332,4,0,
357,11,345,1,31,
357,11,348,2,0,
357,11,349,2,0,
357,11,363,1,67,
357,11,363,2,0,
357,11,403,1,51,
357,11,412,4,0,
357,11,416,4,0,
357,11,437,1,71,
357,11,437,2,0,
357,11,447,4,0,
357,11,496,4,0,
357,11,516,1,57,
357,11,523,4,0,
357,11,536,1,47,
357,12,15,4,0,
357,12,16,1,1,2
357,12,18,1,27,
357,12,19,4,0,
357,12,23,1,17,
357,12,34,1,37,
357,12,43,1,1,1
357,12,46,4,0,
357,12,63,4,0,
357,12,70,4,0,
357,12,74,1,7,
357,12,75,1,11,
357,12,76,1,41,
357,12,76,4,0,
357,12,89,4,0,
357,12,92,4,0,
357,12,104,4,0,
357,12,148,4,0,
357,12,156,4,0,
357,12,182,4,0,
357,12,202,4,0,
357,12,211,4,0,
357,12,213,4,0,
357,12,216,4,0,
357,12,218,4,0,
357,12,219,4,0,
357,12,230,1,21,
357,12,235,1,47,
357,12,237,4,0,
357,12,241,4,0,
357,12,249,4,0,
357,12,263,4,0,
357,12,290,4,0,
357,12,331,4,0,
357,12,332,4,0,
357,12,345,1,31,
357,13,15,4,0,
357,13,16,1,1,2
357,13,18,1,27,
357,13,19,4,0,
357,13,23,1,17,
357,13,34,1,37,
357,13,34,3,0,
357,13,38,3,0,
357,13,43,1,1,1
357,13,46,4,0,
357,13,63,4,0,
357,13,70,4,0,
357,13,74,1,7,
357,13,75,1,11,
357,13,76,1,41,
357,13,76,4,0,
357,13,89,4,0,
357,13,92,4,0,
357,13,102,3,0,
357,13,104,4,0,
357,13,148,4,0,
357,13,156,4,0,
357,13,164,3,0,
357,13,182,4,0,
357,13,202,4,0,
357,13,207,3,0,
357,13,211,4,0,
357,13,213,4,0,
357,13,216,4,0,
357,13,218,4,0,
357,13,219,4,0,
357,13,230,1,21,
357,13,235,1,47,
357,13,237,4,0,
357,13,241,4,0,
357,13,249,4,0,
357,13,263,4,0,
357,13,290,4,0,
357,13,331,4,0,
357,13,332,4,0,
357,13,345,1,31,
357,14,13,2,0,
357,14,14,4,0,
357,14,15,4,0,
357,14,16,1,1,2
357,14,18,1,27,
357,14,19,4,0,
357,14,21,2,0,
357,14,23,1,17,
357,14,29,2,0,
357,14,34,1,37,
357,14,43,1,1,1
357,14,46,4,0,
357,14,63,4,0,
357,14,70,4,0,
357,14,73,2,0,
357,14,74,1,7,
357,14,75,1,11,
357,14,76,1,61,
357,14,76,4,0,
357,14,89,4,0,
357,14,92,4,0,
357,14,104,4,0,
357,14,148,4,0,
357,14,156,4,0,
357,14,164,4,0,
357,14,173,3,0,
357,14,174,2,0,
357,14,182,4,0,
357,14,200,3,0,
357,14,202,3,0,
357,14,207,4,0,
357,14,213,4,0,
357,14,214,3,0,
357,14,216,4,0,
357,14,218,4,0,
357,14,219,4,0,
357,14,230,1,21,
357,14,235,1,41,
357,14,235,2,0,
357,14,235,3,0,
357,14,237,4,0,
357,14,241,4,0,
357,14,249,4,0,
357,14,263,4,0,
357,14,267,2,0,
357,14,331,2,0,
357,14,332,4,0,
357,14,345,1,31,
357,14,348,2,0,
357,14,349,2,0,
357,14,355,3,0,
357,14,363,1,67,
357,14,363,2,0,
357,14,366,3,0,
357,14,388,3,0,
357,14,402,3,0,
357,14,403,1,51,
357,14,406,3,0,
357,14,412,4,0,
357,14,416,4,0,
357,14,437,1,71,
357,14,437,2,0,
357,14,447,4,0,
357,14,496,4,0,
357,14,516,1,57,
357,14,523,4,0,
357,14,536,1,47,
357,15,13,2,0,
357,15,14,4,0,
357,15,15,4,0,
357,15,16,1,1,5
357,15,18,1,27,
357,15,19,4,0,
357,15,21,2,0,
357,15,23,1,17,
357,15,29,2,0,
357,15,34,1,37,
357,15,43,1,1,4
357,15,46,4,0,
357,15,63,4,0,
357,15,70,4,0,
357,15,73,2,0,
357,15,74,1,7,
357,15,75,1,11,
357,15,76,1,61,
357,15,76,4,0,
357,15,89,4,0,
357,15,92,4,0,
357,15,104,4,0,
357,15,148,4,0,
357,15,156,4,0,
357,15,164,4,0,
357,15,174,2,0,
357,15,182,4,0,
357,15,207,4,0,
357,15,211,4,0,
357,15,213,4,0,
357,15,214,4,0,
357,15,216,4,0,
357,15,218,4,0,
357,15,219,4,0,
357,15,230,1,21,
357,15,235,1,41,
357,15,235,2,0,
357,15,237,4,0,
357,15,241,4,0,
357,15,249,4,0,
357,15,263,4,0,
357,15,267,2,0,
357,15,267,4,0,
357,15,331,2,0,
357,15,332,4,0,
357,15,345,1,31,
357,15,348,2,0,
357,15,349,2,0,
357,15,355,4,0,
357,15,363,1,1,3
357,15,363,1,67,
357,15,363,2,0,
357,15,403,1,50,
357,15,412,4,0,
357,15,416,4,0,
357,15,437,1,1,1
357,15,437,1,71,
357,15,437,2,0,
357,15,447,4,0,
357,15,496,4,0,
357,15,516,1,1,2
357,15,516,1,57,
357,15,523,4,0,
357,15,536,1,47,
357,15,590,4,0,
357,16,13,2,0,
357,16,14,4,0,
357,16,15,4,0,
357,16,16,1,1,3
357,16,18,1,21,1
357,16,19,4,0,
357,16,21,2,0,
357,16,23,1,10,1
357,16,29,2,0,
357,16,34,1,41,1
357,16,43,1,1,2
357,16,46,4,0,
357,16,63,4,0,
357,16,70,4,0,
357,16,73,2,0,
357,16,74,1,1,4
357,16,75,1,1,5
357,16,76,1,56,1
357,16,76,4,0,
357,16,89,4,0,
357,16,92,4,0,
357,16,104,4,0,
357,16,148,4,0,
357,16,156,4,0,
357,16,164,4,0,
357,16,173,3,0,
357,16,174,2,0,
357,16,182,4,0,
357,16,200,3,0,
357,16,202,3,0,
357,16,207,4,0,
357,16,211,4,0,
357,16,213,4,0,
357,16,214,4,0,
357,16,216,4,0,
357,16,218,4,0,
357,16,219,4,0,
357,16,230,1,6,1
357,16,235,1,50,1
357,16,235,2,0,
357,16,235,3,0,
357,16,237,4,0,
357,16,241,4,0,
357,16,249,4,0,
357,16,263,4,0,
357,16,267,2,0,
357,16,267,4,0,
357,16,290,4,0,
357,16,331,2,0,
357,16,332,4,0,
357,16,345,1,16,1
357,16,348,2,0,
357,16,349,2,0,
357,16,355,4,0,
357,16,363,1,30,1
357,16,363,2,0,
357,16,366,3,0,
357,16,388,3,0,
357,16,402,3,0,
357,16,403,1,36,1
357,16,406,3,0,
357,16,412,4,0,
357,16,416,4,0,
357,16,437,1,1,1
357,16,437,1,61,1
357,16,437,2,0,
357,16,447,4,0,
357,16,496,4,0,
357,16,516,1,46,1
357,16,523,4,0,
357,16,536,1,26,1
357,16,590,4,0,
357,17,13,2,0,
357,17,14,4,0,
357,17,16,1,1,3
357,17,18,1,21,
357,17,19,4,0,
357,17,21,2,0,
357,17,23,1,10,
357,17,29,2,0,
357,17,34,1,41,
357,17,43,1,1,2
357,17,46,4,0,
357,17,63,4,0,
357,17,73,2,0,
357,17,74,1,1,4
357,17,75,1,1,5
357,17,76,1,56,
357,17,76,4,0,
357,17,89,4,0,
357,17,92,4,0,
357,17,104,4,0,
357,17,156,4,0,
357,17,164,4,0,
357,17,174,2,0,
357,17,182,4,0,
357,17,207,4,0,
357,17,211,4,0,
357,17,213,4,0,
357,17,214,4,0,
357,17,216,4,0,
357,17,218,4,0,
357,17,219,4,0,
357,17,230,1,6,
357,17,235,1,50,
357,17,235,2,0,
357,17,237,4,0,
357,17,241,4,0,
357,17,263,4,0,
357,17,267,2,0,
357,17,267,4,0,
357,17,331,2,0,
357,17,332,4,0,
357,17,345,1,16,
357,17,348,2,0,
357,17,349,2,0,
357,17,355,4,0,
357,17,363,1,30,
357,17,363,2,0,
357,17,403,1,36,
357,17,412,4,0,
357,17,416,4,0,
357,17,437,1,1,1
357,17,437,1,61,
357,17,437,2,0,
357,17,447,4,0,
357,17,496,4,0,
357,17,516,1,46,
357,17,523,4,0,
357,17,536,1,26,
357,17,590,4,0,
357,17,693,4,0,
357,18,13,2,0,
357,18,14,4,0,
357,18,16,1,1,3
357,18,18,1,21,
357,18,19,4,0,
357,18,21,2,0,
357,18,23,1,10,
357,18,29,2,0,
357,18,34,1,41,
357,18,43,1,1,2
357,18,46,4,0,
357,18,63,4,0,
357,18,73,2,0,
357,18,74,1,1,4
357,18,75,1,1,5
357,18,76,1,56,
357,18,76,4,0,
357,18,89,4,0,
357,18,92,4,0,
357,18,104,4,0,
357,18,156,4,0,
357,18,164,4,0,
357,18,174,2,0,
357,18,182,4,0,
357,18,207,4,0,
357,18,211,4,0,
357,18,213,4,0,
357,18,214,4,0,
357,18,216,4,0,
357,18,218,4,0,
357,18,219,4,0,
357,18,230,1,6,
357,18,235,1,50,
357,18,235,2,0,
357,18,237,4,0,
357,18,241,4,0,
357,18,263,4,0,
357,18,267,2,0,
357,18,267,4,0,
357,18,331,2,0,
357,18,332,4,0,
357,18,345,1,16,
357,18,348,2,0,
357,18,349,2,0,
357,18,355,4,0,
357,18,363,1,30,
357,18,363,2,0,
357,18,403,1,36,
357,18,412,4,0,
357,18,416,4,0,
357,18,437,1,1,1
357,18,437,1,61,
357,18,437,2,0,
357,18,447,4,0,
357,18,496,4,0,
357,18,516,1,46,
357,18,523,4,0,
357,18,536,1,26,
357,18,590,4,0,
357,18,692,2,0,
357,18,693,4,0,
358,5,35,1,1,
358,5,36,1,17,
358,5,38,1,33,
358,5,45,1,6,
358,5,50,2,0,
358,5,92,4,0,
358,5,93,1,14,
358,5,94,1,46,
358,5,94,4,0,
358,5,95,2,0,
358,5,104,4,0,
358,5,113,4,0,
358,5,115,4,0,
358,5,138,2,0,
358,5,148,4,0,
358,5,149,1,30,
358,5,156,4,0,
358,5,174,2,0,
358,5,182,4,0,
358,5,213,4,0,
358,5,215,1,38,
358,5,216,4,0,
358,5,218,4,0,
358,5,219,1,41,
358,5,219,4,0,
358,5,237,4,0,
358,5,240,4,0,
358,5,241,4,0,
358,5,247,4,0,
358,5,253,1,22,
358,5,259,4,0,
358,5,263,4,0,
358,5,269,4,0,
358,5,281,1,25,
358,5,285,4,0,
358,5,289,4,0,
358,5,290,4,0,
358,5,310,1,9,
358,5,347,4,0,
358,5,351,4,0,
358,6,35,1,1,
358,6,36,1,17,
358,6,38,1,33,
358,6,38,3,0,
358,6,45,1,6,
358,6,50,2,0,
358,6,92,4,0,
358,6,93,1,14,
358,6,94,1,46,
358,6,94,4,0,
358,6,95,2,0,
358,6,102,3,0,
358,6,104,4,0,
358,6,111,3,0,
358,6,113,4,0,
358,6,115,4,0,
358,6,138,2,0,
358,6,138,3,0,
358,6,148,4,0,
358,6,149,1,30,
358,6,156,4,0,
358,6,164,3,0,
358,6,173,3,0,
358,6,174,2,0,
358,6,182,4,0,
358,6,196,3,0,
358,6,203,3,0,
358,6,205,3,0,
358,6,207,3,0,
358,6,213,4,0,
358,6,214,3,0,
358,6,215,1,38,
358,6,216,4,0,
358,6,218,4,0,
358,6,219,1,41,
358,6,219,4,0,
358,6,237,4,0,
358,6,240,4,0,
358,6,241,4,0,
358,6,244,3,0,
358,6,247,4,0,
358,6,253,1,22,
358,6,259,4,0,
358,6,263,4,0,
358,6,269,4,0,
358,6,281,1,25,
358,6,285,4,0,
358,6,289,4,0,
358,6,290,4,0,
358,6,310,1,9,
358,6,347,4,0,
358,6,351,4,0,
358,7,35,1,1,
358,7,36,1,17,
358,7,38,1,33,
358,7,38,3,0,
358,7,45,1,6,
358,7,50,2,0,
358,7,92,4,0,
358,7,93,1,14,
358,7,94,1,46,
358,7,94,4,0,
358,7,95,2,0,
358,7,102,3,0,
358,7,104,4,0,
358,7,113,4,0,
358,7,115,4,0,
358,7,138,2,0,
358,7,138,3,0,
358,7,148,4,0,
358,7,149,1,30,
358,7,156,4,0,
358,7,164,3,0,
358,7,174,2,0,
358,7,182,4,0,
358,7,213,4,0,
358,7,215,1,38,
358,7,216,4,0,
358,7,218,4,0,
358,7,219,1,41,
358,7,219,4,0,
358,7,237,4,0,
358,7,240,4,0,
358,7,241,4,0,
358,7,247,4,0,
358,7,253,1,22,
358,7,259,4,0,
358,7,263,4,0,
358,7,269,4,0,
358,7,281,1,25,
358,7,285,4,0,
358,7,289,4,0,
358,7,290,4,0,
358,7,310,1,9,
358,7,347,4,0,
358,7,351,4,0,
358,8,35,1,1,
358,8,36,1,22,
358,8,38,1,33,
358,8,45,1,6,
358,8,50,2,0,
358,8,86,4,0,
358,8,92,4,0,
358,8,93,1,14,
358,8,94,4,0,
358,8,95,2,0,
358,8,104,4,0,
358,8,113,4,0,
358,8,115,4,0,
358,8,138,2,0,
358,8,138,4,0,
358,8,148,4,0,
358,8,149,1,30,
358,8,156,4,0,
358,8,164,4,0,
358,8,174,2,0,
358,8,182,4,0,
358,8,203,4,0,
358,8,207,4,0,
358,8,213,4,0,
358,8,214,4,0,
358,8,215,1,38,
358,8,216,4,0,
358,8,218,4,0,
358,8,219,1,41,
358,8,219,4,0,
358,8,237,4,0,
358,8,240,4,0,
358,8,241,4,0,
358,8,244,4,0,
358,8,247,4,0,
358,8,248,2,0,
358,8,253,1,17,
358,8,259,4,0,
358,8,263,4,0,
358,8,269,4,0,
358,8,273,2,0,
358,8,278,4,0,
358,8,281,1,25,
358,8,285,4,0,
358,8,289,4,0,
358,8,290,4,0,
358,8,310,1,9,
358,8,326,1,46,
358,8,347,4,0,
358,8,351,4,0,
358,8,361,1,49,
358,8,363,4,0,
358,8,412,4,0,
358,8,433,4,0,
358,8,445,4,0,
358,8,447,4,0,
358,8,451,4,0,
358,9,35,1,1,
358,9,36,1,22,
358,9,38,1,33,
358,9,45,1,6,
358,9,50,2,0,
358,9,86,4,0,
358,9,92,4,0,
358,9,93,1,14,
358,9,94,4,0,
358,9,95,2,0,
358,9,104,4,0,
358,9,113,4,0,
358,9,115,4,0,
358,9,138,2,0,
358,9,138,4,0,
358,9,148,4,0,
358,9,149,1,30,
358,9,156,4,0,
358,9,164,4,0,
358,9,173,3,0,
358,9,174,2,0,
358,9,182,4,0,
358,9,196,3,0,
358,9,203,4,0,
358,9,205,3,0,
358,9,207,4,0,
358,9,213,4,0,
358,9,214,4,0,
358,9,215,1,38,
358,9,216,4,0,
358,9,218,4,0,
358,9,219,1,41,
358,9,219,4,0,
358,9,237,4,0,
358,9,240,4,0,
358,9,241,4,0,
358,9,244,4,0,
358,9,247,4,0,
358,9,248,2,0,
358,9,253,1,17,
358,9,253,3,0,
358,9,259,4,0,
358,9,263,4,0,
358,9,269,4,0,
358,9,270,3,0,
358,9,271,3,0,
358,9,273,2,0,
358,9,278,4,0,
358,9,281,1,25,
358,9,282,3,0,
358,9,285,4,0,
358,9,289,4,0,
358,9,290,4,0,
358,9,310,1,9,
358,9,324,3,0,
358,9,326,1,46,
358,9,347,4,0,
358,9,351,4,0,
358,9,361,1,49,
358,9,363,4,0,
358,9,387,3,0,
358,9,412,4,0,
358,9,428,3,0,
358,9,433,4,0,
358,9,445,4,0,
358,9,447,4,0,
358,9,451,4,0,
358,10,35,1,1,
358,10,36,1,22,
358,10,38,1,33,
358,10,45,1,6,
358,10,50,2,0,
358,10,86,4,0,
358,10,92,4,0,
358,10,93,1,14,
358,10,94,4,0,
358,10,95,2,0,
358,10,104,4,0,
358,10,113,4,0,
358,10,115,4,0,
358,10,138,2,0,
358,10,138,4,0,
358,10,148,4,0,
358,10,149,1,30,
358,10,156,4,0,
358,10,164,4,0,
358,10,173,3,0,
358,10,174,2,0,
358,10,182,4,0,
358,10,196,3,0,
358,10,203,4,0,
358,10,205,3,0,
358,10,207,4,0,
358,10,213,4,0,
358,10,214,4,0,
358,10,215,1,38,
358,10,215,3,0,
358,10,216,4,0,
358,10,218,4,0,
358,10,219,1,41,
358,10,219,4,0,
358,10,237,4,0,
358,10,240,4,0,
358,10,241,4,0,
358,10,244,4,0,
358,10,247,4,0,
358,10,248,2,0,
358,10,253,1,17,
358,10,253,3,0,
358,10,259,4,0,
358,10,263,4,0,
358,10,269,4,0,
358,10,270,3,0,
358,10,271,3,0,
358,10,273,2,0,
358,10,277,3,0,
358,10,278,4,0,
358,10,281,1,25,
358,10,282,3,0,
358,10,285,4,0,
358,10,289,4,0,
358,10,290,4,0,
358,10,310,1,9,
358,10,324,3,0,
358,10,326,1,46,
358,10,347,4,0,
358,10,351,4,0,
358,10,356,3,0,
358,10,361,1,49,
358,10,363,4,0,
358,10,387,3,0,
358,10,412,4,0,
358,10,428,3,0,
358,10,433,4,0,
358,10,445,4,0,
358,10,447,4,0,
358,10,451,4,0,
358,11,35,1,1,
358,11,36,1,22,
358,11,38,1,33,
358,11,45,1,6,
358,11,50,2,0,
358,11,86,4,0,
358,11,92,4,0,
358,11,93,1,14,
358,11,94,4,0,
358,11,95,2,0,
358,11,104,4,0,
358,11,113,4,0,
358,11,115,4,0,
358,11,138,4,0,
358,11,148,4,0,
358,11,149,1,30,
358,11,156,4,0,
358,11,164,4,0,
358,11,174,2,0,
358,11,182,4,0,
358,11,207,4,0,
358,11,213,4,0,
358,11,215,1,38,
358,11,216,4,0,
358,11,218,4,0,
358,11,219,1,41,
358,11,219,4,0,
358,11,237,4,0,
358,11,240,4,0,
358,11,241,4,0,
358,11,244,4,0,
358,11,247,4,0,
358,11,248,2,0,
358,11,253,1,17,
358,11,259,4,0,
358,11,263,4,0,
358,11,269,4,0,
358,11,273,2,0,
358,11,281,1,25,
358,11,285,2,0,
358,11,310,1,9,
358,11,326,1,46,
358,11,347,4,0,
358,11,361,1,57,
358,11,412,4,0,
358,11,433,4,0,
358,11,447,4,0,
358,11,451,4,0,
358,11,473,4,0,
358,11,477,4,0,
358,11,485,1,54,
358,11,496,4,0,
358,11,497,4,0,
358,11,500,2,0,
358,11,505,1,49,
358,12,35,1,1,
358,12,36,1,17,
358,12,38,1,33,
358,12,45,1,6,
358,12,92,4,0,
358,12,93,1,14,
358,12,94,1,46,
358,12,94,4,0,
358,12,104,4,0,
358,12,113,4,0,
358,12,115,4,0,
358,12,148,4,0,
358,12,149,1,30,
358,12,156,4,0,
358,12,182,4,0,
358,12,213,4,0,
358,12,215,1,38,
358,12,216,4,0,
358,12,218,4,0,
358,12,219,1,41,
358,12,219,4,0,
358,12,237,4,0,
358,12,240,4,0,
358,12,241,4,0,
358,12,247,4,0,
358,12,253,1,22,
358,12,259,4,0,
358,12,263,4,0,
358,12,269,4,0,
358,12,281,1,25,
358,12,285,4,0,
358,12,289,4,0,
358,12,290,4,0,
358,12,310,1,9,
358,12,347,4,0,
358,12,351,4,0,
358,13,35,1,1,
358,13,36,1,17,
358,13,38,1,33,
358,13,38,3,0,
358,13,45,1,6,
358,13,92,4,0,
358,13,93,1,14,
358,13,94,1,46,
358,13,94,4,0,
358,13,102,3,0,
358,13,104,4,0,
358,13,113,4,0,
358,13,115,4,0,
358,13,138,3,0,
358,13,148,4,0,
358,13,149,1,30,
358,13,156,4,0,
358,13,164,3,0,
358,13,171,3,0,
358,13,182,4,0,
358,13,196,3,0,
358,13,207,3,0,
358,13,213,4,0,
358,13,215,1,38,
358,13,216,4,0,
358,13,218,4,0,
358,13,219,1,41,
358,13,219,4,0,
358,13,237,4,0,
358,13,240,4,0,
358,13,241,4,0,
358,13,247,4,0,
358,13,253,1,22,
358,13,259,4,0,
358,13,263,4,0,
358,13,269,4,0,
358,13,281,1,25,
358,13,285,4,0,
358,13,289,4,0,
358,13,290,4,0,
358,13,310,1,9,
358,13,347,4,0,
358,13,351,4,0,
358,14,20,3,0,
358,14,35,1,1,
358,14,36,1,22,
358,14,38,1,33,
358,14,45,1,6,
358,14,50,2,0,
358,14,86,4,0,
358,14,92,4,0,
358,14,93,1,14,
358,14,94,4,0,
358,14,95,2,0,
358,14,104,4,0,
358,14,113,4,0,
358,14,115,4,0,
358,14,138,4,0,
358,14,148,4,0,
358,14,149,1,30,
358,14,156,4,0,
358,14,164,4,0,
358,14,173,3,0,
358,14,174,2,0,
358,14,182,4,0,
358,14,196,3,0,
358,14,207,4,0,
358,14,213,4,0,
358,14,214,3,0,
358,14,215,1,38,
358,14,215,3,0,
358,14,216,4,0,
358,14,218,4,0,
358,14,219,1,41,
358,14,219,4,0,
358,14,237,4,0,
358,14,240,4,0,
358,14,241,4,0,
358,14,244,4,0,
358,14,247,4,0,
358,14,248,2,0,
358,14,253,1,17,
358,14,253,3,0,
358,14,259,4,0,
358,14,263,4,0,
358,14,269,4,0,
358,14,270,3,0,
358,14,271,3,0,
358,14,273,2,0,
358,14,277,3,0,
358,14,278,3,0,
358,14,281,1,25,
358,14,282,3,0,
358,14,285,2,0,
358,14,285,3,0,
358,14,289,3,0,
358,14,304,3,0,
358,14,310,1,9,
358,14,324,3,0,
358,14,326,1,46,
358,14,347,4,0,
358,14,356,3,0,
358,14,361,1,57,
358,14,387,3,0,
358,14,412,4,0,
358,14,428,3,0,
358,14,433,4,0,
358,14,447,4,0,
358,14,451,4,0,
358,14,473,4,0,
358,14,477,4,0,
358,14,485,1,54,
358,14,496,4,0,
358,14,497,4,0,
358,14,500,2,0,
358,14,505,1,49,
358,15,35,1,1,3
358,15,36,1,22,
358,15,38,1,33,
358,15,45,1,6,
358,15,50,2,0,
358,15,86,4,0,
358,15,92,4,0,
358,15,93,1,14,
358,15,94,4,0,
358,15,95,2,0,
358,15,104,4,0,
358,15,105,2,0,
358,15,113,4,0,
358,15,115,4,0,
358,15,138,4,0,
358,15,148,4,0,
358,15,149,1,30,
358,15,156,4,0,
358,15,164,4,0,
358,15,174,2,0,
358,15,182,4,0,
358,15,207,4,0,
358,15,213,4,0,
358,15,214,4,0,
358,15,215,1,38,
358,15,216,4,0,
358,15,218,4,0,
358,15,219,1,41,
358,15,219,4,0,
358,15,237,4,0,
358,15,240,4,0,
358,15,241,4,0,
358,15,244,4,0,
358,15,247,4,0,
358,15,248,2,0,
358,15,253,1,17,
358,15,259,4,0,
358,15,263,4,0,
358,15,269,4,0,
358,15,273,2,0,
358,15,281,1,25,
358,15,285,2,0,
358,15,310,1,9,
358,15,322,2,0,
358,15,326,1,46,
358,15,347,4,0,
358,15,361,1,1,1
358,15,361,1,57,
358,15,412,4,0,
358,15,433,4,0,
358,15,447,4,0,
358,15,451,4,0,
358,15,473,4,0,
358,15,485,1,1,2
358,15,485,1,54,
358,15,496,4,0,
358,15,497,4,0,
358,15,500,2,0,
358,15,505,1,49,
358,15,590,4,0,
358,15,605,4,0,
358,16,20,3,0,
358,16,35,1,1,3
358,16,36,1,19,1
358,16,38,1,42,1
358,16,45,1,1,4
358,16,45,1,4,1
358,16,50,2,0,
358,16,86,4,0,
358,16,92,4,0,
358,16,93,1,1,6
358,16,93,1,10,1
358,16,94,4,0,
358,16,95,2,0,
358,16,104,4,0,
358,16,105,2,0,
358,16,113,4,0,
358,16,115,4,0,
358,16,138,4,0,
358,16,148,4,0,
358,16,149,1,16,1
358,16,156,4,0,
358,16,164,4,0,
358,16,173,3,0,
358,16,174,2,0,
358,16,182,4,0,
358,16,196,3,0,
358,16,207,4,0,
358,16,213,4,0,
358,16,214,4,0,
358,16,215,1,27,1
358,16,215,3,0,
358,16,216,4,0,
358,16,218,4,0,
358,16,219,1,37,1
358,16,219,4,0,
358,16,237,4,0,
358,16,240,4,0,
358,16,241,4,0,
358,16,244,4,0,
358,16,247,4,0,
358,16,248,2,0,
358,16,253,1,32,1
358,16,253,3,0,
358,16,259,4,0,
358,16,263,4,0,
358,16,269,4,0,
358,16,270,3,0,
358,16,271,3,0,
358,16,273,2,0,
358,16,277,3,0,
358,16,278,3,0,
358,16,281,1,13,1
358,16,282,3,0,
358,16,285,2,0,
358,16,285,3,0,
358,16,289,3,0,
358,16,290,4,0,
358,16,304,3,0,
358,16,310,1,1,5
358,16,310,1,7,1
358,16,322,2,0,
358,16,324,3,0,
358,16,326,1,22,1
358,16,347,4,0,
358,16,351,3,0,
358,16,356,3,0,
358,16,361,1,1,1
358,16,361,1,57,1
358,16,387,3,0,
358,16,412,4,0,
358,16,428,3,0,
358,16,433,4,0,
358,16,447,4,0,
358,16,451,4,0,
358,16,473,4,0,
358,16,485,1,1,2
358,16,485,1,52,1
358,16,496,4,0,
358,16,497,4,0,
358,16,500,2,0,
358,16,505,1,47,1
358,16,590,4,0,
358,16,605,4,0,
358,17,35,1,1,3
358,17,36,1,19,
358,17,38,1,42,
358,17,45,1,1,4
358,17,45,1,4,
358,17,50,2,0,
358,17,86,4,0,
358,17,92,4,0,
358,17,93,1,1,6
358,17,93,1,10,
358,17,94,4,0,
358,17,95,2,0,
358,17,104,4,0,
358,17,105,2,0,
358,17,113,4,0,
358,17,115,4,0,
358,17,138,4,0,
358,17,149,1,16,
358,17,156,4,0,
358,17,164,4,0,
358,17,174,2,0,
358,17,182,4,0,
358,17,207,4,0,
358,17,213,4,0,
358,17,214,4,0,
358,17,215,1,27,
358,17,216,4,0,
358,17,218,4,0,
358,17,219,1,37,
358,17,219,4,0,
358,17,237,4,0,
358,17,240,4,0,
358,17,241,4,0,
358,17,244,4,0,
358,17,247,4,0,
358,17,248,2,0,
358,17,253,1,32,
358,17,259,4,0,
358,17,263,4,0,
358,17,269,4,0,
358,17,273,2,0,
358,17,281,1,13,
358,17,285,2,0,
358,17,310,1,1,5
358,17,310,1,7,
358,17,322,2,0,
358,17,326,1,22,
358,17,347,4,0,
358,17,361,1,1,1
358,17,361,1,57,
358,17,412,4,0,
358,17,433,4,0,
358,17,447,4,0,
358,17,451,4,0,
358,17,473,4,0,
358,17,485,1,1,2
358,17,485,1,52,
358,17,496,4,0,
358,17,497,4,0,
358,17,500,2,0,
358,17,505,1,47,
358,17,590,4,0,
358,17,605,4,0,
358,18,35,1,1,3
358,18,36,1,19,
358,18,38,1,42,
358,18,45,1,1,4
358,18,45,1,4,
358,18,50,2,0,
358,18,86,4,0,
358,18,92,4,0,
358,18,93,1,1,6
358,18,93,1,10,
358,18,94,4,0,
358,18,95,2,0,
358,18,104,4,0,
358,18,105,2,0,
358,18,113,4,0,
358,18,115,4,0,
358,18,138,4,0,
358,18,149,1,16,
358,18,156,4,0,
358,18,164,4,0,
358,18,174,2,0,
358,18,182,4,0,
358,18,195,2,0,
358,18,207,4,0,
358,18,213,4,0,
358,18,214,4,0,
358,18,215,1,27,
358,18,216,4,0,
358,18,218,4,0,
358,18,219,1,37,
358,18,219,4,0,
358,18,237,4,0,
358,18,240,4,0,
358,18,241,4,0,
358,18,244,4,0,
358,18,247,4,0,
358,18,248,2,0,
358,18,253,1,32,
358,18,259,4,0,
358,18,263,4,0,
358,18,269,4,0,
358,18,273,2,0,
358,18,281,1,13,
358,18,285,2,0,
358,18,310,1,1,5
358,18,310,1,7,
358,18,322,2,0,
358,18,326,1,22,
358,18,347,4,0,
358,18,361,1,1,1
358,18,361,1,57,
358,18,412,4,0,
358,18,433,4,0,
358,18,447,4,0,
358,18,451,4,0,
358,18,473,4,0,
358,18,485,1,1,2
358,18,485,1,52,
358,18,496,4,0,
358,18,497,4,0,
358,18,500,2,0,
358,18,505,1,47,
358,18,578,2,0,
358,18,590,4,0,
358,18,605,4,0,
359,5,10,1,1,
359,5,13,1,17,
359,5,14,1,26,
359,5,15,4,0,
359,5,38,2,0,
359,5,43,1,5,
359,5,44,1,21,
359,5,53,4,0,
359,5,58,4,0,
359,5,59,4,0,
359,5,63,4,0,
359,5,70,4,0,
359,5,85,4,0,
359,5,87,4,0,
359,5,92,4,0,
359,5,98,1,13,
359,5,104,1,31,
359,5,104,4,0,
359,5,126,4,0,
359,5,148,4,0,
359,5,156,4,0,
359,5,163,1,36,
359,5,164,2,0,
359,5,168,4,0,
359,5,174,2,0,
359,5,182,4,0,
359,5,185,2,0,
359,5,195,1,46,
359,5,201,4,0,
359,5,213,4,0,
359,5,216,4,0,
359,5,218,4,0,
359,5,226,2,0,
359,5,231,4,0,
359,5,237,4,0,
359,5,240,4,0,
359,5,241,4,0,
359,5,247,4,0,
359,5,248,1,41,
359,5,249,4,0,
359,5,258,4,0,
359,5,259,4,0,
359,5,263,4,0,
359,5,269,1,9,
359,5,269,4,0,
359,5,277,2,0,
359,5,289,4,0,
359,5,290,4,0,
359,5,332,4,0,
359,5,347,4,0,
359,5,351,4,0,
359,5,352,4,0,
359,6,10,1,1,
359,6,13,1,17,
359,6,14,1,26,
359,6,14,3,0,
359,6,15,4,0,
359,6,34,3,0,
359,6,38,2,0,
359,6,38,3,0,
359,6,43,1,5,
359,6,44,1,21,
359,6,53,4,0,
359,6,58,4,0,
359,6,59,4,0,
359,6,63,4,0,
359,6,68,3,0,
359,6,70,4,0,
359,6,85,4,0,
359,6,86,3,0,
359,6,87,4,0,
359,6,92,4,0,
359,6,98,1,13,
359,6,102,3,0,
359,6,104,1,31,
359,6,104,4,0,
359,6,126,4,0,
359,6,129,3,0,
359,6,138,3,0,
359,6,148,4,0,
359,6,156,4,0,
359,6,157,3,0,
359,6,163,1,36,
359,6,164,2,0,
359,6,164,3,0,
359,6,168,4,0,
359,6,173,3,0,
359,6,174,2,0,
359,6,182,4,0,
359,6,185,2,0,
359,6,189,3,0,
359,6,195,1,46,
359,6,196,3,0,
359,6,201,4,0,
359,6,203,3,0,
359,6,207,3,0,
359,6,210,3,0,
359,6,213,4,0,
359,6,214,3,0,
359,6,216,4,0,
359,6,218,4,0,
359,6,226,2,0,
359,6,231,4,0,
359,6,237,4,0,
359,6,240,4,0,
359,6,241,4,0,
359,6,244,3,0,
359,6,247,4,0,
359,6,248,1,41,
359,6,249,4,0,
359,6,258,4,0,
359,6,259,4,0,
359,6,263,4,0,
359,6,269,1,9,
359,6,269,4,0,
359,6,277,2,0,
359,6,289,4,0,
359,6,290,4,0,
359,6,332,4,0,
359,6,347,4,0,
359,6,351,4,0,
359,6,352,4,0,
359,7,10,1,1,
359,7,13,1,17,
359,7,14,1,26,
359,7,14,3,0,
359,7,15,4,0,
359,7,34,3,0,
359,7,38,2,0,
359,7,38,3,0,
359,7,43,1,5,
359,7,44,1,21,
359,7,53,4,0,
359,7,58,4,0,
359,7,59,4,0,
359,7,63,4,0,
359,7,68,3,0,
359,7,70,4,0,
359,7,85,4,0,
359,7,86,3,0,
359,7,87,4,0,
359,7,92,4,0,
359,7,98,1,13,
359,7,102,3,0,
359,7,104,1,31,
359,7,104,4,0,
359,7,126,4,0,
359,7,138,3,0,
359,7,148,4,0,
359,7,156,4,0,
359,7,157,3,0,
359,7,163,1,36,
359,7,164,2,0,
359,7,164,3,0,
359,7,168,4,0,
359,7,174,2,0,
359,7,182,4,0,
359,7,185,2,0,
359,7,195,1,46,
359,7,201,4,0,
359,7,213,4,0,
359,7,216,4,0,
359,7,218,4,0,
359,7,226,2,0,
359,7,231,4,0,
359,7,237,4,0,
359,7,240,4,0,
359,7,241,4,0,
359,7,247,4,0,
359,7,248,1,41,
359,7,249,4,0,
359,7,258,4,0,
359,7,259,4,0,
359,7,263,4,0,
359,7,269,1,9,
359,7,269,4,0,
359,7,277,2,0,
359,7,289,4,0,
359,7,290,4,0,
359,7,332,4,0,
359,7,347,4,0,
359,7,351,4,0,
359,7,352,4,0,
359,8,10,1,1,
359,8,13,1,17,
359,8,14,1,25,
359,8,14,4,0,
359,8,15,4,0,
359,8,38,2,0,
359,8,43,1,4,
359,8,44,1,28,
359,8,53,4,0,
359,8,58,4,0,
359,8,59,4,0,
359,8,63,4,0,
359,8,70,4,0,
359,8,85,4,0,
359,8,86,4,0,
359,8,87,4,0,
359,8,92,4,0,
359,8,98,1,12,
359,8,104,1,33,
359,8,104,4,0,
359,8,126,4,0,
359,8,138,4,0,
359,8,148,4,0,
359,8,156,4,0,
359,8,157,4,0,
359,8,163,1,36,
359,8,164,2,0,
359,8,164,4,0,
359,8,168,4,0,
359,8,174,2,0,
359,8,182,4,0,
359,8,185,2,0,
359,8,195,1,65,
359,8,197,1,49,
359,8,201,4,0,
359,8,203,4,0,
359,8,206,4,0,
359,8,207,4,0,
359,8,212,2,0,
359,8,213,4,0,
359,8,214,4,0,
359,8,216,4,0,
359,8,218,4,0,
359,8,226,2,0,
359,8,228,1,20,
359,8,231,4,0,
359,8,237,4,0,
359,8,240,4,0,
359,8,241,4,0,
359,8,244,4,0,
359,8,247,4,0,
359,8,248,1,41,
359,8,249,4,0,
359,8,258,4,0,
359,8,259,4,0,
359,8,261,4,0,
359,8,263,4,0,
359,8,269,1,9,
359,8,269,4,0,
359,8,277,2,0,
359,8,289,4,0,
359,8,290,4,0,
359,8,317,4,0,
359,8,332,4,0,
359,8,347,4,0,
359,8,351,4,0,
359,8,352,4,0,
359,8,363,4,0,
359,8,371,4,0,
359,8,372,2,0,
359,8,382,1,57,
359,8,382,2,0,
359,8,386,2,0,
359,8,389,1,44,
359,8,389,2,0,
359,8,399,4,0,
359,8,400,1,52,
359,8,404,4,0,
359,8,416,4,0,
359,8,421,4,0,
359,8,427,1,60,
359,8,428,2,0,
359,8,444,4,0,
359,8,445,4,0,
359,8,451,4,0,
359,9,10,1,1,1
359,9,13,1,17,
359,9,14,1,25,
359,9,14,4,0,
359,9,15,4,0,
359,9,38,2,0,
359,9,43,1,4,
359,9,44,1,28,
359,9,53,4,0,
359,9,58,4,0,
359,9,59,4,0,
359,9,63,4,0,
359,9,70,4,0,
359,9,85,4,0,
359,9,86,4,0,
359,9,87,4,0,
359,9,92,4,0,
359,9,98,1,12,
359,9,104,1,33,
359,9,104,4,0,
359,9,126,4,0,
359,9,129,3,0,
359,9,138,4,0,
359,9,148,4,0,
359,9,156,4,0,
359,9,157,4,0,
359,9,163,1,36,
359,9,164,2,0,
359,9,164,4,0,
359,9,168,4,0,
359,9,173,3,0,
359,9,174,2,0,
359,9,180,3,0,
359,9,182,4,0,
359,9,185,2,0,
359,9,189,3,0,
359,9,195,1,65,
359,9,196,3,0,
359,9,197,1,49,
359,9,201,4,0,
359,9,203,4,0,
359,9,206,4,0,
359,9,207,4,0,
359,9,210,3,0,
359,9,212,2,0,
359,9,213,4,0,
359,9,214,4,0,
359,9,216,4,0,
359,9,218,4,0,
359,9,226,2,0,
359,9,228,1,20,
359,9,231,4,0,
359,9,237,4,0,
359,9,240,4,0,
359,9,241,4,0,
359,9,244,4,0,
359,9,247,4,0,
359,9,248,1,41,
359,9,249,4,0,
359,9,258,4,0,
359,9,259,4,0,
359,9,261,4,0,
359,9,263,4,0,
359,9,269,1,9,
359,9,269,4,0,
359,9,276,3,0,
359,9,277,2,0,
359,9,282,3,0,
359,9,289,4,0,
359,9,290,4,0,
359,9,317,4,0,
359,9,332,4,0,
359,9,340,3,0,
359,9,347,4,0,
359,9,351,4,0,
359,9,352,4,0,
359,9,363,4,0,
359,9,364,1,1,2
359,9,371,4,0,
359,9,372,2,0,
359,9,382,1,57,
359,9,382,2,0,
359,9,386,2,0,
359,9,389,1,44,
359,9,389,2,0,
359,9,389,3,0,
359,9,399,4,0,
359,9,400,1,52,
359,9,404,4,0,
359,9,416,4,0,
359,9,421,4,0,
359,9,427,1,60,
359,9,428,2,0,
359,9,428,3,0,
359,9,444,4,0,
359,9,445,4,0,
359,9,451,4,0,
359,10,10,1,1,1
359,10,13,1,17,
359,10,14,1,25,
359,10,14,4,0,
359,10,15,4,0,
359,10,29,3,0,
359,10,38,2,0,
359,10,43,1,4,
359,10,44,1,28,
359,10,53,4,0,
359,10,58,4,0,
359,10,59,4,0,
359,10,63,4,0,
359,10,70,4,0,
359,10,85,4,0,
359,10,86,4,0,
359,10,87,4,0,
359,10,92,4,0,
359,10,98,1,12,
359,10,104,1,33,
359,10,104,4,0,
359,10,126,4,0,
359,10,129,3,0,
359,10,138,4,0,
359,10,148,4,0,
359,10,156,4,0,
359,10,157,4,0,
359,10,163,1,36,
359,10,164,2,0,
359,10,164,4,0,
359,10,168,4,0,
359,10,173,3,0,
359,10,174,2,0,
359,10,180,3,0,
359,10,182,4,0,
359,10,185,2,0,
359,10,189,3,0,
359,10,195,1,65,
359,10,196,3,0,
359,10,197,1,49,
359,10,201,4,0,
359,10,203,4,0,
359,10,206,4,0,
359,10,207,4,0,
359,10,210,3,0,
359,10,212,2,0,
359,10,213,4,0,
359,10,214,4,0,
359,10,216,4,0,
359,10,218,4,0,
359,10,224,2,0,
359,10,226,2,0,
359,10,228,1,20,
359,10,231,4,0,
359,10,237,4,0,
359,10,240,4,0,
359,10,241,4,0,
359,10,244,4,0,
359,10,247,4,0,
359,10,248,1,41,
359,10,249,4,0,
359,10,258,4,0,
359,10,259,4,0,
359,10,261,4,0,
359,10,263,4,0,
359,10,269,1,9,
359,10,269,4,0,
359,10,272,3,0,
359,10,276,3,0,
359,10,277,2,0,
359,10,277,3,0,
359,10,282,3,0,
359,10,289,4,0,
359,10,290,4,0,
359,10,317,4,0,
359,10,332,4,0,
359,10,340,3,0,
359,10,347,4,0,
359,10,351,4,0,
359,10,352,4,0,
359,10,363,4,0,
359,10,364,1,1,2
359,10,371,4,0,
359,10,372,2,0,
359,10,382,1,57,
359,10,382,2,0,
359,10,386,2,0,
359,10,389,1,44,
359,10,389,2,0,
359,10,389,3,0,
359,10,399,4,0,
359,10,400,1,52,
359,10,404,4,0,
359,10,416,4,0,
359,10,421,4,0,
359,10,427,1,60,
359,10,428,2,0,
359,10,428,3,0,
359,10,444,4,0,
359,10,445,4,0,
359,10,451,4,0,
359,11,10,1,1,1
359,11,13,1,17,
359,11,14,1,25,
359,11,14,4,0,
359,11,15,4,0,
359,11,38,2,0,
359,11,43,1,4,
359,11,44,1,28,
359,11,53,4,0,
359,11,58,4,0,
359,11,59,4,0,
359,11,63,4,0,
359,11,70,4,0,
359,11,85,4,0,
359,11,86,4,0,
359,11,87,4,0,
359,11,92,4,0,
359,11,98,1,12,
359,11,104,1,33,
359,11,104,4,0,
359,11,126,4,0,
359,11,138,4,0,
359,11,148,4,0,
359,11,156,4,0,
359,11,157,4,0,
359,11,163,1,36,
359,11,164,4,0,
359,11,168,4,0,
359,11,174,2,0,
359,11,182,4,0,
359,11,185,2,0,
359,11,195,1,65,
359,11,195,2,0,
359,11,197,1,49,
359,11,201,4,0,
359,11,206,4,0,
359,11,207,4,0,
359,11,212,2,0,
359,11,213,4,0,
359,11,216,4,0,
359,11,218,4,0,
359,11,224,2,0,
359,11,226,2,0,
359,11,228,1,20,
359,11,237,4,0,
359,11,240,4,0,
359,11,241,4,0,
359,11,244,4,0,
359,11,247,4,0,
359,11,248,1,41,
359,11,249,4,0,
359,11,258,4,0,
359,11,259,4,0,
359,11,261,4,0,
359,11,263,4,0,
359,11,269,1,9,
359,11,269,4,0,
359,11,277,2,0,
359,11,317,4,0,
359,11,332,4,0,
359,11,347,4,0,
359,11,364,1,1,2
359,11,371,4,0,
359,11,372,2,0,
359,11,382,1,57,
359,11,382,2,0,
359,11,386,2,0,
359,11,389,1,44,
359,11,389,2,0,
359,11,400,1,52,
359,11,404,4,0,
359,11,416,4,0,
359,11,421,4,0,
359,11,427,1,60,
359,11,428,2,0,
359,11,444,4,0,
359,11,451,4,0,
359,11,468,4,0,
359,11,496,4,0,
359,11,497,4,0,
359,11,506,2,0,
359,11,510,4,0,
359,11,514,4,0,
359,11,555,4,0,
359,12,10,1,1,
359,12,13,1,17,
359,12,14,1,26,
359,12,15,4,0,
359,12,43,1,5,
359,12,44,1,21,
359,12,53,4,0,
359,12,58,4,0,
359,12,59,4,0,
359,12,63,4,0,
359,12,70,4,0,
359,12,85,4,0,
359,12,87,4,0,
359,12,92,4,0,
359,12,98,1,13,
359,12,104,1,31,
359,12,104,4,0,
359,12,126,4,0,
359,12,148,4,0,
359,12,156,4,0,
359,12,163,1,36,
359,12,168,4,0,
359,12,182,4,0,
359,12,195,1,46,
359,12,201,4,0,
359,12,213,4,0,
359,12,216,4,0,
359,12,218,4,0,
359,12,231,4,0,
359,12,237,4,0,
359,12,240,4,0,
359,12,241,4,0,
359,12,247,4,0,
359,12,248,1,41,
359,12,249,4,0,
359,12,258,4,0,
359,12,259,4,0,
359,12,263,4,0,
359,12,269,1,9,
359,12,269,4,0,
359,12,289,4,0,
359,12,290,4,0,
359,12,332,4,0,
359,12,347,4,0,
359,12,351,4,0,
359,12,352,4,0,
359,13,10,1,1,
359,13,13,1,17,
359,13,14,1,26,
359,13,15,4,0,
359,13,34,3,0,
359,13,38,3,0,
359,13,43,1,5,
359,13,44,1,21,
359,13,53,4,0,
359,13,58,4,0,
359,13,59,4,0,
359,13,63,4,0,
359,13,70,4,0,
359,13,85,4,0,
359,13,86,3,0,
359,13,87,4,0,
359,13,92,4,0,
359,13,98,1,13,
359,13,102,3,0,
359,13,104,1,31,
359,13,104,4,0,
359,13,126,4,0,
359,13,138,3,0,
359,13,148,4,0,
359,13,156,4,0,
359,13,163,1,36,
359,13,164,3,0,
359,13,168,4,0,
359,13,171,3,0,
359,13,182,4,0,
359,13,195,1,46,
359,13,196,3,0,
359,13,201,4,0,
359,13,207,3,0,
359,13,213,4,0,
359,13,216,4,0,
359,13,218,4,0,
359,13,231,4,0,
359,13,237,4,0,
359,13,240,4,0,
359,13,241,4,0,
359,13,247,4,0,
359,13,248,1,41,
359,13,249,4,0,
359,13,258,4,0,
359,13,259,4,0,
359,13,263,4,0,
359,13,269,1,9,
359,13,269,4,0,
359,13,289,4,0,
359,13,290,4,0,
359,13,332,4,0,
359,13,347,4,0,
359,13,351,4,0,
359,13,352,4,0,
359,14,10,1,1,1
359,14,13,1,57,
359,14,14,1,33,
359,14,14,4,0,
359,14,15,4,0,
359,14,38,2,0,
359,14,43,1,4,
359,14,44,1,20,
359,14,53,4,0,
359,14,58,4,0,
359,14,59,4,0,
359,14,63,4,0,
359,14,70,4,0,
359,14,85,4,0,
359,14,86,4,0,
359,14,87,4,0,
359,14,92,4,0,
359,14,98,1,9,
359,14,104,1,25,
359,14,104,4,0,
359,14,126,4,0,
359,14,138,4,0,
359,14,148,4,0,
359,14,156,4,0,
359,14,157,4,0,
359,14,163,1,28,
359,14,164,4,0,
359,14,168,4,0,
359,14,173,3,0,
359,14,174,2,0,
359,14,180,3,0,
359,14,182,4,0,
359,14,185,2,0,
359,14,195,1,65,
359,14,195,2,0,
359,14,196,3,0,
359,14,197,1,44,
359,14,201,4,0,
359,14,206,4,0,
359,14,207,4,0,
359,14,212,2,0,
359,14,213,4,0,
359,14,214,3,0,
359,14,216,4,0,
359,14,218,4,0,
359,14,224,2,0,
359,14,226,2,0,
359,14,228,1,12,
359,14,231,3,0,
359,14,237,4,0,
359,14,240,4,0,
359,14,241,4,0,
359,14,244,4,0,
359,14,247,4,0,
359,14,248,1,36,
359,14,249,4,0,
359,14,258,4,0,
359,14,259,4,0,
359,14,261,4,0,
359,14,263,4,0,
359,14,269,1,17,
359,14,269,4,0,
359,14,272,3,0,
359,14,276,3,0,
359,14,277,2,0,
359,14,277,3,0,
359,14,282,3,0,
359,14,289,3,0,
359,14,317,4,0,
359,14,332,4,0,
359,14,340,3,0,
359,14,347,4,0,
359,14,364,1,1,2
359,14,371,4,0,
359,14,372,2,0,
359,14,382,1,60,
359,14,382,2,0,
359,14,386,2,0,
359,14,389,1,52,
359,14,389,2,0,
359,14,399,3,0,
359,14,400,1,41,
359,14,404,4,0,
359,14,416,4,0,
359,14,421,4,0,
359,14,427,1,49,
359,14,428,2,0,
359,14,428,3,0,
359,14,444,4,0,
359,14,451,4,0,
359,14,468,4,0,
359,14,492,3,0,
359,14,496,4,0,
359,14,497,4,0,
359,14,506,2,0,
359,14,510,4,0,
359,14,514,4,0,
359,14,555,4,0,
359,15,10,1,1,6
359,15,13,1,1,3
359,15,13,1,57,
359,15,14,1,33,
359,15,14,4,0,
359,15,15,4,0,
359,15,38,2,0,
359,15,43,1,4,
359,15,44,1,20,
359,15,53,4,0,
359,15,58,4,0,
359,15,59,4,0,
359,15,63,4,0,
359,15,70,4,0,
359,15,85,4,0,
359,15,86,4,0,
359,15,87,4,0,
359,15,92,4,0,
359,15,98,1,9,
359,15,104,1,25,
359,15,104,4,0,
359,15,126,4,0,
359,15,138,4,0,
359,15,148,4,0,
359,15,156,4,0,
359,15,157,4,0,
359,15,163,1,28,
359,15,164,4,0,
359,15,168,4,0,
359,15,174,2,0,
359,15,182,4,0,
359,15,185,2,0,
359,15,195,1,1,1
359,15,195,1,65,
359,15,195,2,0,
359,15,197,1,1,4
359,15,197,1,44,
359,15,201,4,0,
359,15,206,4,0,
359,15,207,4,0,
359,15,212,2,0,
359,15,213,4,0,
359,15,214,4,0,
359,15,216,4,0,
359,15,218,4,0,
359,15,224,2,0,
359,15,226,2,0,
359,15,228,1,12,
359,15,237,4,0,
359,15,240,4,0,
359,15,241,4,0,
359,15,244,4,0,
359,15,247,4,0,
359,15,248,1,36,
359,15,249,4,0,
359,15,258,4,0,
359,15,259,4,0,
359,15,261,4,0,
359,15,263,4,0,
359,15,269,1,1,5
359,15,269,1,17,
359,15,269,4,0,
359,15,277,2,0,
359,15,317,4,0,
359,15,332,4,0,
359,15,347,4,0,
359,15,364,1,1,7
359,15,371,4,0,
359,15,372,2,0,
359,15,382,1,1,2
359,15,382,1,60,
359,15,382,2,0,
359,15,386,2,0,
359,15,389,1,50,
359,15,389,2,0,
359,15,399,4,0,
359,15,400,1,41,
359,15,404,4,0,
359,15,416,4,0,
359,15,421,4,0,
359,15,427,1,47,
359,15,428,2,0,
359,15,444,4,0,
359,15,451,4,0,
359,15,468,4,0,
359,15,496,4,0,
359,15,497,4,0,
359,15,506,2,0,
359,15,510,4,0,
359,15,514,4,0,
359,15,555,4,0,
359,15,583,2,0,
359,15,590,4,0,
359,16,10,1,1,3
359,16,13,1,49,1
359,16,14,1,25,1
359,16,14,4,0,
359,16,15,4,0,
359,16,38,2,0,
359,16,43,1,1,5
359,16,43,1,4,1
359,16,44,1,16,1
359,16,53,4,0,
359,16,58,4,0,
359,16,59,4,0,
359,16,63,4,0,
359,16,70,4,0,
359,16,85,4,0,
359,16,86,4,0,
359,16,87,4,0,
359,16,92,4,0,
359,16,98,1,1,6
359,16,98,1,7,1
359,16,104,1,19,1
359,16,104,4,0,
359,16,126,4,0,
359,16,138,4,0,
359,16,148,4,0,
359,16,156,4,0,
359,16,157,4,0,
359,16,163,1,22,1
359,16,164,4,0,
359,16,168,4,0,
359,16,173,3,0,
359,16,174,2,0,
359,16,180,3,0,
359,16,182,4,0,
359,16,185,2,0,
359,16,195,1,1,1
359,16,195,1,57,1
359,16,195,2,0,
359,16,196,3,0,
359,16,197,1,33,1
359,16,201,4,0,
359,16,206,4,0,
359,16,207,4,0,
359,16,212,2,0,
359,16,213,4,0,
359,16,214,4,0,
359,16,216,4,0,
359,16,218,4,0,
359,16,224,2,0,
359,16,226,2,0,
359,16,228,1,10,1
359,16,231,3,0,
359,16,237,4,0,
359,16,240,4,0,
359,16,241,4,0,
359,16,244,4,0,
359,16,247,4,0,
359,16,248,1,1,2
359,16,248,1,53,1
359,16,249,4,0,
359,16,258,4,0,
359,16,259,4,0,
359,16,261,4,0,
359,16,263,4,0,
359,16,269,1,13,1
359,16,269,4,0,
359,16,272,3,0,
359,16,276,3,0,
359,16,277,2,0,
359,16,277,3,0,
359,16,282,3,0,
359,16,289,3,0,
359,16,290,4,0,
359,16,317,4,0,
359,16,332,4,0,
359,16,340,3,0,
359,16,347,4,0,
359,16,351,3,0,
359,16,352,3,0,
359,16,364,1,1,4
359,16,371,4,0,
359,16,372,2,0,
359,16,382,1,41,1
359,16,382,2,0,
359,16,386,2,0,
359,16,389,1,45,1
359,16,389,2,0,
359,16,399,4,0,
359,16,400,1,29,1
359,16,404,4,0,
359,16,416,4,0,
359,16,421,4,0,
359,16,427,1,37,1
359,16,428,2,0,
359,16,428,3,0,
359,16,444,4,0,
359,16,451,4,0,
359,16,468,4,0,
359,16,492,3,0,
359,16,496,4,0,
359,16,497,4,0,
359,16,506,2,0,
359,16,510,4,0,
359,16,514,4,0,
359,16,555,4,0,
359,16,583,2,0,
359,16,590,4,0,
359,17,10,1,1,3
359,17,13,1,49,
359,17,14,1,25,
359,17,14,4,0,
359,17,38,2,0,
359,17,43,1,1,5
359,17,43,1,4,
359,17,44,1,16,
359,17,53,4,0,
359,17,58,4,0,
359,17,59,4,0,
359,17,63,4,0,
359,17,85,4,0,
359,17,86,4,0,
359,17,87,4,0,
359,17,92,4,0,
359,17,98,1,1,6
359,17,98,1,7,
359,17,104,1,19,
359,17,104,4,0,
359,17,126,4,0,
359,17,138,4,0,
359,17,156,4,0,
359,17,157,4,0,
359,17,163,1,22,
359,17,164,4,0,
359,17,168,4,0,
359,17,174,2,0,
359,17,182,4,0,
359,17,185,2,0,
359,17,195,1,1,1
359,17,195,1,57,
359,17,195,2,0,
359,17,197,1,33,
359,17,201,4,0,
359,17,206,4,0,
359,17,207,4,0,
359,17,212,2,0,
359,17,213,4,0,
359,17,214,4,0,
359,17,216,4,0,
359,17,218,4,0,
359,17,224,2,0,
359,17,226,2,0,
359,17,228,1,10,
359,17,237,4,0,
359,17,240,4,0,
359,17,241,4,0,
359,17,244,4,0,
359,17,247,4,0,
359,17,248,1,1,2
359,17,248,1,53,
359,17,258,4,0,
359,17,259,4,0,
359,17,261,4,0,
359,17,263,4,0,
359,17,269,1,13,
359,17,269,4,0,
359,17,277,2,0,
359,17,317,4,0,
359,17,332,4,0,
359,17,347,4,0,
359,17,364,1,1,4
359,17,371,4,0,
359,17,372,2,0,
359,17,382,1,41,
359,17,382,2,0,
359,17,386,2,0,
359,17,389,1,45,
359,17,389,2,0,
359,17,399,4,0,
359,17,400,1,29,
359,17,404,4,0,
359,17,416,4,0,
359,17,421,4,0,
359,17,427,1,37,
359,17,428,2,0,
359,17,444,4,0,
359,17,451,4,0,
359,17,496,4,0,
359,17,497,4,0,
359,17,506,2,0,
359,17,555,4,0,
359,17,583,2,0,
359,17,590,4,0,
359,17,693,4,0,
359,18,10,1,1,3
359,18,13,1,49,
359,18,14,1,25,
359,18,14,4,0,
359,18,38,2,0,
359,18,43,1,1,5
359,18,43,1,4,
359,18,44,1,16,
359,18,53,4,0,
359,18,58,4,0,
359,18,59,4,0,
359,18,63,4,0,
359,18,85,4,0,
359,18,86,4,0,
359,18,87,4,0,
359,18,92,4,0,
359,18,98,1,1,6
359,18,98,1,7,
359,18,104,1,19,
359,18,104,4,0,
359,18,126,4,0,
359,18,138,4,0,
359,18,156,4,0,
359,18,157,4,0,
359,18,163,1,22,
359,18,164,4,0,
359,18,168,4,0,
359,18,174,2,0,
359,18,182,4,0,
359,18,185,2,0,
359,18,195,1,1,1
359,18,195,1,57,
359,18,195,2,0,
359,18,197,1,33,
359,18,201,4,0,
359,18,206,4,0,
359,18,207,4,0,
359,18,212,2,0,
359,18,213,4,0,
359,18,214,4,0,
359,18,216,4,0,
359,18,218,4,0,
359,18,224,2,0,
359,18,226,2,0,
359,18,228,1,10,
359,18,237,4,0,
359,18,240,4,0,
359,18,241,4,0,
359,18,244,4,0,
359,18,247,4,0,
359,18,248,1,1,2
359,18,248,1,53,
359,18,258,4,0,
359,18,259,4,0,
359,18,261,4,0,
359,18,263,4,0,
359,18,269,1,13,
359,18,269,4,0,
359,18,277,2,0,
359,18,317,4,0,
359,18,332,4,0,
359,18,347,4,0,
359,18,364,1,1,4
359,18,371,4,0,
359,18,372,2,0,
359,18,382,1,41,
359,18,382,2,0,
359,18,386,2,0,
359,18,389,1,45,
359,18,389,2,0,
359,18,399,4,0,
359,18,400,1,29,
359,18,404,4,0,
359,18,416,4,0,
359,18,421,4,0,
359,18,427,1,37,
359,18,428,2,0,
359,18,444,4,0,
359,18,451,4,0,
359,18,496,4,0,
359,18,497,4,0,
359,18,506,2,0,
359,18,555,4,0,
359,18,583,2,0,
359,18,590,4,0,
359,18,693,4,0,
360,5,68,1,15,
360,5,150,1,1,1
360,5,194,1,15,6
360,5,204,1,1,2
360,5,219,1,15,5
360,5,227,1,1,3
360,5,243,1,15,4
360,6,68,1,15,
360,6,150,1,1,1
360,6,194,1,15,6
360,6,204,1,1,2
360,6,219,1,15,5
360,6,227,1,1,3
360,6,243,1,15,4
360,7,68,1,15,
360,7,150,1,1,1
360,7,194,1,15,6
360,7,204,1,1,2
360,7,219,1,15,5
360,7,227,1,1,3
360,7,243,1,15,4
360,8,68,1,15,1
360,8,150,1,1,1
360,8,194,1,15,4
360,8,204,1,1,2
360,8,219,1,15,3
360,8,227,1,1,3
360,8,243,1,15,2
360,9,68,1,15,1
360,9,150,1,1,1
360,9,194,1,15,4
360,9,204,1,1,2
360,9,219,1,15,3
360,9,227,1,1,3
360,9,243,1,15,2
360,10,68,1,15,1
360,10,150,1,1,1
360,10,194,1,15,4
360,10,204,1,1,2
360,10,219,1,15,3
360,10,227,1,1,3
360,10,243,1,15,2
360,11,68,1,15,1
360,11,150,1,1,1
360,11,194,1,15,4
360,11,204,1,1,2
360,11,219,1,15,3
360,11,227,1,1,3
360,11,243,1,15,2
360,12,68,1,15,
360,12,150,1,1,1
360,12,194,1,15,6
360,12,204,1,1,2
360,12,219,1,15,5
360,12,227,1,1,3
360,12,243,1,15,4
360,13,68,1,15,
360,13,150,1,1,1
360,13,194,1,15,6
360,13,204,1,1,2
360,13,219,1,15,5
360,13,227,1,1,3
360,13,243,1,15,4
360,14,68,1,15,1
360,14,150,1,1,1
360,14,194,1,15,4
360,14,204,1,1,2
360,14,219,1,15,3
360,14,227,1,1,3
360,14,243,1,15,2
360,15,68,1,15,1
360,15,150,1,1,1
360,15,194,1,15,4
360,15,204,1,1,2
360,15,219,1,15,3
360,15,219,4,0,
360,15,227,1,1,3
360,15,243,1,15,2
360,16,68,1,15,1
360,16,150,1,1,1
360,16,194,1,15,4
360,16,204,1,1,2
360,16,219,1,15,3
360,16,219,4,0,
360,16,227,1,1,3
360,16,243,1,15,2
360,17,68,1,15,1
360,17,150,1,1,1
360,17,194,1,15,4
360,17,204,1,1,2
360,17,219,1,15,3
360,17,219,4,0,
360,17,227,1,1,3
360,17,243,1,15,2
360,18,68,1,15,1
360,18,150,1,1,1
360,18,194,1,15,4
360,18,204,1,1,2
360,18,219,1,15,3
360,18,219,4,0,
360,18,227,1,1,3
360,18,243,1,15,2
361,5,29,1,19,
361,5,43,1,1,2
361,5,44,1,10,
361,5,58,1,34,
361,5,58,4,0,
361,5,59,1,43,
361,5,59,4,0,
361,5,92,4,0,
361,5,104,1,7,
361,5,104,4,0,
361,5,113,4,0,
361,5,148,4,0,
361,5,156,4,0,
361,5,181,1,1,1
361,5,182,1,25,
361,5,182,4,0,
361,5,191,2,0,
361,5,196,1,16,
361,5,213,4,0,
361,5,216,4,0,
361,5,218,4,0,
361,5,219,4,0,
361,5,237,4,0,
361,5,240,4,0,
361,5,242,1,28,
361,5,247,4,0,
361,5,258,1,37,
361,5,258,4,0,
361,5,263,4,0,
361,5,290,4,0,
361,5,335,2,0,
361,5,352,4,0,
361,6,29,1,19,
361,6,34,3,0,
361,6,38,3,0,
361,6,43,1,1,2
361,6,44,1,10,
361,6,58,1,34,
361,6,58,4,0,
361,6,59,1,43,
361,6,59,4,0,
361,6,92,4,0,
361,6,102,3,0,
361,6,104,1,7,
361,6,104,4,0,
361,6,113,4,0,
361,6,148,4,0,
361,6,156,4,0,
361,6,164,3,0,
361,6,173,3,0,
361,6,181,1,1,1
361,6,182,1,25,
361,6,182,4,0,
361,6,191,2,0,
361,6,196,1,16,
361,6,196,3,0,
361,6,203,3,0,
361,6,207,3,0,
361,6,213,4,0,
361,6,214,3,0,
361,6,216,4,0,
361,6,218,4,0,
361,6,219,4,0,
361,6,237,4,0,
361,6,240,4,0,
361,6,242,1,28,
361,6,247,4,0,
361,6,258,1,37,
361,6,258,4,0,
361,6,263,4,0,
361,6,290,4,0,
361,6,335,2,0,
361,6,352,4,0,
361,7,29,1,19,
361,7,34,3,0,
361,7,38,3,0,
361,7,43,1,1,2
361,7,44,1,10,
361,7,58,1,34,
361,7,58,4,0,
361,7,59,1,43,
361,7,59,4,0,
361,7,92,4,0,
361,7,102,3,0,
361,7,104,1,7,
361,7,104,4,0,
361,7,113,4,0,
361,7,148,4,0,
361,7,156,4,0,
361,7,164,3,0,
361,7,181,1,1,1
361,7,182,1,25,
361,7,182,4,0,
361,7,191,2,0,
361,7,196,1,16,
361,7,213,4,0,
361,7,216,4,0,
361,7,218,4,0,
361,7,219,4,0,
361,7,237,4,0,
361,7,240,4,0,
361,7,242,1,28,
361,7,247,4,0,
361,7,258,1,37,
361,7,258,4,0,
361,7,263,4,0,
361,7,290,4,0,
361,7,335,2,0,
361,7,352,4,0,
361,8,29,1,19,
361,8,43,1,1,2
361,8,44,1,10,
361,8,50,2,0,
361,8,58,4,0,
361,8,59,1,46,
361,8,59,4,0,
361,8,92,4,0,
361,8,104,1,4,
361,8,104,4,0,
361,8,113,4,0,
361,8,117,2,0,
361,8,148,4,0,
361,8,156,4,0,
361,8,164,4,0,
361,8,181,1,1,1
361,8,182,1,22,
361,8,182,4,0,
361,8,191,2,0,
361,8,196,1,13,
361,8,203,4,0,
361,8,205,2,0,
361,8,207,4,0,
361,8,213,4,0,
361,8,214,4,0,
361,8,216,4,0,
361,8,218,4,0,
361,8,219,4,0,
361,8,237,4,0,
361,8,240,4,0,
361,8,242,1,31,
361,8,247,4,0,
361,8,258,1,40,
361,8,258,4,0,
361,8,263,4,0,
361,8,290,4,0,
361,8,335,2,0,
361,8,352,4,0,
361,8,363,4,0,
361,8,419,4,0,
361,8,420,1,37,
361,8,423,1,28,
361,8,445,4,0,
361,9,29,1,19,
361,9,43,1,1,2
361,9,44,1,10,
361,9,50,2,0,
361,9,58,4,0,
361,9,59,1,46,
361,9,59,4,0,
361,9,92,4,0,
361,9,104,1,4,
361,9,104,4,0,
361,9,113,4,0,
361,9,117,2,0,
361,9,148,4,0,
361,9,156,4,0,
361,9,164,4,0,
361,9,173,3,0,
361,9,180,3,0,
361,9,181,1,1,1
361,9,182,1,22,
361,9,182,4,0,
361,9,191,2,0,
361,9,196,1,13,
361,9,196,3,0,
361,9,203,4,0,
361,9,205,2,0,
361,9,205,3,0,
361,9,207,4,0,
361,9,213,4,0,
361,9,214,4,0,
361,9,216,4,0,
361,9,218,4,0,
361,9,219,4,0,
361,9,237,4,0,
361,9,240,4,0,
361,9,242,1,31,
361,9,247,4,0,
361,9,258,1,40,
361,9,258,4,0,
361,9,263,4,0,
361,9,290,4,0,
361,9,335,2,0,
361,9,352,4,0,
361,9,363,4,0,
361,9,419,4,0,
361,9,420,1,37,
361,9,423,1,28,
361,9,445,4,0,
361,10,29,1,19,
361,10,29,3,0,
361,10,43,1,1,2
361,10,44,1,10,
361,10,50,2,0,
361,10,58,4,0,
361,10,59,1,46,
361,10,59,4,0,
361,10,92,4,0,
361,10,104,1,4,
361,10,104,4,0,
361,10,113,4,0,
361,10,117,2,0,
361,10,148,4,0,
361,10,156,4,0,
361,10,164,4,0,
361,10,173,3,0,
361,10,180,3,0,
361,10,181,1,1,1
361,10,182,1,22,
361,10,182,4,0,
361,10,191,2,0,
361,10,196,1,13,
361,10,196,3,0,
361,10,203,4,0,
361,10,205,2,0,
361,10,205,3,0,
361,10,207,4,0,
361,10,213,4,0,
361,10,214,4,0,
361,10,216,4,0,
361,10,218,4,0,
361,10,219,4,0,
361,10,237,4,0,
361,10,240,4,0,
361,10,242,1,31,
361,10,247,4,0,
361,10,258,1,40,
361,10,258,4,0,
361,10,263,4,0,
361,10,290,4,0,
361,10,311,2,0,
361,10,335,2,0,
361,10,335,3,0,
361,10,352,4,0,
361,10,363,4,0,
361,10,419,4,0,
361,10,420,1,37,
361,10,423,1,28,
361,10,445,4,0,
361,11,29,1,19,
361,11,43,1,1,2
361,11,44,1,10,
361,11,50,2,0,
361,11,58,4,0,
361,11,59,1,46,
361,11,59,4,0,
361,11,92,4,0,
361,11,104,1,4,
361,11,104,4,0,
361,11,113,4,0,
361,11,117,2,0,
361,11,148,4,0,
361,11,156,4,0,
361,11,164,4,0,
361,11,181,1,1,1
361,11,182,1,22,
361,11,182,4,0,
361,11,191,2,0,
361,11,196,1,13,
361,11,205,2,0,
361,11,207,4,0,
361,11,213,4,0,
361,11,216,4,0,
361,11,218,4,0,
361,11,219,4,0,
361,11,237,4,0,
361,11,240,4,0,
361,11,242,1,31,
361,11,247,4,0,
361,11,258,1,40,
361,11,258,4,0,
361,11,263,4,0,
361,11,311,2,0,
361,11,313,2,0,
361,11,335,2,0,
361,11,419,2,0,
361,11,420,1,37,
361,11,423,1,28,
361,11,496,4,0,
361,11,506,2,0,
361,11,524,4,0,
361,12,29,1,19,
361,12,43,1,1,2
361,12,44,1,10,
361,12,58,1,34,
361,12,58,4,0,
361,12,59,1,43,
361,12,59,4,0,
361,12,92,4,0,
361,12,104,1,7,
361,12,104,4,0,
361,12,113,4,0,
361,12,148,4,0,
361,12,156,4,0,
361,12,181,1,1,1
361,12,182,1,25,
361,12,182,4,0,
361,12,196,1,16,
361,12,213,4,0,
361,12,216,4,0,
361,12,218,4,0,
361,12,219,4,0,
361,12,237,4,0,
361,12,240,4,0,
361,12,242,1,28,
361,12,247,4,0,
361,12,258,1,37,
361,12,258,4,0,
361,12,263,4,0,
361,12,290,4,0,
361,12,352,4,0,
361,13,29,1,19,
361,13,34,3,0,
361,13,38,3,0,
361,13,43,1,1,2
361,13,44,1,10,
361,13,58,1,34,
361,13,58,4,0,
361,13,59,1,43,
361,13,59,4,0,
361,13,92,4,0,
361,13,102,3,0,
361,13,104,1,7,
361,13,104,4,0,
361,13,113,4,0,
361,13,148,4,0,
361,13,156,4,0,
361,13,164,3,0,
361,13,181,1,1,1
361,13,182,1,25,
361,13,182,4,0,
361,13,196,1,16,
361,13,196,3,0,
361,13,207,3,0,
361,13,213,4,0,
361,13,216,4,0,
361,13,218,4,0,
361,13,219,4,0,
361,13,237,4,0,
361,13,240,4,0,
361,13,242,1,28,
361,13,247,4,0,
361,13,258,1,37,
361,13,258,4,0,
361,13,263,4,0,
361,13,290,4,0,
361,13,352,4,0,
361,14,29,1,19,
361,14,43,1,1,2
361,14,44,1,10,
361,14,50,2,0,
361,14,58,4,0,
361,14,59,1,46,
361,14,59,4,0,
361,14,92,4,0,
361,14,104,1,4,
361,14,104,4,0,
361,14,113,4,0,
361,14,117,2,0,
361,14,148,4,0,
361,14,156,4,0,
361,14,164,4,0,
361,14,173,3,0,
361,14,180,3,0,
361,14,181,1,1,1
361,14,182,1,22,
361,14,182,4,0,
361,14,191,2,0,
361,14,196,1,13,
361,14,196,3,0,
361,14,205,2,0,
361,14,207,4,0,
361,14,213,4,0,
361,14,214,3,0,
361,14,216,4,0,
361,14,218,4,0,
361,14,219,4,0,
361,14,237,4,0,
361,14,240,4,0,
361,14,242,1,31,
361,14,247,4,0,
361,14,258,1,40,
361,14,258,4,0,
361,14,263,4,0,
361,14,311,2,0,
361,14,313,2,0,
361,14,335,2,0,
361,14,335,3,0,
361,14,419,2,0,
361,14,420,1,37,
361,14,423,1,28,
361,14,496,4,0,
361,14,506,2,0,
361,14,524,4,0,
361,15,29,1,19,
361,15,43,1,1,2
361,15,44,1,10,
361,15,50,2,0,
361,15,58,4,0,
361,15,59,1,46,
361,15,59,4,0,
361,15,92,4,0,
361,15,104,1,4,
361,15,104,4,0,
361,15,113,4,0,
361,15,117,2,0,
361,15,148,4,0,
361,15,156,4,0,
361,15,164,4,0,
361,15,181,1,1,1
361,15,182,1,22,
361,15,182,4,0,
361,15,191,2,0,
361,15,196,1,13,
361,15,205,2,0,
361,15,207,4,0,
361,15,213,4,0,
361,15,214,4,0,
361,15,216,4,0,
361,15,218,4,0,
361,15,219,4,0,
361,15,237,4,0,
361,15,240,4,0,
361,15,242,1,31,
361,15,247,4,0,
361,15,258,1,40,
361,15,258,4,0,
361,15,263,4,0,
361,15,311,2,0,
361,15,313,2,0,
361,15,335,2,0,
361,15,415,2,0,
361,15,419,2,0,
361,15,420,1,37,
361,15,423,1,28,
361,15,496,4,0,
361,15,506,2,0,
361,15,524,4,0,
361,15,590,4,0,
361,16,29,1,28,1
361,16,43,1,1,2
361,16,44,1,19,1
361,16,50,2,0,
361,16,58,4,0,
361,16,59,1,46,1
361,16,59,4,0,
361,16,92,4,0,
361,16,104,1,5,1
361,16,104,4,0,
361,16,113,4,0,
361,16,117,2,0,
361,16,148,4,0,
361,16,156,4,0,
361,16,164,4,0,
361,16,173,3,0,
361,16,180,3,0,
361,16,181,1,1,1
361,16,182,1,32,1
361,16,182,4,0,
361,16,191,2,0,
361,16,196,1,14,1
361,16,196,3,0,
361,16,205,2,0,
361,16,207,4,0,
361,16,213,4,0,
361,16,214,4,0,
361,16,216,4,0,
361,16,218,4,0,
361,16,219,4,0,
361,16,237,4,0,
361,16,240,4,0,
361,16,242,1,41,1
361,16,247,4,0,
361,16,258,1,50,1
361,16,258,4,0,
361,16,263,4,0,
361,16,290,4,0,
361,16,311,2,0,
361,16,313,2,0,
361,16,335,2,0,
361,16,335,3,0,
361,16,352,3,0,
361,16,415,2,0,
361,16,419,2,0,
361,16,420,1,10,1
361,16,423,1,23,1
361,16,496,4,0,
361,16,506,2,0,
361,16,524,1,37,1
361,16,524,4,0,
361,16,590,4,0,
361,17,29,1,28,
361,17,43,1,1,2
361,17,44,1,19,
361,17,50,2,0,
361,17,58,4,0,
361,17,59,1,46,
361,17,59,4,0,
361,17,92,4,0,
361,17,104,1,5,
361,17,104,4,0,
361,17,113,4,0,
361,17,117,2,0,
361,17,156,4,0,
361,17,164,4,0,
361,17,181,1,1,1
361,17,182,1,32,
361,17,182,4,0,
361,17,191,2,0,
361,17,196,1,14,
361,17,205,2,0,
361,17,207,4,0,
361,17,213,4,0,
361,17,214,4,0,
361,17,216,4,0,
361,17,218,4,0,
361,17,219,4,0,
361,17,237,4,0,
361,17,240,4,0,
361,17,242,1,41,
361,17,247,4,0,
361,17,258,1,50,
361,17,258,4,0,
361,17,263,4,0,
361,17,311,2,0,
361,17,313,2,0,
361,17,335,2,0,
361,17,415,2,0,
361,17,419,2,0,
361,17,420,1,10,
361,17,423,1,23,
361,17,496,4,0,
361,17,506,2,0,
361,17,524,1,37,
361,17,524,4,0,
361,17,590,4,0,
361,18,29,1,28,
361,18,43,1,1,2
361,18,44,1,19,
361,18,50,2,0,
361,18,58,4,0,
361,18,59,1,46,
361,18,59,4,0,
361,18,92,4,0,
361,18,104,1,5,
361,18,104,4,0,
361,18,113,4,0,
361,18,117,2,0,
361,18,156,4,0,
361,18,164,4,0,
361,18,181,1,1,1
361,18,182,1,32,
361,18,182,4,0,
361,18,191,2,0,
361,18,196,1,14,
361,18,205,2,0,
361,18,207,4,0,
361,18,213,4,0,
361,18,214,4,0,
361,18,216,4,0,
361,18,218,4,0,
361,18,219,4,0,
361,18,237,4,0,
361,18,240,4,0,
361,18,242,1,41,
361,18,247,4,0,
361,18,258,1,50,
361,18,258,4,0,
361,18,263,4,0,
361,18,311,2,0,
361,18,313,2,0,
361,18,335,2,0,
361,18,415,2,0,
361,18,419,2,0,
361,18,420,1,10,
361,18,423,1,23,
361,18,496,4,0,
361,18,506,2,0,
361,18,524,1,37,
361,18,524,4,0,
361,18,590,4,0,
362,5,29,1,19,
362,5,43,1,1,2
362,5,44,1,1,4
362,5,44,1,10,
362,5,58,1,34,
362,5,58,4,0,
362,5,59,1,53,
362,5,59,4,0,
362,5,63,4,0,
362,5,89,4,0,
362,5,92,4,0,
362,5,104,1,1,3
362,5,104,1,7,
362,5,104,4,0,
362,5,113,4,0,
362,5,148,4,0,
362,5,156,4,0,
362,5,181,1,1,1
362,5,182,1,25,
362,5,182,4,0,
362,5,196,1,16,
362,5,213,4,0,
362,5,216,4,0,
362,5,218,4,0,
362,5,219,4,0,
362,5,237,4,0,
362,5,240,4,0,
362,5,242,1,28,
362,5,247,4,0,
362,5,258,1,42,
362,5,258,4,0,
362,5,259,4,0,
362,5,263,4,0,
362,5,269,4,0,
362,5,290,4,0,
362,5,329,1,61,
362,5,352,4,0,
362,6,29,1,19,
362,6,34,3,0,
362,6,38,3,0,
362,6,43,1,1,2
362,6,44,1,1,4
362,6,44,1,10,
362,6,58,1,34,
362,6,58,4,0,
362,6,59,1,53,
362,6,59,4,0,
362,6,63,4,0,
362,6,89,4,0,
362,6,92,4,0,
362,6,102,3,0,
362,6,104,1,1,3
362,6,104,1,7,
362,6,104,4,0,
362,6,111,3,0,
362,6,113,4,0,
362,6,148,4,0,
362,6,153,3,0,
362,6,156,4,0,
362,6,164,3,0,
362,6,173,3,0,
362,6,181,1,1,1
362,6,182,1,25,
362,6,182,4,0,
362,6,196,1,16,
362,6,196,3,0,
362,6,203,3,0,
362,6,205,3,0,
362,6,207,3,0,
362,6,213,4,0,
362,6,214,3,0,
362,6,216,4,0,
362,6,218,4,0,
362,6,219,4,0,
362,6,237,4,0,
362,6,240,4,0,
362,6,242,1,28,
362,6,247,4,0,
362,6,258,1,42,
362,6,258,4,0,
362,6,259,4,0,
362,6,263,4,0,
362,6,269,4,0,
362,6,290,4,0,
362,6,329,1,61,
362,6,352,4,0,
362,7,29,1,19,
362,7,34,3,0,
362,7,38,3,0,
362,7,43,1,1,2
362,7,44,1,1,4
362,7,44,1,10,
362,7,58,1,34,
362,7,58,4,0,
362,7,59,1,53,
362,7,59,4,0,
362,7,63,4,0,
362,7,89,4,0,
362,7,92,4,0,
362,7,102,3,0,
362,7,104,1,1,3
362,7,104,1,7,
362,7,104,4,0,
362,7,113,4,0,
362,7,148,4,0,
362,7,153,3,0,
362,7,156,4,0,
362,7,164,3,0,
362,7,181,1,1,1
362,7,182,1,25,
362,7,182,4,0,
362,7,196,1,16,
362,7,213,4,0,
362,7,216,4,0,
362,7,218,4,0,
362,7,219,4,0,
362,7,237,4,0,
362,7,240,4,0,
362,7,242,1,28,
362,7,247,4,0,
362,7,258,1,42,
362,7,258,4,0,
362,7,259,4,0,
362,7,263,4,0,
362,7,269,4,0,
362,7,290,4,0,
362,7,329,1,61,
362,7,352,4,0,
362,8,29,1,19,
362,8,43,1,1,2
362,8,44,1,1,4
362,8,44,1,10,
362,8,58,1,37,
362,8,58,4,0,
362,8,59,1,51,
362,8,59,4,0,
362,8,63,4,0,
362,8,89,4,0,
362,8,92,4,0,
362,8,104,1,1,3
362,8,104,1,4,
362,8,104,4,0,
362,8,113,4,0,
362,8,148,4,0,
362,8,153,4,0,
362,8,156,4,0,
362,8,164,4,0,
362,8,181,1,1,1
362,8,182,1,22,
362,8,182,4,0,
362,8,196,1,13,
362,8,203,4,0,
362,8,207,4,0,
362,8,213,4,0,
362,8,214,4,0,
362,8,216,4,0,
362,8,218,4,0,
362,8,219,4,0,
362,8,237,4,0,
362,8,240,4,0,
362,8,242,1,31,
362,8,247,4,0,
362,8,258,1,40,
362,8,258,4,0,
362,8,259,4,0,
362,8,263,4,0,
362,8,269,4,0,
362,8,290,4,0,
362,8,329,1,59,
362,8,352,4,0,
362,8,360,4,0,
362,8,363,4,0,
362,8,371,4,0,
362,8,399,4,0,
362,8,416,4,0,
362,8,419,4,0,
362,8,423,1,28,
362,8,445,4,0,
362,9,29,1,19,
362,9,43,1,1,2
362,9,44,1,1,4
362,9,44,1,10,
362,9,58,1,37,
362,9,58,4,0,
362,9,59,1,51,
362,9,59,4,0,
362,9,63,4,0,
362,9,89,4,0,
362,9,92,4,0,
362,9,104,1,1,3
362,9,104,1,4,
362,9,104,4,0,
362,9,113,4,0,
362,9,148,4,0,
362,9,153,4,0,
362,9,156,4,0,
362,9,164,4,0,
362,9,173,3,0,
362,9,180,3,0,
362,9,181,1,1,1
362,9,182,1,22,
362,9,182,4,0,
362,9,196,1,13,
362,9,196,3,0,
362,9,203,4,0,
362,9,205,3,0,
362,9,207,4,0,
362,9,213,4,0,
362,9,214,4,0,
362,9,216,4,0,
362,9,218,4,0,
362,9,219,4,0,
362,9,237,4,0,
362,9,240,4,0,
362,9,242,1,31,
362,9,247,4,0,
362,9,258,1,40,
362,9,258,4,0,
362,9,259,4,0,
362,9,263,4,0,
362,9,269,4,0,
362,9,290,4,0,
362,9,324,3,0,
362,9,329,1,59,
362,9,352,4,0,
362,9,360,4,0,
362,9,363,4,0,
362,9,371,4,0,
362,9,399,4,0,
362,9,416,4,0,
362,9,419,4,0,
362,9,423,1,28,
362,9,442,3,0,
362,9,445,4,0,
362,10,29,1,19,
362,10,29,3,0,
362,10,43,1,1,2
362,10,44,1,1,4
362,10,44,1,10,
362,10,58,1,37,
362,10,58,4,0,
362,10,59,1,51,
362,10,59,4,0,
362,10,63,4,0,
362,10,89,4,0,
362,10,92,4,0,
362,10,104,1,1,3
362,10,104,1,4,
362,10,104,4,0,
362,10,113,4,0,
362,10,148,4,0,
362,10,153,4,0,
362,10,156,4,0,
362,10,162,3,0,
362,10,164,4,0,
362,10,173,3,0,
362,10,180,3,0,
362,10,181,1,1,1
362,10,182,1,22,
362,10,182,4,0,
362,10,196,1,13,
362,10,196,3,0,
362,10,203,4,0,
362,10,205,3,0,
362,10,207,4,0,
362,10,213,4,0,
362,10,214,4,0,
362,10,216,4,0,
362,10,218,4,0,
362,10,219,4,0,
362,10,237,4,0,
362,10,240,4,0,
362,10,242,1,31,
362,10,247,4,0,
362,10,258,1,40,
362,10,258,4,0,
362,10,259,4,0,
362,10,263,4,0,
362,10,269,4,0,
362,10,290,4,0,
362,10,324,3,0,
362,10,329,1,59,
362,10,335,3,0,
362,10,352,4,0,
362,10,360,4,0,
362,10,363,4,0,
362,10,371,4,0,
362,10,399,4,0,
362,10,416,4,0,
362,10,419,4,0,
362,10,423,1,28,
362,10,442,3,0,
362,10,445,4,0,
362,11,29,1,19,
362,11,43,1,1,2
362,11,44,1,1,4
362,11,44,1,10,
362,11,58,1,37,
362,11,58,4,0,
362,11,59,1,51,
362,11,59,4,0,
362,11,63,4,0,
362,11,89,4,0,
362,11,92,4,0,
362,11,104,1,1,3
362,11,104,1,4,
362,11,104,4,0,
362,11,113,4,0,
362,11,148,4,0,
362,11,153,4,0,
362,11,156,4,0,
362,11,164,4,0,
362,11,181,1,1,1
362,11,182,1,22,
362,11,182,4,0,
362,11,196,1,13,
362,11,207,4,0,
362,11,213,4,0,
362,11,216,4,0,
362,11,218,4,0,
362,11,219,4,0,
362,11,237,4,0,
362,11,240,4,0,
362,11,242,1,31,
362,11,247,4,0,
362,11,258,1,40,
362,11,258,4,0,
362,11,259,4,0,
362,11,263,4,0,
362,11,269,4,0,
362,11,329,1,59,
362,11,360,4,0,
362,11,371,4,0,
362,11,416,4,0,
362,11,423,1,28,
362,11,496,4,0,
362,11,523,4,0,
362,11,524,4,0,
362,12,29,1,19,
362,12,43,1,1,2
362,12,44,1,1,4
362,12,44,1,10,
362,12,58,1,34,
362,12,58,4,0,
362,12,59,1,53,
362,12,59,4,0,
362,12,63,4,0,
362,12,89,4,0,
362,12,92,4,0,
362,12,104,1,1,3
362,12,104,1,7,
362,12,104,4,0,
362,12,113,4,0,
362,12,148,4,0,
362,12,156,4,0,
362,12,181,1,1,1
362,12,182,1,25,
362,12,182,4,0,
362,12,196,1,16,
362,12,213,4,0,
362,12,216,4,0,
362,12,218,4,0,
362,12,219,4,0,
362,12,237,4,0,
362,12,240,4,0,
362,12,242,1,28,
362,12,247,4,0,
362,12,258,1,42,
362,12,258,4,0,
362,12,259,4,0,
362,12,263,4,0,
362,12,269,4,0,
362,12,290,4,0,
362,12,329,1,61,
362,12,352,4,0,
362,13,29,1,19,
362,13,34,3,0,
362,13,38,3,0,
362,13,43,1,1,2
362,13,44,1,1,4
362,13,44,1,10,
362,13,58,1,34,
362,13,58,4,0,
362,13,59,1,53,
362,13,59,4,0,
362,13,63,4,0,
362,13,89,4,0,
362,13,92,4,0,
362,13,102,3,0,
362,13,104,1,1,3
362,13,104,1,7,
362,13,104,4,0,
362,13,113,4,0,
362,13,120,3,0,
362,13,148,4,0,
362,13,156,4,0,
362,13,164,3,0,
362,13,181,1,1,1
362,13,182,1,25,
362,13,182,4,0,
362,13,196,1,16,
362,13,196,3,0,
362,13,207,3,0,
362,13,213,4,0,
362,13,216,4,0,
362,13,218,4,0,
362,13,219,4,0,
362,13,237,4,0,
362,13,240,4,0,
362,13,242,1,28,
362,13,247,4,0,
362,13,258,1,42,
362,13,258,4,0,
362,13,259,4,0,
362,13,263,4,0,
362,13,269,4,0,
362,13,290,4,0,
362,13,329,1,61,
362,13,352,4,0,
362,14,29,1,19,
362,14,43,1,1,2
362,14,44,1,1,4
362,14,44,1,10,
362,14,58,1,37,
362,14,58,4,0,
362,14,59,1,51,
362,14,59,4,0,
362,14,63,4,0,
362,14,89,4,0,
362,14,92,4,0,
362,14,104,1,1,3
362,14,104,1,4,
362,14,104,4,0,
362,14,113,4,0,
362,14,148,4,0,
362,14,153,4,0,
362,14,156,4,0,
362,14,162,3,0,
362,14,164,4,0,
362,14,173,3,0,
362,14,180,3,0,
362,14,181,1,1,1
362,14,182,1,22,
362,14,182,4,0,
362,14,196,1,13,
362,14,196,3,0,
362,14,207,4,0,
362,14,213,4,0,
362,14,214,3,0,
362,14,216,4,0,
362,14,218,4,0,
362,14,219,4,0,
362,14,237,4,0,
362,14,240,4,0,
362,14,242,1,31,
362,14,247,4,0,
362,14,258,1,40,
362,14,258,4,0,
362,14,259,4,0,
362,14,263,4,0,
362,14,269,4,0,
362,14,324,3,0,
362,14,329,1,59,
362,14,335,3,0,
362,14,360,4,0,
362,14,371,4,0,
362,14,399,3,0,
362,14,416,4,0,
362,14,423,1,28,
362,14,442,3,0,
362,14,496,4,0,
362,14,523,4,0,
362,14,524,4,0,
362,15,29,1,19,
362,15,43,1,1,3
362,15,44,1,1,5
362,15,44,1,10,
362,15,58,1,37,
362,15,58,4,0,
362,15,59,1,51,
362,15,59,4,0,
362,15,63,4,0,
362,15,89,4,0,
362,15,92,4,0,
362,15,104,1,1,4
362,15,104,1,4,
362,15,104,4,0,
362,15,113,4,0,
362,15,148,4,0,
362,15,153,4,0,
362,15,156,4,0,
362,15,164,4,0,
362,15,181,1,1,2
362,15,182,1,22,
362,15,182,4,0,
362,15,196,1,13,
362,15,207,4,0,
362,15,213,4,0,
362,15,214,4,0,
362,15,216,4,0,
362,15,218,4,0,
362,15,219,4,0,
362,15,237,4,0,
362,15,240,4,0,
362,15,242,1,31,
362,15,247,4,0,
362,15,258,1,40,
362,15,258,4,0,
362,15,259,4,0,
362,15,263,4,0,
362,15,269,4,0,
362,15,329,1,1,1
362,15,329,1,59,
362,15,360,4,0,
362,15,371,4,0,
362,15,399,4,0,
362,15,416,4,0,
362,15,423,1,28,
362,15,496,4,0,
362,15,523,4,0,
362,15,524,4,0,
362,15,590,4,0,
362,16,29,1,28,1
362,16,43,1,1,3
362,16,44,1,19,1
362,16,58,4,0,
362,16,59,1,48,1
362,16,59,4,0,
362,16,63,4,0,
362,16,89,4,0,
362,16,92,4,0,
362,16,104,1,1,4
362,16,104,1,5,1
362,16,104,4,0,
362,16,113,4,0,
362,16,148,4,0,
362,16,153,4,0,
362,16,156,4,0,
362,16,162,3,0,
362,16,164,4,0,
362,16,173,3,0,
362,16,180,3,0,
362,16,181,1,1,2
362,16,182,1,32,1
362,16,182,4,0,
362,16,196,1,14,1
362,16,196,3,0,
362,16,207,4,0,
362,16,213,4,0,
362,16,214,4,0,
362,16,216,4,0,
362,16,218,4,0,
362,16,219,4,0,
362,16,237,4,0,
362,16,240,4,0,
362,16,242,1,41,1
362,16,247,4,0,
362,16,258,1,54,1
362,16,258,4,0,
362,16,259,4,0,
362,16,263,4,0,
362,16,269,4,0,
362,16,290,4,0,
362,16,324,3,0,
362,16,329,1,1,1
362,16,329,1,61,1
362,16,335,3,0,
362,16,352,3,0,
362,16,360,4,0,
362,16,371,4,0,
362,16,399,4,0,
362,16,416,4,0,
362,16,420,1,1,5
362,16,420,1,10,1
362,16,423,1,23,1
362,16,442,3,0,
362,16,496,4,0,
362,16,523,4,0,
362,16,524,1,37,1
362,16,524,4,0,
362,16,573,1,42,1
362,16,590,4,0,
362,17,29,1,28,
362,17,43,1,1,4
362,17,44,1,19,
362,17,58,4,0,
362,17,59,1,48,
362,17,59,4,0,
362,17,63,4,0,
362,17,89,4,0,
362,17,92,4,0,
362,17,104,1,1,5
362,17,104,1,5,
362,17,104,4,0,
362,17,113,4,0,
362,17,153,4,0,
362,17,156,4,0,
362,17,164,4,0,
362,17,181,1,1,3
362,17,182,1,32,
362,17,182,4,0,
362,17,196,1,14,
362,17,207,4,0,
362,17,213,4,0,
362,17,214,4,0,
362,17,216,4,0,
362,17,218,4,0,
362,17,219,4,0,
362,17,237,4,0,
362,17,240,4,0,
362,17,242,1,41,
362,17,247,4,0,
362,17,258,1,54,
362,17,258,4,0,
362,17,259,4,0,
362,17,263,4,0,
362,17,269,4,0,
362,17,329,1,1,2
362,17,329,1,61,
362,17,360,4,0,
362,17,371,4,0,
362,17,399,4,0,
362,17,416,4,0,
362,17,420,1,1,6
362,17,420,1,10,
362,17,423,1,23,
362,17,496,4,0,
362,17,523,4,0,
362,17,524,1,37,
362,17,524,4,0,
362,17,573,1,0,
362,17,573,1,1,1
362,17,590,4,0,
362,18,29,1,28,
362,18,43,1,1,4
362,18,44,1,19,
362,18,58,4,0,
362,18,59,1,48,
362,18,59,4,0,
362,18,63,4,0,
362,18,89,4,0,
362,18,92,4,0,
362,18,104,1,1,5
362,18,104,1,5,
362,18,104,4,0,
362,18,113,4,0,
362,18,153,4,0,
362,18,156,4,0,
362,18,164,4,0,
362,18,181,1,1,3
362,18,182,1,32,
362,18,182,4,0,
362,18,196,1,14,
362,18,207,4,0,
362,18,213,4,0,
362,18,214,4,0,
362,18,216,4,0,
362,18,218,4,0,
362,18,219,4,0,
362,18,237,4,0,
362,18,240,4,0,
362,18,242,1,41,
362,18,247,4,0,
362,18,258,1,54,
362,18,258,4,0,
362,18,259,4,0,
362,18,263,4,0,
362,18,269,4,0,
362,18,329,1,1,2
362,18,329,1,61,
362,18,360,4,0,
362,18,371,4,0,
362,18,399,4,0,
362,18,416,4,0,
362,18,420,1,1,6
362,18,420,1,10,
362,18,423,1,23,
362,18,496,4,0,
362,18,523,4,0,
362,18,524,1,37,
362,18,524,4,0,
362,18,573,1,0,
362,18,573,1,1,1
362,18,590,4,0,
363,5,34,1,19,
363,5,45,1,1,2
363,5,55,1,1,3
363,5,57,4,0,
363,5,58,4,0,
363,5,59,1,43,
363,5,59,4,0,
363,5,62,1,25,
363,5,70,4,0,
363,5,89,4,0,
363,5,90,2,0,
363,5,92,4,0,
363,5,104,4,0,
363,5,127,4,0,
363,5,156,1,37,
363,5,156,4,0,
363,5,157,2,0,
363,5,173,1,37,4
363,5,174,2,0,
363,5,181,1,1,1
363,5,182,4,0,
363,5,213,4,0,
363,5,216,4,0,
363,5,218,4,0,
363,5,227,1,7,
363,5,231,4,0,
363,5,237,4,0,
363,5,240,4,0,
363,5,249,4,0,
363,5,254,2,0,
363,5,255,2,0,
363,5,256,2,0,
363,5,258,1,31,
363,5,258,4,0,
363,5,263,4,0,
363,5,281,2,0,
363,5,290,4,0,
363,5,291,4,0,
363,5,301,1,13,
363,5,317,4,0,
363,5,329,1,49,
363,5,346,2,0,
363,5,352,4,0,
363,6,34,1,19,
363,6,34,3,0,
363,6,38,3,0,
363,6,45,1,1,2
363,6,55,1,1,3
363,6,57,4,0,
363,6,58,4,0,
363,6,59,1,43,
363,6,59,4,0,
363,6,62,1,25,
363,6,70,4,0,
363,6,89,4,0,
363,6,90,2,0,
363,6,92,4,0,
363,6,102,3,0,
363,6,104,4,0,
363,6,111,3,0,
363,6,127,4,0,
363,6,156,1,37,
363,6,156,4,0,
363,6,157,2,0,
363,6,157,3,0,
363,6,164,3,0,
363,6,173,1,37,4
363,6,173,3,0,
363,6,174,2,0,
363,6,181,1,1,1
363,6,182,4,0,
363,6,189,3,0,
363,6,196,3,0,
363,6,203,3,0,
363,6,205,3,0,
363,6,207,3,0,
363,6,213,4,0,
363,6,214,3,0,
363,6,216,4,0,
363,6,218,4,0,
363,6,227,1,7,
363,6,231,4,0,
363,6,237,4,0,
363,6,240,4,0,
363,6,249,4,0,
363,6,254,2,0,
363,6,255,2,0,
363,6,256,2,0,
363,6,258,1,31,
363,6,258,4,0,
363,6,263,4,0,
363,6,281,2,0,
363,6,290,4,0,
363,6,291,4,0,
363,6,301,1,13,
363,6,317,4,0,
363,6,329,1,49,
363,6,346,2,0,
363,6,352,4,0,
363,7,34,1,19,
363,7,34,3,0,
363,7,38,3,0,
363,7,45,1,1,2
363,7,55,1,1,4
363,7,57,4,0,
363,7,58,4,0,
363,7,59,1,43,
363,7,59,4,0,
363,7,62,1,25,
363,7,70,4,0,
363,7,89,4,0,
363,7,90,2,0,
363,7,92,4,0,
363,7,102,3,0,
363,7,104,4,0,
363,7,111,1,1,3
363,7,127,4,0,
363,7,156,1,37,
363,7,156,4,0,
363,7,157,2,0,
363,7,157,3,0,
363,7,164,3,0,
363,7,173,1,37,5
363,7,174,2,0,
363,7,181,1,1,1
363,7,182,4,0,
363,7,213,4,0,
363,7,216,4,0,
363,7,218,4,0,
363,7,227,1,7,
363,7,231,4,0,
363,7,237,4,0,
363,7,240,4,0,
363,7,249,4,0,
363,7,254,2,0,
363,7,255,2,0,
363,7,256,2,0,
363,7,258,1,31,
363,7,258,4,0,
363,7,263,4,0,
363,7,281,2,0,
363,7,290,4,0,
363,7,291,4,0,
363,7,301,1,13,
363,7,317,4,0,
363,7,329,1,49,
363,7,346,2,0,
363,7,352,4,0,
363,8,34,1,19,
363,8,45,1,1,3
363,8,55,1,1,4
363,8,57,4,0,
363,8,58,4,0,
363,8,59,1,43,
363,8,59,4,0,
363,8,62,1,25,
363,8,70,4,0,
363,8,89,4,0,
363,8,90,2,0,
363,8,92,4,0,
363,8,104,4,0,
363,8,111,1,1,1
363,8,127,4,0,
363,8,156,1,37,1
363,8,156,4,0,
363,8,157,2,0,
363,8,157,4,0,
363,8,164,4,0,
363,8,173,1,37,2
363,8,174,2,0,
363,8,181,1,1,2
363,8,182,4,0,
363,8,203,4,0,
363,8,207,4,0,
363,8,213,4,0,
363,8,214,4,0,
363,8,216,4,0,
363,8,218,4,0,
363,8,227,1,7,
363,8,231,4,0,
363,8,237,4,0,
363,8,240,4,0,
363,8,249,4,0,
363,8,254,2,0,
363,8,255,2,0,
363,8,256,2,0,
363,8,258,1,31,
363,8,258,4,0,
363,8,263,4,0,
363,8,281,2,0,
363,8,290,4,0,
363,8,301,1,13,
363,8,317,4,0,
363,8,324,2,0,
363,8,329,1,49,
363,8,346,2,0,
363,8,352,4,0,
363,8,362,4,0,
363,8,363,4,0,
363,8,445,4,0,
363,9,34,1,19,
363,9,45,1,1,3
363,9,55,1,1,4
363,9,57,4,0,
363,9,58,4,0,
363,9,59,1,43,
363,9,59,4,0,
363,9,62,1,25,
363,9,70,4,0,
363,9,89,4,0,
363,9,90,2,0,
363,9,92,4,0,
363,9,104,4,0,
363,9,111,1,1,1
363,9,127,4,0,
363,9,156,1,37,1
363,9,156,4,0,
363,9,157,2,0,
363,9,157,4,0,
363,9,164,4,0,
363,9,173,1,37,2
363,9,173,3,0,
363,9,174,2,0,
363,9,181,1,1,2
363,9,182,4,0,
363,9,189,3,0,
363,9,196,3,0,
363,9,203,4,0,
363,9,205,3,0,
363,9,207,4,0,
363,9,213,4,0,
363,9,214,4,0,
363,9,216,4,0,
363,9,218,4,0,
363,9,227,1,7,
363,9,231,4,0,
363,9,237,4,0,
363,9,240,4,0,
363,9,249,4,0,
363,9,254,2,0,
363,9,255,2,0,
363,9,256,2,0,
363,9,258,1,31,
363,9,258,4,0,
363,9,263,4,0,
363,9,281,2,0,
363,9,290,4,0,
363,9,291,3,0,
363,9,301,1,13,
363,9,317,4,0,
363,9,324,2,0,
363,9,324,3,0,
363,9,329,1,49,
363,9,346,2,0,
363,9,352,4,0,
363,9,362,4,0,
363,9,363,4,0,
363,9,401,3,0,
363,9,445,4,0,
363,10,29,3,0,
363,10,34,1,19,
363,10,45,1,1,3
363,10,55,1,1,4
363,10,57,4,0,
363,10,58,4,0,
363,10,59,1,43,
363,10,59,4,0,
363,10,62,1,25,
363,10,70,4,0,
363,10,89,4,0,
363,10,90,2,0,
363,10,92,4,0,
363,10,104,4,0,
363,10,111,1,1,1
363,10,127,4,0,
363,10,156,1,37,1
363,10,156,4,0,
363,10,157,2,0,
363,10,157,4,0,
363,10,162,3,0,
363,10,164,4,0,
363,10,173,1,37,2
363,10,173,3,0,
363,10,174,2,0,
363,10,181,1,1,2
363,10,182,4,0,
363,10,189,3,0,
363,10,196,3,0,
363,10,203,4,0,
363,10,205,3,0,
363,10,207,4,0,
363,10,213,4,0,
363,10,214,4,0,
363,10,216,4,0,
363,10,218,4,0,
363,10,227,1,7,
363,10,231,4,0,
363,10,237,4,0,
363,10,240,4,0,
363,10,249,4,0,
363,10,250,4,0,
363,10,254,2,0,
363,10,255,2,0,
363,10,256,2,0,
363,10,258,1,31,
363,10,258,4,0,
363,10,263,4,0,
363,10,281,2,0,
363,10,290,4,0,
363,10,291,3,0,
363,10,301,1,13,
363,10,317,4,0,
363,10,324,2,0,
363,10,324,3,0,
363,10,329,1,49,
363,10,346,2,0,
363,10,352,4,0,
363,10,362,4,0,
363,10,363,4,0,
363,10,392,2,0,
363,10,401,3,0,
363,10,445,4,0,
363,11,34,1,19,
363,11,45,1,1,3
363,11,55,1,1,4
363,11,57,4,0,
363,11,58,4,0,
363,11,59,1,43,
363,11,59,4,0,
363,11,62,1,25,
363,11,70,4,0,
363,11,89,4,0,
363,11,90,2,0,
363,11,92,4,0,
363,11,104,4,0,
363,11,111,1,1,1
363,11,127,4,0,
363,11,156,1,37,1
363,11,156,4,0,
363,11,157,4,0,
363,11,164,4,0,
363,11,173,1,37,2
363,11,174,2,0,
363,11,181,1,1,2
363,11,182,4,0,
363,11,205,2,0,
363,11,207,4,0,
363,11,213,4,0,
363,11,214,2,0,
363,11,216,4,0,
363,11,218,4,0,
363,11,227,1,7,
363,11,237,4,0,
363,11,240,4,0,
363,11,249,4,0,
363,11,254,2,0,
363,11,255,2,0,
363,11,256,2,0,
363,11,258,1,31,
363,11,258,4,0,
363,11,263,4,0,
363,11,281,2,0,
363,11,291,4,0,
363,11,301,1,13,
363,11,317,4,0,
363,11,324,2,0,
363,11,329,1,49,
363,11,346,2,0,
363,11,352,2,0,
363,11,392,2,0,
363,11,496,4,0,
363,11,497,4,0,
363,11,523,4,0,
363,11,524,4,0,
363,12,34,1,19,
363,12,45,1,1,2
363,12,55,1,1,3
363,12,57,4,0,
363,12,58,4,0,
363,12,59,1,43,
363,12,59,4,0,
363,12,62,1,25,
363,12,70,4,0,
363,12,89,4,0,
363,12,92,4,0,
363,12,104,4,0,
363,12,127,4,0,
363,12,156,1,37,
363,12,156,4,0,
363,12,173,1,37,4
363,12,181,1,1,1
363,12,182,4,0,
363,12,213,4,0,
363,12,216,4,0,
363,12,218,4,0,
363,12,227,1,7,
363,12,231,4,0,
363,12,237,4,0,
363,12,240,4,0,
363,12,249,4,0,
363,12,258,1,31,
363,12,258,4,0,
363,12,263,4,0,
363,12,290,4,0,
363,12,291,4,0,
363,12,301,1,13,
363,12,317,4,0,
363,12,329,1,49,
363,12,352,4,0,
363,13,34,1,19,
363,13,34,3,0,
363,13,38,3,0,
363,13,45,1,1,2
363,13,55,1,1,3
363,13,57,4,0,
363,13,58,4,0,
363,13,59,1,43,
363,13,59,4,0,
363,13,62,1,25,
363,13,70,4,0,
363,13,89,4,0,
363,13,92,4,0,
363,13,102,3,0,
363,13,104,4,0,
363,13,127,4,0,
363,13,156,1,37,
363,13,156,4,0,
363,13,164,3,0,
363,13,173,1,37,4
363,13,181,1,1,1
363,13,182,4,0,
363,13,196,3,0,
363,13,207,3,0,
363,13,213,4,0,
363,13,216,4,0,
363,13,218,4,0,
363,13,227,1,7,
363,13,231,4,0,
363,13,237,4,0,
363,13,240,4,0,
363,13,249,4,0,
363,13,258,1,31,
363,13,258,4,0,
363,13,263,4,0,
363,13,290,4,0,
363,13,291,4,0,
363,13,301,1,13,
363,13,317,4,0,
363,13,329,1,49,
363,13,352,4,0,
363,14,34,1,19,
363,14,45,1,1,3
363,14,55,1,1,4
363,14,57,4,0,
363,14,58,4,0,
363,14,59,1,43,
363,14,59,4,0,
363,14,62,1,25,
363,14,70,4,0,
363,14,89,4,0,
363,14,90,2,0,
363,14,92,4,0,
363,14,104,4,0,
363,14,111,1,1,1
363,14,127,4,0,
363,14,156,1,37,1
363,14,156,4,0,
363,14,157,4,0,
363,14,162,3,0,
363,14,164,4,0,
363,14,173,1,37,2
363,14,173,3,0,
363,14,174,2,0,
363,14,181,1,1,2
363,14,182,4,0,
363,14,196,3,0,
363,14,205,2,0,
363,14,207,4,0,
363,14,213,4,0,
363,14,214,2,0,
363,14,214,3,0,
363,14,216,4,0,
363,14,218,4,0,
363,14,227,1,7,
363,14,231,3,0,
363,14,237,4,0,
363,14,240,4,0,
363,14,249,4,0,
363,14,254,2,0,
363,14,255,2,0,
363,14,256,2,0,
363,14,258,1,31,
363,14,258,4,0,
363,14,263,4,0,
363,14,281,2,0,
363,14,291,4,0,
363,14,301,1,13,
363,14,317,4,0,
363,14,324,2,0,
363,14,324,3,0,
363,14,329,1,49,
363,14,346,2,0,
363,14,352,2,0,
363,14,392,2,0,
363,14,401,3,0,
363,14,496,4,0,
363,14,497,4,0,
363,14,523,4,0,
363,14,524,4,0,
363,15,34,1,19,
363,15,45,1,1,3
363,15,55,1,1,4
363,15,57,4,0,
363,15,58,4,0,
363,15,59,1,43,
363,15,59,4,0,
363,15,62,1,25,
363,15,70,4,0,
363,15,89,4,0,
363,15,90,2,0,
363,15,92,4,0,
363,15,104,4,0,
363,15,111,1,1,1
363,15,127,4,0,
363,15,156,1,37,1
363,15,156,4,0,
363,15,157,4,0,
363,15,164,4,0,
363,15,173,1,37,2
363,15,174,2,0,
363,15,181,1,1,2
363,15,182,4,0,
363,15,187,2,0,
363,15,205,2,0,
363,15,207,4,0,
363,15,213,4,0,
363,15,214,2,0,
363,15,214,4,0,
363,15,216,4,0,
363,15,218,4,0,
363,15,227,1,7,
363,15,237,4,0,
363,15,240,4,0,
363,15,249,4,0,
363,15,254,2,0,
363,15,255,2,0,
363,15,256,2,0,
363,15,258,1,31,
363,15,258,4,0,
363,15,263,4,0,
363,15,281,2,0,
363,15,301,1,13,
363,15,317,4,0,
363,15,324,2,0,
363,15,329,1,49,
363,15,346,2,0,
363,15,352,2,0,
363,15,392,2,0,
363,15,496,4,0,
363,15,497,4,0,
363,15,523,4,0,
363,15,524,4,0,
363,15,590,4,0,
363,16,34,1,26,1
363,16,45,1,1,3
363,16,55,1,1,4
363,16,57,4,0,
363,16,58,4,0,
363,16,59,1,41,1
363,16,59,4,0,
363,16,62,1,21,1
363,16,70,4,0,
363,16,89,4,0,
363,16,90,2,0,
363,16,92,4,0,
363,16,104,4,0,
363,16,111,1,1,1
363,16,127,4,0,
363,16,156,1,31,1
363,16,156,4,0,
363,16,157,4,0,
363,16,162,3,0,
363,16,164,4,0,
363,16,173,1,31,2
363,16,173,3,0,
363,16,174,2,0,
363,16,181,1,1,2
363,16,182,4,0,
363,16,187,2,0,
363,16,196,3,0,
363,16,205,1,5,1
363,16,205,2,0,
363,16,207,4,0,
363,16,213,4,0,
363,16,214,2,0,
363,16,214,4,0,
363,16,216,4,0,
363,16,218,4,0,
363,16,227,1,9,1
363,16,231,3,0,
363,16,237,4,0,
363,16,240,4,0,
363,16,249,4,0,
363,16,254,2,0,
363,16,255,2,0,
363,16,256,2,0,
363,16,258,1,36,1
363,16,258,4,0,
363,16,263,4,0,
363,16,281,2,0,
363,16,290,4,0,
363,16,291,4,0,
363,16,301,1,13,1
363,16,317,4,0,
363,16,324,2,0,
363,16,324,3,0,
363,16,329,1,46,1
363,16,346,2,0,
363,16,352,2,0,
363,16,352,3,0,
363,16,362,1,17,1
363,16,392,2,0,
363,16,401,3,0,
363,16,496,4,0,
363,16,497,4,0,
363,16,523,4,0,
363,16,524,4,0,
363,16,590,4,0,
363,17,34,1,26,
363,17,45,1,1,3
363,17,55,1,1,4
363,17,57,4,0,
363,17,58,4,0,
363,17,59,1,41,
363,17,59,4,0,
363,17,62,1,21,
363,17,89,4,0,
363,17,90,2,0,
363,17,92,4,0,
363,17,104,4,0,
363,17,111,1,1,1
363,17,127,4,0,
363,17,156,1,31,1
363,17,156,4,0,
363,17,157,4,0,
363,17,164,4,0,
363,17,173,1,31,2
363,17,174,2,0,
363,17,181,1,1,2
363,17,182,4,0,
363,17,187,2,0,
363,17,205,1,5,
363,17,205,2,0,
363,17,207,4,0,
363,17,213,4,0,
363,17,214,2,0,
363,17,214,4,0,
363,17,216,4,0,
363,17,218,4,0,
363,17,227,1,9,
363,17,237,4,0,
363,17,240,4,0,
363,17,254,2,0,
363,17,255,2,0,
363,17,256,2,0,
363,17,258,1,36,
363,17,258,4,0,
363,17,263,4,0,
363,17,281,2,0,
363,17,301,1,13,
363,17,317,4,0,
363,17,324,2,0,
363,17,329,1,46,
363,17,346,2,0,
363,17,352,2,0,
363,17,362,1,17,
363,17,392,2,0,
363,17,496,4,0,
363,17,497,4,0,
363,17,523,4,0,
363,17,524,4,0,
363,17,590,4,0,
363,18,34,1,26,
363,18,45,1,1,3
363,18,55,1,1,4
363,18,57,4,0,
363,18,58,4,0,
363,18,59,1,41,
363,18,59,4,0,
363,18,62,1,21,
363,18,89,4,0,
363,18,90,2,0,
363,18,92,4,0,
363,18,104,4,0,
363,18,111,1,1,1
363,18,127,4,0,
363,18,156,1,31,1
363,18,156,4,0,
363,18,157,4,0,
363,18,164,4,0,
363,18,173,1,31,2
363,18,174,2,0,
363,18,181,1,1,2
363,18,182,4,0,
363,18,187,2,0,
363,18,205,1,5,
363,18,205,2,0,
363,18,207,4,0,
363,18,213,4,0,
363,18,214,2,0,
363,18,214,4,0,
363,18,216,4,0,
363,18,218,4,0,
363,18,227,1,9,
363,18,237,4,0,
363,18,240,4,0,
363,18,254,2,0,
363,18,255,2,0,
363,18,256,2,0,
363,18,258,1,36,
363,18,258,4,0,
363,18,263,4,0,
363,18,281,2,0,
363,18,301,1,13,
363,18,317,4,0,
363,18,324,2,0,
363,18,329,1,46,
363,18,346,2,0,
363,18,352,2,0,
363,18,362,1,17,
363,18,392,2,0,
363,18,496,4,0,
363,18,497,4,0,
363,18,523,4,0,
363,18,524,4,0,
363,18,590,4,0,
364,5,34,1,19,
364,5,45,1,1,2
364,5,46,4,0,
364,5,55,1,1,3
364,5,57,4,0,
364,5,58,4,0,
364,5,59,1,47,
364,5,59,4,0,
364,5,62,1,25,
364,5,70,4,0,
364,5,89,4,0,
364,5,92,4,0,
364,5,104,4,0,
364,5,127,4,0,
364,5,156,1,39,
364,5,156,4,0,
364,5,173,1,39,5
364,5,181,1,1,1
364,5,182,4,0,
364,5,213,4,0,
364,5,216,4,0,
364,5,218,4,0,
364,5,227,1,1,4
364,5,227,1,7,
364,5,231,4,0,
364,5,237,4,0,
364,5,240,4,0,
364,5,249,4,0,
364,5,258,1,31,
364,5,258,4,0,
364,5,263,4,0,
364,5,290,4,0,
364,5,291,4,0,
364,5,301,1,13,
364,5,317,4,0,
364,5,329,1,55,
364,5,352,4,0,
364,6,34,1,19,
364,6,34,3,0,
364,6,38,3,0,
364,6,45,1,1,2
364,6,46,4,0,
364,6,55,1,1,3
364,6,57,4,0,
364,6,58,4,0,
364,6,59,1,47,
364,6,59,4,0,
364,6,62,1,25,
364,6,70,4,0,
364,6,89,4,0,
364,6,92,4,0,
364,6,102,3,0,
364,6,104,4,0,
364,6,111,3,0,
364,6,127,4,0,
364,6,156,1,39,
364,6,156,4,0,
364,6,157,3,0,
364,6,164,3,0,
364,6,173,1,39,5
364,6,173,3,0,
364,6,181,1,1,1
364,6,182,4,0,
364,6,189,3,0,
364,6,196,3,0,
364,6,203,3,0,
364,6,205,3,0,
364,6,207,3,0,
364,6,213,4,0,
364,6,214,3,0,
364,6,216,4,0,
364,6,218,4,0,
364,6,227,1,1,4
364,6,227,1,7,
364,6,231,4,0,
364,6,237,4,0,
364,6,240,4,0,
364,6,249,4,0,
364,6,258,1,31,
364,6,258,4,0,
364,6,263,4,0,
364,6,290,4,0,
364,6,291,4,0,
364,6,301,1,13,
364,6,317,4,0,
364,6,329,1,55,
364,6,352,4,0,
364,7,34,1,19,
364,7,34,3,0,
364,7,38,3,0,
364,7,45,1,1,2
364,7,46,4,0,
364,7,55,1,1,4
364,7,57,4,0,
364,7,58,4,0,
364,7,59,1,47,
364,7,59,4,0,
364,7,62,1,25,
364,7,70,4,0,
364,7,89,4,0,
364,7,92,4,0,
364,7,102,3,0,
364,7,104,4,0,
364,7,111,1,1,3
364,7,127,4,0,
364,7,156,1,39,
364,7,156,4,0,
364,7,157,3,0,
364,7,164,3,0,
364,7,173,1,39,5
364,7,181,1,1,1
364,7,182,4,0,
364,7,213,4,0,
364,7,216,4,0,
364,7,218,4,0,
364,7,227,1,7,
364,7,231,4,0,
364,7,237,4,0,
364,7,240,4,0,
364,7,249,4,0,
364,7,258,1,31,
364,7,258,4,0,
364,7,263,4,0,
364,7,290,4,0,
364,7,291,4,0,
364,7,301,1,13,
364,7,317,4,0,
364,7,329,1,55,
364,7,352,4,0,
364,8,34,1,19,
364,8,45,1,1,2
364,8,46,4,0,
364,8,55,1,1,3
364,8,57,4,0,
364,8,58,4,0,
364,8,59,1,47,
364,8,59,4,0,
364,8,62,1,25,
364,8,70,4,0,
364,8,89,4,0,
364,8,92,4,0,
364,8,104,4,0,
364,8,127,4,0,
364,8,156,1,39,1
364,8,156,4,0,
364,8,157,4,0,
364,8,164,4,0,
364,8,173,1,39,2
364,8,181,1,1,1
364,8,182,4,0,
364,8,203,4,0,
364,8,207,1,32,
364,8,207,4,0,
364,8,213,4,0,
364,8,214,4,0,
364,8,216,4,0,
364,8,218,4,0,
364,8,227,1,1,4
364,8,227,1,7,
364,8,231,4,0,
364,8,237,4,0,
364,8,240,4,0,
364,8,249,4,0,
364,8,258,1,31,
364,8,258,4,0,
364,8,263,4,0,
364,8,290,4,0,
364,8,301,1,13,
364,8,317,4,0,
364,8,329,1,55,
364,8,352,4,0,
364,8,362,4,0,
364,8,363,4,0,
364,8,445,4,0,
364,9,34,1,19,
364,9,45,1,1,2
364,9,46,4,0,
364,9,55,1,1,3
364,9,57,4,0,
364,9,58,4,0,
364,9,59,1,47,
364,9,59,4,0,
364,9,62,1,25,
364,9,70,4,0,
364,9,89,4,0,
364,9,92,4,0,
364,9,104,4,0,
364,9,127,4,0,
364,9,156,1,39,1
364,9,156,4,0,
364,9,157,4,0,
364,9,164,4,0,
364,9,173,1,39,2
364,9,173,3,0,
364,9,181,1,1,1
364,9,182,4,0,
364,9,189,3,0,
364,9,196,3,0,
364,9,203,4,0,
364,9,205,3,0,
364,9,207,1,32,
364,9,207,4,0,
364,9,213,4,0,
364,9,214,4,0,
364,9,216,4,0,
364,9,218,4,0,
364,9,227,1,1,4
364,9,227,1,7,
364,9,231,4,0,
364,9,237,4,0,
364,9,240,4,0,
364,9,249,4,0,
364,9,258,1,31,
364,9,258,4,0,
364,9,263,4,0,
364,9,290,4,0,
364,9,291,3,0,
364,9,301,1,13,
364,9,317,4,0,
364,9,324,3,0,
364,9,329,1,55,
364,9,352,4,0,
364,9,362,4,0,
364,9,363,4,0,
364,9,401,3,0,
364,9,445,4,0,
364,10,29,3,0,
364,10,34,1,19,
364,10,45,1,1,2
364,10,46,4,0,
364,10,55,1,1,3
364,10,57,4,0,
364,10,58,4,0,
364,10,59,1,47,
364,10,59,4,0,
364,10,62,1,25,
364,10,70,4,0,
364,10,89,4,0,
364,10,92,4,0,
364,10,104,4,0,
364,10,127,4,0,
364,10,156,1,39,1
364,10,156,4,0,
364,10,157,4,0,
364,10,162,3,0,
364,10,164,4,0,
364,10,173,1,39,2
364,10,173,3,0,
364,10,181,1,1,1
364,10,182,4,0,
364,10,189,3,0,
364,10,196,3,0,
364,10,203,4,0,
364,10,205,3,0,
364,10,207,1,32,
364,10,207,4,0,
364,10,213,4,0,
364,10,214,4,0,
364,10,216,4,0,
364,10,218,4,0,
364,10,227,1,1,4
364,10,227,1,7,
364,10,231,4,0,
364,10,237,4,0,
364,10,240,4,0,
364,10,249,4,0,
364,10,250,4,0,
364,10,258,1,31,
364,10,258,4,0,
364,10,263,4,0,
364,10,290,4,0,
364,10,291,3,0,
364,10,301,1,13,
364,10,317,4,0,
364,10,324,3,0,
364,10,329,1,55,
364,10,352,4,0,
364,10,362,4,0,
364,10,363,4,0,
364,10,401,3,0,
364,10,445,4,0,
364,11,34,1,19,
364,11,45,1,1,2
364,11,46,4,0,
364,11,55,1,1,3
364,11,57,4,0,
364,11,58,4,0,
364,11,59,1,47,
364,11,59,4,0,
364,11,62,1,25,
364,11,70,4,0,
364,11,89,4,0,
364,11,92,4,0,
364,11,104,4,0,
364,11,127,4,0,
364,11,156,1,39,1
364,11,156,4,0,
364,11,157,4,0,
364,11,164,4,0,
364,11,173,1,39,2
364,11,181,1,1,1
364,11,182,4,0,
364,11,207,1,32,
364,11,207,4,0,
364,11,213,4,0,
364,11,216,4,0,
364,11,218,4,0,
364,11,227,1,1,4
364,11,227,1,7,
364,11,237,4,0,
364,11,240,4,0,
364,11,249,4,0,
364,11,258,1,31,
364,11,258,4,0,
364,11,263,4,0,
364,11,291,4,0,
364,11,301,1,13,
364,11,317,4,0,
364,11,329,1,55,
364,11,496,4,0,
364,11,497,4,0,
364,11,523,4,0,
364,11,524,4,0,
364,12,34,1,19,
364,12,45,1,1,2
364,12,46,4,0,
364,12,55,1,1,3
364,12,57,4,0,
364,12,58,4,0,
364,12,59,1,47,
364,12,59,4,0,
364,12,62,1,25,
364,12,70,4,0,
364,12,89,4,0,
364,12,92,4,0,
364,12,104,4,0,
364,12,127,4,0,
364,12,156,1,39,
364,12,156,4,0,
364,12,173,1,39,5
364,12,181,1,1,1
364,12,182,4,0,
364,12,213,4,0,
364,12,216,4,0,
364,12,218,4,0,
364,12,227,1,1,4
364,12,227,1,7,
364,12,231,4,0,
364,12,237,4,0,
364,12,240,4,0,
364,12,249,4,0,
364,12,258,1,31,
364,12,258,4,0,
364,12,263,4,0,
364,12,290,4,0,
364,12,291,4,0,
364,12,301,1,13,
364,12,317,4,0,
364,12,329,1,55,
364,12,352,4,0,
364,13,34,1,19,
364,13,34,3,0,
364,13,38,3,0,
364,13,45,1,1,2
364,13,46,4,0,
364,13,55,1,1,3
364,13,57,4,0,
364,13,58,4,0,
364,13,59,1,47,
364,13,59,4,0,
364,13,62,1,25,
364,13,70,4,0,
364,13,89,4,0,
364,13,92,4,0,
364,13,102,3,0,
364,13,104,4,0,
364,13,127,4,0,
364,13,156,1,39,
364,13,156,4,0,
364,13,164,3,0,
364,13,173,1,39,5
364,13,181,1,1,1
364,13,182,4,0,
364,13,196,3,0,
364,13,207,3,0,
364,13,213,4,0,
364,13,216,4,0,
364,13,218,4,0,
364,13,227,1,1,4
364,13,227,1,7,
364,13,231,4,0,
364,13,237,4,0,
364,13,240,4,0,
364,13,249,4,0,
364,13,258,1,31,
364,13,258,4,0,
364,13,263,4,0,
364,13,290,4,0,
364,13,291,4,0,
364,13,301,1,13,
364,13,317,4,0,
364,13,329,1,55,
364,13,352,4,0,
364,14,34,1,19,
364,14,45,1,1,2
364,14,46,4,0,
364,14,55,1,1,3
364,14,57,4,0,
364,14,58,4,0,
364,14,59,1,47,
364,14,59,4,0,
364,14,62,1,25,
364,14,70,4,0,
364,14,89,4,0,
364,14,92,4,0,
364,14,104,4,0,
364,14,127,4,0,
364,14,156,1,39,1
364,14,156,4,0,
364,14,157,4,0,
364,14,162,3,0,
364,14,164,4,0,
364,14,173,1,39,2
364,14,173,3,0,
364,14,181,1,1,1
364,14,182,4,0,
364,14,196,3,0,
364,14,207,1,32,
364,14,207,4,0,
364,14,213,4,0,
364,14,214,3,0,
364,14,216,4,0,
364,14,218,4,0,
364,14,227,1,1,4
364,14,227,1,7,
364,14,231,3,0,
364,14,237,4,0,
364,14,240,4,0,
364,14,249,4,0,
364,14,258,1,31,
364,14,258,4,0,
364,14,263,4,0,
364,14,291,4,0,
364,14,301,1,13,
364,14,317,4,0,
364,14,324,3,0,
364,14,329,1,55,
364,14,401,3,0,
364,14,496,4,0,
364,14,497,4,0,
364,14,523,4,0,
364,14,524,4,0,
364,15,34,1,19,
364,15,45,1,1,2
364,15,46,4,0,
364,15,55,1,1,3
364,15,57,4,0,
364,15,58,4,0,
364,15,59,1,47,
364,15,59,4,0,
364,15,62,1,25,
364,15,70,4,0,
364,15,89,4,0,
364,15,92,4,0,
364,15,104,4,0,
364,15,127,4,0,
364,15,156,1,39,1
364,15,156,4,0,
364,15,157,4,0,
364,15,164,4,0,
364,15,173,1,39,2
364,15,181,1,1,1
364,15,182,4,0,
364,15,207,1,32,
364,15,207,4,0,
364,15,213,4,0,
364,15,214,4,0,
364,15,216,4,0,
364,15,218,4,0,
364,15,227,1,1,4
364,15,227,1,7,
364,15,237,4,0,
364,15,240,4,0,
364,15,249,4,0,
364,15,258,1,31,
364,15,258,4,0,
364,15,263,4,0,
364,15,301,1,13,
364,15,317,4,0,
364,15,329,1,55,
364,15,496,4,0,
364,15,497,4,0,
364,15,523,4,0,
364,15,524,4,0,
364,15,590,4,0,
364,16,34,1,26,1
364,16,45,1,1,3
364,16,46,4,0,
364,16,55,1,1,4
364,16,57,4,0,
364,16,58,4,0,
364,16,59,1,45,1
364,16,59,4,0,
364,16,62,1,21,1
364,16,70,4,0,
364,16,89,4,0,
364,16,92,4,0,
364,16,104,4,0,
364,16,111,1,1,1
364,16,127,4,0,
364,16,156,1,31,1
364,16,156,4,0,
364,16,157,4,0,
364,16,162,3,0,
364,16,164,4,0,
364,16,173,1,31,2
364,16,173,3,0,
364,16,181,1,1,2
364,16,182,4,0,
364,16,196,3,0,
364,16,205,1,5,1
364,16,207,1,32,1
364,16,207,4,0,
364,16,213,4,0,
364,16,214,4,0,
364,16,216,4,0,
364,16,218,4,0,
364,16,227,1,9,1
364,16,231,3,0,
364,16,237,4,0,
364,16,240,4,0,
364,16,249,4,0,
364,16,258,1,38,1
364,16,258,4,0,
364,16,263,4,0,
364,16,290,4,0,
364,16,291,4,0,
364,16,301,1,13,1
364,16,317,4,0,
364,16,324,3,0,
364,16,329,1,52,1
364,16,352,3,0,
364,16,362,1,17,1
364,16,401,3,0,
364,16,496,4,0,
364,16,497,4,0,
364,16,523,4,0,
364,16,524,4,0,
364,16,590,4,0,
364,17,34,1,26,
364,17,45,1,1,4
364,17,46,4,0,
364,17,55,1,1,5
364,17,57,4,0,
364,17,58,4,0,
364,17,59,1,45,
364,17,59,4,0,
364,17,62,1,21,
364,17,89,4,0,
364,17,92,4,0,
364,17,104,4,0,
364,17,111,1,1,2
364,17,127,4,0,
364,17,156,1,31,1
364,17,156,4,0,
364,17,157,4,0,
364,17,164,4,0,
364,17,173,1,31,2
364,17,181,1,1,3
364,17,182,4,0,
364,17,205,1,5,
364,17,207,1,0,
364,17,207,1,1,1
364,17,207,4,0,
364,17,213,4,0,
364,17,214,4,0,
364,17,216,4,0,
364,17,218,4,0,
364,17,227,1,9,
364,17,237,4,0,
364,17,240,4,0,
364,17,258,1,38,
364,17,258,4,0,
364,17,263,4,0,
364,17,301,1,13,
364,17,317,4,0,
364,17,329,1,52,
364,17,362,1,17,
364,17,496,4,0,
364,17,497,4,0,
364,17,523,4,0,
364,17,524,4,0,
364,17,590,4,0,
364,18,34,1,26,
364,18,45,1,1,4
364,18,46,4,0,
364,18,55,1,1,5
364,18,57,4,0,
364,18,58,4,0,
364,18,59,1,45,
364,18,59,4,0,
364,18,62,1,21,
364,18,89,4,0,
364,18,92,4,0,
364,18,104,4,0,
364,18,111,1,1,2
364,18,127,4,0,
364,18,156,1,31,1
364,18,156,4,0,
364,18,157,4,0,
364,18,164,4,0,
364,18,173,1,31,2
364,18,181,1,1,3
364,18,182,4,0,
364,18,205,1,5,
364,18,207,1,0,
364,18,207,1,1,1
364,18,207,4,0,
364,18,213,4,0,
364,18,214,4,0,
364,18,216,4,0,
364,18,218,4,0,
364,18,227,1,9,
364,18,237,4,0,
364,18,240,4,0,
364,18,258,1,38,
364,18,258,4,0,
364,18,263,4,0,
364,18,301,1,13,
364,18,317,4,0,
364,18,329,1,52,
364,18,362,1,17,
364,18,496,4,0,
364,18,497,4,0,
364,18,523,4,0,
364,18,524,4,0,
364,18,590,4,0,
365,5,34,1,19,
365,5,45,1,1,2
365,5,46,4,0,
365,5,55,1,1,3
365,5,57,4,0,
365,5,58,4,0,
365,5,59,1,50,
365,5,59,4,0,
365,5,62,1,25,
365,5,63,4,0,
365,5,70,4,0,
365,5,89,4,0,
365,5,92,4,0,
365,5,104,4,0,
365,5,127,4,0,
365,5,156,1,39,
365,5,156,4,0,
365,5,173,1,39,5
365,5,181,1,1,1
365,5,182,4,0,
365,5,213,4,0,
365,5,216,4,0,
365,5,218,4,0,
365,5,227,1,1,4
365,5,227,1,7,
365,5,231,4,0,
365,5,237,4,0,
365,5,240,4,0,
365,5,249,4,0,
365,5,258,1,31,
365,5,258,4,0,
365,5,263,4,0,
365,5,290,4,0,
365,5,291,4,0,
365,5,301,1,13,
365,5,317,4,0,
365,5,329,1,61,
365,5,352,4,0,
365,6,34,1,19,
365,6,34,3,0,
365,6,38,3,0,
365,6,45,1,1,2
365,6,46,4,0,
365,6,55,1,1,3
365,6,57,4,0,
365,6,58,4,0,
365,6,59,1,50,
365,6,59,4,0,
365,6,62,1,25,
365,6,63,4,0,
365,6,70,4,0,
365,6,89,4,0,
365,6,92,4,0,
365,6,102,3,0,
365,6,104,4,0,
365,6,111,3,0,
365,6,127,4,0,
365,6,156,1,39,
365,6,156,4,0,
365,6,157,3,0,
365,6,164,3,0,
365,6,173,1,39,5
365,6,173,3,0,
365,6,181,1,1,1
365,6,182,4,0,
365,6,189,3,0,
365,6,196,3,0,
365,6,203,3,0,
365,6,205,3,0,
365,6,207,3,0,
365,6,213,4,0,
365,6,214,3,0,
365,6,216,4,0,
365,6,218,4,0,
365,6,227,1,1,4
365,6,227,1,7,
365,6,231,4,0,
365,6,237,4,0,
365,6,240,4,0,
365,6,249,4,0,
365,6,258,1,31,
365,6,258,4,0,
365,6,263,4,0,
365,6,290,4,0,
365,6,291,4,0,
365,6,301,1,13,
365,6,317,4,0,
365,6,329,1,61,
365,6,352,4,0,
365,7,34,1,19,
365,7,34,3,0,
365,7,38,3,0,
365,7,45,1,1,2
365,7,46,4,0,
365,7,55,1,1,4
365,7,57,4,0,
365,7,58,4,0,
365,7,59,1,50,
365,7,59,4,0,
365,7,62,1,25,
365,7,63,4,0,
365,7,70,4,0,
365,7,89,4,0,
365,7,92,4,0,
365,7,102,3,0,
365,7,104,4,0,
365,7,111,1,1,3
365,7,127,4,0,
365,7,156,1,39,
365,7,156,4,0,
365,7,157,3,0,
365,7,164,3,0,
365,7,173,1,39,5
365,7,181,1,1,1
365,7,182,4,0,
365,7,213,4,0,
365,7,216,4,0,
365,7,218,4,0,
365,7,227,1,7,
365,7,231,4,0,
365,7,237,4,0,
365,7,240,4,0,
365,7,249,4,0,
365,7,258,1,31,
365,7,258,4,0,
365,7,263,4,0,
365,7,290,4,0,
365,7,291,4,0,
365,7,301,1,13,
365,7,317,4,0,
365,7,329,1,61,
365,7,352,4,0,
365,8,34,1,19,
365,8,45,1,1,3
365,8,46,4,0,
365,8,55,1,1,4
365,8,57,4,0,
365,8,58,4,0,
365,8,59,1,52,
365,8,59,4,0,
365,8,62,1,25,
365,8,63,4,0,
365,8,70,4,0,
365,8,89,4,0,
365,8,92,4,0,
365,8,104,4,0,
365,8,127,4,0,
365,8,156,1,39,1
365,8,156,4,0,
365,8,157,4,0,
365,8,164,4,0,
365,8,173,1,39,2
365,8,181,1,1,2
365,8,182,4,0,
365,8,203,4,0,
365,8,207,1,32,
365,8,207,4,0,
365,8,213,4,0,
365,8,214,4,0,
365,8,216,4,0,
365,8,218,4,0,
365,8,227,1,1,5
365,8,227,1,7,
365,8,231,4,0,
365,8,237,4,0,
365,8,240,4,0,
365,8,242,1,1,1
365,8,249,4,0,
365,8,258,1,31,
365,8,258,4,0,
365,8,263,4,0,
365,8,290,4,0,
365,8,301,1,13,
365,8,317,4,0,
365,8,329,1,65,
365,8,352,4,0,
365,8,362,4,0,
365,8,363,4,0,
365,8,416,4,0,
365,8,419,4,0,
365,8,423,1,44,
365,8,445,4,0,
365,9,34,1,19,
365,9,45,1,1,3
365,9,46,4,0,
365,9,55,1,1,4
365,9,57,4,0,
365,9,58,4,0,
365,9,59,1,52,
365,9,59,4,0,
365,9,62,1,25,
365,9,63,4,0,
365,9,70,4,0,
365,9,89,4,0,
365,9,92,4,0,
365,9,104,4,0,
365,9,127,4,0,
365,9,156,1,39,1
365,9,156,4,0,
365,9,157,4,0,
365,9,164,4,0,
365,9,173,1,39,2
365,9,173,3,0,
365,9,181,1,1,2
365,9,182,4,0,
365,9,189,3,0,
365,9,196,3,0,
365,9,203,4,0,
365,9,205,3,0,
365,9,207,1,32,
365,9,207,4,0,
365,9,210,3,0,
365,9,213,4,0,
365,9,214,4,0,
365,9,216,4,0,
365,9,218,4,0,
365,9,227,1,1,5
365,9,227,1,7,
365,9,231,4,0,
365,9,237,4,0,
365,9,240,4,0,
365,9,242,1,1,1
365,9,249,4,0,
365,9,258,1,31,
365,9,258,4,0,
365,9,263,4,0,
365,9,290,4,0,
365,9,291,3,0,
365,9,301,1,13,
365,9,317,4,0,
365,9,324,3,0,
365,9,329,1,65,
365,9,352,4,0,
365,9,362,4,0,
365,9,363,4,0,
365,9,401,3,0,
365,9,416,4,0,
365,9,419,4,0,
365,9,423,1,44,
365,9,442,3,0,
365,9,445,4,0,
365,10,29,3,0,
365,10,34,1,19,
365,10,45,1,1,3
365,10,46,4,0,
365,10,55,1,1,4
365,10,57,4,0,
365,10,58,4,0,
365,10,59,1,52,
365,10,59,4,0,
365,10,62,1,25,
365,10,63,4,0,
365,10,70,4,0,
365,10,89,4,0,
365,10,92,4,0,
365,10,104,4,0,
365,10,127,4,0,
365,10,156,1,39,1
365,10,156,4,0,
365,10,157,4,0,
365,10,162,3,0,
365,10,164,4,0,
365,10,173,1,39,2
365,10,173,3,0,
365,10,181,1,1,2
365,10,182,4,0,
365,10,189,3,0,
365,10,196,3,0,
365,10,203,4,0,
365,10,205,3,0,
365,10,207,1,32,
365,10,207,4,0,
365,10,210,3,0,
365,10,213,4,0,
365,10,214,4,0,
365,10,216,4,0,
365,10,218,4,0,
365,10,227,1,1,5
365,10,227,1,7,
365,10,231,4,0,
365,10,237,4,0,
365,10,240,4,0,
365,10,242,1,1,1
365,10,249,4,0,
365,10,250,4,0,
365,10,258,1,31,
365,10,258,4,0,
365,10,263,4,0,
365,10,290,4,0,
365,10,291,3,0,
365,10,301,1,13,
365,10,317,4,0,
365,10,324,3,0,
365,10,329,1,65,
365,10,335,3,0,
365,10,352,4,0,
365,10,362,4,0,
365,10,363,4,0,
365,10,401,3,0,
365,10,416,4,0,
365,10,419,4,0,
365,10,423,1,44,
365,10,442,3,0,
365,10,445,4,0,
365,11,34,1,19,
365,11,45,1,1,3
365,11,46,4,0,
365,11,55,1,1,4
365,11,57,4,0,
365,11,58,4,0,
365,11,59,1,52,
365,11,59,4,0,
365,11,62,1,25,
365,11,63,4,0,
365,11,70,4,0,
365,11,89,4,0,
365,11,92,4,0,
365,11,104,4,0,
365,11,127,4,0,
365,11,156,1,39,1
365,11,156,4,0,
365,11,157,4,0,
365,11,164,4,0,
365,11,173,1,39,2
365,11,181,1,1,2
365,11,182,4,0,
365,11,207,1,32,
365,11,207,4,0,
365,11,213,4,0,
365,11,216,4,0,
365,11,218,4,0,
365,11,227,1,1,5
365,11,227,1,7,
365,11,237,4,0,
365,11,240,4,0,
365,11,242,1,1,1
365,11,249,4,0,
365,11,258,1,31,
365,11,258,4,0,
365,11,263,4,0,
365,11,291,4,0,
365,11,301,1,13,
365,11,317,4,0,
365,11,329,1,65,
365,11,416,4,0,
365,11,423,1,44,
365,11,496,4,0,
365,11,497,4,0,
365,11,523,4,0,
365,11,524,4,0,
365,12,34,1,19,
365,12,45,1,1,2
365,12,46,4,0,
365,12,55,1,1,3
365,12,57,4,0,
365,12,58,4,0,
365,12,59,1,50,
365,12,59,4,0,
365,12,62,1,25,
365,12,63,4,0,
365,12,70,4,0,
365,12,89,4,0,
365,12,92,4,0,
365,12,104,4,0,
365,12,127,4,0,
365,12,156,1,39,
365,12,156,4,0,
365,12,173,1,39,5
365,12,181,1,1,1
365,12,182,4,0,
365,12,213,4,0,
365,12,216,4,0,
365,12,218,4,0,
365,12,227,1,1,4
365,12,227,1,7,
365,12,231,4,0,
365,12,237,4,0,
365,12,240,4,0,
365,12,249,4,0,
365,12,258,1,31,
365,12,258,4,0,
365,12,263,4,0,
365,12,290,4,0,
365,12,291,4,0,
365,12,301,1,13,
365,12,317,4,0,
365,12,329,1,61,
365,12,352,4,0,
365,13,34,1,19,
365,13,34,3,0,
365,13,38,3,0,
365,13,45,1,1,2
365,13,46,4,0,
365,13,55,1,1,3
365,13,57,4,0,
365,13,58,4,0,
365,13,59,1,50,
365,13,59,4,0,
365,13,62,1,25,
365,13,63,4,0,
365,13,70,4,0,
365,13,89,4,0,
365,13,92,4,0,
365,13,102,3,0,
365,13,104,4,0,
365,13,127,4,0,
365,13,156,1,39,
365,13,156,4,0,
365,13,164,3,0,
365,13,173,1,39,5
365,13,181,1,1,1
365,13,182,4,0,
365,13,196,3,0,
365,13,207,3,0,
365,13,213,4,0,
365,13,216,4,0,
365,13,218,4,0,
365,13,227,1,1,4
365,13,227,1,7,
365,13,231,4,0,
365,13,237,4,0,
365,13,240,4,0,
365,13,249,4,0,
365,13,258,1,31,
365,13,258,4,0,
365,13,263,4,0,
365,13,290,4,0,
365,13,291,4,0,
365,13,301,1,13,
365,13,317,4,0,
365,13,329,1,61,
365,13,352,4,0,
365,14,34,1,19,
365,14,45,1,1,3
365,14,46,4,0,
365,14,55,1,1,4
365,14,57,4,0,
365,14,58,4,0,
365,14,59,1,52,
365,14,59,4,0,
365,14,62,1,25,
365,14,63,4,0,
365,14,70,4,0,
365,14,89,4,0,
365,14,92,4,0,
365,14,104,4,0,
365,14,127,4,0,
365,14,156,1,39,1
365,14,156,4,0,
365,14,157,4,0,
365,14,162,3,0,
365,14,164,4,0,
365,14,173,1,39,2
365,14,173,3,0,
365,14,181,1,1,2
365,14,182,4,0,
365,14,196,3,0,
365,14,207,1,32,
365,14,207,4,0,
365,14,213,4,0,
365,14,214,3,0,
365,14,216,4,0,
365,14,218,4,0,
365,14,227,1,1,5
365,14,227,1,7,
365,14,231,3,0,
365,14,237,4,0,
365,14,240,4,0,
365,14,242,1,1,1
365,14,249,4,0,
365,14,258,1,31,
365,14,258,4,0,
365,14,263,4,0,
365,14,291,4,0,
365,14,301,1,13,
365,14,317,4,0,
365,14,324,3,0,
365,14,329,1,65,
365,14,335,3,0,
365,14,401,3,0,
365,14,416,4,0,
365,14,423,1,44,
365,14,442,3,0,
365,14,496,4,0,
365,14,497,4,0,
365,14,523,4,0,
365,14,524,4,0,
365,15,34,1,19,
365,15,45,1,1,3
365,15,46,4,0,
365,15,55,1,1,4
365,15,57,4,0,
365,15,58,4,0,
365,15,59,1,52,
365,15,59,4,0,
365,15,62,1,25,
365,15,63,4,0,
365,15,70,4,0,
365,15,89,4,0,
365,15,92,4,0,
365,15,104,4,0,
365,15,127,4,0,
365,15,156,1,39,1
365,15,156,4,0,
365,15,157,4,0,
365,15,164,4,0,
365,15,173,1,39,2
365,15,181,1,1,2
365,15,182,4,0,
365,15,207,1,32,
365,15,207,4,0,
365,15,213,4,0,
365,15,214,4,0,
365,15,216,4,0,
365,15,218,4,0,
365,15,227,1,1,5
365,15,227,1,7,
365,15,237,4,0,
365,15,240,4,0,
365,15,242,1,1,1
365,15,249,4,0,
365,15,258,1,31,
365,15,258,4,0,
365,15,263,4,0,
365,15,301,1,13,
365,15,317,4,0,
365,15,329,1,65,
365,15,416,4,0,
365,15,423,1,44,
365,15,496,4,0,
365,15,497,4,0,
365,15,523,4,0,
365,15,524,4,0,
365,15,590,4,0,
365,16,34,1,25,1
365,16,45,1,1,4
365,16,46,4,0,
365,16,55,1,1,5
365,16,57,4,0,
365,16,58,4,0,
365,16,59,1,49,1
365,16,59,4,0,
365,16,62,1,19,2
365,16,63,4,0,
365,16,70,4,0,
365,16,89,4,0,
365,16,92,4,0,
365,16,104,4,0,
365,16,111,1,1,2
365,16,127,4,0,
365,16,156,1,31,1
365,16,156,4,0,
365,16,157,4,0,
365,16,162,3,0,
365,16,164,4,0,
365,16,173,1,31,2
365,16,173,3,0,
365,16,181,1,1,3
365,16,182,4,0,
365,16,196,3,0,
365,16,205,1,7,1
365,16,207,1,32,1
365,16,207,4,0,
365,16,213,4,0,
365,16,214,4,0,
365,16,216,4,0,
365,16,218,4,0,
365,16,227,1,7,2
365,16,231,3,0,
365,16,237,4,0,
365,16,240,4,0,
365,16,242,1,1,1
365,16,249,4,0,
365,16,258,1,38,1
365,16,258,4,0,
365,16,263,4,0,
365,16,290,4,0,
365,16,291,4,0,
365,16,301,1,13,1
365,16,317,4,0,
365,16,324,3,0,
365,16,329,1,60,1
365,16,335,3,0,
365,16,352,3,0,
365,16,362,1,19,1
365,16,401,3,0,
365,16,416,4,0,
365,16,423,1,44,1
365,16,442,3,0,
365,16,496,4,0,
365,16,497,4,0,
365,16,523,4,0,
365,16,524,4,0,
365,16,590,4,0,
365,17,34,1,25,
365,17,45,1,1,6
365,17,46,4,0,
365,17,55,1,1,7
365,17,57,4,0,
365,17,58,4,0,
365,17,59,1,49,
365,17,59,4,0,
365,17,62,1,19,2
365,17,63,4,0,
365,17,89,4,0,
365,17,92,4,0,
365,17,104,4,0,
365,17,111,1,1,4
365,17,127,4,0,
365,17,156,1,31,1
365,17,156,4,0,
365,17,157,4,0,
365,17,164,4,0,
365,17,173,1,31,2
365,17,181,1,1,5
365,17,182,4,0,
365,17,205,1,7,1
365,17,207,1,1,2
365,17,207,4,0,
365,17,213,4,0,
365,17,214,4,0,
365,17,216,4,0,
365,17,218,4,0,
365,17,227,1,7,2
365,17,237,4,0,
365,17,240,4,0,
365,17,242,1,1,3
365,17,258,1,38,
365,17,258,4,0,
365,17,263,4,0,
365,17,301,1,13,
365,17,317,4,0,
365,17,329,1,60,
365,17,362,1,19,1
365,17,416,4,0,
365,17,423,1,0,
365,17,423,1,1,1
365,17,496,4,0,
365,17,497,4,0,
365,17,523,4,0,
365,17,524,4,0,
365,17,590,4,0,
365,18,34,1,25,
365,18,45,1,1,6
365,18,46,4,0,
365,18,55,1,1,7
365,18,57,4,0,
365,18,58,4,0,
365,18,59,1,49,
365,18,59,4,0,
365,18,62,1,19,2
365,18,63,4,0,
365,18,89,4,0,
365,18,92,4,0,
365,18,104,4,0,
365,18,111,1,1,4
365,18,127,4,0,
365,18,156,1,31,1
365,18,156,4,0,
365,18,157,4,0,
365,18,164,4,0,
365,18,173,1,31,2
365,18,181,1,1,5
365,18,182,4,0,
365,18,205,1,7,1
365,18,207,1,1,2
365,18,207,4,0,
365,18,213,4,0,
365,18,214,4,0,
365,18,216,4,0,
365,18,218,4,0,
365,18,227,1,7,2
365,18,237,4,0,
365,18,240,4,0,
365,18,242,1,1,3
365,18,258,1,38,
365,18,258,4,0,
365,18,263,4,0,
365,18,301,1,13,
365,18,317,4,0,
365,18,329,1,60,
365,18,362,1,19,1
365,18,416,4,0,
365,18,423,1,0,
365,18,423,1,1,1
365,18,496,4,0,
365,18,497,4,0,
365,18,523,4,0,
365,18,524,4,0,
365,18,590,4,0,
366,5,34,2,0,
366,5,48,2,0,
366,5,55,1,1,2
366,5,57,4,0,
366,5,58,4,0,
366,5,59,4,0,
366,5,92,4,0,
366,5,104,4,0,
366,5,109,2,0,
366,5,112,2,0,
366,5,127,4,0,
366,5,128,1,1,1
366,5,156,4,0,
366,5,182,4,0,
366,5,213,4,0,
366,5,216,4,0,
366,5,218,4,0,
366,5,237,4,0,
366,5,240,4,0,
366,5,250,1,1,3
366,5,258,4,0,
366,5,263,4,0,
366,5,287,2,0,
366,5,290,4,0,
366,5,291,4,0,
366,5,300,2,0,
366,5,334,1,1,4
366,5,352,4,0,
366,6,34,2,0,
366,6,34,3,0,
366,6,38,3,0,
366,6,48,2,0,
366,6,55,1,1,2
366,6,57,4,0,
366,6,58,4,0,
366,6,59,4,0,
366,6,92,4,0,
366,6,102,3,0,
366,6,104,4,0,
366,6,109,2,0,
366,6,112,2,0,
366,6,127,4,0,
366,6,128,1,1,1
366,6,156,4,0,
366,6,164,3,0,
366,6,173,3,0,
366,6,182,4,0,
366,6,196,3,0,
366,6,203,3,0,
366,6,207,3,0,
366,6,213,4,0,
366,6,214,3,0,
366,6,216,4,0,
366,6,218,4,0,
366,6,237,4,0,
366,6,240,4,0,
366,6,250,1,1,3
366,6,258,4,0,
366,6,263,4,0,
366,6,287,2,0,
366,6,290,4,0,
366,6,291,4,0,
366,6,300,2,0,
366,6,334,1,1,4
366,6,352,4,0,
366,7,34,2,0,
366,7,34,3,0,
366,7,38,3,0,
366,7,48,2,0,
366,7,55,1,1,2
366,7,57,4,0,
366,7,58,4,0,
366,7,59,4,0,
366,7,92,4,0,
366,7,102,3,0,
366,7,104,4,0,
366,7,109,2,0,
366,7,112,2,0,
366,7,127,4,0,
366,7,128,1,1,1
366,7,156,4,0,
366,7,164,3,0,
366,7,182,4,0,
366,7,213,4,0,
366,7,216,4,0,
366,7,218,4,0,
366,7,237,4,0,
366,7,240,4,0,
366,7,250,1,1,3
366,7,258,4,0,
366,7,263,4,0,
366,7,287,2,0,
366,7,290,4,0,
366,7,291,4,0,
366,7,300,2,0,
366,7,334,1,1,4
366,7,352,4,0,
366,8,34,2,0,
366,8,48,2,0,
366,8,55,1,1,2
366,8,57,4,0,
366,8,58,4,0,
366,8,59,4,0,
366,8,92,4,0,
366,8,104,4,0,
366,8,109,2,0,
366,8,112,2,0,
366,8,127,4,0,
366,8,128,1,1,1
366,8,156,4,0,
366,8,164,4,0,
366,8,182,4,0,
366,8,203,4,0,
366,8,207,4,0,
366,8,213,4,0,
366,8,214,4,0,
366,8,216,4,0,
366,8,218,4,0,
366,8,237,4,0,
366,8,240,4,0,
366,8,250,1,1,3
366,8,258,4,0,
366,8,263,4,0,
366,8,287,2,0,
366,8,290,4,0,
366,8,300,2,0,
366,8,334,1,1,4
366,8,352,4,0,
366,8,362,4,0,
366,8,363,4,0,
366,8,392,2,0,
366,8,445,4,0,
366,9,34,2,0,
366,9,48,2,0,
366,9,55,1,1,2
366,9,57,4,0,
366,9,58,4,0,
366,9,59,4,0,
366,9,92,4,0,
366,9,104,4,0,
366,9,109,2,0,
366,9,112,2,0,
366,9,127,4,0,
366,9,128,1,1,1
366,9,156,4,0,
366,9,164,4,0,
366,9,173,3,0,
366,9,182,4,0,
366,9,196,3,0,
366,9,203,4,0,
366,9,207,4,0,
366,9,213,4,0,
366,9,214,4,0,
366,9,216,4,0,
366,9,218,4,0,
366,9,237,4,0,
366,9,240,4,0,
366,9,250,1,1,3
366,9,258,4,0,
366,9,263,4,0,
366,9,287,2,0,
366,9,290,4,0,
366,9,291,3,0,
366,9,300,2,0,
366,9,334,1,1,4
366,9,334,3,0,
366,9,352,4,0,
366,9,362,4,0,
366,9,363,4,0,
366,9,392,2,0,
366,9,445,4,0,
366,10,34,2,0,
366,10,48,2,0,
366,10,55,1,1,2
366,10,57,4,0,
366,10,58,4,0,
366,10,59,4,0,
366,10,92,4,0,
366,10,104,4,0,
366,10,109,2,0,
366,10,112,2,0,
366,10,127,4,0,
366,10,128,1,1,1
366,10,156,4,0,
366,10,164,4,0,
366,10,173,3,0,
366,10,182,4,0,
366,10,196,3,0,
366,10,203,4,0,
366,10,207,4,0,
366,10,213,4,0,
366,10,214,4,0,
366,10,216,4,0,
366,10,218,4,0,
366,10,237,4,0,
366,10,240,4,0,
366,10,250,1,1,3
366,10,250,4,0,
366,10,258,4,0,
366,10,263,4,0,
366,10,287,2,0,
366,10,290,4,0,
366,10,291,3,0,
366,10,300,2,0,
366,10,330,2,0,
366,10,334,1,1,4
366,10,334,3,0,
366,10,352,4,0,
366,10,362,4,0,
366,10,363,4,0,
366,10,392,2,0,
366,10,445,4,0,
366,11,34,2,0,
366,11,48,2,0,
366,11,55,1,1,2
366,11,57,4,0,
366,11,58,4,0,
366,11,59,4,0,
366,11,92,4,0,
366,11,104,4,0,
366,11,109,2,0,
366,11,112,2,0,
366,11,127,4,0,
366,11,128,1,1,1
366,11,156,4,0,
366,11,164,4,0,
366,11,182,4,0,
366,11,203,2,0,
366,11,207,4,0,
366,11,213,4,0,
366,11,216,4,0,
366,11,218,4,0,
366,11,237,4,0,
366,11,240,4,0,
366,11,250,1,1,3
366,11,258,4,0,
366,11,263,4,0,
366,11,287,2,0,
366,11,291,4,0,
366,11,300,2,0,
366,11,330,2,0,
366,11,334,1,1,4
366,11,352,2,0,
366,11,362,2,0,
366,11,392,2,0,
366,11,496,4,0,
366,11,503,4,0,
366,11,504,1,51,
366,12,55,1,1,2
366,12,57,4,0,
366,12,58,4,0,
366,12,59,4,0,
366,12,92,4,0,
366,12,104,4,0,
366,12,127,4,0,
366,12,128,1,1,1
366,12,156,4,0,
366,12,182,4,0,
366,12,213,4,0,
366,12,216,4,0,
366,12,218,4,0,
366,12,237,4,0,
366,12,240,4,0,
366,12,250,1,1,3
366,12,258,4,0,
366,12,263,4,0,
366,12,290,4,0,
366,12,291,4,0,
366,12,334,1,1,4
366,12,352,4,0,
366,13,34,3,0,
366,13,38,3,0,
366,13,55,1,1,2
366,13,57,4,0,
366,13,58,4,0,
366,13,59,4,0,
366,13,92,4,0,
366,13,102,3,0,
366,13,104,4,0,
366,13,127,4,0,
366,13,128,1,1,1
366,13,156,4,0,
366,13,164,3,0,
366,13,182,4,0,
366,13,196,3,0,
366,13,207,3,0,
366,13,213,4,0,
366,13,216,4,0,
366,13,218,4,0,
366,13,237,4,0,
366,13,240,4,0,
366,13,250,1,1,3
366,13,258,4,0,
366,13,263,4,0,
366,13,290,4,0,
366,13,291,4,0,
366,13,334,1,1,4
366,13,352,4,0,
366,14,34,2,0,
366,14,48,2,0,
366,14,55,1,1,2
366,14,57,4,0,
366,14,58,4,0,
366,14,59,4,0,
366,14,92,4,0,
366,14,104,4,0,
366,14,109,2,0,
366,14,112,2,0,
366,14,127,4,0,
366,14,128,1,1,1
366,14,156,4,0,
366,14,164,4,0,
366,14,173,3,0,
366,14,182,4,0,
366,14,196,3,0,
366,14,203,2,0,
366,14,207,4,0,
366,14,213,4,0,
366,14,214,3,0,
366,14,216,4,0,
366,14,218,4,0,
366,14,237,4,0,
366,14,240,4,0,
366,14,250,1,1,3
366,14,258,4,0,
366,14,263,4,0,
366,14,287,2,0,
366,14,291,4,0,
366,14,300,2,0,
366,14,330,2,0,
366,14,334,1,1,4
366,14,334,3,0,
366,14,352,2,0,
366,14,362,2,0,
366,14,392,2,0,
366,14,496,4,0,
366,14,503,4,0,
366,14,504,1,51,
366,15,34,2,0,
366,15,48,2,0,
366,15,55,1,1,2
366,15,57,4,0,
366,15,58,4,0,
366,15,59,4,0,
366,15,92,4,0,
366,15,104,4,0,
366,15,109,2,0,
366,15,112,2,0,
366,15,127,4,0,
366,15,128,1,1,1
366,15,156,4,0,
366,15,164,4,0,
366,15,182,4,0,
366,15,203,2,0,
366,15,207,4,0,
366,15,213,4,0,
366,15,214,4,0,
366,15,216,4,0,
366,15,218,4,0,
366,15,237,4,0,
366,15,240,4,0,
366,15,250,1,1,3
366,15,258,4,0,
366,15,263,4,0,
366,15,287,2,0,
366,15,300,2,0,
366,15,330,2,0,
366,15,334,1,1,4
366,15,352,2,0,
366,15,362,2,0,
366,15,392,2,0,
366,15,496,4,0,
366,15,503,4,0,
366,15,504,1,50,
366,15,590,4,0,
366,16,34,2,0,
366,16,48,2,0,
366,16,55,1,1,2
366,16,57,4,0,
366,16,58,4,0,
366,16,59,4,0,
366,16,92,4,0,
366,16,104,4,0,
366,16,109,2,0,
366,16,112,2,0,
366,16,127,4,0,
366,16,128,1,1,1
366,16,156,4,0,
366,16,164,4,0,
366,16,173,3,0,
366,16,182,4,0,
366,16,196,3,0,
366,16,203,2,0,
366,16,207,4,0,
366,16,213,4,0,
366,16,214,4,0,
366,16,216,4,0,
366,16,218,4,0,
366,16,237,4,0,
366,16,240,4,0,
366,16,250,1,1,3
366,16,258,4,0,
366,16,263,4,0,
366,16,287,2,0,
366,16,290,4,0,
366,16,291,4,0,
366,16,300,2,0,
366,16,330,2,0,
366,16,334,1,1,4
366,16,334,3,0,
366,16,352,2,0,
366,16,352,3,0,
366,16,362,2,0,
366,16,392,2,0,
366,16,496,4,0,
366,16,503,4,0,
366,16,504,1,50,1
366,16,590,4,0,
366,17,34,2,0,
366,17,48,2,0,
366,17,55,1,1,2
366,17,57,4,0,
366,17,58,4,0,
366,17,59,4,0,
366,17,92,4,0,
366,17,104,4,0,
366,17,109,2,0,
366,17,112,2,0,
366,17,127,4,0,
366,17,128,1,1,1
366,17,156,4,0,
366,17,164,4,0,
366,17,182,4,0,
366,17,203,2,0,
366,17,207,4,0,
366,17,213,4,0,
366,17,214,4,0,
366,17,216,4,0,
366,17,218,4,0,
366,17,237,4,0,
366,17,240,4,0,
366,17,250,1,1,3
366,17,258,4,0,
366,17,263,4,0,
366,17,287,2,0,
366,17,300,2,0,
366,17,330,2,0,
366,17,334,1,1,4
366,17,352,2,0,
366,17,362,2,0,
366,17,392,2,0,
366,17,496,4,0,
366,17,503,4,0,
366,17,504,1,50,
366,17,590,4,0,
366,18,34,2,0,
366,18,48,2,0,
366,18,55,1,1,2
366,18,57,4,0,
366,18,58,4,0,
366,18,59,4,0,
366,18,92,4,0,
366,18,104,4,0,
366,18,109,2,0,
366,18,112,2,0,
366,18,127,4,0,
366,18,128,1,1,1
366,18,156,4,0,
366,18,164,4,0,
366,18,182,4,0,
366,18,203,2,0,
366,18,207,4,0,
366,18,213,4,0,
366,18,214,4,0,
366,18,216,4,0,
366,18,218,4,0,
366,18,237,4,0,
366,18,240,4,0,
366,18,250,1,1,3
366,18,258,4,0,
366,18,263,4,0,
366,18,287,2,0,
366,18,300,2,0,
366,18,330,2,0,
366,18,334,1,1,4
366,18,352,2,0,
366,18,362,2,0,
366,18,392,2,0,
366,18,496,4,0,
366,18,503,4,0,
366,18,504,1,50,
366,18,590,4,0,
367,5,44,1,8,
367,5,56,1,50,
367,5,57,4,0,
367,5,58,4,0,
367,5,59,4,0,
367,5,63,4,0,
367,5,92,4,0,
367,5,103,1,15,
367,5,104,4,0,
367,5,127,4,0,
367,5,156,4,0,
367,5,182,4,0,
367,5,184,1,29,
367,5,213,4,0,
367,5,216,4,0,
367,5,218,4,0,
367,5,226,1,43,
367,5,237,4,0,
367,5,240,4,0,
367,5,242,1,36,
367,5,250,1,1,
367,5,258,4,0,
367,5,263,4,0,
367,5,289,4,0,
367,5,290,4,0,
367,5,291,4,0,
367,5,317,4,0,
367,5,352,1,22,
367,5,352,4,0,
367,6,34,3,0,
367,6,38,3,0,
367,6,44,1,8,
367,6,56,1,50,
367,6,57,4,0,
367,6,58,4,0,
367,6,59,4,0,
367,6,63,4,0,
367,6,92,4,0,
367,6,102,3,0,
367,6,103,1,15,
367,6,104,4,0,
367,6,127,4,0,
367,6,129,3,0,
367,6,156,4,0,
367,6,164,3,0,
367,6,173,3,0,
367,6,182,4,0,
367,6,184,1,29,
367,6,189,3,0,
367,6,196,3,0,
367,6,203,3,0,
367,6,207,3,0,
367,6,213,4,0,
367,6,214,3,0,
367,6,216,4,0,
367,6,218,4,0,
367,6,226,1,43,
367,6,237,4,0,
367,6,240,4,0,
367,6,242,1,36,
367,6,250,1,1,
367,6,258,4,0,
367,6,263,4,0,
367,6,289,4,0,
367,6,290,4,0,
367,6,291,4,0,
367,6,317,4,0,
367,6,352,1,22,
367,6,352,4,0,
367,7,34,3,0,
367,7,38,3,0,
367,7,44,1,8,
367,7,56,1,50,
367,7,57,4,0,
367,7,58,4,0,
367,7,59,4,0,
367,7,63,4,0,
367,7,92,4,0,
367,7,102,3,0,
367,7,103,1,15,
367,7,104,4,0,
367,7,127,4,0,
367,7,156,4,0,
367,7,164,3,0,
367,7,182,4,0,
367,7,184,1,29,
367,7,213,4,0,
367,7,216,4,0,
367,7,218,4,0,
367,7,226,1,43,
367,7,237,4,0,
367,7,240,4,0,
367,7,242,1,36,
367,7,250,1,1,
367,7,258,4,0,
367,7,263,4,0,
367,7,289,4,0,
367,7,290,4,0,
367,7,291,4,0,
367,7,317,4,0,
367,7,352,1,22,
367,7,352,4,0,
367,8,44,1,6,
367,8,56,1,51,
367,8,57,4,0,
367,8,58,4,0,
367,8,59,4,0,
367,8,63,4,0,
367,8,92,4,0,
367,8,103,1,10,
367,8,104,4,0,
367,8,127,4,0,
367,8,156,4,0,
367,8,164,4,0,
367,8,182,4,0,
367,8,184,1,19,
367,8,203,4,0,
367,8,207,4,0,
367,8,213,4,0,
367,8,214,4,0,
367,8,216,4,0,
367,8,218,4,0,
367,8,226,1,33,
367,8,237,4,0,
367,8,240,4,0,
367,8,242,1,42,
367,8,250,1,1,
367,8,258,4,0,
367,8,263,4,0,
367,8,289,4,0,
367,8,290,4,0,
367,8,291,1,37,
367,8,317,4,0,
367,8,352,1,15,
367,8,352,4,0,
367,8,362,1,28,
367,8,362,4,0,
367,8,363,4,0,
367,8,401,1,46,
367,8,416,4,0,
367,8,423,1,24,
367,8,445,4,0,
367,9,44,1,6,
367,9,56,1,51,
367,9,57,4,0,
367,9,58,4,0,
367,9,59,4,0,
367,9,63,4,0,
367,9,92,4,0,
367,9,103,1,10,
367,9,104,4,0,
367,9,127,4,0,
367,9,129,3,0,
367,9,156,4,0,
367,9,164,4,0,
367,9,173,3,0,
367,9,182,4,0,
367,9,184,1,19,
367,9,189,3,0,
367,9,196,3,0,
367,9,203,4,0,
367,9,207,4,0,
367,9,213,4,0,
367,9,214,4,0,
367,9,216,4,0,
367,9,218,4,0,
367,9,226,1,33,
367,9,237,4,0,
367,9,240,4,0,
367,9,242,1,42,
367,9,250,1,1,
367,9,258,4,0,
367,9,263,4,0,
367,9,289,4,0,
367,9,290,4,0,
367,9,291,1,37,
367,9,291,3,0,
367,9,317,4,0,
367,9,340,3,0,
367,9,352,1,15,
367,9,352,4,0,
367,9,362,1,28,
367,9,362,4,0,
367,9,363,4,0,
367,9,389,3,0,
367,9,401,1,46,
367,9,401,3,0,
367,9,416,4,0,
367,9,423,1,24,
367,9,445,4,0,
367,10,44,1,6,
367,10,56,1,51,
367,10,57,4,0,
367,10,58,4,0,
367,10,59,4,0,
367,10,63,4,0,
367,10,92,4,0,
367,10,103,1,10,
367,10,104,4,0,
367,10,127,4,0,
367,10,129,3,0,
367,10,156,4,0,
367,10,162,3,0,
367,10,164,4,0,
367,10,173,3,0,
367,10,182,4,0,
367,10,184,1,19,
367,10,189,3,0,
367,10,196,3,0,
367,10,203,4,0,
367,10,207,4,0,
367,10,213,4,0,
367,10,214,4,0,
367,10,216,4,0,
367,10,218,4,0,
367,10,226,1,33,
367,10,237,4,0,
367,10,240,4,0,
367,10,242,1,42,
367,10,250,1,1,
367,10,250,4,0,
367,10,258,4,0,
367,10,263,4,0,
367,10,289,4,0,
367,10,290,4,0,
367,10,291,1,37,
367,10,291,3,0,
367,10,317,4,0,
367,10,340,3,0,
367,10,352,1,15,
367,10,352,4,0,
367,10,362,1,28,
367,10,362,4,0,
367,10,363,4,0,
367,10,389,3,0,
367,10,401,1,46,
367,10,401,3,0,
367,10,416,4,0,
367,10,423,1,24,
367,10,445,4,0,
367,11,44,1,6,
367,11,56,1,51,
367,11,57,4,0,
367,11,58,4,0,
367,11,59,4,0,
367,11,63,4,0,
367,11,92,4,0,
367,11,103,1,10,
367,11,104,4,0,
367,11,127,4,0,
367,11,156,4,0,
367,11,164,4,0,
367,11,182,4,0,
367,11,184,1,19,
367,11,207,4,0,
367,11,213,4,0,
367,11,216,4,0,
367,11,218,4,0,
367,11,226,1,33,
367,11,237,4,0,
367,11,240,4,0,
367,11,242,1,42,
367,11,250,1,1,
367,11,258,4,0,
367,11,263,4,0,
367,11,291,1,37,
367,11,291,4,0,
367,11,317,4,0,
367,11,352,1,15,
367,11,362,1,28,
367,11,401,1,46,
367,11,416,4,0,
367,11,423,1,24,
367,11,496,4,0,
367,11,503,4,0,
367,12,44,1,8,
367,12,56,1,50,
367,12,57,4,0,
367,12,58,4,0,
367,12,59,4,0,
367,12,63,4,0,
367,12,92,4,0,
367,12,103,1,15,
367,12,104,4,0,
367,12,127,4,0,
367,12,156,4,0,
367,12,182,4,0,
367,12,184,1,29,
367,12,213,4,0,
367,12,216,4,0,
367,12,218,4,0,
367,12,226,1,43,
367,12,237,4,0,
367,12,240,4,0,
367,12,242,1,36,
367,12,250,1,1,
367,12,258,4,0,
367,12,263,4,0,
367,12,289,4,0,
367,12,290,4,0,
367,12,291,4,0,
367,12,317,4,0,
367,12,352,1,22,
367,12,352,4,0,
367,13,34,3,0,
367,13,38,3,0,
367,13,44,1,8,
367,13,56,1,50,
367,13,57,4,0,
367,13,58,4,0,
367,13,59,4,0,
367,13,63,4,0,
367,13,92,4,0,
367,13,102,3,0,
367,13,103,1,15,
367,13,104,4,0,
367,13,127,4,0,
367,13,156,4,0,
367,13,164,3,0,
367,13,182,4,0,
367,13,184,1,29,
367,13,196,3,0,
367,13,207,3,0,
367,13,213,4,0,
367,13,216,4,0,
367,13,218,4,0,
367,13,226,1,43,
367,13,237,4,0,
367,13,240,4,0,
367,13,242,1,36,
367,13,250,1,1,
367,13,258,4,0,
367,13,263,4,0,
367,13,289,4,0,
367,13,290,4,0,
367,13,291,4,0,
367,13,317,4,0,
367,13,352,1,22,
367,13,352,4,0,
367,14,20,3,0,
367,14,44,1,6,
367,14,56,1,51,
367,14,57,4,0,
367,14,58,4,0,
367,14,59,4,0,
367,14,63,4,0,
367,14,92,4,0,
367,14,103,1,10,
367,14,104,4,0,
367,14,127,4,0,
367,14,156,4,0,
367,14,162,3,0,
367,14,164,4,0,
367,14,173,3,0,
367,14,182,4,0,
367,14,184,1,19,
367,14,196,3,0,
367,14,207,4,0,
367,14,213,4,0,
367,14,214,3,0,
367,14,216,4,0,
367,14,218,4,0,
367,14,226,1,33,
367,14,237,4,0,
367,14,240,4,0,
367,14,242,1,42,
367,14,250,1,1,
367,14,258,4,0,
367,14,263,4,0,
367,14,289,3,0,
367,14,291,1,37,
367,14,291,4,0,
367,14,317,4,0,
367,14,340,3,0,
367,14,352,1,15,
367,14,362,1,28,
367,14,401,1,46,
367,14,401,3,0,
367,14,416,4,0,
367,14,423,1,24,
367,14,496,4,0,
367,14,503,4,0,
367,15,44,1,6,
367,15,56,1,50,
367,15,57,4,0,
367,15,58,4,0,
367,15,59,4,0,
367,15,63,4,0,
367,15,92,4,0,
367,15,103,1,10,
367,15,104,4,0,
367,15,127,4,0,
367,15,156,4,0,
367,15,164,4,0,
367,15,182,4,0,
367,15,184,1,19,
367,15,207,4,0,
367,15,213,4,0,
367,15,214,4,0,
367,15,216,4,0,
367,15,218,4,0,
367,15,226,1,33,
367,15,237,4,0,
367,15,240,4,0,
367,15,242,1,42,
367,15,250,1,1,
367,15,258,4,0,
367,15,263,4,0,
367,15,291,1,37,
367,15,317,4,0,
367,15,352,1,15,
367,15,362,1,28,
367,15,401,1,46,
367,15,416,4,0,
367,15,423,1,24,
367,15,496,4,0,
367,15,503,4,0,
367,15,590,4,0,
367,15,611,4,0,
367,16,20,3,0,
367,16,44,1,1,2
367,16,56,1,50,1
367,16,57,4,0,
367,16,58,4,0,
367,16,59,4,0,
367,16,63,4,0,
367,16,92,4,0,
367,16,103,1,5,1
367,16,104,4,0,
367,16,127,4,0,
367,16,156,4,0,
367,16,162,3,0,
367,16,164,4,0,
367,16,173,3,0,
367,16,182,4,0,
367,16,184,1,9,1
367,16,185,1,11,1
367,16,196,3,0,
367,16,207,4,0,
367,16,213,4,0,
367,16,214,4,0,
367,16,216,4,0,
367,16,218,4,0,
367,16,226,1,29,1
367,16,237,4,0,
367,16,240,4,0,
367,16,242,1,34,1
367,16,250,1,1,1
367,16,258,4,0,
367,16,263,4,0,
367,16,289,3,0,
367,16,290,4,0,
367,16,291,1,26,1
367,16,291,4,0,
367,16,317,4,0,
367,16,340,3,0,
367,16,352,1,14,1
367,16,352,3,0,
367,16,362,1,19,1
367,16,389,1,23,1
367,16,401,1,39,1
367,16,401,3,0,
367,16,416,4,0,
367,16,423,1,16,1
367,16,489,1,45,1
367,16,496,4,0,
367,16,503,4,0,
367,16,590,4,0,
367,16,611,4,0,
367,17,44,1,1,2
367,17,56,1,50,
367,17,57,4,0,
367,17,58,4,0,
367,17,59,4,0,
367,17,63,4,0,
367,17,92,4,0,
367,17,103,1,5,
367,17,104,4,0,
367,17,127,4,0,
367,17,156,4,0,
367,17,164,4,0,
367,17,182,4,0,
367,17,184,1,9,
367,17,185,1,11,
367,17,207,4,0,
367,17,213,4,0,
367,17,214,4,0,
367,17,216,4,0,
367,17,218,4,0,
367,17,226,1,29,
367,17,237,4,0,
367,17,240,4,0,
367,17,242,1,34,
367,17,250,1,1,1
367,17,258,4,0,
367,17,263,4,0,
367,17,291,1,26,
367,17,317,4,0,
367,17,352,1,14,
367,17,362,1,19,
367,17,389,1,23,
367,17,401,1,39,
367,17,416,4,0,
367,17,423,1,16,
367,17,489,1,45,
367,17,496,4,0,
367,17,503,4,0,
367,17,590,4,0,
367,17,611,4,0,
367,18,44,1,1,2
367,18,56,1,50,
367,18,57,4,0,
367,18,58,4,0,
367,18,59,4,0,
367,18,63,4,0,
367,18,92,4,0,
367,18,103,1,5,
367,18,104,4,0,
367,18,127,4,0,
367,18,156,4,0,
367,18,164,4,0,
367,18,182,4,0,
367,18,184,1,9,
367,18,185,1,11,
367,18,207,4,0,
367,18,213,4,0,
367,18,214,4,0,
367,18,216,4,0,
367,18,218,4,0,
367,18,226,1,29,
367,18,237,4,0,
367,18,240,4,0,
367,18,242,1,34,
367,18,250,1,1,1
367,18,258,4,0,
367,18,263,4,0,
367,18,291,1,26,
367,18,317,4,0,
367,18,352,1,14,
367,18,362,1,19,
367,18,389,1,23,
367,18,401,1,39,
367,18,416,4,0,
367,18,423,1,16,
367,18,489,1,45,
367,18,496,4,0,
367,18,503,4,0,
367,18,590,4,0,
367,18,611,4,0,
368,5,56,1,50,
368,5,57,4,0,
368,5,58,4,0,
368,5,59,4,0,
368,5,63,4,0,
368,5,92,4,0,
368,5,93,1,8,
368,5,94,1,36,
368,5,94,4,0,
368,5,97,1,15,
368,5,104,4,0,
368,5,127,4,0,
368,5,133,1,29,
368,5,156,4,0,
368,5,182,4,0,
368,5,213,4,0,
368,5,216,4,0,
368,5,218,4,0,
368,5,219,4,0,
368,5,226,1,43,
368,5,237,4,0,
368,5,240,4,0,
368,5,247,4,0,
368,5,250,1,1,
368,5,258,4,0,
368,5,263,4,0,
368,5,290,4,0,
368,5,291,4,0,
368,5,352,1,22,
368,5,352,4,0,
368,6,34,3,0,
368,6,38,3,0,
368,6,56,1,50,
368,6,57,4,0,
368,6,58,4,0,
368,6,59,4,0,
368,6,63,4,0,
368,6,92,4,0,
368,6,93,1,8,
368,6,94,1,36,
368,6,94,4,0,
368,6,97,1,15,
368,6,102,3,0,
368,6,104,4,0,
368,6,127,4,0,
368,6,129,3,0,
368,6,133,1,29,
368,6,156,4,0,
368,6,164,3,0,
368,6,173,3,0,
368,6,182,4,0,
368,6,189,3,0,
368,6,196,3,0,
368,6,203,3,0,
368,6,207,3,0,
368,6,213,4,0,
368,6,214,3,0,
368,6,216,4,0,
368,6,218,4,0,
368,6,219,4,0,
368,6,226,1,43,
368,6,237,4,0,
368,6,240,4,0,
368,6,247,4,0,
368,6,250,1,1,
368,6,258,4,0,
368,6,263,4,0,
368,6,290,4,0,
368,6,291,4,0,
368,6,352,1,22,
368,6,352,4,0,
368,7,34,3,0,
368,7,38,3,0,
368,7,56,1,50,
368,7,57,4,0,
368,7,58,4,0,
368,7,59,4,0,
368,7,63,4,0,
368,7,92,4,0,
368,7,93,1,8,
368,7,94,1,36,
368,7,94,4,0,
368,7,97,1,15,
368,7,102,3,0,
368,7,104,4,0,
368,7,127,4,0,
368,7,133,1,29,
368,7,156,4,0,
368,7,164,3,0,
368,7,182,4,0,
368,7,213,4,0,
368,7,216,4,0,
368,7,218,4,0,
368,7,219,4,0,
368,7,226,1,43,
368,7,237,4,0,
368,7,240,4,0,
368,7,247,4,0,
368,7,250,1,1,
368,7,258,4,0,
368,7,263,4,0,
368,7,290,4,0,
368,7,291,4,0,
368,7,352,1,22,
368,7,352,4,0,
368,8,56,1,51,
368,8,57,4,0,
368,8,58,4,0,
368,8,59,4,0,
368,8,63,4,0,
368,8,92,4,0,
368,8,93,1,6,
368,8,94,1,42,
368,8,94,4,0,
368,8,97,1,10,
368,8,104,4,0,
368,8,127,4,0,
368,8,133,1,19,
368,8,156,4,0,
368,8,164,4,0,
368,8,182,4,0,
368,8,203,4,0,
368,8,207,4,0,
368,8,213,4,0,
368,8,214,4,0,
368,8,216,4,0,
368,8,218,4,0,
368,8,219,4,0,
368,8,226,1,33,
368,8,237,4,0,
368,8,240,4,0,
368,8,244,4,0,
368,8,247,4,0,
368,8,250,1,1,
368,8,258,4,0,
368,8,263,4,0,
368,8,290,4,0,
368,8,291,1,37,
368,8,352,1,15,
368,8,352,4,0,
368,8,362,4,0,
368,8,363,4,0,
368,8,392,1,24,
368,8,401,1,46,
368,8,416,4,0,
368,8,445,1,28,
368,8,445,4,0,
368,9,56,1,51,
368,9,57,4,0,
368,9,58,4,0,
368,9,59,4,0,
368,9,63,4,0,
368,9,92,4,0,
368,9,93,1,6,
368,9,94,1,42,
368,9,94,4,0,
368,9,97,1,10,
368,9,104,4,0,
368,9,127,4,0,
368,9,129,3,0,
368,9,133,1,19,
368,9,156,4,0,
368,9,164,4,0,
368,9,173,3,0,
368,9,182,4,0,
368,9,189,3,0,
368,9,196,3,0,
368,9,203,4,0,
368,9,207,4,0,
368,9,213,4,0,
368,9,214,4,0,
368,9,216,4,0,
368,9,218,4,0,
368,9,219,4,0,
368,9,226,1,33,
368,9,237,4,0,
368,9,240,4,0,
368,9,244,4,0,
368,9,247,4,0,
368,9,250,1,1,
368,9,258,4,0,
368,9,263,4,0,
368,9,290,4,0,
368,9,291,1,37,
368,9,291,3,0,
368,9,324,3,0,
368,9,340,3,0,
368,9,352,1,15,
368,9,352,4,0,
368,9,362,4,0,
368,9,363,4,0,
368,9,392,1,24,
368,9,401,1,46,
368,9,401,3,0,
368,9,416,4,0,
368,9,445,1,28,
368,9,445,4,0,
368,10,56,1,51,
368,10,57,4,0,
368,10,58,4,0,
368,10,59,4,0,
368,10,63,4,0,
368,10,92,4,0,
368,10,93,1,6,
368,10,94,1,42,
368,10,94,4,0,
368,10,97,1,10,
368,10,104,4,0,
368,10,127,4,0,
368,10,129,3,0,
368,10,133,1,19,
368,10,156,4,0,
368,10,164,4,0,
368,10,173,3,0,
368,10,182,4,0,
368,10,189,3,0,
368,10,196,3,0,
368,10,203,4,0,
368,10,207,4,0,
368,10,213,4,0,
368,10,214,4,0,
368,10,216,4,0,
368,10,218,4,0,
368,10,219,4,0,
368,10,226,1,33,
368,10,237,4,0,
368,10,240,4,0,
368,10,244,4,0,
368,10,247,4,0,
368,10,250,1,1,
368,10,250,4,0,
368,10,258,4,0,
368,10,263,4,0,
368,10,290,4,0,
368,10,291,1,37,
368,10,291,3,0,
368,10,324,3,0,
368,10,340,3,0,
368,10,352,1,15,
368,10,352,4,0,
368,10,362,4,0,
368,10,363,4,0,
368,10,392,1,24,
368,10,401,1,46,
368,10,401,3,0,
368,10,416,4,0,
368,10,445,1,28,
368,10,445,4,0,
368,11,56,1,51,
368,11,57,4,0,
368,11,58,4,0,
368,11,59,4,0,
368,11,63,4,0,
368,11,92,4,0,
368,11,93,1,6,
368,11,94,1,42,
368,11,94,4,0,
368,11,97,1,10,
368,11,104,4,0,
368,11,127,4,0,
368,11,133,1,19,
368,11,156,4,0,
368,11,164,4,0,
368,11,182,4,0,
368,11,207,4,0,
368,11,213,4,0,
368,11,216,4,0,
368,11,218,4,0,
368,11,219,4,0,
368,11,226,1,33,
368,11,237,4,0,
368,11,240,4,0,
368,11,244,4,0,
368,11,247,4,0,
368,11,250,1,1,
368,11,258,4,0,
368,11,263,4,0,
368,11,291,1,37,
368,11,291,4,0,
368,11,352,1,15,
368,11,392,1,24,
368,11,401,1,46,
368,11,416,4,0,
368,11,445,1,28,
368,11,496,4,0,
368,11,503,4,0,
368,12,56,1,50,
368,12,57,4,0,
368,12,58,4,0,
368,12,59,4,0,
368,12,63,4,0,
368,12,92,4,0,
368,12,93,1,8,
368,12,94,1,36,
368,12,94,4,0,
368,12,97,1,15,
368,12,104,4,0,
368,12,127,4,0,
368,12,133,1,29,
368,12,156,4,0,
368,12,182,4,0,
368,12,213,4,0,
368,12,216,4,0,
368,12,218,4,0,
368,12,219,4,0,
368,12,226,1,43,
368,12,237,4,0,
368,12,240,4,0,
368,12,247,4,0,
368,12,250,1,1,
368,12,258,4,0,
368,12,263,4,0,
368,12,290,4,0,
368,12,291,4,0,
368,12,352,1,22,
368,12,352,4,0,
368,13,34,3,0,
368,13,38,3,0,
368,13,56,1,50,
368,13,57,4,0,
368,13,58,4,0,
368,13,59,4,0,
368,13,63,4,0,
368,13,92,4,0,
368,13,93,1,8,
368,13,94,1,36,
368,13,94,4,0,
368,13,97,1,15,
368,13,102,3,0,
368,13,104,4,0,
368,13,127,4,0,
368,13,133,1,29,
368,13,156,4,0,
368,13,164,3,0,
368,13,182,4,0,
368,13,196,3,0,
368,13,207,3,0,
368,13,213,4,0,
368,13,216,4,0,
368,13,218,4,0,
368,13,219,4,0,
368,13,226,1,43,
368,13,237,4,0,
368,13,240,4,0,
368,13,247,4,0,
368,13,250,1,1,
368,13,258,4,0,
368,13,263,4,0,
368,13,290,4,0,
368,13,291,4,0,
368,13,352,1,22,
368,13,352,4,0,
368,14,20,3,0,
368,14,56,1,51,
368,14,57,4,0,
368,14,58,4,0,
368,14,59,4,0,
368,14,63,4,0,
368,14,92,4,0,
368,14,93,1,6,
368,14,94,1,42,
368,14,94,4,0,
368,14,97,1,10,
368,14,104,4,0,
368,14,127,4,0,
368,14,133,1,19,
368,14,156,4,0,
368,14,164,4,0,
368,14,173,3,0,
368,14,182,4,0,
368,14,196,3,0,
368,14,207,4,0,
368,14,213,4,0,
368,14,214,3,0,
368,14,216,4,0,
368,14,218,4,0,
368,14,219,4,0,
368,14,226,1,33,
368,14,237,4,0,
368,14,240,4,0,
368,14,244,4,0,
368,14,247,4,0,
368,14,250,1,1,
368,14,258,4,0,
368,14,263,4,0,
368,14,291,1,37,
368,14,291,4,0,
368,14,324,3,0,
368,14,340,3,0,
368,14,352,1,15,
368,14,392,1,24,
368,14,401,1,46,
368,14,401,3,0,
368,14,416,4,0,
368,14,445,1,28,
368,14,496,4,0,
368,14,503,4,0,
368,15,56,1,50,
368,15,57,4,0,
368,15,58,4,0,
368,15,59,4,0,
368,15,63,4,0,
368,15,92,4,0,
368,15,93,1,6,
368,15,94,1,42,
368,15,94,4,0,
368,15,97,1,10,
368,15,104,4,0,
368,15,127,4,0,
368,15,133,1,19,
368,15,156,4,0,
368,15,164,4,0,
368,15,182,4,0,
368,15,207,4,0,
368,15,213,4,0,
368,15,214,4,0,
368,15,216,4,0,
368,15,218,4,0,
368,15,219,4,0,
368,15,226,1,33,
368,15,237,4,0,
368,15,240,4,0,
368,15,244,4,0,
368,15,247,4,0,
368,15,250,1,1,
368,15,258,4,0,
368,15,263,4,0,
368,15,291,1,37,
368,15,352,1,15,
368,15,392,1,24,
368,15,401,1,46,
368,15,416,4,0,
368,15,445,1,28,
368,15,496,4,0,
368,15,503,4,0,
368,15,590,4,0,
368,15,611,4,0,
368,16,20,3,0,
368,16,56,1,50,1
368,16,57,4,0,
368,16,58,4,0,
368,16,59,4,0,
368,16,63,4,0,
368,16,92,4,0,
368,16,93,1,1,2
368,16,94,1,34,1
368,16,94,4,0,
368,16,97,1,9,1
368,16,104,4,0,
368,16,127,4,0,
368,16,133,1,16,1
368,16,156,4,0,
368,16,164,4,0,
368,16,173,3,0,
368,16,182,4,0,
368,16,196,3,0,
368,16,207,4,0,
368,16,213,4,0,
368,16,214,4,0,
368,16,216,4,0,
368,16,218,4,0,
368,16,219,4,0,
368,16,226,1,29,1
368,16,237,4,0,
368,16,240,4,0,
368,16,244,4,0,
368,16,247,4,0,
368,16,250,1,1,1
368,16,258,4,0,
368,16,263,4,0,
368,16,290,4,0,
368,16,291,1,26,1
368,16,291,4,0,
368,16,324,3,0,
368,16,340,3,0,
368,16,346,1,5,1
368,16,352,1,14,1
368,16,352,3,0,
368,16,392,1,19,1
368,16,401,1,39,1
368,16,401,3,0,
368,16,416,4,0,
368,16,445,1,23,1
368,16,489,1,45,1
368,16,496,4,0,
368,16,503,4,0,
368,16,577,1,11,1
368,16,590,4,0,
368,16,611,4,0,
368,17,56,1,50,
368,17,57,4,0,
368,17,58,4,0,
368,17,59,4,0,
368,17,63,4,0,
368,17,92,4,0,
368,17,93,1,1,2
368,17,94,1,34,
368,17,94,4,0,
368,17,97,1,9,
368,17,104,4,0,
368,17,127,4,0,
368,17,133,1,16,
368,17,156,4,0,
368,17,164,4,0,
368,17,182,4,0,
368,17,207,4,0,
368,17,213,4,0,
368,17,214,4,0,
368,17,216,4,0,
368,17,218,4,0,
368,17,219,4,0,
368,17,226,1,29,
368,17,237,4,0,
368,17,240,4,0,
368,17,244,4,0,
368,17,247,4,0,
368,17,250,1,1,1
368,17,258,4,0,
368,17,263,4,0,
368,17,291,1,26,
368,17,346,1,5,
368,17,352,1,14,
368,17,392,1,19,
368,17,401,1,39,
368,17,416,4,0,
368,17,445,1,23,
368,17,489,1,45,
368,17,496,4,0,
368,17,503,4,0,
368,17,577,1,11,
368,17,590,4,0,
368,17,611,4,0,
368,18,56,1,50,
368,18,57,4,0,
368,18,58,4,0,
368,18,59,4,0,
368,18,63,4,0,
368,18,92,4,0,
368,18,93,1,1,2
368,18,94,1,34,
368,18,94,4,0,
368,18,97,1,9,
368,18,104,4,0,
368,18,127,4,0,
368,18,133,1,16,
368,18,156,4,0,
368,18,164,4,0,
368,18,182,4,0,
368,18,207,4,0,
368,18,213,4,0,
368,18,214,4,0,
368,18,216,4,0,
368,18,218,4,0,
368,18,219,4,0,
368,18,226,1,29,
368,18,237,4,0,
368,18,240,4,0,
368,18,244,4,0,
368,18,247,4,0,
368,18,250,1,1,1
368,18,258,4,0,
368,18,263,4,0,
368,18,291,1,26,
368,18,346,1,5,
368,18,352,1,14,
368,18,392,1,19,
368,18,401,1,39,
368,18,416,4,0,
368,18,445,1,23,
368,18,489,1,45,
368,18,496,4,0,
368,18,503,4,0,
368,18,577,1,11,
368,18,590,4,0,
368,18,611,4,0,
369,5,33,1,1,1
369,5,36,1,29,
369,5,38,1,57,
369,5,55,1,8,
369,5,56,1,64,
369,5,57,4,0,
369,5,58,4,0,
369,5,59,4,0,
369,5,63,4,0,
369,5,89,4,0,
369,5,92,4,0,
369,5,104,4,0,
369,5,106,1,1,2
369,5,127,4,0,
369,5,130,2,0,
369,5,133,2,0,
369,5,156,1,50,
369,5,156,4,0,
369,5,157,2,0,
369,5,182,4,0,
369,5,201,4,0,
369,5,213,4,0,
369,5,214,2,0,
369,5,216,4,0,
369,5,218,4,0,
369,5,219,4,0,
369,5,222,2,0,
369,5,237,4,0,
369,5,240,4,0,
369,5,246,1,43,
369,5,249,4,0,
369,5,258,4,0,
369,5,263,4,0,
369,5,281,1,22,
369,5,290,4,0,
369,5,291,4,0,
369,5,300,1,36,
369,5,317,1,15,
369,5,317,4,0,
369,5,346,2,0,
369,5,347,4,0,
369,5,352,4,0,
369,6,33,1,1,1
369,6,34,3,0,
369,6,36,1,29,
369,6,38,1,57,
369,6,38,3,0,
369,6,55,1,8,
369,6,56,1,64,
369,6,57,4,0,
369,6,58,4,0,
369,6,59,4,0,
369,6,63,4,0,
369,6,89,4,0,
369,6,92,4,0,
369,6,102,3,0,
369,6,104,4,0,
369,6,106,1,1,2
369,6,127,4,0,
369,6,130,2,0,
369,6,133,2,0,
369,6,156,1,50,
369,6,156,4,0,
369,6,157,2,0,
369,6,157,3,0,
369,6,164,3,0,
369,6,173,3,0,
369,6,182,4,0,
369,6,189,3,0,
369,6,196,3,0,
369,6,201,4,0,
369,6,203,3,0,
369,6,207,3,0,
369,6,213,4,0,
369,6,214,2,0,
369,6,214,3,0,
369,6,216,4,0,
369,6,218,4,0,
369,6,219,4,0,
369,6,222,2,0,
369,6,237,4,0,
369,6,240,4,0,
369,6,244,3,0,
369,6,246,1,43,
369,6,249,4,0,
369,6,258,4,0,
369,6,263,4,0,
369,6,281,1,22,
369,6,290,4,0,
369,6,291,4,0,
369,6,300,1,36,
369,6,317,1,15,
369,6,317,4,0,
369,6,346,2,0,
369,6,347,4,0,
369,6,352,4,0,
369,7,33,1,1,1
369,7,34,3,0,
369,7,36,1,29,
369,7,38,1,57,
369,7,38,3,0,
369,7,55,1,8,
369,7,56,1,64,
369,7,57,4,0,
369,7,58,4,0,
369,7,59,4,0,
369,7,63,4,0,
369,7,89,4,0,
369,7,92,4,0,
369,7,102,3,0,
369,7,104,4,0,
369,7,106,1,1,2
369,7,127,4,0,
369,7,130,2,0,
369,7,133,2,0,
369,7,156,1,50,
369,7,156,4,0,
369,7,157,2,0,
369,7,157,3,0,
369,7,164,3,0,
369,7,182,4,0,
369,7,201,4,0,
369,7,213,4,0,
369,7,214,2,0,
369,7,216,4,0,
369,7,218,4,0,
369,7,219,4,0,
369,7,222,2,0,
369,7,237,4,0,
369,7,240,4,0,
369,7,246,1,43,
369,7,249,4,0,
369,7,258,4,0,
369,7,263,4,0,
369,7,281,1,22,
369,7,290,4,0,
369,7,291,4,0,
369,7,300,1,36,
369,7,317,1,15,
369,7,317,4,0,
369,7,346,2,0,
369,7,347,4,0,
369,7,352,4,0,
369,8,33,1,1,1
369,8,36,1,29,
369,8,38,1,50,
369,8,55,1,8,
369,8,56,1,71,
369,8,57,4,0,
369,8,58,4,0,
369,8,59,4,0,
369,8,63,4,0,
369,8,89,4,0,
369,8,92,4,0,
369,8,104,4,0,
369,8,106,1,1,2
369,8,127,4,0,
369,8,130,2,0,
369,8,133,2,0,
369,8,156,1,64,
369,8,156,4,0,
369,8,157,2,0,
369,8,157,4,0,
369,8,164,4,0,
369,8,173,2,0,
369,8,182,4,0,
369,8,189,2,0,
369,8,201,4,0,
369,8,203,4,0,
369,8,207,4,0,
369,8,213,4,0,
369,8,214,2,0,
369,8,214,4,0,
369,8,216,4,0,
369,8,218,4,0,
369,8,219,4,0,
369,8,222,2,0,
369,8,237,4,0,
369,8,240,4,0,
369,8,244,4,0,
369,8,246,1,43,
369,8,249,4,0,
369,8,258,4,0,
369,8,263,4,0,
369,8,281,1,22,
369,8,290,4,0,
369,8,291,1,57,
369,8,300,1,36,
369,8,317,1,15,
369,8,317,4,0,
369,8,346,2,0,
369,8,347,4,0,
369,8,352,4,0,
369,8,362,4,0,
369,8,363,4,0,
369,8,397,4,0,
369,8,401,2,0,
369,8,416,4,0,
369,8,444,4,0,
369,8,445,4,0,
369,8,446,4,0,
369,8,457,1,78,
369,9,33,1,1,1
369,9,36,1,29,
369,9,38,1,50,
369,9,55,1,8,
369,9,56,1,71,
369,9,57,4,0,
369,9,58,4,0,
369,9,59,4,0,
369,9,63,4,0,
369,9,89,4,0,
369,9,92,4,0,
369,9,104,4,0,
369,9,106,1,1,2
369,9,127,4,0,
369,9,130,2,0,
369,9,133,2,0,
369,9,156,1,64,
369,9,156,4,0,
369,9,157,2,0,
369,9,157,4,0,
369,9,164,4,0,
369,9,173,2,0,
369,9,173,3,0,
369,9,182,4,0,
369,9,189,2,0,
369,9,189,3,0,
369,9,196,3,0,
369,9,201,4,0,
369,9,203,4,0,
369,9,207,4,0,
369,9,213,4,0,
369,9,214,2,0,
369,9,214,4,0,
369,9,216,4,0,
369,9,218,4,0,
369,9,219,4,0,
369,9,222,2,0,
369,9,237,4,0,
369,9,240,4,0,
369,9,244,4,0,
369,9,246,1,43,
369,9,246,3,0,
369,9,249,4,0,
369,9,258,4,0,
369,9,263,4,0,
369,9,281,1,22,
369,9,290,4,0,
369,9,291,1,57,
369,9,291,3,0,
369,9,300,1,36,
369,9,317,1,15,
369,9,317,4,0,
369,9,340,3,0,
369,9,346,2,0,
369,9,347,4,0,
369,9,352,4,0,
369,9,362,4,0,
369,9,363,4,0,
369,9,397,4,0,
369,9,401,2,0,
369,9,401,3,0,
369,9,414,3,0,
369,9,416,4,0,
369,9,444,4,0,
369,9,445,4,0,
369,9,446,4,0,
369,9,457,1,78,
369,10,29,3,0,
369,10,33,1,1,1
369,10,36,1,29,
369,10,38,1,50,
369,10,55,1,8,
369,10,56,1,71,
369,10,57,4,0,
369,10,58,4,0,
369,10,59,4,0,
369,10,63,4,0,
369,10,89,4,0,
369,10,92,4,0,
369,10,104,4,0,
369,10,106,1,1,2
369,10,127,4,0,
369,10,130,2,0,
369,10,133,2,0,
369,10,156,1,64,
369,10,156,4,0,
369,10,157,2,0,
369,10,157,4,0,
369,10,164,4,0,
369,10,173,2,0,
369,10,173,3,0,
369,10,182,4,0,
369,10,189,2,0,
369,10,189,3,0,
369,10,196,3,0,
369,10,201,4,0,
369,10,203,4,0,
369,10,207,4,0,
369,10,213,4,0,
369,10,214,2,0,
369,10,214,4,0,
369,10,216,4,0,
369,10,218,4,0,
369,10,219,4,0,
369,10,222,2,0,
369,10,237,4,0,
369,10,240,4,0,
369,10,244,4,0,
369,10,246,1,43,
369,10,246,3,0,
369,10,249,4,0,
369,10,250,4,0,
369,10,258,4,0,
369,10,263,4,0,
369,10,281,1,22,
369,10,290,4,0,
369,10,291,1,57,
369,10,291,3,0,
369,10,300,1,36,
369,10,317,1,15,
369,10,317,4,0,
369,10,330,2,0,
369,10,340,3,0,
369,10,346,2,0,
369,10,347,4,0,
369,10,352,4,0,
369,10,362,4,0,
369,10,363,4,0,
369,10,397,4,0,
369,10,401,2,0,
369,10,401,3,0,
369,10,414,3,0,
369,10,416,4,0,
369,10,444,4,0,
369,10,445,4,0,
369,10,446,4,0,
369,10,457,1,78,
369,11,33,1,1,1
369,11,36,1,29,
369,11,38,1,50,
369,11,55,1,8,
369,11,56,1,71,
369,11,57,4,0,
369,11,58,4,0,
369,11,59,4,0,
369,11,63,4,0,
369,11,89,4,0,
369,11,92,4,0,
369,11,104,4,0,
369,11,106,1,1,2
369,11,127,4,0,
369,11,130,2,0,
369,11,133,2,0,
369,11,156,1,64,
369,11,156,4,0,
369,11,157,4,0,
369,11,164,4,0,
369,11,173,2,0,
369,11,182,4,0,
369,11,189,2,0,
369,11,201,4,0,
369,11,207,4,0,
369,11,213,4,0,
369,11,214,2,0,
369,11,216,4,0,
369,11,218,4,0,
369,11,219,4,0,
369,11,222,2,0,
369,11,237,4,0,
369,11,240,4,0,
369,11,244,4,0,
369,11,246,1,43,
369,11,249,4,0,
369,11,258,4,0,
369,11,263,4,0,
369,11,281,1,22,
369,11,291,1,57,
369,11,291,4,0,
369,11,300,1,36,
369,11,317,1,15,
369,11,317,4,0,
369,11,330,2,0,
369,11,341,2,0,
369,11,346,2,0,
369,11,347,4,0,
369,11,362,2,0,
369,11,397,4,0,
369,11,401,2,0,
369,11,416,4,0,
369,11,428,2,0,
369,11,444,4,0,
369,11,457,1,78,
369,11,479,4,0,
369,11,496,4,0,
369,11,503,4,0,
369,11,523,4,0,
369,12,33,1,1,1
369,12,36,1,29,
369,12,38,1,57,
369,12,55,1,8,
369,12,56,1,64,
369,12,57,4,0,
369,12,58,4,0,
369,12,59,4,0,
369,12,63,4,0,
369,12,89,4,0,
369,12,92,4,0,
369,12,104,4,0,
369,12,106,1,1,2
369,12,127,4,0,
369,12,156,1,50,
369,12,156,4,0,
369,12,182,4,0,
369,12,201,4,0,
369,12,213,4,0,
369,12,216,4,0,
369,12,218,4,0,
369,12,219,4,0,
369,12,237,4,0,
369,12,240,4,0,
369,12,246,1,43,
369,12,249,4,0,
369,12,258,4,0,
369,12,263,4,0,
369,12,281,1,22,
369,12,290,4,0,
369,12,291,4,0,
369,12,300,1,36,
369,12,317,1,15,
369,12,317,4,0,
369,12,347,4,0,
369,12,352,4,0,
369,13,33,1,1,1
369,13,34,3,0,
369,13,36,1,29,
369,13,38,1,57,
369,13,38,3,0,
369,13,55,1,8,
369,13,56,1,64,
369,13,57,4,0,
369,13,58,4,0,
369,13,59,4,0,
369,13,63,4,0,
369,13,89,4,0,
369,13,92,4,0,
369,13,102,3,0,
369,13,104,4,0,
369,13,106,1,1,2
369,13,127,4,0,
369,13,156,1,50,
369,13,156,4,0,
369,13,164,3,0,
369,13,182,4,0,
369,13,196,3,0,
369,13,201,4,0,
369,13,207,3,0,
369,13,213,4,0,
369,13,216,4,0,
369,13,218,4,0,
369,13,219,4,0,
369,13,237,4,0,
369,13,240,4,0,
369,13,246,1,43,
369,13,249,4,0,
369,13,258,4,0,
369,13,263,4,0,
369,13,281,1,22,
369,13,290,4,0,
369,13,291,4,0,
369,13,300,1,36,
369,13,317,1,15,
369,13,317,4,0,
369,13,347,4,0,
369,13,352,4,0,
369,14,33,1,1,1
369,14,36,1,29,
369,14,38,1,50,
369,14,55,1,8,
369,14,56,1,71,
369,14,57,4,0,
369,14,58,4,0,
369,14,59,4,0,
369,14,63,4,0,
369,14,89,4,0,
369,14,92,4,0,
369,14,104,4,0,
369,14,106,1,1,2
369,14,127,4,0,
369,14,130,2,0,
369,14,133,2,0,
369,14,156,1,64,
369,14,156,4,0,
369,14,157,4,0,
369,14,164,4,0,
369,14,173,2,0,
369,14,173,3,0,
369,14,182,4,0,
369,14,189,2,0,
369,14,196,3,0,
369,14,201,4,0,
369,14,207,4,0,
369,14,213,4,0,
369,14,214,2,0,
369,14,214,3,0,
369,14,216,4,0,
369,14,218,4,0,
369,14,219,4,0,
369,14,222,2,0,
369,14,237,4,0,
369,14,240,4,0,
369,14,244,4,0,
369,14,246,1,43,
369,14,249,4,0,
369,14,258,4,0,
369,14,263,4,0,
369,14,281,1,22,
369,14,291,1,57,
369,14,291,4,0,
369,14,300,1,36,
369,14,317,1,15,
369,14,317,4,0,
369,14,330,2,0,
369,14,340,3,0,
369,14,341,2,0,
369,14,346,2,0,
369,14,347,4,0,
369,14,362,2,0,
369,14,397,4,0,
369,14,401,2,0,
369,14,401,3,0,
369,14,414,3,0,
369,14,416,4,0,
369,14,428,2,0,
369,14,428,3,0,
369,14,444,4,0,
369,14,446,3,0,
369,14,457,1,78,
369,14,479,4,0,
369,14,496,4,0,
369,14,503,4,0,
369,14,523,4,0,
369,15,33,1,1,5
369,15,36,1,29,
369,15,38,1,50,
369,15,55,1,8,
369,15,56,1,1,2
369,15,56,1,71,
369,15,57,4,0,
369,15,58,4,0,
369,15,59,4,0,
369,15,63,4,0,
369,15,89,4,0,
369,15,92,4,0,
369,15,104,4,0,
369,15,106,1,1,6
369,15,127,4,0,
369,15,130,2,0,
369,15,133,2,0,
369,15,156,1,64,
369,15,156,4,0,
369,15,157,4,0,
369,15,164,4,0,
369,15,173,2,0,
369,15,182,4,0,
369,15,189,2,0,
369,15,201,4,0,
369,15,207,4,0,
369,15,213,4,0,
369,15,214,2,0,
369,15,214,4,0,
369,15,216,4,0,
369,15,218,4,0,
369,15,219,4,0,
369,15,222,2,0,
369,15,237,4,0,
369,15,240,4,0,
369,15,244,4,0,
369,15,246,1,1,3
369,15,246,1,43,
369,15,249,4,0,
369,15,258,4,0,
369,15,263,4,0,
369,15,281,1,22,
369,15,291,1,57,
369,15,300,1,1,4
369,15,300,1,36,
369,15,317,1,15,
369,15,317,4,0,
369,15,330,2,0,
369,15,341,2,0,
369,15,346,2,0,
369,15,347,4,0,
369,15,362,2,0,
369,15,397,4,0,
369,15,401,2,0,
369,15,416,4,0,
369,15,428,2,0,
369,15,444,4,0,
369,15,457,1,1,1
369,15,457,1,78,
369,15,479,4,0,
369,15,496,4,0,
369,15,503,4,0,
369,15,523,4,0,
369,15,590,4,0,
369,16,33,1,1,2
369,16,36,1,31,1
369,16,38,1,50,1
369,16,55,1,1,5
369,16,55,1,10,1
369,16,56,1,46,1
369,16,57,4,0,
369,16,58,4,0,
369,16,59,4,0,
369,16,63,4,0,
369,16,89,4,0,
369,16,92,4,0,
369,16,104,4,0,
369,16,106,1,1,3
369,16,127,4,0,
369,16,130,2,0,
369,16,133,2,0,
369,16,156,1,41,1
369,16,156,4,0,
369,16,157,4,0,
369,16,164,4,0,
369,16,173,2,0,
369,16,173,3,0,
369,16,182,4,0,
369,16,189,2,0,
369,16,196,3,0,
369,16,201,4,0,
369,16,207,4,0,
369,16,213,4,0,
369,16,214,2,0,
369,16,214,4,0,
369,16,216,4,0,
369,16,218,4,0,
369,16,219,4,0,
369,16,222,2,0,
369,16,237,4,0,
369,16,240,4,0,
369,16,244,4,0,
369,16,246,1,21,1
369,16,249,4,0,
369,16,258,4,0,
369,16,263,4,0,
369,16,281,1,35,1
369,16,290,4,0,
369,16,291,1,26,1
369,16,291,4,0,
369,16,300,1,1,4
369,16,300,1,6,1
369,16,317,1,15,1
369,16,317,4,0,
369,16,330,2,0,
369,16,340,3,0,
369,16,341,2,0,
369,16,346,2,0,
369,16,347,4,0,
369,16,352,3,0,
369,16,362,2,0,
369,16,397,4,0,
369,16,401,2,0,
369,16,401,3,0,
369,16,414,3,0,
369,16,416,4,0,
369,16,428,2,0,
369,16,428,3,0,
369,16,444,4,0,
369,16,446,3,0,
369,16,457,1,1,1
369,16,457,1,56,1
369,16,479,4,0,
369,16,496,4,0,
369,16,503,4,0,
369,16,523,4,0,
369,16,590,4,0,
369,17,33,1,1,3
369,17,36,1,31,
369,17,38,1,50,
369,17,55,1,1,6
369,17,55,1,10,
369,17,56,1,46,
369,17,57,4,0,
369,17,58,4,0,
369,17,59,4,0,
369,17,63,4,0,
369,17,89,4,0,
369,17,92,4,0,
369,17,104,4,0,
369,17,106,1,1,4
369,17,127,4,0,
369,17,130,2,0,
369,17,133,2,0,
369,17,156,1,41,
369,17,156,4,0,
369,17,157,4,0,
369,17,164,4,0,
369,17,173,2,0,
369,17,175,1,1,1
369,17,182,4,0,
369,17,189,2,0,
369,17,201,4,0,
369,17,207,4,0,
369,17,213,4,0,
369,17,214,2,0,
369,17,214,4,0,
369,17,216,4,0,
369,17,218,4,0,
369,17,219,4,0,
369,17,222,2,0,
369,17,237,4,0,
369,17,240,4,0,
369,17,244,4,0,
369,17,246,1,21,
369,17,258,4,0,
369,17,263,4,0,
369,17,281,1,35,
369,17,291,1,26,
369,17,300,1,1,5
369,17,300,1,6,
369,17,317,1,15,
369,17,317,4,0,
369,17,330,2,0,
369,17,341,2,0,
369,17,346,2,0,
369,17,347,4,0,
369,17,362,2,0,
369,17,397,4,0,
369,17,401,2,0,
369,17,416,4,0,
369,17,428,2,0,
369,17,444,4,0,
369,17,457,1,1,2
369,17,457,1,56,
369,17,479,4,0,
369,17,496,4,0,
369,17,503,4,0,
369,17,523,4,0,
369,17,590,4,0,
369,18,33,1,1,3
369,18,36,1,31,
369,18,38,1,50,
369,18,55,1,1,6
369,18,55,1,10,
369,18,56,1,46,
369,18,57,4,0,
369,18,58,4,0,
369,18,59,4,0,
369,18,63,4,0,
369,18,89,4,0,
369,18,92,4,0,
369,18,104,4,0,
369,18,106,1,1,4
369,18,127,4,0,
369,18,130,2,0,
369,18,133,2,0,
369,18,156,1,41,
369,18,156,4,0,
369,18,157,4,0,
369,18,164,4,0,
369,18,173,2,0,
369,18,175,1,1,1
369,18,182,4,0,
369,18,189,2,0,
369,18,201,4,0,
369,18,207,4,0,
369,18,213,4,0,
369,18,214,2,0,
369,18,214,4,0,
369,18,216,4,0,
369,18,218,4,0,
369,18,219,4,0,
369,18,222,2,0,
369,18,237,4,0,
369,18,240,4,0,
369,18,244,4,0,
369,18,246,1,21,
369,18,258,4,0,
369,18,263,4,0,
369,18,281,1,35,
369,18,291,1,26,
369,18,300,1,1,5
369,18,300,1,6,
369,18,317,1,15,
369,18,317,4,0,
369,18,330,2,0,
369,18,341,2,0,
369,18,346,2,0,
369,18,347,4,0,
369,18,362,2,0,
369,18,397,4,0,
369,18,401,2,0,
369,18,416,4,0,
369,18,428,2,0,
369,18,444,4,0,
369,18,457,1,1,2
369,18,457,1,56,
369,18,479,4,0,
369,18,496,4,0,
369,18,503,4,0,
369,18,523,4,0,
369,18,590,4,0,
370,5,33,1,1,
370,5,36,1,24,
370,5,48,2,0,
370,5,55,1,12,
370,5,57,4,0,
370,5,58,4,0,
370,5,59,4,0,
370,5,92,4,0,
370,5,97,1,16,
370,5,104,4,0,
370,5,127,4,0,
370,5,150,2,0,
370,5,156,4,0,
370,5,175,1,40,
370,5,182,4,0,
370,5,186,1,36,
370,5,204,1,4,
370,5,213,1,28,
370,5,213,4,0,
370,5,216,4,0,
370,5,218,4,0,
370,5,219,1,48,
370,5,219,4,0,
370,5,237,4,0,
370,5,240,4,0,
370,5,258,4,0,
370,5,263,4,0,
370,5,290,4,0,
370,5,291,4,0,
370,5,300,2,0,
370,5,346,2,0,
370,5,352,4,0,
370,6,33,1,1,
370,6,36,1,24,
370,6,38,3,0,
370,6,48,2,0,
370,6,55,1,12,
370,6,57,4,0,
370,6,58,4,0,
370,6,59,4,0,
370,6,92,4,0,
370,6,97,1,16,
370,6,102,3,0,
370,6,104,4,0,
370,6,127,4,0,
370,6,129,3,0,
370,6,150,2,0,
370,6,156,4,0,
370,6,164,3,0,
370,6,173,3,0,
370,6,175,1,40,
370,6,182,4,0,
370,6,186,1,36,
370,6,196,3,0,
370,6,203,3,0,
370,6,204,1,4,
370,6,207,3,0,
370,6,213,1,28,
370,6,213,4,0,
370,6,214,3,0,
370,6,216,4,0,
370,6,218,4,0,
370,6,219,1,48,
370,6,219,4,0,
370,6,237,4,0,
370,6,240,4,0,
370,6,244,3,0,
370,6,258,4,0,
370,6,263,4,0,
370,6,290,4,0,
370,6,291,4,0,
370,6,300,2,0,
370,6,346,2,0,
370,6,352,4,0,
370,7,33,1,1,
370,7,36,1,24,
370,7,38,3,0,
370,7,48,2,0,
370,7,55,1,12,
370,7,57,4,0,
370,7,58,4,0,
370,7,59,4,0,
370,7,92,4,0,
370,7,97,1,16,
370,7,102,3,0,
370,7,104,4,0,
370,7,127,4,0,
370,7,150,2,0,
370,7,156,4,0,
370,7,164,3,0,
370,7,175,1,40,
370,7,182,4,0,
370,7,186,1,36,
370,7,204,1,4,
370,7,213,1,28,
370,7,213,4,0,
370,7,216,4,0,
370,7,218,4,0,
370,7,219,1,48,
370,7,219,4,0,
370,7,237,4,0,
370,7,240,4,0,
370,7,258,4,0,
370,7,263,4,0,
370,7,290,4,0,
370,7,291,4,0,
370,7,300,2,0,
370,7,346,2,0,
370,7,352,4,0,
370,8,33,1,1,
370,8,36,1,14,
370,8,48,2,0,
370,8,55,1,7,
370,8,57,4,0,
370,8,58,4,0,
370,8,59,4,0,
370,8,92,4,0,
370,8,97,1,9,
370,8,104,4,0,
370,8,127,4,0,
370,8,150,2,0,
370,8,156,4,0,
370,8,164,4,0,
370,8,175,1,46,
370,8,182,4,0,
370,8,186,1,27,
370,8,203,4,0,
370,8,204,1,4,
370,8,207,4,0,
370,8,213,1,22,
370,8,213,4,0,
370,8,214,4,0,
370,8,216,4,0,
370,8,218,4,0,
370,8,219,1,51,
370,8,219,4,0,
370,8,237,4,0,
370,8,240,4,0,
370,8,244,4,0,
370,8,258,4,0,
370,8,263,4,0,
370,8,290,4,0,
370,8,300,2,0,
370,8,346,2,0,
370,8,352,1,31,
370,8,352,4,0,
370,8,362,4,0,
370,8,363,4,0,
370,8,381,1,17,
370,8,392,1,37,
370,8,392,2,0,
370,8,445,1,40,
370,8,445,2,0,
370,8,445,4,0,
370,9,33,1,1,
370,9,36,1,14,
370,9,48,2,0,
370,9,55,1,7,
370,9,57,4,0,
370,9,58,4,0,
370,9,59,4,0,
370,9,92,4,0,
370,9,97,1,9,
370,9,104,4,0,
370,9,127,4,0,
370,9,129,3,0,
370,9,150,2,0,
370,9,156,4,0,
370,9,164,4,0,
370,9,173,3,0,
370,9,175,1,46,
370,9,182,4,0,
370,9,186,1,27,
370,9,196,3,0,
370,9,203,4,0,
370,9,204,1,4,
370,9,207,4,0,
370,9,213,1,22,
370,9,213,4,0,
370,9,214,4,0,
370,9,216,4,0,
370,9,218,4,0,
370,9,219,1,51,
370,9,219,4,0,
370,9,237,4,0,
370,9,240,4,0,
370,9,244,4,0,
370,9,258,4,0,
370,9,263,4,0,
370,9,290,4,0,
370,9,291,3,0,
370,9,300,2,0,
370,9,340,3,0,
370,9,346,2,0,
370,9,352,1,31,
370,9,352,4,0,
370,9,362,4,0,
370,9,363,4,0,
370,9,381,1,17,
370,9,392,1,37,
370,9,392,2,0,
370,9,445,1,40,
370,9,445,2,0,
370,9,445,4,0,
370,10,33,1,1,
370,10,36,1,14,
370,10,48,2,0,
370,10,55,1,7,
370,10,57,4,0,
370,10,58,4,0,
370,10,59,4,0,
370,10,92,4,0,
370,10,97,1,9,
370,10,104,4,0,
370,10,127,4,0,
370,10,129,3,0,
370,10,150,2,0,
370,10,156,4,0,
370,10,164,4,0,
370,10,173,3,0,
370,10,175,1,46,
370,10,182,4,0,
370,10,186,1,27,
370,10,196,3,0,
370,10,203,4,0,
370,10,204,1,4,
370,10,207,4,0,
370,10,213,1,22,
370,10,213,4,0,
370,10,214,4,0,
370,10,216,4,0,
370,10,218,4,0,
370,10,219,1,51,
370,10,219,4,0,
370,10,237,4,0,
370,10,240,4,0,
370,10,244,4,0,
370,10,250,4,0,
370,10,258,4,0,
370,10,263,4,0,
370,10,290,4,0,
370,10,291,3,0,
370,10,300,2,0,
370,10,340,3,0,
370,10,346,2,0,
370,10,352,1,31,
370,10,352,4,0,
370,10,362,4,0,
370,10,363,4,0,
370,10,381,1,17,
370,10,392,1,37,
370,10,392,2,0,
370,10,445,1,40,
370,10,445,2,0,
370,10,445,4,0,
370,10,453,2,0,
370,11,33,1,1,
370,11,36,1,14,
370,11,48,2,0,
370,11,55,1,7,
370,11,56,1,40,
370,11,57,4,0,
370,11,58,4,0,
370,11,59,4,0,
370,11,92,4,0,
370,11,97,1,9,
370,11,104,4,0,
370,11,127,4,0,
370,11,150,2,0,
370,11,156,4,0,
370,11,164,4,0,
370,11,175,1,31,
370,11,182,4,0,
370,11,186,1,37,
370,11,204,1,4,
370,11,207,4,0,
370,11,213,1,27,
370,11,213,4,0,
370,11,216,4,0,
370,11,218,4,0,
370,11,219,1,55,
370,11,219,4,0,
370,11,237,4,0,
370,11,240,4,0,
370,11,244,4,0,
370,11,258,4,0,
370,11,263,4,0,
370,11,291,4,0,
370,11,300,2,0,
370,11,346,2,0,
370,11,352,1,22,
370,11,362,2,0,
370,11,381,1,17,
370,11,392,1,46,
370,11,392,2,0,
370,11,445,1,51,
370,11,445,2,0,
370,11,453,2,0,
370,11,496,4,0,
370,11,503,4,0,
370,11,505,2,0,
370,12,33,1,1,
370,12,36,1,24,
370,12,55,1,12,
370,12,57,4,0,
370,12,58,4,0,
370,12,59,4,0,
370,12,92,4,0,
370,12,97,1,16,
370,12,104,4,0,
370,12,127,4,0,
370,12,156,4,0,
370,12,175,1,40,
370,12,182,4,0,
370,12,186,1,36,
370,12,204,1,4,
370,12,213,1,28,
370,12,213,4,0,
370,12,216,4,0,
370,12,218,4,0,
370,12,219,1,48,
370,12,219,4,0,
370,12,237,4,0,
370,12,240,4,0,
370,12,258,4,0,
370,12,263,4,0,
370,12,290,4,0,
370,12,291,4,0,
370,12,352,4,0,
370,13,33,1,1,
370,13,36,1,24,
370,13,38,3,0,
370,13,55,1,12,
370,13,57,4,0,
370,13,58,4,0,
370,13,59,4,0,
370,13,92,4,0,
370,13,97,1,16,
370,13,102,3,0,
370,13,104,4,0,
370,13,127,4,0,
370,13,156,4,0,
370,13,164,3,0,
370,13,175,1,40,
370,13,182,4,0,
370,13,186,1,36,
370,13,196,3,0,
370,13,204,1,4,
370,13,207,3,0,
370,13,213,1,28,
370,13,213,4,0,
370,13,216,4,0,
370,13,218,4,0,
370,13,219,1,48,
370,13,219,4,0,
370,13,237,4,0,
370,13,240,4,0,
370,13,258,4,0,
370,13,263,4,0,
370,13,290,4,0,
370,13,291,4,0,
370,13,352,4,0,
370,14,33,1,1,
370,14,36,1,14,
370,14,48,2,0,
370,14,55,1,7,
370,14,56,1,40,
370,14,57,4,0,
370,14,58,4,0,
370,14,59,4,0,
370,14,92,4,0,
370,14,97,1,9,
370,14,104,4,0,
370,14,127,4,0,
370,14,150,2,0,
370,14,156,4,0,
370,14,164,4,0,
370,14,173,3,0,
370,14,175,1,31,
370,14,182,4,0,
370,14,186,1,37,
370,14,196,3,0,
370,14,204,1,4,
370,14,207,4,0,
370,14,213,1,27,
370,14,213,4,0,
370,14,214,3,0,
370,14,216,4,0,
370,14,218,4,0,
370,14,219,1,55,
370,14,219,4,0,
370,14,237,4,0,
370,14,240,4,0,
370,14,244,4,0,
370,14,258,4,0,
370,14,263,4,0,
370,14,291,4,0,
370,14,300,2,0,
370,14,340,3,0,
370,14,346,2,0,
370,14,352,1,22,
370,14,362,2,0,
370,14,381,1,17,
370,14,392,1,46,
370,14,392,2,0,
370,14,445,1,51,
370,14,445,2,0,
370,14,453,2,0,
370,14,496,4,0,
370,14,503,4,0,
370,14,505,2,0,
370,15,33,1,1,
370,15,36,1,14,
370,15,48,2,0,
370,15,55,1,7,
370,15,56,1,40,
370,15,57,4,0,
370,15,58,4,0,
370,15,59,4,0,
370,15,92,4,0,
370,15,97,1,9,
370,15,104,4,0,
370,15,127,4,0,
370,15,150,2,0,
370,15,156,4,0,
370,15,164,4,0,
370,15,175,1,31,
370,15,182,4,0,
370,15,186,1,37,
370,15,204,1,4,
370,15,207,4,0,
370,15,213,1,27,
370,15,213,4,0,
370,15,214,4,0,
370,15,216,4,0,
370,15,218,4,0,
370,15,219,1,55,
370,15,219,4,0,
370,15,237,4,0,
370,15,240,4,0,
370,15,244,4,0,
370,15,258,4,0,
370,15,263,4,0,
370,15,300,2,0,
370,15,346,2,0,
370,15,352,1,22,
370,15,362,2,0,
370,15,381,1,17,
370,15,392,1,46,
370,15,392,2,0,
370,15,445,1,50,
370,15,445,2,0,
370,15,453,2,0,
370,15,494,2,0,
370,15,496,4,0,
370,15,503,4,0,
370,15,505,2,0,
370,15,590,4,0,
370,16,33,1,1,1
370,16,36,1,37,1
370,16,48,2,0,
370,16,55,1,4,1
370,16,56,1,50,1
370,16,57,4,0,
370,16,58,4,0,
370,16,59,4,0,
370,16,92,4,0,
370,16,97,1,7,1
370,16,104,4,0,
370,16,127,4,0,
370,16,150,2,0,
370,16,156,4,0,
370,16,164,4,0,
370,16,173,3,0,
370,16,175,1,27,1
370,16,182,4,0,
370,16,186,1,31,1
370,16,196,3,0,
370,16,204,1,1,2
370,16,207,4,0,
370,16,213,1,22,1
370,16,213,4,0,
370,16,214,4,0,
370,16,216,4,0,
370,16,218,4,0,
370,16,219,1,55,1
370,16,219,4,0,
370,16,237,4,0,
370,16,240,4,0,
370,16,244,4,0,
370,16,258,4,0,
370,16,263,4,0,
370,16,290,4,0,
370,16,291,4,0,
370,16,300,2,0,
370,16,340,3,0,
370,16,346,2,0,
370,16,352,1,17,1
370,16,352,3,0,
370,16,362,2,0,
370,16,381,1,14,1
370,16,392,1,40,1
370,16,392,2,0,
370,16,445,1,46,1
370,16,445,2,0,
370,16,453,2,0,
370,16,494,2,0,
370,16,496,4,0,
370,16,503,4,0,
370,16,505,2,0,
370,16,577,1,9,1
370,16,590,4,0,
370,17,33,1,1,1
370,17,36,1,34,
370,17,48,2,0,
370,17,55,1,4,
370,17,56,1,46,
370,17,57,4,0,
370,17,58,4,0,
370,17,59,4,0,
370,17,92,4,0,
370,17,97,1,7,
370,17,104,4,0,
370,17,127,4,0,
370,17,150,2,0,
370,17,156,4,0,
370,17,164,4,0,
370,17,175,1,26,
370,17,182,4,0,
370,17,186,1,31,
370,17,204,1,1,2
370,17,207,4,0,
370,17,213,1,20,
370,17,213,4,0,
370,17,214,4,0,
370,17,216,4,0,
370,17,218,4,0,
370,17,219,1,49,
370,17,219,4,0,
370,17,237,4,0,
370,17,240,4,0,
370,17,244,4,0,
370,17,258,4,0,
370,17,263,4,0,
370,17,300,2,0,
370,17,346,2,0,
370,17,352,1,17,
370,17,362,2,0,
370,17,381,1,13,
370,17,392,1,40,
370,17,392,2,0,
370,17,445,1,37,
370,17,445,2,0,
370,17,453,2,0,
370,17,487,1,42,
370,17,494,2,0,
370,17,496,4,0,
370,17,503,4,0,
370,17,505,2,0,
370,17,531,1,22,
370,17,577,1,9,
370,17,590,4,0,
370,18,33,1,1,1
370,18,36,1,34,
370,18,48,2,0,
370,18,55,1,4,
370,18,56,1,46,
370,18,57,4,0,
370,18,58,4,0,
370,18,59,4,0,
370,18,92,4,0,
370,18,97,1,7,
370,18,104,4,0,
370,18,127,4,0,
370,18,150,2,0,
370,18,156,4,0,
370,18,164,4,0,
370,18,175,1,26,
370,18,182,4,0,
370,18,186,1,31,
370,18,204,1,1,2
370,18,207,4,0,
370,18,213,1,20,
370,18,213,4,0,
370,18,214,4,0,
370,18,216,4,0,
370,18,218,4,0,
370,18,219,1,49,
370,18,219,4,0,
370,18,237,4,0,
370,18,240,4,0,
370,18,244,4,0,
370,18,258,4,0,
370,18,263,4,0,
370,18,300,2,0,
370,18,346,2,0,
370,18,352,1,17,
370,18,362,2,0,
370,18,381,1,13,
370,18,392,1,40,
370,18,392,2,0,
370,18,445,1,37,
370,18,445,2,0,
370,18,453,2,0,
370,18,487,1,42,
370,18,494,2,0,
370,18,496,4,0,
370,18,503,4,0,
370,18,505,2,0,
370,18,531,1,22,
370,18,577,1,9,
370,18,590,4,0,
371,5,15,4,0,
371,5,29,1,17,
371,5,37,2,0,
371,5,38,1,53,
371,5,43,1,9,
371,5,44,1,5,
371,5,46,4,0,
371,5,52,1,25,
371,5,53,4,0,
371,5,56,2,0,
371,5,70,4,0,
371,5,82,2,0,
371,5,92,4,0,
371,5,99,1,1,
371,5,104,4,0,
371,5,116,1,21,
371,5,126,4,0,
371,5,156,4,0,
371,5,182,4,0,
371,5,184,1,37,
371,5,213,4,0,
371,5,216,4,0,
371,5,218,4,0,
371,5,225,1,33,
371,5,237,4,0,
371,5,239,2,0,
371,5,240,4,0,
371,5,241,4,0,
371,5,242,1,41,
371,5,249,4,0,
371,5,263,4,0,
371,5,280,4,0,
371,5,290,4,0,
371,5,317,4,0,
371,5,332,4,0,
371,5,337,1,49,
371,5,337,4,0,
371,5,349,2,0,
371,6,15,4,0,
371,6,29,1,17,
371,6,34,3,0,
371,6,37,2,0,
371,6,38,1,53,
371,6,38,3,0,
371,6,43,1,9,
371,6,44,1,5,
371,6,46,4,0,
371,6,52,1,25,
371,6,53,4,0,
371,6,56,2,0,
371,6,70,4,0,
371,6,82,2,0,
371,6,92,4,0,
371,6,99,1,1,
371,6,102,3,0,
371,6,104,4,0,
371,6,116,1,21,
371,6,126,4,0,
371,6,156,4,0,
371,6,157,3,0,
371,6,164,3,0,
371,6,173,3,0,
371,6,182,4,0,
371,6,184,1,37,
371,6,189,3,0,
371,6,203,3,0,
371,6,207,3,0,
371,6,210,3,0,
371,6,213,4,0,
371,6,214,3,0,
371,6,216,4,0,
371,6,218,4,0,
371,6,225,1,33,
371,6,237,4,0,
371,6,239,2,0,
371,6,240,4,0,
371,6,241,4,0,
371,6,242,1,41,
371,6,249,4,0,
371,6,263,4,0,
371,6,280,4,0,
371,6,290,4,0,
371,6,317,4,0,
371,6,332,4,0,
371,6,337,1,49,
371,6,337,4,0,
371,6,349,2,0,
371,7,15,4,0,
371,7,29,1,17,
371,7,34,3,0,
371,7,37,2,0,
371,7,38,1,53,
371,7,38,3,0,
371,7,43,1,9,
371,7,44,1,5,
371,7,46,4,0,
371,7,52,1,25,
371,7,53,4,0,
371,7,56,2,0,
371,7,70,4,0,
371,7,82,2,0,
371,7,92,4,0,
371,7,99,1,1,
371,7,102,3,0,
371,7,104,4,0,
371,7,116,1,21,
371,7,126,4,0,
371,7,156,4,0,
371,7,157,3,0,
371,7,164,3,0,
371,7,182,4,0,
371,7,184,1,37,
371,7,213,4,0,
371,7,216,4,0,
371,7,218,4,0,
371,7,225,1,33,
371,7,237,4,0,
371,7,239,2,0,
371,7,240,4,0,
371,7,241,4,0,
371,7,242,1,41,
371,7,249,4,0,
371,7,263,4,0,
371,7,280,4,0,
371,7,290,4,0,
371,7,317,4,0,
371,7,332,4,0,
371,7,337,1,49,
371,7,337,4,0,
371,7,349,2,0,
371,8,15,4,0,
371,8,29,1,16,
371,8,37,2,0,
371,8,38,1,55,
371,8,43,1,10,
371,8,44,1,5,
371,8,46,4,0,
371,8,52,1,25,
371,8,53,4,0,
371,8,56,2,0,
371,8,70,4,0,
371,8,82,2,0,
371,8,92,4,0,
371,8,99,1,1,
371,8,104,4,0,
371,8,116,1,20,
371,8,126,4,0,
371,8,156,4,0,
371,8,157,4,0,
371,8,164,4,0,
371,8,182,4,0,
371,8,184,1,40,
371,8,203,4,0,
371,8,207,4,0,
371,8,213,4,0,
371,8,214,4,0,
371,8,216,4,0,
371,8,218,4,0,
371,8,225,1,31,
371,8,237,4,0,
371,8,239,2,0,
371,8,240,4,0,
371,8,241,4,0,
371,8,242,1,46,
371,8,249,4,0,
371,8,263,4,0,
371,8,280,4,0,
371,8,290,4,0,
371,8,317,4,0,
371,8,332,4,0,
371,8,337,1,50,
371,8,337,4,0,
371,8,349,2,0,
371,8,363,4,0,
371,8,406,4,0,
371,8,407,2,0,
371,8,421,2,0,
371,8,421,4,0,
371,8,424,2,0,
371,8,428,1,35,
371,8,434,3,0,
371,8,445,4,0,
371,9,15,4,0,
371,9,29,1,16,
371,9,37,2,0,
371,9,38,1,55,
371,9,43,1,10,
371,9,44,1,5,
371,9,46,4,0,
371,9,52,1,25,
371,9,53,4,0,
371,9,56,2,0,
371,9,70,4,0,
371,9,82,2,0,
371,9,92,4,0,
371,9,99,1,1,
371,9,104,4,0,
371,9,116,1,20,
371,9,126,4,0,
371,9,156,4,0,
371,9,157,4,0,
371,9,164,4,0,
371,9,173,3,0,
371,9,182,4,0,
371,9,184,1,40,
371,9,189,3,0,
371,9,200,3,0,
371,9,203,4,0,
371,9,207,4,0,
371,9,210,3,0,
371,9,213,4,0,
371,9,214,4,0,
371,9,216,4,0,
371,9,218,4,0,
371,9,225,1,31,
371,9,237,4,0,
371,9,239,2,0,
371,9,239,3,0,
371,9,240,4,0,
371,9,241,4,0,
371,9,242,1,46,
371,9,249,4,0,
371,9,263,4,0,
371,9,280,4,0,
371,9,290,4,0,
371,9,317,4,0,
371,9,332,4,0,
371,9,337,1,50,
371,9,337,4,0,
371,9,349,2,0,
371,9,363,4,0,
371,9,406,4,0,
371,9,407,2,0,
371,9,421,2,0,
371,9,421,4,0,
371,9,424,2,0,
371,9,428,1,35,
371,9,428,3,0,
371,9,434,3,0,
371,9,445,4,0,
371,10,15,4,0,
371,10,29,1,16,
371,10,29,3,0,
371,10,37,2,0,
371,10,38,1,55,
371,10,43,1,10,
371,10,44,1,5,
371,10,46,4,0,
371,10,52,1,25,
371,10,53,4,0,
371,10,56,2,0,
371,10,70,4,0,
371,10,82,2,0,
371,10,92,4,0,
371,10,99,1,1,
371,10,104,4,0,
371,10,116,1,20,
371,10,126,4,0,
371,10,156,4,0,
371,10,157,4,0,
371,10,164,4,0,
371,10,173,3,0,
371,10,182,4,0,
371,10,184,1,40,
371,10,189,3,0,
371,10,200,3,0,
371,10,203,4,0,
371,10,207,4,0,
371,10,210,3,0,
371,10,213,4,0,
371,10,214,4,0,
371,10,216,4,0,
371,10,218,4,0,
371,10,225,1,31,
371,10,237,4,0,
371,10,239,2,0,
371,10,239,3,0,
371,10,240,4,0,
371,10,241,4,0,
371,10,242,1,46,
371,10,249,4,0,
371,10,263,4,0,
371,10,280,4,0,
371,10,290,4,0,
371,10,317,4,0,
371,10,332,4,0,
371,10,337,1,50,
371,10,337,4,0,
371,10,349,2,0,
371,10,363,4,0,
371,10,406,4,0,
371,10,407,2,0,
371,10,421,2,0,
371,10,421,4,0,
371,10,424,2,0,
371,10,428,1,35,
371,10,428,3,0,
371,10,434,3,0,
371,10,445,4,0,
371,11,15,4,0,
371,11,29,1,16,
371,11,37,2,0,
371,11,38,1,55,
371,11,43,1,10,
371,11,44,1,5,
371,11,46,4,0,
371,11,52,1,25,
371,11,53,4,0,
371,11,56,2,0,
371,11,70,4,0,
371,11,82,2,0,
371,11,92,4,0,
371,11,99,1,1,
371,11,104,4,0,
371,11,111,2,0,
371,11,116,1,20,
371,11,126,4,0,
371,11,156,4,0,
371,11,157,4,0,
371,11,164,4,0,
371,11,182,4,0,
371,11,184,1,40,
371,11,203,2,0,
371,11,207,4,0,
371,11,213,4,0,
371,11,216,4,0,
371,11,218,4,0,
371,11,225,1,31,
371,11,237,4,0,
371,11,239,2,0,
371,11,240,4,0,
371,11,241,4,0,
371,11,242,1,46,
371,11,249,4,0,
371,11,263,4,0,
371,11,280,4,0,
371,11,317,4,0,
371,11,332,4,0,
371,11,337,1,50,
371,11,337,4,0,
371,11,349,2,0,
371,11,406,2,0,
371,11,407,2,0,
371,11,421,4,0,
371,11,424,2,0,
371,11,428,1,35,
371,11,434,3,0,
371,11,468,4,0,
371,11,496,4,0,
371,11,510,4,0,
371,12,15,4,0,
371,12,29,1,17,
371,12,38,1,53,
371,12,43,1,9,
371,12,44,1,5,
371,12,46,4,0,
371,12,52,1,25,
371,12,53,4,0,
371,12,70,4,0,
371,12,92,4,0,
371,12,99,1,1,
371,12,104,4,0,
371,12,116,1,21,
371,12,126,4,0,
371,12,156,4,0,
371,12,182,4,0,
371,12,184,1,37,
371,12,213,4,0,
371,12,216,4,0,
371,12,218,4,0,
371,12,225,1,33,
371,12,237,4,0,
371,12,240,4,0,
371,12,241,4,0,
371,12,242,1,41,
371,12,249,4,0,
371,12,263,4,0,
371,12,280,4,0,
371,12,290,4,0,
371,12,317,4,0,
371,12,332,4,0,
371,12,337,1,49,
371,12,337,4,0,
371,13,15,4,0,
371,13,29,1,17,
371,13,34,3,0,
371,13,38,1,53,
371,13,38,3,0,
371,13,43,1,9,
371,13,44,1,5,
371,13,46,4,0,
371,13,52,1,25,
371,13,53,4,0,
371,13,70,4,0,
371,13,92,4,0,
371,13,99,1,1,
371,13,102,3,0,
371,13,104,4,0,
371,13,116,1,21,
371,13,126,4,0,
371,13,156,4,0,
371,13,164,3,0,
371,13,182,4,0,
371,13,184,1,37,
371,13,207,3,0,
371,13,213,4,0,
371,13,216,4,0,
371,13,218,4,0,
371,13,225,1,33,
371,13,237,4,0,
371,13,240,4,0,
371,13,241,4,0,
371,13,242,1,41,
371,13,249,4,0,
371,13,263,4,0,
371,13,280,4,0,
371,13,290,4,0,
371,13,317,4,0,
371,13,332,4,0,
371,13,337,1,49,
371,13,337,4,0,
371,14,15,4,0,
371,14,29,1,16,
371,14,37,2,0,
371,14,38,1,55,
371,14,43,1,10,
371,14,44,1,5,
371,14,46,4,0,
371,14,52,1,25,
371,14,53,4,0,
371,14,56,2,0,
371,14,70,4,0,
371,14,82,2,0,
371,14,92,4,0,
371,14,99,1,1,
371,14,104,4,0,
371,14,111,2,0,
371,14,116,1,20,
371,14,126,4,0,
371,14,156,4,0,
371,14,157,4,0,
371,14,164,4,0,
371,14,173,3,0,
371,14,182,4,0,
371,14,184,1,40,
371,14,200,3,0,
371,14,203,2,0,
371,14,207,4,0,
371,14,213,4,0,
371,14,214,3,0,
371,14,216,4,0,
371,14,218,4,0,
371,14,225,1,31,
371,14,237,4,0,
371,14,239,2,0,
371,14,240,4,0,
371,14,241,4,0,
371,14,242,1,46,
371,14,249,4,0,
371,14,263,4,0,
371,14,280,4,0,
371,14,304,3,0,
371,14,317,4,0,
371,14,332,4,0,
371,14,337,1,50,
371,14,337,4,0,
371,14,349,2,0,
371,14,406,2,0,
371,14,406,3,0,
371,14,407,2,0,
371,14,421,4,0,
371,14,424,2,0,
371,14,428,1,35,
371,14,428,3,0,
371,14,434,3,0,
371,14,468,4,0,
371,14,496,4,0,
371,14,510,4,0,
371,15,15,4,0,
371,15,29,1,16,
371,15,37,2,0,
371,15,38,1,55,
371,15,43,1,10,
371,15,44,1,5,
371,15,46,4,0,
371,15,52,1,25,
371,15,53,4,0,
371,15,56,2,0,
371,15,70,4,0,
371,15,82,2,0,
371,15,92,4,0,
371,15,99,1,1,
371,15,104,4,0,
371,15,111,2,0,
371,15,116,1,20,
371,15,126,4,0,
371,15,156,4,0,
371,15,157,4,0,
371,15,164,4,0,
371,15,182,4,0,
371,15,184,1,40,
371,15,203,2,0,
371,15,207,4,0,
371,15,213,4,0,
371,15,214,4,0,
371,15,216,4,0,
371,15,218,4,0,
371,15,225,1,31,
371,15,237,4,0,
371,15,239,2,0,
371,15,240,4,0,
371,15,241,4,0,
371,15,242,1,46,
371,15,249,4,0,
371,15,263,4,0,
371,15,280,4,0,
371,15,317,4,0,
371,15,332,4,0,
371,15,337,1,50,
371,15,337,4,0,
371,15,349,2,0,
371,15,406,2,0,
371,15,407,2,0,
371,15,421,4,0,
371,15,424,2,0,
371,15,428,1,35,
371,15,434,3,0,
371,15,468,4,0,
371,15,496,4,0,
371,15,510,4,0,
371,15,590,4,0,
371,16,15,4,0,
371,16,29,1,17,1
371,16,37,2,0,
371,16,38,1,49,1
371,16,43,1,7,1
371,16,44,1,10,1
371,16,46,4,0,
371,16,52,1,4,1
371,16,53,1,44,1
371,16,53,4,0,
371,16,56,2,0,
371,16,70,4,0,
371,16,82,2,0,
371,16,92,4,0,
371,16,99,1,1,1
371,16,104,4,0,
371,16,111,2,0,
371,16,116,1,21,1
371,16,126,4,0,
371,16,156,4,0,
371,16,157,4,0,
371,16,164,4,0,
371,16,173,3,0,
371,16,182,4,0,
371,16,184,1,39,1
371,16,200,3,0,
371,16,203,2,0,
371,16,207,4,0,
371,16,213,4,0,
371,16,214,4,0,
371,16,216,4,0,
371,16,218,4,0,
371,16,225,1,13,1
371,16,237,4,0,
371,16,239,2,0,
371,16,240,4,0,
371,16,241,4,0,
371,16,242,1,25,1
371,16,249,4,0,
371,16,263,4,0,
371,16,280,4,0,
371,16,290,4,0,
371,16,304,3,0,
371,16,317,4,0,
371,16,332,4,0,
371,16,337,1,29,1
371,16,337,4,0,
371,16,349,2,0,
371,16,406,2,0,
371,16,406,3,0,
371,16,407,2,0,
371,16,421,4,0,
371,16,424,2,0,
371,16,428,1,34,1
371,16,428,3,0,
371,16,434,3,0,
371,16,468,4,0,
371,16,496,4,0,
371,16,510,4,0,
371,16,590,4,0,
371,17,29,1,17,
371,17,37,2,0,
371,17,38,1,49,
371,17,43,1,7,
371,17,44,1,10,
371,17,46,4,0,
371,17,52,1,4,
371,17,53,1,44,
371,17,53,4,0,
371,17,56,2,0,
371,17,82,2,0,
371,17,92,4,0,
371,17,99,1,1,
371,17,104,4,0,
371,17,111,2,0,
371,17,116,1,21,
371,17,126,4,0,
371,17,156,4,0,
371,17,157,4,0,
371,17,164,4,0,
371,17,182,4,0,
371,17,184,1,39,
371,17,203,2,0,
371,17,207,4,0,
371,17,213,4,0,
371,17,214,4,0,
371,17,216,4,0,
371,17,218,4,0,
371,17,225,1,13,
371,17,237,4,0,
371,17,239,2,0,
371,17,240,4,0,
371,17,241,4,0,
371,17,242,1,25,
371,17,263,4,0,
371,17,280,4,0,
371,17,317,4,0,
371,17,332,4,0,
371,17,337,1,29,
371,17,337,4,0,
371,17,349,2,0,
371,17,406,2,0,
371,17,407,2,0,
371,17,421,4,0,
371,17,424,2,0,
371,17,428,1,34,
371,17,434,3,0,
371,17,496,4,0,
371,17,590,4,0,
371,18,29,1,17,
371,18,37,2,0,
371,18,38,1,49,
371,18,43,1,7,
371,18,44,1,10,
371,18,46,4,0,
371,18,52,1,4,
371,18,53,1,44,
371,18,53,4,0,
371,18,56,2,0,
371,18,82,2,0,
371,18,92,4,0,
371,18,99,1,1,
371,18,104,4,0,
371,18,111,2,0,
371,18,116,1,21,
371,18,126,4,0,
371,18,156,4,0,
371,18,157,4,0,
371,18,164,4,0,
371,18,182,4,0,
371,18,184,1,39,
371,18,203,2,0,
371,18,207,4,0,
371,18,213,4,0,
371,18,214,4,0,
371,18,216,4,0,
371,18,218,4,0,
371,18,225,1,13,
371,18,237,4,0,
371,18,239,2,0,
371,18,240,4,0,
371,18,241,4,0,
371,18,242,1,25,
371,18,263,4,0,
371,18,280,4,0,
371,18,317,4,0,
371,18,332,4,0,
371,18,337,1,29,
371,18,337,4,0,
371,18,349,2,0,
371,18,406,2,0,
371,18,407,2,0,
371,18,421,4,0,
371,18,424,2,0,
371,18,428,1,34,
371,18,434,3,0,
371,18,496,4,0,
371,18,590,4,0,
372,5,15,4,0,
372,5,29,1,1,4
372,5,29,1,17,
372,5,38,1,78,
372,5,43,1,1,3
372,5,43,1,9,
372,5,44,1,1,2
372,5,44,1,5,
372,5,46,4,0,
372,5,52,1,25,
372,5,53,4,0,
372,5,70,4,0,
372,5,92,4,0,
372,5,99,1,1,1
372,5,104,4,0,
372,5,116,1,21,
372,5,126,4,0,
372,5,156,4,0,
372,5,182,1,30,
372,5,182,4,0,
372,5,184,1,47,
372,5,213,4,0,
372,5,216,4,0,
372,5,218,4,0,
372,5,225,1,38,
372,5,237,4,0,
372,5,240,4,0,
372,5,241,4,0,
372,5,242,1,56,
372,5,249,4,0,
372,5,263,4,0,
372,5,280,4,0,
372,5,290,4,0,
372,5,317,4,0,
372,5,332,4,0,
372,5,337,1,69,
372,5,337,4,0,
372,6,15,4,0,
372,6,29,1,1,4
372,6,29,1,17,
372,6,34,3,0,
372,6,38,1,78,
372,6,38,3,0,
372,6,43,1,1,3
372,6,43,1,9,
372,6,44,1,1,2
372,6,44,1,5,
372,6,46,4,0,
372,6,52,1,25,
372,6,53,4,0,
372,6,70,4,0,
372,6,92,4,0,
372,6,99,1,1,1
372,6,102,3,0,
372,6,104,4,0,
372,6,111,3,0,
372,6,116,1,21,
372,6,126,4,0,
372,6,156,4,0,
372,6,157,3,0,
372,6,164,3,0,
372,6,173,3,0,
372,6,182,1,30,
372,6,182,4,0,
372,6,184,1,47,
372,6,189,3,0,
372,6,203,3,0,
372,6,205,3,0,
372,6,207,3,0,
372,6,210,3,0,
372,6,213,4,0,
372,6,214,3,0,
372,6,216,4,0,
372,6,218,4,0,
372,6,225,1,38,
372,6,237,4,0,
372,6,240,4,0,
372,6,241,4,0,
372,6,242,1,56,
372,6,249,4,0,
372,6,263,4,0,
372,6,280,4,0,
372,6,290,4,0,
372,6,317,4,0,
372,6,332,4,0,
372,6,337,1,69,
372,6,337,4,0,
372,7,15,4,0,
372,7,29,1,1,4
372,7,29,1,17,
372,7,34,3,0,
372,7,38,1,78,
372,7,38,3,0,
372,7,43,1,1,3
372,7,43,1,9,
372,7,44,1,1,2
372,7,44,1,5,
372,7,46,4,0,
372,7,52,1,25,
372,7,53,4,0,
372,7,70,4,0,
372,7,92,4,0,
372,7,99,1,1,1
372,7,102,3,0,
372,7,104,4,0,
372,7,116,1,21,
372,7,126,4,0,
372,7,156,4,0,
372,7,157,3,0,
372,7,164,3,0,
372,7,182,1,30,
372,7,182,4,0,
372,7,184,1,47,
372,7,213,4,0,
372,7,216,4,0,
372,7,218,4,0,
372,7,225,1,38,
372,7,237,4,0,
372,7,240,4,0,
372,7,241,4,0,
372,7,242,1,56,
372,7,249,4,0,
372,7,263,4,0,
372,7,280,4,0,
372,7,290,4,0,
372,7,317,4,0,
372,7,332,4,0,
372,7,337,1,69,
372,7,337,4,0,
372,8,15,4,0,
372,8,29,1,1,4
372,8,29,1,16,
372,8,38,1,61,
372,8,43,1,1,3
372,8,43,1,10,
372,8,44,1,1,2
372,8,44,1,5,
372,8,46,4,0,
372,8,52,1,25,
372,8,53,4,0,
372,8,70,4,0,
372,8,92,4,0,
372,8,99,1,1,1
372,8,104,4,0,
372,8,116,1,20,
372,8,126,4,0,
372,8,156,4,0,
372,8,157,4,0,
372,8,164,4,0,
372,8,182,1,30,
372,8,182,4,0,
372,8,184,1,43,
372,8,203,4,0,
372,8,207,4,0,
372,8,213,4,0,
372,8,214,4,0,
372,8,216,4,0,
372,8,218,4,0,
372,8,225,1,32,
372,8,237,4,0,
372,8,240,4,0,
372,8,241,4,0,
372,8,242,1,50,
372,8,249,4,0,
372,8,263,4,0,
372,8,280,4,0,
372,8,290,4,0,
372,8,317,4,0,
372,8,332,4,0,
372,8,337,1,55,
372,8,337,4,0,
372,8,363,4,0,
372,8,406,4,0,
372,8,421,4,0,
372,8,428,1,37,
372,8,434,3,0,
372,8,445,4,0,
372,9,15,4,0,
372,9,29,1,1,4
372,9,29,1,16,
372,9,38,1,61,
372,9,43,1,1,3
372,9,43,1,10,
372,9,44,1,1,2
372,9,44,1,5,
372,9,46,4,0,
372,9,52,1,25,
372,9,53,4,0,
372,9,70,4,0,
372,9,92,4,0,
372,9,99,1,1,1
372,9,104,4,0,
372,9,116,1,20,
372,9,126,4,0,
372,9,156,4,0,
372,9,157,4,0,
372,9,164,4,0,
372,9,173,3,0,
372,9,182,1,30,
372,9,182,4,0,
372,9,184,1,43,
372,9,189,3,0,
372,9,200,3,0,
372,9,203,4,0,
372,9,205,3,0,
372,9,207,4,0,
372,9,210,3,0,
372,9,213,4,0,
372,9,214,4,0,
372,9,216,4,0,
372,9,218,4,0,
372,9,225,1,32,
372,9,237,4,0,
372,9,239,3,0,
372,9,240,4,0,
372,9,241,4,0,
372,9,242,1,50,
372,9,249,4,0,
372,9,263,4,0,
372,9,280,4,0,
372,9,290,4,0,
372,9,317,4,0,
372,9,332,4,0,
372,9,334,3,0,
372,9,337,1,55,
372,9,337,4,0,
372,9,363,4,0,
372,9,406,4,0,
372,9,421,4,0,
372,9,428,1,37,
372,9,428,3,0,
372,9,434,3,0,
372,9,445,4,0,
372,10,15,4,0,
372,10,29,1,1,4
372,10,29,1,16,
372,10,29,3,0,
372,10,38,1,61,
372,10,43,1,1,3
372,10,43,1,10,
372,10,44,1,1,2
372,10,44,1,5,
372,10,46,4,0,
372,10,52,1,25,
372,10,53,4,0,
372,10,70,4,0,
372,10,92,4,0,
372,10,99,1,1,1
372,10,104,4,0,
372,10,116,1,20,
372,10,126,4,0,
372,10,156,4,0,
372,10,157,4,0,
372,10,164,4,0,
372,10,173,3,0,
372,10,182,1,30,
372,10,182,4,0,
372,10,184,1,43,
372,10,189,3,0,
372,10,200,3,0,
372,10,203,4,0,
372,10,205,3,0,
372,10,207,4,0,
372,10,210,3,0,
372,10,213,4,0,
372,10,214,4,0,
372,10,216,4,0,
372,10,218,4,0,
372,10,225,1,32,
372,10,237,4,0,
372,10,239,3,0,
372,10,240,4,0,
372,10,241,4,0,
372,10,242,1,50,
372,10,249,4,0,
372,10,263,4,0,
372,10,280,4,0,
372,10,290,4,0,
372,10,317,4,0,
372,10,332,4,0,
372,10,334,3,0,
372,10,337,1,55,
372,10,337,4,0,
372,10,363,4,0,
372,10,406,4,0,
372,10,421,4,0,
372,10,428,1,37,
372,10,428,3,0,
372,10,434,3,0,
372,10,445,4,0,
372,11,15,4,0,
372,11,29,1,1,4
372,11,29,1,16,
372,11,38,1,61,
372,11,43,1,1,3
372,11,43,1,10,
372,11,44,1,1,2
372,11,44,1,5,
372,11,46,4,0,
372,11,52,1,25,
372,11,53,4,0,
372,11,70,4,0,
372,11,92,4,0,
372,11,99,1,1,1
372,11,104,4,0,
372,11,116,1,20,
372,11,126,4,0,
372,11,156,4,0,
372,11,157,4,0,
372,11,164,4,0,
372,11,182,1,30,
372,11,182,4,0,
372,11,184,1,43,
372,11,207,4,0,
372,11,213,4,0,
372,11,216,4,0,
372,11,218,4,0,
372,11,225,1,32,
372,11,237,4,0,
372,11,240,4,0,
372,11,241,4,0,
372,11,242,1,50,
372,11,249,4,0,
372,11,263,4,0,
372,11,280,4,0,
372,11,317,4,0,
372,11,332,4,0,
372,11,337,1,55,
372,11,337,4,0,
372,11,421,4,0,
372,11,428,1,37,
372,11,434,3,0,
372,11,468,4,0,
372,11,496,4,0,
372,11,510,4,0,
372,12,15,4,0,
372,12,29,1,1,4
372,12,29,1,17,
372,12,38,1,78,
372,12,43,1,1,3
372,12,43,1,9,
372,12,44,1,1,2
372,12,44,1,5,
372,12,46,4,0,
372,12,52,1,25,
372,12,53,4,0,
372,12,70,4,0,
372,12,92,4,0,
372,12,99,1,1,1
372,12,104,4,0,
372,12,116,1,21,
372,12,126,4,0,
372,12,156,4,0,
372,12,182,1,30,
372,12,182,4,0,
372,12,184,1,47,
372,12,213,4,0,
372,12,216,4,0,
372,12,218,4,0,
372,12,225,1,38,
372,12,237,4,0,
372,12,240,4,0,
372,12,241,4,0,
372,12,242,1,56,
372,12,249,4,0,
372,12,263,4,0,
372,12,280,4,0,
372,12,290,4,0,
372,12,317,4,0,
372,12,332,4,0,
372,12,337,1,69,
372,12,337,4,0,
372,13,15,4,0,
372,13,29,1,1,4
372,13,29,1,17,
372,13,34,3,0,
372,13,38,1,78,
372,13,38,3,0,
372,13,43,1,1,3
372,13,43,1,9,
372,13,44,1,1,2
372,13,44,1,5,
372,13,46,4,0,
372,13,52,1,25,
372,13,53,4,0,
372,13,70,4,0,
372,13,92,4,0,
372,13,99,1,1,1
372,13,102,3,0,
372,13,104,4,0,
372,13,116,1,21,
372,13,126,4,0,
372,13,156,4,0,
372,13,164,3,0,
372,13,182,1,30,
372,13,182,4,0,
372,13,184,1,47,
372,13,207,3,0,
372,13,213,4,0,
372,13,216,4,0,
372,13,218,4,0,
372,13,225,1,38,
372,13,237,4,0,
372,13,240,4,0,
372,13,241,4,0,
372,13,242,1,56,
372,13,249,4,0,
372,13,263,4,0,
372,13,280,4,0,
372,13,290,4,0,
372,13,317,4,0,
372,13,332,4,0,
372,13,337,1,69,
372,13,337,4,0,
372,14,15,4,0,
372,14,29,1,1,4
372,14,29,1,16,
372,14,38,1,61,
372,14,43,1,1,3
372,14,43,1,10,
372,14,44,1,1,2
372,14,44,1,5,
372,14,46,4,0,
372,14,52,1,25,
372,14,53,4,0,
372,14,70,4,0,
372,14,92,4,0,
372,14,99,1,1,1
372,14,104,4,0,
372,14,116,1,20,
372,14,126,4,0,
372,14,156,4,0,
372,14,157,4,0,
372,14,164,4,0,
372,14,173,3,0,
372,14,182,1,30,
372,14,182,4,0,
372,14,184,1,43,
372,14,200,3,0,
372,14,207,4,0,
372,14,213,4,0,
372,14,214,3,0,
372,14,216,4,0,
372,14,218,4,0,
372,14,225,1,32,
372,14,237,4,0,
372,14,240,4,0,
372,14,241,4,0,
372,14,242,1,50,
372,14,249,4,0,
372,14,263,4,0,
372,14,280,4,0,
372,14,304,3,0,
372,14,317,4,0,
372,14,332,4,0,
372,14,334,3,0,
372,14,337,1,55,
372,14,337,4,0,
372,14,406,3,0,
372,14,421,4,0,
372,14,428,1,37,
372,14,428,3,0,
372,14,434,3,0,
372,14,468,4,0,
372,14,496,4,0,
372,14,510,4,0,
372,15,15,4,0,
372,15,29,1,1,4
372,15,29,1,16,
372,15,38,1,61,
372,15,43,1,1,3
372,15,43,1,10,
372,15,44,1,1,2
372,15,44,1,5,
372,15,46,4,0,
372,15,52,1,25,
372,15,53,4,0,
372,15,70,4,0,
372,15,92,4,0,
372,15,99,1,1,1
372,15,104,4,0,
372,15,116,1,20,
372,15,126,4,0,
372,15,156,4,0,
372,15,157,4,0,
372,15,164,4,0,
372,15,182,1,30,
372,15,182,4,0,
372,15,184,1,43,
372,15,207,4,0,
372,15,213,4,0,
372,15,214,4,0,
372,15,216,4,0,
372,15,218,4,0,
372,15,225,1,32,
372,15,237,4,0,
372,15,240,4,0,
372,15,241,4,0,
372,15,242,1,50,
372,15,249,4,0,
372,15,263,4,0,
372,15,280,4,0,
372,15,317,4,0,
372,15,332,4,0,
372,15,337,1,55,
372,15,337,4,0,
372,15,421,4,0,
372,15,428,1,37,
372,15,434,3,0,
372,15,468,4,0,
372,15,496,4,0,
372,15,510,4,0,
372,15,590,4,0,
372,16,15,4,0,
372,16,29,1,17,1
372,16,38,1,56,1
372,16,43,1,1,3
372,16,43,1,7,1
372,16,44,1,1,4
372,16,44,1,10,1
372,16,46,4,0,
372,16,52,1,1,2
372,16,52,1,4,1
372,16,53,1,49,1
372,16,53,4,0,
372,16,70,4,0,
372,16,92,4,0,
372,16,99,1,1,1
372,16,104,4,0,
372,16,116,1,21,1
372,16,126,4,0,
372,16,156,4,0,
372,16,157,4,0,
372,16,164,4,0,
372,16,173,3,0,
372,16,182,1,30,1
372,16,182,4,0,
372,16,184,1,42,1
372,16,200,3,0,
372,16,207,4,0,
372,16,213,4,0,
372,16,214,4,0,
372,16,216,4,0,
372,16,218,4,0,
372,16,225,1,13,1
372,16,237,4,0,
372,16,240,4,0,
372,16,241,4,0,
372,16,242,1,25,1
372,16,249,4,0,
372,16,263,4,0,
372,16,280,4,0,
372,16,290,4,0,
372,16,304,3,0,
372,16,317,4,0,
372,16,332,4,0,
372,16,334,3,0,
372,16,337,1,29,1
372,16,337,4,0,
372,16,406,3,0,
372,16,421,4,0,
372,16,428,1,35,1
372,16,428,3,0,
372,16,434,3,0,
372,16,468,4,0,
372,16,496,4,0,
372,16,510,4,0,
372,16,590,4,0,
372,17,29,1,17,
372,17,38,1,56,
372,17,43,1,1,4
372,17,43,1,7,
372,17,44,1,1,5
372,17,44,1,10,
372,17,46,4,0,
372,17,52,1,1,3
372,17,52,1,4,
372,17,53,1,49,
372,17,53,4,0,
372,17,92,4,0,
372,17,99,1,1,2
372,17,104,4,0,
372,17,116,1,21,
372,17,126,4,0,
372,17,156,4,0,
372,17,157,4,0,
372,17,164,4,0,
372,17,182,1,0,
372,17,182,1,1,1
372,17,182,4,0,
372,17,184,1,42,
372,17,207,4,0,
372,17,213,4,0,
372,17,214,4,0,
372,17,216,4,0,
372,17,218,4,0,
372,17,225,1,13,
372,17,237,4,0,
372,17,240,4,0,
372,17,241,4,0,
372,17,242,1,25,
372,17,263,4,0,
372,17,280,4,0,
372,17,317,4,0,
372,17,332,4,0,
372,17,337,1,29,
372,17,337,4,0,
372,17,421,4,0,
372,17,428,1,35,
372,17,434,3,0,
372,17,496,4,0,
372,17,590,4,0,
372,18,29,1,17,
372,18,38,1,56,
372,18,43,1,1,4
372,18,43,1,7,
372,18,44,1,1,5
372,18,44,1,10,
372,18,46,4,0,
372,18,52,1,1,3
372,18,52,1,4,
372,18,53,1,49,
372,18,53,4,0,
372,18,92,4,0,
372,18,99,1,1,2
372,18,104,4,0,
372,18,116,1,21,
372,18,126,4,0,
372,18,156,4,0,
372,18,157,4,0,
372,18,164,4,0,
372,18,182,1,0,
372,18,182,1,1,1
372,18,182,4,0,
372,18,184,1,42,
372,18,207,4,0,
372,18,213,4,0,
372,18,214,4,0,
372,18,216,4,0,
372,18,218,4,0,
372,18,225,1,13,
372,18,237,4,0,
372,18,240,4,0,
372,18,241,4,0,
372,18,242,1,25,
372,18,263,4,0,
372,18,280,4,0,
372,18,317,4,0,
372,18,332,4,0,
372,18,337,1,29,
372,18,337,4,0,
372,18,421,4,0,
372,18,428,1,35,
372,18,434,3,0,
372,18,496,4,0,
372,18,590,4,0,
373,5,15,4,0,
373,5,19,1,50,
373,5,19,4,0,
373,5,29,1,1,4
373,5,29,1,17,
373,5,38,1,93,
373,5,43,1,1,3
373,5,43,1,9,
373,5,44,1,1,2
373,5,44,1,5,
373,5,46,4,0,
373,5,52,1,25,
373,5,53,4,0,
373,5,63,4,0,
373,5,70,4,0,
373,5,89,4,0,
373,5,92,4,0,
373,5,99,1,1,1
373,5,104,4,0,
373,5,116,1,21,
373,5,126,4,0,
373,5,156,4,0,
373,5,182,1,30,
373,5,182,4,0,
373,5,184,1,47,
373,5,211,4,0,
373,5,213,4,0,
373,5,216,4,0,
373,5,218,4,0,
373,5,225,1,38,
373,5,231,4,0,
373,5,237,4,0,
373,5,240,4,0,
373,5,241,4,0,
373,5,242,1,61,
373,5,249,4,0,
373,5,263,4,0,
373,5,280,4,0,
373,5,290,4,0,
373,5,317,4,0,
373,5,332,4,0,
373,5,337,1,79,
373,5,337,4,0,
373,6,15,4,0,
373,6,19,1,50,
373,6,19,4,0,
373,6,29,1,1,4
373,6,29,1,17,
373,6,34,3,0,
373,6,38,1,93,
373,6,38,3,0,
373,6,43,1,1,3
373,6,43,1,9,
373,6,44,1,1,2
373,6,44,1,5,
373,6,46,4,0,
373,6,52,1,25,
373,6,53,4,0,
373,6,63,4,0,
373,6,70,4,0,
373,6,89,4,0,
373,6,92,4,0,
373,6,99,1,1,1
373,6,102,3,0,
373,6,104,4,0,
373,6,111,3,0,
373,6,116,1,21,
373,6,126,4,0,
373,6,129,3,0,
373,6,156,4,0,
373,6,157,3,0,
373,6,164,3,0,
373,6,173,3,0,
373,6,182,1,30,
373,6,182,4,0,
373,6,184,1,47,
373,6,189,3,0,
373,6,203,3,0,
373,6,205,3,0,
373,6,207,3,0,
373,6,210,3,0,
373,6,211,4,0,
373,6,213,4,0,
373,6,214,3,0,
373,6,216,4,0,
373,6,218,4,0,
373,6,225,1,38,
373,6,231,4,0,
373,6,237,4,0,
373,6,240,4,0,
373,6,241,4,0,
373,6,242,1,61,
373,6,249,4,0,
373,6,263,4,0,
373,6,280,4,0,
373,6,290,4,0,
373,6,317,4,0,
373,6,332,4,0,
373,6,337,1,79,
373,6,337,4,0,
373,7,15,4,0,
373,7,19,1,50,
373,7,19,4,0,
373,7,29,1,1,4
373,7,29,1,17,
373,7,34,3,0,
373,7,38,1,93,
373,7,38,3,0,
373,7,43,1,1,3
373,7,43,1,9,
373,7,44,1,1,2
373,7,44,1,5,
373,7,46,4,0,
373,7,52,1,25,
373,7,53,4,0,
373,7,63,4,0,
373,7,70,4,0,
373,7,89,4,0,
373,7,92,4,0,
373,7,99,1,1,1
373,7,102,3,0,
373,7,104,4,0,
373,7,116,1,21,
373,7,126,4,0,
373,7,156,4,0,
373,7,157,3,0,
373,7,164,3,0,
373,7,182,1,30,
373,7,182,4,0,
373,7,184,1,47,
373,7,211,4,0,
373,7,213,4,0,
373,7,216,4,0,
373,7,218,4,0,
373,7,225,1,38,
373,7,231,4,0,
373,7,237,4,0,
373,7,240,4,0,
373,7,241,4,0,
373,7,242,1,61,
373,7,249,4,0,
373,7,263,4,0,
373,7,280,4,0,
373,7,290,4,0,
373,7,317,4,0,
373,7,332,4,0,
373,7,337,1,79,
373,7,337,4,0,
373,8,15,4,0,
373,8,19,1,50,
373,8,19,4,0,
373,8,29,1,1,6
373,8,29,1,16,
373,8,38,1,70,
373,8,43,1,1,5
373,8,43,1,10,
373,8,44,1,1,4
373,8,44,1,5,
373,8,46,4,0,
373,8,52,1,25,
373,8,53,4,0,
373,8,63,4,0,
373,8,70,4,0,
373,8,89,4,0,
373,8,92,4,0,
373,8,99,1,1,3
373,8,104,4,0,
373,8,116,1,20,
373,8,126,4,0,
373,8,156,4,0,
373,8,157,4,0,
373,8,164,4,0,
373,8,182,1,30,
373,8,182,4,0,
373,8,184,1,43,
373,8,203,4,0,
373,8,207,4,0,
373,8,211,4,0,
373,8,213,4,0,
373,8,214,4,0,
373,8,216,4,0,
373,8,218,4,0,
373,8,225,1,32,
373,8,231,4,0,
373,8,237,4,0,
373,8,240,4,0,
373,8,241,4,0,
373,8,242,1,53,
373,8,249,4,0,
373,8,263,4,0,
373,8,280,4,0,
373,8,290,4,0,
373,8,317,4,0,
373,8,332,4,0,
373,8,337,1,61,
373,8,337,4,0,
373,8,355,4,0,
373,8,363,4,0,
373,8,406,4,0,
373,8,416,4,0,
373,8,421,4,0,
373,8,422,1,1,2
373,8,424,1,1,1
373,8,428,1,37,
373,8,432,4,0,
373,8,434,3,0,
373,8,444,4,0,
373,8,445,4,0,
373,9,15,4,0,
373,9,19,1,50,
373,9,19,4,0,
373,9,29,1,1,6
373,9,29,1,16,
373,9,38,1,70,
373,9,43,1,1,5
373,9,43,1,10,
373,9,44,1,1,4
373,9,44,1,5,
373,9,46,4,0,
373,9,52,1,25,
373,9,53,4,0,
373,9,63,4,0,
373,9,70,4,0,
373,9,89,4,0,
373,9,92,4,0,
373,9,99,1,1,3
373,9,104,4,0,
373,9,116,1,20,
373,9,126,4,0,
373,9,129,3,0,
373,9,156,4,0,
373,9,157,4,0,
373,9,164,4,0,
373,9,173,3,0,
373,9,182,1,30,
373,9,182,4,0,
373,9,184,1,43,
373,9,189,3,0,
373,9,200,3,0,
373,9,203,4,0,
373,9,205,3,0,
373,9,207,4,0,
373,9,210,3,0,
373,9,211,4,0,
373,9,213,4,0,
373,9,214,4,0,
373,9,216,4,0,
373,9,218,4,0,
373,9,225,1,32,
373,9,231,4,0,
373,9,237,4,0,
373,9,239,3,0,
373,9,240,4,0,
373,9,241,4,0,
373,9,242,1,53,
373,9,249,4,0,
373,9,257,3,0,
373,9,263,4,0,
373,9,280,4,0,
373,9,290,4,0,
373,9,314,3,0,
373,9,317,4,0,
373,9,332,4,0,
373,9,337,1,61,
373,9,337,4,0,
373,9,355,4,0,
373,9,363,4,0,
373,9,401,3,0,
373,9,406,4,0,
373,9,416,4,0,
373,9,421,4,0,
373,9,422,1,1,2
373,9,424,1,1,1
373,9,428,1,37,
373,9,428,3,0,
373,9,432,4,0,
373,9,434,3,0,
373,9,444,4,0,
373,9,445,4,0,
373,9,466,3,0,
373,10,15,4,0,
373,10,19,1,50,
373,10,19,4,0,
373,10,29,1,1,6
373,10,29,1,16,
373,10,29,3,0,
373,10,38,1,70,
373,10,43,1,1,5
373,10,43,1,10,
373,10,44,1,1,4
373,10,44,1,5,
373,10,46,4,0,
373,10,52,1,25,
373,10,53,4,0,
373,10,63,4,0,
373,10,70,4,0,
373,10,89,4,0,
373,10,92,4,0,
373,10,99,1,1,3
373,10,104,4,0,
373,10,116,1,20,
373,10,126,4,0,
373,10,129,3,0,
373,10,156,4,0,
373,10,157,4,0,
373,10,164,4,0,
373,10,173,3,0,
373,10,182,1,30,
373,10,182,4,0,
373,10,184,1,43,
373,10,189,3,0,
373,10,200,3,0,
373,10,203,4,0,
373,10,205,3,0,
373,10,207,4,0,
373,10,210,3,0,
373,10,211,4,0,
373,10,213,4,0,
373,10,214,4,0,
373,10,216,4,0,
373,10,218,4,0,
373,10,225,1,32,
373,10,231,4,0,
373,10,237,4,0,
373,10,239,3,0,
373,10,240,4,0,
373,10,241,4,0,
373,10,242,1,53,
373,10,249,4,0,
373,10,257,3,0,
373,10,263,4,0,
373,10,280,4,0,
373,10,290,4,0,
373,10,314,3,0,
373,10,317,4,0,
373,10,332,4,0,
373,10,337,1,61,
373,10,337,4,0,
373,10,355,4,0,
373,10,363,4,0,
373,10,366,3,0,
373,10,401,3,0,
373,10,406,4,0,
373,10,416,4,0,
373,10,421,4,0,
373,10,422,1,1,2
373,10,424,1,1,1
373,10,428,1,37,
373,10,428,3,0,
373,10,434,3,0,
373,10,444,4,0,
373,10,445,4,0,
373,10,466,3,0,
373,11,15,4,0,
373,11,19,1,50,
373,11,19,4,0,
373,11,29,1,1,6
373,11,29,1,16,
373,11,38,1,70,
373,11,43,1,1,5
373,11,43,1,10,
373,11,44,1,1,4
373,11,44,1,5,
373,11,46,4,0,
373,11,52,1,25,
373,11,53,4,0,
373,11,63,4,0,
373,11,70,4,0,
373,11,89,4,0,
373,11,92,4,0,
373,11,99,1,1,3
373,11,104,4,0,
373,11,116,1,20,
373,11,126,4,0,
373,11,156,4,0,
373,11,157,4,0,
373,11,164,4,0,
373,11,182,1,30,
373,11,182,4,0,
373,11,184,1,43,
373,11,207,4,0,
373,11,213,4,0,
373,11,216,4,0,
373,11,218,4,0,
373,11,225,1,32,
373,11,237,4,0,
373,11,240,4,0,
373,11,241,4,0,
373,11,242,1,53,
373,11,249,4,0,
373,11,263,4,0,
373,11,280,4,0,
373,11,317,4,0,
373,11,332,4,0,
373,11,337,1,61,
373,11,337,4,0,
373,11,416,4,0,
373,11,421,4,0,
373,11,422,1,1,2
373,11,424,1,1,1
373,11,428,1,37,
373,11,434,3,0,
373,11,444,4,0,
373,11,468,4,0,
373,11,496,4,0,
373,11,510,4,0,
373,11,523,4,0,
373,11,525,1,80,
373,11,525,4,0,
373,12,15,4,0,
373,12,19,1,50,
373,12,19,4,0,
373,12,29,1,1,4
373,12,29,1,17,
373,12,38,1,93,
373,12,43,1,1,3
373,12,43,1,9,
373,12,44,1,1,2
373,12,44,1,5,
373,12,46,4,0,
373,12,52,1,25,
373,12,53,4,0,
373,12,63,4,0,
373,12,70,4,0,
373,12,89,4,0,
373,12,92,4,0,
373,12,99,1,1,1
373,12,104,4,0,
373,12,116,1,21,
373,12,126,4,0,
373,12,156,4,0,
373,12,182,1,30,
373,12,182,4,0,
373,12,184,1,47,
373,12,211,4,0,
373,12,213,4,0,
373,12,216,4,0,
373,12,218,4,0,
373,12,225,1,38,
373,12,231,4,0,
373,12,237,4,0,
373,12,240,4,0,
373,12,241,4,0,
373,12,242,1,61,
373,12,249,4,0,
373,12,263,4,0,
373,12,280,4,0,
373,12,290,4,0,
373,12,317,4,0,
373,12,332,4,0,
373,12,337,1,79,
373,12,337,4,0,
373,13,15,4,0,
373,13,19,1,50,
373,13,19,4,0,
373,13,29,1,1,4
373,13,29,1,17,
373,13,34,3,0,
373,13,38,1,93,
373,13,38,3,0,
373,13,43,1,1,3
373,13,43,1,9,
373,13,44,1,1,2
373,13,44,1,5,
373,13,46,4,0,
373,13,52,1,25,
373,13,53,4,0,
373,13,63,4,0,
373,13,70,4,0,
373,13,89,4,0,
373,13,92,4,0,
373,13,99,1,1,1
373,13,102,3,0,
373,13,104,4,0,
373,13,116,1,21,
373,13,126,4,0,
373,13,156,4,0,
373,13,164,3,0,
373,13,182,1,30,
373,13,182,4,0,
373,13,184,1,47,
373,13,207,3,0,
373,13,211,4,0,
373,13,213,4,0,
373,13,216,4,0,
373,13,218,4,0,
373,13,225,1,38,
373,13,231,4,0,
373,13,237,4,0,
373,13,240,4,0,
373,13,241,4,0,
373,13,242,1,61,
373,13,249,4,0,
373,13,263,4,0,
373,13,280,4,0,
373,13,290,4,0,
373,13,317,4,0,
373,13,332,4,0,
373,13,337,1,79,
373,13,337,4,0,
373,14,15,4,0,
373,14,19,1,50,
373,14,19,4,0,
373,14,29,1,1,6
373,14,29,1,16,
373,14,38,1,70,
373,14,43,1,1,5
373,14,43,1,10,
373,14,44,1,1,4
373,14,44,1,5,
373,14,46,4,0,
373,14,52,1,25,
373,14,53,4,0,
373,14,63,4,0,
373,14,70,4,0,
373,14,89,4,0,
373,14,92,4,0,
373,14,99,1,1,3
373,14,104,4,0,
373,14,116,1,20,
373,14,126,4,0,
373,14,156,4,0,
373,14,157,4,0,
373,14,164,4,0,
373,14,173,3,0,
373,14,182,1,30,
373,14,182,4,0,
373,14,184,1,43,
373,14,200,3,0,
373,14,207,4,0,
373,14,213,4,0,
373,14,214,3,0,
373,14,216,4,0,
373,14,218,4,0,
373,14,225,1,32,
373,14,231,3,0,
373,14,237,4,0,
373,14,240,4,0,
373,14,241,4,0,
373,14,242,1,53,
373,14,249,4,0,
373,14,257,3,0,
373,14,263,4,0,
373,14,280,4,0,
373,14,304,3,0,
373,14,317,4,0,
373,14,332,4,0,
373,14,337,1,61,
373,14,337,4,0,
373,14,355,3,0,
373,14,366,3,0,
373,14,401,3,0,
373,14,406,3,0,
373,14,416,4,0,
373,14,421,4,0,
373,14,422,1,1,2
373,14,424,1,1,1
373,14,428,1,37,
373,14,428,3,0,
373,14,434,3,0,
373,14,444,4,0,
373,14,468,4,0,
373,14,496,4,0,
373,14,510,4,0,
373,14,523,4,0,
373,14,525,1,80,
373,14,525,4,0,
373,15,15,4,0,
373,15,19,1,50,
373,15,19,4,0,
373,15,29,1,1,7
373,15,29,1,16,
373,15,38,1,1,1
373,15,38,1,70,
373,15,43,1,1,6
373,15,43,1,10,
373,15,44,1,1,5
373,15,44,1,5,
373,15,46,4,0,
373,15,52,1,25,
373,15,53,4,0,
373,15,63,4,0,
373,15,70,4,0,
373,15,89,4,0,
373,15,92,4,0,
373,15,99,1,1,4
373,15,104,4,0,
373,15,116,1,20,
373,15,126,4,0,
373,15,156,4,0,
373,15,157,4,0,
373,15,164,4,0,
373,15,182,1,30,
373,15,182,4,0,
373,15,184,1,43,
373,15,207,4,0,
373,15,211,4,0,
373,15,213,4,0,
373,15,214,4,0,
373,15,216,4,0,
373,15,218,4,0,
373,15,225,1,32,
373,15,237,4,0,
373,15,240,4,0,
373,15,241,4,0,
373,15,242,1,53,
373,15,249,4,0,
373,15,263,4,0,
373,15,280,4,0,
373,15,317,4,0,
373,15,332,4,0,
373,15,337,1,61,
373,15,337,4,0,
373,15,355,4,0,
373,15,416,4,0,
373,15,421,4,0,
373,15,422,1,1,3
373,15,424,1,1,2
373,15,428,1,37,
373,15,434,3,0,
373,15,444,4,0,
373,15,468,4,0,
373,15,496,4,0,
373,15,510,4,0,
373,15,523,4,0,
373,15,525,1,80,
373,15,525,4,0,
373,15,590,4,0,
373,16,15,4,0,
373,16,19,1,50,1
373,16,19,4,0,
373,16,29,1,17,1
373,16,38,1,63,1
373,16,43,1,1,6
373,16,43,1,7,1
373,16,44,1,1,7
373,16,44,1,10,1
373,16,46,4,0,
373,16,52,1,1,5
373,16,52,1,4,1
373,16,53,1,49,1
373,16,53,4,0,
373,16,63,4,0,
373,16,70,4,0,
373,16,89,4,0,
373,16,92,4,0,
373,16,99,1,1,4
373,16,104,4,0,
373,16,116,1,21,1
373,16,126,4,0,
373,16,156,4,0,
373,16,157,4,0,
373,16,164,4,0,
373,16,173,3,0,
373,16,182,1,30,1
373,16,182,4,0,
373,16,184,1,42,1
373,16,200,3,0,
373,16,207,4,0,
373,16,211,4,0,
373,16,213,4,0,
373,16,214,4,0,
373,16,216,4,0,
373,16,218,4,0,
373,16,225,1,13,1
373,16,231,3,0,
373,16,237,4,0,
373,16,240,4,0,
373,16,241,4,0,
373,16,242,1,25,1
373,16,249,4,0,
373,16,257,3,0,
373,16,263,4,0,
373,16,280,4,0,
373,16,290,4,0,
373,16,304,3,0,
373,16,317,4,0,
373,16,332,4,0,
373,16,337,1,29,1
373,16,337,4,0,
373,16,355,4,0,
373,16,366,3,0,
373,16,401,3,0,
373,16,406,3,0,
373,16,416,4,0,
373,16,421,4,0,
373,16,422,1,1,3
373,16,424,1,1,2
373,16,428,1,35,1
373,16,428,3,0,
373,16,434,3,0,
373,16,444,4,0,
373,16,468,4,0,
373,16,496,4,0,
373,16,510,4,0,
373,16,523,4,0,
373,16,525,1,1,1
373,16,525,4,0,
373,16,590,4,0,
373,17,19,1,0,
373,17,19,1,1,1
373,17,19,4,0,
373,17,29,1,17,
373,17,38,1,63,
373,17,43,1,1,8
373,17,43,1,7,
373,17,44,1,1,9
373,17,44,1,10,
373,17,46,4,0,
373,17,52,1,1,7
373,17,52,1,4,
373,17,53,1,49,
373,17,53,4,0,
373,17,63,4,0,
373,17,89,4,0,
373,17,92,4,0,
373,17,99,1,1,6
373,17,104,4,0,
373,17,116,1,21,
373,17,126,4,0,
373,17,156,4,0,
373,17,157,4,0,
373,17,164,4,0,
373,17,182,1,1,2
373,17,182,4,0,
373,17,184,1,42,
373,17,207,4,0,
373,17,211,4,0,
373,17,213,4,0,
373,17,214,4,0,
373,17,216,4,0,
373,17,218,4,0,
373,17,225,1,13,
373,17,237,4,0,
373,17,240,4,0,
373,17,241,4,0,
373,17,242,1,25,
373,17,263,4,0,
373,17,280,4,0,
373,17,317,4,0,
373,17,332,4,0,
373,17,337,1,29,
373,17,337,4,0,
373,17,355,4,0,
373,17,416,4,0,
373,17,421,4,0,
373,17,422,1,1,5
373,17,424,1,1,4
373,17,428,1,35,
373,17,434,3,0,
373,17,444,4,0,
373,17,496,4,0,
373,17,523,4,0,
373,17,525,1,1,3
373,17,525,4,0,
373,17,590,4,0,
373,17,693,4,0,
373,18,19,1,0,
373,18,19,1,1,1
373,18,19,4,0,
373,18,29,1,17,
373,18,38,1,63,
373,18,43,1,1,8
373,18,43,1,7,
373,18,44,1,1,9
373,18,44,1,10,
373,18,46,4,0,
373,18,52,1,1,7
373,18,52,1,4,
373,18,53,1,49,
373,18,53,4,0,
373,18,63,4,0,
373,18,89,4,0,
373,18,92,4,0,
373,18,99,1,1,6
373,18,104,4,0,
373,18,116,1,21,
373,18,126,4,0,
373,18,156,4,0,
373,18,157,4,0,
373,18,164,4,0,
373,18,182,1,1,2
373,18,182,4,0,
373,18,184,1,42,
373,18,207,4,0,
373,18,211,4,0,
373,18,213,4,0,
373,18,214,4,0,
373,18,216,4,0,
373,18,218,4,0,
373,18,225,1,13,
373,18,237,4,0,
373,18,240,4,0,
373,18,241,4,0,
373,18,242,1,25,
373,18,263,4,0,
373,18,280,4,0,
373,18,317,4,0,
373,18,332,4,0,
373,18,337,1,29,
373,18,337,4,0,
373,18,355,4,0,
373,18,416,4,0,
373,18,421,4,0,
373,18,422,1,1,5
373,18,424,1,1,4
373,18,428,1,35,
373,18,434,3,0,
373,18,444,4,0,
373,18,496,4,0,
373,18,523,4,0,
373,18,525,1,1,3
373,18,525,4,0,
373,18,590,4,0,
373,18,693,4,0,
374,5,36,1,1,
374,6,36,1,1,
374,7,36,1,1,
374,8,36,1,1,
374,9,36,1,1,
374,9,334,3,0,
374,9,428,3,0,
374,9,442,3,0,
374,10,29,3,0,
374,10,36,1,1,
374,10,334,3,0,
374,10,428,3,0,
374,10,442,3,0,
374,11,36,1,1,
374,12,36,1,1,
374,13,36,1,1,
374,14,36,1,1,
374,14,334,3,0,
374,14,428,3,0,
374,14,442,3,0,
374,15,36,1,1,
374,16,36,1,1,1
374,16,334,3,0,
374,16,428,3,0,
374,16,442,3,0,
374,17,36,1,1,
374,18,36,1,1,
375,5,15,4,0,
375,5,36,1,1,
375,5,63,1,62,
375,5,63,4,0,
375,5,70,4,0,
375,5,89,4,0,
375,5,92,4,0,
375,5,93,1,20,1
375,5,94,1,38,
375,5,94,4,0,
375,5,97,1,56,
375,5,104,4,0,
375,5,113,4,0,
375,5,115,4,0,
375,5,148,4,0,
375,5,156,4,0,
375,5,182,4,0,
375,5,184,1,26,
375,5,188,4,0,
375,5,201,4,0,
375,5,216,4,0,
375,5,218,4,0,
375,5,228,1,32,
375,5,232,1,20,2
375,5,237,4,0,
375,5,240,4,0,
375,5,241,4,0,
375,5,247,4,0,
375,5,249,4,0,
375,5,263,4,0,
375,5,280,4,0,
375,5,290,4,0,
375,5,309,1,50,
375,5,317,4,0,
375,5,332,4,0,
375,5,334,1,44,
375,6,8,3,0,
375,6,9,3,0,
375,6,15,4,0,
375,6,34,3,0,
375,6,36,1,1,
375,6,38,3,0,
375,6,63,1,62,
375,6,63,4,0,
375,6,70,4,0,
375,6,89,4,0,
375,6,92,4,0,
375,6,93,1,20,1
375,6,94,1,38,
375,6,94,4,0,
375,6,97,1,56,
375,6,102,3,0,
375,6,104,4,0,
375,6,111,3,0,
375,6,113,4,0,
375,6,115,4,0,
375,6,129,3,0,
375,6,148,4,0,
375,6,153,3,0,
375,6,156,4,0,
375,6,157,3,0,
375,6,164,3,0,
375,6,173,3,0,
375,6,182,4,0,
375,6,184,1,26,
375,6,188,4,0,
375,6,189,3,0,
375,6,196,3,0,
375,6,201,4,0,
375,6,203,3,0,
375,6,205,3,0,
375,6,207,3,0,
375,6,210,3,0,
375,6,214,3,0,
375,6,216,4,0,
375,6,218,4,0,
375,6,223,3,0,
375,6,228,1,32,
375,6,232,1,20,2
375,6,237,4,0,
375,6,240,4,0,
375,6,241,4,0,
375,6,244,3,0,
375,6,247,4,0,
375,6,249,4,0,
375,6,263,4,0,
375,6,280,4,0,
375,6,290,4,0,
375,6,309,1,50,
375,6,317,4,0,
375,6,332,4,0,
375,6,334,1,44,
375,7,15,4,0,
375,7,34,3,0,
375,7,36,1,1,
375,7,38,3,0,
375,7,63,1,62,
375,7,63,4,0,
375,7,70,4,0,
375,7,89,4,0,
375,7,92,4,0,
375,7,93,1,20,1
375,7,94,1,38,
375,7,94,4,0,
375,7,97,1,56,
375,7,102,3,0,
375,7,104,4,0,
375,7,113,4,0,
375,7,115,4,0,
375,7,148,4,0,
375,7,153,3,0,
375,7,156,4,0,
375,7,157,3,0,
375,7,164,3,0,
375,7,182,4,0,
375,7,184,1,26,
375,7,188,4,0,
375,7,201,4,0,
375,7,216,4,0,
375,7,218,4,0,
375,7,228,1,32,
375,7,232,1,20,2
375,7,237,4,0,
375,7,240,4,0,
375,7,241,4,0,
375,7,247,4,0,
375,7,249,4,0,
375,7,263,4,0,
375,7,280,4,0,
375,7,290,4,0,
375,7,309,1,50,
375,7,317,4,0,
375,7,332,4,0,
375,7,334,1,44,
375,8,15,4,0,
375,8,36,1,1,2
375,8,63,1,56,
375,8,63,4,0,
375,8,70,4,0,
375,8,89,4,0,
375,8,92,4,0,
375,8,93,1,1,4
375,8,93,1,20,2
375,8,94,1,36,
375,8,94,4,0,
375,8,97,1,44,
375,8,104,4,0,
375,8,113,4,0,
375,8,115,4,0,
375,8,148,4,0,
375,8,153,4,0,
375,8,156,4,0,
375,8,157,4,0,
375,8,164,4,0,
375,8,182,4,0,
375,8,184,1,24,
375,8,188,4,0,
375,8,201,4,0,
375,8,203,4,0,
375,8,207,4,0,
375,8,214,4,0,
375,8,216,4,0,
375,8,218,4,0,
375,8,228,1,28,
375,8,232,1,1,3
375,8,232,1,20,1
375,8,237,4,0,
375,8,240,4,0,
375,8,241,4,0,
375,8,244,4,0,
375,8,247,4,0,
375,8,249,4,0,
375,8,263,4,0,
375,8,280,4,0,
375,8,290,4,0,
375,8,309,1,48,
375,8,317,4,0,
375,8,332,4,0,
375,8,334,1,40,
375,8,360,4,0,
375,8,363,4,0,
375,8,393,1,1,1
375,8,397,4,0,
375,8,418,1,32,
375,8,428,1,52,
375,8,430,4,0,
375,8,446,4,0,
375,8,447,4,0,
375,9,8,3,0,
375,9,9,3,0,
375,9,15,4,0,
375,9,36,1,1,2
375,9,63,1,56,
375,9,63,4,0,
375,9,70,4,0,
375,9,89,4,0,
375,9,92,4,0,
375,9,93,1,1,4
375,9,93,1,20,2
375,9,94,1,36,
375,9,94,4,0,
375,9,97,1,44,
375,9,104,4,0,
375,9,113,4,0,
375,9,115,4,0,
375,9,129,3,0,
375,9,148,4,0,
375,9,153,4,0,
375,9,156,4,0,
375,9,157,4,0,
375,9,164,4,0,
375,9,173,3,0,
375,9,182,4,0,
375,9,184,1,24,
375,9,188,4,0,
375,9,189,3,0,
375,9,196,3,0,
375,9,201,4,0,
375,9,203,4,0,
375,9,205,3,0,
375,9,207,4,0,
375,9,210,3,0,
375,9,214,4,0,
375,9,216,4,0,
375,9,218,4,0,
375,9,228,1,28,
375,9,232,1,1,3
375,9,232,1,20,1
375,9,237,4,0,
375,9,240,4,0,
375,9,241,4,0,
375,9,244,4,0,
375,9,247,4,0,
375,9,249,4,0,
375,9,263,4,0,
375,9,271,3,0,
375,9,280,4,0,
375,9,290,4,0,
375,9,309,1,48,
375,9,317,4,0,
375,9,324,3,0,
375,9,332,4,0,
375,9,334,1,40,
375,9,334,3,0,
375,9,360,4,0,
375,9,363,4,0,
375,9,393,1,1,1
375,9,393,3,0,
375,9,397,4,0,
375,9,418,1,32,
375,9,428,1,52,
375,9,428,3,0,
375,9,430,4,0,
375,9,442,3,0,
375,9,446,4,0,
375,9,447,4,0,
375,10,8,3,0,
375,10,9,3,0,
375,10,15,4,0,
375,10,29,3,0,
375,10,36,1,1,2
375,10,63,1,56,
375,10,63,4,0,
375,10,70,4,0,
375,10,89,4,0,
375,10,92,4,0,
375,10,93,1,1,4
375,10,93,1,20,2
375,10,94,1,36,
375,10,94,4,0,
375,10,97,1,44,
375,10,104,4,0,
375,10,113,4,0,
375,10,115,4,0,
375,10,129,3,0,
375,10,148,4,0,
375,10,153,4,0,
375,10,156,4,0,
375,10,157,4,0,
375,10,164,4,0,
375,10,173,3,0,
375,10,182,4,0,
375,10,184,1,24,
375,10,188,4,0,
375,10,189,3,0,
375,10,196,3,0,
375,10,201,4,0,
375,10,203,4,0,
375,10,205,3,0,
375,10,207,4,0,
375,10,210,3,0,
375,10,214,4,0,
375,10,216,4,0,
375,10,218,4,0,
375,10,228,1,28,
375,10,232,1,1,3
375,10,232,1,20,1
375,10,237,4,0,
375,10,240,4,0,
375,10,241,4,0,
375,10,244,4,0,
375,10,247,4,0,
375,10,249,4,0,
375,10,263,4,0,
375,10,271,3,0,
375,10,280,4,0,
375,10,290,4,0,
375,10,309,1,48,
375,10,317,4,0,
375,10,324,3,0,
375,10,332,4,0,
375,10,334,1,40,
375,10,334,3,0,
375,10,356,3,0,
375,10,360,4,0,
375,10,363,4,0,
375,10,393,1,1,1
375,10,393,3,0,
375,10,397,4,0,
375,10,418,1,32,
375,10,428,1,52,
375,10,428,3,0,
375,10,430,4,0,
375,10,442,3,0,
375,10,446,4,0,
375,10,447,4,0,
375,11,15,4,0,
375,11,36,1,1,2
375,11,63,1,56,
375,11,63,4,0,
375,11,70,4,0,
375,11,89,4,0,
375,11,92,4,0,
375,11,93,1,1,4
375,11,93,1,20,2
375,11,94,1,36,
375,11,94,4,0,
375,11,97,1,44,
375,11,104,4,0,
375,11,113,4,0,
375,11,115,4,0,
375,11,148,4,0,
375,11,153,4,0,
375,11,156,4,0,
375,11,157,4,0,
375,11,164,4,0,
375,11,182,4,0,
375,11,184,1,24,
375,11,188,4,0,
375,11,201,4,0,
375,11,207,4,0,
375,11,216,4,0,
375,11,218,4,0,
375,11,228,1,28,
375,11,232,1,1,3
375,11,232,1,20,1
375,11,237,4,0,
375,11,240,4,0,
375,11,241,4,0,
375,11,244,4,0,
375,11,247,4,0,
375,11,249,4,0,
375,11,263,4,0,
375,11,280,4,0,
375,11,309,1,48,
375,11,317,4,0,
375,11,332,4,0,
375,11,334,1,40,
375,11,360,4,0,
375,11,393,1,1,1
375,11,397,4,0,
375,11,418,1,32,
375,11,428,1,52,
375,11,430,4,0,
375,11,447,4,0,
375,11,468,4,0,
375,11,473,4,0,
375,11,477,4,0,
375,11,496,4,0,
375,11,523,4,0,
375,12,15,4,0,
375,12,36,1,1,
375,12,63,1,62,
375,12,63,4,0,
375,12,70,4,0,
375,12,89,4,0,
375,12,92,4,0,
375,12,93,1,20,1
375,12,94,1,38,
375,12,94,4,0,
375,12,97,1,56,
375,12,104,4,0,
375,12,113,4,0,
375,12,115,4,0,
375,12,148,4,0,
375,12,156,4,0,
375,12,182,4,0,
375,12,184,1,26,
375,12,188,4,0,
375,12,201,4,0,
375,12,216,4,0,
375,12,218,4,0,
375,12,228,1,32,
375,12,232,1,20,2
375,12,237,4,0,
375,12,240,4,0,
375,12,241,4,0,
375,12,247,4,0,
375,12,249,4,0,
375,12,263,4,0,
375,12,280,4,0,
375,12,290,4,0,
375,12,309,1,50,
375,12,317,4,0,
375,12,332,4,0,
375,12,334,1,44,
375,13,15,4,0,
375,13,34,3,0,
375,13,36,1,1,
375,13,38,3,0,
375,13,63,1,62,
375,13,63,4,0,
375,13,70,4,0,
375,13,89,4,0,
375,13,92,4,0,
375,13,93,1,20,1
375,13,94,1,38,
375,13,94,4,0,
375,13,97,1,56,
375,13,102,3,0,
375,13,104,4,0,
375,13,113,4,0,
375,13,115,4,0,
375,13,120,3,0,
375,13,148,4,0,
375,13,156,4,0,
375,13,164,3,0,
375,13,182,4,0,
375,13,184,1,26,
375,13,188,4,0,
375,13,196,3,0,
375,13,201,4,0,
375,13,207,3,0,
375,13,216,4,0,
375,13,218,4,0,
375,13,228,1,32,
375,13,232,1,20,2
375,13,237,4,0,
375,13,240,4,0,
375,13,241,4,0,
375,13,247,4,0,
375,13,249,4,0,
375,13,263,4,0,
375,13,280,4,0,
375,13,290,4,0,
375,13,309,1,50,
375,13,317,4,0,
375,13,332,4,0,
375,13,334,1,44,
375,14,8,3,0,
375,14,9,3,0,
375,14,15,4,0,
375,14,36,1,1,2
375,14,63,1,50,
375,14,63,4,0,
375,14,70,4,0,
375,14,89,4,0,
375,14,92,4,0,
375,14,93,1,1,4
375,14,93,1,20,1
375,14,94,1,41,
375,14,94,4,0,
375,14,97,1,38,
375,14,104,4,0,
375,14,113,4,0,
375,14,115,4,0,
375,14,148,4,0,
375,14,153,4,0,
375,14,156,4,0,
375,14,157,4,0,
375,14,164,4,0,
375,14,173,3,0,
375,14,182,4,0,
375,14,184,1,35,
375,14,188,4,0,
375,14,196,3,0,
375,14,201,4,0,
375,14,207,4,0,
375,14,214,3,0,
375,14,216,4,0,
375,14,218,4,0,
375,14,228,1,23,
375,14,232,1,1,3
375,14,232,1,20,2
375,14,237,4,0,
375,14,240,4,0,
375,14,241,4,0,
375,14,244,4,0,
375,14,247,4,0,
375,14,249,4,0,
375,14,263,4,0,
375,14,271,3,0,
375,14,280,4,0,
375,14,309,1,44,
375,14,317,4,0,
375,14,324,3,0,
375,14,332,4,0,
375,14,334,1,47,
375,14,334,3,0,
375,14,356,3,0,
375,14,357,1,26,
375,14,360,4,0,
375,14,393,1,1,1
375,14,393,3,0,
375,14,397,4,0,
375,14,418,1,32,
375,14,428,1,29,
375,14,428,3,0,
375,14,430,4,0,
375,14,442,3,0,
375,14,446,3,0,
375,14,447,4,0,
375,14,468,4,0,
375,14,473,4,0,
375,14,477,4,0,
375,14,496,4,0,
375,14,523,4,0,
375,15,15,4,0,
375,15,36,1,1,2
375,15,63,1,50,
375,15,63,4,0,
375,15,70,4,0,
375,15,89,4,0,
375,15,92,4,0,
375,15,93,1,1,4
375,15,93,1,20,1
375,15,94,1,41,
375,15,94,4,0,
375,15,97,1,38,
375,15,104,4,0,
375,15,113,4,0,
375,15,115,4,0,
375,15,148,4,0,
375,15,153,4,0,
375,15,156,4,0,
375,15,157,4,0,
375,15,164,4,0,
375,15,182,4,0,
375,15,184,1,35,
375,15,188,4,0,
375,15,201,4,0,
375,15,207,4,0,
375,15,214,4,0,
375,15,216,4,0,
375,15,218,4,0,
375,15,228,1,23,
375,15,232,1,1,3
375,15,232,1,20,2
375,15,237,4,0,
375,15,240,4,0,
375,15,241,4,0,
375,15,244,4,0,
375,15,247,4,0,
375,15,249,4,0,
375,15,263,4,0,
375,15,280,4,0,
375,15,309,1,44,
375,15,317,4,0,
375,15,332,4,0,
375,15,334,1,47,
375,15,357,1,26,
375,15,360,4,0,
375,15,393,1,1,1
375,15,397,4,0,
375,15,418,1,32,
375,15,428,1,29,
375,15,430,4,0,
375,15,447,4,0,
375,15,468,4,0,
375,15,473,4,0,
375,15,496,4,0,
375,15,523,4,0,
375,15,590,4,0,
375,15,612,4,0,
375,16,8,3,0,
375,16,9,3,0,
375,16,15,4,0,
375,16,36,1,1,2
375,16,63,1,50,1
375,16,63,4,0,
375,16,70,4,0,
375,16,89,4,0,
375,16,92,4,0,
375,16,93,1,1,3
375,16,93,1,20,1
375,16,94,1,38,1
375,16,94,4,0,
375,16,97,1,41,1
375,16,104,4,0,
375,16,113,4,0,
375,16,115,4,0,
375,16,148,4,0,
375,16,153,4,0,
375,16,156,4,0,
375,16,157,4,0,
375,16,164,4,0,
375,16,173,3,0,
375,16,182,4,0,
375,16,184,1,35,1
375,16,188,4,0,
375,16,196,3,0,
375,16,201,4,0,
375,16,207,4,0,
375,16,214,4,0,
375,16,216,4,0,
375,16,218,4,0,
375,16,228,1,23,1
375,16,232,1,1,4
375,16,232,1,20,2
375,16,237,4,0,
375,16,240,4,0,
375,16,241,4,0,
375,16,244,4,0,
375,16,247,4,0,
375,16,249,4,0,
375,16,263,4,0,
375,16,271,3,0,
375,16,280,4,0,
375,16,290,4,0,
375,16,309,1,44,1
375,16,317,4,0,
375,16,324,3,0,
375,16,332,4,0,
375,16,334,1,47,1
375,16,334,3,0,
375,16,356,3,0,
375,16,357,1,29,1
375,16,360,4,0,
375,16,393,1,1,1
375,16,393,3,0,
375,16,397,4,0,
375,16,418,1,26,1
375,16,428,1,32,1
375,16,428,3,0,
375,16,430,4,0,
375,16,442,3,0,
375,16,446,3,0,
375,16,447,4,0,
375,16,468,4,0,
375,16,473,4,0,
375,16,496,4,0,
375,16,523,4,0,
375,16,590,4,0,
375,16,612,4,0,
375,17,36,1,1,4
375,17,63,1,50,
375,17,63,4,0,
375,17,89,4,0,
375,17,92,4,0,
375,17,93,1,0,
375,17,93,1,1,1
375,17,94,1,38,
375,17,94,4,0,
375,17,97,1,41,
375,17,104,4,0,
375,17,113,4,0,
375,17,115,4,0,
375,17,153,4,0,
375,17,156,4,0,
375,17,157,4,0,
375,17,164,4,0,
375,17,182,4,0,
375,17,184,1,35,
375,17,188,4,0,
375,17,201,4,0,
375,17,207,4,0,
375,17,214,4,0,
375,17,216,4,0,
375,17,218,4,0,
375,17,228,1,23,
375,17,232,1,0,
375,17,232,1,1,2
375,17,237,4,0,
375,17,240,4,0,
375,17,241,4,0,
375,17,244,4,0,
375,17,247,4,0,
375,17,263,4,0,
375,17,280,4,0,
375,17,309,1,44,
375,17,317,4,0,
375,17,332,4,0,
375,17,334,1,47,
375,17,357,1,29,
375,17,360,4,0,
375,17,393,1,1,3
375,17,397,4,0,
375,17,418,1,26,
375,17,428,1,32,
375,17,430,4,0,
375,17,447,4,0,
375,17,473,4,0,
375,17,496,4,0,
375,17,523,4,0,
375,17,590,4,0,
375,18,36,1,1,4
375,18,63,1,50,
375,18,63,4,0,
375,18,89,4,0,
375,18,92,4,0,
375,18,93,1,0,
375,18,93,1,1,1
375,18,94,1,38,
375,18,94,4,0,
375,18,97,1,41,
375,18,104,4,0,
375,18,113,4,0,
375,18,115,4,0,
375,18,153,4,0,
375,18,156,4,0,
375,18,157,4,0,
375,18,164,4,0,
375,18,182,4,0,
375,18,184,1,35,
375,18,188,4,0,
375,18,201,4,0,
375,18,207,4,0,
375,18,214,4,0,
375,18,216,4,0,
375,18,218,4,0,
375,18,228,1,23,
375,18,232,1,0,
375,18,232,1,1,2
375,18,237,4,0,
375,18,240,4,0,
375,18,241,4,0,
375,18,244,4,0,
375,18,247,4,0,
375,18,263,4,0,
375,18,280,4,0,
375,18,309,1,44,
375,18,317,4,0,
375,18,332,4,0,
375,18,334,1,47,
375,18,357,1,29,
375,18,360,4,0,
375,18,393,1,1,3
375,18,397,4,0,
375,18,418,1,26,
375,18,428,1,32,
375,18,430,4,0,
375,18,447,4,0,
375,18,473,4,0,
375,18,496,4,0,
375,18,523,4,0,
375,18,590,4,0,
376,5,15,4,0,
376,5,36,1,1,1
376,5,63,1,77,
376,5,63,4,0,
376,5,70,4,0,
376,5,89,4,0,
376,5,92,4,0,
376,5,93,1,1,2
376,5,93,1,20,
376,5,94,1,38,
376,5,94,4,0,
376,5,97,1,66,
376,5,104,4,0,
376,5,113,4,0,
376,5,115,4,0,
376,5,148,4,0,
376,5,156,4,0,
376,5,182,4,0,
376,5,184,1,1,4
376,5,184,1,26,
376,5,188,4,0,
376,5,201,4,0,
376,5,216,4,0,
376,5,218,4,0,
376,5,228,1,32,
376,5,232,1,1,3
376,5,232,1,20,5
376,5,237,4,0,
376,5,240,4,0,
376,5,241,4,0,
376,5,247,4,0,
376,5,249,4,0,
376,5,263,4,0,
376,5,280,4,0,
376,5,290,4,0,
376,5,309,1,55,
376,5,317,4,0,
376,5,332,4,0,
376,5,334,1,44,
376,6,8,3,0,
376,6,9,3,0,
376,6,15,4,0,
376,6,34,3,0,
376,6,36,1,1,1
376,6,38,3,0,
376,6,63,1,77,
376,6,63,4,0,
376,6,70,4,0,
376,6,89,4,0,
376,6,92,4,0,
376,6,93,1,1,2
376,6,93,1,20,
376,6,94,1,38,
376,6,94,4,0,
376,6,97,1,66,
376,6,102,3,0,
376,6,104,4,0,
376,6,111,3,0,
376,6,113,4,0,
376,6,115,4,0,
376,6,129,3,0,
376,6,148,4,0,
376,6,153,3,0,
376,6,156,4,0,
376,6,157,3,0,
376,6,164,3,0,
376,6,173,3,0,
376,6,182,4,0,
376,6,184,1,1,4
376,6,184,1,26,
376,6,188,4,0,
376,6,189,3,0,
376,6,196,3,0,
376,6,201,4,0,
376,6,203,3,0,
376,6,205,3,0,
376,6,207,3,0,
376,6,210,3,0,
376,6,214,3,0,
376,6,216,4,0,
376,6,218,4,0,
376,6,223,3,0,
376,6,228,1,32,
376,6,232,1,1,3
376,6,232,1,20,5
376,6,237,4,0,
376,6,240,4,0,
376,6,241,4,0,
376,6,244,3,0,
376,6,247,4,0,
376,6,249,4,0,
376,6,263,4,0,
376,6,280,4,0,
376,6,290,4,0,
376,6,309,1,55,
376,6,317,4,0,
376,6,332,4,0,
376,6,334,1,44,
376,7,15,4,0,
376,7,34,3,0,
376,7,36,1,1,1
376,7,38,3,0,
376,7,63,1,77,
376,7,63,4,0,
376,7,70,4,0,
376,7,89,4,0,
376,7,92,4,0,
376,7,93,1,1,2
376,7,93,1,20,
376,7,94,1,38,
376,7,94,4,0,
376,7,97,1,66,
376,7,102,3,0,
376,7,104,4,0,
376,7,113,4,0,
376,7,115,4,0,
376,7,148,4,0,
376,7,153,3,0,
376,7,156,4,0,
376,7,157,3,0,
376,7,164,3,0,
376,7,182,4,0,
376,7,184,1,1,4
376,7,184,1,26,
376,7,188,4,0,
376,7,201,4,0,
376,7,216,4,0,
376,7,218,4,0,
376,7,228,1,32,
376,7,232,1,1,3
376,7,232,1,20,5
376,7,237,4,0,
376,7,240,4,0,
376,7,241,4,0,
376,7,247,4,0,
376,7,249,4,0,
376,7,263,4,0,
376,7,280,4,0,
376,7,290,4,0,
376,7,309,1,55,
376,7,317,4,0,
376,7,332,4,0,
376,7,334,1,44,
376,8,15,4,0,
376,8,36,1,1,2
376,8,63,1,71,
376,8,63,4,0,
376,8,70,4,0,
376,8,89,4,0,
376,8,92,4,0,
376,8,93,1,1,4
376,8,93,1,20,2
376,8,94,1,36,
376,8,94,4,0,
376,8,97,1,44,
376,8,104,4,0,
376,8,113,4,0,
376,8,115,4,0,
376,8,148,4,0,
376,8,153,4,0,
376,8,156,4,0,
376,8,157,4,0,
376,8,164,4,0,
376,8,182,4,0,
376,8,184,1,24,
376,8,188,4,0,
376,8,201,4,0,
376,8,203,4,0,
376,8,207,4,0,
376,8,214,4,0,
376,8,216,4,0,
376,8,218,4,0,
376,8,228,1,28,
376,8,232,1,1,3
376,8,232,1,20,1
376,8,237,4,0,
376,8,240,4,0,
376,8,241,4,0,
376,8,244,4,0,
376,8,247,4,0,
376,8,249,4,0,
376,8,263,4,0,
376,8,280,4,0,
376,8,290,4,0,
376,8,309,1,53,
376,8,317,4,0,
376,8,332,4,0,
376,8,334,1,40,
376,8,359,1,45,
376,8,360,4,0,
376,8,363,4,0,
376,8,393,1,1,1
376,8,397,4,0,
376,8,416,4,0,
376,8,418,1,32,
376,8,428,1,62,
376,8,430,4,0,
376,8,446,4,0,
376,8,447,4,0,
376,9,8,3,0,
376,9,9,3,0,
376,9,15,4,0,
376,9,36,1,1,2
376,9,63,1,71,
376,9,63,4,0,
376,9,70,4,0,
376,9,89,4,0,
376,9,92,4,0,
376,9,93,1,1,4
376,9,93,1,20,2
376,9,94,1,36,
376,9,94,4,0,
376,9,97,1,44,
376,9,104,4,0,
376,9,113,4,0,
376,9,115,4,0,
376,9,129,3,0,
376,9,148,4,0,
376,9,153,4,0,
376,9,156,4,0,
376,9,157,4,0,
376,9,164,4,0,
376,9,173,3,0,
376,9,182,4,0,
376,9,184,1,24,
376,9,188,4,0,
376,9,189,3,0,
376,9,196,3,0,
376,9,201,4,0,
376,9,203,4,0,
376,9,205,3,0,
376,9,207,4,0,
376,9,210,3,0,
376,9,214,4,0,
376,9,216,4,0,
376,9,218,4,0,
376,9,228,1,28,
376,9,232,1,1,3
376,9,232,1,20,1
376,9,237,4,0,
376,9,240,4,0,
376,9,241,4,0,
376,9,244,4,0,
376,9,247,4,0,
376,9,249,4,0,
376,9,263,4,0,
376,9,271,3,0,
376,9,280,4,0,
376,9,290,4,0,
376,9,309,1,53,
376,9,317,4,0,
376,9,324,3,0,
376,9,332,4,0,
376,9,334,1,40,
376,9,334,3,0,
376,9,359,1,45,
376,9,360,4,0,
376,9,363,4,0,
376,9,393,1,1,1
376,9,393,3,0,
376,9,397,4,0,
376,9,416,4,0,
376,9,418,1,32,
376,9,428,1,62,
376,9,428,3,0,
376,9,430,4,0,
376,9,442,3,0,
376,9,446,4,0,
376,9,447,4,0,
376,10,8,3,0,
376,10,9,3,0,
376,10,15,4,0,
376,10,29,3,0,
376,10,36,1,1,2
376,10,63,1,71,
376,10,63,4,0,
376,10,70,4,0,
376,10,89,4,0,
376,10,92,4,0,
376,10,93,1,1,4
376,10,93,1,20,2
376,10,94,1,36,
376,10,94,4,0,
376,10,97,1,44,
376,10,104,4,0,
376,10,113,4,0,
376,10,115,4,0,
376,10,129,3,0,
376,10,148,4,0,
376,10,153,4,0,
376,10,156,4,0,
376,10,157,4,0,
376,10,164,4,0,
376,10,173,3,0,
376,10,182,4,0,
376,10,184,1,24,
376,10,188,4,0,
376,10,189,3,0,
376,10,196,3,0,
376,10,201,4,0,
376,10,203,4,0,
376,10,205,3,0,
376,10,207,4,0,
376,10,210,3,0,
376,10,214,4,0,
376,10,216,4,0,
376,10,218,4,0,
376,10,228,1,28,
376,10,232,1,1,3
376,10,232,1,20,1
376,10,237,4,0,
376,10,240,4,0,
376,10,241,4,0,
376,10,244,4,0,
376,10,247,4,0,
376,10,249,4,0,
376,10,263,4,0,
376,10,271,3,0,
376,10,280,4,0,
376,10,290,4,0,
376,10,309,1,53,
376,10,317,4,0,
376,10,324,3,0,
376,10,332,4,0,
376,10,334,1,40,
376,10,334,3,0,
376,10,335,3,0,
376,10,356,3,0,
376,10,359,1,45,
376,10,360,4,0,
376,10,363,4,0,
376,10,393,1,1,1
376,10,393,3,0,
376,10,397,4,0,
376,10,416,4,0,
376,10,418,1,32,
376,10,428,1,62,
376,10,428,3,0,
376,10,430,4,0,
376,10,442,3,0,
376,10,446,4,0,
376,10,447,4,0,
376,11,15,4,0,
376,11,36,1,1,2
376,11,63,1,71,
376,11,63,4,0,
376,11,70,4,0,
376,11,89,4,0,
376,11,92,4,0,
376,11,93,1,1,4
376,11,93,1,20,2
376,11,94,1,36,
376,11,94,4,0,
376,11,97,1,44,
376,11,104,4,0,
376,11,113,4,0,
376,11,115,4,0,
376,11,148,4,0,
376,11,153,4,0,
376,11,156,4,0,
376,11,157,4,0,
376,11,164,4,0,
376,11,182,4,0,
376,11,184,1,24,
376,11,188,4,0,
376,11,201,4,0,
376,11,207,4,0,
376,11,216,4,0,
376,11,218,4,0,
376,11,228,1,28,
376,11,232,1,1,3
376,11,232,1,20,1
376,11,237,4,0,
376,11,240,4,0,
376,11,241,4,0,
376,11,244,4,0,
376,11,247,4,0,
376,11,249,4,0,
376,11,263,4,0,
376,11,280,4,0,
376,11,309,1,53,
376,11,317,4,0,
376,11,332,4,0,
376,11,334,1,40,
376,11,359,1,45,
376,11,360,4,0,
376,11,393,1,1,1
376,11,397,4,0,
376,11,416,4,0,
376,11,418,1,32,
376,11,428,1,62,
376,11,430,4,0,
376,11,447,4,0,
376,11,468,4,0,
376,11,473,4,0,
376,11,477,4,0,
376,11,496,4,0,
376,11,523,4,0,
376,12,15,4,0,
376,12,36,1,1,1
376,12,63,1,77,
376,12,63,4,0,
376,12,70,4,0,
376,12,89,4,0,
376,12,92,4,0,
376,12,93,1,1,2
376,12,93,1,20,
376,12,94,1,38,
376,12,94,4,0,
376,12,97,1,66,
376,12,104,4,0,
376,12,113,4,0,
376,12,115,4,0,
376,12,148,4,0,
376,12,156,4,0,
376,12,182,4,0,
376,12,184,1,1,4
376,12,184,1,26,
376,12,188,4,0,
376,12,201,4,0,
376,12,216,4,0,
376,12,218,4,0,
376,12,228,1,32,
376,12,232,1,1,3
376,12,232,1,20,5
376,12,237,4,0,
376,12,240,4,0,
376,12,241,4,0,
376,12,247,4,0,
376,12,249,4,0,
376,12,263,4,0,
376,12,280,4,0,
376,12,290,4,0,
376,12,309,1,55,
376,12,317,4,0,
376,12,332,4,0,
376,12,334,1,44,
376,13,15,4,0,
376,13,34,3,0,
376,13,36,1,1,1
376,13,38,3,0,
376,13,63,1,77,
376,13,63,4,0,
376,13,70,4,0,
376,13,89,4,0,
376,13,92,4,0,
376,13,93,1,1,2
376,13,93,1,20,
376,13,94,1,38,
376,13,94,4,0,
376,13,97,1,66,
376,13,102,3,0,
376,13,104,4,0,
376,13,113,4,0,
376,13,115,4,0,
376,13,120,3,0,
376,13,148,4,0,
376,13,156,4,0,
376,13,164,3,0,
376,13,182,4,0,
376,13,184,1,1,4
376,13,184,1,26,
376,13,188,4,0,
376,13,196,3,0,
376,13,201,4,0,
376,13,207,3,0,
376,13,216,4,0,
376,13,218,4,0,
376,13,228,1,32,
376,13,232,1,1,3
376,13,232,1,20,5
376,13,237,4,0,
376,13,240,4,0,
376,13,241,4,0,
376,13,247,4,0,
376,13,249,4,0,
376,13,263,4,0,
376,13,280,4,0,
376,13,290,4,0,
376,13,309,1,55,
376,13,317,4,0,
376,13,332,4,0,
376,13,334,1,44,
376,14,8,3,0,
376,14,9,3,0,
376,14,15,4,0,
376,14,36,1,1,2
376,14,63,1,62,
376,14,63,4,0,
376,14,70,4,0,
376,14,89,4,0,
376,14,92,4,0,
376,14,93,1,1,4
376,14,93,1,20,1
376,14,94,1,41,
376,14,94,4,0,
376,14,97,1,38,
376,14,104,4,0,
376,14,113,4,0,
376,14,115,4,0,
376,14,148,4,0,
376,14,153,4,0,
376,14,156,4,0,
376,14,157,4,0,
376,14,164,4,0,
376,14,173,3,0,
376,14,182,4,0,
376,14,184,1,35,
376,14,188,4,0,
376,14,196,3,0,
376,14,201,4,0,
376,14,207,4,0,
376,14,214,3,0,
376,14,216,4,0,
376,14,218,4,0,
376,14,228,1,23,
376,14,232,1,1,3
376,14,232,1,20,2
376,14,237,4,0,
376,14,240,4,0,
376,14,241,4,0,
376,14,244,4,0,
376,14,247,4,0,
376,14,249,4,0,
376,14,263,4,0,
376,14,271,3,0,
376,14,280,4,0,
376,14,309,1,44,
376,14,317,4,0,
376,14,324,3,0,
376,14,332,4,0,
376,14,334,1,53,
376,14,334,3,0,
376,14,335,3,0,
376,14,356,3,0,
376,14,357,1,26,
376,14,359,1,45,
376,14,360,4,0,
376,14,393,1,1,1
376,14,393,3,0,
376,14,397,4,0,
376,14,416,4,0,
376,14,418,1,32,
376,14,428,1,29,
376,14,428,3,0,
376,14,430,4,0,
376,14,442,3,0,
376,14,446,3,0,
376,14,447,4,0,
376,14,468,4,0,
376,14,473,4,0,
376,14,477,4,0,
376,14,496,4,0,
376,14,523,4,0,
376,15,15,4,0,
376,15,36,1,1,2
376,15,63,1,62,
376,15,63,4,0,
376,15,70,4,0,
376,15,89,4,0,
376,15,92,4,0,
376,15,93,1,1,4
376,15,93,1,20,1
376,15,94,1,41,
376,15,94,4,0,
376,15,97,1,38,
376,15,104,4,0,
376,15,113,4,0,
376,15,115,4,0,
376,15,148,4,0,
376,15,153,4,0,
376,15,156,4,0,
376,15,157,4,0,
376,15,164,4,0,
376,15,182,4,0,
376,15,184,1,35,
376,15,188,4,0,
376,15,201,4,0,
376,15,207,4,0,
376,15,214,4,0,
376,15,216,4,0,
376,15,218,4,0,
376,15,228,1,23,
376,15,232,1,1,3
376,15,232,1,20,2
376,15,237,4,0,
376,15,240,4,0,
376,15,241,4,0,
376,15,244,4,0,
376,15,247,4,0,
376,15,249,4,0,
376,15,263,4,0,
376,15,280,4,0,
376,15,309,1,44,
376,15,317,4,0,
376,15,332,4,0,
376,15,334,1,53,
376,15,357,1,26,
376,15,359,1,45,
376,15,360,4,0,
376,15,393,1,1,1
376,15,397,4,0,
376,15,416,4,0,
376,15,418,1,32,
376,15,428,1,29,
376,15,430,4,0,
376,15,447,4,0,
376,15,468,4,0,
376,15,473,4,0,
376,15,496,4,0,
376,15,523,4,0,
376,15,590,4,0,
376,15,612,4,0,
376,16,8,3,0,
376,16,9,3,0,
376,16,15,4,0,
376,16,36,1,1,2
376,16,63,1,60,1
376,16,63,4,0,
376,16,70,4,0,
376,16,89,4,0,
376,16,92,4,0,
376,16,93,1,1,3
376,16,93,1,20,1
376,16,94,1,38,1
376,16,94,4,0,
376,16,97,1,41,1
376,16,104,4,0,
376,16,113,4,0,
376,16,115,4,0,
376,16,148,4,0,
376,16,153,4,0,
376,16,156,4,0,
376,16,157,4,0,
376,16,164,4,0,
376,16,173,3,0,
376,16,182,4,0,
376,16,184,1,35,1
376,16,188,4,0,
376,16,196,3,0,
376,16,201,4,0,
376,16,207,4,0,
376,16,214,4,0,
376,16,216,4,0,
376,16,218,4,0,
376,16,228,1,23,1
376,16,232,1,1,4
376,16,232,1,20,2
376,16,237,4,0,
376,16,240,4,0,
376,16,241,4,0,
376,16,244,4,0,
376,16,247,4,0,
376,16,249,4,0,
376,16,263,4,0,
376,16,271,3,0,
376,16,280,4,0,
376,16,290,4,0,
376,16,309,1,44,1
376,16,317,4,0,
376,16,324,3,0,
376,16,332,4,0,
376,16,334,1,52,1
376,16,334,3,0,
376,16,335,3,0,
376,16,356,3,0,
376,16,357,1,29,1
376,16,359,1,45,1
376,16,360,4,0,
376,16,393,1,1,1
376,16,393,3,0,
376,16,397,4,0,
376,16,416,4,0,
376,16,418,1,26,1
376,16,428,1,32,1
376,16,428,3,0,
376,16,430,4,0,
376,16,442,3,0,
376,16,446,3,0,
376,16,447,4,0,
376,16,468,4,0,
376,16,473,4,0,
376,16,496,4,0,
376,16,523,4,0,
376,16,590,4,0,
376,16,612,4,0,
376,17,36,1,1,5
376,17,63,1,60,
376,17,63,4,0,
376,17,89,4,0,
376,17,92,4,0,
376,17,93,1,1,2
376,17,94,1,38,
376,17,94,4,0,
376,17,97,1,41,
376,17,104,4,0,
376,17,113,4,0,
376,17,115,4,0,
376,17,153,4,0,
376,17,156,4,0,
376,17,157,4,0,
376,17,164,4,0,
376,17,182,4,0,
376,17,184,1,35,
376,17,188,4,0,
376,17,201,4,0,
376,17,207,4,0,
376,17,214,4,0,
376,17,216,4,0,
376,17,218,4,0,
376,17,228,1,23,
376,17,232,1,1,3
376,17,237,4,0,
376,17,240,4,0,
376,17,241,4,0,
376,17,244,4,0,
376,17,247,4,0,
376,17,263,4,0,
376,17,280,4,0,
376,17,309,1,44,
376,17,317,4,0,
376,17,332,4,0,
376,17,334,1,52,
376,17,357,1,29,
376,17,359,1,0,
376,17,359,1,1,1
376,17,360,4,0,
376,17,393,1,1,4
376,17,397,4,0,
376,17,416,4,0,
376,17,418,1,26,
376,17,428,1,32,
376,17,430,4,0,
376,17,447,4,0,
376,17,473,4,0,
376,17,496,4,0,
376,17,523,4,0,
376,17,590,4,0,
376,18,36,1,1,5
376,18,63,1,60,
376,18,63,4,0,
376,18,89,4,0,
376,18,92,4,0,
376,18,93,1,1,2
376,18,94,1,38,
376,18,94,4,0,
376,18,97,1,41,
376,18,104,4,0,
376,18,113,4,0,
376,18,115,4,0,
376,18,153,4,0,
376,18,156,4,0,
376,18,157,4,0,
376,18,164,4,0,
376,18,182,4,0,
376,18,184,1,35,
376,18,188,4,0,
376,18,201,4,0,
376,18,207,4,0,
376,18,214,4,0,
376,18,216,4,0,
376,18,218,4,0,
376,18,228,1,23,
376,18,232,1,1,3
376,18,237,4,0,
376,18,240,4,0,
376,18,241,4,0,
376,18,244,4,0,
376,18,247,4,0,
376,18,263,4,0,
376,18,280,4,0,
376,18,309,1,44,
376,18,317,4,0,
376,18,332,4,0,
376,18,334,1,52,
376,18,357,1,29,
376,18,359,1,0,
376,18,359,1,1,1
376,18,360,4,0,
376,18,393,1,1,4
376,18,397,4,0,
376,18,416,4,0,
376,18,418,1,26,
376,18,428,1,32,
376,18,430,4,0,
376,18,447,4,0,
376,18,473,4,0,
376,18,496,4,0,
376,18,523,4,0,
376,18,590,4,0,
377,5,63,1,65,
377,5,63,4,0,
377,5,70,4,0,
377,5,85,4,0,
377,5,87,4,0,
377,5,88,1,9,
377,5,89,4,0,
377,5,91,4,0,
377,5,92,4,0,
377,5,104,4,0,
377,5,153,1,1,
377,5,156,4,0,
377,5,174,1,17,
377,5,182,4,0,
377,5,192,1,49,
377,5,199,1,57,
377,5,201,4,0,
377,5,216,4,0,
377,5,218,4,0,
377,5,219,4,0,
377,5,237,4,0,
377,5,241,4,0,
377,5,246,1,33,
377,5,249,4,0,
377,5,263,4,0,
377,5,264,4,0,
377,5,276,1,25,
377,5,280,4,0,
377,5,290,4,0,
377,5,317,4,0,
377,5,334,1,41,
377,5,351,4,0,
377,6,5,3,0,
377,6,7,3,0,
377,6,8,3,0,
377,6,9,3,0,
377,6,25,3,0,
377,6,34,3,0,
377,6,38,3,0,
377,6,63,1,65,
377,6,63,4,0,
377,6,68,3,0,
377,6,69,3,0,
377,6,70,4,0,
377,6,85,4,0,
377,6,86,3,0,
377,6,87,4,0,
377,6,88,1,9,
377,6,89,4,0,
377,6,91,4,0,
377,6,92,4,0,
377,6,102,3,0,
377,6,104,4,0,
377,6,111,3,0,
377,6,153,1,1,
377,6,153,3,0,
377,6,156,4,0,
377,6,157,3,0,
377,6,164,3,0,
377,6,173,3,0,
377,6,174,1,17,
377,6,182,4,0,
377,6,189,3,0,
377,6,192,1,49,
377,6,199,1,57,
377,6,201,4,0,
377,6,203,3,0,
377,6,205,3,0,
377,6,207,3,0,
377,6,214,3,0,
377,6,216,4,0,
377,6,218,4,0,
377,6,219,4,0,
377,6,223,3,0,
377,6,237,4,0,
377,6,241,4,0,
377,6,244,3,0,
377,6,246,1,33,
377,6,249,4,0,
377,6,263,4,0,
377,6,264,4,0,
377,6,276,1,25,
377,6,280,4,0,
377,6,290,4,0,
377,6,317,4,0,
377,6,334,1,41,
377,6,351,4,0,
377,7,5,3,0,
377,7,25,3,0,
377,7,34,3,0,
377,7,38,3,0,
377,7,63,1,65,
377,7,63,4,0,
377,7,68,3,0,
377,7,69,3,0,
377,7,70,4,0,
377,7,85,4,0,
377,7,86,3,0,
377,7,87,4,0,
377,7,88,1,9,
377,7,89,4,0,
377,7,91,4,0,
377,7,92,4,0,
377,7,102,3,0,
377,7,104,4,0,
377,7,153,1,1,
377,7,153,3,0,
377,7,156,4,0,
377,7,157,3,0,
377,7,164,3,0,
377,7,174,1,17,
377,7,182,4,0,
377,7,192,1,49,
377,7,199,1,57,
377,7,201,4,0,
377,7,216,4,0,
377,7,218,4,0,
377,7,219,4,0,
377,7,237,4,0,
377,7,241,4,0,
377,7,246,1,33,
377,7,249,4,0,
377,7,263,4,0,
377,7,264,4,0,
377,7,276,1,25,
377,7,280,4,0,
377,7,290,4,0,
377,7,317,4,0,
377,7,334,1,41,
377,7,351,4,0,
377,8,63,1,89,
377,8,63,4,0,
377,8,70,4,0,
377,8,85,4,0,
377,8,86,4,0,
377,8,87,4,0,
377,8,88,1,9,
377,8,89,4,0,
377,8,91,4,0,
377,8,92,4,0,
377,8,104,4,0,
377,8,153,1,1,
377,8,153,4,0,
377,8,156,4,0,
377,8,157,4,0,
377,8,164,4,0,
377,8,174,1,17,
377,8,182,4,0,
377,8,192,1,65,
377,8,199,1,57,
377,8,201,4,0,
377,8,203,4,0,
377,8,207,4,0,
377,8,214,4,0,
377,8,216,4,0,
377,8,218,4,0,
377,8,219,4,0,
377,8,237,4,0,
377,8,241,4,0,
377,8,244,4,0,
377,8,246,1,33,
377,8,249,4,0,
377,8,263,4,0,
377,8,264,4,0,
377,8,276,1,25,
377,8,280,4,0,
377,8,290,4,0,
377,8,317,4,0,
377,8,334,1,41,
377,8,351,4,0,
377,8,359,1,81,
377,8,363,4,0,
377,8,374,4,0,
377,8,397,4,0,
377,8,409,4,0,
377,8,411,4,0,
377,8,416,4,0,
377,8,431,4,0,
377,8,444,1,73,
377,8,444,4,0,
377,8,446,4,0,
377,8,451,1,49,
377,8,451,4,0,
377,9,7,3,0,
377,9,8,3,0,
377,9,9,3,0,
377,9,23,1,1,2
377,9,63,1,89,
377,9,63,4,0,
377,9,70,4,0,
377,9,85,4,0,
377,9,86,4,0,
377,9,87,4,0,
377,9,88,1,9,
377,9,89,4,0,
377,9,91,4,0,
377,9,92,4,0,
377,9,104,4,0,
377,9,153,1,1,1
377,9,153,4,0,
377,9,156,4,0,
377,9,157,4,0,
377,9,164,4,0,
377,9,173,3,0,
377,9,174,1,17,
377,9,182,4,0,
377,9,189,3,0,
377,9,192,1,65,
377,9,199,1,57,
377,9,201,4,0,
377,9,203,4,0,
377,9,205,3,0,
377,9,207,4,0,
377,9,214,4,0,
377,9,216,4,0,
377,9,218,4,0,
377,9,219,4,0,
377,9,237,4,0,
377,9,241,4,0,
377,9,244,4,0,
377,9,246,1,33,
377,9,246,3,0,
377,9,249,4,0,
377,9,263,4,0,
377,9,264,4,0,
377,9,276,1,25,
377,9,276,3,0,
377,9,280,4,0,
377,9,290,4,0,
377,9,317,4,0,
377,9,334,1,41,
377,9,351,4,0,
377,9,359,1,81,
377,9,363,4,0,
377,9,374,4,0,
377,9,397,4,0,
377,9,409,4,0,
377,9,411,4,0,
377,9,414,3,0,
377,9,416,4,0,
377,9,431,4,0,
377,9,442,3,0,
377,9,444,1,73,
377,9,444,4,0,
377,9,446,4,0,
377,9,451,1,49,
377,9,451,4,0,
377,10,7,3,0,
377,10,8,3,0,
377,10,9,3,0,
377,10,23,1,1,2
377,10,29,3,0,
377,10,63,1,89,
377,10,63,4,0,
377,10,70,4,0,
377,10,85,4,0,
377,10,86,4,0,
377,10,87,4,0,
377,10,88,1,9,
377,10,89,4,0,
377,10,91,4,0,
377,10,92,4,0,
377,10,104,4,0,
377,10,153,1,1,1
377,10,153,4,0,
377,10,156,4,0,
377,10,157,4,0,
377,10,164,4,0,
377,10,173,3,0,
377,10,174,1,17,
377,10,182,4,0,
377,10,189,3,0,
377,10,192,1,65,
377,10,199,1,57,
377,10,201,4,0,
377,10,203,4,0,
377,10,205,3,0,
377,10,207,4,0,
377,10,214,4,0,
377,10,216,4,0,
377,10,218,4,0,
377,10,219,4,0,
377,10,237,4,0,
377,10,241,4,0,
377,10,244,4,0,
377,10,246,1,33,
377,10,246,3,0,
377,10,249,4,0,
377,10,263,4,0,
377,10,264,4,0,
377,10,276,1,25,
377,10,276,3,0,
377,10,280,4,0,
377,10,290,4,0,
377,10,317,4,0,
377,10,334,1,41,
377,10,335,3,0,
377,10,351,4,0,
377,10,356,3,0,
377,10,359,1,81,
377,10,363,4,0,
377,10,374,4,0,
377,10,397,4,0,
377,10,409,4,0,
377,10,411,4,0,
377,10,414,3,0,
377,10,416,4,0,
377,10,431,4,0,
377,10,442,3,0,
377,10,444,1,73,
377,10,444,4,0,
377,10,446,4,0,
377,10,451,1,49,
377,10,451,4,0,
377,11,23,1,1,2
377,11,63,1,89,
377,11,63,4,0,
377,11,70,4,0,
377,11,85,4,0,
377,11,86,4,0,
377,11,87,4,0,
377,11,88,1,9,
377,11,89,4,0,
377,11,91,4,0,
377,11,92,4,0,
377,11,104,4,0,
377,11,153,1,1,1
377,11,153,4,0,
377,11,156,4,0,
377,11,157,4,0,
377,11,164,4,0,
377,11,174,1,17,
377,11,182,4,0,
377,11,192,1,65,
377,11,199,1,57,
377,11,201,4,0,
377,11,207,4,0,
377,11,216,4,0,
377,11,218,4,0,
377,11,219,4,0,
377,11,237,4,0,
377,11,241,4,0,
377,11,244,4,0,
377,11,246,1,33,
377,11,249,4,0,
377,11,263,4,0,
377,11,276,1,25,
377,11,280,4,0,
377,11,317,4,0,
377,11,334,1,41,
377,11,359,1,81,
377,11,374,4,0,
377,11,397,4,0,
377,11,411,4,0,
377,11,416,4,0,
377,11,444,1,73,
377,11,444,4,0,
377,11,451,1,49,
377,11,451,4,0,
377,11,479,4,0,
377,11,496,4,0,
377,11,523,4,0,
377,12,63,1,65,
377,12,63,4,0,
377,12,70,4,0,
377,12,85,4,0,
377,12,87,4,0,
377,12,88,1,9,
377,12,89,4,0,
377,12,91,4,0,
377,12,92,4,0,
377,12,104,4,0,
377,12,153,1,1,
377,12,156,4,0,
377,12,174,1,17,
377,12,182,4,0,
377,12,192,1,49,
377,12,199,1,57,
377,12,201,4,0,
377,12,216,4,0,
377,12,218,4,0,
377,12,219,4,0,
377,12,237,4,0,
377,12,241,4,0,
377,12,246,1,33,
377,12,249,4,0,
377,12,263,4,0,
377,12,264,4,0,
377,12,276,1,25,
377,12,280,4,0,
377,12,290,4,0,
377,12,317,4,0,
377,12,334,1,41,
377,12,351,4,0,
377,13,34,3,0,
377,13,38,3,0,
377,13,63,1,65,
377,13,63,4,0,
377,13,69,3,0,
377,13,70,4,0,
377,13,85,4,0,
377,13,86,3,0,
377,13,87,4,0,
377,13,88,1,9,
377,13,89,4,0,
377,13,91,4,0,
377,13,92,4,0,
377,13,102,3,0,
377,13,104,4,0,
377,13,120,3,0,
377,13,153,1,1,
377,13,156,4,0,
377,13,164,3,0,
377,13,174,1,17,
377,13,182,4,0,
377,13,192,1,49,
377,13,199,1,57,
377,13,201,4,0,
377,13,207,3,0,
377,13,216,4,0,
377,13,218,4,0,
377,13,219,4,0,
377,13,237,4,0,
377,13,241,4,0,
377,13,246,1,33,
377,13,249,4,0,
377,13,263,4,0,
377,13,264,4,0,
377,13,276,1,25,
377,13,280,4,0,
377,13,290,4,0,
377,13,317,4,0,
377,13,334,1,41,
377,13,351,4,0,
377,14,7,3,0,
377,14,8,3,0,
377,14,9,3,0,
377,14,23,1,1,2
377,14,63,1,89,
377,14,63,4,0,
377,14,70,4,0,
377,14,85,4,0,
377,14,86,4,0,
377,14,87,4,0,
377,14,88,1,9,
377,14,89,4,0,
377,14,91,4,0,
377,14,92,4,0,
377,14,104,4,0,
377,14,153,1,1,1
377,14,153,4,0,
377,14,156,4,0,
377,14,157,4,0,
377,14,164,4,0,
377,14,173,3,0,
377,14,174,1,17,
377,14,182,4,0,
377,14,192,1,65,
377,14,199,1,57,
377,14,201,4,0,
377,14,207,4,0,
377,14,214,3,0,
377,14,216,4,0,
377,14,218,4,0,
377,14,219,4,0,
377,14,237,4,0,
377,14,241,4,0,
377,14,244,4,0,
377,14,246,1,33,
377,14,249,4,0,
377,14,263,4,0,
377,14,276,1,25,
377,14,276,3,0,
377,14,280,4,0,
377,14,317,4,0,
377,14,334,1,41,
377,14,334,3,0,
377,14,335,3,0,
377,14,356,3,0,
377,14,359,1,81,
377,14,374,4,0,
377,14,397,4,0,
377,14,409,3,0,
377,14,411,4,0,
377,14,414,3,0,
377,14,416,4,0,
377,14,442,3,0,
377,14,444,1,73,
377,14,444,4,0,
377,14,446,3,0,
377,14,451,1,49,
377,14,451,4,0,
377,14,479,4,0,
377,14,496,4,0,
377,14,523,4,0,
377,15,23,1,1,5
377,15,63,1,89,
377,15,63,4,0,
377,15,70,4,0,
377,15,85,4,0,
377,15,86,4,0,
377,15,87,4,0,
377,15,88,1,9,
377,15,89,4,0,
377,15,91,4,0,
377,15,92,4,0,
377,15,104,4,0,
377,15,153,1,1,4
377,15,153,4,0,
377,15,156,4,0,
377,15,157,4,0,
377,15,164,4,0,
377,15,174,1,17,
377,15,182,4,0,
377,15,192,1,1,2
377,15,192,1,65,
377,15,199,1,1,3
377,15,199,1,57,
377,15,201,4,0,
377,15,207,4,0,
377,15,214,4,0,
377,15,216,4,0,
377,15,218,4,0,
377,15,219,4,0,
377,15,237,4,0,
377,15,241,4,0,
377,15,244,4,0,
377,15,246,1,33,
377,15,249,4,0,
377,15,263,4,0,
377,15,276,1,25,
377,15,280,4,0,
377,15,317,4,0,
377,15,334,1,41,
377,15,359,1,1,1
377,15,359,1,81,
377,15,374,4,0,
377,15,397,4,0,
377,15,411,4,0,
377,15,416,4,0,
377,15,444,1,73,
377,15,444,4,0,
377,15,451,1,49,
377,15,451,4,0,
377,15,479,4,0,
377,15,496,4,0,
377,15,523,4,0,
377,15,590,4,0,
377,15,612,4,0,
377,16,7,3,0,
377,16,8,3,0,
377,16,9,3,0,
377,16,23,1,1,2
377,16,63,1,67,1
377,16,63,4,0,
377,16,70,4,0,
377,16,85,4,0,
377,16,86,4,0,
377,16,87,4,0,
377,16,88,1,1,3
377,16,88,1,7,1
377,16,89,4,0,
377,16,91,4,0,
377,16,92,4,0,
377,16,104,4,0,
377,16,153,1,1,1
377,16,153,4,0,
377,16,156,4,0,
377,16,157,4,0,
377,16,164,4,0,
377,16,173,3,0,
377,16,174,1,25,1
377,16,182,4,0,
377,16,192,1,55,2
377,16,199,1,55,1
377,16,201,4,0,
377,16,207,4,0,
377,16,214,4,0,
377,16,216,4,0,
377,16,218,4,0,
377,16,219,4,0,
377,16,237,4,0,
377,16,241,4,0,
377,16,244,4,0,
377,16,246,1,31,1
377,16,249,4,0,
377,16,263,4,0,
377,16,264,3,0,
377,16,276,1,61,1
377,16,276,3,0,
377,16,280,4,0,
377,16,290,4,0,
377,16,317,4,0,
377,16,334,1,37,1
377,16,334,3,0,
377,16,335,3,0,
377,16,351,3,0,
377,16,356,3,0,
377,16,359,1,49,1
377,16,374,4,0,
377,16,397,4,0,
377,16,409,3,0,
377,16,411,4,0,
377,16,414,3,0,
377,16,416,4,0,
377,16,442,3,0,
377,16,444,1,43,1
377,16,444,4,0,
377,16,446,3,0,
377,16,451,1,1,4
377,16,451,1,13,1
377,16,451,4,0,
377,16,479,4,0,
377,16,496,4,0,
377,16,523,1,1,5
377,16,523,1,19,1
377,16,523,4,0,
377,16,590,4,0,
377,16,612,4,0,
377,17,23,1,1,2
377,17,63,1,67,
377,17,63,4,0,
377,17,85,4,0,
377,17,86,4,0,
377,17,87,4,0,
377,17,88,1,1,3
377,17,88,1,7,
377,17,89,4,0,
377,17,92,4,0,
377,17,104,4,0,
377,17,153,1,1,1
377,17,153,4,0,
377,17,156,4,0,
377,17,157,4,0,
377,17,164,4,0,
377,17,174,1,25,
377,17,182,4,0,
377,17,192,1,55,2
377,17,199,1,55,1
377,17,201,4,0,
377,17,207,4,0,
377,17,214,4,0,
377,17,216,4,0,
377,17,218,4,0,
377,17,219,4,0,
377,17,237,4,0,
377,17,241,4,0,
377,17,244,4,0,
377,17,246,1,31,
377,17,263,4,0,
377,17,276,1,61,
377,17,280,4,0,
377,17,317,4,0,
377,17,334,1,37,
377,17,359,1,49,
377,17,374,4,0,
377,17,397,4,0,
377,17,411,4,0,
377,17,416,4,0,
377,17,444,1,43,
377,17,444,4,0,
377,17,451,1,1,4
377,17,451,1,13,
377,17,451,4,0,
377,17,479,4,0,
377,17,496,4,0,
377,17,523,1,1,5
377,17,523,1,19,
377,17,523,4,0,
377,17,590,4,0,
377,18,23,1,1,2
377,18,63,1,67,
377,18,63,4,0,
377,18,85,4,0,
377,18,86,4,0,
377,18,87,4,0,
377,18,88,1,1,3
377,18,88,1,7,
377,18,89,4,0,
377,18,92,4,0,
377,18,104,4,0,
377,18,153,1,1,1
377,18,153,4,0,
377,18,156,4,0,
377,18,157,4,0,
377,18,164,4,0,
377,18,174,1,25,
377,18,182,4,0,
377,18,192,1,55,2
377,18,199,1,55,1
377,18,201,4,0,
377,18,207,4,0,
377,18,214,4,0,
377,18,216,4,0,
377,18,218,4,0,
377,18,219,4,0,
377,18,237,4,0,
377,18,241,4,0,
377,18,244,4,0,
377,18,246,1,31,
377,18,263,4,0,
377,18,276,1,61,
377,18,280,4,0,
377,18,317,4,0,
377,18,334,1,37,
377,18,359,1,49,
377,18,374,4,0,
377,18,397,4,0,
377,18,411,4,0,
377,18,416,4,0,
377,18,444,1,43,
377,18,444,4,0,
377,18,451,1,1,4
377,18,451,1,13,
377,18,451,4,0,
377,18,479,4,0,
377,18,496,4,0,
377,18,523,1,1,5
377,18,523,1,19,
377,18,523,4,0,
377,18,590,4,0,
378,5,58,4,0,
378,5,59,4,0,
378,5,63,1,65,
378,5,63,4,0,
378,5,70,4,0,
378,5,85,4,0,
378,5,87,4,0,
378,5,89,4,0,
378,5,92,4,0,
378,5,104,4,0,
378,5,133,1,41,
378,5,153,1,1,
378,5,156,4,0,
378,5,174,1,17,
378,5,182,4,0,
378,5,192,1,49,
378,5,196,1,9,
378,5,199,1,57,
378,5,216,4,0,
378,5,218,4,0,
378,5,219,4,0,
378,5,237,4,0,
378,5,240,4,0,
378,5,246,1,33,
378,5,249,4,0,
378,5,258,4,0,
378,5,263,4,0,
378,5,264,4,0,
378,5,276,1,25,
378,5,280,4,0,
378,5,290,4,0,
378,5,351,4,0,
378,6,5,3,0,
378,6,8,3,0,
378,6,9,3,0,
378,6,25,3,0,
378,6,34,3,0,
378,6,38,3,0,
378,6,58,4,0,
378,6,59,4,0,
378,6,63,1,65,
378,6,63,4,0,
378,6,68,3,0,
378,6,69,3,0,
378,6,70,4,0,
378,6,85,4,0,
378,6,86,3,0,
378,6,87,4,0,
378,6,89,4,0,
378,6,92,4,0,
378,6,102,3,0,
378,6,104,4,0,
378,6,111,3,0,
378,6,133,1,41,
378,6,153,1,1,
378,6,153,3,0,
378,6,156,4,0,
378,6,157,3,0,
378,6,164,3,0,
378,6,173,3,0,
378,6,174,1,17,
378,6,182,4,0,
378,6,189,3,0,
378,6,192,1,49,
378,6,196,1,9,
378,6,196,3,0,
378,6,199,1,57,
378,6,203,3,0,
378,6,205,3,0,
378,6,207,3,0,
378,6,214,3,0,
378,6,216,4,0,
378,6,218,4,0,
378,6,219,4,0,
378,6,223,3,0,
378,6,237,4,0,
378,6,240,4,0,
378,6,244,3,0,
378,6,246,1,33,
378,6,249,4,0,
378,6,258,4,0,
378,6,263,4,0,
378,6,264,4,0,
378,6,276,1,25,
378,6,280,4,0,
378,6,290,4,0,
378,6,351,4,0,
378,7,5,3,0,
378,7,25,3,0,
378,7,34,3,0,
378,7,38,3,0,
378,7,58,4,0,
378,7,59,4,0,
378,7,63,1,65,
378,7,63,4,0,
378,7,68,3,0,
378,7,69,3,0,
378,7,70,4,0,
378,7,85,4,0,
378,7,86,3,0,
378,7,87,4,0,
378,7,89,4,0,
378,7,92,4,0,
378,7,102,3,0,
378,7,104,4,0,
378,7,133,1,41,
378,7,153,1,1,
378,7,153,3,0,
378,7,156,4,0,
378,7,157,3,0,
378,7,164,3,0,
378,7,174,1,17,
378,7,182,4,0,
378,7,192,1,49,
378,7,196,1,9,
378,7,199,1,57,
378,7,216,4,0,
378,7,218,4,0,
378,7,219,4,0,
378,7,237,4,0,
378,7,240,4,0,
378,7,246,1,33,
378,7,249,4,0,
378,7,258,4,0,
378,7,263,4,0,
378,7,264,4,0,
378,7,276,1,25,
378,7,280,4,0,
378,7,290,4,0,
378,7,351,4,0,
378,8,58,1,73,
378,8,58,4,0,
378,8,59,4,0,
378,8,63,1,89,
378,8,63,4,0,
378,8,70,4,0,
378,8,85,4,0,
378,8,86,4,0,
378,8,87,4,0,
378,8,89,4,0,
378,8,92,4,0,
378,8,104,4,0,
378,8,133,1,41,
378,8,153,1,1,
378,8,153,4,0,
378,8,156,4,0,
378,8,157,4,0,
378,8,164,4,0,
378,8,174,1,17,
378,8,182,4,0,
378,8,192,1,65,
378,8,196,1,9,
378,8,199,1,57,
378,8,203,4,0,
378,8,207,4,0,
378,8,214,4,0,
378,8,216,4,0,
378,8,218,4,0,
378,8,219,4,0,
378,8,237,4,0,
378,8,240,4,0,
378,8,244,4,0,
378,8,246,1,33,
378,8,249,4,0,
378,8,258,4,0,
378,8,263,4,0,
378,8,264,4,0,
378,8,276,1,25,
378,8,280,4,0,
378,8,290,4,0,
378,8,317,4,0,
378,8,351,4,0,
378,8,359,1,81,
378,8,363,4,0,
378,8,374,4,0,
378,8,397,4,0,
378,8,411,4,0,
378,8,416,4,0,
378,8,419,4,0,
378,8,430,4,0,
378,8,431,4,0,
378,8,451,1,49,
378,8,451,4,0,
378,9,8,3,0,
378,9,9,3,0,
378,9,23,1,1,2
378,9,58,1,73,
378,9,58,4,0,
378,9,59,4,0,
378,9,63,1,89,
378,9,63,4,0,
378,9,70,4,0,
378,9,85,4,0,
378,9,86,4,0,
378,9,87,4,0,
378,9,89,4,0,
378,9,92,4,0,
378,9,104,4,0,
378,9,133,1,41,
378,9,153,1,1,1
378,9,153,4,0,
378,9,156,4,0,
378,9,157,4,0,
378,9,164,4,0,
378,9,173,3,0,
378,9,174,1,17,
378,9,182,4,0,
378,9,189,3,0,
378,9,192,1,65,
378,9,196,1,9,
378,9,196,3,0,
378,9,199,1,57,
378,9,203,4,0,
378,9,205,3,0,
378,9,207,4,0,
378,9,214,4,0,
378,9,216,4,0,
378,9,218,4,0,
378,9,219,4,0,
378,9,237,4,0,
378,9,240,4,0,
378,9,244,4,0,
378,9,246,1,33,
378,9,246,3,0,
378,9,249,4,0,
378,9,258,4,0,
378,9,263,4,0,
378,9,264,4,0,
378,9,276,1,25,
378,9,276,3,0,
378,9,280,4,0,
378,9,290,4,0,
378,9,317,4,0,
378,9,324,3,0,
378,9,351,4,0,
378,9,359,1,81,
378,9,363,4,0,
378,9,374,4,0,
378,9,397,4,0,
378,9,411,4,0,
378,9,416,4,0,
378,9,419,4,0,
378,9,430,4,0,
378,9,431,4,0,
378,9,442,3,0,
378,9,451,1,49,
378,9,451,4,0,
378,10,8,3,0,
378,10,9,3,0,
378,10,23,1,1,2
378,10,29,3,0,
378,10,58,1,73,
378,10,58,4,0,
378,10,59,4,0,
378,10,63,1,89,
378,10,63,4,0,
378,10,70,4,0,
378,10,85,4,0,
378,10,86,4,0,
378,10,87,4,0,
378,10,89,4,0,
378,10,92,4,0,
378,10,104,4,0,
378,10,133,1,41,
378,10,153,1,1,1
378,10,153,4,0,
378,10,156,4,0,
378,10,157,4,0,
378,10,164,4,0,
378,10,173,3,0,
378,10,174,1,17,
378,10,182,4,0,
378,10,189,3,0,
378,10,192,1,65,
378,10,196,1,9,
378,10,196,3,0,
378,10,199,1,57,
378,10,203,4,0,
378,10,205,3,0,
378,10,207,4,0,
378,10,214,4,0,
378,10,216,4,0,
378,10,218,4,0,
378,10,219,4,0,
378,10,237,4,0,
378,10,240,4,0,
378,10,244,4,0,
378,10,246,1,33,
378,10,246,3,0,
378,10,249,4,0,
378,10,258,4,0,
378,10,263,4,0,
378,10,264,4,0,
378,10,276,1,25,
378,10,276,3,0,
378,10,280,4,0,
378,10,290,4,0,
378,10,317,4,0,
378,10,324,3,0,
378,10,335,3,0,
378,10,351,4,0,
378,10,356,3,0,
378,10,359,1,81,
378,10,363,4,0,
378,10,374,4,0,
378,10,397,4,0,
378,10,411,4,0,
378,10,416,4,0,
378,10,419,4,0,
378,10,430,4,0,
378,10,431,4,0,
378,10,442,3,0,
378,10,451,1,49,
378,10,451,4,0,
378,11,23,1,1,2
378,11,58,1,73,
378,11,58,4,0,
378,11,59,4,0,
378,11,63,1,89,
378,11,63,4,0,
378,11,70,4,0,
378,11,85,4,0,
378,11,86,4,0,
378,11,87,4,0,
378,11,89,4,0,
378,11,92,4,0,
378,11,104,4,0,
378,11,133,1,41,
378,11,153,1,1,1
378,11,153,4,0,
378,11,156,4,0,
378,11,157,4,0,
378,11,164,4,0,
378,11,174,1,17,
378,11,182,4,0,
378,11,192,1,65,
378,11,196,1,9,
378,11,199,1,57,
378,11,207,4,0,
378,11,216,4,0,
378,11,218,4,0,
378,11,219,4,0,
378,11,237,4,0,
378,11,240,4,0,
378,11,244,4,0,
378,11,246,1,33,
378,11,249,4,0,
378,11,258,4,0,
378,11,263,4,0,
378,11,276,1,25,
378,11,280,4,0,
378,11,317,4,0,
378,11,359,1,81,
378,11,374,4,0,
378,11,397,4,0,
378,11,411,4,0,
378,11,416,4,0,
378,11,430,4,0,
378,11,451,1,49,
378,11,451,4,0,
378,11,496,4,0,
378,11,523,4,0,
378,11,524,4,0,
378,12,58,4,0,
378,12,59,4,0,
378,12,63,1,65,
378,12,63,4,0,
378,12,70,4,0,
378,12,85,4,0,
378,12,87,4,0,
378,12,89,4,0,
378,12,92,4,0,
378,12,104,4,0,
378,12,133,1,41,
378,12,153,1,1,
378,12,156,4,0,
378,12,174,1,17,
378,12,182,4,0,
378,12,192,1,49,
378,12,196,1,9,
378,12,199,1,57,
378,12,216,4,0,
378,12,218,4,0,
378,12,219,4,0,
378,12,237,4,0,
378,12,240,4,0,
378,12,246,1,33,
378,12,249,4,0,
378,12,258,4,0,
378,12,263,4,0,
378,12,264,4,0,
378,12,276,1,25,
378,12,280,4,0,
378,12,290,4,0,
378,12,351,4,0,
378,13,34,3,0,
378,13,38,3,0,
378,13,58,4,0,
378,13,59,4,0,
378,13,63,1,65,
378,13,63,4,0,
378,13,69,3,0,
378,13,70,4,0,
378,13,85,4,0,
378,13,86,3,0,
378,13,87,4,0,
378,13,89,4,0,
378,13,92,4,0,
378,13,102,3,0,
378,13,104,4,0,
378,13,120,3,0,
378,13,133,1,41,
378,13,153,1,1,
378,13,156,4,0,
378,13,164,3,0,
378,13,174,1,17,
378,13,182,4,0,
378,13,192,1,49,
378,13,196,1,9,
378,13,196,3,0,
378,13,199,1,57,
378,13,207,3,0,
378,13,216,4,0,
378,13,218,4,0,
378,13,219,4,0,
378,13,237,4,0,
378,13,240,4,0,
378,13,246,1,33,
378,13,249,4,0,
378,13,258,4,0,
378,13,263,4,0,
378,13,264,4,0,
378,13,276,1,25,
378,13,280,4,0,
378,13,290,4,0,
378,13,351,4,0,
378,14,8,3,0,
378,14,9,3,0,
378,14,23,1,1,2
378,14,58,1,73,
378,14,58,4,0,
378,14,59,4,0,
378,14,63,1,89,
378,14,63,4,0,
378,14,70,4,0,
378,14,85,4,0,
378,14,86,4,0,
378,14,87,4,0,
378,14,89,4,0,
378,14,92,4,0,
378,14,104,4,0,
378,14,133,1,41,
378,14,153,1,1,1
378,14,153,4,0,
378,14,156,4,0,
378,14,157,4,0,
378,14,164,4,0,
378,14,173,3,0,
378,14,174,1,17,
378,14,182,4,0,
378,14,192,1,65,
378,14,196,1,9,
378,14,196,3,0,
378,14,199,1,57,
378,14,207,4,0,
378,14,214,3,0,
378,14,216,4,0,
378,14,218,4,0,
378,14,219,4,0,
378,14,237,4,0,
378,14,240,4,0,
378,14,244,4,0,
378,14,246,1,33,
378,14,249,4,0,
378,14,258,4,0,
378,14,263,4,0,
378,14,276,1,25,
378,14,276,3,0,
378,14,280,4,0,
378,14,317,4,0,
378,14,324,3,0,
378,14,335,3,0,
378,14,356,3,0,
378,14,359,1,81,
378,14,374,4,0,
378,14,397,4,0,
378,14,411,4,0,
378,14,416,4,0,
378,14,430,4,0,
378,14,442,3,0,
378,14,451,1,49,
378,14,451,4,0,
378,14,496,4,0,
378,14,523,4,0,
378,14,524,4,0,
378,15,23,1,1,5
378,15,58,1,73,
378,15,58,4,0,
378,15,59,4,0,
378,15,63,1,89,
378,15,63,4,0,
378,15,70,4,0,
378,15,85,4,0,
378,15,86,4,0,
378,15,87,4,0,
378,15,89,4,0,
378,15,92,4,0,
378,15,104,4,0,
378,15,133,1,41,
378,15,153,1,1,4
378,15,153,4,0,
378,15,156,4,0,
378,15,157,4,0,
378,15,164,4,0,
378,15,174,1,17,
378,15,182,4,0,
378,15,192,1,1,2
378,15,192,1,65,
378,15,196,1,9,
378,15,199,1,1,3
378,15,199,1,57,
378,15,207,4,0,
378,15,214,4,0,
378,15,216,4,0,
378,15,218,4,0,
378,15,219,4,0,
378,15,237,4,0,
378,15,240,4,0,
378,15,244,4,0,
378,15,246,1,33,
378,15,249,4,0,
378,15,258,4,0,
378,15,263,4,0,
378,15,276,1,25,
378,15,280,4,0,
378,15,317,4,0,
378,15,359,1,1,1
378,15,359,1,81,
378,15,374,4,0,
378,15,397,4,0,
378,15,411,4,0,
378,15,416,4,0,
378,15,430,4,0,
378,15,451,1,49,
378,15,451,4,0,
378,15,496,4,0,
378,15,523,4,0,
378,15,524,4,0,
378,15,590,4,0,
378,15,612,4,0,
378,16,8,3,0,
378,16,9,3,0,
378,16,23,1,1,2
378,16,58,1,43,1
378,16,58,4,0,
378,16,59,4,0,
378,16,63,1,67,1
378,16,63,4,0,
378,16,70,4,0,
378,16,85,4,0,
378,16,86,4,0,
378,16,87,4,0,
378,16,89,4,0,
378,16,92,4,0,
378,16,104,4,0,
378,16,133,1,37,1
378,16,153,1,1,1
378,16,153,4,0,
378,16,156,4,0,
378,16,157,4,0,
378,16,164,4,0,
378,16,173,3,0,
378,16,174,1,25,1
378,16,182,4,0,
378,16,192,1,55,2
378,16,196,1,1,3
378,16,196,1,7,1
378,16,196,3,0,
378,16,199,1,55,1
378,16,207,4,0,
378,16,214,4,0,
378,16,216,4,0,
378,16,218,4,0,
378,16,219,4,0,
378,16,237,4,0,
378,16,240,4,0,
378,16,244,4,0,
378,16,246,1,31,1
378,16,249,4,0,
378,16,258,4,0,
378,16,263,4,0,
378,16,264,3,0,
378,16,276,1,61,1
378,16,276,3,0,
378,16,280,4,0,
378,16,290,4,0,
378,16,317,4,0,
378,16,324,3,0,
378,16,335,3,0,
378,16,351,3,0,
378,16,356,3,0,
378,16,359,1,49,1
378,16,374,4,0,
378,16,397,4,0,
378,16,411,4,0,
378,16,416,4,0,
378,16,430,4,0,
378,16,442,3,0,
378,16,451,1,1,4
378,16,451,1,13,1
378,16,451,4,0,
378,16,496,4,0,
378,16,523,1,1,5
378,16,523,1,19,1
378,16,523,4,0,
378,16,524,4,0,
378,16,590,4,0,
378,16,612,4,0,
378,17,23,1,1,2
378,17,58,1,43,
378,17,58,4,0,
378,17,59,4,0,
378,17,63,1,67,
378,17,63,4,0,
378,17,85,4,0,
378,17,86,4,0,
378,17,87,4,0,
378,17,89,4,0,
378,17,92,4,0,
378,17,104,4,0,
378,17,133,1,37,
378,17,153,1,1,1
378,17,153,4,0,
378,17,156,4,0,
378,17,157,4,0,
378,17,164,4,0,
378,17,174,1,25,
378,17,182,4,0,
378,17,192,1,55,2
378,17,196,1,1,3
378,17,196,1,7,
378,17,199,1,55,1
378,17,207,4,0,
378,17,214,4,0,
378,17,216,4,0,
378,17,218,4,0,
378,17,219,4,0,
378,17,237,4,0,
378,17,240,4,0,
378,17,244,4,0,
378,17,246,1,31,
378,17,258,4,0,
378,17,263,4,0,
378,17,276,1,61,
378,17,280,4,0,
378,17,317,4,0,
378,17,359,1,49,
378,17,374,4,0,
378,17,397,4,0,
378,17,411,4,0,
378,17,416,4,0,
378,17,430,4,0,
378,17,451,1,1,4
378,17,451,1,13,
378,17,451,4,0,
378,17,496,4,0,
378,17,523,1,1,5
378,17,523,1,19,
378,17,523,4,0,
378,17,524,4,0,
378,17,590,4,0,
378,17,694,4,0,
378,18,23,1,1,2
378,18,58,1,43,
378,18,58,4,0,
378,18,59,4,0,
378,18,63,1,67,
378,18,63,4,0,
378,18,85,4,0,
378,18,86,4,0,
378,18,87,4,0,
378,18,89,4,0,
378,18,92,4,0,
378,18,104,4,0,
378,18,133,1,37,
378,18,153,1,1,1
378,18,153,4,0,
378,18,156,4,0,
378,18,157,4,0,
378,18,164,4,0,
378,18,174,1,25,
378,18,182,4,0,
378,18,192,1,55,2
378,18,196,1,1,3
378,18,196,1,7,
378,18,199,1,55,1
378,18,207,4,0,
378,18,214,4,0,
378,18,216,4,0,
378,18,218,4,0,
378,18,219,4,0,
378,18,237,4,0,
378,18,240,4,0,
378,18,244,4,0,
378,18,246,1,31,
378,18,258,4,0,
378,18,263,4,0,
378,18,276,1,61,
378,18,280,4,0,
378,18,317,4,0,
378,18,359,1,49,
378,18,374,4,0,
378,18,397,4,0,
378,18,411,4,0,
378,18,416,4,0,
378,18,430,4,0,
378,18,451,1,1,4
378,18,451,1,13,
378,18,451,4,0,
378,18,496,4,0,
378,18,523,1,1,5
378,18,523,1,19,
378,18,523,4,0,
378,18,524,4,0,
378,18,590,4,0,
378,18,694,4,0,
379,5,63,1,65,
379,5,63,4,0,
379,5,70,4,0,
379,5,85,4,0,
379,5,87,4,0,
379,5,89,4,0,
379,5,92,4,0,
379,5,104,4,0,
379,5,133,1,41,2
379,5,153,1,1,
379,5,156,4,0,
379,5,174,1,17,
379,5,182,4,0,
379,5,192,1,49,
379,5,199,1,57,
379,5,201,4,0,
379,5,216,4,0,
379,5,218,4,0,
379,5,219,4,0,
379,5,232,1,9,
379,5,237,4,0,
379,5,240,4,0,
379,5,241,4,0,
379,5,246,1,33,
379,5,249,4,0,
379,5,263,4,0,
379,5,264,4,0,
379,5,276,1,25,
379,5,280,4,0,
379,5,290,4,0,
379,5,317,4,0,
379,5,332,4,0,
379,5,334,1,41,1
379,5,351,4,0,
379,6,5,3,0,
379,6,8,3,0,
379,6,9,3,0,
379,6,25,3,0,
379,6,34,3,0,
379,6,38,3,0,
379,6,63,1,65,
379,6,63,4,0,
379,6,68,3,0,
379,6,69,3,0,
379,6,70,4,0,
379,6,85,4,0,
379,6,86,3,0,
379,6,87,4,0,
379,6,89,4,0,
379,6,92,4,0,
379,6,102,3,0,
379,6,104,4,0,
379,6,111,3,0,
379,6,133,1,41,2
379,6,153,1,1,
379,6,153,3,0,
379,6,156,4,0,
379,6,157,3,0,
379,6,164,3,0,
379,6,173,3,0,
379,6,174,1,17,
379,6,182,4,0,
379,6,189,3,0,
379,6,192,1,49,
379,6,199,1,57,
379,6,201,4,0,
379,6,203,3,0,
379,6,205,3,0,
379,6,207,3,0,
379,6,214,3,0,
379,6,216,4,0,
379,6,218,4,0,
379,6,219,4,0,
379,6,223,3,0,
379,6,232,1,9,
379,6,237,4,0,
379,6,240,4,0,
379,6,241,4,0,
379,6,244,3,0,
379,6,246,1,33,
379,6,249,4,0,
379,6,263,4,0,
379,6,264,4,0,
379,6,276,1,25,
379,6,280,4,0,
379,6,290,4,0,
379,6,317,4,0,
379,6,332,4,0,
379,6,334,1,41,1
379,6,351,4,0,
379,7,5,3,0,
379,7,25,3,0,
379,7,34,3,0,
379,7,38,3,0,
379,7,63,1,65,
379,7,63,4,0,
379,7,68,3,0,
379,7,69,3,0,
379,7,70,4,0,
379,7,85,4,0,
379,7,86,3,0,
379,7,87,4,0,
379,7,89,4,0,
379,7,92,4,0,
379,7,102,3,0,
379,7,104,4,0,
379,7,133,1,41,2
379,7,153,1,1,
379,7,153,3,0,
379,7,156,4,0,
379,7,157,3,0,
379,7,164,3,0,
379,7,174,1,17,
379,7,182,4,0,
379,7,192,1,49,
379,7,199,1,57,
379,7,201,4,0,
379,7,216,4,0,
379,7,218,4,0,
379,7,219,4,0,
379,7,232,1,9,
379,7,237,4,0,
379,7,240,4,0,
379,7,241,4,0,
379,7,246,1,33,
379,7,249,4,0,
379,7,263,4,0,
379,7,264,4,0,
379,7,276,1,25,
379,7,280,4,0,
379,7,290,4,0,
379,7,317,4,0,
379,7,332,4,0,
379,7,334,1,41,1
379,7,351,4,0,
379,8,63,1,89,
379,8,63,4,0,
379,8,70,4,0,
379,8,85,4,0,
379,8,86,4,0,
379,8,87,4,0,
379,8,89,4,0,
379,8,92,4,0,
379,8,104,4,0,
379,8,133,1,41,2
379,8,153,1,1,
379,8,153,4,0,
379,8,156,4,0,
379,8,157,4,0,
379,8,164,4,0,
379,8,174,1,17,
379,8,182,4,0,
379,8,192,1,65,
379,8,199,1,57,
379,8,201,4,0,
379,8,203,4,0,
379,8,207,4,0,
379,8,214,4,0,
379,8,216,4,0,
379,8,218,4,0,
379,8,219,4,0,
379,8,232,1,9,
379,8,237,4,0,
379,8,240,4,0,
379,8,241,4,0,
379,8,244,4,0,
379,8,246,1,33,
379,8,249,4,0,
379,8,263,4,0,
379,8,264,4,0,
379,8,276,1,25,
379,8,280,4,0,
379,8,290,4,0,
379,8,317,4,0,
379,8,332,4,0,
379,8,334,1,41,1
379,8,351,4,0,
379,8,359,1,81,
379,8,363,4,0,
379,8,374,4,0,
379,8,397,4,0,
379,8,411,4,0,
379,8,416,4,0,
379,8,421,4,0,
379,8,430,1,73,2
379,8,430,4,0,
379,8,431,4,0,
379,8,442,1,73,1
379,8,446,4,0,
379,8,451,1,49,
379,8,451,4,0,
379,9,8,3,0,
379,9,9,3,0,
379,9,23,1,1,2
379,9,63,1,89,
379,9,63,4,0,
379,9,70,4,0,
379,9,85,4,0,
379,9,86,4,0,
379,9,87,4,0,
379,9,89,4,0,
379,9,92,4,0,
379,9,104,4,0,
379,9,133,1,41,2
379,9,153,1,1,1
379,9,153,4,0,
379,9,156,4,0,
379,9,157,4,0,
379,9,164,4,0,
379,9,173,3,0,
379,9,174,1,17,
379,9,182,4,0,
379,9,189,3,0,
379,9,192,1,65,
379,9,199,1,57,
379,9,201,4,0,
379,9,203,4,0,
379,9,205,3,0,
379,9,207,4,0,
379,9,214,4,0,
379,9,216,4,0,
379,9,218,4,0,
379,9,219,4,0,
379,9,232,1,9,
379,9,237,4,0,
379,9,240,4,0,
379,9,241,4,0,
379,9,244,4,0,
379,9,246,1,33,
379,9,246,3,0,
379,9,249,4,0,
379,9,263,4,0,
379,9,264,4,0,
379,9,276,1,25,
379,9,276,3,0,
379,9,280,4,0,
379,9,290,4,0,
379,9,317,4,0,
379,9,332,4,0,
379,9,334,1,41,1
379,9,334,3,0,
379,9,351,4,0,
379,9,359,1,81,
379,9,363,4,0,
379,9,374,4,0,
379,9,393,3,0,
379,9,397,4,0,
379,9,411,4,0,
379,9,416,4,0,
379,9,421,4,0,
379,9,430,1,73,2
379,9,430,4,0,
379,9,431,4,0,
379,9,442,1,73,1
379,9,442,3,0,
379,9,446,4,0,
379,9,451,1,49,
379,9,451,4,0,
379,10,8,3,0,
379,10,9,3,0,
379,10,23,1,1,2
379,10,29,3,0,
379,10,63,1,89,
379,10,63,4,0,
379,10,70,4,0,
379,10,85,4,0,
379,10,86,4,0,
379,10,87,4,0,
379,10,89,4,0,
379,10,92,4,0,
379,10,104,4,0,
379,10,133,1,41,2
379,10,153,1,1,1
379,10,153,4,0,
379,10,156,4,0,
379,10,157,4,0,
379,10,164,4,0,
379,10,173,3,0,
379,10,174,1,17,
379,10,182,4,0,
379,10,189,3,0,
379,10,192,1,65,
379,10,199,1,57,
379,10,201,4,0,
379,10,203,4,0,
379,10,205,3,0,
379,10,207,4,0,
379,10,214,4,0,
379,10,216,4,0,
379,10,218,4,0,
379,10,219,4,0,
379,10,232,1,9,
379,10,237,4,0,
379,10,240,4,0,
379,10,241,4,0,
379,10,244,4,0,
379,10,246,1,33,
379,10,246,3,0,
379,10,249,4,0,
379,10,263,4,0,
379,10,264,4,0,
379,10,276,1,25,
379,10,276,3,0,
379,10,280,4,0,
379,10,290,4,0,
379,10,317,4,0,
379,10,332,4,0,
379,10,334,1,41,1
379,10,334,3,0,
379,10,335,3,0,
379,10,351,4,0,
379,10,356,3,0,
379,10,359,1,81,
379,10,363,4,0,
379,10,374,4,0,
379,10,393,3,0,
379,10,397,4,0,
379,10,411,4,0,
379,10,416,4,0,
379,10,421,4,0,
379,10,430,1,73,2
379,10,430,4,0,
379,10,431,4,0,
379,10,442,1,73,1
379,10,442,3,0,
379,10,446,4,0,
379,10,451,1,49,
379,10,451,4,0,
379,11,23,1,1,2
379,11,63,1,89,
379,11,63,4,0,
379,11,70,4,0,
379,11,85,4,0,
379,11,86,4,0,
379,11,87,4,0,
379,11,89,4,0,
379,11,92,4,0,
379,11,104,4,0,
379,11,133,1,41,2
379,11,153,1,1,1
379,11,153,4,0,
379,11,156,4,0,
379,11,157,4,0,
379,11,164,4,0,
379,11,174,1,17,
379,11,182,4,0,
379,11,192,1,65,
379,11,199,1,57,
379,11,201,4,0,
379,11,207,4,0,
379,11,216,4,0,
379,11,218,4,0,
379,11,219,4,0,
379,11,232,1,9,
379,11,237,4,0,
379,11,240,4,0,
379,11,241,4,0,
379,11,244,4,0,
379,11,246,1,33,
379,11,249,4,0,
379,11,263,4,0,
379,11,276,1,25,
379,11,280,4,0,
379,11,317,4,0,
379,11,332,4,0,
379,11,334,1,41,1
379,11,359,1,81,
379,11,374,4,0,
379,11,397,4,0,
379,11,411,4,0,
379,11,416,4,0,
379,11,421,4,0,
379,11,430,1,73,2
379,11,430,4,0,
379,11,442,1,73,1
379,11,451,1,49,
379,11,451,4,0,
379,11,468,4,0,
379,11,496,4,0,
379,11,523,4,0,
379,12,63,1,65,
379,12,63,4,0,
379,12,70,4,0,
379,12,85,4,0,
379,12,87,4,0,
379,12,89,4,0,
379,12,92,4,0,
379,12,104,4,0,
379,12,133,1,41,2
379,12,153,1,1,
379,12,156,4,0,
379,12,174,1,17,
379,12,182,4,0,
379,12,192,1,49,
379,12,199,1,57,
379,12,201,4,0,
379,12,216,4,0,
379,12,218,4,0,
379,12,219,4,0,
379,12,232,1,9,
379,12,237,4,0,
379,12,240,4,0,
379,12,241,4,0,
379,12,246,1,33,
379,12,249,4,0,
379,12,263,4,0,
379,12,264,4,0,
379,12,276,1,25,
379,12,280,4,0,
379,12,290,4,0,
379,12,317,4,0,
379,12,332,4,0,
379,12,334,1,41,1
379,12,351,4,0,
379,13,34,3,0,
379,13,38,3,0,
379,13,63,1,65,
379,13,63,4,0,
379,13,69,3,0,
379,13,70,4,0,
379,13,85,4,0,
379,13,86,3,0,
379,13,87,4,0,
379,13,89,4,0,
379,13,92,4,0,
379,13,102,3,0,
379,13,104,4,0,
379,13,120,3,0,
379,13,133,1,41,2
379,13,153,1,1,
379,13,156,4,0,
379,13,164,3,0,
379,13,174,1,17,
379,13,182,4,0,
379,13,192,1,49,
379,13,199,1,57,
379,13,201,4,0,
379,13,207,3,0,
379,13,216,4,0,
379,13,218,4,0,
379,13,219,4,0,
379,13,232,1,9,
379,13,237,4,0,
379,13,240,4,0,
379,13,241,4,0,
379,13,246,1,33,
379,13,249,4,0,
379,13,263,4,0,
379,13,264,4,0,
379,13,276,1,25,
379,13,280,4,0,
379,13,290,4,0,
379,13,317,4,0,
379,13,332,4,0,
379,13,334,1,41,1
379,13,351,4,0,
379,14,8,3,0,
379,14,9,3,0,
379,14,23,1,1,2
379,14,63,1,89,
379,14,63,4,0,
379,14,70,4,0,
379,14,85,4,0,
379,14,86,4,0,
379,14,87,4,0,
379,14,89,4,0,
379,14,92,4,0,
379,14,104,4,0,
379,14,133,1,41,2
379,14,153,1,1,1
379,14,153,4,0,
379,14,156,4,0,
379,14,157,4,0,
379,14,164,4,0,
379,14,173,3,0,
379,14,174,1,17,
379,14,182,4,0,
379,14,192,1,65,
379,14,199,1,57,
379,14,201,4,0,
379,14,207,4,0,
379,14,214,3,0,
379,14,216,4,0,
379,14,218,4,0,
379,14,219,4,0,
379,14,232,1,9,
379,14,237,4,0,
379,14,240,4,0,
379,14,241,4,0,
379,14,244,4,0,
379,14,246,1,33,
379,14,249,4,0,
379,14,263,4,0,
379,14,276,1,25,
379,14,276,3,0,
379,14,280,4,0,
379,14,317,4,0,
379,14,332,4,0,
379,14,334,1,41,1
379,14,334,3,0,
379,14,335,3,0,
379,14,356,3,0,
379,14,359,1,81,
379,14,374,4,0,
379,14,393,3,0,
379,14,397,4,0,
379,14,411,4,0,
379,14,416,4,0,
379,14,421,4,0,
379,14,430,1,73,2
379,14,430,4,0,
379,14,442,1,73,1
379,14,442,3,0,
379,14,446,3,0,
379,14,451,1,49,
379,14,451,4,0,
379,14,468,4,0,
379,14,496,4,0,
379,14,523,4,0,
379,15,23,1,1,6
379,15,63,1,89,
379,15,63,4,0,
379,15,70,4,0,
379,15,85,4,0,
379,15,86,4,0,
379,15,87,4,0,
379,15,89,4,0,
379,15,92,4,0,
379,15,104,4,0,
379,15,133,1,41,2
379,15,153,1,1,5
379,15,153,4,0,
379,15,156,4,0,
379,15,157,4,0,
379,15,164,4,0,
379,15,174,1,17,
379,15,182,4,0,
379,15,192,1,1,3
379,15,192,1,65,
379,15,199,1,1,4
379,15,199,1,57,
379,15,201,4,0,
379,15,207,4,0,
379,15,214,4,0,
379,15,216,4,0,
379,15,218,4,0,
379,15,219,4,0,
379,15,232,1,9,
379,15,237,4,0,
379,15,240,4,0,
379,15,241,4,0,
379,15,244,4,0,
379,15,246,1,33,
379,15,249,4,0,
379,15,263,4,0,
379,15,276,1,25,
379,15,280,4,0,
379,15,317,4,0,
379,15,332,4,0,
379,15,334,1,41,1
379,15,359,1,1,1
379,15,359,1,81,
379,15,374,4,0,
379,15,397,4,0,
379,15,411,4,0,
379,15,416,4,0,
379,15,421,4,0,
379,15,430,1,73,2
379,15,430,4,0,
379,15,442,1,1,2
379,15,442,1,73,1
379,15,451,1,49,
379,15,451,4,0,
379,15,468,4,0,
379,15,496,4,0,
379,15,523,4,0,
379,15,590,4,0,
379,15,612,4,0,
379,16,8,3,0,
379,16,9,3,0,
379,16,23,1,1,2
379,16,63,1,67,1
379,16,63,4,0,
379,16,70,4,0,
379,16,85,4,0,
379,16,86,4,0,
379,16,87,4,0,
379,16,89,4,0,
379,16,92,4,0,
379,16,104,4,0,
379,16,133,1,37,2
379,16,153,1,1,1
379,16,153,4,0,
379,16,156,4,0,
379,16,157,4,0,
379,16,164,4,0,
379,16,173,3,0,
379,16,174,1,25,1
379,16,182,4,0,
379,16,192,1,55,2
379,16,199,1,55,1
379,16,201,4,0,
379,16,207,4,0,
379,16,214,4,0,
379,16,216,4,0,
379,16,218,4,0,
379,16,219,4,0,
379,16,232,1,1,3
379,16,232,1,7,1
379,16,237,4,0,
379,16,240,4,0,
379,16,241,4,0,
379,16,244,4,0,
379,16,246,1,31,1
379,16,249,4,0,
379,16,263,4,0,
379,16,264,3,0,
379,16,276,1,61,1
379,16,276,3,0,
379,16,280,4,0,
379,16,290,4,0,
379,16,317,4,0,
379,16,332,4,0,
379,16,334,1,37,1
379,16,334,3,0,
379,16,335,3,0,
379,16,351,3,0,
379,16,356,3,0,
379,16,359,1,49,1
379,16,374,4,0,
379,16,393,3,0,
379,16,397,4,0,
379,16,411,4,0,
379,16,416,4,0,
379,16,421,4,0,
379,16,430,1,43,2
379,16,430,4,0,
379,16,442,1,43,1
379,16,442,3,0,
379,16,446,3,0,
379,16,451,1,1,4
379,16,451,1,13,1
379,16,451,4,0,
379,16,468,4,0,
379,16,496,4,0,
379,16,523,1,1,5
379,16,523,1,19,1
379,16,523,4,0,
379,16,590,4,0,
379,16,612,4,0,
379,17,23,1,1,2
379,17,63,1,67,
379,17,63,4,0,
379,17,85,4,0,
379,17,86,4,0,
379,17,87,4,0,
379,17,89,4,0,
379,17,92,4,0,
379,17,104,4,0,
379,17,133,1,37,2
379,17,153,1,1,1
379,17,153,4,0,
379,17,156,4,0,
379,17,157,4,0,
379,17,164,4,0,
379,17,174,1,25,
379,17,182,4,0,
379,17,192,1,55,2
379,17,199,1,55,1
379,17,201,4,0,
379,17,207,4,0,
379,17,214,4,0,
379,17,216,4,0,
379,17,218,4,0,
379,17,219,4,0,
379,17,232,1,1,3
379,17,232,1,7,
379,17,237,4,0,
379,17,240,4,0,
379,17,241,4,0,
379,17,244,4,0,
379,17,246,1,31,
379,17,263,4,0,
379,17,276,1,61,
379,17,280,4,0,
379,17,317,4,0,
379,17,332,4,0,
379,17,334,1,37,1
379,17,359,1,49,
379,17,374,4,0,
379,17,397,4,0,
379,17,411,4,0,
379,17,416,4,0,
379,17,421,4,0,
379,17,430,1,43,2
379,17,430,4,0,
379,17,442,1,43,1
379,17,451,1,1,4
379,17,451,1,13,
379,17,451,4,0,
379,17,496,4,0,
379,17,523,1,1,5
379,17,523,1,19,
379,17,523,4,0,
379,17,590,4,0,
379,18,23,1,1,2
379,18,63,1,67,
379,18,63,4,0,
379,18,85,4,0,
379,18,86,4,0,
379,18,87,4,0,
379,18,89,4,0,
379,18,92,4,0,
379,18,104,4,0,
379,18,133,1,37,2
379,18,153,1,1,1
379,18,153,4,0,
379,18,156,4,0,
379,18,157,4,0,
379,18,164,4,0,
379,18,174,1,25,
379,18,182,4,0,
379,18,192,1,55,2
379,18,199,1,55,1
379,18,201,4,0,
379,18,207,4,0,
379,18,214,4,0,
379,18,216,4,0,
379,18,218,4,0,
379,18,219,4,0,
379,18,232,1,1,3
379,18,232,1,7,
379,18,237,4,0,
379,18,240,4,0,
379,18,241,4,0,
379,18,244,4,0,
379,18,246,1,31,
379,18,263,4,0,
379,18,276,1,61,
379,18,280,4,0,
379,18,317,4,0,
379,18,332,4,0,
379,18,334,1,37,1
379,18,359,1,49,
379,18,374,4,0,
379,18,397,4,0,
379,18,411,4,0,
379,18,416,4,0,
379,18,421,4,0,
379,18,430,1,43,2
379,18,430,4,0,
379,18,442,1,43,1
379,18,451,1,1,4
379,18,451,1,13,
379,18,451,4,0,
379,18,496,4,0,
379,18,523,1,1,5
379,18,523,1,19,
379,18,523,4,0,
379,18,590,4,0,
380,5,15,4,0,
380,5,19,4,0,
380,5,46,4,0,
380,5,57,4,0,
380,5,58,4,0,
380,5,63,4,0,
380,5,76,4,0,
380,5,85,4,0,
380,5,87,4,0,
380,5,89,4,0,
380,5,92,4,0,
380,5,94,1,40,
380,5,94,4,0,
380,5,104,4,0,
380,5,105,1,45,
380,5,113,4,0,
380,5,115,4,0,
380,5,127,4,0,
380,5,148,4,0,
380,5,149,1,1,
380,5,156,4,0,
380,5,182,4,0,
380,5,201,4,0,
380,5,204,1,50,
380,5,211,4,0,
380,5,213,4,0,
380,5,216,4,0,
380,5,218,4,0,
380,5,219,1,15,
380,5,219,4,0,
380,5,225,1,20,
380,5,237,4,0,
380,5,240,4,0,
380,5,241,4,0,
380,5,247,4,0,
380,5,263,4,0,
380,5,270,1,10,
380,5,273,1,5,
380,5,287,1,30,
380,5,290,4,0,
380,5,291,4,0,
380,5,296,1,35,
380,5,332,4,0,
380,5,337,4,0,
380,5,346,1,25,
380,5,347,4,0,
380,5,351,4,0,
380,5,352,4,0,
380,6,15,4,0,
380,6,19,4,0,
380,6,34,3,0,
380,6,38,3,0,
380,6,46,4,0,
380,6,57,4,0,
380,6,58,4,0,
380,6,63,4,0,
380,6,76,4,0,
380,6,85,4,0,
380,6,86,3,0,
380,6,87,4,0,
380,6,89,4,0,
380,6,92,4,0,
380,6,94,1,40,
380,6,94,4,0,
380,6,102,3,0,
380,6,104,4,0,
380,6,105,1,45,
380,6,113,4,0,
380,6,115,4,0,
380,6,127,4,0,
380,6,129,3,0,
380,6,138,3,0,
380,6,148,4,0,
380,6,149,1,1,
380,6,156,4,0,
380,6,164,3,0,
380,6,173,3,0,
380,6,182,4,0,
380,6,189,3,0,
380,6,196,3,0,
380,6,201,4,0,
380,6,203,3,0,
380,6,204,1,50,
380,6,207,3,0,
380,6,210,3,0,
380,6,211,4,0,
380,6,213,4,0,
380,6,214,3,0,
380,6,216,4,0,
380,6,218,4,0,
380,6,219,1,15,
380,6,219,4,0,
380,6,225,1,20,
380,6,237,4,0,
380,6,240,4,0,
380,6,241,4,0,
380,6,244,3,0,
380,6,247,4,0,
380,6,263,4,0,
380,6,270,1,10,
380,6,273,1,5,
380,6,287,1,30,
380,6,290,4,0,
380,6,291,4,0,
380,6,296,1,35,
380,6,332,4,0,
380,6,337,4,0,
380,6,346,1,25,
380,6,347,4,0,
380,6,351,4,0,
380,6,352,4,0,
380,7,15,4,0,
380,7,19,4,0,
380,7,34,3,0,
380,7,38,3,0,
380,7,46,4,0,
380,7,57,4,0,
380,7,58,4,0,
380,7,63,4,0,
380,7,76,4,0,
380,7,85,4,0,
380,7,86,3,0,
380,7,87,4,0,
380,7,89,4,0,
380,7,92,4,0,
380,7,94,1,40,
380,7,94,4,0,
380,7,102,3,0,
380,7,104,4,0,
380,7,105,1,45,
380,7,113,4,0,
380,7,115,4,0,
380,7,127,4,0,
380,7,138,3,0,
380,7,148,4,0,
380,7,149,1,1,
380,7,156,4,0,
380,7,164,3,0,
380,7,182,4,0,
380,7,201,4,0,
380,7,204,1,50,
380,7,211,4,0,
380,7,213,4,0,
380,7,216,4,0,
380,7,218,4,0,
380,7,219,1,15,
380,7,219,4,0,
380,7,225,1,20,
380,7,237,4,0,
380,7,240,4,0,
380,7,241,4,0,
380,7,247,4,0,
380,7,263,4,0,
380,7,270,1,10,
380,7,273,1,5,
380,7,287,1,30,
380,7,290,4,0,
380,7,291,4,0,
380,7,296,1,35,
380,7,332,4,0,
380,7,337,4,0,
380,7,346,1,25,
380,7,347,4,0,
380,7,351,4,0,
380,7,352,4,0,
380,8,15,4,0,
380,8,19,4,0,
380,8,46,4,0,
380,8,57,4,0,
380,8,58,4,0,
380,8,63,4,0,
380,8,76,4,0,
380,8,85,4,0,
380,8,86,4,0,
380,8,87,4,0,
380,8,89,4,0,
380,8,92,4,0,
380,8,94,1,65,
380,8,94,4,0,
380,8,104,4,0,
380,8,105,1,45,
380,8,113,4,0,
380,8,115,4,0,
380,8,127,4,0,
380,8,138,4,0,
380,8,148,4,0,
380,8,149,1,1,
380,8,156,4,0,
380,8,164,4,0,
380,8,182,4,0,
380,8,201,4,0,
380,8,203,4,0,
380,8,204,1,55,
380,8,207,4,0,
380,8,211,4,0,
380,8,213,4,0,
380,8,214,4,0,
380,8,216,4,0,
380,8,218,4,0,
380,8,219,1,15,
380,8,219,4,0,
380,8,225,1,20,
380,8,237,4,0,
380,8,240,4,0,
380,8,241,4,0,
380,8,244,4,0,
380,8,247,4,0,
380,8,263,4,0,
380,8,270,1,10,
380,8,273,1,5,
380,8,287,1,30,
380,8,290,4,0,
380,8,296,1,35,
380,8,332,4,0,
380,8,337,4,0,
380,8,346,1,25,
380,8,347,4,0,
380,8,351,4,0,
380,8,352,4,0,
380,8,355,4,0,
380,8,361,1,60,
380,8,363,4,0,
380,8,375,1,50,
380,8,406,1,70,
380,8,406,4,0,
380,8,412,4,0,
380,8,416,4,0,
380,8,421,4,0,
380,8,428,1,40,
380,8,432,4,0,
380,8,434,3,0,
380,8,445,4,0,
380,8,447,4,0,
380,8,451,4,0,
380,9,15,4,0,
380,9,19,4,0,
380,9,46,4,0,
380,9,57,4,0,
380,9,58,4,0,
380,9,63,4,0,
380,9,76,4,0,
380,9,85,4,0,
380,9,86,4,0,
380,9,87,4,0,
380,9,89,4,0,
380,9,92,4,0,
380,9,94,1,65,
380,9,94,4,0,
380,9,104,4,0,
380,9,105,1,45,
380,9,113,4,0,
380,9,115,4,0,
380,9,127,4,0,
380,9,129,3,0,
380,9,138,4,0,
380,9,148,4,0,
380,9,149,1,1,
380,9,156,4,0,
380,9,164,4,0,
380,9,173,3,0,
380,9,182,4,0,
380,9,189,3,0,
380,9,196,3,0,
380,9,200,3,0,
380,9,201,4,0,
380,9,203,4,0,
380,9,204,1,55,
380,9,207,4,0,
380,9,210,3,0,
380,9,211,4,0,
380,9,213,4,0,
380,9,214,4,0,
380,9,216,4,0,
380,9,218,4,0,
380,9,219,1,15,
380,9,219,4,0,
380,9,225,1,20,
380,9,237,4,0,
380,9,239,3,0,
380,9,240,4,0,
380,9,241,4,0,
380,9,244,4,0,
380,9,247,4,0,
380,9,263,4,0,
380,9,270,1,10,
380,9,270,3,0,
380,9,271,3,0,
380,9,273,1,5,
380,9,287,1,30,
380,9,290,4,0,
380,9,291,3,0,
380,9,296,1,35,
380,9,332,4,0,
380,9,337,4,0,
380,9,346,1,25,
380,9,347,4,0,
380,9,351,4,0,
380,9,352,4,0,
380,9,355,4,0,
380,9,361,1,60,
380,9,363,4,0,
380,9,375,1,50,
380,9,387,3,0,
380,9,389,3,0,
380,9,406,1,70,
380,9,406,4,0,
380,9,412,4,0,
380,9,416,4,0,
380,9,421,4,0,
380,9,428,1,40,
380,9,428,3,0,
380,9,432,4,0,
380,9,434,3,0,
380,9,445,4,0,
380,9,447,4,0,
380,9,451,4,0,
380,10,15,4,0,
380,10,19,4,0,
380,10,46,4,0,
380,10,57,4,0,
380,10,58,4,0,
380,10,63,4,0,
380,10,76,4,0,
380,10,85,4,0,
380,10,86,4,0,
380,10,87,4,0,
380,10,89,4,0,
380,10,92,4,0,
380,10,94,1,65,
380,10,94,4,0,
380,10,104,4,0,
380,10,105,1,45,
380,10,113,4,0,
380,10,115,4,0,
380,10,127,4,0,
380,10,129,3,0,
380,10,138,4,0,
380,10,148,4,0,
380,10,149,1,1,
380,10,156,4,0,
380,10,164,4,0,
380,10,173,3,0,
380,10,182,4,0,
380,10,189,3,0,
380,10,196,3,0,
380,10,200,3,0,
380,10,201,4,0,
380,10,203,4,0,
380,10,204,1,55,
380,10,207,4,0,
380,10,210,3,0,
380,10,211,4,0,
380,10,213,4,0,
380,10,214,4,0,
380,10,216,4,0,
380,10,218,4,0,
380,10,219,1,15,
380,10,219,4,0,
380,10,225,1,20,
380,10,237,4,0,
380,10,239,3,0,
380,10,240,4,0,
380,10,241,4,0,
380,10,244,4,0,
380,10,247,4,0,
380,10,250,4,0,
380,10,263,4,0,
380,10,270,1,10,
380,10,270,3,0,
380,10,271,3,0,
380,10,272,3,0,
380,10,273,1,5,
380,10,277,3,0,
380,10,287,1,30,
380,10,290,4,0,
380,10,291,3,0,
380,10,296,1,35,
380,10,332,4,0,
380,10,337,4,0,
380,10,346,1,25,
380,10,347,4,0,
380,10,351,4,0,
380,10,352,4,0,
380,10,355,4,0,
380,10,361,1,60,
380,10,363,4,0,
380,10,366,3,0,
380,10,375,1,50,
380,10,387,3,0,
380,10,389,3,0,
380,10,406,1,70,
380,10,406,4,0,
380,10,412,4,0,
380,10,416,4,0,
380,10,421,4,0,
380,10,428,1,40,
380,10,428,3,0,
380,10,434,3,0,
380,10,445,4,0,
380,10,447,4,0,
380,10,451,4,0,
380,11,15,4,0,
380,11,19,4,0,
380,11,46,4,0,
380,11,57,4,0,
380,11,58,4,0,
380,11,63,4,0,
380,11,76,4,0,
380,11,85,4,0,
380,11,86,4,0,
380,11,87,4,0,
380,11,89,4,0,
380,11,92,4,0,
380,11,94,1,60,
380,11,94,4,0,
380,11,104,4,0,
380,11,105,1,45,
380,11,113,4,0,
380,11,115,4,0,
380,11,127,4,0,
380,11,138,4,0,
380,11,148,4,0,
380,11,149,1,1,
380,11,156,4,0,
380,11,164,4,0,
380,11,182,4,0,
380,11,201,4,0,
380,11,204,1,55,
380,11,207,4,0,
380,11,213,4,0,
380,11,216,4,0,
380,11,218,4,0,
380,11,219,1,15,
380,11,219,4,0,
380,11,225,1,20,
380,11,237,4,0,
380,11,240,4,0,
380,11,241,4,0,
380,11,244,4,0,
380,11,247,4,0,
380,11,263,4,0,
380,11,270,1,10,
380,11,273,1,5,
380,11,287,1,30,
380,11,291,4,0,
380,11,296,1,35,
380,11,332,4,0,
380,11,337,4,0,
380,11,346,1,25,
380,11,347,4,0,
380,11,361,1,85,
380,11,375,1,50,
380,11,406,1,80,
380,11,412,4,0,
380,11,416,4,0,
380,11,421,4,0,
380,11,428,1,40,
380,11,434,3,0,
380,11,447,4,0,
380,11,451,4,0,
380,11,468,4,0,
380,11,470,1,75,
380,11,473,4,0,
380,11,477,4,0,
380,11,496,4,0,
380,11,505,1,65,
380,11,513,1,70,
380,11,514,4,0,
380,11,523,4,0,
380,12,15,4,0,
380,12,19,4,0,
380,12,46,4,0,
380,12,57,4,0,
380,12,58,4,0,
380,12,63,4,0,
380,12,76,4,0,
380,12,85,4,0,
380,12,87,4,0,
380,12,89,4,0,
380,12,92,4,0,
380,12,94,1,40,
380,12,94,4,0,
380,12,104,4,0,
380,12,105,1,45,
380,12,113,4,0,
380,12,115,4,0,
380,12,127,4,0,
380,12,148,4,0,
380,12,149,1,1,
380,12,156,4,0,
380,12,182,4,0,
380,12,201,4,0,
380,12,204,1,50,
380,12,211,4,0,
380,12,213,4,0,
380,12,216,4,0,
380,12,218,4,0,
380,12,219,1,15,
380,12,219,4,0,
380,12,225,1,20,
380,12,237,4,0,
380,12,240,4,0,
380,12,241,4,0,
380,12,247,4,0,
380,12,263,4,0,
380,12,270,1,10,
380,12,273,1,5,
380,12,287,1,30,
380,12,290,4,0,
380,12,291,4,0,
380,12,296,1,35,
380,12,332,4,0,
380,12,337,4,0,
380,12,346,1,25,
380,12,347,4,0,
380,12,351,4,0,
380,12,352,4,0,
380,13,15,4,0,
380,13,19,4,0,
380,13,34,3,0,
380,13,38,3,0,
380,13,46,4,0,
380,13,57,4,0,
380,13,58,4,0,
380,13,63,4,0,
380,13,76,4,0,
380,13,85,4,0,
380,13,86,3,0,
380,13,87,4,0,
380,13,89,4,0,
380,13,92,4,0,
380,13,94,1,40,
380,13,94,4,0,
380,13,102,3,0,
380,13,104,4,0,
380,13,105,1,45,
380,13,113,4,0,
380,13,115,4,0,
380,13,127,4,0,
380,13,138,3,0,
380,13,148,4,0,
380,13,149,1,1,
380,13,156,4,0,
380,13,164,3,0,
380,13,182,4,0,
380,13,196,3,0,
380,13,201,4,0,
380,13,204,1,50,
380,13,207,3,0,
380,13,211,4,0,
380,13,213,4,0,
380,13,216,4,0,
380,13,218,4,0,
380,13,219,1,15,
380,13,219,4,0,
380,13,225,1,20,
380,13,237,4,0,
380,13,240,4,0,
380,13,241,4,0,
380,13,247,4,0,
380,13,263,4,0,
380,13,270,1,10,
380,13,273,1,5,
380,13,287,1,30,
380,13,290,4,0,
380,13,291,4,0,
380,13,296,1,35,
380,13,332,4,0,
380,13,337,4,0,
380,13,346,1,25,
380,13,347,4,0,
380,13,351,4,0,
380,13,352,4,0,
380,14,15,4,0,
380,14,19,4,0,
380,14,46,4,0,
380,14,57,4,0,
380,14,58,4,0,
380,14,63,4,0,
380,14,76,4,0,
380,14,85,4,0,
380,14,86,4,0,
380,14,87,4,0,
380,14,89,4,0,
380,14,92,4,0,
380,14,94,1,60,
380,14,94,4,0,
380,14,104,4,0,
380,14,105,1,45,
380,14,113,4,0,
380,14,115,4,0,
380,14,127,4,0,
380,14,138,4,0,
380,14,148,4,0,
380,14,149,1,1,
380,14,156,4,0,
380,14,164,4,0,
380,14,173,3,0,
380,14,182,4,0,
380,14,196,3,0,
380,14,200,3,0,
380,14,201,4,0,
380,14,204,1,55,
380,14,207,4,0,
380,14,213,4,0,
380,14,214,3,0,
380,14,216,4,0,
380,14,218,4,0,
380,14,219,1,15,
380,14,219,4,0,
380,14,225,1,20,
380,14,237,4,0,
380,14,240,4,0,
380,14,241,4,0,
380,14,244,4,0,
380,14,247,4,0,
380,14,263,4,0,
380,14,270,1,10,
380,14,270,3,0,
380,14,271,3,0,
380,14,272,3,0,
380,14,273,1,5,
380,14,277,3,0,
380,14,287,1,30,
380,14,291,4,0,
380,14,296,1,35,
380,14,332,4,0,
380,14,337,4,0,
380,14,343,3,0,
380,14,346,1,25,
380,14,347,4,0,
380,14,355,3,0,
380,14,361,1,85,
380,14,366,3,0,
380,14,375,1,50,
380,14,387,3,0,
380,14,406,1,80,
380,14,406,3,0,
380,14,412,4,0,
380,14,416,4,0,
380,14,421,4,0,
380,14,428,1,40,
380,14,428,3,0,
380,14,434,3,0,
380,14,447,4,0,
380,14,451,4,0,
380,14,468,4,0,
380,14,470,1,75,
380,14,473,4,0,
380,14,477,4,0,
380,14,478,3,0,
380,14,496,4,0,
380,14,505,1,65,
380,14,513,1,70,
380,14,514,4,0,
380,14,523,4,0,
380,15,15,4,0,
380,15,19,4,0,
380,15,46,4,0,
380,15,57,4,0,
380,15,58,4,0,
380,15,63,4,0,
380,15,76,4,0,
380,15,85,4,0,
380,15,86,4,0,
380,15,87,4,0,
380,15,89,4,0,
380,15,92,4,0,
380,15,94,1,60,
380,15,94,4,0,
380,15,104,4,0,
380,15,105,1,45,
380,15,113,4,0,
380,15,115,4,0,
380,15,127,4,0,
380,15,138,4,0,
380,15,148,4,0,
380,15,149,1,1,7
380,15,156,4,0,
380,15,164,4,0,
380,15,182,4,0,
380,15,201,4,0,
380,15,204,1,1,6
380,15,204,1,55,
380,15,207,4,0,
380,15,211,4,0,
380,15,213,4,0,
380,15,214,4,0,
380,15,216,4,0,
380,15,218,4,0,
380,15,219,1,15,
380,15,219,4,0,
380,15,225,1,20,
380,15,237,4,0,
380,15,240,4,0,
380,15,241,4,0,
380,15,244,4,0,
380,15,247,4,0,
380,15,263,4,0,
380,15,270,1,10,
380,15,273,1,5,
380,15,287,1,30,
380,15,296,1,35,
380,15,332,4,0,
380,15,337,4,0,
380,15,346,1,25,
380,15,347,4,0,
380,15,355,4,0,
380,15,361,1,1,1
380,15,361,1,85,
380,15,375,1,50,
380,15,406,1,1,2
380,15,406,1,80,
380,15,412,4,0,
380,15,416,4,0,
380,15,421,4,0,
380,15,428,1,40,
380,15,434,3,0,
380,15,447,4,0,
380,15,451,4,0,
380,15,468,4,0,
380,15,470,1,1,3
380,15,470,1,75,
380,15,473,4,0,
380,15,496,4,0,
380,15,505,1,1,5
380,15,505,1,65,
380,15,513,1,1,4
380,15,513,1,70,
380,15,514,4,0,
380,15,523,4,0,
380,15,590,4,0,
380,16,15,4,0,
380,16,19,4,0,
380,16,46,4,0,
380,16,57,4,0,
380,16,58,4,0,
380,16,63,4,0,
380,16,76,4,0,
380,16,85,4,0,
380,16,86,4,0,
380,16,87,4,0,
380,16,89,4,0,
380,16,92,4,0,
380,16,94,1,51,1
380,16,94,4,0,
380,16,104,4,0,
380,16,105,1,32,1
380,16,113,4,0,
380,16,115,4,0,
380,16,127,4,0,
380,16,138,4,0,
380,16,148,4,0,
380,16,149,1,1,4
380,16,156,4,0,
380,16,164,4,0,
380,16,173,3,0,
380,16,182,4,0,
380,16,196,3,0,
380,16,200,3,0,
380,16,201,4,0,
380,16,204,1,7,1
380,16,207,4,0,
380,16,211,4,0,
380,16,213,4,0,
380,16,214,4,0,
380,16,216,4,0,
380,16,218,4,0,
380,16,219,1,1,5
380,16,219,4,0,
380,16,225,1,20,1
380,16,237,4,0,
380,16,240,4,0,
380,16,241,4,0,
380,16,244,4,0,
380,16,247,4,0,
380,16,263,4,0,
380,16,270,1,1,2
380,16,270,3,0,
380,16,271,3,0,
380,16,272,3,0,
380,16,273,1,1,3
380,16,277,3,0,
380,16,287,1,13,1
380,16,290,4,0,
380,16,291,4,0,
380,16,296,1,24,1
380,16,332,4,0,
380,16,337,4,0,
380,16,343,3,0,
380,16,346,1,4,1
380,16,347,4,0,
380,16,351,3,0,
380,16,352,3,0,
380,16,355,4,0,
380,16,361,1,1,1
380,16,361,1,61,1
380,16,366,3,0,
380,16,375,1,28,1
380,16,387,3,0,
380,16,406,1,56,1
380,16,406,3,0,
380,16,412,4,0,
380,16,416,4,0,
380,16,421,4,0,
380,16,428,1,41,1
380,16,428,3,0,
380,16,434,3,0,
380,16,447,4,0,
380,16,451,4,0,
380,16,468,4,0,
380,16,470,1,46,1
380,16,473,4,0,
380,16,478,3,0,
380,16,496,4,0,
380,16,500,1,10,1
380,16,505,1,16,1
380,16,513,1,36,1
380,16,514,4,0,
380,16,523,4,0,
380,16,590,4,0,
380,17,19,4,0,
380,17,46,4,0,
380,17,57,4,0,
380,17,58,4,0,
380,17,63,4,0,
380,17,76,4,0,
380,17,85,4,0,
380,17,86,4,0,
380,17,87,4,0,
380,17,89,4,0,
380,17,92,4,0,
380,17,94,1,51,
380,17,94,4,0,
380,17,104,4,0,
380,17,105,1,32,
380,17,113,4,0,
380,17,115,4,0,
380,17,127,4,0,
380,17,138,4,0,
380,17,149,1,1,4
380,17,156,4,0,
380,17,164,4,0,
380,17,182,4,0,
380,17,201,4,0,
380,17,204,1,7,
380,17,207,4,0,
380,17,211,4,0,
380,17,213,4,0,
380,17,214,4,0,
380,17,216,4,0,
380,17,218,4,0,
380,17,219,1,1,5
380,17,219,4,0,
380,17,225,1,20,
380,17,237,4,0,
380,17,240,4,0,
380,17,241,4,0,
380,17,244,4,0,
380,17,247,4,0,
380,17,263,4,0,
380,17,270,1,1,2
380,17,273,1,1,3
380,17,287,1,13,
380,17,296,1,24,
380,17,332,4,0,
380,17,337,4,0,
380,17,346,1,4,
380,17,347,4,0,
380,17,355,4,0,
380,17,361,1,1,1
380,17,361,1,61,
380,17,375,1,28,
380,17,406,1,56,
380,17,412,4,0,
380,17,416,4,0,
380,17,421,4,0,
380,17,428,1,41,
380,17,434,3,0,
380,17,447,4,0,
380,17,451,4,0,
380,17,470,1,46,
380,17,473,4,0,
380,17,496,4,0,
380,17,500,1,10,
380,17,505,1,16,
380,17,513,1,36,
380,17,523,4,0,
380,17,590,4,0,
380,18,19,4,0,
380,18,46,4,0,
380,18,57,4,0,
380,18,58,4,0,
380,18,63,4,0,
380,18,76,4,0,
380,18,85,4,0,
380,18,86,4,0,
380,18,87,4,0,
380,18,89,4,0,
380,18,92,4,0,
380,18,94,1,51,
380,18,94,4,0,
380,18,104,4,0,
380,18,105,1,32,
380,18,113,4,0,
380,18,115,4,0,
380,18,127,4,0,
380,18,138,4,0,
380,18,149,1,1,4
380,18,156,4,0,
380,18,164,4,0,
380,18,182,4,0,
380,18,201,4,0,
380,18,204,1,7,
380,18,207,4,0,
380,18,211,4,0,
380,18,213,4,0,
380,18,214,4,0,
380,18,216,4,0,
380,18,218,4,0,
380,18,219,1,1,5
380,18,219,4,0,
380,18,225,1,20,
380,18,237,4,0,
380,18,240,4,0,
380,18,241,4,0,
380,18,244,4,0,
380,18,247,4,0,
380,18,263,4,0,
380,18,270,1,1,2
380,18,273,1,1,3
380,18,287,1,13,
380,18,296,1,24,
380,18,332,4,0,
380,18,337,4,0,
380,18,346,1,4,
380,18,347,4,0,
380,18,355,4,0,
380,18,361,1,1,1
380,18,361,1,61,
380,18,375,1,28,
380,18,406,1,56,
380,18,412,4,0,
380,18,416,4,0,
380,18,421,4,0,
380,18,428,1,41,
380,18,434,3,0,
380,18,447,4,0,
380,18,451,4,0,
380,18,470,1,46,
380,18,473,4,0,
380,18,496,4,0,
380,18,500,1,10,
380,18,505,1,16,
380,18,513,1,36,
380,18,523,4,0,
380,18,590,4,0,
381,5,15,4,0,
381,5,19,4,0,
381,5,46,4,0,
381,5,57,4,0,
381,5,58,4,0,
381,5,63,4,0,
381,5,76,4,0,
381,5,85,4,0,
381,5,87,4,0,
381,5,89,4,0,
381,5,92,4,0,
381,5,94,1,40,
381,5,94,4,0,
381,5,104,4,0,
381,5,105,1,45,
381,5,113,4,0,
381,5,115,4,0,
381,5,127,4,0,
381,5,148,4,0,
381,5,149,1,1,
381,5,156,4,0,
381,5,182,1,25,
381,5,182,4,0,
381,5,201,4,0,
381,5,211,4,0,
381,5,213,4,0,
381,5,216,4,0,
381,5,218,4,0,
381,5,219,1,15,
381,5,219,4,0,
381,5,225,1,20,
381,5,237,4,0,
381,5,240,4,0,
381,5,241,4,0,
381,5,247,4,0,
381,5,262,1,5,
381,5,263,4,0,
381,5,270,1,10,
381,5,287,1,30,
381,5,290,4,0,
381,5,291,4,0,
381,5,295,1,35,
381,5,332,4,0,
381,5,337,4,0,
381,5,347,4,0,
381,5,349,1,50,
381,5,351,4,0,
381,5,352,4,0,
381,6,15,4,0,
381,6,19,4,0,
381,6,34,3,0,
381,6,38,3,0,
381,6,46,4,0,
381,6,57,4,0,
381,6,58,4,0,
381,6,63,4,0,
381,6,76,4,0,
381,6,85,4,0,
381,6,86,3,0,
381,6,87,4,0,
381,6,89,4,0,
381,6,92,4,0,
381,6,94,1,40,
381,6,94,4,0,
381,6,102,3,0,
381,6,104,4,0,
381,6,105,1,45,
381,6,113,4,0,
381,6,115,4,0,
381,6,127,4,0,
381,6,129,3,0,
381,6,138,3,0,
381,6,148,4,0,
381,6,149,1,1,
381,6,156,4,0,
381,6,164,3,0,
381,6,173,3,0,
381,6,182,1,25,
381,6,182,4,0,
381,6,189,3,0,
381,6,196,3,0,
381,6,201,4,0,
381,6,203,3,0,
381,6,207,3,0,
381,6,210,3,0,
381,6,211,4,0,
381,6,213,4,0,
381,6,214,3,0,
381,6,216,4,0,
381,6,218,4,0,
381,6,219,1,15,
381,6,219,4,0,
381,6,225,1,20,
381,6,237,4,0,
381,6,240,4,0,
381,6,241,4,0,
381,6,244,3,0,
381,6,247,4,0,
381,6,262,1,5,
381,6,263,4,0,
381,6,270,1,10,
381,6,287,1,30,
381,6,290,4,0,
381,6,291,4,0,
381,6,295,1,35,
381,6,332,4,0,
381,6,337,4,0,
381,6,347,4,0,
381,6,349,1,50,
381,6,351,4,0,
381,6,352,4,0,
381,7,15,4,0,
381,7,19,4,0,
381,7,34,3,0,
381,7,38,3,0,
381,7,46,4,0,
381,7,57,4,0,
381,7,58,4,0,
381,7,63,4,0,
381,7,76,4,0,
381,7,85,4,0,
381,7,86,3,0,
381,7,87,4,0,
381,7,89,4,0,
381,7,92,4,0,
381,7,94,1,40,
381,7,94,4,0,
381,7,102,3,0,
381,7,104,4,0,
381,7,105,1,45,
381,7,113,4,0,
381,7,115,4,0,
381,7,127,4,0,
381,7,138,3,0,
381,7,148,4,0,
381,7,149,1,1,
381,7,156,4,0,
381,7,164,3,0,
381,7,182,1,25,
381,7,182,4,0,
381,7,201,4,0,
381,7,211,4,0,
381,7,213,4,0,
381,7,216,4,0,
381,7,218,4,0,
381,7,219,1,15,
381,7,219,4,0,
381,7,225,1,20,
381,7,237,4,0,
381,7,240,4,0,
381,7,241,4,0,
381,7,247,4,0,
381,7,262,1,5,
381,7,263,4,0,
381,7,270,1,10,
381,7,287,1,30,
381,7,290,4,0,
381,7,291,4,0,
381,7,295,1,35,
381,7,332,4,0,
381,7,337,4,0,
381,7,347,4,0,
381,7,349,1,50,
381,7,351,4,0,
381,7,352,4,0,
381,8,15,4,0,
381,8,19,4,0,
381,8,46,4,0,
381,8,57,4,0,
381,8,58,4,0,
381,8,63,4,0,
381,8,76,4,0,
381,8,85,4,0,
381,8,86,4,0,
381,8,87,4,0,
381,8,89,4,0,
381,8,92,4,0,
381,8,94,1,65,
381,8,94,4,0,
381,8,104,4,0,
381,8,105,1,45,
381,8,113,4,0,
381,8,115,4,0,
381,8,127,4,0,
381,8,138,4,0,
381,8,148,4,0,
381,8,149,1,1,
381,8,156,4,0,
381,8,164,4,0,
381,8,182,1,25,
381,8,182,4,0,
381,8,201,4,0,
381,8,203,4,0,
381,8,207,4,0,
381,8,211,4,0,
381,8,213,4,0,
381,8,214,4,0,
381,8,216,4,0,
381,8,218,4,0,
381,8,219,1,15,
381,8,219,4,0,
381,8,225,1,20,
381,8,237,4,0,
381,8,240,4,0,
381,8,241,4,0,
381,8,244,4,0,
381,8,247,4,0,
381,8,262,1,60,
381,8,263,4,0,
381,8,270,1,10,
381,8,287,1,30,
381,8,290,4,0,
381,8,295,1,35,
381,8,332,4,0,
381,8,337,4,0,
381,8,347,4,0,
381,8,349,1,55,
381,8,351,4,0,
381,8,352,4,0,
381,8,355,4,0,
381,8,363,4,0,
381,8,375,1,50,
381,8,377,1,5,
381,8,406,1,70,
381,8,406,4,0,
381,8,412,4,0,
381,8,416,4,0,
381,8,421,4,0,
381,8,428,1,40,
381,8,432,4,0,
381,8,434,3,0,
381,8,445,4,0,
381,8,447,4,0,
381,8,451,4,0,
381,9,15,4,0,
381,9,19,4,0,
381,9,46,4,0,
381,9,57,4,0,
381,9,58,4,0,
381,9,63,4,0,
381,9,76,4,0,
381,9,85,4,0,
381,9,86,4,0,
381,9,87,4,0,
381,9,89,4,0,
381,9,92,4,0,
381,9,94,1,65,
381,9,94,4,0,
381,9,104,4,0,
381,9,105,1,45,
381,9,113,4,0,
381,9,115,4,0,
381,9,127,4,0,
381,9,129,3,0,
381,9,138,4,0,
381,9,148,4,0,
381,9,149,1,1,
381,9,156,4,0,
381,9,164,4,0,
381,9,173,3,0,
381,9,182,1,25,
381,9,182,4,0,
381,9,189,3,0,
381,9,196,3,0,
381,9,200,3,0,
381,9,201,4,0,
381,9,203,4,0,
381,9,207,4,0,
381,9,210,3,0,
381,9,211,4,0,
381,9,213,4,0,
381,9,214,4,0,
381,9,216,4,0,
381,9,218,4,0,
381,9,219,1,15,
381,9,219,4,0,
381,9,225,1,20,
381,9,237,4,0,
381,9,239,3,0,
381,9,240,4,0,
381,9,241,4,0,
381,9,244,4,0,
381,9,247,4,0,
381,9,262,1,60,
381,9,263,4,0,
381,9,270,1,10,
381,9,270,3,0,
381,9,271,3,0,
381,9,287,1,30,
381,9,290,4,0,
381,9,291,3,0,
381,9,295,1,35,
381,9,332,4,0,
381,9,337,4,0,
381,9,347,4,0,
381,9,349,1,55,
381,9,351,4,0,
381,9,352,4,0,
381,9,355,4,0,
381,9,363,4,0,
381,9,375,1,50,
381,9,377,1,5,
381,9,387,3,0,
381,9,406,1,70,
381,9,406,4,0,
381,9,412,4,0,
381,9,416,4,0,
381,9,421,4,0,
381,9,428,1,40,
381,9,428,3,0,
381,9,432,4,0,
381,9,434,3,0,
381,9,445,4,0,
381,9,447,4,0,
381,9,451,4,0,
381,10,15,4,0,
381,10,19,4,0,
381,10,46,4,0,
381,10,57,4,0,
381,10,58,4,0,
381,10,63,4,0,
381,10,76,4,0,
381,10,85,4,0,
381,10,86,4,0,
381,10,87,4,0,
381,10,89,4,0,
381,10,92,4,0,
381,10,94,1,65,
381,10,94,4,0,
381,10,104,4,0,
381,10,105,1,45,
381,10,113,4,0,
381,10,115,4,0,
381,10,127,4,0,
381,10,129,3,0,
381,10,138,4,0,
381,10,148,4,0,
381,10,149,1,1,
381,10,156,4,0,
381,10,164,4,0,
381,10,173,3,0,
381,10,182,1,25,
381,10,182,4,0,
381,10,189,3,0,
381,10,196,3,0,
381,10,200,3,0,
381,10,201,4,0,
381,10,203,4,0,
381,10,207,4,0,
381,10,210,3,0,
381,10,211,4,0,
381,10,213,4,0,
381,10,214,4,0,
381,10,216,4,0,
381,10,218,4,0,
381,10,219,1,15,
381,10,219,4,0,
381,10,225,1,20,
381,10,237,4,0,
381,10,239,3,0,
381,10,240,4,0,
381,10,241,4,0,
381,10,244,4,0,
381,10,247,4,0,
381,10,250,4,0,
381,10,262,1,60,
381,10,263,4,0,
381,10,270,1,10,
381,10,270,3,0,
381,10,271,3,0,
381,10,277,3,0,
381,10,287,1,30,
381,10,290,4,0,
381,10,291,3,0,
381,10,295,1,35,
381,10,332,4,0,
381,10,337,4,0,
381,10,347,4,0,
381,10,349,1,55,
381,10,351,4,0,
381,10,352,4,0,
381,10,355,4,0,
381,10,363,4,0,
381,10,366,3,0,
381,10,375,1,50,
381,10,377,1,5,
381,10,387,3,0,
381,10,406,1,70,
381,10,406,4,0,
381,10,412,4,0,
381,10,416,4,0,
381,10,421,4,0,
381,10,428,1,40,
381,10,428,3,0,
381,10,434,3,0,
381,10,445,4,0,
381,10,447,4,0,
381,10,451,4,0,
381,11,15,4,0,
381,11,19,4,0,
381,11,46,4,0,
381,11,57,4,0,
381,11,58,4,0,
381,11,63,4,0,
381,11,76,4,0,
381,11,85,4,0,
381,11,86,4,0,
381,11,87,4,0,
381,11,89,4,0,
381,11,92,4,0,
381,11,94,1,60,
381,11,94,4,0,
381,11,104,4,0,
381,11,105,1,45,
381,11,113,4,0,
381,11,115,4,0,
381,11,127,4,0,
381,11,138,4,0,
381,11,148,4,0,
381,11,149,1,1,
381,11,156,4,0,
381,11,164,4,0,
381,11,182,1,25,
381,11,182,4,0,
381,11,201,4,0,
381,11,207,4,0,
381,11,213,4,0,
381,11,216,4,0,
381,11,218,4,0,
381,11,219,1,15,
381,11,219,4,0,
381,11,225,1,20,
381,11,237,4,0,
381,11,240,4,0,
381,11,241,4,0,
381,11,244,4,0,
381,11,247,4,0,
381,11,262,1,85,
381,11,263,4,0,
381,11,270,1,10,
381,11,287,1,30,
381,11,291,4,0,
381,11,295,1,35,
381,11,332,4,0,
381,11,337,4,0,
381,11,347,4,0,
381,11,349,1,55,
381,11,375,1,50,
381,11,377,1,5,
381,11,406,1,80,
381,11,412,4,0,
381,11,416,4,0,
381,11,421,4,0,
381,11,428,1,40,
381,11,434,3,0,
381,11,447,4,0,
381,11,451,4,0,
381,11,468,4,0,
381,11,471,1,75,
381,11,473,4,0,
381,11,477,1,70,
381,11,477,4,0,
381,11,496,4,0,
381,11,505,1,65,
381,11,514,4,0,
381,11,523,4,0,
381,12,15,4,0,
381,12,19,4,0,
381,12,46,4,0,
381,12,57,4,0,
381,12,58,4,0,
381,12,63,4,0,
381,12,76,4,0,
381,12,85,4,0,
381,12,87,4,0,
381,12,89,4,0,
381,12,92,4,0,
381,12,94,1,40,
381,12,94,4,0,
381,12,104,4,0,
381,12,105,1,45,
381,12,113,4,0,
381,12,115,4,0,
381,12,127,4,0,
381,12,148,4,0,
381,12,149,1,1,
381,12,156,4,0,
381,12,182,1,25,
381,12,182,4,0,
381,12,201,4,0,
381,12,211,4,0,
381,12,213,4,0,
381,12,216,4,0,
381,12,218,4,0,
381,12,219,1,15,
381,12,219,4,0,
381,12,225,1,20,
381,12,237,4,0,
381,12,240,4,0,
381,12,241,4,0,
381,12,247,4,0,
381,12,262,1,5,
381,12,263,4,0,
381,12,270,1,10,
381,12,287,1,30,
381,12,290,4,0,
381,12,291,4,0,
381,12,295,1,35,
381,12,332,4,0,
381,12,337,4,0,
381,12,347,4,0,
381,12,349,1,50,
381,12,351,4,0,
381,12,352,4,0,
381,13,15,4,0,
381,13,19,4,0,
381,13,34,3,0,
381,13,38,3,0,
381,13,46,4,0,
381,13,57,4,0,
381,13,58,4,0,
381,13,63,4,0,
381,13,76,4,0,
381,13,85,4,0,
381,13,86,3,0,
381,13,87,4,0,
381,13,89,4,0,
381,13,92,4,0,
381,13,94,1,40,
381,13,94,4,0,
381,13,102,3,0,
381,13,104,4,0,
381,13,105,1,45,
381,13,113,4,0,
381,13,115,4,0,
381,13,127,4,0,
381,13,138,3,0,
381,13,148,4,0,
381,13,149,1,1,
381,13,156,4,0,
381,13,164,3,0,
381,13,182,1,25,
381,13,182,4,0,
381,13,196,3,0,
381,13,201,4,0,
381,13,207,3,0,
381,13,211,4,0,
381,13,213,4,0,
381,13,216,4,0,
381,13,218,4,0,
381,13,219,1,15,
381,13,219,4,0,
381,13,225,1,20,
381,13,237,4,0,
381,13,240,4,0,
381,13,241,4,0,
381,13,247,4,0,
381,13,262,1,5,
381,13,263,4,0,
381,13,270,1,10,
381,13,287,1,30,
381,13,290,4,0,
381,13,291,4,0,
381,13,295,1,35,
381,13,332,4,0,
381,13,337,4,0,
381,13,347,4,0,
381,13,349,1,50,
381,13,351,4,0,
381,13,352,4,0,
381,14,15,4,0,
381,14,19,4,0,
381,14,46,4,0,
381,14,57,4,0,
381,14,58,4,0,
381,14,63,4,0,
381,14,76,4,0,
381,14,85,4,0,
381,14,86,4,0,
381,14,87,4,0,
381,14,89,4,0,
381,14,92,4,0,
381,14,94,1,60,
381,14,94,4,0,
381,14,104,4,0,
381,14,105,1,45,
381,14,113,4,0,
381,14,115,4,0,
381,14,127,4,0,
381,14,138,4,0,
381,14,148,4,0,
381,14,149,1,1,
381,14,156,4,0,
381,14,164,4,0,
381,14,173,3,0,
381,14,182,1,25,
381,14,182,4,0,
381,14,196,3,0,
381,14,200,3,0,
381,14,201,4,0,
381,14,207,4,0,
381,14,213,4,0,
381,14,214,3,0,
381,14,216,4,0,
381,14,218,4,0,
381,14,219,1,15,
381,14,219,4,0,
381,14,225,1,20,
381,14,237,4,0,
381,14,240,4,0,
381,14,241,4,0,
381,14,244,4,0,
381,14,247,4,0,
381,14,262,1,85,
381,14,263,4,0,
381,14,270,1,10,
381,14,270,3,0,
381,14,271,3,0,
381,14,277,3,0,
381,14,287,1,30,
381,14,291,4,0,
381,14,295,1,35,
381,14,332,4,0,
381,14,337,4,0,
381,14,347,4,0,
381,14,349,1,55,
381,14,355,3,0,
381,14,366,3,0,
381,14,375,1,50,
381,14,377,1,5,
381,14,387,3,0,
381,14,406,1,80,
381,14,406,3,0,
381,14,412,4,0,
381,14,416,4,0,
381,14,421,4,0,
381,14,428,1,40,
381,14,428,3,0,
381,14,434,3,0,
381,14,447,4,0,
381,14,451,4,0,
381,14,468,4,0,
381,14,471,1,75,
381,14,472,3,0,
381,14,473,4,0,
381,14,477,1,70,
381,14,477,4,0,
381,14,496,4,0,
381,14,505,1,65,
381,14,514,4,0,
381,14,523,4,0,
381,15,15,4,0,
381,15,19,4,0,
381,15,46,4,0,
381,15,57,4,0,
381,15,58,4,0,
381,15,63,4,0,
381,15,76,4,0,
381,15,85,4,0,
381,15,86,4,0,
381,15,87,4,0,
381,15,89,4,0,
381,15,92,4,0,
381,15,94,1,60,
381,15,94,4,0,
381,15,104,4,0,
381,15,105,1,45,
381,15,113,4,0,
381,15,115,4,0,
381,15,127,4,0,
381,15,138,4,0,
381,15,148,4,0,
381,15,149,1,1,7
381,15,156,4,0,
381,15,164,4,0,
381,15,182,1,25,
381,15,182,4,0,
381,15,201,4,0,
381,15,207,4,0,
381,15,211,4,0,
381,15,213,4,0,
381,15,214,4,0,
381,15,216,4,0,
381,15,218,4,0,
381,15,219,1,15,
381,15,219,4,0,
381,15,225,1,20,
381,15,237,4,0,
381,15,240,4,0,
381,15,241,4,0,
381,15,244,4,0,
381,15,247,4,0,
381,15,262,1,1,1
381,15,262,1,85,
381,15,263,4,0,
381,15,270,1,10,
381,15,287,1,30,
381,15,295,1,35,
381,15,332,4,0,
381,15,337,4,0,
381,15,347,4,0,
381,15,349,1,1,6
381,15,349,1,55,
381,15,355,4,0,
381,15,375,1,50,
381,15,377,1,5,
381,15,406,1,1,2
381,15,406,1,80,
381,15,412,4,0,
381,15,416,4,0,
381,15,421,4,0,
381,15,428,1,40,
381,15,434,3,0,
381,15,447,4,0,
381,15,451,4,0,
381,15,468,4,0,
381,15,471,1,1,3
381,15,471,1,75,
381,15,473,4,0,
381,15,477,1,1,4
381,15,477,1,70,
381,15,496,4,0,
381,15,505,1,1,5
381,15,505,1,65,
381,15,514,4,0,
381,15,523,4,0,
381,15,590,4,0,
381,16,15,4,0,
381,16,19,4,0,
381,16,46,4,0,
381,16,57,4,0,
381,16,58,4,0,
381,16,63,4,0,
381,16,76,4,0,
381,16,85,4,0,
381,16,86,4,0,
381,16,87,4,0,
381,16,89,4,0,
381,16,92,4,0,
381,16,94,1,51,1
381,16,94,4,0,
381,16,104,4,0,
381,16,105,1,32,1
381,16,113,4,0,
381,16,115,4,0,
381,16,127,4,0,
381,16,138,4,0,
381,16,148,4,0,
381,16,149,1,1,4
381,16,156,4,0,
381,16,164,4,0,
381,16,173,3,0,
381,16,182,1,4,1
381,16,182,4,0,
381,16,196,3,0,
381,16,200,3,0,
381,16,201,4,0,
381,16,207,4,0,
381,16,211,4,0,
381,16,213,4,0,
381,16,214,4,0,
381,16,216,4,0,
381,16,218,4,0,
381,16,219,1,1,5
381,16,219,4,0,
381,16,225,1,20,1
381,16,237,4,0,
381,16,240,4,0,
381,16,241,4,0,
381,16,244,4,0,
381,16,247,4,0,
381,16,262,1,1,1
381,16,262,1,61,1
381,16,263,4,0,
381,16,270,1,1,2
381,16,270,3,0,
381,16,271,3,0,
381,16,277,3,0,
381,16,287,1,13,1
381,16,290,4,0,
381,16,291,4,0,
381,16,295,1,24,1
381,16,332,4,0,
381,16,337,4,0,
381,16,347,4,0,
381,16,349,1,7,1
381,16,351,3,0,
381,16,352,3,0,
381,16,355,4,0,
381,16,366,3,0,
381,16,375,1,28,1
381,16,377,1,1,3
381,16,387,3,0,
381,16,406,1,56,1
381,16,406,3,0,
381,16,412,4,0,
381,16,416,4,0,
381,16,421,4,0,
381,16,428,1,41,1
381,16,428,3,0,
381,16,434,3,0,
381,16,447,4,0,
381,16,451,4,0,
381,16,468,4,0,
381,16,471,1,46,1
381,16,472,3,0,
381,16,473,4,0,
381,16,477,1,36,1
381,16,496,4,0,
381,16,500,1,10,1
381,16,505,1,16,1
381,16,514,4,0,
381,16,523,4,0,
381,16,590,4,0,
381,17,19,4,0,
381,17,46,4,0,
381,17,57,4,0,
381,17,58,4,0,
381,17,63,4,0,
381,17,76,4,0,
381,17,85,4,0,
381,17,86,4,0,
381,17,87,4,0,
381,17,89,4,0,
381,17,92,4,0,
381,17,94,1,51,
381,17,94,4,0,
381,17,104,4,0,
381,17,105,1,32,
381,17,113,4,0,
381,17,115,4,0,
381,17,127,4,0,
381,17,138,4,0,
381,17,149,1,1,4
381,17,156,4,0,
381,17,164,4,0,
381,17,182,1,4,
381,17,182,4,0,
381,17,201,4,0,
381,17,207,4,0,
381,17,211,4,0,
381,17,213,4,0,
381,17,214,4,0,
381,17,216,4,0,
381,17,218,4,0,
381,17,219,1,1,5
381,17,219,4,0,
381,17,225,1,20,
381,17,237,4,0,
381,17,240,4,0,
381,17,241,4,0,
381,17,244,4,0,
381,17,247,4,0,
381,17,262,1,1,1
381,17,262,1,61,
381,17,263,4,0,
381,17,270,1,1,2
381,17,287,1,13,
381,17,295,1,24,
381,17,332,4,0,
381,17,337,4,0,
381,17,347,4,0,
381,17,349,1,7,
381,17,355,4,0,
381,17,375,1,28,
381,17,377,1,1,3
381,17,406,1,56,
381,17,412,4,0,
381,17,416,4,0,
381,17,421,4,0,
381,17,428,1,41,
381,17,434,3,0,
381,17,447,4,0,
381,17,451,4,0,
381,17,471,1,46,
381,17,473,4,0,
381,17,477,1,36,
381,17,496,4,0,
381,17,500,1,10,
381,17,505,1,16,
381,17,523,4,0,
381,17,590,4,0,
381,18,19,4,0,
381,18,46,4,0,
381,18,57,4,0,
381,18,58,4,0,
381,18,63,4,0,
381,18,76,4,0,
381,18,85,4,0,
381,18,86,4,0,
381,18,87,4,0,
381,18,89,4,0,
381,18,92,4,0,
381,18,94,1,51,
381,18,94,4,0,
381,18,104,4,0,
381,18,105,1,32,
381,18,113,4,0,
381,18,115,4,0,
381,18,127,4,0,
381,18,138,4,0,
381,18,149,1,1,4
381,18,156,4,0,
381,18,164,4,0,
381,18,182,1,4,
381,18,182,4,0,
381,18,201,4,0,
381,18,207,4,0,
381,18,211,4,0,
381,18,213,4,0,
381,18,214,4,0,
381,18,216,4,0,
381,18,218,4,0,
381,18,219,1,1,5
381,18,219,4,0,
381,18,225,1,20,
381,18,237,4,0,
381,18,240,4,0,
381,18,241,4,0,
381,18,244,4,0,
381,18,247,4,0,
381,18,262,1,1,1
381,18,262,1,61,
381,18,263,4,0,
381,18,270,1,1,2
381,18,287,1,13,
381,18,295,1,24,
381,18,332,4,0,
381,18,337,4,0,
381,18,347,4,0,
381,18,349,1,7,
381,18,355,4,0,
381,18,375,1,28,
381,18,377,1,1,3
381,18,406,1,56,
381,18,412,4,0,
381,18,416,4,0,
381,18,421,4,0,
381,18,428,1,41,
381,18,434,3,0,
381,18,447,4,0,
381,18,451,4,0,
381,18,471,1,46,
381,18,473,4,0,
381,18,477,1,36,
381,18,496,4,0,
381,18,500,1,10,
381,18,505,1,16,
381,18,523,4,0,
381,18,590,4,0,
382,5,34,1,20,
382,5,38,1,65,
382,5,46,4,0,
382,5,56,1,45,
382,5,57,4,0,
382,5,58,1,35,
382,5,58,4,0,
382,5,59,4,0,
382,5,63,4,0,
382,5,70,4,0,
382,5,85,4,0,
382,5,87,4,0,
382,5,89,4,0,
382,5,92,4,0,
382,5,104,4,0,
382,5,127,4,0,
382,5,156,1,50,
382,5,156,4,0,
382,5,182,4,0,
382,5,184,1,5,
382,5,216,4,0,
382,5,218,4,0,
382,5,219,4,0,
382,5,237,4,0,
382,5,240,4,0,
382,5,246,1,15,
382,5,249,4,0,
382,5,258,4,0,
382,5,263,4,0,
382,5,280,4,0,
382,5,290,4,0,
382,5,291,4,0,
382,5,317,4,0,
382,5,323,1,75,
382,5,329,1,60,
382,5,347,1,30,
382,5,347,4,0,
382,5,351,4,0,
382,5,352,1,1,
382,5,352,4,0,
382,6,34,1,20,
382,6,34,3,0,
382,6,38,1,65,
382,6,38,3,0,
382,6,46,4,0,
382,6,56,1,45,
382,6,57,4,0,
382,6,58,1,35,
382,6,58,4,0,
382,6,59,4,0,
382,6,63,4,0,
382,6,70,4,0,
382,6,85,4,0,
382,6,86,3,0,
382,6,87,4,0,
382,6,89,4,0,
382,6,92,4,0,
382,6,102,3,0,
382,6,104,4,0,
382,6,111,3,0,
382,6,127,4,0,
382,6,129,3,0,
382,6,156,1,50,
382,6,156,4,0,
382,6,157,3,0,
382,6,164,3,0,
382,6,173,3,0,
382,6,182,4,0,
382,6,184,1,5,
382,6,189,3,0,
382,6,196,3,0,
382,6,203,3,0,
382,6,207,3,0,
382,6,214,3,0,
382,6,216,4,0,
382,6,218,4,0,
382,6,219,4,0,
382,6,237,4,0,
382,6,240,4,0,
382,6,244,3,0,
382,6,246,1,15,
382,6,249,4,0,
382,6,258,4,0,
382,6,263,4,0,
382,6,280,4,0,
382,6,290,4,0,
382,6,291,4,0,
382,6,317,4,0,
382,6,323,1,75,
382,6,329,1,60,
382,6,347,1,30,
382,6,347,4,0,
382,6,351,4,0,
382,6,352,1,1,
382,6,352,4,0,
382,7,34,1,20,
382,7,34,3,0,
382,7,38,1,65,
382,7,38,3,0,
382,7,46,4,0,
382,7,56,1,45,
382,7,57,4,0,
382,7,58,1,35,
382,7,58,4,0,
382,7,59,4,0,
382,7,63,4,0,
382,7,70,4,0,
382,7,85,4,0,
382,7,86,3,0,
382,7,87,4,0,
382,7,89,4,0,
382,7,92,4,0,
382,7,102,3,0,
382,7,104,4,0,
382,7,127,4,0,
382,7,156,1,50,
382,7,156,4,0,
382,7,157,3,0,
382,7,164,3,0,
382,7,182,4,0,
382,7,184,1,5,
382,7,216,4,0,
382,7,218,4,0,
382,7,219,4,0,
382,7,237,4,0,
382,7,240,4,0,
382,7,246,1,15,
382,7,249,4,0,
382,7,258,4,0,
382,7,263,4,0,
382,7,280,4,0,
382,7,290,4,0,
382,7,291,4,0,
382,7,317,4,0,
382,7,323,1,75,
382,7,329,1,60,
382,7,347,1,30,
382,7,347,4,0,
382,7,351,4,0,
382,7,352,1,1,
382,7,352,4,0,
382,8,34,1,20,
382,8,38,1,65,
382,8,46,4,0,
382,8,56,1,45,
382,8,57,4,0,
382,8,58,1,35,
382,8,58,4,0,
382,8,59,4,0,
382,8,63,4,0,
382,8,70,4,0,
382,8,85,4,0,
382,8,86,4,0,
382,8,87,4,0,
382,8,89,4,0,
382,8,92,4,0,
382,8,104,4,0,
382,8,127,4,0,
382,8,156,1,50,
382,8,156,4,0,
382,8,157,4,0,
382,8,164,4,0,
382,8,182,4,0,
382,8,184,1,5,
382,8,203,4,0,
382,8,207,4,0,
382,8,214,4,0,
382,8,216,4,0,
382,8,218,4,0,
382,8,219,4,0,
382,8,237,4,0,
382,8,240,4,0,
382,8,244,4,0,
382,8,246,1,15,
382,8,249,4,0,
382,8,258,4,0,
382,8,263,4,0,
382,8,280,4,0,
382,8,290,4,0,
382,8,317,4,0,
382,8,323,1,80,
382,8,329,1,60,
382,8,347,1,30,
382,8,347,4,0,
382,8,351,4,0,
382,8,352,1,1,
382,8,352,4,0,
382,8,362,4,0,
382,8,363,4,0,
382,8,401,1,75,
382,8,416,4,0,
382,8,419,4,0,
382,9,34,1,20,
382,9,38,1,65,
382,9,46,4,0,
382,9,56,1,45,
382,9,57,4,0,
382,9,58,1,35,
382,9,58,4,0,
382,9,59,4,0,
382,9,63,4,0,
382,9,70,4,0,
382,9,85,4,0,
382,9,86,4,0,
382,9,87,4,0,
382,9,89,4,0,
382,9,92,4,0,
382,9,104,4,0,
382,9,127,4,0,
382,9,129,3,0,
382,9,156,1,50,
382,9,156,4,0,
382,9,157,4,0,
382,9,164,4,0,
382,9,173,3,0,
382,9,182,4,0,
382,9,184,1,5,
382,9,189,3,0,
382,9,196,3,0,
382,9,203,4,0,
382,9,207,4,0,
382,9,214,4,0,
382,9,216,4,0,
382,9,218,4,0,
382,9,219,4,0,
382,9,237,4,0,
382,9,240,4,0,
382,9,244,4,0,
382,9,246,1,15,
382,9,246,3,0,
382,9,249,4,0,
382,9,253,3,0,
382,9,258,4,0,
382,9,263,4,0,
382,9,280,4,0,
382,9,290,4,0,
382,9,291,3,0,
382,9,317,4,0,
382,9,323,1,80,
382,9,324,3,0,
382,9,329,1,60,
382,9,347,1,30,
382,9,347,4,0,
382,9,351,4,0,
382,9,352,1,1,
382,9,352,4,0,
382,9,362,4,0,
382,9,363,4,0,
382,9,401,1,75,
382,9,401,3,0,
382,9,416,4,0,
382,9,419,4,0,
382,9,442,3,0,
382,10,29,3,0,
382,10,34,1,15,
382,10,38,1,80,
382,10,46,4,0,
382,10,56,1,90,
382,10,57,4,0,
382,10,58,1,35,
382,10,58,4,0,
382,10,59,4,0,
382,10,63,4,0,
382,10,70,4,0,
382,10,85,4,0,
382,10,86,4,0,
382,10,87,4,0,
382,10,89,4,0,
382,10,92,4,0,
382,10,104,4,0,
382,10,127,4,0,
382,10,129,3,0,
382,10,156,4,0,
382,10,157,4,0,
382,10,164,4,0,
382,10,173,3,0,
382,10,182,4,0,
382,10,184,1,5,
382,10,189,3,0,
382,10,196,3,0,
382,10,203,4,0,
382,10,207,4,0,
382,10,214,4,0,
382,10,216,4,0,
382,10,218,4,0,
382,10,219,4,0,
382,10,237,4,0,
382,10,240,4,0,
382,10,244,4,0,
382,10,246,1,45,
382,10,246,3,0,
382,10,249,4,0,
382,10,250,4,0,
382,10,253,3,0,
382,10,258,4,0,
382,10,263,4,0,
382,10,280,4,0,
382,10,290,4,0,
382,10,291,3,0,
382,10,317,4,0,
382,10,323,1,50,
382,10,324,3,0,
382,10,329,1,75,
382,10,330,1,20,
382,10,335,3,0,
382,10,347,1,60,
382,10,347,4,0,
382,10,351,4,0,
382,10,352,1,1,
382,10,352,4,0,
382,10,362,4,0,
382,10,363,4,0,
382,10,392,1,30,
382,10,401,1,65,
382,10,401,3,0,
382,10,416,4,0,
382,10,419,4,0,
382,10,442,3,0,
382,11,34,1,15,
382,11,38,1,80,
382,11,46,4,0,
382,11,56,1,90,
382,11,57,4,0,
382,11,58,1,35,
382,11,58,4,0,
382,11,59,4,0,
382,11,63,4,0,
382,11,70,4,0,
382,11,85,4,0,
382,11,86,4,0,
382,11,87,4,0,
382,11,89,4,0,
382,11,92,4,0,
382,11,104,4,0,
382,11,127,4,0,
382,11,156,4,0,
382,11,157,4,0,
382,11,164,4,0,
382,11,182,4,0,
382,11,184,1,5,
382,11,207,4,0,
382,11,216,4,0,
382,11,218,4,0,
382,11,219,4,0,
382,11,237,4,0,
382,11,240,4,0,
382,11,244,4,0,
382,11,246,1,45,
382,11,249,4,0,
382,11,258,4,0,
382,11,263,4,0,
382,11,280,4,0,
382,11,291,4,0,
382,11,317,4,0,
382,11,323,1,50,
382,11,329,1,75,
382,11,330,1,20,
382,11,347,1,60,
382,11,347,4,0,
382,11,352,1,1,
382,11,392,1,30,
382,11,401,1,65,
382,11,416,4,0,
382,11,496,4,0,
382,11,503,4,0,
382,11,523,4,0,
382,12,34,1,20,
382,12,38,1,65,
382,12,46,4,0,
382,12,56,1,45,
382,12,57,4,0,
382,12,58,1,35,
382,12,58,4,0,
382,12,59,4,0,
382,12,63,4,0,
382,12,70,4,0,
382,12,85,4,0,
382,12,87,4,0,
382,12,89,4,0,
382,12,92,4,0,
382,12,104,4,0,
382,12,127,4,0,
382,12,156,1,50,
382,12,156,4,0,
382,12,182,4,0,
382,12,184,1,5,
382,12,216,4,0,
382,12,218,4,0,
382,12,219,4,0,
382,12,237,4,0,
382,12,240,4,0,
382,12,246,1,15,
382,12,249,4,0,
382,12,258,4,0,
382,12,263,4,0,
382,12,280,4,0,
382,12,290,4,0,
382,12,291,4,0,
382,12,317,4,0,
382,12,323,1,75,
382,12,329,1,60,
382,12,347,1,30,
382,12,347,4,0,
382,12,351,4,0,
382,12,352,1,1,
382,12,352,4,0,
382,13,34,1,20,
382,13,34,3,0,
382,13,38,1,65,
382,13,38,3,0,
382,13,46,4,0,
382,13,56,1,45,
382,13,57,4,0,
382,13,58,1,35,
382,13,58,4,0,
382,13,59,4,0,
382,13,63,4,0,
382,13,70,4,0,
382,13,85,4,0,
382,13,86,3,0,
382,13,87,4,0,
382,13,89,4,0,
382,13,92,4,0,
382,13,102,3,0,
382,13,104,4,0,
382,13,127,4,0,
382,13,156,1,50,
382,13,156,4,0,
382,13,164,3,0,
382,13,182,4,0,
382,13,184,1,5,
382,13,196,3,0,
382,13,207,3,0,
382,13,216,4,0,
382,13,218,4,0,
382,13,219,4,0,
382,13,237,4,0,
382,13,240,4,0,
382,13,246,1,15,
382,13,249,4,0,
382,13,258,4,0,
382,13,263,4,0,
382,13,280,4,0,
382,13,290,4,0,
382,13,291,4,0,
382,13,317,4,0,
382,13,323,1,75,
382,13,329,1,60,
382,13,347,1,30,
382,13,347,4,0,
382,13,351,4,0,
382,13,352,1,1,
382,13,352,4,0,
382,14,34,1,15,
382,14,38,1,80,
382,14,46,4,0,
382,14,56,1,90,
382,14,57,4,0,
382,14,58,1,35,
382,14,58,4,0,
382,14,59,4,0,
382,14,63,4,0,
382,14,70,4,0,
382,14,85,4,0,
382,14,86,4,0,
382,14,87,4,0,
382,14,89,4,0,
382,14,92,4,0,
382,14,104,4,0,
382,14,127,4,0,
382,14,156,4,0,
382,14,157,4,0,
382,14,164,4,0,
382,14,173,3,0,
382,14,182,4,0,
382,14,184,1,5,
382,14,196,3,0,
382,14,207,4,0,
382,14,214,3,0,
382,14,216,4,0,
382,14,218,4,0,
382,14,219,4,0,
382,14,237,4,0,
382,14,240,4,0,
382,14,244,4,0,
382,14,246,1,45,
382,14,249,4,0,
382,14,253,3,0,
382,14,258,4,0,
382,14,263,4,0,
382,14,280,4,0,
382,14,291,4,0,
382,14,317,4,0,
382,14,323,1,50,
382,14,324,3,0,
382,14,329,1,75,
382,14,330,1,20,
382,14,335,3,0,
382,14,347,1,60,
382,14,347,4,0,
382,14,352,1,1,
382,14,392,1,30,
382,14,401,1,65,
382,14,401,3,0,
382,14,416,4,0,
382,14,442,3,0,
382,14,496,4,0,
382,14,503,4,0,
382,14,523,4,0,
382,15,34,1,15,
382,15,38,1,80,
382,15,46,4,0,
382,15,56,1,90,
382,15,57,4,0,
382,15,58,1,35,
382,15,58,4,0,
382,15,59,4,0,
382,15,63,4,0,
382,15,70,4,0,
382,15,85,4,0,
382,15,86,4,0,
382,15,87,4,0,
382,15,89,4,0,
382,15,92,4,0,
382,15,104,4,0,
382,15,127,4,0,
382,15,156,4,0,
382,15,157,4,0,
382,15,164,4,0,
382,15,182,4,0,
382,15,184,1,5,
382,15,207,4,0,
382,15,214,4,0,
382,15,216,4,0,
382,15,218,4,0,
382,15,219,4,0,
382,15,237,4,0,
382,15,240,4,0,
382,15,244,4,0,
382,15,246,1,45,
382,15,249,4,0,
382,15,258,4,0,
382,15,263,4,0,
382,15,280,4,0,
382,15,317,4,0,
382,15,323,1,50,
382,15,329,1,75,
382,15,330,1,20,
382,15,347,1,60,
382,15,347,4,0,
382,15,352,1,1,
382,15,392,1,30,
382,15,401,1,65,
382,15,416,4,0,
382,15,496,4,0,
382,15,503,4,0,
382,15,523,4,0,
382,15,590,4,0,
382,16,34,1,20,1
382,16,38,1,80,1
382,16,46,4,0,
382,16,56,1,75,1
382,16,57,4,0,
382,16,58,1,35,1
382,16,58,4,0,
382,16,59,4,0,
382,16,63,4,0,
382,16,70,4,0,
382,16,85,4,0,
382,16,86,4,0,
382,16,87,4,0,
382,16,89,4,0,
382,16,92,4,0,
382,16,104,4,0,
382,16,127,4,0,
382,16,156,4,0,
382,16,157,4,0,
382,16,164,4,0,
382,16,173,3,0,
382,16,182,4,0,
382,16,184,1,5,1
382,16,196,3,0,
382,16,207,4,0,
382,16,214,4,0,
382,16,216,4,0,
382,16,218,4,0,
382,16,219,4,0,
382,16,237,4,0,
382,16,240,4,0,
382,16,244,4,0,
382,16,246,1,1,1
382,16,249,4,0,
382,16,253,3,0,
382,16,258,4,0,
382,16,263,4,0,
382,16,280,4,0,
382,16,290,4,0,
382,16,291,4,0,
382,16,317,4,0,
382,16,323,1,90,1
382,16,324,3,0,
382,16,329,1,65,1
382,16,330,1,60,1
382,16,335,3,0,
382,16,347,1,50,1
382,16,347,4,0,
382,16,351,3,0,
382,16,352,1,1,2
382,16,352,3,0,
382,16,392,1,30,1
382,16,401,1,15,1
382,16,401,3,0,
382,16,416,4,0,
382,16,442,3,0,
382,16,496,4,0,
382,16,503,4,0,
382,16,523,4,0,
382,16,590,4,0,
382,16,618,1,45,1
382,17,34,1,20,
382,17,38,1,80,
382,17,46,4,0,
382,17,56,1,75,
382,17,57,4,0,
382,17,58,1,35,
382,17,58,4,0,
382,17,59,4,0,
382,17,63,4,0,
382,17,85,4,0,
382,17,86,4,0,
382,17,87,4,0,
382,17,89,4,0,
382,17,92,4,0,
382,17,104,4,0,
382,17,127,4,0,
382,17,156,4,0,
382,17,157,4,0,
382,17,164,4,0,
382,17,182,4,0,
382,17,184,1,5,
382,17,207,4,0,
382,17,214,4,0,
382,17,216,4,0,
382,17,218,4,0,
382,17,219,4,0,
382,17,237,4,0,
382,17,240,4,0,
382,17,244,4,0,
382,17,246,1,1,1
382,17,258,4,0,
382,17,263,4,0,
382,17,280,4,0,
382,17,317,4,0,
382,17,323,1,90,
382,17,329,1,65,
382,17,330,1,60,
382,17,347,1,50,
382,17,347,4,0,
382,17,352,1,1,2
382,17,392,1,30,
382,17,401,1,15,
382,17,416,4,0,
382,17,496,4,0,
382,17,503,4,0,
382,17,523,4,0,
382,17,590,4,0,
382,17,618,1,45,
382,18,34,1,20,
382,18,38,1,80,
382,18,46,4,0,
382,18,56,1,75,
382,18,57,4,0,
382,18,58,1,35,
382,18,58,4,0,
382,18,59,4,0,
382,18,63,4,0,
382,18,85,4,0,
382,18,86,4,0,
382,18,87,4,0,
382,18,89,4,0,
382,18,92,4,0,
382,18,104,4,0,
382,18,127,4,0,
382,18,156,4,0,
382,18,157,4,0,
382,18,164,4,0,
382,18,182,4,0,
382,18,184,1,5,
382,18,207,4,0,
382,18,214,4,0,
382,18,216,4,0,
382,18,218,4,0,
382,18,219,4,0,
382,18,237,4,0,
382,18,240,4,0,
382,18,244,4,0,
382,18,246,1,1,1
382,18,258,4,0,
382,18,263,4,0,
382,18,280,4,0,
382,18,317,4,0,
382,18,323,1,90,
382,18,329,1,65,
382,18,330,1,60,
382,18,347,1,50,
382,18,347,4,0,
382,18,352,1,1,2
382,18,392,1,30,
382,18,401,1,15,
382,18,416,4,0,
382,18,496,4,0,
382,18,503,4,0,
382,18,523,4,0,
382,18,590,4,0,
382,18,618,1,45,
383,5,15,4,0,
383,5,46,4,0,
383,5,53,4,0,
383,5,63,4,0,
383,5,70,4,0,
383,5,76,1,65,
383,5,76,4,0,
383,5,85,4,0,
383,5,87,4,0,
383,5,89,1,35,
383,5,89,4,0,
383,5,90,1,60,
383,5,91,4,0,
383,5,92,4,0,
383,5,104,4,0,
383,5,126,1,45,
383,5,126,4,0,
383,5,156,1,50,
383,5,156,4,0,
383,5,163,1,20,
383,5,182,4,0,
383,5,184,1,5,
383,5,201,4,0,
383,5,216,4,0,
383,5,218,4,0,
383,5,219,4,0,
383,5,231,4,0,
383,5,237,4,0,
383,5,241,4,0,
383,5,246,1,15,
383,5,249,4,0,
383,5,263,4,0,
383,5,280,4,0,
383,5,284,1,75,
383,5,290,4,0,
383,5,315,4,0,
383,5,317,4,0,
383,5,332,4,0,
383,5,337,4,0,
383,5,339,1,30,
383,5,339,4,0,
383,5,341,1,1,
383,5,351,4,0,
383,6,5,3,0,
383,6,7,3,0,
383,6,9,3,0,
383,6,14,3,0,
383,6,15,4,0,
383,6,25,3,0,
383,6,34,3,0,
383,6,38,3,0,
383,6,46,4,0,
383,6,53,4,0,
383,6,63,4,0,
383,6,68,3,0,
383,6,69,3,0,
383,6,70,4,0,
383,6,76,1,65,
383,6,76,4,0,
383,6,85,4,0,
383,6,86,3,0,
383,6,87,4,0,
383,6,89,1,35,
383,6,89,4,0,
383,6,90,1,60,
383,6,91,4,0,
383,6,92,4,0,
383,6,102,3,0,
383,6,104,4,0,
383,6,111,3,0,
383,6,126,1,45,
383,6,126,4,0,
383,6,129,3,0,
383,6,156,1,50,
383,6,156,4,0,
383,6,157,3,0,
383,6,163,1,20,
383,6,164,3,0,
383,6,173,3,0,
383,6,182,4,0,
383,6,184,1,5,
383,6,189,3,0,
383,6,201,4,0,
383,6,203,3,0,
383,6,205,3,0,
383,6,207,3,0,
383,6,210,3,0,
383,6,214,3,0,
383,6,216,4,0,
383,6,218,4,0,
383,6,219,4,0,
383,6,223,3,0,
383,6,231,4,0,
383,6,237,4,0,
383,6,241,4,0,
383,6,244,3,0,
383,6,246,1,15,
383,6,249,4,0,
383,6,263,4,0,
383,6,280,4,0,
383,6,284,1,75,
383,6,290,4,0,
383,6,315,4,0,
383,6,317,4,0,
383,6,332,4,0,
383,6,337,4,0,
383,6,339,1,30,
383,6,339,4,0,
383,6,341,1,1,
383,6,351,4,0,
383,7,5,3,0,
383,7,14,3,0,
383,7,15,4,0,
383,7,25,3,0,
383,7,34,3,0,
383,7,38,3,0,
383,7,46,4,0,
383,7,53,4,0,
383,7,63,4,0,
383,7,68,3,0,
383,7,69,3,0,
383,7,70,4,0,
383,7,76,1,65,
383,7,76,4,0,
383,7,85,4,0,
383,7,86,3,0,
383,7,87,4,0,
383,7,89,1,35,
383,7,89,4,0,
383,7,90,1,60,
383,7,91,4,0,
383,7,92,4,0,
383,7,102,3,0,
383,7,104,4,0,
383,7,126,1,45,
383,7,126,4,0,
383,7,156,1,50,
383,7,156,4,0,
383,7,157,3,0,
383,7,163,1,20,
383,7,164,3,0,
383,7,182,4,0,
383,7,184,1,5,
383,7,201,4,0,
383,7,216,4,0,
383,7,218,4,0,
383,7,219,4,0,
383,7,231,4,0,
383,7,237,4,0,
383,7,241,4,0,
383,7,246,1,15,
383,7,249,4,0,
383,7,263,4,0,
383,7,280,4,0,
383,7,284,1,75,
383,7,290,4,0,
383,7,315,4,0,
383,7,317,4,0,
383,7,332,4,0,
383,7,337,4,0,
383,7,339,1,30,
383,7,339,4,0,
383,7,341,1,1,
383,7,351,4,0,
383,8,14,4,0,
383,8,15,4,0,
383,8,46,4,0,
383,8,53,4,0,
383,8,63,4,0,
383,8,70,4,0,
383,8,76,1,65,
383,8,76,4,0,
383,8,85,4,0,
383,8,86,4,0,
383,8,87,4,0,
383,8,89,1,35,
383,8,89,4,0,
383,8,90,1,60,
383,8,91,4,0,
383,8,92,4,0,
383,8,104,4,0,
383,8,126,1,45,
383,8,126,4,0,
383,8,156,1,50,
383,8,156,4,0,
383,8,157,4,0,
383,8,163,1,20,
383,8,164,4,0,
383,8,182,4,0,
383,8,184,1,5,
383,8,201,4,0,
383,8,203,4,0,
383,8,207,4,0,
383,8,214,4,0,
383,8,216,4,0,
383,8,218,4,0,
383,8,219,4,0,
383,8,231,4,0,
383,8,237,4,0,
383,8,241,4,0,
383,8,244,4,0,
383,8,246,1,15,
383,8,249,4,0,
383,8,263,4,0,
383,8,280,4,0,
383,8,284,1,80,
383,8,290,4,0,
383,8,315,4,0,
383,8,317,4,0,
383,8,332,4,0,
383,8,337,4,0,
383,8,339,1,30,
383,8,339,4,0,
383,8,341,1,1,
383,8,351,4,0,
383,8,363,4,0,
383,8,374,4,0,
383,8,397,4,0,
383,8,406,4,0,
383,8,411,4,0,
383,8,414,1,75,
383,8,416,4,0,
383,8,421,4,0,
383,8,431,4,0,
383,8,444,4,0,
383,8,446,4,0,
383,9,7,3,0,
383,9,9,3,0,
383,9,14,4,0,
383,9,15,4,0,
383,9,46,4,0,
383,9,53,4,0,
383,9,63,4,0,
383,9,70,4,0,
383,9,76,1,65,
383,9,76,4,0,
383,9,85,4,0,
383,9,86,4,0,
383,9,87,4,0,
383,9,89,1,35,
383,9,89,4,0,
383,9,90,1,60,
383,9,91,4,0,
383,9,92,4,0,
383,9,104,4,0,
383,9,126,1,45,
383,9,126,4,0,
383,9,129,3,0,
383,9,156,1,50,
383,9,156,4,0,
383,9,157,4,0,
383,9,163,1,20,
383,9,164,4,0,
383,9,173,3,0,
383,9,182,4,0,
383,9,184,1,5,
383,9,189,3,0,
383,9,201,4,0,
383,9,203,4,0,
383,9,205,3,0,
383,9,207,4,0,
383,9,210,3,0,
383,9,214,4,0,
383,9,216,4,0,
383,9,218,4,0,
383,9,219,4,0,
383,9,231,4,0,
383,9,237,4,0,
383,9,241,4,0,
383,9,244,4,0,
383,9,246,1,15,
383,9,246,3,0,
383,9,249,4,0,
383,9,253,3,0,
383,9,263,4,0,
383,9,280,4,0,
383,9,284,1,80,
383,9,290,4,0,
383,9,315,4,0,
383,9,317,4,0,
383,9,332,4,0,
383,9,337,4,0,
383,9,339,1,30,
383,9,339,4,0,
383,9,341,1,1,
383,9,351,4,0,
383,9,363,4,0,
383,9,374,4,0,
383,9,397,4,0,
383,9,406,4,0,
383,9,411,4,0,
383,9,414,1,75,
383,9,414,3,0,
383,9,416,4,0,
383,9,421,4,0,
383,9,431,4,0,
383,9,442,3,0,
383,9,444,4,0,
383,9,446,4,0,
383,10,7,3,0,
383,10,9,3,0,
383,10,14,4,0,
383,10,15,4,0,
383,10,29,3,0,
383,10,46,4,0,
383,10,53,4,0,
383,10,63,4,0,
383,10,70,4,0,
383,10,76,1,80,
383,10,76,4,0,
383,10,85,4,0,
383,10,86,4,0,
383,10,87,4,0,
383,10,89,1,35,
383,10,89,4,0,
383,10,90,1,75,
383,10,91,4,0,
383,10,92,4,0,
383,10,104,4,0,
383,10,126,1,90,
383,10,126,4,0,
383,10,129,3,0,
383,10,156,1,30,
383,10,156,4,0,
383,10,157,4,0,
383,10,164,4,0,
383,10,173,3,0,
383,10,182,4,0,
383,10,184,1,5,
383,10,189,3,0,
383,10,201,4,0,
383,10,203,4,0,
383,10,205,3,0,
383,10,207,4,0,
383,10,210,3,0,
383,10,214,4,0,
383,10,216,4,0,
383,10,218,4,0,
383,10,219,4,0,
383,10,231,4,0,
383,10,237,4,0,
383,10,241,4,0,
383,10,244,4,0,
383,10,246,1,45,
383,10,246,3,0,
383,10,249,4,0,
383,10,253,3,0,
383,10,263,4,0,
383,10,280,4,0,
383,10,284,1,50,
383,10,290,4,0,
383,10,315,4,0,
383,10,317,4,0,
383,10,332,4,0,
383,10,335,3,0,
383,10,337,4,0,
383,10,339,1,60,
383,10,339,4,0,
383,10,341,1,1,
383,10,351,4,0,
383,10,359,1,20,
383,10,363,4,0,
383,10,374,4,0,
383,10,397,4,0,
383,10,406,4,0,
383,10,411,4,0,
383,10,414,1,65,
383,10,414,3,0,
383,10,416,4,0,
383,10,421,4,0,
383,10,431,4,0,
383,10,436,1,15,
383,10,442,3,0,
383,10,444,4,0,
383,10,446,4,0,
383,11,14,4,0,
383,11,15,4,0,
383,11,46,4,0,
383,11,53,4,0,
383,11,63,4,0,
383,11,70,4,0,
383,11,76,1,80,
383,11,76,4,0,
383,11,85,4,0,
383,11,86,4,0,
383,11,87,4,0,
383,11,89,1,35,
383,11,89,4,0,
383,11,90,1,75,
383,11,91,4,0,
383,11,92,4,0,
383,11,104,4,0,
383,11,126,1,90,
383,11,126,4,0,
383,11,156,1,30,
383,11,156,4,0,
383,11,157,4,0,
383,11,164,4,0,
383,11,182,4,0,
383,11,184,1,5,
383,11,201,4,0,
383,11,207,4,0,
383,11,216,4,0,
383,11,218,4,0,
383,11,219,4,0,
383,11,237,4,0,
383,11,241,4,0,
383,11,244,4,0,
383,11,246,1,45,
383,11,249,4,0,
383,11,263,4,0,
383,11,280,4,0,
383,11,284,1,50,
383,11,315,4,0,
383,11,317,4,0,
383,11,332,4,0,
383,11,337,4,0,
383,11,339,1,60,
383,11,339,4,0,
383,11,341,1,1,
383,11,359,1,20,
383,11,374,4,0,
383,11,397,4,0,
383,11,411,4,0,
383,11,414,1,65,
383,11,416,4,0,
383,11,421,4,0,
383,11,436,1,15,
383,11,444,4,0,
383,11,468,4,0,
383,11,479,4,0,
383,11,496,4,0,
383,11,510,4,0,
383,11,523,4,0,
383,11,525,4,0,
383,12,15,4,0,
383,12,46,4,0,
383,12,53,4,0,
383,12,63,4,0,
383,12,70,4,0,
383,12,76,1,65,
383,12,76,4,0,
383,12,85,4,0,
383,12,87,4,0,
383,12,89,1,35,
383,12,89,4,0,
383,12,90,1,60,
383,12,91,4,0,
383,12,92,4,0,
383,12,104,4,0,
383,12,126,1,45,
383,12,126,4,0,
383,12,156,1,50,
383,12,156,4,0,
383,12,163,1,20,
383,12,182,4,0,
383,12,184,1,5,
383,12,201,4,0,
383,12,216,4,0,
383,12,218,4,0,
383,12,219,4,0,
383,12,231,4,0,
383,12,237,4,0,
383,12,241,4,0,
383,12,246,1,15,
383,12,249,4,0,
383,12,263,4,0,
383,12,280,4,0,
383,12,284,1,75,
383,12,290,4,0,
383,12,315,4,0,
383,12,317,4,0,
383,12,332,4,0,
383,12,337,4,0,
383,12,339,1,30,
383,12,339,4,0,
383,12,341,1,1,
383,12,351,4,0,
383,13,15,4,0,
383,13,34,3,0,
383,13,38,3,0,
383,13,46,4,0,
383,13,53,4,0,
383,13,63,4,0,
383,13,69,3,0,
383,13,70,4,0,
383,13,76,1,65,
383,13,76,4,0,
383,13,85,4,0,
383,13,86,3,0,
383,13,87,4,0,
383,13,89,1,35,
383,13,89,4,0,
383,13,90,1,60,
383,13,91,4,0,
383,13,92,4,0,
383,13,102,3,0,
383,13,104,4,0,
383,13,126,1,45,
383,13,126,4,0,
383,13,156,1,50,
383,13,156,4,0,
383,13,163,1,20,
383,13,164,3,0,
383,13,182,4,0,
383,13,184,1,5,
383,13,201,4,0,
383,13,207,3,0,
383,13,216,4,0,
383,13,218,4,0,
383,13,219,4,0,
383,13,231,4,0,
383,13,237,4,0,
383,13,241,4,0,
383,13,246,1,15,
383,13,249,4,0,
383,13,263,4,0,
383,13,280,4,0,
383,13,284,1,75,
383,13,290,4,0,
383,13,315,4,0,
383,13,317,4,0,
383,13,332,4,0,
383,13,337,4,0,
383,13,339,1,30,
383,13,339,4,0,
383,13,341,1,1,
383,13,351,4,0,
383,14,7,3,0,
383,14,9,3,0,
383,14,14,4,0,
383,14,15,4,0,
383,14,46,4,0,
383,14,53,4,0,
383,14,63,4,0,
383,14,70,4,0,
383,14,76,1,80,
383,14,76,4,0,
383,14,85,4,0,
383,14,86,4,0,
383,14,87,4,0,
383,14,89,1,35,
383,14,89,4,0,
383,14,90,1,75,
383,14,91,4,0,
383,14,92,4,0,
383,14,104,4,0,
383,14,126,1,90,
383,14,126,4,0,
383,14,156,1,30,
383,14,156,4,0,
383,14,157,4,0,
383,14,164,4,0,
383,14,173,3,0,
383,14,182,4,0,
383,14,184,1,5,
383,14,201,4,0,
383,14,207,4,0,
383,14,214,3,0,
383,14,216,4,0,
383,14,218,4,0,
383,14,219,4,0,
383,14,231,3,0,
383,14,237,4,0,
383,14,241,4,0,
383,14,244,4,0,
383,14,246,1,45,
383,14,249,4,0,
383,14,253,3,0,
383,14,263,4,0,
383,14,280,4,0,
383,14,284,1,50,
383,14,315,4,0,
383,14,317,4,0,
383,14,332,4,0,
383,14,335,3,0,
383,14,337,4,0,
383,14,339,1,60,
383,14,339,4,0,
383,14,341,1,1,
383,14,359,1,20,
383,14,374,4,0,
383,14,397,4,0,
383,14,406,3,0,
383,14,411,4,0,
383,14,414,1,65,
383,14,414,3,0,
383,14,416,4,0,
383,14,421,4,0,
383,14,436,1,15,
383,14,442,3,0,
383,14,444,4,0,
383,14,446,3,0,
383,14,468,4,0,
383,14,479,4,0,
383,14,496,4,0,
383,14,510,4,0,
383,14,523,4,0,
383,14,525,4,0,
383,15,14,4,0,
383,15,15,4,0,
383,15,46,4,0,
383,15,53,4,0,
383,15,63,4,0,
383,15,70,4,0,
383,15,76,1,80,
383,15,76,4,0,
383,15,85,4,0,
383,15,86,4,0,
383,15,87,4,0,
383,15,89,1,35,
383,15,89,4,0,
383,15,90,1,75,
383,15,91,4,0,
383,15,92,4,0,
383,15,104,4,0,
383,15,126,1,90,
383,15,126,4,0,
383,15,156,1,30,
383,15,156,4,0,
383,15,157,4,0,
383,15,164,4,0,
383,15,182,4,0,
383,15,184,1,5,
383,15,201,4,0,
383,15,207,4,0,
383,15,214,4,0,
383,15,216,4,0,
383,15,218,4,0,
383,15,219,4,0,
383,15,237,4,0,
383,15,241,4,0,
383,15,244,4,0,
383,15,246,1,45,
383,15,249,4,0,
383,15,263,4,0,
383,15,280,4,0,
383,15,284,1,50,
383,15,315,4,0,
383,15,317,4,0,
383,15,332,4,0,
383,15,337,4,0,
383,15,339,1,60,
383,15,339,4,0,
383,15,341,1,1,
383,15,359,1,20,
383,15,374,4,0,
383,15,397,4,0,
383,15,411,4,0,
383,15,414,1,65,
383,15,416,4,0,
383,15,421,4,0,
383,15,436,1,15,
383,15,444,4,0,
383,15,468,4,0,
383,15,479,4,0,
383,15,496,4,0,
383,15,510,4,0,
383,15,523,4,0,
383,15,525,4,0,
383,15,590,4,0,
383,15,612,4,0,
383,16,7,3,0,
383,16,9,3,0,
383,16,14,4,0,
383,16,15,4,0,
383,16,46,4,0,
383,16,53,4,0,
383,16,63,4,0,
383,16,70,4,0,
383,16,76,1,60,1
383,16,76,4,0,
383,16,85,4,0,
383,16,86,4,0,
383,16,87,4,0,
383,16,89,1,35,1
383,16,89,4,0,
383,16,90,1,65,1
383,16,91,4,0,
383,16,92,4,0,
383,16,104,4,0,
383,16,126,1,75,1
383,16,126,4,0,
383,16,156,1,30,1
383,16,156,4,0,
383,16,157,4,0,
383,16,164,4,0,
383,16,173,3,0,
383,16,182,4,0,
383,16,184,1,5,1
383,16,201,4,0,
383,16,207,4,0,
383,16,214,4,0,
383,16,216,4,0,
383,16,218,4,0,
383,16,219,4,0,
383,16,231,3,0,
383,16,237,4,0,
383,16,241,4,0,
383,16,244,4,0,
383,16,246,1,1,1
383,16,249,4,0,
383,16,253,3,0,
383,16,263,4,0,
383,16,280,4,0,
383,16,284,1,90,1
383,16,290,4,0,
383,16,315,4,0,
383,16,317,4,0,
383,16,332,4,0,
383,16,335,3,0,
383,16,337,4,0,
383,16,339,1,50,1
383,16,339,4,0,
383,16,341,1,1,2
383,16,351,3,0,
383,16,359,1,80,1
383,16,374,4,0,
383,16,397,4,0,
383,16,406,3,0,
383,16,411,4,0,
383,16,414,1,15,1
383,16,414,3,0,
383,16,416,4,0,
383,16,421,4,0,
383,16,436,1,20,1
383,16,442,3,0,
383,16,444,4,0,
383,16,446,3,0,
383,16,468,4,0,
383,16,479,4,0,
383,16,496,4,0,
383,16,510,4,0,
383,16,523,4,0,
383,16,525,4,0,
383,16,590,4,0,
383,16,612,4,0,
383,16,619,1,45,1
383,17,14,4,0,
383,17,46,4,0,
383,17,53,4,0,
383,17,63,4,0,
383,17,76,1,60,
383,17,76,4,0,
383,17,85,4,0,
383,17,86,4,0,
383,17,87,4,0,
383,17,89,1,35,
383,17,89,4,0,
383,17,90,1,65,
383,17,92,4,0,
383,17,104,4,0,
383,17,126,1,75,
383,17,126,4,0,
383,17,156,1,30,
383,17,156,4,0,
383,17,157,4,0,
383,17,164,4,0,
383,17,182,4,0,
383,17,184,1,5,
383,17,201,4,0,
383,17,207,4,0,
383,17,214,4,0,
383,17,216,4,0,
383,17,218,4,0,
383,17,219,4,0,
383,17,237,4,0,
383,17,241,4,0,
383,17,244,4,0,
383,17,246,1,1,1
383,17,263,4,0,
383,17,280,4,0,
383,17,284,1,90,
383,17,315,4,0,
383,17,317,4,0,
383,17,332,4,0,
383,17,337,4,0,
383,17,339,1,50,
383,17,339,4,0,
383,17,341,1,1,2
383,17,359,1,80,
383,17,374,4,0,
383,17,397,4,0,
383,17,411,4,0,
383,17,414,1,15,
383,17,416,4,0,
383,17,421,4,0,
383,17,436,1,20,
383,17,444,4,0,
383,17,479,4,0,
383,17,496,4,0,
383,17,523,4,0,
383,17,525,4,0,
383,17,590,4,0,
383,17,619,1,45,
383,17,693,4,0,
383,18,14,4,0,
383,18,46,4,0,
383,18,53,4,0,
383,18,63,4,0,
383,18,76,1,60,
383,18,76,4,0,
383,18,85,4,0,
383,18,86,4,0,
383,18,87,4,0,
383,18,89,1,35,
383,18,89,4,0,
383,18,90,1,65,
383,18,92,4,0,
383,18,104,4,0,
383,18,126,1,75,
383,18,126,4,0,
383,18,156,1,30,
383,18,156,4,0,
383,18,157,4,0,
383,18,164,4,0,
383,18,182,4,0,
383,18,184,1,5,
383,18,201,4,0,
383,18,207,4,0,
383,18,214,4,0,
383,18,216,4,0,
383,18,218,4,0,
383,18,219,4,0,
383,18,237,4,0,
383,18,241,4,0,
383,18,244,4,0,
383,18,246,1,1,1
383,18,263,4,0,
383,18,280,4,0,
383,18,284,1,90,
383,18,315,4,0,
383,18,317,4,0,
383,18,332,4,0,
383,18,337,4,0,
383,18,339,1,50,
383,18,339,4,0,
383,18,341,1,1,2
383,18,359,1,80,
383,18,374,4,0,
383,18,397,4,0,
383,18,411,4,0,
383,18,414,1,15,
383,18,416,4,0,
383,18,421,4,0,
383,18,436,1,20,
383,18,444,4,0,
383,18,479,4,0,
383,18,496,4,0,
383,18,523,4,0,
383,18,525,4,0,
383,18,590,4,0,
383,18,619,1,45,
383,18,693,4,0,
384,5,19,1,45,
384,5,19,4,0,
384,5,46,4,0,
384,5,53,4,0,
384,5,57,4,0,
384,5,58,4,0,
384,5,59,4,0,
384,5,63,1,75,
384,5,63,4,0,
384,5,70,4,0,
384,5,76,4,0,
384,5,85,4,0,
384,5,87,4,0,
384,5,89,4,0,
384,5,92,4,0,
384,5,104,4,0,
384,5,126,4,0,
384,5,127,4,0,
384,5,156,1,50,
384,5,156,4,0,
384,5,182,4,0,
384,5,184,1,5,
384,5,200,1,65,
384,5,201,4,0,
384,5,216,4,0,
384,5,218,4,0,
384,5,231,4,0,
384,5,237,4,0,
384,5,239,1,1,
384,5,240,4,0,
384,5,241,4,0,
384,5,242,1,35,
384,5,245,1,60,
384,5,246,1,15,
384,5,249,4,0,
384,5,263,4,0,
384,5,280,4,0,
384,5,290,4,0,
384,5,291,4,0,
384,5,315,4,0,
384,5,332,4,0,
384,5,337,1,20,
384,5,337,4,0,
384,5,339,4,0,
384,5,349,1,30,
384,5,351,4,0,
384,5,352,4,0,
384,6,19,1,45,
384,6,19,4,0,
384,6,34,3,0,
384,6,38,3,0,
384,6,46,4,0,
384,6,53,4,0,
384,6,57,4,0,
384,6,58,4,0,
384,6,59,4,0,
384,6,63,1,75,
384,6,63,4,0,
384,6,70,4,0,
384,6,76,4,0,
384,6,85,4,0,
384,6,86,3,0,
384,6,87,4,0,
384,6,89,4,0,
384,6,92,4,0,
384,6,102,3,0,
384,6,104,4,0,
384,6,126,4,0,
384,6,127,4,0,
384,6,129,3,0,
384,6,156,1,50,
384,6,156,4,0,
384,6,157,3,0,
384,6,164,3,0,
384,6,173,3,0,
384,6,182,4,0,
384,6,184,1,5,
384,6,189,3,0,
384,6,196,3,0,
384,6,200,1,65,
384,6,201,4,0,
384,6,203,3,0,
384,6,207,3,0,
384,6,210,3,0,
384,6,214,3,0,
384,6,216,4,0,
384,6,218,4,0,
384,6,231,4,0,
384,6,237,4,0,
384,6,239,1,1,
384,6,240,4,0,
384,6,241,4,0,
384,6,242,1,35,
384,6,244,3,0,
384,6,245,1,60,
384,6,246,1,15,
384,6,249,4,0,
384,6,263,4,0,
384,6,280,4,0,
384,6,290,4,0,
384,6,291,4,0,
384,6,315,4,0,
384,6,332,4,0,
384,6,337,1,20,
384,6,337,4,0,
384,6,339,4,0,
384,6,349,1,30,
384,6,351,4,0,
384,6,352,4,0,
384,7,19,1,45,
384,7,19,4,0,
384,7,34,3,0,
384,7,38,3,0,
384,7,46,4,0,
384,7,53,4,0,
384,7,57,4,0,
384,7,58,4,0,
384,7,59,4,0,
384,7,63,1,75,
384,7,63,4,0,
384,7,70,4,0,
384,7,76,4,0,
384,7,85,4,0,
384,7,86,3,0,
384,7,87,4,0,
384,7,89,4,0,
384,7,92,4,0,
384,7,102,3,0,
384,7,104,4,0,
384,7,126,4,0,
384,7,127,4,0,
384,7,156,1,50,
384,7,156,4,0,
384,7,157,3,0,
384,7,164,3,0,
384,7,182,4,0,
384,7,184,1,5,
384,7,200,1,65,
384,7,201,4,0,
384,7,216,4,0,
384,7,218,4,0,
384,7,231,4,0,
384,7,237,4,0,
384,7,239,1,1,
384,7,240,4,0,
384,7,241,4,0,
384,7,242,1,35,
384,7,245,1,60,
384,7,246,1,15,
384,7,249,4,0,
384,7,263,4,0,
384,7,280,4,0,
384,7,290,4,0,
384,7,291,4,0,
384,7,315,4,0,
384,7,332,4,0,
384,7,337,1,20,
384,7,337,4,0,
384,7,339,4,0,
384,7,349,1,30,
384,7,351,4,0,
384,7,352,4,0,
384,8,14,4,0,
384,8,19,1,45,
384,8,19,4,0,
384,8,46,4,0,
384,8,53,4,0,
384,8,57,4,0,
384,8,58,4,0,
384,8,59,4,0,
384,8,63,1,65,
384,8,63,4,0,
384,8,70,4,0,
384,8,76,4,0,
384,8,85,4,0,
384,8,86,4,0,
384,8,87,4,0,
384,8,89,4,0,
384,8,92,4,0,
384,8,104,4,0,
384,8,126,4,0,
384,8,127,4,0,
384,8,156,1,50,
384,8,156,4,0,
384,8,157,4,0,
384,8,164,4,0,
384,8,182,4,0,
384,8,184,1,5,
384,8,200,1,80,
384,8,201,4,0,
384,8,203,4,0,
384,8,207,4,0,
384,8,214,4,0,
384,8,216,4,0,
384,8,218,4,0,
384,8,231,4,0,
384,8,237,4,0,
384,8,239,1,1,
384,8,240,4,0,
384,8,241,4,0,
384,8,242,1,35,
384,8,244,4,0,
384,8,245,1,60,
384,8,246,1,15,
384,8,249,4,0,
384,8,263,4,0,
384,8,280,4,0,
384,8,290,4,0,
384,8,315,4,0,
384,8,317,4,0,
384,8,332,4,0,
384,8,337,1,20,
384,8,337,4,0,
384,8,339,4,0,
384,8,349,1,30,
384,8,351,4,0,
384,8,352,4,0,
384,8,360,4,0,
384,8,363,4,0,
384,8,374,4,0,
384,8,406,1,75,
384,8,406,4,0,
384,8,411,4,0,
384,8,412,4,0,
384,8,416,4,0,
384,8,419,4,0,
384,8,421,4,0,
384,8,434,3,0,
384,8,444,4,0,
384,9,14,4,0,
384,9,19,1,45,
384,9,19,4,0,
384,9,46,4,0,
384,9,53,4,0,
384,9,57,4,0,
384,9,58,4,0,
384,9,59,4,0,
384,9,63,1,65,
384,9,63,4,0,
384,9,70,4,0,
384,9,76,4,0,
384,9,85,4,0,
384,9,86,4,0,
384,9,87,4,0,
384,9,89,4,0,
384,9,92,4,0,
384,9,104,4,0,
384,9,126,4,0,
384,9,127,4,0,
384,9,129,3,0,
384,9,156,1,50,
384,9,156,4,0,
384,9,157,4,0,
384,9,164,4,0,
384,9,173,3,0,
384,9,182,4,0,
384,9,184,1,5,
384,9,189,3,0,
384,9,196,3,0,
384,9,200,1,80,
384,9,200,3,0,
384,9,201,4,0,
384,9,203,4,0,
384,9,207,4,0,
384,9,210,3,0,
384,9,214,4,0,
384,9,216,4,0,
384,9,218,4,0,
384,9,231,4,0,
384,9,237,4,0,
384,9,239,1,1,
384,9,239,3,0,
384,9,240,4,0,
384,9,241,4,0,
384,9,242,1,35,
384,9,244,4,0,
384,9,245,1,60,
384,9,246,1,15,
384,9,246,3,0,
384,9,249,4,0,
384,9,253,3,0,
384,9,263,4,0,
384,9,280,4,0,
384,9,290,4,0,
384,9,291,3,0,
384,9,315,4,0,
384,9,317,4,0,
384,9,332,4,0,
384,9,337,1,20,
384,9,337,4,0,
384,9,339,4,0,
384,9,349,1,30,
384,9,351,4,0,
384,9,352,4,0,
384,9,360,4,0,
384,9,363,4,0,
384,9,374,4,0,
384,9,401,3,0,
384,9,406,1,75,
384,9,406,4,0,
384,9,411,4,0,
384,9,412,4,0,
384,9,414,3,0,
384,9,416,4,0,
384,9,419,4,0,
384,9,421,4,0,
384,9,434,3,0,
384,9,442,3,0,
384,9,444,4,0,
384,10,14,4,0,
384,10,19,1,65,
384,10,19,4,0,
384,10,29,3,0,
384,10,46,4,0,
384,10,53,4,0,
384,10,57,4,0,
384,10,58,4,0,
384,10,59,4,0,
384,10,63,1,80,
384,10,63,4,0,
384,10,70,4,0,
384,10,76,4,0,
384,10,85,4,0,
384,10,86,4,0,
384,10,87,4,0,
384,10,89,4,0,
384,10,92,4,0,
384,10,104,4,0,
384,10,126,4,0,
384,10,127,4,0,
384,10,129,3,0,
384,10,156,1,30,
384,10,156,4,0,
384,10,157,4,0,
384,10,164,4,0,
384,10,173,3,0,
384,10,182,4,0,
384,10,184,1,5,
384,10,189,3,0,
384,10,196,3,0,
384,10,200,1,50,
384,10,200,3,0,
384,10,201,4,0,
384,10,203,4,0,
384,10,207,4,0,
384,10,210,3,0,
384,10,214,4,0,
384,10,216,4,0,
384,10,218,4,0,
384,10,231,4,0,
384,10,237,4,0,
384,10,239,1,1,
384,10,239,3,0,
384,10,240,4,0,
384,10,241,4,0,
384,10,242,1,15,
384,10,244,4,0,
384,10,245,1,75,
384,10,246,1,45,
384,10,246,3,0,
384,10,249,4,0,
384,10,250,4,0,
384,10,253,3,0,
384,10,263,4,0,
384,10,280,4,0,
384,10,290,4,0,
384,10,291,3,0,
384,10,304,1,20,
384,10,315,4,0,
384,10,317,4,0,
384,10,332,4,0,
384,10,337,4,0,
384,10,339,4,0,
384,10,349,1,60,
384,10,351,4,0,
384,10,352,4,0,
384,10,360,4,0,
384,10,363,4,0,
384,10,366,3,0,
384,10,374,4,0,
384,10,401,3,0,
384,10,403,1,35,
384,10,406,1,90,
384,10,406,4,0,
384,10,411,4,0,
384,10,412,4,0,
384,10,414,3,0,
384,10,416,4,0,
384,10,419,4,0,
384,10,421,4,0,
384,10,434,3,0,
384,10,442,3,0,
384,10,444,4,0,
384,11,14,4,0,
384,11,19,1,65,
384,11,19,4,0,
384,11,46,4,0,
384,11,53,4,0,
384,11,57,4,0,
384,11,58,4,0,
384,11,59,4,0,
384,11,63,1,80,
384,11,63,4,0,
384,11,70,4,0,
384,11,76,4,0,
384,11,85,4,0,
384,11,86,4,0,
384,11,87,4,0,
384,11,89,4,0,
384,11,92,4,0,
384,11,104,4,0,
384,11,126,4,0,
384,11,127,4,0,
384,11,156,1,30,
384,11,156,4,0,
384,11,157,4,0,
384,11,164,4,0,
384,11,182,4,0,
384,11,184,1,5,
384,11,200,1,50,
384,11,201,4,0,
384,11,207,4,0,
384,11,216,4,0,
384,11,218,4,0,
384,11,237,4,0,
384,11,239,1,1,
384,11,240,4,0,
384,11,241,4,0,
384,11,242,1,15,
384,11,244,4,0,
384,11,245,1,75,
384,11,246,1,45,
384,11,249,4,0,
384,11,263,4,0,
384,11,280,4,0,
384,11,291,4,0,
384,11,304,1,20,
384,11,315,4,0,
384,11,317,4,0,
384,11,332,4,0,
384,11,337,4,0,
384,11,339,4,0,
384,11,349,1,60,
384,11,360,4,0,
384,11,374,4,0,
384,11,403,1,35,
384,11,406,1,90,
384,11,411,4,0,
384,11,412,4,0,
384,11,416,4,0,
384,11,421,4,0,
384,11,434,3,0,
384,11,444,4,0,
384,11,468,4,0,
384,11,496,4,0,
384,11,497,4,0,
384,11,507,4,0,
384,11,510,4,0,
384,11,523,4,0,
384,11,525,4,0,
384,12,19,1,45,
384,12,19,4,0,
384,12,46,4,0,
384,12,53,4,0,
384,12,57,4,0,
384,12,58,4,0,
384,12,59,4,0,
384,12,63,1,75,
384,12,63,4,0,
384,12,70,4,0,
384,12,76,4,0,
384,12,85,4,0,
384,12,87,4,0,
384,12,89,4,0,
384,12,92,4,0,
384,12,104,4,0,
384,12,126,4,0,
384,12,127,4,0,
384,12,156,1,50,
384,12,156,4,0,
384,12,182,4,0,
384,12,184,1,5,
384,12,200,1,65,
384,12,201,4,0,
384,12,216,4,0,
384,12,218,4,0,
384,12,231,4,0,
384,12,237,4,0,
384,12,239,1,1,
384,12,240,4,0,
384,12,241,4,0,
384,12,242,1,35,
384,12,245,1,60,
384,12,246,1,15,
384,12,249,4,0,
384,12,263,4,0,
384,12,280,4,0,
384,12,290,4,0,
384,12,291,4,0,
384,12,315,4,0,
384,12,332,4,0,
384,12,337,1,20,
384,12,337,4,0,
384,12,339,4,0,
384,12,349,1,30,
384,12,351,4,0,
384,12,352,4,0,
384,13,19,1,45,
384,13,19,4,0,
384,13,34,3,0,
384,13,38,3,0,
384,13,46,4,0,
384,13,53,4,0,
384,13,57,4,0,
384,13,58,4,0,
384,13,59,4,0,
384,13,63,1,75,
384,13,63,4,0,
384,13,70,4,0,
384,13,76,4,0,
384,13,85,4,0,
384,13,86,3,0,
384,13,87,4,0,
384,13,89,4,0,
384,13,92,4,0,
384,13,102,3,0,
384,13,104,4,0,
384,13,126,4,0,
384,13,127,4,0,
384,13,156,1,50,
384,13,156,4,0,
384,13,164,3,0,
384,13,182,4,0,
384,13,184,1,5,
384,13,196,3,0,
384,13,200,1,65,
384,13,201,4,0,
384,13,207,3,0,
384,13,216,4,0,
384,13,218,4,0,
384,13,231,4,0,
384,13,237,4,0,
384,13,239,1,1,
384,13,240,4,0,
384,13,241,4,0,
384,13,242,1,35,
384,13,245,1,60,
384,13,246,1,15,
384,13,249,4,0,
384,13,263,4,0,
384,13,280,4,0,
384,13,290,4,0,
384,13,291,4,0,
384,13,315,4,0,
384,13,332,4,0,
384,13,337,1,20,
384,13,337,4,0,
384,13,339,4,0,
384,13,349,1,30,
384,13,351,4,0,
384,13,352,4,0,
384,14,14,4,0,
384,14,19,1,65,
384,14,19,4,0,
384,14,20,3,0,
384,14,46,4,0,
384,14,53,4,0,
384,14,57,4,0,
384,14,58,4,0,
384,14,59,4,0,
384,14,63,1,80,
384,14,63,4,0,
384,14,70,4,0,
384,14,76,4,0,
384,14,85,4,0,
384,14,86,4,0,
384,14,87,4,0,
384,14,89,4,0,
384,14,92,4,0,
384,14,104,4,0,
384,14,126,4,0,
384,14,127,4,0,
384,14,156,1,30,
384,14,156,4,0,
384,14,157,4,0,
384,14,164,4,0,
384,14,173,3,0,
384,14,182,4,0,
384,14,184,1,5,
384,14,196,3,0,
384,14,200,1,50,
384,14,200,3,0,
384,14,201,4,0,
384,14,207,4,0,
384,14,214,3,0,
384,14,216,4,0,
384,14,218,4,0,
384,14,231,3,0,
384,14,237,4,0,
384,14,239,1,1,
384,14,240,4,0,
384,14,241,4,0,
384,14,242,1,15,
384,14,244,4,0,
384,14,245,1,75,
384,14,246,1,45,
384,14,249,4,0,
384,14,253,3,0,
384,14,263,4,0,
384,14,280,4,0,
384,14,291,4,0,
384,14,304,1,20,
384,14,304,3,0,
384,14,315,4,0,
384,14,317,4,0,
384,14,332,4,0,
384,14,337,4,0,
384,14,339,4,0,
384,14,349,1,60,
384,14,360,4,0,
384,14,366,3,0,
384,14,374,4,0,
384,14,401,3,0,
384,14,403,1,35,
384,14,406,1,90,
384,14,406,3,0,
384,14,411,4,0,
384,14,412,4,0,
384,14,414,3,0,
384,14,416,4,0,
384,14,421,4,0,
384,14,434,3,0,
384,14,442,3,0,
384,14,444,4,0,
384,14,468,4,0,
384,14,496,4,0,
384,14,497,4,0,
384,14,507,4,0,
384,14,510,4,0,
384,14,523,4,0,
384,14,525,4,0,
384,15,14,4,0,
384,15,19,1,65,
384,15,19,4,0,
384,15,46,4,0,
384,15,53,4,0,
384,15,57,4,0,
384,15,58,4,0,
384,15,59,4,0,
384,15,63,1,80,
384,15,63,4,0,
384,15,70,4,0,
384,15,76,4,0,
384,15,85,4,0,
384,15,86,4,0,
384,15,87,4,0,
384,15,89,4,0,
384,15,92,4,0,
384,15,104,4,0,
384,15,126,4,0,
384,15,127,4,0,
384,15,156,1,30,
384,15,156,4,0,
384,15,157,4,0,
384,15,164,4,0,
384,15,182,4,0,
384,15,184,1,5,
384,15,200,1,50,
384,15,201,4,0,
384,15,207,4,0,
384,15,214,4,0,
384,15,216,4,0,
384,15,218,4,0,
384,15,237,4,0,
384,15,239,1,1,
384,15,240,4,0,
384,15,241,4,0,
384,15,242,1,15,
384,15,244,4,0,
384,15,245,1,75,
384,15,246,1,45,
384,15,249,4,0,
384,15,263,4,0,
384,15,280,4,0,
384,15,304,1,20,
384,15,315,4,0,
384,15,317,4,0,
384,15,332,4,0,
384,15,337,4,0,
384,15,339,4,0,
384,15,349,1,60,
384,15,360,4,0,
384,15,374,4,0,
384,15,403,1,35,
384,15,406,1,90,
384,15,411,4,0,
384,15,412,4,0,
384,15,416,4,0,
384,15,421,4,0,
384,15,434,3,0,
384,15,444,4,0,
384,15,468,4,0,
384,15,496,4,0,
384,15,497,4,0,
384,15,507,4,0,
384,15,510,4,0,
384,15,523,4,0,
384,15,525,4,0,
384,15,590,4,0,
384,16,14,4,0,
384,16,19,1,65,1
384,16,19,4,0,
384,16,20,3,0,
384,16,46,4,0,
384,16,53,4,0,
384,16,57,4,0,
384,16,58,4,0,
384,16,59,4,0,
384,16,63,1,90,1
384,16,63,4,0,
384,16,70,4,0,
384,16,76,4,0,
384,16,85,4,0,
384,16,86,4,0,
384,16,87,4,0,
384,16,89,4,0,
384,16,92,4,0,
384,16,104,4,0,
384,16,126,4,0,
384,16,127,4,0,
384,16,156,1,35,1
384,16,156,4,0,
384,16,157,4,0,
384,16,164,4,0,
384,16,173,3,0,
384,16,182,4,0,
384,16,184,1,5,1
384,16,196,3,0,
384,16,200,1,80,1
384,16,200,3,0,
384,16,201,4,0,
384,16,207,4,0,
384,16,214,4,0,
384,16,216,4,0,
384,16,218,4,0,
384,16,231,3,0,
384,16,237,4,0,
384,16,239,1,1,1
384,16,240,4,0,
384,16,241,4,0,
384,16,242,1,20,1
384,16,244,4,0,
384,16,245,1,45,1
384,16,246,1,15,1
384,16,249,4,0,
384,16,253,3,0,
384,16,263,4,0,
384,16,280,4,0,
384,16,290,4,0,
384,16,291,4,0,
384,16,304,1,75,1
384,16,304,3,0,
384,16,315,4,0,
384,16,317,4,0,
384,16,332,4,0,
384,16,337,4,0,
384,16,339,4,0,
384,16,349,1,60,1
384,16,351,3,0,
384,16,352,3,0,
384,16,360,4,0,
384,16,366,3,0,
384,16,374,4,0,
384,16,401,3,0,
384,16,403,1,30,1
384,16,406,1,50,1
384,16,406,3,0,
384,16,411,4,0,
384,16,412,4,0,
384,16,414,3,0,
384,16,416,4,0,
384,16,421,4,0,
384,16,434,3,0,
384,16,442,3,0,
384,16,444,4,0,
384,16,468,4,0,
384,16,496,4,0,
384,16,497,4,0,
384,16,507,4,0,
384,16,510,4,0,
384,16,523,4,0,
384,16,525,4,0,
384,16,590,4,0,
384,16,620,3,0,
384,17,14,4,0,
384,17,19,1,65,
384,17,19,4,0,
384,17,46,4,0,
384,17,53,4,0,
384,17,57,4,0,
384,17,58,4,0,
384,17,59,4,0,
384,17,63,1,90,
384,17,63,4,0,
384,17,76,4,0,
384,17,85,4,0,
384,17,86,4,0,
384,17,87,4,0,
384,17,89,4,0,
384,17,92,4,0,
384,17,104,4,0,
384,17,126,4,0,
384,17,127,4,0,
384,17,156,1,35,
384,17,156,4,0,
384,17,157,4,0,
384,17,164,4,0,
384,17,182,4,0,
384,17,184,1,5,
384,17,200,1,80,
384,17,201,4,0,
384,17,207,4,0,
384,17,214,4,0,
384,17,216,4,0,
384,17,218,4,0,
384,17,237,4,0,
384,17,239,1,1,
384,17,240,4,0,
384,17,241,4,0,
384,17,242,1,20,
384,17,244,4,0,
384,17,245,1,45,
384,17,246,1,15,
384,17,263,4,0,
384,17,280,4,0,
384,17,304,1,75,
384,17,315,4,0,
384,17,317,4,0,
384,17,332,4,0,
384,17,337,4,0,
384,17,339,4,0,
384,17,349,1,60,
384,17,360,4,0,
384,17,374,4,0,
384,17,403,1,30,
384,17,406,1,50,
384,17,411,4,0,
384,17,412,4,0,
384,17,416,4,0,
384,17,421,4,0,
384,17,434,3,0,
384,17,444,4,0,
384,17,496,4,0,
384,17,497,4,0,
384,17,507,4,0,
384,17,523,4,0,
384,17,525,4,0,
384,17,590,4,0,
384,17,620,3,0,
384,17,693,4,0,
384,18,14,4,0,
384,18,19,1,65,
384,18,19,4,0,
384,18,46,4,0,
384,18,53,4,0,
384,18,57,4,0,
384,18,58,4,0,
384,18,59,4,0,
384,18,63,1,90,
384,18,63,4,0,
384,18,76,4,0,
384,18,85,4,0,
384,18,86,4,0,
384,18,87,4,0,
384,18,89,4,0,
384,18,92,4,0,
384,18,104,4,0,
384,18,126,4,0,
384,18,127,4,0,
384,18,156,1,35,
384,18,156,4,0,
384,18,157,4,0,
384,18,164,4,0,
384,18,182,4,0,
384,18,184,1,5,
384,18,200,1,80,
384,18,201,4,0,
384,18,207,4,0,
384,18,214,4,0,
384,18,216,4,0,
384,18,218,4,0,
384,18,237,4,0,
384,18,239,1,1,
384,18,240,4,0,
384,18,241,4,0,
384,18,242,1,20,
384,18,244,4,0,
384,18,245,1,45,
384,18,246,1,15,
384,18,263,4,0,
384,18,280,4,0,
384,18,304,1,75,
384,18,315,4,0,
384,18,317,4,0,
384,18,332,4,0,
384,18,337,4,0,
384,18,339,4,0,
384,18,349,1,60,
384,18,360,4,0,
384,18,374,4,0,
384,18,403,1,30,
384,18,406,1,50,
384,18,411,4,0,
384,18,412,4,0,
384,18,416,4,0,
384,18,421,4,0,
384,18,434,3,0,
384,18,444,4,0,
384,18,496,4,0,
384,18,497,4,0,
384,18,507,4,0,
384,18,523,4,0,
384,18,525,4,0,
384,18,590,4,0,
384,18,620,3,0,
384,18,693,4,0,
385,5,38,1,35,
385,5,63,4,0,
385,5,85,4,0,
385,5,87,4,0,
385,5,92,4,0,
385,5,93,1,1,2
385,5,94,1,20,
385,5,94,4,0,
385,5,104,4,0,
385,5,113,4,0,
385,5,115,4,0,
385,5,129,1,10,
385,5,148,4,0,
385,5,156,1,5,
385,5,156,1,30,
385,5,156,4,0,
385,5,182,4,0,
385,5,201,4,0,
385,5,216,4,0,
385,5,218,4,0,
385,5,219,4,0,
385,5,237,4,0,
385,5,240,4,0,
385,5,241,4,0,
385,5,247,4,0,
385,5,248,1,40,
385,5,263,4,0,
385,5,270,1,15,
385,5,273,1,1,1
385,5,285,4,0,
385,5,287,1,25,
385,5,290,4,0,
385,5,322,1,45,
385,5,332,4,0,
385,5,347,4,0,
385,5,351,4,0,
385,5,352,4,0,
385,5,353,1,50,
385,6,7,3,0,
385,6,8,3,0,
385,6,9,3,0,
385,6,34,3,0,
385,6,38,1,35,
385,6,38,3,0,
385,6,63,4,0,
385,6,85,4,0,
385,6,86,3,0,
385,6,87,4,0,
385,6,92,4,0,
385,6,93,1,1,2
385,6,94,1,20,
385,6,94,4,0,
385,6,102,3,0,
385,6,104,4,0,
385,6,111,3,0,
385,6,113,4,0,
385,6,115,4,0,
385,6,118,3,0,
385,6,129,1,10,
385,6,129,3,0,
385,6,138,3,0,
385,6,148,4,0,
385,6,156,1,5,
385,6,156,1,30,
385,6,156,4,0,
385,6,164,3,0,
385,6,173,3,0,
385,6,182,4,0,
385,6,189,3,0,
385,6,196,3,0,
385,6,201,4,0,
385,6,203,3,0,
385,6,207,3,0,
385,6,214,3,0,
385,6,216,4,0,
385,6,218,4,0,
385,6,219,4,0,
385,6,223,3,0,
385,6,237,4,0,
385,6,240,4,0,
385,6,241,4,0,
385,6,244,3,0,
385,6,247,4,0,
385,6,248,1,40,
385,6,263,4,0,
385,6,270,1,15,
385,6,273,1,1,1
385,6,285,4,0,
385,6,287,1,25,
385,6,290,4,0,
385,6,322,1,45,
385,6,332,4,0,
385,6,347,4,0,
385,6,351,4,0,
385,6,352,4,0,
385,6,353,1,50,
385,7,34,3,0,
385,7,38,1,35,
385,7,38,3,0,
385,7,63,4,0,
385,7,85,4,0,
385,7,86,3,0,
385,7,87,4,0,
385,7,92,4,0,
385,7,93,1,1,2
385,7,94,1,20,
385,7,94,4,0,
385,7,102,3,0,
385,7,104,4,0,
385,7,113,4,0,
385,7,115,4,0,
385,7,118,3,0,
385,7,129,1,10,
385,7,138,3,0,
385,7,148,4,0,
385,7,156,1,5,
385,7,156,1,30,
385,7,156,4,0,
385,7,164,3,0,
385,7,182,4,0,
385,7,201,4,0,
385,7,216,4,0,
385,7,218,4,0,
385,7,219,4,0,
385,7,237,4,0,
385,7,240,4,0,
385,7,241,4,0,
385,7,247,4,0,
385,7,248,1,40,
385,7,263,4,0,
385,7,270,1,15,
385,7,273,1,1,1
385,7,285,4,0,
385,7,287,1,25,
385,7,290,4,0,
385,7,322,1,45,
385,7,332,4,0,
385,7,347,4,0,
385,7,351,4,0,
385,7,352,4,0,
385,7,353,1,50,
385,8,38,1,40,
385,8,63,4,0,
385,8,85,4,0,
385,8,86,4,0,
385,8,87,4,0,
385,8,92,4,0,
385,8,93,1,1,2
385,8,94,1,20,
385,8,94,4,0,
385,8,104,4,0,
385,8,113,4,0,
385,8,115,4,0,
385,8,129,1,10,
385,8,138,4,0,
385,8,148,4,0,
385,8,156,1,5,
385,8,156,1,30,
385,8,156,4,0,
385,8,164,4,0,
385,8,182,4,0,
385,8,201,4,0,
385,8,203,4,0,
385,8,207,4,0,
385,8,214,4,0,
385,8,216,4,0,
385,8,218,4,0,
385,8,219,4,0,
385,8,237,4,0,
385,8,240,4,0,
385,8,241,4,0,
385,8,244,4,0,
385,8,247,4,0,
385,8,248,1,55,
385,8,263,4,0,
385,8,270,1,15,
385,8,273,1,1,1
385,8,278,4,0,
385,8,285,4,0,
385,8,287,1,25,
385,8,290,4,0,
385,8,322,1,60,
385,8,332,4,0,
385,8,347,4,0,
385,8,351,4,0,
385,8,352,4,0,
385,8,353,1,70,
385,8,356,1,45,
385,8,361,1,50,
385,8,363,4,0,
385,8,369,4,0,
385,8,374,4,0,
385,8,387,1,65,
385,8,409,4,0,
385,8,412,4,0,
385,8,416,4,0,
385,8,428,1,35,
385,8,430,4,0,
385,8,433,4,0,
385,8,446,4,0,
385,8,447,4,0,
385,8,451,4,0,
385,9,7,3,0,
385,9,8,3,0,
385,9,9,3,0,
385,9,38,1,40,
385,9,63,4,0,
385,9,85,4,0,
385,9,86,4,0,
385,9,87,4,0,
385,9,92,4,0,
385,9,93,1,1,2
385,9,94,1,20,
385,9,94,4,0,
385,9,104,4,0,
385,9,113,4,0,
385,9,115,4,0,
385,9,129,1,10,
385,9,129,3,0,
385,9,138,4,0,
385,9,148,4,0,
385,9,156,1,5,
385,9,156,1,30,
385,9,156,4,0,
385,9,164,4,0,
385,9,173,3,0,
385,9,182,4,0,
385,9,189,3,0,
385,9,196,3,0,
385,9,201,4,0,
385,9,203,4,0,
385,9,207,4,0,
385,9,214,4,0,
385,9,216,4,0,
385,9,218,4,0,
385,9,219,4,0,
385,9,237,4,0,
385,9,240,4,0,
385,9,241,4,0,
385,9,244,4,0,
385,9,246,3,0,
385,9,247,4,0,
385,9,248,1,55,
385,9,253,3,0,
385,9,263,4,0,
385,9,270,1,15,
385,9,270,3,0,
385,9,271,3,0,
385,9,273,1,1,1
385,9,278,4,0,
385,9,285,4,0,
385,9,287,1,25,
385,9,290,4,0,
385,9,322,1,60,
385,9,324,3,0,
385,9,332,4,0,
385,9,334,3,0,
385,9,347,4,0,
385,9,351,4,0,
385,9,352,4,0,
385,9,353,1,70,
385,9,356,1,45,
385,9,361,1,50,
385,9,363,4,0,
385,9,369,4,0,
385,9,374,4,0,
385,9,387,1,65,
385,9,387,3,0,
385,9,409,4,0,
385,9,412,4,0,
385,9,416,4,0,
385,9,428,1,35,
385,9,428,3,0,
385,9,430,4,0,
385,9,433,4,0,
385,9,442,3,0,
385,9,446,4,0,
385,9,447,4,0,
385,9,451,4,0,
385,10,7,3,0,
385,10,8,3,0,
385,10,9,3,0,
385,10,29,3,0,
385,10,38,1,40,
385,10,63,4,0,
385,10,85,4,0,
385,10,86,4,0,
385,10,87,4,0,
385,10,92,4,0,
385,10,93,1,1,2
385,10,94,1,20,
385,10,94,4,0,
385,10,104,4,0,
385,10,113,4,0,
385,10,115,4,0,
385,10,129,1,10,
385,10,129,3,0,
385,10,138,4,0,
385,10,148,4,0,
385,10,156,1,5,
385,10,156,1,30,
385,10,156,4,0,
385,10,164,4,0,
385,10,173,3,0,
385,10,182,4,0,
385,10,189,3,0,
385,10,196,3,0,
385,10,201,4,0,
385,10,203,4,0,
385,10,207,4,0,
385,10,214,4,0,
385,10,216,4,0,
385,10,218,4,0,
385,10,219,4,0,
385,10,237,4,0,
385,10,240,4,0,
385,10,241,4,0,
385,10,244,4,0,
385,10,246,3,0,
385,10,247,4,0,
385,10,248,1,55,
385,10,253,3,0,
385,10,263,4,0,
385,10,270,1,15,
385,10,270,3,0,
385,10,271,3,0,
385,10,273,1,1,1
385,10,277,3,0,
385,10,278,4,0,
385,10,285,4,0,
385,10,287,1,25,
385,10,290,4,0,
385,10,322,1,60,
385,10,324,3,0,
385,10,332,4,0,
385,10,334,3,0,
385,10,347,4,0,
385,10,351,4,0,
385,10,352,4,0,
385,10,353,1,70,
385,10,356,1,45,
385,10,356,3,0,
385,10,361,1,50,
385,10,363,4,0,
385,10,369,4,0,
385,10,374,4,0,
385,10,387,1,65,
385,10,387,3,0,
385,10,409,4,0,
385,10,412,4,0,
385,10,416,4,0,
385,10,428,1,35,
385,10,428,3,0,
385,10,430,4,0,
385,10,433,4,0,
385,10,442,3,0,
385,10,446,4,0,
385,10,447,4,0,
385,10,451,4,0,
385,11,38,1,40,
385,11,63,4,0,
385,11,85,4,0,
385,11,86,4,0,
385,11,87,4,0,
385,11,92,4,0,
385,11,93,1,1,2
385,11,94,1,20,
385,11,94,4,0,
385,11,104,4,0,
385,11,113,4,0,
385,11,115,4,0,
385,11,129,1,10,
385,11,138,4,0,
385,11,148,4,0,
385,11,156,1,5,
385,11,156,1,30,
385,11,156,4,0,
385,11,164,4,0,
385,11,182,4,0,
385,11,201,4,0,
385,11,207,4,0,
385,11,216,4,0,
385,11,218,4,0,
385,11,219,4,0,
385,11,237,4,0,
385,11,240,4,0,
385,11,241,4,0,
385,11,244,4,0,
385,11,247,4,0,
385,11,248,1,55,
385,11,263,4,0,
385,11,270,1,15,
385,11,273,1,1,1
385,11,287,1,25,
385,11,322,1,60,
385,11,332,4,0,
385,11,347,4,0,
385,11,353,1,70,
385,11,356,1,45,
385,11,361,1,50,
385,11,369,4,0,
385,11,374,4,0,
385,11,387,1,65,
385,11,412,4,0,
385,11,416,4,0,
385,11,428,1,35,
385,11,430,4,0,
385,11,433,4,0,
385,11,447,4,0,
385,11,451,4,0,
385,11,473,4,0,
385,11,477,4,0,
385,11,496,4,0,
385,12,38,1,35,
385,12,63,4,0,
385,12,85,4,0,
385,12,87,4,0,
385,12,92,4,0,
385,12,93,1,1,2
385,12,94,1,20,
385,12,94,4,0,
385,12,104,4,0,
385,12,113,4,0,
385,12,115,4,0,
385,12,129,1,10,
385,12,148,4,0,
385,12,156,1,5,
385,12,156,1,30,
385,12,156,4,0,
385,12,182,4,0,
385,12,201,4,0,
385,12,216,4,0,
385,12,218,4,0,
385,12,219,4,0,
385,12,237,4,0,
385,12,240,4,0,
385,12,241,4,0,
385,12,247,4,0,
385,12,248,1,40,
385,12,263,4,0,
385,12,270,1,15,
385,12,273,1,1,1
385,12,285,4,0,
385,12,287,1,25,
385,12,290,4,0,
385,12,322,1,45,
385,12,332,4,0,
385,12,347,4,0,
385,12,351,4,0,
385,12,352,4,0,
385,12,353,1,50,
385,13,34,3,0,
385,13,38,1,35,
385,13,38,3,0,
385,13,63,4,0,
385,13,85,4,0,
385,13,86,3,0,
385,13,87,4,0,
385,13,92,4,0,
385,13,93,1,1,2
385,13,94,1,20,
385,13,94,4,0,
385,13,102,3,0,
385,13,104,4,0,
385,13,113,4,0,
385,13,115,4,0,
385,13,129,1,10,
385,13,138,3,0,
385,13,148,4,0,
385,13,156,1,5,
385,13,156,1,30,
385,13,156,4,0,
385,13,164,3,0,
385,13,171,3,0,
385,13,182,4,0,
385,13,196,3,0,
385,13,201,4,0,
385,13,207,3,0,
385,13,216,4,0,
385,13,218,4,0,
385,13,219,4,0,
385,13,237,4,0,
385,13,240,4,0,
385,13,241,4,0,
385,13,247,4,0,
385,13,248,1,40,
385,13,263,4,0,
385,13,270,1,15,
385,13,273,1,1,1
385,13,285,4,0,
385,13,287,1,25,
385,13,290,4,0,
385,13,322,1,45,
385,13,332,4,0,
385,13,347,4,0,
385,13,351,4,0,
385,13,352,4,0,
385,13,353,1,50,
385,14,7,3,0,
385,14,8,3,0,
385,14,9,3,0,
385,14,38,1,40,
385,14,63,4,0,
385,14,85,4,0,
385,14,86,4,0,
385,14,87,4,0,
385,14,92,4,0,
385,14,93,1,1,2
385,14,94,1,20,
385,14,94,4,0,
385,14,104,4,0,
385,14,113,4,0,
385,14,115,4,0,
385,14,129,1,10,
385,14,138,4,0,
385,14,148,4,0,
385,14,156,1,5,
385,14,156,1,30,
385,14,156,4,0,
385,14,164,4,0,
385,14,173,3,0,
385,14,182,4,0,
385,14,196,3,0,
385,14,201,4,0,
385,14,207,4,0,
385,14,214,3,0,
385,14,216,4,0,
385,14,218,4,0,
385,14,219,4,0,
385,14,237,4,0,
385,14,240,4,0,
385,14,241,4,0,
385,14,244,4,0,
385,14,247,4,0,
385,14,248,1,55,
385,14,253,3,0,
385,14,263,4,0,
385,14,270,1,15,
385,14,270,3,0,
385,14,271,3,0,
385,14,273,1,1,1
385,14,277,3,0,
385,14,278,3,0,
385,14,285,3,0,
385,14,287,1,25,
385,14,322,1,60,
385,14,324,3,0,
385,14,332,4,0,
385,14,334,3,0,
385,14,347,4,0,
385,14,353,1,70,
385,14,356,1,45,
385,14,356,3,0,
385,14,361,1,50,
385,14,369,4,0,
385,14,374,4,0,
385,14,387,1,65,
385,14,387,3,0,
385,14,409,3,0,
385,14,412,4,0,
385,14,416,4,0,
385,14,428,1,35,
385,14,428,3,0,
385,14,430,4,0,
385,14,433,4,0,
385,14,442,3,0,
385,14,446,3,0,
385,14,447,4,0,
385,14,451,4,0,
385,14,473,4,0,
385,14,477,4,0,
385,14,478,3,0,
385,14,496,4,0,
385,15,38,1,40,
385,15,63,4,0,
385,15,85,4,0,
385,15,86,4,0,
385,15,87,4,0,
385,15,92,4,0,
385,15,93,1,1,2
385,15,94,1,20,
385,15,94,4,0,
385,15,104,4,0,
385,15,113,4,0,
385,15,115,4,0,
385,15,129,1,10,
385,15,138,4,0,
385,15,148,4,0,
385,15,156,1,5,
385,15,156,1,30,
385,15,156,4,0,
385,15,164,4,0,
385,15,182,4,0,
385,15,201,4,0,
385,15,207,4,0,
385,15,214,4,0,
385,15,216,4,0,
385,15,218,4,0,
385,15,219,4,0,
385,15,237,4,0,
385,15,240,4,0,
385,15,241,4,0,
385,15,244,4,0,
385,15,247,4,0,
385,15,248,1,55,
385,15,263,4,0,
385,15,270,1,15,
385,15,273,1,1,1
385,15,287,1,25,
385,15,322,1,60,
385,15,332,4,0,
385,15,347,4,0,
385,15,353,1,70,
385,15,356,1,45,
385,15,361,1,50,
385,15,369,4,0,
385,15,374,4,0,
385,15,387,1,65,
385,15,412,4,0,
385,15,416,4,0,
385,15,428,1,35,
385,15,430,4,0,
385,15,433,4,0,
385,15,447,4,0,
385,15,451,4,0,
385,15,473,4,0,
385,15,496,4,0,
385,15,590,4,0,
385,15,605,4,0,
385,15,612,4,0,
385,16,7,3,0,
385,16,8,3,0,
385,16,9,3,0,
385,16,38,1,40,1
385,16,63,4,0,
385,16,85,4,0,
385,16,86,4,0,
385,16,87,4,0,
385,16,92,4,0,
385,16,93,1,1,2
385,16,94,1,20,1
385,16,94,4,0,
385,16,104,4,0,
385,16,113,4,0,
385,16,115,4,0,
385,16,129,1,10,1
385,16,138,4,0,
385,16,148,4,0,
385,16,156,1,5,1
385,16,156,1,30,1
385,16,156,4,0,
385,16,164,4,0,
385,16,173,3,0,
385,16,182,4,0,
385,16,196,3,0,
385,16,201,4,0,
385,16,207,4,0,
385,16,214,4,0,
385,16,216,4,0,
385,16,218,4,0,
385,16,219,4,0,
385,16,237,4,0,
385,16,240,4,0,
385,16,241,4,0,
385,16,244,4,0,
385,16,247,4,0,
385,16,248,1,55,1
385,16,253,3,0,
385,16,263,4,0,
385,16,270,1,15,1
385,16,270,3,0,
385,16,271,3,0,
385,16,273,1,1,1
385,16,277,3,0,
385,16,278,3,0,
385,16,285,3,0,
385,16,287,1,25,1
385,16,290,4,0,
385,16,322,1,60,1
385,16,324,3,0,
385,16,332,4,0,
385,16,334,3,0,
385,16,347,4,0,
385,16,351,3,0,
385,16,352,3,0,
385,16,353,1,70,1
385,16,356,1,45,1
385,16,356,3,0,
385,16,361,1,50,1
385,16,369,4,0,
385,16,374,4,0,
385,16,387,1,65,1
385,16,387,3,0,
385,16,409,3,0,
385,16,412,4,0,
385,16,416,4,0,
385,16,428,1,35,1
385,16,428,3,0,
385,16,430,4,0,
385,16,433,4,0,
385,16,442,3,0,
385,16,446,3,0,
385,16,447,4,0,
385,16,451,4,0,
385,16,473,4,0,
385,16,478,3,0,
385,16,496,4,0,
385,16,590,4,0,
385,16,605,4,0,
385,16,612,4,0,
385,17,38,1,40,
385,17,63,4,0,
385,17,85,4,0,
385,17,86,4,0,
385,17,87,4,0,
385,17,92,4,0,
385,17,93,1,1,2
385,17,94,1,20,
385,17,94,4,0,
385,17,104,4,0,
385,17,113,4,0,
385,17,115,4,0,
385,17,129,1,10,
385,17,138,4,0,
385,17,156,1,5,
385,17,156,1,30,
385,17,156,4,0,
385,17,164,4,0,
385,17,182,4,0,
385,17,201,4,0,
385,17,207,4,0,
385,17,214,4,0,
385,17,216,4,0,
385,17,218,4,0,
385,17,219,4,0,
385,17,237,4,0,
385,17,240,4,0,
385,17,241,4,0,
385,17,244,4,0,
385,17,247,4,0,
385,17,248,1,55,
385,17,263,4,0,
385,17,270,1,15,
385,17,273,1,1,1
385,17,287,1,25,
385,17,322,1,60,
385,17,332,4,0,
385,17,347,4,0,
385,17,353,1,70,
385,17,356,1,45,
385,17,361,1,50,
385,17,369,4,0,
385,17,374,4,0,
385,17,387,1,65,
385,17,412,4,0,
385,17,416,4,0,
385,17,428,1,35,
385,17,430,4,0,
385,17,433,4,0,
385,17,447,4,0,
385,17,451,4,0,
385,17,473,4,0,
385,17,496,4,0,
385,17,590,4,0,
385,17,605,4,0,
385,18,38,1,40,
385,18,63,4,0,
385,18,85,4,0,
385,18,86,4,0,
385,18,87,4,0,
385,18,92,4,0,
385,18,93,1,1,2
385,18,94,1,20,
385,18,94,4,0,
385,18,104,4,0,
385,18,113,4,0,
385,18,115,4,0,
385,18,129,1,10,
385,18,138,4,0,
385,18,156,1,5,
385,18,156,4,0,
385,18,164,4,0,
385,18,182,4,0,
385,18,201,4,0,
385,18,207,4,0,
385,18,214,4,0,
385,18,216,4,0,
385,18,218,4,0,
385,18,219,4,0,
385,18,237,4,0,
385,18,240,4,0,
385,18,241,4,0,
385,18,244,4,0,
385,18,247,4,0,
385,18,248,1,55,
385,18,263,4,0,
385,18,270,1,15,
385,18,273,1,1,1
385,18,287,1,25,
385,18,322,1,60,
385,18,332,4,0,
385,18,347,4,0,
385,18,353,1,70,
385,18,356,1,45,
385,18,361,1,50,
385,18,369,4,0,
385,18,374,4,0,
385,18,381,1,30,
385,18,387,1,65,
385,18,412,4,0,
385,18,416,4,0,
385,18,428,1,35,
385,18,430,4,0,
385,18,433,4,0,
385,18,447,4,0,
385,18,451,4,0,
385,18,473,4,0,
385,18,496,4,0,
385,18,590,4,0,
385,18,605,4,0,
386,5,15,4,0,
386,5,35,1,1,2
386,5,43,1,1,1
386,5,58,4,0,
386,5,63,1,50,
386,5,63,4,0,
386,5,70,4,0,
386,5,76,4,0,
386,5,85,4,0,
386,5,87,4,0,
386,5,92,4,0,
386,5,94,1,25,
386,5,94,4,0,
386,5,100,1,10,
386,5,101,1,5,
386,5,104,4,0,
386,5,105,1,40,
386,5,113,4,0,
386,5,115,4,0,
386,5,148,4,0,
386,5,156,4,0,
386,5,182,4,0,
386,5,216,4,0,
386,5,218,4,0,
386,5,219,4,0,
386,5,228,1,20,
386,5,237,4,0,
386,5,240,4,0,
386,5,241,4,0,
386,5,247,4,0,
386,5,249,4,0,
386,5,259,4,0,
386,5,263,4,0,
386,5,264,4,0,
386,5,269,4,0,
386,5,280,4,0,
386,5,282,1,15,
386,5,285,4,0,
386,5,289,1,30,
386,5,289,4,0,
386,5,290,4,0,
386,5,317,4,0,
386,5,322,1,35,
386,5,332,4,0,
386,5,347,4,0,
386,5,351,4,0,
386,5,352,4,0,
386,5,354,1,45,
386,8,15,4,0,
386,8,35,1,1,2
386,8,43,1,1,1
386,8,58,4,0,
386,8,63,1,97,
386,8,63,4,0,
386,8,70,4,0,
386,8,76,4,0,
386,8,85,4,0,
386,8,86,4,0,
386,8,87,4,0,
386,8,92,4,0,
386,8,94,1,41,
386,8,94,4,0,
386,8,100,1,17,
386,8,101,1,9,
386,8,104,4,0,
386,8,105,1,81,
386,8,113,4,0,
386,8,115,4,0,
386,8,138,4,0,
386,8,148,4,0,
386,8,156,4,0,
386,8,157,4,0,
386,8,164,4,0,
386,8,182,4,0,
386,8,203,4,0,
386,8,207,4,0,
386,8,214,4,0,
386,8,216,4,0,
386,8,218,4,0,
386,8,219,4,0,
386,8,228,1,33,
386,8,237,4,0,
386,8,240,4,0,
386,8,241,4,0,
386,8,244,4,0,
386,8,247,4,0,
386,8,249,4,0,
386,8,259,4,0,
386,8,263,4,0,
386,8,264,4,0,
386,8,269,4,0,
386,8,278,4,0,
386,8,280,4,0,
386,8,282,1,25,
386,8,285,4,0,
386,8,289,1,49,
386,8,289,4,0,
386,8,290,4,0,
386,8,317,4,0,
386,8,322,1,73,
386,8,332,4,0,
386,8,347,4,0,
386,8,351,4,0,
386,8,352,4,0,
386,8,354,1,89,
386,8,363,4,0,
386,8,374,4,0,
386,8,375,1,57,
386,8,398,4,0,
386,8,409,4,0,
386,8,411,4,0,
386,8,412,4,0,
386,8,416,4,0,
386,8,419,4,0,
386,8,428,1,65,
386,8,430,4,0,
386,8,433,4,0,
386,8,446,4,0,
386,8,447,4,0,
386,8,451,4,0,
386,9,7,3,0,
386,9,8,3,0,
386,9,9,3,0,
386,9,15,4,0,
386,9,35,1,1,2
386,9,43,1,1,1
386,9,58,4,0,
386,9,63,1,97,
386,9,63,4,0,
386,9,70,4,0,
386,9,76,4,0,
386,9,85,4,0,
386,9,86,4,0,
386,9,87,4,0,
386,9,92,4,0,
386,9,94,1,41,
386,9,94,4,0,
386,9,100,1,17,
386,9,101,1,9,
386,9,104,4,0,
386,9,105,1,81,
386,9,113,4,0,
386,9,115,4,0,
386,9,129,3,0,
386,9,138,4,0,
386,9,148,4,0,
386,9,156,4,0,
386,9,157,4,0,
386,9,164,4,0,
386,9,173,3,0,
386,9,182,4,0,
386,9,189,3,0,
386,9,196,3,0,
386,9,203,4,0,
386,9,207,4,0,
386,9,214,4,0,
386,9,216,4,0,
386,9,218,4,0,
386,9,219,4,0,
386,9,228,1,33,
386,9,237,4,0,
386,9,240,4,0,
386,9,241,4,0,
386,9,244,4,0,
386,9,247,4,0,
386,9,249,4,0,
386,9,259,4,0,
386,9,263,4,0,
386,9,264,4,0,
386,9,269,4,0,
386,9,271,3,0,
386,9,278,4,0,
386,9,280,4,0,
386,9,282,1,25,
386,9,282,3,0,
386,9,285,4,0,
386,9,289,1,49,
386,9,289,4,0,
386,9,290,4,0,
386,9,317,4,0,
386,9,322,1,73,
386,9,324,3,0,
386,9,332,4,0,
386,9,347,4,0,
386,9,351,4,0,
386,9,352,4,0,
386,9,354,1,89,
386,9,363,4,0,
386,9,374,4,0,
386,9,375,1,57,
386,9,398,4,0,
386,9,409,4,0,
386,9,411,4,0,
386,9,412,4,0,
386,9,416,4,0,
386,9,419,4,0,
386,9,428,1,65,
386,9,428,3,0,
386,9,430,4,0,
386,9,433,4,0,
386,9,446,4,0,
386,9,447,4,0,
386,9,451,4,0,
386,10,7,3,0,
386,10,8,3,0,
386,10,9,3,0,
386,10,15,4,0,
386,10,29,3,0,
386,10,35,1,1,2
386,10,43,1,1,1
386,10,58,4,0,
386,10,63,1,97,
386,10,63,4,0,
386,10,67,3,0,
386,10,70,4,0,
386,10,76,4,0,
386,10,85,4,0,
386,10,86,4,0,
386,10,87,4,0,
386,10,92,4,0,
386,10,94,1,41,
386,10,94,4,0,
386,10,100,1,17,
386,10,101,1,9,
386,10,104,4,0,
386,10,105,1,81,
386,10,113,4,0,
386,10,115,4,0,
386,10,129,3,0,
386,10,138,4,0,
386,10,148,4,0,
386,10,156,4,0,
386,10,157,4,0,
386,10,164,4,0,
386,10,173,3,0,
386,10,182,4,0,
386,10,189,3,0,
386,10,196,3,0,
386,10,203,4,0,
386,10,207,4,0,
386,10,214,4,0,
386,10,216,4,0,
386,10,218,4,0,
386,10,219,4,0,
386,10,228,1,33,
386,10,237,4,0,
386,10,240,4,0,
386,10,241,4,0,
386,10,244,4,0,
386,10,247,4,0,
386,10,249,4,0,
386,10,259,4,0,
386,10,263,4,0,
386,10,264,4,0,
386,10,269,4,0,
386,10,271,3,0,
386,10,272,3,0,
386,10,277,3,0,
386,10,278,4,0,
386,10,280,4,0,
386,10,282,1,25,
386,10,282,3,0,
386,10,285,4,0,
386,10,289,1,49,
386,10,289,4,0,
386,10,290,4,0,
386,10,317,4,0,
386,10,322,1,73,
386,10,324,3,0,
386,10,332,4,0,
386,10,347,4,0,
386,10,351,4,0,
386,10,352,4,0,
386,10,354,1,89,
386,10,356,3,0,
386,10,363,4,0,
386,10,374,4,0,
386,10,375,1,57,
386,10,398,4,0,
386,10,409,4,0,
386,10,411,4,0,
386,10,412,4,0,
386,10,416,4,0,
386,10,419,4,0,
386,10,428,1,65,
386,10,428,3,0,
386,10,430,4,0,
386,10,433,4,0,
386,10,446,4,0,
386,10,447,4,0,
386,10,451,4,0,
386,11,15,4,0,
386,11,35,1,1,2
386,11,43,1,1,1
386,11,58,4,0,
386,11,63,1,97,
386,11,63,4,0,
386,11,70,4,0,
386,11,76,4,0,
386,11,85,4,0,
386,11,86,4,0,
386,11,87,4,0,
386,11,92,4,0,
386,11,94,1,41,
386,11,94,4,0,
386,11,100,1,17,
386,11,101,1,9,
386,11,104,4,0,
386,11,105,1,81,
386,11,113,4,0,
386,11,115,4,0,
386,11,138,4,0,
386,11,148,4,0,
386,11,156,4,0,
386,11,157,4,0,
386,11,164,4,0,
386,11,182,4,0,
386,11,207,4,0,
386,11,216,4,0,
386,11,218,4,0,
386,11,219,4,0,
386,11,228,1,33,
386,11,237,4,0,
386,11,240,4,0,
386,11,241,4,0,
386,11,244,4,0,
386,11,247,4,0,
386,11,249,4,0,
386,11,259,4,0,
386,11,263,4,0,
386,11,269,4,0,
386,11,280,4,0,
386,11,282,1,25,
386,11,289,1,49,
386,11,317,4,0,
386,11,322,1,73,
386,11,332,4,0,
386,11,347,4,0,
386,11,354,1,89,
386,11,374,4,0,
386,11,375,1,57,
386,11,398,4,0,
386,11,411,4,0,
386,11,412,4,0,
386,11,416,4,0,
386,11,428,1,65,
386,11,430,4,0,
386,11,433,4,0,
386,11,447,4,0,
386,11,451,4,0,
386,11,473,4,0,
386,11,477,4,0,
386,11,490,4,0,
386,11,496,4,0,
386,11,502,4,0,
386,12,15,4,0,
386,12,35,1,1,2
386,12,43,1,1,1
386,12,58,4,0,
386,12,63,1,50,
386,12,63,4,0,
386,12,70,4,0,
386,12,76,4,0,
386,12,85,4,0,
386,12,87,4,0,
386,12,92,4,0,
386,12,94,1,25,
386,12,94,4,0,
386,12,100,1,10,
386,12,101,1,5,
386,12,104,4,0,
386,12,105,1,40,
386,12,113,4,0,
386,12,115,4,0,
386,12,148,4,0,
386,12,156,4,0,
386,12,182,4,0,
386,12,216,4,0,
386,12,218,4,0,
386,12,219,4,0,
386,12,228,1,20,
386,12,237,4,0,
386,12,240,4,0,
386,12,241,4,0,
386,12,247,4,0,
386,12,249,4,0,
386,12,259,4,0,
386,12,263,4,0,
386,12,264,4,0,
386,12,269,4,0,
386,12,280,4,0,
386,12,282,1,15,
386,12,285,4,0,
386,12,289,1,30,
386,12,289,4,0,
386,12,290,4,0,
386,12,317,4,0,
386,12,322,1,35,
386,12,332,4,0,
386,12,347,4,0,
386,12,351,4,0,
386,12,352,4,0,
386,12,354,1,45,
386,13,15,4,0,
386,13,34,3,0,
386,13,35,1,1,2
386,13,38,3,0,
386,13,43,1,1,1
386,13,58,4,0,
386,13,63,1,50,
386,13,63,4,0,
386,13,69,3,0,
386,13,70,4,0,
386,13,76,4,0,
386,13,85,4,0,
386,13,86,3,0,
386,13,87,4,0,
386,13,92,4,0,
386,13,94,1,25,
386,13,94,4,0,
386,13,100,1,10,
386,13,101,1,5,
386,13,102,3,0,
386,13,104,4,0,
386,13,105,1,40,
386,13,113,4,0,
386,13,115,4,0,
386,13,138,3,0,
386,13,148,4,0,
386,13,156,4,0,
386,13,164,3,0,
386,13,171,3,0,
386,13,182,4,0,
386,13,196,3,0,
386,13,207,3,0,
386,13,216,4,0,
386,13,218,4,0,
386,13,219,4,0,
386,13,228,1,20,
386,13,237,4,0,
386,13,240,4,0,
386,13,241,4,0,
386,13,247,4,0,
386,13,249,4,0,
386,13,259,4,0,
386,13,263,4,0,
386,13,264,4,0,
386,13,269,4,0,
386,13,280,4,0,
386,13,282,1,15,
386,13,285,4,0,
386,13,289,1,30,
386,13,289,4,0,
386,13,290,4,0,
386,13,317,4,0,
386,13,322,1,35,
386,13,332,4,0,
386,13,347,4,0,
386,13,351,4,0,
386,13,352,4,0,
386,13,354,1,45,
386,14,7,3,0,
386,14,8,3,0,
386,14,9,3,0,
386,14,15,4,0,
386,14,20,3,0,
386,14,35,1,1,2
386,14,43,1,1,1
386,14,58,4,0,
386,14,63,1,97,
386,14,63,4,0,
386,14,67,3,0,
386,14,70,4,0,
386,14,76,4,0,
386,14,85,4,0,
386,14,86,4,0,
386,14,87,4,0,
386,14,92,4,0,
386,14,94,1,41,
386,14,94,4,0,
386,14,100,1,17,
386,14,101,1,9,
386,14,104,4,0,
386,14,105,1,81,
386,14,113,4,0,
386,14,115,4,0,
386,14,138,4,0,
386,14,148,4,0,
386,14,156,4,0,
386,14,157,4,0,
386,14,164,4,0,
386,14,173,3,0,
386,14,182,4,0,
386,14,196,3,0,
386,14,207,4,0,
386,14,214,3,0,
386,14,216,4,0,
386,14,218,4,0,
386,14,219,4,0,
386,14,228,1,33,
386,14,237,4,0,
386,14,240,4,0,
386,14,241,4,0,
386,14,244,4,0,
386,14,247,4,0,
386,14,249,4,0,
386,14,259,4,0,
386,14,263,4,0,
386,14,269,4,0,
386,14,271,3,0,
386,14,272,3,0,
386,14,277,3,0,
386,14,278,3,0,
386,14,280,4,0,
386,14,282,1,25,
386,14,282,3,0,
386,14,285,3,0,
386,14,289,1,49,
386,14,289,3,0,
386,14,317,4,0,
386,14,322,1,73,
386,14,324,3,0,
386,14,332,4,0,
386,14,347,4,0,
386,14,354,1,89,
386,14,356,3,0,
386,14,374,4,0,
386,14,375,1,57,
386,14,398,4,0,
386,14,409,3,0,
386,14,411,4,0,
386,14,412,4,0,
386,14,416,4,0,
386,14,428,1,65,
386,14,428,3,0,
386,14,430,4,0,
386,14,433,4,0,
386,14,446,3,0,
386,14,447,4,0,
386,14,451,4,0,
386,14,472,3,0,
386,14,473,4,0,
386,14,477,4,0,
386,14,490,4,0,
386,14,496,4,0,
386,14,502,4,0,
386,15,15,4,0,
386,15,35,1,1,2
386,15,43,1,1,1
386,15,58,4,0,
386,15,63,1,97,
386,15,63,4,0,
386,15,70,4,0,
386,15,76,4,0,
386,15,85,4,0,
386,15,86,4,0,
386,15,87,4,0,
386,15,92,4,0,
386,15,94,1,41,
386,15,94,4,0,
386,15,100,1,17,
386,15,101,1,9,
386,15,104,4,0,
386,15,105,1,81,
386,15,113,4,0,
386,15,115,4,0,
386,15,138,4,0,
386,15,148,4,0,
386,15,156,4,0,
386,15,157,4,0,
386,15,164,4,0,
386,15,182,4,0,
386,15,207,4,0,
386,15,214,4,0,
386,15,216,4,0,
386,15,218,4,0,
386,15,219,4,0,
386,15,228,1,33,
386,15,237,4,0,
386,15,240,4,0,
386,15,241,4,0,
386,15,244,4,0,
386,15,247,4,0,
386,15,249,4,0,
386,15,259,4,0,
386,15,263,4,0,
386,15,269,4,0,
386,15,280,4,0,
386,15,282,1,25,
386,15,289,1,49,
386,15,317,4,0,
386,15,322,1,73,
386,15,332,4,0,
386,15,347,4,0,
386,15,354,1,89,
386,15,374,4,0,
386,15,375,1,57,
386,15,398,4,0,
386,15,399,4,0,
386,15,411,4,0,
386,15,412,4,0,
386,15,416,4,0,
386,15,428,1,65,
386,15,430,4,0,
386,15,433,4,0,
386,15,447,4,0,
386,15,451,4,0,
386,15,473,4,0,
386,15,490,4,0,
386,15,496,4,0,
386,15,590,4,0,
386,15,612,4,0,
386,16,7,3,0,
386,16,8,3,0,
386,16,9,3,0,
386,16,15,4,0,
386,16,20,3,0,
386,16,35,1,1,2
386,16,43,1,1,1
386,16,58,4,0,
386,16,63,1,73,1
386,16,63,4,0,
386,16,67,3,0,
386,16,70,4,0,
386,16,76,4,0,
386,16,85,4,0,
386,16,86,4,0,
386,16,87,4,0,
386,16,92,4,0,
386,16,94,1,31,1
386,16,94,4,0,
386,16,100,1,13,1
386,16,101,1,7,1
386,16,104,4,0,
386,16,105,1,61,1
386,16,113,4,0,
386,16,115,4,0,
386,16,138,4,0,
386,16,148,4,0,
386,16,156,4,0,
386,16,157,4,0,
386,16,164,4,0,
386,16,173,3,0,
386,16,182,4,0,
386,16,196,3,0,
386,16,207,4,0,
386,16,214,4,0,
386,16,216,4,0,
386,16,218,4,0,
386,16,219,4,0,
386,16,228,1,25,1
386,16,237,4,0,
386,16,240,4,0,
386,16,241,4,0,
386,16,244,4,0,
386,16,247,4,0,
386,16,249,4,0,
386,16,259,4,0,
386,16,263,4,0,
386,16,264,3,0,
386,16,269,4,0,
386,16,271,3,0,
386,16,272,3,0,
386,16,277,3,0,
386,16,278,3,0,
386,16,280,4,0,
386,16,282,1,19,1
386,16,282,3,0,
386,16,285,3,0,
386,16,289,1,37,1
386,16,289,3,0,
386,16,290,4,0,
386,16,317,4,0,
386,16,322,1,55,1
386,16,324,3,0,
386,16,332,4,0,
386,16,347,4,0,
386,16,351,3,0,
386,16,352,3,0,
386,16,354,1,67,1
386,16,356,3,0,
386,16,374,4,0,
386,16,375,1,43,1
386,16,398,4,0,
386,16,399,4,0,
386,16,409,3,0,
386,16,411,4,0,
386,16,412,4,0,
386,16,416,4,0,
386,16,428,1,49,1
386,16,428,3,0,
386,16,430,4,0,
386,16,433,4,0,
386,16,446,3,0,
386,16,447,4,0,
386,16,451,4,0,
386,16,472,3,0,
386,16,473,4,0,
386,16,490,4,0,
386,16,496,4,0,
386,16,590,4,0,
386,16,612,4,0,
386,17,35,1,1,2
386,17,43,1,1,1
386,17,58,4,0,
386,17,63,1,73,
386,17,63,4,0,
386,17,76,4,0,
386,17,85,4,0,
386,17,86,4,0,
386,17,87,4,0,
386,17,92,4,0,
386,17,94,1,31,
386,17,94,4,0,
386,17,100,1,13,
386,17,101,1,7,
386,17,104,4,0,
386,17,105,1,61,
386,17,113,4,0,
386,17,115,4,0,
386,17,138,4,0,
386,17,156,4,0,
386,17,157,4,0,
386,17,164,4,0,
386,17,182,4,0,
386,17,207,4,0,
386,17,214,4,0,
386,17,216,4,0,
386,17,218,4,0,
386,17,219,4,0,
386,17,228,1,25,
386,17,237,4,0,
386,17,240,4,0,
386,17,241,4,0,
386,17,244,4,0,
386,17,247,4,0,
386,17,259,4,0,
386,17,263,4,0,
386,17,269,4,0,
386,17,280,4,0,
386,17,282,1,19,
386,17,289,1,37,
386,17,317,4,0,
386,17,322,1,55,
386,17,332,4,0,
386,17,347,4,0,
386,17,354,1,67,
386,17,374,4,0,
386,17,375,1,43,
386,17,398,4,0,
386,17,399,4,0,
386,17,411,4,0,
386,17,412,4,0,
386,17,416,4,0,
386,17,428,1,49,
386,17,430,4,0,
386,17,433,4,0,
386,17,447,4,0,
386,17,451,4,0,
386,17,473,4,0,
386,17,490,4,0,
386,17,496,4,0,
386,17,590,4,0,
386,17,693,4,0,
386,18,35,1,1,2
386,18,43,1,1,1
386,18,58,4,0,
386,18,63,1,73,
386,18,63,4,0,
386,18,76,4,0,
386,18,85,4,0,
386,18,86,4,0,
386,18,87,4,0,
386,18,92,4,0,
386,18,94,1,31,
386,18,94,4,0,
386,18,100,1,13,
386,18,101,1,7,
386,18,104,4,0,
386,18,105,1,61,
386,18,113,4,0,
386,18,115,4,0,
386,18,138,4,0,
386,18,156,4,0,
386,18,157,4,0,
386,18,164,4,0,
386,18,182,4,0,
386,18,207,4,0,
386,18,214,4,0,
386,18,216,4,0,
386,18,218,4,0,
386,18,219,4,0,
386,18,228,1,25,
386,18,237,4,0,
386,18,240,4,0,
386,18,241,4,0,
386,18,244,4,0,
386,18,247,4,0,
386,18,259,4,0,
386,18,263,4,0,
386,18,269,4,0,
386,18,280,4,0,
386,18,282,1,19,
386,18,289,1,37,
386,18,317,4,0,
386,18,322,1,55,
386,18,332,4,0,
386,18,347,4,0,
386,18,354,1,67,
386,18,374,4,0,
386,18,375,1,43,
386,18,398,4,0,
386,18,399,4,0,
386,18,411,4,0,
386,18,412,4,0,
386,18,416,4,0,
386,18,428,1,49,
386,18,430,4,0,
386,18,433,4,0,
386,18,447,4,0,
386,18,451,4,0,
386,18,473,4,0,
386,18,490,4,0,
386,18,496,4,0,
386,18,590,4,0,
386,18,693,4,0,
387,8,14,4,0,
387,8,15,4,0,
387,8,33,1,1,
387,8,34,2,0,
387,8,37,2,0,
387,8,38,2,0,
387,8,44,1,21,
387,8,70,4,0,
387,8,71,1,9,
387,8,72,1,25,
387,8,73,1,29,
387,8,74,2,0,
387,8,75,1,13,
387,8,76,4,0,
387,8,92,4,0,
387,8,104,4,0,
387,8,110,1,5,
387,8,113,4,0,
387,8,115,4,0,
387,8,133,2,0,
387,8,148,4,0,
387,8,156,4,0,
387,8,164,4,0,
387,8,174,1,17,
387,8,182,4,0,
387,8,202,1,41,
387,8,202,4,0,
387,8,203,4,0,
387,8,207,4,0,
387,8,213,4,0,
387,8,214,4,0,
387,8,216,4,0,
387,8,218,4,0,
387,8,219,4,0,
387,8,231,4,0,
387,8,235,1,33,
387,8,237,4,0,
387,8,241,4,0,
387,8,242,1,37,
387,8,249,4,0,
387,8,263,4,0,
387,8,276,2,0,
387,8,290,4,0,
387,8,321,2,0,
387,8,328,2,0,
387,8,331,4,0,
387,8,363,4,0,
387,8,388,2,0,
387,8,402,2,0,
387,8,412,4,0,
387,8,431,4,0,
387,8,437,1,45,
387,8,445,4,0,
387,8,446,4,0,
387,8,447,4,0,
387,9,14,4,0,
387,9,15,4,0,
387,9,33,1,1,
387,9,34,2,0,
387,9,37,2,0,
387,9,38,2,0,
387,9,44,1,21,
387,9,70,4,0,
387,9,71,1,9,
387,9,72,1,25,
387,9,73,1,29,
387,9,74,2,0,
387,9,75,1,13,
387,9,76,4,0,
387,9,92,4,0,
387,9,104,4,0,
387,9,110,1,5,
387,9,113,4,0,
387,9,115,4,0,
387,9,133,2,0,
387,9,148,4,0,
387,9,156,4,0,
387,9,164,4,0,
387,9,173,3,0,
387,9,174,1,17,
387,9,182,4,0,
387,9,189,3,0,
387,9,202,1,41,
387,9,202,4,0,
387,9,203,4,0,
387,9,207,4,0,
387,9,213,4,0,
387,9,214,4,0,
387,9,216,4,0,
387,9,218,4,0,
387,9,219,4,0,
387,9,231,4,0,
387,9,235,1,33,
387,9,235,3,0,
387,9,237,4,0,
387,9,241,4,0,
387,9,242,1,37,
387,9,249,4,0,
387,9,263,4,0,
387,9,276,2,0,
387,9,276,3,0,
387,9,290,4,0,
387,9,321,2,0,
387,9,328,2,0,
387,9,331,4,0,
387,9,363,4,0,
387,9,388,2,0,
387,9,402,2,0,
387,9,402,3,0,
387,9,412,4,0,
387,9,414,3,0,
387,9,431,4,0,
387,9,437,1,45,
387,9,445,4,0,
387,9,446,4,0,
387,9,447,4,0,
387,10,14,4,0,
387,10,15,4,0,
387,10,29,3,0,
387,10,33,1,1,
387,10,34,2,0,
387,10,37,2,0,
387,10,38,2,0,
387,10,44,1,21,
387,10,70,4,0,
387,10,71,1,9,
387,10,72,1,25,
387,10,73,1,29,
387,10,74,2,0,
387,10,75,1,13,
387,10,76,4,0,
387,10,92,4,0,
387,10,104,4,0,
387,10,110,1,5,
387,10,113,4,0,
387,10,115,4,0,
387,10,133,2,0,
387,10,148,4,0,
387,10,156,4,0,
387,10,164,4,0,
387,10,173,3,0,
387,10,174,1,17,
387,10,182,4,0,
387,10,189,3,0,
387,10,202,1,41,
387,10,202,4,0,
387,10,203,4,0,
387,10,207,4,0,
387,10,213,4,0,
387,10,214,4,0,
387,10,216,4,0,
387,10,218,4,0,
387,10,219,4,0,
387,10,231,4,0,
387,10,235,1,33,
387,10,235,3,0,
387,10,237,4,0,
387,10,241,4,0,
387,10,242,1,37,
387,10,249,4,0,
387,10,254,2,0,
387,10,255,2,0,
387,10,256,2,0,
387,10,263,4,0,
387,10,276,2,0,
387,10,276,3,0,
387,10,290,4,0,
387,10,321,2,0,
387,10,328,2,0,
387,10,331,4,0,
387,10,363,4,0,
387,10,388,2,0,
387,10,388,3,0,
387,10,402,2,0,
387,10,402,3,0,
387,10,412,4,0,
387,10,414,3,0,
387,10,431,4,0,
387,10,437,1,45,
387,10,445,4,0,
387,10,446,4,0,
387,10,447,4,0,
387,11,14,4,0,
387,11,15,4,0,
387,11,33,1,1,
387,11,34,2,0,
387,11,37,2,0,
387,11,38,2,0,
387,11,44,1,21,
387,11,70,4,0,
387,11,71,1,9,
387,11,72,1,25,
387,11,73,1,29,
387,11,74,2,0,
387,11,75,1,13,
387,11,76,4,0,
387,11,92,4,0,
387,11,104,4,0,
387,11,110,1,5,
387,11,113,4,0,
387,11,115,4,0,
387,11,133,2,0,
387,11,148,4,0,
387,11,156,4,0,
387,11,164,4,0,
387,11,174,1,17,
387,11,182,4,0,
387,11,202,1,41,
387,11,207,4,0,
387,11,213,4,0,
387,11,216,4,0,
387,11,218,4,0,
387,11,219,4,0,
387,11,235,1,33,
387,11,237,4,0,
387,11,241,4,0,
387,11,242,1,37,
387,11,249,4,0,
387,11,254,2,0,
387,11,255,2,0,
387,11,256,2,0,
387,11,263,4,0,
387,11,276,2,0,
387,11,321,2,0,
387,11,328,2,0,
387,11,388,2,0,
387,11,402,2,0,
387,11,412,4,0,
387,11,414,2,0,
387,11,437,1,45,
387,11,447,4,0,
387,11,469,2,0,
387,11,496,4,0,
387,11,520,3,0,
387,14,14,4,0,
387,14,15,4,0,
387,14,33,1,1,
387,14,34,2,0,
387,14,37,2,0,
387,14,38,2,0,
387,14,44,1,21,
387,14,70,4,0,
387,14,71,1,9,
387,14,72,1,25,
387,14,73,1,29,
387,14,74,2,0,
387,14,75,1,13,
387,14,76,4,0,
387,14,92,4,0,
387,14,104,4,0,
387,14,110,1,5,
387,14,113,4,0,
387,14,115,4,0,
387,14,133,2,0,
387,14,148,4,0,
387,14,156,4,0,
387,14,164,4,0,
387,14,173,3,0,
387,14,174,1,17,
387,14,182,4,0,
387,14,202,1,41,
387,14,202,3,0,
387,14,207,4,0,
387,14,213,4,0,
387,14,214,3,0,
387,14,216,4,0,
387,14,218,4,0,
387,14,219,4,0,
387,14,231,3,0,
387,14,235,1,33,
387,14,235,3,0,
387,14,237,4,0,
387,14,241,4,0,
387,14,242,1,37,
387,14,249,4,0,
387,14,254,2,0,
387,14,255,2,0,
387,14,256,2,0,
387,14,263,4,0,
387,14,276,2,0,
387,14,276,3,0,
387,14,321,2,0,
387,14,328,2,0,
387,14,388,2,0,
387,14,388,3,0,
387,14,402,2,0,
387,14,402,3,0,
387,14,412,4,0,
387,14,414,2,0,
387,14,414,3,0,
387,14,437,1,45,
387,14,446,3,0,
387,14,447,4,0,
387,14,469,2,0,
387,14,496,4,0,
387,14,520,3,0,
387,15,14,4,0,
387,15,15,4,0,
387,15,33,1,1,
387,15,34,2,0,
387,15,37,2,0,
387,15,38,2,0,
387,15,44,1,21,
387,15,70,4,0,
387,15,71,1,9,
387,15,72,1,25,
387,15,73,1,29,
387,15,74,2,0,
387,15,75,1,13,
387,15,76,4,0,
387,15,92,4,0,
387,15,104,4,0,
387,15,110,1,5,
387,15,113,4,0,
387,15,115,4,0,
387,15,133,2,0,
387,15,148,4,0,
387,15,156,4,0,
387,15,164,4,0,
387,15,174,1,17,
387,15,182,4,0,
387,15,202,1,41,
387,15,207,4,0,
387,15,213,4,0,
387,15,214,4,0,
387,15,216,4,0,
387,15,218,4,0,
387,15,219,4,0,
387,15,235,1,33,
387,15,237,4,0,
387,15,241,4,0,
387,15,242,1,37,
387,15,249,4,0,
387,15,254,2,0,
387,15,255,2,0,
387,15,256,2,0,
387,15,263,4,0,
387,15,267,4,0,
387,15,276,2,0,
387,15,321,2,0,
387,15,328,2,0,
387,15,388,2,0,
387,15,402,2,0,
387,15,412,4,0,
387,15,414,2,0,
387,15,437,1,45,
387,15,447,4,0,
387,15,469,2,0,
387,15,496,4,0,
387,15,520,3,0,
387,15,580,2,0,
387,15,590,4,0,
387,16,14,4,0,
387,16,15,4,0,
387,16,33,1,1,1
387,16,34,2,0,
387,16,37,2,0,
387,16,38,2,0,
387,16,44,1,21,1
387,16,70,4,0,
387,16,71,1,9,1
387,16,72,1,25,1
387,16,73,1,29,1
387,16,74,2,0,
387,16,75,1,13,1
387,16,76,4,0,
387,16,92,4,0,
387,16,104,4,0,
387,16,110,1,5,1
387,16,113,4,0,
387,16,115,4,0,
387,16,133,2,0,
387,16,148,4,0,
387,16,156,4,0,
387,16,164,4,0,
387,16,173,3,0,
387,16,174,1,17,1
387,16,182,4,0,
387,16,202,1,41,1
387,16,202,3,0,
387,16,207,4,0,
387,16,213,4,0,
387,16,214,4,0,
387,16,216,4,0,
387,16,218,4,0,
387,16,219,4,0,
387,16,231,3,0,
387,16,235,1,33,1
387,16,235,3,0,
387,16,237,4,0,
387,16,241,4,0,
387,16,242,1,37,1
387,16,249,4,0,
387,16,254,2,0,
387,16,255,2,0,
387,16,256,2,0,
387,16,263,4,0,
387,16,267,4,0,
387,16,276,2,0,
387,16,276,3,0,
387,16,290,4,0,
387,16,321,2,0,
387,16,328,2,0,
387,16,388,2,0,
387,16,388,3,0,
387,16,402,2,0,
387,16,402,3,0,
387,16,412,4,0,
387,16,414,2,0,
387,16,414,3,0,
387,16,437,1,45,1
387,16,446,3,0,
387,16,447,4,0,
387,16,469,2,0,
387,16,496,4,0,
387,16,520,3,0,
387,16,580,2,0,
387,16,590,4,0,
387,17,14,4,0,
387,17,33,1,1,
387,17,34,2,0,
387,17,37,2,0,
387,17,38,2,0,
387,17,44,1,21,
387,17,71,1,9,
387,17,72,1,25,
387,17,73,1,29,
387,17,74,2,0,
387,17,75,1,13,
387,17,76,4,0,
387,17,92,4,0,
387,17,104,4,0,
387,17,110,1,5,
387,17,113,4,0,
387,17,115,4,0,
387,17,133,2,0,
387,17,156,4,0,
387,17,164,4,0,
387,17,174,1,17,
387,17,182,4,0,
387,17,202,1,41,
387,17,207,4,0,
387,17,213,4,0,
387,17,214,4,0,
387,17,216,4,0,
387,17,218,4,0,
387,17,219,4,0,
387,17,235,1,33,
387,17,237,4,0,
387,17,241,4,0,
387,17,242,1,37,
387,17,254,2,0,
387,17,255,2,0,
387,17,256,2,0,
387,17,263,4,0,
387,17,267,4,0,
387,17,276,2,0,
387,17,321,2,0,
387,17,328,2,0,
387,17,388,2,0,
387,17,402,2,0,
387,17,412,4,0,
387,17,414,2,0,
387,17,437,1,45,
387,17,447,4,0,
387,17,469,2,0,
387,17,496,4,0,
387,17,520,3,0,
387,17,526,4,0,
387,17,580,2,0,
387,17,590,4,0,
387,18,14,4,0,
387,18,33,1,1,
387,18,34,2,0,
387,18,37,2,0,
387,18,38,2,0,
387,18,44,1,21,
387,18,71,1,9,
387,18,72,1,25,
387,18,73,1,29,
387,18,74,2,0,
387,18,75,1,13,
387,18,76,4,0,
387,18,92,4,0,
387,18,104,4,0,
387,18,110,1,5,
387,18,113,4,0,
387,18,115,4,0,
387,18,133,2,0,
387,18,156,4,0,
387,18,164,4,0,
387,18,174,1,17,
387,18,182,4,0,
387,18,202,1,41,
387,18,207,4,0,
387,18,213,4,0,
387,18,214,4,0,
387,18,216,4,0,
387,18,218,4,0,
387,18,219,4,0,
387,18,235,1,33,
387,18,237,4,0,
387,18,241,4,0,
387,18,242,1,37,
387,18,254,2,0,
387,18,255,2,0,
387,18,256,2,0,
387,18,263,4,0,
387,18,267,4,0,
387,18,276,2,0,
387,18,321,2,0,
387,18,328,2,0,
387,18,388,2,0,
387,18,402,2,0,
387,18,412,4,0,
387,18,414,2,0,
387,18,437,1,45,
387,18,447,4,0,
387,18,469,2,0,
387,18,484,2,0,
387,18,496,4,0,
387,18,520,3,0,
387,18,526,4,0,
387,18,580,2,0,
387,18,590,4,0,
388,8,14,4,0,
388,8,15,4,0,
388,8,33,1,1,1
388,8,44,1,22,
388,8,70,4,0,
388,8,71,1,9,
388,8,72,1,27,
388,8,73,1,32,
388,8,75,1,13,
388,8,76,4,0,
388,8,92,4,0,
388,8,104,4,0,
388,8,110,1,1,2
388,8,110,1,5,
388,8,113,4,0,
388,8,115,4,0,
388,8,148,4,0,
388,8,156,4,0,
388,8,164,4,0,
388,8,174,1,17,
388,8,182,4,0,
388,8,202,1,47,
388,8,202,4,0,
388,8,203,4,0,
388,8,207,4,0,
388,8,213,4,0,
388,8,214,4,0,
388,8,216,4,0,
388,8,218,4,0,
388,8,219,4,0,
388,8,231,4,0,
388,8,235,1,37,
388,8,237,4,0,
388,8,241,4,0,
388,8,242,1,42,
388,8,249,4,0,
388,8,263,4,0,
388,8,290,4,0,
388,8,331,4,0,
388,8,363,4,0,
388,8,412,4,0,
388,8,431,4,0,
388,8,437,1,52,
388,8,445,4,0,
388,8,446,4,0,
388,8,447,4,0,
388,9,14,4,0,
388,9,15,4,0,
388,9,33,1,1,1
388,9,44,1,22,
388,9,70,4,0,
388,9,71,1,9,
388,9,72,1,27,
388,9,73,1,32,
388,9,75,1,13,
388,9,76,4,0,
388,9,92,4,0,
388,9,104,4,0,
388,9,110,1,1,2
388,9,110,1,5,
388,9,113,4,0,
388,9,115,4,0,
388,9,148,4,0,
388,9,156,4,0,
388,9,164,4,0,
388,9,173,3,0,
388,9,174,1,17,
388,9,182,4,0,
388,9,189,3,0,
388,9,202,1,47,
388,9,202,4,0,
388,9,203,4,0,
388,9,207,4,0,
388,9,213,4,0,
388,9,214,4,0,
388,9,216,4,0,
388,9,218,4,0,
388,9,219,4,0,
388,9,231,4,0,
388,9,235,1,37,
388,9,235,3,0,
388,9,237,4,0,
388,9,241,4,0,
388,9,242,1,42,
388,9,249,4,0,
388,9,263,4,0,
388,9,276,3,0,
388,9,290,4,0,
388,9,331,4,0,
388,9,363,4,0,
388,9,402,3,0,
388,9,412,4,0,
388,9,414,3,0,
388,9,431,4,0,
388,9,437,1,52,
388,9,445,4,0,
388,9,446,4,0,
388,9,447,4,0,
388,10,14,4,0,
388,10,15,4,0,
388,10,29,3,0,
388,10,33,1,1,1
388,10,44,1,22,
388,10,70,4,0,
388,10,71,1,9,
388,10,72,1,27,
388,10,73,1,32,
388,10,75,1,13,
388,10,76,4,0,
388,10,92,4,0,
388,10,104,4,0,
388,10,110,1,1,2
388,10,110,1,5,
388,10,113,4,0,
388,10,115,4,0,
388,10,148,4,0,
388,10,156,4,0,
388,10,164,4,0,
388,10,173,3,0,
388,10,174,1,17,
388,10,182,4,0,
388,10,189,3,0,
388,10,202,1,47,
388,10,202,4,0,
388,10,203,4,0,
388,10,207,4,0,
388,10,213,4,0,
388,10,214,4,0,
388,10,216,4,0,
388,10,218,4,0,
388,10,219,4,0,
388,10,231,4,0,
388,10,235,1,37,
388,10,235,3,0,
388,10,237,4,0,
388,10,241,4,0,
388,10,242,1,42,
388,10,249,4,0,
388,10,263,4,0,
388,10,276,3,0,
388,10,290,4,0,
388,10,331,4,0,
388,10,363,4,0,
388,10,388,3,0,
388,10,402,3,0,
388,10,412,4,0,
388,10,414,3,0,
388,10,431,4,0,
388,10,437,1,52,
388,10,445,4,0,
388,10,446,4,0,
388,10,447,4,0,
388,11,14,4,0,
388,11,15,4,0,
388,11,33,1,1,1
388,11,44,1,22,
388,11,70,4,0,
388,11,71,1,9,
388,11,72,1,27,
388,11,73,1,32,
388,11,75,1,13,
388,11,76,4,0,
388,11,92,4,0,
388,11,104,4,0,
388,11,110,1,1,2
388,11,110,1,5,
388,11,113,4,0,
388,11,115,4,0,
388,11,148,4,0,
388,11,156,4,0,
388,11,164,4,0,
388,11,174,1,17,
388,11,182,4,0,
388,11,202,1,47,
388,11,207,4,0,
388,11,213,4,0,
388,11,216,4,0,
388,11,218,4,0,
388,11,219,4,0,
388,11,235,1,37,
388,11,237,4,0,
388,11,241,4,0,
388,11,242,1,42,
388,11,249,4,0,
388,11,263,4,0,
388,11,412,4,0,
388,11,437,1,52,
388,11,447,4,0,
388,11,496,4,0,
388,11,520,3,0,
388,14,14,4,0,
388,14,15,4,0,
388,14,33,1,1,1
388,14,44,1,22,
388,14,70,4,0,
388,14,71,1,9,
388,14,72,1,27,
388,14,73,1,32,
388,14,75,1,13,
388,14,76,4,0,
388,14,92,4,0,
388,14,104,4,0,
388,14,110,1,1,2
388,14,110,1,5,
388,14,113,4,0,
388,14,115,4,0,
388,14,148,4,0,
388,14,156,4,0,
388,14,164,4,0,
388,14,173,3,0,
388,14,174,1,17,
388,14,182,4,0,
388,14,202,1,47,
388,14,202,3,0,
388,14,207,4,0,
388,14,213,4,0,
388,14,214,3,0,
388,14,216,4,0,
388,14,218,4,0,
388,14,219,4,0,
388,14,231,3,0,
388,14,235,1,37,
388,14,235,3,0,
388,14,237,4,0,
388,14,241,4,0,
388,14,242,1,42,
388,14,249,4,0,
388,14,263,4,0,
388,14,276,3,0,
388,14,388,3,0,
388,14,402,3,0,
388,14,412,4,0,
388,14,414,3,0,
388,14,437,1,52,
388,14,446,3,0,
388,14,447,4,0,
388,14,496,4,0,
388,14,520,3,0,
388,15,14,4,0,
388,15,15,4,0,
388,15,33,1,1,1
388,15,44,1,22,
388,15,70,4,0,
388,15,71,1,9,
388,15,72,1,27,
388,15,73,1,32,
388,15,75,1,13,
388,15,76,4,0,
388,15,92,4,0,
388,15,104,4,0,
388,15,110,1,1,2
388,15,110,1,5,
388,15,113,4,0,
388,15,115,4,0,
388,15,148,4,0,
388,15,156,4,0,
388,15,164,4,0,
388,15,174,1,17,
388,15,182,4,0,
388,15,202,1,47,
388,15,207,4,0,
388,15,213,4,0,
388,15,214,4,0,
388,15,216,4,0,
388,15,218,4,0,
388,15,219,4,0,
388,15,235,1,37,
388,15,237,4,0,
388,15,241,4,0,
388,15,242,1,42,
388,15,249,4,0,
388,15,263,4,0,
388,15,267,4,0,
388,15,412,4,0,
388,15,437,1,52,
388,15,447,4,0,
388,15,496,4,0,
388,15,520,3,0,
388,15,590,4,0,
388,16,14,4,0,
388,16,15,4,0,
388,16,33,1,1,1
388,16,44,1,22,1
388,16,70,4,0,
388,16,71,1,9,1
388,16,72,1,27,1
388,16,73,1,32,1
388,16,75,1,13,1
388,16,76,4,0,
388,16,92,4,0,
388,16,104,4,0,
388,16,110,1,1,2
388,16,110,1,5,1
388,16,113,4,0,
388,16,115,4,0,
388,16,148,4,0,
388,16,156,4,0,
388,16,164,4,0,
388,16,173,3,0,
388,16,174,1,17,1
388,16,182,4,0,
388,16,202,1,47,1
388,16,202,3,0,
388,16,207,4,0,
388,16,213,4,0,
388,16,214,4,0,
388,16,216,4,0,
388,16,218,4,0,
388,16,219,4,0,
388,16,231,3,0,
388,16,235,1,37,1
388,16,235,3,0,
388,16,237,4,0,
388,16,241,4,0,
388,16,242,1,42,1
388,16,249,4,0,
388,16,263,4,0,
388,16,267,4,0,
388,16,276,3,0,
388,16,290,4,0,
388,16,388,3,0,
388,16,402,3,0,
388,16,412,4,0,
388,16,414,3,0,
388,16,437,1,52,1
388,16,446,3,0,
388,16,447,4,0,
388,16,496,4,0,
388,16,520,3,0,
388,16,590,4,0,
388,17,14,4,0,
388,17,33,1,1,1
388,17,44,1,22,
388,17,71,1,1,3
388,17,71,1,9,
388,17,72,1,27,
388,17,73,1,32,
388,17,75,1,13,
388,17,76,4,0,
388,17,92,4,0,
388,17,104,4,0,
388,17,110,1,1,2
388,17,110,1,5,
388,17,113,4,0,
388,17,115,4,0,
388,17,156,4,0,
388,17,164,4,0,
388,17,174,1,17,
388,17,182,4,0,
388,17,202,1,47,
388,17,207,4,0,
388,17,213,4,0,
388,17,214,4,0,
388,17,216,4,0,
388,17,218,4,0,
388,17,219,4,0,
388,17,235,1,37,
388,17,237,4,0,
388,17,241,4,0,
388,17,242,1,42,
388,17,263,4,0,
388,17,267,4,0,
388,17,412,4,0,
388,17,437,1,52,
388,17,447,4,0,
388,17,496,4,0,
388,17,520,3,0,
388,17,526,4,0,
388,17,590,4,0,
388,18,14,4,0,
388,18,33,1,1,1
388,18,44,1,22,
388,18,71,1,1,3
388,18,71,1,9,
388,18,72,1,27,
388,18,73,1,32,
388,18,75,1,13,
388,18,76,4,0,
388,18,92,4,0,
388,18,104,4,0,
388,18,110,1,1,2
388,18,110,1,5,
388,18,113,4,0,
388,18,115,4,0,
388,18,156,4,0,
388,18,164,4,0,
388,18,174,1,17,
388,18,182,4,0,
388,18,202,1,47,
388,18,207,4,0,
388,18,213,4,0,
388,18,214,4,0,
388,18,216,4,0,
388,18,218,4,0,
388,18,219,4,0,
388,18,235,1,37,
388,18,237,4,0,
388,18,241,4,0,
388,18,242,1,42,
388,18,263,4,0,
388,18,267,4,0,
388,18,412,4,0,
388,18,437,1,52,
388,18,447,4,0,
388,18,496,4,0,
388,18,520,3,0,
388,18,526,4,0,
388,18,590,4,0,
389,8,14,4,0,
389,8,15,4,0,
389,8,33,1,1,2
389,8,44,1,22,
389,8,46,4,0,
389,8,63,4,0,
389,8,70,4,0,
389,8,71,1,1,4
389,8,71,1,9,
389,8,72,1,27,
389,8,73,1,33,
389,8,75,1,1,5
389,8,75,1,13,
389,8,76,4,0,
389,8,89,1,32,
389,8,89,4,0,
389,8,92,4,0,
389,8,104,4,0,
389,8,110,1,1,3
389,8,110,1,5,
389,8,113,4,0,
389,8,115,4,0,
389,8,148,4,0,
389,8,156,4,0,
389,8,157,4,0,
389,8,164,4,0,
389,8,174,1,17,
389,8,182,4,0,
389,8,201,4,0,
389,8,202,1,51,
389,8,202,4,0,
389,8,203,4,0,
389,8,207,4,0,
389,8,213,4,0,
389,8,214,4,0,
389,8,216,4,0,
389,8,218,4,0,
389,8,219,4,0,
389,8,231,4,0,
389,8,235,1,39,
389,8,237,4,0,
389,8,241,4,0,
389,8,242,1,45,
389,8,249,4,0,
389,8,263,4,0,
389,8,290,4,0,
389,8,317,4,0,
389,8,331,4,0,
389,8,338,3,0,
389,8,363,4,0,
389,8,397,4,0,
389,8,412,4,0,
389,8,416,4,0,
389,8,431,4,0,
389,8,437,1,57,
389,8,444,4,0,
389,8,445,4,0,
389,8,446,4,0,
389,8,447,4,0,
389,8,452,1,1,1
389,9,14,4,0,
389,9,15,4,0,
389,9,33,1,1,2
389,9,44,1,22,
389,9,46,4,0,
389,9,63,4,0,
389,9,70,4,0,
389,9,71,1,1,4
389,9,71,1,9,
389,9,72,1,27,
389,9,73,1,33,
389,9,75,1,1,5
389,9,75,1,13,
389,9,76,4,0,
389,9,89,1,32,
389,9,89,4,0,
389,9,92,4,0,
389,9,104,4,0,
389,9,110,1,1,3
389,9,110,1,5,
389,9,113,4,0,
389,9,115,4,0,
389,9,148,4,0,
389,9,156,4,0,
389,9,157,4,0,
389,9,164,4,0,
389,9,173,3,0,
389,9,174,1,17,
389,9,182,4,0,
389,9,189,3,0,
389,9,200,3,0,
389,9,201,4,0,
389,9,202,1,51,
389,9,202,4,0,
389,9,203,4,0,
389,9,207,4,0,
389,9,213,4,0,
389,9,214,4,0,
389,9,216,4,0,
389,9,218,4,0,
389,9,219,4,0,
389,9,231,4,0,
389,9,235,1,39,
389,9,235,3,0,
389,9,237,4,0,
389,9,241,4,0,
389,9,242,1,45,
389,9,249,4,0,
389,9,263,4,0,
389,9,276,3,0,
389,9,290,4,0,
389,9,317,4,0,
389,9,331,4,0,
389,9,338,3,0,
389,9,363,4,0,
389,9,397,4,0,
389,9,402,3,0,
389,9,412,4,0,
389,9,414,3,0,
389,9,416,4,0,
389,9,431,4,0,
389,9,437,1,57,
389,9,442,3,0,
389,9,444,4,0,
389,9,445,4,0,
389,9,446,4,0,
389,9,447,4,0,
389,9,452,1,1,1
389,10,14,4,0,
389,10,15,4,0,
389,10,29,3,0,
389,10,33,1,1,2
389,10,44,1,22,
389,10,46,4,0,
389,10,63,4,0,
389,10,70,4,0,
389,10,71,1,1,4
389,10,71,1,9,
389,10,72,1,27,
389,10,73,1,33,
389,10,75,1,1,5
389,10,75,1,13,
389,10,76,4,0,
389,10,89,1,32,
389,10,89,4,0,
389,10,92,4,0,
389,10,104,4,0,
389,10,110,1,1,3
389,10,110,1,5,
389,10,113,4,0,
389,10,115,4,0,
389,10,148,4,0,
389,10,156,4,0,
389,10,157,4,0,
389,10,164,4,0,
389,10,173,3,0,
389,10,174,1,17,
389,10,182,4,0,
389,10,189,3,0,
389,10,200,3,0,
389,10,201,4,0,
389,10,202,1,51,
389,10,202,4,0,
389,10,203,4,0,
389,10,207,4,0,
389,10,213,4,0,
389,10,214,4,0,
389,10,216,4,0,
389,10,218,4,0,
389,10,219,4,0,
389,10,231,4,0,
389,10,235,1,39,
389,10,235,3,0,
389,10,237,4,0,
389,10,241,4,0,
389,10,242,1,45,
389,10,249,4,0,
389,10,263,4,0,
389,10,276,3,0,
389,10,290,4,0,
389,10,317,4,0,
389,10,331,4,0,
389,10,335,3,0,
389,10,338,3,0,
389,10,363,4,0,
389,10,388,3,0,
389,10,397,4,0,
389,10,402,3,0,
389,10,412,4,0,
389,10,414,3,0,
389,10,416,4,0,
389,10,431,4,0,
389,10,437,1,57,
389,10,442,3,0,
389,10,444,4,0,
389,10,445,4,0,
389,10,446,4,0,
389,10,447,4,0,
389,10,452,1,1,1
389,11,14,4,0,
389,11,15,4,0,
389,11,33,1,1,2
389,11,44,1,22,
389,11,46,4,0,
389,11,63,4,0,
389,11,70,4,0,
389,11,71,1,1,4
389,11,71,1,9,
389,11,72,1,27,
389,11,73,1,33,
389,11,75,1,1,5
389,11,75,1,13,
389,11,76,4,0,
389,11,89,1,32,
389,11,89,4,0,
389,11,92,4,0,
389,11,104,4,0,
389,11,110,1,1,3
389,11,110,1,5,
389,11,113,4,0,
389,11,115,4,0,
389,11,148,4,0,
389,11,156,4,0,
389,11,157,4,0,
389,11,164,4,0,
389,11,174,1,17,
389,11,182,4,0,
389,11,201,4,0,
389,11,202,1,51,
389,11,207,4,0,
389,11,213,4,0,
389,11,216,4,0,
389,11,218,4,0,
389,11,219,4,0,
389,11,235,1,39,
389,11,237,4,0,
389,11,241,4,0,
389,11,242,1,45,
389,11,249,4,0,
389,11,263,4,0,
389,11,317,4,0,
389,11,338,3,0,
389,11,397,4,0,
389,11,412,4,0,
389,11,416,4,0,
389,11,437,1,57,
389,11,444,4,0,
389,11,447,4,0,
389,11,452,1,1,1
389,11,496,4,0,
389,11,520,3,0,
389,11,523,4,0,
389,14,14,4,0,
389,14,15,4,0,
389,14,33,1,1,2
389,14,44,1,22,
389,14,46,4,0,
389,14,63,4,0,
389,14,70,4,0,
389,14,71,1,1,4
389,14,71,1,9,
389,14,72,1,27,
389,14,73,1,33,
389,14,75,1,1,5
389,14,75,1,13,
389,14,76,4,0,
389,14,89,1,32,
389,14,89,4,0,
389,14,92,4,0,
389,14,104,4,0,
389,14,110,1,1,3
389,14,110,1,5,
389,14,113,4,0,
389,14,115,4,0,
389,14,148,4,0,
389,14,156,4,0,
389,14,157,4,0,
389,14,164,4,0,
389,14,173,3,0,
389,14,174,1,17,
389,14,182,4,0,
389,14,200,3,0,
389,14,201,4,0,
389,14,202,1,51,
389,14,202,3,0,
389,14,207,4,0,
389,14,213,4,0,
389,14,214,3,0,
389,14,216,4,0,
389,14,218,4,0,
389,14,219,4,0,
389,14,231,3,0,
389,14,235,1,39,
389,14,235,3,0,
389,14,237,4,0,
389,14,241,4,0,
389,14,242,1,45,
389,14,249,4,0,
389,14,263,4,0,
389,14,276,3,0,
389,14,317,4,0,
389,14,335,3,0,
389,14,338,3,0,
389,14,388,3,0,
389,14,397,4,0,
389,14,402,3,0,
389,14,412,4,0,
389,14,414,3,0,
389,14,416,4,0,
389,14,437,1,57,
389,14,442,3,0,
389,14,444,4,0,
389,14,446,3,0,
389,14,447,4,0,
389,14,452,1,1,1
389,14,496,4,0,
389,14,520,3,0,
389,14,523,4,0,
389,15,14,4,0,
389,15,15,4,0,
389,15,33,1,1,2
389,15,44,1,22,
389,15,46,4,0,
389,15,63,4,0,
389,15,70,4,0,
389,15,71,1,1,4
389,15,71,1,9,
389,15,72,1,27,
389,15,73,1,33,
389,15,75,1,1,5
389,15,75,1,13,
389,15,76,4,0,
389,15,89,1,32,
389,15,89,4,0,
389,15,92,4,0,
389,15,104,4,0,
389,15,110,1,1,3
389,15,110,1,5,
389,15,113,4,0,
389,15,115,4,0,
389,15,148,4,0,
389,15,156,4,0,
389,15,157,4,0,
389,15,164,4,0,
389,15,174,1,17,
389,15,182,4,0,
389,15,201,4,0,
389,15,202,1,51,
389,15,207,4,0,
389,15,213,4,0,
389,15,214,4,0,
389,15,216,4,0,
389,15,218,4,0,
389,15,219,4,0,
389,15,235,1,39,
389,15,237,4,0,
389,15,241,4,0,
389,15,242,1,45,
389,15,249,4,0,
389,15,263,4,0,
389,15,267,4,0,
389,15,317,4,0,
389,15,338,3,0,
389,15,397,4,0,
389,15,412,4,0,
389,15,416,4,0,
389,15,437,1,57,
389,15,444,4,0,
389,15,447,4,0,
389,15,452,1,1,1
389,15,496,4,0,
389,15,520,3,0,
389,15,523,4,0,
389,15,590,4,0,
389,16,14,4,0,
389,16,15,4,0,
389,16,33,1,1,2
389,16,44,1,22,1
389,16,46,4,0,
389,16,63,4,0,
389,16,70,4,0,
389,16,71,1,1,4
389,16,71,1,9,1
389,16,72,1,27,1
389,16,73,1,33,1
389,16,75,1,1,5
389,16,75,1,13,1
389,16,76,4,0,
389,16,89,1,32,1
389,16,89,4,0,
389,16,92,4,0,
389,16,104,4,0,
389,16,110,1,1,3
389,16,110,1,5,1
389,16,113,4,0,
389,16,115,4,0,
389,16,148,4,0,
389,16,156,4,0,
389,16,157,4,0,
389,16,164,4,0,
389,16,173,3,0,
389,16,174,1,17,1
389,16,182,4,0,
389,16,200,3,0,
389,16,201,4,0,
389,16,202,1,51,1
389,16,202,3,0,
389,16,207,4,0,
389,16,213,4,0,
389,16,214,4,0,
389,16,216,4,0,
389,16,218,4,0,
389,16,219,4,0,
389,16,231,3,0,
389,16,235,1,39,1
389,16,235,3,0,
389,16,237,4,0,
389,16,241,4,0,
389,16,242,1,45,1
389,16,249,4,0,
389,16,263,4,0,
389,16,267,4,0,
389,16,276,3,0,
389,16,290,4,0,
389,16,317,4,0,
389,16,335,3,0,
389,16,338,3,0,
389,16,388,3,0,
389,16,397,4,0,
389,16,402,3,0,
389,16,412,4,0,
389,16,414,3,0,
389,16,416,4,0,
389,16,437,1,57,1
389,16,442,3,0,
389,16,444,4,0,
389,16,446,3,0,
389,16,447,4,0,
389,16,452,1,1,1
389,16,496,4,0,
389,16,520,3,0,
389,16,523,4,0,
389,16,590,4,0,
389,17,14,4,0,
389,17,33,1,1,3
389,17,44,1,22,
389,17,46,4,0,
389,17,63,4,0,
389,17,71,1,1,5
389,17,71,1,9,
389,17,72,1,27,
389,17,73,1,33,
389,17,75,1,1,6
389,17,75,1,13,
389,17,76,4,0,
389,17,89,1,0,
389,17,89,1,1,1
389,17,89,4,0,
389,17,92,4,0,
389,17,104,4,0,
389,17,110,1,1,4
389,17,110,1,5,
389,17,113,4,0,
389,17,115,4,0,
389,17,156,4,0,
389,17,157,4,0,
389,17,164,4,0,
389,17,174,1,17,
389,17,182,4,0,
389,17,201,4,0,
389,17,202,1,51,
389,17,207,4,0,
389,17,213,4,0,
389,17,214,4,0,
389,17,216,4,0,
389,17,218,4,0,
389,17,219,4,0,
389,17,235,1,39,
389,17,237,4,0,
389,17,241,4,0,
389,17,242,1,45,
389,17,263,4,0,
389,17,267,4,0,
389,17,317,4,0,
389,17,338,3,0,
389,17,397,4,0,
389,17,412,4,0,
389,17,416,4,0,
389,17,437,1,57,
389,17,444,4,0,
389,17,447,4,0,
389,17,452,1,1,2
389,17,496,4,0,
389,17,520,3,0,
389,17,523,4,0,
389,17,526,4,0,
389,17,590,4,0,
389,18,14,4,0,
389,18,33,1,1,3
389,18,44,1,22,
389,18,46,4,0,
389,18,63,4,0,
389,18,71,1,1,5
389,18,71,1,9,
389,18,72,1,27,
389,18,73,1,33,
389,18,75,1,1,6
389,18,75,1,13,
389,18,76,4,0,
389,18,89,1,0,
389,18,89,1,1,1
389,18,89,4,0,
389,18,92,4,0,
389,18,104,4,0,
389,18,110,1,1,4
389,18,110,1,5,
389,18,113,4,0,
389,18,115,4,0,
389,18,156,4,0,
389,18,157,4,0,
389,18,164,4,0,
389,18,174,1,17,
389,18,182,4,0,
389,18,201,4,0,
389,18,202,1,51,
389,18,207,4,0,
389,18,213,4,0,
389,18,214,4,0,
389,18,216,4,0,
389,18,218,4,0,
389,18,219,4,0,
389,18,235,1,39,
389,18,237,4,0,
389,18,241,4,0,
389,18,242,1,45,
389,18,263,4,0,
389,18,267,4,0,
389,18,317,4,0,
389,18,338,3,0,
389,18,397,4,0,
389,18,412,4,0,
389,18,416,4,0,
389,18,437,1,57,
389,18,444,4,0,
389,18,447,4,0,
389,18,452,1,1,2
389,18,496,4,0,
389,18,520,3,0,
389,18,523,4,0,
389,18,526,4,0,
389,18,590,4,0,
390,8,7,2,0,
390,8,9,2,0,
390,8,10,1,1,1
390,8,14,4,0,
390,8,15,4,0,
390,8,24,2,0,
390,8,43,1,1,2
390,8,52,1,7,
390,8,53,1,41,
390,8,53,4,0,
390,8,68,2,0,
390,8,70,4,0,
390,8,83,1,33,
390,8,91,4,0,
390,8,92,4,0,
390,8,104,4,0,
390,8,116,2,0,
390,8,126,4,0,
390,8,154,1,15,
390,8,156,4,0,
390,8,164,4,0,
390,8,172,1,17,
390,8,182,4,0,
390,8,203,4,0,
390,8,207,4,0,
390,8,213,4,0,
390,8,214,4,0,
390,8,216,4,0,
390,8,218,4,0,
390,8,227,2,0,
390,8,231,4,0,
390,8,237,4,0,
390,8,241,4,0,
390,8,249,4,0,
390,8,252,2,0,
390,8,257,2,0,
390,8,259,1,25,
390,8,259,4,0,
390,8,261,4,0,
390,8,263,1,31,
390,8,263,4,0,
390,8,264,4,0,
390,8,269,1,9,
390,8,269,4,0,
390,8,270,2,0,
390,8,280,4,0,
390,8,290,4,0,
390,8,299,2,0,
390,8,303,1,39,
390,8,315,4,0,
390,8,332,4,0,
390,8,339,4,0,
390,8,363,4,0,
390,8,369,4,0,
390,8,374,4,0,
390,8,417,1,23,
390,8,421,4,0,
390,8,431,4,0,
390,8,445,4,0,
390,8,446,4,0,
390,8,447,4,0,
390,9,7,2,0,
390,9,7,3,0,
390,9,9,2,0,
390,9,9,3,0,
390,9,10,1,1,1
390,9,14,4,0,
390,9,15,4,0,
390,9,24,2,0,
390,9,43,1,1,2
390,9,52,1,7,
390,9,53,1,41,
390,9,53,4,0,
390,9,68,2,0,
390,9,70,4,0,
390,9,83,1,33,
390,9,91,4,0,
390,9,92,4,0,
390,9,104,4,0,
390,9,116,2,0,
390,9,126,4,0,
390,9,129,3,0,
390,9,154,1,15,
390,9,156,4,0,
390,9,164,4,0,
390,9,172,1,17,
390,9,173,3,0,
390,9,182,4,0,
390,9,189,3,0,
390,9,203,4,0,
390,9,205,3,0,
390,9,207,4,0,
390,9,213,4,0,
390,9,214,4,0,
390,9,216,4,0,
390,9,218,4,0,
390,9,227,2,0,
390,9,231,4,0,
390,9,237,4,0,
390,9,241,4,0,
390,9,249,4,0,
390,9,252,2,0,
390,9,253,3,0,
390,9,257,2,0,
390,9,257,3,0,
390,9,259,1,25,
390,9,259,4,0,
390,9,261,4,0,
390,9,263,1,31,
390,9,263,4,0,
390,9,264,4,0,
390,9,269,1,9,
390,9,269,4,0,
390,9,270,2,0,
390,9,270,3,0,
390,9,280,4,0,
390,9,283,3,0,
390,9,290,4,0,
390,9,299,2,0,
390,9,303,1,39,
390,9,315,4,0,
390,9,332,4,0,
390,9,339,4,0,
390,9,363,4,0,
390,9,369,4,0,
390,9,374,4,0,
390,9,410,3,0,
390,9,417,1,23,
390,9,421,4,0,
390,9,431,4,0,
390,9,441,3,0,
390,9,445,4,0,
390,9,446,4,0,
390,9,447,4,0,
390,10,7,2,0,
390,10,7,3,0,
390,10,9,2,0,
390,10,9,3,0,
390,10,10,1,1,1
390,10,14,4,0,
390,10,15,4,0,
390,10,24,2,0,
390,10,29,3,0,
390,10,43,1,1,2
390,10,52,1,7,
390,10,53,1,41,
390,10,53,4,0,
390,10,67,3,0,
390,10,68,2,0,
390,10,70,4,0,
390,10,83,1,33,
390,10,91,4,0,
390,10,92,4,0,
390,10,104,4,0,
390,10,116,2,0,
390,10,126,4,0,
390,10,129,3,0,
390,10,154,1,15,
390,10,156,4,0,
390,10,164,4,0,
390,10,172,1,17,
390,10,173,3,0,
390,10,182,4,0,
390,10,189,3,0,
390,10,203,4,0,
390,10,205,3,0,
390,10,207,4,0,
390,10,213,4,0,
390,10,214,4,0,
390,10,216,4,0,
390,10,218,4,0,
390,10,227,2,0,
390,10,231,4,0,
390,10,237,4,0,
390,10,241,4,0,
390,10,249,4,0,
390,10,252,2,0,
390,10,253,3,0,
390,10,257,2,0,
390,10,257,3,0,
390,10,259,1,25,
390,10,259,4,0,
390,10,261,4,0,
390,10,263,1,31,
390,10,263,4,0,
390,10,264,4,0,
390,10,269,1,9,
390,10,269,4,0,
390,10,270,2,0,
390,10,270,3,0,
390,10,272,3,0,
390,10,274,2,0,
390,10,280,4,0,
390,10,283,3,0,
390,10,290,4,0,
390,10,299,2,0,
390,10,303,1,39,
390,10,315,4,0,
390,10,332,4,0,
390,10,339,4,0,
390,10,363,4,0,
390,10,369,4,0,
390,10,374,4,0,
390,10,410,3,0,
390,10,417,1,23,
390,10,421,4,0,
390,10,431,4,0,
390,10,441,3,0,
390,10,445,4,0,
390,10,446,4,0,
390,10,447,4,0,
390,11,7,2,0,
390,11,9,2,0,
390,11,10,1,1,1
390,11,14,4,0,
390,11,15,4,0,
390,11,24,2,0,
390,11,43,1,1,2
390,11,52,1,7,
390,11,53,1,47,
390,11,53,4,0,
390,11,66,2,0,
390,11,68,2,0,
390,11,70,4,0,
390,11,83,1,33,
390,11,91,4,0,
390,11,92,4,0,
390,11,104,4,0,
390,11,116,2,0,
390,11,126,4,0,
390,11,154,1,15,
390,11,156,4,0,
390,11,164,4,0,
390,11,172,1,17,
390,11,182,4,0,
390,11,207,4,0,
390,11,213,4,0,
390,11,216,4,0,
390,11,218,4,0,
390,11,227,2,0,
390,11,237,4,0,
390,11,241,4,0,
390,11,249,4,0,
390,11,252,2,0,
390,11,257,2,0,
390,11,259,1,25,
390,11,259,4,0,
390,11,261,4,0,
390,11,263,1,31,
390,11,263,4,0,
390,11,264,2,0,
390,11,269,1,9,
390,11,269,4,0,
390,11,270,2,0,
390,11,274,2,0,
390,11,280,4,0,
390,11,299,2,0,
390,11,303,1,41,
390,11,315,4,0,
390,11,332,4,0,
390,11,339,4,0,
390,11,369,4,0,
390,11,374,4,0,
390,11,417,1,23,
390,11,421,4,0,
390,11,447,4,0,
390,11,468,4,0,
390,11,488,4,0,
390,11,490,4,0,
390,11,496,4,0,
390,11,501,2,0,
390,11,510,4,0,
390,11,512,1,39,
390,11,512,4,0,
390,11,519,3,0,
390,14,7,2,0,
390,14,7,3,0,
390,14,9,2,0,
390,14,9,3,0,
390,14,10,1,1,1
390,14,14,4,0,
390,14,15,4,0,
390,14,24,2,0,
390,14,43,1,1,2
390,14,52,1,7,
390,14,53,1,47,
390,14,53,4,0,
390,14,66,2,0,
390,14,67,3,0,
390,14,68,2,0,
390,14,70,4,0,
390,14,83,1,33,
390,14,91,4,0,
390,14,92,4,0,
390,14,104,4,0,
390,14,116,2,0,
390,14,126,4,0,
390,14,154,1,15,
390,14,156,4,0,
390,14,164,4,0,
390,14,172,1,17,
390,14,173,3,0,
390,14,182,4,0,
390,14,207,4,0,
390,14,213,4,0,
390,14,214,3,0,
390,14,216,4,0,
390,14,218,4,0,
390,14,227,2,0,
390,14,231,3,0,
390,14,237,4,0,
390,14,241,4,0,
390,14,249,4,0,
390,14,252,2,0,
390,14,253,3,0,
390,14,257,2,0,
390,14,257,3,0,
390,14,259,1,25,
390,14,259,4,0,
390,14,261,4,0,
390,14,263,1,31,
390,14,263,4,0,
390,14,264,2,0,
390,14,269,1,9,
390,14,269,4,0,
390,14,270,2,0,
390,14,270,3,0,
390,14,272,3,0,
390,14,274,2,0,
390,14,280,4,0,
390,14,283,3,0,
390,14,299,2,0,
390,14,303,1,41,
390,14,315,4,0,
390,14,332,4,0,
390,14,339,4,0,
390,14,343,3,0,
390,14,369,4,0,
390,14,374,4,0,
390,14,417,1,23,
390,14,421,4,0,
390,14,441,3,0,
390,14,446,3,0,
390,14,447,4,0,
390,14,468,4,0,
390,14,488,4,0,
390,14,490,4,0,
390,14,496,4,0,
390,14,501,2,0,
390,14,510,4,0,
390,14,512,1,39,
390,14,512,4,0,
390,14,519,3,0,
390,15,7,2,0,
390,15,9,2,0,
390,15,10,1,1,1
390,15,14,4,0,
390,15,15,4,0,
390,15,24,2,0,
390,15,43,1,1,2
390,15,52,1,7,
390,15,53,1,47,
390,15,53,4,0,
390,15,66,2,0,
390,15,68,2,0,
390,15,70,4,0,
390,15,83,1,33,
390,15,91,4,0,
390,15,92,4,0,
390,15,104,4,0,
390,15,116,2,0,
390,15,126,4,0,
390,15,154,1,15,
390,15,156,4,0,
390,15,164,4,0,
390,15,172,1,17,
390,15,182,4,0,
390,15,207,4,0,
390,15,213,4,0,
390,15,214,4,0,
390,15,216,4,0,
390,15,218,4,0,
390,15,227,2,0,
390,15,237,4,0,
390,15,241,4,0,
390,15,249,4,0,
390,15,252,2,0,
390,15,257,2,0,
390,15,259,1,25,
390,15,259,4,0,
390,15,261,4,0,
390,15,263,1,31,
390,15,263,4,0,
390,15,264,2,0,
390,15,269,1,9,
390,15,269,4,0,
390,15,270,2,0,
390,15,274,2,0,
390,15,280,4,0,
390,15,299,2,0,
390,15,303,1,41,
390,15,315,4,0,
390,15,332,4,0,
390,15,339,4,0,
390,15,369,4,0,
390,15,374,4,0,
390,15,417,1,23,
390,15,421,4,0,
390,15,447,4,0,
390,15,468,4,0,
390,15,488,4,0,
390,15,490,4,0,
390,15,496,4,0,
390,15,501,2,0,
390,15,510,4,0,
390,15,512,1,39,
390,15,512,4,0,
390,15,519,3,0,
390,15,590,4,0,
390,15,612,4,0,
390,16,7,2,0,
390,16,7,3,0,
390,16,9,2,0,
390,16,9,3,0,
390,16,10,1,1,1
390,16,14,4,0,
390,16,15,4,0,
390,16,24,2,0,
390,16,43,1,1,2
390,16,52,1,7,1
390,16,53,1,47,1
390,16,53,4,0,
390,16,66,2,0,
390,16,67,3,0,
390,16,68,2,0,
390,16,70,4,0,
390,16,83,1,33,1
390,16,91,4,0,
390,16,92,4,0,
390,16,104,4,0,
390,16,116,2,0,
390,16,126,4,0,
390,16,154,1,15,1
390,16,156,4,0,
390,16,164,4,0,
390,16,172,1,17,1
390,16,173,3,0,
390,16,182,4,0,
390,16,207,4,0,
390,16,213,4,0,
390,16,214,4,0,
390,16,216,4,0,
390,16,218,4,0,
390,16,227,2,0,
390,16,231,3,0,
390,16,237,4,0,
390,16,241,4,0,
390,16,249,4,0,
390,16,252,2,0,
390,16,253,3,0,
390,16,257,2,0,
390,16,257,3,0,
390,16,259,1,25,1
390,16,259,4,0,
390,16,261,4,0,
390,16,263,1,31,1
390,16,263,4,0,
390,16,264,2,0,
390,16,264,3,0,
390,16,269,1,9,1
390,16,269,4,0,
390,16,270,2,0,
390,16,270,3,0,
390,16,272,3,0,
390,16,274,2,0,
390,16,280,4,0,
390,16,283,3,0,
390,16,290,4,0,
390,16,299,2,0,
390,16,303,1,41,1
390,16,315,4,0,
390,16,332,4,0,
390,16,339,4,0,
390,16,343,3,0,
390,16,369,4,0,
390,16,374,4,0,
390,16,417,1,23,1
390,16,421,4,0,
390,16,441,3,0,
390,16,446,3,0,
390,16,447,4,0,
390,16,468,4,0,
390,16,488,4,0,
390,16,490,4,0,
390,16,496,4,0,
390,16,501,2,0,
390,16,510,4,0,
390,16,512,1,39,1
390,16,512,4,0,
390,16,519,3,0,
390,16,590,4,0,
390,16,612,4,0,
390,17,7,2,0,
390,17,9,2,0,
390,17,10,1,1,1
390,17,14,4,0,
390,17,24,2,0,
390,17,43,1,1,2
390,17,52,1,7,
390,17,53,1,47,
390,17,53,4,0,
390,17,66,2,0,
390,17,68,2,0,
390,17,83,1,33,
390,17,92,4,0,
390,17,104,4,0,
390,17,116,2,0,
390,17,126,4,0,
390,17,154,1,15,
390,17,156,4,0,
390,17,164,4,0,
390,17,172,1,17,
390,17,182,4,0,
390,17,207,4,0,
390,17,213,4,0,
390,17,214,4,0,
390,17,216,4,0,
390,17,218,4,0,
390,17,227,2,0,
390,17,237,4,0,
390,17,241,4,0,
390,17,252,2,0,
390,17,257,2,0,
390,17,259,1,25,
390,17,259,4,0,
390,17,261,4,0,
390,17,263,1,31,
390,17,263,4,0,
390,17,264,2,0,
390,17,269,1,9,
390,17,269,4,0,
390,17,270,2,0,
390,17,274,2,0,
390,17,280,4,0,
390,17,299,2,0,
390,17,303,1,41,
390,17,315,4,0,
390,17,332,4,0,
390,17,339,4,0,
390,17,369,4,0,
390,17,374,4,0,
390,17,417,1,23,
390,17,421,4,0,
390,17,447,4,0,
390,17,488,4,0,
390,17,490,4,0,
390,17,496,4,0,
390,17,501,2,0,
390,17,512,1,39,
390,17,512,4,0,
390,17,519,3,0,
390,17,526,4,0,
390,17,590,4,0,
390,18,7,2,0,
390,18,9,2,0,
390,18,10,1,1,1
390,18,14,4,0,
390,18,24,2,0,
390,18,43,1,1,2
390,18,52,1,7,
390,18,53,1,47,
390,18,53,4,0,
390,18,66,2,0,
390,18,68,2,0,
390,18,83,1,33,
390,18,92,4,0,
390,18,104,4,0,
390,18,116,2,0,
390,18,126,4,0,
390,18,154,1,15,
390,18,156,4,0,
390,18,164,4,0,
390,18,172,1,17,
390,18,182,4,0,
390,18,207,4,0,
390,18,213,4,0,
390,18,214,4,0,
390,18,216,4,0,
390,18,218,4,0,
390,18,227,2,0,
390,18,237,4,0,
390,18,241,4,0,
390,18,252,2,0,
390,18,257,2,0,
390,18,259,1,25,
390,18,259,4,0,
390,18,261,4,0,
390,18,263,1,31,
390,18,263,4,0,
390,18,264,2,0,
390,18,269,1,9,
390,18,269,4,0,
390,18,270,2,0,
390,18,274,2,0,
390,18,280,4,0,
390,18,299,2,0,
390,18,303,1,41,
390,18,315,4,0,
390,18,332,4,0,
390,18,339,4,0,
390,18,369,4,0,
390,18,374,4,0,
390,18,417,1,23,
390,18,421,4,0,
390,18,447,4,0,
390,18,488,4,0,
390,18,490,4,0,
390,18,496,4,0,
390,18,501,2,0,
390,18,512,1,39,
390,18,512,4,0,
390,18,519,3,0,
390,18,526,4,0,
390,18,590,4,0,
390,18,612,2,0,
391,8,10,1,1,1
391,8,14,4,0,
391,8,15,4,0,
391,8,43,1,1,2
391,8,52,1,1,3
391,8,52,1,7,
391,8,53,4,0,
391,8,70,4,0,
391,8,83,1,39,
391,8,91,4,0,
391,8,92,4,0,
391,8,104,4,0,
391,8,126,4,0,
391,8,154,1,16,
391,8,156,4,0,
391,8,157,4,0,
391,8,164,4,0,
391,8,172,1,19,
391,8,182,4,0,
391,8,183,1,14,
391,8,203,4,0,
391,8,207,4,0,
391,8,213,4,0,
391,8,214,4,0,
391,8,216,4,0,
391,8,218,4,0,
391,8,231,4,0,
391,8,237,4,0,
391,8,241,4,0,
391,8,249,4,0,
391,8,259,1,29,
391,8,259,4,0,
391,8,261,4,0,
391,8,263,4,0,
391,8,264,4,0,
391,8,269,1,9,
391,8,269,4,0,
391,8,280,4,0,
391,8,290,4,0,
391,8,303,1,46,
391,8,315,4,0,
391,8,317,4,0,
391,8,332,4,0,
391,8,339,4,0,
391,8,363,4,0,
391,8,364,1,26,
391,8,369,4,0,
391,8,370,1,36,
391,8,374,4,0,
391,8,394,1,49,
391,8,398,4,0,
391,8,411,4,0,
391,8,421,4,0,
391,8,431,4,0,
391,8,445,4,0,
391,8,446,4,0,
391,8,447,4,0,
391,9,7,3,0,
391,9,9,3,0,
391,9,10,1,1,1
391,9,14,4,0,
391,9,15,4,0,
391,9,43,1,1,2
391,9,52,1,1,3
391,9,52,1,7,
391,9,53,4,0,
391,9,70,4,0,
391,9,83,1,39,
391,9,91,4,0,
391,9,92,4,0,
391,9,104,4,0,
391,9,126,4,0,
391,9,129,3,0,
391,9,154,1,16,
391,9,156,4,0,
391,9,157,4,0,
391,9,164,4,0,
391,9,172,1,19,
391,9,173,3,0,
391,9,182,4,0,
391,9,183,1,14,
391,9,189,3,0,
391,9,203,4,0,
391,9,205,3,0,
391,9,207,4,0,
391,9,213,4,0,
391,9,214,4,0,
391,9,216,4,0,
391,9,218,4,0,
391,9,231,4,0,
391,9,237,4,0,
391,9,241,4,0,
391,9,249,4,0,
391,9,257,3,0,
391,9,259,1,29,
391,9,259,4,0,
391,9,261,4,0,
391,9,263,4,0,
391,9,264,4,0,
391,9,269,1,9,
391,9,269,4,0,
391,9,270,3,0,
391,9,280,4,0,
391,9,283,3,0,
391,9,290,4,0,
391,9,303,1,46,
391,9,315,4,0,
391,9,317,4,0,
391,9,332,4,0,
391,9,339,4,0,
391,9,363,4,0,
391,9,364,1,26,
391,9,369,4,0,
391,9,370,1,36,
391,9,374,4,0,
391,9,394,1,49,
391,9,398,4,0,
391,9,410,3,0,
391,9,411,4,0,
391,9,421,4,0,
391,9,431,4,0,
391,9,441,3,0,
391,9,445,4,0,
391,9,446,4,0,
391,9,447,4,0,
391,10,7,3,0,
391,10,9,3,0,
391,10,10,1,1,1
391,10,14,4,0,
391,10,15,4,0,
391,10,29,3,0,
391,10,43,1,1,2
391,10,52,1,1,3
391,10,52,1,7,
391,10,53,4,0,
391,10,67,3,0,
391,10,70,4,0,
391,10,83,1,39,
391,10,91,4,0,
391,10,92,4,0,
391,10,104,4,0,
391,10,126,4,0,
391,10,129,3,0,
391,10,154,1,16,
391,10,156,4,0,
391,10,157,4,0,
391,10,164,4,0,
391,10,172,1,19,
391,10,173,3,0,
391,10,182,4,0,
391,10,183,1,14,
391,10,189,3,0,
391,10,203,4,0,
391,10,205,3,0,
391,10,207,4,0,
391,10,213,4,0,
391,10,214,4,0,
391,10,216,4,0,
391,10,218,4,0,
391,10,231,4,0,
391,10,237,4,0,
391,10,241,4,0,
391,10,249,4,0,
391,10,257,3,0,
391,10,259,1,29,
391,10,259,4,0,
391,10,261,4,0,
391,10,263,4,0,
391,10,264,4,0,
391,10,269,1,9,
391,10,269,4,0,
391,10,270,3,0,
391,10,272,3,0,
391,10,280,4,0,
391,10,283,3,0,
391,10,290,4,0,
391,10,303,1,46,
391,10,315,4,0,
391,10,317,4,0,
391,10,332,4,0,
391,10,339,4,0,
391,10,363,4,0,
391,10,364,1,26,
391,10,369,4,0,
391,10,370,1,36,
391,10,374,4,0,
391,10,394,1,49,
391,10,398,4,0,
391,10,410,3,0,
391,10,411,4,0,
391,10,421,4,0,
391,10,431,4,0,
391,10,441,3,0,
391,10,445,4,0,
391,10,446,4,0,
391,10,447,4,0,
391,11,10,1,1,1
391,11,14,4,0,
391,11,15,4,0,
391,11,43,1,1,2
391,11,52,1,1,3
391,11,52,1,7,
391,11,53,4,0,
391,11,70,4,0,
391,11,83,1,39,
391,11,91,4,0,
391,11,92,4,0,
391,11,104,4,0,
391,11,126,4,0,
391,11,154,1,16,
391,11,156,4,0,
391,11,157,4,0,
391,11,164,4,0,
391,11,172,1,19,
391,11,182,4,0,
391,11,183,1,14,
391,11,207,4,0,
391,11,213,4,0,
391,11,216,4,0,
391,11,218,4,0,
391,11,237,4,0,
391,11,241,4,0,
391,11,249,4,0,
391,11,259,1,29,
391,11,259,4,0,
391,11,261,4,0,
391,11,263,4,0,
391,11,269,1,9,
391,11,269,4,0,
391,11,280,4,0,
391,11,303,1,49,
391,11,315,4,0,
391,11,317,4,0,
391,11,332,4,0,
391,11,339,4,0,
391,11,364,1,26,
391,11,369,4,0,
391,11,370,1,36,
391,11,374,4,0,
391,11,394,1,56,
391,11,398,4,0,
391,11,411,4,0,
391,11,421,4,0,
391,11,447,4,0,
391,11,468,4,0,
391,11,488,4,0,
391,11,490,4,0,
391,11,496,4,0,
391,11,510,4,0,
391,11,512,1,46,
391,11,512,4,0,
391,11,514,4,0,
391,11,519,3,0,
391,11,526,4,0,
391,14,7,3,0,
391,14,9,3,0,
391,14,10,1,1,1
391,14,14,4,0,
391,14,15,4,0,
391,14,43,1,1,2
391,14,52,1,1,3
391,14,52,1,7,
391,14,53,4,0,
391,14,67,3,0,
391,14,70,4,0,
391,14,83,1,39,
391,14,91,4,0,
391,14,92,4,0,
391,14,104,4,0,
391,14,126,4,0,
391,14,154,1,16,
391,14,156,4,0,
391,14,157,4,0,
391,14,164,4,0,
391,14,172,1,19,
391,14,173,3,0,
391,14,182,4,0,
391,14,183,1,14,
391,14,207,4,0,
391,14,213,4,0,
391,14,214,3,0,
391,14,216,4,0,
391,14,218,4,0,
391,14,231,3,0,
391,14,237,4,0,
391,14,241,4,0,
391,14,249,4,0,
391,14,257,3,0,
391,14,259,1,29,
391,14,259,4,0,
391,14,261,4,0,
391,14,263,4,0,
391,14,269,1,9,
391,14,269,4,0,
391,14,270,3,0,
391,14,272,3,0,
391,14,280,4,0,
391,14,283,3,0,
391,14,303,1,49,
391,14,315,4,0,
391,14,317,4,0,
391,14,332,4,0,
391,14,339,4,0,
391,14,343,3,0,
391,14,364,1,26,
391,14,369,4,0,
391,14,370,1,36,
391,14,374,4,0,
391,14,394,1,56,
391,14,398,4,0,
391,14,411,4,0,
391,14,421,4,0,
391,14,441,3,0,
391,14,446,3,0,
391,14,447,4,0,
391,14,468,4,0,
391,14,488,4,0,
391,14,490,4,0,
391,14,496,4,0,
391,14,510,4,0,
391,14,512,1,46,
391,14,512,4,0,
391,14,514,4,0,
391,14,519,3,0,
391,14,526,4,0,
391,14,530,3,0,
391,15,10,1,1,1
391,15,14,4,0,
391,15,15,4,0,
391,15,43,1,1,2
391,15,52,1,1,3
391,15,52,1,7,
391,15,53,4,0,
391,15,70,4,0,
391,15,83,1,39,
391,15,91,4,0,
391,15,92,4,0,
391,15,104,4,0,
391,15,126,4,0,
391,15,154,1,16,
391,15,156,4,0,
391,15,157,4,0,
391,15,164,4,0,
391,15,172,1,19,
391,15,182,4,0,
391,15,183,1,14,
391,15,207,4,0,
391,15,213,4,0,
391,15,214,4,0,
391,15,216,4,0,
391,15,218,4,0,
391,15,237,4,0,
391,15,241,4,0,
391,15,249,4,0,
391,15,259,1,29,
391,15,259,4,0,
391,15,261,4,0,
391,15,263,4,0,
391,15,269,1,9,
391,15,269,4,0,
391,15,280,4,0,
391,15,303,1,49,
391,15,315,4,0,
391,15,317,4,0,
391,15,332,4,0,
391,15,339,4,0,
391,15,364,1,26,
391,15,369,4,0,
391,15,370,1,36,
391,15,374,4,0,
391,15,394,1,56,
391,15,398,4,0,
391,15,411,4,0,
391,15,421,4,0,
391,15,447,4,0,
391,15,468,4,0,
391,15,488,4,0,
391,15,490,4,0,
391,15,496,4,0,
391,15,510,4,0,
391,15,512,1,46,
391,15,512,4,0,
391,15,514,4,0,
391,15,519,3,0,
391,15,590,4,0,
391,15,612,4,0,
391,16,7,3,0,
391,16,9,3,0,
391,16,10,1,1,1
391,16,14,4,0,
391,16,15,4,0,
391,16,43,1,1,2
391,16,52,1,1,3
391,16,52,1,7,1
391,16,53,4,0,
391,16,67,3,0,
391,16,70,4,0,
391,16,83,1,39,1
391,16,91,4,0,
391,16,92,4,0,
391,16,104,4,0,
391,16,126,4,0,
391,16,154,1,16,1
391,16,156,4,0,
391,16,157,4,0,
391,16,164,4,0,
391,16,172,1,19,1
391,16,173,3,0,
391,16,182,4,0,
391,16,183,1,14,1
391,16,207,4,0,
391,16,213,4,0,
391,16,214,4,0,
391,16,216,4,0,
391,16,218,4,0,
391,16,231,3,0,
391,16,237,4,0,
391,16,241,4,0,
391,16,249,4,0,
391,16,257,3,0,
391,16,259,1,29,1
391,16,259,4,0,
391,16,261,4,0,
391,16,263,4,0,
391,16,264,3,0,
391,16,269,1,9,1
391,16,269,4,0,
391,16,270,3,0,
391,16,272,3,0,
391,16,280,4,0,
391,16,283,3,0,
391,16,290,4,0,
391,16,303,1,49,1
391,16,315,4,0,
391,16,317,4,0,
391,16,332,4,0,
391,16,339,4,0,
391,16,343,3,0,
391,16,364,1,26,1
391,16,369,4,0,
391,16,370,1,36,1
391,16,374,4,0,
391,16,394,1,56,1
391,16,398,4,0,
391,16,411,4,0,
391,16,421,4,0,
391,16,441,3,0,
391,16,446,3,0,
391,16,447,4,0,
391,16,468,4,0,
391,16,488,4,0,
391,16,490,4,0,
391,16,496,4,0,
391,16,510,4,0,
391,16,512,1,46,1
391,16,512,4,0,
391,16,514,4,0,
391,16,519,3,0,
391,16,530,3,0,
391,16,590,4,0,
391,16,612,4,0,
391,17,10,1,1,2
391,17,14,4,0,
391,17,43,1,1,3
391,17,52,1,1,4
391,17,52,1,7,
391,17,53,4,0,
391,17,83,1,39,
391,17,92,4,0,
391,17,104,4,0,
391,17,126,4,0,
391,17,154,1,16,
391,17,156,4,0,
391,17,157,4,0,
391,17,164,4,0,
391,17,172,1,19,
391,17,182,4,0,
391,17,183,1,0,
391,17,183,1,1,1
391,17,207,4,0,
391,17,213,4,0,
391,17,214,4,0,
391,17,216,4,0,
391,17,218,4,0,
391,17,237,4,0,
391,17,241,4,0,
391,17,259,1,29,
391,17,259,4,0,
391,17,261,4,0,
391,17,263,4,0,
391,17,269,1,9,
391,17,269,4,0,
391,17,280,4,0,
391,17,303,1,49,
391,17,315,4,0,
391,17,317,4,0,
391,17,332,4,0,
391,17,339,4,0,
391,17,364,1,26,
391,17,369,4,0,
391,17,370,1,36,
391,17,374,4,0,
391,17,394,1,56,
391,17,398,4,0,
391,17,411,4,0,
391,17,421,4,0,
391,17,447,4,0,
391,17,488,4,0,
391,17,490,4,0,
391,17,496,4,0,
391,17,512,1,46,
391,17,512,4,0,
391,17,519,3,0,
391,17,526,4,0,
391,17,590,4,0,
391,18,10,1,1,2
391,18,14,4,0,
391,18,43,1,1,3
391,18,52,1,1,4
391,18,52,1,7,
391,18,53,4,0,
391,18,83,1,39,
391,18,92,4,0,
391,18,104,4,0,
391,18,126,4,0,
391,18,154,1,16,
391,18,156,4,0,
391,18,157,4,0,
391,18,164,4,0,
391,18,172,1,19,
391,18,182,4,0,
391,18,183,1,0,
391,18,183,1,1,1
391,18,207,4,0,
391,18,213,4,0,
391,18,214,4,0,
391,18,216,4,0,
391,18,218,4,0,
391,18,237,4,0,
391,18,241,4,0,
391,18,259,1,29,
391,18,259,4,0,
391,18,261,4,0,
391,18,263,4,0,
391,18,269,1,9,
391,18,269,4,0,
391,18,280,4,0,
391,18,303,1,49,
391,18,315,4,0,
391,18,317,4,0,
391,18,332,4,0,
391,18,339,4,0,
391,18,364,1,26,
391,18,369,4,0,
391,18,370,1,36,
391,18,374,4,0,
391,18,394,1,56,
391,18,398,4,0,
391,18,411,4,0,
391,18,421,4,0,
391,18,447,4,0,
391,18,488,4,0,
391,18,490,4,0,
391,18,496,4,0,
391,18,512,1,46,
391,18,512,4,0,
391,18,519,3,0,
391,18,526,4,0,
391,18,590,4,0,
392,8,10,1,1,1
392,8,14,4,0,
392,8,15,4,0,
392,8,43,1,1,2
392,8,46,4,0,
392,8,52,1,1,3
392,8,52,1,7,
392,8,53,4,0,
392,8,63,4,0,
392,8,70,4,0,
392,8,76,4,0,
392,8,83,1,45,
392,8,89,4,0,
392,8,91,4,0,
392,8,92,4,0,
392,8,104,4,0,
392,8,126,4,0,
392,8,154,1,17,
392,8,156,4,0,
392,8,157,4,0,
392,8,164,4,0,
392,8,172,1,21,
392,8,182,4,0,
392,8,183,1,14,
392,8,203,4,0,
392,8,207,4,0,
392,8,213,4,0,
392,8,214,4,0,
392,8,216,4,0,
392,8,218,4,0,
392,8,231,4,0,
392,8,237,4,0,
392,8,241,4,0,
392,8,249,4,0,
392,8,259,4,0,
392,8,261,4,0,
392,8,263,4,0,
392,8,264,4,0,
392,8,269,1,1,4
392,8,269,1,9,
392,8,269,4,0,
392,8,280,4,0,
392,8,290,4,0,
392,8,307,3,0,
392,8,315,4,0,
392,8,317,4,0,
392,8,332,4,0,
392,8,339,4,0,
392,8,347,1,53,
392,8,347,4,0,
392,8,363,4,0,
392,8,364,1,29,
392,8,369,4,0,
392,8,370,1,41,
392,8,374,4,0,
392,8,386,1,33,
392,8,394,1,57,
392,8,398,4,0,
392,8,411,4,0,
392,8,416,4,0,
392,8,421,4,0,
392,8,431,4,0,
392,8,444,4,0,
392,8,445,4,0,
392,8,446,4,0,
392,8,447,4,0,
392,9,7,3,0,
392,9,9,3,0,
392,9,10,1,1,1
392,9,14,4,0,
392,9,15,4,0,
392,9,43,1,1,2
392,9,46,4,0,
392,9,52,1,1,3
392,9,52,1,7,
392,9,53,4,0,
392,9,63,4,0,
392,9,70,4,0,
392,9,76,4,0,
392,9,83,1,45,
392,9,89,4,0,
392,9,91,4,0,
392,9,92,4,0,
392,9,104,4,0,
392,9,126,4,0,
392,9,129,3,0,
392,9,154,1,17,
392,9,156,4,0,
392,9,157,4,0,
392,9,164,4,0,
392,9,172,1,21,
392,9,173,3,0,
392,9,182,4,0,
392,9,183,1,14,
392,9,189,3,0,
392,9,203,4,0,
392,9,205,3,0,
392,9,207,4,0,
392,9,213,4,0,
392,9,214,4,0,
392,9,216,4,0,
392,9,218,4,0,
392,9,231,4,0,
392,9,237,4,0,
392,9,241,4,0,
392,9,249,4,0,
392,9,257,3,0,
392,9,259,4,0,
392,9,261,4,0,
392,9,263,4,0,
392,9,264,4,0,
392,9,269,1,1,4
392,9,269,1,9,
392,9,269,4,0,
392,9,270,3,0,
392,9,280,4,0,
392,9,283,3,0,
392,9,290,4,0,
392,9,307,3,0,
392,9,315,4,0,
392,9,317,4,0,
392,9,332,4,0,
392,9,339,4,0,
392,9,347,1,53,
392,9,347,4,0,
392,9,363,4,0,
392,9,364,1,29,
392,9,369,4,0,
392,9,370,1,41,
392,9,374,4,0,
392,9,386,1,33,
392,9,394,1,57,
392,9,398,4,0,
392,9,410,3,0,
392,9,411,4,0,
392,9,416,4,0,
392,9,421,4,0,
392,9,431,4,0,
392,9,441,3,0,
392,9,444,4,0,
392,9,445,4,0,
392,9,446,4,0,
392,9,447,4,0,
392,10,7,3,0,
392,10,9,3,0,
392,10,10,1,1,1
392,10,14,4,0,
392,10,15,4,0,
392,10,29,3,0,
392,10,43,1,1,2
392,10,46,4,0,
392,10,52,1,1,3
392,10,52,1,7,
392,10,53,4,0,
392,10,63,4,0,
392,10,67,3,0,
392,10,70,4,0,
392,10,76,4,0,
392,10,83,1,45,
392,10,89,4,0,
392,10,91,4,0,
392,10,92,4,0,
392,10,104,4,0,
392,10,126,4,0,
392,10,129,3,0,
392,10,154,1,17,
392,10,156,4,0,
392,10,157,4,0,
392,10,164,4,0,
392,10,172,1,21,
392,10,173,3,0,
392,10,182,4,0,
392,10,183,1,14,
392,10,189,3,0,
392,10,203,4,0,
392,10,205,3,0,
392,10,207,4,0,
392,10,213,4,0,
392,10,214,4,0,
392,10,216,4,0,
392,10,218,4,0,
392,10,231,4,0,
392,10,237,4,0,
392,10,241,4,0,
392,10,249,4,0,
392,10,257,3,0,
392,10,259,4,0,
392,10,261,4,0,
392,10,263,4,0,
392,10,264,4,0,
392,10,269,1,1,4
392,10,269,1,9,
392,10,269,4,0,
392,10,270,3,0,
392,10,272,3,0,
392,10,280,4,0,
392,10,283,3,0,
392,10,290,4,0,
392,10,307,3,0,
392,10,315,4,0,
392,10,317,4,0,
392,10,332,4,0,
392,10,339,4,0,
392,10,347,1,53,
392,10,347,4,0,
392,10,363,4,0,
392,10,364,1,29,
392,10,369,4,0,
392,10,370,1,41,
392,10,374,4,0,
392,10,386,1,33,
392,10,394,1,57,
392,10,398,4,0,
392,10,410,3,0,
392,10,411,4,0,
392,10,416,4,0,
392,10,421,4,0,
392,10,431,4,0,
392,10,441,3,0,
392,10,444,4,0,
392,10,445,4,0,
392,10,446,4,0,
392,10,447,4,0,
392,11,10,1,1,1
392,11,14,4,0,
392,11,15,4,0,
392,11,43,1,1,2
392,11,46,4,0,
392,11,52,1,1,3
392,11,52,1,7,
392,11,53,4,0,
392,11,63,4,0,
392,11,70,4,0,
392,11,76,4,0,
392,11,83,1,42,
392,11,89,4,0,
392,11,91,4,0,
392,11,92,4,0,
392,11,104,4,0,
392,11,126,4,0,
392,11,154,1,16,
392,11,156,4,0,
392,11,157,4,0,
392,11,164,4,0,
392,11,172,1,19,
392,11,182,4,0,
392,11,183,1,14,
392,11,207,4,0,
392,11,213,4,0,
392,11,216,4,0,
392,11,218,4,0,
392,11,237,4,0,
392,11,241,4,0,
392,11,249,4,0,
392,11,259,4,0,
392,11,261,4,0,
392,11,263,4,0,
392,11,269,1,1,4
392,11,269,1,9,
392,11,269,4,0,
392,11,280,4,0,
392,11,307,3,0,
392,11,315,4,0,
392,11,317,4,0,
392,11,332,4,0,
392,11,339,4,0,
392,11,347,1,58,
392,11,347,4,0,
392,11,364,1,26,
392,11,369,4,0,
392,11,370,1,36,
392,11,374,4,0,
392,11,386,1,29,
392,11,394,1,68,
392,11,398,4,0,
392,11,411,4,0,
392,11,416,4,0,
392,11,421,4,0,
392,11,444,4,0,
392,11,447,4,0,
392,11,468,4,0,
392,11,488,4,0,
392,11,490,4,0,
392,11,496,4,0,
392,11,510,4,0,
392,11,512,1,52,
392,11,512,4,0,
392,11,514,4,0,
392,11,519,3,0,
392,11,523,4,0,
392,11,526,4,0,
392,14,7,3,0,
392,14,9,3,0,
392,14,10,1,1,1
392,14,14,4,0,
392,14,15,4,0,
392,14,43,1,1,2
392,14,46,4,0,
392,14,52,1,1,3
392,14,52,1,7,
392,14,53,4,0,
392,14,63,4,0,
392,14,67,3,0,
392,14,70,4,0,
392,14,76,4,0,
392,14,83,1,42,
392,14,89,4,0,
392,14,91,4,0,
392,14,92,4,0,
392,14,104,4,0,
392,14,126,4,0,
392,14,154,1,16,
392,14,156,4,0,
392,14,157,4,0,
392,14,164,4,0,
392,14,172,1,19,
392,14,173,3,0,
392,14,182,4,0,
392,14,183,1,14,
392,14,207,4,0,
392,14,213,4,0,
392,14,214,3,0,
392,14,216,4,0,
392,14,218,4,0,
392,14,231,3,0,
392,14,237,4,0,
392,14,241,4,0,
392,14,249,4,0,
392,14,257,3,0,
392,14,259,4,0,
392,14,261,4,0,
392,14,263,4,0,
392,14,269,1,1,4
392,14,269,1,9,
392,14,269,4,0,
392,14,270,3,0,
392,14,272,3,0,
392,14,280,4,0,
392,14,283,3,0,
392,14,307,3,0,
392,14,315,4,0,
392,14,317,4,0,
392,14,332,4,0,
392,14,339,4,0,
392,14,343,3,0,
392,14,347,1,58,
392,14,347,4,0,
392,14,364,1,26,
392,14,369,4,0,
392,14,370,1,36,
392,14,374,4,0,
392,14,386,1,29,
392,14,394,1,68,
392,14,398,4,0,
392,14,411,4,0,
392,14,416,4,0,
392,14,421,4,0,
392,14,441,3,0,
392,14,444,4,0,
392,14,446,3,0,
392,14,447,4,0,
392,14,468,4,0,
392,14,488,4,0,
392,14,490,4,0,
392,14,496,4,0,
392,14,510,4,0,
392,14,512,1,52,
392,14,512,4,0,
392,14,514,4,0,
392,14,519,3,0,
392,14,523,4,0,
392,14,526,4,0,
392,14,530,3,0,
392,15,10,1,1,2
392,15,14,4,0,
392,15,15,4,0,
392,15,43,1,1,3
392,15,46,4,0,
392,15,52,1,1,4
392,15,52,1,7,
392,15,53,4,0,
392,15,63,4,0,
392,15,70,4,0,
392,15,76,4,0,
392,15,83,1,42,
392,15,89,4,0,
392,15,91,4,0,
392,15,92,4,0,
392,15,104,4,0,
392,15,126,4,0,
392,15,154,1,16,
392,15,156,4,0,
392,15,157,4,0,
392,15,164,4,0,
392,15,172,1,19,
392,15,182,4,0,
392,15,183,1,14,
392,15,207,4,0,
392,15,213,4,0,
392,15,214,4,0,
392,15,216,4,0,
392,15,218,4,0,
392,15,237,4,0,
392,15,241,4,0,
392,15,249,4,0,
392,15,259,4,0,
392,15,261,4,0,
392,15,263,4,0,
392,15,269,1,1,5
392,15,269,1,9,
392,15,269,4,0,
392,15,280,4,0,
392,15,307,3,0,
392,15,315,4,0,
392,15,317,4,0,
392,15,332,4,0,
392,15,339,4,0,
392,15,347,1,58,
392,15,347,4,0,
392,15,364,1,26,
392,15,369,4,0,
392,15,370,1,36,
392,15,374,4,0,
392,15,386,1,29,
392,15,394,1,1,1
392,15,394,1,68,
392,15,398,4,0,
392,15,411,4,0,
392,15,416,4,0,
392,15,421,4,0,
392,15,444,4,0,
392,15,447,4,0,
392,15,468,4,0,
392,15,488,4,0,
392,15,490,4,0,
392,15,496,4,0,
392,15,510,4,0,
392,15,512,1,52,
392,15,512,4,0,
392,15,514,4,0,
392,15,519,3,0,
392,15,523,4,0,
392,15,590,4,0,
392,15,612,4,0,
392,16,7,3,0,
392,16,9,3,0,
392,16,10,1,1,2
392,16,14,4,0,
392,16,15,4,0,
392,16,43,1,1,3
392,16,46,4,0,
392,16,52,1,1,4
392,16,52,1,7,1
392,16,53,4,0,
392,16,63,4,0,
392,16,67,3,0,
392,16,70,4,0,
392,16,76,4,0,
392,16,83,1,42,1
392,16,89,4,0,
392,16,91,4,0,
392,16,92,4,0,
392,16,104,4,0,
392,16,126,4,0,
392,16,154,1,16,1
392,16,156,4,0,
392,16,157,4,0,
392,16,164,4,0,
392,16,172,1,19,1
392,16,173,3,0,
392,16,182,4,0,
392,16,183,1,14,1
392,16,207,4,0,
392,16,213,4,0,
392,16,214,4,0,
392,16,216,4,0,
392,16,218,4,0,
392,16,231,3,0,
392,16,237,4,0,
392,16,241,4,0,
392,16,249,4,0,
392,16,257,3,0,
392,16,259,4,0,
392,16,261,4,0,
392,16,263,4,0,
392,16,264,3,0,
392,16,269,1,1,5
392,16,269,1,9,1
392,16,269,4,0,
392,16,270,3,0,
392,16,272,3,0,
392,16,280,4,0,
392,16,283,3,0,
392,16,290,4,0,
392,16,307,3,0,
392,16,315,4,0,
392,16,317,4,0,
392,16,332,4,0,
392,16,339,4,0,
392,16,343,3,0,
392,16,347,1,58,1
392,16,347,4,0,
392,16,364,1,26,1
392,16,369,4,0,
392,16,370,1,36,1
392,16,374,4,0,
392,16,386,1,29,1
392,16,394,1,1,1
392,16,394,1,68,1
392,16,398,4,0,
392,16,411,4,0,
392,16,416,4,0,
392,16,421,4,0,
392,16,441,3,0,
392,16,444,4,0,
392,16,446,3,0,
392,16,447,4,0,
392,16,468,4,0,
392,16,488,4,0,
392,16,490,4,0,
392,16,496,4,0,
392,16,510,4,0,
392,16,512,1,52,1
392,16,512,4,0,
392,16,514,4,0,
392,16,519,3,0,
392,16,523,4,0,
392,16,530,3,0,
392,16,590,4,0,
392,16,612,4,0,
392,17,10,1,1,4
392,17,14,4,0,
392,17,43,1,1,5
392,17,46,4,0,
392,17,52,1,1,6
392,17,52,1,7,
392,17,53,4,0,
392,17,63,4,0,
392,17,76,4,0,
392,17,83,1,42,
392,17,89,4,0,
392,17,92,4,0,
392,17,104,4,0,
392,17,126,4,0,
392,17,154,1,16,
392,17,156,4,0,
392,17,157,4,0,
392,17,164,4,0,
392,17,172,1,19,
392,17,182,4,0,
392,17,183,1,1,2
392,17,207,4,0,
392,17,213,4,0,
392,17,214,4,0,
392,17,216,4,0,
392,17,218,4,0,
392,17,237,4,0,
392,17,241,4,0,
392,17,259,4,0,
392,17,261,4,0,
392,17,263,4,0,
392,17,269,1,1,7
392,17,269,1,9,
392,17,269,4,0,
392,17,280,4,0,
392,17,307,3,0,
392,17,315,4,0,
392,17,317,4,0,
392,17,332,4,0,
392,17,339,4,0,
392,17,347,1,58,
392,17,347,4,0,
392,17,364,1,26,
392,17,369,4,0,
392,17,370,1,0,
392,17,370,1,1,1
392,17,374,4,0,
392,17,386,1,29,
392,17,394,1,1,3
392,17,394,1,68,
392,17,398,4,0,
392,17,411,4,0,
392,17,416,4,0,
392,17,421,4,0,
392,17,444,4,0,
392,17,447,4,0,
392,17,488,4,0,
392,17,490,4,0,
392,17,496,4,0,
392,17,512,1,52,
392,17,512,4,0,
392,17,519,3,0,
392,17,523,4,0,
392,17,526,4,0,
392,17,590,4,0,
392,18,10,1,1,4
392,18,14,4,0,
392,18,43,1,1,5
392,18,46,4,0,
392,18,52,1,1,6
392,18,52,1,7,
392,18,53,4,0,
392,18,63,4,0,
392,18,76,4,0,
392,18,83,1,42,
392,18,89,4,0,
392,18,92,4,0,
392,18,104,4,0,
392,18,126,4,0,
392,18,154,1,16,
392,18,156,4,0,
392,18,157,4,0,
392,18,164,4,0,
392,18,172,1,19,
392,18,182,4,0,
392,18,183,1,1,2
392,18,207,4,0,
392,18,213,4,0,
392,18,214,4,0,
392,18,216,4,0,
392,18,218,4,0,
392,18,237,4,0,
392,18,241,4,0,
392,18,259,4,0,
392,18,261,4,0,
392,18,263,4,0,
392,18,269,1,1,7
392,18,269,1,9,
392,18,269,4,0,
392,18,280,4,0,
392,18,307,3,0,
392,18,315,4,0,
392,18,317,4,0,
392,18,332,4,0,
392,18,339,4,0,
392,18,347,1,58,
392,18,347,4,0,
392,18,364,1,26,
392,18,369,4,0,
392,18,370,1,0,
392,18,370,1,1,1
392,18,374,4,0,
392,18,386,1,29,
392,18,394,1,1,3
392,18,394,1,68,
392,18,398,4,0,
392,18,411,4,0,
392,18,416,4,0,
392,18,421,4,0,
392,18,444,4,0,
392,18,447,4,0,
392,18,488,4,0,
392,18,490,4,0,
392,18,496,4,0,
392,18,512,1,52,
392,18,512,4,0,
392,18,519,3,0,
392,18,523,4,0,
392,18,526,4,0,
392,18,590,4,0,
393,8,1,1,1,
393,8,15,4,0,
393,8,31,1,25,
393,8,45,1,4,
393,8,48,2,0,
393,8,54,1,36,
393,8,56,1,43,
393,8,56,2,0,
393,8,57,4,0,
393,8,58,4,0,
393,8,59,4,0,
393,8,61,1,22,
393,8,64,1,15,
393,8,65,1,39,
393,8,91,4,0,
393,8,92,4,0,
393,8,97,2,0,
393,8,104,4,0,
393,8,117,1,18,
393,8,127,4,0,
393,8,145,1,8,
393,8,156,4,0,
393,8,164,4,0,
393,8,173,2,0,
393,8,175,2,0,
393,8,182,4,0,
393,8,189,2,0,
393,8,203,4,0,
393,8,207,4,0,
393,8,213,4,0,
393,8,214,4,0,
393,8,216,4,0,
393,8,218,4,0,
393,8,237,4,0,
393,8,240,4,0,
393,8,250,1,32,
393,8,258,4,0,
393,8,263,4,0,
393,8,280,4,0,
393,8,281,2,0,
393,8,290,4,0,
393,8,300,2,0,
393,8,317,4,0,
393,8,332,4,0,
393,8,346,1,11,
393,8,352,4,0,
393,8,362,1,29,
393,8,362,4,0,
393,8,363,4,0,
393,8,365,4,0,
393,8,374,4,0,
393,8,392,2,0,
393,8,432,4,0,
393,8,445,4,0,
393,8,446,4,0,
393,8,447,4,0,
393,8,458,2,0,
393,9,1,1,1,
393,9,15,4,0,
393,9,31,1,25,
393,9,45,1,4,
393,9,48,2,0,
393,9,54,1,36,
393,9,56,1,43,
393,9,56,2,0,
393,9,57,4,0,
393,9,58,4,0,
393,9,59,4,0,
393,9,61,1,18,
393,9,64,1,15,
393,9,65,1,39,
393,9,91,4,0,
393,9,92,4,0,
393,9,97,2,0,
393,9,104,4,0,
393,9,117,1,22,
393,9,127,4,0,
393,9,145,1,8,
393,9,156,4,0,
393,9,164,4,0,
393,9,173,2,0,
393,9,173,3,0,
393,9,175,2,0,
393,9,182,4,0,
393,9,189,2,0,
393,9,189,3,0,
393,9,196,3,0,
393,9,203,4,0,
393,9,207,4,0,
393,9,213,4,0,
393,9,214,4,0,
393,9,216,4,0,
393,9,218,4,0,
393,9,237,4,0,
393,9,240,4,0,
393,9,250,1,32,
393,9,258,4,0,
393,9,263,4,0,
393,9,280,4,0,
393,9,281,2,0,
393,9,290,4,0,
393,9,291,3,0,
393,9,300,2,0,
393,9,317,4,0,
393,9,324,3,0,
393,9,332,4,0,
393,9,346,1,11,
393,9,352,4,0,
393,9,362,1,29,
393,9,362,4,0,
393,9,363,4,0,
393,9,365,4,0,
393,9,374,4,0,
393,9,392,2,0,
393,9,432,4,0,
393,9,445,4,0,
393,9,446,4,0,
393,9,447,4,0,
393,9,458,2,0,
393,10,1,1,1,
393,10,15,4,0,
393,10,29,3,0,
393,10,31,1,25,
393,10,45,1,4,
393,10,48,2,0,
393,10,54,1,36,
393,10,56,1,43,
393,10,56,2,0,
393,10,57,4,0,
393,10,58,4,0,
393,10,59,4,0,
393,10,61,1,18,
393,10,64,1,15,
393,10,65,1,39,
393,10,91,4,0,
393,10,92,4,0,
393,10,97,2,0,
393,10,104,4,0,
393,10,117,1,22,
393,10,127,4,0,
393,10,145,1,8,
393,10,156,4,0,
393,10,164,4,0,
393,10,173,2,0,
393,10,173,3,0,
393,10,175,2,0,
393,10,182,4,0,
393,10,189,2,0,
393,10,189,3,0,
393,10,196,3,0,
393,10,203,4,0,
393,10,207,4,0,
393,10,213,4,0,
393,10,214,4,0,
393,10,216,4,0,
393,10,218,4,0,
393,10,237,4,0,
393,10,240,4,0,
393,10,250,1,32,
393,10,250,4,0,
393,10,258,4,0,
393,10,263,4,0,
393,10,280,4,0,
393,10,281,2,0,
393,10,290,4,0,
393,10,291,3,0,
393,10,297,2,0,
393,10,300,2,0,
393,10,317,4,0,
393,10,324,3,0,
393,10,332,4,0,
393,10,346,1,11,
393,10,352,4,0,
393,10,362,1,29,
393,10,362,4,0,
393,10,363,4,0,
393,10,365,4,0,
393,10,374,4,0,
393,10,392,2,0,
393,10,445,4,0,
393,10,446,4,0,
393,10,447,4,0,
393,10,458,2,0,
393,11,1,1,1,
393,11,15,4,0,
393,11,31,1,25,
393,11,45,1,4,
393,11,48,2,0,
393,11,54,1,36,
393,11,56,1,43,
393,11,56,2,0,
393,11,57,4,0,
393,11,58,4,0,
393,11,59,4,0,
393,11,61,1,18,
393,11,64,1,15,
393,11,65,1,39,
393,11,91,4,0,
393,11,92,4,0,
393,11,97,2,0,
393,11,104,4,0,
393,11,117,1,22,
393,11,117,2,0,
393,11,127,4,0,
393,11,145,1,8,
393,11,156,4,0,
393,11,164,4,0,
393,11,173,2,0,
393,11,175,2,0,
393,11,182,4,0,
393,11,189,2,0,
393,11,196,2,0,
393,11,207,4,0,
393,11,213,4,0,
393,11,216,4,0,
393,11,218,4,0,
393,11,237,4,0,
393,11,240,4,0,
393,11,250,1,32,
393,11,258,4,0,
393,11,263,4,0,
393,11,280,4,0,
393,11,281,2,0,
393,11,291,4,0,
393,11,297,2,0,
393,11,300,2,0,
393,11,317,4,0,
393,11,332,4,0,
393,11,346,1,11,
393,11,362,1,29,
393,11,365,4,0,
393,11,374,4,0,
393,11,392,2,0,
393,11,447,4,0,
393,11,458,2,0,
393,11,496,4,0,
393,11,497,4,0,
393,11,503,4,0,
393,11,511,4,0,
393,11,518,3,0,
393,14,1,1,1,
393,14,15,4,0,
393,14,31,1,25,
393,14,45,1,4,
393,14,48,2,0,
393,14,54,1,36,
393,14,56,1,43,
393,14,56,2,0,
393,14,57,4,0,
393,14,58,4,0,
393,14,59,4,0,
393,14,61,1,18,
393,14,64,1,15,
393,14,65,1,39,
393,14,91,4,0,
393,14,92,4,0,
393,14,97,2,0,
393,14,104,4,0,
393,14,117,1,22,
393,14,117,2,0,
393,14,127,4,0,
393,14,145,1,8,
393,14,156,4,0,
393,14,164,4,0,
393,14,173,2,0,
393,14,173,3,0,
393,14,175,2,0,
393,14,182,4,0,
393,14,189,2,0,
393,14,196,2,0,
393,14,196,3,0,
393,14,207,4,0,
393,14,213,4,0,
393,14,214,3,0,
393,14,216,4,0,
393,14,218,4,0,
393,14,237,4,0,
393,14,240,4,0,
393,14,250,1,32,
393,14,258,4,0,
393,14,263,4,0,
393,14,280,4,0,
393,14,281,2,0,
393,14,291,4,0,
393,14,297,2,0,
393,14,300,2,0,
393,14,317,4,0,
393,14,324,3,0,
393,14,332,4,0,
393,14,343,3,0,
393,14,346,1,11,
393,14,362,1,29,
393,14,365,4,0,
393,14,374,4,0,
393,14,392,2,0,
393,14,446,3,0,
393,14,447,4,0,
393,14,458,2,0,
393,14,496,4,0,
393,14,497,4,0,
393,14,503,4,0,
393,14,511,4,0,
393,14,518,3,0,
393,15,1,1,1,
393,15,15,4,0,
393,15,31,1,25,
393,15,45,1,4,
393,15,48,2,0,
393,15,54,1,36,
393,15,56,1,43,
393,15,56,2,0,
393,15,57,4,0,
393,15,58,4,0,
393,15,59,4,0,
393,15,61,1,18,
393,15,64,1,15,
393,15,65,1,39,
393,15,91,4,0,
393,15,92,4,0,
393,15,97,2,0,
393,15,104,4,0,
393,15,117,1,22,
393,15,117,2,0,
393,15,127,4,0,
393,15,145,1,8,
393,15,156,4,0,
393,15,164,4,0,
393,15,173,2,0,
393,15,175,2,0,
393,15,182,4,0,
393,15,189,2,0,
393,15,196,2,0,
393,15,207,4,0,
393,15,213,4,0,
393,15,214,4,0,
393,15,216,4,0,
393,15,218,4,0,
393,15,237,4,0,
393,15,240,4,0,
393,15,250,1,32,
393,15,258,4,0,
393,15,263,4,0,
393,15,280,4,0,
393,15,281,2,0,
393,15,297,2,0,
393,15,300,2,0,
393,15,317,4,0,
393,15,332,4,0,
393,15,346,1,11,
393,15,362,1,29,
393,15,374,4,0,
393,15,392,2,0,
393,15,447,4,0,
393,15,458,2,0,
393,15,496,4,0,
393,15,497,4,0,
393,15,503,4,0,
393,15,511,4,0,
393,15,518,3,0,
393,15,590,4,0,
393,16,1,1,1,1
393,16,15,4,0,
393,16,31,1,25,1
393,16,45,1,4,1
393,16,48,2,0,
393,16,54,1,36,1
393,16,56,1,43,1
393,16,56,2,0,
393,16,57,4,0,
393,16,58,4,0,
393,16,59,4,0,
393,16,61,1,18,1
393,16,64,1,15,1
393,16,65,1,39,1
393,16,91,4,0,
393,16,92,4,0,
393,16,97,2,0,
393,16,104,4,0,
393,16,117,1,22,1
393,16,117,2,0,
393,16,127,4,0,
393,16,145,1,8,1
393,16,156,4,0,
393,16,164,4,0,
393,16,173,2,0,
393,16,173,3,0,
393,16,175,2,0,
393,16,182,4,0,
393,16,189,2,0,
393,16,196,2,0,
393,16,196,3,0,
393,16,207,4,0,
393,16,213,4,0,
393,16,214,4,0,
393,16,216,4,0,
393,16,218,4,0,
393,16,237,4,0,
393,16,240,4,0,
393,16,250,1,32,1
393,16,258,4,0,
393,16,263,4,0,
393,16,280,4,0,
393,16,281,2,0,
393,16,290,4,0,
393,16,291,4,0,
393,16,297,2,0,
393,16,300,2,0,
393,16,317,4,0,
393,16,324,3,0,
393,16,332,4,0,
393,16,343,3,0,
393,16,346,1,11,1
393,16,352,3,0,
393,16,362,1,29,1
393,16,374,4,0,
393,16,392,2,0,
393,16,446,3,0,
393,16,447,4,0,
393,16,458,2,0,
393,16,496,4,0,
393,16,497,4,0,
393,16,503,4,0,
393,16,511,4,0,
393,16,518,3,0,
393,16,590,4,0,
393,17,1,1,1,
393,17,31,1,25,
393,17,45,1,4,
393,17,48,2,0,
393,17,54,1,36,
393,17,56,1,43,
393,17,56,2,0,
393,17,57,4,0,
393,17,58,4,0,
393,17,59,4,0,
393,17,61,1,18,
393,17,64,1,15,
393,17,65,1,39,
393,17,92,4,0,
393,17,97,2,0,
393,17,104,4,0,
393,17,117,1,22,
393,17,117,2,0,
393,17,127,4,0,
393,17,145,1,8,
393,17,156,4,0,
393,17,164,4,0,
393,17,173,2,0,
393,17,175,2,0,
393,17,182,4,0,
393,17,189,2,0,
393,17,196,2,0,
393,17,207,4,0,
393,17,213,4,0,
393,17,214,4,0,
393,17,216,4,0,
393,17,218,4,0,
393,17,237,4,0,
393,17,240,4,0,
393,17,250,1,32,
393,17,258,4,0,
393,17,263,4,0,
393,17,280,4,0,
393,17,281,2,0,
393,17,297,2,0,
393,17,300,2,0,
393,17,317,4,0,
393,17,332,4,0,
393,17,346,1,11,
393,17,362,1,29,
393,17,374,4,0,
393,17,392,2,0,
393,17,447,4,0,
393,17,458,2,0,
393,17,496,4,0,
393,17,497,4,0,
393,17,503,4,0,
393,17,511,4,0,
393,17,518,3,0,
393,17,526,4,0,
393,17,590,4,0,
393,18,1,1,1,
393,18,31,1,25,
393,18,45,1,4,
393,18,48,2,0,
393,18,54,1,36,
393,18,56,1,43,
393,18,56,2,0,
393,18,57,4,0,
393,18,58,4,0,
393,18,59,4,0,
393,18,61,1,18,
393,18,64,1,15,
393,18,65,1,39,
393,18,92,4,0,
393,18,97,2,0,
393,18,104,4,0,
393,18,117,1,22,
393,18,117,2,0,
393,18,127,4,0,
393,18,145,1,8,
393,18,156,4,0,
393,18,164,4,0,
393,18,173,2,0,
393,18,175,2,0,
393,18,182,4,0,
393,18,189,2,0,
393,18,196,2,0,
393,18,207,4,0,
393,18,213,4,0,
393,18,214,4,0,
393,18,216,4,0,
393,18,218,4,0,
393,18,237,4,0,
393,18,240,4,0,
393,18,250,1,32,
393,18,258,4,0,
393,18,263,4,0,
393,18,280,4,0,
393,18,281,2,0,
393,18,297,2,0,
393,18,300,2,0,
393,18,317,4,0,
393,18,332,4,0,
393,18,346,1,11,
393,18,362,1,29,
393,18,374,4,0,
393,18,392,2,0,
393,18,447,4,0,
393,18,458,2,0,
393,18,496,4,0,
393,18,497,4,0,
393,18,503,4,0,
393,18,511,4,0,
393,18,518,3,0,
393,18,526,4,0,
393,18,590,4,0,
393,18,681,2,0,
394,8,15,4,0,
394,8,31,1,28,
394,8,33,1,1,1
394,8,45,1,1,2
394,8,45,1,4,
394,8,54,1,42,
394,8,56,1,51,
394,8,57,4,0,
394,8,58,4,0,
394,8,59,4,0,
394,8,61,1,24,
394,8,64,1,15,
394,8,65,1,46,
394,8,70,4,0,
394,8,91,4,0,
394,8,92,4,0,
394,8,104,4,0,
394,8,117,1,19,
394,8,127,4,0,
394,8,145,1,8,
394,8,156,4,0,
394,8,164,4,0,
394,8,182,4,0,
394,8,203,4,0,
394,8,207,4,0,
394,8,213,4,0,
394,8,214,4,0,
394,8,216,4,0,
394,8,218,4,0,
394,8,232,1,16,
394,8,237,4,0,
394,8,240,4,0,
394,8,249,4,0,
394,8,250,1,37,
394,8,258,4,0,
394,8,263,4,0,
394,8,280,4,0,
394,8,290,4,0,
394,8,317,4,0,
394,8,332,4,0,
394,8,346,1,11,
394,8,352,4,0,
394,8,362,1,33,
394,8,362,4,0,
394,8,363,4,0,
394,8,365,4,0,
394,8,374,4,0,
394,8,421,4,0,
394,8,432,4,0,
394,8,445,4,0,
394,8,446,4,0,
394,8,447,4,0,
394,9,15,4,0,
394,9,31,1,28,
394,9,33,1,1,1
394,9,45,1,1,2
394,9,45,1,4,
394,9,54,1,42,
394,9,56,1,51,
394,9,57,4,0,
394,9,58,4,0,
394,9,59,4,0,
394,9,61,1,19,
394,9,64,1,15,
394,9,65,1,46,
394,9,70,4,0,
394,9,91,4,0,
394,9,92,4,0,
394,9,104,4,0,
394,9,117,1,24,
394,9,127,4,0,
394,9,145,1,8,
394,9,156,4,0,
394,9,164,4,0,
394,9,173,3,0,
394,9,182,4,0,
394,9,189,3,0,
394,9,196,3,0,
394,9,203,4,0,
394,9,207,4,0,
394,9,213,4,0,
394,9,214,4,0,
394,9,216,4,0,
394,9,218,4,0,
394,9,232,1,16,
394,9,237,4,0,
394,9,240,4,0,
394,9,249,4,0,
394,9,250,1,37,
394,9,258,4,0,
394,9,263,4,0,
394,9,280,4,0,
394,9,290,4,0,
394,9,291,3,0,
394,9,317,4,0,
394,9,324,3,0,
394,9,332,4,0,
394,9,346,1,11,
394,9,352,4,0,
394,9,362,1,33,
394,9,362,4,0,
394,9,363,4,0,
394,9,365,4,0,
394,9,374,4,0,
394,9,421,4,0,
394,9,432,4,0,
394,9,445,4,0,
394,9,446,4,0,
394,9,447,4,0,
394,10,15,4,0,
394,10,29,3,0,
394,10,31,1,28,
394,10,33,1,1,1
394,10,45,1,1,2
394,10,45,1,4,
394,10,54,1,42,
394,10,56,1,51,
394,10,57,4,0,
394,10,58,4,0,
394,10,59,4,0,
394,10,61,1,19,
394,10,64,1,15,
394,10,65,1,46,
394,10,70,4,0,
394,10,91,4,0,
394,10,92,4,0,
394,10,104,4,0,
394,10,117,1,24,
394,10,127,4,0,
394,10,145,1,8,
394,10,156,4,0,
394,10,164,4,0,
394,10,173,3,0,
394,10,182,4,0,
394,10,189,3,0,
394,10,196,3,0,
394,10,203,4,0,
394,10,207,4,0,
394,10,213,4,0,
394,10,214,4,0,
394,10,216,4,0,
394,10,218,4,0,
394,10,232,1,16,
394,10,237,4,0,
394,10,240,4,0,
394,10,249,4,0,
394,10,250,1,37,
394,10,250,4,0,
394,10,258,4,0,
394,10,263,4,0,
394,10,280,4,0,
394,10,290,4,0,
394,10,291,3,0,
394,10,317,4,0,
394,10,324,3,0,
394,10,332,4,0,
394,10,346,1,11,
394,10,352,4,0,
394,10,362,1,33,
394,10,362,4,0,
394,10,363,4,0,
394,10,365,4,0,
394,10,374,4,0,
394,10,421,4,0,
394,10,445,4,0,
394,10,446,4,0,
394,10,447,4,0,
394,11,15,4,0,
394,11,31,1,28,
394,11,33,1,1,1
394,11,45,1,1,2
394,11,45,1,4,
394,11,54,1,42,
394,11,56,1,51,
394,11,57,4,0,
394,11,58,4,0,
394,11,59,4,0,
394,11,61,1,19,
394,11,64,1,15,
394,11,65,1,46,
394,11,70,4,0,
394,11,91,4,0,
394,11,92,4,0,
394,11,104,4,0,
394,11,117,1,24,
394,11,127,4,0,
394,11,145,1,8,
394,11,156,4,0,
394,11,164,4,0,
394,11,182,4,0,
394,11,207,4,0,
394,11,213,4,0,
394,11,216,4,0,
394,11,218,4,0,
394,11,232,1,16,
394,11,237,4,0,
394,11,240,4,0,
394,11,249,4,0,
394,11,250,1,37,
394,11,258,4,0,
394,11,263,4,0,
394,11,280,4,0,
394,11,291,4,0,
394,11,317,4,0,
394,11,332,4,0,
394,11,346,1,11,
394,11,362,1,33,
394,11,365,4,0,
394,11,374,4,0,
394,11,421,4,0,
394,11,447,4,0,
394,11,468,4,0,
394,11,496,4,0,
394,11,497,4,0,
394,11,503,4,0,
394,11,511,4,0,
394,11,518,3,0,
394,14,15,4,0,
394,14,31,1,28,
394,14,33,1,1,1
394,14,45,1,1,2
394,14,45,1,4,
394,14,54,1,42,
394,14,56,1,51,
394,14,57,4,0,
394,14,58,4,0,
394,14,59,4,0,
394,14,61,1,19,
394,14,64,1,15,
394,14,65,1,46,
394,14,70,4,0,
394,14,91,4,0,
394,14,92,4,0,
394,14,104,4,0,
394,14,117,1,24,
394,14,127,4,0,
394,14,145,1,8,
394,14,156,4,0,
394,14,164,4,0,
394,14,173,3,0,
394,14,182,4,0,
394,14,196,3,0,
394,14,207,4,0,
394,14,213,4,0,
394,14,214,3,0,
394,14,216,4,0,
394,14,218,4,0,
394,14,232,1,16,
394,14,237,4,0,
394,14,240,4,0,
394,14,249,4,0,
394,14,250,1,37,
394,14,258,4,0,
394,14,263,4,0,
394,14,280,4,0,
394,14,291,4,0,
394,14,317,4,0,
394,14,324,3,0,
394,14,332,4,0,
394,14,343,3,0,
394,14,346,1,11,
394,14,362,1,33,
394,14,365,4,0,
394,14,374,4,0,
394,14,421,4,0,
394,14,446,3,0,
394,14,447,4,0,
394,14,468,4,0,
394,14,496,4,0,
394,14,497,4,0,
394,14,503,4,0,
394,14,511,4,0,
394,14,518,3,0,
394,15,15,4,0,
394,15,31,1,28,
394,15,33,1,1,1
394,15,45,1,1,2
394,15,45,1,4,
394,15,54,1,42,
394,15,56,1,50,
394,15,57,4,0,
394,15,58,4,0,
394,15,59,4,0,
394,15,61,1,19,
394,15,64,1,15,
394,15,65,1,46,
394,15,70,4,0,
394,15,91,4,0,
394,15,92,4,0,
394,15,104,4,0,
394,15,117,1,24,
394,15,127,4,0,
394,15,145,1,8,
394,15,156,4,0,
394,15,164,4,0,
394,15,182,4,0,
394,15,207,4,0,
394,15,213,4,0,
394,15,214,4,0,
394,15,216,4,0,
394,15,218,4,0,
394,15,232,1,16,
394,15,237,4,0,
394,15,240,4,0,
394,15,249,4,0,
394,15,250,1,37,
394,15,258,4,0,
394,15,263,4,0,
394,15,280,4,0,
394,15,317,4,0,
394,15,332,4,0,
394,15,346,1,11,
394,15,362,1,33,
394,15,374,4,0,
394,15,421,4,0,
394,15,447,4,0,
394,15,468,4,0,
394,15,496,4,0,
394,15,497,4,0,
394,15,503,4,0,
394,15,511,4,0,
394,15,518,3,0,
394,15,590,4,0,
394,16,15,4,0,
394,16,31,1,28,1
394,16,33,1,1,1
394,16,45,1,1,2
394,16,45,1,4,1
394,16,54,1,42,1
394,16,56,1,50,1
394,16,57,4,0,
394,16,58,4,0,
394,16,59,4,0,
394,16,61,1,19,1
394,16,64,1,15,1
394,16,65,1,46,1
394,16,70,4,0,
394,16,91,4,0,
394,16,92,4,0,
394,16,104,4,0,
394,16,117,1,24,1
394,16,127,4,0,
394,16,145,1,8,1
394,16,156,4,0,
394,16,164,4,0,
394,16,173,3,0,
394,16,182,4,0,
394,16,196,3,0,
394,16,207,4,0,
394,16,213,4,0,
394,16,214,4,0,
394,16,216,4,0,
394,16,218,4,0,
394,16,232,1,16,1
394,16,237,4,0,
394,16,240,4,0,
394,16,249,4,0,
394,16,250,1,37,1
394,16,258,4,0,
394,16,263,4,0,
394,16,280,4,0,
394,16,290,4,0,
394,16,291,4,0,
394,16,317,4,0,
394,16,324,3,0,
394,16,332,4,0,
394,16,343,3,0,
394,16,346,1,11,1
394,16,352,3,0,
394,16,362,1,33,1
394,16,374,4,0,
394,16,421,4,0,
394,16,446,3,0,
394,16,447,4,0,
394,16,468,4,0,
394,16,496,4,0,
394,16,497,4,0,
394,16,503,4,0,
394,16,511,4,0,
394,16,518,3,0,
394,16,590,4,0,
394,17,31,1,28,
394,17,33,1,1,2
394,17,45,1,1,3
394,17,45,1,4,
394,17,54,1,42,
394,17,56,1,50,
394,17,57,4,0,
394,17,58,4,0,
394,17,59,4,0,
394,17,61,1,19,
394,17,64,1,15,
394,17,65,1,46,
394,17,92,4,0,
394,17,104,4,0,
394,17,117,1,24,
394,17,127,4,0,
394,17,145,1,1,4
394,17,145,1,8,
394,17,156,4,0,
394,17,164,4,0,
394,17,182,4,0,
394,17,207,4,0,
394,17,213,4,0,
394,17,214,4,0,
394,17,216,4,0,
394,17,218,4,0,
394,17,232,1,0,
394,17,232,1,1,1
394,17,237,4,0,
394,17,240,4,0,
394,17,250,1,37,
394,17,258,4,0,
394,17,263,4,0,
394,17,280,4,0,
394,17,317,4,0,
394,17,332,4,0,
394,17,346,1,11,
394,17,362,1,33,
394,17,374,4,0,
394,17,421,4,0,
394,17,447,4,0,
394,17,496,4,0,
394,17,497,4,0,
394,17,503,4,0,
394,17,511,4,0,
394,17,518,3,0,
394,17,526,4,0,
394,17,590,4,0,
394,18,31,1,28,
394,18,33,1,1,2
394,18,45,1,1,3
394,18,45,1,4,
394,18,54,1,42,
394,18,56,1,50,
394,18,57,4,0,
394,18,58,4,0,
394,18,59,4,0,
394,18,61,1,19,
394,18,64,1,15,
394,18,65,1,46,
394,18,92,4,0,
394,18,104,4,0,
394,18,117,1,24,
394,18,127,4,0,
394,18,145,1,1,4
394,18,145,1,8,
394,18,156,4,0,
394,18,164,4,0,
394,18,182,4,0,
394,18,207,4,0,
394,18,213,4,0,
394,18,214,4,0,
394,18,216,4,0,
394,18,218,4,0,
394,18,232,1,0,
394,18,232,1,1,1
394,18,237,4,0,
394,18,240,4,0,
394,18,250,1,37,
394,18,258,4,0,
394,18,263,4,0,
394,18,280,4,0,
394,18,317,4,0,
394,18,332,4,0,
394,18,346,1,11,
394,18,362,1,33,
394,18,374,4,0,
394,18,421,4,0,
394,18,447,4,0,
394,18,496,4,0,
394,18,497,4,0,
394,18,503,4,0,
394,18,511,4,0,
394,18,518,3,0,
394,18,526,4,0,
394,18,590,4,0,
395,8,14,1,11,
395,8,14,4,0,
395,8,15,4,0,
395,8,31,1,28,
395,8,33,1,1,1
395,8,45,1,1,2
395,8,45,1,4,
395,8,46,4,0,
395,8,54,1,46,
395,8,56,1,59,
395,8,57,4,0,
395,8,58,4,0,
395,8,59,4,0,
395,8,61,1,24,
395,8,63,4,0,
395,8,64,1,15,
395,8,65,1,52,
395,8,70,4,0,
395,8,89,4,0,
395,8,91,4,0,
395,8,92,4,0,
395,8,104,4,0,
395,8,127,4,0,
395,8,145,1,1,3
395,8,145,1,8,
395,8,156,4,0,
395,8,157,4,0,
395,8,164,4,0,
395,8,182,4,0,
395,8,203,4,0,
395,8,207,1,19,
395,8,207,4,0,
395,8,211,4,0,
395,8,213,4,0,
395,8,214,4,0,
395,8,216,4,0,
395,8,218,4,0,
395,8,232,1,16,
395,8,237,4,0,
395,8,240,4,0,
395,8,249,4,0,
395,8,250,1,39,
395,8,258,4,0,
395,8,263,4,0,
395,8,280,4,0,
395,8,290,4,0,
395,8,308,3,0,
395,8,317,4,0,
395,8,332,4,0,
395,8,352,4,0,
395,8,362,1,33,
395,8,362,4,0,
395,8,363,4,0,
395,8,365,4,0,
395,8,374,4,0,
395,8,416,4,0,
395,8,419,4,0,
395,8,421,4,0,
395,8,430,4,0,
395,8,431,4,0,
395,8,432,4,0,
395,8,445,4,0,
395,8,446,4,0,
395,8,447,4,0,
395,8,453,1,36,
395,9,14,1,11,
395,9,14,4,0,
395,9,15,4,0,
395,9,31,1,28,
395,9,33,1,1,1
395,9,45,1,1,2
395,9,45,1,4,
395,9,46,4,0,
395,9,54,1,46,
395,9,56,1,59,
395,9,57,4,0,
395,9,58,4,0,
395,9,59,4,0,
395,9,61,1,19,
395,9,63,4,0,
395,9,64,1,15,
395,9,65,1,52,
395,9,70,4,0,
395,9,89,4,0,
395,9,91,4,0,
395,9,92,4,0,
395,9,104,4,0,
395,9,127,4,0,
395,9,145,1,1,3
395,9,145,1,8,
395,9,156,4,0,
395,9,157,4,0,
395,9,164,4,0,
395,9,173,3,0,
395,9,182,4,0,
395,9,189,3,0,
395,9,196,3,0,
395,9,203,4,0,
395,9,207,1,24,
395,9,207,4,0,
395,9,210,3,0,
395,9,211,4,0,
395,9,213,4,0,
395,9,214,4,0,
395,9,216,4,0,
395,9,218,4,0,
395,9,232,1,16,
395,9,237,4,0,
395,9,240,4,0,
395,9,249,4,0,
395,9,250,1,39,
395,9,258,4,0,
395,9,263,4,0,
395,9,280,4,0,
395,9,282,3,0,
395,9,290,4,0,
395,9,291,3,0,
395,9,308,3,0,
395,9,317,4,0,
395,9,324,3,0,
395,9,332,4,0,
395,9,334,3,0,
395,9,352,4,0,
395,9,362,1,33,
395,9,362,4,0,
395,9,363,4,0,
395,9,365,4,0,
395,9,374,4,0,
395,9,416,4,0,
395,9,419,4,0,
395,9,421,4,0,
395,9,430,4,0,
395,9,431,4,0,
395,9,432,4,0,
395,9,445,4,0,
395,9,446,4,0,
395,9,447,4,0,
395,9,453,1,36,
395,10,14,1,11,
395,10,14,4,0,
395,10,15,4,0,
395,10,29,3,0,
395,10,31,1,28,
395,10,33,1,1,1
395,10,45,1,1,2
395,10,45,1,4,
395,10,46,4,0,
395,10,54,1,46,
395,10,56,1,59,
395,10,57,4,0,
395,10,58,4,0,
395,10,59,4,0,
395,10,61,1,19,
395,10,63,4,0,
395,10,64,1,15,
395,10,65,1,52,
395,10,70,4,0,
395,10,89,4,0,
395,10,91,4,0,
395,10,92,4,0,
395,10,104,4,0,
395,10,127,4,0,
395,10,145,1,1,3
395,10,145,1,8,
395,10,156,4,0,
395,10,157,4,0,
395,10,164,4,0,
395,10,173,3,0,
395,10,182,4,0,
395,10,189,3,0,
395,10,196,3,0,
395,10,203,4,0,
395,10,207,1,24,
395,10,207,4,0,
395,10,210,3,0,
395,10,211,4,0,
395,10,213,4,0,
395,10,214,4,0,
395,10,216,4,0,
395,10,218,4,0,
395,10,232,1,16,
395,10,237,4,0,
395,10,240,4,0,
395,10,249,4,0,
395,10,250,1,39,
395,10,250,4,0,
395,10,258,4,0,
395,10,263,4,0,
395,10,280,4,0,
395,10,282,3,0,
395,10,290,4,0,
395,10,291,3,0,
395,10,308,3,0,
395,10,317,4,0,
395,10,324,3,0,
395,10,332,4,0,
395,10,334,3,0,
395,10,352,4,0,
395,10,362,1,33,
395,10,362,4,0,
395,10,363,4,0,
395,10,365,4,0,
395,10,374,4,0,
395,10,416,4,0,
395,10,419,4,0,
395,10,421,4,0,
395,10,430,4,0,
395,10,431,4,0,
395,10,445,4,0,
395,10,446,4,0,
395,10,447,4,0,
395,10,453,1,36,
395,11,14,1,11,
395,11,14,4,0,
395,11,15,4,0,
395,11,31,1,28,
395,11,33,1,1,1
395,11,45,1,1,2
395,11,45,1,4,
395,11,46,4,0,
395,11,54,1,46,
395,11,56,1,59,
395,11,57,4,0,
395,11,58,4,0,
395,11,59,4,0,
395,11,61,1,19,
395,11,63,4,0,
395,11,64,1,15,
395,11,65,1,52,
395,11,70,4,0,
395,11,89,4,0,
395,11,91,4,0,
395,11,92,4,0,
395,11,104,4,0,
395,11,127,4,0,
395,11,145,1,1,3
395,11,145,1,8,
395,11,156,4,0,
395,11,157,4,0,
395,11,164,4,0,
395,11,182,4,0,
395,11,207,1,24,
395,11,207,4,0,
395,11,213,4,0,
395,11,216,4,0,
395,11,218,4,0,
395,11,232,1,16,
395,11,237,4,0,
395,11,240,4,0,
395,11,249,4,0,
395,11,250,1,39,
395,11,258,4,0,
395,11,263,4,0,
395,11,280,4,0,
395,11,291,4,0,
395,11,308,3,0,
395,11,317,4,0,
395,11,332,4,0,
395,11,362,1,33,
395,11,365,4,0,
395,11,374,4,0,
395,11,416,4,0,
395,11,421,4,0,
395,11,430,4,0,
395,11,447,4,0,
395,11,453,1,36,
395,11,468,4,0,
395,11,496,4,0,
395,11,497,4,0,
395,11,503,4,0,
395,11,511,4,0,
395,11,518,3,0,
395,11,523,4,0,
395,14,14,1,11,
395,14,14,4,0,
395,14,15,4,0,
395,14,31,1,28,
395,14,33,1,1,1
395,14,45,1,1,2
395,14,45,1,4,
395,14,46,4,0,
395,14,54,1,46,
395,14,56,1,59,
395,14,57,4,0,
395,14,58,4,0,
395,14,59,4,0,
395,14,61,1,19,
395,14,63,4,0,
395,14,64,1,15,
395,14,65,1,52,
395,14,70,4,0,
395,14,89,4,0,
395,14,91,4,0,
395,14,92,4,0,
395,14,104,4,0,
395,14,127,4,0,
395,14,145,1,1,3
395,14,145,1,8,
395,14,156,4,0,
395,14,157,4,0,
395,14,164,4,0,
395,14,173,3,0,
395,14,182,4,0,
395,14,196,3,0,
395,14,207,1,24,
395,14,207,4,0,
395,14,213,4,0,
395,14,214,3,0,
395,14,216,4,0,
395,14,218,4,0,
395,14,232,1,16,
395,14,237,4,0,
395,14,240,4,0,
395,14,249,4,0,
395,14,250,1,39,
395,14,258,4,0,
395,14,263,4,0,
395,14,280,4,0,
395,14,282,3,0,
395,14,291,4,0,
395,14,308,3,0,
395,14,317,4,0,
395,14,324,3,0,
395,14,332,4,0,
395,14,334,3,0,
395,14,343,3,0,
395,14,362,1,33,
395,14,365,4,0,
395,14,374,4,0,
395,14,416,4,0,
395,14,421,4,0,
395,14,430,4,0,
395,14,446,3,0,
395,14,447,4,0,
395,14,453,1,36,
395,14,468,4,0,
395,14,496,4,0,
395,14,497,4,0,
395,14,503,4,0,
395,14,511,4,0,
395,14,518,3,0,
395,14,523,4,0,
395,15,14,1,11,
395,15,14,4,0,
395,15,15,4,0,
395,15,31,1,28,
395,15,33,1,1,1
395,15,45,1,1,2
395,15,45,1,4,
395,15,46,4,0,
395,15,54,1,46,
395,15,56,1,59,
395,15,57,4,0,
395,15,58,4,0,
395,15,59,4,0,
395,15,61,1,19,
395,15,63,4,0,
395,15,64,1,15,
395,15,65,1,52,
395,15,70,4,0,
395,15,89,4,0,
395,15,91,4,0,
395,15,92,4,0,
395,15,104,4,0,
395,15,127,4,0,
395,15,145,1,1,3
395,15,145,1,8,
395,15,156,4,0,
395,15,157,4,0,
395,15,164,4,0,
395,15,182,4,0,
395,15,207,1,24,
395,15,207,4,0,
395,15,211,4,0,
395,15,213,4,0,
395,15,214,4,0,
395,15,216,4,0,
395,15,218,4,0,
395,15,232,1,16,
395,15,237,4,0,
395,15,240,4,0,
395,15,249,4,0,
395,15,250,1,39,
395,15,258,4,0,
395,15,263,4,0,
395,15,280,4,0,
395,15,308,3,0,
395,15,317,4,0,
395,15,332,4,0,
395,15,362,1,33,
395,15,374,4,0,
395,15,416,4,0,
395,15,421,4,0,
395,15,430,4,0,
395,15,447,4,0,
395,15,453,1,36,
395,15,468,4,0,
395,15,496,4,0,
395,15,497,4,0,
395,15,503,4,0,
395,15,511,4,0,
395,15,518,3,0,
395,15,523,4,0,
395,15,590,4,0,
395,16,14,1,11,1
395,16,14,4,0,
395,16,15,4,0,
395,16,31,1,28,1
395,16,33,1,1,1
395,16,45,1,1,2
395,16,45,1,4,1
395,16,46,4,0,
395,16,54,1,46,1
395,16,56,1,59,1
395,16,57,4,0,
395,16,58,4,0,
395,16,59,4,0,
395,16,61,1,19,1
395,16,63,4,0,
395,16,64,1,15,1
395,16,65,1,52,1
395,16,70,4,0,
395,16,89,4,0,
395,16,91,4,0,
395,16,92,4,0,
395,16,104,4,0,
395,16,127,4,0,
395,16,145,1,1,3
395,16,145,1,8,1
395,16,156,4,0,
395,16,157,4,0,
395,16,164,4,0,
395,16,173,3,0,
395,16,182,4,0,
395,16,196,3,0,
395,16,207,1,24,1
395,16,207,4,0,
395,16,211,4,0,
395,16,213,4,0,
395,16,214,4,0,
395,16,216,4,0,
395,16,218,4,0,
395,16,232,1,16,1
395,16,237,4,0,
395,16,240,4,0,
395,16,249,4,0,
395,16,250,1,39,1
395,16,258,4,0,
395,16,263,4,0,
395,16,280,4,0,
395,16,282,3,0,
395,16,290,4,0,
395,16,291,4,0,
395,16,308,3,0,
395,16,317,4,0,
395,16,324,3,0,
395,16,332,4,0,
395,16,334,3,0,
395,16,343,3,0,
395,16,352,3,0,
395,16,362,1,33,1
395,16,374,4,0,
395,16,416,4,0,
395,16,421,4,0,
395,16,430,4,0,
395,16,446,3,0,
395,16,447,4,0,
395,16,453,1,36,1
395,16,468,4,0,
395,16,496,4,0,
395,16,497,4,0,
395,16,503,4,0,
395,16,511,4,0,
395,16,518,3,0,
395,16,523,4,0,
395,16,590,4,0,
395,17,14,1,11,
395,17,14,4,0,
395,17,31,1,28,
395,17,33,1,1,3
395,17,45,1,1,4
395,17,45,1,4,
395,17,46,4,0,
395,17,54,1,46,
395,17,56,1,59,
395,17,57,4,0,
395,17,58,4,0,
395,17,59,4,0,
395,17,61,1,19,
395,17,63,4,0,
395,17,64,1,15,
395,17,65,1,52,
395,17,89,4,0,
395,17,92,4,0,
395,17,104,4,0,
395,17,127,4,0,
395,17,145,1,1,5
395,17,145,1,8,
395,17,156,4,0,
395,17,157,4,0,
395,17,164,4,0,
395,17,182,4,0,
395,17,207,1,24,
395,17,207,4,0,
395,17,211,4,0,
395,17,213,4,0,
395,17,214,4,0,
395,17,216,4,0,
395,17,218,4,0,
395,17,232,1,1,2
395,17,237,4,0,
395,17,240,4,0,
395,17,250,1,39,
395,17,258,4,0,
395,17,263,4,0,
395,17,280,4,0,
395,17,308,3,0,
395,17,317,4,0,
395,17,332,4,0,
395,17,362,1,33,
395,17,374,4,0,
395,17,416,4,0,
395,17,421,4,0,
395,17,430,4,0,
395,17,447,4,0,
395,17,453,1,0,
395,17,453,1,1,1
395,17,496,4,0,
395,17,497,4,0,
395,17,503,4,0,
395,17,511,4,0,
395,17,518,3,0,
395,17,523,4,0,
395,17,526,4,0,
395,17,590,4,0,
395,18,14,1,11,
395,18,14,4,0,
395,18,31,1,28,
395,18,33,1,1,3
395,18,45,1,1,4
395,18,45,1,4,
395,18,46,4,0,
395,18,54,1,46,
395,18,56,1,59,
395,18,57,4,0,
395,18,58,4,0,
395,18,59,4,0,
395,18,61,1,19,
395,18,63,4,0,
395,18,64,1,15,
395,18,65,1,52,
395,18,89,4,0,
395,18,92,4,0,
395,18,104,4,0,
395,18,127,4,0,
395,18,145,1,1,5
395,18,145,1,8,
395,18,156,4,0,
395,18,157,4,0,
395,18,164,4,0,
395,18,182,4,0,
395,18,207,1,24,
395,18,207,4,0,
395,18,211,4,0,
395,18,213,4,0,
395,18,214,4,0,
395,18,216,4,0,
395,18,218,4,0,
395,18,232,1,1,2
395,18,237,4,0,
395,18,240,4,0,
395,18,250,1,39,
395,18,258,4,0,
395,18,263,4,0,
395,18,280,4,0,
395,18,308,3,0,
395,18,317,4,0,
395,18,332,4,0,
395,18,362,1,33,
395,18,374,4,0,
395,18,416,4,0,
395,18,421,4,0,
395,18,430,4,0,
395,18,447,4,0,
395,18,453,1,0,
395,18,453,1,1,1
395,18,496,4,0,
395,18,497,4,0,
395,18,503,4,0,
395,18,511,4,0,
395,18,518,3,0,
395,18,523,4,0,
395,18,526,4,0,
395,18,590,4,0,
396,8,17,1,9,
396,8,18,1,21,
396,8,19,4,0,
396,8,28,2,0,
396,8,31,2,0,
396,8,33,1,1,1
396,8,36,1,29,
396,8,38,2,0,
396,8,45,1,1,2
396,8,92,4,0,
396,8,97,1,33,
396,8,98,1,5,
396,8,104,1,13,
396,8,104,4,0,
396,8,156,4,0,
396,8,164,4,0,
396,8,168,4,0,
396,8,182,4,0,
396,8,193,2,0,
396,8,203,4,0,
396,8,207,4,0,
396,8,211,4,0,
396,8,213,4,0,
396,8,214,4,0,
396,8,216,4,0,
396,8,218,4,0,
396,8,228,2,0,
396,8,237,4,0,
396,8,240,4,0,
396,8,241,4,0,
396,8,263,4,0,
396,8,283,1,17,
396,8,290,4,0,
396,8,297,2,0,
396,8,310,2,0,
396,8,332,1,25,
396,8,332,4,0,
396,8,355,4,0,
396,8,363,4,0,
396,8,365,4,0,
396,8,369,4,0,
396,8,413,1,37,
396,8,432,4,0,
396,8,445,4,0,
396,9,17,1,9,
396,9,18,1,21,
396,9,19,4,0,
396,9,28,2,0,
396,9,31,2,0,
396,9,33,1,1,1
396,9,36,1,29,
396,9,38,2,0,
396,9,45,1,1,2
396,9,92,4,0,
396,9,97,1,33,
396,9,98,1,5,
396,9,104,1,13,
396,9,104,4,0,
396,9,129,3,0,
396,9,156,4,0,
396,9,164,4,0,
396,9,168,4,0,
396,9,173,3,0,
396,9,182,4,0,
396,9,189,3,0,
396,9,193,2,0,
396,9,203,4,0,
396,9,207,4,0,
396,9,211,4,0,
396,9,213,4,0,
396,9,214,4,0,
396,9,216,4,0,
396,9,218,4,0,
396,9,228,2,0,
396,9,237,4,0,
396,9,239,3,0,
396,9,240,4,0,
396,9,241,4,0,
396,9,257,3,0,
396,9,263,4,0,
396,9,283,1,17,
396,9,283,3,0,
396,9,290,4,0,
396,9,297,2,0,
396,9,310,2,0,
396,9,314,3,0,
396,9,332,1,25,
396,9,332,4,0,
396,9,355,4,0,
396,9,363,4,0,
396,9,365,4,0,
396,9,369,4,0,
396,9,413,1,37,
396,9,432,4,0,
396,9,445,4,0,
396,9,466,3,0,
396,10,17,1,9,
396,10,18,1,21,
396,10,19,4,0,
396,10,28,2,0,
396,10,31,2,0,
396,10,33,1,1,1
396,10,36,1,29,
396,10,38,2,0,
396,10,45,1,1,2
396,10,92,4,0,
396,10,97,1,33,
396,10,98,1,5,
396,10,104,1,13,
396,10,104,4,0,
396,10,129,3,0,
396,10,156,4,0,
396,10,164,4,0,
396,10,168,4,0,
396,10,173,3,0,
396,10,182,4,0,
396,10,189,3,0,
396,10,193,2,0,
396,10,203,4,0,
396,10,207,4,0,
396,10,211,4,0,
396,10,213,4,0,
396,10,214,4,0,
396,10,216,4,0,
396,10,218,4,0,
396,10,228,2,0,
396,10,237,4,0,
396,10,239,3,0,
396,10,240,4,0,
396,10,241,4,0,
396,10,257,3,0,
396,10,263,4,0,
396,10,283,1,17,
396,10,283,3,0,
396,10,290,4,0,
396,10,297,2,0,
396,10,310,2,0,
396,10,314,3,0,
396,10,332,1,25,
396,10,332,4,0,
396,10,355,4,0,
396,10,363,4,0,
396,10,365,4,0,
396,10,366,3,0,
396,10,369,4,0,
396,10,413,1,37,
396,10,445,4,0,
396,10,466,3,0,
396,11,17,1,9,
396,11,18,1,21,
396,11,19,4,0,
396,11,28,2,0,
396,11,31,2,0,
396,11,33,1,1,1
396,11,36,1,29,
396,11,38,2,0,
396,11,45,1,1,2
396,11,92,4,0,
396,11,97,1,33,
396,11,98,1,5,
396,11,104,1,13,
396,11,104,4,0,
396,11,156,4,0,
396,11,164,4,0,
396,11,168,4,0,
396,11,182,4,0,
396,11,193,2,0,
396,11,197,2,0,
396,11,207,4,0,
396,11,211,2,0,
396,11,213,4,0,
396,11,216,4,0,
396,11,218,4,0,
396,11,228,2,0,
396,11,237,4,0,
396,11,240,4,0,
396,11,241,4,0,
396,11,253,2,0,
396,11,263,4,0,
396,11,279,2,0,
396,11,283,1,17,
396,11,297,2,0,
396,11,310,2,0,
396,11,332,1,25,
396,11,332,4,0,
396,11,355,2,0,
396,11,365,4,0,
396,11,369,4,0,
396,11,413,1,37,
396,11,496,4,0,
396,11,497,4,0,
396,11,515,1,41,
396,11,526,4,0,
396,14,17,1,9,
396,14,18,1,21,
396,14,19,4,0,
396,14,28,2,0,
396,14,31,2,0,
396,14,33,1,1,1
396,14,36,1,29,
396,14,38,2,0,
396,14,45,1,1,2
396,14,92,4,0,
396,14,97,1,33,
396,14,98,1,5,
396,14,104,1,13,
396,14,104,4,0,
396,14,156,4,0,
396,14,164,4,0,
396,14,168,4,0,
396,14,173,3,0,
396,14,182,4,0,
396,14,193,2,0,
396,14,197,2,0,
396,14,207,4,0,
396,14,211,2,0,
396,14,213,4,0,
396,14,214,3,0,
396,14,216,4,0,
396,14,218,4,0,
396,14,228,2,0,
396,14,237,4,0,
396,14,240,4,0,
396,14,241,4,0,
396,14,253,2,0,
396,14,257,3,0,
396,14,263,4,0,
396,14,279,2,0,
396,14,283,1,17,
396,14,283,3,0,
396,14,297,2,0,
396,14,310,2,0,
396,14,332,1,25,
396,14,332,4,0,
396,14,355,2,0,
396,14,355,3,0,
396,14,365,4,0,
396,14,366,3,0,
396,14,369,4,0,
396,14,413,1,37,
396,14,496,4,0,
396,14,497,4,0,
396,14,515,1,41,
396,14,526,4,0,
396,15,17,1,9,
396,15,18,1,21,
396,15,19,4,0,
396,15,28,2,0,
396,15,31,2,0,
396,15,33,1,1,1
396,15,36,1,29,
396,15,38,2,0,
396,15,45,1,1,2
396,15,92,4,0,
396,15,97,1,33,
396,15,98,1,5,
396,15,104,1,13,
396,15,104,4,0,
396,15,119,2,0,
396,15,156,4,0,
396,15,164,4,0,
396,15,168,4,0,
396,15,182,4,0,
396,15,193,2,0,
396,15,197,2,0,
396,15,207,4,0,
396,15,211,2,0,
396,15,211,4,0,
396,15,213,4,0,
396,15,214,4,0,
396,15,216,4,0,
396,15,218,4,0,
396,15,228,2,0,
396,15,237,4,0,
396,15,240,4,0,
396,15,241,4,0,
396,15,253,2,0,
396,15,263,4,0,
396,15,279,2,0,
396,15,283,1,17,
396,15,297,2,0,
396,15,310,2,0,
396,15,332,1,25,
396,15,332,4,0,
396,15,355,2,0,
396,15,355,4,0,
396,15,369,4,0,
396,15,413,1,37,
396,15,496,4,0,
396,15,497,4,0,
396,15,515,1,41,
396,15,590,4,0,
396,16,17,1,9,1
396,16,18,1,21,1
396,16,19,4,0,
396,16,28,2,0,
396,16,31,2,0,
396,16,33,1,1,1
396,16,36,1,29,1
396,16,38,2,0,
396,16,45,1,1,2
396,16,92,4,0,
396,16,97,1,33,1
396,16,98,1,5,1
396,16,104,1,13,1
396,16,104,4,0,
396,16,119,2,0,
396,16,156,4,0,
396,16,164,4,0,
396,16,168,4,0,
396,16,173,3,0,
396,16,182,4,0,
396,16,193,2,0,
396,16,197,2,0,
396,16,207,4,0,
396,16,211,2,0,
396,16,211,4,0,
396,16,213,4,0,
396,16,214,4,0,
396,16,216,4,0,
396,16,218,4,0,
396,16,228,2,0,
396,16,237,4,0,
396,16,240,4,0,
396,16,241,4,0,
396,16,253,2,0,
396,16,253,3,0,
396,16,257,3,0,
396,16,263,4,0,
396,16,279,2,0,
396,16,283,1,17,1
396,16,283,3,0,
396,16,290,4,0,
396,16,297,2,0,
396,16,310,2,0,
396,16,332,1,25,1
396,16,332,4,0,
396,16,355,2,0,
396,16,355,4,0,
396,16,366,3,0,
396,16,369,4,0,
396,16,413,1,37,1
396,16,496,4,0,
396,16,497,4,0,
396,16,515,1,41,1
396,16,590,4,0,
396,17,17,1,9,
396,17,18,1,21,
396,17,19,4,0,
396,17,28,2,0,
396,17,31,2,0,
396,17,33,1,1,1
396,17,36,1,29,
396,17,38,2,0,
396,17,45,1,1,2
396,17,92,4,0,
396,17,97,1,33,
396,17,98,1,5,
396,17,104,1,13,
396,17,104,4,0,
396,17,119,2,0,
396,17,156,4,0,
396,17,164,4,0,
396,17,168,4,0,
396,17,182,4,0,
396,17,193,2,0,
396,17,197,2,0,
396,17,207,4,0,
396,17,211,2,0,
396,17,211,4,0,
396,17,213,4,0,
396,17,214,4,0,
396,17,216,4,0,
396,17,218,4,0,
396,17,228,2,0,
396,17,237,4,0,
396,17,240,4,0,
396,17,241,4,0,
396,17,253,2,0,
396,17,263,4,0,
396,17,279,2,0,
396,17,283,1,17,
396,17,297,2,0,
396,17,310,2,0,
396,17,332,1,25,
396,17,332,4,0,
396,17,355,2,0,
396,17,355,4,0,
396,17,369,4,0,
396,17,413,1,37,
396,17,496,4,0,
396,17,497,4,0,
396,17,515,1,41,
396,17,526,4,0,
396,17,590,4,0,
396,18,17,1,9,
396,18,18,1,21,
396,18,19,4,0,
396,18,28,2,0,
396,18,31,2,0,
396,18,33,1,1,1
396,18,36,1,29,
396,18,38,2,0,
396,18,45,1,1,2
396,18,92,4,0,
396,18,97,1,33,
396,18,98,1,5,
396,18,104,1,13,
396,18,104,4,0,
396,18,119,2,0,
396,18,156,4,0,
396,18,164,4,0,
396,18,168,4,0,
396,18,182,4,0,
396,18,193,2,0,
396,18,197,2,0,
396,18,207,4,0,
396,18,211,2,0,
396,18,211,4,0,
396,18,213,4,0,
396,18,214,4,0,
396,18,216,4,0,
396,18,218,4,0,
396,18,228,2,0,
396,18,237,4,0,
396,18,240,4,0,
396,18,241,4,0,
396,18,253,2,0,
396,18,263,4,0,
396,18,279,2,0,
396,18,283,1,17,
396,18,297,2,0,
396,18,310,2,0,
396,18,332,1,25,
396,18,332,4,0,
396,18,355,2,0,
396,18,355,4,0,
396,18,369,4,0,
396,18,413,1,37,
396,18,496,4,0,
396,18,497,4,0,
396,18,515,1,41,
396,18,526,4,0,
396,18,590,4,0,
397,8,17,1,9,
397,8,18,1,23,
397,8,19,4,0,
397,8,33,1,1,1
397,8,36,1,33,
397,8,45,1,1,2
397,8,92,4,0,
397,8,97,1,38,
397,8,98,1,1,3
397,8,98,1,5,
397,8,104,1,13,
397,8,104,4,0,
397,8,156,4,0,
397,8,164,4,0,
397,8,168,4,0,
397,8,182,4,0,
397,8,203,4,0,
397,8,207,4,0,
397,8,211,4,0,
397,8,213,4,0,
397,8,214,4,0,
397,8,216,4,0,
397,8,218,4,0,
397,8,237,4,0,
397,8,240,4,0,
397,8,241,4,0,
397,8,263,4,0,
397,8,283,1,18,
397,8,290,4,0,
397,8,332,1,28,
397,8,332,4,0,
397,8,355,4,0,
397,8,363,4,0,
397,8,365,4,0,
397,8,369,4,0,
397,8,413,1,43,
397,8,432,4,0,
397,8,445,4,0,
397,9,17,1,9,
397,9,18,1,23,
397,9,19,4,0,
397,9,33,1,1,1
397,9,36,1,33,
397,9,45,1,1,2
397,9,92,4,0,
397,9,97,1,38,
397,9,98,1,1,3
397,9,98,1,5,
397,9,104,1,13,
397,9,104,4,0,
397,9,129,3,0,
397,9,156,4,0,
397,9,164,4,0,
397,9,168,4,0,
397,9,173,3,0,
397,9,182,4,0,
397,9,189,3,0,
397,9,203,4,0,
397,9,207,4,0,
397,9,211,4,0,
397,9,213,4,0,
397,9,214,4,0,
397,9,216,4,0,
397,9,218,4,0,
397,9,237,4,0,
397,9,239,3,0,
397,9,240,4,0,
397,9,241,4,0,
397,9,257,3,0,
397,9,263,4,0,
397,9,283,1,18,
397,9,283,3,0,
397,9,290,4,0,
397,9,314,3,0,
397,9,332,1,28,
397,9,332,4,0,
397,9,355,4,0,
397,9,363,4,0,
397,9,365,4,0,
397,9,369,4,0,
397,9,413,1,43,
397,9,432,4,0,
397,9,445,4,0,
397,9,466,3,0,
397,10,17,1,9,
397,10,18,1,23,
397,10,19,4,0,
397,10,33,1,1,1
397,10,36,1,33,
397,10,45,1,1,2
397,10,92,4,0,
397,10,97,1,38,
397,10,98,1,1,3
397,10,98,1,5,
397,10,104,1,13,
397,10,104,4,0,
397,10,129,3,0,
397,10,156,4,0,
397,10,164,4,0,
397,10,168,4,0,
397,10,173,3,0,
397,10,182,4,0,
397,10,189,3,0,
397,10,203,4,0,
397,10,207,4,0,
397,10,211,4,0,
397,10,213,4,0,
397,10,214,4,0,
397,10,216,4,0,
397,10,218,4,0,
397,10,237,4,0,
397,10,239,3,0,
397,10,240,4,0,
397,10,241,4,0,
397,10,257,3,0,
397,10,263,4,0,
397,10,283,1,18,
397,10,283,3,0,
397,10,290,4,0,
397,10,314,3,0,
397,10,332,1,28,
397,10,332,4,0,
397,10,355,4,0,
397,10,363,4,0,
397,10,365,4,0,
397,10,366,3,0,
397,10,369,4,0,
397,10,413,1,43,
397,10,445,4,0,
397,10,466,3,0,
397,11,17,1,9,
397,11,18,1,23,
397,11,19,4,0,
397,11,33,1,1,1
397,11,36,1,33,
397,11,45,1,1,2
397,11,92,4,0,
397,11,97,1,38,
397,11,98,1,1,3
397,11,98,1,5,
397,11,104,1,13,
397,11,104,4,0,
397,11,156,4,0,
397,11,164,4,0,
397,11,168,4,0,
397,11,182,4,0,
397,11,207,4,0,
397,11,213,4,0,
397,11,216,4,0,
397,11,218,4,0,
397,11,237,4,0,
397,11,240,4,0,
397,11,241,4,0,
397,11,263,4,0,
397,11,283,1,18,
397,11,332,1,28,
397,11,332,4,0,
397,11,365,4,0,
397,11,369,4,0,
397,11,413,1,43,
397,11,496,4,0,
397,11,497,4,0,
397,11,514,4,0,
397,11,515,1,48,
397,11,526,4,0,
397,14,17,1,9,
397,14,18,1,23,
397,14,19,4,0,
397,14,33,1,1,1
397,14,36,1,33,
397,14,45,1,1,2
397,14,92,4,0,
397,14,97,1,38,
397,14,98,1,1,3
397,14,98,1,5,
397,14,104,1,13,
397,14,104,4,0,
397,14,156,4,0,
397,14,164,4,0,
397,14,168,4,0,
397,14,173,3,0,
397,14,182,4,0,
397,14,207,4,0,
397,14,213,4,0,
397,14,214,3,0,
397,14,216,4,0,
397,14,218,4,0,
397,14,237,4,0,
397,14,240,4,0,
397,14,241,4,0,
397,14,257,3,0,
397,14,263,4,0,
397,14,283,1,18,
397,14,283,3,0,
397,14,332,1,28,
397,14,332,4,0,
397,14,355,3,0,
397,14,365,4,0,
397,14,366,3,0,
397,14,369,4,0,
397,14,413,1,43,
397,14,496,4,0,
397,14,497,4,0,
397,14,514,4,0,
397,14,515,1,48,
397,14,526,4,0,
397,15,17,1,9,
397,15,18,1,23,
397,15,19,4,0,
397,15,33,1,1,1
397,15,36,1,33,
397,15,45,1,1,2
397,15,92,4,0,
397,15,97,1,38,
397,15,98,1,1,3
397,15,98,1,5,
397,15,104,1,13,
397,15,104,4,0,
397,15,156,4,0,
397,15,164,4,0,
397,15,168,4,0,
397,15,182,4,0,
397,15,207,4,0,
397,15,211,4,0,
397,15,213,4,0,
397,15,214,4,0,
397,15,216,4,0,
397,15,218,4,0,
397,15,237,4,0,
397,15,240,4,0,
397,15,241,4,0,
397,15,263,4,0,
397,15,283,1,18,
397,15,332,1,28,
397,15,332,4,0,
397,15,355,4,0,
397,15,369,4,0,
397,15,413,1,43,
397,15,496,4,0,
397,15,497,4,0,
397,15,514,4,0,
397,15,515,1,48,
397,15,590,4,0,
397,16,17,1,9,1
397,16,18,1,23,1
397,16,19,4,0,
397,16,33,1,1,1
397,16,36,1,33,1
397,16,45,1,1,2
397,16,92,4,0,
397,16,97,1,38,1
397,16,98,1,1,3
397,16,98,1,5,1
397,16,104,1,13,1
397,16,104,4,0,
397,16,156,4,0,
397,16,164,4,0,
397,16,168,4,0,
397,16,173,3,0,
397,16,182,4,0,
397,16,207,4,0,
397,16,211,4,0,
397,16,213,4,0,
397,16,214,4,0,
397,16,216,4,0,
397,16,218,4,0,
397,16,237,4,0,
397,16,240,4,0,
397,16,241,4,0,
397,16,253,3,0,
397,16,257,3,0,
397,16,263,4,0,
397,16,283,1,18,1
397,16,283,3,0,
397,16,290,4,0,
397,16,332,1,28,1
397,16,332,4,0,
397,16,355,4,0,
397,16,366,3,0,
397,16,369,4,0,
397,16,413,1,43,1
397,16,496,4,0,
397,16,497,4,0,
397,16,514,4,0,
397,16,515,1,48,1
397,16,590,4,0,
397,17,17,1,9,
397,17,18,1,23,
397,17,19,4,0,
397,17,33,1,1,1
397,17,36,1,33,
397,17,45,1,1,2
397,17,92,4,0,
397,17,97,1,38,
397,17,98,1,1,3
397,17,98,1,5,
397,17,104,1,13,
397,17,104,4,0,
397,17,156,4,0,
397,17,164,4,0,
397,17,168,4,0,
397,17,182,4,0,
397,17,207,4,0,
397,17,211,4,0,
397,17,213,4,0,
397,17,214,4,0,
397,17,216,4,0,
397,17,218,4,0,
397,17,237,4,0,
397,17,240,4,0,
397,17,241,4,0,
397,17,263,4,0,
397,17,283,1,18,
397,17,332,1,28,
397,17,332,4,0,
397,17,355,4,0,
397,17,369,4,0,
397,17,413,1,43,
397,17,496,4,0,
397,17,497,4,0,
397,17,515,1,48,
397,17,526,4,0,
397,17,590,4,0,
397,18,17,1,9,
397,18,18,1,23,
397,18,19,4,0,
397,18,33,1,1,1
397,18,36,1,33,
397,18,45,1,1,2
397,18,92,4,0,
397,18,97,1,38,
397,18,98,1,1,3
397,18,98,1,5,
397,18,104,1,13,
397,18,104,4,0,
397,18,156,4,0,
397,18,164,4,0,
397,18,168,4,0,
397,18,182,4,0,
397,18,207,4,0,
397,18,211,4,0,
397,18,213,4,0,
397,18,214,4,0,
397,18,216,4,0,
397,18,218,4,0,
397,18,237,4,0,
397,18,240,4,0,
397,18,241,4,0,
397,18,263,4,0,
397,18,283,1,18,
397,18,332,1,28,
397,18,332,4,0,
397,18,355,4,0,
397,18,369,4,0,
397,18,413,1,43,
397,18,496,4,0,
397,18,497,4,0,
397,18,515,1,48,
397,18,526,4,0,
397,18,590,4,0,
398,8,17,1,1,4
398,8,17,1,9,
398,8,18,1,23,
398,8,19,4,0,
398,8,33,1,1,1
398,8,36,1,33,
398,8,45,1,1,2
398,8,63,4,0,
398,8,92,4,0,
398,8,97,1,41,
398,8,98,1,1,3
398,8,98,1,5,
398,8,104,1,13,
398,8,104,4,0,
398,8,156,4,0,
398,8,164,4,0,
398,8,168,4,0,
398,8,182,4,0,
398,8,203,4,0,
398,8,207,4,0,
398,8,211,4,0,
398,8,213,4,0,
398,8,214,4,0,
398,8,216,4,0,
398,8,218,4,0,
398,8,237,4,0,
398,8,240,4,0,
398,8,241,4,0,
398,8,263,4,0,
398,8,283,1,18,
398,8,290,4,0,
398,8,332,1,28,
398,8,332,4,0,
398,8,355,4,0,
398,8,363,4,0,
398,8,365,4,0,
398,8,369,4,0,
398,8,370,1,34,
398,8,413,1,49,
398,8,416,4,0,
398,8,432,4,0,
398,8,445,4,0,
398,9,17,1,1,4
398,9,17,1,9,
398,9,18,1,23,
398,9,19,4,0,
398,9,33,1,1,1
398,9,36,1,33,
398,9,45,1,1,2
398,9,63,4,0,
398,9,92,4,0,
398,9,97,1,41,
398,9,98,1,1,3
398,9,98,1,5,
398,9,104,1,13,
398,9,104,4,0,
398,9,129,3,0,
398,9,156,4,0,
398,9,164,4,0,
398,9,168,4,0,
398,9,173,3,0,
398,9,182,4,0,
398,9,189,3,0,
398,9,203,4,0,
398,9,207,4,0,
398,9,211,4,0,
398,9,213,4,0,
398,9,214,4,0,
398,9,216,4,0,
398,9,218,4,0,
398,9,237,4,0,
398,9,239,3,0,
398,9,240,4,0,
398,9,241,4,0,
398,9,257,3,0,
398,9,263,4,0,
398,9,283,1,18,
398,9,283,3,0,
398,9,290,4,0,
398,9,314,3,0,
398,9,332,1,28,
398,9,332,4,0,
398,9,355,4,0,
398,9,363,4,0,
398,9,365,4,0,
398,9,369,4,0,
398,9,370,1,34,
398,9,413,1,49,
398,9,416,4,0,
398,9,432,4,0,
398,9,445,4,0,
398,9,466,3,0,
398,10,17,1,1,4
398,10,17,1,9,
398,10,18,1,23,
398,10,19,4,0,
398,10,33,1,1,1
398,10,36,1,33,
398,10,45,1,1,2
398,10,63,4,0,
398,10,92,4,0,
398,10,97,1,41,
398,10,98,1,1,3
398,10,98,1,5,
398,10,104,1,13,
398,10,104,4,0,
398,10,129,3,0,
398,10,143,3,0,
398,10,156,4,0,
398,10,164,4,0,
398,10,168,4,0,
398,10,173,3,0,
398,10,182,4,0,
398,10,189,3,0,
398,10,203,4,0,
398,10,207,4,0,
398,10,211,4,0,
398,10,213,4,0,
398,10,214,4,0,
398,10,216,4,0,
398,10,218,4,0,
398,10,237,4,0,
398,10,239,3,0,
398,10,240,4,0,
398,10,241,4,0,
398,10,257,3,0,
398,10,263,4,0,
398,10,283,1,18,
398,10,283,3,0,
398,10,290,4,0,
398,10,314,3,0,
398,10,332,1,28,
398,10,332,4,0,
398,10,355,4,0,
398,10,363,4,0,
398,10,365,4,0,
398,10,366,3,0,
398,10,369,4,0,
398,10,370,1,34,
398,10,413,1,49,
398,10,416,4,0,
398,10,445,4,0,
398,10,466,3,0,
398,11,17,1,1,4
398,11,17,1,9,
398,11,18,1,23,
398,11,19,4,0,
398,11,33,1,1,1
398,11,36,1,33,
398,11,45,1,1,2
398,11,63,4,0,
398,11,92,4,0,
398,11,97,1,41,
398,11,98,1,1,3
398,11,98,1,5,
398,11,104,1,13,
398,11,104,4,0,
398,11,156,4,0,
398,11,164,4,0,
398,11,168,4,0,
398,11,182,4,0,
398,11,207,4,0,
398,11,213,4,0,
398,11,216,4,0,
398,11,218,4,0,
398,11,237,4,0,
398,11,240,4,0,
398,11,241,4,0,
398,11,263,4,0,
398,11,283,1,18,
398,11,332,1,28,
398,11,332,4,0,
398,11,365,4,0,
398,11,369,4,0,
398,11,370,1,34,
398,11,413,1,49,
398,11,416,4,0,
398,11,496,4,0,
398,11,497,4,0,
398,11,514,4,0,
398,11,515,1,57,
398,11,526,4,0,
398,14,17,1,1,4
398,14,17,1,9,
398,14,18,1,23,
398,14,19,4,0,
398,14,33,1,1,1
398,14,36,1,33,
398,14,45,1,1,2
398,14,63,4,0,
398,14,92,4,0,
398,14,97,1,41,
398,14,98,1,1,3
398,14,98,1,5,
398,14,104,1,13,
398,14,104,4,0,
398,14,143,3,0,
398,14,156,4,0,
398,14,164,4,0,
398,14,168,4,0,
398,14,173,3,0,
398,14,182,4,0,
398,14,207,4,0,
398,14,213,4,0,
398,14,214,3,0,
398,14,216,4,0,
398,14,218,4,0,
398,14,237,4,0,
398,14,240,4,0,
398,14,241,4,0,
398,14,257,3,0,
398,14,263,4,0,
398,14,283,1,18,
398,14,283,3,0,
398,14,332,1,28,
398,14,332,4,0,
398,14,355,3,0,
398,14,365,4,0,
398,14,366,3,0,
398,14,369,4,0,
398,14,370,1,34,
398,14,413,1,49,
398,14,416,4,0,
398,14,496,4,0,
398,14,497,4,0,
398,14,514,4,0,
398,14,515,1,57,
398,14,526,4,0,
398,15,17,1,1,4
398,15,17,1,9,
398,15,18,1,23,
398,15,19,4,0,
398,15,33,1,1,1
398,15,36,1,33,
398,15,45,1,1,2
398,15,63,4,0,
398,15,92,4,0,
398,15,97,1,41,
398,15,98,1,1,3
398,15,98,1,5,
398,15,104,1,13,
398,15,104,4,0,
398,15,156,4,0,
398,15,164,4,0,
398,15,168,4,0,
398,15,182,4,0,
398,15,207,4,0,
398,15,211,4,0,
398,15,213,4,0,
398,15,214,4,0,
398,15,216,4,0,
398,15,218,4,0,
398,15,237,4,0,
398,15,240,4,0,
398,15,241,4,0,
398,15,263,4,0,
398,15,283,1,18,
398,15,332,1,28,
398,15,332,4,0,
398,15,355,4,0,
398,15,369,4,0,
398,15,370,1,34,
398,15,413,1,49,
398,15,416,4,0,
398,15,496,4,0,
398,15,497,4,0,
398,15,514,4,0,
398,15,515,1,57,
398,15,590,4,0,
398,16,17,1,1,4
398,16,17,1,9,1
398,16,18,1,23,1
398,16,19,4,0,
398,16,33,1,1,1
398,16,36,1,33,1
398,16,45,1,1,2
398,16,63,4,0,
398,16,92,4,0,
398,16,97,1,41,1
398,16,98,1,1,3
398,16,98,1,5,1
398,16,104,1,13,1
398,16,104,4,0,
398,16,143,3,0,
398,16,156,4,0,
398,16,164,4,0,
398,16,168,4,0,
398,16,173,3,0,
398,16,182,4,0,
398,16,207,4,0,
398,16,211,4,0,
398,16,213,4,0,
398,16,214,4,0,
398,16,216,4,0,
398,16,218,4,0,
398,16,237,4,0,
398,16,240,4,0,
398,16,241,4,0,
398,16,253,3,0,
398,16,257,3,0,
398,16,263,4,0,
398,16,283,1,18,1
398,16,283,3,0,
398,16,290,4,0,
398,16,332,1,28,1
398,16,332,4,0,
398,16,355,4,0,
398,16,366,3,0,
398,16,369,4,0,
398,16,370,1,34,1
398,16,413,1,49,1
398,16,416,4,0,
398,16,496,4,0,
398,16,497,4,0,
398,16,514,4,0,
398,16,515,1,57,1
398,16,590,4,0,
398,17,17,1,1,5
398,17,17,1,9,
398,17,18,1,23,
398,17,19,4,0,
398,17,33,1,1,2
398,17,36,1,33,
398,17,45,1,1,3
398,17,63,4,0,
398,17,92,4,0,
398,17,97,1,41,
398,17,98,1,1,4
398,17,98,1,5,
398,17,104,1,13,
398,17,104,4,0,
398,17,156,4,0,
398,17,164,4,0,
398,17,168,4,0,
398,17,182,4,0,
398,17,207,4,0,
398,17,211,4,0,
398,17,213,4,0,
398,17,214,4,0,
398,17,216,4,0,
398,17,218,4,0,
398,17,237,4,0,
398,17,240,4,0,
398,17,241,4,0,
398,17,263,4,0,
398,17,283,1,18,
398,17,332,1,28,
398,17,332,4,0,
398,17,355,4,0,
398,17,369,4,0,
398,17,370,1,0,
398,17,370,1,1,1
398,17,413,1,49,
398,17,416,4,0,
398,17,496,4,0,
398,17,497,4,0,
398,17,515,1,57,
398,17,526,4,0,
398,17,590,4,0,
398,18,17,1,1,5
398,18,17,1,9,
398,18,18,1,23,
398,18,19,4,0,
398,18,33,1,1,2
398,18,36,1,33,
398,18,45,1,1,3
398,18,63,4,0,
398,18,92,4,0,
398,18,97,1,41,
398,18,98,1,1,4
398,18,98,1,5,
398,18,104,1,13,
398,18,104,4,0,
398,18,156,4,0,
398,18,164,4,0,
398,18,168,4,0,
398,18,182,4,0,
398,18,207,4,0,
398,18,211,4,0,
398,18,213,4,0,
398,18,214,4,0,
398,18,216,4,0,
398,18,218,4,0,
398,18,237,4,0,
398,18,240,4,0,
398,18,241,4,0,
398,18,263,4,0,
398,18,283,1,18,
398,18,332,1,28,
398,18,332,4,0,
398,18,355,4,0,
398,18,369,4,0,
398,18,370,1,0,
398,18,370,1,1,1
398,18,413,1,49,
398,18,416,4,0,
398,18,496,4,0,
398,18,497,4,0,
398,18,515,1,57,
398,18,526,4,0,
398,18,590,4,0,
399,8,15,4,0,
399,8,29,1,17,
399,8,33,1,1,
399,8,36,1,33,
399,8,38,2,0,
399,8,45,1,5,
399,8,58,4,0,
399,8,59,4,0,
399,8,85,4,0,
399,8,86,4,0,
399,8,87,4,0,
399,8,91,4,0,
399,8,92,4,0,
399,8,98,2,0,
399,8,104,4,0,
399,8,111,1,9,
399,8,111,2,0,
399,8,133,1,29,
399,8,154,2,0,
399,8,156,4,0,
399,8,158,1,21,
399,8,162,1,37,
399,8,164,4,0,
399,8,168,4,0,
399,8,182,4,0,
399,8,203,4,0,
399,8,205,1,13,
399,8,205,2,0,
399,8,207,4,0,
399,8,213,4,0,
399,8,214,4,0,
399,8,216,4,0,
399,8,218,4,0,
399,8,231,4,0,
399,8,237,4,0,
399,8,240,4,0,
399,8,241,4,0,
399,8,247,4,0,
399,8,249,4,0,
399,8,263,4,0,
399,8,269,4,0,
399,8,276,1,41,
399,8,281,1,25,
399,8,290,4,0,
399,8,316,2,0,
399,8,346,2,0,
399,8,351,4,0,
399,8,363,4,0,
399,8,365,4,0,
399,8,401,2,0,
399,8,445,4,0,
399,8,446,4,0,
399,8,447,4,0,
399,8,451,4,0,
399,9,15,4,0,
399,9,29,1,17,
399,9,33,1,1,
399,9,36,1,33,
399,9,38,2,0,
399,9,45,1,5,
399,9,58,4,0,
399,9,59,4,0,
399,9,85,4,0,
399,9,86,4,0,
399,9,87,4,0,
399,9,91,4,0,
399,9,92,4,0,
399,9,98,2,0,
399,9,104,4,0,
399,9,111,1,9,
399,9,111,2,0,
399,9,129,3,0,
399,9,133,1,29,
399,9,154,2,0,
399,9,156,4,0,
399,9,158,1,21,
399,9,162,1,37,
399,9,164,4,0,
399,9,168,4,0,
399,9,173,3,0,
399,9,174,1,45,
399,9,182,4,0,
399,9,189,3,0,
399,9,196,3,0,
399,9,203,4,0,
399,9,205,1,13,
399,9,205,2,0,
399,9,205,3,0,
399,9,207,4,0,
399,9,210,3,0,
399,9,213,4,0,
399,9,214,4,0,
399,9,216,4,0,
399,9,218,4,0,
399,9,231,4,0,
399,9,237,4,0,
399,9,240,4,0,
399,9,241,4,0,
399,9,247,4,0,
399,9,249,4,0,
399,9,263,4,0,
399,9,269,4,0,
399,9,276,1,41,
399,9,276,3,0,
399,9,281,1,25,
399,9,290,4,0,
399,9,316,2,0,
399,9,346,2,0,
399,9,351,4,0,
399,9,363,4,0,
399,9,365,4,0,
399,9,387,3,0,
399,9,401,2,0,
399,9,401,3,0,
399,9,445,4,0,
399,9,446,4,0,
399,9,447,4,0,
399,9,451,4,0,
399,10,15,4,0,
399,10,29,1,17,
399,10,29,3,0,
399,10,33,1,1,
399,10,36,1,33,
399,10,38,2,0,
399,10,45,1,5,
399,10,58,4,0,
399,10,59,4,0,
399,10,85,4,0,
399,10,86,4,0,
399,10,87,4,0,
399,10,91,4,0,
399,10,92,4,0,
399,10,98,2,0,
399,10,104,4,0,
399,10,111,1,9,
399,10,111,2,0,
399,10,129,3,0,
399,10,133,1,29,
399,10,154,2,0,
399,10,156,4,0,
399,10,158,1,21,
399,10,162,1,37,
399,10,162,3,0,
399,10,164,4,0,
399,10,168,4,0,
399,10,173,3,0,
399,10,174,1,45,
399,10,182,4,0,
399,10,189,3,0,
399,10,196,3,0,
399,10,203,4,0,
399,10,205,1,13,
399,10,205,2,0,
399,10,205,3,0,
399,10,207,4,0,
399,10,210,3,0,
399,10,213,4,0,
399,10,214,4,0,
399,10,216,4,0,
399,10,218,4,0,
399,10,231,4,0,
399,10,237,4,0,
399,10,240,4,0,
399,10,241,4,0,
399,10,247,4,0,
399,10,249,4,0,
399,10,263,4,0,
399,10,269,4,0,
399,10,276,1,41,
399,10,276,3,0,
399,10,281,1,25,
399,10,290,4,0,
399,10,316,2,0,
399,10,346,2,0,
399,10,351,4,0,
399,10,363,4,0,
399,10,365,4,0,
399,10,387,3,0,
399,10,401,2,0,
399,10,401,3,0,
399,10,445,4,0,
399,10,446,4,0,
399,10,447,4,0,
399,10,451,4,0,
399,11,15,4,0,
399,11,29,1,17,
399,11,33,1,1,
399,11,36,1,33,
399,11,38,2,0,
399,11,45,1,5,
399,11,58,4,0,
399,11,59,4,0,
399,11,85,4,0,
399,11,86,4,0,
399,11,87,4,0,
399,11,91,4,0,
399,11,92,4,0,
399,11,98,2,0,
399,11,104,4,0,
399,11,111,1,9,
399,11,111,2,0,
399,11,130,2,0,
399,11,133,1,29,
399,11,154,2,0,
399,11,156,4,0,
399,11,158,1,21,
399,11,162,1,37,
399,11,164,4,0,
399,11,168,4,0,
399,11,174,1,45,
399,11,182,4,0,
399,11,203,2,0,
399,11,205,1,13,
399,11,205,2,0,
399,11,207,4,0,
399,11,213,4,0,
399,11,214,2,0,
399,11,216,4,0,
399,11,218,4,0,
399,11,237,4,0,
399,11,240,4,0,
399,11,241,4,0,
399,11,247,4,0,
399,11,249,4,0,
399,11,263,4,0,
399,11,269,4,0,
399,11,276,1,41,
399,11,281,1,25,
399,11,316,2,0,
399,11,346,2,0,
399,11,365,4,0,
399,11,401,2,0,
399,11,431,2,0,
399,11,447,4,0,
399,11,451,4,0,
399,11,496,4,0,
399,11,497,4,0,
399,11,514,4,0,
399,11,526,4,0,
399,14,15,4,0,
399,14,29,1,17,
399,14,33,1,1,
399,14,36,1,33,
399,14,38,2,0,
399,14,45,1,5,
399,14,58,4,0,
399,14,59,4,0,
399,14,85,4,0,
399,14,86,4,0,
399,14,87,4,0,
399,14,91,4,0,
399,14,92,4,0,
399,14,98,2,0,
399,14,104,4,0,
399,14,111,1,9,
399,14,111,2,0,
399,14,130,2,0,
399,14,133,1,29,
399,14,154,2,0,
399,14,156,4,0,
399,14,158,1,21,
399,14,162,1,37,
399,14,162,3,0,
399,14,164,4,0,
399,14,168,4,0,
399,14,173,3,0,
399,14,174,1,45,
399,14,182,4,0,
399,14,196,3,0,
399,14,203,2,0,
399,14,205,1,13,
399,14,205,2,0,
399,14,207,4,0,
399,14,213,4,0,
399,14,214,2,0,
399,14,214,3,0,
399,14,216,4,0,
399,14,218,4,0,
399,14,231,3,0,
399,14,237,4,0,
399,14,240,4,0,
399,14,241,4,0,
399,14,247,4,0,
399,14,249,4,0,
399,14,263,4,0,
399,14,269,4,0,
399,14,276,1,41,
399,14,276,3,0,
399,14,281,1,25,
399,14,316,2,0,
399,14,343,3,0,
399,14,346,2,0,
399,14,365,4,0,
399,14,387,3,0,
399,14,401,2,0,
399,14,401,3,0,
399,14,431,2,0,
399,14,446,3,0,
399,14,447,4,0,
399,14,451,4,0,
399,14,496,4,0,
399,14,497,4,0,
399,14,514,4,0,
399,14,526,4,0,
399,15,15,4,0,
399,15,29,1,17,
399,15,33,1,1,
399,15,36,1,33,
399,15,38,2,0,
399,15,45,1,5,
399,15,58,4,0,
399,15,59,4,0,
399,15,85,4,0,
399,15,86,4,0,
399,15,87,4,0,
399,15,91,4,0,
399,15,92,4,0,
399,15,98,2,0,
399,15,104,4,0,
399,15,111,1,9,
399,15,111,2,0,
399,15,130,2,0,
399,15,133,1,29,
399,15,154,2,0,
399,15,156,4,0,
399,15,158,1,21,
399,15,162,1,37,
399,15,164,4,0,
399,15,168,4,0,
399,15,174,1,45,
399,15,182,4,0,
399,15,203,2,0,
399,15,205,1,13,
399,15,205,2,0,
399,15,207,4,0,
399,15,213,4,0,
399,15,214,2,0,
399,15,214,4,0,
399,15,216,4,0,
399,15,218,4,0,
399,15,237,4,0,
399,15,240,4,0,
399,15,241,4,0,
399,15,247,4,0,
399,15,249,4,0,
399,15,263,4,0,
399,15,269,4,0,
399,15,276,1,41,
399,15,281,1,25,
399,15,316,2,0,
399,15,346,2,0,
399,15,401,2,0,
399,15,431,2,0,
399,15,447,4,0,
399,15,451,4,0,
399,15,496,4,0,
399,15,497,4,0,
399,15,514,4,0,
399,15,590,4,0,
399,16,15,4,0,
399,16,29,1,17,1
399,16,33,1,1,1
399,16,36,1,33,1
399,16,38,2,0,
399,16,45,1,5,1
399,16,58,4,0,
399,16,59,4,0,
399,16,85,4,0,
399,16,86,4,0,
399,16,87,4,0,
399,16,91,4,0,
399,16,92,4,0,
399,16,98,2,0,
399,16,104,4,0,
399,16,111,1,9,1
399,16,111,2,0,
399,16,130,2,0,
399,16,133,1,29,1
399,16,154,2,0,
399,16,156,4,0,
399,16,158,1,21,1
399,16,162,1,37,1
399,16,162,3,0,
399,16,164,4,0,
399,16,168,4,0,
399,16,173,3,0,
399,16,174,1,45,1
399,16,182,4,0,
399,16,196,3,0,
399,16,203,2,0,
399,16,205,1,13,1
399,16,205,2,0,
399,16,207,4,0,
399,16,213,4,0,
399,16,214,2,0,
399,16,214,4,0,
399,16,216,4,0,
399,16,218,4,0,
399,16,231,3,0,
399,16,237,4,0,
399,16,240,4,0,
399,16,241,4,0,
399,16,247,4,0,
399,16,249,4,0,
399,16,263,4,0,
399,16,269,4,0,
399,16,276,1,41,1
399,16,276,3,0,
399,16,281,1,25,1
399,16,290,4,0,
399,16,316,2,0,
399,16,343,3,0,
399,16,346,2,0,
399,16,351,3,0,
399,16,387,3,0,
399,16,401,2,0,
399,16,401,3,0,
399,16,431,2,0,
399,16,446,3,0,
399,16,447,4,0,
399,16,451,4,0,
399,16,496,4,0,
399,16,497,4,0,
399,16,514,4,0,
399,16,590,4,0,
399,17,14,1,37,
399,17,14,4,0,
399,17,29,1,13,
399,17,33,1,1,1
399,17,36,1,29,
399,17,38,2,0,
399,17,45,1,1,2
399,17,58,4,0,
399,17,59,4,0,
399,17,85,4,0,
399,17,86,4,0,
399,17,87,4,0,
399,17,92,4,0,
399,17,98,2,0,
399,17,104,4,0,
399,17,111,1,5,
399,17,111,2,0,
399,17,130,2,0,
399,17,133,1,41,
399,17,154,2,0,
399,17,156,4,0,
399,17,158,1,17,
399,17,162,1,33,
399,17,164,4,0,
399,17,168,4,0,
399,17,174,1,49,
399,17,182,4,0,
399,17,203,2,0,
399,17,205,1,9,
399,17,205,2,0,
399,17,207,4,0,
399,17,213,4,0,
399,17,214,2,0,
399,17,214,4,0,
399,17,216,4,0,
399,17,218,4,0,
399,17,237,4,0,
399,17,240,4,0,
399,17,241,4,0,
399,17,242,1,25,
399,17,247,4,0,
399,17,263,4,0,
399,17,269,4,0,
399,17,276,1,45,
399,17,281,1,21,
399,17,316,2,0,
399,17,346,2,0,
399,17,401,2,0,
399,17,431,2,0,
399,17,447,4,0,
399,17,451,4,0,
399,17,496,4,0,
399,17,497,4,0,
399,17,526,4,0,
399,17,590,4,0,
399,18,14,1,37,
399,18,14,4,0,
399,18,29,1,13,
399,18,33,1,1,1
399,18,36,1,29,
399,18,38,2,0,
399,18,45,1,1,2
399,18,58,4,0,
399,18,59,4,0,
399,18,85,4,0,
399,18,86,4,0,
399,18,87,4,0,
399,18,92,4,0,
399,18,98,2,0,
399,18,104,4,0,
399,18,111,1,5,
399,18,111,2,0,
399,18,130,2,0,
399,18,133,1,41,
399,18,154,2,0,
399,18,156,4,0,
399,18,158,1,17,
399,18,162,1,33,
399,18,164,4,0,
399,18,168,4,0,
399,18,174,1,49,
399,18,182,4,0,
399,18,203,2,0,
399,18,205,1,9,
399,18,205,2,0,
399,18,207,4,0,
399,18,213,4,0,
399,18,214,2,0,
399,18,214,4,0,
399,18,216,4,0,
399,18,218,4,0,
399,18,237,4,0,
399,18,240,4,0,
399,18,241,4,0,
399,18,242,1,25,
399,18,247,4,0,
399,18,263,4,0,
399,18,269,4,0,
399,18,276,1,45,
399,18,281,1,21,
399,18,300,2,0,
399,18,316,2,0,
399,18,346,2,0,
399,18,401,2,0,
399,18,431,2,0,
399,18,447,4,0,
399,18,451,4,0,
399,18,496,4,0,
399,18,497,4,0,
399,18,526,4,0,
399,18,590,4,0,
400,8,15,4,0,
400,8,29,1,18,
400,8,33,1,1,1
400,8,36,1,38,
400,8,45,1,1,2
400,8,45,1,5,
400,8,55,1,15,
400,8,57,4,0,
400,8,58,4,0,
400,8,59,4,0,
400,8,63,4,0,
400,8,70,4,0,
400,8,85,4,0,
400,8,86,4,0,
400,8,87,4,0,
400,8,91,4,0,
400,8,92,4,0,
400,8,104,4,0,
400,8,111,1,9,
400,8,127,4,0,
400,8,133,1,33,
400,8,156,4,0,
400,8,158,1,23,
400,8,162,1,43,
400,8,164,4,0,
400,8,168,4,0,
400,8,182,4,0,
400,8,203,4,0,
400,8,205,1,13,
400,8,207,4,0,
400,8,213,4,0,
400,8,214,4,0,
400,8,216,4,0,
400,8,218,4,0,
400,8,231,4,0,
400,8,237,4,0,
400,8,240,4,0,
400,8,241,4,0,
400,8,247,4,0,
400,8,249,4,0,
400,8,263,4,0,
400,8,264,4,0,
400,8,269,4,0,
400,8,276,1,48,
400,8,281,1,28,
400,8,290,4,0,
400,8,351,4,0,
400,8,352,4,0,
400,8,363,4,0,
400,8,365,4,0,
400,8,374,4,0,
400,8,416,4,0,
400,8,431,4,0,
400,8,445,4,0,
400,8,446,4,0,
400,8,447,4,0,
400,8,451,4,0,
400,9,15,4,0,
400,9,29,1,18,
400,9,33,1,1,1
400,9,36,1,38,
400,9,45,1,1,2
400,9,45,1,5,
400,9,55,1,15,
400,9,57,4,0,
400,9,58,4,0,
400,9,59,4,0,
400,9,63,4,0,
400,9,70,4,0,
400,9,85,4,0,
400,9,86,4,0,
400,9,87,4,0,
400,9,91,4,0,
400,9,92,4,0,
400,9,104,4,0,
400,9,111,1,9,
400,9,127,4,0,
400,9,129,3,0,
400,9,133,1,33,
400,9,156,4,0,
400,9,158,1,23,
400,9,162,1,43,
400,9,164,4,0,
400,9,168,4,0,
400,9,173,3,0,
400,9,174,1,53,
400,9,182,4,0,
400,9,189,3,0,
400,9,196,3,0,
400,9,203,4,0,
400,9,205,1,13,
400,9,205,3,0,
400,9,207,4,0,
400,9,210,3,0,
400,9,213,4,0,
400,9,214,4,0,
400,9,216,4,0,
400,9,218,4,0,
400,9,231,4,0,
400,9,237,4,0,
400,9,240,4,0,
400,9,241,4,0,
400,9,247,4,0,
400,9,249,4,0,
400,9,263,4,0,
400,9,264,4,0,
400,9,269,4,0,
400,9,276,1,48,
400,9,276,3,0,
400,9,281,1,28,
400,9,290,4,0,
400,9,291,3,0,
400,9,351,4,0,
400,9,352,4,0,
400,9,363,4,0,
400,9,365,4,0,
400,9,374,4,0,
400,9,387,3,0,
400,9,401,3,0,
400,9,416,4,0,
400,9,431,4,0,
400,9,445,4,0,
400,9,446,4,0,
400,9,447,4,0,
400,9,451,4,0,
400,10,15,4,0,
400,10,29,1,18,
400,10,29,3,0,
400,10,33,1,1,1
400,10,36,1,38,
400,10,45,1,1,2
400,10,45,1,5,
400,10,55,1,15,
400,10,57,4,0,
400,10,58,4,0,
400,10,59,4,0,
400,10,63,4,0,
400,10,70,4,0,
400,10,85,4,0,
400,10,86,4,0,
400,10,87,4,0,
400,10,91,4,0,
400,10,92,4,0,
400,10,104,4,0,
400,10,111,1,9,
400,10,127,4,0,
400,10,129,3,0,
400,10,133,1,33,
400,10,156,4,0,
400,10,158,1,23,
400,10,162,1,43,
400,10,162,3,0,
400,10,164,4,0,
400,10,168,4,0,
400,10,173,3,0,
400,10,174,1,53,
400,10,182,4,0,
400,10,189,3,0,
400,10,196,3,0,
400,10,203,4,0,
400,10,205,1,13,
400,10,205,3,0,
400,10,207,4,0,
400,10,210,3,0,
400,10,213,4,0,
400,10,214,4,0,
400,10,216,4,0,
400,10,218,4,0,
400,10,231,4,0,
400,10,237,4,0,
400,10,240,4,0,
400,10,241,4,0,
400,10,247,4,0,
400,10,249,4,0,
400,10,250,4,0,
400,10,263,4,0,
400,10,264,4,0,
400,10,269,4,0,
400,10,276,1,48,
400,10,276,3,0,
400,10,281,1,28,
400,10,290,4,0,
400,10,291,3,0,
400,10,351,4,0,
400,10,352,4,0,
400,10,363,4,0,
400,10,365,4,0,
400,10,374,4,0,
400,10,387,3,0,
400,10,401,3,0,
400,10,416,4,0,
400,10,431,4,0,
400,10,445,4,0,
400,10,446,4,0,
400,10,447,4,0,
400,10,451,4,0,
400,11,15,4,0,
400,11,29,1,18,
400,11,33,1,1,1
400,11,36,1,38,
400,11,45,1,1,2
400,11,45,1,5,
400,11,55,1,15,
400,11,57,4,0,
400,11,58,4,0,
400,11,59,4,0,
400,11,63,4,0,
400,11,70,4,0,
400,11,85,4,0,
400,11,86,4,0,
400,11,87,4,0,
400,11,91,4,0,
400,11,92,4,0,
400,11,104,4,0,
400,11,111,1,9,
400,11,127,4,0,
400,11,133,1,33,
400,11,156,4,0,
400,11,158,1,23,
400,11,162,1,43,
400,11,164,4,0,
400,11,168,4,0,
400,11,174,1,53,
400,11,182,4,0,
400,11,205,1,13,
400,11,207,4,0,
400,11,213,4,0,
400,11,216,4,0,
400,11,218,4,0,
400,11,237,4,0,
400,11,240,4,0,
400,11,241,4,0,
400,11,247,4,0,
400,11,249,4,0,
400,11,263,4,0,
400,11,269,4,0,
400,11,276,1,48,
400,11,281,1,28,
400,11,291,4,0,
400,11,365,4,0,
400,11,374,4,0,
400,11,416,4,0,
400,11,447,4,0,
400,11,451,4,0,
400,11,496,4,0,
400,11,497,4,0,
400,11,503,4,0,
400,11,514,4,0,
400,11,523,4,0,
400,11,526,4,0,
400,14,15,4,0,
400,14,29,1,18,
400,14,33,1,1,1
400,14,36,1,38,
400,14,45,1,1,2
400,14,45,1,5,
400,14,55,1,15,
400,14,57,4,0,
400,14,58,4,0,
400,14,59,4,0,
400,14,63,4,0,
400,14,70,4,0,
400,14,85,4,0,
400,14,86,4,0,
400,14,87,4,0,
400,14,91,4,0,
400,14,92,4,0,
400,14,104,4,0,
400,14,111,1,9,
400,14,127,4,0,
400,14,133,1,33,
400,14,156,4,0,
400,14,158,1,23,
400,14,162,1,43,
400,14,162,3,0,
400,14,164,4,0,
400,14,168,4,0,
400,14,173,3,0,
400,14,174,1,53,
400,14,182,4,0,
400,14,196,3,0,
400,14,205,1,13,
400,14,207,4,0,
400,14,213,4,0,
400,14,214,3,0,
400,14,216,4,0,
400,14,218,4,0,
400,14,231,3,0,
400,14,237,4,0,
400,14,240,4,0,
400,14,241,4,0,
400,14,247,4,0,
400,14,249,4,0,
400,14,263,4,0,
400,14,269,4,0,
400,14,276,1,48,
400,14,276,3,0,
400,14,281,1,28,
400,14,291,4,0,
400,14,343,3,0,
400,14,365,4,0,
400,14,374,4,0,
400,14,387,3,0,
400,14,401,3,0,
400,14,416,4,0,
400,14,446,3,0,
400,14,447,4,0,
400,14,451,4,0,
400,14,496,4,0,
400,14,497,4,0,
400,14,503,4,0,
400,14,514,4,0,
400,14,523,4,0,
400,14,526,4,0,
400,15,15,4,0,
400,15,29,1,18,
400,15,33,1,1,2
400,15,36,1,38,
400,15,45,1,1,3
400,15,45,1,5,
400,15,55,1,15,
400,15,57,4,0,
400,15,58,4,0,
400,15,59,4,0,
400,15,63,4,0,
400,15,70,4,0,
400,15,85,4,0,
400,15,86,4,0,
400,15,87,4,0,
400,15,91,4,0,
400,15,92,4,0,
400,15,104,4,0,
400,15,111,1,9,
400,15,127,4,0,
400,15,133,1,33,
400,15,156,4,0,
400,15,158,1,23,
400,15,162,1,43,
400,15,164,4,0,
400,15,168,4,0,
400,15,174,1,53,
400,15,182,4,0,
400,15,205,1,13,
400,15,207,4,0,
400,15,213,4,0,
400,15,214,4,0,
400,15,216,4,0,
400,15,218,4,0,
400,15,237,4,0,
400,15,240,4,0,
400,15,241,4,0,
400,15,247,4,0,
400,15,249,4,0,
400,15,263,4,0,
400,15,269,4,0,
400,15,276,1,48,
400,15,281,1,28,
400,15,374,4,0,
400,15,416,4,0,
400,15,447,4,0,
400,15,451,4,0,
400,15,496,4,0,
400,15,497,4,0,
400,15,503,4,0,
400,15,514,4,0,
400,15,523,4,0,
400,15,563,1,1,1
400,15,590,4,0,
400,16,15,4,0,
400,16,29,1,18,1
400,16,33,1,1,2
400,16,36,1,38,1
400,16,45,1,1,3
400,16,45,1,5,1
400,16,55,1,15,1
400,16,57,4,0,
400,16,58,4,0,
400,16,59,4,0,
400,16,63,4,0,
400,16,70,4,0,
400,16,85,4,0,
400,16,86,4,0,
400,16,87,4,0,
400,16,91,4,0,
400,16,92,4,0,
400,16,104,4,0,
400,16,111,1,9,1
400,16,127,4,0,
400,16,133,1,33,1
400,16,156,4,0,
400,16,158,1,23,1
400,16,162,1,43,1
400,16,162,3,0,
400,16,164,4,0,
400,16,168,4,0,
400,16,173,3,0,
400,16,174,1,53,1
400,16,182,4,0,
400,16,196,3,0,
400,16,205,1,13,1
400,16,207,4,0,
400,16,213,4,0,
400,16,214,4,0,
400,16,216,4,0,
400,16,218,4,0,
400,16,231,3,0,
400,16,237,4,0,
400,16,240,4,0,
400,16,241,4,0,
400,16,247,4,0,
400,16,249,4,0,
400,16,263,4,0,
400,16,264,3,0,
400,16,269,4,0,
400,16,276,1,48,1
400,16,276,3,0,
400,16,281,1,28,1
400,16,290,4,0,
400,16,291,4,0,
400,16,343,3,0,
400,16,351,3,0,
400,16,352,3,0,
400,16,374,4,0,
400,16,387,3,0,
400,16,401,3,0,
400,16,416,4,0,
400,16,446,3,0,
400,16,447,4,0,
400,16,451,4,0,
400,16,496,4,0,
400,16,497,4,0,
400,16,503,4,0,
400,16,514,4,0,
400,16,523,4,0,
400,16,563,1,1,1
400,16,590,4,0,
400,17,14,1,43,
400,17,14,4,0,
400,17,29,1,13,
400,17,33,1,1,4
400,17,36,1,33,
400,17,45,1,1,5
400,17,55,1,0,
400,17,55,1,1,1
400,17,57,4,0,
400,17,58,4,0,
400,17,59,4,0,
400,17,63,4,0,
400,17,85,4,0,
400,17,86,4,0,
400,17,87,4,0,
400,17,92,4,0,
400,17,104,4,0,
400,17,111,1,5,
400,17,127,4,0,
400,17,133,1,48,
400,17,156,4,0,
400,17,158,1,18,
400,17,162,1,38,
400,17,164,4,0,
400,17,168,4,0,
400,17,174,1,58,
400,17,182,4,0,
400,17,205,1,9,
400,17,207,4,0,
400,17,213,4,0,
400,17,214,4,0,
400,17,216,4,0,
400,17,218,4,0,
400,17,237,4,0,
400,17,240,4,0,
400,17,241,4,0,
400,17,242,1,28,
400,17,247,4,0,
400,17,263,4,0,
400,17,269,4,0,
400,17,276,1,53,
400,17,281,1,23,
400,17,374,4,0,
400,17,416,4,0,
400,17,447,4,0,
400,17,451,4,0,
400,17,453,1,1,2
400,17,496,4,0,
400,17,497,4,0,
400,17,503,4,0,
400,17,523,4,0,
400,17,526,4,0,
400,17,563,1,1,3
400,17,590,4,0,
400,18,14,1,43,
400,18,14,4,0,
400,18,29,1,13,
400,18,33,1,1,4
400,18,36,1,33,
400,18,45,1,1,5
400,18,55,1,0,
400,18,55,1,1,1
400,18,57,4,0,
400,18,58,4,0,
400,18,59,4,0,
400,18,63,4,0,
400,18,85,4,0,
400,18,86,4,0,
400,18,87,4,0,
400,18,92,4,0,
400,18,104,4,0,
400,18,111,1,5,
400,18,127,4,0,
400,18,133,1,48,
400,18,156,4,0,
400,18,158,1,18,
400,18,162,1,38,
400,18,164,4,0,
400,18,168,4,0,
400,18,174,1,58,
400,18,182,4,0,
400,18,205,1,9,
400,18,207,4,0,
400,18,213,4,0,
400,18,214,4,0,
400,18,216,4,0,
400,18,218,4,0,
400,18,237,4,0,
400,18,240,4,0,
400,18,241,4,0,
400,18,242,1,28,
400,18,247,4,0,
400,18,263,4,0,
400,18,269,4,0,
400,18,276,1,53,
400,18,281,1,23,
400,18,374,4,0,
400,18,416,4,0,
400,18,447,4,0,
400,18,451,4,0,
400,18,453,1,1,2
400,18,496,4,0,
400,18,497,4,0,
400,18,503,4,0,
400,18,523,4,0,
400,18,526,4,0,
400,18,563,1,1,3
400,18,590,4,0,
401,8,45,1,1,1
401,8,117,1,1,2
401,9,45,1,1,1
401,9,117,1,1,2
401,9,173,3,0,
401,9,189,3,0,
401,9,253,3,0,
401,9,283,3,0,
401,9,450,1,16,
401,10,45,1,1,1
401,10,81,3,0,
401,10,117,1,1,2
401,10,173,3,0,
401,10,189,3,0,
401,10,253,3,0,
401,10,283,3,0,
401,10,450,1,16,
401,10,450,3,0,
401,11,45,1,1,1
401,11,117,1,1,2
401,11,450,1,16,
401,11,522,1,6,
401,14,45,1,1,1
401,14,117,1,1,2
401,14,173,3,0,
401,14,253,3,0,
401,14,283,3,0,
401,14,450,1,16,
401,14,450,3,0,
401,14,522,1,6,
401,15,45,1,1,1
401,15,117,1,1,2
401,15,450,1,16,
401,15,522,1,6,
401,15,522,4,0,
401,16,45,1,1,1
401,16,117,1,1,2
401,16,173,3,0,
401,16,253,3,0,
401,16,283,3,0,
401,16,450,1,16,1
401,16,450,3,0,
401,16,522,1,6,1
401,16,522,4,0,
401,17,45,1,1,1
401,17,117,1,1,2
401,17,450,1,16,
401,17,522,1,6,
401,18,45,1,1,1
401,18,117,1,1,2
401,18,450,1,16,
401,18,522,1,6,
402,8,14,4,0,
402,8,15,4,0,
402,8,45,1,1,1
402,8,47,1,18,
402,8,63,4,0,
402,8,70,4,0,
402,8,92,4,0,
402,8,103,1,30,
402,8,104,4,0,
402,8,116,1,22,
402,8,117,1,1,2
402,8,141,1,14,
402,8,148,4,0,
402,8,156,4,0,
402,8,164,4,0,
402,8,182,4,0,
402,8,195,1,38,
402,8,203,4,0,
402,8,206,4,0,
402,8,207,4,0,
402,8,210,1,10,
402,8,213,4,0,
402,8,214,4,0,
402,8,216,4,0,
402,8,218,4,0,
402,8,237,4,0,
402,8,240,4,0,
402,8,241,4,0,
402,8,249,4,0,
402,8,263,4,0,
402,8,280,4,0,
402,8,290,4,0,
402,8,318,4,0,
402,8,332,4,0,
402,8,363,4,0,
402,8,404,1,26,
402,8,404,4,0,
402,8,405,1,34,
402,8,416,4,0,
402,8,445,4,0,
402,9,14,4,0,
402,9,15,4,0,
402,9,45,1,1,1
402,9,47,1,18,
402,9,63,4,0,
402,9,70,4,0,
402,9,92,4,0,
402,9,103,1,34,
402,9,104,4,0,
402,9,116,1,22,
402,9,117,1,1,2
402,9,141,1,14,
402,9,148,4,0,
402,9,156,4,0,
402,9,163,1,26,
402,9,164,4,0,
402,9,173,3,0,
402,9,182,4,0,
402,9,189,3,0,
402,9,195,1,50,
402,9,203,4,0,
402,9,206,4,0,
402,9,207,4,0,
402,9,210,1,10,
402,9,210,3,0,
402,9,213,4,0,
402,9,214,4,0,
402,9,216,4,0,
402,9,218,4,0,
402,9,237,4,0,
402,9,240,4,0,
402,9,241,4,0,
402,9,249,4,0,
402,9,253,3,0,
402,9,263,4,0,
402,9,269,1,38,
402,9,280,4,0,
402,9,282,3,0,
402,9,283,3,0,
402,9,290,4,0,
402,9,318,4,0,
402,9,332,4,0,
402,9,363,4,0,
402,9,400,1,42,
402,9,404,1,30,
402,9,404,4,0,
402,9,405,1,46,
402,9,416,4,0,
402,9,445,4,0,
402,10,14,4,0,
402,10,15,4,0,
402,10,45,1,1,1
402,10,47,1,18,
402,10,63,4,0,
402,10,70,4,0,
402,10,81,3,0,
402,10,92,4,0,
402,10,103,1,34,
402,10,104,4,0,
402,10,116,1,22,
402,10,117,1,1,2
402,10,141,1,14,
402,10,148,4,0,
402,10,156,4,0,
402,10,163,1,26,
402,10,164,4,0,
402,10,173,3,0,
402,10,182,4,0,
402,10,189,3,0,
402,10,195,1,50,
402,10,203,4,0,
402,10,206,4,0,
402,10,207,4,0,
402,10,210,1,10,
402,10,210,3,0,
402,10,213,4,0,
402,10,214,4,0,
402,10,216,4,0,
402,10,218,4,0,
402,10,237,4,0,
402,10,240,4,0,
402,10,241,4,0,
402,10,249,4,0,
402,10,253,3,0,
402,10,263,4,0,
402,10,269,1,38,
402,10,269,4,0,
402,10,280,4,0,
402,10,282,3,0,
402,10,283,3,0,
402,10,290,4,0,
402,10,318,4,0,
402,10,332,4,0,
402,10,363,4,0,
402,10,400,1,42,
402,10,404,1,30,
402,10,404,4,0,
402,10,405,1,46,
402,10,416,4,0,
402,10,445,4,0,
402,10,450,3,0,
402,11,14,4,0,
402,11,15,4,0,
402,11,45,1,1,1
402,11,47,1,18,
402,11,63,4,0,
402,11,70,4,0,
402,11,92,4,0,
402,11,103,1,34,
402,11,104,4,0,
402,11,116,1,22,
402,11,117,1,1,2
402,11,141,1,14,
402,11,148,4,0,
402,11,156,4,0,
402,11,163,1,26,
402,11,164,4,0,
402,11,182,4,0,
402,11,195,1,50,
402,11,206,4,0,
402,11,207,4,0,
402,11,210,1,10,
402,11,213,4,0,
402,11,216,4,0,
402,11,218,4,0,
402,11,237,4,0,
402,11,240,4,0,
402,11,241,4,0,
402,11,249,4,0,
402,11,263,4,0,
402,11,269,1,38,
402,11,269,4,0,
402,11,280,4,0,
402,11,332,4,0,
402,11,400,1,42,
402,11,404,1,30,
402,11,404,4,0,
402,11,405,1,46,
402,11,416,4,0,
402,11,468,4,0,
402,11,496,4,0,
402,11,497,4,0,
402,11,522,4,0,
402,14,14,4,0,
402,14,15,4,0,
402,14,45,1,1,1
402,14,47,1,18,
402,14,63,4,0,
402,14,70,4,0,
402,14,92,4,0,
402,14,103,1,34,
402,14,104,4,0,
402,14,116,1,22,
402,14,117,1,1,2
402,14,141,1,14,
402,14,148,4,0,
402,14,156,4,0,
402,14,163,1,26,
402,14,164,4,0,
402,14,173,3,0,
402,14,182,4,0,
402,14,195,1,50,
402,14,206,4,0,
402,14,207,4,0,
402,14,210,1,10,
402,14,213,4,0,
402,14,214,3,0,
402,14,215,3,0,
402,14,216,4,0,
402,14,218,4,0,
402,14,237,4,0,
402,14,240,4,0,
402,14,241,4,0,
402,14,249,4,0,
402,14,253,3,0,
402,14,263,4,0,
402,14,269,1,38,
402,14,269,4,0,
402,14,280,4,0,
402,14,282,3,0,
402,14,283,3,0,
402,14,304,3,0,
402,14,332,4,0,
402,14,400,1,42,
402,14,404,1,30,
402,14,404,4,0,
402,14,405,1,46,
402,14,416,4,0,
402,14,450,3,0,
402,14,468,4,0,
402,14,496,4,0,
402,14,497,4,0,
402,14,522,4,0,
402,15,14,4,0,
402,15,15,4,0,
402,15,45,1,1,1
402,15,47,1,18,
402,15,63,4,0,
402,15,70,4,0,
402,15,92,4,0,
402,15,103,1,34,
402,15,104,4,0,
402,15,116,1,22,
402,15,117,1,1,2
402,15,141,1,14,
402,15,148,4,0,
402,15,156,4,0,
402,15,163,1,26,
402,15,164,4,0,
402,15,182,4,0,
402,15,195,1,50,
402,15,206,4,0,
402,15,207,4,0,
402,15,210,1,10,
402,15,213,4,0,
402,15,214,4,0,
402,15,216,4,0,
402,15,218,4,0,
402,15,237,4,0,
402,15,240,4,0,
402,15,241,4,0,
402,15,249,4,0,
402,15,263,4,0,
402,15,269,1,38,
402,15,269,4,0,
402,15,280,4,0,
402,15,332,4,0,
402,15,400,1,42,
402,15,404,1,30,
402,15,404,4,0,
402,15,405,1,46,
402,15,416,4,0,
402,15,468,4,0,
402,15,496,4,0,
402,15,497,4,0,
402,15,522,4,0,
402,15,564,1,44,
402,15,565,1,36,
402,15,590,4,0,
402,15,611,4,0,
402,15,612,4,0,
402,16,14,4,0,
402,16,15,4,0,
402,16,45,1,1,1
402,16,47,1,18,1
402,16,63,4,0,
402,16,70,4,0,
402,16,92,4,0,
402,16,103,1,34,1
402,16,104,4,0,
402,16,116,1,22,1
402,16,117,1,1,2
402,16,141,1,14,1
402,16,148,4,0,
402,16,156,4,0,
402,16,163,1,26,1
402,16,164,4,0,
402,16,173,3,0,
402,16,182,4,0,
402,16,195,1,50,1
402,16,206,4,0,
402,16,207,4,0,
402,16,210,1,10,1
402,16,213,4,0,
402,16,214,4,0,
402,16,215,3,0,
402,16,216,4,0,
402,16,218,4,0,
402,16,237,4,0,
402,16,240,4,0,
402,16,241,4,0,
402,16,249,4,0,
402,16,253,3,0,
402,16,263,4,0,
402,16,269,1,38,1
402,16,269,4,0,
402,16,280,4,0,
402,16,282,3,0,
402,16,283,3,0,
402,16,290,4,0,
402,16,304,3,0,
402,16,332,4,0,
402,16,400,1,42,1
402,16,404,1,30,1
402,16,404,4,0,
402,16,405,1,46,1
402,16,416,4,0,
402,16,450,3,0,
402,16,468,4,0,
402,16,496,4,0,
402,16,497,4,0,
402,16,522,4,0,
402,16,564,1,44,1
402,16,565,1,36,1
402,16,590,4,0,
402,16,611,4,0,
402,16,612,4,0,
402,17,14,4,0,
402,17,45,1,1,2
402,17,47,1,18,
402,17,63,4,0,
402,17,71,1,14,
402,17,92,4,0,
402,17,103,1,34,
402,17,104,4,0,
402,17,116,1,22,
402,17,117,1,1,3
402,17,141,4,0,
402,17,156,4,0,
402,17,163,1,26,
402,17,164,4,0,
402,17,182,4,0,
402,17,195,1,50,
402,17,206,4,0,
402,17,207,4,0,
402,17,210,1,0,
402,17,210,1,1,1
402,17,213,4,0,
402,17,214,4,0,
402,17,216,4,0,
402,17,218,4,0,
402,17,237,4,0,
402,17,240,4,0,
402,17,241,4,0,
402,17,263,4,0,
402,17,269,1,38,
402,17,269,4,0,
402,17,280,4,0,
402,17,332,4,0,
402,17,400,1,42,
402,17,404,1,30,
402,17,404,4,0,
402,17,405,1,46,
402,17,416,4,0,
402,17,496,4,0,
402,17,497,4,0,
402,17,564,1,44,
402,17,565,1,36,
402,17,590,4,0,
402,17,611,4,0,
402,18,14,4,0,
402,18,45,1,1,2
402,18,47,1,18,
402,18,63,4,0,
402,18,71,1,14,
402,18,92,4,0,
402,18,103,1,34,
402,18,104,4,0,
402,18,116,1,22,
402,18,117,1,1,3
402,18,141,4,0,
402,18,156,4,0,
402,18,163,1,26,
402,18,164,4,0,
402,18,182,4,0,
402,18,195,1,50,
402,18,206,4,0,
402,18,207,4,0,
402,18,210,1,0,
402,18,210,1,1,1
402,18,213,4,0,
402,18,214,4,0,
402,18,216,4,0,
402,18,218,4,0,
402,18,237,4,0,
402,18,240,4,0,
402,18,241,4,0,
402,18,263,4,0,
402,18,269,1,38,
402,18,269,4,0,
402,18,280,4,0,
402,18,332,4,0,
402,18,400,1,42,
402,18,404,1,30,
402,18,404,4,0,
402,18,405,1,46,
402,18,416,4,0,
402,18,496,4,0,
402,18,497,4,0,
402,18,564,1,44,
402,18,565,1,36,
402,18,590,4,0,
402,18,611,4,0,
403,8,33,1,1,
403,8,36,2,0,
403,8,43,1,5,
403,8,44,1,13,
403,8,46,1,21,
403,8,46,4,0,
403,8,70,4,0,
403,8,85,4,0,
403,8,86,4,0,
403,8,87,4,0,
403,8,92,4,0,
403,8,98,2,0,
403,8,104,4,0,
403,8,113,4,0,
403,8,148,4,0,
403,8,156,4,0,
403,8,164,4,0,
403,8,168,4,0,
403,8,182,4,0,
403,8,184,1,37,
403,8,203,4,0,
403,8,207,1,25,
403,8,207,4,0,
403,8,209,1,17,
403,8,213,4,0,
403,8,214,4,0,
403,8,216,4,0,
403,8,218,4,0,
403,8,231,4,0,
403,8,237,4,0,
403,8,240,4,0,
403,8,242,1,29,
403,8,263,4,0,
403,8,268,1,9,
403,8,290,4,0,
403,8,336,2,0,
403,8,351,4,0,
403,8,363,4,0,
403,8,422,1,33,
403,8,422,2,0,
403,8,423,2,0,
403,8,424,2,0,
403,8,435,1,41,
403,8,445,4,0,
403,8,451,4,0,
403,9,33,1,1,
403,9,36,2,0,
403,9,43,1,5,
403,9,44,1,17,
403,9,46,1,21,
403,9,46,4,0,
403,9,70,4,0,
403,9,85,4,0,
403,9,86,4,0,
403,9,87,4,0,
403,9,92,4,0,
403,9,98,2,0,
403,9,104,4,0,
403,9,113,4,0,
403,9,129,3,0,
403,9,148,4,0,
403,9,156,4,0,
403,9,164,4,0,
403,9,168,4,0,
403,9,173,3,0,
403,9,182,4,0,
403,9,184,1,37,
403,9,189,3,0,
403,9,203,4,0,
403,9,207,1,25,
403,9,207,4,0,
403,9,209,1,13,
403,9,210,3,0,
403,9,213,4,0,
403,9,214,4,0,
403,9,216,4,0,
403,9,218,4,0,
403,9,231,4,0,
403,9,237,4,0,
403,9,240,4,0,
403,9,242,1,33,
403,9,263,4,0,
403,9,268,1,9,
403,9,290,4,0,
403,9,324,3,0,
403,9,336,2,0,
403,9,351,4,0,
403,9,363,4,0,
403,9,393,3,0,
403,9,422,1,29,
403,9,422,2,0,
403,9,423,2,0,
403,9,424,2,0,
403,9,435,1,41,
403,9,445,4,0,
403,9,451,4,0,
403,10,29,3,0,
403,10,33,1,1,
403,10,36,2,0,
403,10,43,1,5,
403,10,44,1,17,
403,10,46,1,21,
403,10,46,4,0,
403,10,70,4,0,
403,10,85,4,0,
403,10,86,4,0,
403,10,87,4,0,
403,10,92,4,0,
403,10,98,2,0,
403,10,104,4,0,
403,10,113,4,0,
403,10,129,3,0,
403,10,148,4,0,
403,10,156,4,0,
403,10,164,4,0,
403,10,168,4,0,
403,10,173,3,0,
403,10,182,4,0,
403,10,184,1,37,
403,10,189,3,0,
403,10,203,4,0,
403,10,207,1,25,
403,10,207,4,0,
403,10,209,1,13,
403,10,210,3,0,
403,10,213,4,0,
403,10,214,4,0,
403,10,216,4,0,
403,10,218,4,0,
403,10,231,4,0,
403,10,237,4,0,
403,10,240,4,0,
403,10,242,1,33,
403,10,263,4,0,
403,10,268,1,9,
403,10,290,4,0,
403,10,324,3,0,
403,10,336,2,0,
403,10,351,4,0,
403,10,363,4,0,
403,10,393,3,0,
403,10,400,2,0,
403,10,422,1,29,
403,10,422,2,0,
403,10,423,2,0,
403,10,424,2,0,
403,10,435,1,41,
403,10,445,4,0,
403,10,451,4,0,
403,11,24,2,0,
403,11,33,1,1,
403,11,36,2,0,
403,11,43,1,5,
403,11,44,1,17,
403,11,46,1,21,
403,11,46,4,0,
403,11,70,4,0,
403,11,85,4,0,
403,11,86,4,0,
403,11,87,4,0,
403,11,92,4,0,
403,11,98,2,0,
403,11,104,4,0,
403,11,113,4,0,
403,11,129,2,0,
403,11,148,4,0,
403,11,156,4,0,
403,11,164,4,0,
403,11,168,4,0,
403,11,182,4,0,
403,11,184,1,37,
403,11,207,1,25,
403,11,207,4,0,
403,11,209,1,13,
403,11,213,4,0,
403,11,216,4,0,
403,11,218,4,0,
403,11,237,4,0,
403,11,240,4,0,
403,11,242,1,33,
403,11,263,4,0,
403,11,268,1,9,
403,11,270,2,0,
403,11,324,2,0,
403,11,336,2,0,
403,11,351,2,0,
403,11,400,2,0,
403,11,422,1,29,
403,11,422,2,0,
403,11,423,2,0,
403,11,424,2,0,
403,11,435,1,41,
403,11,451,4,0,
403,11,496,4,0,
403,11,521,4,0,
403,11,528,1,45,
403,11,528,4,0,
403,11,555,4,0,
403,14,24,2,0,
403,14,33,1,1,
403,14,36,2,0,
403,14,43,1,5,
403,14,44,1,17,
403,14,46,1,21,
403,14,46,4,0,
403,14,70,4,0,
403,14,85,4,0,
403,14,86,4,0,
403,14,87,4,0,
403,14,92,4,0,
403,14,98,2,0,
403,14,104,4,0,
403,14,113,4,0,
403,14,129,2,0,
403,14,148,4,0,
403,14,156,4,0,
403,14,164,4,0,
403,14,168,4,0,
403,14,173,3,0,
403,14,182,4,0,
403,14,184,1,37,
403,14,207,1,25,
403,14,207,4,0,
403,14,209,1,13,
403,14,213,4,0,
403,14,214,3,0,
403,14,216,4,0,
403,14,218,4,0,
403,14,231,3,0,
403,14,237,4,0,
403,14,240,4,0,
403,14,242,1,33,
403,14,263,4,0,
403,14,268,1,9,
403,14,270,2,0,
403,14,324,2,0,
403,14,324,3,0,
403,14,336,2,0,
403,14,351,2,0,
403,14,393,3,0,
403,14,400,2,0,
403,14,422,1,29,
403,14,422,2,0,
403,14,423,2,0,
403,14,424,2,0,
403,14,435,1,41,
403,14,451,4,0,
403,14,496,4,0,
403,14,521,4,0,
403,14,528,1,45,
403,14,528,4,0,
403,14,555,4,0,
403,15,24,2,0,
403,15,33,1,1,
403,15,36,2,0,
403,15,43,1,5,
403,15,44,1,17,
403,15,46,1,21,
403,15,46,4,0,
403,15,70,4,0,
403,15,85,4,0,
403,15,86,4,0,
403,15,87,4,0,
403,15,92,4,0,
403,15,98,2,0,
403,15,104,4,0,
403,15,113,4,0,
403,15,129,2,0,
403,15,148,4,0,
403,15,156,4,0,
403,15,164,4,0,
403,15,168,4,0,
403,15,182,4,0,
403,15,184,1,37,
403,15,207,1,25,
403,15,207,4,0,
403,15,209,1,13,
403,15,213,4,0,
403,15,214,4,0,
403,15,216,4,0,
403,15,218,4,0,
403,15,237,4,0,
403,15,240,4,0,
403,15,242,1,33,
403,15,263,4,0,
403,15,268,1,9,
403,15,270,2,0,
403,15,313,2,0,
403,15,324,2,0,
403,15,336,2,0,
403,15,351,2,0,
403,15,400,2,0,
403,15,422,1,29,
403,15,422,2,0,
403,15,423,2,0,
403,15,424,2,0,
403,15,435,1,41,
403,15,451,4,0,
403,15,496,4,0,
403,15,521,4,0,
403,15,528,1,45,
403,15,528,4,0,
403,15,555,4,0,
403,15,590,4,0,
403,15,598,2,0,
403,15,608,1,11,
403,16,24,2,0,
403,16,33,1,1,1
403,16,36,2,0,
403,16,43,1,5,1
403,16,44,1,17,1
403,16,46,1,21,1
403,16,46,4,0,
403,16,70,4,0,
403,16,85,4,0,
403,16,86,4,0,
403,16,87,4,0,
403,16,92,4,0,
403,16,98,2,0,
403,16,104,4,0,
403,16,113,4,0,
403,16,129,2,0,
403,16,148,4,0,
403,16,156,4,0,
403,16,164,4,0,
403,16,168,4,0,
403,16,173,3,0,
403,16,182,4,0,
403,16,184,1,37,1
403,16,207,1,25,1
403,16,207,4,0,
403,16,209,1,13,1
403,16,213,4,0,
403,16,214,4,0,
403,16,216,4,0,
403,16,218,4,0,
403,16,231,3,0,
403,16,237,4,0,
403,16,240,4,0,
403,16,242,1,33,1
403,16,263,4,0,
403,16,268,1,9,1
403,16,270,2,0,
403,16,270,3,0,
403,16,290,4,0,
403,16,313,2,0,
403,16,324,2,0,
403,16,324,3,0,
403,16,336,2,0,
403,16,351,2,0,
403,16,351,3,0,
403,16,393,3,0,
403,16,400,2,0,
403,16,422,1,29,1
403,16,422,2,0,
403,16,423,2,0,
403,16,424,2,0,
403,16,435,1,41,1
403,16,451,4,0,
403,16,496,4,0,
403,16,521,4,0,
403,16,528,1,45,1
403,16,528,4,0,
403,16,555,4,0,
403,16,590,4,0,
403,16,598,2,0,
403,16,608,1,11,1
403,17,24,2,0,
403,17,33,1,1,
403,17,36,2,0,
403,17,43,1,5,
403,17,44,1,17,
403,17,46,1,21,
403,17,46,4,0,
403,17,85,4,0,
403,17,86,4,0,
403,17,87,4,0,
403,17,92,4,0,
403,17,98,2,0,
403,17,104,4,0,
403,17,113,4,0,
403,17,129,2,0,
403,17,156,4,0,
403,17,164,4,0,
403,17,168,4,0,
403,17,182,4,0,
403,17,184,1,37,
403,17,207,1,25,
403,17,207,4,0,
403,17,209,1,13,
403,17,213,4,0,
403,17,214,4,0,
403,17,216,4,0,
403,17,218,4,0,
403,17,237,4,0,
403,17,240,4,0,
403,17,242,1,33,
403,17,263,4,0,
403,17,268,1,9,
403,17,270,2,0,
403,17,313,2,0,
403,17,324,2,0,
403,17,336,2,0,
403,17,351,2,0,
403,17,400,2,0,
403,17,422,1,29,
403,17,422,2,0,
403,17,423,2,0,
403,17,424,2,0,
403,17,435,1,41,
403,17,451,4,0,
403,17,496,4,0,
403,17,521,4,0,
403,17,528,1,45,
403,17,528,4,0,
403,17,555,4,0,
403,17,590,4,0,
403,17,598,2,0,
403,17,608,1,11,
403,18,24,2,0,
403,18,33,1,1,
403,18,36,2,0,
403,18,43,1,5,
403,18,44,1,17,
403,18,46,1,21,
403,18,46,4,0,
403,18,85,4,0,
403,18,86,4,0,
403,18,87,4,0,
403,18,92,4,0,
403,18,98,2,0,
403,18,104,4,0,
403,18,113,4,0,
403,18,129,2,0,
403,18,156,4,0,
403,18,164,4,0,
403,18,168,4,0,
403,18,182,4,0,
403,18,184,1,37,
403,18,207,1,25,
403,18,207,4,0,
403,18,209,1,13,
403,18,213,4,0,
403,18,214,4,0,
403,18,216,4,0,
403,18,218,4,0,
403,18,237,4,0,
403,18,240,4,0,
403,18,242,1,33,
403,18,263,4,0,
403,18,268,1,9,
403,18,270,2,0,
403,18,313,2,0,
403,18,324,2,0,
403,18,336,2,0,
403,18,351,2,0,
403,18,400,2,0,
403,18,422,1,29,
403,18,422,2,0,
403,18,423,2,0,
403,18,424,2,0,
403,18,435,1,41,
403,18,451,4,0,
403,18,496,4,0,
403,18,521,4,0,
403,18,528,1,45,
403,18,528,4,0,
403,18,555,4,0,
403,18,590,4,0,
403,18,598,2,0,
403,18,608,1,11,
404,8,33,1,1,1
404,8,43,1,1,2
404,8,43,1,5,
404,8,44,1,13,
404,8,46,1,23,
404,8,46,4,0,
404,8,70,4,0,
404,8,85,4,0,
404,8,86,4,0,
404,8,87,4,0,
404,8,92,4,0,
404,8,104,4,0,
404,8,113,4,0,
404,8,148,4,0,
404,8,156,4,0,
404,8,164,4,0,
404,8,168,4,0,
404,8,182,4,0,
404,8,184,1,43,
404,8,203,4,0,
404,8,207,1,28,
404,8,207,4,0,
404,8,209,1,18,
404,8,213,4,0,
404,8,214,4,0,
404,8,216,4,0,
404,8,218,4,0,
404,8,231,4,0,
404,8,237,4,0,
404,8,240,4,0,
404,8,242,1,33,
404,8,263,4,0,
404,8,268,1,9,
404,8,290,4,0,
404,8,351,4,0,
404,8,363,4,0,
404,8,422,1,38,
404,8,435,1,48,
404,8,445,4,0,
404,8,451,4,0,
404,9,33,1,1,1
404,9,43,1,1,2
404,9,43,1,5,
404,9,44,1,18,
404,9,46,1,23,
404,9,46,4,0,
404,9,70,4,0,
404,9,85,4,0,
404,9,86,4,0,
404,9,87,4,0,
404,9,92,4,0,
404,9,104,4,0,
404,9,113,4,0,
404,9,129,3,0,
404,9,148,4,0,
404,9,156,4,0,
404,9,164,4,0,
404,9,168,4,0,
404,9,173,3,0,
404,9,182,4,0,
404,9,184,1,43,
404,9,189,3,0,
404,9,203,4,0,
404,9,207,1,28,
404,9,207,4,0,
404,9,209,1,13,
404,9,210,3,0,
404,9,213,4,0,
404,9,214,4,0,
404,9,216,4,0,
404,9,218,4,0,
404,9,231,4,0,
404,9,237,4,0,
404,9,240,4,0,
404,9,242,1,38,
404,9,263,4,0,
404,9,268,1,9,
404,9,290,4,0,
404,9,324,3,0,
404,9,351,4,0,
404,9,363,4,0,
404,9,393,3,0,
404,9,422,1,33,
404,9,435,1,48,
404,9,445,4,0,
404,9,451,4,0,
404,10,29,3,0,
404,10,33,1,1,1
404,10,43,1,1,2
404,10,43,1,5,
404,10,44,1,18,
404,10,46,1,23,
404,10,46,4,0,
404,10,70,4,0,
404,10,85,4,0,
404,10,86,4,0,
404,10,87,4,0,
404,10,92,4,0,
404,10,104,4,0,
404,10,113,4,0,
404,10,129,3,0,
404,10,148,4,0,
404,10,156,4,0,
404,10,164,4,0,
404,10,168,4,0,
404,10,173,3,0,
404,10,182,4,0,
404,10,184,1,43,
404,10,189,3,0,
404,10,203,4,0,
404,10,207,1,28,
404,10,207,4,0,
404,10,209,1,13,
404,10,210,3,0,
404,10,213,4,0,
404,10,214,4,0,
404,10,216,4,0,
404,10,218,4,0,
404,10,231,4,0,
404,10,237,4,0,
404,10,240,4,0,
404,10,242,1,38,
404,10,263,4,0,
404,10,268,1,9,
404,10,290,4,0,
404,10,324,3,0,
404,10,351,4,0,
404,10,363,4,0,
404,10,393,3,0,
404,10,422,1,33,
404,10,435,1,48,
404,10,445,4,0,
404,10,451,4,0,
404,11,33,1,1,1
404,11,43,1,1,2
404,11,43,1,5,
404,11,44,1,18,
404,11,46,1,23,
404,11,46,4,0,
404,11,70,4,0,
404,11,85,4,0,
404,11,86,4,0,
404,11,87,4,0,
404,11,92,4,0,
404,11,104,4,0,
404,11,113,4,0,
404,11,148,4,0,
404,11,156,4,0,
404,11,164,4,0,
404,11,168,4,0,
404,11,182,4,0,
404,11,184,1,43,
404,11,207,1,28,
404,11,207,4,0,
404,11,209,1,13,
404,11,213,4,0,
404,11,216,4,0,
404,11,218,4,0,
404,11,237,4,0,
404,11,240,4,0,
404,11,242,1,38,
404,11,263,4,0,
404,11,268,1,9,
404,11,422,1,33,
404,11,435,1,48,
404,11,451,4,0,
404,11,496,4,0,
404,11,521,4,0,
404,11,528,1,53,
404,11,528,4,0,
404,11,555,4,0,
404,14,33,1,1,1
404,14,43,1,1,2
404,14,43,1,5,
404,14,44,1,18,
404,14,46,1,23,
404,14,46,4,0,
404,14,70,4,0,
404,14,85,4,0,
404,14,86,4,0,
404,14,87,4,0,
404,14,92,4,0,
404,14,104,4,0,
404,14,113,4,0,
404,14,148,4,0,
404,14,156,4,0,
404,14,164,4,0,
404,14,168,4,0,
404,14,173,3,0,
404,14,182,4,0,
404,14,184,1,43,
404,14,207,1,28,
404,14,207,4,0,
404,14,209,1,13,
404,14,213,4,0,
404,14,214,3,0,
404,14,216,4,0,
404,14,218,4,0,
404,14,231,3,0,
404,14,237,4,0,
404,14,240,4,0,
404,14,242,1,38,
404,14,263,4,0,
404,14,268,1,9,
404,14,324,3,0,
404,14,393,3,0,
404,14,422,1,33,
404,14,435,1,48,
404,14,451,4,0,
404,14,496,4,0,
404,14,521,4,0,
404,14,528,1,53,
404,14,528,4,0,
404,14,555,4,0,
404,15,33,1,1,1
404,15,43,1,1,2
404,15,43,1,5,
404,15,44,1,18,
404,15,46,1,23,
404,15,46,4,0,
404,15,70,4,0,
404,15,85,4,0,
404,15,86,4,0,
404,15,87,4,0,
404,15,92,4,0,
404,15,104,4,0,
404,15,113,4,0,
404,15,148,4,0,
404,15,156,4,0,
404,15,164,4,0,
404,15,168,4,0,
404,15,182,4,0,
404,15,184,1,43,
404,15,207,1,28,
404,15,207,4,0,
404,15,209,1,13,
404,15,213,4,0,
404,15,214,4,0,
404,15,216,4,0,
404,15,218,4,0,
404,15,237,4,0,
404,15,240,4,0,
404,15,242,1,38,
404,15,263,4,0,
404,15,268,1,9,
404,15,422,1,33,
404,15,435,1,48,
404,15,451,4,0,
404,15,496,4,0,
404,15,521,4,0,
404,15,528,1,53,
404,15,528,4,0,
404,15,555,4,0,
404,15,590,4,0,
404,16,33,1,1,1
404,16,43,1,1,2
404,16,43,1,5,1
404,16,44,1,18,1
404,16,46,1,23,1
404,16,46,4,0,
404,16,70,4,0,
404,16,85,4,0,
404,16,86,4,0,
404,16,87,4,0,
404,16,92,4,0,
404,16,104,4,0,
404,16,113,4,0,
404,16,148,4,0,
404,16,156,4,0,
404,16,164,4,0,
404,16,168,4,0,
404,16,173,3,0,
404,16,182,4,0,
404,16,184,1,43,1
404,16,207,1,28,1
404,16,207,4,0,
404,16,209,1,13,1
404,16,213,4,0,
404,16,214,4,0,
404,16,216,4,0,
404,16,218,4,0,
404,16,231,3,0,
404,16,237,4,0,
404,16,240,4,0,
404,16,242,1,38,1
404,16,263,4,0,
404,16,268,1,9,1
404,16,270,3,0,
404,16,290,4,0,
404,16,324,3,0,
404,16,351,3,0,
404,16,393,3,0,
404,16,422,1,33,1
404,16,435,1,48,1
404,16,451,4,0,
404,16,496,4,0,
404,16,521,4,0,
404,16,528,1,53,1
404,16,528,4,0,
404,16,555,4,0,
404,16,590,4,0,
404,17,33,1,1,1
404,17,43,1,1,2
404,17,43,1,5,
404,17,44,1,18,
404,17,46,1,23,
404,17,46,4,0,
404,17,85,4,0,
404,17,86,4,0,
404,17,87,4,0,
404,17,92,4,0,
404,17,104,4,0,
404,17,113,4,0,
404,17,156,4,0,
404,17,164,4,0,
404,17,168,4,0,
404,17,182,4,0,
404,17,184,1,43,
404,17,207,1,28,
404,17,207,4,0,
404,17,209,1,13,
404,17,213,4,0,
404,17,214,4,0,
404,17,216,4,0,
404,17,218,4,0,
404,17,237,4,0,
404,17,240,4,0,
404,17,242,1,38,
404,17,263,4,0,
404,17,268,1,9,
404,17,422,1,33,
404,17,435,1,48,
404,17,451,4,0,
404,17,496,4,0,
404,17,521,4,0,
404,17,528,1,53,
404,17,528,4,0,
404,17,555,4,0,
404,17,590,4,0,
404,18,33,1,1,1
404,18,43,1,1,2
404,18,43,1,5,
404,18,44,1,18,
404,18,46,1,23,
404,18,46,4,0,
404,18,85,4,0,
404,18,86,4,0,
404,18,87,4,0,
404,18,92,4,0,
404,18,104,4,0,
404,18,113,4,0,
404,18,156,4,0,
404,18,164,4,0,
404,18,168,4,0,
404,18,182,4,0,
404,18,184,1,43,
404,18,207,1,28,
404,18,207,4,0,
404,18,209,1,13,
404,18,213,4,0,
404,18,214,4,0,
404,18,216,4,0,
404,18,218,4,0,
404,18,237,4,0,
404,18,240,4,0,
404,18,242,1,38,
404,18,263,4,0,
404,18,268,1,9,
404,18,422,1,33,
404,18,435,1,48,
404,18,451,4,0,
404,18,496,4,0,
404,18,521,4,0,
404,18,528,1,53,
404,18,528,4,0,
404,18,555,4,0,
404,18,590,4,0,
405,8,33,1,1,1
405,8,43,1,1,2
405,8,43,1,5,
405,8,44,1,13,
405,8,46,1,23,
405,8,46,4,0,
405,8,63,4,0,
405,8,70,4,0,
405,8,85,4,0,
405,8,86,4,0,
405,8,87,4,0,
405,8,92,4,0,
405,8,104,4,0,
405,8,113,4,0,
405,8,148,4,0,
405,8,156,4,0,
405,8,164,4,0,
405,8,168,4,0,
405,8,182,4,0,
405,8,184,1,49,
405,8,203,4,0,
405,8,207,1,28,
405,8,207,4,0,
405,8,209,1,18,
405,8,213,4,0,
405,8,214,4,0,
405,8,216,4,0,
405,8,218,4,0,
405,8,231,4,0,
405,8,237,4,0,
405,8,240,4,0,
405,8,242,1,35,
405,8,263,4,0,
405,8,268,1,1,3
405,8,268,1,9,
405,8,290,4,0,
405,8,351,4,0,
405,8,363,4,0,
405,8,416,4,0,
405,8,422,1,42,
405,8,435,1,56,
405,8,445,4,0,
405,8,451,4,0,
405,9,33,1,1,1
405,9,43,1,1,2
405,9,43,1,5,
405,9,44,1,18,
405,9,46,1,23,
405,9,46,4,0,
405,9,63,4,0,
405,9,70,4,0,
405,9,85,4,0,
405,9,86,4,0,
405,9,87,4,0,
405,9,92,4,0,
405,9,104,4,0,
405,9,113,4,0,
405,9,129,3,0,
405,9,148,4,0,
405,9,156,4,0,
405,9,164,4,0,
405,9,168,4,0,
405,9,173,3,0,
405,9,182,4,0,
405,9,184,1,49,
405,9,189,3,0,
405,9,203,4,0,
405,9,207,1,28,
405,9,207,4,0,
405,9,209,1,13,
405,9,210,3,0,
405,9,213,4,0,
405,9,214,4,0,
405,9,216,4,0,
405,9,218,4,0,
405,9,231,4,0,
405,9,237,4,0,
405,9,240,4,0,
405,9,242,1,42,
405,9,263,4,0,
405,9,268,1,1,3
405,9,268,1,9,
405,9,276,3,0,
405,9,290,4,0,
405,9,324,3,0,
405,9,351,4,0,
405,9,363,4,0,
405,9,393,3,0,
405,9,416,4,0,
405,9,422,1,35,
405,9,435,1,56,
405,9,445,4,0,
405,9,451,4,0,
405,10,29,3,0,
405,10,33,1,1,1
405,10,43,1,1,2
405,10,43,1,5,
405,10,44,1,18,
405,10,46,1,23,
405,10,46,4,0,
405,10,63,4,0,
405,10,70,4,0,
405,10,85,4,0,
405,10,86,4,0,
405,10,87,4,0,
405,10,92,4,0,
405,10,104,4,0,
405,10,113,4,0,
405,10,129,3,0,
405,10,148,4,0,
405,10,156,4,0,
405,10,164,4,0,
405,10,168,4,0,
405,10,173,3,0,
405,10,182,4,0,
405,10,184,1,49,
405,10,189,3,0,
405,10,203,4,0,
405,10,207,1,28,
405,10,207,4,0,
405,10,209,1,13,
405,10,210,3,0,
405,10,213,4,0,
405,10,214,4,0,
405,10,216,4,0,
405,10,218,4,0,
405,10,231,4,0,
405,10,237,4,0,
405,10,240,4,0,
405,10,242,1,42,
405,10,263,4,0,
405,10,268,1,1,3
405,10,268,1,9,
405,10,276,3,0,
405,10,290,4,0,
405,10,324,3,0,
405,10,351,4,0,
405,10,363,4,0,
405,10,393,3,0,
405,10,416,4,0,
405,10,422,1,35,
405,10,435,1,56,
405,10,445,4,0,
405,10,451,4,0,
405,11,33,1,1,1
405,11,43,1,1,2
405,11,43,1,5,
405,11,44,1,18,
405,11,46,1,23,
405,11,46,4,0,
405,11,63,4,0,
405,11,70,4,0,
405,11,85,4,0,
405,11,86,4,0,
405,11,87,4,0,
405,11,92,4,0,
405,11,104,4,0,
405,11,113,4,0,
405,11,148,4,0,
405,11,156,4,0,
405,11,164,4,0,
405,11,168,4,0,
405,11,182,4,0,
405,11,184,1,49,
405,11,207,1,28,
405,11,207,4,0,
405,11,209,1,13,
405,11,213,4,0,
405,11,216,4,0,
405,11,218,4,0,
405,11,237,4,0,
405,11,240,4,0,
405,11,242,1,42,
405,11,263,4,0,
405,11,268,1,1,3
405,11,268,1,9,
405,11,416,4,0,
405,11,422,1,35,
405,11,435,1,56,
405,11,451,4,0,
405,11,496,4,0,
405,11,521,4,0,
405,11,528,1,63,
405,11,528,4,0,
405,11,555,4,0,
405,14,33,1,1,1
405,14,43,1,1,2
405,14,43,1,5,
405,14,44,1,18,
405,14,46,1,23,
405,14,46,4,0,
405,14,63,4,0,
405,14,70,4,0,
405,14,85,4,0,
405,14,86,4,0,
405,14,87,4,0,
405,14,92,4,0,
405,14,104,4,0,
405,14,113,4,0,
405,14,148,4,0,
405,14,156,4,0,
405,14,164,4,0,
405,14,168,4,0,
405,14,173,3,0,
405,14,182,4,0,
405,14,184,1,49,
405,14,207,1,28,
405,14,207,4,0,
405,14,209,1,13,
405,14,213,4,0,
405,14,214,3,0,
405,14,216,4,0,
405,14,218,4,0,
405,14,231,3,0,
405,14,237,4,0,
405,14,240,4,0,
405,14,242,1,42,
405,14,263,4,0,
405,14,268,1,1,3
405,14,268,1,9,
405,14,276,3,0,
405,14,324,3,0,
405,14,393,3,0,
405,14,416,4,0,
405,14,422,1,35,
405,14,435,1,56,
405,14,451,4,0,
405,14,496,4,0,
405,14,521,4,0,
405,14,528,1,63,
405,14,528,4,0,
405,14,555,4,0,
405,15,33,1,1,2
405,15,43,1,1,3
405,15,43,1,5,
405,15,44,1,18,
405,15,46,1,23,
405,15,46,4,0,
405,15,63,4,0,
405,15,70,4,0,
405,15,85,4,0,
405,15,86,4,0,
405,15,87,4,0,
405,15,92,4,0,
405,15,104,4,0,
405,15,113,4,0,
405,15,148,4,0,
405,15,156,4,0,
405,15,164,4,0,
405,15,168,4,0,
405,15,182,4,0,
405,15,184,1,49,
405,15,207,1,28,
405,15,207,4,0,
405,15,209,1,13,
405,15,213,4,0,
405,15,214,4,0,
405,15,216,4,0,
405,15,218,4,0,
405,15,237,4,0,
405,15,240,4,0,
405,15,242,1,42,
405,15,263,4,0,
405,15,268,1,1,4
405,15,268,1,9,
405,15,416,4,0,
405,15,422,1,35,
405,15,435,1,56,
405,15,451,4,0,
405,15,496,4,0,
405,15,521,4,0,
405,15,528,1,63,
405,15,528,4,0,
405,15,555,4,0,
405,15,590,4,0,
405,15,604,1,1,1
405,15,604,1,67,
405,16,33,1,1,2
405,16,43,1,1,3
405,16,43,1,5,1
405,16,44,1,18,1
405,16,46,1,23,1
405,16,46,4,0,
405,16,63,4,0,
405,16,70,4,0,
405,16,85,4,0,
405,16,86,4,0,
405,16,87,4,0,
405,16,92,4,0,
405,16,104,4,0,
405,16,113,4,0,
405,16,148,4,0,
405,16,156,4,0,
405,16,164,4,0,
405,16,168,4,0,
405,16,173,3,0,
405,16,182,4,0,
405,16,184,1,49,1
405,16,207,1,28,1
405,16,207,4,0,
405,16,209,1,13,1
405,16,213,4,0,
405,16,214,4,0,
405,16,216,4,0,
405,16,218,4,0,
405,16,231,3,0,
405,16,237,4,0,
405,16,240,4,0,
405,16,242,1,42,1
405,16,263,4,0,
405,16,268,1,1,4
405,16,268,1,9,1
405,16,270,3,0,
405,16,276,3,0,
405,16,290,4,0,
405,16,324,3,0,
405,16,351,3,0,
405,16,393,3,0,
405,16,416,4,0,
405,16,422,1,35,1
405,16,435,1,56,1
405,16,451,4,0,
405,16,496,4,0,
405,16,521,4,0,
405,16,528,1,63,1
405,16,528,4,0,
405,16,555,4,0,
405,16,590,4,0,
405,16,604,1,1,1
405,16,604,1,67,1
405,17,33,1,1,2
405,17,43,1,1,3
405,17,43,1,5,
405,17,44,1,18,
405,17,46,1,23,
405,17,46,4,0,
405,17,63,4,0,
405,17,85,4,0,
405,17,86,4,0,
405,17,87,4,0,
405,17,92,4,0,
405,17,104,4,0,
405,17,113,4,0,
405,17,156,4,0,
405,17,164,4,0,
405,17,168,4,0,
405,17,182,4,0,
405,17,184,1,49,
405,17,207,1,28,
405,17,207,4,0,
405,17,209,1,13,
405,17,213,4,0,
405,17,214,4,0,
405,17,216,4,0,
405,17,218,4,0,
405,17,237,4,0,
405,17,240,4,0,
405,17,242,1,42,
405,17,263,4,0,
405,17,268,1,1,4
405,17,268,1,9,
405,17,416,4,0,
405,17,422,1,35,
405,17,435,1,56,
405,17,451,4,0,
405,17,496,4,0,
405,17,521,4,0,
405,17,528,1,63,
405,17,528,4,0,
405,17,555,4,0,
405,17,590,4,0,
405,17,604,1,1,1
405,17,604,1,67,
405,18,33,1,1,2
405,18,43,1,1,3
405,18,43,1,5,
405,18,44,1,18,
405,18,46,1,23,
405,18,46,4,0,
405,18,63,4,0,
405,18,85,4,0,
405,18,86,4,0,
405,18,87,4,0,
405,18,92,4,0,
405,18,104,4,0,
405,18,113,4,0,
405,18,156,4,0,
405,18,164,4,0,
405,18,168,4,0,
405,18,182,4,0,
405,18,184,1,49,
405,18,207,1,28,
405,18,207,4,0,
405,18,209,1,13,
405,18,213,4,0,
405,18,214,4,0,
405,18,216,4,0,
405,18,218,4,0,
405,18,237,4,0,
405,18,240,4,0,
405,18,242,1,42,
405,18,263,4,0,
405,18,268,1,1,4
405,18,268,1,9,
405,18,416,4,0,
405,18,422,1,35,
405,18,435,1,56,
405,18,451,4,0,
405,18,496,4,0,
405,18,521,4,0,
405,18,528,1,63,
405,18,528,4,0,
405,18,555,4,0,
405,18,590,4,0,
405,18,604,1,1,1
405,18,604,1,67,
406,8,14,4,0,
406,8,15,4,0,
406,8,42,2,0,
406,8,71,1,1,
406,8,72,1,13,
406,8,74,1,4,
406,8,75,2,0,
406,8,76,4,0,
406,8,78,1,10,
406,8,79,2,0,
406,8,92,4,0,
406,8,104,4,0,
406,8,148,4,0,
406,8,156,4,0,
406,8,164,4,0,
406,8,170,2,0,
406,8,178,2,0,
406,8,182,4,0,
406,8,188,4,0,
406,8,191,2,0,
406,8,202,4,0,
406,8,203,4,0,
406,8,207,4,0,
406,8,213,4,0,
406,8,214,4,0,
406,8,216,4,0,
406,8,218,4,0,
406,8,235,2,0,
406,8,237,4,0,
406,8,240,4,0,
406,8,241,4,0,
406,8,244,4,0,
406,8,247,4,0,
406,8,263,4,0,
406,8,290,4,0,
406,8,326,2,0,
406,8,331,4,0,
406,8,346,1,7,
406,8,363,4,0,
406,8,388,1,16,
406,8,412,4,0,
406,8,437,2,0,
406,8,445,4,0,
406,8,447,4,0,
406,9,14,4,0,
406,9,15,4,0,
406,9,42,2,0,
406,9,71,1,1,
406,9,72,1,13,
406,9,74,1,4,
406,9,75,2,0,
406,9,76,4,0,
406,9,78,1,10,
406,9,79,2,0,
406,9,92,4,0,
406,9,104,4,0,
406,9,129,3,0,
406,9,148,4,0,
406,9,156,4,0,
406,9,164,4,0,
406,9,170,2,0,
406,9,173,3,0,
406,9,178,2,0,
406,9,182,4,0,
406,9,188,4,0,
406,9,189,3,0,
406,9,191,2,0,
406,9,202,4,0,
406,9,203,4,0,
406,9,207,4,0,
406,9,213,4,0,
406,9,214,4,0,
406,9,216,4,0,
406,9,218,4,0,
406,9,235,2,0,
406,9,235,3,0,
406,9,237,4,0,
406,9,240,4,0,
406,9,241,4,0,
406,9,244,4,0,
406,9,247,4,0,
406,9,253,3,0,
406,9,263,4,0,
406,9,290,4,0,
406,9,326,2,0,
406,9,331,4,0,
406,9,346,1,7,
406,9,363,4,0,
406,9,388,1,16,
406,9,402,3,0,
406,9,412,4,0,
406,9,437,2,0,
406,9,445,4,0,
406,9,447,4,0,
406,10,14,4,0,
406,10,15,4,0,
406,10,42,2,0,
406,10,71,1,1,
406,10,72,1,13,
406,10,74,1,4,
406,10,75,2,0,
406,10,76,4,0,
406,10,78,1,10,
406,10,79,2,0,
406,10,92,4,0,
406,10,104,4,0,
406,10,129,3,0,
406,10,148,4,0,
406,10,156,4,0,
406,10,164,4,0,
406,10,170,2,0,
406,10,173,3,0,
406,10,178,2,0,
406,10,182,4,0,
406,10,188,4,0,
406,10,189,3,0,
406,10,191,2,0,
406,10,202,4,0,
406,10,203,4,0,
406,10,207,4,0,
406,10,213,4,0,
406,10,214,4,0,
406,10,216,4,0,
406,10,218,4,0,
406,10,235,2,0,
406,10,235,3,0,
406,10,237,4,0,
406,10,240,4,0,
406,10,241,4,0,
406,10,244,4,0,
406,10,247,4,0,
406,10,253,3,0,
406,10,263,4,0,
406,10,290,4,0,
406,10,326,2,0,
406,10,331,4,0,
406,10,346,1,7,
406,10,363,4,0,
406,10,388,1,16,
406,10,388,3,0,
406,10,402,3,0,
406,10,412,4,0,
406,10,437,2,0,
406,10,445,4,0,
406,10,447,4,0,
406,11,14,4,0,
406,11,15,4,0,
406,11,42,2,0,
406,11,71,1,1,
406,11,72,1,13,
406,11,74,1,4,
406,11,75,2,0,
406,11,76,4,0,
406,11,78,1,10,
406,11,79,2,0,
406,11,92,4,0,
406,11,104,4,0,
406,11,148,4,0,
406,11,156,4,0,
406,11,164,4,0,
406,11,170,2,0,
406,11,178,2,0,
406,11,182,4,0,
406,11,188,4,0,
406,11,191,2,0,
406,11,202,2,0,
406,11,207,4,0,
406,11,213,4,0,
406,11,216,4,0,
406,11,218,4,0,
406,11,235,2,0,
406,11,237,4,0,
406,11,240,4,0,
406,11,241,4,0,
406,11,244,4,0,
406,11,247,4,0,
406,11,263,4,0,
406,11,320,2,0,
406,11,326,2,0,
406,11,346,1,7,
406,11,363,2,0,
406,11,388,1,16,
406,11,402,2,0,
406,11,412,4,0,
406,11,437,2,0,
406,11,447,4,0,
406,11,474,4,0,
406,11,496,4,0,
406,14,14,4,0,
406,14,15,4,0,
406,14,42,2,0,
406,14,71,1,1,
406,14,72,1,13,
406,14,74,1,4,
406,14,75,2,0,
406,14,76,4,0,
406,14,78,1,10,
406,14,79,2,0,
406,14,92,4,0,
406,14,104,4,0,
406,14,148,4,0,
406,14,156,4,0,
406,14,164,4,0,
406,14,170,2,0,
406,14,173,3,0,
406,14,178,2,0,
406,14,182,4,0,
406,14,188,4,0,
406,14,191,2,0,
406,14,202,2,0,
406,14,202,3,0,
406,14,207,4,0,
406,14,213,4,0,
406,14,214,3,0,
406,14,216,4,0,
406,14,218,4,0,
406,14,235,2,0,
406,14,235,3,0,
406,14,237,4,0,
406,14,240,4,0,
406,14,241,4,0,
406,14,244,4,0,
406,14,247,4,0,
406,14,253,3,0,
406,14,263,4,0,
406,14,320,2,0,
406,14,326,2,0,
406,14,343,3,0,
406,14,346,1,7,
406,14,363,2,0,
406,14,388,1,16,
406,14,388,3,0,
406,14,402,2,0,
406,14,402,3,0,
406,14,412,4,0,
406,14,437,2,0,
406,14,447,4,0,
406,14,474,4,0,
406,14,496,4,0,
406,15,14,4,0,
406,15,15,4,0,
406,15,42,2,0,
406,15,71,1,1,
406,15,72,1,13,
406,15,74,1,4,
406,15,75,2,0,
406,15,76,4,0,
406,15,78,1,10,
406,15,79,2,0,
406,15,92,4,0,
406,15,104,4,0,
406,15,148,4,0,
406,15,156,4,0,
406,15,164,4,0,
406,15,170,2,0,
406,15,178,2,0,
406,15,182,4,0,
406,15,188,4,0,
406,15,191,2,0,
406,15,202,2,0,
406,15,207,4,0,
406,15,213,4,0,
406,15,214,4,0,
406,15,216,4,0,
406,15,218,4,0,
406,15,235,2,0,
406,15,237,4,0,
406,15,240,4,0,
406,15,241,4,0,
406,15,244,4,0,
406,15,247,4,0,
406,15,263,4,0,
406,15,267,4,0,
406,15,320,2,0,
406,15,326,2,0,
406,15,346,1,7,
406,15,363,2,0,
406,15,388,1,16,
406,15,402,2,0,
406,15,412,4,0,
406,15,437,2,0,
406,15,447,4,0,
406,15,474,4,0,
406,15,496,4,0,
406,15,590,4,0,
406,15,605,4,0,
406,16,14,4,0,
406,16,15,4,0,
406,16,42,2,0,
406,16,71,1,1,1
406,16,72,1,13,1
406,16,74,1,4,1
406,16,75,2,0,
406,16,76,4,0,
406,16,78,1,10,1
406,16,79,2,0,
406,16,92,4,0,
406,16,104,4,0,
406,16,148,4,0,
406,16,156,4,0,
406,16,164,4,0,
406,16,170,2,0,
406,16,173,3,0,
406,16,178,2,0,
406,16,182,4,0,
406,16,188,4,0,
406,16,191,2,0,
406,16,202,2,0,
406,16,202,3,0,
406,16,207,4,0,
406,16,213,4,0,
406,16,214,4,0,
406,16,216,4,0,
406,16,218,4,0,
406,16,235,2,0,
406,16,235,3,0,
406,16,237,4,0,
406,16,240,4,0,
406,16,241,4,0,
406,16,244,4,0,
406,16,247,4,0,
406,16,253,3,0,
406,16,263,4,0,
406,16,267,4,0,
406,16,290,4,0,
406,16,320,2,0,
406,16,326,2,0,
406,16,343,3,0,
406,16,346,1,7,1
406,16,363,2,0,
406,16,388,1,16,1
406,16,388,3,0,
406,16,402,2,0,
406,16,402,3,0,
406,16,412,4,0,
406,16,437,2,0,
406,16,447,4,0,
406,16,474,4,0,
406,16,496,4,0,
406,16,590,4,0,
406,16,605,4,0,
406,17,14,4,0,
406,17,42,2,0,
406,17,71,1,1,
406,17,72,1,13,
406,17,74,1,4,
406,17,75,2,0,
406,17,76,4,0,
406,17,78,1,10,
406,17,79,2,0,
406,17,92,4,0,
406,17,104,4,0,
406,17,156,4,0,
406,17,164,4,0,
406,17,170,2,0,
406,17,178,2,0,
406,17,182,4,0,
406,17,188,4,0,
406,17,191,2,0,
406,17,202,2,0,
406,17,207,4,0,
406,17,213,4,0,
406,17,214,4,0,
406,17,216,4,0,
406,17,218,4,0,
406,17,235,2,0,
406,17,237,4,0,
406,17,240,4,0,
406,17,241,4,0,
406,17,244,4,0,
406,17,247,4,0,
406,17,263,4,0,
406,17,267,4,0,
406,17,320,2,0,
406,17,326,2,0,
406,17,346,1,7,
406,17,363,2,0,
406,17,388,1,16,
406,17,402,2,0,
406,17,412,4,0,
406,17,437,2,0,
406,17,447,4,0,
406,17,474,4,0,
406,17,496,4,0,
406,17,590,4,0,
406,17,605,4,0,
406,18,14,4,0,
406,18,42,2,0,
406,18,71,1,1,
406,18,72,1,13,
406,18,74,1,4,
406,18,75,2,0,
406,18,76,4,0,
406,18,78,1,10,
406,18,79,2,0,
406,18,92,4,0,
406,18,104,4,0,
406,18,156,4,0,
406,18,164,4,0,
406,18,170,2,0,
406,18,178,2,0,
406,18,182,4,0,
406,18,188,4,0,
406,18,191,2,0,
406,18,202,2,0,
406,18,207,4,0,
406,18,213,4,0,
406,18,214,4,0,
406,18,216,4,0,
406,18,218,4,0,
406,18,235,2,0,
406,18,237,4,0,
406,18,240,4,0,
406,18,241,4,0,
406,18,244,4,0,
406,18,247,4,0,
406,18,263,4,0,
406,18,267,4,0,
406,18,320,2,0,
406,18,326,2,0,
406,18,346,1,7,
406,18,363,2,0,
406,18,388,1,16,
406,18,402,2,0,
406,18,412,4,0,
406,18,437,2,0,
406,18,447,4,0,
406,18,474,4,0,
406,18,496,4,0,
406,18,590,4,0,
406,18,605,4,0,
407,8,14,4,0,
407,8,15,4,0,
407,8,40,1,1,2
407,8,63,4,0,
407,8,72,1,1,3
407,8,76,4,0,
407,8,92,4,0,
407,8,104,4,0,
407,8,148,4,0,
407,8,156,4,0,
407,8,164,4,0,
407,8,182,4,0,
407,8,188,4,0,
407,8,202,4,0,
407,8,203,4,0,
407,8,207,4,0,
407,8,213,4,0,
407,8,214,4,0,
407,8,216,4,0,
407,8,218,4,0,
407,8,230,1,1,5
407,8,237,4,0,
407,8,240,4,0,
407,8,241,4,0,
407,8,244,4,0,
407,8,247,4,0,
407,8,263,4,0,
407,8,290,4,0,
407,8,311,1,1,1
407,8,331,4,0,
407,8,345,1,1,4
407,8,363,4,0,
407,8,398,4,0,
407,8,412,4,0,
407,8,416,4,0,
407,8,445,4,0,
407,8,447,4,0,
407,9,14,4,0,
407,9,15,4,0,
407,9,40,1,1,2
407,9,63,4,0,
407,9,72,1,1,3
407,9,76,4,0,
407,9,92,4,0,
407,9,104,4,0,
407,9,129,3,0,
407,9,148,4,0,
407,9,156,4,0,
407,9,164,4,0,
407,9,173,3,0,
407,9,182,4,0,
407,9,188,4,0,
407,9,189,3,0,
407,9,202,4,0,
407,9,203,4,0,
407,9,207,4,0,
407,9,210,3,0,
407,9,213,4,0,
407,9,214,4,0,
407,9,216,4,0,
407,9,218,4,0,
407,9,230,1,1,5
407,9,235,3,0,
407,9,237,4,0,
407,9,240,4,0,
407,9,241,4,0,
407,9,244,4,0,
407,9,247,4,0,
407,9,263,4,0,
407,9,290,4,0,
407,9,311,1,1,1
407,9,331,4,0,
407,9,345,1,1,4
407,9,363,4,0,
407,9,398,4,0,
407,9,402,3,0,
407,9,412,4,0,
407,9,416,4,0,
407,9,445,4,0,
407,9,447,4,0,
407,10,14,4,0,
407,10,15,4,0,
407,10,40,1,1,2
407,10,63,4,0,
407,10,72,1,1,3
407,10,76,4,0,
407,10,92,4,0,
407,10,104,4,0,
407,10,129,3,0,
407,10,148,4,0,
407,10,156,4,0,
407,10,164,4,0,
407,10,173,3,0,
407,10,182,4,0,
407,10,188,4,0,
407,10,189,3,0,
407,10,202,4,0,
407,10,203,4,0,
407,10,207,4,0,
407,10,210,3,0,
407,10,213,4,0,
407,10,214,4,0,
407,10,216,4,0,
407,10,218,4,0,
407,10,230,1,1,5
407,10,235,3,0,
407,10,237,4,0,
407,10,240,4,0,
407,10,241,4,0,
407,10,244,4,0,
407,10,247,4,0,
407,10,263,4,0,
407,10,290,4,0,
407,10,311,1,1,1
407,10,331,4,0,
407,10,345,1,1,4
407,10,363,4,0,
407,10,388,3,0,
407,10,398,4,0,
407,10,402,3,0,
407,10,412,4,0,
407,10,416,4,0,
407,10,445,4,0,
407,10,447,4,0,
407,11,14,4,0,
407,11,15,4,0,
407,11,40,1,1,2
407,11,63,4,0,
407,11,72,1,1,3
407,11,76,4,0,
407,11,92,4,0,
407,11,104,4,0,
407,11,148,4,0,
407,11,156,4,0,
407,11,164,4,0,
407,11,182,4,0,
407,11,188,4,0,
407,11,207,4,0,
407,11,213,4,0,
407,11,216,4,0,
407,11,218,4,0,
407,11,230,1,1,5
407,11,237,4,0,
407,11,240,4,0,
407,11,241,4,0,
407,11,244,4,0,
407,11,247,4,0,
407,11,263,4,0,
407,11,311,1,1,1
407,11,345,1,1,4
407,11,398,4,0,
407,11,412,4,0,
407,11,416,4,0,
407,11,447,4,0,
407,11,474,4,0,
407,11,496,4,0,
407,14,14,4,0,
407,14,15,4,0,
407,14,40,1,1,2
407,14,63,4,0,
407,14,72,1,1,3
407,14,76,4,0,
407,14,92,4,0,
407,14,104,4,0,
407,14,148,4,0,
407,14,156,4,0,
407,14,164,4,0,
407,14,173,3,0,
407,14,182,4,0,
407,14,188,4,0,
407,14,202,3,0,
407,14,207,4,0,
407,14,213,4,0,
407,14,214,3,0,
407,14,216,4,0,
407,14,218,4,0,
407,14,230,1,1,5
407,14,235,3,0,
407,14,237,4,0,
407,14,240,4,0,
407,14,241,4,0,
407,14,244,4,0,
407,14,247,4,0,
407,14,263,4,0,
407,14,311,1,1,1
407,14,343,3,0,
407,14,345,1,1,4
407,14,388,3,0,
407,14,398,4,0,
407,14,402,3,0,
407,14,412,4,0,
407,14,416,4,0,
407,14,447,4,0,
407,14,474,4,0,
407,14,496,4,0,
407,15,14,4,0,
407,15,15,4,0,
407,15,40,1,1,4
407,15,63,4,0,
407,15,72,1,1,5
407,15,76,4,0,
407,15,92,4,0,
407,15,104,4,0,
407,15,148,4,0,
407,15,156,4,0,
407,15,164,4,0,
407,15,182,4,0,
407,15,188,4,0,
407,15,207,4,0,
407,15,213,4,0,
407,15,214,4,0,
407,15,216,4,0,
407,15,218,4,0,
407,15,230,1,1,7
407,15,237,4,0,
407,15,240,4,0,
407,15,241,4,0,
407,15,244,4,0,
407,15,247,4,0,
407,15,263,4,0,
407,15,267,4,0,
407,15,311,1,1,3
407,15,345,1,1,6
407,15,398,4,0,
407,15,412,4,0,
407,15,416,4,0,
407,15,447,4,0,
407,15,474,4,0,
407,15,496,4,0,
407,15,580,1,1,2
407,15,590,4,0,
407,15,599,1,1,1
407,15,605,4,0,
407,16,14,4,0,
407,16,15,4,0,
407,16,40,1,1,4
407,16,63,4,0,
407,16,72,1,1,5
407,16,76,4,0,
407,16,92,4,0,
407,16,104,4,0,
407,16,148,4,0,
407,16,156,4,0,
407,16,164,4,0,
407,16,173,3,0,
407,16,182,4,0,
407,16,188,4,0,
407,16,202,3,0,
407,16,207,4,0,
407,16,213,4,0,
407,16,214,4,0,
407,16,216,4,0,
407,16,218,4,0,
407,16,230,1,1,7
407,16,235,3,0,
407,16,237,4,0,
407,16,240,4,0,
407,16,241,4,0,
407,16,244,4,0,
407,16,247,4,0,
407,16,263,4,0,
407,16,267,4,0,
407,16,290,4,0,
407,16,311,1,1,3
407,16,343,3,0,
407,16,345,1,1,6
407,16,388,3,0,
407,16,398,4,0,
407,16,402,3,0,
407,16,412,4,0,
407,16,416,4,0,
407,16,447,4,0,
407,16,474,4,0,
407,16,496,4,0,
407,16,580,1,1,2
407,16,590,4,0,
407,16,599,1,1,1
407,16,605,4,0,
407,17,14,4,0,
407,17,40,1,1,4
407,17,63,4,0,
407,17,72,1,1,5
407,17,76,4,0,
407,17,92,4,0,
407,17,104,4,0,
407,17,156,4,0,
407,17,164,4,0,
407,17,182,4,0,
407,17,188,4,0,
407,17,207,4,0,
407,17,213,4,0,
407,17,214,4,0,
407,17,216,4,0,
407,17,218,4,0,
407,17,230,1,1,7
407,17,237,4,0,
407,17,240,4,0,
407,17,241,4,0,
407,17,244,4,0,
407,17,247,4,0,
407,17,263,4,0,
407,17,267,4,0,
407,17,311,1,1,3
407,17,345,1,1,6
407,17,398,4,0,
407,17,412,4,0,
407,17,416,4,0,
407,17,447,4,0,
407,17,474,4,0,
407,17,496,4,0,
407,17,580,1,1,2
407,17,590,4,0,
407,17,599,1,1,1
407,17,605,4,0,
407,18,14,4,0,
407,18,40,1,1,4
407,18,63,4,0,
407,18,72,1,1,5
407,18,76,4,0,
407,18,92,4,0,
407,18,104,4,0,
407,18,156,4,0,
407,18,164,4,0,
407,18,182,4,0,
407,18,188,4,0,
407,18,207,4,0,
407,18,213,4,0,
407,18,214,4,0,
407,18,216,4,0,
407,18,218,4,0,
407,18,230,1,1,7
407,18,237,4,0,
407,18,240,4,0,
407,18,241,4,0,
407,18,244,4,0,
407,18,247,4,0,
407,18,263,4,0,
407,18,267,4,0,
407,18,311,1,1,3
407,18,345,1,1,6
407,18,398,4,0,
407,18,412,4,0,
407,18,416,4,0,
407,18,447,4,0,
407,18,474,4,0,
407,18,496,4,0,
407,18,580,1,1,2
407,18,590,4,0,
407,18,599,1,1,1
407,18,605,4,0,
408,8,14,4,0,
408,8,18,2,0,
408,8,21,2,0,
408,8,23,2,0,
408,8,29,1,1,1
408,8,36,1,15,
408,8,37,2,0,
408,8,38,2,0,
408,8,43,1,1,2
408,8,43,2,0,
408,8,46,4,0,
408,8,53,4,0,
408,8,58,4,0,
408,8,59,4,0,
408,8,70,4,0,
408,8,85,4,0,
408,8,87,4,0,
408,8,89,4,0,
408,8,91,4,0,
408,8,92,4,0,
408,8,103,1,37,
408,8,104,4,0,
408,8,116,1,6,
408,8,126,4,0,
408,8,156,4,0,
408,8,157,4,0,
408,8,164,4,0,
408,8,168,4,0,
408,8,182,4,0,
408,8,184,1,19,
408,8,201,4,0,
408,8,203,4,0,
408,8,207,4,0,
408,8,213,4,0,
408,8,214,4,0,
408,8,216,4,0,
408,8,218,4,0,
408,8,228,1,10,
408,8,231,4,0,
408,8,237,4,0,
408,8,240,4,0,
408,8,241,4,0,
408,8,242,2,0,
408,8,246,1,28,
408,8,249,4,0,
408,8,263,4,0,
408,8,290,4,0,
408,8,317,4,0,
408,8,351,4,0,
408,8,359,2,0,
408,8,363,4,0,
408,8,371,4,0,
408,8,372,1,24,
408,8,374,4,0,
408,8,397,4,0,
408,8,406,4,0,
408,8,428,1,33,
408,8,431,4,0,
408,8,444,4,0,
408,8,445,4,0,
408,8,446,4,0,
408,8,457,1,43,
408,9,7,3,0,
408,9,9,3,0,
408,9,14,4,0,
408,9,18,2,0,
408,9,21,2,0,
408,9,23,2,0,
408,9,29,1,1,1
408,9,36,1,15,
408,9,37,2,0,
408,9,38,2,0,
408,9,43,1,1,2
408,9,43,2,0,
408,9,46,4,0,
408,9,53,4,0,
408,9,58,4,0,
408,9,59,4,0,
408,9,70,4,0,
408,9,85,4,0,
408,9,87,4,0,
408,9,89,4,0,
408,9,91,4,0,
408,9,92,4,0,
408,9,103,1,37,
408,9,104,4,0,
408,9,116,1,6,
408,9,126,4,0,
408,9,156,4,0,
408,9,157,4,0,
408,9,164,4,0,
408,9,168,4,0,
408,9,173,3,0,
408,9,180,3,0,
408,9,182,4,0,
408,9,184,1,19,
408,9,189,3,0,
408,9,201,4,0,
408,9,203,4,0,
408,9,207,4,0,
408,9,213,4,0,
408,9,214,4,0,
408,9,216,4,0,
408,9,218,4,0,
408,9,228,1,10,
408,9,231,4,0,
408,9,237,4,0,
408,9,240,4,0,
408,9,241,4,0,
408,9,242,2,0,
408,9,246,1,28,
408,9,246,3,0,
408,9,249,4,0,
408,9,253,3,0,
408,9,263,4,0,
408,9,276,3,0,
408,9,283,3,0,
408,9,290,4,0,
408,9,317,4,0,
408,9,351,4,0,
408,9,359,2,0,
408,9,363,4,0,
408,9,371,4,0,
408,9,372,1,24,
408,9,374,4,0,
408,9,397,4,0,
408,9,406,4,0,
408,9,414,3,0,
408,9,428,1,33,
408,9,428,3,0,
408,9,431,4,0,
408,9,442,3,0,
408,9,444,4,0,
408,9,445,4,0,
408,9,446,4,0,
408,9,457,1,43,
408,10,7,3,0,
408,10,9,3,0,
408,10,14,4,0,
408,10,18,2,0,
408,10,21,2,0,
408,10,23,2,0,
408,10,29,1,1,1
408,10,29,3,0,
408,10,36,1,15,
408,10,37,2,0,
408,10,38,2,0,
408,10,43,1,1,2
408,10,43,2,0,
408,10,46,4,0,
408,10,53,4,0,
408,10,58,4,0,
408,10,59,4,0,
408,10,70,4,0,
408,10,85,4,0,
408,10,87,4,0,
408,10,89,4,0,
408,10,91,4,0,
408,10,92,4,0,
408,10,103,1,37,
408,10,104,4,0,
408,10,116,1,6,
408,10,126,4,0,
408,10,156,4,0,
408,10,157,4,0,
408,10,164,4,0,
408,10,168,4,0,
408,10,173,3,0,
408,10,174,2,0,
408,10,180,3,0,
408,10,182,4,0,
408,10,184,1,19,
408,10,189,3,0,
408,10,201,4,0,
408,10,203,4,0,
408,10,207,4,0,
408,10,213,4,0,
408,10,214,4,0,
408,10,216,4,0,
408,10,218,4,0,
408,10,228,1,10,
408,10,231,4,0,
408,10,237,4,0,
408,10,240,4,0,
408,10,241,4,0,
408,10,242,2,0,
408,10,246,1,28,
408,10,246,3,0,
408,10,249,4,0,
408,10,253,3,0,
408,10,263,4,0,
408,10,276,3,0,
408,10,283,3,0,
408,10,290,4,0,
408,10,317,4,0,
408,10,351,4,0,
408,10,359,2,0,
408,10,363,4,0,
408,10,371,4,0,
408,10,372,1,24,
408,10,374,4,0,
408,10,397,4,0,
408,10,406,4,0,
408,10,414,3,0,
408,10,428,1,33,
408,10,428,3,0,
408,10,431,4,0,
408,10,442,3,0,
408,10,444,4,0,
408,10,445,4,0,
408,10,446,4,0,
408,10,457,1,43,
408,11,14,4,0,
408,11,18,2,0,
408,11,21,2,0,
408,11,23,2,0,
408,11,29,1,1,1
408,11,36,1,15,
408,11,37,2,0,
408,11,38,2,0,
408,11,43,1,1,2
408,11,43,2,0,
408,11,46,4,0,
408,11,53,4,0,
408,11,58,4,0,
408,11,59,4,0,
408,11,70,4,0,
408,11,85,4,0,
408,11,87,4,0,
408,11,89,4,0,
408,11,91,4,0,
408,11,92,4,0,
408,11,103,1,42,
408,11,104,4,0,
408,11,116,1,6,
408,11,126,4,0,
408,11,156,4,0,
408,11,157,4,0,
408,11,164,4,0,
408,11,168,4,0,
408,11,174,2,0,
408,11,182,4,0,
408,11,184,1,19,
408,11,201,4,0,
408,11,207,4,0,
408,11,213,4,0,
408,11,216,4,0,
408,11,218,4,0,
408,11,228,1,10,
408,11,231,2,0,
408,11,237,4,0,
408,11,240,4,0,
408,11,241,4,0,
408,11,242,2,0,
408,11,246,1,33,
408,11,249,4,0,
408,11,263,4,0,
408,11,317,4,0,
408,11,359,2,0,
408,11,371,4,0,
408,11,372,1,24,
408,11,374,4,0,
408,11,397,4,0,
408,11,428,1,37,
408,11,442,2,0,
408,11,444,4,0,
408,11,457,1,46,
408,11,479,4,0,
408,11,496,4,0,
408,11,498,1,28,
408,11,510,4,0,
408,11,523,4,0,
408,14,7,3,0,
408,14,9,3,0,
408,14,14,4,0,
408,14,18,2,0,
408,14,21,2,0,
408,14,23,2,0,
408,14,29,1,1,1
408,14,36,1,15,
408,14,37,2,0,
408,14,38,2,0,
408,14,43,1,1,2
408,14,43,2,0,
408,14,46,4,0,
408,14,53,4,0,
408,14,58,4,0,
408,14,59,4,0,
408,14,70,4,0,
408,14,85,4,0,
408,14,87,4,0,
408,14,89,4,0,
408,14,91,4,0,
408,14,92,4,0,
408,14,103,1,42,
408,14,104,4,0,
408,14,116,1,6,
408,14,126,4,0,
408,14,156,4,0,
408,14,157,4,0,
408,14,164,4,0,
408,14,168,4,0,
408,14,173,3,0,
408,14,174,2,0,
408,14,180,3,0,
408,14,182,4,0,
408,14,184,1,19,
408,14,201,4,0,
408,14,207,4,0,
408,14,213,4,0,
408,14,214,3,0,
408,14,216,4,0,
408,14,218,4,0,
408,14,228,1,10,
408,14,231,2,0,
408,14,231,3,0,
408,14,237,4,0,
408,14,240,4,0,
408,14,241,4,0,
408,14,242,2,0,
408,14,246,1,33,
408,14,249,4,0,
408,14,253,3,0,
408,14,263,4,0,
408,14,276,3,0,
408,14,283,3,0,
408,14,317,4,0,
408,14,359,2,0,
408,14,371,4,0,
408,14,372,1,24,
408,14,374,4,0,
408,14,397,4,0,
408,14,406,3,0,
408,14,414,3,0,
408,14,428,1,37,
408,14,428,3,0,
408,14,442,2,0,
408,14,442,3,0,
408,14,444,4,0,
408,14,446,3,0,
408,14,457,1,46,
408,14,479,4,0,
408,14,496,4,0,
408,14,498,1,28,
408,14,510,4,0,
408,14,523,4,0,
408,15,14,4,0,
408,15,18,2,0,
408,15,21,2,0,
408,15,23,2,0,
408,15,29,1,1,1
408,15,36,1,15,
408,15,37,2,0,
408,15,38,2,0,
408,15,43,1,1,2
408,15,43,2,0,
408,15,46,4,0,
408,15,53,4,0,
408,15,58,4,0,
408,15,59,4,0,
408,15,70,4,0,
408,15,85,4,0,
408,15,87,4,0,
408,15,89,4,0,
408,15,91,4,0,
408,15,92,4,0,
408,15,103,1,42,
408,15,104,4,0,
408,15,116,1,6,
408,15,126,4,0,
408,15,156,4,0,
408,15,157,4,0,
408,15,164,4,0,
408,15,168,4,0,
408,15,174,2,0,
408,15,182,4,0,
408,15,184,1,19,
408,15,201,4,0,
408,15,207,4,0,
408,15,213,4,0,
408,15,214,4,0,
408,15,216,4,0,
408,15,218,4,0,
408,15,228,1,10,
408,15,231,2,0,
408,15,237,4,0,
408,15,240,4,0,
408,15,241,4,0,
408,15,242,2,0,
408,15,246,1,33,
408,15,249,4,0,
408,15,263,4,0,
408,15,317,4,0,
408,15,359,2,0,
408,15,371,4,0,
408,15,372,1,24,
408,15,374,4,0,
408,15,397,4,0,
408,15,428,1,37,
408,15,442,2,0,
408,15,444,4,0,
408,15,457,1,46,
408,15,479,4,0,
408,15,496,4,0,
408,15,498,1,28,
408,15,510,4,0,
408,15,523,4,0,
408,15,590,4,0,
408,15,612,4,0,
408,16,7,3,0,
408,16,9,3,0,
408,16,14,4,0,
408,16,18,2,0,
408,16,21,2,0,
408,16,23,2,0,
408,16,29,1,1,1
408,16,36,1,15,1
408,16,37,2,0,
408,16,38,2,0,
408,16,43,1,1,2
408,16,43,2,0,
408,16,46,4,0,
408,16,53,4,0,
408,16,58,4,0,
408,16,59,4,0,
408,16,70,4,0,
408,16,85,4,0,
408,16,87,4,0,
408,16,89,4,0,
408,16,91,4,0,
408,16,92,4,0,
408,16,103,1,42,1
408,16,104,4,0,
408,16,116,1,6,1
408,16,126,4,0,
408,16,156,4,0,
408,16,157,4,0,
408,16,164,4,0,
408,16,168,4,0,
408,16,173,3,0,
408,16,174,2,0,
408,16,180,3,0,
408,16,182,4,0,
408,16,184,1,19,1
408,16,201,4,0,
408,16,207,4,0,
408,16,213,4,0,
408,16,214,4,0,
408,16,216,4,0,
408,16,218,4,0,
408,16,228,1,10,1
408,16,231,2,0,
408,16,231,3,0,
408,16,237,4,0,
408,16,240,4,0,
408,16,241,4,0,
408,16,242,2,0,
408,16,246,1,33,1
408,16,249,4,0,
408,16,253,3,0,
408,16,263,4,0,
408,16,276,3,0,
408,16,283,3,0,
408,16,290,4,0,
408,16,317,4,0,
408,16,351,3,0,
408,16,359,2,0,
408,16,371,4,0,
408,16,372,1,24,1
408,16,374,4,0,
408,16,397,4,0,
408,16,406,3,0,
408,16,414,3,0,
408,16,428,1,37,1
408,16,428,3,0,
408,16,442,2,0,
408,16,442,3,0,
408,16,444,4,0,
408,16,446,3,0,
408,16,457,1,46,1
408,16,479,4,0,
408,16,496,4,0,
408,16,498,1,28,1
408,16,510,4,0,
408,16,523,4,0,
408,16,590,4,0,
408,16,612,4,0,
408,17,14,4,0,
408,17,18,2,0,
408,17,21,2,0,
408,17,23,2,0,
408,17,29,1,1,1
408,17,36,1,15,
408,17,37,2,0,
408,17,38,2,0,
408,17,43,1,1,2
408,17,43,2,0,
408,17,46,4,0,
408,17,53,4,0,
408,17,58,4,0,
408,17,59,4,0,
408,17,85,4,0,
408,17,87,4,0,
408,17,89,4,0,
408,17,92,4,0,
408,17,103,1,42,
408,17,104,4,0,
408,17,116,1,6,
408,17,126,4,0,
408,17,156,4,0,
408,17,157,4,0,
408,17,164,4,0,
408,17,168,4,0,
408,17,174,2,0,
408,17,182,4,0,
408,17,184,1,19,
408,17,201,4,0,
408,17,207,4,0,
408,17,213,4,0,
408,17,214,4,0,
408,17,216,4,0,
408,17,218,4,0,
408,17,228,1,10,
408,17,231,2,0,
408,17,237,4,0,
408,17,240,4,0,
408,17,241,4,0,
408,17,242,2,0,
408,17,246,1,33,
408,17,263,4,0,
408,17,317,4,0,
408,17,359,2,0,
408,17,371,4,0,
408,17,372,1,24,
408,17,374,4,0,
408,17,397,4,0,
408,17,428,1,37,
408,17,442,2,0,
408,17,444,4,0,
408,17,457,1,46,
408,17,479,4,0,
408,17,496,4,0,
408,17,498,1,28,
408,17,523,4,0,
408,17,590,4,0,
408,18,14,4,0,
408,18,18,2,0,
408,18,21,2,0,
408,18,23,2,0,
408,18,29,1,1,1
408,18,36,1,15,
408,18,37,2,0,
408,18,38,2,0,
408,18,43,1,1,2
408,18,43,2,0,
408,18,46,4,0,
408,18,53,4,0,
408,18,58,4,0,
408,18,59,4,0,
408,18,85,4,0,
408,18,87,4,0,
408,18,89,4,0,
408,18,92,4,0,
408,18,103,1,42,
408,18,104,4,0,
408,18,116,1,6,
408,18,126,4,0,
408,18,156,4,0,
408,18,157,4,0,
408,18,164,4,0,
408,18,168,4,0,
408,18,174,2,0,
408,18,182,4,0,
408,18,184,1,19,
408,18,201,4,0,
408,18,207,4,0,
408,18,213,4,0,
408,18,214,4,0,
408,18,216,4,0,
408,18,218,4,0,
408,18,228,1,10,
408,18,231,2,0,
408,18,237,4,0,
408,18,240,4,0,
408,18,241,4,0,
408,18,242,2,0,
408,18,246,1,33,
408,18,263,4,0,
408,18,317,4,0,
408,18,359,2,0,
408,18,371,4,0,
408,18,372,1,24,
408,18,374,4,0,
408,18,397,4,0,
408,18,428,1,37,
408,18,442,2,0,
408,18,444,4,0,
408,18,457,1,46,
408,18,479,4,0,
408,18,496,4,0,
408,18,498,1,28,
408,18,523,4,0,
408,18,590,4,0,
409,8,14,4,0,
409,8,15,4,0,
409,8,29,1,1,1
409,8,36,1,15,
409,8,43,1,1,2
409,8,46,4,0,
409,8,53,4,0,
409,8,57,4,0,
409,8,58,4,0,
409,8,59,4,0,
409,8,63,4,0,
409,8,70,4,0,
409,8,85,4,0,
409,8,87,4,0,
409,8,89,4,0,
409,8,91,4,0,
409,8,92,4,0,
409,8,103,1,43,
409,8,104,4,0,
409,8,116,1,6,
409,8,126,4,0,
409,8,156,4,0,
409,8,157,4,0,
409,8,164,4,0,
409,8,168,4,0,
409,8,182,4,0,
409,8,184,1,19,
409,8,201,4,0,
409,8,203,4,0,
409,8,207,4,0,
409,8,213,4,0,
409,8,214,4,0,
409,8,216,4,0,
409,8,218,4,0,
409,8,228,1,10,
409,8,231,4,0,
409,8,237,4,0,
409,8,240,4,0,
409,8,241,4,0,
409,8,246,1,28,
409,8,249,4,0,
409,8,263,4,0,
409,8,264,4,0,
409,8,280,4,0,
409,8,283,1,30,
409,8,290,4,0,
409,8,317,4,0,
409,8,351,4,0,
409,8,363,4,0,
409,8,371,4,0,
409,8,372,1,24,
409,8,374,4,0,
409,8,397,4,0,
409,8,406,4,0,
409,8,411,4,0,
409,8,416,4,0,
409,8,419,4,0,
409,8,428,1,36,
409,8,431,4,0,
409,8,444,4,0,
409,8,445,4,0,
409,8,446,4,0,
409,8,457,1,52,
409,9,7,3,0,
409,9,9,3,0,
409,9,14,4,0,
409,9,15,4,0,
409,9,29,1,1,1
409,9,36,1,15,
409,9,43,1,1,2
409,9,46,4,0,
409,9,53,4,0,
409,9,57,4,0,
409,9,58,4,0,
409,9,59,4,0,
409,9,63,4,0,
409,9,70,4,0,
409,9,85,4,0,
409,9,87,4,0,
409,9,89,4,0,
409,9,91,4,0,
409,9,92,4,0,
409,9,103,1,43,
409,9,104,4,0,
409,9,116,1,6,
409,9,126,4,0,
409,9,156,4,0,
409,9,157,4,0,
409,9,164,4,0,
409,9,168,4,0,
409,9,173,3,0,
409,9,180,3,0,
409,9,182,4,0,
409,9,184,1,19,
409,9,189,3,0,
409,9,200,3,0,
409,9,201,4,0,
409,9,203,4,0,
409,9,207,4,0,
409,9,213,4,0,
409,9,214,4,0,
409,9,216,4,0,
409,9,218,4,0,
409,9,228,1,10,
409,9,231,4,0,
409,9,237,4,0,
409,9,240,4,0,
409,9,241,4,0,
409,9,246,1,28,
409,9,246,3,0,
409,9,249,4,0,
409,9,253,3,0,
409,9,263,4,0,
409,9,264,4,0,
409,9,276,3,0,
409,9,280,4,0,
409,9,283,1,30,
409,9,283,3,0,
409,9,290,4,0,
409,9,317,4,0,
409,9,351,4,0,
409,9,363,4,0,
409,9,371,4,0,
409,9,372,1,24,
409,9,374,4,0,
409,9,397,4,0,
409,9,406,4,0,
409,9,411,4,0,
409,9,414,3,0,
409,9,416,4,0,
409,9,419,4,0,
409,9,428,1,36,
409,9,428,3,0,
409,9,431,4,0,
409,9,442,3,0,
409,9,444,4,0,
409,9,445,4,0,
409,9,446,4,0,
409,9,457,1,52,
409,10,7,3,0,
409,10,9,3,0,
409,10,14,4,0,
409,10,15,4,0,
409,10,29,1,1,1
409,10,29,3,0,
409,10,36,1,15,
409,10,43,1,1,2
409,10,46,4,0,
409,10,53,4,0,
409,10,57,4,0,
409,10,58,4,0,
409,10,59,4,0,
409,10,63,4,0,
409,10,70,4,0,
409,10,85,4,0,
409,10,87,4,0,
409,10,89,4,0,
409,10,91,4,0,
409,10,92,4,0,
409,10,103,1,43,
409,10,104,4,0,
409,10,116,1,6,
409,10,126,4,0,
409,10,156,4,0,
409,10,157,4,0,
409,10,164,4,0,
409,10,168,4,0,
409,10,173,3,0,
409,10,180,3,0,
409,10,182,4,0,
409,10,184,1,19,
409,10,189,3,0,
409,10,200,3,0,
409,10,201,4,0,
409,10,203,4,0,
409,10,207,4,0,
409,10,213,4,0,
409,10,214,4,0,
409,10,216,4,0,
409,10,218,4,0,
409,10,220,3,0,
409,10,228,1,10,
409,10,231,4,0,
409,10,237,4,0,
409,10,240,4,0,
409,10,241,4,0,
409,10,246,1,28,
409,10,246,3,0,
409,10,249,4,0,
409,10,250,4,0,
409,10,253,3,0,
409,10,263,4,0,
409,10,264,4,0,
409,10,276,3,0,
409,10,280,4,0,
409,10,283,1,30,
409,10,283,3,0,
409,10,290,4,0,
409,10,317,4,0,
409,10,351,4,0,
409,10,363,4,0,
409,10,371,4,0,
409,10,372,1,24,
409,10,374,4,0,
409,10,397,4,0,
409,10,406,4,0,
409,10,411,4,0,
409,10,414,3,0,
409,10,416,4,0,
409,10,419,4,0,
409,10,428,1,36,
409,10,428,3,0,
409,10,431,4,0,
409,10,442,3,0,
409,10,444,4,0,
409,10,445,4,0,
409,10,446,4,0,
409,10,457,1,52,
409,11,14,4,0,
409,11,15,4,0,
409,11,29,1,1,1
409,11,36,1,15,
409,11,43,1,1,2
409,11,46,4,0,
409,11,53,4,0,
409,11,57,4,0,
409,11,58,4,0,
409,11,59,4,0,
409,11,63,4,0,
409,11,70,4,0,
409,11,85,4,0,
409,11,87,4,0,
409,11,89,4,0,
409,11,91,4,0,
409,11,92,4,0,
409,11,103,1,51,
409,11,104,4,0,
409,11,116,1,6,
409,11,126,4,0,
409,11,156,4,0,
409,11,157,4,0,
409,11,164,4,0,
409,11,168,4,0,
409,11,182,4,0,
409,11,184,1,19,
409,11,201,4,0,
409,11,207,4,0,
409,11,213,4,0,
409,11,216,4,0,
409,11,218,4,0,
409,11,228,1,10,
409,11,237,4,0,
409,11,240,4,0,
409,11,241,4,0,
409,11,246,1,36,
409,11,249,4,0,
409,11,263,4,0,
409,11,280,4,0,
409,11,283,1,30,
409,11,317,4,0,
409,11,371,4,0,
409,11,372,1,24,
409,11,374,4,0,
409,11,397,4,0,
409,11,411,4,0,
409,11,416,4,0,
409,11,428,1,43,
409,11,444,4,0,
409,11,457,1,58,
409,11,479,4,0,
409,11,496,4,0,
409,11,498,1,28,
409,11,510,4,0,
409,11,523,4,0,
409,11,525,4,0,
409,14,7,3,0,
409,14,9,3,0,
409,14,14,4,0,
409,14,15,4,0,
409,14,29,1,1,1
409,14,36,1,15,
409,14,43,1,1,2
409,14,46,4,0,
409,14,53,4,0,
409,14,57,4,0,
409,14,58,4,0,
409,14,59,4,0,
409,14,63,4,0,
409,14,70,4,0,
409,14,85,4,0,
409,14,87,4,0,
409,14,89,4,0,
409,14,91,4,0,
409,14,92,4,0,
409,14,103,1,51,
409,14,104,4,0,
409,14,116,1,6,
409,14,126,4,0,
409,14,156,4,0,
409,14,157,4,0,
409,14,164,4,0,
409,14,168,4,0,
409,14,173,3,0,
409,14,180,3,0,
409,14,182,4,0,
409,14,184,1,19,
409,14,200,3,0,
409,14,201,4,0,
409,14,207,4,0,
409,14,213,4,0,
409,14,214,3,0,
409,14,216,4,0,
409,14,218,4,0,
409,14,220,3,0,
409,14,228,1,10,
409,14,231,3,0,
409,14,237,4,0,
409,14,240,4,0,
409,14,241,4,0,
409,14,246,1,36,
409,14,249,4,0,
409,14,253,3,0,
409,14,263,4,0,
409,14,276,3,0,
409,14,280,4,0,
409,14,283,1,30,
409,14,283,3,0,
409,14,317,4,0,
409,14,371,4,0,
409,14,372,1,24,
409,14,374,4,0,
409,14,397,4,0,
409,14,406,3,0,
409,14,411,4,0,
409,14,414,3,0,
409,14,416,4,0,
409,14,428,1,43,
409,14,428,3,0,
409,14,442,3,0,
409,14,444,4,0,
409,14,446,3,0,
409,14,457,1,58,
409,14,479,4,0,
409,14,496,4,0,
409,14,498,1,28,
409,14,510,4,0,
409,14,523,4,0,
409,14,525,4,0,
409,15,14,4,0,
409,15,15,4,0,
409,15,29,1,1,1
409,15,36,1,15,
409,15,43,1,1,2
409,15,46,4,0,
409,15,53,4,0,
409,15,57,4,0,
409,15,58,4,0,
409,15,59,4,0,
409,15,63,4,0,
409,15,70,4,0,
409,15,85,4,0,
409,15,87,4,0,
409,15,89,4,0,
409,15,91,4,0,
409,15,92,4,0,
409,15,103,1,51,
409,15,104,4,0,
409,15,116,1,6,
409,15,126,4,0,
409,15,156,4,0,
409,15,157,4,0,
409,15,164,4,0,
409,15,168,4,0,
409,15,182,4,0,
409,15,184,1,19,
409,15,201,4,0,
409,15,207,4,0,
409,15,213,4,0,
409,15,214,4,0,
409,15,216,4,0,
409,15,218,4,0,
409,15,228,1,10,
409,15,237,4,0,
409,15,240,4,0,
409,15,241,4,0,
409,15,246,1,36,
409,15,249,4,0,
409,15,263,4,0,
409,15,280,4,0,
409,15,283,1,30,
409,15,317,4,0,
409,15,371,4,0,
409,15,372,1,24,
409,15,374,4,0,
409,15,397,4,0,
409,15,411,4,0,
409,15,416,4,0,
409,15,428,1,43,
409,15,444,4,0,
409,15,457,1,58,
409,15,479,4,0,
409,15,496,4,0,
409,15,498,1,28,
409,15,510,4,0,
409,15,523,4,0,
409,15,525,4,0,
409,15,590,4,0,
409,15,612,4,0,
409,16,7,3,0,
409,16,9,3,0,
409,16,14,4,0,
409,16,15,4,0,
409,16,29,1,1,1
409,16,36,1,15,1
409,16,43,1,1,2
409,16,46,4,0,
409,16,53,4,0,
409,16,57,4,0,
409,16,58,4,0,
409,16,59,4,0,
409,16,63,4,0,
409,16,70,4,0,
409,16,85,4,0,
409,16,87,4,0,
409,16,89,4,0,
409,16,91,4,0,
409,16,92,4,0,
409,16,103,1,51,1
409,16,104,4,0,
409,16,116,1,6,1
409,16,126,4,0,
409,16,156,4,0,
409,16,157,4,0,
409,16,164,4,0,
409,16,168,4,0,
409,16,173,3,0,
409,16,180,3,0,
409,16,182,4,0,
409,16,184,1,19,1
409,16,200,3,0,
409,16,201,4,0,
409,16,207,4,0,
409,16,213,4,0,
409,16,214,4,0,
409,16,216,4,0,
409,16,218,4,0,
409,16,220,3,0,
409,16,228,1,10,1
409,16,231,3,0,
409,16,237,4,0,
409,16,240,4,0,
409,16,241,4,0,
409,16,246,1,36,1
409,16,249,4,0,
409,16,253,3,0,
409,16,263,4,0,
409,16,264,3,0,
409,16,276,3,0,
409,16,280,4,0,
409,16,283,1,30,1
409,16,283,3,0,
409,16,290,4,0,
409,16,317,4,0,
409,16,351,3,0,
409,16,371,4,0,
409,16,372,1,24,1
409,16,374,4,0,
409,16,397,4,0,
409,16,406,3,0,
409,16,411,4,0,
409,16,414,3,0,
409,16,416,4,0,
409,16,428,1,43,1
409,16,428,3,0,
409,16,442,3,0,
409,16,444,4,0,
409,16,446,3,0,
409,16,457,1,58,1
409,16,479,4,0,
409,16,496,4,0,
409,16,498,1,28,1
409,16,510,4,0,
409,16,523,4,0,
409,16,525,4,0,
409,16,590,4,0,
409,16,612,4,0,
409,17,14,4,0,
409,17,29,1,1,2
409,17,36,1,15,
409,17,43,1,1,3
409,17,46,4,0,
409,17,53,4,0,
409,17,57,4,0,
409,17,58,4,0,
409,17,59,4,0,
409,17,63,4,0,
409,17,85,4,0,
409,17,87,4,0,
409,17,89,4,0,
409,17,92,4,0,
409,17,103,1,51,
409,17,104,4,0,
409,17,116,1,1,4
409,17,116,1,6,
409,17,126,4,0,
409,17,156,4,0,
409,17,157,4,0,
409,17,164,4,0,
409,17,168,4,0,
409,17,182,4,0,
409,17,184,1,19,
409,17,201,4,0,
409,17,207,4,0,
409,17,213,4,0,
409,17,214,4,0,
409,17,216,4,0,
409,17,218,4,0,
409,17,228,1,1,5
409,17,228,1,10,
409,17,237,4,0,
409,17,240,4,0,
409,17,241,4,0,
409,17,246,1,36,
409,17,263,4,0,
409,17,280,4,0,
409,17,283,1,0,
409,17,283,1,1,1
409,17,317,4,0,
409,17,371,4,0,
409,17,372,1,24,
409,17,374,4,0,
409,17,397,4,0,
409,17,411,4,0,
409,17,416,4,0,
409,17,428,1,43,
409,17,444,4,0,
409,17,457,1,58,
409,17,479,4,0,
409,17,496,4,0,
409,17,498,1,28,
409,17,523,4,0,
409,17,525,4,0,
409,17,590,4,0,
409,18,14,4,0,
409,18,29,1,1,2
409,18,36,1,15,
409,18,43,1,1,3
409,18,46,4,0,
409,18,53,4,0,
409,18,57,4,0,
409,18,58,4,0,
409,18,59,4,0,
409,18,63,4,0,
409,18,85,4,0,
409,18,87,4,0,
409,18,89,4,0,
409,18,92,4,0,
409,18,103,1,51,
409,18,104,4,0,
409,18,116,1,1,4
409,18,116,1,6,
409,18,126,4,0,
409,18,156,4,0,
409,18,157,4,0,
409,18,164,4,0,
409,18,168,4,0,
409,18,182,4,0,
409,18,184,1,19,
409,18,201,4,0,
409,18,207,4,0,
409,18,213,4,0,
409,18,214,4,0,
409,18,216,4,0,
409,18,218,4,0,
409,18,228,1,1,5
409,18,228,1,10,
409,18,237,4,0,
409,18,240,4,0,
409,18,241,4,0,
409,18,246,1,36,
409,18,263,4,0,
409,18,280,4,0,
409,18,283,1,0,
409,18,283,1,1,1
409,18,317,4,0,
409,18,371,4,0,
409,18,372,1,24,
409,18,374,4,0,
409,18,397,4,0,
409,18,411,4,0,
409,18,416,4,0,
409,18,428,1,43,
409,18,444,4,0,
409,18,457,1,58,
409,18,479,4,0,
409,18,496,4,0,
409,18,498,1,28,
409,18,523,4,0,
409,18,525,4,0,
409,18,590,4,0,
410,8,29,2,0,
410,8,33,1,1,1
410,8,34,2,0,
410,8,36,1,15,
410,8,38,2,0,
410,8,46,4,0,
410,8,53,4,0,
410,8,58,4,0,
410,8,59,4,0,
410,8,70,4,0,
410,8,85,4,0,
410,8,87,4,0,
410,8,89,4,0,
410,8,90,2,0,
410,8,91,4,0,
410,8,92,4,0,
410,8,103,2,0,
410,8,104,4,0,
410,8,116,2,0,
410,8,126,4,0,
410,8,156,4,0,
410,8,157,4,0,
410,8,164,4,0,
410,8,174,2,0,
410,8,182,1,1,2
410,8,182,4,0,
410,8,184,2,0,
410,8,201,4,0,
410,8,203,1,33,
410,8,203,4,0,
410,8,207,1,24,
410,8,207,4,0,
410,8,213,4,0,
410,8,214,4,0,
410,8,216,4,0,
410,8,218,4,0,
410,8,231,4,0,
410,8,237,4,0,
410,8,240,4,0,
410,8,241,4,0,
410,8,246,1,28,
410,8,249,4,0,
410,8,259,4,0,
410,8,263,4,0,
410,8,269,1,6,
410,8,269,4,0,
410,8,290,4,0,
410,8,317,4,0,
410,8,319,1,10,
410,8,334,1,19,
410,8,350,2,0,
410,8,351,4,0,
410,8,363,4,0,
410,8,368,1,37,
410,8,397,4,0,
410,8,430,4,0,
410,8,442,1,43,
410,8,444,4,0,
410,8,445,4,0,
410,8,446,4,0,
410,9,29,2,0,
410,9,33,1,1,1
410,9,34,2,0,
410,9,36,1,15,
410,9,38,2,0,
410,9,46,4,0,
410,9,53,4,0,
410,9,58,4,0,
410,9,59,4,0,
410,9,70,4,0,
410,9,85,4,0,
410,9,87,4,0,
410,9,89,4,0,
410,9,90,2,0,
410,9,91,4,0,
410,9,92,4,0,
410,9,103,2,0,
410,9,104,4,0,
410,9,116,2,0,
410,9,126,4,0,
410,9,156,4,0,
410,9,157,4,0,
410,9,164,4,0,
410,9,173,3,0,
410,9,174,2,0,
410,9,182,1,1,2
410,9,182,4,0,
410,9,184,2,0,
410,9,189,3,0,
410,9,201,4,0,
410,9,203,1,33,
410,9,203,4,0,
410,9,207,1,24,
410,9,207,4,0,
410,9,213,4,0,
410,9,214,4,0,
410,9,216,4,0,
410,9,218,4,0,
410,9,231,4,0,
410,9,237,4,0,
410,9,240,4,0,
410,9,241,4,0,
410,9,246,1,28,
410,9,246,3,0,
410,9,249,4,0,
410,9,259,4,0,
410,9,263,4,0,
410,9,269,1,6,
410,9,269,4,0,
410,9,290,4,0,
410,9,317,4,0,
410,9,319,1,10,
410,9,334,1,19,
410,9,334,3,0,
410,9,350,2,0,
410,9,351,4,0,
410,9,363,4,0,
410,9,368,1,37,
410,9,393,3,0,
410,9,397,4,0,
410,9,414,3,0,
410,9,430,4,0,
410,9,442,1,43,
410,9,442,3,0,
410,9,444,4,0,
410,9,445,4,0,
410,9,446,4,0,
410,10,29,2,0,
410,10,29,3,0,
410,10,33,1,1,1
410,10,34,2,0,
410,10,36,1,15,
410,10,38,2,0,
410,10,46,4,0,
410,10,53,4,0,
410,10,58,4,0,
410,10,59,4,0,
410,10,68,2,0,
410,10,70,4,0,
410,10,85,4,0,
410,10,87,4,0,
410,10,89,4,0,
410,10,90,2,0,
410,10,91,4,0,
410,10,92,4,0,
410,10,103,2,0,
410,10,104,4,0,
410,10,116,2,0,
410,10,126,4,0,
410,10,156,4,0,
410,10,157,4,0,
410,10,164,4,0,
410,10,173,3,0,
410,10,174,2,0,
410,10,182,1,1,2
410,10,182,4,0,
410,10,184,2,0,
410,10,189,3,0,
410,10,201,4,0,
410,10,203,1,33,
410,10,203,4,0,
410,10,207,1,24,
410,10,207,4,0,
410,10,213,4,0,
410,10,214,4,0,
410,10,216,4,0,
410,10,218,4,0,
410,10,231,4,0,
410,10,237,4,0,
410,10,240,4,0,
410,10,241,4,0,
410,10,246,1,28,
410,10,246,3,0,
410,10,249,4,0,
410,10,259,4,0,
410,10,263,4,0,
410,10,269,1,6,
410,10,269,4,0,
410,10,290,4,0,
410,10,317,4,0,
410,10,319,1,10,
410,10,334,1,19,
410,10,334,3,0,
410,10,350,2,0,
410,10,351,4,0,
410,10,363,4,0,
410,10,368,1,37,
410,10,393,3,0,
410,10,397,4,0,
410,10,414,3,0,
410,10,430,4,0,
410,10,442,1,43,
410,10,442,3,0,
410,10,444,4,0,
410,10,445,4,0,
410,10,446,4,0,
410,11,29,2,0,
410,11,33,1,1,1
410,11,34,2,0,
410,11,36,1,15,
410,11,38,2,0,
410,11,46,4,0,
410,11,53,4,0,
410,11,58,4,0,
410,11,59,4,0,
410,11,68,2,0,
410,11,70,4,0,
410,11,85,4,0,
410,11,87,4,0,
410,11,89,4,0,
410,11,90,2,0,
410,11,91,4,0,
410,11,92,4,0,
410,11,103,2,0,
410,11,104,4,0,
410,11,116,2,0,
410,11,126,4,0,
410,11,156,4,0,
410,11,157,4,0,
410,11,164,4,0,
410,11,174,2,0,
410,11,182,1,1,2
410,11,182,4,0,
410,11,184,2,0,
410,11,201,4,0,
410,11,203,1,33,
410,11,207,1,24,
410,11,207,4,0,
410,11,213,4,0,
410,11,216,4,0,
410,11,218,4,0,
410,11,237,4,0,
410,11,240,4,0,
410,11,241,4,0,
410,11,246,1,28,
410,11,249,4,0,
410,11,259,4,0,
410,11,263,4,0,
410,11,269,1,6,
410,11,269,4,0,
410,11,317,4,0,
410,11,319,1,10,
410,11,334,1,19,
410,11,350,2,0,
410,11,368,1,37,
410,11,397,4,0,
410,11,430,4,0,
410,11,442,1,42,
410,11,444,4,0,
410,11,446,2,0,
410,11,469,2,0,
410,11,479,4,0,
410,11,484,1,46,
410,11,496,4,0,
410,11,510,4,0,
410,11,523,4,0,
410,14,29,2,0,
410,14,33,1,1,1
410,14,34,2,0,
410,14,36,1,15,
410,14,38,2,0,
410,14,46,4,0,
410,14,53,4,0,
410,14,58,4,0,
410,14,59,4,0,
410,14,68,2,0,
410,14,70,4,0,
410,14,85,4,0,
410,14,87,4,0,
410,14,89,4,0,
410,14,90,2,0,
410,14,91,4,0,
410,14,92,4,0,
410,14,103,2,0,
410,14,104,4,0,
410,14,116,2,0,
410,14,126,4,0,
410,14,156,4,0,
410,14,157,4,0,
410,14,164,4,0,
410,14,173,3,0,
410,14,174,2,0,
410,14,182,1,1,2
410,14,182,4,0,
410,14,184,2,0,
410,14,201,4,0,
410,14,203,1,33,
410,14,207,1,24,
410,14,207,4,0,
410,14,213,4,0,
410,14,214,3,0,
410,14,216,4,0,
410,14,218,4,0,
410,14,231,3,0,
410,14,237,4,0,
410,14,240,4,0,
410,14,241,4,0,
410,14,246,1,28,
410,14,249,4,0,
410,14,259,4,0,
410,14,263,4,0,
410,14,269,1,6,
410,14,269,4,0,
410,14,317,4,0,
410,14,319,1,10,
410,14,334,1,19,
410,14,334,3,0,
410,14,350,2,0,
410,14,368,1,37,
410,14,393,3,0,
410,14,397,4,0,
410,14,414,3,0,
410,14,430,4,0,
410,14,442,1,42,
410,14,442,3,0,
410,14,444,4,0,
410,14,446,2,0,
410,14,446,3,0,
410,14,469,2,0,
410,14,479,4,0,
410,14,484,1,46,
410,14,496,4,0,
410,14,510,4,0,
410,14,523,4,0,
410,15,29,2,0,
410,15,33,1,1,1
410,15,34,2,0,
410,15,36,1,15,
410,15,38,2,0,
410,15,46,4,0,
410,15,53,4,0,
410,15,58,4,0,
410,15,59,4,0,
410,15,68,2,0,
410,15,70,4,0,
410,15,85,4,0,
410,15,87,4,0,
410,15,89,4,0,
410,15,90,2,0,
410,15,91,4,0,
410,15,92,4,0,
410,15,103,2,0,
410,15,104,4,0,
410,15,116,2,0,
410,15,126,4,0,
410,15,156,4,0,
410,15,157,4,0,
410,15,164,4,0,
410,15,174,2,0,
410,15,182,1,1,2
410,15,182,4,0,
410,15,184,2,0,
410,15,201,4,0,
410,15,203,1,33,
410,15,207,1,24,
410,15,207,4,0,
410,15,213,4,0,
410,15,214,4,0,
410,15,216,4,0,
410,15,218,4,0,
410,15,237,4,0,
410,15,240,4,0,
410,15,241,4,0,
410,15,246,1,28,
410,15,249,4,0,
410,15,259,4,0,
410,15,263,4,0,
410,15,269,1,6,
410,15,269,4,0,
410,15,317,4,0,
410,15,319,1,10,
410,15,334,1,19,
410,15,350,2,0,
410,15,368,1,37,
410,15,397,4,0,
410,15,430,4,0,
410,15,442,1,42,
410,15,444,4,0,
410,15,446,2,0,
410,15,469,2,0,
410,15,470,2,0,
410,15,479,4,0,
410,15,484,1,46,
410,15,496,4,0,
410,15,510,4,0,
410,15,523,4,0,
410,15,590,4,0,
410,16,29,2,0,
410,16,33,1,1,1
410,16,34,2,0,
410,16,36,1,15,1
410,16,38,2,0,
410,16,46,4,0,
410,16,53,4,0,
410,16,58,4,0,
410,16,59,4,0,
410,16,68,2,0,
410,16,70,4,0,
410,16,85,4,0,
410,16,87,4,0,
410,16,89,4,0,
410,16,90,2,0,
410,16,91,4,0,
410,16,92,4,0,
410,16,103,2,0,
410,16,104,4,0,
410,16,116,2,0,
410,16,126,4,0,
410,16,156,4,0,
410,16,157,4,0,
410,16,164,4,0,
410,16,173,3,0,
410,16,174,2,0,
410,16,182,1,1,2
410,16,182,4,0,
410,16,184,2,0,
410,16,201,4,0,
410,16,203,1,33,1
410,16,207,1,24,1
410,16,207,4,0,
410,16,213,4,0,
410,16,214,4,0,
410,16,216,4,0,
410,16,218,4,0,
410,16,231,3,0,
410,16,237,4,0,
410,16,240,4,0,
410,16,241,4,0,
410,16,246,1,28,1
410,16,249,4,0,
410,16,259,4,0,
410,16,263,4,0,
410,16,269,1,6,1
410,16,269,4,0,
410,16,290,4,0,
410,16,317,4,0,
410,16,319,1,10,1
410,16,334,1,19,1
410,16,334,3,0,
410,16,350,2,0,
410,16,351,3,0,
410,16,368,1,37,1
410,16,393,3,0,
410,16,397,4,0,
410,16,414,3,0,
410,16,430,4,0,
410,16,442,1,42,1
410,16,442,3,0,
410,16,444,4,0,
410,16,446,2,0,
410,16,446,3,0,
410,16,469,2,0,
410,16,470,2,0,
410,16,479,4,0,
410,16,484,1,46,1
410,16,496,4,0,
410,16,510,4,0,
410,16,523,4,0,
410,16,590,4,0,
410,17,29,2,0,
410,17,33,1,1,1
410,17,34,2,0,
410,17,36,1,15,
410,17,38,2,0,
410,17,46,4,0,
410,17,53,4,0,
410,17,58,4,0,
410,17,59,4,0,
410,17,68,2,0,
410,17,85,4,0,
410,17,87,4,0,
410,17,89,4,0,
410,17,90,2,0,
410,17,92,4,0,
410,17,103,2,0,
410,17,104,4,0,
410,17,116,2,0,
410,17,126,4,0,
410,17,156,4,0,
410,17,157,4,0,
410,17,164,4,0,
410,17,174,2,0,
410,17,182,1,1,2
410,17,182,4,0,
410,17,184,2,0,
410,17,201,4,0,
410,17,203,1,33,
410,17,207,1,24,
410,17,207,4,0,
410,17,213,4,0,
410,17,214,4,0,
410,17,216,4,0,
410,17,218,4,0,
410,17,237,4,0,
410,17,240,4,0,
410,17,241,4,0,
410,17,246,1,28,
410,17,259,4,0,
410,17,263,4,0,
410,17,269,1,6,
410,17,269,4,0,
410,17,317,4,0,
410,17,319,1,10,
410,17,334,1,19,
410,17,350,2,0,
410,17,368,1,37,
410,17,397,4,0,
410,17,430,4,0,
410,17,442,1,42,
410,17,444,4,0,
410,17,446,2,0,
410,17,469,2,0,
410,17,470,2,0,
410,17,479,4,0,
410,17,484,1,46,
410,17,496,4,0,
410,17,523,4,0,
410,17,590,4,0,
410,18,29,2,0,
410,18,33,1,1,1
410,18,34,2,0,
410,18,36,1,15,
410,18,38,2,0,
410,18,46,4,0,
410,18,53,4,0,
410,18,58,4,0,
410,18,59,4,0,
410,18,68,2,0,
410,18,85,4,0,
410,18,87,4,0,
410,18,89,4,0,
410,18,90,2,0,
410,18,92,4,0,
410,18,103,2,0,
410,18,104,4,0,
410,18,116,2,0,
410,18,126,4,0,
410,18,156,4,0,
410,18,157,4,0,
410,18,164,4,0,
410,18,174,2,0,
410,18,182,1,1,2
410,18,182,4,0,
410,18,184,2,0,
410,18,201,4,0,
410,18,203,1,33,
410,18,207,1,24,
410,18,207,4,0,
410,18,213,4,0,
410,18,214,4,0,
410,18,216,4,0,
410,18,218,4,0,
410,18,237,4,0,
410,18,240,4,0,
410,18,241,4,0,
410,18,246,1,28,
410,18,259,4,0,
410,18,263,4,0,
410,18,269,1,6,
410,18,269,4,0,
410,18,317,4,0,
410,18,319,1,10,
410,18,334,1,19,
410,18,350,2,0,
410,18,368,1,37,
410,18,397,4,0,
410,18,430,4,0,
410,18,442,1,42,
410,18,444,4,0,
410,18,446,2,0,
410,18,469,2,0,
410,18,470,2,0,
410,18,479,4,0,
410,18,484,1,46,
410,18,496,4,0,
410,18,523,4,0,
410,18,590,4,0,
411,8,33,1,1,1
411,8,36,1,15,
411,8,46,4,0,
411,8,53,4,0,
411,8,58,4,0,
411,8,59,4,0,
411,8,63,4,0,
411,8,70,4,0,
411,8,85,4,0,
411,8,87,4,0,
411,8,89,4,0,
411,8,91,4,0,
411,8,92,4,0,
411,8,104,4,0,
411,8,126,4,0,
411,8,156,4,0,
411,8,157,4,0,
411,8,164,4,0,
411,8,182,1,1,2
411,8,182,4,0,
411,8,201,4,0,
411,8,203,1,36,
411,8,203,4,0,
411,8,207,1,24,
411,8,207,4,0,
411,8,213,4,0,
411,8,214,4,0,
411,8,216,4,0,
411,8,218,4,0,
411,8,231,4,0,
411,8,237,4,0,
411,8,240,4,0,
411,8,241,4,0,
411,8,246,1,28,
411,8,249,4,0,
411,8,259,4,0,
411,8,263,4,0,
411,8,269,1,1,3
411,8,269,1,6,
411,8,269,4,0,
411,8,290,4,0,
411,8,317,4,0,
411,8,319,1,1,4
411,8,319,1,10,
411,8,334,1,19,
411,8,335,1,30,
411,8,351,4,0,
411,8,363,4,0,
411,8,368,1,43,
411,8,397,4,0,
411,8,416,4,0,
411,8,419,4,0,
411,8,430,4,0,
411,8,442,1,52,
411,8,444,4,0,
411,8,445,4,0,
411,8,446,4,0,
411,9,33,1,1,1
411,9,36,1,15,
411,9,46,4,0,
411,9,53,4,0,
411,9,58,4,0,
411,9,59,4,0,
411,9,63,4,0,
411,9,70,4,0,
411,9,85,4,0,
411,9,87,4,0,
411,9,89,4,0,
411,9,91,4,0,
411,9,92,4,0,
411,9,104,4,0,
411,9,126,4,0,
411,9,156,4,0,
411,9,157,4,0,
411,9,164,4,0,
411,9,173,3,0,
411,9,182,1,1,2
411,9,182,4,0,
411,9,189,3,0,
411,9,200,3,0,
411,9,201,4,0,
411,9,203,1,36,
411,9,203,4,0,
411,9,207,1,24,
411,9,207,4,0,
411,9,213,4,0,
411,9,214,4,0,
411,9,216,4,0,
411,9,218,4,0,
411,9,231,4,0,
411,9,237,4,0,
411,9,240,4,0,
411,9,241,4,0,
411,9,246,1,28,
411,9,246,3,0,
411,9,249,4,0,
411,9,259,4,0,
411,9,263,4,0,
411,9,269,1,1,3
411,9,269,1,6,
411,9,269,4,0,
411,9,290,4,0,
411,9,317,4,0,
411,9,319,1,1,4
411,9,319,1,10,
411,9,334,1,19,
411,9,334,3,0,
411,9,335,1,30,
411,9,351,4,0,
411,9,363,4,0,
411,9,368,1,43,
411,9,393,3,0,
411,9,397,4,0,
411,9,414,3,0,
411,9,416,4,0,
411,9,419,4,0,
411,9,430,4,0,
411,9,442,1,52,
411,9,442,3,0,
411,9,444,4,0,
411,9,445,4,0,
411,9,446,4,0,
411,10,29,3,0,
411,10,33,1,1,1
411,10,36,1,15,
411,10,46,4,0,
411,10,53,4,0,
411,10,58,4,0,
411,10,59,4,0,
411,10,63,4,0,
411,10,70,4,0,
411,10,85,4,0,
411,10,87,4,0,
411,10,89,4,0,
411,10,91,4,0,
411,10,92,4,0,
411,10,104,4,0,
411,10,126,4,0,
411,10,156,4,0,
411,10,157,4,0,
411,10,164,4,0,
411,10,173,3,0,
411,10,182,1,1,2
411,10,182,4,0,
411,10,189,3,0,
411,10,200,3,0,
411,10,201,4,0,
411,10,203,1,36,
411,10,203,4,0,
411,10,207,1,24,
411,10,207,4,0,
411,10,213,4,0,
411,10,214,4,0,
411,10,216,4,0,
411,10,218,4,0,
411,10,231,4,0,
411,10,237,4,0,
411,10,240,4,0,
411,10,241,4,0,
411,10,246,1,28,
411,10,246,3,0,
411,10,249,4,0,
411,10,259,4,0,
411,10,263,4,0,
411,10,269,1,1,3
411,10,269,1,6,
411,10,269,4,0,
411,10,277,3,0,
411,10,290,4,0,
411,10,317,4,0,
411,10,319,1,1,4
411,10,319,1,10,
411,10,334,1,19,
411,10,334,3,0,
411,10,335,1,30,
411,10,335,3,0,
411,10,351,4,0,
411,10,363,4,0,
411,10,368,1,43,
411,10,393,3,0,
411,10,397,4,0,
411,10,414,3,0,
411,10,416,4,0,
411,10,419,4,0,
411,10,430,4,0,
411,10,442,1,52,
411,10,442,3,0,
411,10,444,4,0,
411,10,445,4,0,
411,10,446,4,0,
411,11,33,1,1,1
411,11,36,1,15,
411,11,46,4,0,
411,11,53,4,0,
411,11,58,4,0,
411,11,59,4,0,
411,11,63,4,0,
411,11,70,4,0,
411,11,85,4,0,
411,11,87,4,0,
411,11,89,4,0,
411,11,91,4,0,
411,11,92,4,0,
411,11,104,4,0,
411,11,126,4,0,
411,11,156,4,0,
411,11,157,4,0,
411,11,164,4,0,
411,11,182,1,1,2
411,11,182,4,0,
411,11,201,4,0,
411,11,203,1,36,
411,11,207,1,24,
411,11,207,4,0,
411,11,213,4,0,
411,11,216,4,0,
411,11,218,4,0,
411,11,237,4,0,
411,11,240,4,0,
411,11,241,4,0,
411,11,246,1,28,
411,11,249,4,0,
411,11,259,4,0,
411,11,263,4,0,
411,11,269,1,1,3
411,11,269,1,6,
411,11,269,4,0,
411,11,317,4,0,
411,11,319,1,1,4
411,11,319,1,10,
411,11,334,1,19,
411,11,335,1,30,
411,11,368,1,43,
411,11,397,4,0,
411,11,416,4,0,
411,11,430,4,0,
411,11,442,1,51,
411,11,444,4,0,
411,11,479,4,0,
411,11,484,1,58,
411,11,496,4,0,
411,11,510,4,0,
411,11,523,4,0,
411,14,33,1,1,1
411,14,36,1,15,
411,14,46,4,0,
411,14,53,4,0,
411,14,58,4,0,
411,14,59,4,0,
411,14,63,4,0,
411,14,70,4,0,
411,14,85,4,0,
411,14,87,4,0,
411,14,89,4,0,
411,14,91,4,0,
411,14,92,4,0,
411,14,104,4,0,
411,14,126,4,0,
411,14,156,4,0,
411,14,157,4,0,
411,14,164,4,0,
411,14,173,3,0,
411,14,182,1,1,2
411,14,182,4,0,
411,14,200,3,0,
411,14,201,4,0,
411,14,203,1,36,
411,14,207,1,24,
411,14,207,4,0,
411,14,213,4,0,
411,14,214,3,0,
411,14,216,4,0,
411,14,218,4,0,
411,14,231,3,0,
411,14,237,4,0,
411,14,240,4,0,
411,14,241,4,0,
411,14,246,1,28,
411,14,249,4,0,
411,14,259,4,0,
411,14,263,4,0,
411,14,269,1,1,3
411,14,269,1,6,
411,14,269,4,0,
411,14,277,3,0,
411,14,317,4,0,
411,14,319,1,1,4
411,14,319,1,10,
411,14,334,1,19,
411,14,334,3,0,
411,14,335,1,30,
411,14,335,3,0,
411,14,368,1,43,
411,14,393,3,0,
411,14,397,4,0,
411,14,414,3,0,
411,14,416,4,0,
411,14,430,4,0,
411,14,442,1,51,
411,14,442,3,0,
411,14,444,4,0,
411,14,446,3,0,
411,14,479,4,0,
411,14,484,1,58,
411,14,496,4,0,
411,14,510,4,0,
411,14,523,4,0,
411,15,33,1,1,1
411,15,36,1,15,
411,15,46,4,0,
411,15,53,4,0,
411,15,58,4,0,
411,15,59,4,0,
411,15,63,4,0,
411,15,70,4,0,
411,15,85,4,0,
411,15,87,4,0,
411,15,89,4,0,
411,15,91,4,0,
411,15,92,4,0,
411,15,104,4,0,
411,15,126,4,0,
411,15,156,4,0,
411,15,157,4,0,
411,15,164,4,0,
411,15,182,1,1,2
411,15,182,4,0,
411,15,201,4,0,
411,15,203,1,36,
411,15,207,1,24,
411,15,207,4,0,
411,15,213,4,0,
411,15,214,4,0,
411,15,216,4,0,
411,15,218,4,0,
411,15,237,4,0,
411,15,240,4,0,
411,15,241,4,0,
411,15,246,1,28,
411,15,249,4,0,
411,15,259,4,0,
411,15,263,4,0,
411,15,269,1,1,3
411,15,269,1,6,
411,15,269,4,0,
411,15,317,4,0,
411,15,319,1,1,4
411,15,319,1,10,
411,15,334,1,19,
411,15,335,1,30,
411,15,368,1,43,
411,15,397,4,0,
411,15,416,4,0,
411,15,430,4,0,
411,15,442,1,51,
411,15,444,4,0,
411,15,479,4,0,
411,15,484,1,58,
411,15,496,4,0,
411,15,510,4,0,
411,15,523,4,0,
411,15,590,4,0,
411,16,33,1,1,1
411,16,36,1,15,1
411,16,46,4,0,
411,16,53,4,0,
411,16,58,4,0,
411,16,59,4,0,
411,16,63,4,0,
411,16,70,4,0,
411,16,85,4,0,
411,16,87,4,0,
411,16,89,4,0,
411,16,91,4,0,
411,16,92,4,0,
411,16,104,4,0,
411,16,126,4,0,
411,16,156,4,0,
411,16,157,4,0,
411,16,164,4,0,
411,16,173,3,0,
411,16,182,1,1,2
411,16,182,4,0,
411,16,200,3,0,
411,16,201,4,0,
411,16,203,1,36,1
411,16,207,1,24,1
411,16,207,4,0,
411,16,213,4,0,
411,16,214,4,0,
411,16,216,4,0,
411,16,218,4,0,
411,16,231,3,0,
411,16,237,4,0,
411,16,240,4,0,
411,16,241,4,0,
411,16,246,1,28,1
411,16,249,4,0,
411,16,259,4,0,
411,16,263,4,0,
411,16,269,1,1,3
411,16,269,1,6,1
411,16,269,4,0,
411,16,277,3,0,
411,16,290,4,0,
411,16,317,4,0,
411,16,319,1,1,4
411,16,319,1,10,1
411,16,334,1,19,1
411,16,334,3,0,
411,16,335,1,30,1
411,16,335,3,0,
411,16,351,3,0,
411,16,368,1,43,1
411,16,393,3,0,
411,16,397,4,0,
411,16,414,3,0,
411,16,416,4,0,
411,16,430,4,0,
411,16,442,1,51,1
411,16,442,3,0,
411,16,444,4,0,
411,16,446,3,0,
411,16,479,4,0,
411,16,484,1,58,1
411,16,496,4,0,
411,16,510,4,0,
411,16,523,4,0,
411,16,590,4,0,
411,17,33,1,1,2
411,17,36,1,15,
411,17,46,4,0,
411,17,53,4,0,
411,17,58,4,0,
411,17,59,4,0,
411,17,63,4,0,
411,17,85,4,0,
411,17,87,4,0,
411,17,89,4,0,
411,17,92,4,0,
411,17,104,4,0,
411,17,126,4,0,
411,17,156,4,0,
411,17,157,4,0,
411,17,164,4,0,
411,17,182,1,1,3
411,17,182,4,0,
411,17,201,4,0,
411,17,203,1,36,
411,17,207,1,24,
411,17,207,4,0,
411,17,213,4,0,
411,17,214,4,0,
411,17,216,4,0,
411,17,218,4,0,
411,17,237,4,0,
411,17,240,4,0,
411,17,241,4,0,
411,17,246,1,28,
411,17,259,4,0,
411,17,263,4,0,
411,17,269,1,1,4
411,17,269,1,6,
411,17,269,4,0,
411,17,317,4,0,
411,17,319,1,1,5
411,17,319,1,10,
411,17,334,1,19,
411,17,335,1,0,
411,17,335,1,1,1
411,17,368,1,43,
411,17,397,4,0,
411,17,416,4,0,
411,17,430,4,0,
411,17,442,1,51,
411,17,444,4,0,
411,17,479,4,0,
411,17,484,1,58,
411,17,496,4,0,
411,17,523,4,0,
411,17,590,4,0,
411,18,33,1,1,2
411,18,36,1,15,
411,18,46,4,0,
411,18,53,4,0,
411,18,58,4,0,
411,18,59,4,0,
411,18,63,4,0,
411,18,85,4,0,
411,18,87,4,0,
411,18,89,4,0,
411,18,92,4,0,
411,18,104,4,0,
411,18,126,4,0,
411,18,156,4,0,
411,18,157,4,0,
411,18,164,4,0,
411,18,182,1,1,3
411,18,182,4,0,
411,18,201,4,0,
411,18,203,1,36,
411,18,207,1,24,
411,18,207,4,0,
411,18,213,4,0,
411,18,214,4,0,
411,18,216,4,0,
411,18,218,4,0,
411,18,237,4,0,
411,18,240,4,0,
411,18,241,4,0,
411,18,246,1,28,
411,18,259,4,0,
411,18,263,4,0,
411,18,269,1,1,4
411,18,269,1,6,
411,18,269,4,0,
411,18,317,4,0,
411,18,319,1,1,5
411,18,319,1,10,
411,18,334,1,19,
411,18,335,1,0,
411,18,335,1,1,1
411,18,368,1,43,
411,18,397,4,0,
411,18,416,4,0,
411,18,430,4,0,
411,18,442,1,51,
411,18,444,4,0,
411,18,479,4,0,
411,18,484,1,58,
411,18,496,4,0,
411,18,523,4,0,
411,18,590,4,0,
412,8,33,1,10,
412,8,182,1,1,
412,8,237,1,20,
412,9,33,1,10,
412,9,173,3,0,
412,9,182,1,1,
412,9,237,1,20,
412,9,450,1,15,
412,10,33,1,10,
412,10,81,3,0,
412,10,173,3,0,
412,10,182,1,1,
412,10,237,1,20,
412,10,450,1,15,
412,10,450,3,0,
412,11,33,1,10,
412,11,182,1,1,
412,11,237,1,20,
412,11,450,1,15,
412,14,33,1,10,
412,14,173,3,0,
412,14,182,1,1,
412,14,237,1,20,
412,14,450,1,15,
412,14,450,3,0,
412,14,527,3,0,
412,15,33,1,10,
412,15,182,1,1,
412,15,182,4,0,
412,15,237,1,20,
412,15,237,4,0,
412,15,450,1,15,
412,16,33,1,10,1
412,16,173,3,0,
412,16,182,1,1,1
412,16,182,4,0,
412,16,237,1,20,1
412,16,237,4,0,
412,16,450,1,15,1
412,16,450,3,0,
412,16,527,3,0,
412,17,33,1,10,
412,17,182,1,1,
412,17,182,4,0,
412,17,237,1,20,
412,17,237,4,0,
412,17,450,1,15,
412,18,33,1,10,
412,18,182,1,1,
412,18,182,4,0,
412,18,237,1,20,
412,18,237,4,0,
412,18,450,1,15,
413,8,33,1,1,
413,8,60,1,32,
413,8,63,4,0,
413,8,74,1,29,
413,8,75,1,26,
413,8,76,4,0,
413,8,92,4,0,
413,8,93,1,23,
413,8,94,1,44,
413,8,94,4,0,
413,8,104,4,0,
413,8,138,4,0,
413,8,148,4,0,
413,8,156,4,0,
413,8,164,4,0,
413,8,168,4,0,
413,8,175,1,38,
413,8,182,1,10,
413,8,182,4,0,
413,8,202,4,0,
413,8,203,4,0,
413,8,207,4,0,
413,8,213,1,41,
413,8,213,4,0,
413,8,214,4,0,
413,8,216,4,0,
413,8,218,4,0,
413,8,219,4,0,
413,8,237,1,20,
413,8,237,4,0,
413,8,240,4,0,
413,8,241,4,0,
413,8,244,4,0,
413,8,247,4,0,
413,8,263,4,0,
413,8,285,4,0,
413,8,290,4,0,
413,8,331,4,0,
413,8,363,4,0,
413,8,412,4,0,
413,8,416,4,0,
413,8,437,1,47,
413,8,445,1,35,
413,8,445,4,0,
413,8,447,4,0,
413,9,33,1,1,
413,9,60,1,32,
413,9,63,4,0,
413,9,74,1,29,
413,9,75,1,26,
413,9,76,4,0,
413,9,92,4,0,
413,9,93,1,23,
413,9,94,1,44,
413,9,94,4,0,
413,9,104,4,0,
413,9,138,4,0,
413,9,148,4,0,
413,9,156,4,0,
413,9,164,4,0,
413,9,168,4,0,
413,9,173,3,0,
413,9,175,1,38,
413,9,182,1,10,
413,9,182,4,0,
413,9,202,4,0,
413,9,203,4,0,
413,9,207,4,0,
413,9,213,1,41,
413,9,213,4,0,
413,9,214,4,0,
413,9,216,4,0,
413,9,218,4,0,
413,9,219,4,0,
413,9,235,3,0,
413,9,237,1,20,
413,9,237,4,0,
413,9,240,4,0,
413,9,241,4,0,
413,9,244,4,0,
413,9,247,4,0,
413,9,253,3,0,
413,9,263,4,0,
413,9,283,3,0,
413,9,285,4,0,
413,9,290,4,0,
413,9,324,3,0,
413,9,331,4,0,
413,9,363,4,0,
413,9,389,3,0,
413,9,402,3,0,
413,9,412,4,0,
413,9,416,4,0,
413,9,437,1,47,
413,9,445,1,35,
413,9,445,4,0,
413,9,447,4,0,
413,9,450,1,15,
413,10,33,1,1,
413,10,60,1,32,
413,10,63,4,0,
413,10,74,1,29,
413,10,75,1,26,
413,10,76,4,0,
413,10,81,3,0,
413,10,92,4,0,
413,10,93,1,23,
413,10,94,1,44,
413,10,94,4,0,
413,10,104,4,0,
413,10,138,4,0,
413,10,148,4,0,
413,10,156,4,0,
413,10,164,4,0,
413,10,168,4,0,
413,10,173,3,0,
413,10,175,1,38,
413,10,182,1,10,
413,10,182,4,0,
413,10,202,4,0,
413,10,203,4,0,
413,10,207,4,0,
413,10,213,1,41,
413,10,213,4,0,
413,10,214,4,0,
413,10,216,4,0,
413,10,218,4,0,
413,10,219,4,0,
413,10,235,3,0,
413,10,237,1,20,
413,10,237,4,0,
413,10,240,4,0,
413,10,241,4,0,
413,10,244,4,0,
413,10,247,4,0,
413,10,253,3,0,
413,10,263,4,0,
413,10,283,3,0,
413,10,285,4,0,
413,10,290,4,0,
413,10,324,3,0,
413,10,331,4,0,
413,10,363,4,0,
413,10,388,3,0,
413,10,389,3,0,
413,10,402,3,0,
413,10,412,4,0,
413,10,416,4,0,
413,10,437,1,47,
413,10,445,1,35,
413,10,445,4,0,
413,10,447,4,0,
413,10,450,1,15,
413,10,450,3,0,
413,11,33,1,1,
413,11,60,1,32,
413,11,63,4,0,
413,11,74,1,29,
413,11,75,1,26,
413,11,76,4,0,
413,11,92,4,0,
413,11,93,1,23,
413,11,94,1,44,
413,11,94,4,0,
413,11,104,4,0,
413,11,138,4,0,
413,11,148,4,0,
413,11,156,4,0,
413,11,164,4,0,
413,11,168,4,0,
413,11,175,1,38,
413,11,182,1,10,
413,11,182,4,0,
413,11,207,4,0,
413,11,213,1,41,
413,11,213,4,0,
413,11,216,4,0,
413,11,218,4,0,
413,11,219,4,0,
413,11,237,1,20,
413,11,237,4,0,
413,11,240,4,0,
413,11,241,4,0,
413,11,244,4,0,
413,11,247,4,0,
413,11,263,4,0,
413,11,412,4,0,
413,11,416,4,0,
413,11,437,1,47,
413,11,445,1,35,
413,11,447,4,0,
413,11,450,1,15,
413,11,474,4,0,
413,11,496,4,0,
413,11,522,4,0,
413,14,33,1,1,
413,14,60,1,32,
413,14,63,4,0,
413,14,74,1,29,
413,14,75,1,26,
413,14,76,4,0,
413,14,92,4,0,
413,14,93,1,23,
413,14,94,1,44,
413,14,94,4,0,
413,14,104,4,0,
413,14,138,4,0,
413,14,148,4,0,
413,14,156,4,0,
413,14,164,4,0,
413,14,168,4,0,
413,14,173,3,0,
413,14,175,1,38,
413,14,182,1,10,
413,14,182,4,0,
413,14,202,3,0,
413,14,207,4,0,
413,14,213,1,41,
413,14,213,4,0,
413,14,214,3,0,
413,14,216,4,0,
413,14,218,4,0,
413,14,219,4,0,
413,14,235,3,0,
413,14,237,1,20,
413,14,237,4,0,
413,14,240,4,0,
413,14,241,4,0,
413,14,244,4,0,
413,14,247,4,0,
413,14,253,3,0,
413,14,263,4,0,
413,14,283,3,0,
413,14,285,3,0,
413,14,324,3,0,
413,14,388,3,0,
413,14,402,3,0,
413,14,412,4,0,
413,14,416,4,0,
413,14,437,1,47,
413,14,445,1,35,
413,14,447,4,0,
413,14,450,1,15,
413,14,450,3,0,
413,14,474,4,0,
413,14,496,4,0,
413,14,522,4,0,
413,14,527,3,0,
413,15,33,1,1,
413,15,60,1,32,
413,15,63,4,0,
413,15,74,1,29,
413,15,75,1,26,
413,15,76,4,0,
413,15,92,4,0,
413,15,93,1,23,
413,15,94,1,44,
413,15,94,4,0,
413,15,104,4,0,
413,15,138,4,0,
413,15,148,4,0,
413,15,156,4,0,
413,15,164,4,0,
413,15,168,4,0,
413,15,175,1,38,
413,15,182,1,10,
413,15,182,4,0,
413,15,207,4,0,
413,15,213,1,41,
413,15,213,4,0,
413,15,214,4,0,
413,15,216,4,0,
413,15,218,4,0,
413,15,219,4,0,
413,15,237,1,20,
413,15,237,4,0,
413,15,240,4,0,
413,15,241,4,0,
413,15,244,4,0,
413,15,247,4,0,
413,15,263,4,0,
413,15,412,4,0,
413,15,416,4,0,
413,15,437,1,47,
413,15,445,1,35,
413,15,447,4,0,
413,15,450,1,15,
413,15,474,4,0,
413,15,496,4,0,
413,15,522,4,0,
413,15,590,4,0,
413,15,611,4,0,
413,16,33,1,1,1
413,16,60,1,32,1
413,16,63,4,0,
413,16,74,1,29,1
413,16,75,1,26,1
413,16,76,4,0,
413,16,92,4,0,
413,16,93,1,23,1
413,16,94,1,44,1
413,16,94,4,0,
413,16,104,4,0,
413,16,138,4,0,
413,16,148,4,0,
413,16,156,4,0,
413,16,164,4,0,
413,16,168,4,0,
413,16,173,3,0,
413,16,175,1,38,1
413,16,182,1,10,1
413,16,182,4,0,
413,16,202,3,0,
413,16,207,4,0,
413,16,213,1,41,1
413,16,213,4,0,
413,16,214,4,0,
413,16,216,4,0,
413,16,218,4,0,
413,16,219,4,0,
413,16,235,3,0,
413,16,237,1,20,1
413,16,237,4,0,
413,16,240,4,0,
413,16,241,4,0,
413,16,244,4,0,
413,16,247,4,0,
413,16,253,3,0,
413,16,263,4,0,
413,16,283,3,0,
413,16,285,3,0,
413,16,290,4,0,
413,16,324,3,0,
413,16,388,3,0,
413,16,402,3,0,
413,16,412,4,0,
413,16,416,4,0,
413,16,437,1,47,1
413,16,445,1,35,1
413,16,447,4,0,
413,16,450,1,15,1
413,16,450,3,0,
413,16,474,4,0,
413,16,496,4,0,
413,16,522,4,0,
413,16,527,3,0,
413,16,590,4,0,
413,16,611,4,0,
413,17,33,1,1,3
413,17,60,1,32,
413,17,63,4,0,
413,17,74,1,29,
413,17,75,1,26,
413,17,76,4,0,
413,17,92,4,0,
413,17,93,1,23,
413,17,94,1,44,
413,17,94,4,0,
413,17,104,4,0,
413,17,138,4,0,
413,17,156,4,0,
413,17,164,4,0,
413,17,168,4,0,
413,17,175,1,38,
413,17,182,1,1,4
413,17,182,1,10,
413,17,182,4,0,
413,17,207,4,0,
413,17,213,1,41,
413,17,213,4,0,
413,17,214,4,0,
413,17,216,4,0,
413,17,218,4,0,
413,17,219,4,0,
413,17,237,1,20,
413,17,237,4,0,
413,17,240,4,0,
413,17,241,4,0,
413,17,244,4,0,
413,17,247,4,0,
413,17,263,4,0,
413,17,389,1,1,2
413,17,405,1,50,
413,17,412,4,0,
413,17,416,4,0,
413,17,437,1,47,
413,17,445,1,35,
413,17,447,4,0,
413,17,450,1,1,5
413,17,450,1,15,
413,17,474,4,0,
413,17,483,1,0,
413,17,483,1,1,1
413,17,496,4,0,
413,17,590,4,0,
413,17,611,4,0,
413,18,33,1,1,3
413,18,60,1,32,
413,18,63,4,0,
413,18,74,1,29,
413,18,75,1,26,
413,18,76,4,0,
413,18,92,4,0,
413,18,93,1,23,
413,18,94,1,44,
413,18,94,4,0,
413,18,104,4,0,
413,18,138,4,0,
413,18,156,4,0,
413,18,164,4,0,
413,18,168,4,0,
413,18,175,1,38,
413,18,182,1,1,4
413,18,182,1,10,
413,18,182,4,0,
413,18,207,4,0,
413,18,213,1,41,
413,18,213,4,0,
413,18,214,4,0,
413,18,216,4,0,
413,18,218,4,0,
413,18,219,4,0,
413,18,237,1,20,
413,18,237,4,0,
413,18,240,4,0,
413,18,241,4,0,
413,18,244,4,0,
413,18,247,4,0,
413,18,263,4,0,
413,18,389,1,1,2
413,18,405,1,50,
413,18,412,4,0,
413,18,416,4,0,
413,18,437,1,47,
413,18,445,1,35,
413,18,447,4,0,
413,18,450,1,1,5
413,18,450,1,15,
413,18,474,4,0,
413,18,483,1,0,
413,18,483,1,1,1
413,18,496,4,0,
413,18,590,4,0,
413,18,611,4,0,
414,8,16,1,26,
414,8,33,1,1,
414,8,60,1,32,
414,8,63,4,0,
414,8,76,4,0,
414,8,77,1,29,
414,8,92,4,0,
414,8,93,1,23,
414,8,94,1,44,
414,8,94,4,0,
414,8,104,4,0,
414,8,138,4,0,
414,8,148,4,0,
414,8,156,4,0,
414,8,164,4,0,
414,8,168,4,0,
414,8,182,1,10,
414,8,182,4,0,
414,8,202,4,0,
414,8,203,4,0,
414,8,207,4,0,
414,8,213,4,0,
414,8,214,4,0,
414,8,216,4,0,
414,8,218,4,0,
414,8,219,4,0,
414,8,237,1,20,
414,8,237,4,0,
414,8,240,4,0,
414,8,241,4,0,
414,8,244,4,0,
414,8,247,4,0,
414,8,263,4,0,
414,8,285,4,0,
414,8,290,4,0,
414,8,293,1,35,
414,8,318,1,38,
414,8,318,4,0,
414,8,332,4,0,
414,8,355,4,0,
414,8,363,4,0,
414,8,369,4,0,
414,8,403,1,41,
414,8,405,1,47,
414,8,412,4,0,
414,8,416,4,0,
414,8,432,4,0,
414,8,445,4,0,
414,9,16,1,26,
414,9,33,1,1,
414,9,60,1,32,
414,9,63,4,0,
414,9,76,4,0,
414,9,77,1,29,
414,9,92,4,0,
414,9,93,1,23,
414,9,94,1,44,
414,9,94,4,0,
414,9,104,4,0,
414,9,129,3,0,
414,9,138,4,0,
414,9,148,4,0,
414,9,156,4,0,
414,9,164,4,0,
414,9,168,4,0,
414,9,173,3,0,
414,9,182,1,10,
414,9,182,4,0,
414,9,189,3,0,
414,9,202,4,0,
414,9,203,4,0,
414,9,207,4,0,
414,9,213,4,0,
414,9,214,4,0,
414,9,216,4,0,
414,9,218,4,0,
414,9,219,4,0,
414,9,237,1,20,
414,9,237,4,0,
414,9,239,3,0,
414,9,240,4,0,
414,9,241,4,0,
414,9,244,4,0,
414,9,247,4,0,
414,9,263,4,0,
414,9,285,4,0,
414,9,290,4,0,
414,9,293,1,35,
414,9,314,3,0,
414,9,318,1,38,
414,9,318,4,0,
414,9,324,3,0,
414,9,332,4,0,
414,9,355,4,0,
414,9,363,4,0,
414,9,369,4,0,
414,9,403,1,41,
414,9,405,1,47,
414,9,412,4,0,
414,9,416,4,0,
414,9,432,4,0,
414,9,445,4,0,
414,9,450,1,15,
414,9,466,3,0,
414,10,16,1,26,
414,10,33,1,1,
414,10,60,1,32,
414,10,63,4,0,
414,10,76,4,0,
414,10,77,1,29,
414,10,81,3,0,
414,10,92,4,0,
414,10,93,1,23,
414,10,94,1,44,
414,10,94,4,0,
414,10,104,4,0,
414,10,129,3,0,
414,10,138,4,0,
414,10,148,4,0,
414,10,156,4,0,
414,10,164,4,0,
414,10,168,4,0,
414,10,173,3,0,
414,10,182,1,10,
414,10,182,4,0,
414,10,189,3,0,
414,10,202,4,0,
414,10,203,4,0,
414,10,207,4,0,
414,10,213,4,0,
414,10,214,4,0,
414,10,216,4,0,
414,10,218,4,0,
414,10,219,4,0,
414,10,237,1,20,
414,10,237,4,0,
414,10,239,3,0,
414,10,240,4,0,
414,10,241,4,0,
414,10,244,4,0,
414,10,247,4,0,
414,10,263,4,0,
414,10,285,4,0,
414,10,290,4,0,
414,10,293,1,35,
414,10,314,3,0,
414,10,318,1,38,
414,10,318,4,0,
414,10,324,3,0,
414,10,332,4,0,
414,10,355,4,0,
414,10,363,4,0,
414,10,366,3,0,
414,10,369,4,0,
414,10,403,1,41,
414,10,405,1,47,
414,10,412,4,0,
414,10,416,4,0,
414,10,445,4,0,
414,10,450,1,15,
414,10,450,3,0,
414,10,466,3,0,
414,11,16,1,26,
414,11,33,1,1,
414,11,60,1,32,
414,11,63,4,0,
414,11,76,4,0,
414,11,77,1,29,
414,11,92,4,0,
414,11,93,1,23,
414,11,94,1,44,
414,11,94,4,0,
414,11,104,4,0,
414,11,138,4,0,
414,11,148,4,0,
414,11,156,4,0,
414,11,164,4,0,
414,11,168,4,0,
414,11,182,1,10,
414,11,182,4,0,
414,11,207,4,0,
414,11,213,4,0,
414,11,216,4,0,
414,11,218,4,0,
414,11,219,4,0,
414,11,237,1,20,
414,11,237,4,0,
414,11,240,4,0,
414,11,241,4,0,
414,11,244,4,0,
414,11,247,4,0,
414,11,263,4,0,
414,11,293,1,35,
414,11,318,1,38,
414,11,332,4,0,
414,11,369,4,0,
414,11,403,1,41,
414,11,405,1,47,
414,11,412,4,0,
414,11,416,4,0,
414,11,450,1,15,
414,11,474,4,0,
414,11,483,1,50,
414,11,496,4,0,
414,11,512,4,0,
414,11,522,4,0,
414,14,16,1,26,
414,14,33,1,1,
414,14,60,1,32,
414,14,63,4,0,
414,14,76,4,0,
414,14,77,1,29,
414,14,92,4,0,
414,14,93,1,23,
414,14,94,1,44,
414,14,94,4,0,
414,14,104,4,0,
414,14,138,4,0,
414,14,148,4,0,
414,14,156,4,0,
414,14,164,4,0,
414,14,168,4,0,
414,14,173,3,0,
414,14,182,1,10,
414,14,182,4,0,
414,14,202,3,0,
414,14,207,4,0,
414,14,213,4,0,
414,14,214,3,0,
414,14,216,4,0,
414,14,218,4,0,
414,14,219,4,0,
414,14,237,1,20,
414,14,237,4,0,
414,14,240,4,0,
414,14,241,4,0,
414,14,244,4,0,
414,14,247,4,0,
414,14,263,4,0,
414,14,285,3,0,
414,14,293,1,35,
414,14,318,1,38,
414,14,324,3,0,
414,14,332,4,0,
414,14,355,3,0,
414,14,366,3,0,
414,14,369,4,0,
414,14,403,1,41,
414,14,405,1,47,
414,14,412,4,0,
414,14,416,4,0,
414,14,450,1,15,
414,14,450,3,0,
414,14,474,4,0,
414,14,483,1,50,
414,14,496,4,0,
414,14,512,4,0,
414,14,522,4,0,
414,14,527,3,0,
414,15,16,1,26,
414,15,33,1,1,
414,15,60,1,32,
414,15,63,4,0,
414,15,76,4,0,
414,15,77,1,29,
414,15,92,4,0,
414,15,93,1,23,
414,15,94,1,44,
414,15,94,4,0,
414,15,104,4,0,
414,15,138,4,0,
414,15,148,4,0,
414,15,156,4,0,
414,15,164,4,0,
414,15,168,4,0,
414,15,182,1,10,
414,15,182,4,0,
414,15,207,4,0,
414,15,213,4,0,
414,15,214,4,0,
414,15,216,4,0,
414,15,218,4,0,
414,15,219,4,0,
414,15,237,1,20,
414,15,237,4,0,
414,15,240,4,0,
414,15,241,4,0,
414,15,244,4,0,
414,15,247,4,0,
414,15,263,4,0,
414,15,293,1,35,
414,15,318,1,38,
414,15,332,4,0,
414,15,355,4,0,
414,15,369,4,0,
414,15,403,1,41,
414,15,405,1,47,
414,15,412,4,0,
414,15,416,4,0,
414,15,450,1,15,
414,15,474,4,0,
414,15,483,1,50,
414,15,496,4,0,
414,15,512,4,0,
414,15,522,4,0,
414,15,590,4,0,
414,15,611,4,0,
414,16,16,1,26,1
414,16,33,1,1,1
414,16,60,1,32,1
414,16,63,4,0,
414,16,76,4,0,
414,16,77,1,29,1
414,16,92,4,0,
414,16,93,1,23,1
414,16,94,1,44,1
414,16,94,4,0,
414,16,104,4,0,
414,16,138,4,0,
414,16,148,4,0,
414,16,156,4,0,
414,16,164,4,0,
414,16,168,4,0,
414,16,173,3,0,
414,16,182,1,10,1
414,16,182,4,0,
414,16,202,3,0,
414,16,207,4,0,
414,16,213,4,0,
414,16,214,4,0,
414,16,216,4,0,
414,16,218,4,0,
414,16,219,4,0,
414,16,237,1,20,1
414,16,237,4,0,
414,16,240,4,0,
414,16,241,4,0,
414,16,244,4,0,
414,16,247,4,0,
414,16,263,4,0,
414,16,285,3,0,
414,16,290,4,0,
414,16,293,1,35,1
414,16,318,1,38,1
414,16,324,3,0,
414,16,332,4,0,
414,16,355,4,0,
414,16,366,3,0,
414,16,369,4,0,
414,16,403,1,41,1
414,16,405,1,47,1
414,16,412,4,0,
414,16,416,4,0,
414,16,450,1,15,1
414,16,450,3,0,
414,16,474,4,0,
414,16,483,1,50,1
414,16,496,4,0,
414,16,512,4,0,
414,16,522,4,0,
414,16,527,3,0,
414,16,590,4,0,
414,16,611,4,0,
414,17,16,1,26,
414,17,33,1,1,2
414,17,60,1,32,
414,17,63,4,0,
414,17,76,4,0,
414,17,77,1,29,
414,17,92,4,0,
414,17,93,1,23,
414,17,94,1,44,
414,17,94,4,0,
414,17,104,4,0,
414,17,138,4,0,
414,17,156,4,0,
414,17,164,4,0,
414,17,168,4,0,
414,17,182,1,1,3
414,17,182,1,10,
414,17,182,4,0,
414,17,207,4,0,
414,17,213,4,0,
414,17,214,4,0,
414,17,216,4,0,
414,17,218,4,0,
414,17,219,4,0,
414,17,237,1,20,
414,17,237,4,0,
414,17,240,4,0,
414,17,241,4,0,
414,17,244,4,0,
414,17,247,4,0,
414,17,263,4,0,
414,17,293,1,35,
414,17,318,1,38,
414,17,332,4,0,
414,17,355,4,0,
414,17,369,4,0,
414,17,403,1,41,
414,17,405,1,50,
414,17,412,4,0,
414,17,416,4,0,
414,17,450,1,1,4
414,17,450,1,15,
414,17,474,4,0,
414,17,483,1,0,
414,17,483,1,1,1
414,17,496,4,0,
414,17,512,4,0,
414,17,590,4,0,
414,17,611,4,0,
414,17,679,1,47,
414,18,16,1,26,
414,18,33,1,1,2
414,18,60,1,32,
414,18,63,4,0,
414,18,76,4,0,
414,18,77,1,29,
414,18,92,4,0,
414,18,93,1,23,
414,18,94,1,44,
414,18,94,4,0,
414,18,104,4,0,
414,18,138,4,0,
414,18,156,4,0,
414,18,164,4,0,
414,18,168,4,0,
414,18,182,1,1,3
414,18,182,1,10,
414,18,182,4,0,
414,18,207,4,0,
414,18,213,4,0,
414,18,214,4,0,
414,18,216,4,0,
414,18,218,4,0,
414,18,219,4,0,
414,18,237,1,20,
414,18,237,4,0,
414,18,240,4,0,
414,18,241,4,0,
414,18,244,4,0,
414,18,247,4,0,
414,18,263,4,0,
414,18,293,1,35,
414,18,318,1,38,
414,18,332,4,0,
414,18,355,4,0,
414,18,369,4,0,
414,18,403,1,41,
414,18,405,1,50,
414,18,412,4,0,
414,18,416,4,0,
414,18,450,1,1,4
414,18,450,1,15,
414,18,474,4,0,
414,18,483,1,0,
414,18,483,1,1,1
414,18,496,4,0,
414,18,512,4,0,
414,18,590,4,0,
414,18,611,4,0,
414,18,679,1,47,
415,8,16,1,1,2
415,8,230,1,1,1
415,9,16,1,1,2
415,9,129,3,0,
415,9,173,3,0,
415,9,189,3,0,
415,9,230,1,1,1
415,9,283,3,0,
415,9,314,3,0,
415,9,450,1,13,
415,9,466,3,0,
415,10,16,1,1,2
415,10,81,3,0,
415,10,129,3,0,
415,10,173,3,0,
415,10,189,3,0,
415,10,230,1,1,1
415,10,283,3,0,
415,10,314,3,0,
415,10,366,3,0,
415,10,450,1,13,
415,10,450,3,0,
415,10,466,3,0,
415,11,16,1,1,2
415,11,230,1,1,1
415,11,450,1,13,
415,14,16,1,1,2
415,14,173,3,0,
415,14,230,1,1,1
415,14,283,3,0,
415,14,366,3,0,
415,14,405,1,29,
415,14,450,1,13,
415,14,450,3,0,
415,15,16,1,1,2
415,15,230,1,1,1
415,15,405,1,29,
415,15,450,1,13,
415,16,16,1,1,2
415,16,173,3,0,
415,16,230,1,1,1
415,16,283,3,0,
415,16,366,3,0,
415,16,405,1,29,1
415,16,450,1,13,1
415,16,450,3,0,
415,17,16,1,1,2
415,17,230,1,1,1
415,17,405,1,29,
415,17,450,1,13,
415,18,16,1,1,2
415,18,230,1,1,1
415,18,405,1,29,
415,18,450,1,13,
416,8,15,4,0,
416,8,16,1,1,2
416,8,40,1,3,
416,8,63,4,0,
416,8,92,1,27,
416,8,92,4,0,
416,8,104,4,0,
416,8,109,1,7,
416,8,148,4,0,
416,8,154,1,19,
416,8,156,4,0,
416,8,163,1,31,
416,8,164,4,0,
416,8,168,4,0,
416,8,182,4,0,
416,8,188,4,0,
416,8,194,1,43,
416,8,203,4,0,
416,8,207,1,39,
416,8,207,4,0,
416,8,210,1,9,
416,8,213,4,0,
416,8,214,4,0,
416,8,216,4,0,
416,8,218,4,0,
416,8,228,1,15,
416,8,230,1,1,1
416,8,237,4,0,
416,8,240,4,0,
416,8,241,4,0,
416,8,263,4,0,
416,8,290,4,0,
416,8,318,4,0,
416,8,332,4,0,
416,8,355,4,0,
416,8,363,4,0,
416,8,369,4,0,
416,8,374,4,0,
416,8,404,4,0,
416,8,408,1,21,
416,8,416,4,0,
416,8,432,4,0,
416,8,445,1,33,
416,8,445,4,0,
416,8,454,1,37,
416,8,455,1,13,
416,8,456,1,25,
416,9,15,4,0,
416,9,16,1,1,2
416,9,40,1,3,
416,9,63,4,0,
416,9,92,1,27,
416,9,92,4,0,
416,9,104,4,0,
416,9,109,1,7,
416,9,129,3,0,
416,9,148,4,0,
416,9,154,1,19,
416,9,156,4,0,
416,9,163,1,31,
416,9,164,4,0,
416,9,168,4,0,
416,9,173,3,0,
416,9,182,4,0,
416,9,188,4,0,
416,9,189,3,0,
416,9,194,1,43,
416,9,203,4,0,
416,9,207,1,39,
416,9,207,4,0,
416,9,210,1,9,
416,9,210,3,0,
416,9,213,4,0,
416,9,214,4,0,
416,9,216,4,0,
416,9,218,4,0,
416,9,228,1,15,
416,9,230,1,1,1
416,9,237,4,0,
416,9,240,4,0,
416,9,241,4,0,
416,9,263,4,0,
416,9,283,3,0,
416,9,290,4,0,
416,9,314,3,0,
416,9,318,4,0,
416,9,324,3,0,
416,9,332,4,0,
416,9,355,4,0,
416,9,363,4,0,
416,9,369,4,0,
416,9,374,4,0,
416,9,404,4,0,
416,9,408,1,21,
416,9,416,4,0,
416,9,432,4,0,
416,9,445,1,33,
416,9,445,4,0,
416,9,454,1,37,
416,9,455,1,13,
416,9,456,1,25,
416,9,466,3,0,
416,10,15,4,0,
416,10,16,1,1,2
416,10,40,1,3,
416,10,63,4,0,
416,10,81,3,0,
416,10,92,1,27,
416,10,92,4,0,
416,10,104,4,0,
416,10,109,1,7,
416,10,129,3,0,
416,10,148,4,0,
416,10,154,1,19,
416,10,156,4,0,
416,10,163,1,31,
416,10,164,4,0,
416,10,168,4,0,
416,10,173,3,0,
416,10,182,4,0,
416,10,188,4,0,
416,10,189,3,0,
416,10,194,1,43,
416,10,203,4,0,
416,10,207,1,39,
416,10,207,4,0,
416,10,210,1,9,
416,10,210,3,0,
416,10,213,4,0,
416,10,214,4,0,
416,10,216,4,0,
416,10,218,4,0,
416,10,228,1,15,
416,10,230,1,1,1
416,10,237,4,0,
416,10,240,4,0,
416,10,241,4,0,
416,10,263,4,0,
416,10,283,3,0,
416,10,290,4,0,
416,10,314,3,0,
416,10,318,4,0,
416,10,324,3,0,
416,10,332,4,0,
416,10,355,4,0,
416,10,363,4,0,
416,10,366,3,0,
416,10,369,4,0,
416,10,374,4,0,
416,10,404,4,0,
416,10,408,1,21,
416,10,416,4,0,
416,10,445,1,33,
416,10,445,4,0,
416,10,450,3,0,
416,10,454,1,37,
416,10,455,1,13,
416,10,456,1,25,
416,10,466,3,0,
416,11,15,4,0,
416,11,16,1,1,2
416,11,40,1,3,
416,11,63,4,0,
416,11,92,1,27,
416,11,92,4,0,
416,11,104,4,0,
416,11,109,1,7,
416,11,148,4,0,
416,11,154,1,19,
416,11,156,4,0,
416,11,163,1,31,
416,11,164,4,0,
416,11,168,4,0,
416,11,182,4,0,
416,11,188,4,0,
416,11,194,1,43,
416,11,207,1,39,
416,11,207,4,0,
416,11,210,1,9,
416,11,213,4,0,
416,11,216,4,0,
416,11,218,4,0,
416,11,228,1,15,
416,11,230,1,1,1
416,11,237,4,0,
416,11,240,4,0,
416,11,241,4,0,
416,11,263,4,0,
416,11,332,4,0,
416,11,369,4,0,
416,11,374,4,0,
416,11,404,4,0,
416,11,408,1,21,
416,11,416,4,0,
416,11,445,1,33,
416,11,454,1,37,
416,11,455,1,13,
416,11,456,1,25,
416,11,468,4,0,
416,11,474,4,0,
416,11,496,4,0,
416,11,511,4,0,
416,11,512,4,0,
416,11,522,4,0,
416,14,15,4,0,
416,14,16,1,1,2
416,14,40,1,1,3
416,14,63,4,0,
416,14,92,1,33,
416,14,92,4,0,
416,14,104,4,0,
416,14,109,1,1,4
416,14,148,4,0,
416,14,154,1,13,
416,14,156,4,0,
416,14,163,1,21,
416,14,164,4,0,
416,14,168,4,0,
416,14,173,3,0,
416,14,182,4,0,
416,14,188,4,0,
416,14,194,1,53,
416,14,207,1,49,
416,14,207,4,0,
416,14,210,1,5,
416,14,213,4,0,
416,14,214,3,0,
416,14,216,4,0,
416,14,218,4,0,
416,14,228,1,9,
416,14,230,1,1,1
416,14,237,4,0,
416,14,240,4,0,
416,14,241,4,0,
416,14,263,4,0,
416,14,283,3,0,
416,14,324,3,0,
416,14,332,4,0,
416,14,355,3,0,
416,14,366,3,0,
416,14,369,4,0,
416,14,374,4,0,
416,14,403,1,37,
416,14,404,4,0,
416,14,408,1,25,
416,14,416,4,0,
416,14,445,1,41,
416,14,450,3,0,
416,14,454,1,45,
416,14,455,1,17,
416,14,456,1,29,
416,14,468,4,0,
416,14,474,4,0,
416,14,496,4,0,
416,14,511,4,0,
416,14,512,4,0,
416,14,522,4,0,
416,15,15,4,0,
416,15,16,1,1,4
416,15,40,1,1,5
416,15,63,4,0,
416,15,92,1,33,
416,15,92,4,0,
416,15,104,4,0,
416,15,109,1,1,6
416,15,148,4,0,
416,15,154,1,13,
416,15,156,4,0,
416,15,163,1,21,
416,15,164,4,0,
416,15,168,4,0,
416,15,182,4,0,
416,15,188,4,0,
416,15,194,1,1,2
416,15,194,1,53,
416,15,207,1,49,
416,15,207,4,0,
416,15,210,1,5,
416,15,213,4,0,
416,15,214,4,0,
416,15,216,4,0,
416,15,218,4,0,
416,15,228,1,9,
416,15,230,1,1,3
416,15,237,4,0,
416,15,240,4,0,
416,15,241,4,0,
416,15,263,4,0,
416,15,332,4,0,
416,15,355,4,0,
416,15,369,4,0,
416,15,374,4,0,
416,15,403,1,37,
416,15,404,4,0,
416,15,408,1,25,
416,15,416,4,0,
416,15,445,1,41,
416,15,454,1,45,
416,15,455,1,17,
416,15,456,1,29,
416,15,468,4,0,
416,15,474,4,0,
416,15,496,4,0,
416,15,511,4,0,
416,15,512,4,0,
416,15,522,4,0,
416,15,565,1,1,1
416,15,565,1,57,
416,15,590,4,0,
416,15,611,4,0,
416,16,15,4,0,
416,16,16,1,1,4
416,16,40,1,1,5
416,16,63,4,0,
416,16,92,1,33,1
416,16,92,4,0,
416,16,104,4,0,
416,16,109,1,1,6
416,16,148,4,0,
416,16,154,1,13,1
416,16,156,4,0,
416,16,163,1,21,1
416,16,164,4,0,
416,16,168,4,0,
416,16,173,3,0,
416,16,182,4,0,
416,16,188,4,0,
416,16,194,1,1,2
416,16,194,1,53,1
416,16,207,1,49,1
416,16,207,4,0,
416,16,210,1,5,1
416,16,213,4,0,
416,16,214,4,0,
416,16,216,4,0,
416,16,218,4,0,
416,16,228,1,9,1
416,16,230,1,1,3
416,16,237,4,0,
416,16,240,4,0,
416,16,241,4,0,
416,16,263,4,0,
416,16,283,3,0,
416,16,290,4,0,
416,16,324,3,0,
416,16,332,4,0,
416,16,355,4,0,
416,16,366,3,0,
416,16,369,4,0,
416,16,374,4,0,
416,16,403,1,37,1
416,16,404,4,0,
416,16,408,1,25,1
416,16,416,4,0,
416,16,445,1,41,1
416,16,450,3,0,
416,16,454,1,45,1
416,16,455,1,17,1
416,16,456,1,29,1
416,16,468,4,0,
416,16,474,4,0,
416,16,496,4,0,
416,16,511,4,0,
416,16,512,4,0,
416,16,522,4,0,
416,16,565,1,1,1
416,16,565,1,57,1
416,16,590,4,0,
416,16,611,4,0,
416,17,16,1,1,5
416,17,40,1,1,6
416,17,63,4,0,
416,17,92,1,33,
416,17,92,4,0,
416,17,104,4,0,
416,17,109,1,1,7
416,17,154,1,13,
416,17,156,4,0,
416,17,163,1,0,
416,17,163,1,1,1
416,17,164,4,0,
416,17,168,4,0,
416,17,182,4,0,
416,17,188,4,0,
416,17,194,1,1,3
416,17,194,1,53,
416,17,207,1,49,
416,17,207,4,0,
416,17,210,1,5,
416,17,213,4,0,
416,17,214,4,0,
416,17,216,4,0,
416,17,218,4,0,
416,17,228,1,9,
416,17,230,1,1,4
416,17,237,4,0,
416,17,240,4,0,
416,17,241,4,0,
416,17,263,4,0,
416,17,332,4,0,
416,17,355,4,0,
416,17,369,4,0,
416,17,374,4,0,
416,17,403,1,37,
416,17,404,4,0,
416,17,408,1,25,
416,17,416,4,0,
416,17,445,1,41,
416,17,454,1,45,
416,17,455,1,17,
416,17,456,1,29,
416,17,474,4,0,
416,17,496,4,0,
416,17,511,4,0,
416,17,512,4,0,
416,17,565,1,1,2
416,17,565,1,57,
416,17,590,4,0,
416,17,611,4,0,
416,18,16,1,1,5
416,18,40,1,1,6
416,18,63,4,0,
416,18,92,1,33,
416,18,92,4,0,
416,18,104,4,0,
416,18,109,1,1,7
416,18,154,1,13,
416,18,156,4,0,
416,18,163,1,0,
416,18,163,1,1,1
416,18,164,4,0,
416,18,168,4,0,
416,18,182,4,0,
416,18,188,4,0,
416,18,194,1,1,3
416,18,194,1,53,
416,18,207,1,49,
416,18,207,4,0,
416,18,210,1,5,
416,18,213,4,0,
416,18,214,4,0,
416,18,216,4,0,
416,18,218,4,0,
416,18,228,1,9,
416,18,230,1,1,4
416,18,237,4,0,
416,18,240,4,0,
416,18,241,4,0,
416,18,263,4,0,
416,18,332,4,0,
416,18,355,4,0,
416,18,369,4,0,
416,18,374,4,0,
416,18,403,1,37,
416,18,404,4,0,
416,18,408,1,25,
416,18,416,4,0,
416,18,445,1,41,
416,18,454,1,45,
416,18,455,1,17,
416,18,456,1,29,
416,18,474,4,0,
416,18,496,4,0,
416,18,511,4,0,
416,18,512,4,0,
416,18,565,1,1,2
416,18,565,1,57,
416,18,590,4,0,
416,18,611,4,0,
417,8,15,4,0,
417,8,44,2,0,
417,8,45,1,1,1
417,8,85,4,0,
417,8,86,4,0,
417,8,87,4,0,
417,8,91,4,0,
417,8,92,4,0,
417,8,98,1,5,
417,8,104,4,0,
417,8,111,2,0,
417,8,113,4,0,
417,8,117,1,1,2
417,8,129,1,21,
417,8,148,4,0,
417,8,156,4,0,
417,8,162,1,33,
417,8,164,4,0,
417,8,175,2,0,
417,8,182,4,0,
417,8,186,1,25,
417,8,203,1,17,
417,8,203,4,0,
417,8,204,1,9,
417,8,205,2,0,
417,8,207,4,0,
417,8,209,1,13,
417,8,213,4,0,
417,8,214,4,0,
417,8,216,4,0,
417,8,218,4,0,
417,8,231,4,0,
417,8,237,4,0,
417,8,240,4,0,
417,8,260,2,0,
417,8,263,4,0,
417,8,290,4,0,
417,8,313,2,0,
417,8,343,2,0,
417,8,351,4,0,
417,8,363,4,0,
417,8,369,4,0,
417,8,374,4,0,
417,8,387,1,37,
417,8,435,1,29,
417,8,445,4,0,
417,8,447,4,0,
417,8,451,4,0,
417,9,9,3,0,
417,9,15,4,0,
417,9,44,2,0,
417,9,45,1,1,1
417,9,85,4,0,
417,9,86,4,0,
417,9,87,4,0,
417,9,91,4,0,
417,9,92,4,0,
417,9,98,1,5,
417,9,104,4,0,
417,9,111,2,0,
417,9,113,4,0,
417,9,117,1,1,2
417,9,129,1,21,
417,9,129,3,0,
417,9,148,4,0,
417,9,156,4,0,
417,9,162,1,33,
417,9,164,4,0,
417,9,173,3,0,
417,9,175,2,0,
417,9,182,4,0,
417,9,186,1,25,
417,9,189,3,0,
417,9,203,1,17,
417,9,203,4,0,
417,9,204,1,9,
417,9,205,2,0,
417,9,205,3,0,
417,9,207,4,0,
417,9,209,1,13,
417,9,213,4,0,
417,9,214,4,0,
417,9,216,4,0,
417,9,218,4,0,
417,9,231,4,0,
417,9,237,4,0,
417,9,240,4,0,
417,9,253,3,0,
417,9,260,2,0,
417,9,263,4,0,
417,9,270,3,0,
417,9,290,4,0,
417,9,313,2,0,
417,9,343,2,0,
417,9,351,4,0,
417,9,363,4,0,
417,9,369,4,0,
417,9,374,4,0,
417,9,387,1,37,
417,9,387,3,0,
417,9,393,3,0,
417,9,402,3,0,
417,9,435,1,29,
417,9,441,3,0,
417,9,445,4,0,
417,9,447,4,0,
417,9,451,4,0,
417,10,9,3,0,
417,10,15,4,0,
417,10,29,3,0,
417,10,44,2,0,
417,10,45,1,1,1
417,10,85,4,0,
417,10,86,4,0,
417,10,87,4,0,
417,10,91,4,0,
417,10,92,4,0,
417,10,98,1,5,
417,10,104,4,0,
417,10,111,2,0,
417,10,113,4,0,
417,10,117,1,1,2
417,10,129,1,21,
417,10,129,3,0,
417,10,148,4,0,
417,10,156,4,0,
417,10,162,1,33,
417,10,162,3,0,
417,10,164,4,0,
417,10,173,3,0,
417,10,175,2,0,
417,10,182,4,0,
417,10,186,1,25,
417,10,189,3,0,
417,10,203,1,17,
417,10,203,4,0,
417,10,204,1,9,
417,10,205,2,0,
417,10,205,3,0,
417,10,207,4,0,
417,10,209,1,13,
417,10,213,4,0,
417,10,214,4,0,
417,10,216,4,0,
417,10,218,4,0,
417,10,231,4,0,
417,10,237,4,0,
417,10,240,4,0,
417,10,253,3,0,
417,10,260,2,0,
417,10,263,4,0,
417,10,270,3,0,
417,10,290,4,0,
417,10,313,2,0,
417,10,343,2,0,
417,10,351,4,0,
417,10,363,4,0,
417,10,369,4,0,
417,10,374,4,0,
417,10,387,1,37,
417,10,387,3,0,
417,10,393,3,0,
417,10,402,3,0,
417,10,435,1,29,
417,10,441,3,0,
417,10,445,4,0,
417,10,447,4,0,
417,10,451,4,0,
417,11,15,4,0,
417,11,39,2,0,
417,11,44,2,0,
417,11,45,1,1,1
417,11,85,4,0,
417,11,86,1,33,
417,11,86,4,0,
417,11,87,4,0,
417,11,91,4,0,
417,11,92,4,0,
417,11,98,1,5,
417,11,104,4,0,
417,11,111,2,0,
417,11,113,4,0,
417,11,117,1,1,2
417,11,129,1,21,
417,11,148,4,0,
417,11,156,4,0,
417,11,158,1,49,
417,11,162,1,37,
417,11,164,4,0,
417,11,175,2,0,
417,11,182,4,0,
417,11,186,1,29,
417,11,203,1,17,
417,11,204,1,9,
417,11,205,2,0,
417,11,207,4,0,
417,11,209,1,13,
417,11,213,4,0,
417,11,216,4,0,
417,11,218,4,0,
417,11,231,2,0,
417,11,237,4,0,
417,11,240,4,0,
417,11,260,2,0,
417,11,263,4,0,
417,11,266,2,0,
417,11,268,2,0,
417,11,313,2,0,
417,11,343,2,0,
417,11,369,4,0,
417,11,374,4,0,
417,11,387,1,45,
417,11,435,1,41,
417,11,447,4,0,
417,11,451,4,0,
417,11,486,1,25,
417,11,496,4,0,
417,11,497,4,0,
417,11,516,2,0,
417,11,521,4,0,
417,14,9,3,0,
417,14,15,4,0,
417,14,39,2,0,
417,14,44,2,0,
417,14,45,1,1,1
417,14,85,4,0,
417,14,86,1,33,
417,14,86,4,0,
417,14,87,4,0,
417,14,91,4,0,
417,14,92,4,0,
417,14,98,1,5,
417,14,104,4,0,
417,14,111,2,0,
417,14,113,4,0,
417,14,117,1,1,2
417,14,129,1,21,
417,14,148,4,0,
417,14,156,4,0,
417,14,158,1,49,
417,14,162,1,37,
417,14,162,3,0,
417,14,164,4,0,
417,14,173,3,0,
417,14,175,2,0,
417,14,182,4,0,
417,14,186,1,29,
417,14,203,1,17,
417,14,204,1,9,
417,14,205,2,0,
417,14,207,4,0,
417,14,209,1,13,
417,14,213,4,0,
417,14,214,3,0,
417,14,216,4,0,
417,14,218,4,0,
417,14,231,2,0,
417,14,231,3,0,
417,14,237,4,0,
417,14,240,4,0,
417,14,253,3,0,
417,14,260,2,0,
417,14,263,4,0,
417,14,266,2,0,
417,14,268,2,0,
417,14,270,3,0,
417,14,313,2,0,
417,14,343,2,0,
417,14,343,3,0,
417,14,369,4,0,
417,14,374,4,0,
417,14,387,1,45,
417,14,387,3,0,
417,14,393,3,0,
417,14,402,3,0,
417,14,435,1,41,
417,14,441,3,0,
417,14,447,4,0,
417,14,451,4,0,
417,14,486,1,25,
417,14,496,4,0,
417,14,497,4,0,
417,14,516,2,0,
417,14,521,4,0,
417,15,15,4,0,
417,15,39,2,0,
417,15,44,2,0,
417,15,45,1,1,1
417,15,85,4,0,
417,15,86,1,33,
417,15,86,4,0,
417,15,87,4,0,
417,15,91,4,0,
417,15,92,4,0,
417,15,98,1,5,
417,15,104,4,0,
417,15,111,2,0,
417,15,113,4,0,
417,15,117,1,1,2
417,15,129,1,21,
417,15,148,4,0,
417,15,156,4,0,
417,15,158,1,49,
417,15,162,1,37,
417,15,164,4,0,
417,15,175,2,0,
417,15,182,4,0,
417,15,186,1,29,
417,15,203,1,17,
417,15,204,1,9,
417,15,205,2,0,
417,15,207,4,0,
417,15,209,1,13,
417,15,213,4,0,
417,15,214,4,0,
417,15,216,4,0,
417,15,218,4,0,
417,15,231,2,0,
417,15,237,4,0,
417,15,240,4,0,
417,15,260,2,0,
417,15,263,4,0,
417,15,266,2,0,
417,15,268,2,0,
417,15,313,2,0,
417,15,343,2,0,
417,15,369,4,0,
417,15,374,4,0,
417,15,387,1,45,
417,15,435,1,41,
417,15,447,4,0,
417,15,451,4,0,
417,15,486,1,25,
417,15,496,4,0,
417,15,497,4,0,
417,15,516,2,0,
417,15,521,4,0,
417,15,569,2,0,
417,15,590,4,0,
417,15,609,1,19,
417,16,9,3,0,
417,16,15,4,0,
417,16,39,2,0,
417,16,44,2,0,
417,16,45,1,1,1
417,16,85,4,0,
417,16,86,1,33,1
417,16,86,4,0,
417,16,87,4,0,
417,16,91,4,0,
417,16,92,4,0,
417,16,98,1,5,1
417,16,104,4,0,
417,16,111,2,0,
417,16,113,4,0,
417,16,117,1,1,2
417,16,129,1,21,1
417,16,148,4,0,
417,16,156,4,0,
417,16,158,1,49,1
417,16,162,1,37,1
417,16,162,3,0,
417,16,164,4,0,
417,16,173,3,0,
417,16,175,2,0,
417,16,182,4,0,
417,16,186,1,29,1
417,16,203,1,17,1
417,16,204,1,9,1
417,16,205,2,0,
417,16,207,4,0,
417,16,209,1,13,1
417,16,213,4,0,
417,16,214,4,0,
417,16,216,4,0,
417,16,218,4,0,
417,16,231,2,0,
417,16,231,3,0,
417,16,237,4,0,
417,16,240,4,0,
417,16,253,3,0,
417,16,260,2,0,
417,16,263,4,0,
417,16,266,2,0,
417,16,268,2,0,
417,16,270,3,0,
417,16,290,4,0,
417,16,313,2,0,
417,16,343,2,0,
417,16,343,3,0,
417,16,351,3,0,
417,16,369,4,0,
417,16,374,4,0,
417,16,387,1,45,1
417,16,387,3,0,
417,16,393,3,0,
417,16,402,3,0,
417,16,435,1,41,1
417,16,441,3,0,
417,16,447,4,0,
417,16,451,4,0,
417,16,486,1,25,1
417,16,496,4,0,
417,16,497,4,0,
417,16,516,2,0,
417,16,521,4,0,
417,16,527,3,0,
417,16,569,2,0,
417,16,590,4,0,
417,16,609,1,19,1
417,17,39,2,0,
417,17,44,2,0,
417,17,45,1,1,1
417,17,85,4,0,
417,17,86,1,33,
417,17,86,4,0,
417,17,87,4,0,
417,17,92,4,0,
417,17,98,1,5,
417,17,104,4,0,
417,17,111,2,0,
417,17,113,4,0,
417,17,117,1,1,2
417,17,129,1,21,
417,17,156,4,0,
417,17,158,1,49,
417,17,162,1,37,
417,17,164,4,0,
417,17,175,2,0,
417,17,182,4,0,
417,17,186,1,29,
417,17,203,1,17,
417,17,204,1,9,
417,17,205,2,0,
417,17,207,4,0,
417,17,209,1,13,
417,17,213,4,0,
417,17,214,4,0,
417,17,216,4,0,
417,17,218,4,0,
417,17,231,2,0,
417,17,237,4,0,
417,17,240,4,0,
417,17,260,2,0,
417,17,263,4,0,
417,17,266,2,0,
417,17,268,2,0,
417,17,313,2,0,
417,17,343,2,0,
417,17,369,4,0,
417,17,374,4,0,
417,17,387,1,45,
417,17,435,1,41,
417,17,447,4,0,
417,17,451,4,0,
417,17,486,1,25,
417,17,496,4,0,
417,17,497,4,0,
417,17,516,2,0,
417,17,521,4,0,
417,17,569,2,0,
417,17,590,4,0,
417,17,609,1,19,
417,18,39,2,0,
417,18,44,2,0,
417,18,45,1,1,1
417,18,85,4,0,
417,18,86,1,33,
417,18,86,4,0,
417,18,87,4,0,
417,18,92,4,0,
417,18,98,1,5,
417,18,104,4,0,
417,18,111,2,0,
417,18,113,4,0,
417,18,117,1,1,2
417,18,129,1,21,
417,18,156,4,0,
417,18,158,1,49,
417,18,162,1,37,
417,18,164,4,0,
417,18,175,2,0,
417,18,182,4,0,
417,18,186,1,29,
417,18,203,1,17,
417,18,204,1,9,
417,18,205,2,0,
417,18,207,4,0,
417,18,209,1,13,
417,18,213,4,0,
417,18,214,4,0,
417,18,216,4,0,
417,18,218,4,0,
417,18,231,2,0,
417,18,237,4,0,
417,18,240,4,0,
417,18,260,2,0,
417,18,263,4,0,
417,18,266,2,0,
417,18,268,2,0,
417,18,313,2,0,
417,18,343,2,0,
417,18,369,4,0,
417,18,374,4,0,
417,18,387,1,45,
417,18,435,1,41,
417,18,447,4,0,
417,18,451,4,0,
417,18,486,1,25,
417,18,496,4,0,
417,18,497,4,0,
417,18,516,2,0,
417,18,521,4,0,
417,18,569,2,0,
417,18,590,4,0,
417,18,608,2,0,
417,18,609,1,19,
418,8,3,2,0,
418,8,13,1,45,
418,8,29,2,0,
418,8,45,1,1,2
418,8,49,1,1,1
418,8,55,1,6,
418,8,57,4,0,
418,8,58,4,0,
418,8,59,4,0,
418,8,70,4,0,
418,8,91,4,0,
418,8,92,4,0,
418,8,97,1,28,
418,8,98,1,3,
418,8,104,4,0,
418,8,127,4,0,
418,8,129,1,15,
418,8,154,2,0,
418,8,156,4,0,
418,8,163,2,0,
418,8,164,4,0,
418,8,182,4,0,
418,8,189,2,0,
418,8,203,4,0,
418,8,207,4,0,
418,8,210,2,0,
418,8,213,4,0,
418,8,214,4,0,
418,8,216,4,0,
418,8,218,4,0,
418,8,226,2,0,
418,8,228,1,10,
418,8,231,4,0,
418,8,237,4,0,
418,8,240,4,0,
418,8,249,4,0,
418,8,250,1,36,
418,8,258,4,0,
418,8,263,4,0,
418,8,264,4,0,
418,8,280,4,0,
418,8,290,4,0,
418,8,316,2,0,
418,8,317,4,0,
418,8,339,4,0,
418,8,346,1,1,3
418,8,352,4,0,
418,8,362,4,0,
418,8,363,4,0,
418,8,445,4,0,
418,8,453,1,21,
418,9,3,2,0,
418,9,8,3,0,
418,9,13,1,45,
418,9,29,2,0,
418,9,45,1,1,2
418,9,49,1,1,1
418,9,55,1,6,
418,9,57,4,0,
418,9,58,4,0,
418,9,59,4,0,
418,9,70,4,0,
418,9,91,4,0,
418,9,92,4,0,
418,9,97,1,28,
418,9,98,1,3,
418,9,104,4,0,
418,9,127,4,0,
418,9,129,1,15,
418,9,129,3,0,
418,9,154,2,0,
418,9,156,4,0,
418,9,163,2,0,
418,9,164,4,0,
418,9,173,3,0,
418,9,182,4,0,
418,9,189,2,0,
418,9,189,3,0,
418,9,196,3,0,
418,9,203,4,0,
418,9,207,4,0,
418,9,210,2,0,
418,9,213,4,0,
418,9,214,4,0,
418,9,216,4,0,
418,9,218,4,0,
418,9,226,2,0,
418,9,228,1,10,
418,9,231,4,0,
418,9,237,4,0,
418,9,240,4,0,
418,9,249,4,0,
418,9,250,1,36,
418,9,258,4,0,
418,9,263,4,0,
418,9,264,4,0,
418,9,280,4,0,
418,9,290,4,0,
418,9,291,3,0,
418,9,316,2,0,
418,9,317,4,0,
418,9,339,4,0,
418,9,346,1,1,3
418,9,352,4,0,
418,9,362,4,0,
418,9,363,4,0,
418,9,445,4,0,
418,9,453,1,21,
418,10,3,2,0,
418,10,8,3,0,
418,10,13,1,45,
418,10,29,2,0,
418,10,29,3,0,
418,10,45,1,1,2
418,10,49,1,1,1
418,10,55,1,6,
418,10,57,4,0,
418,10,58,4,0,
418,10,59,4,0,
418,10,70,4,0,
418,10,91,4,0,
418,10,92,4,0,
418,10,97,1,28,
418,10,98,1,3,
418,10,104,4,0,
418,10,127,4,0,
418,10,129,1,15,
418,10,129,3,0,
418,10,154,2,0,
418,10,156,4,0,
418,10,163,2,0,
418,10,164,4,0,
418,10,173,3,0,
418,10,182,4,0,
418,10,189,2,0,
418,10,189,3,0,
418,10,196,3,0,
418,10,203,4,0,
418,10,207,4,0,
418,10,210,2,0,
418,10,213,4,0,
418,10,214,4,0,
418,10,216,4,0,
418,10,218,4,0,
418,10,226,2,0,
418,10,228,1,10,
418,10,231,4,0,
418,10,237,4,0,
418,10,240,4,0,
418,10,249,4,0,
418,10,250,1,36,
418,10,250,4,0,
418,10,258,4,0,
418,10,263,4,0,
418,10,264,4,0,
418,10,280,4,0,
418,10,290,4,0,
418,10,291,3,0,
418,10,316,2,0,
418,10,317,4,0,
418,10,339,4,0,
418,10,346,1,1,3
418,10,352,4,0,
418,10,362,4,0,
418,10,363,4,0,
418,10,445,4,0,
418,10,453,1,21,
418,11,3,2,0,
418,11,13,1,45,
418,11,29,2,0,
418,11,45,1,1,2
418,11,49,1,1,1
418,11,55,1,6,
418,11,57,4,0,
418,11,58,4,0,
418,11,59,4,0,
418,11,70,4,0,
418,11,91,4,0,
418,11,92,4,0,
418,11,97,1,28,
418,11,98,1,3,
418,11,104,4,0,
418,11,127,4,0,
418,11,129,1,15,
418,11,154,2,0,
418,11,156,4,0,
418,11,163,2,0,
418,11,164,4,0,
418,11,182,4,0,
418,11,189,2,0,
418,11,207,4,0,
418,11,210,2,0,
418,11,213,4,0,
418,11,216,4,0,
418,11,218,4,0,
418,11,226,2,0,
418,11,228,1,10,
418,11,237,4,0,
418,11,240,4,0,
418,11,249,4,0,
418,11,250,1,36,
418,11,258,4,0,
418,11,263,4,0,
418,11,280,4,0,
418,11,291,4,0,
418,11,316,2,0,
418,11,317,4,0,
418,11,339,4,0,
418,11,346,1,1,3
418,11,382,2,0,
418,11,392,2,0,
418,11,401,1,55,
418,11,401,2,0,
418,11,415,2,0,
418,11,453,1,21,
418,11,496,4,0,
418,11,497,4,0,
418,11,503,4,0,
418,11,541,2,0,
418,14,3,2,0,
418,14,8,3,0,
418,14,13,1,35,
418,14,29,2,0,
418,14,45,1,4,
418,14,49,1,1,
418,14,55,1,15,
418,14,56,1,45,
418,14,57,4,0,
418,14,58,4,0,
418,14,59,4,0,
418,14,70,4,0,
418,14,91,4,0,
418,14,92,4,0,
418,14,97,1,41,
418,14,98,1,11,
418,14,104,4,0,
418,14,127,4,0,
418,14,129,1,21,
418,14,154,2,0,
418,14,156,4,0,
418,14,163,2,0,
418,14,164,4,0,
418,14,173,3,0,
418,14,182,4,0,
418,14,189,2,0,
418,14,196,3,0,
418,14,207,4,0,
418,14,210,2,0,
418,14,213,4,0,
418,14,214,3,0,
418,14,216,4,0,
418,14,218,4,0,
418,14,226,2,0,
418,14,228,1,18,
418,14,231,3,0,
418,14,237,4,0,
418,14,240,4,0,
418,14,249,4,0,
418,14,250,1,31,
418,14,258,4,0,
418,14,263,4,0,
418,14,280,4,0,
418,14,291,4,0,
418,14,316,2,0,
418,14,317,4,0,
418,14,339,4,0,
418,14,346,1,7,
418,14,382,2,0,
418,14,392,2,0,
418,14,401,1,38,
418,14,401,2,0,
418,14,401,3,0,
418,14,415,2,0,
418,14,453,1,24,
418,14,458,1,27,
418,14,496,4,0,
418,14,497,4,0,
418,14,503,4,0,
418,14,541,2,0,
418,15,3,2,0,
418,15,13,1,35,
418,15,29,2,0,
418,15,45,1,4,
418,15,49,1,1,
418,15,55,1,15,
418,15,56,1,45,
418,15,57,4,0,
418,15,58,4,0,
418,15,59,4,0,
418,15,70,4,0,
418,15,91,4,0,
418,15,92,4,0,
418,15,97,1,41,
418,15,98,1,11,
418,15,104,4,0,
418,15,127,4,0,
418,15,129,1,21,
418,15,154,2,0,
418,15,156,4,0,
418,15,163,2,0,
418,15,164,4,0,
418,15,182,4,0,
418,15,189,2,0,
418,15,207,4,0,
418,15,210,2,0,
418,15,213,4,0,
418,15,214,4,0,
418,15,216,4,0,
418,15,218,4,0,
418,15,226,2,0,
418,15,228,1,18,
418,15,237,4,0,
418,15,240,4,0,
418,15,249,4,0,
418,15,250,1,31,
418,15,258,4,0,
418,15,263,4,0,
418,15,280,4,0,
418,15,316,2,0,
418,15,317,4,0,
418,15,339,4,0,
418,15,346,1,7,
418,15,382,2,0,
418,15,392,2,0,
418,15,401,1,38,
418,15,401,2,0,
418,15,415,2,0,
418,15,453,1,24,
418,15,458,1,27,
418,15,487,2,0,
418,15,496,4,0,
418,15,497,4,0,
418,15,503,4,0,
418,15,541,2,0,
418,15,590,4,0,
418,15,612,4,0,
418,16,3,2,0,
418,16,8,3,0,
418,16,13,1,35,1
418,16,29,2,0,
418,16,45,1,4,1
418,16,49,1,1,1
418,16,55,1,15,1
418,16,56,1,45,1
418,16,57,4,0,
418,16,58,4,0,
418,16,59,4,0,
418,16,70,4,0,
418,16,91,4,0,
418,16,92,4,0,
418,16,97,1,41,1
418,16,98,1,11,1
418,16,104,4,0,
418,16,127,4,0,
418,16,129,1,21,1
418,16,154,2,0,
418,16,156,4,0,
418,16,163,2,0,
418,16,164,4,0,
418,16,173,3,0,
418,16,182,4,0,
418,16,189,2,0,
418,16,196,3,0,
418,16,207,4,0,
418,16,210,2,0,
418,16,213,4,0,
418,16,214,4,0,
418,16,216,4,0,
418,16,218,4,0,
418,16,226,2,0,
418,16,228,1,18,1
418,16,231,3,0,
418,16,237,4,0,
418,16,240,4,0,
418,16,249,4,0,
418,16,250,1,31,1
418,16,258,4,0,
418,16,263,4,0,
418,16,264,3,0,
418,16,280,4,0,
418,16,290,4,0,
418,16,291,4,0,
418,16,316,2,0,
418,16,317,4,0,
418,16,339,4,0,
418,16,346,1,7,1
418,16,352,3,0,
418,16,382,2,0,
418,16,392,2,0,
418,16,401,1,38,1
418,16,401,2,0,
418,16,401,3,0,
418,16,415,2,0,
418,16,453,1,24,1
418,16,458,1,27,1
418,16,487,2,0,
418,16,496,4,0,
418,16,497,4,0,
418,16,503,4,0,
418,16,541,2,0,
418,16,590,4,0,
418,16,612,4,0,
418,17,3,2,0,
418,17,13,1,35,
418,17,29,2,0,
418,17,45,1,4,
418,17,49,1,1,
418,17,55,1,15,
418,17,56,1,45,
418,17,57,4,0,
418,17,58,4,0,
418,17,59,4,0,
418,17,92,4,0,
418,17,97,1,41,
418,17,98,1,11,
418,17,104,4,0,
418,17,127,4,0,
418,17,129,1,21,
418,17,154,2,0,
418,17,156,4,0,
418,17,163,2,0,
418,17,164,4,0,
418,17,182,4,0,
418,17,189,2,0,
418,17,207,4,0,
418,17,210,2,0,
418,17,213,4,0,
418,17,214,4,0,
418,17,216,4,0,
418,17,218,4,0,
418,17,226,2,0,
418,17,228,1,18,
418,17,237,4,0,
418,17,240,4,0,
418,17,250,1,31,
418,17,258,4,0,
418,17,263,4,0,
418,17,280,4,0,
418,17,316,2,0,
418,17,317,4,0,
418,17,339,4,0,
418,17,346,1,7,
418,17,382,2,0,
418,17,392,2,0,
418,17,401,1,38,
418,17,401,2,0,
418,17,415,2,0,
418,17,453,1,24,
418,17,458,1,27,
418,17,487,2,0,
418,17,496,4,0,
418,17,497,4,0,
418,17,503,4,0,
418,17,541,2,0,
418,17,590,4,0,
418,18,3,2,0,
418,18,13,1,35,
418,18,29,2,0,
418,18,45,1,4,
418,18,49,1,1,
418,18,55,1,15,
418,18,56,1,45,
418,18,57,4,0,
418,18,58,4,0,
418,18,59,4,0,
418,18,92,4,0,
418,18,97,1,41,
418,18,98,1,11,
418,18,104,4,0,
418,18,127,4,0,
418,18,129,1,21,
418,18,154,2,0,
418,18,156,4,0,
418,18,163,2,0,
418,18,164,4,0,
418,18,182,4,0,
418,18,189,2,0,
418,18,207,4,0,
418,18,210,2,0,
418,18,213,4,0,
418,18,214,4,0,
418,18,216,4,0,
418,18,218,4,0,
418,18,226,2,0,
418,18,228,1,18,
418,18,237,4,0,
418,18,240,4,0,
418,18,250,1,31,
418,18,258,4,0,
418,18,263,4,0,
418,18,270,2,0,
418,18,280,4,0,
418,18,316,2,0,
418,18,317,4,0,
418,18,339,4,0,
418,18,346,1,7,
418,18,382,2,0,
418,18,392,2,0,
418,18,401,1,38,
418,18,401,2,0,
418,18,415,2,0,
418,18,453,1,24,
418,18,458,1,27,
418,18,487,2,0,
418,18,496,4,0,
418,18,497,4,0,
418,18,503,4,0,
418,18,541,2,0,
418,18,590,4,0,
419,8,13,1,50,
419,8,45,1,1,3
419,8,46,4,0,
419,8,49,1,1,2
419,8,55,1,6,
419,8,57,4,0,
419,8,58,4,0,
419,8,59,4,0,
419,8,63,4,0,
419,8,70,4,0,
419,8,91,4,0,
419,8,92,4,0,
419,8,97,1,29,
419,8,98,1,1,5
419,8,98,1,3,
419,8,104,4,0,
419,8,127,4,0,
419,8,129,1,15,
419,8,156,4,0,
419,8,164,4,0,
419,8,182,4,0,
419,8,203,4,0,
419,8,207,4,0,
419,8,213,4,0,
419,8,214,4,0,
419,8,216,4,0,
419,8,218,4,0,
419,8,228,1,10,
419,8,231,4,0,
419,8,237,4,0,
419,8,240,4,0,
419,8,242,1,26,
419,8,249,4,0,
419,8,250,1,39,
419,8,258,4,0,
419,8,259,4,0,
419,8,263,4,0,
419,8,264,4,0,
419,8,269,4,0,
419,8,280,4,0,
419,8,290,4,0,
419,8,317,4,0,
419,8,339,4,0,
419,8,346,1,1,4
419,8,352,4,0,
419,8,362,4,0,
419,8,363,4,0,
419,8,371,4,0,
419,8,411,4,0,
419,8,416,4,0,
419,8,423,1,1,1
419,8,445,4,0,
419,8,453,1,21,
419,9,8,3,0,
419,9,13,1,50,
419,9,45,1,1,3
419,9,46,4,0,
419,9,49,1,1,2
419,9,55,1,6,
419,9,57,4,0,
419,9,58,4,0,
419,9,59,4,0,
419,9,63,4,0,
419,9,70,4,0,
419,9,91,4,0,
419,9,92,4,0,
419,9,97,1,29,
419,9,98,1,1,5
419,9,98,1,3,
419,9,104,4,0,
419,9,127,4,0,
419,9,129,1,15,
419,9,129,3,0,
419,9,156,4,0,
419,9,164,4,0,
419,9,173,3,0,
419,9,182,4,0,
419,9,189,3,0,
419,9,196,3,0,
419,9,203,4,0,
419,9,207,4,0,
419,9,213,4,0,
419,9,214,4,0,
419,9,216,4,0,
419,9,218,4,0,
419,9,228,1,10,
419,9,231,4,0,
419,9,237,4,0,
419,9,240,4,0,
419,9,242,1,26,
419,9,249,4,0,
419,9,250,1,39,
419,9,258,4,0,
419,9,259,4,0,
419,9,263,4,0,
419,9,264,4,0,
419,9,269,4,0,
419,9,280,4,0,
419,9,290,4,0,
419,9,291,3,0,
419,9,317,4,0,
419,9,339,4,0,
419,9,346,1,1,4
419,9,352,4,0,
419,9,362,4,0,
419,9,363,4,0,
419,9,371,4,0,
419,9,401,3,0,
419,9,411,4,0,
419,9,416,4,0,
419,9,423,1,1,1
419,9,445,4,0,
419,9,453,1,21,
419,10,8,3,0,
419,10,13,1,50,
419,10,29,3,0,
419,10,45,1,1,3
419,10,46,4,0,
419,10,49,1,1,2
419,10,55,1,6,
419,10,57,4,0,
419,10,58,4,0,
419,10,59,4,0,
419,10,63,4,0,
419,10,67,3,0,
419,10,70,4,0,
419,10,91,4,0,
419,10,92,4,0,
419,10,97,1,29,
419,10,98,1,1,5
419,10,98,1,3,
419,10,104,4,0,
419,10,127,4,0,
419,10,129,1,15,
419,10,129,3,0,
419,10,156,4,0,
419,10,164,4,0,
419,10,173,3,0,
419,10,182,4,0,
419,10,189,3,0,
419,10,196,3,0,
419,10,203,4,0,
419,10,207,4,0,
419,10,213,4,0,
419,10,214,4,0,
419,10,216,4,0,
419,10,218,4,0,
419,10,228,1,10,
419,10,231,4,0,
419,10,237,4,0,
419,10,240,4,0,
419,10,242,1,26,
419,10,249,4,0,
419,10,250,1,39,
419,10,250,4,0,
419,10,258,4,0,
419,10,259,4,0,
419,10,263,4,0,
419,10,264,4,0,
419,10,269,4,0,
419,10,280,4,0,
419,10,290,4,0,
419,10,291,3,0,
419,10,317,4,0,
419,10,339,4,0,
419,10,346,1,1,4
419,10,352,4,0,
419,10,362,4,0,
419,10,363,4,0,
419,10,371,4,0,
419,10,401,3,0,
419,10,411,4,0,
419,10,416,4,0,
419,10,423,1,1,1
419,10,445,4,0,
419,10,453,1,21,
419,11,13,1,50,
419,11,45,1,1,3
419,11,46,4,0,
419,11,49,1,1,2
419,11,55,1,6,
419,11,57,4,0,
419,11,58,4,0,
419,11,59,4,0,
419,11,63,4,0,
419,11,70,4,0,
419,11,91,4,0,
419,11,92,4,0,
419,11,97,1,29,
419,11,98,1,1,5
419,11,98,1,3,
419,11,104,4,0,
419,11,127,4,0,
419,11,129,1,15,
419,11,156,4,0,
419,11,164,4,0,
419,11,182,4,0,
419,11,207,4,0,
419,11,213,4,0,
419,11,216,4,0,
419,11,218,4,0,
419,11,228,1,10,
419,11,237,4,0,
419,11,240,4,0,
419,11,242,1,26,
419,11,249,4,0,
419,11,250,1,39,
419,11,258,4,0,
419,11,259,4,0,
419,11,263,4,0,
419,11,269,4,0,
419,11,280,4,0,
419,11,291,4,0,
419,11,317,4,0,
419,11,339,4,0,
419,11,346,1,1,4
419,11,371,4,0,
419,11,401,1,62,
419,11,411,4,0,
419,11,416,4,0,
419,11,423,1,1,1
419,11,453,1,21,
419,11,496,4,0,
419,11,497,4,0,
419,11,503,4,0,
419,14,8,3,0,
419,14,13,1,41,
419,14,45,1,1,4
419,14,45,1,4,
419,14,46,4,0,
419,14,49,1,1,3
419,14,55,1,15,
419,14,56,1,57,
419,14,57,4,0,
419,14,58,4,0,
419,14,59,4,0,
419,14,63,4,0,
419,14,67,3,0,
419,14,70,4,0,
419,14,91,4,0,
419,14,92,4,0,
419,14,97,1,51,
419,14,98,1,1,6
419,14,98,1,11,
419,14,104,4,0,
419,14,127,4,0,
419,14,129,1,21,
419,14,156,4,0,
419,14,164,4,0,
419,14,173,3,0,
419,14,182,4,0,
419,14,196,3,0,
419,14,207,4,0,
419,14,213,4,0,
419,14,214,3,0,
419,14,216,4,0,
419,14,218,4,0,
419,14,228,1,18,
419,14,231,3,0,
419,14,237,4,0,
419,14,240,4,0,
419,14,242,1,1,2
419,14,249,4,0,
419,14,250,1,35,
419,14,258,4,0,
419,14,259,4,0,
419,14,263,4,0,
419,14,269,4,0,
419,14,280,4,0,
419,14,291,4,0,
419,14,317,4,0,
419,14,339,4,0,
419,14,346,1,1,5
419,14,346,1,7,
419,14,371,4,0,
419,14,401,1,46,
419,14,401,3,0,
419,14,411,4,0,
419,14,416,4,0,
419,14,423,1,1,1
419,14,453,1,24,
419,14,458,1,29,
419,14,496,4,0,
419,14,497,4,0,
419,14,503,4,0,
419,15,13,1,41,
419,15,45,1,1,4
419,15,45,1,4,
419,15,46,4,0,
419,15,49,1,1,3
419,15,55,1,15,
419,15,56,1,57,
419,15,57,4,0,
419,15,58,4,0,
419,15,59,4,0,
419,15,63,4,0,
419,15,70,4,0,
419,15,91,4,0,
419,15,92,4,0,
419,15,97,1,51,
419,15,98,1,1,6
419,15,98,1,11,
419,15,104,4,0,
419,15,127,4,0,
419,15,129,1,21,
419,15,156,4,0,
419,15,164,4,0,
419,15,182,4,0,
419,15,207,4,0,
419,15,213,4,0,
419,15,214,4,0,
419,15,216,4,0,
419,15,218,4,0,
419,15,228,1,18,
419,15,237,4,0,
419,15,240,4,0,
419,15,242,1,1,2
419,15,249,4,0,
419,15,250,1,35,
419,15,258,4,0,
419,15,259,4,0,
419,15,263,4,0,
419,15,269,4,0,
419,15,280,4,0,
419,15,317,4,0,
419,15,339,4,0,
419,15,346,1,1,5
419,15,346,1,7,
419,15,371,4,0,
419,15,401,1,46,
419,15,411,4,0,
419,15,416,4,0,
419,15,423,1,1,1
419,15,453,1,24,
419,15,458,1,29,
419,15,496,4,0,
419,15,497,4,0,
419,15,503,4,0,
419,15,590,4,0,
419,15,612,4,0,
419,16,8,3,0,
419,16,13,1,41,1
419,16,45,1,1,4
419,16,45,1,4,1
419,16,46,4,0,
419,16,49,1,1,3
419,16,55,1,15,1
419,16,56,1,57,1
419,16,57,4,0,
419,16,58,4,0,
419,16,59,4,0,
419,16,63,4,0,
419,16,67,3,0,
419,16,70,4,0,
419,16,91,4,0,
419,16,92,4,0,
419,16,97,1,51,1
419,16,98,1,1,6
419,16,98,1,11,1
419,16,104,4,0,
419,16,127,4,0,
419,16,129,1,21,1
419,16,156,4,0,
419,16,164,4,0,
419,16,173,3,0,
419,16,182,4,0,
419,16,196,3,0,
419,16,207,4,0,
419,16,213,4,0,
419,16,214,4,0,
419,16,216,4,0,
419,16,218,4,0,
419,16,228,1,18,1
419,16,231,3,0,
419,16,237,4,0,
419,16,240,4,0,
419,16,242,1,1,2
419,16,249,4,0,
419,16,250,1,35,1
419,16,258,4,0,
419,16,259,4,0,
419,16,263,4,0,
419,16,264,3,0,
419,16,269,4,0,
419,16,280,4,0,
419,16,290,4,0,
419,16,291,4,0,
419,16,317,4,0,
419,16,339,4,0,
419,16,346,1,1,5
419,16,346,1,7,1
419,16,352,3,0,
419,16,371,4,0,
419,16,401,1,46,1
419,16,401,3,0,
419,16,411,4,0,
419,16,416,4,0,
419,16,423,1,1,1
419,16,453,1,24,1
419,16,458,1,29,1
419,16,496,4,0,
419,16,497,4,0,
419,16,503,4,0,
419,16,590,4,0,
419,16,612,4,0,
419,17,13,1,41,
419,17,45,1,1,4
419,17,45,1,4,
419,17,46,4,0,
419,17,49,1,1,3
419,17,55,1,15,
419,17,56,1,57,
419,17,57,4,0,
419,17,58,4,0,
419,17,59,4,0,
419,17,63,4,0,
419,17,92,4,0,
419,17,97,1,51,
419,17,98,1,1,6
419,17,98,1,11,
419,17,104,4,0,
419,17,127,4,0,
419,17,129,1,21,
419,17,156,4,0,
419,17,164,4,0,
419,17,182,4,0,
419,17,207,4,0,
419,17,213,4,0,
419,17,214,4,0,
419,17,216,4,0,
419,17,218,4,0,
419,17,228,1,18,
419,17,237,4,0,
419,17,240,4,0,
419,17,242,1,1,2
419,17,250,1,35,
419,17,258,4,0,
419,17,259,4,0,
419,17,263,4,0,
419,17,269,4,0,
419,17,280,4,0,
419,17,317,4,0,
419,17,339,4,0,
419,17,346,1,1,5
419,17,346,1,7,
419,17,371,4,0,
419,17,401,1,46,
419,17,411,4,0,
419,17,416,4,0,
419,17,423,1,1,1
419,17,453,1,24,
419,17,458,1,29,
419,17,496,4,0,
419,17,497,4,0,
419,17,503,4,0,
419,17,590,4,0,
419,18,13,1,41,
419,18,45,1,1,4
419,18,45,1,4,
419,18,46,4,0,
419,18,49,1,1,3
419,18,55,1,15,
419,18,56,1,57,
419,18,57,4,0,
419,18,58,4,0,
419,18,59,4,0,
419,18,63,4,0,
419,18,92,4,0,
419,18,97,1,51,
419,18,98,1,1,6
419,18,98,1,11,
419,18,104,4,0,
419,18,127,4,0,
419,18,129,1,21,
419,18,156,4,0,
419,18,164,4,0,
419,18,182,4,0,
419,18,207,4,0,
419,18,213,4,0,
419,18,214,4,0,
419,18,216,4,0,
419,18,218,4,0,
419,18,228,1,18,
419,18,237,4,0,
419,18,240,4,0,
419,18,242,1,1,2
419,18,250,1,35,
419,18,258,4,0,
419,18,259,4,0,
419,18,263,4,0,
419,18,269,4,0,
419,18,280,4,0,
419,18,317,4,0,
419,18,339,4,0,
419,18,346,1,1,5
419,18,346,1,7,
419,18,371,4,0,
419,18,401,1,46,
419,18,411,4,0,
419,18,416,4,0,
419,18,423,1,1,1
419,18,453,1,24,
419,18,458,1,29,
419,18,496,4,0,
419,18,497,4,0,
419,18,503,4,0,
419,18,590,4,0,
420,8,14,4,0,
420,8,33,1,1,
420,8,36,1,31,
420,8,73,1,10,
420,8,74,1,7,
420,8,75,2,0,
420,8,76,1,37,
420,8,76,4,0,
420,8,92,4,0,
420,8,104,4,0,
420,8,148,4,0,
420,8,156,4,0,
420,8,164,4,0,
420,8,182,4,0,
420,8,202,4,0,
420,8,203,4,0,
420,8,207,4,0,
420,8,213,4,0,
420,8,214,4,0,
420,8,216,4,0,
420,8,218,4,0,
420,8,219,4,0,
420,8,230,2,0,
420,8,237,4,0,
420,8,241,1,22,
420,8,241,4,0,
420,8,263,4,0,
420,8,267,2,0,
420,8,270,1,13,
420,8,290,4,0,
420,8,312,2,0,
420,8,320,2,0,
420,8,321,2,0,
420,8,331,4,0,
420,8,345,1,19,
420,8,363,4,0,
420,8,381,1,40,
420,8,388,1,28,
420,8,412,4,0,
420,8,445,4,0,
420,8,447,4,0,
420,9,14,4,0,
420,9,33,1,1,
420,9,36,1,31,
420,9,73,1,10,
420,9,74,1,7,
420,9,75,2,0,
420,9,76,1,37,
420,9,76,4,0,
420,9,92,4,0,
420,9,104,4,0,
420,9,148,4,0,
420,9,156,4,0,
420,9,164,4,0,
420,9,173,3,0,
420,9,182,4,0,
420,9,202,4,0,
420,9,203,4,0,
420,9,205,3,0,
420,9,207,4,0,
420,9,213,4,0,
420,9,214,4,0,
420,9,216,4,0,
420,9,218,4,0,
420,9,219,4,0,
420,9,230,2,0,
420,9,235,3,0,
420,9,237,4,0,
420,9,241,1,22,
420,9,241,4,0,
420,9,263,4,0,
420,9,267,2,0,
420,9,270,1,13,
420,9,270,3,0,
420,9,290,4,0,
420,9,312,2,0,
420,9,320,2,0,
420,9,321,2,0,
420,9,331,4,0,
420,9,345,1,19,
420,9,363,4,0,
420,9,381,1,40,
420,9,388,1,28,
420,9,402,3,0,
420,9,412,4,0,
420,9,445,4,0,
420,9,447,4,0,
420,10,14,4,0,
420,10,33,1,1,
420,10,36,1,31,
420,10,73,1,10,
420,10,74,1,7,
420,10,75,2,0,
420,10,76,1,37,
420,10,76,4,0,
420,10,92,4,0,
420,10,104,4,0,
420,10,148,4,0,
420,10,156,4,0,
420,10,164,4,0,
420,10,173,3,0,
420,10,182,4,0,
420,10,202,4,0,
420,10,203,4,0,
420,10,205,3,0,
420,10,207,4,0,
420,10,213,4,0,
420,10,214,4,0,
420,10,216,4,0,
420,10,218,4,0,
420,10,219,4,0,
420,10,230,2,0,
420,10,235,3,0,
420,10,237,4,0,
420,10,241,1,22,
420,10,241,4,0,
420,10,263,4,0,
420,10,267,2,0,
420,10,270,1,13,
420,10,270,3,0,
420,10,290,4,0,
420,10,311,2,0,
420,10,312,2,0,
420,10,320,2,0,
420,10,321,2,0,
420,10,331,4,0,
420,10,345,1,19,
420,10,363,4,0,
420,10,381,1,40,
420,10,388,1,28,
420,10,388,3,0,
420,10,402,3,0,
420,10,412,4,0,
420,10,445,4,0,
420,10,447,4,0,
420,11,14,4,0,
420,11,33,1,1,2
420,11,36,1,31,
420,11,73,1,10,
420,11,74,1,7,
420,11,75,2,0,
420,11,76,1,37,
420,11,76,4,0,
420,11,92,4,0,
420,11,104,4,0,
420,11,111,2,0,
420,11,148,4,0,
420,11,156,4,0,
420,11,164,4,0,
420,11,182,4,0,
420,11,205,2,0,
420,11,207,4,0,
420,11,213,4,0,
420,11,216,4,0,
420,11,218,4,0,
420,11,219,4,0,
420,11,230,2,0,
420,11,234,1,1,1
420,11,237,4,0,
420,11,241,1,22,
420,11,241,4,0,
420,11,263,4,0,
420,11,267,2,0,
420,11,270,1,13,
420,11,311,2,0,
420,11,312,2,0,
420,11,320,2,0,
420,11,321,2,0,
420,11,345,1,19,
420,11,361,2,0,
420,11,363,2,0,
420,11,381,1,40,
420,11,388,1,28,
420,11,402,2,0,
420,11,412,4,0,
420,11,447,4,0,
420,11,496,4,0,
420,11,505,2,0,
420,14,14,4,0,
420,14,33,1,1,2
420,14,36,1,31,
420,14,73,1,10,
420,14,74,1,7,
420,14,75,2,0,
420,14,76,1,37,
420,14,76,4,0,
420,14,92,4,0,
420,14,104,4,0,
420,14,111,2,0,
420,14,148,4,0,
420,14,156,4,0,
420,14,164,4,0,
420,14,173,3,0,
420,14,182,4,0,
420,14,202,3,0,
420,14,205,2,0,
420,14,207,4,0,
420,14,213,4,0,
420,14,214,3,0,
420,14,216,4,0,
420,14,218,4,0,
420,14,219,4,0,
420,14,230,2,0,
420,14,234,1,1,1
420,14,235,3,0,
420,14,237,4,0,
420,14,241,1,22,
420,14,241,4,0,
420,14,263,4,0,
420,14,267,2,0,
420,14,270,1,13,
420,14,270,3,0,
420,14,311,2,0,
420,14,312,2,0,
420,14,320,2,0,
420,14,321,2,0,
420,14,345,1,19,
420,14,361,2,0,
420,14,363,2,0,
420,14,381,1,40,
420,14,388,1,28,
420,14,388,3,0,
420,14,402,2,0,
420,14,402,3,0,
420,14,412,4,0,
420,14,447,4,0,
420,14,496,4,0,
420,14,505,2,0,
420,15,14,4,0,
420,15,33,1,1,2
420,15,36,1,31,
420,15,73,1,10,
420,15,74,1,7,
420,15,75,2,0,
420,15,76,1,37,
420,15,76,4,0,
420,15,92,4,0,
420,15,104,4,0,
420,15,111,2,0,
420,15,148,4,0,
420,15,156,4,0,
420,15,164,4,0,
420,15,182,4,0,
420,15,205,2,0,
420,15,207,4,0,
420,15,213,4,0,
420,15,214,4,0,
420,15,216,4,0,
420,15,218,4,0,
420,15,219,4,0,
420,15,230,2,0,
420,15,234,1,1,1
420,15,237,4,0,
420,15,241,1,22,
420,15,241,4,0,
420,15,263,4,0,
420,15,267,2,0,
420,15,267,4,0,
420,15,270,1,13,
420,15,311,2,0,
420,15,312,2,0,
420,15,320,2,0,
420,15,321,2,0,
420,15,345,1,19,
420,15,361,2,0,
420,15,363,2,0,
420,15,381,1,40,
420,15,388,1,28,
420,15,402,2,0,
420,15,412,4,0,
420,15,447,4,0,
420,15,496,4,0,
420,15,505,2,0,
420,15,572,1,47,
420,15,579,2,0,
420,15,590,4,0,
420,15,605,4,0,
420,16,14,4,0,
420,16,33,1,1,2
420,16,36,1,31,1
420,16,73,1,10,1
420,16,74,1,7,1
420,16,75,2,0,
420,16,76,1,37,1
420,16,76,4,0,
420,16,92,4,0,
420,16,104,4,0,
420,16,111,2,0,
420,16,148,4,0,
420,16,156,4,0,
420,16,164,4,0,
420,16,173,3,0,
420,16,182,4,0,
420,16,202,3,0,
420,16,205,2,0,
420,16,207,4,0,
420,16,213,4,0,
420,16,214,4,0,
420,16,216,4,0,
420,16,218,4,0,
420,16,219,4,0,
420,16,230,2,0,
420,16,234,1,1,1
420,16,235,3,0,
420,16,237,4,0,
420,16,241,1,22,1
420,16,241,4,0,
420,16,263,4,0,
420,16,267,2,0,
420,16,267,4,0,
420,16,270,1,13,1
420,16,270,3,0,
420,16,290,4,0,
420,16,311,2,0,
420,16,312,2,0,
420,16,320,2,0,
420,16,321,2,0,
420,16,345,1,19,1
420,16,361,2,0,
420,16,363,2,0,
420,16,381,1,40,1
420,16,388,1,28,1
420,16,388,3,0,
420,16,402,2,0,
420,16,402,3,0,
420,16,412,4,0,
420,16,447,4,0,
420,16,496,4,0,
420,16,505,2,0,
420,16,572,1,47,1
420,16,579,2,0,
420,16,590,4,0,
420,16,605,4,0,
420,17,14,4,0,
420,17,33,1,1,2
420,17,36,1,31,
420,17,73,1,10,
420,17,74,1,7,
420,17,75,2,0,
420,17,76,1,37,
420,17,76,4,0,
420,17,92,4,0,
420,17,104,4,0,
420,17,111,2,0,
420,17,156,4,0,
420,17,164,4,0,
420,17,182,4,0,
420,17,205,2,0,
420,17,207,4,0,
420,17,213,4,0,
420,17,214,4,0,
420,17,216,4,0,
420,17,218,4,0,
420,17,219,4,0,
420,17,230,2,0,
420,17,234,1,1,1
420,17,237,4,0,
420,17,241,1,22,
420,17,241,4,0,
420,17,263,4,0,
420,17,267,2,0,
420,17,267,4,0,
420,17,270,1,13,
420,17,311,2,0,
420,17,312,2,0,
420,17,320,2,0,
420,17,321,2,0,
420,17,345,1,19,
420,17,361,2,0,
420,17,363,2,0,
420,17,381,1,40,
420,17,388,1,28,
420,17,402,2,0,
420,17,412,4,0,
420,17,447,4,0,
420,17,496,4,0,
420,17,505,2,0,
420,17,572,1,47,
420,17,579,2,0,
420,17,590,4,0,
420,17,605,4,0,
420,18,14,4,0,
420,18,33,1,1,2
420,18,36,1,31,
420,18,73,1,10,
420,18,74,1,7,
420,18,75,2,0,
420,18,76,1,37,
420,18,76,4,0,
420,18,92,4,0,
420,18,104,4,0,
420,18,111,2,0,
420,18,156,4,0,
420,18,164,4,0,
420,18,182,4,0,
420,18,205,2,0,
420,18,207,4,0,
420,18,213,4,0,
420,18,214,4,0,
420,18,216,4,0,
420,18,218,4,0,
420,18,219,4,0,
420,18,230,2,0,
420,18,234,1,1,1
420,18,237,4,0,
420,18,241,1,22,
420,18,241,4,0,
420,18,263,4,0,
420,18,267,2,0,
420,18,267,4,0,
420,18,270,1,13,
420,18,311,2,0,
420,18,312,2,0,
420,18,320,2,0,
420,18,321,2,0,
420,18,345,1,19,
420,18,361,2,0,
420,18,363,2,0,
420,18,381,1,40,
420,18,388,1,28,
420,18,402,2,0,
420,18,412,4,0,
420,18,447,4,0,
420,18,496,4,0,
420,18,505,2,0,
420,18,572,1,47,
420,18,579,2,0,
420,18,580,2,0,
420,18,590,4,0,
420,18,605,4,0,
421,8,14,4,0,
421,8,33,1,1,1
421,8,36,1,35,
421,8,63,4,0,
421,8,73,1,10,
421,8,74,1,1,2
421,8,74,1,7,
421,8,76,1,43,
421,8,76,4,0,
421,8,80,1,25,
421,8,92,4,0,
421,8,104,4,0,
421,8,148,4,0,
421,8,156,4,0,
421,8,164,4,0,
421,8,182,4,0,
421,8,202,4,0,
421,8,203,4,0,
421,8,207,4,0,
421,8,213,4,0,
421,8,214,4,0,
421,8,216,4,0,
421,8,218,4,0,
421,8,219,4,0,
421,8,237,4,0,
421,8,241,1,22,
421,8,241,4,0,
421,8,263,4,0,
421,8,270,1,13,
421,8,290,4,0,
421,8,331,4,0,
421,8,345,1,19,
421,8,363,4,0,
421,8,381,1,48,
421,8,388,1,30,
421,8,412,4,0,
421,8,416,4,0,
421,8,445,4,0,
421,8,447,4,0,
421,9,14,4,0,
421,9,33,1,1,1
421,9,36,1,35,
421,9,63,4,0,
421,9,73,1,10,
421,9,74,1,1,2
421,9,74,1,7,
421,9,76,1,43,
421,9,76,4,0,
421,9,80,1,25,
421,9,92,4,0,
421,9,104,4,0,
421,9,148,4,0,
421,9,156,4,0,
421,9,164,4,0,
421,9,173,3,0,
421,9,182,4,0,
421,9,202,4,0,
421,9,203,4,0,
421,9,205,3,0,
421,9,207,4,0,
421,9,213,4,0,
421,9,214,4,0,
421,9,216,4,0,
421,9,218,4,0,
421,9,219,4,0,
421,9,235,3,0,
421,9,237,4,0,
421,9,241,1,22,
421,9,241,4,0,
421,9,263,4,0,
421,9,270,1,13,
421,9,270,3,0,
421,9,290,4,0,
421,9,331,4,0,
421,9,345,1,19,
421,9,363,4,0,
421,9,381,1,48,
421,9,388,1,30,
421,9,402,3,0,
421,9,412,4,0,
421,9,416,4,0,
421,9,445,4,0,
421,9,447,4,0,
421,10,14,4,0,
421,10,33,1,1,1
421,10,36,1,35,
421,10,63,4,0,
421,10,73,1,10,
421,10,74,1,1,2
421,10,74,1,7,
421,10,76,1,43,
421,10,76,4,0,
421,10,80,1,25,
421,10,92,4,0,
421,10,104,4,0,
421,10,148,4,0,
421,10,156,4,0,
421,10,164,4,0,
421,10,173,3,0,
421,10,182,4,0,
421,10,202,4,0,
421,10,203,4,0,
421,10,205,3,0,
421,10,207,4,0,
421,10,213,4,0,
421,10,214,4,0,
421,10,216,4,0,
421,10,218,4,0,
421,10,219,4,0,
421,10,235,3,0,
421,10,237,4,0,
421,10,241,1,22,
421,10,241,4,0,
421,10,263,4,0,
421,10,270,1,13,
421,10,270,3,0,
421,10,290,4,0,
421,10,331,4,0,
421,10,345,1,19,
421,10,363,4,0,
421,10,381,1,48,
421,10,388,1,30,
421,10,388,3,0,
421,10,402,3,0,
421,10,412,4,0,
421,10,416,4,0,
421,10,445,4,0,
421,10,447,4,0,
421,11,14,4,0,
421,11,33,1,1,2
421,11,36,1,35,
421,11,63,4,0,
421,11,73,1,10,
421,11,74,1,1,3
421,11,74,1,7,
421,11,76,1,43,
421,11,76,4,0,
421,11,80,1,25,
421,11,92,4,0,
421,11,104,4,0,
421,11,148,4,0,
421,11,156,4,0,
421,11,164,4,0,
421,11,182,4,0,
421,11,207,4,0,
421,11,213,4,0,
421,11,216,4,0,
421,11,218,4,0,
421,11,219,4,0,
421,11,234,1,1,1
421,11,237,4,0,
421,11,241,1,22,
421,11,241,4,0,
421,11,263,4,0,
421,11,270,1,13,
421,11,345,1,19,
421,11,381,1,48,
421,11,388,1,30,
421,11,412,4,0,
421,11,416,4,0,
421,11,447,4,0,
421,11,496,4,0,
421,14,14,4,0,
421,14,33,1,1,2
421,14,36,1,35,
421,14,63,4,0,
421,14,73,1,10,
421,14,74,1,1,3
421,14,74,1,7,
421,14,76,1,43,
421,14,76,4,0,
421,14,80,1,25,
421,14,92,4,0,
421,14,104,4,0,
421,14,148,4,0,
421,14,156,4,0,
421,14,164,4,0,
421,14,173,3,0,
421,14,182,4,0,
421,14,202,3,0,
421,14,207,4,0,
421,14,213,4,0,
421,14,214,3,0,
421,14,216,4,0,
421,14,218,4,0,
421,14,219,4,0,
421,14,234,1,1,1
421,14,235,3,0,
421,14,237,4,0,
421,14,241,1,22,
421,14,241,4,0,
421,14,263,4,0,
421,14,270,1,13,
421,14,270,3,0,
421,14,345,1,19,
421,14,381,1,48,
421,14,388,1,30,
421,14,388,3,0,
421,14,402,3,0,
421,14,412,4,0,
421,14,416,4,0,
421,14,447,4,0,
421,14,496,4,0,
421,15,14,4,0,
421,15,33,1,1,2
421,15,36,1,35,
421,15,63,4,0,
421,15,73,1,10,
421,15,74,1,1,3
421,15,74,1,7,
421,15,76,1,43,
421,15,76,4,0,
421,15,80,1,25,
421,15,92,4,0,
421,15,104,4,0,
421,15,148,4,0,
421,15,156,4,0,
421,15,164,4,0,
421,15,182,4,0,
421,15,207,4,0,
421,15,213,4,0,
421,15,214,4,0,
421,15,216,4,0,
421,15,218,4,0,
421,15,219,4,0,
421,15,234,1,1,1
421,15,237,4,0,
421,15,241,1,22,
421,15,241,4,0,
421,15,263,4,0,
421,15,267,4,0,
421,15,270,1,13,
421,15,345,1,19,
421,15,381,1,48,
421,15,388,1,30,
421,15,412,4,0,
421,15,416,4,0,
421,15,447,4,0,
421,15,496,4,0,
421,15,572,1,50,
421,15,590,4,0,
421,15,605,4,0,
421,16,14,4,0,
421,16,33,1,1,2
421,16,36,1,35,1
421,16,63,4,0,
421,16,73,1,10,1
421,16,74,1,1,3
421,16,74,1,7,1
421,16,76,1,43,1
421,16,76,4,0,
421,16,80,1,25,1
421,16,92,4,0,
421,16,104,4,0,
421,16,148,4,0,
421,16,156,4,0,
421,16,164,4,0,
421,16,173,3,0,
421,16,182,4,0,
421,16,202,3,0,
421,16,207,4,0,
421,16,213,4,0,
421,16,214,4,0,
421,16,216,4,0,
421,16,218,4,0,
421,16,219,4,0,
421,16,234,1,1,1
421,16,235,3,0,
421,16,237,4,0,
421,16,241,1,22,1
421,16,241,4,0,
421,16,263,4,0,
421,16,267,4,0,
421,16,270,1,13,1
421,16,270,3,0,
421,16,290,4,0,
421,16,345,1,19,1
421,16,381,1,48,1
421,16,388,1,30,1
421,16,388,3,0,
421,16,402,3,0,
421,16,412,4,0,
421,16,416,4,0,
421,16,447,4,0,
421,16,496,4,0,
421,16,572,1,50,1
421,16,590,4,0,
421,16,605,4,0,
421,17,14,4,0,
421,17,33,1,1,3
421,17,36,1,35,
421,17,63,4,0,
421,17,73,1,1,5
421,17,73,1,10,
421,17,74,1,1,4
421,17,74,1,7,
421,17,76,1,43,
421,17,76,4,0,
421,17,80,1,0,
421,17,80,1,1,1
421,17,92,4,0,
421,17,104,4,0,
421,17,156,4,0,
421,17,164,4,0,
421,17,182,4,0,
421,17,207,4,0,
421,17,213,4,0,
421,17,214,4,0,
421,17,216,4,0,
421,17,218,4,0,
421,17,219,4,0,
421,17,234,1,1,2
421,17,237,4,0,
421,17,241,1,22,
421,17,241,4,0,
421,17,263,4,0,
421,17,267,4,0,
421,17,270,1,13,
421,17,345,1,19,
421,17,381,1,48,
421,17,388,1,30,
421,17,412,4,0,
421,17,416,4,0,
421,17,447,4,0,
421,17,496,4,0,
421,17,572,1,50,
421,17,590,4,0,
421,17,605,4,0,
421,18,14,4,0,
421,18,33,1,1,3
421,18,36,1,35,
421,18,63,4,0,
421,18,73,1,1,5
421,18,73,1,10,
421,18,74,1,1,4
421,18,74,1,7,
421,18,76,1,43,
421,18,76,4,0,
421,18,80,1,0,
421,18,80,1,1,1
421,18,92,4,0,
421,18,104,4,0,
421,18,156,4,0,
421,18,164,4,0,
421,18,182,4,0,
421,18,207,4,0,
421,18,213,4,0,
421,18,214,4,0,
421,18,216,4,0,
421,18,218,4,0,
421,18,219,4,0,
421,18,234,1,1,2
421,18,237,4,0,
421,18,241,1,22,
421,18,241,4,0,
421,18,263,4,0,
421,18,267,4,0,
421,18,270,1,13,
421,18,345,1,19,
421,18,381,1,48,
421,18,388,1,30,
421,18,412,4,0,
421,18,416,4,0,
421,18,447,4,0,
421,18,496,4,0,
421,18,572,1,50,
421,18,590,4,0,
421,18,605,4,0,
422,8,34,1,29,
422,8,57,4,0,
422,8,58,4,0,
422,8,59,4,0,
422,8,68,2,0,
422,8,90,2,0,
422,8,92,4,0,
422,8,104,4,0,
422,8,105,1,46,
422,8,106,1,4,
422,8,133,2,0,
422,8,156,4,0,
422,8,164,4,0,
422,8,174,2,0,
422,8,182,4,0,
422,8,189,1,1,
422,8,203,4,0,
422,8,207,4,0,
422,8,213,4,0,
422,8,214,4,0,
422,8,216,4,0,
422,8,218,4,0,
422,8,237,1,16,
422,8,237,4,0,
422,8,240,1,22,
422,8,240,4,0,
422,8,243,2,0,
422,8,254,2,0,
422,8,255,2,0,
422,8,256,2,0,
422,8,258,4,0,
422,8,262,2,0,
422,8,263,4,0,
422,8,281,2,0,
422,8,290,4,0,
422,8,300,1,2,
422,8,330,1,37,
422,8,352,1,7,
422,8,352,4,0,
422,8,362,4,0,
422,8,363,4,0,
422,8,426,1,11,
422,8,445,4,0,
422,9,34,1,29,
422,9,57,4,0,
422,9,58,4,0,
422,9,59,4,0,
422,9,68,2,0,
422,9,90,2,0,
422,9,92,4,0,
422,9,104,4,0,
422,9,105,1,46,
422,9,106,1,4,
422,9,133,2,0,
422,9,156,4,0,
422,9,164,4,0,
422,9,173,3,0,
422,9,174,2,0,
422,9,182,4,0,
422,9,189,1,1,
422,9,189,3,0,
422,9,196,3,0,
422,9,203,4,0,
422,9,207,4,0,
422,9,213,4,0,
422,9,214,4,0,
422,9,216,4,0,
422,9,218,4,0,
422,9,237,1,16,
422,9,237,4,0,
422,9,240,1,22,
422,9,240,4,0,
422,9,243,2,0,
422,9,246,3,0,
422,9,254,2,0,
422,9,255,2,0,
422,9,256,2,0,
422,9,258,4,0,
422,9,262,2,0,
422,9,263,4,0,
422,9,281,2,0,
422,9,290,4,0,
422,9,291,3,0,
422,9,300,1,2,
422,9,330,1,37,
422,9,352,1,7,
422,9,352,4,0,
422,9,362,4,0,
422,9,363,4,0,
422,9,414,3,0,
422,9,426,1,11,
422,9,445,4,0,
422,10,29,3,0,
422,10,34,1,29,
422,10,57,4,0,
422,10,58,4,0,
422,10,59,4,0,
422,10,68,2,0,
422,10,81,3,0,
422,10,90,2,0,
422,10,92,4,0,
422,10,104,4,0,
422,10,105,1,46,
422,10,106,1,4,
422,10,124,2,0,
422,10,133,2,0,
422,10,156,4,0,
422,10,164,4,0,
422,10,173,3,0,
422,10,174,2,0,
422,10,182,4,0,
422,10,189,1,1,
422,10,189,3,0,
422,10,196,3,0,
422,10,203,4,0,
422,10,207,4,0,
422,10,213,4,0,
422,10,214,4,0,
422,10,216,4,0,
422,10,218,4,0,
422,10,220,3,0,
422,10,237,1,16,
422,10,237,4,0,
422,10,240,1,22,
422,10,240,4,0,
422,10,243,2,0,
422,10,246,3,0,
422,10,250,4,0,
422,10,254,2,0,
422,10,255,2,0,
422,10,256,2,0,
422,10,258,4,0,
422,10,262,2,0,
422,10,263,4,0,
422,10,281,2,0,
422,10,290,4,0,
422,10,291,3,0,
422,10,300,1,2,
422,10,330,1,37,
422,10,352,1,7,
422,10,352,4,0,
422,10,362,4,0,
422,10,363,4,0,
422,10,376,2,0,
422,10,414,3,0,
422,10,426,1,11,
422,10,445,4,0,
422,11,34,1,29,
422,11,54,2,0,
422,11,57,4,0,
422,11,58,4,0,
422,11,59,4,0,
422,11,68,2,0,
422,11,90,2,0,
422,11,92,4,0,
422,11,104,4,0,
422,11,105,1,46,
422,11,106,1,4,
422,11,124,2,0,
422,11,133,2,0,
422,11,156,4,0,
422,11,164,4,0,
422,11,174,2,0,
422,11,182,4,0,
422,11,189,1,1,
422,11,207,4,0,
422,11,213,4,0,
422,11,216,4,0,
422,11,218,4,0,
422,11,237,1,16,
422,11,237,4,0,
422,11,240,1,22,
422,11,240,4,0,
422,11,243,2,0,
422,11,254,2,0,
422,11,255,2,0,
422,11,256,2,0,
422,11,258,4,0,
422,11,262,2,0,
422,11,263,4,0,
422,11,281,2,0,
422,11,291,4,0,
422,11,300,1,2,
422,11,330,1,37,
422,11,352,1,7,
422,11,362,2,0,
422,11,376,2,0,
422,11,426,1,11,
422,11,496,4,0,
422,11,499,2,0,
422,11,503,4,0,
422,14,34,1,29,
422,14,54,2,0,
422,14,57,4,0,
422,14,58,4,0,
422,14,59,4,0,
422,14,68,2,0,
422,14,90,2,0,
422,14,92,4,0,
422,14,104,4,0,
422,14,105,1,46,
422,14,106,1,4,
422,14,124,2,0,
422,14,133,2,0,
422,14,156,4,0,
422,14,164,4,0,
422,14,173,3,0,
422,14,174,2,0,
422,14,182,4,0,
422,14,189,1,1,
422,14,196,3,0,
422,14,207,4,0,
422,14,213,4,0,
422,14,214,3,0,
422,14,216,4,0,
422,14,218,4,0,
422,14,220,3,0,
422,14,237,1,16,
422,14,237,4,0,
422,14,240,1,22,
422,14,240,4,0,
422,14,243,2,0,
422,14,254,2,0,
422,14,255,2,0,
422,14,256,2,0,
422,14,258,4,0,
422,14,262,2,0,
422,14,263,4,0,
422,14,281,2,0,
422,14,291,4,0,
422,14,300,1,2,
422,14,330,1,37,
422,14,352,1,7,
422,14,362,2,0,
422,14,376,2,0,
422,14,414,3,0,
422,14,426,1,11,
422,14,496,4,0,
422,14,499,2,0,
422,14,503,4,0,
422,15,34,1,29,
422,15,54,2,0,
422,15,57,4,0,
422,15,58,4,0,
422,15,59,4,0,
422,15,68,2,0,
422,15,90,2,0,
422,15,92,4,0,
422,15,104,4,0,
422,15,105,1,46,
422,15,106,1,4,
422,15,124,2,0,
422,15,133,2,0,
422,15,151,2,0,
422,15,156,4,0,
422,15,164,4,0,
422,15,174,2,0,
422,15,182,4,0,
422,15,189,1,1,
422,15,207,4,0,
422,15,213,4,0,
422,15,214,4,0,
422,15,216,4,0,
422,15,218,4,0,
422,15,237,1,16,
422,15,237,4,0,
422,15,240,1,22,
422,15,240,4,0,
422,15,243,2,0,
422,15,254,2,0,
422,15,255,2,0,
422,15,256,2,0,
422,15,258,4,0,
422,15,262,2,0,
422,15,263,4,0,
422,15,281,2,0,
422,15,300,1,2,
422,15,330,1,37,
422,15,352,1,7,
422,15,362,2,0,
422,15,376,2,0,
422,15,426,1,11,
422,15,496,4,0,
422,15,499,2,0,
422,15,503,4,0,
422,15,590,4,0,
422,15,611,4,0,
422,16,34,1,29,1
422,16,54,2,0,
422,16,57,4,0,
422,16,58,4,0,
422,16,59,4,0,
422,16,68,2,0,
422,16,90,2,0,
422,16,92,4,0,
422,16,104,4,0,
422,16,105,1,46,1
422,16,106,1,4,1
422,16,124,2,0,
422,16,133,2,0,
422,16,151,2,0,
422,16,156,4,0,
422,16,164,4,0,
422,16,173,3,0,
422,16,174,2,0,
422,16,182,4,0,
422,16,189,1,1,1
422,16,196,3,0,
422,16,207,4,0,
422,16,213,4,0,
422,16,214,4,0,
422,16,216,4,0,
422,16,218,4,0,
422,16,220,3,0,
422,16,237,1,16,1
422,16,237,4,0,
422,16,240,1,22,1
422,16,240,4,0,
422,16,243,2,0,
422,16,254,2,0,
422,16,255,2,0,
422,16,256,2,0,
422,16,258,4,0,
422,16,262,2,0,
422,16,263,4,0,
422,16,281,2,0,
422,16,290,4,0,
422,16,291,4,0,
422,16,300,1,2,1
422,16,330,1,37,1
422,16,352,1,7,1
422,16,352,3,0,
422,16,362,2,0,
422,16,376,2,0,
422,16,414,3,0,
422,16,426,1,11,1
422,16,496,4,0,
422,16,499,2,0,
422,16,503,4,0,
422,16,590,4,0,
422,16,611,4,0,
422,17,34,1,29,
422,17,54,2,0,
422,17,57,4,0,
422,17,58,4,0,
422,17,59,4,0,
422,17,68,2,0,
422,17,90,2,0,
422,17,92,4,0,
422,17,104,4,0,
422,17,105,1,46,
422,17,106,1,4,
422,17,124,2,0,
422,17,133,2,0,
422,17,151,2,0,
422,17,156,4,0,
422,17,164,4,0,
422,17,174,2,0,
422,17,182,4,0,
422,17,189,1,1,
422,17,207,4,0,
422,17,213,4,0,
422,17,214,4,0,
422,17,216,4,0,
422,17,218,4,0,
422,17,237,1,16,
422,17,237,4,0,
422,17,240,1,22,
422,17,240,4,0,
422,17,243,2,0,
422,17,254,2,0,
422,17,255,2,0,
422,17,256,2,0,
422,17,258,4,0,
422,17,262,2,0,
422,17,263,4,0,
422,17,281,2,0,
422,17,300,1,2,
422,17,330,1,37,
422,17,352,1,7,
422,17,362,2,0,
422,17,376,2,0,
422,17,426,1,11,
422,17,496,4,0,
422,17,499,2,0,
422,17,503,4,0,
422,17,590,4,0,
422,17,611,4,0,
422,18,34,1,29,
422,18,54,2,0,
422,18,57,4,0,
422,18,58,4,0,
422,18,59,4,0,
422,18,68,2,0,
422,18,90,2,0,
422,18,92,4,0,
422,18,104,4,0,
422,18,105,1,46,
422,18,106,1,4,
422,18,124,2,0,
422,18,133,2,0,
422,18,151,2,0,
422,18,156,4,0,
422,18,164,4,0,
422,18,174,2,0,
422,18,182,4,0,
422,18,189,1,1,
422,18,207,4,0,
422,18,213,4,0,
422,18,214,4,0,
422,18,216,4,0,
422,18,218,4,0,
422,18,237,1,16,
422,18,237,4,0,
422,18,240,1,22,
422,18,240,4,0,
422,18,243,2,0,
422,18,254,2,0,
422,18,255,2,0,
422,18,256,2,0,
422,18,258,4,0,
422,18,262,2,0,
422,18,263,4,0,
422,18,281,2,0,
422,18,300,1,2,
422,18,330,1,37,
422,18,352,1,7,
422,18,362,2,0,
422,18,376,2,0,
422,18,426,1,11,
422,18,496,4,0,
422,18,499,2,0,
422,18,503,4,0,
422,18,590,4,0,
422,18,611,4,0,
423,8,34,1,29,
423,8,57,4,0,
423,8,58,4,0,
423,8,59,4,0,
423,8,63,4,0,
423,8,70,4,0,
423,8,89,4,0,
423,8,91,4,0,
423,8,92,4,0,
423,8,104,4,0,
423,8,105,1,54,
423,8,106,1,1,3
423,8,106,1,4,
423,8,127,4,0,
423,8,148,4,0,
423,8,156,4,0,
423,8,157,4,0,
423,8,164,4,0,
423,8,182,4,0,
423,8,188,4,0,
423,8,189,1,1,1
423,8,201,4,0,
423,8,203,4,0,
423,8,207,4,0,
423,8,213,4,0,
423,8,214,4,0,
423,8,216,4,0,
423,8,218,4,0,
423,8,237,1,16,
423,8,237,4,0,
423,8,240,1,22,
423,8,240,4,0,
423,8,249,4,0,
423,8,258,4,0,
423,8,263,4,0,
423,8,290,4,0,
423,8,300,1,1,2
423,8,300,1,2,
423,8,317,4,0,
423,8,330,1,41,
423,8,352,1,1,4
423,8,352,1,7,
423,8,352,4,0,
423,8,362,4,0,
423,8,363,4,0,
423,8,416,4,0,
423,8,426,1,11,
423,8,444,4,0,
423,8,445,4,0,
423,9,34,1,29,
423,9,57,4,0,
423,9,58,4,0,
423,9,59,4,0,
423,9,63,4,0,
423,9,70,4,0,
423,9,89,4,0,
423,9,91,4,0,
423,9,92,4,0,
423,9,104,4,0,
423,9,105,1,54,
423,9,106,1,1,3
423,9,106,1,4,
423,9,127,4,0,
423,9,148,4,0,
423,9,156,4,0,
423,9,157,4,0,
423,9,164,4,0,
423,9,173,3,0,
423,9,182,4,0,
423,9,188,4,0,
423,9,189,1,1,1
423,9,189,3,0,
423,9,196,3,0,
423,9,201,4,0,
423,9,203,4,0,
423,9,207,4,0,
423,9,213,4,0,
423,9,214,4,0,
423,9,216,4,0,
423,9,218,4,0,
423,9,237,1,16,
423,9,237,4,0,
423,9,240,1,22,
423,9,240,4,0,
423,9,246,3,0,
423,9,249,4,0,
423,9,258,4,0,
423,9,263,4,0,
423,9,290,4,0,
423,9,291,3,0,
423,9,300,1,1,2
423,9,300,1,2,
423,9,317,4,0,
423,9,330,1,41,
423,9,352,1,1,4
423,9,352,1,7,
423,9,352,4,0,
423,9,362,4,0,
423,9,363,4,0,
423,9,414,3,0,
423,9,416,4,0,
423,9,426,1,11,
423,9,444,4,0,
423,9,445,4,0,
423,10,29,3,0,
423,10,34,1,29,
423,10,57,4,0,
423,10,58,4,0,
423,10,59,4,0,
423,10,63,4,0,
423,10,70,4,0,
423,10,81,3,0,
423,10,89,4,0,
423,10,91,4,0,
423,10,92,4,0,
423,10,104,4,0,
423,10,105,1,54,
423,10,106,1,1,3
423,10,106,1,4,
423,10,127,4,0,
423,10,148,4,0,
423,10,156,4,0,
423,10,157,4,0,
423,10,164,4,0,
423,10,173,3,0,
423,10,182,4,0,
423,10,188,4,0,
423,10,189,1,1,1
423,10,189,3,0,
423,10,196,3,0,
423,10,201,4,0,
423,10,203,4,0,
423,10,207,4,0,
423,10,213,4,0,
423,10,214,4,0,
423,10,216,4,0,
423,10,218,4,0,
423,10,220,3,0,
423,10,237,1,16,
423,10,237,4,0,
423,10,240,1,22,
423,10,240,4,0,
423,10,246,3,0,
423,10,249,4,0,
423,10,250,4,0,
423,10,258,4,0,
423,10,263,4,0,
423,10,290,4,0,
423,10,291,3,0,
423,10,300,1,1,2
423,10,300,1,2,
423,10,317,4,0,
423,10,330,1,41,
423,10,335,3,0,
423,10,352,1,1,4
423,10,352,1,7,
423,10,352,4,0,
423,10,362,4,0,
423,10,363,4,0,
423,10,414,3,0,
423,10,416,4,0,
423,10,426,1,11,
423,10,444,4,0,
423,10,445,4,0,
423,11,34,1,29,
423,11,57,4,0,
423,11,58,4,0,
423,11,59,4,0,
423,11,63,4,0,
423,11,70,4,0,
423,11,89,4,0,
423,11,91,4,0,
423,11,92,4,0,
423,11,104,4,0,
423,11,105,1,54,
423,11,106,1,1,3
423,11,106,1,4,
423,11,127,4,0,
423,11,148,4,0,
423,11,156,4,0,
423,11,157,4,0,
423,11,164,4,0,
423,11,182,4,0,
423,11,188,4,0,
423,11,189,1,1,1
423,11,201,4,0,
423,11,207,4,0,
423,11,213,4,0,
423,11,216,4,0,
423,11,218,4,0,
423,11,237,1,16,
423,11,237,4,0,
423,11,240,1,22,
423,11,240,4,0,
423,11,249,4,0,
423,11,258,4,0,
423,11,263,4,0,
423,11,291,4,0,
423,11,300,1,1,2
423,11,300,1,2,
423,11,317,4,0,
423,11,330,1,41,
423,11,352,1,1,4
423,11,352,1,7,
423,11,416,4,0,
423,11,426,1,11,
423,11,444,4,0,
423,11,482,4,0,
423,11,496,4,0,
423,11,503,4,0,
423,11,523,4,0,
423,14,34,1,29,
423,14,57,4,0,
423,14,58,4,0,
423,14,59,4,0,
423,14,63,4,0,
423,14,70,4,0,
423,14,89,4,0,
423,14,91,4,0,
423,14,92,4,0,
423,14,104,4,0,
423,14,105,1,54,
423,14,106,1,1,3
423,14,106,1,4,
423,14,127,4,0,
423,14,148,4,0,
423,14,156,4,0,
423,14,157,4,0,
423,14,164,4,0,
423,14,173,3,0,
423,14,182,4,0,
423,14,188,4,0,
423,14,189,1,1,1
423,14,196,3,0,
423,14,201,4,0,
423,14,207,4,0,
423,14,213,4,0,
423,14,214,3,0,
423,14,216,4,0,
423,14,218,4,0,
423,14,220,3,0,
423,14,237,1,16,
423,14,237,4,0,
423,14,240,1,22,
423,14,240,4,0,
423,14,249,4,0,
423,14,258,4,0,
423,14,263,4,0,
423,14,291,4,0,
423,14,300,1,1,2
423,14,300,1,2,
423,14,317,4,0,
423,14,330,1,41,
423,14,335,3,0,
423,14,352,1,1,4
423,14,352,1,7,
423,14,414,3,0,
423,14,416,4,0,
423,14,426,1,11,
423,14,444,4,0,
423,14,482,4,0,
423,14,496,4,0,
423,14,503,4,0,
423,14,523,4,0,
423,15,34,1,29,
423,15,57,4,0,
423,15,58,4,0,
423,15,59,4,0,
423,15,63,4,0,
423,15,70,4,0,
423,15,89,4,0,
423,15,91,4,0,
423,15,92,4,0,
423,15,104,4,0,
423,15,105,1,54,
423,15,106,1,1,3
423,15,106,1,4,
423,15,127,4,0,
423,15,148,4,0,
423,15,156,4,0,
423,15,157,4,0,
423,15,164,4,0,
423,15,182,4,0,
423,15,188,4,0,
423,15,189,1,1,1
423,15,201,4,0,
423,15,207,4,0,
423,15,213,4,0,
423,15,214,4,0,
423,15,216,4,0,
423,15,218,4,0,
423,15,237,1,16,
423,15,237,4,0,
423,15,240,1,22,
423,15,240,4,0,
423,15,249,4,0,
423,15,258,4,0,
423,15,263,4,0,
423,15,300,1,1,2
423,15,300,1,2,
423,15,317,4,0,
423,15,330,1,41,
423,15,352,1,1,4
423,15,352,1,7,
423,15,416,4,0,
423,15,426,1,11,
423,15,444,4,0,
423,15,482,4,0,
423,15,496,4,0,
423,15,503,4,0,
423,15,523,4,0,
423,15,590,4,0,
423,15,611,4,0,
423,16,34,1,29,1
423,16,57,4,0,
423,16,58,4,0,
423,16,59,4,0,
423,16,63,4,0,
423,16,70,4,0,
423,16,89,4,0,
423,16,91,4,0,
423,16,92,4,0,
423,16,104,4,0,
423,16,105,1,54,1
423,16,106,1,1,3
423,16,106,1,4,1
423,16,127,4,0,
423,16,148,4,0,
423,16,156,4,0,
423,16,157,4,0,
423,16,164,4,0,
423,16,173,3,0,
423,16,182,4,0,
423,16,188,4,0,
423,16,189,1,1,1
423,16,196,3,0,
423,16,201,4,0,
423,16,207,4,0,
423,16,213,4,0,
423,16,214,4,0,
423,16,216,4,0,
423,16,218,4,0,
423,16,220,3,0,
423,16,237,1,16,1
423,16,237,4,0,
423,16,240,1,22,1
423,16,240,4,0,
423,16,249,4,0,
423,16,258,4,0,
423,16,263,4,0,
423,16,290,4,0,
423,16,291,4,0,
423,16,300,1,1,2
423,16,300,1,2,1
423,16,317,4,0,
423,16,330,1,41,1
423,16,335,3,0,
423,16,352,1,1,4
423,16,352,1,7,1
423,16,352,3,0,
423,16,414,3,0,
423,16,416,4,0,
423,16,426,1,11,1
423,16,444,4,0,
423,16,482,4,0,
423,16,496,4,0,
423,16,503,4,0,
423,16,523,4,0,
423,16,590,4,0,
423,16,611,4,0,
423,17,34,1,29,
423,17,57,4,0,
423,17,58,4,0,
423,17,59,4,0,
423,17,63,4,0,
423,17,89,4,0,
423,17,92,4,0,
423,17,104,4,0,
423,17,105,1,54,
423,17,106,1,1,3
423,17,106,1,4,
423,17,127,4,0,
423,17,156,4,0,
423,17,157,4,0,
423,17,164,4,0,
423,17,182,4,0,
423,17,188,4,0,
423,17,189,1,1,1
423,17,201,4,0,
423,17,207,4,0,
423,17,213,4,0,
423,17,214,4,0,
423,17,216,4,0,
423,17,218,4,0,
423,17,237,1,16,
423,17,237,4,0,
423,17,240,1,22,
423,17,240,4,0,
423,17,258,4,0,
423,17,263,4,0,
423,17,300,1,1,2
423,17,300,1,2,
423,17,317,4,0,
423,17,330,1,41,
423,17,352,1,1,4
423,17,352,1,7,
423,17,416,4,0,
423,17,426,1,11,
423,17,444,4,0,
423,17,482,4,0,
423,17,496,4,0,
423,17,503,4,0,
423,17,523,4,0,
423,17,590,4,0,
423,17,611,4,0,
423,18,34,1,29,
423,18,57,4,0,
423,18,58,4,0,
423,18,59,4,0,
423,18,63,4,0,
423,18,89,4,0,
423,18,92,4,0,
423,18,104,4,0,
423,18,105,1,54,
423,18,106,1,1,3
423,18,106,1,4,
423,18,127,4,0,
423,18,156,4,0,
423,18,157,4,0,
423,18,164,4,0,
423,18,182,4,0,
423,18,188,4,0,
423,18,189,1,1,1
423,18,201,4,0,
423,18,207,4,0,
423,18,213,4,0,
423,18,214,4,0,
423,18,216,4,0,
423,18,218,4,0,
423,18,237,1,16,
423,18,237,4,0,
423,18,240,1,22,
423,18,240,4,0,
423,18,258,4,0,
423,18,263,4,0,
423,18,300,1,1,2
423,18,300,1,2,
423,18,317,4,0,
423,18,330,1,41,
423,18,352,1,1,4
423,18,352,1,7,
423,18,416,4,0,
423,18,426,1,11,
423,18,444,4,0,
423,18,482,4,0,
423,18,496,4,0,
423,18,503,4,0,
423,18,523,4,0,
423,18,590,4,0,
423,18,611,4,0,
424,8,10,1,1,1
424,8,15,4,0,
424,8,28,1,1,3
424,8,28,1,4,
424,8,39,1,1,2
424,8,63,4,0,
424,8,70,4,0,
424,8,76,4,0,
424,8,85,4,0,
424,8,86,4,0,
424,8,87,4,0,
424,8,91,4,0,
424,8,92,4,0,
424,8,97,1,29,
424,8,103,1,25,
424,8,104,4,0,
424,8,129,1,22,
424,8,138,4,0,
424,8,154,1,18,
424,8,156,4,0,
424,8,164,4,0,
424,8,168,4,0,
424,8,182,4,0,
424,8,203,4,0,
424,8,207,4,0,
424,8,213,4,0,
424,8,214,4,0,
424,8,216,4,0,
424,8,218,4,0,
424,8,226,1,11,
424,8,231,4,0,
424,8,237,4,0,
424,8,240,4,0,
424,8,241,4,0,
424,8,247,4,0,
424,8,249,4,0,
424,8,263,4,0,
424,8,264,4,0,
424,8,269,4,0,
424,8,280,4,0,
424,8,289,4,0,
424,8,290,4,0,
424,8,310,1,1,4
424,8,310,1,8,
424,8,321,1,15,
424,8,332,4,0,
424,8,351,4,0,
424,8,352,4,0,
424,8,363,4,0,
424,8,369,4,0,
424,8,371,4,0,
424,8,374,1,36,
424,8,374,4,0,
424,8,387,1,43,
424,8,416,4,0,
424,8,417,1,39,
424,8,421,4,0,
424,8,445,4,0,
424,8,447,4,0,
424,8,458,1,32,
424,9,7,3,0,
424,9,8,3,0,
424,9,9,3,0,
424,9,10,1,1,1
424,9,15,4,0,
424,9,28,1,1,3
424,9,28,1,4,
424,9,39,1,1,2
424,9,63,4,0,
424,9,70,4,0,
424,9,76,4,0,
424,9,85,4,0,
424,9,86,4,0,
424,9,87,4,0,
424,9,91,4,0,
424,9,92,4,0,
424,9,97,1,29,
424,9,103,1,25,
424,9,104,4,0,
424,9,129,1,22,
424,9,129,3,0,
424,9,138,4,0,
424,9,154,1,18,
424,9,156,4,0,
424,9,164,4,0,
424,9,168,4,0,
424,9,173,3,0,
424,9,180,3,0,
424,9,182,4,0,
424,9,189,3,0,
424,9,203,4,0,
424,9,207,4,0,
424,9,210,3,0,
424,9,213,4,0,
424,9,214,4,0,
424,9,216,4,0,
424,9,218,4,0,
424,9,226,1,11,
424,9,231,4,0,
424,9,237,4,0,
424,9,240,4,0,
424,9,241,4,0,
424,9,247,4,0,
424,9,249,4,0,
424,9,253,3,0,
424,9,263,4,0,
424,9,264,4,0,
424,9,269,4,0,
424,9,280,4,0,
424,9,282,3,0,
424,9,289,4,0,
424,9,290,4,0,
424,9,310,1,1,4
424,9,310,1,8,
424,9,321,1,15,
424,9,332,4,0,
424,9,340,3,0,
424,9,351,4,0,
424,9,352,4,0,
424,9,363,4,0,
424,9,369,4,0,
424,9,371,4,0,
424,9,374,1,36,
424,9,374,4,0,
424,9,387,1,43,
424,9,387,3,0,
424,9,402,3,0,
424,9,416,4,0,
424,9,417,1,39,
424,9,421,4,0,
424,9,441,3,0,
424,9,445,4,0,
424,9,447,4,0,
424,9,458,1,32,
424,10,7,3,0,
424,10,8,3,0,
424,10,9,3,0,
424,10,10,1,1,1
424,10,15,4,0,
424,10,28,1,1,3
424,10,28,1,4,
424,10,29,3,0,
424,10,39,1,1,2
424,10,63,4,0,
424,10,67,3,0,
424,10,70,4,0,
424,10,76,4,0,
424,10,85,4,0,
424,10,86,4,0,
424,10,87,4,0,
424,10,91,4,0,
424,10,92,4,0,
424,10,97,1,29,
424,10,103,1,25,
424,10,104,4,0,
424,10,129,1,22,
424,10,129,3,0,
424,10,138,4,0,
424,10,154,1,18,
424,10,156,4,0,
424,10,164,4,0,
424,10,168,4,0,
424,10,173,3,0,
424,10,180,3,0,
424,10,182,4,0,
424,10,189,3,0,
424,10,203,4,0,
424,10,207,4,0,
424,10,210,3,0,
424,10,213,4,0,
424,10,214,4,0,
424,10,216,4,0,
424,10,218,4,0,
424,10,226,1,11,
424,10,231,4,0,
424,10,237,4,0,
424,10,240,4,0,
424,10,241,4,0,
424,10,247,4,0,
424,10,249,4,0,
424,10,253,3,0,
424,10,263,4,0,
424,10,264,4,0,
424,10,269,4,0,
424,10,272,3,0,
424,10,280,4,0,
424,10,282,3,0,
424,10,289,4,0,
424,10,290,4,0,
424,10,310,1,1,4
424,10,310,1,8,
424,10,321,1,15,
424,10,332,4,0,
424,10,340,3,0,
424,10,351,4,0,
424,10,352,4,0,
424,10,363,4,0,
424,10,369,4,0,
424,10,371,4,0,
424,10,374,1,36,
424,10,374,4,0,
424,10,387,1,43,
424,10,387,3,0,
424,10,402,3,0,
424,10,416,4,0,
424,10,417,1,39,
424,10,421,4,0,
424,10,441,3,0,
424,10,445,4,0,
424,10,447,4,0,
424,10,458,1,32,
424,11,10,1,1,1
424,11,15,4,0,
424,11,28,1,1,3
424,11,28,1,4,
424,11,39,1,1,2
424,11,63,4,0,
424,11,70,4,0,
424,11,76,4,0,
424,11,85,4,0,
424,11,86,4,0,
424,11,87,4,0,
424,11,91,4,0,
424,11,92,4,0,
424,11,97,1,29,
424,11,103,1,25,
424,11,104,4,0,
424,11,129,1,22,
424,11,138,4,0,
424,11,154,1,18,
424,11,156,4,0,
424,11,164,4,0,
424,11,168,4,0,
424,11,182,4,0,
424,11,207,4,0,
424,11,213,4,0,
424,11,216,4,0,
424,11,218,4,0,
424,11,226,1,11,
424,11,237,4,0,
424,11,240,4,0,
424,11,241,4,0,
424,11,247,4,0,
424,11,249,4,0,
424,11,263,4,0,
424,11,269,4,0,
424,11,280,4,0,
424,11,310,1,1,4
424,11,310,1,8,
424,11,321,1,15,
424,11,332,4,0,
424,11,369,4,0,
424,11,371,4,0,
424,11,374,1,36,
424,11,374,4,0,
424,11,387,1,43,
424,11,416,4,0,
424,11,417,1,39,
424,11,421,4,0,
424,11,447,4,0,
424,11,458,1,32,
424,11,468,4,0,
424,11,490,4,0,
424,11,496,4,0,
424,11,512,4,0,
424,11,514,4,0,
424,11,526,4,0,
424,14,7,3,0,
424,14,8,3,0,
424,14,9,3,0,
424,14,10,1,1,1
424,14,15,4,0,
424,14,28,1,1,3
424,14,28,1,4,
424,14,39,1,1,2
424,14,63,4,0,
424,14,67,3,0,
424,14,70,4,0,
424,14,76,4,0,
424,14,85,4,0,
424,14,86,4,0,
424,14,87,4,0,
424,14,91,4,0,
424,14,92,4,0,
424,14,97,1,29,
424,14,103,1,25,
424,14,104,4,0,
424,14,129,1,22,
424,14,138,4,0,
424,14,154,1,18,
424,14,156,4,0,
424,14,164,4,0,
424,14,168,4,0,
424,14,173,3,0,
424,14,180,3,0,
424,14,182,4,0,
424,14,207,4,0,
424,14,213,4,0,
424,14,214,3,0,
424,14,216,4,0,
424,14,218,4,0,
424,14,226,1,11,
424,14,231,3,0,
424,14,237,4,0,
424,14,240,4,0,
424,14,241,4,0,
424,14,247,4,0,
424,14,249,4,0,
424,14,253,3,0,
424,14,263,4,0,
424,14,269,4,0,
424,14,272,3,0,
424,14,280,4,0,
424,14,282,3,0,
424,14,289,3,0,
424,14,310,1,1,4
424,14,310,1,8,
424,14,321,1,15,
424,14,332,4,0,
424,14,340,3,0,
424,14,343,3,0,
424,14,369,4,0,
424,14,371,4,0,
424,14,374,1,36,
424,14,374,4,0,
424,14,387,1,43,
424,14,387,3,0,
424,14,402,3,0,
424,14,416,4,0,
424,14,417,1,39,
424,14,421,4,0,
424,14,441,3,0,
424,14,447,4,0,
424,14,458,1,32,
424,14,468,4,0,
424,14,490,4,0,
424,14,492,3,0,
424,14,496,4,0,
424,14,512,4,0,
424,14,514,4,0,
424,14,526,4,0,
424,15,10,1,1,1
424,15,15,4,0,
424,15,28,1,1,3
424,15,28,1,4,
424,15,39,1,1,2
424,15,63,4,0,
424,15,70,4,0,
424,15,76,4,0,
424,15,85,4,0,
424,15,86,4,0,
424,15,87,4,0,
424,15,91,4,0,
424,15,92,4,0,
424,15,97,1,29,
424,15,103,1,25,
424,15,104,4,0,
424,15,129,1,22,
424,15,138,4,0,
424,15,154,1,18,
424,15,156,4,0,
424,15,164,4,0,
424,15,168,4,0,
424,15,182,4,0,
424,15,207,4,0,
424,15,213,4,0,
424,15,214,4,0,
424,15,216,4,0,
424,15,218,4,0,
424,15,226,1,11,
424,15,237,4,0,
424,15,240,4,0,
424,15,241,4,0,
424,15,247,4,0,
424,15,249,4,0,
424,15,263,4,0,
424,15,269,4,0,
424,15,280,4,0,
424,15,310,1,1,4
424,15,310,1,8,
424,15,321,1,15,
424,15,332,4,0,
424,15,369,4,0,
424,15,371,4,0,
424,15,374,1,36,
424,15,374,4,0,
424,15,387,1,43,
424,15,416,4,0,
424,15,417,1,39,
424,15,421,4,0,
424,15,447,4,0,
424,15,458,1,32,
424,15,468,4,0,
424,15,490,4,0,
424,15,496,4,0,
424,15,512,4,0,
424,15,514,4,0,
424,15,590,4,0,
424,15,612,4,0,
424,16,7,3,0,
424,16,8,3,0,
424,16,9,3,0,
424,16,10,1,1,1
424,16,15,4,0,
424,16,28,1,1,3
424,16,28,1,4,1
424,16,39,1,1,2
424,16,63,4,0,
424,16,67,3,0,
424,16,70,4,0,
424,16,76,4,0,
424,16,85,4,0,
424,16,86,4,0,
424,16,87,4,0,
424,16,91,4,0,
424,16,92,4,0,
424,16,97,1,29,1
424,16,103,1,25,1
424,16,104,4,0,
424,16,129,1,22,1
424,16,138,4,0,
424,16,154,1,18,1
424,16,156,4,0,
424,16,164,4,0,
424,16,168,4,0,
424,16,173,3,0,
424,16,180,3,0,
424,16,182,4,0,
424,16,207,4,0,
424,16,213,4,0,
424,16,214,4,0,
424,16,216,4,0,
424,16,218,4,0,
424,16,226,1,11,1
424,16,231,3,0,
424,16,237,4,0,
424,16,240,4,0,
424,16,241,4,0,
424,16,247,4,0,
424,16,249,4,0,
424,16,253,3,0,
424,16,263,4,0,
424,16,264,3,0,
424,16,269,4,0,
424,16,272,3,0,
424,16,280,4,0,
424,16,282,3,0,
424,16,289,3,0,
424,16,290,4,0,
424,16,310,1,1,4
424,16,310,1,8,1
424,16,321,1,15,1
424,16,332,4,0,
424,16,340,3,0,
424,16,343,3,0,
424,16,351,3,0,
424,16,352,3,0,
424,16,369,4,0,
424,16,371,4,0,
424,16,374,1,36,1
424,16,374,4,0,
424,16,387,1,43,1
424,16,387,3,0,
424,16,402,3,0,
424,16,416,4,0,
424,16,417,1,39,1
424,16,421,4,0,
424,16,441,3,0,
424,16,447,4,0,
424,16,458,1,32,1
424,16,468,4,0,
424,16,490,4,0,
424,16,492,3,0,
424,16,496,4,0,
424,16,512,4,0,
424,16,514,4,0,
424,16,590,4,0,
424,16,612,4,0,
424,17,10,1,1,2
424,17,28,1,1,4
424,17,28,1,4,
424,17,39,1,1,3
424,17,63,4,0,
424,17,76,4,0,
424,17,85,4,0,
424,17,86,4,0,
424,17,87,4,0,
424,17,92,4,0,
424,17,97,1,29,
424,17,103,1,25,
424,17,104,4,0,
424,17,129,1,22,
424,17,138,4,0,
424,17,154,1,18,
424,17,156,4,0,
424,17,164,4,0,
424,17,168,4,0,
424,17,182,4,0,
424,17,207,4,0,
424,17,213,4,0,
424,17,214,4,0,
424,17,216,4,0,
424,17,218,4,0,
424,17,226,1,11,
424,17,237,4,0,
424,17,240,4,0,
424,17,241,4,0,
424,17,247,4,0,
424,17,263,4,0,
424,17,269,4,0,
424,17,280,4,0,
424,17,310,1,1,5
424,17,310,1,8,
424,17,321,1,15,
424,17,332,4,0,
424,17,369,4,0,
424,17,371,4,0,
424,17,374,1,36,
424,17,374,4,0,
424,17,387,1,43,
424,17,416,4,0,
424,17,417,1,39,
424,17,421,4,0,
424,17,447,4,0,
424,17,458,1,32,
424,17,490,4,0,
424,17,496,4,0,
424,17,512,4,0,
424,17,526,4,0,
424,17,530,1,1,1
424,17,590,4,0,
424,18,10,1,1,2
424,18,28,1,1,4
424,18,28,1,4,
424,18,39,1,1,3
424,18,63,4,0,
424,18,76,4,0,
424,18,85,4,0,
424,18,86,4,0,
424,18,87,4,0,
424,18,92,4,0,
424,18,97,1,29,
424,18,103,1,25,
424,18,104,4,0,
424,18,129,1,22,
424,18,138,4,0,
424,18,154,1,18,
424,18,156,4,0,
424,18,164,4,0,
424,18,168,4,0,
424,18,182,4,0,
424,18,207,4,0,
424,18,213,4,0,
424,18,214,4,0,
424,18,216,4,0,
424,18,218,4,0,
424,18,226,1,11,
424,18,237,4,0,
424,18,240,4,0,
424,18,241,4,0,
424,18,247,4,0,
424,18,263,4,0,
424,18,269,4,0,
424,18,280,4,0,
424,18,310,1,1,5
424,18,310,1,8,
424,18,321,1,15,
424,18,332,4,0,
424,18,369,4,0,
424,18,371,4,0,
424,18,374,1,36,
424,18,374,4,0,
424,18,387,1,43,
424,18,416,4,0,
424,18,417,1,39,
424,18,421,4,0,
424,18,447,4,0,
424,18,458,1,32,
424,18,490,4,0,
424,18,496,4,0,
424,18,512,4,0,
424,18,526,4,0,
424,18,530,1,1,1
424,18,590,4,0,
425,8,15,4,0,
425,8,16,1,11,
425,8,34,2,0,
425,8,50,2,0,
425,8,85,4,0,
425,8,86,4,0,
425,8,87,4,0,
425,8,92,4,0,
425,8,94,4,0,
425,8,95,2,0,
425,8,104,4,0,
425,8,107,1,1,2
425,8,114,2,0,
425,8,116,1,14,
425,8,132,1,1,1
425,8,138,4,0,
425,8,148,4,0,
425,8,153,1,43,
425,8,153,4,0,
425,8,156,4,0,
425,8,164,4,0,
425,8,168,4,0,
425,8,182,4,0,
425,8,194,2,0,
425,8,203,4,0,
425,8,207,4,0,
425,8,213,4,0,
425,8,214,4,0,
425,8,216,4,0,
425,8,218,4,0,
425,8,226,1,33,
425,8,237,4,0,
425,8,240,4,0,
425,8,241,4,0,
425,8,244,4,0,
425,8,247,1,38,
425,8,247,4,0,
425,8,254,1,22,
425,8,255,1,27,2
425,8,256,1,27,1
425,8,261,4,0,
425,8,262,2,0,
425,8,263,4,0,
425,8,278,4,0,
425,8,285,4,0,
425,8,290,4,0,
425,8,310,1,6,
425,8,318,4,0,
425,8,347,4,0,
425,8,351,4,0,
425,8,360,4,0,
425,8,363,4,0,
425,8,371,1,17,
425,8,371,4,0,
425,8,373,4,0,
425,8,432,4,0,
425,8,445,4,0,
425,8,451,4,0,
425,8,466,1,30,
425,9,15,4,0,
425,9,16,1,11,
425,9,34,2,0,
425,9,50,2,0,
425,9,85,4,0,
425,9,86,4,0,
425,9,87,4,0,
425,9,92,4,0,
425,9,94,4,0,
425,9,95,2,0,
425,9,104,4,0,
425,9,107,1,1,2
425,9,114,2,0,
425,9,116,1,14,
425,9,129,3,0,
425,9,132,1,1,1
425,9,138,4,0,
425,9,148,4,0,
425,9,153,1,43,
425,9,153,4,0,
425,9,156,4,0,
425,9,164,4,0,
425,9,168,4,0,
425,9,173,3,0,
425,9,180,3,0,
425,9,182,4,0,
425,9,189,3,0,
425,9,194,2,0,
425,9,196,3,0,
425,9,203,4,0,
425,9,205,3,0,
425,9,207,4,0,
425,9,213,4,0,
425,9,214,4,0,
425,9,216,4,0,
425,9,218,4,0,
425,9,226,1,33,
425,9,237,4,0,
425,9,240,4,0,
425,9,241,4,0,
425,9,244,4,0,
425,9,247,1,38,
425,9,247,4,0,
425,9,254,1,22,
425,9,255,1,27,2
425,9,256,1,27,1
425,9,261,4,0,
425,9,262,2,0,
425,9,263,4,0,
425,9,271,3,0,
425,9,278,4,0,
425,9,282,3,0,
425,9,285,4,0,
425,9,290,4,0,
425,9,310,1,6,
425,9,314,3,0,
425,9,318,4,0,
425,9,347,4,0,
425,9,351,4,0,
425,9,360,4,0,
425,9,363,4,0,
425,9,371,1,17,
425,9,371,4,0,
425,9,373,4,0,
425,9,389,3,0,
425,9,432,4,0,
425,9,445,4,0,
425,9,451,4,0,
425,9,466,1,30,
425,9,466,3,0,
425,10,15,4,0,
425,10,16,1,11,
425,10,34,2,0,
425,10,50,2,0,
425,10,85,4,0,
425,10,86,4,0,
425,10,87,4,0,
425,10,92,4,0,
425,10,94,4,0,
425,10,95,2,0,
425,10,104,4,0,
425,10,107,1,1,2
425,10,114,2,0,
425,10,116,1,14,
425,10,129,3,0,
425,10,132,1,1,1
425,10,138,4,0,
425,10,148,4,0,
425,10,153,1,43,
425,10,153,4,0,
425,10,156,4,0,
425,10,164,4,0,
425,10,168,4,0,
425,10,173,3,0,
425,10,180,3,0,
425,10,182,4,0,
425,10,189,3,0,
425,10,194,2,0,
425,10,196,3,0,
425,10,203,4,0,
425,10,205,3,0,
425,10,207,4,0,
425,10,213,4,0,
425,10,214,4,0,
425,10,216,4,0,
425,10,218,4,0,
425,10,220,3,0,
425,10,226,1,33,
425,10,237,4,0,
425,10,240,4,0,
425,10,241,4,0,
425,10,244,4,0,
425,10,247,1,38,
425,10,247,4,0,
425,10,254,1,22,
425,10,255,1,27,2
425,10,256,1,27,1
425,10,261,4,0,
425,10,262,2,0,
425,10,263,4,0,
425,10,271,3,0,
425,10,277,3,0,
425,10,278,4,0,
425,10,282,3,0,
425,10,285,4,0,
425,10,290,4,0,
425,10,310,1,6,
425,10,311,2,0,
425,10,314,3,0,
425,10,318,4,0,
425,10,347,4,0,
425,10,351,4,0,
425,10,360,4,0,
425,10,363,4,0,
425,10,366,3,0,
425,10,371,1,17,
425,10,371,4,0,
425,10,373,4,0,
425,10,389,3,0,
425,10,445,4,0,
425,10,451,4,0,
425,10,466,1,30,
425,10,466,3,0,
425,11,15,4,0,
425,11,16,1,11,
425,11,34,2,0,
425,11,50,2,0,
425,11,85,4,0,
425,11,86,4,0,
425,11,87,4,0,
425,11,92,4,0,
425,11,94,4,0,
425,11,95,2,0,
425,11,104,4,0,
425,11,107,1,1,2
425,11,114,2,0,
425,11,116,1,14,
425,11,132,1,1,1
425,11,138,4,0,
425,11,148,4,0,
425,11,153,1,46,
425,11,153,4,0,
425,11,156,4,0,
425,11,164,4,0,
425,11,168,4,0,
425,11,182,4,0,
425,11,194,2,0,
425,11,207,4,0,
425,11,213,4,0,
425,11,216,4,0,
425,11,218,4,0,
425,11,226,1,38,
425,11,237,4,0,
425,11,240,4,0,
425,11,241,4,0,
425,11,244,4,0,
425,11,247,1,43,
425,11,247,4,0,
425,11,254,1,27,
425,11,255,1,30,2
425,11,256,1,30,1
425,11,261,4,0,
425,11,262,2,0,
425,11,263,4,0,
425,11,310,1,6,
425,11,311,2,0,
425,11,347,4,0,
425,11,360,4,0,
425,11,371,1,17,
425,11,371,4,0,
425,11,373,4,0,
425,11,432,2,0,
425,11,451,4,0,
425,11,466,1,33,
425,11,477,4,0,
425,11,496,4,0,
425,11,499,2,0,
425,11,506,1,22,
425,11,512,4,0,
425,14,15,4,0,
425,14,16,1,8,
425,14,20,3,0,
425,14,34,2,0,
425,14,50,2,0,
425,14,85,4,0,
425,14,86,4,0,
425,14,87,4,0,
425,14,92,4,0,
425,14,94,4,0,
425,14,95,2,0,
425,14,104,4,0,
425,14,107,1,1,2
425,14,114,2,0,
425,14,116,1,13,
425,14,132,1,1,1
425,14,133,1,40,
425,14,138,4,0,
425,14,148,4,0,
425,14,153,1,50,
425,14,153,4,0,
425,14,156,4,0,
425,14,164,4,0,
425,14,168,4,0,
425,14,173,3,0,
425,14,180,3,0,
425,14,182,4,0,
425,14,194,2,0,
425,14,196,3,0,
425,14,207,4,0,
425,14,213,4,0,
425,14,214,3,0,
425,14,216,4,0,
425,14,218,4,0,
425,14,220,3,0,
425,14,226,1,44,
425,14,237,4,0,
425,14,240,4,0,
425,14,241,4,0,
425,14,244,4,0,
425,14,247,1,36,
425,14,247,4,0,
425,14,254,1,25,
425,14,255,1,32,2
425,14,256,1,32,1
425,14,261,4,0,
425,14,262,2,0,
425,14,263,4,0,
425,14,271,3,0,
425,14,277,3,0,
425,14,278,3,0,
425,14,282,3,0,
425,14,285,3,0,
425,14,310,1,4,
425,14,311,2,0,
425,14,347,4,0,
425,14,360,4,0,
425,14,366,3,0,
425,14,371,1,16,
425,14,371,4,0,
425,14,373,4,0,
425,14,432,2,0,
425,14,451,4,0,
425,14,466,1,20,
425,14,477,4,0,
425,14,496,4,0,
425,14,499,2,0,
425,14,506,1,27,
425,14,512,4,0,
425,15,15,4,0,
425,15,16,1,8,
425,15,34,2,0,
425,15,50,2,0,
425,15,85,4,0,
425,15,86,4,0,
425,15,87,4,0,
425,15,92,4,0,
425,15,94,4,0,
425,15,95,2,0,
425,15,104,4,0,
425,15,107,1,1,2
425,15,114,2,0,
425,15,116,1,13,
425,15,132,1,1,1
425,15,133,1,40,
425,15,138,4,0,
425,15,148,4,0,
425,15,153,1,50,
425,15,153,4,0,
425,15,156,4,0,
425,15,164,4,0,
425,15,168,4,0,
425,15,182,4,0,
425,15,194,2,0,
425,15,207,4,0,
425,15,213,4,0,
425,15,214,4,0,
425,15,216,4,0,
425,15,218,4,0,
425,15,226,1,44,
425,15,237,4,0,
425,15,240,4,0,
425,15,241,4,0,
425,15,244,4,0,
425,15,247,1,36,
425,15,247,4,0,
425,15,254,1,25,
425,15,255,1,32,2
425,15,256,1,32,1
425,15,261,4,0,
425,15,262,2,0,
425,15,263,4,0,
425,15,310,1,4,
425,15,311,2,0,
425,15,347,4,0,
425,15,360,4,0,
425,15,366,2,0,
425,15,371,1,16,
425,15,371,4,0,
425,15,373,4,0,
425,15,432,2,0,
425,15,451,4,0,
425,15,466,1,20,
425,15,496,4,0,
425,15,499,2,0,
425,15,506,1,27,
425,15,512,4,0,
425,15,590,4,0,
425,16,15,4,0,
425,16,16,1,8,1
425,16,20,3,0,
425,16,34,2,0,
425,16,50,2,0,
425,16,85,4,0,
425,16,86,4,0,
425,16,87,4,0,
425,16,92,4,0,
425,16,94,4,0,
425,16,95,2,0,
425,16,104,4,0,
425,16,107,1,1,2
425,16,114,2,0,
425,16,116,1,13,1
425,16,132,1,1,1
425,16,133,1,40,1
425,16,138,4,0,
425,16,148,4,0,
425,16,153,1,50,1
425,16,153,4,0,
425,16,156,4,0,
425,16,164,4,0,
425,16,168,4,0,
425,16,173,3,0,
425,16,180,3,0,
425,16,182,4,0,
425,16,194,2,0,
425,16,196,3,0,
425,16,207,4,0,
425,16,213,4,0,
425,16,214,4,0,
425,16,216,4,0,
425,16,218,4,0,
425,16,220,3,0,
425,16,226,1,44,1
425,16,237,4,0,
425,16,240,4,0,
425,16,241,4,0,
425,16,244,4,0,
425,16,247,1,36,1
425,16,247,4,0,
425,16,254,1,25,1
425,16,255,1,32,2
425,16,256,1,32,1
425,16,261,4,0,
425,16,262,2,0,
425,16,263,4,0,
425,16,271,3,0,
425,16,277,3,0,
425,16,278,3,0,
425,16,282,3,0,
425,16,285,3,0,
425,16,290,4,0,
425,16,310,1,4,1
425,16,311,2,0,
425,16,347,4,0,
425,16,351,3,0,
425,16,360,4,0,
425,16,366,2,0,
425,16,366,3,0,
425,16,371,1,16,1
425,16,371,4,0,
425,16,373,4,0,
425,16,432,2,0,
425,16,451,4,0,
425,16,466,1,20,1
425,16,496,4,0,
425,16,499,2,0,
425,16,506,1,27,1
425,16,512,4,0,
425,16,590,4,0,
425,17,16,1,8,
425,17,34,2,0,
425,17,50,2,0,
425,17,85,4,0,
425,17,86,4,0,
425,17,87,4,0,
425,17,92,4,0,
425,17,94,4,0,
425,17,95,2,0,
425,17,104,4,0,
425,17,107,1,1,2
425,17,114,2,0,
425,17,116,1,13,
425,17,132,1,1,1
425,17,133,1,40,
425,17,138,4,0,
425,17,153,1,50,
425,17,153,4,0,
425,17,156,4,0,
425,17,164,4,0,
425,17,168,4,0,
425,17,182,4,0,
425,17,194,2,0,
425,17,207,4,0,
425,17,213,4,0,
425,17,214,4,0,
425,17,216,4,0,
425,17,218,4,0,
425,17,226,1,44,
425,17,237,4,0,
425,17,240,4,0,
425,17,241,4,0,
425,17,244,4,0,
425,17,247,1,36,
425,17,247,4,0,
425,17,254,1,25,
425,17,255,1,32,2
425,17,256,1,32,1
425,17,261,4,0,
425,17,262,2,0,
425,17,263,4,0,
425,17,310,1,4,
425,17,311,2,0,
425,17,347,4,0,
425,17,360,4,0,
425,17,366,2,0,
425,17,371,1,16,
425,17,371,4,0,
425,17,373,4,0,
425,17,432,2,0,
425,17,451,4,0,
425,17,466,1,20,
425,17,496,4,0,
425,17,499,2,0,
425,17,506,1,27,
425,17,512,4,0,
425,17,590,4,0,
425,18,16,1,8,
425,18,34,2,0,
425,18,50,2,0,
425,18,85,4,0,
425,18,86,4,0,
425,18,87,4,0,
425,18,92,4,0,
425,18,94,4,0,
425,18,95,2,0,
425,18,104,4,0,
425,18,107,1,1,2
425,18,114,2,0,
425,18,116,1,13,
425,18,132,1,1,1
425,18,133,1,40,
425,18,138,4,0,
425,18,153,1,50,
425,18,153,4,0,
425,18,156,4,0,
425,18,164,4,0,
425,18,168,4,0,
425,18,182,4,0,
425,18,194,2,0,
425,18,207,4,0,
425,18,213,4,0,
425,18,214,4,0,
425,18,216,4,0,
425,18,218,4,0,
425,18,226,1,44,
425,18,237,4,0,
425,18,240,4,0,
425,18,241,4,0,
425,18,244,4,0,
425,18,247,1,36,
425,18,247,4,0,
425,18,254,1,25,
425,18,255,1,32,2
425,18,256,1,32,1
425,18,261,4,0,
425,18,262,2,0,
425,18,263,4,0,
425,18,310,1,4,
425,18,311,2,0,
425,18,347,4,0,
425,18,360,4,0,
425,18,366,2,0,
425,18,371,1,16,
425,18,371,4,0,
425,18,373,4,0,
425,18,432,2,0,
425,18,451,4,0,
425,18,466,1,20,
425,18,496,4,0,
425,18,499,2,0,
425,18,506,1,27,
425,18,512,4,0,
425,18,590,4,0,
426,8,15,4,0,
426,8,16,1,1,4
426,8,16,1,11,
426,8,19,4,0,
426,8,63,4,0,
426,8,85,4,0,
426,8,86,4,0,
426,8,87,4,0,
426,8,92,4,0,
426,8,94,4,0,
426,8,104,4,0,
426,8,107,1,1,2
426,8,116,1,14,
426,8,132,1,1,1
426,8,138,4,0,
426,8,148,4,0,
426,8,153,1,51,
426,8,153,4,0,
426,8,156,4,0,
426,8,164,4,0,
426,8,168,4,0,
426,8,182,4,0,
426,8,203,4,0,
426,8,207,4,0,
426,8,213,4,0,
426,8,214,4,0,
426,8,216,4,0,
426,8,218,4,0,
426,8,226,1,37,
426,8,237,4,0,
426,8,240,4,0,
426,8,241,4,0,
426,8,244,4,0,
426,8,247,1,44,
426,8,247,4,0,
426,8,254,1,22,
426,8,255,1,27,2
426,8,256,1,27,1
426,8,261,4,0,
426,8,263,4,0,
426,8,278,4,0,
426,8,285,4,0,
426,8,290,4,0,
426,8,310,1,1,3
426,8,310,1,6,
426,8,318,4,0,
426,8,347,4,0,
426,8,351,4,0,
426,8,360,4,0,
426,8,363,4,0,
426,8,371,1,17,
426,8,371,4,0,
426,8,373,4,0,
426,8,416,4,0,
426,8,432,4,0,
426,8,445,4,0,
426,8,451,4,0,
426,8,466,1,32,
426,9,15,4,0,
426,9,16,1,1,4
426,9,16,1,11,
426,9,19,4,0,
426,9,63,4,0,
426,9,85,4,0,
426,9,86,4,0,
426,9,87,4,0,
426,9,92,4,0,
426,9,94,4,0,
426,9,104,4,0,
426,9,107,1,1,2
426,9,116,1,14,
426,9,129,3,0,
426,9,132,1,1,1
426,9,138,4,0,
426,9,148,4,0,
426,9,153,1,51,
426,9,153,4,0,
426,9,156,4,0,
426,9,164,4,0,
426,9,168,4,0,
426,9,173,3,0,
426,9,180,3,0,
426,9,182,4,0,
426,9,189,3,0,
426,9,196,3,0,
426,9,203,4,0,
426,9,205,3,0,
426,9,207,4,0,
426,9,213,4,0,
426,9,214,4,0,
426,9,216,4,0,
426,9,218,4,0,
426,9,226,1,37,
426,9,237,4,0,
426,9,240,4,0,
426,9,241,4,0,
426,9,244,4,0,
426,9,247,1,44,
426,9,247,4,0,
426,9,254,1,22,
426,9,255,1,27,2
426,9,256,1,27,1
426,9,261,4,0,
426,9,263,4,0,
426,9,271,3,0,
426,9,278,4,0,
426,9,282,3,0,
426,9,285,4,0,
426,9,290,4,0,
426,9,310,1,1,3
426,9,310,1,6,
426,9,314,3,0,
426,9,318,4,0,
426,9,347,4,0,
426,9,351,4,0,
426,9,360,4,0,
426,9,363,4,0,
426,9,371,1,17,
426,9,371,4,0,
426,9,373,4,0,
426,9,389,3,0,
426,9,416,4,0,
426,9,432,4,0,
426,9,445,4,0,
426,9,451,4,0,
426,9,466,1,32,
426,9,466,3,0,
426,10,15,4,0,
426,10,16,1,1,4
426,10,16,1,11,
426,10,19,4,0,
426,10,63,4,0,
426,10,85,4,0,
426,10,86,4,0,
426,10,87,4,0,
426,10,92,4,0,
426,10,94,4,0,
426,10,104,4,0,
426,10,107,1,1,2
426,10,116,1,14,
426,10,129,3,0,
426,10,132,1,1,1
426,10,138,4,0,
426,10,148,4,0,
426,10,153,1,51,
426,10,153,4,0,
426,10,156,4,0,
426,10,164,4,0,
426,10,168,4,0,
426,10,173,3,0,
426,10,180,3,0,
426,10,182,4,0,
426,10,189,3,0,
426,10,196,3,0,
426,10,203,4,0,
426,10,205,3,0,
426,10,207,4,0,
426,10,213,4,0,
426,10,214,4,0,
426,10,216,4,0,
426,10,218,4,0,
426,10,220,3,0,
426,10,226,1,37,
426,10,237,4,0,
426,10,240,4,0,
426,10,241,4,0,
426,10,244,4,0,
426,10,247,1,44,
426,10,247,4,0,
426,10,254,1,22,
426,10,255,1,27,2
426,10,256,1,27,1
426,10,261,4,0,
426,10,263,4,0,
426,10,271,3,0,
426,10,277,3,0,
426,10,278,4,0,
426,10,282,3,0,
426,10,285,4,0,
426,10,290,4,0,
426,10,310,1,1,3
426,10,310,1,6,
426,10,314,3,0,
426,10,318,4,0,
426,10,347,4,0,
426,10,351,4,0,
426,10,360,4,0,
426,10,363,4,0,
426,10,366,3,0,
426,10,371,1,17,
426,10,371,4,0,
426,10,373,4,0,
426,10,389,3,0,
426,10,416,4,0,
426,10,445,4,0,
426,10,451,4,0,
426,10,466,1,32,
426,10,466,3,0,
426,11,15,4,0,
426,11,16,1,1,4
426,11,16,1,11,
426,11,19,4,0,
426,11,63,4,0,
426,11,85,4,0,
426,11,86,4,0,
426,11,87,4,0,
426,11,92,4,0,
426,11,94,4,0,
426,11,104,4,0,
426,11,107,1,1,2
426,11,116,1,14,
426,11,132,1,1,1
426,11,138,4,0,
426,11,148,4,0,
426,11,153,1,56,
426,11,153,4,0,
426,11,156,4,0,
426,11,164,4,0,
426,11,168,4,0,
426,11,182,4,0,
426,11,207,4,0,
426,11,213,4,0,
426,11,216,4,0,
426,11,218,4,0,
426,11,226,1,44,
426,11,237,4,0,
426,11,240,4,0,
426,11,241,4,0,
426,11,244,4,0,
426,11,247,1,51,
426,11,247,4,0,
426,11,254,1,27,
426,11,255,1,32,2
426,11,256,1,32,1
426,11,261,4,0,
426,11,263,4,0,
426,11,310,1,1,3
426,11,310,1,6,
426,11,347,4,0,
426,11,360,4,0,
426,11,371,1,17,
426,11,371,4,0,
426,11,373,4,0,
426,11,416,4,0,
426,11,451,4,0,
426,11,466,1,37,
426,11,477,4,0,
426,11,496,4,0,
426,11,506,1,22,
426,11,512,4,0,
426,14,15,4,0,
426,14,16,1,1,4
426,14,16,1,8,
426,14,19,4,0,
426,14,20,3,0,
426,14,63,4,0,
426,14,85,4,0,
426,14,86,4,0,
426,14,87,4,0,
426,14,92,4,0,
426,14,94,4,0,
426,14,104,4,0,
426,14,107,1,1,2
426,14,116,1,13,
426,14,132,1,1,1
426,14,133,1,46,
426,14,138,4,0,
426,14,148,4,0,
426,14,153,1,60,
426,14,153,4,0,
426,14,156,4,0,
426,14,164,4,0,
426,14,168,4,0,
426,14,173,3,0,
426,14,180,3,0,
426,14,182,4,0,
426,14,196,3,0,
426,14,207,4,0,
426,14,213,4,0,
426,14,214,3,0,
426,14,216,4,0,
426,14,218,4,0,
426,14,220,3,0,
426,14,226,1,52,
426,14,237,4,0,
426,14,240,4,0,
426,14,241,4,0,
426,14,244,4,0,
426,14,247,1,40,
426,14,247,4,0,
426,14,254,1,25,
426,14,255,1,34,2
426,14,256,1,34,1
426,14,261,4,0,
426,14,263,4,0,
426,14,271,3,0,
426,14,277,3,0,
426,14,278,3,0,
426,14,282,3,0,
426,14,285,3,0,
426,14,310,1,1,3
426,14,310,1,4,
426,14,347,4,0,
426,14,360,4,0,
426,14,366,3,0,
426,14,371,1,16,
426,14,371,4,0,
426,14,373,4,0,
426,14,416,4,0,
426,14,451,4,0,
426,14,466,1,20,
426,14,477,4,0,
426,14,496,4,0,
426,14,506,1,27,
426,14,512,4,0,
426,15,15,4,0,
426,15,16,1,1,5
426,15,16,1,8,
426,15,19,4,0,
426,15,63,4,0,
426,15,85,4,0,
426,15,86,4,0,
426,15,87,4,0,
426,15,92,4,0,
426,15,94,4,0,
426,15,104,4,0,
426,15,107,1,1,3
426,15,116,1,13,
426,15,132,1,1,2
426,15,133,1,46,
426,15,138,4,0,
426,15,148,4,0,
426,15,153,1,60,
426,15,153,4,0,
426,15,156,4,0,
426,15,164,4,0,
426,15,168,4,0,
426,15,182,4,0,
426,15,207,4,0,
426,15,213,4,0,
426,15,214,4,0,
426,15,216,4,0,
426,15,218,4,0,
426,15,226,1,52,
426,15,237,4,0,
426,15,240,4,0,
426,15,241,4,0,
426,15,244,4,0,
426,15,247,1,40,
426,15,247,4,0,
426,15,254,1,25,
426,15,255,1,34,2
426,15,256,1,34,1
426,15,261,4,0,
426,15,263,4,0,
426,15,310,1,1,4
426,15,310,1,4,
426,15,347,4,0,
426,15,360,4,0,
426,15,371,1,16,
426,15,371,4,0,
426,15,373,4,0,
426,15,416,4,0,
426,15,451,4,0,
426,15,466,1,20,
426,15,496,4,0,
426,15,506,1,27,
426,15,512,4,0,
426,15,566,1,1,1
426,15,566,1,65,
426,15,590,4,0,
426,16,15,4,0,
426,16,16,1,1,5
426,16,16,1,8,1
426,16,19,4,0,
426,16,20,3,0,
426,16,63,4,0,
426,16,85,4,0,
426,16,86,4,0,
426,16,87,4,0,
426,16,92,4,0,
426,16,94,4,0,
426,16,104,4,0,
426,16,107,1,1,3
426,16,116,1,13,1
426,16,132,1,1,2
426,16,133,1,46,1
426,16,138,4,0,
426,16,148,4,0,
426,16,153,1,60,1
426,16,153,4,0,
426,16,156,4,0,
426,16,164,4,0,
426,16,168,4,0,
426,16,173,3,0,
426,16,180,3,0,
426,16,182,4,0,
426,16,196,3,0,
426,16,207,4,0,
426,16,213,4,0,
426,16,214,4,0,
426,16,216,4,0,
426,16,218,4,0,
426,16,220,3,0,
426,16,226,1,52,1
426,16,237,4,0,
426,16,240,4,0,
426,16,241,4,0,
426,16,244,4,0,
426,16,247,1,40,1
426,16,247,4,0,
426,16,254,1,25,1
426,16,255,1,34,2
426,16,256,1,34,1
426,16,261,4,0,
426,16,263,4,0,
426,16,271,3,0,
426,16,277,3,0,
426,16,278,3,0,
426,16,282,3,0,
426,16,285,3,0,
426,16,290,4,0,
426,16,310,1,1,4
426,16,310,1,4,1
426,16,347,4,0,
426,16,351,3,0,
426,16,360,4,0,
426,16,366,3,0,
426,16,371,1,16,1
426,16,371,4,0,
426,16,373,4,0,
426,16,416,4,0,
426,16,451,4,0,
426,16,466,1,20,1
426,16,496,4,0,
426,16,506,1,27,1
426,16,512,4,0,
426,16,566,1,1,1
426,16,566,1,65,1
426,16,590,4,0,
426,17,16,1,1,5
426,17,16,1,8,
426,17,19,4,0,
426,17,63,4,0,
426,17,85,4,0,
426,17,86,4,0,
426,17,87,4,0,
426,17,92,4,0,
426,17,94,4,0,
426,17,104,4,0,
426,17,107,1,1,3
426,17,116,1,13,
426,17,132,1,1,2
426,17,133,1,46,
426,17,138,4,0,
426,17,153,1,60,
426,17,153,4,0,
426,17,156,4,0,
426,17,164,4,0,
426,17,168,4,0,
426,17,182,4,0,
426,17,207,4,0,
426,17,213,4,0,
426,17,214,4,0,
426,17,216,4,0,
426,17,218,4,0,
426,17,226,1,52,
426,17,237,4,0,
426,17,240,4,0,
426,17,241,4,0,
426,17,244,4,0,
426,17,247,1,40,
426,17,247,4,0,
426,17,254,1,25,
426,17,255,1,34,2
426,17,256,1,34,1
426,17,261,4,0,
426,17,263,4,0,
426,17,310,1,1,4
426,17,310,1,4,
426,17,347,4,0,
426,17,360,4,0,
426,17,371,1,16,
426,17,371,4,0,
426,17,373,4,0,
426,17,416,4,0,
426,17,451,4,0,
426,17,466,1,20,
426,17,496,4,0,
426,17,506,1,27,
426,17,512,4,0,
426,17,566,1,1,1
426,17,566,1,65,
426,17,590,4,0,
426,18,16,1,1,5
426,18,16,1,8,
426,18,19,4,0,
426,18,63,4,0,
426,18,85,4,0,
426,18,86,4,0,
426,18,87,4,0,
426,18,92,4,0,
426,18,94,4,0,
426,18,104,4,0,
426,18,107,1,1,3
426,18,116,1,13,
426,18,132,1,1,2
426,18,133,1,46,
426,18,138,4,0,
426,18,153,1,60,
426,18,153,4,0,
426,18,156,4,0,
426,18,164,4,0,
426,18,168,4,0,
426,18,182,4,0,
426,18,207,4,0,
426,18,213,4,0,
426,18,214,4,0,
426,18,216,4,0,
426,18,218,4,0,
426,18,226,1,52,
426,18,237,4,0,
426,18,240,4,0,
426,18,241,4,0,
426,18,244,4,0,
426,18,247,1,40,
426,18,247,4,0,
426,18,254,1,25,
426,18,255,1,34,2
426,18,256,1,34,1
426,18,261,4,0,
426,18,263,4,0,
426,18,310,1,1,4
426,18,310,1,4,
426,18,347,4,0,
426,18,360,4,0,
426,18,371,1,16,
426,18,371,4,0,
426,18,373,4,0,
426,18,416,4,0,
426,18,451,4,0,
426,18,466,1,20,
426,18,496,4,0,
426,18,506,1,27,
426,18,512,4,0,
426,18,566,1,1,1
426,18,566,1,65,
426,18,590,4,0,
427,8,1,1,1,2
427,8,7,2,0,
427,8,8,2,0,
427,8,9,2,0,
427,8,15,4,0,
427,8,26,1,23,
427,8,58,4,0,
427,8,67,2,0,
427,8,76,4,0,
427,8,85,4,0,
427,8,86,4,0,
427,8,91,4,0,
427,8,92,4,0,
427,8,97,1,33,
427,8,98,1,16,
427,8,104,4,0,
427,8,111,1,1,3
427,8,146,1,36,
427,8,150,1,1,1
427,8,156,4,0,
427,8,164,4,0,
427,8,182,4,0,
427,8,186,2,0,
427,8,193,1,1,4
427,8,203,1,6,
427,8,203,4,0,
427,8,204,1,43,
427,8,207,4,0,
427,8,213,2,0,
427,8,213,4,0,
427,8,214,4,0,
427,8,216,4,0,
427,8,218,1,13,
427,8,218,4,0,
427,8,226,1,26,
427,8,227,2,0,
427,8,231,4,0,
427,8,237,4,0,
427,8,240,4,0,
427,8,241,4,0,
427,8,247,4,0,
427,8,249,4,0,
427,8,252,2,0,
427,8,263,4,0,
427,8,264,4,0,
427,8,290,4,0,
427,8,313,2,0,
427,8,327,2,0,
427,8,340,1,46,
427,8,351,4,0,
427,8,352,4,0,
427,8,361,1,53,
427,8,363,4,0,
427,8,374,4,0,
427,8,409,4,0,
427,8,415,2,0,
427,8,445,4,0,
427,8,447,4,0,
427,8,451,4,0,
427,8,458,2,0,
427,9,1,1,1,2
427,9,7,2,0,
427,9,8,2,0,
427,9,9,2,0,
427,9,15,4,0,
427,9,26,1,23,
427,9,58,4,0,
427,9,67,2,0,
427,9,76,4,0,
427,9,85,4,0,
427,9,86,4,0,
427,9,91,4,0,
427,9,92,4,0,
427,9,97,1,33,
427,9,98,1,16,
427,9,104,4,0,
427,9,111,1,1,3
427,9,129,3,0,
427,9,146,1,36,
427,9,150,1,1,1
427,9,156,4,0,
427,9,164,4,0,
427,9,173,3,0,
427,9,182,4,0,
427,9,186,2,0,
427,9,189,3,0,
427,9,193,1,1,4
427,9,203,1,6,
427,9,203,4,0,
427,9,204,1,43,
427,9,207,4,0,
427,9,213,2,0,
427,9,213,4,0,
427,9,214,4,0,
427,9,216,4,0,
427,9,218,1,13,
427,9,218,4,0,
427,9,226,1,26,
427,9,227,2,0,
427,9,231,4,0,
427,9,237,4,0,
427,9,240,4,0,
427,9,241,4,0,
427,9,247,4,0,
427,9,249,4,0,
427,9,252,2,0,
427,9,253,3,0,
427,9,263,4,0,
427,9,264,4,0,
427,9,270,3,0,
427,9,283,3,0,
427,9,290,4,0,
427,9,313,2,0,
427,9,327,2,0,
427,9,340,1,46,
427,9,340,3,0,
427,9,351,4,0,
427,9,352,4,0,
427,9,361,1,53,
427,9,363,4,0,
427,9,374,4,0,
427,9,387,3,0,
427,9,409,4,0,
427,9,415,2,0,
427,9,445,4,0,
427,9,447,4,0,
427,9,451,4,0,
427,9,458,2,0,
427,10,1,1,1,2
427,10,7,2,0,
427,10,8,2,0,
427,10,9,2,0,
427,10,15,4,0,
427,10,26,1,23,
427,10,29,3,0,
427,10,58,4,0,
427,10,67,2,0,
427,10,67,3,0,
427,10,76,4,0,
427,10,85,4,0,
427,10,86,4,0,
427,10,91,4,0,
427,10,92,4,0,
427,10,97,1,33,
427,10,98,1,16,
427,10,104,4,0,
427,10,111,1,1,3
427,10,129,3,0,
427,10,146,1,36,
427,10,150,1,1,1
427,10,156,4,0,
427,10,164,4,0,
427,10,173,3,0,
427,10,175,2,0,
427,10,182,4,0,
427,10,186,2,0,
427,10,189,3,0,
427,10,193,1,1,4
427,10,203,1,6,
427,10,203,4,0,
427,10,204,1,43,
427,10,207,4,0,
427,10,213,2,0,
427,10,213,4,0,
427,10,214,4,0,
427,10,215,3,0,
427,10,216,4,0,
427,10,218,1,13,
427,10,218,4,0,
427,10,226,1,26,
427,10,227,2,0,
427,10,231,4,0,
427,10,237,4,0,
427,10,240,4,0,
427,10,241,4,0,
427,10,247,4,0,
427,10,249,4,0,
427,10,252,2,0,
427,10,253,3,0,
427,10,263,4,0,
427,10,264,4,0,
427,10,270,3,0,
427,10,277,3,0,
427,10,283,3,0,
427,10,290,4,0,
427,10,313,2,0,
427,10,327,2,0,
427,10,340,1,46,
427,10,340,3,0,
427,10,351,4,0,
427,10,352,4,0,
427,10,361,1,53,
427,10,363,4,0,
427,10,374,4,0,
427,10,387,3,0,
427,10,409,4,0,
427,10,415,2,0,
427,10,445,4,0,
427,10,447,4,0,
427,10,451,4,0,
427,10,458,2,0,
427,11,1,1,1,2
427,11,7,2,0,
427,11,8,2,0,
427,11,9,2,0,
427,11,15,4,0,
427,11,26,1,23,
427,11,58,4,0,
427,11,67,2,0,
427,11,76,4,0,
427,11,85,4,0,
427,11,86,4,0,
427,11,91,4,0,
427,11,92,4,0,
427,11,97,1,33,
427,11,98,1,16,
427,11,104,4,0,
427,11,111,1,1,3
427,11,146,1,36,
427,11,150,1,1,1
427,11,156,4,0,
427,11,164,4,0,
427,11,175,2,0,
427,11,182,4,0,
427,11,186,2,0,
427,11,193,1,1,4
427,11,203,1,6,
427,11,204,1,46,
427,11,207,4,0,
427,11,213,4,0,
427,11,216,4,0,
427,11,218,1,13,
427,11,218,4,0,
427,11,226,1,26,
427,11,227,2,0,
427,11,237,4,0,
427,11,240,4,0,
427,11,241,4,0,
427,11,247,4,0,
427,11,249,4,0,
427,11,252,2,0,
427,11,263,4,0,
427,11,264,2,0,
427,11,313,2,0,
427,11,327,2,0,
427,11,340,1,56,
427,11,361,1,63,
427,11,374,4,0,
427,11,383,2,0,
427,11,415,2,0,
427,11,447,4,0,
427,11,451,4,0,
427,11,458,2,0,
427,11,494,1,53,
427,11,495,1,43,
427,11,496,4,0,
427,11,509,2,0,
427,11,514,4,0,
427,11,526,4,0,
427,14,1,1,1,2
427,14,7,2,0,
427,14,7,3,0,
427,14,8,2,0,
427,14,8,3,0,
427,14,9,2,0,
427,14,9,3,0,
427,14,15,4,0,
427,14,26,1,23,
427,14,58,4,0,
427,14,67,2,0,
427,14,67,3,0,
427,14,76,4,0,
427,14,85,4,0,
427,14,86,4,0,
427,14,91,4,0,
427,14,92,4,0,
427,14,97,1,33,
427,14,98,1,16,
427,14,104,4,0,
427,14,111,1,1,3
427,14,146,1,36,
427,14,150,1,1,1
427,14,156,4,0,
427,14,164,4,0,
427,14,173,3,0,
427,14,175,2,0,
427,14,182,4,0,
427,14,186,2,0,
427,14,193,1,1,4
427,14,203,1,6,
427,14,204,1,46,
427,14,207,4,0,
427,14,213,4,0,
427,14,214,3,0,
427,14,215,3,0,
427,14,216,4,0,
427,14,218,1,13,
427,14,218,4,0,
427,14,226,1,26,
427,14,227,2,0,
427,14,231,3,0,
427,14,237,4,0,
427,14,240,4,0,
427,14,241,4,0,
427,14,247,4,0,
427,14,249,4,0,
427,14,252,2,0,
427,14,253,3,0,
427,14,263,4,0,
427,14,264,2,0,
427,14,270,3,0,
427,14,277,3,0,
427,14,283,3,0,
427,14,304,3,0,
427,14,313,2,0,
427,14,327,2,0,
427,14,340,1,56,
427,14,340,3,0,
427,14,343,3,0,
427,14,361,1,63,
427,14,374,4,0,
427,14,383,2,0,
427,14,387,3,0,
427,14,409,3,0,
427,14,415,2,0,
427,14,447,4,0,
427,14,451,4,0,
427,14,458,2,0,
427,14,494,1,53,
427,14,495,1,43,
427,14,495,3,0,
427,14,496,4,0,
427,14,509,2,0,
427,14,514,4,0,
427,14,526,4,0,
427,15,1,1,1,3
427,15,7,2,0,
427,15,8,2,0,
427,15,9,2,0,
427,15,15,4,0,
427,15,26,1,23,
427,15,58,4,0,
427,15,67,2,0,
427,15,76,4,0,
427,15,85,4,0,
427,15,86,4,0,
427,15,91,4,0,
427,15,92,4,0,
427,15,97,1,33,
427,15,98,1,16,
427,15,104,4,0,
427,15,111,1,1,1
427,15,146,1,36,
427,15,150,1,1,2
427,15,156,4,0,
427,15,164,4,0,
427,15,175,2,0,
427,15,182,4,0,
427,15,186,2,0,
427,15,193,1,1,4
427,15,203,1,6,
427,15,204,1,46,
427,15,207,4,0,
427,15,213,4,0,
427,15,214,4,0,
427,15,216,4,0,
427,15,218,1,13,
427,15,218,4,0,
427,15,226,1,26,
427,15,227,2,0,
427,15,237,4,0,
427,15,240,4,0,
427,15,241,4,0,
427,15,247,4,0,
427,15,249,4,0,
427,15,252,2,0,
427,15,263,4,0,
427,15,264,2,0,
427,15,298,2,0,
427,15,300,2,0,
427,15,313,2,0,
427,15,322,2,0,
427,15,327,2,0,
427,15,340,1,56,
427,15,361,1,63,
427,15,374,4,0,
427,15,383,2,0,
427,15,415,2,0,
427,15,447,4,0,
427,15,451,4,0,
427,15,458,2,0,
427,15,494,1,50,
427,15,495,1,43,
427,15,496,4,0,
427,15,509,2,0,
427,15,514,4,0,
427,15,590,4,0,
427,15,608,1,10,
427,15,612,4,0,
427,16,1,1,1,3
427,16,7,2,0,
427,16,7,3,0,
427,16,8,2,0,
427,16,8,3,0,
427,16,9,2,0,
427,16,9,3,0,
427,16,15,4,0,
427,16,26,1,23,1
427,16,58,4,0,
427,16,67,2,0,
427,16,67,3,0,
427,16,76,4,0,
427,16,85,4,0,
427,16,86,4,0,
427,16,91,4,0,
427,16,92,4,0,
427,16,97,1,33,1
427,16,98,1,16,1
427,16,104,4,0,
427,16,111,1,1,1
427,16,146,1,36,1
427,16,150,1,1,2
427,16,156,4,0,
427,16,164,4,0,
427,16,173,3,0,
427,16,175,2,0,
427,16,182,4,0,
427,16,186,2,0,
427,16,193,1,1,4
427,16,203,1,6,1
427,16,204,1,46,1
427,16,207,4,0,
427,16,213,4,0,
427,16,214,4,0,
427,16,215,3,0,
427,16,216,4,0,
427,16,218,1,13,1
427,16,218,4,0,
427,16,226,1,26,1
427,16,227,2,0,
427,16,231,3,0,
427,16,237,4,0,
427,16,240,4,0,
427,16,241,4,0,
427,16,247,4,0,
427,16,249,4,0,
427,16,252,2,0,
427,16,253,3,0,
427,16,263,4,0,
427,16,264,2,0,
427,16,264,3,0,
427,16,270,3,0,
427,16,277,3,0,
427,16,283,3,0,
427,16,290,4,0,
427,16,298,2,0,
427,16,300,2,0,
427,16,304,3,0,
427,16,313,2,0,
427,16,322,2,0,
427,16,327,2,0,
427,16,340,1,56,1
427,16,340,3,0,
427,16,343,3,0,
427,16,351,3,0,
427,16,352,3,0,
427,16,361,1,63,1
427,16,374,4,0,
427,16,383,2,0,
427,16,387,3,0,
427,16,409,3,0,
427,16,415,2,0,
427,16,447,4,0,
427,16,451,4,0,
427,16,458,2,0,
427,16,494,1,50,1
427,16,495,1,43,1
427,16,495,3,0,
427,16,496,4,0,
427,16,509,2,0,
427,16,514,4,0,
427,16,590,4,0,
427,16,608,1,10,1
427,16,612,4,0,
427,17,1,1,1,4
427,17,7,2,0,
427,17,8,2,0,
427,17,9,2,0,
427,17,26,1,23,
427,17,58,4,0,
427,17,67,2,0,
427,17,76,4,0,
427,17,85,4,0,
427,17,86,4,0,
427,17,92,4,0,
427,17,97,1,33,
427,17,98,1,16,
427,17,104,4,0,
427,17,111,1,1,2
427,17,146,1,36,
427,17,150,1,1,3
427,17,156,4,0,
427,17,164,4,0,
427,17,175,2,0,
427,17,182,4,0,
427,17,186,2,0,
427,17,193,1,1,5
427,17,203,1,6,
427,17,204,1,46,
427,17,207,4,0,
427,17,213,4,0,
427,17,214,4,0,
427,17,216,4,0,
427,17,218,1,1,1
427,17,218,4,0,
427,17,226,1,26,
427,17,227,2,0,
427,17,237,4,0,
427,17,240,4,0,
427,17,241,4,0,
427,17,247,4,0,
427,17,252,2,0,
427,17,263,4,0,
427,17,264,2,0,
427,17,298,2,0,
427,17,300,2,0,
427,17,313,2,0,
427,17,322,2,0,
427,17,327,2,0,
427,17,340,1,56,
427,17,361,1,63,
427,17,374,4,0,
427,17,383,2,0,
427,17,415,2,0,
427,17,447,4,0,
427,17,451,4,0,
427,17,458,2,0,
427,17,494,1,50,
427,17,495,1,43,
427,17,496,4,0,
427,17,509,2,0,
427,17,526,4,0,
427,17,590,4,0,
427,17,608,1,13,
427,18,1,1,1,4
427,18,7,2,0,
427,18,8,2,0,
427,18,9,2,0,
427,18,26,1,23,
427,18,58,4,0,
427,18,67,2,0,
427,18,76,4,0,
427,18,85,4,0,
427,18,86,4,0,
427,18,92,4,0,
427,18,97,1,33,
427,18,98,1,16,
427,18,104,4,0,
427,18,111,1,1,2
427,18,146,1,36,
427,18,150,1,1,3
427,18,156,4,0,
427,18,164,4,0,
427,18,175,2,0,
427,18,182,4,0,
427,18,186,2,0,
427,18,193,1,1,5
427,18,203,1,6,
427,18,204,1,46,
427,18,207,4,0,
427,18,213,4,0,
427,18,214,4,0,
427,18,216,4,0,
427,18,218,1,1,1
427,18,218,4,0,
427,18,226,1,26,
427,18,227,2,0,
427,18,237,4,0,
427,18,240,4,0,
427,18,241,4,0,
427,18,247,4,0,
427,18,252,2,0,
427,18,263,4,0,
427,18,264,2,0,
427,18,298,2,0,
427,18,300,2,0,
427,18,313,2,0,
427,18,322,2,0,
427,18,327,2,0,
427,18,340,1,56,
427,18,361,1,63,
427,18,374,4,0,
427,18,383,2,0,
427,18,415,2,0,
427,18,447,4,0,
427,18,451,4,0,
427,18,458,2,0,
427,18,494,1,50,
427,18,495,1,43,
427,18,496,4,0,
427,18,509,2,0,
427,18,526,4,0,
427,18,590,4,0,
427,18,608,1,13,
427,18,612,2,0,
428,8,1,1,1,4
428,8,15,4,0,
428,8,26,1,23,
428,8,58,4,0,
428,8,59,4,0,
428,8,63,4,0,
428,8,70,4,0,
428,8,76,4,0,
428,8,85,4,0,
428,8,86,4,0,
428,8,87,4,0,
428,8,91,4,0,
428,8,92,4,0,
428,8,97,1,33,
428,8,98,1,16,
428,8,104,4,0,
428,8,111,1,1,5
428,8,146,1,36,
428,8,150,1,1,3
428,8,156,4,0,
428,8,164,4,0,
428,8,182,4,0,
428,8,193,1,1,6
428,8,203,1,6,
428,8,203,4,0,
428,8,204,1,43,
428,8,207,4,0,
428,8,213,4,0,
428,8,214,4,0,
428,8,216,1,13,
428,8,216,4,0,
428,8,218,4,0,
428,8,226,1,26,
428,8,231,4,0,
428,8,237,4,0,
428,8,240,4,0,
428,8,241,4,0,
428,8,243,1,1,1
428,8,247,4,0,
428,8,249,4,0,
428,8,263,4,0,
428,8,264,4,0,
428,8,277,1,1,2
428,8,290,4,0,
428,8,340,1,46,
428,8,351,4,0,
428,8,352,4,0,
428,8,361,1,53,
428,8,363,4,0,
428,8,374,4,0,
428,8,409,4,0,
428,8,411,4,0,
428,8,416,4,0,
428,8,445,4,0,
428,8,447,4,0,
428,8,451,4,0,
428,9,1,1,1,4
428,9,7,3,0,
428,9,8,3,0,
428,9,9,3,0,
428,9,15,4,0,
428,9,26,1,23,
428,9,58,4,0,
428,9,59,4,0,
428,9,63,4,0,
428,9,70,4,0,
428,9,76,4,0,
428,9,85,4,0,
428,9,86,4,0,
428,9,87,4,0,
428,9,91,4,0,
428,9,92,4,0,
428,9,97,1,33,
428,9,98,1,16,
428,9,104,4,0,
428,9,111,1,1,5
428,9,129,3,0,
428,9,146,1,36,
428,9,150,1,1,3
428,9,156,4,0,
428,9,164,4,0,
428,9,173,3,0,
428,9,182,4,0,
428,9,189,3,0,
428,9,193,1,1,6
428,9,203,1,6,
428,9,203,4,0,
428,9,204,1,43,
428,9,207,4,0,
428,9,210,3,0,
428,9,213,4,0,
428,9,214,4,0,
428,9,216,1,13,
428,9,216,4,0,
428,9,218,4,0,
428,9,226,1,26,
428,9,231,4,0,
428,9,237,4,0,
428,9,240,4,0,
428,9,241,4,0,
428,9,243,1,1,1
428,9,247,4,0,
428,9,249,4,0,
428,9,253,3,0,
428,9,263,4,0,
428,9,264,4,0,
428,9,270,3,0,
428,9,277,1,1,2
428,9,283,3,0,
428,9,290,4,0,
428,9,340,1,46,
428,9,340,3,0,
428,9,351,4,0,
428,9,352,4,0,
428,9,361,1,53,
428,9,363,4,0,
428,9,374,4,0,
428,9,387,3,0,
428,9,409,4,0,
428,9,411,4,0,
428,9,416,4,0,
428,9,445,4,0,
428,9,447,4,0,
428,9,451,4,0,
428,10,1,1,1,4
428,10,7,3,0,
428,10,8,3,0,
428,10,9,3,0,
428,10,15,4,0,
428,10,26,1,23,
428,10,29,3,0,
428,10,58,4,0,
428,10,59,4,0,
428,10,63,4,0,
428,10,67,3,0,
428,10,70,4,0,
428,10,76,4,0,
428,10,85,4,0,
428,10,86,4,0,
428,10,87,4,0,
428,10,91,4,0,
428,10,92,4,0,
428,10,97,1,33,
428,10,98,1,16,
428,10,104,4,0,
428,10,111,1,1,5
428,10,129,3,0,
428,10,146,1,36,
428,10,150,1,1,3
428,10,156,4,0,
428,10,164,4,0,
428,10,173,3,0,
428,10,182,4,0,
428,10,189,3,0,
428,10,193,1,1,6
428,10,203,1,6,
428,10,203,4,0,
428,10,204,1,43,
428,10,207,4,0,
428,10,210,3,0,
428,10,213,4,0,
428,10,214,4,0,
428,10,215,3,0,
428,10,216,1,13,
428,10,216,4,0,
428,10,218,4,0,
428,10,226,1,26,
428,10,231,4,0,
428,10,237,4,0,
428,10,240,4,0,
428,10,241,4,0,
428,10,243,1,1,1
428,10,247,4,0,
428,10,249,4,0,
428,10,253,3,0,
428,10,263,4,0,
428,10,264,4,0,
428,10,270,3,0,
428,10,277,1,1,2
428,10,277,3,0,
428,10,283,3,0,
428,10,290,4,0,
428,10,340,1,46,
428,10,340,3,0,
428,10,351,4,0,
428,10,352,4,0,
428,10,361,1,53,
428,10,363,4,0,
428,10,374,4,0,
428,10,387,3,0,
428,10,409,4,0,
428,10,411,4,0,
428,10,416,4,0,
428,10,445,4,0,
428,10,447,4,0,
428,10,451,4,0,
428,11,1,1,1,4
428,11,15,4,0,
428,11,26,1,23,
428,11,58,4,0,
428,11,59,4,0,
428,11,63,4,0,
428,11,70,4,0,
428,11,76,4,0,
428,11,85,4,0,
428,11,86,4,0,
428,11,87,4,0,
428,11,91,4,0,
428,11,92,4,0,
428,11,97,1,33,
428,11,98,1,16,
428,11,104,4,0,
428,11,111,1,1,5
428,11,146,1,36,
428,11,150,1,1,3
428,11,156,4,0,
428,11,164,4,0,
428,11,182,4,0,
428,11,193,1,1,6
428,11,203,1,6,
428,11,204,1,46,
428,11,207,4,0,
428,11,213,4,0,
428,11,216,1,13,
428,11,216,4,0,
428,11,218,4,0,
428,11,226,1,26,
428,11,237,4,0,
428,11,240,4,0,
428,11,241,4,0,
428,11,243,1,1,1
428,11,247,4,0,
428,11,249,4,0,
428,11,263,4,0,
428,11,277,1,1,2
428,11,340,1,56,
428,11,361,1,63,
428,11,374,4,0,
428,11,411,4,0,
428,11,416,4,0,
428,11,447,4,0,
428,11,451,4,0,
428,11,490,4,0,
428,11,494,1,53,
428,11,495,1,43,
428,11,496,4,0,
428,11,514,4,0,
428,11,526,4,0,
428,14,1,1,1,4
428,14,7,3,0,
428,14,8,3,0,
428,14,9,3,0,
428,14,15,4,0,
428,14,26,1,23,
428,14,58,4,0,
428,14,59,4,0,
428,14,63,4,0,
428,14,67,3,0,
428,14,70,4,0,
428,14,76,4,0,
428,14,85,4,0,
428,14,86,4,0,
428,14,87,4,0,
428,14,91,4,0,
428,14,92,4,0,
428,14,97,1,33,
428,14,98,1,16,
428,14,104,4,0,
428,14,111,1,1,5
428,14,146,1,36,
428,14,150,1,1,3
428,14,156,4,0,
428,14,164,4,0,
428,14,173,3,0,
428,14,182,4,0,
428,14,193,1,1,6
428,14,203,1,6,
428,14,204,1,46,
428,14,207,4,0,
428,14,213,4,0,
428,14,214,3,0,
428,14,215,3,0,
428,14,216,1,13,
428,14,216,4,0,
428,14,218,4,0,
428,14,226,1,26,
428,14,231,3,0,
428,14,237,4,0,
428,14,240,4,0,
428,14,241,4,0,
428,14,243,1,1,1
428,14,247,4,0,
428,14,249,4,0,
428,14,253,3,0,
428,14,263,4,0,
428,14,270,3,0,
428,14,277,1,1,2
428,14,277,3,0,
428,14,283,3,0,
428,14,304,3,0,
428,14,340,1,56,
428,14,340,3,0,
428,14,343,3,0,
428,14,361,1,63,
428,14,374,4,0,
428,14,387,3,0,
428,14,409,3,0,
428,14,411,4,0,
428,14,416,4,0,
428,14,447,4,0,
428,14,451,4,0,
428,14,490,4,0,
428,14,494,1,53,
428,14,495,1,43,
428,14,495,3,0,
428,14,496,4,0,
428,14,514,4,0,
428,14,526,4,0,
428,15,1,1,1,8
428,15,15,4,0,
428,15,26,1,23,
428,15,58,4,0,
428,15,59,4,0,
428,15,63,4,0,
428,15,70,4,0,
428,15,76,4,0,
428,15,85,4,0,
428,15,86,4,0,
428,15,87,4,0,
428,15,91,4,0,
428,15,92,4,0,
428,15,97,1,33,
428,15,98,1,16,
428,15,104,4,0,
428,15,111,1,1,6
428,15,146,1,36,
428,15,150,1,1,7
428,15,156,4,0,
428,15,164,4,0,
428,15,182,4,0,
428,15,193,1,1,9
428,15,203,1,6,
428,15,204,1,46,
428,15,207,4,0,
428,15,213,4,0,
428,15,214,4,0,
428,15,216,1,13,
428,15,216,4,0,
428,15,218,4,0,
428,15,226,1,26,
428,15,237,4,0,
428,15,240,4,0,
428,15,241,4,0,
428,15,243,1,1,4
428,15,247,4,0,
428,15,249,4,0,
428,15,263,4,0,
428,15,277,1,1,5
428,15,340,1,1,2
428,15,340,1,56,
428,15,361,1,1,1
428,15,361,1,63,
428,15,374,4,0,
428,15,411,4,0,
428,15,416,4,0,
428,15,447,4,0,
428,15,451,4,0,
428,15,490,4,0,
428,15,494,1,53,
428,15,495,1,43,
428,15,496,4,0,
428,15,514,4,0,
428,15,563,1,1,3
428,15,590,4,0,
428,15,612,4,0,
428,16,1,1,1,8
428,16,7,3,0,
428,16,8,3,0,
428,16,9,3,0,
428,16,15,4,0,
428,16,26,1,23,1
428,16,58,4,0,
428,16,59,4,0,
428,16,63,4,0,
428,16,67,3,0,
428,16,70,4,0,
428,16,76,4,0,
428,16,85,4,0,
428,16,86,4,0,
428,16,87,4,0,
428,16,91,4,0,
428,16,92,4,0,
428,16,97,1,33,1
428,16,98,1,16,1
428,16,104,4,0,
428,16,111,1,1,6
428,16,136,1,66,1
428,16,146,1,36,1
428,16,150,1,1,7
428,16,156,4,0,
428,16,164,4,0,
428,16,173,3,0,
428,16,182,4,0,
428,16,193,1,1,9
428,16,203,1,6,1
428,16,204,1,46,1
428,16,207,4,0,
428,16,213,4,0,
428,16,214,4,0,
428,16,215,3,0,
428,16,216,1,13,1
428,16,216,4,0,
428,16,218,4,0,
428,16,226,1,26,1
428,16,231,3,0,
428,16,237,4,0,
428,16,240,4,0,
428,16,241,4,0,
428,16,243,1,1,4
428,16,247,4,0,
428,16,249,4,0,
428,16,253,3,0,
428,16,263,4,0,
428,16,264,3,0,
428,16,270,3,0,
428,16,277,1,1,5
428,16,277,3,0,
428,16,283,3,0,
428,16,290,4,0,
428,16,304,3,0,
428,16,340,1,1,2
428,16,340,1,56,1
428,16,340,3,0,
428,16,343,3,0,
428,16,351,3,0,
428,16,352,3,0,
428,16,361,1,1,1
428,16,361,1,63,1
428,16,374,4,0,
428,16,387,3,0,
428,16,409,3,0,
428,16,411,4,0,
428,16,416,4,0,
428,16,447,4,0,
428,16,451,4,0,
428,16,490,4,0,
428,16,494,1,53,1
428,16,495,1,43,1
428,16,495,3,0,
428,16,496,4,0,
428,16,514,4,0,
428,16,563,1,1,3
428,16,590,4,0,
428,16,612,4,0,
428,17,1,1,1,9
428,17,26,1,23,
428,17,58,4,0,
428,17,59,4,0,
428,17,63,4,0,
428,17,76,4,0,
428,17,85,4,0,
428,17,86,4,0,
428,17,87,4,0,
428,17,92,4,0,
428,17,97,1,33,
428,17,98,1,16,
428,17,104,4,0,
428,17,111,1,1,7
428,17,136,1,66,
428,17,146,1,36,
428,17,150,1,1,8
428,17,156,4,0,
428,17,164,4,0,
428,17,182,4,0,
428,17,193,1,1,10
428,17,203,1,6,
428,17,204,1,46,
428,17,207,4,0,
428,17,213,4,0,
428,17,214,4,0,
428,17,216,1,0,
428,17,216,1,1,1
428,17,216,4,0,
428,17,218,4,0,
428,17,226,1,26,
428,17,237,4,0,
428,17,240,4,0,
428,17,241,4,0,
428,17,243,1,1,5
428,17,247,4,0,
428,17,263,4,0,
428,17,277,1,1,6
428,17,340,1,1,3
428,17,340,1,56,
428,17,361,1,1,2
428,17,361,1,63,
428,17,374,4,0,
428,17,411,4,0,
428,17,416,4,0,
428,17,447,4,0,
428,17,451,4,0,
428,17,490,4,0,
428,17,494,1,53,
428,17,495,1,43,
428,17,496,4,0,
428,17,526,4,0,
428,17,563,1,1,4
428,17,590,4,0,
428,17,608,1,13,
428,17,693,4,0,
428,18,1,1,1,9
428,18,26,1,23,
428,18,58,4,0,
428,18,59,4,0,
428,18,63,4,0,
428,18,76,4,0,
428,18,85,4,0,
428,18,86,4,0,
428,18,87,4,0,
428,18,92,4,0,
428,18,97,1,33,
428,18,98,1,16,
428,18,104,4,0,
428,18,111,1,1,7
428,18,136,1,66,
428,18,146,1,36,
428,18,150,1,1,8
428,18,156,4,0,
428,18,164,4,0,
428,18,182,4,0,
428,18,193,1,1,10
428,18,203,1,6,
428,18,204,1,46,
428,18,207,4,0,
428,18,213,4,0,
428,18,214,4,0,
428,18,216,1,0,
428,18,216,1,1,1
428,18,216,4,0,
428,18,218,4,0,
428,18,226,1,26,
428,18,237,4,0,
428,18,240,4,0,
428,18,241,4,0,
428,18,243,1,1,5
428,18,247,4,0,
428,18,263,4,0,
428,18,277,1,1,6
428,18,340,1,1,3
428,18,340,1,56,
428,18,361,1,1,2
428,18,361,1,63,
428,18,374,4,0,
428,18,411,4,0,
428,18,416,4,0,
428,18,447,4,0,
428,18,451,4,0,
428,18,490,4,0,
428,18,494,1,53,
428,18,495,1,43,
428,18,496,4,0,
428,18,526,4,0,
428,18,563,1,1,4
428,18,590,4,0,
428,18,608,1,13,
428,18,693,4,0,
429,8,45,1,1,3
429,8,63,4,0,
429,8,85,4,0,
429,8,86,4,0,
429,8,87,4,0,
429,8,92,4,0,
429,8,94,4,0,
429,8,104,4,0,
429,8,138,4,0,
429,8,148,4,0,
429,8,149,1,1,4
429,8,156,4,0,
429,8,164,4,0,
429,8,168,4,0,
429,8,180,1,1,5
429,8,182,4,0,
429,8,203,4,0,
429,8,207,4,0,
429,8,213,4,0,
429,8,214,4,0,
429,8,216,4,0,
429,8,218,4,0,
429,8,237,4,0,
429,8,240,4,0,
429,8,241,4,0,
429,8,244,4,0,
429,8,247,4,0,
429,8,259,4,0,
429,8,261,4,0,
429,8,263,4,0,
429,8,269,4,0,
429,8,285,4,0,
429,8,289,4,0,
429,8,290,4,0,
429,8,310,1,1,6
429,8,332,4,0,
429,8,345,1,1,2
429,8,347,4,0,
429,8,351,4,0,
429,8,363,4,0,
429,8,371,4,0,
429,8,373,4,0,
429,8,381,1,1,1
429,8,399,4,0,
429,8,412,4,0,
429,8,416,4,0,
429,8,433,4,0,
429,8,445,4,0,
429,8,451,4,0,
429,9,45,1,1,3
429,9,63,4,0,
429,9,85,4,0,
429,9,86,4,0,
429,9,87,4,0,
429,9,92,4,0,
429,9,94,4,0,
429,9,104,4,0,
429,9,129,3,0,
429,9,138,4,0,
429,9,148,4,0,
429,9,149,1,1,4
429,9,156,4,0,
429,9,164,4,0,
429,9,168,4,0,
429,9,173,3,0,
429,9,180,1,1,5
429,9,180,3,0,
429,9,182,4,0,
429,9,196,3,0,
429,9,203,4,0,
429,9,207,4,0,
429,9,213,4,0,
429,9,214,4,0,
429,9,216,4,0,
429,9,218,4,0,
429,9,237,4,0,
429,9,240,4,0,
429,9,241,4,0,
429,9,244,4,0,
429,9,247,4,0,
429,9,253,3,0,
429,9,259,4,0,
429,9,261,4,0,
429,9,263,4,0,
429,9,269,4,0,
429,9,271,3,0,
429,9,285,4,0,
429,9,289,4,0,
429,9,290,4,0,
429,9,310,1,1,6
429,9,332,4,0,
429,9,345,1,1,2
429,9,347,4,0,
429,9,351,4,0,
429,9,363,4,0,
429,9,371,4,0,
429,9,373,4,0,
429,9,381,1,1,1
429,9,389,3,0,
429,9,399,4,0,
429,9,412,4,0,
429,9,416,4,0,
429,9,433,4,0,
429,9,445,4,0,
429,9,451,4,0,
429,9,466,3,0,
429,10,29,3,0,
429,10,45,1,1,3
429,10,63,4,0,
429,10,85,4,0,
429,10,86,4,0,
429,10,87,4,0,
429,10,92,4,0,
429,10,94,4,0,
429,10,104,4,0,
429,10,129,3,0,
429,10,138,4,0,
429,10,148,4,0,
429,10,149,1,1,4
429,10,156,4,0,
429,10,164,4,0,
429,10,168,4,0,
429,10,173,3,0,
429,10,180,1,1,5
429,10,180,3,0,
429,10,182,4,0,
429,10,196,3,0,
429,10,203,4,0,
429,10,207,4,0,
429,10,213,4,0,
429,10,214,4,0,
429,10,215,3,0,
429,10,216,4,0,
429,10,218,4,0,
429,10,220,3,0,
429,10,237,4,0,
429,10,240,4,0,
429,10,241,4,0,
429,10,244,4,0,
429,10,247,4,0,
429,10,253,3,0,
429,10,259,4,0,
429,10,261,4,0,
429,10,263,4,0,
429,10,269,4,0,
429,10,271,3,0,
429,10,277,3,0,
429,10,285,4,0,
429,10,289,4,0,
429,10,290,4,0,
429,10,310,1,1,6
429,10,332,4,0,
429,10,345,1,1,2
429,10,347,4,0,
429,10,351,4,0,
429,10,363,4,0,
429,10,371,4,0,
429,10,373,4,0,
429,10,381,1,1,1
429,10,389,3,0,
429,10,399,4,0,
429,10,412,4,0,
429,10,416,4,0,
429,10,433,4,0,
429,10,445,4,0,
429,10,451,4,0,
429,10,466,3,0,
429,11,45,1,1,3
429,11,63,4,0,
429,11,85,4,0,
429,11,86,4,0,
429,11,87,4,0,
429,11,92,4,0,
429,11,94,4,0,
429,11,104,4,0,
429,11,138,4,0,
429,11,148,4,0,
429,11,149,1,1,4
429,11,156,4,0,
429,11,164,4,0,
429,11,168,4,0,
429,11,180,1,1,5
429,11,182,4,0,
429,11,207,4,0,
429,11,213,4,0,
429,11,216,4,0,
429,11,218,4,0,
429,11,237,4,0,
429,11,240,4,0,
429,11,241,4,0,
429,11,244,4,0,
429,11,247,4,0,
429,11,259,4,0,
429,11,261,4,0,
429,11,263,4,0,
429,11,269,4,0,
429,11,310,1,1,6
429,11,332,4,0,
429,11,345,1,1,2
429,11,347,4,0,
429,11,371,4,0,
429,11,373,4,0,
429,11,381,1,1,1
429,11,412,4,0,
429,11,416,4,0,
429,11,433,4,0,
429,11,451,4,0,
429,11,477,4,0,
429,11,496,4,0,
429,11,497,4,0,
429,14,45,1,1,3
429,14,63,4,0,
429,14,85,4,0,
429,14,86,4,0,
429,14,87,4,0,
429,14,92,4,0,
429,14,94,4,0,
429,14,104,4,0,
429,14,138,4,0,
429,14,148,4,0,
429,14,149,1,1,4
429,14,156,4,0,
429,14,164,4,0,
429,14,168,4,0,
429,14,173,3,0,
429,14,180,1,1,5
429,14,180,3,0,
429,14,182,4,0,
429,14,196,3,0,
429,14,207,4,0,
429,14,213,4,0,
429,14,214,3,0,
429,14,215,3,0,
429,14,216,4,0,
429,14,218,4,0,
429,14,220,3,0,
429,14,237,4,0,
429,14,240,4,0,
429,14,241,4,0,
429,14,244,4,0,
429,14,247,4,0,
429,14,253,3,0,
429,14,259,4,0,
429,14,261,4,0,
429,14,263,4,0,
429,14,269,4,0,
429,14,271,3,0,
429,14,277,3,0,
429,14,285,3,0,
429,14,289,3,0,
429,14,304,3,0,
429,14,310,1,1,6
429,14,332,4,0,
429,14,345,1,1,2
429,14,347,4,0,
429,14,371,4,0,
429,14,373,4,0,
429,14,381,1,1,1
429,14,399,3,0,
429,14,412,4,0,
429,14,416,4,0,
429,14,433,4,0,
429,14,451,4,0,
429,14,472,3,0,
429,14,477,4,0,
429,14,478,3,0,
429,14,492,3,0,
429,14,496,4,0,
429,14,497,4,0,
429,15,45,1,1,5
429,15,63,4,0,
429,15,85,4,0,
429,15,86,4,0,
429,15,87,4,0,
429,15,92,4,0,
429,15,94,4,0,
429,15,104,4,0,
429,15,138,4,0,
429,15,148,4,0,
429,15,149,1,1,6
429,15,156,4,0,
429,15,164,4,0,
429,15,168,4,0,
429,15,180,1,1,7
429,15,182,4,0,
429,15,207,4,0,
429,15,213,4,0,
429,15,214,4,0,
429,15,216,4,0,
429,15,218,4,0,
429,15,237,4,0,
429,15,240,4,0,
429,15,241,4,0,
429,15,244,4,0,
429,15,247,4,0,
429,15,259,4,0,
429,15,261,4,0,
429,15,263,4,0,
429,15,269,4,0,
429,15,310,1,1,8
429,15,332,4,0,
429,15,345,1,1,4
429,15,347,4,0,
429,15,371,4,0,
429,15,373,4,0,
429,15,381,1,1,3
429,15,399,4,0,
429,15,408,1,1,1
429,15,412,4,0,
429,15,416,4,0,
429,15,433,4,0,
429,15,451,4,0,
429,15,496,4,0,
429,15,497,4,0,
429,15,566,1,1,2
429,15,590,4,0,
429,15,605,4,0,
429,16,45,1,1,6
429,16,63,4,0,
429,16,85,4,0,
429,16,86,4,0,
429,16,87,4,0,
429,16,92,4,0,
429,16,94,4,0,
429,16,104,4,0,
429,16,138,4,0,
429,16,148,4,0,
429,16,149,1,1,7
429,16,156,4,0,
429,16,164,4,0,
429,16,168,4,0,
429,16,173,3,0,
429,16,180,1,1,8
429,16,180,3,0,
429,16,182,4,0,
429,16,196,3,0,
429,16,207,4,0,
429,16,213,4,0,
429,16,214,4,0,
429,16,215,3,0,
429,16,216,4,0,
429,16,218,4,0,
429,16,220,3,0,
429,16,237,4,0,
429,16,240,4,0,
429,16,241,4,0,
429,16,244,4,0,
429,16,247,4,0,
429,16,253,3,0,
429,16,259,4,0,
429,16,261,4,0,
429,16,263,4,0,
429,16,269,4,0,
429,16,271,3,0,
429,16,277,3,0,
429,16,285,3,0,
429,16,289,3,0,
429,16,290,4,0,
429,16,304,3,0,
429,16,310,1,1,9
429,16,332,4,0,
429,16,345,1,1,5
429,16,347,4,0,
429,16,351,3,0,
429,16,371,4,0,
429,16,373,4,0,
429,16,381,1,1,4
429,16,399,4,0,
429,16,408,1,1,2
429,16,412,4,0,
429,16,416,4,0,
429,16,433,4,0,
429,16,451,4,0,
429,16,472,3,0,
429,16,478,3,0,
429,16,492,3,0,
429,16,496,4,0,
429,16,497,4,0,
429,16,566,1,1,3
429,16,590,4,0,
429,16,595,1,1,1
429,16,605,4,0,
429,17,45,1,1,6
429,17,63,4,0,
429,17,85,4,0,
429,17,86,4,0,
429,17,87,4,0,
429,17,92,4,0,
429,17,94,4,0,
429,17,104,4,0,
429,17,138,4,0,
429,17,149,1,1,7
429,17,156,4,0,
429,17,164,4,0,
429,17,168,4,0,
429,17,180,1,1,8
429,17,182,4,0,
429,17,207,4,0,
429,17,213,4,0,
429,17,214,4,0,
429,17,216,4,0,
429,17,218,4,0,
429,17,237,4,0,
429,17,240,4,0,
429,17,241,4,0,
429,17,244,4,0,
429,17,247,4,0,
429,17,259,4,0,
429,17,261,4,0,
429,17,263,4,0,
429,17,269,4,0,
429,17,310,1,1,9
429,17,332,4,0,
429,17,345,1,1,5
429,17,347,4,0,
429,17,371,4,0,
429,17,373,4,0,
429,17,381,1,1,4
429,17,399,4,0,
429,17,408,1,1,2
429,17,412,4,0,
429,17,416,4,0,
429,17,433,4,0,
429,17,451,4,0,
429,17,496,4,0,
429,17,497,4,0,
429,17,566,1,1,3
429,17,590,4,0,
429,17,595,1,1,1
429,17,605,4,0,
429,18,45,1,1,6
429,18,63,4,0,
429,18,85,4,0,
429,18,86,4,0,
429,18,87,4,0,
429,18,92,4,0,
429,18,94,4,0,
429,18,104,4,0,
429,18,138,4,0,
429,18,149,1,1,7
429,18,156,4,0,
429,18,164,4,0,
429,18,168,4,0,
429,18,180,1,1,8
429,18,182,4,0,
429,18,207,4,0,
429,18,213,4,0,
429,18,214,4,0,
429,18,216,4,0,
429,18,218,4,0,
429,18,237,4,0,
429,18,240,4,0,
429,18,241,4,0,
429,18,244,4,0,
429,18,247,4,0,
429,18,259,4,0,
429,18,261,4,0,
429,18,263,4,0,
429,18,269,4,0,
429,18,310,1,1,9
429,18,332,4,0,
429,18,345,1,1,5
429,18,347,4,0,
429,18,371,4,0,
429,18,373,4,0,
429,18,381,1,1,4
429,18,399,4,0,
429,18,408,1,1,2
429,18,412,4,0,
429,18,416,4,0,
429,18,433,4,0,
429,18,451,4,0,
429,18,496,4,0,
429,18,497,4,0,
429,18,566,1,1,3
429,18,590,4,0,
429,18,595,1,1,1
429,18,605,4,0,
430,8,17,1,1,4
430,8,19,4,0,
430,8,63,4,0,
430,8,86,4,0,
430,8,92,4,0,
430,8,94,4,0,
430,8,104,4,0,
430,8,114,1,1,3
430,8,138,4,0,
430,8,156,4,0,
430,8,164,4,0,
430,8,168,4,0,
430,8,182,4,0,
430,8,203,4,0,
430,8,207,1,25,
430,8,207,4,0,
430,8,211,4,0,
430,8,213,4,0,
430,8,214,4,0,
430,8,216,4,0,
430,8,218,4,0,
430,8,228,1,1,2
430,8,237,4,0,
430,8,240,4,0,
430,8,241,4,0,
430,8,244,4,0,
430,8,247,4,0,
430,8,259,4,0,
430,8,263,4,0,
430,8,269,4,0,
430,8,289,4,0,
430,8,290,4,0,
430,8,310,1,1,1
430,8,332,4,0,
430,8,347,4,0,
430,8,355,4,0,
430,8,363,4,0,
430,8,365,4,0,
430,8,371,4,0,
430,8,373,4,0,
430,8,399,1,55,
430,8,399,4,0,
430,8,400,1,45,
430,8,416,4,0,
430,8,417,1,35,
430,8,432,4,0,
430,8,445,4,0,
430,9,17,1,1,4
430,9,19,4,0,
430,9,63,4,0,
430,9,86,4,0,
430,9,92,4,0,
430,9,94,4,0,
430,9,104,4,0,
430,9,114,1,1,3
430,9,129,3,0,
430,9,138,4,0,
430,9,156,4,0,
430,9,164,4,0,
430,9,168,4,0,
430,9,180,3,0,
430,9,182,4,0,
430,9,189,3,0,
430,9,203,4,0,
430,9,207,1,25,
430,9,207,4,0,
430,9,211,4,0,
430,9,213,4,0,
430,9,214,4,0,
430,9,216,4,0,
430,9,218,4,0,
430,9,228,1,1,2
430,9,237,4,0,
430,9,239,3,0,
430,9,240,4,0,
430,9,241,4,0,
430,9,244,4,0,
430,9,247,4,0,
430,9,253,3,0,
430,9,257,3,0,
430,9,259,4,0,
430,9,263,4,0,
430,9,269,4,0,
430,9,276,3,0,
430,9,289,4,0,
430,9,290,4,0,
430,9,310,1,1,1
430,9,314,3,0,
430,9,332,4,0,
430,9,347,4,0,
430,9,355,4,0,
430,9,363,4,0,
430,9,365,4,0,
430,9,371,4,0,
430,9,373,4,0,
430,9,389,3,0,
430,9,399,1,55,
430,9,399,4,0,
430,9,400,1,45,
430,9,416,4,0,
430,9,417,1,35,
430,9,432,4,0,
430,9,445,4,0,
430,9,466,3,0,
430,10,17,1,1,4
430,10,19,4,0,
430,10,63,4,0,
430,10,86,4,0,
430,10,92,4,0,
430,10,94,4,0,
430,10,104,4,0,
430,10,114,1,1,3
430,10,129,3,0,
430,10,138,4,0,
430,10,143,3,0,
430,10,156,4,0,
430,10,164,4,0,
430,10,168,4,0,
430,10,180,3,0,
430,10,182,4,0,
430,10,189,3,0,
430,10,203,4,0,
430,10,207,1,25,
430,10,207,4,0,
430,10,211,4,0,
430,10,213,4,0,
430,10,214,4,0,
430,10,216,4,0,
430,10,218,4,0,
430,10,228,1,1,2
430,10,237,4,0,
430,10,239,3,0,
430,10,240,4,0,
430,10,241,4,0,
430,10,244,4,0,
430,10,247,4,0,
430,10,253,3,0,
430,10,257,3,0,
430,10,259,4,0,
430,10,263,4,0,
430,10,269,4,0,
430,10,276,3,0,
430,10,289,4,0,
430,10,290,4,0,
430,10,310,1,1,1
430,10,314,3,0,
430,10,332,4,0,
430,10,347,4,0,
430,10,355,4,0,
430,10,363,4,0,
430,10,365,4,0,
430,10,366,3,0,
430,10,371,4,0,
430,10,373,4,0,
430,10,389,3,0,
430,10,399,1,55,
430,10,399,4,0,
430,10,400,1,45,
430,10,416,4,0,
430,10,417,1,35,
430,10,445,4,0,
430,10,466,3,0,
430,11,17,1,1,4
430,11,19,4,0,
430,11,63,4,0,
430,11,86,4,0,
430,11,92,4,0,
430,11,94,4,0,
430,11,104,4,0,
430,11,114,1,1,3
430,11,138,4,0,
430,11,156,4,0,
430,11,164,4,0,
430,11,168,4,0,
430,11,182,4,0,
430,11,207,1,25,
430,11,207,4,0,
430,11,213,4,0,
430,11,216,4,0,
430,11,218,4,0,
430,11,228,1,1,2
430,11,237,4,0,
430,11,240,4,0,
430,11,241,4,0,
430,11,244,4,0,
430,11,247,4,0,
430,11,259,4,0,
430,11,263,4,0,
430,11,269,4,0,
430,11,310,1,1,1
430,11,332,4,0,
430,11,347,4,0,
430,11,365,4,0,
430,11,371,4,0,
430,11,373,4,0,
430,11,399,1,75,
430,11,400,1,55,
430,11,416,4,0,
430,11,417,1,35,
430,11,492,1,45,
430,11,496,4,0,
430,11,510,4,0,
430,11,511,1,65,
430,11,511,4,0,
430,11,514,4,0,
430,11,555,4,0,
430,14,17,1,1,4
430,14,19,4,0,
430,14,63,4,0,
430,14,86,4,0,
430,14,92,4,0,
430,14,94,4,0,
430,14,104,4,0,
430,14,114,1,1,3
430,14,138,4,0,
430,14,143,3,0,
430,14,156,4,0,
430,14,164,4,0,
430,14,168,4,0,
430,14,180,3,0,
430,14,182,4,0,
430,14,196,3,0,
430,14,207,1,25,
430,14,207,4,0,
430,14,213,4,0,
430,14,214,3,0,
430,14,216,4,0,
430,14,218,4,0,
430,14,228,1,1,2
430,14,237,4,0,
430,14,240,4,0,
430,14,241,4,0,
430,14,244,4,0,
430,14,247,4,0,
430,14,253,3,0,
430,14,257,3,0,
430,14,259,4,0,
430,14,263,4,0,
430,14,269,4,0,
430,14,276,3,0,
430,14,289,3,0,
430,14,310,1,1,1
430,14,332,4,0,
430,14,347,4,0,
430,14,355,3,0,
430,14,365,4,0,
430,14,366,3,0,
430,14,371,4,0,
430,14,373,4,0,
430,14,399,1,75,
430,14,399,3,0,
430,14,400,1,55,
430,14,416,4,0,
430,14,417,1,35,
430,14,492,1,45,
430,14,492,3,0,
430,14,496,4,0,
430,14,510,4,0,
430,14,511,1,65,
430,14,511,4,0,
430,14,514,4,0,
430,14,555,4,0,
430,15,17,1,1,6
430,15,19,4,0,
430,15,63,4,0,
430,15,86,4,0,
430,15,92,4,0,
430,15,94,4,0,
430,15,104,4,0,
430,15,114,1,1,5
430,15,138,4,0,
430,15,156,4,0,
430,15,164,4,0,
430,15,168,4,0,
430,15,182,4,0,
430,15,207,1,25,
430,15,207,4,0,
430,15,211,4,0,
430,15,213,4,0,
430,15,214,4,0,
430,15,216,4,0,
430,15,218,4,0,
430,15,228,1,1,4
430,15,237,4,0,
430,15,240,4,0,
430,15,241,4,0,
430,15,244,4,0,
430,15,247,4,0,
430,15,259,4,0,
430,15,263,4,0,
430,15,269,4,0,
430,15,310,1,1,3
430,15,332,4,0,
430,15,347,4,0,
430,15,355,4,0,
430,15,371,4,0,
430,15,373,4,0,
430,15,389,1,1,2
430,15,399,1,75,
430,15,399,4,0,
430,15,400,1,1,1
430,15,400,1,55,
430,15,416,4,0,
430,15,417,1,35,
430,15,492,1,45,
430,15,496,4,0,
430,15,510,4,0,
430,15,511,1,65,
430,15,511,4,0,
430,15,514,4,0,
430,15,555,4,0,
430,15,590,4,0,
430,16,17,1,1,6
430,16,19,4,0,
430,16,63,4,0,
430,16,86,4,0,
430,16,92,4,0,
430,16,94,4,0,
430,16,104,4,0,
430,16,114,1,1,5
430,16,138,4,0,
430,16,143,3,0,
430,16,156,4,0,
430,16,164,4,0,
430,16,168,4,0,
430,16,173,3,0,
430,16,180,3,0,
430,16,182,4,0,
430,16,196,3,0,
430,16,207,1,25,1
430,16,207,4,0,
430,16,211,4,0,
430,16,213,4,0,
430,16,214,4,0,
430,16,216,4,0,
430,16,218,4,0,
430,16,228,1,1,4
430,16,237,4,0,
430,16,240,4,0,
430,16,241,4,0,
430,16,244,4,0,
430,16,247,4,0,
430,16,253,3,0,
430,16,257,3,0,
430,16,259,4,0,
430,16,263,4,0,
430,16,269,4,0,
430,16,276,3,0,
430,16,289,3,0,
430,16,290,4,0,
430,16,310,1,1,3
430,16,332,4,0,
430,16,347,4,0,
430,16,355,4,0,
430,16,366,3,0,
430,16,371,4,0,
430,16,373,4,0,
430,16,389,1,1,2
430,16,399,1,75,1
430,16,399,4,0,
430,16,400,1,1,1
430,16,400,1,55,1
430,16,416,4,0,
430,16,417,1,35,1
430,16,492,1,45,1
430,16,492,3,0,
430,16,496,4,0,
430,16,510,4,0,
430,16,511,1,65,1
430,16,511,4,0,
430,16,514,4,0,
430,16,555,4,0,
430,16,590,4,0,
430,17,17,1,1,6
430,17,19,4,0,
430,17,63,4,0,
430,17,86,4,0,
430,17,92,4,0,
430,17,94,4,0,
430,17,104,4,0,
430,17,114,1,1,5
430,17,138,4,0,
430,17,156,4,0,
430,17,164,4,0,
430,17,168,4,0,
430,17,182,4,0,
430,17,207,1,25,
430,17,207,4,0,
430,17,211,4,0,
430,17,213,4,0,
430,17,214,4,0,
430,17,216,4,0,
430,17,218,4,0,
430,17,228,1,1,4
430,17,237,4,0,
430,17,240,4,0,
430,17,241,4,0,
430,17,244,4,0,
430,17,247,4,0,
430,17,259,4,0,
430,17,263,4,0,
430,17,269,4,0,
430,17,310,1,1,3
430,17,332,4,0,
430,17,347,4,0,
430,17,355,4,0,
430,17,371,4,0,
430,17,373,4,0,
430,17,389,1,1,2
430,17,399,1,75,
430,17,399,4,0,
430,17,400,1,1,1
430,17,400,1,55,
430,17,416,4,0,
430,17,417,1,35,
430,17,492,1,45,
430,17,496,4,0,
430,17,511,1,65,
430,17,511,4,0,
430,17,555,4,0,
430,17,590,4,0,
430,18,17,1,1,6
430,18,19,4,0,
430,18,63,4,0,
430,18,86,4,0,
430,18,92,4,0,
430,18,94,4,0,
430,18,104,4,0,
430,18,114,1,1,5
430,18,138,4,0,
430,18,156,4,0,
430,18,164,4,0,
430,18,168,4,0,
430,18,182,4,0,
430,18,207,1,25,
430,18,207,4,0,
430,18,211,4,0,
430,18,213,4,0,
430,18,214,4,0,
430,18,216,4,0,
430,18,218,4,0,
430,18,228,1,1,4
430,18,237,4,0,
430,18,240,4,0,
430,18,241,4,0,
430,18,244,4,0,
430,18,247,4,0,
430,18,259,4,0,
430,18,263,4,0,
430,18,269,4,0,
430,18,310,1,1,3
430,18,332,4,0,
430,18,347,4,0,
430,18,355,4,0,
430,18,371,4,0,
430,18,373,4,0,
430,18,389,1,1,2
430,18,399,1,75,
430,18,399,4,0,
430,18,400,1,1,1
430,18,400,1,55,
430,18,416,4,0,
430,18,417,1,35,
430,18,492,1,45,
430,18,496,4,0,
430,18,511,1,65,
430,18,511,4,0,
430,18,555,4,0,
430,18,590,4,0,
431,8,10,1,5,
431,8,15,4,0,
431,8,28,2,0,
431,8,39,2,0,
431,8,44,2,0,
431,8,45,1,8,
431,8,85,4,0,
431,8,87,4,0,
431,8,91,4,0,
431,8,92,4,0,
431,8,95,1,13,
431,8,98,2,0,
431,8,104,4,0,
431,8,138,4,0,
431,8,148,4,0,
431,8,154,1,20,
431,8,156,4,0,
431,8,163,1,37,
431,8,164,4,0,
431,8,168,4,0,
431,8,182,4,0,
431,8,185,1,17,
431,8,203,4,0,
431,8,204,1,25,
431,8,207,4,0,
431,8,213,1,45,
431,8,213,4,0,
431,8,214,4,0,
431,8,216,4,0,
431,8,218,4,0,
431,8,231,4,0,
431,8,237,4,0,
431,8,240,4,0,
431,8,241,4,0,
431,8,244,4,0,
431,8,247,4,0,
431,8,252,1,1,
431,8,259,4,0,
431,8,263,4,0,
431,8,269,4,0,
431,8,274,1,29,
431,8,289,4,0,
431,8,290,4,0,
431,8,313,2,0,
431,8,332,4,0,
431,8,351,4,0,
431,8,352,4,0,
431,8,363,4,0,
431,8,369,4,0,
431,8,371,4,0,
431,8,372,2,0,
431,8,389,1,41,
431,8,421,4,0,
431,8,445,1,32,
431,8,445,4,0,
431,9,10,1,5,
431,9,15,4,0,
431,9,28,2,0,
431,9,39,2,0,
431,9,44,2,0,
431,9,45,1,8,
431,9,85,4,0,
431,9,87,4,0,
431,9,91,4,0,
431,9,92,4,0,
431,9,95,1,13,
431,9,98,2,0,
431,9,104,4,0,
431,9,129,3,0,
431,9,138,4,0,
431,9,148,4,0,
431,9,154,1,20,
431,9,156,4,0,
431,9,163,1,37,
431,9,164,4,0,
431,9,168,4,0,
431,9,173,3,0,
431,9,182,4,0,
431,9,185,1,17,
431,9,189,3,0,
431,9,203,4,0,
431,9,204,1,25,
431,9,207,4,0,
431,9,210,3,0,
431,9,213,1,45,
431,9,213,4,0,
431,9,214,4,0,
431,9,216,4,0,
431,9,218,4,0,
431,9,231,4,0,
431,9,237,4,0,
431,9,240,4,0,
431,9,241,4,0,
431,9,244,4,0,
431,9,247,4,0,
431,9,252,1,1,
431,9,259,4,0,
431,9,263,4,0,
431,9,269,4,0,
431,9,274,1,29,
431,9,282,3,0,
431,9,289,4,0,
431,9,290,4,0,
431,9,313,2,0,
431,9,332,4,0,
431,9,351,4,0,
431,9,352,4,0,
431,9,363,4,0,
431,9,369,4,0,
431,9,371,4,0,
431,9,372,2,0,
431,9,387,3,0,
431,9,389,1,41,
431,9,389,3,0,
431,9,421,4,0,
431,9,445,1,32,
431,9,445,4,0,
431,10,10,1,5,
431,10,15,4,0,
431,10,28,2,0,
431,10,29,3,0,
431,10,39,2,0,
431,10,44,2,0,
431,10,45,1,8,
431,10,85,4,0,
431,10,87,4,0,
431,10,91,4,0,
431,10,92,4,0,
431,10,95,1,13,
431,10,98,2,0,
431,10,104,4,0,
431,10,129,3,0,
431,10,138,4,0,
431,10,148,4,0,
431,10,154,1,20,
431,10,156,4,0,
431,10,162,3,0,
431,10,163,1,37,
431,10,164,4,0,
431,10,168,4,0,
431,10,173,3,0,
431,10,175,2,0,
431,10,182,4,0,
431,10,185,1,17,
431,10,189,3,0,
431,10,203,4,0,
431,10,204,1,25,
431,10,207,4,0,
431,10,210,3,0,
431,10,213,1,45,
431,10,213,4,0,
431,10,214,4,0,
431,10,216,4,0,
431,10,218,4,0,
431,10,231,4,0,
431,10,237,4,0,
431,10,240,4,0,
431,10,241,4,0,
431,10,244,4,0,
431,10,247,4,0,
431,10,252,1,1,
431,10,259,4,0,
431,10,263,4,0,
431,10,269,4,0,
431,10,274,1,29,
431,10,282,3,0,
431,10,289,4,0,
431,10,290,4,0,
431,10,313,2,0,
431,10,332,4,0,
431,10,351,4,0,
431,10,352,4,0,
431,10,363,4,0,
431,10,369,4,0,
431,10,371,4,0,
431,10,372,2,0,
431,10,387,3,0,
431,10,389,1,41,
431,10,389,3,0,
431,10,421,4,0,
431,10,445,1,32,
431,10,445,4,0,
431,11,10,1,5,
431,11,15,4,0,
431,11,28,2,0,
431,11,39,2,0,
431,11,44,2,0,
431,11,45,1,8,
431,11,85,4,0,
431,11,87,4,0,
431,11,91,4,0,
431,11,92,4,0,
431,11,95,1,13,
431,11,98,2,0,
431,11,104,4,0,
431,11,138,4,0,
431,11,148,4,0,
431,11,154,1,20,
431,11,156,4,0,
431,11,163,1,37,
431,11,164,4,0,
431,11,168,4,0,
431,11,175,2,0,
431,11,182,4,0,
431,11,185,1,17,
431,11,204,1,25,
431,11,207,4,0,
431,11,213,1,44,
431,11,213,4,0,
431,11,216,4,0,
431,11,218,4,0,
431,11,237,4,0,
431,11,240,4,0,
431,11,241,4,0,
431,11,244,4,0,
431,11,247,4,0,
431,11,252,1,1,
431,11,259,4,0,
431,11,263,4,0,
431,11,269,4,0,
431,11,274,1,29,
431,11,289,2,0,
431,11,313,2,0,
431,11,332,4,0,
431,11,358,2,0,
431,11,369,4,0,
431,11,371,4,0,
431,11,372,2,0,
431,11,387,2,0,
431,11,389,1,41,
431,11,421,4,0,
431,11,445,1,32,
431,11,468,1,48,
431,11,468,4,0,
431,11,496,4,0,
431,11,497,4,0,
431,11,514,4,0,
431,11,526,4,0,
431,14,10,1,5,
431,14,15,4,0,
431,14,28,2,0,
431,14,39,2,0,
431,14,44,2,0,
431,14,45,1,8,
431,14,85,4,0,
431,14,87,4,0,
431,14,91,4,0,
431,14,92,4,0,
431,14,95,1,13,
431,14,98,2,0,
431,14,104,4,0,
431,14,138,4,0,
431,14,148,4,0,
431,14,154,1,20,
431,14,156,4,0,
431,14,162,3,0,
431,14,163,1,37,
431,14,164,4,0,
431,14,168,4,0,
431,14,173,3,0,
431,14,175,2,0,
431,14,182,4,0,
431,14,185,1,17,
431,14,204,1,25,
431,14,207,4,0,
431,14,213,1,44,
431,14,213,4,0,
431,14,214,3,0,
431,14,216,4,0,
431,14,218,4,0,
431,14,231,3,0,
431,14,237,4,0,
431,14,240,4,0,
431,14,241,4,0,
431,14,244,4,0,
431,14,247,4,0,
431,14,252,1,1,
431,14,259,4,0,
431,14,263,4,0,
431,14,269,4,0,
431,14,274,1,29,
431,14,282,3,0,
431,14,289,2,0,
431,14,289,3,0,
431,14,304,3,0,
431,14,313,2,0,
431,14,332,4,0,
431,14,343,3,0,
431,14,358,2,0,
431,14,369,4,0,
431,14,371,4,0,
431,14,372,2,0,
431,14,387,2,0,
431,14,387,3,0,
431,14,389,1,41,
431,14,421,4,0,
431,14,445,1,32,
431,14,468,1,48,
431,14,468,4,0,
431,14,492,3,0,
431,14,496,4,0,
431,14,497,4,0,
431,14,514,4,0,
431,14,526,4,0,
431,15,10,1,5,
431,15,15,4,0,
431,15,28,2,0,
431,15,39,2,0,
431,15,44,2,0,
431,15,45,1,8,
431,15,85,4,0,
431,15,87,4,0,
431,15,91,4,0,
431,15,92,4,0,
431,15,95,1,13,
431,15,98,2,0,
431,15,104,4,0,
431,15,138,4,0,
431,15,148,4,0,
431,15,154,1,20,
431,15,156,4,0,
431,15,163,1,37,
431,15,164,4,0,
431,15,168,4,0,
431,15,175,2,0,
431,15,182,4,0,
431,15,185,1,17,
431,15,204,1,25,
431,15,207,4,0,
431,15,213,1,44,
431,15,213,4,0,
431,15,214,4,0,
431,15,216,4,0,
431,15,218,4,0,
431,15,237,4,0,
431,15,240,4,0,
431,15,241,4,0,
431,15,244,4,0,
431,15,247,4,0,
431,15,252,1,1,
431,15,259,4,0,
431,15,263,4,0,
431,15,269,4,0,
431,15,274,1,29,
431,15,289,2,0,
431,15,313,2,0,
431,15,332,4,0,
431,15,358,2,0,
431,15,369,4,0,
431,15,371,4,0,
431,15,372,2,0,
431,15,387,2,0,
431,15,389,1,41,
431,15,421,4,0,
431,15,445,1,32,
431,15,468,1,48,
431,15,468,4,0,
431,15,496,4,0,
431,15,497,4,0,
431,15,514,4,0,
431,15,583,1,50,
431,15,590,4,0,
431,16,10,1,5,1
431,16,15,4,0,
431,16,28,2,0,
431,16,39,2,0,
431,16,44,2,0,
431,16,45,1,8,1
431,16,85,4,0,
431,16,87,4,0,
431,16,91,4,0,
431,16,92,4,0,
431,16,95,1,13,1
431,16,98,2,0,
431,16,104,4,0,
431,16,138,4,0,
431,16,148,4,0,
431,16,154,1,20,1
431,16,156,4,0,
431,16,162,3,0,
431,16,163,1,37,1
431,16,164,4,0,
431,16,168,4,0,
431,16,173,3,0,
431,16,175,2,0,
431,16,182,4,0,
431,16,185,1,17,1
431,16,204,1,25,1
431,16,207,4,0,
431,16,213,1,44,1
431,16,213,4,0,
431,16,214,4,0,
431,16,216,4,0,
431,16,218,4,0,
431,16,231,3,0,
431,16,237,4,0,
431,16,240,4,0,
431,16,241,4,0,
431,16,244,4,0,
431,16,247,4,0,
431,16,252,1,1,1
431,16,259,4,0,
431,16,263,4,0,
431,16,269,4,0,
431,16,274,1,29,1
431,16,282,3,0,
431,16,289,2,0,
431,16,289,3,0,
431,16,290,4,0,
431,16,304,3,0,
431,16,313,2,0,
431,16,332,4,0,
431,16,343,3,0,
431,16,351,3,0,
431,16,352,3,0,
431,16,358,2,0,
431,16,369,4,0,
431,16,371,4,0,
431,16,372,2,0,
431,16,387,2,0,
431,16,387,3,0,
431,16,389,1,41,1
431,16,421,4,0,
431,16,445,1,32,1
431,16,468,1,48,1
431,16,468,4,0,
431,16,492,3,0,
431,16,496,4,0,
431,16,497,4,0,
431,16,514,4,0,
431,16,583,1,50,1
431,16,590,4,0,
431,17,10,1,5,
431,17,28,2,0,
431,17,39,2,0,
431,17,44,2,0,
431,17,45,1,8,
431,17,85,4,0,
431,17,87,4,0,
431,17,92,4,0,
431,17,95,1,13,
431,17,98,2,0,
431,17,104,4,0,
431,17,138,4,0,
431,17,154,1,20,
431,17,156,4,0,
431,17,163,1,37,
431,17,164,4,0,
431,17,168,4,0,
431,17,175,2,0,
431,17,182,4,0,
431,17,185,1,17,
431,17,204,1,25,
431,17,207,4,0,
431,17,213,1,44,
431,17,213,4,0,
431,17,214,4,0,
431,17,216,4,0,
431,17,218,4,0,
431,17,237,4,0,
431,17,240,4,0,
431,17,241,4,0,
431,17,244,4,0,
431,17,247,4,0,
431,17,252,1,1,
431,17,259,4,0,
431,17,263,4,0,
431,17,269,4,0,
431,17,274,1,29,
431,17,289,2,0,
431,17,313,2,0,
431,17,332,4,0,
431,17,358,2,0,
431,17,369,4,0,
431,17,371,4,0,
431,17,372,2,0,
431,17,387,2,0,
431,17,389,1,41,
431,17,421,4,0,
431,17,445,1,32,
431,17,468,1,48,
431,17,496,4,0,
431,17,497,4,0,
431,17,526,4,0,
431,17,583,1,50,
431,17,590,4,0,
431,18,10,1,5,
431,18,28,2,0,
431,18,39,2,0,
431,18,44,2,0,
431,18,45,1,8,
431,18,85,4,0,
431,18,87,4,0,
431,18,92,4,0,
431,18,95,1,13,
431,18,98,2,0,
431,18,104,4,0,
431,18,138,4,0,
431,18,154,1,20,
431,18,156,4,0,
431,18,163,1,37,
431,18,164,4,0,
431,18,168,4,0,
431,18,175,2,0,
431,18,182,4,0,
431,18,185,1,17,
431,18,204,1,25,
431,18,207,4,0,
431,18,213,1,44,
431,18,213,4,0,
431,18,214,4,0,
431,18,216,4,0,
431,18,218,4,0,
431,18,237,4,0,
431,18,240,4,0,
431,18,241,4,0,
431,18,244,4,0,
431,18,247,4,0,
431,18,252,1,1,
431,18,259,4,0,
431,18,263,4,0,
431,18,269,4,0,
431,18,274,1,29,
431,18,289,2,0,
431,18,313,2,0,
431,18,332,4,0,
431,18,358,2,0,
431,18,369,4,0,
431,18,371,4,0,
431,18,372,2,0,
431,18,387,2,0,
431,18,389,1,41,
431,18,421,4,0,
431,18,445,1,32,
431,18,468,1,48,
431,18,496,4,0,
431,18,497,4,0,
431,18,526,4,0,
431,18,583,1,50,
431,18,590,4,0,
432,8,10,1,1,2
432,8,10,1,5,
432,8,15,4,0,
432,8,34,1,45,
432,8,45,1,1,3
432,8,45,1,8,
432,8,46,4,0,
432,8,63,4,0,
432,8,85,4,0,
432,8,87,4,0,
432,8,91,4,0,
432,8,92,4,0,
432,8,95,1,13,
432,8,104,4,0,
432,8,138,4,0,
432,8,148,4,0,
432,8,154,1,20,
432,8,156,4,0,
432,8,163,1,37,
432,8,164,4,0,
432,8,168,4,0,
432,8,182,4,0,
432,8,185,1,17,
432,8,203,4,0,
432,8,204,1,25,
432,8,207,1,38,
432,8,207,4,0,
432,8,213,1,53,
432,8,213,4,0,
432,8,214,4,0,
432,8,216,4,0,
432,8,218,4,0,
432,8,231,4,0,
432,8,237,4,0,
432,8,240,4,0,
432,8,241,4,0,
432,8,244,4,0,
432,8,247,4,0,
432,8,252,1,1,1
432,8,259,4,0,
432,8,263,4,0,
432,8,269,4,0,
432,8,274,1,29,
432,8,289,4,0,
432,8,290,4,0,
432,8,332,4,0,
432,8,351,4,0,
432,8,352,4,0,
432,8,363,4,0,
432,8,369,4,0,
432,8,371,4,0,
432,8,416,4,0,
432,8,421,4,0,
432,8,445,1,32,
432,8,445,4,0,
432,9,10,1,1,2
432,9,10,1,5,
432,9,15,4,0,
432,9,34,1,45,
432,9,45,1,1,3
432,9,45,1,8,
432,9,46,4,0,
432,9,63,4,0,
432,9,85,4,0,
432,9,87,4,0,
432,9,91,4,0,
432,9,92,4,0,
432,9,95,1,13,
432,9,104,4,0,
432,9,129,3,0,
432,9,138,4,0,
432,9,148,4,0,
432,9,154,1,20,
432,9,156,4,0,
432,9,163,1,37,
432,9,164,4,0,
432,9,168,4,0,
432,9,173,3,0,
432,9,182,4,0,
432,9,185,1,17,
432,9,189,3,0,
432,9,203,4,0,
432,9,204,1,25,
432,9,205,3,0,
432,9,207,1,38,
432,9,207,4,0,
432,9,210,3,0,
432,9,213,1,53,
432,9,213,4,0,
432,9,214,4,0,
432,9,216,4,0,
432,9,218,4,0,
432,9,231,4,0,
432,9,237,4,0,
432,9,240,4,0,
432,9,241,4,0,
432,9,244,4,0,
432,9,247,4,0,
432,9,252,1,1,1
432,9,259,4,0,
432,9,263,4,0,
432,9,269,4,0,
432,9,274,1,29,
432,9,282,3,0,
432,9,289,4,0,
432,9,290,4,0,
432,9,332,4,0,
432,9,351,4,0,
432,9,352,4,0,
432,9,363,4,0,
432,9,369,4,0,
432,9,371,4,0,
432,9,387,3,0,
432,9,389,3,0,
432,9,416,4,0,
432,9,421,4,0,
432,9,445,1,32,
432,9,445,4,0,
432,10,10,1,1,2
432,10,10,1,5,
432,10,15,4,0,
432,10,29,3,0,
432,10,34,1,45,
432,10,45,1,1,3
432,10,45,1,8,
432,10,46,4,0,
432,10,63,4,0,
432,10,85,4,0,
432,10,87,4,0,
432,10,91,4,0,
432,10,92,4,0,
432,10,95,1,13,
432,10,104,4,0,
432,10,129,3,0,
432,10,138,4,0,
432,10,148,4,0,
432,10,154,1,20,
432,10,156,4,0,
432,10,162,3,0,
432,10,163,1,37,
432,10,164,4,0,
432,10,168,4,0,
432,10,173,3,0,
432,10,182,4,0,
432,10,185,1,17,
432,10,189,3,0,
432,10,203,4,0,
432,10,204,1,25,
432,10,205,3,0,
432,10,207,1,38,
432,10,207,4,0,
432,10,210,3,0,
432,10,213,1,53,
432,10,213,4,0,
432,10,214,4,0,
432,10,216,4,0,
432,10,218,4,0,
432,10,231,4,0,
432,10,237,4,0,
432,10,240,4,0,
432,10,241,4,0,
432,10,244,4,0,
432,10,247,4,0,
432,10,252,1,1,1
432,10,259,4,0,
432,10,263,4,0,
432,10,269,4,0,
432,10,274,1,29,
432,10,282,3,0,
432,10,289,4,0,
432,10,290,4,0,
432,10,332,4,0,
432,10,351,4,0,
432,10,352,4,0,
432,10,363,4,0,
432,10,369,4,0,
432,10,371,4,0,
432,10,387,3,0,
432,10,389,3,0,
432,10,416,4,0,
432,10,421,4,0,
432,10,445,1,32,
432,10,445,4,0,
432,11,10,1,1,2
432,11,10,1,5,
432,11,15,4,0,
432,11,34,1,45,
432,11,45,1,1,3
432,11,45,1,8,
432,11,46,4,0,
432,11,63,4,0,
432,11,85,4,0,
432,11,87,4,0,
432,11,91,4,0,
432,11,92,4,0,
432,11,95,1,13,
432,11,104,4,0,
432,11,138,4,0,
432,11,148,4,0,
432,11,154,1,20,
432,11,156,4,0,
432,11,163,1,37,
432,11,164,4,0,
432,11,168,4,0,
432,11,182,4,0,
432,11,185,1,17,
432,11,204,1,25,
432,11,207,1,38,
432,11,207,4,0,
432,11,213,1,52,
432,11,213,4,0,
432,11,216,4,0,
432,11,218,4,0,
432,11,237,4,0,
432,11,240,4,0,
432,11,241,4,0,
432,11,244,4,0,
432,11,247,4,0,
432,11,252,1,1,1
432,11,259,4,0,
432,11,263,4,0,
432,11,269,4,0,
432,11,274,1,29,
432,11,332,4,0,
432,11,369,4,0,
432,11,371,4,0,
432,11,416,4,0,
432,11,421,4,0,
432,11,445,1,32,
432,11,468,1,60,
432,11,468,4,0,
432,11,496,4,0,
432,11,497,4,0,
432,11,514,4,0,
432,11,523,4,0,
432,11,526,4,0,
432,14,10,1,1,2
432,14,10,1,5,
432,14,15,4,0,
432,14,34,1,45,
432,14,45,1,1,3
432,14,45,1,8,
432,14,46,4,0,
432,14,63,4,0,
432,14,85,4,0,
432,14,87,4,0,
432,14,91,4,0,
432,14,92,4,0,
432,14,95,1,13,
432,14,104,4,0,
432,14,138,4,0,
432,14,148,4,0,
432,14,154,1,20,
432,14,156,4,0,
432,14,162,3,0,
432,14,163,1,37,
432,14,164,4,0,
432,14,168,4,0,
432,14,173,3,0,
432,14,182,4,0,
432,14,185,1,17,
432,14,204,1,25,
432,14,207,1,38,
432,14,207,4,0,
432,14,213,1,52,
432,14,213,4,0,
432,14,214,3,0,
432,14,216,4,0,
432,14,218,4,0,
432,14,231,3,0,
432,14,237,4,0,
432,14,240,4,0,
432,14,241,4,0,
432,14,244,4,0,
432,14,247,4,0,
432,14,252,1,1,1
432,14,259,4,0,
432,14,263,4,0,
432,14,269,4,0,
432,14,274,1,29,
432,14,282,3,0,
432,14,289,3,0,
432,14,304,3,0,
432,14,332,4,0,
432,14,343,3,0,
432,14,369,4,0,
432,14,371,4,0,
432,14,387,3,0,
432,14,416,4,0,
432,14,421,4,0,
432,14,445,1,32,
432,14,468,1,60,
432,14,468,4,0,
432,14,492,3,0,
432,14,496,4,0,
432,14,497,4,0,
432,14,514,4,0,
432,14,523,4,0,
432,14,526,4,0,
432,15,10,1,1,2
432,15,10,1,5,
432,15,15,4,0,
432,15,34,1,45,
432,15,45,1,1,3
432,15,45,1,8,
432,15,46,4,0,
432,15,63,4,0,
432,15,85,4,0,
432,15,87,4,0,
432,15,91,4,0,
432,15,92,4,0,
432,15,95,1,13,
432,15,104,4,0,
432,15,138,4,0,
432,15,148,4,0,
432,15,154,1,20,
432,15,156,4,0,
432,15,163,1,37,
432,15,164,4,0,
432,15,168,4,0,
432,15,182,4,0,
432,15,185,1,17,
432,15,204,1,25,
432,15,207,1,38,
432,15,207,4,0,
432,15,213,1,52,
432,15,213,4,0,
432,15,214,4,0,
432,15,216,4,0,
432,15,218,4,0,
432,15,237,4,0,
432,15,240,4,0,
432,15,241,4,0,
432,15,244,4,0,
432,15,247,4,0,
432,15,252,1,1,1
432,15,259,4,0,
432,15,263,4,0,
432,15,269,4,0,
432,15,274,1,29,
432,15,332,4,0,
432,15,369,4,0,
432,15,371,4,0,
432,15,416,4,0,
432,15,421,4,0,
432,15,445,1,32,
432,15,468,1,60,
432,15,468,4,0,
432,15,496,4,0,
432,15,497,4,0,
432,15,514,4,0,
432,15,523,4,0,
432,15,590,4,0,
432,16,10,1,1,2
432,16,10,1,5,1
432,16,15,4,0,
432,16,34,1,45,1
432,16,45,1,1,3
432,16,45,1,8,1
432,16,46,4,0,
432,16,63,4,0,
432,16,85,4,0,
432,16,87,4,0,
432,16,91,4,0,
432,16,92,4,0,
432,16,95,1,13,1
432,16,104,4,0,
432,16,138,4,0,
432,16,148,4,0,
432,16,154,1,20,1
432,16,156,4,0,
432,16,162,3,0,
432,16,163,1,37,1
432,16,164,4,0,
432,16,168,4,0,
432,16,173,3,0,
432,16,182,4,0,
432,16,185,1,17,1
432,16,204,1,25,1
432,16,207,1,38,1
432,16,207,4,0,
432,16,213,1,52,1
432,16,213,4,0,
432,16,214,4,0,
432,16,216,4,0,
432,16,218,4,0,
432,16,231,3,0,
432,16,237,4,0,
432,16,240,4,0,
432,16,241,4,0,
432,16,244,4,0,
432,16,247,4,0,
432,16,252,1,1,1
432,16,259,4,0,
432,16,263,4,0,
432,16,269,4,0,
432,16,274,1,29,1
432,16,282,3,0,
432,16,289,3,0,
432,16,290,4,0,
432,16,304,3,0,
432,16,332,4,0,
432,16,343,3,0,
432,16,351,3,0,
432,16,352,3,0,
432,16,369,4,0,
432,16,371,4,0,
432,16,387,3,0,
432,16,416,4,0,
432,16,421,4,0,
432,16,445,1,32,1
432,16,468,1,60,1
432,16,468,4,0,
432,16,492,3,0,
432,16,496,4,0,
432,16,497,4,0,
432,16,514,4,0,
432,16,523,4,0,
432,16,590,4,0,
432,17,10,1,1,3
432,17,10,1,5,
432,17,34,1,45,
432,17,45,1,1,4
432,17,45,1,8,
432,17,46,4,0,
432,17,63,4,0,
432,17,85,4,0,
432,17,87,4,0,
432,17,92,4,0,
432,17,95,1,13,
432,17,104,4,0,
432,17,138,4,0,
432,17,154,1,20,
432,17,156,4,0,
432,17,163,1,37,
432,17,164,4,0,
432,17,168,4,0,
432,17,182,4,0,
432,17,185,1,17,
432,17,204,1,25,
432,17,207,1,0,
432,17,207,1,1,1
432,17,207,4,0,
432,17,213,1,52,
432,17,213,4,0,
432,17,214,4,0,
432,17,216,4,0,
432,17,218,4,0,
432,17,237,4,0,
432,17,240,4,0,
432,17,241,4,0,
432,17,244,4,0,
432,17,247,4,0,
432,17,252,1,1,2
432,17,259,4,0,
432,17,263,4,0,
432,17,269,4,0,
432,17,274,1,29,
432,17,332,4,0,
432,17,369,4,0,
432,17,371,4,0,
432,17,416,4,0,
432,17,421,4,0,
432,17,445,1,32,
432,17,468,1,60,
432,17,496,4,0,
432,17,497,4,0,
432,17,523,4,0,
432,17,526,4,0,
432,17,590,4,0,
432,18,10,1,1,3
432,18,10,1,5,
432,18,34,1,45,
432,18,45,1,1,4
432,18,45,1,8,
432,18,46,4,0,
432,18,63,4,0,
432,18,85,4,0,
432,18,87,4,0,
432,18,92,4,0,
432,18,95,1,13,
432,18,104,4,0,
432,18,138,4,0,
432,18,154,1,20,
432,18,156,4,0,
432,18,163,1,37,
432,18,164,4,0,
432,18,168,4,0,
432,18,182,4,0,
432,18,185,1,17,
432,18,204,1,25,
432,18,207,1,0,
432,18,207,1,1,1
432,18,207,4,0,
432,18,213,1,52,
432,18,213,4,0,
432,18,214,4,0,
432,18,216,4,0,
432,18,218,4,0,
432,18,237,4,0,
432,18,240,4,0,
432,18,241,4,0,
432,18,244,4,0,
432,18,247,4,0,
432,18,252,1,1,2
432,18,259,4,0,
432,18,263,4,0,
432,18,269,4,0,
432,18,274,1,29,
432,18,332,4,0,
432,18,369,4,0,
432,18,371,4,0,
432,18,416,4,0,
432,18,421,4,0,
432,18,445,1,32,
432,18,468,1,60,
432,18,496,4,0,
432,18,497,4,0,
432,18,523,4,0,
432,18,526,4,0,
432,18,590,4,0,
433,8,35,1,1,
433,8,45,1,6,
433,8,50,2,0,
433,8,86,4,0,
433,8,92,4,0,
433,8,93,1,14,
433,8,94,4,0,
433,8,95,2,0,
433,8,104,4,0,
433,8,105,2,0,
433,8,113,4,0,
433,8,115,4,0,
433,8,138,2,0,
433,8,138,4,0,
433,8,148,4,0,
433,8,156,4,0,
433,8,164,4,0,
433,8,174,2,0,
433,8,182,4,0,
433,8,203,4,0,
433,8,207,4,0,
433,8,213,4,0,
433,8,214,4,0,
433,8,216,4,0,
433,8,218,4,0,
433,8,219,4,0,
433,8,237,4,0,
433,8,240,4,0,
433,8,241,4,0,
433,8,244,4,0,
433,8,247,4,0,
433,8,248,2,0,
433,8,253,1,17,
433,8,259,4,0,
433,8,263,4,0,
433,8,269,4,0,
433,8,273,2,0,
433,8,278,4,0,
433,8,285,4,0,
433,8,289,4,0,
433,8,290,4,0,
433,8,310,1,9,
433,8,347,4,0,
433,8,351,4,0,
433,8,363,4,0,
433,8,387,1,22,
433,8,433,4,0,
433,8,445,4,0,
433,8,447,4,0,
433,8,451,4,0,
433,9,35,1,1,
433,9,45,1,6,
433,9,50,2,0,
433,9,86,4,0,
433,9,92,4,0,
433,9,93,1,14,
433,9,94,4,0,
433,9,95,2,0,
433,9,104,4,0,
433,9,105,2,0,
433,9,113,4,0,
433,9,115,4,0,
433,9,129,3,0,
433,9,138,2,0,
433,9,138,4,0,
433,9,148,4,0,
433,9,156,4,0,
433,9,164,4,0,
433,9,173,3,0,
433,9,174,2,0,
433,9,182,4,0,
433,9,196,3,0,
433,9,203,4,0,
433,9,205,3,0,
433,9,207,4,0,
433,9,213,4,0,
433,9,214,4,0,
433,9,216,4,0,
433,9,218,4,0,
433,9,219,4,0,
433,9,237,4,0,
433,9,240,4,0,
433,9,241,4,0,
433,9,244,4,0,
433,9,247,4,0,
433,9,248,2,0,
433,9,253,1,17,
433,9,253,3,0,
433,9,259,4,0,
433,9,263,4,0,
433,9,269,4,0,
433,9,270,3,0,
433,9,271,3,0,
433,9,273,2,0,
433,9,278,4,0,
433,9,282,3,0,
433,9,285,4,0,
433,9,289,4,0,
433,9,290,4,0,
433,9,310,1,9,
433,9,324,3,0,
433,9,347,4,0,
433,9,351,4,0,
433,9,363,4,0,
433,9,387,1,22,
433,9,387,3,0,
433,9,428,3,0,
433,9,433,4,0,
433,9,445,4,0,
433,9,447,4,0,
433,9,451,4,0,
433,10,35,1,1,
433,10,45,1,6,
433,10,50,2,0,
433,10,86,4,0,
433,10,92,4,0,
433,10,93,1,14,
433,10,94,4,0,
433,10,95,2,0,
433,10,104,4,0,
433,10,105,2,0,
433,10,113,4,0,
433,10,115,4,0,
433,10,129,3,0,
433,10,138,2,0,
433,10,138,4,0,
433,10,148,4,0,
433,10,156,4,0,
433,10,164,4,0,
433,10,173,3,0,
433,10,174,2,0,
433,10,182,4,0,
433,10,196,3,0,
433,10,203,4,0,
433,10,205,3,0,
433,10,207,4,0,
433,10,213,4,0,
433,10,214,4,0,
433,10,215,3,0,
433,10,216,4,0,
433,10,218,4,0,
433,10,219,4,0,
433,10,237,4,0,
433,10,240,4,0,
433,10,241,4,0,
433,10,244,4,0,
433,10,247,4,0,
433,10,248,2,0,
433,10,253,1,17,
433,10,253,3,0,
433,10,259,4,0,
433,10,263,4,0,
433,10,269,4,0,
433,10,270,3,0,
433,10,271,3,0,
433,10,273,2,0,
433,10,277,3,0,
433,10,278,4,0,
433,10,282,3,0,
433,10,285,4,0,
433,10,289,4,0,
433,10,290,4,0,
433,10,310,1,9,
433,10,324,3,0,
433,10,347,4,0,
433,10,351,4,0,
433,10,356,3,0,
433,10,363,4,0,
433,10,387,1,22,
433,10,387,3,0,
433,10,428,3,0,
433,10,433,4,0,
433,10,445,4,0,
433,10,447,4,0,
433,10,451,4,0,
433,11,35,1,1,
433,11,45,1,6,
433,11,50,2,0,
433,11,86,4,0,
433,11,92,4,0,
433,11,93,1,14,
433,11,94,4,0,
433,11,95,2,0,
433,11,104,4,0,
433,11,105,2,0,
433,11,113,4,0,
433,11,115,4,0,
433,11,138,4,0,
433,11,148,4,0,
433,11,156,4,0,
433,11,164,4,0,
433,11,174,2,0,
433,11,182,4,0,
433,11,207,4,0,
433,11,213,4,0,
433,11,216,4,0,
433,11,218,4,0,
433,11,219,4,0,
433,11,237,4,0,
433,11,240,4,0,
433,11,241,4,0,
433,11,244,4,0,
433,11,247,4,0,
433,11,248,2,0,
433,11,253,1,17,
433,11,259,4,0,
433,11,263,4,0,
433,11,269,4,0,
433,11,273,2,0,
433,11,285,2,0,
433,11,310,1,9,
433,11,347,4,0,
433,11,387,1,22,
433,11,433,4,0,
433,11,447,4,0,
433,11,451,4,0,
433,11,473,4,0,
433,11,477,4,0,
433,11,494,1,25,
433,11,496,4,0,
433,11,497,4,0,
433,11,500,2,0,
433,14,20,3,0,
433,14,35,1,1,
433,14,45,1,6,
433,14,50,2,0,
433,14,86,4,0,
433,14,92,4,0,
433,14,93,1,14,
433,14,94,4,0,
433,14,95,2,0,
433,14,104,4,0,
433,14,105,2,0,
433,14,113,4,0,
433,14,115,4,0,
433,14,138,4,0,
433,14,148,4,0,
433,14,156,4,0,
433,14,164,4,0,
433,14,173,3,0,
433,14,174,2,0,
433,14,182,4,0,
433,14,196,3,0,
433,14,207,4,0,
433,14,213,4,0,
433,14,214,3,0,
433,14,215,3,0,
433,14,216,4,0,
433,14,218,4,0,
433,14,219,4,0,
433,14,237,4,0,
433,14,240,4,0,
433,14,241,4,0,
433,14,244,4,0,
433,14,247,4,0,
433,14,248,2,0,
433,14,253,1,17,
433,14,253,3,0,
433,14,259,4,0,
433,14,263,4,0,
433,14,269,4,0,
433,14,270,3,0,
433,14,271,3,0,
433,14,273,2,0,
433,14,277,3,0,
433,14,278,3,0,
433,14,282,3,0,
433,14,285,2,0,
433,14,285,3,0,
433,14,289,3,0,
433,14,304,3,0,
433,14,310,1,9,
433,14,324,3,0,
433,14,347,4,0,
433,14,356,3,0,
433,14,387,1,22,
433,14,387,3,0,
433,14,428,3,0,
433,14,433,4,0,
433,14,447,4,0,
433,14,451,4,0,
433,14,473,4,0,
433,14,477,4,0,
433,14,494,1,25,
433,14,496,4,0,
433,14,497,4,0,
433,14,500,2,0,
433,15,35,1,1,
433,15,45,1,6,
433,15,50,2,0,
433,15,86,4,0,
433,15,92,4,0,
433,15,93,1,14,
433,15,94,4,0,
433,15,95,2,0,
433,15,104,4,0,
433,15,105,2,0,
433,15,113,4,0,
433,15,115,4,0,
433,15,138,4,0,
433,15,148,4,0,
433,15,156,4,0,
433,15,164,4,0,
433,15,174,2,0,
433,15,182,4,0,
433,15,207,4,0,
433,15,213,4,0,
433,15,214,4,0,
433,15,216,4,0,
433,15,218,4,0,
433,15,219,4,0,
433,15,237,4,0,
433,15,240,4,0,
433,15,241,4,0,
433,15,244,4,0,
433,15,247,4,0,
433,15,248,2,0,
433,15,253,1,17,
433,15,259,4,0,
433,15,263,4,0,
433,15,269,4,0,
433,15,273,2,0,
433,15,285,2,0,
433,15,310,1,9,
433,15,322,2,0,
433,15,347,4,0,
433,15,387,1,22,
433,15,433,4,0,
433,15,447,4,0,
433,15,451,4,0,
433,15,473,4,0,
433,15,494,1,25,
433,15,496,4,0,
433,15,497,4,0,
433,15,500,2,0,
433,15,590,4,0,
433,15,605,4,0,
433,16,20,3,0,
433,16,35,1,1,1
433,16,45,1,4,1
433,16,50,2,0,
433,16,86,4,0,
433,16,92,4,0,
433,16,93,1,10,1
433,16,94,4,0,
433,16,95,2,0,
433,16,104,4,0,
433,16,105,2,0,
433,16,113,4,0,
433,16,115,4,0,
433,16,138,4,0,
433,16,148,4,0,
433,16,156,4,0,
433,16,164,4,0,
433,16,173,3,0,
433,16,174,2,0,
433,16,182,4,0,
433,16,196,3,0,
433,16,207,4,0,
433,16,213,4,0,
433,16,214,4,0,
433,16,215,3,0,
433,16,216,4,0,
433,16,218,4,0,
433,16,219,4,0,
433,16,237,4,0,
433,16,240,4,0,
433,16,241,4,0,
433,16,244,4,0,
433,16,247,4,0,
433,16,248,2,0,
433,16,253,1,32,1
433,16,253,3,0,
433,16,259,4,0,
433,16,263,4,0,
433,16,269,4,0,
433,16,270,3,0,
433,16,271,3,0,
433,16,273,2,0,
433,16,277,3,0,
433,16,278,3,0,
433,16,281,1,13,1
433,16,282,3,0,
433,16,285,2,0,
433,16,285,3,0,
433,16,289,3,0,
433,16,290,4,0,
433,16,304,3,0,
433,16,310,1,7,1
433,16,322,2,0,
433,16,324,3,0,
433,16,347,4,0,
433,16,351,3,0,
433,16,356,3,0,
433,16,387,1,16,1
433,16,387,3,0,
433,16,428,3,0,
433,16,433,4,0,
433,16,447,4,0,
433,16,451,4,0,
433,16,473,4,0,
433,16,494,1,19,1
433,16,496,4,0,
433,16,497,4,0,
433,16,500,2,0,
433,16,590,4,0,
433,16,605,4,0,
433,17,35,1,1,
433,17,45,1,4,
433,17,50,2,0,
433,17,86,4,0,
433,17,92,4,0,
433,17,93,1,10,
433,17,94,4,0,
433,17,95,2,0,
433,17,104,4,0,
433,17,105,2,0,
433,17,113,4,0,
433,17,115,4,0,
433,17,138,4,0,
433,17,156,4,0,
433,17,164,4,0,
433,17,174,2,0,
433,17,182,4,0,
433,17,207,4,0,
433,17,213,4,0,
433,17,214,4,0,
433,17,216,4,0,
433,17,218,4,0,
433,17,219,4,0,
433,17,237,4,0,
433,17,240,4,0,
433,17,241,4,0,
433,17,244,4,0,
433,17,247,4,0,
433,17,248,2,0,
433,17,253,1,32,
433,17,259,4,0,
433,17,263,4,0,
433,17,269,4,0,
433,17,273,2,0,
433,17,281,1,13,
433,17,285,2,0,
433,17,310,1,7,
433,17,322,2,0,
433,17,347,4,0,
433,17,387,1,16,
433,17,433,4,0,
433,17,447,4,0,
433,17,451,4,0,
433,17,473,4,0,
433,17,494,1,19,
433,17,496,4,0,
433,17,497,4,0,
433,17,500,2,0,
433,17,590,4,0,
433,17,605,4,0,
433,18,35,1,1,
433,18,45,1,4,
433,18,50,2,0,
433,18,86,4,0,
433,18,92,4,0,
433,18,93,1,10,
433,18,94,4,0,
433,18,95,2,0,
433,18,104,4,0,
433,18,105,2,0,
433,18,113,4,0,
433,18,115,4,0,
433,18,138,4,0,
433,18,156,4,0,
433,18,164,4,0,
433,18,174,2,0,
433,18,182,4,0,
433,18,207,4,0,
433,18,213,4,0,
433,18,214,4,0,
433,18,216,4,0,
433,18,218,4,0,
433,18,219,4,0,
433,18,237,4,0,
433,18,240,4,0,
433,18,241,4,0,
433,18,244,4,0,
433,18,247,4,0,
433,18,248,2,0,
433,18,253,1,32,
433,18,259,4,0,
433,18,263,4,0,
433,18,269,4,0,
433,18,273,2,0,
433,18,281,1,13,
433,18,285,2,0,
433,18,310,1,7,
433,18,322,2,0,
433,18,347,4,0,
433,18,387,1,16,
433,18,433,4,0,
433,18,447,4,0,
433,18,451,4,0,
433,18,473,4,0,
433,18,494,1,19,
433,18,496,4,0,
433,18,497,4,0,
433,18,500,2,0,
433,18,590,4,0,
433,18,605,4,0,
434,8,10,1,1,1
434,8,15,4,0,
434,8,38,2,0,
434,8,43,2,0,
434,8,46,4,0,
434,8,53,4,0,
434,8,91,4,0,
434,8,92,1,20,
434,8,92,4,0,
434,8,103,1,7,
434,8,104,4,0,
434,8,108,1,15,
434,8,116,1,1,2
434,8,123,2,0,
434,8,126,4,0,
434,8,139,1,4,
434,8,153,1,44,
434,8,153,4,0,
434,8,154,1,11,
434,8,156,4,0,
434,8,163,1,25,
434,8,164,4,0,
434,8,168,4,0,
434,8,182,4,0,
434,8,184,2,0,
434,8,188,4,0,
434,8,203,4,0,
434,8,207,4,0,
434,8,213,4,0,
434,8,214,4,0,
434,8,216,4,0,
434,8,218,4,0,
434,8,228,2,0,
434,8,231,4,0,
434,8,237,4,0,
434,8,240,4,0,
434,8,241,4,0,
434,8,242,2,0,
434,8,247,4,0,
434,8,249,4,0,
434,8,259,4,0,
434,8,262,1,37,
434,8,263,4,0,
434,8,269,4,0,
434,8,289,4,0,
434,8,290,4,0,
434,8,310,2,0,
434,8,363,4,0,
434,8,371,4,0,
434,8,386,2,0,
434,8,399,4,0,
434,8,400,1,31,
434,8,421,4,0,
434,8,432,4,0,
434,8,445,4,0,
434,9,10,1,1,1
434,9,15,4,0,
434,9,38,2,0,
434,9,43,2,0,
434,9,46,4,0,
434,9,53,4,0,
434,9,91,4,0,
434,9,92,1,27,
434,9,92,4,0,
434,9,103,1,7,
434,9,104,4,0,
434,9,108,1,14,
434,9,116,1,1,2
434,9,123,2,0,
434,9,126,4,0,
434,9,129,3,0,
434,9,139,1,4,
434,9,153,1,44,
434,9,153,4,0,
434,9,154,1,10,
434,9,156,4,0,
434,9,163,1,22,
434,9,164,4,0,
434,9,168,4,0,
434,9,173,3,0,
434,9,182,4,0,
434,9,184,2,0,
434,9,188,4,0,
434,9,189,3,0,
434,9,203,4,0,
434,9,207,4,0,
434,9,210,3,0,
434,9,213,4,0,
434,9,214,4,0,
434,9,216,4,0,
434,9,218,4,0,
434,9,228,2,0,
434,9,231,4,0,
434,9,237,4,0,
434,9,240,4,0,
434,9,241,4,0,
434,9,242,2,0,
434,9,247,4,0,
434,9,249,4,0,
434,9,259,4,0,
434,9,262,1,38,
434,9,263,4,0,
434,9,269,4,0,
434,9,289,4,0,
434,9,290,4,0,
434,9,310,2,0,
434,9,363,4,0,
434,9,364,1,18,
434,9,371,4,0,
434,9,386,2,0,
434,9,389,3,0,
434,9,399,4,0,
434,9,400,1,32,
434,9,421,4,0,
434,9,432,4,0,
434,9,445,4,0,
434,10,10,1,1,1
434,10,15,4,0,
434,10,29,3,0,
434,10,38,2,0,
434,10,43,2,0,
434,10,46,4,0,
434,10,53,4,0,
434,10,91,4,0,
434,10,92,1,27,
434,10,92,4,0,
434,10,103,1,7,
434,10,104,4,0,
434,10,108,1,14,
434,10,114,2,0,
434,10,116,1,1,2
434,10,123,2,0,
434,10,126,4,0,
434,10,129,3,0,
434,10,139,1,4,
434,10,153,1,44,
434,10,153,4,0,
434,10,154,1,10,
434,10,156,4,0,
434,10,163,1,22,
434,10,164,4,0,
434,10,168,4,0,
434,10,173,3,0,
434,10,182,4,0,
434,10,184,2,0,
434,10,188,4,0,
434,10,189,3,0,
434,10,203,4,0,
434,10,207,4,0,
434,10,210,3,0,
434,10,213,4,0,
434,10,214,4,0,
434,10,216,4,0,
434,10,218,4,0,
434,10,228,2,0,
434,10,231,4,0,
434,10,237,4,0,
434,10,240,4,0,
434,10,241,4,0,
434,10,242,2,0,
434,10,247,4,0,
434,10,249,4,0,
434,10,259,4,0,
434,10,262,1,38,
434,10,263,4,0,
434,10,269,4,0,
434,10,289,4,0,
434,10,290,4,0,
434,10,310,2,0,
434,10,363,4,0,
434,10,364,1,18,
434,10,371,4,0,
434,10,386,2,0,
434,10,389,3,0,
434,10,399,4,0,
434,10,400,1,32,
434,10,421,4,0,
434,10,445,4,0,
434,11,10,1,1,1
434,11,15,4,0,
434,11,38,2,0,
434,11,43,2,0,
434,11,46,4,0,
434,11,53,4,0,
434,11,91,4,0,
434,11,92,1,27,
434,11,92,4,0,
434,11,103,1,7,
434,11,104,4,0,
434,11,108,1,14,
434,11,114,2,0,
434,11,116,1,1,2
434,11,123,2,0,
434,11,126,4,0,
434,11,139,1,4,
434,11,153,1,49,
434,11,153,4,0,
434,11,154,1,10,
434,11,156,4,0,
434,11,163,1,22,
434,11,164,4,0,
434,11,168,4,0,
434,11,182,4,0,
434,11,184,2,0,
434,11,188,4,0,
434,11,207,4,0,
434,11,213,4,0,
434,11,216,4,0,
434,11,218,4,0,
434,11,228,2,0,
434,11,231,2,0,
434,11,237,4,0,
434,11,240,4,0,
434,11,241,4,0,
434,11,242,2,0,
434,11,247,4,0,
434,11,249,4,0,
434,11,259,4,0,
434,11,262,1,43,
434,11,263,4,0,
434,11,269,4,0,
434,11,310,2,0,
434,11,364,1,18,
434,11,371,4,0,
434,11,386,2,0,
434,11,400,1,37,
434,11,421,4,0,
434,11,468,4,0,
434,11,474,4,0,
434,11,481,2,0,
434,11,491,1,32,
434,11,492,2,0,
434,11,496,4,0,
434,11,510,4,0,
434,11,555,4,0,
434,14,10,1,1,1
434,14,15,4,0,
434,14,38,2,0,
434,14,43,2,0,
434,14,46,4,0,
434,14,53,4,0,
434,14,91,4,0,
434,14,92,1,27,
434,14,92,4,0,
434,14,103,1,7,
434,14,104,4,0,
434,14,108,1,14,
434,14,114,2,0,
434,14,116,1,1,2
434,14,123,2,0,
434,14,126,4,0,
434,14,139,1,4,
434,14,153,1,49,
434,14,153,4,0,
434,14,154,1,10,
434,14,156,4,0,
434,14,163,1,22,
434,14,164,4,0,
434,14,168,4,0,
434,14,173,3,0,
434,14,182,4,0,
434,14,184,2,0,
434,14,188,4,0,
434,14,207,4,0,
434,14,213,4,0,
434,14,214,3,0,
434,14,216,4,0,
434,14,218,4,0,
434,14,228,2,0,
434,14,231,2,0,
434,14,231,3,0,
434,14,237,4,0,
434,14,240,4,0,
434,14,241,4,0,
434,14,242,2,0,
434,14,247,4,0,
434,14,249,4,0,
434,14,259,4,0,
434,14,262,1,43,
434,14,263,4,0,
434,14,269,4,0,
434,14,289,3,0,
434,14,310,2,0,
434,14,364,1,18,
434,14,371,4,0,
434,14,386,2,0,
434,14,399,3,0,
434,14,400,1,37,
434,14,421,4,0,
434,14,468,4,0,
434,14,474,4,0,
434,14,481,2,0,
434,14,491,1,32,
434,14,492,2,0,
434,14,492,3,0,
434,14,496,4,0,
434,14,510,4,0,
434,14,555,4,0,
434,15,10,1,1,1
434,15,15,4,0,
434,15,38,2,0,
434,15,43,2,0,
434,15,46,4,0,
434,15,53,4,0,
434,15,91,4,0,
434,15,92,1,27,
434,15,92,4,0,
434,15,103,1,7,
434,15,104,4,0,
434,15,108,1,14,
434,15,114,2,0,
434,15,116,1,1,2
434,15,123,2,0,
434,15,126,4,0,
434,15,139,1,4,
434,15,153,1,49,
434,15,153,4,0,
434,15,154,1,10,
434,15,156,4,0,
434,15,163,1,22,
434,15,164,4,0,
434,15,168,4,0,
434,15,182,4,0,
434,15,184,2,0,
434,15,188,4,0,
434,15,207,4,0,
434,15,213,4,0,
434,15,214,4,0,
434,15,216,4,0,
434,15,218,4,0,
434,15,228,2,0,
434,15,231,2,0,
434,15,237,4,0,
434,15,240,4,0,
434,15,241,4,0,
434,15,242,2,0,
434,15,247,4,0,
434,15,249,4,0,
434,15,259,4,0,
434,15,262,1,43,
434,15,263,4,0,
434,15,269,4,0,
434,15,310,2,0,
434,15,364,1,18,
434,15,371,4,0,
434,15,386,2,0,
434,15,399,4,0,
434,15,400,1,37,
434,15,421,4,0,
434,15,468,4,0,
434,15,474,4,0,
434,15,481,2,0,
434,15,491,1,32,
434,15,492,2,0,
434,15,496,4,0,
434,15,510,4,0,
434,15,555,4,0,
434,15,562,1,46,
434,15,583,2,0,
434,15,590,4,0,
434,16,10,1,1,1
434,16,15,4,0,
434,16,38,2,0,
434,16,43,2,0,
434,16,46,4,0,
434,16,53,4,0,
434,16,91,4,0,
434,16,92,1,27,1
434,16,92,4,0,
434,16,103,1,7,1
434,16,104,4,0,
434,16,108,1,14,1
434,16,114,2,0,
434,16,116,1,1,2
434,16,123,2,0,
434,16,126,4,0,
434,16,139,1,4,1
434,16,153,1,49,1
434,16,153,4,0,
434,16,154,1,10,1
434,16,156,4,0,
434,16,163,1,22,1
434,16,164,4,0,
434,16,168,4,0,
434,16,173,3,0,
434,16,182,4,0,
434,16,184,2,0,
434,16,188,4,0,
434,16,207,4,0,
434,16,213,4,0,
434,16,214,4,0,
434,16,216,4,0,
434,16,218,4,0,
434,16,228,2,0,
434,16,231,2,0,
434,16,231,3,0,
434,16,237,4,0,
434,16,240,4,0,
434,16,241,4,0,
434,16,242,2,0,
434,16,247,4,0,
434,16,249,4,0,
434,16,259,4,0,
434,16,262,1,43,1
434,16,263,4,0,
434,16,269,4,0,
434,16,289,3,0,
434,16,290,4,0,
434,16,310,2,0,
434,16,364,1,18,1
434,16,371,4,0,
434,16,386,2,0,
434,16,399,4,0,
434,16,400,1,37,1
434,16,421,4,0,
434,16,468,4,0,
434,16,474,4,0,
434,16,481,2,0,
434,16,491,1,32,1
434,16,492,2,0,
434,16,492,3,0,
434,16,496,4,0,
434,16,510,4,0,
434,16,555,4,0,
434,16,562,1,46,1
434,16,583,2,0,
434,16,590,4,0,
434,17,10,1,1,1
434,17,38,2,0,
434,17,43,2,0,
434,17,44,1,21,
434,17,46,4,0,
434,17,53,4,0,
434,17,92,1,27,
434,17,92,4,0,
434,17,103,1,7,
434,17,104,4,0,
434,17,108,1,13,
434,17,114,2,0,
434,17,116,1,1,2
434,17,123,2,0,
434,17,126,4,0,
434,17,139,1,3,
434,17,153,1,45,
434,17,153,4,0,
434,17,154,1,9,
434,17,156,4,0,
434,17,163,1,25,
434,17,164,4,0,
434,17,168,4,0,
434,17,182,4,0,
434,17,184,2,0,
434,17,188,4,0,
434,17,207,4,0,
434,17,213,4,0,
434,17,214,4,0,
434,17,216,4,0,
434,17,218,4,0,
434,17,228,2,0,
434,17,231,2,0,
434,17,237,4,0,
434,17,240,4,0,
434,17,241,4,0,
434,17,242,2,0,
434,17,247,4,0,
434,17,259,4,0,
434,17,262,1,33,
434,17,263,4,0,
434,17,269,4,0,
434,17,310,2,0,
434,17,364,1,15,
434,17,371,4,0,
434,17,386,2,0,
434,17,389,1,39,
434,17,399,4,0,
434,17,400,1,31,
434,17,421,4,0,
434,17,474,4,0,
434,17,481,2,0,
434,17,491,1,19,
434,17,492,2,0,
434,17,496,4,0,
434,17,555,4,0,
434,17,562,1,43,
434,17,583,2,0,
434,17,590,4,0,
434,17,599,1,37,
434,18,10,1,1,1
434,18,38,2,0,
434,18,43,2,0,
434,18,44,1,21,
434,18,46,4,0,
434,18,53,4,0,
434,18,92,1,27,
434,18,92,4,0,
434,18,103,1,7,
434,18,104,4,0,
434,18,108,1,13,
434,18,114,2,0,
434,18,116,1,1,2
434,18,123,2,0,
434,18,126,4,0,
434,18,139,1,3,
434,18,153,1,45,
434,18,153,4,0,
434,18,154,1,9,
434,18,156,4,0,
434,18,163,1,25,
434,18,164,4,0,
434,18,168,4,0,
434,18,182,4,0,
434,18,184,2,0,
434,18,188,4,0,
434,18,207,4,0,
434,18,213,4,0,
434,18,214,4,0,
434,18,216,4,0,
434,18,218,4,0,
434,18,228,2,0,
434,18,231,2,0,
434,18,237,4,0,
434,18,240,4,0,
434,18,241,4,0,
434,18,242,2,0,
434,18,247,4,0,
434,18,259,4,0,
434,18,262,1,33,
434,18,263,4,0,
434,18,269,4,0,
434,18,310,2,0,
434,18,364,1,15,
434,18,371,4,0,
434,18,386,2,0,
434,18,389,1,39,
434,18,399,4,0,
434,18,400,1,31,
434,18,421,4,0,
434,18,474,4,0,
434,18,481,2,0,
434,18,491,1,19,
434,18,492,2,0,
434,18,496,4,0,
434,18,555,4,0,
434,18,562,1,43,
434,18,583,2,0,
434,18,590,4,0,
434,18,599,1,37,
435,8,10,1,1,1
435,8,15,4,0,
435,8,46,4,0,
435,8,53,1,34,
435,8,53,4,0,
435,8,63,4,0,
435,8,70,4,0,
435,8,91,4,0,
435,8,92,1,20,
435,8,92,4,0,
435,8,103,1,7,
435,8,104,4,0,
435,8,108,1,15,
435,8,116,1,1,2
435,8,126,4,0,
435,8,139,1,1,3
435,8,139,1,4,
435,8,153,1,52,
435,8,153,4,0,
435,8,154,1,11,
435,8,156,4,0,
435,8,163,1,25,
435,8,164,4,0,
435,8,168,4,0,
435,8,182,4,0,
435,8,188,4,0,
435,8,203,4,0,
435,8,207,4,0,
435,8,213,4,0,
435,8,214,4,0,
435,8,216,4,0,
435,8,218,4,0,
435,8,231,4,0,
435,8,237,4,0,
435,8,240,4,0,
435,8,241,4,0,
435,8,247,4,0,
435,8,249,4,0,
435,8,259,4,0,
435,8,262,1,41,
435,8,263,4,0,
435,8,269,4,0,
435,8,289,4,0,
435,8,290,4,0,
435,8,363,4,0,
435,8,371,4,0,
435,8,398,4,0,
435,8,399,4,0,
435,8,400,1,31,
435,8,416,4,0,
435,8,421,4,0,
435,8,432,4,0,
435,8,445,4,0,
435,9,10,1,1,1
435,9,15,4,0,
435,9,46,4,0,
435,9,53,1,34,
435,9,53,4,0,
435,9,63,4,0,
435,9,70,4,0,
435,9,91,4,0,
435,9,92,1,27,
435,9,92,4,0,
435,9,103,1,7,
435,9,104,4,0,
435,9,108,1,14,
435,9,116,1,1,2
435,9,126,4,0,
435,9,129,3,0,
435,9,139,1,1,3
435,9,139,1,4,
435,9,153,1,52,
435,9,153,4,0,
435,9,154,1,10,
435,9,156,4,0,
435,9,163,1,22,
435,9,164,4,0,
435,9,168,4,0,
435,9,173,3,0,
435,9,182,4,0,
435,9,188,4,0,
435,9,189,3,0,
435,9,203,4,0,
435,9,207,4,0,
435,9,210,3,0,
435,9,213,4,0,
435,9,214,4,0,
435,9,216,4,0,
435,9,218,4,0,
435,9,231,4,0,
435,9,237,4,0,
435,9,240,4,0,
435,9,241,4,0,
435,9,247,4,0,
435,9,249,4,0,
435,9,259,4,0,
435,9,262,1,42,
435,9,263,4,0,
435,9,269,4,0,
435,9,289,4,0,
435,9,290,4,0,
435,9,363,4,0,
435,9,364,1,18,
435,9,371,4,0,
435,9,389,3,0,
435,9,398,4,0,
435,9,399,4,0,
435,9,400,1,32,
435,9,416,4,0,
435,9,421,4,0,
435,9,432,4,0,
435,9,445,4,0,
435,10,10,1,1,1
435,10,15,4,0,
435,10,29,3,0,
435,10,46,4,0,
435,10,53,1,34,
435,10,53,4,0,
435,10,63,4,0,
435,10,70,4,0,
435,10,91,4,0,
435,10,92,1,27,
435,10,92,4,0,
435,10,103,1,7,
435,10,104,4,0,
435,10,108,1,14,
435,10,116,1,1,2
435,10,126,4,0,
435,10,129,3,0,
435,10,139,1,1,3
435,10,139,1,4,
435,10,153,1,52,
435,10,153,4,0,
435,10,154,1,10,
435,10,156,4,0,
435,10,163,1,22,
435,10,164,4,0,
435,10,168,4,0,
435,10,173,3,0,
435,10,182,4,0,
435,10,188,4,0,
435,10,189,3,0,
435,10,203,4,0,
435,10,207,4,0,
435,10,210,3,0,
435,10,213,4,0,
435,10,214,4,0,
435,10,216,4,0,
435,10,218,4,0,
435,10,231,4,0,
435,10,237,4,0,
435,10,240,4,0,
435,10,241,4,0,
435,10,247,4,0,
435,10,249,4,0,
435,10,259,4,0,
435,10,262,1,42,
435,10,263,4,0,
435,10,269,4,0,
435,10,289,4,0,
435,10,290,4,0,
435,10,363,4,0,
435,10,364,1,18,
435,10,371,4,0,
435,10,389,3,0,
435,10,398,4,0,
435,10,399,4,0,
435,10,400,1,32,
435,10,416,4,0,
435,10,421,4,0,
435,10,445,4,0,
435,11,10,1,1,1
435,11,15,4,0,
435,11,46,4,0,
435,11,53,1,34,
435,11,53,4,0,
435,11,63,4,0,
435,11,70,4,0,
435,11,91,4,0,
435,11,92,1,27,
435,11,92,4,0,
435,11,103,1,7,
435,11,104,4,0,
435,11,108,1,14,
435,11,116,1,1,2
435,11,126,4,0,
435,11,139,1,1,3
435,11,139,1,4,
435,11,153,1,61,
435,11,153,4,0,
435,11,154,1,10,
435,11,156,4,0,
435,11,163,1,22,
435,11,164,4,0,
435,11,168,4,0,
435,11,182,4,0,
435,11,188,4,0,
435,11,207,4,0,
435,11,213,4,0,
435,11,216,4,0,
435,11,218,4,0,
435,11,237,4,0,
435,11,240,4,0,
435,11,241,4,0,
435,11,247,4,0,
435,11,249,4,0,
435,11,259,4,0,
435,11,262,1,51,
435,11,263,4,0,
435,11,269,4,0,
435,11,364,1,18,
435,11,371,4,0,
435,11,398,4,0,
435,11,400,1,41,
435,11,416,4,0,
435,11,421,4,0,
435,11,468,4,0,
435,11,474,4,0,
435,11,491,1,32,
435,11,496,4,0,
435,11,510,4,0,
435,11,555,4,0,
435,14,10,1,1,1
435,14,15,4,0,
435,14,46,4,0,
435,14,53,1,34,
435,14,53,4,0,
435,14,63,4,0,
435,14,70,4,0,
435,14,91,4,0,
435,14,92,1,27,
435,14,92,4,0,
435,14,103,1,7,
435,14,104,4,0,
435,14,108,1,14,
435,14,116,1,1,2
435,14,126,4,0,
435,14,139,1,1,3
435,14,139,1,4,
435,14,153,1,61,
435,14,153,4,0,
435,14,154,1,10,
435,14,156,4,0,
435,14,163,1,22,
435,14,164,4,0,
435,14,168,4,0,
435,14,173,3,0,
435,14,182,4,0,
435,14,188,4,0,
435,14,207,4,0,
435,14,213,4,0,
435,14,214,3,0,
435,14,216,4,0,
435,14,218,4,0,
435,14,231,3,0,
435,14,237,4,0,
435,14,240,4,0,
435,14,241,4,0,
435,14,247,4,0,
435,14,249,4,0,
435,14,259,4,0,
435,14,262,1,51,
435,14,263,4,0,
435,14,269,4,0,
435,14,289,3,0,
435,14,364,1,18,
435,14,371,4,0,
435,14,398,4,0,
435,14,399,3,0,
435,14,400,1,41,
435,14,416,4,0,
435,14,421,4,0,
435,14,468,4,0,
435,14,474,4,0,
435,14,491,1,32,
435,14,492,3,0,
435,14,496,4,0,
435,14,510,4,0,
435,14,555,4,0,
435,15,10,1,1,1
435,15,15,4,0,
435,15,46,4,0,
435,15,53,1,34,
435,15,53,4,0,
435,15,63,4,0,
435,15,70,4,0,
435,15,91,4,0,
435,15,92,1,27,
435,15,92,4,0,
435,15,103,1,7,
435,15,104,4,0,
435,15,108,1,14,
435,15,116,1,1,2
435,15,126,4,0,
435,15,139,1,1,3
435,15,139,1,4,
435,15,153,1,61,
435,15,153,4,0,
435,15,154,1,10,
435,15,156,4,0,
435,15,163,1,22,
435,15,164,4,0,
435,15,168,4,0,
435,15,182,4,0,
435,15,188,4,0,
435,15,207,4,0,
435,15,213,4,0,
435,15,214,4,0,
435,15,216,4,0,
435,15,218,4,0,
435,15,237,4,0,
435,15,240,4,0,
435,15,241,4,0,
435,15,247,4,0,
435,15,249,4,0,
435,15,259,4,0,
435,15,262,1,51,
435,15,263,4,0,
435,15,269,4,0,
435,15,364,1,18,
435,15,371,4,0,
435,15,398,4,0,
435,15,399,4,0,
435,15,400,1,41,
435,15,416,4,0,
435,15,421,4,0,
435,15,468,4,0,
435,15,474,4,0,
435,15,491,1,32,
435,15,496,4,0,
435,15,510,4,0,
435,15,555,4,0,
435,15,562,1,56,
435,15,590,4,0,
435,16,10,1,1,1
435,16,15,4,0,
435,16,46,4,0,
435,16,53,1,34,1
435,16,53,4,0,
435,16,63,4,0,
435,16,70,4,0,
435,16,91,4,0,
435,16,92,1,27,1
435,16,92,4,0,
435,16,103,1,7,1
435,16,104,4,0,
435,16,108,1,14,1
435,16,116,1,1,2
435,16,126,4,0,
435,16,139,1,1,3
435,16,139,1,4,1
435,16,153,1,61,1
435,16,153,4,0,
435,16,154,1,10,1
435,16,156,4,0,
435,16,163,1,22,1
435,16,164,4,0,
435,16,168,4,0,
435,16,173,3,0,
435,16,182,4,0,
435,16,188,4,0,
435,16,207,4,0,
435,16,213,4,0,
435,16,214,4,0,
435,16,216,4,0,
435,16,218,4,0,
435,16,231,3,0,
435,16,237,4,0,
435,16,240,4,0,
435,16,241,4,0,
435,16,247,4,0,
435,16,249,4,0,
435,16,259,4,0,
435,16,262,1,51,1
435,16,263,4,0,
435,16,269,4,0,
435,16,289,3,0,
435,16,290,4,0,
435,16,364,1,18,1
435,16,371,4,0,
435,16,398,4,0,
435,16,399,4,0,
435,16,400,1,41,1
435,16,416,4,0,
435,16,421,4,0,
435,16,468,4,0,
435,16,474,4,0,
435,16,491,1,32,1
435,16,492,3,0,
435,16,496,4,0,
435,16,510,4,0,
435,16,555,4,0,
435,16,562,1,56,1
435,16,590,4,0,
435,17,10,1,1,2
435,17,44,1,21,
435,17,46,4,0,
435,17,53,1,0,
435,17,53,1,1,1
435,17,53,4,0,
435,17,63,4,0,
435,17,92,1,27,
435,17,92,4,0,
435,17,103,1,1,5
435,17,103,1,7,
435,17,104,4,0,
435,17,108,1,13,
435,17,116,1,1,3
435,17,126,4,0,
435,17,139,1,1,4
435,17,139,1,3,
435,17,153,1,45,
435,17,153,4,0,
435,17,154,1,9,
435,17,156,4,0,
435,17,163,1,25,
435,17,164,4,0,
435,17,168,4,0,
435,17,182,4,0,
435,17,188,4,0,
435,17,207,4,0,
435,17,213,4,0,
435,17,214,4,0,
435,17,216,4,0,
435,17,218,4,0,
435,17,237,4,0,
435,17,240,4,0,
435,17,241,4,0,
435,17,247,4,0,
435,17,259,4,0,
435,17,262,1,33,
435,17,263,4,0,
435,17,269,4,0,
435,17,364,1,15,
435,17,371,4,0,
435,17,389,1,39,
435,17,398,4,0,
435,17,399,4,0,
435,17,400,1,31,
435,17,416,4,0,
435,17,421,4,0,
435,17,474,4,0,
435,17,491,1,19,
435,17,496,4,0,
435,17,555,4,0,
435,17,562,1,43,
435,17,590,4,0,
435,17,599,1,37,
435,18,10,1,1,2
435,18,44,1,21,
435,18,46,4,0,
435,18,53,1,0,
435,18,53,1,1,1
435,18,53,4,0,
435,18,63,4,0,
435,18,92,1,27,
435,18,92,4,0,
435,18,103,1,1,5
435,18,103,1,7,
435,18,104,4,0,
435,18,108,1,13,
435,18,116,1,1,3
435,18,126,4,0,
435,18,139,1,1,4
435,18,139,1,3,
435,18,153,1,45,
435,18,153,4,0,
435,18,154,1,9,
435,18,156,4,0,
435,18,163,1,25,
435,18,164,4,0,
435,18,168,4,0,
435,18,182,4,0,
435,18,188,4,0,
435,18,207,4,0,
435,18,213,4,0,
435,18,214,4,0,
435,18,216,4,0,
435,18,218,4,0,
435,18,237,4,0,
435,18,240,4,0,
435,18,241,4,0,
435,18,247,4,0,
435,18,259,4,0,
435,18,262,1,33,
435,18,263,4,0,
435,18,269,4,0,
435,18,364,1,15,
435,18,371,4,0,
435,18,389,1,39,
435,18,398,4,0,
435,18,399,4,0,
435,18,400,1,31,
435,18,416,4,0,
435,18,421,4,0,
435,18,474,4,0,
435,18,491,1,19,
435,18,496,4,0,
435,18,555,4,0,
435,18,562,1,43,
435,18,590,4,0,
435,18,599,1,37,
436,8,33,1,1,1
436,8,76,4,0,
436,8,89,4,0,
436,8,92,4,0,
436,8,93,1,1,2
436,8,94,4,0,
436,8,95,1,7,
436,8,104,4,0,
436,8,109,1,14,
436,8,113,4,0,
436,8,115,4,0,
436,8,138,4,0,
436,8,148,4,0,
436,8,156,4,0,
436,8,157,4,0,
436,8,164,4,0,
436,8,182,4,0,
436,8,185,1,41,
436,8,201,4,0,
436,8,203,4,0,
436,8,207,4,0,
436,8,214,4,0,
436,8,216,4,0,
436,8,218,4,0,
436,8,219,1,30,
436,8,219,4,0,
436,8,237,4,0,
436,8,240,4,0,
436,8,241,4,0,
436,8,244,4,0,
436,8,247,4,0,
436,8,248,1,37,
436,8,263,4,0,
436,8,278,4,0,
436,8,285,4,0,
436,8,286,1,12,
436,8,290,4,0,
436,8,317,4,0,
436,8,326,1,19,
436,8,334,1,26,
436,8,347,4,0,
436,8,360,1,35,
436,8,360,4,0,
436,8,363,4,0,
436,8,371,1,49,
436,8,371,4,0,
436,8,377,1,52,
436,8,397,4,0,
436,8,430,4,0,
436,8,433,4,0,
436,8,446,4,0,
436,8,447,4,0,
436,8,451,4,0,
436,9,33,1,1,1
436,9,76,4,0,
436,9,89,4,0,
436,9,92,4,0,
436,9,93,1,1,2
436,9,94,4,0,
436,9,95,1,7,
436,9,104,4,0,
436,9,109,1,14,
436,9,113,4,0,
436,9,115,4,0,
436,9,138,4,0,
436,9,148,4,0,
436,9,156,4,0,
436,9,157,4,0,
436,9,164,4,0,
436,9,173,3,0,
436,9,182,4,0,
436,9,185,1,41,
436,9,201,4,0,
436,9,203,4,0,
436,9,205,3,0,
436,9,207,4,0,
436,9,214,4,0,
436,9,216,4,0,
436,9,218,4,0,
436,9,219,1,30,
436,9,219,4,0,
436,9,237,4,0,
436,9,240,4,0,
436,9,241,4,0,
436,9,244,4,0,
436,9,246,3,0,
436,9,247,4,0,
436,9,248,1,37,
436,9,263,4,0,
436,9,271,3,0,
436,9,278,4,0,
436,9,285,4,0,
436,9,286,1,12,
436,9,290,4,0,
436,9,317,4,0,
436,9,324,3,0,
436,9,326,1,19,
436,9,334,1,26,
436,9,347,4,0,
436,9,360,1,35,
436,9,360,4,0,
436,9,363,4,0,
436,9,371,1,49,
436,9,371,4,0,
436,9,377,1,52,
436,9,397,4,0,
436,9,430,4,0,
436,9,433,4,0,
436,9,446,4,0,
436,9,447,4,0,
436,9,451,4,0,
436,10,33,1,1,1
436,10,76,4,0,
436,10,89,4,0,
436,10,92,4,0,
436,10,93,1,1,2
436,10,94,4,0,
436,10,95,1,7,
436,10,104,4,0,
436,10,109,1,14,
436,10,113,4,0,
436,10,115,4,0,
436,10,138,4,0,
436,10,148,4,0,
436,10,156,4,0,
436,10,157,4,0,
436,10,164,4,0,
436,10,173,3,0,
436,10,182,4,0,
436,10,185,1,41,
436,10,201,4,0,
436,10,203,4,0,
436,10,205,3,0,
436,10,207,4,0,
436,10,214,4,0,
436,10,216,4,0,
436,10,218,4,0,
436,10,219,1,30,
436,10,219,4,0,
436,10,237,4,0,
436,10,240,4,0,
436,10,241,4,0,
436,10,244,4,0,
436,10,246,3,0,
436,10,247,4,0,
436,10,248,1,37,
436,10,263,4,0,
436,10,271,3,0,
436,10,278,4,0,
436,10,285,4,0,
436,10,286,1,12,
436,10,290,4,0,
436,10,317,4,0,
436,10,324,3,0,
436,10,326,1,19,
436,10,334,1,26,
436,10,347,4,0,
436,10,356,3,0,
436,10,360,1,35,
436,10,360,4,0,
436,10,363,4,0,
436,10,371,1,49,
436,10,371,4,0,
436,10,377,1,52,
436,10,397,4,0,
436,10,430,4,0,
436,10,433,4,0,
436,10,446,4,0,
436,10,447,4,0,
436,10,451,4,0,
436,11,33,1,1,1
436,11,76,4,0,
436,11,89,4,0,
436,11,92,4,0,
436,11,93,1,1,2
436,11,94,4,0,
436,11,95,1,7,
436,11,104,4,0,
436,11,109,1,14,
436,11,113,4,0,
436,11,115,4,0,
436,11,138,4,0,
436,11,148,4,0,
436,11,156,4,0,
436,11,157,4,0,
436,11,164,4,0,
436,11,182,4,0,
436,11,185,1,41,
436,11,201,4,0,
436,11,207,4,0,
436,11,216,4,0,
436,11,218,4,0,
436,11,219,1,30,
436,11,219,4,0,
436,11,237,4,0,
436,11,240,4,0,
436,11,241,4,0,
436,11,244,4,0,
436,11,247,4,0,
436,11,248,1,37,
436,11,263,4,0,
436,11,286,1,12,
436,11,317,4,0,
436,11,326,1,19,
436,11,334,1,26,
436,11,347,4,0,
436,11,360,1,35,
436,11,360,4,0,
436,11,371,1,49,
436,11,371,4,0,
436,11,377,1,52,
436,11,397,4,0,
436,11,430,4,0,
436,11,433,4,0,
436,11,447,4,0,
436,11,451,4,0,
436,11,473,4,0,
436,11,477,4,0,
436,11,484,1,54,
436,11,496,4,0,
436,11,523,4,0,
436,14,33,1,1,1
436,14,76,4,0,
436,14,89,4,0,
436,14,92,4,0,
436,14,93,1,1,2
436,14,94,4,0,
436,14,95,1,5,
436,14,104,4,0,
436,14,109,1,11,
436,14,113,4,0,
436,14,115,4,0,
436,14,138,4,0,
436,14,148,4,0,
436,14,149,1,15,
436,14,156,4,0,
436,14,157,4,0,
436,14,164,4,0,
436,14,173,3,0,
436,14,182,4,0,
436,14,185,1,21,
436,14,201,4,0,
436,14,207,4,0,
436,14,214,3,0,
436,14,216,4,0,
436,14,218,4,0,
436,14,219,1,25,
436,14,219,4,0,
436,14,237,4,0,
436,14,240,4,0,
436,14,241,4,0,
436,14,244,4,0,
436,14,247,4,0,
436,14,248,1,29,
436,14,263,4,0,
436,14,271,3,0,
436,14,278,3,0,
436,14,285,3,0,
436,14,286,1,9,
436,14,317,4,0,
436,14,319,1,31,
436,14,324,3,0,
436,14,326,1,39,
436,14,334,1,19,
436,14,334,3,0,
436,14,347,4,0,
436,14,356,3,0,
436,14,360,1,35,
436,14,360,4,0,
436,14,371,1,41,
436,14,371,4,0,
436,14,377,1,45,
436,14,397,4,0,
436,14,430,4,0,
436,14,433,4,0,
436,14,446,3,0,
436,14,447,4,0,
436,14,451,4,0,
436,14,472,3,0,
436,14,473,4,0,
436,14,477,4,0,
436,14,484,1,49,
436,14,496,4,0,
436,14,523,4,0,
436,15,33,1,1,1
436,15,76,4,0,
436,15,89,4,0,
436,15,92,4,0,
436,15,93,1,1,2
436,15,94,4,0,
436,15,95,1,5,
436,15,104,4,0,
436,15,109,1,11,
436,15,113,4,0,
436,15,115,4,0,
436,15,138,4,0,
436,15,148,4,0,
436,15,149,1,15,
436,15,156,4,0,
436,15,157,4,0,
436,15,164,4,0,
436,15,182,4,0,
436,15,185,1,21,
436,15,201,4,0,
436,15,207,4,0,
436,15,214,4,0,
436,15,216,4,0,
436,15,218,4,0,
436,15,219,1,25,
436,15,219,4,0,
436,15,237,4,0,
436,15,240,4,0,
436,15,241,4,0,
436,15,244,4,0,
436,15,247,4,0,
436,15,248,1,29,
436,15,263,4,0,
436,15,286,1,9,
436,15,317,4,0,
436,15,319,1,31,
436,15,326,1,39,
436,15,334,1,19,
436,15,347,4,0,
436,15,360,1,35,
436,15,360,4,0,
436,15,371,1,41,
436,15,371,4,0,
436,15,377,1,45,
436,15,397,4,0,
436,15,430,4,0,
436,15,433,4,0,
436,15,447,4,0,
436,15,451,4,0,
436,15,473,4,0,
436,15,484,1,49,
436,15,496,4,0,
436,15,523,4,0,
436,15,590,4,0,
436,16,33,1,1,1
436,16,76,4,0,
436,16,89,4,0,
436,16,92,4,0,
436,16,93,1,1,2
436,16,94,4,0,
436,16,95,1,5,1
436,16,104,4,0,
436,16,109,1,11,1
436,16,113,4,0,
436,16,115,4,0,
436,16,138,4,0,
436,16,148,4,0,
436,16,149,1,15,1
436,16,156,4,0,
436,16,157,4,0,
436,16,164,4,0,
436,16,173,3,0,
436,16,182,4,0,
436,16,185,1,21,1
436,16,201,4,0,
436,16,207,4,0,
436,16,214,4,0,
436,16,216,4,0,
436,16,218,4,0,
436,16,219,1,25,1
436,16,219,4,0,
436,16,237,4,0,
436,16,240,4,0,
436,16,241,4,0,
436,16,244,4,0,
436,16,247,4,0,
436,16,248,1,29,1
436,16,263,4,0,
436,16,271,3,0,
436,16,278,3,0,
436,16,285,3,0,
436,16,286,1,9,1
436,16,290,4,0,
436,16,317,4,0,
436,16,319,1,31,1
436,16,324,3,0,
436,16,326,1,39,1
436,16,334,1,19,1
436,16,334,3,0,
436,16,347,4,0,
436,16,356,3,0,
436,16,360,1,35,1
436,16,360,4,0,
436,16,371,1,41,1
436,16,371,4,0,
436,16,377,1,45,1
436,16,397,4,0,
436,16,430,4,0,
436,16,433,4,0,
436,16,446,3,0,
436,16,447,4,0,
436,16,451,4,0,
436,16,472,3,0,
436,16,473,4,0,
436,16,484,1,49,1
436,16,496,4,0,
436,16,523,4,0,
436,16,590,4,0,
436,17,33,1,1,1
436,17,76,4,0,
436,17,89,4,0,
436,17,92,4,0,
436,17,93,1,1,2
436,17,94,4,0,
436,17,95,1,5,
436,17,104,4,0,
436,17,109,1,11,
436,17,113,4,0,
436,17,115,4,0,
436,17,138,4,0,
436,17,149,1,15,
436,17,156,4,0,
436,17,157,4,0,
436,17,164,4,0,
436,17,182,4,0,
436,17,185,1,21,
436,17,201,4,0,
436,17,207,4,0,
436,17,214,4,0,
436,17,216,4,0,
436,17,218,4,0,
436,17,219,1,25,
436,17,219,4,0,
436,17,237,4,0,
436,17,240,4,0,
436,17,241,4,0,
436,17,244,4,0,
436,17,247,4,0,
436,17,248,1,29,
436,17,263,4,0,
436,17,286,1,9,
436,17,317,4,0,
436,17,319,1,31,
436,17,326,1,39,
436,17,334,1,19,
436,17,347,4,0,
436,17,360,1,35,
436,17,360,4,0,
436,17,371,1,41,
436,17,371,4,0,
436,17,377,1,45,
436,17,397,4,0,
436,17,430,4,0,
436,17,433,4,0,
436,17,447,4,0,
436,17,451,4,0,
436,17,473,4,0,
436,17,484,1,49,
436,17,496,4,0,
436,17,523,4,0,
436,17,590,4,0,
436,18,33,1,1,1
436,18,76,4,0,
436,18,89,4,0,
436,18,92,4,0,
436,18,93,1,1,2
436,18,94,4,0,
436,18,95,1,5,
436,18,104,4,0,
436,18,109,1,11,
436,18,113,4,0,
436,18,115,4,0,
436,18,138,4,0,
436,18,149,1,15,
436,18,156,4,0,
436,18,157,4,0,
436,18,164,4,0,
436,18,182,4,0,
436,18,185,1,21,
436,18,201,4,0,
436,18,207,4,0,
436,18,214,4,0,
436,18,216,4,0,
436,18,218,4,0,
436,18,219,1,25,
436,18,219,4,0,
436,18,237,4,0,
436,18,240,4,0,
436,18,241,4,0,
436,18,244,4,0,
436,18,247,4,0,
436,18,248,1,29,
436,18,263,4,0,
436,18,286,1,9,
436,18,317,4,0,
436,18,319,1,31,
436,18,326,1,39,
436,18,334,1,19,
436,18,347,4,0,
436,18,360,1,35,
436,18,360,4,0,
436,18,371,1,41,
436,18,371,4,0,
436,18,377,1,45,
436,18,397,4,0,
436,18,430,4,0,
436,18,433,4,0,
436,18,447,4,0,
436,18,451,4,0,
436,18,473,4,0,
436,18,484,1,49,
436,18,496,4,0,
436,18,523,4,0,
436,18,590,4,0,
437,8,33,1,1,3
437,8,63,4,0,
437,8,70,4,0,
437,8,76,4,0,
437,8,89,4,0,
437,8,92,4,0,
437,8,93,1,1,4
437,8,94,4,0,
437,8,95,1,1,5
437,8,95,1,7,
437,8,104,4,0,
437,8,109,1,14,
437,8,113,4,0,
437,8,115,4,0,
437,8,138,4,0,
437,8,148,4,0,
437,8,153,4,0,
437,8,156,4,0,
437,8,157,4,0,
437,8,164,4,0,
437,8,182,4,0,
437,8,185,1,50,
437,8,201,4,0,
437,8,203,4,0,
437,8,207,4,0,
437,8,214,4,0,
437,8,216,4,0,
437,8,218,4,0,
437,8,219,1,30,
437,8,219,4,0,
437,8,237,4,0,
437,8,240,1,1,2
437,8,240,4,0,
437,8,241,1,1,1
437,8,241,4,0,
437,8,244,4,0,
437,8,247,4,0,
437,8,248,1,43,
437,8,249,4,0,
437,8,263,4,0,
437,8,278,4,0,
437,8,285,4,0,
437,8,286,1,1,6
437,8,286,1,12,
437,8,290,4,0,
437,8,317,4,0,
437,8,326,1,19,
437,8,334,1,26,
437,8,335,1,33,
437,8,347,4,0,
437,8,360,1,38,
437,8,360,4,0,
437,8,363,4,0,
437,8,371,1,61,
437,8,371,4,0,
437,8,377,1,67,
437,8,397,4,0,
437,8,416,4,0,
437,8,430,4,0,
437,8,433,4,0,
437,8,446,4,0,
437,8,447,4,0,
437,8,451,4,0,
437,9,33,1,1,3
437,9,63,4,0,
437,9,70,4,0,
437,9,76,4,0,
437,9,89,4,0,
437,9,92,4,0,
437,9,93,1,1,4
437,9,94,4,0,
437,9,95,1,1,5
437,9,95,1,7,
437,9,104,4,0,
437,9,109,1,14,
437,9,113,4,0,
437,9,115,4,0,
437,9,138,4,0,
437,9,148,4,0,
437,9,153,4,0,
437,9,156,4,0,
437,9,157,4,0,
437,9,164,4,0,
437,9,173,3,0,
437,9,182,4,0,
437,9,185,1,50,
437,9,201,4,0,
437,9,203,4,0,
437,9,205,3,0,
437,9,207,4,0,
437,9,214,4,0,
437,9,216,4,0,
437,9,218,4,0,
437,9,219,1,30,
437,9,219,4,0,
437,9,237,4,0,
437,9,240,1,1,2
437,9,240,4,0,
437,9,241,1,1,1
437,9,241,4,0,
437,9,244,4,0,
437,9,246,3,0,
437,9,247,4,0,
437,9,248,1,43,
437,9,249,4,0,
437,9,263,4,0,
437,9,271,3,0,
437,9,278,4,0,
437,9,285,4,0,
437,9,286,1,1,6
437,9,286,1,12,
437,9,290,4,0,
437,9,317,4,0,
437,9,324,3,0,
437,9,326,1,19,
437,9,334,1,26,
437,9,335,1,33,
437,9,347,4,0,
437,9,360,1,38,
437,9,360,4,0,
437,9,363,4,0,
437,9,371,1,61,
437,9,371,4,0,
437,9,377,1,67,
437,9,397,4,0,
437,9,416,4,0,
437,9,428,3,0,
437,9,430,4,0,
437,9,433,4,0,
437,9,442,3,0,
437,9,446,4,0,
437,9,447,4,0,
437,9,451,4,0,
437,10,33,1,1,3
437,10,63,4,0,
437,10,70,4,0,
437,10,76,4,0,
437,10,89,4,0,
437,10,92,4,0,
437,10,93,1,1,4
437,10,94,4,0,
437,10,95,1,1,5
437,10,95,1,7,
437,10,104,4,0,
437,10,109,1,14,
437,10,113,4,0,
437,10,115,4,0,
437,10,138,4,0,
437,10,148,4,0,
437,10,153,4,0,
437,10,156,4,0,
437,10,157,4,0,
437,10,164,4,0,
437,10,173,3,0,
437,10,182,4,0,
437,10,185,1,50,
437,10,201,4,0,
437,10,203,4,0,
437,10,205,3,0,
437,10,207,4,0,
437,10,214,4,0,
437,10,216,4,0,
437,10,218,4,0,
437,10,219,1,30,
437,10,219,4,0,
437,10,237,4,0,
437,10,240,1,1,2
437,10,240,4,0,
437,10,241,1,1,1
437,10,241,4,0,
437,10,244,4,0,
437,10,246,3,0,
437,10,247,4,0,
437,10,248,1,43,
437,10,249,4,0,
437,10,263,4,0,
437,10,271,3,0,
437,10,278,4,0,
437,10,285,4,0,
437,10,286,1,1,6
437,10,286,1,12,
437,10,290,4,0,
437,10,317,4,0,
437,10,324,3,0,
437,10,326,1,19,
437,10,334,1,26,
437,10,335,1,33,
437,10,335,3,0,
437,10,347,4,0,
437,10,356,3,0,
437,10,360,1,38,
437,10,360,4,0,
437,10,363,4,0,
437,10,371,1,61,
437,10,371,4,0,
437,10,377,1,67,
437,10,397,4,0,
437,10,416,4,0,
437,10,428,3,0,
437,10,430,4,0,
437,10,433,4,0,
437,10,442,3,0,
437,10,446,4,0,
437,10,447,4,0,
437,10,451,4,0,
437,11,33,1,1,3
437,11,63,4,0,
437,11,70,4,0,
437,11,76,4,0,
437,11,89,4,0,
437,11,92,4,0,
437,11,93,1,1,4
437,11,94,4,0,
437,11,95,1,1,5
437,11,95,1,7,
437,11,104,4,0,
437,11,109,1,14,
437,11,113,4,0,
437,11,115,4,0,
437,11,138,4,0,
437,11,148,4,0,
437,11,153,4,0,
437,11,156,4,0,
437,11,157,4,0,
437,11,164,4,0,
437,11,182,4,0,
437,11,185,1,50,
437,11,201,4,0,
437,11,207,4,0,
437,11,216,4,0,
437,11,218,4,0,
437,11,219,1,30,
437,11,219,4,0,
437,11,237,4,0,
437,11,240,1,1,2
437,11,240,4,0,
437,11,241,1,1,1
437,11,241,4,0,
437,11,244,4,0,
437,11,247,4,0,
437,11,248,1,43,
437,11,249,4,0,
437,11,263,4,0,
437,11,286,1,1,6
437,11,286,1,12,
437,11,317,4,0,
437,11,326,1,19,
437,11,334,1,26,
437,11,335,1,33,
437,11,347,4,0,
437,11,360,1,38,
437,11,360,4,0,
437,11,371,1,61,
437,11,371,4,0,
437,11,377,1,67,
437,11,397,4,0,
437,11,416,4,0,
437,11,430,4,0,
437,11,433,4,0,
437,11,447,4,0,
437,11,451,4,0,
437,11,473,4,0,
437,11,477,4,0,
437,11,484,1,72,
437,11,496,4,0,
437,11,523,4,0,
437,14,33,1,1,3
437,14,63,4,0,
437,14,70,4,0,
437,14,76,4,0,
437,14,89,4,0,
437,14,92,4,0,
437,14,93,1,1,4
437,14,94,4,0,
437,14,95,1,1,5
437,14,95,1,5,
437,14,104,4,0,
437,14,109,1,11,
437,14,113,4,0,
437,14,115,4,0,
437,14,138,4,0,
437,14,148,4,0,
437,14,149,1,15,
437,14,153,4,0,
437,14,156,4,0,
437,14,157,4,0,
437,14,164,4,0,
437,14,173,3,0,
437,14,182,4,0,
437,14,185,1,21,
437,14,201,4,0,
437,14,207,4,0,
437,14,214,3,0,
437,14,216,4,0,
437,14,218,4,0,
437,14,219,1,25,
437,14,219,4,0,
437,14,237,4,0,
437,14,240,1,1,2
437,14,240,4,0,
437,14,241,1,1,1
437,14,241,4,0,
437,14,244,4,0,
437,14,247,4,0,
437,14,248,1,29,
437,14,249,4,0,
437,14,263,4,0,
437,14,271,3,0,
437,14,278,3,0,
437,14,285,3,0,
437,14,286,1,1,6
437,14,286,1,9,
437,14,317,4,0,
437,14,319,1,31,
437,14,324,3,0,
437,14,326,1,42,
437,14,334,1,19,
437,14,334,3,0,
437,14,335,1,33,
437,14,335,3,0,
437,14,347,4,0,
437,14,356,3,0,
437,14,360,1,36,
437,14,360,4,0,
437,14,371,1,46,
437,14,371,4,0,
437,14,377,1,52,
437,14,397,4,0,
437,14,416,4,0,
437,14,428,3,0,
437,14,430,4,0,
437,14,433,4,0,
437,14,442,3,0,
437,14,446,3,0,
437,14,447,4,0,
437,14,451,4,0,
437,14,472,3,0,
437,14,473,4,0,
437,14,477,4,0,
437,14,484,1,58,
437,14,496,4,0,
437,14,523,4,0,
437,15,33,1,1,3
437,15,63,4,0,
437,15,70,4,0,
437,15,76,4,0,
437,15,89,4,0,
437,15,92,4,0,
437,15,93,1,1,4
437,15,94,4,0,
437,15,95,1,1,5
437,15,95,1,5,
437,15,104,4,0,
437,15,109,1,11,
437,15,113,4,0,
437,15,115,4,0,
437,15,138,4,0,
437,15,148,4,0,
437,15,149,1,15,
437,15,153,4,0,
437,15,156,4,0,
437,15,157,4,0,
437,15,164,4,0,
437,15,182,4,0,
437,15,185,1,21,
437,15,201,4,0,
437,15,207,4,0,
437,15,214,4,0,
437,15,216,4,0,
437,15,218,4,0,
437,15,219,1,25,
437,15,219,4,0,
437,15,237,4,0,
437,15,240,1,1,2
437,15,240,4,0,
437,15,241,1,1,1
437,15,241,4,0,
437,15,244,4,0,
437,15,247,4,0,
437,15,248,1,29,
437,15,249,4,0,
437,15,263,4,0,
437,15,286,1,1,6
437,15,286,1,9,
437,15,317,4,0,
437,15,319,1,31,
437,15,326,1,42,
437,15,334,1,19,
437,15,335,1,33,
437,15,347,4,0,
437,15,360,1,36,
437,15,360,4,0,
437,15,371,1,46,
437,15,371,4,0,
437,15,377,1,52,
437,15,397,4,0,
437,15,416,4,0,
437,15,430,4,0,
437,15,433,4,0,
437,15,447,4,0,
437,15,451,4,0,
437,15,473,4,0,
437,15,484,1,58,
437,15,496,4,0,
437,15,523,4,0,
437,15,590,4,0,
437,16,33,1,1,3
437,16,63,4,0,
437,16,70,4,0,
437,16,76,4,0,
437,16,89,4,0,
437,16,92,4,0,
437,16,93,1,1,4
437,16,94,4,0,
437,16,95,1,1,5
437,16,95,1,5,1
437,16,104,4,0,
437,16,109,1,11,1
437,16,113,4,0,
437,16,115,4,0,
437,16,138,4,0,
437,16,148,4,0,
437,16,149,1,15,1
437,16,153,4,0,
437,16,156,4,0,
437,16,157,4,0,
437,16,164,4,0,
437,16,173,3,0,
437,16,182,4,0,
437,16,185,1,21,1
437,16,201,4,0,
437,16,207,4,0,
437,16,214,4,0,
437,16,216,4,0,
437,16,218,4,0,
437,16,219,1,25,1
437,16,219,4,0,
437,16,237,4,0,
437,16,240,1,1,2
437,16,240,4,0,
437,16,241,1,1,1
437,16,241,4,0,
437,16,244,4,0,
437,16,247,4,0,
437,16,248,1,29,1
437,16,249,4,0,
437,16,263,4,0,
437,16,271,3,0,
437,16,278,3,0,
437,16,285,3,0,
437,16,286,1,1,6
437,16,286,1,9,1
437,16,290,4,0,
437,16,317,4,0,
437,16,319,1,31,1
437,16,324,3,0,
437,16,326,1,42,1
437,16,334,1,19,1
437,16,334,3,0,
437,16,335,1,33,1
437,16,335,3,0,
437,16,347,4,0,
437,16,356,3,0,
437,16,360,1,36,1
437,16,360,4,0,
437,16,371,1,46,1
437,16,371,4,0,
437,16,377,1,52,1
437,16,397,4,0,
437,16,416,4,0,
437,16,428,3,0,
437,16,430,4,0,
437,16,433,4,0,
437,16,442,3,0,
437,16,446,3,0,
437,16,447,4,0,
437,16,451,4,0,
437,16,472,3,0,
437,16,473,4,0,
437,16,484,1,58,1
437,16,496,4,0,
437,16,523,4,0,
437,16,590,4,0,
437,17,33,1,1,4
437,17,63,4,0,
437,17,76,4,0,
437,17,89,4,0,
437,17,92,4,0,
437,17,93,1,1,5
437,17,94,4,0,
437,17,95,1,1,6
437,17,95,1,5,
437,17,104,4,0,
437,17,109,1,11,
437,17,113,4,0,
437,17,115,4,0,
437,17,138,4,0,
437,17,149,1,15,
437,17,153,4,0,
437,17,156,4,0,
437,17,157,4,0,
437,17,164,4,0,
437,17,182,4,0,
437,17,185,1,21,
437,17,201,4,0,
437,17,207,4,0,
437,17,214,4,0,
437,17,216,4,0,
437,17,218,4,0,
437,17,219,1,25,
437,17,219,4,0,
437,17,237,4,0,
437,17,240,1,1,3
437,17,240,4,0,
437,17,241,1,1,2
437,17,241,4,0,
437,17,244,4,0,
437,17,247,4,0,
437,17,248,1,29,
437,17,263,4,0,
437,17,286,1,1,7
437,17,286,1,9,
437,17,317,4,0,
437,17,319,1,31,
437,17,326,1,42,
437,17,334,1,19,
437,17,335,1,0,
437,17,335,1,1,1
437,17,347,4,0,
437,17,360,1,36,
437,17,360,4,0,
437,17,371,1,46,
437,17,371,4,0,
437,17,377,1,52,
437,17,397,4,0,
437,17,416,4,0,
437,17,430,4,0,
437,17,433,4,0,
437,17,447,4,0,
437,17,451,4,0,
437,17,473,4,0,
437,17,484,1,58,
437,17,496,4,0,
437,17,523,4,0,
437,17,590,4,0,
437,18,33,1,1,4
437,18,63,4,0,
437,18,76,4,0,
437,18,89,4,0,
437,18,92,4,0,
437,18,93,1,1,5
437,18,94,4,0,
437,18,95,1,1,6
437,18,95,1,5,
437,18,104,4,0,
437,18,109,1,11,
437,18,113,4,0,
437,18,115,4,0,
437,18,138,4,0,
437,18,149,1,15,
437,18,153,4,0,
437,18,156,4,0,
437,18,157,4,0,
437,18,164,4,0,
437,18,182,4,0,
437,18,185,1,21,
437,18,201,4,0,
437,18,207,4,0,
437,18,214,4,0,
437,18,216,4,0,
437,18,218,4,0,
437,18,219,1,25,
437,18,219,4,0,
437,18,237,4,0,
437,18,240,1,1,3
437,18,240,4,0,
437,18,241,1,1,2
437,18,241,4,0,
437,18,244,4,0,
437,18,247,4,0,
437,18,248,1,29,
437,18,263,4,0,
437,18,286,1,1,7
437,18,286,1,9,
437,18,317,4,0,
437,18,319,1,31,
437,18,326,1,42,
437,18,334,1,19,
437,18,335,1,0,
437,18,335,1,1,1
437,18,347,4,0,
437,18,360,1,36,
437,18,360,4,0,
437,18,371,1,46,
437,18,371,4,0,
437,18,377,1,52,
437,18,397,4,0,
437,18,416,4,0,
437,18,430,4,0,
437,18,433,4,0,
437,18,447,4,0,
437,18,451,4,0,
437,18,473,4,0,
437,18,484,1,58,
437,18,496,4,0,
437,18,523,4,0,
437,18,590,4,0,
438,8,21,1,38,
438,8,29,2,0,
438,8,38,1,46,
438,8,67,1,9,
438,8,88,1,14,
438,8,91,4,0,
438,8,92,4,0,
438,8,102,1,17,
438,8,104,4,0,
438,8,106,2,0,
438,8,111,2,0,
438,8,120,2,0,
438,8,153,4,0,
438,8,156,4,0,
438,8,157,1,33,
438,8,157,4,0,
438,8,164,4,0,
438,8,168,4,0,
438,8,175,1,6,
438,8,182,4,0,
438,8,185,1,25,
438,8,201,4,0,
438,8,203,4,0,
438,8,205,2,0,
438,8,207,4,0,
438,8,213,4,0,
438,8,214,4,0,
438,8,216,4,0,
438,8,218,4,0,
438,8,237,4,0,
438,8,241,4,0,
438,8,244,4,0,
438,8,263,4,0,
438,8,280,4,0,
438,8,290,4,0,
438,8,313,1,1,1
438,8,317,1,30,
438,8,317,4,0,
438,8,328,2,0,
438,8,335,1,22,
438,8,347,4,0,
438,8,363,4,0,
438,8,383,1,1,2
438,8,389,1,41,
438,8,397,4,0,
438,8,445,4,0,
438,8,446,4,0,
438,9,21,1,38,
438,9,29,2,0,
438,9,38,1,46,
438,9,67,1,9,
438,9,88,1,14,
438,9,91,4,0,
438,9,92,4,0,
438,9,102,1,17,
438,9,104,4,0,
438,9,106,2,0,
438,9,111,2,0,
438,9,120,2,0,
438,9,153,4,0,
438,9,156,4,0,
438,9,157,1,33,
438,9,157,4,0,
438,9,164,4,0,
438,9,168,4,0,
438,9,173,3,0,
438,9,175,1,6,
438,9,182,4,0,
438,9,185,1,25,
438,9,201,4,0,
438,9,203,4,0,
438,9,205,2,0,
438,9,205,3,0,
438,9,207,4,0,
438,9,213,4,0,
438,9,214,4,0,
438,9,216,4,0,
438,9,218,4,0,
438,9,237,4,0,
438,9,241,4,0,
438,9,244,4,0,
438,9,253,3,0,
438,9,263,4,0,
438,9,270,3,0,
438,9,280,4,0,
438,9,290,4,0,
438,9,313,1,1,1
438,9,317,1,30,
438,9,317,4,0,
438,9,328,2,0,
438,9,335,1,22,
438,9,347,4,0,
438,9,363,4,0,
438,9,383,1,1,2
438,9,389,1,41,
438,9,389,3,0,
438,9,397,4,0,
438,9,414,3,0,
438,9,445,4,0,
438,9,446,4,0,
438,10,21,1,38,
438,10,29,2,0,
438,10,29,3,0,
438,10,38,1,46,
438,10,67,1,9,
438,10,67,3,0,
438,10,88,1,14,
438,10,91,4,0,
438,10,92,4,0,
438,10,102,1,17,
438,10,104,4,0,
438,10,106,2,0,
438,10,111,2,0,
438,10,120,2,0,
438,10,153,4,0,
438,10,156,4,0,
438,10,157,1,33,
438,10,157,4,0,
438,10,164,4,0,
438,10,168,4,0,
438,10,173,3,0,
438,10,175,1,6,
438,10,182,4,0,
438,10,185,1,25,
438,10,201,4,0,
438,10,203,4,0,
438,10,205,2,0,
438,10,205,3,0,
438,10,207,4,0,
438,10,213,4,0,
438,10,214,4,0,
438,10,216,4,0,
438,10,218,4,0,
438,10,237,4,0,
438,10,241,4,0,
438,10,244,4,0,
438,10,253,3,0,
438,10,263,4,0,
438,10,270,3,0,
438,10,272,3,0,
438,10,280,4,0,
438,10,290,4,0,
438,10,313,1,1,1
438,10,317,1,30,
438,10,317,4,0,
438,10,328,2,0,
438,10,335,1,22,
438,10,335,3,0,
438,10,347,4,0,
438,10,363,4,0,
438,10,383,1,1,2
438,10,389,1,41,
438,10,389,3,0,
438,10,397,4,0,
438,10,414,3,0,
438,10,445,4,0,
438,10,446,4,0,
438,11,21,1,38,
438,11,29,2,0,
438,11,38,1,46,
438,11,67,1,9,
438,11,88,1,14,
438,11,91,4,0,
438,11,92,4,0,
438,11,102,1,17,
438,11,104,4,0,
438,11,106,2,0,
438,11,111,2,0,
438,11,120,2,0,
438,11,153,4,0,
438,11,156,4,0,
438,11,157,1,33,
438,11,157,4,0,
438,11,164,4,0,
438,11,168,4,0,
438,11,174,2,0,
438,11,175,1,6,
438,11,182,4,0,
438,11,185,1,25,
438,11,201,4,0,
438,11,203,2,0,
438,11,205,2,0,
438,11,207,4,0,
438,11,213,4,0,
438,11,216,4,0,
438,11,218,4,0,
438,11,237,4,0,
438,11,241,4,0,
438,11,244,4,0,
438,11,263,4,0,
438,11,280,4,0,
438,11,313,1,1,1
438,11,317,1,30,
438,11,317,4,0,
438,11,328,2,0,
438,11,335,1,22,
438,11,347,4,0,
438,11,383,1,1,2
438,11,389,1,41,
438,11,397,4,0,
438,11,446,2,0,
438,11,479,4,0,
438,11,496,4,0,
438,14,21,1,15,
438,14,29,2,0,
438,14,38,1,40,
438,14,67,1,8,
438,14,67,3,0,
438,14,88,1,12,
438,14,91,4,0,
438,14,92,4,0,
438,14,102,1,33,
438,14,104,4,0,
438,14,106,2,0,
438,14,111,2,0,
438,14,120,2,0,
438,14,153,4,0,
438,14,156,4,0,
438,14,157,1,29,
438,14,157,4,0,
438,14,164,4,0,
438,14,168,4,0,
438,14,173,3,0,
438,14,174,2,0,
438,14,175,1,5,
438,14,182,4,0,
438,14,185,1,19,
438,14,201,4,0,
438,14,203,2,0,
438,14,205,2,0,
438,14,207,4,0,
438,14,213,4,0,
438,14,214,3,0,
438,14,216,4,0,
438,14,218,4,0,
438,14,237,4,0,
438,14,241,4,0,
438,14,244,4,0,
438,14,253,3,0,
438,14,263,4,0,
438,14,270,3,0,
438,14,272,3,0,
438,14,280,4,0,
438,14,313,1,1,1
438,14,317,1,22,
438,14,317,4,0,
438,14,328,2,0,
438,14,335,1,26,
438,14,335,3,0,
438,14,343,3,0,
438,14,347,4,0,
438,14,383,1,1,2
438,14,389,1,36,
438,14,397,4,0,
438,14,414,3,0,
438,14,446,2,0,
438,14,446,3,0,
438,14,479,4,0,
438,14,492,3,0,
438,14,495,3,0,
438,14,496,4,0,
438,15,29,2,0,
438,15,38,1,40,
438,15,67,1,8,
438,15,68,1,33,
438,15,88,1,12,
438,15,91,4,0,
438,15,92,4,0,
438,15,102,1,15,
438,15,104,4,0,
438,15,106,2,0,
438,15,111,2,0,
438,15,120,2,0,
438,15,153,4,0,
438,15,156,4,0,
438,15,157,1,29,
438,15,157,4,0,
438,15,164,4,0,
438,15,168,4,0,
438,15,174,2,0,
438,15,175,1,5,
438,15,182,4,0,
438,15,185,1,19,
438,15,201,4,0,
438,15,203,2,0,
438,15,205,2,0,
438,15,207,4,0,
438,15,213,4,0,
438,15,214,4,0,
438,15,216,4,0,
438,15,218,4,0,
438,15,237,4,0,
438,15,241,4,0,
438,15,244,4,0,
438,15,263,4,0,
438,15,267,4,0,
438,15,280,4,0,
438,15,313,1,1,1
438,15,317,1,22,
438,15,317,4,0,
438,15,328,2,0,
438,15,335,1,26,
438,15,347,4,0,
438,15,383,1,1,2
438,15,389,1,36,
438,15,397,4,0,
438,15,446,2,0,
438,15,479,4,0,
438,15,496,4,0,
438,15,590,4,0,
438,16,29,2,0,
438,16,38,1,40,1
438,16,67,1,8,1
438,16,67,3,0,
438,16,68,1,33,1
438,16,88,1,12,1
438,16,91,4,0,
438,16,92,4,0,
438,16,102,1,15,1
438,16,104,4,0,
438,16,106,2,0,
438,16,111,2,0,
438,16,120,2,0,
438,16,153,4,0,
438,16,156,4,0,
438,16,157,1,29,1
438,16,157,4,0,
438,16,164,4,0,
438,16,168,4,0,
438,16,173,3,0,
438,16,174,2,0,
438,16,175,1,5,1
438,16,182,4,0,
438,16,185,1,19,1
438,16,201,4,0,
438,16,203,2,0,
438,16,205,2,0,
438,16,207,4,0,
438,16,213,4,0,
438,16,214,4,0,
438,16,216,4,0,
438,16,218,4,0,
438,16,237,4,0,
438,16,241,4,0,
438,16,244,4,0,
438,16,253,3,0,
438,16,263,4,0,
438,16,267,4,0,
438,16,270,3,0,
438,16,272,3,0,
438,16,280,4,0,
438,16,290,4,0,
438,16,313,1,1,1
438,16,317,1,22,1
438,16,317,4,0,
438,16,328,2,0,
438,16,335,1,26,1
438,16,335,3,0,
438,16,343,3,0,
438,16,347,4,0,
438,16,383,1,1,2
438,16,389,1,36,1
438,16,397,4,0,
438,16,414,3,0,
438,16,446,2,0,
438,16,446,3,0,
438,16,479,4,0,
438,16,492,3,0,
438,16,495,3,0,
438,16,496,4,0,
438,16,590,4,0,
438,17,29,2,0,
438,17,38,1,43,
438,17,67,1,8,
438,17,68,1,36,
438,17,88,1,12,
438,17,92,4,0,
438,17,102,1,15,
438,17,104,4,0,
438,17,106,2,0,
438,17,111,2,0,
438,17,120,2,0,
438,17,153,4,0,
438,17,156,4,0,
438,17,157,1,33,
438,17,157,4,0,
438,17,164,4,0,
438,17,168,4,0,
438,17,174,2,0,
438,17,175,1,5,
438,17,182,4,0,
438,17,185,1,19,
438,17,201,4,0,
438,17,203,2,0,
438,17,205,2,0,
438,17,207,4,0,
438,17,213,4,0,
438,17,214,4,0,
438,17,216,4,0,
438,17,218,4,0,
438,17,237,4,0,
438,17,241,4,0,
438,17,244,4,0,
438,17,263,4,0,
438,17,267,4,0,
438,17,280,4,0,
438,17,313,1,1,1
438,17,317,1,26,
438,17,317,4,0,
438,17,328,2,0,
438,17,335,1,29,
438,17,347,4,0,
438,17,383,1,1,2
438,17,389,1,40,
438,17,397,4,0,
438,17,446,2,0,
438,17,479,4,0,
438,17,496,4,0,
438,17,590,4,0,
438,17,715,1,22,
438,18,29,2,0,
438,18,38,1,43,
438,18,67,1,8,
438,18,68,1,36,
438,18,88,1,12,
438,18,92,4,0,
438,18,102,1,15,
438,18,104,4,0,
438,18,106,2,0,
438,18,111,2,0,
438,18,120,2,0,
438,18,153,4,0,
438,18,156,4,0,
438,18,157,1,33,
438,18,157,4,0,
438,18,164,4,0,
438,18,168,4,0,
438,18,174,2,0,
438,18,175,1,5,
438,18,182,4,0,
438,18,185,1,19,
438,18,201,4,0,
438,18,203,2,0,
438,18,205,2,0,
438,18,207,4,0,
438,18,213,4,0,
438,18,214,4,0,
438,18,216,4,0,
438,18,218,4,0,
438,18,237,4,0,
438,18,241,4,0,
438,18,244,4,0,
438,18,263,4,0,
438,18,267,4,0,
438,18,280,4,0,
438,18,313,1,1,1
438,18,317,1,26,
438,18,317,4,0,
438,18,328,2,0,
438,18,335,1,29,
438,18,347,4,0,
438,18,383,1,1,2
438,18,389,1,40,
438,18,397,4,0,
438,18,446,2,0,
438,18,479,4,0,
438,18,496,4,0,
438,18,590,4,0,
438,18,715,1,22,
439,8,3,1,15,
439,8,60,1,25,
439,8,76,4,0,
439,8,85,4,0,
439,8,86,4,0,
439,8,87,4,0,
439,8,92,4,0,
439,8,93,1,1,3
439,8,94,1,39,
439,8,94,4,0,
439,8,95,2,0,
439,8,96,1,8,
439,8,102,1,18,
439,8,102,2,0,
439,8,104,4,0,
439,8,109,2,0,
439,8,112,1,1,2
439,8,113,1,22,1
439,8,113,4,0,
439,8,115,1,22,2
439,8,115,4,0,
439,8,138,4,0,
439,8,148,4,0,
439,8,156,4,0,
439,8,164,1,29,
439,8,164,4,0,
439,8,168,4,0,
439,8,182,4,0,
439,8,203,4,0,
439,8,204,2,0,
439,8,207,4,0,
439,8,213,4,0,
439,8,214,4,0,
439,8,216,4,0,
439,8,218,4,0,
439,8,219,1,50,
439,8,219,4,0,
439,8,226,1,46,
439,8,227,1,11,
439,8,237,4,0,
439,8,240,4,0,
439,8,241,4,0,
439,8,244,2,0,
439,8,244,4,0,
439,8,247,4,0,
439,8,248,2,0,
439,8,252,2,0,
439,8,259,4,0,
439,8,263,4,0,
439,8,264,4,0,
439,8,269,4,0,
439,8,271,1,36,
439,8,271,2,0,
439,8,272,1,43,
439,8,278,1,32,
439,8,278,4,0,
439,8,280,4,0,
439,8,285,4,0,
439,8,289,4,0,
439,8,290,4,0,
439,8,298,2,0,
439,8,321,1,1,1
439,8,347,4,0,
439,8,351,4,0,
439,8,358,2,0,
439,8,361,2,0,
439,8,363,4,0,
439,8,374,4,0,
439,8,383,1,4,
439,8,409,4,0,
439,8,433,4,0,
439,8,445,4,0,
439,8,447,4,0,
439,8,451,4,0,
439,9,3,1,15,
439,9,60,1,25,
439,9,76,4,0,
439,9,85,4,0,
439,9,86,4,0,
439,9,87,4,0,
439,9,92,4,0,
439,9,93,1,1,3
439,9,94,1,39,
439,9,94,4,0,
439,9,95,2,0,
439,9,96,1,8,
439,9,102,1,18,
439,9,102,2,0,
439,9,104,4,0,
439,9,109,2,0,
439,9,112,1,1,2
439,9,113,1,22,1
439,9,113,4,0,
439,9,115,1,22,2
439,9,115,4,0,
439,9,138,4,0,
439,9,148,4,0,
439,9,156,4,0,
439,9,164,1,29,
439,9,164,4,0,
439,9,168,4,0,
439,9,173,3,0,
439,9,182,4,0,
439,9,189,3,0,
439,9,203,4,0,
439,9,204,2,0,
439,9,207,4,0,
439,9,213,4,0,
439,9,214,4,0,
439,9,216,4,0,
439,9,218,4,0,
439,9,219,1,50,
439,9,219,4,0,
439,9,226,1,46,
439,9,227,1,11,
439,9,237,4,0,
439,9,240,4,0,
439,9,241,4,0,
439,9,244,2,0,
439,9,244,4,0,
439,9,247,4,0,
439,9,248,2,0,
439,9,252,2,0,
439,9,253,3,0,
439,9,259,4,0,
439,9,263,4,0,
439,9,264,4,0,
439,9,269,4,0,
439,9,270,3,0,
439,9,271,1,36,
439,9,271,2,0,
439,9,271,3,0,
439,9,272,1,43,
439,9,278,1,32,
439,9,278,4,0,
439,9,280,4,0,
439,9,285,4,0,
439,9,289,4,0,
439,9,290,4,0,
439,9,298,2,0,
439,9,321,1,1,1
439,9,324,3,0,
439,9,347,4,0,
439,9,351,4,0,
439,9,358,2,0,
439,9,361,2,0,
439,9,363,4,0,
439,9,374,4,0,
439,9,383,1,4,
439,9,409,4,0,
439,9,433,4,0,
439,9,445,4,0,
439,9,447,4,0,
439,9,451,4,0,
439,10,3,1,15,
439,10,29,3,0,
439,10,60,1,25,
439,10,76,4,0,
439,10,85,4,0,
439,10,86,4,0,
439,10,87,4,0,
439,10,92,4,0,
439,10,93,1,1,3
439,10,94,1,39,
439,10,94,4,0,
439,10,95,2,0,
439,10,96,1,8,
439,10,102,1,18,
439,10,102,2,0,
439,10,104,4,0,
439,10,109,2,0,
439,10,112,1,1,2
439,10,113,1,22,1
439,10,113,4,0,
439,10,115,1,22,2
439,10,115,4,0,
439,10,138,4,0,
439,10,148,4,0,
439,10,156,4,0,
439,10,164,1,29,
439,10,164,4,0,
439,10,168,4,0,
439,10,173,3,0,
439,10,182,4,0,
439,10,189,3,0,
439,10,203,4,0,
439,10,204,2,0,
439,10,207,4,0,
439,10,213,4,0,
439,10,214,4,0,
439,10,216,4,0,
439,10,218,4,0,
439,10,219,1,50,
439,10,219,4,0,
439,10,226,1,46,
439,10,227,1,11,
439,10,237,4,0,
439,10,240,4,0,
439,10,241,4,0,
439,10,244,2,0,
439,10,244,4,0,
439,10,247,4,0,
439,10,248,2,0,
439,10,252,2,0,
439,10,253,3,0,
439,10,259,4,0,
439,10,263,4,0,
439,10,264,4,0,
439,10,269,4,0,
439,10,270,3,0,
439,10,271,1,36,
439,10,271,2,0,
439,10,271,3,0,
439,10,272,1,43,
439,10,272,3,0,
439,10,277,3,0,
439,10,278,1,32,
439,10,278,4,0,
439,10,280,4,0,
439,10,285,4,0,
439,10,289,4,0,
439,10,290,4,0,
439,10,298,2,0,
439,10,321,1,1,1
439,10,324,3,0,
439,10,347,4,0,
439,10,351,4,0,
439,10,358,2,0,
439,10,361,2,0,
439,10,363,4,0,
439,10,374,4,0,
439,10,383,1,4,
439,10,409,4,0,
439,10,417,2,0,
439,10,433,4,0,
439,10,445,4,0,
439,10,447,4,0,
439,10,451,4,0,
439,11,3,1,15,
439,11,60,1,25,
439,11,76,4,0,
439,11,85,4,0,
439,11,86,4,0,
439,11,87,4,0,
439,11,92,4,0,
439,11,93,1,1,3
439,11,94,1,39,
439,11,94,4,0,
439,11,95,2,0,
439,11,96,1,8,
439,11,102,1,18,
439,11,102,2,0,
439,11,104,4,0,
439,11,109,2,0,
439,11,112,1,1,2
439,11,113,1,22,1
439,11,113,4,0,
439,11,115,1,22,2
439,11,115,4,0,
439,11,138,4,0,
439,11,148,4,0,
439,11,156,4,0,
439,11,164,1,29,
439,11,164,4,0,
439,11,168,4,0,
439,11,182,4,0,
439,11,196,2,0,
439,11,204,2,0,
439,11,207,4,0,
439,11,213,4,0,
439,11,216,4,0,
439,11,218,4,0,
439,11,219,1,50,
439,11,219,4,0,
439,11,226,1,46,
439,11,227,1,11,
439,11,237,4,0,
439,11,240,4,0,
439,11,241,4,0,
439,11,244,4,0,
439,11,247,4,0,
439,11,248,2,0,
439,11,252,2,0,
439,11,259,4,0,
439,11,263,4,0,
439,11,269,4,0,
439,11,271,1,36,
439,11,271,2,0,
439,11,272,1,43,
439,11,278,1,32,
439,11,280,4,0,
439,11,298,2,0,
439,11,321,1,1,1
439,11,347,4,0,
439,11,358,2,0,
439,11,361,2,0,
439,11,374,4,0,
439,11,383,1,4,
439,11,417,2,0,
439,11,433,4,0,
439,11,447,4,0,
439,11,451,4,0,
439,11,471,2,0,
439,11,473,4,0,
439,11,477,4,0,
439,11,478,2,0,
439,11,496,4,0,
439,14,3,1,11,
439,14,60,1,25,
439,14,76,4,0,
439,14,85,4,0,
439,14,86,4,0,
439,14,87,4,0,
439,14,92,4,0,
439,14,93,1,1,3
439,14,94,1,39,
439,14,94,4,0,
439,14,95,2,0,
439,14,96,1,8,
439,14,102,1,15,
439,14,102,2,0,
439,14,104,4,0,
439,14,109,2,0,
439,14,112,1,1,2
439,14,113,1,22,1
439,14,113,4,0,
439,14,115,1,22,2
439,14,115,4,0,
439,14,138,4,0,
439,14,148,4,0,
439,14,156,4,0,
439,14,164,1,29,
439,14,164,4,0,
439,14,168,4,0,
439,14,173,3,0,
439,14,182,4,0,
439,14,196,2,0,
439,14,196,3,0,
439,14,204,2,0,
439,14,207,4,0,
439,14,213,4,0,
439,14,214,3,0,
439,14,216,4,0,
439,14,218,4,0,
439,14,219,1,50,
439,14,219,4,0,
439,14,226,1,46,
439,14,227,1,18,
439,14,237,4,0,
439,14,240,4,0,
439,14,241,4,0,
439,14,244,4,0,
439,14,247,4,0,
439,14,248,2,0,
439,14,252,2,0,
439,14,253,3,0,
439,14,259,4,0,
439,14,263,4,0,
439,14,269,4,0,
439,14,270,3,0,
439,14,271,1,36,
439,14,271,2,0,
439,14,271,3,0,
439,14,272,1,43,
439,14,272,3,0,
439,14,277,3,0,
439,14,278,1,32,
439,14,278,3,0,
439,14,280,4,0,
439,14,285,3,0,
439,14,289,3,0,
439,14,298,2,0,
439,14,321,1,1,1
439,14,324,3,0,
439,14,343,3,0,
439,14,347,4,0,
439,14,358,2,0,
439,14,361,2,0,
439,14,374,4,0,
439,14,383,1,4,
439,14,409,3,0,
439,14,417,2,0,
439,14,433,4,0,
439,14,447,4,0,
439,14,451,4,0,
439,14,471,2,0,
439,14,472,3,0,
439,14,473,4,0,
439,14,477,4,0,
439,14,478,2,0,
439,14,478,3,0,
439,14,496,4,0,
439,15,3,1,11,
439,15,60,1,25,
439,15,76,4,0,
439,15,85,4,0,
439,15,86,4,0,
439,15,87,4,0,
439,15,92,4,0,
439,15,93,1,1,3
439,15,94,1,39,
439,15,94,4,0,
439,15,95,2,0,
439,15,96,1,8,
439,15,102,1,15,
439,15,102,2,0,
439,15,104,4,0,
439,15,109,2,0,
439,15,112,1,1,2
439,15,113,1,22,1
439,15,113,4,0,
439,15,115,1,22,2
439,15,115,4,0,
439,15,138,4,0,
439,15,148,4,0,
439,15,156,4,0,
439,15,164,1,29,
439,15,164,4,0,
439,15,168,4,0,
439,15,182,4,0,
439,15,196,2,0,
439,15,204,2,0,
439,15,207,4,0,
439,15,213,4,0,
439,15,214,4,0,
439,15,216,4,0,
439,15,218,4,0,
439,15,219,1,50,
439,15,219,4,0,
439,15,226,1,46,
439,15,227,1,18,
439,15,237,4,0,
439,15,240,4,0,
439,15,241,4,0,
439,15,244,4,0,
439,15,247,4,0,
439,15,248,2,0,
439,15,252,2,0,
439,15,259,4,0,
439,15,263,4,0,
439,15,269,4,0,
439,15,271,1,36,
439,15,271,2,0,
439,15,272,1,43,
439,15,278,1,32,
439,15,280,4,0,
439,15,298,2,0,
439,15,321,1,1,1
439,15,347,4,0,
439,15,358,2,0,
439,15,361,2,0,
439,15,374,4,0,
439,15,383,1,4,
439,15,417,2,0,
439,15,433,4,0,
439,15,447,4,0,
439,15,451,4,0,
439,15,471,2,0,
439,15,473,4,0,
439,15,478,2,0,
439,15,496,4,0,
439,15,590,4,0,
439,15,611,4,0,
439,16,3,1,11,1
439,16,60,1,25,1
439,16,76,4,0,
439,16,85,4,0,
439,16,86,4,0,
439,16,87,4,0,
439,16,92,4,0,
439,16,93,1,1,3
439,16,94,1,39,1
439,16,94,4,0,
439,16,95,2,0,
439,16,96,1,8,1
439,16,102,1,15,1
439,16,102,2,0,
439,16,104,4,0,
439,16,109,2,0,
439,16,112,1,1,2
439,16,113,1,22,1
439,16,113,4,0,
439,16,115,1,22,2
439,16,115,4,0,
439,16,138,4,0,
439,16,148,4,0,
439,16,156,4,0,
439,16,164,1,29,1
439,16,164,4,0,
439,16,168,4,0,
439,16,173,3,0,
439,16,182,4,0,
439,16,196,2,0,
439,16,196,3,0,
439,16,204,2,0,
439,16,207,4,0,
439,16,213,4,0,
439,16,214,4,0,
439,16,216,4,0,
439,16,218,4,0,
439,16,219,1,50,1
439,16,219,4,0,
439,16,226,1,46,1
439,16,227,1,18,1
439,16,237,4,0,
439,16,240,4,0,
439,16,241,4,0,
439,16,244,4,0,
439,16,247,4,0,
439,16,248,2,0,
439,16,252,2,0,
439,16,253,3,0,
439,16,259,4,0,
439,16,263,4,0,
439,16,264,3,0,
439,16,269,4,0,
439,16,270,3,0,
439,16,271,1,36,1
439,16,271,2,0,
439,16,271,3,0,
439,16,272,1,43,1
439,16,272,3,0,
439,16,277,3,0,
439,16,278,1,32,1
439,16,278,3,0,
439,16,280,4,0,
439,16,285,3,0,
439,16,289,3,0,
439,16,290,4,0,
439,16,298,2,0,
439,16,321,1,1,1
439,16,324,3,0,
439,16,343,3,0,
439,16,347,4,0,
439,16,351,3,0,
439,16,358,2,0,
439,16,361,2,0,
439,16,374,4,0,
439,16,383,1,4,1
439,16,409,3,0,
439,16,417,2,0,
439,16,433,4,0,
439,16,447,4,0,
439,16,451,4,0,
439,16,471,2,0,
439,16,472,3,0,
439,16,473,4,0,
439,16,478,2,0,
439,16,478,3,0,
439,16,496,4,0,
439,16,590,4,0,
439,16,611,4,0,
439,17,3,1,11,
439,17,60,1,25,
439,17,76,4,0,
439,17,85,4,0,
439,17,86,4,0,
439,17,87,4,0,
439,17,92,4,0,
439,17,93,1,1,3
439,17,94,1,39,
439,17,94,4,0,
439,17,95,2,0,
439,17,96,1,8,
439,17,102,1,15,
439,17,102,2,0,
439,17,104,4,0,
439,17,109,2,0,
439,17,112,1,1,2
439,17,113,1,22,1
439,17,113,4,0,
439,17,115,1,22,2
439,17,115,4,0,
439,17,138,4,0,
439,17,156,4,0,
439,17,164,1,29,
439,17,164,4,0,
439,17,168,4,0,
439,17,182,4,0,
439,17,196,2,0,
439,17,204,2,0,
439,17,207,4,0,
439,17,213,4,0,
439,17,214,4,0,
439,17,216,4,0,
439,17,218,4,0,
439,17,219,1,50,
439,17,219,4,0,
439,17,226,1,46,
439,17,227,1,18,
439,17,237,4,0,
439,17,240,4,0,
439,17,241,4,0,
439,17,244,4,0,
439,17,247,4,0,
439,17,248,2,0,
439,17,252,2,0,
439,17,259,4,0,
439,17,263,4,0,
439,17,269,4,0,
439,17,271,1,36,
439,17,271,2,0,
439,17,272,1,43,
439,17,278,1,32,
439,17,280,4,0,
439,17,298,2,0,
439,17,321,1,1,1
439,17,347,4,0,
439,17,358,2,0,
439,17,361,2,0,
439,17,374,4,0,
439,17,383,1,4,
439,17,417,2,0,
439,17,433,4,0,
439,17,447,4,0,
439,17,451,4,0,
439,17,471,2,0,
439,17,473,4,0,
439,17,478,2,0,
439,17,496,4,0,
439,17,590,4,0,
439,17,611,4,0,
439,18,1,1,1,3
439,18,3,1,11,
439,18,60,1,25,
439,18,76,4,0,
439,18,85,4,0,
439,18,86,4,0,
439,18,87,4,0,
439,18,92,4,0,
439,18,93,1,1,4
439,18,94,1,39,
439,18,94,4,0,
439,18,95,2,0,
439,18,96,1,8,
439,18,102,1,15,
439,18,102,2,0,
439,18,104,4,0,
439,18,109,2,0,
439,18,112,1,1,2
439,18,113,1,22,1
439,18,113,4,0,
439,18,115,1,22,2
439,18,115,4,0,
439,18,138,4,0,
439,18,156,4,0,
439,18,164,1,29,
439,18,164,4,0,
439,18,168,4,0,
439,18,182,4,0,
439,18,196,2,0,
439,18,204,2,0,
439,18,207,4,0,
439,18,213,4,0,
439,18,214,4,0,
439,18,216,4,0,
439,18,218,4,0,
439,18,219,1,50,
439,18,219,4,0,
439,18,226,1,46,
439,18,227,1,18,
439,18,237,4,0,
439,18,240,4,0,
439,18,241,4,0,
439,18,244,4,0,
439,18,247,4,0,
439,18,248,2,0,
439,18,252,2,0,
439,18,259,4,0,
439,18,263,4,0,
439,18,269,4,0,
439,18,271,1,36,
439,18,271,2,0,
439,18,272,1,43,
439,18,278,1,32,
439,18,280,4,0,
439,18,298,2,0,
439,18,321,1,1,1
439,18,347,4,0,
439,18,358,2,0,
439,18,361,2,0,
439,18,374,4,0,
439,18,383,1,4,
439,18,417,2,0,
439,18,433,4,0,
439,18,447,4,0,
439,18,451,4,0,
439,18,471,2,0,
439,18,473,4,0,
439,18,478,2,0,
439,18,496,4,0,
439,18,590,4,0,
439,18,611,4,0,
439,18,678,2,0,
440,8,1,1,1,1
440,8,53,4,0,
440,8,68,2,0,
440,8,76,4,0,
440,8,86,4,0,
440,8,92,4,0,
440,8,94,4,0,
440,8,104,4,0,
440,8,113,4,0,
440,8,118,2,0,
440,8,126,4,0,
440,8,138,4,0,
440,8,148,4,0,
440,8,156,4,0,
440,8,164,2,0,
440,8,164,4,0,
440,8,182,4,0,
440,8,186,1,12,
440,8,203,4,0,
440,8,204,1,1,2
440,8,207,4,0,
440,8,213,4,0,
440,8,214,4,0,
440,8,215,2,0,
440,8,216,4,0,
440,8,217,2,0,
440,8,218,4,0,
440,8,219,4,0,
440,8,237,4,0,
440,8,240,4,0,
440,8,241,4,0,
440,8,244,4,0,
440,8,247,4,0,
440,8,258,4,0,
440,8,263,4,0,
440,8,270,2,0,
440,8,278,4,0,
440,8,287,1,9,
440,8,290,4,0,
440,8,312,2,0,
440,8,351,4,0,
440,8,352,4,0,
440,8,356,2,0,
440,8,363,4,0,
440,8,374,4,0,
440,8,383,1,5,
440,8,387,2,0,
440,8,409,4,0,
440,8,445,4,0,
440,8,447,4,0,
440,9,1,1,1,1
440,9,53,4,0,
440,9,68,2,0,
440,9,76,4,0,
440,9,86,4,0,
440,9,92,4,0,
440,9,94,4,0,
440,9,104,4,0,
440,9,113,4,0,
440,9,118,2,0,
440,9,126,4,0,
440,9,138,4,0,
440,9,148,4,0,
440,9,156,4,0,
440,9,164,2,0,
440,9,164,4,0,
440,9,173,3,0,
440,9,182,4,0,
440,9,186,1,12,
440,9,189,3,0,
440,9,196,3,0,
440,9,203,4,0,
440,9,204,1,1,2
440,9,205,3,0,
440,9,207,4,0,
440,9,213,4,0,
440,9,214,4,0,
440,9,215,2,0,
440,9,216,4,0,
440,9,217,2,0,
440,9,218,4,0,
440,9,219,4,0,
440,9,237,4,0,
440,9,240,4,0,
440,9,241,4,0,
440,9,244,4,0,
440,9,247,4,0,
440,9,253,3,0,
440,9,258,4,0,
440,9,263,4,0,
440,9,270,2,0,
440,9,270,3,0,
440,9,278,4,0,
440,9,283,3,0,
440,9,287,1,9,
440,9,290,4,0,
440,9,312,2,0,
440,9,351,4,0,
440,9,352,4,0,
440,9,356,2,0,
440,9,363,4,0,
440,9,374,4,0,
440,9,383,1,5,
440,9,387,2,0,
440,9,387,3,0,
440,9,409,4,0,
440,9,428,3,0,
440,9,445,4,0,
440,9,447,4,0,
440,10,1,1,1,1
440,10,29,3,0,
440,10,53,4,0,
440,10,68,2,0,
440,10,76,4,0,
440,10,86,4,0,
440,10,92,4,0,
440,10,94,4,0,
440,10,104,4,0,
440,10,113,4,0,
440,10,118,2,0,
440,10,126,4,0,
440,10,138,4,0,
440,10,148,4,0,
440,10,156,4,0,
440,10,164,2,0,
440,10,164,4,0,
440,10,173,3,0,
440,10,182,4,0,
440,10,186,1,12,
440,10,189,3,0,
440,10,196,3,0,
440,10,203,4,0,
440,10,204,1,1,2
440,10,205,3,0,
440,10,207,4,0,
440,10,213,4,0,
440,10,214,4,0,
440,10,215,2,0,
440,10,215,3,0,
440,10,216,4,0,
440,10,217,2,0,
440,10,218,4,0,
440,10,219,4,0,
440,10,237,4,0,
440,10,240,4,0,
440,10,241,4,0,
440,10,244,4,0,
440,10,247,4,0,
440,10,253,3,0,
440,10,258,4,0,
440,10,263,4,0,
440,10,270,2,0,
440,10,270,3,0,
440,10,278,4,0,
440,10,283,3,0,
440,10,287,1,9,
440,10,290,4,0,
440,10,312,2,0,
440,10,351,4,0,
440,10,352,4,0,
440,10,356,2,0,
440,10,356,3,0,
440,10,363,4,0,
440,10,374,4,0,
440,10,383,1,5,
440,10,387,2,0,
440,10,387,3,0,
440,10,409,4,0,
440,10,426,2,0,
440,10,428,3,0,
440,10,445,4,0,
440,10,447,4,0,
440,11,1,1,1,1
440,11,53,4,0,
440,11,68,2,0,
440,11,76,4,0,
440,11,86,4,0,
440,11,92,4,0,
440,11,94,4,0,
440,11,104,4,0,
440,11,113,4,0,
440,11,118,2,0,
440,11,126,4,0,
440,11,138,4,0,
440,11,148,4,0,
440,11,156,4,0,
440,11,164,4,0,
440,11,182,4,0,
440,11,186,1,12,
440,11,203,2,0,
440,11,204,1,1,2
440,11,207,4,0,
440,11,213,4,0,
440,11,215,2,0,
440,11,216,4,0,
440,11,217,2,0,
440,11,218,4,0,
440,11,219,4,0,
440,11,237,4,0,
440,11,240,4,0,
440,11,241,4,0,
440,11,244,4,0,
440,11,247,4,0,
440,11,258,4,0,
440,11,263,4,0,
440,11,270,2,0,
440,11,287,1,9,
440,11,312,2,0,
440,11,356,2,0,
440,11,363,2,0,
440,11,374,4,0,
440,11,383,1,5,
440,11,387,2,0,
440,11,426,2,0,
440,11,447,4,0,
440,11,496,4,0,
440,11,497,4,0,
440,11,510,4,0,
440,11,526,4,0,
440,14,1,1,1,1
440,14,53,4,0,
440,14,68,2,0,
440,14,76,4,0,
440,14,86,4,0,
440,14,92,4,0,
440,14,94,4,0,
440,14,104,4,0,
440,14,113,4,0,
440,14,118,2,0,
440,14,126,4,0,
440,14,138,4,0,
440,14,148,4,0,
440,14,156,4,0,
440,14,164,4,0,
440,14,173,3,0,
440,14,182,4,0,
440,14,186,1,12,
440,14,196,3,0,
440,14,203,2,0,
440,14,204,1,1,2
440,14,207,4,0,
440,14,213,4,0,
440,14,214,3,0,
440,14,215,2,0,
440,14,215,3,0,
440,14,216,4,0,
440,14,217,2,0,
440,14,218,4,0,
440,14,219,4,0,
440,14,237,4,0,
440,14,240,4,0,
440,14,241,4,0,
440,14,244,4,0,
440,14,247,4,0,
440,14,253,3,0,
440,14,258,4,0,
440,14,263,4,0,
440,14,270,2,0,
440,14,270,3,0,
440,14,278,3,0,
440,14,283,3,0,
440,14,287,1,9,
440,14,304,3,0,
440,14,312,2,0,
440,14,343,3,0,
440,14,356,2,0,
440,14,356,3,0,
440,14,363,2,0,
440,14,374,4,0,
440,14,383,1,5,
440,14,387,2,0,
440,14,387,3,0,
440,14,409,3,0,
440,14,426,2,0,
440,14,428,3,0,
440,14,447,4,0,
440,14,496,4,0,
440,14,497,4,0,
440,14,510,4,0,
440,14,526,4,0,
440,15,1,1,1,1
440,15,53,4,0,
440,15,68,2,0,
440,15,76,4,0,
440,15,86,4,0,
440,15,92,4,0,
440,15,94,4,0,
440,15,104,4,0,
440,15,113,4,0,
440,15,118,2,0,
440,15,126,4,0,
440,15,138,4,0,
440,15,148,4,0,
440,15,156,4,0,
440,15,164,4,0,
440,15,182,4,0,
440,15,186,1,12,
440,15,203,2,0,
440,15,204,1,1,2
440,15,207,4,0,
440,15,213,4,0,
440,15,214,4,0,
440,15,215,2,0,
440,15,216,4,0,
440,15,217,2,0,
440,15,218,4,0,
440,15,219,4,0,
440,15,237,4,0,
440,15,240,4,0,
440,15,241,4,0,
440,15,244,4,0,
440,15,247,4,0,
440,15,258,4,0,
440,15,263,4,0,
440,15,270,2,0,
440,15,287,1,9,
440,15,312,2,0,
440,15,356,2,0,
440,15,363,2,0,
440,15,374,4,0,
440,15,383,1,5,
440,15,387,2,0,
440,15,426,2,0,
440,15,447,4,0,
440,15,496,4,0,
440,15,497,4,0,
440,15,510,4,0,
440,15,590,4,0,
440,16,1,1,1,1
440,16,53,4,0,
440,16,68,2,0,
440,16,76,4,0,
440,16,86,4,0,
440,16,92,4,0,
440,16,94,4,0,
440,16,104,4,0,
440,16,113,4,0,
440,16,118,2,0,
440,16,126,4,0,
440,16,138,4,0,
440,16,148,4,0,
440,16,156,4,0,
440,16,164,4,0,
440,16,173,3,0,
440,16,182,4,0,
440,16,186,1,12,1
440,16,196,3,0,
440,16,203,2,0,
440,16,204,1,1,2
440,16,207,4,0,
440,16,213,4,0,
440,16,214,4,0,
440,16,215,2,0,
440,16,215,3,0,
440,16,216,4,0,
440,16,217,2,0,
440,16,218,4,0,
440,16,219,4,0,
440,16,237,4,0,
440,16,240,4,0,
440,16,241,4,0,
440,16,244,4,0,
440,16,247,4,0,
440,16,253,3,0,
440,16,258,4,0,
440,16,263,4,0,
440,16,270,2,0,
440,16,270,3,0,
440,16,278,3,0,
440,16,283,3,0,
440,16,287,1,9,1
440,16,290,4,0,
440,16,304,3,0,
440,16,312,2,0,
440,16,343,3,0,
440,16,351,3,0,
440,16,352,3,0,
440,16,356,2,0,
440,16,356,3,0,
440,16,363,2,0,
440,16,374,4,0,
440,16,383,1,5,1
440,16,387,2,0,
440,16,387,3,0,
440,16,409,3,0,
440,16,426,2,0,
440,16,428,3,0,
440,16,447,4,0,
440,16,496,4,0,
440,16,497,4,0,
440,16,510,4,0,
440,16,590,4,0,
440,17,1,1,1,1
440,17,53,4,0,
440,17,68,2,0,
440,17,76,4,0,
440,17,86,4,0,
440,17,92,4,0,
440,17,94,4,0,
440,17,104,4,0,
440,17,113,4,0,
440,17,118,2,0,
440,17,126,4,0,
440,17,138,4,0,
440,17,156,4,0,
440,17,164,4,0,
440,17,182,4,0,
440,17,186,1,12,
440,17,203,2,0,
440,17,204,1,1,2
440,17,207,4,0,
440,17,213,4,0,
440,17,214,4,0,
440,17,215,2,0,
440,17,216,4,0,
440,17,217,2,0,
440,17,218,4,0,
440,17,219,4,0,
440,17,237,4,0,
440,17,240,4,0,
440,17,241,4,0,
440,17,244,4,0,
440,17,247,4,0,
440,17,258,4,0,
440,17,263,4,0,
440,17,270,2,0,
440,17,287,1,9,
440,17,312,2,0,
440,17,356,2,0,
440,17,363,2,0,
440,17,374,4,0,
440,17,383,1,5,
440,17,387,2,0,
440,17,426,2,0,
440,17,447,4,0,
440,17,496,4,0,
440,17,497,4,0,
440,17,526,4,0,
440,17,590,4,0,
440,18,1,1,1,1
440,18,53,4,0,
440,18,68,2,0,
440,18,76,4,0,
440,18,86,4,0,
440,18,92,4,0,
440,18,94,4,0,
440,18,104,4,0,
440,18,113,4,0,
440,18,118,2,0,
440,18,126,4,0,
440,18,138,4,0,
440,18,156,4,0,
440,18,164,4,0,
440,18,182,4,0,
440,18,186,1,12,
440,18,203,2,0,
440,18,204,1,1,2
440,18,207,4,0,
440,18,213,4,0,
440,18,214,4,0,
440,18,215,2,0,
440,18,216,4,0,
440,18,217,2,0,
440,18,218,4,0,
440,18,219,4,0,
440,18,237,4,0,
440,18,240,4,0,
440,18,241,4,0,
440,18,244,4,0,
440,18,247,4,0,
440,18,258,4,0,
440,18,263,4,0,
440,18,270,2,0,
440,18,287,1,9,
440,18,312,2,0,
440,18,356,2,0,
440,18,363,2,0,
440,18,374,4,0,
440,18,383,1,5,
440,18,387,2,0,
440,18,426,2,0,
440,18,447,4,0,
440,18,496,4,0,
440,18,497,4,0,
440,18,526,4,0,
440,18,590,4,0,
441,8,19,4,0,
441,8,31,1,17,
441,8,45,1,5,
441,8,47,1,13,
441,8,48,2,0,
441,8,64,1,1,
441,8,92,4,0,
441,8,97,2,0,
441,8,101,2,0,
441,8,102,1,29,
441,8,104,4,0,
441,8,119,1,9,
441,8,156,4,0,
441,8,164,4,0,
441,8,168,4,0,
441,8,182,4,0,
441,8,203,4,0,
441,8,207,4,0,
441,8,211,4,0,
441,8,213,4,0,
441,8,214,4,0,
441,8,216,4,0,
441,8,218,4,0,
441,8,227,2,0,
441,8,237,4,0,
441,8,240,4,0,
441,8,241,4,0,
441,8,253,1,37,
441,8,259,4,0,
441,8,263,4,0,
441,8,269,1,25,
441,8,269,4,0,
441,8,290,4,0,
441,8,297,1,41,
441,8,304,1,45,
441,8,332,4,0,
441,8,355,1,33,
441,8,355,4,0,
441,8,363,4,0,
441,8,365,4,0,
441,8,369,4,0,
441,8,417,2,0,
441,8,432,4,0,
441,8,445,4,0,
441,8,448,1,21,
441,9,19,4,0,
441,9,31,1,17,
441,9,45,1,5,
441,9,47,1,13,
441,9,48,2,0,
441,9,64,1,1,
441,9,92,4,0,
441,9,97,2,0,
441,9,101,2,0,
441,9,102,1,29,
441,9,104,4,0,
441,9,119,1,9,
441,9,129,3,0,
441,9,156,4,0,
441,9,164,4,0,
441,9,168,4,0,
441,9,173,3,0,
441,9,182,4,0,
441,9,189,3,0,
441,9,203,4,0,
441,9,207,4,0,
441,9,211,4,0,
441,9,213,4,0,
441,9,214,4,0,
441,9,216,4,0,
441,9,218,4,0,
441,9,227,2,0,
441,9,237,4,0,
441,9,239,3,0,
441,9,240,4,0,
441,9,241,4,0,
441,9,253,1,37,
441,9,253,3,0,
441,9,257,3,0,
441,9,259,4,0,
441,9,263,4,0,
441,9,269,1,25,
441,9,269,4,0,
441,9,290,4,0,
441,9,297,1,41,
441,9,304,1,45,
441,9,314,3,0,
441,9,332,4,0,
441,9,355,1,33,
441,9,355,4,0,
441,9,363,4,0,
441,9,365,4,0,
441,9,369,4,0,
441,9,417,2,0,
441,9,432,4,0,
441,9,445,4,0,
441,9,448,1,21,
441,9,466,3,0,
441,10,19,4,0,
441,10,31,1,17,
441,10,45,1,5,
441,10,47,1,13,
441,10,48,2,0,
441,10,64,1,1,
441,10,92,4,0,
441,10,97,2,0,
441,10,101,2,0,
441,10,102,1,29,
441,10,104,4,0,
441,10,119,1,9,
441,10,129,3,0,
441,10,143,3,0,
441,10,156,4,0,
441,10,164,4,0,
441,10,168,4,0,
441,10,173,3,0,
441,10,182,4,0,
441,10,189,3,0,
441,10,203,4,0,
441,10,207,4,0,
441,10,211,4,0,
441,10,213,4,0,
441,10,214,4,0,
441,10,216,4,0,
441,10,218,4,0,
441,10,227,2,0,
441,10,237,4,0,
441,10,239,3,0,
441,10,240,4,0,
441,10,241,4,0,
441,10,253,1,37,
441,10,253,3,0,
441,10,257,3,0,
441,10,259,4,0,
441,10,263,4,0,
441,10,269,1,25,
441,10,269,4,0,
441,10,272,3,0,
441,10,290,4,0,
441,10,297,1,41,
441,10,304,1,45,
441,10,314,3,0,
441,10,332,4,0,
441,10,355,1,33,
441,10,355,4,0,
441,10,363,4,0,
441,10,365,4,0,
441,10,366,3,0,
441,10,369,4,0,
441,10,417,2,0,
441,10,445,4,0,
441,10,448,1,21,
441,10,466,3,0,
441,11,19,4,0,
441,11,31,1,17,
441,11,45,1,5,
441,11,47,1,13,
441,11,48,2,0,
441,11,64,1,1,
441,11,92,4,0,
441,11,97,2,0,
441,11,101,2,0,
441,11,102,1,33,
441,11,104,4,0,
441,11,119,1,9,
441,11,156,4,0,
441,11,164,4,0,
441,11,168,4,0,
441,11,182,4,0,
441,11,207,4,0,
441,11,211,2,0,
441,11,213,4,0,
441,11,214,2,0,
441,11,216,4,0,
441,11,218,4,0,
441,11,227,2,0,
441,11,237,4,0,
441,11,240,4,0,
441,11,241,4,0,
441,11,253,1,45,
441,11,259,4,0,
441,11,263,4,0,
441,11,269,1,25,
441,11,269,4,0,
441,11,297,1,53,
441,11,304,1,57,
441,11,314,2,0,
441,11,332,4,0,
441,11,355,1,41,
441,11,365,4,0,
441,11,369,4,0,
441,11,417,2,0,
441,11,432,2,0,
441,11,448,1,21,
441,11,485,1,49,
441,11,496,1,29,
441,11,496,4,0,
441,11,497,1,37,
441,11,497,4,0,
441,11,526,4,0,
441,14,19,4,0,
441,14,31,1,17,
441,14,45,1,5,
441,14,47,1,13,
441,14,48,2,0,
441,14,64,1,1,
441,14,92,4,0,
441,14,97,2,0,
441,14,101,2,0,
441,14,102,1,33,
441,14,104,4,0,
441,14,119,1,9,
441,14,143,3,0,
441,14,156,4,0,
441,14,164,4,0,
441,14,168,4,0,
441,14,173,3,0,
441,14,182,4,0,
441,14,207,4,0,
441,14,211,2,0,
441,14,213,4,0,
441,14,214,2,0,
441,14,214,3,0,
441,14,216,4,0,
441,14,218,4,0,
441,14,227,2,0,
441,14,237,4,0,
441,14,240,4,0,
441,14,241,4,0,
441,14,253,1,45,
441,14,253,3,0,
441,14,257,3,0,
441,14,259,4,0,
441,14,263,4,0,
441,14,269,1,25,
441,14,269,4,0,
441,14,272,3,0,
441,14,297,1,53,
441,14,304,1,57,
441,14,304,3,0,
441,14,314,2,0,
441,14,332,4,0,
441,14,355,1,41,
441,14,355,3,0,
441,14,365,4,0,
441,14,366,3,0,
441,14,369,4,0,
441,14,417,2,0,
441,14,432,2,0,
441,14,448,1,21,
441,14,485,1,49,
441,14,496,1,29,
441,14,496,4,0,
441,14,497,1,37,
441,14,497,4,0,
441,14,526,4,0,
441,15,19,4,0,
441,15,31,1,17,
441,15,45,1,5,
441,15,47,1,13,
441,15,48,2,0,
441,15,64,1,1,5
441,15,92,4,0,
441,15,97,2,0,
441,15,101,2,0,
441,15,102,1,33,
441,15,104,4,0,
441,15,119,1,9,
441,15,156,4,0,
441,15,164,4,0,
441,15,168,4,0,
441,15,182,4,0,
441,15,207,4,0,
441,15,211,2,0,
441,15,211,4,0,
441,15,213,4,0,
441,15,214,2,0,
441,15,214,4,0,
441,15,216,4,0,
441,15,218,4,0,
441,15,227,2,0,
441,15,237,4,0,
441,15,240,4,0,
441,15,241,4,0,
441,15,253,1,45,
441,15,259,4,0,
441,15,263,4,0,
441,15,269,1,1,4
441,15,269,1,25,
441,15,269,4,0,
441,15,297,1,50,
441,15,304,1,1,1
441,15,304,1,57,
441,15,314,2,0,
441,15,332,4,0,
441,15,355,1,41,
441,15,355,4,0,
441,15,369,4,0,
441,15,417,2,0,
441,15,432,2,0,
441,15,448,1,1,2
441,15,448,1,21,
441,15,485,1,49,
441,15,496,1,29,
441,15,496,4,0,
441,15,497,1,37,
441,15,497,4,0,
441,15,586,2,0,
441,15,590,1,1,3
441,15,590,4,0,
441,16,19,4,0,
441,16,31,1,17,1
441,16,45,1,5,1
441,16,47,1,13,1
441,16,48,2,0,
441,16,64,1,1,5
441,16,92,4,0,
441,16,97,2,0,
441,16,101,2,0,
441,16,102,1,33,1
441,16,104,4,0,
441,16,119,1,9,1
441,16,143,3,0,
441,16,156,4,0,
441,16,164,4,0,
441,16,168,4,0,
441,16,173,3,0,
441,16,182,4,0,
441,16,207,4,0,
441,16,211,2,0,
441,16,211,4,0,
441,16,213,4,0,
441,16,214,2,0,
441,16,214,4,0,
441,16,216,4,0,
441,16,218,4,0,
441,16,227,2,0,
441,16,237,4,0,
441,16,240,4,0,
441,16,241,4,0,
441,16,253,1,45,1
441,16,253,3,0,
441,16,257,3,0,
441,16,259,4,0,
441,16,263,4,0,
441,16,269,1,1,4
441,16,269,1,25,1
441,16,269,4,0,
441,16,272,3,0,
441,16,290,4,0,
441,16,297,1,50,1
441,16,304,1,1,1
441,16,304,1,57,1
441,16,304,3,0,
441,16,314,2,0,
441,16,332,4,0,
441,16,355,1,41,1
441,16,355,4,0,
441,16,366,3,0,
441,16,369,4,0,
441,16,417,2,0,
441,16,432,2,0,
441,16,448,1,1,2
441,16,448,1,21,1
441,16,485,1,49,1
441,16,496,1,29,1
441,16,496,4,0,
441,16,497,1,37,1
441,16,497,4,0,
441,16,586,2,0,
441,16,590,1,1,3
441,16,590,4,0,
441,17,19,4,0,
441,17,31,1,17,
441,17,45,1,5,
441,17,47,1,13,
441,17,48,2,0,
441,17,64,1,1,5
441,17,92,4,0,
441,17,97,2,0,
441,17,101,2,0,
441,17,102,1,33,
441,17,104,4,0,
441,17,119,1,9,
441,17,156,4,0,
441,17,164,4,0,
441,17,168,4,0,
441,17,182,4,0,
441,17,207,4,0,
441,17,211,2,0,
441,17,211,4,0,
441,17,213,4,0,
441,17,214,2,0,
441,17,214,4,0,
441,17,216,4,0,
441,17,218,4,0,
441,17,227,2,0,
441,17,237,4,0,
441,17,240,4,0,
441,17,241,4,0,
441,17,253,1,45,
441,17,259,4,0,
441,17,263,4,0,
441,17,269,1,1,4
441,17,269,1,25,
441,17,269,4,0,
441,17,297,1,50,
441,17,304,1,1,1
441,17,304,1,57,
441,17,314,2,0,
441,17,332,4,0,
441,17,355,1,41,
441,17,355,4,0,
441,17,369,4,0,
441,17,417,2,0,
441,17,432,2,0,
441,17,448,1,1,2
441,17,448,1,21,
441,17,485,1,49,
441,17,496,1,29,
441,17,496,4,0,
441,17,497,1,37,
441,17,497,4,0,
441,17,526,4,0,
441,17,586,2,0,
441,17,590,1,1,3
441,17,590,4,0,
441,18,19,4,0,
441,18,31,1,17,
441,18,45,1,5,
441,18,47,1,13,
441,18,48,2,0,
441,18,64,1,1,5
441,18,92,4,0,
441,18,97,2,0,
441,18,101,2,0,
441,18,102,1,33,
441,18,104,4,0,
441,18,119,1,9,
441,18,156,4,0,
441,18,164,4,0,
441,18,168,4,0,
441,18,182,4,0,
441,18,207,4,0,
441,18,211,2,0,
441,18,211,4,0,
441,18,213,4,0,
441,18,214,2,0,
441,18,214,4,0,
441,18,216,4,0,
441,18,218,4,0,
441,18,227,2,0,
441,18,237,4,0,
441,18,240,4,0,
441,18,241,4,0,
441,18,253,1,45,
441,18,259,4,0,
441,18,263,4,0,
441,18,269,1,1,4
441,18,269,1,25,
441,18,269,4,0,
441,18,297,1,50,
441,18,304,1,1,1
441,18,304,1,57,
441,18,314,2,0,
441,18,332,4,0,
441,18,355,1,41,
441,18,355,4,0,
441,18,369,4,0,
441,18,417,2,0,
441,18,432,2,0,
441,18,448,1,1,2
441,18,448,1,21,
441,18,485,1,49,
441,18,496,1,29,
441,18,496,4,0,
441,18,497,1,37,
441,18,497,4,0,
441,18,526,4,0,
441,18,586,2,0,
441,18,590,1,1,3
441,18,590,4,0,
442,8,63,4,0,
442,8,92,4,0,
442,8,94,4,0,
442,8,95,1,13,
442,8,104,4,0,
442,8,108,2,0,
442,8,109,1,1,3
442,8,138,1,19,
442,8,138,4,0,
442,8,148,4,0,
442,8,156,4,0,
442,8,164,4,0,
442,8,168,4,0,
442,8,174,1,1,1
442,8,180,1,1,4
442,8,182,4,0,
442,8,185,1,7,
442,8,194,2,0,
442,8,203,4,0,
442,8,207,4,0,
442,8,213,4,0,
442,8,214,4,0,
442,8,216,4,0,
442,8,218,4,0,
442,8,220,2,0,
442,8,228,1,1,2
442,8,237,4,0,
442,8,240,4,0,
442,8,241,4,0,
442,8,244,4,0,
442,8,247,4,0,
442,8,259,4,0,
442,8,261,4,0,
442,8,262,1,43,
442,8,263,4,0,
442,8,269,4,0,
442,8,286,2,0,
442,8,288,2,0,
442,8,289,4,0,
442,8,290,4,0,
442,8,317,4,0,
442,8,318,4,0,
442,8,347,4,0,
442,8,351,4,0,
442,8,352,4,0,
442,8,363,4,0,
442,8,373,4,0,
442,8,389,1,31,
442,8,399,1,49,
442,8,399,4,0,
442,8,416,4,0,
442,8,417,1,37,
442,8,425,1,1,5
442,8,425,2,0,
442,8,445,4,0,
442,8,466,1,25,
442,9,63,4,0,
442,9,92,4,0,
442,9,94,4,0,
442,9,95,1,13,
442,9,104,4,0,
442,9,108,2,0,
442,9,109,1,1,3
442,9,138,1,19,
442,9,138,4,0,
442,9,148,4,0,
442,9,156,4,0,
442,9,164,4,0,
442,9,168,4,0,
442,9,173,3,0,
442,9,174,1,1,1
442,9,180,1,1,4
442,9,180,3,0,
442,9,182,4,0,
442,9,185,1,7,
442,9,194,2,0,
442,9,196,3,0,
442,9,203,4,0,
442,9,207,4,0,
442,9,213,4,0,
442,9,214,4,0,
442,9,216,4,0,
442,9,218,4,0,
442,9,220,2,0,
442,9,228,1,1,2
442,9,237,4,0,
442,9,240,4,0,
442,9,241,4,0,
442,9,244,4,0,
442,9,247,4,0,
442,9,253,3,0,
442,9,259,4,0,
442,9,261,4,0,
442,9,262,1,43,
442,9,263,4,0,
442,9,269,4,0,
442,9,271,3,0,
442,9,286,2,0,
442,9,288,2,0,
442,9,289,4,0,
442,9,290,4,0,
442,9,317,4,0,
442,9,318,4,0,
442,9,347,4,0,
442,9,351,4,0,
442,9,352,4,0,
442,9,363,4,0,
442,9,373,4,0,
442,9,389,1,31,
442,9,389,3,0,
442,9,399,1,49,
442,9,399,4,0,
442,9,416,4,0,
442,9,417,1,37,
442,9,425,1,1,5
442,9,425,2,0,
442,9,445,4,0,
442,9,466,1,25,
442,9,466,3,0,
442,10,63,4,0,
442,10,92,4,0,
442,10,94,4,0,
442,10,95,1,13,
442,10,104,4,0,
442,10,108,2,0,
442,10,109,1,1,3
442,10,138,1,19,
442,10,138,4,0,
442,10,148,4,0,
442,10,156,4,0,
442,10,164,4,0,
442,10,168,4,0,
442,10,173,3,0,
442,10,174,1,1,1
442,10,180,1,1,4
442,10,180,3,0,
442,10,182,4,0,
442,10,185,1,7,
442,10,194,2,0,
442,10,196,3,0,
442,10,203,4,0,
442,10,207,4,0,
442,10,213,4,0,
442,10,214,4,0,
442,10,216,4,0,
442,10,218,4,0,
442,10,220,2,0,
442,10,220,3,0,
442,10,228,1,1,2
442,10,237,4,0,
442,10,240,4,0,
442,10,241,4,0,
442,10,244,4,0,
442,10,247,4,0,
442,10,253,3,0,
442,10,259,4,0,
442,10,261,4,0,
442,10,262,1,43,
442,10,263,4,0,
442,10,269,4,0,
442,10,271,3,0,
442,10,286,2,0,
442,10,288,2,0,
442,10,289,4,0,
442,10,290,4,0,
442,10,317,4,0,
442,10,318,4,0,
442,10,347,4,0,
442,10,351,4,0,
442,10,352,4,0,
442,10,363,4,0,
442,10,373,4,0,
442,10,389,1,31,
442,10,389,3,0,
442,10,399,1,49,
442,10,399,4,0,
442,10,416,4,0,
442,10,417,1,37,
442,10,425,1,1,5
442,10,425,2,0,
442,10,445,4,0,
442,10,466,1,25,
442,10,466,3,0,
442,11,63,4,0,
442,11,92,4,0,
442,11,94,4,0,
442,11,95,1,13,
442,11,104,4,0,
442,11,108,2,0,
442,11,109,1,1,3
442,11,138,1,19,
442,11,138,4,0,
442,11,148,4,0,
442,11,156,4,0,
442,11,164,4,0,
442,11,168,4,0,
442,11,171,2,0,
442,11,174,1,1,1
442,11,180,1,1,4
442,11,182,4,0,
442,11,185,1,7,
442,11,194,2,0,
442,11,207,4,0,
442,11,213,4,0,
442,11,216,4,0,
442,11,218,4,0,
442,11,220,2,0,
442,11,228,1,1,2
442,11,237,4,0,
442,11,240,4,0,
442,11,241,4,0,
442,11,244,4,0,
442,11,247,4,0,
442,11,259,4,0,
442,11,261,4,0,
442,11,262,1,43,
442,11,263,4,0,
442,11,269,4,0,
442,11,286,2,0,
442,11,288,2,0,
442,11,317,4,0,
442,11,347,4,0,
442,11,373,4,0,
442,11,389,1,31,
442,11,399,1,49,
442,11,416,4,0,
442,11,417,1,37,
442,11,425,1,1,5
442,11,425,2,0,
442,11,445,2,0,
442,11,466,1,25,
442,11,477,4,0,
442,11,496,4,0,
442,11,511,4,0,
442,11,514,4,0,
442,11,555,4,0,
442,14,63,4,0,
442,14,92,4,0,
442,14,94,4,0,
442,14,95,1,13,
442,14,104,4,0,
442,14,108,2,0,
442,14,109,1,1,3
442,14,138,1,19,
442,14,138,4,0,
442,14,148,4,0,
442,14,156,4,0,
442,14,164,4,0,
442,14,168,4,0,
442,14,171,2,0,
442,14,173,3,0,
442,14,174,1,1,1
442,14,180,1,1,4
442,14,180,3,0,
442,14,182,4,0,
442,14,185,1,7,
442,14,194,2,0,
442,14,196,3,0,
442,14,207,4,0,
442,14,213,4,0,
442,14,214,3,0,
442,14,216,4,0,
442,14,218,4,0,
442,14,220,2,0,
442,14,220,3,0,
442,14,228,1,1,2
442,14,237,4,0,
442,14,240,4,0,
442,14,241,4,0,
442,14,244,4,0,
442,14,247,4,0,
442,14,253,3,0,
442,14,259,4,0,
442,14,261,4,0,
442,14,262,1,43,
442,14,263,4,0,
442,14,269,4,0,
442,14,271,3,0,
442,14,286,2,0,
442,14,288,2,0,
442,14,289,3,0,
442,14,317,4,0,
442,14,347,4,0,
442,14,373,4,0,
442,14,389,1,31,
442,14,399,1,49,
442,14,399,3,0,
442,14,416,4,0,
442,14,417,1,37,
442,14,425,1,1,5
442,14,425,2,0,
442,14,445,2,0,
442,14,466,1,25,
442,14,472,3,0,
442,14,477,4,0,
442,14,492,3,0,
442,14,496,4,0,
442,14,511,4,0,
442,14,514,4,0,
442,14,555,4,0,
442,15,63,4,0,
442,15,92,4,0,
442,15,94,4,0,
442,15,95,1,13,
442,15,104,4,0,
442,15,108,2,0,
442,15,109,1,1,3
442,15,138,1,19,
442,15,138,4,0,
442,15,148,4,0,
442,15,156,4,0,
442,15,164,4,0,
442,15,168,4,0,
442,15,171,2,0,
442,15,174,1,1,1
442,15,180,1,1,4
442,15,182,4,0,
442,15,185,1,7,
442,15,194,2,0,
442,15,207,4,0,
442,15,213,4,0,
442,15,214,4,0,
442,15,216,4,0,
442,15,218,4,0,
442,15,220,2,0,
442,15,228,1,1,2
442,15,237,4,0,
442,15,240,4,0,
442,15,241,4,0,
442,15,244,4,0,
442,15,247,4,0,
442,15,259,4,0,
442,15,261,4,0,
442,15,262,1,43,
442,15,263,4,0,
442,15,269,4,0,
442,15,286,2,0,
442,15,288,2,0,
442,15,317,4,0,
442,15,347,4,0,
442,15,373,4,0,
442,15,389,1,31,
442,15,399,1,49,
442,15,399,4,0,
442,15,416,4,0,
442,15,417,1,37,
442,15,425,1,1,5
442,15,425,2,0,
442,15,445,2,0,
442,15,466,1,25,
442,15,492,2,0,
442,15,496,4,0,
442,15,511,4,0,
442,15,514,4,0,
442,15,555,4,0,
442,15,590,4,0,
442,15,611,4,0,
442,16,63,4,0,
442,16,92,4,0,
442,16,94,4,0,
442,16,95,1,13,1
442,16,104,4,0,
442,16,108,2,0,
442,16,109,1,1,3
442,16,138,1,19,1
442,16,138,4,0,
442,16,148,4,0,
442,16,156,4,0,
442,16,164,4,0,
442,16,168,4,0,
442,16,171,2,0,
442,16,173,3,0,
442,16,174,1,1,1
442,16,180,1,1,4
442,16,180,3,0,
442,16,182,4,0,
442,16,185,1,7,1
442,16,194,2,0,
442,16,196,3,0,
442,16,207,4,0,
442,16,213,4,0,
442,16,214,4,0,
442,16,216,4,0,
442,16,218,4,0,
442,16,220,2,0,
442,16,220,3,0,
442,16,228,1,1,2
442,16,237,4,0,
442,16,240,4,0,
442,16,241,4,0,
442,16,244,4,0,
442,16,247,4,0,
442,16,253,3,0,
442,16,259,4,0,
442,16,261,4,0,
442,16,262,1,43,1
442,16,263,4,0,
442,16,269,4,0,
442,16,271,3,0,
442,16,286,2,0,
442,16,288,2,0,
442,16,289,3,0,
442,16,290,4,0,
442,16,317,4,0,
442,16,347,4,0,
442,16,351,3,0,
442,16,352,3,0,
442,16,373,4,0,
442,16,389,1,31,1
442,16,399,1,49,1
442,16,399,4,0,
442,16,416,4,0,
442,16,417,1,37,1
442,16,425,1,1,5
442,16,425,2,0,
442,16,445,2,0,
442,16,466,1,25,1
442,16,472,3,0,
442,16,492,2,0,
442,16,492,3,0,
442,16,496,4,0,
442,16,511,4,0,
442,16,514,4,0,
442,16,555,4,0,
442,16,590,4,0,
442,16,611,4,0,
442,17,63,4,0,
442,17,92,4,0,
442,17,94,4,0,
442,17,95,1,13,
442,17,104,4,0,
442,17,108,2,0,
442,17,109,1,1,3
442,17,138,1,19,
442,17,138,4,0,
442,17,156,4,0,
442,17,164,4,0,
442,17,168,4,0,
442,17,171,2,0,
442,17,174,1,1,1
442,17,180,1,1,4
442,17,182,4,0,
442,17,185,1,7,
442,17,194,2,0,
442,17,207,4,0,
442,17,213,4,0,
442,17,214,4,0,
442,17,216,4,0,
442,17,218,4,0,
442,17,220,2,0,
442,17,228,1,1,2
442,17,237,4,0,
442,17,240,4,0,
442,17,241,4,0,
442,17,244,4,0,
442,17,247,4,0,
442,17,259,4,0,
442,17,261,4,0,
442,17,262,1,43,
442,17,263,4,0,
442,17,269,4,0,
442,17,286,2,0,
442,17,288,2,0,
442,17,317,4,0,
442,17,347,4,0,
442,17,373,4,0,
442,17,389,1,31,
442,17,399,1,49,
442,17,399,4,0,
442,17,416,4,0,
442,17,417,1,37,
442,17,425,1,1,5
442,17,425,2,0,
442,17,445,2,0,
442,17,466,1,25,
442,17,492,2,0,
442,17,496,4,0,
442,17,511,4,0,
442,17,555,4,0,
442,17,590,4,0,
442,17,611,4,0,
442,18,50,2,0,
442,18,63,4,0,
442,18,92,4,0,
442,18,94,4,0,
442,18,95,1,13,
442,18,104,4,0,
442,18,108,2,0,
442,18,109,1,1,3
442,18,138,1,19,
442,18,138,4,0,
442,18,156,4,0,
442,18,164,4,0,
442,18,168,4,0,
442,18,171,2,0,
442,18,174,1,1,1
442,18,180,1,1,4
442,18,182,4,0,
442,18,185,1,7,
442,18,194,2,0,
442,18,207,4,0,
442,18,213,4,0,
442,18,214,4,0,
442,18,216,4,0,
442,18,218,4,0,
442,18,220,2,0,
442,18,228,1,1,2
442,18,237,4,0,
442,18,240,4,0,
442,18,241,4,0,
442,18,244,4,0,
442,18,247,4,0,
442,18,259,4,0,
442,18,261,4,0,
442,18,262,1,43,
442,18,263,4,0,
442,18,269,4,0,
442,18,286,2,0,
442,18,288,2,0,
442,18,317,4,0,
442,18,347,4,0,
442,18,373,4,0,
442,18,389,1,31,
442,18,399,1,49,
442,18,399,4,0,
442,18,416,4,0,
442,18,417,1,37,
442,18,425,1,1,5
442,18,425,2,0,
442,18,445,2,0,
442,18,466,1,25,
442,18,492,2,0,
442,18,496,4,0,
442,18,511,4,0,
442,18,555,4,0,
442,18,590,4,0,
442,18,611,4,0,
443,8,15,4,0,
443,8,28,1,3,
443,8,33,1,1,
443,8,34,2,0,
443,8,36,1,15,
443,8,37,2,0,
443,8,38,2,0,
443,8,46,4,0,
443,8,53,4,0,
443,8,70,4,0,
443,8,82,1,7,
443,8,89,4,0,
443,8,91,1,31,
443,8,91,4,0,
443,8,92,4,0,
443,8,104,4,0,
443,8,126,4,0,
443,8,156,4,0,
443,8,157,4,0,
443,8,163,1,25,
443,8,164,4,0,
443,8,182,4,0,
443,8,184,2,0,
443,8,200,2,0,
443,8,201,1,13,
443,8,201,4,0,
443,8,203,4,0,
443,8,207,4,0,
443,8,213,4,0,
443,8,214,4,0,
443,8,216,4,0,
443,8,218,4,0,
443,8,225,2,0,
443,8,232,2,0,
443,8,237,4,0,
443,8,239,2,0,
443,8,240,4,0,
443,8,241,4,0,
443,8,249,4,0,
443,8,263,4,0,
443,8,290,4,0,
443,8,317,4,0,
443,8,328,1,19,
443,8,328,2,0,
443,8,332,4,0,
443,8,337,1,27,
443,8,337,4,0,
443,8,363,4,0,
443,8,406,4,0,
443,8,407,1,37,
443,8,421,4,0,
443,8,431,4,0,
443,8,434,3,0,
443,8,442,2,0,
443,8,444,4,0,
443,8,445,4,0,
443,8,446,4,0,
443,9,15,4,0,
443,9,28,1,3,
443,9,33,1,1,
443,9,34,2,0,
443,9,36,1,15,
443,9,37,2,0,
443,9,38,2,0,
443,9,46,4,0,
443,9,53,4,0,
443,9,70,4,0,
443,9,82,1,7,
443,9,89,4,0,
443,9,91,1,31,
443,9,91,4,0,
443,9,92,4,0,
443,9,104,4,0,
443,9,126,4,0,
443,9,129,3,0,
443,9,156,4,0,
443,9,157,4,0,
443,9,163,1,25,
443,9,164,4,0,
443,9,173,3,0,
443,9,182,4,0,
443,9,184,2,0,
443,9,189,3,0,
443,9,200,2,0,
443,9,200,3,0,
443,9,201,1,13,
443,9,201,4,0,
443,9,203,4,0,
443,9,207,4,0,
443,9,210,3,0,
443,9,213,4,0,
443,9,214,4,0,
443,9,216,4,0,
443,9,218,4,0,
443,9,225,2,0,
443,9,232,2,0,
443,9,237,4,0,
443,9,239,2,0,
443,9,239,3,0,
443,9,240,4,0,
443,9,241,4,0,
443,9,249,4,0,
443,9,263,4,0,
443,9,290,4,0,
443,9,317,4,0,
443,9,328,1,19,
443,9,328,2,0,
443,9,332,4,0,
443,9,337,1,27,
443,9,337,4,0,
443,9,363,4,0,
443,9,406,4,0,
443,9,407,1,37,
443,9,414,3,0,
443,9,421,4,0,
443,9,431,4,0,
443,9,434,3,0,
443,9,442,2,0,
443,9,442,3,0,
443,9,444,4,0,
443,9,445,4,0,
443,9,446,4,0,
443,10,15,4,0,
443,10,28,1,3,
443,10,29,3,0,
443,10,33,1,1,
443,10,34,2,0,
443,10,36,1,15,
443,10,37,2,0,
443,10,38,2,0,
443,10,46,4,0,
443,10,53,4,0,
443,10,70,4,0,
443,10,82,1,7,
443,10,89,4,0,
443,10,91,1,31,
443,10,91,4,0,
443,10,92,4,0,
443,10,104,4,0,
443,10,126,4,0,
443,10,129,3,0,
443,10,156,4,0,
443,10,157,4,0,
443,10,163,1,25,
443,10,164,4,0,
443,10,173,3,0,
443,10,182,4,0,
443,10,184,2,0,
443,10,189,3,0,
443,10,200,2,0,
443,10,200,3,0,
443,10,201,1,13,
443,10,201,4,0,
443,10,203,4,0,
443,10,207,4,0,
443,10,210,3,0,
443,10,213,4,0,
443,10,214,4,0,
443,10,216,4,0,
443,10,218,4,0,
443,10,225,2,0,
443,10,232,2,0,
443,10,237,4,0,
443,10,239,2,0,
443,10,239,3,0,
443,10,240,4,0,
443,10,241,4,0,
443,10,249,4,0,
443,10,263,4,0,
443,10,290,4,0,
443,10,317,4,0,
443,10,328,1,19,
443,10,328,2,0,
443,10,332,4,0,
443,10,337,1,27,
443,10,337,4,0,
443,10,341,2,0,
443,10,363,4,0,
443,10,406,4,0,
443,10,407,1,37,
443,10,414,3,0,
443,10,421,4,0,
443,10,431,4,0,
443,10,434,3,0,
443,10,442,2,0,
443,10,442,3,0,
443,10,444,4,0,
443,10,445,4,0,
443,10,446,4,0,
443,11,15,4,0,
443,11,28,1,3,
443,11,33,1,1,
443,11,34,2,0,
443,11,36,1,15,
443,11,37,2,0,
443,11,38,2,0,
443,11,46,4,0,
443,11,53,4,0,
443,11,70,4,0,
443,11,82,1,7,
443,11,89,4,0,
443,11,91,1,31,
443,11,91,4,0,
443,11,92,4,0,
443,11,104,4,0,
443,11,126,4,0,
443,11,156,4,0,
443,11,157,4,0,
443,11,163,1,25,
443,11,164,4,0,
443,11,182,4,0,
443,11,184,2,0,
443,11,200,2,0,
443,11,201,1,13,
443,11,201,4,0,
443,11,207,4,0,
443,11,213,4,0,
443,11,216,4,0,
443,11,218,4,0,
443,11,225,2,0,
443,11,231,2,0,
443,11,232,2,0,
443,11,237,4,0,
443,11,239,2,0,
443,11,240,4,0,
443,11,241,4,0,
443,11,249,4,0,
443,11,263,4,0,
443,11,317,4,0,
443,11,328,1,19,
443,11,328,2,0,
443,11,332,4,0,
443,11,337,1,27,
443,11,337,4,0,
443,11,341,2,0,
443,11,407,1,37,
443,11,421,4,0,
443,11,431,2,0,
443,11,434,3,0,
443,11,442,2,0,
443,11,444,4,0,
443,11,468,4,0,
443,11,496,4,0,
443,11,510,4,0,
443,11,523,4,0,
443,14,15,4,0,
443,14,28,1,3,
443,14,33,1,1,
443,14,34,2,0,
443,14,36,1,15,
443,14,37,2,0,
443,14,38,2,0,
443,14,46,4,0,
443,14,53,4,0,
443,14,70,4,0,
443,14,82,1,7,
443,14,89,4,0,
443,14,91,1,31,
443,14,91,4,0,
443,14,92,4,0,
443,14,104,4,0,
443,14,126,4,0,
443,14,156,4,0,
443,14,157,4,0,
443,14,163,1,25,
443,14,164,4,0,
443,14,173,3,0,
443,14,182,4,0,
443,14,184,2,0,
443,14,200,2,0,
443,14,200,3,0,
443,14,201,1,13,
443,14,201,4,0,
443,14,207,4,0,
443,14,213,4,0,
443,14,214,3,0,
443,14,216,4,0,
443,14,218,4,0,
443,14,225,2,0,
443,14,231,2,0,
443,14,231,3,0,
443,14,232,2,0,
443,14,237,4,0,
443,14,239,2,0,
443,14,240,4,0,
443,14,241,4,0,
443,14,249,4,0,
443,14,263,4,0,
443,14,317,4,0,
443,14,328,1,19,
443,14,328,2,0,
443,14,332,4,0,
443,14,337,1,27,
443,14,337,4,0,
443,14,341,2,0,
443,14,406,3,0,
443,14,407,1,37,
443,14,414,3,0,
443,14,421,4,0,
443,14,431,2,0,
443,14,434,3,0,
443,14,442,2,0,
443,14,442,3,0,
443,14,444,4,0,
443,14,446,3,0,
443,14,468,4,0,
443,14,496,4,0,
443,14,510,4,0,
443,14,523,4,0,
443,15,15,4,0,
443,15,28,1,3,
443,15,33,1,1,
443,15,34,2,0,
443,15,36,1,15,
443,15,37,2,0,
443,15,38,2,0,
443,15,46,4,0,
443,15,53,4,0,
443,15,70,4,0,
443,15,82,1,7,
443,15,89,4,0,
443,15,91,1,31,
443,15,91,4,0,
443,15,92,4,0,
443,15,104,4,0,
443,15,126,4,0,
443,15,156,4,0,
443,15,157,4,0,
443,15,163,1,25,
443,15,164,4,0,
443,15,182,4,0,
443,15,184,2,0,
443,15,200,2,0,
443,15,201,1,13,
443,15,201,4,0,
443,15,207,4,0,
443,15,213,4,0,
443,15,214,4,0,
443,15,216,4,0,
443,15,218,4,0,
443,15,225,2,0,
443,15,231,2,0,
443,15,232,2,0,
443,15,237,4,0,
443,15,239,2,0,
443,15,240,4,0,
443,15,241,4,0,
443,15,249,4,0,
443,15,263,4,0,
443,15,317,4,0,
443,15,328,1,19,
443,15,328,2,0,
443,15,332,4,0,
443,15,337,1,27,
443,15,337,4,0,
443,15,341,2,0,
443,15,407,1,37,
443,15,421,4,0,
443,15,431,2,0,
443,15,434,3,0,
443,15,442,2,0,
443,15,444,4,0,
443,15,468,4,0,
443,15,496,4,0,
443,15,510,4,0,
443,15,523,4,0,
443,15,590,4,0,
443,16,15,4,0,
443,16,28,1,3,1
443,16,33,1,1,1
443,16,34,2,0,
443,16,36,1,15,1
443,16,37,2,0,
443,16,38,2,0,
443,16,46,4,0,
443,16,53,4,0,
443,16,70,4,0,
443,16,82,1,7,1
443,16,89,4,0,
443,16,91,1,31,1
443,16,91,4,0,
443,16,92,4,0,
443,16,104,4,0,
443,16,126,4,0,
443,16,156,4,0,
443,16,157,4,0,
443,16,163,1,25,1
443,16,164,4,0,
443,16,173,3,0,
443,16,182,4,0,
443,16,184,2,0,
443,16,200,2,0,
443,16,200,3,0,
443,16,201,1,13,1
443,16,201,4,0,
443,16,207,4,0,
443,16,213,4,0,
443,16,214,4,0,
443,16,216,4,0,
443,16,218,4,0,
443,16,225,2,0,
443,16,231,2,0,
443,16,231,3,0,
443,16,232,2,0,
443,16,237,4,0,
443,16,239,2,0,
443,16,240,4,0,
443,16,241,4,0,
443,16,249,4,0,
443,16,263,4,0,
443,16,290,4,0,
443,16,317,4,0,
443,16,328,1,19,1
443,16,328,2,0,
443,16,332,4,0,
443,16,337,1,27,1
443,16,337,4,0,
443,16,341,2,0,
443,16,406,3,0,
443,16,407,1,37,1
443,16,414,3,0,
443,16,421,4,0,
443,16,431,2,0,
443,16,434,3,0,
443,16,442,2,0,
443,16,442,3,0,
443,16,444,4,0,
443,16,446,3,0,
443,16,468,4,0,
443,16,496,4,0,
443,16,510,4,0,
443,16,523,4,0,
443,16,590,4,0,
443,17,28,1,3,
443,17,33,1,1,
443,17,34,2,0,
443,17,36,1,15,
443,17,37,2,0,
443,17,38,2,0,
443,17,46,4,0,
443,17,53,4,0,
443,17,82,1,7,
443,17,89,4,0,
443,17,91,1,31,
443,17,92,4,0,
443,17,104,4,0,
443,17,126,4,0,
443,17,156,4,0,
443,17,157,4,0,
443,17,163,1,25,
443,17,164,4,0,
443,17,182,4,0,
443,17,184,2,0,
443,17,200,2,0,
443,17,201,1,13,
443,17,201,4,0,
443,17,207,4,0,
443,17,213,4,0,
443,17,214,4,0,
443,17,216,4,0,
443,17,218,4,0,
443,17,225,2,0,
443,17,231,2,0,
443,17,232,2,0,
443,17,237,4,0,
443,17,239,2,0,
443,17,240,4,0,
443,17,241,4,0,
443,17,263,4,0,
443,17,317,4,0,
443,17,328,1,19,
443,17,328,2,0,
443,17,332,4,0,
443,17,337,1,27,
443,17,337,4,0,
443,17,341,2,0,
443,17,407,1,37,
443,17,421,4,0,
443,17,431,2,0,
443,17,434,3,0,
443,17,442,2,0,
443,17,444,4,0,
443,17,496,4,0,
443,17,523,4,0,
443,17,590,4,0,
443,18,28,1,3,
443,18,33,1,1,
443,18,34,2,0,
443,18,36,1,15,
443,18,37,2,0,
443,18,38,2,0,
443,18,46,4,0,
443,18,53,4,0,
443,18,82,1,7,
443,18,89,4,0,
443,18,91,1,31,
443,18,92,4,0,
443,18,104,4,0,
443,18,126,4,0,
443,18,156,4,0,
443,18,157,4,0,
443,18,163,1,25,
443,18,164,4,0,
443,18,182,4,0,
443,18,184,2,0,
443,18,200,2,0,
443,18,201,1,13,
443,18,201,4,0,
443,18,207,4,0,
443,18,213,4,0,
443,18,214,4,0,
443,18,216,4,0,
443,18,218,4,0,
443,18,225,2,0,
443,18,231,2,0,
443,18,232,2,0,
443,18,237,4,0,
443,18,239,2,0,
443,18,240,4,0,
443,18,241,4,0,
443,18,263,4,0,
443,18,317,4,0,
443,18,328,1,19,
443,18,328,2,0,
443,18,332,4,0,
443,18,337,1,27,
443,18,337,4,0,
443,18,341,2,0,
443,18,407,1,37,
443,18,421,4,0,
443,18,431,2,0,
443,18,434,3,0,
443,18,442,2,0,
443,18,444,4,0,
443,18,496,4,0,
443,18,523,4,0,
443,18,590,4,0,
444,8,15,4,0,
444,8,28,1,1,2
444,8,28,1,3,
444,8,33,1,1,1
444,8,36,1,15,
444,8,46,4,0,
444,8,53,4,0,
444,8,70,4,0,
444,8,82,1,7,
444,8,89,4,0,
444,8,91,1,40,
444,8,91,4,0,
444,8,92,4,0,
444,8,104,4,0,
444,8,126,4,0,
444,8,156,4,0,
444,8,157,4,0,
444,8,163,1,28,
444,8,164,4,0,
444,8,182,4,0,
444,8,201,1,13,
444,8,201,4,0,
444,8,203,4,0,
444,8,207,4,0,
444,8,213,4,0,
444,8,214,4,0,
444,8,216,4,0,
444,8,218,4,0,
444,8,231,4,0,
444,8,237,4,0,
444,8,240,4,0,
444,8,241,4,0,
444,8,249,4,0,
444,8,263,4,0,
444,8,290,4,0,
444,8,317,4,0,
444,8,328,1,19,
444,8,332,4,0,
444,8,337,1,33,
444,8,337,4,0,
444,8,363,4,0,
444,8,406,4,0,
444,8,407,1,49,
444,8,421,4,0,
444,8,431,4,0,
444,8,434,3,0,
444,8,444,4,0,
444,8,445,4,0,
444,8,446,4,0,
444,9,15,4,0,
444,9,28,1,1,2
444,9,28,1,3,
444,9,33,1,1,1
444,9,36,1,15,
444,9,46,4,0,
444,9,53,4,0,
444,9,70,4,0,
444,9,82,1,7,
444,9,89,4,0,
444,9,91,1,40,
444,9,91,4,0,
444,9,92,4,0,
444,9,104,4,0,
444,9,126,4,0,
444,9,129,3,0,
444,9,156,4,0,
444,9,157,4,0,
444,9,163,1,28,
444,9,164,4,0,
444,9,173,3,0,
444,9,182,4,0,
444,9,189,3,0,
444,9,200,3,0,
444,9,201,1,13,
444,9,201,4,0,
444,9,203,4,0,
444,9,207,4,0,
444,9,210,3,0,
444,9,213,4,0,
444,9,214,4,0,
444,9,216,4,0,
444,9,218,4,0,
444,9,231,4,0,
444,9,237,4,0,
444,9,239,3,0,
444,9,240,4,0,
444,9,241,4,0,
444,9,249,4,0,
444,9,263,4,0,
444,9,290,4,0,
444,9,317,4,0,
444,9,328,1,19,
444,9,332,4,0,
444,9,337,1,33,
444,9,337,4,0,
444,9,363,4,0,
444,9,406,4,0,
444,9,407,1,49,
444,9,414,3,0,
444,9,421,4,0,
444,9,431,4,0,
444,9,434,3,0,
444,9,442,3,0,
444,9,444,4,0,
444,9,445,4,0,
444,9,446,4,0,
444,10,15,4,0,
444,10,28,1,1,2
444,10,28,1,3,
444,10,29,3,0,
444,10,33,1,1,1
444,10,36,1,15,
444,10,46,4,0,
444,10,53,4,0,
444,10,70,4,0,
444,10,82,1,7,
444,10,89,4,0,
444,10,91,1,40,
444,10,91,4,0,
444,10,92,4,0,
444,10,104,4,0,
444,10,126,4,0,
444,10,129,3,0,
444,10,156,4,0,
444,10,157,4,0,
444,10,163,1,28,
444,10,164,4,0,
444,10,173,3,0,
444,10,182,4,0,
444,10,189,3,0,
444,10,200,3,0,
444,10,201,1,13,
444,10,201,4,0,
444,10,203,4,0,
444,10,207,4,0,
444,10,210,3,0,
444,10,213,4,0,
444,10,214,4,0,
444,10,216,4,0,
444,10,218,4,0,
444,10,231,4,0,
444,10,237,4,0,
444,10,239,3,0,
444,10,240,4,0,
444,10,241,4,0,
444,10,249,4,0,
444,10,263,4,0,
444,10,290,4,0,
444,10,317,4,0,
444,10,328,1,19,
444,10,332,4,0,
444,10,337,1,33,
444,10,337,4,0,
444,10,363,4,0,
444,10,406,4,0,
444,10,407,1,49,
444,10,414,3,0,
444,10,421,4,0,
444,10,431,4,0,
444,10,434,3,0,
444,10,442,3,0,
444,10,444,4,0,
444,10,445,4,0,
444,10,446,4,0,
444,11,15,4,0,
444,11,28,1,1,2
444,11,28,1,3,
444,11,33,1,1,1
444,11,36,1,15,
444,11,46,4,0,
444,11,53,4,0,
444,11,70,4,0,
444,11,82,1,7,
444,11,89,4,0,
444,11,91,1,40,
444,11,91,4,0,
444,11,92,4,0,
444,11,104,4,0,
444,11,126,4,0,
444,11,156,4,0,
444,11,157,4,0,
444,11,163,1,28,
444,11,164,4,0,
444,11,182,4,0,
444,11,201,1,13,
444,11,201,4,0,
444,11,207,4,0,
444,11,213,4,0,
444,11,216,4,0,
444,11,218,4,0,
444,11,237,4,0,
444,11,240,4,0,
444,11,241,4,0,
444,11,249,4,0,
444,11,263,4,0,
444,11,317,4,0,
444,11,328,1,19,
444,11,332,4,0,
444,11,337,1,33,
444,11,337,4,0,
444,11,407,1,49,
444,11,421,4,0,
444,11,434,3,0,
444,11,444,4,0,
444,11,468,4,0,
444,11,496,4,0,
444,11,510,4,0,
444,11,523,4,0,
444,11,530,1,24,
444,14,15,4,0,
444,14,28,1,1,2
444,14,28,1,3,
444,14,33,1,1,1
444,14,36,1,15,
444,14,46,4,0,
444,14,53,4,0,
444,14,70,4,0,
444,14,82,1,7,
444,14,89,4,0,
444,14,91,1,40,
444,14,91,4,0,
444,14,92,4,0,
444,14,104,4,0,
444,14,126,4,0,
444,14,156,4,0,
444,14,157,4,0,
444,14,163,1,28,
444,14,164,4,0,
444,14,173,3,0,
444,14,182,4,0,
444,14,200,3,0,
444,14,201,1,13,
444,14,201,4,0,
444,14,207,4,0,
444,14,213,4,0,
444,14,214,3,0,
444,14,216,4,0,
444,14,218,4,0,
444,14,231,3,0,
444,14,237,4,0,
444,14,240,4,0,
444,14,241,4,0,
444,14,249,4,0,
444,14,263,4,0,
444,14,317,4,0,
444,14,328,1,19,
444,14,332,4,0,
444,14,337,1,33,
444,14,337,4,0,
444,14,406,3,0,
444,14,407,1,49,
444,14,414,3,0,
444,14,421,4,0,
444,14,434,3,0,
444,14,442,3,0,
444,14,444,4,0,
444,14,446,3,0,
444,14,468,4,0,
444,14,496,4,0,
444,14,510,4,0,
444,14,523,4,0,
444,14,530,1,24,
444,14,530,3,0,
444,15,15,4,0,
444,15,28,1,1,2
444,15,28,1,3,
444,15,33,1,1,1
444,15,36,1,15,
444,15,46,4,0,
444,15,53,4,0,
444,15,70,4,0,
444,15,82,1,7,
444,15,89,4,0,
444,15,91,1,40,
444,15,91,4,0,
444,15,92,4,0,
444,15,104,4,0,
444,15,126,4,0,
444,15,156,4,0,
444,15,157,4,0,
444,15,163,1,28,
444,15,164,4,0,
444,15,182,4,0,
444,15,201,1,13,
444,15,201,4,0,
444,15,207,4,0,
444,15,213,4,0,
444,15,214,4,0,
444,15,216,4,0,
444,15,218,4,0,
444,15,237,4,0,
444,15,240,4,0,
444,15,241,4,0,
444,15,249,4,0,
444,15,263,4,0,
444,15,317,4,0,
444,15,328,1,19,
444,15,332,4,0,
444,15,337,1,33,
444,15,337,4,0,
444,15,407,1,49,
444,15,421,4,0,
444,15,434,3,0,
444,15,444,4,0,
444,15,468,4,0,
444,15,496,4,0,
444,15,510,4,0,
444,15,523,4,0,
444,15,530,1,24,
444,15,590,4,0,
444,16,15,4,0,
444,16,28,1,1,2
444,16,28,1,3,1
444,16,33,1,1,1
444,16,36,1,15,1
444,16,46,4,0,
444,16,53,4,0,
444,16,70,4,0,
444,16,82,1,7,1
444,16,89,4,0,
444,16,91,1,40,1
444,16,91,4,0,
444,16,92,4,0,
444,16,104,4,0,
444,16,126,4,0,
444,16,156,4,0,
444,16,157,4,0,
444,16,163,1,28,1
444,16,164,4,0,
444,16,173,3,0,
444,16,182,4,0,
444,16,200,3,0,
444,16,201,1,13,1
444,16,201,4,0,
444,16,207,4,0,
444,16,213,4,0,
444,16,214,4,0,
444,16,216,4,0,
444,16,218,4,0,
444,16,231,3,0,
444,16,237,4,0,
444,16,240,4,0,
444,16,241,4,0,
444,16,249,4,0,
444,16,263,4,0,
444,16,290,4,0,
444,16,317,4,0,
444,16,328,1,19,1
444,16,332,4,0,
444,16,337,1,33,1
444,16,337,4,0,
444,16,406,3,0,
444,16,407,1,49,1
444,16,414,3,0,
444,16,421,4,0,
444,16,434,3,0,
444,16,442,3,0,
444,16,444,4,0,
444,16,446,3,0,
444,16,468,4,0,
444,16,496,4,0,
444,16,510,4,0,
444,16,523,4,0,
444,16,530,1,24,1
444,16,530,3,0,
444,16,590,4,0,
444,17,28,1,1,3
444,17,28,1,3,
444,17,33,1,1,2
444,17,36,1,15,
444,17,46,4,0,
444,17,53,4,0,
444,17,82,1,1,4
444,17,82,1,7,
444,17,89,4,0,
444,17,91,1,40,
444,17,92,4,0,
444,17,104,4,0,
444,17,126,4,0,
444,17,156,4,0,
444,17,157,4,0,
444,17,163,1,28,
444,17,164,4,0,
444,17,182,4,0,
444,17,201,1,13,
444,17,201,4,0,
444,17,207,4,0,
444,17,213,4,0,
444,17,214,4,0,
444,17,216,4,0,
444,17,218,4,0,
444,17,237,4,0,
444,17,240,4,0,
444,17,241,4,0,
444,17,263,4,0,
444,17,317,4,0,
444,17,328,1,19,
444,17,332,4,0,
444,17,337,1,33,
444,17,337,4,0,
444,17,407,1,49,
444,17,421,4,0,
444,17,434,3,0,
444,17,444,4,0,
444,17,496,4,0,
444,17,523,4,0,
444,17,530,1,0,
444,17,530,1,1,1
444,17,590,4,0,
444,18,28,1,1,3
444,18,28,1,3,
444,18,33,1,1,2
444,18,36,1,15,
444,18,46,4,0,
444,18,53,4,0,
444,18,82,1,1,4
444,18,82,1,7,
444,18,89,4,0,
444,18,91,1,40,
444,18,92,4,0,
444,18,104,4,0,
444,18,126,4,0,
444,18,156,4,0,
444,18,157,4,0,
444,18,163,1,28,
444,18,164,4,0,
444,18,182,4,0,
444,18,201,1,13,
444,18,201,4,0,
444,18,207,4,0,
444,18,213,4,0,
444,18,214,4,0,
444,18,216,4,0,
444,18,218,4,0,
444,18,237,4,0,
444,18,240,4,0,
444,18,241,4,0,
444,18,263,4,0,
444,18,317,4,0,
444,18,328,1,19,
444,18,332,4,0,
444,18,337,1,33,
444,18,337,4,0,
444,18,407,1,49,
444,18,421,4,0,
444,18,434,3,0,
444,18,444,4,0,
444,18,496,4,0,
444,18,523,4,0,
444,18,530,1,0,
444,18,530,1,1,1
444,18,590,4,0,
445,8,14,4,0,
445,8,15,4,0,
445,8,28,1,1,3
445,8,28,1,3,
445,8,33,1,1,2
445,8,36,1,15,
445,8,46,4,0,
445,8,53,4,0,
445,8,57,4,0,
445,8,63,4,0,
445,8,70,4,0,
445,8,82,1,1,4
445,8,82,1,7,
445,8,89,4,0,
445,8,91,1,40,
445,8,91,4,0,
445,8,92,4,0,
445,8,104,4,0,
445,8,126,4,0,
445,8,156,4,0,
445,8,157,4,0,
445,8,163,1,28,
445,8,164,4,0,
445,8,182,4,0,
445,8,201,1,1,5
445,8,201,1,13,
445,8,201,4,0,
445,8,203,4,0,
445,8,206,4,0,
445,8,207,4,0,
445,8,213,4,0,
445,8,214,4,0,
445,8,216,4,0,
445,8,218,4,0,
445,8,231,4,0,
445,8,237,4,0,
445,8,240,4,0,
445,8,241,4,0,
445,8,242,1,48,
445,8,249,4,0,
445,8,263,4,0,
445,8,280,4,0,
445,8,290,4,0,
445,8,317,4,0,
445,8,328,1,19,
445,8,332,4,0,
445,8,337,1,33,
445,8,337,4,0,
445,8,363,4,0,
445,8,374,4,0,
445,8,398,4,0,
445,8,406,4,0,
445,8,407,1,55,
445,8,416,4,0,
445,8,421,4,0,
445,8,424,1,1,1
445,8,431,4,0,
445,8,434,3,0,
445,8,444,4,0,
445,8,445,4,0,
445,8,446,4,0,
445,9,14,4,0,
445,9,15,4,0,
445,9,28,1,1,3
445,9,28,1,3,
445,9,33,1,1,2
445,9,36,1,15,
445,9,46,4,0,
445,9,53,4,0,
445,9,57,4,0,
445,9,63,4,0,
445,9,70,4,0,
445,9,82,1,1,4
445,9,82,1,7,
445,9,89,4,0,
445,9,91,1,40,
445,9,91,4,0,
445,9,92,4,0,
445,9,104,4,0,
445,9,126,4,0,
445,9,129,3,0,
445,9,156,4,0,
445,9,157,4,0,
445,9,163,1,28,
445,9,164,4,0,
445,9,173,3,0,
445,9,182,4,0,
445,9,189,3,0,
445,9,200,3,0,
445,9,201,1,1,5
445,9,201,1,13,
445,9,201,4,0,
445,9,203,4,0,
445,9,206,4,0,
445,9,207,4,0,
445,9,210,3,0,
445,9,213,4,0,
445,9,214,4,0,
445,9,216,4,0,
445,9,218,4,0,
445,9,231,4,0,
445,9,237,4,0,
445,9,239,3,0,
445,9,240,4,0,
445,9,241,4,0,
445,9,242,1,48,
445,9,249,4,0,
445,9,263,4,0,
445,9,280,4,0,
445,9,290,4,0,
445,9,317,4,0,
445,9,328,1,19,
445,9,332,4,0,
445,9,337,1,33,
445,9,337,4,0,
445,9,363,4,0,
445,9,374,4,0,
445,9,398,4,0,
445,9,401,3,0,
445,9,406,4,0,
445,9,407,1,55,
445,9,414,3,0,
445,9,416,4,0,
445,9,421,4,0,
445,9,424,1,1,1
445,9,431,4,0,
445,9,434,3,0,
445,9,442,3,0,
445,9,444,4,0,
445,9,445,4,0,
445,9,446,4,0,
445,10,14,4,0,
445,10,15,4,0,
445,10,28,1,1,3
445,10,28,1,3,
445,10,29,3,0,
445,10,33,1,1,2
445,10,36,1,15,
445,10,46,4,0,
445,10,53,4,0,
445,10,57,4,0,
445,10,63,4,0,
445,10,70,4,0,
445,10,82,1,1,4
445,10,82,1,7,
445,10,89,4,0,
445,10,91,1,40,
445,10,91,4,0,
445,10,92,4,0,
445,10,104,4,0,
445,10,126,4,0,
445,10,129,3,0,
445,10,156,4,0,
445,10,157,4,0,
445,10,163,1,28,
445,10,164,4,0,
445,10,173,3,0,
445,10,182,4,0,
445,10,189,3,0,
445,10,200,3,0,
445,10,201,1,1,5
445,10,201,1,13,
445,10,201,4,0,
445,10,203,4,0,
445,10,206,4,0,
445,10,207,4,0,
445,10,210,3,0,
445,10,213,4,0,
445,10,214,4,0,
445,10,216,4,0,
445,10,218,4,0,
445,10,231,4,0,
445,10,237,4,0,
445,10,239,3,0,
445,10,240,4,0,
445,10,241,4,0,
445,10,242,1,48,
445,10,249,4,0,
445,10,250,4,0,
445,10,263,4,0,
445,10,280,4,0,
445,10,290,4,0,
445,10,317,4,0,
445,10,328,1,19,
445,10,332,4,0,
445,10,337,1,33,
445,10,337,4,0,
445,10,363,4,0,
445,10,374,4,0,
445,10,398,4,0,
445,10,401,3,0,
445,10,406,4,0,
445,10,407,1,55,
445,10,414,3,0,
445,10,416,4,0,
445,10,421,4,0,
445,10,424,1,1,1
445,10,431,4,0,
445,10,434,3,0,
445,10,442,3,0,
445,10,444,4,0,
445,10,445,4,0,
445,10,446,4,0,
445,11,14,4,0,
445,11,15,4,0,
445,11,28,1,1,3
445,11,28,1,3,
445,11,33,1,1,2
445,11,36,1,15,
445,11,46,4,0,
445,11,53,4,0,
445,11,57,4,0,
445,11,63,4,0,
445,11,70,4,0,
445,11,82,1,1,4
445,11,82,1,7,
445,11,89,4,0,
445,11,91,1,40,
445,11,91,4,0,
445,11,92,4,0,
445,11,104,4,0,
445,11,126,4,0,
445,11,156,4,0,
445,11,157,4,0,
445,11,163,1,28,
445,11,164,4,0,
445,11,182,4,0,
445,11,201,1,1,5
445,11,201,1,13,
445,11,201,4,0,
445,11,206,4,0,
445,11,207,4,0,
445,11,213,4,0,
445,11,216,4,0,
445,11,218,4,0,
445,11,237,4,0,
445,11,240,4,0,
445,11,241,4,0,
445,11,242,1,48,
445,11,249,4,0,
445,11,263,4,0,
445,11,280,4,0,
445,11,317,4,0,
445,11,328,1,19,
445,11,332,4,0,
445,11,337,1,33,
445,11,337,4,0,
445,11,374,4,0,
445,11,398,4,0,
445,11,407,1,55,
445,11,416,4,0,
445,11,421,4,0,
445,11,424,1,1,1
445,11,434,3,0,
445,11,444,4,0,
445,11,468,4,0,
445,11,496,4,0,
445,11,510,4,0,
445,11,523,4,0,
445,11,525,4,0,
445,11,530,1,24,
445,14,14,4,0,
445,14,15,4,0,
445,14,28,1,1,3
445,14,28,1,3,
445,14,33,1,1,2
445,14,36,1,15,
445,14,46,4,0,
445,14,53,4,0,
445,14,57,4,0,
445,14,63,4,0,
445,14,70,4,0,
445,14,82,1,1,4
445,14,82,1,7,
445,14,89,4,0,
445,14,91,1,40,
445,14,91,4,0,
445,14,92,4,0,
445,14,104,4,0,
445,14,126,4,0,
445,14,156,4,0,
445,14,157,4,0,
445,14,163,1,28,
445,14,164,4,0,
445,14,173,3,0,
445,14,182,4,0,
445,14,200,3,0,
445,14,201,1,1,5
445,14,201,1,13,
445,14,201,4,0,
445,14,206,4,0,
445,14,207,4,0,
445,14,213,4,0,
445,14,214,3,0,
445,14,216,4,0,
445,14,218,4,0,
445,14,231,3,0,
445,14,237,4,0,
445,14,240,4,0,
445,14,241,4,0,
445,14,242,1,48,
445,14,249,4,0,
445,14,263,4,0,
445,14,280,4,0,
445,14,317,4,0,
445,14,328,1,19,
445,14,332,4,0,
445,14,337,1,33,
445,14,337,4,0,
445,14,374,4,0,
445,14,398,4,0,
445,14,401,3,0,
445,14,406,3,0,
445,14,407,1,55,
445,14,414,3,0,
445,14,416,4,0,
445,14,421,4,0,
445,14,424,1,1,1
445,14,434,3,0,
445,14,442,3,0,
445,14,444,4,0,
445,14,446,3,0,
445,14,468,4,0,
445,14,496,4,0,
445,14,510,4,0,
445,14,523,4,0,
445,14,525,4,0,
445,14,530,1,24,
445,14,530,3,0,
445,15,14,4,0,
445,15,15,4,0,
445,15,28,1,1,3
445,15,28,1,3,
445,15,33,1,1,2
445,15,36,1,15,
445,15,46,4,0,
445,15,53,4,0,
445,15,57,4,0,
445,15,63,4,0,
445,15,70,4,0,
445,15,82,1,1,4
445,15,82,1,7,
445,15,89,4,0,
445,15,91,1,40,
445,15,91,4,0,
445,15,92,4,0,
445,15,104,4,0,
445,15,126,4,0,
445,15,156,4,0,
445,15,157,4,0,
445,15,163,1,28,
445,15,164,4,0,
445,15,182,4,0,
445,15,201,1,1,5
445,15,201,1,13,
445,15,201,4,0,
445,15,206,4,0,
445,15,207,4,0,
445,15,213,4,0,
445,15,214,4,0,
445,15,216,4,0,
445,15,218,4,0,
445,15,237,4,0,
445,15,240,4,0,
445,15,241,4,0,
445,15,242,1,48,
445,15,249,4,0,
445,15,263,4,0,
445,15,280,4,0,
445,15,317,4,0,
445,15,328,1,19,
445,15,332,4,0,
445,15,337,1,33,
445,15,337,4,0,
445,15,374,4,0,
445,15,398,4,0,
445,15,407,1,55,
445,15,416,4,0,
445,15,421,4,0,
445,15,424,1,1,1
445,15,434,3,0,
445,15,444,4,0,
445,15,468,4,0,
445,15,496,4,0,
445,15,510,4,0,
445,15,523,4,0,
445,15,525,4,0,
445,15,530,1,24,
445,15,590,4,0,
445,16,14,4,0,
445,16,15,4,0,
445,16,28,1,1,3
445,16,28,1,3,1
445,16,33,1,1,2
445,16,36,1,15,1
445,16,46,4,0,
445,16,53,4,0,
445,16,57,4,0,
445,16,63,4,0,
445,16,70,4,0,
445,16,82,1,1,4
445,16,82,1,7,1
445,16,89,4,0,
445,16,91,1,40,1
445,16,91,4,0,
445,16,92,4,0,
445,16,104,4,0,
445,16,126,4,0,
445,16,156,4,0,
445,16,157,4,0,
445,16,163,1,28,1
445,16,164,4,0,
445,16,173,3,0,
445,16,182,4,0,
445,16,200,3,0,
445,16,201,1,1,5
445,16,201,1,13,1
445,16,201,4,0,
445,16,206,4,0,
445,16,207,4,0,
445,16,213,4,0,
445,16,214,4,0,
445,16,216,4,0,
445,16,218,4,0,
445,16,231,3,0,
445,16,237,4,0,
445,16,240,4,0,
445,16,241,4,0,
445,16,242,1,48,1
445,16,249,4,0,
445,16,263,4,0,
445,16,280,4,0,
445,16,290,4,0,
445,16,317,4,0,
445,16,328,1,19,1
445,16,332,4,0,
445,16,337,1,33,1
445,16,337,4,0,
445,16,374,4,0,
445,16,398,4,0,
445,16,401,3,0,
445,16,406,3,0,
445,16,407,1,55,1
445,16,414,3,0,
445,16,416,4,0,
445,16,421,4,0,
445,16,424,1,1,1
445,16,434,3,0,
445,16,442,3,0,
445,16,444,4,0,
445,16,446,3,0,
445,16,468,4,0,
445,16,496,4,0,
445,16,510,4,0,
445,16,523,4,0,
445,16,525,4,0,
445,16,530,1,24,1
445,16,530,3,0,
445,16,590,4,0,
445,17,14,4,0,
445,17,28,1,1,5
445,17,28,1,3,
445,17,33,1,1,4
445,17,36,1,15,
445,17,46,4,0,
445,17,53,4,0,
445,17,57,4,0,
445,17,63,4,0,
445,17,82,1,1,6
445,17,82,1,7,
445,17,89,4,0,
445,17,91,1,40,
445,17,92,4,0,
445,17,104,4,0,
445,17,126,4,0,
445,17,156,4,0,
445,17,157,4,0,
445,17,163,1,28,
445,17,164,4,0,
445,17,182,4,0,
445,17,201,1,1,7
445,17,201,1,13,
445,17,201,4,0,
445,17,206,4,0,
445,17,207,4,0,
445,17,213,4,0,
445,17,214,4,0,
445,17,216,4,0,
445,17,218,4,0,
445,17,237,4,0,
445,17,240,4,0,
445,17,241,4,0,
445,17,242,1,0,
445,17,242,1,1,1
445,17,263,4,0,
445,17,280,4,0,
445,17,317,4,0,
445,17,328,1,19,
445,17,332,4,0,
445,17,337,1,33,
445,17,337,4,0,
445,17,374,4,0,
445,17,398,4,0,
445,17,407,1,55,
445,17,416,4,0,
445,17,421,4,0,
445,17,424,1,1,3
445,17,434,3,0,
445,17,444,4,0,
445,17,496,4,0,
445,17,523,4,0,
445,17,525,4,0,
445,17,530,1,1,2
445,17,590,4,0,
445,17,693,4,0,
445,18,14,4,0,
445,18,28,1,1,5
445,18,28,1,3,
445,18,33,1,1,4
445,18,36,1,15,
445,18,46,4,0,
445,18,53,4,0,
445,18,57,4,0,
445,18,63,4,0,
445,18,82,1,1,6
445,18,82,1,7,
445,18,89,4,0,
445,18,91,1,40,
445,18,92,4,0,
445,18,104,4,0,
445,18,126,4,0,
445,18,156,4,0,
445,18,157,4,0,
445,18,163,1,28,
445,18,164,4,0,
445,18,182,4,0,
445,18,201,1,1,7
445,18,201,1,13,
445,18,201,4,0,
445,18,206,4,0,
445,18,207,4,0,
445,18,213,4,0,
445,18,214,4,0,
445,18,216,4,0,
445,18,218,4,0,
445,18,237,4,0,
445,18,240,4,0,
445,18,241,4,0,
445,18,242,1,0,
445,18,242,1,1,1
445,18,263,4,0,
445,18,280,4,0,
445,18,317,4,0,
445,18,328,1,19,
445,18,332,4,0,
445,18,337,1,33,
445,18,337,4,0,
445,18,374,4,0,
445,18,398,4,0,
445,18,407,1,55,
445,18,416,4,0,
445,18,421,4,0,
445,18,424,1,1,3
445,18,434,3,0,
445,18,444,4,0,
445,18,496,4,0,
445,18,523,4,0,
445,18,525,4,0,
445,18,530,1,1,2
445,18,590,4,0,
445,18,693,4,0,
446,8,18,2,0,
446,8,33,1,1,3
446,8,34,1,33,
446,8,38,2,0,
446,8,53,4,0,
446,8,57,4,0,
446,8,58,4,0,
446,8,59,4,0,
446,8,70,4,0,
446,8,76,4,0,
446,8,85,4,0,
446,8,87,4,0,
446,8,89,4,0,
446,8,92,4,0,
446,8,94,4,0,
446,8,103,1,20,
446,8,104,4,0,
446,8,111,1,4,
446,8,118,1,1,1
446,8,122,1,12,
446,8,122,2,0,
446,8,126,4,0,
446,8,133,1,9,
446,8,156,4,0,
446,8,157,4,0,
446,8,164,2,0,
446,8,164,4,0,
446,8,174,2,0,
446,8,182,4,0,
446,8,201,4,0,
446,8,203,4,0,
446,8,204,2,0,
446,8,205,1,41,
446,8,207,4,0,
446,8,213,4,0,
446,8,214,4,0,
446,8,216,4,0,
446,8,218,4,0,
446,8,228,2,0,
446,8,237,4,0,
446,8,240,4,0,
446,8,241,4,0,
446,8,247,4,0,
446,8,249,4,0,
446,8,254,1,25,
446,8,256,1,28,
446,8,263,4,0,
446,8,264,4,0,
446,8,278,1,17,
446,8,278,4,0,
446,8,280,4,0,
446,8,290,4,0,
446,8,316,1,1,2
446,8,317,4,0,
446,8,351,4,0,
446,8,352,4,0,
446,8,363,1,44,
446,8,363,4,0,
446,8,374,1,36,
446,8,374,4,0,
446,8,387,1,49,
446,8,428,2,0,
446,8,431,4,0,
446,8,445,4,0,
446,9,7,3,0,
446,9,8,3,0,
446,9,9,3,0,
446,9,18,2,0,
446,9,33,1,1,3
446,9,34,1,33,
446,9,38,2,0,
446,9,53,4,0,
446,9,57,4,0,
446,9,58,4,0,
446,9,59,4,0,
446,9,70,4,0,
446,9,76,4,0,
446,9,85,4,0,
446,9,87,4,0,
446,9,89,4,0,
446,9,92,4,0,
446,9,94,4,0,
446,9,103,1,20,
446,9,104,4,0,
446,9,111,1,4,
446,9,118,1,1,1
446,9,122,1,12,
446,9,122,2,0,
446,9,126,4,0,
446,9,133,1,9,
446,9,156,4,0,
446,9,157,4,0,
446,9,164,2,0,
446,9,164,4,0,
446,9,173,3,0,
446,9,174,2,0,
446,9,182,4,0,
446,9,189,3,0,
446,9,196,3,0,
446,9,201,4,0,
446,9,203,4,0,
446,9,204,2,0,
446,9,205,1,41,
446,9,205,3,0,
446,9,207,4,0,
446,9,213,4,0,
446,9,214,4,0,
446,9,216,4,0,
446,9,218,4,0,
446,9,228,2,0,
446,9,237,4,0,
446,9,240,4,0,
446,9,241,4,0,
446,9,247,4,0,
446,9,249,4,0,
446,9,253,3,0,
446,9,254,1,25,
446,9,256,1,28,
446,9,263,4,0,
446,9,264,4,0,
446,9,276,3,0,
446,9,278,1,17,
446,9,278,4,0,
446,9,280,4,0,
446,9,290,4,0,
446,9,316,1,1,2
446,9,317,4,0,
446,9,351,4,0,
446,9,352,4,0,
446,9,363,1,44,
446,9,363,4,0,
446,9,374,1,36,
446,9,374,4,0,
446,9,387,1,49,
446,9,387,3,0,
446,9,402,3,0,
446,9,428,2,0,
446,9,428,3,0,
446,9,431,4,0,
446,9,441,3,0,
446,9,445,4,0,
446,10,7,3,0,
446,10,8,3,0,
446,10,9,3,0,
446,10,18,2,0,
446,10,29,3,0,
446,10,33,1,1,3
446,10,34,1,33,
446,10,38,2,0,
446,10,53,4,0,
446,10,57,4,0,
446,10,58,4,0,
446,10,59,4,0,
446,10,68,2,0,
446,10,70,4,0,
446,10,76,4,0,
446,10,85,4,0,
446,10,87,4,0,
446,10,89,4,0,
446,10,92,4,0,
446,10,94,4,0,
446,10,103,1,20,
446,10,104,4,0,
446,10,111,1,4,
446,10,118,1,1,1
446,10,122,1,12,
446,10,122,2,0,
446,10,126,4,0,
446,10,133,1,9,
446,10,156,4,0,
446,10,157,4,0,
446,10,164,2,0,
446,10,164,4,0,
446,10,173,3,0,
446,10,174,2,0,
446,10,182,4,0,
446,10,189,3,0,
446,10,196,3,0,
446,10,201,4,0,
446,10,203,4,0,
446,10,204,2,0,
446,10,205,1,41,
446,10,205,3,0,
446,10,207,4,0,
446,10,213,4,0,
446,10,214,4,0,
446,10,216,4,0,
446,10,218,4,0,
446,10,228,2,0,
446,10,237,4,0,
446,10,240,4,0,
446,10,241,4,0,
446,10,247,4,0,
446,10,249,4,0,
446,10,250,4,0,
446,10,253,3,0,
446,10,254,1,25,
446,10,256,1,28,
446,10,263,4,0,
446,10,264,4,0,
446,10,276,3,0,
446,10,278,1,17,
446,10,278,4,0,
446,10,280,4,0,
446,10,290,4,0,
446,10,316,1,1,2
446,10,317,4,0,
446,10,351,4,0,
446,10,352,4,0,
446,10,363,1,44,
446,10,363,4,0,
446,10,374,1,36,
446,10,374,4,0,
446,10,387,1,49,
446,10,387,3,0,
446,10,402,3,0,
446,10,428,2,0,
446,10,428,3,0,
446,10,431,4,0,
446,10,441,3,0,
446,10,445,4,0,
446,11,18,2,0,
446,11,33,1,1,3
446,11,34,1,36,
446,11,38,2,0,
446,11,53,4,0,
446,11,57,4,0,
446,11,58,4,0,
446,11,59,4,0,
446,11,68,2,0,
446,11,70,4,0,
446,11,76,4,0,
446,11,85,4,0,
446,11,87,4,0,
446,11,89,4,0,
446,11,92,4,0,
446,11,94,4,0,
446,11,103,1,20,
446,11,104,4,0,
446,11,111,1,4,
446,11,118,1,1,1
446,11,120,2,0,
446,11,122,1,12,
446,11,122,2,0,
446,11,126,4,0,
446,11,133,1,9,
446,11,156,4,0,
446,11,157,4,0,
446,11,164,4,0,
446,11,174,2,0,
446,11,182,4,0,
446,11,201,4,0,
446,11,204,2,0,
446,11,205,1,44,
446,11,207,4,0,
446,11,213,4,0,
446,11,216,4,0,
446,11,218,4,0,
446,11,228,2,0,
446,11,237,4,0,
446,11,240,4,0,
446,11,241,4,0,
446,11,247,4,0,
446,11,249,4,0,
446,11,254,1,28,
446,11,256,1,33,
446,11,263,4,0,
446,11,278,1,17,
446,11,280,4,0,
446,11,289,1,52,
446,11,316,1,1,2
446,11,317,4,0,
446,11,363,1,49,
446,11,363,2,0,
446,11,374,1,41,
446,11,374,4,0,
446,11,387,1,57,
446,11,428,2,0,
446,11,495,2,0,
446,11,496,4,0,
446,11,498,1,25,
446,11,510,4,0,
446,11,514,4,0,
446,11,523,4,0,
446,11,526,4,0,
446,14,7,3,0,
446,14,8,3,0,
446,14,9,3,0,
446,14,18,2,0,
446,14,33,1,1,3
446,14,34,1,36,
446,14,38,2,0,
446,14,53,4,0,
446,14,57,4,0,
446,14,58,4,0,
446,14,59,4,0,
446,14,68,2,0,
446,14,70,4,0,
446,14,76,4,0,
446,14,85,4,0,
446,14,87,4,0,
446,14,89,4,0,
446,14,92,4,0,
446,14,94,4,0,
446,14,103,1,20,
446,14,104,4,0,
446,14,111,1,4,
446,14,118,1,1,1
446,14,120,2,0,
446,14,122,1,12,
446,14,122,2,0,
446,14,126,4,0,
446,14,133,1,9,
446,14,156,4,0,
446,14,157,4,0,
446,14,164,4,0,
446,14,173,3,0,
446,14,174,2,0,
446,14,182,4,0,
446,14,196,3,0,
446,14,201,4,0,
446,14,204,2,0,
446,14,205,1,44,
446,14,207,4,0,
446,14,213,4,0,
446,14,214,3,0,
446,14,216,4,0,
446,14,218,4,0,
446,14,228,2,0,
446,14,237,4,0,
446,14,240,4,0,
446,14,241,4,0,
446,14,247,4,0,
446,14,249,4,0,
446,14,253,3,0,
446,14,254,1,28,
446,14,256,1,33,
446,14,263,4,0,
446,14,276,3,0,
446,14,278,1,17,
446,14,278,3,0,
446,14,280,4,0,
446,14,289,1,52,
446,14,289,3,0,
446,14,304,3,0,
446,14,316,1,1,2
446,14,317,4,0,
446,14,343,3,0,
446,14,363,1,49,
446,14,363,2,0,
446,14,374,1,41,
446,14,374,4,0,
446,14,387,1,57,
446,14,387,3,0,
446,14,402,3,0,
446,14,428,2,0,
446,14,428,3,0,
446,14,441,3,0,
446,14,495,2,0,
446,14,495,3,0,
446,14,496,4,0,
446,14,498,1,25,
446,14,510,4,0,
446,14,514,4,0,
446,14,523,4,0,
446,14,526,4,0,
446,15,18,2,0,
446,15,33,1,1,6
446,15,34,1,25,
446,15,38,2,0,
446,15,53,4,0,
446,15,57,4,0,
446,15,58,4,0,
446,15,59,4,0,
446,15,68,2,0,
446,15,70,4,0,
446,15,76,4,0,
446,15,85,4,0,
446,15,87,4,0,
446,15,89,4,0,
446,15,92,4,0,
446,15,94,4,0,
446,15,103,1,20,
446,15,104,4,0,
446,15,111,1,4,
446,15,118,1,1,4
446,15,120,2,0,
446,15,122,1,1,3
446,15,122,1,12,
446,15,122,2,0,
446,15,126,4,0,
446,15,133,1,9,
446,15,156,4,0,
446,15,157,4,0,
446,15,164,4,0,
446,15,174,2,0,
446,15,182,4,0,
446,15,187,1,44,
446,15,201,4,0,
446,15,204,2,0,
446,15,205,1,36,
446,15,207,4,0,
446,15,213,4,0,
446,15,214,4,0,
446,15,216,4,0,
446,15,218,4,0,
446,15,228,2,0,
446,15,237,4,0,
446,15,240,4,0,
446,15,241,4,0,
446,15,247,4,0,
446,15,249,4,0,
446,15,254,1,28,
446,15,256,1,33,
446,15,263,4,0,
446,15,280,4,0,
446,15,289,1,1,2
446,15,289,1,52,
446,15,316,1,1,5
446,15,317,4,0,
446,15,363,1,49,
446,15,363,2,0,
446,15,374,1,41,
446,15,374,4,0,
446,15,387,1,1,1
446,15,387,1,57,
446,15,428,2,0,
446,15,495,2,0,
446,15,496,4,0,
446,15,498,1,17,
446,15,510,4,0,
446,15,514,4,0,
446,15,523,4,0,
446,15,562,2,0,
446,15,590,4,0,
446,15,612,4,0,
446,16,7,3,0,
446,16,8,3,0,
446,16,9,3,0,
446,16,18,2,0,
446,16,33,1,1,6
446,16,34,1,25,1
446,16,38,2,0,
446,16,53,4,0,
446,16,57,4,0,
446,16,58,4,0,
446,16,59,4,0,
446,16,68,2,0,
446,16,70,4,0,
446,16,76,4,0,
446,16,85,4,0,
446,16,87,4,0,
446,16,89,4,0,
446,16,92,4,0,
446,16,94,4,0,
446,16,103,1,20,1
446,16,104,4,0,
446,16,111,1,4,1
446,16,118,1,1,4
446,16,120,2,0,
446,16,122,1,1,3
446,16,122,1,12,1
446,16,122,2,0,
446,16,126,4,0,
446,16,133,1,9,1
446,16,156,4,0,
446,16,157,4,0,
446,16,164,4,0,
446,16,173,3,0,
446,16,174,2,0,
446,16,182,4,0,
446,16,187,1,44,1
446,16,196,3,0,
446,16,201,4,0,
446,16,204,2,0,
446,16,205,1,36,1
446,16,207,4,0,
446,16,213,4,0,
446,16,214,4,0,
446,16,216,4,0,
446,16,218,4,0,
446,16,228,2,0,
446,16,237,4,0,
446,16,240,4,0,
446,16,241,4,0,
446,16,247,4,0,
446,16,249,4,0,
446,16,253,3,0,
446,16,254,1,28,1
446,16,256,1,33,1
446,16,263,4,0,
446,16,264,3,0,
446,16,276,3,0,
446,16,278,1,1,2
446,16,278,3,0,
446,16,280,4,0,
446,16,289,1,50,1
446,16,289,3,0,
446,16,290,4,0,
446,16,304,3,0,
446,16,316,1,1,5
446,16,317,4,0,
446,16,343,3,0,
446,16,351,3,0,
446,16,352,3,0,
446,16,363,1,49,1
446,16,363,2,0,
446,16,374,1,41,1
446,16,374,4,0,
446,16,387,1,1,1
446,16,387,1,57,1
446,16,387,3,0,
446,16,402,3,0,
446,16,428,2,0,
446,16,428,3,0,
446,16,441,3,0,
446,16,495,2,0,
446,16,495,3,0,
446,16,496,4,0,
446,16,498,1,17,1
446,16,510,4,0,
446,16,514,4,0,
446,16,523,4,0,
446,16,562,2,0,
446,16,590,4,0,
446,16,612,4,0,
446,17,18,2,0,
446,17,33,1,1,6
446,17,34,1,25,
446,17,38,2,0,
446,17,53,4,0,
446,17,57,4,0,
446,17,58,4,0,
446,17,59,4,0,
446,17,68,2,0,
446,17,76,4,0,
446,17,85,4,0,
446,17,87,4,0,
446,17,89,4,0,
446,17,92,4,0,
446,17,94,4,0,
446,17,103,1,20,
446,17,104,4,0,
446,17,111,1,4,
446,17,118,1,1,4
446,17,120,2,0,
446,17,122,1,1,3
446,17,122,1,12,
446,17,122,2,0,
446,17,126,4,0,
446,17,133,1,9,
446,17,156,4,0,
446,17,157,4,0,
446,17,164,4,0,
446,17,174,2,0,
446,17,182,4,0,
446,17,187,1,44,
446,17,201,4,0,
446,17,204,2,0,
446,17,205,1,36,
446,17,207,4,0,
446,17,213,4,0,
446,17,214,4,0,
446,17,216,4,0,
446,17,218,4,0,
446,17,228,2,0,
446,17,237,4,0,
446,17,240,4,0,
446,17,241,4,0,
446,17,247,4,0,
446,17,254,1,28,
446,17,256,1,33,
446,17,263,4,0,
446,17,278,1,1,2
446,17,280,4,0,
446,17,289,1,50,
446,17,316,1,1,5
446,17,317,4,0,
446,17,363,1,49,
446,17,363,2,0,
446,17,374,1,41,
446,17,374,4,0,
446,17,387,1,1,1
446,17,387,1,57,
446,17,428,2,0,
446,17,495,2,0,
446,17,496,4,0,
446,17,498,1,17,
446,17,523,4,0,
446,17,526,4,0,
446,17,562,2,0,
446,17,590,4,0,
446,18,18,2,0,
446,18,33,1,1,6
446,18,34,1,25,
446,18,38,2,0,
446,18,53,4,0,
446,18,57,4,0,
446,18,58,4,0,
446,18,59,4,0,
446,18,68,2,0,
446,18,76,4,0,
446,18,85,4,0,
446,18,87,4,0,
446,18,89,4,0,
446,18,92,4,0,
446,18,94,4,0,
446,18,103,1,20,
446,18,104,4,0,
446,18,111,1,4,
446,18,118,1,1,4
446,18,120,2,0,
446,18,122,1,1,3
446,18,122,1,12,
446,18,122,2,0,
446,18,126,4,0,
446,18,133,1,9,
446,18,156,4,0,
446,18,157,4,0,
446,18,164,4,0,
446,18,174,2,0,
446,18,182,4,0,
446,18,187,1,44,
446,18,201,4,0,
446,18,204,2,0,
446,18,205,1,36,
446,18,207,4,0,
446,18,213,4,0,
446,18,214,4,0,
446,18,216,4,0,
446,18,218,4,0,
446,18,228,2,0,
446,18,237,4,0,
446,18,240,4,0,
446,18,241,4,0,
446,18,247,4,0,
446,18,254,1,28,
446,18,256,1,33,
446,18,263,4,0,
446,18,278,1,1,2
446,18,280,4,0,
446,18,289,1,50,
446,18,316,1,1,5
446,18,317,4,0,
446,18,363,1,49,
446,18,363,2,0,
446,18,374,1,41,
446,18,374,4,0,
446,18,387,1,1,1
446,18,387,1,57,
446,18,428,2,0,
446,18,495,2,0,
446,18,496,4,0,
446,18,498,1,17,
446,18,523,4,0,
446,18,526,4,0,
446,18,562,2,0,
446,18,590,4,0,
447,8,14,4,0,
447,8,44,2,0,
447,8,46,4,0,
447,8,67,2,0,
447,8,68,1,6,
447,8,70,4,0,
447,8,89,4,0,
447,8,91,4,0,
447,8,92,4,0,
447,8,97,2,0,
447,8,98,1,1,1
447,8,103,1,24,
447,8,104,4,0,
447,8,136,2,0,
447,8,156,4,0,
447,8,157,4,0,
447,8,164,4,0,
447,8,170,2,0,
447,8,179,1,19,
447,8,182,4,0,
447,8,193,1,1,2
447,8,197,2,0,
447,8,203,1,1,3
447,8,203,4,0,
447,8,207,4,0,
447,8,213,4,0,
447,8,214,4,0,
447,8,216,4,0,
447,8,218,4,0,
447,8,231,4,0,
447,8,237,4,0,
447,8,238,2,0,
447,8,240,4,0,
447,8,241,4,0,
447,8,242,2,0,
447,8,249,4,0,
447,8,263,4,0,
447,8,264,4,0,
447,8,280,4,0,
447,8,290,4,0,
447,8,299,2,0,
447,8,317,4,0,
447,8,327,2,0,
447,8,334,2,0,
447,8,339,4,0,
447,8,363,4,0,
447,8,364,1,15,
447,8,371,4,0,
447,8,374,4,0,
447,8,383,1,29,
447,8,395,1,11,
447,8,398,4,0,
447,8,409,4,0,
447,8,410,2,0,
447,8,411,4,0,
447,8,418,2,0,
447,8,421,4,0,
447,8,445,4,0,
447,9,8,3,0,
447,9,9,3,0,
447,9,14,4,0,
447,9,44,2,0,
447,9,46,4,0,
447,9,67,2,0,
447,9,68,1,6,
447,9,70,4,0,
447,9,89,4,0,
447,9,91,4,0,
447,9,92,4,0,
447,9,97,2,0,
447,9,98,1,1,1
447,9,103,1,24,
447,9,104,4,0,
447,9,129,3,0,
447,9,136,2,0,
447,9,156,4,0,
447,9,157,4,0,
447,9,164,4,0,
447,9,170,2,0,
447,9,173,3,0,
447,9,179,1,19,
447,9,182,4,0,
447,9,189,3,0,
447,9,193,1,1,2
447,9,197,2,0,
447,9,203,1,1,3
447,9,203,4,0,
447,9,207,4,0,
447,9,210,3,0,
447,9,213,4,0,
447,9,214,4,0,
447,9,216,4,0,
447,9,218,4,0,
447,9,231,4,0,
447,9,237,4,0,
447,9,238,2,0,
447,9,240,4,0,
447,9,241,4,0,
447,9,242,2,0,
447,9,249,4,0,
447,9,263,4,0,
447,9,264,4,0,
447,9,270,3,0,
447,9,280,4,0,
447,9,290,4,0,
447,9,299,2,0,
447,9,317,4,0,
447,9,327,2,0,
447,9,334,2,0,
447,9,334,3,0,
447,9,339,4,0,
447,9,363,4,0,
447,9,364,1,15,
447,9,371,4,0,
447,9,374,4,0,
447,9,383,1,29,
447,9,393,3,0,
447,9,395,1,11,
447,9,398,4,0,
447,9,409,4,0,
447,9,410,2,0,
447,9,410,3,0,
447,9,411,4,0,
447,9,418,2,0,
447,9,421,4,0,
447,9,428,3,0,
447,9,445,4,0,
447,10,8,3,0,
447,10,9,3,0,
447,10,14,4,0,
447,10,29,3,0,
447,10,44,2,0,
447,10,46,4,0,
447,10,67,2,0,
447,10,67,3,0,
447,10,68,1,6,
447,10,70,4,0,
447,10,89,4,0,
447,10,91,4,0,
447,10,92,4,0,
447,10,97,2,0,
447,10,98,1,1,1
447,10,103,1,24,
447,10,104,4,0,
447,10,129,3,0,
447,10,136,2,0,
447,10,156,4,0,
447,10,157,4,0,
447,10,164,4,0,
447,10,170,2,0,
447,10,173,3,0,
447,10,179,1,19,
447,10,182,4,0,
447,10,189,3,0,
447,10,193,1,1,2
447,10,197,2,0,
447,10,203,1,1,3
447,10,203,4,0,
447,10,207,4,0,
447,10,210,3,0,
447,10,213,4,0,
447,10,214,4,0,
447,10,216,4,0,
447,10,218,4,0,
447,10,231,4,0,
447,10,237,4,0,
447,10,238,2,0,
447,10,240,4,0,
447,10,241,4,0,
447,10,242,2,0,
447,10,249,4,0,
447,10,263,4,0,
447,10,264,4,0,
447,10,266,2,0,
447,10,270,3,0,
447,10,272,3,0,
447,10,280,4,0,
447,10,290,4,0,
447,10,299,2,0,
447,10,317,4,0,
447,10,327,2,0,
447,10,334,2,0,
447,10,334,3,0,
447,10,339,4,0,
447,10,363,4,0,
447,10,364,1,15,
447,10,371,4,0,
447,10,374,4,0,
447,10,383,1,29,
447,10,393,3,0,
447,10,395,1,11,
447,10,398,4,0,
447,10,409,4,0,
447,10,410,2,0,
447,10,410,3,0,
447,10,411,4,0,
447,10,418,2,0,
447,10,421,4,0,
447,10,428,3,0,
447,10,445,4,0,
447,11,14,4,0,
447,11,44,2,0,
447,11,46,4,0,
447,11,67,2,0,
447,11,68,1,6,
447,11,70,4,0,
447,11,89,4,0,
447,11,91,4,0,
447,11,92,4,0,
447,11,97,2,0,
447,11,98,1,1,1
447,11,103,1,24,
447,11,104,4,0,
447,11,136,2,0,
447,11,156,4,0,
447,11,157,4,0,
447,11,164,4,0,
447,11,170,2,0,
447,11,179,1,19,
447,11,182,4,0,
447,11,193,1,1,2
447,11,197,2,0,
447,11,203,1,1,3
447,11,207,4,0,
447,11,213,4,0,
447,11,216,4,0,
447,11,218,4,0,
447,11,237,4,0,
447,11,238,2,0,
447,11,240,4,0,
447,11,241,4,0,
447,11,242,2,0,
447,11,249,4,0,
447,11,263,4,0,
447,11,266,2,0,
447,11,280,4,0,
447,11,299,2,0,
447,11,317,4,0,
447,11,327,2,0,
447,11,334,2,0,
447,11,339,4,0,
447,11,364,1,15,
447,11,371,4,0,
447,11,374,4,0,
447,11,383,1,29,
447,11,395,1,11,
447,11,398,4,0,
447,11,410,2,0,
447,11,411,4,0,
447,11,417,1,47,
447,11,418,2,0,
447,11,421,4,0,
447,11,490,4,0,
447,11,496,4,0,
447,11,509,2,0,
447,11,514,4,0,
447,11,515,1,55,
447,11,523,4,0,
447,11,526,4,0,
447,14,8,3,0,
447,14,9,3,0,
447,14,14,4,0,
447,14,44,2,0,
447,14,46,4,0,
447,14,67,2,0,
447,14,67,3,0,
447,14,68,1,6,
447,14,70,4,0,
447,14,89,4,0,
447,14,91,4,0,
447,14,92,4,0,
447,14,97,2,0,
447,14,98,1,1,2
447,14,103,1,24,
447,14,104,4,0,
447,14,136,2,0,
447,14,156,4,0,
447,14,157,4,0,
447,14,164,4,0,
447,14,170,2,0,
447,14,173,3,0,
447,14,179,1,29,
447,14,182,4,0,
447,14,193,1,1,1
447,14,197,2,0,
447,14,203,1,1,3
447,14,207,4,0,
447,14,213,4,0,
447,14,214,3,0,
447,14,216,4,0,
447,14,218,4,0,
447,14,231,3,0,
447,14,237,4,0,
447,14,238,2,0,
447,14,240,4,0,
447,14,241,4,0,
447,14,242,2,0,
447,14,249,4,0,
447,14,263,4,0,
447,14,266,2,0,
447,14,270,3,0,
447,14,272,3,0,
447,14,280,4,0,
447,14,299,2,0,
447,14,317,4,0,
447,14,327,2,0,
447,14,334,2,0,
447,14,334,3,0,
447,14,339,4,0,
447,14,364,1,11,
447,14,371,4,0,
447,14,374,4,0,
447,14,383,1,19,
447,14,393,3,0,
447,14,395,1,15,
447,14,398,4,0,
447,14,409,3,0,
447,14,410,2,0,
447,14,411,4,0,
447,14,417,1,47,
447,14,418,2,0,
447,14,421,4,0,
447,14,428,3,0,
447,14,490,4,0,
447,14,496,4,0,
447,14,509,2,0,
447,14,514,4,0,
447,14,515,1,55,
447,14,523,4,0,
447,14,526,4,0,
447,14,530,3,0,
447,15,14,4,0,
447,15,44,2,0,
447,15,46,4,0,
447,15,67,2,0,
447,15,68,1,6,
447,15,70,4,0,
447,15,89,4,0,
447,15,91,4,0,
447,15,92,4,0,
447,15,97,2,0,
447,15,98,1,1,2
447,15,103,1,24,
447,15,104,4,0,
447,15,136,2,0,
447,15,156,4,0,
447,15,157,4,0,
447,15,164,4,0,
447,15,170,2,0,
447,15,179,1,29,
447,15,182,4,0,
447,15,193,1,1,1
447,15,197,2,0,
447,15,203,1,1,3
447,15,207,4,0,
447,15,213,4,0,
447,15,214,4,0,
447,15,216,4,0,
447,15,218,4,0,
447,15,237,4,0,
447,15,238,2,0,
447,15,240,4,0,
447,15,241,4,0,
447,15,242,2,0,
447,15,249,4,0,
447,15,263,4,0,
447,15,266,2,0,
447,15,280,4,0,
447,15,299,2,0,
447,15,317,4,0,
447,15,327,2,0,
447,15,334,2,0,
447,15,339,4,0,
447,15,364,1,11,
447,15,371,4,0,
447,15,374,4,0,
447,15,383,1,19,
447,15,395,1,15,
447,15,398,4,0,
447,15,410,2,0,
447,15,411,4,0,
447,15,417,1,47,
447,15,418,2,0,
447,15,421,4,0,
447,15,490,4,0,
447,15,496,4,0,
447,15,509,2,0,
447,15,514,4,0,
447,15,515,1,50,
447,15,523,4,0,
447,15,590,4,0,
447,15,612,4,0,
447,16,8,3,0,
447,16,9,3,0,
447,16,14,4,0,
447,16,44,2,0,
447,16,46,4,0,
447,16,67,2,0,
447,16,67,3,0,
447,16,68,1,6,1
447,16,70,4,0,
447,16,89,4,0,
447,16,91,4,0,
447,16,92,4,0,
447,16,97,2,0,
447,16,98,1,1,2
447,16,103,1,24,1
447,16,104,4,0,
447,16,136,2,0,
447,16,156,4,0,
447,16,157,4,0,
447,16,164,4,0,
447,16,170,2,0,
447,16,173,3,0,
447,16,179,1,29,1
447,16,182,4,0,
447,16,193,1,1,1
447,16,197,2,0,
447,16,203,1,1,3
447,16,207,4,0,
447,16,213,4,0,
447,16,214,4,0,
447,16,216,4,0,
447,16,218,4,0,
447,16,231,3,0,
447,16,237,4,0,
447,16,238,2,0,
447,16,240,4,0,
447,16,241,4,0,
447,16,242,2,0,
447,16,249,4,0,
447,16,263,4,0,
447,16,264,3,0,
447,16,266,2,0,
447,16,270,3,0,
447,16,272,3,0,
447,16,280,4,0,
447,16,290,4,0,
447,16,299,2,0,
447,16,317,4,0,
447,16,327,2,0,
447,16,334,2,0,
447,16,334,3,0,
447,16,339,4,0,
447,16,364,1,11,1
447,16,371,4,0,
447,16,374,4,0,
447,16,383,1,19,1
447,16,393,3,0,
447,16,395,1,15,1
447,16,398,4,0,
447,16,409,3,0,
447,16,410,2,0,
447,16,411,4,0,
447,16,417,1,47,1
447,16,418,2,0,
447,16,421,4,0,
447,16,428,3,0,
447,16,490,4,0,
447,16,496,4,0,
447,16,509,2,0,
447,16,514,4,0,
447,16,515,1,50,1
447,16,523,4,0,
447,16,530,3,0,
447,16,590,4,0,
447,16,612,4,0,
447,17,14,4,0,
447,17,44,2,0,
447,17,46,4,0,
447,17,67,2,0,
447,17,68,1,6,
447,17,89,4,0,
447,17,92,4,0,
447,17,97,2,0,
447,17,98,1,1,2
447,17,103,1,24,
447,17,104,4,0,
447,17,136,2,0,
447,17,156,4,0,
447,17,157,4,0,
447,17,164,4,0,
447,17,170,2,0,
447,17,179,1,29,
447,17,182,4,0,
447,17,193,1,1,1
447,17,197,2,0,
447,17,203,1,1,3
447,17,207,4,0,
447,17,213,4,0,
447,17,214,4,0,
447,17,216,4,0,
447,17,218,4,0,
447,17,237,4,0,
447,17,238,2,0,
447,17,240,4,0,
447,17,241,4,0,
447,17,242,2,0,
447,17,263,4,0,
447,17,266,2,0,
447,17,280,4,0,
447,17,299,2,0,
447,17,317,4,0,
447,17,327,2,0,
447,17,334,2,0,
447,17,339,4,0,
447,17,364,1,11,
447,17,371,4,0,
447,17,374,4,0,
447,17,383,1,19,
447,17,395,1,15,
447,17,398,4,0,
447,17,410,2,0,
447,17,411,4,0,
447,17,417,1,47,
447,17,418,2,0,
447,17,421,4,0,
447,17,490,4,0,
447,17,496,4,0,
447,17,509,2,0,
447,17,515,1,50,
447,17,523,4,0,
447,17,526,4,0,
447,17,590,4,0,
447,18,14,4,0,
447,18,44,2,0,
447,18,46,4,0,
447,18,67,2,0,
447,18,68,1,6,
447,18,89,4,0,
447,18,92,4,0,
447,18,97,2,0,
447,18,98,1,1,2
447,18,103,1,24,
447,18,104,4,0,
447,18,136,2,0,
447,18,156,4,0,
447,18,157,4,0,
447,18,164,4,0,
447,18,170,2,0,
447,18,179,1,29,
447,18,182,4,0,
447,18,193,1,1,1
447,18,197,2,0,
447,18,203,1,1,3
447,18,207,4,0,
447,18,213,4,0,
447,18,214,4,0,
447,18,216,4,0,
447,18,218,4,0,
447,18,237,4,0,
447,18,238,2,0,
447,18,240,4,0,
447,18,241,4,0,
447,18,242,2,0,
447,18,263,4,0,
447,18,266,2,0,
447,18,280,4,0,
447,18,299,2,0,
447,18,309,2,0,
447,18,317,4,0,
447,18,327,2,0,
447,18,334,2,0,
447,18,339,4,0,
447,18,364,1,11,
447,18,371,4,0,
447,18,374,4,0,
447,18,383,1,19,
447,18,395,1,15,
447,18,398,4,0,
447,18,410,2,0,
447,18,411,4,0,
447,18,417,1,47,
447,18,418,2,0,
447,18,421,4,0,
447,18,490,4,0,
447,18,496,4,0,
447,18,509,2,0,
447,18,515,1,50,
447,18,523,4,0,
447,18,526,4,0,
447,18,590,4,0,
448,8,14,1,33,
448,8,14,4,0,
448,8,46,4,0,
448,8,63,4,0,
448,8,68,1,6,
448,8,70,4,0,
448,8,89,4,0,
448,8,91,4,0,
448,8,92,4,0,
448,8,94,4,0,
448,8,98,1,1,2
448,8,104,4,0,
448,8,156,4,0,
448,8,157,4,0,
448,8,164,4,0,
448,8,182,4,0,
448,8,193,1,1,3
448,8,197,1,1,4
448,8,198,1,19,
448,8,203,4,0,
448,8,207,4,0,
448,8,213,4,0,
448,8,214,4,0,
448,8,216,4,0,
448,8,218,4,0,
448,8,231,4,0,
448,8,232,1,1,5
448,8,237,4,0,
448,8,240,4,0,
448,8,241,4,0,
448,8,245,1,51,
448,8,247,4,0,
448,8,249,4,0,
448,8,263,4,0,
448,8,264,4,0,
448,8,280,4,0,
448,8,290,4,0,
448,8,317,4,0,
448,8,319,1,24,
448,8,339,4,0,
448,8,347,4,0,
448,8,352,4,0,
448,8,363,4,0,
448,8,364,1,15,
448,8,370,1,42,
448,8,371,4,0,
448,8,374,4,0,
448,8,382,1,29,
448,8,395,1,11,
448,8,396,1,37,
448,8,398,4,0,
448,8,399,1,1,1
448,8,399,4,0,
448,8,406,1,47,
448,8,406,4,0,
448,8,409,4,0,
448,8,411,4,0,
448,8,416,4,0,
448,8,421,4,0,
448,8,430,4,0,
448,8,431,4,0,
448,8,444,4,0,
448,8,445,4,0,
448,9,8,3,0,
448,9,9,3,0,
448,9,14,1,33,
448,9,14,4,0,
448,9,46,4,0,
448,9,63,4,0,
448,9,68,1,6,
448,9,70,4,0,
448,9,89,4,0,
448,9,91,4,0,
448,9,92,4,0,
448,9,94,4,0,
448,9,98,1,1,2
448,9,104,4,0,
448,9,129,3,0,
448,9,156,4,0,
448,9,157,4,0,
448,9,164,4,0,
448,9,173,3,0,
448,9,182,4,0,
448,9,189,3,0,
448,9,193,1,1,3
448,9,197,1,1,4
448,9,198,1,19,
448,9,203,4,0,
448,9,207,4,0,
448,9,210,3,0,
448,9,213,4,0,
448,9,214,4,0,
448,9,216,4,0,
448,9,218,4,0,
448,9,231,4,0,
448,9,232,1,1,5
448,9,237,4,0,
448,9,240,4,0,
448,9,241,4,0,
448,9,245,1,51,
448,9,247,4,0,
448,9,249,4,0,
448,9,263,4,0,
448,9,264,4,0,
448,9,270,3,0,
448,9,280,4,0,
448,9,290,4,0,
448,9,317,4,0,
448,9,319,1,24,
448,9,334,3,0,
448,9,339,4,0,
448,9,347,4,0,
448,9,352,4,0,
448,9,363,4,0,
448,9,364,1,15,
448,9,370,1,42,
448,9,371,4,0,
448,9,374,4,0,
448,9,382,1,29,
448,9,393,3,0,
448,9,395,1,11,
448,9,396,1,37,
448,9,398,4,0,
448,9,399,1,1,1
448,9,399,4,0,
448,9,406,1,47,
448,9,406,4,0,
448,9,409,4,0,
448,9,410,3,0,
448,9,411,4,0,
448,9,416,4,0,
448,9,421,4,0,
448,9,428,3,0,
448,9,430,4,0,
448,9,431,4,0,
448,9,444,4,0,
448,9,445,4,0,
448,10,8,3,0,
448,10,9,3,0,
448,10,14,1,33,
448,10,14,4,0,
448,10,29,3,0,
448,10,46,4,0,
448,10,63,4,0,
448,10,67,3,0,
448,10,68,1,6,
448,10,70,4,0,
448,10,89,4,0,
448,10,91,4,0,
448,10,92,4,0,
448,10,94,4,0,
448,10,98,1,1,2
448,10,104,4,0,
448,10,129,3,0,
448,10,156,4,0,
448,10,157,4,0,
448,10,164,4,0,
448,10,173,3,0,
448,10,182,4,0,
448,10,189,3,0,
448,10,193,1,1,3
448,10,197,1,1,4
448,10,198,1,19,
448,10,203,4,0,
448,10,207,4,0,
448,10,210,3,0,
448,10,213,4,0,
448,10,214,4,0,
448,10,216,4,0,
448,10,218,4,0,
448,10,231,4,0,
448,10,232,1,1,5
448,10,237,4,0,
448,10,240,4,0,
448,10,241,4,0,
448,10,245,1,51,
448,10,247,4,0,
448,10,249,4,0,
448,10,263,4,0,
448,10,264,4,0,
448,10,270,3,0,
448,10,272,3,0,
448,10,280,4,0,
448,10,290,4,0,
448,10,317,4,0,
448,10,319,1,24,
448,10,334,3,0,
448,10,339,4,0,
448,10,347,4,0,
448,10,352,4,0,
448,10,363,4,0,
448,10,364,1,15,
448,10,370,1,42,
448,10,371,4,0,
448,10,374,4,0,
448,10,382,1,29,
448,10,393,3,0,
448,10,395,1,11,
448,10,396,1,37,
448,10,398,4,0,
448,10,399,1,1,1
448,10,399,4,0,
448,10,406,1,47,
448,10,406,4,0,
448,10,409,4,0,
448,10,410,3,0,
448,10,411,4,0,
448,10,416,4,0,
448,10,421,4,0,
448,10,428,3,0,
448,10,430,4,0,
448,10,431,4,0,
448,10,444,4,0,
448,10,445,4,0,
448,11,14,1,37,
448,11,14,4,0,
448,11,46,4,0,
448,11,63,4,0,
448,11,68,1,6,
448,11,70,4,0,
448,11,89,4,0,
448,11,91,4,0,
448,11,92,4,0,
448,11,94,4,0,
448,11,98,1,1,2
448,11,104,4,0,
448,11,156,4,0,
448,11,157,4,0,
448,11,164,4,0,
448,11,182,4,0,
448,11,193,1,1,3
448,11,197,1,1,4
448,11,198,1,19,
448,11,207,4,0,
448,11,213,4,0,
448,11,216,4,0,
448,11,218,4,0,
448,11,232,1,1,5
448,11,237,4,0,
448,11,240,4,0,
448,11,241,4,0,
448,11,245,1,65,
448,11,247,4,0,
448,11,249,4,0,
448,11,263,4,0,
448,11,280,4,0,
448,11,317,4,0,
448,11,319,1,24,
448,11,339,4,0,
448,11,347,1,47,
448,11,347,4,0,
448,11,364,1,15,
448,11,370,1,55,
448,11,371,4,0,
448,11,374,4,0,
448,11,382,1,29,
448,11,395,1,11,
448,11,396,1,51,
448,11,398,4,0,
448,11,399,1,1,1
448,11,406,1,60,
448,11,411,4,0,
448,11,416,4,0,
448,11,421,4,0,
448,11,430,4,0,
448,11,444,4,0,
448,11,468,4,0,
448,11,490,4,0,
448,11,496,4,0,
448,11,501,1,33,
448,11,505,1,42,
448,11,514,4,0,
448,11,523,4,0,
448,11,526,4,0,
448,14,8,3,0,
448,14,9,3,0,
448,14,14,1,37,
448,14,14,4,0,
448,14,46,4,0,
448,14,63,4,0,
448,14,67,3,0,
448,14,68,1,6,
448,14,70,4,0,
448,14,89,4,0,
448,14,91,4,0,
448,14,92,4,0,
448,14,94,4,0,
448,14,98,1,1,3
448,14,104,4,0,
448,14,156,4,0,
448,14,157,4,0,
448,14,164,4,0,
448,14,173,3,0,
448,14,182,4,0,
448,14,193,1,1,2
448,14,197,1,1,4
448,14,198,1,29,
448,14,207,4,0,
448,14,213,4,0,
448,14,214,3,0,
448,14,216,4,0,
448,14,218,4,0,
448,14,231,3,0,
448,14,232,1,1,5
448,14,237,4,0,
448,14,240,4,0,
448,14,241,4,0,
448,14,245,1,65,
448,14,247,4,0,
448,14,249,4,0,
448,14,263,4,0,
448,14,270,3,0,
448,14,272,3,0,
448,14,280,4,0,
448,14,317,4,0,
448,14,319,1,24,
448,14,334,3,0,
448,14,339,4,0,
448,14,347,1,47,
448,14,347,4,0,
448,14,364,1,11,
448,14,370,1,55,
448,14,371,4,0,
448,14,374,4,0,
448,14,382,1,19,
448,14,393,3,0,
448,14,395,1,15,
448,14,396,1,51,
448,14,398,4,0,
448,14,399,1,1,1
448,14,399,3,0,
448,14,406,1,60,
448,14,406,3,0,
448,14,409,3,0,
448,14,411,4,0,
448,14,416,4,0,
448,14,421,4,0,
448,14,428,3,0,
448,14,430,4,0,
448,14,444,4,0,
448,14,468,4,0,
448,14,490,4,0,
448,14,496,4,0,
448,14,501,1,33,
448,14,505,1,42,
448,14,514,4,0,
448,14,523,4,0,
448,14,526,4,0,
448,14,530,3,0,
448,15,14,1,19,
448,15,14,4,0,
448,15,46,4,0,
448,15,63,4,0,
448,15,68,1,6,
448,15,70,4,0,
448,15,89,4,0,
448,15,91,4,0,
448,15,92,4,0,
448,15,94,4,0,
448,15,98,1,1,6
448,15,104,4,0,
448,15,156,4,0,
448,15,157,4,0,
448,15,164,4,0,
448,15,182,4,0,
448,15,193,1,1,5
448,15,197,1,1,7
448,15,198,1,29,
448,15,207,4,0,
448,15,213,4,0,
448,15,214,4,0,
448,15,216,4,0,
448,15,218,4,0,
448,15,232,1,1,8
448,15,237,4,0,
448,15,240,4,0,
448,15,241,4,0,
448,15,245,1,1,1
448,15,245,1,65,
448,15,247,4,0,
448,15,249,4,0,
448,15,263,4,0,
448,15,280,4,0,
448,15,317,4,0,
448,15,319,1,24,
448,15,339,4,0,
448,15,347,1,47,
448,15,347,4,0,
448,15,364,1,11,
448,15,370,1,1,3
448,15,370,1,55,
448,15,371,4,0,
448,15,374,4,0,
448,15,382,1,37,
448,15,396,1,1,4
448,15,396,1,42,
448,15,398,4,0,
448,15,399,4,0,
448,15,406,1,1,2
448,15,406,1,60,
448,15,411,4,0,
448,15,416,4,0,
448,15,421,4,0,
448,15,430,4,0,
448,15,444,4,0,
448,15,468,4,0,
448,15,490,4,0,
448,15,496,4,0,
448,15,501,1,33,
448,15,505,1,51,
448,15,514,4,0,
448,15,523,4,0,
448,15,590,4,0,
448,15,612,1,15,
448,15,612,4,0,
448,16,8,3,0,
448,16,9,3,0,
448,16,14,1,19,1
448,16,14,4,0,
448,16,46,4,0,
448,16,63,4,0,
448,16,67,3,0,
448,16,68,1,6,1
448,16,70,4,0,
448,16,89,4,0,
448,16,91,4,0,
448,16,92,4,0,
448,16,94,4,0,
448,16,98,1,1,6
448,16,104,4,0,
448,16,156,4,0,
448,16,157,4,0,
448,16,164,4,0,
448,16,173,3,0,
448,16,182,4,0,
448,16,193,1,1,5
448,16,197,1,1,7
448,16,198,1,29,1
448,16,207,4,0,
448,16,213,4,0,
448,16,214,4,0,
448,16,216,4,0,
448,16,218,4,0,
448,16,231,3,0,
448,16,232,1,1,8
448,16,237,4,0,
448,16,240,4,0,
448,16,241,4,0,
448,16,245,1,1,1
448,16,245,1,65,1
448,16,247,4,0,
448,16,249,4,0,
448,16,263,4,0,
448,16,264,3,0,
448,16,270,3,0,
448,16,272,3,0,
448,16,280,4,0,
448,16,290,4,0,
448,16,317,4,0,
448,16,319,1,24,1
448,16,334,3,0,
448,16,339,4,0,
448,16,347,1,47,1
448,16,347,4,0,
448,16,352,3,0,
448,16,364,1,11,1
448,16,370,1,1,3
448,16,370,1,55,1
448,16,371,4,0,
448,16,374,4,0,
448,16,382,1,37,1
448,16,393,3,0,
448,16,396,1,1,4
448,16,396,1,42,1
448,16,398,4,0,
448,16,399,4,0,
448,16,406,1,1,2
448,16,406,1,60,1
448,16,406,3,0,
448,16,409,3,0,
448,16,411,4,0,
448,16,416,4,0,
448,16,421,4,0,
448,16,428,3,0,
448,16,430,4,0,
448,16,444,4,0,
448,16,468,4,0,
448,16,490,4,0,
448,16,496,4,0,
448,16,501,1,33,1
448,16,505,1,51,1
448,16,514,4,0,
448,16,523,4,0,
448,16,530,3,0,
448,16,590,4,0,
448,16,612,1,15,1
448,16,612,4,0,
448,17,14,1,19,
448,17,14,4,0,
448,17,46,4,0,
448,17,63,4,0,
448,17,68,1,6,
448,17,89,4,0,
448,17,92,4,0,
448,17,94,4,0,
448,17,98,1,1,4
448,17,104,4,0,
448,17,156,4,0,
448,17,157,4,0,
448,17,164,4,0,
448,17,182,4,0,
448,17,193,1,1,3
448,17,197,1,1,5
448,17,198,1,29,
448,17,207,4,0,
448,17,213,4,0,
448,17,214,4,0,
448,17,216,4,0,
448,17,218,4,0,
448,17,232,1,1,6
448,17,237,4,0,
448,17,240,4,0,
448,17,241,4,0,
448,17,245,1,65,
448,17,247,4,0,
448,17,263,4,0,
448,17,280,4,0,
448,17,317,4,0,
448,17,319,1,24,
448,17,339,4,0,
448,17,347,1,47,
448,17,347,4,0,
448,17,364,1,11,
448,17,370,1,55,
448,17,371,4,0,
448,17,374,4,0,
448,17,382,1,37,
448,17,396,1,0,
448,17,396,1,1,1
448,17,398,4,0,
448,17,399,4,0,
448,17,406,1,60,
448,17,411,4,0,
448,17,416,4,0,
448,17,421,4,0,
448,17,430,4,0,
448,17,444,4,0,
448,17,490,4,0,
448,17,496,4,0,
448,17,501,1,33,
448,17,505,1,51,
448,17,523,4,0,
448,17,526,1,42,
448,17,526,4,0,
448,17,590,4,0,
448,17,612,1,15,
448,17,673,1,1,2
448,18,14,1,19,
448,18,14,4,0,
448,18,46,4,0,
448,18,63,4,0,
448,18,68,1,6,
448,18,89,4,0,
448,18,92,4,0,
448,18,94,4,0,
448,18,98,1,1,4
448,18,104,4,0,
448,18,156,4,0,
448,18,157,4,0,
448,18,164,4,0,
448,18,182,4,0,
448,18,193,1,1,3
448,18,197,1,1,5
448,18,198,1,29,
448,18,207,4,0,
448,18,213,4,0,
448,18,214,4,0,
448,18,216,4,0,
448,18,218,4,0,
448,18,232,1,1,6
448,18,237,4,0,
448,18,240,4,0,
448,18,241,4,0,
448,18,245,1,65,
448,18,247,4,0,
448,18,263,4,0,
448,18,280,4,0,
448,18,317,4,0,
448,18,319,1,24,
448,18,339,4,0,
448,18,347,1,47,
448,18,347,4,0,
448,18,364,1,11,
448,18,370,1,55,
448,18,371,4,0,
448,18,374,4,0,
448,18,382,1,37,
448,18,396,1,0,
448,18,396,1,1,1
448,18,398,4,0,
448,18,399,4,0,
448,18,406,1,60,
448,18,411,4,0,
448,18,416,4,0,
448,18,421,4,0,
448,18,430,4,0,
448,18,444,4,0,
448,18,490,4,0,
448,18,496,4,0,
448,18,501,1,33,
448,18,505,1,51,
448,18,523,4,0,
448,18,526,1,42,
448,18,526,4,0,
448,18,590,4,0,
448,18,612,1,15,
448,18,673,1,1,2
449,8,28,1,1,2
449,8,33,1,1,1
449,8,34,2,0,
449,8,36,1,19,
449,8,38,1,44,
449,8,44,1,7,
449,8,46,4,0,
449,8,70,4,0,
449,8,89,1,37,
449,8,89,4,0,
449,8,90,1,50,
449,8,92,4,0,
449,8,104,4,0,
449,8,156,4,0,
449,8,157,4,0,
449,8,164,4,0,
449,8,174,2,0,
449,8,182,4,0,
449,8,201,4,0,
449,8,203,4,0,
449,8,207,4,0,
449,8,213,4,0,
449,8,214,4,0,
449,8,216,4,0,
449,8,218,4,0,
449,8,231,4,0,
449,8,237,4,0,
449,8,241,4,0,
449,8,242,1,31,
449,8,249,4,0,
449,8,254,2,0,
449,8,255,2,0,
449,8,256,2,0,
449,8,263,4,0,
449,8,281,1,13,
449,8,290,4,0,
449,8,303,2,0,
449,8,317,4,0,
449,8,328,1,25,
449,8,328,2,0,
449,8,352,4,0,
449,8,363,4,0,
449,8,445,4,0,
449,8,446,4,0,
449,9,28,1,1,2
449,9,33,1,1,1
449,9,34,2,0,
449,9,36,1,19,
449,9,38,1,44,
449,9,44,1,7,
449,9,46,4,0,
449,9,70,4,0,
449,9,89,1,37,
449,9,89,4,0,
449,9,90,1,50,
449,9,92,4,0,
449,9,104,4,0,
449,9,156,4,0,
449,9,157,4,0,
449,9,164,4,0,
449,9,173,3,0,
449,9,174,2,0,
449,9,182,4,0,
449,9,189,3,0,
449,9,201,4,0,
449,9,203,4,0,
449,9,207,4,0,
449,9,213,4,0,
449,9,214,4,0,
449,9,216,4,0,
449,9,218,4,0,
449,9,231,4,0,
449,9,237,4,0,
449,9,241,4,0,
449,9,242,1,31,
449,9,249,4,0,
449,9,254,2,0,
449,9,255,2,0,
449,9,256,2,0,
449,9,263,4,0,
449,9,276,3,0,
449,9,281,1,13,
449,9,290,4,0,
449,9,303,2,0,
449,9,317,4,0,
449,9,328,1,25,
449,9,328,2,0,
449,9,352,4,0,
449,9,363,4,0,
449,9,414,3,0,
449,9,445,4,0,
449,9,446,4,0,
449,10,28,1,1,2
449,10,29,3,0,
449,10,33,1,1,1
449,10,34,2,0,
449,10,36,1,19,1
449,10,38,1,44,
449,10,44,1,7,
449,10,46,4,0,
449,10,70,4,0,
449,10,89,1,37,
449,10,89,4,0,
449,10,90,1,50,
449,10,91,1,19,2
449,10,91,4,0,
449,10,92,4,0,
449,10,104,4,0,
449,10,156,4,0,
449,10,157,4,0,
449,10,164,4,0,
449,10,173,3,0,
449,10,174,2,0,
449,10,182,4,0,
449,10,189,3,0,
449,10,201,4,0,
449,10,203,4,0,
449,10,207,4,0,
449,10,213,4,0,
449,10,214,4,0,
449,10,216,4,0,
449,10,218,4,0,
449,10,231,4,0,
449,10,237,4,0,
449,10,241,4,0,
449,10,242,1,31,
449,10,249,4,0,
449,10,254,2,0,
449,10,255,2,0,
449,10,256,2,0,
449,10,263,4,0,
449,10,276,3,0,
449,10,279,2,0,
449,10,281,1,13,
449,10,290,4,0,
449,10,303,2,0,
449,10,317,4,0,
449,10,328,1,25,
449,10,328,2,0,
449,10,352,4,0,
449,10,363,4,0,
449,10,414,3,0,
449,10,445,4,0,
449,10,446,4,0,
449,11,18,2,0,
449,11,28,1,1,2
449,11,33,1,1,1
449,11,34,2,0,
449,11,36,1,19,1
449,11,38,1,44,
449,11,44,1,7,
449,11,46,4,0,
449,11,70,4,0,
449,11,89,1,37,
449,11,89,4,0,
449,11,90,1,50,
449,11,91,1,19,2
449,11,91,4,0,
449,11,92,4,0,
449,11,104,4,0,
449,11,156,4,0,
449,11,157,4,0,
449,11,164,4,0,
449,11,174,2,0,
449,11,182,4,0,
449,11,201,4,0,
449,11,207,4,0,
449,11,213,4,0,
449,11,214,2,0,
449,11,216,4,0,
449,11,218,4,0,
449,11,237,4,0,
449,11,241,4,0,
449,11,242,1,31,
449,11,249,4,0,
449,11,254,2,0,
449,11,255,2,0,
449,11,256,2,0,
449,11,263,4,0,
449,11,279,2,0,
449,11,281,1,13,
449,11,303,2,0,
449,11,317,4,0,
449,11,328,1,25,
449,11,328,2,0,
449,11,496,4,0,
449,11,523,4,0,
449,14,18,2,0,
449,14,28,1,1,2
449,14,33,1,1,1
449,14,34,2,0,
449,14,36,1,19,1
449,14,38,1,44,
449,14,44,1,7,
449,14,46,4,0,
449,14,70,4,0,
449,14,89,1,37,
449,14,89,4,0,
449,14,90,1,50,
449,14,91,1,19,2
449,14,91,4,0,
449,14,92,4,0,
449,14,104,4,0,
449,14,156,4,0,
449,14,157,4,0,
449,14,164,4,0,
449,14,173,3,0,
449,14,174,2,0,
449,14,182,4,0,
449,14,201,4,0,
449,14,207,4,0,
449,14,213,4,0,
449,14,214,2,0,
449,14,214,3,0,
449,14,216,4,0,
449,14,218,4,0,
449,14,231,3,0,
449,14,237,4,0,
449,14,241,4,0,
449,14,242,1,31,
449,14,249,4,0,
449,14,254,2,0,
449,14,255,2,0,
449,14,256,2,0,
449,14,263,4,0,
449,14,276,3,0,
449,14,279,2,0,
449,14,281,1,13,
449,14,303,2,0,
449,14,317,4,0,
449,14,328,1,25,
449,14,328,2,0,
449,14,414,3,0,
449,14,446,3,0,
449,14,496,4,0,
449,14,523,4,0,
449,15,18,2,0,
449,15,28,1,1,2
449,15,33,1,1,1
449,15,34,2,0,
449,15,36,1,19,1
449,15,38,1,44,
449,15,44,1,7,
449,15,46,4,0,
449,15,70,4,0,
449,15,89,1,37,
449,15,89,4,0,
449,15,90,1,50,
449,15,91,1,19,2
449,15,91,4,0,
449,15,92,4,0,
449,15,104,4,0,
449,15,156,4,0,
449,15,157,4,0,
449,15,164,4,0,
449,15,174,2,0,
449,15,182,4,0,
449,15,201,4,0,
449,15,207,4,0,
449,15,213,4,0,
449,15,214,2,0,
449,15,214,4,0,
449,15,216,4,0,
449,15,218,4,0,
449,15,237,4,0,
449,15,241,4,0,
449,15,242,1,31,
449,15,249,4,0,
449,15,254,2,0,
449,15,255,2,0,
449,15,256,2,0,
449,15,263,4,0,
449,15,279,2,0,
449,15,281,1,13,
449,15,303,2,0,
449,15,317,4,0,
449,15,328,1,25,
449,15,328,2,0,
449,15,496,4,0,
449,15,523,4,0,
449,15,590,4,0,
449,16,18,2,0,
449,16,28,1,1,2
449,16,33,1,1,1
449,16,34,2,0,
449,16,36,1,19,1
449,16,38,1,44,1
449,16,44,1,7,1
449,16,46,4,0,
449,16,70,4,0,
449,16,89,1,37,1
449,16,89,4,0,
449,16,90,1,50,1
449,16,91,1,19,2
449,16,91,4,0,
449,16,92,4,0,
449,16,104,4,0,
449,16,156,4,0,
449,16,157,4,0,
449,16,164,4,0,
449,16,173,3,0,
449,16,174,2,0,
449,16,182,4,0,
449,16,201,4,0,
449,16,207,4,0,
449,16,213,4,0,
449,16,214,2,0,
449,16,214,4,0,
449,16,216,4,0,
449,16,218,4,0,
449,16,231,3,0,
449,16,237,4,0,
449,16,241,4,0,
449,16,242,1,31,1
449,16,249,4,0,
449,16,254,2,0,
449,16,255,2,0,
449,16,256,2,0,
449,16,263,4,0,
449,16,276,3,0,
449,16,279,2,0,
449,16,281,1,13,1
449,16,290,4,0,
449,16,303,2,0,
449,16,317,4,0,
449,16,328,1,25,1
449,16,328,2,0,
449,16,352,3,0,
449,16,414,3,0,
449,16,446,3,0,
449,16,496,4,0,
449,16,523,4,0,
449,16,590,4,0,
449,17,18,2,0,
449,17,28,1,1,2
449,17,33,1,1,1
449,17,34,2,0,
449,17,36,1,19,1
449,17,38,1,44,
449,17,44,1,7,
449,17,46,4,0,
449,17,89,1,37,
449,17,89,4,0,
449,17,90,1,50,
449,17,91,1,19,2
449,17,92,4,0,
449,17,104,4,0,
449,17,156,4,0,
449,17,157,4,0,
449,17,164,4,0,
449,17,174,2,0,
449,17,182,4,0,
449,17,201,4,0,
449,17,207,4,0,
449,17,213,4,0,
449,17,214,2,0,
449,17,214,4,0,
449,17,216,4,0,
449,17,218,4,0,
449,17,237,4,0,
449,17,241,4,0,
449,17,242,1,31,
449,17,254,2,0,
449,17,255,2,0,
449,17,256,2,0,
449,17,263,4,0,
449,17,279,2,0,
449,17,281,1,13,
449,17,303,2,0,
449,17,317,4,0,
449,17,328,1,25,
449,17,328,2,0,
449,17,496,4,0,
449,17,523,4,0,
449,17,590,4,0,
449,18,18,2,0,
449,18,28,1,1,2
449,18,33,1,1,1
449,18,34,2,0,
449,18,36,1,19,1
449,18,38,1,44,
449,18,44,1,7,
449,18,46,4,0,
449,18,89,1,37,
449,18,89,4,0,
449,18,90,1,50,
449,18,91,1,19,2
449,18,92,4,0,
449,18,104,4,0,
449,18,156,4,0,
449,18,157,4,0,
449,18,164,4,0,
449,18,174,2,0,
449,18,182,4,0,
449,18,201,4,0,
449,18,207,4,0,
449,18,213,4,0,
449,18,214,2,0,
449,18,214,4,0,
449,18,216,4,0,
449,18,218,4,0,
449,18,237,4,0,
449,18,241,4,0,
449,18,242,1,31,
449,18,254,2,0,
449,18,255,2,0,
449,18,256,2,0,
449,18,263,4,0,
449,18,279,2,0,
449,18,281,1,13,
449,18,303,2,0,
449,18,317,4,0,
449,18,328,1,25,
449,18,328,2,0,
449,18,496,4,0,
449,18,523,4,0,
449,18,590,4,0,
450,8,28,1,1,5
450,8,33,1,1,4
450,8,36,1,19,
450,8,38,1,50,
450,8,44,1,1,6
450,8,44,1,7,
450,8,46,4,0,
450,8,63,4,0,
450,8,70,4,0,
450,8,89,1,40,
450,8,89,4,0,
450,8,90,1,60,
450,8,92,4,0,
450,8,104,4,0,
450,8,156,4,0,
450,8,157,4,0,
450,8,164,4,0,
450,8,182,4,0,
450,8,201,4,0,
450,8,203,4,0,
450,8,207,4,0,
450,8,213,4,0,
450,8,214,4,0,
450,8,216,4,0,
450,8,218,4,0,
450,8,231,4,0,
450,8,237,4,0,
450,8,241,4,0,
450,8,242,1,31,
450,8,249,4,0,
450,8,263,4,0,
450,8,281,1,1,7
450,8,281,1,13,
450,8,290,4,0,
450,8,317,4,0,
450,8,328,1,25,
450,8,352,4,0,
450,8,363,4,0,
450,8,416,4,0,
450,8,422,1,1,3
450,8,423,1,1,1
450,8,424,1,1,2
450,8,444,4,0,
450,8,445,4,0,
450,8,446,4,0,
450,9,28,1,1,5
450,9,33,1,1,4
450,9,36,1,19,
450,9,38,1,50,
450,9,44,1,1,6
450,9,44,1,7,
450,9,46,4,0,
450,9,63,4,0,
450,9,70,4,0,
450,9,89,1,40,
450,9,89,4,0,
450,9,90,1,60,
450,9,92,4,0,
450,9,104,4,0,
450,9,156,4,0,
450,9,157,4,0,
450,9,164,4,0,
450,9,173,3,0,
450,9,182,4,0,
450,9,189,3,0,
450,9,201,4,0,
450,9,203,4,0,
450,9,207,4,0,
450,9,213,4,0,
450,9,214,4,0,
450,9,216,4,0,
450,9,218,4,0,
450,9,231,4,0,
450,9,237,4,0,
450,9,241,4,0,
450,9,242,1,31,
450,9,249,4,0,
450,9,263,4,0,
450,9,276,3,0,
450,9,281,1,1,7
450,9,281,1,13,
450,9,290,4,0,
450,9,317,4,0,
450,9,328,1,25,
450,9,352,4,0,
450,9,363,4,0,
450,9,414,3,0,
450,9,416,4,0,
450,9,422,1,1,3
450,9,423,1,1,1
450,9,424,1,1,2
450,9,442,3,0,
450,9,444,4,0,
450,9,445,4,0,
450,9,446,4,0,
450,10,28,1,1,5
450,10,29,3,0,
450,10,33,1,1,4
450,10,36,1,19,1
450,10,38,1,50,
450,10,44,1,1,6
450,10,44,1,7,
450,10,46,4,0,
450,10,63,4,0,
450,10,70,4,0,
450,10,89,1,40,
450,10,89,4,0,
450,10,90,1,60,
450,10,91,1,19,2
450,10,91,4,0,
450,10,92,4,0,
450,10,104,4,0,
450,10,156,4,0,
450,10,157,4,0,
450,10,164,4,0,
450,10,173,3,0,
450,10,182,4,0,
450,10,189,3,0,
450,10,201,4,0,
450,10,203,4,0,
450,10,207,4,0,
450,10,213,4,0,
450,10,214,4,0,
450,10,216,4,0,
450,10,218,4,0,
450,10,231,4,0,
450,10,237,4,0,
450,10,241,4,0,
450,10,242,1,31,
450,10,249,4,0,
450,10,263,4,0,
450,10,276,3,0,
450,10,281,1,1,7
450,10,281,1,13,
450,10,290,4,0,
450,10,317,4,0,
450,10,328,1,25,
450,10,352,4,0,
450,10,363,4,0,
450,10,414,3,0,
450,10,416,4,0,
450,10,422,1,1,3
450,10,423,1,1,1
450,10,424,1,1,2
450,10,442,3,0,
450,10,444,4,0,
450,10,445,4,0,
450,10,446,4,0,
450,11,28,1,1,5
450,11,33,1,1,4
450,11,36,1,19,1
450,11,38,1,50,
450,11,44,1,1,6
450,11,44,1,7,
450,11,46,4,0,
450,11,63,4,0,
450,11,70,4,0,
450,11,89,1,40,
450,11,89,4,0,
450,11,90,1,60,
450,11,91,1,19,2
450,11,91,4,0,
450,11,92,4,0,
450,11,104,4,0,
450,11,156,4,0,
450,11,157,4,0,
450,11,164,4,0,
450,11,182,4,0,
450,11,201,4,0,
450,11,207,4,0,
450,11,213,4,0,
450,11,216,4,0,
450,11,218,4,0,
450,11,237,4,0,
450,11,241,4,0,
450,11,242,1,31,
450,11,249,4,0,
450,11,263,4,0,
450,11,281,1,1,7
450,11,281,1,13,
450,11,317,4,0,
450,11,328,1,25,
450,11,416,4,0,
450,11,422,1,1,3
450,11,423,1,1,1
450,11,424,1,1,2
450,11,444,4,0,
450,11,496,4,0,
450,11,523,4,0,
450,14,28,1,1,5
450,14,33,1,1,4
450,14,36,1,19,1
450,14,38,1,50,
450,14,44,1,1,6
450,14,44,1,7,
450,14,46,4,0,
450,14,63,4,0,
450,14,70,4,0,
450,14,89,1,40,
450,14,89,4,0,
450,14,90,1,60,
450,14,91,1,19,2
450,14,91,4,0,
450,14,92,4,0,
450,14,104,4,0,
450,14,156,4,0,
450,14,157,4,0,
450,14,164,4,0,
450,14,173,3,0,
450,14,182,4,0,
450,14,201,4,0,
450,14,207,4,0,
450,14,213,4,0,
450,14,214,3,0,
450,14,216,4,0,
450,14,218,4,0,
450,14,231,3,0,
450,14,237,4,0,
450,14,241,4,0,
450,14,242,1,31,
450,14,249,4,0,
450,14,263,4,0,
450,14,276,3,0,
450,14,281,1,1,7
450,14,281,1,13,
450,14,317,4,0,
450,14,328,1,25,
450,14,414,3,0,
450,14,416,4,0,
450,14,422,1,1,3
450,14,423,1,1,1
450,14,424,1,1,2
450,14,442,3,0,
450,14,444,4,0,
450,14,446,3,0,
450,14,496,4,0,
450,14,523,4,0,
450,15,28,1,1,5
450,15,33,1,1,4
450,15,36,1,19,1
450,15,38,1,50,
450,15,44,1,1,6
450,15,44,1,7,
450,15,46,4,0,
450,15,63,4,0,
450,15,70,4,0,
450,15,89,1,40,
450,15,89,4,0,
450,15,90,1,60,
450,15,91,1,19,2
450,15,91,4,0,
450,15,92,4,0,
450,15,104,4,0,
450,15,156,4,0,
450,15,157,4,0,
450,15,164,4,0,
450,15,182,4,0,
450,15,201,4,0,
450,15,207,4,0,
450,15,213,4,0,
450,15,214,4,0,
450,15,216,4,0,
450,15,218,4,0,
450,15,237,4,0,
450,15,241,4,0,
450,15,242,1,31,
450,15,249,4,0,
450,15,263,4,0,
450,15,281,1,1,7
450,15,281,1,13,
450,15,317,4,0,
450,15,328,1,25,
450,15,416,4,0,
450,15,422,1,1,3
450,15,423,1,1,1
450,15,424,1,1,2
450,15,444,4,0,
450,15,496,4,0,
450,15,523,4,0,
450,15,590,4,0,
450,16,28,1,1,5
450,16,33,1,1,4
450,16,36,1,19,1
450,16,38,1,50,1
450,16,44,1,1,6
450,16,44,1,7,1
450,16,46,4,0,
450,16,63,4,0,
450,16,70,4,0,
450,16,89,1,40,1
450,16,89,4,0,
450,16,90,1,60,1
450,16,91,1,19,2
450,16,91,4,0,
450,16,92,4,0,
450,16,104,4,0,
450,16,156,4,0,
450,16,157,4,0,
450,16,164,4,0,
450,16,173,3,0,
450,16,182,4,0,
450,16,201,4,0,
450,16,207,4,0,
450,16,213,4,0,
450,16,214,4,0,
450,16,216,4,0,
450,16,218,4,0,
450,16,231,3,0,
450,16,237,4,0,
450,16,241,4,0,
450,16,242,1,31,1
450,16,249,4,0,
450,16,263,4,0,
450,16,276,3,0,
450,16,281,1,1,7
450,16,281,1,13,1
450,16,290,4,0,
450,16,317,4,0,
450,16,328,1,25,1
450,16,352,3,0,
450,16,414,3,0,
450,16,416,4,0,
450,16,422,1,1,3
450,16,423,1,1,1
450,16,424,1,1,2
450,16,442,3,0,
450,16,444,4,0,
450,16,446,3,0,
450,16,496,4,0,
450,16,523,4,0,
450,16,590,4,0,
450,17,28,1,1,5
450,17,33,1,1,4
450,17,36,1,19,1
450,17,38,1,50,
450,17,44,1,1,6
450,17,44,1,7,
450,17,46,4,0,
450,17,63,4,0,
450,17,89,1,40,
450,17,89,4,0,
450,17,90,1,60,
450,17,91,1,19,2
450,17,92,4,0,
450,17,104,4,0,
450,17,156,4,0,
450,17,157,4,0,
450,17,164,4,0,
450,17,182,4,0,
450,17,201,4,0,
450,17,207,4,0,
450,17,213,4,0,
450,17,214,4,0,
450,17,216,4,0,
450,17,218,4,0,
450,17,237,4,0,
450,17,241,4,0,
450,17,242,1,31,
450,17,263,4,0,
450,17,281,1,1,7
450,17,281,1,13,
450,17,317,4,0,
450,17,328,1,25,
450,17,416,4,0,
450,17,422,1,1,3
450,17,423,1,1,1
450,17,424,1,1,2
450,17,444,4,0,
450,17,496,4,0,
450,17,523,4,0,
450,17,590,4,0,
450,18,28,1,1,5
450,18,33,1,1,4
450,18,36,1,19,1
450,18,38,1,50,
450,18,44,1,1,6
450,18,44,1,7,
450,18,46,4,0,
450,18,63,4,0,
450,18,89,1,40,
450,18,89,4,0,
450,18,90,1,60,
450,18,91,1,19,2
450,18,92,4,0,
450,18,104,4,0,
450,18,156,4,0,
450,18,157,4,0,
450,18,164,4,0,
450,18,182,4,0,
450,18,201,4,0,
450,18,207,4,0,
450,18,213,4,0,
450,18,214,4,0,
450,18,216,4,0,
450,18,218,4,0,
450,18,237,4,0,
450,18,241,4,0,
450,18,242,1,31,
450,18,263,4,0,
450,18,281,1,1,7
450,18,281,1,13,
450,18,317,4,0,
450,18,328,1,25,
450,18,416,4,0,
450,18,422,1,1,3
450,18,423,1,1,1
450,18,424,1,1,2
450,18,444,4,0,
450,18,496,4,0,
450,18,523,4,0,
450,18,590,4,0,
451,8,14,4,0,
451,8,15,4,0,
451,8,18,2,0,
451,8,28,2,0,
451,8,40,1,1,2
451,8,42,1,12,
451,8,43,1,6,
451,8,44,1,1,1
451,8,70,4,0,
451,8,91,4,0,
451,8,92,4,0,
451,8,97,2,0,
451,8,103,2,0,
451,8,104,4,0,
451,8,109,2,0,
451,8,148,4,0,
451,8,156,4,0,
451,8,163,2,0,
451,8,164,4,0,
451,8,168,4,0,
451,8,182,4,0,
451,8,184,1,28,
451,8,185,2,0,
451,8,188,4,0,
451,8,203,4,0,
451,8,206,4,0,
451,8,207,4,0,
451,8,213,4,0,
451,8,214,4,0,
451,8,216,4,0,
451,8,218,4,0,
451,8,228,2,0,
451,8,231,4,0,
451,8,237,4,0,
451,8,240,4,0,
451,8,241,4,0,
451,8,242,1,45,
451,8,247,4,0,
451,8,249,4,0,
451,8,259,4,0,
451,8,263,4,0,
451,8,269,4,0,
451,8,280,4,0,
451,8,282,1,23,
451,8,290,4,0,
451,8,305,1,39,
451,8,317,4,0,
451,8,332,4,0,
451,8,363,4,0,
451,8,367,1,17,
451,8,371,4,0,
451,8,374,4,0,
451,8,390,1,34,
451,8,398,4,0,
451,8,399,4,0,
451,8,400,2,0,
451,8,404,4,0,
451,8,440,1,50,
451,8,445,4,0,
451,9,14,4,0,
451,9,15,4,0,
451,9,18,2,0,
451,9,28,2,0,
451,9,40,1,1,2
451,9,42,1,12,
451,9,43,1,1,3
451,9,44,1,1,1
451,9,70,4,0,
451,9,91,4,0,
451,9,92,4,0,
451,9,97,2,0,
451,9,103,2,0,
451,9,104,4,0,
451,9,109,2,0,
451,9,148,4,0,
451,9,156,4,0,
451,9,163,2,0,
451,9,164,4,0,
451,9,168,4,0,
451,9,173,3,0,
451,9,182,4,0,
451,9,184,1,23,
451,9,185,2,0,
451,9,188,4,0,
451,9,189,3,0,
451,9,203,4,0,
451,9,206,4,0,
451,9,207,4,0,
451,9,210,3,0,
451,9,213,4,0,
451,9,214,4,0,
451,9,216,4,0,
451,9,218,4,0,
451,9,228,2,0,
451,9,231,4,0,
451,9,237,4,0,
451,9,240,4,0,
451,9,241,4,0,
451,9,242,1,45,
451,9,247,4,0,
451,9,249,4,0,
451,9,259,4,0,
451,9,263,4,0,
451,9,269,4,0,
451,9,280,4,0,
451,9,282,1,6,
451,9,282,3,0,
451,9,290,4,0,
451,9,305,1,39,
451,9,317,4,0,
451,9,332,4,0,
451,9,363,4,0,
451,9,367,1,17,
451,9,371,4,0,
451,9,374,4,0,
451,9,390,1,28,
451,9,398,4,0,
451,9,399,4,0,
451,9,400,2,0,
451,9,401,3,0,
451,9,404,4,0,
451,9,440,1,50,
451,9,445,4,0,
451,9,450,1,34,
451,10,14,4,0,
451,10,15,4,0,
451,10,18,2,0,
451,10,28,2,0,
451,10,29,3,0,
451,10,40,1,1,2
451,10,42,1,12,
451,10,43,1,1,3
451,10,44,1,1,1
451,10,70,4,0,
451,10,91,4,0,
451,10,92,4,0,
451,10,97,2,0,
451,10,103,2,0,
451,10,104,4,0,
451,10,109,2,0,
451,10,148,4,0,
451,10,156,4,0,
451,10,163,2,0,
451,10,164,4,0,
451,10,168,4,0,
451,10,173,3,0,
451,10,182,4,0,
451,10,184,1,23,
451,10,185,2,0,
451,10,188,4,0,
451,10,189,3,0,
451,10,203,4,0,
451,10,206,4,0,
451,10,207,4,0,
451,10,210,3,0,
451,10,213,4,0,
451,10,214,4,0,
451,10,216,4,0,
451,10,218,4,0,
451,10,228,2,0,
451,10,231,4,0,
451,10,237,4,0,
451,10,240,4,0,
451,10,241,4,0,
451,10,242,1,45,
451,10,247,4,0,
451,10,249,4,0,
451,10,259,4,0,
451,10,263,4,0,
451,10,269,4,0,
451,10,280,4,0,
451,10,282,1,6,
451,10,282,3,0,
451,10,290,4,0,
451,10,305,1,39,
451,10,317,4,0,
451,10,332,4,0,
451,10,363,4,0,
451,10,367,1,17,
451,10,371,4,0,
451,10,374,4,0,
451,10,390,1,28,
451,10,398,4,0,
451,10,399,4,0,
451,10,400,2,0,
451,10,401,3,0,
451,10,404,4,0,
451,10,440,1,50,
451,10,445,4,0,
451,10,450,1,34,
451,10,450,3,0,
451,11,14,4,0,
451,11,15,4,0,
451,11,18,2,0,
451,11,28,2,0,
451,11,40,1,1,2
451,11,41,2,0,
451,11,42,1,12,
451,11,43,1,1,3
451,11,44,1,1,1
451,11,70,4,0,
451,11,91,4,0,
451,11,92,4,0,
451,11,97,2,0,
451,11,103,2,0,
451,11,104,4,0,
451,11,109,2,0,
451,11,148,4,0,
451,11,156,4,0,
451,11,163,2,0,
451,11,164,4,0,
451,11,168,4,0,
451,11,182,4,0,
451,11,184,1,23,
451,11,185,2,0,
451,11,188,4,0,
451,11,206,4,0,
451,11,207,4,0,
451,11,213,4,0,
451,11,216,4,0,
451,11,218,4,0,
451,11,228,2,0,
451,11,231,2,0,
451,11,237,4,0,
451,11,240,4,0,
451,11,241,4,0,
451,11,242,1,56,
451,11,247,4,0,
451,11,249,4,0,
451,11,259,4,0,
451,11,263,4,0,
451,11,269,4,0,
451,11,280,4,0,
451,11,282,1,6,
451,11,305,1,39,
451,11,317,4,0,
451,11,332,4,0,
451,11,342,2,0,
451,11,367,1,17,
451,11,371,4,0,
451,11,374,4,0,
451,11,390,1,28,
451,11,398,4,0,
451,11,400,2,0,
451,11,404,4,0,
451,11,440,1,61,
451,11,450,1,34,
451,11,468,1,45,
451,11,468,4,0,
451,11,474,1,50,
451,11,474,4,0,
451,11,496,4,0,
451,11,522,4,0,
451,14,14,4,0,
451,14,15,4,0,
451,14,18,2,0,
451,14,28,2,0,
451,14,40,1,1,2
451,14,41,2,0,
451,14,42,1,9,
451,14,43,1,1,3
451,14,44,1,1,1
451,14,70,4,0,
451,14,91,4,0,
451,14,92,4,0,
451,14,97,2,0,
451,14,103,2,0,
451,14,104,4,0,
451,14,109,2,0,
451,14,148,4,0,
451,14,156,4,0,
451,14,163,2,0,
451,14,164,4,0,
451,14,168,4,0,
451,14,173,3,0,
451,14,182,4,0,
451,14,184,1,41,
451,14,185,2,0,
451,14,188,4,0,
451,14,206,4,0,
451,14,207,4,0,
451,14,213,4,0,
451,14,214,3,0,
451,14,216,4,0,
451,14,218,4,0,
451,14,228,1,16,
451,14,228,2,0,
451,14,231,2,0,
451,14,231,3,0,
451,14,237,4,0,
451,14,240,4,0,
451,14,241,4,0,
451,14,242,1,45,
451,14,247,4,0,
451,14,249,4,0,
451,14,259,4,0,
451,14,263,4,0,
451,14,269,4,0,
451,14,280,4,0,
451,14,282,1,5,
451,14,282,3,0,
451,14,305,1,23,
451,14,317,4,0,
451,14,332,4,0,
451,14,342,2,0,
451,14,367,1,13,
451,14,371,4,0,
451,14,374,4,0,
451,14,390,1,34,
451,14,398,4,0,
451,14,399,3,0,
451,14,400,1,38,
451,14,400,2,0,
451,14,401,3,0,
451,14,404,4,0,
451,14,440,1,49,
451,14,450,1,20,
451,14,450,3,0,
451,14,468,1,30,
451,14,468,4,0,
451,14,474,1,27,
451,14,474,4,0,
451,14,496,4,0,
451,14,522,4,0,
451,15,14,4,0,
451,15,15,4,0,
451,15,18,2,0,
451,15,28,2,0,
451,15,40,1,1,2
451,15,41,2,0,
451,15,42,1,9,
451,15,43,1,1,3
451,15,44,1,1,1
451,15,70,4,0,
451,15,91,4,0,
451,15,92,4,0,
451,15,97,2,0,
451,15,103,2,0,
451,15,104,4,0,
451,15,109,2,0,
451,15,148,4,0,
451,15,156,4,0,
451,15,163,2,0,
451,15,164,4,0,
451,15,168,4,0,
451,15,182,4,0,
451,15,184,1,41,
451,15,185,2,0,
451,15,188,4,0,
451,15,206,4,0,
451,15,207,4,0,
451,15,213,4,0,
451,15,214,4,0,
451,15,216,4,0,
451,15,218,4,0,
451,15,228,1,16,
451,15,228,2,0,
451,15,231,2,0,
451,15,237,4,0,
451,15,240,4,0,
451,15,241,4,0,
451,15,242,1,45,
451,15,247,4,0,
451,15,249,4,0,
451,15,259,4,0,
451,15,263,4,0,
451,15,269,4,0,
451,15,280,4,0,
451,15,282,1,5,
451,15,305,1,23,
451,15,317,4,0,
451,15,332,4,0,
451,15,342,2,0,
451,15,367,1,13,
451,15,371,4,0,
451,15,374,4,0,
451,15,390,1,34,
451,15,398,4,0,
451,15,399,4,0,
451,15,400,1,38,
451,15,400,2,0,
451,15,404,4,0,
451,15,440,1,49,
451,15,450,1,20,
451,15,468,1,30,
451,15,468,4,0,
451,15,474,1,27,
451,15,474,4,0,
451,15,496,4,0,
451,15,522,4,0,
451,15,565,1,47,
451,15,590,4,0,
451,15,611,4,0,
451,16,14,4,0,
451,16,15,4,0,
451,16,18,2,0,
451,16,28,2,0,
451,16,40,1,1,2
451,16,41,2,0,
451,16,42,1,9,1
451,16,43,1,1,3
451,16,44,1,1,1
451,16,70,4,0,
451,16,91,4,0,
451,16,92,4,0,
451,16,97,2,0,
451,16,103,2,0,
451,16,104,4,0,
451,16,109,2,0,
451,16,148,4,0,
451,16,156,4,0,
451,16,163,2,0,
451,16,164,4,0,
451,16,168,4,0,
451,16,173,3,0,
451,16,182,4,0,
451,16,184,1,41,1
451,16,185,2,0,
451,16,188,4,0,
451,16,206,4,0,
451,16,207,4,0,
451,16,213,4,0,
451,16,214,4,0,
451,16,216,4,0,
451,16,218,4,0,
451,16,228,1,16,1
451,16,228,2,0,
451,16,231,2,0,
451,16,231,3,0,
451,16,237,4,0,
451,16,240,4,0,
451,16,241,4,0,
451,16,242,1,45,1
451,16,247,4,0,
451,16,249,4,0,
451,16,259,4,0,
451,16,263,4,0,
451,16,269,4,0,
451,16,280,4,0,
451,16,282,1,5,1
451,16,282,3,0,
451,16,290,4,0,
451,16,305,1,23,1
451,16,317,4,0,
451,16,332,4,0,
451,16,342,2,0,
451,16,367,1,13,1
451,16,371,4,0,
451,16,374,4,0,
451,16,390,1,34,1
451,16,398,4,0,
451,16,399,4,0,
451,16,400,1,38,1
451,16,400,2,0,
451,16,401,3,0,
451,16,404,4,0,
451,16,440,1,49,1
451,16,450,1,20,1
451,16,450,3,0,
451,16,468,1,30,1
451,16,468,4,0,
451,16,474,1,27,1
451,16,474,4,0,
451,16,496,4,0,
451,16,522,4,0,
451,16,565,1,47,1
451,16,590,4,0,
451,16,611,4,0,
451,17,14,4,0,
451,17,18,2,0,
451,17,28,2,0,
451,17,40,1,1,2
451,17,41,2,0,
451,17,42,1,9,
451,17,43,1,1,3
451,17,44,1,1,1
451,17,92,4,0,
451,17,97,2,0,
451,17,103,2,0,
451,17,104,4,0,
451,17,109,2,0,
451,17,156,4,0,
451,17,163,2,0,
451,17,164,4,0,
451,17,168,4,0,
451,17,182,4,0,
451,17,184,1,41,
451,17,185,2,0,
451,17,188,4,0,
451,17,206,4,0,
451,17,207,4,0,
451,17,213,4,0,
451,17,214,4,0,
451,17,216,4,0,
451,17,218,4,0,
451,17,228,1,16,
451,17,228,2,0,
451,17,231,2,0,
451,17,237,4,0,
451,17,240,4,0,
451,17,241,4,0,
451,17,242,1,45,
451,17,247,4,0,
451,17,259,4,0,
451,17,263,4,0,
451,17,269,4,0,
451,17,280,4,0,
451,17,282,1,5,
451,17,305,1,23,
451,17,317,4,0,
451,17,332,4,0,
451,17,342,2,0,
451,17,367,1,13,
451,17,371,4,0,
451,17,374,4,0,
451,17,390,1,34,
451,17,398,4,0,
451,17,399,4,0,
451,17,400,1,38,
451,17,400,2,0,
451,17,404,4,0,
451,17,440,1,49,
451,17,450,1,20,
451,17,468,1,30,
451,17,474,1,27,
451,17,474,4,0,
451,17,496,4,0,
451,17,565,1,47,
451,17,590,4,0,
451,17,611,4,0,
451,18,14,4,0,
451,18,18,2,0,
451,18,28,2,0,
451,18,40,1,1,2
451,18,41,2,0,
451,18,42,1,9,
451,18,43,1,1,3
451,18,44,1,1,1
451,18,92,4,0,
451,18,97,2,0,
451,18,103,2,0,
451,18,104,4,0,
451,18,109,2,0,
451,18,156,4,0,
451,18,163,2,0,
451,18,164,4,0,
451,18,168,4,0,
451,18,182,4,0,
451,18,184,1,41,
451,18,185,2,0,
451,18,188,4,0,
451,18,206,4,0,
451,18,207,4,0,
451,18,213,4,0,
451,18,214,4,0,
451,18,216,4,0,
451,18,218,4,0,
451,18,228,1,16,
451,18,228,2,0,
451,18,231,2,0,
451,18,237,4,0,
451,18,240,4,0,
451,18,241,4,0,
451,18,242,1,45,
451,18,247,4,0,
451,18,259,4,0,
451,18,263,4,0,
451,18,269,4,0,
451,18,280,4,0,
451,18,282,1,5,
451,18,305,1,23,
451,18,317,4,0,
451,18,332,4,0,
451,18,342,2,0,
451,18,367,1,13,
451,18,371,4,0,
451,18,374,4,0,
451,18,390,1,34,
451,18,398,4,0,
451,18,399,4,0,
451,18,400,1,38,
451,18,400,2,0,
451,18,404,4,0,
451,18,440,1,49,
451,18,450,1,20,
451,18,468,1,30,
451,18,474,1,27,
451,18,474,4,0,
451,18,496,4,0,
451,18,565,1,47,
451,18,590,4,0,
451,18,611,4,0,
452,8,14,4,0,
452,8,15,4,0,
452,8,40,1,1,5
452,8,42,1,1,7
452,8,42,1,12,
452,8,43,1,1,6
452,8,43,1,6,
452,8,44,1,1,4
452,8,46,4,0,
452,8,63,4,0,
452,8,70,4,0,
452,8,89,4,0,
452,8,91,4,0,
452,8,92,4,0,
452,8,104,4,0,
452,8,148,4,0,
452,8,156,4,0,
452,8,157,4,0,
452,8,164,4,0,
452,8,168,4,0,
452,8,182,4,0,
452,8,184,1,28,
452,8,188,4,0,
452,8,203,4,0,
452,8,206,4,0,
452,8,207,4,0,
452,8,213,4,0,
452,8,214,4,0,
452,8,216,4,0,
452,8,218,4,0,
452,8,231,4,0,
452,8,237,4,0,
452,8,240,4,0,
452,8,241,4,0,
452,8,242,1,49,
452,8,247,4,0,
452,8,249,4,0,
452,8,259,4,0,
452,8,263,4,0,
452,8,269,4,0,
452,8,280,4,0,
452,8,282,1,23,
452,8,290,4,0,
452,8,305,1,39,
452,8,317,4,0,
452,8,332,4,0,
452,8,363,4,0,
452,8,367,1,17,
452,8,371,4,0,
452,8,374,4,0,
452,8,390,1,34,
452,8,398,4,0,
452,8,399,4,0,
452,8,404,4,0,
452,8,416,4,0,
452,8,422,1,1,1
452,8,423,1,1,2
452,8,424,1,1,3
452,8,431,4,0,
452,8,440,1,58,
452,8,445,4,0,
452,9,14,4,0,
452,9,15,4,0,
452,9,40,1,1,5
452,9,42,1,12,
452,9,43,1,1,6
452,9,44,1,1,4
452,9,46,4,0,
452,9,63,4,0,
452,9,70,4,0,
452,9,89,4,0,
452,9,91,4,0,
452,9,92,4,0,
452,9,104,4,0,
452,9,148,4,0,
452,9,156,4,0,
452,9,157,4,0,
452,9,164,4,0,
452,9,168,4,0,
452,9,173,3,0,
452,9,182,4,0,
452,9,184,1,23,
452,9,188,4,0,
452,9,189,3,0,
452,9,203,4,0,
452,9,206,4,0,
452,9,207,4,0,
452,9,210,3,0,
452,9,213,4,0,
452,9,214,4,0,
452,9,216,4,0,
452,9,218,4,0,
452,9,231,4,0,
452,9,237,4,0,
452,9,240,4,0,
452,9,241,4,0,
452,9,242,1,49,
452,9,247,4,0,
452,9,249,4,0,
452,9,259,4,0,
452,9,263,4,0,
452,9,269,4,0,
452,9,280,4,0,
452,9,282,1,1,7
452,9,282,1,6,
452,9,282,3,0,
452,9,290,4,0,
452,9,305,1,39,
452,9,317,4,0,
452,9,332,4,0,
452,9,363,4,0,
452,9,367,1,17,
452,9,371,4,0,
452,9,374,4,0,
452,9,390,1,28,
452,9,398,4,0,
452,9,399,4,0,
452,9,401,3,0,
452,9,404,4,0,
452,9,416,4,0,
452,9,422,1,1,1
452,9,423,1,1,2
452,9,424,1,1,3
452,9,431,4,0,
452,9,440,1,58,
452,9,445,4,0,
452,9,450,1,34,
452,10,14,4,0,
452,10,15,4,0,
452,10,29,3,0,
452,10,40,1,1,5
452,10,42,1,12,
452,10,43,1,1,6
452,10,44,1,1,4
452,10,46,4,0,
452,10,63,4,0,
452,10,70,4,0,
452,10,89,4,0,
452,10,91,4,0,
452,10,92,4,0,
452,10,104,4,0,
452,10,148,4,0,
452,10,156,4,0,
452,10,157,4,0,
452,10,164,4,0,
452,10,168,4,0,
452,10,173,3,0,
452,10,182,4,0,
452,10,184,1,23,
452,10,188,4,0,
452,10,189,3,0,
452,10,203,4,0,
452,10,206,4,0,
452,10,207,4,0,
452,10,210,3,0,
452,10,213,4,0,
452,10,214,4,0,
452,10,216,4,0,
452,10,218,4,0,
452,10,231,4,0,
452,10,237,4,0,
452,10,240,4,0,
452,10,241,4,0,
452,10,242,1,49,
452,10,247,4,0,
452,10,249,4,0,
452,10,259,4,0,
452,10,263,4,0,
452,10,269,4,0,
452,10,280,4,0,
452,10,282,1,1,7
452,10,282,1,6,
452,10,282,3,0,
452,10,290,4,0,
452,10,305,1,39,
452,10,317,4,0,
452,10,332,4,0,
452,10,363,4,0,
452,10,367,1,17,
452,10,371,4,0,
452,10,374,4,0,
452,10,390,1,28,
452,10,398,4,0,
452,10,399,4,0,
452,10,401,3,0,
452,10,404,4,0,
452,10,416,4,0,
452,10,422,1,1,1
452,10,423,1,1,2
452,10,424,1,1,3
452,10,431,4,0,
452,10,440,1,58,
452,10,445,4,0,
452,10,450,1,34,
452,10,450,3,0,
452,11,14,4,0,
452,11,15,4,0,
452,11,40,1,1,5
452,11,42,1,12,
452,11,43,1,1,6
452,11,44,1,1,4
452,11,46,4,0,
452,11,63,4,0,
452,11,70,4,0,
452,11,89,4,0,
452,11,91,4,0,
452,11,92,4,0,
452,11,104,4,0,
452,11,148,4,0,
452,11,156,4,0,
452,11,157,4,0,
452,11,164,4,0,
452,11,168,4,0,
452,11,182,4,0,
452,11,184,1,23,
452,11,188,4,0,
452,11,206,4,0,
452,11,207,4,0,
452,11,213,4,0,
452,11,216,4,0,
452,11,218,4,0,
452,11,237,4,0,
452,11,240,4,0,
452,11,241,4,0,
452,11,242,1,65,
452,11,247,4,0,
452,11,249,4,0,
452,11,259,4,0,
452,11,263,4,0,
452,11,269,4,0,
452,11,280,4,0,
452,11,282,1,1,7
452,11,282,1,6,
452,11,305,1,39,
452,11,317,4,0,
452,11,332,4,0,
452,11,367,1,17,
452,11,371,4,0,
452,11,374,4,0,
452,11,390,1,28,
452,11,398,4,0,
452,11,404,4,0,
452,11,416,4,0,
452,11,422,1,1,1
452,11,423,1,1,2
452,11,424,1,1,3
452,11,440,1,73,
452,11,450,1,34,
452,11,468,1,48,
452,11,468,4,0,
452,11,474,1,56,
452,11,474,4,0,
452,11,496,4,0,
452,11,514,4,0,
452,11,522,4,0,
452,11,523,4,0,
452,11,555,4,0,
452,14,14,4,0,
452,14,15,4,0,
452,14,40,1,1,5
452,14,42,1,9,
452,14,43,1,1,6
452,14,44,1,1,4
452,14,46,4,0,
452,14,63,4,0,
452,14,70,4,0,
452,14,89,4,0,
452,14,91,4,0,
452,14,92,4,0,
452,14,104,4,0,
452,14,148,4,0,
452,14,156,4,0,
452,14,157,4,0,
452,14,164,4,0,
452,14,168,4,0,
452,14,173,3,0,
452,14,182,4,0,
452,14,184,1,43,
452,14,188,4,0,
452,14,206,4,0,
452,14,207,4,0,
452,14,213,4,0,
452,14,214,3,0,
452,14,216,4,0,
452,14,218,4,0,
452,14,228,1,16,
452,14,231,3,0,
452,14,237,4,0,
452,14,240,4,0,
452,14,241,4,0,
452,14,242,1,49,
452,14,247,4,0,
452,14,249,4,0,
452,14,259,4,0,
452,14,263,4,0,
452,14,269,4,0,
452,14,280,4,0,
452,14,282,1,1,7
452,14,282,1,5,
452,14,282,3,0,
452,14,305,1,23,
452,14,317,4,0,
452,14,332,4,0,
452,14,367,1,13,
452,14,371,4,0,
452,14,374,4,0,
452,14,390,1,34,
452,14,398,4,0,
452,14,399,3,0,
452,14,400,1,38,
452,14,401,3,0,
452,14,404,4,0,
452,14,416,4,0,
452,14,422,1,1,1
452,14,423,1,1,2
452,14,424,1,1,3
452,14,440,1,57,
452,14,450,1,20,
452,14,450,3,0,
452,14,468,1,30,
452,14,468,4,0,
452,14,474,1,27,
452,14,474,4,0,
452,14,496,4,0,
452,14,514,4,0,
452,14,522,4,0,
452,14,523,4,0,
452,14,555,4,0,
452,15,14,4,0,
452,15,15,4,0,
452,15,40,1,1,5
452,15,42,1,9,
452,15,43,1,1,6
452,15,44,1,1,4
452,15,46,4,0,
452,15,63,4,0,
452,15,70,4,0,
452,15,89,4,0,
452,15,91,4,0,
452,15,92,4,0,
452,15,104,4,0,
452,15,148,4,0,
452,15,156,4,0,
452,15,157,4,0,
452,15,164,4,0,
452,15,168,4,0,
452,15,182,4,0,
452,15,184,1,43,
452,15,188,4,0,
452,15,206,4,0,
452,15,207,4,0,
452,15,213,4,0,
452,15,214,4,0,
452,15,216,4,0,
452,15,218,4,0,
452,15,228,1,16,
452,15,237,4,0,
452,15,240,4,0,
452,15,241,4,0,
452,15,242,1,49,
452,15,247,4,0,
452,15,249,4,0,
452,15,259,4,0,
452,15,263,4,0,
452,15,269,4,0,
452,15,280,4,0,
452,15,282,1,1,7
452,15,282,1,5,
452,15,305,1,23,
452,15,317,4,0,
452,15,332,4,0,
452,15,367,1,13,
452,15,371,4,0,
452,15,374,4,0,
452,15,390,1,34,
452,15,398,4,0,
452,15,399,4,0,
452,15,400,1,38,
452,15,404,4,0,
452,15,416,4,0,
452,15,422,1,1,1
452,15,423,1,1,2
452,15,424,1,1,3
452,15,440,1,57,
452,15,450,1,20,
452,15,468,1,30,
452,15,468,4,0,
452,15,474,1,27,
452,15,474,4,0,
452,15,496,4,0,
452,15,514,4,0,
452,15,522,4,0,
452,15,523,4,0,
452,15,555,4,0,
452,15,565,1,53,
452,15,590,4,0,
452,15,611,4,0,
452,16,14,4,0,
452,16,15,4,0,
452,16,40,1,1,5
452,16,42,1,9,1
452,16,43,1,1,6
452,16,44,1,1,4
452,16,46,4,0,
452,16,63,4,0,
452,16,70,4,0,
452,16,89,4,0,
452,16,91,4,0,
452,16,92,4,0,
452,16,104,4,0,
452,16,148,4,0,
452,16,156,4,0,
452,16,157,4,0,
452,16,164,4,0,
452,16,168,4,0,
452,16,173,3,0,
452,16,182,4,0,
452,16,184,1,43,1
452,16,188,4,0,
452,16,206,4,0,
452,16,207,4,0,
452,16,213,4,0,
452,16,214,4,0,
452,16,216,4,0,
452,16,218,4,0,
452,16,228,1,16,1
452,16,231,3,0,
452,16,237,4,0,
452,16,240,4,0,
452,16,241,4,0,
452,16,242,1,49,1
452,16,247,4,0,
452,16,249,4,0,
452,16,259,4,0,
452,16,263,4,0,
452,16,269,4,0,
452,16,280,4,0,
452,16,282,1,1,7
452,16,282,1,5,1
452,16,282,3,0,
452,16,290,4,0,
452,16,305,1,23,1
452,16,317,4,0,
452,16,332,4,0,
452,16,367,1,13,1
452,16,371,4,0,
452,16,374,4,0,
452,16,390,1,34,1
452,16,398,4,0,
452,16,399,4,0,
452,16,400,1,38,1
452,16,401,3,0,
452,16,404,4,0,
452,16,416,4,0,
452,16,422,1,1,1
452,16,423,1,1,2
452,16,424,1,1,3
452,16,440,1,57,1
452,16,450,1,20,1
452,16,450,3,0,
452,16,468,1,30,1
452,16,468,4,0,
452,16,474,1,27,1
452,16,474,4,0,
452,16,496,4,0,
452,16,514,4,0,
452,16,522,4,0,
452,16,523,4,0,
452,16,555,4,0,
452,16,565,1,53,1
452,16,590,4,0,
452,16,611,4,0,
452,17,14,4,0,
452,17,40,1,1,5
452,17,42,1,9,
452,17,43,1,1,6
452,17,44,1,1,4
452,17,46,4,0,
452,17,63,4,0,
452,17,89,4,0,
452,17,92,4,0,
452,17,104,4,0,
452,17,156,4,0,
452,17,157,4,0,
452,17,164,4,0,
452,17,168,4,0,
452,17,182,4,0,
452,17,184,1,43,
452,17,188,4,0,
452,17,206,4,0,
452,17,207,4,0,
452,17,213,4,0,
452,17,214,4,0,
452,17,216,4,0,
452,17,218,4,0,
452,17,228,1,16,
452,17,237,4,0,
452,17,240,4,0,
452,17,241,4,0,
452,17,242,1,49,
452,17,247,4,0,
452,17,259,4,0,
452,17,263,4,0,
452,17,269,4,0,
452,17,280,4,0,
452,17,282,1,1,7
452,17,282,1,5,
452,17,305,1,23,
452,17,317,4,0,
452,17,332,4,0,
452,17,367,1,13,
452,17,371,4,0,
452,17,374,4,0,
452,17,390,1,34,
452,17,398,4,0,
452,17,399,4,0,
452,17,400,1,38,
452,17,404,4,0,
452,17,416,4,0,
452,17,422,1,1,1
452,17,423,1,1,2
452,17,424,1,1,3
452,17,440,1,57,
452,17,450,1,20,
452,17,468,1,30,
452,17,474,1,27,
452,17,474,4,0,
452,17,496,4,0,
452,17,523,4,0,
452,17,555,4,0,
452,17,565,1,53,
452,17,590,4,0,
452,17,611,4,0,
452,17,693,4,0,
452,18,14,4,0,
452,18,40,1,1,5
452,18,42,1,9,
452,18,43,1,1,6
452,18,44,1,1,4
452,18,46,4,0,
452,18,63,4,0,
452,18,89,4,0,
452,18,92,4,0,
452,18,104,4,0,
452,18,156,4,0,
452,18,157,4,0,
452,18,164,4,0,
452,18,168,4,0,
452,18,182,4,0,
452,18,184,1,43,
452,18,188,4,0,
452,18,206,4,0,
452,18,207,4,0,
452,18,213,4,0,
452,18,214,4,0,
452,18,216,4,0,
452,18,218,4,0,
452,18,228,1,16,
452,18,237,4,0,
452,18,240,4,0,
452,18,241,4,0,
452,18,242,1,49,
452,18,247,4,0,
452,18,259,4,0,
452,18,263,4,0,
452,18,269,4,0,
452,18,280,4,0,
452,18,282,1,1,7
452,18,282,1,5,
452,18,305,1,23,
452,18,317,4,0,
452,18,332,4,0,
452,18,367,1,13,
452,18,371,4,0,
452,18,374,4,0,
452,18,390,1,34,
452,18,398,4,0,
452,18,399,4,0,
452,18,400,1,38,
452,18,404,4,0,
452,18,416,4,0,
452,18,422,1,1,1
452,18,423,1,1,2
452,18,424,1,1,3
452,18,440,1,57,
452,18,450,1,20,
452,18,468,1,30,
452,18,474,1,27,
452,18,474,4,0,
452,18,496,4,0,
452,18,523,4,0,
452,18,555,4,0,
452,18,565,1,53,
452,18,590,4,0,
452,18,611,4,0,
452,18,693,4,0,
453,8,29,2,0,
453,8,40,1,8,
453,8,70,4,0,
453,8,89,4,0,
453,8,91,4,0,
453,8,92,4,0,
453,8,96,2,0,
453,8,104,4,0,
453,8,156,4,0,
453,8,157,4,0,
453,8,164,4,0,
453,8,168,4,0,
453,8,182,4,0,
453,8,185,1,17,
453,8,188,1,43,
453,8,188,4,0,
453,8,189,1,3,
453,8,203,4,0,
453,8,207,1,24,
453,8,207,4,0,
453,8,213,4,0,
453,8,214,4,0,
453,8,216,4,0,
453,8,218,4,0,
453,8,223,2,0,
453,8,228,1,15,
453,8,237,4,0,
453,8,238,2,0,
453,8,240,4,0,
453,8,241,4,0,
453,8,247,4,0,
453,8,249,4,0,
453,8,252,2,0,
453,8,259,4,0,
453,8,260,1,45,
453,8,263,4,0,
453,8,264,4,0,
453,8,265,2,0,
453,8,269,1,10,
453,8,269,4,0,
453,8,279,1,22,
453,8,280,4,0,
453,8,289,4,0,
453,8,290,4,0,
453,8,310,1,1,
453,8,317,4,0,
453,8,339,4,0,
453,8,358,2,0,
453,8,363,4,0,
453,8,364,2,0,
453,8,371,4,0,
453,8,373,4,0,
453,8,374,4,0,
453,8,382,2,0,
453,8,389,1,31,
453,8,398,1,38,
453,8,398,4,0,
453,8,399,4,0,
453,8,404,4,0,
453,8,410,2,0,
453,8,411,4,0,
453,8,417,1,36,
453,8,418,2,0,
453,8,426,1,29,
453,8,431,4,0,
453,8,445,4,0,
453,9,8,3,0,
453,9,9,3,0,
453,9,29,2,0,
453,9,40,1,8,
453,9,70,4,0,
453,9,89,4,0,
453,9,91,4,0,
453,9,92,4,0,
453,9,96,2,0,
453,9,104,4,0,
453,9,156,4,0,
453,9,157,4,0,
453,9,164,4,0,
453,9,168,4,0,
453,9,173,3,0,
453,9,180,3,0,
453,9,182,4,0,
453,9,185,1,17,
453,9,188,1,43,
453,9,188,4,0,
453,9,189,1,3,
453,9,189,3,0,
453,9,196,3,0,
453,9,203,4,0,
453,9,207,1,24,
453,9,207,4,0,
453,9,210,3,0,
453,9,213,4,0,
453,9,214,4,0,
453,9,216,4,0,
453,9,218,4,0,
453,9,223,2,0,
453,9,228,1,15,
453,9,237,4,0,
453,9,238,2,0,
453,9,240,4,0,
453,9,241,4,0,
453,9,247,4,0,
453,9,249,4,0,
453,9,252,2,0,
453,9,259,4,0,
453,9,260,1,45,
453,9,263,4,0,
453,9,264,4,0,
453,9,265,2,0,
453,9,269,1,10,
453,9,269,4,0,
453,9,270,3,0,
453,9,279,1,22,
453,9,280,4,0,
453,9,282,3,0,
453,9,289,4,0,
453,9,290,4,0,
453,9,310,1,1,
453,9,317,4,0,
453,9,339,4,0,
453,9,340,3,0,
453,9,358,2,0,
453,9,363,4,0,
453,9,364,2,0,
453,9,371,4,0,
453,9,373,4,0,
453,9,374,4,0,
453,9,382,2,0,
453,9,389,1,31,
453,9,389,3,0,
453,9,398,1,38,
453,9,398,4,0,
453,9,399,4,0,
453,9,404,4,0,
453,9,410,2,0,
453,9,410,3,0,
453,9,411,4,0,
453,9,417,1,36,
453,9,418,2,0,
453,9,426,1,29,
453,9,431,4,0,
453,9,441,3,0,
453,9,445,4,0,
453,10,8,3,0,
453,10,9,3,0,
453,10,29,2,0,
453,10,29,3,0,
453,10,40,1,8,
453,10,67,3,0,
453,10,68,2,0,
453,10,70,4,0,
453,10,89,4,0,
453,10,91,4,0,
453,10,92,4,0,
453,10,96,2,0,
453,10,104,4,0,
453,10,156,4,0,
453,10,157,4,0,
453,10,162,3,0,
453,10,164,4,0,
453,10,168,4,0,
453,10,173,3,0,
453,10,180,3,0,
453,10,182,4,0,
453,10,185,1,17,
453,10,188,1,43,
453,10,188,4,0,
453,10,189,1,3,
453,10,189,3,0,
453,10,196,3,0,
453,10,203,4,0,
453,10,207,1,24,
453,10,207,4,0,
453,10,210,3,0,
453,10,213,4,0,
453,10,214,4,0,
453,10,216,4,0,
453,10,218,4,0,
453,10,223,2,0,
453,10,228,1,15,
453,10,237,4,0,
453,10,238,2,0,
453,10,240,4,0,
453,10,241,4,0,
453,10,247,4,0,
453,10,249,4,0,
453,10,252,2,0,
453,10,259,4,0,
453,10,260,1,45,
453,10,263,4,0,
453,10,264,4,0,
453,10,265,2,0,
453,10,269,1,10,
453,10,269,4,0,
453,10,270,3,0,
453,10,272,3,0,
453,10,279,1,22,
453,10,280,4,0,
453,10,282,3,0,
453,10,289,4,0,
453,10,290,4,0,
453,10,310,1,1,
453,10,317,4,0,
453,10,339,4,0,
453,10,340,3,0,
453,10,358,2,0,
453,10,363,4,0,
453,10,364,2,0,
453,10,371,4,0,
453,10,373,4,0,
453,10,374,4,0,
453,10,382,2,0,
453,10,389,1,31,
453,10,389,3,0,
453,10,398,1,38,
453,10,398,4,0,
453,10,399,4,0,
453,10,404,4,0,
453,10,410,2,0,
453,10,410,3,0,
453,10,411,4,0,
453,10,417,1,36,
453,10,418,2,0,
453,10,426,1,29,
453,10,431,4,0,
453,10,441,3,0,
453,10,445,4,0,
453,11,29,2,0,
453,11,40,1,8,
453,11,68,2,0,
453,11,70,4,0,
453,11,89,4,0,
453,11,91,4,0,
453,11,92,4,0,
453,11,96,2,0,
453,11,104,4,0,
453,11,156,4,0,
453,11,157,4,0,
453,11,164,4,0,
453,11,168,4,0,
453,11,182,4,0,
453,11,185,1,17,
453,11,188,1,45,
453,11,188,4,0,
453,11,189,1,3,
453,11,207,1,24,
453,11,207,4,0,
453,11,213,4,0,
453,11,216,4,0,
453,11,218,4,0,
453,11,223,2,0,
453,11,228,1,15,
453,11,237,4,0,
453,11,238,2,0,
453,11,240,4,0,
453,11,241,4,0,
453,11,247,4,0,
453,11,249,4,0,
453,11,252,2,0,
453,11,259,4,0,
453,11,260,1,50,
453,11,263,4,0,
453,11,265,2,0,
453,11,269,1,10,
453,11,269,4,0,
453,11,279,1,22,
453,11,280,4,0,
453,11,310,1,1,
453,11,317,4,0,
453,11,339,4,0,
453,11,358,2,0,
453,11,364,2,0,
453,11,367,2,0,
453,11,371,4,0,
453,11,373,4,0,
453,11,374,4,0,
453,11,382,2,0,
453,11,389,1,31,
453,11,398,1,43,
453,11,398,4,0,
453,11,404,4,0,
453,11,409,2,0,
453,11,410,2,0,
453,11,411,4,0,
453,11,417,1,38,
453,11,418,2,0,
453,11,426,1,29,
453,11,474,1,36,
453,11,474,4,0,
453,11,482,4,0,
453,11,490,4,0,
453,11,496,4,0,
453,11,514,4,0,
453,11,523,4,0,
453,11,526,4,0,
453,14,8,3,0,
453,14,9,3,0,
453,14,29,2,0,
453,14,40,1,8,
453,14,67,3,0,
453,14,68,2,0,
453,14,70,4,0,
453,14,89,4,0,
453,14,91,4,0,
453,14,92,4,0,
453,14,96,2,0,
453,14,104,4,0,
453,14,156,4,0,
453,14,157,4,0,
453,14,162,3,0,
453,14,164,4,0,
453,14,168,4,0,
453,14,173,3,0,
453,14,180,3,0,
453,14,182,4,0,
453,14,185,1,17,
453,14,188,1,45,
453,14,188,4,0,
453,14,189,1,3,
453,14,196,3,0,
453,14,207,1,24,
453,14,207,4,0,
453,14,213,4,0,
453,14,214,3,0,
453,14,216,4,0,
453,14,218,4,0,
453,14,223,2,0,
453,14,228,1,15,
453,14,237,4,0,
453,14,238,2,0,
453,14,240,4,0,
453,14,241,4,0,
453,14,247,4,0,
453,14,249,4,0,
453,14,252,2,0,
453,14,259,4,0,
453,14,260,1,50,
453,14,263,4,0,
453,14,265,2,0,
453,14,269,1,10,
453,14,269,4,0,
453,14,270,3,0,
453,14,272,3,0,
453,14,279,1,22,
453,14,280,4,0,
453,14,282,3,0,
453,14,289,3,0,
453,14,310,1,1,
453,14,317,4,0,
453,14,339,4,0,
453,14,340,3,0,
453,14,358,2,0,
453,14,364,2,0,
453,14,367,2,0,
453,14,371,4,0,
453,14,373,4,0,
453,14,374,4,0,
453,14,382,2,0,
453,14,389,1,31,
453,14,398,1,43,
453,14,398,4,0,
453,14,399,3,0,
453,14,404,4,0,
453,14,409,2,0,
453,14,409,3,0,
453,14,410,2,0,
453,14,411,4,0,
453,14,417,1,38,
453,14,418,2,0,
453,14,426,1,29,
453,14,441,3,0,
453,14,474,1,36,
453,14,474,4,0,
453,14,482,4,0,
453,14,490,4,0,
453,14,492,3,0,
453,14,496,4,0,
453,14,514,4,0,
453,14,523,4,0,
453,14,526,4,0,
453,14,530,3,0,
453,15,29,2,0,
453,15,40,1,8,
453,15,68,2,0,
453,15,70,4,0,
453,15,89,4,0,
453,15,91,4,0,
453,15,92,4,0,
453,15,96,2,0,
453,15,104,4,0,
453,15,156,4,0,
453,15,157,4,0,
453,15,164,4,0,
453,15,168,4,0,
453,15,182,4,0,
453,15,185,1,17,
453,15,188,1,45,
453,15,188,4,0,
453,15,189,1,3,
453,15,207,1,24,
453,15,207,4,0,
453,15,213,4,0,
453,15,214,4,0,
453,15,216,4,0,
453,15,218,4,0,
453,15,223,2,0,
453,15,228,1,15,
453,15,237,4,0,
453,15,238,2,0,
453,15,240,4,0,
453,15,241,4,0,
453,15,247,4,0,
453,15,249,4,0,
453,15,252,2,0,
453,15,259,4,0,
453,15,260,1,50,
453,15,263,4,0,
453,15,265,2,0,
453,15,269,1,10,
453,15,269,4,0,
453,15,279,1,22,
453,15,280,4,0,
453,15,310,1,1,
453,15,317,4,0,
453,15,339,4,0,
453,15,358,2,0,
453,15,364,2,0,
453,15,367,2,0,
453,15,371,4,0,
453,15,373,4,0,
453,15,374,4,0,
453,15,382,2,0,
453,15,389,1,31,
453,15,398,1,43,
453,15,398,4,0,
453,15,399,4,0,
453,15,404,4,0,
453,15,409,2,0,
453,15,410,2,0,
453,15,411,4,0,
453,15,417,1,38,
453,15,418,2,0,
453,15,426,1,29,
453,15,474,1,36,
453,15,474,4,0,
453,15,482,4,0,
453,15,490,4,0,
453,15,496,4,0,
453,15,501,2,0,
453,15,514,4,0,
453,15,523,4,0,
453,15,562,1,47,
453,15,590,4,0,
453,15,612,4,0,
453,16,8,3,0,
453,16,9,3,0,
453,16,29,2,0,
453,16,40,1,8,1
453,16,67,3,0,
453,16,68,2,0,
453,16,70,4,0,
453,16,89,4,0,
453,16,91,4,0,
453,16,92,4,0,
453,16,96,2,0,
453,16,104,4,0,
453,16,156,4,0,
453,16,157,4,0,
453,16,162,3,0,
453,16,164,4,0,
453,16,168,4,0,
453,16,173,3,0,
453,16,180,3,0,
453,16,182,4,0,
453,16,185,1,17,1
453,16,188,1,45,1
453,16,188,4,0,
453,16,189,1,3,1
453,16,196,3,0,
453,16,207,1,24,1
453,16,207,4,0,
453,16,213,4,0,
453,16,214,4,0,
453,16,216,4,0,
453,16,218,4,0,
453,16,223,2,0,
453,16,228,1,15,1
453,16,237,4,0,
453,16,238,2,0,
453,16,240,4,0,
453,16,241,4,0,
453,16,247,4,0,
453,16,249,4,0,
453,16,252,2,0,
453,16,259,4,0,
453,16,260,1,50,1
453,16,263,4,0,
453,16,264,3,0,
453,16,265,2,0,
453,16,269,1,10,1
453,16,269,4,0,
453,16,270,3,0,
453,16,272,3,0,
453,16,279,1,22,1
453,16,280,4,0,
453,16,282,3,0,
453,16,289,3,0,
453,16,290,4,0,
453,16,310,1,1,1
453,16,317,4,0,
453,16,339,4,0,
453,16,340,3,0,
453,16,358,2,0,
453,16,364,2,0,
453,16,367,2,0,
453,16,371,4,0,
453,16,373,4,0,
453,16,374,4,0,
453,16,382,2,0,
453,16,389,1,31,1
453,16,398,1,43,1
453,16,398,4,0,
453,16,399,4,0,
453,16,404,4,0,
453,16,409,2,0,
453,16,409,3,0,
453,16,410,2,0,
453,16,411,4,0,
453,16,417,1,38,1
453,16,418,2,0,
453,16,426,1,29,1
453,16,441,3,0,
453,16,474,1,36,1
453,16,474,4,0,
453,16,482,4,0,
453,16,490,4,0,
453,16,492,3,0,
453,16,496,4,0,
453,16,501,2,0,
453,16,514,4,0,
453,16,523,4,0,
453,16,530,3,0,
453,16,562,1,47,1
453,16,590,4,0,
453,16,612,4,0,
453,17,29,2,0,
453,17,40,1,8,
453,17,68,2,0,
453,17,89,4,0,
453,17,92,4,0,
453,17,96,2,0,
453,17,104,4,0,
453,17,156,4,0,
453,17,157,4,0,
453,17,164,4,0,
453,17,168,4,0,
453,17,182,4,0,
453,17,185,1,17,
453,17,188,1,45,
453,17,188,4,0,
453,17,189,1,3,
453,17,207,1,24,
453,17,207,4,0,
453,17,213,4,0,
453,17,214,4,0,
453,17,216,4,0,
453,17,218,4,0,
453,17,223,2,0,
453,17,228,1,15,
453,17,237,4,0,
453,17,238,2,0,
453,17,240,4,0,
453,17,241,4,0,
453,17,247,4,0,
453,17,252,2,0,
453,17,259,4,0,
453,17,260,1,50,
453,17,263,4,0,
453,17,265,2,0,
453,17,269,1,10,
453,17,269,4,0,
453,17,279,1,22,
453,17,280,4,0,
453,17,310,1,1,
453,17,317,4,0,
453,17,339,4,0,
453,17,358,2,0,
453,17,364,2,0,
453,17,367,2,0,
453,17,371,4,0,
453,17,373,4,0,
453,17,374,4,0,
453,17,382,2,0,
453,17,389,1,31,
453,17,398,1,43,
453,17,398,4,0,
453,17,399,4,0,
453,17,404,4,0,
453,17,409,2,0,
453,17,410,2,0,
453,17,411,4,0,
453,17,417,1,38,
453,17,418,2,0,
453,17,426,1,29,
453,17,474,1,36,
453,17,474,4,0,
453,17,482,4,0,
453,17,490,4,0,
453,17,496,4,0,
453,17,501,2,0,
453,17,523,4,0,
453,17,526,4,0,
453,17,562,1,47,
453,17,590,4,0,
453,18,29,2,0,
453,18,40,1,8,
453,18,68,2,0,
453,18,89,4,0,
453,18,92,4,0,
453,18,96,2,0,
453,18,104,4,0,
453,18,156,4,0,
453,18,157,4,0,
453,18,164,4,0,
453,18,168,4,0,
453,18,182,4,0,
453,18,185,1,17,
453,18,188,1,45,
453,18,188,4,0,
453,18,189,1,3,
453,18,207,1,24,
453,18,207,4,0,
453,18,213,4,0,
453,18,214,4,0,
453,18,216,4,0,
453,18,218,4,0,
453,18,223,2,0,
453,18,228,1,15,
453,18,237,4,0,
453,18,238,2,0,
453,18,240,4,0,
453,18,241,4,0,
453,18,247,4,0,
453,18,252,2,0,
453,18,259,4,0,
453,18,260,1,50,
453,18,263,4,0,
453,18,265,2,0,
453,18,269,1,10,
453,18,269,4,0,
453,18,279,1,22,
453,18,280,4,0,
453,18,310,1,1,
453,18,317,4,0,
453,18,339,4,0,
453,18,358,2,0,
453,18,364,2,0,
453,18,367,2,0,
453,18,371,4,0,
453,18,373,4,0,
453,18,374,4,0,
453,18,382,2,0,
453,18,389,1,31,
453,18,398,1,43,
453,18,398,4,0,
453,18,399,4,0,
453,18,404,4,0,
453,18,409,2,0,
453,18,410,2,0,
453,18,411,4,0,
453,18,417,1,38,
453,18,418,2,0,
453,18,426,1,29,
453,18,474,1,36,
453,18,474,4,0,
453,18,482,4,0,
453,18,490,4,0,
453,18,496,4,0,
453,18,501,2,0,
453,18,523,4,0,
453,18,526,4,0,
453,18,562,1,47,
453,18,590,4,0,
454,8,14,4,0,
454,8,15,4,0,
454,8,40,1,1,3
454,8,40,1,8,
454,8,63,4,0,
454,8,70,4,0,
454,8,89,4,0,
454,8,91,4,0,
454,8,92,4,0,
454,8,104,4,0,
454,8,156,4,0,
454,8,157,4,0,
454,8,164,4,0,
454,8,168,4,0,
454,8,182,4,0,
454,8,185,1,17,
454,8,188,1,49,
454,8,188,4,0,
454,8,189,1,1,2
454,8,189,1,3,
454,8,203,4,0,
454,8,207,1,24,
454,8,207,4,0,
454,8,213,4,0,
454,8,214,4,0,
454,8,216,4,0,
454,8,218,4,0,
454,8,228,1,15,
454,8,237,4,0,
454,8,240,4,0,
454,8,241,4,0,
454,8,247,4,0,
454,8,249,4,0,
454,8,259,4,0,
454,8,260,1,54,
454,8,263,4,0,
454,8,264,4,0,
454,8,269,1,10,
454,8,269,4,0,
454,8,279,1,22,
454,8,280,4,0,
454,8,289,4,0,
454,8,290,4,0,
454,8,310,1,1,1
454,8,317,4,0,
454,8,339,4,0,
454,8,363,4,0,
454,8,371,4,0,
454,8,373,4,0,
454,8,374,4,0,
454,8,389,1,31,
454,8,398,1,41,
454,8,398,4,0,
454,8,399,4,0,
454,8,404,4,0,
454,8,411,4,0,
454,8,416,4,0,
454,8,417,1,36,
454,8,426,1,29,
454,8,431,4,0,
454,8,444,4,0,
454,8,445,4,0,
454,9,8,3,0,
454,9,9,3,0,
454,9,14,4,0,
454,9,15,4,0,
454,9,40,1,1,3
454,9,40,1,8,
454,9,63,4,0,
454,9,70,4,0,
454,9,89,4,0,
454,9,91,4,0,
454,9,92,4,0,
454,9,104,4,0,
454,9,156,4,0,
454,9,157,4,0,
454,9,164,4,0,
454,9,168,4,0,
454,9,173,3,0,
454,9,180,3,0,
454,9,182,4,0,
454,9,185,1,17,
454,9,188,1,49,
454,9,188,4,0,
454,9,189,1,1,2
454,9,189,1,3,
454,9,189,3,0,
454,9,196,3,0,
454,9,203,4,0,
454,9,207,1,24,
454,9,207,4,0,
454,9,210,3,0,
454,9,213,4,0,
454,9,214,4,0,
454,9,216,4,0,
454,9,218,4,0,
454,9,228,1,15,
454,9,237,4,0,
454,9,240,4,0,
454,9,241,4,0,
454,9,247,4,0,
454,9,249,4,0,
454,9,259,4,0,
454,9,260,1,54,
454,9,263,4,0,
454,9,264,4,0,
454,9,269,1,10,
454,9,269,4,0,
454,9,270,3,0,
454,9,279,1,22,
454,9,280,4,0,
454,9,282,3,0,
454,9,289,4,0,
454,9,290,4,0,
454,9,310,1,1,1
454,9,317,4,0,
454,9,339,4,0,
454,9,340,3,0,
454,9,363,4,0,
454,9,371,4,0,
454,9,373,4,0,
454,9,374,4,0,
454,9,389,1,31,
454,9,389,3,0,
454,9,398,1,41,
454,9,398,4,0,
454,9,399,4,0,
454,9,404,4,0,
454,9,410,3,0,
454,9,411,4,0,
454,9,416,4,0,
454,9,417,1,36,
454,9,426,1,29,
454,9,431,4,0,
454,9,441,3,0,
454,9,444,4,0,
454,9,445,4,0,
454,10,8,3,0,
454,10,9,3,0,
454,10,14,4,0,
454,10,15,4,0,
454,10,29,3,0,
454,10,40,1,1,3
454,10,40,1,8,
454,10,63,4,0,
454,10,67,3,0,
454,10,70,4,0,
454,10,89,4,0,
454,10,91,4,0,
454,10,92,4,0,
454,10,104,4,0,
454,10,156,4,0,
454,10,157,4,0,
454,10,162,3,0,
454,10,164,4,0,
454,10,168,4,0,
454,10,173,3,0,
454,10,180,3,0,
454,10,182,4,0,
454,10,185,1,17,
454,10,188,1,49,
454,10,188,4,0,
454,10,189,1,1,2
454,10,189,1,3,
454,10,189,3,0,
454,10,196,3,0,
454,10,203,4,0,
454,10,207,1,24,
454,10,207,4,0,
454,10,210,3,0,
454,10,213,4,0,
454,10,214,4,0,
454,10,216,4,0,
454,10,218,4,0,
454,10,228,1,15,
454,10,237,4,0,
454,10,240,4,0,
454,10,241,4,0,
454,10,247,4,0,
454,10,249,4,0,
454,10,259,4,0,
454,10,260,1,54,
454,10,263,4,0,
454,10,264,4,0,
454,10,269,1,10,
454,10,269,4,0,
454,10,270,3,0,
454,10,272,3,0,
454,10,279,1,22,
454,10,280,4,0,
454,10,282,3,0,
454,10,289,4,0,
454,10,290,4,0,
454,10,310,1,1,1
454,10,317,4,0,
454,10,339,4,0,
454,10,340,3,0,
454,10,363,4,0,
454,10,371,4,0,
454,10,373,4,0,
454,10,374,4,0,
454,10,389,1,31,
454,10,389,3,0,
454,10,398,1,41,
454,10,398,4,0,
454,10,399,4,0,
454,10,404,4,0,
454,10,410,3,0,
454,10,411,4,0,
454,10,416,4,0,
454,10,417,1,36,
454,10,426,1,29,
454,10,431,4,0,
454,10,441,3,0,
454,10,444,4,0,
454,10,445,4,0,
454,11,14,4,0,
454,11,15,4,0,
454,11,40,1,1,3
454,11,40,1,8,
454,11,63,4,0,
454,11,70,4,0,
454,11,89,4,0,
454,11,91,4,0,
454,11,92,4,0,
454,11,104,4,0,
454,11,156,4,0,
454,11,157,4,0,
454,11,164,4,0,
454,11,168,4,0,
454,11,182,4,0,
454,11,185,1,17,
454,11,188,1,54,
454,11,188,4,0,
454,11,189,1,1,2
454,11,189,1,3,
454,11,207,1,24,
454,11,207,4,0,
454,11,213,4,0,
454,11,216,4,0,
454,11,218,4,0,
454,11,228,1,15,
454,11,237,4,0,
454,11,240,4,0,
454,11,241,4,0,
454,11,247,4,0,
454,11,249,4,0,
454,11,259,4,0,
454,11,260,1,62,
454,11,263,4,0,
454,11,269,1,10,
454,11,269,4,0,
454,11,279,1,22,
454,11,280,4,0,
454,11,310,1,1,1
454,11,317,4,0,
454,11,339,4,0,
454,11,371,4,0,
454,11,373,4,0,
454,11,374,4,0,
454,11,389,1,31,
454,11,398,1,49,
454,11,398,4,0,
454,11,404,4,0,
454,11,411,4,0,
454,11,416,4,0,
454,11,417,1,41,
454,11,426,1,29,
454,11,444,4,0,
454,11,474,1,36,
454,11,474,4,0,
454,11,482,4,0,
454,11,490,4,0,
454,11,496,4,0,
454,11,514,4,0,
454,11,523,4,0,
454,11,526,4,0,
454,14,8,3,0,
454,14,9,3,0,
454,14,14,4,0,
454,14,15,4,0,
454,14,40,1,1,3
454,14,40,1,8,
454,14,63,4,0,
454,14,67,3,0,
454,14,70,4,0,
454,14,89,4,0,
454,14,91,4,0,
454,14,92,4,0,
454,14,104,4,0,
454,14,156,4,0,
454,14,157,4,0,
454,14,162,3,0,
454,14,164,4,0,
454,14,168,4,0,
454,14,173,3,0,
454,14,180,3,0,
454,14,182,4,0,
454,14,185,1,17,
454,14,188,1,54,
454,14,188,4,0,
454,14,189,1,1,2
454,14,189,1,3,
454,14,196,3,0,
454,14,207,1,24,
454,14,207,4,0,
454,14,213,4,0,
454,14,214,3,0,
454,14,216,4,0,
454,14,218,4,0,
454,14,228,1,15,
454,14,237,4,0,
454,14,240,4,0,
454,14,241,4,0,
454,14,247,4,0,
454,14,249,4,0,
454,14,259,4,0,
454,14,260,1,62,
454,14,263,4,0,
454,14,269,1,10,
454,14,269,4,0,
454,14,270,3,0,
454,14,272,3,0,
454,14,279,1,22,
454,14,280,4,0,
454,14,282,3,0,
454,14,289,3,0,
454,14,310,1,1,1
454,14,317,4,0,
454,14,339,4,0,
454,14,340,3,0,
454,14,371,4,0,
454,14,373,4,0,
454,14,374,4,0,
454,14,389,1,31,
454,14,398,1,49,
454,14,398,4,0,
454,14,399,3,0,
454,14,404,4,0,
454,14,409,3,0,
454,14,411,4,0,
454,14,416,4,0,
454,14,417,1,41,
454,14,426,1,29,
454,14,441,3,0,
454,14,444,4,0,
454,14,474,1,36,
454,14,474,4,0,
454,14,482,4,0,
454,14,490,4,0,
454,14,492,3,0,
454,14,496,4,0,
454,14,514,4,0,
454,14,523,4,0,
454,14,526,4,0,
454,14,530,3,0,
454,15,14,4,0,
454,15,15,4,0,
454,15,40,1,1,3
454,15,40,1,8,
454,15,63,4,0,
454,15,70,4,0,
454,15,89,4,0,
454,15,91,4,0,
454,15,92,4,0,
454,15,104,4,0,
454,15,156,4,0,
454,15,157,4,0,
454,15,164,4,0,
454,15,168,4,0,
454,15,182,4,0,
454,15,185,1,17,
454,15,188,1,54,
454,15,188,4,0,
454,15,189,1,1,2
454,15,189,1,3,
454,15,207,1,24,
454,15,207,4,0,
454,15,213,4,0,
454,15,214,4,0,
454,15,216,4,0,
454,15,218,4,0,
454,15,228,1,15,
454,15,237,4,0,
454,15,240,4,0,
454,15,241,4,0,
454,15,247,4,0,
454,15,249,4,0,
454,15,259,4,0,
454,15,260,1,62,
454,15,263,4,0,
454,15,269,1,10,
454,15,269,4,0,
454,15,279,1,22,
454,15,280,4,0,
454,15,310,1,1,1
454,15,317,4,0,
454,15,339,4,0,
454,15,371,4,0,
454,15,373,4,0,
454,15,374,4,0,
454,15,389,1,31,
454,15,398,1,49,
454,15,398,4,0,
454,15,399,4,0,
454,15,404,4,0,
454,15,411,4,0,
454,15,416,4,0,
454,15,417,1,41,
454,15,426,1,29,
454,15,444,4,0,
454,15,474,1,36,
454,15,474,4,0,
454,15,482,4,0,
454,15,490,4,0,
454,15,496,4,0,
454,15,514,4,0,
454,15,523,4,0,
454,15,562,1,58,
454,15,590,4,0,
454,15,612,4,0,
454,16,8,3,0,
454,16,9,3,0,
454,16,14,4,0,
454,16,15,4,0,
454,16,40,1,1,3
454,16,40,1,8,1
454,16,63,4,0,
454,16,67,3,0,
454,16,70,4,0,
454,16,89,4,0,
454,16,91,4,0,
454,16,92,4,0,
454,16,104,4,0,
454,16,156,4,0,
454,16,157,4,0,
454,16,162,3,0,
454,16,164,4,0,
454,16,168,4,0,
454,16,173,3,0,
454,16,180,3,0,
454,16,182,4,0,
454,16,185,1,17,1
454,16,188,1,54,1
454,16,188,4,0,
454,16,189,1,1,2
454,16,189,1,3,1
454,16,196,3,0,
454,16,207,1,24,1
454,16,207,4,0,
454,16,213,4,0,
454,16,214,4,0,
454,16,216,4,0,
454,16,218,4,0,
454,16,228,1,15,1
454,16,237,4,0,
454,16,240,4,0,
454,16,241,4,0,
454,16,247,4,0,
454,16,249,4,0,
454,16,259,4,0,
454,16,260,1,62,1
454,16,263,4,0,
454,16,264,3,0,
454,16,269,1,10,1
454,16,269,4,0,
454,16,270,3,0,
454,16,272,3,0,
454,16,279,1,22,1
454,16,280,4,0,
454,16,282,3,0,
454,16,289,3,0,
454,16,290,4,0,
454,16,310,1,1,1
454,16,317,4,0,
454,16,339,4,0,
454,16,340,3,0,
454,16,371,4,0,
454,16,373,4,0,
454,16,374,4,0,
454,16,389,1,31,1
454,16,398,1,49,1
454,16,398,4,0,
454,16,399,4,0,
454,16,404,4,0,
454,16,409,3,0,
454,16,411,4,0,
454,16,416,4,0,
454,16,417,1,41,1
454,16,426,1,29,1
454,16,441,3,0,
454,16,444,4,0,
454,16,474,1,36,1
454,16,474,4,0,
454,16,482,4,0,
454,16,490,4,0,
454,16,492,3,0,
454,16,496,4,0,
454,16,514,4,0,
454,16,523,4,0,
454,16,530,3,0,
454,16,562,1,58,1
454,16,590,4,0,
454,16,612,4,0,
454,17,14,4,0,
454,17,40,1,1,3
454,17,40,1,8,
454,17,63,4,0,
454,17,89,4,0,
454,17,92,4,0,
454,17,104,4,0,
454,17,156,4,0,
454,17,157,4,0,
454,17,164,4,0,
454,17,168,4,0,
454,17,182,4,0,
454,17,185,1,17,
454,17,188,1,54,
454,17,188,4,0,
454,17,189,1,1,2
454,17,189,1,3,
454,17,207,1,24,
454,17,207,4,0,
454,17,213,4,0,
454,17,214,4,0,
454,17,216,4,0,
454,17,218,4,0,
454,17,228,1,15,
454,17,237,4,0,
454,17,240,4,0,
454,17,241,4,0,
454,17,247,4,0,
454,17,259,4,0,
454,17,260,1,62,
454,17,263,4,0,
454,17,269,1,10,
454,17,269,4,0,
454,17,279,1,22,
454,17,280,4,0,
454,17,310,1,1,1
454,17,317,4,0,
454,17,339,4,0,
454,17,371,4,0,
454,17,373,4,0,
454,17,374,4,0,
454,17,389,1,31,
454,17,398,1,49,
454,17,398,4,0,
454,17,399,4,0,
454,17,404,4,0,
454,17,411,4,0,
454,17,416,4,0,
454,17,417,1,41,
454,17,426,1,29,
454,17,444,4,0,
454,17,474,1,36,
454,17,474,4,0,
454,17,482,4,0,
454,17,490,4,0,
454,17,496,4,0,
454,17,523,4,0,
454,17,526,4,0,
454,17,562,1,58,
454,17,590,4,0,
454,18,14,4,0,
454,18,40,1,1,3
454,18,40,1,8,
454,18,63,4,0,
454,18,89,4,0,
454,18,92,4,0,
454,18,104,4,0,
454,18,156,4,0,
454,18,157,4,0,
454,18,164,4,0,
454,18,168,4,0,
454,18,182,4,0,
454,18,185,1,17,
454,18,188,1,54,
454,18,188,4,0,
454,18,189,1,1,2
454,18,189,1,3,
454,18,207,1,24,
454,18,207,4,0,
454,18,213,4,0,
454,18,214,4,0,
454,18,216,4,0,
454,18,218,4,0,
454,18,228,1,15,
454,18,237,4,0,
454,18,240,4,0,
454,18,241,4,0,
454,18,247,4,0,
454,18,259,4,0,
454,18,260,1,62,
454,18,263,4,0,
454,18,269,1,10,
454,18,269,4,0,
454,18,279,1,22,
454,18,280,4,0,
454,18,310,1,1,1
454,18,317,4,0,
454,18,339,4,0,
454,18,371,4,0,
454,18,373,4,0,
454,18,374,4,0,
454,18,389,1,31,
454,18,398,1,49,
454,18,398,4,0,
454,18,399,4,0,
454,18,404,4,0,
454,18,411,4,0,
454,18,416,4,0,
454,18,417,1,41,
454,18,426,1,29,
454,18,444,4,0,
454,18,474,1,36,
454,18,474,4,0,
454,18,482,4,0,
454,18,490,4,0,
454,18,496,4,0,
454,18,523,4,0,
454,18,526,4,0,
454,18,562,1,58,
454,18,590,4,0,
455,8,14,4,0,
455,8,15,4,0,
455,8,20,1,1,1
455,8,21,2,0,
455,8,22,1,11,
455,8,44,1,7,
455,8,63,4,0,
455,8,73,2,0,
455,8,74,1,1,2
455,8,75,2,0,
455,8,76,4,0,
455,8,78,2,0,
455,8,79,2,0,
455,8,92,4,0,
455,8,104,4,0,
455,8,148,4,0,
455,8,156,4,0,
455,8,164,4,0,
455,8,168,4,0,
455,8,182,4,0,
455,8,185,1,27,
455,8,188,4,0,
455,8,202,4,0,
455,8,203,4,0,
455,8,207,4,0,
455,8,213,4,0,
455,8,214,4,0,
455,8,216,4,0,
455,8,218,4,0,
455,8,230,1,17,
455,8,235,2,0,
455,8,237,4,0,
455,8,241,4,0,
455,8,242,1,37,
455,8,254,1,31,1
455,8,255,1,31,2
455,8,256,1,31,3
455,8,263,4,0,
455,8,275,1,21,
455,8,290,4,0,
455,8,331,4,0,
455,8,345,2,0,
455,8,363,4,0,
455,8,371,4,0,
455,8,374,4,0,
455,8,378,1,41,
455,8,388,2,0,
455,8,412,4,0,
455,8,416,4,0,
455,8,438,1,47,
455,8,445,4,0,
455,8,447,4,0,
455,9,14,4,0,
455,9,15,4,0,
455,9,20,1,1,1
455,9,21,2,0,
455,9,22,1,11,
455,9,44,1,7,
455,9,63,4,0,
455,9,73,2,0,
455,9,74,1,1,2
455,9,75,2,0,
455,9,76,4,0,
455,9,78,2,0,
455,9,79,2,0,
455,9,92,4,0,
455,9,104,4,0,
455,9,148,4,0,
455,9,156,4,0,
455,9,164,4,0,
455,9,168,4,0,
455,9,173,3,0,
455,9,182,4,0,
455,9,185,1,27,
455,9,188,4,0,
455,9,189,3,0,
455,9,202,4,0,
455,9,203,4,0,
455,9,207,4,0,
455,9,210,3,0,
455,9,213,4,0,
455,9,214,4,0,
455,9,216,4,0,
455,9,218,4,0,
455,9,230,1,17,
455,9,235,2,0,
455,9,235,3,0,
455,9,237,4,0,
455,9,241,4,0,
455,9,242,1,37,
455,9,254,1,31,1
455,9,255,1,31,2
455,9,256,1,31,3
455,9,263,4,0,
455,9,275,1,21,
455,9,282,3,0,
455,9,290,4,0,
455,9,331,4,0,
455,9,345,2,0,
455,9,363,4,0,
455,9,371,4,0,
455,9,374,4,0,
455,9,378,1,41,
455,9,380,3,0,
455,9,388,2,0,
455,9,402,3,0,
455,9,412,4,0,
455,9,416,4,0,
455,9,438,1,47,
455,9,445,4,0,
455,9,447,4,0,
455,10,14,4,0,
455,10,15,4,0,
455,10,20,1,1,1
455,10,21,2,0,
455,10,22,1,11,
455,10,44,1,7,
455,10,63,4,0,
455,10,73,2,0,
455,10,74,1,1,2
455,10,75,2,0,
455,10,76,4,0,
455,10,78,2,0,
455,10,79,2,0,
455,10,92,4,0,
455,10,104,4,0,
455,10,148,4,0,
455,10,156,4,0,
455,10,164,4,0,
455,10,168,4,0,
455,10,173,3,0,
455,10,182,4,0,
455,10,185,1,27,
455,10,188,4,0,
455,10,189,3,0,
455,10,202,4,0,
455,10,203,4,0,
455,10,207,4,0,
455,10,210,3,0,
455,10,213,4,0,
455,10,214,4,0,
455,10,216,4,0,
455,10,218,4,0,
455,10,230,1,17,
455,10,235,2,0,
455,10,235,3,0,
455,10,237,4,0,
455,10,241,4,0,
455,10,242,1,37,
455,10,254,1,31,1
455,10,255,1,31,2
455,10,256,1,31,3
455,10,263,4,0,
455,10,275,1,21,
455,10,282,3,0,
455,10,290,4,0,
455,10,331,4,0,
455,10,345,2,0,
455,10,363,4,0,
455,10,371,4,0,
455,10,374,4,0,
455,10,378,1,41,
455,10,380,3,0,
455,10,388,2,0,
455,10,388,3,0,
455,10,402,3,0,
455,10,412,4,0,
455,10,416,4,0,
455,10,438,1,47,
455,10,445,4,0,
455,10,447,4,0,
455,10,450,3,0,
455,11,14,4,0,
455,11,15,4,0,
455,11,20,1,1,1
455,11,21,2,0,
455,11,22,1,11,
455,11,44,1,7,
455,11,63,4,0,
455,11,73,2,0,
455,11,74,1,1,2
455,11,75,2,0,
455,11,76,4,0,
455,11,78,2,0,
455,11,79,2,0,
455,11,92,4,0,
455,11,104,4,0,
455,11,148,4,0,
455,11,156,4,0,
455,11,164,4,0,
455,11,168,4,0,
455,11,182,4,0,
455,11,185,1,27,
455,11,188,4,0,
455,11,202,2,0,
455,11,207,4,0,
455,11,213,4,0,
455,11,216,4,0,
455,11,218,4,0,
455,11,230,1,17,
455,11,235,2,0,
455,11,237,4,0,
455,11,241,4,0,
455,11,242,1,41,
455,11,254,1,37,1
455,11,255,1,37,2
455,11,256,1,37,3
455,11,263,4,0,
455,11,275,1,21,
455,11,320,2,0,
455,11,345,2,0,
455,11,371,4,0,
455,11,374,4,0,
455,11,378,1,47,
455,11,388,2,0,
455,11,412,4,0,
455,11,416,4,0,
455,11,438,1,51,
455,11,447,4,0,
455,11,476,2,0,
455,11,496,4,0,
455,11,536,1,31,
455,14,14,4,0,
455,14,15,4,0,
455,14,20,1,1,1
455,14,20,3,0,
455,14,21,2,0,
455,14,22,1,11,
455,14,44,1,7,
455,14,63,4,0,
455,14,73,2,0,
455,14,74,1,1,2
455,14,75,2,0,
455,14,76,4,0,
455,14,78,2,0,
455,14,79,2,0,
455,14,92,4,0,
455,14,104,4,0,
455,14,148,4,0,
455,14,156,4,0,
455,14,164,4,0,
455,14,168,4,0,
455,14,173,3,0,
455,14,182,4,0,
455,14,185,1,27,
455,14,188,4,0,
455,14,202,2,0,
455,14,202,3,0,
455,14,207,4,0,
455,14,213,4,0,
455,14,214,3,0,
455,14,216,4,0,
455,14,218,4,0,
455,14,230,1,17,
455,14,235,2,0,
455,14,235,3,0,
455,14,237,4,0,
455,14,241,4,0,
455,14,242,1,41,
455,14,254,1,37,1
455,14,255,1,37,2
455,14,256,1,37,3
455,14,263,4,0,
455,14,275,1,21,
455,14,282,3,0,
455,14,320,2,0,
455,14,345,2,0,
455,14,371,4,0,
455,14,374,4,0,
455,14,378,1,47,
455,14,380,3,0,
455,14,388,2,0,
455,14,388,3,0,
455,14,402,3,0,
455,14,412,4,0,
455,14,416,4,0,
455,14,438,1,51,
455,14,447,4,0,
455,14,450,3,0,
455,14,476,2,0,
455,14,496,4,0,
455,14,536,1,31,
455,15,14,4,0,
455,15,15,4,0,
455,15,20,1,1,1
455,15,21,2,0,
455,15,22,1,11,
455,15,44,1,7,
455,15,63,4,0,
455,15,73,2,0,
455,15,74,1,1,2
455,15,75,2,0,
455,15,76,4,0,
455,15,78,2,0,
455,15,79,2,0,
455,15,92,4,0,
455,15,104,4,0,
455,15,148,4,0,
455,15,156,4,0,
455,15,164,4,0,
455,15,168,4,0,
455,15,182,4,0,
455,15,185,1,27,
455,15,188,4,0,
455,15,202,2,0,
455,15,207,4,0,
455,15,213,4,0,
455,15,214,4,0,
455,15,216,4,0,
455,15,218,4,0,
455,15,230,1,17,
455,15,235,2,0,
455,15,237,4,0,
455,15,241,4,0,
455,15,242,1,41,
455,15,254,1,37,1
455,15,255,1,37,2
455,15,256,1,37,3
455,15,263,4,0,
455,15,267,4,0,
455,15,275,1,21,
455,15,320,2,0,
455,15,345,2,0,
455,15,371,4,0,
455,15,374,4,0,
455,15,378,1,47,
455,15,388,2,0,
455,15,412,4,0,
455,15,416,4,0,
455,15,438,1,50,
455,15,447,4,0,
455,15,476,2,0,
455,15,496,4,0,
455,15,536,1,31,
455,15,590,4,0,
455,15,611,4,0,
455,16,14,4,0,
455,16,15,4,0,
455,16,20,1,1,1
455,16,20,3,0,
455,16,21,2,0,
455,16,22,1,11,1
455,16,44,1,7,1
455,16,63,4,0,
455,16,73,2,0,
455,16,74,1,1,2
455,16,75,2,0,
455,16,76,4,0,
455,16,78,2,0,
455,16,79,2,0,
455,16,92,4,0,
455,16,104,4,0,
455,16,148,4,0,
455,16,156,4,0,
455,16,164,4,0,
455,16,168,4,0,
455,16,173,3,0,
455,16,182,4,0,
455,16,185,1,27,1
455,16,188,4,0,
455,16,202,2,0,
455,16,202,3,0,
455,16,207,4,0,
455,16,213,4,0,
455,16,214,4,0,
455,16,216,4,0,
455,16,218,4,0,
455,16,230,1,17,1
455,16,235,2,0,
455,16,235,3,0,
455,16,237,4,0,
455,16,241,4,0,
455,16,242,1,41,1
455,16,254,1,37,1
455,16,255,1,37,2
455,16,256,1,37,3
455,16,263,4,0,
455,16,267,4,0,
455,16,275,1,21,1
455,16,282,3,0,
455,16,290,4,0,
455,16,320,2,0,
455,16,345,2,0,
455,16,371,4,0,
455,16,374,4,0,
455,16,378,1,47,1
455,16,380,3,0,
455,16,388,2,0,
455,16,388,3,0,
455,16,402,3,0,
455,16,412,4,0,
455,16,416,4,0,
455,16,438,1,50,1
455,16,447,4,0,
455,16,450,3,0,
455,16,476,2,0,
455,16,496,4,0,
455,16,536,1,31,1
455,16,590,4,0,
455,16,611,4,0,
455,17,14,4,0,
455,17,20,1,1,1
455,17,21,2,0,
455,17,22,1,11,
455,17,44,1,7,
455,17,63,4,0,
455,17,73,2,0,
455,17,74,1,1,2
455,17,75,2,0,
455,17,76,4,0,
455,17,78,2,0,
455,17,79,2,0,
455,17,92,4,0,
455,17,104,4,0,
455,17,156,4,0,
455,17,164,4,0,
455,17,168,4,0,
455,17,182,4,0,
455,17,185,1,27,
455,17,188,4,0,
455,17,202,2,0,
455,17,207,4,0,
455,17,213,4,0,
455,17,214,4,0,
455,17,216,4,0,
455,17,218,4,0,
455,17,230,1,17,
455,17,235,2,0,
455,17,237,4,0,
455,17,241,4,0,
455,17,242,1,41,
455,17,254,1,37,1
455,17,255,1,37,2
455,17,256,1,37,3
455,17,263,4,0,
455,17,267,4,0,
455,17,275,1,21,
455,17,320,2,0,
455,17,345,2,0,
455,17,371,4,0,
455,17,374,4,0,
455,17,378,1,47,
455,17,388,2,0,
455,17,412,4,0,
455,17,416,4,0,
455,17,438,1,50,
455,17,447,4,0,
455,17,476,2,0,
455,17,496,4,0,
455,17,536,1,31,
455,17,590,4,0,
455,17,611,4,0,
455,18,14,4,0,
455,18,20,1,1,1
455,18,21,2,0,
455,18,22,1,11,
455,18,44,1,7,
455,18,63,4,0,
455,18,73,2,0,
455,18,74,1,1,2
455,18,75,2,0,
455,18,76,4,0,
455,18,78,2,0,
455,18,79,2,0,
455,18,92,4,0,
455,18,104,4,0,
455,18,156,4,0,
455,18,164,4,0,
455,18,168,4,0,
455,18,182,4,0,
455,18,185,1,27,
455,18,188,4,0,
455,18,202,2,0,
455,18,207,4,0,
455,18,213,4,0,
455,18,214,4,0,
455,18,216,4,0,
455,18,218,4,0,
455,18,230,1,17,
455,18,235,2,0,
455,18,237,4,0,
455,18,241,4,0,
455,18,242,1,41,
455,18,254,1,37,1
455,18,255,1,37,2
455,18,256,1,37,3
455,18,263,4,0,
455,18,267,4,0,
455,18,275,1,21,
455,18,320,2,0,
455,18,345,2,0,
455,18,371,4,0,
455,18,374,4,0,
455,18,378,1,47,
455,18,388,2,0,
455,18,412,4,0,
455,18,416,4,0,
455,18,438,1,50,
455,18,447,4,0,
455,18,476,2,0,
455,18,491,2,0,
455,18,496,4,0,
455,18,536,1,31,
455,18,590,4,0,
455,18,611,4,0,
456,8,1,1,1,
456,8,16,1,17,
456,8,55,1,6,
456,8,57,4,0,
456,8,58,4,0,
456,8,59,4,0,
456,8,60,2,0,
456,8,92,4,0,
456,8,97,2,0,
456,8,104,4,0,
456,8,127,4,0,
456,8,148,4,0,
456,8,150,2,0,
456,8,156,4,0,
456,8,164,4,0,
456,8,175,2,0,
456,8,182,4,0,
456,8,186,2,0,
456,8,203,4,0,
456,8,204,2,0,
456,8,207,4,0,
456,8,213,1,10,
456,8,213,4,0,
456,8,214,4,0,
456,8,216,4,0,
456,8,218,4,0,
456,8,219,1,29,
456,8,219,4,0,
456,8,237,4,0,
456,8,240,1,13,
456,8,240,4,0,
456,8,244,4,0,
456,8,250,1,38,
456,8,258,4,0,
456,8,263,4,0,
456,8,290,4,0,
456,8,318,1,49,
456,8,318,4,0,
456,8,321,2,0,
456,8,340,1,45,
456,8,352,1,22,
456,8,352,4,0,
456,8,362,4,0,
456,8,363,4,0,
456,8,369,1,42,
456,8,369,4,0,
456,8,371,4,0,
456,8,392,1,33,
456,8,401,2,0,
456,8,432,4,0,
456,8,445,1,26,
456,8,445,4,0,
456,9,1,1,1,
456,9,16,1,17,
456,9,55,1,6,
456,9,57,4,0,
456,9,58,4,0,
456,9,59,4,0,
456,9,60,2,0,
456,9,92,4,0,
456,9,97,2,0,
456,9,104,4,0,
456,9,127,4,0,
456,9,129,3,0,
456,9,148,4,0,
456,9,150,2,0,
456,9,156,4,0,
456,9,164,4,0,
456,9,173,3,0,
456,9,175,2,0,
456,9,182,4,0,
456,9,186,2,0,
456,9,196,3,0,
456,9,203,4,0,
456,9,204,2,0,
456,9,207,4,0,
456,9,213,1,10,
456,9,213,4,0,
456,9,214,4,0,
456,9,216,4,0,
456,9,218,4,0,
456,9,219,1,29,
456,9,219,4,0,
456,9,237,4,0,
456,9,239,3,0,
456,9,240,1,13,
456,9,240,4,0,
456,9,244,4,0,
456,9,250,1,38,
456,9,258,4,0,
456,9,263,4,0,
456,9,290,4,0,
456,9,291,3,0,
456,9,314,3,0,
456,9,318,1,49,
456,9,318,4,0,
456,9,321,2,0,
456,9,340,1,45,
456,9,340,3,0,
456,9,352,1,22,
456,9,352,4,0,
456,9,362,4,0,
456,9,363,4,0,
456,9,369,1,42,
456,9,369,4,0,
456,9,371,4,0,
456,9,392,1,33,
456,9,401,2,0,
456,9,401,3,0,
456,9,432,4,0,
456,9,445,1,26,
456,9,445,4,0,
456,9,466,3,0,
456,10,1,1,1,
456,10,16,1,17,
456,10,55,1,6,
456,10,57,4,0,
456,10,58,4,0,
456,10,59,4,0,
456,10,60,2,0,
456,10,92,4,0,
456,10,97,2,0,
456,10,104,4,0,
456,10,127,4,0,
456,10,129,3,0,
456,10,148,4,0,
456,10,150,2,0,
456,10,156,4,0,
456,10,164,4,0,
456,10,173,3,0,
456,10,175,2,0,
456,10,182,4,0,
456,10,186,2,0,
456,10,196,3,0,
456,10,203,4,0,
456,10,204,2,0,
456,10,207,4,0,
456,10,213,1,10,
456,10,213,4,0,
456,10,214,4,0,
456,10,216,4,0,
456,10,218,4,0,
456,10,219,1,29,
456,10,219,4,0,
456,10,237,4,0,
456,10,239,3,0,
456,10,240,1,13,
456,10,240,4,0,
456,10,244,4,0,
456,10,250,1,38,
456,10,250,4,0,
456,10,258,4,0,
456,10,263,4,0,
456,10,290,4,0,
456,10,291,3,0,
456,10,314,3,0,
456,10,318,1,49,
456,10,318,4,0,
456,10,321,2,0,
456,10,340,1,45,
456,10,340,3,0,
456,10,352,1,22,
456,10,352,4,0,
456,10,362,4,0,
456,10,363,4,0,
456,10,366,3,0,
456,10,369,1,42,
456,10,369,4,0,
456,10,371,4,0,
456,10,392,1,33,
456,10,401,2,0,
456,10,401,3,0,
456,10,445,1,26,
456,10,445,4,0,
456,10,466,3,0,
456,11,1,1,1,
456,11,16,1,17,
456,11,55,1,6,
456,11,57,4,0,
456,11,58,4,0,
456,11,59,4,0,
456,11,60,2,0,
456,11,62,2,0,
456,11,92,4,0,
456,11,97,2,0,
456,11,104,4,0,
456,11,127,4,0,
456,11,148,4,0,
456,11,150,2,0,
456,11,156,4,0,
456,11,164,4,0,
456,11,175,2,0,
456,11,182,4,0,
456,11,186,2,0,
456,11,204,2,0,
456,11,207,4,0,
456,11,213,1,10,
456,11,213,4,0,
456,11,216,4,0,
456,11,218,4,0,
456,11,219,1,29,
456,11,219,4,0,
456,11,237,4,0,
456,11,240,1,13,
456,11,240,4,0,
456,11,244,4,0,
456,11,250,1,38,
456,11,258,4,0,
456,11,263,4,0,
456,11,291,4,0,
456,11,318,1,49,
456,11,321,2,0,
456,11,324,2,0,
456,11,340,1,45,
456,11,352,1,22,
456,11,362,2,0,
456,11,369,1,42,
456,11,369,4,0,
456,11,371,4,0,
456,11,392,1,33,
456,11,401,2,0,
456,11,445,1,26,
456,11,487,1,54,
456,11,496,4,0,
456,11,503,4,0,
456,14,1,1,1,
456,14,16,1,17,
456,14,55,1,6,
456,14,57,4,0,
456,14,58,4,0,
456,14,59,4,0,
456,14,60,2,0,
456,14,62,2,0,
456,14,92,4,0,
456,14,97,2,0,
456,14,104,4,0,
456,14,127,4,0,
456,14,148,4,0,
456,14,150,2,0,
456,14,156,4,0,
456,14,164,4,0,
456,14,173,3,0,
456,14,175,2,0,
456,14,182,4,0,
456,14,186,2,0,
456,14,196,3,0,
456,14,204,2,0,
456,14,207,4,0,
456,14,213,1,10,
456,14,213,4,0,
456,14,214,3,0,
456,14,216,4,0,
456,14,218,4,0,
456,14,219,1,29,
456,14,219,4,0,
456,14,237,4,0,
456,14,240,1,13,
456,14,240,4,0,
456,14,244,4,0,
456,14,250,1,38,
456,14,258,4,0,
456,14,263,4,0,
456,14,291,4,0,
456,14,318,1,49,
456,14,321,2,0,
456,14,324,2,0,
456,14,324,3,0,
456,14,340,1,45,
456,14,340,3,0,
456,14,352,1,22,
456,14,362,2,0,
456,14,366,3,0,
456,14,369,1,42,
456,14,369,4,0,
456,14,371,4,0,
456,14,392,1,33,
456,14,401,2,0,
456,14,401,3,0,
456,14,445,1,26,
456,14,487,1,54,
456,14,496,4,0,
456,14,503,4,0,
456,15,1,1,1,
456,15,16,1,17,
456,15,55,1,6,
456,15,57,4,0,
456,15,58,4,0,
456,15,59,4,0,
456,15,60,2,0,
456,15,62,2,0,
456,15,92,4,0,
456,15,97,2,0,
456,15,104,4,0,
456,15,127,4,0,
456,15,148,4,0,
456,15,150,2,0,
456,15,156,4,0,
456,15,164,4,0,
456,15,175,2,0,
456,15,182,4,0,
456,15,186,2,0,
456,15,204,2,0,
456,15,207,4,0,
456,15,213,1,10,
456,15,213,4,0,
456,15,214,4,0,
456,15,216,4,0,
456,15,218,4,0,
456,15,219,1,29,
456,15,219,4,0,
456,15,237,4,0,
456,15,240,1,13,
456,15,240,4,0,
456,15,244,4,0,
456,15,250,1,38,
456,15,258,4,0,
456,15,263,4,0,
456,15,267,4,0,
456,15,318,1,49,
456,15,321,2,0,
456,15,324,2,0,
456,15,340,1,45,
456,15,352,1,22,
456,15,362,2,0,
456,15,369,1,42,
456,15,369,4,0,
456,15,371,4,0,
456,15,392,1,33,
456,15,401,2,0,
456,15,445,1,26,
456,15,487,1,54,
456,15,496,4,0,
456,15,503,4,0,
456,15,590,4,0,
456,16,1,1,1,1
456,16,16,1,17,1
456,16,55,1,6,1
456,16,57,4,0,
456,16,58,4,0,
456,16,59,4,0,
456,16,60,2,0,
456,16,62,2,0,
456,16,92,4,0,
456,16,97,2,0,
456,16,104,4,0,
456,16,127,4,0,
456,16,148,4,0,
456,16,150,2,0,
456,16,156,4,0,
456,16,164,4,0,
456,16,173,3,0,
456,16,175,2,0,
456,16,182,4,0,
456,16,186,2,0,
456,16,196,3,0,
456,16,204,2,0,
456,16,207,4,0,
456,16,213,1,10,1
456,16,213,4,0,
456,16,214,4,0,
456,16,216,4,0,
456,16,218,4,0,
456,16,219,1,29,1
456,16,219,4,0,
456,16,237,4,0,
456,16,240,1,13,1
456,16,240,4,0,
456,16,244,4,0,
456,16,250,1,38,1
456,16,258,4,0,
456,16,263,4,0,
456,16,267,4,0,
456,16,290,4,0,
456,16,291,4,0,
456,16,318,1,49,1
456,16,321,2,0,
456,16,324,2,0,
456,16,324,3,0,
456,16,340,1,45,1
456,16,340,3,0,
456,16,352,1,22,1
456,16,352,3,0,
456,16,362,2,0,
456,16,366,3,0,
456,16,369,1,42,1
456,16,369,4,0,
456,16,371,4,0,
456,16,392,1,33,1
456,16,401,2,0,
456,16,401,3,0,
456,16,445,1,26,1
456,16,487,1,54,1
456,16,496,4,0,
456,16,503,4,0,
456,16,590,4,0,
456,17,1,1,1,
456,17,16,1,17,
456,17,55,1,6,
456,17,57,4,0,
456,17,58,4,0,
456,17,59,4,0,
456,17,60,2,0,
456,17,62,2,0,
456,17,92,4,0,
456,17,97,2,0,
456,17,104,4,0,
456,17,127,4,0,
456,17,150,2,0,
456,17,156,4,0,
456,17,164,4,0,
456,17,175,2,0,
456,17,182,4,0,
456,17,186,2,0,
456,17,204,2,0,
456,17,207,4,0,
456,17,213,1,10,
456,17,213,4,0,
456,17,214,4,0,
456,17,216,4,0,
456,17,218,4,0,
456,17,219,1,29,
456,17,219,4,0,
456,17,237,4,0,
456,17,240,1,13,
456,17,240,4,0,
456,17,244,4,0,
456,17,250,1,38,
456,17,258,4,0,
456,17,263,4,0,
456,17,267,4,0,
456,17,318,1,49,
456,17,321,2,0,
456,17,324,2,0,
456,17,340,1,45,
456,17,352,1,22,
456,17,362,2,0,
456,17,369,1,42,
456,17,369,4,0,
456,17,371,4,0,
456,17,392,1,33,
456,17,401,2,0,
456,17,445,1,26,
456,17,487,1,54,
456,17,496,4,0,
456,17,503,4,0,
456,17,590,4,0,
456,18,1,1,1,
456,18,16,1,17,
456,18,55,1,6,
456,18,57,4,0,
456,18,58,4,0,
456,18,59,4,0,
456,18,60,2,0,
456,18,62,2,0,
456,18,92,4,0,
456,18,97,2,0,
456,18,104,4,0,
456,18,109,2,0,
456,18,127,4,0,
456,18,150,2,0,
456,18,156,4,0,
456,18,164,4,0,
456,18,175,2,0,
456,18,182,4,0,
456,18,186,2,0,
456,18,204,2,0,
456,18,207,4,0,
456,18,213,1,10,
456,18,213,4,0,
456,18,214,4,0,
456,18,216,4,0,
456,18,218,4,0,
456,18,219,1,29,
456,18,219,4,0,
456,18,237,4,0,
456,18,240,1,13,
456,18,240,4,0,
456,18,244,4,0,
456,18,250,1,38,
456,18,258,4,0,
456,18,263,4,0,
456,18,267,4,0,
456,18,318,1,49,
456,18,321,2,0,
456,18,324,2,0,
456,18,340,1,45,
456,18,352,1,22,
456,18,362,2,0,
456,18,369,1,42,
456,18,369,4,0,
456,18,371,4,0,
456,18,392,1,33,
456,18,401,2,0,
456,18,445,1,26,
456,18,487,1,54,
456,18,496,4,0,
456,18,503,4,0,
456,18,590,4,0,
457,8,1,1,1,1
457,8,16,1,17,
457,8,55,1,1,2
457,8,55,1,6,
457,8,57,4,0,
457,8,58,4,0,
457,8,59,4,0,
457,8,63,4,0,
457,8,92,4,0,
457,8,104,4,0,
457,8,127,4,0,
457,8,148,4,0,
457,8,156,4,0,
457,8,164,4,0,
457,8,182,4,0,
457,8,203,4,0,
457,8,207,4,0,
457,8,213,1,1,3
457,8,213,1,10,
457,8,213,4,0,
457,8,214,4,0,
457,8,216,4,0,
457,8,218,4,0,
457,8,219,1,29,
457,8,219,4,0,
457,8,237,4,0,
457,8,240,1,13,
457,8,240,4,0,
457,8,244,4,0,
457,8,250,1,42,
457,8,258,4,0,
457,8,263,4,0,
457,8,290,4,0,
457,8,318,1,59,
457,8,318,4,0,
457,8,340,1,53,
457,8,352,1,22,
457,8,352,4,0,
457,8,362,4,0,
457,8,363,4,0,
457,8,369,1,48,
457,8,369,4,0,
457,8,371,4,0,
457,8,392,1,35,
457,8,416,4,0,
457,8,432,4,0,
457,8,445,1,26,
457,8,445,4,0,
457,9,1,1,1,1
457,9,16,1,17,
457,9,55,1,1,2
457,9,55,1,6,
457,9,57,4,0,
457,9,58,4,0,
457,9,59,4,0,
457,9,63,4,0,
457,9,92,4,0,
457,9,104,4,0,
457,9,127,4,0,
457,9,129,3,0,
457,9,148,4,0,
457,9,156,4,0,
457,9,164,4,0,
457,9,173,3,0,
457,9,182,4,0,
457,9,196,3,0,
457,9,203,4,0,
457,9,207,4,0,
457,9,213,1,1,3
457,9,213,1,10,
457,9,213,4,0,
457,9,214,4,0,
457,9,216,4,0,
457,9,218,4,0,
457,9,219,1,29,
457,9,219,4,0,
457,9,237,4,0,
457,9,239,3,0,
457,9,240,1,13,
457,9,240,4,0,
457,9,244,4,0,
457,9,250,1,42,
457,9,258,4,0,
457,9,263,4,0,
457,9,290,4,0,
457,9,291,3,0,
457,9,314,3,0,
457,9,318,1,59,
457,9,318,4,0,
457,9,340,1,53,
457,9,340,3,0,
457,9,352,1,22,
457,9,352,4,0,
457,9,362,4,0,
457,9,363,4,0,
457,9,369,1,48,
457,9,369,4,0,
457,9,371,4,0,
457,9,392,1,35,
457,9,401,3,0,
457,9,416,4,0,
457,9,432,4,0,
457,9,445,1,26,
457,9,445,4,0,
457,9,466,3,0,
457,10,1,1,1,1
457,10,16,1,17,
457,10,55,1,1,2
457,10,55,1,6,
457,10,57,4,0,
457,10,58,4,0,
457,10,59,4,0,
457,10,63,4,0,
457,10,92,4,0,
457,10,104,4,0,
457,10,127,4,0,
457,10,129,3,0,
457,10,148,4,0,
457,10,156,4,0,
457,10,164,4,0,
457,10,173,3,0,
457,10,182,4,0,
457,10,196,3,0,
457,10,203,4,0,
457,10,207,4,0,
457,10,213,1,1,3
457,10,213,1,10,
457,10,213,4,0,
457,10,214,4,0,
457,10,216,4,0,
457,10,218,4,0,
457,10,219,1,29,
457,10,219,4,0,
457,10,237,4,0,
457,10,239,3,0,
457,10,240,1,13,
457,10,240,4,0,
457,10,244,4,0,
457,10,250,1,42,
457,10,250,4,0,
457,10,258,4,0,
457,10,263,4,0,
457,10,290,4,0,
457,10,291,3,0,
457,10,314,3,0,
457,10,318,1,59,
457,10,318,4,0,
457,10,340,1,53,
457,10,340,3,0,
457,10,352,1,22,
457,10,352,4,0,
457,10,362,4,0,
457,10,363,4,0,
457,10,366,3,0,
457,10,369,1,48,
457,10,369,4,0,
457,10,371,4,0,
457,10,392,1,35,
457,10,401,3,0,
457,10,416,4,0,
457,10,445,1,26,
457,10,445,4,0,
457,10,466,3,0,
457,11,1,1,1,1
457,11,16,1,17,
457,11,55,1,1,2
457,11,55,1,6,
457,11,57,4,0,
457,11,58,4,0,
457,11,59,4,0,
457,11,63,4,0,
457,11,92,4,0,
457,11,104,4,0,
457,11,127,4,0,
457,11,148,4,0,
457,11,156,4,0,
457,11,164,4,0,
457,11,182,4,0,
457,11,207,4,0,
457,11,213,1,1,3
457,11,213,1,10,
457,11,213,4,0,
457,11,216,4,0,
457,11,218,4,0,
457,11,219,1,29,
457,11,219,4,0,
457,11,237,4,0,
457,11,240,1,13,
457,11,240,4,0,
457,11,244,4,0,
457,11,250,1,42,
457,11,258,4,0,
457,11,263,4,0,
457,11,291,4,0,
457,11,318,1,59,
457,11,340,1,53,
457,11,352,1,22,
457,11,369,1,48,
457,11,369,4,0,
457,11,371,4,0,
457,11,392,1,35,
457,11,416,4,0,
457,11,445,1,26,
457,11,487,1,66,
457,11,496,4,0,
457,11,503,4,0,
457,14,1,1,1,1
457,14,16,1,17,
457,14,55,1,1,2
457,14,55,1,6,
457,14,57,4,0,
457,14,58,4,0,
457,14,59,4,0,
457,14,63,4,0,
457,14,92,4,0,
457,14,104,4,0,
457,14,127,4,0,
457,14,148,4,0,
457,14,156,4,0,
457,14,164,4,0,
457,14,173,3,0,
457,14,182,4,0,
457,14,196,3,0,
457,14,207,4,0,
457,14,213,1,1,3
457,14,213,1,10,
457,14,213,4,0,
457,14,214,3,0,
457,14,216,4,0,
457,14,218,4,0,
457,14,219,1,29,
457,14,219,4,0,
457,14,237,4,0,
457,14,240,1,13,
457,14,240,4,0,
457,14,244,4,0,
457,14,250,1,42,
457,14,258,4,0,
457,14,263,4,0,
457,14,291,4,0,
457,14,318,1,59,
457,14,324,3,0,
457,14,340,1,53,
457,14,340,3,0,
457,14,352,1,22,
457,14,366,3,0,
457,14,369,1,48,
457,14,369,4,0,
457,14,371,4,0,
457,14,392,1,35,
457,14,401,3,0,
457,14,416,4,0,
457,14,445,1,26,
457,14,487,1,66,
457,14,496,4,0,
457,14,503,4,0,
457,15,1,1,1,3
457,15,16,1,1,2
457,15,16,1,17,
457,15,55,1,1,4
457,15,55,1,6,
457,15,57,4,0,
457,15,58,4,0,
457,15,59,4,0,
457,15,63,4,0,
457,15,92,4,0,
457,15,104,4,0,
457,15,127,4,0,
457,15,148,4,0,
457,15,156,4,0,
457,15,164,4,0,
457,15,182,4,0,
457,15,207,4,0,
457,15,213,1,1,5
457,15,213,1,10,
457,15,213,4,0,
457,15,214,4,0,
457,15,216,4,0,
457,15,218,4,0,
457,15,219,1,29,
457,15,219,4,0,
457,15,237,4,0,
457,15,240,1,13,
457,15,240,4,0,
457,15,244,4,0,
457,15,250,1,42,
457,15,258,4,0,
457,15,263,4,0,
457,15,318,1,59,
457,15,340,1,53,
457,15,352,1,22,
457,15,369,1,48,
457,15,369,4,0,
457,15,371,4,0,
457,15,392,1,35,
457,15,416,4,0,
457,15,445,1,26,
457,15,487,1,1,1
457,15,487,1,66,
457,15,496,4,0,
457,15,503,4,0,
457,15,590,4,0,
457,16,1,1,1,3
457,16,16,1,1,2
457,16,16,1,17,1
457,16,55,1,1,4
457,16,55,1,6,1
457,16,57,4,0,
457,16,58,4,0,
457,16,59,4,0,
457,16,63,4,0,
457,16,92,4,0,
457,16,104,4,0,
457,16,127,4,0,
457,16,148,4,0,
457,16,156,4,0,
457,16,164,4,0,
457,16,173,3,0,
457,16,182,4,0,
457,16,196,3,0,
457,16,207,4,0,
457,16,213,1,1,5
457,16,213,1,10,1
457,16,213,4,0,
457,16,214,4,0,
457,16,216,4,0,
457,16,218,4,0,
457,16,219,1,29,1
457,16,219,4,0,
457,16,237,4,0,
457,16,240,1,13,1
457,16,240,4,0,
457,16,244,4,0,
457,16,250,1,42,1
457,16,258,4,0,
457,16,263,4,0,
457,16,290,4,0,
457,16,291,4,0,
457,16,318,1,59,1
457,16,324,3,0,
457,16,340,1,53,1
457,16,340,3,0,
457,16,352,1,22,1
457,16,352,3,0,
457,16,366,3,0,
457,16,369,1,48,1
457,16,369,4,0,
457,16,371,4,0,
457,16,392,1,35,1
457,16,401,3,0,
457,16,416,4,0,
457,16,445,1,26,1
457,16,487,1,1,1
457,16,487,1,66,1
457,16,496,4,0,
457,16,503,4,0,
457,16,590,4,0,
457,17,1,1,1,3
457,17,16,1,1,2
457,17,16,1,17,
457,17,55,1,1,4
457,17,55,1,6,
457,17,57,4,0,
457,17,58,4,0,
457,17,59,4,0,
457,17,63,4,0,
457,17,92,4,0,
457,17,104,4,0,
457,17,127,4,0,
457,17,156,4,0,
457,17,164,4,0,
457,17,182,4,0,
457,17,207,4,0,
457,17,213,1,1,5
457,17,213,1,10,
457,17,213,4,0,
457,17,214,4,0,
457,17,216,4,0,
457,17,218,4,0,
457,17,219,1,29,
457,17,219,4,0,
457,17,237,4,0,
457,17,240,1,13,
457,17,240,4,0,
457,17,244,4,0,
457,17,250,1,42,
457,17,258,4,0,
457,17,263,4,0,
457,17,318,1,59,
457,17,340,1,53,
457,17,352,1,22,
457,17,369,1,48,
457,17,369,4,0,
457,17,371,4,0,
457,17,392,1,35,
457,17,416,4,0,
457,17,445,1,26,
457,17,487,1,1,1
457,17,487,1,66,
457,17,496,4,0,
457,17,503,4,0,
457,17,590,4,0,
457,18,1,1,1,3
457,18,16,1,1,2
457,18,16,1,17,
457,18,55,1,1,4
457,18,55,1,6,
457,18,57,4,0,
457,18,58,4,0,
457,18,59,4,0,
457,18,63,4,0,
457,18,92,4,0,
457,18,104,4,0,
457,18,127,4,0,
457,18,156,4,0,
457,18,164,4,0,
457,18,182,4,0,
457,18,207,4,0,
457,18,213,1,1,5
457,18,213,1,10,
457,18,213,4,0,
457,18,214,4,0,
457,18,216,4,0,
457,18,218,4,0,
457,18,219,1,29,
457,18,219,4,0,
457,18,237,4,0,
457,18,240,1,13,
457,18,240,4,0,
457,18,244,4,0,
457,18,250,1,42,
457,18,258,4,0,
457,18,263,4,0,
457,18,318,1,59,
457,18,340,1,53,
457,18,352,1,22,
457,18,369,1,48,
457,18,369,4,0,
457,18,371,4,0,
457,18,392,1,35,
457,18,416,4,0,
457,18,445,1,26,
457,18,487,1,1,1
457,18,487,1,66,
457,18,496,4,0,
457,18,503,4,0,
457,18,590,4,0,
458,8,17,1,22,
458,8,21,2,0,
458,8,29,1,13,
458,8,33,1,1,1
458,8,36,1,31,
458,8,48,1,4,
458,8,56,1,49,
458,8,56,2,0,
458,8,57,4,0,
458,8,58,4,0,
458,8,59,4,0,
458,8,61,1,10,
458,8,89,4,0,
458,8,92,4,0,
458,8,97,1,19,
458,8,104,4,0,
458,8,109,1,37,
458,8,114,2,0,
458,8,127,4,0,
458,8,145,1,1,2
458,8,150,2,0,
458,8,156,4,0,
458,8,157,2,0,
458,8,164,4,0,
458,8,182,4,0,
458,8,203,4,0,
458,8,207,4,0,
458,8,213,4,0,
458,8,214,4,0,
458,8,216,4,0,
458,8,218,4,0,
458,8,237,4,0,
458,8,239,2,0,
458,8,240,4,0,
458,8,243,2,0,
458,8,258,4,0,
458,8,263,4,0,
458,8,290,4,0,
458,8,300,2,0,
458,8,324,2,0,
458,8,332,4,0,
458,8,340,1,40,
458,8,346,2,0,
458,8,352,1,28,
458,8,352,4,0,
458,8,363,4,0,
458,8,392,1,46,
458,8,445,4,0,
458,9,17,1,22,
458,9,21,2,0,
458,9,29,1,13,
458,9,33,1,1,1
458,9,36,1,31,
458,9,48,1,4,
458,9,56,1,49,
458,9,56,2,0,
458,9,57,4,0,
458,9,58,4,0,
458,9,59,4,0,
458,9,61,1,10,
458,9,89,4,0,
458,9,92,4,0,
458,9,97,1,19,
458,9,104,4,0,
458,9,109,1,37,
458,9,114,2,0,
458,9,127,4,0,
458,9,129,3,0,
458,9,145,1,1,2
458,9,150,2,0,
458,9,156,4,0,
458,9,157,2,0,
458,9,164,4,0,
458,9,173,3,0,
458,9,182,4,0,
458,9,189,3,0,
458,9,196,3,0,
458,9,203,4,0,
458,9,207,4,0,
458,9,213,4,0,
458,9,214,4,0,
458,9,216,4,0,
458,9,218,4,0,
458,9,237,4,0,
458,9,239,2,0,
458,9,240,4,0,
458,9,243,2,0,
458,9,258,4,0,
458,9,263,4,0,
458,9,270,3,0,
458,9,290,4,0,
458,9,291,3,0,
458,9,300,2,0,
458,9,314,3,0,
458,9,324,2,0,
458,9,332,4,0,
458,9,340,1,40,
458,9,340,3,0,
458,9,346,2,0,
458,9,352,1,28,
458,9,352,4,0,
458,9,363,4,0,
458,9,392,1,46,
458,9,445,4,0,
458,10,17,1,22,
458,10,21,2,0,
458,10,29,1,13,
458,10,29,3,0,
458,10,33,1,1,1
458,10,36,1,31,
458,10,48,1,4,
458,10,56,1,49,
458,10,56,2,0,
458,10,57,4,0,
458,10,58,4,0,
458,10,59,4,0,
458,10,61,1,10,
458,10,89,4,0,
458,10,92,4,0,
458,10,97,1,19,
458,10,104,4,0,
458,10,109,1,37,
458,10,114,2,0,
458,10,127,4,0,
458,10,129,3,0,
458,10,145,1,1,2
458,10,150,2,0,
458,10,156,4,0,
458,10,157,2,0,
458,10,164,4,0,
458,10,173,3,0,
458,10,182,4,0,
458,10,189,3,0,
458,10,196,3,0,
458,10,203,4,0,
458,10,207,4,0,
458,10,213,4,0,
458,10,214,4,0,
458,10,216,4,0,
458,10,218,4,0,
458,10,237,4,0,
458,10,239,2,0,
458,10,240,4,0,
458,10,243,2,0,
458,10,250,4,0,
458,10,258,4,0,
458,10,263,4,0,
458,10,270,3,0,
458,10,290,4,0,
458,10,291,3,0,
458,10,300,2,0,
458,10,314,3,0,
458,10,324,2,0,
458,10,332,4,0,
458,10,340,1,40,
458,10,340,3,0,
458,10,346,2,0,
458,10,352,1,28,
458,10,352,4,0,
458,10,363,4,0,
458,10,392,1,46,
458,10,445,4,0,
458,11,17,1,22,
458,11,21,2,0,
458,11,29,1,13,
458,11,33,1,1,1
458,11,36,1,31,
458,11,48,1,4,
458,11,56,1,49,
458,11,56,2,0,
458,11,57,4,0,
458,11,58,4,0,
458,11,59,4,0,
458,11,61,1,10,
458,11,89,4,0,
458,11,92,4,0,
458,11,97,1,19,
458,11,104,4,0,
458,11,109,1,37,
458,11,114,2,0,
458,11,127,4,0,
458,11,133,2,0,
458,11,145,1,1,2
458,11,150,2,0,
458,11,156,4,0,
458,11,157,4,0,
458,11,164,4,0,
458,11,182,4,0,
458,11,207,4,0,
458,11,213,4,0,
458,11,216,4,0,
458,11,218,4,0,
458,11,237,4,0,
458,11,239,2,0,
458,11,240,4,0,
458,11,243,2,0,
458,11,258,4,0,
458,11,263,4,0,
458,11,291,4,0,
458,11,300,2,0,
458,11,324,2,0,
458,11,332,4,0,
458,11,340,1,40,
458,11,346,2,0,
458,11,352,1,28,
458,11,392,1,46,
458,11,469,2,0,
458,11,496,4,0,
458,11,503,4,0,
458,11,512,4,0,
458,11,523,4,0,
458,14,17,1,14,
458,14,21,2,0,
458,14,29,1,16,
458,14,33,1,1,1
458,14,36,1,27,
458,14,48,1,3,
458,14,56,1,49,
458,14,56,2,0,
458,14,57,4,0,
458,14,58,4,0,
458,14,59,4,0,
458,14,61,1,7,
458,14,89,4,0,
458,14,92,4,0,
458,14,97,1,32,
458,14,104,4,0,
458,14,109,1,11,
458,14,114,2,0,
458,14,127,4,0,
458,14,133,2,0,
458,14,145,1,1,2
458,14,150,2,0,
458,14,156,4,0,
458,14,157,4,0,
458,14,164,4,0,
458,14,173,3,0,
458,14,182,4,0,
458,14,196,3,0,
458,14,207,4,0,
458,14,213,4,0,
458,14,214,3,0,
458,14,216,4,0,
458,14,218,4,0,
458,14,237,4,0,
458,14,239,2,0,
458,14,240,4,0,
458,14,243,2,0,
458,14,258,4,0,
458,14,263,4,0,
458,14,270,3,0,
458,14,291,4,0,
458,14,300,2,0,
458,14,324,2,0,
458,14,324,3,0,
458,14,332,4,0,
458,14,340,1,46,
458,14,340,3,0,
458,14,346,2,0,
458,14,352,1,19,
458,14,392,1,39,
458,14,403,1,36,
458,14,469,1,23,
458,14,469,2,0,
458,14,496,4,0,
458,14,503,4,0,
458,14,512,4,0,
458,14,523,4,0,
458,15,17,1,14,
458,15,21,2,0,
458,15,29,1,16,
458,15,33,1,1,1
458,15,36,1,27,
458,15,48,1,3,
458,15,56,1,49,
458,15,56,2,0,
458,15,57,4,0,
458,15,58,4,0,
458,15,59,4,0,
458,15,61,1,7,
458,15,89,4,0,
458,15,92,4,0,
458,15,97,1,32,
458,15,104,4,0,
458,15,109,1,11,
458,15,114,2,0,
458,15,127,4,0,
458,15,133,2,0,
458,15,145,1,1,2
458,15,150,2,0,
458,15,156,4,0,
458,15,157,4,0,
458,15,164,4,0,
458,15,182,4,0,
458,15,207,4,0,
458,15,213,4,0,
458,15,214,4,0,
458,15,216,4,0,
458,15,218,4,0,
458,15,237,4,0,
458,15,239,2,0,
458,15,240,4,0,
458,15,243,2,0,
458,15,258,4,0,
458,15,263,4,0,
458,15,300,2,0,
458,15,324,2,0,
458,15,332,4,0,
458,15,340,1,46,
458,15,346,2,0,
458,15,352,1,19,
458,15,366,2,0,
458,15,392,1,39,
458,15,403,1,36,
458,15,469,1,23,
458,15,469,2,0,
458,15,496,4,0,
458,15,503,4,0,
458,15,512,4,0,
458,15,523,4,0,
458,15,590,4,0,
458,16,17,1,14,1
458,16,21,2,0,
458,16,29,1,16,1
458,16,33,1,1,1
458,16,36,1,27,1
458,16,48,1,3,1
458,16,56,1,49,1
458,16,56,2,0,
458,16,57,4,0,
458,16,58,4,0,
458,16,59,4,0,
458,16,61,1,7,1
458,16,89,4,0,
458,16,92,4,0,
458,16,97,1,32,1
458,16,104,4,0,
458,16,109,1,11,1
458,16,114,2,0,
458,16,127,4,0,
458,16,133,2,0,
458,16,145,1,1,2
458,16,150,2,0,
458,16,156,4,0,
458,16,157,4,0,
458,16,164,4,0,
458,16,173,3,0,
458,16,182,4,0,
458,16,196,3,0,
458,16,207,4,0,
458,16,213,4,0,
458,16,214,4,0,
458,16,216,4,0,
458,16,218,4,0,
458,16,237,4,0,
458,16,239,2,0,
458,16,240,4,0,
458,16,243,2,0,
458,16,258,4,0,
458,16,263,4,0,
458,16,270,3,0,
458,16,290,4,0,
458,16,291,4,0,
458,16,300,2,0,
458,16,324,2,0,
458,16,324,3,0,
458,16,332,4,0,
458,16,340,1,46,1
458,16,340,3,0,
458,16,346,2,0,
458,16,352,1,19,1
458,16,352,3,0,
458,16,366,2,0,
458,16,392,1,39,1
458,16,403,1,36,1
458,16,469,1,23,1
458,16,469,2,0,
458,16,496,4,0,
458,16,503,4,0,
458,16,512,4,0,
458,16,523,4,0,
458,16,590,4,0,
458,17,17,1,14,
458,17,21,2,0,
458,17,29,1,16,
458,17,33,1,1,1
458,17,36,1,27,
458,17,48,1,3,
458,17,56,1,49,
458,17,56,2,0,
458,17,57,4,0,
458,17,58,4,0,
458,17,59,4,0,
458,17,61,1,7,
458,17,89,4,0,
458,17,92,4,0,
458,17,97,1,32,
458,17,104,4,0,
458,17,109,1,11,
458,17,114,2,0,
458,17,127,4,0,
458,17,133,2,0,
458,17,145,1,1,2
458,17,150,2,0,
458,17,156,4,0,
458,17,157,4,0,
458,17,164,4,0,
458,17,182,4,0,
458,17,207,4,0,
458,17,213,4,0,
458,17,214,4,0,
458,17,216,4,0,
458,17,218,4,0,
458,17,237,4,0,
458,17,239,2,0,
458,17,240,4,0,
458,17,243,2,0,
458,17,258,4,0,
458,17,263,4,0,
458,17,300,2,0,
458,17,324,2,0,
458,17,332,4,0,
458,17,340,1,46,
458,17,346,2,0,
458,17,352,1,19,
458,17,366,2,0,
458,17,392,1,39,
458,17,403,1,36,
458,17,469,1,23,
458,17,469,2,0,
458,17,496,4,0,
458,17,503,4,0,
458,17,512,4,0,
458,17,523,4,0,
458,17,590,4,0,
458,18,17,1,14,
458,18,21,2,0,
458,18,29,1,16,
458,18,33,1,1,1
458,18,36,1,27,
458,18,48,1,3,
458,18,56,1,49,
458,18,56,2,0,
458,18,57,4,0,
458,18,58,4,0,
458,18,59,4,0,
458,18,61,1,7,
458,18,89,4,0,
458,18,92,4,0,
458,18,97,1,32,
458,18,104,4,0,
458,18,109,1,11,
458,18,114,2,0,
458,18,127,4,0,
458,18,133,2,0,
458,18,145,1,1,2
458,18,150,2,0,
458,18,156,4,0,
458,18,157,4,0,
458,18,164,4,0,
458,18,182,4,0,
458,18,207,4,0,
458,18,213,4,0,
458,18,214,4,0,
458,18,216,4,0,
458,18,218,4,0,
458,18,237,4,0,
458,18,239,2,0,
458,18,240,4,0,
458,18,243,2,0,
458,18,258,4,0,
458,18,263,4,0,
458,18,300,2,0,
458,18,324,2,0,
458,18,332,4,0,
458,18,340,1,46,
458,18,346,2,0,
458,18,352,1,19,
458,18,366,2,0,
458,18,392,1,39,
458,18,403,1,36,
458,18,469,1,23,
458,18,469,2,0,
458,18,496,4,0,
458,18,503,4,0,
458,18,512,4,0,
458,18,523,4,0,
458,18,590,4,0,
459,8,14,4,0,
459,8,23,2,0,
459,8,38,2,0,
459,8,43,1,1,2
459,8,54,1,21,
459,8,54,2,0,
459,8,58,4,0,
459,8,59,1,41,
459,8,59,4,0,
459,8,73,2,0,
459,8,74,2,0,
459,8,75,1,5,
459,8,76,4,0,
459,8,92,4,0,
459,8,104,4,0,
459,8,113,4,0,
459,8,148,4,0,
459,8,156,4,0,
459,8,164,4,0,
459,8,181,1,1,1
459,8,182,4,0,
459,8,196,1,9,
459,8,202,4,0,
459,8,203,4,0,
459,8,207,1,17,
459,8,207,4,0,
459,8,213,4,0,
459,8,214,4,0,
459,8,216,4,0,
459,8,218,4,0,
459,8,219,4,0,
459,8,231,4,0,
459,8,237,4,0,
459,8,240,4,0,
459,8,247,4,0,
459,8,258,4,0,
459,8,263,4,0,
459,8,275,1,31,
459,8,290,4,0,
459,8,320,1,13,
459,8,329,1,46,
459,8,331,4,0,
459,8,345,2,0,
459,8,352,4,0,
459,8,363,4,0,
459,8,402,2,0,
459,8,412,4,0,
459,8,419,4,0,
459,8,420,1,26,
459,8,445,4,0,
459,8,447,4,0,
459,8,452,1,36,
459,9,8,3,0,
459,9,14,4,0,
459,9,23,2,0,
459,9,38,2,0,
459,9,43,1,1,2
459,9,54,1,21,
459,9,54,2,0,
459,9,58,4,0,
459,9,59,1,41,
459,9,59,4,0,
459,9,73,2,0,
459,9,74,2,0,
459,9,75,1,5,
459,9,76,4,0,
459,9,92,4,0,
459,9,104,4,0,
459,9,113,4,0,
459,9,148,4,0,
459,9,156,4,0,
459,9,164,4,0,
459,9,173,3,0,
459,9,181,1,1,1
459,9,182,4,0,
459,9,189,3,0,
459,9,196,1,9,
459,9,196,3,0,
459,9,202,4,0,
459,9,203,4,0,
459,9,207,1,17,
459,9,207,4,0,
459,9,213,4,0,
459,9,214,4,0,
459,9,216,4,0,
459,9,218,4,0,
459,9,219,4,0,
459,9,231,4,0,
459,9,235,3,0,
459,9,237,4,0,
459,9,240,4,0,
459,9,247,4,0,
459,9,258,4,0,
459,9,263,4,0,
459,9,275,1,31,
459,9,290,4,0,
459,9,320,1,13,
459,9,329,1,46,
459,9,331,4,0,
459,9,345,2,0,
459,9,352,4,0,
459,9,363,4,0,
459,9,402,2,0,
459,9,402,3,0,
459,9,412,4,0,
459,9,419,4,0,
459,9,420,1,26,
459,9,445,4,0,
459,9,447,4,0,
459,9,452,1,36,
459,10,8,3,0,
459,10,14,4,0,
459,10,23,2,0,
459,10,29,3,0,
459,10,38,2,0,
459,10,43,1,1,2
459,10,54,1,21,
459,10,54,2,0,
459,10,58,4,0,
459,10,59,1,41,
459,10,59,4,0,
459,10,73,2,0,
459,10,74,2,0,
459,10,75,1,5,
459,10,76,4,0,
459,10,92,4,0,
459,10,104,4,0,
459,10,113,4,0,
459,10,130,2,0,
459,10,148,4,0,
459,10,156,4,0,
459,10,164,4,0,
459,10,173,3,0,
459,10,181,1,1,1
459,10,182,4,0,
459,10,189,3,0,
459,10,196,1,9,
459,10,196,3,0,
459,10,202,4,0,
459,10,203,4,0,
459,10,207,1,17,
459,10,207,4,0,
459,10,213,4,0,
459,10,214,4,0,
459,10,216,4,0,
459,10,218,4,0,
459,10,219,4,0,
459,10,231,4,0,
459,10,235,3,0,
459,10,237,4,0,
459,10,240,4,0,
459,10,247,4,0,
459,10,258,4,0,
459,10,263,4,0,
459,10,272,3,0,
459,10,275,1,31,
459,10,290,4,0,
459,10,320,1,13,
459,10,329,1,46,
459,10,331,4,0,
459,10,345,2,0,
459,10,352,4,0,
459,10,363,4,0,
459,10,388,3,0,
459,10,402,2,0,
459,10,402,3,0,
459,10,412,4,0,
459,10,419,4,0,
459,10,420,1,26,
459,10,445,4,0,
459,10,447,4,0,
459,10,452,1,36,
459,11,14,4,0,
459,11,23,2,0,
459,11,38,2,0,
459,11,43,1,1,2
459,11,54,1,21,
459,11,54,2,0,
459,11,58,4,0,
459,11,59,1,41,
459,11,59,4,0,
459,11,73,2,0,
459,11,74,2,0,
459,11,75,1,5,
459,11,76,4,0,
459,11,92,4,0,
459,11,104,4,0,
459,11,113,4,0,
459,11,130,2,0,
459,11,148,4,0,
459,11,156,4,0,
459,11,164,4,0,
459,11,181,1,1,1
459,11,182,4,0,
459,11,196,1,9,
459,11,207,1,17,
459,11,207,4,0,
459,11,213,4,0,
459,11,216,4,0,
459,11,218,4,0,
459,11,219,4,0,
459,11,237,4,0,
459,11,240,4,0,
459,11,247,4,0,
459,11,258,4,0,
459,11,263,4,0,
459,11,275,1,31,
459,11,320,1,13,
459,11,329,1,46,
459,11,331,2,0,
459,11,345,2,0,
459,11,363,2,0,
459,11,402,2,0,
459,11,412,4,0,
459,11,419,2,0,
459,11,420,1,26,
459,11,447,4,0,
459,11,452,1,36,
459,11,496,4,0,
459,11,524,4,0,
459,14,8,3,0,
459,14,14,4,0,
459,14,23,2,0,
459,14,38,2,0,
459,14,43,1,1,2
459,14,54,1,21,
459,14,54,2,0,
459,14,58,4,0,
459,14,59,1,41,
459,14,59,4,0,
459,14,73,2,0,
459,14,74,2,0,
459,14,75,1,5,
459,14,76,4,0,
459,14,92,4,0,
459,14,104,4,0,
459,14,113,4,0,
459,14,130,2,0,
459,14,148,4,0,
459,14,156,4,0,
459,14,164,4,0,
459,14,173,3,0,
459,14,181,1,1,1
459,14,182,4,0,
459,14,196,1,9,
459,14,196,3,0,
459,14,202,3,0,
459,14,207,1,17,
459,14,207,4,0,
459,14,213,4,0,
459,14,214,3,0,
459,14,216,4,0,
459,14,218,4,0,
459,14,219,4,0,
459,14,231,3,0,
459,14,235,3,0,
459,14,237,4,0,
459,14,240,4,0,
459,14,247,4,0,
459,14,258,4,0,
459,14,263,4,0,
459,14,272,3,0,
459,14,275,1,31,
459,14,320,1,13,
459,14,329,1,46,
459,14,331,2,0,
459,14,345,2,0,
459,14,363,2,0,
459,14,388,3,0,
459,14,402,2,0,
459,14,402,3,0,
459,14,412,4,0,
459,14,419,2,0,
459,14,420,1,26,
459,14,447,4,0,
459,14,452,1,36,
459,14,496,4,0,
459,14,524,4,0,
459,15,14,4,0,
459,15,23,2,0,
459,15,38,2,0,
459,15,43,1,1,2
459,15,54,1,21,
459,15,54,2,0,
459,15,58,4,0,
459,15,59,1,41,
459,15,59,4,0,
459,15,73,2,0,
459,15,74,2,0,
459,15,75,1,5,
459,15,76,4,0,
459,15,92,4,0,
459,15,104,4,0,
459,15,113,4,0,
459,15,130,2,0,
459,15,148,4,0,
459,15,156,4,0,
459,15,164,4,0,
459,15,181,1,1,1
459,15,182,4,0,
459,15,196,1,9,
459,15,207,1,17,
459,15,207,4,0,
459,15,213,4,0,
459,15,214,4,0,
459,15,216,4,0,
459,15,218,4,0,
459,15,219,4,0,
459,15,237,4,0,
459,15,240,4,0,
459,15,247,4,0,
459,15,258,4,0,
459,15,263,4,0,
459,15,275,1,31,
459,15,320,1,13,
459,15,329,1,46,
459,15,331,2,0,
459,15,345,2,0,
459,15,363,2,0,
459,15,402,2,0,
459,15,412,4,0,
459,15,419,2,0,
459,15,420,1,26,
459,15,447,4,0,
459,15,452,1,36,
459,15,496,4,0,
459,15,524,4,0,
459,15,590,4,0,
459,16,8,3,0,
459,16,14,4,0,
459,16,23,2,0,
459,16,38,2,0,
459,16,43,1,1,2
459,16,54,1,21,1
459,16,54,2,0,
459,16,58,4,0,
459,16,59,1,41,1
459,16,59,4,0,
459,16,73,2,0,
459,16,74,2,0,
459,16,75,1,5,1
459,16,76,4,0,
459,16,92,4,0,
459,16,104,4,0,
459,16,113,4,0,
459,16,130,2,0,
459,16,148,4,0,
459,16,156,4,0,
459,16,164,4,0,
459,16,173,3,0,
459,16,181,1,1,1
459,16,182,4,0,
459,16,196,1,9,1
459,16,196,3,0,
459,16,202,3,0,
459,16,207,1,17,1
459,16,207,4,0,
459,16,213,4,0,
459,16,214,4,0,
459,16,216,4,0,
459,16,218,4,0,
459,16,219,4,0,
459,16,231,3,0,
459,16,235,3,0,
459,16,237,4,0,
459,16,240,4,0,
459,16,247,4,0,
459,16,258,4,0,
459,16,263,4,0,
459,16,272,3,0,
459,16,275,1,31,1
459,16,290,4,0,
459,16,320,1,13,1
459,16,329,1,46,1
459,16,331,2,0,
459,16,345,2,0,
459,16,352,3,0,
459,16,363,2,0,
459,16,388,3,0,
459,16,402,2,0,
459,16,402,3,0,
459,16,412,4,0,
459,16,419,2,0,
459,16,420,1,26,1
459,16,447,4,0,
459,16,452,1,36,1
459,16,496,4,0,
459,16,524,4,0,
459,16,590,4,0,
459,17,14,4,0,
459,17,23,2,0,
459,17,38,2,0,
459,17,43,1,1,2
459,17,54,1,21,
459,17,54,2,0,
459,17,58,4,0,
459,17,59,1,41,
459,17,59,4,0,
459,17,73,2,0,
459,17,74,2,0,
459,17,75,1,5,
459,17,76,4,0,
459,17,92,4,0,
459,17,104,4,0,
459,17,113,4,0,
459,17,130,2,0,
459,17,156,4,0,
459,17,164,4,0,
459,17,181,1,1,1
459,17,182,4,0,
459,17,196,1,9,
459,17,207,1,17,
459,17,207,4,0,
459,17,213,4,0,
459,17,214,4,0,
459,17,216,4,0,
459,17,218,4,0,
459,17,219,4,0,
459,17,237,4,0,
459,17,240,4,0,
459,17,247,4,0,
459,17,258,4,0,
459,17,263,4,0,
459,17,275,1,31,
459,17,320,1,13,
459,17,329,1,46,
459,17,331,2,0,
459,17,345,2,0,
459,17,363,2,0,
459,17,402,2,0,
459,17,412,4,0,
459,17,419,2,0,
459,17,420,1,26,
459,17,447,4,0,
459,17,452,1,36,
459,17,496,4,0,
459,17,524,4,0,
459,17,590,4,0,
459,18,14,4,0,
459,18,23,2,0,
459,18,38,2,0,
459,18,43,1,1,2
459,18,54,1,21,
459,18,54,2,0,
459,18,58,4,0,
459,18,59,1,41,
459,18,59,4,0,
459,18,73,2,0,
459,18,74,2,0,
459,18,75,1,5,
459,18,76,4,0,
459,18,92,4,0,
459,18,104,4,0,
459,18,113,4,0,
459,18,130,2,0,
459,18,156,4,0,
459,18,164,4,0,
459,18,181,1,1,1
459,18,182,4,0,
459,18,196,1,9,
459,18,207,1,17,
459,18,207,4,0,
459,18,213,4,0,
459,18,214,4,0,
459,18,216,4,0,
459,18,218,4,0,
459,18,219,4,0,
459,18,237,4,0,
459,18,240,4,0,
459,18,247,4,0,
459,18,258,4,0,
459,18,263,4,0,
459,18,275,1,31,
459,18,320,1,13,
459,18,329,1,46,
459,18,331,2,0,
459,18,345,2,0,
459,18,363,2,0,
459,18,402,2,0,
459,18,412,4,0,
459,18,419,2,0,
459,18,420,1,26,
459,18,447,4,0,
459,18,452,1,36,
459,18,496,4,0,
459,18,524,4,0,
459,18,590,4,0,
460,8,8,1,1,1
460,8,14,4,0,
460,8,43,1,1,3
460,8,54,1,21,
460,8,58,4,0,
460,8,59,1,47,
460,8,59,4,0,
460,8,63,4,0,
460,8,70,4,0,
460,8,75,1,1,4
460,8,75,1,5,
460,8,76,4,0,
460,8,89,4,0,
460,8,92,4,0,
460,8,104,4,0,
460,8,113,4,0,
460,8,148,4,0,
460,8,156,4,0,
460,8,157,4,0,
460,8,164,4,0,
460,8,181,1,1,2
460,8,182,4,0,
460,8,196,1,1,5
460,8,196,1,9,
460,8,202,4,0,
460,8,203,4,0,
460,8,207,1,17,
460,8,207,4,0,
460,8,213,4,0,
460,8,214,4,0,
460,8,216,4,0,
460,8,218,4,0,
460,8,219,4,0,
460,8,231,4,0,
460,8,237,4,0,
460,8,240,4,0,
460,8,247,4,0,
460,8,249,4,0,
460,8,258,4,0,
460,8,263,4,0,
460,8,264,4,0,
460,8,275,1,31,
460,8,280,4,0,
460,8,290,4,0,
460,8,317,4,0,
460,8,320,1,13,
460,8,329,1,58,
460,8,331,4,0,
460,8,352,4,0,
460,8,363,4,0,
460,8,374,4,0,
460,8,411,4,0,
460,8,412,4,0,
460,8,416,4,0,
460,8,419,4,0,
460,8,420,1,26,
460,8,431,4,0,
460,8,445,4,0,
460,8,447,4,0,
460,8,452,1,36,
460,9,8,1,1,1
460,9,8,3,0,
460,9,14,4,0,
460,9,43,1,1,3
460,9,54,1,21,
460,9,58,4,0,
460,9,59,1,47,
460,9,59,4,0,
460,9,63,4,0,
460,9,70,4,0,
460,9,75,1,1,4
460,9,75,1,5,
460,9,76,4,0,
460,9,89,4,0,
460,9,92,4,0,
460,9,104,4,0,
460,9,113,4,0,
460,9,148,4,0,
460,9,156,4,0,
460,9,157,4,0,
460,9,164,4,0,
460,9,173,3,0,
460,9,181,1,1,2
460,9,182,4,0,
460,9,189,3,0,
460,9,196,1,1,5
460,9,196,1,9,
460,9,196,3,0,
460,9,200,3,0,
460,9,202,4,0,
460,9,203,4,0,
460,9,207,1,17,
460,9,207,4,0,
460,9,213,4,0,
460,9,214,4,0,
460,9,216,4,0,
460,9,218,4,0,
460,9,219,4,0,
460,9,231,4,0,
460,9,235,3,0,
460,9,237,4,0,
460,9,240,4,0,
460,9,247,4,0,
460,9,249,4,0,
460,9,258,4,0,
460,9,263,4,0,
460,9,264,4,0,
460,9,275,1,31,
460,9,280,4,0,
460,9,290,4,0,
460,9,317,4,0,
460,9,320,1,13,
460,9,329,1,58,
460,9,331,4,0,
460,9,352,4,0,
460,9,363,4,0,
460,9,374,4,0,
460,9,402,3,0,
460,9,411,4,0,
460,9,412,4,0,
460,9,416,4,0,
460,9,419,4,0,
460,9,420,1,26,
460,9,431,4,0,
460,9,445,4,0,
460,9,447,4,0,
460,9,452,1,36,
460,10,8,1,1,1
460,10,8,3,0,
460,10,14,4,0,
460,10,29,3,0,
460,10,43,1,1,3
460,10,54,1,21,
460,10,58,4,0,
460,10,59,1,47,
460,10,59,4,0,
460,10,63,4,0,
460,10,70,4,0,
460,10,75,1,1,4
460,10,75,1,5,
460,10,76,4,0,
460,10,89,4,0,
460,10,92,4,0,
460,10,104,4,0,
460,10,113,4,0,
460,10,148,4,0,
460,10,156,4,0,
460,10,157,4,0,
460,10,164,4,0,
460,10,173,3,0,
460,10,181,1,1,2
460,10,182,4,0,
460,10,189,3,0,
460,10,196,1,1,5
460,10,196,1,9,
460,10,196,3,0,
460,10,200,3,0,
460,10,202,4,0,
460,10,203,4,0,
460,10,207,1,17,
460,10,207,4,0,
460,10,213,4,0,
460,10,214,4,0,
460,10,216,4,0,
460,10,218,4,0,
460,10,219,4,0,
460,10,231,4,0,
460,10,235,3,0,
460,10,237,4,0,
460,10,240,4,0,
460,10,247,4,0,
460,10,249,4,0,
460,10,258,4,0,
460,10,263,4,0,
460,10,264,4,0,
460,10,272,3,0,
460,10,275,1,31,
460,10,280,4,0,
460,10,290,4,0,
460,10,317,4,0,
460,10,320,1,13,
460,10,329,1,58,
460,10,331,4,0,
460,10,335,3,0,
460,10,352,4,0,
460,10,363,4,0,
460,10,374,4,0,
460,10,388,3,0,
460,10,402,3,0,
460,10,411,4,0,
460,10,412,4,0,
460,10,416,4,0,
460,10,419,4,0,
460,10,420,1,26,
460,10,431,4,0,
460,10,445,4,0,
460,10,447,4,0,
460,10,452,1,36,
460,11,8,1,1,1
460,11,14,4,0,
460,11,43,1,1,3
460,11,54,1,21,
460,11,58,4,0,
460,11,59,1,47,
460,11,59,4,0,
460,11,63,4,0,
460,11,70,4,0,
460,11,75,1,1,4
460,11,75,1,5,
460,11,76,4,0,
460,11,89,4,0,
460,11,92,4,0,
460,11,104,4,0,
460,11,113,4,0,
460,11,148,4,0,
460,11,156,4,0,
460,11,157,4,0,
460,11,164,4,0,
460,11,181,1,1,2
460,11,182,4,0,
460,11,196,1,1,5
460,11,196,1,9,
460,11,207,1,17,
460,11,207,4,0,
460,11,213,4,0,
460,11,216,4,0,
460,11,218,4,0,
460,11,219,4,0,
460,11,237,4,0,
460,11,240,4,0,
460,11,247,4,0,
460,11,249,4,0,
460,11,258,4,0,
460,11,263,4,0,
460,11,275,1,31,
460,11,280,4,0,
460,11,317,4,0,
460,11,320,1,13,
460,11,329,1,58,
460,11,374,4,0,
460,11,411,4,0,
460,11,412,4,0,
460,11,416,4,0,
460,11,420,1,26,
460,11,447,4,0,
460,11,452,1,36,
460,11,496,4,0,
460,11,523,4,0,
460,11,524,4,0,
460,14,8,1,1,1
460,14,8,3,0,
460,14,14,4,0,
460,14,43,1,1,3
460,14,54,1,21,
460,14,58,4,0,
460,14,59,1,47,
460,14,59,4,0,
460,14,63,4,0,
460,14,70,4,0,
460,14,75,1,1,4
460,14,75,1,5,
460,14,76,4,0,
460,14,89,4,0,
460,14,92,4,0,
460,14,104,4,0,
460,14,113,4,0,
460,14,148,4,0,
460,14,156,4,0,
460,14,157,4,0,
460,14,164,4,0,
460,14,173,3,0,
460,14,181,1,1,2
460,14,182,4,0,
460,14,196,1,1,5
460,14,196,1,9,
460,14,196,3,0,
460,14,200,3,0,
460,14,202,3,0,
460,14,207,1,17,
460,14,207,4,0,
460,14,213,4,0,
460,14,214,3,0,
460,14,216,4,0,
460,14,218,4,0,
460,14,219,4,0,
460,14,231,3,0,
460,14,235,3,0,
460,14,237,4,0,
460,14,240,4,0,
460,14,247,4,0,
460,14,249,4,0,
460,14,258,4,0,
460,14,263,4,0,
460,14,272,3,0,
460,14,275,1,31,
460,14,280,4,0,
460,14,317,4,0,
460,14,320,1,13,
460,14,329,1,58,
460,14,335,3,0,
460,14,374,4,0,
460,14,388,3,0,
460,14,402,3,0,
460,14,411,4,0,
460,14,412,4,0,
460,14,416,4,0,
460,14,420,1,26,
460,14,447,4,0,
460,14,452,1,36,
460,14,496,4,0,
460,14,523,4,0,
460,14,524,4,0,
460,15,8,1,1,1
460,15,14,4,0,
460,15,43,1,1,3
460,15,54,1,21,
460,15,58,4,0,
460,15,59,1,47,
460,15,59,4,0,
460,15,63,4,0,
460,15,70,4,0,
460,15,75,1,1,4
460,15,75,1,5,
460,15,76,4,0,
460,15,89,4,0,
460,15,92,4,0,
460,15,104,4,0,
460,15,113,4,0,
460,15,148,4,0,
460,15,156,4,0,
460,15,157,4,0,
460,15,164,4,0,
460,15,181,1,1,2
460,15,182,4,0,
460,15,196,1,1,5
460,15,196,1,9,
460,15,207,1,17,
460,15,207,4,0,
460,15,213,4,0,
460,15,214,4,0,
460,15,216,4,0,
460,15,218,4,0,
460,15,219,4,0,
460,15,237,4,0,
460,15,240,4,0,
460,15,247,4,0,
460,15,249,4,0,
460,15,258,4,0,
460,15,263,4,0,
460,15,275,1,31,
460,15,280,4,0,
460,15,317,4,0,
460,15,320,1,13,
460,15,329,1,58,
460,15,374,4,0,
460,15,411,4,0,
460,15,412,4,0,
460,15,416,4,0,
460,15,420,1,26,
460,15,447,4,0,
460,15,452,1,36,
460,15,496,4,0,
460,15,523,4,0,
460,15,524,4,0,
460,15,590,4,0,
460,16,8,1,1,1
460,16,8,3,0,
460,16,14,4,0,
460,16,43,1,1,3
460,16,54,1,21,1
460,16,58,4,0,
460,16,59,1,47,1
460,16,59,4,0,
460,16,63,4,0,
460,16,70,4,0,
460,16,75,1,1,4
460,16,75,1,5,1
460,16,76,4,0,
460,16,89,4,0,
460,16,92,4,0,
460,16,104,4,0,
460,16,113,4,0,
460,16,148,4,0,
460,16,156,4,0,
460,16,157,4,0,
460,16,164,4,0,
460,16,173,3,0,
460,16,181,1,1,2
460,16,182,4,0,
460,16,196,1,1,5
460,16,196,1,9,1
460,16,196,3,0,
460,16,200,3,0,
460,16,202,3,0,
460,16,207,1,17,1
460,16,207,4,0,
460,16,213,4,0,
460,16,214,4,0,
460,16,216,4,0,
460,16,218,4,0,
460,16,219,4,0,
460,16,231,3,0,
460,16,235,3,0,
460,16,237,4,0,
460,16,240,4,0,
460,16,247,4,0,
460,16,249,4,0,
460,16,258,4,0,
460,16,263,4,0,
460,16,264,3,0,
460,16,272,3,0,
460,16,275,1,31,1
460,16,280,4,0,
460,16,290,4,0,
460,16,317,4,0,
460,16,320,1,13,1
460,16,329,1,58,1
460,16,335,3,0,
460,16,352,3,0,
460,16,374,4,0,
460,16,388,3,0,
460,16,402,3,0,
460,16,411,4,0,
460,16,412,4,0,
460,16,416,4,0,
460,16,420,1,26,1
460,16,447,4,0,
460,16,452,1,36,1
460,16,496,4,0,
460,16,523,4,0,
460,16,524,4,0,
460,16,590,4,0,
460,17,8,1,1,1
460,17,14,4,0,
460,17,43,1,1,3
460,17,54,1,21,
460,17,58,4,0,
460,17,59,1,47,
460,17,59,4,0,
460,17,63,4,0,
460,17,75,1,1,4
460,17,75,1,5,
460,17,76,4,0,
460,17,89,4,0,
460,17,92,4,0,
460,17,104,4,0,
460,17,113,4,0,
460,17,156,4,0,
460,17,157,4,0,
460,17,164,4,0,
460,17,181,1,1,2
460,17,182,4,0,
460,17,196,1,1,5
460,17,196,1,9,
460,17,207,1,17,
460,17,207,4,0,
460,17,213,4,0,
460,17,214,4,0,
460,17,216,4,0,
460,17,218,4,0,
460,17,219,4,0,
460,17,237,4,0,
460,17,240,4,0,
460,17,247,4,0,
460,17,258,4,0,
460,17,263,4,0,
460,17,275,1,31,
460,17,280,4,0,
460,17,317,4,0,
460,17,320,1,13,
460,17,329,1,58,
460,17,374,4,0,
460,17,411,4,0,
460,17,412,4,0,
460,17,416,4,0,
460,17,420,1,26,
460,17,447,4,0,
460,17,452,1,36,
460,17,496,4,0,
460,17,523,4,0,
460,17,524,4,0,
460,17,590,4,0,
460,18,8,1,1,1
460,18,14,4,0,
460,18,43,1,1,3
460,18,54,1,21,
460,18,58,4,0,
460,18,59,1,47,
460,18,59,4,0,
460,18,63,4,0,
460,18,75,1,1,4
460,18,75,1,5,
460,18,76,4,0,
460,18,89,4,0,
460,18,92,4,0,
460,18,104,4,0,
460,18,113,4,0,
460,18,156,4,0,
460,18,157,4,0,
460,18,164,4,0,
460,18,181,1,1,2
460,18,182,4,0,
460,18,196,1,1,5
460,18,196,1,9,
460,18,207,1,17,
460,18,207,4,0,
460,18,213,4,0,
460,18,214,4,0,
460,18,216,4,0,
460,18,218,4,0,
460,18,219,4,0,
460,18,237,4,0,
460,18,240,4,0,
460,18,247,4,0,
460,18,258,4,0,
460,18,263,4,0,
460,18,275,1,31,
460,18,280,4,0,
460,18,317,4,0,
460,18,320,1,13,
460,18,329,1,58,
460,18,374,4,0,
460,18,411,4,0,
460,18,412,4,0,
460,18,416,4,0,
460,18,420,1,26,
460,18,447,4,0,
460,18,452,1,36,
460,18,496,4,0,
460,18,523,4,0,
460,18,524,4,0,
460,18,590,4,0,
461,8,10,1,1,4
461,8,14,4,0,
461,8,15,4,0,
461,8,43,1,1,5
461,8,57,4,0,
461,8,58,4,0,
461,8,59,4,0,
461,8,63,4,0,
461,8,70,4,0,
461,8,91,4,0,
461,8,92,4,0,
461,8,98,1,1,7
461,8,98,1,8,
461,8,103,1,10,
461,8,104,4,0,
461,8,138,4,0,
461,8,154,1,21,
461,8,156,4,0,
461,8,164,4,0,
461,8,168,4,0,
461,8,182,4,0,
461,8,185,1,14,
461,8,196,1,28,
461,8,203,4,0,
461,8,206,4,0,
461,8,207,4,0,
461,8,213,4,0,
461,8,214,4,0,
461,8,216,4,0,
461,8,218,4,0,
461,8,231,4,0,
461,8,232,1,42,
461,8,237,4,0,
461,8,240,4,0,
461,8,241,4,0,
461,8,244,4,0,
461,8,247,4,0,
461,8,249,4,0,
461,8,258,4,0,
461,8,259,4,0,
461,8,263,4,0,
461,8,264,4,0,
461,8,269,1,1,6
461,8,269,4,0,
461,8,279,1,1,2
461,8,280,4,0,
461,8,289,4,0,
461,8,290,4,0,
461,8,332,4,0,
461,8,347,4,0,
461,8,363,4,0,
461,8,371,4,0,
461,8,372,1,1,3
461,8,373,1,1,1
461,8,373,4,0,
461,8,374,1,38,
461,8,374,4,0,
461,8,398,4,0,
461,8,399,1,49,
461,8,399,4,0,
461,8,400,1,35,
461,8,404,4,0,
461,8,411,4,0,
461,8,416,4,0,
461,8,417,1,24,
461,8,419,4,0,
461,8,421,4,0,
461,8,445,4,0,
461,9,8,3,0,
461,9,10,1,1,4
461,9,14,4,0,
461,9,15,4,0,
461,9,43,1,1,5
461,9,57,4,0,
461,9,58,4,0,
461,9,59,4,0,
461,9,63,4,0,
461,9,70,4,0,
461,9,91,4,0,
461,9,92,4,0,
461,9,98,1,1,7
461,9,98,1,8,
461,9,103,1,10,
461,9,104,4,0,
461,9,129,3,0,
461,9,138,4,0,
461,9,154,1,21,
461,9,156,4,0,
461,9,164,4,0,
461,9,168,4,0,
461,9,173,3,0,
461,9,180,3,0,
461,9,182,4,0,
461,9,185,1,14,
461,9,189,3,0,
461,9,196,1,28,
461,9,196,3,0,
461,9,203,4,0,
461,9,206,4,0,
461,9,207,4,0,
461,9,210,3,0,
461,9,213,4,0,
461,9,214,4,0,
461,9,216,4,0,
461,9,218,4,0,
461,9,231,4,0,
461,9,232,1,42,
461,9,237,4,0,
461,9,240,4,0,
461,9,241,4,0,
461,9,244,4,0,
461,9,247,4,0,
461,9,249,4,0,
461,9,258,4,0,
461,9,259,4,0,
461,9,263,4,0,
461,9,264,4,0,
461,9,269,1,1,6
461,9,269,4,0,
461,9,279,1,1,2
461,9,280,4,0,
461,9,282,3,0,
461,9,289,4,0,
461,9,290,4,0,
461,9,332,4,0,
461,9,347,4,0,
461,9,363,4,0,
461,9,371,4,0,
461,9,372,1,1,3
461,9,373,1,1,1
461,9,373,4,0,
461,9,374,1,38,
461,9,374,4,0,
461,9,398,4,0,
461,9,399,1,49,
461,9,399,4,0,
461,9,400,1,35,
461,9,404,4,0,
461,9,411,4,0,
461,9,416,4,0,
461,9,417,1,24,
461,9,419,4,0,
461,9,421,4,0,
461,9,445,4,0,
461,10,8,3,0,
461,10,10,1,1,4
461,10,14,4,0,
461,10,15,4,0,
461,10,29,3,0,
461,10,43,1,1,5
461,10,57,4,0,
461,10,58,4,0,
461,10,59,4,0,
461,10,63,4,0,
461,10,67,3,0,
461,10,70,4,0,
461,10,91,4,0,
461,10,92,4,0,
461,10,98,1,1,7
461,10,98,1,8,
461,10,103,1,10,
461,10,104,4,0,
461,10,129,3,0,
461,10,138,4,0,
461,10,154,1,21,
461,10,156,4,0,
461,10,164,4,0,
461,10,168,4,0,
461,10,173,3,0,
461,10,180,3,0,
461,10,182,4,0,
461,10,185,1,14,
461,10,189,3,0,
461,10,196,1,28,
461,10,196,3,0,
461,10,203,4,0,
461,10,206,4,0,
461,10,207,4,0,
461,10,210,3,0,
461,10,213,4,0,
461,10,214,4,0,
461,10,216,4,0,
461,10,218,4,0,
461,10,231,4,0,
461,10,232,1,42,
461,10,237,4,0,
461,10,240,4,0,
461,10,241,4,0,
461,10,244,4,0,
461,10,247,4,0,
461,10,249,4,0,
461,10,250,4,0,
461,10,258,4,0,
461,10,259,4,0,
461,10,263,4,0,
461,10,264,4,0,
461,10,269,1,1,6
461,10,269,4,0,
461,10,279,1,1,2
461,10,280,4,0,
461,10,282,3,0,
461,10,289,4,0,
461,10,290,4,0,
461,10,332,4,0,
461,10,347,4,0,
461,10,363,4,0,
461,10,371,4,0,
461,10,372,1,1,3
461,10,373,1,1,1
461,10,373,4,0,
461,10,374,1,38,
461,10,374,4,0,
461,10,398,4,0,
461,10,399,1,49,
461,10,399,4,0,
461,10,400,1,35,
461,10,404,4,0,
461,10,411,4,0,
461,10,416,4,0,
461,10,417,1,24,
461,10,419,4,0,
461,10,421,4,0,
461,10,445,4,0,
461,11,10,1,1,4
461,11,14,4,0,
461,11,15,4,0,
461,11,43,1,1,5
461,11,57,4,0,
461,11,58,4,0,
461,11,59,4,0,
461,11,63,4,0,
461,11,70,4,0,
461,11,91,4,0,
461,11,92,4,0,
461,11,98,1,1,7
461,11,98,1,8,
461,11,103,1,10,
461,11,104,4,0,
461,11,115,4,0,
461,11,138,4,0,
461,11,154,1,21,
461,11,156,4,0,
461,11,164,4,0,
461,11,168,4,0,
461,11,182,4,0,
461,11,185,1,14,
461,11,196,1,28,
461,11,206,4,0,
461,11,207,4,0,
461,11,213,4,0,
461,11,216,4,0,
461,11,218,4,0,
461,11,232,1,49,
461,11,237,4,0,
461,11,240,4,0,
461,11,241,4,0,
461,11,244,4,0,
461,11,247,4,0,
461,11,249,4,0,
461,11,258,4,0,
461,11,259,4,0,
461,11,263,4,0,
461,11,269,1,1,6
461,11,269,4,0,
461,11,279,1,1,2
461,11,280,4,0,
461,11,332,4,0,
461,11,347,4,0,
461,11,371,4,0,
461,11,372,1,1,3
461,11,373,1,1,1
461,11,373,4,0,
461,11,374,1,42,
461,11,374,4,0,
461,11,398,4,0,
461,11,399,1,51,
461,11,400,1,38,
461,11,404,4,0,
461,11,411,4,0,
461,11,416,4,0,
461,11,417,1,24,
461,11,421,4,0,
461,11,468,1,35,
461,11,468,4,0,
461,11,490,4,0,
461,11,496,4,0,
461,11,514,4,0,
461,11,555,4,0,
461,14,8,3,0,
461,14,10,1,1,4
461,14,14,4,0,
461,14,15,4,0,
461,14,43,1,1,5
461,14,57,4,0,
461,14,58,4,0,
461,14,59,4,0,
461,14,63,4,0,
461,14,67,3,0,
461,14,70,4,0,
461,14,91,4,0,
461,14,92,4,0,
461,14,98,1,1,7
461,14,98,1,8,
461,14,103,1,32,
461,14,104,4,0,
461,14,115,4,0,
461,14,138,4,0,
461,14,154,1,16,
461,14,156,4,0,
461,14,164,4,0,
461,14,168,4,0,
461,14,173,3,0,
461,14,180,3,0,
461,14,182,4,0,
461,14,185,1,10,
461,14,196,1,14,
461,14,196,3,0,
461,14,206,4,0,
461,14,207,4,0,
461,14,213,4,0,
461,14,214,3,0,
461,14,216,4,0,
461,14,218,4,0,
461,14,231,3,0,
461,14,232,1,22,
461,14,237,4,0,
461,14,240,4,0,
461,14,241,4,0,
461,14,244,4,0,
461,14,247,4,0,
461,14,249,4,0,
461,14,258,4,0,
461,14,259,4,0,
461,14,263,4,0,
461,14,269,1,1,6
461,14,269,4,0,
461,14,279,1,1,2
461,14,280,4,0,
461,14,282,3,0,
461,14,289,1,40,
461,14,289,3,0,
461,14,332,4,0,
461,14,347,4,0,
461,14,371,4,0,
461,14,372,1,1,3
461,14,373,1,1,1
461,14,373,4,0,
461,14,374,1,28,
461,14,374,4,0,
461,14,386,1,44,
461,14,398,4,0,
461,14,399,1,47,
461,14,399,3,0,
461,14,400,1,35,
461,14,404,4,0,
461,14,411,4,0,
461,14,416,4,0,
461,14,417,1,20,
461,14,421,4,0,
461,14,468,1,25,
461,14,468,4,0,
461,14,490,4,0,
461,14,492,3,0,
461,14,496,4,0,
461,14,514,4,0,
461,14,555,4,0,
461,15,10,1,1,4
461,15,14,4,0,
461,15,15,4,0,
461,15,43,1,1,5
461,15,57,4,0,
461,15,58,4,0,
461,15,59,4,0,
461,15,63,4,0,
461,15,70,4,0,
461,15,91,4,0,
461,15,92,4,0,
461,15,98,1,1,7
461,15,98,1,8,
461,15,103,1,32,
461,15,104,4,0,
461,15,115,4,0,
461,15,138,4,0,
461,15,154,1,16,
461,15,156,4,0,
461,15,164,4,0,
461,15,168,4,0,
461,15,182,4,0,
461,15,185,1,10,
461,15,196,1,14,
461,15,206,4,0,
461,15,207,4,0,
461,15,213,4,0,
461,15,214,4,0,
461,15,216,4,0,
461,15,218,4,0,
461,15,232,1,22,
461,15,237,4,0,
461,15,240,4,0,
461,15,241,4,0,
461,15,244,4,0,
461,15,247,4,0,
461,15,249,4,0,
461,15,258,4,0,
461,15,259,4,0,
461,15,263,4,0,
461,15,269,1,1,6
461,15,269,4,0,
461,15,279,1,1,2
461,15,280,4,0,
461,15,289,1,40,
461,15,332,4,0,
461,15,347,4,0,
461,15,371,4,0,
461,15,372,1,1,3
461,15,373,1,1,1
461,15,373,4,0,
461,15,374,1,28,
461,15,374,4,0,
461,15,386,1,44,
461,15,398,4,0,
461,15,399,1,47,
461,15,399,4,0,
461,15,400,1,35,
461,15,404,4,0,
461,15,411,4,0,
461,15,416,4,0,
461,15,417,1,20,
461,15,421,4,0,
461,15,468,1,25,
461,15,468,4,0,
461,15,490,4,0,
461,15,496,4,0,
461,15,514,4,0,
461,15,555,4,0,
461,15,590,4,0,
461,15,612,4,0,
461,16,8,3,0,
461,16,10,1,1,4
461,16,14,4,0,
461,16,15,4,0,
461,16,43,1,1,5
461,16,57,4,0,
461,16,58,4,0,
461,16,59,4,0,
461,16,63,4,0,
461,16,67,3,0,
461,16,70,4,0,
461,16,91,4,0,
461,16,92,4,0,
461,16,98,1,1,7
461,16,98,1,8,1
461,16,103,1,32,1
461,16,104,4,0,
461,16,115,4,0,
461,16,138,4,0,
461,16,154,1,16,1
461,16,156,4,0,
461,16,164,4,0,
461,16,168,4,0,
461,16,173,3,0,
461,16,180,3,0,
461,16,182,4,0,
461,16,185,1,10,1
461,16,196,1,14,1
461,16,196,3,0,
461,16,206,4,0,
461,16,207,4,0,
461,16,213,4,0,
461,16,214,4,0,
461,16,216,4,0,
461,16,218,4,0,
461,16,231,3,0,
461,16,232,1,22,1
461,16,237,4,0,
461,16,240,4,0,
461,16,241,4,0,
461,16,244,4,0,
461,16,247,4,0,
461,16,249,4,0,
461,16,258,4,0,
461,16,259,4,0,
461,16,263,4,0,
461,16,264,3,0,
461,16,269,1,1,6
461,16,269,4,0,
461,16,279,1,1,2
461,16,280,4,0,
461,16,282,3,0,
461,16,289,1,40,1
461,16,289,3,0,
461,16,290,4,0,
461,16,332,4,0,
461,16,347,4,0,
461,16,371,4,0,
461,16,372,1,1,3
461,16,373,1,1,1
461,16,373,4,0,
461,16,374,1,28,1
461,16,374,4,0,
461,16,386,1,44,1
461,16,398,4,0,
461,16,399,1,47,1
461,16,399,4,0,
461,16,400,1,35,1
461,16,404,4,0,
461,16,411,4,0,
461,16,416,4,0,
461,16,417,1,20,1
461,16,421,4,0,
461,16,468,1,25,1
461,16,468,4,0,
461,16,490,4,0,
461,16,492,3,0,
461,16,496,4,0,
461,16,514,4,0,
461,16,555,4,0,
461,16,590,4,0,
461,16,612,4,0,
461,17,10,1,1,4
461,17,14,4,0,
461,17,43,1,1,5
461,17,57,4,0,
461,17,58,4,0,
461,17,59,4,0,
461,17,63,4,0,
461,17,92,4,0,
461,17,98,1,1,7
461,17,98,1,8,
461,17,103,1,32,
461,17,104,4,0,
461,17,115,4,0,
461,17,138,4,0,
461,17,154,1,16,
461,17,156,4,0,
461,17,164,4,0,
461,17,168,4,0,
461,17,182,4,0,
461,17,185,1,10,
461,17,196,1,14,
461,17,206,4,0,
461,17,207,4,0,
461,17,213,4,0,
461,17,214,4,0,
461,17,216,4,0,
461,17,218,4,0,
461,17,232,1,22,
461,17,237,4,0,
461,17,240,4,0,
461,17,241,4,0,
461,17,244,4,0,
461,17,247,4,0,
461,17,258,4,0,
461,17,259,4,0,
461,17,263,4,0,
461,17,269,1,1,6
461,17,269,4,0,
461,17,279,1,1,2
461,17,280,4,0,
461,17,289,1,40,
461,17,332,4,0,
461,17,347,4,0,
461,17,371,4,0,
461,17,372,1,1,3
461,17,373,1,1,1
461,17,373,4,0,
461,17,374,1,28,
461,17,374,4,0,
461,17,386,1,44,
461,17,398,4,0,
461,17,399,1,47,
461,17,399,4,0,
461,17,400,1,35,
461,17,404,4,0,
461,17,411,4,0,
461,17,416,4,0,
461,17,417,1,20,
461,17,421,4,0,
461,17,468,1,25,
461,17,490,4,0,
461,17,496,4,0,
461,17,555,4,0,
461,17,590,4,0,
461,18,10,1,1,4
461,18,14,4,0,
461,18,43,1,1,5
461,18,57,4,0,
461,18,58,4,0,
461,18,59,4,0,
461,18,63,4,0,
461,18,92,4,0,
461,18,98,1,1,7
461,18,98,1,8,
461,18,103,1,32,
461,18,104,4,0,
461,18,115,4,0,
461,18,138,4,0,
461,18,154,1,16,
461,18,156,4,0,
461,18,164,4,0,
461,18,168,4,0,
461,18,182,4,0,
461,18,185,1,10,
461,18,196,1,14,
461,18,206,4,0,
461,18,207,4,0,
461,18,213,4,0,
461,18,214,4,0,
461,18,216,4,0,
461,18,218,4,0,
461,18,232,1,22,
461,18,237,4,0,
461,18,240,4,0,
461,18,241,4,0,
461,18,244,4,0,
461,18,247,4,0,
461,18,258,4,0,
461,18,259,4,0,
461,18,263,4,0,
461,18,269,1,1,6
461,18,269,4,0,
461,18,279,1,1,2
461,18,280,4,0,
461,18,289,1,40,
461,18,332,4,0,
461,18,347,4,0,
461,18,371,4,0,
461,18,372,1,1,3
461,18,373,1,1,1
461,18,373,4,0,
461,18,374,1,28,
461,18,374,4,0,
461,18,386,1,44,
461,18,398,4,0,
461,18,399,1,47,
461,18,399,4,0,
461,18,400,1,35,
461,18,404,4,0,
461,18,411,4,0,
461,18,416,4,0,
461,18,417,1,20,
461,18,421,4,0,
461,18,468,1,25,
461,18,490,4,0,
461,18,496,4,0,
461,18,555,4,0,
461,18,590,4,0,
462,8,33,1,1,4
462,8,48,1,1,6
462,8,48,1,11,
462,8,49,1,14,
462,8,63,4,0,
462,8,84,1,1,5
462,8,84,1,6,
462,8,85,4,0,
462,8,86,1,17,
462,8,86,4,0,
462,8,87,4,0,
462,8,92,4,0,
462,8,103,1,34,
462,8,104,4,0,
462,8,112,1,1,2
462,8,113,4,0,
462,8,115,4,0,
462,8,148,4,0,
462,8,153,4,0,
462,8,156,4,0,
462,8,164,4,0,
462,8,182,4,0,
462,8,192,1,60,
462,8,199,1,27,
462,8,203,4,0,
462,8,207,4,0,
462,8,209,1,22,
462,8,214,4,0,
462,8,216,4,0,
462,8,218,4,0,
462,8,237,4,0,
462,8,240,4,0,
462,8,241,4,0,
462,8,243,1,1,1
462,8,244,4,0,
462,8,263,4,0,
462,8,278,4,0,
462,8,290,4,0,
462,8,319,1,1,3
462,8,351,4,0,
462,8,360,1,54,
462,8,360,4,0,
462,8,363,4,0,
462,8,393,1,50,
462,8,416,4,0,
462,8,429,1,46,
462,8,430,4,0,
462,8,435,1,40,
462,8,443,1,30,
462,8,451,4,0,
462,9,33,1,1,4
462,9,48,1,1,6
462,9,48,1,11,
462,9,49,1,14,
462,9,63,4,0,
462,9,84,1,1,5
462,9,84,1,6,
462,9,85,4,0,
462,9,86,1,17,
462,9,86,4,0,
462,9,87,4,0,
462,9,92,4,0,
462,9,103,1,34,
462,9,104,4,0,
462,9,112,1,1,2
462,9,113,4,0,
462,9,115,4,0,
462,9,129,3,0,
462,9,148,4,0,
462,9,153,4,0,
462,9,156,4,0,
462,9,164,4,0,
462,9,173,3,0,
462,9,182,4,0,
462,9,192,1,60,
462,9,199,1,27,
462,9,203,4,0,
462,9,205,3,0,
462,9,207,4,0,
462,9,209,1,22,
462,9,214,4,0,
462,9,216,4,0,
462,9,218,4,0,
462,9,237,4,0,
462,9,240,4,0,
462,9,241,4,0,
462,9,243,1,1,1
462,9,244,4,0,
462,9,263,4,0,
462,9,278,4,0,
462,9,290,4,0,
462,9,319,1,1,3
462,9,324,3,0,
462,9,334,3,0,
462,9,351,4,0,
462,9,360,1,54,
462,9,360,4,0,
462,9,363,4,0,
462,9,393,1,50,
462,9,393,3,0,
462,9,416,4,0,
462,9,429,1,46,
462,9,430,4,0,
462,9,435,1,40,
462,9,442,3,0,
462,9,443,1,30,
462,9,451,4,0,
462,10,33,1,1,4
462,10,48,1,1,6
462,10,48,1,11,
462,10,49,1,14,
462,10,63,4,0,
462,10,84,1,1,5
462,10,84,1,6,
462,10,85,4,0,
462,10,86,1,17,
462,10,86,4,0,
462,10,87,4,0,
462,10,92,4,0,
462,10,103,1,34,
462,10,104,4,0,
462,10,112,1,1,2
462,10,113,4,0,
462,10,115,4,0,
462,10,129,3,0,
462,10,148,4,0,
462,10,153,4,0,
462,10,156,4,0,
462,10,164,4,0,
462,10,173,3,0,
462,10,182,4,0,
462,10,192,1,60,
462,10,199,1,27,
462,10,203,4,0,
462,10,205,3,0,
462,10,207,4,0,
462,10,209,1,22,
462,10,214,4,0,
462,10,216,4,0,
462,10,218,4,0,
462,10,237,4,0,
462,10,240,4,0,
462,10,241,4,0,
462,10,243,1,1,1
462,10,244,4,0,
462,10,263,4,0,
462,10,277,3,0,
462,10,278,4,0,
462,10,290,4,0,
462,10,319,1,1,3
462,10,324,3,0,
462,10,334,3,0,
462,10,351,4,0,
462,10,356,3,0,
462,10,360,1,54,
462,10,360,4,0,
462,10,363,4,0,
462,10,393,1,50,
462,10,393,3,0,
462,10,416,4,0,
462,10,429,1,46,
462,10,430,4,0,
462,10,435,1,40,
462,10,442,3,0,
462,10,443,1,30,
462,10,451,4,0,
462,11,33,1,1,4
462,11,48,1,1,6
462,11,48,1,11,
462,11,49,1,14,
462,11,63,4,0,
462,11,84,1,1,5
462,11,84,1,6,
462,11,85,4,0,
462,11,86,1,17,
462,11,86,4,0,
462,11,87,4,0,
462,11,92,4,0,
462,11,103,1,40,
462,11,104,4,0,
462,11,112,1,1,2
462,11,113,4,0,
462,11,115,4,0,
462,11,148,4,0,
462,11,153,4,0,
462,11,156,4,0,
462,11,164,4,0,
462,11,182,4,0,
462,11,192,1,66,
462,11,199,1,30,
462,11,207,4,0,
462,11,209,1,22,
462,11,216,4,0,
462,11,218,4,0,
462,11,237,4,0,
462,11,240,4,0,
462,11,241,4,0,
462,11,243,1,1,1
462,11,244,4,0,
462,11,263,4,0,
462,11,319,1,1,3
462,11,360,1,60,
462,11,360,4,0,
462,11,393,1,54,
462,11,416,4,0,
462,11,429,1,50,
462,11,430,4,0,
462,11,435,1,46,
462,11,443,1,34,
462,11,451,4,0,
462,11,486,1,27,
462,11,496,4,0,
462,11,521,4,0,
462,11,528,4,0,
462,14,33,1,1,3
462,14,48,1,1,4
462,14,48,1,4,
462,14,49,1,1,6
462,14,49,1,11,
462,14,63,4,0,
462,14,84,1,1,5
462,14,84,1,7,
462,14,85,4,0,
462,14,86,1,15,
462,14,86,4,0,
462,14,87,4,0,
462,14,92,4,0,
462,14,103,1,45,
462,14,104,4,0,
462,14,112,1,1,2
462,14,113,4,0,
462,14,115,4,0,
462,14,148,4,0,
462,14,153,4,0,
462,14,156,4,0,
462,14,164,4,0,
462,14,173,3,0,
462,14,182,4,0,
462,14,192,1,73,
462,14,199,1,56,
462,14,207,4,0,
462,14,209,1,21,
462,14,214,3,0,
462,14,216,4,0,
462,14,218,4,0,
462,14,237,4,0,
462,14,240,4,0,
462,14,241,4,0,
462,14,243,1,1,1
462,14,244,4,0,
462,14,263,4,0,
462,14,277,3,0,
462,14,278,3,0,
462,14,319,1,29,
462,14,324,3,0,
462,14,334,3,0,
462,14,356,3,0,
462,14,360,1,67,
462,14,360,4,0,
462,14,393,1,62,
462,14,393,3,0,
462,14,416,4,0,
462,14,429,1,25,
462,14,430,1,39,
462,14,430,4,0,
462,14,435,1,51,
462,14,442,3,0,
462,14,443,1,18,
462,14,451,4,0,
462,14,486,1,34,
462,14,496,4,0,
462,14,521,4,0,
462,14,527,3,0,
462,14,528,4,0,
462,15,33,1,1,5
462,15,48,1,1,6
462,15,48,1,4,
462,15,49,1,1,7
462,15,63,4,0,
462,15,84,1,7,
462,15,85,4,0,
462,15,86,1,15,
462,15,86,4,0,
462,15,87,4,0,
462,15,92,4,0,
462,15,103,1,45,
462,15,104,4,0,
462,15,112,1,1,4
462,15,113,4,0,
462,15,115,4,0,
462,15,148,4,0,
462,15,153,4,0,
462,15,156,4,0,
462,15,164,4,0,
462,15,182,4,0,
462,15,192,1,1,1
462,15,192,1,73,
462,15,199,1,56,
462,15,207,4,0,
462,15,209,1,21,
462,15,214,4,0,
462,15,216,4,0,
462,15,218,4,0,
462,15,237,4,0,
462,15,240,4,0,
462,15,241,4,0,
462,15,243,1,1,3
462,15,244,4,0,
462,15,263,4,0,
462,15,319,1,29,
462,15,360,1,67,
462,15,360,4,0,
462,15,393,1,62,
462,15,416,4,0,
462,15,429,1,25,
462,15,430,1,39,
462,15,430,4,0,
462,15,435,1,51,
462,15,443,1,18,
462,15,451,4,0,
462,15,486,1,34,
462,15,496,4,0,
462,15,521,4,0,
462,15,528,4,0,
462,15,590,4,0,
462,15,602,1,1,2
462,15,604,1,11,
462,16,33,1,1,6
462,16,48,1,1,7
462,16,48,1,5,1
462,16,49,1,1,9
462,16,49,1,11,1
462,16,63,4,0,
462,16,84,1,1,8
462,16,84,1,7,1
462,16,85,4,0,
462,16,86,1,13,1
462,16,86,4,0,
462,16,87,4,0,
462,16,92,4,0,
462,16,103,1,39,1
462,16,104,4,0,
462,16,112,1,1,4
462,16,113,4,0,
462,16,115,4,0,
462,16,148,4,0,
462,16,153,4,0,
462,16,156,4,0,
462,16,164,4,0,
462,16,173,3,0,
462,16,182,4,0,
462,16,192,1,1,1
462,16,192,1,63,1
462,16,199,1,49,1
462,16,207,4,0,
462,16,209,1,19,1
462,16,214,4,0,
462,16,216,4,0,
462,16,218,4,0,
462,16,237,4,0,
462,16,240,4,0,
462,16,241,4,0,
462,16,243,1,1,3
462,16,244,4,0,
462,16,263,4,0,
462,16,277,3,0,
462,16,278,3,0,
462,16,290,4,0,
462,16,319,1,25,1
462,16,324,3,0,
462,16,334,3,0,
462,16,351,3,0,
462,16,356,3,0,
462,16,360,1,59,1
462,16,360,4,0,
462,16,393,1,53,1
462,16,393,3,0,
462,16,416,4,0,
462,16,429,1,23,1
462,16,430,1,33,1
462,16,430,4,0,
462,16,435,1,43,1
462,16,442,3,0,
462,16,443,1,17,1
462,16,451,4,0,
462,16,486,1,29,1
462,16,496,4,0,
462,16,521,4,0,
462,16,527,3,0,
462,16,528,4,0,
462,16,590,4,0,
462,16,602,1,1,2
462,16,604,1,1,5
462,17,33,1,1,7
462,17,48,1,1,8
462,17,49,1,17,
462,17,63,4,0,
462,17,84,1,1,9
462,17,84,1,5,
462,17,85,4,0,
462,17,86,1,11,
462,17,86,4,0,
462,17,87,4,0,
462,17,92,4,0,
462,17,103,1,39,
462,17,104,4,0,
462,17,112,1,1,5
462,17,113,1,13,
462,17,113,4,0,
462,17,115,4,0,
462,17,153,4,0,
462,17,156,4,0,
462,17,161,1,1,1
462,17,164,4,0,
462,17,182,4,0,
462,17,192,1,1,2
462,17,192,1,63,
462,17,199,1,49,
462,17,207,4,0,
462,17,209,1,19,
462,17,214,4,0,
462,17,216,4,0,
462,17,218,4,0,
462,17,237,4,0,
462,17,240,4,0,
462,17,241,4,0,
462,17,243,1,1,4
462,17,244,4,0,
462,17,263,4,0,
462,17,319,1,25,
462,17,360,1,59,
462,17,360,4,0,
462,17,393,1,53,
462,17,416,4,0,
462,17,429,1,23,
462,17,430,1,33,
462,17,430,4,0,
462,17,435,1,43,
462,17,443,1,1,10
462,17,443,1,7,
462,17,451,4,0,
462,17,486,1,29,
462,17,496,4,0,
462,17,521,4,0,
462,17,528,4,0,
462,17,590,4,0,
462,17,602,1,1,3
462,17,604,1,1,6
462,18,33,1,1,7
462,18,48,1,1,8
462,18,49,1,17,
462,18,63,4,0,
462,18,84,1,1,9
462,18,84,1,5,
462,18,85,4,0,
462,18,86,1,1,10
462,18,86,1,7,
462,18,86,4,0,
462,18,87,4,0,
462,18,92,4,0,
462,18,103,1,39,
462,18,104,4,0,
462,18,112,1,1,5
462,18,113,1,13,
462,18,113,4,0,
462,18,115,4,0,
462,18,153,4,0,
462,18,156,4,0,
462,18,161,1,1,1
462,18,164,4,0,
462,18,182,4,0,
462,18,192,1,1,2
462,18,192,1,63,
462,18,199,1,49,
462,18,207,4,0,
462,18,209,1,19,
462,18,214,4,0,
462,18,216,4,0,
462,18,218,4,0,
462,18,237,4,0,
462,18,240,4,0,
462,18,241,4,0,
462,18,243,1,1,4
462,18,244,4,0,
462,18,263,4,0,
462,18,319,1,25,
462,18,360,1,59,
462,18,360,4,0,
462,18,393,1,53,
462,18,416,4,0,
462,18,429,1,23,
462,18,430,1,33,
462,18,430,4,0,
462,18,435,1,43,
462,18,443,1,11,
462,18,451,4,0,
462,18,486,1,29,
462,18,496,4,0,
462,18,521,4,0,
462,18,528,4,0,
462,18,590,4,0,
462,18,602,1,1,3
462,18,604,1,1,6
463,8,14,4,0,
463,8,15,4,0,
463,8,21,1,29,
463,8,23,1,21,
463,8,35,1,17,
463,8,48,1,5,
463,8,50,1,25,
463,8,53,4,0,
463,8,57,4,0,
463,8,58,4,0,
463,8,59,4,0,
463,8,63,4,0,
463,8,70,4,0,
463,8,76,4,0,
463,8,85,4,0,
463,8,87,4,0,
463,8,89,4,0,
463,8,91,4,0,
463,8,92,4,0,
463,8,103,1,45,
463,8,104,4,0,
463,8,111,1,9,
463,8,122,1,1,
463,8,126,4,0,
463,8,138,4,0,
463,8,153,4,0,
463,8,156,4,0,
463,8,157,4,0,
463,8,164,4,0,
463,8,168,4,0,
463,8,182,4,0,
463,8,201,4,0,
463,8,203,4,0,
463,8,205,1,33,
463,8,207,4,0,
463,8,213,4,0,
463,8,214,4,0,
463,8,216,4,0,
463,8,218,4,0,
463,8,231,4,0,
463,8,237,4,0,
463,8,240,4,0,
463,8,241,4,0,
463,8,244,4,0,
463,8,247,4,0,
463,8,249,4,0,
463,8,263,4,0,
463,8,264,4,0,
463,8,280,4,0,
463,8,282,1,13,
463,8,287,1,41,
463,8,290,4,0,
463,8,317,4,0,
463,8,351,4,0,
463,8,352,4,0,
463,8,360,1,57,
463,8,360,4,0,
463,8,363,4,0,
463,8,374,4,0,
463,8,378,1,53,
463,8,382,1,37,
463,8,411,4,0,
463,8,416,4,0,
463,8,431,4,0,
463,8,438,1,49,
463,8,445,4,0,
463,9,7,3,0,
463,9,8,3,0,
463,9,9,3,0,
463,9,14,4,0,
463,9,15,4,0,
463,9,21,1,29,
463,9,23,1,21,
463,9,35,1,17,
463,9,48,1,5,
463,9,50,1,25,
463,9,53,4,0,
463,9,57,4,0,
463,9,58,4,0,
463,9,59,4,0,
463,9,63,4,0,
463,9,70,4,0,
463,9,76,4,0,
463,9,85,4,0,
463,9,87,4,0,
463,9,89,4,0,
463,9,91,4,0,
463,9,92,4,0,
463,9,103,1,45,
463,9,104,4,0,
463,9,111,1,9,
463,9,122,1,1,
463,9,126,4,0,
463,9,138,4,0,
463,9,153,4,0,
463,9,156,4,0,
463,9,157,4,0,
463,9,164,4,0,
463,9,168,4,0,
463,9,173,3,0,
463,9,182,4,0,
463,9,189,3,0,
463,9,196,3,0,
463,9,201,4,0,
463,9,203,4,0,
463,9,205,1,33,
463,9,205,3,0,
463,9,207,4,0,
463,9,213,4,0,
463,9,214,4,0,
463,9,216,4,0,
463,9,218,4,0,
463,9,231,4,0,
463,9,237,4,0,
463,9,240,4,0,
463,9,241,4,0,
463,9,244,4,0,
463,9,247,4,0,
463,9,249,4,0,
463,9,263,4,0,
463,9,264,4,0,
463,9,280,4,0,
463,9,282,1,13,
463,9,282,3,0,
463,9,287,1,41,
463,9,290,4,0,
463,9,317,4,0,
463,9,351,4,0,
463,9,352,4,0,
463,9,360,1,57,
463,9,360,4,0,
463,9,363,4,0,
463,9,374,4,0,
463,9,378,1,53,
463,9,382,1,37,
463,9,401,3,0,
463,9,411,4,0,
463,9,416,4,0,
463,9,428,3,0,
463,9,431,4,0,
463,9,438,1,49,
463,9,445,4,0,
463,10,7,3,0,
463,10,8,3,0,
463,10,9,3,0,
463,10,14,4,0,
463,10,15,4,0,
463,10,21,1,29,
463,10,23,1,21,
463,10,29,3,0,
463,10,35,1,17,
463,10,48,1,5,
463,10,50,1,25,
463,10,53,4,0,
463,10,57,4,0,
463,10,58,4,0,
463,10,59,4,0,
463,10,63,4,0,
463,10,70,4,0,
463,10,76,4,0,
463,10,85,4,0,
463,10,87,4,0,
463,10,89,4,0,
463,10,91,4,0,
463,10,92,4,0,
463,10,103,1,45,
463,10,104,4,0,
463,10,111,1,9,
463,10,122,1,1,
463,10,126,4,0,
463,10,138,4,0,
463,10,153,4,0,
463,10,156,4,0,
463,10,157,4,0,
463,10,164,4,0,
463,10,168,4,0,
463,10,173,3,0,
463,10,182,4,0,
463,10,189,3,0,
463,10,196,3,0,
463,10,201,4,0,
463,10,203,4,0,
463,10,205,1,33,
463,10,205,3,0,
463,10,207,4,0,
463,10,213,4,0,
463,10,214,4,0,
463,10,216,4,0,
463,10,218,4,0,
463,10,231,4,0,
463,10,237,4,0,
463,10,240,4,0,
463,10,241,4,0,
463,10,244,4,0,
463,10,247,4,0,
463,10,249,4,0,
463,10,250,4,0,
463,10,263,4,0,
463,10,264,4,0,
463,10,280,4,0,
463,10,282,1,13,
463,10,282,3,0,
463,10,287,1,41,
463,10,290,4,0,
463,10,317,4,0,
463,10,335,3,0,
463,10,351,4,0,
463,10,352,4,0,
463,10,360,1,57,
463,10,360,4,0,
463,10,363,4,0,
463,10,374,4,0,
463,10,378,1,53,
463,10,382,1,37,
463,10,401,3,0,
463,10,411,4,0,
463,10,416,4,0,
463,10,428,3,0,
463,10,431,4,0,
463,10,438,1,49,
463,10,445,4,0,
463,11,14,4,0,
463,11,15,4,0,
463,11,21,1,29,
463,11,23,1,21,
463,11,35,1,17,
463,11,48,1,5,
463,11,50,1,25,
463,11,53,4,0,
463,11,57,4,0,
463,11,58,4,0,
463,11,59,4,0,
463,11,63,4,0,
463,11,70,4,0,
463,11,76,4,0,
463,11,85,4,0,
463,11,87,4,0,
463,11,89,4,0,
463,11,91,4,0,
463,11,92,4,0,
463,11,103,1,49,
463,11,104,4,0,
463,11,111,1,9,
463,11,122,1,1,
463,11,126,4,0,
463,11,138,4,0,
463,11,153,4,0,
463,11,156,4,0,
463,11,157,4,0,
463,11,164,4,0,
463,11,168,4,0,
463,11,182,4,0,
463,11,201,4,0,
463,11,205,1,33,
463,11,207,4,0,
463,11,213,4,0,
463,11,216,4,0,
463,11,218,4,0,
463,11,237,4,0,
463,11,240,4,0,
463,11,241,4,0,
463,11,244,4,0,
463,11,247,4,0,
463,11,249,4,0,
463,11,263,4,0,
463,11,280,4,0,
463,11,282,1,13,
463,11,287,1,45,
463,11,317,4,0,
463,11,360,1,61,
463,11,360,4,0,
463,11,374,4,0,
463,11,378,1,57,
463,11,382,1,41,
463,11,411,4,0,
463,11,416,4,0,
463,11,438,1,53,
463,11,496,4,0,
463,11,498,1,37,
463,11,510,4,0,
463,11,514,4,0,
463,11,523,4,0,
463,11,525,4,0,
463,11,526,4,0,
463,14,7,3,0,
463,14,8,3,0,
463,14,9,3,0,
463,14,14,4,0,
463,14,15,4,0,
463,14,20,3,0,
463,14,21,1,29,
463,14,23,1,21,
463,14,35,1,17,
463,14,48,1,5,
463,14,50,1,25,
463,14,53,4,0,
463,14,57,4,0,
463,14,58,4,0,
463,14,59,4,0,
463,14,63,4,0,
463,14,70,4,0,
463,14,76,4,0,
463,14,85,4,0,
463,14,87,4,0,
463,14,89,4,0,
463,14,91,4,0,
463,14,92,4,0,
463,14,103,1,49,
463,14,104,4,0,
463,14,111,1,9,
463,14,122,1,1,
463,14,126,4,0,
463,14,138,4,0,
463,14,153,4,0,
463,14,156,4,0,
463,14,157,4,0,
463,14,164,4,0,
463,14,168,4,0,
463,14,173,3,0,
463,14,182,4,0,
463,14,196,3,0,
463,14,201,4,0,
463,14,205,1,33,
463,14,207,4,0,
463,14,213,4,0,
463,14,214,3,0,
463,14,216,4,0,
463,14,218,4,0,
463,14,231,3,0,
463,14,237,4,0,
463,14,240,4,0,
463,14,241,4,0,
463,14,244,4,0,
463,14,247,4,0,
463,14,249,4,0,
463,14,263,4,0,
463,14,280,4,0,
463,14,282,1,13,
463,14,282,3,0,
463,14,287,1,45,
463,14,317,4,0,
463,14,335,3,0,
463,14,360,1,61,
463,14,360,4,0,
463,14,374,4,0,
463,14,378,1,57,
463,14,382,1,41,
463,14,401,3,0,
463,14,411,4,0,
463,14,416,4,0,
463,14,428,3,0,
463,14,438,1,53,
463,14,496,4,0,
463,14,498,1,37,
463,14,510,4,0,
463,14,514,4,0,
463,14,523,4,0,
463,14,525,4,0,
463,14,526,4,0,
463,15,14,4,0,
463,15,15,4,0,
463,15,21,1,29,
463,15,23,1,21,
463,15,35,1,17,
463,15,48,1,5,
463,15,50,1,25,
463,15,53,4,0,
463,15,57,4,0,
463,15,58,4,0,
463,15,59,4,0,
463,15,63,4,0,
463,15,70,4,0,
463,15,76,4,0,
463,15,85,4,0,
463,15,87,4,0,
463,15,89,4,0,
463,15,91,4,0,
463,15,92,4,0,
463,15,103,1,49,
463,15,104,4,0,
463,15,111,1,9,
463,15,122,1,1,3
463,15,126,4,0,
463,15,138,4,0,
463,15,153,4,0,
463,15,156,4,0,
463,15,157,4,0,
463,15,164,4,0,
463,15,168,4,0,
463,15,182,4,0,
463,15,201,4,0,
463,15,205,1,33,
463,15,207,4,0,
463,15,213,4,0,
463,15,214,4,0,
463,15,216,4,0,
463,15,218,4,0,
463,15,237,4,0,
463,15,240,4,0,
463,15,241,4,0,
463,15,244,4,0,
463,15,247,4,0,
463,15,249,4,0,
463,15,263,4,0,
463,15,280,4,0,
463,15,282,1,13,
463,15,287,1,45,
463,15,317,4,0,
463,15,360,1,61,
463,15,360,4,0,
463,15,374,4,0,
463,15,378,1,1,1
463,15,378,1,57,
463,15,382,1,41,
463,15,411,4,0,
463,15,416,4,0,
463,15,438,1,1,2
463,15,438,1,53,
463,15,496,4,0,
463,15,498,1,37,
463,15,510,4,0,
463,15,514,4,0,
463,15,523,4,0,
463,15,525,4,0,
463,15,590,4,0,
463,15,612,4,0,
463,16,7,3,0,
463,16,8,3,0,
463,16,9,3,0,
463,16,14,4,0,
463,16,15,4,0,
463,16,20,3,0,
463,16,21,1,29,1
463,16,23,1,21,1
463,16,35,1,17,1
463,16,48,1,5,1
463,16,50,1,25,1
463,16,53,4,0,
463,16,57,4,0,
463,16,58,4,0,
463,16,59,4,0,
463,16,63,4,0,
463,16,70,4,0,
463,16,76,4,0,
463,16,85,4,0,
463,16,87,4,0,
463,16,89,4,0,
463,16,91,4,0,
463,16,92,4,0,
463,16,103,1,49,1
463,16,104,4,0,
463,16,111,1,9,1
463,16,122,1,1,3
463,16,126,4,0,
463,16,138,4,0,
463,16,153,4,0,
463,16,156,4,0,
463,16,157,4,0,
463,16,164,4,0,
463,16,168,4,0,
463,16,173,3,0,
463,16,182,4,0,
463,16,196,3,0,
463,16,201,4,0,
463,16,205,1,33,1
463,16,207,4,0,
463,16,213,4,0,
463,16,214,4,0,
463,16,216,4,0,
463,16,218,4,0,
463,16,231,3,0,
463,16,237,4,0,
463,16,240,4,0,
463,16,241,4,0,
463,16,244,4,0,
463,16,247,4,0,
463,16,249,4,0,
463,16,263,4,0,
463,16,264,3,0,
463,16,280,4,0,
463,16,282,1,13,1
463,16,282,3,0,
463,16,287,1,45,1
463,16,290,4,0,
463,16,317,4,0,
463,16,335,3,0,
463,16,351,3,0,
463,16,352,3,0,
463,16,360,1,61,1
463,16,360,4,0,
463,16,374,4,0,
463,16,378,1,1,1
463,16,378,1,57,1
463,16,382,1,41,1
463,16,401,3,0,
463,16,411,4,0,
463,16,416,4,0,
463,16,428,3,0,
463,16,438,1,1,2
463,16,438,1,53,1
463,16,496,4,0,
463,16,498,1,37,1
463,16,510,4,0,
463,16,514,4,0,
463,16,523,4,0,
463,16,525,4,0,
463,16,590,4,0,
463,16,612,4,0,
463,17,14,4,0,
463,17,21,1,29,
463,17,23,1,21,
463,17,35,1,17,
463,17,48,1,5,
463,17,50,1,25,
463,17,53,4,0,
463,17,57,4,0,
463,17,58,4,0,
463,17,59,4,0,
463,17,63,4,0,
463,17,76,4,0,
463,17,85,4,0,
463,17,87,4,0,
463,17,89,4,0,
463,17,92,4,0,
463,17,103,1,49,
463,17,104,4,0,
463,17,111,1,9,
463,17,122,1,1,3
463,17,126,4,0,
463,17,138,4,0,
463,17,153,4,0,
463,17,156,4,0,
463,17,157,4,0,
463,17,164,4,0,
463,17,168,4,0,
463,17,182,4,0,
463,17,201,4,0,
463,17,205,1,33,
463,17,207,4,0,
463,17,213,4,0,
463,17,214,4,0,
463,17,216,4,0,
463,17,218,4,0,
463,17,237,4,0,
463,17,240,4,0,
463,17,241,4,0,
463,17,244,4,0,
463,17,247,4,0,
463,17,263,4,0,
463,17,280,4,0,
463,17,282,1,13,
463,17,287,1,45,
463,17,317,4,0,
463,17,360,1,61,
463,17,360,4,0,
463,17,374,4,0,
463,17,378,1,1,1
463,17,378,1,57,
463,17,382,1,41,
463,17,411,4,0,
463,17,416,4,0,
463,17,438,1,1,2
463,17,438,1,53,
463,17,496,4,0,
463,17,498,1,37,
463,17,523,4,0,
463,17,525,4,0,
463,17,526,4,0,
463,17,590,4,0,
463,17,693,4,0,
463,18,14,4,0,
463,18,21,1,29,
463,18,23,1,21,
463,18,35,1,17,
463,18,48,1,5,
463,18,50,1,25,
463,18,53,4,0,
463,18,57,4,0,
463,18,58,4,0,
463,18,59,4,0,
463,18,63,4,0,
463,18,76,4,0,
463,18,85,4,0,
463,18,87,4,0,
463,18,89,4,0,
463,18,92,4,0,
463,18,103,1,49,
463,18,104,4,0,
463,18,111,1,9,
463,18,122,1,1,3
463,18,126,4,0,
463,18,138,4,0,
463,18,153,4,0,
463,18,156,4,0,
463,18,157,4,0,
463,18,164,4,0,
463,18,168,4,0,
463,18,182,4,0,
463,18,201,4,0,
463,18,205,1,33,
463,18,207,4,0,
463,18,213,4,0,
463,18,214,4,0,
463,18,216,4,0,
463,18,218,4,0,
463,18,237,4,0,
463,18,240,4,0,
463,18,241,4,0,
463,18,244,4,0,
463,18,247,4,0,
463,18,263,4,0,
463,18,280,4,0,
463,18,282,1,13,
463,18,287,1,45,
463,18,317,4,0,
463,18,360,1,61,
463,18,360,4,0,
463,18,374,4,0,
463,18,378,1,1,1
463,18,378,1,57,
463,18,382,1,41,
463,18,411,4,0,
463,18,416,4,0,
463,18,438,1,1,2
463,18,438,1,53,
463,18,496,4,0,
463,18,498,1,37,
463,18,523,4,0,
463,18,525,4,0,
463,18,526,4,0,
463,18,590,4,0,
463,18,693,4,0,
464,8,14,4,0,
464,8,15,4,0,
464,8,23,1,1,4
464,8,23,1,9,
464,8,30,1,1,2
464,8,31,1,1,5
464,8,31,1,13,
464,8,32,1,37,
464,8,36,1,33,
464,8,39,1,1,3
464,8,46,4,0,
464,8,53,4,0,
464,8,57,4,0,
464,8,58,4,0,
464,8,59,4,0,
464,8,63,4,0,
464,8,70,4,0,
464,8,85,4,0,
464,8,87,4,0,
464,8,89,1,49,
464,8,89,4,0,
464,8,91,4,0,
464,8,92,4,0,
464,8,104,4,0,
464,8,126,4,0,
464,8,156,4,0,
464,8,157,4,0,
464,8,164,4,0,
464,8,168,4,0,
464,8,182,4,0,
464,8,184,1,21,
464,8,201,4,0,
464,8,203,4,0,
464,8,207,4,0,
464,8,213,4,0,
464,8,214,4,0,
464,8,216,4,0,
464,8,218,4,0,
464,8,224,1,57,
464,8,231,4,0,
464,8,237,4,0,
464,8,240,4,0,
464,8,241,4,0,
464,8,249,4,0,
464,8,263,4,0,
464,8,264,4,0,
464,8,280,4,0,
464,8,290,4,0,
464,8,317,4,0,
464,8,350,1,25,
464,8,351,4,0,
464,8,359,1,42,
464,8,363,4,0,
464,8,371,4,0,
464,8,374,4,0,
464,8,397,4,0,
464,8,398,1,1,1
464,8,398,4,0,
464,8,406,4,0,
464,8,411,4,0,
464,8,416,4,0,
464,8,419,4,0,
464,8,421,4,0,
464,8,430,4,0,
464,8,431,4,0,
464,8,439,1,61,
464,8,444,1,45,
464,8,444,4,0,
464,8,445,4,0,
464,8,446,4,0,
464,9,7,3,0,
464,9,8,3,0,
464,9,9,3,0,
464,9,14,4,0,
464,9,15,4,0,
464,9,23,1,1,4
464,9,23,1,9,
464,9,30,1,1,2
464,9,31,1,1,5
464,9,31,1,13,
464,9,32,1,37,
464,9,36,1,33,
464,9,39,1,1,3
464,9,46,4,0,
464,9,53,4,0,
464,9,57,4,0,
464,9,58,4,0,
464,9,59,4,0,
464,9,63,4,0,
464,9,70,4,0,
464,9,85,4,0,
464,9,87,4,0,
464,9,89,1,49,
464,9,89,4,0,
464,9,91,4,0,
464,9,92,4,0,
464,9,104,4,0,
464,9,126,4,0,
464,9,156,4,0,
464,9,157,4,0,
464,9,164,4,0,
464,9,168,4,0,
464,9,173,3,0,
464,9,180,3,0,
464,9,182,4,0,
464,9,184,1,21,
464,9,189,3,0,
464,9,196,3,0,
464,9,200,3,0,
464,9,201,4,0,
464,9,203,4,0,
464,9,205,3,0,
464,9,207,4,0,
464,9,210,3,0,
464,9,213,4,0,
464,9,214,4,0,
464,9,216,4,0,
464,9,218,4,0,
464,9,224,1,57,
464,9,231,4,0,
464,9,237,4,0,
464,9,240,4,0,
464,9,241,4,0,
464,9,246,3,0,
464,9,249,4,0,
464,9,253,3,0,
464,9,263,4,0,
464,9,264,4,0,
464,9,276,3,0,
464,9,280,4,0,
464,9,283,3,0,
464,9,290,4,0,
464,9,317,4,0,
464,9,350,1,25,
464,9,351,4,0,
464,9,359,1,42,
464,9,363,4,0,
464,9,371,4,0,
464,9,374,4,0,
464,9,397,4,0,
464,9,398,1,1,1
464,9,398,4,0,
464,9,401,3,0,
464,9,406,4,0,
464,9,411,4,0,
464,9,414,3,0,
464,9,416,4,0,
464,9,419,4,0,
464,9,421,4,0,
464,9,430,4,0,
464,9,431,4,0,
464,9,439,1,61,
464,9,442,3,0,
464,9,444,1,45,
464,9,444,4,0,
464,9,445,4,0,
464,9,446,4,0,
464,10,7,3,0,
464,10,8,3,0,
464,10,9,3,0,
464,10,14,4,0,
464,10,15,4,0,
464,10,23,1,1,4
464,10,23,1,9,
464,10,29,3,0,
464,10,30,1,1,2
464,10,31,1,1,5
464,10,31,1,13,
464,10,32,1,37,
464,10,36,1,33,
464,10,39,1,1,3
464,10,46,4,0,
464,10,53,4,0,
464,10,57,4,0,
464,10,58,4,0,
464,10,59,4,0,
464,10,63,4,0,
464,10,70,4,0,
464,10,85,4,0,
464,10,87,4,0,
464,10,89,1,49,
464,10,89,4,0,
464,10,91,4,0,
464,10,92,4,0,
464,10,104,4,0,
464,10,126,4,0,
464,10,156,4,0,
464,10,157,4,0,
464,10,164,4,0,
464,10,168,4,0,
464,10,173,3,0,
464,10,180,3,0,
464,10,182,4,0,
464,10,184,1,21,
464,10,189,3,0,
464,10,196,3,0,
464,10,200,3,0,
464,10,201,4,0,
464,10,203,4,0,
464,10,205,3,0,
464,10,207,4,0,
464,10,210,3,0,
464,10,213,4,0,
464,10,214,4,0,
464,10,216,4,0,
464,10,218,4,0,
464,10,224,1,57,
464,10,231,4,0,
464,10,237,4,0,
464,10,240,4,0,
464,10,241,4,0,
464,10,246,3,0,
464,10,249,4,0,
464,10,250,4,0,
464,10,253,3,0,
464,10,263,4,0,
464,10,264,4,0,
464,10,276,3,0,
464,10,280,4,0,
464,10,283,3,0,
464,10,290,4,0,
464,10,317,4,0,
464,10,335,3,0,
464,10,350,1,25,
464,10,351,4,0,
464,10,359,1,42,
464,10,363,4,0,
464,10,371,4,0,
464,10,374,4,0,
464,10,397,4,0,
464,10,398,1,1,1
464,10,398,4,0,
464,10,401,3,0,
464,10,406,4,0,
464,10,411,4,0,
464,10,414,3,0,
464,10,416,4,0,
464,10,419,4,0,
464,10,421,4,0,
464,10,430,4,0,
464,10,431,4,0,
464,10,439,1,61,
464,10,442,3,0,
464,10,444,1,45,
464,10,444,4,0,
464,10,445,4,0,
464,10,446,4,0,
464,11,14,4,0,
464,11,15,4,0,
464,11,23,1,1,4
464,11,23,1,9,
464,11,30,1,1,2
464,11,31,1,1,5
464,11,31,1,19,1
464,11,32,1,71,
464,11,36,1,41,
464,11,39,1,1,3
464,11,46,4,0,
464,11,53,4,0,
464,11,57,4,0,
464,11,58,4,0,
464,11,59,4,0,
464,11,63,4,0,
464,11,70,4,0,
464,11,85,4,0,
464,11,87,4,0,
464,11,89,1,62,
464,11,89,4,0,
464,11,91,4,0,
464,11,92,4,0,
464,11,104,4,0,
464,11,126,4,0,
464,11,156,4,0,
464,11,157,4,0,
464,11,164,4,0,
464,11,168,4,0,
464,11,182,4,0,
464,11,184,1,19,2
464,11,201,4,0,
464,11,207,4,0,
464,11,213,4,0,
464,11,216,4,0,
464,11,218,4,0,
464,11,224,1,77,
464,11,237,4,0,
464,11,240,4,0,
464,11,241,4,0,
464,11,249,4,0,
464,11,263,4,0,
464,11,280,4,0,
464,11,317,4,0,
464,11,350,1,23,
464,11,359,1,42,
464,11,371,4,0,
464,11,374,4,0,
464,11,397,4,0,
464,11,398,1,1,1
464,11,398,4,0,
464,11,411,4,0,
464,11,416,4,0,
464,11,421,4,0,
464,11,430,4,0,
464,11,439,1,86,
464,11,444,1,56,
464,11,444,4,0,
464,11,479,4,0,
464,11,496,4,0,
464,11,498,1,30,
464,11,510,4,0,
464,11,523,4,0,
464,11,525,4,0,
464,11,529,1,47,
464,14,7,3,0,
464,14,8,3,0,
464,14,9,3,0,
464,14,14,4,0,
464,14,15,4,0,
464,14,23,1,1,4
464,14,23,1,9,
464,14,30,1,1,2
464,14,31,1,1,5
464,14,31,1,19,1
464,14,32,1,71,
464,14,36,1,41,
464,14,39,1,1,3
464,14,46,4,0,
464,14,53,4,0,
464,14,57,4,0,
464,14,58,4,0,
464,14,59,4,0,
464,14,63,4,0,
464,14,70,4,0,
464,14,85,4,0,
464,14,87,4,0,
464,14,89,1,62,
464,14,89,4,0,
464,14,91,4,0,
464,14,92,4,0,
464,14,104,4,0,
464,14,126,4,0,
464,14,156,4,0,
464,14,157,4,0,
464,14,164,4,0,
464,14,168,4,0,
464,14,173,3,0,
464,14,180,3,0,
464,14,182,4,0,
464,14,184,1,19,2
464,14,196,3,0,
464,14,200,3,0,
464,14,201,4,0,
464,14,207,4,0,
464,14,213,4,0,
464,14,214,3,0,
464,14,216,4,0,
464,14,218,4,0,
464,14,224,1,77,
464,14,231,3,0,
464,14,237,4,0,
464,14,240,4,0,
464,14,241,4,0,
464,14,249,4,0,
464,14,253,3,0,
464,14,263,4,0,
464,14,276,3,0,
464,14,280,4,0,
464,14,283,3,0,
464,14,317,4,0,
464,14,335,3,0,
464,14,350,1,23,
464,14,359,1,42,
464,14,371,4,0,
464,14,374,4,0,
464,14,397,4,0,
464,14,398,1,1,1
464,14,398,4,0,
464,14,401,3,0,
464,14,406,3,0,
464,14,411,4,0,
464,14,414,3,0,
464,14,416,4,0,
464,14,421,4,0,
464,14,430,4,0,
464,14,439,1,86,
464,14,442,3,0,
464,14,444,1,56,
464,14,444,4,0,
464,14,446,3,0,
464,14,479,4,0,
464,14,496,4,0,
464,14,498,1,30,
464,14,510,4,0,
464,14,523,4,0,
464,14,525,4,0,
464,14,529,1,47,
464,14,529,3,0,
464,15,14,4,0,
464,15,15,4,0,
464,15,23,1,1,7
464,15,23,1,9,
464,15,30,1,1,5
464,15,31,1,1,8
464,15,31,1,19,1
464,15,32,1,1,3
464,15,32,1,71,
464,15,36,1,41,
464,15,39,1,1,6
464,15,46,4,0,
464,15,53,4,0,
464,15,57,4,0,
464,15,58,4,0,
464,15,59,4,0,
464,15,63,4,0,
464,15,70,4,0,
464,15,85,4,0,
464,15,87,4,0,
464,15,89,1,62,
464,15,89,4,0,
464,15,91,4,0,
464,15,92,4,0,
464,15,104,4,0,
464,15,126,4,0,
464,15,156,4,0,
464,15,157,4,0,
464,15,164,4,0,
464,15,168,4,0,
464,15,182,4,0,
464,15,184,1,19,2
464,15,201,4,0,
464,15,207,4,0,
464,15,213,4,0,
464,15,214,4,0,
464,15,216,4,0,
464,15,218,4,0,
464,15,224,1,1,2
464,15,224,1,77,
464,15,237,4,0,
464,15,240,4,0,
464,15,241,4,0,
464,15,249,4,0,
464,15,263,4,0,
464,15,280,4,0,
464,15,317,4,0,
464,15,350,1,23,
464,15,359,1,42,
464,15,371,4,0,
464,15,374,4,0,
464,15,397,4,0,
464,15,398,1,1,4
464,15,398,4,0,
464,15,411,4,0,
464,15,416,4,0,
464,15,421,4,0,
464,15,430,4,0,
464,15,439,1,1,1
464,15,439,1,86,
464,15,444,1,56,
464,15,444,4,0,
464,15,479,4,0,
464,15,496,4,0,
464,15,498,1,30,
464,15,510,4,0,
464,15,523,4,0,
464,15,525,4,0,
464,15,529,1,47,
464,15,590,4,0,
464,15,612,4,0,
464,16,7,3,0,
464,16,8,3,0,
464,16,9,3,0,
464,16,14,4,0,
464,16,15,4,0,
464,16,23,1,17,1
464,16,30,1,1,4
464,16,31,1,1,6
464,16,31,1,5,1
464,16,32,1,1,2
464,16,32,1,62,1
464,16,36,1,37,1
464,16,39,1,1,5
464,16,46,4,0,
464,16,53,4,0,
464,16,57,4,0,
464,16,58,4,0,
464,16,59,4,0,
464,16,63,4,0,
464,16,70,4,0,
464,16,85,4,0,
464,16,87,4,0,
464,16,89,1,48,1
464,16,89,4,0,
464,16,91,4,0,
464,16,92,4,0,
464,16,104,4,0,
464,16,126,4,0,
464,16,156,4,0,
464,16,157,4,0,
464,16,164,4,0,
464,16,168,4,0,
464,16,173,3,0,
464,16,180,3,0,
464,16,182,4,0,
464,16,184,1,1,7
464,16,184,1,9,1
464,16,196,3,0,
464,16,200,3,0,
464,16,201,4,0,
464,16,207,4,0,
464,16,213,4,0,
464,16,214,4,0,
464,16,216,4,0,
464,16,218,4,0,
464,16,224,1,55,1
464,16,231,3,0,
464,16,237,4,0,
464,16,240,4,0,
464,16,241,4,0,
464,16,249,4,0,
464,16,253,3,0,
464,16,263,4,0,
464,16,264,3,0,
464,16,276,3,0,
464,16,280,4,0,
464,16,283,3,0,
464,16,290,4,0,
464,16,317,4,0,
464,16,335,3,0,
464,16,350,1,29,1
464,16,351,3,0,
464,16,359,1,42,1
464,16,371,4,0,
464,16,374,4,0,
464,16,397,4,0,
464,16,398,1,1,3
464,16,398,4,0,
464,16,401,3,0,
464,16,406,3,0,
464,16,411,4,0,
464,16,414,3,0,
464,16,416,4,0,
464,16,421,4,0,
464,16,430,4,0,
464,16,439,1,1,1
464,16,439,1,69,1
464,16,442,3,0,
464,16,444,1,41,1
464,16,444,4,0,
464,16,446,3,0,
464,16,479,1,13,1
464,16,479,4,0,
464,16,496,4,0,
464,16,498,1,25,1
464,16,510,4,0,
464,16,523,1,21,1
464,16,523,4,0,
464,16,525,4,0,
464,16,529,1,33,1
464,16,529,3,0,
464,16,590,4,0,
464,16,612,4,0,
464,17,14,4,0,
464,17,23,1,17,
464,17,30,1,1,5
464,17,31,1,1,7
464,17,31,1,5,
464,17,32,1,1,3
464,17,32,1,62,
464,17,36,1,37,
464,17,39,1,1,6
464,17,46,4,0,
464,17,53,4,0,
464,17,57,4,0,
464,17,58,4,0,
464,17,59,4,0,
464,17,63,4,0,
464,17,85,4,0,
464,17,87,4,0,
464,17,89,1,48,
464,17,89,4,0,
464,17,92,4,0,
464,17,104,4,0,
464,17,126,4,0,
464,17,156,4,0,
464,17,157,4,0,
464,17,164,4,0,
464,17,168,4,0,
464,17,182,4,0,
464,17,184,1,1,8
464,17,184,1,9,
464,17,201,4,0,
464,17,207,4,0,
464,17,213,4,0,
464,17,214,4,0,
464,17,216,4,0,
464,17,218,4,0,
464,17,224,1,55,
464,17,237,4,0,
464,17,240,4,0,
464,17,241,4,0,
464,17,263,4,0,
464,17,280,4,0,
464,17,317,4,0,
464,17,350,1,29,
464,17,359,1,1,1
464,17,371,4,0,
464,17,374,4,0,
464,17,397,4,0,
464,17,398,1,1,4
464,17,398,4,0,
464,17,411,4,0,
464,17,416,4,0,
464,17,421,4,0,
464,17,430,4,0,
464,17,439,1,1,2
464,17,439,1,69,
464,17,444,1,41,
464,17,444,4,0,
464,17,479,1,13,
464,17,479,4,0,
464,17,496,4,0,
464,17,498,1,25,
464,17,523,1,21,
464,17,523,4,0,
464,17,525,4,0,
464,17,529,1,33,
464,17,590,4,0,
464,17,684,4,0,
464,17,693,4,0,
464,18,14,4,0,
464,18,23,1,17,
464,18,30,1,1,5
464,18,31,1,1,7
464,18,31,1,5,
464,18,32,1,1,3
464,18,32,1,62,
464,18,36,1,37,
464,18,39,1,1,6
464,18,46,4,0,
464,18,53,4,0,
464,18,57,4,0,
464,18,58,4,0,
464,18,59,4,0,
464,18,63,4,0,
464,18,85,4,0,
464,18,87,4,0,
464,18,89,1,48,
464,18,89,4,0,
464,18,92,4,0,
464,18,104,4,0,
464,18,126,4,0,
464,18,156,4,0,
464,18,157,4,0,
464,18,164,4,0,
464,18,168,4,0,
464,18,182,4,0,
464,18,184,1,1,8
464,18,184,1,9,
464,18,201,4,0,
464,18,207,4,0,
464,18,213,4,0,
464,18,214,4,0,
464,18,216,4,0,
464,18,218,4,0,
464,18,224,1,55,
464,18,237,4,0,
464,18,240,4,0,
464,18,241,4,0,
464,18,263,4,0,
464,18,280,4,0,
464,18,317,4,0,
464,18,350,1,29,
464,18,359,1,1,1
464,18,371,4,0,
464,18,374,4,0,
464,18,397,4,0,
464,18,398,1,1,4
464,18,398,4,0,
464,18,411,4,0,
464,18,416,4,0,
464,18,421,4,0,
464,18,430,4,0,
464,18,439,1,1,2
464,18,439,1,69,
464,18,444,1,41,
464,18,444,4,0,
464,18,479,1,13,
464,18,479,4,0,
464,18,496,4,0,
464,18,498,1,25,
464,18,523,1,21,
464,18,523,4,0,
464,18,525,4,0,
464,18,529,1,33,
464,18,590,4,0,
464,18,684,4,0,
464,18,693,4,0,
465,8,14,4,0,
465,8,15,4,0,
465,8,20,1,22,
465,8,21,1,43,
465,8,22,1,19,
465,8,63,4,0,
465,8,70,4,0,
465,8,71,1,8,
465,8,72,1,26,
465,8,74,1,12,
465,8,76,4,0,
465,8,77,1,15,
465,8,78,1,29,
465,8,79,1,5,
465,8,89,4,0,
465,8,92,4,0,
465,8,104,4,0,
465,8,115,4,0,
465,8,132,1,1,2
465,8,148,4,0,
465,8,156,4,0,
465,8,157,4,0,
465,8,164,4,0,
465,8,168,4,0,
465,8,182,4,0,
465,8,188,4,0,
465,8,202,4,0,
465,8,203,4,0,
465,8,207,4,0,
465,8,213,4,0,
465,8,214,4,0,
465,8,216,4,0,
465,8,218,4,0,
465,8,237,4,0,
465,8,241,4,0,
465,8,244,4,0,
465,8,246,1,33,
465,8,249,4,0,
465,8,263,4,0,
465,8,275,1,1,1
465,8,280,4,0,
465,8,282,1,36,
465,8,290,4,0,
465,8,317,4,0,
465,8,321,1,47,
465,8,331,4,0,
465,8,332,4,0,
465,8,335,1,57,
465,8,351,4,0,
465,8,363,1,40,
465,8,363,4,0,
465,8,371,4,0,
465,8,374,4,0,
465,8,378,1,50,
465,8,398,4,0,
465,8,411,4,0,
465,8,412,4,0,
465,8,416,4,0,
465,8,438,1,54,
465,8,445,4,0,
465,8,447,4,0,
465,9,14,4,0,
465,9,15,4,0,
465,9,20,1,22,
465,9,21,1,43,
465,9,22,1,19,
465,9,63,4,0,
465,9,70,4,0,
465,9,71,1,8,
465,9,72,1,26,
465,9,74,1,12,
465,9,76,4,0,
465,9,77,1,15,
465,9,78,1,29,
465,9,79,1,5,
465,9,89,4,0,
465,9,92,4,0,
465,9,104,4,0,
465,9,115,4,0,
465,9,132,1,1,2
465,9,148,4,0,
465,9,156,4,0,
465,9,157,4,0,
465,9,164,4,0,
465,9,168,4,0,
465,9,173,3,0,
465,9,182,4,0,
465,9,188,4,0,
465,9,189,3,0,
465,9,202,4,0,
465,9,203,4,0,
465,9,207,4,0,
465,9,213,4,0,
465,9,214,4,0,
465,9,216,4,0,
465,9,218,4,0,
465,9,235,3,0,
465,9,237,4,0,
465,9,241,4,0,
465,9,244,4,0,
465,9,246,1,33,
465,9,246,3,0,
465,9,249,4,0,
465,9,263,4,0,
465,9,275,1,1,1
465,9,280,4,0,
465,9,282,1,36,
465,9,282,3,0,
465,9,290,4,0,
465,9,317,4,0,
465,9,321,1,47,
465,9,331,4,0,
465,9,332,4,0,
465,9,335,1,57,
465,9,351,4,0,
465,9,363,1,40,
465,9,363,4,0,
465,9,371,4,0,
465,9,374,4,0,
465,9,378,1,50,
465,9,398,4,0,
465,9,402,3,0,
465,9,411,4,0,
465,9,412,4,0,
465,9,416,4,0,
465,9,438,1,54,
465,9,445,4,0,
465,9,447,4,0,
465,10,14,4,0,
465,10,15,4,0,
465,10,20,1,22,
465,10,21,1,43,
465,10,22,1,19,
465,10,29,3,0,
465,10,63,4,0,
465,10,70,4,0,
465,10,71,1,8,
465,10,72,1,26,
465,10,74,1,12,
465,10,76,4,0,
465,10,77,1,15,
465,10,78,1,29,
465,10,79,1,5,
465,10,89,4,0,
465,10,92,4,0,
465,10,104,4,0,
465,10,115,4,0,
465,10,132,1,1,2
465,10,148,4,0,
465,10,156,4,0,
465,10,157,4,0,
465,10,164,4,0,
465,10,168,4,0,
465,10,173,3,0,
465,10,182,4,0,
465,10,188,4,0,
465,10,189,3,0,
465,10,202,4,0,
465,10,203,4,0,
465,10,207,4,0,
465,10,213,4,0,
465,10,214,4,0,
465,10,216,4,0,
465,10,218,4,0,
465,10,220,3,0,
465,10,235,3,0,
465,10,237,4,0,
465,10,241,4,0,
465,10,244,4,0,
465,10,246,1,33,
465,10,246,3,0,
465,10,249,4,0,
465,10,263,4,0,
465,10,275,1,1,1
465,10,280,4,0,
465,10,282,1,36,
465,10,282,3,0,
465,10,290,4,0,
465,10,317,4,0,
465,10,321,1,47,
465,10,331,4,0,
465,10,332,4,0,
465,10,335,1,57,
465,10,335,3,0,
465,10,351,4,0,
465,10,363,1,40,
465,10,363,4,0,
465,10,371,4,0,
465,10,374,4,0,
465,10,378,1,50,
465,10,388,3,0,
465,10,398,4,0,
465,10,402,3,0,
465,10,411,4,0,
465,10,412,4,0,
465,10,416,4,0,
465,10,438,1,54,
465,10,445,4,0,
465,10,447,4,0,
465,11,14,4,0,
465,11,15,4,0,
465,11,20,1,22,
465,11,21,1,43,
465,11,22,1,19,
465,11,63,4,0,
465,11,70,4,0,
465,11,71,1,8,
465,11,72,1,26,
465,11,74,1,12,
465,11,76,4,0,
465,11,77,1,15,
465,11,78,1,29,
465,11,79,1,5,
465,11,89,4,0,
465,11,92,4,0,
465,11,104,4,0,
465,11,115,4,0,
465,11,132,1,1,2
465,11,148,4,0,
465,11,156,4,0,
465,11,157,4,0,
465,11,164,4,0,
465,11,168,4,0,
465,11,182,4,0,
465,11,188,4,0,
465,11,207,4,0,
465,11,213,4,0,
465,11,216,4,0,
465,11,218,4,0,
465,11,237,4,0,
465,11,241,4,0,
465,11,244,4,0,
465,11,246,1,36,
465,11,249,4,0,
465,11,263,4,0,
465,11,275,1,1,1
465,11,280,4,0,
465,11,282,1,33,
465,11,317,4,0,
465,11,321,1,47,
465,11,332,4,0,
465,11,335,1,57,
465,11,363,1,40,
465,11,371,4,0,
465,11,374,4,0,
465,11,378,1,50,
465,11,398,4,0,
465,11,411,4,0,
465,11,412,4,0,
465,11,416,4,0,
465,11,438,1,54,
465,11,447,4,0,
465,11,496,4,0,
465,11,523,4,0,
465,14,14,4,0,
465,14,15,4,0,
465,14,20,1,17,
465,14,20,3,0,
465,14,21,1,43,
465,14,22,1,7,
465,14,63,4,0,
465,14,70,4,0,
465,14,71,1,10,
465,14,72,1,23,
465,14,74,1,20,
465,14,76,4,0,
465,14,77,1,14,
465,14,78,1,30,
465,14,79,1,4,
465,14,89,4,0,
465,14,92,4,0,
465,14,104,4,0,
465,14,115,4,0,
465,14,132,1,1,2
465,14,148,4,0,
465,14,156,4,0,
465,14,157,4,0,
465,14,164,4,0,
465,14,168,4,0,
465,14,173,3,0,
465,14,182,4,0,
465,14,188,4,0,
465,14,202,1,36,
465,14,202,3,0,
465,14,207,4,0,
465,14,213,4,0,
465,14,214,3,0,
465,14,216,4,0,
465,14,218,4,0,
465,14,220,3,0,
465,14,235,3,0,
465,14,237,4,0,
465,14,241,4,0,
465,14,244,4,0,
465,14,246,1,40,
465,14,249,4,0,
465,14,263,4,0,
465,14,275,1,1,1
465,14,280,4,0,
465,14,282,1,27,
465,14,282,3,0,
465,14,283,3,0,
465,14,317,4,0,
465,14,321,1,46,
465,14,332,4,0,
465,14,335,1,56,
465,14,335,3,0,
465,14,363,1,33,
465,14,371,4,0,
465,14,374,4,0,
465,14,378,1,49,
465,14,388,3,0,
465,14,398,4,0,
465,14,402,3,0,
465,14,411,4,0,
465,14,412,4,0,
465,14,416,4,0,
465,14,438,1,53,
465,14,447,4,0,
465,14,496,4,0,
465,14,523,4,0,
465,15,14,4,0,
465,15,15,4,0,
465,15,20,1,17,
465,15,21,1,43,
465,15,22,1,7,
465,15,63,4,0,
465,15,70,4,0,
465,15,71,1,10,
465,15,72,1,23,
465,15,74,1,20,
465,15,76,4,0,
465,15,77,1,14,
465,15,78,1,30,
465,15,79,1,4,
465,15,89,4,0,
465,15,92,4,0,
465,15,104,4,0,
465,15,115,4,0,
465,15,132,1,1,3
465,15,148,4,0,
465,15,156,4,0,
465,15,157,4,0,
465,15,164,4,0,
465,15,168,4,0,
465,15,182,4,0,
465,15,188,4,0,
465,15,202,1,36,
465,15,207,4,0,
465,15,213,4,0,
465,15,214,4,0,
465,15,216,4,0,
465,15,218,4,0,
465,15,237,4,0,
465,15,241,4,0,
465,15,244,4,0,
465,15,246,1,40,
465,15,249,4,0,
465,15,263,4,0,
465,15,267,4,0,
465,15,275,1,1,2
465,15,280,4,0,
465,15,282,1,27,
465,15,317,4,0,
465,15,321,1,46,
465,15,332,4,0,
465,15,335,1,1,1
465,15,335,1,56,
465,15,363,1,33,
465,15,371,4,0,
465,15,374,4,0,
465,15,378,1,49,
465,15,398,4,0,
465,15,411,4,0,
465,15,412,4,0,
465,15,416,4,0,
465,15,438,1,53,
465,15,447,4,0,
465,15,496,4,0,
465,15,523,4,0,
465,15,580,1,50,
465,15,590,4,0,
465,15,611,4,0,
465,16,14,4,0,
465,16,15,4,0,
465,16,20,1,17,1
465,16,20,3,0,
465,16,21,1,43,1
465,16,22,1,7,1
465,16,63,4,0,
465,16,70,4,0,
465,16,71,1,10,1
465,16,72,1,23,1
465,16,74,1,20,1
465,16,76,4,0,
465,16,77,1,14,1
465,16,78,1,30,1
465,16,79,1,4,1
465,16,89,4,0,
465,16,92,4,0,
465,16,104,4,0,
465,16,115,4,0,
465,16,132,1,1,3
465,16,148,4,0,
465,16,156,4,0,
465,16,157,4,0,
465,16,164,4,0,
465,16,168,4,0,
465,16,173,3,0,
465,16,182,4,0,
465,16,188,4,0,
465,16,202,1,36,1
465,16,202,3,0,
465,16,207,4,0,
465,16,213,4,0,
465,16,214,4,0,
465,16,216,4,0,
465,16,218,4,0,
465,16,220,3,0,
465,16,235,3,0,
465,16,237,4,0,
465,16,241,4,0,
465,16,244,4,0,
465,16,246,1,40,1
465,16,249,4,0,
465,16,263,4,0,
465,16,267,4,0,
465,16,275,1,1,2
465,16,280,4,0,
465,16,282,1,27,1
465,16,282,3,0,
465,16,283,3,0,
465,16,290,4,0,
465,16,317,4,0,
465,16,321,1,46,1
465,16,332,4,0,
465,16,335,1,1,1
465,16,335,1,56,1
465,16,335,3,0,
465,16,351,3,0,
465,16,363,1,33,1
465,16,371,4,0,
465,16,374,4,0,
465,16,378,1,49,1
465,16,388,3,0,
465,16,398,4,0,
465,16,402,3,0,
465,16,411,4,0,
465,16,412,4,0,
465,16,416,4,0,
465,16,438,1,53,1
465,16,447,4,0,
465,16,496,4,0,
465,16,523,4,0,
465,16,580,1,50,1
465,16,590,4,0,
465,16,611,4,0,
465,17,14,4,0,
465,17,20,1,17,
465,17,21,1,43,
465,17,22,1,7,
465,17,63,4,0,
465,17,71,1,10,
465,17,72,1,23,
465,17,74,1,20,
465,17,76,4,0,
465,17,77,1,14,
465,17,78,1,30,
465,17,79,1,4,
465,17,89,4,0,
465,17,92,4,0,
465,17,104,4,0,
465,17,115,4,0,
465,17,132,1,1,3
465,17,156,4,0,
465,17,157,4,0,
465,17,164,4,0,
465,17,168,4,0,
465,17,182,4,0,
465,17,188,4,0,
465,17,202,1,36,
465,17,207,4,0,
465,17,213,4,0,
465,17,214,4,0,
465,17,216,4,0,
465,17,218,4,0,
465,17,237,4,0,
465,17,241,4,0,
465,17,244,4,0,
465,17,246,1,40,
465,17,263,4,0,
465,17,267,4,0,
465,17,275,1,1,2
465,17,280,4,0,
465,17,282,1,27,
465,17,317,4,0,
465,17,321,1,46,
465,17,332,4,0,
465,17,335,1,1,1
465,17,335,1,56,
465,17,363,1,33,
465,17,371,4,0,
465,17,374,4,0,
465,17,378,1,49,
465,17,398,4,0,
465,17,411,4,0,
465,17,412,4,0,
465,17,416,4,0,
465,17,438,1,53,
465,17,447,4,0,
465,17,496,4,0,
465,17,523,4,0,
465,17,580,1,50,
465,17,590,4,0,
465,17,611,4,0,
465,18,14,4,0,
465,18,20,1,17,
465,18,21,1,43,
465,18,22,1,7,
465,18,63,4,0,
465,18,71,1,10,
465,18,72,1,23,
465,18,74,1,20,
465,18,76,4,0,
465,18,77,1,14,
465,18,78,1,30,
465,18,79,1,4,
465,18,89,4,0,
465,18,92,4,0,
465,18,104,4,0,
465,18,115,4,0,
465,18,132,1,1,3
465,18,156,4,0,
465,18,157,4,0,
465,18,164,4,0,
465,18,168,4,0,
465,18,182,4,0,
465,18,188,4,0,
465,18,202,1,36,
465,18,207,4,0,
465,18,213,4,0,
465,18,214,4,0,
465,18,216,4,0,
465,18,218,4,0,
465,18,237,4,0,
465,18,241,4,0,
465,18,244,4,0,
465,18,246,1,40,
465,18,263,4,0,
465,18,267,4,0,
465,18,275,1,1,2
465,18,280,4,0,
465,18,282,1,27,
465,18,317,4,0,
465,18,321,1,46,
465,18,332,4,0,
465,18,335,1,1,1
465,18,335,1,56,
465,18,363,1,33,
465,18,371,4,0,
465,18,374,4,0,
465,18,378,1,49,
465,18,398,4,0,
465,18,411,4,0,
465,18,412,4,0,
465,18,416,4,0,
465,18,438,1,53,
465,18,447,4,0,
465,18,496,4,0,
465,18,523,4,0,
465,18,580,1,50,
465,18,590,4,0,
465,18,611,4,0,
466,8,7,1,1,1
466,8,9,1,28,
466,8,43,1,1,3
466,8,53,4,0,
466,8,63,4,0,
466,8,67,1,1,5
466,8,67,1,10,
466,8,70,4,0,
466,8,84,1,1,4
466,8,84,1,7,
466,8,85,1,43,
466,8,85,4,0,
466,8,86,4,0,
466,8,87,1,58,
466,8,87,4,0,
466,8,89,4,0,
466,8,91,4,0,
466,8,92,4,0,
466,8,94,4,0,
466,8,98,1,1,2
466,8,103,1,52,
466,8,104,4,0,
466,8,113,1,25,
466,8,113,4,0,
466,8,129,1,16,
466,8,148,4,0,
466,8,156,4,0,
466,8,157,4,0,
466,8,164,4,0,
466,8,168,4,0,
466,8,182,4,0,
466,8,203,4,0,
466,8,207,4,0,
466,8,213,4,0,
466,8,214,4,0,
466,8,216,4,0,
466,8,218,4,0,
466,8,231,4,0,
466,8,237,4,0,
466,8,240,4,0,
466,8,249,4,0,
466,8,259,4,0,
466,8,263,4,0,
466,8,264,4,0,
466,8,269,4,0,
466,8,280,4,0,
466,8,290,4,0,
466,8,317,4,0,
466,8,351,1,19,
466,8,351,4,0,
466,8,363,4,0,
466,8,374,4,0,
466,8,411,4,0,
466,8,416,1,67,
466,8,416,4,0,
466,8,431,4,0,
466,8,435,1,37,
466,8,445,4,0,
466,8,451,4,0,
466,9,7,1,1,1
466,9,7,3,0,
466,9,8,3,0,
466,9,9,1,28,
466,9,9,3,0,
466,9,43,1,1,3
466,9,53,4,0,
466,9,63,4,0,
466,9,67,1,1,5
466,9,67,1,10,
466,9,70,4,0,
466,9,84,1,1,4
466,9,84,1,7,
466,9,85,1,43,
466,9,85,4,0,
466,9,86,4,0,
466,9,87,1,58,
466,9,87,4,0,
466,9,89,4,0,
466,9,91,4,0,
466,9,92,4,0,
466,9,94,4,0,
466,9,98,1,1,2
466,9,103,1,52,
466,9,104,4,0,
466,9,113,1,25,
466,9,113,4,0,
466,9,129,1,16,
466,9,129,3,0,
466,9,148,4,0,
466,9,156,4,0,
466,9,157,4,0,
466,9,164,4,0,
466,9,168,4,0,
466,9,173,3,0,
466,9,182,4,0,
466,9,189,3,0,
466,9,203,4,0,
466,9,207,4,0,
466,9,213,4,0,
466,9,214,4,0,
466,9,216,4,0,
466,9,218,4,0,
466,9,231,4,0,
466,9,237,4,0,
466,9,240,4,0,
466,9,249,4,0,
466,9,259,4,0,
466,9,263,4,0,
466,9,264,4,0,
466,9,269,4,0,
466,9,270,3,0,
466,9,280,4,0,
466,9,290,4,0,
466,9,317,4,0,
466,9,324,3,0,
466,9,351,1,19,
466,9,351,4,0,
466,9,363,4,0,
466,9,374,4,0,
466,9,393,3,0,
466,9,411,4,0,
466,9,416,1,67,
466,9,416,4,0,
466,9,431,4,0,
466,9,435,1,37,
466,9,445,4,0,
466,9,451,4,0,
466,10,7,1,1,1
466,10,7,3,0,
466,10,8,3,0,
466,10,9,1,28,
466,10,9,3,0,
466,10,29,3,0,
466,10,43,1,1,3
466,10,53,4,0,
466,10,63,4,0,
466,10,67,1,1,5
466,10,67,1,10,
466,10,67,3,0,
466,10,70,4,0,
466,10,84,1,1,4
466,10,84,1,7,
466,10,85,1,43,
466,10,85,4,0,
466,10,86,4,0,
466,10,87,1,58,
466,10,87,4,0,
466,10,89,4,0,
466,10,91,4,0,
466,10,92,4,0,
466,10,94,4,0,
466,10,98,1,1,2
466,10,103,1,52,
466,10,104,4,0,
466,10,113,1,25,
466,10,113,4,0,
466,10,129,1,16,
466,10,129,3,0,
466,10,148,4,0,
466,10,156,4,0,
466,10,157,4,0,
466,10,164,4,0,
466,10,168,4,0,
466,10,173,3,0,
466,10,182,4,0,
466,10,189,3,0,
466,10,203,4,0,
466,10,207,4,0,
466,10,213,4,0,
466,10,214,4,0,
466,10,216,4,0,
466,10,218,4,0,
466,10,231,4,0,
466,10,237,4,0,
466,10,240,4,0,
466,10,249,4,0,
466,10,259,4,0,
466,10,263,4,0,
466,10,264,4,0,
466,10,269,4,0,
466,10,270,3,0,
466,10,280,4,0,
466,10,290,4,0,
466,10,317,4,0,
466,10,324,3,0,
466,10,351,1,19,
466,10,351,4,0,
466,10,363,4,0,
466,10,374,4,0,
466,10,393,3,0,
466,10,411,4,0,
466,10,416,1,67,
466,10,416,4,0,
466,10,431,4,0,
466,10,435,1,37,
466,10,445,4,0,
466,10,451,4,0,
466,11,7,1,1,1
466,11,9,1,38,
466,11,43,1,1,3
466,11,53,4,0,
466,11,63,4,0,
466,11,67,1,1,5
466,11,67,1,11,
466,11,70,4,0,
466,11,84,1,1,4
466,11,84,1,6,
466,11,85,1,50,
466,11,85,4,0,
466,11,86,4,0,
466,11,87,1,62,
466,11,87,4,0,
466,11,89,4,0,
466,11,91,4,0,
466,11,92,4,0,
466,11,94,4,0,
466,11,98,1,1,2
466,11,103,1,56,
466,11,104,4,0,
466,11,113,1,26,
466,11,113,4,0,
466,11,129,1,16,
466,11,148,4,0,
466,11,156,4,0,
466,11,157,4,0,
466,11,164,4,0,
466,11,168,4,0,
466,11,182,4,0,
466,11,207,4,0,
466,11,213,4,0,
466,11,216,4,0,
466,11,218,4,0,
466,11,237,4,0,
466,11,240,4,0,
466,11,249,4,0,
466,11,259,4,0,
466,11,263,4,0,
466,11,269,4,0,
466,11,280,4,0,
466,11,317,4,0,
466,11,351,1,21,
466,11,374,4,0,
466,11,411,4,0,
466,11,416,1,68,
466,11,416,4,0,
466,11,435,1,44,
466,11,451,4,0,
466,11,486,1,32,
466,11,490,4,0,
466,11,496,4,0,
466,11,521,4,0,
466,11,523,4,0,
466,11,528,4,0,
466,14,7,1,1,1
466,14,7,3,0,
466,14,8,3,0,
466,14,9,1,29,
466,14,9,3,0,
466,14,43,1,1,3
466,14,53,4,0,
466,14,63,4,0,
466,14,67,1,1,5
466,14,67,1,8,
466,14,67,3,0,
466,14,70,4,0,
466,14,84,1,1,4
466,14,84,1,5,
466,14,85,1,49,
466,14,85,4,0,
466,14,86,1,19,
466,14,86,4,0,
466,14,87,1,55,
466,14,87,4,0,
466,14,89,4,0,
466,14,91,4,0,
466,14,92,4,0,
466,14,94,4,0,
466,14,98,1,1,2
466,14,103,1,42,
466,14,104,4,0,
466,14,113,1,26,
466,14,113,4,0,
466,14,129,1,12,
466,14,148,4,0,
466,14,156,4,0,
466,14,157,4,0,
466,14,164,4,0,
466,14,168,4,0,
466,14,173,3,0,
466,14,182,4,0,
466,14,207,4,0,
466,14,213,4,0,
466,14,214,3,0,
466,14,216,4,0,
466,14,218,4,0,
466,14,231,3,0,
466,14,237,4,0,
466,14,240,4,0,
466,14,249,4,0,
466,14,259,4,0,
466,14,263,4,0,
466,14,269,4,0,
466,14,270,3,0,
466,14,280,4,0,
466,14,317,4,0,
466,14,324,3,0,
466,14,343,3,0,
466,14,351,1,15,
466,14,374,4,0,
466,14,393,3,0,
466,14,411,4,0,
466,14,416,1,62,
466,14,416,4,0,
466,14,435,1,36,
466,14,451,4,0,
466,14,486,1,22,
466,14,490,4,0,
466,14,496,4,0,
466,14,521,4,0,
466,14,523,4,0,
466,14,527,3,0,
466,14,528,4,0,
466,14,530,3,0,
466,15,7,1,1,3
466,15,9,1,29,
466,15,43,1,1,5
466,15,53,4,0,
466,15,63,4,0,
466,15,67,1,1,7
466,15,67,1,8,
466,15,70,4,0,
466,15,84,1,1,6
466,15,84,1,5,
466,15,85,1,49,
466,15,85,4,0,
466,15,86,1,19,
466,15,86,4,0,
466,15,87,1,55,
466,15,87,4,0,
466,15,89,4,0,
466,15,91,4,0,
466,15,92,4,0,
466,15,94,4,0,
466,15,98,1,1,4
466,15,103,1,42,
466,15,104,4,0,
466,15,113,1,26,
466,15,113,4,0,
466,15,129,1,12,
466,15,148,4,0,
466,15,156,4,0,
466,15,157,4,0,
466,15,164,4,0,
466,15,168,4,0,
466,15,182,4,0,
466,15,207,4,0,
466,15,213,4,0,
466,15,214,4,0,
466,15,216,4,0,
466,15,218,4,0,
466,15,237,4,0,
466,15,240,4,0,
466,15,249,4,0,
466,15,259,4,0,
466,15,263,4,0,
466,15,269,4,0,
466,15,280,4,0,
466,15,317,4,0,
466,15,351,1,15,
466,15,374,4,0,
466,15,411,4,0,
466,15,416,1,62,
466,15,416,4,0,
466,15,435,1,36,
466,15,451,4,0,
466,15,486,1,22,
466,15,490,4,0,
466,15,496,4,0,
466,15,521,4,0,
466,15,523,4,0,
466,15,528,4,0,
466,15,569,1,1,2
466,15,590,4,0,
466,15,604,1,1,1
466,15,604,1,65,
466,15,612,4,0,
466,16,7,1,1,3
466,16,7,3,0,
466,16,8,3,0,
466,16,9,1,29,1
466,16,9,3,0,
466,16,43,1,1,5
466,16,53,4,0,
466,16,63,4,0,
466,16,67,1,1,7
466,16,67,1,8,1
466,16,67,3,0,
466,16,70,4,0,
466,16,84,1,1,6
466,16,84,1,5,1
466,16,85,1,49,1
466,16,85,4,0,
466,16,86,1,19,1
466,16,86,4,0,
466,16,87,1,55,1
466,16,87,4,0,
466,16,89,4,0,
466,16,91,4,0,
466,16,92,4,0,
466,16,94,4,0,
466,16,98,1,1,4
466,16,103,1,42,1
466,16,104,4,0,
466,16,113,1,26,1
466,16,113,4,0,
466,16,129,1,12,1
466,16,148,4,0,
466,16,156,4,0,
466,16,157,4,0,
466,16,164,4,0,
466,16,168,4,0,
466,16,173,3,0,
466,16,182,4,0,
466,16,207,4,0,
466,16,213,4,0,
466,16,214,4,0,
466,16,216,4,0,
466,16,218,4,0,
466,16,231,3,0,
466,16,237,4,0,
466,16,240,4,0,
466,16,249,4,0,
466,16,259,4,0,
466,16,263,4,0,
466,16,264,3,0,
466,16,269,4,0,
466,16,270,3,0,
466,16,280,4,0,
466,16,290,4,0,
466,16,317,4,0,
466,16,324,3,0,
466,16,343,3,0,
466,16,351,1,15,1
466,16,351,3,0,
466,16,374,4,0,
466,16,393,3,0,
466,16,411,4,0,
466,16,416,1,62,1
466,16,416,4,0,
466,16,435,1,36,1
466,16,451,4,0,
466,16,486,1,22,1
466,16,490,4,0,
466,16,496,4,0,
466,16,521,4,0,
466,16,523,4,0,
466,16,527,3,0,
466,16,528,4,0,
466,16,530,3,0,
466,16,569,1,1,2
466,16,590,4,0,
466,16,604,1,1,1
466,16,604,1,65,1
466,16,612,4,0,
466,17,7,1,1,3
466,17,9,1,29,
466,17,43,1,1,5
466,17,53,4,0,
466,17,63,4,0,
466,17,67,1,1,7
466,17,67,1,8,
466,17,84,1,1,6
466,17,84,1,5,
466,17,85,1,49,
466,17,85,4,0,
466,17,86,1,19,
466,17,86,4,0,
466,17,87,1,55,
466,17,87,4,0,
466,17,89,4,0,
466,17,92,4,0,
466,17,94,4,0,
466,17,98,1,1,4
466,17,103,1,42,
466,17,104,4,0,
466,17,113,1,26,
466,17,113,4,0,
466,17,129,1,12,
466,17,156,4,0,
466,17,157,4,0,
466,17,164,4,0,
466,17,168,4,0,
466,17,182,4,0,
466,17,207,4,0,
466,17,213,4,0,
466,17,214,4,0,
466,17,216,4,0,
466,17,218,4,0,
466,17,237,4,0,
466,17,240,4,0,
466,17,259,4,0,
466,17,263,4,0,
466,17,269,4,0,
466,17,280,4,0,
466,17,317,4,0,
466,17,351,1,15,
466,17,374,4,0,
466,17,411,4,0,
466,17,416,1,62,
466,17,416,4,0,
466,17,435,1,36,
466,17,451,4,0,
466,17,486,1,22,
466,17,490,4,0,
466,17,496,4,0,
466,17,521,4,0,
466,17,523,4,0,
466,17,528,4,0,
466,17,569,1,1,2
466,17,590,4,0,
466,17,604,1,1,1
466,17,604,1,65,
466,18,7,1,1,3
466,18,9,1,29,
466,18,43,1,1,5
466,18,53,4,0,
466,18,63,4,0,
466,18,67,1,1,7
466,18,67,1,8,
466,18,84,1,1,6
466,18,84,1,5,
466,18,85,1,49,
466,18,85,4,0,
466,18,86,1,19,
466,18,86,4,0,
466,18,87,1,55,
466,18,87,4,0,
466,18,89,4,0,
466,18,92,4,0,
466,18,94,4,0,
466,18,98,1,1,4
466,18,103,1,42,
466,18,104,4,0,
466,18,113,1,26,
466,18,113,4,0,
466,18,129,1,12,
466,18,156,4,0,
466,18,157,4,0,
466,18,164,4,0,
466,18,168,4,0,
466,18,182,4,0,
466,18,207,4,0,
466,18,213,4,0,
466,18,214,4,0,
466,18,216,4,0,
466,18,218,4,0,
466,18,237,4,0,
466,18,240,4,0,
466,18,259,4,0,
466,18,263,4,0,
466,18,269,4,0,
466,18,280,4,0,
466,18,317,4,0,
466,18,351,1,15,
466,18,374,4,0,
466,18,411,4,0,
466,18,416,1,62,
466,18,416,4,0,
466,18,435,1,36,
466,18,451,4,0,
466,18,486,1,22,
466,18,490,4,0,
466,18,496,4,0,
466,18,521,4,0,
466,18,523,4,0,
466,18,528,4,0,
466,18,569,1,1,2
466,18,590,4,0,
466,18,604,1,1,1
466,18,604,1,65,
467,8,7,1,28,
467,8,9,1,1,1
467,8,43,1,1,3
467,8,52,1,1,4
467,8,52,1,7,
467,8,53,1,43,
467,8,53,4,0,
467,8,63,1,67,
467,8,63,4,0,
467,8,70,4,0,
467,8,76,4,0,
467,8,83,1,19,
467,8,85,4,0,
467,8,89,4,0,
467,8,92,4,0,
467,8,94,4,0,
467,8,104,4,0,
467,8,108,1,1,5
467,8,108,1,10,
467,8,109,1,25,
467,8,123,1,1,2
467,8,126,1,58,
467,8,126,4,0,
467,8,156,4,0,
467,8,157,4,0,
467,8,164,4,0,
467,8,168,4,0,
467,8,182,4,0,
467,8,185,1,16,
467,8,203,4,0,
467,8,207,4,0,
467,8,213,4,0,
467,8,214,4,0,
467,8,216,4,0,
467,8,218,4,0,
467,8,231,4,0,
467,8,237,4,0,
467,8,241,1,52,
467,8,241,4,0,
467,8,249,4,0,
467,8,259,4,0,
467,8,261,4,0,
467,8,263,4,0,
467,8,264,4,0,
467,8,269,4,0,
467,8,280,4,0,
467,8,290,4,0,
467,8,315,4,0,
467,8,317,4,0,
467,8,363,4,0,
467,8,374,4,0,
467,8,411,4,0,
467,8,416,4,0,
467,8,431,4,0,
467,8,436,1,37,
467,8,445,4,0,
467,9,7,1,28,
467,9,7,3,0,
467,9,9,1,1,1
467,9,9,3,0,
467,9,43,1,1,3
467,9,52,1,1,4
467,9,52,1,7,
467,9,53,1,43,
467,9,53,4,0,
467,9,63,1,67,
467,9,63,4,0,
467,9,70,4,0,
467,9,76,4,0,
467,9,83,1,19,
467,9,85,4,0,
467,9,89,4,0,
467,9,92,4,0,
467,9,94,4,0,
467,9,104,4,0,
467,9,108,1,1,5
467,9,108,1,10,
467,9,109,1,25,
467,9,123,1,1,2
467,9,126,1,58,
467,9,126,4,0,
467,9,156,4,0,
467,9,157,4,0,
467,9,164,4,0,
467,9,168,4,0,
467,9,173,3,0,
467,9,182,4,0,
467,9,185,1,16,
467,9,189,3,0,
467,9,203,4,0,
467,9,207,4,0,
467,9,213,4,0,
467,9,214,4,0,
467,9,216,4,0,
467,9,218,4,0,
467,9,231,4,0,
467,9,237,4,0,
467,9,241,1,52,
467,9,241,4,0,
467,9,249,4,0,
467,9,257,3,0,
467,9,259,4,0,
467,9,261,4,0,
467,9,263,4,0,
467,9,264,4,0,
467,9,269,4,0,
467,9,270,3,0,
467,9,280,4,0,
467,9,290,4,0,
467,9,315,4,0,
467,9,317,4,0,
467,9,363,4,0,
467,9,374,4,0,
467,9,411,4,0,
467,9,416,4,0,
467,9,431,4,0,
467,9,436,1,37,
467,9,445,4,0,
467,10,7,1,28,
467,10,7,3,0,
467,10,9,1,1,1
467,10,9,3,0,
467,10,29,3,0,
467,10,43,1,1,3
467,10,52,1,1,4
467,10,52,1,7,
467,10,53,1,43,
467,10,53,4,0,
467,10,63,1,67,
467,10,63,4,0,
467,10,67,3,0,
467,10,70,4,0,
467,10,76,4,0,
467,10,83,1,19,
467,10,85,4,0,
467,10,89,4,0,
467,10,92,4,0,
467,10,94,4,0,
467,10,104,4,0,
467,10,108,1,1,5
467,10,108,1,10,
467,10,109,1,25,
467,10,123,1,1,2
467,10,126,1,58,
467,10,126,4,0,
467,10,156,4,0,
467,10,157,4,0,
467,10,164,4,0,
467,10,168,4,0,
467,10,173,3,0,
467,10,182,4,0,
467,10,185,1,16,
467,10,189,3,0,
467,10,203,4,0,
467,10,207,4,0,
467,10,213,4,0,
467,10,214,4,0,
467,10,216,4,0,
467,10,218,4,0,
467,10,231,4,0,
467,10,237,4,0,
467,10,241,1,52,
467,10,241,4,0,
467,10,249,4,0,
467,10,257,3,0,
467,10,259,4,0,
467,10,261,4,0,
467,10,263,4,0,
467,10,264,4,0,
467,10,269,4,0,
467,10,270,3,0,
467,10,280,4,0,
467,10,290,4,0,
467,10,315,4,0,
467,10,317,4,0,
467,10,363,4,0,
467,10,374,4,0,
467,10,411,4,0,
467,10,416,4,0,
467,10,431,4,0,
467,10,436,1,37,
467,10,445,4,0,
467,11,7,1,38,
467,11,9,1,1,1
467,11,43,1,1,3
467,11,52,1,1,4
467,11,52,1,6,
467,11,53,1,50,
467,11,53,4,0,
467,11,63,1,68,
467,11,63,4,0,
467,11,70,4,0,
467,11,76,4,0,
467,11,83,1,21,
467,11,85,4,0,
467,11,89,4,0,
467,11,92,4,0,
467,11,94,4,0,
467,11,104,4,0,
467,11,108,1,1,5
467,11,108,1,11,
467,11,109,1,26,
467,11,123,1,1,2
467,11,126,1,62,
467,11,126,4,0,
467,11,156,4,0,
467,11,157,4,0,
467,11,164,4,0,
467,11,168,4,0,
467,11,182,4,0,
467,11,185,1,16,
467,11,207,4,0,
467,11,213,4,0,
467,11,216,4,0,
467,11,218,4,0,
467,11,237,4,0,
467,11,241,1,56,
467,11,241,4,0,
467,11,249,4,0,
467,11,259,4,0,
467,11,261,4,0,
467,11,263,4,0,
467,11,269,4,0,
467,11,280,4,0,
467,11,315,4,0,
467,11,317,4,0,
467,11,374,4,0,
467,11,411,4,0,
467,11,416,4,0,
467,11,436,1,44,
467,11,481,1,32,
467,11,488,4,0,
467,11,490,4,0,
467,11,496,4,0,
467,11,510,4,0,
467,11,523,4,0,
467,14,7,1,29,
467,14,7,3,0,
467,14,9,1,1,1
467,14,9,3,0,
467,14,43,1,1,3
467,14,52,1,1,4
467,14,52,1,5,
467,14,53,1,49,
467,14,53,4,0,
467,14,63,1,62,
467,14,63,4,0,
467,14,67,3,0,
467,14,70,4,0,
467,14,76,4,0,
467,14,83,1,15,
467,14,85,4,0,
467,14,89,4,0,
467,14,92,4,0,
467,14,94,4,0,
467,14,104,4,0,
467,14,108,1,1,5
467,14,108,1,8,
467,14,109,1,26,
467,14,123,1,1,2
467,14,126,1,55,
467,14,126,4,0,
467,14,156,4,0,
467,14,157,4,0,
467,14,164,4,0,
467,14,168,4,0,
467,14,173,3,0,
467,14,182,4,0,
467,14,185,1,12,
467,14,207,4,0,
467,14,213,4,0,
467,14,214,3,0,
467,14,216,4,0,
467,14,218,4,0,
467,14,231,3,0,
467,14,237,4,0,
467,14,241,1,42,
467,14,241,4,0,
467,14,249,4,0,
467,14,257,3,0,
467,14,259,4,0,
467,14,261,4,0,
467,14,263,4,0,
467,14,269,4,0,
467,14,270,3,0,
467,14,280,4,0,
467,14,315,4,0,
467,14,317,4,0,
467,14,343,3,0,
467,14,374,4,0,
467,14,411,4,0,
467,14,416,4,0,
467,14,436,1,36,
467,14,481,1,22,
467,14,488,4,0,
467,14,490,4,0,
467,14,496,4,0,
467,14,499,1,19,
467,14,510,4,0,
467,14,523,4,0,
467,14,530,3,0,
467,15,7,1,29,
467,15,9,1,1,1
467,15,43,1,1,3
467,15,52,1,1,4
467,15,52,1,5,
467,15,53,1,49,
467,15,53,4,0,
467,15,63,1,62,
467,15,63,4,0,
467,15,70,4,0,
467,15,76,4,0,
467,15,83,1,15,
467,15,85,4,0,
467,15,89,4,0,
467,15,92,4,0,
467,15,94,4,0,
467,15,104,4,0,
467,15,108,1,1,5
467,15,108,1,8,
467,15,109,1,26,
467,15,123,1,1,2
467,15,126,1,55,
467,15,126,4,0,
467,15,156,4,0,
467,15,157,4,0,
467,15,164,4,0,
467,15,168,4,0,
467,15,182,4,0,
467,15,185,1,12,
467,15,207,4,0,
467,15,213,4,0,
467,15,214,4,0,
467,15,216,4,0,
467,15,218,4,0,
467,15,237,4,0,
467,15,241,1,42,
467,15,241,4,0,
467,15,249,4,0,
467,15,259,4,0,
467,15,261,4,0,
467,15,263,4,0,
467,15,269,4,0,
467,15,280,4,0,
467,15,315,4,0,
467,15,317,4,0,
467,15,374,4,0,
467,15,411,4,0,
467,15,416,4,0,
467,15,436,1,36,
467,15,481,1,22,
467,15,488,4,0,
467,15,490,4,0,
467,15,496,4,0,
467,15,499,1,19,
467,15,510,4,0,
467,15,523,4,0,
467,15,590,4,0,
467,15,612,4,0,
467,16,7,1,29,1
467,16,7,3,0,
467,16,9,1,1,1
467,16,9,3,0,
467,16,43,1,1,3
467,16,52,1,1,4
467,16,52,1,5,1
467,16,53,1,49,1
467,16,53,4,0,
467,16,63,1,62,1
467,16,63,4,0,
467,16,67,3,0,
467,16,70,4,0,
467,16,76,4,0,
467,16,83,1,15,1
467,16,85,4,0,
467,16,89,4,0,
467,16,92,4,0,
467,16,94,4,0,
467,16,104,4,0,
467,16,108,1,1,5
467,16,108,1,8,1
467,16,109,1,26,1
467,16,123,1,1,2
467,16,126,1,55,1
467,16,126,4,0,
467,16,156,4,0,
467,16,157,4,0,
467,16,164,4,0,
467,16,168,4,0,
467,16,173,3,0,
467,16,182,4,0,
467,16,185,1,12,1
467,16,207,4,0,
467,16,213,4,0,
467,16,214,4,0,
467,16,216,4,0,
467,16,218,4,0,
467,16,231,3,0,
467,16,237,4,0,
467,16,241,1,42,1
467,16,241,4,0,
467,16,249,4,0,
467,16,257,3,0,
467,16,259,4,0,
467,16,261,4,0,
467,16,263,4,0,
467,16,264,3,0,
467,16,269,4,0,
467,16,270,3,0,
467,16,280,4,0,
467,16,290,4,0,
467,16,315,4,0,
467,16,317,4,0,
467,16,343,3,0,
467,16,374,4,0,
467,16,411,4,0,
467,16,416,4,0,
467,16,436,1,36,1
467,16,481,1,22,1
467,16,488,4,0,
467,16,490,4,0,
467,16,496,4,0,
467,16,499,1,19,1
467,16,510,4,0,
467,16,523,4,0,
467,16,530,3,0,
467,16,590,4,0,
467,16,612,4,0,
467,17,7,1,29,
467,17,9,1,1,1
467,17,43,1,1,3
467,17,52,1,1,4
467,17,52,1,5,
467,17,53,1,49,
467,17,53,4,0,
467,17,63,1,62,
467,17,63,4,0,
467,17,76,4,0,
467,17,83,1,15,
467,17,85,4,0,
467,17,89,4,0,
467,17,92,4,0,
467,17,94,4,0,
467,17,104,4,0,
467,17,108,1,1,5
467,17,108,1,8,
467,17,109,1,26,
467,17,123,1,1,2
467,17,126,1,55,
467,17,126,4,0,
467,17,156,4,0,
467,17,157,4,0,
467,17,164,4,0,
467,17,168,4,0,
467,17,182,4,0,
467,17,185,1,12,
467,17,207,4,0,
467,17,213,4,0,
467,17,214,4,0,
467,17,216,4,0,
467,17,218,4,0,
467,17,237,4,0,
467,17,241,1,42,
467,17,241,4,0,
467,17,259,4,0,
467,17,261,4,0,
467,17,263,4,0,
467,17,269,4,0,
467,17,280,4,0,
467,17,315,4,0,
467,17,317,4,0,
467,17,374,4,0,
467,17,411,4,0,
467,17,416,4,0,
467,17,436,1,36,
467,17,481,1,22,
467,17,488,4,0,
467,17,490,4,0,
467,17,496,4,0,
467,17,499,1,19,
467,17,523,4,0,
467,17,590,4,0,
467,18,7,1,29,
467,18,9,1,1,1
467,18,43,1,1,3
467,18,52,1,1,4
467,18,52,1,5,
467,18,53,1,49,
467,18,53,4,0,
467,18,63,1,62,
467,18,63,4,0,
467,18,76,4,0,
467,18,83,1,15,
467,18,85,4,0,
467,18,89,4,0,
467,18,92,4,0,
467,18,94,4,0,
467,18,104,4,0,
467,18,108,1,1,5
467,18,108,1,8,
467,18,109,1,26,
467,18,123,1,1,2
467,18,126,1,55,
467,18,126,4,0,
467,18,156,4,0,
467,18,157,4,0,
467,18,164,4,0,
467,18,168,4,0,
467,18,182,4,0,
467,18,185,1,12,
467,18,207,4,0,
467,18,213,4,0,
467,18,214,4,0,
467,18,216,4,0,
467,18,218,4,0,
467,18,237,4,0,
467,18,241,1,42,
467,18,241,4,0,
467,18,259,4,0,
467,18,261,4,0,
467,18,263,4,0,
467,18,269,4,0,
467,18,280,4,0,
467,18,315,4,0,
467,18,317,4,0,
467,18,374,4,0,
467,18,411,4,0,
467,18,416,4,0,
467,18,436,1,36,
467,18,481,1,22,
467,18,488,4,0,
467,18,490,4,0,
467,18,496,4,0,
467,18,499,1,19,
467,18,523,4,0,
467,18,590,4,0,
468,8,19,4,0,
468,8,53,4,0,
468,8,63,4,0,
468,8,76,4,0,
468,8,86,4,0,
468,8,92,4,0,
468,8,94,4,0,
468,8,104,4,0,
468,8,113,4,0,
468,8,115,4,0,
468,8,126,4,0,
468,8,138,4,0,
468,8,143,1,1,1
468,8,148,4,0,
468,8,156,4,0,
468,8,164,4,0,
468,8,182,4,0,
468,8,203,4,0,
468,8,207,4,0,
468,8,211,4,0,
468,8,213,4,0,
468,8,214,4,0,
468,8,216,4,0,
468,8,218,4,0,
468,8,219,4,0,
468,8,237,4,0,
468,8,240,4,0,
468,8,241,4,0,
468,8,244,4,0,
468,8,245,1,1,2
468,8,247,4,0,
468,8,249,4,0,
468,8,263,4,0,
468,8,264,4,0,
468,8,280,4,0,
468,8,290,4,0,
468,8,318,4,0,
468,8,332,4,0,
468,8,351,4,0,
468,8,352,4,0,
468,8,355,4,0,
468,8,363,4,0,
468,8,365,4,0,
468,8,374,4,0,
468,8,396,1,1,3
468,8,403,1,1,4
468,8,409,4,0,
468,8,416,4,0,
468,8,432,4,0,
468,8,445,4,0,
468,8,447,4,0,
468,9,19,4,0,
468,9,53,4,0,
468,9,63,4,0,
468,9,76,4,0,
468,9,86,4,0,
468,9,92,4,0,
468,9,94,4,0,
468,9,104,4,0,
468,9,113,4,0,
468,9,115,4,0,
468,9,126,4,0,
468,9,129,3,0,
468,9,138,4,0,
468,9,143,1,1,1
468,9,148,4,0,
468,9,156,4,0,
468,9,164,4,0,
468,9,173,3,0,
468,9,182,4,0,
468,9,189,3,0,
468,9,203,4,0,
468,9,205,3,0,
468,9,207,4,0,
468,9,211,4,0,
468,9,213,4,0,
468,9,214,4,0,
468,9,216,4,0,
468,9,218,4,0,
468,9,219,4,0,
468,9,237,4,0,
468,9,239,3,0,
468,9,240,4,0,
468,9,241,4,0,
468,9,244,4,0,
468,9,245,1,1,2
468,9,246,3,0,
468,9,247,4,0,
468,9,249,4,0,
468,9,257,3,0,
468,9,263,4,0,
468,9,264,4,0,
468,9,271,3,0,
468,9,280,4,0,
468,9,283,3,0,
468,9,290,4,0,
468,9,314,3,0,
468,9,318,4,0,
468,9,324,3,0,
468,9,332,4,0,
468,9,351,4,0,
468,9,352,4,0,
468,9,355,4,0,
468,9,363,4,0,
468,9,365,4,0,
468,9,374,4,0,
468,9,387,3,0,
468,9,396,1,1,3
468,9,403,1,1,4
468,9,409,4,0,
468,9,416,4,0,
468,9,428,3,0,
468,9,432,4,0,
468,9,445,4,0,
468,9,447,4,0,
468,9,466,3,0,
468,10,19,4,0,
468,10,29,3,0,
468,10,53,4,0,
468,10,63,4,0,
468,10,76,4,0,
468,10,86,4,0,
468,10,92,4,0,
468,10,94,4,0,
468,10,104,4,0,
468,10,113,4,0,
468,10,115,4,0,
468,10,126,4,0,
468,10,129,3,0,
468,10,138,4,0,
468,10,143,1,1,1
468,10,143,3,0,
468,10,148,4,0,
468,10,156,4,0,
468,10,164,4,0,
468,10,173,3,0,
468,10,182,4,0,
468,10,189,3,0,
468,10,203,4,0,
468,10,205,3,0,
468,10,207,4,0,
468,10,211,4,0,
468,10,213,4,0,
468,10,214,4,0,
468,10,215,3,0,
468,10,216,4,0,
468,10,218,4,0,
468,10,219,4,0,
468,10,237,4,0,
468,10,239,3,0,
468,10,240,4,0,
468,10,241,4,0,
468,10,244,4,0,
468,10,245,1,1,2
468,10,246,3,0,
468,10,247,4,0,
468,10,249,4,0,
468,10,257,3,0,
468,10,263,4,0,
468,10,264,4,0,
468,10,271,3,0,
468,10,277,3,0,
468,10,280,4,0,
468,10,283,3,0,
468,10,290,4,0,
468,10,314,3,0,
468,10,318,4,0,
468,10,324,3,0,
468,10,332,4,0,
468,10,351,4,0,
468,10,352,4,0,
468,10,355,4,0,
468,10,363,4,0,
468,10,365,4,0,
468,10,366,3,0,
468,10,374,4,0,
468,10,387,3,0,
468,10,396,1,1,3
468,10,403,1,1,4
468,10,409,4,0,
468,10,416,4,0,
468,10,428,3,0,
468,10,445,4,0,
468,10,447,4,0,
468,10,466,3,0,
468,11,19,4,0,
468,11,53,4,0,
468,11,63,4,0,
468,11,76,4,0,
468,11,86,4,0,
468,11,92,4,0,
468,11,94,4,0,
468,11,104,4,0,
468,11,113,4,0,
468,11,115,4,0,
468,11,126,4,0,
468,11,138,4,0,
468,11,143,1,1,1
468,11,148,4,0,
468,11,156,4,0,
468,11,164,4,0,
468,11,182,4,0,
468,11,207,4,0,
468,11,213,4,0,
468,11,216,4,0,
468,11,218,4,0,
468,11,219,4,0,
468,11,237,4,0,
468,11,240,4,0,
468,11,241,4,0,
468,11,244,4,0,
468,11,245,1,1,2
468,11,247,4,0,
468,11,249,4,0,
468,11,263,4,0,
468,11,280,4,0,
468,11,332,4,0,
468,11,365,4,0,
468,11,374,4,0,
468,11,396,1,1,3
468,11,403,1,1,4
468,11,416,4,0,
468,11,447,4,0,
468,11,473,4,0,
468,11,477,4,0,
468,11,496,4,0,
468,11,497,4,0,
468,11,510,4,0,
468,11,514,4,0,
468,11,526,4,0,
468,14,19,4,0,
468,14,53,4,0,
468,14,63,4,0,
468,14,76,4,0,
468,14,86,4,0,
468,14,92,4,0,
468,14,94,4,0,
468,14,104,4,0,
468,14,113,4,0,
468,14,115,4,0,
468,14,126,4,0,
468,14,138,4,0,
468,14,143,1,1,1
468,14,143,3,0,
468,14,148,4,0,
468,14,156,4,0,
468,14,164,4,0,
468,14,173,3,0,
468,14,182,4,0,
468,14,207,4,0,
468,14,213,4,0,
468,14,214,3,0,
468,14,215,3,0,
468,14,216,4,0,
468,14,218,4,0,
468,14,219,4,0,
468,14,237,4,0,
468,14,240,4,0,
468,14,241,4,0,
468,14,244,4,0,
468,14,245,1,1,2
468,14,247,4,0,
468,14,249,4,0,
468,14,257,3,0,
468,14,263,4,0,
468,14,271,3,0,
468,14,277,3,0,
468,14,280,4,0,
468,14,283,3,0,
468,14,304,3,0,
468,14,324,3,0,
468,14,332,4,0,
468,14,343,3,0,
468,14,355,3,0,
468,14,365,4,0,
468,14,366,3,0,
468,14,374,4,0,
468,14,387,3,0,
468,14,396,1,1,3
468,14,403,1,1,4
468,14,409,3,0,
468,14,416,4,0,
468,14,428,3,0,
468,14,447,4,0,
468,14,473,4,0,
468,14,477,4,0,
468,14,496,4,0,
468,14,497,4,0,
468,14,510,4,0,
468,14,514,4,0,
468,14,526,4,0,
468,15,19,4,0,
468,15,53,4,0,
468,15,63,4,0,
468,15,76,4,0,
468,15,86,4,0,
468,15,92,4,0,
468,15,94,4,0,
468,15,104,4,0,
468,15,113,4,0,
468,15,115,4,0,
468,15,126,4,0,
468,15,138,4,0,
468,15,143,1,1,2
468,15,148,4,0,
468,15,156,4,0,
468,15,164,4,0,
468,15,182,4,0,
468,15,207,4,0,
468,15,211,4,0,
468,15,213,4,0,
468,15,214,4,0,
468,15,216,4,0,
468,15,218,4,0,
468,15,219,4,0,
468,15,237,4,0,
468,15,240,4,0,
468,15,241,4,0,
468,15,244,4,0,
468,15,245,1,1,3
468,15,247,4,0,
468,15,249,4,0,
468,15,263,4,0,
468,15,280,4,0,
468,15,332,4,0,
468,15,355,4,0,
468,15,374,4,0,
468,15,396,1,1,4
468,15,403,1,1,5
468,15,416,4,0,
468,15,447,4,0,
468,15,473,4,0,
468,15,495,1,1,1
468,15,496,4,0,
468,15,497,4,0,
468,15,510,4,0,
468,15,514,4,0,
468,15,590,4,0,
468,15,605,4,0,
468,16,19,4,0,
468,16,53,4,0,
468,16,63,4,0,
468,16,76,4,0,
468,16,86,4,0,
468,16,92,4,0,
468,16,94,4,0,
468,16,104,4,0,
468,16,113,4,0,
468,16,115,4,0,
468,16,126,4,0,
468,16,138,4,0,
468,16,143,1,1,2
468,16,143,3,0,
468,16,148,4,0,
468,16,156,4,0,
468,16,164,4,0,
468,16,173,3,0,
468,16,182,4,0,
468,16,207,4,0,
468,16,211,4,0,
468,16,213,4,0,
468,16,214,4,0,
468,16,215,3,0,
468,16,216,4,0,
468,16,218,4,0,
468,16,219,4,0,
468,16,237,4,0,
468,16,240,4,0,
468,16,241,4,0,
468,16,244,4,0,
468,16,245,1,1,3
468,16,247,4,0,
468,16,249,4,0,
468,16,257,3,0,
468,16,263,4,0,
468,16,264,3,0,
468,16,271,3,0,
468,16,277,3,0,
468,16,280,4,0,
468,16,283,3,0,
468,16,290,4,0,
468,16,304,3,0,
468,16,324,3,0,
468,16,332,4,0,
468,16,343,3,0,
468,16,351,3,0,
468,16,352,3,0,
468,16,355,4,0,
468,16,366,3,0,
468,16,374,4,0,
468,16,387,3,0,
468,16,396,1,1,4
468,16,403,1,1,5
468,16,409,3,0,
468,16,416,4,0,
468,16,428,3,0,
468,16,447,4,0,
468,16,473,4,0,
468,16,495,1,1,1
468,16,495,3,0,
468,16,496,4,0,
468,16,497,4,0,
468,16,510,4,0,
468,16,514,4,0,
468,16,590,4,0,
468,16,605,4,0,
468,17,19,4,0,
468,17,53,4,0,
468,17,63,4,0,
468,17,76,4,0,
468,17,86,4,0,
468,17,92,4,0,
468,17,94,4,0,
468,17,104,4,0,
468,17,113,4,0,
468,17,115,4,0,
468,17,126,4,0,
468,17,138,4,0,
468,17,143,1,1,2
468,17,156,4,0,
468,17,164,4,0,
468,17,182,4,0,
468,17,207,4,0,
468,17,211,4,0,
468,17,213,4,0,
468,17,214,4,0,
468,17,216,4,0,
468,17,218,4,0,
468,17,219,4,0,
468,17,237,4,0,
468,17,240,4,0,
468,17,241,4,0,
468,17,244,4,0,
468,17,245,1,1,3
468,17,247,4,0,
468,17,263,4,0,
468,17,280,4,0,
468,17,332,4,0,
468,17,355,4,0,
468,17,374,4,0,
468,17,396,1,1,4
468,17,403,1,1,5
468,17,416,4,0,
468,17,447,4,0,
468,17,473,4,0,
468,17,495,1,1,1
468,17,496,4,0,
468,17,497,4,0,
468,17,526,4,0,
468,17,590,4,0,
468,17,605,4,0,
468,18,19,4,0,
468,18,53,4,0,
468,18,63,4,0,
468,18,76,4,0,
468,18,86,4,0,
468,18,92,4,0,
468,18,94,4,0,
468,18,104,4,0,
468,18,113,4,0,
468,18,115,4,0,
468,18,126,4,0,
468,18,138,4,0,
468,18,143,1,1,2
468,18,156,4,0,
468,18,164,4,0,
468,18,182,4,0,
468,18,207,4,0,
468,18,211,4,0,
468,18,213,4,0,
468,18,214,4,0,
468,18,216,4,0,
468,18,218,4,0,
468,18,219,4,0,
468,18,237,4,0,
468,18,240,4,0,
468,18,241,4,0,
468,18,244,4,0,
468,18,245,1,1,3
468,18,247,4,0,
468,18,263,4,0,
468,18,280,4,0,
468,18,332,4,0,
468,18,355,4,0,
468,18,374,4,0,
468,18,396,1,1,4
468,18,403,1,1,5
468,18,416,4,0,
468,18,447,4,0,
468,18,473,4,0,
468,18,495,1,1,1
468,18,496,4,0,
468,18,497,4,0,
468,18,526,4,0,
468,18,590,4,0,
468,18,605,4,0,
469,8,33,1,1,3
469,8,48,1,22,
469,8,49,1,14,
469,8,63,4,0,
469,8,76,4,0,
469,8,92,4,0,
469,8,94,4,0,
469,8,98,1,1,5
469,8,98,1,6,
469,8,103,1,43,
469,8,104,1,1,6
469,8,104,1,11,
469,8,104,4,0,
469,8,138,4,0,
469,8,148,4,0,
469,8,156,4,0,
469,8,163,1,38,
469,8,164,4,0,
469,8,168,4,0,
469,8,182,4,0,
469,8,193,1,1,4
469,8,197,1,17,
469,8,202,4,0,
469,8,203,4,0,
469,8,207,4,0,
469,8,211,4,0,
469,8,213,4,0,
469,8,214,4,0,
469,8,216,4,0,
469,8,218,4,0,
469,8,228,1,30,
469,8,237,4,0,
469,8,241,4,0,
469,8,244,4,0,
469,8,246,1,33,
469,8,247,4,0,
469,8,253,1,27,
469,8,263,4,0,
469,8,290,4,0,
469,8,318,4,0,
469,8,332,4,0,
469,8,355,4,0,
469,8,363,4,0,
469,8,369,1,46,
469,8,369,4,0,
469,8,400,1,1,1
469,8,403,1,49,
469,8,405,1,54,
469,8,416,4,0,
469,8,432,4,0,
469,8,445,4,0,
469,8,450,1,1,2
469,9,33,1,1,3
469,9,48,1,22,
469,9,49,1,14,
469,9,63,4,0,
469,9,76,4,0,
469,9,92,4,0,
469,9,94,4,0,
469,9,98,1,1,5
469,9,98,1,6,
469,9,103,1,46,
469,9,104,1,1,6
469,9,104,1,11,
469,9,104,4,0,
469,9,129,3,0,
469,9,138,4,0,
469,9,148,4,0,
469,9,156,4,0,
469,9,163,1,43,
469,9,164,4,0,
469,9,168,4,0,
469,9,173,3,0,
469,9,182,4,0,
469,9,189,3,0,
469,9,193,1,1,4
469,9,197,1,17,
469,9,202,4,0,
469,9,203,4,0,
469,9,207,4,0,
469,9,211,4,0,
469,9,213,4,0,
469,9,214,4,0,
469,9,216,4,0,
469,9,218,4,0,
469,9,228,1,30,
469,9,237,4,0,
469,9,241,4,0,
469,9,244,4,0,
469,9,246,1,33,
469,9,246,3,0,
469,9,247,4,0,
469,9,253,1,27,
469,9,253,3,0,
469,9,263,4,0,
469,9,290,4,0,
469,9,314,3,0,
469,9,318,4,0,
469,9,324,3,0,
469,9,332,4,0,
469,9,355,4,0,
469,9,363,4,0,
469,9,364,1,38,
469,9,369,1,49,
469,9,369,4,0,
469,9,400,1,1,1
469,9,403,1,54,
469,9,405,1,57,
469,9,416,4,0,
469,9,432,4,0,
469,9,445,4,0,
469,9,450,1,1,2
469,9,466,3,0,
469,10,29,3,0,
469,10,33,1,1,3
469,10,48,1,22,
469,10,49,1,14,
469,10,63,4,0,
469,10,76,4,0,
469,10,81,3,0,
469,10,92,4,0,
469,10,94,4,0,
469,10,98,1,1,5
469,10,98,1,6,
469,10,103,1,46,
469,10,104,1,1,6
469,10,104,1,11,
469,10,104,4,0,
469,10,129,3,0,
469,10,138,4,0,
469,10,148,4,0,
469,10,156,4,0,
469,10,163,1,43,
469,10,164,4,0,
469,10,168,4,0,
469,10,173,3,0,
469,10,182,4,0,
469,10,189,3,0,
469,10,193,1,1,4
469,10,197,1,17,
469,10,202,4,0,
469,10,203,4,0,
469,10,207,4,0,
469,10,211,4,0,
469,10,213,4,0,
469,10,214,4,0,
469,10,216,4,0,
469,10,218,4,0,
469,10,228,1,30,
469,10,237,4,0,
469,10,241,4,0,
469,10,244,4,0,
469,10,246,1,33,
469,10,246,3,0,
469,10,247,4,0,
469,10,253,1,27,
469,10,253,3,0,
469,10,263,4,0,
469,10,290,4,0,
469,10,314,3,0,
469,10,318,4,0,
469,10,324,3,0,
469,10,332,4,0,
469,10,355,4,0,
469,10,363,4,0,
469,10,364,1,38,
469,10,366,3,0,
469,10,369,1,49,
469,10,369,4,0,
469,10,400,1,1,1
469,10,403,1,54,
469,10,405,1,57,
469,10,416,4,0,
469,10,445,4,0,
469,10,450,1,1,2
469,10,450,3,0,
469,10,466,3,0,
469,11,33,1,1,3
469,11,48,1,22,
469,11,49,1,14,
469,11,63,4,0,
469,11,76,4,0,
469,11,92,4,0,
469,11,94,4,0,
469,11,98,1,1,5
469,11,98,1,6,
469,11,103,1,46,
469,11,104,1,1,6
469,11,104,1,11,
469,11,104,4,0,
469,11,138,4,0,
469,11,148,4,0,
469,11,156,4,0,
469,11,163,1,43,
469,11,164,4,0,
469,11,168,4,0,
469,11,182,4,0,
469,11,193,1,1,4
469,11,197,1,17,
469,11,207,4,0,
469,11,213,4,0,
469,11,216,4,0,
469,11,218,4,0,
469,11,228,1,30,
469,11,237,4,0,
469,11,241,4,0,
469,11,244,4,0,
469,11,246,1,33,
469,11,247,4,0,
469,11,253,1,27,
469,11,263,4,0,
469,11,332,4,0,
469,11,364,1,38,
469,11,369,1,49,
469,11,369,4,0,
469,11,400,1,1,1
469,11,403,1,54,
469,11,405,1,57,
469,11,416,4,0,
469,11,450,1,1,2
469,11,496,4,0,
469,11,522,4,0,
469,14,33,1,1,3
469,14,48,1,22,
469,14,49,1,14,
469,14,63,4,0,
469,14,76,4,0,
469,14,92,4,0,
469,14,94,4,0,
469,14,98,1,1,5
469,14,98,1,6,
469,14,103,1,46,
469,14,104,1,1,6
469,14,104,1,11,
469,14,104,4,0,
469,14,138,4,0,
469,14,148,4,0,
469,14,156,4,0,
469,14,163,1,43,
469,14,164,4,0,
469,14,168,4,0,
469,14,173,3,0,
469,14,182,4,0,
469,14,193,1,1,4
469,14,197,1,17,
469,14,202,3,0,
469,14,207,4,0,
469,14,213,4,0,
469,14,214,3,0,
469,14,216,4,0,
469,14,218,4,0,
469,14,228,1,30,
469,14,237,4,0,
469,14,241,4,0,
469,14,244,4,0,
469,14,246,1,33,
469,14,247,4,0,
469,14,253,1,27,
469,14,253,3,0,
469,14,263,4,0,
469,14,324,3,0,
469,14,332,4,0,
469,14,355,3,0,
469,14,364,1,38,
469,14,366,3,0,
469,14,369,1,49,
469,14,369,4,0,
469,14,400,1,1,1
469,14,403,1,54,
469,14,405,1,57,
469,14,416,4,0,
469,14,450,1,1,2
469,14,450,3,0,
469,14,496,4,0,
469,14,522,4,0,
469,15,33,1,1,5
469,15,48,1,22,
469,15,49,1,14,
469,15,63,4,0,
469,15,76,4,0,
469,15,92,4,0,
469,15,94,4,0,
469,15,98,1,1,7
469,15,98,1,6,
469,15,103,1,46,
469,15,104,1,1,8
469,15,104,1,11,
469,15,104,4,0,
469,15,138,4,0,
469,15,148,4,0,
469,15,156,4,0,
469,15,163,1,43,
469,15,164,4,0,
469,15,168,4,0,
469,15,182,4,0,
469,15,193,1,1,6
469,15,197,1,17,
469,15,207,4,0,
469,15,211,4,0,
469,15,213,4,0,
469,15,214,4,0,
469,15,216,4,0,
469,15,218,4,0,
469,15,228,1,30,
469,15,237,4,0,
469,15,241,4,0,
469,15,244,4,0,
469,15,246,1,33,
469,15,247,4,0,
469,15,253,1,27,
469,15,263,4,0,
469,15,332,4,0,
469,15,355,4,0,
469,15,364,1,38,
469,15,369,1,49,
469,15,369,4,0,
469,15,400,1,1,3
469,15,403,1,1,2
469,15,403,1,54,
469,15,405,1,1,1
469,15,405,1,57,
469,15,416,4,0,
469,15,450,1,1,4
469,15,496,4,0,
469,15,522,4,0,
469,15,590,4,0,
469,16,33,1,1,5
469,16,48,1,22,1
469,16,49,1,14,1
469,16,63,4,0,
469,16,76,4,0,
469,16,92,4,0,
469,16,94,4,0,
469,16,98,1,1,7
469,16,98,1,6,1
469,16,103,1,46,1
469,16,104,1,1,8
469,16,104,1,11,1
469,16,104,4,0,
469,16,138,4,0,
469,16,148,4,0,
469,16,156,4,0,
469,16,163,1,43,1
469,16,164,4,0,
469,16,168,4,0,
469,16,173,3,0,
469,16,182,4,0,
469,16,193,1,1,6
469,16,197,1,17,1
469,16,202,3,0,
469,16,207,4,0,
469,16,211,4,0,
469,16,213,4,0,
469,16,214,4,0,
469,16,216,4,0,
469,16,218,4,0,
469,16,228,1,30,1
469,16,237,4,0,
469,16,241,4,0,
469,16,244,4,0,
469,16,246,1,33,1
469,16,247,4,0,
469,16,253,1,27,1
469,16,253,3,0,
469,16,263,4,0,
469,16,290,4,0,
469,16,324,3,0,
469,16,332,4,0,
469,16,355,4,0,
469,16,364,1,38,1
469,16,366,3,0,
469,16,369,1,49,1
469,16,369,4,0,
469,16,400,1,1,3
469,16,403,1,1,2
469,16,403,1,54,1
469,16,405,1,1,1
469,16,405,1,57,1
469,16,416,4,0,
469,16,450,1,1,4
469,16,450,3,0,
469,16,496,4,0,
469,16,522,4,0,
469,16,590,4,0,
469,17,33,1,1,5
469,17,48,1,22,
469,17,49,1,14,
469,17,63,4,0,
469,17,76,4,0,
469,17,92,4,0,
469,17,94,4,0,
469,17,98,1,1,7
469,17,98,1,6,
469,17,103,1,46,
469,17,104,1,1,8
469,17,104,1,11,
469,17,104,4,0,
469,17,138,4,0,
469,17,141,4,0,
469,17,156,4,0,
469,17,163,1,43,
469,17,164,4,0,
469,17,168,4,0,
469,17,182,4,0,
469,17,193,1,1,6
469,17,197,1,17,
469,17,207,4,0,
469,17,211,4,0,
469,17,213,4,0,
469,17,214,4,0,
469,17,216,4,0,
469,17,218,4,0,
469,17,228,1,30,
469,17,237,4,0,
469,17,241,4,0,
469,17,244,4,0,
469,17,246,1,33,
469,17,247,4,0,
469,17,253,1,27,
469,17,263,4,0,
469,17,332,4,0,
469,17,355,4,0,
469,17,364,1,38,
469,17,369,1,49,
469,17,369,4,0,
469,17,400,1,1,3
469,17,403,1,1,2
469,17,403,1,54,
469,17,405,1,1,1
469,17,405,1,57,
469,17,416,4,0,
469,17,450,1,1,4
469,17,496,4,0,
469,17,590,4,0,
469,18,33,1,1,5
469,18,48,1,22,
469,18,49,1,14,
469,18,63,4,0,
469,18,76,4,0,
469,18,92,4,0,
469,18,94,4,0,
469,18,98,1,1,7
469,18,98,1,6,
469,18,103,1,46,
469,18,104,1,1,8
469,18,104,1,11,
469,18,104,4,0,
469,18,138,4,0,
469,18,141,4,0,
469,18,156,4,0,
469,18,163,1,43,
469,18,164,4,0,
469,18,168,4,0,
469,18,182,4,0,
469,18,193,1,1,6
469,18,197,1,17,
469,18,207,4,0,
469,18,211,4,0,
469,18,213,4,0,
469,18,214,4,0,
469,18,216,4,0,
469,18,218,4,0,
469,18,228,1,30,
469,18,237,4,0,
469,18,241,4,0,
469,18,244,4,0,
469,18,246,1,33,
469,18,247,4,0,
469,18,253,1,27,
469,18,263,4,0,
469,18,332,4,0,
469,18,355,4,0,
469,18,364,1,38,
469,18,369,1,49,
469,18,369,4,0,
469,18,400,1,1,3
469,18,403,1,1,2
469,18,403,1,54,
469,18,405,1,1,1
469,18,405,1,57,
469,18,416,4,0,
469,18,450,1,1,4
469,18,496,4,0,
469,18,590,4,0,
470,8,14,4,0,
470,8,28,1,8,
470,8,33,1,1,1
470,8,39,1,1,2
470,8,46,4,0,
470,8,63,4,0,
470,8,70,4,0,
470,8,75,1,15,
470,8,76,4,0,
470,8,91,4,0,
470,8,92,4,0,
470,8,98,1,22,
470,8,104,4,0,
470,8,148,4,0,
470,8,156,4,0,
470,8,164,4,0,
470,8,182,4,0,
470,8,202,1,43,
470,8,202,4,0,
470,8,203,4,0,
470,8,207,4,0,
470,8,213,4,0,
470,8,214,4,0,
470,8,216,4,0,
470,8,218,4,0,
470,8,231,4,0,
470,8,235,1,29,
470,8,237,4,0,
470,8,240,4,0,
470,8,241,1,64,
470,8,241,4,0,
470,8,247,4,0,
470,8,249,4,0,
470,8,263,4,0,
470,8,270,1,1,3
470,8,290,4,0,
470,8,320,1,57,
470,8,331,4,0,
470,8,332,4,0,
470,8,345,1,36,
470,8,348,1,71,
470,8,363,4,0,
470,8,387,1,50,
470,8,404,4,0,
470,8,412,4,0,
470,8,416,4,0,
470,8,445,4,0,
470,8,447,4,0,
470,9,14,1,78,
470,9,14,4,0,
470,9,28,1,8,
470,9,33,1,1,2
470,9,39,1,1,1
470,9,46,4,0,
470,9,63,4,0,
470,9,70,4,0,
470,9,75,1,15,
470,9,76,4,0,
470,9,91,4,0,
470,9,92,4,0,
470,9,98,1,22,
470,9,104,4,0,
470,9,129,3,0,
470,9,148,4,0,
470,9,156,4,0,
470,9,164,4,0,
470,9,173,3,0,
470,9,182,4,0,
470,9,189,3,0,
470,9,202,1,43,
470,9,202,4,0,
470,9,203,4,0,
470,9,207,4,0,
470,9,210,3,0,
470,9,213,4,0,
470,9,214,4,0,
470,9,216,4,0,
470,9,218,4,0,
470,9,231,4,0,
470,9,235,1,29,
470,9,235,3,0,
470,9,237,4,0,
470,9,240,4,0,
470,9,241,1,64,
470,9,241,4,0,
470,9,247,4,0,
470,9,249,4,0,
470,9,263,4,0,
470,9,270,1,1,3
470,9,270,3,0,
470,9,282,3,0,
470,9,290,4,0,
470,9,320,1,57,
470,9,331,4,0,
470,9,332,4,0,
470,9,345,1,36,
470,9,348,1,71,
470,9,363,4,0,
470,9,387,1,50,
470,9,387,3,0,
470,9,402,3,0,
470,9,404,4,0,
470,9,412,4,0,
470,9,416,4,0,
470,9,445,4,0,
470,9,447,4,0,
470,10,14,1,78,
470,10,14,4,0,
470,10,28,1,8,
470,10,29,3,0,
470,10,33,1,1,2
470,10,39,1,1,1
470,10,46,4,0,
470,10,63,4,0,
470,10,70,4,0,
470,10,75,1,15,
470,10,76,4,0,
470,10,91,4,0,
470,10,92,4,0,
470,10,98,1,22,
470,10,104,4,0,
470,10,129,3,0,
470,10,148,4,0,
470,10,156,4,0,
470,10,164,4,0,
470,10,173,3,0,
470,10,182,4,0,
470,10,189,3,0,
470,10,202,1,43,
470,10,202,4,0,
470,10,203,4,0,
470,10,207,4,0,
470,10,210,3,0,
470,10,213,4,0,
470,10,214,4,0,
470,10,215,3,0,
470,10,216,4,0,
470,10,218,4,0,
470,10,231,4,0,
470,10,235,1,29,
470,10,235,3,0,
470,10,237,4,0,
470,10,240,4,0,
470,10,241,1,64,
470,10,241,4,0,
470,10,247,4,0,
470,10,249,4,0,
470,10,263,4,0,
470,10,270,1,1,3
470,10,270,3,0,
470,10,282,3,0,
470,10,290,4,0,
470,10,320,1,57,
470,10,331,4,0,
470,10,332,4,0,
470,10,345,1,36,
470,10,348,1,71,
470,10,363,4,0,
470,10,387,1,50,
470,10,387,3,0,
470,10,388,3,0,
470,10,402,3,0,
470,10,404,4,0,
470,10,412,4,0,
470,10,416,4,0,
470,10,445,4,0,
470,10,447,4,0,
470,11,14,1,78,
470,11,14,4,0,
470,11,28,1,8,
470,11,33,1,1,2
470,11,39,1,1,1
470,11,46,4,0,
470,11,63,4,0,
470,11,70,4,0,
470,11,75,1,15,
470,11,76,4,0,
470,11,91,4,0,
470,11,92,4,0,
470,11,98,1,22,
470,11,104,4,0,
470,11,148,4,0,
470,11,156,4,0,
470,11,164,4,0,
470,11,182,4,0,
470,11,202,1,43,
470,11,207,4,0,
470,11,213,4,0,
470,11,216,4,0,
470,11,218,4,0,
470,11,235,1,29,
470,11,237,4,0,
470,11,240,4,0,
470,11,241,1,64,
470,11,241,4,0,
470,11,247,4,0,
470,11,249,4,0,
470,11,263,4,0,
470,11,270,1,1,3
470,11,320,1,57,
470,11,332,4,0,
470,11,345,1,36,
470,11,348,1,71,
470,11,387,1,50,
470,11,404,4,0,
470,11,412,4,0,
470,11,416,4,0,
470,11,447,4,0,
470,11,496,4,0,
470,11,497,4,0,
470,11,514,4,0,
470,11,526,4,0,
470,14,14,1,29,
470,14,14,4,0,
470,14,28,1,5,
470,14,33,1,1,2
470,14,39,1,1,1
470,14,46,4,0,
470,14,63,4,0,
470,14,70,4,0,
470,14,75,1,9,
470,14,76,4,0,
470,14,91,4,0,
470,14,92,4,0,
470,14,98,1,13,
470,14,104,4,0,
470,14,148,4,0,
470,14,156,4,0,
470,14,164,4,0,
470,14,173,3,0,
470,14,182,4,0,
470,14,202,1,25,
470,14,202,3,0,
470,14,207,4,0,
470,14,213,4,0,
470,14,214,3,0,
470,14,215,3,0,
470,14,216,4,0,
470,14,218,4,0,
470,14,231,3,0,
470,14,235,1,33,
470,14,235,3,0,
470,14,237,4,0,
470,14,240,4,0,
470,14,241,1,37,
470,14,241,4,0,
470,14,247,4,0,
470,14,249,4,0,
470,14,263,4,0,
470,14,270,1,1,3
470,14,270,3,0,
470,14,282,3,0,
470,14,304,3,0,
470,14,320,1,17,
470,14,332,4,0,
470,14,343,3,0,
470,14,345,1,21,
470,14,348,1,45,
470,14,387,1,41,
470,14,387,3,0,
470,14,388,3,0,
470,14,402,3,0,
470,14,404,4,0,
470,14,412,4,0,
470,14,416,4,0,
470,14,447,4,0,
470,14,496,4,0,
470,14,497,4,0,
470,14,514,4,0,
470,14,526,4,0,
470,15,14,1,29,
470,15,14,4,0,
470,15,28,1,5,
470,15,33,1,1,2
470,15,39,1,1,1
470,15,46,4,0,
470,15,63,4,0,
470,15,70,4,0,
470,15,75,1,9,
470,15,76,4,0,
470,15,91,4,0,
470,15,92,4,0,
470,15,98,1,13,
470,15,104,4,0,
470,15,148,4,0,
470,15,156,4,0,
470,15,164,4,0,
470,15,182,4,0,
470,15,202,1,25,
470,15,207,4,0,
470,15,213,4,0,
470,15,214,4,0,
470,15,216,4,0,
470,15,218,4,0,
470,15,235,1,33,
470,15,237,4,0,
470,15,240,4,0,
470,15,241,1,37,
470,15,241,4,0,
470,15,247,4,0,
470,15,249,4,0,
470,15,263,4,0,
470,15,267,4,0,
470,15,270,1,1,3
470,15,320,1,17,
470,15,332,4,0,
470,15,345,1,20,
470,15,348,1,45,
470,15,387,1,41,
470,15,404,4,0,
470,15,412,4,0,
470,15,416,4,0,
470,15,447,4,0,
470,15,496,4,0,
470,15,497,4,0,
470,15,514,4,0,
470,15,590,4,0,
470,16,14,1,29,1
470,16,14,4,0,
470,16,28,1,5,1
470,16,33,1,1,2
470,16,39,1,1,1
470,16,46,4,0,
470,16,63,4,0,
470,16,70,4,0,
470,16,75,1,9,1
470,16,76,4,0,
470,16,91,4,0,
470,16,92,4,0,
470,16,98,1,13,1
470,16,104,4,0,
470,16,148,4,0,
470,16,156,4,0,
470,16,164,4,0,
470,16,173,3,0,
470,16,182,4,0,
470,16,202,1,25,1
470,16,202,3,0,
470,16,207,4,0,
470,16,213,4,0,
470,16,214,4,0,
470,16,215,3,0,
470,16,216,4,0,
470,16,218,4,0,
470,16,231,3,0,
470,16,235,1,33,1
470,16,235,3,0,
470,16,237,4,0,
470,16,240,4,0,
470,16,241,1,37,1
470,16,241,4,0,
470,16,247,4,0,
470,16,249,4,0,
470,16,263,4,0,
470,16,267,4,0,
470,16,270,1,1,3
470,16,270,3,0,
470,16,282,3,0,
470,16,290,4,0,
470,16,304,3,0,
470,16,320,1,17,1
470,16,332,4,0,
470,16,343,3,0,
470,16,345,1,20,1
470,16,348,1,45,1
470,16,387,1,41,1
470,16,387,3,0,
470,16,388,3,0,
470,16,402,3,0,
470,16,404,4,0,
470,16,412,4,0,
470,16,416,4,0,
470,16,447,4,0,
470,16,496,4,0,
470,16,497,4,0,
470,16,514,4,0,
470,16,590,4,0,
470,17,14,1,29,
470,17,14,4,0,
470,17,28,1,5,
470,17,33,1,1,3
470,17,39,1,1,4
470,17,46,4,0,
470,17,63,4,0,
470,17,75,1,0,
470,17,75,1,1,1
470,17,76,4,0,
470,17,92,4,0,
470,17,98,1,13,
470,17,104,4,0,
470,17,156,4,0,
470,17,164,4,0,
470,17,182,4,0,
470,17,202,1,25,
470,17,207,4,0,
470,17,213,4,0,
470,17,214,4,0,
470,17,216,4,0,
470,17,218,4,0,
470,17,235,1,33,
470,17,237,4,0,
470,17,240,4,0,
470,17,241,1,37,
470,17,241,4,0,
470,17,247,4,0,
470,17,263,4,0,
470,17,267,4,0,
470,17,270,1,1,2
470,17,320,1,17,
470,17,332,4,0,
470,17,345,1,20,
470,17,348,1,45,
470,17,387,1,41,
470,17,404,4,0,
470,17,412,4,0,
470,17,416,4,0,
470,17,447,4,0,
470,17,496,4,0,
470,17,497,4,0,
470,17,526,4,0,
470,17,590,4,0,
470,17,608,1,9,
470,18,14,1,29,
470,18,14,4,0,
470,18,28,1,5,
470,18,33,1,1,3
470,18,39,1,1,4
470,18,46,4,0,
470,18,63,4,0,
470,18,75,1,0,
470,18,75,1,1,1
470,18,76,4,0,
470,18,92,4,0,
470,18,98,1,13,
470,18,104,4,0,
470,18,156,4,0,
470,18,164,4,0,
470,18,182,4,0,
470,18,202,1,25,
470,18,207,4,0,
470,18,213,4,0,
470,18,214,4,0,
470,18,216,4,0,
470,18,218,4,0,
470,18,235,1,33,
470,18,237,4,0,
470,18,240,4,0,
470,18,241,1,37,
470,18,241,4,0,
470,18,247,4,0,
470,18,263,4,0,
470,18,267,4,0,
470,18,270,1,1,2
470,18,320,1,17,
470,18,332,4,0,
470,18,345,1,20,
470,18,348,1,45,
470,18,387,1,41,
470,18,404,4,0,
470,18,412,4,0,
470,18,416,4,0,
470,18,447,4,0,
470,18,496,4,0,
470,18,497,4,0,
470,18,526,4,0,
470,18,590,4,0,
470,18,608,1,9,
471,8,28,1,8,
471,8,33,1,1,1
471,8,39,1,1,2
471,8,44,1,29,
471,8,46,4,0,
471,8,58,4,0,
471,8,59,1,71,
471,8,59,4,0,
471,8,63,4,0,
471,8,70,4,0,
471,8,91,4,0,
471,8,92,4,0,
471,8,98,1,22,
471,8,104,4,0,
471,8,156,4,0,
471,8,164,4,0,
471,8,182,4,0,
471,8,196,1,15,
471,8,203,4,0,
471,8,207,4,0,
471,8,213,4,0,
471,8,214,4,0,
471,8,216,4,0,
471,8,218,4,0,
471,8,231,4,0,
471,8,237,4,0,
471,8,240,4,0,
471,8,241,4,0,
471,8,243,1,57,
471,8,247,4,0,
471,8,249,4,0,
471,8,258,1,64,
471,8,258,4,0,
471,8,263,4,0,
471,8,270,1,1,3
471,8,290,4,0,
471,8,352,4,0,
471,8,363,4,0,
471,8,387,1,50,
471,8,416,4,0,
471,8,419,4,0,
471,8,420,1,36,
471,8,423,1,43,
471,8,445,4,0,
471,9,28,1,8,
471,9,33,1,1,2
471,9,39,1,1,1
471,9,44,1,29,
471,9,46,4,0,
471,9,58,4,0,
471,9,59,1,71,
471,9,59,4,0,
471,9,63,4,0,
471,9,70,4,0,
471,9,91,4,0,
471,9,92,4,0,
471,9,98,1,22,
471,9,104,4,0,
471,9,112,1,78,
471,9,129,3,0,
471,9,156,4,0,
471,9,164,4,0,
471,9,173,3,0,
471,9,182,4,0,
471,9,189,3,0,
471,9,196,1,15,
471,9,196,3,0,
471,9,203,4,0,
471,9,207,4,0,
471,9,213,4,0,
471,9,214,4,0,
471,9,216,4,0,
471,9,218,4,0,
471,9,231,4,0,
471,9,237,4,0,
471,9,240,4,0,
471,9,241,4,0,
471,9,243,1,57,
471,9,247,4,0,
471,9,249,4,0,
471,9,258,1,64,
471,9,258,4,0,
471,9,263,4,0,
471,9,270,1,1,3
471,9,270,3,0,
471,9,290,4,0,
471,9,324,3,0,
471,9,352,4,0,
471,9,363,4,0,
471,9,387,1,50,
471,9,387,3,0,
471,9,401,3,0,
471,9,416,4,0,
471,9,419,4,0,
471,9,420,1,36,
471,9,423,1,43,
471,9,445,4,0,
471,10,28,1,8,
471,10,29,3,0,
471,10,33,1,1,2
471,10,39,1,1,1
471,10,44,1,29,
471,10,46,4,0,
471,10,58,4,0,
471,10,59,1,71,
471,10,59,4,0,
471,10,63,4,0,
471,10,70,4,0,
471,10,91,4,0,
471,10,92,4,0,
471,10,98,1,22,
471,10,104,4,0,
471,10,112,1,78,
471,10,129,3,0,
471,10,156,4,0,
471,10,164,4,0,
471,10,173,3,0,
471,10,182,4,0,
471,10,189,3,0,
471,10,196,1,15,
471,10,196,3,0,
471,10,203,4,0,
471,10,207,4,0,
471,10,213,4,0,
471,10,214,4,0,
471,10,215,3,0,
471,10,216,4,0,
471,10,218,4,0,
471,10,231,4,0,
471,10,237,4,0,
471,10,240,4,0,
471,10,241,4,0,
471,10,243,1,57,
471,10,247,4,0,
471,10,249,4,0,
471,10,258,1,64,
471,10,258,4,0,
471,10,263,4,0,
471,10,270,1,1,3
471,10,270,3,0,
471,10,290,4,0,
471,10,324,3,0,
471,10,352,4,0,
471,10,363,4,0,
471,10,387,1,50,
471,10,387,3,0,
471,10,401,3,0,
471,10,416,4,0,
471,10,419,4,0,
471,10,420,1,36,
471,10,423,1,43,
471,10,445,4,0,
471,11,28,1,8,
471,11,33,1,1,2
471,11,39,1,1,1
471,11,44,1,29,
471,11,46,4,0,
471,11,58,4,0,
471,11,59,1,71,
471,11,59,4,0,
471,11,63,4,0,
471,11,70,4,0,
471,11,91,4,0,
471,11,92,4,0,
471,11,98,1,22,
471,11,104,4,0,
471,11,112,1,78,
471,11,156,4,0,
471,11,164,4,0,
471,11,182,4,0,
471,11,196,1,15,
471,11,207,4,0,
471,11,213,4,0,
471,11,216,4,0,
471,11,218,4,0,
471,11,237,4,0,
471,11,240,4,0,
471,11,241,4,0,
471,11,243,1,57,
471,11,247,4,0,
471,11,249,4,0,
471,11,258,1,64,
471,11,258,4,0,
471,11,263,4,0,
471,11,270,1,1,3
471,11,387,1,50,
471,11,416,4,0,
471,11,420,1,36,
471,11,423,1,43,
471,11,496,4,0,
471,11,497,4,0,
471,11,514,4,0,
471,11,524,4,0,
471,11,526,4,0,
471,14,28,1,5,
471,14,33,1,1,2
471,14,39,1,1,3
471,14,44,1,17,
471,14,46,4,0,
471,14,58,4,0,
471,14,59,1,45,
471,14,59,4,0,
471,14,63,4,0,
471,14,70,4,0,
471,14,91,4,0,
471,14,92,4,0,
471,14,98,1,13,
471,14,104,4,0,
471,14,112,1,29,
471,14,156,4,0,
471,14,164,4,0,
471,14,173,3,0,
471,14,182,4,0,
471,14,196,1,9,
471,14,196,3,0,
471,14,207,4,0,
471,14,213,4,0,
471,14,214,3,0,
471,14,215,3,0,
471,14,216,4,0,
471,14,218,4,0,
471,14,231,3,0,
471,14,237,4,0,
471,14,240,4,0,
471,14,241,4,0,
471,14,243,1,33,
471,14,247,4,0,
471,14,249,4,0,
471,14,258,1,37,
471,14,258,4,0,
471,14,263,4,0,
471,14,270,1,1,1
471,14,270,3,0,
471,14,304,3,0,
471,14,324,3,0,
471,14,343,3,0,
471,14,387,1,41,
471,14,387,3,0,
471,14,401,3,0,
471,14,416,4,0,
471,14,420,1,25,
471,14,423,1,21,
471,14,496,4,0,
471,14,497,4,0,
471,14,514,4,0,
471,14,524,4,0,
471,14,526,4,0,
471,15,28,1,5,
471,15,33,1,1,2
471,15,39,1,1,3
471,15,44,1,17,
471,15,46,4,0,
471,15,58,4,0,
471,15,59,1,45,
471,15,59,4,0,
471,15,63,4,0,
471,15,70,4,0,
471,15,91,4,0,
471,15,92,4,0,
471,15,98,1,13,
471,15,104,4,0,
471,15,112,1,29,
471,15,156,4,0,
471,15,164,4,0,
471,15,182,4,0,
471,15,196,1,9,
471,15,207,4,0,
471,15,213,4,0,
471,15,214,4,0,
471,15,216,4,0,
471,15,218,4,0,
471,15,237,4,0,
471,15,240,4,0,
471,15,241,4,0,
471,15,243,1,33,
471,15,247,4,0,
471,15,249,4,0,
471,15,258,1,37,
471,15,258,4,0,
471,15,263,4,0,
471,15,270,1,1,1
471,15,387,1,41,
471,15,416,4,0,
471,15,420,1,25,
471,15,423,1,20,
471,15,496,4,0,
471,15,497,4,0,
471,15,514,4,0,
471,15,524,4,0,
471,15,590,4,0,
471,16,28,1,5,1
471,16,33,1,1,2
471,16,39,1,1,3
471,16,44,1,17,1
471,16,46,4,0,
471,16,58,4,0,
471,16,59,1,45,1
471,16,59,4,0,
471,16,63,4,0,
471,16,70,4,0,
471,16,91,4,0,
471,16,92,4,0,
471,16,98,1,13,1
471,16,104,4,0,
471,16,112,1,29,1
471,16,156,4,0,
471,16,164,4,0,
471,16,173,3,0,
471,16,182,4,0,
471,16,196,1,9,1
471,16,196,3,0,
471,16,207,4,0,
471,16,213,4,0,
471,16,214,4,0,
471,16,215,3,0,
471,16,216,4,0,
471,16,218,4,0,
471,16,231,3,0,
471,16,237,4,0,
471,16,240,4,0,
471,16,241,4,0,
471,16,243,1,33,1
471,16,247,4,0,
471,16,249,4,0,
471,16,258,1,37,1
471,16,258,4,0,
471,16,263,4,0,
471,16,270,1,1,1
471,16,270,3,0,
471,16,290,4,0,
471,16,304,3,0,
471,16,324,3,0,
471,16,343,3,0,
471,16,352,3,0,
471,16,387,1,41,1
471,16,387,3,0,
471,16,401,3,0,
471,16,416,4,0,
471,16,420,1,25,1
471,16,423,1,20,1
471,16,496,4,0,
471,16,497,4,0,
471,16,514,4,0,
471,16,524,4,0,
471,16,590,4,0,
471,17,28,1,5,
471,17,33,1,1,3
471,17,39,1,1,4
471,17,44,1,17,
471,17,46,4,0,
471,17,58,4,0,
471,17,59,1,45,
471,17,59,4,0,
471,17,63,4,0,
471,17,92,4,0,
471,17,98,1,13,
471,17,104,4,0,
471,17,112,1,29,
471,17,156,4,0,
471,17,164,4,0,
471,17,182,4,0,
471,17,196,1,0,
471,17,196,1,1,1
471,17,207,4,0,
471,17,213,4,0,
471,17,214,4,0,
471,17,216,4,0,
471,17,218,4,0,
471,17,237,4,0,
471,17,240,4,0,
471,17,241,4,0,
471,17,243,1,33,
471,17,247,4,0,
471,17,258,1,37,
471,17,258,4,0,
471,17,263,4,0,
471,17,270,1,1,2
471,17,387,1,41,
471,17,416,4,0,
471,17,420,1,25,
471,17,423,1,20,
471,17,496,4,0,
471,17,497,4,0,
471,17,524,4,0,
471,17,526,4,0,
471,17,590,4,0,
471,17,608,1,9,
471,17,694,4,0,
471,18,28,1,5,
471,18,33,1,1,3
471,18,39,1,1,4
471,18,44,1,17,
471,18,46,4,0,
471,18,58,4,0,
471,18,59,1,45,
471,18,59,4,0,
471,18,63,4,0,
471,18,92,4,0,
471,18,98,1,13,
471,18,104,4,0,
471,18,112,1,29,
471,18,156,4,0,
471,18,164,4,0,
471,18,182,4,0,
471,18,196,1,0,
471,18,196,1,1,1
471,18,207,4,0,
471,18,213,4,0,
471,18,214,4,0,
471,18,216,4,0,
471,18,218,4,0,
471,18,237,4,0,
471,18,240,4,0,
471,18,241,4,0,
471,18,243,1,33,
471,18,247,4,0,
471,18,258,1,37,
471,18,258,4,0,
471,18,263,4,0,
471,18,270,1,1,2
471,18,387,1,41,
471,18,416,4,0,
471,18,420,1,25,
471,18,423,1,20,
471,18,496,4,0,
471,18,497,4,0,
471,18,524,4,0,
471,18,526,4,0,
471,18,590,4,0,
471,18,608,1,9,
471,18,694,4,0,
472,8,12,1,45,
472,8,14,1,34,
472,8,14,4,0,
472,8,15,4,0,
472,8,28,1,1,5
472,8,28,1,5,
472,8,63,4,0,
472,8,70,4,0,
472,8,89,4,0,
472,8,91,4,0,
472,8,92,4,0,
472,8,98,1,16,
472,8,103,1,27,
472,8,104,4,0,
472,8,106,1,1,6
472,8,106,1,9,
472,8,156,4,0,
472,8,157,4,0,
472,8,164,4,0,
472,8,168,4,0,
472,8,182,4,0,
472,8,185,1,23,
472,8,188,4,0,
472,8,201,4,0,
472,8,203,4,0,
472,8,206,4,0,
472,8,207,4,0,
472,8,210,1,20,
472,8,211,4,0,
472,8,213,4,0,
472,8,214,4,0,
472,8,216,4,0,
472,8,218,4,0,
472,8,231,4,0,
472,8,237,4,0,
472,8,240,4,0,
472,8,241,4,0,
472,8,249,4,0,
472,8,259,4,0,
472,8,263,4,0,
472,8,269,4,0,
472,8,280,4,0,
472,8,282,1,1,7
472,8,282,1,12,
472,8,290,4,0,
472,8,317,4,0,
472,8,332,4,0,
472,8,355,4,0,
472,8,363,4,0,
472,8,369,1,38,
472,8,369,4,0,
472,8,371,4,0,
472,8,374,4,0,
472,8,397,4,0,
472,8,398,1,1,4
472,8,398,4,0,
472,8,399,4,0,
472,8,400,1,31,
472,8,404,1,42,
472,8,404,4,0,
472,8,416,4,0,
472,8,422,1,1,1
472,8,423,1,1,2
472,8,424,1,1,3
472,8,432,4,0,
472,8,444,4,0,
472,8,445,4,0,
472,8,446,4,0,
472,9,12,1,45,
472,9,14,1,34,
472,9,14,4,0,
472,9,15,4,0,
472,9,28,1,1,5
472,9,28,1,5,
472,9,63,4,0,
472,9,70,4,0,
472,9,89,4,0,
472,9,91,4,0,
472,9,92,4,0,
472,9,98,1,16,
472,9,103,1,27,
472,9,104,4,0,
472,9,106,1,1,6
472,9,106,1,9,
472,9,129,3,0,
472,9,156,4,0,
472,9,157,4,0,
472,9,164,4,0,
472,9,168,4,0,
472,9,173,3,0,
472,9,182,4,0,
472,9,185,1,23,
472,9,188,4,0,
472,9,189,3,0,
472,9,201,4,0,
472,9,203,4,0,
472,9,206,4,0,
472,9,207,4,0,
472,9,210,1,20,
472,9,210,3,0,
472,9,211,4,0,
472,9,213,4,0,
472,9,214,4,0,
472,9,216,4,0,
472,9,218,4,0,
472,9,231,4,0,
472,9,237,4,0,
472,9,240,4,0,
472,9,241,4,0,
472,9,249,4,0,
472,9,259,4,0,
472,9,263,4,0,
472,9,269,4,0,
472,9,280,4,0,
472,9,282,1,1,7
472,9,282,1,12,
472,9,282,3,0,
472,9,290,4,0,
472,9,317,4,0,
472,9,332,4,0,
472,9,355,4,0,
472,9,363,4,0,
472,9,369,1,38,
472,9,369,4,0,
472,9,371,4,0,
472,9,374,4,0,
472,9,397,4,0,
472,9,398,1,1,4
472,9,398,4,0,
472,9,399,4,0,
472,9,400,1,31,
472,9,401,3,0,
472,9,404,1,42,
472,9,404,4,0,
472,9,414,3,0,
472,9,416,4,0,
472,9,422,1,1,1
472,9,423,1,1,2
472,9,424,1,1,3
472,9,432,4,0,
472,9,444,4,0,
472,9,445,4,0,
472,9,446,4,0,
472,10,12,1,45,
472,10,14,1,34,
472,10,14,4,0,
472,10,15,4,0,
472,10,28,1,1,5
472,10,28,1,5,
472,10,29,3,0,
472,10,63,4,0,
472,10,70,4,0,
472,10,89,4,0,
472,10,91,4,0,
472,10,92,4,0,
472,10,98,1,16,
472,10,103,1,27,
472,10,104,4,0,
472,10,106,1,1,6
472,10,106,1,9,
472,10,129,3,0,
472,10,143,3,0,
472,10,156,4,0,
472,10,157,4,0,
472,10,164,4,0,
472,10,168,4,0,
472,10,173,3,0,
472,10,182,4,0,
472,10,185,1,23,
472,10,188,4,0,
472,10,189,3,0,
472,10,201,4,0,
472,10,203,4,0,
472,10,206,4,0,
472,10,207,4,0,
472,10,210,1,20,
472,10,210,3,0,
472,10,211,4,0,
472,10,213,4,0,
472,10,214,4,0,
472,10,216,4,0,
472,10,218,4,0,
472,10,231,4,0,
472,10,237,4,0,
472,10,240,4,0,
472,10,241,4,0,
472,10,249,4,0,
472,10,259,4,0,
472,10,263,4,0,
472,10,269,4,0,
472,10,280,4,0,
472,10,282,1,1,7
472,10,282,1,12,
472,10,282,3,0,
472,10,290,4,0,
472,10,317,4,0,
472,10,332,4,0,
472,10,355,4,0,
472,10,363,4,0,
472,10,366,3,0,
472,10,369,1,38,
472,10,369,4,0,
472,10,371,4,0,
472,10,374,4,0,
472,10,397,4,0,
472,10,398,1,1,4
472,10,398,4,0,
472,10,399,4,0,
472,10,400,1,31,
472,10,401,3,0,
472,10,404,1,42,
472,10,404,4,0,
472,10,414,3,0,
472,10,416,4,0,
472,10,422,1,1,1
472,10,423,1,1,2
472,10,424,1,1,3
472,10,444,4,0,
472,10,445,4,0,
472,10,446,4,0,
472,11,12,1,49,
472,11,14,1,38,
472,11,14,4,0,
472,11,15,4,0,
472,11,28,1,1,5
472,11,28,1,5,
472,11,63,4,0,
472,11,70,4,0,
472,11,89,4,0,
472,11,91,4,0,
472,11,92,4,0,
472,11,98,1,16,
472,11,103,1,31,
472,11,104,4,0,
472,11,106,1,1,6
472,11,106,1,9,
472,11,156,4,0,
472,11,157,4,0,
472,11,164,4,0,
472,11,168,4,0,
472,11,182,4,0,
472,11,185,1,23,
472,11,188,4,0,
472,11,201,4,0,
472,11,206,4,0,
472,11,207,4,0,
472,11,210,1,20,
472,11,213,4,0,
472,11,216,4,0,
472,11,218,4,0,
472,11,237,4,0,
472,11,240,4,0,
472,11,241,4,0,
472,11,249,4,0,
472,11,259,4,0,
472,11,263,4,0,
472,11,269,4,0,
472,11,280,4,0,
472,11,282,1,1,7
472,11,282,1,12,
472,11,317,4,0,
472,11,332,4,0,
472,11,369,1,42,
472,11,369,4,0,
472,11,371,4,0,
472,11,374,4,0,
472,11,397,4,0,
472,11,398,1,1,4
472,11,398,4,0,
472,11,400,1,34,
472,11,404,1,45,
472,11,404,4,0,
472,11,416,4,0,
472,11,422,1,1,1
472,11,423,1,1,2
472,11,424,1,1,3
472,11,444,4,0,
472,11,468,4,0,
472,11,474,4,0,
472,11,496,4,0,
472,11,512,1,27,
472,11,512,4,0,
472,11,522,4,0,
472,11,523,4,0,
472,14,12,1,55,
472,14,14,1,50,
472,14,14,4,0,
472,14,15,4,0,
472,14,28,1,1,5
472,14,28,1,4,
472,14,63,4,0,
472,14,70,4,0,
472,14,89,4,0,
472,14,91,4,0,
472,14,92,4,0,
472,14,98,1,13,
472,14,103,1,35,
472,14,104,4,0,
472,14,106,1,1,6
472,14,106,1,7,
472,14,143,3,0,
472,14,156,4,0,
472,14,157,4,0,
472,14,164,4,0,
472,14,168,4,0,
472,14,173,3,0,
472,14,182,4,0,
472,14,185,1,19,
472,14,188,4,0,
472,14,201,4,0,
472,14,206,4,0,
472,14,207,4,0,
472,14,210,1,16,
472,14,213,4,0,
472,14,214,3,0,
472,14,216,4,0,
472,14,218,4,0,
472,14,231,3,0,
472,14,237,4,0,
472,14,240,4,0,
472,14,241,4,0,
472,14,249,4,0,
472,14,259,4,0,
472,14,263,4,0,
472,14,269,4,0,
472,14,280,4,0,
472,14,282,1,1,7
472,14,282,1,10,
472,14,282,3,0,
472,14,317,4,0,
472,14,327,1,45,
472,14,332,4,0,
472,14,355,3,0,
472,14,366,3,0,
472,14,369,1,30,
472,14,369,4,0,
472,14,371,4,0,
472,14,374,4,0,
472,14,397,4,0,
472,14,398,1,1,4
472,14,398,4,0,
472,14,399,3,0,
472,14,400,1,27,
472,14,401,3,0,
472,14,404,1,40,
472,14,404,4,0,
472,14,414,3,0,
472,14,416,4,0,
472,14,422,1,1,1
472,14,423,1,1,2
472,14,424,1,1,3
472,14,444,4,0,
472,14,446,3,0,
472,14,450,3,0,
472,14,468,4,0,
472,14,474,4,0,
472,14,496,4,0,
472,14,512,1,22,
472,14,512,4,0,
472,14,522,4,0,
472,14,523,4,0,
472,15,12,1,1,1
472,15,12,1,55,
472,15,14,1,50,
472,15,14,4,0,
472,15,15,4,0,
472,15,28,1,1,6
472,15,28,1,4,
472,15,63,4,0,
472,15,70,4,0,
472,15,89,4,0,
472,15,91,4,0,
472,15,92,4,0,
472,15,98,1,13,
472,15,103,1,35,
472,15,104,4,0,
472,15,106,1,1,7
472,15,106,1,7,
472,15,156,4,0,
472,15,157,4,0,
472,15,164,4,0,
472,15,168,4,0,
472,15,182,4,0,
472,15,185,1,19,
472,15,188,4,0,
472,15,201,4,0,
472,15,206,4,0,
472,15,207,4,0,
472,15,210,1,16,
472,15,211,4,0,
472,15,213,4,0,
472,15,214,4,0,
472,15,216,4,0,
472,15,218,4,0,
472,15,237,4,0,
472,15,240,4,0,
472,15,241,4,0,
472,15,249,4,0,
472,15,259,4,0,
472,15,263,4,0,
472,15,269,4,0,
472,15,280,4,0,
472,15,282,1,1,8
472,15,282,1,10,
472,15,317,4,0,
472,15,327,1,45,
472,15,332,4,0,
472,15,355,4,0,
472,15,369,1,30,
472,15,369,4,0,
472,15,371,4,0,
472,15,374,4,0,
472,15,397,4,0,
472,15,398,1,1,5
472,15,398,4,0,
472,15,399,4,0,
472,15,400,1,27,
472,15,404,1,40,
472,15,404,4,0,
472,15,416,4,0,
472,15,422,1,1,2
472,15,423,1,1,3
472,15,424,1,1,4
472,15,444,4,0,
472,15,468,4,0,
472,15,474,4,0,
472,15,496,4,0,
472,15,512,1,22,
472,15,512,4,0,
472,15,522,4,0,
472,15,523,4,0,
472,15,590,4,0,
472,16,12,1,1,1
472,16,12,1,55,1
472,16,14,1,50,1
472,16,14,4,0,
472,16,15,4,0,
472,16,28,1,1,6
472,16,28,1,4,1
472,16,63,4,0,
472,16,70,4,0,
472,16,89,4,0,
472,16,91,4,0,
472,16,92,4,0,
472,16,98,1,13,1
472,16,103,1,35,1
472,16,104,4,0,
472,16,106,1,1,7
472,16,106,1,7,1
472,16,143,3,0,
472,16,156,4,0,
472,16,157,4,0,
472,16,164,4,0,
472,16,168,4,0,
472,16,173,3,0,
472,16,182,4,0,
472,16,185,1,19,1
472,16,188,4,0,
472,16,201,4,0,
472,16,206,4,0,
472,16,207,4,0,
472,16,210,1,16,1
472,16,211,4,0,
472,16,213,4,0,
472,16,214,4,0,
472,16,216,4,0,
472,16,218,4,0,
472,16,231,3,0,
472,16,237,4,0,
472,16,240,4,0,
472,16,241,4,0,
472,16,249,4,0,
472,16,259,4,0,
472,16,263,4,0,
472,16,269,4,0,
472,16,280,4,0,
472,16,282,1,1,8
472,16,282,1,10,1
472,16,282,3,0,
472,16,290,4,0,
472,16,317,4,0,
472,16,327,1,45,1
472,16,332,4,0,
472,16,355,4,0,
472,16,366,3,0,
472,16,369,1,30,1
472,16,369,4,0,
472,16,371,4,0,
472,16,374,4,0,
472,16,397,4,0,
472,16,398,1,1,5
472,16,398,4,0,
472,16,399,4,0,
472,16,400,1,27,1
472,16,401,3,0,
472,16,404,1,40,1
472,16,404,4,0,
472,16,414,3,0,
472,16,416,4,0,
472,16,422,1,1,2
472,16,423,1,1,3
472,16,424,1,1,4
472,16,444,4,0,
472,16,446,3,0,
472,16,450,3,0,
472,16,468,4,0,
472,16,474,4,0,
472,16,496,4,0,
472,16,512,1,22,1
472,16,512,4,0,
472,16,522,4,0,
472,16,523,4,0,
472,16,590,4,0,
472,17,12,1,1,1
472,17,12,1,55,
472,17,14,1,50,
472,17,14,4,0,
472,17,28,1,1,6
472,17,28,1,4,
472,17,63,4,0,
472,17,89,4,0,
472,17,92,4,0,
472,17,98,1,13,
472,17,103,1,35,
472,17,104,4,0,
472,17,106,1,1,7
472,17,106,1,7,
472,17,156,4,0,
472,17,157,4,0,
472,17,164,4,0,
472,17,168,4,0,
472,17,182,4,0,
472,17,185,1,19,
472,17,188,4,0,
472,17,201,4,0,
472,17,206,4,0,
472,17,207,4,0,
472,17,210,1,16,
472,17,211,4,0,
472,17,213,4,0,
472,17,214,4,0,
472,17,216,4,0,
472,17,218,4,0,
472,17,237,4,0,
472,17,240,4,0,
472,17,241,4,0,
472,17,259,4,0,
472,17,263,4,0,
472,17,269,4,0,
472,17,280,4,0,
472,17,282,1,1,8
472,17,282,1,10,
472,17,317,4,0,
472,17,327,1,45,
472,17,332,4,0,
472,17,355,4,0,
472,17,369,1,30,
472,17,369,4,0,
472,17,371,4,0,
472,17,374,4,0,
472,17,397,4,0,
472,17,398,1,1,5
472,17,398,4,0,
472,17,399,4,0,
472,17,400,1,27,
472,17,404,1,40,
472,17,404,4,0,
472,17,416,4,0,
472,17,422,1,1,2
472,17,423,1,1,3
472,17,424,1,1,4
472,17,444,4,0,
472,17,474,4,0,
472,17,496,4,0,
472,17,512,1,22,
472,17,512,4,0,
472,17,523,4,0,
472,17,590,4,0,
472,17,693,4,0,
472,18,12,1,1,1
472,18,12,1,55,
472,18,14,1,50,
472,18,14,4,0,
472,18,28,1,1,6
472,18,28,1,4,
472,18,63,4,0,
472,18,89,4,0,
472,18,92,4,0,
472,18,98,1,13,
472,18,103,1,35,
472,18,104,4,0,
472,18,106,1,1,7
472,18,106,1,7,
472,18,156,4,0,
472,18,157,4,0,
472,18,164,4,0,
472,18,168,4,0,
472,18,182,4,0,
472,18,185,1,19,
472,18,188,4,0,
472,18,201,4,0,
472,18,206,4,0,
472,18,207,4,0,
472,18,210,1,16,
472,18,211,4,0,
472,18,213,4,0,
472,18,214,4,0,
472,18,216,4,0,
472,18,218,4,0,
472,18,237,4,0,
472,18,240,4,0,
472,18,241,4,0,
472,18,259,4,0,
472,18,263,4,0,
472,18,269,4,0,
472,18,280,4,0,
472,18,282,1,1,8
472,18,282,1,10,
472,18,317,4,0,
472,18,327,1,45,
472,18,332,4,0,
472,18,355,4,0,
472,18,369,1,30,
472,18,369,4,0,
472,18,371,4,0,
472,18,374,4,0,
472,18,397,4,0,
472,18,398,1,1,5
472,18,398,4,0,
472,18,399,4,0,
472,18,400,1,27,
472,18,404,1,40,
472,18,404,4,0,
472,18,416,4,0,
472,18,422,1,1,2
472,18,423,1,1,3
472,18,424,1,1,4
472,18,444,4,0,
472,18,474,4,0,
472,18,496,4,0,
472,18,512,1,22,
472,18,512,4,0,
472,18,523,4,0,
472,18,590,4,0,
472,18,693,4,0,
473,8,36,1,32,
473,8,46,4,0,
473,8,54,1,48,
473,8,58,4,0,
473,8,59,1,56,
473,8,59,4,0,
473,8,63,4,0,
473,8,64,1,1,2
473,8,70,4,0,
473,8,89,1,40,
473,8,89,4,0,
473,8,91,4,0,
473,8,92,4,0,
473,8,104,4,0,
473,8,113,4,0,
473,8,115,4,0,
473,8,156,4,0,
473,8,157,4,0,
473,8,164,4,0,
473,8,181,1,1,5
473,8,181,1,8,
473,8,182,4,0,
473,8,184,1,65,
473,8,189,1,13,
473,8,201,4,0,
473,8,203,1,16,
473,8,203,4,0,
473,8,207,4,0,
473,8,213,4,0,
473,8,214,4,0,
473,8,216,4,0,
473,8,218,4,0,
473,8,237,4,0,
473,8,240,4,0,
473,8,246,1,1,1
473,8,249,4,0,
473,8,258,1,25,
473,8,258,4,0,
473,8,263,4,0,
473,8,290,4,0,
473,8,300,1,1,4
473,8,300,1,4,
473,8,316,1,1,3
473,8,317,4,0,
473,8,363,4,0,
473,8,416,4,0,
473,8,419,4,0,
473,8,423,1,28,
473,8,426,1,20,
473,8,431,4,0,
473,8,444,4,0,
473,8,445,4,0,
473,8,446,4,0,
473,8,458,1,33,
473,9,36,1,32,
473,9,46,4,0,
473,9,54,1,48,
473,9,58,4,0,
473,9,59,1,56,
473,9,59,4,0,
473,9,63,4,0,
473,9,64,1,1,2
473,9,70,4,0,
473,9,89,1,40,
473,9,89,4,0,
473,9,91,4,0,
473,9,92,4,0,
473,9,104,4,0,
473,9,113,4,0,
473,9,115,4,0,
473,9,156,4,0,
473,9,157,4,0,
473,9,164,4,0,
473,9,173,3,0,
473,9,181,1,1,5
473,9,181,1,8,
473,9,182,4,0,
473,9,184,1,65,
473,9,189,1,13,
473,9,189,3,0,
473,9,196,3,0,
473,9,201,4,0,
473,9,203,1,16,
473,9,203,4,0,
473,9,207,4,0,
473,9,210,3,0,
473,9,213,4,0,
473,9,214,4,0,
473,9,216,4,0,
473,9,218,4,0,
473,9,237,4,0,
473,9,240,4,0,
473,9,246,1,1,1
473,9,246,3,0,
473,9,249,4,0,
473,9,258,1,25,
473,9,258,4,0,
473,9,263,4,0,
473,9,276,3,0,
473,9,282,3,0,
473,9,283,3,0,
473,9,290,4,0,
473,9,300,1,1,4
473,9,300,1,4,
473,9,316,1,1,3
473,9,317,4,0,
473,9,363,4,0,
473,9,414,3,0,
473,9,416,4,0,
473,9,419,4,0,
473,9,423,1,28,
473,9,426,1,20,
473,9,431,4,0,
473,9,442,3,0,
473,9,444,4,0,
473,9,445,4,0,
473,9,446,4,0,
473,9,458,1,33,
473,10,29,3,0,
473,10,36,1,32,
473,10,46,4,0,
473,10,54,1,48,
473,10,58,4,0,
473,10,59,1,56,
473,10,59,4,0,
473,10,63,4,0,
473,10,64,1,1,2
473,10,70,4,0,
473,10,89,1,40,
473,10,89,4,0,
473,10,91,4,0,
473,10,92,4,0,
473,10,104,4,0,
473,10,113,4,0,
473,10,115,4,0,
473,10,156,4,0,
473,10,157,4,0,
473,10,164,4,0,
473,10,173,3,0,
473,10,181,1,1,5
473,10,181,1,8,
473,10,182,4,0,
473,10,184,1,65,
473,10,189,1,13,
473,10,189,3,0,
473,10,196,3,0,
473,10,201,4,0,
473,10,203,1,16,
473,10,203,4,0,
473,10,207,4,0,
473,10,210,3,0,
473,10,213,4,0,
473,10,214,4,0,
473,10,216,4,0,
473,10,218,4,0,
473,10,237,4,0,
473,10,240,4,0,
473,10,246,1,1,1
473,10,246,3,0,
473,10,249,4,0,
473,10,258,1,25,
473,10,258,4,0,
473,10,263,4,0,
473,10,276,3,0,
473,10,282,3,0,
473,10,283,3,0,
473,10,290,4,0,
473,10,300,1,1,4
473,10,300,1,4,
473,10,316,1,1,3
473,10,317,4,0,
473,10,335,3,0,
473,10,363,4,0,
473,10,414,3,0,
473,10,416,4,0,
473,10,419,4,0,
473,10,423,1,28,
473,10,426,1,20,
473,10,431,4,0,
473,10,442,3,0,
473,10,444,4,0,
473,10,445,4,0,
473,10,446,4,0,
473,10,458,1,33,
473,11,36,1,32,
473,11,46,4,0,
473,11,54,1,48,
473,11,58,4,0,
473,11,59,1,56,
473,11,59,4,0,
473,11,63,4,0,
473,11,64,1,1,2
473,11,70,4,0,
473,11,89,1,40,
473,11,89,4,0,
473,11,91,4,0,
473,11,92,4,0,
473,11,104,4,0,
473,11,113,4,0,
473,11,115,4,0,
473,11,156,4,0,
473,11,157,4,0,
473,11,164,4,0,
473,11,181,1,1,5
473,11,181,1,8,
473,11,182,4,0,
473,11,184,1,65,
473,11,189,1,13,
473,11,201,4,0,
473,11,203,1,16,
473,11,207,4,0,
473,11,213,4,0,
473,11,216,4,0,
473,11,218,4,0,
473,11,237,4,0,
473,11,240,4,0,
473,11,246,1,1,1
473,11,249,4,0,
473,11,258,1,25,
473,11,258,4,0,
473,11,263,4,0,
473,11,300,1,1,4
473,11,300,1,4,
473,11,316,1,1,3
473,11,317,4,0,
473,11,416,4,0,
473,11,423,1,28,
473,11,426,1,20,
473,11,444,4,0,
473,11,458,1,33,
473,11,496,4,0,
473,11,523,4,0,
473,14,36,1,28,
473,14,37,1,41,
473,14,46,4,0,
473,14,54,1,37,
473,14,58,4,0,
473,14,59,1,52,
473,14,59,4,0,
473,14,63,4,0,
473,14,64,1,1,2
473,14,70,4,0,
473,14,89,1,46,
473,14,89,4,0,
473,14,91,4,0,
473,14,92,4,0,
473,14,104,4,0,
473,14,113,4,0,
473,14,115,4,0,
473,14,156,4,0,
473,14,157,4,0,
473,14,164,4,0,
473,14,173,3,0,
473,14,181,1,1,5
473,14,181,1,8,
473,14,182,4,0,
473,14,184,1,58,
473,14,189,1,11,
473,14,196,3,0,
473,14,201,4,0,
473,14,203,1,14,
473,14,207,4,0,
473,14,213,4,0,
473,14,214,3,0,
473,14,216,4,0,
473,14,218,4,0,
473,14,237,4,0,
473,14,240,4,0,
473,14,246,1,1,1
473,14,249,4,0,
473,14,258,1,21,
473,14,258,4,0,
473,14,263,4,0,
473,14,276,3,0,
473,14,282,3,0,
473,14,283,3,0,
473,14,300,1,1,4
473,14,300,1,5,
473,14,316,1,1,3
473,14,317,4,0,
473,14,335,3,0,
473,14,414,3,0,
473,14,416,4,0,
473,14,423,1,24,
473,14,426,1,18,
473,14,442,3,0,
473,14,444,4,0,
473,14,446,3,0,
473,14,458,1,33,
473,14,496,4,0,
473,14,523,4,0,
473,15,36,1,28,
473,15,37,1,41,
473,15,46,4,0,
473,15,54,1,37,
473,15,58,4,0,
473,15,59,1,52,
473,15,59,4,0,
473,15,63,4,0,
473,15,64,1,1,3
473,15,70,4,0,
473,15,89,1,46,
473,15,89,4,0,
473,15,91,4,0,
473,15,92,4,0,
473,15,104,4,0,
473,15,113,4,0,
473,15,115,4,0,
473,15,156,4,0,
473,15,157,4,0,
473,15,164,4,0,
473,15,181,1,1,6
473,15,181,1,8,
473,15,182,4,0,
473,15,184,1,1,1
473,15,184,1,58,
473,15,189,1,11,
473,15,201,4,0,
473,15,203,1,14,
473,15,207,4,0,
473,15,213,4,0,
473,15,214,4,0,
473,15,216,4,0,
473,15,218,4,0,
473,15,237,4,0,
473,15,240,4,0,
473,15,246,1,1,2
473,15,249,4,0,
473,15,258,1,21,
473,15,258,4,0,
473,15,263,4,0,
473,15,300,1,1,5
473,15,300,1,5,
473,15,316,1,1,4
473,15,317,4,0,
473,15,416,4,0,
473,15,423,1,24,
473,15,426,1,18,
473,15,444,4,0,
473,15,458,1,33,
473,15,496,4,0,
473,15,523,4,0,
473,15,590,4,0,
473,16,36,1,28,1
473,16,37,1,41,1
473,16,46,4,0,
473,16,54,1,37,1
473,16,58,4,0,
473,16,59,1,52,1
473,16,59,4,0,
473,16,63,4,0,
473,16,64,1,1,3
473,16,70,4,0,
473,16,89,1,46,1
473,16,89,4,0,
473,16,91,4,0,
473,16,92,4,0,
473,16,104,4,0,
473,16,113,4,0,
473,16,115,4,0,
473,16,156,4,0,
473,16,157,4,0,
473,16,164,4,0,
473,16,173,3,0,
473,16,181,1,1,6
473,16,181,1,8,1
473,16,182,4,0,
473,16,184,1,1,1
473,16,184,1,58,1
473,16,189,1,11,1
473,16,196,3,0,
473,16,201,4,0,
473,16,203,1,14,1
473,16,207,4,0,
473,16,213,4,0,
473,16,214,4,0,
473,16,216,4,0,
473,16,218,4,0,
473,16,237,4,0,
473,16,240,4,0,
473,16,246,1,1,2
473,16,249,4,0,
473,16,258,1,21,1
473,16,258,4,0,
473,16,263,4,0,
473,16,276,3,0,
473,16,282,3,0,
473,16,283,3,0,
473,16,290,4,0,
473,16,300,1,1,5
473,16,300,1,5,1
473,16,316,1,1,4
473,16,317,4,0,
473,16,335,3,0,
473,16,414,3,0,
473,16,416,4,0,
473,16,423,1,24,1
473,16,426,1,18,1
473,16,442,3,0,
473,16,444,4,0,
473,16,446,3,0,
473,16,458,1,33,1
473,16,496,4,0,
473,16,523,4,0,
473,16,590,4,0,
473,17,31,1,1,1
473,17,36,1,28,
473,17,37,1,41,
473,17,46,4,0,
473,17,54,1,37,
473,17,58,4,0,
473,17,59,1,52,
473,17,59,4,0,
473,17,63,4,0,
473,17,64,1,1,4
473,17,89,1,46,
473,17,89,4,0,
473,17,92,4,0,
473,17,104,4,0,
473,17,113,4,0,
473,17,115,4,0,
473,17,156,4,0,
473,17,157,4,0,
473,17,164,4,0,
473,17,181,1,1,7
473,17,181,1,8,
473,17,182,4,0,
473,17,184,1,1,2
473,17,184,1,58,
473,17,189,1,11,
473,17,201,4,0,
473,17,203,1,14,
473,17,207,4,0,
473,17,213,4,0,
473,17,214,4,0,
473,17,216,4,0,
473,17,218,4,0,
473,17,237,4,0,
473,17,240,4,0,
473,17,246,1,1,3
473,17,258,1,21,
473,17,258,4,0,
473,17,263,4,0,
473,17,300,1,1,6
473,17,300,1,5,
473,17,316,1,1,5
473,17,317,4,0,
473,17,416,4,0,
473,17,423,1,24,
473,17,426,1,18,
473,17,444,4,0,
473,17,458,1,33,
473,17,496,4,0,
473,17,523,4,0,
473,17,590,4,0,
473,18,31,1,1,1
473,18,36,1,28,
473,18,37,1,41,
473,18,46,4,0,
473,18,54,1,37,
473,18,58,4,0,
473,18,59,1,52,
473,18,59,4,0,
473,18,63,4,0,
473,18,64,1,1,4
473,18,89,1,46,
473,18,89,4,0,
473,18,92,4,0,
473,18,104,4,0,
473,18,113,4,0,
473,18,115,4,0,
473,18,156,4,0,
473,18,157,4,0,
473,18,164,4,0,
473,18,181,1,1,7
473,18,181,1,8,
473,18,182,4,0,
473,18,184,1,1,2
473,18,184,1,58,
473,18,189,1,11,
473,18,201,4,0,
473,18,203,1,14,
473,18,207,4,0,
473,18,213,4,0,
473,18,214,4,0,
473,18,216,4,0,
473,18,218,4,0,
473,18,237,4,0,
473,18,240,4,0,
473,18,246,1,1,3
473,18,258,1,21,
473,18,258,4,0,
473,18,263,4,0,
473,18,300,1,1,6
473,18,300,1,5,
473,18,316,1,1,5
473,18,317,4,0,
473,18,416,4,0,
473,18,423,1,24,
473,18,426,1,18,
473,18,444,4,0,
473,18,458,1,33,
473,18,496,4,0,
473,18,523,4,0,
473,18,590,4,0,
474,8,33,1,1,3
474,8,58,4,0,
474,8,59,4,0,
474,8,60,1,7,
474,8,63,1,67,
474,8,63,4,0,
474,8,76,4,0,
474,8,85,4,0,
474,8,86,4,0,
474,8,87,4,0,
474,8,92,4,0,
474,8,94,4,0,
474,8,97,1,12,
474,8,104,4,0,
474,8,105,1,18,
474,8,138,4,0,
474,8,148,4,0,
474,8,156,4,0,
474,8,160,1,1,4
474,8,161,1,51,
474,8,164,4,0,
474,8,168,4,0,
474,8,176,1,1,2
474,8,182,4,0,
474,8,192,1,62,
474,8,199,1,45,
474,8,203,4,0,
474,8,207,4,0,
474,8,214,4,0,
474,8,216,4,0,
474,8,218,4,0,
474,8,231,4,0,
474,8,237,4,0,
474,8,240,4,0,
474,8,241,4,0,
474,8,244,4,0,
474,8,247,4,0,
474,8,263,4,0,
474,8,277,1,56,
474,8,278,4,0,
474,8,290,4,0,
474,8,324,1,29,
474,8,332,4,0,
474,8,351,4,0,
474,8,363,4,0,
474,8,373,1,34,
474,8,373,4,0,
474,8,393,1,23,
474,8,399,4,0,
474,8,416,4,0,
474,8,417,1,1,5
474,8,433,1,1,1
474,8,433,4,0,
474,8,435,1,40,
474,8,451,4,0,
474,9,33,1,1,3
474,9,58,4,0,
474,9,59,4,0,
474,9,60,1,7,
474,9,63,1,67,
474,9,63,4,0,
474,9,76,4,0,
474,9,85,4,0,
474,9,86,4,0,
474,9,87,4,0,
474,9,92,4,0,
474,9,94,4,0,
474,9,97,1,12,
474,9,104,4,0,
474,9,105,1,18,
474,9,129,3,0,
474,9,138,4,0,
474,9,148,4,0,
474,9,156,4,0,
474,9,160,1,1,4
474,9,161,1,51,
474,9,164,4,0,
474,9,168,4,0,
474,9,173,3,0,
474,9,176,1,1,2
474,9,182,4,0,
474,9,192,1,62,
474,9,196,3,0,
474,9,199,1,45,
474,9,203,4,0,
474,9,207,4,0,
474,9,214,4,0,
474,9,216,4,0,
474,9,218,4,0,
474,9,231,4,0,
474,9,237,4,0,
474,9,240,4,0,
474,9,241,4,0,
474,9,244,4,0,
474,9,247,4,0,
474,9,253,3,0,
474,9,263,4,0,
474,9,271,3,0,
474,9,277,1,56,
474,9,278,4,0,
474,9,290,4,0,
474,9,324,1,29,
474,9,324,3,0,
474,9,332,4,0,
474,9,351,4,0,
474,9,363,4,0,
474,9,373,1,34,
474,9,373,4,0,
474,9,387,3,0,
474,9,393,1,23,
474,9,399,4,0,
474,9,416,4,0,
474,9,417,1,1,5
474,9,428,3,0,
474,9,433,1,1,1
474,9,433,4,0,
474,9,435,1,40,
474,9,451,4,0,
474,10,33,1,1,3
474,10,58,4,0,
474,10,59,4,0,
474,10,60,1,7,
474,10,63,1,67,
474,10,63,4,0,
474,10,76,4,0,
474,10,85,4,0,
474,10,86,4,0,
474,10,87,4,0,
474,10,92,4,0,
474,10,94,4,0,
474,10,97,1,12,
474,10,104,4,0,
474,10,105,1,18,
474,10,129,3,0,
474,10,138,4,0,
474,10,148,4,0,
474,10,156,4,0,
474,10,160,1,1,4
474,10,161,1,51,
474,10,164,4,0,
474,10,168,4,0,
474,10,173,3,0,
474,10,176,1,1,2
474,10,182,4,0,
474,10,192,1,62,
474,10,196,3,0,
474,10,199,1,45,
474,10,203,4,0,
474,10,207,4,0,
474,10,214,4,0,
474,10,216,4,0,
474,10,218,4,0,
474,10,220,3,0,
474,10,231,4,0,
474,10,237,4,0,
474,10,240,4,0,
474,10,241,4,0,
474,10,244,4,0,
474,10,247,4,0,
474,10,253,3,0,
474,10,263,4,0,
474,10,271,3,0,
474,10,277,1,56,
474,10,277,3,0,
474,10,278,4,0,
474,10,290,4,0,
474,10,324,1,29,
474,10,324,3,0,
474,10,332,4,0,
474,10,351,4,0,
474,10,356,3,0,
474,10,363,4,0,
474,10,373,1,34,
474,10,373,4,0,
474,10,387,3,0,
474,10,393,1,23,
474,10,399,4,0,
474,10,416,4,0,
474,10,417,1,1,5
474,10,428,3,0,
474,10,433,1,1,1
474,10,433,4,0,
474,10,435,1,40,
474,10,451,4,0,
474,11,33,1,1,3
474,11,58,4,0,
474,11,59,4,0,
474,11,60,1,7,
474,11,63,1,67,
474,11,63,4,0,
474,11,76,4,0,
474,11,85,4,0,
474,11,86,4,0,
474,11,87,4,0,
474,11,92,4,0,
474,11,94,4,0,
474,11,97,1,12,
474,11,104,4,0,
474,11,105,1,18,
474,11,138,4,0,
474,11,148,4,0,
474,11,156,4,0,
474,11,160,1,1,4
474,11,161,1,51,
474,11,164,4,0,
474,11,168,4,0,
474,11,176,1,1,2
474,11,182,4,0,
474,11,192,1,62,
474,11,199,1,45,
474,11,207,4,0,
474,11,216,4,0,
474,11,218,4,0,
474,11,237,4,0,
474,11,240,4,0,
474,11,241,4,0,
474,11,244,4,0,
474,11,247,4,0,
474,11,263,4,0,
474,11,277,1,56,
474,11,324,1,29,
474,11,332,4,0,
474,11,373,1,34,
474,11,373,4,0,
474,11,393,1,23,
474,11,416,4,0,
474,11,417,1,1,5
474,11,433,1,1,1
474,11,433,4,0,
474,11,435,1,40,
474,11,451,4,0,
474,11,473,4,0,
474,11,496,4,0,
474,14,33,1,1,3
474,14,58,4,0,
474,14,59,4,0,
474,14,60,1,7,
474,14,63,1,67,
474,14,63,4,0,
474,14,76,4,0,
474,14,85,4,0,
474,14,86,4,0,
474,14,87,4,0,
474,14,92,4,0,
474,14,94,4,0,
474,14,97,1,12,
474,14,104,4,0,
474,14,105,1,18,
474,14,138,4,0,
474,14,148,4,0,
474,14,156,4,0,
474,14,160,1,1,4
474,14,161,1,51,
474,14,164,4,0,
474,14,168,4,0,
474,14,173,3,0,
474,14,176,1,1,2
474,14,182,4,0,
474,14,192,1,62,
474,14,196,3,0,
474,14,199,1,45,
474,14,207,4,0,
474,14,214,3,0,
474,14,216,4,0,
474,14,218,4,0,
474,14,220,3,0,
474,14,231,3,0,
474,14,237,4,0,
474,14,240,4,0,
474,14,241,4,0,
474,14,244,4,0,
474,14,247,4,0,
474,14,253,3,0,
474,14,263,4,0,
474,14,271,3,0,
474,14,277,1,56,
474,14,277,3,0,
474,14,278,3,0,
474,14,324,1,29,
474,14,324,3,0,
474,14,332,4,0,
474,14,356,3,0,
474,14,373,1,34,
474,14,373,4,0,
474,14,387,3,0,
474,14,393,1,23,
474,14,393,3,0,
474,14,399,3,0,
474,14,416,4,0,
474,14,417,1,1,5
474,14,428,3,0,
474,14,433,1,1,1
474,14,433,4,0,
474,14,435,1,40,
474,14,451,4,0,
474,14,472,3,0,
474,14,473,4,0,
474,14,492,3,0,
474,14,496,4,0,
474,14,527,3,0,
474,15,33,1,1,5
474,15,58,4,0,
474,15,59,4,0,
474,15,60,1,7,
474,15,63,1,67,
474,15,63,4,0,
474,15,76,4,0,
474,15,85,4,0,
474,15,86,4,0,
474,15,87,4,0,
474,15,92,4,0,
474,15,94,4,0,
474,15,97,1,12,
474,15,104,4,0,
474,15,105,1,18,
474,15,138,4,0,
474,15,148,4,0,
474,15,156,4,0,
474,15,160,1,1,6
474,15,161,1,50,
474,15,164,4,0,
474,15,168,4,0,
474,15,176,1,1,4
474,15,182,4,0,
474,15,192,1,1,2
474,15,192,1,62,
474,15,199,1,45,
474,15,207,4,0,
474,15,214,4,0,
474,15,216,4,0,
474,15,218,4,0,
474,15,237,4,0,
474,15,240,4,0,
474,15,241,4,0,
474,15,244,4,0,
474,15,247,4,0,
474,15,263,4,0,
474,15,277,1,1,3
474,15,277,1,56,
474,15,324,1,29,
474,15,332,4,0,
474,15,373,1,34,
474,15,373,4,0,
474,15,393,1,23,
474,15,399,4,0,
474,15,416,4,0,
474,15,417,1,1,7
474,15,433,1,1,1
474,15,433,4,0,
474,15,435,1,40,
474,15,451,4,0,
474,15,473,4,0,
474,15,496,4,0,
474,15,590,4,0,
474,16,33,1,1,5
474,16,58,4,0,
474,16,59,4,0,
474,16,60,1,7,1
474,16,63,1,67,1
474,16,63,4,0,
474,16,76,4,0,
474,16,85,4,0,
474,16,86,4,0,
474,16,87,4,0,
474,16,92,4,0,
474,16,94,4,0,
474,16,97,1,12,1
474,16,104,4,0,
474,16,105,1,18,1
474,16,138,4,0,
474,16,148,4,0,
474,16,156,4,0,
474,16,160,1,1,6
474,16,161,1,50,1
474,16,164,4,0,
474,16,168,4,0,
474,16,173,3,0,
474,16,176,1,1,4
474,16,182,4,0,
474,16,192,1,1,2
474,16,192,1,62,1
474,16,196,3,0,
474,16,199,1,45,1
474,16,207,4,0,
474,16,214,4,0,
474,16,216,4,0,
474,16,218,4,0,
474,16,220,3,0,
474,16,231,3,0,
474,16,237,4,0,
474,16,240,4,0,
474,16,241,4,0,
474,16,244,4,0,
474,16,247,4,0,
474,16,253,3,0,
474,16,263,4,0,
474,16,271,3,0,
474,16,277,1,1,3
474,16,277,1,56,1
474,16,277,3,0,
474,16,278,3,0,
474,16,290,4,0,
474,16,324,1,29,1
474,16,324,3,0,
474,16,332,4,0,
474,16,351,3,0,
474,16,356,3,0,
474,16,373,1,34,1
474,16,373,4,0,
474,16,387,3,0,
474,16,393,1,23,1
474,16,393,3,0,
474,16,399,4,0,
474,16,416,4,0,
474,16,417,1,1,7
474,16,428,3,0,
474,16,433,1,1,1
474,16,433,4,0,
474,16,435,1,40,1
474,16,451,4,0,
474,16,473,4,0,
474,16,492,3,0,
474,16,496,4,0,
474,16,527,3,0,
474,16,590,4,0,
474,17,33,1,1,5
474,17,58,4,0,
474,17,59,4,0,
474,17,60,1,7,
474,17,63,1,67,
474,17,63,4,0,
474,17,76,4,0,
474,17,85,4,0,
474,17,86,4,0,
474,17,87,4,0,
474,17,92,4,0,
474,17,94,4,0,
474,17,97,1,12,
474,17,104,4,0,
474,17,105,1,18,
474,17,138,4,0,
474,17,156,4,0,
474,17,160,1,1,6
474,17,161,1,50,
474,17,164,4,0,
474,17,168,4,0,
474,17,176,1,1,4
474,17,182,4,0,
474,17,192,1,1,2
474,17,192,1,62,
474,17,199,1,45,
474,17,207,4,0,
474,17,214,4,0,
474,17,216,4,0,
474,17,218,4,0,
474,17,237,4,0,
474,17,240,4,0,
474,17,241,4,0,
474,17,244,4,0,
474,17,247,4,0,
474,17,263,4,0,
474,17,277,1,1,3
474,17,277,1,56,
474,17,324,1,29,
474,17,332,4,0,
474,17,373,1,34,
474,17,373,4,0,
474,17,393,1,23,
474,17,399,4,0,
474,17,416,4,0,
474,17,417,1,1,7
474,17,433,1,1,1
474,17,433,4,0,
474,17,435,1,40,
474,17,451,4,0,
474,17,473,4,0,
474,17,496,4,0,
474,17,590,4,0,
474,18,33,1,1,5
474,18,58,4,0,
474,18,59,4,0,
474,18,60,1,7,
474,18,63,1,67,
474,18,63,4,0,
474,18,76,4,0,
474,18,85,4,0,
474,18,86,4,0,
474,18,87,4,0,
474,18,92,4,0,
474,18,94,4,0,
474,18,97,1,12,
474,18,104,4,0,
474,18,105,1,18,
474,18,138,4,0,
474,18,156,4,0,
474,18,160,1,1,6
474,18,161,1,50,
474,18,164,4,0,
474,18,168,4,0,
474,18,176,1,1,4
474,18,182,4,0,
474,18,192,1,1,2
474,18,192,1,62,
474,18,199,1,45,
474,18,207,4,0,
474,18,214,4,0,
474,18,216,4,0,
474,18,218,4,0,
474,18,237,4,0,
474,18,240,4,0,
474,18,241,4,0,
474,18,244,4,0,
474,18,247,4,0,
474,18,263,4,0,
474,18,277,1,1,3
474,18,277,1,56,
474,18,324,1,29,
474,18,332,4,0,
474,18,373,1,34,
474,18,373,4,0,
474,18,393,1,23,
474,18,399,4,0,
474,18,416,4,0,
474,18,417,1,1,7
474,18,433,1,1,1
474,18,433,4,0,
474,18,435,1,40,
474,18,451,4,0,
474,18,473,4,0,
474,18,496,4,0,
474,18,590,4,0,
475,8,14,1,25,
475,8,14,4,0,
475,8,15,4,0,
475,8,43,1,1,3
475,8,63,4,0,
475,8,70,4,0,
475,8,85,4,0,
475,8,86,4,0,
475,8,89,4,0,
475,8,92,4,0,
475,8,93,1,1,4
475,8,93,1,6,
475,8,94,4,0,
475,8,100,1,1,6
475,8,100,1,12,
475,8,104,1,1,5
475,8,104,1,10,
475,8,104,4,0,
475,8,113,4,0,
475,8,115,4,0,
475,8,138,4,0,
475,8,148,4,0,
475,8,156,4,0,
475,8,157,4,0,
475,8,163,1,22,
475,8,164,4,0,
475,8,168,4,0,
475,8,182,1,50,
475,8,182,4,0,
475,8,203,4,0,
475,8,206,1,45,
475,8,206,4,0,
475,8,207,4,0,
475,8,210,1,17,
475,8,213,4,0,
475,8,214,4,0,
475,8,216,4,0,
475,8,218,4,0,
475,8,219,4,0,
475,8,237,4,0,
475,8,240,4,0,
475,8,241,4,0,
475,8,244,4,0,
475,8,247,4,0,
475,8,249,4,0,
475,8,259,4,0,
475,8,263,4,0,
475,8,264,4,0,
475,8,269,4,0,
475,8,270,1,36,
475,8,278,4,0,
475,8,280,4,0,
475,8,285,4,0,
475,8,289,4,0,
475,8,290,4,0,
475,8,317,4,0,
475,8,332,4,0,
475,8,339,4,0,
475,8,347,4,0,
475,8,348,1,1,1
475,8,351,4,0,
475,8,363,4,0,
475,8,364,1,39,
475,8,370,1,53,
475,8,374,4,0,
475,8,398,4,0,
475,8,400,1,1,2
475,8,404,4,0,
475,8,409,4,0,
475,8,411,4,0,
475,8,416,4,0,
475,8,427,1,31,
475,8,433,4,0,
475,8,444,4,0,
475,8,445,4,0,
475,8,447,4,0,
475,8,451,4,0,
475,9,7,3,0,
475,9,8,3,0,
475,9,9,3,0,
475,9,14,1,25,
475,9,14,4,0,
475,9,15,4,0,
475,9,43,1,1,3
475,9,63,4,0,
475,9,70,4,0,
475,9,85,4,0,
475,9,86,4,0,
475,9,89,4,0,
475,9,92,4,0,
475,9,93,1,1,4
475,9,93,1,6,
475,9,94,4,0,
475,9,100,1,1,6
475,9,100,1,12,
475,9,104,1,1,5
475,9,104,1,10,
475,9,104,4,0,
475,9,113,4,0,
475,9,115,4,0,
475,9,129,3,0,
475,9,138,4,0,
475,9,148,4,0,
475,9,156,4,0,
475,9,157,4,0,
475,9,163,1,22,
475,9,164,4,0,
475,9,168,4,0,
475,9,173,3,0,
475,9,182,1,50,
475,9,182,4,0,
475,9,189,3,0,
475,9,203,4,0,
475,9,206,1,45,
475,9,206,4,0,
475,9,207,4,0,
475,9,210,1,17,
475,9,210,3,0,
475,9,213,4,0,
475,9,214,4,0,
475,9,216,4,0,
475,9,218,4,0,
475,9,219,4,0,
475,9,237,4,0,
475,9,240,4,0,
475,9,241,4,0,
475,9,244,4,0,
475,9,247,4,0,
475,9,249,4,0,
475,9,259,4,0,
475,9,263,4,0,
475,9,264,4,0,
475,9,269,4,0,
475,9,270,1,36,
475,9,270,3,0,
475,9,271,3,0,
475,9,278,4,0,
475,9,280,4,0,
475,9,282,3,0,
475,9,285,4,0,
475,9,289,4,0,
475,9,290,4,0,
475,9,317,4,0,
475,9,324,3,0,
475,9,332,4,0,
475,9,339,4,0,
475,9,347,4,0,
475,9,348,1,1,1
475,9,351,4,0,
475,9,363,4,0,
475,9,364,1,39,
475,9,370,1,53,
475,9,374,4,0,
475,9,398,4,0,
475,9,400,1,1,2
475,9,404,4,0,
475,9,409,4,0,
475,9,410,3,0,
475,9,411,4,0,
475,9,416,4,0,
475,9,427,1,31,
475,9,428,3,0,
475,9,433,4,0,
475,9,444,4,0,
475,9,445,4,0,
475,9,447,4,0,
475,9,451,4,0,
475,10,7,3,0,
475,10,8,3,0,
475,10,9,3,0,
475,10,14,1,25,
475,10,14,4,0,
475,10,15,4,0,
475,10,29,3,0,
475,10,43,1,1,3
475,10,63,4,0,
475,10,67,3,0,
475,10,70,4,0,
475,10,85,4,0,
475,10,86,4,0,
475,10,89,4,0,
475,10,92,4,0,
475,10,93,1,1,4
475,10,93,1,6,
475,10,94,4,0,
475,10,100,1,1,6
475,10,100,1,12,
475,10,104,1,1,5
475,10,104,1,10,
475,10,104,4,0,
475,10,113,4,0,
475,10,115,4,0,
475,10,129,3,0,
475,10,138,4,0,
475,10,148,4,0,
475,10,156,4,0,
475,10,157,4,0,
475,10,163,1,22,
475,10,164,4,0,
475,10,168,4,0,
475,10,173,3,0,
475,10,182,1,50,
475,10,182,4,0,
475,10,189,3,0,
475,10,203,4,0,
475,10,206,1,45,
475,10,206,4,0,
475,10,207,4,0,
475,10,210,1,17,
475,10,210,3,0,
475,10,213,4,0,
475,10,214,4,0,
475,10,216,4,0,
475,10,218,4,0,
475,10,219,4,0,
475,10,220,3,0,
475,10,237,4,0,
475,10,240,4,0,
475,10,241,4,0,
475,10,244,4,0,
475,10,247,4,0,
475,10,249,4,0,
475,10,259,4,0,
475,10,263,4,0,
475,10,264,4,0,
475,10,269,4,0,
475,10,270,1,36,
475,10,270,3,0,
475,10,271,3,0,
475,10,277,3,0,
475,10,278,4,0,
475,10,280,4,0,
475,10,282,3,0,
475,10,285,4,0,
475,10,289,4,0,
475,10,290,4,0,
475,10,317,4,0,
475,10,324,3,0,
475,10,332,4,0,
475,10,339,4,0,
475,10,347,4,0,
475,10,348,1,1,1
475,10,351,4,0,
475,10,363,4,0,
475,10,364,1,39,
475,10,370,1,53,
475,10,374,4,0,
475,10,398,4,0,
475,10,400,1,1,2
475,10,404,4,0,
475,10,409,4,0,
475,10,410,3,0,
475,10,411,4,0,
475,10,416,4,0,
475,10,427,1,31,
475,10,428,3,0,
475,10,433,4,0,
475,10,444,4,0,
475,10,445,4,0,
475,10,447,4,0,
475,10,451,4,0,
475,11,14,1,31,
475,11,14,4,0,
475,11,15,4,0,
475,11,43,1,1,3
475,11,63,4,0,
475,11,70,4,0,
475,11,85,4,0,
475,11,86,4,0,
475,11,89,4,0,
475,11,92,4,0,
475,11,93,1,1,4
475,11,93,1,6,
475,11,94,4,0,
475,11,100,1,1,6
475,11,100,1,12,
475,11,104,1,1,5
475,11,104,1,10,
475,11,104,4,0,
475,11,113,4,0,
475,11,115,4,0,
475,11,138,4,0,
475,11,148,4,0,
475,11,156,4,0,
475,11,157,4,0,
475,11,163,1,22,
475,11,164,4,0,
475,11,168,4,0,
475,11,182,1,53,
475,11,182,4,0,
475,11,206,1,50,
475,11,206,4,0,
475,11,207,4,0,
475,11,210,1,17,
475,11,213,4,0,
475,11,216,4,0,
475,11,218,4,0,
475,11,219,4,0,
475,11,237,4,0,
475,11,240,4,0,
475,11,241,4,0,
475,11,244,4,0,
475,11,247,4,0,
475,11,249,4,0,
475,11,259,4,0,
475,11,261,4,0,
475,11,263,4,0,
475,11,269,4,0,
475,11,270,1,39,
475,11,280,4,0,
475,11,317,4,0,
475,11,332,4,0,
475,11,339,4,0,
475,11,347,4,0,
475,11,348,1,1,1
475,11,364,1,45,
475,11,370,1,59,
475,11,374,4,0,
475,11,398,4,0,
475,11,400,1,1,2
475,11,404,4,0,
475,11,411,4,0,
475,11,416,4,0,
475,11,427,1,36,
475,11,433,4,0,
475,11,444,4,0,
475,11,447,4,0,
475,11,451,4,0,
475,11,473,4,0,
475,11,477,4,0,
475,11,490,4,0,
475,11,496,4,0,
475,11,497,4,0,
475,11,500,1,64,
475,11,502,4,0,
475,11,505,1,25,
475,11,514,4,0,
475,11,523,4,0,
475,11,526,4,0,
475,14,7,3,0,
475,14,8,3,0,
475,14,9,3,0,
475,14,14,1,31,
475,14,14,4,0,
475,14,15,4,0,
475,14,43,1,1,3
475,14,63,4,0,
475,14,67,3,0,
475,14,70,4,0,
475,14,85,4,0,
475,14,86,4,0,
475,14,89,4,0,
475,14,92,4,0,
475,14,93,1,1,4
475,14,93,1,6,
475,14,94,4,0,
475,14,100,1,1,6
475,14,100,1,12,
475,14,104,1,1,5
475,14,104,1,10,
475,14,104,4,0,
475,14,113,4,0,
475,14,115,4,0,
475,14,138,4,0,
475,14,148,4,0,
475,14,156,4,0,
475,14,157,4,0,
475,14,163,1,22,
475,14,164,4,0,
475,14,168,4,0,
475,14,173,3,0,
475,14,182,1,53,
475,14,182,4,0,
475,14,206,1,50,
475,14,206,4,0,
475,14,207,4,0,
475,14,210,1,17,
475,14,213,4,0,
475,14,214,3,0,
475,14,216,4,0,
475,14,218,4,0,
475,14,219,4,0,
475,14,220,3,0,
475,14,237,4,0,
475,14,240,4,0,
475,14,241,4,0,
475,14,244,4,0,
475,14,247,4,0,
475,14,249,4,0,
475,14,259,4,0,
475,14,261,4,0,
475,14,263,4,0,
475,14,269,4,0,
475,14,270,1,39,
475,14,270,3,0,
475,14,271,3,0,
475,14,277,3,0,
475,14,278,3,0,
475,14,280,4,0,
475,14,282,3,0,
475,14,285,3,0,
475,14,289,3,0,
475,14,304,3,0,
475,14,317,4,0,
475,14,324,3,0,
475,14,332,4,0,
475,14,339,4,0,
475,14,347,4,0,
475,14,348,1,1,1
475,14,364,1,45,
475,14,370,1,59,
475,14,374,4,0,
475,14,398,4,0,
475,14,400,1,1,2
475,14,404,4,0,
475,14,409,3,0,
475,14,411,4,0,
475,14,416,4,0,
475,14,427,1,36,
475,14,428,3,0,
475,14,433,4,0,
475,14,444,4,0,
475,14,447,4,0,
475,14,451,4,0,
475,14,472,3,0,
475,14,473,4,0,
475,14,477,4,0,
475,14,478,3,0,
475,14,490,4,0,
475,14,496,4,0,
475,14,497,4,0,
475,14,500,1,64,
475,14,502,4,0,
475,14,505,1,25,
475,14,514,4,0,
475,14,523,4,0,
475,14,526,4,0,
475,14,530,3,0,
475,15,14,1,31,
475,15,14,4,0,
475,15,15,4,0,
475,15,43,1,1,5
475,15,63,4,0,
475,15,70,4,0,
475,15,85,4,0,
475,15,86,4,0,
475,15,89,4,0,
475,15,92,4,0,
475,15,93,1,1,6
475,15,93,1,6,
475,15,94,4,0,
475,15,100,1,1,8
475,15,100,1,12,
475,15,104,1,1,7
475,15,104,1,10,
475,15,104,4,0,
475,15,113,4,0,
475,15,115,4,0,
475,15,138,4,0,
475,15,148,4,0,
475,15,156,4,0,
475,15,157,4,0,
475,15,163,1,22,
475,15,164,4,0,
475,15,168,4,0,
475,15,182,1,53,
475,15,182,4,0,
475,15,206,1,50,
475,15,206,4,0,
475,15,207,4,0,
475,15,210,1,17,
475,15,213,4,0,
475,15,214,4,0,
475,15,216,4,0,
475,15,218,4,0,
475,15,219,4,0,
475,15,237,4,0,
475,15,240,4,0,
475,15,241,4,0,
475,15,244,4,0,
475,15,247,4,0,
475,15,249,4,0,
475,15,259,4,0,
475,15,261,4,0,
475,15,263,4,0,
475,15,269,4,0,
475,15,270,1,39,
475,15,280,4,0,
475,15,317,4,0,
475,15,332,4,0,
475,15,339,4,0,
475,15,347,4,0,
475,15,348,1,1,3
475,15,364,1,45,
475,15,370,1,1,2
475,15,370,1,59,
475,15,374,4,0,
475,15,398,4,0,
475,15,400,1,1,4
475,15,404,4,0,
475,15,411,4,0,
475,15,416,4,0,
475,15,427,1,36,
475,15,433,4,0,
475,15,444,4,0,
475,15,447,4,0,
475,15,451,4,0,
475,15,473,4,0,
475,15,490,4,0,
475,15,496,4,0,
475,15,497,4,0,
475,15,500,1,1,1
475,15,500,1,64,
475,15,505,1,25,
475,15,514,4,0,
475,15,523,4,0,
475,15,590,4,0,
475,15,605,4,0,
475,15,612,4,0,
475,16,7,3,0,
475,16,8,3,0,
475,16,9,3,0,
475,16,14,1,26,1
475,16,14,4,0,
475,16,15,4,0,
475,16,43,1,1,5
475,16,63,4,0,
475,16,67,3,0,
475,16,70,4,0,
475,16,85,4,0,
475,16,86,4,0,
475,16,89,4,0,
475,16,92,4,0,
475,16,93,1,1,6
475,16,93,1,4,1
475,16,94,4,0,
475,16,100,1,1,8
475,16,100,1,9,1
475,16,104,1,1,7
475,16,104,1,6,1
475,16,104,4,0,
475,16,113,4,0,
475,16,115,4,0,
475,16,138,4,0,
475,16,148,4,0,
475,16,156,4,0,
475,16,157,4,0,
475,16,163,1,17,1
475,16,164,4,0,
475,16,168,4,0,
475,16,173,3,0,
475,16,182,1,49,1
475,16,182,4,0,
475,16,206,1,44,1
475,16,206,4,0,
475,16,207,4,0,
475,16,210,1,14,1
475,16,213,4,0,
475,16,214,4,0,
475,16,216,4,0,
475,16,218,4,0,
475,16,219,4,0,
475,16,220,3,0,
475,16,237,4,0,
475,16,240,4,0,
475,16,241,4,0,
475,16,244,4,0,
475,16,247,4,0,
475,16,249,4,0,
475,16,259,4,0,
475,16,261,4,0,
475,16,263,4,0,
475,16,264,3,0,
475,16,269,4,0,
475,16,270,1,35,1
475,16,270,3,0,
475,16,271,3,0,
475,16,277,3,0,
475,16,278,3,0,
475,16,280,4,0,
475,16,282,3,0,
475,16,285,3,0,
475,16,289,3,0,
475,16,290,4,0,
475,16,304,3,0,
475,16,317,4,0,
475,16,324,3,0,
475,16,332,4,0,
475,16,339,4,0,
475,16,347,4,0,
475,16,348,1,1,3
475,16,351,3,0,
475,16,364,1,40,1
475,16,370,1,1,2
475,16,370,1,53,1
475,16,374,4,0,
475,16,398,4,0,
475,16,400,1,1,4
475,16,404,4,0,
475,16,409,3,0,
475,16,411,4,0,
475,16,416,4,0,
475,16,427,1,31,1
475,16,428,3,0,
475,16,433,4,0,
475,16,444,4,0,
475,16,447,4,0,
475,16,451,4,0,
475,16,469,1,23,1
475,16,472,3,0,
475,16,473,4,0,
475,16,478,3,0,
475,16,490,4,0,
475,16,496,4,0,
475,16,497,4,0,
475,16,500,1,1,1
475,16,500,1,58,1
475,16,501,1,11,1
475,16,505,1,19,1
475,16,514,4,0,
475,16,523,4,0,
475,16,530,3,0,
475,16,590,4,0,
475,16,605,4,0,
475,16,612,4,0,
475,17,14,1,26,
475,17,14,4,0,
475,17,43,1,1,6
475,17,63,4,0,
475,17,85,4,0,
475,17,86,4,0,
475,17,89,4,0,
475,17,92,4,0,
475,17,93,1,1,7
475,17,93,1,4,
475,17,94,4,0,
475,17,100,1,1,9
475,17,100,1,9,
475,17,104,1,1,8
475,17,104,1,6,
475,17,104,4,0,
475,17,113,4,0,
475,17,115,4,0,
475,17,138,4,0,
475,17,156,4,0,
475,17,157,4,0,
475,17,163,1,0,
475,17,163,1,1,1
475,17,164,4,0,
475,17,168,4,0,
475,17,182,1,49,
475,17,182,4,0,
475,17,206,1,44,
475,17,206,4,0,
475,17,207,4,0,
475,17,210,1,14,
475,17,213,4,0,
475,17,214,4,0,
475,17,216,4,0,
475,17,218,4,0,
475,17,219,4,0,
475,17,237,4,0,
475,17,240,4,0,
475,17,241,4,0,
475,17,244,4,0,
475,17,247,4,0,
475,17,259,4,0,
475,17,261,4,0,
475,17,263,4,0,
475,17,269,4,0,
475,17,270,1,35,
475,17,280,4,0,
475,17,317,4,0,
475,17,332,1,17,
475,17,332,4,0,
475,17,339,4,0,
475,17,347,4,0,
475,17,348,1,1,4
475,17,364,1,40,
475,17,370,1,1,3
475,17,370,1,53,
475,17,374,4,0,
475,17,398,4,0,
475,17,400,1,1,5
475,17,404,4,0,
475,17,411,4,0,
475,17,416,4,0,
475,17,427,1,31,
475,17,433,4,0,
475,17,444,4,0,
475,17,447,4,0,
475,17,451,4,0,
475,17,469,1,23,
475,17,473,4,0,
475,17,490,4,0,
475,17,496,4,0,
475,17,497,4,0,
475,17,500,1,1,2
475,17,500,1,58,
475,17,501,1,11,
475,17,505,1,19,
475,17,523,4,0,
475,17,526,4,0,
475,17,590,4,0,
475,17,605,4,0,
475,18,14,1,26,
475,18,14,4,0,
475,18,43,1,1,6
475,18,63,4,0,
475,18,85,4,0,
475,18,86,4,0,
475,18,89,4,0,
475,18,92,4,0,
475,18,93,1,1,7
475,18,93,1,4,
475,18,94,4,0,
475,18,100,1,1,9
475,18,100,1,9,
475,18,104,1,1,8
475,18,104,1,6,
475,18,104,4,0,
475,18,113,4,0,
475,18,115,4,0,
475,18,138,4,0,
475,18,156,4,0,
475,18,157,4,0,
475,18,163,1,0,
475,18,163,1,1,1
475,18,164,4,0,
475,18,168,4,0,
475,18,182,1,49,
475,18,182,4,0,
475,18,206,1,44,
475,18,206,4,0,
475,18,207,4,0,
475,18,210,1,14,
475,18,213,4,0,
475,18,214,4,0,
475,18,216,4,0,
475,18,218,4,0,
475,18,219,4,0,
475,18,237,4,0,
475,18,240,4,0,
475,18,241,4,0,
475,18,244,4,0,
475,18,247,4,0,
475,18,259,4,0,
475,18,261,4,0,
475,18,263,4,0,
475,18,269,4,0,
475,18,270,1,35,
475,18,280,4,0,
475,18,317,4,0,
475,18,332,1,17,
475,18,332,4,0,
475,18,339,4,0,
475,18,347,4,0,
475,18,348,1,1,4
475,18,364,1,40,
475,18,370,1,1,3
475,18,370,1,53,
475,18,374,4,0,
475,18,398,4,0,
475,18,400,1,1,5
475,18,404,4,0,
475,18,411,4,0,
475,18,416,4,0,
475,18,427,1,31,
475,18,433,4,0,
475,18,444,4,0,
475,18,447,4,0,
475,18,451,4,0,
475,18,469,1,23,
475,18,473,4,0,
475,18,490,4,0,
475,18,496,4,0,
475,18,497,4,0,
475,18,500,1,1,2
475,18,500,1,58,
475,18,501,1,11,
475,18,505,1,19,
475,18,523,4,0,
475,18,526,4,0,
475,18,590,4,0,
475,18,605,4,0,
476,8,33,1,1,3
476,8,63,4,0,
476,8,70,4,0,
476,8,85,4,0,
476,8,86,1,25,
476,8,86,4,0,
476,8,87,4,0,
476,8,89,4,0,
476,8,92,4,0,
476,8,104,4,0,
476,8,153,4,0,
476,8,156,1,43,
476,8,156,4,0,
476,8,157,1,31,
476,8,157,4,0,
476,8,164,4,0,
476,8,182,4,0,
476,8,192,1,61,
476,8,199,1,67,
476,8,201,1,37,
476,8,201,4,0,
476,8,203,4,0,
476,8,207,4,0,
476,8,213,4,0,
476,8,214,4,0,
476,8,216,4,0,
476,8,218,4,0,
476,8,237,4,0,
476,8,241,4,0,
476,8,249,4,0,
476,8,259,4,0,
476,8,263,4,0,
476,8,269,4,0,
476,8,290,4,0,
476,8,317,4,0,
476,8,334,1,1,4
476,8,334,1,7,
476,8,335,1,1,6
476,8,335,1,19,
476,8,351,4,0,
476,8,356,1,1,2
476,8,363,4,0,
476,8,393,1,1,1
476,8,397,4,0,
476,8,414,1,73,
476,8,416,4,0,
476,8,430,4,0,
476,8,435,1,49,
476,8,443,1,1,5
476,8,443,1,13,
476,8,444,1,55,
476,8,444,4,0,
476,8,445,4,0,
476,8,446,4,0,
476,9,7,3,0,
476,9,8,3,0,
476,9,9,3,0,
476,9,33,1,1,3
476,9,63,4,0,
476,9,70,4,0,
476,9,85,4,0,
476,9,86,1,25,
476,9,86,4,0,
476,9,87,4,0,
476,9,89,4,0,
476,9,92,4,0,
476,9,104,4,0,
476,9,153,4,0,
476,9,156,1,43,
476,9,156,4,0,
476,9,157,1,31,
476,9,157,4,0,
476,9,164,4,0,
476,9,173,3,0,
476,9,182,4,0,
476,9,189,3,0,
476,9,192,1,67,
476,9,199,1,73,
476,9,201,1,37,
476,9,201,4,0,
476,9,203,4,0,
476,9,205,3,0,
476,9,207,4,0,
476,9,213,4,0,
476,9,214,4,0,
476,9,216,4,0,
476,9,218,4,0,
476,9,237,4,0,
476,9,241,4,0,
476,9,246,3,0,
476,9,249,4,0,
476,9,259,4,0,
476,9,263,4,0,
476,9,269,4,0,
476,9,290,4,0,
476,9,317,4,0,
476,9,334,1,1,4
476,9,334,1,7,
476,9,334,3,0,
476,9,335,1,1,6
476,9,335,1,19,
476,9,351,4,0,
476,9,356,1,1,2
476,9,363,4,0,
476,9,393,1,1,1
476,9,393,3,0,
476,9,397,4,0,
476,9,408,1,49,
476,9,414,1,79,
476,9,414,3,0,
476,9,416,4,0,
476,9,430,4,0,
476,9,435,1,55,
476,9,442,3,0,
476,9,443,1,1,5
476,9,443,1,13,
476,9,444,1,61,
476,9,444,4,0,
476,9,445,4,0,
476,9,446,4,0,
476,10,7,3,0,
476,10,8,3,0,
476,10,9,3,0,
476,10,29,3,0,
476,10,33,1,1,3
476,10,63,4,0,
476,10,70,4,0,
476,10,85,4,0,
476,10,86,1,25,
476,10,86,4,0,
476,10,87,4,0,
476,10,89,4,0,
476,10,92,4,0,
476,10,104,4,0,
476,10,153,4,0,
476,10,156,1,43,
476,10,156,4,0,
476,10,157,1,31,
476,10,157,4,0,
476,10,164,4,0,
476,10,173,3,0,
476,10,182,4,0,
476,10,189,3,0,
476,10,192,1,67,
476,10,199,1,73,
476,10,201,1,37,
476,10,201,4,0,
476,10,203,4,0,
476,10,205,3,0,
476,10,207,4,0,
476,10,213,4,0,
476,10,214,4,0,
476,10,216,4,0,
476,10,218,4,0,
476,10,220,3,0,
476,10,237,4,0,
476,10,241,4,0,
476,10,246,3,0,
476,10,249,4,0,
476,10,259,4,0,
476,10,263,4,0,
476,10,269,4,0,
476,10,277,3,0,
476,10,290,4,0,
476,10,317,4,0,
476,10,334,1,1,4
476,10,334,1,7,
476,10,334,3,0,
476,10,335,1,1,6
476,10,335,1,19,
476,10,335,3,0,
476,10,351,4,0,
476,10,356,1,1,2
476,10,356,3,0,
476,10,363,4,0,
476,10,393,1,1,1
476,10,393,3,0,
476,10,397,4,0,
476,10,408,1,49,
476,10,414,1,79,
476,10,414,3,0,
476,10,416,4,0,
476,10,430,4,0,
476,10,435,1,55,
476,10,442,3,0,
476,10,443,1,1,5
476,10,443,1,13,
476,10,444,1,61,
476,10,444,4,0,
476,10,445,4,0,
476,10,446,4,0,
476,11,33,1,1,3
476,11,63,4,0,
476,11,70,4,0,
476,11,85,4,0,
476,11,86,1,25,
476,11,86,4,0,
476,11,87,4,0,
476,11,89,4,0,
476,11,92,4,0,
476,11,104,4,0,
476,11,153,4,0,
476,11,156,1,43,
476,11,156,4,0,
476,11,157,1,31,
476,11,157,4,0,
476,11,164,4,0,
476,11,182,4,0,
476,11,192,1,67,
476,11,199,1,73,
476,11,201,1,37,
476,11,201,4,0,
476,11,207,4,0,
476,11,213,4,0,
476,11,216,4,0,
476,11,218,4,0,
476,11,237,4,0,
476,11,241,4,0,
476,11,249,4,0,
476,11,259,4,0,
476,11,263,4,0,
476,11,269,4,0,
476,11,317,4,0,
476,11,334,1,1,4
476,11,334,1,7,
476,11,335,1,1,6
476,11,335,1,19,
476,11,356,1,1,2
476,11,393,1,1,1
476,11,397,4,0,
476,11,408,1,49,
476,11,414,1,79,
476,11,416,4,0,
476,11,430,4,0,
476,11,435,1,55,
476,11,443,1,1,5
476,11,443,1,13,
476,11,444,1,61,
476,11,444,4,0,
476,11,479,4,0,
476,11,496,4,0,
476,11,521,4,0,
476,11,523,4,0,
476,14,7,3,0,
476,14,8,3,0,
476,14,9,3,0,
476,14,33,1,1,3
476,14,63,4,0,
476,14,70,4,0,
476,14,85,4,0,
476,14,86,1,15,
476,14,86,4,0,
476,14,87,4,0,
476,14,89,4,0,
476,14,92,4,0,
476,14,104,4,0,
476,14,153,4,0,
476,14,156,1,22,
476,14,156,4,0,
476,14,157,1,29,
476,14,157,4,0,
476,14,164,4,0,
476,14,173,3,0,
476,14,182,4,0,
476,14,192,1,50,2
476,14,199,1,50,1
476,14,201,1,36,
476,14,201,4,0,
476,14,207,4,0,
476,14,209,1,25,
476,14,213,4,0,
476,14,214,3,0,
476,14,216,4,0,
476,14,218,4,0,
476,14,220,3,0,
476,14,237,4,0,
476,14,241,4,0,
476,14,249,4,0,
476,14,259,4,0,
476,14,263,4,0,
476,14,269,4,0,
476,14,277,3,0,
476,14,317,4,0,
476,14,334,1,1,4
476,14,334,1,4,
476,14,334,3,0,
476,14,335,1,1,5
476,14,335,1,8,
476,14,335,3,0,
476,14,350,1,18,
476,14,356,1,1,2
476,14,356,3,0,
476,14,393,1,1,1
476,14,393,3,0,
476,14,397,4,0,
476,14,408,1,32,
476,14,414,1,43,
476,14,414,3,0,
476,14,416,4,0,
476,14,430,4,0,
476,14,435,1,39,
476,14,442,3,0,
476,14,443,1,1,6
476,14,443,1,11,
476,14,444,1,46,
476,14,444,4,0,
476,14,446,3,0,
476,14,479,4,0,
476,14,496,4,0,
476,14,521,4,0,
476,14,523,4,0,
476,15,33,1,1,3
476,15,63,4,0,
476,15,70,4,0,
476,15,85,4,0,
476,15,86,1,15,
476,15,86,4,0,
476,15,87,4,0,
476,15,89,4,0,
476,15,92,4,0,
476,15,104,4,0,
476,15,153,4,0,
476,15,156,1,22,
476,15,156,4,0,
476,15,157,1,29,
476,15,157,4,0,
476,15,164,4,0,
476,15,182,4,0,
476,15,192,1,50,2
476,15,199,1,50,1
476,15,201,1,36,
476,15,201,4,0,
476,15,207,4,0,
476,15,209,1,25,
476,15,213,4,0,
476,15,214,4,0,
476,15,216,4,0,
476,15,218,4,0,
476,15,237,4,0,
476,15,241,4,0,
476,15,249,4,0,
476,15,259,4,0,
476,15,263,4,0,
476,15,269,4,0,
476,15,317,4,0,
476,15,334,1,1,4
476,15,334,1,4,
476,15,335,1,1,5
476,15,335,1,8,
476,15,350,1,18,
476,15,356,1,1,2
476,15,393,1,1,1
476,15,397,4,0,
476,15,408,1,32,
476,15,414,1,43,
476,15,416,4,0,
476,15,430,4,0,
476,15,435,1,39,
476,15,443,1,1,6
476,15,443,1,11,
476,15,444,1,46,
476,15,444,4,0,
476,15,479,4,0,
476,15,496,4,0,
476,15,521,4,0,
476,15,523,4,0,
476,15,590,4,0,
476,15,605,4,0,
476,16,7,3,0,
476,16,8,3,0,
476,16,9,3,0,
476,16,33,1,1,4
476,16,63,4,0,
476,16,70,4,0,
476,16,85,4,0,
476,16,86,1,13,1
476,16,86,4,0,
476,16,87,4,0,
476,16,89,4,0,
476,16,92,4,0,
476,16,104,4,0,
476,16,153,4,0,
476,16,156,1,16,1
476,16,156,4,0,
476,16,157,1,22,1
476,16,157,4,0,
476,16,164,4,0,
476,16,173,3,0,
476,16,182,4,0,
476,16,192,1,43,2
476,16,199,1,43,1
476,16,201,1,34,1
476,16,201,4,0,
476,16,207,4,0,
476,16,209,1,19,1
476,16,213,4,0,
476,16,214,4,0,
476,16,216,4,0,
476,16,218,4,0,
476,16,220,3,0,
476,16,237,4,0,
476,16,241,4,0,
476,16,249,4,0,
476,16,259,4,0,
476,16,263,4,0,
476,16,269,4,0,
476,16,277,3,0,
476,16,290,4,0,
476,16,317,4,0,
476,16,334,1,1,5
476,16,334,1,4,1
476,16,334,3,0,
476,16,335,1,1,6
476,16,335,1,7,1
476,16,335,3,0,
476,16,350,1,28,1
476,16,351,3,0,
476,16,356,1,1,2
476,16,356,3,0,
476,16,393,1,1,1
476,16,393,3,0,
476,16,397,4,0,
476,16,408,1,25,1
476,16,414,1,37,1
476,16,414,3,0,
476,16,416,4,0,
476,16,430,4,0,
476,16,435,1,31,1
476,16,442,3,0,
476,16,443,1,1,7
476,16,443,1,10,1
476,16,444,1,40,1
476,16,444,4,0,
476,16,446,3,0,
476,16,469,1,1,3
476,16,479,4,0,
476,16,496,4,0,
476,16,521,4,0,
476,16,523,4,0,
476,16,590,4,0,
476,16,605,4,0,
476,17,33,1,1,6
476,17,63,4,0,
476,17,85,4,0,
476,17,86,1,13,
476,17,86,4,0,
476,17,87,4,0,
476,17,89,4,0,
476,17,92,4,0,
476,17,104,4,0,
476,17,153,4,0,
476,17,156,1,16,
476,17,156,4,0,
476,17,157,1,22,
476,17,157,4,0,
476,17,161,1,0,
476,17,161,1,1,1
476,17,164,4,0,
476,17,182,4,0,
476,17,192,1,43,2
476,17,199,1,43,1
476,17,201,1,34,
476,17,201,4,0,
476,17,207,4,0,
476,17,209,1,19,
476,17,213,4,0,
476,17,214,4,0,
476,17,216,4,0,
476,17,218,4,0,
476,17,237,4,0,
476,17,241,4,0,
476,17,259,4,0,
476,17,263,4,0,
476,17,269,4,0,
476,17,317,4,0,
476,17,334,1,1,7
476,17,334,1,4,
476,17,335,1,1,8
476,17,335,1,7,
476,17,350,1,28,
476,17,356,1,1,4
476,17,393,1,1,3
476,17,397,4,0,
476,17,408,1,25,
476,17,414,1,37,
476,17,416,4,0,
476,17,430,4,0,
476,17,435,1,31,
476,17,443,1,1,9
476,17,443,1,10,
476,17,444,1,40,
476,17,444,4,0,
476,17,469,1,1,5
476,17,479,4,0,
476,17,496,4,0,
476,17,521,4,0,
476,17,523,4,0,
476,17,590,4,0,
476,17,602,1,1,2
476,17,605,4,0,
476,18,33,1,1,6
476,18,63,4,0,
476,18,85,4,0,
476,18,86,1,13,
476,18,86,4,0,
476,18,87,4,0,
476,18,89,4,0,
476,18,92,4,0,
476,18,104,4,0,
476,18,153,4,0,
476,18,156,1,16,
476,18,156,4,0,
476,18,157,1,22,
476,18,157,4,0,
476,18,161,1,0,
476,18,161,1,1,1
476,18,164,4,0,
476,18,182,4,0,
476,18,192,1,43,2
476,18,199,1,43,1
476,18,201,1,34,
476,18,201,4,0,
476,18,207,4,0,
476,18,209,1,19,
476,18,213,4,0,
476,18,214,4,0,
476,18,216,4,0,
476,18,218,4,0,
476,18,237,4,0,
476,18,241,4,0,
476,18,259,4,0,
476,18,263,4,0,
476,18,269,4,0,
476,18,317,4,0,
476,18,334,1,1,7
476,18,334,1,4,
476,18,335,1,1,8
476,18,335,1,7,
476,18,350,1,28,
476,18,356,1,1,4
476,18,393,1,1,3
476,18,397,4,0,
476,18,408,1,25,
476,18,414,1,37,
476,18,416,4,0,
476,18,430,4,0,
476,18,435,1,31,
476,18,443,1,1,9
476,18,443,1,10,
476,18,444,1,40,
476,18,444,4,0,
476,18,469,1,1,5
476,18,479,4,0,
476,18,496,4,0,
476,18,521,4,0,
476,18,523,4,0,
476,18,590,4,0,
476,18,602,1,1,2
476,18,605,4,0,
477,8,7,1,1,1
477,8,8,1,1,2
477,8,9,1,1,3
477,8,20,1,1,5
477,8,43,1,1,6
477,8,50,1,1,8
477,8,50,1,6,
477,8,58,4,0,
477,8,59,4,0,
477,8,63,4,0,
477,8,70,4,0,
477,8,89,4,0,
477,8,92,4,0,
477,8,94,4,0,
477,8,101,1,1,7
477,8,104,4,0,
477,8,109,1,17,
477,8,138,4,0,
477,8,148,4,0,
477,8,156,4,0,
477,8,157,4,0,
477,8,164,4,0,
477,8,168,4,0,
477,8,174,1,30,
477,8,182,4,0,
477,8,193,1,9,
477,8,203,4,0,
477,8,207,4,0,
477,8,212,1,43,
477,8,213,4,0,
477,8,214,4,0,
477,8,216,4,0,
477,8,218,4,0,
477,8,228,1,25,
477,8,237,4,0,
477,8,240,4,0,
477,8,241,4,0,
477,8,244,4,0,
477,8,247,4,0,
477,8,248,1,61,
477,8,249,4,0,
477,8,259,4,0,
477,8,261,1,33,
477,8,261,4,0,
477,8,263,4,0,
477,8,264,4,0,
477,8,269,4,0,
477,8,280,4,0,
477,8,285,4,0,
477,8,289,4,0,
477,8,290,4,0,
477,8,310,1,14,
477,8,317,4,0,
477,8,325,1,37,
477,8,347,4,0,
477,8,356,1,1,4
477,8,363,4,0,
477,8,371,1,51,
477,8,371,4,0,
477,8,373,4,0,
477,8,374,4,0,
477,8,399,4,0,
477,8,411,4,0,
477,8,416,4,0,
477,8,425,1,22,
477,8,433,4,0,
477,8,445,4,0,
477,8,451,4,0,
477,9,7,1,1,1
477,9,7,3,0,
477,9,8,1,1,2
477,9,8,3,0,
477,9,9,1,1,3
477,9,9,3,0,
477,9,20,1,1,5
477,9,43,1,1,6
477,9,50,1,1,8
477,9,50,1,6,
477,9,58,4,0,
477,9,59,4,0,
477,9,63,4,0,
477,9,70,4,0,
477,9,89,4,0,
477,9,92,4,0,
477,9,94,4,0,
477,9,101,1,1,7
477,9,104,4,0,
477,9,109,1,17,
477,9,138,4,0,
477,9,148,4,0,
477,9,156,4,0,
477,9,157,4,0,
477,9,164,4,0,
477,9,168,4,0,
477,9,173,3,0,
477,9,174,1,30,
477,9,180,3,0,
477,9,182,4,0,
477,9,189,3,0,
477,9,193,1,9,
477,9,196,3,0,
477,9,203,4,0,
477,9,207,4,0,
477,9,212,1,43,
477,9,213,4,0,
477,9,214,4,0,
477,9,216,4,0,
477,9,218,4,0,
477,9,228,1,25,
477,9,237,4,0,
477,9,240,4,0,
477,9,241,4,0,
477,9,244,4,0,
477,9,247,4,0,
477,9,248,1,61,
477,9,249,4,0,
477,9,259,4,0,
477,9,261,1,33,
477,9,261,4,0,
477,9,263,4,0,
477,9,264,4,0,
477,9,269,4,0,
477,9,271,3,0,
477,9,280,4,0,
477,9,285,4,0,
477,9,289,4,0,
477,9,290,4,0,
477,9,310,1,14,
477,9,317,4,0,
477,9,325,1,37,
477,9,347,4,0,
477,9,356,1,1,4
477,9,363,4,0,
477,9,371,1,51,
477,9,371,4,0,
477,9,373,4,0,
477,9,374,4,0,
477,9,389,3,0,
477,9,399,4,0,
477,9,411,4,0,
477,9,416,4,0,
477,9,425,1,22,
477,9,433,4,0,
477,9,445,4,0,
477,9,451,4,0,
477,9,466,3,0,
477,10,7,1,1,1
477,10,7,3,0,
477,10,8,1,1,2
477,10,8,3,0,
477,10,9,1,1,3
477,10,9,3,0,
477,10,20,1,1,5
477,10,29,3,0,
477,10,43,1,1,6
477,10,50,1,1,8
477,10,50,1,6,
477,10,58,4,0,
477,10,59,4,0,
477,10,63,4,0,
477,10,70,4,0,
477,10,89,4,0,
477,10,92,4,0,
477,10,94,4,0,
477,10,101,1,1,7
477,10,104,4,0,
477,10,109,1,17,
477,10,138,4,0,
477,10,148,4,0,
477,10,156,4,0,
477,10,157,4,0,
477,10,164,4,0,
477,10,168,4,0,
477,10,173,3,0,
477,10,174,1,30,
477,10,180,3,0,
477,10,182,4,0,
477,10,189,3,0,
477,10,193,1,9,
477,10,196,3,0,
477,10,203,4,0,
477,10,207,4,0,
477,10,212,1,43,
477,10,213,4,0,
477,10,214,4,0,
477,10,216,4,0,
477,10,218,4,0,
477,10,220,3,0,
477,10,228,1,25,
477,10,237,4,0,
477,10,240,4,0,
477,10,241,4,0,
477,10,244,4,0,
477,10,247,4,0,
477,10,248,1,61,
477,10,249,4,0,
477,10,259,4,0,
477,10,261,1,33,
477,10,261,4,0,
477,10,263,4,0,
477,10,264,4,0,
477,10,269,4,0,
477,10,271,3,0,
477,10,280,4,0,
477,10,285,4,0,
477,10,289,4,0,
477,10,290,4,0,
477,10,310,1,14,
477,10,317,4,0,
477,10,325,1,37,
477,10,347,4,0,
477,10,356,1,1,4
477,10,356,3,0,
477,10,363,4,0,
477,10,371,1,51,
477,10,371,4,0,
477,10,373,4,0,
477,10,374,4,0,
477,10,389,3,0,
477,10,399,4,0,
477,10,411,4,0,
477,10,416,4,0,
477,10,425,1,22,
477,10,433,4,0,
477,10,445,4,0,
477,10,451,4,0,
477,10,466,3,0,
477,11,7,1,1,1
477,11,8,1,1,2
477,11,9,1,1,3
477,11,20,1,1,5
477,11,43,1,1,6
477,11,50,1,1,8
477,11,50,1,6,
477,11,58,4,0,
477,11,59,4,0,
477,11,63,4,0,
477,11,70,4,0,
477,11,89,4,0,
477,11,92,4,0,
477,11,94,4,0,
477,11,101,1,1,7
477,11,104,4,0,
477,11,109,1,17,
477,11,138,4,0,
477,11,148,4,0,
477,11,156,4,0,
477,11,157,4,0,
477,11,164,4,0,
477,11,168,4,0,
477,11,174,1,30,
477,11,182,4,0,
477,11,193,1,9,
477,11,207,4,0,
477,11,212,1,49,
477,11,213,4,0,
477,11,216,4,0,
477,11,218,4,0,
477,11,228,1,25,
477,11,237,4,0,
477,11,240,4,0,
477,11,241,4,0,
477,11,244,4,0,
477,11,247,4,0,
477,11,248,1,61,
477,11,249,4,0,
477,11,259,4,0,
477,11,261,1,33,
477,11,261,4,0,
477,11,263,4,0,
477,11,269,4,0,
477,11,280,4,0,
477,11,310,1,14,
477,11,317,4,0,
477,11,325,1,37,
477,11,347,4,0,
477,11,356,1,1,4
477,11,371,1,58,
477,11,371,4,0,
477,11,373,4,0,
477,11,374,4,0,
477,11,411,4,0,
477,11,416,4,0,
477,11,425,1,22,
477,11,433,4,0,
477,11,451,4,0,
477,11,477,4,0,
477,11,496,4,0,
477,11,506,1,42,
477,11,523,4,0,
477,14,7,1,1,1
477,14,7,3,0,
477,14,8,1,1,2
477,14,8,3,0,
477,14,9,1,1,3
477,14,9,3,0,
477,14,20,1,1,5
477,14,20,3,0,
477,14,43,1,1,6
477,14,50,1,1,8
477,14,50,1,6,
477,14,58,4,0,
477,14,59,4,0,
477,14,63,4,0,
477,14,70,4,0,
477,14,89,4,0,
477,14,92,4,0,
477,14,94,4,0,
477,14,101,1,1,7
477,14,104,4,0,
477,14,109,1,17,
477,14,138,4,0,
477,14,148,4,0,
477,14,156,4,0,
477,14,157,4,0,
477,14,164,4,0,
477,14,168,4,0,
477,14,173,3,0,
477,14,174,1,30,
477,14,180,3,0,
477,14,182,4,0,
477,14,193,1,9,
477,14,196,3,0,
477,14,207,4,0,
477,14,212,1,49,
477,14,213,4,0,
477,14,214,3,0,
477,14,216,4,0,
477,14,218,4,0,
477,14,220,3,0,
477,14,228,1,25,
477,14,237,4,0,
477,14,240,4,0,
477,14,241,4,0,
477,14,244,4,0,
477,14,247,4,0,
477,14,248,1,61,
477,14,249,4,0,
477,14,259,4,0,
477,14,261,1,33,
477,14,261,4,0,
477,14,263,4,0,
477,14,269,4,0,
477,14,271,3,0,
477,14,280,4,0,
477,14,285,3,0,
477,14,289,3,0,
477,14,310,1,14,
477,14,317,4,0,
477,14,325,1,37,
477,14,347,4,0,
477,14,356,1,1,4
477,14,356,3,0,
477,14,371,1,58,
477,14,371,4,0,
477,14,373,4,0,
477,14,374,4,0,
477,14,399,3,0,
477,14,411,4,0,
477,14,416,4,0,
477,14,425,1,22,
477,14,433,4,0,
477,14,451,4,0,
477,14,477,4,0,
477,14,496,4,0,
477,14,506,1,42,
477,14,523,4,0,
477,15,7,1,1,1
477,15,8,1,1,2
477,15,9,1,1,3
477,15,20,1,1,5
477,15,43,1,1,6
477,15,50,1,1,8
477,15,50,1,6,
477,15,58,4,0,
477,15,59,4,0,
477,15,63,4,0,
477,15,70,4,0,
477,15,89,4,0,
477,15,92,4,0,
477,15,94,4,0,
477,15,101,1,1,7
477,15,104,4,0,
477,15,109,1,17,
477,15,138,4,0,
477,15,148,4,0,
477,15,156,4,0,
477,15,157,4,0,
477,15,164,4,0,
477,15,168,4,0,
477,15,174,1,30,
477,15,182,4,0,
477,15,193,1,9,
477,15,207,4,0,
477,15,212,1,49,
477,15,213,4,0,
477,15,214,4,0,
477,15,216,4,0,
477,15,218,4,0,
477,15,228,1,25,
477,15,237,4,0,
477,15,240,4,0,
477,15,241,4,0,
477,15,244,4,0,
477,15,247,4,0,
477,15,248,1,61,
477,15,249,4,0,
477,15,259,4,0,
477,15,261,1,33,
477,15,261,4,0,
477,15,263,4,0,
477,15,269,4,0,
477,15,280,4,0,
477,15,310,1,14,
477,15,317,4,0,
477,15,325,1,37,
477,15,347,4,0,
477,15,356,1,1,4
477,15,371,1,58,
477,15,371,4,0,
477,15,373,4,0,
477,15,374,4,0,
477,15,399,4,0,
477,15,411,4,0,
477,15,416,4,0,
477,15,425,1,22,
477,15,433,4,0,
477,15,451,4,0,
477,15,496,4,0,
477,15,506,1,42,
477,15,523,4,0,
477,15,590,4,0,
477,15,611,4,0,
477,15,612,4,0,
477,16,7,1,1,2
477,16,7,3,0,
477,16,8,1,1,3
477,16,8,3,0,
477,16,9,1,1,4
477,16,9,3,0,
477,16,20,1,1,6
477,16,20,3,0,
477,16,43,1,1,7
477,16,50,1,1,9
477,16,50,1,6,1
477,16,58,4,0,
477,16,59,4,0,
477,16,63,4,0,
477,16,70,4,0,
477,16,89,4,0,
477,16,92,4,0,
477,16,94,4,0,
477,16,101,1,1,8
477,16,104,4,0,
477,16,109,1,30,1
477,16,138,4,0,
477,16,148,4,0,
477,16,156,4,0,
477,16,157,4,0,
477,16,164,4,0,
477,16,168,4,0,
477,16,173,3,0,
477,16,174,1,33,1
477,16,180,3,0,
477,16,182,4,0,
477,16,193,1,14,1
477,16,196,3,0,
477,16,207,4,0,
477,16,212,1,52,1
477,16,213,4,0,
477,16,214,4,0,
477,16,216,4,0,
477,16,218,4,0,
477,16,220,3,0,
477,16,228,1,22,1
477,16,237,4,0,
477,16,240,4,0,
477,16,241,4,0,
477,16,244,4,0,
477,16,247,1,45,1
477,16,247,4,0,
477,16,248,1,1,1
477,16,248,1,64,1
477,16,249,4,0,
477,16,259,4,0,
477,16,261,1,25,1
477,16,261,4,0,
477,16,263,4,0,
477,16,264,3,0,
477,16,269,4,0,
477,16,271,3,0,
477,16,280,4,0,
477,16,285,3,0,
477,16,289,3,0,
477,16,290,4,0,
477,16,310,1,1,10
477,16,310,1,9,1
477,16,317,4,0,
477,16,325,1,37,1
477,16,347,4,0,
477,16,356,1,1,5
477,16,356,3,0,
477,16,371,1,57,1
477,16,371,4,0,
477,16,373,4,0,
477,16,374,4,0,
477,16,399,4,0,
477,16,411,4,0,
477,16,416,4,0,
477,16,425,1,17,1
477,16,433,4,0,
477,16,451,4,0,
477,16,472,3,0,
477,16,496,4,0,
477,16,506,1,40,1
477,16,523,4,0,
477,16,590,4,0,
477,16,611,4,0,
477,16,612,4,0,
477,17,7,1,1,3
477,17,8,1,1,4
477,17,9,1,1,5
477,17,20,1,1,7
477,17,43,1,1,8
477,17,50,1,1,10
477,17,50,1,6,
477,17,58,4,0,
477,17,59,4,0,
477,17,63,4,0,
477,17,89,4,0,
477,17,92,4,0,
477,17,94,4,0,
477,17,101,1,1,9
477,17,104,4,0,
477,17,109,1,30,
477,17,138,4,0,
477,17,156,4,0,
477,17,157,4,0,
477,17,164,4,0,
477,17,168,4,0,
477,17,174,1,33,
477,17,182,4,0,
477,17,193,1,14,
477,17,207,4,0,
477,17,212,1,52,
477,17,213,4,0,
477,17,214,4,0,
477,17,216,4,0,
477,17,218,4,0,
477,17,228,1,22,
477,17,237,4,0,
477,17,240,4,0,
477,17,241,4,0,
477,17,244,4,0,
477,17,247,1,45,
477,17,247,4,0,
477,17,248,1,1,2
477,17,248,1,64,
477,17,259,4,0,
477,17,261,1,25,
477,17,261,4,0,
477,17,263,4,0,
477,17,269,4,0,
477,17,280,4,0,
477,17,310,1,1,11
477,17,310,1,9,
477,17,317,4,0,
477,17,325,1,1,1
477,17,347,4,0,
477,17,356,1,1,6
477,17,371,1,57,
477,17,371,4,0,
477,17,373,4,0,
477,17,374,4,0,
477,17,399,4,0,
477,17,411,4,0,
477,17,416,4,0,
477,17,425,1,17,
477,17,433,4,0,
477,17,451,4,0,
477,17,496,4,0,
477,17,506,1,40,
477,17,523,4,0,
477,17,590,4,0,
477,17,611,4,0,
477,18,7,1,1,3
477,18,8,1,1,4
477,18,9,1,1,5
477,18,20,1,1,7
477,18,43,1,1,8
477,18,50,1,1,10
477,18,50,1,6,
477,18,58,4,0,
477,18,59,4,0,
477,18,63,4,0,
477,18,89,4,0,
477,18,92,4,0,
477,18,94,4,0,
477,18,101,1,1,9
477,18,104,4,0,
477,18,109,1,30,
477,18,138,4,0,
477,18,156,4,0,
477,18,157,4,0,
477,18,164,4,0,
477,18,168,4,0,
477,18,174,1,33,
477,18,182,4,0,
477,18,193,1,14,
477,18,207,4,0,
477,18,212,1,52,
477,18,213,4,0,
477,18,214,4,0,
477,18,216,4,0,
477,18,218,4,0,
477,18,228,1,22,
477,18,237,4,0,
477,18,240,4,0,
477,18,241,4,0,
477,18,244,4,0,
477,18,247,1,45,
477,18,247,4,0,
477,18,248,1,1,2
477,18,248,1,64,
477,18,259,4,0,
477,18,261,1,25,
477,18,261,4,0,
477,18,263,4,0,
477,18,269,4,0,
477,18,280,4,0,
477,18,310,1,1,11
477,18,310,1,9,
477,18,317,4,0,
477,18,325,1,1,1
477,18,347,4,0,
477,18,356,1,1,6
477,18,371,1,57,
477,18,371,4,0,
477,18,373,4,0,
477,18,374,4,0,
477,18,399,4,0,
477,18,411,4,0,
477,18,416,4,0,
477,18,425,1,17,
477,18,433,4,0,
477,18,451,4,0,
477,18,496,4,0,
477,18,506,1,40,
477,18,523,4,0,
477,18,590,4,0,
477,18,611,4,0,
478,8,43,1,1,2
478,8,58,4,0,
478,8,59,1,51,
478,8,59,4,0,
478,8,63,4,0,
478,8,85,4,0,
478,8,86,4,0,
478,8,87,4,0,
478,8,92,4,0,
478,8,94,4,0,
478,8,104,1,1,3
478,8,104,1,4,
478,8,104,4,0,
478,8,109,1,19,
478,8,113,4,0,
478,8,138,4,0,
478,8,148,4,0,
478,8,156,4,0,
478,8,164,4,0,
478,8,181,1,1,1
478,8,182,4,0,
478,8,194,1,59,
478,8,196,1,13,
478,8,203,4,0,
478,8,207,4,0,
478,8,213,4,0,
478,8,214,4,0,
478,8,216,4,0,
478,8,218,4,0,
478,8,219,4,0,
478,8,237,4,0,
478,8,240,4,0,
478,8,244,4,0,
478,8,247,4,0,
478,8,258,1,40,
478,8,258,4,0,
478,8,259,4,0,
478,8,263,4,0,
478,8,269,4,0,
478,8,289,4,0,
478,8,290,4,0,
478,8,310,1,1,4
478,8,310,1,10,
478,8,351,4,0,
478,8,352,4,0,
478,8,358,1,28,
478,8,363,4,0,
478,8,371,4,0,
478,8,373,4,0,
478,8,374,4,0,
478,8,416,4,0,
478,8,419,4,0,
478,8,420,1,37,
478,8,445,1,31,
478,8,445,4,0,
478,8,466,1,22,
478,9,8,3,0,
478,9,43,1,1,2
478,9,58,4,0,
478,9,59,1,51,
478,9,59,4,0,
478,9,63,4,0,
478,9,85,4,0,
478,9,86,4,0,
478,9,87,4,0,
478,9,92,4,0,
478,9,94,4,0,
478,9,104,1,1,3
478,9,104,1,4,
478,9,104,4,0,
478,9,109,1,19,
478,9,113,4,0,
478,9,138,4,0,
478,9,148,4,0,
478,9,156,4,0,
478,9,164,4,0,
478,9,173,3,0,
478,9,180,3,0,
478,9,181,1,1,1
478,9,182,4,0,
478,9,189,3,0,
478,9,194,1,59,
478,9,196,1,13,
478,9,196,3,0,
478,9,203,4,0,
478,9,205,3,0,
478,9,207,4,0,
478,9,213,4,0,
478,9,214,4,0,
478,9,216,4,0,
478,9,218,4,0,
478,9,219,4,0,
478,9,237,4,0,
478,9,240,4,0,
478,9,244,4,0,
478,9,247,4,0,
478,9,258,1,40,
478,9,258,4,0,
478,9,259,4,0,
478,9,263,4,0,
478,9,269,4,0,
478,9,271,3,0,
478,9,289,4,0,
478,9,290,4,0,
478,9,310,1,1,4
478,9,310,1,10,
478,9,324,3,0,
478,9,351,4,0,
478,9,352,4,0,
478,9,358,1,28,
478,9,363,4,0,
478,9,371,4,0,
478,9,373,4,0,
478,9,374,4,0,
478,9,389,3,0,
478,9,416,4,0,
478,9,419,4,0,
478,9,420,1,37,
478,9,445,1,31,
478,9,445,4,0,
478,9,466,1,22,
478,9,466,3,0,
478,10,8,3,0,
478,10,29,3,0,
478,10,43,1,1,2
478,10,58,4,0,
478,10,59,1,51,
478,10,59,4,0,
478,10,63,4,0,
478,10,85,4,0,
478,10,86,4,0,
478,10,87,4,0,
478,10,92,4,0,
478,10,94,4,0,
478,10,104,1,1,3
478,10,104,1,4,
478,10,104,4,0,
478,10,109,1,19,
478,10,113,4,0,
478,10,138,4,0,
478,10,148,4,0,
478,10,156,4,0,
478,10,164,4,0,
478,10,173,3,0,
478,10,180,3,0,
478,10,181,1,1,1
478,10,182,4,0,
478,10,189,3,0,
478,10,194,1,59,
478,10,196,1,13,
478,10,196,3,0,
478,10,203,4,0,
478,10,205,3,0,
478,10,207,4,0,
478,10,213,4,0,
478,10,214,4,0,
478,10,216,4,0,
478,10,218,4,0,
478,10,219,4,0,
478,10,220,3,0,
478,10,237,4,0,
478,10,240,4,0,
478,10,244,4,0,
478,10,247,4,0,
478,10,258,1,40,
478,10,258,4,0,
478,10,259,4,0,
478,10,263,4,0,
478,10,269,4,0,
478,10,271,3,0,
478,10,289,4,0,
478,10,290,4,0,
478,10,310,1,1,4
478,10,310,1,10,
478,10,324,3,0,
478,10,335,3,0,
478,10,351,4,0,
478,10,352,4,0,
478,10,358,1,28,
478,10,363,4,0,
478,10,371,4,0,
478,10,373,4,0,
478,10,374,4,0,
478,10,389,3,0,
478,10,416,4,0,
478,10,419,4,0,
478,10,420,1,37,
478,10,445,1,31,
478,10,445,4,0,
478,10,466,1,22,
478,10,466,3,0,
478,11,43,1,1,2
478,11,58,4,0,
478,11,59,1,51,
478,11,59,4,0,
478,11,63,4,0,
478,11,85,4,0,
478,11,86,4,0,
478,11,87,4,0,
478,11,92,4,0,
478,11,94,4,0,
478,11,104,1,1,3
478,11,104,1,4,
478,11,104,4,0,
478,11,109,1,19,
478,11,113,4,0,
478,11,138,4,0,
478,11,148,4,0,
478,11,156,4,0,
478,11,164,4,0,
478,11,181,1,1,1
478,11,182,4,0,
478,11,194,1,59,
478,11,196,1,13,
478,11,207,4,0,
478,11,213,4,0,
478,11,216,4,0,
478,11,218,4,0,
478,11,219,4,0,
478,11,237,4,0,
478,11,240,4,0,
478,11,244,4,0,
478,11,247,4,0,
478,11,258,1,40,
478,11,258,4,0,
478,11,259,4,0,
478,11,263,4,0,
478,11,269,4,0,
478,11,310,1,1,4
478,11,310,1,10,
478,11,358,1,28,
478,11,371,4,0,
478,11,373,4,0,
478,11,374,4,0,
478,11,416,4,0,
478,11,420,1,37,
478,11,445,1,31,
478,11,466,1,22,
478,11,477,4,0,
478,11,496,4,0,
478,11,524,4,0,
478,14,8,3,0,
478,14,43,1,1,2
478,14,58,4,0,
478,14,59,1,51,
478,14,59,4,0,
478,14,63,4,0,
478,14,85,4,0,
478,14,86,4,0,
478,14,87,4,0,
478,14,92,4,0,
478,14,94,4,0,
478,14,104,1,1,3
478,14,104,1,4,
478,14,104,4,0,
478,14,109,1,19,
478,14,113,4,0,
478,14,138,4,0,
478,14,148,4,0,
478,14,156,4,0,
478,14,164,4,0,
478,14,173,3,0,
478,14,180,3,0,
478,14,181,1,1,1
478,14,182,4,0,
478,14,194,1,59,
478,14,196,1,13,
478,14,196,3,0,
478,14,207,4,0,
478,14,213,4,0,
478,14,214,3,0,
478,14,216,4,0,
478,14,218,4,0,
478,14,219,4,0,
478,14,220,3,0,
478,14,237,4,0,
478,14,240,4,0,
478,14,244,4,0,
478,14,247,4,0,
478,14,258,1,40,
478,14,258,4,0,
478,14,259,4,0,
478,14,263,4,0,
478,14,269,4,0,
478,14,271,3,0,
478,14,289,3,0,
478,14,310,1,1,4
478,14,310,1,10,
478,14,324,3,0,
478,14,335,3,0,
478,14,358,1,28,
478,14,371,4,0,
478,14,373,4,0,
478,14,374,4,0,
478,14,416,4,0,
478,14,420,1,37,
478,14,445,1,31,
478,14,466,1,22,
478,14,477,4,0,
478,14,496,4,0,
478,14,524,4,0,
478,15,43,1,1,3
478,15,58,4,0,
478,15,59,1,51,
478,15,59,4,0,
478,15,63,4,0,
478,15,85,4,0,
478,15,86,4,0,
478,15,87,4,0,
478,15,92,4,0,
478,15,94,4,0,
478,15,104,1,1,4
478,15,104,1,4,
478,15,104,4,0,
478,15,109,1,19,
478,15,113,4,0,
478,15,138,4,0,
478,15,148,4,0,
478,15,156,4,0,
478,15,164,4,0,
478,15,181,1,1,2
478,15,182,4,0,
478,15,194,1,1,1
478,15,194,1,59,
478,15,196,1,13,
478,15,207,4,0,
478,15,213,4,0,
478,15,214,4,0,
478,15,216,4,0,
478,15,218,4,0,
478,15,219,4,0,
478,15,237,4,0,
478,15,240,4,0,
478,15,244,4,0,
478,15,247,4,0,
478,15,258,1,40,
478,15,258,4,0,
478,15,259,4,0,
478,15,263,4,0,
478,15,269,4,0,
478,15,310,1,1,5
478,15,310,1,10,
478,15,358,1,28,
478,15,371,4,0,
478,15,373,4,0,
478,15,374,4,0,
478,15,416,4,0,
478,15,420,1,37,
478,15,445,1,31,
478,15,466,1,22,
478,15,496,4,0,
478,15,524,4,0,
478,15,590,4,0,
478,16,8,3,0,
478,16,43,1,1,3
478,16,58,4,0,
478,16,59,1,48,1
478,16,59,4,0,
478,16,63,4,0,
478,16,85,4,0,
478,16,86,4,0,
478,16,87,4,0,
478,16,92,4,0,
478,16,94,4,0,
478,16,104,1,1,4
478,16,104,1,5,1
478,16,104,4,0,
478,16,109,1,32,1
478,16,113,4,0,
478,16,138,4,0,
478,16,148,4,0,
478,16,156,4,0,
478,16,164,4,0,
478,16,173,3,0,
478,16,180,3,0,
478,16,181,1,1,2
478,16,182,4,0,
478,16,194,1,1,1
478,16,194,1,61,1
478,16,196,1,14,1
478,16,196,3,0,
478,16,207,4,0,
478,16,213,4,0,
478,16,214,4,0,
478,16,216,4,0,
478,16,218,4,0,
478,16,219,4,0,
478,16,220,3,0,
478,16,237,4,0,
478,16,240,4,0,
478,16,244,4,0,
478,16,247,1,42,1
478,16,247,4,0,
478,16,258,1,54,1
478,16,258,4,0,
478,16,259,4,0,
478,16,263,4,0,
478,16,269,4,0,
478,16,271,3,0,
478,16,289,3,0,
478,16,290,4,0,
478,16,310,1,19,1
478,16,324,3,0,
478,16,335,3,0,
478,16,351,3,0,
478,16,352,3,0,
478,16,358,1,37,1
478,16,371,4,0,
478,16,373,4,0,
478,16,374,4,0,
478,16,416,4,0,
478,16,420,1,1,5
478,16,420,1,10,1
478,16,445,1,41,1
478,16,466,1,28,1
478,16,496,4,0,
478,16,524,4,0,
478,16,577,1,23,1
478,16,590,4,0,
478,17,43,1,1,4
478,17,58,4,0,
478,17,59,1,48,
478,17,59,4,0,
478,17,63,4,0,
478,17,85,4,0,
478,17,86,4,0,
478,17,87,4,0,
478,17,92,4,0,
478,17,94,4,0,
478,17,104,1,1,5
478,17,104,1,5,
478,17,104,4,0,
478,17,109,1,32,
478,17,113,4,0,
478,17,138,4,0,
478,17,156,4,0,
478,17,164,4,0,
478,17,181,1,1,3
478,17,182,4,0,
478,17,194,1,1,2
478,17,194,1,61,
478,17,196,1,14,
478,17,207,4,0,
478,17,213,4,0,
478,17,214,4,0,
478,17,216,4,0,
478,17,218,4,0,
478,17,219,4,0,
478,17,237,4,0,
478,17,240,4,0,
478,17,244,4,0,
478,17,247,1,42,
478,17,247,4,0,
478,17,258,1,54,
478,17,258,4,0,
478,17,259,4,0,
478,17,261,1,28,
478,17,261,4,0,
478,17,263,4,0,
478,17,269,4,0,
478,17,310,1,19,
478,17,358,1,37,
478,17,371,4,0,
478,17,373,4,0,
478,17,374,4,0,
478,17,416,4,0,
478,17,420,1,1,6
478,17,420,1,10,
478,17,445,1,41,
478,17,466,1,0,
478,17,466,1,1,1
478,17,496,4,0,
478,17,524,4,0,
478,17,577,1,23,
478,17,590,4,0,
478,17,694,4,0,
478,18,43,1,1,4
478,18,58,4,0,
478,18,59,1,48,
478,18,59,4,0,
478,18,63,4,0,
478,18,85,4,0,
478,18,86,4,0,
478,18,87,4,0,
478,18,92,4,0,
478,18,94,4,0,
478,18,104,1,1,5
478,18,104,1,5,
478,18,104,4,0,
478,18,109,1,32,
478,18,113,4,0,
478,18,138,4,0,
478,18,156,4,0,
478,18,164,4,0,
478,18,181,1,1,3
478,18,182,4,0,
478,18,194,1,1,2
478,18,194,1,61,
478,18,196,1,14,
478,18,207,4,0,
478,18,213,4,0,
478,18,214,4,0,
478,18,216,4,0,
478,18,218,4,0,
478,18,219,4,0,
478,18,237,4,0,
478,18,240,4,0,
478,18,244,4,0,
478,18,247,1,42,
478,18,247,4,0,
478,18,258,1,54,
478,18,258,4,0,
478,18,259,4,0,
478,18,261,1,28,
478,18,261,4,0,
478,18,263,4,0,
478,18,269,4,0,
478,18,310,1,19,
478,18,358,1,37,
478,18,371,4,0,
478,18,373,4,0,
478,18,374,4,0,
478,18,416,4,0,
478,18,420,1,1,6
478,18,420,1,10,
478,18,445,1,41,
478,18,466,1,0,
478,18,466,1,1,1
478,18,496,4,0,
478,18,524,4,0,
478,18,577,1,23,
478,18,590,4,0,
478,18,694,4,0,
479,8,84,1,1,4
479,8,85,4,0,
479,8,86,1,1,3
479,8,86,4,0,
479,8,87,4,0,
479,8,92,4,0,
479,8,104,1,15,
479,8,104,4,0,
479,8,109,1,1,5
479,8,113,4,0,
479,8,115,4,0,
479,8,138,4,0,
479,8,148,4,0,
479,8,156,4,0,
479,8,164,1,36,
479,8,164,4,0,
479,8,168,4,0,
479,8,182,4,0,
479,8,203,4,0,
479,8,207,4,0,
479,8,214,4,0,
479,8,216,4,0,
479,8,218,4,0,
479,8,237,4,0,
479,8,240,4,0,
479,8,241,4,0,
479,8,244,4,0,
479,8,247,4,0,
479,8,253,1,8,
479,8,261,4,0,
479,8,263,4,0,
479,8,268,1,43,
479,8,271,1,1,1
479,8,289,4,0,
479,8,290,4,0,
479,8,310,1,1,2
479,8,351,1,22,
479,8,351,4,0,
479,8,363,4,0,
479,8,399,4,0,
479,8,435,1,50,
479,8,451,4,0,
479,8,466,1,29,
479,9,84,1,1,4
479,9,84,10,0,
479,9,85,4,0,
479,9,86,1,1,3
479,9,86,4,0,
479,9,87,4,0,
479,9,92,4,0,
479,9,104,1,15,
479,9,104,4,0,
479,9,109,1,1,5
479,9,113,4,0,
479,9,115,4,0,
479,9,129,3,0,
479,9,138,4,0,
479,9,148,4,0,
479,9,156,4,0,
479,9,164,1,36,
479,9,164,4,0,
479,9,168,4,0,
479,9,173,3,0,
479,9,180,3,0,
479,9,182,4,0,
479,9,189,3,0,
479,9,203,4,0,
479,9,207,4,0,
479,9,214,4,0,
479,9,216,4,0,
479,9,218,4,0,
479,9,237,4,0,
479,9,240,4,0,
479,9,241,4,0,
479,9,244,4,0,
479,9,247,4,0,
479,9,253,1,8,
479,9,253,3,0,
479,9,261,4,0,
479,9,263,4,0,
479,9,268,1,43,
479,9,271,1,1,1
479,9,271,3,0,
479,9,289,4,0,
479,9,290,4,0,
479,9,310,1,1,2
479,9,324,3,0,
479,9,351,1,22,
479,9,351,4,0,
479,9,363,4,0,
479,9,389,3,0,
479,9,399,4,0,
479,9,435,1,50,
479,9,451,4,0,
479,9,466,1,29,
479,9,466,3,0,
479,10,84,1,1,4
479,10,84,10,0,
479,10,85,4,0,
479,10,86,1,1,3
479,10,86,4,0,
479,10,87,4,0,
479,10,92,4,0,
479,10,104,1,15,
479,10,104,4,0,
479,10,109,1,1,5
479,10,113,4,0,
479,10,115,4,0,
479,10,129,3,0,
479,10,138,4,0,
479,10,148,4,0,
479,10,156,4,0,
479,10,164,1,36,
479,10,164,4,0,
479,10,168,4,0,
479,10,173,3,0,
479,10,180,3,0,
479,10,182,4,0,
479,10,189,3,0,
479,10,203,4,0,
479,10,207,4,0,
479,10,214,4,0,
479,10,216,4,0,
479,10,218,4,0,
479,10,220,3,0,
479,10,237,4,0,
479,10,240,4,0,
479,10,241,4,0,
479,10,244,4,0,
479,10,247,4,0,
479,10,253,1,8,
479,10,253,3,0,
479,10,261,4,0,
479,10,263,4,0,
479,10,268,1,43,
479,10,271,1,1,1
479,10,271,3,0,
479,10,289,4,0,
479,10,290,4,0,
479,10,310,1,1,2
479,10,324,3,0,
479,10,351,1,22,
479,10,351,4,0,
479,10,363,4,0,
479,10,389,3,0,
479,10,399,4,0,
479,10,435,1,50,
479,10,451,4,0,
479,10,466,1,29,
479,10,466,3,0,
479,11,84,1,1,4
479,11,84,10,0,
479,11,85,4,0,
479,11,86,1,1,3
479,11,86,4,0,
479,11,87,4,0,
479,11,92,4,0,
479,11,104,1,15,
479,11,104,4,0,
479,11,109,1,1,5
479,11,113,4,0,
479,11,115,4,0,
479,11,138,4,0,
479,11,148,4,0,
479,11,156,4,0,
479,11,164,1,36,
479,11,164,4,0,
479,11,168,4,0,
479,11,182,4,0,
479,11,207,4,0,
479,11,216,4,0,
479,11,218,4,0,
479,11,237,4,0,
479,11,240,4,0,
479,11,241,4,0,
479,11,244,4,0,
479,11,247,4,0,
479,11,253,1,8,
479,11,261,4,0,
479,11,263,4,0,
479,11,268,1,57,
479,11,271,1,1,1
479,11,310,1,1,2
479,11,351,1,22,
479,11,435,1,64,
479,11,451,4,0,
479,11,466,1,29,
479,11,477,4,0,
479,11,486,1,43,
479,11,496,4,0,
479,11,506,1,50,
479,11,521,4,0,
479,14,84,1,1,4
479,14,84,10,0,
479,14,85,4,0,
479,14,86,1,1,3
479,14,86,4,0,
479,14,87,4,0,
479,14,92,4,0,
479,14,104,1,15,
479,14,104,4,0,
479,14,109,1,1,5
479,14,113,4,0,
479,14,115,4,0,
479,14,138,4,0,
479,14,148,4,0,
479,14,156,4,0,
479,14,164,1,36,
479,14,164,4,0,
479,14,168,4,0,
479,14,173,3,0,
479,14,180,3,0,
479,14,182,4,0,
479,14,207,4,0,
479,14,214,3,0,
479,14,216,4,0,
479,14,218,4,0,
479,14,220,3,0,
479,14,237,4,0,
479,14,240,4,0,
479,14,241,4,0,
479,14,244,4,0,
479,14,247,4,0,
479,14,253,1,8,
479,14,253,3,0,
479,14,261,4,0,
479,14,263,4,0,
479,14,268,1,57,
479,14,271,1,1,1
479,14,271,3,0,
479,14,289,3,0,
479,14,310,1,1,2
479,14,324,3,0,
479,14,351,1,22,
479,14,399,3,0,
479,14,435,1,64,
479,14,451,4,0,
479,14,466,1,29,
479,14,477,4,0,
479,14,486,1,43,
479,14,496,4,0,
479,14,506,1,50,
479,14,521,4,0,
479,14,527,3,0,
479,15,84,1,1,6
479,15,84,10,0,
479,15,85,4,0,
479,15,86,1,1,5
479,15,86,4,0,
479,15,87,4,0,
479,15,92,4,0,
479,15,104,1,15,
479,15,104,4,0,
479,15,109,1,1,7
479,15,113,4,0,
479,15,115,4,0,
479,15,138,4,0,
479,15,148,4,0,
479,15,156,4,0,
479,15,164,1,36,
479,15,164,4,0,
479,15,168,4,0,
479,15,182,4,0,
479,15,207,4,0,
479,15,214,4,0,
479,15,216,4,0,
479,15,218,4,0,
479,15,237,4,0,
479,15,240,4,0,
479,15,241,4,0,
479,15,244,4,0,
479,15,247,4,0,
479,15,253,1,8,
479,15,261,4,0,
479,15,263,4,0,
479,15,268,1,1,2
479,15,268,1,57,
479,15,271,1,1,3
479,15,310,1,1,4
479,15,351,1,22,
479,15,399,4,0,
479,15,435,1,1,1
479,15,435,1,64,
479,15,451,4,0,
479,15,466,1,29,
479,15,486,1,43,
479,15,496,4,0,
479,15,506,1,50,
479,15,521,4,0,
479,15,590,4,0,
479,16,84,1,1,6
479,16,84,10,0,
479,16,85,4,0,
479,16,86,1,1,5
479,16,86,4,0,
479,16,87,4,0,
479,16,92,4,0,
479,16,104,1,15,1
479,16,104,4,0,
479,16,109,1,1,7
479,16,113,4,0,
479,16,115,4,0,
479,16,138,4,0,
479,16,148,4,0,
479,16,156,4,0,
479,16,164,1,36,1
479,16,164,4,0,
479,16,168,4,0,
479,16,173,3,0,
479,16,180,3,0,
479,16,182,4,0,
479,16,207,4,0,
479,16,214,4,0,
479,16,216,4,0,
479,16,218,4,0,
479,16,220,3,0,
479,16,237,4,0,
479,16,240,4,0,
479,16,241,4,0,
479,16,244,4,0,
479,16,247,4,0,
479,16,253,1,8,1
479,16,253,3,0,
479,16,261,4,0,
479,16,263,4,0,
479,16,268,1,1,2
479,16,268,1,57,1
479,16,271,1,1,3
479,16,271,3,0,
479,16,289,3,0,
479,16,290,4,0,
479,16,310,1,1,4
479,16,324,3,0,
479,16,351,1,22,1
479,16,351,3,0,
479,16,399,4,0,
479,16,435,1,1,1
479,16,435,1,64,1
479,16,451,4,0,
479,16,466,1,29,1
479,16,486,1,43,1
479,16,492,3,0,
479,16,496,4,0,
479,16,506,1,50,1
479,16,521,4,0,
479,16,527,3,0,
479,16,590,4,0,
479,17,84,1,1,6
479,17,84,10,0,
479,17,85,4,0,
479,17,86,1,1,5
479,17,86,4,0,
479,17,87,4,0,
479,17,92,4,0,
479,17,104,1,15,
479,17,104,4,0,
479,17,109,1,1,7
479,17,113,4,0,
479,17,115,4,0,
479,17,138,4,0,
479,17,156,4,0,
479,17,164,1,36,
479,17,164,4,0,
479,17,168,4,0,
479,17,182,4,0,
479,17,207,4,0,
479,17,214,4,0,
479,17,216,4,0,
479,17,218,4,0,
479,17,237,4,0,
479,17,240,4,0,
479,17,241,4,0,
479,17,244,4,0,
479,17,247,4,0,
479,17,253,1,8,
479,17,261,4,0,
479,17,263,4,0,
479,17,268,1,1,2
479,17,268,1,57,
479,17,271,1,1,3
479,17,310,1,1,4
479,17,351,1,22,
479,17,399,4,0,
479,17,435,1,1,1
479,17,435,1,64,
479,17,451,4,0,
479,17,466,1,29,
479,17,486,1,43,
479,17,496,4,0,
479,17,506,1,50,
479,17,521,4,0,
479,17,590,4,0,
479,18,84,1,1,6
479,18,84,10,0,
479,18,85,4,0,
479,18,86,1,1,5
479,18,86,4,0,
479,18,87,4,0,
479,18,92,4,0,
479,18,104,1,15,
479,18,104,4,0,
479,18,109,1,1,7
479,18,113,4,0,
479,18,115,4,0,
479,18,138,4,0,
479,18,156,4,0,
479,18,164,1,36,
479,18,164,4,0,
479,18,168,4,0,
479,18,182,4,0,
479,18,207,4,0,
479,18,214,4,0,
479,18,216,4,0,
479,18,218,4,0,
479,18,237,4,0,
479,18,240,4,0,
479,18,241,4,0,
479,18,244,4,0,
479,18,247,4,0,
479,18,253,1,8,
479,18,261,4,0,
479,18,263,4,0,
479,18,268,1,1,2
479,18,268,1,57,
479,18,271,1,1,3
479,18,310,1,1,4
479,18,351,1,22,
479,18,399,4,0,
479,18,435,1,1,1
479,18,435,1,64,
479,18,451,4,0,
479,18,466,1,29,
479,18,486,1,43,
479,18,496,4,0,
479,18,506,1,50,
479,18,521,4,0,
479,18,590,4,0,
480,8,63,4,0,
480,8,76,4,0,
480,8,85,4,0,
480,8,86,4,0,
480,8,87,4,0,
480,8,92,4,0,
480,8,93,1,21,
480,8,94,4,0,
480,8,104,4,0,
480,8,113,4,0,
480,8,115,4,0,
480,8,133,1,46,
480,8,138,4,0,
480,8,148,4,0,
480,8,156,1,1,
480,8,156,4,0,
480,8,164,4,0,
480,8,175,1,61,
480,8,182,4,0,
480,8,201,4,0,
480,8,202,4,0,
480,8,203,1,16,
480,8,203,4,0,
480,8,207,4,0,
480,8,214,4,0,
480,8,216,4,0,
480,8,218,4,0,
480,8,219,4,0,
480,8,231,4,0,
480,8,237,4,0,
480,8,240,4,0,
480,8,241,4,0,
480,8,244,4,0,
480,8,247,4,0,
480,8,248,1,36,
480,8,262,1,76,
480,8,263,4,0,
480,8,278,4,0,
480,8,281,1,31,
480,8,285,4,0,
480,8,286,1,6,
480,8,290,4,0,
480,8,326,1,51,
480,8,347,4,0,
480,8,351,4,0,
480,8,352,4,0,
480,8,363,1,66,
480,8,363,4,0,
480,8,369,4,0,
480,8,374,4,0,
480,8,412,4,0,
480,8,416,4,0,
480,8,433,4,0,
480,8,446,4,0,
480,8,447,4,0,
480,8,451,4,0,
480,9,7,3,0,
480,9,8,3,0,
480,9,9,3,0,
480,9,63,4,0,
480,9,76,4,0,
480,9,85,4,0,
480,9,86,4,0,
480,9,87,4,0,
480,9,92,4,0,
480,9,93,1,1,2
480,9,94,4,0,
480,9,104,4,0,
480,9,113,4,0,
480,9,115,4,0,
480,9,129,1,21,
480,9,129,3,0,
480,9,133,1,46,
480,9,138,4,0,
480,9,148,4,0,
480,9,156,1,1,1
480,9,156,4,0,
480,9,164,4,0,
480,9,173,3,0,
480,9,175,1,61,
480,9,182,4,0,
480,9,189,3,0,
480,9,201,4,0,
480,9,202,4,0,
480,9,203,1,16,
480,9,203,4,0,
480,9,207,4,0,
480,9,214,4,0,
480,9,216,4,0,
480,9,218,4,0,
480,9,219,4,0,
480,9,231,4,0,
480,9,237,4,0,
480,9,240,4,0,
480,9,241,4,0,
480,9,244,4,0,
480,9,247,4,0,
480,9,248,1,36,
480,9,262,1,76,
480,9,263,4,0,
480,9,270,3,0,
480,9,271,3,0,
480,9,278,4,0,
480,9,281,1,31,
480,9,282,3,0,
480,9,285,4,0,
480,9,286,1,6,
480,9,290,4,0,
480,9,324,3,0,
480,9,326,1,51,
480,9,347,4,0,
480,9,351,4,0,
480,9,352,4,0,
480,9,363,1,66,
480,9,363,4,0,
480,9,369,4,0,
480,9,374,4,0,
480,9,412,4,0,
480,9,416,4,0,
480,9,428,3,0,
480,9,433,4,0,
480,9,446,4,0,
480,9,447,4,0,
480,9,451,4,0,
480,10,7,3,0,
480,10,8,3,0,
480,10,9,3,0,
480,10,29,3,0,
480,10,63,4,0,
480,10,76,4,0,
480,10,85,4,0,
480,10,86,4,0,
480,10,87,4,0,
480,10,92,4,0,
480,10,93,1,1,2
480,10,94,4,0,
480,10,104,4,0,
480,10,113,4,0,
480,10,115,4,0,
480,10,129,1,21,
480,10,129,3,0,
480,10,133,1,46,
480,10,138,4,0,
480,10,148,4,0,
480,10,156,1,1,1
480,10,156,4,0,
480,10,164,4,0,
480,10,173,3,0,
480,10,175,1,61,
480,10,182,4,0,
480,10,189,3,0,
480,10,201,4,0,
480,10,202,4,0,
480,10,203,1,16,
480,10,203,4,0,
480,10,207,4,0,
480,10,214,4,0,
480,10,215,3,0,
480,10,216,4,0,
480,10,218,4,0,
480,10,219,4,0,
480,10,231,4,0,
480,10,237,4,0,
480,10,240,4,0,
480,10,241,4,0,
480,10,244,4,0,
480,10,247,4,0,
480,10,248,1,36,
480,10,262,1,76,
480,10,263,4,0,
480,10,270,3,0,
480,10,271,3,0,
480,10,272,3,0,
480,10,277,3,0,
480,10,278,4,0,
480,10,281,1,31,
480,10,282,3,0,
480,10,285,4,0,
480,10,286,1,6,
480,10,290,4,0,
480,10,324,3,0,
480,10,326,1,51,
480,10,347,4,0,
480,10,351,4,0,
480,10,352,4,0,
480,10,363,1,66,
480,10,363,4,0,
480,10,369,4,0,
480,10,374,4,0,
480,10,412,4,0,
480,10,416,4,0,
480,10,428,3,0,
480,10,433,4,0,
480,10,446,4,0,
480,10,447,4,0,
480,10,451,4,0,
480,11,63,4,0,
480,11,76,4,0,
480,11,85,4,0,
480,11,86,4,0,
480,11,87,4,0,
480,11,92,4,0,
480,11,93,1,1,2
480,11,94,4,0,
480,11,104,4,0,
480,11,113,4,0,
480,11,115,4,0,
480,11,129,1,21,
480,11,133,1,46,
480,11,138,4,0,
480,11,148,4,0,
480,11,156,1,1,1
480,11,156,4,0,
480,11,164,4,0,
480,11,175,1,61,
480,11,182,4,0,
480,11,201,4,0,
480,11,203,1,16,
480,11,207,4,0,
480,11,216,4,0,
480,11,218,4,0,
480,11,219,4,0,
480,11,237,4,0,
480,11,240,4,0,
480,11,241,4,0,
480,11,244,4,0,
480,11,247,4,0,
480,11,248,1,36,
480,11,262,1,76,
480,11,263,4,0,
480,11,281,1,31,
480,11,286,1,6,
480,11,326,1,51,
480,11,347,4,0,
480,11,363,1,66,
480,11,369,4,0,
480,11,374,4,0,
480,11,412,4,0,
480,11,416,4,0,
480,11,433,4,0,
480,11,447,4,0,
480,11,451,4,0,
480,11,473,4,0,
480,11,477,4,0,
480,11,496,4,0,
480,11,512,4,0,
480,14,7,3,0,
480,14,8,3,0,
480,14,9,3,0,
480,14,63,4,0,
480,14,76,4,0,
480,14,85,4,0,
480,14,86,4,0,
480,14,87,4,0,
480,14,92,4,0,
480,14,93,1,1,2
480,14,94,4,0,
480,14,104,4,0,
480,14,113,4,0,
480,14,115,4,0,
480,14,129,1,21,
480,14,133,1,46,
480,14,138,4,0,
480,14,148,4,0,
480,14,156,1,1,1
480,14,156,4,0,
480,14,164,4,0,
480,14,173,3,0,
480,14,175,1,61,
480,14,182,4,0,
480,14,201,4,0,
480,14,202,3,0,
480,14,203,1,16,
480,14,207,4,0,
480,14,214,3,0,
480,14,215,3,0,
480,14,216,4,0,
480,14,218,4,0,
480,14,219,4,0,
480,14,231,3,0,
480,14,237,4,0,
480,14,240,4,0,
480,14,241,4,0,
480,14,244,4,0,
480,14,247,4,0,
480,14,248,1,36,
480,14,262,1,76,
480,14,263,4,0,
480,14,270,3,0,
480,14,271,3,0,
480,14,272,3,0,
480,14,277,3,0,
480,14,278,3,0,
480,14,281,1,31,
480,14,282,3,0,
480,14,285,3,0,
480,14,286,1,6,
480,14,324,3,0,
480,14,326,1,51,
480,14,347,4,0,
480,14,363,1,66,
480,14,369,4,0,
480,14,374,4,0,
480,14,412,4,0,
480,14,416,4,0,
480,14,428,3,0,
480,14,433,4,0,
480,14,446,3,0,
480,14,447,4,0,
480,14,451,4,0,
480,14,472,3,0,
480,14,473,4,0,
480,14,477,4,0,
480,14,478,3,0,
480,14,492,3,0,
480,14,496,4,0,
480,14,512,4,0,
480,15,63,4,0,
480,15,76,4,0,
480,15,85,4,0,
480,15,86,4,0,
480,15,87,4,0,
480,15,92,4,0,
480,15,93,1,1,5
480,15,94,4,0,
480,15,104,4,0,
480,15,113,4,0,
480,15,115,4,0,
480,15,129,1,21,
480,15,133,1,46,
480,15,138,4,0,
480,15,148,4,0,
480,15,156,1,1,4
480,15,156,4,0,
480,15,164,4,0,
480,15,175,1,1,3
480,15,175,1,61,
480,15,182,4,0,
480,15,201,4,0,
480,15,203,1,16,
480,15,207,4,0,
480,15,214,4,0,
480,15,216,4,0,
480,15,218,4,0,
480,15,219,4,0,
480,15,237,4,0,
480,15,240,4,0,
480,15,241,4,0,
480,15,244,4,0,
480,15,247,4,0,
480,15,248,1,36,
480,15,262,1,1,1
480,15,262,1,76,
480,15,263,4,0,
480,15,281,1,31,
480,15,286,1,6,
480,15,326,1,50,
480,15,347,4,0,
480,15,363,1,1,2
480,15,363,1,66,
480,15,369,4,0,
480,15,374,4,0,
480,15,412,4,0,
480,15,416,4,0,
480,15,433,4,0,
480,15,447,4,0,
480,15,451,4,0,
480,15,473,4,0,
480,15,496,4,0,
480,15,512,4,0,
480,15,590,4,0,
480,15,605,4,0,
480,15,612,4,0,
480,16,7,3,0,
480,16,8,3,0,
480,16,9,3,0,
480,16,63,4,0,
480,16,76,4,0,
480,16,85,4,0,
480,16,86,4,0,
480,16,87,4,0,
480,16,92,4,0,
480,16,93,1,1,5
480,16,94,4,0,
480,16,104,4,0,
480,16,113,4,0,
480,16,115,4,0,
480,16,129,1,21,1
480,16,133,1,46,1
480,16,138,4,0,
480,16,148,4,0,
480,16,156,1,1,4
480,16,156,4,0,
480,16,164,4,0,
480,16,173,3,0,
480,16,175,1,1,3
480,16,175,1,61,1
480,16,182,4,0,
480,16,201,4,0,
480,16,202,3,0,
480,16,203,1,16,1
480,16,207,4,0,
480,16,214,4,0,
480,16,215,3,0,
480,16,216,4,0,
480,16,218,4,0,
480,16,219,4,0,
480,16,231,3,0,
480,16,237,4,0,
480,16,240,4,0,
480,16,241,4,0,
480,16,244,4,0,
480,16,247,4,0,
480,16,248,1,36,1
480,16,262,1,1,1
480,16,262,1,76,1
480,16,263,4,0,
480,16,270,3,0,
480,16,271,3,0,
480,16,272,3,0,
480,16,277,3,0,
480,16,278,3,0,
480,16,281,1,31,1
480,16,282,3,0,
480,16,285,3,0,
480,16,286,1,6,1
480,16,290,4,0,
480,16,324,3,0,
480,16,326,1,50,1
480,16,347,4,0,
480,16,351,3,0,
480,16,352,3,0,
480,16,363,1,1,2
480,16,363,1,66,1
480,16,369,4,0,
480,16,374,4,0,
480,16,412,4,0,
480,16,416,4,0,
480,16,428,3,0,
480,16,433,4,0,
480,16,446,3,0,
480,16,447,4,0,
480,16,451,4,0,
480,16,472,3,0,
480,16,473,4,0,
480,16,478,3,0,
480,16,492,3,0,
480,16,496,4,0,
480,16,512,4,0,
480,16,590,4,0,
480,16,605,4,0,
480,16,612,4,0,
480,17,63,4,0,
480,17,76,4,0,
480,17,85,4,0,
480,17,86,4,0,
480,17,87,4,0,
480,17,92,4,0,
480,17,93,1,1,5
480,17,94,4,0,
480,17,104,4,0,
480,17,113,4,0,
480,17,115,4,0,
480,17,129,1,21,
480,17,133,1,46,
480,17,138,4,0,
480,17,156,1,1,4
480,17,156,4,0,
480,17,164,4,0,
480,17,175,1,1,3
480,17,175,1,61,
480,17,182,4,0,
480,17,201,4,0,
480,17,203,1,16,
480,17,207,4,0,
480,17,214,4,0,
480,17,216,4,0,
480,17,218,4,0,
480,17,219,4,0,
480,17,237,4,0,
480,17,240,4,0,
480,17,241,4,0,
480,17,244,4,0,
480,17,247,4,0,
480,17,248,1,36,
480,17,262,1,1,1
480,17,262,1,76,
480,17,263,4,0,
480,17,281,1,31,
480,17,286,1,6,
480,17,326,1,50,
480,17,347,4,0,
480,17,363,1,1,2
480,17,363,1,66,
480,17,369,4,0,
480,17,374,4,0,
480,17,412,4,0,
480,17,416,4,0,
480,17,433,4,0,
480,17,447,4,0,
480,17,451,4,0,
480,17,473,4,0,
480,17,496,4,0,
480,17,512,4,0,
480,17,590,4,0,
480,17,605,4,0,
480,18,63,4,0,
480,18,76,4,0,
480,18,85,4,0,
480,18,86,4,0,
480,18,87,4,0,
480,18,92,4,0,
480,18,93,1,1,5
480,18,94,4,0,
480,18,104,4,0,
480,18,113,4,0,
480,18,115,4,0,
480,18,129,1,21,
480,18,133,1,46,
480,18,138,4,0,
480,18,156,1,1,4
480,18,156,4,0,
480,18,164,4,0,
480,18,175,1,1,3
480,18,175,1,61,
480,18,182,4,0,
480,18,201,4,0,
480,18,203,1,16,
480,18,207,4,0,
480,18,214,4,0,
480,18,216,4,0,
480,18,218,4,0,
480,18,219,4,0,
480,18,237,4,0,
480,18,240,4,0,
480,18,241,4,0,
480,18,244,4,0,
480,18,247,4,0,
480,18,248,1,36,
480,18,262,1,1,1
480,18,262,1,76,
480,18,263,4,0,
480,18,281,1,31,
480,18,286,1,6,
480,18,326,1,50,
480,18,347,4,0,
480,18,363,1,1,2
480,18,363,1,66,
480,18,369,4,0,
480,18,374,4,0,
480,18,412,4,0,
480,18,416,4,0,
480,18,433,4,0,
480,18,447,4,0,
480,18,451,4,0,
480,18,473,4,0,
480,18,496,4,0,
480,18,512,4,0,
480,18,590,4,0,
480,18,605,4,0,
481,8,58,4,0,
481,8,59,4,0,
481,8,63,4,0,
481,8,85,4,0,
481,8,86,4,0,
481,8,87,4,0,
481,8,92,4,0,
481,8,93,1,21,
481,8,94,4,0,
481,8,104,4,0,
481,8,113,4,0,
481,8,115,4,0,
481,8,138,4,0,
481,8,148,4,0,
481,8,156,1,1,
481,8,156,4,0,
481,8,164,4,0,
481,8,182,1,16,
481,8,182,4,0,
481,8,201,4,0,
481,8,203,4,0,
481,8,204,1,46,
481,8,207,4,0,
481,8,214,4,0,
481,8,216,4,0,
481,8,218,4,0,
481,8,219,4,0,
481,8,231,4,0,
481,8,237,4,0,
481,8,240,4,0,
481,8,241,4,0,
481,8,244,4,0,
481,8,247,4,0,
481,8,248,1,36,
481,8,263,4,0,
481,8,278,4,0,
481,8,285,4,0,
481,8,286,1,6,
481,8,290,4,0,
481,8,326,1,51,
481,8,347,4,0,
481,8,351,4,0,
481,8,352,4,0,
481,8,361,1,76,
481,8,363,1,66,
481,8,363,4,0,
481,8,369,4,0,
481,8,374,4,0,
481,8,381,1,31,
481,8,383,1,61,
481,8,412,4,0,
481,8,416,4,0,
481,8,433,4,0,
481,8,446,4,0,
481,8,447,4,0,
481,8,451,4,0,
481,9,7,3,0,
481,9,8,3,0,
481,9,9,3,0,
481,9,58,4,0,
481,9,59,4,0,
481,9,63,4,0,
481,9,85,4,0,
481,9,86,4,0,
481,9,87,4,0,
481,9,92,4,0,
481,9,93,1,1,2
481,9,94,4,0,
481,9,104,4,0,
481,9,113,4,0,
481,9,115,4,0,
481,9,129,1,21,
481,9,129,3,0,
481,9,138,4,0,
481,9,148,4,0,
481,9,156,1,1,1
481,9,156,4,0,
481,9,164,4,0,
481,9,173,3,0,
481,9,182,1,16,
481,9,182,4,0,
481,9,189,3,0,
481,9,201,4,0,
481,9,203,4,0,
481,9,204,1,46,
481,9,207,4,0,
481,9,214,4,0,
481,9,216,4,0,
481,9,218,4,0,
481,9,219,4,0,
481,9,231,4,0,
481,9,237,4,0,
481,9,240,4,0,
481,9,241,4,0,
481,9,244,4,0,
481,9,247,4,0,
481,9,248,1,36,
481,9,263,4,0,
481,9,270,3,0,
481,9,271,3,0,
481,9,278,4,0,
481,9,282,3,0,
481,9,285,4,0,
481,9,286,1,6,
481,9,290,4,0,
481,9,324,3,0,
481,9,326,1,51,
481,9,347,4,0,
481,9,351,4,0,
481,9,352,4,0,
481,9,361,1,76,
481,9,363,1,66,
481,9,363,4,0,
481,9,369,4,0,
481,9,374,4,0,
481,9,381,1,31,
481,9,383,1,61,
481,9,412,4,0,
481,9,416,4,0,
481,9,428,3,0,
481,9,433,4,0,
481,9,446,4,0,
481,9,447,4,0,
481,9,451,4,0,
481,10,7,3,0,
481,10,8,3,0,
481,10,9,3,0,
481,10,29,3,0,
481,10,58,4,0,
481,10,59,4,0,
481,10,63,4,0,
481,10,85,4,0,
481,10,86,4,0,
481,10,87,4,0,
481,10,92,4,0,
481,10,93,1,1,2
481,10,94,4,0,
481,10,104,4,0,
481,10,113,4,0,
481,10,115,4,0,
481,10,129,1,21,
481,10,129,3,0,
481,10,138,4,0,
481,10,148,4,0,
481,10,156,1,1,1
481,10,156,4,0,
481,10,164,4,0,
481,10,173,3,0,
481,10,182,1,16,
481,10,182,4,0,
481,10,189,3,0,
481,10,201,4,0,
481,10,203,4,0,
481,10,204,1,46,
481,10,207,4,0,
481,10,214,4,0,
481,10,216,4,0,
481,10,218,4,0,
481,10,219,4,0,
481,10,231,4,0,
481,10,237,4,0,
481,10,240,4,0,
481,10,241,4,0,
481,10,244,4,0,
481,10,247,4,0,
481,10,248,1,36,
481,10,263,4,0,
481,10,270,3,0,
481,10,271,3,0,
481,10,272,3,0,
481,10,277,3,0,
481,10,278,4,0,
481,10,282,3,0,
481,10,285,4,0,
481,10,286,1,6,
481,10,290,4,0,
481,10,324,3,0,
481,10,326,1,51,
481,10,347,4,0,
481,10,351,4,0,
481,10,352,4,0,
481,10,361,1,76,
481,10,363,1,66,
481,10,363,4,0,
481,10,369,4,0,
481,10,374,4,0,
481,10,381,1,31,
481,10,383,1,61,
481,10,412,4,0,
481,10,416,4,0,
481,10,428,3,0,
481,10,433,4,0,
481,10,446,4,0,
481,10,447,4,0,
481,10,451,4,0,
481,11,58,4,0,
481,11,59,4,0,
481,11,63,4,0,
481,11,85,4,0,
481,11,86,4,0,
481,11,87,4,0,
481,11,92,4,0,
481,11,93,1,1,2
481,11,94,4,0,
481,11,104,4,0,
481,11,113,4,0,
481,11,115,4,0,
481,11,129,1,21,
481,11,138,4,0,
481,11,148,4,0,
481,11,156,1,1,1
481,11,156,4,0,
481,11,164,4,0,
481,11,182,1,16,
481,11,182,4,0,
481,11,201,4,0,
481,11,204,1,46,
481,11,207,4,0,
481,11,216,4,0,
481,11,218,4,0,
481,11,219,4,0,
481,11,237,4,0,
481,11,240,4,0,
481,11,241,4,0,
481,11,244,4,0,
481,11,247,4,0,
481,11,248,1,36,
481,11,263,4,0,
481,11,286,1,6,
481,11,326,1,51,
481,11,347,4,0,
481,11,361,1,76,
481,11,363,1,66,
481,11,369,4,0,
481,11,374,4,0,
481,11,381,1,31,
481,11,383,1,61,
481,11,412,4,0,
481,11,416,4,0,
481,11,433,4,0,
481,11,447,4,0,
481,11,451,4,0,
481,11,473,4,0,
481,11,477,4,0,
481,11,496,4,0,
481,11,512,4,0,
481,14,7,3,0,
481,14,8,3,0,
481,14,9,3,0,
481,14,58,4,0,
481,14,59,4,0,
481,14,63,4,0,
481,14,85,4,0,
481,14,86,4,0,
481,14,87,4,0,
481,14,92,4,0,
481,14,93,1,1,2
481,14,94,4,0,
481,14,104,4,0,
481,14,113,4,0,
481,14,115,4,0,
481,14,129,1,21,
481,14,138,4,0,
481,14,148,4,0,
481,14,156,1,1,1
481,14,156,4,0,
481,14,164,4,0,
481,14,173,3,0,
481,14,182,1,16,
481,14,182,4,0,
481,14,201,4,0,
481,14,204,1,46,
481,14,207,4,0,
481,14,214,3,0,
481,14,216,4,0,
481,14,218,4,0,
481,14,219,4,0,
481,14,231,3,0,
481,14,237,4,0,
481,14,240,4,0,
481,14,241,4,0,
481,14,244,4,0,
481,14,247,4,0,
481,14,248,1,36,
481,14,263,4,0,
481,14,270,3,0,
481,14,271,3,0,
481,14,272,3,0,
481,14,277,3,0,
481,14,278,3,0,
481,14,282,3,0,
481,14,285,3,0,
481,14,286,1,6,
481,14,324,3,0,
481,14,326,1,51,
481,14,347,4,0,
481,14,361,1,76,
481,14,363,1,66,
481,14,369,4,0,
481,14,374,4,0,
481,14,381,1,31,
481,14,383,1,61,
481,14,412,4,0,
481,14,416,4,0,
481,14,428,3,0,
481,14,433,4,0,
481,14,446,3,0,
481,14,447,4,0,
481,14,451,4,0,
481,14,472,3,0,
481,14,473,4,0,
481,14,477,4,0,
481,14,478,3,0,
481,14,496,4,0,
481,14,512,4,0,
481,15,58,4,0,
481,15,59,4,0,
481,15,63,4,0,
481,15,85,4,0,
481,15,86,4,0,
481,15,87,4,0,
481,15,92,4,0,
481,15,93,1,1,5
481,15,94,4,0,
481,15,104,4,0,
481,15,113,4,0,
481,15,115,4,0,
481,15,129,1,21,
481,15,138,4,0,
481,15,148,4,0,
481,15,156,1,1,4
481,15,156,4,0,
481,15,164,4,0,
481,15,182,1,16,
481,15,182,4,0,
481,15,201,4,0,
481,15,204,1,46,
481,15,207,4,0,
481,15,214,4,0,
481,15,216,4,0,
481,15,218,4,0,
481,15,219,4,0,
481,15,237,4,0,
481,15,240,4,0,
481,15,241,4,0,
481,15,244,4,0,
481,15,247,4,0,
481,15,248,1,36,
481,15,263,4,0,
481,15,286,1,6,
481,15,326,1,50,
481,15,347,4,0,
481,15,361,1,1,1
481,15,361,1,76,
481,15,363,1,1,2
481,15,363,1,66,
481,15,369,4,0,
481,15,374,4,0,
481,15,381,1,31,
481,15,383,1,1,3
481,15,383,1,61,
481,15,412,4,0,
481,15,416,4,0,
481,15,433,4,0,
481,15,447,4,0,
481,15,451,4,0,
481,15,473,4,0,
481,15,496,4,0,
481,15,512,4,0,
481,15,590,4,0,
481,15,605,4,0,
481,15,612,4,0,
481,16,7,3,0,
481,16,8,3,0,
481,16,9,3,0,
481,16,58,4,0,
481,16,59,4,0,
481,16,63,4,0,
481,16,85,4,0,
481,16,86,4,0,
481,16,87,4,0,
481,16,92,4,0,
481,16,93,1,1,5
481,16,94,4,0,
481,16,104,4,0,
481,16,113,4,0,
481,16,115,4,0,
481,16,129,1,21,1
481,16,138,4,0,
481,16,148,4,0,
481,16,156,1,1,4
481,16,156,4,0,
481,16,164,4,0,
481,16,173,3,0,
481,16,182,1,16,1
481,16,182,4,0,
481,16,201,4,0,
481,16,204,1,46,1
481,16,207,4,0,
481,16,214,4,0,
481,16,216,4,0,
481,16,218,4,0,
481,16,219,4,0,
481,16,231,3,0,
481,16,237,4,0,
481,16,240,4,0,
481,16,241,4,0,
481,16,244,4,0,
481,16,247,4,0,
481,16,248,1,36,1
481,16,263,4,0,
481,16,270,3,0,
481,16,271,3,0,
481,16,272,3,0,
481,16,277,3,0,
481,16,278,3,0,
481,16,282,3,0,
481,16,285,3,0,
481,16,286,1,6,1
481,16,290,4,0,
481,16,324,3,0,
481,16,326,1,50,1
481,16,347,4,0,
481,16,351,3,0,
481,16,352,3,0,
481,16,361,1,1,1
481,16,361,1,76,1
481,16,363,1,1,2
481,16,363,1,66,1
481,16,369,4,0,
481,16,374,4,0,
481,16,381,1,31,1
481,16,383,1,1,3
481,16,383,1,61,1
481,16,412,4,0,
481,16,416,4,0,
481,16,428,3,0,
481,16,433,4,0,
481,16,446,3,0,
481,16,447,4,0,
481,16,451,4,0,
481,16,472,3,0,
481,16,473,4,0,
481,16,478,3,0,
481,16,496,4,0,
481,16,512,4,0,
481,16,590,4,0,
481,16,605,4,0,
481,16,612,4,0,
481,17,58,4,0,
481,17,59,4,0,
481,17,63,4,0,
481,17,85,4,0,
481,17,86,4,0,
481,17,87,4,0,
481,17,92,4,0,
481,17,93,1,1,5
481,17,94,4,0,
481,17,104,4,0,
481,17,113,4,0,
481,17,115,4,0,
481,17,129,1,21,
481,17,138,4,0,
481,17,156,1,1,4
481,17,156,4,0,
481,17,164,4,0,
481,17,182,1,16,
481,17,182,4,0,
481,17,201,4,0,
481,17,204,1,46,
481,17,207,4,0,
481,17,214,4,0,
481,17,216,4,0,
481,17,218,4,0,
481,17,219,4,0,
481,17,237,4,0,
481,17,240,4,0,
481,17,241,4,0,
481,17,244,4,0,
481,17,247,4,0,
481,17,248,1,36,
481,17,263,4,0,
481,17,286,1,6,
481,17,326,1,50,
481,17,347,4,0,
481,17,361,1,1,1
481,17,361,1,76,
481,17,363,1,1,2
481,17,363,1,66,
481,17,369,4,0,
481,17,374,4,0,
481,17,381,1,31,
481,17,383,1,1,3
481,17,383,1,61,
481,17,412,4,0,
481,17,416,4,0,
481,17,433,4,0,
481,17,447,4,0,
481,17,451,4,0,
481,17,473,4,0,
481,17,496,4,0,
481,17,512,4,0,
481,17,590,4,0,
481,17,605,4,0,
481,18,58,4,0,
481,18,59,4,0,
481,18,63,4,0,
481,18,85,4,0,
481,18,86,4,0,
481,18,87,4,0,
481,18,92,4,0,
481,18,93,1,1,5
481,18,94,4,0,
481,18,104,4,0,
481,18,113,4,0,
481,18,115,4,0,
481,18,129,1,21,
481,18,138,4,0,
481,18,156,1,1,4
481,18,156,4,0,
481,18,164,4,0,
481,18,182,1,16,
481,18,182,4,0,
481,18,201,4,0,
481,18,204,1,46,
481,18,207,4,0,
481,18,214,4,0,
481,18,216,4,0,
481,18,218,4,0,
481,18,219,4,0,
481,18,237,4,0,
481,18,240,4,0,
481,18,241,4,0,
481,18,244,4,0,
481,18,247,4,0,
481,18,248,1,36,
481,18,263,4,0,
481,18,286,1,6,
481,18,326,1,50,
481,18,347,4,0,
481,18,361,1,1,1
481,18,361,1,76,
481,18,363,1,1,2
481,18,363,1,66,
481,18,369,4,0,
481,18,374,4,0,
481,18,381,1,31,
481,18,383,1,1,3
481,18,383,1,61,
481,18,412,4,0,
481,18,416,4,0,
481,18,433,4,0,
481,18,447,4,0,
481,18,451,4,0,
481,18,473,4,0,
481,18,496,4,0,
481,18,512,4,0,
481,18,590,4,0,
481,18,605,4,0,
482,8,53,4,0,
482,8,63,4,0,
482,8,85,4,0,
482,8,86,4,0,
482,8,87,4,0,
482,8,92,4,0,
482,8,93,1,21,
482,8,94,4,0,
482,8,104,4,0,
482,8,113,4,0,
482,8,115,4,0,
482,8,126,4,0,
482,8,138,4,0,
482,8,148,4,0,
482,8,153,1,76,
482,8,153,4,0,
482,8,156,1,1,
482,8,156,4,0,
482,8,164,4,0,
482,8,182,4,0,
482,8,197,1,16,
482,8,201,4,0,
482,8,203,4,0,
482,8,207,4,0,
482,8,214,4,0,
482,8,216,4,0,
482,8,218,4,0,
482,8,219,4,0,
482,8,231,4,0,
482,8,237,4,0,
482,8,240,4,0,
482,8,241,4,0,
482,8,244,4,0,
482,8,247,4,0,
482,8,248,1,36,
482,8,253,1,31,
482,8,259,4,0,
482,8,263,4,0,
482,8,269,4,0,
482,8,278,4,0,
482,8,285,4,0,
482,8,286,1,6,
482,8,290,4,0,
482,8,326,1,51,
482,8,347,4,0,
482,8,351,4,0,
482,8,352,4,0,
482,8,363,1,66,
482,8,363,4,0,
482,8,369,4,0,
482,8,371,4,0,
482,8,374,4,0,
482,8,387,1,61,
482,8,412,4,0,
482,8,416,4,0,
482,8,417,1,46,
482,8,433,4,0,
482,8,446,4,0,
482,8,447,4,0,
482,8,451,4,0,
482,9,7,3,0,
482,9,8,3,0,
482,9,9,3,0,
482,9,53,4,0,
482,9,63,4,0,
482,9,85,4,0,
482,9,86,4,0,
482,9,87,4,0,
482,9,92,4,0,
482,9,93,1,1,2
482,9,94,4,0,
482,9,104,4,0,
482,9,113,4,0,
482,9,115,4,0,
482,9,126,4,0,
482,9,129,1,21,
482,9,129,3,0,
482,9,138,4,0,
482,9,148,4,0,
482,9,153,1,76,
482,9,153,4,0,
482,9,156,1,1,1
482,9,156,4,0,
482,9,164,4,0,
482,9,173,3,0,
482,9,182,4,0,
482,9,189,3,0,
482,9,197,1,16,
482,9,201,4,0,
482,9,203,4,0,
482,9,207,4,0,
482,9,214,4,0,
482,9,216,4,0,
482,9,218,4,0,
482,9,219,4,0,
482,9,231,4,0,
482,9,237,4,0,
482,9,240,4,0,
482,9,241,4,0,
482,9,244,4,0,
482,9,247,4,0,
482,9,248,1,36,
482,9,253,1,31,
482,9,253,3,0,
482,9,259,4,0,
482,9,263,4,0,
482,9,269,4,0,
482,9,270,3,0,
482,9,271,3,0,
482,9,278,4,0,
482,9,282,3,0,
482,9,285,4,0,
482,9,286,1,6,
482,9,290,4,0,
482,9,324,3,0,
482,9,326,1,51,
482,9,347,4,0,
482,9,351,4,0,
482,9,352,4,0,
482,9,363,1,66,
482,9,363,4,0,
482,9,369,4,0,
482,9,371,4,0,
482,9,374,4,0,
482,9,387,1,61,
482,9,387,3,0,
482,9,412,4,0,
482,9,416,4,0,
482,9,417,1,46,
482,9,428,3,0,
482,9,433,4,0,
482,9,446,4,0,
482,9,447,4,0,
482,9,451,4,0,
482,10,7,3,0,
482,10,8,3,0,
482,10,9,3,0,
482,10,29,3,0,
482,10,53,4,0,
482,10,63,4,0,
482,10,85,4,0,
482,10,86,4,0,
482,10,87,4,0,
482,10,92,4,0,
482,10,93,1,1,2
482,10,94,4,0,
482,10,104,4,0,
482,10,113,4,0,
482,10,115,4,0,
482,10,126,4,0,
482,10,129,1,21,
482,10,129,3,0,
482,10,138,4,0,
482,10,148,4,0,
482,10,153,1,76,
482,10,153,4,0,
482,10,156,1,1,1
482,10,156,4,0,
482,10,164,4,0,
482,10,173,3,0,
482,10,182,4,0,
482,10,189,3,0,
482,10,197,1,16,
482,10,201,4,0,
482,10,203,4,0,
482,10,207,4,0,
482,10,214,4,0,
482,10,216,4,0,
482,10,218,4,0,
482,10,219,4,0,
482,10,231,4,0,
482,10,237,4,0,
482,10,240,4,0,
482,10,241,4,0,
482,10,244,4,0,
482,10,247,4,0,
482,10,248,1,36,
482,10,253,1,31,
482,10,253,3,0,
482,10,259,4,0,
482,10,263,4,0,
482,10,269,4,0,
482,10,270,3,0,
482,10,271,3,0,
482,10,272,3,0,
482,10,277,3,0,
482,10,278,4,0,
482,10,282,3,0,
482,10,285,4,0,
482,10,286,1,6,
482,10,290,4,0,
482,10,324,3,0,
482,10,326,1,51,
482,10,347,4,0,
482,10,351,4,0,
482,10,352,4,0,
482,10,363,1,66,
482,10,363,4,0,
482,10,369,4,0,
482,10,371,4,0,
482,10,374,4,0,
482,10,387,1,61,
482,10,387,3,0,
482,10,412,4,0,
482,10,416,4,0,
482,10,417,1,46,
482,10,428,3,0,
482,10,433,4,0,
482,10,446,4,0,
482,10,447,4,0,
482,10,451,4,0,
482,11,53,4,0,
482,11,63,4,0,
482,11,85,4,0,
482,11,86,4,0,
482,11,87,4,0,
482,11,92,4,0,
482,11,93,1,1,2
482,11,94,4,0,
482,11,104,4,0,
482,11,113,4,0,
482,11,115,4,0,
482,11,126,4,0,
482,11,129,1,21,
482,11,138,4,0,
482,11,148,4,0,
482,11,153,1,76,
482,11,153,4,0,
482,11,156,1,1,1
482,11,156,4,0,
482,11,164,4,0,
482,11,182,4,0,
482,11,197,1,16,
482,11,201,4,0,
482,11,207,4,0,
482,11,216,4,0,
482,11,218,4,0,
482,11,219,4,0,
482,11,237,4,0,
482,11,240,4,0,
482,11,241,4,0,
482,11,244,4,0,
482,11,247,4,0,
482,11,248,1,36,
482,11,253,1,31,
482,11,259,4,0,
482,11,263,4,0,
482,11,269,4,0,
482,11,286,1,6,
482,11,326,1,51,
482,11,347,4,0,
482,11,363,1,66,
482,11,369,4,0,
482,11,371,4,0,
482,11,374,4,0,
482,11,387,1,61,
482,11,412,4,0,
482,11,416,4,0,
482,11,417,1,46,
482,11,433,4,0,
482,11,447,4,0,
482,11,451,4,0,
482,11,473,4,0,
482,11,477,4,0,
482,11,496,4,0,
482,11,510,4,0,
482,11,512,4,0,
482,14,7,3,0,
482,14,8,3,0,
482,14,9,3,0,
482,14,53,4,0,
482,14,63,4,0,
482,14,85,4,0,
482,14,86,4,0,
482,14,87,4,0,
482,14,92,4,0,
482,14,93,1,1,2
482,14,94,4,0,
482,14,104,4,0,
482,14,113,4,0,
482,14,115,4,0,
482,14,126,4,0,
482,14,129,1,21,
482,14,138,4,0,
482,14,148,4,0,
482,14,153,1,76,
482,14,153,4,0,
482,14,156,1,1,1
482,14,156,4,0,
482,14,164,4,0,
482,14,173,3,0,
482,14,182,4,0,
482,14,197,1,16,
482,14,201,4,0,
482,14,207,4,0,
482,14,214,3,0,
482,14,216,4,0,
482,14,218,4,0,
482,14,219,4,0,
482,14,231,3,0,
482,14,237,4,0,
482,14,240,4,0,
482,14,241,4,0,
482,14,244,4,0,
482,14,247,4,0,
482,14,248,1,36,
482,14,253,1,31,
482,14,253,3,0,
482,14,259,4,0,
482,14,263,4,0,
482,14,269,4,0,
482,14,270,3,0,
482,14,271,3,0,
482,14,272,3,0,
482,14,277,3,0,
482,14,278,3,0,
482,14,282,3,0,
482,14,285,3,0,
482,14,286,1,6,
482,14,324,3,0,
482,14,326,1,51,
482,14,347,4,0,
482,14,363,1,66,
482,14,369,4,0,
482,14,371,4,0,
482,14,374,4,0,
482,14,387,1,61,
482,14,387,3,0,
482,14,412,4,0,
482,14,416,4,0,
482,14,417,1,46,
482,14,428,3,0,
482,14,433,4,0,
482,14,446,3,0,
482,14,447,4,0,
482,14,451,4,0,
482,14,472,3,0,
482,14,473,4,0,
482,14,477,4,0,
482,14,478,3,0,
482,14,496,4,0,
482,14,510,4,0,
482,14,512,4,0,
482,15,53,4,0,
482,15,63,4,0,
482,15,85,4,0,
482,15,86,4,0,
482,15,87,4,0,
482,15,92,4,0,
482,15,93,1,1,4
482,15,94,4,0,
482,15,104,4,0,
482,15,113,4,0,
482,15,115,4,0,
482,15,126,4,0,
482,15,129,1,21,
482,15,138,4,0,
482,15,148,4,0,
482,15,153,1,76,
482,15,153,4,0,
482,15,156,1,1,3
482,15,156,4,0,
482,15,164,4,0,
482,15,182,4,0,
482,15,197,1,16,
482,15,201,4,0,
482,15,207,4,0,
482,15,214,4,0,
482,15,216,4,0,
482,15,218,4,0,
482,15,219,4,0,
482,15,237,4,0,
482,15,240,4,0,
482,15,241,4,0,
482,15,244,4,0,
482,15,247,4,0,
482,15,248,1,36,
482,15,253,1,31,
482,15,259,4,0,
482,15,263,4,0,
482,15,269,4,0,
482,15,286,1,6,
482,15,326,1,50,
482,15,347,4,0,
482,15,363,1,1,1
482,15,363,1,66,
482,15,369,4,0,
482,15,371,4,0,
482,15,374,4,0,
482,15,387,1,1,2
482,15,387,1,61,
482,15,412,4,0,
482,15,416,4,0,
482,15,417,1,46,
482,15,433,4,0,
482,15,447,4,0,
482,15,451,4,0,
482,15,473,4,0,
482,15,496,4,0,
482,15,510,4,0,
482,15,512,4,0,
482,15,590,4,0,
482,15,605,4,0,
482,15,612,4,0,
482,16,7,3,0,
482,16,8,3,0,
482,16,9,3,0,
482,16,53,4,0,
482,16,63,4,0,
482,16,85,4,0,
482,16,86,4,0,
482,16,87,4,0,
482,16,92,4,0,
482,16,93,1,1,4
482,16,94,4,0,
482,16,104,4,0,
482,16,113,4,0,
482,16,115,4,0,
482,16,126,4,0,
482,16,129,1,21,1
482,16,138,4,0,
482,16,148,4,0,
482,16,153,1,76,1
482,16,153,4,0,
482,16,156,1,1,3
482,16,156,4,0,
482,16,164,4,0,
482,16,173,3,0,
482,16,182,4,0,
482,16,197,1,16,1
482,16,201,4,0,
482,16,207,4,0,
482,16,214,4,0,
482,16,216,4,0,
482,16,218,4,0,
482,16,219,4,0,
482,16,231,3,0,
482,16,237,4,0,
482,16,240,4,0,
482,16,241,4,0,
482,16,244,4,0,
482,16,247,4,0,
482,16,248,1,36,1
482,16,253,1,31,1
482,16,253,3,0,
482,16,259,4,0,
482,16,263,4,0,
482,16,269,4,0,
482,16,270,3,0,
482,16,271,3,0,
482,16,272,3,0,
482,16,277,3,0,
482,16,278,3,0,
482,16,282,3,0,
482,16,285,3,0,
482,16,286,1,6,1
482,16,290,4,0,
482,16,324,3,0,
482,16,326,1,50,1
482,16,347,4,0,
482,16,351,3,0,
482,16,352,3,0,
482,16,363,1,1,1
482,16,363,1,66,1
482,16,369,4,0,
482,16,371,4,0,
482,16,374,4,0,
482,16,387,1,1,2
482,16,387,1,61,1
482,16,387,3,0,
482,16,412,4,0,
482,16,416,4,0,
482,16,417,1,46,1
482,16,428,3,0,
482,16,433,4,0,
482,16,446,3,0,
482,16,447,4,0,
482,16,451,4,0,
482,16,472,3,0,
482,16,473,4,0,
482,16,478,3,0,
482,16,496,4,0,
482,16,510,4,0,
482,16,512,4,0,
482,16,590,4,0,
482,16,605,4,0,
482,16,612,4,0,
482,17,53,4,0,
482,17,63,4,0,
482,17,85,4,0,
482,17,86,4,0,
482,17,87,4,0,
482,17,92,4,0,
482,17,93,1,1,4
482,17,94,4,0,
482,17,104,4,0,
482,17,113,4,0,
482,17,115,4,0,
482,17,126,4,0,
482,17,129,1,21,
482,17,138,4,0,
482,17,153,1,76,
482,17,153,4,0,
482,17,156,1,1,3
482,17,156,4,0,
482,17,164,4,0,
482,17,182,4,0,
482,17,197,1,16,
482,17,201,4,0,
482,17,207,4,0,
482,17,214,4,0,
482,17,216,4,0,
482,17,218,4,0,
482,17,219,4,0,
482,17,237,4,0,
482,17,240,4,0,
482,17,241,4,0,
482,17,244,4,0,
482,17,247,4,0,
482,17,248,1,36,
482,17,253,1,31,
482,17,259,4,0,
482,17,263,4,0,
482,17,269,4,0,
482,17,286,1,6,
482,17,326,1,50,
482,17,347,4,0,
482,17,363,1,1,1
482,17,363,1,66,
482,17,369,4,0,
482,17,371,4,0,
482,17,374,4,0,
482,17,387,1,1,2
482,17,387,1,61,
482,17,412,4,0,
482,17,416,4,0,
482,17,417,1,46,
482,17,433,4,0,
482,17,447,4,0,
482,17,451,4,0,
482,17,473,4,0,
482,17,496,4,0,
482,17,512,4,0,
482,17,590,4,0,
482,17,605,4,0,
482,18,53,4,0,
482,18,63,4,0,
482,18,85,4,0,
482,18,86,4,0,
482,18,87,4,0,
482,18,92,4,0,
482,18,93,1,1,4
482,18,94,4,0,
482,18,104,4,0,
482,18,113,4,0,
482,18,115,4,0,
482,18,126,4,0,
482,18,129,1,21,
482,18,138,4,0,
482,18,153,1,76,
482,18,153,4,0,
482,18,156,1,1,3
482,18,156,4,0,
482,18,164,4,0,
482,18,182,4,0,
482,18,197,1,16,
482,18,201,4,0,
482,18,207,4,0,
482,18,214,4,0,
482,18,216,4,0,
482,18,218,4,0,
482,18,219,4,0,
482,18,237,4,0,
482,18,240,4,0,
482,18,241,4,0,
482,18,244,4,0,
482,18,247,4,0,
482,18,248,1,36,
482,18,253,1,31,
482,18,259,4,0,
482,18,263,4,0,
482,18,269,4,0,
482,18,286,1,6,
482,18,326,1,50,
482,18,347,4,0,
482,18,363,1,1,1
482,18,363,1,66,
482,18,369,4,0,
482,18,371,4,0,
482,18,374,4,0,
482,18,387,1,1,2
482,18,387,1,61,
482,18,412,4,0,
482,18,416,4,0,
482,18,417,1,46,
482,18,433,4,0,
482,18,447,4,0,
482,18,451,4,0,
482,18,473,4,0,
482,18,496,4,0,
482,18,512,4,0,
482,18,590,4,0,
482,18,605,4,0,
483,8,15,4,0,
483,8,46,4,0,
483,8,53,4,0,
483,8,58,4,0,
483,8,59,4,0,
483,8,63,4,0,
483,8,70,4,0,
483,8,85,4,0,
483,8,86,4,0,
483,8,87,4,0,
483,8,89,4,0,
483,8,92,4,0,
483,8,104,4,0,
483,8,126,4,0,
483,8,148,4,0,
483,8,156,4,0,
483,8,157,4,0,
483,8,163,1,70,
483,8,164,4,0,
483,8,182,4,0,
483,8,184,1,1,2
483,8,201,4,0,
483,8,203,4,0,
483,8,207,4,0,
483,8,214,4,0,
483,8,216,4,0,
483,8,218,4,0,
483,8,219,4,0,
483,8,225,1,1,1
483,8,231,4,0,
483,8,232,1,10,
483,8,237,4,0,
483,8,240,4,0,
483,8,241,4,0,
483,8,244,4,0,
483,8,246,1,20,
483,8,249,4,0,
483,8,263,4,0,
483,8,280,4,0,
483,8,290,4,0,
483,8,315,4,0,
483,8,317,4,0,
483,8,332,4,0,
483,8,337,1,30,
483,8,337,4,0,
483,8,339,4,0,
483,8,351,4,0,
483,8,363,4,0,
483,8,377,1,50,
483,8,396,1,90,
483,8,406,4,0,
483,8,414,1,60,
483,8,416,4,0,
483,8,421,4,0,
483,8,430,1,80,
483,8,430,4,0,
483,8,433,4,0,
483,8,434,3,0,
483,8,444,4,0,
483,8,446,4,0,
483,8,459,1,40,
483,9,15,4,0,
483,9,46,4,0,
483,9,53,4,0,
483,9,58,4,0,
483,9,59,4,0,
483,9,63,4,0,
483,9,70,4,0,
483,9,85,4,0,
483,9,86,4,0,
483,9,87,4,0,
483,9,89,4,0,
483,9,92,4,0,
483,9,104,4,0,
483,9,126,4,0,
483,9,129,3,0,
483,9,148,4,0,
483,9,156,4,0,
483,9,157,4,0,
483,9,163,1,70,
483,9,164,4,0,
483,9,173,3,0,
483,9,182,4,0,
483,9,184,1,1,2
483,9,189,3,0,
483,9,200,3,0,
483,9,201,4,0,
483,9,203,4,0,
483,9,207,4,0,
483,9,210,3,0,
483,9,214,4,0,
483,9,216,4,0,
483,9,218,4,0,
483,9,219,4,0,
483,9,225,1,1,1
483,9,231,4,0,
483,9,232,1,10,
483,9,237,4,0,
483,9,239,3,0,
483,9,240,4,0,
483,9,241,4,0,
483,9,244,4,0,
483,9,246,1,20,
483,9,246,3,0,
483,9,249,4,0,
483,9,263,4,0,
483,9,280,4,0,
483,9,290,4,0,
483,9,315,4,0,
483,9,317,4,0,
483,9,332,4,0,
483,9,334,3,0,
483,9,337,1,30,
483,9,337,4,0,
483,9,339,4,0,
483,9,351,4,0,
483,9,363,4,0,
483,9,377,1,50,
483,9,393,3,0,
483,9,396,1,90,
483,9,406,4,0,
483,9,414,1,60,
483,9,414,3,0,
483,9,416,4,0,
483,9,421,4,0,
483,9,430,1,80,
483,9,430,4,0,
483,9,433,4,0,
483,9,434,3,0,
483,9,442,3,0,
483,9,444,4,0,
483,9,446,4,0,
483,9,459,1,40,
483,10,15,4,0,
483,10,29,3,0,
483,10,46,4,0,
483,10,53,4,0,
483,10,58,4,0,
483,10,59,4,0,
483,10,63,4,0,
483,10,70,4,0,
483,10,85,4,0,
483,10,86,4,0,
483,10,87,4,0,
483,10,89,4,0,
483,10,92,4,0,
483,10,104,4,0,
483,10,126,4,0,
483,10,129,3,0,
483,10,148,4,0,
483,10,156,4,0,
483,10,157,4,0,
483,10,163,1,15,
483,10,164,4,0,
483,10,173,3,0,
483,10,182,4,0,
483,10,184,1,1,2
483,10,189,3,0,
483,10,200,3,0,
483,10,201,4,0,
483,10,203,4,0,
483,10,207,4,0,
483,10,210,3,0,
483,10,214,4,0,
483,10,216,4,0,
483,10,218,4,0,
483,10,219,4,0,
483,10,225,1,1,1
483,10,231,4,0,
483,10,232,1,6,
483,10,237,4,0,
483,10,239,3,0,
483,10,240,4,0,
483,10,241,4,0,
483,10,244,4,0,
483,10,246,1,10,
483,10,246,3,0,
483,10,249,4,0,
483,10,263,4,0,
483,10,280,4,0,
483,10,290,4,0,
483,10,315,4,0,
483,10,317,4,0,
483,10,332,4,0,
483,10,334,3,0,
483,10,337,1,28,
483,10,337,4,0,
483,10,339,4,0,
483,10,351,4,0,
483,10,356,3,0,
483,10,363,4,0,
483,10,368,1,24,
483,10,393,3,0,
483,10,396,1,37,
483,10,406,4,0,
483,10,408,1,19,
483,10,414,1,33,
483,10,414,3,0,
483,10,416,4,0,
483,10,421,4,0,
483,10,430,1,42,
483,10,430,4,0,
483,10,433,4,0,
483,10,434,3,0,
483,10,442,3,0,
483,10,444,4,0,
483,10,446,4,0,
483,10,459,1,46,
483,11,15,4,0,
483,11,46,4,0,
483,11,53,4,0,
483,11,58,4,0,
483,11,59,4,0,
483,11,63,4,0,
483,11,70,4,0,
483,11,85,4,0,
483,11,86,4,0,
483,11,87,4,0,
483,11,89,4,0,
483,11,92,4,0,
483,11,104,4,0,
483,11,126,4,0,
483,11,148,4,0,
483,11,156,4,0,
483,11,157,4,0,
483,11,163,1,15,
483,11,164,4,0,
483,11,182,4,0,
483,11,184,1,1,2
483,11,201,4,0,
483,11,207,4,0,
483,11,216,4,0,
483,11,218,4,0,
483,11,219,4,0,
483,11,225,1,1,1
483,11,231,1,42,
483,11,232,1,6,
483,11,237,4,0,
483,11,240,4,0,
483,11,241,4,0,
483,11,244,4,0,
483,11,246,1,10,
483,11,249,4,0,
483,11,263,4,0,
483,11,280,4,0,
483,11,315,4,0,
483,11,317,4,0,
483,11,332,4,0,
483,11,337,1,28,
483,11,337,4,0,
483,11,339,4,0,
483,11,368,1,24,
483,11,396,1,37,
483,11,408,1,19,
483,11,414,1,33,
483,11,416,4,0,
483,11,421,4,0,
483,11,430,1,50,
483,11,430,4,0,
483,11,433,4,0,
483,11,434,3,0,
483,11,444,4,0,
483,11,459,1,46,
483,11,468,4,0,
483,11,496,4,0,
483,11,497,4,0,
483,11,510,4,0,
483,11,523,4,0,
483,11,525,4,0,
483,14,15,4,0,
483,14,46,4,0,
483,14,53,4,0,
483,14,58,4,0,
483,14,59,4,0,
483,14,63,4,0,
483,14,70,4,0,
483,14,85,4,0,
483,14,86,4,0,
483,14,87,4,0,
483,14,89,4,0,
483,14,92,4,0,
483,14,104,4,0,
483,14,126,4,0,
483,14,148,4,0,
483,14,156,4,0,
483,14,157,4,0,
483,14,163,1,15,
483,14,164,4,0,
483,14,173,3,0,
483,14,182,4,0,
483,14,184,1,1,2
483,14,200,3,0,
483,14,201,4,0,
483,14,207,4,0,
483,14,214,3,0,
483,14,216,4,0,
483,14,218,4,0,
483,14,219,4,0,
483,14,225,1,1,1
483,14,231,1,42,
483,14,231,3,0,
483,14,232,1,6,
483,14,237,4,0,
483,14,240,4,0,
483,14,241,4,0,
483,14,244,4,0,
483,14,246,1,10,
483,14,249,4,0,
483,14,263,4,0,
483,14,280,4,0,
483,14,304,3,0,
483,14,315,4,0,
483,14,317,4,0,
483,14,332,4,0,
483,14,334,3,0,
483,14,337,1,28,
483,14,337,4,0,
483,14,339,4,0,
483,14,356,3,0,
483,14,368,1,24,
483,14,393,3,0,
483,14,396,1,37,
483,14,406,3,0,
483,14,408,1,19,
483,14,414,1,33,
483,14,414,3,0,
483,14,416,4,0,
483,14,421,4,0,
483,14,430,1,50,
483,14,430,4,0,
483,14,433,4,0,
483,14,434,3,0,
483,14,442,3,0,
483,14,444,4,0,
483,14,446,3,0,
483,14,459,1,46,
483,14,468,4,0,
483,14,496,4,0,
483,14,497,4,0,
483,14,510,4,0,
483,14,523,4,0,
483,14,525,4,0,
483,15,15,4,0,
483,15,46,4,0,
483,15,53,4,0,
483,15,58,4,0,
483,15,59,4,0,
483,15,63,4,0,
483,15,70,4,0,
483,15,85,4,0,
483,15,86,4,0,
483,15,87,4,0,
483,15,89,4,0,
483,15,92,4,0,
483,15,104,4,0,
483,15,126,4,0,
483,15,148,4,0,
483,15,156,4,0,
483,15,157,4,0,
483,15,163,1,15,
483,15,164,4,0,
483,15,182,4,0,
483,15,184,1,1,2
483,15,201,4,0,
483,15,207,4,0,
483,15,214,4,0,
483,15,216,4,0,
483,15,218,4,0,
483,15,219,4,0,
483,15,225,1,1,1
483,15,231,1,42,
483,15,232,1,6,
483,15,237,4,0,
483,15,240,4,0,
483,15,241,4,0,
483,15,244,4,0,
483,15,246,1,10,
483,15,249,4,0,
483,15,263,4,0,
483,15,280,4,0,
483,15,315,4,0,
483,15,317,4,0,
483,15,332,4,0,
483,15,337,1,28,
483,15,337,4,0,
483,15,339,4,0,
483,15,368,1,24,
483,15,396,1,37,
483,15,408,1,19,
483,15,414,1,33,
483,15,416,4,0,
483,15,421,4,0,
483,15,430,1,50,
483,15,430,4,0,
483,15,433,4,0,
483,15,434,3,0,
483,15,444,4,0,
483,15,459,1,46,
483,15,468,4,0,
483,15,496,4,0,
483,15,497,4,0,
483,15,510,4,0,
483,15,523,4,0,
483,15,525,4,0,
483,15,590,4,0,
483,16,15,4,0,
483,16,46,4,0,
483,16,53,4,0,
483,16,58,4,0,
483,16,59,4,0,
483,16,63,4,0,
483,16,70,4,0,
483,16,85,4,0,
483,16,86,4,0,
483,16,87,4,0,
483,16,89,4,0,
483,16,92,4,0,
483,16,104,4,0,
483,16,126,4,0,
483,16,148,4,0,
483,16,156,4,0,
483,16,157,4,0,
483,16,163,1,15,1
483,16,164,4,0,
483,16,173,3,0,
483,16,182,4,0,
483,16,184,1,1,2
483,16,200,3,0,
483,16,201,4,0,
483,16,207,4,0,
483,16,214,4,0,
483,16,216,4,0,
483,16,218,4,0,
483,16,219,4,0,
483,16,225,1,1,1
483,16,231,1,42,1
483,16,231,3,0,
483,16,232,1,6,1
483,16,237,4,0,
483,16,240,4,0,
483,16,241,4,0,
483,16,244,4,0,
483,16,246,1,10,1
483,16,249,4,0,
483,16,263,4,0,
483,16,280,4,0,
483,16,290,4,0,
483,16,304,3,0,
483,16,315,4,0,
483,16,317,4,0,
483,16,332,4,0,
483,16,334,3,0,
483,16,337,1,28,1
483,16,337,4,0,
483,16,339,4,0,
483,16,351,3,0,
483,16,356,3,0,
483,16,368,1,24,1
483,16,393,3,0,
483,16,396,1,37,1
483,16,406,3,0,
483,16,408,1,19,1
483,16,414,1,33,1
483,16,414,3,0,
483,16,416,4,0,
483,16,421,4,0,
483,16,430,1,50,1
483,16,430,4,0,
483,16,433,4,0,
483,16,434,3,0,
483,16,442,3,0,
483,16,444,4,0,
483,16,446,3,0,
483,16,459,1,46,1
483,16,468,4,0,
483,16,496,4,0,
483,16,497,4,0,
483,16,510,4,0,
483,16,523,4,0,
483,16,525,4,0,
483,16,590,4,0,
483,17,46,4,0,
483,17,53,4,0,
483,17,58,4,0,
483,17,59,4,0,
483,17,63,4,0,
483,17,85,4,0,
483,17,86,4,0,
483,17,87,4,0,
483,17,89,4,0,
483,17,92,4,0,
483,17,104,4,0,
483,17,126,4,0,
483,17,156,4,0,
483,17,157,4,0,
483,17,163,1,15,
483,17,164,4,0,
483,17,182,4,0,
483,17,184,1,1,2
483,17,201,4,0,
483,17,207,4,0,
483,17,214,4,0,
483,17,216,4,0,
483,17,218,4,0,
483,17,219,4,0,
483,17,225,1,1,1
483,17,231,1,42,
483,17,232,1,6,
483,17,237,4,0,
483,17,240,4,0,
483,17,241,4,0,
483,17,244,4,0,
483,17,246,1,10,
483,17,263,4,0,
483,17,280,4,0,
483,17,315,4,0,
483,17,317,4,0,
483,17,332,4,0,
483,17,337,1,28,
483,17,337,4,0,
483,17,339,4,0,
483,17,368,1,24,
483,17,396,1,37,
483,17,408,1,19,
483,17,414,1,33,
483,17,416,4,0,
483,17,421,4,0,
483,17,430,1,50,
483,17,430,4,0,
483,17,433,4,0,
483,17,434,3,0,
483,17,444,4,0,
483,17,459,1,46,
483,17,496,4,0,
483,17,497,4,0,
483,17,523,4,0,
483,17,525,4,0,
483,17,590,4,0,
483,18,46,4,0,
483,18,53,4,0,
483,18,58,4,0,
483,18,59,4,0,
483,18,63,4,0,
483,18,85,4,0,
483,18,86,4,0,
483,18,87,4,0,
483,18,89,4,0,
483,18,92,4,0,
483,18,104,4,0,
483,18,126,4,0,
483,18,156,4,0,
483,18,157,4,0,
483,18,163,1,15,
483,18,164,4,0,
483,18,182,4,0,
483,18,184,1,1,2
483,18,201,4,0,
483,18,207,4,0,
483,18,214,4,0,
483,18,216,4,0,
483,18,218,4,0,
483,18,219,4,0,
483,18,225,1,1,1
483,18,231,1,42,
483,18,232,1,6,
483,18,237,4,0,
483,18,240,4,0,
483,18,241,4,0,
483,18,244,4,0,
483,18,246,1,10,
483,18,263,4,0,
483,18,280,4,0,
483,18,315,4,0,
483,18,317,4,0,
483,18,332,4,0,
483,18,337,1,28,
483,18,337,4,0,
483,18,339,4,0,
483,18,368,1,24,
483,18,396,1,37,
483,18,408,1,19,
483,18,414,1,33,
483,18,416,4,0,
483,18,421,4,0,
483,18,430,1,50,
483,18,430,4,0,
483,18,433,4,0,
483,18,434,3,0,
483,18,444,4,0,
483,18,459,1,46,
483,18,496,4,0,
483,18,497,4,0,
483,18,523,4,0,
483,18,525,4,0,
483,18,590,4,0,
484,8,15,4,0,
484,8,46,4,0,
484,8,53,4,0,
484,8,57,4,0,
484,8,58,4,0,
484,8,59,4,0,
484,8,63,4,0,
484,8,70,4,0,
484,8,85,4,0,
484,8,86,4,0,
484,8,87,4,0,
484,8,89,4,0,
484,8,92,4,0,
484,8,104,4,0,
484,8,126,4,0,
484,8,156,4,0,
484,8,157,4,0,
484,8,163,1,70,
484,8,164,4,0,
484,8,182,4,0,
484,8,184,1,1,2
484,8,201,4,0,
484,8,203,4,0,
484,8,207,4,0,
484,8,214,4,0,
484,8,216,4,0,
484,8,218,4,0,
484,8,219,4,0,
484,8,225,1,1,1
484,8,237,4,0,
484,8,240,4,0,
484,8,241,4,0,
484,8,244,4,0,
484,8,246,1,20,
484,8,249,4,0,
484,8,258,4,0,
484,8,263,4,0,
484,8,264,4,0,
484,8,280,4,0,
484,8,290,4,0,
484,8,317,4,0,
484,8,332,4,0,
484,8,337,1,30,
484,8,337,4,0,
484,8,339,4,0,
484,8,351,4,0,
484,8,352,1,10,
484,8,352,4,0,
484,8,362,4,0,
484,8,363,4,0,
484,8,374,4,0,
484,8,377,1,50,
484,8,396,1,90,
484,8,401,1,80,
484,8,406,4,0,
484,8,411,4,0,
484,8,414,1,60,
484,8,416,4,0,
484,8,419,4,0,
484,8,421,4,0,
484,8,433,4,0,
484,8,434,3,0,
484,8,444,4,0,
484,8,460,1,40,
484,9,15,4,0,
484,9,46,4,0,
484,9,53,4,0,
484,9,57,4,0,
484,9,58,4,0,
484,9,59,4,0,
484,9,63,4,0,
484,9,70,4,0,
484,9,85,4,0,
484,9,86,4,0,
484,9,87,4,0,
484,9,89,4,0,
484,9,92,4,0,
484,9,104,4,0,
484,9,126,4,0,
484,9,129,3,0,
484,9,156,4,0,
484,9,157,4,0,
484,9,163,1,70,
484,9,164,4,0,
484,9,173,3,0,
484,9,182,4,0,
484,9,184,1,1,2
484,9,189,3,0,
484,9,200,3,0,
484,9,201,4,0,
484,9,203,4,0,
484,9,207,4,0,
484,9,210,3,0,
484,9,214,4,0,
484,9,216,4,0,
484,9,218,4,0,
484,9,219,4,0,
484,9,225,1,1,1
484,9,237,4,0,
484,9,239,3,0,
484,9,240,4,0,
484,9,241,4,0,
484,9,244,4,0,
484,9,246,1,20,
484,9,246,3,0,
484,9,249,4,0,
484,9,258,4,0,
484,9,263,4,0,
484,9,264,4,0,
484,9,280,4,0,
484,9,290,4,0,
484,9,291,3,0,
484,9,317,4,0,
484,9,332,4,0,
484,9,337,1,30,
484,9,337,4,0,
484,9,339,4,0,
484,9,351,4,0,
484,9,352,1,10,
484,9,352,4,0,
484,9,362,4,0,
484,9,363,4,0,
484,9,374,4,0,
484,9,377,1,50,
484,9,396,1,90,
484,9,401,1,80,
484,9,401,3,0,
484,9,406,4,0,
484,9,411,4,0,
484,9,414,1,60,
484,9,414,3,0,
484,9,416,4,0,
484,9,419,4,0,
484,9,421,4,0,
484,9,433,4,0,
484,9,434,3,0,
484,9,444,4,0,
484,9,460,1,40,
484,10,15,4,0,
484,10,29,3,0,
484,10,46,4,0,
484,10,53,4,0,
484,10,56,1,42,
484,10,57,4,0,
484,10,58,4,0,
484,10,59,4,0,
484,10,63,4,0,
484,10,70,4,0,
484,10,85,4,0,
484,10,86,4,0,
484,10,87,4,0,
484,10,89,4,0,
484,10,92,4,0,
484,10,104,4,0,
484,10,126,4,0,
484,10,129,3,0,
484,10,156,4,0,
484,10,157,4,0,
484,10,163,1,15,
484,10,164,4,0,
484,10,173,3,0,
484,10,182,4,0,
484,10,184,1,1,2
484,10,189,3,0,
484,10,200,3,0,
484,10,201,4,0,
484,10,203,4,0,
484,10,207,4,0,
484,10,210,3,0,
484,10,214,4,0,
484,10,216,4,0,
484,10,218,4,0,
484,10,219,4,0,
484,10,225,1,1,1
484,10,237,4,0,
484,10,239,3,0,
484,10,240,4,0,
484,10,241,4,0,
484,10,244,4,0,
484,10,246,1,10,
484,10,246,3,0,
484,10,249,4,0,
484,10,250,4,0,
484,10,258,4,0,
484,10,263,4,0,
484,10,264,4,0,
484,10,280,4,0,
484,10,290,4,0,
484,10,291,3,0,
484,10,317,4,0,
484,10,332,4,0,
484,10,337,1,28,
484,10,337,4,0,
484,10,339,4,0,
484,10,351,4,0,
484,10,352,1,6,
484,10,352,4,0,
484,10,356,3,0,
484,10,362,4,0,
484,10,363,4,0,
484,10,374,4,0,
484,10,396,1,37,
484,10,401,1,24,
484,10,401,3,0,
484,10,406,4,0,
484,10,408,1,19,
484,10,411,4,0,
484,10,414,1,33,
484,10,414,3,0,
484,10,416,4,0,
484,10,419,4,0,
484,10,421,4,0,
484,10,433,4,0,
484,10,434,3,0,
484,10,444,4,0,
484,10,460,1,46,
484,11,15,4,0,
484,11,46,4,0,
484,11,53,4,0,
484,11,56,1,50,
484,11,57,4,0,
484,11,58,4,0,
484,11,59,4,0,
484,11,63,4,0,
484,11,70,4,0,
484,11,85,4,0,
484,11,86,4,0,
484,11,87,4,0,
484,11,89,4,0,
484,11,92,4,0,
484,11,104,4,0,
484,11,126,4,0,
484,11,156,4,0,
484,11,157,4,0,
484,11,163,1,15,
484,11,164,4,0,
484,11,182,4,0,
484,11,184,1,1,2
484,11,201,4,0,
484,11,207,4,0,
484,11,216,4,0,
484,11,218,4,0,
484,11,219,4,0,
484,11,225,1,1,1
484,11,237,4,0,
484,11,240,4,0,
484,11,241,4,0,
484,11,244,4,0,
484,11,246,1,10,
484,11,249,4,0,
484,11,258,4,0,
484,11,263,4,0,
484,11,280,4,0,
484,11,291,4,0,
484,11,317,4,0,
484,11,332,4,0,
484,11,337,1,28,
484,11,337,4,0,
484,11,339,4,0,
484,11,352,1,6,
484,11,374,4,0,
484,11,396,1,37,
484,11,401,1,24,
484,11,401,1,42,
484,11,408,1,19,
484,11,411,4,0,
484,11,414,1,33,
484,11,416,4,0,
484,11,421,4,0,
484,11,433,4,0,
484,11,434,3,0,
484,11,444,4,0,
484,11,460,1,46,
484,11,468,4,0,
484,11,496,4,0,
484,11,497,4,0,
484,11,510,4,0,
484,11,523,4,0,
484,11,525,4,0,
484,14,15,4,0,
484,14,46,4,0,
484,14,53,4,0,
484,14,56,1,50,
484,14,57,4,0,
484,14,58,4,0,
484,14,59,4,0,
484,14,63,4,0,
484,14,70,4,0,
484,14,85,4,0,
484,14,86,4,0,
484,14,87,4,0,
484,14,89,4,0,
484,14,92,4,0,
484,14,104,4,0,
484,14,126,4,0,
484,14,156,4,0,
484,14,157,4,0,
484,14,163,1,15,
484,14,164,4,0,
484,14,173,3,0,
484,14,182,4,0,
484,14,184,1,1,2
484,14,200,3,0,
484,14,201,4,0,
484,14,207,4,0,
484,14,214,3,0,
484,14,216,4,0,
484,14,218,4,0,
484,14,219,4,0,
484,14,225,1,1,1
484,14,237,4,0,
484,14,240,4,0,
484,14,241,4,0,
484,14,244,4,0,
484,14,246,1,10,
484,14,249,4,0,
484,14,258,4,0,
484,14,263,4,0,
484,14,280,4,0,
484,14,291,4,0,
484,14,304,3,0,
484,14,317,4,0,
484,14,332,4,0,
484,14,337,1,28,
484,14,337,4,0,
484,14,339,4,0,
484,14,352,1,6,
484,14,356,3,0,
484,14,374,4,0,
484,14,396,1,37,
484,14,401,1,24,
484,14,401,1,42,
484,14,401,3,0,
484,14,406,3,0,
484,14,408,1,19,
484,14,411,4,0,
484,14,414,1,33,
484,14,414,3,0,
484,14,416,4,0,
484,14,421,4,0,
484,14,433,4,0,
484,14,434,3,0,
484,14,444,4,0,
484,14,460,1,46,
484,14,468,4,0,
484,14,496,4,0,
484,14,497,4,0,
484,14,510,4,0,
484,14,523,4,0,
484,14,525,4,0,
484,15,15,4,0,
484,15,46,4,0,
484,15,53,4,0,
484,15,56,1,50,
484,15,57,4,0,
484,15,58,4,0,
484,15,59,4,0,
484,15,63,4,0,
484,15,70,4,0,
484,15,85,4,0,
484,15,86,4,0,
484,15,87,4,0,
484,15,89,4,0,
484,15,92,4,0,
484,15,104,4,0,
484,15,126,4,0,
484,15,156,4,0,
484,15,157,4,0,
484,15,163,1,15,
484,15,164,4,0,
484,15,182,4,0,
484,15,184,1,1,2
484,15,201,4,0,
484,15,207,4,0,
484,15,214,4,0,
484,15,216,4,0,
484,15,218,4,0,
484,15,219,4,0,
484,15,225,1,1,1
484,15,237,4,0,
484,15,240,4,0,
484,15,241,4,0,
484,15,244,4,0,
484,15,246,1,10,
484,15,249,4,0,
484,15,258,4,0,
484,15,263,4,0,
484,15,280,4,0,
484,15,317,4,0,
484,15,332,4,0,
484,15,337,1,28,
484,15,337,4,0,
484,15,339,4,0,
484,15,352,1,6,
484,15,374,4,0,
484,15,396,1,37,
484,15,401,1,24,
484,15,401,1,42,
484,15,408,1,19,
484,15,411,4,0,
484,15,414,1,33,
484,15,416,4,0,
484,15,421,4,0,
484,15,433,4,0,
484,15,434,3,0,
484,15,444,4,0,
484,15,460,1,46,
484,15,468,4,0,
484,15,496,4,0,
484,15,497,4,0,
484,15,510,4,0,
484,15,523,4,0,
484,15,525,4,0,
484,15,590,4,0,
484,16,15,4,0,
484,16,46,4,0,
484,16,53,4,0,
484,16,56,1,50,1
484,16,57,4,0,
484,16,58,4,0,
484,16,59,4,0,
484,16,63,4,0,
484,16,70,4,0,
484,16,85,4,0,
484,16,86,4,0,
484,16,87,4,0,
484,16,89,4,0,
484,16,92,4,0,
484,16,104,4,0,
484,16,126,4,0,
484,16,156,4,0,
484,16,157,4,0,
484,16,163,1,15,1
484,16,164,4,0,
484,16,173,3,0,
484,16,182,4,0,
484,16,184,1,1,2
484,16,200,3,0,
484,16,201,4,0,
484,16,207,4,0,
484,16,214,4,0,
484,16,216,4,0,
484,16,218,4,0,
484,16,219,4,0,
484,16,225,1,1,1
484,16,237,4,0,
484,16,240,4,0,
484,16,241,4,0,
484,16,244,4,0,
484,16,246,1,10,1
484,16,249,4,0,
484,16,258,4,0,
484,16,263,4,0,
484,16,264,3,0,
484,16,280,4,0,
484,16,290,4,0,
484,16,291,4,0,
484,16,304,3,0,
484,16,317,4,0,
484,16,332,4,0,
484,16,337,1,28,1
484,16,337,4,0,
484,16,339,4,0,
484,16,351,3,0,
484,16,352,1,6,1
484,16,352,3,0,
484,16,356,3,0,
484,16,374,4,0,
484,16,396,1,37,1
484,16,401,1,24,1
484,16,401,1,42,1
484,16,401,3,0,
484,16,406,3,0,
484,16,408,1,19,1
484,16,411,4,0,
484,16,414,1,33,1
484,16,414,3,0,
484,16,416,4,0,
484,16,421,4,0,
484,16,433,4,0,
484,16,434,3,0,
484,16,444,4,0,
484,16,460,1,46,1
484,16,468,4,0,
484,16,496,4,0,
484,16,497,4,0,
484,16,510,4,0,
484,16,523,4,0,
484,16,525,4,0,
484,16,590,4,0,
484,17,46,4,0,
484,17,53,4,0,
484,17,56,1,50,
484,17,57,4,0,
484,17,58,4,0,
484,17,59,4,0,
484,17,63,4,0,
484,17,85,4,0,
484,17,86,4,0,
484,17,87,4,0,
484,17,89,4,0,
484,17,92,4,0,
484,17,104,4,0,
484,17,126,4,0,
484,17,156,4,0,
484,17,157,4,0,
484,17,163,1,15,
484,17,164,4,0,
484,17,182,4,0,
484,17,184,1,1,2
484,17,201,4,0,
484,17,207,4,0,
484,17,214,4,0,
484,17,216,4,0,
484,17,218,4,0,
484,17,219,4,0,
484,17,225,1,1,1
484,17,237,4,0,
484,17,240,4,0,
484,17,241,4,0,
484,17,244,4,0,
484,17,246,1,10,
484,17,258,4,0,
484,17,263,4,0,
484,17,280,4,0,
484,17,317,4,0,
484,17,332,4,0,
484,17,337,1,28,
484,17,337,4,0,
484,17,339,4,0,
484,17,352,1,6,
484,17,374,4,0,
484,17,396,1,37,
484,17,401,1,24,
484,17,401,1,42,
484,17,408,1,19,
484,17,411,4,0,
484,17,414,1,33,
484,17,416,4,0,
484,17,421,4,0,
484,17,433,4,0,
484,17,434,3,0,
484,17,444,4,0,
484,17,460,1,46,
484,17,496,4,0,
484,17,497,4,0,
484,17,523,4,0,
484,17,525,4,0,
484,17,590,4,0,
484,18,46,4,0,
484,18,53,4,0,
484,18,56,1,50,
484,18,57,4,0,
484,18,58,4,0,
484,18,59,4,0,
484,18,63,4,0,
484,18,85,4,0,
484,18,86,4,0,
484,18,87,4,0,
484,18,89,4,0,
484,18,92,4,0,
484,18,104,4,0,
484,18,126,4,0,
484,18,156,4,0,
484,18,157,4,0,
484,18,163,1,15,
484,18,164,4,0,
484,18,182,4,0,
484,18,184,1,1,2
484,18,201,4,0,
484,18,207,4,0,
484,18,214,4,0,
484,18,216,4,0,
484,18,218,4,0,
484,18,219,4,0,
484,18,225,1,1,1
484,18,237,4,0,
484,18,240,4,0,
484,18,241,4,0,
484,18,244,4,0,
484,18,246,1,10,
484,18,258,4,0,
484,18,263,4,0,
484,18,280,4,0,
484,18,317,4,0,
484,18,332,4,0,
484,18,337,1,28,
484,18,337,4,0,
484,18,339,4,0,
484,18,352,1,6,
484,18,374,4,0,
484,18,392,1,24,
484,18,396,1,37,
484,18,401,1,42,
484,18,408,1,19,
484,18,411,4,0,
484,18,414,1,33,
484,18,416,4,0,
484,18,421,4,0,
484,18,433,4,0,
484,18,434,3,0,
484,18,444,4,0,
484,18,460,1,46,
484,18,496,4,0,
484,18,497,4,0,
484,18,523,4,0,
484,18,525,4,0,
484,18,590,4,0,
485,8,43,1,9,
485,8,46,4,0,
485,8,53,4,0,
485,8,63,4,0,
485,8,70,4,0,
485,8,76,4,0,
485,8,83,1,57,
485,8,89,4,0,
485,8,91,4,0,
485,8,92,4,0,
485,8,104,4,0,
485,8,126,4,0,
485,8,153,4,0,
485,8,156,4,0,
485,8,157,4,0,
485,8,164,4,0,
485,8,182,4,0,
485,8,184,1,41,
485,8,203,4,0,
485,8,207,4,0,
485,8,213,4,0,
485,8,214,4,0,
485,8,216,4,0,
485,8,218,4,0,
485,8,237,4,0,
485,8,241,4,0,
485,8,242,1,33,
485,8,246,1,1,
485,8,249,4,0,
485,8,257,1,81,
485,8,259,4,0,
485,8,261,4,0,
485,8,263,4,0,
485,8,269,4,0,
485,8,290,4,0,
485,8,315,4,0,
485,8,317,4,0,
485,8,319,1,25,
485,8,363,4,0,
485,8,371,4,0,
485,8,399,4,0,
485,8,406,4,0,
485,8,414,1,73,
485,8,416,4,0,
485,8,424,1,17,
485,8,430,4,0,
485,8,431,4,0,
485,8,436,1,49,
485,8,442,1,65,
485,8,444,1,88,
485,8,444,4,0,
485,8,445,4,0,
485,8,446,4,0,
485,8,463,1,96,
485,9,43,1,9,
485,9,46,4,0,
485,9,53,4,0,
485,9,63,4,0,
485,9,70,4,0,
485,9,76,4,0,
485,9,83,1,57,
485,9,89,4,0,
485,9,91,4,0,
485,9,92,4,0,
485,9,104,4,0,
485,9,126,4,0,
485,9,153,4,0,
485,9,156,4,0,
485,9,157,4,0,
485,9,164,4,0,
485,9,173,3,0,
485,9,182,4,0,
485,9,184,1,41,
485,9,189,3,0,
485,9,203,4,0,
485,9,207,4,0,
485,9,213,4,0,
485,9,214,4,0,
485,9,216,4,0,
485,9,218,4,0,
485,9,237,4,0,
485,9,241,4,0,
485,9,242,1,33,
485,9,246,1,1,
485,9,246,3,0,
485,9,249,4,0,
485,9,253,3,0,
485,9,257,1,81,
485,9,257,3,0,
485,9,259,4,0,
485,9,261,4,0,
485,9,263,4,0,
485,9,269,4,0,
485,9,290,4,0,
485,9,315,4,0,
485,9,317,4,0,
485,9,319,1,25,
485,9,334,3,0,
485,9,363,4,0,
485,9,371,4,0,
485,9,399,4,0,
485,9,406,4,0,
485,9,414,1,73,
485,9,414,3,0,
485,9,416,4,0,
485,9,424,1,17,
485,9,430,4,0,
485,9,431,4,0,
485,9,436,1,49,
485,9,442,1,65,
485,9,442,3,0,
485,9,444,1,88,
485,9,444,4,0,
485,9,445,4,0,
485,9,446,4,0,
485,9,463,1,96,
485,10,29,3,0,
485,10,43,1,9,
485,10,46,4,0,
485,10,53,4,0,
485,10,63,4,0,
485,10,70,4,0,
485,10,76,4,0,
485,10,83,1,57,
485,10,89,4,0,
485,10,91,4,0,
485,10,92,4,0,
485,10,104,4,0,
485,10,126,4,0,
485,10,153,4,0,
485,10,156,4,0,
485,10,157,4,0,
485,10,164,4,0,
485,10,173,3,0,
485,10,182,4,0,
485,10,184,1,41,
485,10,189,3,0,
485,10,203,4,0,
485,10,207,4,0,
485,10,213,4,0,
485,10,214,4,0,
485,10,216,4,0,
485,10,218,4,0,
485,10,237,4,0,
485,10,241,4,0,
485,10,242,1,33,
485,10,246,1,1,
485,10,246,3,0,
485,10,249,4,0,
485,10,253,3,0,
485,10,257,1,81,
485,10,257,3,0,
485,10,259,4,0,
485,10,261,4,0,
485,10,263,4,0,
485,10,269,4,0,
485,10,290,4,0,
485,10,315,4,0,
485,10,317,4,0,
485,10,319,1,25,
485,10,334,3,0,
485,10,363,4,0,
485,10,371,4,0,
485,10,399,4,0,
485,10,406,4,0,
485,10,414,1,73,
485,10,414,3,0,
485,10,416,4,0,
485,10,424,1,17,
485,10,430,4,0,
485,10,431,4,0,
485,10,436,1,49,
485,10,442,1,65,
485,10,442,3,0,
485,10,444,1,88,
485,10,444,4,0,
485,10,445,4,0,
485,10,446,4,0,
485,10,450,3,0,
485,10,463,1,96,
485,11,43,1,9,
485,11,46,4,0,
485,11,53,4,0,
485,11,63,4,0,
485,11,70,4,0,
485,11,76,4,0,
485,11,83,1,57,
485,11,89,4,0,
485,11,91,4,0,
485,11,92,4,0,
485,11,104,4,0,
485,11,126,4,0,
485,11,153,4,0,
485,11,156,4,0,
485,11,157,4,0,
485,11,164,4,0,
485,11,182,4,0,
485,11,184,1,41,
485,11,207,4,0,
485,11,213,4,0,
485,11,216,4,0,
485,11,218,4,0,
485,11,237,4,0,
485,11,241,4,0,
485,11,242,1,33,
485,11,246,1,1,
485,11,249,4,0,
485,11,257,1,81,
485,11,259,4,0,
485,11,261,4,0,
485,11,263,4,0,
485,11,269,4,0,
485,11,315,4,0,
485,11,317,4,0,
485,11,319,1,25,
485,11,371,4,0,
485,11,414,1,73,
485,11,416,4,0,
485,11,424,1,17,
485,11,430,4,0,
485,11,436,1,49,
485,11,442,1,65,
485,11,444,1,88,
485,11,444,4,0,
485,11,463,1,96,
485,11,488,4,0,
485,11,496,4,0,
485,11,510,4,0,
485,11,523,4,0,
485,14,43,1,9,
485,14,46,4,0,
485,14,53,4,0,
485,14,63,4,0,
485,14,70,4,0,
485,14,76,4,0,
485,14,83,1,57,
485,14,89,4,0,
485,14,91,4,0,
485,14,92,4,0,
485,14,104,4,0,
485,14,126,4,0,
485,14,153,4,0,
485,14,156,4,0,
485,14,157,4,0,
485,14,164,4,0,
485,14,173,3,0,
485,14,182,4,0,
485,14,184,1,41,
485,14,207,4,0,
485,14,213,4,0,
485,14,214,3,0,
485,14,216,4,0,
485,14,218,4,0,
485,14,237,4,0,
485,14,241,4,0,
485,14,242,1,33,
485,14,246,1,1,
485,14,249,4,0,
485,14,253,3,0,
485,14,257,1,81,
485,14,257,3,0,
485,14,259,4,0,
485,14,261,4,0,
485,14,263,4,0,
485,14,269,4,0,
485,14,315,4,0,
485,14,317,4,0,
485,14,319,1,25,
485,14,334,3,0,
485,14,371,4,0,
485,14,399,3,0,
485,14,406,3,0,
485,14,414,1,73,
485,14,414,3,0,
485,14,416,4,0,
485,14,424,1,17,
485,14,430,4,0,
485,14,436,1,49,
485,14,442,1,65,
485,14,442,3,0,
485,14,444,1,88,
485,14,444,4,0,
485,14,446,3,0,
485,14,450,3,0,
485,14,463,1,96,
485,14,488,4,0,
485,14,496,4,0,
485,14,510,4,0,
485,14,523,4,0,
485,15,43,1,9,
485,15,46,4,0,
485,15,53,4,0,
485,15,63,4,0,
485,15,70,4,0,
485,15,76,4,0,
485,15,83,1,1,5
485,15,83,1,57,
485,15,89,4,0,
485,15,91,4,0,
485,15,92,4,0,
485,15,104,4,0,
485,15,126,4,0,
485,15,153,4,0,
485,15,156,4,0,
485,15,157,4,0,
485,15,164,4,0,
485,15,182,4,0,
485,15,184,1,41,
485,15,207,4,0,
485,15,213,4,0,
485,15,214,4,0,
485,15,216,4,0,
485,15,218,4,0,
485,15,237,4,0,
485,15,241,4,0,
485,15,242,1,33,
485,15,246,1,1,6
485,15,249,4,0,
485,15,257,1,1,2
485,15,257,1,81,
485,15,259,4,0,
485,15,261,4,0,
485,15,263,4,0,
485,15,267,4,0,
485,15,269,4,0,
485,15,315,4,0,
485,15,317,4,0,
485,15,319,1,25,
485,15,371,4,0,
485,15,399,4,0,
485,15,414,1,1,3
485,15,414,1,73,
485,15,416,4,0,
485,15,424,1,17,
485,15,430,4,0,
485,15,436,1,49,
485,15,442,1,1,4
485,15,442,1,65,
485,15,444,1,88,
485,15,444,4,0,
485,15,463,1,1,1
485,15,463,1,96,
485,15,488,4,0,
485,15,496,4,0,
485,15,510,4,0,
485,15,523,4,0,
485,15,590,4,0,
485,16,43,1,9,1
485,16,46,4,0,
485,16,53,4,0,
485,16,63,4,0,
485,16,70,4,0,
485,16,76,4,0,
485,16,83,1,1,5
485,16,83,1,57,1
485,16,89,4,0,
485,16,91,4,0,
485,16,92,4,0,
485,16,104,4,0,
485,16,126,4,0,
485,16,153,4,0,
485,16,156,4,0,
485,16,157,4,0,
485,16,164,4,0,
485,16,173,3,0,
485,16,182,4,0,
485,16,184,1,41,1
485,16,207,4,0,
485,16,213,4,0,
485,16,214,4,0,
485,16,216,4,0,
485,16,218,4,0,
485,16,237,4,0,
485,16,241,4,0,
485,16,242,1,33,1
485,16,246,1,1,6
485,16,249,4,0,
485,16,253,3,0,
485,16,257,1,1,2
485,16,257,1,81,1
485,16,257,3,0,
485,16,259,4,0,
485,16,261,4,0,
485,16,263,4,0,
485,16,267,4,0,
485,16,269,4,0,
485,16,290,4,0,
485,16,315,4,0,
485,16,317,4,0,
485,16,319,1,25,1
485,16,334,3,0,
485,16,371,4,0,
485,16,399,4,0,
485,16,406,3,0,
485,16,414,1,1,3
485,16,414,1,73,1
485,16,414,3,0,
485,16,416,4,0,
485,16,424,1,17,1
485,16,430,4,0,
485,16,436,1,49,1
485,16,442,1,1,4
485,16,442,1,65,1
485,16,442,3,0,
485,16,444,1,88,1
485,16,444,4,0,
485,16,446,3,0,
485,16,450,3,0,
485,16,463,1,1,1
485,16,463,1,96,1
485,16,488,4,0,
485,16,496,4,0,
485,16,510,4,0,
485,16,523,4,0,
485,16,590,4,0,
485,17,43,1,9,
485,17,46,4,0,
485,17,53,4,0,
485,17,63,4,0,
485,17,76,4,0,
485,17,83,1,1,5
485,17,83,1,57,
485,17,89,4,0,
485,17,92,4,0,
485,17,104,4,0,
485,17,126,4,0,
485,17,153,4,0,
485,17,156,4,0,
485,17,157,4,0,
485,17,164,4,0,
485,17,182,4,0,
485,17,184,1,41,
485,17,207,4,0,
485,17,213,4,0,
485,17,214,4,0,
485,17,216,4,0,
485,17,218,4,0,
485,17,237,4,0,
485,17,241,4,0,
485,17,242,1,33,
485,17,246,1,1,6
485,17,257,1,1,2
485,17,257,1,81,
485,17,259,4,0,
485,17,261,4,0,
485,17,263,4,0,
485,17,267,4,0,
485,17,269,4,0,
485,17,315,4,0,
485,17,317,4,0,
485,17,319,1,25,
485,17,371,4,0,
485,17,399,4,0,
485,17,414,1,1,3
485,17,414,1,73,
485,17,416,4,0,
485,17,424,1,17,
485,17,430,4,0,
485,17,436,1,49,
485,17,442,1,1,4
485,17,442,1,65,
485,17,444,1,88,
485,17,444,4,0,
485,17,463,1,1,1
485,17,463,1,96,
485,17,488,4,0,
485,17,496,4,0,
485,17,523,4,0,
485,17,590,4,0,
485,18,43,1,9,
485,18,46,4,0,
485,18,53,4,0,
485,18,63,4,0,
485,18,76,4,0,
485,18,83,1,1,5
485,18,83,1,57,
485,18,89,4,0,
485,18,92,4,0,
485,18,104,4,0,
485,18,126,4,0,
485,18,153,4,0,
485,18,156,4,0,
485,18,157,4,0,
485,18,164,4,0,
485,18,182,4,0,
485,18,184,1,41,
485,18,207,4,0,
485,18,213,4,0,
485,18,214,4,0,
485,18,216,4,0,
485,18,218,4,0,
485,18,237,4,0,
485,18,241,4,0,
485,18,242,1,33,
485,18,246,1,1,6
485,18,257,1,1,2
485,18,257,1,81,
485,18,259,4,0,
485,18,261,4,0,
485,18,263,4,0,
485,18,267,4,0,
485,18,269,4,0,
485,18,315,4,0,
485,18,317,4,0,
485,18,319,1,25,
485,18,371,4,0,
485,18,399,4,0,
485,18,414,1,1,3
485,18,414,1,73,
485,18,416,4,0,
485,18,424,1,17,
485,18,430,4,0,
485,18,436,1,49,
485,18,442,1,1,4
485,18,442,1,65,
485,18,444,1,88,
485,18,444,4,0,
485,18,463,1,1,1
485,18,463,1,96,
485,18,488,4,0,
485,18,496,4,0,
485,18,523,4,0,
485,18,590,4,0,
486,8,5,1,1,4
486,8,7,1,1,1
486,8,8,1,1,2
486,8,9,1,1,3
486,8,23,1,1,7
486,8,63,4,0,
486,8,70,4,0,
486,8,85,4,0,
486,8,86,4,0,
486,8,87,4,0,
486,8,89,4,0,
486,8,92,4,0,
486,8,104,4,0,
486,8,109,1,1,6
486,8,157,4,0,
486,8,164,4,0,
486,8,203,4,0,
486,8,207,4,0,
486,8,214,4,0,
486,8,216,4,0,
486,8,218,4,0,
486,8,219,4,0,
486,8,237,4,0,
486,8,240,4,0,
486,8,241,4,0,
486,8,244,4,0,
486,8,249,4,0,
486,8,263,4,0,
486,8,264,4,0,
486,8,276,1,25,
486,8,280,4,0,
486,8,282,1,1,5
486,8,290,4,0,
486,8,317,4,0,
486,8,332,4,0,
486,8,351,4,0,
486,8,363,4,0,
486,8,374,4,0,
486,8,397,4,0,
486,8,409,4,0,
486,8,411,4,0,
486,8,416,1,100,
486,8,416,4,0,
486,8,419,4,0,
486,8,428,1,50,
486,8,431,4,0,
486,8,444,4,0,
486,8,462,1,75,
486,9,7,1,1,1
486,9,7,3,0,
486,9,8,1,1,2
486,9,8,3,0,
486,9,9,1,1,3
486,9,9,3,0,
486,9,63,4,0,
486,9,70,4,0,
486,9,85,4,0,
486,9,86,4,0,
486,9,87,4,0,
486,9,89,4,0,
486,9,92,4,0,
486,9,104,4,0,
486,9,109,1,1,6
486,9,146,1,1,4
486,9,157,4,0,
486,9,164,4,0,
486,9,173,3,0,
486,9,189,3,0,
486,9,193,1,1,7
486,9,196,3,0,
486,9,203,4,0,
486,9,207,4,0,
486,9,214,4,0,
486,9,216,4,0,
486,9,218,4,0,
486,9,219,4,0,
486,9,237,4,0,
486,9,240,4,0,
486,9,241,4,0,
486,9,244,4,0,
486,9,246,3,0,
486,9,249,4,0,
486,9,263,4,0,
486,9,264,4,0,
486,9,276,3,0,
486,9,279,1,25,
486,9,280,4,0,
486,9,282,1,1,5
486,9,290,4,0,
486,9,317,4,0,
486,9,332,4,0,
486,9,351,4,0,
486,9,363,4,0,
486,9,374,4,0,
486,9,397,4,0,
486,9,409,4,0,
486,9,411,4,0,
486,9,414,3,0,
486,9,416,1,100,
486,9,416,4,0,
486,9,419,4,0,
486,9,428,1,50,
486,9,428,3,0,
486,9,431,4,0,
486,9,442,3,0,
486,9,444,4,0,
486,9,462,1,75,
486,10,7,1,1,1
486,10,7,3,0,
486,10,8,1,1,2
486,10,8,3,0,
486,10,9,1,1,3
486,10,9,3,0,
486,10,29,3,0,
486,10,63,4,0,
486,10,70,4,0,
486,10,85,4,0,
486,10,86,4,0,
486,10,87,4,0,
486,10,89,4,0,
486,10,92,4,0,
486,10,104,4,0,
486,10,109,1,1,6
486,10,146,1,1,4
486,10,157,4,0,
486,10,164,4,0,
486,10,173,3,0,
486,10,189,3,0,
486,10,193,1,1,7
486,10,196,3,0,
486,10,203,4,0,
486,10,207,4,0,
486,10,214,4,0,
486,10,216,4,0,
486,10,218,4,0,
486,10,219,4,0,
486,10,237,4,0,
486,10,240,4,0,
486,10,241,4,0,
486,10,244,4,0,
486,10,246,3,0,
486,10,249,4,0,
486,10,263,4,0,
486,10,264,4,0,
486,10,276,3,0,
486,10,279,1,25,
486,10,280,4,0,
486,10,282,1,1,5
486,10,290,4,0,
486,10,317,4,0,
486,10,332,4,0,
486,10,335,3,0,
486,10,351,4,0,
486,10,356,3,0,
486,10,363,4,0,
486,10,374,4,0,
486,10,397,4,0,
486,10,409,4,0,
486,10,411,4,0,
486,10,414,3,0,
486,10,416,1,100,
486,10,416,4,0,
486,10,419,4,0,
486,10,428,1,50,
486,10,428,3,0,
486,10,431,4,0,
486,10,442,3,0,
486,10,444,4,0,
486,10,462,1,75,
486,11,7,1,1,1
486,11,8,1,1,2
486,11,9,1,1,3
486,11,63,4,0,
486,11,70,4,0,
486,11,85,4,0,
486,11,86,4,0,
486,11,87,4,0,
486,11,89,4,0,
486,11,92,4,0,
486,11,104,4,0,
486,11,109,1,1,6
486,11,146,1,1,4
486,11,157,4,0,
486,11,164,4,0,
486,11,193,1,1,7
486,11,207,4,0,
486,11,216,4,0,
486,11,218,4,0,
486,11,219,4,0,
486,11,237,4,0,
486,11,240,4,0,
486,11,241,4,0,
486,11,244,4,0,
486,11,249,4,0,
486,11,263,4,0,
486,11,279,1,25,
486,11,280,4,0,
486,11,282,1,1,5
486,11,317,4,0,
486,11,332,4,0,
486,11,371,1,65,
486,11,371,4,0,
486,11,374,4,0,
486,11,397,4,0,
486,11,411,4,0,
486,11,416,1,100,
486,11,416,4,0,
486,11,428,1,50,
486,11,444,4,0,
486,11,462,1,75,
486,11,469,1,40,
486,11,479,4,0,
486,11,484,1,90,
486,11,496,4,0,
486,11,514,4,0,
486,11,523,4,0,
486,14,7,1,1,1
486,14,7,3,0,
486,14,8,1,1,2
486,14,8,3,0,
486,14,9,1,1,3
486,14,9,3,0,
486,14,63,4,0,
486,14,70,4,0,
486,14,85,4,0,
486,14,86,4,0,
486,14,87,4,0,
486,14,89,4,0,
486,14,92,4,0,
486,14,104,4,0,
486,14,109,1,1,6
486,14,146,1,1,4
486,14,157,4,0,
486,14,164,4,0,
486,14,173,3,0,
486,14,193,1,1,7
486,14,196,3,0,
486,14,207,4,0,
486,14,214,3,0,
486,14,216,4,0,
486,14,218,4,0,
486,14,219,4,0,
486,14,237,4,0,
486,14,240,4,0,
486,14,241,4,0,
486,14,244,4,0,
486,14,249,4,0,
486,14,263,4,0,
486,14,276,3,0,
486,14,279,1,25,
486,14,280,4,0,
486,14,282,1,1,5
486,14,282,3,0,
486,14,317,4,0,
486,14,332,4,0,
486,14,335,3,0,
486,14,356,3,0,
486,14,371,1,65,
486,14,371,4,0,
486,14,374,4,0,
486,14,397,4,0,
486,14,409,3,0,
486,14,411,4,0,
486,14,414,3,0,
486,14,416,1,100,
486,14,416,4,0,
486,14,428,1,50,
486,14,428,3,0,
486,14,442,3,0,
486,14,444,4,0,
486,14,462,1,75,
486,14,469,1,40,
486,14,479,4,0,
486,14,484,1,90,
486,14,496,4,0,
486,14,514,4,0,
486,14,523,4,0,
486,15,7,1,1,3
486,15,8,1,1,4
486,15,9,1,1,5
486,15,63,4,0,
486,15,70,4,0,
486,15,85,4,0,
486,15,86,4,0,
486,15,87,4,0,
486,15,89,4,0,
486,15,92,4,0,
486,15,104,4,0,
486,15,109,1,1,8
486,15,146,1,1,6
486,15,157,4,0,
486,15,164,4,0,
486,15,193,1,1,9
486,15,207,4,0,
486,15,214,4,0,
486,15,216,4,0,
486,15,218,4,0,
486,15,219,4,0,
486,15,237,4,0,
486,15,240,4,0,
486,15,241,4,0,
486,15,244,4,0,
486,15,249,4,0,
486,15,263,4,0,
486,15,267,4,0,
486,15,279,1,25,
486,15,280,4,0,
486,15,282,1,1,7
486,15,317,4,0,
486,15,332,4,0,
486,15,371,1,65,
486,15,371,4,0,
486,15,374,4,0,
486,15,397,4,0,
486,15,411,4,0,
486,15,416,1,100,
486,15,416,4,0,
486,15,428,1,50,
486,15,444,4,0,
486,15,462,1,1,2
486,15,462,1,75,
486,15,469,1,40,
486,15,479,4,0,
486,15,484,1,1,1
486,15,484,1,90,
486,15,496,4,0,
486,15,514,4,0,
486,15,523,4,0,
486,15,590,4,0,
486,15,612,4,0,
486,16,7,1,1,3
486,16,7,3,0,
486,16,8,1,1,4
486,16,8,3,0,
486,16,9,1,1,5
486,16,9,3,0,
486,16,63,4,0,
486,16,70,4,0,
486,16,85,4,0,
486,16,86,4,0,
486,16,87,4,0,
486,16,89,4,0,
486,16,92,4,0,
486,16,104,4,0,
486,16,109,1,1,8
486,16,146,1,1,6
486,16,157,4,0,
486,16,164,4,0,
486,16,173,3,0,
486,16,193,1,1,9
486,16,196,3,0,
486,16,207,4,0,
486,16,214,4,0,
486,16,216,4,0,
486,16,218,4,0,
486,16,219,4,0,
486,16,237,4,0,
486,16,240,4,0,
486,16,241,4,0,
486,16,244,4,0,
486,16,249,4,0,
486,16,263,4,0,
486,16,264,3,0,
486,16,267,4,0,
486,16,276,3,0,
486,16,279,1,25,1
486,16,280,4,0,
486,16,282,1,1,7
486,16,282,3,0,
486,16,290,4,0,
486,16,317,4,0,
486,16,332,4,0,
486,16,335,3,0,
486,16,351,3,0,
486,16,356,3,0,
486,16,371,1,65,1
486,16,371,4,0,
486,16,374,4,0,
486,16,397,4,0,
486,16,409,3,0,
486,16,411,4,0,
486,16,414,3,0,
486,16,416,1,100,1
486,16,416,4,0,
486,16,428,1,50,1
486,16,428,3,0,
486,16,442,3,0,
486,16,444,4,0,
486,16,462,1,1,2
486,16,462,1,75,1
486,16,469,1,40,1
486,16,479,4,0,
486,16,484,1,1,1
486,16,484,1,90,1
486,16,496,4,0,
486,16,514,4,0,
486,16,523,4,0,
486,16,590,4,0,
486,16,612,4,0,
486,17,7,1,1,3
486,17,8,1,1,4
486,17,9,1,1,5
486,17,63,4,0,
486,17,85,4,0,
486,17,86,4,0,
486,17,87,4,0,
486,17,89,4,0,
486,17,92,4,0,
486,17,104,4,0,
486,17,109,1,1,8
486,17,146,1,1,6
486,17,157,4,0,
486,17,164,4,0,
486,17,193,1,1,9
486,17,207,4,0,
486,17,214,4,0,
486,17,216,4,0,
486,17,218,4,0,
486,17,219,4,0,
486,17,237,4,0,
486,17,240,4,0,
486,17,241,4,0,
486,17,244,4,0,
486,17,263,4,0,
486,17,267,4,0,
486,17,279,1,25,
486,17,280,4,0,
486,17,282,1,1,7
486,17,317,4,0,
486,17,332,4,0,
486,17,371,1,65,
486,17,371,4,0,
486,17,374,4,0,
486,17,397,4,0,
486,17,411,4,0,
486,17,416,1,100,
486,17,416,4,0,
486,17,428,1,50,
486,17,444,4,0,
486,17,462,1,1,2
486,17,462,1,75,
486,17,469,1,40,
486,17,479,4,0,
486,17,484,1,1,1
486,17,484,1,90,
486,17,496,4,0,
486,17,523,4,0,
486,17,590,4,0,
486,18,7,1,1,3
486,18,8,1,1,4
486,18,9,1,1,5
486,18,63,4,0,
486,18,85,4,0,
486,18,86,4,0,
486,18,87,4,0,
486,18,89,4,0,
486,18,92,4,0,
486,18,104,4,0,
486,18,109,1,1,8
486,18,146,1,1,6
486,18,157,4,0,
486,18,164,4,0,
486,18,193,1,1,9
486,18,207,4,0,
486,18,214,4,0,
486,18,216,4,0,
486,18,218,4,0,
486,18,219,4,0,
486,18,237,4,0,
486,18,240,4,0,
486,18,241,4,0,
486,18,244,4,0,
486,18,263,4,0,
486,18,267,4,0,
486,18,279,1,25,
486,18,280,4,0,
486,18,282,1,1,7
486,18,317,4,0,
486,18,332,4,0,
486,18,371,1,65,
486,18,371,4,0,
486,18,374,4,0,
486,18,397,4,0,
486,18,411,4,0,
486,18,416,1,100,
486,18,416,4,0,
486,18,428,1,50,
486,18,444,4,0,
486,18,462,1,1,2
486,18,462,1,75,
486,18,469,1,40,
486,18,479,4,0,
486,18,484,1,1,1
486,18,484,1,90,
486,18,496,4,0,
486,18,523,4,0,
486,18,590,4,0,
487,8,15,4,0,
487,8,19,4,0,
487,8,46,4,0,
487,8,63,4,0,
487,8,70,4,0,
487,8,85,4,0,
487,8,86,4,0,
487,8,87,4,0,
487,8,89,4,0,
487,8,92,4,0,
487,8,94,4,0,
487,8,104,4,0,
487,8,138,4,0,
487,8,156,4,0,
487,8,163,1,70,
487,8,164,4,0,
487,8,182,4,0,
487,8,184,1,1,2
487,8,203,4,0,
487,8,207,4,0,
487,8,211,4,0,
487,8,214,4,0,
487,8,216,4,0,
487,8,218,4,0,
487,8,219,4,0,
487,8,225,1,1,1
487,8,231,4,0,
487,8,237,4,0,
487,8,240,4,0,
487,8,241,4,0,
487,8,244,4,0,
487,8,246,1,20,
487,8,247,4,0,
487,8,249,4,0,
487,8,261,4,0,
487,8,263,4,0,
487,8,290,4,0,
487,8,318,4,0,
487,8,332,4,0,
487,8,337,1,30,
487,8,337,4,0,
487,8,347,4,0,
487,8,351,4,0,
487,8,363,4,0,
487,8,371,4,0,
487,8,377,1,50,
487,8,396,1,90,
487,8,399,4,0,
487,8,406,4,0,
487,8,412,4,0,
487,8,414,1,60,
487,8,416,4,0,
487,8,421,1,80,
487,8,421,4,0,
487,8,431,4,0,
487,8,432,4,0,
487,8,434,3,0,
487,8,444,4,0,
487,8,451,4,0,
487,8,466,1,10,
487,8,467,1,40,
487,9,15,4,0,
487,9,19,4,0,
487,9,46,4,0,
487,9,63,4,0,
487,9,70,4,0,
487,9,85,4,0,
487,9,86,4,0,
487,9,87,4,0,
487,9,89,4,0,
487,9,92,4,0,
487,9,94,4,0,
487,9,104,4,0,
487,9,129,3,0,
487,9,138,4,0,
487,9,156,4,0,
487,9,163,1,70,
487,9,164,4,0,
487,9,173,3,0,
487,9,180,3,0,
487,9,182,4,0,
487,9,184,1,1,2
487,9,189,3,0,
487,9,196,3,0,
487,9,200,3,0,
487,9,203,4,0,
487,9,207,4,0,
487,9,210,3,0,
487,9,211,4,0,
487,9,214,4,0,
487,9,216,4,0,
487,9,218,4,0,
487,9,219,4,0,
487,9,225,1,1,1
487,9,231,4,0,
487,9,237,4,0,
487,9,239,3,0,
487,9,240,4,0,
487,9,241,4,0,
487,9,244,4,0,
487,9,246,1,20,
487,9,246,3,0,
487,9,247,4,0,
487,9,249,4,0,
487,9,261,4,0,
487,9,263,4,0,
487,9,290,4,0,
487,9,314,3,0,
487,9,318,4,0,
487,9,332,4,0,
487,9,337,1,30,
487,9,337,4,0,
487,9,347,4,0,
487,9,351,4,0,
487,9,363,4,0,
487,9,371,4,0,
487,9,377,1,50,
487,9,396,1,90,
487,9,399,4,0,
487,9,401,3,0,
487,9,406,4,0,
487,9,412,4,0,
487,9,414,1,60,
487,9,414,3,0,
487,9,416,4,0,
487,9,421,1,80,
487,9,421,4,0,
487,9,431,4,0,
487,9,432,4,0,
487,9,434,3,0,
487,9,442,3,0,
487,9,444,4,0,
487,9,451,4,0,
487,9,466,1,10,
487,9,466,3,0,
487,9,467,1,40,
487,10,15,4,0,
487,10,19,4,0,
487,10,29,3,0,
487,10,46,4,0,
487,10,63,4,0,
487,10,70,4,0,
487,10,85,4,0,
487,10,86,4,0,
487,10,87,4,0,
487,10,89,4,0,
487,10,92,4,0,
487,10,94,4,0,
487,10,104,4,0,
487,10,129,3,0,
487,10,138,4,0,
487,10,156,4,0,
487,10,163,1,15,
487,10,164,4,0,
487,10,173,3,0,
487,10,180,3,0,
487,10,182,4,0,
487,10,184,1,1,2
487,10,189,3,0,
487,10,194,1,24,
487,10,196,3,0,
487,10,200,3,0,
487,10,203,4,0,
487,10,207,4,0,
487,10,210,3,0,
487,10,211,4,0,
487,10,214,4,0,
487,10,216,4,0,
487,10,218,4,0,
487,10,219,4,0,
487,10,220,3,0,
487,10,225,1,1,1
487,10,231,4,0,
487,10,237,4,0,
487,10,239,3,0,
487,10,240,4,0,
487,10,241,4,0,
487,10,244,4,0,
487,10,246,1,10,
487,10,246,3,0,
487,10,247,4,0,
487,10,249,4,0,
487,10,261,4,0,
487,10,263,4,0,
487,10,290,4,0,
487,10,314,3,0,
487,10,318,4,0,
487,10,332,4,0,
487,10,337,1,28,
487,10,337,4,0,
487,10,347,4,0,
487,10,351,4,0,
487,10,356,3,0,
487,10,363,4,0,
487,10,371,4,0,
487,10,396,1,37,
487,10,399,4,0,
487,10,401,3,0,
487,10,406,4,0,
487,10,412,4,0,
487,10,414,1,33,
487,10,414,3,0,
487,10,416,4,0,
487,10,421,1,42,
487,10,421,4,0,
487,10,425,1,19,
487,10,431,4,0,
487,10,434,3,0,
487,10,442,3,0,
487,10,444,4,0,
487,10,451,4,0,
487,10,466,1,6,
487,10,466,3,0,
487,10,467,1,46,
487,11,15,4,0,
487,11,19,4,0,
487,11,46,4,0,
487,11,63,4,0,
487,11,70,4,0,
487,11,85,4,0,
487,11,86,4,0,
487,11,87,4,0,
487,11,89,4,0,
487,11,92,4,0,
487,11,94,4,0,
487,11,104,4,0,
487,11,138,4,0,
487,11,156,4,0,
487,11,163,1,15,
487,11,164,4,0,
487,11,182,4,0,
487,11,184,1,1,2
487,11,194,1,24,
487,11,207,4,0,
487,11,216,4,0,
487,11,218,4,0,
487,11,219,4,0,
487,11,225,1,1,1
487,11,237,4,0,
487,11,240,4,0,
487,11,241,4,0,
487,11,244,4,0,
487,11,246,1,10,
487,11,247,4,0,
487,11,249,4,0,
487,11,261,4,0,
487,11,263,4,0,
487,11,332,4,0,
487,11,337,1,28,
487,11,337,4,0,
487,11,347,4,0,
487,11,371,4,0,
487,11,396,1,37,
487,11,412,4,0,
487,11,414,1,33,
487,11,416,4,0,
487,11,421,1,42,
487,11,421,4,0,
487,11,425,1,19,
487,11,434,3,0,
487,11,444,4,0,
487,11,451,4,0,
487,11,466,1,6,
487,11,467,1,46,
487,11,468,4,0,
487,11,477,4,0,
487,11,496,4,0,
487,11,497,4,0,
487,11,506,1,50,
487,11,523,4,0,
487,11,525,4,0,
487,14,15,4,0,
487,14,19,4,0,
487,14,46,4,0,
487,14,63,4,0,
487,14,70,4,0,
487,14,85,4,0,
487,14,86,4,0,
487,14,87,4,0,
487,14,89,4,0,
487,14,92,4,0,
487,14,94,4,0,
487,14,104,4,0,
487,14,138,4,0,
487,14,156,4,0,
487,14,163,1,15,
487,14,164,4,0,
487,14,173,3,0,
487,14,180,3,0,
487,14,182,4,0,
487,14,184,1,1,2
487,14,194,1,24,
487,14,196,3,0,
487,14,200,3,0,
487,14,207,4,0,
487,14,214,3,0,
487,14,216,4,0,
487,14,218,4,0,
487,14,219,4,0,
487,14,220,3,0,
487,14,225,1,1,1
487,14,231,3,0,
487,14,237,4,0,
487,14,240,4,0,
487,14,241,4,0,
487,14,244,4,0,
487,14,246,1,10,
487,14,247,4,0,
487,14,249,4,0,
487,14,261,4,0,
487,14,263,4,0,
487,14,304,3,0,
487,14,332,4,0,
487,14,337,1,28,
487,14,337,4,0,
487,14,347,4,0,
487,14,356,3,0,
487,14,371,4,0,
487,14,396,1,37,
487,14,399,3,0,
487,14,401,3,0,
487,14,406,3,0,
487,14,412,4,0,
487,14,414,1,33,
487,14,414,3,0,
487,14,416,4,0,
487,14,421,1,42,
487,14,421,4,0,
487,14,425,1,19,
487,14,434,3,0,
487,14,442,3,0,
487,14,444,4,0,
487,14,451,4,0,
487,14,466,1,6,
487,14,467,1,46,
487,14,468,4,0,
487,14,477,4,0,
487,14,496,4,0,
487,14,497,4,0,
487,14,506,1,50,
487,14,523,4,0,
487,14,525,4,0,
487,15,15,4,0,
487,15,19,4,0,
487,15,46,4,0,
487,15,63,4,0,
487,15,70,4,0,
487,15,85,4,0,
487,15,86,4,0,
487,15,87,4,0,
487,15,89,4,0,
487,15,92,4,0,
487,15,94,4,0,
487,15,104,4,0,
487,15,138,4,0,
487,15,156,4,0,
487,15,163,1,15,
487,15,164,4,0,
487,15,182,4,0,
487,15,184,1,1,2
487,15,194,1,24,
487,15,207,4,0,
487,15,211,4,0,
487,15,214,4,0,
487,15,216,4,0,
487,15,218,4,0,
487,15,219,4,0,
487,15,225,1,1,1
487,15,237,4,0,
487,15,240,4,0,
487,15,241,4,0,
487,15,244,4,0,
487,15,246,1,10,
487,15,247,4,0,
487,15,249,4,0,
487,15,261,4,0,
487,15,263,4,0,
487,15,332,4,0,
487,15,337,1,28,
487,15,337,4,0,
487,15,347,4,0,
487,15,371,4,0,
487,15,396,1,37,
487,15,399,4,0,
487,15,412,4,0,
487,15,414,1,33,
487,15,416,4,0,
487,15,421,1,42,
487,15,421,4,0,
487,15,425,1,19,
487,15,434,3,0,
487,15,444,4,0,
487,15,451,4,0,
487,15,466,1,6,
487,15,467,1,46,
487,15,468,4,0,
487,15,496,4,0,
487,15,497,4,0,
487,15,506,1,50,
487,15,523,4,0,
487,15,525,4,0,
487,15,590,4,0,
487,16,15,4,0,
487,16,19,4,0,
487,16,46,4,0,
487,16,63,4,0,
487,16,70,4,0,
487,16,85,4,0,
487,16,86,4,0,
487,16,87,4,0,
487,16,89,4,0,
487,16,92,4,0,
487,16,94,4,0,
487,16,104,4,0,
487,16,138,4,0,
487,16,156,4,0,
487,16,163,1,15,1
487,16,164,4,0,
487,16,173,3,0,
487,16,180,3,0,
487,16,182,4,0,
487,16,184,1,1,2
487,16,194,1,24,1
487,16,196,3,0,
487,16,200,3,0,
487,16,207,4,0,
487,16,211,4,0,
487,16,214,4,0,
487,16,216,4,0,
487,16,218,4,0,
487,16,219,4,0,
487,16,220,3,0,
487,16,225,1,1,1
487,16,231,3,0,
487,16,237,4,0,
487,16,240,4,0,
487,16,241,4,0,
487,16,244,4,0,
487,16,246,1,10,1
487,16,247,4,0,
487,16,249,4,0,
487,16,261,4,0,
487,16,263,4,0,
487,16,290,4,0,
487,16,304,3,0,
487,16,332,4,0,
487,16,337,1,28,1
487,16,337,4,0,
487,16,347,4,0,
487,16,351,3,0,
487,16,356,3,0,
487,16,371,4,0,
487,16,396,1,37,1
487,16,399,4,0,
487,16,401,3,0,
487,16,406,3,0,
487,16,412,4,0,
487,16,414,1,33,1
487,16,414,3,0,
487,16,416,4,0,
487,16,421,1,42,1
487,16,421,4,0,
487,16,425,1,19,1
487,16,434,3,0,
487,16,442,3,0,
487,16,444,4,0,
487,16,451,4,0,
487,16,466,1,6,1
487,16,467,1,46,1
487,16,468,4,0,
487,16,496,4,0,
487,16,497,4,0,
487,16,506,1,50,1
487,16,523,4,0,
487,16,525,4,0,
487,16,590,4,0,
487,17,19,4,0,
487,17,46,4,0,
487,17,63,4,0,
487,17,85,4,0,
487,17,86,4,0,
487,17,87,4,0,
487,17,89,4,0,
487,17,92,4,0,
487,17,94,4,0,
487,17,104,4,0,
487,17,138,4,0,
487,17,156,4,0,
487,17,163,1,15,
487,17,164,4,0,
487,17,182,4,0,
487,17,184,1,1,2
487,17,194,1,24,
487,17,207,4,0,
487,17,211,4,0,
487,17,214,4,0,
487,17,216,4,0,
487,17,218,4,0,
487,17,219,4,0,
487,17,225,1,1,1
487,17,237,4,0,
487,17,240,4,0,
487,17,241,4,0,
487,17,244,4,0,
487,17,246,1,10,
487,17,247,4,0,
487,17,261,4,0,
487,17,263,4,0,
487,17,332,4,0,
487,17,337,1,28,
487,17,337,4,0,
487,17,347,4,0,
487,17,371,4,0,
487,17,396,1,37,
487,17,399,4,0,
487,17,412,4,0,
487,17,414,1,33,
487,17,416,4,0,
487,17,421,1,42,
487,17,421,4,0,
487,17,425,1,19,
487,17,434,3,0,
487,17,444,4,0,
487,17,451,4,0,
487,17,466,1,6,
487,17,467,1,46,
487,17,496,4,0,
487,17,497,4,0,
487,17,506,1,50,
487,17,523,4,0,
487,17,525,4,0,
487,17,590,4,0,
487,17,693,4,0,
487,18,19,4,0,
487,18,46,4,0,
487,18,63,4,0,
487,18,85,4,0,
487,18,86,4,0,
487,18,87,4,0,
487,18,89,4,0,
487,18,92,4,0,
487,18,94,4,0,
487,18,104,4,0,
487,18,138,4,0,
487,18,156,4,0,
487,18,163,1,15,
487,18,164,4,0,
487,18,182,4,0,
487,18,184,1,1,2
487,18,194,1,24,
487,18,207,4,0,
487,18,211,4,0,
487,18,214,4,0,
487,18,216,4,0,
487,18,218,4,0,
487,18,219,4,0,
487,18,225,1,1,1
487,18,237,4,0,
487,18,240,4,0,
487,18,241,4,0,
487,18,244,4,0,
487,18,246,1,10,
487,18,247,4,0,
487,18,261,4,0,
487,18,263,4,0,
487,18,332,4,0,
487,18,337,1,28,
487,18,337,4,0,
487,18,347,4,0,
487,18,371,4,0,
487,18,396,1,37,
487,18,399,4,0,
487,18,412,4,0,
487,18,414,1,33,
487,18,416,4,0,
487,18,421,1,42,
487,18,421,4,0,
487,18,425,1,19,
487,18,434,3,0,
487,18,444,4,0,
487,18,451,4,0,
487,18,466,1,6,
487,18,467,1,46,
487,18,496,4,0,
487,18,497,4,0,
487,18,506,1,50,
487,18,523,4,0,
487,18,525,4,0,
487,18,590,4,0,
487,18,693,4,0,
488,8,54,1,20,
488,8,58,4,0,
488,8,62,1,29,
488,8,63,4,0,
488,8,76,4,0,
488,8,86,4,0,
488,8,92,4,0,
488,8,93,1,1,1
488,8,94,1,93,
488,8,94,4,0,
488,8,104,1,1,2
488,8,104,4,0,
488,8,113,4,0,
488,8,115,4,0,
488,8,138,4,0,
488,8,148,4,0,
488,8,156,4,0,
488,8,163,1,47,
488,8,164,4,0,
488,8,182,4,0,
488,8,203,4,0,
488,8,207,4,0,
488,8,213,4,0,
488,8,214,4,0,
488,8,216,4,0,
488,8,218,4,0,
488,8,219,1,11,
488,8,219,4,0,
488,8,236,1,57,
488,8,237,4,0,
488,8,240,4,0,
488,8,241,4,0,
488,8,244,4,0,
488,8,247,4,0,
488,8,248,1,38,
488,8,263,4,0,
488,8,278,4,0,
488,8,285,4,0,
488,8,290,4,0,
488,8,347,4,0,
488,8,363,4,0,
488,8,375,1,75,
488,8,412,4,0,
488,8,416,4,0,
488,8,427,1,66,
488,8,433,4,0,
488,8,445,4,0,
488,8,447,4,0,
488,8,451,4,0,
488,8,461,1,84,
488,9,54,1,20,
488,9,58,4,0,
488,9,62,1,29,
488,9,63,4,0,
488,9,76,4,0,
488,9,86,4,0,
488,9,92,4,0,
488,9,93,1,1,1
488,9,94,1,93,
488,9,94,4,0,
488,9,104,1,1,2
488,9,104,4,0,
488,9,113,4,0,
488,9,115,4,0,
488,9,129,3,0,
488,9,138,4,0,
488,9,148,4,0,
488,9,156,4,0,
488,9,163,1,47,
488,9,164,4,0,
488,9,173,3,0,
488,9,182,4,0,
488,9,189,3,0,
488,9,196,3,0,
488,9,203,4,0,
488,9,207,4,0,
488,9,210,3,0,
488,9,213,4,0,
488,9,214,4,0,
488,9,216,4,0,
488,9,218,4,0,
488,9,219,1,11,
488,9,219,4,0,
488,9,236,1,57,
488,9,237,4,0,
488,9,240,4,0,
488,9,241,4,0,
488,9,244,4,0,
488,9,247,4,0,
488,9,248,1,38,
488,9,263,4,0,
488,9,270,3,0,
488,9,271,3,0,
488,9,278,4,0,
488,9,285,4,0,
488,9,290,4,0,
488,9,324,3,0,
488,9,347,4,0,
488,9,363,4,0,
488,9,375,1,75,
488,9,412,4,0,
488,9,416,4,0,
488,9,427,1,66,
488,9,428,3,0,
488,9,433,4,0,
488,9,445,4,0,
488,9,447,4,0,
488,9,451,4,0,
488,9,461,1,84,
488,10,54,1,20,
488,10,58,4,0,
488,10,62,1,29,
488,10,63,4,0,
488,10,76,4,0,
488,10,86,4,0,
488,10,92,4,0,
488,10,93,1,1,1
488,10,94,1,93,
488,10,94,4,0,
488,10,104,1,1,2
488,10,104,4,0,
488,10,113,4,0,
488,10,115,4,0,
488,10,129,3,0,
488,10,138,4,0,
488,10,148,4,0,
488,10,156,4,0,
488,10,163,1,47,
488,10,164,4,0,
488,10,173,3,0,
488,10,182,4,0,
488,10,189,3,0,
488,10,196,3,0,
488,10,203,4,0,
488,10,207,4,0,
488,10,210,3,0,
488,10,213,4,0,
488,10,214,4,0,
488,10,216,4,0,
488,10,218,4,0,
488,10,219,1,11,
488,10,219,4,0,
488,10,236,1,57,
488,10,237,4,0,
488,10,240,4,0,
488,10,241,4,0,
488,10,244,4,0,
488,10,247,4,0,
488,10,248,1,38,
488,10,263,4,0,
488,10,270,3,0,
488,10,271,3,0,
488,10,277,3,0,
488,10,278,4,0,
488,10,285,4,0,
488,10,290,4,0,
488,10,324,3,0,
488,10,347,4,0,
488,10,356,3,0,
488,10,363,4,0,
488,10,375,1,75,
488,10,412,4,0,
488,10,416,4,0,
488,10,427,1,66,
488,10,428,3,0,
488,10,433,4,0,
488,10,445,4,0,
488,10,447,4,0,
488,10,451,4,0,
488,10,461,1,84,
488,11,54,1,20,
488,11,58,4,0,
488,11,62,1,29,
488,11,63,4,0,
488,11,76,4,0,
488,11,86,4,0,
488,11,92,4,0,
488,11,93,1,1,1
488,11,94,1,93,
488,11,94,4,0,
488,11,104,1,1,2
488,11,104,4,0,
488,11,113,4,0,
488,11,115,4,0,
488,11,138,4,0,
488,11,148,4,0,
488,11,156,4,0,
488,11,163,1,47,
488,11,164,4,0,
488,11,182,4,0,
488,11,207,4,0,
488,11,213,4,0,
488,11,216,4,0,
488,11,218,4,0,
488,11,219,1,11,
488,11,219,4,0,
488,11,236,1,57,
488,11,237,4,0,
488,11,240,4,0,
488,11,241,4,0,
488,11,244,4,0,
488,11,247,4,0,
488,11,248,1,38,
488,11,263,4,0,
488,11,347,4,0,
488,11,375,1,75,
488,11,412,4,0,
488,11,416,4,0,
488,11,427,1,66,
488,11,433,4,0,
488,11,447,4,0,
488,11,451,4,0,
488,11,461,1,84,
488,11,473,4,0,
488,11,477,4,0,
488,11,496,4,0,
488,14,54,1,20,
488,14,58,4,0,
488,14,62,1,29,
488,14,63,4,0,
488,14,76,4,0,
488,14,86,4,0,
488,14,92,4,0,
488,14,93,1,1,1
488,14,94,1,93,
488,14,94,4,0,
488,14,104,1,1,2
488,14,104,4,0,
488,14,113,4,0,
488,14,115,4,0,
488,14,138,4,0,
488,14,148,4,0,
488,14,156,4,0,
488,14,163,1,47,
488,14,164,4,0,
488,14,173,3,0,
488,14,182,4,0,
488,14,196,3,0,
488,14,207,4,0,
488,14,213,4,0,
488,14,214,3,0,
488,14,216,4,0,
488,14,218,4,0,
488,14,219,1,11,
488,14,219,4,0,
488,14,236,1,57,
488,14,237,4,0,
488,14,240,4,0,
488,14,241,4,0,
488,14,244,4,0,
488,14,247,4,0,
488,14,248,1,38,
488,14,263,4,0,
488,14,270,3,0,
488,14,271,3,0,
488,14,277,3,0,
488,14,278,3,0,
488,14,285,3,0,
488,14,324,3,0,
488,14,347,4,0,
488,14,356,3,0,
488,14,375,1,75,
488,14,412,4,0,
488,14,416,4,0,
488,14,427,1,66,
488,14,428,3,0,
488,14,433,4,0,
488,14,447,4,0,
488,14,451,4,0,
488,14,461,1,84,
488,14,473,4,0,
488,14,477,4,0,
488,14,478,3,0,
488,14,496,4,0,
488,15,54,1,20,
488,15,58,4,0,
488,15,62,1,29,
488,15,63,4,0,
488,15,76,4,0,
488,15,86,4,0,
488,15,92,4,0,
488,15,93,1,1,5
488,15,94,1,93,
488,15,94,4,0,
488,15,104,1,1,6
488,15,104,4,0,
488,15,113,4,0,
488,15,115,4,0,
488,15,138,4,0,
488,15,148,4,0,
488,15,156,4,0,
488,15,163,1,47,
488,15,164,4,0,
488,15,182,4,0,
488,15,207,4,0,
488,15,213,4,0,
488,15,214,4,0,
488,15,216,4,0,
488,15,218,4,0,
488,15,219,1,11,
488,15,219,4,0,
488,15,236,1,1,4
488,15,236,1,57,
488,15,237,4,0,
488,15,240,4,0,
488,15,241,4,0,
488,15,244,4,0,
488,15,247,4,0,
488,15,248,1,38,
488,15,263,4,0,
488,15,347,4,0,
488,15,375,1,1,2
488,15,375,1,75,
488,15,412,4,0,
488,15,416,4,0,
488,15,427,1,1,3
488,15,427,1,66,
488,15,433,4,0,
488,15,447,4,0,
488,15,451,4,0,
488,15,461,1,1,1
488,15,461,1,84,
488,15,473,4,0,
488,15,496,4,0,
488,15,585,1,99,
488,15,590,4,0,
488,16,54,1,20,1
488,16,58,4,0,
488,16,62,1,29,1
488,16,63,4,0,
488,16,76,4,0,
488,16,86,4,0,
488,16,92,4,0,
488,16,93,1,1,5
488,16,94,1,93,1
488,16,94,4,0,
488,16,104,1,1,6
488,16,104,4,0,
488,16,113,4,0,
488,16,115,4,0,
488,16,138,4,0,
488,16,148,4,0,
488,16,156,4,0,
488,16,163,1,47,1
488,16,164,4,0,
488,16,173,3,0,
488,16,182,4,0,
488,16,196,3,0,
488,16,207,4,0,
488,16,213,4,0,
488,16,214,4,0,
488,16,216,4,0,
488,16,218,4,0,
488,16,219,1,11,1
488,16,219,4,0,
488,16,236,1,1,4
488,16,236,1,57,1
488,16,237,4,0,
488,16,240,4,0,
488,16,241,4,0,
488,16,244,4,0,
488,16,247,4,0,
488,16,248,1,38,1
488,16,263,4,0,
488,16,270,3,0,
488,16,271,3,0,
488,16,277,3,0,
488,16,278,3,0,
488,16,285,3,0,
488,16,290,4,0,
488,16,324,3,0,
488,16,347,4,0,
488,16,356,3,0,
488,16,375,1,1,2
488,16,375,1,75,1
488,16,412,4,0,
488,16,416,4,0,
488,16,427,1,1,3
488,16,427,1,66,1
488,16,428,3,0,
488,16,433,4,0,
488,16,447,4,0,
488,16,451,4,0,
488,16,461,1,1,1
488,16,461,1,84,1
488,16,473,4,0,
488,16,478,3,0,
488,16,496,4,0,
488,16,585,1,99,1
488,16,590,4,0,
488,17,54,1,20,
488,17,58,4,0,
488,17,62,1,29,
488,17,63,4,0,
488,17,76,4,0,
488,17,86,4,0,
488,17,92,4,0,
488,17,93,1,1,5
488,17,94,1,93,
488,17,94,4,0,
488,17,104,1,1,6
488,17,104,4,0,
488,17,113,4,0,
488,17,115,4,0,
488,17,138,4,0,
488,17,156,4,0,
488,17,163,1,47,
488,17,164,4,0,
488,17,182,4,0,
488,17,207,4,0,
488,17,213,4,0,
488,17,214,4,0,
488,17,216,4,0,
488,17,218,4,0,
488,17,219,1,11,
488,17,219,4,0,
488,17,236,1,1,4
488,17,236,1,57,
488,17,237,4,0,
488,17,240,4,0,
488,17,241,4,0,
488,17,244,4,0,
488,17,247,4,0,
488,17,248,1,38,
488,17,263,4,0,
488,17,347,4,0,
488,17,375,1,1,2
488,17,375,1,75,
488,17,412,4,0,
488,17,416,4,0,
488,17,427,1,1,3
488,17,427,1,66,
488,17,433,4,0,
488,17,447,4,0,
488,17,451,4,0,
488,17,461,1,1,1
488,17,461,1,84,
488,17,473,4,0,
488,17,496,4,0,
488,17,585,1,99,
488,17,590,4,0,
488,18,54,1,20,
488,18,58,4,0,
488,18,62,1,29,
488,18,63,4,0,
488,18,76,4,0,
488,18,86,4,0,
488,18,92,4,0,
488,18,93,1,1,5
488,18,94,1,93,
488,18,94,4,0,
488,18,104,1,1,6
488,18,104,4,0,
488,18,113,4,0,
488,18,115,4,0,
488,18,138,4,0,
488,18,156,4,0,
488,18,163,1,47,
488,18,164,4,0,
488,18,182,4,0,
488,18,207,4,0,
488,18,213,4,0,
488,18,214,4,0,
488,18,216,4,0,
488,18,218,4,0,
488,18,219,1,11,
488,18,219,4,0,
488,18,236,1,1,4
488,18,236,1,57,
488,18,237,4,0,
488,18,240,4,0,
488,18,241,4,0,
488,18,244,4,0,
488,18,247,4,0,
488,18,248,1,38,
488,18,263,4,0,
488,18,347,4,0,
488,18,375,1,1,2
488,18,375,1,75,
488,18,412,4,0,
488,18,416,4,0,
488,18,427,1,1,3
488,18,427,1,66,
488,18,433,4,0,
488,18,447,4,0,
488,18,451,4,0,
488,18,461,1,1,1
488,18,461,1,84,
488,18,473,4,0,
488,18,496,4,0,
488,18,585,1,99,
488,18,590,4,0,
489,8,48,1,16,
489,8,57,4,0,
489,8,58,4,0,
489,8,59,4,0,
489,8,61,1,24,
489,8,92,4,0,
489,8,104,4,0,
489,8,127,4,0,
489,8,145,1,1,1
489,8,151,1,31,
489,8,156,4,0,
489,8,164,4,0,
489,8,182,4,0,
489,8,203,4,0,
489,8,204,1,9,
489,8,207,4,0,
489,8,214,4,0,
489,8,216,4,0,
489,8,218,4,0,
489,8,219,4,0,
489,8,237,4,0,
489,8,240,1,69,
489,8,240,4,0,
489,8,244,4,0,
489,8,250,1,39,
489,8,258,4,0,
489,8,263,4,0,
489,8,290,4,0,
489,8,291,1,61,
489,8,346,1,1,2
489,8,352,1,46,
489,8,352,4,0,
489,8,362,4,0,
489,8,363,4,0,
489,8,369,4,0,
489,8,374,4,0,
489,8,392,1,54,
489,8,447,4,0,
489,9,48,1,16,
489,9,57,4,0,
489,9,58,4,0,
489,9,59,4,0,
489,9,61,1,24,
489,9,92,4,0,
489,9,104,4,0,
489,9,127,4,0,
489,9,129,3,0,
489,9,145,1,1,1
489,9,151,1,31,
489,9,156,4,0,
489,9,164,4,0,
489,9,173,3,0,
489,9,182,4,0,
489,9,189,3,0,
489,9,196,3,0,
489,9,203,4,0,
489,9,204,1,9,
489,9,207,4,0,
489,9,214,4,0,
489,9,216,4,0,
489,9,218,4,0,
489,9,219,4,0,
489,9,237,4,0,
489,9,240,1,69,
489,9,240,4,0,
489,9,244,4,0,
489,9,246,3,0,
489,9,250,1,39,
489,9,253,3,0,
489,9,258,4,0,
489,9,263,4,0,
489,9,270,3,0,
489,9,282,3,0,
489,9,290,4,0,
489,9,291,1,61,
489,9,291,3,0,
489,9,324,3,0,
489,9,340,3,0,
489,9,346,1,1,2
489,9,352,1,46,
489,9,352,4,0,
489,9,362,4,0,
489,9,363,4,0,
489,9,369,4,0,
489,9,374,4,0,
489,9,387,3,0,
489,9,392,1,54,
489,9,447,4,0,
489,10,48,1,16,
489,10,57,4,0,
489,10,58,4,0,
489,10,59,4,0,
489,10,61,1,24,
489,10,92,4,0,
489,10,104,4,0,
489,10,127,4,0,
489,10,129,3,0,
489,10,145,1,1,1
489,10,151,1,31,
489,10,156,4,0,
489,10,164,4,0,
489,10,173,3,0,
489,10,182,4,0,
489,10,189,3,0,
489,10,196,3,0,
489,10,203,4,0,
489,10,204,1,9,
489,10,207,4,0,
489,10,214,4,0,
489,10,215,3,0,
489,10,216,4,0,
489,10,218,4,0,
489,10,219,4,0,
489,10,237,4,0,
489,10,240,1,69,
489,10,240,4,0,
489,10,244,4,0,
489,10,246,3,0,
489,10,250,1,39,
489,10,250,4,0,
489,10,253,3,0,
489,10,258,4,0,
489,10,263,4,0,
489,10,270,3,0,
489,10,282,3,0,
489,10,290,4,0,
489,10,291,1,61,
489,10,291,3,0,
489,10,324,3,0,
489,10,340,3,0,
489,10,346,1,1,2
489,10,352,1,46,
489,10,352,4,0,
489,10,362,4,0,
489,10,363,4,0,
489,10,369,4,0,
489,10,374,4,0,
489,10,387,3,0,
489,10,392,1,54,
489,10,447,4,0,
489,11,48,1,16,
489,11,57,4,0,
489,11,58,4,0,
489,11,59,4,0,
489,11,61,1,24,
489,11,92,4,0,
489,11,104,4,0,
489,11,127,4,0,
489,11,145,1,1,1
489,11,151,1,31,
489,11,156,4,0,
489,11,164,4,0,
489,11,182,4,0,
489,11,204,1,9,
489,11,207,4,0,
489,11,216,4,0,
489,11,218,4,0,
489,11,219,4,0,
489,11,237,4,0,
489,11,240,1,69,
489,11,240,4,0,
489,11,244,4,0,
489,11,250,1,39,
489,11,258,4,0,
489,11,263,4,0,
489,11,291,1,61,
489,11,291,4,0,
489,11,346,1,1,2
489,11,352,1,46,
489,11,369,4,0,
489,11,374,4,0,
489,11,392,1,54,
489,11,447,4,0,
489,11,496,4,0,
489,11,503,4,0,
489,14,48,1,16,
489,14,57,4,0,
489,14,58,4,0,
489,14,59,4,0,
489,14,61,1,24,
489,14,92,4,0,
489,14,104,4,0,
489,14,127,4,0,
489,14,145,1,1,1
489,14,151,1,31,
489,14,156,4,0,
489,14,164,4,0,
489,14,173,3,0,
489,14,182,4,0,
489,14,196,3,0,
489,14,204,1,9,
489,14,207,4,0,
489,14,214,3,0,
489,14,215,3,0,
489,14,216,4,0,
489,14,218,4,0,
489,14,219,4,0,
489,14,237,4,0,
489,14,240,1,69,
489,14,240,4,0,
489,14,244,4,0,
489,14,250,1,39,
489,14,253,3,0,
489,14,258,4,0,
489,14,263,4,0,
489,14,270,3,0,
489,14,282,3,0,
489,14,291,1,61,
489,14,291,4,0,
489,14,324,3,0,
489,14,340,3,0,
489,14,343,3,0,
489,14,346,1,1,2
489,14,352,1,46,
489,14,369,4,0,
489,14,374,4,0,
489,14,387,3,0,
489,14,392,1,54,
489,14,447,4,0,
489,14,496,4,0,
489,14,503,4,0,
489,15,48,1,16,
489,15,57,4,0,
489,15,58,4,0,
489,15,59,4,0,
489,15,61,1,24,
489,15,92,4,0,
489,15,104,4,0,
489,15,127,4,0,
489,15,145,1,1,1
489,15,151,1,31,
489,15,156,4,0,
489,15,164,4,0,
489,15,182,4,0,
489,15,204,1,9,
489,15,207,4,0,
489,15,214,4,0,
489,15,216,4,0,
489,15,218,4,0,
489,15,219,4,0,
489,15,237,4,0,
489,15,240,1,69,
489,15,240,4,0,
489,15,244,4,0,
489,15,250,1,39,
489,15,258,4,0,
489,15,263,4,0,
489,15,291,1,61,
489,15,346,1,1,2
489,15,352,1,46,
489,15,369,4,0,
489,15,374,4,0,
489,15,392,1,54,
489,15,447,4,0,
489,15,496,4,0,
489,15,503,4,0,
489,15,590,4,0,
489,15,605,4,0,
489,16,48,1,16,1
489,16,57,4,0,
489,16,58,4,0,
489,16,59,4,0,
489,16,61,1,24,1
489,16,92,4,0,
489,16,104,4,0,
489,16,127,4,0,
489,16,145,1,1,1
489,16,151,1,31,1
489,16,156,4,0,
489,16,164,4,0,
489,16,173,3,0,
489,16,182,4,0,
489,16,196,3,0,
489,16,204,1,9,1
489,16,207,4,0,
489,16,214,4,0,
489,16,215,3,0,
489,16,216,4,0,
489,16,218,4,0,
489,16,219,4,0,
489,16,237,4,0,
489,16,240,1,69,1
489,16,240,4,0,
489,16,244,4,0,
489,16,250,1,39,1
489,16,253,3,0,
489,16,258,4,0,
489,16,263,4,0,
489,16,270,3,0,
489,16,282,3,0,
489,16,290,4,0,
489,16,291,1,61,1
489,16,291,4,0,
489,16,324,3,0,
489,16,340,3,0,
489,16,343,3,0,
489,16,346,1,1,2
489,16,352,1,46,1
489,16,352,3,0,
489,16,369,4,0,
489,16,374,4,0,
489,16,387,3,0,
489,16,392,1,54,1
489,16,447,4,0,
489,16,496,4,0,
489,16,503,4,0,
489,16,590,4,0,
489,16,605,4,0,
489,17,48,1,16,
489,17,57,4,0,
489,17,58,4,0,
489,17,59,4,0,
489,17,61,1,24,
489,17,92,4,0,
489,17,104,4,0,
489,17,127,4,0,
489,17,145,1,1,1
489,17,151,1,31,
489,17,156,4,0,
489,17,164,4,0,
489,17,182,4,0,
489,17,204,1,9,
489,17,207,4,0,
489,17,214,4,0,
489,17,216,4,0,
489,17,218,4,0,
489,17,219,4,0,
489,17,237,4,0,
489,17,240,1,69,
489,17,240,4,0,
489,17,244,4,0,
489,17,250,1,39,
489,17,258,4,0,
489,17,263,4,0,
489,17,291,1,61,
489,17,346,1,1,2
489,17,352,1,46,
489,17,369,4,0,
489,17,374,4,0,
489,17,392,1,54,
489,17,447,4,0,
489,17,496,4,0,
489,17,503,4,0,
489,17,590,4,0,
489,17,605,4,0,
489,18,48,1,16,
489,18,57,4,0,
489,18,58,4,0,
489,18,59,4,0,
489,18,61,1,24,
489,18,92,4,0,
489,18,104,4,0,
489,18,127,4,0,
489,18,145,1,1,1
489,18,151,1,31,
489,18,156,4,0,
489,18,164,4,0,
489,18,182,4,0,
489,18,204,1,9,
489,18,207,4,0,
489,18,214,4,0,
489,18,216,4,0,
489,18,218,4,0,
489,18,219,4,0,
489,18,237,4,0,
489,18,240,1,69,
489,18,240,4,0,
489,18,244,4,0,
489,18,250,1,39,
489,18,258,4,0,
489,18,263,4,0,
489,18,291,1,61,
489,18,346,1,1,2
489,18,352,1,46,
489,18,369,4,0,
489,18,374,4,0,
489,18,392,1,54,
489,18,447,4,0,
489,18,496,4,0,
489,18,503,4,0,
489,18,590,4,0,
489,18,605,4,0,
490,8,48,1,16,
490,8,57,4,0,
490,8,58,4,0,
490,8,59,4,0,
490,8,61,1,24,
490,8,63,4,0,
490,8,92,4,0,
490,8,94,4,0,
490,8,104,4,0,
490,8,113,4,0,
490,8,115,4,0,
490,8,127,4,0,
490,8,145,1,1,2
490,8,148,4,0,
490,8,151,1,31,
490,8,156,4,0,
490,8,164,4,0,
490,8,182,4,0,
490,8,203,4,0,
490,8,204,1,9,
490,8,207,4,0,
490,8,214,4,0,
490,8,216,4,0,
490,8,218,4,0,
490,8,219,4,0,
490,8,237,4,0,
490,8,240,1,69,
490,8,240,4,0,
490,8,244,4,0,
490,8,247,4,0,
490,8,250,1,39,
490,8,258,4,0,
490,8,263,4,0,
490,8,285,4,0,
490,8,290,4,0,
490,8,291,1,61,
490,8,294,1,1,1
490,8,346,1,1,3
490,8,347,4,0,
490,8,352,1,46,
490,8,352,4,0,
490,8,362,4,0,
490,8,363,4,0,
490,8,369,4,0,
490,8,374,4,0,
490,8,391,1,76,
490,8,392,1,54,
490,8,412,4,0,
490,8,416,4,0,
490,8,447,4,0,
490,9,48,1,16,
490,9,57,4,0,
490,9,58,4,0,
490,9,59,4,0,
490,9,61,1,24,
490,9,63,4,0,
490,9,92,4,0,
490,9,94,4,0,
490,9,104,4,0,
490,9,113,4,0,
490,9,115,4,0,
490,9,127,4,0,
490,9,129,3,0,
490,9,145,1,1,2
490,9,148,4,0,
490,9,151,1,31,
490,9,156,4,0,
490,9,164,4,0,
490,9,173,3,0,
490,9,182,4,0,
490,9,189,3,0,
490,9,196,3,0,
490,9,203,4,0,
490,9,204,1,9,
490,9,207,4,0,
490,9,214,4,0,
490,9,216,4,0,
490,9,218,4,0,
490,9,219,4,0,
490,9,237,4,0,
490,9,240,1,69,
490,9,240,4,0,
490,9,244,4,0,
490,9,246,3,0,
490,9,247,4,0,
490,9,250,1,39,
490,9,253,3,0,
490,9,258,4,0,
490,9,263,4,0,
490,9,270,3,0,
490,9,282,3,0,
490,9,285,4,0,
490,9,290,4,0,
490,9,291,1,61,
490,9,291,3,0,
490,9,294,1,1,1
490,9,324,3,0,
490,9,340,3,0,
490,9,346,1,1,3
490,9,347,4,0,
490,9,352,1,46,
490,9,352,4,0,
490,9,362,4,0,
490,9,363,4,0,
490,9,369,4,0,
490,9,374,4,0,
490,9,387,3,0,
490,9,391,1,76,
490,9,392,1,54,
490,9,412,4,0,
490,9,416,4,0,
490,9,447,4,0,
490,10,48,1,16,
490,10,57,4,0,
490,10,58,4,0,
490,10,59,4,0,
490,10,61,1,24,
490,10,63,4,0,
490,10,92,4,0,
490,10,94,4,0,
490,10,104,4,0,
490,10,113,4,0,
490,10,115,4,0,
490,10,127,4,0,
490,10,129,3,0,
490,10,145,1,1,2
490,10,148,4,0,
490,10,151,1,31,
490,10,156,4,0,
490,10,164,4,0,
490,10,173,3,0,
490,10,182,4,0,
490,10,189,3,0,
490,10,196,3,0,
490,10,203,4,0,
490,10,204,1,9,
490,10,207,4,0,
490,10,214,4,0,
490,10,215,3,0,
490,10,216,4,0,
490,10,218,4,0,
490,10,219,4,0,
490,10,237,4,0,
490,10,240,1,69,
490,10,240,4,0,
490,10,244,4,0,
490,10,246,3,0,
490,10,247,4,0,
490,10,250,1,39,
490,10,250,4,0,
490,10,253,3,0,
490,10,258,4,0,
490,10,263,4,0,
490,10,270,3,0,
490,10,282,3,0,
490,10,285,4,0,
490,10,290,4,0,
490,10,291,1,61,
490,10,291,3,0,
490,10,294,1,1,1
490,10,324,3,0,
490,10,340,3,0,
490,10,346,1,1,3
490,10,347,4,0,
490,10,352,1,46,
490,10,352,4,0,
490,10,362,4,0,
490,10,363,4,0,
490,10,369,4,0,
490,10,374,4,0,
490,10,387,3,0,
490,10,391,1,76,
490,10,392,1,54,
490,10,412,4,0,
490,10,416,4,0,
490,10,447,4,0,
490,11,48,1,16,
490,11,57,4,0,
490,11,58,4,0,
490,11,59,4,0,
490,11,61,1,24,
490,11,63,4,0,
490,11,92,4,0,
490,11,94,4,0,
490,11,104,4,0,
490,11,113,4,0,
490,11,115,4,0,
490,11,127,4,0,
490,11,145,1,1,2
490,11,148,4,0,
490,11,151,1,31,
490,11,156,4,0,
490,11,164,4,0,
490,11,182,4,0,
490,11,204,1,9,
490,11,207,4,0,
490,11,216,4,0,
490,11,218,4,0,
490,11,219,4,0,
490,11,237,4,0,
490,11,240,1,69,
490,11,240,4,0,
490,11,244,4,0,
490,11,247,4,0,
490,11,250,1,39,
490,11,258,4,0,
490,11,263,4,0,
490,11,291,1,61,
490,11,291,4,0,
490,11,294,1,1,1
490,11,346,1,1,3
490,11,347,4,0,
490,11,352,1,46,
490,11,369,4,0,
490,11,374,4,0,
490,11,391,1,76,
490,11,392,1,54,
490,11,412,4,0,
490,11,416,4,0,
490,11,447,4,0,
490,11,496,4,0,
490,11,503,4,0,
490,14,48,1,16,
490,14,57,4,0,
490,14,58,4,0,
490,14,59,4,0,
490,14,61,1,24,
490,14,63,4,0,
490,14,92,4,0,
490,14,94,4,0,
490,14,104,4,0,
490,14,113,4,0,
490,14,115,4,0,
490,14,127,4,0,
490,14,145,1,1,2
490,14,148,4,0,
490,14,151,1,31,
490,14,156,4,0,
490,14,164,4,0,
490,14,173,3,0,
490,14,182,4,0,
490,14,196,3,0,
490,14,204,1,9,
490,14,207,4,0,
490,14,214,3,0,
490,14,215,3,0,
490,14,216,4,0,
490,14,218,4,0,
490,14,219,4,0,
490,14,237,4,0,
490,14,240,1,69,
490,14,240,4,0,
490,14,244,4,0,
490,14,247,4,0,
490,14,250,1,39,
490,14,253,3,0,
490,14,258,4,0,
490,14,263,4,0,
490,14,270,3,0,
490,14,282,3,0,
490,14,285,3,0,
490,14,291,1,61,
490,14,291,4,0,
490,14,294,1,1,1
490,14,324,3,0,
490,14,340,3,0,
490,14,343,3,0,
490,14,346,1,1,3
490,14,347,4,0,
490,14,352,1,46,
490,14,369,4,0,
490,14,374,4,0,
490,14,387,3,0,
490,14,391,1,76,
490,14,392,1,54,
490,14,412,4,0,
490,14,416,4,0,
490,14,447,4,0,
490,14,496,4,0,
490,14,503,4,0,
490,15,48,1,16,
490,15,57,4,0,
490,15,58,4,0,
490,15,59,4,0,
490,15,61,1,24,
490,15,63,4,0,
490,15,92,4,0,
490,15,94,4,0,
490,15,104,4,0,
490,15,113,4,0,
490,15,115,4,0,
490,15,127,4,0,
490,15,145,1,1,2
490,15,148,4,0,
490,15,151,1,31,
490,15,156,4,0,
490,15,164,4,0,
490,15,182,4,0,
490,15,204,1,9,
490,15,207,4,0,
490,15,214,4,0,
490,15,216,4,0,
490,15,218,4,0,
490,15,219,4,0,
490,15,237,4,0,
490,15,240,1,69,
490,15,240,4,0,
490,15,244,4,0,
490,15,247,4,0,
490,15,250,1,39,
490,15,258,4,0,
490,15,263,4,0,
490,15,291,1,61,
490,15,294,1,1,1
490,15,346,1,1,3
490,15,347,4,0,
490,15,352,1,46,
490,15,369,4,0,
490,15,374,4,0,
490,15,391,1,76,
490,15,392,1,54,
490,15,412,4,0,
490,15,416,4,0,
490,15,447,4,0,
490,15,496,4,0,
490,15,503,4,0,
490,15,590,4,0,
490,15,605,4,0,
490,16,48,1,16,1
490,16,57,4,0,
490,16,58,4,0,
490,16,59,4,0,
490,16,61,1,24,1
490,16,63,4,0,
490,16,92,4,0,
490,16,94,4,0,
490,16,104,4,0,
490,16,113,4,0,
490,16,115,4,0,
490,16,127,4,0,
490,16,145,1,1,2
490,16,148,4,0,
490,16,151,1,31,1
490,16,156,4,0,
490,16,164,4,0,
490,16,173,3,0,
490,16,182,4,0,
490,16,196,3,0,
490,16,204,1,9,1
490,16,207,4,0,
490,16,214,4,0,
490,16,215,3,0,
490,16,216,4,0,
490,16,218,4,0,
490,16,219,4,0,
490,16,237,4,0,
490,16,240,1,69,1
490,16,240,4,0,
490,16,244,4,0,
490,16,247,4,0,
490,16,250,1,39,1
490,16,253,3,0,
490,16,258,4,0,
490,16,263,4,0,
490,16,270,3,0,
490,16,282,3,0,
490,16,285,3,0,
490,16,290,4,0,
490,16,291,1,61,1
490,16,291,4,0,
490,16,294,1,1,1
490,16,324,3,0,
490,16,340,3,0,
490,16,343,3,0,
490,16,346,1,1,3
490,16,347,4,0,
490,16,352,1,46,1
490,16,352,3,0,
490,16,369,4,0,
490,16,374,4,0,
490,16,387,3,0,
490,16,391,1,76,1
490,16,392,1,54,1
490,16,412,4,0,
490,16,416,4,0,
490,16,447,4,0,
490,16,496,4,0,
490,16,503,4,0,
490,16,590,4,0,
490,16,605,4,0,
490,17,48,1,16,
490,17,57,4,0,
490,17,58,4,0,
490,17,59,4,0,
490,17,61,1,24,
490,17,63,4,0,
490,17,92,4,0,
490,17,94,4,0,
490,17,104,4,0,
490,17,113,4,0,
490,17,115,4,0,
490,17,127,4,0,
490,17,145,1,1,2
490,17,151,1,31,
490,17,156,4,0,
490,17,164,4,0,
490,17,182,4,0,
490,17,204,1,9,
490,17,207,4,0,
490,17,214,4,0,
490,17,216,4,0,
490,17,218,4,0,
490,17,219,4,0,
490,17,237,4,0,
490,17,240,1,69,
490,17,240,4,0,
490,17,244,4,0,
490,17,247,4,0,
490,17,250,1,39,
490,17,258,4,0,
490,17,263,4,0,
490,17,291,1,61,
490,17,294,1,1,1
490,17,346,1,1,3
490,17,347,4,0,
490,17,352,1,46,
490,17,369,4,0,
490,17,374,4,0,
490,17,391,1,76,
490,17,392,1,54,
490,17,412,4,0,
490,17,416,4,0,
490,17,447,4,0,
490,17,496,4,0,
490,17,503,4,0,
490,17,590,4,0,
490,17,605,4,0,
490,18,48,1,16,
490,18,57,4,0,
490,18,58,4,0,
490,18,59,4,0,
490,18,61,1,24,
490,18,63,4,0,
490,18,92,4,0,
490,18,94,4,0,
490,18,104,4,0,
490,18,113,4,0,
490,18,115,4,0,
490,18,127,4,0,
490,18,145,1,1,2
490,18,151,1,31,
490,18,156,4,0,
490,18,164,4,0,
490,18,182,4,0,
490,18,204,1,9,
490,18,207,4,0,
490,18,214,4,0,
490,18,216,4,0,
490,18,218,4,0,
490,18,219,4,0,
490,18,237,4,0,
490,18,240,1,69,
490,18,240,4,0,
490,18,244,4,0,
490,18,247,4,0,
490,18,250,1,39,
490,18,258,4,0,
490,18,263,4,0,
490,18,291,1,61,
490,18,294,1,1,1
490,18,346,1,1,3
490,18,347,4,0,
490,18,352,1,46,
490,18,369,4,0,
490,18,374,4,0,
490,18,391,1,76,
490,18,392,1,54,
490,18,412,4,0,
490,18,416,4,0,
490,18,447,4,0,
490,18,496,4,0,
490,18,503,4,0,
490,18,590,4,0,
490,18,605,4,0,
491,8,14,4,0,
491,8,15,4,0,
491,8,50,1,1,2
491,8,58,4,0,
491,8,59,4,0,
491,8,63,4,0,
491,8,70,4,0,
491,8,85,4,0,
491,8,86,4,0,
491,8,87,4,0,
491,8,92,4,0,
491,8,94,4,0,
491,8,95,1,20,
491,8,98,1,11,
491,8,101,1,1,1
491,8,104,1,47,
491,8,104,4,0,
491,8,114,1,57,
491,8,138,1,84,
491,8,138,4,0,
491,8,148,4,0,
491,8,156,4,0,
491,8,157,4,0,
491,8,164,4,0,
491,8,168,4,0,
491,8,171,1,38,
491,8,182,4,0,
491,8,188,4,0,
491,8,203,4,0,
491,8,207,4,0,
491,8,214,4,0,
491,8,216,4,0,
491,8,218,4,0,
491,8,228,1,29,
491,8,237,4,0,
491,8,240,4,0,
491,8,241,4,0,
491,8,244,4,0,
491,8,247,4,0,
491,8,249,4,0,
491,8,259,4,0,
491,8,261,4,0,
491,8,263,4,0,
491,8,264,4,0,
491,8,269,4,0,
491,8,280,4,0,
491,8,289,4,0,
491,8,290,4,0,
491,8,317,4,0,
491,8,332,4,0,
491,8,347,4,0,
491,8,351,4,0,
491,8,363,4,0,
491,8,371,4,0,
491,8,373,1,75,
491,8,373,4,0,
491,8,374,4,0,
491,8,398,4,0,
491,8,399,1,93,
491,8,399,4,0,
491,8,404,4,0,
491,8,409,4,0,
491,8,411,4,0,
491,8,416,4,0,
491,8,421,4,0,
491,8,431,4,0,
491,8,451,4,0,
491,8,464,1,66,
491,9,14,4,0,
491,9,15,4,0,
491,9,50,1,1,2
491,9,58,4,0,
491,9,59,4,0,
491,9,63,4,0,
491,9,70,4,0,
491,9,85,4,0,
491,9,86,4,0,
491,9,87,4,0,
491,9,92,4,0,
491,9,94,4,0,
491,9,95,1,20,
491,9,98,1,11,
491,9,104,1,47,
491,9,104,4,0,
491,9,114,1,57,
491,9,129,3,0,
491,9,138,1,84,
491,9,138,4,0,
491,9,148,4,0,
491,9,156,4,0,
491,9,157,4,0,
491,9,164,4,0,
491,9,168,4,0,
491,9,171,1,38,
491,9,173,3,0,
491,9,180,3,0,
491,9,182,4,0,
491,9,185,1,29,
491,9,188,4,0,
491,9,189,3,0,
491,9,196,3,0,
491,9,203,4,0,
491,9,207,4,0,
491,9,214,4,0,
491,9,216,4,0,
491,9,218,4,0,
491,9,237,4,0,
491,9,240,4,0,
491,9,241,4,0,
491,9,244,4,0,
491,9,247,4,0,
491,9,249,4,0,
491,9,259,4,0,
491,9,261,4,0,
491,9,263,4,0,
491,9,264,4,0,
491,9,269,4,0,
491,9,271,3,0,
491,9,280,4,0,
491,9,282,3,0,
491,9,289,4,0,
491,9,290,4,0,
491,9,317,4,0,
491,9,332,4,0,
491,9,347,4,0,
491,9,351,4,0,
491,9,363,4,0,
491,9,371,4,0,
491,9,373,4,0,
491,9,374,4,0,
491,9,387,3,0,
491,9,389,3,0,
491,9,398,4,0,
491,9,399,1,93,
491,9,399,4,0,
491,9,404,4,0,
491,9,409,4,0,
491,9,411,4,0,
491,9,416,4,0,
491,9,417,1,75,
491,9,421,4,0,
491,9,431,4,0,
491,9,451,4,0,
491,9,464,1,66,
491,9,466,1,1,1
491,9,466,3,0,
491,10,14,4,0,
491,10,15,4,0,
491,10,29,3,0,
491,10,50,1,1,2
491,10,58,4,0,
491,10,59,4,0,
491,10,63,4,0,
491,10,70,4,0,
491,10,85,4,0,
491,10,86,4,0,
491,10,87,4,0,
491,10,92,4,0,
491,10,94,4,0,
491,10,95,1,20,
491,10,98,1,11,
491,10,104,1,47,
491,10,104,4,0,
491,10,114,1,57,
491,10,129,3,0,
491,10,138,1,84,
491,10,138,4,0,
491,10,148,4,0,
491,10,156,4,0,
491,10,157,4,0,
491,10,164,4,0,
491,10,168,4,0,
491,10,171,1,38,
491,10,173,3,0,
491,10,180,3,0,
491,10,182,4,0,
491,10,185,1,29,
491,10,188,4,0,
491,10,189,3,0,
491,10,196,3,0,
491,10,203,4,0,
491,10,207,4,0,
491,10,214,4,0,
491,10,216,4,0,
491,10,218,4,0,
491,10,237,4,0,
491,10,240,4,0,
491,10,241,4,0,
491,10,244,4,0,
491,10,247,4,0,
491,10,249,4,0,
491,10,259,4,0,
491,10,261,4,0,
491,10,263,4,0,
491,10,264,4,0,
491,10,269,4,0,
491,10,271,3,0,
491,10,280,4,0,
491,10,282,3,0,
491,10,289,4,0,
491,10,290,4,0,
491,10,317,4,0,
491,10,332,4,0,
491,10,347,4,0,
491,10,351,4,0,
491,10,363,4,0,
491,10,371,4,0,
491,10,373,4,0,
491,10,374,4,0,
491,10,387,3,0,
491,10,389,3,0,
491,10,398,4,0,
491,10,399,1,93,
491,10,399,4,0,
491,10,404,4,0,
491,10,409,4,0,
491,10,411,4,0,
491,10,416,4,0,
491,10,417,1,75,
491,10,421,4,0,
491,10,431,4,0,
491,10,451,4,0,
491,10,464,1,66,
491,10,466,1,1,1
491,10,466,3,0,
491,11,14,4,0,
491,11,15,4,0,
491,11,50,1,1,2
491,11,58,4,0,
491,11,59,4,0,
491,11,63,4,0,
491,11,70,4,0,
491,11,85,4,0,
491,11,86,4,0,
491,11,87,4,0,
491,11,92,4,0,
491,11,94,4,0,
491,11,95,1,20,
491,11,98,1,11,
491,11,104,1,47,
491,11,104,4,0,
491,11,114,1,57,
491,11,138,1,84,
491,11,138,4,0,
491,11,148,4,0,
491,11,156,4,0,
491,11,157,4,0,
491,11,164,4,0,
491,11,168,4,0,
491,11,171,1,38,
491,11,182,4,0,
491,11,185,1,29,
491,11,188,4,0,
491,11,207,4,0,
491,11,216,4,0,
491,11,218,4,0,
491,11,237,4,0,
491,11,240,4,0,
491,11,241,4,0,
491,11,244,4,0,
491,11,247,4,0,
491,11,249,4,0,
491,11,259,4,0,
491,11,261,4,0,
491,11,263,4,0,
491,11,269,4,0,
491,11,280,4,0,
491,11,317,4,0,
491,11,332,4,0,
491,11,347,4,0,
491,11,371,4,0,
491,11,373,4,0,
491,11,374,4,0,
491,11,398,4,0,
491,11,399,1,93,
491,11,404,4,0,
491,11,411,4,0,
491,11,416,4,0,
491,11,417,1,75,
491,11,421,4,0,
491,11,451,4,0,
491,11,464,1,66,
491,11,466,1,1,1
491,11,496,4,0,
491,11,510,4,0,
491,11,514,4,0,
491,11,555,4,0,
491,14,14,4,0,
491,14,15,4,0,
491,14,50,1,1,2
491,14,58,4,0,
491,14,59,4,0,
491,14,63,4,0,
491,14,70,4,0,
491,14,85,4,0,
491,14,86,4,0,
491,14,87,4,0,
491,14,92,4,0,
491,14,94,4,0,
491,14,95,1,20,
491,14,98,1,11,
491,14,104,1,47,
491,14,104,4,0,
491,14,114,1,57,
491,14,138,1,84,
491,14,138,4,0,
491,14,148,4,0,
491,14,156,4,0,
491,14,157,4,0,
491,14,164,4,0,
491,14,168,4,0,
491,14,171,1,38,
491,14,173,3,0,
491,14,180,3,0,
491,14,182,4,0,
491,14,185,1,29,
491,14,188,4,0,
491,14,196,3,0,
491,14,207,4,0,
491,14,214,3,0,
491,14,216,4,0,
491,14,218,4,0,
491,14,237,4,0,
491,14,240,4,0,
491,14,241,4,0,
491,14,244,4,0,
491,14,247,4,0,
491,14,249,4,0,
491,14,259,4,0,
491,14,261,4,0,
491,14,263,4,0,
491,14,269,4,0,
491,14,271,3,0,
491,14,280,4,0,
491,14,282,3,0,
491,14,289,3,0,
491,14,317,4,0,
491,14,332,4,0,
491,14,347,4,0,
491,14,371,4,0,
491,14,373,4,0,
491,14,374,4,0,
491,14,387,3,0,
491,14,398,4,0,
491,14,399,1,93,
491,14,399,3,0,
491,14,404,4,0,
491,14,409,3,0,
491,14,411,4,0,
491,14,416,4,0,
491,14,417,1,75,
491,14,421,4,0,
491,14,451,4,0,
491,14,464,1,66,
491,14,466,1,1,1
491,14,472,3,0,
491,14,492,3,0,
491,14,496,4,0,
491,14,510,4,0,
491,14,514,4,0,
491,14,555,4,0,
491,15,14,4,0,
491,15,15,4,0,
491,15,50,1,1,2
491,15,58,4,0,
491,15,59,4,0,
491,15,63,4,0,
491,15,70,4,0,
491,15,85,4,0,
491,15,86,4,0,
491,15,87,4,0,
491,15,92,4,0,
491,15,94,4,0,
491,15,95,1,20,
491,15,98,1,11,
491,15,104,1,47,
491,15,104,4,0,
491,15,114,1,57,
491,15,138,1,84,
491,15,138,4,0,
491,15,148,4,0,
491,15,156,4,0,
491,15,157,4,0,
491,15,164,4,0,
491,15,168,4,0,
491,15,171,1,38,
491,15,182,4,0,
491,15,185,1,29,
491,15,188,4,0,
491,15,207,4,0,
491,15,214,4,0,
491,15,216,4,0,
491,15,218,4,0,
491,15,237,4,0,
491,15,240,4,0,
491,15,241,4,0,
491,15,244,4,0,
491,15,247,4,0,
491,15,249,4,0,
491,15,259,4,0,
491,15,261,4,0,
491,15,263,4,0,
491,15,269,4,0,
491,15,280,4,0,
491,15,317,4,0,
491,15,332,4,0,
491,15,347,4,0,
491,15,371,4,0,
491,15,373,4,0,
491,15,374,4,0,
491,15,398,4,0,
491,15,399,1,93,
491,15,399,4,0,
491,15,404,4,0,
491,15,411,4,0,
491,15,416,4,0,
491,15,417,1,75,
491,15,421,4,0,
491,15,451,4,0,
491,15,464,1,66,
491,15,466,1,1,1
491,15,496,4,0,
491,15,510,4,0,
491,15,514,4,0,
491,15,555,4,0,
491,15,590,4,0,
491,15,612,4,0,
491,16,14,4,0,
491,16,15,4,0,
491,16,50,1,1,2
491,16,58,4,0,
491,16,59,4,0,
491,16,63,4,0,
491,16,70,4,0,
491,16,85,4,0,
491,16,86,4,0,
491,16,87,4,0,
491,16,92,4,0,
491,16,94,4,0,
491,16,95,1,20,1
491,16,98,1,11,1
491,16,104,1,47,1
491,16,104,4,0,
491,16,114,1,57,1
491,16,138,1,84,1
491,16,138,4,0,
491,16,148,4,0,
491,16,156,4,0,
491,16,157,4,0,
491,16,164,4,0,
491,16,168,4,0,
491,16,171,1,38,1
491,16,173,3,0,
491,16,180,3,0,
491,16,182,4,0,
491,16,185,1,29,1
491,16,188,4,0,
491,16,196,3,0,
491,16,207,4,0,
491,16,214,4,0,
491,16,216,4,0,
491,16,218,4,0,
491,16,237,4,0,
491,16,240,4,0,
491,16,241,4,0,
491,16,244,4,0,
491,16,247,4,0,
491,16,249,4,0,
491,16,259,4,0,
491,16,261,4,0,
491,16,263,4,0,
491,16,264,3,0,
491,16,269,4,0,
491,16,271,3,0,
491,16,280,4,0,
491,16,282,3,0,
491,16,289,3,0,
491,16,290,4,0,
491,16,317,4,0,
491,16,332,4,0,
491,16,347,4,0,
491,16,351,3,0,
491,16,371,4,0,
491,16,373,4,0,
491,16,374,4,0,
491,16,387,3,0,
491,16,398,4,0,
491,16,399,1,93,1
491,16,399,4,0,
491,16,404,4,0,
491,16,409,3,0,
491,16,411,4,0,
491,16,416,4,0,
491,16,417,1,75,1
491,16,421,4,0,
491,16,451,4,0,
491,16,464,1,66,1
491,16,466,1,1,1
491,16,472,3,0,
491,16,492,3,0,
491,16,496,4,0,
491,16,510,4,0,
491,16,514,4,0,
491,16,555,4,0,
491,16,590,4,0,
491,16,612,4,0,
491,17,14,4,0,
491,17,50,1,1,2
491,17,58,4,0,
491,17,59,4,0,
491,17,63,4,0,
491,17,85,4,0,
491,17,86,4,0,
491,17,87,4,0,
491,17,92,4,0,
491,17,94,4,0,
491,17,95,1,20,
491,17,98,1,11,
491,17,104,1,47,
491,17,104,4,0,
491,17,114,1,57,
491,17,138,1,84,
491,17,138,4,0,
491,17,156,4,0,
491,17,157,4,0,
491,17,164,4,0,
491,17,168,4,0,
491,17,171,1,38,
491,17,182,4,0,
491,17,185,1,29,
491,17,188,4,0,
491,17,207,4,0,
491,17,214,4,0,
491,17,216,4,0,
491,17,218,4,0,
491,17,237,4,0,
491,17,240,4,0,
491,17,241,4,0,
491,17,244,4,0,
491,17,247,4,0,
491,17,259,4,0,
491,17,261,4,0,
491,17,263,4,0,
491,17,269,4,0,
491,17,280,4,0,
491,17,317,4,0,
491,17,332,4,0,
491,17,347,4,0,
491,17,371,4,0,
491,17,373,4,0,
491,17,374,4,0,
491,17,398,4,0,
491,17,399,1,93,
491,17,399,4,0,
491,17,404,4,0,
491,17,411,4,0,
491,17,416,4,0,
491,17,417,1,75,
491,17,421,4,0,
491,17,451,4,0,
491,17,464,1,66,
491,17,466,1,1,1
491,17,496,4,0,
491,17,555,4,0,
491,17,590,4,0,
491,18,14,4,0,
491,18,50,1,1,2
491,18,58,4,0,
491,18,59,4,0,
491,18,63,4,0,
491,18,85,4,0,
491,18,86,4,0,
491,18,87,4,0,
491,18,92,4,0,
491,18,94,4,0,
491,18,95,1,20,
491,18,98,1,11,
491,18,104,1,47,
491,18,104,4,0,
491,18,114,1,57,
491,18,138,1,84,
491,18,138,4,0,
491,18,156,4,0,
491,18,157,4,0,
491,18,164,4,0,
491,18,168,4,0,
491,18,171,1,38,
491,18,182,4,0,
491,18,185,1,29,
491,18,188,4,0,
491,18,207,4,0,
491,18,214,4,0,
491,18,216,4,0,
491,18,218,4,0,
491,18,237,4,0,
491,18,240,4,0,
491,18,241,4,0,
491,18,244,4,0,
491,18,247,4,0,
491,18,259,4,0,
491,18,261,4,0,
491,18,263,4,0,
491,18,269,4,0,
491,18,280,4,0,
491,18,317,4,0,
491,18,332,4,0,
491,18,347,4,0,
491,18,371,4,0,
491,18,373,4,0,
491,18,374,4,0,
491,18,398,4,0,
491,18,399,1,93,
491,18,399,4,0,
491,18,404,4,0,
491,18,411,4,0,
491,18,416,4,0,
491,18,417,1,75,
491,18,421,4,0,
491,18,451,4,0,
491,18,464,1,66,
491,18,466,1,1,1
491,18,496,4,0,
491,18,555,4,0,
491,18,590,4,0,
492,8,14,4,0,
492,8,63,4,0,
492,8,73,1,19,
492,8,76,4,0,
492,8,92,4,0,
492,8,94,4,0,
492,8,104,4,0,
492,8,111,1,1,
492,8,148,4,0,
492,8,156,4,0,
492,8,164,4,0,
492,8,182,4,0,
492,8,202,4,0,
492,8,203,4,0,
492,8,207,4,0,
492,8,214,4,0,
492,8,216,4,0,
492,8,218,4,0,
492,8,219,4,0,
492,8,230,1,37,
492,8,235,1,28,
492,8,237,4,0,
492,8,241,4,0,
492,8,244,4,0,
492,8,263,4,0,
492,8,290,4,0,
492,8,312,1,64,
492,8,320,1,82,
492,8,331,4,0,
492,8,345,1,10,
492,8,363,1,46,
492,8,363,4,0,
492,8,381,1,91,
492,8,388,1,55,
492,8,412,1,73,
492,8,412,4,0,
492,8,416,4,0,
492,8,447,4,0,
492,8,465,1,100,
492,9,14,4,0,
492,9,63,4,0,
492,9,73,1,19,
492,9,74,1,1,
492,9,76,4,0,
492,9,92,4,0,
492,9,94,4,0,
492,9,104,4,0,
492,9,129,3,0,
492,9,148,4,0,
492,9,156,4,0,
492,9,164,4,0,
492,9,173,3,0,
492,9,182,4,0,
492,9,186,1,82,
492,9,189,3,0,
492,9,202,4,0,
492,9,203,4,0,
492,9,207,4,0,
492,9,214,4,0,
492,9,216,4,0,
492,9,218,4,0,
492,9,219,4,0,
492,9,230,1,37,
492,9,235,1,28,
492,9,235,3,0,
492,9,237,4,0,
492,9,241,4,0,
492,9,244,4,0,
492,9,263,4,0,
492,9,283,3,0,
492,9,290,4,0,
492,9,312,1,64,
492,9,331,4,0,
492,9,345,1,10,
492,9,361,1,91,
492,9,363,1,46,
492,9,363,4,0,
492,9,387,3,0,
492,9,388,1,55,
492,9,402,3,0,
492,9,412,1,73,
492,9,412,4,0,
492,9,414,3,0,
492,9,416,4,0,
492,9,428,3,0,
492,9,447,4,0,
492,9,465,1,100,
492,10,14,4,0,
492,10,29,3,0,
492,10,63,4,0,
492,10,73,1,19,
492,10,74,1,1,
492,10,76,4,0,
492,10,92,4,0,
492,10,94,4,0,
492,10,104,4,0,
492,10,129,3,0,
492,10,148,4,0,
492,10,156,4,0,
492,10,164,4,0,
492,10,173,3,0,
492,10,182,4,0,
492,10,186,1,82,
492,10,189,3,0,
492,10,202,4,0,
492,10,203,4,0,
492,10,207,4,0,
492,10,214,4,0,
492,10,216,4,0,
492,10,218,4,0,
492,10,219,4,0,
492,10,230,1,37,
492,10,235,1,28,
492,10,235,3,0,
492,10,237,4,0,
492,10,241,4,0,
492,10,244,4,0,
492,10,263,4,0,
492,10,283,3,0,
492,10,290,4,0,
492,10,312,1,64,
492,10,331,4,0,
492,10,345,1,10,
492,10,361,1,91,
492,10,363,1,46,
492,10,363,4,0,
492,10,387,3,0,
492,10,388,1,55,
492,10,388,3,0,
492,10,402,3,0,
492,10,412,1,73,
492,10,412,4,0,
492,10,414,3,0,
492,10,416,4,0,
492,10,428,3,0,
492,10,447,4,0,
492,10,465,1,100,
492,11,14,4,0,
492,11,63,4,0,
492,11,73,1,19,
492,11,74,1,1,
492,11,76,4,0,
492,11,92,4,0,
492,11,94,4,0,
492,11,104,4,0,
492,11,148,4,0,
492,11,156,4,0,
492,11,164,4,0,
492,11,182,4,0,
492,11,186,1,82,
492,11,207,4,0,
492,11,216,4,0,
492,11,218,4,0,
492,11,219,4,0,
492,11,230,1,37,
492,11,235,1,28,
492,11,237,4,0,
492,11,241,4,0,
492,11,244,4,0,
492,11,263,4,0,
492,11,312,1,64,
492,11,345,1,10,
492,11,361,1,91,
492,11,363,1,46,
492,11,388,1,55,
492,11,412,1,73,
492,11,412,4,0,
492,11,416,4,0,
492,11,447,4,0,
492,11,465,1,100,
492,11,496,4,0,
492,14,14,4,0,
492,14,63,4,0,
492,14,73,1,19,
492,14,74,1,1,
492,14,76,4,0,
492,14,92,4,0,
492,14,94,4,0,
492,14,104,4,0,
492,14,148,4,0,
492,14,156,4,0,
492,14,164,4,0,
492,14,173,3,0,
492,14,182,4,0,
492,14,186,1,82,
492,14,202,3,0,
492,14,207,4,0,
492,14,214,3,0,
492,14,216,4,0,
492,14,218,4,0,
492,14,219,4,0,
492,14,230,1,37,
492,14,235,1,28,
492,14,235,3,0,
492,14,237,4,0,
492,14,241,4,0,
492,14,244,4,0,
492,14,263,4,0,
492,14,283,3,0,
492,14,312,1,64,
492,14,343,3,0,
492,14,345,1,10,
492,14,361,1,91,
492,14,363,1,46,
492,14,387,3,0,
492,14,388,1,55,
492,14,388,3,0,
492,14,402,3,0,
492,14,412,1,73,
492,14,412,4,0,
492,14,414,3,0,
492,14,416,4,0,
492,14,428,3,0,
492,14,447,4,0,
492,14,465,1,100,
492,14,496,4,0,
492,15,14,4,0,
492,15,63,4,0,
492,15,73,1,19,
492,15,74,1,1,
492,15,76,4,0,
492,15,92,4,0,
492,15,94,4,0,
492,15,104,4,0,
492,15,148,4,0,
492,15,156,4,0,
492,15,164,4,0,
492,15,182,4,0,
492,15,186,1,82,
492,15,207,4,0,
492,15,214,4,0,
492,15,216,4,0,
492,15,218,4,0,
492,15,219,4,0,
492,15,230,1,37,
492,15,235,1,28,
492,15,237,4,0,
492,15,241,4,0,
492,15,244,4,0,
492,15,263,4,0,
492,15,267,4,0,
492,15,312,1,64,
492,15,345,1,10,
492,15,361,1,91,
492,15,363,1,46,
492,15,388,1,55,
492,15,412,1,73,
492,15,412,4,0,
492,15,416,4,0,
492,15,447,4,0,
492,15,465,1,100,
492,15,496,4,0,
492,15,590,4,0,
492,15,605,4,0,
492,16,14,4,0,
492,16,63,4,0,
492,16,73,1,19,1
492,16,74,1,1,1
492,16,76,4,0,
492,16,92,4,0,
492,16,94,4,0,
492,16,104,4,0,
492,16,148,4,0,
492,16,156,4,0,
492,16,164,4,0,
492,16,173,3,0,
492,16,182,4,0,
492,16,186,1,82,1
492,16,202,3,0,
492,16,207,4,0,
492,16,214,4,0,
492,16,216,4,0,
492,16,218,4,0,
492,16,219,4,0,
492,16,230,1,37,1
492,16,235,1,28,1
492,16,235,3,0,
492,16,237,4,0,
492,16,241,4,0,
492,16,244,4,0,
492,16,263,4,0,
492,16,267,4,0,
492,16,283,3,0,
492,16,290,4,0,
492,16,312,1,64,1
492,16,343,3,0,
492,16,345,1,10,1
492,16,361,1,91,1
492,16,363,1,46,1
492,16,387,3,0,
492,16,388,1,55,1
492,16,388,3,0,
492,16,402,3,0,
492,16,412,1,73,1
492,16,412,4,0,
492,16,414,3,0,
492,16,416,4,0,
492,16,428,3,0,
492,16,447,4,0,
492,16,465,1,100,1
492,16,496,4,0,
492,16,590,4,0,
492,16,605,4,0,
492,17,14,4,0,
492,17,63,4,0,
492,17,73,1,19,
492,17,74,1,1,
492,17,76,4,0,
492,17,92,4,0,
492,17,94,4,0,
492,17,104,4,0,
492,17,156,4,0,
492,17,164,4,0,
492,17,182,4,0,
492,17,186,1,82,
492,17,207,4,0,
492,17,214,4,0,
492,17,216,4,0,
492,17,218,4,0,
492,17,219,4,0,
492,17,230,1,37,
492,17,235,1,28,
492,17,237,4,0,
492,17,241,4,0,
492,17,244,4,0,
492,17,263,4,0,
492,17,267,4,0,
492,17,312,1,64,
492,17,345,1,10,
492,17,361,1,91,
492,17,363,1,46,
492,17,388,1,55,
492,17,412,1,73,
492,17,412,4,0,
492,17,416,4,0,
492,17,447,4,0,
492,17,465,1,100,
492,17,496,4,0,
492,17,590,4,0,
492,17,605,4,0,
492,18,14,4,0,
492,18,63,4,0,
492,18,73,1,19,
492,18,74,1,1,
492,18,76,4,0,
492,18,92,4,0,
492,18,94,4,0,
492,18,104,4,0,
492,18,156,4,0,
492,18,164,4,0,
492,18,182,4,0,
492,18,186,1,82,
492,18,207,4,0,
492,18,214,4,0,
492,18,216,4,0,
492,18,218,4,0,
492,18,219,4,0,
492,18,230,1,37,
492,18,235,1,28,
492,18,237,4,0,
492,18,241,4,0,
492,18,244,4,0,
492,18,263,4,0,
492,18,267,4,0,
492,18,312,1,64,
492,18,345,1,10,
492,18,361,1,91,
492,18,363,1,46,
492,18,388,1,55,
492,18,412,1,73,
492,18,412,4,0,
492,18,416,4,0,
492,18,447,4,0,
492,18,465,1,100,
492,18,496,4,0,
492,18,590,4,0,
492,18,605,4,0,
493,8,14,4,0,
493,8,15,4,0,
493,8,19,4,0,
493,8,46,4,0,
493,8,53,4,0,
493,8,57,4,0,
493,8,58,4,0,
493,8,59,4,0,
493,8,63,1,80,
493,8,63,4,0,
493,8,69,1,1,1
493,8,70,4,0,
493,8,76,4,0,
493,8,85,4,0,
493,8,86,4,0,
493,8,87,4,0,
493,8,89,4,0,
493,8,92,4,0,
493,8,94,4,0,
493,8,104,4,0,
493,8,105,1,70,
493,8,113,4,0,
493,8,115,4,0,
493,8,126,4,0,
493,8,127,4,0,
493,8,138,4,0,
493,8,148,4,0,
493,8,156,4,0,
493,8,157,4,0,
493,8,164,4,0,
493,8,182,4,0,
493,8,188,4,0,
493,8,195,1,90,
493,8,201,4,0,
493,8,202,4,0,
493,8,203,4,0,
493,8,207,4,0,
493,8,214,4,0,
493,8,216,4,0,
493,8,218,4,0,
493,8,219,4,0,
493,8,231,4,0,
493,8,237,4,0,
493,8,240,4,0,
493,8,241,4,0,
493,8,244,4,0,
493,8,245,1,40,
493,8,247,4,0,
493,8,248,1,60,
493,8,249,4,0,
493,8,258,4,0,
493,8,261,4,0,
493,8,263,4,0,
493,8,278,4,0,
493,8,280,4,0,
493,8,287,1,50,
493,8,290,4,0,
493,8,304,1,30,
493,8,315,4,0,
493,8,317,4,0,
493,8,318,4,0,
493,8,322,1,1,2
493,8,331,4,0,
493,8,332,4,0,
493,8,337,4,0,
493,8,347,4,0,
493,8,351,4,0,
493,8,352,4,0,
493,8,356,1,10,
493,8,362,4,0,
493,8,363,1,1,3
493,8,363,4,0,
493,8,371,4,0,
493,8,386,1,1,4
493,8,398,4,0,
493,8,399,4,0,
493,8,404,4,0,
493,8,406,4,0,
493,8,411,4,0,
493,8,412,4,0,
493,8,414,1,20,
493,8,416,4,0,
493,8,419,4,0,
493,8,421,4,0,
493,8,430,4,0,
493,8,431,4,0,
493,8,432,4,0,
493,8,433,4,0,
493,8,434,3,0,
493,8,444,4,0,
493,8,446,4,0,
493,8,447,4,0,
493,8,449,1,100,
493,8,451,4,0,
493,9,14,4,0,
493,9,15,4,0,
493,9,19,4,0,
493,9,46,4,0,
493,9,53,4,0,
493,9,57,4,0,
493,9,58,4,0,
493,9,59,4,0,
493,9,63,1,80,
493,9,63,4,0,
493,9,69,1,1,1
493,9,70,4,0,
493,9,76,4,0,
493,9,85,4,0,
493,9,86,4,0,
493,9,87,4,0,
493,9,89,4,0,
493,9,92,4,0,
493,9,94,4,0,
493,9,104,4,0,
493,9,105,1,70,
493,9,113,4,0,
493,9,115,4,0,
493,9,126,4,0,
493,9,127,4,0,
493,9,129,3,0,
493,9,138,4,0,
493,9,148,4,0,
493,9,156,4,0,
493,9,157,4,0,
493,9,164,4,0,
493,9,173,3,0,
493,9,182,4,0,
493,9,188,4,0,
493,9,189,3,0,
493,9,195,1,90,
493,9,196,3,0,
493,9,200,3,0,
493,9,201,4,0,
493,9,202,4,0,
493,9,203,4,0,
493,9,207,4,0,
493,9,210,3,0,
493,9,214,4,0,
493,9,216,4,0,
493,9,218,4,0,
493,9,219,4,0,
493,9,231,4,0,
493,9,237,4,0,
493,9,239,3,0,
493,9,240,4,0,
493,9,241,4,0,
493,9,244,4,0,
493,9,245,1,40,
493,9,246,3,0,
493,9,247,4,0,
493,9,248,1,60,
493,9,249,4,0,
493,9,257,3,0,
493,9,258,4,0,
493,9,261,4,0,
493,9,263,4,0,
493,9,271,3,0,
493,9,278,4,0,
493,9,280,4,0,
493,9,287,1,50,
493,9,290,4,0,
493,9,291,3,0,
493,9,304,1,30,
493,9,315,4,0,
493,9,317,4,0,
493,9,318,4,0,
493,9,322,1,1,2
493,9,324,3,0,
493,9,331,4,0,
493,9,332,4,0,
493,9,334,3,0,
493,9,337,4,0,
493,9,347,4,0,
493,9,351,4,0,
493,9,352,4,0,
493,9,356,1,10,
493,9,362,4,0,
493,9,363,1,1,3
493,9,363,4,0,
493,9,371,4,0,
493,9,386,1,1,4
493,9,387,3,0,
493,9,398,4,0,
493,9,399,4,0,
493,9,401,3,0,
493,9,404,4,0,
493,9,406,4,0,
493,9,411,4,0,
493,9,412,4,0,
493,9,414,1,20,
493,9,414,3,0,
493,9,416,4,0,
493,9,419,4,0,
493,9,421,4,0,
493,9,428,3,0,
493,9,430,4,0,
493,9,431,4,0,
493,9,432,4,0,
493,9,433,4,0,
493,9,434,3,0,
493,9,442,3,0,
493,9,444,4,0,
493,9,446,4,0,
493,9,447,4,0,
493,9,449,1,100,
493,9,451,4,0,
493,9,466,3,0,
493,10,14,4,0,
493,10,15,4,0,
493,10,19,4,0,
493,10,29,3,0,
493,10,46,4,0,
493,10,53,4,0,
493,10,57,4,0,
493,10,58,4,0,
493,10,59,4,0,
493,10,63,1,80,
493,10,63,4,0,
493,10,69,1,1,1
493,10,70,4,0,
493,10,76,4,0,
493,10,85,4,0,
493,10,86,4,0,
493,10,87,4,0,
493,10,89,4,0,
493,10,92,4,0,
493,10,94,4,0,
493,10,104,4,0,
493,10,105,1,70,
493,10,113,4,0,
493,10,115,4,0,
493,10,126,4,0,
493,10,127,4,0,
493,10,129,3,0,
493,10,138,4,0,
493,10,148,4,0,
493,10,156,4,0,
493,10,157,4,0,
493,10,164,4,0,
493,10,173,3,0,
493,10,182,4,0,
493,10,188,4,0,
493,10,189,3,0,
493,10,195,1,90,
493,10,196,3,0,
493,10,200,3,0,
493,10,201,4,0,
493,10,202,4,0,
493,10,203,4,0,
493,10,207,4,0,
493,10,210,3,0,
493,10,214,4,0,
493,10,216,4,0,
493,10,218,4,0,
493,10,219,4,0,
493,10,231,4,0,
493,10,237,4,0,
493,10,239,3,0,
493,10,240,4,0,
493,10,241,4,0,
493,10,244,4,0,
493,10,245,1,40,
493,10,246,3,0,
493,10,247,4,0,
493,10,248,1,60,
493,10,249,4,0,
493,10,250,4,0,
493,10,257,3,0,
493,10,258,4,0,
493,10,261,4,0,
493,10,263,4,0,
493,10,271,3,0,
493,10,277,3,0,
493,10,278,4,0,
493,10,280,4,0,
493,10,287,1,50,
493,10,290,4,0,
493,10,291,3,0,
493,10,304,1,30,
493,10,315,4,0,
493,10,317,4,0,
493,10,318,4,0,
493,10,322,1,1,2
493,10,324,3,0,
493,10,331,4,0,
493,10,332,4,0,
493,10,334,3,0,
493,10,337,4,0,
493,10,347,4,0,
493,10,351,4,0,
493,10,352,4,0,
493,10,356,1,10,
493,10,356,3,0,
493,10,362,4,0,
493,10,363,1,1,3
493,10,363,4,0,
493,10,366,3,0,
493,10,371,4,0,
493,10,386,1,1,4
493,10,387,3,0,
493,10,398,4,0,
493,10,399,4,0,
493,10,401,3,0,
493,10,404,4,0,
493,10,406,4,0,
493,10,411,4,0,
493,10,412,4,0,
493,10,414,1,20,
493,10,414,3,0,
493,10,416,4,0,
493,10,419,4,0,
493,10,421,4,0,
493,10,428,3,0,
493,10,430,4,0,
493,10,431,4,0,
493,10,433,4,0,
493,10,434,3,0,
493,10,442,3,0,
493,10,444,4,0,
493,10,446,4,0,
493,10,447,4,0,
493,10,449,1,100,
493,10,451,4,0,
493,10,466,3,0,
493,11,14,4,0,
493,11,15,4,0,
493,11,19,4,0,
493,11,46,4,0,
493,11,53,4,0,
493,11,57,4,0,
493,11,58,4,0,
493,11,59,4,0,
493,11,63,1,80,
493,11,63,4,0,
493,11,69,1,1,1
493,11,70,4,0,
493,11,76,4,0,
493,11,85,4,0,
493,11,86,4,0,
493,11,87,4,0,
493,11,89,4,0,
493,11,92,4,0,
493,11,94,4,0,
493,11,104,4,0,
493,11,105,1,70,
493,11,113,4,0,
493,11,115,4,0,
493,11,126,4,0,
493,11,127,4,0,
493,11,138,4,0,
493,11,148,4,0,
493,11,156,4,0,
493,11,157,4,0,
493,11,164,4,0,
493,11,182,4,0,
493,11,188,4,0,
493,11,195,1,90,
493,11,201,4,0,
493,11,207,4,0,
493,11,216,4,0,
493,11,218,4,0,
493,11,219,4,0,
493,11,237,4,0,
493,11,240,4,0,
493,11,241,4,0,
493,11,244,4,0,
493,11,245,1,40,
493,11,247,4,0,
493,11,248,1,60,
493,11,249,4,0,
493,11,258,4,0,
493,11,261,4,0,
493,11,263,4,0,
493,11,280,4,0,
493,11,287,1,50,
493,11,304,1,30,
493,11,315,4,0,
493,11,317,4,0,
493,11,322,1,1,2
493,11,332,4,0,
493,11,337,4,0,
493,11,347,4,0,
493,11,356,1,10,
493,11,363,1,1,3
493,11,371,4,0,
493,11,386,1,1,4
493,11,398,4,0,
493,11,404,4,0,
493,11,411,4,0,
493,11,412,4,0,
493,11,414,1,20,
493,11,416,4,0,
493,11,421,4,0,
493,11,430,4,0,
493,11,433,4,0,
493,11,434,3,0,
493,11,444,4,0,
493,11,447,4,0,
493,11,449,1,100,
493,11,451,4,0,
493,11,468,4,0,
493,11,473,4,0,
493,11,477,4,0,
493,11,496,4,0,
493,11,497,4,0,
493,11,510,4,0,
493,11,511,4,0,
493,11,514,4,0,
493,11,523,4,0,
493,11,526,4,0,
493,11,555,4,0,
493,14,14,4,0,
493,14,15,4,0,
493,14,19,4,0,
493,14,46,4,0,
493,14,53,4,0,
493,14,57,4,0,
493,14,58,4,0,
493,14,59,4,0,
493,14,63,1,80,
493,14,63,4,0,
493,14,69,1,1,1
493,14,70,4,0,
493,14,76,4,0,
493,14,85,4,0,
493,14,86,4,0,
493,14,87,4,0,
493,14,89,4,0,
493,14,92,4,0,
493,14,94,4,0,
493,14,104,4,0,
493,14,105,1,70,
493,14,113,4,0,
493,14,115,4,0,
493,14,126,4,0,
493,14,127,4,0,
493,14,138,4,0,
493,14,148,4,0,
493,14,156,4,0,
493,14,157,4,0,
493,14,164,4,0,
493,14,173,3,0,
493,14,182,4,0,
493,14,188,4,0,
493,14,195,1,90,
493,14,196,3,0,
493,14,200,3,0,
493,14,201,4,0,
493,14,202,3,0,
493,14,207,4,0,
493,14,214,3,0,
493,14,216,4,0,
493,14,218,4,0,
493,14,219,4,0,
493,14,231,3,0,
493,14,237,4,0,
493,14,240,4,0,
493,14,241,4,0,
493,14,244,4,0,
493,14,245,1,40,
493,14,247,4,0,
493,14,248,1,60,
493,14,249,4,0,
493,14,257,3,0,
493,14,258,4,0,
493,14,261,4,0,
493,14,263,4,0,
493,14,271,3,0,
493,14,277,3,0,
493,14,278,3,0,
493,14,280,4,0,
493,14,287,1,50,
493,14,304,1,30,
493,14,304,3,0,
493,14,315,4,0,
493,14,317,4,0,
493,14,322,1,1,2
493,14,324,3,0,
493,14,332,4,0,
493,14,334,3,0,
493,14,337,4,0,
493,14,347,4,0,
493,14,356,1,10,
493,14,356,3,0,
493,14,363,1,1,3
493,14,366,3,0,
493,14,371,4,0,
493,14,386,1,1,4
493,14,387,3,0,
493,14,398,4,0,
493,14,399,3,0,
493,14,401,3,0,
493,14,404,4,0,
493,14,406,3,0,
493,14,411,4,0,
493,14,412,4,0,
493,14,414,1,20,
493,14,414,3,0,
493,14,416,4,0,
493,14,421,4,0,
493,14,428,3,0,
493,14,430,4,0,
493,14,433,4,0,
493,14,434,3,0,
493,14,442,3,0,
493,14,444,4,0,
493,14,446,3,0,
493,14,447,4,0,
493,14,449,1,100,
493,14,451,4,0,
493,14,468,4,0,
493,14,473,4,0,
493,14,477,4,0,
493,14,496,4,0,
493,14,497,4,0,
493,14,510,4,0,
493,14,511,4,0,
493,14,514,4,0,
493,14,523,4,0,
493,14,526,4,0,
493,14,555,4,0,
493,15,14,4,0,
493,15,15,4,0,
493,15,19,4,0,
493,15,46,4,0,
493,15,53,4,0,
493,15,57,4,0,
493,15,58,4,0,
493,15,59,4,0,
493,15,63,1,80,
493,15,63,4,0,
493,15,69,1,1,1
493,15,70,4,0,
493,15,76,4,0,
493,15,85,4,0,
493,15,86,4,0,
493,15,87,4,0,
493,15,89,4,0,
493,15,92,4,0,
493,15,94,4,0,
493,15,104,4,0,
493,15,105,1,70,
493,15,113,4,0,
493,15,115,4,0,
493,15,126,4,0,
493,15,127,4,0,
493,15,138,4,0,
493,15,148,4,0,
493,15,156,4,0,
493,15,157,4,0,
493,15,164,4,0,
493,15,182,4,0,
493,15,188,4,0,
493,15,195,1,90,
493,15,201,4,0,
493,15,207,4,0,
493,15,214,4,0,
493,15,216,4,0,
493,15,218,4,0,
493,15,219,4,0,
493,15,237,4,0,
493,15,240,4,0,
493,15,241,4,0,
493,15,244,4,0,
493,15,245,1,40,
493,15,247,4,0,
493,15,248,1,60,
493,15,249,4,0,
493,15,258,4,0,
493,15,261,4,0,
493,15,263,4,0,
493,15,280,4,0,
493,15,287,1,50,
493,15,304,1,30,
493,15,315,4,0,
493,15,317,4,0,
493,15,322,1,1,2
493,15,332,4,0,
493,15,337,4,0,
493,15,347,4,0,
493,15,356,1,10,
493,15,363,1,1,3
493,15,371,4,0,
493,15,386,1,1,4
493,15,398,4,0,
493,15,399,4,0,
493,15,404,4,0,
493,15,411,4,0,
493,15,412,4,0,
493,15,414,1,20,
493,15,416,4,0,
493,15,421,4,0,
493,15,430,4,0,
493,15,433,4,0,
493,15,434,3,0,
493,15,444,4,0,
493,15,447,4,0,
493,15,449,1,100,
493,15,451,4,0,
493,15,468,4,0,
493,15,473,4,0,
493,15,496,4,0,
493,15,497,4,0,
493,15,510,4,0,
493,15,511,4,0,
493,15,514,4,0,
493,15,523,4,0,
493,15,555,4,0,
493,15,590,4,0,
493,16,14,4,0,
493,16,15,4,0,
493,16,19,4,0,
493,16,46,4,0,
493,16,53,4,0,
493,16,57,4,0,
493,16,58,4,0,
493,16,59,4,0,
493,16,63,1,80,1
493,16,63,4,0,
493,16,69,1,1,1
493,16,70,4,0,
493,16,76,4,0,
493,16,85,4,0,
493,16,86,4,0,
493,16,87,4,0,
493,16,89,4,0,
493,16,92,4,0,
493,16,94,4,0,
493,16,104,4,0,
493,16,105,1,70,1
493,16,113,4,0,
493,16,115,4,0,
493,16,126,4,0,
493,16,127,4,0,
493,16,138,4,0,
493,16,148,4,0,
493,16,156,4,0,
493,16,157,4,0,
493,16,164,4,0,
493,16,173,3,0,
493,16,182,4,0,
493,16,188,4,0,
493,16,195,1,90,1
493,16,196,3,0,
493,16,200,3,0,
493,16,201,4,0,
493,16,202,3,0,
493,16,207,4,0,
493,16,214,4,0,
493,16,216,4,0,
493,16,218,4,0,
493,16,219,4,0,
493,16,231,3,0,
493,16,237,4,0,
493,16,240,4,0,
493,16,241,4,0,
493,16,244,4,0,
493,16,245,1,40,1
493,16,247,4,0,
493,16,248,1,60,1
493,16,249,4,0,
493,16,257,3,0,
493,16,258,4,0,
493,16,261,4,0,
493,16,263,4,0,
493,16,271,3,0,
493,16,277,3,0,
493,16,278,3,0,
493,16,280,4,0,
493,16,287,1,50,1
493,16,290,4,0,
493,16,291,4,0,
493,16,304,1,30,1
493,16,304,3,0,
493,16,315,4,0,
493,16,317,4,0,
493,16,322,1,1,2
493,16,324,3,0,
493,16,332,4,0,
493,16,334,3,0,
493,16,337,4,0,
493,16,347,4,0,
493,16,351,3,0,
493,16,352,3,0,
493,16,356,1,10,1
493,16,356,3,0,
493,16,363,1,1,3
493,16,366,3,0,
493,16,371,4,0,
493,16,386,1,1,4
493,16,387,3,0,
493,16,398,4,0,
493,16,399,4,0,
493,16,401,3,0,
493,16,404,4,0,
493,16,406,3,0,
493,16,411,4,0,
493,16,412,4,0,
493,16,414,1,20,1
493,16,414,3,0,
493,16,416,4,0,
493,16,421,4,0,
493,16,428,3,0,
493,16,430,4,0,
493,16,433,4,0,
493,16,434,3,0,
493,16,442,3,0,
493,16,444,4,0,
493,16,446,3,0,
493,16,447,4,0,
493,16,449,1,100,1
493,16,451,4,0,
493,16,468,4,0,
493,16,473,4,0,
493,16,496,4,0,
493,16,497,4,0,
493,16,510,4,0,
493,16,511,4,0,
493,16,514,4,0,
493,16,523,4,0,
493,16,555,4,0,
493,16,590,4,0,
493,17,14,4,0,
493,17,19,4,0,
493,17,46,4,0,
493,17,53,4,0,
493,17,57,4,0,
493,17,58,4,0,
493,17,59,4,0,
493,17,63,1,80,
493,17,63,4,0,
493,17,69,1,1,1
493,17,76,4,0,
493,17,85,4,0,
493,17,86,4,0,
493,17,87,4,0,
493,17,89,4,0,
493,17,92,4,0,
493,17,94,4,0,
493,17,104,4,0,
493,17,105,1,70,
493,17,113,4,0,
493,17,115,4,0,
493,17,126,4,0,
493,17,127,4,0,
493,17,138,4,0,
493,17,156,4,0,
493,17,157,4,0,
493,17,164,4,0,
493,17,182,4,0,
493,17,188,4,0,
493,17,195,1,90,
493,17,201,4,0,
493,17,207,4,0,
493,17,214,4,0,
493,17,216,4,0,
493,17,218,4,0,
493,17,219,4,0,
493,17,237,4,0,
493,17,240,4,0,
493,17,241,4,0,
493,17,244,4,0,
493,17,245,1,40,
493,17,247,4,0,
493,17,248,1,60,
493,17,258,4,0,
493,17,261,4,0,
493,17,263,4,0,
493,17,280,4,0,
493,17,287,1,50,
493,17,304,1,30,
493,17,315,4,0,
493,17,317,4,0,
493,17,322,1,1,2
493,17,332,4,0,
493,17,337,4,0,
493,17,347,4,0,
493,17,356,1,10,
493,17,363,1,1,3
493,17,371,4,0,
493,17,386,1,1,4
493,17,398,4,0,
493,17,399,4,0,
493,17,404,4,0,
493,17,411,4,0,
493,17,412,4,0,
493,17,414,1,20,
493,17,416,4,0,
493,17,421,4,0,
493,17,430,4,0,
493,17,433,4,0,
493,17,434,3,0,
493,17,444,4,0,
493,17,447,4,0,
493,17,449,1,100,
493,17,451,4,0,
493,17,473,4,0,
493,17,496,4,0,
493,17,497,4,0,
493,17,511,4,0,
493,17,523,4,0,
493,17,526,4,0,
493,17,555,4,0,
493,17,590,4,0,
493,18,14,4,0,
493,18,19,4,0,
493,18,46,4,0,
493,18,53,4,0,
493,18,57,4,0,
493,18,58,4,0,
493,18,59,4,0,
493,18,63,1,80,
493,18,63,4,0,
493,18,69,1,1,1
493,18,76,4,0,
493,18,85,4,0,
493,18,86,4,0,
493,18,87,4,0,
493,18,89,4,0,
493,18,92,4,0,
493,18,94,4,0,
493,18,104,4,0,
493,18,105,1,70,
493,18,113,4,0,
493,18,115,4,0,
493,18,126,4,0,
493,18,127,4,0,
493,18,138,4,0,
493,18,156,4,0,
493,18,157,4,0,
493,18,164,4,0,
493,18,182,4,0,
493,18,188,4,0,
493,18,195,1,90,
493,18,201,4,0,
493,18,207,4,0,
493,18,214,4,0,
493,18,216,4,0,
493,18,218,4,0,
493,18,219,4,0,
493,18,237,4,0,
493,18,240,4,0,
493,18,241,4,0,
493,18,244,4,0,
493,18,245,1,40,
493,18,247,4,0,
493,18,248,1,60,
493,18,258,4,0,
493,18,261,4,0,
493,18,263,4,0,
493,18,280,4,0,
493,18,287,1,50,
493,18,304,1,30,
493,18,315,4,0,
493,18,317,4,0,
493,18,322,1,1,2
493,18,332,4,0,
493,18,337,4,0,
493,18,347,4,0,
493,18,356,1,10,
493,18,363,1,1,3
493,18,371,4,0,
493,18,386,1,1,4
493,18,398,4,0,
493,18,399,4,0,
493,18,404,4,0,
493,18,411,4,0,
493,18,412,4,0,
493,18,414,1,20,
493,18,416,4,0,
493,18,421,4,0,
493,18,430,4,0,
493,18,433,4,0,
493,18,434,3,0,
493,18,444,4,0,
493,18,447,4,0,
493,18,449,1,100,
493,18,451,4,0,
493,18,473,4,0,
493,18,496,4,0,
493,18,497,4,0,
493,18,511,4,0,
493,18,523,4,0,
493,18,526,4,0,
493,18,555,4,0,
493,18,590,4,0,
494,11,29,1,17,
494,11,38,1,65,
494,11,53,4,0,
494,11,63,4,0,
494,11,76,4,0,
494,11,85,4,0,
494,11,86,4,0,
494,11,87,4,0,
494,11,92,4,0,
494,11,93,1,1,3
494,11,94,4,0,
494,11,98,1,1,5
494,11,104,4,0,
494,11,113,4,0,
494,11,116,1,1,2
494,11,126,4,0,
494,11,148,4,0,
494,11,156,4,0,
494,11,164,4,0,
494,11,179,1,33,
494,11,182,4,0,
494,11,203,1,9,
494,11,207,4,0,
494,11,216,4,0,
494,11,218,4,0,
494,11,219,4,0,
494,11,237,4,0,
494,11,241,4,0,
494,11,244,4,0,
494,11,247,4,0,
494,11,249,4,0,
494,11,261,4,0,
494,11,263,4,0,
494,11,269,4,0,
494,11,280,4,0,
494,11,315,1,97,
494,11,315,4,0,
494,11,369,4,0,
494,11,373,4,0,
494,11,374,4,0,
494,11,394,1,73,
494,11,411,4,0,
494,11,412,4,0,
494,11,416,4,0,
494,11,428,1,49,
494,11,433,4,0,
494,11,447,4,0,
494,11,451,4,0,
494,11,473,4,0,
494,11,477,4,0,
494,11,481,1,41,
494,11,488,1,25,
494,11,488,4,0,
494,11,496,4,0,
494,11,500,1,89,
494,11,510,1,1,4
494,11,510,4,0,
494,11,515,1,81,
494,11,517,1,57,
494,11,526,4,0,
494,11,528,4,0,
494,11,545,1,1,1
494,14,7,3,0,
494,14,9,3,0,
494,14,29,1,17,
494,14,38,1,65,
494,14,53,4,0,
494,14,63,4,0,
494,14,76,4,0,
494,14,85,4,0,
494,14,86,4,0,
494,14,87,4,0,
494,14,92,4,0,
494,14,93,1,1,3
494,14,94,4,0,
494,14,98,1,1,5
494,14,104,4,0,
494,14,113,4,0,
494,14,116,1,1,2
494,14,126,4,0,
494,14,148,4,0,
494,14,156,4,0,
494,14,164,4,0,
494,14,173,3,0,
494,14,179,1,33,
494,14,182,4,0,
494,14,203,1,9,
494,14,207,4,0,
494,14,214,3,0,
494,14,216,4,0,
494,14,218,4,0,
494,14,219,4,0,
494,14,237,4,0,
494,14,241,4,0,
494,14,244,4,0,
494,14,247,4,0,
494,14,249,4,0,
494,14,253,3,0,
494,14,257,3,0,
494,14,261,4,0,
494,14,263,4,0,
494,14,269,4,0,
494,14,270,3,0,
494,14,271,3,0,
494,14,272,3,0,
494,14,277,3,0,
494,14,280,4,0,
494,14,285,3,0,
494,14,315,1,97,
494,14,315,4,0,
494,14,324,3,0,
494,14,340,3,0,
494,14,369,4,0,
494,14,373,4,0,
494,14,374,4,0,
494,14,387,3,0,
494,14,394,1,73,
494,14,411,4,0,
494,14,412,4,0,
494,14,416,4,0,
494,14,428,1,49,
494,14,428,3,0,
494,14,433,4,0,
494,14,447,4,0,
494,14,451,4,0,
494,14,473,4,0,
494,14,477,4,0,
494,14,481,1,41,
494,14,488,1,25,
494,14,488,4,0,
494,14,496,4,0,
494,14,500,1,89,
494,14,510,1,1,4
494,14,510,4,0,
494,14,515,1,81,
494,14,517,1,57,
494,14,526,4,0,
494,14,528,4,0,
494,14,545,1,1,1
494,15,29,1,17,
494,15,38,1,65,
494,15,53,4,0,
494,15,63,4,0,
494,15,76,4,0,
494,15,85,4,0,
494,15,86,4,0,
494,15,87,4,0,
494,15,92,4,0,
494,15,93,1,1,3
494,15,94,4,0,
494,15,98,1,1,5
494,15,104,4,0,
494,15,113,4,0,
494,15,116,1,1,2
494,15,126,4,0,
494,15,148,4,0,
494,15,156,4,0,
494,15,164,4,0,
494,15,179,1,33,
494,15,182,4,0,
494,15,203,1,9,
494,15,207,4,0,
494,15,214,4,0,
494,15,216,4,0,
494,15,218,4,0,
494,15,219,4,0,
494,15,237,4,0,
494,15,241,4,0,
494,15,244,4,0,
494,15,247,4,0,
494,15,249,4,0,
494,15,261,4,0,
494,15,263,4,0,
494,15,269,4,0,
494,15,280,4,0,
494,15,315,1,97,
494,15,315,4,0,
494,15,369,4,0,
494,15,373,4,0,
494,15,374,4,0,
494,15,394,1,73,
494,15,411,4,0,
494,15,412,4,0,
494,15,416,4,0,
494,15,428,1,49,
494,15,433,4,0,
494,15,447,4,0,
494,15,451,4,0,
494,15,473,4,0,
494,15,481,1,41,
494,15,488,1,25,
494,15,488,4,0,
494,15,496,4,0,
494,15,500,1,89,
494,15,510,1,1,4
494,15,510,4,0,
494,15,515,1,81,
494,15,517,1,57,
494,15,528,4,0,
494,15,545,1,1,1
494,15,590,4,0,
494,15,605,4,0,
494,15,612,4,0,
494,16,7,3,0,
494,16,9,3,0,
494,16,29,1,17,1
494,16,38,1,65,1
494,16,53,4,0,
494,16,63,4,0,
494,16,76,4,0,
494,16,85,4,0,
494,16,86,4,0,
494,16,87,4,0,
494,16,92,4,0,
494,16,93,1,1,3
494,16,94,4,0,
494,16,98,1,1,5
494,16,104,4,0,
494,16,113,4,0,
494,16,116,1,1,2
494,16,126,4,0,
494,16,148,4,0,
494,16,156,4,0,
494,16,164,4,0,
494,16,173,3,0,
494,16,179,1,33,1
494,16,182,4,0,
494,16,203,1,9,1
494,16,207,4,0,
494,16,214,4,0,
494,16,216,4,0,
494,16,218,4,0,
494,16,219,4,0,
494,16,237,4,0,
494,16,241,4,0,
494,16,244,4,0,
494,16,247,4,0,
494,16,249,4,0,
494,16,253,3,0,
494,16,257,3,0,
494,16,261,4,0,
494,16,263,4,0,
494,16,269,4,0,
494,16,270,3,0,
494,16,271,3,0,
494,16,272,3,0,
494,16,277,3,0,
494,16,280,4,0,
494,16,285,3,0,
494,16,290,4,0,
494,16,315,1,97,1
494,16,315,4,0,
494,16,324,3,0,
494,16,340,3,0,
494,16,351,3,0,
494,16,369,4,0,
494,16,373,4,0,
494,16,374,4,0,
494,16,387,3,0,
494,16,394,1,73,1
494,16,411,4,0,
494,16,412,4,0,
494,16,416,4,0,
494,16,428,1,49,1
494,16,428,3,0,
494,16,433,4,0,
494,16,447,4,0,
494,16,451,4,0,
494,16,473,4,0,
494,16,481,1,41,1
494,16,488,1,25,1
494,16,488,4,0,
494,16,496,4,0,
494,16,500,1,89,1
494,16,510,1,1,4
494,16,510,4,0,
494,16,515,1,81,1
494,16,517,1,57,1
494,16,528,4,0,
494,16,545,1,1,1
494,16,590,4,0,
494,16,605,4,0,
494,16,612,4,0,
494,17,29,1,17,
494,17,38,1,65,
494,17,53,4,0,
494,17,63,4,0,
494,17,76,4,0,
494,17,85,4,0,
494,17,86,4,0,
494,17,87,4,0,
494,17,92,4,0,
494,17,93,1,1,3
494,17,94,4,0,
494,17,98,1,1,5
494,17,104,4,0,
494,17,113,4,0,
494,17,116,1,1,2
494,17,126,4,0,
494,17,156,4,0,
494,17,164,4,0,
494,17,179,1,33,
494,17,182,4,0,
494,17,203,1,9,
494,17,207,4,0,
494,17,214,4,0,
494,17,216,4,0,
494,17,218,4,0,
494,17,219,4,0,
494,17,237,4,0,
494,17,241,4,0,
494,17,244,4,0,
494,17,247,4,0,
494,17,261,4,0,
494,17,263,4,0,
494,17,269,4,0,
494,17,280,4,0,
494,17,315,1,97,
494,17,315,4,0,
494,17,369,4,0,
494,17,373,4,0,
494,17,374,4,0,
494,17,394,1,73,
494,17,411,4,0,
494,17,412,4,0,
494,17,416,4,0,
494,17,428,1,49,
494,17,433,4,0,
494,17,447,4,0,
494,17,451,4,0,
494,17,473,4,0,
494,17,481,1,41,
494,17,488,1,25,
494,17,488,4,0,
494,17,496,4,0,
494,17,500,1,89,
494,17,510,1,1,4
494,17,515,1,81,
494,17,517,1,57,
494,17,526,4,0,
494,17,528,4,0,
494,17,545,1,1,1
494,17,590,4,0,
494,17,605,4,0,
494,18,29,1,17,
494,18,38,1,65,
494,18,53,4,0,
494,18,63,4,0,
494,18,76,4,0,
494,18,85,4,0,
494,18,86,4,0,
494,18,87,4,0,
494,18,92,4,0,
494,18,93,1,1,3
494,18,94,4,0,
494,18,98,1,1,5
494,18,104,4,0,
494,18,113,4,0,
494,18,116,1,1,2
494,18,126,4,0,
494,18,156,4,0,
494,18,164,4,0,
494,18,179,1,33,
494,18,182,4,0,
494,18,203,1,9,
494,18,207,4,0,
494,18,214,4,0,
494,18,216,4,0,
494,18,218,4,0,
494,18,219,4,0,
494,18,237,4,0,
494,18,241,4,0,
494,18,244,4,0,
494,18,247,4,0,
494,18,261,4,0,
494,18,263,4,0,
494,18,269,4,0,
494,18,280,4,0,
494,18,315,1,97,
494,18,315,4,0,
494,18,369,4,0,
494,18,373,4,0,
494,18,374,4,0,
494,18,394,1,73,
494,18,411,4,0,
494,18,412,4,0,
494,18,416,4,0,
494,18,428,1,49,
494,18,433,4,0,
494,18,447,4,0,
494,18,451,4,0,
494,18,473,4,0,
494,18,481,1,41,
494,18,488,1,25,
494,18,488,4,0,
494,18,496,4,0,
494,18,500,1,89,
494,18,510,1,1,4
494,18,515,1,81,
494,18,517,1,57,
494,18,526,4,0,
494,18,528,4,0,
494,18,545,1,1,1
494,18,590,4,0,
494,18,605,4,0,
495,11,14,4,0,
495,11,15,4,0,
495,11,21,1,25,
495,11,22,1,7,
495,11,33,1,1,
495,11,35,1,10,
495,11,43,1,4,
495,11,72,1,22,
495,11,73,1,19,
495,11,74,1,13,
495,11,76,4,0,
495,11,92,4,0,
495,11,104,4,0,
495,11,113,4,0,
495,11,115,4,0,
495,11,137,2,0,
495,11,148,4,0,
495,11,156,4,0,
495,11,164,4,0,
495,11,182,4,0,
495,11,202,1,34,
495,11,207,4,0,
495,11,212,2,0,
495,11,213,4,0,
495,11,216,4,0,
495,11,218,4,0,
495,11,219,4,0,
495,11,228,2,0,
495,11,230,2,0,
495,11,231,2,0,
495,11,237,4,0,
495,11,239,2,0,
495,11,241,4,0,
495,11,243,2,0,
495,11,259,4,0,
495,11,263,4,0,
495,11,269,4,0,
495,11,332,4,0,
495,11,345,2,0,
495,11,347,4,0,
495,11,348,1,28,
495,11,363,2,0,
495,11,378,1,37,
495,11,380,1,40,
495,11,412,4,0,
495,11,437,1,43,
495,11,445,2,0,
495,11,447,4,0,
495,11,489,1,31,
495,11,496,4,0,
495,11,520,3,0,
495,11,536,1,16,
495,14,14,4,0,
495,14,15,4,0,
495,14,20,3,0,
495,14,21,1,25,
495,14,22,1,7,
495,14,33,1,1,
495,14,35,1,10,
495,14,43,1,4,
495,14,72,1,22,
495,14,73,1,19,
495,14,74,1,13,
495,14,76,4,0,
495,14,92,4,0,
495,14,104,4,0,
495,14,113,4,0,
495,14,115,4,0,
495,14,137,2,0,
495,14,148,4,0,
495,14,156,4,0,
495,14,164,4,0,
495,14,173,3,0,
495,14,182,4,0,
495,14,202,1,34,
495,14,202,3,0,
495,14,207,4,0,
495,14,212,2,0,
495,14,213,4,0,
495,14,214,3,0,
495,14,216,4,0,
495,14,218,4,0,
495,14,219,4,0,
495,14,228,2,0,
495,14,230,2,0,
495,14,231,2,0,
495,14,231,3,0,
495,14,235,3,0,
495,14,237,4,0,
495,14,239,2,0,
495,14,241,4,0,
495,14,243,2,0,
495,14,259,4,0,
495,14,263,4,0,
495,14,269,4,0,
495,14,282,3,0,
495,14,289,3,0,
495,14,332,4,0,
495,14,345,2,0,
495,14,347,4,0,
495,14,348,1,28,
495,14,363,2,0,
495,14,378,1,37,
495,14,380,1,40,
495,14,380,3,0,
495,14,388,3,0,
495,14,401,3,0,
495,14,402,3,0,
495,14,412,4,0,
495,14,437,1,43,
495,14,445,2,0,
495,14,447,4,0,
495,14,489,1,31,
495,14,496,4,0,
495,14,520,3,0,
495,14,536,1,16,
495,15,14,4,0,
495,15,15,4,0,
495,15,21,1,25,
495,15,22,1,7,
495,15,33,1,1,
495,15,35,1,10,
495,15,43,1,4,
495,15,72,1,22,
495,15,73,1,19,
495,15,74,1,13,
495,15,76,4,0,
495,15,92,4,0,
495,15,104,4,0,
495,15,113,4,0,
495,15,115,4,0,
495,15,137,2,0,
495,15,148,4,0,
495,15,156,4,0,
495,15,164,4,0,
495,15,182,4,0,
495,15,202,1,34,
495,15,207,4,0,
495,15,212,2,0,
495,15,213,4,0,
495,15,214,4,0,
495,15,216,4,0,
495,15,218,4,0,
495,15,219,4,0,
495,15,228,2,0,
495,15,230,2,0,
495,15,231,2,0,
495,15,237,4,0,
495,15,239,2,0,
495,15,241,4,0,
495,15,243,2,0,
495,15,259,4,0,
495,15,263,4,0,
495,15,267,4,0,
495,15,269,4,0,
495,15,332,4,0,
495,15,345,2,0,
495,15,347,4,0,
495,15,348,1,28,
495,15,363,2,0,
495,15,378,1,37,
495,15,380,1,40,
495,15,412,4,0,
495,15,437,1,43,
495,15,445,2,0,
495,15,447,4,0,
495,15,489,1,31,
495,15,496,4,0,
495,15,520,3,0,
495,15,536,1,16,
495,15,580,2,0,
495,15,590,4,0,
495,16,14,4,0,
495,16,15,4,0,
495,16,20,3,0,
495,16,21,1,25,1
495,16,22,1,7,1
495,16,33,1,1,1
495,16,35,1,10,1
495,16,43,1,4,1
495,16,72,1,22,1
495,16,73,1,19,1
495,16,74,1,13,1
495,16,76,4,0,
495,16,92,4,0,
495,16,104,4,0,
495,16,113,4,0,
495,16,115,4,0,
495,16,137,2,0,
495,16,148,4,0,
495,16,156,4,0,
495,16,164,4,0,
495,16,173,3,0,
495,16,182,4,0,
495,16,202,1,34,1
495,16,202,3,0,
495,16,207,4,0,
495,16,212,2,0,
495,16,213,4,0,
495,16,214,4,0,
495,16,216,4,0,
495,16,218,4,0,
495,16,219,4,0,
495,16,228,2,0,
495,16,230,2,0,
495,16,231,2,0,
495,16,231,3,0,
495,16,235,3,0,
495,16,237,4,0,
495,16,239,2,0,
495,16,241,4,0,
495,16,243,2,0,
495,16,259,4,0,
495,16,263,4,0,
495,16,267,4,0,
495,16,269,4,0,
495,16,282,3,0,
495,16,289,3,0,
495,16,290,4,0,
495,16,332,4,0,
495,16,345,2,0,
495,16,347,4,0,
495,16,348,1,28,1
495,16,363,2,0,
495,16,378,1,37,1
495,16,380,1,40,1
495,16,380,3,0,
495,16,388,3,0,
495,16,401,3,0,
495,16,402,3,0,
495,16,412,4,0,
495,16,437,1,43,1
495,16,445,2,0,
495,16,447,4,0,
495,16,489,1,31,1
495,16,496,4,0,
495,16,520,3,0,
495,16,536,1,16,1
495,16,580,2,0,
495,16,590,4,0,
495,17,14,4,0,
495,17,21,1,25,
495,17,22,1,7,
495,17,33,1,1,
495,17,35,1,10,
495,17,43,1,4,
495,17,72,1,22,
495,17,73,1,19,
495,17,74,1,13,
495,17,76,4,0,
495,17,92,4,0,
495,17,104,4,0,
495,17,113,4,0,
495,17,115,4,0,
495,17,137,2,0,
495,17,156,4,0,
495,17,164,4,0,
495,17,182,4,0,
495,17,202,1,34,
495,17,207,4,0,
495,17,212,2,0,
495,17,213,4,0,
495,17,214,4,0,
495,17,216,4,0,
495,17,218,4,0,
495,17,219,4,0,
495,17,228,2,0,
495,17,230,2,0,
495,17,231,2,0,
495,17,237,4,0,
495,17,239,2,0,
495,17,241,4,0,
495,17,243,2,0,
495,17,259,4,0,
495,17,263,4,0,
495,17,267,4,0,
495,17,269,4,0,
495,17,332,4,0,
495,17,345,2,0,
495,17,347,4,0,
495,17,348,1,28,
495,17,363,2,0,
495,17,378,1,37,
495,17,380,1,40,
495,17,412,4,0,
495,17,437,1,43,
495,17,445,2,0,
495,17,447,4,0,
495,17,489,1,31,
495,17,496,4,0,
495,17,520,3,0,
495,17,526,4,0,
495,17,536,1,16,
495,17,580,2,0,
495,17,590,4,0,
495,18,14,4,0,
495,18,21,1,25,
495,18,22,1,7,
495,18,33,1,1,
495,18,35,1,10,
495,18,43,1,4,
495,18,72,1,22,
495,18,73,1,19,
495,18,74,1,13,
495,18,76,4,0,
495,18,92,4,0,
495,18,104,4,0,
495,18,113,4,0,
495,18,115,4,0,
495,18,137,2,0,
495,18,156,4,0,
495,18,164,4,0,
495,18,182,4,0,
495,18,202,1,34,
495,18,207,4,0,
495,18,212,2,0,
495,18,213,4,0,
495,18,214,4,0,
495,18,216,4,0,
495,18,218,4,0,
495,18,219,4,0,
495,18,228,2,0,
495,18,230,2,0,
495,18,231,2,0,
495,18,237,4,0,
495,18,239,2,0,
495,18,241,4,0,
495,18,243,2,0,
495,18,259,4,0,
495,18,263,4,0,
495,18,267,4,0,
495,18,269,4,0,
495,18,332,4,0,
495,18,345,2,0,
495,18,347,4,0,
495,18,348,1,28,
495,18,363,2,0,
495,18,378,1,37,
495,18,380,1,40,
495,18,412,4,0,
495,18,437,1,43,
495,18,445,2,0,
495,18,447,4,0,
495,18,489,1,31,
495,18,496,4,0,
495,18,520,3,0,
495,18,526,4,0,
495,18,536,1,16,
495,18,580,2,0,
495,18,590,4,0,
496,11,14,4,0,
496,11,15,4,0,
496,11,21,1,28,
496,11,22,1,1,3
496,11,22,1,7,
496,11,33,1,1,1
496,11,35,1,1,4
496,11,35,1,10,
496,11,43,1,1,2
496,11,43,1,4,
496,11,72,1,24,
496,11,73,1,20,
496,11,74,1,13,
496,11,76,4,0,
496,11,92,4,0,
496,11,104,4,0,
496,11,113,4,0,
496,11,115,4,0,
496,11,148,4,0,
496,11,156,4,0,
496,11,164,4,0,
496,11,182,4,0,
496,11,202,1,40,
496,11,207,4,0,
496,11,213,4,0,
496,11,216,4,0,
496,11,218,4,0,
496,11,219,4,0,
496,11,237,4,0,
496,11,241,4,0,
496,11,259,4,0,
496,11,263,4,0,
496,11,269,4,0,
496,11,332,4,0,
496,11,347,4,0,
496,11,348,1,32,
496,11,378,1,44,
496,11,380,1,48,
496,11,412,4,0,
496,11,437,1,52,
496,11,447,4,0,
496,11,489,1,36,
496,11,496,4,0,
496,11,520,3,0,
496,11,536,1,16,
496,14,14,4,0,
496,14,15,4,0,
496,14,20,3,0,
496,14,21,1,28,
496,14,22,1,1,3
496,14,22,1,7,
496,14,33,1,1,1
496,14,35,1,1,4
496,14,35,1,10,
496,14,43,1,1,2
496,14,43,1,4,
496,14,72,1,24,
496,14,73,1,20,
496,14,74,1,13,
496,14,76,4,0,
496,14,92,4,0,
496,14,104,4,0,
496,14,113,4,0,
496,14,115,4,0,
496,14,148,4,0,
496,14,156,4,0,
496,14,164,4,0,
496,14,173,3,0,
496,14,182,4,0,
496,14,202,1,40,
496,14,202,3,0,
496,14,207,4,0,
496,14,213,4,0,
496,14,214,3,0,
496,14,216,4,0,
496,14,218,4,0,
496,14,219,4,0,
496,14,231,3,0,
496,14,235,3,0,
496,14,237,4,0,
496,14,241,4,0,
496,14,259,4,0,
496,14,263,4,0,
496,14,269,4,0,
496,14,282,3,0,
496,14,289,3,0,
496,14,332,4,0,
496,14,347,4,0,
496,14,348,1,32,
496,14,378,1,44,
496,14,380,1,48,
496,14,380,3,0,
496,14,388,3,0,
496,14,401,3,0,
496,14,402,3,0,
496,14,412,4,0,
496,14,437,1,52,
496,14,447,4,0,
496,14,489,1,36,
496,14,496,4,0,
496,14,520,3,0,
496,14,536,1,16,
496,15,14,4,0,
496,15,15,4,0,
496,15,21,1,28,
496,15,22,1,1,3
496,15,22,1,7,
496,15,33,1,1,1
496,15,35,1,1,4
496,15,35,1,10,
496,15,43,1,1,2
496,15,43,1,4,
496,15,72,1,24,
496,15,73,1,20,
496,15,74,1,13,
496,15,76,4,0,
496,15,92,4,0,
496,15,104,4,0,
496,15,113,4,0,
496,15,115,4,0,
496,15,148,4,0,
496,15,156,4,0,
496,15,164,4,0,
496,15,182,4,0,
496,15,202,1,40,
496,15,207,4,0,
496,15,213,4,0,
496,15,214,4,0,
496,15,216,4,0,
496,15,218,4,0,
496,15,219,4,0,
496,15,237,4,0,
496,15,241,4,0,
496,15,259,4,0,
496,15,263,4,0,
496,15,267,4,0,
496,15,269,4,0,
496,15,332,4,0,
496,15,347,4,0,
496,15,348,1,32,
496,15,378,1,44,
496,15,380,1,48,
496,15,412,4,0,
496,15,437,1,52,
496,15,447,4,0,
496,15,489,1,36,
496,15,496,4,0,
496,15,520,3,0,
496,15,536,1,16,
496,15,590,4,0,
496,16,14,4,0,
496,16,15,4,0,
496,16,20,3,0,
496,16,21,1,28,1
496,16,22,1,1,3
496,16,22,1,7,1
496,16,33,1,1,1
496,16,35,1,1,4
496,16,35,1,10,1
496,16,43,1,1,2
496,16,43,1,4,1
496,16,72,1,24,1
496,16,73,1,20,1
496,16,74,1,13,1
496,16,76,4,0,
496,16,92,4,0,
496,16,104,4,0,
496,16,113,4,0,
496,16,115,4,0,
496,16,148,4,0,
496,16,156,4,0,
496,16,164,4,0,
496,16,173,3,0,
496,16,182,4,0,
496,16,202,1,40,1
496,16,202,3,0,
496,16,207,4,0,
496,16,213,4,0,
496,16,214,4,0,
496,16,216,4,0,
496,16,218,4,0,
496,16,219,4,0,
496,16,231,3,0,
496,16,235,3,0,
496,16,237,4,0,
496,16,241,4,0,
496,16,259,4,0,
496,16,263,4,0,
496,16,267,4,0,
496,16,269,4,0,
496,16,282,3,0,
496,16,289,3,0,
496,16,290,4,0,
496,16,332,4,0,
496,16,347,4,0,
496,16,348,1,32,1
496,16,378,1,44,1
496,16,380,1,48,1
496,16,380,3,0,
496,16,388,3,0,
496,16,401,3,0,
496,16,402,3,0,
496,16,412,4,0,
496,16,437,1,52,1
496,16,447,4,0,
496,16,489,1,36,1
496,16,496,4,0,
496,16,520,3,0,
496,16,536,1,16,1
496,16,590,4,0,
496,17,14,4,0,
496,17,21,1,28,
496,17,22,1,1,3
496,17,22,1,7,
496,17,33,1,1,1
496,17,35,1,1,4
496,17,35,1,10,
496,17,43,1,1,2
496,17,43,1,4,
496,17,72,1,24,
496,17,73,1,20,
496,17,74,1,13,
496,17,76,4,0,
496,17,92,4,0,
496,17,104,4,0,
496,17,113,4,0,
496,17,115,4,0,
496,17,156,4,0,
496,17,164,4,0,
496,17,182,4,0,
496,17,202,1,40,
496,17,207,4,0,
496,17,213,4,0,
496,17,214,4,0,
496,17,216,4,0,
496,17,218,4,0,
496,17,219,4,0,
496,17,237,4,0,
496,17,241,4,0,
496,17,259,4,0,
496,17,263,4,0,
496,17,267,4,0,
496,17,269,4,0,
496,17,332,4,0,
496,17,347,4,0,
496,17,348,1,32,
496,17,378,1,44,
496,17,380,1,48,
496,17,412,4,0,
496,17,437,1,52,
496,17,447,4,0,
496,17,489,1,36,
496,17,496,4,0,
496,17,520,3,0,
496,17,526,4,0,
496,17,536,1,16,
496,17,590,4,0,
496,18,14,4,0,
496,18,21,1,28,
496,18,22,1,1,3
496,18,22,1,7,
496,18,33,1,1,1
496,18,35,1,1,4
496,18,35,1,10,
496,18,43,1,1,2
496,18,43,1,4,
496,18,72,1,24,
496,18,73,1,20,
496,18,74,1,13,
496,18,76,4,0,
496,18,92,4,0,
496,18,104,4,0,
496,18,113,4,0,
496,18,115,4,0,
496,18,156,4,0,
496,18,164,4,0,
496,18,182,4,0,
496,18,202,1,40,
496,18,207,4,0,
496,18,213,4,0,
496,18,214,4,0,
496,18,216,4,0,
496,18,218,4,0,
496,18,219,4,0,
496,18,237,4,0,
496,18,241,4,0,
496,18,259,4,0,
496,18,263,4,0,
496,18,267,4,0,
496,18,269,4,0,
496,18,332,4,0,
496,18,347,4,0,
496,18,348,1,32,
496,18,378,1,44,
496,18,380,1,48,
496,18,412,4,0,
496,18,437,1,52,
496,18,447,4,0,
496,18,489,1,36,
496,18,496,4,0,
496,18,520,3,0,
496,18,526,4,0,
496,18,536,1,16,
496,18,590,4,0,
497,11,14,4,0,
497,11,15,4,0,
497,11,21,1,28,
497,11,22,1,1,3
497,11,22,1,7,
497,11,33,1,1,1
497,11,35,1,1,4
497,11,35,1,10,
497,11,43,1,1,2
497,11,43,1,4,
497,11,63,4,0,
497,11,70,4,0,
497,11,72,1,24,
497,11,73,1,20,
497,11,74,1,13,
497,11,76,4,0,
497,11,92,4,0,
497,11,104,4,0,
497,11,113,4,0,
497,11,115,4,0,
497,11,148,4,0,
497,11,156,4,0,
497,11,164,4,0,
497,11,182,4,0,
497,11,202,1,44,
497,11,207,4,0,
497,11,213,4,0,
497,11,216,4,0,
497,11,218,4,0,
497,11,219,4,0,
497,11,237,4,0,
497,11,241,4,0,
497,11,249,4,0,
497,11,259,4,0,
497,11,263,4,0,
497,11,269,4,0,
497,11,332,4,0,
497,11,338,3,0,
497,11,347,4,0,
497,11,348,1,32,
497,11,378,1,50,
497,11,380,1,56,
497,11,412,4,0,
497,11,416,4,0,
497,11,437,1,62,
497,11,447,4,0,
497,11,489,1,38,
497,11,496,4,0,
497,11,520,3,0,
497,11,525,4,0,
497,11,536,1,16,
497,14,14,4,0,
497,14,15,4,0,
497,14,20,3,0,
497,14,21,1,28,
497,14,22,1,1,3
497,14,22,1,7,
497,14,33,1,1,1
497,14,35,1,1,4
497,14,35,1,10,
497,14,43,1,1,2
497,14,43,1,4,
497,14,63,4,0,
497,14,70,4,0,
497,14,72,1,24,
497,14,73,1,20,
497,14,74,1,13,
497,14,76,4,0,
497,14,92,4,0,
497,14,104,4,0,
497,14,113,4,0,
497,14,115,4,0,
497,14,148,4,0,
497,14,156,4,0,
497,14,164,4,0,
497,14,173,3,0,
497,14,182,4,0,
497,14,200,3,0,
497,14,202,1,44,
497,14,202,3,0,
497,14,207,4,0,
497,14,213,4,0,
497,14,214,3,0,
497,14,216,4,0,
497,14,218,4,0,
497,14,219,4,0,
497,14,231,3,0,
497,14,235,3,0,
497,14,237,4,0,
497,14,241,4,0,
497,14,249,4,0,
497,14,259,4,0,
497,14,263,4,0,
497,14,269,4,0,
497,14,282,3,0,
497,14,289,3,0,
497,14,332,4,0,
497,14,338,3,0,
497,14,347,4,0,
497,14,348,1,32,
497,14,378,1,50,
497,14,380,1,56,
497,14,380,3,0,
497,14,388,3,0,
497,14,401,3,0,
497,14,402,3,0,
497,14,406,3,0,
497,14,412,4,0,
497,14,416,4,0,
497,14,437,1,62,
497,14,447,4,0,
497,14,489,1,38,
497,14,496,4,0,
497,14,520,3,0,
497,14,525,4,0,
497,14,536,1,16,
497,15,14,4,0,
497,15,15,4,0,
497,15,21,1,28,
497,15,22,1,1,3
497,15,22,1,7,
497,15,33,1,1,1
497,15,35,1,1,4
497,15,35,1,10,
497,15,43,1,1,2
497,15,43,1,4,
497,15,63,4,0,
497,15,70,4,0,
497,15,72,1,24,
497,15,73,1,20,
497,15,74,1,13,
497,15,76,4,0,
497,15,92,4,0,
497,15,104,4,0,
497,15,113,4,0,
497,15,115,4,0,
497,15,148,4,0,
497,15,156,4,0,
497,15,164,4,0,
497,15,182,4,0,
497,15,202,1,44,
497,15,207,4,0,
497,15,213,4,0,
497,15,214,4,0,
497,15,216,4,0,
497,15,218,4,0,
497,15,219,4,0,
497,15,237,4,0,
497,15,241,4,0,
497,15,249,4,0,
497,15,259,4,0,
497,15,263,4,0,
497,15,267,4,0,
497,15,269,4,0,
497,15,332,4,0,
497,15,338,3,0,
497,15,347,4,0,
497,15,348,1,32,
497,15,378,1,50,
497,15,380,1,56,
497,15,412,4,0,
497,15,416,4,0,
497,15,437,1,62,
497,15,447,4,0,
497,15,489,1,38,
497,15,496,4,0,
497,15,520,3,0,
497,15,525,4,0,
497,15,536,1,16,
497,15,590,4,0,
497,16,14,4,0,
497,16,15,4,0,
497,16,20,3,0,
497,16,21,1,28,1
497,16,22,1,1,3
497,16,22,1,7,1
497,16,33,1,1,1
497,16,35,1,1,4
497,16,35,1,10,1
497,16,43,1,1,2
497,16,43,1,4,1
497,16,63,4,0,
497,16,70,4,0,
497,16,72,1,24,1
497,16,73,1,20,1
497,16,74,1,13,1
497,16,76,4,0,
497,16,92,4,0,
497,16,104,4,0,
497,16,113,4,0,
497,16,115,4,0,
497,16,148,4,0,
497,16,156,4,0,
497,16,164,4,0,
497,16,173,3,0,
497,16,182,4,0,
497,16,200,3,0,
497,16,202,1,44,1
497,16,202,3,0,
497,16,207,4,0,
497,16,213,4,0,
497,16,214,4,0,
497,16,216,4,0,
497,16,218,4,0,
497,16,219,4,0,
497,16,231,3,0,
497,16,235,3,0,
497,16,237,4,0,
497,16,241,4,0,
497,16,249,4,0,
497,16,259,4,0,
497,16,263,4,0,
497,16,267,4,0,
497,16,269,4,0,
497,16,282,3,0,
497,16,289,3,0,
497,16,290,4,0,
497,16,332,4,0,
497,16,338,3,0,
497,16,347,4,0,
497,16,348,1,32,1
497,16,378,1,50,1
497,16,380,1,56,1
497,16,380,3,0,
497,16,388,3,0,
497,16,401,3,0,
497,16,402,3,0,
497,16,406,3,0,
497,16,412,4,0,
497,16,416,4,0,
497,16,437,1,62,1
497,16,447,4,0,
497,16,489,1,38,1
497,16,496,4,0,
497,16,520,3,0,
497,16,525,4,0,
497,16,536,1,16,1
497,16,590,4,0,
497,17,14,4,0,
497,17,21,1,28,
497,17,22,1,1,3
497,17,22,1,7,
497,17,33,1,1,1
497,17,35,1,1,4
497,17,35,1,10,
497,17,43,1,1,2
497,17,43,1,4,
497,17,63,4,0,
497,17,72,1,24,
497,17,73,1,20,
497,17,74,1,13,
497,17,76,4,0,
497,17,92,4,0,
497,17,104,4,0,
497,17,113,4,0,
497,17,115,4,0,
497,17,156,4,0,
497,17,164,4,0,
497,17,182,4,0,
497,17,202,1,44,
497,17,207,4,0,
497,17,213,4,0,
497,17,214,4,0,
497,17,216,4,0,
497,17,218,4,0,
497,17,219,4,0,
497,17,237,4,0,
497,17,241,4,0,
497,17,259,4,0,
497,17,263,4,0,
497,17,267,4,0,
497,17,269,4,0,
497,17,332,4,0,
497,17,338,3,0,
497,17,347,4,0,
497,17,348,1,32,
497,17,378,1,50,
497,17,380,1,56,
497,17,412,4,0,
497,17,416,4,0,
497,17,437,1,62,
497,17,447,4,0,
497,17,489,1,38,
497,17,496,4,0,
497,17,520,3,0,
497,17,525,4,0,
497,17,526,4,0,
497,17,536,1,16,
497,17,590,4,0,
497,17,693,4,0,
497,18,14,4,0,
497,18,21,1,28,
497,18,22,1,1,3
497,18,22,1,7,
497,18,33,1,1,1
497,18,35,1,1,4
497,18,35,1,10,
497,18,43,1,1,2
497,18,43,1,4,
497,18,63,4,0,
497,18,72,1,24,
497,18,73,1,20,
497,18,74,1,13,
497,18,76,4,0,
497,18,92,4,0,
497,18,104,4,0,
497,18,113,4,0,
497,18,115,4,0,
497,18,156,4,0,
497,18,164,4,0,
497,18,182,4,0,
497,18,202,1,44,
497,18,207,4,0,
497,18,213,4,0,
497,18,214,4,0,
497,18,216,4,0,
497,18,218,4,0,
497,18,219,4,0,
497,18,237,4,0,
497,18,241,4,0,
497,18,259,4,0,
497,18,263,4,0,
497,18,267,4,0,
497,18,269,4,0,
497,18,332,4,0,
497,18,338,3,0,
497,18,347,4,0,
497,18,348,1,32,
497,18,378,1,50,
497,18,380,1,56,
497,18,412,4,0,
497,18,416,4,0,
497,18,437,1,62,
497,18,447,4,0,
497,18,489,1,38,
497,18,496,4,0,
497,18,520,3,0,
497,18,525,4,0,
497,18,526,4,0,
497,18,536,1,16,
497,18,590,4,0,
497,18,693,4,0,
498,11,33,1,1,
498,11,34,2,0,
498,11,36,1,25,
498,11,37,2,0,
498,11,39,1,3,
498,11,46,1,39,
498,11,46,4,0,
498,11,52,1,7,
498,11,53,1,33,
498,11,53,4,0,
498,11,70,4,0,
498,11,76,4,0,
498,11,92,4,0,
498,11,104,4,0,
498,11,111,1,13,
498,11,123,1,19,
498,11,126,4,0,
498,11,156,4,0,
498,11,164,4,0,
498,11,174,2,0,
498,11,182,4,0,
498,11,205,1,21,
498,11,207,4,0,
498,11,213,4,0,
498,11,214,2,0,
498,11,216,4,0,
498,11,218,4,0,
498,11,222,2,0,
498,11,237,4,0,
498,11,241,4,0,
498,11,249,4,0,
498,11,261,4,0,
498,11,263,4,0,
498,11,269,4,0,
498,11,276,2,0,
498,11,281,2,0,
498,11,283,2,0,
498,11,315,4,0,
498,11,316,1,9,
498,11,317,4,0,
498,11,343,2,0,
498,11,360,4,0,
498,11,372,1,31,
498,11,394,1,43,
498,11,447,4,0,
498,11,457,1,37,
498,11,484,2,0,
498,11,488,1,15,
498,11,488,4,0,
498,11,496,4,0,
498,11,497,4,0,
498,11,510,4,0,
498,11,519,3,0,
498,11,528,4,0,
498,11,535,1,27,
498,14,33,1,1,
498,14,34,2,0,
498,14,36,1,25,
498,14,37,2,0,
498,14,39,1,3,
498,14,46,1,39,
498,14,46,4,0,
498,14,52,1,7,
498,14,53,1,33,
498,14,53,4,0,
498,14,70,4,0,
498,14,76,4,0,
498,14,92,4,0,
498,14,104,4,0,
498,14,111,1,13,
498,14,123,1,19,
498,14,126,4,0,
498,14,156,4,0,
498,14,164,4,0,
498,14,173,3,0,
498,14,174,2,0,
498,14,182,4,0,
498,14,205,1,21,
498,14,207,4,0,
498,14,213,4,0,
498,14,214,2,0,
498,14,214,3,0,
498,14,216,4,0,
498,14,218,4,0,
498,14,222,2,0,
498,14,231,3,0,
498,14,237,4,0,
498,14,241,4,0,
498,14,249,4,0,
498,14,257,3,0,
498,14,261,4,0,
498,14,263,4,0,
498,14,269,4,0,
498,14,270,3,0,
498,14,276,2,0,
498,14,276,3,0,
498,14,281,2,0,
498,14,283,2,0,
498,14,283,3,0,
498,14,315,4,0,
498,14,316,1,9,
498,14,317,4,0,
498,14,343,2,0,
498,14,343,3,0,
498,14,360,4,0,
498,14,372,1,31,
498,14,394,1,43,
498,14,447,4,0,
498,14,457,1,37,
498,14,484,2,0,
498,14,488,1,15,
498,14,488,4,0,
498,14,496,4,0,
498,14,497,4,0,
498,14,510,4,0,
498,14,519,3,0,
498,14,528,4,0,
498,14,535,1,27,
498,15,33,1,1,
498,15,34,2,0,
498,15,36,1,25,
498,15,37,2,0,
498,15,39,1,3,
498,15,46,1,39,
498,15,46,4,0,
498,15,52,1,7,
498,15,53,1,33,
498,15,53,4,0,
498,15,70,4,0,
498,15,76,4,0,
498,15,92,4,0,
498,15,104,4,0,
498,15,111,1,13,
498,15,123,1,19,
498,15,126,4,0,
498,15,156,4,0,
498,15,164,4,0,
498,15,174,2,0,
498,15,182,4,0,
498,15,205,1,21,
498,15,207,4,0,
498,15,213,4,0,
498,15,214,2,0,
498,15,214,4,0,
498,15,216,4,0,
498,15,218,4,0,
498,15,222,2,0,
498,15,237,4,0,
498,15,241,4,0,
498,15,249,4,0,
498,15,261,4,0,
498,15,263,4,0,
498,15,269,4,0,
498,15,276,2,0,
498,15,281,2,0,
498,15,283,2,0,
498,15,315,4,0,
498,15,316,1,9,
498,15,317,4,0,
498,15,343,2,0,
498,15,360,4,0,
498,15,372,1,31,
498,15,389,2,0,
498,15,394,1,43,
498,15,447,4,0,
498,15,457,1,37,
498,15,484,2,0,
498,15,488,1,15,
498,15,488,4,0,
498,15,496,4,0,
498,15,497,4,0,
498,15,510,4,0,
498,15,519,3,0,
498,15,528,4,0,
498,15,535,1,27,
498,15,590,4,0,
498,16,33,1,1,1
498,16,34,2,0,
498,16,36,1,25,1
498,16,37,2,0,
498,16,39,1,3,1
498,16,46,1,39,1
498,16,46,4,0,
498,16,52,1,7,1
498,16,53,1,33,1
498,16,53,4,0,
498,16,70,4,0,
498,16,76,4,0,
498,16,92,4,0,
498,16,104,4,0,
498,16,111,1,13,1
498,16,123,1,19,1
498,16,126,4,0,
498,16,156,4,0,
498,16,164,4,0,
498,16,173,3,0,
498,16,174,2,0,
498,16,182,4,0,
498,16,205,1,21,1
498,16,207,4,0,
498,16,213,4,0,
498,16,214,2,0,
498,16,214,4,0,
498,16,216,4,0,
498,16,218,4,0,
498,16,222,2,0,
498,16,231,3,0,
498,16,237,4,0,
498,16,241,4,0,
498,16,249,4,0,
498,16,257,3,0,
498,16,261,4,0,
498,16,263,4,0,
498,16,269,4,0,
498,16,270,3,0,
498,16,276,2,0,
498,16,276,3,0,
498,16,281,2,0,
498,16,283,2,0,
498,16,283,3,0,
498,16,290,4,0,
498,16,315,4,0,
498,16,316,1,9,1
498,16,317,4,0,
498,16,343,2,0,
498,16,343,3,0,
498,16,360,4,0,
498,16,372,1,31,1
498,16,389,2,0,
498,16,394,1,43,1
498,16,428,3,0,
498,16,447,4,0,
498,16,457,1,37,1
498,16,484,2,0,
498,16,488,1,15,1
498,16,488,4,0,
498,16,496,4,0,
498,16,497,4,0,
498,16,510,4,0,
498,16,519,3,0,
498,16,528,4,0,
498,16,535,1,27,1
498,16,590,4,0,
498,17,33,1,1,
498,17,34,2,0,
498,17,36,1,25,
498,17,37,2,0,
498,17,39,1,3,
498,17,46,1,39,
498,17,46,4,0,
498,17,52,1,7,
498,17,53,1,33,
498,17,53,4,0,
498,17,76,4,0,
498,17,92,4,0,
498,17,104,4,0,
498,17,111,1,13,
498,17,123,1,19,
498,17,126,4,0,
498,17,156,4,0,
498,17,164,4,0,
498,17,174,2,0,
498,17,182,4,0,
498,17,205,1,21,
498,17,207,4,0,
498,17,213,4,0,
498,17,214,2,0,
498,17,214,4,0,
498,17,216,4,0,
498,17,218,4,0,
498,17,222,2,0,
498,17,237,4,0,
498,17,241,4,0,
498,17,261,4,0,
498,17,263,4,0,
498,17,269,4,0,
498,17,276,2,0,
498,17,281,2,0,
498,17,283,2,0,
498,17,315,4,0,
498,17,316,1,9,
498,17,317,4,0,
498,17,343,2,0,
498,17,360,4,0,
498,17,372,1,31,
498,17,389,2,0,
498,17,394,1,43,
498,17,447,4,0,
498,17,457,1,37,
498,17,484,2,0,
498,17,488,1,15,
498,17,488,4,0,
498,17,496,4,0,
498,17,497,4,0,
498,17,519,3,0,
498,17,526,4,0,
498,17,528,4,0,
498,17,535,1,27,
498,17,590,4,0,
498,18,33,1,1,
498,18,34,2,0,
498,18,36,1,25,
498,18,37,2,0,
498,18,39,1,3,
498,18,46,1,39,
498,18,46,4,0,
498,18,52,1,7,
498,18,53,1,33,
498,18,53,4,0,
498,18,76,4,0,
498,18,92,4,0,
498,18,104,4,0,
498,18,111,1,13,
498,18,123,1,19,
498,18,126,4,0,
498,18,156,4,0,
498,18,164,4,0,
498,18,174,2,0,
498,18,182,4,0,
498,18,205,1,21,
498,18,207,4,0,
498,18,213,4,0,
498,18,214,2,0,
498,18,214,4,0,
498,18,216,4,0,
498,18,218,4,0,
498,18,222,2,0,
498,18,237,4,0,
498,18,241,4,0,
498,18,261,4,0,
498,18,263,4,0,
498,18,269,4,0,
498,18,276,2,0,
498,18,281,2,0,
498,18,283,2,0,
498,18,315,4,0,
498,18,316,1,9,
498,18,317,4,0,
498,18,343,2,0,
498,18,360,4,0,
498,18,372,1,31,
498,18,389,2,0,
498,18,394,1,43,
498,18,447,4,0,
498,18,457,1,37,
498,18,484,2,0,
498,18,488,1,15,
498,18,488,4,0,
498,18,496,4,0,
498,18,497,4,0,
498,18,519,3,0,
498,18,526,4,0,
498,18,528,4,0,
498,18,535,1,27,
498,18,590,4,0,
498,18,682,2,0,
499,11,33,1,1,1
499,11,36,1,28,
499,11,39,1,1,2
499,11,39,1,3,
499,11,46,1,47,
499,11,46,4,0,
499,11,52,1,1,3
499,11,52,1,7,
499,11,53,1,39,
499,11,53,4,0,
499,11,70,4,0,
499,11,76,4,0,
499,11,92,4,0,
499,11,104,4,0,
499,11,111,1,13,
499,11,123,1,20,
499,11,126,4,0,
499,11,156,4,0,
499,11,157,4,0,
499,11,164,4,0,
499,11,182,4,0,
499,11,205,1,23,
499,11,207,4,0,
499,11,213,4,0,
499,11,216,4,0,
499,11,218,4,0,
499,11,237,4,0,
499,11,241,4,0,
499,11,249,4,0,
499,11,261,4,0,
499,11,263,4,0,
499,11,269,4,0,
499,11,280,4,0,
499,11,292,1,17,
499,11,315,4,0,
499,11,316,1,1,4
499,11,316,1,9,
499,11,317,4,0,
499,11,360,4,0,
499,11,372,1,36,
499,11,374,4,0,
499,11,394,1,52,
499,11,398,4,0,
499,11,411,4,0,
499,11,444,4,0,
499,11,447,4,0,
499,11,457,1,44,
499,11,488,1,15,
499,11,488,4,0,
499,11,490,4,0,
499,11,496,4,0,
499,11,497,4,0,
499,11,510,4,0,
499,11,519,3,0,
499,11,523,4,0,
499,11,526,4,0,
499,11,528,4,0,
499,11,535,1,31,
499,14,7,3,0,
499,14,9,3,0,
499,14,33,1,1,1
499,14,36,1,28,
499,14,39,1,1,2
499,14,39,1,3,
499,14,46,1,47,
499,14,46,4,0,
499,14,52,1,1,3
499,14,52,1,7,
499,14,53,1,39,
499,14,53,4,0,
499,14,67,3,0,
499,14,70,4,0,
499,14,76,4,0,
499,14,92,4,0,
499,14,104,4,0,
499,14,111,1,13,
499,14,123,1,20,
499,14,126,4,0,
499,14,156,4,0,
499,14,157,4,0,
499,14,164,4,0,
499,14,173,3,0,
499,14,182,4,0,
499,14,205,1,23,
499,14,207,4,0,
499,14,213,4,0,
499,14,214,3,0,
499,14,216,4,0,
499,14,218,4,0,
499,14,231,3,0,
499,14,237,4,0,
499,14,241,4,0,
499,14,249,4,0,
499,14,257,3,0,
499,14,261,4,0,
499,14,263,4,0,
499,14,269,4,0,
499,14,270,3,0,
499,14,276,3,0,
499,14,280,4,0,
499,14,283,3,0,
499,14,292,1,17,
499,14,315,4,0,
499,14,316,1,1,4
499,14,316,1,9,
499,14,317,4,0,
499,14,343,3,0,
499,14,360,4,0,
499,14,372,1,36,
499,14,374,4,0,
499,14,394,1,52,
499,14,398,4,0,
499,14,411,4,0,
499,14,444,4,0,
499,14,447,4,0,
499,14,457,1,44,
499,14,488,1,15,
499,14,488,4,0,
499,14,490,4,0,
499,14,496,4,0,
499,14,497,4,0,
499,14,510,4,0,
499,14,519,3,0,
499,14,523,4,0,
499,14,526,4,0,
499,14,528,4,0,
499,14,535,1,31,
499,15,33,1,1,1
499,15,36,1,28,
499,15,39,1,1,2
499,15,39,1,3,
499,15,46,1,47,
499,15,46,4,0,
499,15,52,1,1,3
499,15,52,1,7,
499,15,53,1,39,
499,15,53,4,0,
499,15,70,4,0,
499,15,76,4,0,
499,15,92,4,0,
499,15,104,4,0,
499,15,111,1,13,
499,15,123,1,20,
499,15,126,4,0,
499,15,156,4,0,
499,15,157,4,0,
499,15,164,4,0,
499,15,182,4,0,
499,15,205,1,23,
499,15,207,4,0,
499,15,213,4,0,
499,15,214,4,0,
499,15,216,4,0,
499,15,218,4,0,
499,15,237,4,0,
499,15,241,4,0,
499,15,249,4,0,
499,15,261,4,0,
499,15,263,4,0,
499,15,269,4,0,
499,15,280,4,0,
499,15,292,1,17,
499,15,315,4,0,
499,15,316,1,1,4
499,15,316,1,9,
499,15,317,4,0,
499,15,360,4,0,
499,15,372,1,36,
499,15,374,4,0,
499,15,394,1,52,
499,15,398,4,0,
499,15,411,4,0,
499,15,444,4,0,
499,15,447,4,0,
499,15,457,1,44,
499,15,488,1,15,
499,15,488,4,0,
499,15,490,4,0,
499,15,496,4,0,
499,15,497,4,0,
499,15,510,4,0,
499,15,519,3,0,
499,15,523,4,0,
499,15,528,4,0,
499,15,535,1,31,
499,15,590,4,0,
499,15,612,4,0,
499,16,7,3,0,
499,16,9,3,0,
499,16,33,1,1,1
499,16,36,1,28,1
499,16,39,1,1,2
499,16,39,1,3,1
499,16,46,1,47,1
499,16,46,4,0,
499,16,52,1,1,3
499,16,52,1,7,1
499,16,53,1,39,1
499,16,53,4,0,
499,16,67,3,0,
499,16,70,4,0,
499,16,76,4,0,
499,16,92,4,0,
499,16,104,4,0,
499,16,111,1,13,1
499,16,123,1,20,1
499,16,126,4,0,
499,16,156,4,0,
499,16,157,4,0,
499,16,164,4,0,
499,16,173,3,0,
499,16,182,4,0,
499,16,205,1,23,1
499,16,207,4,0,
499,16,213,4,0,
499,16,214,4,0,
499,16,216,4,0,
499,16,218,4,0,
499,16,231,3,0,
499,16,237,4,0,
499,16,241,4,0,
499,16,249,4,0,
499,16,257,3,0,
499,16,261,4,0,
499,16,263,4,0,
499,16,264,3,0,
499,16,269,4,0,
499,16,270,3,0,
499,16,276,3,0,
499,16,280,4,0,
499,16,283,3,0,
499,16,290,4,0,
499,16,292,1,17,1
499,16,315,4,0,
499,16,316,1,1,4
499,16,316,1,9,1
499,16,317,4,0,
499,16,343,3,0,
499,16,360,4,0,
499,16,372,1,36,1
499,16,374,4,0,
499,16,394,1,52,1
499,16,398,4,0,
499,16,411,4,0,
499,16,428,3,0,
499,16,444,4,0,
499,16,447,4,0,
499,16,457,1,44,1
499,16,488,1,15,1
499,16,488,4,0,
499,16,490,4,0,
499,16,496,4,0,
499,16,497,4,0,
499,16,510,4,0,
499,16,519,3,0,
499,16,523,4,0,
499,16,528,4,0,
499,16,535,1,31,1
499,16,590,4,0,
499,16,612,4,0,
499,17,33,1,1,2
499,17,36,1,28,
499,17,39,1,1,3
499,17,39,1,3,
499,17,46,1,47,
499,17,46,4,0,
499,17,52,1,1,4
499,17,52,1,7,
499,17,53,1,39,
499,17,53,4,0,
499,17,76,4,0,
499,17,92,4,0,
499,17,104,4,0,
499,17,111,1,13,
499,17,123,1,20,
499,17,126,4,0,
499,17,156,4,0,
499,17,157,4,0,
499,17,164,4,0,
499,17,182,4,0,
499,17,205,1,23,
499,17,207,4,0,
499,17,213,4,0,
499,17,214,4,0,
499,17,216,4,0,
499,17,218,4,0,
499,17,237,4,0,
499,17,241,4,0,
499,17,261,4,0,
499,17,263,4,0,
499,17,269,4,0,
499,17,280,4,0,
499,17,292,1,0,
499,17,292,1,1,1
499,17,315,4,0,
499,17,316,1,1,5
499,17,316,1,9,
499,17,317,4,0,
499,17,360,4,0,
499,17,372,1,36,
499,17,374,4,0,
499,17,394,1,52,
499,17,398,4,0,
499,17,411,4,0,
499,17,444,4,0,
499,17,447,4,0,
499,17,457,1,44,
499,17,488,1,15,
499,17,488,4,0,
499,17,490,4,0,
499,17,496,4,0,
499,17,497,4,0,
499,17,519,3,0,
499,17,523,4,0,
499,17,526,4,0,
499,17,528,4,0,
499,17,535,1,31,
499,17,590,4,0,
499,18,33,1,1,2
499,18,36,1,28,
499,18,39,1,1,3
499,18,39,1,3,
499,18,46,1,47,
499,18,46,4,0,
499,18,52,1,1,4
499,18,52,1,7,
499,18,53,1,39,
499,18,53,4,0,
499,18,76,4,0,
499,18,92,4,0,
499,18,104,4,0,
499,18,111,1,13,
499,18,123,1,20,
499,18,126,4,0,
499,18,156,4,0,
499,18,157,4,0,
499,18,164,4,0,
499,18,182,4,0,
499,18,205,1,23,
499,18,207,4,0,
499,18,213,4,0,
499,18,214,4,0,
499,18,216,4,0,
499,18,218,4,0,
499,18,237,4,0,
499,18,241,4,0,
499,18,261,4,0,
499,18,263,4,0,
499,18,269,4,0,
499,18,280,4,0,
499,18,292,1,0,
499,18,292,1,1,1
499,18,315,4,0,
499,18,316,1,1,5
499,18,316,1,9,
499,18,317,4,0,
499,18,360,4,0,
499,18,372,1,36,
499,18,374,4,0,
499,18,394,1,52,
499,18,398,4,0,
499,18,411,4,0,
499,18,444,4,0,
499,18,447,4,0,
499,18,457,1,44,
499,18,488,1,15,
499,18,488,4,0,
499,18,490,4,0,
499,18,496,4,0,
499,18,497,4,0,
499,18,519,3,0,
499,18,523,4,0,
499,18,526,4,0,
499,18,528,4,0,
499,18,535,1,31,
499,18,590,4,0,
500,11,33,1,1,2
500,11,36,1,28,
500,11,39,1,1,3
500,11,39,1,3,
500,11,46,1,55,
500,11,46,4,0,
500,11,52,1,1,4
500,11,52,1,7,
500,11,53,1,43,
500,11,53,4,0,
500,11,63,4,0,
500,11,70,4,0,
500,11,76,4,0,
500,11,89,4,0,
500,11,92,4,0,
500,11,104,4,0,
500,11,111,1,13,
500,11,123,1,20,
500,11,126,4,0,
500,11,156,4,0,
500,11,157,4,0,
500,11,164,4,0,
500,11,182,4,0,
500,11,205,1,23,
500,11,207,4,0,
500,11,213,4,0,
500,11,216,4,0,
500,11,218,4,0,
500,11,237,4,0,
500,11,241,4,0,
500,11,249,4,0,
500,11,261,4,0,
500,11,263,4,0,
500,11,269,4,0,
500,11,280,4,0,
500,11,292,1,17,
500,11,307,3,0,
500,11,315,4,0,
500,11,316,1,1,5
500,11,316,1,9,
500,11,317,4,0,
500,11,339,4,0,
500,11,359,1,1,1
500,11,360,4,0,
500,11,372,1,38,
500,11,374,4,0,
500,11,394,1,62,
500,11,398,4,0,
500,11,411,4,0,
500,11,416,4,0,
500,11,444,4,0,
500,11,447,4,0,
500,11,457,1,50,
500,11,479,4,0,
500,11,488,1,15,
500,11,488,4,0,
500,11,490,4,0,
500,11,496,4,0,
500,11,497,4,0,
500,11,503,4,0,
500,11,510,4,0,
500,11,519,3,0,
500,11,523,4,0,
500,11,526,4,0,
500,11,528,4,0,
500,11,535,1,31,
500,14,7,3,0,
500,14,9,3,0,
500,14,33,1,1,2
500,14,36,1,28,
500,14,39,1,1,3
500,14,39,1,3,
500,14,46,1,55,
500,14,46,4,0,
500,14,52,1,1,4
500,14,52,1,7,
500,14,53,1,43,
500,14,53,4,0,
500,14,63,4,0,
500,14,67,3,0,
500,14,70,4,0,
500,14,76,4,0,
500,14,89,4,0,
500,14,92,4,0,
500,14,104,4,0,
500,14,111,1,13,
500,14,123,1,20,
500,14,126,4,0,
500,14,156,4,0,
500,14,157,4,0,
500,14,164,4,0,
500,14,173,3,0,
500,14,182,4,0,
500,14,205,1,23,
500,14,207,4,0,
500,14,213,4,0,
500,14,214,3,0,
500,14,216,4,0,
500,14,218,4,0,
500,14,231,3,0,
500,14,237,4,0,
500,14,241,4,0,
500,14,249,4,0,
500,14,257,3,0,
500,14,261,4,0,
500,14,263,4,0,
500,14,269,4,0,
500,14,270,3,0,
500,14,276,3,0,
500,14,280,4,0,
500,14,283,3,0,
500,14,292,1,17,
500,14,307,3,0,
500,14,315,4,0,
500,14,316,1,1,5
500,14,316,1,9,
500,14,317,4,0,
500,14,335,3,0,
500,14,339,4,0,
500,14,343,3,0,
500,14,359,1,1,1
500,14,360,4,0,
500,14,372,1,38,
500,14,374,4,0,
500,14,394,1,62,
500,14,398,4,0,
500,14,411,4,0,
500,14,416,4,0,
500,14,442,3,0,
500,14,444,4,0,
500,14,447,4,0,
500,14,457,1,50,
500,14,479,4,0,
500,14,488,1,15,
500,14,488,4,0,
500,14,490,4,0,
500,14,496,4,0,
500,14,497,4,0,
500,14,503,4,0,
500,14,510,4,0,
500,14,519,3,0,
500,14,523,4,0,
500,14,526,4,0,
500,14,528,4,0,
500,14,535,1,31,
500,15,33,1,1,2
500,15,36,1,28,
500,15,39,1,1,3
500,15,39,1,3,
500,15,46,1,55,
500,15,46,4,0,
500,15,52,1,1,4
500,15,52,1,7,
500,15,53,1,43,
500,15,53,4,0,
500,15,63,4,0,
500,15,70,4,0,
500,15,76,4,0,
500,15,89,4,0,
500,15,92,4,0,
500,15,104,4,0,
500,15,111,1,13,
500,15,123,1,20,
500,15,126,4,0,
500,15,156,4,0,
500,15,157,4,0,
500,15,164,4,0,
500,15,182,4,0,
500,15,205,1,23,
500,15,207,4,0,
500,15,213,4,0,
500,15,214,4,0,
500,15,216,4,0,
500,15,218,4,0,
500,15,237,4,0,
500,15,241,4,0,
500,15,249,4,0,
500,15,261,4,0,
500,15,263,4,0,
500,15,269,4,0,
500,15,280,4,0,
500,15,292,1,17,
500,15,307,3,0,
500,15,315,4,0,
500,15,316,1,1,5
500,15,316,1,9,
500,15,317,4,0,
500,15,339,4,0,
500,15,359,1,1,1
500,15,360,4,0,
500,15,372,1,38,
500,15,374,4,0,
500,15,394,1,62,
500,15,398,4,0,
500,15,411,4,0,
500,15,416,4,0,
500,15,444,4,0,
500,15,447,4,0,
500,15,457,1,50,
500,15,479,4,0,
500,15,488,1,15,
500,15,488,4,0,
500,15,490,4,0,
500,15,496,4,0,
500,15,497,4,0,
500,15,503,4,0,
500,15,510,4,0,
500,15,519,3,0,
500,15,523,4,0,
500,15,528,4,0,
500,15,535,1,31,
500,15,590,4,0,
500,15,612,4,0,
500,16,7,3,0,
500,16,9,3,0,
500,16,33,1,1,2
500,16,36,1,28,1
500,16,39,1,1,3
500,16,39,1,3,1
500,16,46,1,55,1
500,16,46,4,0,
500,16,52,1,1,4
500,16,52,1,7,1
500,16,53,1,43,1
500,16,53,4,0,
500,16,63,4,0,
500,16,67,3,0,
500,16,70,4,0,
500,16,76,4,0,
500,16,89,4,0,
500,16,92,4,0,
500,16,104,4,0,
500,16,111,1,13,1
500,16,123,1,20,1
500,16,126,4,0,
500,16,156,4,0,
500,16,157,4,0,
500,16,164,4,0,
500,16,173,3,0,
500,16,182,4,0,
500,16,205,1,23,1
500,16,207,4,0,
500,16,213,4,0,
500,16,214,4,0,
500,16,216,4,0,
500,16,218,4,0,
500,16,231,3,0,
500,16,237,4,0,
500,16,241,4,0,
500,16,249,4,0,
500,16,257,3,0,
500,16,261,4,0,
500,16,263,4,0,
500,16,264,3,0,
500,16,269,4,0,
500,16,270,3,0,
500,16,276,3,0,
500,16,280,4,0,
500,16,283,3,0,
500,16,290,4,0,
500,16,292,1,17,1
500,16,307,3,0,
500,16,315,4,0,
500,16,316,1,1,5
500,16,316,1,9,1
500,16,317,4,0,
500,16,335,3,0,
500,16,339,4,0,
500,16,343,3,0,
500,16,359,1,1,1
500,16,360,4,0,
500,16,372,1,38,1
500,16,374,4,0,
500,16,394,1,62,1
500,16,398,4,0,
500,16,411,4,0,
500,16,416,4,0,
500,16,428,3,0,
500,16,442,3,0,
500,16,444,4,0,
500,16,447,4,0,
500,16,457,1,50,1
500,16,479,4,0,
500,16,488,1,15,1
500,16,488,4,0,
500,16,490,4,0,
500,16,496,4,0,
500,16,497,4,0,
500,16,503,4,0,
500,16,510,4,0,
500,16,519,3,0,
500,16,523,4,0,
500,16,528,4,0,
500,16,535,1,31,1
500,16,590,4,0,
500,16,612,4,0,
500,17,33,1,1,3
500,17,36,1,28,
500,17,39,1,1,4
500,17,39,1,3,
500,17,46,1,55,
500,17,46,4,0,
500,17,52,1,1,5
500,17,52,1,7,
500,17,53,1,43,
500,17,53,4,0,
500,17,63,4,0,
500,17,76,4,0,
500,17,89,4,0,
500,17,92,4,0,
500,17,104,4,0,
500,17,111,1,13,
500,17,123,1,20,
500,17,126,4,0,
500,17,156,4,0,
500,17,157,4,0,
500,17,164,4,0,
500,17,182,4,0,
500,17,205,1,23,
500,17,207,4,0,
500,17,213,4,0,
500,17,214,4,0,
500,17,216,4,0,
500,17,218,4,0,
500,17,237,4,0,
500,17,241,4,0,
500,17,261,4,0,
500,17,263,4,0,
500,17,269,4,0,
500,17,280,4,0,
500,17,292,1,1,1
500,17,307,3,0,
500,17,315,4,0,
500,17,316,1,1,6
500,17,316,1,9,
500,17,317,4,0,
500,17,339,4,0,
500,17,359,1,1,2
500,17,360,4,0,
500,17,372,1,38,
500,17,374,4,0,
500,17,394,1,62,
500,17,398,4,0,
500,17,411,4,0,
500,17,416,4,0,
500,17,444,4,0,
500,17,447,4,0,
500,17,457,1,50,
500,17,479,4,0,
500,17,488,1,15,
500,17,488,4,0,
500,17,490,4,0,
500,17,496,4,0,
500,17,497,4,0,
500,17,503,4,0,
500,17,519,3,0,
500,17,523,4,0,
500,17,526,4,0,
500,17,528,4,0,
500,17,535,1,31,
500,17,590,4,0,
500,18,33,1,1,3
500,18,36,1,28,
500,18,39,1,1,4
500,18,39,1,3,
500,18,46,1,55,
500,18,46,4,0,
500,18,52,1,1,5
500,18,52,1,7,
500,18,53,1,43,
500,18,53,4,0,
500,18,63,4,0,
500,18,76,4,0,
500,18,89,4,0,
500,18,92,4,0,
500,18,104,4,0,
500,18,111,1,13,
500,18,123,1,20,
500,18,126,4,0,
500,18,156,4,0,
500,18,157,4,0,
500,18,164,4,0,
500,18,182,4,0,
500,18,205,1,23,
500,18,207,4,0,
500,18,213,4,0,
500,18,214,4,0,
500,18,216,4,0,
500,18,218,4,0,
500,18,237,4,0,
500,18,241,4,0,
500,18,261,4,0,
500,18,263,4,0,
500,18,269,4,0,
500,18,280,4,0,
500,18,292,1,1,1
500,18,307,3,0,
500,18,315,4,0,
500,18,316,1,1,6
500,18,316,1,9,
500,18,317,4,0,
500,18,339,4,0,
500,18,359,1,1,2
500,18,360,4,0,
500,18,372,1,38,
500,18,374,4,0,
500,18,394,1,62,
500,18,398,4,0,
500,18,411,4,0,
500,18,416,4,0,
500,18,444,4,0,
500,18,447,4,0,
500,18,457,1,50,
500,18,479,4,0,
500,18,488,1,15,
500,18,488,4,0,
500,18,490,4,0,
500,18,496,4,0,
500,18,497,4,0,
500,18,503,4,0,
500,18,519,3,0,
500,18,523,4,0,
500,18,526,4,0,
500,18,528,4,0,
500,18,535,1,31,
500,18,590,4,0,
501,11,14,1,41,
501,11,14,4,0,
501,11,15,4,0,
501,11,33,1,1,
501,11,39,1,5,
501,11,55,1,7,
501,11,56,1,43,
501,11,57,4,0,
501,11,58,4,0,
501,11,59,4,0,
501,11,91,4,0,
501,11,92,4,0,
501,11,103,2,0,
501,11,104,4,0,
501,11,116,1,13,
501,11,127,4,0,
501,11,156,4,0,
501,11,164,4,0,
501,11,182,4,0,
501,11,197,2,0,
501,11,206,4,0,
501,11,207,4,0,
501,11,210,1,19,
501,11,213,4,0,
501,11,216,4,0,
501,11,218,4,0,
501,11,227,1,31,
501,11,237,4,0,
501,11,240,4,0,
501,11,249,4,0,
501,11,258,4,0,
501,11,263,4,0,
501,11,269,4,0,
501,11,279,1,25,
501,11,291,4,0,
501,11,332,4,0,
501,11,346,1,11,
501,11,352,1,23,
501,11,362,2,0,
501,11,372,2,0,
501,11,374,4,0,
501,11,376,2,0,
501,11,383,2,0,
501,11,400,2,0,
501,11,401,1,35,
501,11,403,2,0,
501,11,404,4,0,
501,11,447,4,0,
501,11,453,1,29,
501,11,496,4,0,
501,11,503,4,0,
501,11,514,1,37,
501,11,514,4,0,
501,11,518,3,0,
501,11,534,1,17,
501,14,14,1,41,
501,14,14,4,0,
501,14,15,4,0,
501,14,33,1,1,
501,14,39,1,5,
501,14,55,1,7,
501,14,56,1,43,
501,14,57,4,0,
501,14,58,4,0,
501,14,59,4,0,
501,14,91,4,0,
501,14,92,4,0,
501,14,103,2,0,
501,14,104,4,0,
501,14,116,1,13,
501,14,127,4,0,
501,14,156,4,0,
501,14,164,4,0,
501,14,173,3,0,
501,14,182,4,0,
501,14,196,3,0,
501,14,197,2,0,
501,14,206,4,0,
501,14,207,4,0,
501,14,210,1,19,
501,14,213,4,0,
501,14,214,3,0,
501,14,216,4,0,
501,14,218,4,0,
501,14,227,1,31,
501,14,231,3,0,
501,14,237,4,0,
501,14,240,4,0,
501,14,249,4,0,
501,14,258,4,0,
501,14,263,4,0,
501,14,269,4,0,
501,14,270,3,0,
501,14,279,1,25,
501,14,291,4,0,
501,14,332,4,0,
501,14,343,3,0,
501,14,346,1,11,
501,14,352,1,23,
501,14,362,2,0,
501,14,372,2,0,
501,14,374,4,0,
501,14,376,2,0,
501,14,383,2,0,
501,14,400,2,0,
501,14,401,1,35,
501,14,401,3,0,
501,14,403,2,0,
501,14,404,4,0,
501,14,447,4,0,
501,14,453,1,29,
501,14,496,4,0,
501,14,503,4,0,
501,14,514,1,37,
501,14,514,4,0,
501,14,518,3,0,
501,14,534,1,17,
501,15,14,1,41,
501,15,14,4,0,
501,15,15,4,0,
501,15,33,1,1,
501,15,39,1,5,
501,15,55,1,7,
501,15,56,1,43,
501,15,57,4,0,
501,15,58,4,0,
501,15,59,4,0,
501,15,91,4,0,
501,15,92,4,0,
501,15,103,2,0,
501,15,104,4,0,
501,15,116,1,13,
501,15,127,4,0,
501,15,156,4,0,
501,15,164,4,0,
501,15,182,4,0,
501,15,197,2,0,
501,15,206,4,0,
501,15,207,4,0,
501,15,210,1,19,
501,15,213,4,0,
501,15,214,4,0,
501,15,216,4,0,
501,15,218,4,0,
501,15,227,1,31,
501,15,237,4,0,
501,15,240,4,0,
501,15,249,4,0,
501,15,258,4,0,
501,15,263,4,0,
501,15,269,4,0,
501,15,279,1,25,
501,15,332,4,0,
501,15,346,1,11,
501,15,352,1,23,
501,15,362,2,0,
501,15,372,2,0,
501,15,374,4,0,
501,15,376,2,0,
501,15,383,2,0,
501,15,400,2,0,
501,15,401,1,35,
501,15,403,2,0,
501,15,404,4,0,
501,15,447,4,0,
501,15,453,1,29,
501,15,496,4,0,
501,15,503,4,0,
501,15,514,1,37,
501,15,514,4,0,
501,15,518,3,0,
501,15,534,1,17,
501,15,590,4,0,
501,16,14,1,41,1
501,16,14,4,0,
501,16,15,4,0,
501,16,33,1,1,1
501,16,39,1,5,1
501,16,55,1,7,1
501,16,56,1,43,1
501,16,57,4,0,
501,16,58,4,0,
501,16,59,4,0,
501,16,91,4,0,
501,16,92,4,0,
501,16,103,2,0,
501,16,104,4,0,
501,16,116,1,13,1
501,16,127,4,0,
501,16,156,4,0,
501,16,164,4,0,
501,16,173,3,0,
501,16,182,4,0,
501,16,196,3,0,
501,16,197,2,0,
501,16,206,4,0,
501,16,207,4,0,
501,16,210,1,19,1
501,16,213,4,0,
501,16,214,4,0,
501,16,216,4,0,
501,16,218,4,0,
501,16,227,1,31,1
501,16,231,3,0,
501,16,237,4,0,
501,16,240,4,0,
501,16,249,4,0,
501,16,258,4,0,
501,16,263,4,0,
501,16,269,4,0,
501,16,270,3,0,
501,16,279,1,25,1
501,16,290,4,0,
501,16,291,4,0,
501,16,332,4,0,
501,16,343,3,0,
501,16,346,1,11,1
501,16,352,1,23,1
501,16,352,3,0,
501,16,362,2,0,
501,16,372,2,0,
501,16,374,4,0,
501,16,376,2,0,
501,16,383,2,0,
501,16,400,2,0,
501,16,401,1,35,1
501,16,401,3,0,
501,16,403,2,0,
501,16,404,4,0,
501,16,447,4,0,
501,16,453,1,29,1
501,16,496,4,0,
501,16,503,4,0,
501,16,514,1,37,1
501,16,514,4,0,
501,16,518,3,0,
501,16,534,1,17,1
501,16,590,4,0,
501,17,14,1,41,
501,17,14,4,0,
501,17,33,1,1,
501,17,39,1,5,
501,17,55,1,7,
501,17,56,1,43,
501,17,57,4,0,
501,17,58,4,0,
501,17,59,4,0,
501,17,92,4,0,
501,17,103,2,0,
501,17,104,4,0,
501,17,116,1,13,
501,17,127,4,0,
501,17,156,4,0,
501,17,164,4,0,
501,17,182,4,0,
501,17,197,2,0,
501,17,206,4,0,
501,17,207,4,0,
501,17,210,1,19,
501,17,213,4,0,
501,17,214,4,0,
501,17,216,4,0,
501,17,218,4,0,
501,17,227,1,31,
501,17,237,4,0,
501,17,240,4,0,
501,17,258,4,0,
501,17,263,4,0,
501,17,269,4,0,
501,17,279,1,25,
501,17,332,4,0,
501,17,346,1,11,
501,17,352,1,23,
501,17,362,2,0,
501,17,372,2,0,
501,17,374,4,0,
501,17,376,2,0,
501,17,383,2,0,
501,17,400,2,0,
501,17,401,1,35,
501,17,403,2,0,
501,17,404,4,0,
501,17,447,4,0,
501,17,453,1,29,
501,17,496,4,0,
501,17,503,4,0,
501,17,514,1,37,
501,17,518,3,0,
501,17,526,4,0,
501,17,534,1,17,
501,17,590,4,0,
501,18,14,1,41,
501,18,14,4,0,
501,18,33,1,1,
501,18,39,1,5,
501,18,55,1,7,
501,18,56,1,43,
501,18,57,4,0,
501,18,58,4,0,
501,18,59,4,0,
501,18,92,4,0,
501,18,103,2,0,
501,18,104,4,0,
501,18,116,1,13,
501,18,127,4,0,
501,18,156,4,0,
501,18,164,4,0,
501,18,182,4,0,
501,18,197,2,0,
501,18,206,4,0,
501,18,207,4,0,
501,18,210,1,19,
501,18,213,4,0,
501,18,214,4,0,
501,18,216,4,0,
501,18,218,4,0,
501,18,227,1,31,
501,18,237,4,0,
501,18,240,4,0,
501,18,258,4,0,
501,18,263,4,0,
501,18,269,4,0,
501,18,279,1,25,
501,18,332,4,0,
501,18,346,1,11,
501,18,352,1,23,
501,18,362,2,0,
501,18,372,2,0,
501,18,374,4,0,
501,18,376,2,0,
501,18,383,2,0,
501,18,400,2,0,
501,18,401,1,35,
501,18,403,2,0,
501,18,404,4,0,
501,18,447,4,0,
501,18,453,1,29,
501,18,496,4,0,
501,18,503,4,0,
501,18,514,1,37,
501,18,518,3,0,
501,18,526,4,0,
501,18,533,2,0,
501,18,534,1,17,
501,18,590,4,0,
502,11,14,1,49,
502,11,14,4,0,
502,11,15,4,0,
502,11,33,1,1,1
502,11,39,1,1,2
502,11,39,1,5,
502,11,55,1,1,3
502,11,55,1,7,
502,11,56,1,52,
502,11,57,4,0,
502,11,58,4,0,
502,11,59,4,0,
502,11,91,4,0,
502,11,92,4,0,
502,11,104,4,0,
502,11,116,1,13,
502,11,127,4,0,
502,11,156,4,0,
502,11,164,4,0,
502,11,182,4,0,
502,11,206,4,0,
502,11,207,4,0,
502,11,210,1,20,
502,11,213,4,0,
502,11,216,4,0,
502,11,218,4,0,
502,11,227,1,36,
502,11,237,4,0,
502,11,240,4,0,
502,11,249,4,0,
502,11,258,4,0,
502,11,263,4,0,
502,11,269,4,0,
502,11,279,1,28,
502,11,291,4,0,
502,11,332,4,0,
502,11,346,1,1,4
502,11,346,1,11,
502,11,352,1,25,
502,11,374,4,0,
502,11,401,1,41,
502,11,404,4,0,
502,11,447,4,0,
502,11,453,1,33,
502,11,496,4,0,
502,11,503,4,0,
502,11,514,1,44,
502,11,514,4,0,
502,11,518,3,0,
502,11,534,1,17,
502,14,14,1,49,
502,14,14,4,0,
502,14,15,4,0,
502,14,33,1,1,1
502,14,39,1,1,2
502,14,39,1,5,
502,14,55,1,1,3
502,14,55,1,7,
502,14,56,1,52,
502,14,57,4,0,
502,14,58,4,0,
502,14,59,4,0,
502,14,91,4,0,
502,14,92,4,0,
502,14,104,4,0,
502,14,116,1,13,
502,14,127,4,0,
502,14,156,4,0,
502,14,164,4,0,
502,14,173,3,0,
502,14,182,4,0,
502,14,196,3,0,
502,14,206,4,0,
502,14,207,4,0,
502,14,210,1,20,
502,14,213,4,0,
502,14,214,3,0,
502,14,216,4,0,
502,14,218,4,0,
502,14,227,1,36,
502,14,231,3,0,
502,14,237,4,0,
502,14,240,4,0,
502,14,249,4,0,
502,14,258,4,0,
502,14,263,4,0,
502,14,269,4,0,
502,14,270,3,0,
502,14,279,1,28,
502,14,291,4,0,
502,14,332,4,0,
502,14,343,3,0,
502,14,346,1,1,4
502,14,346,1,11,
502,14,352,1,25,
502,14,374,4,0,
502,14,401,1,41,
502,14,401,3,0,
502,14,404,4,0,
502,14,447,4,0,
502,14,453,1,33,
502,14,496,4,0,
502,14,503,4,0,
502,14,514,1,44,
502,14,514,4,0,
502,14,518,3,0,
502,14,534,1,17,
502,15,14,1,49,
502,15,14,4,0,
502,15,15,4,0,
502,15,33,1,1,1
502,15,39,1,1,2
502,15,39,1,5,
502,15,55,1,1,3
502,15,55,1,7,
502,15,56,1,52,
502,15,57,4,0,
502,15,58,4,0,
502,15,59,4,0,
502,15,91,4,0,
502,15,92,4,0,
502,15,104,4,0,
502,15,116,1,13,
502,15,127,4,0,
502,15,156,4,0,
502,15,164,4,0,
502,15,182,4,0,
502,15,206,4,0,
502,15,207,4,0,
502,15,210,1,20,
502,15,213,4,0,
502,15,214,4,0,
502,15,216,4,0,
502,15,218,4,0,
502,15,227,1,36,
502,15,237,4,0,
502,15,240,4,0,
502,15,249,4,0,
502,15,258,4,0,
502,15,263,4,0,
502,15,269,4,0,
502,15,279,1,28,
502,15,332,4,0,
502,15,346,1,1,4
502,15,346,1,11,
502,15,352,1,25,
502,15,374,4,0,
502,15,401,1,41,
502,15,404,4,0,
502,15,447,4,0,
502,15,453,1,33,
502,15,496,4,0,
502,15,503,4,0,
502,15,514,1,44,
502,15,514,4,0,
502,15,518,3,0,
502,15,534,1,17,
502,15,590,4,0,
502,16,14,1,49,1
502,16,14,4,0,
502,16,15,4,0,
502,16,33,1,1,1
502,16,39,1,1,2
502,16,39,1,5,1
502,16,55,1,1,3
502,16,55,1,7,1
502,16,56,1,52,1
502,16,57,4,0,
502,16,58,4,0,
502,16,59,4,0,
502,16,91,4,0,
502,16,92,4,0,
502,16,104,4,0,
502,16,116,1,13,1
502,16,127,4,0,
502,16,156,4,0,
502,16,164,4,0,
502,16,173,3,0,
502,16,182,4,0,
502,16,196,3,0,
502,16,206,4,0,
502,16,207,4,0,
502,16,210,1,20,1
502,16,213,4,0,
502,16,214,4,0,
502,16,216,4,0,
502,16,218,4,0,
502,16,227,1,36,1
502,16,231,3,0,
502,16,237,4,0,
502,16,240,4,0,
502,16,249,4,0,
502,16,258,4,0,
502,16,263,4,0,
502,16,269,4,0,
502,16,270,3,0,
502,16,279,1,28,1
502,16,290,4,0,
502,16,291,4,0,
502,16,332,4,0,
502,16,343,3,0,
502,16,346,1,1,4
502,16,346,1,11,1
502,16,352,1,25,1
502,16,352,3,0,
502,16,374,4,0,
502,16,401,1,41,1
502,16,401,3,0,
502,16,404,4,0,
502,16,447,4,0,
502,16,453,1,33,1
502,16,496,4,0,
502,16,503,4,0,
502,16,514,1,44,1
502,16,514,4,0,
502,16,518,3,0,
502,16,534,1,17,1
502,16,590,4,0,
502,17,14,1,50,
502,17,14,4,0,
502,17,33,1,1,1
502,17,39,1,1,2
502,17,39,1,5,
502,17,55,1,1,3
502,17,55,1,7,
502,17,56,1,53,
502,17,57,4,0,
502,17,58,4,0,
502,17,59,4,0,
502,17,92,4,0,
502,17,104,4,0,
502,17,116,1,13,
502,17,127,4,0,
502,17,156,4,0,
502,17,164,4,0,
502,17,182,4,0,
502,17,206,4,0,
502,17,207,4,0,
502,17,210,1,21,
502,17,213,4,0,
502,17,214,4,0,
502,17,216,4,0,
502,17,218,4,0,
502,17,227,1,37,
502,17,237,4,0,
502,17,240,4,0,
502,17,258,4,0,
502,17,263,4,0,
502,17,269,4,0,
502,17,279,1,29,
502,17,332,4,0,
502,17,346,1,1,4
502,17,346,1,11,
502,17,352,1,26,
502,17,374,4,0,
502,17,401,1,42,
502,17,404,4,0,
502,17,447,4,0,
502,17,453,1,34,
502,17,496,4,0,
502,17,503,4,0,
502,17,514,1,45,
502,17,518,3,0,
502,17,526,4,0,
502,17,534,1,18,
502,17,590,4,0,
502,18,14,1,50,
502,18,14,4,0,
502,18,33,1,1,1
502,18,39,1,1,2
502,18,39,1,5,
502,18,55,1,1,3
502,18,55,1,7,
502,18,56,1,53,
502,18,57,4,0,
502,18,58,4,0,
502,18,59,4,0,
502,18,92,4,0,
502,18,104,4,0,
502,18,116,1,13,
502,18,127,4,0,
502,18,156,4,0,
502,18,164,4,0,
502,18,182,4,0,
502,18,206,4,0,
502,18,207,4,0,
502,18,210,1,21,
502,18,213,4,0,
502,18,214,4,0,
502,18,216,4,0,
502,18,218,4,0,
502,18,227,1,37,
502,18,237,4,0,
502,18,240,4,0,
502,18,258,4,0,
502,18,263,4,0,
502,18,269,4,0,
502,18,279,1,29,
502,18,332,4,0,
502,18,346,1,1,4
502,18,346,1,11,
502,18,352,1,26,
502,18,374,4,0,
502,18,401,1,42,
502,18,404,4,0,
502,18,447,4,0,
502,18,453,1,34,
502,18,496,4,0,
502,18,503,4,0,
502,18,514,1,45,
502,18,518,3,0,
502,18,526,4,0,
502,18,534,1,18,
502,18,590,4,0,
503,11,14,1,57,
503,11,14,4,0,
503,11,15,4,0,
503,11,33,1,1,2
503,11,39,1,1,3
503,11,39,1,5,
503,11,55,1,1,4
503,11,55,1,7,
503,11,56,1,62,
503,11,57,4,0,
503,11,58,4,0,
503,11,59,4,0,
503,11,63,4,0,
503,11,70,4,0,
503,11,91,4,0,
503,11,92,4,0,
503,11,104,4,0,
503,11,116,1,13,
503,11,127,4,0,
503,11,156,4,0,
503,11,163,1,36,
503,11,164,4,0,
503,11,182,4,0,
503,11,206,4,0,
503,11,207,4,0,
503,11,210,1,20,
503,11,213,4,0,
503,11,216,4,0,
503,11,218,4,0,
503,11,224,1,1,1
503,11,227,1,38,
503,11,237,4,0,
503,11,240,4,0,
503,11,249,4,0,
503,11,258,4,0,
503,11,263,4,0,
503,11,269,4,0,
503,11,279,1,28,
503,11,291,4,0,
503,11,308,3,0,
503,11,332,4,0,
503,11,346,1,1,5
503,11,346,1,11,
503,11,352,1,25,
503,11,374,4,0,
503,11,401,1,45,
503,11,404,4,0,
503,11,416,4,0,
503,11,447,4,0,
503,11,453,1,33,
503,11,496,4,0,
503,11,503,4,0,
503,11,514,1,50,
503,11,514,4,0,
503,11,518,3,0,
503,11,525,4,0,
503,11,534,1,17,
503,14,14,1,57,
503,14,14,4,0,
503,14,15,4,0,
503,14,33,1,1,2
503,14,39,1,1,3
503,14,39,1,5,
503,14,55,1,1,4
503,14,55,1,7,
503,14,56,1,62,
503,14,57,4,0,
503,14,58,4,0,
503,14,59,4,0,
503,14,63,4,0,
503,14,70,4,0,
503,14,91,4,0,
503,14,92,4,0,
503,14,104,4,0,
503,14,116,1,13,
503,14,127,4,0,
503,14,156,4,0,
503,14,163,1,36,
503,14,164,4,0,
503,14,173,3,0,
503,14,182,4,0,
503,14,196,3,0,
503,14,206,4,0,
503,14,207,4,0,
503,14,210,1,20,
503,14,213,4,0,
503,14,214,3,0,
503,14,216,4,0,
503,14,218,4,0,
503,14,224,1,1,1
503,14,227,1,38,
503,14,231,3,0,
503,14,237,4,0,
503,14,240,4,0,
503,14,249,4,0,
503,14,258,4,0,
503,14,263,4,0,
503,14,269,4,0,
503,14,270,3,0,
503,14,276,3,0,
503,14,279,1,28,
503,14,282,3,0,
503,14,291,4,0,
503,14,308,3,0,
503,14,332,4,0,
503,14,335,3,0,
503,14,343,3,0,
503,14,346,1,1,5
503,14,346,1,11,
503,14,352,1,25,
503,14,374,4,0,
503,14,401,1,45,
503,14,401,3,0,
503,14,404,4,0,
503,14,416,4,0,
503,14,447,4,0,
503,14,453,1,33,
503,14,496,4,0,
503,14,503,4,0,
503,14,514,1,50,
503,14,514,4,0,
503,14,518,3,0,
503,14,525,4,0,
503,14,534,1,17,
503,15,14,1,57,
503,15,14,4,0,
503,15,15,4,0,
503,15,33,1,1,2
503,15,39,1,1,3
503,15,39,1,5,
503,15,55,1,1,4
503,15,55,1,7,
503,15,56,1,62,
503,15,57,4,0,
503,15,58,4,0,
503,15,59,4,0,
503,15,63,4,0,
503,15,70,4,0,
503,15,91,4,0,
503,15,92,4,0,
503,15,104,4,0,
503,15,116,1,13,
503,15,127,4,0,
503,15,156,4,0,
503,15,163,1,36,
503,15,164,4,0,
503,15,182,4,0,
503,15,206,4,0,
503,15,207,4,0,
503,15,210,1,20,
503,15,213,4,0,
503,15,214,4,0,
503,15,216,4,0,
503,15,218,4,0,
503,15,224,1,1,1
503,15,227,1,38,
503,15,237,4,0,
503,15,240,4,0,
503,15,249,4,0,
503,15,258,4,0,
503,15,263,4,0,
503,15,269,4,0,
503,15,279,1,28,
503,15,308,3,0,
503,15,332,4,0,
503,15,346,1,1,5
503,15,346,1,11,
503,15,352,1,25,
503,15,374,4,0,
503,15,401,1,45,
503,15,404,4,0,
503,15,416,4,0,
503,15,447,4,0,
503,15,453,1,33,
503,15,496,4,0,
503,15,503,4,0,
503,15,514,1,50,
503,15,514,4,0,
503,15,518,3,0,
503,15,525,4,0,
503,15,534,1,17,
503,15,590,4,0,
503,16,14,1,57,1
503,16,14,4,0,
503,16,15,4,0,
503,16,33,1,1,2
503,16,39,1,1,3
503,16,39,1,5,1
503,16,55,1,1,4
503,16,55,1,7,1
503,16,56,1,62,1
503,16,57,4,0,
503,16,58,4,0,
503,16,59,4,0,
503,16,63,4,0,
503,16,70,4,0,
503,16,91,4,0,
503,16,92,4,0,
503,16,104,4,0,
503,16,116,1,13,1
503,16,127,4,0,
503,16,156,4,0,
503,16,163,1,36,1
503,16,164,4,0,
503,16,173,3,0,
503,16,182,4,0,
503,16,196,3,0,
503,16,206,4,0,
503,16,207,4,0,
503,16,210,1,20,1
503,16,213,4,0,
503,16,214,4,0,
503,16,216,4,0,
503,16,218,4,0,
503,16,224,1,1,1
503,16,227,1,38,1
503,16,231,3,0,
503,16,237,4,0,
503,16,240,4,0,
503,16,249,4,0,
503,16,258,4,0,
503,16,263,4,0,
503,16,269,4,0,
503,16,270,3,0,
503,16,276,3,0,
503,16,279,1,28,1
503,16,282,3,0,
503,16,290,4,0,
503,16,291,4,0,
503,16,308,3,0,
503,16,332,4,0,
503,16,335,3,0,
503,16,343,3,0,
503,16,346,1,1,5
503,16,346,1,11,1
503,16,352,1,25,1
503,16,352,3,0,
503,16,374,4,0,
503,16,401,1,45,1
503,16,401,3,0,
503,16,404,4,0,
503,16,416,4,0,
503,16,447,4,0,
503,16,453,1,33,1
503,16,496,4,0,
503,16,503,4,0,
503,16,514,1,50,1
503,16,514,4,0,
503,16,518,3,0,
503,16,525,4,0,
503,16,534,1,17,1
503,16,590,4,0,
503,17,14,1,58,
503,17,14,4,0,
503,17,33,1,1,3
503,17,39,1,1,4
503,17,39,1,5,
503,17,55,1,1,5
503,17,55,1,7,
503,17,56,1,63,
503,17,57,4,0,
503,17,58,4,0,
503,17,59,4,0,
503,17,63,4,0,
503,17,92,4,0,
503,17,104,4,0,
503,17,116,1,13,
503,17,127,4,0,
503,17,156,4,0,
503,17,163,1,0,
503,17,163,1,1,1
503,17,164,4,0,
503,17,182,4,0,
503,17,206,4,0,
503,17,207,4,0,
503,17,210,1,21,
503,17,213,4,0,
503,17,214,4,0,
503,17,216,4,0,
503,17,218,4,0,
503,17,224,1,1,2
503,17,227,1,39,
503,17,237,4,0,
503,17,240,4,0,
503,17,258,4,0,
503,17,263,4,0,
503,17,269,4,0,
503,17,279,1,29,
503,17,308,3,0,
503,17,332,4,0,
503,17,346,1,1,6
503,17,346,1,11,
503,17,352,1,25,
503,17,374,4,0,
503,17,401,1,46,
503,17,404,4,0,
503,17,416,4,0,
503,17,447,4,0,
503,17,453,1,34,
503,17,496,4,0,
503,17,503,4,0,
503,17,514,1,51,
503,17,518,3,0,
503,17,525,4,0,
503,17,526,4,0,
503,17,534,1,18,
503,17,590,4,0,
503,17,684,4,0,
503,18,14,1,58,
503,18,14,4,0,
503,18,33,1,1,3
503,18,39,1,1,4
503,18,39,1,5,
503,18,55,1,1,5
503,18,55,1,7,
503,18,56,1,63,
503,18,57,4,0,
503,18,58,4,0,
503,18,59,4,0,
503,18,63,4,0,
503,18,92,4,0,
503,18,104,4,0,
503,18,116,1,13,
503,18,127,4,0,
503,18,156,4,0,
503,18,163,1,0,
503,18,163,1,1,1
503,18,164,4,0,
503,18,182,4,0,
503,18,206,4,0,
503,18,207,4,0,
503,18,210,1,21,
503,18,213,4,0,
503,18,214,4,0,
503,18,216,4,0,
503,18,218,4,0,
503,18,224,1,1,2
503,18,227,1,39,
503,18,237,4,0,
503,18,240,4,0,
503,18,258,4,0,
503,18,263,4,0,
503,18,269,4,0,
503,18,279,1,29,
503,18,308,3,0,
503,18,332,4,0,
503,18,346,1,1,6
503,18,346,1,11,
503,18,352,1,25,
503,18,374,4,0,
503,18,401,1,46,
503,18,404,4,0,
503,18,416,4,0,
503,18,447,4,0,
503,18,453,1,34,
503,18,496,4,0,
503,18,503,4,0,
503,18,514,1,51,
503,18,518,3,0,
503,18,525,4,0,
503,18,526,4,0,
503,18,534,1,18,
503,18,590,4,0,
503,18,684,4,0,
504,11,14,4,0,
504,11,15,4,0,
504,11,21,1,36,
504,11,28,1,13,
504,11,33,1,1,
504,11,43,1,3,
504,11,44,1,6,
504,11,85,4,0,
504,11,91,4,0,
504,11,92,4,0,
504,11,95,1,18,
504,11,103,2,0,
504,11,104,4,0,
504,11,117,1,8,
504,11,156,4,0,
504,11,158,1,28,
504,11,162,1,21,
504,11,164,4,0,
504,11,175,2,0,
504,11,182,4,0,
504,11,193,2,0,
504,11,197,1,11,
504,11,207,4,0,
504,11,212,1,31,
504,11,213,4,0,
504,11,216,4,0,
504,11,218,4,0,
504,11,226,1,33,
504,11,228,2,0,
504,11,231,2,0,
504,11,237,4,0,
504,11,240,4,0,
504,11,241,4,0,
504,11,242,1,16,
504,11,247,4,0,
504,11,263,4,0,
504,11,279,2,0,
504,11,372,2,0,
504,11,374,4,0,
504,11,447,4,0,
504,11,495,1,23,
504,11,496,4,0,
504,11,514,4,0,
504,11,526,1,26,
504,11,526,4,0,
504,14,14,4,0,
504,14,15,4,0,
504,14,21,1,36,
504,14,28,1,13,
504,14,33,1,1,
504,14,43,1,3,
504,14,44,1,6,
504,14,67,3,0,
504,14,85,4,0,
504,14,91,4,0,
504,14,92,4,0,
504,14,95,1,18,
504,14,103,2,0,
504,14,104,4,0,
504,14,117,1,8,
504,14,156,4,0,
504,14,158,1,28,
504,14,162,1,21,
504,14,162,3,0,
504,14,164,4,0,
504,14,173,3,0,
504,14,175,2,0,
504,14,182,4,0,
504,14,193,2,0,
504,14,197,1,11,
504,14,207,4,0,
504,14,212,1,31,
504,14,213,4,0,
504,14,214,3,0,
504,14,216,4,0,
504,14,218,4,0,
504,14,226,1,33,
504,14,228,2,0,
504,14,231,2,0,
504,14,231,3,0,
504,14,237,4,0,
504,14,240,4,0,
504,14,241,4,0,
504,14,242,1,16,
504,14,247,4,0,
504,14,263,4,0,
504,14,270,3,0,
504,14,279,2,0,
504,14,283,3,0,
504,14,343,3,0,
504,14,372,2,0,
504,14,374,4,0,
504,14,387,3,0,
504,14,401,3,0,
504,14,402,3,0,
504,14,428,3,0,
504,14,441,3,0,
504,14,447,4,0,
504,14,495,1,23,
504,14,495,3,0,
504,14,496,4,0,
504,14,514,4,0,
504,14,526,1,26,
504,14,526,4,0,
504,15,14,4,0,
504,15,15,4,0,
504,15,21,1,36,
504,15,28,1,13,
504,15,33,1,1,
504,15,43,1,3,
504,15,44,1,6,
504,15,85,4,0,
504,15,91,4,0,
504,15,92,4,0,
504,15,95,1,18,
504,15,103,2,0,
504,15,104,4,0,
504,15,117,1,8,
504,15,156,4,0,
504,15,158,1,28,
504,15,162,1,21,
504,15,164,4,0,
504,15,175,2,0,
504,15,182,4,0,
504,15,193,2,0,
504,15,197,1,11,
504,15,207,4,0,
504,15,212,1,31,
504,15,213,4,0,
504,15,214,4,0,
504,15,216,4,0,
504,15,218,4,0,
504,15,226,1,33,
504,15,228,2,0,
504,15,231,2,0,
504,15,237,4,0,
504,15,240,4,0,
504,15,241,4,0,
504,15,242,1,16,
504,15,247,4,0,
504,15,263,4,0,
504,15,279,2,0,
504,15,372,2,0,
504,15,374,4,0,
504,15,447,4,0,
504,15,495,1,23,
504,15,496,4,0,
504,15,514,4,0,
504,15,526,1,26,
504,15,590,4,0,
504,16,14,4,0,
504,16,15,4,0,
504,16,21,1,36,1
504,16,28,1,13,1
504,16,33,1,1,1
504,16,43,1,3,1
504,16,44,1,6,1
504,16,67,3,0,
504,16,85,4,0,
504,16,91,4,0,
504,16,92,4,0,
504,16,95,1,18,1
504,16,103,2,0,
504,16,104,4,0,
504,16,117,1,8,1
504,16,156,4,0,
504,16,158,1,28,1
504,16,162,1,21,1
504,16,162,3,0,
504,16,164,4,0,
504,16,173,3,0,
504,16,175,2,0,
504,16,182,4,0,
504,16,193,2,0,
504,16,197,1,11,1
504,16,207,4,0,
504,16,212,1,31,1
504,16,213,4,0,
504,16,214,4,0,
504,16,216,4,0,
504,16,218,4,0,
504,16,226,1,33,1
504,16,228,2,0,
504,16,231,2,0,
504,16,231,3,0,
504,16,237,4,0,
504,16,240,4,0,
504,16,241,4,0,
504,16,242,1,16,1
504,16,247,4,0,
504,16,263,4,0,
504,16,270,3,0,
504,16,279,2,0,
504,16,283,3,0,
504,16,290,4,0,
504,16,343,3,0,
504,16,351,3,0,
504,16,372,2,0,
504,16,374,4,0,
504,16,387,3,0,
504,16,401,3,0,
504,16,402,3,0,
504,16,428,3,0,
504,16,441,3,0,
504,16,447,4,0,
504,16,495,1,23,1
504,16,495,3,0,
504,16,496,4,0,
504,16,514,4,0,
504,16,526,1,26,1
504,16,590,4,0,
504,17,14,4,0,
504,17,21,1,41,
504,17,28,1,13,
504,17,33,1,1,
504,17,43,1,3,
504,17,44,1,6,
504,17,85,4,0,
504,17,92,4,0,
504,17,95,1,18,
504,17,103,2,0,
504,17,104,4,0,
504,17,116,1,26,
504,17,117,1,8,
504,17,156,4,0,
504,17,158,1,31,
504,17,162,1,21,
504,17,164,4,0,
504,17,175,2,0,
504,17,182,4,0,
504,17,193,2,0,
504,17,197,1,11,
504,17,207,4,0,
504,17,212,1,36,
504,17,213,4,0,
504,17,214,4,0,
504,17,216,4,0,
504,17,218,4,0,
504,17,226,1,38,
504,17,228,2,0,
504,17,231,2,0,
504,17,237,4,0,
504,17,240,4,0,
504,17,241,4,0,
504,17,242,1,16,
504,17,247,4,0,
504,17,263,4,0,
504,17,279,2,0,
504,17,372,2,0,
504,17,374,4,0,
504,17,417,1,33,
504,17,447,4,0,
504,17,495,1,23,
504,17,496,4,0,
504,17,526,1,28,
504,17,526,4,0,
504,17,590,4,0,
504,18,14,4,0,
504,18,21,1,41,
504,18,28,1,13,
504,18,33,1,1,
504,18,43,1,3,
504,18,44,1,6,
504,18,85,4,0,
504,18,92,4,0,
504,18,95,1,18,
504,18,103,2,0,
504,18,104,4,0,
504,18,116,1,26,
504,18,117,1,8,
504,18,156,4,0,
504,18,158,1,31,
504,18,162,1,21,
504,18,164,4,0,
504,18,175,2,0,
504,18,182,4,0,
504,18,193,2,0,
504,18,197,1,11,
504,18,207,4,0,
504,18,212,1,36,
504,18,213,4,0,
504,18,214,4,0,
504,18,216,4,0,
504,18,218,4,0,
504,18,226,1,38,
504,18,228,2,0,
504,18,231,2,0,
504,18,237,4,0,
504,18,240,4,0,
504,18,241,4,0,
504,18,242,1,16,
504,18,247,4,0,
504,18,263,4,0,
504,18,279,2,0,
504,18,331,2,0,
504,18,372,2,0,
504,18,374,4,0,
504,18,417,1,33,
504,18,447,4,0,
504,18,495,1,23,
504,18,496,4,0,
504,18,526,1,28,
504,18,526,4,0,
504,18,590,4,0,
504,18,715,2,0,
505,11,14,4,0,
505,11,15,4,0,
505,11,21,1,43,
505,11,28,1,13,
505,11,33,1,1,1
505,11,43,1,1,2
505,11,43,1,3,
505,11,44,1,1,3
505,11,44,1,6,
505,11,53,4,0,
505,11,63,4,0,
505,11,67,1,1,4
505,11,70,4,0,
505,11,85,4,0,
505,11,86,4,0,
505,11,87,4,0,
505,11,91,4,0,
505,11,92,4,0,
505,11,95,1,18,
505,11,104,4,0,
505,11,109,1,20,
505,11,113,4,0,
505,11,117,1,8,
505,11,138,4,0,
505,11,148,4,0,
505,11,156,4,0,
505,11,158,1,32,
505,11,162,1,22,
505,11,164,4,0,
505,11,182,4,0,
505,11,197,1,11,
505,11,207,4,0,
505,11,212,1,36,
505,11,213,4,0,
505,11,216,4,0,
505,11,218,4,0,
505,11,226,1,39,
505,11,237,4,0,
505,11,240,4,0,
505,11,241,4,0,
505,11,242,1,16,
505,11,244,1,29,
505,11,244,4,0,
505,11,247,4,0,
505,11,249,4,0,
505,11,263,4,0,
505,11,374,4,0,
505,11,411,4,0,
505,11,416,4,0,
505,11,447,4,0,
505,11,495,1,25,
505,11,496,4,0,
505,11,514,4,0,
505,11,526,4,0,
505,14,7,3,0,
505,14,8,3,0,
505,14,9,3,0,
505,14,14,4,0,
505,14,15,4,0,
505,14,21,1,43,
505,14,28,1,13,
505,14,33,1,1,1
505,14,43,1,1,2
505,14,43,1,3,
505,14,44,1,1,3
505,14,44,1,6,
505,14,53,4,0,
505,14,63,4,0,
505,14,67,1,1,4
505,14,67,3,0,
505,14,70,4,0,
505,14,85,4,0,
505,14,86,4,0,
505,14,87,4,0,
505,14,91,4,0,
505,14,92,4,0,
505,14,95,1,18,
505,14,104,4,0,
505,14,109,1,20,
505,14,113,4,0,
505,14,117,1,8,
505,14,138,4,0,
505,14,148,4,0,
505,14,156,4,0,
505,14,158,1,32,
505,14,162,1,22,
505,14,162,3,0,
505,14,164,4,0,
505,14,173,3,0,
505,14,182,4,0,
505,14,197,1,11,
505,14,207,4,0,
505,14,212,1,36,
505,14,213,4,0,
505,14,214,3,0,
505,14,216,4,0,
505,14,218,4,0,
505,14,226,1,39,
505,14,231,3,0,
505,14,237,4,0,
505,14,240,4,0,
505,14,241,4,0,
505,14,242,1,16,
505,14,244,1,29,
505,14,244,4,0,
505,14,247,4,0,
505,14,249,4,0,
505,14,263,4,0,
505,14,270,3,0,
505,14,282,3,0,
505,14,283,3,0,
505,14,324,3,0,
505,14,343,3,0,
505,14,374,4,0,
505,14,387,3,0,
505,14,401,3,0,
505,14,402,3,0,
505,14,411,4,0,
505,14,416,4,0,
505,14,428,3,0,
505,14,441,3,0,
505,14,447,4,0,
505,14,495,1,25,
505,14,495,3,0,
505,14,496,4,0,
505,14,514,4,0,
505,14,526,4,0,
505,15,14,4,0,
505,15,15,4,0,
505,15,21,1,43,
505,15,28,1,13,
505,15,33,1,1,2
505,15,43,1,1,3
505,15,43,1,3,
505,15,44,1,1,4
505,15,44,1,6,
505,15,53,4,0,
505,15,63,4,0,
505,15,67,1,1,5
505,15,70,4,0,
505,15,85,4,0,
505,15,86,4,0,
505,15,87,4,0,
505,15,91,4,0,
505,15,92,4,0,
505,15,95,1,18,
505,15,104,4,0,
505,15,109,1,20,
505,15,113,4,0,
505,15,117,1,8,
505,15,138,4,0,
505,15,148,4,0,
505,15,156,4,0,
505,15,158,1,32,
505,15,162,1,22,
505,15,164,4,0,
505,15,182,4,0,
505,15,197,1,11,
505,15,207,4,0,
505,15,212,1,36,
505,15,213,4,0,
505,15,214,4,0,
505,15,216,4,0,
505,15,218,4,0,
505,15,226,1,39,
505,15,237,4,0,
505,15,240,4,0,
505,15,241,4,0,
505,15,242,1,16,
505,15,244,1,29,
505,15,244,4,0,
505,15,247,4,0,
505,15,249,4,0,
505,15,263,4,0,
505,15,374,4,0,
505,15,411,4,0,
505,15,416,4,0,
505,15,447,4,0,
505,15,495,1,25,
505,15,496,4,0,
505,15,514,4,0,
505,15,563,1,1,1
505,15,590,4,0,
505,15,612,4,0,
505,16,7,3,0,
505,16,8,3,0,
505,16,9,3,0,
505,16,14,4,0,
505,16,15,4,0,
505,16,21,1,43,1
505,16,28,1,13,1
505,16,33,1,1,2
505,16,43,1,1,3
505,16,43,1,3,1
505,16,44,1,1,4
505,16,44,1,6,1
505,16,53,4,0,
505,16,63,4,0,
505,16,67,1,1,5
505,16,67,3,0,
505,16,70,4,0,
505,16,85,4,0,
505,16,86,4,0,
505,16,87,4,0,
505,16,91,4,0,
505,16,92,4,0,
505,16,95,1,18,1
505,16,104,4,0,
505,16,109,1,20,1
505,16,113,4,0,
505,16,117,1,8,1
505,16,138,4,0,
505,16,148,4,0,
505,16,156,4,0,
505,16,158,1,32,1
505,16,162,1,22,1
505,16,162,3,0,
505,16,164,4,0,
505,16,173,3,0,
505,16,182,4,0,
505,16,197,1,11,1
505,16,207,4,0,
505,16,212,1,36,1
505,16,213,4,0,
505,16,214,4,0,
505,16,216,4,0,
505,16,218,4,0,
505,16,226,1,39,1
505,16,231,3,0,
505,16,237,4,0,
505,16,240,4,0,
505,16,241,4,0,
505,16,242,1,16,1
505,16,244,1,29,1
505,16,244,4,0,
505,16,247,4,0,
505,16,249,4,0,
505,16,263,4,0,
505,16,264,3,0,
505,16,270,3,0,
505,16,282,3,0,
505,16,283,3,0,
505,16,290,4,0,
505,16,324,3,0,
505,16,343,3,0,
505,16,351,3,0,
505,16,374,4,0,
505,16,387,3,0,
505,16,401,3,0,
505,16,402,3,0,
505,16,411,4,0,
505,16,416,4,0,
505,16,428,3,0,
505,16,441,3,0,
505,16,447,4,0,
505,16,495,1,25,1
505,16,495,3,0,
505,16,496,4,0,
505,16,514,4,0,
505,16,563,1,1,1
505,16,590,4,0,
505,16,612,4,0,
505,17,14,4,0,
505,17,21,1,50,
505,17,28,1,13,
505,17,33,1,1,3
505,17,43,1,1,4
505,17,43,1,3,
505,17,44,1,1,5
505,17,44,1,6,
505,17,53,4,0,
505,17,63,4,0,
505,17,67,1,1,6
505,17,85,4,0,
505,17,86,4,0,
505,17,87,4,0,
505,17,92,4,0,
505,17,95,1,18,
505,17,104,4,0,
505,17,109,1,0,
505,17,109,1,1,1
505,17,113,4,0,
505,17,116,1,29,
505,17,117,1,8,
505,17,138,4,0,
505,17,156,4,0,
505,17,158,1,36,
505,17,162,1,22,
505,17,164,4,0,
505,17,182,4,0,
505,17,197,1,11,
505,17,207,4,0,
505,17,212,1,43,
505,17,213,4,0,
505,17,214,4,0,
505,17,216,4,0,
505,17,218,4,0,
505,17,226,1,46,
505,17,237,4,0,
505,17,240,4,0,
505,17,241,4,0,
505,17,242,1,16,
505,17,244,1,32,
505,17,244,4,0,
505,17,247,4,0,
505,17,263,4,0,
505,17,374,4,0,
505,17,411,4,0,
505,17,416,4,0,
505,17,417,1,39,
505,17,447,4,0,
505,17,495,1,25,
505,17,496,4,0,
505,17,526,4,0,
505,17,563,1,1,2
505,17,590,4,0,
505,18,14,4,0,
505,18,21,1,50,
505,18,28,1,13,
505,18,33,1,1,3
505,18,43,1,1,4
505,18,43,1,3,
505,18,44,1,1,5
505,18,44,1,6,
505,18,53,4,0,
505,18,63,4,0,
505,18,67,1,1,6
505,18,85,4,0,
505,18,86,4,0,
505,18,87,4,0,
505,18,92,4,0,
505,18,95,1,18,
505,18,104,4,0,
505,18,109,1,0,
505,18,109,1,1,1
505,18,113,4,0,
505,18,116,1,29,
505,18,117,1,8,
505,18,138,4,0,
505,18,156,4,0,
505,18,158,1,36,
505,18,162,1,22,
505,18,164,4,0,
505,18,182,4,0,
505,18,197,1,11,
505,18,207,4,0,
505,18,212,1,43,
505,18,213,4,0,
505,18,214,4,0,
505,18,216,4,0,
505,18,218,4,0,
505,18,226,1,46,
505,18,237,4,0,
505,18,240,4,0,
505,18,241,4,0,
505,18,242,1,16,
505,18,244,1,32,
505,18,244,4,0,
505,18,247,4,0,
505,18,263,4,0,
505,18,374,4,0,
505,18,411,4,0,
505,18,416,4,0,
505,18,417,1,39,
505,18,447,4,0,
505,18,495,1,25,
505,18,496,4,0,
505,18,526,4,0,
505,18,563,1,1,2
505,18,590,4,0,
506,11,28,2,0,
506,11,33,1,1,2
506,11,36,1,15,
506,11,43,1,1,1
506,11,44,1,8,
506,11,46,1,26,
506,11,46,4,0,
506,11,85,4,0,
506,11,86,4,0,
506,11,91,4,0,
506,11,92,4,0,
506,11,104,4,0,
506,11,122,2,0,
506,11,156,4,0,
506,11,164,4,0,
506,11,179,1,33,
506,11,182,4,0,
506,11,189,2,0,
506,11,203,2,0,
506,11,204,2,0,
506,11,207,4,0,
506,11,213,4,0,
506,11,216,4,0,
506,11,218,4,0,
506,11,228,2,0,
506,11,237,4,0,
506,11,240,4,0,
506,11,241,4,0,
506,11,242,1,22,
506,11,247,4,0,
506,11,249,4,0,
506,11,263,4,0,
506,11,270,1,12,
506,11,281,2,0,
506,11,316,1,5,
506,11,317,4,0,
506,11,332,4,0,
506,11,336,2,0,
506,11,387,1,36,
506,11,416,1,40,
506,11,416,4,0,
506,11,422,2,0,
506,11,423,2,0,
506,11,424,2,0,
506,11,496,4,0,
506,11,514,1,29,
506,11,514,4,0,
506,11,526,1,19,
506,11,526,4,0,
506,11,528,4,0,
506,11,555,4,0,
506,14,28,2,0,
506,14,33,1,1,2
506,14,36,1,15,
506,14,43,1,1,1
506,14,44,1,8,
506,14,46,1,26,
506,14,46,4,0,
506,14,85,4,0,
506,14,86,4,0,
506,14,91,4,0,
506,14,92,4,0,
506,14,104,4,0,
506,14,122,2,0,
506,14,156,4,0,
506,14,164,4,0,
506,14,173,3,0,
506,14,179,1,33,
506,14,182,4,0,
506,14,189,2,0,
506,14,203,2,0,
506,14,204,2,0,
506,14,207,4,0,
506,14,213,4,0,
506,14,214,3,0,
506,14,216,4,0,
506,14,218,4,0,
506,14,228,2,0,
506,14,237,4,0,
506,14,240,4,0,
506,14,241,4,0,
506,14,242,1,22,
506,14,247,4,0,
506,14,249,4,0,
506,14,253,3,0,
506,14,263,4,0,
506,14,270,1,12,
506,14,270,3,0,
506,14,281,2,0,
506,14,304,3,0,
506,14,316,1,5,
506,14,317,4,0,
506,14,332,4,0,
506,14,336,2,0,
506,14,343,3,0,
506,14,387,1,36,
506,14,387,3,0,
506,14,416,1,40,
506,14,416,4,0,
506,14,422,2,0,
506,14,423,2,0,
506,14,424,2,0,
506,14,496,4,0,
506,14,514,1,29,
506,14,514,4,0,
506,14,526,1,19,
506,14,526,4,0,
506,14,528,4,0,
506,14,555,4,0,
506,15,28,2,0,
506,15,33,1,1,2
506,15,36,1,15,
506,15,43,1,1,1
506,15,44,1,8,
506,15,46,1,26,
506,15,46,4,0,
506,15,85,4,0,
506,15,86,4,0,
506,15,91,4,0,
506,15,92,4,0,
506,15,104,4,0,
506,15,122,2,0,
506,15,156,4,0,
506,15,164,4,0,
506,15,179,1,33,
506,15,182,4,0,
506,15,189,2,0,
506,15,203,2,0,
506,15,204,2,0,
506,15,207,4,0,
506,15,213,4,0,
506,15,214,4,0,
506,15,216,4,0,
506,15,218,4,0,
506,15,228,2,0,
506,15,237,4,0,
506,15,240,4,0,
506,15,241,4,0,
506,15,242,1,22,
506,15,247,4,0,
506,15,249,4,0,
506,15,263,4,0,
506,15,270,1,12,
506,15,281,2,0,
506,15,316,1,5,
506,15,317,4,0,
506,15,332,4,0,
506,15,336,2,0,
506,15,387,1,36,
506,15,416,1,40,
506,15,416,4,0,
506,15,422,2,0,
506,15,423,2,0,
506,15,424,2,0,
506,15,495,2,0,
506,15,496,4,0,
506,15,514,1,29,
506,15,514,4,0,
506,15,526,1,19,
506,15,528,4,0,
506,15,555,4,0,
506,15,583,1,45,
506,15,590,4,0,
506,15,608,1,10,
506,16,28,2,0,
506,16,33,1,1,2
506,16,36,1,15,1
506,16,43,1,1,1
506,16,44,1,8,1
506,16,46,1,26,1
506,16,46,4,0,
506,16,85,4,0,
506,16,86,4,0,
506,16,91,4,0,
506,16,92,4,0,
506,16,104,4,0,
506,16,122,2,0,
506,16,156,4,0,
506,16,164,4,0,
506,16,173,3,0,
506,16,179,1,33,1
506,16,182,4,0,
506,16,189,2,0,
506,16,203,2,0,
506,16,204,2,0,
506,16,207,4,0,
506,16,213,4,0,
506,16,214,4,0,
506,16,216,4,0,
506,16,218,4,0,
506,16,228,2,0,
506,16,237,4,0,
506,16,240,4,0,
506,16,241,4,0,
506,16,242,1,22,1
506,16,247,4,0,
506,16,249,4,0,
506,16,253,3,0,
506,16,263,4,0,
506,16,270,1,12,1
506,16,270,3,0,
506,16,281,2,0,
506,16,290,4,0,
506,16,304,3,0,
506,16,316,1,5,1
506,16,317,4,0,
506,16,332,4,0,
506,16,336,2,0,
506,16,343,3,0,
506,16,351,3,0,
506,16,387,1,36,1
506,16,387,3,0,
506,16,416,1,40,1
506,16,416,4,0,
506,16,422,2,0,
506,16,423,2,0,
506,16,424,2,0,
506,16,495,2,0,
506,16,495,3,0,
506,16,496,4,0,
506,16,514,1,29,1
506,16,514,4,0,
506,16,526,1,19,1
506,16,528,4,0,
506,16,555,4,0,
506,16,583,1,45,1
506,16,590,4,0,
506,16,608,1,10,1
506,17,28,2,0,
506,17,33,1,1,2
506,17,36,1,15,
506,17,43,1,1,1
506,17,44,1,8,
506,17,46,1,26,
506,17,46,4,0,
506,17,85,4,0,
506,17,86,4,0,
506,17,92,4,0,
506,17,104,4,0,
506,17,122,2,0,
506,17,156,4,0,
506,17,164,4,0,
506,17,179,1,33,
506,17,182,4,0,
506,17,189,2,0,
506,17,203,2,0,
506,17,204,2,0,
506,17,207,4,0,
506,17,213,4,0,
506,17,214,4,0,
506,17,216,4,0,
506,17,218,4,0,
506,17,228,2,0,
506,17,237,4,0,
506,17,240,4,0,
506,17,241,4,0,
506,17,242,1,22,
506,17,247,4,0,
506,17,263,4,0,
506,17,270,1,12,
506,17,281,2,0,
506,17,316,1,5,
506,17,317,4,0,
506,17,332,4,0,
506,17,336,2,0,
506,17,387,1,36,
506,17,416,1,40,
506,17,416,4,0,
506,17,422,2,0,
506,17,423,2,0,
506,17,424,2,0,
506,17,495,2,0,
506,17,496,4,0,
506,17,514,1,29,
506,17,526,1,19,
506,17,526,4,0,
506,17,528,4,0,
506,17,555,4,0,
506,17,583,1,45,
506,17,590,4,0,
506,17,608,1,10,
506,17,706,2,0,
506,18,28,2,0,
506,18,33,1,1,2
506,18,36,1,15,
506,18,43,1,1,1
506,18,44,1,8,
506,18,46,1,26,
506,18,46,4,0,
506,18,85,4,0,
506,18,86,4,0,
506,18,92,4,0,
506,18,104,4,0,
506,18,122,2,0,
506,18,156,4,0,
506,18,164,4,0,
506,18,179,1,33,
506,18,182,4,0,
506,18,189,2,0,
506,18,203,2,0,
506,18,204,2,0,
506,18,207,4,0,
506,18,213,4,0,
506,18,214,4,0,
506,18,216,4,0,
506,18,218,4,0,
506,18,228,2,0,
506,18,237,4,0,
506,18,240,4,0,
506,18,241,4,0,
506,18,242,1,22,
506,18,247,4,0,
506,18,263,4,0,
506,18,270,1,12,
506,18,281,2,0,
506,18,316,1,5,
506,18,317,4,0,
506,18,332,4,0,
506,18,336,2,0,
506,18,387,1,36,
506,18,416,1,40,
506,18,416,4,0,
506,18,422,2,0,
506,18,423,2,0,
506,18,424,2,0,
506,18,495,2,0,
506,18,496,4,0,
506,18,514,1,29,
506,18,526,1,19,
506,18,526,4,0,
506,18,528,4,0,
506,18,555,4,0,
506,18,583,1,45,
506,18,590,4,0,
506,18,608,1,10,
506,18,706,2,0,
507,11,33,1,1,2
507,11,36,1,15,
507,11,43,1,1,1
507,11,44,1,1,4
507,11,44,1,8,
507,11,46,1,29,
507,11,46,4,0,
507,11,57,4,0,
507,11,70,4,0,
507,11,85,4,0,
507,11,86,4,0,
507,11,91,4,0,
507,11,92,4,0,
507,11,104,4,0,
507,11,156,4,0,
507,11,164,4,0,
507,11,179,1,38,
507,11,182,4,0,
507,11,207,4,0,
507,11,213,4,0,
507,11,216,4,0,
507,11,218,4,0,
507,11,237,4,0,
507,11,240,4,0,
507,11,241,4,0,
507,11,242,1,24,
507,11,247,4,0,
507,11,249,4,0,
507,11,263,4,0,
507,11,270,1,12,
507,11,316,1,1,3
507,11,316,1,5,
507,11,317,4,0,
507,11,332,4,0,
507,11,371,4,0,
507,11,387,1,42,
507,11,416,1,47,
507,11,416,4,0,
507,11,496,4,0,
507,11,514,1,33,
507,11,514,4,0,
507,11,526,1,20,
507,11,526,4,0,
507,11,528,4,0,
507,11,555,4,0,
507,14,33,1,1,2
507,14,36,1,15,
507,14,43,1,1,1
507,14,44,1,1,4
507,14,44,1,8,
507,14,46,1,29,
507,14,46,4,0,
507,14,57,4,0,
507,14,70,4,0,
507,14,85,4,0,
507,14,86,4,0,
507,14,91,4,0,
507,14,92,4,0,
507,14,104,4,0,
507,14,156,4,0,
507,14,164,4,0,
507,14,173,3,0,
507,14,179,1,38,
507,14,182,4,0,
507,14,207,4,0,
507,14,213,4,0,
507,14,214,3,0,
507,14,216,4,0,
507,14,218,4,0,
507,14,237,4,0,
507,14,240,4,0,
507,14,241,4,0,
507,14,242,1,24,
507,14,247,4,0,
507,14,249,4,0,
507,14,253,3,0,
507,14,263,4,0,
507,14,270,1,12,
507,14,270,3,0,
507,14,304,3,0,
507,14,316,1,1,3
507,14,316,1,5,
507,14,317,4,0,
507,14,332,4,0,
507,14,343,3,0,
507,14,371,4,0,
507,14,387,1,42,
507,14,387,3,0,
507,14,416,1,47,
507,14,416,4,0,
507,14,496,4,0,
507,14,514,1,33,
507,14,514,4,0,
507,14,526,1,20,
507,14,526,4,0,
507,14,528,4,0,
507,14,555,4,0,
507,15,33,1,1,2
507,15,36,1,15,
507,15,43,1,1,1
507,15,44,1,1,4
507,15,44,1,8,
507,15,46,1,29,
507,15,46,4,0,
507,15,57,4,0,
507,15,70,4,0,
507,15,85,4,0,
507,15,86,4,0,
507,15,91,4,0,
507,15,92,4,0,
507,15,104,4,0,
507,15,156,4,0,
507,15,164,4,0,
507,15,179,1,38,
507,15,182,4,0,
507,15,207,4,0,
507,15,213,4,0,
507,15,214,4,0,
507,15,216,4,0,
507,15,218,4,0,
507,15,237,4,0,
507,15,240,4,0,
507,15,241,4,0,
507,15,242,1,24,
507,15,247,4,0,
507,15,249,4,0,
507,15,263,4,0,
507,15,270,1,12,
507,15,316,1,1,3
507,15,316,1,5,
507,15,317,4,0,
507,15,332,4,0,
507,15,371,4,0,
507,15,387,1,42,
507,15,416,1,47,
507,15,416,4,0,
507,15,496,4,0,
507,15,514,1,33,
507,15,514,4,0,
507,15,526,1,20,
507,15,528,4,0,
507,15,555,4,0,
507,15,583,1,52,
507,15,590,4,0,
507,16,33,1,1,2
507,16,36,1,15,1
507,16,43,1,1,1
507,16,44,1,1,4
507,16,44,1,8,1
507,16,46,1,29,1
507,16,46,4,0,
507,16,57,4,0,
507,16,70,4,0,
507,16,85,4,0,
507,16,86,4,0,
507,16,91,4,0,
507,16,92,4,0,
507,16,104,4,0,
507,16,156,4,0,
507,16,164,4,0,
507,16,173,3,0,
507,16,179,1,38,1
507,16,182,4,0,
507,16,207,4,0,
507,16,213,4,0,
507,16,214,4,0,
507,16,216,4,0,
507,16,218,4,0,
507,16,237,4,0,
507,16,240,4,0,
507,16,241,4,0,
507,16,242,1,24,1
507,16,247,4,0,
507,16,249,4,0,
507,16,253,3,0,
507,16,263,4,0,
507,16,270,1,12,1
507,16,270,3,0,
507,16,290,4,0,
507,16,304,3,0,
507,16,316,1,1,3
507,16,316,1,5,1
507,16,317,4,0,
507,16,332,4,0,
507,16,343,3,0,
507,16,351,3,0,
507,16,371,4,0,
507,16,387,1,42,1
507,16,387,3,0,
507,16,416,1,47,1
507,16,416,4,0,
507,16,495,3,0,
507,16,496,4,0,
507,16,514,1,33,1
507,16,514,4,0,
507,16,526,1,20,1
507,16,528,4,0,
507,16,555,4,0,
507,16,583,1,52,1
507,16,590,4,0,
507,17,33,1,1,2
507,17,36,1,15,
507,17,43,1,1,1
507,17,44,1,1,4
507,17,44,1,8,
507,17,46,1,29,
507,17,46,4,0,
507,17,57,4,0,
507,17,85,4,0,
507,17,86,4,0,
507,17,92,4,0,
507,17,104,4,0,
507,17,156,4,0,
507,17,164,4,0,
507,17,179,1,38,
507,17,182,4,0,
507,17,207,4,0,
507,17,213,4,0,
507,17,214,4,0,
507,17,216,4,0,
507,17,218,4,0,
507,17,237,4,0,
507,17,240,4,0,
507,17,241,4,0,
507,17,242,1,24,
507,17,247,4,0,
507,17,263,4,0,
507,17,270,1,12,
507,17,316,1,1,3
507,17,316,1,5,
507,17,317,4,0,
507,17,332,4,0,
507,17,371,4,0,
507,17,387,1,42,
507,17,416,1,47,
507,17,416,4,0,
507,17,496,4,0,
507,17,514,1,33,
507,17,526,1,20,
507,17,526,4,0,
507,17,528,4,0,
507,17,555,4,0,
507,17,583,1,52,
507,17,590,4,0,
507,18,33,1,1,2
507,18,36,1,15,
507,18,43,1,1,1
507,18,44,1,1,4
507,18,44,1,8,
507,18,46,1,29,
507,18,46,4,0,
507,18,57,4,0,
507,18,85,4,0,
507,18,86,4,0,
507,18,92,4,0,
507,18,104,4,0,
507,18,156,4,0,
507,18,164,4,0,
507,18,179,1,38,
507,18,182,4,0,
507,18,207,4,0,
507,18,213,4,0,
507,18,214,4,0,
507,18,216,4,0,
507,18,218,4,0,
507,18,237,4,0,
507,18,240,4,0,
507,18,241,4,0,
507,18,242,1,24,
507,18,247,4,0,
507,18,263,4,0,
507,18,270,1,12,
507,18,316,1,1,3
507,18,316,1,5,
507,18,317,4,0,
507,18,332,4,0,
507,18,371,4,0,
507,18,387,1,42,
507,18,416,1,47,
507,18,416,4,0,
507,18,496,4,0,
507,18,514,1,33,
507,18,526,1,20,
507,18,526,4,0,
507,18,528,4,0,
507,18,555,4,0,
507,18,583,1,52,
507,18,590,4,0,
508,11,33,1,1,5
508,11,36,1,15,
508,11,43,1,1,4
508,11,44,1,1,7
508,11,44,1,8,
508,11,46,1,29,
508,11,46,4,0,
508,11,57,4,0,
508,11,63,4,0,
508,11,70,4,0,
508,11,85,4,0,
508,11,86,4,0,
508,11,87,4,0,
508,11,91,4,0,
508,11,92,4,0,
508,11,104,4,0,
508,11,156,4,0,
508,11,164,4,0,
508,11,179,1,42,
508,11,182,4,0,
508,11,207,4,0,
508,11,213,4,0,
508,11,216,4,0,
508,11,218,4,0,
508,11,237,4,0,
508,11,240,4,0,
508,11,241,4,0,
508,11,242,1,24,
508,11,247,4,0,
508,11,249,4,0,
508,11,263,4,0,
508,11,270,1,12,
508,11,316,1,1,6
508,11,316,1,5,
508,11,317,4,0,
508,11,332,4,0,
508,11,371,4,0,
508,11,387,1,51,
508,11,416,1,59,
508,11,416,4,0,
508,11,422,1,1,3
508,11,423,1,1,1
508,11,424,1,1,2
508,11,496,4,0,
508,11,514,1,36,
508,11,514,4,0,
508,11,526,1,20,
508,11,526,4,0,
508,11,528,4,0,
508,11,555,4,0,
508,14,33,1,1,5
508,14,36,1,15,
508,14,43,1,1,4
508,14,44,1,1,7
508,14,44,1,8,
508,14,46,1,29,
508,14,46,4,0,
508,14,57,4,0,
508,14,63,4,0,
508,14,70,4,0,
508,14,85,4,0,
508,14,86,4,0,
508,14,87,4,0,
508,14,91,4,0,
508,14,92,4,0,
508,14,104,4,0,
508,14,156,4,0,
508,14,164,4,0,
508,14,173,3,0,
508,14,179,1,42,
508,14,182,4,0,
508,14,207,4,0,
508,14,213,4,0,
508,14,214,3,0,
508,14,216,4,0,
508,14,218,4,0,
508,14,237,4,0,
508,14,240,4,0,
508,14,241,4,0,
508,14,242,1,24,
508,14,247,4,0,
508,14,249,4,0,
508,14,253,3,0,
508,14,263,4,0,
508,14,270,1,12,
508,14,270,3,0,
508,14,276,3,0,
508,14,304,3,0,
508,14,316,1,1,6
508,14,316,1,5,
508,14,317,4,0,
508,14,332,4,0,
508,14,343,3,0,
508,14,371,4,0,
508,14,387,1,51,
508,14,387,3,0,
508,14,416,1,59,
508,14,416,4,0,
508,14,422,1,1,3
508,14,423,1,1,1
508,14,424,1,1,2
508,14,442,3,0,
508,14,496,4,0,
508,14,514,1,36,
508,14,514,4,0,
508,14,526,1,20,
508,14,526,4,0,
508,14,528,4,0,
508,14,555,4,0,
508,15,33,1,1,5
508,15,36,1,15,
508,15,43,1,1,4
508,15,44,1,1,7
508,15,44,1,8,
508,15,46,1,29,
508,15,46,4,0,
508,15,57,4,0,
508,15,63,4,0,
508,15,70,4,0,
508,15,85,4,0,
508,15,86,4,0,
508,15,87,4,0,
508,15,91,4,0,
508,15,92,4,0,
508,15,104,4,0,
508,15,156,4,0,
508,15,164,4,0,
508,15,179,1,42,
508,15,182,4,0,
508,15,207,4,0,
508,15,213,4,0,
508,15,214,4,0,
508,15,216,4,0,
508,15,218,4,0,
508,15,237,4,0,
508,15,240,4,0,
508,15,241,4,0,
508,15,242,1,24,
508,15,247,4,0,
508,15,249,4,0,
508,15,263,4,0,
508,15,270,1,12,
508,15,316,1,1,6
508,15,316,1,5,
508,15,317,4,0,
508,15,332,4,0,
508,15,371,4,0,
508,15,387,1,51,
508,15,416,1,59,
508,15,416,4,0,
508,15,422,1,1,3
508,15,423,1,1,1
508,15,424,1,1,2
508,15,496,4,0,
508,15,514,1,36,
508,15,514,4,0,
508,15,526,1,20,
508,15,528,4,0,
508,15,555,4,0,
508,15,583,1,63,
508,15,590,4,0,
508,16,33,1,1,5
508,16,36,1,15,1
508,16,43,1,1,4
508,16,44,1,1,7
508,16,44,1,8,1
508,16,46,1,29,1
508,16,46,4,0,
508,16,57,4,0,
508,16,63,4,0,
508,16,70,4,0,
508,16,85,4,0,
508,16,86,4,0,
508,16,87,4,0,
508,16,91,4,0,
508,16,92,4,0,
508,16,104,4,0,
508,16,156,4,0,
508,16,164,4,0,
508,16,173,3,0,
508,16,179,1,42,1
508,16,182,4,0,
508,16,207,4,0,
508,16,213,4,0,
508,16,214,4,0,
508,16,216,4,0,
508,16,218,4,0,
508,16,237,4,0,
508,16,240,4,0,
508,16,241,4,0,
508,16,242,1,24,1
508,16,247,4,0,
508,16,249,4,0,
508,16,253,3,0,
508,16,263,4,0,
508,16,270,1,12,1
508,16,270,3,0,
508,16,276,3,0,
508,16,290,4,0,
508,16,304,3,0,
508,16,316,1,1,6
508,16,316,1,5,1
508,16,317,4,0,
508,16,332,4,0,
508,16,343,3,0,
508,16,351,3,0,
508,16,371,4,0,
508,16,387,1,51,1
508,16,387,3,0,
508,16,416,1,59,1
508,16,416,4,0,
508,16,422,1,1,3
508,16,423,1,1,1
508,16,424,1,1,2
508,16,442,3,0,
508,16,495,3,0,
508,16,496,4,0,
508,16,514,1,36,1
508,16,514,4,0,
508,16,526,1,20,1
508,16,528,4,0,
508,16,555,4,0,
508,16,583,1,63,1
508,16,590,4,0,
508,17,33,1,1,5
508,17,36,1,15,
508,17,43,1,1,4
508,17,44,1,1,7
508,17,44,1,8,
508,17,46,1,29,
508,17,46,4,0,
508,17,57,4,0,
508,17,63,4,0,
508,17,85,4,0,
508,17,86,4,0,
508,17,87,4,0,
508,17,92,4,0,
508,17,104,4,0,
508,17,156,4,0,
508,17,164,4,0,
508,17,179,1,42,
508,17,182,4,0,
508,17,207,4,0,
508,17,213,4,0,
508,17,214,4,0,
508,17,216,4,0,
508,17,218,4,0,
508,17,237,4,0,
508,17,240,4,0,
508,17,241,4,0,
508,17,242,1,24,
508,17,247,4,0,
508,17,263,4,0,
508,17,270,1,12,
508,17,316,1,1,6
508,17,316,1,5,
508,17,317,4,0,
508,17,332,4,0,
508,17,371,4,0,
508,17,387,1,51,
508,17,416,1,59,
508,17,416,4,0,
508,17,422,1,1,3
508,17,423,1,1,1
508,17,424,1,1,2
508,17,496,4,0,
508,17,514,1,36,
508,17,526,1,20,
508,17,526,4,0,
508,17,528,4,0,
508,17,555,4,0,
508,17,583,1,63,
508,17,590,4,0,
508,18,33,1,1,5
508,18,36,1,15,
508,18,43,1,1,4
508,18,44,1,1,7
508,18,44,1,8,
508,18,46,1,29,
508,18,46,4,0,
508,18,57,4,0,
508,18,63,4,0,
508,18,85,4,0,
508,18,86,4,0,
508,18,87,4,0,
508,18,92,4,0,
508,18,104,4,0,
508,18,156,4,0,
508,18,164,4,0,
508,18,179,1,42,
508,18,182,4,0,
508,18,207,4,0,
508,18,213,4,0,
508,18,214,4,0,
508,18,216,4,0,
508,18,218,4,0,
508,18,237,4,0,
508,18,240,4,0,
508,18,241,4,0,
508,18,242,1,24,
508,18,247,4,0,
508,18,263,4,0,
508,18,270,1,12,
508,18,316,1,1,6
508,18,316,1,5,
508,18,317,4,0,
508,18,332,4,0,
508,18,371,4,0,
508,18,387,1,51,
508,18,416,1,59,
508,18,416,4,0,
508,18,422,1,1,3
508,18,423,1,1,1
508,18,424,1,1,2
508,18,496,4,0,
508,18,514,1,36,
508,18,526,1,20,
508,18,526,4,0,
508,18,528,4,0,
508,18,555,4,0,
508,18,583,1,63,
508,18,590,4,0,
509,11,6,2,0,
509,11,10,1,1,
509,11,15,4,0,
509,11,28,1,10,
509,11,45,1,3,
509,11,86,4,0,
509,11,92,4,0,
509,11,104,4,0,
509,11,138,4,0,
509,11,154,1,12,
509,11,156,4,0,
509,11,163,1,30,
509,11,164,4,0,
509,11,168,4,0,
509,11,182,4,0,
509,11,185,2,0,
509,11,204,2,0,
509,11,207,4,0,
509,11,213,4,0,
509,11,216,4,0,
509,11,218,4,0,
509,11,227,2,0,
509,11,228,1,15,
509,11,237,4,0,
509,11,240,4,0,
509,11,241,4,0,
509,11,244,4,0,
509,11,247,4,0,
509,11,252,1,21,
509,11,259,1,19,
509,11,259,4,0,
509,11,263,4,0,
509,11,269,4,0,
509,11,274,1,6,
509,11,281,2,0,
509,11,289,1,39,
509,11,313,2,0,
509,11,332,4,0,
509,11,343,2,0,
509,11,371,4,0,
509,11,372,1,28,
509,11,373,4,0,
509,11,389,1,46,
509,11,400,1,37,
509,11,417,1,42,
509,11,421,4,0,
509,11,445,1,33,
509,11,447,4,0,
509,11,468,1,24,
509,11,468,4,0,
509,11,492,2,0,
509,11,496,4,0,
509,11,497,4,0,
509,11,555,4,0,
509,14,6,2,0,
509,14,10,1,1,
509,14,15,4,0,
509,14,28,1,10,
509,14,45,1,3,
509,14,86,4,0,
509,14,92,4,0,
509,14,104,4,0,
509,14,138,4,0,
509,14,154,1,12,
509,14,156,4,0,
509,14,163,1,30,
509,14,164,4,0,
509,14,168,4,0,
509,14,173,3,0,
509,14,180,3,0,
509,14,182,4,0,
509,14,185,2,0,
509,14,204,2,0,
509,14,207,4,0,
509,14,213,4,0,
509,14,214,3,0,
509,14,216,4,0,
509,14,218,4,0,
509,14,227,2,0,
509,14,228,1,15,
509,14,231,3,0,
509,14,237,4,0,
509,14,240,4,0,
509,14,241,4,0,
509,14,244,4,0,
509,14,247,4,0,
509,14,252,1,21,
509,14,259,1,19,
509,14,259,4,0,
509,14,263,4,0,
509,14,269,4,0,
509,14,271,3,0,
509,14,272,3,0,
509,14,274,1,6,
509,14,281,2,0,
509,14,282,3,0,
509,14,289,1,39,
509,14,289,3,0,
509,14,304,3,0,
509,14,313,2,0,
509,14,332,4,0,
509,14,343,2,0,
509,14,343,3,0,
509,14,369,4,0,
509,14,371,4,0,
509,14,372,1,28,
509,14,373,4,0,
509,14,389,1,46,
509,14,399,3,0,
509,14,400,1,37,
509,14,402,3,0,
509,14,417,1,42,
509,14,421,4,0,
509,14,441,3,0,
509,14,445,1,33,
509,14,447,4,0,
509,14,468,1,24,
509,14,468,4,0,
509,14,492,2,0,
509,14,492,3,0,
509,14,496,4,0,
509,14,497,4,0,
509,14,555,4,0,
509,15,6,2,0,
509,15,10,1,1,
509,15,15,4,0,
509,15,28,1,10,
509,15,45,1,3,
509,15,86,4,0,
509,15,92,4,0,
509,15,104,4,0,
509,15,138,4,0,
509,15,154,1,12,
509,15,156,4,0,
509,15,163,1,30,
509,15,164,4,0,
509,15,168,4,0,
509,15,182,4,0,
509,15,185,2,0,
509,15,204,2,0,
509,15,207,4,0,
509,15,213,4,0,
509,15,214,4,0,
509,15,216,4,0,
509,15,218,4,0,
509,15,227,2,0,
509,15,228,1,15,
509,15,237,4,0,
509,15,240,4,0,
509,15,241,4,0,
509,15,244,4,0,
509,15,247,4,0,
509,15,252,1,21,
509,15,259,1,19,
509,15,259,4,0,
509,15,263,4,0,
509,15,269,4,0,
509,15,274,1,6,
509,15,281,2,0,
509,15,289,1,39,
509,15,313,2,0,
509,15,332,4,0,
509,15,343,2,0,
509,15,369,4,0,
509,15,371,4,0,
509,15,372,1,28,
509,15,373,4,0,
509,15,383,2,0,
509,15,389,1,46,
509,15,399,4,0,
509,15,400,1,37,
509,15,417,1,42,
509,15,421,4,0,
509,15,445,1,33,
509,15,447,4,0,
509,15,468,1,24,
509,15,468,4,0,
509,15,492,2,0,
509,15,496,4,0,
509,15,497,4,0,
509,15,555,4,0,
509,15,583,1,49,
509,15,590,4,0,
509,16,6,2,0,
509,16,10,1,1,1
509,16,15,4,0,
509,16,28,1,10,1
509,16,45,1,3,1
509,16,86,4,0,
509,16,92,4,0,
509,16,104,4,0,
509,16,138,4,0,
509,16,154,1,12,1
509,16,156,4,0,
509,16,163,1,30,1
509,16,164,4,0,
509,16,168,4,0,
509,16,173,3,0,
509,16,180,3,0,
509,16,182,4,0,
509,16,185,2,0,
509,16,204,2,0,
509,16,207,4,0,
509,16,213,4,0,
509,16,214,4,0,
509,16,216,4,0,
509,16,218,4,0,
509,16,227,2,0,
509,16,228,1,15,1
509,16,231,3,0,
509,16,237,4,0,
509,16,240,4,0,
509,16,241,4,0,
509,16,244,4,0,
509,16,247,4,0,
509,16,252,1,21,1
509,16,259,1,19,1
509,16,259,4,0,
509,16,263,4,0,
509,16,269,4,0,
509,16,271,3,0,
509,16,272,3,0,
509,16,274,1,6,1
509,16,281,2,0,
509,16,282,3,0,
509,16,289,1,39,1
509,16,289,3,0,
509,16,290,4,0,
509,16,304,3,0,
509,16,313,2,0,
509,16,332,4,0,
509,16,343,2,0,
509,16,343,3,0,
509,16,369,4,0,
509,16,371,4,0,
509,16,372,1,28,1
509,16,373,4,0,
509,16,383,2,0,
509,16,389,1,46,1
509,16,399,4,0,
509,16,400,1,37,1
509,16,402,3,0,
509,16,417,1,42,1
509,16,421,4,0,
509,16,441,3,0,
509,16,445,1,33,1
509,16,447,4,0,
509,16,468,1,24,1
509,16,468,4,0,
509,16,492,2,0,
509,16,492,3,0,
509,16,496,4,0,
509,16,497,4,0,
509,16,555,4,0,
509,16,583,1,49,1
509,16,590,4,0,
509,17,6,2,0,
509,17,10,1,1,
509,17,28,1,10,
509,17,45,1,3,
509,17,86,4,0,
509,17,92,4,0,
509,17,104,4,0,
509,17,138,4,0,
509,17,154,1,12,
509,17,156,4,0,
509,17,163,1,30,
509,17,164,4,0,
509,17,168,4,0,
509,17,182,4,0,
509,17,185,2,0,
509,17,204,2,0,
509,17,207,4,0,
509,17,213,4,0,
509,17,214,4,0,
509,17,216,4,0,
509,17,218,4,0,
509,17,227,2,0,
509,17,228,1,15,
509,17,237,4,0,
509,17,240,4,0,
509,17,241,4,0,
509,17,244,4,0,
509,17,247,4,0,
509,17,252,1,21,
509,17,259,1,19,
509,17,259,4,0,
509,17,263,4,0,
509,17,269,4,0,
509,17,274,1,6,
509,17,281,2,0,
509,17,289,1,39,
509,17,313,2,0,
509,17,332,4,0,
509,17,343,2,0,
509,17,369,4,0,
509,17,371,4,0,
509,17,372,1,28,
509,17,373,4,0,
509,17,383,2,0,
509,17,389,1,46,
509,17,399,4,0,
509,17,400,1,37,
509,17,417,1,42,
509,17,421,4,0,
509,17,445,1,33,
509,17,447,4,0,
509,17,468,1,24,
509,17,492,2,0,
509,17,496,4,0,
509,17,497,4,0,
509,17,555,4,0,
509,17,583,1,49,
509,17,590,4,0,
509,18,6,2,0,
509,18,10,1,1,
509,18,28,1,10,
509,18,45,1,3,
509,18,86,4,0,
509,18,92,4,0,
509,18,104,4,0,
509,18,138,4,0,
509,18,154,1,12,
509,18,156,4,0,
509,18,163,1,30,
509,18,164,4,0,
509,18,168,4,0,
509,18,182,4,0,
509,18,185,2,0,
509,18,204,2,0,
509,18,207,4,0,
509,18,213,4,0,
509,18,214,4,0,
509,18,216,4,0,
509,18,218,4,0,
509,18,227,2,0,
509,18,228,1,15,
509,18,237,4,0,
509,18,240,4,0,
509,18,241,4,0,
509,18,244,4,0,
509,18,247,4,0,
509,18,252,1,21,
509,18,259,1,19,
509,18,259,4,0,
509,18,263,4,0,
509,18,269,4,0,
509,18,274,1,6,
509,18,281,2,0,
509,18,289,1,39,
509,18,313,2,0,
509,18,332,4,0,
509,18,343,2,0,
509,18,369,4,0,
509,18,371,4,0,
509,18,372,1,28,
509,18,373,4,0,
509,18,383,2,0,
509,18,389,1,46,
509,18,399,4,0,
509,18,400,1,37,
509,18,417,1,42,
509,18,421,4,0,
509,18,445,1,33,
509,18,447,4,0,
509,18,468,1,24,
509,18,492,2,0,
509,18,496,4,0,
509,18,497,4,0,
509,18,555,4,0,
509,18,583,1,49,
509,18,590,4,0,
510,11,10,1,1,1
510,11,15,4,0,
510,11,28,1,1,4
510,11,28,1,10,
510,11,45,1,1,2
510,11,45,1,3,
510,11,63,4,0,
510,11,86,4,0,
510,11,92,4,0,
510,11,104,4,0,
510,11,138,4,0,
510,11,154,1,12,
510,11,156,4,0,
510,11,163,1,34,
510,11,164,4,0,
510,11,168,4,0,
510,11,182,4,0,
510,11,207,4,0,
510,11,213,4,0,
510,11,216,4,0,
510,11,218,4,0,
510,11,228,1,15,
510,11,237,4,0,
510,11,240,4,0,
510,11,241,4,0,
510,11,244,4,0,
510,11,247,4,0,
510,11,249,4,0,
510,11,252,1,22,
510,11,259,1,19,
510,11,259,4,0,
510,11,263,4,0,
510,11,269,1,38,
510,11,269,4,0,
510,11,274,1,1,3
510,11,274,1,6,
510,11,289,1,47,
510,11,332,4,0,
510,11,371,4,0,
510,11,372,1,31,
510,11,373,4,0,
510,11,389,1,55,
510,11,400,1,43,
510,11,416,4,0,
510,11,417,1,50,
510,11,421,4,0,
510,11,447,4,0,
510,11,468,1,26,
510,11,468,4,0,
510,11,496,4,0,
510,11,497,4,0,
510,11,555,4,0,
510,14,10,1,1,1
510,14,15,4,0,
510,14,28,1,1,4
510,14,28,1,10,
510,14,45,1,1,2
510,14,45,1,3,
510,14,63,4,0,
510,14,86,4,0,
510,14,92,4,0,
510,14,104,4,0,
510,14,138,4,0,
510,14,154,1,12,
510,14,156,4,0,
510,14,163,1,34,
510,14,164,4,0,
510,14,168,4,0,
510,14,173,3,0,
510,14,180,3,0,
510,14,182,4,0,
510,14,207,4,0,
510,14,213,4,0,
510,14,214,3,0,
510,14,216,4,0,
510,14,218,4,0,
510,14,228,1,15,
510,14,231,3,0,
510,14,237,4,0,
510,14,240,4,0,
510,14,241,4,0,
510,14,244,4,0,
510,14,247,4,0,
510,14,249,4,0,
510,14,252,1,22,
510,14,259,1,19,
510,14,259,4,0,
510,14,263,4,0,
510,14,269,1,38,
510,14,269,4,0,
510,14,271,3,0,
510,14,272,3,0,
510,14,274,1,1,3
510,14,274,1,6,
510,14,282,3,0,
510,14,289,1,47,
510,14,289,3,0,
510,14,304,3,0,
510,14,332,4,0,
510,14,343,3,0,
510,14,369,4,0,
510,14,371,4,0,
510,14,372,1,31,
510,14,373,4,0,
510,14,389,1,55,
510,14,399,3,0,
510,14,400,1,43,
510,14,402,3,0,
510,14,416,4,0,
510,14,417,1,50,
510,14,421,4,0,
510,14,441,3,0,
510,14,447,4,0,
510,14,468,1,26,
510,14,468,4,0,
510,14,492,3,0,
510,14,496,4,0,
510,14,497,4,0,
510,14,555,4,0,
510,15,10,1,1,1
510,15,15,4,0,
510,15,28,1,1,4
510,15,28,1,10,
510,15,45,1,1,2
510,15,45,1,3,
510,15,63,4,0,
510,15,86,4,0,
510,15,92,4,0,
510,15,104,4,0,
510,15,138,4,0,
510,15,154,1,12,
510,15,156,4,0,
510,15,163,1,34,
510,15,164,4,0,
510,15,168,4,0,
510,15,182,4,0,
510,15,207,4,0,
510,15,213,4,0,
510,15,214,4,0,
510,15,216,4,0,
510,15,218,4,0,
510,15,228,1,15,
510,15,237,4,0,
510,15,240,4,0,
510,15,241,4,0,
510,15,244,4,0,
510,15,247,4,0,
510,15,249,4,0,
510,15,252,1,22,
510,15,259,1,19,
510,15,259,4,0,
510,15,263,4,0,
510,15,269,1,38,
510,15,269,4,0,
510,15,274,1,1,3
510,15,274,1,6,
510,15,289,1,47,
510,15,332,4,0,
510,15,369,4,0,
510,15,371,4,0,
510,15,372,1,31,
510,15,373,4,0,
510,15,389,1,55,
510,15,399,4,0,
510,15,400,1,43,
510,15,416,4,0,
510,15,417,1,50,
510,15,421,4,0,
510,15,447,4,0,
510,15,468,1,26,
510,15,468,4,0,
510,15,496,4,0,
510,15,497,4,0,
510,15,555,4,0,
510,15,583,1,58,
510,15,590,4,0,
510,16,10,1,1,1
510,16,15,4,0,
510,16,28,1,1,4
510,16,28,1,10,1
510,16,45,1,1,2
510,16,45,1,3,1
510,16,63,4,0,
510,16,86,4,0,
510,16,92,4,0,
510,16,104,4,0,
510,16,138,4,0,
510,16,154,1,12,1
510,16,156,4,0,
510,16,163,1,34,1
510,16,164,4,0,
510,16,168,4,0,
510,16,173,3,0,
510,16,180,3,0,
510,16,182,4,0,
510,16,207,4,0,
510,16,213,4,0,
510,16,214,4,0,
510,16,216,4,0,
510,16,218,4,0,
510,16,228,1,15,1
510,16,231,3,0,
510,16,237,4,0,
510,16,240,4,0,
510,16,241,4,0,
510,16,244,4,0,
510,16,247,4,0,
510,16,249,4,0,
510,16,252,1,22,1
510,16,259,1,19,1
510,16,259,4,0,
510,16,263,4,0,
510,16,269,1,38,1
510,16,269,4,0,
510,16,271,3,0,
510,16,272,3,0,
510,16,274,1,1,3
510,16,274,1,6,1
510,16,282,3,0,
510,16,289,1,47,1
510,16,289,3,0,
510,16,290,4,0,
510,16,304,3,0,
510,16,332,4,0,
510,16,343,3,0,
510,16,369,4,0,
510,16,371,4,0,
510,16,372,1,31,1
510,16,373,4,0,
510,16,389,1,55,1
510,16,399,4,0,
510,16,400,1,43,1
510,16,402,3,0,
510,16,416,4,0,
510,16,417,1,50,1
510,16,421,4,0,
510,16,441,3,0,
510,16,447,4,0,
510,16,468,1,26,1
510,16,468,4,0,
510,16,492,3,0,
510,16,496,4,0,
510,16,497,4,0,
510,16,555,4,0,
510,16,583,1,58,1
510,16,590,4,0,
510,17,10,1,1,1
510,17,28,1,1,4
510,17,28,1,10,
510,17,45,1,1,2
510,17,45,1,3,
510,17,63,4,0,
510,17,86,4,0,
510,17,92,4,0,
510,17,104,4,0,
510,17,138,4,0,
510,17,154,1,12,
510,17,156,4,0,
510,17,163,1,34,
510,17,164,4,0,
510,17,168,4,0,
510,17,182,4,0,
510,17,207,4,0,
510,17,213,4,0,
510,17,214,4,0,
510,17,216,4,0,
510,17,218,4,0,
510,17,228,1,15,
510,17,237,4,0,
510,17,240,4,0,
510,17,241,4,0,
510,17,244,4,0,
510,17,247,4,0,
510,17,252,1,22,
510,17,259,1,19,
510,17,259,4,0,
510,17,263,4,0,
510,17,269,1,38,
510,17,269,4,0,
510,17,274,1,1,3
510,17,274,1,6,
510,17,289,1,47,
510,17,332,4,0,
510,17,369,4,0,
510,17,371,4,0,
510,17,372,1,31,
510,17,373,4,0,
510,17,389,1,55,
510,17,399,4,0,
510,17,400,1,43,
510,17,416,4,0,
510,17,417,1,50,
510,17,421,4,0,
510,17,447,4,0,
510,17,468,1,26,
510,17,496,4,0,
510,17,497,4,0,
510,17,555,4,0,
510,17,583,1,58,
510,17,590,4,0,
510,18,10,1,1,1
510,18,28,1,1,4
510,18,28,1,10,
510,18,45,1,1,2
510,18,45,1,3,
510,18,63,4,0,
510,18,86,4,0,
510,18,92,4,0,
510,18,104,4,0,
510,18,138,4,0,
510,18,154,1,12,
510,18,156,4,0,
510,18,163,1,34,
510,18,164,4,0,
510,18,168,4,0,
510,18,182,4,0,
510,18,207,4,0,
510,18,213,4,0,
510,18,214,4,0,
510,18,216,4,0,
510,18,218,4,0,
510,18,228,1,15,
510,18,237,4,0,
510,18,240,4,0,
510,18,241,4,0,
510,18,244,4,0,
510,18,247,4,0,
510,18,252,1,22,
510,18,259,1,19,
510,18,259,4,0,
510,18,263,4,0,
510,18,269,1,38,
510,18,269,4,0,
510,18,274,1,1,3
510,18,274,1,6,
510,18,289,1,47,
510,18,332,4,0,
510,18,369,4,0,
510,18,371,4,0,
510,18,372,1,31,
510,18,373,4,0,
510,18,389,1,55,
510,18,399,4,0,
510,18,400,1,43,
510,18,416,4,0,
510,18,417,1,50,
510,18,421,4,0,
510,18,447,4,0,
510,18,468,1,26,
510,18,496,4,0,
510,18,497,4,0,
510,18,555,4,0,
510,18,583,1,58,
510,18,590,4,0,
511,11,10,1,1,
511,11,15,4,0,
511,11,22,1,10,
511,11,43,1,4,
511,11,44,1,19,
511,11,67,2,0,
511,11,73,1,16,
511,11,76,4,0,
511,11,91,4,0,
511,11,92,4,0,
511,11,104,4,0,
511,11,122,1,7,
511,11,148,4,0,
511,11,154,1,13,
511,11,156,4,0,
511,11,164,4,0,
511,11,168,4,0,
511,11,182,4,0,
511,11,207,4,0,
511,11,213,4,0,
511,11,216,4,0,
511,11,218,4,0,
511,11,237,4,0,
511,11,241,4,0,
511,11,242,1,43,
511,11,249,4,0,
511,11,259,1,25,
511,11,259,4,0,
511,11,263,4,0,
511,11,269,4,0,
511,11,272,2,0,
511,11,278,1,37,
511,11,310,2,0,
511,11,317,4,0,
511,11,320,2,0,
511,11,321,2,0,
511,11,331,2,0,
511,11,343,2,0,
511,11,345,2,0,
511,11,363,1,40,
511,11,371,4,0,
511,11,374,1,28,
511,11,374,4,0,
511,11,402,1,22,
511,11,412,4,0,
511,11,417,2,0,
511,11,421,4,0,
511,11,437,2,0,
511,11,447,1,34,
511,11,447,4,0,
511,11,468,4,0,
511,11,490,4,0,
511,11,496,4,0,
511,11,512,1,31,
511,11,512,4,0,
511,11,526,4,0,
511,14,10,1,1,
511,14,15,4,0,
511,14,22,1,10,
511,14,43,1,4,
511,14,44,1,19,
511,14,67,2,0,
511,14,67,3,0,
511,14,73,1,16,
511,14,76,4,0,
511,14,91,4,0,
511,14,92,4,0,
511,14,104,4,0,
511,14,122,1,7,
511,14,148,4,0,
511,14,154,1,13,
511,14,156,4,0,
511,14,164,4,0,
511,14,168,4,0,
511,14,173,3,0,
511,14,182,4,0,
511,14,202,3,0,
511,14,207,4,0,
511,14,213,4,0,
511,14,214,3,0,
511,14,216,4,0,
511,14,218,4,0,
511,14,231,3,0,
511,14,235,3,0,
511,14,237,4,0,
511,14,241,4,0,
511,14,242,1,43,
511,14,249,4,0,
511,14,253,3,0,
511,14,259,1,25,
511,14,259,4,0,
511,14,263,4,0,
511,14,269,4,0,
511,14,270,3,0,
511,14,272,2,0,
511,14,272,3,0,
511,14,278,1,37,
511,14,278,3,0,
511,14,282,3,0,
511,14,283,3,0,
511,14,310,2,0,
511,14,317,4,0,
511,14,320,2,0,
511,14,321,2,0,
511,14,331,2,0,
511,14,343,2,0,
511,14,343,3,0,
511,14,345,2,0,
511,14,363,1,40,
511,14,371,4,0,
511,14,374,1,28,
511,14,374,4,0,
511,14,388,3,0,
511,14,402,1,22,
511,14,402,3,0,
511,14,412,4,0,
511,14,417,2,0,
511,14,421,4,0,
511,14,437,2,0,
511,14,441,3,0,
511,14,447,1,34,
511,14,447,4,0,
511,14,468,4,0,
511,14,490,4,0,
511,14,496,4,0,
511,14,512,1,31,
511,14,512,4,0,
511,14,526,4,0,
511,15,10,1,1,1
511,15,15,4,0,
511,15,22,1,10,
511,15,43,1,4,
511,15,44,1,19,
511,15,67,2,0,
511,15,73,1,16,
511,15,76,4,0,
511,15,91,4,0,
511,15,92,4,0,
511,15,104,4,0,
511,15,122,1,7,
511,15,148,4,0,
511,15,154,1,13,
511,15,156,4,0,
511,15,164,4,0,
511,15,168,4,0,
511,15,182,4,0,
511,15,207,4,0,
511,15,213,4,0,
511,15,214,4,0,
511,15,216,4,0,
511,15,218,4,0,
511,15,237,4,0,
511,15,241,4,0,
511,15,242,1,43,
511,15,249,4,0,
511,15,259,1,25,
511,15,259,4,0,
511,15,263,4,0,
511,15,267,4,0,
511,15,269,4,0,
511,15,272,2,0,
511,15,278,1,37,
511,15,310,2,0,
511,15,317,4,0,
511,15,320,2,0,
511,15,321,2,0,
511,15,331,2,0,
511,15,343,2,0,
511,15,345,2,0,
511,15,363,1,40,
511,15,371,4,0,
511,15,374,1,28,
511,15,374,4,0,
511,15,402,1,22,
511,15,412,4,0,
511,15,417,2,0,
511,15,421,4,0,
511,15,437,2,0,
511,15,447,1,34,
511,15,447,4,0,
511,15,468,4,0,
511,15,490,4,0,
511,15,496,4,0,
511,15,512,1,31,
511,15,512,4,0,
511,15,574,2,0,
511,15,589,1,1,2
511,15,590,4,0,
511,16,10,1,1,1
511,16,15,4,0,
511,16,22,1,10,1
511,16,43,1,4,1
511,16,44,1,19,1
511,16,67,2,0,
511,16,67,3,0,
511,16,73,1,16,1
511,16,76,4,0,
511,16,91,4,0,
511,16,92,4,0,
511,16,104,4,0,
511,16,122,1,7,1
511,16,148,4,0,
511,16,154,1,13,1
511,16,156,4,0,
511,16,164,4,0,
511,16,168,4,0,
511,16,173,3,0,
511,16,182,4,0,
511,16,202,3,0,
511,16,207,4,0,
511,16,213,4,0,
511,16,214,4,0,
511,16,216,4,0,
511,16,218,4,0,
511,16,231,3,0,
511,16,235,3,0,
511,16,237,4,0,
511,16,241,4,0,
511,16,242,1,43,1
511,16,249,4,0,
511,16,253,3,0,
511,16,259,1,25,1
511,16,259,4,0,
511,16,263,4,0,
511,16,264,3,0,
511,16,267,4,0,
511,16,269,4,0,
511,16,270,3,0,
511,16,272,2,0,
511,16,272,3,0,
511,16,278,1,37,1
511,16,278,3,0,
511,16,282,3,0,
511,16,283,3,0,
511,16,290,4,0,
511,16,310,2,0,
511,16,317,4,0,
511,16,320,2,0,
511,16,321,2,0,
511,16,331,2,0,
511,16,343,2,0,
511,16,343,3,0,
511,16,345,2,0,
511,16,363,1,40,1
511,16,371,4,0,
511,16,374,1,28,1
511,16,374,4,0,
511,16,380,3,0,
511,16,388,3,0,
511,16,402,1,22,1
511,16,402,3,0,
511,16,412,4,0,
511,16,417,2,0,
511,16,421,4,0,
511,16,437,2,0,
511,16,441,3,0,
511,16,447,1,34,1
511,16,447,4,0,
511,16,468,4,0,
511,16,490,4,0,
511,16,496,4,0,
511,16,512,1,31,1
511,16,512,4,0,
511,16,574,2,0,
511,16,589,1,1,2
511,16,590,4,0,
511,17,10,1,1,1
511,17,22,1,10,
511,17,43,1,4,
511,17,44,1,19,
511,17,67,2,0,
511,17,73,1,16,
511,17,76,4,0,
511,17,92,4,0,
511,17,104,4,0,
511,17,122,1,7,
511,17,154,1,13,
511,17,156,4,0,
511,17,164,4,0,
511,17,168,4,0,
511,17,182,4,0,
511,17,207,4,0,
511,17,213,4,0,
511,17,214,4,0,
511,17,216,4,0,
511,17,218,4,0,
511,17,237,4,0,
511,17,241,4,0,
511,17,242,1,43,
511,17,259,1,25,
511,17,259,4,0,
511,17,263,4,0,
511,17,267,4,0,
511,17,269,4,0,
511,17,272,2,0,
511,17,278,1,37,
511,17,310,2,0,
511,17,317,4,0,
511,17,320,2,0,
511,17,321,2,0,
511,17,331,2,0,
511,17,343,2,0,
511,17,345,2,0,
511,17,363,1,40,
511,17,371,4,0,
511,17,374,1,28,
511,17,374,4,0,
511,17,402,1,22,
511,17,412,4,0,
511,17,417,2,0,
511,17,421,4,0,
511,17,437,2,0,
511,17,447,1,34,
511,17,447,4,0,
511,17,490,4,0,
511,17,496,4,0,
511,17,512,1,31,
511,17,512,4,0,
511,17,520,3,0,
511,17,526,4,0,
511,17,574,2,0,
511,17,589,1,1,2
511,17,590,4,0,
511,18,10,1,1,1
511,18,22,1,10,
511,18,43,1,4,
511,18,44,1,19,
511,18,67,2,0,
511,18,73,1,16,
511,18,76,4,0,
511,18,92,4,0,
511,18,104,4,0,
511,18,122,1,7,
511,18,154,1,13,
511,18,156,4,0,
511,18,164,4,0,
511,18,168,4,0,
511,18,182,4,0,
511,18,207,4,0,
511,18,213,4,0,
511,18,214,4,0,
511,18,216,4,0,
511,18,218,4,0,
511,18,237,4,0,
511,18,241,4,0,
511,18,242,1,43,
511,18,259,1,25,
511,18,259,4,0,
511,18,263,4,0,
511,18,267,4,0,
511,18,269,4,0,
511,18,272,2,0,
511,18,278,1,37,
511,18,310,2,0,
511,18,317,4,0,
511,18,320,2,0,
511,18,321,2,0,
511,18,331,2,0,
511,18,343,2,0,
511,18,345,2,0,
511,18,363,1,40,
511,18,371,4,0,
511,18,374,1,28,
511,18,374,4,0,
511,18,402,1,22,
511,18,412,4,0,
511,18,417,2,0,
511,18,421,4,0,
511,18,437,2,0,
511,18,447,1,34,
511,18,447,4,0,
511,18,490,4,0,
511,18,496,4,0,
511,18,512,1,31,
511,18,512,4,0,
511,18,520,3,0,
511,18,526,4,0,
511,18,574,2,0,
511,18,589,1,1,2
511,18,590,4,0,
511,18,596,2,0,
512,11,15,4,0,
512,11,43,1,1,1
512,11,63,4,0,
512,11,76,4,0,
512,11,91,4,0,
512,11,92,4,0,
512,11,104,4,0,
512,11,122,1,1,2
512,11,148,4,0,
512,11,154,1,1,3
512,11,156,4,0,
512,11,157,4,0,
512,11,164,4,0,
512,11,168,4,0,
512,11,182,4,0,
512,11,207,4,0,
512,11,213,4,0,
512,11,216,4,0,
512,11,218,4,0,
512,11,237,4,0,
512,11,241,4,0,
512,11,249,4,0,
512,11,259,4,0,
512,11,263,4,0,
512,11,269,4,0,
512,11,280,4,0,
512,11,317,4,0,
512,11,371,4,0,
512,11,374,4,0,
512,11,402,1,1,4
512,11,411,4,0,
512,11,412,4,0,
512,11,416,4,0,
512,11,421,4,0,
512,11,447,4,0,
512,11,468,4,0,
512,11,490,4,0,
512,11,496,4,0,
512,11,512,4,0,
512,11,526,4,0,
512,14,15,4,0,
512,14,43,1,1,1
512,14,63,4,0,
512,14,67,3,0,
512,14,76,4,0,
512,14,91,4,0,
512,14,92,4,0,
512,14,104,4,0,
512,14,122,1,1,2
512,14,148,4,0,
512,14,154,1,1,3
512,14,156,4,0,
512,14,157,4,0,
512,14,164,4,0,
512,14,168,4,0,
512,14,173,3,0,
512,14,182,4,0,
512,14,202,3,0,
512,14,207,4,0,
512,14,213,4,0,
512,14,214,3,0,
512,14,216,4,0,
512,14,218,4,0,
512,14,231,3,0,
512,14,235,3,0,
512,14,237,4,0,
512,14,241,4,0,
512,14,249,4,0,
512,14,253,3,0,
512,14,259,4,0,
512,14,263,4,0,
512,14,269,4,0,
512,14,270,3,0,
512,14,272,3,0,
512,14,276,3,0,
512,14,278,3,0,
512,14,280,4,0,
512,14,282,3,0,
512,14,283,3,0,
512,14,317,4,0,
512,14,343,3,0,
512,14,371,4,0,
512,14,374,4,0,
512,14,388,3,0,
512,14,402,1,1,4
512,14,402,3,0,
512,14,411,4,0,
512,14,412,4,0,
512,14,416,4,0,
512,14,421,4,0,
512,14,441,3,0,
512,14,447,4,0,
512,14,468,4,0,
512,14,490,4,0,
512,14,496,4,0,
512,14,512,4,0,
512,14,526,4,0,
512,15,15,4,0,
512,15,43,1,1,1
512,15,63,4,0,
512,15,76,4,0,
512,15,91,4,0,
512,15,92,4,0,
512,15,104,4,0,
512,15,122,1,1,2
512,15,148,4,0,
512,15,154,1,1,3
512,15,156,4,0,
512,15,157,4,0,
512,15,164,4,0,
512,15,168,4,0,
512,15,182,4,0,
512,15,207,4,0,
512,15,213,4,0,
512,15,214,4,0,
512,15,216,4,0,
512,15,218,4,0,
512,15,237,4,0,
512,15,241,4,0,
512,15,249,4,0,
512,15,259,4,0,
512,15,263,4,0,
512,15,267,4,0,
512,15,269,4,0,
512,15,280,4,0,
512,15,317,4,0,
512,15,371,4,0,
512,15,374,4,0,
512,15,402,1,1,4
512,15,411,4,0,
512,15,412,4,0,
512,15,416,4,0,
512,15,421,4,0,
512,15,447,4,0,
512,15,468,4,0,
512,15,490,4,0,
512,15,496,4,0,
512,15,512,4,0,
512,15,590,4,0,
512,15,612,4,0,
512,16,15,4,0,
512,16,43,1,1,1
512,16,63,4,0,
512,16,67,3,0,
512,16,76,4,0,
512,16,91,4,0,
512,16,92,4,0,
512,16,104,4,0,
512,16,122,1,1,2
512,16,148,4,0,
512,16,154,1,1,3
512,16,156,4,0,
512,16,157,4,0,
512,16,164,4,0,
512,16,168,4,0,
512,16,173,3,0,
512,16,182,4,0,
512,16,202,3,0,
512,16,207,4,0,
512,16,213,4,0,
512,16,214,4,0,
512,16,216,4,0,
512,16,218,4,0,
512,16,231,3,0,
512,16,235,3,0,
512,16,237,4,0,
512,16,241,4,0,
512,16,249,4,0,
512,16,253,3,0,
512,16,259,4,0,
512,16,263,4,0,
512,16,264,3,0,
512,16,267,4,0,
512,16,269,4,0,
512,16,270,3,0,
512,16,272,3,0,
512,16,276,3,0,
512,16,278,3,0,
512,16,280,4,0,
512,16,282,3,0,
512,16,283,3,0,
512,16,290,4,0,
512,16,317,4,0,
512,16,343,3,0,
512,16,371,4,0,
512,16,374,4,0,
512,16,380,3,0,
512,16,388,3,0,
512,16,402,1,1,4
512,16,402,3,0,
512,16,411,4,0,
512,16,412,4,0,
512,16,416,4,0,
512,16,421,4,0,
512,16,441,3,0,
512,16,447,4,0,
512,16,468,4,0,
512,16,490,4,0,
512,16,496,4,0,
512,16,512,4,0,
512,16,590,4,0,
512,16,612,4,0,
512,17,43,1,1,1
512,17,63,4,0,
512,17,76,4,0,
512,17,92,4,0,
512,17,104,4,0,
512,17,122,1,1,2
512,17,154,1,1,3
512,17,156,4,0,
512,17,157,4,0,
512,17,164,4,0,
512,17,168,4,0,
512,17,182,4,0,
512,17,207,4,0,
512,17,213,4,0,
512,17,214,4,0,
512,17,216,4,0,
512,17,218,4,0,
512,17,237,4,0,
512,17,241,4,0,
512,17,259,4,0,
512,17,263,4,0,
512,17,267,4,0,
512,17,269,4,0,
512,17,280,4,0,
512,17,317,4,0,
512,17,371,4,0,
512,17,374,4,0,
512,17,402,1,1,4
512,17,411,4,0,
512,17,412,4,0,
512,17,416,4,0,
512,17,421,4,0,
512,17,447,4,0,
512,17,490,4,0,
512,17,496,4,0,
512,17,512,4,0,
512,17,520,3,0,
512,17,526,4,0,
512,17,590,4,0,
512,18,43,1,1,1
512,18,63,4,0,
512,18,76,4,0,
512,18,92,4,0,
512,18,104,4,0,
512,18,122,1,1,2
512,18,154,1,1,3
512,18,156,4,0,
512,18,157,4,0,
512,18,164,4,0,
512,18,168,4,0,
512,18,182,4,0,
512,18,207,4,0,
512,18,213,4,0,
512,18,214,4,0,
512,18,216,4,0,
512,18,218,4,0,
512,18,237,4,0,
512,18,241,4,0,
512,18,259,4,0,
512,18,263,4,0,
512,18,267,4,0,
512,18,269,4,0,
512,18,280,4,0,
512,18,317,4,0,
512,18,371,4,0,
512,18,374,4,0,
512,18,402,1,1,4
512,18,411,4,0,
512,18,412,4,0,
512,18,416,4,0,
512,18,421,4,0,
512,18,447,4,0,
512,18,490,4,0,
512,18,496,4,0,
512,18,512,4,0,
512,18,520,3,0,
512,18,526,4,0,
512,18,590,4,0,
513,11,7,2,0,
513,11,10,1,1,
513,11,15,4,0,
513,11,43,1,4,
513,11,44,1,19,
513,11,53,4,0,
513,11,67,2,0,
513,11,76,4,0,
513,11,83,2,0,
513,11,91,4,0,
513,11,92,4,0,
513,11,104,4,0,
513,11,122,1,7,
513,11,126,1,34,
513,11,126,4,0,
513,11,133,1,25,
513,11,154,1,13,
513,11,156,4,0,
513,11,164,4,0,
513,11,168,4,0,
513,11,182,4,0,
513,11,207,4,0,
513,11,213,4,0,
513,11,214,2,0,
513,11,216,4,0,
513,11,218,4,0,
513,11,237,4,0,
513,11,241,4,0,
513,11,242,1,43,
513,11,249,4,0,
513,11,257,2,0,
513,11,259,4,0,
513,11,261,4,0,
513,11,263,4,0,
513,11,269,4,0,
513,11,272,2,0,
513,11,278,1,37,
513,11,281,1,16,
513,11,310,2,0,
513,11,315,4,0,
513,11,317,4,0,
513,11,321,2,0,
513,11,343,2,0,
513,11,363,1,40,
513,11,371,4,0,
513,11,374,1,28,
513,11,374,4,0,
513,11,417,2,0,
513,11,421,4,0,
513,11,447,4,0,
513,11,468,4,0,
513,11,481,1,22,
513,11,488,4,0,
513,11,490,4,0,
513,11,496,4,0,
513,11,510,1,10,
513,11,510,4,0,
513,11,512,1,31,
513,11,512,4,0,
513,11,526,4,0,
513,14,7,2,0,
513,14,7,3,0,
513,14,10,1,1,
513,14,15,4,0,
513,14,43,1,4,
513,14,44,1,19,
513,14,53,4,0,
513,14,67,2,0,
513,14,67,3,0,
513,14,76,4,0,
513,14,83,2,0,
513,14,91,4,0,
513,14,92,4,0,
513,14,104,4,0,
513,14,122,1,7,
513,14,126,1,34,
513,14,126,4,0,
513,14,133,1,25,
513,14,154,1,13,
513,14,156,4,0,
513,14,164,4,0,
513,14,168,4,0,
513,14,173,3,0,
513,14,182,4,0,
513,14,207,4,0,
513,14,213,4,0,
513,14,214,2,0,
513,14,214,3,0,
513,14,216,4,0,
513,14,218,4,0,
513,14,231,3,0,
513,14,237,4,0,
513,14,241,4,0,
513,14,242,1,43,
513,14,249,4,0,
513,14,253,3,0,
513,14,257,2,0,
513,14,257,3,0,
513,14,259,4,0,
513,14,261,4,0,
513,14,263,4,0,
513,14,269,4,0,
513,14,270,3,0,
513,14,272,2,0,
513,14,272,3,0,
513,14,278,1,37,
513,14,278,3,0,
513,14,281,1,16,
513,14,282,3,0,
513,14,283,3,0,
513,14,310,2,0,
513,14,315,4,0,
513,14,317,4,0,
513,14,321,2,0,
513,14,343,2,0,
513,14,343,3,0,
513,14,363,1,40,
513,14,371,4,0,
513,14,374,1,28,
513,14,374,4,0,
513,14,417,2,0,
513,14,421,4,0,
513,14,441,3,0,
513,14,447,4,0,
513,14,468,4,0,
513,14,481,1,22,
513,14,488,4,0,
513,14,490,4,0,
513,14,496,4,0,
513,14,510,1,10,
513,14,510,4,0,
513,14,512,1,31,
513,14,512,4,0,
513,14,526,4,0,
513,15,7,2,0,
513,15,10,1,1,1
513,15,15,4,0,
513,15,43,1,4,
513,15,44,1,19,
513,15,53,4,0,
513,15,67,2,0,
513,15,76,4,0,
513,15,83,2,0,
513,15,91,4,0,
513,15,92,4,0,
513,15,104,4,0,
513,15,122,1,7,
513,15,126,1,34,
513,15,126,4,0,
513,15,133,1,25,
513,15,154,1,13,
513,15,156,4,0,
513,15,164,4,0,
513,15,168,4,0,
513,15,182,4,0,
513,15,207,4,0,
513,15,213,4,0,
513,15,214,2,0,
513,15,214,4,0,
513,15,216,4,0,
513,15,218,4,0,
513,15,237,4,0,
513,15,241,4,0,
513,15,242,1,43,
513,15,249,4,0,
513,15,257,2,0,
513,15,259,4,0,
513,15,261,4,0,
513,15,263,4,0,
513,15,269,4,0,
513,15,272,2,0,
513,15,278,1,37,
513,15,281,1,16,
513,15,310,2,0,
513,15,315,4,0,
513,15,317,4,0,
513,15,321,2,0,
513,15,343,2,0,
513,15,363,1,40,
513,15,371,4,0,
513,15,374,1,28,
513,15,374,4,0,
513,15,417,2,0,
513,15,421,4,0,
513,15,447,4,0,
513,15,468,4,0,
513,15,481,1,22,
513,15,488,4,0,
513,15,490,4,0,
513,15,496,4,0,
513,15,510,1,10,
513,15,510,4,0,
513,15,512,1,31,
513,15,512,4,0,
513,15,574,2,0,
513,15,589,1,1,2
513,15,590,4,0,
513,16,7,2,0,
513,16,7,3,0,
513,16,10,1,1,1
513,16,15,4,0,
513,16,43,1,4,1
513,16,44,1,19,1
513,16,53,4,0,
513,16,67,2,0,
513,16,67,3,0,
513,16,76,4,0,
513,16,83,2,0,
513,16,91,4,0,
513,16,92,4,0,
513,16,104,4,0,
513,16,122,1,7,1
513,16,126,1,34,1
513,16,126,4,0,
513,16,133,1,25,1
513,16,154,1,13,1
513,16,156,4,0,
513,16,164,4,0,
513,16,168,4,0,
513,16,173,3,0,
513,16,182,4,0,
513,16,207,4,0,
513,16,213,4,0,
513,16,214,2,0,
513,16,214,4,0,
513,16,216,4,0,
513,16,218,4,0,
513,16,231,3,0,
513,16,237,4,0,
513,16,241,4,0,
513,16,242,1,43,1
513,16,249,4,0,
513,16,253,3,0,
513,16,257,2,0,
513,16,257,3,0,
513,16,259,4,0,
513,16,261,4,0,
513,16,263,4,0,
513,16,264,3,0,
513,16,269,4,0,
513,16,270,3,0,
513,16,272,2,0,
513,16,272,3,0,
513,16,278,1,37,1
513,16,278,3,0,
513,16,281,1,16,1
513,16,282,3,0,
513,16,283,3,0,
513,16,290,4,0,
513,16,310,2,0,
513,16,315,4,0,
513,16,317,4,0,
513,16,321,2,0,
513,16,343,2,0,
513,16,343,3,0,
513,16,363,1,40,1
513,16,371,4,0,
513,16,374,1,28,1
513,16,374,4,0,
513,16,380,3,0,
513,16,417,2,0,
513,16,421,4,0,
513,16,441,3,0,
513,16,447,4,0,
513,16,468,4,0,
513,16,481,1,22,1
513,16,488,4,0,
513,16,490,4,0,
513,16,496,4,0,
513,16,510,1,10,1
513,16,510,4,0,
513,16,512,1,31,1
513,16,512,4,0,
513,16,574,2,0,
513,16,589,1,1,2
513,16,590,4,0,
513,17,7,2,0,
513,17,10,1,1,1
513,17,43,1,4,
513,17,44,1,19,
513,17,53,4,0,
513,17,67,2,0,
513,17,76,4,0,
513,17,83,2,0,
513,17,92,4,0,
513,17,104,4,0,
513,17,122,1,7,
513,17,126,1,34,
513,17,126,4,0,
513,17,133,1,25,
513,17,154,1,13,
513,17,156,4,0,
513,17,164,4,0,
513,17,168,4,0,
513,17,182,4,0,
513,17,207,4,0,
513,17,213,4,0,
513,17,214,2,0,
513,17,214,4,0,
513,17,216,4,0,
513,17,218,4,0,
513,17,237,4,0,
513,17,241,4,0,
513,17,242,1,43,
513,17,257,2,0,
513,17,259,4,0,
513,17,261,4,0,
513,17,263,4,0,
513,17,269,4,0,
513,17,272,2,0,
513,17,278,1,37,
513,17,281,1,16,
513,17,310,2,0,
513,17,315,4,0,
513,17,317,4,0,
513,17,321,2,0,
513,17,343,2,0,
513,17,363,1,40,
513,17,371,4,0,
513,17,374,1,28,
513,17,374,4,0,
513,17,417,2,0,
513,17,421,4,0,
513,17,447,4,0,
513,17,481,1,22,
513,17,488,4,0,
513,17,490,4,0,
513,17,496,4,0,
513,17,510,1,10,
513,17,512,1,31,
513,17,512,4,0,
513,17,519,3,0,
513,17,526,4,0,
513,17,574,2,0,
513,17,589,1,1,2
513,17,590,4,0,
513,18,7,2,0,
513,18,10,1,1,1
513,18,43,1,4,
513,18,44,1,19,
513,18,53,4,0,
513,18,67,2,0,
513,18,76,4,0,
513,18,83,2,0,
513,18,92,4,0,
513,18,104,4,0,
513,18,122,1,7,
513,18,126,1,34,
513,18,126,4,0,
513,18,133,1,25,
513,18,154,1,13,
513,18,156,4,0,
513,18,164,4,0,
513,18,168,4,0,
513,18,182,4,0,
513,18,207,4,0,
513,18,213,4,0,
513,18,214,2,0,
513,18,214,4,0,
513,18,216,4,0,
513,18,218,4,0,
513,18,237,4,0,
513,18,241,4,0,
513,18,242,1,43,
513,18,257,2,0,
513,18,259,4,0,
513,18,261,4,0,
513,18,263,4,0,
513,18,269,4,0,
513,18,272,2,0,
513,18,278,1,37,
513,18,281,1,16,
513,18,310,2,0,
513,18,315,4,0,
513,18,317,4,0,
513,18,321,2,0,
513,18,343,2,0,
513,18,363,1,40,
513,18,371,4,0,
513,18,374,1,28,
513,18,374,4,0,
513,18,394,2,0,
513,18,417,2,0,
513,18,421,4,0,
513,18,447,4,0,
513,18,481,1,22,
513,18,488,4,0,
513,18,490,4,0,
513,18,496,4,0,
513,18,510,1,10,
513,18,512,1,31,
513,18,512,4,0,
513,18,519,3,0,
513,18,526,4,0,
513,18,562,2,0,
513,18,574,2,0,
513,18,589,1,1,2
513,18,590,4,0,
514,11,15,4,0,
514,11,43,1,1,1
514,11,53,4,0,
514,11,63,4,0,
514,11,76,4,0,
514,11,91,4,0,
514,11,92,4,0,
514,11,104,4,0,
514,11,122,1,1,2
514,11,126,4,0,
514,11,154,1,1,3
514,11,156,4,0,
514,11,157,4,0,
514,11,164,4,0,
514,11,168,4,0,
514,11,182,4,0,
514,11,207,4,0,
514,11,213,4,0,
514,11,216,4,0,
514,11,218,4,0,
514,11,237,4,0,
514,11,241,4,0,
514,11,249,4,0,
514,11,259,4,0,
514,11,261,4,0,
514,11,263,4,0,
514,11,269,4,0,
514,11,280,4,0,
514,11,315,4,0,
514,11,317,4,0,
514,11,371,4,0,
514,11,374,4,0,
514,11,411,4,0,
514,11,416,4,0,
514,11,421,4,0,
514,11,447,4,0,
514,11,468,4,0,
514,11,481,1,1,4
514,11,488,4,0,
514,11,490,4,0,
514,11,496,4,0,
514,11,510,4,0,
514,11,512,4,0,
514,11,526,4,0,
514,14,7,3,0,
514,14,15,4,0,
514,14,43,1,1,1
514,14,53,4,0,
514,14,63,4,0,
514,14,67,3,0,
514,14,76,4,0,
514,14,91,4,0,
514,14,92,4,0,
514,14,104,4,0,
514,14,122,1,1,2
514,14,126,4,0,
514,14,154,1,1,3
514,14,156,4,0,
514,14,157,4,0,
514,14,164,4,0,
514,14,168,4,0,
514,14,173,3,0,
514,14,182,4,0,
514,14,207,4,0,
514,14,213,4,0,
514,14,214,3,0,
514,14,216,4,0,
514,14,218,4,0,
514,14,231,3,0,
514,14,237,4,0,
514,14,241,4,0,
514,14,249,4,0,
514,14,253,3,0,
514,14,257,3,0,
514,14,259,4,0,
514,14,261,4,0,
514,14,263,4,0,
514,14,269,4,0,
514,14,270,3,0,
514,14,272,3,0,
514,14,276,3,0,
514,14,278,3,0,
514,14,280,4,0,
514,14,282,3,0,
514,14,283,3,0,
514,14,315,4,0,
514,14,317,4,0,
514,14,343,3,0,
514,14,371,4,0,
514,14,374,4,0,
514,14,411,4,0,
514,14,416,4,0,
514,14,421,4,0,
514,14,441,3,0,
514,14,447,4,0,
514,14,468,4,0,
514,14,481,1,1,4
514,14,488,4,0,
514,14,490,4,0,
514,14,496,4,0,
514,14,510,4,0,
514,14,512,4,0,
514,14,526,4,0,
514,15,15,4,0,
514,15,43,1,1,1
514,15,53,4,0,
514,15,63,4,0,
514,15,76,4,0,
514,15,91,4,0,
514,15,92,4,0,
514,15,104,4,0,
514,15,122,1,1,2
514,15,126,4,0,
514,15,154,1,1,3
514,15,156,4,0,
514,15,157,4,0,
514,15,164,4,0,
514,15,168,4,0,
514,15,182,4,0,
514,15,207,4,0,
514,15,213,4,0,
514,15,214,4,0,
514,15,216,4,0,
514,15,218,4,0,
514,15,237,4,0,
514,15,241,4,0,
514,15,249,4,0,
514,15,259,4,0,
514,15,261,4,0,
514,15,263,4,0,
514,15,269,4,0,
514,15,280,4,0,
514,15,315,4,0,
514,15,317,4,0,
514,15,371,4,0,
514,15,374,4,0,
514,15,411,4,0,
514,15,416,4,0,
514,15,421,4,0,
514,15,447,4,0,
514,15,468,4,0,
514,15,481,1,1,4
514,15,488,4,0,
514,15,490,4,0,
514,15,496,4,0,
514,15,510,4,0,
514,15,512,4,0,
514,15,590,4,0,
514,15,612,4,0,
514,16,7,3,0,
514,16,15,4,0,
514,16,43,1,1,1
514,16,53,4,0,
514,16,63,4,0,
514,16,67,3,0,
514,16,76,4,0,
514,16,91,4,0,
514,16,92,4,0,
514,16,104,4,0,
514,16,122,1,1,2
514,16,126,4,0,
514,16,154,1,1,3
514,16,156,4,0,
514,16,157,4,0,
514,16,164,4,0,
514,16,168,4,0,
514,16,173,3,0,
514,16,182,4,0,
514,16,207,4,0,
514,16,213,4,0,
514,16,214,4,0,
514,16,216,4,0,
514,16,218,4,0,
514,16,231,3,0,
514,16,237,4,0,
514,16,241,4,0,
514,16,249,4,0,
514,16,253,3,0,
514,16,257,3,0,
514,16,259,4,0,
514,16,261,4,0,
514,16,263,4,0,
514,16,264,3,0,
514,16,269,4,0,
514,16,270,3,0,
514,16,272,3,0,
514,16,276,3,0,
514,16,278,3,0,
514,16,280,4,0,
514,16,282,3,0,
514,16,283,3,0,
514,16,290,4,0,
514,16,315,4,0,
514,16,317,4,0,
514,16,343,3,0,
514,16,371,4,0,
514,16,374,4,0,
514,16,380,3,0,
514,16,411,4,0,
514,16,416,4,0,
514,16,421,4,0,
514,16,441,3,0,
514,16,447,4,0,
514,16,468,4,0,
514,16,481,1,1,4
514,16,488,4,0,
514,16,490,4,0,
514,16,496,4,0,
514,16,510,4,0,
514,16,512,4,0,
514,16,590,4,0,
514,16,612,4,0,
514,17,43,1,1,1
514,17,53,4,0,
514,17,63,4,0,
514,17,76,4,0,
514,17,92,4,0,
514,17,104,4,0,
514,17,122,1,1,2
514,17,126,4,0,
514,17,154,1,1,3
514,17,156,4,0,
514,17,157,4,0,
514,17,164,4,0,
514,17,168,4,0,
514,17,182,4,0,
514,17,207,4,0,
514,17,213,4,0,
514,17,214,4,0,
514,17,216,4,0,
514,17,218,4,0,
514,17,237,4,0,
514,17,241,4,0,
514,17,259,4,0,
514,17,261,4,0,
514,17,263,4,0,
514,17,269,4,0,
514,17,280,4,0,
514,17,315,4,0,
514,17,317,4,0,
514,17,371,4,0,
514,17,374,4,0,
514,17,411,4,0,
514,17,416,4,0,
514,17,421,4,0,
514,17,447,4,0,
514,17,481,1,1,4
514,17,488,4,0,
514,17,490,4,0,
514,17,496,4,0,
514,17,512,4,0,
514,17,519,3,0,
514,17,526,4,0,
514,17,590,4,0,
514,18,43,1,1,1
514,18,53,4,0,
514,18,63,4,0,
514,18,76,4,0,
514,18,92,4,0,
514,18,104,4,0,
514,18,122,1,1,2
514,18,126,4,0,
514,18,154,1,1,3
514,18,156,4,0,
514,18,157,4,0,
514,18,164,4,0,
514,18,168,4,0,
514,18,182,4,0,
514,18,207,4,0,
514,18,213,4,0,
514,18,214,4,0,
514,18,216,4,0,
514,18,218,4,0,
514,18,237,4,0,
514,18,241,4,0,
514,18,259,4,0,
514,18,261,4,0,
514,18,263,4,0,
514,18,269,4,0,
514,18,280,4,0,
514,18,315,4,0,
514,18,317,4,0,
514,18,371,4,0,
514,18,374,4,0,
514,18,411,4,0,
514,18,416,4,0,
514,18,421,4,0,
514,18,447,4,0,
514,18,481,1,1,4
514,18,488,4,0,
514,18,490,4,0,
514,18,496,4,0,
514,18,512,4,0,
514,18,519,3,0,
514,18,526,4,0,
514,18,590,4,0,
515,11,10,1,1,
515,11,15,4,0,
515,11,43,1,4,
515,11,44,1,19,
515,11,55,1,10,
515,11,56,2,0,
515,11,57,4,0,
515,11,58,4,0,
515,11,59,4,0,
515,11,67,2,0,
515,11,91,4,0,
515,11,92,4,0,
515,11,104,4,0,
515,11,122,1,7,
515,11,127,4,0,
515,11,154,1,13,
515,11,156,4,0,
515,11,164,4,0,
515,11,168,4,0,
515,11,182,4,0,
515,11,207,4,0,
515,11,213,4,0,
515,11,216,4,0,
515,11,218,4,0,
515,11,237,4,0,
515,11,240,4,0,
515,11,242,1,43,
515,11,249,4,0,
515,11,258,4,0,
515,11,259,4,0,
515,11,263,4,0,
515,11,269,1,25,
515,11,269,4,0,
515,11,272,2,0,
515,11,278,1,37,
515,11,291,4,0,
515,11,300,2,0,
515,11,310,2,0,
515,11,317,4,0,
515,11,321,2,0,
515,11,343,2,0,
515,11,346,1,16,
515,11,362,1,34,
515,11,363,1,40,
515,11,371,4,0,
515,11,374,1,28,
515,11,374,4,0,
515,11,392,2,0,
515,11,401,2,0,
515,11,417,2,0,
515,11,421,4,0,
515,11,447,4,0,
515,11,468,4,0,
515,11,490,4,0,
515,11,496,4,0,
515,11,503,1,22,
515,11,503,4,0,
515,11,512,1,31,
515,11,512,4,0,
515,11,526,4,0,
515,14,8,3,0,
515,14,10,1,1,
515,14,15,4,0,
515,14,43,1,4,
515,14,44,1,19,
515,14,55,1,10,
515,14,56,2,0,
515,14,57,4,0,
515,14,58,4,0,
515,14,59,4,0,
515,14,67,2,0,
515,14,67,3,0,
515,14,91,4,0,
515,14,92,4,0,
515,14,104,4,0,
515,14,122,1,7,
515,14,127,4,0,
515,14,154,1,13,
515,14,156,4,0,
515,14,164,4,0,
515,14,168,4,0,
515,14,173,3,0,
515,14,182,4,0,
515,14,196,3,0,
515,14,207,4,0,
515,14,213,4,0,
515,14,214,3,0,
515,14,216,4,0,
515,14,218,4,0,
515,14,231,3,0,
515,14,237,4,0,
515,14,240,4,0,
515,14,242,1,43,
515,14,249,4,0,
515,14,253,3,0,
515,14,258,4,0,
515,14,259,4,0,
515,14,263,4,0,
515,14,269,1,25,
515,14,269,4,0,
515,14,270,3,0,
515,14,272,2,0,
515,14,272,3,0,
515,14,278,1,37,
515,14,278,3,0,
515,14,282,3,0,
515,14,283,3,0,
515,14,291,4,0,
515,14,300,2,0,
515,14,310,2,0,
515,14,317,4,0,
515,14,321,2,0,
515,14,343,2,0,
515,14,343,3,0,
515,14,346,1,16,
515,14,362,1,34,
515,14,363,1,40,
515,14,371,4,0,
515,14,374,1,28,
515,14,374,4,0,
515,14,392,2,0,
515,14,401,2,0,
515,14,401,3,0,
515,14,417,2,0,
515,14,421,4,0,
515,14,441,3,0,
515,14,447,4,0,
515,14,468,4,0,
515,14,490,4,0,
515,14,496,4,0,
515,14,503,1,22,
515,14,503,4,0,
515,14,512,1,31,
515,14,512,4,0,
515,14,526,4,0,
515,15,10,1,1,1
515,15,15,4,0,
515,15,43,1,4,
515,15,44,1,19,
515,15,55,1,10,
515,15,56,2,0,
515,15,57,4,0,
515,15,58,4,0,
515,15,59,4,0,
515,15,67,2,0,
515,15,91,4,0,
515,15,92,4,0,
515,15,104,4,0,
515,15,122,1,7,
515,15,127,4,0,
515,15,154,1,13,
515,15,156,4,0,
515,15,164,4,0,
515,15,168,4,0,
515,15,182,4,0,
515,15,207,4,0,
515,15,213,4,0,
515,15,214,4,0,
515,15,216,4,0,
515,15,218,4,0,
515,15,237,4,0,
515,15,240,4,0,
515,15,242,1,43,
515,15,249,4,0,
515,15,258,4,0,
515,15,259,4,0,
515,15,263,4,0,
515,15,269,1,25,
515,15,269,4,0,
515,15,272,2,0,
515,15,278,1,37,
515,15,300,2,0,
515,15,310,2,0,
515,15,317,4,0,
515,15,321,2,0,
515,15,343,2,0,
515,15,346,1,16,
515,15,362,1,34,
515,15,363,1,40,
515,15,371,4,0,
515,15,374,1,28,
515,15,374,4,0,
515,15,392,2,0,
515,15,401,2,0,
515,15,417,2,0,
515,15,421,4,0,
515,15,447,4,0,
515,15,468,4,0,
515,15,490,4,0,
515,15,496,4,0,
515,15,503,1,22,
515,15,503,4,0,
515,15,512,1,31,
515,15,512,4,0,
515,15,574,2,0,
515,15,589,1,1,2
515,15,590,4,0,
515,16,8,3,0,
515,16,10,1,1,1
515,16,15,4,0,
515,16,43,1,4,1
515,16,44,1,19,1
515,16,55,1,10,1
515,16,56,2,0,
515,16,57,4,0,
515,16,58,4,0,
515,16,59,4,0,
515,16,67,2,0,
515,16,67,3,0,
515,16,91,4,0,
515,16,92,4,0,
515,16,104,4,0,
515,16,122,1,7,1
515,16,127,4,0,
515,16,154,1,13,1
515,16,156,4,0,
515,16,164,4,0,
515,16,168,4,0,
515,16,173,3,0,
515,16,182,4,0,
515,16,196,3,0,
515,16,207,4,0,
515,16,213,4,0,
515,16,214,4,0,
515,16,216,4,0,
515,16,218,4,0,
515,16,231,3,0,
515,16,237,4,0,
515,16,240,4,0,
515,16,242,1,43,1
515,16,249,4,0,
515,16,253,3,0,
515,16,258,4,0,
515,16,259,4,0,
515,16,263,4,0,
515,16,264,3,0,
515,16,269,1,25,1
515,16,269,4,0,
515,16,270,3,0,
515,16,272,2,0,
515,16,272,3,0,
515,16,278,1,37,1
515,16,278,3,0,
515,16,282,3,0,
515,16,283,3,0,
515,16,290,4,0,
515,16,291,4,0,
515,16,300,2,0,
515,16,310,2,0,
515,16,317,4,0,
515,16,321,2,0,
515,16,343,2,0,
515,16,343,3,0,
515,16,346,1,16,1
515,16,352,3,0,
515,16,362,1,34,1
515,16,363,1,40,1
515,16,371,4,0,
515,16,374,1,28,1
515,16,374,4,0,
515,16,380,3,0,
515,16,392,2,0,
515,16,401,2,0,
515,16,401,3,0,
515,16,417,2,0,
515,16,421,4,0,
515,16,441,3,0,
515,16,447,4,0,
515,16,468,4,0,
515,16,490,4,0,
515,16,496,4,0,
515,16,503,1,22,1
515,16,503,4,0,
515,16,512,1,31,1
515,16,512,4,0,
515,16,574,2,0,
515,16,589,1,1,2
515,16,590,4,0,
515,17,10,1,1,1
515,17,43,1,4,
515,17,44,1,19,
515,17,55,1,10,
515,17,56,2,0,
515,17,57,4,0,
515,17,58,4,0,
515,17,59,4,0,
515,17,67,2,0,
515,17,92,4,0,
515,17,104,4,0,
515,17,122,1,7,
515,17,127,4,0,
515,17,154,1,13,
515,17,156,4,0,
515,17,164,4,0,
515,17,168,4,0,
515,17,182,4,0,
515,17,207,4,0,
515,17,213,4,0,
515,17,214,4,0,
515,17,216,4,0,
515,17,218,4,0,
515,17,237,4,0,
515,17,240,4,0,
515,17,242,1,43,
515,17,258,4,0,
515,17,259,4,0,
515,17,263,4,0,
515,17,269,1,25,
515,17,269,4,0,
515,17,272,2,0,
515,17,278,1,37,
515,17,300,2,0,
515,17,310,2,0,
515,17,317,4,0,
515,17,321,2,0,
515,17,343,2,0,
515,17,346,1,16,
515,17,362,1,34,
515,17,363,1,40,
515,17,371,4,0,
515,17,374,1,28,
515,17,374,4,0,
515,17,392,2,0,
515,17,401,2,0,
515,17,417,2,0,
515,17,421,4,0,
515,17,447,4,0,
515,17,490,4,0,
515,17,496,4,0,
515,17,503,1,22,
515,17,503,4,0,
515,17,512,1,31,
515,17,512,4,0,
515,17,518,3,0,
515,17,526,4,0,
515,17,574,2,0,
515,17,589,1,1,2
515,17,590,4,0,
515,18,10,1,1,1
515,18,43,1,4,
515,18,44,1,19,
515,18,55,1,10,
515,18,56,2,0,
515,18,57,4,0,
515,18,58,4,0,
515,18,59,4,0,
515,18,67,2,0,
515,18,92,4,0,
515,18,104,4,0,
515,18,122,1,7,
515,18,127,4,0,
515,18,154,1,13,
515,18,156,4,0,
515,18,164,4,0,
515,18,168,4,0,
515,18,182,4,0,
515,18,207,4,0,
515,18,213,4,0,
515,18,214,4,0,
515,18,216,4,0,
515,18,218,4,0,
515,18,237,4,0,
515,18,240,4,0,
515,18,242,1,43,
515,18,258,4,0,
515,18,259,4,0,
515,18,263,4,0,
515,18,269,1,25,
515,18,269,4,0,
515,18,272,2,0,
515,18,278,1,37,
515,18,300,2,0,
515,18,310,2,0,
515,18,317,4,0,
515,18,321,2,0,
515,18,343,2,0,
515,18,346,1,16,
515,18,362,1,34,
515,18,363,1,40,
515,18,371,4,0,
515,18,374,1,28,
515,18,374,4,0,
515,18,392,2,0,
515,18,401,2,0,
515,18,417,2,0,
515,18,421,4,0,
515,18,447,4,0,
515,18,490,4,0,
515,18,496,4,0,
515,18,503,1,22,
515,18,503,4,0,
515,18,512,1,31,
515,18,512,4,0,
515,18,518,3,0,
515,18,526,4,0,
515,18,574,2,0,
515,18,589,1,1,2
515,18,590,4,0,
516,11,15,4,0,
516,11,43,1,1,1
516,11,57,4,0,
516,11,58,4,0,
516,11,59,4,0,
516,11,63,4,0,
516,11,91,4,0,
516,11,92,4,0,
516,11,104,4,0,
516,11,122,1,1,2
516,11,127,4,0,
516,11,154,1,1,3
516,11,156,4,0,
516,11,157,4,0,
516,11,164,4,0,
516,11,168,4,0,
516,11,182,4,0,
516,11,207,4,0,
516,11,213,4,0,
516,11,216,4,0,
516,11,218,4,0,
516,11,237,4,0,
516,11,240,4,0,
516,11,249,4,0,
516,11,258,4,0,
516,11,259,4,0,
516,11,263,4,0,
516,11,269,4,0,
516,11,280,4,0,
516,11,291,4,0,
516,11,317,4,0,
516,11,371,4,0,
516,11,374,4,0,
516,11,411,4,0,
516,11,416,4,0,
516,11,421,4,0,
516,11,447,4,0,
516,11,468,4,0,
516,11,490,4,0,
516,11,496,4,0,
516,11,503,1,1,4
516,11,503,4,0,
516,11,512,4,0,
516,11,526,4,0,
516,14,8,3,0,
516,14,15,4,0,
516,14,43,1,1,1
516,14,57,4,0,
516,14,58,4,0,
516,14,59,4,0,
516,14,63,4,0,
516,14,67,3,0,
516,14,91,4,0,
516,14,92,4,0,
516,14,104,4,0,
516,14,122,1,1,2
516,14,127,4,0,
516,14,154,1,1,3
516,14,156,4,0,
516,14,157,4,0,
516,14,164,4,0,
516,14,168,4,0,
516,14,173,3,0,
516,14,182,4,0,
516,14,196,3,0,
516,14,207,4,0,
516,14,213,4,0,
516,14,214,3,0,
516,14,216,4,0,
516,14,218,4,0,
516,14,231,3,0,
516,14,237,4,0,
516,14,240,4,0,
516,14,249,4,0,
516,14,253,3,0,
516,14,258,4,0,
516,14,259,4,0,
516,14,263,4,0,
516,14,269,4,0,
516,14,270,3,0,
516,14,272,3,0,
516,14,276,3,0,
516,14,278,3,0,
516,14,280,4,0,
516,14,282,3,0,
516,14,283,3,0,
516,14,291,4,0,
516,14,317,4,0,
516,14,343,3,0,
516,14,371,4,0,
516,14,374,4,0,
516,14,401,3,0,
516,14,411,4,0,
516,14,416,4,0,
516,14,421,4,0,
516,14,441,3,0,
516,14,447,4,0,
516,14,468,4,0,
516,14,490,4,0,
516,14,496,4,0,
516,14,503,1,1,4
516,14,503,4,0,
516,14,512,4,0,
516,14,526,4,0,
516,15,15,4,0,
516,15,43,1,1,1
516,15,57,4,0,
516,15,58,4,0,
516,15,59,4,0,
516,15,63,4,0,
516,15,91,4,0,
516,15,92,4,0,
516,15,104,4,0,
516,15,122,1,1,2
516,15,127,4,0,
516,15,154,1,1,3
516,15,156,4,0,
516,15,157,4,0,
516,15,164,4,0,
516,15,168,4,0,
516,15,182,4,0,
516,15,207,4,0,
516,15,213,4,0,
516,15,214,4,0,
516,15,216,4,0,
516,15,218,4,0,
516,15,237,4,0,
516,15,240,4,0,
516,15,249,4,0,
516,15,258,4,0,
516,15,259,4,0,
516,15,263,4,0,
516,15,269,4,0,
516,15,280,4,0,
516,15,317,4,0,
516,15,371,4,0,
516,15,374,4,0,
516,15,411,4,0,
516,15,416,4,0,
516,15,421,4,0,
516,15,447,4,0,
516,15,468,4,0,
516,15,490,4,0,
516,15,496,4,0,
516,15,503,1,1,4
516,15,503,4,0,
516,15,512,4,0,
516,15,590,4,0,
516,15,612,4,0,
516,16,8,3,0,
516,16,15,4,0,
516,16,43,1,1,1
516,16,57,4,0,
516,16,58,4,0,
516,16,59,4,0,
516,16,63,4,0,
516,16,67,3,0,
516,16,91,4,0,
516,16,92,4,0,
516,16,104,4,0,
516,16,122,1,1,2
516,16,127,4,0,
516,16,154,1,1,3
516,16,156,4,0,
516,16,157,4,0,
516,16,164,4,0,
516,16,168,4,0,
516,16,173,3,0,
516,16,182,4,0,
516,16,196,3,0,
516,16,207,4,0,
516,16,213,4,0,
516,16,214,4,0,
516,16,216,4,0,
516,16,218,4,0,
516,16,231,3,0,
516,16,237,4,0,
516,16,240,4,0,
516,16,249,4,0,
516,16,253,3,0,
516,16,258,4,0,
516,16,259,4,0,
516,16,263,4,0,
516,16,264,3,0,
516,16,269,4,0,
516,16,270,3,0,
516,16,272,3,0,
516,16,276,3,0,
516,16,278,3,0,
516,16,280,4,0,
516,16,282,3,0,
516,16,283,3,0,
516,16,290,4,0,
516,16,291,4,0,
516,16,317,4,0,
516,16,343,3,0,
516,16,352,3,0,
516,16,371,4,0,
516,16,374,4,0,
516,16,380,3,0,
516,16,401,3,0,
516,16,411,4,0,
516,16,416,4,0,
516,16,421,4,0,
516,16,441,3,0,
516,16,447,4,0,
516,16,468,4,0,
516,16,490,4,0,
516,16,496,4,0,
516,16,503,1,1,4
516,16,503,4,0,
516,16,512,4,0,
516,16,590,4,0,
516,16,612,4,0,
516,17,43,1,1,1
516,17,57,4,0,
516,17,58,4,0,
516,17,59,4,0,
516,17,63,4,0,
516,17,92,4,0,
516,17,104,4,0,
516,17,122,1,1,2
516,17,127,4,0,
516,17,154,1,1,3
516,17,156,4,0,
516,17,157,4,0,
516,17,164,4,0,
516,17,168,4,0,
516,17,182,4,0,
516,17,207,4,0,
516,17,213,4,0,
516,17,214,4,0,
516,17,216,4,0,
516,17,218,4,0,
516,17,237,4,0,
516,17,240,4,0,
516,17,258,4,0,
516,17,259,4,0,
516,17,263,4,0,
516,17,269,4,0,
516,17,280,4,0,
516,17,317,4,0,
516,17,371,4,0,
516,17,374,4,0,
516,17,411,4,0,
516,17,416,4,0,
516,17,421,4,0,
516,17,447,4,0,
516,17,490,4,0,
516,17,496,4,0,
516,17,503,1,1,4
516,17,503,4,0,
516,17,512,4,0,
516,17,518,3,0,
516,17,526,4,0,
516,17,590,4,0,
516,18,43,1,1,1
516,18,57,4,0,
516,18,58,4,0,
516,18,59,4,0,
516,18,63,4,0,
516,18,92,4,0,
516,18,104,4,0,
516,18,122,1,1,2
516,18,127,4,0,
516,18,154,1,1,3
516,18,156,4,0,
516,18,157,4,0,
516,18,164,4,0,
516,18,168,4,0,
516,18,182,4,0,
516,18,207,4,0,
516,18,213,4,0,
516,18,214,4,0,
516,18,216,4,0,
516,18,218,4,0,
516,18,237,4,0,
516,18,240,4,0,
516,18,258,4,0,
516,18,259,4,0,
516,18,263,4,0,
516,18,269,4,0,
516,18,280,4,0,
516,18,317,4,0,
516,18,371,4,0,
516,18,374,4,0,
516,18,411,4,0,
516,18,416,4,0,
516,18,421,4,0,
516,18,447,4,0,
516,18,490,4,0,
516,18,496,4,0,
516,18,503,1,1,4
516,18,503,4,0,
516,18,512,4,0,
516,18,518,3,0,
516,18,526,4,0,
516,18,590,4,0,
517,11,49,2,0,
517,11,60,1,11,
517,11,86,4,0,
517,11,92,4,0,
517,11,94,1,37,
517,11,94,4,0,
517,11,95,1,19,
517,11,104,4,0,
517,11,111,1,1,2
517,11,112,2,0,
517,11,113,4,0,
517,11,115,4,0,
517,11,129,2,0,
517,11,138,1,41,
517,11,138,4,0,
517,11,148,4,0,
517,11,149,1,1,1
517,11,156,4,0,
517,11,157,4,0,
517,11,164,4,0,
517,11,171,1,29,
517,11,174,2,0,
517,11,182,4,0,
517,11,207,4,0,
517,11,213,4,0,
517,11,214,2,0,
517,11,216,4,0,
517,11,218,4,0,
517,11,219,4,0,
517,11,226,2,0,
517,11,236,1,17,
517,11,237,4,0,
517,11,240,4,0,
517,11,244,4,0,
517,11,247,4,0,
517,11,248,1,31,
517,11,259,4,0,
517,11,263,4,0,
517,11,270,2,0,
517,11,277,2,0,
517,11,281,1,7,
517,11,286,1,13,
517,11,290,2,0,
517,11,317,4,0,
517,11,347,1,35,
517,11,347,4,0,
517,11,360,4,0,
517,11,381,1,5,
517,11,412,4,0,
517,11,428,1,23,
517,11,433,4,0,
517,11,451,4,0,
517,11,473,4,0,
517,11,477,1,43,
517,11,477,4,0,
517,11,485,1,25,
517,11,496,4,0,
517,11,500,1,47,
517,14,49,2,0,
517,14,60,1,11,
517,14,86,4,0,
517,14,92,4,0,
517,14,94,1,37,
517,14,94,4,0,
517,14,95,1,19,
517,14,104,4,0,
517,14,111,1,1,2
517,14,112,2,0,
517,14,113,4,0,
517,14,115,4,0,
517,14,129,2,0,
517,14,138,1,41,
517,14,138,4,0,
517,14,148,4,0,
517,14,149,1,1,1
517,14,156,4,0,
517,14,157,4,0,
517,14,164,4,0,
517,14,171,1,29,
517,14,173,3,0,
517,14,174,2,0,
517,14,182,4,0,
517,14,207,4,0,
517,14,213,4,0,
517,14,214,2,0,
517,14,214,3,0,
517,14,215,3,0,
517,14,216,4,0,
517,14,218,4,0,
517,14,219,4,0,
517,14,220,3,0,
517,14,226,2,0,
517,14,236,1,17,
517,14,237,4,0,
517,14,240,4,0,
517,14,244,4,0,
517,14,247,4,0,
517,14,248,1,31,
517,14,259,4,0,
517,14,263,4,0,
517,14,270,2,0,
517,14,270,3,0,
517,14,271,3,0,
517,14,277,2,0,
517,14,277,3,0,
517,14,281,1,7,
517,14,285,3,0,
517,14,286,1,13,
517,14,290,2,0,
517,14,317,4,0,
517,14,324,3,0,
517,14,347,1,35,
517,14,347,4,0,
517,14,356,3,0,
517,14,360,4,0,
517,14,381,1,5,
517,14,388,3,0,
517,14,412,4,0,
517,14,428,1,23,
517,14,428,3,0,
517,14,433,4,0,
517,14,451,4,0,
517,14,472,3,0,
517,14,473,4,0,
517,14,477,1,43,
517,14,477,4,0,
517,14,485,1,25,
517,14,495,3,0,
517,14,496,4,0,
517,14,500,1,47,
517,15,49,2,0,
517,15,60,1,11,
517,15,86,4,0,
517,15,92,4,0,
517,15,94,1,37,
517,15,94,4,0,
517,15,95,1,19,
517,15,104,4,0,
517,15,111,1,1,2
517,15,112,2,0,
517,15,113,4,0,
517,15,115,4,0,
517,15,129,2,0,
517,15,138,1,41,
517,15,138,4,0,
517,15,148,4,0,
517,15,149,1,1,1
517,15,156,4,0,
517,15,157,4,0,
517,15,164,4,0,
517,15,171,1,29,
517,15,174,2,0,
517,15,182,4,0,
517,15,207,4,0,
517,15,213,4,0,
517,15,214,2,0,
517,15,214,4,0,
517,15,216,4,0,
517,15,218,4,0,
517,15,219,4,0,
517,15,226,2,0,
517,15,236,1,17,
517,15,237,4,0,
517,15,240,4,0,
517,15,244,4,0,
517,15,247,4,0,
517,15,248,1,31,
517,15,259,4,0,
517,15,263,4,0,
517,15,270,2,0,
517,15,277,2,0,
517,15,281,1,7,
517,15,286,1,13,
517,15,290,2,0,
517,15,317,4,0,
517,15,347,1,35,
517,15,347,4,0,
517,15,360,4,0,
517,15,361,2,0,
517,15,381,1,5,
517,15,412,4,0,
517,15,428,1,23,
517,15,433,4,0,
517,15,451,4,0,
517,15,473,4,0,
517,15,477,1,43,
517,15,485,1,25,
517,15,496,4,0,
517,15,500,1,47,
517,15,590,4,0,
517,15,605,4,0,
517,16,49,2,0,
517,16,60,1,11,1
517,16,86,4,0,
517,16,92,4,0,
517,16,94,1,37,1
517,16,94,4,0,
517,16,95,1,19,1
517,16,104,4,0,
517,16,111,1,1,2
517,16,112,2,0,
517,16,113,4,0,
517,16,115,4,0,
517,16,129,2,0,
517,16,138,1,41,1
517,16,138,4,0,
517,16,148,4,0,
517,16,149,1,1,1
517,16,156,4,0,
517,16,157,4,0,
517,16,164,4,0,
517,16,171,1,29,1
517,16,173,3,0,
517,16,174,2,0,
517,16,182,4,0,
517,16,207,4,0,
517,16,213,4,0,
517,16,214,2,0,
517,16,214,4,0,
517,16,215,3,0,
517,16,216,4,0,
517,16,218,4,0,
517,16,219,4,0,
517,16,220,3,0,
517,16,226,2,0,
517,16,236,1,17,1
517,16,237,4,0,
517,16,240,4,0,
517,16,244,4,0,
517,16,247,4,0,
517,16,248,1,31,1
517,16,259,4,0,
517,16,263,4,0,
517,16,270,2,0,
517,16,270,3,0,
517,16,271,3,0,
517,16,277,2,0,
517,16,277,3,0,
517,16,281,1,7,1
517,16,285,3,0,
517,16,286,1,13,1
517,16,290,2,0,
517,16,290,4,0,
517,16,317,4,0,
517,16,324,3,0,
517,16,347,1,35,1
517,16,347,4,0,
517,16,351,3,0,
517,16,356,3,0,
517,16,360,4,0,
517,16,361,2,0,
517,16,381,1,5,1
517,16,388,3,0,
517,16,412,4,0,
517,16,428,1,23,1
517,16,428,3,0,
517,16,433,4,0,
517,16,451,4,0,
517,16,472,3,0,
517,16,473,4,0,
517,16,477,1,43,1
517,16,485,1,25,1
517,16,495,3,0,
517,16,496,4,0,
517,16,500,1,47,1
517,16,590,4,0,
517,16,605,4,0,
517,17,49,2,0,
517,17,60,1,11,
517,17,86,4,0,
517,17,92,4,0,
517,17,94,1,37,
517,17,94,4,0,
517,17,95,1,19,
517,17,104,4,0,
517,17,111,1,1,2
517,17,112,2,0,
517,17,113,4,0,
517,17,115,4,0,
517,17,129,2,0,
517,17,138,1,41,
517,17,138,4,0,
517,17,149,1,1,1
517,17,156,4,0,
517,17,157,4,0,
517,17,164,4,0,
517,17,171,1,29,
517,17,174,2,0,
517,17,182,4,0,
517,17,207,4,0,
517,17,213,4,0,
517,17,214,2,0,
517,17,214,4,0,
517,17,216,4,0,
517,17,218,4,0,
517,17,219,4,0,
517,17,226,2,0,
517,17,236,1,17,
517,17,237,4,0,
517,17,240,4,0,
517,17,244,4,0,
517,17,247,4,0,
517,17,248,1,31,
517,17,259,4,0,
517,17,263,4,0,
517,17,270,2,0,
517,17,277,2,0,
517,17,281,1,7,
517,17,286,1,13,
517,17,290,2,0,
517,17,317,4,0,
517,17,347,1,35,
517,17,347,4,0,
517,17,360,4,0,
517,17,361,2,0,
517,17,381,1,5,
517,17,412,4,0,
517,17,428,1,23,
517,17,433,4,0,
517,17,451,4,0,
517,17,473,4,0,
517,17,477,1,43,
517,17,485,1,25,
517,17,496,4,0,
517,17,500,1,47,
517,17,590,4,0,
517,17,605,4,0,
517,18,49,2,0,
517,18,60,1,11,
517,18,86,4,0,
517,18,92,4,0,
517,18,94,1,37,
517,18,94,4,0,
517,18,95,1,19,
517,18,104,4,0,
517,18,111,1,1,2
517,18,112,2,0,
517,18,113,4,0,
517,18,115,4,0,
517,18,129,2,0,
517,18,138,1,41,
517,18,138,4,0,
517,18,149,1,1,1
517,18,156,4,0,
517,18,157,4,0,
517,18,164,4,0,
517,18,171,1,29,
517,18,174,2,0,
517,18,182,4,0,
517,18,207,4,0,
517,18,213,4,0,
517,18,214,2,0,
517,18,214,4,0,
517,18,216,4,0,
517,18,218,4,0,
517,18,219,4,0,
517,18,226,2,0,
517,18,236,1,17,
517,18,237,4,0,
517,18,240,4,0,
517,18,244,4,0,
517,18,247,4,0,
517,18,248,1,31,
517,18,259,4,0,
517,18,263,4,0,
517,18,270,2,0,
517,18,277,2,0,
517,18,281,1,7,
517,18,286,1,13,
517,18,290,2,0,
517,18,317,4,0,
517,18,347,1,35,
517,18,347,4,0,
517,18,360,4,0,
517,18,361,2,0,
517,18,381,1,5,
517,18,412,4,0,
517,18,428,1,23,
517,18,433,4,0,
517,18,451,4,0,
517,18,473,4,0,
517,18,477,1,43,
517,18,485,1,25,
517,18,496,4,0,
517,18,500,1,47,
517,18,590,4,0,
517,18,605,4,0,
518,11,60,1,1,3
518,11,63,4,0,
518,11,86,4,0,
518,11,92,4,0,
518,11,94,4,0,
518,11,95,1,1,4
518,11,104,4,0,
518,11,111,1,1,1
518,11,113,4,0,
518,11,115,4,0,
518,11,138,4,0,
518,11,148,4,0,
518,11,156,4,0,
518,11,157,4,0,
518,11,164,4,0,
518,11,182,4,0,
518,11,207,4,0,
518,11,213,4,0,
518,11,216,4,0,
518,11,218,4,0,
518,11,219,4,0,
518,11,237,4,0,
518,11,240,4,0,
518,11,244,4,0,
518,11,247,4,0,
518,11,259,4,0,
518,11,263,4,0,
518,11,317,4,0,
518,11,347,4,0,
518,11,360,4,0,
518,11,381,1,1,2
518,11,412,4,0,
518,11,416,4,0,
518,11,433,4,0,
518,11,451,4,0,
518,11,473,4,0,
518,11,477,4,0,
518,11,496,4,0,
518,14,60,1,1,3
518,14,63,4,0,
518,14,86,4,0,
518,14,92,4,0,
518,14,94,4,0,
518,14,95,1,1,4
518,14,104,4,0,
518,14,111,1,1,1
518,14,113,4,0,
518,14,115,4,0,
518,14,138,4,0,
518,14,148,4,0,
518,14,156,4,0,
518,14,157,4,0,
518,14,164,4,0,
518,14,173,3,0,
518,14,182,4,0,
518,14,207,4,0,
518,14,213,4,0,
518,14,214,3,0,
518,14,215,3,0,
518,14,216,4,0,
518,14,218,4,0,
518,14,219,4,0,
518,14,220,3,0,
518,14,237,4,0,
518,14,240,4,0,
518,14,244,4,0,
518,14,247,4,0,
518,14,259,4,0,
518,14,263,4,0,
518,14,270,3,0,
518,14,271,3,0,
518,14,277,3,0,
518,14,285,3,0,
518,14,317,4,0,
518,14,324,3,0,
518,14,347,4,0,
518,14,356,3,0,
518,14,360,4,0,
518,14,381,1,1,2
518,14,388,3,0,
518,14,412,4,0,
518,14,416,4,0,
518,14,428,3,0,
518,14,433,4,0,
518,14,451,4,0,
518,14,472,3,0,
518,14,473,4,0,
518,14,477,4,0,
518,14,495,3,0,
518,14,496,4,0,
518,15,60,1,1,3
518,15,63,4,0,
518,15,86,4,0,
518,15,92,4,0,
518,15,94,4,0,
518,15,95,1,1,4
518,15,104,4,0,
518,15,111,1,1,1
518,15,113,4,0,
518,15,115,4,0,
518,15,138,4,0,
518,15,148,4,0,
518,15,156,4,0,
518,15,157,4,0,
518,15,164,4,0,
518,15,182,4,0,
518,15,207,4,0,
518,15,213,4,0,
518,15,214,4,0,
518,15,216,4,0,
518,15,218,4,0,
518,15,219,4,0,
518,15,237,4,0,
518,15,240,4,0,
518,15,244,4,0,
518,15,247,4,0,
518,15,259,4,0,
518,15,263,4,0,
518,15,317,4,0,
518,15,347,4,0,
518,15,360,4,0,
518,15,381,1,1,2
518,15,412,4,0,
518,15,416,4,0,
518,15,433,4,0,
518,15,451,4,0,
518,15,473,4,0,
518,15,496,4,0,
518,15,590,4,0,
518,15,605,4,0,
518,16,60,1,1,3
518,16,63,4,0,
518,16,86,4,0,
518,16,92,4,0,
518,16,94,4,0,
518,16,95,1,1,4
518,16,104,4,0,
518,16,111,1,1,1
518,16,113,4,0,
518,16,115,4,0,
518,16,138,4,0,
518,16,148,4,0,
518,16,156,4,0,
518,16,157,4,0,
518,16,164,4,0,
518,16,173,3,0,
518,16,182,4,0,
518,16,207,4,0,
518,16,213,4,0,
518,16,214,4,0,
518,16,215,3,0,
518,16,216,4,0,
518,16,218,4,0,
518,16,219,4,0,
518,16,220,3,0,
518,16,237,4,0,
518,16,240,4,0,
518,16,244,4,0,
518,16,247,4,0,
518,16,259,4,0,
518,16,263,4,0,
518,16,270,3,0,
518,16,271,3,0,
518,16,277,3,0,
518,16,285,3,0,
518,16,290,4,0,
518,16,317,4,0,
518,16,324,3,0,
518,16,347,4,0,
518,16,351,3,0,
518,16,356,3,0,
518,16,360,4,0,
518,16,381,1,1,2
518,16,388,3,0,
518,16,412,4,0,
518,16,416,4,0,
518,16,428,3,0,
518,16,433,4,0,
518,16,451,4,0,
518,16,472,3,0,
518,16,473,4,0,
518,16,495,3,0,
518,16,496,4,0,
518,16,590,4,0,
518,16,605,4,0,
518,17,60,1,1,4
518,17,63,4,0,
518,17,86,4,0,
518,17,92,4,0,
518,17,94,4,0,
518,17,95,1,1,5
518,17,104,4,0,
518,17,111,1,1,2
518,17,113,4,0,
518,17,115,4,0,
518,17,138,4,0,
518,17,156,4,0,
518,17,157,4,0,
518,17,164,4,0,
518,17,182,4,0,
518,17,207,4,0,
518,17,213,4,0,
518,17,214,4,0,
518,17,216,4,0,
518,17,218,4,0,
518,17,219,4,0,
518,17,237,4,0,
518,17,240,4,0,
518,17,244,4,0,
518,17,247,4,0,
518,17,259,4,0,
518,17,263,4,0,
518,17,317,4,0,
518,17,347,4,0,
518,17,360,4,0,
518,17,381,1,1,3
518,17,412,4,0,
518,17,416,4,0,
518,17,433,4,0,
518,17,451,4,0,
518,17,473,4,0,
518,17,496,4,0,
518,17,590,4,0,
518,17,605,4,0,
518,17,678,1,1,1
518,18,60,1,1,4
518,18,63,4,0,
518,18,86,4,0,
518,18,92,4,0,
518,18,94,4,0,
518,18,95,1,1,5
518,18,104,4,0,
518,18,111,1,1,2
518,18,113,4,0,
518,18,115,4,0,
518,18,138,4,0,
518,18,156,4,0,
518,18,157,4,0,
518,18,164,4,0,
518,18,182,4,0,
518,18,207,4,0,
518,18,213,4,0,
518,18,214,4,0,
518,18,216,4,0,
518,18,218,4,0,
518,18,219,4,0,
518,18,237,4,0,
518,18,240,4,0,
518,18,244,4,0,
518,18,247,4,0,
518,18,259,4,0,
518,18,263,4,0,
518,18,317,4,0,
518,18,347,4,0,
518,18,360,4,0,
518,18,381,1,1,3
518,18,412,4,0,
518,18,416,4,0,
518,18,433,4,0,
518,18,451,4,0,
518,18,473,4,0,
518,18,496,4,0,
518,18,590,4,0,
518,18,605,4,0,
518,18,678,1,1,1
519,11,13,1,32,
519,11,16,1,1,
519,11,19,4,0,
519,11,43,1,8,
519,11,45,1,4,
519,11,92,4,0,
519,11,95,2,0,
519,11,98,1,11,
519,11,104,4,0,
519,11,143,1,50,
519,11,156,4,0,
519,11,164,4,0,
519,11,182,4,0,
519,11,197,1,22,
519,11,207,1,39,
519,11,207,4,0,
519,11,211,2,0,
519,11,213,4,0,
519,11,216,4,0,
519,11,218,4,0,
519,11,234,2,0,
519,11,237,4,0,
519,11,240,4,0,
519,11,241,4,0,
519,11,253,2,0,
519,11,263,1,43,
519,11,263,4,0,
519,11,269,1,25,
519,11,269,4,0,
519,11,273,2,0,
519,11,297,1,36,
519,11,314,1,15,
519,11,332,4,0,
519,11,355,1,18,
519,11,365,4,0,
519,11,366,1,46,
519,11,369,4,0,
519,11,381,2,0,
519,11,403,1,29,
519,11,496,4,0,
519,11,497,4,0,
519,11,516,2,0,
519,11,526,4,0,
519,14,13,1,32,
519,14,16,1,1,
519,14,19,4,0,
519,14,43,1,8,
519,14,45,1,4,
519,14,92,4,0,
519,14,95,2,0,
519,14,98,1,11,
519,14,104,4,0,
519,14,143,1,50,
519,14,143,3,0,
519,14,156,4,0,
519,14,164,4,0,
519,14,173,3,0,
519,14,182,4,0,
519,14,197,1,22,
519,14,207,1,39,
519,14,207,4,0,
519,14,211,2,0,
519,14,213,4,0,
519,14,214,3,0,
519,14,216,4,0,
519,14,218,4,0,
519,14,234,2,0,
519,14,237,4,0,
519,14,240,4,0,
519,14,241,4,0,
519,14,253,2,0,
519,14,253,3,0,
519,14,257,3,0,
519,14,263,1,43,
519,14,263,4,0,
519,14,269,1,25,
519,14,269,4,0,
519,14,273,2,0,
519,14,297,1,36,
519,14,314,1,15,
519,14,332,4,0,
519,14,355,1,18,
519,14,355,3,0,
519,14,365,4,0,
519,14,366,1,46,
519,14,366,3,0,
519,14,369,4,0,
519,14,381,2,0,
519,14,403,1,29,
519,14,496,4,0,
519,14,497,4,0,
519,14,516,2,0,
519,14,526,4,0,
519,15,13,1,32,
519,15,16,1,1,
519,15,19,4,0,
519,15,43,1,8,
519,15,45,1,4,
519,15,92,4,0,
519,15,95,2,0,
519,15,98,1,11,
519,15,104,4,0,
519,15,143,1,50,
519,15,156,4,0,
519,15,164,4,0,
519,15,182,4,0,
519,15,197,1,22,
519,15,207,1,39,
519,15,207,4,0,
519,15,211,2,0,
519,15,211,4,0,
519,15,213,4,0,
519,15,214,4,0,
519,15,216,4,0,
519,15,218,4,0,
519,15,234,2,0,
519,15,237,4,0,
519,15,240,4,0,
519,15,241,4,0,
519,15,253,2,0,
519,15,263,1,43,
519,15,263,4,0,
519,15,269,1,25,
519,15,269,4,0,
519,15,273,2,0,
519,15,297,1,36,
519,15,314,1,15,
519,15,332,4,0,
519,15,355,1,18,
519,15,355,4,0,
519,15,366,1,46,
519,15,369,4,0,
519,15,381,2,0,
519,15,400,2,0,
519,15,403,1,29,
519,15,496,4,0,
519,15,497,4,0,
519,15,516,2,0,
519,15,590,4,0,
519,16,13,1,32,1
519,16,16,1,1,1
519,16,19,4,0,
519,16,43,1,8,1
519,16,45,1,4,1
519,16,92,4,0,
519,16,95,2,0,
519,16,98,1,11,1
519,16,104,4,0,
519,16,143,1,50,1
519,16,143,3,0,
519,16,156,4,0,
519,16,164,4,0,
519,16,173,3,0,
519,16,182,4,0,
519,16,197,1,22,1
519,16,207,1,39,1
519,16,207,4,0,
519,16,211,2,0,
519,16,211,4,0,
519,16,213,4,0,
519,16,214,4,0,
519,16,216,4,0,
519,16,218,4,0,
519,16,234,2,0,
519,16,237,4,0,
519,16,240,4,0,
519,16,241,4,0,
519,16,253,2,0,
519,16,253,3,0,
519,16,257,3,0,
519,16,263,1,43,1
519,16,263,4,0,
519,16,269,1,25,1
519,16,269,4,0,
519,16,273,2,0,
519,16,290,4,0,
519,16,297,1,36,1
519,16,314,1,15,1
519,16,332,4,0,
519,16,355,1,18,1
519,16,355,4,0,
519,16,366,1,46,1
519,16,366,3,0,
519,16,369,4,0,
519,16,381,2,0,
519,16,400,2,0,
519,16,403,1,29,1
519,16,496,4,0,
519,16,497,4,0,
519,16,516,2,0,
519,16,590,4,0,
519,17,13,1,32,
519,17,16,1,1,
519,17,19,4,0,
519,17,43,1,8,
519,17,45,1,4,
519,17,92,4,0,
519,17,95,2,0,
519,17,98,1,11,
519,17,104,4,0,
519,17,143,1,50,
519,17,156,4,0,
519,17,164,4,0,
519,17,182,4,0,
519,17,197,1,22,
519,17,207,1,39,
519,17,207,4,0,
519,17,211,2,0,
519,17,211,4,0,
519,17,213,4,0,
519,17,214,4,0,
519,17,216,4,0,
519,17,218,4,0,
519,17,234,2,0,
519,17,237,4,0,
519,17,240,4,0,
519,17,241,4,0,
519,17,253,2,0,
519,17,263,1,43,
519,17,263,4,0,
519,17,269,1,25,
519,17,269,4,0,
519,17,273,2,0,
519,17,297,1,36,
519,17,314,1,15,
519,17,332,4,0,
519,17,355,1,18,
519,17,355,4,0,
519,17,366,1,46,
519,17,369,4,0,
519,17,381,2,0,
519,17,400,2,0,
519,17,403,1,29,
519,17,496,4,0,
519,17,497,4,0,
519,17,516,2,0,
519,17,526,4,0,
519,17,590,4,0,
519,18,13,1,32,
519,18,16,1,1,
519,18,19,4,0,
519,18,43,1,8,
519,18,45,1,4,
519,18,92,4,0,
519,18,95,2,0,
519,18,98,1,11,
519,18,104,4,0,
519,18,143,1,50,
519,18,156,4,0,
519,18,164,4,0,
519,18,182,4,0,
519,18,197,1,22,
519,18,207,1,39,
519,18,207,4,0,
519,18,211,2,0,
519,18,211,4,0,
519,18,213,4,0,
519,18,214,4,0,
519,18,216,4,0,
519,18,218,4,0,
519,18,234,2,0,
519,18,237,4,0,
519,18,240,4,0,
519,18,241,4,0,
519,18,253,2,0,
519,18,263,1,43,
519,18,263,4,0,
519,18,269,1,25,
519,18,269,4,0,
519,18,273,2,0,
519,18,297,1,36,
519,18,314,1,15,
519,18,332,4,0,
519,18,355,1,18,
519,18,355,4,0,
519,18,366,1,46,
519,18,369,4,0,
519,18,381,2,0,
519,18,400,2,0,
519,18,403,1,29,
519,18,496,4,0,
519,18,497,4,0,
519,18,516,2,0,
519,18,526,4,0,
519,18,590,4,0,
520,11,13,1,36,
520,11,16,1,1,1
520,11,19,4,0,
520,11,43,1,1,3
520,11,43,1,8,
520,11,45,1,1,2
520,11,45,1,4,
520,11,92,4,0,
520,11,98,1,1,4
520,11,98,1,11,
520,11,104,4,0,
520,11,143,1,59,
520,11,156,4,0,
520,11,164,4,0,
520,11,182,4,0,
520,11,197,1,23,
520,11,207,1,45,
520,11,207,4,0,
520,11,213,4,0,
520,11,216,4,0,
520,11,218,4,0,
520,11,237,4,0,
520,11,240,4,0,
520,11,241,4,0,
520,11,263,1,50,
520,11,263,4,0,
520,11,269,1,27,
520,11,269,4,0,
520,11,297,1,41,
520,11,314,1,15,
520,11,332,4,0,
520,11,355,1,18,
520,11,365,4,0,
520,11,366,1,54,
520,11,369,4,0,
520,11,403,1,32,
520,11,496,4,0,
520,11,497,4,0,
520,11,526,4,0,
520,14,13,1,36,
520,14,16,1,1,1
520,14,19,4,0,
520,14,43,1,1,3
520,14,43,1,8,
520,14,45,1,1,2
520,14,45,1,4,
520,14,92,4,0,
520,14,98,1,1,4
520,14,98,1,11,
520,14,104,4,0,
520,14,143,1,59,
520,14,143,3,0,
520,14,156,4,0,
520,14,164,4,0,
520,14,173,3,0,
520,14,182,4,0,
520,14,197,1,23,
520,14,207,1,45,
520,14,207,4,0,
520,14,213,4,0,
520,14,214,3,0,
520,14,216,4,0,
520,14,218,4,0,
520,14,237,4,0,
520,14,240,4,0,
520,14,241,4,0,
520,14,253,3,0,
520,14,257,3,0,
520,14,263,1,50,
520,14,263,4,0,
520,14,269,1,27,
520,14,269,4,0,
520,14,297,1,41,
520,14,314,1,15,
520,14,332,4,0,
520,14,355,1,18,
520,14,355,3,0,
520,14,365,4,0,
520,14,366,1,54,
520,14,366,3,0,
520,14,369,4,0,
520,14,403,1,32,
520,14,496,4,0,
520,14,497,4,0,
520,14,526,4,0,
520,15,13,1,36,
520,15,16,1,1,1
520,15,19,4,0,
520,15,43,1,1,3
520,15,43,1,8,
520,15,45,1,1,2
520,15,45,1,4,
520,15,92,4,0,
520,15,98,1,1,4
520,15,98,1,11,
520,15,104,4,0,
520,15,143,1,59,
520,15,156,4,0,
520,15,164,4,0,
520,15,182,4,0,
520,15,197,1,23,
520,15,207,1,45,
520,15,207,4,0,
520,15,211,4,0,
520,15,213,4,0,
520,15,214,4,0,
520,15,216,4,0,
520,15,218,4,0,
520,15,237,4,0,
520,15,240,4,0,
520,15,241,4,0,
520,15,263,1,50,
520,15,263,4,0,
520,15,269,1,27,
520,15,269,4,0,
520,15,297,1,41,
520,15,314,1,15,
520,15,332,4,0,
520,15,355,1,18,
520,15,355,4,0,
520,15,366,1,54,
520,15,369,4,0,
520,15,403,1,32,
520,15,496,4,0,
520,15,497,4,0,
520,15,590,4,0,
520,16,13,1,36,1
520,16,16,1,1,1
520,16,19,4,0,
520,16,43,1,1,3
520,16,43,1,8,1
520,16,45,1,1,2
520,16,45,1,4,1
520,16,92,4,0,
520,16,98,1,1,4
520,16,98,1,11,1
520,16,104,4,0,
520,16,143,1,59,1
520,16,143,3,0,
520,16,156,4,0,
520,16,164,4,0,
520,16,173,3,0,
520,16,182,4,0,
520,16,197,1,23,1
520,16,207,1,45,1
520,16,207,4,0,
520,16,211,4,0,
520,16,213,4,0,
520,16,214,4,0,
520,16,216,4,0,
520,16,218,4,0,
520,16,237,4,0,
520,16,240,4,0,
520,16,241,4,0,
520,16,253,3,0,
520,16,257,3,0,
520,16,263,1,50,1
520,16,263,4,0,
520,16,269,1,27,1
520,16,269,4,0,
520,16,290,4,0,
520,16,297,1,41,1
520,16,314,1,15,1
520,16,332,4,0,
520,16,355,1,18,1
520,16,355,4,0,
520,16,366,1,54,1
520,16,366,3,0,
520,16,369,4,0,
520,16,403,1,32,1
520,16,496,4,0,
520,16,497,4,0,
520,16,590,4,0,
520,17,13,1,36,
520,17,16,1,1,1
520,17,19,4,0,
520,17,43,1,1,3
520,17,43,1,8,
520,17,45,1,1,2
520,17,45,1,4,
520,17,92,4,0,
520,17,98,1,1,4
520,17,98,1,11,
520,17,104,4,0,
520,17,143,1,59,
520,17,156,4,0,
520,17,164,4,0,
520,17,182,4,0,
520,17,197,1,23,
520,17,207,1,45,
520,17,207,4,0,
520,17,211,4,0,
520,17,213,4,0,
520,17,214,4,0,
520,17,216,4,0,
520,17,218,4,0,
520,17,237,4,0,
520,17,240,4,0,
520,17,241,4,0,
520,17,263,1,50,
520,17,263,4,0,
520,17,269,1,27,
520,17,269,4,0,
520,17,297,1,41,
520,17,314,1,15,
520,17,332,4,0,
520,17,355,1,18,
520,17,355,4,0,
520,17,366,1,54,
520,17,369,4,0,
520,17,403,1,32,
520,17,496,4,0,
520,17,497,4,0,
520,17,526,4,0,
520,17,590,4,0,
520,18,13,1,36,
520,18,16,1,1,1
520,18,19,4,0,
520,18,43,1,1,3
520,18,43,1,8,
520,18,45,1,1,2
520,18,45,1,4,
520,18,92,4,0,
520,18,98,1,1,4
520,18,98,1,11,
520,18,104,4,0,
520,18,143,1,59,
520,18,156,4,0,
520,18,164,4,0,
520,18,182,4,0,
520,18,197,1,23,
520,18,207,1,45,
520,18,207,4,0,
520,18,211,4,0,
520,18,213,4,0,
520,18,214,4,0,
520,18,216,4,0,
520,18,218,4,0,
520,18,237,4,0,
520,18,240,4,0,
520,18,241,4,0,
520,18,263,1,50,
520,18,263,4,0,
520,18,269,1,27,
520,18,269,4,0,
520,18,297,1,41,
520,18,314,1,15,
520,18,332,4,0,
520,18,355,1,18,
520,18,355,4,0,
520,18,366,1,54,
520,18,369,4,0,
520,18,403,1,32,
520,18,496,4,0,
520,18,497,4,0,
520,18,526,4,0,
520,18,590,4,0,
521,11,13,1,38,
521,11,16,1,1,1
521,11,19,4,0,
521,11,43,1,1,3
521,11,43,1,8,
521,11,45,1,1,2
521,11,45,1,4,
521,11,63,4,0,
521,11,92,4,0,
521,11,98,1,1,4
521,11,98,1,11,
521,11,104,4,0,
521,11,143,1,66,
521,11,156,4,0,
521,11,164,4,0,
521,11,182,4,0,
521,11,197,1,23,
521,11,207,1,49,
521,11,207,4,0,
521,11,213,4,0,
521,11,216,4,0,
521,11,218,4,0,
521,11,237,4,0,
521,11,240,4,0,
521,11,241,4,0,
521,11,244,4,0,
521,11,263,1,55,
521,11,263,4,0,
521,11,269,1,27,
521,11,269,4,0,
521,11,297,1,44,
521,11,314,1,15,
521,11,332,4,0,
521,11,355,1,18,
521,11,365,4,0,
521,11,366,1,60,
521,11,369,4,0,
521,11,403,1,33,
521,11,416,4,0,
521,11,496,4,0,
521,11,497,4,0,
521,11,526,4,0,
521,14,13,1,38,
521,14,16,1,1,1
521,14,19,4,0,
521,14,43,1,1,3
521,14,43,1,8,
521,14,45,1,1,2
521,14,45,1,4,
521,14,63,4,0,
521,14,92,4,0,
521,14,98,1,1,4
521,14,98,1,11,
521,14,104,4,0,
521,14,143,1,66,
521,14,143,3,0,
521,14,156,4,0,
521,14,164,4,0,
521,14,173,3,0,
521,14,182,4,0,
521,14,197,1,23,
521,14,207,1,49,
521,14,207,4,0,
521,14,213,4,0,
521,14,214,3,0,
521,14,216,4,0,
521,14,218,4,0,
521,14,237,4,0,
521,14,240,4,0,
521,14,241,4,0,
521,14,244,4,0,
521,14,253,3,0,
521,14,257,3,0,
521,14,263,1,55,
521,14,263,4,0,
521,14,269,1,27,
521,14,269,4,0,
521,14,297,1,44,
521,14,314,1,15,
521,14,332,4,0,
521,14,355,1,18,
521,14,355,3,0,
521,14,365,4,0,
521,14,366,1,60,
521,14,366,3,0,
521,14,369,4,0,
521,14,403,1,33,
521,14,416,4,0,
521,14,496,4,0,
521,14,497,4,0,
521,14,526,4,0,
521,15,13,1,38,
521,15,16,1,1,1
521,15,19,4,0,
521,15,43,1,1,3
521,15,43,1,8,
521,15,45,1,1,2
521,15,45,1,4,
521,15,63,4,0,
521,15,92,4,0,
521,15,98,1,1,4
521,15,98,1,11,
521,15,104,4,0,
521,15,143,1,66,
521,15,156,4,0,
521,15,164,4,0,
521,15,182,4,0,
521,15,197,1,23,
521,15,207,1,49,
521,15,207,4,0,
521,15,211,4,0,
521,15,213,4,0,
521,15,214,4,0,
521,15,216,4,0,
521,15,218,4,0,
521,15,237,4,0,
521,15,240,4,0,
521,15,241,4,0,
521,15,244,4,0,
521,15,263,1,55,
521,15,263,4,0,
521,15,269,1,27,
521,15,269,4,0,
521,15,297,1,44,
521,15,314,1,15,
521,15,332,4,0,
521,15,355,1,18,
521,15,355,4,0,
521,15,366,1,60,
521,15,369,4,0,
521,15,403,1,33,
521,15,416,4,0,
521,15,496,4,0,
521,15,497,4,0,
521,15,590,4,0,
521,16,13,1,38,1
521,16,16,1,1,1
521,16,19,4,0,
521,16,43,1,1,3
521,16,43,1,8,1
521,16,45,1,1,2
521,16,45,1,4,1
521,16,63,4,0,
521,16,92,4,0,
521,16,98,1,1,4
521,16,98,1,11,1
521,16,104,4,0,
521,16,143,1,66,1
521,16,143,3,0,
521,16,156,4,0,
521,16,164,4,0,
521,16,173,3,0,
521,16,182,4,0,
521,16,197,1,23,1
521,16,207,1,49,1
521,16,207,4,0,
521,16,211,4,0,
521,16,213,4,0,
521,16,214,4,0,
521,16,216,4,0,
521,16,218,4,0,
521,16,237,4,0,
521,16,240,4,0,
521,16,241,4,0,
521,16,244,4,0,
521,16,253,3,0,
521,16,257,3,0,
521,16,263,1,55,1
521,16,263,4,0,
521,16,269,1,27,1
521,16,269,4,0,
521,16,290,4,0,
521,16,297,1,44,1
521,16,314,1,15,1
521,16,332,4,0,
521,16,355,1,18,1
521,16,355,4,0,
521,16,366,1,60,1
521,16,366,3,0,
521,16,369,4,0,
521,16,403,1,33,1
521,16,416,4,0,
521,16,496,4,0,
521,16,497,4,0,
521,16,590,4,0,
521,17,13,1,38,
521,17,16,1,1,1
521,17,19,4,0,
521,17,43,1,1,3
521,17,43,1,8,
521,17,45,1,1,2
521,17,45,1,4,
521,17,63,4,0,
521,17,92,4,0,
521,17,98,1,1,4
521,17,98,1,11,
521,17,104,4,0,
521,17,143,1,66,
521,17,156,4,0,
521,17,164,4,0,
521,17,182,4,0,
521,17,197,1,23,
521,17,207,1,49,
521,17,207,4,0,
521,17,211,4,0,
521,17,213,4,0,
521,17,214,4,0,
521,17,216,4,0,
521,17,218,4,0,
521,17,237,4,0,
521,17,240,4,0,
521,17,241,4,0,
521,17,244,4,0,
521,17,263,1,55,
521,17,263,4,0,
521,17,269,1,27,
521,17,269,4,0,
521,17,297,1,44,
521,17,314,1,15,
521,17,332,4,0,
521,17,355,1,18,
521,17,355,4,0,
521,17,366,1,60,
521,17,369,4,0,
521,17,403,1,33,
521,17,416,4,0,
521,17,496,4,0,
521,17,497,4,0,
521,17,526,4,0,
521,17,590,4,0,
521,18,13,1,38,
521,18,16,1,1,1
521,18,19,4,0,
521,18,43,1,1,3
521,18,43,1,8,
521,18,45,1,1,2
521,18,45,1,4,
521,18,63,4,0,
521,18,92,4,0,
521,18,98,1,1,4
521,18,98,1,11,
521,18,104,4,0,
521,18,143,1,66,
521,18,156,4,0,
521,18,164,4,0,
521,18,182,4,0,
521,18,197,1,23,
521,18,207,1,49,
521,18,207,4,0,
521,18,211,4,0,
521,18,213,4,0,
521,18,214,4,0,
521,18,216,4,0,
521,18,218,4,0,
521,18,237,4,0,
521,18,240,4,0,
521,18,241,4,0,
521,18,244,4,0,
521,18,263,1,55,
521,18,263,4,0,
521,18,269,1,27,
521,18,269,4,0,
521,18,297,1,44,
521,18,314,1,15,
521,18,332,4,0,
521,18,355,1,18,
521,18,355,4,0,
521,18,366,1,60,
521,18,369,4,0,
521,18,403,1,33,
521,18,416,4,0,
521,18,496,4,0,
521,18,497,4,0,
521,18,526,4,0,
521,18,590,4,0,
522,11,23,1,29,
522,11,24,2,0,
522,11,28,2,0,
522,11,36,2,0,
522,11,37,1,43,
522,11,38,2,0,
522,11,39,1,4,
522,11,85,4,0,
522,11,86,1,15,
522,11,86,4,0,
522,11,87,4,0,
522,11,92,4,0,
522,11,97,1,36,
522,11,98,1,1,
522,11,99,2,0,
522,11,103,2,0,
522,11,104,4,0,
522,11,113,4,0,
522,11,148,4,0,
522,11,156,4,0,
522,11,164,4,0,
522,11,182,4,0,
522,11,203,2,0,
522,11,207,4,0,
522,11,209,1,25,
522,11,213,4,0,
522,11,216,4,0,
522,11,218,4,0,
522,11,228,1,22,
522,11,237,4,0,
522,11,240,4,0,
522,11,263,4,0,
522,11,268,1,8,
522,11,351,1,11,
522,11,351,2,0,
522,11,382,2,0,
522,11,435,1,32,
522,11,451,4,0,
522,11,488,1,18,
522,11,488,4,0,
522,11,496,4,0,
522,11,521,4,0,
522,11,528,1,39,
522,11,528,4,0,
522,14,23,1,29,
522,14,24,2,0,
522,14,28,2,0,
522,14,36,2,0,
522,14,37,1,43,
522,14,38,2,0,
522,14,39,1,4,
522,14,85,4,0,
522,14,86,1,15,
522,14,86,4,0,
522,14,87,4,0,
522,14,92,4,0,
522,14,97,1,36,
522,14,98,1,1,
522,14,99,2,0,
522,14,103,2,0,
522,14,104,4,0,
522,14,113,4,0,
522,14,148,4,0,
522,14,156,4,0,
522,14,164,4,0,
522,14,173,3,0,
522,14,182,4,0,
522,14,203,2,0,
522,14,207,4,0,
522,14,209,1,25,
522,14,213,4,0,
522,14,214,3,0,
522,14,216,4,0,
522,14,218,4,0,
522,14,228,1,22,
522,14,237,4,0,
522,14,240,4,0,
522,14,263,4,0,
522,14,268,1,8,
522,14,324,3,0,
522,14,340,3,0,
522,14,351,1,11,
522,14,351,2,0,
522,14,382,2,0,
522,14,393,3,0,
522,14,435,1,32,
522,14,451,4,0,
522,14,488,1,18,
522,14,488,4,0,
522,14,496,4,0,
522,14,521,4,0,
522,14,528,1,39,
522,14,528,4,0,
522,15,23,1,29,
522,15,24,2,0,
522,15,28,2,0,
522,15,36,2,0,
522,15,37,1,43,
522,15,38,2,0,
522,15,39,1,4,
522,15,85,4,0,
522,15,86,1,15,
522,15,86,4,0,
522,15,87,4,0,
522,15,92,4,0,
522,15,97,1,36,
522,15,98,1,1,
522,15,99,2,0,
522,15,103,2,0,
522,15,104,4,0,
522,15,113,4,0,
522,15,148,4,0,
522,15,156,4,0,
522,15,164,4,0,
522,15,182,4,0,
522,15,203,2,0,
522,15,207,4,0,
522,15,209,1,25,
522,15,213,4,0,
522,15,214,4,0,
522,15,216,4,0,
522,15,218,4,0,
522,15,228,1,22,
522,15,237,4,0,
522,15,240,4,0,
522,15,263,4,0,
522,15,268,1,8,
522,15,289,2,0,
522,15,351,1,11,
522,15,351,2,0,
522,15,382,2,0,
522,15,435,1,32,
522,15,451,4,0,
522,15,488,1,18,
522,15,488,4,0,
522,15,496,4,0,
522,15,521,4,0,
522,15,528,1,39,
522,15,528,4,0,
522,15,590,4,0,
522,16,23,1,29,1
522,16,24,2,0,
522,16,28,2,0,
522,16,36,2,0,
522,16,37,1,43,1
522,16,38,2,0,
522,16,39,1,4,1
522,16,85,4,0,
522,16,86,1,15,1
522,16,86,4,0,
522,16,87,4,0,
522,16,92,4,0,
522,16,97,1,36,1
522,16,98,1,1,1
522,16,99,2,0,
522,16,103,2,0,
522,16,104,4,0,
522,16,113,4,0,
522,16,148,4,0,
522,16,156,4,0,
522,16,164,4,0,
522,16,173,3,0,
522,16,182,4,0,
522,16,203,2,0,
522,16,207,4,0,
522,16,209,1,25,1
522,16,213,4,0,
522,16,214,4,0,
522,16,216,4,0,
522,16,218,4,0,
522,16,228,1,22,1
522,16,237,4,0,
522,16,240,4,0,
522,16,263,4,0,
522,16,268,1,8,1
522,16,289,2,0,
522,16,289,3,0,
522,16,290,4,0,
522,16,324,3,0,
522,16,340,3,0,
522,16,351,1,11,1
522,16,351,2,0,
522,16,351,3,0,
522,16,382,2,0,
522,16,393,3,0,
522,16,435,1,32,1
522,16,451,4,0,
522,16,488,1,18,1
522,16,488,4,0,
522,16,496,4,0,
522,16,521,4,0,
522,16,528,1,39,1
522,16,528,4,0,
522,16,590,4,0,
522,17,23,1,29,
522,17,24,2,0,
522,17,28,2,0,
522,17,36,2,0,
522,17,37,1,43,
522,17,38,2,0,
522,17,39,1,4,
522,17,85,4,0,
522,17,86,1,15,
522,17,86,4,0,
522,17,87,4,0,
522,17,92,4,0,
522,17,97,1,36,
522,17,98,1,1,
522,17,99,2,0,
522,17,103,2,0,
522,17,104,4,0,
522,17,113,4,0,
522,17,156,4,0,
522,17,164,4,0,
522,17,182,4,0,
522,17,203,2,0,
522,17,207,4,0,
522,17,209,1,25,
522,17,213,4,0,
522,17,214,4,0,
522,17,216,4,0,
522,17,218,4,0,
522,17,228,1,22,
522,17,237,4,0,
522,17,240,4,0,
522,17,263,4,0,
522,17,268,1,8,
522,17,289,2,0,
522,17,351,1,11,
522,17,351,2,0,
522,17,382,2,0,
522,17,435,1,32,
522,17,451,4,0,
522,17,488,1,18,
522,17,488,4,0,
522,17,496,4,0,
522,17,521,4,0,
522,17,528,1,39,
522,17,528,4,0,
522,17,590,4,0,
522,18,23,1,29,
522,18,24,2,0,
522,18,28,2,0,
522,18,36,2,0,
522,18,37,1,43,
522,18,38,2,0,
522,18,39,1,4,
522,18,85,4,0,
522,18,86,1,15,
522,18,86,4,0,
522,18,87,4,0,
522,18,92,4,0,
522,18,97,1,36,
522,18,98,1,1,
522,18,99,2,0,
522,18,103,2,0,
522,18,104,4,0,
522,18,113,4,0,
522,18,156,4,0,
522,18,164,4,0,
522,18,182,4,0,
522,18,203,2,0,
522,18,207,4,0,
522,18,209,1,25,
522,18,213,4,0,
522,18,214,4,0,
522,18,216,4,0,
522,18,218,4,0,
522,18,228,1,22,
522,18,237,4,0,
522,18,240,4,0,
522,18,263,4,0,
522,18,268,1,8,
522,18,289,2,0,
522,18,351,1,11,
522,18,351,2,0,
522,18,364,2,0,
522,18,382,2,0,
522,18,435,1,32,
522,18,451,4,0,
522,18,488,1,18,
522,18,488,4,0,
522,18,496,4,0,
522,18,521,4,0,
522,18,528,1,39,
522,18,528,4,0,
522,18,590,4,0,
523,11,23,1,31,
523,11,37,1,53,
523,11,39,1,1,2
523,11,39,1,4,
523,11,63,4,0,
523,11,85,4,0,
523,11,86,1,1,4
523,11,86,1,15,
523,11,86,4,0,
523,11,87,4,0,
523,11,92,4,0,
523,11,97,1,42,
523,11,98,1,1,1
523,11,104,4,0,
523,11,113,4,0,
523,11,148,4,0,
523,11,156,4,0,
523,11,164,4,0,
523,11,182,4,0,
523,11,207,4,0,
523,11,209,1,25,
523,11,213,4,0,
523,11,216,4,0,
523,11,218,4,0,
523,11,228,1,22,
523,11,237,4,0,
523,11,240,4,0,
523,11,249,4,0,
523,11,263,4,0,
523,11,268,1,1,3
523,11,268,1,8,
523,11,315,4,0,
523,11,351,1,11,
523,11,416,4,0,
523,11,435,1,36,
523,11,451,4,0,
523,11,488,1,18,
523,11,488,4,0,
523,11,496,4,0,
523,11,521,4,0,
523,11,528,1,47,
523,11,528,4,0,
523,14,23,1,31,
523,14,37,1,53,
523,14,39,1,1,2
523,14,39,1,4,
523,14,63,4,0,
523,14,85,4,0,
523,14,86,1,1,4
523,14,86,1,15,
523,14,86,4,0,
523,14,87,4,0,
523,14,92,4,0,
523,14,97,1,42,
523,14,98,1,1,1
523,14,104,4,0,
523,14,113,4,0,
523,14,148,4,0,
523,14,156,4,0,
523,14,164,4,0,
523,14,173,3,0,
523,14,182,4,0,
523,14,207,4,0,
523,14,209,1,25,
523,14,213,4,0,
523,14,214,3,0,
523,14,216,4,0,
523,14,218,4,0,
523,14,228,1,22,
523,14,237,4,0,
523,14,240,4,0,
523,14,249,4,0,
523,14,263,4,0,
523,14,268,1,1,3
523,14,268,1,8,
523,14,315,4,0,
523,14,324,3,0,
523,14,340,3,0,
523,14,351,1,11,
523,14,393,3,0,
523,14,416,4,0,
523,14,435,1,36,
523,14,451,4,0,
523,14,488,1,18,
523,14,488,4,0,
523,14,496,4,0,
523,14,521,4,0,
523,14,528,1,47,
523,14,528,4,0,
523,15,23,1,31,
523,15,37,1,53,
523,15,39,1,1,3
523,15,39,1,4,
523,15,63,4,0,
523,15,85,4,0,
523,15,86,1,1,5
523,15,86,1,15,
523,15,86,4,0,
523,15,87,4,0,
523,15,92,4,0,
523,15,97,1,42,
523,15,98,1,1,2
523,15,104,4,0,
523,15,113,4,0,
523,15,148,4,0,
523,15,156,4,0,
523,15,164,4,0,
523,15,182,4,0,
523,15,207,4,0,
523,15,209,1,25,
523,15,213,4,0,
523,15,214,4,0,
523,15,216,4,0,
523,15,218,4,0,
523,15,228,1,22,
523,15,237,4,0,
523,15,240,4,0,
523,15,249,4,0,
523,15,263,4,0,
523,15,268,1,1,4
523,15,268,1,8,
523,15,315,4,0,
523,15,351,1,11,
523,15,416,4,0,
523,15,435,1,36,
523,15,451,4,0,
523,15,488,1,18,
523,15,488,4,0,
523,15,496,4,0,
523,15,521,4,0,
523,15,528,1,47,
523,15,528,4,0,
523,15,569,1,1,1
523,15,569,1,58,
523,15,590,4,0,
523,16,23,1,31,1
523,16,37,1,53,1
523,16,39,1,1,3
523,16,39,1,4,1
523,16,63,4,0,
523,16,85,4,0,
523,16,86,1,1,5
523,16,86,1,15,1
523,16,86,4,0,
523,16,87,4,0,
523,16,92,4,0,
523,16,97,1,42,1
523,16,98,1,1,2
523,16,104,4,0,
523,16,113,4,0,
523,16,148,4,0,
523,16,156,4,0,
523,16,164,4,0,
523,16,173,3,0,
523,16,182,4,0,
523,16,207,4,0,
523,16,209,1,25,1
523,16,213,4,0,
523,16,214,4,0,
523,16,216,4,0,
523,16,218,4,0,
523,16,228,1,22,1
523,16,237,4,0,
523,16,240,4,0,
523,16,249,4,0,
523,16,263,4,0,
523,16,268,1,1,4
523,16,268,1,8,1
523,16,289,3,0,
523,16,290,4,0,
523,16,315,4,0,
523,16,324,3,0,
523,16,340,3,0,
523,16,351,1,11,1
523,16,351,3,0,
523,16,393,3,0,
523,16,416,4,0,
523,16,435,1,36,1
523,16,451,4,0,
523,16,488,1,18,1
523,16,488,4,0,
523,16,496,4,0,
523,16,521,4,0,
523,16,528,1,47,1
523,16,528,4,0,
523,16,569,1,1,1
523,16,569,1,58,1
523,16,590,4,0,
523,17,23,1,31,
523,17,37,1,53,
523,17,39,1,1,3
523,17,39,1,4,
523,17,63,4,0,
523,17,85,4,0,
523,17,86,1,1,5
523,17,86,1,15,
523,17,86,4,0,
523,17,87,4,0,
523,17,92,4,0,
523,17,97,1,42,
523,17,98,1,1,2
523,17,104,4,0,
523,17,113,4,0,
523,17,156,4,0,
523,17,164,4,0,
523,17,182,4,0,
523,17,207,4,0,
523,17,209,1,25,
523,17,213,4,0,
523,17,214,4,0,
523,17,216,4,0,
523,17,218,4,0,
523,17,228,1,22,
523,17,237,4,0,
523,17,240,4,0,
523,17,263,4,0,
523,17,268,1,1,4
523,17,268,1,8,
523,17,315,4,0,
523,17,351,1,11,
523,17,416,4,0,
523,17,435,1,36,
523,17,451,4,0,
523,17,488,1,18,
523,17,488,4,0,
523,17,496,4,0,
523,17,521,4,0,
523,17,528,1,47,
523,17,528,4,0,
523,17,569,1,1,1
523,17,569,1,58,
523,17,590,4,0,
523,18,23,1,31,
523,18,37,1,53,
523,18,39,1,1,3
523,18,39,1,4,
523,18,63,4,0,
523,18,85,4,0,
523,18,86,1,1,5
523,18,86,1,15,
523,18,86,4,0,
523,18,87,4,0,
523,18,92,4,0,
523,18,97,1,42,
523,18,98,1,1,2
523,18,104,4,0,
523,18,113,4,0,
523,18,156,4,0,
523,18,164,4,0,
523,18,182,4,0,
523,18,207,4,0,
523,18,209,1,25,
523,18,213,4,0,
523,18,214,4,0,
523,18,216,4,0,
523,18,218,4,0,
523,18,228,1,22,
523,18,237,4,0,
523,18,240,4,0,
523,18,263,4,0,
523,18,268,1,1,4
523,18,268,1,8,
523,18,315,4,0,
523,18,351,1,11,
523,18,416,4,0,
523,18,435,1,36,
523,18,451,4,0,
523,18,488,1,18,
523,18,488,4,0,
523,18,496,4,0,
523,18,521,4,0,
523,18,528,1,47,
523,18,528,4,0,
523,18,569,1,1,1
523,18,569,1,58,
523,18,590,4,0,
524,11,28,1,7,
524,11,29,1,10,
524,11,33,1,1,
524,11,36,2,0,
524,11,70,4,0,
524,11,89,4,0,
524,11,92,4,0,
524,11,104,4,0,
524,11,106,1,4,
524,11,153,1,40,
524,11,153,4,0,
524,11,156,4,0,
524,11,157,1,27,
524,11,157,4,0,
524,11,164,4,0,
524,11,174,2,0,
524,11,182,4,0,
524,11,189,1,17,
524,11,199,2,0,
524,11,201,1,33,
524,11,201,4,0,
524,11,207,4,0,
524,11,213,4,0,
524,11,216,4,0,
524,11,218,4,0,
524,11,222,2,0,
524,11,237,4,0,
524,11,249,4,0,
524,11,263,4,0,
524,11,317,2,0,
524,11,317,4,0,
524,11,334,1,20,
524,11,350,1,14,
524,11,356,2,0,
524,11,397,4,0,
524,11,430,4,0,
524,11,444,1,36,
524,11,444,4,0,
524,11,446,1,30,
524,11,475,2,0,
524,11,479,1,23,
524,11,479,4,0,
524,11,484,2,0,
524,11,496,4,0,
524,11,523,4,0,
524,14,28,1,7,
524,14,29,1,10,
524,14,33,1,1,
524,14,36,2,0,
524,14,70,4,0,
524,14,89,4,0,
524,14,92,4,0,
524,14,104,4,0,
524,14,106,1,4,
524,14,153,1,40,
524,14,153,4,0,
524,14,156,4,0,
524,14,157,1,27,
524,14,157,4,0,
524,14,164,4,0,
524,14,173,3,0,
524,14,174,2,0,
524,14,182,4,0,
524,14,189,1,17,
524,14,199,2,0,
524,14,201,1,33,
524,14,201,4,0,
524,14,207,4,0,
524,14,213,4,0,
524,14,214,3,0,
524,14,216,4,0,
524,14,218,4,0,
524,14,222,2,0,
524,14,237,4,0,
524,14,249,4,0,
524,14,263,4,0,
524,14,317,2,0,
524,14,317,4,0,
524,14,334,1,20,
524,14,334,3,0,
524,14,335,3,0,
524,14,350,1,14,
524,14,356,2,0,
524,14,356,3,0,
524,14,397,4,0,
524,14,414,3,0,
524,14,430,4,0,
524,14,444,1,36,
524,14,444,4,0,
524,14,446,1,30,
524,14,446,3,0,
524,14,475,2,0,
524,14,479,1,23,
524,14,479,4,0,
524,14,484,2,0,
524,14,496,4,0,
524,14,523,4,0,
524,15,28,1,7,
524,15,29,1,10,
524,15,33,1,1,
524,15,36,2,0,
524,15,70,4,0,
524,15,89,4,0,
524,15,92,4,0,
524,15,104,4,0,
524,15,106,1,4,
524,15,153,1,40,
524,15,153,4,0,
524,15,156,4,0,
524,15,157,1,27,
524,15,157,4,0,
524,15,164,4,0,
524,15,174,2,0,
524,15,182,4,0,
524,15,189,1,17,
524,15,199,2,0,
524,15,201,1,33,
524,15,201,4,0,
524,15,207,4,0,
524,15,213,4,0,
524,15,214,4,0,
524,15,216,4,0,
524,15,218,4,0,
524,15,222,2,0,
524,15,237,4,0,
524,15,249,4,0,
524,15,263,4,0,
524,15,267,4,0,
524,15,317,2,0,
524,15,317,4,0,
524,15,334,1,20,
524,15,350,1,14,
524,15,356,2,0,
524,15,397,4,0,
524,15,430,4,0,
524,15,444,1,36,
524,15,444,4,0,
524,15,446,1,30,
524,15,469,2,0,
524,15,475,2,0,
524,15,479,1,23,
524,15,479,4,0,
524,15,484,2,0,
524,15,496,4,0,
524,15,523,4,0,
524,15,590,4,0,
524,16,28,1,7,1
524,16,29,1,10,1
524,16,33,1,1,1
524,16,36,2,0,
524,16,70,4,0,
524,16,89,4,0,
524,16,92,4,0,
524,16,104,4,0,
524,16,106,1,4,1
524,16,153,1,40,1
524,16,153,4,0,
524,16,156,4,0,
524,16,157,1,27,1
524,16,157,4,0,
524,16,164,4,0,
524,16,173,3,0,
524,16,174,2,0,
524,16,182,4,0,
524,16,189,1,17,1
524,16,199,2,0,
524,16,201,1,33,1
524,16,201,4,0,
524,16,207,4,0,
524,16,213,4,0,
524,16,214,4,0,
524,16,216,4,0,
524,16,218,4,0,
524,16,222,2,0,
524,16,237,4,0,
524,16,249,4,0,
524,16,263,4,0,
524,16,267,4,0,
524,16,290,4,0,
524,16,317,2,0,
524,16,317,4,0,
524,16,334,1,20,1
524,16,334,3,0,
524,16,335,3,0,
524,16,350,1,14,1
524,16,356,2,0,
524,16,356,3,0,
524,16,397,4,0,
524,16,414,3,0,
524,16,430,4,0,
524,16,444,1,36,1
524,16,444,4,0,
524,16,446,1,30,1
524,16,446,3,0,
524,16,469,2,0,
524,16,475,2,0,
524,16,479,1,23,1
524,16,479,4,0,
524,16,484,2,0,
524,16,496,4,0,
524,16,523,4,0,
524,16,590,4,0,
524,17,28,1,7,
524,17,29,1,10,
524,17,33,1,1,
524,17,36,2,0,
524,17,89,4,0,
524,17,92,4,0,
524,17,104,4,0,
524,17,106,1,4,
524,17,153,1,40,
524,17,153,4,0,
524,17,156,4,0,
524,17,157,1,27,
524,17,157,4,0,
524,17,164,4,0,
524,17,174,2,0,
524,17,182,4,0,
524,17,189,1,17,
524,17,199,2,0,
524,17,201,1,33,
524,17,201,4,0,
524,17,207,4,0,
524,17,213,4,0,
524,17,214,4,0,
524,17,216,4,0,
524,17,218,4,0,
524,17,222,2,0,
524,17,237,4,0,
524,17,263,4,0,
524,17,267,4,0,
524,17,317,2,0,
524,17,317,4,0,
524,17,334,1,20,
524,17,350,1,14,
524,17,356,2,0,
524,17,397,4,0,
524,17,430,4,0,
524,17,444,1,36,
524,17,444,4,0,
524,17,446,1,30,
524,17,469,2,0,
524,17,475,2,0,
524,17,479,1,23,
524,17,479,4,0,
524,17,484,2,0,
524,17,496,4,0,
524,17,523,4,0,
524,17,590,4,0,
524,18,28,1,7,
524,18,29,1,10,
524,18,33,1,1,
524,18,36,2,0,
524,18,89,4,0,
524,18,92,4,0,
524,18,104,4,0,
524,18,106,1,4,
524,18,153,1,40,
524,18,153,4,0,
524,18,156,4,0,
524,18,157,1,27,
524,18,157,4,0,
524,18,164,4,0,
524,18,174,2,0,
524,18,182,4,0,
524,18,189,1,17,
524,18,199,2,0,
524,18,201,1,33,
524,18,201,4,0,
524,18,207,4,0,
524,18,213,4,0,
524,18,214,4,0,
524,18,216,4,0,
524,18,218,4,0,
524,18,222,2,0,
524,18,237,4,0,
524,18,263,4,0,
524,18,267,4,0,
524,18,317,2,0,
524,18,317,4,0,
524,18,334,1,20,
524,18,350,1,14,
524,18,356,2,0,
524,18,397,4,0,
524,18,430,4,0,
524,18,444,1,36,
524,18,444,4,0,
524,18,446,1,30,
524,18,469,2,0,
524,18,475,2,0,
524,18,479,1,23,
524,18,479,4,0,
524,18,484,2,0,
524,18,496,4,0,
524,18,523,4,0,
524,18,590,4,0,
525,11,28,1,1,3
525,11,28,1,7,
525,11,29,1,1,4
525,11,29,1,10,
525,11,33,1,1,1
525,11,70,4,0,
525,11,89,4,0,
525,11,92,4,0,
525,11,104,4,0,
525,11,106,1,1,2
525,11,106,1,4,
525,11,153,1,55,
525,11,153,4,0,
525,11,156,4,0,
525,11,157,1,30,
525,11,157,4,0,
525,11,164,4,0,
525,11,182,4,0,
525,11,189,1,17,
525,11,201,1,42,
525,11,201,4,0,
525,11,207,4,0,
525,11,213,4,0,
525,11,216,4,0,
525,11,218,4,0,
525,11,237,4,0,
525,11,249,4,0,
525,11,263,4,0,
525,11,317,4,0,
525,11,334,1,20,
525,11,350,1,14,
525,11,397,4,0,
525,11,408,1,25,
525,11,430,4,0,
525,11,444,1,48,
525,11,444,4,0,
525,11,446,1,36,
525,11,479,1,23,
525,11,479,4,0,
525,11,496,4,0,
525,11,523,4,0,
525,14,28,1,1,3
525,14,28,1,7,
525,14,29,1,1,4
525,14,29,1,10,
525,14,33,1,1,1
525,14,70,4,0,
525,14,89,4,0,
525,14,92,4,0,
525,14,104,4,0,
525,14,106,1,1,2
525,14,106,1,4,
525,14,153,1,55,
525,14,153,4,0,
525,14,156,4,0,
525,14,157,1,30,
525,14,157,4,0,
525,14,164,4,0,
525,14,173,3,0,
525,14,182,4,0,
525,14,189,1,17,
525,14,201,1,42,
525,14,201,4,0,
525,14,207,4,0,
525,14,213,4,0,
525,14,214,3,0,
525,14,216,4,0,
525,14,218,4,0,
525,14,237,4,0,
525,14,249,4,0,
525,14,263,4,0,
525,14,317,4,0,
525,14,334,1,20,
525,14,334,3,0,
525,14,335,3,0,
525,14,350,1,14,
525,14,356,3,0,
525,14,397,4,0,
525,14,408,1,25,
525,14,414,3,0,
525,14,430,4,0,
525,14,444,1,48,
525,14,444,4,0,
525,14,446,1,36,
525,14,446,3,0,
525,14,479,1,23,
525,14,479,4,0,
525,14,496,4,0,
525,14,523,4,0,
525,15,28,1,1,3
525,15,28,1,7,
525,15,29,1,1,4
525,15,29,1,10,
525,15,33,1,1,1
525,15,70,4,0,
525,15,89,4,0,
525,15,92,4,0,
525,15,104,4,0,
525,15,106,1,1,2
525,15,106,1,4,
525,15,153,1,55,
525,15,153,4,0,
525,15,156,4,0,
525,15,157,1,30,
525,15,157,4,0,
525,15,164,4,0,
525,15,182,4,0,
525,15,189,1,17,
525,15,201,1,42,
525,15,201,4,0,
525,15,207,4,0,
525,15,213,4,0,
525,15,214,4,0,
525,15,216,4,0,
525,15,218,4,0,
525,15,237,4,0,
525,15,249,4,0,
525,15,263,4,0,
525,15,267,4,0,
525,15,317,4,0,
525,15,334,1,20,
525,15,350,1,14,
525,15,397,4,0,
525,15,408,1,25,
525,15,430,4,0,
525,15,444,1,48,
525,15,444,4,0,
525,15,446,1,36,
525,15,479,1,23,
525,15,479,4,0,
525,15,496,4,0,
525,15,523,4,0,
525,15,590,4,0,
525,16,28,1,1,3
525,16,28,1,7,1
525,16,29,1,1,4
525,16,29,1,10,1
525,16,33,1,1,1
525,16,70,4,0,
525,16,89,4,0,
525,16,92,4,0,
525,16,104,4,0,
525,16,106,1,1,2
525,16,106,1,4,1
525,16,153,1,55,1
525,16,153,4,0,
525,16,156,4,0,
525,16,157,1,30,1
525,16,157,4,0,
525,16,164,4,0,
525,16,173,3,0,
525,16,182,4,0,
525,16,189,1,17,1
525,16,201,1,42,1
525,16,201,4,0,
525,16,207,4,0,
525,16,213,4,0,
525,16,214,4,0,
525,16,216,4,0,
525,16,218,4,0,
525,16,237,4,0,
525,16,249,4,0,
525,16,263,4,0,
525,16,267,4,0,
525,16,290,4,0,
525,16,317,4,0,
525,16,334,1,20,1
525,16,334,3,0,
525,16,335,3,0,
525,16,350,1,14,1
525,16,356,3,0,
525,16,397,4,0,
525,16,408,1,25,1
525,16,414,3,0,
525,16,430,4,0,
525,16,444,1,48,1
525,16,444,4,0,
525,16,446,1,36,1
525,16,446,3,0,
525,16,479,1,23,1
525,16,479,4,0,
525,16,496,4,0,
525,16,523,4,0,
525,16,590,4,0,
525,17,28,1,1,4
525,17,28,1,7,
525,17,29,1,1,5
525,17,29,1,10,
525,17,33,1,1,2
525,17,89,4,0,
525,17,92,4,0,
525,17,104,4,0,
525,17,106,1,1,3
525,17,106,1,4,
525,17,153,1,55,
525,17,153,4,0,
525,17,156,4,0,
525,17,157,1,30,
525,17,157,4,0,
525,17,164,4,0,
525,17,182,4,0,
525,17,189,1,17,
525,17,201,1,42,
525,17,201,4,0,
525,17,207,4,0,
525,17,213,4,0,
525,17,214,4,0,
525,17,216,4,0,
525,17,218,4,0,
525,17,237,4,0,
525,17,263,4,0,
525,17,267,4,0,
525,17,317,4,0,
525,17,334,1,20,
525,17,350,1,14,
525,17,397,4,0,
525,17,408,1,0,
525,17,408,1,1,1
525,17,430,4,0,
525,17,444,1,48,
525,17,444,4,0,
525,17,446,1,36,
525,17,479,1,23,
525,17,479,4,0,
525,17,496,4,0,
525,17,523,4,0,
525,17,590,4,0,
525,18,28,1,1,4
525,18,28,1,7,
525,18,29,1,1,5
525,18,29,1,10,
525,18,33,1,1,2
525,18,89,4,0,
525,18,92,4,0,
525,18,104,4,0,
525,18,106,1,1,3
525,18,106,1,4,
525,18,153,1,55,
525,18,153,4,0,
525,18,156,4,0,
525,18,157,1,30,
525,18,157,4,0,
525,18,164,4,0,
525,18,182,4,0,
525,18,189,1,17,
525,18,201,1,42,
525,18,201,4,0,
525,18,207,4,0,
525,18,213,4,0,
525,18,214,4,0,
525,18,216,4,0,
525,18,218,4,0,
525,18,237,4,0,
525,18,263,4,0,
525,18,267,4,0,
525,18,317,4,0,
525,18,334,1,20,
525,18,350,1,14,
525,18,397,4,0,
525,18,408,1,0,
525,18,408,1,1,1
525,18,430,4,0,
525,18,444,1,48,
525,18,444,4,0,
525,18,446,1,36,
525,18,479,1,23,
525,18,479,4,0,
525,18,496,4,0,
525,18,523,4,0,
525,18,590,4,0,
526,11,28,1,1,3
526,11,28,1,7,
526,11,29,1,1,4
526,11,29,1,10,
526,11,33,1,1,1
526,11,63,4,0,
526,11,70,4,0,
526,11,76,4,0,
526,11,89,4,0,
526,11,92,4,0,
526,11,104,4,0,
526,11,106,1,1,2
526,11,106,1,4,
526,11,153,1,55,
526,11,153,4,0,
526,11,156,4,0,
526,11,157,1,30,
526,11,157,4,0,
526,11,164,4,0,
526,11,182,4,0,
526,11,189,1,17,
526,11,201,1,42,
526,11,201,4,0,
526,11,207,4,0,
526,11,213,4,0,
526,11,216,4,0,
526,11,218,4,0,
526,11,237,4,0,
526,11,249,4,0,
526,11,263,4,0,
526,11,317,4,0,
526,11,334,1,20,
526,11,350,1,14,
526,11,397,4,0,
526,11,408,1,25,
526,11,416,4,0,
526,11,430,4,0,
526,11,444,1,48,
526,11,444,4,0,
526,11,446,1,36,
526,11,479,1,23,
526,11,479,4,0,
526,11,496,4,0,
526,11,523,4,0,
526,14,28,1,1,3
526,14,28,1,7,
526,14,29,1,1,4
526,14,29,1,10,
526,14,33,1,1,1
526,14,63,4,0,
526,14,70,4,0,
526,14,76,4,0,
526,14,89,4,0,
526,14,92,4,0,
526,14,104,4,0,
526,14,106,1,1,2
526,14,106,1,4,
526,14,153,1,55,
526,14,153,4,0,
526,14,156,4,0,
526,14,157,1,30,
526,14,157,4,0,
526,14,164,4,0,
526,14,173,3,0,
526,14,182,4,0,
526,14,189,1,17,
526,14,201,1,42,
526,14,201,4,0,
526,14,207,4,0,
526,14,213,4,0,
526,14,214,3,0,
526,14,216,4,0,
526,14,218,4,0,
526,14,237,4,0,
526,14,249,4,0,
526,14,263,4,0,
526,14,276,3,0,
526,14,317,4,0,
526,14,334,1,20,
526,14,334,3,0,
526,14,335,3,0,
526,14,350,1,14,
526,14,356,3,0,
526,14,397,4,0,
526,14,408,1,25,
526,14,414,3,0,
526,14,416,4,0,
526,14,430,4,0,
526,14,442,3,0,
526,14,444,1,48,
526,14,444,4,0,
526,14,446,1,36,
526,14,446,3,0,
526,14,479,1,23,
526,14,479,4,0,
526,14,496,4,0,
526,14,523,4,0,
526,15,28,1,1,3
526,15,28,1,7,
526,15,29,1,1,4
526,15,29,1,10,
526,15,33,1,1,1
526,15,63,4,0,
526,15,70,4,0,
526,15,76,4,0,
526,15,89,4,0,
526,15,92,4,0,
526,15,104,4,0,
526,15,106,1,1,2
526,15,106,1,4,
526,15,153,1,55,
526,15,153,4,0,
526,15,156,4,0,
526,15,157,1,30,
526,15,157,4,0,
526,15,164,4,0,
526,15,182,4,0,
526,15,189,1,17,
526,15,201,1,42,
526,15,201,4,0,
526,15,207,4,0,
526,15,213,4,0,
526,15,214,4,0,
526,15,216,4,0,
526,15,218,4,0,
526,15,237,4,0,
526,15,249,4,0,
526,15,263,4,0,
526,15,267,4,0,
526,15,317,4,0,
526,15,334,1,20,
526,15,350,1,14,
526,15,397,4,0,
526,15,408,1,25,
526,15,416,4,0,
526,15,430,4,0,
526,15,444,1,48,
526,15,444,4,0,
526,15,446,1,36,
526,15,479,1,23,
526,15,479,4,0,
526,15,496,4,0,
526,15,523,4,0,
526,15,590,4,0,
526,16,28,1,1,3
526,16,28,1,7,1
526,16,29,1,1,4
526,16,29,1,10,1
526,16,33,1,1,1
526,16,63,4,0,
526,16,70,4,0,
526,16,76,4,0,
526,16,89,4,0,
526,16,92,4,0,
526,16,104,4,0,
526,16,106,1,1,2
526,16,106,1,4,1
526,16,153,1,55,1
526,16,153,4,0,
526,16,156,4,0,
526,16,157,1,30,1
526,16,157,4,0,
526,16,164,4,0,
526,16,173,3,0,
526,16,182,4,0,
526,16,189,1,17,1
526,16,201,1,42,1
526,16,201,4,0,
526,16,207,4,0,
526,16,213,4,0,
526,16,214,4,0,
526,16,216,4,0,
526,16,218,4,0,
526,16,237,4,0,
526,16,249,4,0,
526,16,263,4,0,
526,16,267,4,0,
526,16,276,3,0,
526,16,290,4,0,
526,16,317,4,0,
526,16,334,1,20,1
526,16,334,3,0,
526,16,335,3,0,
526,16,350,1,14,1
526,16,356,3,0,
526,16,397,4,0,
526,16,408,1,25,1
526,16,414,3,0,
526,16,416,4,0,
526,16,430,4,0,
526,16,442,3,0,
526,16,444,1,48,1
526,16,444,4,0,
526,16,446,1,36,1
526,16,446,3,0,
526,16,479,1,23,1
526,16,479,4,0,
526,16,496,4,0,
526,16,523,4,0,
526,16,590,4,0,
526,17,28,1,1,4
526,17,28,1,7,
526,17,29,1,1,5
526,17,29,1,10,
526,17,33,1,1,2
526,17,63,4,0,
526,17,76,4,0,
526,17,89,4,0,
526,17,92,4,0,
526,17,104,4,0,
526,17,106,1,1,3
526,17,106,1,4,
526,17,153,1,55,
526,17,153,4,0,
526,17,156,4,0,
526,17,157,1,30,
526,17,157,4,0,
526,17,164,4,0,
526,17,182,4,0,
526,17,189,1,17,
526,17,201,1,42,
526,17,201,4,0,
526,17,207,4,0,
526,17,213,4,0,
526,17,214,4,0,
526,17,216,4,0,
526,17,218,4,0,
526,17,237,4,0,
526,17,263,4,0,
526,17,267,4,0,
526,17,317,4,0,
526,17,334,1,20,
526,17,350,1,14,
526,17,397,4,0,
526,17,408,1,1,1
526,17,416,4,0,
526,17,430,4,0,
526,17,444,1,48,
526,17,444,4,0,
526,17,446,1,36,
526,17,479,1,23,
526,17,479,4,0,
526,17,496,4,0,
526,17,523,4,0,
526,17,590,4,0,
526,18,28,1,1,4
526,18,28,1,7,
526,18,29,1,1,5
526,18,29,1,10,
526,18,33,1,1,2
526,18,63,4,0,
526,18,76,4,0,
526,18,89,4,0,
526,18,92,4,0,
526,18,104,4,0,
526,18,106,1,1,3
526,18,106,1,4,
526,18,153,1,55,
526,18,153,4,0,
526,18,156,4,0,
526,18,157,1,30,
526,18,157,4,0,
526,18,164,4,0,
526,18,182,4,0,
526,18,189,1,17,
526,18,201,1,42,
526,18,201,4,0,
526,18,207,4,0,
526,18,213,4,0,
526,18,214,4,0,
526,18,216,4,0,
526,18,218,4,0,
526,18,237,4,0,
526,18,263,4,0,
526,18,267,4,0,
526,18,317,4,0,
526,18,334,1,20,
526,18,350,1,14,
526,18,397,4,0,
526,18,408,1,1,1
526,18,416,4,0,
526,18,430,4,0,
526,18,444,1,48,
526,18,444,4,0,
526,18,446,1,36,
526,18,479,1,23,
526,18,479,4,0,
526,18,496,4,0,
526,18,523,4,0,
526,18,590,4,0,
527,11,16,1,8,
527,11,19,4,0,
527,11,48,2,0,
527,11,86,4,0,
527,11,92,4,0,
527,11,93,1,1,
527,11,94,1,41,
527,11,94,4,0,
527,11,104,4,0,
527,11,113,4,0,
527,11,115,4,0,
527,11,133,1,29,1
527,11,138,4,0,
527,11,148,4,0,
527,11,156,4,0,
527,11,164,4,0,
527,11,168,4,0,
527,11,182,4,0,
527,11,204,2,0,
527,11,207,4,0,
527,11,213,1,25,
527,11,213,4,0,
527,11,216,4,0,
527,11,218,4,0,
527,11,219,4,0,
527,11,237,4,0,
527,11,240,4,0,
527,11,244,4,0,
527,11,247,4,0,
527,11,248,1,36,
527,11,259,4,0,
527,11,260,2,0,
527,11,263,4,0,
527,11,269,4,0,
527,11,270,2,0,
527,11,282,2,0,
527,11,283,1,47,
527,11,286,1,19,
527,11,313,2,0,
527,11,314,1,21,
527,11,316,1,4,
527,11,332,4,0,
527,11,347,1,29,2
527,11,347,4,0,
527,11,355,2,0,
527,11,360,4,0,
527,11,365,4,0,
527,11,369,4,0,
527,11,372,1,12,
527,11,373,4,0,
527,11,403,1,32,
527,11,412,4,0,
527,11,433,4,0,
527,11,451,4,0,
527,11,473,4,0,
527,11,477,4,0,
527,11,485,2,0,
527,11,496,4,0,
527,11,500,2,0,
527,11,512,4,0,
527,11,531,1,15,
527,14,16,1,8,
527,14,19,4,0,
527,14,48,2,0,
527,14,86,4,0,
527,14,92,4,0,
527,14,93,1,1,
527,14,94,1,41,
527,14,94,4,0,
527,14,104,4,0,
527,14,113,4,0,
527,14,115,4,0,
527,14,133,1,29,1
527,14,138,4,0,
527,14,148,4,0,
527,14,156,4,0,
527,14,162,3,0,
527,14,164,4,0,
527,14,168,4,0,
527,14,173,3,0,
527,14,182,4,0,
527,14,202,3,0,
527,14,204,2,0,
527,14,207,4,0,
527,14,213,1,25,
527,14,213,4,0,
527,14,214,3,0,
527,14,216,4,0,
527,14,218,4,0,
527,14,219,4,0,
527,14,237,4,0,
527,14,240,4,0,
527,14,244,4,0,
527,14,247,4,0,
527,14,248,1,36,
527,14,253,3,0,
527,14,257,3,0,
527,14,259,4,0,
527,14,260,2,0,
527,14,263,4,0,
527,14,269,4,0,
527,14,270,2,0,
527,14,270,3,0,
527,14,271,3,0,
527,14,277,3,0,
527,14,282,2,0,
527,14,282,3,0,
527,14,283,1,47,
527,14,283,3,0,
527,14,285,3,0,
527,14,286,1,19,
527,14,313,2,0,
527,14,314,1,21,
527,14,316,1,4,
527,14,324,3,0,
527,14,332,4,0,
527,14,347,1,29,2
527,14,347,4,0,
527,14,355,2,0,
527,14,355,3,0,
527,14,360,4,0,
527,14,365,4,0,
527,14,366,3,0,
527,14,369,4,0,
527,14,372,1,12,
527,14,373,4,0,
527,14,403,1,32,
527,14,412,4,0,
527,14,428,3,0,
527,14,433,4,0,
527,14,451,4,0,
527,14,473,4,0,
527,14,477,4,0,
527,14,485,2,0,
527,14,495,3,0,
527,14,496,4,0,
527,14,500,2,0,
527,14,512,4,0,
527,14,531,1,15,
527,15,16,1,8,
527,15,19,4,0,
527,15,48,2,0,
527,15,86,4,0,
527,15,92,4,0,
527,15,93,1,1,
527,15,94,1,41,
527,15,94,4,0,
527,15,104,4,0,
527,15,113,4,0,
527,15,115,4,0,
527,15,133,1,29,1
527,15,138,4,0,
527,15,148,4,0,
527,15,156,4,0,
527,15,164,4,0,
527,15,168,4,0,
527,15,182,4,0,
527,15,204,2,0,
527,15,207,4,0,
527,15,211,4,0,
527,15,213,1,25,
527,15,213,4,0,
527,15,214,4,0,
527,15,216,4,0,
527,15,218,4,0,
527,15,219,4,0,
527,15,237,4,0,
527,15,240,4,0,
527,15,244,4,0,
527,15,247,4,0,
527,15,248,1,36,
527,15,259,4,0,
527,15,260,2,0,
527,15,263,4,0,
527,15,269,4,0,
527,15,270,2,0,
527,15,282,2,0,
527,15,283,1,47,
527,15,286,1,19,
527,15,313,2,0,
527,15,314,1,21,
527,15,316,1,4,
527,15,332,4,0,
527,15,347,1,29,2
527,15,347,4,0,
527,15,355,2,0,
527,15,355,4,0,
527,15,360,4,0,
527,15,369,4,0,
527,15,372,1,12,
527,15,373,4,0,
527,15,375,2,0,
527,15,403,1,32,
527,15,412,4,0,
527,15,433,4,0,
527,15,445,2,0,
527,15,451,4,0,
527,15,473,4,0,
527,15,485,2,0,
527,15,496,4,0,
527,15,500,2,0,
527,15,512,4,0,
527,15,531,1,15,
527,15,590,4,0,
527,15,599,2,0,
527,16,16,1,8,1
527,16,19,4,0,
527,16,48,2,0,
527,16,86,4,0,
527,16,92,4,0,
527,16,93,1,1,1
527,16,94,1,41,1
527,16,94,4,0,
527,16,104,4,0,
527,16,113,4,0,
527,16,115,4,0,
527,16,133,1,29,1
527,16,138,4,0,
527,16,148,4,0,
527,16,156,4,0,
527,16,162,3,0,
527,16,164,4,0,
527,16,168,4,0,
527,16,173,3,0,
527,16,182,4,0,
527,16,202,3,0,
527,16,204,2,0,
527,16,207,4,0,
527,16,211,4,0,
527,16,213,1,25,1
527,16,213,4,0,
527,16,214,4,0,
527,16,216,4,0,
527,16,218,4,0,
527,16,219,4,0,
527,16,237,4,0,
527,16,240,4,0,
527,16,244,4,0,
527,16,247,4,0,
527,16,248,1,36,1
527,16,253,3,0,
527,16,257,3,0,
527,16,259,4,0,
527,16,260,2,0,
527,16,263,4,0,
527,16,269,4,0,
527,16,270,2,0,
527,16,270,3,0,
527,16,271,3,0,
527,16,277,3,0,
527,16,282,2,0,
527,16,282,3,0,
527,16,283,1,47,1
527,16,283,3,0,
527,16,285,3,0,
527,16,286,1,19,1
527,16,290,4,0,
527,16,313,2,0,
527,16,314,1,21,1
527,16,316,1,4,1
527,16,324,3,0,
527,16,332,4,0,
527,16,347,1,29,2
527,16,347,4,0,
527,16,351,3,0,
527,16,355,2,0,
527,16,355,4,0,
527,16,360,4,0,
527,16,366,3,0,
527,16,369,4,0,
527,16,372,1,12,1
527,16,373,4,0,
527,16,375,2,0,
527,16,403,1,32,1
527,16,412,4,0,
527,16,428,3,0,
527,16,433,4,0,
527,16,445,2,0,
527,16,451,4,0,
527,16,473,4,0,
527,16,485,2,0,
527,16,495,3,0,
527,16,496,4,0,
527,16,500,2,0,
527,16,512,4,0,
527,16,531,1,15,1
527,16,590,4,0,
527,16,599,2,0,
527,17,16,1,8,
527,17,19,4,0,
527,17,48,2,0,
527,17,86,4,0,
527,17,92,4,0,
527,17,93,1,1,
527,17,94,1,41,
527,17,94,4,0,
527,17,104,4,0,
527,17,113,4,0,
527,17,115,4,0,
527,17,133,1,29,1
527,17,138,4,0,
527,17,156,4,0,
527,17,164,4,0,
527,17,168,4,0,
527,17,182,4,0,
527,17,204,2,0,
527,17,207,4,0,
527,17,211,4,0,
527,17,213,1,25,
527,17,213,4,0,
527,17,214,4,0,
527,17,216,4,0,
527,17,218,4,0,
527,17,219,4,0,
527,17,237,4,0,
527,17,240,4,0,
527,17,244,4,0,
527,17,247,4,0,
527,17,248,1,36,
527,17,259,4,0,
527,17,260,2,0,
527,17,263,4,0,
527,17,269,4,0,
527,17,270,2,0,
527,17,282,2,0,
527,17,283,1,47,
527,17,286,1,19,
527,17,313,2,0,
527,17,314,1,21,
527,17,316,1,4,
527,17,332,4,0,
527,17,347,1,29,2
527,17,347,4,0,
527,17,355,2,0,
527,17,355,4,0,
527,17,360,4,0,
527,17,369,4,0,
527,17,372,1,12,
527,17,373,4,0,
527,17,375,2,0,
527,17,403,1,32,
527,17,412,4,0,
527,17,433,4,0,
527,17,445,2,0,
527,17,451,4,0,
527,17,473,4,0,
527,17,485,2,0,
527,17,496,4,0,
527,17,500,2,0,
527,17,512,4,0,
527,17,531,1,15,
527,17,590,4,0,
527,17,599,2,0,
527,18,16,1,8,
527,18,19,4,0,
527,18,48,2,0,
527,18,86,4,0,
527,18,92,4,0,
527,18,93,1,1,
527,18,94,1,41,
527,18,94,4,0,
527,18,104,4,0,
527,18,113,4,0,
527,18,115,4,0,
527,18,133,1,29,1
527,18,138,4,0,
527,18,156,4,0,
527,18,164,4,0,
527,18,168,4,0,
527,18,182,4,0,
527,18,204,2,0,
527,18,207,4,0,
527,18,211,4,0,
527,18,213,1,25,
527,18,213,4,0,
527,18,214,4,0,
527,18,216,4,0,
527,18,218,4,0,
527,18,219,4,0,
527,18,237,4,0,
527,18,240,4,0,
527,18,244,4,0,
527,18,247,4,0,
527,18,248,1,36,
527,18,259,4,0,
527,18,260,2,0,
527,18,263,4,0,
527,18,269,4,0,
527,18,270,2,0,
527,18,282,2,0,
527,18,283,1,47,
527,18,286,1,19,
527,18,313,2,0,
527,18,314,1,21,
527,18,316,1,4,
527,18,332,4,0,
527,18,347,1,29,2
527,18,347,4,0,
527,18,355,2,0,
527,18,355,4,0,
527,18,360,4,0,
527,18,369,4,0,
527,18,372,1,12,
527,18,373,4,0,
527,18,375,2,0,
527,18,403,1,32,
527,18,412,4,0,
527,18,433,4,0,
527,18,445,2,0,
527,18,451,4,0,
527,18,473,4,0,
527,18,485,2,0,
527,18,496,4,0,
527,18,500,2,0,
527,18,512,4,0,
527,18,531,1,15,
527,18,590,4,0,
527,18,599,2,0,
528,11,16,1,1,3
528,11,16,1,8,
528,11,19,4,0,
528,11,63,4,0,
528,11,86,4,0,
528,11,92,4,0,
528,11,93,1,1,1
528,11,94,1,41,
528,11,94,4,0,
528,11,104,4,0,
528,11,113,4,0,
528,11,115,4,0,
528,11,133,1,29,1
528,11,138,4,0,
528,11,148,4,0,
528,11,156,4,0,
528,11,164,4,0,
528,11,168,4,0,
528,11,182,4,0,
528,11,207,4,0,
528,11,213,1,25,
528,11,213,4,0,
528,11,216,4,0,
528,11,218,4,0,
528,11,219,4,0,
528,11,237,4,0,
528,11,240,4,0,
528,11,244,4,0,
528,11,247,4,0,
528,11,248,1,36,
528,11,259,4,0,
528,11,263,4,0,
528,11,269,4,0,
528,11,283,1,47,
528,11,286,1,19,
528,11,314,1,21,
528,11,316,1,1,2
528,11,316,1,4,
528,11,332,4,0,
528,11,347,1,29,2
528,11,347,4,0,
528,11,360,4,0,
528,11,365,4,0,
528,11,369,4,0,
528,11,372,1,1,4
528,11,372,1,12,
528,11,373,4,0,
528,11,403,1,32,
528,11,412,4,0,
528,11,416,4,0,
528,11,433,4,0,
528,11,451,4,0,
528,11,473,4,0,
528,11,477,4,0,
528,11,496,4,0,
528,11,512,4,0,
528,11,531,1,15,
528,14,16,1,1,3
528,14,16,1,8,
528,14,19,4,0,
528,14,63,4,0,
528,14,86,4,0,
528,14,92,4,0,
528,14,93,1,1,1
528,14,94,1,41,
528,14,94,4,0,
528,14,104,4,0,
528,14,113,4,0,
528,14,115,4,0,
528,14,133,1,29,1
528,14,138,4,0,
528,14,143,3,0,
528,14,148,4,0,
528,14,156,4,0,
528,14,162,3,0,
528,14,164,4,0,
528,14,168,4,0,
528,14,173,3,0,
528,14,182,4,0,
528,14,202,3,0,
528,14,207,4,0,
528,14,213,1,25,
528,14,213,4,0,
528,14,214,3,0,
528,14,216,4,0,
528,14,218,4,0,
528,14,219,4,0,
528,14,237,4,0,
528,14,240,4,0,
528,14,244,4,0,
528,14,247,4,0,
528,14,248,1,36,
528,14,253,3,0,
528,14,257,3,0,
528,14,259,4,0,
528,14,263,4,0,
528,14,269,4,0,
528,14,270,3,0,
528,14,271,3,0,
528,14,277,3,0,
528,14,282,3,0,
528,14,283,1,47,
528,14,283,3,0,
528,14,285,3,0,
528,14,286,1,19,
528,14,314,1,21,
528,14,316,1,1,2
528,14,316,1,4,
528,14,324,3,0,
528,14,332,4,0,
528,14,347,1,29,2
528,14,347,4,0,
528,14,355,3,0,
528,14,360,4,0,
528,14,365,4,0,
528,14,366,3,0,
528,14,369,4,0,
528,14,372,1,1,4
528,14,372,1,12,
528,14,373,4,0,
528,14,403,1,32,
528,14,412,4,0,
528,14,416,4,0,
528,14,428,3,0,
528,14,433,4,0,
528,14,451,4,0,
528,14,473,4,0,
528,14,477,4,0,
528,14,495,3,0,
528,14,496,4,0,
528,14,512,4,0,
528,14,531,1,15,
528,15,16,1,1,3
528,15,16,1,8,
528,15,19,4,0,
528,15,63,4,0,
528,15,86,4,0,
528,15,92,4,0,
528,15,93,1,1,1
528,15,94,1,41,
528,15,94,4,0,
528,15,104,4,0,
528,15,113,4,0,
528,15,115,4,0,
528,15,133,1,29,1
528,15,138,4,0,
528,15,148,4,0,
528,15,156,4,0,
528,15,164,4,0,
528,15,168,4,0,
528,15,182,4,0,
528,15,207,4,0,
528,15,211,4,0,
528,15,213,1,25,
528,15,213,4,0,
528,15,214,4,0,
528,15,216,4,0,
528,15,218,4,0,
528,15,219,4,0,
528,15,237,4,0,
528,15,240,4,0,
528,15,244,4,0,
528,15,247,4,0,
528,15,248,1,36,
528,15,259,4,0,
528,15,263,4,0,
528,15,269,4,0,
528,15,283,1,47,
528,15,286,1,19,
528,15,314,1,21,
528,15,316,1,1,2
528,15,316,1,4,
528,15,332,4,0,
528,15,347,1,29,2
528,15,347,4,0,
528,15,355,4,0,
528,15,360,4,0,
528,15,369,4,0,
528,15,372,1,1,4
528,15,372,1,12,
528,15,373,4,0,
528,15,403,1,32,
528,15,412,4,0,
528,15,416,4,0,
528,15,433,4,0,
528,15,451,4,0,
528,15,473,4,0,
528,15,496,4,0,
528,15,512,4,0,
528,15,531,1,15,
528,15,590,4,0,
528,16,16,1,1,3
528,16,16,1,8,1
528,16,19,4,0,
528,16,63,4,0,
528,16,86,4,0,
528,16,92,4,0,
528,16,93,1,1,1
528,16,94,1,41,1
528,16,94,4,0,
528,16,104,4,0,
528,16,113,4,0,
528,16,115,4,0,
528,16,133,1,29,1
528,16,138,4,0,
528,16,143,3,0,
528,16,148,4,0,
528,16,156,4,0,
528,16,162,3,0,
528,16,164,4,0,
528,16,168,4,0,
528,16,173,3,0,
528,16,182,4,0,
528,16,202,3,0,
528,16,207,4,0,
528,16,211,4,0,
528,16,213,1,25,1
528,16,213,4,0,
528,16,214,4,0,
528,16,216,4,0,
528,16,218,4,0,
528,16,219,4,0,
528,16,237,4,0,
528,16,240,4,0,
528,16,244,4,0,
528,16,247,4,0,
528,16,248,1,36,1
528,16,253,3,0,
528,16,257,3,0,
528,16,259,4,0,
528,16,263,4,0,
528,16,269,4,0,
528,16,270,3,0,
528,16,271,3,0,
528,16,277,3,0,
528,16,282,3,0,
528,16,283,1,47,1
528,16,283,3,0,
528,16,285,3,0,
528,16,286,1,19,1
528,16,290,4,0,
528,16,314,1,21,1
528,16,316,1,1,2
528,16,316,1,4,1
528,16,324,3,0,
528,16,332,4,0,
528,16,347,1,29,2
528,16,347,4,0,
528,16,351,3,0,
528,16,355,4,0,
528,16,360,4,0,
528,16,366,3,0,
528,16,369,4,0,
528,16,372,1,1,4
528,16,372,1,12,1
528,16,373,4,0,
528,16,403,1,32,1
528,16,412,4,0,
528,16,416,4,0,
528,16,428,3,0,
528,16,433,4,0,
528,16,451,4,0,
528,16,473,4,0,
528,16,495,3,0,
528,16,496,4,0,
528,16,512,4,0,
528,16,531,1,15,1
528,16,590,4,0,
528,17,16,1,1,3
528,17,16,1,8,
528,17,19,4,0,
528,17,63,4,0,
528,17,86,4,0,
528,17,92,4,0,
528,17,93,1,1,1
528,17,94,1,41,
528,17,94,4,0,
528,17,104,4,0,
528,17,113,4,0,
528,17,115,4,0,
528,17,133,1,29,1
528,17,138,4,0,
528,17,156,4,0,
528,17,164,4,0,
528,17,168,4,0,
528,17,182,4,0,
528,17,207,4,0,
528,17,211,4,0,
528,17,213,1,25,
528,17,213,4,0,
528,17,214,4,0,
528,17,216,4,0,
528,17,218,4,0,
528,17,219,4,0,
528,17,237,4,0,
528,17,240,4,0,
528,17,244,4,0,
528,17,247,4,0,
528,17,248,1,36,
528,17,259,4,0,
528,17,263,4,0,
528,17,269,4,0,
528,17,283,1,47,
528,17,286,1,19,
528,17,314,1,21,
528,17,316,1,1,2
528,17,316,1,4,
528,17,332,4,0,
528,17,347,1,29,2
528,17,347,4,0,
528,17,355,4,0,
528,17,360,4,0,
528,17,369,4,0,
528,17,372,1,1,4
528,17,372,1,12,
528,17,373,4,0,
528,17,403,1,32,
528,17,412,4,0,
528,17,416,4,0,
528,17,433,4,0,
528,17,451,4,0,
528,17,473,4,0,
528,17,496,4,0,
528,17,512,4,0,
528,17,531,1,15,
528,17,590,4,0,
528,18,16,1,1,3
528,18,16,1,8,
528,18,19,4,0,
528,18,63,4,0,
528,18,86,4,0,
528,18,92,4,0,
528,18,93,1,1,1
528,18,94,1,41,
528,18,94,4,0,
528,18,104,4,0,
528,18,113,4,0,
528,18,115,4,0,
528,18,133,1,29,1
528,18,138,4,0,
528,18,156,4,0,
528,18,164,4,0,
528,18,168,4,0,
528,18,182,4,0,
528,18,207,4,0,
528,18,211,4,0,
528,18,213,1,25,
528,18,213,4,0,
528,18,214,4,0,
528,18,216,4,0,
528,18,218,4,0,
528,18,219,4,0,
528,18,237,4,0,
528,18,240,4,0,
528,18,244,4,0,
528,18,247,4,0,
528,18,248,1,36,
528,18,259,4,0,
528,18,263,4,0,
528,18,269,4,0,
528,18,283,1,47,
528,18,286,1,19,
528,18,314,1,21,
528,18,316,1,1,2
528,18,316,1,4,
528,18,332,4,0,
528,18,347,1,29,2
528,18,347,4,0,
528,18,355,4,0,
528,18,360,4,0,
528,18,369,4,0,
528,18,372,1,1,4
528,18,372,1,12,
528,18,373,4,0,
528,18,403,1,32,
528,18,412,4,0,
528,18,416,4,0,
528,18,433,4,0,
528,18,451,4,0,
528,18,473,4,0,
528,18,496,4,0,
528,18,512,4,0,
528,18,531,1,15,
528,18,590,4,0,
529,11,10,1,1,1
529,11,14,1,36,
529,11,14,4,0,
529,11,15,4,0,
529,11,66,2,0,
529,11,70,4,0,
529,11,89,1,33,
529,11,89,4,0,
529,11,90,1,47,
529,11,91,1,19,
529,11,91,4,0,
529,11,92,4,0,
529,11,104,4,0,
529,11,130,2,0,
529,11,154,1,12,
529,11,156,4,0,
529,11,157,1,29,
529,11,157,4,0,
529,11,163,1,26,
529,11,164,4,0,
529,11,182,4,0,
529,11,188,4,0,
529,11,189,1,8,
529,11,201,1,40,
529,11,201,4,0,
529,11,207,4,0,
529,11,213,4,0,
529,11,216,4,0,
529,11,218,4,0,
529,11,229,1,5,
529,11,229,2,0,
529,11,232,1,15,
529,11,237,4,0,
529,11,249,4,0,
529,11,263,4,0,
529,11,280,4,0,
529,11,300,1,1,2
529,11,306,2,0,
529,11,317,4,0,
529,11,319,2,0,
529,11,332,4,0,
529,11,334,2,0,
529,11,374,4,0,
529,11,398,4,0,
529,11,404,4,0,
529,11,414,2,0,
529,11,421,4,0,
529,11,431,2,0,
529,11,468,1,22,
529,11,468,4,0,
529,11,496,4,0,
529,11,523,4,0,
529,11,529,1,43,
529,14,10,1,1,1
529,14,14,1,36,
529,14,14,4,0,
529,14,15,4,0,
529,14,66,2,0,
529,14,70,4,0,
529,14,89,1,33,
529,14,89,4,0,
529,14,90,1,47,
529,14,91,1,19,
529,14,91,4,0,
529,14,92,4,0,
529,14,104,4,0,
529,14,130,2,0,
529,14,154,1,12,
529,14,156,4,0,
529,14,157,1,29,
529,14,157,4,0,
529,14,163,1,26,
529,14,164,4,0,
529,14,173,3,0,
529,14,182,4,0,
529,14,188,4,0,
529,14,189,1,8,
529,14,201,1,40,
529,14,201,4,0,
529,14,207,4,0,
529,14,213,4,0,
529,14,214,3,0,
529,14,216,4,0,
529,14,218,4,0,
529,14,229,1,5,
529,14,229,2,0,
529,14,232,1,15,
529,14,237,4,0,
529,14,249,4,0,
529,14,263,4,0,
529,14,280,4,0,
529,14,300,1,1,2
529,14,306,2,0,
529,14,317,4,0,
529,14,319,2,0,
529,14,332,4,0,
529,14,334,2,0,
529,14,334,3,0,
529,14,374,4,0,
529,14,398,4,0,
529,14,404,4,0,
529,14,414,2,0,
529,14,414,3,0,
529,14,421,4,0,
529,14,431,2,0,
529,14,446,3,0,
529,14,468,1,22,
529,14,468,4,0,
529,14,496,4,0,
529,14,523,4,0,
529,14,529,1,43,
529,14,529,3,0,
529,15,10,1,1,1
529,15,14,1,36,
529,15,14,4,0,
529,15,15,4,0,
529,15,66,2,0,
529,15,70,4,0,
529,15,89,1,33,
529,15,89,4,0,
529,15,90,1,47,
529,15,91,1,19,
529,15,91,4,0,
529,15,92,4,0,
529,15,104,4,0,
529,15,130,2,0,
529,15,154,1,12,
529,15,156,4,0,
529,15,157,1,29,
529,15,157,4,0,
529,15,163,1,26,
529,15,164,4,0,
529,15,182,4,0,
529,15,188,4,0,
529,15,189,1,8,
529,15,201,1,40,
529,15,201,4,0,
529,15,207,4,0,
529,15,213,4,0,
529,15,214,4,0,
529,15,216,4,0,
529,15,218,4,0,
529,15,229,1,5,
529,15,229,2,0,
529,15,232,1,15,
529,15,237,4,0,
529,15,249,4,0,
529,15,263,4,0,
529,15,280,4,0,
529,15,300,1,1,2
529,15,306,2,0,
529,15,317,4,0,
529,15,319,2,0,
529,15,332,4,0,
529,15,334,2,0,
529,15,374,4,0,
529,15,398,4,0,
529,15,404,4,0,
529,15,414,2,0,
529,15,421,4,0,
529,15,431,2,0,
529,15,468,1,22,
529,15,468,4,0,
529,15,496,4,0,
529,15,523,4,0,
529,15,529,1,43,
529,15,590,4,0,
529,16,10,1,1,1
529,16,14,1,36,1
529,16,14,4,0,
529,16,15,4,0,
529,16,66,2,0,
529,16,70,4,0,
529,16,89,1,33,1
529,16,89,4,0,
529,16,90,1,47,1
529,16,91,1,19,1
529,16,91,4,0,
529,16,92,4,0,
529,16,104,4,0,
529,16,130,2,0,
529,16,154,1,12,1
529,16,156,4,0,
529,16,157,1,29,1
529,16,157,4,0,
529,16,163,1,26,1
529,16,164,4,0,
529,16,173,3,0,
529,16,182,4,0,
529,16,188,4,0,
529,16,189,1,8,1
529,16,201,1,40,1
529,16,201,4,0,
529,16,207,4,0,
529,16,213,4,0,
529,16,214,4,0,
529,16,216,4,0,
529,16,218,4,0,
529,16,229,1,5,1
529,16,229,2,0,
529,16,232,1,15,1
529,16,237,4,0,
529,16,249,4,0,
529,16,263,4,0,
529,16,280,4,0,
529,16,290,4,0,
529,16,300,1,1,2
529,16,306,2,0,
529,16,317,4,0,
529,16,319,2,0,
529,16,332,4,0,
529,16,334,2,0,
529,16,334,3,0,
529,16,374,4,0,
529,16,398,4,0,
529,16,404,4,0,
529,16,414,2,0,
529,16,414,3,0,
529,16,421,4,0,
529,16,431,2,0,
529,16,446,3,0,
529,16,468,1,22,1
529,16,468,4,0,
529,16,496,4,0,
529,16,523,4,0,
529,16,529,1,43,1
529,16,529,3,0,
529,16,590,4,0,
529,17,10,1,1,1
529,17,14,1,36,
529,17,14,4,0,
529,17,66,2,0,
529,17,89,1,33,
529,17,89,4,0,
529,17,90,1,47,
529,17,91,1,19,
529,17,92,4,0,
529,17,104,4,0,
529,17,130,2,0,
529,17,154,1,12,
529,17,156,4,0,
529,17,157,1,29,
529,17,157,4,0,
529,17,163,1,26,
529,17,164,4,0,
529,17,182,4,0,
529,17,188,4,0,
529,17,189,1,8,
529,17,201,1,40,
529,17,201,4,0,
529,17,207,4,0,
529,17,213,4,0,
529,17,214,4,0,
529,17,216,4,0,
529,17,218,4,0,
529,17,229,1,5,
529,17,229,2,0,
529,17,232,1,15,
529,17,237,4,0,
529,17,263,4,0,
529,17,280,4,0,
529,17,300,1,1,2
529,17,306,2,0,
529,17,317,4,0,
529,17,319,2,0,
529,17,332,4,0,
529,17,334,2,0,
529,17,374,4,0,
529,17,398,4,0,
529,17,404,4,0,
529,17,414,2,0,
529,17,421,4,0,
529,17,431,2,0,
529,17,468,1,22,
529,17,496,4,0,
529,17,523,4,0,
529,17,529,1,43,
529,17,590,4,0,
529,18,10,1,1,1
529,18,14,1,36,
529,18,14,4,0,
529,18,66,2,0,
529,18,89,1,33,
529,18,89,4,0,
529,18,90,1,47,
529,18,91,1,19,
529,18,92,4,0,
529,18,104,4,0,
529,18,130,2,0,
529,18,154,1,12,
529,18,156,4,0,
529,18,157,1,29,
529,18,157,4,0,
529,18,163,1,26,
529,18,164,4,0,
529,18,182,4,0,
529,18,188,4,0,
529,18,189,1,8,
529,18,201,1,40,
529,18,201,4,0,
529,18,207,4,0,
529,18,213,4,0,
529,18,214,4,0,
529,18,216,4,0,
529,18,218,4,0,
529,18,229,1,5,
529,18,229,2,0,
529,18,232,1,15,
529,18,237,4,0,
529,18,263,4,0,
529,18,280,4,0,
529,18,300,1,1,2
529,18,306,2,0,
529,18,317,4,0,
529,18,319,2,0,
529,18,332,4,0,
529,18,334,2,0,
529,18,374,4,0,
529,18,398,4,0,
529,18,404,4,0,
529,18,414,2,0,
529,18,421,4,0,
529,18,431,2,0,
529,18,468,1,22,
529,18,496,4,0,
529,18,523,4,0,
529,18,529,1,43,
529,18,590,4,0,
530,11,10,1,1,1
530,11,14,1,42,
530,11,14,4,0,
530,11,15,4,0,
530,11,32,1,31,
530,11,63,4,0,
530,11,70,4,0,
530,11,89,1,36,
530,11,89,4,0,
530,11,90,1,62,
530,11,91,1,19,
530,11,91,4,0,
530,11,92,4,0,
530,11,104,4,0,
530,11,154,1,12,
530,11,156,4,0,
530,11,157,1,29,
530,11,157,4,0,
530,11,163,1,26,
530,11,164,4,0,
530,11,182,4,0,
530,11,188,4,0,
530,11,189,1,1,4
530,11,189,1,8,
530,11,201,1,49,
530,11,201,4,0,
530,11,207,4,0,
530,11,213,4,0,
530,11,216,4,0,
530,11,218,4,0,
530,11,229,1,1,3
530,11,229,1,5,
530,11,232,1,15,
530,11,237,4,0,
530,11,249,4,0,
530,11,263,4,0,
530,11,280,4,0,
530,11,300,1,1,2
530,11,317,4,0,
530,11,332,4,0,
530,11,374,4,0,
530,11,398,4,0,
530,11,404,4,0,
530,11,411,4,0,
530,11,416,4,0,
530,11,421,4,0,
530,11,468,1,22,
530,11,468,4,0,
530,11,496,4,0,
530,11,523,4,0,
530,11,529,1,55,
530,14,10,1,1,1
530,14,14,1,42,
530,14,14,4,0,
530,14,15,4,0,
530,14,32,1,31,
530,14,63,4,0,
530,14,70,4,0,
530,14,89,1,36,
530,14,89,4,0,
530,14,90,1,62,
530,14,91,1,19,
530,14,91,4,0,
530,14,92,4,0,
530,14,104,4,0,
530,14,154,1,12,
530,14,156,4,0,
530,14,157,1,29,
530,14,157,4,0,
530,14,163,1,26,
530,14,164,4,0,
530,14,173,3,0,
530,14,182,4,0,
530,14,188,4,0,
530,14,189,1,1,4
530,14,189,1,8,
530,14,201,1,49,
530,14,201,4,0,
530,14,207,4,0,
530,14,213,4,0,
530,14,214,3,0,
530,14,216,4,0,
530,14,218,4,0,
530,14,229,1,1,3
530,14,229,1,5,
530,14,232,1,15,
530,14,237,4,0,
530,14,249,4,0,
530,14,263,4,0,
530,14,280,4,0,
530,14,300,1,1,2
530,14,317,4,0,
530,14,332,4,0,
530,14,334,3,0,
530,14,374,4,0,
530,14,393,3,0,
530,14,398,4,0,
530,14,404,4,0,
530,14,411,4,0,
530,14,414,3,0,
530,14,416,4,0,
530,14,421,4,0,
530,14,442,3,0,
530,14,446,3,0,
530,14,468,1,22,
530,14,468,4,0,
530,14,496,4,0,
530,14,523,4,0,
530,14,529,1,55,
530,14,529,3,0,
530,15,10,1,1,2
530,15,14,1,42,
530,15,14,4,0,
530,15,15,4,0,
530,15,32,1,31,
530,15,63,4,0,
530,15,70,4,0,
530,15,89,1,36,
530,15,89,4,0,
530,15,90,1,62,
530,15,91,1,19,
530,15,91,4,0,
530,15,92,4,0,
530,15,104,4,0,
530,15,154,1,12,
530,15,156,4,0,
530,15,157,1,29,
530,15,157,4,0,
530,15,163,1,26,
530,15,164,4,0,
530,15,182,4,0,
530,15,188,4,0,
530,15,189,1,1,5
530,15,189,1,8,
530,15,201,1,49,
530,15,201,4,0,
530,15,207,4,0,
530,15,213,4,0,
530,15,214,4,0,
530,15,216,4,0,
530,15,218,4,0,
530,15,229,1,1,4
530,15,229,1,5,
530,15,232,1,15,
530,15,237,4,0,
530,15,249,4,0,
530,15,263,4,0,
530,15,280,4,0,
530,15,300,1,1,3
530,15,317,4,0,
530,15,332,4,0,
530,15,374,4,0,
530,15,398,4,0,
530,15,404,4,0,
530,15,411,4,0,
530,15,416,4,0,
530,15,421,4,0,
530,15,468,1,22,
530,15,468,4,0,
530,15,496,4,0,
530,15,523,4,0,
530,15,529,1,55,
530,15,563,1,1,1
530,15,590,4,0,
530,16,10,1,1,2
530,16,14,1,42,1
530,16,14,4,0,
530,16,15,4,0,
530,16,32,1,31,1
530,16,63,4,0,
530,16,70,4,0,
530,16,89,1,36,1
530,16,89,4,0,
530,16,90,1,62,1
530,16,91,1,19,1
530,16,91,4,0,
530,16,92,4,0,
530,16,104,4,0,
530,16,154,1,12,1
530,16,156,4,0,
530,16,157,1,29,1
530,16,157,4,0,
530,16,163,1,26,1
530,16,164,4,0,
530,16,173,3,0,
530,16,182,4,0,
530,16,188,4,0,
530,16,189,1,1,5
530,16,189,1,8,1
530,16,201,1,49,1
530,16,201,4,0,
530,16,207,4,0,
530,16,213,4,0,
530,16,214,4,0,
530,16,216,4,0,
530,16,218,4,0,
530,16,229,1,1,4
530,16,229,1,5,1
530,16,232,1,15,1
530,16,237,4,0,
530,16,249,4,0,
530,16,263,4,0,
530,16,280,4,0,
530,16,290,4,0,
530,16,300,1,1,3
530,16,317,4,0,
530,16,332,4,0,
530,16,334,3,0,
530,16,374,4,0,
530,16,393,3,0,
530,16,398,4,0,
530,16,404,4,0,
530,16,411,4,0,
530,16,414,3,0,
530,16,416,4,0,
530,16,421,4,0,
530,16,442,3,0,
530,16,446,3,0,
530,16,468,1,22,1
530,16,468,4,0,
530,16,496,4,0,
530,16,523,4,0,
530,16,529,1,55,1
530,16,529,3,0,
530,16,563,1,1,1
530,16,590,4,0,
530,17,10,1,1,3
530,17,14,1,42,
530,17,14,4,0,
530,17,32,1,0,
530,17,32,1,1,1
530,17,63,4,0,
530,17,89,1,36,
530,17,89,4,0,
530,17,90,1,62,
530,17,91,1,19,
530,17,92,4,0,
530,17,104,4,0,
530,17,154,1,12,
530,17,156,4,0,
530,17,157,1,29,
530,17,157,4,0,
530,17,163,1,26,
530,17,164,4,0,
530,17,182,4,0,
530,17,188,4,0,
530,17,189,1,1,6
530,17,189,1,8,
530,17,201,1,49,
530,17,201,4,0,
530,17,207,4,0,
530,17,213,4,0,
530,17,214,4,0,
530,17,216,4,0,
530,17,218,4,0,
530,17,229,1,1,5
530,17,229,1,5,
530,17,232,1,15,
530,17,237,4,0,
530,17,263,4,0,
530,17,280,4,0,
530,17,300,1,1,4
530,17,317,4,0,
530,17,332,4,0,
530,17,374,4,0,
530,17,398,4,0,
530,17,404,4,0,
530,17,411,4,0,
530,17,416,4,0,
530,17,421,4,0,
530,17,468,1,22,
530,17,496,4,0,
530,17,523,4,0,
530,17,529,1,55,
530,17,563,1,1,2
530,17,590,4,0,
530,17,684,4,0,
530,17,693,4,0,
530,18,10,1,1,3
530,18,14,1,42,
530,18,14,4,0,
530,18,32,1,0,
530,18,32,1,1,1
530,18,63,4,0,
530,18,89,1,36,
530,18,89,4,0,
530,18,90,1,62,
530,18,91,1,19,
530,18,92,4,0,
530,18,104,4,0,
530,18,154,1,12,
530,18,156,4,0,
530,18,157,1,29,
530,18,157,4,0,
530,18,163,1,26,
530,18,164,4,0,
530,18,182,4,0,
530,18,188,4,0,
530,18,189,1,1,6
530,18,189,1,8,
530,18,201,1,49,
530,18,201,4,0,
530,18,207,4,0,
530,18,213,4,0,
530,18,214,4,0,
530,18,216,4,0,
530,18,218,4,0,
530,18,229,1,1,5
530,18,229,1,5,
530,18,232,1,15,
530,18,237,4,0,
530,18,263,4,0,
530,18,280,4,0,
530,18,300,1,1,4
530,18,317,4,0,
530,18,332,4,0,
530,18,374,4,0,
530,18,398,4,0,
530,18,404,4,0,
530,18,411,4,0,
530,18,416,4,0,
530,18,421,4,0,
530,18,468,1,22,
530,18,496,4,0,
530,18,523,4,0,
530,18,529,1,55,
530,18,563,1,1,2
530,18,590,4,0,
530,18,684,4,0,
530,18,693,4,0,
531,11,1,1,1,1
531,11,3,1,10,
531,11,36,1,30,
531,11,38,1,50,
531,11,45,1,1,2
531,11,53,4,0,
531,11,57,4,0,
531,11,58,4,0,
531,11,59,4,0,
531,11,63,4,0,
531,11,76,4,0,
531,11,85,4,0,
531,11,86,4,0,
531,11,87,4,0,
531,11,91,4,0,
531,11,92,4,0,
531,11,94,4,0,
531,11,104,4,0,
531,11,113,4,0,
531,11,115,4,0,
531,11,126,4,0,
531,11,133,2,0,
531,11,138,4,0,
531,11,148,4,0,
531,11,156,4,0,
531,11,164,4,0,
531,11,182,4,0,
531,11,186,2,0,
531,11,207,4,0,
531,11,213,1,15,
531,11,213,4,0,
531,11,214,2,0,
531,11,215,2,0,
531,11,216,4,0,
531,11,218,4,0,
531,11,219,4,0,
531,11,227,2,0,
531,11,237,4,0,
531,11,240,4,0,
531,11,241,4,0,
531,11,244,4,0,
531,11,247,4,0,
531,11,263,4,0,
531,11,270,1,1,3
531,11,273,2,0,
531,11,281,2,0,
531,11,287,1,5,
531,11,290,1,20,
531,11,347,4,0,
531,11,361,2,0,
531,11,374,4,0,
531,11,381,2,0,
531,11,387,1,55,
531,11,433,4,0,
531,11,447,4,0,
531,11,451,4,0,
531,11,473,4,0,
531,11,477,4,0,
531,11,493,1,45,
531,11,494,1,25,
531,11,495,1,40,
531,11,496,4,0,
531,11,497,4,0,
531,11,505,1,35,
531,11,510,4,0,
531,11,514,4,0,
531,11,516,2,0,
531,11,526,4,0,
531,11,528,4,0,
531,14,1,1,1,1
531,14,3,1,10,
531,14,7,3,0,
531,14,8,3,0,
531,14,9,3,0,
531,14,36,1,30,
531,14,38,1,50,
531,14,45,1,1,2
531,14,53,4,0,
531,14,57,4,0,
531,14,58,4,0,
531,14,59,4,0,
531,14,63,4,0,
531,14,67,3,0,
531,14,76,4,0,
531,14,85,4,0,
531,14,86,4,0,
531,14,87,4,0,
531,14,91,4,0,
531,14,92,4,0,
531,14,94,4,0,
531,14,104,4,0,
531,14,113,4,0,
531,14,115,4,0,
531,14,126,4,0,
531,14,133,2,0,
531,14,138,4,0,
531,14,148,4,0,
531,14,156,4,0,
531,14,164,4,0,
531,14,173,3,0,
531,14,182,4,0,
531,14,186,2,0,
531,14,196,3,0,
531,14,207,4,0,
531,14,213,1,15,
531,14,213,4,0,
531,14,214,2,0,
531,14,214,3,0,
531,14,215,2,0,
531,14,215,3,0,
531,14,216,4,0,
531,14,218,4,0,
531,14,219,4,0,
531,14,220,3,0,
531,14,227,2,0,
531,14,231,3,0,
531,14,237,4,0,
531,14,240,4,0,
531,14,241,4,0,
531,14,244,4,0,
531,14,247,4,0,
531,14,253,3,0,
531,14,263,4,0,
531,14,270,1,1,3
531,14,270,3,0,
531,14,272,3,0,
531,14,273,2,0,
531,14,277,3,0,
531,14,281,2,0,
531,14,282,3,0,
531,14,285,3,0,
531,14,287,1,5,
531,14,289,3,0,
531,14,290,1,20,
531,14,304,3,0,
531,14,324,3,0,
531,14,343,3,0,
531,14,347,4,0,
531,14,356,3,0,
531,14,361,2,0,
531,14,374,4,0,
531,14,381,2,0,
531,14,387,1,55,
531,14,387,3,0,
531,14,409,3,0,
531,14,428,3,0,
531,14,433,4,0,
531,14,447,4,0,
531,14,451,4,0,
531,14,473,4,0,
531,14,477,4,0,
531,14,493,1,45,
531,14,494,1,25,
531,14,495,1,40,
531,14,495,3,0,
531,14,496,4,0,
531,14,497,4,0,
531,14,505,1,35,
531,14,510,4,0,
531,14,514,4,0,
531,14,516,2,0,
531,14,526,4,0,
531,14,528,4,0,
531,15,1,1,1,3
531,15,3,1,10,
531,15,36,1,30,
531,15,38,1,50,
531,15,45,1,1,4
531,15,53,4,0,
531,15,57,4,0,
531,15,58,4,0,
531,15,59,4,0,
531,15,63,4,0,
531,15,76,4,0,
531,15,85,4,0,
531,15,86,4,0,
531,15,87,4,0,
531,15,91,4,0,
531,15,92,4,0,
531,15,94,4,0,
531,15,104,4,0,
531,15,113,4,0,
531,15,115,4,0,
531,15,126,4,0,
531,15,133,2,0,
531,15,138,4,0,
531,15,148,4,0,
531,15,156,4,0,
531,15,164,4,0,
531,15,182,4,0,
531,15,186,2,0,
531,15,207,4,0,
531,15,213,1,15,
531,15,213,4,0,
531,15,214,2,0,
531,15,214,4,0,
531,15,215,2,0,
531,15,216,4,0,
531,15,218,4,0,
531,15,219,4,0,
531,15,227,2,0,
531,15,237,4,0,
531,15,240,4,0,
531,15,241,4,0,
531,15,244,4,0,
531,15,247,4,0,
531,15,263,4,0,
531,15,270,1,1,5
531,15,273,2,0,
531,15,281,2,0,
531,15,287,1,5,
531,15,290,1,20,
531,15,347,4,0,
531,15,361,2,0,
531,15,374,4,0,
531,15,381,2,0,
531,15,387,1,1,1
531,15,387,1,55,
531,15,433,4,0,
531,15,447,4,0,
531,15,451,4,0,
531,15,473,4,0,
531,15,493,1,45,
531,15,494,1,25,
531,15,495,1,40,
531,15,496,4,0,
531,15,497,4,0,
531,15,505,1,35,
531,15,510,4,0,
531,15,514,4,0,
531,15,516,2,0,
531,15,528,4,0,
531,15,577,2,0,
531,15,589,1,1,2
531,15,590,4,0,
531,15,605,4,0,
531,15,612,4,0,
531,16,1,1,1,4
531,16,3,1,17,1
531,16,7,3,0,
531,16,8,3,0,
531,16,9,3,0,
531,16,36,1,33,1
531,16,38,1,49,1
531,16,45,1,1,5
531,16,53,4,0,
531,16,57,4,0,
531,16,58,4,0,
531,16,59,4,0,
531,16,63,4,0,
531,16,67,3,0,
531,16,76,4,0,
531,16,85,4,0,
531,16,86,4,0,
531,16,87,4,0,
531,16,91,4,0,
531,16,92,4,0,
531,16,94,4,0,
531,16,104,4,0,
531,16,113,4,0,
531,16,115,4,0,
531,16,126,4,0,
531,16,133,2,0,
531,16,138,4,0,
531,16,148,4,0,
531,16,156,4,0,
531,16,164,4,0,
531,16,173,3,0,
531,16,182,4,0,
531,16,186,2,0,
531,16,196,3,0,
531,16,207,4,0,
531,16,213,1,21,1
531,16,213,4,0,
531,16,214,2,0,
531,16,214,4,0,
531,16,215,2,0,
531,16,215,3,0,
531,16,216,4,0,
531,16,218,4,0,
531,16,219,4,0,
531,16,220,3,0,
531,16,227,2,0,
531,16,231,3,0,
531,16,237,4,0,
531,16,240,4,0,
531,16,241,4,0,
531,16,244,4,0,
531,16,247,4,0,
531,16,253,3,0,
531,16,263,4,0,
531,16,264,3,0,
531,16,270,1,1,6
531,16,270,3,0,
531,16,272,3,0,
531,16,273,2,0,
531,16,277,3,0,
531,16,281,2,0,
531,16,282,3,0,
531,16,285,3,0,
531,16,287,1,9,1
531,16,289,3,0,
531,16,290,1,25,1
531,16,290,4,0,
531,16,304,3,0,
531,16,324,3,0,
531,16,343,3,0,
531,16,347,4,0,
531,16,356,3,0,
531,16,361,2,0,
531,16,374,4,0,
531,16,381,2,0,
531,16,387,1,1,1
531,16,387,1,53,1
531,16,387,3,0,
531,16,409,3,0,
531,16,428,3,0,
531,16,433,4,0,
531,16,447,4,0,
531,16,451,4,0,
531,16,473,4,0,
531,16,493,1,45,1
531,16,494,1,29,1
531,16,495,1,41,1
531,16,495,3,0,
531,16,496,4,0,
531,16,497,4,0,
531,16,505,1,37,1
531,16,510,4,0,
531,16,514,4,0,
531,16,516,2,0,
531,16,528,4,0,
531,16,574,1,13,1
531,16,577,2,0,
531,16,581,1,1,2
531,16,589,1,1,3
531,16,590,4,0,
531,16,605,4,0,
531,16,608,1,5,1
531,16,612,4,0,
531,17,1,1,1,5
531,17,3,1,17,
531,17,36,1,33,
531,17,38,1,49,
531,17,45,1,1,6
531,17,53,4,0,
531,17,57,4,0,
531,17,58,4,0,
531,17,59,4,0,
531,17,63,4,0,
531,17,76,4,0,
531,17,85,4,0,
531,17,86,4,0,
531,17,87,4,0,
531,17,92,4,0,
531,17,94,4,0,
531,17,104,4,0,
531,17,113,4,0,
531,17,115,4,0,
531,17,126,4,0,
531,17,133,2,0,
531,17,138,4,0,
531,17,156,4,0,
531,17,164,4,0,
531,17,182,4,0,
531,17,186,2,0,
531,17,207,4,0,
531,17,213,1,21,
531,17,213,4,0,
531,17,214,2,0,
531,17,214,4,0,
531,17,215,2,0,
531,17,216,4,0,
531,17,218,4,0,
531,17,219,4,0,
531,17,227,2,0,
531,17,237,4,0,
531,17,240,4,0,
531,17,241,4,0,
531,17,244,4,0,
531,17,247,4,0,
531,17,263,4,0,
531,17,270,1,1,7
531,17,273,2,0,
531,17,281,2,0,
531,17,287,1,9,
531,17,290,1,25,
531,17,304,1,1,2
531,17,304,1,53,
531,17,347,4,0,
531,17,361,2,0,
531,17,374,4,0,
531,17,381,2,0,
531,17,387,1,1,1
531,17,387,1,57,
531,17,433,4,0,
531,17,447,4,0,
531,17,451,4,0,
531,17,473,4,0,
531,17,493,1,45,
531,17,494,1,29,
531,17,495,1,41,
531,17,496,4,0,
531,17,497,4,0,
531,17,505,1,37,
531,17,516,2,0,
531,17,526,4,0,
531,17,528,4,0,
531,17,574,1,13,
531,17,577,2,0,
531,17,581,1,1,3
531,17,589,1,1,4
531,17,590,4,0,
531,17,605,4,0,
531,17,608,1,5,
531,18,1,1,1,5
531,18,3,1,17,
531,18,36,1,33,
531,18,38,1,49,
531,18,45,1,1,6
531,18,53,4,0,
531,18,57,4,0,
531,18,58,4,0,
531,18,59,4,0,
531,18,63,4,0,
531,18,76,4,0,
531,18,85,4,0,
531,18,86,4,0,
531,18,87,4,0,
531,18,92,4,0,
531,18,94,4,0,
531,18,104,4,0,
531,18,113,4,0,
531,18,115,4,0,
531,18,126,4,0,
531,18,133,2,0,
531,18,138,4,0,
531,18,156,4,0,
531,18,164,4,0,
531,18,182,4,0,
531,18,186,2,0,
531,18,207,4,0,
531,18,213,1,21,
531,18,213,4,0,
531,18,214,2,0,
531,18,214,4,0,
531,18,215,2,0,
531,18,216,4,0,
531,18,218,4,0,
531,18,219,4,0,
531,18,227,2,0,
531,18,237,4,0,
531,18,240,4,0,
531,18,241,4,0,
531,18,244,4,0,
531,18,247,4,0,
531,18,263,4,0,
531,18,270,1,1,7
531,18,273,2,0,
531,18,281,2,0,
531,18,287,1,9,
531,18,290,1,25,
531,18,304,1,1,2
531,18,304,1,53,
531,18,347,4,0,
531,18,361,2,0,
531,18,374,4,0,
531,18,381,2,0,
531,18,387,1,1,1
531,18,387,1,57,
531,18,433,4,0,
531,18,447,4,0,
531,18,451,4,0,
531,18,473,4,0,
531,18,493,1,45,
531,18,494,1,29,
531,18,495,1,41,
531,18,496,4,0,
531,18,497,4,0,
531,18,505,1,37,
531,18,516,2,0,
531,18,526,4,0,
531,18,528,4,0,
531,18,574,1,13,
531,18,577,2,0,
531,18,581,1,1,3
531,18,589,1,1,4
531,18,590,4,0,
531,18,605,4,0,
531,18,608,1,5,
532,11,1,1,1,1
532,11,4,2,0,
532,11,43,1,1,2
532,11,67,1,12,
532,11,68,2,0,
532,11,70,4,0,
532,11,88,1,16,
532,11,91,4,0,
532,11,92,4,0,
532,11,104,4,0,
532,11,116,1,4,
532,11,117,1,8,
532,11,156,4,0,
532,11,157,1,31,
532,11,157,4,0,
532,11,164,4,0,
532,11,179,2,0,
532,11,182,4,0,
532,11,183,2,0,
532,11,184,1,37,
532,11,193,2,0,
532,11,197,2,0,
532,11,203,2,0,
532,11,207,4,0,
532,11,213,4,0,
532,11,216,4,0,
532,11,218,4,0,
532,11,223,1,34,
532,11,237,4,0,
532,11,240,4,0,
532,11,241,4,0,
532,11,249,4,0,
532,11,263,4,0,
532,11,264,1,46,
532,11,265,2,0,
532,11,269,4,0,
532,11,276,1,49,
532,11,280,4,0,
532,11,317,4,0,
532,11,339,1,28,
532,11,339,4,0,
532,11,358,1,20,
532,11,359,1,40,
532,11,371,4,0,
532,11,374,4,0,
532,11,395,2,0,
532,11,398,4,0,
532,11,409,2,0,
532,11,411,4,0,
532,11,444,1,43,
532,11,444,4,0,
532,11,447,4,0,
532,11,469,2,0,
532,11,479,4,0,
532,11,490,4,0,
532,11,496,4,0,
532,11,498,1,24,
532,11,514,4,0,
532,11,526,4,0,
532,14,1,1,1,1
532,14,4,2,0,
532,14,7,3,0,
532,14,8,3,0,
532,14,9,3,0,
532,14,43,1,1,2
532,14,67,1,12,
532,14,67,3,0,
532,14,68,2,0,
532,14,70,4,0,
532,14,88,1,16,
532,14,91,4,0,
532,14,92,4,0,
532,14,104,4,0,
532,14,116,1,4,
532,14,117,1,8,
532,14,156,4,0,
532,14,157,1,31,
532,14,157,4,0,
532,14,164,4,0,
532,14,173,3,0,
532,14,179,2,0,
532,14,182,4,0,
532,14,183,2,0,
532,14,184,1,37,
532,14,193,2,0,
532,14,197,2,0,
532,14,203,2,0,
532,14,207,4,0,
532,14,213,4,0,
532,14,214,3,0,
532,14,216,4,0,
532,14,218,4,0,
532,14,223,1,34,
532,14,237,4,0,
532,14,240,4,0,
532,14,241,4,0,
532,14,249,4,0,
532,14,263,4,0,
532,14,264,1,46,
532,14,265,2,0,
532,14,269,4,0,
532,14,270,3,0,
532,14,276,1,49,
532,14,276,3,0,
532,14,280,4,0,
532,14,282,3,0,
532,14,317,4,0,
532,14,335,3,0,
532,14,339,1,28,
532,14,339,4,0,
532,14,358,1,20,
532,14,359,1,40,
532,14,371,4,0,
532,14,374,4,0,
532,14,395,2,0,
532,14,398,4,0,
532,14,409,2,0,
532,14,409,3,0,
532,14,411,4,0,
532,14,444,1,43,
532,14,444,4,0,
532,14,447,4,0,
532,14,469,2,0,
532,14,479,4,0,
532,14,490,4,0,
532,14,496,4,0,
532,14,498,1,24,
532,14,514,4,0,
532,14,526,4,0,
532,15,1,1,1,1
532,15,4,2,0,
532,15,43,1,1,2
532,15,67,1,12,
532,15,68,2,0,
532,15,70,4,0,
532,15,88,1,16,
532,15,91,4,0,
532,15,92,4,0,
532,15,104,4,0,
532,15,116,1,4,
532,15,117,1,8,
532,15,156,4,0,
532,15,157,1,31,
532,15,157,4,0,
532,15,164,4,0,
532,15,179,2,0,
532,15,182,4,0,
532,15,183,2,0,
532,15,184,1,37,
532,15,193,2,0,
532,15,197,2,0,
532,15,203,2,0,
532,15,207,4,0,
532,15,213,4,0,
532,15,214,4,0,
532,15,216,4,0,
532,15,218,4,0,
532,15,223,1,34,
532,15,237,4,0,
532,15,240,4,0,
532,15,241,4,0,
532,15,249,4,0,
532,15,263,4,0,
532,15,264,1,46,
532,15,265,2,0,
532,15,269,4,0,
532,15,276,1,49,
532,15,280,4,0,
532,15,317,4,0,
532,15,339,1,28,
532,15,339,4,0,
532,15,358,1,20,
532,15,359,1,40,
532,15,371,4,0,
532,15,374,4,0,
532,15,395,2,0,
532,15,398,4,0,
532,15,409,2,0,
532,15,411,4,0,
532,15,444,1,43,
532,15,444,4,0,
532,15,447,4,0,
532,15,469,2,0,
532,15,479,4,0,
532,15,490,4,0,
532,15,496,4,0,
532,15,498,1,24,
532,15,514,4,0,
532,15,590,4,0,
532,15,612,4,0,
532,16,1,1,1,1
532,16,4,2,0,
532,16,7,3,0,
532,16,8,3,0,
532,16,9,3,0,
532,16,43,1,1,2
532,16,67,1,12,1
532,16,67,3,0,
532,16,68,2,0,
532,16,70,4,0,
532,16,88,1,16,1
532,16,91,4,0,
532,16,92,4,0,
532,16,104,4,0,
532,16,116,1,4,1
532,16,117,1,8,1
532,16,156,4,0,
532,16,157,1,31,1
532,16,157,4,0,
532,16,164,4,0,
532,16,173,3,0,
532,16,179,2,0,
532,16,182,4,0,
532,16,183,2,0,
532,16,184,1,37,1
532,16,193,2,0,
532,16,197,2,0,
532,16,203,2,0,
532,16,207,4,0,
532,16,213,4,0,
532,16,214,4,0,
532,16,216,4,0,
532,16,218,4,0,
532,16,223,1,34,1
532,16,237,4,0,
532,16,240,4,0,
532,16,241,4,0,
532,16,249,4,0,
532,16,263,4,0,
532,16,264,1,46,1
532,16,264,3,0,
532,16,265,2,0,
532,16,269,4,0,
532,16,270,3,0,
532,16,276,1,49,1
532,16,276,3,0,
532,16,280,4,0,
532,16,282,3,0,
532,16,290,4,0,
532,16,317,4,0,
532,16,335,3,0,
532,16,339,1,28,1
532,16,339,4,0,
532,16,358,1,20,1
532,16,359,1,40,1
532,16,371,4,0,
532,16,374,4,0,
532,16,395,2,0,
532,16,398,4,0,
532,16,409,2,0,
532,16,409,3,0,
532,16,411,4,0,
532,16,444,1,43,1
532,16,444,4,0,
532,16,447,4,0,
532,16,469,2,0,
532,16,479,4,0,
532,16,490,4,0,
532,16,496,4,0,
532,16,498,1,24,1
532,16,514,4,0,
532,16,590,4,0,
532,16,612,4,0,
532,17,1,1,1,1
532,17,4,2,0,
532,17,43,1,1,2
532,17,67,1,12,
532,17,68,2,0,
532,17,88,1,16,
532,17,92,4,0,
532,17,104,4,0,
532,17,116,1,4,
532,17,117,1,8,
532,17,156,4,0,
532,17,157,1,31,
532,17,157,4,0,
532,17,164,4,0,
532,17,179,2,0,
532,17,182,4,0,
532,17,183,2,0,
532,17,184,1,37,
532,17,193,2,0,
532,17,197,2,0,
532,17,203,2,0,
532,17,207,4,0,
532,17,213,4,0,
532,17,214,4,0,
532,17,216,4,0,
532,17,218,4,0,
532,17,223,1,34,
532,17,237,4,0,
532,17,240,4,0,
532,17,241,4,0,
532,17,263,4,0,
532,17,264,1,46,
532,17,265,2,0,
532,17,269,4,0,
532,17,276,1,49,
532,17,280,4,0,
532,17,317,4,0,
532,17,339,1,28,
532,17,339,4,0,
532,17,358,1,20,
532,17,359,1,40,
532,17,371,4,0,
532,17,374,4,0,
532,17,395,2,0,
532,17,398,4,0,
532,17,409,2,0,
532,17,411,4,0,
532,17,444,1,43,
532,17,444,4,0,
532,17,447,4,0,
532,17,469,2,0,
532,17,479,4,0,
532,17,490,4,0,
532,17,496,4,0,
532,17,498,1,24,
532,17,526,4,0,
532,17,590,4,0,
532,17,693,4,0,
532,18,1,1,1,1
532,18,4,2,0,
532,18,43,1,1,2
532,18,67,1,12,
532,18,68,2,0,
532,18,88,1,16,
532,18,92,4,0,
532,18,104,4,0,
532,18,116,1,4,
532,18,117,1,8,
532,18,156,4,0,
532,18,157,1,31,
532,18,157,4,0,
532,18,164,4,0,
532,18,179,2,0,
532,18,182,4,0,
532,18,183,2,0,
532,18,184,1,37,
532,18,193,2,0,
532,18,197,2,0,
532,18,203,2,0,
532,18,207,4,0,
532,18,213,4,0,
532,18,214,4,0,
532,18,216,4,0,
532,18,218,4,0,
532,18,223,1,34,
532,18,237,4,0,
532,18,240,4,0,
532,18,241,4,0,
532,18,263,4,0,
532,18,264,1,46,
532,18,265,2,0,
532,18,269,4,0,
532,18,276,1,49,
532,18,280,4,0,
532,18,317,4,0,
532,18,339,1,28,
532,18,339,4,0,
532,18,358,1,20,
532,18,359,1,40,
532,18,371,4,0,
532,18,374,4,0,
532,18,395,2,0,
532,18,398,4,0,
532,18,409,2,0,
532,18,411,4,0,
532,18,444,1,43,
532,18,444,4,0,
532,18,447,4,0,
532,18,469,2,0,
532,18,479,4,0,
532,18,490,4,0,
532,18,496,4,0,
532,18,498,1,24,
532,18,526,4,0,
532,18,590,4,0,
532,18,612,2,0,
532,18,693,4,0,
533,11,1,1,1,1
533,11,43,1,1,2
533,11,67,1,12,
533,11,70,4,0,
533,11,88,1,16,
533,11,91,4,0,
533,11,92,4,0,
533,11,104,4,0,
533,11,116,1,1,3
533,11,116,1,4,
533,11,117,1,1,4
533,11,117,1,8,
533,11,156,4,0,
533,11,157,1,33,
533,11,157,4,0,
533,11,164,4,0,
533,11,182,4,0,
533,11,184,1,41,
533,11,207,4,0,
533,11,213,4,0,
533,11,216,4,0,
533,11,218,4,0,
533,11,223,1,37,
533,11,237,4,0,
533,11,240,4,0,
533,11,241,4,0,
533,11,249,4,0,
533,11,263,4,0,
533,11,264,1,53,
533,11,269,4,0,
533,11,276,1,57,
533,11,280,4,0,
533,11,317,4,0,
533,11,339,1,29,
533,11,339,4,0,
533,11,358,1,20,
533,11,359,1,45,
533,11,371,4,0,
533,11,374,4,0,
533,11,398,4,0,
533,11,411,4,0,
533,11,444,1,49,
533,11,444,4,0,
533,11,447,4,0,
533,11,479,4,0,
533,11,490,4,0,
533,11,496,4,0,
533,11,498,1,24,
533,11,514,4,0,
533,11,526,4,0,
533,14,1,1,1,1
533,14,7,3,0,
533,14,8,3,0,
533,14,9,3,0,
533,14,43,1,1,2
533,14,67,1,12,
533,14,67,3,0,
533,14,70,4,0,
533,14,88,1,16,
533,14,91,4,0,
533,14,92,4,0,
533,14,104,4,0,
533,14,116,1,1,3
533,14,116,1,4,
533,14,117,1,1,4
533,14,117,1,8,
533,14,156,4,0,
533,14,157,1,33,
533,14,157,4,0,
533,14,164,4,0,
533,14,173,3,0,
533,14,182,4,0,
533,14,184,1,41,
533,14,207,4,0,
533,14,213,4,0,
533,14,214,3,0,
533,14,216,4,0,
533,14,218,4,0,
533,14,223,1,37,
533,14,237,4,0,
533,14,240,4,0,
533,14,241,4,0,
533,14,249,4,0,
533,14,263,4,0,
533,14,264,1,53,
533,14,269,4,0,
533,14,270,3,0,
533,14,276,1,57,
533,14,276,3,0,
533,14,280,4,0,
533,14,282,3,0,
533,14,317,4,0,
533,14,335,3,0,
533,14,339,1,29,
533,14,339,4,0,
533,14,358,1,20,
533,14,359,1,45,
533,14,371,4,0,
533,14,374,4,0,
533,14,398,4,0,
533,14,409,3,0,
533,14,411,4,0,
533,14,444,1,49,
533,14,444,4,0,
533,14,447,4,0,
533,14,479,4,0,
533,14,490,4,0,
533,14,496,4,0,
533,14,498,1,24,
533,14,514,4,0,
533,14,526,4,0,
533,15,1,1,1,1
533,15,43,1,1,2
533,15,67,1,12,
533,15,70,4,0,
533,15,88,1,16,
533,15,91,4,0,
533,15,92,4,0,
533,15,104,4,0,
533,15,116,1,1,3
533,15,116,1,4,
533,15,117,1,1,4
533,15,117,1,8,
533,15,156,4,0,
533,15,157,1,33,
533,15,157,4,0,
533,15,164,4,0,
533,15,182,4,0,
533,15,184,1,41,
533,15,207,4,0,
533,15,213,4,0,
533,15,214,4,0,
533,15,216,4,0,
533,15,218,4,0,
533,15,223,1,37,
533,15,237,4,0,
533,15,240,4,0,
533,15,241,4,0,
533,15,249,4,0,
533,15,263,4,0,
533,15,264,1,53,
533,15,269,4,0,
533,15,276,1,57,
533,15,280,4,0,
533,15,317,4,0,
533,15,339,1,29,
533,15,339,4,0,
533,15,358,1,20,
533,15,359,1,45,
533,15,371,4,0,
533,15,374,4,0,
533,15,398,4,0,
533,15,411,4,0,
533,15,444,1,49,
533,15,444,4,0,
533,15,447,4,0,
533,15,479,4,0,
533,15,490,4,0,
533,15,496,4,0,
533,15,498,1,24,
533,15,514,4,0,
533,15,590,4,0,
533,15,612,4,0,
533,16,1,1,1,1
533,16,7,3,0,
533,16,8,3,0,
533,16,9,3,0,
533,16,43,1,1,2
533,16,67,1,12,1
533,16,67,3,0,
533,16,70,4,0,
533,16,88,1,16,1
533,16,91,4,0,
533,16,92,4,0,
533,16,104,4,0,
533,16,116,1,1,3
533,16,116,1,4,1
533,16,117,1,1,4
533,16,117,1,8,1
533,16,156,4,0,
533,16,157,1,33,1
533,16,157,4,0,
533,16,164,4,0,
533,16,173,3,0,
533,16,182,4,0,
533,16,184,1,41,1
533,16,207,4,0,
533,16,213,4,0,
533,16,214,4,0,
533,16,216,4,0,
533,16,218,4,0,
533,16,223,1,37,1
533,16,237,4,0,
533,16,240,4,0,
533,16,241,4,0,
533,16,249,4,0,
533,16,263,4,0,
533,16,264,1,53,1
533,16,264,3,0,
533,16,269,4,0,
533,16,270,3,0,
533,16,276,1,57,1
533,16,276,3,0,
533,16,280,4,0,
533,16,282,3,0,
533,16,290,4,0,
533,16,317,4,0,
533,16,335,3,0,
533,16,339,1,29,1
533,16,339,4,0,
533,16,358,1,20,1
533,16,359,1,45,1
533,16,371,4,0,
533,16,374,4,0,
533,16,398,4,0,
533,16,409,3,0,
533,16,411,4,0,
533,16,444,1,49,1
533,16,444,4,0,
533,16,447,4,0,
533,16,479,4,0,
533,16,490,4,0,
533,16,496,4,0,
533,16,498,1,24,1
533,16,514,4,0,
533,16,590,4,0,
533,16,612,4,0,
533,17,1,1,1,1
533,17,43,1,1,2
533,17,67,1,12,
533,17,88,1,16,
533,17,92,4,0,
533,17,104,4,0,
533,17,116,1,1,3
533,17,116,1,4,
533,17,117,1,1,4
533,17,117,1,8,
533,17,156,4,0,
533,17,157,1,33,
533,17,157,4,0,
533,17,164,4,0,
533,17,182,4,0,
533,17,184,1,41,
533,17,207,4,0,
533,17,213,4,0,
533,17,214,4,0,
533,17,216,4,0,
533,17,218,4,0,
533,17,223,1,37,
533,17,237,4,0,
533,17,240,4,0,
533,17,241,4,0,
533,17,263,4,0,
533,17,264,1,53,
533,17,269,4,0,
533,17,276,1,57,
533,17,280,4,0,
533,17,317,4,0,
533,17,339,1,29,
533,17,339,4,0,
533,17,358,1,20,
533,17,359,1,45,
533,17,371,4,0,
533,17,374,4,0,
533,17,398,4,0,
533,17,411,4,0,
533,17,444,1,49,
533,17,444,4,0,
533,17,447,4,0,
533,17,479,4,0,
533,17,490,4,0,
533,17,496,4,0,
533,17,498,1,24,
533,17,526,4,0,
533,17,590,4,0,
533,17,693,4,0,
533,18,1,1,1,1
533,18,43,1,1,2
533,18,67,1,12,
533,18,88,1,16,
533,18,92,4,0,
533,18,104,4,0,
533,18,116,1,1,3
533,18,116,1,4,
533,18,117,1,1,4
533,18,117,1,8,
533,18,156,4,0,
533,18,157,1,33,
533,18,157,4,0,
533,18,164,4,0,
533,18,182,4,0,
533,18,184,1,41,
533,18,207,4,0,
533,18,213,4,0,
533,18,214,4,0,
533,18,216,4,0,
533,18,218,4,0,
533,18,223,1,37,
533,18,237,4,0,
533,18,240,4,0,
533,18,241,4,0,
533,18,263,4,0,
533,18,264,1,53,
533,18,269,4,0,
533,18,276,1,57,
533,18,280,4,0,
533,18,317,4,0,
533,18,339,1,29,
533,18,339,4,0,
533,18,358,1,20,
533,18,359,1,45,
533,18,371,4,0,
533,18,374,4,0,
533,18,398,4,0,
533,18,411,4,0,
533,18,444,1,49,
533,18,444,4,0,
533,18,447,4,0,
533,18,479,4,0,
533,18,490,4,0,
533,18,496,4,0,
533,18,498,1,24,
533,18,526,4,0,
533,18,590,4,0,
533,18,693,4,0,
534,11,1,1,1,1
534,11,43,1,1,2
534,11,63,4,0,
534,11,67,1,12,
534,11,70,4,0,
534,11,88,1,16,
534,11,89,4,0,
534,11,91,4,0,
534,11,92,4,0,
534,11,104,4,0,
534,11,116,1,1,3
534,11,116,1,4,
534,11,117,1,1,4
534,11,117,1,8,
534,11,156,4,0,
534,11,157,1,33,
534,11,157,4,0,
534,11,164,4,0,
534,11,182,4,0,
534,11,184,1,41,
534,11,207,4,0,
534,11,213,4,0,
534,11,216,4,0,
534,11,218,4,0,
534,11,223,1,37,
534,11,237,4,0,
534,11,240,4,0,
534,11,241,4,0,
534,11,249,4,0,
534,11,263,4,0,
534,11,264,1,53,
534,11,269,4,0,
534,11,276,1,57,
534,11,280,4,0,
534,11,317,4,0,
534,11,339,1,29,
534,11,339,4,0,
534,11,358,1,20,
534,11,359,1,45,
534,11,371,4,0,
534,11,374,4,0,
534,11,398,4,0,
534,11,411,4,0,
534,11,416,4,0,
534,11,444,1,49,
534,11,444,4,0,
534,11,447,4,0,
534,11,479,4,0,
534,11,490,4,0,
534,11,496,4,0,
534,11,498,1,24,
534,11,514,4,0,
534,11,523,4,0,
534,11,526,4,0,
534,14,1,1,1,1
534,14,7,3,0,
534,14,8,3,0,
534,14,9,3,0,
534,14,43,1,1,2
534,14,63,4,0,
534,14,67,1,12,
534,14,67,3,0,
534,14,70,4,0,
534,14,88,1,16,
534,14,89,4,0,
534,14,91,4,0,
534,14,92,4,0,
534,14,104,4,0,
534,14,116,1,1,3
534,14,116,1,4,
534,14,117,1,1,4
534,14,117,1,8,
534,14,156,4,0,
534,14,157,1,33,
534,14,157,4,0,
534,14,164,4,0,
534,14,173,3,0,
534,14,182,4,0,
534,14,184,1,41,
534,14,207,4,0,
534,14,213,4,0,
534,14,214,3,0,
534,14,216,4,0,
534,14,218,4,0,
534,14,223,1,37,
534,14,237,4,0,
534,14,240,4,0,
534,14,241,4,0,
534,14,249,4,0,
534,14,263,4,0,
534,14,264,1,53,
534,14,269,4,0,
534,14,270,3,0,
534,14,276,1,57,
534,14,276,3,0,
534,14,280,4,0,
534,14,282,3,0,
534,14,317,4,0,
534,14,335,3,0,
534,14,339,1,29,
534,14,339,4,0,
534,14,358,1,20,
534,14,359,1,45,
534,14,371,4,0,
534,14,374,4,0,
534,14,398,4,0,
534,14,409,3,0,
534,14,411,4,0,
534,14,416,4,0,
534,14,444,1,49,
534,14,444,4,0,
534,14,447,4,0,
534,14,479,4,0,
534,14,490,4,0,
534,14,496,4,0,
534,14,498,1,24,
534,14,514,4,0,
534,14,523,4,0,
534,14,526,4,0,
534,15,1,1,1,1
534,15,43,1,1,2
534,15,63,4,0,
534,15,67,1,12,
534,15,70,4,0,
534,15,88,1,16,
534,15,89,4,0,
534,15,91,4,0,
534,15,92,4,0,
534,15,104,4,0,
534,15,116,1,1,3
534,15,116,1,4,
534,15,117,1,1,4
534,15,117,1,8,
534,15,156,4,0,
534,15,157,1,33,
534,15,157,4,0,
534,15,164,4,0,
534,15,182,4,0,
534,15,184,1,41,
534,15,207,4,0,
534,15,213,4,0,
534,15,214,4,0,
534,15,216,4,0,
534,15,218,4,0,
534,15,223,1,37,
534,15,237,4,0,
534,15,240,4,0,
534,15,241,4,0,
534,15,249,4,0,
534,15,263,4,0,
534,15,264,1,53,
534,15,269,4,0,
534,15,276,1,57,
534,15,280,4,0,
534,15,317,4,0,
534,15,339,1,29,
534,15,339,4,0,
534,15,358,1,20,
534,15,359,1,45,
534,15,371,4,0,
534,15,374,4,0,
534,15,398,4,0,
534,15,411,4,0,
534,15,416,4,0,
534,15,444,1,49,
534,15,444,4,0,
534,15,447,4,0,
534,15,479,4,0,
534,15,490,4,0,
534,15,496,4,0,
534,15,498,1,24,
534,15,514,4,0,
534,15,523,4,0,
534,15,590,4,0,
534,15,612,4,0,
534,16,1,1,1,1
534,16,7,3,0,
534,16,8,3,0,
534,16,9,3,0,
534,16,43,1,1,2
534,16,63,4,0,
534,16,67,1,12,1
534,16,67,3,0,
534,16,70,4,0,
534,16,88,1,16,1
534,16,89,4,0,
534,16,91,4,0,
534,16,92,4,0,
534,16,104,4,0,
534,16,116,1,1,3
534,16,116,1,4,1
534,16,117,1,1,4
534,16,117,1,8,1
534,16,156,4,0,
534,16,157,1,33,1
534,16,157,4,0,
534,16,164,4,0,
534,16,173,3,0,
534,16,182,4,0,
534,16,184,1,41,1
534,16,207,4,0,
534,16,213,4,0,
534,16,214,4,0,
534,16,216,4,0,
534,16,218,4,0,
534,16,223,1,37,1
534,16,237,4,0,
534,16,240,4,0,
534,16,241,4,0,
534,16,249,4,0,
534,16,263,4,0,
534,16,264,1,53,1
534,16,264,3,0,
534,16,269,4,0,
534,16,270,3,0,
534,16,276,1,57,1
534,16,276,3,0,
534,16,280,4,0,
534,16,282,3,0,
534,16,290,4,0,
534,16,317,4,0,
534,16,335,3,0,
534,16,339,1,29,1
534,16,339,4,0,
534,16,358,1,20,1
534,16,359,1,45,1
534,16,371,4,0,
534,16,374,4,0,
534,16,398,4,0,
534,16,409,3,0,
534,16,411,4,0,
534,16,416,4,0,
534,16,444,1,49,1
534,16,444,4,0,
534,16,447,4,0,
534,16,479,4,0,
534,16,490,4,0,
534,16,496,4,0,
534,16,498,1,24,1
534,16,514,4,0,
534,16,523,4,0,
534,16,590,4,0,
534,16,612,4,0,
534,17,1,1,1,1
534,17,43,1,1,2
534,17,63,4,0,
534,17,67,1,12,
534,17,88,1,16,
534,17,89,4,0,
534,17,92,4,0,
534,17,104,4,0,
534,17,116,1,1,3
534,17,116,1,4,
534,17,117,1,1,4
534,17,117,1,8,
534,17,156,4,0,
534,17,157,1,33,
534,17,157,4,0,
534,17,164,4,0,
534,17,182,4,0,
534,17,184,1,41,
534,17,207,4,0,
534,17,213,4,0,
534,17,214,4,0,
534,17,216,4,0,
534,17,218,4,0,
534,17,223,1,37,
534,17,237,4,0,
534,17,240,4,0,
534,17,241,4,0,
534,17,263,4,0,
534,17,264,1,53,
534,17,269,4,0,
534,17,276,1,57,
534,17,280,4,0,
534,17,317,4,0,
534,17,339,1,29,
534,17,339,4,0,
534,17,358,1,20,
534,17,359,1,45,
534,17,371,4,0,
534,17,374,4,0,
534,17,398,4,0,
534,17,411,4,0,
534,17,416,4,0,
534,17,444,1,49,
534,17,444,4,0,
534,17,447,4,0,
534,17,479,4,0,
534,17,490,4,0,
534,17,496,4,0,
534,17,498,1,24,
534,17,523,4,0,
534,17,526,4,0,
534,17,590,4,0,
534,17,693,4,0,
534,18,1,1,1,1
534,18,43,1,1,2
534,18,63,4,0,
534,18,67,1,12,
534,18,88,1,16,
534,18,89,4,0,
534,18,92,4,0,
534,18,104,4,0,
534,18,116,1,1,3
534,18,116,1,4,
534,18,117,1,1,4
534,18,117,1,8,
534,18,156,4,0,
534,18,157,1,33,
534,18,157,4,0,
534,18,164,4,0,
534,18,182,4,0,
534,18,184,1,41,
534,18,207,4,0,
534,18,213,4,0,
534,18,214,4,0,
534,18,216,4,0,
534,18,218,4,0,
534,18,223,1,37,
534,18,237,4,0,
534,18,240,4,0,
534,18,241,4,0,
534,18,263,4,0,
534,18,264,1,53,
534,18,269,4,0,
534,18,276,1,57,
534,18,280,4,0,
534,18,317,4,0,
534,18,339,1,29,
534,18,339,4,0,
534,18,358,1,20,
534,18,359,1,45,
534,18,371,4,0,
534,18,374,4,0,
534,18,398,4,0,
534,18,411,4,0,
534,18,416,4,0,
534,18,444,1,49,
534,18,444,4,0,
534,18,447,4,0,
534,18,479,4,0,
534,18,490,4,0,
534,18,496,4,0,
534,18,498,1,24,
534,18,523,4,0,
534,18,526,4,0,
534,18,590,4,0,
534,18,693,4,0,
535,11,45,1,1,2
535,11,48,1,5,
535,11,54,2,0,
535,11,56,1,42,
535,11,57,4,0,
535,11,61,1,12,
535,11,92,4,0,
535,11,104,4,0,
535,11,145,1,1,1
535,11,156,4,0,
535,11,164,4,0,
535,11,173,2,0,
535,11,175,1,34,
535,11,182,4,0,
535,11,188,4,0,
535,11,207,4,0,
535,11,213,4,0,
535,11,214,2,0,
535,11,216,4,0,
535,11,218,4,0,
535,11,237,4,0,
535,11,240,1,31,
535,11,240,4,0,
535,11,253,1,23,
535,11,258,4,0,
535,11,263,4,0,
535,11,287,2,0,
535,11,300,2,0,
535,11,304,1,45,
535,11,330,1,27,
535,11,341,1,16,
535,11,352,2,0,
535,11,392,1,20,
535,11,414,2,0,
535,11,426,2,0,
535,11,482,4,0,
535,11,496,1,9,
535,11,496,4,0,
535,11,497,1,38,
535,11,497,4,0,
535,11,503,4,0,
535,14,45,1,1,2
535,14,48,1,5,
535,14,54,2,0,
535,14,56,1,42,
535,14,57,4,0,
535,14,61,1,12,
535,14,92,4,0,
535,14,104,4,0,
535,14,145,1,1,1
535,14,156,4,0,
535,14,164,4,0,
535,14,173,2,0,
535,14,173,3,0,
535,14,175,1,34,
535,14,182,4,0,
535,14,188,4,0,
535,14,196,3,0,
535,14,207,4,0,
535,14,213,4,0,
535,14,214,2,0,
535,14,214,3,0,
535,14,216,4,0,
535,14,218,4,0,
535,14,237,4,0,
535,14,240,1,31,
535,14,240,4,0,
535,14,253,1,23,
535,14,253,3,0,
535,14,258,4,0,
535,14,263,4,0,
535,14,283,3,0,
535,14,287,2,0,
535,14,300,2,0,
535,14,304,1,45,
535,14,304,3,0,
535,14,330,1,27,
535,14,340,3,0,
535,14,341,1,16,
535,14,352,2,0,
535,14,392,1,20,
535,14,414,2,0,
535,14,414,3,0,
535,14,426,2,0,
535,14,482,4,0,
535,14,496,1,9,
535,14,496,4,0,
535,14,497,1,38,
535,14,497,4,0,
535,14,503,4,0,
535,15,45,1,1,2
535,15,48,1,5,
535,15,54,2,0,
535,15,56,1,42,
535,15,57,4,0,
535,15,61,1,12,
535,15,92,4,0,
535,15,104,4,0,
535,15,145,1,1,1
535,15,156,4,0,
535,15,164,4,0,
535,15,173,2,0,
535,15,175,1,34,
535,15,182,4,0,
535,15,188,4,0,
535,15,207,4,0,
535,15,213,4,0,
535,15,214,2,0,
535,15,214,4,0,
535,15,216,4,0,
535,15,218,4,0,
535,15,237,4,0,
535,15,240,1,31,
535,15,240,4,0,
535,15,253,1,23,
535,15,258,4,0,
535,15,263,4,0,
535,15,287,2,0,
535,15,300,2,0,
535,15,304,1,45,
535,15,330,1,27,
535,15,341,1,16,
535,15,352,2,0,
535,15,392,1,20,
535,15,414,2,0,
535,15,426,2,0,
535,15,482,4,0,
535,15,495,2,0,
535,15,496,1,9,
535,15,496,4,0,
535,15,497,1,38,
535,15,497,4,0,
535,15,503,4,0,
535,15,590,4,0,
535,15,611,4,0,
535,16,45,1,1,2
535,16,48,1,5,1
535,16,54,2,0,
535,16,56,1,42,1
535,16,57,4,0,
535,16,61,1,12,1
535,16,92,4,0,
535,16,104,4,0,
535,16,145,1,1,1
535,16,156,4,0,
535,16,164,4,0,
535,16,173,2,0,
535,16,173,3,0,
535,16,175,1,34,1
535,16,182,4,0,
535,16,188,4,0,
535,16,196,3,0,
535,16,207,4,0,
535,16,213,4,0,
535,16,214,2,0,
535,16,214,4,0,
535,16,216,4,0,
535,16,218,4,0,
535,16,237,4,0,
535,16,240,1,31,1
535,16,240,4,0,
535,16,253,1,23,1
535,16,253,3,0,
535,16,258,4,0,
535,16,263,4,0,
535,16,283,3,0,
535,16,287,2,0,
535,16,290,4,0,
535,16,300,2,0,
535,16,304,1,45,1
535,16,304,3,0,
535,16,330,1,27,1
535,16,340,3,0,
535,16,341,1,16,1
535,16,352,2,0,
535,16,352,3,0,
535,16,392,1,20,1
535,16,414,2,0,
535,16,414,3,0,
535,16,426,2,0,
535,16,482,4,0,
535,16,495,2,0,
535,16,495,3,0,
535,16,496,1,9,1
535,16,496,4,0,
535,16,497,1,38,1
535,16,497,4,0,
535,16,503,4,0,
535,16,590,4,0,
535,16,611,4,0,
535,17,45,1,1,2
535,17,48,1,5,
535,17,54,2,0,
535,17,56,1,42,
535,17,57,4,0,
535,17,61,1,12,
535,17,92,4,0,
535,17,104,4,0,
535,17,145,1,1,1
535,17,156,4,0,
535,17,164,4,0,
535,17,173,2,0,
535,17,175,1,34,
535,17,182,4,0,
535,17,188,4,0,
535,17,207,4,0,
535,17,213,4,0,
535,17,214,2,0,
535,17,214,4,0,
535,17,216,4,0,
535,17,218,4,0,
535,17,237,4,0,
535,17,240,1,31,
535,17,240,4,0,
535,17,253,1,23,
535,17,258,4,0,
535,17,263,4,0,
535,17,287,2,0,
535,17,300,2,0,
535,17,304,1,45,
535,17,330,1,27,
535,17,341,1,16,
535,17,352,2,0,
535,17,392,1,20,
535,17,414,2,0,
535,17,426,2,0,
535,17,482,4,0,
535,17,495,2,0,
535,17,496,1,9,
535,17,496,4,0,
535,17,497,1,38,
535,17,497,4,0,
535,17,503,4,0,
535,17,590,4,0,
535,17,611,4,0,
535,18,45,1,1,2
535,18,48,1,5,
535,18,54,2,0,
535,18,56,1,42,
535,18,57,4,0,
535,18,61,1,12,
535,18,92,4,0,
535,18,104,4,0,
535,18,145,1,1,1
535,18,156,4,0,
535,18,164,4,0,
535,18,173,2,0,
535,18,175,1,34,
535,18,182,4,0,
535,18,188,4,0,
535,18,207,4,0,
535,18,213,4,0,
535,18,214,2,0,
535,18,214,4,0,
535,18,216,4,0,
535,18,218,4,0,
535,18,237,4,0,
535,18,240,1,31,
535,18,240,4,0,
535,18,253,1,23,
535,18,258,4,0,
535,18,263,4,0,
535,18,287,2,0,
535,18,300,2,0,
535,18,304,1,45,
535,18,330,1,27,
535,18,341,1,16,
535,18,352,2,0,
535,18,392,1,20,
535,18,414,2,0,
535,18,426,2,0,
535,18,482,4,0,
535,18,495,2,0,
535,18,496,1,9,
535,18,496,4,0,
535,18,497,1,38,
535,18,497,4,0,
535,18,503,4,0,
535,18,590,4,0,
535,18,599,2,0,
535,18,611,4,0,
536,11,45,1,1,2
536,11,48,1,1,3
536,11,48,1,5,
536,11,56,1,47,
536,11,57,4,0,
536,11,61,1,12,
536,11,92,4,0,
536,11,104,4,0,
536,11,145,1,1,1
536,11,156,4,0,
536,11,164,4,0,
536,11,175,1,37,
536,11,182,4,0,
536,11,188,4,0,
536,11,207,4,0,
536,11,213,4,0,
536,11,216,4,0,
536,11,218,4,0,
536,11,237,4,0,
536,11,240,1,33,
536,11,240,4,0,
536,11,249,4,0,
536,11,253,1,23,
536,11,258,4,0,
536,11,263,4,0,
536,11,304,1,51,
536,11,330,1,28,
536,11,341,1,16,
536,11,392,1,20,
536,11,482,4,0,
536,11,496,1,1,4
536,11,496,1,9,
536,11,496,4,0,
536,11,497,1,42,
536,11,497,4,0,
536,11,503,4,0,
536,11,523,4,0,
536,14,45,1,1,2
536,14,48,1,1,3
536,14,48,1,5,
536,14,56,1,47,
536,14,57,4,0,
536,14,61,1,12,
536,14,92,4,0,
536,14,104,4,0,
536,14,145,1,1,1
536,14,156,4,0,
536,14,164,4,0,
536,14,173,3,0,
536,14,175,1,37,
536,14,182,4,0,
536,14,188,4,0,
536,14,196,3,0,
536,14,207,4,0,
536,14,213,4,0,
536,14,214,3,0,
536,14,216,4,0,
536,14,218,4,0,
536,14,237,4,0,
536,14,240,1,33,
536,14,240,4,0,
536,14,249,4,0,
536,14,253,1,23,
536,14,253,3,0,
536,14,258,4,0,
536,14,263,4,0,
536,14,283,3,0,
536,14,304,1,51,
536,14,304,3,0,
536,14,330,1,28,
536,14,340,3,0,
536,14,341,1,16,
536,14,380,3,0,
536,14,392,1,20,
536,14,414,3,0,
536,14,446,3,0,
536,14,482,4,0,
536,14,496,1,1,4
536,14,496,1,9,
536,14,496,4,0,
536,14,497,1,42,
536,14,497,4,0,
536,14,503,4,0,
536,14,523,4,0,
536,15,45,1,1,2
536,15,48,1,1,3
536,15,48,1,5,
536,15,56,1,47,
536,15,57,4,0,
536,15,61,1,12,
536,15,92,4,0,
536,15,104,4,0,
536,15,145,1,1,1
536,15,156,4,0,
536,15,164,4,0,
536,15,175,1,37,
536,15,182,4,0,
536,15,188,4,0,
536,15,207,4,0,
536,15,213,4,0,
536,15,214,4,0,
536,15,216,4,0,
536,15,218,4,0,
536,15,237,4,0,
536,15,240,1,33,
536,15,240,4,0,
536,15,249,4,0,
536,15,253,1,23,
536,15,258,4,0,
536,15,263,4,0,
536,15,304,1,51,
536,15,330,1,28,
536,15,341,1,16,
536,15,392,1,20,
536,15,482,4,0,
536,15,496,1,1,4
536,15,496,1,9,
536,15,496,4,0,
536,15,497,1,42,
536,15,497,4,0,
536,15,503,4,0,
536,15,523,4,0,
536,15,590,4,0,
536,15,611,4,0,
536,16,45,1,1,2
536,16,48,1,1,3
536,16,48,1,5,1
536,16,56,1,47,1
536,16,57,4,0,
536,16,61,1,12,1
536,16,92,4,0,
536,16,104,4,0,
536,16,145,1,1,1
536,16,156,4,0,
536,16,164,4,0,
536,16,173,3,0,
536,16,175,1,37,1
536,16,182,4,0,
536,16,188,4,0,
536,16,196,3,0,
536,16,207,4,0,
536,16,213,4,0,
536,16,214,4,0,
536,16,216,4,0,
536,16,218,4,0,
536,16,237,4,0,
536,16,240,1,33,1
536,16,240,4,0,
536,16,249,4,0,
536,16,253,1,23,1
536,16,253,3,0,
536,16,258,4,0,
536,16,263,4,0,
536,16,264,3,0,
536,16,283,3,0,
536,16,290,4,0,
536,16,304,1,51,1
536,16,304,3,0,
536,16,330,1,28,1
536,16,340,3,0,
536,16,341,1,16,1
536,16,352,3,0,
536,16,380,3,0,
536,16,392,1,20,1
536,16,414,3,0,
536,16,446,3,0,
536,16,482,4,0,
536,16,495,3,0,
536,16,496,1,1,4
536,16,496,1,9,1
536,16,496,4,0,
536,16,497,1,42,1
536,16,497,4,0,
536,16,503,4,0,
536,16,523,4,0,
536,16,590,4,0,
536,16,611,4,0,
536,17,45,1,1,2
536,17,48,1,1,3
536,17,48,1,5,
536,17,56,1,47,
536,17,57,4,0,
536,17,61,1,12,
536,17,92,4,0,
536,17,104,4,0,
536,17,145,1,1,1
536,17,156,4,0,
536,17,164,4,0,
536,17,175,1,37,
536,17,182,4,0,
536,17,188,4,0,
536,17,207,4,0,
536,17,213,4,0,
536,17,214,4,0,
536,17,216,4,0,
536,17,218,4,0,
536,17,237,4,0,
536,17,240,1,33,
536,17,240,4,0,
536,17,253,1,23,
536,17,258,4,0,
536,17,263,4,0,
536,17,304,1,51,
536,17,330,1,28,
536,17,341,1,16,
536,17,392,1,20,
536,17,482,4,0,
536,17,496,1,1,4
536,17,496,1,9,
536,17,496,4,0,
536,17,497,1,42,
536,17,497,4,0,
536,17,503,4,0,
536,17,523,4,0,
536,17,590,4,0,
536,17,611,4,0,
536,18,45,1,1,2
536,18,48,1,1,3
536,18,48,1,5,
536,18,56,1,47,
536,18,57,4,0,
536,18,61,1,12,
536,18,92,4,0,
536,18,104,4,0,
536,18,145,1,1,1
536,18,156,4,0,
536,18,164,4,0,
536,18,175,1,37,
536,18,182,4,0,
536,18,188,4,0,
536,18,207,4,0,
536,18,213,4,0,
536,18,214,4,0,
536,18,216,4,0,
536,18,218,4,0,
536,18,237,4,0,
536,18,240,1,33,
536,18,240,4,0,
536,18,253,1,23,
536,18,258,4,0,
536,18,263,4,0,
536,18,304,1,51,
536,18,330,1,28,
536,18,341,1,16,
536,18,392,1,20,
536,18,482,4,0,
536,18,496,1,1,4
536,18,496,1,9,
536,18,496,4,0,
536,18,497,1,42,
536,18,497,4,0,
536,18,503,4,0,
536,18,523,4,0,
536,18,590,4,0,
536,18,611,4,0,
537,11,45,1,1,2
537,11,48,1,1,3
537,11,48,1,5,
537,11,51,1,36,
537,11,56,1,53,
537,11,57,4,0,
537,11,61,1,12,
537,11,63,4,0,
537,11,70,4,0,
537,11,89,4,0,
537,11,91,4,0,
537,11,92,4,0,
537,11,104,4,0,
537,11,145,1,1,1
537,11,156,4,0,
537,11,157,4,0,
537,11,164,4,0,
537,11,175,1,39,
537,11,182,4,0,
537,11,188,4,0,
537,11,207,4,0,
537,11,213,4,0,
537,11,216,4,0,
537,11,218,4,0,
537,11,237,4,0,
537,11,240,1,33,
537,11,240,4,0,
537,11,249,4,0,
537,11,253,1,23,
537,11,258,4,0,
537,11,263,4,0,
537,11,280,4,0,
537,11,304,1,59,
537,11,317,4,0,
537,11,330,1,28,
537,11,341,1,16,
537,11,371,4,0,
537,11,374,4,0,
537,11,392,1,20,
537,11,398,4,0,
537,11,409,1,44,
537,11,411,4,0,
537,11,416,4,0,
537,11,447,4,0,
537,11,474,4,0,
537,11,482,4,0,
537,11,496,1,1,4
537,11,496,1,9,
537,11,496,4,0,
537,11,497,1,49,
537,11,497,4,0,
537,11,503,4,0,
537,11,523,4,0,
537,14,8,3,0,
537,14,45,1,1,2
537,14,48,1,1,3
537,14,48,1,5,
537,14,51,1,36,
537,14,56,1,53,
537,14,57,4,0,
537,14,61,1,12,
537,14,63,4,0,
537,14,67,3,0,
537,14,70,4,0,
537,14,89,4,0,
537,14,91,4,0,
537,14,92,4,0,
537,14,104,4,0,
537,14,145,1,1,1
537,14,156,4,0,
537,14,157,4,0,
537,14,164,4,0,
537,14,173,3,0,
537,14,175,1,39,
537,14,182,4,0,
537,14,188,4,0,
537,14,196,3,0,
537,14,207,4,0,
537,14,213,4,0,
537,14,214,3,0,
537,14,216,4,0,
537,14,218,4,0,
537,14,237,4,0,
537,14,240,1,33,
537,14,240,4,0,
537,14,249,4,0,
537,14,253,1,23,
537,14,253,3,0,
537,14,258,4,0,
537,14,263,4,0,
537,14,280,4,0,
537,14,282,3,0,
537,14,283,3,0,
537,14,304,1,59,
537,14,304,3,0,
537,14,317,4,0,
537,14,330,1,28,
537,14,340,3,0,
537,14,341,1,16,
537,14,371,4,0,
537,14,374,4,0,
537,14,380,3,0,
537,14,392,1,20,
537,14,398,4,0,
537,14,409,1,44,
537,14,409,3,0,
537,14,411,4,0,
537,14,414,3,0,
537,14,416,4,0,
537,14,446,3,0,
537,14,447,4,0,
537,14,474,4,0,
537,14,482,4,0,
537,14,496,1,1,4
537,14,496,1,9,
537,14,496,4,0,
537,14,497,1,49,
537,14,497,4,0,
537,14,503,4,0,
537,14,523,4,0,
537,15,45,1,1,2
537,15,48,1,1,3
537,15,48,1,5,
537,15,51,1,36,
537,15,56,1,53,
537,15,57,4,0,
537,15,61,1,12,
537,15,63,4,0,
537,15,70,4,0,
537,15,89,4,0,
537,15,91,4,0,
537,15,92,4,0,
537,15,104,4,0,
537,15,145,1,1,1
537,15,156,4,0,
537,15,157,4,0,
537,15,164,4,0,
537,15,175,1,39,
537,15,182,4,0,
537,15,188,4,0,
537,15,207,4,0,
537,15,213,4,0,
537,15,214,4,0,
537,15,216,4,0,
537,15,218,4,0,
537,15,237,4,0,
537,15,240,1,33,
537,15,240,4,0,
537,15,249,4,0,
537,15,253,1,23,
537,15,258,4,0,
537,15,263,4,0,
537,15,280,4,0,
537,15,304,1,59,
537,15,317,4,0,
537,15,330,1,28,
537,15,341,1,16,
537,15,371,4,0,
537,15,374,4,0,
537,15,392,1,20,
537,15,398,4,0,
537,15,409,1,44,
537,15,411,4,0,
537,15,416,4,0,
537,15,447,4,0,
537,15,474,4,0,
537,15,482,4,0,
537,15,496,1,1,4
537,15,496,1,9,
537,15,496,4,0,
537,15,497,1,49,
537,15,497,4,0,
537,15,503,4,0,
537,15,523,4,0,
537,15,590,4,0,
537,15,611,4,0,
537,15,612,4,0,
537,16,8,3,0,
537,16,45,1,1,2
537,16,48,1,1,3
537,16,48,1,5,1
537,16,51,1,36,1
537,16,56,1,53,1
537,16,57,4,0,
537,16,61,1,12,1
537,16,63,4,0,
537,16,67,3,0,
537,16,70,4,0,
537,16,89,4,0,
537,16,91,4,0,
537,16,92,4,0,
537,16,104,4,0,
537,16,145,1,1,1
537,16,156,4,0,
537,16,157,4,0,
537,16,164,4,0,
537,16,173,3,0,
537,16,175,1,39,1
537,16,182,4,0,
537,16,188,4,0,
537,16,196,3,0,
537,16,207,4,0,
537,16,213,4,0,
537,16,214,4,0,
537,16,216,4,0,
537,16,218,4,0,
537,16,237,4,0,
537,16,240,1,33,1
537,16,240,4,0,
537,16,249,4,0,
537,16,253,1,23,1
537,16,253,3,0,
537,16,258,4,0,
537,16,263,4,0,
537,16,264,3,0,
537,16,280,4,0,
537,16,282,3,0,
537,16,283,3,0,
537,16,290,4,0,
537,16,304,1,59,1
537,16,304,3,0,
537,16,317,4,0,
537,16,330,1,28,1
537,16,340,3,0,
537,16,341,1,16,1
537,16,352,3,0,
537,16,371,4,0,
537,16,374,4,0,
537,16,380,3,0,
537,16,392,1,20,1
537,16,398,4,0,
537,16,409,1,44,1
537,16,409,3,0,
537,16,411,4,0,
537,16,414,3,0,
537,16,416,4,0,
537,16,446,3,0,
537,16,447,4,0,
537,16,474,4,0,
537,16,482,4,0,
537,16,495,3,0,
537,16,496,1,1,4
537,16,496,1,9,1
537,16,496,4,0,
537,16,497,1,49,1
537,16,497,4,0,
537,16,503,4,0,
537,16,523,4,0,
537,16,590,4,0,
537,16,611,4,0,
537,16,612,4,0,
537,17,45,1,1,3
537,17,48,1,1,4
537,17,48,1,5,
537,17,51,1,0,
537,17,51,1,1,1
537,17,56,1,53,
537,17,57,4,0,
537,17,61,1,12,
537,17,63,4,0,
537,17,89,4,0,
537,17,92,4,0,
537,17,104,4,0,
537,17,145,1,1,2
537,17,156,4,0,
537,17,157,4,0,
537,17,164,4,0,
537,17,175,1,39,
537,17,182,4,0,
537,17,188,4,0,
537,17,207,4,0,
537,17,213,4,0,
537,17,214,4,0,
537,17,216,4,0,
537,17,218,4,0,
537,17,237,4,0,
537,17,240,1,33,
537,17,240,4,0,
537,17,253,1,23,
537,17,258,4,0,
537,17,263,4,0,
537,17,280,4,0,
537,17,304,1,59,
537,17,317,4,0,
537,17,330,1,28,
537,17,341,1,16,
537,17,371,4,0,
537,17,374,4,0,
537,17,392,1,20,
537,17,398,4,0,
537,17,409,1,44,
537,17,411,4,0,
537,17,416,4,0,
537,17,447,4,0,
537,17,474,4,0,
537,17,482,4,0,
537,17,496,1,1,5
537,17,496,1,9,
537,17,496,4,0,
537,17,497,1,49,
537,17,497,4,0,
537,17,503,4,0,
537,17,523,4,0,
537,17,590,4,0,
537,17,611,4,0,
537,18,45,1,1,3
537,18,48,1,1,4
537,18,48,1,5,
537,18,51,1,0,
537,18,51,1,1,1
537,18,56,1,53,
537,18,57,4,0,
537,18,61,1,12,
537,18,63,4,0,
537,18,89,4,0,
537,18,92,4,0,
537,18,104,4,0,
537,18,145,1,1,2
537,18,156,4,0,
537,18,157,4,0,
537,18,164,4,0,
537,18,175,1,39,
537,18,182,4,0,
537,18,188,4,0,
537,18,207,4,0,
537,18,213,4,0,
537,18,214,4,0,
537,18,216,4,0,
537,18,218,4,0,
537,18,237,4,0,
537,18,240,1,33,
537,18,240,4,0,
537,18,253,1,23,
537,18,258,4,0,
537,18,263,4,0,
537,18,280,4,0,
537,18,304,1,59,
537,18,317,4,0,
537,18,330,1,28,
537,18,341,1,16,
537,18,371,4,0,
537,18,374,4,0,
537,18,392,1,20,
537,18,398,4,0,
537,18,409,1,44,
537,18,411,4,0,
537,18,416,4,0,
537,18,447,4,0,
537,18,474,4,0,
537,18,482,4,0,
537,18,496,1,1,5
537,18,496,1,9,
537,18,496,4,0,
537,18,497,1,49,
537,18,497,4,0,
537,18,503,4,0,
537,18,523,4,0,
537,18,590,4,0,
537,18,611,4,0,
538,11,20,1,1,1
538,11,34,1,29,
538,11,43,1,1,2
538,11,69,1,13,
538,11,70,4,0,
538,11,89,4,0,
538,11,91,4,0,
538,11,92,4,0,
538,11,104,4,0,
538,11,116,1,9,
538,11,117,1,5,
538,11,156,4,0,
538,11,157,4,0,
538,11,164,4,0,
538,11,179,1,53,
538,11,182,4,0,
538,11,203,1,41,
538,11,207,4,0,
538,11,213,4,0,
538,11,216,4,0,
538,11,218,4,0,
538,11,233,1,17,
538,11,237,4,0,
538,11,240,4,0,
538,11,241,4,0,
538,11,249,4,0,
538,11,263,4,0,
538,11,269,4,0,
538,11,276,1,49,
538,11,279,1,21,
538,11,280,4,0,
538,11,317,4,0,
538,11,339,1,33,
538,11,339,4,0,
538,11,371,4,0,
538,11,374,4,0,
538,11,398,4,0,
538,11,411,4,0,
538,11,416,4,0,
538,11,444,4,0,
538,11,447,4,0,
538,11,469,1,45,
538,11,480,1,25,
538,11,490,4,0,
538,11,496,4,0,
538,11,509,1,37,
538,11,514,4,0,
538,11,523,4,0,
538,11,526,4,0,
538,14,7,3,0,
538,14,8,3,0,
538,14,9,3,0,
538,14,20,1,1,1
538,14,20,3,0,
538,14,34,1,29,
538,14,43,1,1,2
538,14,67,3,0,
538,14,69,1,13,
538,14,70,4,0,
538,14,89,4,0,
538,14,91,4,0,
538,14,92,4,0,
538,14,104,4,0,
538,14,116,1,9,
538,14,117,1,5,
538,14,156,4,0,
538,14,157,4,0,
538,14,164,4,0,
538,14,173,3,0,
538,14,179,1,53,
538,14,182,4,0,
538,14,203,1,41,
538,14,207,4,0,
538,14,213,4,0,
538,14,214,3,0,
538,14,216,4,0,
538,14,218,4,0,
538,14,220,3,0,
538,14,233,1,17,
538,14,237,4,0,
538,14,240,4,0,
538,14,241,4,0,
538,14,249,4,0,
538,14,263,4,0,
538,14,269,4,0,
538,14,270,3,0,
538,14,276,1,49,
538,14,276,3,0,
538,14,279,1,21,
538,14,280,4,0,
538,14,282,3,0,
538,14,317,4,0,
538,14,335,3,0,
538,14,339,1,33,
538,14,339,4,0,
538,14,371,4,0,
538,14,374,4,0,
538,14,398,4,0,
538,14,411,4,0,
538,14,416,4,0,
538,14,444,4,0,
538,14,447,4,0,
538,14,469,1,45,
538,14,480,1,25,
538,14,490,4,0,
538,14,496,4,0,
538,14,509,1,37,
538,14,514,4,0,
538,14,523,4,0,
538,14,526,4,0,
538,15,20,1,1,1
538,15,34,1,29,
538,15,43,1,1,2
538,15,69,1,13,
538,15,70,4,0,
538,15,89,4,0,
538,15,91,4,0,
538,15,92,4,0,
538,15,104,4,0,
538,15,116,1,9,
538,15,117,1,5,
538,15,156,4,0,
538,15,157,4,0,
538,15,164,4,0,
538,15,179,1,50,
538,15,182,4,0,
538,15,203,1,41,
538,15,207,4,0,
538,15,213,4,0,
538,15,214,4,0,
538,15,216,4,0,
538,15,218,4,0,
538,15,233,1,17,
538,15,237,4,0,
538,15,240,4,0,
538,15,241,4,0,
538,15,249,4,0,
538,15,263,4,0,
538,15,269,4,0,
538,15,276,1,48,
538,15,279,1,21,
538,15,280,4,0,
538,15,317,4,0,
538,15,339,1,33,
538,15,339,4,0,
538,15,371,4,0,
538,15,374,4,0,
538,15,398,4,0,
538,15,411,4,0,
538,15,416,4,0,
538,15,444,4,0,
538,15,447,4,0,
538,15,469,1,45,
538,15,480,1,25,
538,15,490,4,0,
538,15,496,4,0,
538,15,509,1,37,
538,15,514,4,0,
538,15,523,4,0,
538,15,590,4,0,
538,15,612,4,0,
538,16,7,3,0,
538,16,8,3,0,
538,16,9,3,0,
538,16,20,1,1,1
538,16,20,3,0,
538,16,34,1,29,1
538,16,43,1,1,2
538,16,67,3,0,
538,16,69,1,13,1
538,16,70,4,0,
538,16,89,4,0,
538,16,91,4,0,
538,16,92,4,0,
538,16,104,4,0,
538,16,116,1,9,1
538,16,117,1,5,1
538,16,156,4,0,
538,16,157,4,0,
538,16,164,4,0,
538,16,173,3,0,
538,16,179,1,50,1
538,16,182,4,0,
538,16,203,1,41,1
538,16,207,4,0,
538,16,213,4,0,
538,16,214,4,0,
538,16,216,4,0,
538,16,218,4,0,
538,16,220,3,0,
538,16,233,1,17,1
538,16,237,4,0,
538,16,240,4,0,
538,16,241,4,0,
538,16,249,4,0,
538,16,263,4,0,
538,16,264,3,0,
538,16,269,4,0,
538,16,270,3,0,
538,16,276,1,48,1
538,16,276,3,0,
538,16,279,1,21,1
538,16,280,4,0,
538,16,282,3,0,
538,16,290,4,0,
538,16,317,4,0,
538,16,335,3,0,
538,16,339,1,33,1
538,16,339,4,0,
538,16,371,4,0,
538,16,374,4,0,
538,16,398,4,0,
538,16,411,4,0,
538,16,416,4,0,
538,16,428,3,0,
538,16,444,4,0,
538,16,447,4,0,
538,16,469,1,45,1
538,16,480,1,25,1
538,16,490,4,0,
538,16,496,4,0,
538,16,509,1,37,1
538,16,514,4,0,
538,16,523,4,0,
538,16,590,4,0,
538,16,612,4,0,
538,17,20,1,1,2
538,17,34,1,21,
538,17,43,1,1,3
538,17,69,1,5,
538,17,89,4,0,
538,17,92,4,0,
538,17,104,4,0,
538,17,116,1,1,5
538,17,117,1,1,4
538,17,156,4,0,
538,17,157,4,0,
538,17,164,4,0,
538,17,179,1,45,
538,17,182,4,0,
538,17,203,1,33,
538,17,207,4,0,
538,17,213,4,0,
538,17,214,4,0,
538,17,216,4,0,
538,17,218,4,0,
538,17,233,1,9,
538,17,237,4,0,
538,17,240,4,0,
538,17,241,4,0,
538,17,263,4,0,
538,17,269,4,0,
538,17,276,1,41,
538,17,279,1,13,
538,17,280,4,0,
538,17,317,4,0,
538,17,339,1,25,
538,17,339,4,0,
538,17,371,4,0,
538,17,374,4,0,
538,17,398,4,0,
538,17,411,4,0,
538,17,416,4,0,
538,17,444,4,0,
538,17,447,4,0,
538,17,469,1,37,
538,17,480,1,17,
538,17,490,4,0,
538,17,496,4,0,
538,17,509,1,29,
538,17,523,4,0,
538,17,526,4,0,
538,17,561,1,1,1
538,17,590,4,0,
538,18,20,1,1,2
538,18,34,1,21,
538,18,43,1,1,3
538,18,69,1,5,
538,18,89,4,0,
538,18,92,4,0,
538,18,104,4,0,
538,18,116,1,1,5
538,18,117,1,1,4
538,18,156,4,0,
538,18,157,4,0,
538,18,164,4,0,
538,18,179,1,45,
538,18,182,4,0,
538,18,203,1,33,
538,18,207,4,0,
538,18,213,4,0,
538,18,214,4,0,
538,18,216,4,0,
538,18,218,4,0,
538,18,233,1,9,
538,18,237,4,0,
538,18,240,4,0,
538,18,241,4,0,
538,18,263,4,0,
538,18,269,4,0,
538,18,276,1,41,
538,18,279,1,13,
538,18,280,4,0,
538,18,317,4,0,
538,18,339,1,25,
538,18,339,4,0,
538,18,371,4,0,
538,18,374,4,0,
538,18,398,4,0,
538,18,411,4,0,
538,18,416,4,0,
538,18,444,4,0,
538,18,447,4,0,
538,18,469,1,37,
538,18,480,1,17,
538,18,490,4,0,
538,18,496,4,0,
538,18,509,1,29,
538,18,523,4,0,
538,18,526,4,0,
538,18,561,1,1,1
538,18,590,4,0,
539,11,2,1,25,
539,11,24,1,13,
539,11,43,1,1,2
539,11,68,1,21,
539,11,70,4,0,
539,11,89,4,0,
539,11,91,4,0,
539,11,92,4,0,
539,11,104,4,0,
539,11,116,1,9,
539,11,117,1,5,
539,11,156,4,0,
539,11,157,4,0,
539,11,164,4,0,
539,11,179,1,53,
539,11,182,4,0,
539,11,203,1,41,
539,11,207,4,0,
539,11,213,4,0,
539,11,216,4,0,
539,11,218,4,0,
539,11,237,4,0,
539,11,240,4,0,
539,11,241,4,0,
539,11,249,1,1,1
539,11,249,4,0,
539,11,263,4,0,
539,11,269,4,0,
539,11,280,1,29,
539,11,280,4,0,
539,11,317,4,0,
539,11,339,1,33,
539,11,339,4,0,
539,11,370,1,49,
539,11,371,4,0,
539,11,374,4,0,
539,11,398,4,0,
539,11,411,4,0,
539,11,416,4,0,
539,11,444,4,0,
539,11,447,4,0,
539,11,490,1,17,
539,11,490,4,0,
539,11,496,4,0,
539,11,501,1,45,
539,11,514,1,37,
539,11,514,4,0,
539,11,523,4,0,
539,11,526,4,0,
539,14,2,1,25,
539,14,7,3,0,
539,14,8,3,0,
539,14,9,3,0,
539,14,24,1,13,
539,14,43,1,1,2
539,14,67,3,0,
539,14,68,1,21,
539,14,70,4,0,
539,14,89,4,0,
539,14,91,4,0,
539,14,92,4,0,
539,14,104,4,0,
539,14,116,1,9,
539,14,117,1,5,
539,14,156,4,0,
539,14,157,4,0,
539,14,164,4,0,
539,14,173,3,0,
539,14,179,1,53,
539,14,182,4,0,
539,14,203,1,41,
539,14,207,4,0,
539,14,213,4,0,
539,14,214,3,0,
539,14,216,4,0,
539,14,218,4,0,
539,14,220,3,0,
539,14,237,4,0,
539,14,240,4,0,
539,14,241,4,0,
539,14,249,1,1,1
539,14,249,4,0,
539,14,263,4,0,
539,14,269,4,0,
539,14,270,3,0,
539,14,276,3,0,
539,14,280,1,29,
539,14,280,4,0,
539,14,282,3,0,
539,14,317,4,0,
539,14,335,3,0,
539,14,339,1,33,
539,14,339,4,0,
539,14,370,1,49,
539,14,371,4,0,
539,14,374,4,0,
539,14,398,4,0,
539,14,411,4,0,
539,14,416,4,0,
539,14,444,4,0,
539,14,447,4,0,
539,14,490,1,17,
539,14,490,4,0,
539,14,496,4,0,
539,14,501,1,45,
539,14,514,1,37,
539,14,514,4,0,
539,14,523,4,0,
539,14,526,4,0,
539,14,530,3,0,
539,15,2,1,25,
539,15,24,1,13,
539,15,43,1,1,2
539,15,68,1,21,
539,15,70,4,0,
539,15,89,4,0,
539,15,91,4,0,
539,15,92,4,0,
539,15,104,4,0,
539,15,116,1,9,
539,15,117,1,5,
539,15,156,4,0,
539,15,157,4,0,
539,15,164,4,0,
539,15,179,1,50,
539,15,182,4,0,
539,15,203,1,41,
539,15,207,4,0,
539,15,213,4,0,
539,15,214,4,0,
539,15,216,4,0,
539,15,218,4,0,
539,15,237,4,0,
539,15,240,4,0,
539,15,241,4,0,
539,15,249,1,1,1
539,15,249,4,0,
539,15,263,4,0,
539,15,269,4,0,
539,15,280,1,29,
539,15,280,4,0,
539,15,317,4,0,
539,15,339,1,33,
539,15,339,4,0,
539,15,370,1,48,
539,15,371,4,0,
539,15,374,4,0,
539,15,398,4,0,
539,15,411,4,0,
539,15,416,4,0,
539,15,444,4,0,
539,15,447,4,0,
539,15,490,1,17,
539,15,490,4,0,
539,15,496,4,0,
539,15,501,1,45,
539,15,514,1,37,
539,15,514,4,0,
539,15,523,4,0,
539,15,590,4,0,
539,15,612,4,0,
539,16,2,1,25,1
539,16,7,3,0,
539,16,8,3,0,
539,16,9,3,0,
539,16,24,1,13,1
539,16,43,1,1,2
539,16,67,3,0,
539,16,68,1,21,1
539,16,70,4,0,
539,16,89,4,0,
539,16,91,4,0,
539,16,92,4,0,
539,16,104,4,0,
539,16,116,1,9,1
539,16,117,1,5,1
539,16,156,4,0,
539,16,157,4,0,
539,16,164,4,0,
539,16,173,3,0,
539,16,179,1,50,1
539,16,182,4,0,
539,16,203,1,41,1
539,16,207,4,0,
539,16,213,4,0,
539,16,214,4,0,
539,16,216,4,0,
539,16,218,4,0,
539,16,220,3,0,
539,16,237,4,0,
539,16,240,4,0,
539,16,241,4,0,
539,16,249,1,1,1
539,16,249,4,0,
539,16,263,4,0,
539,16,264,3,0,
539,16,269,4,0,
539,16,270,3,0,
539,16,276,3,0,
539,16,280,1,29,1
539,16,280,4,0,
539,16,282,3,0,
539,16,290,4,0,
539,16,317,4,0,
539,16,335,3,0,
539,16,339,1,33,1
539,16,339,4,0,
539,16,370,1,48,1
539,16,371,4,0,
539,16,374,4,0,
539,16,398,4,0,
539,16,411,4,0,
539,16,416,4,0,
539,16,428,3,0,
539,16,444,4,0,
539,16,447,4,0,
539,16,490,1,17,1
539,16,490,4,0,
539,16,496,4,0,
539,16,501,1,45,1
539,16,514,1,37,1
539,16,514,4,0,
539,16,523,4,0,
539,16,530,3,0,
539,16,590,4,0,
539,16,612,4,0,
539,17,2,1,17,
539,17,24,1,5,
539,17,43,1,1,2
539,17,68,1,13,
539,17,89,4,0,
539,17,92,4,0,
539,17,104,4,0,
539,17,116,1,1,4
539,17,117,1,1,3
539,17,156,4,0,
539,17,157,4,0,
539,17,164,4,0,
539,17,179,1,45,
539,17,182,4,0,
539,17,203,1,33,
539,17,207,4,0,
539,17,213,4,0,
539,17,214,4,0,
539,17,216,4,0,
539,17,218,4,0,
539,17,237,4,0,
539,17,240,4,0,
539,17,241,4,0,
539,17,249,1,1,1
539,17,263,4,0,
539,17,269,4,0,
539,17,280,1,21,
539,17,280,4,0,
539,17,317,4,0,
539,17,339,1,25,
539,17,339,4,0,
539,17,370,1,41,
539,17,371,4,0,
539,17,374,4,0,
539,17,398,4,0,
539,17,411,4,0,
539,17,416,4,0,
539,17,444,4,0,
539,17,447,4,0,
539,17,490,1,9,
539,17,490,4,0,
539,17,496,4,0,
539,17,501,1,37,
539,17,514,1,29,
539,17,523,4,0,
539,17,526,4,0,
539,17,590,4,0,
539,18,2,1,17,
539,18,24,1,5,
539,18,43,1,1,2
539,18,68,1,13,
539,18,89,4,0,
539,18,92,4,0,
539,18,104,4,0,
539,18,116,1,1,4
539,18,117,1,1,3
539,18,156,4,0,
539,18,157,4,0,
539,18,164,4,0,
539,18,179,1,45,
539,18,182,4,0,
539,18,203,1,33,
539,18,207,4,0,
539,18,213,4,0,
539,18,214,4,0,
539,18,216,4,0,
539,18,218,4,0,
539,18,237,4,0,
539,18,240,4,0,
539,18,241,4,0,
539,18,249,1,1,1
539,18,263,4,0,
539,18,269,4,0,
539,18,280,1,21,
539,18,280,4,0,
539,18,317,4,0,
539,18,339,1,25,
539,18,339,4,0,
539,18,370,1,41,
539,18,371,4,0,
539,18,374,4,0,
539,18,398,4,0,
539,18,411,4,0,
539,18,416,4,0,
539,18,444,4,0,
539,18,447,4,0,
539,18,490,1,9,
539,18,490,4,0,
539,18,496,4,0,
539,18,501,1,37,
539,18,514,1,29,
539,18,523,4,0,
539,18,526,4,0,
539,18,590,4,0,
540,11,13,2,0,
540,11,15,4,0,
540,11,33,1,1,1
540,11,75,1,15,
540,11,76,4,0,
540,11,81,1,1,2
540,11,92,4,0,
540,11,97,2,0,
540,11,103,2,0,
540,11,104,4,0,
540,11,113,4,0,
540,11,138,4,0,
540,11,148,4,0,
540,11,156,4,0,
540,11,164,4,0,
540,11,170,2,0,
540,11,175,1,43,
540,11,182,4,0,
540,11,203,1,29,
540,11,207,4,0,
540,11,213,4,0,
540,11,216,4,0,
540,11,218,4,0,
540,11,219,4,0,
540,11,226,2,0,
540,11,237,4,0,
540,11,241,4,0,
540,11,263,4,0,
540,11,293,2,0,
540,11,318,2,0,
540,11,347,4,0,
540,11,371,4,0,
540,11,382,2,0,
540,11,403,2,0,
540,11,405,1,36,
540,11,412,4,0,
540,11,447,4,0,
540,11,450,1,8,
540,11,496,4,0,
540,11,522,1,22,
540,11,522,4,0,
540,14,13,2,0,
540,14,15,4,0,
540,14,33,1,1,1
540,14,75,1,15,
540,14,76,4,0,
540,14,81,1,1,2
540,14,92,4,0,
540,14,97,2,0,
540,14,103,2,0,
540,14,104,4,0,
540,14,113,4,0,
540,14,138,4,0,
540,14,148,4,0,
540,14,156,4,0,
540,14,164,4,0,
540,14,170,2,0,
540,14,173,3,0,
540,14,175,1,43,
540,14,182,4,0,
540,14,202,3,0,
540,14,203,1,29,
540,14,207,4,0,
540,14,213,4,0,
540,14,214,3,0,
540,14,216,4,0,
540,14,218,4,0,
540,14,219,4,0,
540,14,226,2,0,
540,14,235,3,0,
540,14,237,4,0,
540,14,241,4,0,
540,14,263,4,0,
540,14,277,3,0,
540,14,293,2,0,
540,14,318,2,0,
540,14,324,3,0,
540,14,334,3,0,
540,14,347,4,0,
540,14,371,4,0,
540,14,382,2,0,
540,14,388,3,0,
540,14,402,3,0,
540,14,403,2,0,
540,14,405,1,36,
540,14,412,4,0,
540,14,447,4,0,
540,14,450,1,8,
540,14,450,3,0,
540,14,496,4,0,
540,14,522,1,22,
540,14,522,4,0,
540,14,527,3,0,
540,15,13,2,0,
540,15,15,4,0,
540,15,33,1,1,1
540,15,75,1,15,
540,15,76,4,0,
540,15,81,1,1,2
540,15,92,4,0,
540,15,97,2,0,
540,15,103,2,0,
540,15,104,4,0,
540,15,113,4,0,
540,15,138,4,0,
540,15,148,4,0,
540,15,156,4,0,
540,15,164,4,0,
540,15,170,2,0,
540,15,175,1,43,
540,15,182,4,0,
540,15,203,1,29,
540,15,207,4,0,
540,15,213,4,0,
540,15,214,4,0,
540,15,216,4,0,
540,15,218,4,0,
540,15,219,4,0,
540,15,226,2,0,
540,15,237,4,0,
540,15,241,4,0,
540,15,263,4,0,
540,15,267,4,0,
540,15,293,2,0,
540,15,318,2,0,
540,15,347,4,0,
540,15,371,4,0,
540,15,382,2,0,
540,15,403,2,0,
540,15,405,1,36,
540,15,412,4,0,
540,15,447,4,0,
540,15,450,1,8,
540,15,496,4,0,
540,15,522,1,22,
540,15,522,4,0,
540,15,564,1,31,
540,15,590,4,0,
540,16,13,2,0,
540,16,15,4,0,
540,16,33,1,1,1
540,16,75,1,15,1
540,16,76,4,0,
540,16,81,1,1,2
540,16,92,4,0,
540,16,97,2,0,
540,16,103,2,0,
540,16,104,4,0,
540,16,113,4,0,
540,16,138,4,0,
540,16,148,4,0,
540,16,156,4,0,
540,16,164,4,0,
540,16,170,2,0,
540,16,173,3,0,
540,16,175,1,43,1
540,16,182,4,0,
540,16,202,3,0,
540,16,203,1,29,1
540,16,207,4,0,
540,16,213,4,0,
540,16,214,4,0,
540,16,216,4,0,
540,16,218,4,0,
540,16,219,4,0,
540,16,226,2,0,
540,16,235,3,0,
540,16,237,4,0,
540,16,241,4,0,
540,16,263,4,0,
540,16,267,4,0,
540,16,277,3,0,
540,16,290,4,0,
540,16,293,2,0,
540,16,318,2,0,
540,16,324,3,0,
540,16,334,3,0,
540,16,347,4,0,
540,16,371,4,0,
540,16,382,2,0,
540,16,388,3,0,
540,16,402,3,0,
540,16,403,2,0,
540,16,405,1,36,1
540,16,412,4,0,
540,16,447,4,0,
540,16,450,1,8,1
540,16,450,3,0,
540,16,496,4,0,
540,16,522,1,22,1
540,16,522,4,0,
540,16,527,3,0,
540,16,564,1,31,1
540,16,590,4,0,
540,17,13,2,0,
540,17,33,1,1,1
540,17,75,1,15,
540,17,76,4,0,
540,17,81,1,1,2
540,17,92,4,0,
540,17,97,2,0,
540,17,103,2,0,
540,17,104,4,0,
540,17,113,4,0,
540,17,138,4,0,
540,17,156,4,0,
540,17,164,4,0,
540,17,170,2,0,
540,17,175,1,43,
540,17,182,4,0,
540,17,203,1,29,
540,17,207,4,0,
540,17,213,4,0,
540,17,214,4,0,
540,17,216,4,0,
540,17,218,4,0,
540,17,219,4,0,
540,17,226,2,0,
540,17,237,4,0,
540,17,241,4,0,
540,17,263,4,0,
540,17,267,4,0,
540,17,293,2,0,
540,17,318,2,0,
540,17,347,4,0,
540,17,371,4,0,
540,17,382,2,0,
540,17,403,2,0,
540,17,405,1,36,
540,17,412,4,0,
540,17,447,4,0,
540,17,450,1,8,
540,17,496,4,0,
540,17,522,1,22,
540,17,564,1,31,
540,17,590,4,0,
540,18,13,2,0,
540,18,33,1,1,1
540,18,75,1,15,
540,18,76,4,0,
540,18,81,1,1,2
540,18,92,4,0,
540,18,97,2,0,
540,18,103,2,0,
540,18,104,4,0,
540,18,113,4,0,
540,18,138,4,0,
540,18,156,4,0,
540,18,164,4,0,
540,18,170,2,0,
540,18,175,1,43,
540,18,182,4,0,
540,18,203,1,29,
540,18,207,4,0,
540,18,213,4,0,
540,18,214,4,0,
540,18,216,4,0,
540,18,218,4,0,
540,18,219,4,0,
540,18,226,2,0,
540,18,237,4,0,
540,18,241,4,0,
540,18,263,4,0,
540,18,267,4,0,
540,18,293,2,0,
540,18,318,2,0,
540,18,347,4,0,
540,18,371,4,0,
540,18,382,2,0,
540,18,403,2,0,
540,18,405,1,36,
540,18,412,4,0,
540,18,447,4,0,
540,18,450,1,8,
540,18,496,4,0,
540,18,522,1,22,
540,18,564,1,31,
540,18,580,2,0,
540,18,590,4,0,
541,11,15,4,0,
541,11,33,1,1,2
541,11,75,1,1,5
541,11,76,4,0,
541,11,81,1,1,3
541,11,92,4,0,
541,11,104,4,0,
541,11,113,4,0,
541,11,138,4,0,
541,11,148,4,0,
541,11,156,4,0,
541,11,164,4,0,
541,11,182,1,20,
541,11,182,4,0,
541,11,207,4,0,
541,11,213,4,0,
541,11,216,4,0,
541,11,218,4,0,
541,11,219,4,0,
541,11,237,4,0,
541,11,241,4,0,
541,11,263,4,0,
541,11,320,1,1,1
541,11,347,4,0,
541,11,371,4,0,
541,11,412,4,0,
541,11,447,4,0,
541,11,450,1,1,4
541,11,496,4,0,
541,11,522,4,0,
541,14,15,4,0,
541,14,33,1,1,2
541,14,75,1,1,5
541,14,76,4,0,
541,14,81,1,1,3
541,14,92,4,0,
541,14,104,4,0,
541,14,113,4,0,
541,14,138,4,0,
541,14,148,4,0,
541,14,156,4,0,
541,14,164,4,0,
541,14,173,3,0,
541,14,182,1,20,
541,14,182,4,0,
541,14,202,3,0,
541,14,207,4,0,
541,14,213,4,0,
541,14,214,3,0,
541,14,216,4,0,
541,14,218,4,0,
541,14,219,4,0,
541,14,235,3,0,
541,14,237,4,0,
541,14,241,4,0,
541,14,263,4,0,
541,14,277,3,0,
541,14,320,1,1,1
541,14,324,3,0,
541,14,334,3,0,
541,14,347,4,0,
541,14,371,4,0,
541,14,388,3,0,
541,14,402,3,0,
541,14,412,4,0,
541,14,447,4,0,
541,14,450,1,1,4
541,14,450,3,0,
541,14,496,4,0,
541,14,522,4,0,
541,14,527,3,0,
541,15,15,4,0,
541,15,33,1,1,2
541,15,75,1,1,5
541,15,76,4,0,
541,15,81,1,1,3
541,15,92,4,0,
541,15,104,4,0,
541,15,113,4,0,
541,15,138,4,0,
541,15,148,4,0,
541,15,156,4,0,
541,15,164,4,0,
541,15,182,1,20,
541,15,182,4,0,
541,15,207,4,0,
541,15,213,4,0,
541,15,214,4,0,
541,15,216,4,0,
541,15,218,4,0,
541,15,219,4,0,
541,15,237,4,0,
541,15,241,4,0,
541,15,263,4,0,
541,15,267,4,0,
541,15,320,1,1,1
541,15,347,4,0,
541,15,371,4,0,
541,15,412,4,0,
541,15,447,4,0,
541,15,450,1,1,4
541,15,496,4,0,
541,15,522,4,0,
541,15,590,4,0,
541,16,15,4,0,
541,16,33,1,1,2
541,16,75,1,1,5
541,16,76,4,0,
541,16,81,1,1,3
541,16,92,4,0,
541,16,104,4,0,
541,16,113,4,0,
541,16,138,4,0,
541,16,148,4,0,
541,16,156,4,0,
541,16,164,4,0,
541,16,173,3,0,
541,16,182,1,20,1
541,16,182,4,0,
541,16,202,3,0,
541,16,207,4,0,
541,16,213,4,0,
541,16,214,4,0,
541,16,216,4,0,
541,16,218,4,0,
541,16,219,4,0,
541,16,235,3,0,
541,16,237,4,0,
541,16,241,4,0,
541,16,263,4,0,
541,16,267,4,0,
541,16,277,3,0,
541,16,290,4,0,
541,16,320,1,1,1
541,16,324,3,0,
541,16,334,3,0,
541,16,347,4,0,
541,16,371,4,0,
541,16,388,3,0,
541,16,402,3,0,
541,16,412,4,0,
541,16,447,4,0,
541,16,450,1,1,4
541,16,450,3,0,
541,16,496,4,0,
541,16,522,4,0,
541,16,527,3,0,
541,16,590,4,0,
541,17,33,1,1,3
541,17,75,1,1,6
541,17,76,4,0,
541,17,81,1,1,4
541,17,92,4,0,
541,17,104,4,0,
541,17,113,4,0,
541,17,138,4,0,
541,17,156,4,0,
541,17,164,4,0,
541,17,182,1,0,
541,17,182,1,1,1
541,17,182,4,0,
541,17,207,4,0,
541,17,213,4,0,
541,17,214,4,0,
541,17,216,4,0,
541,17,218,4,0,
541,17,219,4,0,
541,17,237,4,0,
541,17,241,4,0,
541,17,263,4,0,
541,17,267,4,0,
541,17,320,1,1,2
541,17,347,4,0,
541,17,371,4,0,
541,17,412,4,0,
541,17,447,4,0,
541,17,450,1,1,5
541,17,496,4,0,
541,17,590,4,0,
541,18,33,1,1,3
541,18,75,1,1,6
541,18,76,4,0,
541,18,81,1,1,4
541,18,92,4,0,
541,18,104,4,0,
541,18,113,4,0,
541,18,138,4,0,
541,18,156,4,0,
541,18,164,4,0,
541,18,182,1,0,
541,18,182,1,1,1
541,18,182,4,0,
541,18,207,4,0,
541,18,213,4,0,
541,18,214,4,0,
541,18,216,4,0,
541,18,218,4,0,
541,18,219,4,0,
541,18,237,4,0,
541,18,241,4,0,
541,18,263,4,0,
541,18,267,4,0,
541,18,320,1,1,2
541,18,347,4,0,
541,18,371,4,0,
541,18,412,4,0,
541,18,447,4,0,
541,18,450,1,1,5
541,18,496,4,0,
541,18,590,4,0,
542,11,14,1,46,
542,11,14,4,0,
542,11,15,4,0,
542,11,33,1,1,2
542,11,63,4,0,
542,11,75,1,1,5
542,11,75,1,15,
542,11,76,4,0,
542,11,81,1,1,3
542,11,92,4,0,
542,11,104,4,0,
542,11,113,4,0,
542,11,115,4,0,
542,11,138,4,0,
542,11,148,4,0,
542,11,156,4,0,
542,11,163,1,29,
542,11,164,4,0,
542,11,182,4,0,
542,11,206,1,1,1
542,11,206,4,0,
542,11,207,4,0,
542,11,213,4,0,
542,11,216,4,0,
542,11,218,4,0,
542,11,219,4,0,
542,11,237,4,0,
542,11,241,4,0,
542,11,263,4,0,
542,11,270,1,32,
542,11,332,4,0,
542,11,347,4,0,
542,11,348,1,36,
542,11,371,4,0,
542,11,398,4,0,
542,11,404,1,39,
542,11,404,4,0,
542,11,412,4,0,
542,11,416,4,0,
542,11,421,4,0,
542,11,437,1,50,
542,11,447,4,0,
542,11,450,1,1,4
542,11,450,1,8,
542,11,468,4,0,
542,11,494,1,43,
542,11,496,4,0,
542,11,514,4,0,
542,11,522,1,22,
542,11,522,4,0,
542,14,14,1,46,
542,14,14,4,0,
542,14,15,4,0,
542,14,33,1,1,2
542,14,63,4,0,
542,14,75,1,1,5
542,14,75,1,15,
542,14,76,4,0,
542,14,81,1,1,3
542,14,92,4,0,
542,14,104,4,0,
542,14,113,4,0,
542,14,115,4,0,
542,14,138,4,0,
542,14,148,4,0,
542,14,156,4,0,
542,14,163,1,29,
542,14,164,4,0,
542,14,173,3,0,
542,14,182,4,0,
542,14,202,3,0,
542,14,206,1,1,1
542,14,206,4,0,
542,14,207,4,0,
542,14,213,4,0,
542,14,214,3,0,
542,14,215,3,0,
542,14,216,4,0,
542,14,218,4,0,
542,14,219,4,0,
542,14,235,3,0,
542,14,237,4,0,
542,14,241,4,0,
542,14,263,4,0,
542,14,270,1,32,
542,14,270,3,0,
542,14,277,3,0,
542,14,282,3,0,
542,14,324,3,0,
542,14,332,4,0,
542,14,334,3,0,
542,14,347,4,0,
542,14,348,1,36,
542,14,371,4,0,
542,14,388,3,0,
542,14,398,4,0,
542,14,402,3,0,
542,14,404,1,39,
542,14,404,4,0,
542,14,412,4,0,
542,14,416,4,0,
542,14,421,4,0,
542,14,437,1,50,
542,14,447,4,0,
542,14,450,1,1,4
542,14,450,1,8,
542,14,450,3,0,
542,14,468,4,0,
542,14,494,1,43,
542,14,496,4,0,
542,14,514,4,0,
542,14,522,1,22,
542,14,522,4,0,
542,14,527,3,0,
542,15,14,1,46,
542,15,14,4,0,
542,15,15,4,0,
542,15,33,1,1,2
542,15,63,4,0,
542,15,75,1,1,5
542,15,75,1,15,
542,15,76,4,0,
542,15,81,1,1,3
542,15,92,4,0,
542,15,104,4,0,
542,15,113,4,0,
542,15,115,4,0,
542,15,138,4,0,
542,15,148,4,0,
542,15,156,4,0,
542,15,163,1,29,
542,15,164,4,0,
542,15,182,4,0,
542,15,206,1,1,1
542,15,206,4,0,
542,15,207,4,0,
542,15,211,4,0,
542,15,213,4,0,
542,15,214,4,0,
542,15,216,4,0,
542,15,218,4,0,
542,15,219,4,0,
542,15,237,4,0,
542,15,241,4,0,
542,15,263,4,0,
542,15,267,4,0,
542,15,270,1,32,
542,15,332,4,0,
542,15,347,4,0,
542,15,348,1,36,
542,15,371,4,0,
542,15,398,4,0,
542,15,404,1,39,
542,15,404,4,0,
542,15,412,4,0,
542,15,416,4,0,
542,15,421,4,0,
542,15,437,1,50,
542,15,447,4,0,
542,15,450,1,1,4
542,15,450,1,8,
542,15,468,4,0,
542,15,494,1,43,
542,15,496,4,0,
542,15,514,4,0,
542,15,522,1,22,
542,15,522,4,0,
542,15,565,1,34,
542,15,590,4,0,
542,16,14,1,46,1
542,16,14,4,0,
542,16,15,4,0,
542,16,33,1,1,2
542,16,63,4,0,
542,16,75,1,1,5
542,16,75,1,15,1
542,16,76,4,0,
542,16,81,1,1,3
542,16,92,4,0,
542,16,104,4,0,
542,16,113,4,0,
542,16,115,4,0,
542,16,138,4,0,
542,16,148,4,0,
542,16,156,4,0,
542,16,163,1,29,1
542,16,164,4,0,
542,16,173,3,0,
542,16,182,4,0,
542,16,202,3,0,
542,16,206,1,1,1
542,16,206,4,0,
542,16,207,4,0,
542,16,211,4,0,
542,16,213,4,0,
542,16,214,4,0,
542,16,215,3,0,
542,16,216,4,0,
542,16,218,4,0,
542,16,219,4,0,
542,16,235,3,0,
542,16,237,4,0,
542,16,241,4,0,
542,16,263,4,0,
542,16,267,4,0,
542,16,270,1,32,1
542,16,270,3,0,
542,16,277,3,0,
542,16,282,3,0,
542,16,290,4,0,
542,16,324,3,0,
542,16,332,4,0,
542,16,334,3,0,
542,16,347,4,0,
542,16,348,1,36,1
542,16,371,4,0,
542,16,388,3,0,
542,16,398,4,0,
542,16,402,3,0,
542,16,404,1,39,1
542,16,404,4,0,
542,16,412,4,0,
542,16,416,4,0,
542,16,421,4,0,
542,16,437,1,50,1
542,16,447,4,0,
542,16,450,1,1,4
542,16,450,1,8,1
542,16,450,3,0,
542,16,468,4,0,
542,16,494,1,43,1
542,16,496,4,0,
542,16,514,4,0,
542,16,522,1,22,1
542,16,522,4,0,
542,16,527,3,0,
542,16,565,1,34,1
542,16,590,4,0,
542,17,14,1,46,
542,17,14,4,0,
542,17,33,1,1,3
542,17,63,4,0,
542,17,75,1,1,6
542,17,75,1,15,
542,17,76,4,0,
542,17,81,1,1,4
542,17,92,4,0,
542,17,104,4,0,
542,17,113,4,0,
542,17,115,4,0,
542,17,138,4,0,
542,17,156,4,0,
542,17,163,1,0,
542,17,163,1,1,1
542,17,164,4,0,
542,17,182,4,0,
542,17,206,1,1,2
542,17,206,4,0,
542,17,207,4,0,
542,17,211,4,0,
542,17,213,4,0,
542,17,214,4,0,
542,17,216,4,0,
542,17,218,4,0,
542,17,219,4,0,
542,17,237,4,0,
542,17,241,4,0,
542,17,263,4,0,
542,17,267,4,0,
542,17,270,1,32,
542,17,332,4,0,
542,17,347,4,0,
542,17,348,1,36,
542,17,371,4,0,
542,17,398,4,0,
542,17,404,1,39,
542,17,404,4,0,
542,17,412,4,0,
542,17,416,4,0,
542,17,421,4,0,
542,17,437,1,50,
542,17,447,4,0,
542,17,450,1,1,5
542,17,450,1,8,
542,17,494,1,43,
542,17,496,4,0,
542,17,522,1,22,
542,17,565,1,29,
542,17,590,4,0,
542,18,14,1,46,
542,18,14,4,0,
542,18,33,1,1,3
542,18,63,4,0,
542,18,75,1,1,6
542,18,75,1,15,
542,18,76,4,0,
542,18,81,1,1,4
542,18,92,4,0,
542,18,104,4,0,
542,18,113,4,0,
542,18,115,4,0,
542,18,138,4,0,
542,18,156,4,0,
542,18,163,1,0,
542,18,163,1,1,1
542,18,164,4,0,
542,18,182,4,0,
542,18,206,1,1,2
542,18,206,4,0,
542,18,207,4,0,
542,18,211,4,0,
542,18,213,4,0,
542,18,214,4,0,
542,18,216,4,0,
542,18,218,4,0,
542,18,219,4,0,
542,18,237,4,0,
542,18,241,4,0,
542,18,263,4,0,
542,18,267,4,0,
542,18,270,1,32,
542,18,332,4,0,
542,18,347,4,0,
542,18,348,1,36,
542,18,371,4,0,
542,18,398,4,0,
542,18,404,1,39,
542,18,404,4,0,
542,18,412,4,0,
542,18,416,4,0,
542,18,421,4,0,
542,18,437,1,50,
542,18,447,4,0,
542,18,450,1,1,5
542,18,450,1,8,
542,18,494,1,43,
542,18,496,4,0,
542,18,522,1,22,
542,18,565,1,29,
542,18,590,4,0,
543,11,36,2,0,
543,11,38,1,43,
543,11,40,1,5,
543,11,41,2,0,
543,11,42,2,0,
543,11,76,4,0,
543,11,92,1,36,
543,11,92,4,0,
543,11,97,1,29,
543,11,103,1,8,
543,11,104,4,0,
543,11,111,1,1,1
543,11,156,4,0,
543,11,164,4,0,
543,11,182,1,15,
543,11,182,4,0,
543,11,188,4,0,
543,11,191,2,0,
543,11,205,1,1,2
543,11,207,4,0,
543,11,213,4,0,
543,11,216,4,0,
543,11,218,4,0,
543,11,228,1,12,
543,11,237,4,0,
543,11,241,4,0,
543,11,249,4,0,
543,11,263,4,0,
543,11,342,1,19,
543,11,360,4,0,
543,11,371,4,0,
543,11,390,2,0,
543,11,398,4,0,
543,11,431,1,40,
543,11,431,2,0,
543,11,450,1,22,
543,11,474,1,26,
543,11,474,4,0,
543,11,496,4,0,
543,11,522,4,0,
543,11,537,1,33,
543,14,36,2,0,
543,14,38,1,43,
543,14,40,1,5,
543,14,41,2,0,
543,14,42,2,0,
543,14,76,4,0,
543,14,92,1,36,
543,14,92,4,0,
543,14,97,1,29,
543,14,103,1,8,
543,14,104,4,0,
543,14,111,1,1,1
543,14,156,4,0,
543,14,164,4,0,
543,14,173,3,0,
543,14,182,1,15,
543,14,182,4,0,
543,14,188,4,0,
543,14,191,2,0,
543,14,205,1,1,2
543,14,207,4,0,
543,14,213,4,0,
543,14,214,3,0,
543,14,216,4,0,
543,14,218,4,0,
543,14,228,1,12,
543,14,237,4,0,
543,14,241,4,0,
543,14,249,4,0,
543,14,263,4,0,
543,14,283,3,0,
543,14,334,3,0,
543,14,342,1,19,
543,14,360,4,0,
543,14,371,4,0,
543,14,390,2,0,
543,14,398,4,0,
543,14,431,1,40,
543,14,431,2,0,
543,14,450,1,22,
543,14,450,3,0,
543,14,474,1,26,
543,14,474,4,0,
543,14,496,4,0,
543,14,522,4,0,
543,14,537,1,33,
543,15,36,2,0,
543,15,38,1,43,
543,15,40,1,5,
543,15,41,2,0,
543,15,42,2,0,
543,15,76,4,0,
543,15,92,1,36,
543,15,92,4,0,
543,15,97,1,29,
543,15,103,1,8,
543,15,104,4,0,
543,15,111,1,1,1
543,15,156,4,0,
543,15,164,4,0,
543,15,182,1,15,
543,15,182,4,0,
543,15,188,4,0,
543,15,191,2,0,
543,15,205,1,1,2
543,15,207,4,0,
543,15,213,4,0,
543,15,214,4,0,
543,15,216,4,0,
543,15,218,4,0,
543,15,228,1,12,
543,15,237,4,0,
543,15,241,4,0,
543,15,249,4,0,
543,15,263,4,0,
543,15,342,1,19,
543,15,360,4,0,
543,15,371,4,0,
543,15,390,2,0,
543,15,398,4,0,
543,15,431,1,40,
543,15,431,2,0,
543,15,450,1,22,
543,15,474,1,26,
543,15,474,1,38,
543,15,474,4,0,
543,15,496,4,0,
543,15,522,4,0,
543,15,537,1,33,
543,15,590,4,0,
543,15,611,4,0,
543,16,36,2,0,
543,16,38,1,43,1
543,16,40,1,5,1
543,16,41,2,0,
543,16,42,2,0,
543,16,76,4,0,
543,16,92,1,36,1
543,16,92,4,0,
543,16,97,1,29,1
543,16,103,1,8,1
543,16,104,4,0,
543,16,111,1,1,1
543,16,156,4,0,
543,16,164,4,0,
543,16,173,3,0,
543,16,182,1,15,1
543,16,182,4,0,
543,16,188,4,0,
543,16,191,2,0,
543,16,205,1,1,2
543,16,207,4,0,
543,16,213,4,0,
543,16,214,4,0,
543,16,216,4,0,
543,16,218,4,0,
543,16,228,1,12,1
543,16,237,4,0,
543,16,241,4,0,
543,16,249,4,0,
543,16,263,4,0,
543,16,283,3,0,
543,16,290,4,0,
543,16,324,3,0,
543,16,334,3,0,
543,16,342,1,19,1
543,16,360,4,0,
543,16,371,4,0,
543,16,390,2,0,
543,16,398,4,0,
543,16,431,1,40,1
543,16,431,2,0,
543,16,450,1,22,1
543,16,450,3,0,
543,16,474,1,26,1
543,16,474,1,38,1
543,16,474,4,0,
543,16,496,4,0,
543,16,522,4,0,
543,16,537,1,33,1
543,16,590,4,0,
543,16,611,4,0,
543,17,36,2,0,
543,17,38,1,43,
543,17,40,1,5,
543,17,41,2,0,
543,17,42,2,0,
543,17,76,4,0,
543,17,92,1,36,
543,17,92,4,0,
543,17,97,1,29,
543,17,103,1,8,
543,17,104,4,0,
543,17,111,1,1,1
543,17,156,4,0,
543,17,164,4,0,
543,17,182,1,15,
543,17,182,4,0,
543,17,188,4,0,
543,17,191,2,0,
543,17,205,1,1,2
543,17,207,4,0,
543,17,213,4,0,
543,17,214,4,0,
543,17,216,4,0,
543,17,218,4,0,
543,17,228,1,12,
543,17,237,4,0,
543,17,241,4,0,
543,17,263,4,0,
543,17,342,1,19,
543,17,360,4,0,
543,17,371,4,0,
543,17,390,2,0,
543,17,398,4,0,
543,17,431,1,40,
543,17,431,2,0,
543,17,450,1,22,
543,17,474,1,26,
543,17,474,1,38,
543,17,474,4,0,
543,17,496,4,0,
543,17,537,1,33,
543,17,590,4,0,
543,17,611,4,0,
543,18,36,2,0,
543,18,38,1,43,
543,18,40,1,5,
543,18,41,2,0,
543,18,42,2,0,
543,18,76,4,0,
543,18,92,1,36,
543,18,92,4,0,
543,18,97,1,29,
543,18,103,1,8,
543,18,104,4,0,
543,18,111,1,1,1
543,18,156,4,0,
543,18,164,4,0,
543,18,182,1,15,
543,18,182,4,0,
543,18,188,4,0,
543,18,191,2,0,
543,18,205,1,1,2
543,18,207,4,0,
543,18,213,4,0,
543,18,214,4,0,
543,18,216,4,0,
543,18,218,4,0,
543,18,228,1,12,
543,18,237,4,0,
543,18,241,4,0,
543,18,263,4,0,
543,18,342,1,19,
543,18,360,4,0,
543,18,371,4,0,
543,18,390,2,0,
543,18,398,4,0,
543,18,431,1,40,
543,18,431,2,0,
543,18,450,1,22,
543,18,474,1,26,
543,18,474,4,0,
543,18,496,4,0,
543,18,537,1,33,
543,18,590,4,0,
543,18,599,1,38,
543,18,611,4,0,
544,11,38,1,50,
544,11,40,1,1,3
544,11,40,1,5,
544,11,76,4,0,
544,11,92,1,41,
544,11,92,4,0,
544,11,97,1,32,
544,11,103,1,1,4
544,11,103,1,8,
544,11,104,4,0,
544,11,111,1,1,1
544,11,156,4,0,
544,11,164,4,0,
544,11,182,1,15,
544,11,182,4,0,
544,11,188,4,0,
544,11,205,1,1,2
544,11,207,4,0,
544,11,213,4,0,
544,11,216,4,0,
544,11,218,4,0,
544,11,228,1,12,
544,11,237,4,0,
544,11,241,4,0,
544,11,249,4,0,
544,11,263,4,0,
544,11,334,1,22,
544,11,342,1,19,
544,11,360,4,0,
544,11,371,4,0,
544,11,398,4,0,
544,11,431,1,46,
544,11,450,1,23,
544,11,474,1,28,
544,11,474,4,0,
544,11,496,4,0,
544,11,522,4,0,
544,11,537,1,37,
544,14,38,1,50,
544,14,40,1,1,3
544,14,40,1,5,
544,14,76,4,0,
544,14,92,1,41,
544,14,92,4,0,
544,14,97,1,32,
544,14,103,1,1,4
544,14,103,1,8,
544,14,104,4,0,
544,14,111,1,1,1
544,14,156,4,0,
544,14,164,4,0,
544,14,173,3,0,
544,14,182,1,15,
544,14,182,4,0,
544,14,188,4,0,
544,14,205,1,1,2
544,14,207,4,0,
544,14,213,4,0,
544,14,214,3,0,
544,14,216,4,0,
544,14,218,4,0,
544,14,228,1,12,
544,14,237,4,0,
544,14,241,4,0,
544,14,249,4,0,
544,14,263,4,0,
544,14,283,3,0,
544,14,334,1,22,
544,14,334,3,0,
544,14,342,1,19,
544,14,360,4,0,
544,14,371,4,0,
544,14,398,4,0,
544,14,431,1,46,
544,14,450,1,23,
544,14,450,3,0,
544,14,474,1,28,
544,14,474,4,0,
544,14,496,4,0,
544,14,522,4,0,
544,14,537,1,37,
544,15,38,1,50,
544,15,40,1,1,3
544,15,40,1,5,
544,15,76,4,0,
544,15,92,1,41,
544,15,92,4,0,
544,15,97,1,32,
544,15,103,1,1,4
544,15,103,1,8,
544,15,104,4,0,
544,15,111,1,1,1
544,15,156,4,0,
544,15,164,4,0,
544,15,182,1,15,
544,15,182,4,0,
544,15,188,4,0,
544,15,205,1,1,2
544,15,207,4,0,
544,15,213,4,0,
544,15,214,4,0,
544,15,216,4,0,
544,15,218,4,0,
544,15,228,1,12,
544,15,237,4,0,
544,15,241,4,0,
544,15,249,4,0,
544,15,263,4,0,
544,15,334,1,22,
544,15,342,1,19,
544,15,360,4,0,
544,15,371,4,0,
544,15,398,4,0,
544,15,431,1,46,
544,15,450,1,23,
544,15,474,1,28,
544,15,474,4,0,
544,15,496,4,0,
544,15,522,4,0,
544,15,537,1,37,
544,15,590,4,0,
544,15,599,1,43,
544,15,611,4,0,
544,16,38,1,50,1
544,16,40,1,1,3
544,16,40,1,5,1
544,16,76,4,0,
544,16,92,1,41,1
544,16,92,4,0,
544,16,97,1,32,1
544,16,103,1,1,4
544,16,103,1,8,1
544,16,104,4,0,
544,16,111,1,1,1
544,16,156,4,0,
544,16,164,4,0,
544,16,173,3,0,
544,16,182,1,15,1
544,16,182,4,0,
544,16,188,4,0,
544,16,205,1,1,2
544,16,207,4,0,
544,16,213,4,0,
544,16,214,4,0,
544,16,216,4,0,
544,16,218,4,0,
544,16,228,1,12,1
544,16,237,4,0,
544,16,241,4,0,
544,16,249,4,0,
544,16,263,4,0,
544,16,283,3,0,
544,16,290,4,0,
544,16,324,3,0,
544,16,334,1,22,1
544,16,334,3,0,
544,16,342,1,19,1
544,16,360,4,0,
544,16,371,4,0,
544,16,398,4,0,
544,16,431,1,46,1
544,16,450,1,23,1
544,16,450,3,0,
544,16,474,1,28,1
544,16,474,4,0,
544,16,496,4,0,
544,16,522,4,0,
544,16,537,1,37,1
544,16,590,4,0,
544,16,599,1,43,1
544,16,611,4,0,
544,17,38,1,50,
544,17,40,1,1,4
544,17,40,1,5,
544,17,76,4,0,
544,17,92,1,41,
544,17,92,4,0,
544,17,97,1,32,
544,17,103,1,1,5
544,17,103,1,8,
544,17,104,4,0,
544,17,111,1,1,2
544,17,156,4,0,
544,17,164,4,0,
544,17,182,1,15,
544,17,182,4,0,
544,17,188,4,0,
544,17,205,1,1,3
544,17,207,4,0,
544,17,213,4,0,
544,17,214,4,0,
544,17,216,4,0,
544,17,218,4,0,
544,17,228,1,12,
544,17,237,4,0,
544,17,241,4,0,
544,17,263,4,0,
544,17,334,1,0,
544,17,334,1,1,1
544,17,342,1,19,
544,17,360,4,0,
544,17,371,4,0,
544,17,398,4,0,
544,17,431,1,46,
544,17,450,1,23,
544,17,474,1,28,
544,17,474,4,0,
544,17,496,4,0,
544,17,537,1,37,
544,17,590,4,0,
544,17,599,1,43,
544,17,611,4,0,
544,18,38,1,50,
544,18,40,1,1,4
544,18,40,1,5,
544,18,76,4,0,
544,18,92,1,41,
544,18,92,4,0,
544,18,97,1,32,
544,18,103,1,1,5
544,18,103,1,8,
544,18,104,4,0,
544,18,111,1,1,2
544,18,156,4,0,
544,18,164,4,0,
544,18,182,1,15,
544,18,182,4,0,
544,18,188,4,0,
544,18,205,1,1,3
544,18,207,4,0,
544,18,213,4,0,
544,18,214,4,0,
544,18,216,4,0,
544,18,218,4,0,
544,18,228,1,12,
544,18,237,4,0,
544,18,241,4,0,
544,18,263,4,0,
544,18,334,1,0,
544,18,334,1,1,1
544,18,342,1,19,
544,18,360,4,0,
544,18,371,4,0,
544,18,398,4,0,
544,18,431,1,46,
544,18,450,1,23,
544,18,474,1,28,
544,18,474,4,0,
544,18,496,4,0,
544,18,537,1,37,
544,18,590,4,0,
544,18,599,1,43,
544,18,611,4,0,
545,11,14,4,0,
545,11,15,4,0,
545,11,38,1,55,
545,11,40,1,1,4
545,11,40,1,5,
545,11,63,4,0,
545,11,70,4,0,
545,11,76,4,0,
545,11,89,4,0,
545,11,91,4,0,
545,11,92,1,44,
545,11,92,4,0,
545,11,97,1,33,
545,11,103,1,1,5
545,11,103,1,8,
545,11,104,4,0,
545,11,111,1,1,2
545,11,156,4,0,
545,11,157,4,0,
545,11,164,4,0,
545,11,182,1,15,
545,11,182,4,0,
545,11,188,4,0,
545,11,205,1,1,3
545,11,207,4,0,
545,11,213,4,0,
545,11,216,4,0,
545,11,218,4,0,
545,11,224,1,1,1
545,11,226,1,30,
545,11,228,1,12,
545,11,237,4,0,
545,11,241,4,0,
545,11,249,4,0,
545,11,263,4,0,
545,11,317,4,0,
545,11,342,1,19,
545,11,360,4,0,
545,11,371,4,0,
545,11,398,4,0,
545,11,404,4,0,
545,11,416,4,0,
545,11,431,1,50,
545,11,450,1,23,
545,11,474,1,28,
545,11,474,4,0,
545,11,496,4,0,
545,11,522,4,0,
545,11,523,4,0,
545,11,537,1,39,
545,14,14,4,0,
545,14,15,4,0,
545,14,38,1,55,
545,14,40,1,1,4
545,14,40,1,5,
545,14,63,4,0,
545,14,70,4,0,
545,14,76,4,0,
545,14,89,4,0,
545,14,91,4,0,
545,14,92,1,44,
545,14,92,4,0,
545,14,97,1,33,
545,14,103,1,1,5
545,14,103,1,8,
545,14,104,4,0,
545,14,111,1,1,2
545,14,156,4,0,
545,14,157,4,0,
545,14,164,4,0,
545,14,173,3,0,
545,14,182,1,15,
545,14,182,4,0,
545,14,188,4,0,
545,14,205,1,1,3
545,14,207,4,0,
545,14,213,4,0,
545,14,214,3,0,
545,14,216,4,0,
545,14,218,4,0,
545,14,224,1,1,1
545,14,226,1,30,
545,14,228,1,12,
545,14,231,3,0,
545,14,237,4,0,
545,14,241,4,0,
545,14,249,4,0,
545,14,263,4,0,
545,14,276,3,0,
545,14,283,3,0,
545,14,289,3,0,
545,14,317,4,0,
545,14,334,3,0,
545,14,342,1,19,
545,14,360,4,0,
545,14,371,4,0,
545,14,398,4,0,
545,14,401,3,0,
545,14,404,4,0,
545,14,416,4,0,
545,14,431,1,50,
545,14,450,1,23,
545,14,450,3,0,
545,14,474,1,28,
545,14,474,4,0,
545,14,496,4,0,
545,14,522,4,0,
545,14,523,4,0,
545,14,537,1,39,
545,15,14,4,0,
545,15,15,4,0,
545,15,38,1,55,
545,15,40,1,1,4
545,15,40,1,5,
545,15,63,4,0,
545,15,70,4,0,
545,15,76,4,0,
545,15,89,4,0,
545,15,91,4,0,
545,15,92,1,44,
545,15,92,4,0,
545,15,97,1,33,
545,15,103,1,1,5
545,15,103,1,8,
545,15,104,4,0,
545,15,111,1,1,2
545,15,156,4,0,
545,15,157,4,0,
545,15,164,4,0,
545,15,182,1,15,
545,15,182,4,0,
545,15,188,4,0,
545,15,205,1,1,3
545,15,207,4,0,
545,15,213,4,0,
545,15,214,4,0,
545,15,216,4,0,
545,15,218,4,0,
545,15,224,1,1,1
545,15,224,1,65,
545,15,226,1,30,
545,15,228,1,12,
545,15,237,4,0,
545,15,241,4,0,
545,15,249,4,0,
545,15,263,4,0,
545,15,317,4,0,
545,15,342,1,19,
545,15,360,4,0,
545,15,371,4,0,
545,15,398,4,0,
545,15,404,4,0,
545,15,416,4,0,
545,15,431,1,50,
545,15,450,1,23,
545,15,474,1,28,
545,15,474,4,0,
545,15,496,4,0,
545,15,522,4,0,
545,15,523,4,0,
545,15,537,1,39,
545,15,590,4,0,
545,15,599,1,47,
545,15,611,4,0,
545,16,14,4,0,
545,16,15,4,0,
545,16,38,1,55,1
545,16,40,1,1,4
545,16,40,1,5,1
545,16,63,4,0,
545,16,70,4,0,
545,16,76,4,0,
545,16,89,4,0,
545,16,91,4,0,
545,16,92,1,44,1
545,16,92,4,0,
545,16,97,1,33,1
545,16,103,1,1,5
545,16,103,1,8,1
545,16,104,4,0,
545,16,111,1,1,2
545,16,156,4,0,
545,16,157,4,0,
545,16,164,4,0,
545,16,173,3,0,
545,16,182,1,15,1
545,16,182,4,0,
545,16,188,4,0,
545,16,205,1,1,3
545,16,207,4,0,
545,16,213,4,0,
545,16,214,4,0,
545,16,216,4,0,
545,16,218,4,0,
545,16,224,1,1,1
545,16,224,1,65,1
545,16,226,1,30,1
545,16,228,1,12,1
545,16,231,3,0,
545,16,237,4,0,
545,16,241,4,0,
545,16,249,4,0,
545,16,263,4,0,
545,16,276,3,0,
545,16,283,3,0,
545,16,289,3,0,
545,16,290,4,0,
545,16,317,4,0,
545,16,324,3,0,
545,16,334,3,0,
545,16,342,1,19,1
545,16,360,4,0,
545,16,371,4,0,
545,16,398,4,0,
545,16,401,3,0,
545,16,404,4,0,
545,16,416,4,0,
545,16,431,1,50,1
545,16,450,1,23,1
545,16,450,3,0,
545,16,474,1,28,1
545,16,474,4,0,
545,16,496,4,0,
545,16,522,4,0,
545,16,523,4,0,
545,16,537,1,39,1
545,16,590,4,0,
545,16,599,1,47,1
545,16,611,4,0,
545,17,14,4,0,
545,17,38,1,55,
545,17,40,1,1,6
545,17,40,1,5,
545,17,63,4,0,
545,17,76,4,0,
545,17,89,4,0,
545,17,92,1,44,
545,17,92,4,0,
545,17,97,1,33,
545,17,103,1,1,7
545,17,103,1,8,
545,17,104,4,0,
545,17,111,1,1,4
545,17,156,4,0,
545,17,157,4,0,
545,17,164,4,0,
545,17,182,1,15,
545,17,182,4,0,
545,17,188,4,0,
545,17,205,1,1,5
545,17,207,4,0,
545,17,213,4,0,
545,17,214,4,0,
545,17,216,4,0,
545,17,218,4,0,
545,17,224,1,1,3
545,17,224,1,65,
545,17,226,1,0,
545,17,226,1,1,1
545,17,228,1,12,
545,17,237,4,0,
545,17,241,4,0,
545,17,263,4,0,
545,17,317,4,0,
545,17,334,1,1,2
545,17,342,1,19,
545,17,360,4,0,
545,17,371,4,0,
545,17,398,4,0,
545,17,404,4,0,
545,17,416,4,0,
545,17,431,1,50,
545,17,450,1,23,
545,17,474,1,28,
545,17,474,4,0,
545,17,496,4,0,
545,17,523,4,0,
545,17,537,1,39,
545,17,590,4,0,
545,17,599,1,47,
545,17,611,4,0,
545,17,684,4,0,
545,18,14,4,0,
545,18,38,1,55,
545,18,40,1,1,6
545,18,40,1,5,
545,18,63,4,0,
545,18,76,4,0,
545,18,89,4,0,
545,18,92,1,44,
545,18,92,4,0,
545,18,97,1,33,
545,18,103,1,1,7
545,18,103,1,8,
545,18,104,4,0,
545,18,111,1,1,4
545,18,156,4,0,
545,18,157,4,0,
545,18,164,4,0,
545,18,182,1,15,
545,18,182,4,0,
545,18,188,4,0,
545,18,205,1,1,5
545,18,207,4,0,
545,18,213,4,0,
545,18,214,4,0,
545,18,216,4,0,
545,18,218,4,0,
545,18,224,1,1,3
545,18,224,1,65,
545,18,226,1,0,
545,18,226,1,1,1
545,18,228,1,12,
545,18,237,4,0,
545,18,241,4,0,
545,18,263,4,0,
545,18,317,4,0,
545,18,334,1,1,2
545,18,342,1,19,
545,18,360,4,0,
545,18,371,4,0,
545,18,398,4,0,
545,18,404,4,0,
545,18,416,4,0,
545,18,431,1,50,
545,18,450,1,23,
545,18,474,1,28,
545,18,474,4,0,
545,18,496,4,0,
545,18,523,4,0,
545,18,537,1,39,
545,18,590,4,0,
545,18,599,1,47,
545,18,611,4,0,
545,18,684,4,0,
546,11,71,1,1,
546,11,72,1,13,
546,11,73,1,8,
546,11,74,1,4,
546,11,75,1,19,
546,11,76,1,46,
546,11,76,4,0,
546,11,77,1,22,
546,11,78,1,10,
546,11,92,4,0,
546,11,104,4,0,
546,11,138,4,0,
546,11,148,4,0,
546,11,156,4,0,
546,11,164,4,0,
546,11,178,1,17,
546,11,182,4,0,
546,11,202,1,26,
546,11,204,1,28,
546,11,207,4,0,
546,11,213,4,0,
546,11,216,4,0,
546,11,218,4,0,
546,11,219,4,0,
546,11,227,2,0,
546,11,237,4,0,
546,11,241,1,40,
546,11,241,4,0,
546,11,251,2,0,
546,11,262,2,0,
546,11,263,4,0,
546,11,269,4,0,
546,11,270,1,31,
546,11,283,1,44,
546,11,313,2,0,
546,11,320,2,0,
546,11,321,2,0,
546,11,363,2,0,
546,11,388,2,0,
546,11,412,1,35,
546,11,412,4,0,
546,11,415,2,0,
546,11,447,4,0,
546,11,496,4,0,
546,11,538,1,37,
546,14,71,1,1,
546,14,72,1,13,
546,14,73,1,8,
546,14,74,1,4,
546,14,75,1,19,
546,14,76,1,46,
546,14,76,4,0,
546,14,77,1,22,
546,14,78,1,10,
546,14,92,4,0,
546,14,104,4,0,
546,14,138,4,0,
546,14,148,4,0,
546,14,156,4,0,
546,14,164,4,0,
546,14,173,3,0,
546,14,178,1,17,
546,14,182,4,0,
546,14,202,1,26,
546,14,202,3,0,
546,14,204,1,28,
546,14,207,4,0,
546,14,213,4,0,
546,14,214,3,0,
546,14,216,4,0,
546,14,218,4,0,
546,14,219,4,0,
546,14,227,2,0,
546,14,237,4,0,
546,14,241,1,40,
546,14,241,4,0,
546,14,251,2,0,
546,14,262,2,0,
546,14,263,4,0,
546,14,269,4,0,
546,14,270,1,31,
546,14,270,3,0,
546,14,282,3,0,
546,14,283,1,44,
546,14,283,3,0,
546,14,313,2,0,
546,14,320,2,0,
546,14,321,2,0,
546,14,343,3,0,
546,14,363,2,0,
546,14,366,3,0,
546,14,388,2,0,
546,14,388,3,0,
546,14,402,3,0,
546,14,412,1,35,
546,14,412,4,0,
546,14,415,2,0,
546,14,447,4,0,
546,14,496,4,0,
546,14,538,1,37,
546,15,71,1,1,1
546,15,72,1,13,
546,15,73,1,8,
546,15,74,1,4,
546,15,75,1,19,
546,15,76,1,46,
546,15,76,4,0,
546,15,77,1,22,
546,15,78,1,10,
546,15,92,4,0,
546,15,104,4,0,
546,15,138,4,0,
546,15,148,4,0,
546,15,156,4,0,
546,15,164,4,0,
546,15,178,1,17,
546,15,182,4,0,
546,15,202,1,26,
546,15,204,1,28,
546,15,207,4,0,
546,15,213,4,0,
546,15,214,4,0,
546,15,216,4,0,
546,15,218,4,0,
546,15,219,4,0,
546,15,227,2,0,
546,15,237,4,0,
546,15,241,1,40,
546,15,241,4,0,
546,15,251,2,0,
546,15,262,2,0,
546,15,263,4,0,
546,15,267,4,0,
546,15,269,4,0,
546,15,270,1,31,
546,15,283,1,44,
546,15,313,2,0,
546,15,320,2,0,
546,15,321,2,0,
546,15,363,2,0,
546,15,388,2,0,
546,15,412,1,35,
546,15,412,4,0,
546,15,415,2,0,
546,15,445,2,0,
546,15,447,4,0,
546,15,496,4,0,
546,15,538,1,37,
546,15,584,1,1,2
546,15,590,4,0,
546,15,605,4,0,
546,16,71,1,1,1
546,16,72,1,13,1
546,16,73,1,8,1
546,16,74,1,4,1
546,16,75,1,19,1
546,16,76,1,46,1
546,16,76,4,0,
546,16,77,1,22,1
546,16,78,1,10,1
546,16,92,4,0,
546,16,104,4,0,
546,16,138,4,0,
546,16,148,4,0,
546,16,156,4,0,
546,16,164,4,0,
546,16,173,3,0,
546,16,178,1,17,1
546,16,182,4,0,
546,16,202,1,26,1
546,16,202,3,0,
546,16,204,1,28,1
546,16,207,4,0,
546,16,213,4,0,
546,16,214,4,0,
546,16,216,4,0,
546,16,218,4,0,
546,16,219,4,0,
546,16,227,2,0,
546,16,237,4,0,
546,16,241,1,40,1
546,16,241,4,0,
546,16,251,2,0,
546,16,262,2,0,
546,16,263,4,0,
546,16,267,4,0,
546,16,269,4,0,
546,16,270,1,31,1
546,16,270,3,0,
546,16,282,3,0,
546,16,283,1,44,1
546,16,283,3,0,
546,16,290,4,0,
546,16,313,2,0,
546,16,320,2,0,
546,16,321,2,0,
546,16,343,3,0,
546,16,363,2,0,
546,16,366,3,0,
546,16,388,2,0,
546,16,388,3,0,
546,16,402,3,0,
546,16,412,1,35,1
546,16,412,4,0,
546,16,415,2,0,
546,16,445,2,0,
546,16,447,4,0,
546,16,496,4,0,
546,16,538,1,37,1
546,16,584,1,1,2
546,16,590,4,0,
546,16,605,4,0,
546,17,71,1,1,1
546,17,72,1,13,
546,17,73,1,8,
546,17,74,1,4,
546,17,75,1,19,
546,17,76,1,46,
546,17,76,4,0,
546,17,77,1,22,
546,17,78,1,10,
546,17,92,4,0,
546,17,104,4,0,
546,17,138,4,0,
546,17,156,4,0,
546,17,164,4,0,
546,17,178,1,17,
546,17,182,4,0,
546,17,202,1,26,
546,17,204,1,28,
546,17,207,4,0,
546,17,213,4,0,
546,17,214,4,0,
546,17,216,4,0,
546,17,218,4,0,
546,17,219,4,0,
546,17,227,2,0,
546,17,237,4,0,
546,17,241,1,40,
546,17,241,4,0,
546,17,251,2,0,
546,17,262,2,0,
546,17,263,4,0,
546,17,267,4,0,
546,17,269,4,0,
546,17,270,1,31,
546,17,283,1,44,
546,17,313,2,0,
546,17,320,2,0,
546,17,321,2,0,
546,17,363,2,0,
546,17,388,2,0,
546,17,412,1,35,
546,17,412,4,0,
546,17,415,2,0,
546,17,445,2,0,
546,17,447,4,0,
546,17,496,4,0,
546,17,538,1,37,
546,17,584,1,1,2
546,17,590,4,0,
546,17,605,4,0,
546,18,71,1,1,1
546,18,72,1,13,
546,18,73,1,8,
546,18,74,1,4,
546,18,75,1,19,
546,18,76,1,46,
546,18,76,4,0,
546,18,77,1,22,
546,18,78,1,10,
546,18,92,4,0,
546,18,104,4,0,
546,18,138,4,0,
546,18,156,4,0,
546,18,164,4,0,
546,18,178,1,17,
546,18,182,4,0,
546,18,202,1,26,
546,18,204,1,28,
546,18,207,4,0,
546,18,213,4,0,
546,18,214,4,0,
546,18,216,4,0,
546,18,218,4,0,
546,18,219,4,0,
546,18,227,2,0,
546,18,237,4,0,
546,18,241,1,40,
546,18,241,4,0,
546,18,251,2,0,
546,18,262,2,0,
546,18,263,4,0,
546,18,267,4,0,
546,18,269,4,0,
546,18,270,1,31,
546,18,283,1,44,
546,18,313,2,0,
546,18,320,2,0,
546,18,321,2,0,
546,18,363,2,0,
546,18,388,2,0,
546,18,412,1,35,
546,18,412,4,0,
546,18,415,2,0,
546,18,445,2,0,
546,18,447,4,0,
546,18,496,4,0,
546,18,538,1,37,
546,18,581,2,0,
546,18,584,1,1,2
546,18,590,4,0,
546,18,605,4,0,
547,11,16,1,10,
547,11,63,4,0,
547,11,72,1,1,3
547,11,73,1,1,2
547,11,74,1,1,1
547,11,76,4,0,
547,11,92,4,0,
547,11,94,4,0,
547,11,104,4,0,
547,11,113,4,0,
547,11,138,4,0,
547,11,148,4,0,
547,11,156,4,0,
547,11,164,4,0,
547,11,168,4,0,
547,11,178,1,1,4
547,11,182,4,0,
547,11,207,4,0,
547,11,213,4,0,
547,11,216,4,0,
547,11,218,4,0,
547,11,219,4,0,
547,11,237,4,0,
547,11,241,4,0,
547,11,247,4,0,
547,11,263,4,0,
547,11,269,4,0,
547,11,366,1,28,
547,11,369,4,0,
547,11,374,4,0,
547,11,412,4,0,
547,11,416,4,0,
547,11,433,4,0,
547,11,447,4,0,
547,11,496,4,0,
547,11,542,1,46,
547,14,16,1,10,
547,14,63,4,0,
547,14,72,1,1,3
547,14,73,1,1,2
547,14,74,1,1,1
547,14,76,4,0,
547,14,92,4,0,
547,14,94,4,0,
547,14,104,4,0,
547,14,113,4,0,
547,14,138,4,0,
547,14,148,4,0,
547,14,156,4,0,
547,14,164,4,0,
547,14,168,4,0,
547,14,173,3,0,
547,14,178,1,1,4
547,14,182,4,0,
547,14,202,3,0,
547,14,207,4,0,
547,14,213,4,0,
547,14,214,3,0,
547,14,216,4,0,
547,14,218,4,0,
547,14,219,4,0,
547,14,237,4,0,
547,14,241,4,0,
547,14,247,4,0,
547,14,263,4,0,
547,14,269,4,0,
547,14,270,3,0,
547,14,282,3,0,
547,14,283,3,0,
547,14,343,3,0,
547,14,366,1,28,
547,14,366,3,0,
547,14,369,4,0,
547,14,374,4,0,
547,14,388,3,0,
547,14,402,3,0,
547,14,412,4,0,
547,14,416,4,0,
547,14,433,4,0,
547,14,447,4,0,
547,14,496,4,0,
547,14,542,1,46,
547,15,16,1,10,
547,15,63,4,0,
547,15,72,1,1,3
547,15,73,1,1,2
547,15,74,1,1,1
547,15,76,4,0,
547,15,92,4,0,
547,15,94,4,0,
547,15,104,4,0,
547,15,113,4,0,
547,15,138,4,0,
547,15,148,4,0,
547,15,156,4,0,
547,15,164,4,0,
547,15,168,4,0,
547,15,178,1,1,4
547,15,182,4,0,
547,15,207,4,0,
547,15,213,4,0,
547,15,214,4,0,
547,15,216,4,0,
547,15,218,4,0,
547,15,219,4,0,
547,15,237,4,0,
547,15,241,4,0,
547,15,247,4,0,
547,15,263,4,0,
547,15,267,4,0,
547,15,269,4,0,
547,15,366,1,28,
547,15,369,4,0,
547,15,374,4,0,
547,15,412,4,0,
547,15,416,4,0,
547,15,433,4,0,
547,15,447,4,0,
547,15,496,4,0,
547,15,542,1,46,
547,15,585,1,50,
547,15,590,4,0,
547,15,605,4,0,
547,16,16,1,10,1
547,16,63,4,0,
547,16,72,1,1,3
547,16,73,1,1,2
547,16,74,1,1,1
547,16,76,4,0,
547,16,92,4,0,
547,16,94,4,0,
547,16,104,4,0,
547,16,113,4,0,
547,16,138,4,0,
547,16,148,4,0,
547,16,156,4,0,
547,16,164,4,0,
547,16,168,4,0,
547,16,173,3,0,
547,16,178,1,1,4
547,16,182,4,0,
547,16,202,3,0,
547,16,207,4,0,
547,16,213,4,0,
547,16,214,4,0,
547,16,216,4,0,
547,16,218,4,0,
547,16,219,4,0,
547,16,237,4,0,
547,16,241,4,0,
547,16,247,4,0,
547,16,263,4,0,
547,16,267,4,0,
547,16,269,4,0,
547,16,270,3,0,
547,16,282,3,0,
547,16,283,3,0,
547,16,290,4,0,
547,16,343,3,0,
547,16,366,1,28,1
547,16,366,3,0,
547,16,369,4,0,
547,16,374,4,0,
547,16,388,3,0,
547,16,402,3,0,
547,16,412,4,0,
547,16,416,4,0,
547,16,433,4,0,
547,16,447,4,0,
547,16,496,4,0,
547,16,542,1,46,1
547,16,585,1,50,1
547,16,590,4,0,
547,16,605,4,0,
547,17,16,1,10,
547,17,63,4,0,
547,17,72,1,1,3
547,17,73,1,1,2
547,17,74,1,1,1
547,17,76,4,0,
547,17,92,4,0,
547,17,94,4,0,
547,17,104,4,0,
547,17,113,4,0,
547,17,138,4,0,
547,17,156,4,0,
547,17,164,4,0,
547,17,168,4,0,
547,17,178,1,1,4
547,17,182,4,0,
547,17,207,4,0,
547,17,213,4,0,
547,17,214,4,0,
547,17,216,4,0,
547,17,218,4,0,
547,17,219,4,0,
547,17,237,4,0,
547,17,241,4,0,
547,17,247,4,0,
547,17,263,4,0,
547,17,267,4,0,
547,17,269,4,0,
547,17,366,1,28,
547,17,369,4,0,
547,17,374,4,0,
547,17,412,4,0,
547,17,416,4,0,
547,17,433,4,0,
547,17,447,4,0,
547,17,496,4,0,
547,17,542,1,46,
547,17,585,1,50,
547,17,590,4,0,
547,17,605,4,0,
547,18,16,1,10,
547,18,63,4,0,
547,18,72,1,1,3
547,18,73,1,1,2
547,18,74,1,1,1
547,18,76,4,0,
547,18,92,4,0,
547,18,94,4,0,
547,18,104,4,0,
547,18,113,4,0,
547,18,138,4,0,
547,18,156,4,0,
547,18,164,4,0,
547,18,168,4,0,
547,18,178,1,1,4
547,18,182,4,0,
547,18,207,4,0,
547,18,213,4,0,
547,18,214,4,0,
547,18,216,4,0,
547,18,218,4,0,
547,18,219,4,0,
547,18,237,4,0,
547,18,241,4,0,
547,18,247,4,0,
547,18,263,4,0,
547,18,267,4,0,
547,18,269,4,0,
547,18,366,1,28,
547,18,369,4,0,
547,18,374,4,0,
547,18,412,4,0,
547,18,416,4,0,
547,18,433,4,0,
547,18,447,4,0,
547,18,496,4,0,
547,18,542,1,46,
547,18,585,1,50,
547,18,590,4,0,
547,18,605,4,0,
548,11,15,4,0,
548,11,71,1,1,
548,11,72,1,13,
548,11,73,1,8,
548,11,74,1,4,
548,11,76,4,0,
548,11,78,1,22,
548,11,79,1,10,
548,11,92,4,0,
548,11,104,4,0,
548,11,117,2,0,
548,11,138,4,0,
548,11,148,4,0,
548,11,156,4,0,
548,11,164,4,0,
548,11,182,4,0,
548,11,202,1,26,
548,11,203,2,0,
548,11,204,2,0,
548,11,207,4,0,
548,11,213,4,0,
548,11,216,4,0,
548,11,218,4,0,
548,11,219,4,0,
548,11,230,2,0,
548,11,235,1,17,
548,11,237,4,0,
548,11,241,1,40,
548,11,241,4,0,
548,11,263,4,0,
548,11,270,1,31,
548,11,275,2,0,
548,11,312,1,28,
548,11,320,2,0,
548,11,345,1,19,
548,11,361,2,0,
548,11,363,2,0,
548,11,388,2,0,
548,11,412,1,35,
548,11,412,4,0,
548,11,437,1,46,
548,11,447,4,0,
548,11,494,1,37,
548,11,495,1,44,
548,11,496,4,0,
548,14,15,4,0,
548,14,71,1,1,
548,14,72,1,13,
548,14,73,1,8,
548,14,74,1,4,
548,14,76,4,0,
548,14,78,1,22,
548,14,79,1,10,
548,14,92,4,0,
548,14,104,4,0,
548,14,117,2,0,
548,14,138,4,0,
548,14,148,4,0,
548,14,156,4,0,
548,14,164,4,0,
548,14,173,3,0,
548,14,182,4,0,
548,14,202,1,26,
548,14,202,3,0,
548,14,203,2,0,
548,14,204,2,0,
548,14,207,4,0,
548,14,213,4,0,
548,14,214,3,0,
548,14,215,3,0,
548,14,216,4,0,
548,14,218,4,0,
548,14,219,4,0,
548,14,230,2,0,
548,14,235,1,17,
548,14,235,3,0,
548,14,237,4,0,
548,14,241,1,40,
548,14,241,4,0,
548,14,263,4,0,
548,14,270,1,31,
548,14,270,3,0,
548,14,275,2,0,
548,14,312,1,28,
548,14,320,2,0,
548,14,343,3,0,
548,14,345,1,19,
548,14,361,2,0,
548,14,363,2,0,
548,14,388,2,0,
548,14,388,3,0,
548,14,402,3,0,
548,14,412,1,35,
548,14,412,4,0,
548,14,437,1,46,
548,14,447,4,0,
548,14,494,1,37,
548,14,495,1,44,
548,14,495,3,0,
548,14,496,4,0,
548,15,15,4,0,
548,15,71,1,1,
548,15,72,1,13,
548,15,73,1,8,
548,15,74,1,4,
548,15,76,4,0,
548,15,78,1,22,
548,15,79,1,10,
548,15,92,4,0,
548,15,104,4,0,
548,15,117,2,0,
548,15,138,4,0,
548,15,148,4,0,
548,15,156,4,0,
548,15,164,4,0,
548,15,182,4,0,
548,15,202,1,26,
548,15,203,2,0,
548,15,204,2,0,
548,15,207,4,0,
548,15,213,4,0,
548,15,214,4,0,
548,15,216,4,0,
548,15,218,4,0,
548,15,219,4,0,
548,15,230,2,0,
548,15,235,1,17,
548,15,237,4,0,
548,15,241,1,40,
548,15,241,4,0,
548,15,263,4,0,
548,15,267,4,0,
548,15,270,1,31,
548,15,275,2,0,
548,15,312,1,28,
548,15,320,2,0,
548,15,345,1,19,
548,15,361,2,0,
548,15,363,2,0,
548,15,388,2,0,
548,15,412,1,35,
548,15,412,4,0,
548,15,437,1,46,
548,15,447,4,0,
548,15,494,1,37,
548,15,495,1,44,
548,15,496,4,0,
548,15,590,4,0,
548,16,15,4,0,
548,16,71,1,1,1
548,16,72,1,13,1
548,16,73,1,8,1
548,16,74,1,4,1
548,16,76,4,0,
548,16,78,1,22,1
548,16,79,1,10,1
548,16,92,4,0,
548,16,104,4,0,
548,16,117,2,0,
548,16,138,4,0,
548,16,148,4,0,
548,16,156,4,0,
548,16,164,4,0,
548,16,173,3,0,
548,16,182,4,0,
548,16,202,1,26,1
548,16,202,3,0,
548,16,203,2,0,
548,16,204,2,0,
548,16,207,4,0,
548,16,213,4,0,
548,16,214,4,0,
548,16,215,3,0,
548,16,216,4,0,
548,16,218,4,0,
548,16,219,4,0,
548,16,230,2,0,
548,16,235,1,17,1
548,16,235,3,0,
548,16,237,4,0,
548,16,241,1,40,1
548,16,241,4,0,
548,16,263,4,0,
548,16,267,4,0,
548,16,270,1,31,1
548,16,270,3,0,
548,16,275,2,0,
548,16,290,4,0,
548,16,312,1,28,1
548,16,320,2,0,
548,16,343,3,0,
548,16,345,1,19,1
548,16,361,2,0,
548,16,363,2,0,
548,16,388,2,0,
548,16,388,3,0,
548,16,402,3,0,
548,16,412,1,35,1
548,16,412,4,0,
548,16,437,1,46,1
548,16,447,4,0,
548,16,494,1,37,1
548,16,495,1,44,1
548,16,495,3,0,
548,16,496,4,0,
548,16,590,4,0,
548,17,71,1,1,
548,17,72,1,13,
548,17,73,1,8,
548,17,74,1,4,
548,17,76,4,0,
548,17,78,1,22,
548,17,79,1,10,
548,17,92,4,0,
548,17,104,4,0,
548,17,117,2,0,
548,17,138,4,0,
548,17,156,4,0,
548,17,164,4,0,
548,17,182,4,0,
548,17,202,1,26,
548,17,203,2,0,
548,17,204,2,0,
548,17,207,4,0,
548,17,213,4,0,
548,17,214,4,0,
548,17,216,4,0,
548,17,218,4,0,
548,17,219,4,0,
548,17,230,2,0,
548,17,235,1,17,
548,17,237,4,0,
548,17,241,1,40,
548,17,241,4,0,
548,17,263,4,0,
548,17,267,4,0,
548,17,270,1,31,
548,17,275,2,0,
548,17,312,1,28,
548,17,320,2,0,
548,17,345,1,19,
548,17,361,2,0,
548,17,363,2,0,
548,17,388,2,0,
548,17,412,1,35,
548,17,412,4,0,
548,17,437,1,46,
548,17,447,4,0,
548,17,494,1,37,
548,17,495,1,44,
548,17,496,4,0,
548,17,590,4,0,
548,18,71,1,1,
548,18,72,1,13,
548,18,73,1,8,
548,18,74,1,4,
548,18,76,4,0,
548,18,78,1,22,
548,18,79,1,10,
548,18,92,4,0,
548,18,104,4,0,
548,18,117,2,0,
548,18,138,4,0,
548,18,156,4,0,
548,18,164,4,0,
548,18,182,4,0,
548,18,202,1,26,
548,18,203,2,0,
548,18,204,2,0,
548,18,207,4,0,
548,18,213,4,0,
548,18,214,4,0,
548,18,216,4,0,
548,18,218,4,0,
548,18,219,4,0,
548,18,227,2,0,
548,18,230,2,0,
548,18,235,1,17,
548,18,237,4,0,
548,18,241,1,40,
548,18,241,4,0,
548,18,263,4,0,
548,18,267,4,0,
548,18,270,1,31,
548,18,275,2,0,
548,18,312,1,28,
548,18,320,2,0,
548,18,345,1,19,
548,18,361,2,0,
548,18,363,2,0,
548,18,388,2,0,
548,18,412,1,35,
548,18,412,4,0,
548,18,437,1,46,
548,18,447,4,0,
548,18,494,1,37,
548,18,495,1,44,
548,18,496,4,0,
548,18,590,4,0,
549,11,14,4,0,
549,11,15,4,0,
549,11,63,4,0,
549,11,72,1,1,3
549,11,73,1,1,2
549,11,74,1,1,1
549,11,76,4,0,
549,11,80,1,46,
549,11,92,4,0,
549,11,104,4,0,
549,11,113,4,0,
549,11,138,4,0,
549,11,148,4,0,
549,11,156,4,0,
549,11,164,4,0,
549,11,182,4,0,
549,11,207,4,0,
549,11,213,4,0,
549,11,216,4,0,
549,11,218,4,0,
549,11,219,4,0,
549,11,235,1,1,4
549,11,237,4,0,
549,11,241,4,0,
549,11,263,4,0,
549,11,298,1,10,
549,11,412,4,0,
549,11,416,4,0,
549,11,447,4,0,
549,11,483,1,28,
549,11,496,4,0,
549,14,14,4,0,
549,14,15,4,0,
549,14,63,4,0,
549,14,72,1,1,3
549,14,73,1,1,2
549,14,74,1,1,1
549,14,76,4,0,
549,14,80,1,46,
549,14,92,4,0,
549,14,104,4,0,
549,14,113,4,0,
549,14,138,4,0,
549,14,148,4,0,
549,14,156,4,0,
549,14,164,4,0,
549,14,173,3,0,
549,14,182,4,0,
549,14,202,3,0,
549,14,207,4,0,
549,14,213,4,0,
549,14,214,3,0,
549,14,215,3,0,
549,14,216,4,0,
549,14,218,4,0,
549,14,219,4,0,
549,14,235,1,1,4
549,14,235,3,0,
549,14,237,4,0,
549,14,241,4,0,
549,14,263,4,0,
549,14,270,3,0,
549,14,272,3,0,
549,14,298,1,10,
549,14,343,3,0,
549,14,388,3,0,
549,14,402,3,0,
549,14,412,4,0,
549,14,416,4,0,
549,14,447,4,0,
549,14,483,1,28,
549,14,495,3,0,
549,14,496,4,0,
549,15,14,4,0,
549,15,15,4,0,
549,15,63,4,0,
549,15,72,1,1,3
549,15,73,1,1,2
549,15,74,1,1,1
549,15,76,4,0,
549,15,80,1,46,
549,15,92,4,0,
549,15,104,4,0,
549,15,113,4,0,
549,15,138,4,0,
549,15,148,4,0,
549,15,156,4,0,
549,15,164,4,0,
549,15,182,4,0,
549,15,207,4,0,
549,15,213,4,0,
549,15,214,4,0,
549,15,216,4,0,
549,15,218,4,0,
549,15,219,4,0,
549,15,235,1,1,4
549,15,237,4,0,
549,15,241,4,0,
549,15,263,4,0,
549,15,267,4,0,
549,15,298,1,10,
549,15,412,4,0,
549,15,416,4,0,
549,15,447,4,0,
549,15,483,1,28,
549,15,496,4,0,
549,15,572,1,50,
549,15,590,4,0,
549,16,14,4,0,
549,16,15,4,0,
549,16,63,4,0,
549,16,72,1,1,3
549,16,73,1,1,2
549,16,74,1,1,1
549,16,76,4,0,
549,16,80,1,46,1
549,16,92,4,0,
549,16,104,4,0,
549,16,113,4,0,
549,16,138,4,0,
549,16,148,4,0,
549,16,156,4,0,
549,16,164,4,0,
549,16,173,3,0,
549,16,182,4,0,
549,16,202,3,0,
549,16,207,4,0,
549,16,213,4,0,
549,16,214,4,0,
549,16,215,3,0,
549,16,216,4,0,
549,16,218,4,0,
549,16,219,4,0,
549,16,235,1,1,4
549,16,235,3,0,
549,16,237,4,0,
549,16,241,4,0,
549,16,263,4,0,
549,16,267,4,0,
549,16,270,3,0,
549,16,272,3,0,
549,16,290,4,0,
549,16,298,1,10,1
549,16,343,3,0,
549,16,388,3,0,
549,16,402,3,0,
549,16,412,4,0,
549,16,416,4,0,
549,16,447,4,0,
549,16,483,1,28,1
549,16,495,3,0,
549,16,496,4,0,
549,16,572,1,50,1
549,16,590,4,0,
549,17,14,4,0,
549,17,63,4,0,
549,17,72,1,1,3
549,17,73,1,1,2
549,17,74,1,1,1
549,17,76,4,0,
549,17,80,1,46,
549,17,92,4,0,
549,17,104,4,0,
549,17,113,4,0,
549,17,138,4,0,
549,17,156,4,0,
549,17,164,4,0,
549,17,182,4,0,
549,17,207,4,0,
549,17,213,4,0,
549,17,214,4,0,
549,17,216,4,0,
549,17,218,4,0,
549,17,219,4,0,
549,17,235,1,1,4
549,17,237,4,0,
549,17,241,4,0,
549,17,263,4,0,
549,17,267,4,0,
549,17,298,1,10,
549,17,412,4,0,
549,17,416,4,0,
549,17,447,4,0,
549,17,483,1,28,
549,17,496,4,0,
549,17,572,1,50,
549,17,590,4,0,
549,18,14,4,0,
549,18,63,4,0,
549,18,72,1,1,3
549,18,73,1,1,2
549,18,74,1,1,1
549,18,76,4,0,
549,18,80,1,46,
549,18,92,4,0,
549,18,104,4,0,
549,18,113,4,0,
549,18,138,4,0,
549,18,156,4,0,
549,18,164,4,0,
549,18,182,4,0,
549,18,207,4,0,
549,18,213,4,0,
549,18,214,4,0,
549,18,216,4,0,
549,18,218,4,0,
549,18,219,4,0,
549,18,235,1,1,4
549,18,237,4,0,
549,18,241,4,0,
549,18,263,4,0,
549,18,267,4,0,
549,18,298,1,10,
549,18,412,4,0,
549,18,416,4,0,
549,18,447,4,0,
549,18,483,1,28,
549,18,496,4,0,
549,18,572,1,50,
549,18,590,4,0,
550,11,15,4,0,
550,11,29,1,7,
550,11,33,1,1,1
550,11,36,1,20,
550,11,37,1,56,
550,11,38,1,36,
550,11,44,1,10,
550,11,55,1,1,2
550,11,57,4,0,
550,11,58,4,0,
550,11,61,2,0,
550,11,92,4,0,
550,11,97,2,0,
550,11,99,2,0,
550,11,104,4,0,
550,11,127,4,0,
550,11,129,2,0,
550,11,156,4,0,
550,11,164,4,0,
550,11,175,1,46,
550,11,182,4,0,
550,11,184,1,41,
550,11,207,4,0,
550,11,213,4,0,
550,11,216,4,0,
550,11,218,4,0,
550,11,237,4,0,
550,11,240,4,0,
550,11,242,1,24,
550,11,250,2,0,
550,11,253,1,4,
550,11,258,4,0,
550,11,263,4,0,
550,11,269,4,0,
550,11,279,2,0,
550,11,291,4,0,
550,11,330,2,0,
550,11,341,2,0,
550,11,362,2,0,
550,11,401,1,28,
550,11,453,1,13,
550,11,487,1,32,
550,11,496,4,0,
550,11,498,1,16,
550,11,503,4,0,
550,11,515,1,51,
550,14,15,4,0,
550,14,29,1,7,
550,14,33,1,1,1
550,14,36,1,20,
550,14,37,1,56,
550,14,38,1,36,
550,14,44,1,10,
550,14,55,1,1,2
550,14,57,4,0,
550,14,58,4,0,
550,14,61,2,0,
550,14,92,4,0,
550,14,97,2,0,
550,14,99,2,0,
550,14,104,4,0,
550,14,127,4,0,
550,14,129,2,0,
550,14,156,4,0,
550,14,164,4,0,
550,14,173,3,0,
550,14,175,1,46,
550,14,182,4,0,
550,14,184,1,41,
550,14,196,3,0,
550,14,207,4,0,
550,14,213,4,0,
550,14,214,3,0,
550,14,216,4,0,
550,14,218,4,0,
550,14,237,4,0,
550,14,240,4,0,
550,14,242,1,24,
550,14,250,2,0,
550,14,253,1,4,
550,14,253,3,0,
550,14,258,4,0,
550,14,263,4,0,
550,14,269,4,0,
550,14,276,3,0,
550,14,279,2,0,
550,14,283,3,0,
550,14,291,4,0,
550,14,330,2,0,
550,14,340,3,0,
550,14,341,2,0,
550,14,362,2,0,
550,14,401,1,28,
550,14,401,3,0,
550,14,428,3,0,
550,14,453,1,13,
550,14,487,1,32,
550,14,496,4,0,
550,14,498,1,16,
550,14,503,4,0,
550,14,515,1,51,
550,15,15,4,0,
550,15,29,1,7,
550,15,33,1,1,4
550,15,36,1,20,
550,15,37,1,1,1
550,15,37,1,56,
550,15,38,1,36,
550,15,39,1,1,3
550,15,44,1,10,
550,15,55,1,1,5
550,15,57,4,0,
550,15,58,4,0,
550,15,61,2,0,
550,15,92,4,0,
550,15,97,2,0,
550,15,99,2,0,
550,15,104,4,0,
550,15,127,4,0,
550,15,129,2,0,
550,15,156,4,0,
550,15,164,4,0,
550,15,175,1,1,2
550,15,175,1,46,
550,15,182,4,0,
550,15,184,1,41,
550,15,207,4,0,
550,15,213,4,0,
550,15,214,4,0,
550,15,216,4,0,
550,15,218,4,0,
550,15,237,4,0,
550,15,240,4,0,
550,15,242,1,24,
550,15,250,2,0,
550,15,253,1,4,
550,15,258,4,0,
550,15,263,4,0,
550,15,269,4,0,
550,15,279,2,0,
550,15,330,2,0,
550,15,341,2,0,
550,15,362,2,0,
550,15,401,1,28,
550,15,453,1,13,
550,15,487,1,32,
550,15,496,4,0,
550,15,498,1,16,
550,15,503,4,0,
550,15,515,1,50,
550,15,590,4,0,
550,16,15,4,0,
550,16,29,1,7,1
550,16,33,1,1,4
550,16,36,1,20,1
550,16,37,1,1,1
550,16,37,1,56,1
550,16,38,1,36,1
550,16,39,1,1,3
550,16,44,1,10,1
550,16,55,1,1,5
550,16,57,4,0,
550,16,58,4,0,
550,16,61,2,0,
550,16,92,4,0,
550,16,97,2,0,
550,16,99,2,0,
550,16,104,4,0,
550,16,127,4,0,
550,16,129,2,0,
550,16,156,4,0,
550,16,164,4,0,
550,16,173,3,0,
550,16,175,1,1,2
550,16,175,1,46,1
550,16,182,4,0,
550,16,184,1,41,1
550,16,196,3,0,
550,16,207,4,0,
550,16,213,4,0,
550,16,214,4,0,
550,16,216,4,0,
550,16,218,4,0,
550,16,237,4,0,
550,16,240,4,0,
550,16,242,1,24,1
550,16,250,2,0,
550,16,253,1,4,1
550,16,253,3,0,
550,16,258,4,0,
550,16,263,4,0,
550,16,269,4,0,
550,16,276,3,0,
550,16,279,2,0,
550,16,283,3,0,
550,16,290,4,0,
550,16,291,4,0,
550,16,330,2,0,
550,16,340,3,0,
550,16,341,2,0,
550,16,362,2,0,
550,16,401,1,28,1
550,16,401,3,0,
550,16,428,3,0,
550,16,453,1,13,1
550,16,487,1,32,1
550,16,496,4,0,
550,16,498,1,16,1
550,16,503,4,0,
550,16,515,1,50,1
550,16,590,4,0,
550,17,29,1,5,
550,17,33,1,1,2
550,17,36,1,14,
550,17,37,1,42,
550,17,38,1,26,
550,17,39,1,1,1
550,17,44,1,7,
550,17,55,1,1,3
550,17,57,4,0,
550,17,58,4,0,
550,17,61,2,0,
550,17,92,4,0,
550,17,97,2,0,
550,17,99,2,0,
550,17,104,4,0,
550,17,127,4,0,
550,17,129,2,0,
550,17,156,4,0,
550,17,164,4,0,
550,17,175,1,34,
550,17,182,4,0,
550,17,184,1,30,
550,17,207,4,0,
550,17,213,4,0,
550,17,214,4,0,
550,17,216,4,0,
550,17,218,4,0,
550,17,237,4,0,
550,17,240,4,0,
550,17,242,1,17,
550,17,250,2,0,
550,17,253,1,3,
550,17,258,4,0,
550,17,263,4,0,
550,17,269,4,0,
550,17,279,2,0,
550,17,330,2,0,
550,17,341,2,0,
550,17,362,2,0,
550,17,401,1,20,
550,17,453,1,9,
550,17,457,1,46,
550,17,487,1,23,
550,17,496,4,0,
550,17,498,1,11,
550,17,503,4,0,
550,17,515,1,38,
550,17,590,4,0,
550,18,29,1,5,
550,18,33,1,1,2
550,18,36,1,14,
550,18,37,1,42,
550,18,38,1,26,
550,18,39,1,1,1
550,18,44,1,7,
550,18,55,1,1,3
550,18,57,4,0,
550,18,58,4,0,
550,18,61,2,0,
550,18,92,4,0,
550,18,97,2,0,
550,18,99,2,0,
550,18,104,4,0,
550,18,127,4,0,
550,18,129,2,0,
550,18,156,4,0,
550,18,164,4,0,
550,18,175,1,34,
550,18,182,4,0,
550,18,184,1,30,
550,18,207,4,0,
550,18,213,4,0,
550,18,214,4,0,
550,18,216,4,0,
550,18,218,4,0,
550,18,237,4,0,
550,18,240,4,0,
550,18,242,1,17,
550,18,250,2,0,
550,18,253,1,3,
550,18,258,4,0,
550,18,263,4,0,
550,18,269,4,0,
550,18,279,2,0,
550,18,330,2,0,
550,18,341,2,0,
550,18,362,2,0,
550,18,401,1,20,
550,18,453,1,9,
550,18,457,1,46,
550,18,457,2,0,
550,18,487,1,23,
550,18,496,4,0,
550,18,498,1,11,
550,18,503,4,0,
550,18,515,1,38,
550,18,590,4,0,
551,11,15,4,0,
551,11,28,1,7,
551,11,37,1,46,
551,11,38,2,0,
551,11,43,1,1,1
551,11,44,1,4,
551,11,46,4,0,
551,11,68,2,0,
551,11,89,1,43,
551,11,89,4,0,
551,11,91,1,31,
551,11,91,4,0,
551,11,92,4,0,
551,11,99,1,1,2
551,11,104,4,0,
551,11,116,2,0,
551,11,156,4,0,
551,11,157,4,0,
551,11,164,4,0,
551,11,168,4,0,
551,11,182,4,0,
551,11,184,1,34,
551,11,188,4,0,
551,11,189,1,19,
551,11,201,1,40,
551,11,201,4,0,
551,11,207,1,25,
551,11,207,4,0,
551,11,212,2,0,
551,11,213,4,0,
551,11,216,4,0,
551,11,218,4,0,
551,11,228,2,0,
551,11,237,4,0,
551,11,242,1,28,
551,11,251,2,0,
551,11,253,2,0,
551,11,259,1,10,
551,11,259,4,0,
551,11,263,4,0,
551,11,269,4,0,
551,11,317,4,0,
551,11,328,1,13,
551,11,371,4,0,
551,11,372,1,16,
551,11,373,1,22,
551,11,373,4,0,
551,11,422,2,0,
551,11,424,2,0,
551,11,431,2,0,
551,11,444,4,0,
551,11,468,4,0,
551,11,492,1,37,
551,11,496,4,0,
551,11,510,4,0,
551,11,514,4,0,
551,11,523,4,0,
551,11,555,4,0,
551,14,15,4,0,
551,14,28,1,7,
551,14,37,1,46,
551,14,38,2,0,
551,14,43,1,1,1
551,14,44,1,4,
551,14,46,4,0,
551,14,68,2,0,
551,14,89,1,43,
551,14,89,4,0,
551,14,91,1,31,
551,14,91,4,0,
551,14,92,4,0,
551,14,99,1,1,2
551,14,104,4,0,
551,14,116,2,0,
551,14,156,4,0,
551,14,157,4,0,
551,14,164,4,0,
551,14,168,4,0,
551,14,173,3,0,
551,14,180,3,0,
551,14,182,4,0,
551,14,184,1,34,
551,14,188,4,0,
551,14,189,1,19,
551,14,201,1,40,
551,14,201,4,0,
551,14,207,1,25,
551,14,207,4,0,
551,14,212,2,0,
551,14,213,4,0,
551,14,214,3,0,
551,14,216,4,0,
551,14,218,4,0,
551,14,228,2,0,
551,14,231,3,0,
551,14,237,4,0,
551,14,242,1,28,
551,14,251,2,0,
551,14,253,2,0,
551,14,253,3,0,
551,14,259,1,10,
551,14,259,4,0,
551,14,263,4,0,
551,14,269,4,0,
551,14,289,3,0,
551,14,317,4,0,
551,14,328,1,13,
551,14,371,4,0,
551,14,372,1,16,
551,14,373,1,22,
551,14,373,4,0,
551,14,399,3,0,
551,14,401,3,0,
551,14,414,3,0,
551,14,422,2,0,
551,14,424,2,0,
551,14,431,2,0,
551,14,444,4,0,
551,14,446,3,0,
551,14,468,4,0,
551,14,492,1,37,
551,14,492,3,0,
551,14,496,4,0,
551,14,510,4,0,
551,14,514,4,0,
551,14,523,4,0,
551,14,555,4,0,
551,15,15,4,0,
551,15,28,1,7,
551,15,37,1,46,
551,15,38,2,0,
551,15,43,1,1,1
551,15,44,1,4,
551,15,46,4,0,
551,15,68,2,0,
551,15,89,1,43,
551,15,89,4,0,
551,15,91,1,31,
551,15,91,4,0,
551,15,92,4,0,
551,15,99,1,1,2
551,15,104,4,0,
551,15,116,2,0,
551,15,156,4,0,
551,15,157,4,0,
551,15,164,4,0,
551,15,168,4,0,
551,15,182,4,0,
551,15,184,1,34,
551,15,188,4,0,
551,15,189,1,19,
551,15,201,1,40,
551,15,201,4,0,
551,15,207,1,25,
551,15,207,4,0,
551,15,212,2,0,
551,15,213,4,0,
551,15,214,4,0,
551,15,216,4,0,
551,15,218,4,0,
551,15,228,2,0,
551,15,237,4,0,
551,15,242,1,28,
551,15,251,2,0,
551,15,253,2,0,
551,15,259,1,10,
551,15,259,4,0,
551,15,263,4,0,
551,15,269,4,0,
551,15,317,4,0,
551,15,328,1,13,
551,15,371,4,0,
551,15,372,1,16,
551,15,373,1,22,
551,15,373,4,0,
551,15,382,2,0,
551,15,399,4,0,
551,15,422,2,0,
551,15,424,2,0,
551,15,431,2,0,
551,15,444,4,0,
551,15,468,4,0,
551,15,492,1,37,
551,15,496,4,0,
551,15,510,4,0,
551,15,514,4,0,
551,15,523,4,0,
551,15,555,4,0,
551,15,590,4,0,
551,16,15,4,0,
551,16,28,1,7,1
551,16,37,1,46,1
551,16,38,2,0,
551,16,43,1,1,1
551,16,44,1,4,1
551,16,46,4,0,
551,16,68,2,0,
551,16,89,1,43,1
551,16,89,4,0,
551,16,91,1,31,1
551,16,91,4,0,
551,16,92,4,0,
551,16,99,1,1,2
551,16,104,4,0,
551,16,116,2,0,
551,16,156,4,0,
551,16,157,4,0,
551,16,164,4,0,
551,16,168,4,0,
551,16,173,3,0,
551,16,180,3,0,
551,16,182,4,0,
551,16,184,1,34,1
551,16,188,4,0,
551,16,189,1,19,1
551,16,201,1,40,1
551,16,201,4,0,
551,16,207,1,25,1
551,16,207,4,0,
551,16,212,2,0,
551,16,213,4,0,
551,16,214,4,0,
551,16,216,4,0,
551,16,218,4,0,
551,16,228,2,0,
551,16,231,3,0,
551,16,237,4,0,
551,16,242,1,28,1
551,16,251,2,0,
551,16,253,2,0,
551,16,253,3,0,
551,16,259,1,10,1
551,16,259,4,0,
551,16,263,4,0,
551,16,269,4,0,
551,16,289,3,0,
551,16,290,4,0,
551,16,317,4,0,
551,16,328,1,13,1
551,16,371,4,0,
551,16,372,1,16,1
551,16,373,1,22,1
551,16,373,4,0,
551,16,382,2,0,
551,16,399,4,0,
551,16,401,3,0,
551,16,414,3,0,
551,16,422,2,0,
551,16,424,2,0,
551,16,431,2,0,
551,16,444,4,0,
551,16,446,3,0,
551,16,468,4,0,
551,16,492,1,37,1
551,16,492,3,0,
551,16,496,4,0,
551,16,510,4,0,
551,16,514,4,0,
551,16,523,4,0,
551,16,555,4,0,
551,16,590,4,0,
551,17,28,1,7,
551,17,37,1,46,
551,17,38,2,0,
551,17,43,1,1,1
551,17,44,1,4,
551,17,46,4,0,
551,17,68,2,0,
551,17,89,1,43,
551,17,89,4,0,
551,17,91,1,31,
551,17,92,4,0,
551,17,99,1,1,2
551,17,104,4,0,
551,17,116,2,0,
551,17,156,4,0,
551,17,157,4,0,
551,17,164,4,0,
551,17,168,4,0,
551,17,182,4,0,
551,17,184,1,34,
551,17,188,4,0,
551,17,189,1,19,
551,17,201,1,40,
551,17,201,4,0,
551,17,207,1,25,
551,17,207,4,0,
551,17,212,2,0,
551,17,213,4,0,
551,17,214,4,0,
551,17,216,4,0,
551,17,218,4,0,
551,17,228,2,0,
551,17,237,4,0,
551,17,242,1,28,
551,17,251,2,0,
551,17,253,2,0,
551,17,259,1,10,
551,17,259,4,0,
551,17,263,4,0,
551,17,269,4,0,
551,17,317,4,0,
551,17,328,1,13,
551,17,371,4,0,
551,17,372,1,16,
551,17,373,1,22,
551,17,373,4,0,
551,17,382,2,0,
551,17,399,4,0,
551,17,422,2,0,
551,17,424,2,0,
551,17,431,2,0,
551,17,444,4,0,
551,17,492,1,37,
551,17,496,4,0,
551,17,523,4,0,
551,17,555,4,0,
551,17,590,4,0,
551,17,681,2,0,
551,18,28,1,7,
551,18,37,1,46,
551,18,38,2,0,
551,18,43,1,1,1
551,18,44,1,4,
551,18,46,4,0,
551,18,68,2,0,
551,18,89,1,43,
551,18,89,4,0,
551,18,91,1,31,
551,18,92,4,0,
551,18,99,1,1,2
551,18,104,4,0,
551,18,116,2,0,
551,18,156,4,0,
551,18,157,4,0,
551,18,164,4,0,
551,18,168,4,0,
551,18,182,4,0,
551,18,184,1,34,
551,18,188,4,0,
551,18,189,1,19,
551,18,201,1,40,
551,18,201,4,0,
551,18,207,1,25,
551,18,207,4,0,
551,18,212,2,0,
551,18,213,4,0,
551,18,214,4,0,
551,18,216,4,0,
551,18,218,4,0,
551,18,228,2,0,
551,18,237,4,0,
551,18,242,1,28,
551,18,251,2,0,
551,18,253,2,0,
551,18,259,1,10,
551,18,259,4,0,
551,18,263,4,0,
551,18,269,4,0,
551,18,317,4,0,
551,18,328,1,13,
551,18,371,4,0,
551,18,372,1,16,
551,18,373,1,22,
551,18,373,4,0,
551,18,382,2,0,
551,18,399,4,0,
551,18,422,2,0,
551,18,424,2,0,
551,18,431,2,0,
551,18,444,4,0,
551,18,492,1,37,
551,18,496,4,0,
551,18,523,4,0,
551,18,555,4,0,
551,18,590,4,0,
551,18,681,2,0,
552,11,15,4,0,
552,11,28,1,1,4
552,11,28,1,7,
552,11,37,1,52,
552,11,43,1,1,1
552,11,44,1,1,3
552,11,44,1,4,
552,11,46,4,0,
552,11,70,4,0,
552,11,89,1,48,
552,11,89,4,0,
552,11,91,1,32,
552,11,91,4,0,
552,11,92,4,0,
552,11,99,1,1,2
552,11,104,4,0,
552,11,156,4,0,
552,11,157,4,0,
552,11,164,4,0,
552,11,168,4,0,
552,11,182,4,0,
552,11,184,1,36,
552,11,188,4,0,
552,11,189,1,19,
552,11,201,1,44,
552,11,201,4,0,
552,11,207,1,25,
552,11,207,4,0,
552,11,213,4,0,
552,11,216,4,0,
552,11,218,4,0,
552,11,237,4,0,
552,11,242,1,28,
552,11,249,4,0,
552,11,259,1,10,
552,11,259,4,0,
552,11,263,4,0,
552,11,269,4,0,
552,11,280,4,0,
552,11,317,4,0,
552,11,328,1,13,
552,11,371,4,0,
552,11,372,1,16,
552,11,373,1,22,
552,11,373,4,0,
552,11,374,4,0,
552,11,421,4,0,
552,11,444,4,0,
552,11,447,4,0,
552,11,468,4,0,
552,11,490,4,0,
552,11,492,1,40,
552,11,496,4,0,
552,11,510,4,0,
552,11,514,4,0,
552,11,523,4,0,
552,11,555,4,0,
552,14,15,4,0,
552,14,28,1,1,4
552,14,28,1,7,
552,14,37,1,52,
552,14,43,1,1,1
552,14,44,1,1,3
552,14,44,1,4,
552,14,46,4,0,
552,14,67,3,0,
552,14,70,4,0,
552,14,89,1,48,
552,14,89,4,0,
552,14,91,1,32,
552,14,91,4,0,
552,14,92,4,0,
552,14,99,1,1,2
552,14,104,4,0,
552,14,156,4,0,
552,14,157,4,0,
552,14,164,4,0,
552,14,168,4,0,
552,14,173,3,0,
552,14,180,3,0,
552,14,182,4,0,
552,14,184,1,36,
552,14,188,4,0,
552,14,189,1,19,
552,14,201,1,44,
552,14,201,4,0,
552,14,207,1,25,
552,14,207,4,0,
552,14,213,4,0,
552,14,214,3,0,
552,14,216,4,0,
552,14,218,4,0,
552,14,231,3,0,
552,14,237,4,0,
552,14,242,1,28,
552,14,249,4,0,
552,14,253,3,0,
552,14,259,1,10,
552,14,259,4,0,
552,14,263,4,0,
552,14,269,4,0,
552,14,280,4,0,
552,14,282,3,0,
552,14,289,3,0,
552,14,317,4,0,
552,14,328,1,13,
552,14,371,4,0,
552,14,372,1,16,
552,14,373,1,22,
552,14,373,4,0,
552,14,374,4,0,
552,14,399,3,0,
552,14,401,3,0,
552,14,414,3,0,
552,14,421,4,0,
552,14,444,4,0,
552,14,446,3,0,
552,14,447,4,0,
552,14,468,4,0,
552,14,490,4,0,
552,14,492,1,40,
552,14,492,3,0,
552,14,496,4,0,
552,14,510,4,0,
552,14,514,4,0,
552,14,523,4,0,
552,14,555,4,0,
552,15,15,4,0,
552,15,28,1,1,4
552,15,28,1,7,
552,15,37,1,52,
552,15,43,1,1,1
552,15,44,1,1,3
552,15,44,1,4,
552,15,46,4,0,
552,15,70,4,0,
552,15,89,1,48,
552,15,89,4,0,
552,15,91,1,32,
552,15,91,4,0,
552,15,92,4,0,
552,15,99,1,1,2
552,15,104,4,0,
552,15,156,4,0,
552,15,157,4,0,
552,15,164,4,0,
552,15,168,4,0,
552,15,182,4,0,
552,15,184,1,36,
552,15,188,4,0,
552,15,189,1,19,
552,15,201,1,44,
552,15,201,4,0,
552,15,207,1,25,
552,15,207,4,0,
552,15,213,4,0,
552,15,214,4,0,
552,15,216,4,0,
552,15,218,4,0,
552,15,237,4,0,
552,15,242,1,28,
552,15,249,4,0,
552,15,259,1,10,
552,15,259,4,0,
552,15,263,4,0,
552,15,269,4,0,
552,15,280,4,0,
552,15,317,4,0,
552,15,328,1,13,
552,15,371,4,0,
552,15,372,1,16,
552,15,373,1,22,
552,15,373,4,0,
552,15,374,4,0,
552,15,399,4,0,
552,15,421,4,0,
552,15,444,4,0,
552,15,447,4,0,
552,15,468,4,0,
552,15,490,4,0,
552,15,492,1,40,
552,15,496,4,0,
552,15,510,4,0,
552,15,514,4,0,
552,15,523,4,0,
552,15,555,4,0,
552,15,590,4,0,
552,15,612,4,0,
552,16,15,4,0,
552,16,28,1,1,4
552,16,28,1,7,1
552,16,37,1,52,1
552,16,43,1,1,1
552,16,44,1,1,3
552,16,44,1,4,1
552,16,46,4,0,
552,16,67,3,0,
552,16,70,4,0,
552,16,89,1,48,1
552,16,89,4,0,
552,16,91,1,32,1
552,16,91,4,0,
552,16,92,4,0,
552,16,99,1,1,2
552,16,104,4,0,
552,16,156,4,0,
552,16,157,4,0,
552,16,164,4,0,
552,16,168,4,0,
552,16,173,3,0,
552,16,180,3,0,
552,16,182,4,0,
552,16,184,1,36,1
552,16,188,4,0,
552,16,189,1,19,1
552,16,201,1,44,1
552,16,201,4,0,
552,16,207,1,25,1
552,16,207,4,0,
552,16,213,4,0,
552,16,214,4,0,
552,16,216,4,0,
552,16,218,4,0,
552,16,231,3,0,
552,16,237,4,0,
552,16,242,1,28,1
552,16,249,4,0,
552,16,253,3,0,
552,16,259,1,10,1
552,16,259,4,0,
552,16,263,4,0,
552,16,264,3,0,
552,16,269,4,0,
552,16,280,4,0,
552,16,282,3,0,
552,16,289,3,0,
552,16,290,4,0,
552,16,317,4,0,
552,16,328,1,13,1
552,16,371,4,0,
552,16,372,1,16,1
552,16,373,1,22,1
552,16,373,4,0,
552,16,374,4,0,
552,16,399,4,0,
552,16,401,3,0,
552,16,414,3,0,
552,16,421,4,0,
552,16,444,4,0,
552,16,446,3,0,
552,16,447,4,0,
552,16,468,4,0,
552,16,490,4,0,
552,16,492,1,40,1
552,16,492,3,0,
552,16,496,4,0,
552,16,510,4,0,
552,16,514,4,0,
552,16,523,4,0,
552,16,555,4,0,
552,16,590,4,0,
552,16,612,4,0,
552,17,28,1,1,4
552,17,28,1,7,
552,17,37,1,52,
552,17,43,1,1,1
552,17,44,1,1,3
552,17,44,1,4,
552,17,46,4,0,
552,17,89,1,48,
552,17,89,4,0,
552,17,91,1,32,
552,17,92,4,0,
552,17,99,1,1,2
552,17,104,4,0,
552,17,156,4,0,
552,17,157,4,0,
552,17,164,4,0,
552,17,168,4,0,
552,17,182,4,0,
552,17,184,1,36,
552,17,188,4,0,
552,17,189,1,19,
552,17,201,1,44,
552,17,201,4,0,
552,17,207,1,25,
552,17,207,4,0,
552,17,213,4,0,
552,17,214,4,0,
552,17,216,4,0,
552,17,218,4,0,
552,17,237,4,0,
552,17,242,1,28,
552,17,259,1,10,
552,17,259,4,0,
552,17,263,4,0,
552,17,269,4,0,
552,17,280,4,0,
552,17,317,4,0,
552,17,328,1,13,
552,17,371,4,0,
552,17,372,1,16,
552,17,373,1,22,
552,17,373,4,0,
552,17,374,4,0,
552,17,399,4,0,
552,17,421,4,0,
552,17,444,4,0,
552,17,447,4,0,
552,17,490,4,0,
552,17,492,1,40,
552,17,496,4,0,
552,17,523,4,0,
552,17,555,4,0,
552,17,590,4,0,
552,17,693,4,0,
552,18,28,1,1,4
552,18,28,1,7,
552,18,37,1,52,
552,18,43,1,1,1
552,18,44,1,1,3
552,18,44,1,4,
552,18,46,4,0,
552,18,89,1,48,
552,18,89,4,0,
552,18,91,1,32,
552,18,92,4,0,
552,18,99,1,1,2
552,18,104,4,0,
552,18,156,4,0,
552,18,157,4,0,
552,18,164,4,0,
552,18,168,4,0,
552,18,182,4,0,
552,18,184,1,36,
552,18,188,4,0,
552,18,189,1,19,
552,18,201,1,44,
552,18,201,4,0,
552,18,207,1,25,
552,18,207,4,0,
552,18,213,4,0,
552,18,214,4,0,
552,18,216,4,0,
552,18,218,4,0,
552,18,237,4,0,
552,18,242,1,28,
552,18,259,1,10,
552,18,259,4,0,
552,18,263,4,0,
552,18,269,4,0,
552,18,280,4,0,
552,18,317,4,0,
552,18,328,1,13,
552,18,371,4,0,
552,18,372,1,16,
552,18,373,1,22,
552,18,373,4,0,
552,18,374,4,0,
552,18,399,4,0,
552,18,421,4,0,
552,18,444,4,0,
552,18,447,4,0,
552,18,490,4,0,
552,18,492,1,40,
552,18,496,4,0,
552,18,523,4,0,
552,18,555,4,0,
552,18,590,4,0,
552,18,693,4,0,
553,11,15,4,0,
553,11,28,1,1,4
553,11,28,1,7,
553,11,43,1,1,1
553,11,44,1,1,3
553,11,44,1,4,
553,11,46,4,0,
553,11,63,4,0,
553,11,70,4,0,
553,11,89,1,54,
553,11,89,4,0,
553,11,91,1,32,
553,11,91,4,0,
553,11,92,4,0,
553,11,99,1,1,2
553,11,104,4,0,
553,11,156,4,0,
553,11,157,4,0,
553,11,164,4,0,
553,11,168,4,0,
553,11,182,4,0,
553,11,184,1,36,
553,11,188,4,0,
553,11,189,1,19,
553,11,200,1,60,
553,11,201,1,48,
553,11,201,4,0,
553,11,207,1,25,
553,11,207,4,0,
553,11,213,4,0,
553,11,216,4,0,
553,11,218,4,0,
553,11,237,4,0,
553,11,242,1,28,
553,11,249,4,0,
553,11,259,1,10,
553,11,259,4,0,
553,11,263,4,0,
553,11,269,4,0,
553,11,280,4,0,
553,11,317,4,0,
553,11,328,1,13,
553,11,332,4,0,
553,11,337,4,0,
553,11,339,4,0,
553,11,371,4,0,
553,11,372,1,16,
553,11,373,1,22,
553,11,373,4,0,
553,11,374,4,0,
553,11,411,4,0,
553,11,416,4,0,
553,11,421,4,0,
553,11,444,4,0,
553,11,447,4,0,
553,11,468,4,0,
553,11,479,4,0,
553,11,490,4,0,
553,11,492,1,42,
553,11,496,4,0,
553,11,510,4,0,
553,11,514,4,0,
553,11,523,4,0,
553,11,525,4,0,
553,11,555,4,0,
553,14,15,4,0,
553,14,28,1,1,4
553,14,28,1,7,
553,14,43,1,1,1
553,14,44,1,1,3
553,14,44,1,4,
553,14,46,4,0,
553,14,63,4,0,
553,14,67,3,0,
553,14,70,4,0,
553,14,89,1,54,
553,14,89,4,0,
553,14,91,1,32,
553,14,91,4,0,
553,14,92,4,0,
553,14,99,1,1,2
553,14,104,4,0,
553,14,156,4,0,
553,14,157,4,0,
553,14,164,4,0,
553,14,168,4,0,
553,14,173,3,0,
553,14,180,3,0,
553,14,182,4,0,
553,14,184,1,36,
553,14,188,4,0,
553,14,189,1,19,
553,14,200,1,60,
553,14,200,3,0,
553,14,201,1,48,
553,14,201,4,0,
553,14,207,1,25,
553,14,207,4,0,
553,14,213,4,0,
553,14,214,3,0,
553,14,216,4,0,
553,14,218,4,0,
553,14,231,3,0,
553,14,237,4,0,
553,14,242,1,28,
553,14,249,4,0,
553,14,253,3,0,
553,14,259,1,10,
553,14,259,4,0,
553,14,263,4,0,
553,14,269,4,0,
553,14,276,3,0,
553,14,280,4,0,
553,14,282,3,0,
553,14,289,3,0,
553,14,317,4,0,
553,14,328,1,13,
553,14,332,4,0,
553,14,335,3,0,
553,14,337,4,0,
553,14,339,4,0,
553,14,371,4,0,
553,14,372,1,16,
553,14,373,1,22,
553,14,373,4,0,
553,14,374,4,0,
553,14,399,3,0,
553,14,401,3,0,
553,14,406,3,0,
553,14,411,4,0,
553,14,414,3,0,
553,14,416,4,0,
553,14,421,4,0,
553,14,444,4,0,
553,14,446,3,0,
553,14,447,4,0,
553,14,468,4,0,
553,14,479,4,0,
553,14,490,4,0,
553,14,492,1,42,
553,14,492,3,0,
553,14,496,4,0,
553,14,510,4,0,
553,14,514,4,0,
553,14,523,4,0,
553,14,525,4,0,
553,14,555,4,0,
553,15,15,4,0,
553,15,28,1,1,5
553,15,28,1,7,
553,15,43,1,1,2
553,15,44,1,1,4
553,15,44,1,4,
553,15,46,4,0,
553,15,63,4,0,
553,15,70,4,0,
553,15,89,1,54,
553,15,89,4,0,
553,15,91,1,32,
553,15,91,4,0,
553,15,92,4,0,
553,15,99,1,1,3
553,15,104,4,0,
553,15,156,4,0,
553,15,157,4,0,
553,15,164,4,0,
553,15,168,4,0,
553,15,182,4,0,
553,15,184,1,36,
553,15,188,4,0,
553,15,189,1,19,
553,15,200,1,1,1
553,15,200,1,60,
553,15,201,1,48,
553,15,201,4,0,
553,15,207,1,25,
553,15,207,4,0,
553,15,213,4,0,
553,15,214,4,0,
553,15,216,4,0,
553,15,218,4,0,
553,15,237,4,0,
553,15,242,1,28,
553,15,249,4,0,
553,15,259,1,10,
553,15,259,4,0,
553,15,263,4,0,
553,15,269,4,0,
553,15,280,4,0,
553,15,317,4,0,
553,15,328,1,13,
553,15,332,4,0,
553,15,337,4,0,
553,15,339,4,0,
553,15,371,4,0,
553,15,372,1,16,
553,15,373,1,22,
553,15,373,4,0,
553,15,374,4,0,
553,15,399,4,0,
553,15,411,4,0,
553,15,416,4,0,
553,15,421,4,0,
553,15,444,4,0,
553,15,447,4,0,
553,15,468,4,0,
553,15,479,4,0,
553,15,490,4,0,
553,15,492,1,42,
553,15,496,4,0,
553,15,510,4,0,
553,15,514,4,0,
553,15,523,4,0,
553,15,525,4,0,
553,15,555,4,0,
553,15,590,4,0,
553,15,612,4,0,
553,16,15,4,0,
553,16,28,1,1,5
553,16,28,1,7,1
553,16,43,1,1,2
553,16,44,1,1,4
553,16,44,1,4,1
553,16,46,4,0,
553,16,63,4,0,
553,16,67,3,0,
553,16,70,4,0,
553,16,89,1,54,1
553,16,89,4,0,
553,16,91,1,32,1
553,16,91,4,0,
553,16,92,4,0,
553,16,99,1,1,3
553,16,104,4,0,
553,16,156,4,0,
553,16,157,4,0,
553,16,164,4,0,
553,16,168,4,0,
553,16,173,3,0,
553,16,180,3,0,
553,16,182,4,0,
553,16,184,1,36,1
553,16,188,4,0,
553,16,189,1,19,1
553,16,200,1,1,1
553,16,200,1,60,1
553,16,200,3,0,
553,16,201,1,48,1
553,16,201,4,0,
553,16,207,1,25,1
553,16,207,4,0,
553,16,213,4,0,
553,16,214,4,0,
553,16,216,4,0,
553,16,218,4,0,
553,16,231,3,0,
553,16,237,4,0,
553,16,242,1,28,1
553,16,249,4,0,
553,16,253,3,0,
553,16,259,1,10,1
553,16,259,4,0,
553,16,263,4,0,
553,16,264,3,0,
553,16,269,4,0,
553,16,276,3,0,
553,16,280,4,0,
553,16,282,3,0,
553,16,289,3,0,
553,16,290,4,0,
553,16,317,4,0,
553,16,328,1,13,1
553,16,332,4,0,
553,16,335,3,0,
553,16,337,4,0,
553,16,339,4,0,
553,16,371,4,0,
553,16,372,1,16,1
553,16,373,1,22,1
553,16,373,4,0,
553,16,374,4,0,
553,16,399,4,0,
553,16,401,3,0,
553,16,406,3,0,
553,16,411,4,0,
553,16,414,3,0,
553,16,416,4,0,
553,16,421,4,0,
553,16,444,4,0,
553,16,446,3,0,
553,16,447,4,0,
553,16,468,4,0,
553,16,479,4,0,
553,16,490,4,0,
553,16,492,1,42,1
553,16,492,3,0,
553,16,496,4,0,
553,16,510,4,0,
553,16,514,4,0,
553,16,523,4,0,
553,16,525,4,0,
553,16,555,4,0,
553,16,590,4,0,
553,16,612,4,0,
553,17,28,1,1,5
553,17,28,1,7,
553,17,43,1,1,2
553,17,44,1,1,4
553,17,44,1,4,
553,17,46,4,0,
553,17,63,4,0,
553,17,89,1,54,
553,17,89,4,0,
553,17,91,1,32,
553,17,92,4,0,
553,17,99,1,1,3
553,17,104,4,0,
553,17,156,4,0,
553,17,157,4,0,
553,17,164,4,0,
553,17,168,4,0,
553,17,182,4,0,
553,17,184,1,36,
553,17,188,4,0,
553,17,189,1,19,
553,17,200,1,60,
553,17,201,1,48,
553,17,201,4,0,
553,17,207,1,25,
553,17,207,4,0,
553,17,213,4,0,
553,17,214,4,0,
553,17,216,4,0,
553,17,218,4,0,
553,17,237,4,0,
553,17,242,1,28,
553,17,259,1,10,
553,17,259,4,0,
553,17,263,4,0,
553,17,269,4,0,
553,17,280,4,0,
553,17,317,4,0,
553,17,328,1,13,
553,17,332,4,0,
553,17,337,4,0,
553,17,339,4,0,
553,17,371,4,0,
553,17,372,1,16,
553,17,373,1,22,
553,17,373,4,0,
553,17,374,4,0,
553,17,399,4,0,
553,17,411,4,0,
553,17,416,4,0,
553,17,421,4,0,
553,17,444,4,0,
553,17,447,4,0,
553,17,479,4,0,
553,17,490,4,0,
553,17,492,1,42,
553,17,496,4,0,
553,17,523,4,0,
553,17,525,4,0,
553,17,555,4,0,
553,17,590,4,0,
553,17,681,1,1,1
553,17,693,4,0,
553,18,28,1,1,5
553,18,28,1,7,
553,18,43,1,1,2
553,18,44,1,1,4
553,18,44,1,4,
553,18,46,4,0,
553,18,63,4,0,
553,18,89,1,54,
553,18,89,4,0,
553,18,91,1,32,
553,18,92,4,0,
553,18,99,1,1,3
553,18,104,4,0,
553,18,156,4,0,
553,18,157,4,0,
553,18,164,4,0,
553,18,168,4,0,
553,18,182,4,0,
553,18,184,1,36,
553,18,188,4,0,
553,18,189,1,19,
553,18,200,1,60,
553,18,201,1,48,
553,18,201,4,0,
553,18,207,1,25,
553,18,207,4,0,
553,18,213,4,0,
553,18,214,4,0,
553,18,216,4,0,
553,18,218,4,0,
553,18,237,4,0,
553,18,242,1,28,
553,18,259,1,10,
553,18,259,4,0,
553,18,263,4,0,
553,18,269,4,0,
553,18,280,4,0,
553,18,317,4,0,
553,18,328,1,13,
553,18,332,4,0,
553,18,337,4,0,
553,18,339,4,0,
553,18,371,4,0,
553,18,372,1,16,
553,18,373,1,22,
553,18,373,4,0,
553,18,374,4,0,
553,18,399,4,0,
553,18,411,4,0,
553,18,416,4,0,
553,18,421,4,0,
553,18,444,4,0,
553,18,447,4,0,
553,18,479,4,0,
553,18,490,4,0,
553,18,492,1,42,
553,18,496,4,0,
553,18,523,4,0,
553,18,525,4,0,
553,18,555,4,0,
553,18,590,4,0,
553,18,681,1,1,1
553,18,693,4,0,
554,11,7,1,22,
554,11,29,1,14,
554,11,33,1,1,
554,11,36,2,0,
554,11,37,1,27,
554,11,46,4,0,
554,11,53,4,0,
554,11,70,4,0,
554,11,76,4,0,
554,11,91,4,0,
554,11,92,4,0,
554,11,99,1,9,
554,11,104,4,0,
554,11,116,2,0,
554,11,126,4,0,
554,11,156,4,0,
554,11,157,4,0,
554,11,164,4,0,
554,11,168,4,0,
554,11,172,2,0,
554,11,182,4,0,
554,11,187,1,30,
554,11,203,2,0,
554,11,205,1,3,
554,11,207,4,0,
554,11,213,4,0,
554,11,214,2,0,
554,11,216,4,0,
554,11,218,4,0,
554,11,227,2,0,
554,11,237,4,0,
554,11,241,4,0,
554,11,249,4,0,
554,11,253,1,17,
554,11,261,4,0,
554,11,263,1,19,
554,11,263,4,0,
554,11,264,2,0,
554,11,269,1,35,
554,11,269,4,0,
554,11,276,1,39,
554,11,280,4,0,
554,11,281,2,0,
554,11,315,1,42,
554,11,315,4,0,
554,11,317,4,0,
554,11,359,2,0,
554,11,360,4,0,
554,11,369,4,0,
554,11,374,4,0,
554,11,394,1,33,
554,11,424,1,11,
554,11,447,4,0,
554,11,488,4,0,
554,11,496,4,0,
554,11,510,1,6,
554,11,510,4,0,
554,11,526,1,25,
554,11,526,4,0,
554,14,7,1,22,
554,14,7,3,0,
554,14,29,1,14,
554,14,33,1,1,
554,14,36,2,0,
554,14,37,1,27,
554,14,46,4,0,
554,14,53,4,0,
554,14,70,4,0,
554,14,76,4,0,
554,14,91,4,0,
554,14,92,4,0,
554,14,99,1,9,
554,14,104,4,0,
554,14,116,2,0,
554,14,126,4,0,
554,14,156,4,0,
554,14,157,4,0,
554,14,164,4,0,
554,14,168,4,0,
554,14,172,2,0,
554,14,173,3,0,
554,14,182,4,0,
554,14,187,1,30,
554,14,203,2,0,
554,14,205,1,3,
554,14,207,4,0,
554,14,213,4,0,
554,14,214,2,0,
554,14,214,3,0,
554,14,216,4,0,
554,14,218,4,0,
554,14,227,2,0,
554,14,237,4,0,
554,14,241,4,0,
554,14,249,4,0,
554,14,253,1,17,
554,14,253,3,0,
554,14,257,3,0,
554,14,261,4,0,
554,14,263,1,19,
554,14,263,4,0,
554,14,264,2,0,
554,14,269,1,35,
554,14,269,4,0,
554,14,276,1,39,
554,14,276,3,0,
554,14,280,4,0,
554,14,281,2,0,
554,14,283,3,0,
554,14,315,1,42,
554,14,315,4,0,
554,14,317,4,0,
554,14,359,2,0,
554,14,360,4,0,
554,14,369,4,0,
554,14,374,4,0,
554,14,394,1,33,
554,14,424,1,11,
554,14,428,3,0,
554,14,447,4,0,
554,14,488,4,0,
554,14,496,4,0,
554,14,510,1,6,
554,14,510,4,0,
554,14,526,1,25,
554,14,526,4,0,
554,15,7,1,22,
554,15,29,1,14,
554,15,33,1,1,
554,15,36,2,0,
554,15,37,1,27,
554,15,46,4,0,
554,15,53,4,0,
554,15,70,4,0,
554,15,76,4,0,
554,15,91,4,0,
554,15,92,4,0,
554,15,99,1,9,
554,15,104,4,0,
554,15,116,2,0,
554,15,126,4,0,
554,15,156,4,0,
554,15,157,4,0,
554,15,164,4,0,
554,15,168,4,0,
554,15,172,2,0,
554,15,182,4,0,
554,15,187,1,30,
554,15,203,2,0,
554,15,205,1,3,
554,15,207,4,0,
554,15,213,4,0,
554,15,214,2,0,
554,15,214,4,0,
554,15,216,4,0,
554,15,218,4,0,
554,15,227,2,0,
554,15,237,4,0,
554,15,241,4,0,
554,15,249,4,0,
554,15,253,1,17,
554,15,261,4,0,
554,15,263,1,19,
554,15,263,4,0,
554,15,264,2,0,
554,15,269,1,35,
554,15,269,4,0,
554,15,276,1,39,
554,15,280,4,0,
554,15,281,2,0,
554,15,289,2,0,
554,15,315,1,42,
554,15,315,4,0,
554,15,317,4,0,
554,15,359,2,0,
554,15,360,4,0,
554,15,369,4,0,
554,15,374,4,0,
554,15,394,1,33,
554,15,424,1,11,
554,15,447,4,0,
554,15,488,4,0,
554,15,496,4,0,
554,15,510,1,6,
554,15,510,4,0,
554,15,526,1,25,
554,15,590,4,0,
554,15,612,4,0,
554,16,7,1,22,1
554,16,7,3,0,
554,16,29,1,14,1
554,16,33,1,1,1
554,16,36,2,0,
554,16,37,1,27,1
554,16,46,4,0,
554,16,53,4,0,
554,16,70,4,0,
554,16,76,4,0,
554,16,91,4,0,
554,16,92,4,0,
554,16,99,1,9,1
554,16,104,4,0,
554,16,116,2,0,
554,16,126,4,0,
554,16,156,4,0,
554,16,157,4,0,
554,16,164,4,0,
554,16,168,4,0,
554,16,172,2,0,
554,16,173,3,0,
554,16,182,4,0,
554,16,187,1,30,1
554,16,203,2,0,
554,16,205,1,3,1
554,16,207,4,0,
554,16,213,4,0,
554,16,214,2,0,
554,16,214,4,0,
554,16,216,4,0,
554,16,218,4,0,
554,16,227,2,0,
554,16,237,4,0,
554,16,241,4,0,
554,16,249,4,0,
554,16,253,1,17,1
554,16,253,3,0,
554,16,257,3,0,
554,16,261,4,0,
554,16,263,1,19,1
554,16,263,4,0,
554,16,264,2,0,
554,16,264,3,0,
554,16,269,1,35,1
554,16,269,4,0,
554,16,276,1,39,1
554,16,276,3,0,
554,16,280,4,0,
554,16,281,2,0,
554,16,283,3,0,
554,16,289,2,0,
554,16,289,3,0,
554,16,290,4,0,
554,16,315,1,42,1
554,16,315,4,0,
554,16,317,4,0,
554,16,359,2,0,
554,16,360,4,0,
554,16,369,4,0,
554,16,374,4,0,
554,16,394,1,33,1
554,16,424,1,11,1
554,16,428,3,0,
554,16,447,4,0,
554,16,488,4,0,
554,16,496,4,0,
554,16,510,1,6,1
554,16,510,4,0,
554,16,526,1,25,1
554,16,590,4,0,
554,16,612,4,0,
554,17,7,1,22,
554,17,29,1,14,
554,17,33,1,1,
554,17,36,2,0,
554,17,37,1,27,
554,17,46,4,0,
554,17,53,4,0,
554,17,76,4,0,
554,17,92,4,0,
554,17,99,1,9,
554,17,104,4,0,
554,17,116,2,0,
554,17,126,4,0,
554,17,156,4,0,
554,17,157,4,0,
554,17,164,4,0,
554,17,168,4,0,
554,17,172,2,0,
554,17,182,4,0,
554,17,187,1,30,
554,17,203,2,0,
554,17,205,1,3,
554,17,207,4,0,
554,17,213,4,0,
554,17,214,2,0,
554,17,214,4,0,
554,17,216,4,0,
554,17,218,4,0,
554,17,227,2,0,
554,17,237,4,0,
554,17,241,4,0,
554,17,253,1,17,
554,17,261,4,0,
554,17,263,1,19,
554,17,263,4,0,
554,17,264,2,0,
554,17,269,1,35,
554,17,269,4,0,
554,17,276,1,39,
554,17,280,4,0,
554,17,281,2,0,
554,17,289,2,0,
554,17,315,1,42,
554,17,315,4,0,
554,17,317,4,0,
554,17,359,2,0,
554,17,360,4,0,
554,17,369,4,0,
554,17,374,4,0,
554,17,394,1,33,
554,17,424,1,11,
554,17,447,4,0,
554,17,488,4,0,
554,17,496,4,0,
554,17,510,1,6,
554,17,526,1,25,
554,17,526,4,0,
554,17,590,4,0,
554,18,7,1,22,
554,18,29,1,14,
554,18,33,1,1,
554,18,36,2,0,
554,18,37,1,27,
554,18,46,4,0,
554,18,53,4,0,
554,18,76,4,0,
554,18,92,4,0,
554,18,99,1,9,
554,18,104,4,0,
554,18,116,2,0,
554,18,126,4,0,
554,18,156,4,0,
554,18,157,4,0,
554,18,164,4,0,
554,18,168,4,0,
554,18,172,2,0,
554,18,182,4,0,
554,18,187,1,30,
554,18,203,2,0,
554,18,205,1,3,
554,18,207,4,0,
554,18,213,4,0,
554,18,214,2,0,
554,18,214,4,0,
554,18,216,4,0,
554,18,218,4,0,
554,18,227,2,0,
554,18,237,4,0,
554,18,241,4,0,
554,18,253,1,17,
554,18,261,4,0,
554,18,263,1,19,
554,18,263,4,0,
554,18,264,2,0,
554,18,269,1,35,
554,18,269,4,0,
554,18,276,1,39,
554,18,280,4,0,
554,18,281,2,0,
554,18,289,2,0,
554,18,315,1,42,
554,18,315,4,0,
554,18,317,4,0,
554,18,326,2,0,
554,18,359,2,0,
554,18,360,4,0,
554,18,369,4,0,
554,18,374,4,0,
554,18,394,1,33,
554,18,424,1,11,
554,18,447,4,0,
554,18,488,4,0,
554,18,496,4,0,
554,18,510,1,6,
554,18,526,1,25,
554,18,526,4,0,
554,18,590,4,0,
555,11,7,1,22,
555,11,29,1,14,
555,11,33,1,1,1
555,11,37,1,27,
555,11,46,4,0,
555,11,53,4,0,
555,11,63,4,0,
555,11,70,4,0,
555,11,76,4,0,
555,11,89,4,0,
555,11,91,4,0,
555,11,92,4,0,
555,11,94,4,0,
555,11,99,1,1,4
555,11,99,1,9,
555,11,104,4,0,
555,11,126,4,0,
555,11,156,4,0,
555,11,157,4,0,
555,11,164,4,0,
555,11,168,4,0,
555,11,182,4,0,
555,11,187,1,30,
555,11,205,1,1,2
555,11,205,1,3,
555,11,207,1,17,
555,11,207,4,0,
555,11,213,4,0,
555,11,216,4,0,
555,11,218,4,0,
555,11,237,4,0,
555,11,241,4,0,
555,11,249,4,0,
555,11,259,4,0,
555,11,261,4,0,
555,11,263,1,19,
555,11,263,4,0,
555,11,269,1,39,
555,11,269,4,0,
555,11,276,1,47,
555,11,280,4,0,
555,11,315,1,54,
555,11,315,4,0,
555,11,317,4,0,
555,11,339,4,0,
555,11,359,1,35,
555,11,360,4,0,
555,11,369,4,0,
555,11,371,4,0,
555,11,374,4,0,
555,11,394,1,33,
555,11,411,4,0,
555,11,416,4,0,
555,11,424,1,11,
555,11,444,4,0,
555,11,447,4,0,
555,11,479,4,0,
555,11,488,4,0,
555,11,496,4,0,
555,11,510,1,1,3
555,11,510,1,6,
555,11,510,4,0,
555,11,523,4,0,
555,11,526,1,25,
555,11,526,4,0,
555,14,7,1,22,
555,14,7,3,0,
555,14,29,1,14,
555,14,33,1,1,1
555,14,37,1,27,
555,14,46,4,0,
555,14,53,4,0,
555,14,63,4,0,
555,14,70,4,0,
555,14,76,4,0,
555,14,89,4,0,
555,14,91,4,0,
555,14,92,4,0,
555,14,94,4,0,
555,14,99,1,1,4
555,14,99,1,9,
555,14,104,4,0,
555,14,126,4,0,
555,14,156,4,0,
555,14,157,4,0,
555,14,164,4,0,
555,14,168,4,0,
555,14,173,3,0,
555,14,182,4,0,
555,14,187,1,30,
555,14,205,1,1,2
555,14,205,1,3,
555,14,207,1,17,
555,14,207,4,0,
555,14,213,4,0,
555,14,214,3,0,
555,14,216,4,0,
555,14,218,4,0,
555,14,237,4,0,
555,14,241,4,0,
555,14,249,4,0,
555,14,253,3,0,
555,14,257,3,0,
555,14,259,4,0,
555,14,261,4,0,
555,14,263,1,19,
555,14,263,4,0,
555,14,269,1,39,
555,14,269,4,0,
555,14,276,1,47,
555,14,276,3,0,
555,14,280,4,0,
555,14,283,3,0,
555,14,315,1,54,
555,14,315,4,0,
555,14,317,4,0,
555,14,339,4,0,
555,14,359,1,35,
555,14,360,4,0,
555,14,369,4,0,
555,14,371,4,0,
555,14,374,4,0,
555,14,394,1,33,
555,14,411,4,0,
555,14,416,4,0,
555,14,424,1,11,
555,14,428,3,0,
555,14,444,4,0,
555,14,447,4,0,
555,14,479,4,0,
555,14,488,4,0,
555,14,496,4,0,
555,14,510,1,1,3
555,14,510,1,6,
555,14,510,4,0,
555,14,523,4,0,
555,14,526,1,25,
555,14,526,4,0,
555,15,7,1,22,
555,15,29,1,14,
555,15,33,1,1,1
555,15,37,1,27,
555,15,46,4,0,
555,15,53,4,0,
555,15,63,4,0,
555,15,70,4,0,
555,15,76,4,0,
555,15,89,4,0,
555,15,91,4,0,
555,15,92,4,0,
555,15,94,4,0,
555,15,99,1,1,4
555,15,99,1,9,
555,15,104,4,0,
555,15,126,4,0,
555,15,156,4,0,
555,15,157,4,0,
555,15,164,4,0,
555,15,168,4,0,
555,15,182,4,0,
555,15,187,1,30,
555,15,205,1,1,2
555,15,205,1,3,
555,15,207,1,17,
555,15,207,4,0,
555,15,213,4,0,
555,15,214,4,0,
555,15,216,4,0,
555,15,218,4,0,
555,15,237,4,0,
555,15,241,4,0,
555,15,249,4,0,
555,15,259,4,0,
555,15,261,4,0,
555,15,263,1,19,
555,15,263,4,0,
555,15,269,1,39,
555,15,269,4,0,
555,15,276,1,47,
555,15,280,4,0,
555,15,315,1,54,
555,15,315,4,0,
555,15,317,4,0,
555,15,339,4,0,
555,15,359,1,35,
555,15,360,4,0,
555,15,369,4,0,
555,15,371,4,0,
555,15,374,4,0,
555,15,394,1,33,
555,15,411,4,0,
555,15,416,4,0,
555,15,424,1,11,
555,15,444,4,0,
555,15,447,4,0,
555,15,479,4,0,
555,15,488,4,0,
555,15,496,4,0,
555,15,510,1,1,3
555,15,510,1,6,
555,15,510,4,0,
555,15,523,4,0,
555,15,526,1,25,
555,15,590,4,0,
555,15,612,4,0,
555,16,7,1,22,1
555,16,7,3,0,
555,16,29,1,14,1
555,16,33,1,1,1
555,16,37,1,27,1
555,16,46,4,0,
555,16,53,4,0,
555,16,63,4,0,
555,16,70,4,0,
555,16,76,4,0,
555,16,89,4,0,
555,16,91,4,0,
555,16,92,4,0,
555,16,94,4,0,
555,16,99,1,1,4
555,16,99,1,9,1
555,16,104,4,0,
555,16,126,4,0,
555,16,156,4,0,
555,16,157,4,0,
555,16,164,4,0,
555,16,168,4,0,
555,16,173,3,0,
555,16,182,4,0,
555,16,187,1,30,1
555,16,205,1,1,2
555,16,205,1,3,1
555,16,207,1,17,1
555,16,207,4,0,
555,16,213,4,0,
555,16,214,4,0,
555,16,216,4,0,
555,16,218,4,0,
555,16,237,4,0,
555,16,241,4,0,
555,16,249,4,0,
555,16,253,3,0,
555,16,257,3,0,
555,16,259,4,0,
555,16,261,4,0,
555,16,263,1,19,1
555,16,263,4,0,
555,16,264,3,0,
555,16,269,1,39,1
555,16,269,4,0,
555,16,276,1,47,1
555,16,276,3,0,
555,16,280,4,0,
555,16,283,3,0,
555,16,289,3,0,
555,16,290,4,0,
555,16,315,1,54,1
555,16,315,4,0,
555,16,317,4,0,
555,16,339,4,0,
555,16,359,1,35,1
555,16,360,4,0,
555,16,369,4,0,
555,16,371,4,0,
555,16,374,4,0,
555,16,394,1,33,1
555,16,411,4,0,
555,16,416,4,0,
555,16,424,1,11,1
555,16,428,3,0,
555,16,444,4,0,
555,16,447,4,0,
555,16,479,4,0,
555,16,488,4,0,
555,16,496,4,0,
555,16,510,1,1,3
555,16,510,1,6,1
555,16,510,4,0,
555,16,523,4,0,
555,16,526,1,25,1
555,16,590,4,0,
555,16,612,4,0,
555,17,7,1,22,
555,17,29,1,14,
555,17,33,1,1,2
555,17,37,1,27,
555,17,46,4,0,
555,17,53,4,0,
555,17,63,4,0,
555,17,76,4,0,
555,17,89,4,0,
555,17,92,4,0,
555,17,94,4,0,
555,17,99,1,1,5
555,17,99,1,9,
555,17,104,4,0,
555,17,126,4,0,
555,17,156,4,0,
555,17,157,4,0,
555,17,164,4,0,
555,17,168,4,0,
555,17,182,4,0,
555,17,187,1,30,
555,17,205,1,1,3
555,17,205,1,3,
555,17,207,1,17,
555,17,207,4,0,
555,17,213,4,0,
555,17,214,4,0,
555,17,216,4,0,
555,17,218,4,0,
555,17,237,4,0,
555,17,241,4,0,
555,17,259,4,0,
555,17,261,4,0,
555,17,263,1,19,
555,17,263,4,0,
555,17,269,1,39,
555,17,269,4,0,
555,17,276,1,47,
555,17,280,4,0,
555,17,315,1,54,
555,17,315,4,0,
555,17,317,4,0,
555,17,339,4,0,
555,17,359,1,0,
555,17,359,1,1,1
555,17,360,4,0,
555,17,369,4,0,
555,17,371,4,0,
555,17,374,4,0,
555,17,394,1,33,
555,17,411,4,0,
555,17,416,4,0,
555,17,424,1,11,
555,17,444,4,0,
555,17,447,4,0,
555,17,479,4,0,
555,17,488,4,0,
555,17,496,4,0,
555,17,510,1,1,4
555,17,510,1,6,
555,17,523,4,0,
555,17,526,1,25,
555,17,526,4,0,
555,17,590,4,0,
555,18,7,1,22,
555,18,29,1,14,
555,18,33,1,1,2
555,18,37,1,27,
555,18,46,4,0,
555,18,53,4,0,
555,18,63,4,0,
555,18,76,4,0,
555,18,89,4,0,
555,18,92,4,0,
555,18,94,4,0,
555,18,99,1,1,5
555,18,99,1,9,
555,18,104,4,0,
555,18,126,4,0,
555,18,156,4,0,
555,18,157,4,0,
555,18,164,4,0,
555,18,168,4,0,
555,18,182,4,0,
555,18,187,1,30,
555,18,205,1,1,3
555,18,205,1,3,
555,18,207,1,17,
555,18,207,4,0,
555,18,213,4,0,
555,18,214,4,0,
555,18,216,4,0,
555,18,218,4,0,
555,18,237,4,0,
555,18,241,4,0,
555,18,259,4,0,
555,18,261,4,0,
555,18,263,1,19,
555,18,263,4,0,
555,18,269,1,39,
555,18,269,4,0,
555,18,276,1,47,
555,18,280,4,0,
555,18,315,1,54,
555,18,315,4,0,
555,18,317,4,0,
555,18,339,4,0,
555,18,359,1,0,
555,18,359,1,1,1
555,18,360,4,0,
555,18,369,4,0,
555,18,371,4,0,
555,18,374,4,0,
555,18,394,1,33,
555,18,411,4,0,
555,18,416,4,0,
555,18,424,1,11,
555,18,444,4,0,
555,18,447,4,0,
555,18,479,4,0,
555,18,488,4,0,
555,18,496,4,0,
555,18,510,1,1,4
555,18,510,1,6,
555,18,523,4,0,
555,18,526,1,25,
555,18,526,4,0,
555,18,590,4,0,
556,11,42,1,10,
556,11,64,1,1,1
556,11,71,1,1,2
556,11,72,1,13,
556,11,73,2,0,
556,11,74,1,6,
556,11,76,1,50,
556,11,76,4,0,
556,11,80,1,38,
556,11,92,4,0,
556,11,104,4,0,
556,11,156,4,0,
556,11,164,4,0,
556,11,178,1,18,
556,11,182,4,0,
556,11,191,2,0,
556,11,202,1,26,
556,11,207,4,0,
556,11,213,4,0,
556,11,216,4,0,
556,11,218,4,0,
556,11,219,4,0,
556,11,230,1,3,
556,11,235,1,15,
556,11,237,4,0,
556,11,241,1,45,
556,11,241,4,0,
556,11,263,4,0,
556,11,275,1,33,
556,11,302,1,22,
556,11,320,2,0,
556,11,331,2,0,
556,11,332,4,0,
556,11,340,2,0,
556,11,367,1,29,
556,11,388,2,0,
556,11,389,1,42,
556,11,398,4,0,
556,11,402,2,0,
556,11,412,4,0,
556,11,447,4,0,
556,11,452,2,0,
556,11,495,1,57,
556,11,496,4,0,
556,11,538,1,55,
556,14,42,1,10,
556,14,64,1,1,1
556,14,71,1,1,2
556,14,72,1,13,
556,14,73,2,0,
556,14,74,1,6,
556,14,76,1,50,
556,14,76,4,0,
556,14,80,1,38,
556,14,92,4,0,
556,14,104,4,0,
556,14,156,4,0,
556,14,164,4,0,
556,14,173,3,0,
556,14,178,1,18,
556,14,182,4,0,
556,14,191,2,0,
556,14,202,1,26,
556,14,202,3,0,
556,14,207,4,0,
556,14,213,4,0,
556,14,214,3,0,
556,14,216,4,0,
556,14,218,4,0,
556,14,219,4,0,
556,14,230,1,3,
556,14,235,1,15,
556,14,235,3,0,
556,14,237,4,0,
556,14,241,1,45,
556,14,241,4,0,
556,14,253,3,0,
556,14,263,4,0,
556,14,270,3,0,
556,14,275,1,33,
556,14,282,3,0,
556,14,283,3,0,
556,14,302,1,22,
556,14,304,3,0,
556,14,320,2,0,
556,14,331,2,0,
556,14,332,4,0,
556,14,340,2,0,
556,14,340,3,0,
556,14,367,1,29,
556,14,388,2,0,
556,14,388,3,0,
556,14,389,1,42,
556,14,398,4,0,
556,14,402,2,0,
556,14,402,3,0,
556,14,409,3,0,
556,14,412,4,0,
556,14,447,4,0,
556,14,452,2,0,
556,14,495,1,57,
556,14,495,3,0,
556,14,496,4,0,
556,14,538,1,55,
556,15,42,1,10,
556,15,64,1,1,3
556,15,71,1,1,4
556,15,72,1,13,
556,15,73,2,0,
556,15,74,1,6,
556,15,76,1,50,
556,15,76,4,0,
556,15,80,1,38,
556,15,92,4,0,
556,15,104,4,0,
556,15,156,4,0,
556,15,164,4,0,
556,15,178,1,18,
556,15,182,4,0,
556,15,191,2,0,
556,15,202,1,26,
556,15,207,4,0,
556,15,213,4,0,
556,15,214,4,0,
556,15,216,4,0,
556,15,218,4,0,
556,15,219,4,0,
556,15,230,1,3,
556,15,235,1,15,
556,15,237,4,0,
556,15,241,1,45,
556,15,241,4,0,
556,15,263,4,0,
556,15,267,4,0,
556,15,275,1,33,
556,15,302,1,22,
556,15,320,2,0,
556,15,331,2,0,
556,15,332,4,0,
556,15,340,2,0,
556,15,367,1,29,
556,15,388,2,0,
556,15,389,1,42,
556,15,398,4,0,
556,15,402,2,0,
556,15,412,4,0,
556,15,447,4,0,
556,15,452,2,0,
556,15,495,1,1,2
556,15,495,1,57,
556,15,496,4,0,
556,15,538,1,1,1
556,15,538,1,55,
556,15,572,1,48,
556,15,580,2,0,
556,15,590,4,0,
556,16,42,1,10,1
556,16,64,1,1,4
556,16,71,1,1,5
556,16,72,1,13,1
556,16,73,2,0,
556,16,74,1,6,1
556,16,76,1,50,1
556,16,76,4,0,
556,16,80,1,38,1
556,16,92,4,0,
556,16,104,4,0,
556,16,156,4,0,
556,16,164,4,0,
556,16,173,3,0,
556,16,178,1,18,1
556,16,182,4,0,
556,16,191,2,0,
556,16,202,1,26,1
556,16,202,3,0,
556,16,207,4,0,
556,16,213,4,0,
556,16,214,4,0,
556,16,216,4,0,
556,16,218,4,0,
556,16,219,4,0,
556,16,230,1,3,1
556,16,235,1,15,1
556,16,235,3,0,
556,16,237,4,0,
556,16,241,1,45,1
556,16,241,4,0,
556,16,253,3,0,
556,16,263,4,0,
556,16,267,4,0,
556,16,270,3,0,
556,16,275,1,33,1
556,16,282,3,0,
556,16,283,3,0,
556,16,290,4,0,
556,16,302,1,22,1
556,16,304,3,0,
556,16,320,2,0,
556,16,331,2,0,
556,16,332,4,0,
556,16,340,2,0,
556,16,340,3,0,
556,16,367,1,29,1
556,16,388,2,0,
556,16,388,3,0,
556,16,389,1,42,1
556,16,398,4,0,
556,16,402,2,0,
556,16,402,3,0,
556,16,409,3,0,
556,16,412,4,0,
556,16,447,4,0,
556,16,452,2,0,
556,16,495,1,1,3
556,16,495,1,57,1
556,16,495,3,0,
556,16,496,4,0,
556,16,538,1,1,2
556,16,538,1,55,1
556,16,572,1,48,1
556,16,580,2,0,
556,16,590,4,0,
556,16,596,1,1,1
556,17,42,1,10,
556,17,64,1,1,4
556,17,71,1,1,5
556,17,72,1,13,
556,17,73,2,0,
556,17,74,1,6,
556,17,76,1,50,
556,17,76,4,0,
556,17,80,1,38,
556,17,92,4,0,
556,17,104,4,0,
556,17,156,4,0,
556,17,164,4,0,
556,17,178,1,18,
556,17,182,4,0,
556,17,191,2,0,
556,17,202,1,26,
556,17,207,4,0,
556,17,213,4,0,
556,17,214,4,0,
556,17,216,4,0,
556,17,218,4,0,
556,17,219,4,0,
556,17,230,1,3,
556,17,235,1,15,
556,17,237,4,0,
556,17,241,1,45,
556,17,241,4,0,
556,17,263,4,0,
556,17,267,4,0,
556,17,275,1,33,
556,17,302,1,22,
556,17,320,2,0,
556,17,331,2,0,
556,17,332,4,0,
556,17,340,2,0,
556,17,367,1,29,
556,17,388,2,0,
556,17,389,1,42,
556,17,398,4,0,
556,17,402,2,0,
556,17,412,4,0,
556,17,447,4,0,
556,17,452,2,0,
556,17,495,1,1,3
556,17,495,1,57,
556,17,496,4,0,
556,17,538,1,1,2
556,17,538,1,55,
556,17,572,1,48,
556,17,580,2,0,
556,17,590,4,0,
556,17,596,1,1,1
556,18,42,1,10,
556,18,64,1,1,4
556,18,71,1,1,5
556,18,72,1,13,
556,18,73,2,0,
556,18,74,1,6,
556,18,76,1,50,
556,18,76,4,0,
556,18,80,1,38,
556,18,92,4,0,
556,18,104,4,0,
556,18,156,4,0,
556,18,164,4,0,
556,18,178,1,18,
556,18,182,4,0,
556,18,191,2,0,
556,18,202,1,26,
556,18,207,4,0,
556,18,213,4,0,
556,18,214,4,0,
556,18,216,4,0,
556,18,218,4,0,
556,18,219,4,0,
556,18,230,1,3,
556,18,235,1,15,
556,18,237,4,0,
556,18,241,1,45,
556,18,241,4,0,
556,18,263,4,0,
556,18,267,4,0,
556,18,275,1,33,
556,18,302,1,22,
556,18,320,2,0,
556,18,331,2,0,
556,18,332,4,0,
556,18,340,2,0,
556,18,367,1,29,
556,18,388,2,0,
556,18,389,1,42,
556,18,398,4,0,
556,18,402,2,0,
556,18,412,4,0,
556,18,447,4,0,
556,18,452,2,0,
556,18,495,1,1,3
556,18,495,1,57,
556,18,496,4,0,
556,18,538,1,1,2
556,18,538,1,55,
556,18,572,1,48,
556,18,580,2,0,
556,18,590,4,0,
556,18,596,1,1,1
557,11,14,4,0,
557,11,15,4,0,
557,11,28,1,11,
557,11,68,2,0,
557,11,70,4,0,
557,11,76,4,0,
557,11,89,4,0,
557,11,91,4,0,
557,11,92,4,0,
557,11,104,4,0,
557,11,110,1,7,
557,11,156,4,0,
557,11,157,1,29,
557,11,157,4,0,
557,11,163,1,31,
557,11,164,4,0,
557,11,174,2,0,
557,11,175,1,41,
557,11,182,4,0,
557,11,185,1,13,
557,11,191,2,0,
557,11,201,4,0,
557,11,203,2,0,
557,11,207,4,0,
557,11,210,1,1,
557,11,213,4,0,
557,11,216,4,0,
557,11,218,4,0,
557,11,237,4,0,
557,11,249,4,0,
557,11,263,4,0,
557,11,317,4,0,
557,11,328,2,0,
557,11,332,4,0,
557,11,334,2,0,
557,11,335,2,0,
557,11,350,1,5,
557,11,397,1,19,
557,11,397,4,0,
557,11,398,4,0,
557,11,400,2,0,
557,11,404,1,35,
557,11,404,4,0,
557,11,421,4,0,
557,11,439,1,43,
557,11,444,4,0,
557,11,446,1,24,
557,11,450,1,23,
557,11,468,4,0,
557,11,479,1,17,
557,11,479,4,0,
557,11,496,4,0,
557,11,504,1,37,
557,11,522,4,0,
557,11,523,4,0,
557,14,14,4,0,
557,14,15,4,0,
557,14,28,1,11,
557,14,68,2,0,
557,14,70,4,0,
557,14,76,4,0,
557,14,89,4,0,
557,14,91,4,0,
557,14,92,4,0,
557,14,104,4,0,
557,14,110,1,7,
557,14,156,4,0,
557,14,157,1,29,
557,14,157,4,0,
557,14,163,1,31,
557,14,164,4,0,
557,14,173,3,0,
557,14,174,2,0,
557,14,175,1,41,
557,14,182,4,0,
557,14,185,1,13,
557,14,191,2,0,
557,14,201,4,0,
557,14,203,2,0,
557,14,207,4,0,
557,14,210,1,1,
557,14,213,4,0,
557,14,214,3,0,
557,14,216,4,0,
557,14,218,4,0,
557,14,237,4,0,
557,14,249,4,0,
557,14,263,4,0,
557,14,282,3,0,
557,14,317,4,0,
557,14,328,2,0,
557,14,332,4,0,
557,14,334,2,0,
557,14,334,3,0,
557,14,335,2,0,
557,14,335,3,0,
557,14,350,1,5,
557,14,397,1,19,
557,14,397,4,0,
557,14,398,4,0,
557,14,400,2,0,
557,14,404,1,35,
557,14,404,4,0,
557,14,421,4,0,
557,14,439,1,43,
557,14,444,4,0,
557,14,446,1,24,
557,14,446,3,0,
557,14,450,1,23,
557,14,450,3,0,
557,14,468,4,0,
557,14,479,1,17,
557,14,479,4,0,
557,14,496,4,0,
557,14,504,1,37,
557,14,522,4,0,
557,14,523,4,0,
557,15,14,4,0,
557,15,15,4,0,
557,15,28,1,11,
557,15,68,2,0,
557,15,70,4,0,
557,15,76,4,0,
557,15,89,4,0,
557,15,91,4,0,
557,15,92,4,0,
557,15,104,4,0,
557,15,110,1,7,
557,15,156,4,0,
557,15,157,1,29,
557,15,157,4,0,
557,15,163,1,31,
557,15,164,4,0,
557,15,174,2,0,
557,15,175,1,41,
557,15,182,4,0,
557,15,185,1,13,
557,15,191,2,0,
557,15,201,4,0,
557,15,203,2,0,
557,15,207,4,0,
557,15,210,1,1,
557,15,213,4,0,
557,15,214,4,0,
557,15,216,4,0,
557,15,218,4,0,
557,15,237,4,0,
557,15,249,4,0,
557,15,263,4,0,
557,15,267,4,0,
557,15,317,4,0,
557,15,328,2,0,
557,15,332,4,0,
557,15,334,2,0,
557,15,335,2,0,
557,15,350,1,5,
557,15,397,1,19,
557,15,397,4,0,
557,15,398,4,0,
557,15,400,2,0,
557,15,404,1,35,
557,15,404,4,0,
557,15,421,4,0,
557,15,439,1,43,
557,15,444,4,0,
557,15,446,1,24,
557,15,450,1,23,
557,15,468,4,0,
557,15,469,2,0,
557,15,479,1,17,
557,15,479,4,0,
557,15,496,4,0,
557,15,504,1,37,
557,15,522,4,0,
557,15,523,4,0,
557,15,563,2,0,
557,15,590,4,0,
557,16,14,4,0,
557,16,15,4,0,
557,16,28,1,11,1
557,16,68,2,0,
557,16,70,4,0,
557,16,76,4,0,
557,16,89,4,0,
557,16,91,4,0,
557,16,92,4,0,
557,16,104,4,0,
557,16,110,1,7,1
557,16,156,4,0,
557,16,157,1,29,1
557,16,157,4,0,
557,16,163,1,31,1
557,16,164,4,0,
557,16,173,3,0,
557,16,174,2,0,
557,16,175,1,41,1
557,16,182,4,0,
557,16,185,1,13,1
557,16,191,2,0,
557,16,201,4,0,
557,16,203,2,0,
557,16,207,4,0,
557,16,210,1,1,1
557,16,213,4,0,
557,16,214,4,0,
557,16,216,4,0,
557,16,218,4,0,
557,16,237,4,0,
557,16,249,4,0,
557,16,263,4,0,
557,16,267,4,0,
557,16,282,3,0,
557,16,290,4,0,
557,16,317,4,0,
557,16,328,2,0,
557,16,332,4,0,
557,16,334,2,0,
557,16,334,3,0,
557,16,335,2,0,
557,16,335,3,0,
557,16,350,1,5,1
557,16,397,1,19,1
557,16,397,4,0,
557,16,398,4,0,
557,16,400,2,0,
557,16,404,1,35,1
557,16,404,4,0,
557,16,421,4,0,
557,16,439,1,43,1
557,16,444,4,0,
557,16,446,1,24,1
557,16,446,3,0,
557,16,450,1,23,1
557,16,450,3,0,
557,16,468,4,0,
557,16,469,2,0,
557,16,479,1,17,1
557,16,479,4,0,
557,16,496,4,0,
557,16,504,1,37,1
557,16,522,4,0,
557,16,523,4,0,
557,16,563,2,0,
557,16,590,4,0,
557,17,14,4,0,
557,17,28,1,11,
557,17,68,2,0,
557,17,76,4,0,
557,17,89,4,0,
557,17,92,4,0,
557,17,104,4,0,
557,17,110,1,7,
557,17,156,4,0,
557,17,157,1,29,
557,17,157,4,0,
557,17,163,1,31,
557,17,164,4,0,
557,17,174,2,0,
557,17,175,1,41,
557,17,182,4,0,
557,17,185,1,13,
557,17,191,2,0,
557,17,201,4,0,
557,17,203,2,0,
557,17,207,4,0,
557,17,210,1,1,
557,17,213,4,0,
557,17,214,4,0,
557,17,216,4,0,
557,17,218,4,0,
557,17,237,4,0,
557,17,263,4,0,
557,17,267,4,0,
557,17,317,4,0,
557,17,328,2,0,
557,17,332,4,0,
557,17,334,2,0,
557,17,335,2,0,
557,17,350,1,5,
557,17,397,1,19,
557,17,397,4,0,
557,17,398,4,0,
557,17,400,2,0,
557,17,404,1,35,
557,17,404,4,0,
557,17,421,4,0,
557,17,439,1,43,
557,17,444,4,0,
557,17,446,1,24,
557,17,450,1,23,
557,17,469,2,0,
557,17,479,1,17,
557,17,479,4,0,
557,17,496,4,0,
557,17,504,1,37,
557,17,523,4,0,
557,17,563,2,0,
557,17,590,4,0,
557,18,14,4,0,
557,18,28,1,11,
557,18,68,2,0,
557,18,76,4,0,
557,18,89,4,0,
557,18,92,4,0,
557,18,104,4,0,
557,18,110,1,7,
557,18,156,4,0,
557,18,157,1,29,
557,18,157,4,0,
557,18,163,1,31,
557,18,164,4,0,
557,18,174,2,0,
557,18,175,1,41,
557,18,182,4,0,
557,18,185,1,13,
557,18,191,2,0,
557,18,201,4,0,
557,18,203,2,0,
557,18,207,4,0,
557,18,210,1,1,
557,18,213,4,0,
557,18,214,4,0,
557,18,216,4,0,
557,18,218,4,0,
557,18,237,4,0,
557,18,263,4,0,
557,18,267,4,0,
557,18,317,4,0,
557,18,328,2,0,
557,18,332,4,0,
557,18,334,2,0,
557,18,335,2,0,
557,18,350,1,5,
557,18,397,1,19,
557,18,397,4,0,
557,18,398,4,0,
557,18,400,2,0,
557,18,404,1,35,
557,18,404,4,0,
557,18,421,4,0,
557,18,439,1,43,
557,18,444,4,0,
557,18,446,1,24,
557,18,450,1,23,
557,18,469,2,0,
557,18,479,1,17,
557,18,479,4,0,
557,18,496,4,0,
557,18,504,1,37,
557,18,523,4,0,
557,18,563,2,0,
557,18,590,4,0,
558,11,14,4,0,
558,11,15,4,0,
558,11,28,1,1,4
558,11,28,1,11,
558,11,63,4,0,
558,11,70,4,0,
558,11,76,4,0,
558,11,89,4,0,
558,11,91,4,0,
558,11,92,4,0,
558,11,104,4,0,
558,11,110,1,1,3
558,11,110,1,7,
558,11,156,4,0,
558,11,157,1,29,
558,11,157,4,0,
558,11,163,1,31,
558,11,164,4,0,
558,11,175,1,50,
558,11,182,4,0,
558,11,185,1,13,
558,11,201,4,0,
558,11,207,4,0,
558,11,213,4,0,
558,11,216,4,0,
558,11,218,4,0,
558,11,237,4,0,
558,11,249,4,0,
558,11,263,4,0,
558,11,317,4,0,
558,11,332,4,0,
558,11,350,1,1,2
558,11,350,1,5,
558,11,397,1,19,
558,11,397,4,0,
558,11,398,4,0,
558,11,404,1,38,
558,11,404,4,0,
558,11,416,4,0,
558,11,421,4,0,
558,11,439,1,55,
558,11,444,4,0,
558,11,446,1,24,
558,11,450,1,23,
558,11,468,4,0,
558,11,479,1,17,
558,11,479,4,0,
558,11,496,4,0,
558,11,504,1,1,1
558,11,504,1,43,
558,11,522,4,0,
558,11,523,4,0,
558,14,14,4,0,
558,14,15,4,0,
558,14,28,1,1,4
558,14,28,1,11,
558,14,63,4,0,
558,14,70,4,0,
558,14,76,4,0,
558,14,89,4,0,
558,14,91,4,0,
558,14,92,4,0,
558,14,104,4,0,
558,14,110,1,1,3
558,14,110,1,7,
558,14,156,4,0,
558,14,157,1,29,
558,14,157,4,0,
558,14,163,1,31,
558,14,164,4,0,
558,14,173,3,0,
558,14,175,1,50,
558,14,182,4,0,
558,14,185,1,13,
558,14,201,4,0,
558,14,207,4,0,
558,14,213,4,0,
558,14,214,3,0,
558,14,216,4,0,
558,14,218,4,0,
558,14,237,4,0,
558,14,249,4,0,
558,14,263,4,0,
558,14,282,3,0,
558,14,317,4,0,
558,14,332,4,0,
558,14,334,3,0,
558,14,335,3,0,
558,14,350,1,1,2
558,14,350,1,5,
558,14,397,1,19,
558,14,397,4,0,
558,14,398,4,0,
558,14,404,1,38,
558,14,404,4,0,
558,14,416,4,0,
558,14,421,4,0,
558,14,439,1,55,
558,14,444,4,0,
558,14,446,1,24,
558,14,446,3,0,
558,14,450,1,23,
558,14,450,3,0,
558,14,468,4,0,
558,14,479,1,17,
558,14,479,4,0,
558,14,496,4,0,
558,14,504,1,1,1
558,14,504,1,43,
558,14,522,4,0,
558,14,523,4,0,
558,15,14,4,0,
558,15,15,4,0,
558,15,28,1,1,4
558,15,28,1,11,
558,15,63,4,0,
558,15,70,4,0,
558,15,76,4,0,
558,15,89,4,0,
558,15,91,4,0,
558,15,92,4,0,
558,15,104,4,0,
558,15,110,1,1,3
558,15,110,1,7,
558,15,156,4,0,
558,15,157,1,29,
558,15,157,4,0,
558,15,163,1,31,
558,15,164,4,0,
558,15,175,1,50,
558,15,182,4,0,
558,15,185,1,13,
558,15,201,4,0,
558,15,207,4,0,
558,15,213,4,0,
558,15,214,4,0,
558,15,216,4,0,
558,15,218,4,0,
558,15,237,4,0,
558,15,249,4,0,
558,15,263,4,0,
558,15,267,4,0,
558,15,317,4,0,
558,15,332,4,0,
558,15,350,1,1,2
558,15,350,1,5,
558,15,397,1,19,
558,15,397,4,0,
558,15,398,4,0,
558,15,404,1,38,
558,15,404,4,0,
558,15,416,4,0,
558,15,421,4,0,
558,15,439,1,55,
558,15,444,4,0,
558,15,446,1,24,
558,15,450,1,23,
558,15,468,4,0,
558,15,479,1,17,
558,15,479,4,0,
558,15,496,4,0,
558,15,504,1,1,1
558,15,504,1,43,
558,15,522,4,0,
558,15,523,4,0,
558,15,590,4,0,
558,16,14,4,0,
558,16,15,4,0,
558,16,28,1,1,4
558,16,28,1,11,1
558,16,63,4,0,
558,16,70,4,0,
558,16,76,4,0,
558,16,89,4,0,
558,16,91,4,0,
558,16,92,4,0,
558,16,104,4,0,
558,16,110,1,1,3
558,16,110,1,7,1
558,16,156,4,0,
558,16,157,1,29,1
558,16,157,4,0,
558,16,163,1,31,1
558,16,164,4,0,
558,16,173,3,0,
558,16,175,1,50,1
558,16,182,4,0,
558,16,185,1,13,1
558,16,201,4,0,
558,16,207,4,0,
558,16,213,4,0,
558,16,214,4,0,
558,16,216,4,0,
558,16,218,4,0,
558,16,237,4,0,
558,16,249,4,0,
558,16,263,4,0,
558,16,267,4,0,
558,16,282,3,0,
558,16,290,4,0,
558,16,317,4,0,
558,16,332,4,0,
558,16,334,3,0,
558,16,335,3,0,
558,16,350,1,1,2
558,16,350,1,5,1
558,16,397,1,19,1
558,16,397,4,0,
558,16,398,4,0,
558,16,404,1,38,1
558,16,404,4,0,
558,16,416,4,0,
558,16,421,4,0,
558,16,439,1,55,1
558,16,444,4,0,
558,16,446,1,24,1
558,16,446,3,0,
558,16,450,1,23,1
558,16,450,3,0,
558,16,468,4,0,
558,16,479,1,17,1
558,16,479,4,0,
558,16,496,4,0,
558,16,504,1,1,1
558,16,504,1,43,1
558,16,522,4,0,
558,16,523,4,0,
558,16,590,4,0,
558,17,14,4,0,
558,17,28,1,1,4
558,17,28,1,11,
558,17,63,4,0,
558,17,76,4,0,
558,17,89,4,0,
558,17,92,4,0,
558,17,104,4,0,
558,17,110,1,1,3
558,17,110,1,7,
558,17,156,4,0,
558,17,157,1,29,
558,17,157,4,0,
558,17,163,1,31,
558,17,164,4,0,
558,17,175,1,50,
558,17,182,4,0,
558,17,185,1,13,
558,17,201,4,0,
558,17,207,4,0,
558,17,213,4,0,
558,17,214,4,0,
558,17,216,4,0,
558,17,218,4,0,
558,17,237,4,0,
558,17,263,4,0,
558,17,267,4,0,
558,17,317,4,0,
558,17,332,4,0,
558,17,350,1,1,2
558,17,350,1,5,
558,17,397,1,19,
558,17,397,4,0,
558,17,398,4,0,
558,17,404,1,38,
558,17,404,4,0,
558,17,416,4,0,
558,17,421,4,0,
558,17,439,1,55,
558,17,444,4,0,
558,17,446,1,24,
558,17,450,1,23,
558,17,479,1,17,
558,17,479,4,0,
558,17,496,4,0,
558,17,504,1,1,1
558,17,504,1,43,
558,17,523,4,0,
558,17,590,4,0,
558,18,14,4,0,
558,18,28,1,1,4
558,18,28,1,11,
558,18,63,4,0,
558,18,76,4,0,
558,18,89,4,0,
558,18,92,4,0,
558,18,104,4,0,
558,18,110,1,1,3
558,18,110,1,7,
558,18,156,4,0,
558,18,157,1,29,
558,18,157,4,0,
558,18,163,1,31,
558,18,164,4,0,
558,18,175,1,50,
558,18,182,4,0,
558,18,185,1,13,
558,18,201,4,0,
558,18,207,4,0,
558,18,213,4,0,
558,18,214,4,0,
558,18,216,4,0,
558,18,218,4,0,
558,18,237,4,0,
558,18,263,4,0,
558,18,267,4,0,
558,18,317,4,0,
558,18,332,4,0,
558,18,350,1,1,2
558,18,350,1,5,
558,18,397,1,19,
558,18,397,4,0,
558,18,398,4,0,
558,18,404,1,38,
558,18,404,4,0,
558,18,416,4,0,
558,18,421,4,0,
558,18,439,1,55,
558,18,444,4,0,
558,18,446,1,24,
558,18,450,1,23,
558,18,479,1,17,
558,18,479,4,0,
558,18,496,4,0,
558,18,504,1,1,1
558,18,504,1,43,
558,18,523,4,0,
558,18,590,4,0,
559,11,7,2,0,
559,11,8,2,0,
559,11,9,2,0,
559,11,28,1,5,
559,11,29,1,12,
559,11,43,1,1,1
559,11,46,4,0,
559,11,67,1,1,2
559,11,68,2,0,
559,11,70,4,0,
559,11,91,4,0,
559,11,92,4,0,
559,11,104,4,0,
559,11,133,2,0,
559,11,136,1,31,
559,11,156,4,0,
559,11,157,4,0,
559,11,164,4,0,
559,11,182,4,0,
559,11,184,1,34,
559,11,185,1,9,
559,11,185,2,0,
559,11,188,4,0,
559,11,197,2,0,
559,11,207,1,16,
559,11,207,4,0,
559,11,213,4,0,
559,11,216,4,0,
559,11,218,4,0,
559,11,237,4,0,
559,11,240,4,0,
559,11,241,4,0,
559,11,242,1,38,
559,11,249,4,0,
559,11,252,2,0,
559,11,259,4,0,
559,11,263,1,42,
559,11,263,4,0,
559,11,264,1,49,
559,11,269,4,0,
559,11,280,1,20,
559,11,280,4,0,
559,11,317,4,0,
559,11,337,4,0,
559,11,339,4,0,
559,11,349,2,0,
559,11,371,1,23,
559,11,371,4,0,
559,11,374,4,0,
559,11,398,4,0,
559,11,409,2,0,
559,11,411,4,0,
559,11,428,2,0,
559,11,431,1,45,
559,11,444,4,0,
559,11,447,4,0,
559,11,457,1,53,
559,11,479,4,0,
559,11,490,4,0,
559,11,496,4,0,
559,11,498,1,27,
559,11,510,4,0,
559,11,514,4,0,
559,11,525,4,0,
559,11,526,4,0,
559,11,555,4,0,
559,14,7,2,0,
559,14,7,3,0,
559,14,8,2,0,
559,14,8,3,0,
559,14,9,2,0,
559,14,9,3,0,
559,14,28,1,5,
559,14,29,1,12,
559,14,43,1,1,1
559,14,46,4,0,
559,14,67,1,1,2
559,14,67,3,0,
559,14,68,2,0,
559,14,70,4,0,
559,14,91,4,0,
559,14,92,4,0,
559,14,104,4,0,
559,14,133,2,0,
559,14,136,1,31,
559,14,156,4,0,
559,14,157,4,0,
559,14,162,3,0,
559,14,164,4,0,
559,14,173,3,0,
559,14,180,3,0,
559,14,182,4,0,
559,14,184,1,34,
559,14,185,1,9,
559,14,185,2,0,
559,14,188,4,0,
559,14,197,2,0,
559,14,207,1,16,
559,14,207,4,0,
559,14,213,4,0,
559,14,214,3,0,
559,14,216,4,0,
559,14,218,4,0,
559,14,231,3,0,
559,14,237,4,0,
559,14,240,4,0,
559,14,241,4,0,
559,14,242,1,38,
559,14,249,4,0,
559,14,252,2,0,
559,14,259,4,0,
559,14,263,1,42,
559,14,263,4,0,
559,14,264,1,49,
559,14,269,4,0,
559,14,280,1,20,
559,14,280,4,0,
559,14,282,3,0,
559,14,289,3,0,
559,14,317,4,0,
559,14,334,3,0,
559,14,337,4,0,
559,14,339,4,0,
559,14,349,2,0,
559,14,371,1,23,
559,14,371,4,0,
559,14,374,4,0,
559,14,398,4,0,
559,14,399,3,0,
559,14,406,3,0,
559,14,409,2,0,
559,14,409,3,0,
559,14,411,4,0,
559,14,428,2,0,
559,14,428,3,0,
559,14,431,1,45,
559,14,442,3,0,
559,14,444,4,0,
559,14,447,4,0,
559,14,457,1,53,
559,14,479,4,0,
559,14,490,4,0,
559,14,492,3,0,
559,14,496,4,0,
559,14,498,1,27,
559,14,510,4,0,
559,14,514,4,0,
559,14,525,4,0,
559,14,526,4,0,
559,14,530,3,0,
559,14,555,4,0,
559,15,7,2,0,
559,15,8,2,0,
559,15,9,2,0,
559,15,28,1,5,
559,15,29,1,12,
559,15,43,1,1,1
559,15,46,4,0,
559,15,67,1,1,2
559,15,68,2,0,
559,15,70,4,0,
559,15,91,4,0,
559,15,92,4,0,
559,15,104,4,0,
559,15,133,2,0,
559,15,136,1,31,
559,15,156,4,0,
559,15,157,4,0,
559,15,164,4,0,
559,15,182,4,0,
559,15,184,1,34,
559,15,185,1,9,
559,15,185,2,0,
559,15,188,4,0,
559,15,197,2,0,
559,15,207,1,16,
559,15,207,4,0,
559,15,213,4,0,
559,15,214,4,0,
559,15,216,4,0,
559,15,218,4,0,
559,15,237,4,0,
559,15,240,4,0,
559,15,241,4,0,
559,15,242,1,38,
559,15,249,4,0,
559,15,252,2,0,
559,15,259,4,0,
559,15,263,1,42,
559,15,263,4,0,
559,15,264,1,48,
559,15,269,4,0,
559,15,280,1,20,
559,15,280,4,0,
559,15,317,4,0,
559,15,337,4,0,
559,15,339,4,0,
559,15,349,2,0,
559,15,371,1,23,
559,15,371,4,0,
559,15,374,4,0,
559,15,398,4,0,
559,15,399,4,0,
559,15,409,2,0,
559,15,411,4,0,
559,15,428,2,0,
559,15,431,1,45,
559,15,444,4,0,
559,15,447,4,0,
559,15,457,1,50,
559,15,479,4,0,
559,15,490,4,0,
559,15,496,4,0,
559,15,498,1,27,
559,15,501,2,0,
559,15,510,4,0,
559,15,514,4,0,
559,15,525,4,0,
559,15,555,4,0,
559,15,590,4,0,
559,15,612,4,0,
559,16,7,2,0,
559,16,7,3,0,
559,16,8,2,0,
559,16,8,3,0,
559,16,9,2,0,
559,16,9,3,0,
559,16,28,1,5,1
559,16,29,1,12,1
559,16,43,1,1,1
559,16,46,4,0,
559,16,67,1,1,2
559,16,67,3,0,
559,16,68,2,0,
559,16,70,4,0,
559,16,91,4,0,
559,16,92,4,0,
559,16,104,4,0,
559,16,133,2,0,
559,16,136,1,31,1
559,16,156,4,0,
559,16,157,4,0,
559,16,162,3,0,
559,16,164,4,0,
559,16,173,3,0,
559,16,180,3,0,
559,16,182,4,0,
559,16,184,1,34,1
559,16,185,1,9,1
559,16,185,2,0,
559,16,188,4,0,
559,16,197,2,0,
559,16,207,1,16,1
559,16,207,4,0,
559,16,213,4,0,
559,16,214,4,0,
559,16,216,4,0,
559,16,218,4,0,
559,16,231,3,0,
559,16,237,4,0,
559,16,240,4,0,
559,16,241,4,0,
559,16,242,1,38,1
559,16,249,4,0,
559,16,252,2,0,
559,16,259,4,0,
559,16,263,1,42,1
559,16,263,4,0,
559,16,264,1,48,1
559,16,264,3,0,
559,16,269,4,0,
559,16,280,1,20,1
559,16,280,4,0,
559,16,282,3,0,
559,16,289,3,0,
559,16,290,4,0,
559,16,317,4,0,
559,16,334,3,0,
559,16,337,4,0,
559,16,339,4,0,
559,16,349,2,0,
559,16,371,1,23,1
559,16,371,4,0,
559,16,374,4,0,
559,16,398,4,0,
559,16,399,4,0,
559,16,406,3,0,
559,16,409,2,0,
559,16,409,3,0,
559,16,411,4,0,
559,16,428,2,0,
559,16,428,3,0,
559,16,431,1,45,1
559,16,442,3,0,
559,16,444,4,0,
559,16,447,4,0,
559,16,457,1,50,1
559,16,479,4,0,
559,16,490,4,0,
559,16,492,3,0,
559,16,496,4,0,
559,16,498,1,27,1
559,16,501,2,0,
559,16,510,4,0,
559,16,514,4,0,
559,16,525,4,0,
559,16,530,3,0,
559,16,555,4,0,
559,16,590,4,0,
559,16,612,4,0,
559,17,7,2,0,
559,17,8,2,0,
559,17,9,2,0,
559,17,28,1,5,
559,17,29,1,1,2
559,17,43,1,1,1
559,17,46,4,0,
559,17,67,1,16,
559,17,68,2,0,
559,17,92,4,0,
559,17,104,4,0,
559,17,133,2,0,
559,17,136,1,31,
559,17,156,4,0,
559,17,157,4,0,
559,17,164,4,0,
559,17,182,4,0,
559,17,184,1,34,
559,17,185,1,9,
559,17,185,2,0,
559,17,188,4,0,
559,17,197,2,0,
559,17,207,1,12,
559,17,207,4,0,
559,17,213,4,0,
559,17,214,4,0,
559,17,216,4,0,
559,17,218,4,0,
559,17,237,4,0,
559,17,240,4,0,
559,17,241,4,0,
559,17,242,1,38,
559,17,252,2,0,
559,17,259,4,0,
559,17,263,1,42,
559,17,263,4,0,
559,17,264,1,48,
559,17,269,4,0,
559,17,280,1,23,
559,17,280,4,0,
559,17,317,4,0,
559,17,337,4,0,
559,17,339,4,0,
559,17,349,2,0,
559,17,371,1,20,
559,17,371,4,0,
559,17,374,4,0,
559,17,398,4,0,
559,17,399,4,0,
559,17,409,2,0,
559,17,411,4,0,
559,17,428,2,0,
559,17,431,1,45,
559,17,444,4,0,
559,17,447,4,0,
559,17,457,1,50,
559,17,479,4,0,
559,17,490,4,0,
559,17,496,4,0,
559,17,498,1,27,
559,17,501,2,0,
559,17,525,4,0,
559,17,526,4,0,
559,17,555,4,0,
559,17,590,4,0,
559,18,7,2,0,
559,18,8,2,0,
559,18,9,2,0,
559,18,28,1,5,
559,18,29,1,1,2
559,18,43,1,1,1
559,18,46,4,0,
559,18,67,1,16,
559,18,68,2,0,
559,18,92,4,0,
559,18,104,4,0,
559,18,133,2,0,
559,18,136,1,31,
559,18,156,4,0,
559,18,157,4,0,
559,18,164,4,0,
559,18,182,4,0,
559,18,184,1,34,
559,18,185,1,9,
559,18,185,2,0,
559,18,188,4,0,
559,18,197,2,0,
559,18,207,1,12,
559,18,207,4,0,
559,18,213,4,0,
559,18,214,4,0,
559,18,216,4,0,
559,18,218,4,0,
559,18,237,4,0,
559,18,240,4,0,
559,18,241,4,0,
559,18,242,1,38,
559,18,252,2,0,
559,18,259,4,0,
559,18,263,1,42,
559,18,263,4,0,
559,18,264,1,48,
559,18,269,4,0,
559,18,280,1,23,
559,18,280,4,0,
559,18,317,4,0,
559,18,337,4,0,
559,18,339,4,0,
559,18,349,2,0,
559,18,371,1,20,
559,18,371,4,0,
559,18,374,4,0,
559,18,398,4,0,
559,18,399,4,0,
559,18,409,2,0,
559,18,411,4,0,
559,18,428,2,0,
559,18,431,1,45,
559,18,444,4,0,
559,18,447,4,0,
559,18,457,1,50,
559,18,479,4,0,
559,18,490,4,0,
559,18,491,2,0,
559,18,496,4,0,
559,18,498,1,27,
559,18,501,2,0,
559,18,525,4,0,
559,18,526,4,0,
559,18,555,4,0,
559,18,590,4,0,
559,18,612,2,0,
560,11,28,1,1,3
560,11,28,1,5,
560,11,29,1,12,
560,11,43,1,1,1
560,11,46,4,0,
560,11,63,4,0,
560,11,67,1,1,2
560,11,70,4,0,
560,11,91,4,0,
560,11,92,4,0,
560,11,104,4,0,
560,11,136,1,31,
560,11,156,4,0,
560,11,157,4,0,
560,11,164,4,0,
560,11,168,4,0,
560,11,182,4,0,
560,11,184,1,34,
560,11,185,1,1,4
560,11,185,1,9,
560,11,188,4,0,
560,11,207,1,16,
560,11,207,4,0,
560,11,213,4,0,
560,11,216,4,0,
560,11,218,4,0,
560,11,237,4,0,
560,11,240,4,0,
560,11,241,4,0,
560,11,242,1,38,
560,11,249,4,0,
560,11,259,4,0,
560,11,263,1,45,
560,11,263,4,0,
560,11,264,1,58,
560,11,269,4,0,
560,11,280,1,20,
560,11,280,4,0,
560,11,317,4,0,
560,11,337,4,0,
560,11,339,4,0,
560,11,371,1,23,
560,11,371,4,0,
560,11,374,4,0,
560,11,398,4,0,
560,11,411,4,0,
560,11,416,4,0,
560,11,431,1,51,
560,11,444,4,0,
560,11,447,4,0,
560,11,457,1,65,
560,11,479,4,0,
560,11,490,4,0,
560,11,496,4,0,
560,11,498,1,27,
560,11,510,4,0,
560,11,514,4,0,
560,11,525,4,0,
560,11,526,4,0,
560,11,555,4,0,
560,14,7,3,0,
560,14,8,3,0,
560,14,9,3,0,
560,14,28,1,1,3
560,14,28,1,5,
560,14,29,1,12,
560,14,43,1,1,1
560,14,46,4,0,
560,14,63,4,0,
560,14,67,1,1,2
560,14,67,3,0,
560,14,70,4,0,
560,14,91,4,0,
560,14,92,4,0,
560,14,104,4,0,
560,14,136,1,31,
560,14,156,4,0,
560,14,157,4,0,
560,14,162,3,0,
560,14,164,4,0,
560,14,168,4,0,
560,14,173,3,0,
560,14,180,3,0,
560,14,182,4,0,
560,14,184,1,34,
560,14,185,1,1,4
560,14,185,1,9,
560,14,188,4,0,
560,14,200,3,0,
560,14,207,1,16,
560,14,207,4,0,
560,14,213,4,0,
560,14,214,3,0,
560,14,216,4,0,
560,14,218,4,0,
560,14,231,3,0,
560,14,237,4,0,
560,14,240,4,0,
560,14,241,4,0,
560,14,242,1,38,
560,14,249,4,0,
560,14,259,4,0,
560,14,263,1,45,
560,14,263,4,0,
560,14,264,1,58,
560,14,269,4,0,
560,14,280,1,20,
560,14,280,4,0,
560,14,282,3,0,
560,14,289,3,0,
560,14,317,4,0,
560,14,334,3,0,
560,14,337,4,0,
560,14,339,4,0,
560,14,371,1,23,
560,14,371,4,0,
560,14,374,4,0,
560,14,398,4,0,
560,14,399,3,0,
560,14,406,3,0,
560,14,409,3,0,
560,14,411,4,0,
560,14,416,4,0,
560,14,428,3,0,
560,14,431,1,51,
560,14,442,3,0,
560,14,444,4,0,
560,14,447,4,0,
560,14,457,1,65,
560,14,479,4,0,
560,14,490,4,0,
560,14,492,3,0,
560,14,496,4,0,
560,14,498,1,27,
560,14,510,4,0,
560,14,514,4,0,
560,14,525,4,0,
560,14,526,4,0,
560,14,530,3,0,
560,14,555,4,0,
560,15,28,1,1,3
560,15,28,1,5,
560,15,29,1,12,
560,15,43,1,1,1
560,15,46,4,0,
560,15,63,4,0,
560,15,67,1,1,2
560,15,70,4,0,
560,15,91,4,0,
560,15,92,4,0,
560,15,104,4,0,
560,15,136,1,31,
560,15,156,4,0,
560,15,157,4,0,
560,15,164,4,0,
560,15,168,4,0,
560,15,182,4,0,
560,15,184,1,34,
560,15,185,1,1,4
560,15,185,1,9,
560,15,188,4,0,
560,15,207,1,16,
560,15,207,4,0,
560,15,213,4,0,
560,15,214,4,0,
560,15,216,4,0,
560,15,218,4,0,
560,15,237,4,0,
560,15,240,4,0,
560,15,241,4,0,
560,15,242,1,38,
560,15,249,4,0,
560,15,259,4,0,
560,15,263,1,45,
560,15,263,4,0,
560,15,264,1,58,
560,15,269,4,0,
560,15,280,1,20,
560,15,280,4,0,
560,15,317,4,0,
560,15,337,4,0,
560,15,339,4,0,
560,15,371,1,23,
560,15,371,4,0,
560,15,374,4,0,
560,15,398,4,0,
560,15,399,4,0,
560,15,411,4,0,
560,15,416,4,0,
560,15,431,1,51,
560,15,444,4,0,
560,15,447,4,0,
560,15,457,1,65,
560,15,479,4,0,
560,15,490,4,0,
560,15,496,4,0,
560,15,498,1,27,
560,15,510,4,0,
560,15,514,4,0,
560,15,525,4,0,
560,15,555,4,0,
560,15,590,4,0,
560,15,612,4,0,
560,16,7,3,0,
560,16,8,3,0,
560,16,9,3,0,
560,16,28,1,1,3
560,16,28,1,5,1
560,16,29,1,12,1
560,16,43,1,1,1
560,16,46,4,0,
560,16,63,4,0,
560,16,67,1,1,2
560,16,67,3,0,
560,16,70,4,0,
560,16,91,4,0,
560,16,92,4,0,
560,16,104,4,0,
560,16,136,1,31,1
560,16,156,4,0,
560,16,157,4,0,
560,16,162,3,0,
560,16,164,4,0,
560,16,168,4,0,
560,16,173,3,0,
560,16,180,3,0,
560,16,182,4,0,
560,16,184,1,34,1
560,16,185,1,1,4
560,16,185,1,9,1
560,16,188,4,0,
560,16,200,3,0,
560,16,207,1,16,1
560,16,207,4,0,
560,16,213,4,0,
560,16,214,4,0,
560,16,216,4,0,
560,16,218,4,0,
560,16,231,3,0,
560,16,237,4,0,
560,16,240,4,0,
560,16,241,4,0,
560,16,242,1,38,1
560,16,249,4,0,
560,16,259,4,0,
560,16,263,1,45,1
560,16,263,4,0,
560,16,264,1,58,1
560,16,264,3,0,
560,16,269,4,0,
560,16,280,1,20,1
560,16,280,4,0,
560,16,282,3,0,
560,16,289,3,0,
560,16,290,4,0,
560,16,317,4,0,
560,16,334,3,0,
560,16,337,4,0,
560,16,339,4,0,
560,16,371,1,23,1
560,16,371,4,0,
560,16,374,4,0,
560,16,398,4,0,
560,16,399,4,0,
560,16,406,3,0,
560,16,409,3,0,
560,16,411,4,0,
560,16,416,4,0,
560,16,428,3,0,
560,16,431,1,51,1
560,16,442,3,0,
560,16,444,4,0,
560,16,447,4,0,
560,16,457,1,65,1
560,16,479,4,0,
560,16,490,4,0,
560,16,492,3,0,
560,16,496,4,0,
560,16,498,1,27,1
560,16,510,4,0,
560,16,514,4,0,
560,16,525,4,0,
560,16,530,3,0,
560,16,555,4,0,
560,16,590,4,0,
560,16,612,4,0,
560,17,28,1,1,3
560,17,28,1,5,
560,17,29,1,1,2
560,17,43,1,1,1
560,17,46,4,0,
560,17,63,4,0,
560,17,67,1,16,
560,17,92,4,0,
560,17,104,4,0,
560,17,136,1,31,
560,17,156,4,0,
560,17,157,4,0,
560,17,164,4,0,
560,17,168,4,0,
560,17,182,4,0,
560,17,184,1,34,
560,17,185,1,1,4
560,17,185,1,9,
560,17,188,4,0,
560,17,207,1,12,
560,17,207,4,0,
560,17,213,4,0,
560,17,214,4,0,
560,17,216,4,0,
560,17,218,4,0,
560,17,237,4,0,
560,17,240,4,0,
560,17,241,4,0,
560,17,242,1,38,
560,17,259,4,0,
560,17,263,1,45,
560,17,263,4,0,
560,17,264,1,58,
560,17,269,4,0,
560,17,280,1,23,
560,17,280,4,0,
560,17,317,4,0,
560,17,337,4,0,
560,17,339,4,0,
560,17,371,1,20,
560,17,371,4,0,
560,17,374,4,0,
560,17,398,4,0,
560,17,399,4,0,
560,17,411,4,0,
560,17,416,4,0,
560,17,431,1,51,
560,17,444,4,0,
560,17,447,4,0,
560,17,457,1,65,
560,17,479,4,0,
560,17,490,4,0,
560,17,496,4,0,
560,17,498,1,27,
560,17,525,4,0,
560,17,526,4,0,
560,17,555,4,0,
560,17,590,4,0,
560,18,28,1,1,3
560,18,28,1,5,
560,18,29,1,1,2
560,18,43,1,1,1
560,18,46,4,0,
560,18,63,4,0,
560,18,67,1,16,
560,18,92,4,0,
560,18,104,4,0,
560,18,136,1,31,
560,18,156,4,0,
560,18,157,4,0,
560,18,164,4,0,
560,18,168,4,0,
560,18,182,4,0,
560,18,184,1,34,
560,18,185,1,1,4
560,18,185,1,9,
560,18,188,4,0,
560,18,207,1,12,
560,18,207,4,0,
560,18,213,4,0,
560,18,214,4,0,
560,18,216,4,0,
560,18,218,4,0,
560,18,237,4,0,
560,18,240,4,0,
560,18,241,4,0,
560,18,242,1,38,
560,18,259,4,0,
560,18,263,1,45,
560,18,263,4,0,
560,18,264,1,58,
560,18,269,4,0,
560,18,280,1,23,
560,18,280,4,0,
560,18,317,4,0,
560,18,337,4,0,
560,18,339,4,0,
560,18,371,1,20,
560,18,371,4,0,
560,18,374,4,0,
560,18,398,4,0,
560,18,399,4,0,
560,18,411,4,0,
560,18,416,4,0,
560,18,431,1,51,
560,18,444,4,0,
560,18,447,4,0,
560,18,457,1,65,
560,18,479,4,0,
560,18,490,4,0,
560,18,496,4,0,
560,18,498,1,27,
560,18,525,4,0,
560,18,526,4,0,
560,18,555,4,0,
560,18,590,4,0,
561,11,16,1,1,1
561,11,18,1,14,
561,11,19,4,0,
561,11,58,4,0,
561,11,60,1,18,
561,11,63,4,0,
561,11,76,4,0,
561,11,86,4,0,
561,11,92,4,0,
561,11,94,1,44,
561,11,94,4,0,
561,11,95,1,4,
561,11,104,4,0,
561,11,113,1,24,
561,11,113,4,0,
561,11,115,1,28,
561,11,115,4,0,
561,11,119,1,34,
561,11,138,4,0,
561,11,143,1,51,
561,11,148,4,0,
561,11,149,1,8,
561,11,156,4,0,
561,11,164,4,0,
561,11,168,4,0,
561,11,182,4,0,
561,11,207,4,0,
561,11,211,2,0,
561,11,213,4,0,
561,11,216,4,0,
561,11,218,4,0,
561,11,219,4,0,
561,11,237,4,0,
561,11,240,4,0,
561,11,244,4,0,
561,11,246,2,0,
561,11,247,4,0,
561,11,263,4,0,
561,11,285,2,0,
561,11,314,1,21,
561,11,322,1,48,
561,11,332,4,0,
561,11,347,4,0,
561,11,355,2,0,
561,11,356,1,38,
561,11,357,1,1,2
561,11,365,4,0,
561,11,366,1,11,
561,11,375,2,0,
561,11,403,1,41,
561,11,412,4,0,
561,11,430,4,0,
561,11,433,4,0,
561,11,451,4,0,
561,11,473,4,0,
561,11,477,4,0,
561,11,479,4,0,
561,11,485,1,31,
561,11,496,4,0,
561,11,500,2,0,
561,14,16,1,1,1
561,14,18,1,14,
561,14,19,4,0,
561,14,58,4,0,
561,14,60,1,18,
561,14,63,4,0,
561,14,76,4,0,
561,14,86,4,0,
561,14,92,4,0,
561,14,94,1,44,
561,14,94,4,0,
561,14,95,1,4,
561,14,104,4,0,
561,14,113,1,24,
561,14,113,4,0,
561,14,115,1,28,
561,14,115,4,0,
561,14,119,1,34,
561,14,138,4,0,
561,14,143,1,51,
561,14,143,3,0,
561,14,148,4,0,
561,14,149,1,8,
561,14,156,4,0,
561,14,164,4,0,
561,14,168,4,0,
561,14,173,3,0,
561,14,182,4,0,
561,14,196,3,0,
561,14,207,4,0,
561,14,211,2,0,
561,14,213,4,0,
561,14,214,3,0,
561,14,216,4,0,
561,14,218,4,0,
561,14,219,4,0,
561,14,237,4,0,
561,14,240,4,0,
561,14,244,4,0,
561,14,246,2,0,
561,14,247,4,0,
561,14,257,3,0,
561,14,263,4,0,
561,14,271,3,0,
561,14,277,3,0,
561,14,285,2,0,
561,14,285,3,0,
561,14,314,1,21,
561,14,322,1,48,
561,14,324,3,0,
561,14,332,4,0,
561,14,347,4,0,
561,14,355,2,0,
561,14,355,3,0,
561,14,356,1,38,
561,14,356,3,0,
561,14,357,1,1,2
561,14,365,4,0,
561,14,366,1,11,
561,14,366,3,0,
561,14,375,2,0,
561,14,399,3,0,
561,14,403,1,41,
561,14,412,4,0,
561,14,428,3,0,
561,14,430,4,0,
561,14,433,4,0,
561,14,451,4,0,
561,14,473,4,0,
561,14,477,4,0,
561,14,478,3,0,
561,14,479,4,0,
561,14,485,1,31,
561,14,496,4,0,
561,14,500,2,0,
561,15,16,1,1,1
561,15,18,1,14,
561,15,19,4,0,
561,15,58,4,0,
561,15,60,1,18,
561,15,63,4,0,
561,15,76,4,0,
561,15,86,4,0,
561,15,92,4,0,
561,15,94,1,44,
561,15,94,4,0,
561,15,95,1,4,
561,15,104,4,0,
561,15,113,1,24,
561,15,113,4,0,
561,15,115,1,28,
561,15,115,4,0,
561,15,119,1,34,
561,15,138,4,0,
561,15,143,1,50,
561,15,148,4,0,
561,15,149,1,8,
561,15,156,4,0,
561,15,164,4,0,
561,15,168,4,0,
561,15,182,4,0,
561,15,207,4,0,
561,15,211,2,0,
561,15,211,4,0,
561,15,213,4,0,
561,15,214,4,0,
561,15,216,4,0,
561,15,218,4,0,
561,15,219,4,0,
561,15,237,4,0,
561,15,240,4,0,
561,15,244,4,0,
561,15,246,2,0,
561,15,247,4,0,
561,15,248,2,0,
561,15,263,4,0,
561,15,285,2,0,
561,15,314,1,21,
561,15,322,1,48,
561,15,332,4,0,
561,15,347,4,0,
561,15,355,2,0,
561,15,355,4,0,
561,15,356,1,38,
561,15,357,1,1,2
561,15,366,1,11,
561,15,375,2,0,
561,15,399,4,0,
561,15,403,1,41,
561,15,412,4,0,
561,15,430,4,0,
561,15,433,4,0,
561,15,451,4,0,
561,15,473,4,0,
561,15,479,4,0,
561,15,485,1,31,
561,15,496,4,0,
561,15,500,2,0,
561,15,590,4,0,
561,15,605,4,0,
561,16,16,1,1,1
561,16,18,1,14,1
561,16,19,4,0,
561,16,58,4,0,
561,16,60,1,18,1
561,16,63,4,0,
561,16,76,4,0,
561,16,86,4,0,
561,16,92,4,0,
561,16,94,1,44,1
561,16,94,4,0,
561,16,95,1,4,1
561,16,104,4,0,
561,16,113,1,24,1
561,16,113,4,0,
561,16,115,1,28,1
561,16,115,4,0,
561,16,119,1,34,1
561,16,138,4,0,
561,16,143,1,50,1
561,16,143,3,0,
561,16,148,4,0,
561,16,149,1,8,1
561,16,156,4,0,
561,16,164,4,0,
561,16,168,4,0,
561,16,173,3,0,
561,16,182,4,0,
561,16,196,3,0,
561,16,207,4,0,
561,16,211,2,0,
561,16,211,4,0,
561,16,213,4,0,
561,16,214,4,0,
561,16,216,4,0,
561,16,218,4,0,
561,16,219,4,0,
561,16,237,4,0,
561,16,240,4,0,
561,16,244,4,0,
561,16,246,2,0,
561,16,247,4,0,
561,16,248,2,0,
561,16,257,3,0,
561,16,263,4,0,
561,16,271,3,0,
561,16,277,3,0,
561,16,285,2,0,
561,16,285,3,0,
561,16,290,4,0,
561,16,314,1,21,1
561,16,322,1,48,1
561,16,324,3,0,
561,16,332,4,0,
561,16,347,4,0,
561,16,351,3,0,
561,16,355,2,0,
561,16,355,4,0,
561,16,356,1,38,1
561,16,356,3,0,
561,16,357,1,1,2
561,16,366,1,11,1
561,16,366,3,0,
561,16,375,2,0,
561,16,399,4,0,
561,16,403,1,41,1
561,16,412,4,0,
561,16,428,3,0,
561,16,430,4,0,
561,16,433,4,0,
561,16,451,4,0,
561,16,473,4,0,
561,16,478,3,0,
561,16,479,4,0,
561,16,485,1,31,1
561,16,496,4,0,
561,16,500,2,0,
561,16,590,4,0,
561,16,605,4,0,
561,17,16,1,1,1
561,17,18,1,14,
561,17,19,4,0,
561,17,58,4,0,
561,17,60,1,18,
561,17,63,4,0,
561,17,76,4,0,
561,17,86,4,0,
561,17,92,4,0,
561,17,94,1,44,
561,17,94,4,0,
561,17,95,1,4,
561,17,104,4,0,
561,17,113,1,24,
561,17,113,4,0,
561,17,115,1,28,
561,17,115,4,0,
561,17,119,1,34,
561,17,138,4,0,
561,17,143,1,50,
561,17,149,1,8,
561,17,156,4,0,
561,17,164,4,0,
561,17,168,4,0,
561,17,182,4,0,
561,17,207,4,0,
561,17,211,2,0,
561,17,211,4,0,
561,17,213,4,0,
561,17,214,4,0,
561,17,216,4,0,
561,17,218,4,0,
561,17,219,4,0,
561,17,237,4,0,
561,17,240,4,0,
561,17,244,4,0,
561,17,246,2,0,
561,17,247,4,0,
561,17,248,2,0,
561,17,263,4,0,
561,17,285,2,0,
561,17,314,1,21,
561,17,322,1,48,
561,17,332,4,0,
561,17,347,4,0,
561,17,355,2,0,
561,17,355,4,0,
561,17,356,1,38,
561,17,357,1,1,2
561,17,366,1,11,
561,17,375,2,0,
561,17,399,4,0,
561,17,403,1,41,
561,17,412,4,0,
561,17,430,4,0,
561,17,433,4,0,
561,17,451,4,0,
561,17,473,4,0,
561,17,479,4,0,
561,17,485,1,31,
561,17,496,4,0,
561,17,500,2,0,
561,17,590,4,0,
561,17,605,4,0,
561,18,16,1,1,1
561,18,18,1,14,
561,18,19,4,0,
561,18,58,4,0,
561,18,60,1,18,
561,18,63,4,0,
561,18,76,4,0,
561,18,86,4,0,
561,18,92,4,0,
561,18,94,1,44,
561,18,94,4,0,
561,18,95,1,4,
561,18,104,4,0,
561,18,113,1,24,
561,18,113,4,0,
561,18,115,1,28,
561,18,115,4,0,
561,18,119,1,34,
561,18,138,4,0,
561,18,143,1,50,
561,18,149,1,8,
561,18,156,4,0,
561,18,164,4,0,
561,18,168,4,0,
561,18,182,4,0,
561,18,207,4,0,
561,18,211,2,0,
561,18,211,4,0,
561,18,213,4,0,
561,18,214,4,0,
561,18,216,4,0,
561,18,218,4,0,
561,18,219,4,0,
561,18,237,4,0,
561,18,240,4,0,
561,18,244,4,0,
561,18,246,2,0,
561,18,247,4,0,
561,18,248,2,0,
561,18,263,4,0,
561,18,285,2,0,
561,18,314,1,21,
561,18,322,1,48,
561,18,332,4,0,
561,18,347,4,0,
561,18,355,2,0,
561,18,355,4,0,
561,18,356,1,38,
561,18,357,1,1,2
561,18,366,1,11,
561,18,375,2,0,
561,18,399,4,0,
561,18,403,1,41,
561,18,412,4,0,
561,18,430,4,0,
561,18,433,4,0,
561,18,451,4,0,
561,18,473,4,0,
561,18,479,4,0,
561,18,485,1,31,
561,18,496,4,0,
561,18,500,2,0,
561,18,590,4,0,
561,18,605,4,0,
562,11,50,1,5,
562,11,50,2,0,
562,11,92,4,0,
562,11,94,4,0,
562,11,101,1,13,
562,11,104,4,0,
562,11,114,1,9,
562,11,138,4,0,
562,11,148,4,0,
562,11,156,4,0,
562,11,164,4,0,
562,11,168,4,0,
562,11,171,2,0,
562,11,174,1,29,
562,11,182,1,1,2
562,11,182,4,0,
562,11,194,1,49,
562,11,203,2,0,
562,11,207,4,0,
562,11,212,1,45,
562,11,213,4,0,
562,11,216,4,0,
562,11,218,4,0,
562,11,219,4,0,
562,11,237,4,0,
562,11,240,4,0,
562,11,244,4,0,
562,11,247,1,37,
562,11,247,4,0,
562,11,261,1,21,
562,11,261,4,0,
562,11,262,2,0,
562,11,263,4,0,
562,11,286,2,0,
562,11,288,1,41,
562,11,310,1,1,1
562,11,313,2,0,
562,11,347,4,0,
562,11,371,4,0,
562,11,373,4,0,
562,11,377,2,0,
562,11,412,4,0,
562,11,417,2,0,
562,11,433,4,0,
562,11,466,1,25,
562,11,470,1,33,2
562,11,471,1,33,1
562,11,477,4,0,
562,11,496,4,0,
562,11,506,1,17,
562,14,50,1,5,
562,14,50,2,0,
562,14,92,4,0,
562,14,94,4,0,
562,14,101,1,13,
562,14,104,4,0,
562,14,114,1,9,
562,14,138,4,0,
562,14,148,4,0,
562,14,156,4,0,
562,14,164,4,0,
562,14,168,4,0,
562,14,171,2,0,
562,14,173,3,0,
562,14,174,1,29,
562,14,180,3,0,
562,14,182,1,1,2
562,14,182,4,0,
562,14,194,1,49,
562,14,203,2,0,
562,14,207,4,0,
562,14,212,1,45,
562,14,213,4,0,
562,14,214,3,0,
562,14,216,4,0,
562,14,218,4,0,
562,14,219,4,0,
562,14,220,3,0,
562,14,237,4,0,
562,14,240,4,0,
562,14,244,4,0,
562,14,247,1,37,
562,14,247,4,0,
562,14,261,1,21,
562,14,261,4,0,
562,14,262,2,0,
562,14,263,4,0,
562,14,271,3,0,
562,14,272,3,0,
562,14,277,3,0,
562,14,282,3,0,
562,14,285,3,0,
562,14,286,2,0,
562,14,288,1,41,
562,14,289,3,0,
562,14,310,1,1,1
562,14,313,2,0,
562,14,334,3,0,
562,14,335,3,0,
562,14,347,4,0,
562,14,371,4,0,
562,14,373,4,0,
562,14,377,2,0,
562,14,399,3,0,
562,14,412,4,0,
562,14,417,2,0,
562,14,433,4,0,
562,14,466,1,25,
562,14,470,1,33,2
562,14,471,1,33,1
562,14,472,3,0,
562,14,477,4,0,
562,14,495,3,0,
562,14,496,4,0,
562,14,506,1,17,
562,15,50,1,5,
562,15,50,2,0,
562,15,92,4,0,
562,15,94,4,0,
562,15,101,1,13,
562,15,104,4,0,
562,15,114,1,9,
562,15,138,4,0,
562,15,148,4,0,
562,15,156,4,0,
562,15,164,4,0,
562,15,168,4,0,
562,15,171,2,0,
562,15,174,1,29,
562,15,182,1,1,2
562,15,182,4,0,
562,15,194,1,49,
562,15,203,2,0,
562,15,207,4,0,
562,15,212,1,45,
562,15,213,4,0,
562,15,214,4,0,
562,15,216,4,0,
562,15,218,4,0,
562,15,219,4,0,
562,15,237,4,0,
562,15,240,4,0,
562,15,244,4,0,
562,15,247,1,37,
562,15,247,4,0,
562,15,261,1,21,
562,15,261,4,0,
562,15,262,2,0,
562,15,263,4,0,
562,15,286,2,0,
562,15,288,1,41,
562,15,310,1,1,1
562,15,313,2,0,
562,15,347,4,0,
562,15,371,4,0,
562,15,373,4,0,
562,15,377,2,0,
562,15,390,2,0,
562,15,399,4,0,
562,15,412,4,0,
562,15,417,2,0,
562,15,433,4,0,
562,15,466,1,25,
562,15,470,1,33,2
562,15,471,1,33,1
562,15,496,4,0,
562,15,502,2,0,
562,15,506,1,17,
562,15,590,4,0,
562,15,611,4,0,
562,16,50,1,5,1
562,16,50,2,0,
562,16,92,4,0,
562,16,94,4,0,
562,16,101,1,13,1
562,16,104,4,0,
562,16,114,1,9,1
562,16,138,4,0,
562,16,148,4,0,
562,16,156,4,0,
562,16,164,4,0,
562,16,168,4,0,
562,16,171,2,0,
562,16,173,3,0,
562,16,174,1,29,1
562,16,180,3,0,
562,16,182,1,1,2
562,16,182,4,0,
562,16,194,1,49,1
562,16,203,2,0,
562,16,207,4,0,
562,16,212,1,45,1
562,16,213,4,0,
562,16,214,4,0,
562,16,216,4,0,
562,16,218,4,0,
562,16,219,4,0,
562,16,220,3,0,
562,16,237,4,0,
562,16,240,4,0,
562,16,244,4,0,
562,16,247,1,37,1
562,16,247,4,0,
562,16,261,1,21,1
562,16,261,4,0,
562,16,262,2,0,
562,16,263,4,0,
562,16,271,3,0,
562,16,272,3,0,
562,16,277,3,0,
562,16,282,3,0,
562,16,285,3,0,
562,16,286,2,0,
562,16,288,1,41,1
562,16,289,3,0,
562,16,290,4,0,
562,16,310,1,1,1
562,16,313,2,0,
562,16,334,3,0,
562,16,335,3,0,
562,16,347,4,0,
562,16,351,3,0,
562,16,371,4,0,
562,16,373,4,0,
562,16,377,2,0,
562,16,390,2,0,
562,16,399,4,0,
562,16,412,4,0,
562,16,417,2,0,
562,16,428,3,0,
562,16,433,4,0,
562,16,466,1,25,1
562,16,470,1,33,2
562,16,471,1,33,1
562,16,472,3,0,
562,16,495,3,0,
562,16,496,4,0,
562,16,502,2,0,
562,16,506,1,17,1
562,16,590,4,0,
562,16,611,4,0,
562,17,50,1,5,
562,17,50,2,0,
562,17,92,4,0,
562,17,94,4,0,
562,17,101,1,13,
562,17,104,4,0,
562,17,114,1,9,
562,17,138,4,0,
562,17,156,4,0,
562,17,164,4,0,
562,17,168,4,0,
562,17,171,2,0,
562,17,174,1,29,
562,17,182,1,1,2
562,17,182,4,0,
562,17,194,1,49,
562,17,203,2,0,
562,17,207,4,0,
562,17,212,1,45,
562,17,213,4,0,
562,17,214,4,0,
562,17,216,4,0,
562,17,218,4,0,
562,17,219,4,0,
562,17,237,4,0,
562,17,240,4,0,
562,17,244,4,0,
562,17,247,1,37,
562,17,247,4,0,
562,17,261,1,21,
562,17,261,4,0,
562,17,262,2,0,
562,17,263,4,0,
562,17,286,2,0,
562,17,288,1,41,
562,17,310,1,1,1
562,17,313,2,0,
562,17,347,4,0,
562,17,371,4,0,
562,17,373,4,0,
562,17,377,2,0,
562,17,390,2,0,
562,17,399,4,0,
562,17,412,4,0,
562,17,417,2,0,
562,17,433,4,0,
562,17,466,1,25,
562,17,470,1,33,2
562,17,471,1,33,1
562,17,496,4,0,
562,17,502,2,0,
562,17,506,1,17,
562,17,590,4,0,
562,17,611,4,0,
562,18,50,1,5,
562,18,50,2,0,
562,18,92,4,0,
562,18,94,4,0,
562,18,101,1,13,
562,18,104,4,0,
562,18,114,1,9,
562,18,138,4,0,
562,18,156,4,0,
562,18,164,4,0,
562,18,168,4,0,
562,18,171,2,0,
562,18,174,1,29,
562,18,182,1,1,2
562,18,182,4,0,
562,18,194,1,49,
562,18,203,2,0,
562,18,207,4,0,
562,18,212,1,45,
562,18,213,4,0,
562,18,214,4,0,
562,18,216,4,0,
562,18,218,4,0,
562,18,219,4,0,
562,18,237,4,0,
562,18,240,4,0,
562,18,244,4,0,
562,18,247,1,37,
562,18,247,4,0,
562,18,261,1,21,
562,18,261,4,0,
562,18,262,2,0,
562,18,263,4,0,
562,18,286,2,0,
562,18,288,1,41,
562,18,310,1,1,1
562,18,313,2,0,
562,18,347,4,0,
562,18,371,4,0,
562,18,373,4,0,
562,18,377,2,0,
562,18,390,2,0,
562,18,399,4,0,
562,18,412,4,0,
562,18,417,2,0,
562,18,433,4,0,
562,18,466,1,25,
562,18,470,1,33,2
562,18,471,1,33,1
562,18,496,4,0,
562,18,502,2,0,
562,18,506,1,17,
562,18,578,2,0,
562,18,590,4,0,
562,18,611,4,0,
563,11,50,1,1,3
563,11,50,1,5,
563,11,63,4,0,
563,11,92,4,0,
563,11,94,4,0,
563,11,101,1,13,
563,11,104,4,0,
563,11,114,1,1,4
563,11,114,1,9,
563,11,138,4,0,
563,11,148,4,0,
563,11,156,4,0,
563,11,164,4,0,
563,11,168,4,0,
563,11,174,1,29,
563,11,182,1,1,2
563,11,182,4,0,
563,11,184,1,34,
563,11,194,1,57,
563,11,207,4,0,
563,11,212,1,51,
563,11,213,4,0,
563,11,216,4,0,
563,11,218,4,0,
563,11,219,4,0,
563,11,237,4,0,
563,11,240,4,0,
563,11,244,4,0,
563,11,247,1,39,
563,11,247,4,0,
563,11,261,1,21,
563,11,261,4,0,
563,11,263,4,0,
563,11,288,1,45,
563,11,310,1,1,1
563,11,347,4,0,
563,11,371,4,0,
563,11,373,4,0,
563,11,412,4,0,
563,11,416,4,0,
563,11,433,4,0,
563,11,447,4,0,
563,11,466,1,25,
563,11,470,1,33,2
563,11,471,1,33,1
563,11,477,4,0,
563,11,496,4,0,
563,11,506,1,17,
563,14,50,1,1,3
563,14,50,1,5,
563,14,63,4,0,
563,14,92,4,0,
563,14,94,4,0,
563,14,101,1,13,
563,14,104,4,0,
563,14,114,1,1,4
563,14,114,1,9,
563,14,138,4,0,
563,14,148,4,0,
563,14,156,4,0,
563,14,164,4,0,
563,14,168,4,0,
563,14,173,3,0,
563,14,174,1,29,
563,14,180,3,0,
563,14,182,1,1,2
563,14,182,4,0,
563,14,184,1,34,
563,14,194,1,57,
563,14,207,4,0,
563,14,212,1,51,
563,14,213,4,0,
563,14,214,3,0,
563,14,216,4,0,
563,14,218,4,0,
563,14,219,4,0,
563,14,220,3,0,
563,14,237,4,0,
563,14,240,4,0,
563,14,244,4,0,
563,14,247,1,39,
563,14,247,4,0,
563,14,261,1,21,
563,14,261,4,0,
563,14,263,4,0,
563,14,271,3,0,
563,14,272,3,0,
563,14,277,3,0,
563,14,282,3,0,
563,14,285,3,0,
563,14,288,1,45,
563,14,289,3,0,
563,14,310,1,1,1
563,14,334,3,0,
563,14,335,3,0,
563,14,347,4,0,
563,14,371,4,0,
563,14,373,4,0,
563,14,399,3,0,
563,14,412,4,0,
563,14,416,4,0,
563,14,433,4,0,
563,14,447,4,0,
563,14,466,1,25,
563,14,470,1,33,2
563,14,471,1,33,1
563,14,472,3,0,
563,14,477,4,0,
563,14,495,3,0,
563,14,496,4,0,
563,14,506,1,17,
563,15,50,1,1,3
563,15,50,1,5,
563,15,63,4,0,
563,15,92,4,0,
563,15,94,4,0,
563,15,101,1,13,
563,15,104,4,0,
563,15,114,1,1,4
563,15,114,1,9,
563,15,138,4,0,
563,15,148,4,0,
563,15,156,4,0,
563,15,164,4,0,
563,15,168,4,0,
563,15,174,1,29,
563,15,182,1,1,2
563,15,182,4,0,
563,15,184,1,34,
563,15,194,1,57,
563,15,207,4,0,
563,15,212,1,51,
563,15,213,4,0,
563,15,214,4,0,
563,15,216,4,0,
563,15,218,4,0,
563,15,219,4,0,
563,15,237,4,0,
563,15,240,4,0,
563,15,244,4,0,
563,15,247,1,39,
563,15,247,4,0,
563,15,261,1,21,
563,15,261,4,0,
563,15,263,4,0,
563,15,288,1,45,
563,15,310,1,1,1
563,15,347,4,0,
563,15,371,4,0,
563,15,373,4,0,
563,15,399,4,0,
563,15,412,4,0,
563,15,416,4,0,
563,15,433,4,0,
563,15,447,4,0,
563,15,466,1,25,
563,15,470,1,33,2
563,15,471,1,33,1
563,15,496,4,0,
563,15,506,1,17,
563,15,590,4,0,
563,15,611,4,0,
563,16,50,1,1,3
563,16,50,1,5,1
563,16,63,4,0,
563,16,92,4,0,
563,16,94,4,0,
563,16,101,1,13,1
563,16,104,4,0,
563,16,114,1,1,4
563,16,114,1,9,1
563,16,138,4,0,
563,16,148,4,0,
563,16,156,4,0,
563,16,164,4,0,
563,16,168,4,0,
563,16,173,3,0,
563,16,174,1,29,1
563,16,180,3,0,
563,16,182,1,1,2
563,16,182,4,0,
563,16,184,1,34,1
563,16,194,1,57,1
563,16,207,4,0,
563,16,212,1,51,1
563,16,213,4,0,
563,16,214,4,0,
563,16,216,4,0,
563,16,218,4,0,
563,16,219,4,0,
563,16,220,3,0,
563,16,237,4,0,
563,16,240,4,0,
563,16,244,4,0,
563,16,247,1,39,1
563,16,247,4,0,
563,16,261,1,21,1
563,16,261,4,0,
563,16,263,4,0,
563,16,271,3,0,
563,16,272,3,0,
563,16,277,3,0,
563,16,282,3,0,
563,16,285,3,0,
563,16,288,1,45,1
563,16,289,3,0,
563,16,290,4,0,
563,16,310,1,1,1
563,16,334,3,0,
563,16,335,3,0,
563,16,347,4,0,
563,16,351,3,0,
563,16,371,4,0,
563,16,373,4,0,
563,16,399,4,0,
563,16,412,4,0,
563,16,416,4,0,
563,16,428,3,0,
563,16,433,4,0,
563,16,447,4,0,
563,16,466,1,25,1
563,16,470,1,33,2
563,16,471,1,33,1
563,16,472,3,0,
563,16,495,3,0,
563,16,496,4,0,
563,16,506,1,17,1
563,16,590,4,0,
563,16,611,4,0,
563,17,50,1,1,4
563,17,50,1,5,
563,17,63,4,0,
563,17,92,4,0,
563,17,94,4,0,
563,17,101,1,13,
563,17,104,4,0,
563,17,114,1,1,5
563,17,114,1,9,
563,17,138,4,0,
563,17,156,4,0,
563,17,164,4,0,
563,17,168,4,0,
563,17,174,1,29,
563,17,182,1,1,3
563,17,182,4,0,
563,17,184,1,0,
563,17,184,1,1,1
563,17,194,1,57,
563,17,207,4,0,
563,17,212,1,51,
563,17,213,4,0,
563,17,214,4,0,
563,17,216,4,0,
563,17,218,4,0,
563,17,219,4,0,
563,17,237,4,0,
563,17,240,4,0,
563,17,244,4,0,
563,17,247,1,39,
563,17,247,4,0,
563,17,261,1,21,
563,17,261,4,0,
563,17,263,4,0,
563,17,288,1,45,
563,17,310,1,1,2
563,17,347,4,0,
563,17,371,4,0,
563,17,373,4,0,
563,17,399,4,0,
563,17,412,4,0,
563,17,416,4,0,
563,17,433,4,0,
563,17,447,4,0,
563,17,466,1,25,
563,17,470,1,33,2
563,17,471,1,33,1
563,17,496,4,0,
563,17,506,1,17,
563,17,590,4,0,
563,17,611,4,0,
563,18,50,1,1,4
563,18,50,1,5,
563,18,63,4,0,
563,18,92,4,0,
563,18,94,4,0,
563,18,101,1,13,
563,18,104,4,0,
563,18,114,1,1,5
563,18,114,1,9,
563,18,138,4,0,
563,18,156,4,0,
563,18,164,4,0,
563,18,168,4,0,
563,18,174,1,29,
563,18,182,1,1,3
563,18,182,4,0,
563,18,184,1,0,
563,18,184,1,1,1
563,18,194,1,57,
563,18,207,4,0,
563,18,212,1,51,
563,18,213,4,0,
563,18,214,4,0,
563,18,216,4,0,
563,18,218,4,0,
563,18,219,4,0,
563,18,237,4,0,
563,18,240,4,0,
563,18,244,4,0,
563,18,247,1,39,
563,18,247,4,0,
563,18,261,1,21,
563,18,261,4,0,
563,18,263,4,0,
563,18,288,1,45,
563,18,310,1,1,2
563,18,347,4,0,
563,18,371,4,0,
563,18,373,4,0,
563,18,399,4,0,
563,18,412,4,0,
563,18,416,4,0,
563,18,433,4,0,
563,18,447,4,0,
563,18,466,1,25,
563,18,470,1,33,2
563,18,471,1,33,1
563,18,496,4,0,
563,18,506,1,17,
563,18,590,4,0,
563,18,611,4,0,
564,11,21,2,0,
564,11,34,2,0,
564,11,44,1,8,
564,11,55,1,1,3
564,11,56,1,51,
564,11,57,4,0,
564,11,58,4,0,
564,11,59,4,0,
564,11,70,4,0,
564,11,88,2,0,
564,11,89,4,0,
564,11,91,4,0,
564,11,92,4,0,
564,11,104,4,0,
564,11,110,1,1,2
564,11,117,1,1,1
564,11,117,2,0,
564,11,127,4,0,
564,11,156,4,0,
564,11,157,1,45,
564,11,157,4,0,
564,11,164,4,0,
564,11,174,1,35,
564,11,175,2,0,
564,11,182,1,11,
564,11,182,4,0,
564,11,201,4,0,
564,11,205,1,5,
564,11,207,4,0,
564,11,213,4,0,
564,11,216,4,0,
564,11,218,4,0,
564,11,237,4,0,
564,11,240,1,48,
564,11,240,4,0,
564,11,242,1,21,
564,11,246,1,18,
564,11,249,4,0,
564,11,250,2,0,
564,11,263,4,0,
564,11,282,2,0,
564,11,291,4,0,
564,11,317,4,0,
564,11,334,2,0,
564,11,352,2,0,
564,11,362,1,28,
564,11,397,4,0,
564,11,401,1,41,
564,11,444,4,0,
564,11,453,1,15,
564,11,469,1,25,
564,11,479,1,31,
564,11,479,4,0,
564,11,496,4,0,
564,11,503,4,0,
564,11,504,1,38,
564,11,523,4,0,
564,14,21,2,0,
564,14,34,2,0,
564,14,44,1,8,
564,14,55,1,1,3
564,14,56,1,51,
564,14,57,4,0,
564,14,58,4,0,
564,14,59,4,0,
564,14,70,4,0,
564,14,88,2,0,
564,14,89,4,0,
564,14,91,4,0,
564,14,92,4,0,
564,14,104,4,0,
564,14,110,1,1,2
564,14,117,1,1,1
564,14,117,2,0,
564,14,127,4,0,
564,14,156,4,0,
564,14,157,1,45,
564,14,157,4,0,
564,14,164,4,0,
564,14,173,3,0,
564,14,174,1,35,
564,14,175,2,0,
564,14,182,1,11,
564,14,182,4,0,
564,14,196,3,0,
564,14,201,4,0,
564,14,205,1,5,
564,14,207,4,0,
564,14,213,4,0,
564,14,214,3,0,
564,14,216,4,0,
564,14,218,4,0,
564,14,231,3,0,
564,14,237,4,0,
564,14,240,1,48,
564,14,240,4,0,
564,14,242,1,21,
564,14,246,1,18,
564,14,249,4,0,
564,14,250,2,0,
564,14,263,4,0,
564,14,282,2,0,
564,14,291,4,0,
564,14,317,4,0,
564,14,334,2,0,
564,14,334,3,0,
564,14,335,3,0,
564,14,352,2,0,
564,14,362,1,28,
564,14,397,4,0,
564,14,401,1,41,
564,14,401,3,0,
564,14,414,3,0,
564,14,444,4,0,
564,14,446,3,0,
564,14,453,1,15,
564,14,469,1,25,
564,14,479,1,31,
564,14,479,4,0,
564,14,496,4,0,
564,14,503,4,0,
564,14,504,1,38,
564,14,523,4,0,
564,15,21,2,0,
564,15,34,2,0,
564,15,44,1,8,
564,15,55,1,1,3
564,15,56,1,50,
564,15,57,4,0,
564,15,58,4,0,
564,15,59,4,0,
564,15,70,4,0,
564,15,88,2,0,
564,15,89,4,0,
564,15,91,4,0,
564,15,92,4,0,
564,15,104,4,0,
564,15,110,1,1,2
564,15,117,1,1,1
564,15,117,2,0,
564,15,127,4,0,
564,15,156,4,0,
564,15,157,1,45,
564,15,157,4,0,
564,15,164,4,0,
564,15,174,1,35,
564,15,175,2,0,
564,15,182,1,11,
564,15,182,4,0,
564,15,201,4,0,
564,15,205,1,5,
564,15,207,4,0,
564,15,213,4,0,
564,15,214,4,0,
564,15,216,4,0,
564,15,218,4,0,
564,15,237,4,0,
564,15,240,1,48,
564,15,240,4,0,
564,15,242,1,21,
564,15,246,1,18,
564,15,249,4,0,
564,15,250,2,0,
564,15,263,4,0,
564,15,282,2,0,
564,15,317,4,0,
564,15,334,2,0,
564,15,352,2,0,
564,15,362,1,28,
564,15,385,2,0,
564,15,397,4,0,
564,15,401,1,41,
564,15,444,4,0,
564,15,453,1,15,
564,15,469,1,25,
564,15,479,1,31,
564,15,479,4,0,
564,15,496,4,0,
564,15,503,4,0,
564,15,504,1,38,
564,15,523,4,0,
564,15,590,4,0,
564,16,21,2,0,
564,16,34,2,0,
564,16,44,1,8,1
564,16,55,1,1,3
564,16,56,1,50,1
564,16,57,4,0,
564,16,58,4,0,
564,16,59,4,0,
564,16,70,4,0,
564,16,88,2,0,
564,16,89,4,0,
564,16,91,4,0,
564,16,92,4,0,
564,16,104,4,0,
564,16,110,1,1,2
564,16,117,1,1,1
564,16,117,2,0,
564,16,127,4,0,
564,16,156,4,0,
564,16,157,1,45,1
564,16,157,4,0,
564,16,164,4,0,
564,16,173,3,0,
564,16,174,1,35,1
564,16,175,2,0,
564,16,182,1,11,1
564,16,182,4,0,
564,16,196,3,0,
564,16,201,4,0,
564,16,205,1,5,1
564,16,207,4,0,
564,16,213,4,0,
564,16,214,4,0,
564,16,216,4,0,
564,16,218,4,0,
564,16,231,3,0,
564,16,237,4,0,
564,16,240,1,48,1
564,16,240,4,0,
564,16,242,1,21,1
564,16,246,1,18,1
564,16,249,4,0,
564,16,250,2,0,
564,16,263,4,0,
564,16,282,2,0,
564,16,282,3,0,
564,16,290,4,0,
564,16,291,4,0,
564,16,317,4,0,
564,16,334,2,0,
564,16,334,3,0,
564,16,335,3,0,
564,16,352,2,0,
564,16,352,3,0,
564,16,362,1,28,1
564,16,385,2,0,
564,16,397,4,0,
564,16,401,1,41,1
564,16,401,3,0,
564,16,414,3,0,
564,16,428,3,0,
564,16,444,4,0,
564,16,446,3,0,
564,16,453,1,15,1
564,16,469,1,25,1
564,16,479,1,31,1
564,16,479,4,0,
564,16,496,4,0,
564,16,503,4,0,
564,16,504,1,38,1
564,16,523,4,0,
564,16,590,4,0,
564,17,21,2,0,
564,17,34,2,0,
564,17,44,1,8,
564,17,55,1,1,3
564,17,56,1,50,
564,17,57,4,0,
564,17,58,4,0,
564,17,59,4,0,
564,17,88,2,0,
564,17,89,4,0,
564,17,92,4,0,
564,17,104,4,0,
564,17,110,1,1,2
564,17,117,1,1,1
564,17,117,2,0,
564,17,127,4,0,
564,17,156,4,0,
564,17,157,1,45,
564,17,157,4,0,
564,17,164,4,0,
564,17,174,1,35,
564,17,175,2,0,
564,17,182,1,11,
564,17,182,4,0,
564,17,201,4,0,
564,17,205,1,5,
564,17,207,4,0,
564,17,213,4,0,
564,17,214,4,0,
564,17,216,4,0,
564,17,218,4,0,
564,17,237,4,0,
564,17,240,1,48,
564,17,240,4,0,
564,17,242,1,21,
564,17,246,1,18,
564,17,250,2,0,
564,17,263,4,0,
564,17,282,2,0,
564,17,317,4,0,
564,17,334,2,0,
564,17,352,2,0,
564,17,362,1,28,
564,17,385,2,0,
564,17,397,4,0,
564,17,401,1,41,
564,17,444,4,0,
564,17,453,1,15,
564,17,469,1,25,
564,17,479,1,31,
564,17,479,4,0,
564,17,496,4,0,
564,17,503,4,0,
564,17,504,1,38,
564,17,523,4,0,
564,17,590,4,0,
564,17,710,2,0,
564,18,21,2,0,
564,18,34,2,0,
564,18,44,1,8,
564,18,55,1,1,3
564,18,56,1,50,
564,18,57,4,0,
564,18,58,4,0,
564,18,59,4,0,
564,18,88,2,0,
564,18,89,4,0,
564,18,92,4,0,
564,18,104,4,0,
564,18,110,1,1,2
564,18,117,1,1,1
564,18,117,2,0,
564,18,127,4,0,
564,18,156,4,0,
564,18,157,1,45,
564,18,157,4,0,
564,18,164,4,0,
564,18,174,1,35,
564,18,175,2,0,
564,18,182,1,11,
564,18,182,4,0,
564,18,201,4,0,
564,18,205,1,5,
564,18,207,4,0,
564,18,213,4,0,
564,18,214,4,0,
564,18,216,4,0,
564,18,218,4,0,
564,18,237,4,0,
564,18,240,1,48,
564,18,240,4,0,
564,18,242,1,21,
564,18,246,1,18,
564,18,250,2,0,
564,18,263,4,0,
564,18,282,2,0,
564,18,317,4,0,
564,18,334,2,0,
564,18,352,2,0,
564,18,362,1,28,
564,18,385,2,0,
564,18,397,4,0,
564,18,401,1,41,
564,18,444,4,0,
564,18,453,1,15,
564,18,469,1,25,
564,18,479,1,31,
564,18,479,4,0,
564,18,496,4,0,
564,18,503,4,0,
564,18,504,1,38,
564,18,523,4,0,
564,18,590,4,0,
564,18,710,2,0,
565,11,44,1,8,
565,11,55,1,1,3
565,11,56,1,61,
565,11,57,4,0,
565,11,58,4,0,
565,11,59,4,0,
565,11,63,4,0,
565,11,70,4,0,
565,11,89,4,0,
565,11,91,4,0,
565,11,92,4,0,
565,11,104,4,0,
565,11,110,1,1,2
565,11,117,1,1,1
565,11,127,4,0,
565,11,156,4,0,
565,11,157,1,51,
565,11,157,4,0,
565,11,164,4,0,
565,11,174,1,35,
565,11,182,1,11,
565,11,182,4,0,
565,11,201,4,0,
565,11,205,1,1,4
565,11,205,1,5,
565,11,207,4,0,
565,11,213,4,0,
565,11,216,4,0,
565,11,218,4,0,
565,11,237,4,0,
565,11,240,1,56,
565,11,240,4,0,
565,11,242,1,21,
565,11,246,1,18,
565,11,249,4,0,
565,11,263,4,0,
565,11,291,4,0,
565,11,317,4,0,
565,11,362,1,28,
565,11,397,4,0,
565,11,401,1,45,
565,11,411,4,0,
565,11,416,4,0,
565,11,444,4,0,
565,11,453,1,15,
565,11,469,1,25,
565,11,479,1,31,
565,11,479,4,0,
565,11,496,4,0,
565,11,503,4,0,
565,11,504,1,40,
565,11,523,4,0,
565,14,44,1,8,
565,14,55,1,1,3
565,14,56,1,61,
565,14,57,4,0,
565,14,58,4,0,
565,14,59,4,0,
565,14,63,4,0,
565,14,67,3,0,
565,14,70,4,0,
565,14,89,4,0,
565,14,91,4,0,
565,14,92,4,0,
565,14,104,4,0,
565,14,110,1,1,2
565,14,117,1,1,1
565,14,127,4,0,
565,14,156,4,0,
565,14,157,1,51,
565,14,157,4,0,
565,14,164,4,0,
565,14,173,3,0,
565,14,174,1,35,
565,14,182,1,11,
565,14,182,4,0,
565,14,196,3,0,
565,14,201,4,0,
565,14,205,1,1,4
565,14,205,1,5,
565,14,207,4,0,
565,14,213,4,0,
565,14,214,3,0,
565,14,216,4,0,
565,14,218,4,0,
565,14,231,3,0,
565,14,237,4,0,
565,14,240,1,56,
565,14,240,4,0,
565,14,242,1,21,
565,14,246,1,18,
565,14,249,4,0,
565,14,263,4,0,
565,14,276,3,0,
565,14,291,4,0,
565,14,317,4,0,
565,14,334,3,0,
565,14,335,3,0,
565,14,362,1,28,
565,14,397,4,0,
565,14,401,1,45,
565,14,401,3,0,
565,14,411,4,0,
565,14,414,3,0,
565,14,416,4,0,
565,14,442,3,0,
565,14,444,4,0,
565,14,446,3,0,
565,14,453,1,15,
565,14,469,1,25,
565,14,479,1,31,
565,14,479,4,0,
565,14,496,4,0,
565,14,503,4,0,
565,14,504,1,40,
565,14,523,4,0,
565,15,44,1,8,
565,15,55,1,1,3
565,15,56,1,61,
565,15,57,4,0,
565,15,58,4,0,
565,15,59,4,0,
565,15,63,4,0,
565,15,70,4,0,
565,15,89,4,0,
565,15,91,4,0,
565,15,92,4,0,
565,15,104,4,0,
565,15,110,1,1,2
565,15,117,1,1,1
565,15,127,4,0,
565,15,156,4,0,
565,15,157,1,51,
565,15,157,4,0,
565,15,164,4,0,
565,15,174,1,35,
565,15,182,1,11,
565,15,182,4,0,
565,15,201,4,0,
565,15,205,1,1,4
565,15,205,1,5,
565,15,207,4,0,
565,15,213,4,0,
565,15,214,4,0,
565,15,216,4,0,
565,15,218,4,0,
565,15,237,4,0,
565,15,240,1,56,
565,15,240,4,0,
565,15,242,1,21,
565,15,246,1,18,
565,15,249,4,0,
565,15,263,4,0,
565,15,317,4,0,
565,15,362,1,28,
565,15,397,4,0,
565,15,401,1,45,
565,15,411,4,0,
565,15,416,4,0,
565,15,444,4,0,
565,15,453,1,15,
565,15,469,1,25,
565,15,479,1,31,
565,15,479,4,0,
565,15,496,4,0,
565,15,503,4,0,
565,15,504,1,40,
565,15,523,4,0,
565,15,590,4,0,
565,16,44,1,8,1
565,16,55,1,1,3
565,16,56,1,61,1
565,16,57,4,0,
565,16,58,4,0,
565,16,59,4,0,
565,16,63,4,0,
565,16,67,3,0,
565,16,70,4,0,
565,16,89,4,0,
565,16,91,4,0,
565,16,92,4,0,
565,16,104,4,0,
565,16,110,1,1,2
565,16,117,1,1,1
565,16,127,4,0,
565,16,156,4,0,
565,16,157,1,51,1
565,16,157,4,0,
565,16,164,4,0,
565,16,173,3,0,
565,16,174,1,35,1
565,16,182,1,11,1
565,16,182,4,0,
565,16,196,3,0,
565,16,201,4,0,
565,16,205,1,1,4
565,16,205,1,5,1
565,16,207,4,0,
565,16,213,4,0,
565,16,214,4,0,
565,16,216,4,0,
565,16,218,4,0,
565,16,231,3,0,
565,16,237,4,0,
565,16,240,1,56,1
565,16,240,4,0,
565,16,242,1,21,1
565,16,246,1,18,1
565,16,249,4,0,
565,16,263,4,0,
565,16,276,3,0,
565,16,282,3,0,
565,16,290,4,0,
565,16,291,4,0,
565,16,317,4,0,
565,16,334,3,0,
565,16,335,3,0,
565,16,352,3,0,
565,16,362,1,28,1
565,16,397,4,0,
565,16,401,1,45,1
565,16,401,3,0,
565,16,411,4,0,
565,16,414,3,0,
565,16,416,4,0,
565,16,428,3,0,
565,16,442,3,0,
565,16,444,4,0,
565,16,446,3,0,
565,16,453,1,15,1
565,16,469,1,25,1
565,16,479,1,31,1
565,16,479,4,0,
565,16,496,4,0,
565,16,503,4,0,
565,16,504,1,40,1
565,16,523,4,0,
565,16,590,4,0,
565,17,44,1,8,
565,17,55,1,1,3
565,17,56,1,61,
565,17,57,4,0,
565,17,58,4,0,
565,17,59,4,0,
565,17,63,4,0,
565,17,89,4,0,
565,17,92,4,0,
565,17,104,4,0,
565,17,110,1,1,2
565,17,117,1,1,1
565,17,127,4,0,
565,17,156,4,0,
565,17,157,1,51,
565,17,157,4,0,
565,17,164,4,0,
565,17,174,1,35,
565,17,182,1,11,
565,17,182,4,0,
565,17,201,4,0,
565,17,205,1,1,4
565,17,205,1,5,
565,17,207,4,0,
565,17,213,4,0,
565,17,214,4,0,
565,17,216,4,0,
565,17,218,4,0,
565,17,237,4,0,
565,17,240,1,56,
565,17,240,4,0,
565,17,242,1,21,
565,17,246,1,18,
565,17,263,4,0,
565,17,317,4,0,
565,17,362,1,28,
565,17,397,4,0,
565,17,401,1,45,
565,17,411,4,0,
565,17,416,4,0,
565,17,444,4,0,
565,17,453,1,15,
565,17,469,1,25,
565,17,479,1,31,
565,17,479,4,0,
565,17,496,4,0,
565,17,503,4,0,
565,17,504,1,40,
565,17,523,4,0,
565,17,590,4,0,
565,18,44,1,8,
565,18,55,1,1,3
565,18,56,1,61,
565,18,57,4,0,
565,18,58,4,0,
565,18,59,4,0,
565,18,63,4,0,
565,18,89,4,0,
565,18,92,4,0,
565,18,104,4,0,
565,18,110,1,1,2
565,18,117,1,1,1
565,18,127,4,0,
565,18,156,4,0,
565,18,157,1,51,
565,18,157,4,0,
565,18,164,4,0,
565,18,174,1,35,
565,18,182,1,11,
565,18,182,4,0,
565,18,201,4,0,
565,18,205,1,1,4
565,18,205,1,5,
565,18,207,4,0,
565,18,213,4,0,
565,18,214,4,0,
565,18,216,4,0,
565,18,218,4,0,
565,18,237,4,0,
565,18,240,1,56,
565,18,240,4,0,
565,18,242,1,21,
565,18,246,1,18,
565,18,263,4,0,
565,18,317,4,0,
565,18,362,1,28,
565,18,397,4,0,
565,18,401,1,45,
565,18,411,4,0,
565,18,416,4,0,
565,18,444,4,0,
565,18,453,1,15,
565,18,469,1,25,
565,18,479,1,31,
565,18,479,4,0,
565,18,496,4,0,
565,18,503,4,0,
565,18,504,1,40,
565,18,523,4,0,
565,18,590,4,0,
566,11,15,4,0,
566,11,17,1,1,3
566,11,37,1,51,
566,11,43,1,1,2
566,11,44,2,0,
566,11,46,4,0,
566,11,88,1,5,
566,11,89,4,0,
566,11,91,4,0,
566,11,92,4,0,
566,11,97,1,21,
566,11,98,1,1,1
566,11,104,1,8,
566,11,104,4,0,
566,11,156,4,0,
566,11,157,1,45,
566,11,157,4,0,
566,11,164,4,0,
566,11,182,4,0,
566,11,184,1,11,
566,11,201,4,0,
566,11,207,4,0,
566,11,211,2,0,
566,11,213,4,0,
566,11,216,4,0,
566,11,218,4,0,
566,11,225,1,31,
566,11,237,4,0,
566,11,242,1,35,
566,11,246,1,18,
566,11,249,4,0,
566,11,259,4,0,
566,11,263,4,0,
566,11,269,4,0,
566,11,282,2,0,
566,11,283,1,38,
566,11,317,4,0,
566,11,332,4,0,
566,11,337,1,48,
566,11,337,4,0,
566,11,365,1,15,
566,11,365,4,0,
566,11,369,1,41,
566,11,369,4,0,
566,11,397,4,0,
566,11,406,2,0,
566,11,414,2,0,
566,11,421,4,0,
566,11,432,2,0,
566,11,444,4,0,
566,11,457,2,0,
566,11,468,4,0,
566,11,479,4,0,
566,11,496,4,0,
566,11,501,1,25,
566,11,512,1,28,
566,11,512,4,0,
566,11,523,4,0,
566,14,15,4,0,
566,14,17,1,1,3
566,14,37,1,51,
566,14,43,1,1,2
566,14,44,2,0,
566,14,46,4,0,
566,14,88,1,5,
566,14,89,4,0,
566,14,91,4,0,
566,14,92,4,0,
566,14,97,1,21,
566,14,98,1,1,1
566,14,104,1,8,
566,14,104,4,0,
566,14,156,4,0,
566,14,157,1,45,
566,14,157,4,0,
566,14,164,4,0,
566,14,173,3,0,
566,14,182,4,0,
566,14,184,1,11,
566,14,201,4,0,
566,14,207,4,0,
566,14,211,2,0,
566,14,213,4,0,
566,14,214,3,0,
566,14,216,4,0,
566,14,218,4,0,
566,14,225,1,31,
566,14,231,3,0,
566,14,237,4,0,
566,14,242,1,35,
566,14,246,1,18,
566,14,249,4,0,
566,14,253,3,0,
566,14,257,3,0,
566,14,259,4,0,
566,14,263,4,0,
566,14,269,4,0,
566,14,282,2,0,
566,14,283,1,38,
566,14,283,3,0,
566,14,317,4,0,
566,14,332,4,0,
566,14,334,3,0,
566,14,337,1,48,
566,14,337,4,0,
566,14,340,3,0,
566,14,355,3,0,
566,14,365,1,15,
566,14,365,4,0,
566,14,366,3,0,
566,14,369,1,41,
566,14,369,4,0,
566,14,397,4,0,
566,14,401,3,0,
566,14,406,2,0,
566,14,406,3,0,
566,14,414,2,0,
566,14,414,3,0,
566,14,421,4,0,
566,14,432,2,0,
566,14,444,4,0,
566,14,446,3,0,
566,14,457,2,0,
566,14,468,4,0,
566,14,479,4,0,
566,14,496,4,0,
566,14,501,1,25,
566,14,512,1,28,
566,14,512,4,0,
566,14,523,4,0,
566,15,15,4,0,
566,15,17,1,1,3
566,15,37,1,50,
566,15,43,1,1,2
566,15,44,2,0,
566,15,46,4,0,
566,15,88,1,5,
566,15,89,4,0,
566,15,91,4,0,
566,15,92,4,0,
566,15,97,1,21,
566,15,98,1,1,1
566,15,104,1,8,
566,15,104,4,0,
566,15,156,4,0,
566,15,157,1,45,
566,15,157,4,0,
566,15,164,4,0,
566,15,182,4,0,
566,15,184,1,11,
566,15,201,4,0,
566,15,207,4,0,
566,15,211,2,0,
566,15,211,4,0,
566,15,213,4,0,
566,15,214,4,0,
566,15,216,4,0,
566,15,218,4,0,
566,15,225,1,31,
566,15,237,4,0,
566,15,242,1,35,
566,15,246,1,18,
566,15,249,4,0,
566,15,259,4,0,
566,15,263,4,0,
566,15,269,4,0,
566,15,282,2,0,
566,15,283,1,38,
566,15,317,4,0,
566,15,332,4,0,
566,15,337,1,48,
566,15,337,4,0,
566,15,355,4,0,
566,15,365,1,15,
566,15,369,1,41,
566,15,369,4,0,
566,15,397,4,0,
566,15,406,2,0,
566,15,414,2,0,
566,15,415,2,0,
566,15,421,4,0,
566,15,432,2,0,
566,15,444,4,0,
566,15,457,2,0,
566,15,468,4,0,
566,15,479,4,0,
566,15,496,4,0,
566,15,501,1,25,
566,15,502,2,0,
566,15,512,1,28,
566,15,512,4,0,
566,15,523,4,0,
566,15,590,4,0,
566,16,15,4,0,
566,16,17,1,1,3
566,16,37,1,50,1
566,16,43,1,1,2
566,16,44,2,0,
566,16,46,4,0,
566,16,88,1,5,1
566,16,89,4,0,
566,16,91,4,0,
566,16,92,4,0,
566,16,97,1,21,1
566,16,98,1,1,1
566,16,104,1,8,1
566,16,104,4,0,
566,16,156,4,0,
566,16,157,1,45,1
566,16,157,4,0,
566,16,164,4,0,
566,16,173,3,0,
566,16,182,4,0,
566,16,184,1,11,1
566,16,201,4,0,
566,16,207,4,0,
566,16,211,2,0,
566,16,211,4,0,
566,16,213,4,0,
566,16,214,4,0,
566,16,216,4,0,
566,16,218,4,0,
566,16,225,1,31,1
566,16,231,3,0,
566,16,237,4,0,
566,16,242,1,35,1
566,16,246,1,18,1
566,16,249,4,0,
566,16,253,3,0,
566,16,257,3,0,
566,16,259,4,0,
566,16,263,4,0,
566,16,269,4,0,
566,16,282,2,0,
566,16,282,3,0,
566,16,283,1,38,1
566,16,283,3,0,
566,16,290,4,0,
566,16,317,4,0,
566,16,332,4,0,
566,16,334,3,0,
566,16,337,1,48,1
566,16,337,4,0,
566,16,340,3,0,
566,16,355,4,0,
566,16,365,1,15,1
566,16,366,3,0,
566,16,369,1,41,1
566,16,369,4,0,
566,16,397,4,0,
566,16,401,3,0,
566,16,406,2,0,
566,16,406,3,0,
566,16,414,2,0,
566,16,414,3,0,
566,16,415,2,0,
566,16,421,4,0,
566,16,428,3,0,
566,16,432,2,0,
566,16,444,4,0,
566,16,446,3,0,
566,16,457,2,0,
566,16,468,4,0,
566,16,479,4,0,
566,16,496,4,0,
566,16,501,1,25,1
566,16,502,2,0,
566,16,512,1,28,1
566,16,512,4,0,
566,16,523,4,0,
566,16,590,4,0,
566,17,17,1,1,3
566,17,37,1,50,
566,17,43,1,1,2
566,17,44,2,0,
566,17,46,4,0,
566,17,88,1,5,
566,17,89,4,0,
566,17,92,4,0,
566,17,97,1,21,
566,17,98,1,1,1
566,17,104,1,8,
566,17,104,4,0,
566,17,156,4,0,
566,17,157,1,45,
566,17,157,4,0,
566,17,164,4,0,
566,17,182,4,0,
566,17,184,1,11,
566,17,201,4,0,
566,17,207,4,0,
566,17,211,2,0,
566,17,211,4,0,
566,17,213,4,0,
566,17,214,4,0,
566,17,216,4,0,
566,17,218,4,0,
566,17,225,1,31,
566,17,237,4,0,
566,17,242,1,35,
566,17,246,1,18,
566,17,259,4,0,
566,17,263,4,0,
566,17,269,4,0,
566,17,282,2,0,
566,17,283,1,38,
566,17,317,4,0,
566,17,332,4,0,
566,17,337,1,48,
566,17,337,4,0,
566,17,355,4,0,
566,17,365,1,15,
566,17,369,1,41,
566,17,369,4,0,
566,17,397,4,0,
566,17,406,2,0,
566,17,414,2,0,
566,17,415,2,0,
566,17,421,4,0,
566,17,432,2,0,
566,17,444,4,0,
566,17,457,2,0,
566,17,479,4,0,
566,17,496,4,0,
566,17,501,1,25,
566,17,502,2,0,
566,17,512,1,28,
566,17,512,4,0,
566,17,523,4,0,
566,17,590,4,0,
566,18,17,1,1,3
566,18,37,1,50,
566,18,43,1,1,2
566,18,44,2,0,
566,18,46,4,0,
566,18,88,1,5,
566,18,89,4,0,
566,18,92,4,0,
566,18,97,1,21,
566,18,98,1,1,1
566,18,104,1,8,
566,18,104,4,0,
566,18,156,4,0,
566,18,157,1,45,
566,18,157,4,0,
566,18,164,4,0,
566,18,182,4,0,
566,18,184,1,11,
566,18,201,4,0,
566,18,207,4,0,
566,18,211,2,0,
566,18,211,4,0,
566,18,213,4,0,
566,18,214,4,0,
566,18,216,4,0,
566,18,218,4,0,
566,18,225,1,31,
566,18,237,4,0,
566,18,242,1,35,
566,18,246,1,18,
566,18,259,4,0,
566,18,263,4,0,
566,18,269,4,0,
566,18,282,2,0,
566,18,283,1,38,
566,18,317,4,0,
566,18,332,4,0,
566,18,337,1,48,
566,18,337,4,0,
566,18,355,4,0,
566,18,365,1,15,
566,18,369,1,41,
566,18,369,4,0,
566,18,397,4,0,
566,18,406,2,0,
566,18,414,2,0,
566,18,415,2,0,
566,18,421,4,0,
566,18,432,2,0,
566,18,444,4,0,
566,18,457,2,0,
566,18,479,4,0,
566,18,496,4,0,
566,18,501,1,25,
566,18,502,2,0,
566,18,512,1,28,
566,18,512,4,0,
566,18,523,4,0,
566,18,590,4,0,
567,11,15,4,0,
567,11,17,1,1,3
567,11,19,4,0,
567,11,37,1,61,
567,11,43,1,1,2
567,11,46,4,0,
567,11,63,4,0,
567,11,88,1,1,4
567,11,88,1,5,
567,11,89,4,0,
567,11,91,4,0,
567,11,92,4,0,
567,11,97,1,21,
567,11,98,1,1,1
567,11,104,1,8,
567,11,104,4,0,
567,11,156,4,0,
567,11,157,1,51,
567,11,157,4,0,
567,11,164,4,0,
567,11,182,4,0,
567,11,184,1,11,
567,11,201,4,0,
567,11,207,4,0,
567,11,213,4,0,
567,11,216,4,0,
567,11,218,4,0,
567,11,225,1,31,
567,11,237,4,0,
567,11,242,1,35,
567,11,246,1,18,
567,11,249,4,0,
567,11,259,4,0,
567,11,263,4,0,
567,11,269,4,0,
567,11,283,1,40,
567,11,317,4,0,
567,11,332,4,0,
567,11,337,1,56,
567,11,337,4,0,
567,11,365,1,15,
567,11,365,4,0,
567,11,369,1,45,
567,11,369,4,0,
567,11,397,4,0,
567,11,411,4,0,
567,11,416,4,0,
567,11,421,4,0,
567,11,444,4,0,
567,11,468,4,0,
567,11,479,4,0,
567,11,496,4,0,
567,11,501,1,25,
567,11,512,1,28,
567,11,512,4,0,
567,11,523,4,0,
567,11,525,4,0,
567,14,15,4,0,
567,14,17,1,1,3
567,14,19,4,0,
567,14,37,1,61,
567,14,43,1,1,2
567,14,46,4,0,
567,14,63,4,0,
567,14,88,1,1,4
567,14,88,1,5,
567,14,89,4,0,
567,14,91,4,0,
567,14,92,4,0,
567,14,97,1,21,
567,14,98,1,1,1
567,14,104,1,8,
567,14,104,4,0,
567,14,143,3,0,
567,14,156,4,0,
567,14,157,1,51,
567,14,157,4,0,
567,14,164,4,0,
567,14,173,3,0,
567,14,182,4,0,
567,14,184,1,11,
567,14,200,3,0,
567,14,201,4,0,
567,14,207,4,0,
567,14,213,4,0,
567,14,214,3,0,
567,14,216,4,0,
567,14,218,4,0,
567,14,225,1,31,
567,14,231,3,0,
567,14,237,4,0,
567,14,242,1,35,
567,14,246,1,18,
567,14,249,4,0,
567,14,253,3,0,
567,14,257,3,0,
567,14,259,4,0,
567,14,263,4,0,
567,14,269,4,0,
567,14,283,1,40,
567,14,283,3,0,
567,14,317,4,0,
567,14,332,4,0,
567,14,334,3,0,
567,14,337,1,56,
567,14,337,4,0,
567,14,340,3,0,
567,14,355,3,0,
567,14,365,1,15,
567,14,365,4,0,
567,14,366,3,0,
567,14,369,1,45,
567,14,369,4,0,
567,14,397,4,0,
567,14,401,3,0,
567,14,406,3,0,
567,14,411,4,0,
567,14,414,3,0,
567,14,416,4,0,
567,14,421,4,0,
567,14,444,4,0,
567,14,446,3,0,
567,14,468,4,0,
567,14,479,4,0,
567,14,496,4,0,
567,14,501,1,25,
567,14,512,1,28,
567,14,512,4,0,
567,14,523,4,0,
567,14,525,4,0,
567,15,15,4,0,
567,15,17,1,1,3
567,15,19,4,0,
567,15,37,1,61,
567,15,43,1,1,2
567,15,46,4,0,
567,15,63,4,0,
567,15,88,1,1,4
567,15,88,1,5,
567,15,89,4,0,
567,15,91,4,0,
567,15,92,4,0,
567,15,97,1,21,
567,15,98,1,1,1
567,15,104,1,8,
567,15,104,4,0,
567,15,156,4,0,
567,15,157,1,51,
567,15,157,4,0,
567,15,164,4,0,
567,15,182,4,0,
567,15,184,1,11,
567,15,201,4,0,
567,15,207,4,0,
567,15,211,4,0,
567,15,213,4,0,
567,15,214,4,0,
567,15,216,4,0,
567,15,218,4,0,
567,15,225,1,31,
567,15,237,4,0,
567,15,242,1,35,
567,15,246,1,18,
567,15,249,4,0,
567,15,259,4,0,
567,15,263,4,0,
567,15,269,4,0,
567,15,283,1,40,
567,15,317,4,0,
567,15,332,4,0,
567,15,337,1,56,
567,15,337,4,0,
567,15,355,4,0,
567,15,365,1,15,
567,15,369,1,45,
567,15,369,4,0,
567,15,397,4,0,
567,15,411,4,0,
567,15,416,4,0,
567,15,421,4,0,
567,15,444,4,0,
567,15,468,4,0,
567,15,479,4,0,
567,15,496,4,0,
567,15,501,1,25,
567,15,512,1,28,
567,15,512,4,0,
567,15,523,4,0,
567,15,525,4,0,
567,15,590,4,0,
567,16,15,4,0,
567,16,17,1,1,3
567,16,19,4,0,
567,16,37,1,61,1
567,16,43,1,1,2
567,16,46,4,0,
567,16,63,4,0,
567,16,88,1,1,4
567,16,88,1,5,1
567,16,89,4,0,
567,16,91,4,0,
567,16,92,4,0,
567,16,97,1,21,1
567,16,98,1,1,1
567,16,104,1,8,1
567,16,104,4,0,
567,16,143,3,0,
567,16,156,4,0,
567,16,157,1,51,1
567,16,157,4,0,
567,16,164,4,0,
567,16,173,3,0,
567,16,182,4,0,
567,16,184,1,11,1
567,16,200,3,0,
567,16,201,4,0,
567,16,207,4,0,
567,16,211,4,0,
567,16,213,4,0,
567,16,214,4,0,
567,16,216,4,0,
567,16,218,4,0,
567,16,225,1,31,1
567,16,231,3,0,
567,16,237,4,0,
567,16,242,1,35,1
567,16,246,1,18,1
567,16,249,4,0,
567,16,253,3,0,
567,16,257,3,0,
567,16,259,4,0,
567,16,263,4,0,
567,16,269,4,0,
567,16,282,3,0,
567,16,283,1,40,1
567,16,283,3,0,
567,16,290,4,0,
567,16,317,4,0,
567,16,332,4,0,
567,16,334,3,0,
567,16,337,1,56,1
567,16,337,4,0,
567,16,340,3,0,
567,16,355,4,0,
567,16,365,1,15,1
567,16,366,3,0,
567,16,369,1,45,1
567,16,369,4,0,
567,16,397,4,0,
567,16,401,3,0,
567,16,406,3,0,
567,16,411,4,0,
567,16,414,3,0,
567,16,416,4,0,
567,16,421,4,0,
567,16,428,3,0,
567,16,444,4,0,
567,16,446,3,0,
567,16,468,4,0,
567,16,479,4,0,
567,16,496,4,0,
567,16,501,1,25,1
567,16,512,1,28,1
567,16,512,4,0,
567,16,523,4,0,
567,16,525,4,0,
567,16,590,4,0,
567,17,17,1,1,3
567,17,19,4,0,
567,17,37,1,61,
567,17,43,1,1,2
567,17,46,4,0,
567,17,63,4,0,
567,17,88,1,1,4
567,17,88,1,5,
567,17,89,4,0,
567,17,92,4,0,
567,17,97,1,21,
567,17,98,1,1,1
567,17,104,1,8,
567,17,104,4,0,
567,17,156,4,0,
567,17,157,1,51,
567,17,157,4,0,
567,17,164,4,0,
567,17,182,4,0,
567,17,184,1,11,
567,17,201,4,0,
567,17,207,4,0,
567,17,211,4,0,
567,17,213,4,0,
567,17,214,4,0,
567,17,216,4,0,
567,17,218,4,0,
567,17,225,1,31,
567,17,237,4,0,
567,17,242,1,35,
567,17,246,1,18,
567,17,259,4,0,
567,17,263,4,0,
567,17,269,4,0,
567,17,283,1,40,
567,17,317,4,0,
567,17,332,4,0,
567,17,337,1,56,
567,17,337,4,0,
567,17,355,4,0,
567,17,365,1,15,
567,17,369,1,45,
567,17,369,4,0,
567,17,397,4,0,
567,17,411,4,0,
567,17,416,4,0,
567,17,421,4,0,
567,17,444,4,0,
567,17,479,4,0,
567,17,496,4,0,
567,17,501,1,25,
567,17,512,1,28,
567,17,512,4,0,
567,17,523,4,0,
567,17,525,4,0,
567,17,590,4,0,
567,18,17,1,1,3
567,18,19,4,0,
567,18,37,1,61,
567,18,43,1,1,2
567,18,46,4,0,
567,18,63,4,0,
567,18,88,1,1,4
567,18,88,1,5,
567,18,89,4,0,
567,18,92,4,0,
567,18,97,1,21,
567,18,98,1,1,1
567,18,104,1,8,
567,18,104,4,0,
567,18,156,4,0,
567,18,157,1,51,
567,18,157,4,0,
567,18,164,4,0,
567,18,182,4,0,
567,18,184,1,11,
567,18,201,4,0,
567,18,207,4,0,
567,18,211,4,0,
567,18,213,4,0,
567,18,214,4,0,
567,18,216,4,0,
567,18,218,4,0,
567,18,225,1,31,
567,18,237,4,0,
567,18,242,1,35,
567,18,246,1,18,
567,18,259,4,0,
567,18,263,4,0,
567,18,269,4,0,
567,18,283,1,40,
567,18,317,4,0,
567,18,332,4,0,
567,18,337,1,56,
567,18,337,4,0,
567,18,355,4,0,
567,18,365,1,15,
567,18,369,1,45,
567,18,369,4,0,
567,18,397,4,0,
567,18,411,4,0,
567,18,416,4,0,
567,18,421,4,0,
567,18,444,4,0,
567,18,479,4,0,
567,18,496,4,0,
567,18,501,1,25,
567,18,512,1,28,
567,18,512,4,0,
567,18,523,4,0,
567,18,525,4,0,
567,18,590,4,0,
568,11,1,1,1,1
568,11,3,1,14,
568,11,28,2,0,
568,11,36,1,25,
568,11,92,1,36,
568,11,92,4,0,
568,11,104,4,0,
568,11,114,2,0,
568,11,120,2,0,
568,11,124,1,18,
568,11,133,1,40,
568,11,139,1,1,2
568,11,153,1,47,
568,11,153,4,0,
568,11,156,4,0,
568,11,164,4,0,
568,11,168,4,0,
568,11,174,2,0,
568,11,182,4,0,
568,11,188,1,29,
568,11,188,4,0,
568,11,191,2,0,
568,11,205,2,0,
568,11,207,4,0,
568,11,213,4,0,
568,11,216,4,0,
568,11,218,4,0,
568,11,237,4,0,
568,11,240,4,0,
568,11,241,4,0,
568,11,254,1,23,1
568,11,256,1,23,2
568,11,263,4,0,
568,11,278,1,3,
568,11,300,2,0,
568,11,350,2,0,
568,11,371,4,0,
568,11,390,1,7,
568,11,441,1,45,
568,11,474,4,0,
568,11,482,4,0,
568,11,491,1,12,
568,11,496,4,0,
568,11,499,1,34,
568,14,1,1,1,1
568,14,3,1,14,
568,14,28,2,0,
568,14,36,1,25,
568,14,92,1,36,
568,14,92,4,0,
568,14,104,4,0,
568,14,114,2,0,
568,14,120,2,0,
568,14,124,1,18,
568,14,133,1,40,
568,14,139,1,1,2
568,14,153,1,47,
568,14,153,4,0,
568,14,156,4,0,
568,14,164,4,0,
568,14,168,4,0,
568,14,173,3,0,
568,14,174,2,0,
568,14,180,3,0,
568,14,182,4,0,
568,14,188,1,29,
568,14,188,4,0,
568,14,191,2,0,
568,14,202,3,0,
568,14,205,2,0,
568,14,207,4,0,
568,14,213,4,0,
568,14,214,3,0,
568,14,216,4,0,
568,14,218,4,0,
568,14,220,3,0,
568,14,237,4,0,
568,14,240,4,0,
568,14,241,4,0,
568,14,254,1,23,1
568,14,256,1,23,2
568,14,263,4,0,
568,14,278,1,3,
568,14,278,3,0,
568,14,300,2,0,
568,14,350,2,0,
568,14,371,4,0,
568,14,390,1,7,
568,14,399,3,0,
568,14,402,3,0,
568,14,409,3,0,
568,14,441,1,45,
568,14,441,3,0,
568,14,474,4,0,
568,14,482,4,0,
568,14,491,1,12,
568,14,496,4,0,
568,14,499,1,34,
568,15,1,1,1,1
568,15,3,1,14,
568,15,28,2,0,
568,15,36,1,25,
568,15,92,1,36,
568,15,92,4,0,
568,15,104,4,0,
568,15,114,2,0,
568,15,120,2,0,
568,15,124,1,18,
568,15,133,1,40,
568,15,139,1,1,2
568,15,153,1,47,
568,15,153,4,0,
568,15,156,4,0,
568,15,164,4,0,
568,15,168,4,0,
568,15,174,2,0,
568,15,182,4,0,
568,15,188,1,29,
568,15,188,4,0,
568,15,191,2,0,
568,15,205,2,0,
568,15,207,4,0,
568,15,213,4,0,
568,15,214,4,0,
568,15,216,4,0,
568,15,218,4,0,
568,15,237,4,0,
568,15,240,4,0,
568,15,241,4,0,
568,15,254,1,23,1
568,15,256,1,23,2
568,15,263,4,0,
568,15,278,1,3,
568,15,300,2,0,
568,15,350,2,0,
568,15,371,4,0,
568,15,390,1,7,
568,15,399,4,0,
568,15,441,1,45,
568,15,474,4,0,
568,15,482,4,0,
568,15,491,1,12,
568,15,496,4,0,
568,15,499,1,34,
568,15,562,1,42,
568,15,590,4,0,
568,15,611,4,0,
568,16,1,1,1,1
568,16,3,1,14,1
568,16,28,2,0,
568,16,36,1,25,1
568,16,92,1,36,1
568,16,92,4,0,
568,16,104,4,0,
568,16,114,2,0,
568,16,120,2,0,
568,16,124,1,18,1
568,16,133,1,40,1
568,16,139,1,1,2
568,16,153,1,47,1
568,16,153,4,0,
568,16,156,4,0,
568,16,164,4,0,
568,16,168,4,0,
568,16,173,3,0,
568,16,174,2,0,
568,16,180,3,0,
568,16,182,4,0,
568,16,188,1,29,1
568,16,188,4,0,
568,16,191,2,0,
568,16,202,3,0,
568,16,205,2,0,
568,16,207,4,0,
568,16,213,4,0,
568,16,214,4,0,
568,16,216,4,0,
568,16,218,4,0,
568,16,220,3,0,
568,16,237,4,0,
568,16,240,4,0,
568,16,241,4,0,
568,16,254,1,23,1
568,16,256,1,23,2
568,16,263,4,0,
568,16,278,1,3,1
568,16,278,3,0,
568,16,290,4,0,
568,16,300,2,0,
568,16,350,2,0,
568,16,371,4,0,
568,16,390,1,7,1
568,16,399,4,0,
568,16,402,3,0,
568,16,409,3,0,
568,16,441,1,45,1
568,16,441,3,0,
568,16,474,4,0,
568,16,482,4,0,
568,16,491,1,12,1
568,16,496,4,0,
568,16,499,1,34,1
568,16,562,1,42,1
568,16,590,4,0,
568,16,611,4,0,
568,17,1,1,1,1
568,17,3,1,14,
568,17,28,2,0,
568,17,36,1,25,
568,17,92,1,36,
568,17,92,4,0,
568,17,104,4,0,
568,17,114,2,0,
568,17,120,2,0,
568,17,124,1,18,
568,17,133,1,40,
568,17,139,1,1,2
568,17,153,1,47,
568,17,153,4,0,
568,17,156,4,0,
568,17,164,4,0,
568,17,168,4,0,
568,17,174,2,0,
568,17,182,4,0,
568,17,188,1,29,
568,17,188,4,0,
568,17,191,2,0,
568,17,205,2,0,
568,17,207,4,0,
568,17,213,4,0,
568,17,214,4,0,
568,17,216,4,0,
568,17,218,4,0,
568,17,237,4,0,
568,17,240,4,0,
568,17,241,4,0,
568,17,254,1,23,1
568,17,256,1,23,2
568,17,263,4,0,
568,17,278,1,3,
568,17,300,2,0,
568,17,350,2,0,
568,17,371,4,0,
568,17,390,1,7,
568,17,399,4,0,
568,17,441,1,45,
568,17,474,4,0,
568,17,482,4,0,
568,17,491,1,12,
568,17,496,4,0,
568,17,499,1,34,
568,17,562,1,42,
568,17,590,4,0,
568,17,611,4,0,
568,18,1,1,1,1
568,18,3,1,14,
568,18,28,2,0,
568,18,36,1,25,
568,18,92,1,36,
568,18,92,4,0,
568,18,104,4,0,
568,18,114,2,0,
568,18,120,2,0,
568,18,124,1,18,
568,18,133,1,40,
568,18,139,1,1,2
568,18,153,1,47,
568,18,153,4,0,
568,18,156,4,0,
568,18,164,4,0,
568,18,168,4,0,
568,18,174,2,0,
568,18,182,4,0,
568,18,188,1,29,
568,18,188,4,0,
568,18,191,2,0,
568,18,205,2,0,
568,18,207,4,0,
568,18,213,4,0,
568,18,214,4,0,
568,18,216,4,0,
568,18,218,4,0,
568,18,237,4,0,
568,18,240,4,0,
568,18,241,4,0,
568,18,254,1,23,1
568,18,256,1,23,2
568,18,263,4,0,
568,18,278,1,3,
568,18,300,2,0,
568,18,350,2,0,
568,18,371,4,0,
568,18,390,1,7,
568,18,399,4,0,
568,18,441,1,45,
568,18,474,4,0,
568,18,475,2,0,
568,18,482,4,0,
568,18,491,1,12,
568,18,496,4,0,
568,18,499,1,34,
568,18,562,1,42,
568,18,590,4,0,
568,18,611,4,0,
569,11,1,1,1,1
569,11,3,1,14,
569,11,34,1,25,
569,11,63,4,0,
569,11,76,4,0,
569,11,85,4,0,
569,11,92,1,39,
569,11,92,4,0,
569,11,94,4,0,
569,11,104,4,0,
569,11,124,1,18,
569,11,133,1,46,
569,11,139,1,1,2
569,11,153,1,59,
569,11,153,4,0,
569,11,156,4,0,
569,11,164,4,0,
569,11,168,4,0,
569,11,182,4,0,
569,11,188,1,29,
569,11,188,4,0,
569,11,207,4,0,
569,11,213,4,0,
569,11,216,4,0,
569,11,218,4,0,
569,11,237,4,0,
569,11,240,4,0,
569,11,241,4,0,
569,11,254,1,23,1
569,11,256,1,23,2
569,11,263,4,0,
569,11,278,1,1,3
569,11,278,1,3,
569,11,371,4,0,
569,11,374,4,0,
569,11,390,1,1,4
569,11,390,1,7,
569,11,397,4,0,
569,11,411,4,0,
569,11,416,4,0,
569,11,441,1,54,
569,11,474,4,0,
569,11,479,4,0,
569,11,482,4,0,
569,11,491,1,12,
569,11,496,4,0,
569,11,499,1,34,
569,14,1,1,1,1
569,14,3,1,14,
569,14,34,1,25,
569,14,63,4,0,
569,14,76,4,0,
569,14,85,4,0,
569,14,92,1,39,
569,14,92,4,0,
569,14,94,4,0,
569,14,104,4,0,
569,14,124,1,18,
569,14,133,1,46,
569,14,139,1,1,2
569,14,153,1,59,
569,14,153,4,0,
569,14,156,4,0,
569,14,164,4,0,
569,14,168,4,0,
569,14,173,3,0,
569,14,180,3,0,
569,14,182,4,0,
569,14,188,1,29,
569,14,188,4,0,
569,14,202,3,0,
569,14,207,4,0,
569,14,213,4,0,
569,14,214,3,0,
569,14,216,4,0,
569,14,218,4,0,
569,14,220,3,0,
569,14,237,4,0,
569,14,240,4,0,
569,14,241,4,0,
569,14,254,1,23,1
569,14,256,1,23,2
569,14,263,4,0,
569,14,278,1,1,3
569,14,278,1,3,
569,14,278,3,0,
569,14,371,4,0,
569,14,374,4,0,
569,14,390,1,1,4
569,14,390,1,7,
569,14,397,4,0,
569,14,399,3,0,
569,14,402,3,0,
569,14,409,3,0,
569,14,411,4,0,
569,14,416,4,0,
569,14,441,1,54,
569,14,441,3,0,
569,14,474,4,0,
569,14,479,4,0,
569,14,482,4,0,
569,14,491,1,12,
569,14,496,4,0,
569,14,499,1,34,
569,15,1,1,1,1
569,15,3,1,14,
569,15,34,1,25,
569,15,63,4,0,
569,15,76,4,0,
569,15,85,4,0,
569,15,92,1,39,
569,15,92,4,0,
569,15,94,4,0,
569,15,104,4,0,
569,15,124,1,18,
569,15,133,1,46,
569,15,139,1,1,2
569,15,153,1,59,
569,15,153,4,0,
569,15,156,4,0,
569,15,164,4,0,
569,15,168,4,0,
569,15,182,4,0,
569,15,188,1,29,
569,15,188,4,0,
569,15,207,4,0,
569,15,213,4,0,
569,15,214,4,0,
569,15,216,4,0,
569,15,218,4,0,
569,15,237,4,0,
569,15,240,4,0,
569,15,241,4,0,
569,15,254,1,23,1
569,15,256,1,23,2
569,15,263,4,0,
569,15,278,1,1,3
569,15,278,1,3,
569,15,371,4,0,
569,15,374,4,0,
569,15,390,1,1,4
569,15,390,1,7,
569,15,397,4,0,
569,15,399,4,0,
569,15,411,4,0,
569,15,416,4,0,
569,15,441,1,54,
569,15,474,4,0,
569,15,479,4,0,
569,15,482,4,0,
569,15,491,1,12,
569,15,496,4,0,
569,15,499,1,34,
569,15,562,1,49,
569,15,590,4,0,
569,15,611,4,0,
569,16,1,1,1,1
569,16,3,1,14,1
569,16,34,1,25,1
569,16,63,4,0,
569,16,76,4,0,
569,16,85,4,0,
569,16,92,1,39,1
569,16,92,4,0,
569,16,94,4,0,
569,16,104,4,0,
569,16,124,1,18,1
569,16,133,1,46,1
569,16,139,1,1,2
569,16,153,1,59,1
569,16,153,4,0,
569,16,156,4,0,
569,16,164,4,0,
569,16,168,4,0,
569,16,173,3,0,
569,16,180,3,0,
569,16,182,4,0,
569,16,188,1,29,1
569,16,188,4,0,
569,16,202,3,0,
569,16,207,4,0,
569,16,213,4,0,
569,16,214,4,0,
569,16,216,4,0,
569,16,218,4,0,
569,16,220,3,0,
569,16,237,4,0,
569,16,240,4,0,
569,16,241,4,0,
569,16,254,1,23,1
569,16,256,1,23,2
569,16,263,4,0,
569,16,278,1,1,3
569,16,278,1,3,1
569,16,278,3,0,
569,16,290,4,0,
569,16,371,4,0,
569,16,374,4,0,
569,16,390,1,1,4
569,16,390,1,7,1
569,16,397,4,0,
569,16,399,4,0,
569,16,402,3,0,
569,16,409,3,0,
569,16,411,4,0,
569,16,416,4,0,
569,16,441,1,54,1
569,16,441,3,0,
569,16,474,4,0,
569,16,479,4,0,
569,16,482,4,0,
569,16,491,1,12,1
569,16,496,4,0,
569,16,499,1,34,1
569,16,562,1,49,1
569,16,590,4,0,
569,16,611,4,0,
569,17,1,1,1,1
569,17,3,1,14,
569,17,34,1,25,
569,17,63,4,0,
569,17,76,4,0,
569,17,85,4,0,
569,17,92,1,39,
569,17,92,4,0,
569,17,94,4,0,
569,17,104,4,0,
569,17,124,1,18,
569,17,133,1,46,
569,17,139,1,1,2
569,17,153,1,59,
569,17,153,4,0,
569,17,156,4,0,
569,17,164,4,0,
569,17,168,4,0,
569,17,182,4,0,
569,17,188,1,29,
569,17,188,4,0,
569,17,207,4,0,
569,17,213,4,0,
569,17,214,4,0,
569,17,216,4,0,
569,17,218,4,0,
569,17,237,4,0,
569,17,240,4,0,
569,17,241,4,0,
569,17,254,1,23,1
569,17,256,1,23,2
569,17,263,4,0,
569,17,278,1,1,3
569,17,278,1,3,
569,17,371,4,0,
569,17,374,4,0,
569,17,390,1,1,4
569,17,390,1,7,
569,17,397,4,0,
569,17,399,4,0,
569,17,411,4,0,
569,17,416,4,0,
569,17,441,1,54,
569,17,474,4,0,
569,17,479,4,0,
569,17,482,4,0,
569,17,491,1,12,
569,17,496,4,0,
569,17,499,1,34,
569,17,562,1,49,
569,17,590,4,0,
569,17,611,4,0,
569,18,1,1,1,1
569,18,3,1,14,
569,18,34,1,25,
569,18,63,4,0,
569,18,76,4,0,
569,18,85,4,0,
569,18,92,1,39,
569,18,92,4,0,
569,18,94,4,0,
569,18,104,4,0,
569,18,124,1,18,
569,18,133,1,46,
569,18,139,1,1,2
569,18,153,1,59,
569,18,153,4,0,
569,18,156,4,0,
569,18,164,4,0,
569,18,168,4,0,
569,18,182,4,0,
569,18,188,1,29,
569,18,188,4,0,
569,18,207,4,0,
569,18,213,4,0,
569,18,214,4,0,
569,18,216,4,0,
569,18,218,4,0,
569,18,237,4,0,
569,18,240,4,0,
569,18,241,4,0,
569,18,254,1,23,1
569,18,256,1,23,2
569,18,263,4,0,
569,18,278,1,1,3
569,18,278,1,3,
569,18,371,4,0,
569,18,374,4,0,
569,18,390,1,1,4
569,18,390,1,7,
569,18,397,4,0,
569,18,399,4,0,
569,18,411,4,0,
569,18,416,4,0,
569,18,441,1,54,
569,18,474,4,0,
569,18,479,4,0,
569,18,482,4,0,
569,18,491,1,12,
569,18,496,4,0,
569,18,499,1,34,
569,18,562,1,49,
569,18,590,4,0,
569,18,611,4,0,
570,11,10,1,1,1
570,11,14,4,0,
570,11,15,4,0,
570,11,43,1,1,2
570,11,46,4,0,
570,11,68,2,0,
570,11,91,4,0,
570,11,92,4,0,
570,11,97,1,37,
570,11,104,4,0,
570,11,154,1,13,
570,11,156,4,0,
570,11,164,4,0,
570,11,168,4,0,
570,11,182,4,0,
570,11,184,1,21,
570,11,185,1,17,
570,11,197,2,0,
570,11,207,4,0,
570,11,213,4,0,
570,11,216,4,0,
570,11,218,4,0,
570,11,228,1,5,
570,11,237,4,0,
570,11,240,4,0,
570,11,241,4,0,
570,11,244,4,0,
570,11,247,4,0,
570,11,259,1,33,
570,11,259,4,0,
570,11,262,2,0,
570,11,263,4,0,
570,11,269,1,25,
570,11,269,4,0,
570,11,286,1,53,
570,11,289,2,0,
570,11,313,1,9,
570,11,326,2,0,
570,11,332,4,0,
570,11,347,4,0,
570,11,369,4,0,
570,11,371,4,0,
570,11,373,1,41,
570,11,373,4,0,
570,11,374,4,0,
570,11,386,1,45,
570,11,389,2,0,
570,11,399,2,0,
570,11,417,1,49,
570,11,445,2,0,
570,11,447,4,0,
570,11,468,4,0,
570,11,492,1,29,
570,11,496,4,0,
570,11,510,4,0,
570,11,514,4,0,
570,11,539,1,57,
570,11,555,4,0,
570,14,10,1,1,1
570,14,14,4,0,
570,14,15,4,0,
570,14,43,1,1,2
570,14,46,4,0,
570,14,68,2,0,
570,14,91,4,0,
570,14,92,4,0,
570,14,97,1,37,
570,14,104,4,0,
570,14,154,1,13,
570,14,156,4,0,
570,14,164,4,0,
570,14,168,4,0,
570,14,173,3,0,
570,14,180,3,0,
570,14,182,4,0,
570,14,184,1,21,
570,14,185,1,17,
570,14,197,2,0,
570,14,207,4,0,
570,14,213,4,0,
570,14,214,3,0,
570,14,216,4,0,
570,14,218,4,0,
570,14,228,1,5,
570,14,237,4,0,
570,14,240,4,0,
570,14,241,4,0,
570,14,244,4,0,
570,14,247,4,0,
570,14,253,3,0,
570,14,259,1,33,
570,14,259,4,0,
570,14,262,2,0,
570,14,263,4,0,
570,14,269,1,25,
570,14,269,4,0,
570,14,271,3,0,
570,14,282,3,0,
570,14,286,1,53,
570,14,289,2,0,
570,14,289,3,0,
570,14,304,3,0,
570,14,313,1,9,
570,14,326,2,0,
570,14,332,4,0,
570,14,340,3,0,
570,14,343,3,0,
570,14,347,4,0,
570,14,369,4,0,
570,14,371,4,0,
570,14,373,1,41,
570,14,373,4,0,
570,14,374,4,0,
570,14,386,1,45,
570,14,389,2,0,
570,14,399,2,0,
570,14,399,3,0,
570,14,417,1,49,
570,14,445,2,0,
570,14,447,4,0,
570,14,468,4,0,
570,14,492,1,29,
570,14,492,3,0,
570,14,496,4,0,
570,14,510,4,0,
570,14,514,4,0,
570,14,539,1,57,
570,14,555,4,0,
570,15,10,1,1,1
570,15,14,4,0,
570,15,15,4,0,
570,15,43,1,1,2
570,15,46,4,0,
570,15,68,2,0,
570,15,91,4,0,
570,15,92,4,0,
570,15,97,1,37,
570,15,104,4,0,
570,15,154,1,13,
570,15,156,4,0,
570,15,164,4,0,
570,15,168,4,0,
570,15,182,4,0,
570,15,184,1,21,
570,15,185,1,17,
570,15,197,2,0,
570,15,207,4,0,
570,15,213,4,0,
570,15,214,4,0,
570,15,216,4,0,
570,15,218,4,0,
570,15,228,1,5,
570,15,237,4,0,
570,15,240,4,0,
570,15,241,4,0,
570,15,244,4,0,
570,15,247,4,0,
570,15,259,1,33,
570,15,259,4,0,
570,15,262,2,0,
570,15,263,4,0,
570,15,269,1,25,
570,15,269,4,0,
570,15,286,1,53,
570,15,289,2,0,
570,15,313,1,9,
570,15,326,2,0,
570,15,332,4,0,
570,15,347,4,0,
570,15,369,4,0,
570,15,371,4,0,
570,15,373,1,41,
570,15,373,4,0,
570,15,374,4,0,
570,15,383,2,0,
570,15,386,1,45,
570,15,389,2,0,
570,15,399,2,0,
570,15,399,4,0,
570,15,417,1,49,
570,15,445,2,0,
570,15,447,4,0,
570,15,468,4,0,
570,15,492,1,29,
570,15,496,4,0,
570,15,510,4,0,
570,15,514,4,0,
570,15,539,1,57,
570,15,555,4,0,
570,15,590,4,0,
570,16,10,1,1,1
570,16,14,4,0,
570,16,15,4,0,
570,16,43,1,1,2
570,16,46,4,0,
570,16,68,2,0,
570,16,91,4,0,
570,16,92,4,0,
570,16,97,1,37,1
570,16,104,4,0,
570,16,154,1,13,1
570,16,156,4,0,
570,16,164,4,0,
570,16,168,4,0,
570,16,173,3,0,
570,16,180,3,0,
570,16,182,4,0,
570,16,184,1,21,1
570,16,185,1,17,1
570,16,197,2,0,
570,16,207,4,0,
570,16,213,4,0,
570,16,214,4,0,
570,16,216,4,0,
570,16,218,4,0,
570,16,228,1,5,1
570,16,237,4,0,
570,16,240,4,0,
570,16,241,4,0,
570,16,244,4,0,
570,16,247,4,0,
570,16,253,3,0,
570,16,259,1,33,1
570,16,259,4,0,
570,16,262,2,0,
570,16,263,4,0,
570,16,269,1,25,1
570,16,269,4,0,
570,16,271,3,0,
570,16,282,3,0,
570,16,286,1,53,1
570,16,289,2,0,
570,16,289,3,0,
570,16,290,4,0,
570,16,304,3,0,
570,16,313,1,9,1
570,16,326,2,0,
570,16,332,4,0,
570,16,340,3,0,
570,16,343,3,0,
570,16,347,4,0,
570,16,369,4,0,
570,16,371,4,0,
570,16,373,1,41,1
570,16,373,4,0,
570,16,374,4,0,
570,16,383,2,0,
570,16,386,1,45,1
570,16,389,2,0,
570,16,399,2,0,
570,16,399,4,0,
570,16,417,1,49,1
570,16,445,2,0,
570,16,447,4,0,
570,16,468,4,0,
570,16,492,1,29,1
570,16,492,3,0,
570,16,496,4,0,
570,16,510,4,0,
570,16,514,4,0,
570,16,539,1,57,1
570,16,555,4,0,
570,16,590,4,0,
570,17,10,1,1,1
570,17,14,4,0,
570,17,43,1,1,2
570,17,46,4,0,
570,17,68,2,0,
570,17,92,4,0,
570,17,97,1,37,
570,17,104,4,0,
570,17,154,1,13,
570,17,156,4,0,
570,17,164,4,0,
570,17,168,4,0,
570,17,182,4,0,
570,17,184,1,21,
570,17,185,1,17,
570,17,197,2,0,
570,17,207,4,0,
570,17,213,4,0,
570,17,214,4,0,
570,17,216,4,0,
570,17,218,4,0,
570,17,228,1,5,
570,17,237,4,0,
570,17,240,4,0,
570,17,241,4,0,
570,17,244,4,0,
570,17,247,4,0,
570,17,259,1,33,
570,17,259,4,0,
570,17,262,2,0,
570,17,263,4,0,
570,17,269,1,25,
570,17,269,4,0,
570,17,286,1,53,
570,17,289,2,0,
570,17,313,1,9,
570,17,326,2,0,
570,17,332,4,0,
570,17,347,4,0,
570,17,369,4,0,
570,17,371,4,0,
570,17,373,1,41,
570,17,373,4,0,
570,17,374,4,0,
570,17,383,2,0,
570,17,386,1,45,
570,17,389,2,0,
570,17,399,2,0,
570,17,399,4,0,
570,17,417,1,49,
570,17,445,2,0,
570,17,447,4,0,
570,17,492,1,29,
570,17,496,4,0,
570,17,539,1,57,
570,17,555,4,0,
570,17,590,4,0,
570,18,10,1,1,1
570,18,14,4,0,
570,18,43,1,1,2
570,18,46,4,0,
570,18,68,2,0,
570,18,92,4,0,
570,18,97,1,37,
570,18,104,4,0,
570,18,154,1,13,
570,18,156,4,0,
570,18,164,4,0,
570,18,168,4,0,
570,18,182,4,0,
570,18,184,1,21,
570,18,185,1,17,
570,18,197,2,0,
570,18,207,4,0,
570,18,213,4,0,
570,18,214,4,0,
570,18,216,4,0,
570,18,218,4,0,
570,18,228,1,5,
570,18,237,4,0,
570,18,240,4,0,
570,18,241,4,0,
570,18,244,4,0,
570,18,247,4,0,
570,18,259,1,33,
570,18,259,4,0,
570,18,262,2,0,
570,18,263,4,0,
570,18,269,1,25,
570,18,269,4,0,
570,18,286,1,53,
570,18,289,2,0,
570,18,313,1,9,
570,18,326,2,0,
570,18,332,4,0,
570,18,347,4,0,
570,18,369,4,0,
570,18,371,4,0,
570,18,373,1,41,
570,18,373,4,0,
570,18,374,4,0,
570,18,383,2,0,
570,18,386,1,45,
570,18,389,2,0,
570,18,399,2,0,
570,18,399,4,0,
570,18,417,1,49,
570,18,445,2,0,
570,18,447,4,0,
570,18,492,1,29,
570,18,496,4,0,
570,18,539,1,57,
570,18,555,4,0,
570,18,590,4,0,
571,11,10,1,1,2
571,11,14,4,0,
571,11,15,4,0,
571,11,43,1,1,3
571,11,46,4,0,
571,11,53,4,0,
571,11,63,4,0,
571,11,91,4,0,
571,11,92,4,0,
571,11,97,1,39,
571,11,104,4,0,
571,11,154,1,13,
571,11,156,4,0,
571,11,164,4,0,
571,11,168,4,0,
571,11,182,4,0,
571,11,184,1,21,
571,11,185,1,17,
571,11,207,4,0,
571,11,213,4,0,
571,11,216,4,0,
571,11,218,4,0,
571,11,228,1,1,4
571,11,228,1,5,
571,11,237,4,0,
571,11,240,4,0,
571,11,241,4,0,
571,11,244,4,0,
571,11,247,4,0,
571,11,249,4,0,
571,11,259,1,34,
571,11,259,4,0,
571,11,263,4,0,
571,11,269,1,25,
571,11,269,4,0,
571,11,286,1,59,
571,11,332,4,0,
571,11,347,4,0,
571,11,369,1,1,1
571,11,369,4,0,
571,11,371,4,0,
571,11,373,1,44,
571,11,373,4,0,
571,11,374,4,0,
571,11,386,1,49,
571,11,400,1,30,
571,11,411,4,0,
571,11,416,4,0,
571,11,417,1,54,
571,11,421,4,0,
571,11,447,4,0,
571,11,468,1,1,5
571,11,468,1,9,
571,11,468,4,0,
571,11,490,4,0,
571,11,492,1,29,
571,11,496,4,0,
571,11,510,4,0,
571,11,514,4,0,
571,11,539,1,64,
571,11,555,4,0,
571,14,10,1,1,2
571,14,14,4,0,
571,14,15,4,0,
571,14,43,1,1,3
571,14,46,4,0,
571,14,53,4,0,
571,14,63,4,0,
571,14,67,3,0,
571,14,91,4,0,
571,14,92,4,0,
571,14,97,1,39,
571,14,104,4,0,
571,14,154,1,13,
571,14,156,4,0,
571,14,164,4,0,
571,14,168,4,0,
571,14,173,3,0,
571,14,180,3,0,
571,14,182,4,0,
571,14,184,1,21,
571,14,185,1,17,
571,14,207,4,0,
571,14,213,4,0,
571,14,214,3,0,
571,14,216,4,0,
571,14,218,4,0,
571,14,228,1,1,4
571,14,228,1,5,
571,14,237,4,0,
571,14,240,4,0,
571,14,241,4,0,
571,14,244,4,0,
571,14,247,4,0,
571,14,249,4,0,
571,14,253,3,0,
571,14,259,1,34,
571,14,259,4,0,
571,14,263,4,0,
571,14,269,1,25,
571,14,269,4,0,
571,14,271,3,0,
571,14,282,3,0,
571,14,286,1,59,
571,14,289,3,0,
571,14,304,3,0,
571,14,332,4,0,
571,14,340,3,0,
571,14,343,3,0,
571,14,347,4,0,
571,14,369,1,1,1
571,14,369,4,0,
571,14,371,4,0,
571,14,373,1,44,
571,14,373,4,0,
571,14,374,4,0,
571,14,386,1,49,
571,14,399,3,0,
571,14,400,1,30,
571,14,411,4,0,
571,14,416,4,0,
571,14,417,1,54,
571,14,421,4,0,
571,14,447,4,0,
571,14,468,1,1,5
571,14,468,1,9,
571,14,468,4,0,
571,14,490,4,0,
571,14,492,1,29,
571,14,492,3,0,
571,14,496,4,0,
571,14,510,4,0,
571,14,514,4,0,
571,14,539,1,64,
571,14,555,4,0,
571,15,10,1,1,4
571,15,14,4,0,
571,15,15,4,0,
571,15,43,1,1,5
571,15,46,4,0,
571,15,53,4,0,
571,15,63,4,0,
571,15,91,4,0,
571,15,92,4,0,
571,15,97,1,39,
571,15,104,4,0,
571,15,154,1,13,
571,15,156,4,0,
571,15,164,4,0,
571,15,168,4,0,
571,15,182,4,0,
571,15,184,1,21,
571,15,185,1,17,
571,15,207,4,0,
571,15,213,4,0,
571,15,214,4,0,
571,15,216,4,0,
571,15,218,4,0,
571,15,228,1,1,6
571,15,228,1,5,
571,15,237,4,0,
571,15,240,4,0,
571,15,241,4,0,
571,15,244,4,0,
571,15,247,4,0,
571,15,249,4,0,
571,15,259,1,34,
571,15,259,4,0,
571,15,263,4,0,
571,15,269,1,25,
571,15,269,4,0,
571,15,286,1,1,2
571,15,286,1,59,
571,15,332,4,0,
571,15,347,4,0,
571,15,369,1,1,3
571,15,369,4,0,
571,15,371,4,0,
571,15,373,1,44,
571,15,373,4,0,
571,15,374,4,0,
571,15,386,1,49,
571,15,399,4,0,
571,15,400,1,30,
571,15,411,4,0,
571,15,416,4,0,
571,15,417,1,54,
571,15,421,4,0,
571,15,447,4,0,
571,15,468,1,1,7
571,15,468,1,9,
571,15,468,4,0,
571,15,490,4,0,
571,15,492,1,29,
571,15,496,4,0,
571,15,510,4,0,
571,15,514,4,0,
571,15,539,1,1,1
571,15,539,1,64,
571,15,555,4,0,
571,15,590,4,0,
571,16,10,1,1,4
571,16,14,4,0,
571,16,15,4,0,
571,16,43,1,1,5
571,16,46,4,0,
571,16,53,4,0,
571,16,63,4,0,
571,16,67,3,0,
571,16,91,4,0,
571,16,92,4,0,
571,16,97,1,39,1
571,16,104,4,0,
571,16,154,1,13,1
571,16,156,4,0,
571,16,164,4,0,
571,16,168,4,0,
571,16,173,3,0,
571,16,180,3,0,
571,16,182,4,0,
571,16,184,1,21,1
571,16,185,1,17,1
571,16,207,4,0,
571,16,213,4,0,
571,16,214,4,0,
571,16,216,4,0,
571,16,218,4,0,
571,16,228,1,1,6
571,16,228,1,5,1
571,16,237,4,0,
571,16,240,4,0,
571,16,241,4,0,
571,16,244,4,0,
571,16,247,4,0,
571,16,249,4,0,
571,16,253,3,0,
571,16,259,1,34,1
571,16,259,4,0,
571,16,263,4,0,
571,16,269,1,25,1
571,16,269,4,0,
571,16,271,3,0,
571,16,282,3,0,
571,16,286,1,1,2
571,16,286,1,59,1
571,16,289,3,0,
571,16,290,4,0,
571,16,304,3,0,
571,16,332,4,0,
571,16,340,3,0,
571,16,343,3,0,
571,16,347,4,0,
571,16,369,1,1,3
571,16,369,4,0,
571,16,371,4,0,
571,16,373,1,44,1
571,16,373,4,0,
571,16,374,4,0,
571,16,386,1,49,1
571,16,399,4,0,
571,16,400,1,30,1
571,16,411,4,0,
571,16,416,4,0,
571,16,417,1,54,1
571,16,421,4,0,
571,16,447,4,0,
571,16,468,1,1,7
571,16,468,1,9,1
571,16,468,4,0,
571,16,490,4,0,
571,16,492,1,29,1
571,16,492,3,0,
571,16,496,4,0,
571,16,510,4,0,
571,16,514,4,0,
571,16,539,1,1,1
571,16,539,1,64,1
571,16,555,4,0,
571,16,590,4,0,
571,17,10,1,1,5
571,17,14,4,0,
571,17,43,1,1,6
571,17,46,4,0,
571,17,53,4,0,
571,17,63,4,0,
571,17,92,4,0,
571,17,97,1,39,
571,17,104,4,0,
571,17,154,1,13,
571,17,156,4,0,
571,17,164,4,0,
571,17,168,4,0,
571,17,182,4,0,
571,17,184,1,21,
571,17,185,1,17,
571,17,207,4,0,
571,17,213,4,0,
571,17,214,4,0,
571,17,216,4,0,
571,17,218,4,0,
571,17,228,1,1,7
571,17,228,1,5,
571,17,237,4,0,
571,17,240,4,0,
571,17,241,4,0,
571,17,244,4,0,
571,17,247,4,0,
571,17,259,1,34,
571,17,259,4,0,
571,17,263,4,0,
571,17,269,1,25,
571,17,269,4,0,
571,17,286,1,1,3
571,17,286,1,59,
571,17,332,4,0,
571,17,347,4,0,
571,17,369,1,1,4
571,17,369,4,0,
571,17,371,4,0,
571,17,373,1,44,
571,17,373,4,0,
571,17,374,4,0,
571,17,386,1,49,
571,17,399,4,0,
571,17,400,1,0,
571,17,400,1,1,1
571,17,411,4,0,
571,17,416,4,0,
571,17,417,1,54,
571,17,421,4,0,
571,17,447,4,0,
571,17,468,1,1,8
571,17,468,1,9,
571,17,490,4,0,
571,17,492,1,29,
571,17,496,4,0,
571,17,539,1,1,2
571,17,539,1,64,
571,17,555,4,0,
571,17,590,4,0,
571,18,10,1,1,5
571,18,14,4,0,
571,18,43,1,1,6
571,18,46,4,0,
571,18,53,4,0,
571,18,63,4,0,
571,18,92,4,0,
571,18,97,1,39,
571,18,104,4,0,
571,18,154,1,13,
571,18,156,4,0,
571,18,164,4,0,
571,18,168,4,0,
571,18,182,4,0,
571,18,184,1,21,
571,18,185,1,17,
571,18,207,4,0,
571,18,213,4,0,
571,18,214,4,0,
571,18,216,4,0,
571,18,218,4,0,
571,18,228,1,1,7
571,18,228,1,5,
571,18,237,4,0,
571,18,240,4,0,
571,18,241,4,0,
571,18,244,4,0,
571,18,247,4,0,
571,18,259,1,34,
571,18,259,4,0,
571,18,263,4,0,
571,18,269,1,25,
571,18,269,4,0,
571,18,286,1,1,3
571,18,286,1,59,
571,18,332,4,0,
571,18,347,4,0,
571,18,369,1,1,4
571,18,369,4,0,
571,18,371,4,0,
571,18,373,1,44,
571,18,373,4,0,
571,18,374,4,0,
571,18,386,1,49,
571,18,399,4,0,
571,18,400,1,0,
571,18,400,1,1,1
571,18,411,4,0,
571,18,416,4,0,
571,18,417,1,54,
571,18,421,4,0,
571,18,447,4,0,
571,18,468,1,1,8
571,18,468,1,9,
571,18,490,4,0,
571,18,492,1,29,
571,18,496,4,0,
571,18,539,1,1,2
571,18,539,1,64,
571,18,555,4,0,
571,18,590,4,0,
572,11,1,1,1,
572,11,3,1,13,
572,11,21,1,37,
572,11,39,2,0,
572,11,45,1,3,
572,11,47,1,21,
572,11,85,4,0,
572,11,86,4,0,
572,11,91,4,0,
572,11,92,4,0,
572,11,104,4,0,
572,11,129,1,19,
572,11,156,4,0,
572,11,164,4,0,
572,11,168,4,0,
572,11,175,2,0,
572,11,182,4,0,
572,11,189,2,0,
572,11,203,2,0,
572,11,204,1,27,
572,11,207,4,0,
572,11,213,4,0,
572,11,214,2,0,
572,11,216,4,0,
572,11,218,4,0,
572,11,219,4,0,
572,11,227,1,15,
572,11,231,2,0,
572,11,237,4,0,
572,11,240,4,0,
572,11,241,4,0,
572,11,263,4,0,
572,11,270,1,7,
572,11,282,2,0,
572,11,304,1,43,
572,11,313,2,0,
572,11,321,1,9,
572,11,347,4,0,
572,11,358,1,31,
572,11,369,4,0,
572,11,374,4,0,
572,11,387,1,45,
572,11,401,2,0,
572,11,445,1,39,
572,11,447,4,0,
572,11,495,1,49,
572,11,496,4,0,
572,11,497,1,33,
572,11,497,4,0,
572,11,514,4,0,
572,11,526,4,0,
572,11,541,1,25,
572,14,1,1,1,
572,14,3,1,13,
572,14,21,1,37,
572,14,39,2,0,
572,14,45,1,3,
572,14,47,1,21,
572,14,85,4,0,
572,14,86,4,0,
572,14,91,4,0,
572,14,92,4,0,
572,14,104,4,0,
572,14,129,1,19,
572,14,156,4,0,
572,14,164,4,0,
572,14,168,4,0,
572,14,173,3,0,
572,14,175,2,0,
572,14,182,4,0,
572,14,189,2,0,
572,14,203,2,0,
572,14,204,1,27,
572,14,207,4,0,
572,14,213,4,0,
572,14,214,2,0,
572,14,214,3,0,
572,14,216,4,0,
572,14,218,4,0,
572,14,219,4,0,
572,14,227,1,15,
572,14,231,2,0,
572,14,231,3,0,
572,14,237,4,0,
572,14,240,4,0,
572,14,241,4,0,
572,14,253,3,0,
572,14,263,4,0,
572,14,270,1,7,
572,14,270,3,0,
572,14,282,2,0,
572,14,282,3,0,
572,14,304,1,43,
572,14,304,3,0,
572,14,313,2,0,
572,14,321,1,9,
572,14,343,3,0,
572,14,347,4,0,
572,14,358,1,31,
572,14,369,4,0,
572,14,374,4,0,
572,14,387,1,45,
572,14,387,3,0,
572,14,401,2,0,
572,14,401,3,0,
572,14,402,3,0,
572,14,441,3,0,
572,14,445,1,39,
572,14,447,4,0,
572,14,495,1,49,
572,14,495,3,0,
572,14,496,4,0,
572,14,497,1,33,
572,14,497,4,0,
572,14,514,4,0,
572,14,526,4,0,
572,14,541,1,25,
572,15,1,1,1,
572,15,3,1,13,
572,15,21,1,37,
572,15,39,2,0,
572,15,47,1,21,
572,15,85,4,0,
572,15,86,4,0,
572,15,91,4,0,
572,15,92,4,0,
572,15,104,4,0,
572,15,129,1,19,
572,15,156,4,0,
572,15,164,4,0,
572,15,168,4,0,
572,15,175,2,0,
572,15,182,4,0,
572,15,189,2,0,
572,15,203,2,0,
572,15,204,1,27,
572,15,207,4,0,
572,15,213,4,0,
572,15,214,2,0,
572,15,214,4,0,
572,15,216,4,0,
572,15,218,4,0,
572,15,219,4,0,
572,15,227,1,15,
572,15,231,2,0,
572,15,237,4,0,
572,15,240,4,0,
572,15,241,4,0,
572,15,263,4,0,
572,15,270,1,7,
572,15,282,2,0,
572,15,304,1,43,
572,15,313,2,0,
572,15,321,1,9,
572,15,347,4,0,
572,15,358,1,31,
572,15,369,4,0,
572,15,374,4,0,
572,15,387,1,45,
572,15,401,2,0,
572,15,445,1,39,
572,15,447,4,0,
572,15,495,1,49,
572,15,496,4,0,
572,15,497,1,33,
572,15,497,4,0,
572,15,514,4,0,
572,15,541,1,25,
572,15,590,4,0,
572,15,605,4,0,
572,15,608,1,3,
572,16,1,1,1,1
572,16,3,1,13,1
572,16,21,1,37,1
572,16,39,2,0,
572,16,47,1,21,1
572,16,85,4,0,
572,16,86,4,0,
572,16,91,4,0,
572,16,92,4,0,
572,16,104,4,0,
572,16,129,1,19,1
572,16,156,4,0,
572,16,164,4,0,
572,16,168,4,0,
572,16,173,3,0,
572,16,175,2,0,
572,16,182,4,0,
572,16,189,2,0,
572,16,203,2,0,
572,16,204,1,27,1
572,16,207,4,0,
572,16,213,4,0,
572,16,214,2,0,
572,16,214,4,0,
572,16,216,4,0,
572,16,218,4,0,
572,16,219,4,0,
572,16,227,1,15,1
572,16,231,2,0,
572,16,231,3,0,
572,16,237,4,0,
572,16,240,4,0,
572,16,241,4,0,
572,16,253,3,0,
572,16,263,4,0,
572,16,270,1,7,1
572,16,270,3,0,
572,16,282,2,0,
572,16,282,3,0,
572,16,290,4,0,
572,16,304,1,43,1
572,16,304,3,0,
572,16,313,2,0,
572,16,321,1,9,1
572,16,343,3,0,
572,16,347,4,0,
572,16,351,3,0,
572,16,358,1,31,1
572,16,369,4,0,
572,16,374,4,0,
572,16,387,1,45,1
572,16,387,3,0,
572,16,401,2,0,
572,16,401,3,0,
572,16,402,3,0,
572,16,441,3,0,
572,16,445,1,39,1
572,16,447,4,0,
572,16,495,1,49,1
572,16,495,3,0,
572,16,496,4,0,
572,16,497,1,33,1
572,16,497,4,0,
572,16,514,4,0,
572,16,541,1,25,1
572,16,590,4,0,
572,16,605,4,0,
572,16,608,1,3,1
572,17,1,1,1,
572,17,3,1,13,
572,17,21,1,37,
572,17,39,2,0,
572,17,47,1,21,
572,17,85,4,0,
572,17,86,4,0,
572,17,92,4,0,
572,17,104,4,0,
572,17,129,1,19,
572,17,156,4,0,
572,17,164,4,0,
572,17,168,4,0,
572,17,175,2,0,
572,17,182,4,0,
572,17,189,2,0,
572,17,203,2,0,
572,17,204,1,27,
572,17,207,4,0,
572,17,213,4,0,
572,17,214,2,0,
572,17,214,4,0,
572,17,216,4,0,
572,17,218,4,0,
572,17,219,4,0,
572,17,227,1,15,
572,17,231,2,0,
572,17,237,4,0,
572,17,240,4,0,
572,17,241,4,0,
572,17,263,4,0,
572,17,270,1,7,
572,17,282,2,0,
572,17,304,1,43,
572,17,313,2,0,
572,17,321,1,9,
572,17,347,4,0,
572,17,358,1,31,
572,17,369,4,0,
572,17,374,4,0,
572,17,387,1,45,
572,17,401,2,0,
572,17,445,1,39,
572,17,447,4,0,
572,17,495,1,49,
572,17,496,4,0,
572,17,497,1,33,
572,17,497,4,0,
572,17,526,4,0,
572,17,541,1,25,
572,17,590,4,0,
572,17,605,4,0,
572,17,608,1,3,
572,18,1,1,1,
572,18,3,1,13,
572,18,21,1,37,
572,18,39,2,0,
572,18,47,1,21,
572,18,85,4,0,
572,18,86,4,0,
572,18,92,4,0,
572,18,104,4,0,
572,18,129,1,19,
572,18,156,4,0,
572,18,164,4,0,
572,18,168,4,0,
572,18,175,2,0,
572,18,182,4,0,
572,18,189,2,0,
572,18,203,2,0,
572,18,204,1,27,
572,18,207,4,0,
572,18,213,4,0,
572,18,214,2,0,
572,18,214,4,0,
572,18,216,4,0,
572,18,218,4,0,
572,18,219,4,0,
572,18,227,1,15,
572,18,231,2,0,
572,18,237,4,0,
572,18,240,4,0,
572,18,241,4,0,
572,18,263,4,0,
572,18,270,1,7,
572,18,282,2,0,
572,18,304,1,43,
572,18,313,2,0,
572,18,321,1,9,
572,18,347,4,0,
572,18,358,1,31,
572,18,369,4,0,
572,18,374,4,0,
572,18,387,1,45,
572,18,401,2,0,
572,18,445,1,39,
572,18,447,4,0,
572,18,495,1,49,
572,18,496,4,0,
572,18,497,1,33,
572,18,497,4,0,
572,18,526,4,0,
572,18,541,1,25,
572,18,590,4,0,
572,18,605,4,0,
572,18,608,1,3,
573,11,47,1,1,5
573,11,63,4,0,
573,11,85,4,0,
573,11,86,4,0,
573,11,87,4,0,
573,11,91,4,0,
573,11,92,4,0,
573,11,104,4,0,
573,11,113,4,0,
573,11,156,4,0,
573,11,164,4,0,
573,11,182,4,0,
573,11,207,4,0,
573,11,213,4,0,
573,11,216,4,0,
573,11,218,4,0,
573,11,219,4,0,
573,11,237,4,0,
573,11,240,4,0,
573,11,241,4,0,
573,11,263,4,0,
573,11,270,1,1,3
573,11,321,1,1,4
573,11,331,1,1,1
573,11,347,4,0,
573,11,350,1,1,2
573,11,369,4,0,
573,11,374,4,0,
573,11,411,4,0,
573,11,416,4,0,
573,11,447,4,0,
573,11,496,4,0,
573,11,497,4,0,
573,11,514,4,0,
573,11,526,4,0,
573,11,541,1,1,6
573,14,47,1,1,5
573,14,63,4,0,
573,14,85,4,0,
573,14,86,4,0,
573,14,87,4,0,
573,14,91,4,0,
573,14,92,4,0,
573,14,104,4,0,
573,14,113,4,0,
573,14,156,4,0,
573,14,164,4,0,
573,14,168,4,0,
573,14,173,3,0,
573,14,182,4,0,
573,14,207,4,0,
573,14,213,4,0,
573,14,214,3,0,
573,14,216,4,0,
573,14,218,4,0,
573,14,219,4,0,
573,14,231,3,0,
573,14,237,4,0,
573,14,240,4,0,
573,14,241,4,0,
573,14,253,3,0,
573,14,263,4,0,
573,14,270,1,1,3
573,14,270,3,0,
573,14,282,3,0,
573,14,304,3,0,
573,14,321,1,1,4
573,14,331,1,1,1
573,14,343,3,0,
573,14,347,4,0,
573,14,350,1,1,2
573,14,369,4,0,
573,14,374,4,0,
573,14,387,3,0,
573,14,401,3,0,
573,14,402,3,0,
573,14,411,4,0,
573,14,416,4,0,
573,14,441,3,0,
573,14,447,4,0,
573,14,495,3,0,
573,14,496,4,0,
573,14,497,4,0,
573,14,514,4,0,
573,14,526,4,0,
573,14,541,1,1,6
573,15,47,1,1,5
573,15,63,4,0,
573,15,85,4,0,
573,15,86,4,0,
573,15,87,4,0,
573,15,91,4,0,
573,15,92,4,0,
573,15,104,4,0,
573,15,113,4,0,
573,15,156,4,0,
573,15,164,4,0,
573,15,168,4,0,
573,15,182,4,0,
573,15,207,4,0,
573,15,213,4,0,
573,15,214,4,0,
573,15,216,4,0,
573,15,218,4,0,
573,15,219,4,0,
573,15,237,4,0,
573,15,240,4,0,
573,15,241,4,0,
573,15,263,4,0,
573,15,270,1,1,3
573,15,321,1,1,4
573,15,331,1,1,1
573,15,347,4,0,
573,15,350,1,1,2
573,15,369,4,0,
573,15,374,4,0,
573,15,411,4,0,
573,15,416,4,0,
573,15,447,4,0,
573,15,496,4,0,
573,15,497,4,0,
573,15,514,4,0,
573,15,541,1,1,6
573,15,590,4,0,
573,15,605,4,0,
573,16,47,1,1,5
573,16,63,4,0,
573,16,85,4,0,
573,16,86,4,0,
573,16,87,4,0,
573,16,91,4,0,
573,16,92,4,0,
573,16,104,4,0,
573,16,113,4,0,
573,16,156,4,0,
573,16,164,4,0,
573,16,168,4,0,
573,16,173,3,0,
573,16,182,4,0,
573,16,207,4,0,
573,16,213,4,0,
573,16,214,4,0,
573,16,216,4,0,
573,16,218,4,0,
573,16,219,4,0,
573,16,231,3,0,
573,16,237,4,0,
573,16,240,4,0,
573,16,241,4,0,
573,16,253,3,0,
573,16,263,4,0,
573,16,270,1,1,3
573,16,270,3,0,
573,16,282,3,0,
573,16,290,4,0,
573,16,304,3,0,
573,16,321,1,1,4
573,16,331,1,1,1
573,16,343,3,0,
573,16,347,4,0,
573,16,350,1,1,2
573,16,351,3,0,
573,16,369,4,0,
573,16,374,4,0,
573,16,387,3,0,
573,16,401,3,0,
573,16,402,3,0,
573,16,411,4,0,
573,16,416,4,0,
573,16,441,3,0,
573,16,447,4,0,
573,16,495,3,0,
573,16,496,4,0,
573,16,497,4,0,
573,16,514,4,0,
573,16,541,1,1,6
573,16,590,4,0,
573,16,605,4,0,
573,17,47,1,1,5
573,17,63,4,0,
573,17,85,4,0,
573,17,86,4,0,
573,17,87,4,0,
573,17,92,4,0,
573,17,104,4,0,
573,17,113,4,0,
573,17,156,4,0,
573,17,164,4,0,
573,17,168,4,0,
573,17,182,4,0,
573,17,207,4,0,
573,17,213,4,0,
573,17,214,4,0,
573,17,216,4,0,
573,17,218,4,0,
573,17,219,4,0,
573,17,237,4,0,
573,17,240,4,0,
573,17,241,4,0,
573,17,263,4,0,
573,17,270,1,1,3
573,17,321,1,1,4
573,17,331,1,1,1
573,17,347,4,0,
573,17,350,1,1,2
573,17,369,4,0,
573,17,374,4,0,
573,17,411,4,0,
573,17,416,4,0,
573,17,447,4,0,
573,17,496,4,0,
573,17,497,4,0,
573,17,526,4,0,
573,17,541,1,1,6
573,17,590,4,0,
573,17,605,4,0,
573,18,47,1,1,5
573,18,63,4,0,
573,18,85,4,0,
573,18,86,4,0,
573,18,87,4,0,
573,18,92,4,0,
573,18,104,4,0,
573,18,113,4,0,
573,18,156,4,0,
573,18,164,4,0,
573,18,168,4,0,
573,18,182,4,0,
573,18,207,4,0,
573,18,213,4,0,
573,18,214,4,0,
573,18,216,4,0,
573,18,218,4,0,
573,18,219,4,0,
573,18,237,4,0,
573,18,240,4,0,
573,18,241,4,0,
573,18,263,4,0,
573,18,270,1,1,3
573,18,321,1,1,4
573,18,331,1,1,1
573,18,347,4,0,
573,18,350,1,1,2
573,18,369,4,0,
573,18,374,4,0,
573,18,411,4,0,
573,18,416,4,0,
573,18,447,4,0,
573,18,496,4,0,
573,18,497,4,0,
573,18,526,4,0,
573,18,541,1,1,6
573,18,590,4,0,
573,18,605,4,0,
574,11,1,1,1,
574,11,3,1,14,
574,11,60,1,16,
574,11,85,4,0,
574,11,86,4,0,
574,11,92,4,0,
574,11,93,1,3,
574,11,94,1,37,
574,11,94,4,0,
574,11,104,4,0,
574,11,113,4,0,
574,11,115,4,0,
574,11,138,4,0,
574,11,148,4,0,
574,11,156,4,0,
574,11,157,4,0,
574,11,164,4,0,
574,11,168,4,0,
574,11,182,4,0,
574,11,185,1,24,
574,11,204,1,46,
574,11,207,4,0,
574,11,212,2,0,
574,11,213,4,0,
574,11,216,4,0,
574,11,218,4,0,
574,11,219,4,0,
574,11,237,4,0,
574,11,240,4,0,
574,11,243,2,0,
574,11,244,4,0,
574,11,247,4,0,
574,11,248,1,31,
574,11,253,2,0,
574,11,259,4,0,
574,11,260,1,28,
574,11,263,4,0,
574,11,269,4,0,
574,11,313,1,10,
574,11,317,4,0,
574,11,321,1,7,
574,11,347,4,0,
574,11,357,2,0,
574,11,371,4,0,
574,11,373,1,19,
574,11,373,4,0,
574,11,374,4,0,
574,11,377,1,33,
574,11,399,2,0,
574,11,412,4,0,
574,11,433,4,0,
574,11,445,2,0,
574,11,447,4,0,
574,11,451,4,0,
574,11,473,1,25,
574,11,473,4,0,
574,11,477,1,40,
574,11,477,4,0,
574,11,478,1,48,
574,11,496,4,0,
574,14,1,1,1,
574,14,3,1,14,
574,14,60,1,16,
574,14,85,4,0,
574,14,86,4,0,
574,14,92,4,0,
574,14,93,1,3,
574,14,94,1,37,
574,14,94,4,0,
574,14,104,4,0,
574,14,113,4,0,
574,14,115,4,0,
574,14,138,4,0,
574,14,148,4,0,
574,14,156,4,0,
574,14,157,4,0,
574,14,164,4,0,
574,14,168,4,0,
574,14,173,3,0,
574,14,182,4,0,
574,14,185,1,24,
574,14,204,1,46,
574,14,207,4,0,
574,14,212,2,0,
574,14,213,4,0,
574,14,214,3,0,
574,14,215,3,0,
574,14,216,4,0,
574,14,218,4,0,
574,14,219,4,0,
574,14,237,4,0,
574,14,240,4,0,
574,14,243,2,0,
574,14,244,4,0,
574,14,247,4,0,
574,14,248,1,31,
574,14,253,2,0,
574,14,253,3,0,
574,14,259,4,0,
574,14,260,1,28,
574,14,263,4,0,
574,14,269,4,0,
574,14,270,3,0,
574,14,271,3,0,
574,14,272,3,0,
574,14,277,3,0,
574,14,278,3,0,
574,14,285,3,0,
574,14,289,3,0,
574,14,313,1,10,
574,14,317,4,0,
574,14,321,1,7,
574,14,324,3,0,
574,14,343,3,0,
574,14,347,4,0,
574,14,356,3,0,
574,14,357,2,0,
574,14,371,4,0,
574,14,373,1,19,
574,14,373,4,0,
574,14,374,4,0,
574,14,377,1,33,
574,14,399,2,0,
574,14,412,4,0,
574,14,428,3,0,
574,14,433,4,0,
574,14,445,2,0,
574,14,447,4,0,
574,14,451,4,0,
574,14,473,1,25,
574,14,473,4,0,
574,14,477,1,40,
574,14,477,4,0,
574,14,478,1,48,
574,14,478,3,0,
574,14,492,3,0,
574,14,496,4,0,
574,15,1,1,1,
574,15,3,1,14,
574,15,60,1,16,
574,15,85,4,0,
574,15,86,4,0,
574,15,92,4,0,
574,15,93,1,3,
574,15,94,1,37,
574,15,94,4,0,
574,15,104,4,0,
574,15,113,4,0,
574,15,115,4,0,
574,15,138,4,0,
574,15,148,4,0,
574,15,156,4,0,
574,15,157,4,0,
574,15,164,4,0,
574,15,168,4,0,
574,15,182,4,0,
574,15,185,1,24,
574,15,204,1,46,
574,15,207,4,0,
574,15,212,2,0,
574,15,213,4,0,
574,15,214,4,0,
574,15,216,4,0,
574,15,218,4,0,
574,15,219,4,0,
574,15,237,4,0,
574,15,240,4,0,
574,15,243,2,0,
574,15,244,4,0,
574,15,247,4,0,
574,15,248,1,31,
574,15,253,2,0,
574,15,259,4,0,
574,15,260,1,28,
574,15,263,4,0,
574,15,269,4,0,
574,15,313,1,10,
574,15,317,4,0,
574,15,321,1,7,
574,15,347,4,0,
574,15,357,2,0,
574,15,371,4,0,
574,15,373,1,19,
574,15,373,4,0,
574,15,374,4,0,
574,15,377,1,33,
574,15,399,2,0,
574,15,412,4,0,
574,15,433,4,0,
574,15,445,2,0,
574,15,447,4,0,
574,15,451,4,0,
574,15,473,1,25,
574,15,473,4,0,
574,15,477,1,40,
574,15,478,1,48,
574,15,496,4,0,
574,15,505,2,0,
574,15,589,1,8,
574,15,590,4,0,
574,16,1,1,1,1
574,16,3,1,14,1
574,16,60,1,16,1
574,16,85,4,0,
574,16,86,4,0,
574,16,92,4,0,
574,16,93,1,3,1
574,16,94,1,37,1
574,16,94,4,0,
574,16,104,4,0,
574,16,113,4,0,
574,16,115,4,0,
574,16,138,4,0,
574,16,148,4,0,
574,16,156,4,0,
574,16,157,4,0,
574,16,164,4,0,
574,16,168,4,0,
574,16,173,3,0,
574,16,182,4,0,
574,16,185,1,24,1
574,16,204,1,46,1
574,16,207,4,0,
574,16,212,2,0,
574,16,213,4,0,
574,16,214,4,0,
574,16,215,3,0,
574,16,216,4,0,
574,16,218,4,0,
574,16,219,4,0,
574,16,237,4,0,
574,16,240,4,0,
574,16,243,2,0,
574,16,244,4,0,
574,16,247,4,0,
574,16,248,1,31,1
574,16,253,2,0,
574,16,253,3,0,
574,16,259,4,0,
574,16,260,1,28,1
574,16,263,4,0,
574,16,269,4,0,
574,16,270,3,0,
574,16,271,3,0,
574,16,272,3,0,
574,16,277,3,0,
574,16,278,3,0,
574,16,285,3,0,
574,16,289,3,0,
574,16,290,4,0,
574,16,313,1,10,1
574,16,317,4,0,
574,16,321,1,7,1
574,16,324,3,0,
574,16,343,3,0,
574,16,347,4,0,
574,16,351,3,0,
574,16,356,3,0,
574,16,357,2,0,
574,16,371,4,0,
574,16,373,1,19,1
574,16,373,4,0,
574,16,374,4,0,
574,16,377,1,33,1
574,16,399,2,0,
574,16,399,4,0,
574,16,412,4,0,
574,16,428,3,0,
574,16,433,4,0,
574,16,445,2,0,
574,16,447,4,0,
574,16,451,4,0,
574,16,473,1,25,1
574,16,473,4,0,
574,16,477,1,40,1
574,16,478,1,48,1
574,16,478,3,0,
574,16,492,3,0,
574,16,496,4,0,
574,16,505,2,0,
574,16,589,1,8,1
574,16,590,4,0,
574,17,1,1,1,
574,17,3,1,14,
574,17,60,1,16,
574,17,85,4,0,
574,17,86,4,0,
574,17,92,4,0,
574,17,93,1,3,
574,17,94,1,37,
574,17,94,4,0,
574,17,104,4,0,
574,17,113,4,0,
574,17,115,4,0,
574,17,138,4,0,
574,17,156,4,0,
574,17,157,4,0,
574,17,164,4,0,
574,17,168,4,0,
574,17,182,4,0,
574,17,185,1,24,
574,17,204,1,46,
574,17,207,4,0,
574,17,212,2,0,
574,17,213,4,0,
574,17,214,4,0,
574,17,216,4,0,
574,17,218,4,0,
574,17,219,4,0,
574,17,237,4,0,
574,17,240,4,0,
574,17,243,2,0,
574,17,244,4,0,
574,17,247,4,0,
574,17,248,1,31,
574,17,253,2,0,
574,17,259,4,0,
574,17,260,1,28,
574,17,263,4,0,
574,17,269,4,0,
574,17,313,1,10,
574,17,317,4,0,
574,17,321,1,7,
574,17,347,4,0,
574,17,357,2,0,
574,17,371,4,0,
574,17,373,1,19,
574,17,373,4,0,
574,17,374,4,0,
574,17,377,1,33,
574,17,399,2,0,
574,17,399,4,0,
574,17,412,4,0,
574,17,433,4,0,
574,17,445,2,0,
574,17,447,4,0,
574,17,451,4,0,
574,17,473,1,25,
574,17,473,4,0,
574,17,477,1,40,
574,17,478,1,48,
574,17,496,4,0,
574,17,505,2,0,
574,17,589,1,8,
574,17,590,4,0,
574,18,1,1,1,
574,18,3,1,14,
574,18,60,1,16,
574,18,85,4,0,
574,18,86,4,0,
574,18,92,4,0,
574,18,93,1,3,
574,18,94,1,37,
574,18,94,4,0,
574,18,104,4,0,
574,18,113,4,0,
574,18,115,4,0,
574,18,138,4,0,
574,18,156,4,0,
574,18,157,4,0,
574,18,164,4,0,
574,18,168,4,0,
574,18,182,4,0,
574,18,185,1,24,
574,18,204,1,46,
574,18,207,4,0,
574,18,212,2,0,
574,18,213,4,0,
574,18,214,4,0,
574,18,216,4,0,
574,18,218,4,0,
574,18,219,4,0,
574,18,237,4,0,
574,18,240,4,0,
574,18,243,2,0,
574,18,244,4,0,
574,18,247,4,0,
574,18,248,1,31,
574,18,253,2,0,
574,18,259,4,0,
574,18,260,1,28,
574,18,263,4,0,
574,18,269,4,0,
574,18,313,1,10,
574,18,317,4,0,
574,18,321,1,7,
574,18,347,4,0,
574,18,357,2,0,
574,18,371,4,0,
574,18,373,1,19,
574,18,373,4,0,
574,18,374,4,0,
574,18,377,1,33,
574,18,399,2,0,
574,18,399,4,0,
574,18,412,4,0,
574,18,433,4,0,
574,18,445,2,0,
574,18,447,4,0,
574,18,451,4,0,
574,18,473,1,25,
574,18,473,4,0,
574,18,477,1,40,
574,18,478,1,48,
574,18,496,4,0,
574,18,505,2,0,
574,18,589,1,8,
574,18,590,4,0,
575,11,1,1,1,1
575,11,3,1,14,
575,11,60,1,16,
575,11,85,4,0,
575,11,86,4,0,
575,11,92,4,0,
575,11,93,1,1,2
575,11,93,1,3,
575,11,94,1,39,
575,11,94,4,0,
575,11,104,4,0,
575,11,113,4,0,
575,11,115,4,0,
575,11,138,4,0,
575,11,148,4,0,
575,11,156,4,0,
575,11,157,4,0,
575,11,164,4,0,
575,11,168,4,0,
575,11,182,4,0,
575,11,185,1,24,
575,11,204,1,50,
575,11,207,4,0,
575,11,213,4,0,
575,11,216,4,0,
575,11,218,4,0,
575,11,219,4,0,
575,11,237,4,0,
575,11,240,4,0,
575,11,244,4,0,
575,11,247,4,0,
575,11,248,1,31,
575,11,259,4,0,
575,11,260,1,28,
575,11,263,4,0,
575,11,269,4,0,
575,11,313,1,1,4
575,11,313,1,10,
575,11,317,4,0,
575,11,321,1,1,3
575,11,321,1,7,
575,11,347,4,0,
575,11,371,4,0,
575,11,373,1,19,
575,11,373,4,0,
575,11,374,4,0,
575,11,377,1,34,
575,11,412,4,0,
575,11,433,4,0,
575,11,447,4,0,
575,11,451,4,0,
575,11,473,1,25,
575,11,473,4,0,
575,11,477,1,43,
575,11,477,4,0,
575,11,478,1,53,
575,11,496,4,0,
575,14,1,1,1,1
575,14,3,1,14,
575,14,60,1,16,
575,14,85,4,0,
575,14,86,4,0,
575,14,92,4,0,
575,14,93,1,1,2
575,14,93,1,3,
575,14,94,1,39,
575,14,94,4,0,
575,14,104,4,0,
575,14,113,4,0,
575,14,115,4,0,
575,14,138,4,0,
575,14,148,4,0,
575,14,156,4,0,
575,14,157,4,0,
575,14,164,4,0,
575,14,168,4,0,
575,14,173,3,0,
575,14,182,4,0,
575,14,185,1,24,
575,14,204,1,50,
575,14,207,4,0,
575,14,213,4,0,
575,14,214,3,0,
575,14,215,3,0,
575,14,216,4,0,
575,14,218,4,0,
575,14,219,4,0,
575,14,237,4,0,
575,14,240,4,0,
575,14,244,4,0,
575,14,247,4,0,
575,14,248,1,31,
575,14,253,3,0,
575,14,259,4,0,
575,14,260,1,28,
575,14,263,4,0,
575,14,269,4,0,
575,14,270,3,0,
575,14,271,3,0,
575,14,272,3,0,
575,14,277,3,0,
575,14,278,3,0,
575,14,285,3,0,
575,14,289,3,0,
575,14,313,1,1,4
575,14,313,1,10,
575,14,317,4,0,
575,14,321,1,1,3
575,14,321,1,7,
575,14,324,3,0,
575,14,343,3,0,
575,14,347,4,0,
575,14,356,3,0,
575,14,371,4,0,
575,14,373,1,19,
575,14,373,4,0,
575,14,374,4,0,
575,14,377,1,34,
575,14,412,4,0,
575,14,428,3,0,
575,14,433,4,0,
575,14,447,4,0,
575,14,451,4,0,
575,14,473,1,25,
575,14,473,4,0,
575,14,477,1,43,
575,14,477,4,0,
575,14,478,1,53,
575,14,478,3,0,
575,14,492,3,0,
575,14,496,4,0,
575,15,1,1,1,1
575,15,3,1,14,
575,15,60,1,16,
575,15,85,4,0,
575,15,86,4,0,
575,15,92,4,0,
575,15,93,1,1,2
575,15,93,1,3,
575,15,94,1,39,
575,15,94,4,0,
575,15,104,4,0,
575,15,113,4,0,
575,15,115,4,0,
575,15,138,4,0,
575,15,148,4,0,
575,15,156,4,0,
575,15,157,4,0,
575,15,164,4,0,
575,15,168,4,0,
575,15,182,4,0,
575,15,185,1,24,
575,15,204,1,50,
575,15,207,4,0,
575,15,213,4,0,
575,15,214,4,0,
575,15,216,4,0,
575,15,218,4,0,
575,15,219,4,0,
575,15,237,4,0,
575,15,240,4,0,
575,15,244,4,0,
575,15,247,4,0,
575,15,248,1,31,
575,15,259,4,0,
575,15,260,1,28,
575,15,263,4,0,
575,15,269,4,0,
575,15,313,1,10,
575,15,317,4,0,
575,15,321,1,1,3
575,15,321,1,7,
575,15,347,4,0,
575,15,371,4,0,
575,15,373,1,19,
575,15,373,4,0,
575,15,374,4,0,
575,15,377,1,34,
575,15,412,4,0,
575,15,433,4,0,
575,15,447,4,0,
575,15,451,4,0,
575,15,473,1,25,
575,15,473,4,0,
575,15,477,1,43,
575,15,478,1,53,
575,15,496,4,0,
575,15,589,1,1,4
575,15,590,4,0,
575,16,1,1,1,1
575,16,3,1,14,1
575,16,60,1,16,1
575,16,85,4,0,
575,16,86,4,0,
575,16,92,4,0,
575,16,93,1,1,2
575,16,93,1,3,1
575,16,94,1,39,1
575,16,94,4,0,
575,16,104,4,0,
575,16,113,4,0,
575,16,115,4,0,
575,16,138,4,0,
575,16,148,4,0,
575,16,156,4,0,
575,16,157,4,0,
575,16,164,4,0,
575,16,168,4,0,
575,16,173,3,0,
575,16,182,4,0,
575,16,185,1,24,1
575,16,204,1,50,1
575,16,207,4,0,
575,16,213,4,0,
575,16,214,4,0,
575,16,215,3,0,
575,16,216,4,0,
575,16,218,4,0,
575,16,219,4,0,
575,16,237,4,0,
575,16,240,4,0,
575,16,244,4,0,
575,16,247,4,0,
575,16,248,1,31,1
575,16,253,3,0,
575,16,259,4,0,
575,16,260,1,28,1
575,16,263,4,0,
575,16,269,4,0,
575,16,270,3,0,
575,16,271,3,0,
575,16,272,3,0,
575,16,277,3,0,
575,16,278,3,0,
575,16,285,3,0,
575,16,289,3,0,
575,16,290,4,0,
575,16,313,1,10,1
575,16,317,4,0,
575,16,321,1,1,3
575,16,321,1,7,1
575,16,324,3,0,
575,16,343,3,0,
575,16,347,4,0,
575,16,351,3,0,
575,16,356,3,0,
575,16,371,4,0,
575,16,373,1,19,1
575,16,373,4,0,
575,16,374,4,0,
575,16,377,1,34,1
575,16,399,4,0,
575,16,412,4,0,
575,16,428,3,0,
575,16,433,4,0,
575,16,447,4,0,
575,16,451,4,0,
575,16,473,1,25,1
575,16,473,4,0,
575,16,477,1,43,1
575,16,478,1,53,1
575,16,478,3,0,
575,16,492,3,0,
575,16,496,4,0,
575,16,589,1,1,4
575,16,590,4,0,
575,17,1,1,1,1
575,17,3,1,14,
575,17,60,1,16,
575,17,85,4,0,
575,17,86,4,0,
575,17,92,4,0,
575,17,93,1,1,2
575,17,93,1,3,
575,17,94,1,39,
575,17,94,4,0,
575,17,104,4,0,
575,17,113,4,0,
575,17,115,4,0,
575,17,138,4,0,
575,17,156,4,0,
575,17,157,4,0,
575,17,164,4,0,
575,17,168,4,0,
575,17,182,4,0,
575,17,185,1,24,
575,17,204,1,50,
575,17,207,4,0,
575,17,213,4,0,
575,17,214,4,0,
575,17,216,4,0,
575,17,218,4,0,
575,17,219,4,0,
575,17,237,4,0,
575,17,240,4,0,
575,17,244,4,0,
575,17,247,4,0,
575,17,248,1,31,
575,17,259,4,0,
575,17,260,1,28,
575,17,263,4,0,
575,17,269,4,0,
575,17,313,1,10,
575,17,317,4,0,
575,17,321,1,1,3
575,17,321,1,7,
575,17,347,4,0,
575,17,371,4,0,
575,17,373,1,19,
575,17,373,4,0,
575,17,374,4,0,
575,17,377,1,34,
575,17,399,4,0,
575,17,412,4,0,
575,17,433,4,0,
575,17,447,4,0,
575,17,451,4,0,
575,17,473,1,25,
575,17,473,4,0,
575,17,477,1,43,
575,17,478,1,53,
575,17,496,4,0,
575,17,589,1,1,4
575,17,590,4,0,
575,18,1,1,1,1
575,18,3,1,14,
575,18,60,1,16,
575,18,85,4,0,
575,18,86,4,0,
575,18,92,4,0,
575,18,93,1,1,2
575,18,93,1,3,
575,18,94,1,39,
575,18,94,4,0,
575,18,104,4,0,
575,18,113,4,0,
575,18,115,4,0,
575,18,138,4,0,
575,18,156,4,0,
575,18,157,4,0,
575,18,164,4,0,
575,18,168,4,0,
575,18,182,4,0,
575,18,185,1,24,
575,18,204,1,50,
575,18,207,4,0,
575,18,213,4,0,
575,18,214,4,0,
575,18,216,4,0,
575,18,218,4,0,
575,18,219,4,0,
575,18,237,4,0,
575,18,240,4,0,
575,18,244,4,0,
575,18,247,4,0,
575,18,248,1,31,
575,18,259,4,0,
575,18,260,1,28,
575,18,263,4,0,
575,18,269,4,0,
575,18,313,1,10,
575,18,317,4,0,
575,18,321,1,1,3
575,18,321,1,7,
575,18,347,4,0,
575,18,371,4,0,
575,18,373,1,19,
575,18,373,4,0,
575,18,374,4,0,
575,18,377,1,34,
575,18,399,4,0,
575,18,412,4,0,
575,18,433,4,0,
575,18,447,4,0,
575,18,451,4,0,
575,18,473,1,25,
575,18,473,4,0,
575,18,477,1,43,
575,18,478,1,53,
575,18,496,4,0,
575,18,589,1,1,4
575,18,590,4,0,
576,11,1,1,1,1
576,11,3,1,14,
576,11,60,1,16,
576,11,63,4,0,
576,11,85,4,0,
576,11,86,4,0,
576,11,92,4,0,
576,11,93,1,1,2
576,11,93,1,3,
576,11,94,1,39,
576,11,94,4,0,
576,11,104,4,0,
576,11,113,4,0,
576,11,115,4,0,
576,11,138,4,0,
576,11,148,4,0,
576,11,156,4,0,
576,11,157,4,0,
576,11,164,4,0,
576,11,168,4,0,
576,11,182,4,0,
576,11,185,1,24,
576,11,204,1,54,
576,11,207,4,0,
576,11,213,4,0,
576,11,216,4,0,
576,11,218,4,0,
576,11,219,4,0,
576,11,237,4,0,
576,11,240,4,0,
576,11,244,4,0,
576,11,247,4,0,
576,11,248,1,31,
576,11,259,4,0,
576,11,260,1,28,
576,11,263,4,0,
576,11,269,4,0,
576,11,280,4,0,
576,11,313,1,1,4
576,11,313,1,10,
576,11,317,4,0,
576,11,321,1,1,3
576,11,321,1,7,
576,11,347,4,0,
576,11,371,4,0,
576,11,373,1,19,
576,11,373,4,0,
576,11,374,4,0,
576,11,377,1,34,
576,11,412,4,0,
576,11,416,4,0,
576,11,433,4,0,
576,11,447,4,0,
576,11,451,4,0,
576,11,473,1,25,
576,11,473,4,0,
576,11,477,1,45,
576,11,477,4,0,
576,11,478,1,59,
576,11,490,4,0,
576,11,496,4,0,
576,14,1,1,1,1
576,14,3,1,14,
576,14,60,1,16,
576,14,63,4,0,
576,14,85,4,0,
576,14,86,4,0,
576,14,92,4,0,
576,14,93,1,1,2
576,14,93,1,3,
576,14,94,1,39,
576,14,94,4,0,
576,14,104,4,0,
576,14,113,4,0,
576,14,115,4,0,
576,14,138,4,0,
576,14,148,4,0,
576,14,156,4,0,
576,14,157,4,0,
576,14,164,4,0,
576,14,168,4,0,
576,14,173,3,0,
576,14,182,4,0,
576,14,185,1,24,
576,14,204,1,54,
576,14,207,4,0,
576,14,213,4,0,
576,14,214,3,0,
576,14,215,3,0,
576,14,216,4,0,
576,14,218,4,0,
576,14,219,4,0,
576,14,237,4,0,
576,14,240,4,0,
576,14,244,4,0,
576,14,247,4,0,
576,14,248,1,31,
576,14,253,3,0,
576,14,259,4,0,
576,14,260,1,28,
576,14,263,4,0,
576,14,269,4,0,
576,14,270,3,0,
576,14,271,3,0,
576,14,272,3,0,
576,14,277,3,0,
576,14,278,3,0,
576,14,280,4,0,
576,14,285,3,0,
576,14,289,3,0,
576,14,313,1,1,4
576,14,313,1,10,
576,14,317,4,0,
576,14,321,1,1,3
576,14,321,1,7,
576,14,324,3,0,
576,14,343,3,0,
576,14,347,4,0,
576,14,356,3,0,
576,14,371,4,0,
576,14,373,1,19,
576,14,373,4,0,
576,14,374,4,0,
576,14,377,1,34,
576,14,412,4,0,
576,14,416,4,0,
576,14,428,3,0,
576,14,433,4,0,
576,14,447,4,0,
576,14,451,4,0,
576,14,473,1,25,
576,14,473,4,0,
576,14,477,1,45,
576,14,477,4,0,
576,14,478,1,59,
576,14,478,3,0,
576,14,490,4,0,
576,14,492,3,0,
576,14,496,4,0,
576,15,1,1,1,1
576,15,3,1,14,
576,15,60,1,16,
576,15,63,4,0,
576,15,85,4,0,
576,15,86,4,0,
576,15,92,4,0,
576,15,93,1,1,2
576,15,93,1,3,
576,15,94,1,39,
576,15,94,4,0,
576,15,104,4,0,
576,15,113,4,0,
576,15,115,4,0,
576,15,138,4,0,
576,15,148,4,0,
576,15,156,4,0,
576,15,157,4,0,
576,15,164,4,0,
576,15,168,4,0,
576,15,182,4,0,
576,15,185,1,24,
576,15,204,1,54,
576,15,207,4,0,
576,15,213,4,0,
576,15,214,4,0,
576,15,216,4,0,
576,15,218,4,0,
576,15,219,4,0,
576,15,237,4,0,
576,15,240,4,0,
576,15,244,4,0,
576,15,247,4,0,
576,15,248,1,31,
576,15,259,4,0,
576,15,260,1,28,
576,15,263,4,0,
576,15,269,4,0,
576,15,280,4,0,
576,15,313,1,10,
576,15,317,4,0,
576,15,321,1,1,3
576,15,321,1,7,
576,15,347,4,0,
576,15,371,4,0,
576,15,373,1,19,
576,15,373,4,0,
576,15,374,4,0,
576,15,377,1,34,
576,15,412,4,0,
576,15,416,4,0,
576,15,433,4,0,
576,15,447,4,0,
576,15,451,4,0,
576,15,473,1,25,
576,15,473,4,0,
576,15,477,1,45,
576,15,478,1,59,
576,15,490,4,0,
576,15,496,4,0,
576,15,589,1,1,4
576,15,590,4,0,
576,15,612,4,0,
576,16,1,1,1,1
576,16,3,1,14,1
576,16,60,1,16,1
576,16,63,4,0,
576,16,85,4,0,
576,16,86,4,0,
576,16,92,4,0,
576,16,93,1,1,2
576,16,93,1,3,1
576,16,94,1,39,1
576,16,94,4,0,
576,16,104,4,0,
576,16,113,4,0,
576,16,115,4,0,
576,16,138,4,0,
576,16,148,4,0,
576,16,156,4,0,
576,16,157,4,0,
576,16,164,4,0,
576,16,168,4,0,
576,16,173,3,0,
576,16,182,4,0,
576,16,185,1,24,1
576,16,204,1,54,1
576,16,207,4,0,
576,16,213,4,0,
576,16,214,4,0,
576,16,215,3,0,
576,16,216,4,0,
576,16,218,4,0,
576,16,219,4,0,
576,16,237,4,0,
576,16,240,4,0,
576,16,244,4,0,
576,16,247,4,0,
576,16,248,1,31,1
576,16,253,3,0,
576,16,259,4,0,
576,16,260,1,28,1
576,16,263,4,0,
576,16,269,4,0,
576,16,270,3,0,
576,16,271,3,0,
576,16,272,3,0,
576,16,277,3,0,
576,16,278,3,0,
576,16,280,4,0,
576,16,285,3,0,
576,16,289,3,0,
576,16,290,4,0,
576,16,313,1,10,1
576,16,317,4,0,
576,16,321,1,1,3
576,16,321,1,7,1
576,16,324,3,0,
576,16,343,3,0,
576,16,347,4,0,
576,16,351,3,0,
576,16,356,3,0,
576,16,371,4,0,
576,16,373,1,19,1
576,16,373,4,0,
576,16,374,4,0,
576,16,377,1,34,1
576,16,399,4,0,
576,16,412,4,0,
576,16,416,4,0,
576,16,428,3,0,
576,16,433,4,0,
576,16,447,4,0,
576,16,451,4,0,
576,16,473,1,25,1
576,16,473,4,0,
576,16,477,1,45,1
576,16,478,1,59,1
576,16,478,3,0,
576,16,490,4,0,
576,16,492,3,0,
576,16,496,4,0,
576,16,589,1,1,4
576,16,590,4,0,
576,16,612,4,0,
576,17,1,1,1,1
576,17,3,1,14,
576,17,60,1,16,
576,17,63,4,0,
576,17,85,4,0,
576,17,86,4,0,
576,17,92,4,0,
576,17,93,1,1,2
576,17,93,1,3,
576,17,94,1,39,
576,17,94,4,0,
576,17,104,4,0,
576,17,113,4,0,
576,17,115,4,0,
576,17,138,4,0,
576,17,156,4,0,
576,17,157,4,0,
576,17,164,4,0,
576,17,168,4,0,
576,17,182,4,0,
576,17,185,1,24,
576,17,204,1,54,
576,17,207,4,0,
576,17,213,4,0,
576,17,214,4,0,
576,17,216,4,0,
576,17,218,4,0,
576,17,219,4,0,
576,17,237,4,0,
576,17,240,4,0,
576,17,244,4,0,
576,17,247,4,0,
576,17,248,1,31,
576,17,259,4,0,
576,17,260,1,28,
576,17,263,4,0,
576,17,269,4,0,
576,17,280,4,0,
576,17,313,1,10,
576,17,317,4,0,
576,17,321,1,1,3
576,17,321,1,7,
576,17,347,4,0,
576,17,371,4,0,
576,17,373,1,19,
576,17,373,4,0,
576,17,374,4,0,
576,17,377,1,34,
576,17,399,4,0,
576,17,412,4,0,
576,17,416,4,0,
576,17,433,4,0,
576,17,447,4,0,
576,17,451,4,0,
576,17,473,1,25,
576,17,473,4,0,
576,17,477,1,45,
576,17,478,1,59,
576,17,490,4,0,
576,17,496,4,0,
576,17,589,1,1,4
576,17,590,4,0,
576,18,1,1,1,1
576,18,3,1,14,
576,18,60,1,16,
576,18,63,4,0,
576,18,85,4,0,
576,18,86,4,0,
576,18,92,4,0,
576,18,93,1,1,2
576,18,93,1,3,
576,18,94,1,39,
576,18,94,4,0,
576,18,104,4,0,
576,18,113,4,0,
576,18,115,4,0,
576,18,138,4,0,
576,18,156,4,0,
576,18,157,4,0,
576,18,164,4,0,
576,18,168,4,0,
576,18,182,4,0,
576,18,185,1,24,
576,18,204,1,54,
576,18,207,4,0,
576,18,213,4,0,
576,18,214,4,0,
576,18,216,4,0,
576,18,218,4,0,
576,18,219,4,0,
576,18,237,4,0,
576,18,240,4,0,
576,18,244,4,0,
576,18,247,4,0,
576,18,248,1,31,
576,18,259,4,0,
576,18,260,1,28,
576,18,263,4,0,
576,18,269,4,0,
576,18,280,4,0,
576,18,313,1,10,
576,18,317,4,0,
576,18,321,1,1,3
576,18,321,1,7,
576,18,347,4,0,
576,18,371,4,0,
576,18,373,1,19,
576,18,373,4,0,
576,18,374,4,0,
576,18,377,1,34,
576,18,399,4,0,
576,18,412,4,0,
576,18,416,4,0,
576,18,433,4,0,
576,18,447,4,0,
576,18,451,4,0,
576,18,473,1,25,
576,18,473,4,0,
576,18,477,1,45,
576,18,478,1,59,
576,18,490,4,0,
576,18,496,4,0,
576,18,589,1,1,4
576,18,590,4,0,
577,11,86,4,0,
577,11,87,4,0,
577,11,92,4,0,
577,11,94,1,37,
577,11,94,4,0,
577,11,101,2,0,
577,11,104,4,0,
577,11,105,1,24,
577,11,109,2,0,
577,11,113,1,16,
577,11,113,4,0,
577,11,115,1,3,
577,11,115,4,0,
577,11,138,4,0,
577,11,148,4,0,
577,11,149,1,1,
577,11,151,2,0,
577,11,153,4,0,
577,11,156,4,0,
577,11,157,4,0,
577,11,164,4,0,
577,11,182,4,0,
577,11,204,1,19,
577,11,205,1,7,
577,11,207,4,0,
577,11,213,4,0,
577,11,216,4,0,
577,11,218,4,0,
577,11,219,4,0,
577,11,220,1,33,
577,11,237,1,14,
577,11,237,4,0,
577,11,240,4,0,
577,11,244,4,0,
577,11,247,4,0,
577,11,248,1,31,
577,11,263,4,0,
577,11,271,2,0,
577,11,283,1,28,
577,11,285,1,40,
577,11,286,2,0,
577,11,289,1,10,
577,11,290,2,0,
577,11,310,2,0,
577,11,317,4,0,
577,11,347,4,0,
577,11,360,4,0,
577,11,373,4,0,
577,11,377,1,46,
577,11,412,4,0,
577,11,430,4,0,
577,11,433,4,0,
577,11,472,1,48,
577,11,473,1,25,
577,11,473,4,0,
577,11,477,4,0,
577,11,496,4,0,
577,14,86,4,0,
577,14,87,4,0,
577,14,92,4,0,
577,14,94,1,37,
577,14,94,4,0,
577,14,101,2,0,
577,14,104,4,0,
577,14,105,1,24,
577,14,109,2,0,
577,14,113,1,16,
577,14,113,4,0,
577,14,115,1,3,
577,14,115,4,0,
577,14,138,4,0,
577,14,148,4,0,
577,14,149,1,1,
577,14,151,2,0,
577,14,153,4,0,
577,14,156,4,0,
577,14,157,4,0,
577,14,164,4,0,
577,14,173,3,0,
577,14,182,4,0,
577,14,204,1,19,
577,14,205,1,7,
577,14,207,4,0,
577,14,213,4,0,
577,14,214,3,0,
577,14,216,4,0,
577,14,218,4,0,
577,14,219,4,0,
577,14,220,1,33,
577,14,220,3,0,
577,14,237,1,14,
577,14,237,4,0,
577,14,240,4,0,
577,14,244,4,0,
577,14,247,4,0,
577,14,248,1,31,
577,14,263,4,0,
577,14,271,2,0,
577,14,271,3,0,
577,14,272,3,0,
577,14,277,3,0,
577,14,283,1,28,
577,14,283,3,0,
577,14,285,1,40,
577,14,285,3,0,
577,14,286,2,0,
577,14,289,1,10,
577,14,289,3,0,
577,14,290,2,0,
577,14,310,2,0,
577,14,317,4,0,
577,14,324,3,0,
577,14,347,4,0,
577,14,356,3,0,
577,14,360,4,0,
577,14,373,4,0,
577,14,377,1,46,
577,14,412,4,0,
577,14,428,3,0,
577,14,430,4,0,
577,14,433,4,0,
577,14,472,1,48,
577,14,472,3,0,
577,14,473,1,25,
577,14,473,4,0,
577,14,477,4,0,
577,14,495,3,0,
577,14,496,4,0,
577,15,86,4,0,
577,15,87,4,0,
577,15,92,4,0,
577,15,94,1,37,
577,15,94,4,0,
577,15,101,2,0,
577,15,104,4,0,
577,15,105,1,24,
577,15,109,2,0,
577,15,113,1,16,
577,15,113,4,0,
577,15,115,1,3,
577,15,115,4,0,
577,15,138,4,0,
577,15,148,4,0,
577,15,149,1,1,
577,15,151,2,0,
577,15,153,4,0,
577,15,156,4,0,
577,15,157,4,0,
577,15,164,4,0,
577,15,182,4,0,
577,15,204,1,19,
577,15,205,1,7,
577,15,207,4,0,
577,15,213,4,0,
577,15,214,4,0,
577,15,216,4,0,
577,15,218,4,0,
577,15,219,4,0,
577,15,220,1,33,
577,15,237,1,14,
577,15,237,4,0,
577,15,240,4,0,
577,15,244,4,0,
577,15,247,4,0,
577,15,248,1,31,
577,15,263,4,0,
577,15,270,2,0,
577,15,271,2,0,
577,15,283,1,28,
577,15,285,1,40,
577,15,286,2,0,
577,15,289,1,10,
577,15,290,2,0,
577,15,310,2,0,
577,15,317,4,0,
577,15,347,4,0,
577,15,360,4,0,
577,15,373,4,0,
577,15,377,1,46,
577,15,412,4,0,
577,15,430,4,0,
577,15,433,4,0,
577,15,472,1,48,
577,15,473,1,25,
577,15,473,4,0,
577,15,496,4,0,
577,15,590,4,0,
577,15,611,4,0,
577,16,86,4,0,
577,16,87,4,0,
577,16,92,4,0,
577,16,94,1,37,1
577,16,94,4,0,
577,16,101,2,0,
577,16,104,4,0,
577,16,105,1,24,1
577,16,109,2,0,
577,16,113,1,16,1
577,16,113,4,0,
577,16,115,1,3,1
577,16,115,4,0,
577,16,138,4,0,
577,16,148,4,0,
577,16,149,1,1,1
577,16,151,2,0,
577,16,153,4,0,
577,16,156,4,0,
577,16,157,4,0,
577,16,164,4,0,
577,16,173,3,0,
577,16,182,4,0,
577,16,204,1,19,1
577,16,205,1,7,1
577,16,207,4,0,
577,16,213,4,0,
577,16,214,4,0,
577,16,216,4,0,
577,16,218,4,0,
577,16,219,4,0,
577,16,220,1,33,1
577,16,220,3,0,
577,16,237,1,14,1
577,16,237,4,0,
577,16,240,4,0,
577,16,244,4,0,
577,16,247,4,0,
577,16,248,1,31,1
577,16,263,4,0,
577,16,270,2,0,
577,16,270,3,0,
577,16,271,2,0,
577,16,271,3,0,
577,16,272,3,0,
577,16,277,3,0,
577,16,283,1,28,1
577,16,283,3,0,
577,16,285,1,40,1
577,16,285,3,0,
577,16,286,2,0,
577,16,289,1,10,1
577,16,289,3,0,
577,16,290,2,0,
577,16,290,4,0,
577,16,310,2,0,
577,16,317,4,0,
577,16,324,3,0,
577,16,334,3,0,
577,16,347,4,0,
577,16,351,3,0,
577,16,356,3,0,
577,16,360,4,0,
577,16,373,4,0,
577,16,377,1,46,1
577,16,412,4,0,
577,16,428,3,0,
577,16,430,4,0,
577,16,433,4,0,
577,16,472,1,48,1
577,16,472,3,0,
577,16,473,1,25,1
577,16,473,4,0,
577,16,495,3,0,
577,16,496,4,0,
577,16,590,4,0,
577,16,611,4,0,
577,17,86,4,0,
577,17,87,4,0,
577,17,92,4,0,
577,17,94,1,37,
577,17,94,4,0,
577,17,101,2,0,
577,17,104,4,0,
577,17,105,1,24,
577,17,109,2,0,
577,17,113,1,16,
577,17,113,4,0,
577,17,115,1,3,
577,17,115,4,0,
577,17,138,4,0,
577,17,149,1,1,
577,17,151,2,0,
577,17,153,4,0,
577,17,156,4,0,
577,17,157,4,0,
577,17,164,4,0,
577,17,182,4,0,
577,17,204,1,19,
577,17,205,1,7,
577,17,207,4,0,
577,17,213,4,0,
577,17,214,4,0,
577,17,216,4,0,
577,17,218,4,0,
577,17,219,4,0,
577,17,220,1,33,
577,17,237,1,14,
577,17,237,4,0,
577,17,240,4,0,
577,17,244,4,0,
577,17,247,4,0,
577,17,248,1,31,
577,17,263,4,0,
577,17,270,2,0,
577,17,271,2,0,
577,17,283,1,28,
577,17,285,1,40,
577,17,286,2,0,
577,17,289,1,10,
577,17,290,2,0,
577,17,310,2,0,
577,17,317,4,0,
577,17,347,4,0,
577,17,360,4,0,
577,17,373,4,0,
577,17,377,1,46,
577,17,412,4,0,
577,17,430,4,0,
577,17,433,4,0,
577,17,472,1,48,
577,17,473,1,25,
577,17,473,4,0,
577,17,496,4,0,
577,17,590,4,0,
577,17,611,4,0,
577,18,86,4,0,
577,18,87,4,0,
577,18,92,4,0,
577,18,94,1,37,
577,18,94,4,0,
577,18,101,2,0,
577,18,104,4,0,
577,18,105,1,24,
577,18,109,2,0,
577,18,113,1,16,
577,18,113,4,0,
577,18,115,1,3,
577,18,115,4,0,
577,18,138,4,0,
577,18,149,1,1,
577,18,151,2,0,
577,18,153,4,0,
577,18,156,4,0,
577,18,157,4,0,
577,18,164,4,0,
577,18,182,4,0,
577,18,204,1,19,
577,18,205,1,7,
577,18,207,4,0,
577,18,213,4,0,
577,18,214,4,0,
577,18,216,4,0,
577,18,218,4,0,
577,18,219,4,0,
577,18,220,1,33,
577,18,237,1,14,
577,18,237,4,0,
577,18,240,4,0,
577,18,244,4,0,
577,18,247,4,0,
577,18,248,1,31,
577,18,263,4,0,
577,18,270,2,0,
577,18,271,2,0,
577,18,283,1,28,
577,18,285,1,40,
577,18,286,2,0,
577,18,289,1,10,
577,18,290,2,0,
577,18,310,2,0,
577,18,317,4,0,
577,18,347,4,0,
577,18,360,4,0,
577,18,373,4,0,
577,18,377,1,46,
577,18,412,4,0,
577,18,430,4,0,
577,18,433,4,0,
577,18,472,1,48,
577,18,473,1,25,
577,18,473,4,0,
577,18,496,4,0,
577,18,590,4,0,
577,18,611,4,0,
578,11,86,4,0,
578,11,87,4,0,
578,11,92,4,0,
578,11,94,1,39,
578,11,94,4,0,
578,11,104,4,0,
578,11,105,1,24,
578,11,113,1,16,
578,11,113,4,0,
578,11,115,1,1,2
578,11,115,1,3,
578,11,115,4,0,
578,11,138,4,0,
578,11,148,4,0,
578,11,149,1,1,1
578,11,153,4,0,
578,11,156,4,0,
578,11,157,4,0,
578,11,164,4,0,
578,11,182,4,0,
578,11,204,1,19,
578,11,205,1,1,3
578,11,205,1,7,
578,11,207,4,0,
578,11,213,4,0,
578,11,216,4,0,
578,11,218,4,0,
578,11,219,4,0,
578,11,220,1,34,
578,11,237,1,14,
578,11,237,4,0,
578,11,240,4,0,
578,11,244,4,0,
578,11,247,4,0,
578,11,248,1,31,
578,11,263,4,0,
578,11,283,1,28,
578,11,285,1,43,
578,11,289,1,1,4
578,11,289,1,10,
578,11,317,4,0,
578,11,347,4,0,
578,11,360,4,0,
578,11,373,4,0,
578,11,377,1,50,
578,11,412,4,0,
578,11,430,4,0,
578,11,433,4,0,
578,11,472,1,53,
578,11,473,1,25,
578,11,473,4,0,
578,11,477,4,0,
578,11,496,4,0,
578,14,86,4,0,
578,14,87,4,0,
578,14,92,4,0,
578,14,94,1,39,
578,14,94,4,0,
578,14,104,4,0,
578,14,105,1,24,
578,14,113,1,16,
578,14,113,4,0,
578,14,115,1,1,2
578,14,115,1,3,
578,14,115,4,0,
578,14,138,4,0,
578,14,148,4,0,
578,14,149,1,1,1
578,14,153,4,0,
578,14,156,4,0,
578,14,157,4,0,
578,14,164,4,0,
578,14,173,3,0,
578,14,182,4,0,
578,14,204,1,19,
578,14,205,1,1,3
578,14,205,1,7,
578,14,207,4,0,
578,14,213,4,0,
578,14,214,3,0,
578,14,216,4,0,
578,14,218,4,0,
578,14,219,4,0,
578,14,220,1,34,
578,14,220,3,0,
578,14,237,1,14,
578,14,237,4,0,
578,14,240,4,0,
578,14,244,4,0,
578,14,247,4,0,
578,14,248,1,31,
578,14,263,4,0,
578,14,271,3,0,
578,14,272,3,0,
578,14,277,3,0,
578,14,283,1,28,
578,14,283,3,0,
578,14,285,1,43,
578,14,285,3,0,
578,14,289,1,1,4
578,14,289,1,10,
578,14,289,3,0,
578,14,317,4,0,
578,14,324,3,0,
578,14,347,4,0,
578,14,356,3,0,
578,14,360,4,0,
578,14,373,4,0,
578,14,377,1,50,
578,14,412,4,0,
578,14,428,3,0,
578,14,430,4,0,
578,14,433,4,0,
578,14,472,1,53,
578,14,472,3,0,
578,14,473,1,25,
578,14,473,4,0,
578,14,477,4,0,
578,14,495,3,0,
578,14,496,4,0,
578,15,86,4,0,
578,15,87,4,0,
578,15,92,4,0,
578,15,94,1,39,
578,15,94,4,0,
578,15,104,4,0,
578,15,105,1,24,
578,15,113,1,16,
578,15,113,4,0,
578,15,115,1,1,2
578,15,115,1,3,
578,15,115,4,0,
578,15,138,4,0,
578,15,148,4,0,
578,15,149,1,1,1
578,15,153,4,0,
578,15,156,4,0,
578,15,157,4,0,
578,15,164,4,0,
578,15,182,4,0,
578,15,204,1,19,
578,15,205,1,1,3
578,15,205,1,7,
578,15,207,4,0,
578,15,213,4,0,
578,15,214,4,0,
578,15,216,4,0,
578,15,218,4,0,
578,15,219,4,0,
578,15,220,1,34,
578,15,237,1,14,
578,15,237,4,0,
578,15,240,4,0,
578,15,244,4,0,
578,15,247,4,0,
578,15,248,1,31,
578,15,263,4,0,
578,15,283,1,28,
578,15,285,1,43,
578,15,289,1,1,4
578,15,289,1,10,
578,15,317,4,0,
578,15,347,4,0,
578,15,360,4,0,
578,15,373,4,0,
578,15,377,1,50,
578,15,412,4,0,
578,15,430,4,0,
578,15,433,4,0,
578,15,472,1,53,
578,15,473,1,25,
578,15,473,4,0,
578,15,496,4,0,
578,15,590,4,0,
578,15,611,4,0,
578,16,86,4,0,
578,16,87,4,0,
578,16,92,4,0,
578,16,94,1,39,1
578,16,94,4,0,
578,16,104,4,0,
578,16,105,1,24,1
578,16,113,1,16,1
578,16,113,4,0,
578,16,115,1,1,2
578,16,115,1,3,1
578,16,115,4,0,
578,16,138,4,0,
578,16,148,4,0,
578,16,149,1,1,1
578,16,153,4,0,
578,16,156,4,0,
578,16,157,4,0,
578,16,164,4,0,
578,16,173,3,0,
578,16,182,4,0,
578,16,204,1,19,1
578,16,205,1,1,3
578,16,205,1,7,1
578,16,207,4,0,
578,16,213,4,0,
578,16,214,4,0,
578,16,216,4,0,
578,16,218,4,0,
578,16,219,4,0,
578,16,220,1,34,1
578,16,220,3,0,
578,16,237,1,14,1
578,16,237,4,0,
578,16,240,4,0,
578,16,244,4,0,
578,16,247,4,0,
578,16,248,1,31,1
578,16,263,4,0,
578,16,270,3,0,
578,16,271,3,0,
578,16,272,3,0,
578,16,277,3,0,
578,16,283,1,28,1
578,16,283,3,0,
578,16,285,1,43,1
578,16,285,3,0,
578,16,289,1,1,4
578,16,289,1,10,1
578,16,289,3,0,
578,16,290,4,0,
578,16,317,4,0,
578,16,324,3,0,
578,16,334,3,0,
578,16,347,4,0,
578,16,351,3,0,
578,16,356,3,0,
578,16,360,4,0,
578,16,373,4,0,
578,16,377,1,50,1
578,16,412,4,0,
578,16,428,3,0,
578,16,430,4,0,
578,16,433,4,0,
578,16,472,1,53,1
578,16,472,3,0,
578,16,473,1,25,1
578,16,473,4,0,
578,16,495,3,0,
578,16,496,4,0,
578,16,590,4,0,
578,16,611,4,0,
578,17,86,4,0,
578,17,87,4,0,
578,17,92,4,0,
578,17,94,1,39,
578,17,94,4,0,
578,17,104,4,0,
578,17,105,1,24,
578,17,113,1,16,
578,17,113,4,0,
578,17,115,1,1,2
578,17,115,1,3,
578,17,115,4,0,
578,17,138,4,0,
578,17,149,1,1,1
578,17,153,4,0,
578,17,156,4,0,
578,17,157,4,0,
578,17,164,4,0,
578,17,182,4,0,
578,17,204,1,19,
578,17,205,1,1,3
578,17,205,1,7,
578,17,207,4,0,
578,17,213,4,0,
578,17,214,4,0,
578,17,216,4,0,
578,17,218,4,0,
578,17,219,4,0,
578,17,220,1,34,
578,17,237,1,14,
578,17,237,4,0,
578,17,240,4,0,
578,17,244,4,0,
578,17,247,4,0,
578,17,248,1,31,
578,17,263,4,0,
578,17,283,1,28,
578,17,285,1,43,
578,17,289,1,1,4
578,17,289,1,10,
578,17,317,4,0,
578,17,347,4,0,
578,17,360,4,0,
578,17,373,4,0,
578,17,377,1,50,
578,17,412,4,0,
578,17,430,4,0,
578,17,433,4,0,
578,17,472,1,53,
578,17,473,1,25,
578,17,473,4,0,
578,17,496,4,0,
578,17,590,4,0,
578,17,611,4,0,
578,18,86,4,0,
578,18,87,4,0,
578,18,92,4,0,
578,18,94,1,39,
578,18,94,4,0,
578,18,104,4,0,
578,18,105,1,24,
578,18,113,1,16,
578,18,113,4,0,
578,18,115,1,1,2
578,18,115,1,3,
578,18,115,4,0,
578,18,138,4,0,
578,18,149,1,1,1
578,18,153,4,0,
578,18,156,4,0,
578,18,157,4,0,
578,18,164,4,0,
578,18,182,4,0,
578,18,204,1,19,
578,18,205,1,1,3
578,18,205,1,7,
578,18,207,4,0,
578,18,213,4,0,
578,18,214,4,0,
578,18,216,4,0,
578,18,218,4,0,
578,18,219,4,0,
578,18,220,1,34,
578,18,237,1,14,
578,18,237,4,0,
578,18,240,4,0,
578,18,244,4,0,
578,18,247,4,0,
578,18,248,1,31,
578,18,263,4,0,
578,18,283,1,28,
578,18,285,1,43,
578,18,289,1,1,4
578,18,289,1,10,
578,18,317,4,0,
578,18,347,4,0,
578,18,360,4,0,
578,18,373,4,0,
578,18,377,1,50,
578,18,412,4,0,
578,18,430,4,0,
578,18,433,4,0,
578,18,472,1,53,
578,18,473,1,25,
578,18,473,4,0,
578,18,496,4,0,
578,18,590,4,0,
578,18,611,4,0,
579,11,63,4,0,
579,11,70,4,0,
579,11,86,4,0,
579,11,87,4,0,
579,11,92,4,0,
579,11,94,1,39,
579,11,94,4,0,
579,11,104,4,0,
579,11,105,1,24,
579,11,113,1,16,
579,11,113,4,0,
579,11,115,1,1,2
579,11,115,1,3,
579,11,115,4,0,
579,11,138,4,0,
579,11,146,1,41,
579,11,148,4,0,
579,11,149,1,1,1
579,11,153,4,0,
579,11,156,4,0,
579,11,157,4,0,
579,11,164,4,0,
579,11,182,4,0,
579,11,204,1,19,
579,11,205,1,1,3
579,11,205,1,7,
579,11,207,4,0,
579,11,213,4,0,
579,11,216,4,0,
579,11,218,4,0,
579,11,219,4,0,
579,11,220,1,34,
579,11,237,1,14,
579,11,237,4,0,
579,11,240,4,0,
579,11,244,4,0,
579,11,247,4,0,
579,11,248,1,31,
579,11,249,4,0,
579,11,263,4,0,
579,11,283,1,28,
579,11,285,1,45,
579,11,289,1,1,4
579,11,289,1,10,
579,11,317,4,0,
579,11,347,4,0,
579,11,360,4,0,
579,11,373,4,0,
579,11,374,4,0,
579,11,377,1,54,
579,11,411,4,0,
579,11,412,4,0,
579,11,416,4,0,
579,11,430,4,0,
579,11,433,4,0,
579,11,447,4,0,
579,11,472,1,59,
579,11,473,1,25,
579,11,473,4,0,
579,11,477,4,0,
579,11,496,4,0,
579,14,7,3,0,
579,14,8,3,0,
579,14,9,3,0,
579,14,63,4,0,
579,14,70,4,0,
579,14,86,4,0,
579,14,87,4,0,
579,14,92,4,0,
579,14,94,1,39,
579,14,94,4,0,
579,14,104,4,0,
579,14,105,1,24,
579,14,113,1,16,
579,14,113,4,0,
579,14,115,1,1,2
579,14,115,1,3,
579,14,115,4,0,
579,14,138,4,0,
579,14,146,1,41,
579,14,148,4,0,
579,14,149,1,1,1
579,14,153,4,0,
579,14,156,4,0,
579,14,157,4,0,
579,14,164,4,0,
579,14,173,3,0,
579,14,182,4,0,
579,14,204,1,19,
579,14,205,1,1,3
579,14,205,1,7,
579,14,207,4,0,
579,14,213,4,0,
579,14,214,3,0,
579,14,216,4,0,
579,14,218,4,0,
579,14,219,4,0,
579,14,220,1,34,
579,14,220,3,0,
579,14,237,1,14,
579,14,237,4,0,
579,14,240,4,0,
579,14,244,4,0,
579,14,247,4,0,
579,14,248,1,31,
579,14,249,4,0,
579,14,263,4,0,
579,14,270,3,0,
579,14,271,3,0,
579,14,272,3,0,
579,14,276,3,0,
579,14,277,3,0,
579,14,282,3,0,
579,14,283,1,28,
579,14,283,3,0,
579,14,285,1,45,
579,14,285,3,0,
579,14,289,1,1,4
579,14,289,1,10,
579,14,289,3,0,
579,14,317,4,0,
579,14,324,3,0,
579,14,347,4,0,
579,14,356,3,0,
579,14,360,4,0,
579,14,373,4,0,
579,14,374,4,0,
579,14,377,1,54,
579,14,409,3,0,
579,14,411,4,0,
579,14,412,4,0,
579,14,416,4,0,
579,14,428,3,0,
579,14,430,4,0,
579,14,433,4,0,
579,14,447,4,0,
579,14,472,1,59,
579,14,472,3,0,
579,14,473,1,25,
579,14,473,4,0,
579,14,477,4,0,
579,14,495,3,0,
579,14,496,4,0,
579,15,63,4,0,
579,15,70,4,0,
579,15,86,4,0,
579,15,87,4,0,
579,15,92,4,0,
579,15,94,1,39,
579,15,94,4,0,
579,15,104,4,0,
579,15,105,1,24,
579,15,113,1,16,
579,15,113,4,0,
579,15,115,1,1,2
579,15,115,1,3,
579,15,115,4,0,
579,15,138,4,0,
579,15,146,1,41,
579,15,148,4,0,
579,15,149,1,1,1
579,15,153,4,0,
579,15,156,4,0,
579,15,157,4,0,
579,15,164,4,0,
579,15,182,4,0,
579,15,204,1,19,
579,15,205,1,1,3
579,15,205,1,7,
579,15,207,4,0,
579,15,213,4,0,
579,15,214,4,0,
579,15,216,4,0,
579,15,218,4,0,
579,15,219,4,0,
579,15,220,1,34,
579,15,237,1,14,
579,15,237,4,0,
579,15,240,4,0,
579,15,244,4,0,
579,15,247,4,0,
579,15,248,1,31,
579,15,249,4,0,
579,15,263,4,0,
579,15,283,1,28,
579,15,285,1,45,
579,15,289,1,1,4
579,15,289,1,10,
579,15,317,4,0,
579,15,347,4,0,
579,15,360,4,0,
579,15,373,4,0,
579,15,374,4,0,
579,15,377,1,54,
579,15,411,4,0,
579,15,412,4,0,
579,15,416,4,0,
579,15,430,4,0,
579,15,433,4,0,
579,15,447,4,0,
579,15,472,1,59,
579,15,473,1,25,
579,15,473,4,0,
579,15,496,4,0,
579,15,590,4,0,
579,15,611,4,0,
579,15,612,4,0,
579,16,7,3,0,
579,16,8,3,0,
579,16,9,3,0,
579,16,63,4,0,
579,16,70,4,0,
579,16,86,4,0,
579,16,87,4,0,
579,16,92,4,0,
579,16,94,1,39,1
579,16,94,4,0,
579,16,104,4,0,
579,16,105,1,24,1
579,16,113,1,16,1
579,16,113,4,0,
579,16,115,1,1,2
579,16,115,1,3,1
579,16,115,4,0,
579,16,138,4,0,
579,16,146,1,41,1
579,16,148,4,0,
579,16,149,1,1,1
579,16,153,4,0,
579,16,156,4,0,
579,16,157,4,0,
579,16,164,4,0,
579,16,173,3,0,
579,16,182,4,0,
579,16,204,1,19,1
579,16,205,1,1,3
579,16,205,1,7,1
579,16,207,4,0,
579,16,213,4,0,
579,16,214,4,0,
579,16,216,4,0,
579,16,218,4,0,
579,16,219,4,0,
579,16,220,1,34,1
579,16,220,3,0,
579,16,237,1,14,1
579,16,237,4,0,
579,16,240,4,0,
579,16,244,4,0,
579,16,247,4,0,
579,16,248,1,31,1
579,16,249,4,0,
579,16,263,4,0,
579,16,264,3,0,
579,16,270,3,0,
579,16,271,3,0,
579,16,272,3,0,
579,16,276,3,0,
579,16,277,3,0,
579,16,282,3,0,
579,16,283,1,28,1
579,16,283,3,0,
579,16,285,1,45,1
579,16,285,3,0,
579,16,289,1,1,4
579,16,289,1,10,1
579,16,289,3,0,
579,16,290,4,0,
579,16,317,4,0,
579,16,324,3,0,
579,16,334,3,0,
579,16,347,4,0,
579,16,351,3,0,
579,16,356,3,0,
579,16,360,4,0,
579,16,373,4,0,
579,16,374,4,0,
579,16,377,1,54,1
579,16,409,3,0,
579,16,411,4,0,
579,16,412,4,0,
579,16,416,4,0,
579,16,428,3,0,
579,16,430,4,0,
579,16,433,4,0,
579,16,447,4,0,
579,16,472,1,59,1
579,16,472,3,0,
579,16,473,1,25,1
579,16,473,4,0,
579,16,495,3,0,
579,16,496,4,0,
579,16,590,4,0,
579,16,611,4,0,
579,16,612,4,0,
579,17,63,4,0,
579,17,86,4,0,
579,17,87,4,0,
579,17,92,4,0,
579,17,94,1,39,
579,17,94,4,0,
579,17,104,4,0,
579,17,105,1,24,
579,17,113,1,16,
579,17,113,4,0,
579,17,115,1,1,3
579,17,115,1,3,
579,17,115,4,0,
579,17,138,4,0,
579,17,146,1,0,
579,17,146,1,1,1
579,17,149,1,1,2
579,17,153,4,0,
579,17,156,4,0,
579,17,157,4,0,
579,17,164,4,0,
579,17,182,4,0,
579,17,204,1,19,
579,17,205,1,1,4
579,17,205,1,7,
579,17,207,4,0,
579,17,213,4,0,
579,17,214,4,0,
579,17,216,4,0,
579,17,218,4,0,
579,17,219,4,0,
579,17,220,1,34,
579,17,237,1,14,
579,17,237,4,0,
579,17,240,4,0,
579,17,244,4,0,
579,17,247,4,0,
579,17,248,1,31,
579,17,263,4,0,
579,17,283,1,28,
579,17,285,1,45,
579,17,289,1,1,5
579,17,289,1,10,
579,17,317,4,0,
579,17,347,4,0,
579,17,360,4,0,
579,17,373,4,0,
579,17,374,4,0,
579,17,377,1,54,
579,17,411,4,0,
579,17,412,4,0,
579,17,416,4,0,
579,17,430,4,0,
579,17,433,4,0,
579,17,447,4,0,
579,17,472,1,59,
579,17,473,1,25,
579,17,473,4,0,
579,17,496,4,0,
579,17,590,4,0,
579,17,611,4,0,
579,18,63,4,0,
579,18,86,4,0,
579,18,87,4,0,
579,18,92,4,0,
579,18,94,1,39,
579,18,94,4,0,
579,18,104,4,0,
579,18,105,1,24,
579,18,113,1,16,
579,18,113,4,0,
579,18,115,1,1,3
579,18,115,1,3,
579,18,115,4,0,
579,18,138,4,0,
579,18,146,1,0,
579,18,146,1,1,1
579,18,149,1,1,2
579,18,153,4,0,
579,18,156,4,0,
579,18,157,4,0,
579,18,164,4,0,
579,18,182,4,0,
579,18,204,1,19,
579,18,205,1,1,4
579,18,205,1,7,
579,18,207,4,0,
579,18,213,4,0,
579,18,214,4,0,
579,18,216,4,0,
579,18,218,4,0,
579,18,219,4,0,
579,18,220,1,34,
579,18,237,1,14,
579,18,237,4,0,
579,18,240,4,0,
579,18,244,4,0,
579,18,247,4,0,
579,18,248,1,31,
579,18,263,4,0,
579,18,283,1,28,
579,18,285,1,45,
579,18,289,1,1,5
579,18,289,1,10,
579,18,317,4,0,
579,18,347,4,0,
579,18,360,4,0,
579,18,373,4,0,
579,18,374,4,0,
579,18,377,1,54,
579,18,411,4,0,
579,18,412,4,0,
579,18,416,4,0,
579,18,430,4,0,
579,18,433,4,0,
579,18,447,4,0,
579,18,472,1,59,
579,18,473,1,25,
579,18,473,4,0,
579,18,496,4,0,
579,18,590,4,0,
579,18,611,4,0,
580,11,16,2,0,
580,11,17,1,9,
580,11,19,4,0,
580,11,55,1,1,
580,11,57,4,0,
580,11,58,4,0,
580,11,61,1,19,
580,11,92,4,0,
580,11,104,4,0,
580,11,119,2,0,
580,11,156,4,0,
580,11,164,4,0,
580,11,182,4,0,
580,11,207,4,0,
580,11,211,2,0,
580,11,213,4,0,
580,11,216,4,0,
580,11,218,4,0,
580,11,237,4,0,
580,11,240,1,34,
580,11,240,4,0,
580,11,258,4,0,
580,11,263,4,0,
580,11,291,4,0,
580,11,297,1,21,
580,11,314,2,0,
580,11,332,1,15,
580,11,332,4,0,
580,11,346,1,3,
580,11,352,1,13,
580,11,355,1,30,
580,11,362,2,0,
580,11,365,4,0,
580,11,366,1,37,
580,11,381,2,0,
580,11,382,2,0,
580,11,392,1,24,
580,11,403,1,27,
580,11,413,1,41,
580,11,432,1,6,
580,11,496,4,0,
580,11,503,4,0,
580,11,542,1,46,
580,14,16,2,0,
580,14,17,1,9,
580,14,19,4,0,
580,14,55,1,1,
580,14,57,4,0,
580,14,58,4,0,
580,14,61,1,19,
580,14,92,4,0,
580,14,104,4,0,
580,14,119,2,0,
580,14,156,4,0,
580,14,164,4,0,
580,14,173,3,0,
580,14,182,4,0,
580,14,196,3,0,
580,14,207,4,0,
580,14,211,2,0,
580,14,213,4,0,
580,14,214,3,0,
580,14,216,4,0,
580,14,218,4,0,
580,14,237,4,0,
580,14,240,1,34,
580,14,240,4,0,
580,14,253,3,0,
580,14,258,4,0,
580,14,263,4,0,
580,14,283,3,0,
580,14,291,4,0,
580,14,297,1,21,
580,14,314,2,0,
580,14,332,1,15,
580,14,332,4,0,
580,14,346,1,3,
580,14,352,1,13,
580,14,355,1,30,
580,14,355,3,0,
580,14,362,2,0,
580,14,365,4,0,
580,14,366,1,37,
580,14,366,3,0,
580,14,381,2,0,
580,14,382,2,0,
580,14,392,1,24,
580,14,403,1,27,
580,14,413,1,41,
580,14,432,1,6,
580,14,496,4,0,
580,14,503,4,0,
580,14,542,1,46,
580,15,16,2,0,
580,15,17,1,9,
580,15,19,4,0,
580,15,55,1,1,
580,15,57,4,0,
580,15,58,4,0,
580,15,61,1,19,
580,15,92,4,0,
580,15,104,4,0,
580,15,119,2,0,
580,15,156,4,0,
580,15,164,4,0,
580,15,182,4,0,
580,15,207,4,0,
580,15,211,2,0,
580,15,211,4,0,
580,15,213,4,0,
580,15,214,4,0,
580,15,216,4,0,
580,15,218,4,0,
580,15,237,4,0,
580,15,240,1,34,
580,15,240,4,0,
580,15,258,4,0,
580,15,263,4,0,
580,15,297,1,21,
580,15,300,2,0,
580,15,314,2,0,
580,15,332,1,15,
580,15,332,4,0,
580,15,346,1,3,
580,15,352,1,13,
580,15,355,1,30,
580,15,355,4,0,
580,15,362,2,0,
580,15,366,1,37,
580,15,381,2,0,
580,15,382,2,0,
580,15,392,1,24,
580,15,403,1,27,
580,15,413,1,41,
580,15,432,1,6,
580,15,496,4,0,
580,15,503,4,0,
580,15,542,1,46,
580,15,590,4,0,
580,16,16,2,0,
580,16,17,1,9,1
580,16,19,4,0,
580,16,55,1,1,1
580,16,57,4,0,
580,16,58,4,0,
580,16,61,1,19,1
580,16,92,4,0,
580,16,104,4,0,
580,16,119,2,0,
580,16,156,4,0,
580,16,164,4,0,
580,16,173,3,0,
580,16,182,4,0,
580,16,196,3,0,
580,16,207,4,0,
580,16,211,2,0,
580,16,211,4,0,
580,16,213,4,0,
580,16,214,4,0,
580,16,216,4,0,
580,16,218,4,0,
580,16,237,4,0,
580,16,240,1,34,1
580,16,240,4,0,
580,16,253,3,0,
580,16,258,4,0,
580,16,263,4,0,
580,16,283,3,0,
580,16,290,4,0,
580,16,291,4,0,
580,16,297,1,21,1
580,16,300,2,0,
580,16,314,2,0,
580,16,332,1,15,1
580,16,332,4,0,
580,16,346,1,3,1
580,16,352,1,13,1
580,16,352,3,0,
580,16,355,1,30,1
580,16,355,4,0,
580,16,362,2,0,
580,16,366,1,37,1
580,16,366,3,0,
580,16,381,2,0,
580,16,382,2,0,
580,16,392,1,24,1
580,16,403,1,27,1
580,16,413,1,41,1
580,16,432,1,6,1
580,16,496,4,0,
580,16,503,4,0,
580,16,542,1,46,1
580,16,590,4,0,
580,17,16,2,0,
580,17,17,1,9,
580,17,19,4,0,
580,17,55,1,1,
580,17,57,4,0,
580,17,58,4,0,
580,17,61,1,19,
580,17,92,4,0,
580,17,104,4,0,
580,17,119,2,0,
580,17,156,4,0,
580,17,164,4,0,
580,17,182,4,0,
580,17,207,4,0,
580,17,211,2,0,
580,17,211,4,0,
580,17,213,4,0,
580,17,214,4,0,
580,17,216,4,0,
580,17,218,4,0,
580,17,237,4,0,
580,17,240,1,34,
580,17,240,4,0,
580,17,258,4,0,
580,17,263,4,0,
580,17,297,1,21,
580,17,300,2,0,
580,17,314,2,0,
580,17,332,1,15,
580,17,332,4,0,
580,17,346,1,3,
580,17,352,1,13,
580,17,355,1,30,
580,17,355,4,0,
580,17,362,2,0,
580,17,366,1,37,
580,17,381,2,0,
580,17,382,2,0,
580,17,392,1,24,
580,17,403,1,27,
580,17,413,1,41,
580,17,432,1,6,
580,17,496,4,0,
580,17,503,4,0,
580,17,542,1,46,
580,17,590,4,0,
580,18,16,2,0,
580,18,17,1,9,
580,18,19,4,0,
580,18,55,1,1,
580,18,57,4,0,
580,18,58,4,0,
580,18,61,1,19,
580,18,92,4,0,
580,18,104,4,0,
580,18,119,2,0,
580,18,156,4,0,
580,18,164,4,0,
580,18,182,4,0,
580,18,207,4,0,
580,18,211,2,0,
580,18,211,4,0,
580,18,213,4,0,
580,18,214,4,0,
580,18,216,4,0,
580,18,218,4,0,
580,18,237,4,0,
580,18,240,1,34,
580,18,240,4,0,
580,18,258,4,0,
580,18,263,4,0,
580,18,297,1,21,
580,18,300,2,0,
580,18,314,2,0,
580,18,332,1,15,
580,18,332,4,0,
580,18,346,1,3,
580,18,352,1,13,
580,18,355,1,30,
580,18,355,4,0,
580,18,362,2,0,
580,18,366,1,37,
580,18,381,2,0,
580,18,382,2,0,
580,18,392,1,24,
580,18,403,1,27,
580,18,413,1,41,
580,18,432,1,6,
580,18,453,2,0,
580,18,496,4,0,
580,18,503,4,0,
580,18,542,1,46,
580,18,590,4,0,
581,11,17,1,1,4
581,11,17,1,9,
581,11,19,4,0,
581,11,55,1,1,1
581,11,57,4,0,
581,11,58,4,0,
581,11,61,1,19,
581,11,63,4,0,
581,11,92,4,0,
581,11,104,4,0,
581,11,156,4,0,
581,11,164,4,0,
581,11,182,4,0,
581,11,207,4,0,
581,11,213,4,0,
581,11,216,4,0,
581,11,218,4,0,
581,11,237,4,0,
581,11,240,1,34,
581,11,240,4,0,
581,11,258,4,0,
581,11,263,4,0,
581,11,291,4,0,
581,11,297,1,21,
581,11,332,1,15,
581,11,332,4,0,
581,11,346,1,1,2
581,11,346,1,3,
581,11,352,1,13,
581,11,355,1,30,
581,11,365,4,0,
581,11,366,1,40,
581,11,392,1,24,
581,11,403,1,27,
581,11,413,1,47,
581,11,416,4,0,
581,11,432,1,1,3
581,11,432,1,6,
581,11,496,4,0,
581,11,503,4,0,
581,11,542,1,55,
581,14,17,1,1,4
581,14,17,1,9,
581,14,19,4,0,
581,14,55,1,1,1
581,14,57,4,0,
581,14,58,4,0,
581,14,61,1,19,
581,14,63,4,0,
581,14,92,4,0,
581,14,104,4,0,
581,14,143,3,0,
581,14,156,4,0,
581,14,164,4,0,
581,14,173,3,0,
581,14,182,4,0,
581,14,196,3,0,
581,14,207,4,0,
581,14,213,4,0,
581,14,214,3,0,
581,14,216,4,0,
581,14,218,4,0,
581,14,237,4,0,
581,14,240,1,34,
581,14,240,4,0,
581,14,253,3,0,
581,14,258,4,0,
581,14,263,4,0,
581,14,283,3,0,
581,14,291,4,0,
581,14,297,1,21,
581,14,332,1,15,
581,14,332,4,0,
581,14,346,1,1,2
581,14,346,1,3,
581,14,352,1,13,
581,14,355,1,30,
581,14,355,3,0,
581,14,365,4,0,
581,14,366,1,40,
581,14,366,3,0,
581,14,392,1,24,
581,14,403,1,27,
581,14,413,1,47,
581,14,416,4,0,
581,14,432,1,1,3
581,14,432,1,6,
581,14,496,4,0,
581,14,503,4,0,
581,14,542,1,55,
581,15,17,1,1,4
581,15,17,1,9,
581,15,19,4,0,
581,15,55,1,1,1
581,15,57,4,0,
581,15,58,4,0,
581,15,61,1,19,
581,15,63,4,0,
581,15,92,4,0,
581,15,104,4,0,
581,15,156,4,0,
581,15,164,4,0,
581,15,182,4,0,
581,15,207,4,0,
581,15,211,4,0,
581,15,213,4,0,
581,15,214,4,0,
581,15,216,4,0,
581,15,218,4,0,
581,15,237,4,0,
581,15,240,1,34,
581,15,240,4,0,
581,15,258,4,0,
581,15,263,4,0,
581,15,297,1,21,
581,15,332,1,15,
581,15,332,4,0,
581,15,346,1,1,2
581,15,346,1,3,
581,15,352,1,13,
581,15,355,1,30,
581,15,355,4,0,
581,15,366,1,40,
581,15,392,1,24,
581,15,403,1,27,
581,15,413,1,47,
581,15,416,4,0,
581,15,432,1,1,3
581,15,432,1,6,
581,15,496,4,0,
581,15,503,4,0,
581,15,542,1,55,
581,15,590,4,0,
581,16,17,1,1,4
581,16,17,1,9,1
581,16,19,4,0,
581,16,55,1,1,1
581,16,57,4,0,
581,16,58,4,0,
581,16,61,1,19,1
581,16,63,4,0,
581,16,92,4,0,
581,16,104,4,0,
581,16,143,3,0,
581,16,156,4,0,
581,16,164,4,0,
581,16,173,3,0,
581,16,182,4,0,
581,16,196,3,0,
581,16,207,4,0,
581,16,211,4,0,
581,16,213,4,0,
581,16,214,4,0,
581,16,216,4,0,
581,16,218,4,0,
581,16,237,4,0,
581,16,240,1,34,1
581,16,240,4,0,
581,16,253,3,0,
581,16,258,4,0,
581,16,263,4,0,
581,16,283,3,0,
581,16,290,4,0,
581,16,291,4,0,
581,16,297,1,21,1
581,16,332,1,15,1
581,16,332,4,0,
581,16,346,1,1,2
581,16,346,1,3,1
581,16,352,1,13,1
581,16,352,3,0,
581,16,355,1,30,1
581,16,355,4,0,
581,16,366,1,40,1
581,16,366,3,0,
581,16,392,1,24,1
581,16,403,1,27,1
581,16,413,1,47,1
581,16,416,4,0,
581,16,432,1,1,3
581,16,432,1,6,1
581,16,496,4,0,
581,16,503,4,0,
581,16,542,1,55,1
581,16,590,4,0,
581,17,17,1,1,4
581,17,17,1,9,
581,17,19,4,0,
581,17,55,1,1,1
581,17,57,4,0,
581,17,58,4,0,
581,17,61,1,19,
581,17,63,4,0,
581,17,92,4,0,
581,17,104,4,0,
581,17,156,4,0,
581,17,164,4,0,
581,17,182,4,0,
581,17,207,4,0,
581,17,211,4,0,
581,17,213,4,0,
581,17,214,4,0,
581,17,216,4,0,
581,17,218,4,0,
581,17,237,4,0,
581,17,240,1,34,
581,17,240,4,0,
581,17,258,4,0,
581,17,263,4,0,
581,17,297,1,21,
581,17,332,1,15,
581,17,332,4,0,
581,17,346,1,1,2
581,17,346,1,3,
581,17,352,1,13,
581,17,355,1,30,
581,17,355,4,0,
581,17,366,1,40,
581,17,392,1,24,
581,17,403,1,27,
581,17,413,1,47,
581,17,416,4,0,
581,17,432,1,1,3
581,17,432,1,6,
581,17,496,4,0,
581,17,503,4,0,
581,17,542,1,55,
581,17,590,4,0,
581,18,17,1,1,4
581,18,17,1,9,
581,18,19,4,0,
581,18,55,1,1,1
581,18,57,4,0,
581,18,58,4,0,
581,18,61,1,19,
581,18,63,4,0,
581,18,92,4,0,
581,18,104,4,0,
581,18,156,4,0,
581,18,164,4,0,
581,18,182,4,0,
581,18,207,4,0,
581,18,211,4,0,
581,18,213,4,0,
581,18,214,4,0,
581,18,216,4,0,
581,18,218,4,0,
581,18,237,4,0,
581,18,240,1,34,
581,18,240,4,0,
581,18,258,4,0,
581,18,263,4,0,
581,18,297,1,21,
581,18,332,1,15,
581,18,332,4,0,
581,18,346,1,1,2
581,18,346,1,3,
581,18,352,1,13,
581,18,355,1,30,
581,18,355,4,0,
581,18,366,1,40,
581,18,392,1,24,
581,18,403,1,27,
581,18,413,1,47,
581,18,416,4,0,
581,18,432,1,1,3
581,18,432,1,6,
581,18,496,4,0,
581,18,503,4,0,
581,18,542,1,55,
581,18,590,4,0,
582,11,54,1,16,
582,11,58,1,35,
582,11,58,4,0,
582,11,59,1,49,
582,11,59,4,0,
582,11,92,4,0,
582,11,104,4,0,
582,11,106,1,4,
582,11,113,4,0,
582,11,151,1,31,
582,11,153,4,0,
582,11,156,4,0,
582,11,164,4,0,
582,11,181,2,0,
582,11,182,4,0,
582,11,196,1,13,
582,11,207,4,0,
582,11,213,4,0,
582,11,216,4,0,
582,11,218,4,0,
582,11,237,4,0,
582,11,240,4,0,
582,11,243,1,44,
582,11,253,1,10,
582,11,258,1,40,
582,11,258,4,0,
582,11,263,4,0,
582,11,269,1,22,
582,11,269,4,0,
582,11,286,2,0,
582,11,310,1,7,
582,11,329,1,53,
582,11,333,1,1,
582,11,334,2,0,
582,11,352,2,0,
582,11,363,2,0,
582,11,393,2,0,
582,11,419,1,19,
582,11,420,2,0,
582,11,429,1,26,
582,11,430,4,0,
582,11,475,2,0,
582,11,496,4,0,
582,11,524,4,0,
582,14,54,1,16,
582,14,58,1,35,
582,14,58,4,0,
582,14,59,1,49,
582,14,59,4,0,
582,14,92,4,0,
582,14,104,4,0,
582,14,106,1,4,
582,14,113,4,0,
582,14,151,1,31,
582,14,153,4,0,
582,14,156,4,0,
582,14,164,4,0,
582,14,173,3,0,
582,14,181,2,0,
582,14,182,4,0,
582,14,196,1,13,
582,14,196,3,0,
582,14,207,4,0,
582,14,213,4,0,
582,14,214,3,0,
582,14,216,4,0,
582,14,218,4,0,
582,14,237,4,0,
582,14,240,4,0,
582,14,243,1,44,
582,14,253,1,10,
582,14,253,3,0,
582,14,258,1,40,
582,14,258,4,0,
582,14,263,4,0,
582,14,269,1,22,
582,14,269,4,0,
582,14,277,3,0,
582,14,286,2,0,
582,14,310,1,7,
582,14,324,3,0,
582,14,329,1,53,
582,14,333,1,1,
582,14,334,2,0,
582,14,334,3,0,
582,14,352,2,0,
582,14,363,2,0,
582,14,393,2,0,
582,14,393,3,0,
582,14,419,1,19,
582,14,420,2,0,
582,14,429,1,26,
582,14,430,4,0,
582,14,475,2,0,
582,14,496,4,0,
582,14,524,4,0,
582,15,54,1,16,
582,15,58,1,35,
582,15,58,4,0,
582,15,59,1,49,
582,15,59,4,0,
582,15,92,4,0,
582,15,104,4,0,
582,15,106,1,4,
582,15,113,4,0,
582,15,151,1,31,
582,15,153,4,0,
582,15,156,4,0,
582,15,164,4,0,
582,15,181,2,0,
582,15,182,4,0,
582,15,196,1,13,
582,15,207,4,0,
582,15,213,4,0,
582,15,214,4,0,
582,15,216,4,0,
582,15,218,4,0,
582,15,237,4,0,
582,15,240,4,0,
582,15,243,1,44,
582,15,253,1,10,
582,15,258,1,40,
582,15,258,4,0,
582,15,263,4,0,
582,15,269,1,22,
582,15,269,4,0,
582,15,286,2,0,
582,15,310,1,7,
582,15,329,1,53,
582,15,333,1,1,
582,15,334,2,0,
582,15,352,2,0,
582,15,363,2,0,
582,15,393,2,0,
582,15,419,1,19,
582,15,420,2,0,
582,15,429,1,26,
582,15,430,4,0,
582,15,475,2,0,
582,15,496,4,0,
582,15,524,4,0,
582,15,590,4,0,
582,16,54,1,16,1
582,16,58,1,35,1
582,16,58,4,0,
582,16,59,1,49,1
582,16,59,4,0,
582,16,92,4,0,
582,16,104,4,0,
582,16,106,1,4,1
582,16,113,4,0,
582,16,151,1,31,1
582,16,153,4,0,
582,16,156,4,0,
582,16,164,4,0,
582,16,173,3,0,
582,16,181,2,0,
582,16,182,4,0,
582,16,196,1,13,1
582,16,196,3,0,
582,16,207,4,0,
582,16,213,4,0,
582,16,214,4,0,
582,16,216,4,0,
582,16,218,4,0,
582,16,237,4,0,
582,16,240,4,0,
582,16,243,1,44,1
582,16,253,1,10,1
582,16,253,3,0,
582,16,258,1,40,1
582,16,258,4,0,
582,16,263,4,0,
582,16,269,1,22,1
582,16,269,4,0,
582,16,277,3,0,
582,16,286,2,0,
582,16,290,4,0,
582,16,310,1,7,1
582,16,324,3,0,
582,16,329,1,53,1
582,16,333,1,1,1
582,16,334,2,0,
582,16,334,3,0,
582,16,352,2,0,
582,16,352,3,0,
582,16,363,2,0,
582,16,393,2,0,
582,16,393,3,0,
582,16,419,1,19,1
582,16,420,2,0,
582,16,429,1,26,1
582,16,430,4,0,
582,16,475,2,0,
582,16,496,4,0,
582,16,524,4,0,
582,16,590,4,0,
582,17,54,1,16,
582,17,58,1,35,
582,17,58,4,0,
582,17,59,1,49,
582,17,59,4,0,
582,17,92,4,0,
582,17,104,4,0,
582,17,106,1,4,
582,17,113,4,0,
582,17,151,1,31,
582,17,153,4,0,
582,17,156,4,0,
582,17,164,4,0,
582,17,181,2,0,
582,17,182,4,0,
582,17,196,1,13,
582,17,207,4,0,
582,17,213,4,0,
582,17,214,4,0,
582,17,216,4,0,
582,17,218,4,0,
582,17,237,4,0,
582,17,240,4,0,
582,17,243,1,44,
582,17,253,1,10,
582,17,258,1,40,
582,17,258,4,0,
582,17,263,4,0,
582,17,269,1,22,
582,17,269,4,0,
582,17,286,2,0,
582,17,310,1,7,
582,17,329,1,53,
582,17,333,1,1,
582,17,334,2,0,
582,17,352,2,0,
582,17,363,2,0,
582,17,393,2,0,
582,17,419,1,19,
582,17,420,2,0,
582,17,429,1,26,
582,17,430,4,0,
582,17,475,2,0,
582,17,496,4,0,
582,17,524,4,0,
582,17,590,4,0,
582,18,54,1,16,
582,18,58,1,35,
582,18,58,4,0,
582,18,59,1,49,
582,18,59,4,0,
582,18,92,4,0,
582,18,104,4,0,
582,18,106,1,4,
582,18,113,4,0,
582,18,151,1,31,
582,18,153,4,0,
582,18,156,4,0,
582,18,164,4,0,
582,18,181,2,0,
582,18,182,4,0,
582,18,196,1,13,
582,18,207,4,0,
582,18,213,4,0,
582,18,214,4,0,
582,18,216,4,0,
582,18,218,4,0,
582,18,237,4,0,
582,18,240,4,0,
582,18,243,1,44,
582,18,253,1,10,
582,18,258,1,40,
582,18,258,4,0,
582,18,263,4,0,
582,18,269,1,22,
582,18,269,4,0,
582,18,286,2,0,
582,18,310,1,7,
582,18,329,1,53,
582,18,333,1,1,
582,18,334,2,0,
582,18,352,2,0,
582,18,363,2,0,
582,18,393,2,0,
582,18,419,1,19,
582,18,420,2,0,
582,18,429,1,26,
582,18,430,4,0,
582,18,475,2,0,
582,18,496,4,0,
582,18,524,4,0,
582,18,590,4,0,
583,11,54,1,16,
583,11,58,1,36,
583,11,58,4,0,
583,11,59,1,53,
583,11,59,4,0,
583,11,92,4,0,
583,11,104,4,0,
583,11,106,1,1,2
583,11,106,1,4,
583,11,113,4,0,
583,11,151,1,31,
583,11,153,4,0,
583,11,156,4,0,
583,11,164,4,0,
583,11,182,4,0,
583,11,196,1,13,
583,11,207,4,0,
583,11,213,4,0,
583,11,216,4,0,
583,11,218,4,0,
583,11,237,4,0,
583,11,240,4,0,
583,11,243,1,47,
583,11,253,1,1,4
583,11,253,1,10,
583,11,258,1,42,
583,11,258,4,0,
583,11,263,4,0,
583,11,269,1,22,
583,11,269,4,0,
583,11,310,1,1,3
583,11,310,1,7,
583,11,329,1,58,
583,11,333,1,1,1
583,11,419,1,19,
583,11,429,1,26,
583,11,430,4,0,
583,11,496,4,0,
583,11,524,4,0,
583,14,54,1,16,
583,14,58,1,36,
583,14,58,4,0,
583,14,59,1,53,
583,14,59,4,0,
583,14,92,4,0,
583,14,104,4,0,
583,14,106,1,1,2
583,14,106,1,4,
583,14,113,4,0,
583,14,151,1,31,
583,14,153,4,0,
583,14,156,4,0,
583,14,164,4,0,
583,14,173,3,0,
583,14,182,4,0,
583,14,196,1,13,
583,14,196,3,0,
583,14,207,4,0,
583,14,213,4,0,
583,14,214,3,0,
583,14,216,4,0,
583,14,218,4,0,
583,14,237,4,0,
583,14,240,4,0,
583,14,243,1,47,
583,14,253,1,1,4
583,14,253,1,10,
583,14,253,3,0,
583,14,258,1,42,
583,14,258,4,0,
583,14,263,4,0,
583,14,269,1,22,
583,14,269,4,0,
583,14,277,3,0,
583,14,310,1,1,3
583,14,310,1,7,
583,14,324,3,0,
583,14,329,1,58,
583,14,333,1,1,1
583,14,334,3,0,
583,14,393,3,0,
583,14,419,1,19,
583,14,429,1,26,
583,14,430,4,0,
583,14,496,4,0,
583,14,524,4,0,
583,15,54,1,16,
583,15,58,1,36,
583,15,58,4,0,
583,15,59,1,53,
583,15,59,4,0,
583,15,92,4,0,
583,15,104,4,0,
583,15,106,1,1,2
583,15,106,1,4,
583,15,113,4,0,
583,15,151,1,31,
583,15,153,4,0,
583,15,156,4,0,
583,15,164,4,0,
583,15,182,4,0,
583,15,196,1,13,
583,15,207,4,0,
583,15,213,4,0,
583,15,214,4,0,
583,15,216,4,0,
583,15,218,4,0,
583,15,237,4,0,
583,15,240,4,0,
583,15,243,1,47,
583,15,253,1,1,4
583,15,253,1,10,
583,15,258,1,42,
583,15,258,4,0,
583,15,263,4,0,
583,15,269,1,22,
583,15,269,4,0,
583,15,310,1,1,3
583,15,310,1,7,
583,15,329,1,58,
583,15,333,1,1,1
583,15,419,1,19,
583,15,429,1,26,
583,15,430,4,0,
583,15,496,4,0,
583,15,524,4,0,
583,15,590,4,0,
583,16,54,1,16,1
583,16,58,1,36,1
583,16,58,4,0,
583,16,59,1,53,1
583,16,59,4,0,
583,16,92,4,0,
583,16,104,4,0,
583,16,106,1,1,2
583,16,106,1,4,1
583,16,113,4,0,
583,16,151,1,31,1
583,16,153,4,0,
583,16,156,4,0,
583,16,164,4,0,
583,16,173,3,0,
583,16,182,4,0,
583,16,196,1,13,1
583,16,196,3,0,
583,16,207,4,0,
583,16,213,4,0,
583,16,214,4,0,
583,16,216,4,0,
583,16,218,4,0,
583,16,237,4,0,
583,16,240,4,0,
583,16,243,1,47,1
583,16,253,1,1,4
583,16,253,1,10,1
583,16,253,3,0,
583,16,258,1,42,1
583,16,258,4,0,
583,16,263,4,0,
583,16,269,1,22,1
583,16,269,4,0,
583,16,277,3,0,
583,16,290,4,0,
583,16,310,1,1,3
583,16,310,1,7,1
583,16,324,3,0,
583,16,329,1,58,1
583,16,333,1,1,1
583,16,334,3,0,
583,16,352,3,0,
583,16,393,3,0,
583,16,419,1,19,1
583,16,429,1,26,1
583,16,430,4,0,
583,16,496,4,0,
583,16,524,4,0,
583,16,590,4,0,
583,17,54,1,16,
583,17,58,1,36,
583,17,58,4,0,
583,17,59,1,53,
583,17,59,4,0,
583,17,92,4,0,
583,17,104,4,0,
583,17,106,1,1,2
583,17,106,1,4,
583,17,113,4,0,
583,17,151,1,31,
583,17,153,4,0,
583,17,156,4,0,
583,17,164,4,0,
583,17,182,4,0,
583,17,196,1,13,
583,17,207,4,0,
583,17,213,4,0,
583,17,214,4,0,
583,17,216,4,0,
583,17,218,4,0,
583,17,237,4,0,
583,17,240,4,0,
583,17,243,1,47,
583,17,253,1,1,4
583,17,253,1,10,
583,17,258,1,42,
583,17,258,4,0,
583,17,263,4,0,
583,17,269,1,22,
583,17,269,4,0,
583,17,310,1,1,3
583,17,310,1,7,
583,17,329,1,58,
583,17,333,1,1,1
583,17,419,1,19,
583,17,429,1,26,
583,17,430,4,0,
583,17,496,4,0,
583,17,524,4,0,
583,17,590,4,0,
583,18,54,1,16,
583,18,58,1,36,
583,18,58,4,0,
583,18,59,1,53,
583,18,59,4,0,
583,18,92,4,0,
583,18,104,4,0,
583,18,106,1,1,2
583,18,106,1,4,
583,18,113,4,0,
583,18,151,1,31,
583,18,153,4,0,
583,18,156,4,0,
583,18,164,4,0,
583,18,182,4,0,
583,18,196,1,13,
583,18,207,4,0,
583,18,213,4,0,
583,18,214,4,0,
583,18,216,4,0,
583,18,218,4,0,
583,18,237,4,0,
583,18,240,4,0,
583,18,243,1,47,
583,18,253,1,1,4
583,18,253,1,10,
583,18,258,1,42,
583,18,258,4,0,
583,18,263,4,0,
583,18,269,1,22,
583,18,269,4,0,
583,18,310,1,1,3
583,18,310,1,7,
583,18,329,1,58,
583,18,333,1,1,1
583,18,419,1,19,
583,18,429,1,26,
583,18,430,4,0,
583,18,496,4,0,
583,18,524,4,0,
583,18,590,4,0,
584,11,54,1,16,
584,11,58,1,36,
584,11,58,4,0,
584,11,59,1,59,
584,11,59,4,0,
584,11,63,4,0,
584,11,92,4,0,
584,11,104,4,0,
584,11,106,1,1,3
584,11,106,1,4,
584,11,113,4,0,
584,11,151,1,31,
584,11,153,4,0,
584,11,156,4,0,
584,11,164,4,0,
584,11,182,4,0,
584,11,196,1,13,
584,11,207,4,0,
584,11,213,4,0,
584,11,216,4,0,
584,11,218,4,0,
584,11,237,4,0,
584,11,240,4,0,
584,11,243,1,50,
584,11,253,1,1,5
584,11,253,1,10,
584,11,258,1,42,
584,11,258,4,0,
584,11,263,4,0,
584,11,269,1,22,
584,11,269,4,0,
584,11,310,1,1,4
584,11,310,1,7,
584,11,311,1,1,1
584,11,329,1,67,
584,11,333,1,1,2
584,11,416,4,0,
584,11,419,1,19,
584,11,429,1,26,
584,11,430,4,0,
584,11,496,4,0,
584,11,524,4,0,
584,14,54,1,16,
584,14,58,1,36,
584,14,58,4,0,
584,14,59,1,59,
584,14,59,4,0,
584,14,63,4,0,
584,14,92,4,0,
584,14,104,4,0,
584,14,106,1,1,3
584,14,106,1,4,
584,14,113,4,0,
584,14,151,1,31,
584,14,153,4,0,
584,14,156,4,0,
584,14,164,4,0,
584,14,173,3,0,
584,14,182,4,0,
584,14,196,1,13,
584,14,196,3,0,
584,14,207,4,0,
584,14,213,4,0,
584,14,214,3,0,
584,14,216,4,0,
584,14,218,4,0,
584,14,237,4,0,
584,14,240,4,0,
584,14,243,1,50,
584,14,253,1,1,5
584,14,253,1,10,
584,14,253,3,0,
584,14,258,1,42,
584,14,258,4,0,
584,14,263,4,0,
584,14,269,1,22,
584,14,269,4,0,
584,14,277,3,0,
584,14,310,1,1,4
584,14,310,1,7,
584,14,311,1,1,1
584,14,324,3,0,
584,14,329,1,67,
584,14,333,1,1,2
584,14,334,3,0,
584,14,393,3,0,
584,14,416,4,0,
584,14,419,1,19,
584,14,429,1,26,
584,14,430,4,0,
584,14,496,4,0,
584,14,524,4,0,
584,15,54,1,16,
584,15,58,1,36,
584,15,58,4,0,
584,15,59,1,59,
584,15,59,4,0,
584,15,63,4,0,
584,15,92,4,0,
584,15,104,4,0,
584,15,106,1,1,5
584,15,106,1,4,
584,15,113,4,0,
584,15,151,1,31,
584,15,153,4,0,
584,15,156,4,0,
584,15,164,4,0,
584,15,182,4,0,
584,15,196,1,13,
584,15,207,4,0,
584,15,213,4,0,
584,15,214,4,0,
584,15,216,4,0,
584,15,218,4,0,
584,15,237,4,0,
584,15,240,4,0,
584,15,243,1,50,
584,15,253,1,1,7
584,15,253,1,10,
584,15,258,1,42,
584,15,258,4,0,
584,15,263,4,0,
584,15,269,1,22,
584,15,269,4,0,
584,15,310,1,1,6
584,15,310,1,7,
584,15,311,1,1,3
584,15,329,1,1,1
584,15,329,1,67,
584,15,333,1,1,4
584,15,416,4,0,
584,15,419,1,19,
584,15,429,1,26,
584,15,430,4,0,
584,15,496,4,0,
584,15,524,4,0,
584,15,573,1,1,2
584,15,590,4,0,
584,16,54,1,16,1
584,16,58,1,36,1
584,16,58,4,0,
584,16,59,1,59,1
584,16,59,4,0,
584,16,63,4,0,
584,16,92,4,0,
584,16,104,4,0,
584,16,106,1,1,5
584,16,106,1,4,1
584,16,113,4,0,
584,16,151,1,31,1
584,16,153,4,0,
584,16,156,4,0,
584,16,164,4,0,
584,16,173,3,0,
584,16,182,4,0,
584,16,196,1,13,1
584,16,196,3,0,
584,16,207,4,0,
584,16,213,4,0,
584,16,214,4,0,
584,16,216,4,0,
584,16,218,4,0,
584,16,237,4,0,
584,16,240,4,0,
584,16,243,1,50,1
584,16,253,1,1,7
584,16,253,1,10,1
584,16,253,3,0,
584,16,258,1,42,1
584,16,258,4,0,
584,16,263,4,0,
584,16,269,1,22,1
584,16,269,4,0,
584,16,277,3,0,
584,16,290,4,0,
584,16,310,1,1,6
584,16,310,1,7,1
584,16,311,1,1,3
584,16,324,3,0,
584,16,329,1,1,1
584,16,329,1,67,1
584,16,333,1,1,4
584,16,334,3,0,
584,16,352,3,0,
584,16,393,3,0,
584,16,416,4,0,
584,16,419,1,19,1
584,16,429,1,26,1
584,16,430,4,0,
584,16,496,4,0,
584,16,524,4,0,
584,16,573,1,1,2
584,16,590,4,0,
584,17,54,1,16,
584,17,58,1,36,
584,17,58,4,0,
584,17,59,1,59,
584,17,59,4,0,
584,17,63,4,0,
584,17,92,4,0,
584,17,104,4,0,
584,17,106,1,1,5
584,17,106,1,4,
584,17,113,4,0,
584,17,151,1,31,
584,17,153,4,0,
584,17,156,4,0,
584,17,164,4,0,
584,17,182,4,0,
584,17,196,1,13,
584,17,207,4,0,
584,17,213,4,0,
584,17,214,4,0,
584,17,216,4,0,
584,17,218,4,0,
584,17,237,4,0,
584,17,240,4,0,
584,17,243,1,50,
584,17,253,1,1,7
584,17,253,1,10,
584,17,258,1,42,
584,17,258,4,0,
584,17,263,4,0,
584,17,269,1,22,
584,17,269,4,0,
584,17,310,1,1,6
584,17,310,1,7,
584,17,311,1,1,3
584,17,329,1,1,1
584,17,329,1,67,
584,17,333,1,1,4
584,17,416,4,0,
584,17,419,1,19,
584,17,429,1,26,
584,17,430,4,0,
584,17,496,4,0,
584,17,524,4,0,
584,17,573,1,1,2
584,17,590,4,0,
584,18,54,1,16,
584,18,58,1,36,
584,18,58,4,0,
584,18,59,1,59,
584,18,59,4,0,
584,18,63,4,0,
584,18,92,4,0,
584,18,104,4,0,
584,18,106,1,1,5
584,18,106,1,4,
584,18,113,4,0,
584,18,151,1,31,
584,18,153,4,0,
584,18,156,4,0,
584,18,164,4,0,
584,18,182,4,0,
584,18,196,1,13,
584,18,207,4,0,
584,18,213,4,0,
584,18,214,4,0,
584,18,216,4,0,
584,18,218,4,0,
584,18,237,4,0,
584,18,240,4,0,
584,18,243,1,50,
584,18,253,1,1,7
584,18,253,1,10,
584,18,258,1,42,
584,18,258,4,0,
584,18,263,4,0,
584,18,269,1,22,
584,18,269,4,0,
584,18,310,1,1,6
584,18,310,1,7,
584,18,311,1,1,3
584,18,329,1,1,1
584,18,329,1,67,
584,18,333,1,1,4
584,18,416,4,0,
584,18,419,1,19,
584,18,429,1,26,
584,18,430,4,0,
584,18,496,4,0,
584,18,524,4,0,
584,18,573,1,1,2
584,18,590,4,0,
585,11,24,1,10,
585,11,26,1,24,
585,11,28,1,7,
585,11,33,1,1,1
585,11,36,1,20,
585,11,38,1,46,
585,11,45,1,4,
585,11,73,1,13,
585,11,76,1,51,
585,11,76,4,0,
585,11,86,4,0,
585,11,92,4,0,
585,11,97,2,0,
585,11,104,4,0,
585,11,113,4,0,
585,11,148,4,0,
585,11,156,4,0,
585,11,164,4,0,
585,11,182,4,0,
585,11,185,1,16,
585,11,204,1,36,
585,11,207,4,0,
585,11,213,4,0,
585,11,214,2,0,
585,11,216,4,0,
585,11,218,4,0,
585,11,219,4,0,
585,11,226,2,0,
585,11,235,2,0,
585,11,237,4,0,
585,11,240,4,0,
585,11,241,4,0,
585,11,247,4,0,
585,11,263,4,0,
585,11,267,1,41,
585,11,293,1,1,2
585,11,312,1,28,
585,11,313,2,0,
585,11,316,2,0,
585,11,320,2,0,
585,11,363,2,0,
585,11,388,2,0,
585,11,412,1,32,
585,11,412,4,0,
585,11,447,4,0,
585,11,496,4,0,
585,11,497,4,0,
585,11,514,4,0,
585,11,526,4,0,
585,11,528,4,0,
585,14,24,1,10,
585,14,26,1,24,
585,14,28,1,7,
585,14,33,1,1,1
585,14,36,1,20,
585,14,38,1,46,
585,14,45,1,4,
585,14,73,1,13,
585,14,76,1,51,
585,14,76,4,0,
585,14,86,4,0,
585,14,92,4,0,
585,14,97,2,0,
585,14,104,4,0,
585,14,113,4,0,
585,14,148,4,0,
585,14,156,4,0,
585,14,164,4,0,
585,14,173,3,0,
585,14,182,4,0,
585,14,185,1,16,
585,14,202,3,0,
585,14,204,1,36,
585,14,207,4,0,
585,14,213,4,0,
585,14,214,2,0,
585,14,214,3,0,
585,14,216,4,0,
585,14,218,4,0,
585,14,219,4,0,
585,14,226,2,0,
585,14,235,2,0,
585,14,235,3,0,
585,14,237,4,0,
585,14,240,4,0,
585,14,241,4,0,
585,14,247,4,0,
585,14,263,4,0,
585,14,267,1,41,
585,14,293,1,1,2
585,14,312,1,28,
585,14,313,2,0,
585,14,316,2,0,
585,14,320,2,0,
585,14,340,3,0,
585,14,363,2,0,
585,14,387,3,0,
585,14,388,2,0,
585,14,388,3,0,
585,14,402,3,0,
585,14,412,1,32,
585,14,412,4,0,
585,14,447,4,0,
585,14,496,4,0,
585,14,497,4,0,
585,14,514,4,0,
585,14,526,4,0,
585,14,528,4,0,
585,15,24,1,10,
585,15,26,1,24,
585,15,28,1,7,
585,15,33,1,1,1
585,15,36,1,20,
585,15,38,1,46,
585,15,45,1,4,
585,15,73,1,13,
585,15,76,1,51,
585,15,76,4,0,
585,15,86,4,0,
585,15,92,4,0,
585,15,97,2,0,
585,15,104,4,0,
585,15,113,4,0,
585,15,148,4,0,
585,15,156,4,0,
585,15,164,4,0,
585,15,182,4,0,
585,15,185,1,16,
585,15,204,1,36,
585,15,207,4,0,
585,15,213,4,0,
585,15,214,2,0,
585,15,214,4,0,
585,15,216,4,0,
585,15,218,4,0,
585,15,219,4,0,
585,15,226,2,0,
585,15,235,2,0,
585,15,237,4,0,
585,15,240,4,0,
585,15,241,4,0,
585,15,247,4,0,
585,15,263,4,0,
585,15,267,1,41,
585,15,267,4,0,
585,15,293,1,1,2
585,15,312,1,28,
585,15,313,2,0,
585,15,316,2,0,
585,15,320,2,0,
585,15,363,2,0,
585,15,388,2,0,
585,15,412,1,32,
585,15,412,4,0,
585,15,447,4,0,
585,15,496,4,0,
585,15,497,4,0,
585,15,514,4,0,
585,15,528,4,0,
585,15,590,4,0,
585,16,24,1,10,1
585,16,26,1,24,1
585,16,28,1,7,1
585,16,33,1,1,1
585,16,36,1,20,1
585,16,38,1,46,1
585,16,45,1,4,1
585,16,73,1,13,1
585,16,76,1,51,1
585,16,76,4,0,
585,16,86,4,0,
585,16,92,4,0,
585,16,97,2,0,
585,16,104,4,0,
585,16,113,4,0,
585,16,148,4,0,
585,16,156,4,0,
585,16,164,4,0,
585,16,173,3,0,
585,16,182,4,0,
585,16,185,1,16,1
585,16,202,3,0,
585,16,204,1,36,1
585,16,207,4,0,
585,16,213,4,0,
585,16,214,2,0,
585,16,214,4,0,
585,16,216,4,0,
585,16,218,4,0,
585,16,219,4,0,
585,16,226,2,0,
585,16,235,2,0,
585,16,235,3,0,
585,16,237,4,0,
585,16,240,4,0,
585,16,241,4,0,
585,16,247,4,0,
585,16,263,4,0,
585,16,267,1,41,1
585,16,267,4,0,
585,16,290,4,0,
585,16,293,1,1,2
585,16,312,1,28,1
585,16,313,2,0,
585,16,316,2,0,
585,16,320,2,0,
585,16,340,3,0,
585,16,363,2,0,
585,16,387,3,0,
585,16,388,2,0,
585,16,388,3,0,
585,16,402,3,0,
585,16,412,1,32,1
585,16,412,4,0,
585,16,447,4,0,
585,16,496,4,0,
585,16,497,4,0,
585,16,514,4,0,
585,16,528,4,0,
585,16,590,4,0,
585,17,24,1,10,
585,17,26,1,24,
585,17,28,1,7,
585,17,33,1,1,1
585,17,36,1,20,
585,17,38,1,46,
585,17,45,1,4,
585,17,73,1,13,
585,17,76,1,51,
585,17,76,4,0,
585,17,86,4,0,
585,17,92,4,0,
585,17,97,2,0,
585,17,104,4,0,
585,17,113,4,0,
585,17,156,4,0,
585,17,164,4,0,
585,17,182,4,0,
585,17,185,1,16,
585,17,204,1,36,
585,17,207,4,0,
585,17,213,4,0,
585,17,214,2,0,
585,17,214,4,0,
585,17,216,4,0,
585,17,218,4,0,
585,17,219,4,0,
585,17,226,2,0,
585,17,235,2,0,
585,17,237,4,0,
585,17,240,4,0,
585,17,241,4,0,
585,17,247,4,0,
585,17,263,4,0,
585,17,267,1,41,
585,17,267,4,0,
585,17,293,1,1,2
585,17,312,1,28,
585,17,313,2,0,
585,17,316,2,0,
585,17,320,2,0,
585,17,363,2,0,
585,17,388,2,0,
585,17,412,1,32,
585,17,412,4,0,
585,17,447,4,0,
585,17,496,4,0,
585,17,497,4,0,
585,17,526,4,0,
585,17,528,4,0,
585,17,590,4,0,
585,18,24,1,10,
585,18,26,1,24,
585,18,28,1,7,
585,18,29,2,0,
585,18,33,1,1,1
585,18,36,1,20,
585,18,38,1,46,
585,18,45,1,4,
585,18,73,1,13,
585,18,76,1,51,
585,18,76,4,0,
585,18,86,4,0,
585,18,92,4,0,
585,18,97,2,0,
585,18,104,4,0,
585,18,113,4,0,
585,18,156,4,0,
585,18,164,4,0,
585,18,182,4,0,
585,18,185,1,16,
585,18,204,1,36,
585,18,207,4,0,
585,18,213,4,0,
585,18,214,2,0,
585,18,214,4,0,
585,18,216,4,0,
585,18,218,4,0,
585,18,219,4,0,
585,18,226,2,0,
585,18,235,2,0,
585,18,237,4,0,
585,18,240,4,0,
585,18,241,4,0,
585,18,247,4,0,
585,18,263,4,0,
585,18,267,1,41,
585,18,267,4,0,
585,18,293,1,1,2
585,18,312,1,28,
585,18,313,2,0,
585,18,316,2,0,
585,18,320,2,0,
585,18,363,2,0,
585,18,388,2,0,
585,18,412,1,32,
585,18,412,4,0,
585,18,447,4,0,
585,18,496,4,0,
585,18,497,4,0,
585,18,526,4,0,
585,18,528,4,0,
585,18,590,4,0,
586,11,14,4,0,
586,11,15,4,0,
586,11,24,1,10,
586,11,26,1,24,
586,11,28,1,1,5
586,11,28,1,7,
586,11,33,1,1,2
586,11,36,1,20,
586,11,38,1,52,
586,11,45,1,1,4
586,11,45,1,4,
586,11,63,4,0,
586,11,73,1,13,
586,11,76,1,60,
586,11,76,4,0,
586,11,86,4,0,
586,11,92,4,0,
586,11,104,4,0,
586,11,113,4,0,
586,11,148,4,0,
586,11,156,4,0,
586,11,164,4,0,
586,11,182,4,0,
586,11,185,1,16,
586,11,204,1,36,
586,11,207,4,0,
586,11,213,4,0,
586,11,216,4,0,
586,11,218,4,0,
586,11,219,4,0,
586,11,224,1,1,1
586,11,237,4,0,
586,11,240,4,0,
586,11,241,4,0,
586,11,247,4,0,
586,11,249,4,0,
586,11,263,4,0,
586,11,267,1,44,
586,11,293,1,1,3
586,11,312,1,28,
586,11,412,1,32,
586,11,412,4,0,
586,11,416,4,0,
586,11,447,4,0,
586,11,496,4,0,
586,11,497,4,0,
586,11,514,4,0,
586,11,526,4,0,
586,11,528,4,0,
586,11,532,1,37,
586,14,14,4,0,
586,14,15,4,0,
586,14,24,1,10,
586,14,26,1,24,
586,14,28,1,1,5
586,14,28,1,7,
586,14,33,1,1,2
586,14,36,1,20,
586,14,38,1,52,
586,14,45,1,1,4
586,14,45,1,4,
586,14,63,4,0,
586,14,73,1,13,
586,14,76,1,60,
586,14,76,4,0,
586,14,86,4,0,
586,14,92,4,0,
586,14,104,4,0,
586,14,113,4,0,
586,14,148,4,0,
586,14,156,4,0,
586,14,164,4,0,
586,14,173,3,0,
586,14,182,4,0,
586,14,185,1,16,
586,14,202,3,0,
586,14,204,1,36,
586,14,207,4,0,
586,14,213,4,0,
586,14,214,3,0,
586,14,216,4,0,
586,14,218,4,0,
586,14,219,4,0,
586,14,224,1,1,1
586,14,235,3,0,
586,14,237,4,0,
586,14,240,4,0,
586,14,241,4,0,
586,14,247,4,0,
586,14,249,4,0,
586,14,263,4,0,
586,14,267,1,44,
586,14,293,1,1,3
586,14,312,1,28,
586,14,340,3,0,
586,14,387,3,0,
586,14,388,3,0,
586,14,402,3,0,
586,14,412,1,32,
586,14,412,4,0,
586,14,416,4,0,
586,14,447,4,0,
586,14,496,4,0,
586,14,497,4,0,
586,14,514,4,0,
586,14,526,4,0,
586,14,528,4,0,
586,14,532,1,37,
586,15,14,4,0,
586,15,15,4,0,
586,15,24,1,10,
586,15,26,1,24,
586,15,28,1,1,5
586,15,28,1,7,
586,15,33,1,1,2
586,15,36,1,20,
586,15,38,1,52,
586,15,45,1,1,4
586,15,45,1,4,
586,15,63,4,0,
586,15,73,1,13,
586,15,76,1,60,
586,15,76,4,0,
586,15,86,4,0,
586,15,92,4,0,
586,15,104,4,0,
586,15,113,4,0,
586,15,148,4,0,
586,15,156,4,0,
586,15,164,4,0,
586,15,182,4,0,
586,15,185,1,16,
586,15,204,1,36,
586,15,207,4,0,
586,15,213,4,0,
586,15,214,4,0,
586,15,216,4,0,
586,15,218,4,0,
586,15,219,4,0,
586,15,224,1,1,1
586,15,237,4,0,
586,15,240,4,0,
586,15,241,4,0,
586,15,247,4,0,
586,15,249,4,0,
586,15,263,4,0,
586,15,267,1,44,
586,15,267,4,0,
586,15,293,1,1,3
586,15,312,1,28,
586,15,412,1,32,
586,15,412,4,0,
586,15,416,4,0,
586,15,447,4,0,
586,15,496,4,0,
586,15,497,4,0,
586,15,514,4,0,
586,15,528,4,0,
586,15,532,1,37,
586,15,590,4,0,
586,16,14,4,0,
586,16,15,4,0,
586,16,24,1,10,1
586,16,26,1,24,1
586,16,28,1,1,5
586,16,28,1,7,1
586,16,33,1,1,2
586,16,36,1,20,1
586,16,38,1,52,1
586,16,45,1,1,4
586,16,45,1,4,1
586,16,63,4,0,
586,16,73,1,13,1
586,16,76,1,60,1
586,16,76,4,0,
586,16,86,4,0,
586,16,92,4,0,
586,16,104,4,0,
586,16,113,4,0,
586,16,148,4,0,
586,16,156,4,0,
586,16,164,4,0,
586,16,173,3,0,
586,16,182,4,0,
586,16,185,1,16,1
586,16,202,3,0,
586,16,204,1,36,1
586,16,207,4,0,
586,16,213,4,0,
586,16,214,4,0,
586,16,216,4,0,
586,16,218,4,0,
586,16,219,4,0,
586,16,224,1,1,1
586,16,235,3,0,
586,16,237,4,0,
586,16,240,4,0,
586,16,241,4,0,
586,16,247,4,0,
586,16,249,4,0,
586,16,263,4,0,
586,16,267,1,44,1
586,16,267,4,0,
586,16,290,4,0,
586,16,293,1,1,3
586,16,312,1,28,1
586,16,340,3,0,
586,16,387,3,0,
586,16,388,3,0,
586,16,402,3,0,
586,16,412,1,32,1
586,16,412,4,0,
586,16,416,4,0,
586,16,447,4,0,
586,16,496,4,0,
586,16,497,4,0,
586,16,514,4,0,
586,16,528,4,0,
586,16,532,1,37,1
586,16,590,4,0,
586,17,14,4,0,
586,17,24,1,10,
586,17,26,1,24,
586,17,28,1,1,6
586,17,28,1,7,
586,17,33,1,1,3
586,17,36,1,20,
586,17,38,1,52,
586,17,45,1,1,5
586,17,45,1,4,
586,17,63,4,0,
586,17,73,1,13,
586,17,76,1,60,
586,17,76,4,0,
586,17,86,4,0,
586,17,92,4,0,
586,17,104,4,0,
586,17,113,4,0,
586,17,156,4,0,
586,17,164,4,0,
586,17,182,4,0,
586,17,185,1,16,
586,17,204,1,36,
586,17,207,4,0,
586,17,213,4,0,
586,17,214,4,0,
586,17,216,4,0,
586,17,218,4,0,
586,17,219,4,0,
586,17,224,1,1,2
586,17,237,4,0,
586,17,240,4,0,
586,17,241,4,0,
586,17,247,4,0,
586,17,263,4,0,
586,17,267,1,44,
586,17,267,4,0,
586,17,293,1,1,4
586,17,312,1,28,
586,17,412,1,32,
586,17,412,4,0,
586,17,416,4,0,
586,17,447,4,0,
586,17,496,4,0,
586,17,497,4,0,
586,17,526,4,0,
586,17,528,4,0,
586,17,532,1,0,
586,17,532,1,1,1
586,17,590,4,0,
586,18,14,4,0,
586,18,24,1,10,
586,18,26,1,24,
586,18,28,1,1,6
586,18,28,1,7,
586,18,33,1,1,3
586,18,36,1,20,
586,18,38,1,52,
586,18,45,1,1,5
586,18,45,1,4,
586,18,63,4,0,
586,18,73,1,13,
586,18,76,1,60,
586,18,76,4,0,
586,18,86,4,0,
586,18,92,4,0,
586,18,104,4,0,
586,18,113,4,0,
586,18,156,4,0,
586,18,164,4,0,
586,18,182,4,0,
586,18,185,1,16,
586,18,204,1,36,
586,18,207,4,0,
586,18,213,4,0,
586,18,214,4,0,
586,18,216,4,0,
586,18,218,4,0,
586,18,219,4,0,
586,18,224,1,1,2
586,18,237,4,0,
586,18,240,4,0,
586,18,241,4,0,
586,18,247,4,0,
586,18,263,4,0,
586,18,267,1,44,
586,18,267,4,0,
586,18,293,1,1,4
586,18,312,1,28,
586,18,412,1,32,
586,18,412,4,0,
586,18,416,4,0,
586,18,447,4,0,
586,18,496,4,0,
586,18,497,4,0,
586,18,526,4,0,
586,18,528,4,0,
586,18,532,1,0,
586,18,532,1,1,1
586,18,590,4,0,
587,11,15,4,0,
587,11,39,1,7,
587,11,84,1,1,
587,11,85,4,0,
587,11,86,4,0,
587,11,87,4,0,
587,11,92,4,0,
587,11,97,1,46,
587,11,98,1,4,
587,11,104,1,19,
587,11,104,4,0,
587,11,113,1,34,
587,11,113,4,0,
587,11,148,4,0,
587,11,156,4,0,
587,11,164,4,0,
587,11,182,4,0,
587,11,204,2,0,
587,11,207,4,0,
587,11,209,1,13,
587,11,213,4,0,
587,11,216,4,0,
587,11,218,4,0,
587,11,226,2,0,
587,11,227,1,38,
587,11,228,1,16,
587,11,231,2,0,
587,11,237,4,0,
587,11,240,4,0,
587,11,263,4,0,
587,11,268,1,10,
587,11,269,4,0,
587,11,310,2,0,
587,11,321,2,0,
587,11,332,4,0,
587,11,343,2,0,
587,11,351,1,22,
587,11,351,2,0,
587,11,355,2,0,
587,11,369,4,0,
587,11,374,4,0,
587,11,403,2,0,
587,11,435,1,50,
587,11,451,4,0,
587,11,486,1,26,
587,11,496,4,0,
587,11,512,1,30,
587,11,512,4,0,
587,11,521,1,42,
587,11,521,4,0,
587,11,528,4,0,
587,14,15,4,0,
587,14,39,1,7,
587,14,84,1,1,
587,14,85,4,0,
587,14,86,4,0,
587,14,87,4,0,
587,14,92,4,0,
587,14,97,1,46,
587,14,98,1,4,
587,14,104,1,19,
587,14,104,4,0,
587,14,113,1,34,
587,14,113,4,0,
587,14,148,4,0,
587,14,156,4,0,
587,14,164,4,0,
587,14,173,3,0,
587,14,182,4,0,
587,14,204,2,0,
587,14,207,4,0,
587,14,209,1,13,
587,14,213,4,0,
587,14,214,3,0,
587,14,216,4,0,
587,14,218,4,0,
587,14,226,2,0,
587,14,227,1,38,
587,14,228,1,16,
587,14,231,2,0,
587,14,231,3,0,
587,14,237,4,0,
587,14,240,4,0,
587,14,263,4,0,
587,14,268,1,10,
587,14,269,4,0,
587,14,270,3,0,
587,14,282,3,0,
587,14,310,2,0,
587,14,321,2,0,
587,14,324,3,0,
587,14,332,4,0,
587,14,343,2,0,
587,14,343,3,0,
587,14,351,1,22,
587,14,351,2,0,
587,14,355,2,0,
587,14,355,3,0,
587,14,366,3,0,
587,14,369,4,0,
587,14,374,4,0,
587,14,387,3,0,
587,14,403,2,0,
587,14,435,1,50,
587,14,451,4,0,
587,14,486,1,26,
587,14,496,4,0,
587,14,512,1,30,
587,14,512,4,0,
587,14,521,1,42,
587,14,521,4,0,
587,14,528,4,0,
587,15,15,4,0,
587,15,39,1,7,
587,15,84,1,1,
587,15,85,4,0,
587,15,86,4,0,
587,15,87,4,0,
587,15,92,4,0,
587,15,97,1,46,
587,15,98,1,4,
587,15,104,1,19,
587,15,104,4,0,
587,15,113,1,34,
587,15,113,4,0,
587,15,148,4,0,
587,15,156,4,0,
587,15,164,4,0,
587,15,182,4,0,
587,15,204,2,0,
587,15,207,4,0,
587,15,209,1,13,
587,15,213,4,0,
587,15,214,4,0,
587,15,216,4,0,
587,15,218,4,0,
587,15,226,2,0,
587,15,227,1,38,
587,15,228,1,16,
587,15,231,2,0,
587,15,237,4,0,
587,15,240,4,0,
587,15,263,4,0,
587,15,268,1,10,
587,15,269,4,0,
587,15,310,2,0,
587,15,321,2,0,
587,15,332,4,0,
587,15,343,2,0,
587,15,351,1,22,
587,15,351,2,0,
587,15,355,2,0,
587,15,355,4,0,
587,15,369,4,0,
587,15,374,4,0,
587,15,403,2,0,
587,15,435,1,50,
587,15,451,4,0,
587,15,486,1,26,
587,15,496,4,0,
587,15,512,1,30,
587,15,512,4,0,
587,15,521,1,42,
587,15,521,4,0,
587,15,528,4,0,
587,15,569,2,0,
587,15,590,4,0,
587,15,609,1,15,
587,16,15,4,0,
587,16,39,1,7,1
587,16,84,1,1,1
587,16,85,4,0,
587,16,86,4,0,
587,16,87,4,0,
587,16,92,4,0,
587,16,97,1,46,1
587,16,98,1,4,1
587,16,104,1,19,1
587,16,104,4,0,
587,16,113,1,34,1
587,16,113,4,0,
587,16,148,4,0,
587,16,156,4,0,
587,16,164,4,0,
587,16,173,3,0,
587,16,182,4,0,
587,16,204,2,0,
587,16,207,4,0,
587,16,209,1,13,1
587,16,213,4,0,
587,16,214,4,0,
587,16,216,4,0,
587,16,218,4,0,
587,16,226,2,0,
587,16,227,1,38,1
587,16,228,1,16,1
587,16,231,2,0,
587,16,231,3,0,
587,16,237,4,0,
587,16,240,4,0,
587,16,263,4,0,
587,16,268,1,10,1
587,16,269,4,0,
587,16,270,3,0,
587,16,282,3,0,
587,16,290,4,0,
587,16,310,2,0,
587,16,321,2,0,
587,16,324,3,0,
587,16,332,4,0,
587,16,343,2,0,
587,16,343,3,0,
587,16,351,1,22,1
587,16,351,2,0,
587,16,351,3,0,
587,16,355,2,0,
587,16,355,4,0,
587,16,366,3,0,
587,16,369,4,0,
587,16,374,4,0,
587,16,387,3,0,
587,16,403,2,0,
587,16,435,1,50,1
587,16,451,4,0,
587,16,486,1,26,1
587,16,496,4,0,
587,16,512,1,30,1
587,16,512,4,0,
587,16,521,1,42,1
587,16,521,4,0,
587,16,527,3,0,
587,16,528,4,0,
587,16,569,2,0,
587,16,590,4,0,
587,16,609,1,15,1
587,17,39,1,7,
587,17,84,1,1,
587,17,85,4,0,
587,17,86,4,0,
587,17,87,4,0,
587,17,92,4,0,
587,17,97,1,46,
587,17,98,1,4,
587,17,104,1,19,
587,17,104,4,0,
587,17,113,1,34,
587,17,113,4,0,
587,17,156,4,0,
587,17,164,4,0,
587,17,182,4,0,
587,17,204,2,0,
587,17,207,4,0,
587,17,209,1,13,
587,17,213,4,0,
587,17,214,4,0,
587,17,216,4,0,
587,17,218,4,0,
587,17,226,2,0,
587,17,227,1,38,
587,17,228,1,16,
587,17,231,2,0,
587,17,237,4,0,
587,17,240,4,0,
587,17,263,4,0,
587,17,268,1,10,
587,17,269,4,0,
587,17,310,2,0,
587,17,321,2,0,
587,17,332,4,0,
587,17,343,2,0,
587,17,351,1,22,
587,17,351,2,0,
587,17,355,2,0,
587,17,355,4,0,
587,17,369,4,0,
587,17,374,4,0,
587,17,403,2,0,
587,17,435,1,50,
587,17,451,4,0,
587,17,486,1,26,
587,17,496,4,0,
587,17,512,1,30,
587,17,512,4,0,
587,17,521,1,42,
587,17,521,4,0,
587,17,528,4,0,
587,17,569,2,0,
587,17,590,4,0,
587,17,609,1,15,
587,17,683,2,0,
587,18,39,1,7,
587,18,84,1,1,
587,18,85,4,0,
587,18,86,4,0,
587,18,87,4,0,
587,18,92,4,0,
587,18,97,1,46,
587,18,98,1,4,
587,18,104,1,19,
587,18,104,4,0,
587,18,113,1,34,
587,18,113,4,0,
587,18,156,4,0,
587,18,164,4,0,
587,18,182,4,0,
587,18,204,2,0,
587,18,207,4,0,
587,18,209,1,13,
587,18,213,4,0,
587,18,214,4,0,
587,18,216,4,0,
587,18,218,4,0,
587,18,226,2,0,
587,18,227,1,38,
587,18,228,1,16,
587,18,231,2,0,
587,18,237,4,0,
587,18,240,4,0,
587,18,263,4,0,
587,18,268,1,10,
587,18,269,4,0,
587,18,310,2,0,
587,18,321,2,0,
587,18,332,4,0,
587,18,343,2,0,
587,18,351,1,22,
587,18,351,2,0,
587,18,355,2,0,
587,18,355,4,0,
587,18,369,4,0,
587,18,374,4,0,
587,18,403,2,0,
587,18,435,1,50,
587,18,451,4,0,
587,18,486,1,26,
587,18,496,4,0,
587,18,512,1,30,
587,18,512,4,0,
587,18,521,1,42,
587,18,521,4,0,
587,18,528,4,0,
587,18,569,2,0,
587,18,590,4,0,
587,18,609,1,15,
587,18,683,2,0,
588,11,14,1,52,
588,11,14,4,0,
588,11,15,4,0,
588,11,29,1,20,
588,11,30,2,0,
588,11,31,1,16,
588,11,36,1,37,
588,11,38,1,56,
588,11,43,1,4,
588,11,64,1,1,
588,11,68,2,0,
588,11,92,4,0,
588,11,103,2,0,
588,11,104,4,0,
588,11,156,4,0,
588,11,163,1,32,
588,11,164,4,0,
588,11,175,1,49,
588,11,182,4,0,
588,11,184,1,40,
588,11,185,2,0,
588,11,203,1,8,
588,11,206,1,25,
588,11,206,4,0,
588,11,207,4,0,
588,11,210,1,13,
588,11,213,4,0,
588,11,216,4,0,
588,11,218,4,0,
588,11,224,2,0,
588,11,228,2,0,
588,11,237,4,0,
588,11,240,4,0,
588,11,263,4,0,
588,11,282,2,0,
588,11,332,4,0,
588,11,398,4,0,
588,11,404,1,44,
588,11,404,4,0,
588,11,405,1,28,
588,11,412,4,0,
588,11,450,2,0,
588,11,496,4,0,
588,11,522,4,0,
588,14,14,1,52,
588,14,14,4,0,
588,14,15,4,0,
588,14,29,1,20,
588,14,30,2,0,
588,14,31,1,16,
588,14,36,1,37,
588,14,38,1,56,
588,14,43,1,4,
588,14,64,1,1,
588,14,68,2,0,
588,14,92,4,0,
588,14,103,2,0,
588,14,104,4,0,
588,14,156,4,0,
588,14,163,1,32,
588,14,164,4,0,
588,14,173,3,0,
588,14,175,1,49,
588,14,182,4,0,
588,14,184,1,40,
588,14,185,2,0,
588,14,202,3,0,
588,14,203,1,8,
588,14,206,1,25,
588,14,206,4,0,
588,14,207,4,0,
588,14,210,1,13,
588,14,213,4,0,
588,14,214,3,0,
588,14,216,4,0,
588,14,218,4,0,
588,14,224,2,0,
588,14,228,2,0,
588,14,237,4,0,
588,14,240,4,0,
588,14,263,4,0,
588,14,282,2,0,
588,14,282,3,0,
588,14,332,4,0,
588,14,334,3,0,
588,14,398,4,0,
588,14,404,1,44,
588,14,404,4,0,
588,14,405,1,28,
588,14,412,4,0,
588,14,450,2,0,
588,14,450,3,0,
588,14,496,4,0,
588,14,522,4,0,
588,15,14,1,52,
588,15,14,4,0,
588,15,15,4,0,
588,15,29,1,20,
588,15,30,2,0,
588,15,31,1,16,
588,15,36,1,37,
588,15,38,1,56,
588,15,43,1,4,
588,15,64,1,1,
588,15,68,2,0,
588,15,92,4,0,
588,15,103,2,0,
588,15,104,4,0,
588,15,156,4,0,
588,15,163,1,32,
588,15,164,4,0,
588,15,175,1,49,
588,15,182,4,0,
588,15,184,1,40,
588,15,185,2,0,
588,15,203,1,8,
588,15,206,1,25,
588,15,206,4,0,
588,15,207,4,0,
588,15,210,1,13,
588,15,213,4,0,
588,15,214,4,0,
588,15,216,4,0,
588,15,218,4,0,
588,15,224,2,0,
588,15,228,2,0,
588,15,237,4,0,
588,15,240,4,0,
588,15,263,4,0,
588,15,282,2,0,
588,15,332,4,0,
588,15,398,4,0,
588,15,404,1,44,
588,15,404,4,0,
588,15,405,1,28,
588,15,412,4,0,
588,15,450,2,0,
588,15,496,4,0,
588,15,522,4,0,
588,15,529,2,0,
588,15,590,4,0,
588,15,611,4,0,
588,16,14,1,52,1
588,16,14,4,0,
588,16,15,4,0,
588,16,29,1,20,1
588,16,30,2,0,
588,16,31,1,16,1
588,16,36,1,37,1
588,16,38,1,56,1
588,16,43,1,4,1
588,16,64,1,1,1
588,16,68,2,0,
588,16,92,4,0,
588,16,103,2,0,
588,16,104,4,0,
588,16,156,4,0,
588,16,163,1,32,1
588,16,164,4,0,
588,16,173,3,0,
588,16,175,1,49,1
588,16,182,4,0,
588,16,184,1,40,1
588,16,185,2,0,
588,16,202,3,0,
588,16,203,1,8,1
588,16,206,1,25,1
588,16,206,4,0,
588,16,207,4,0,
588,16,210,1,13,1
588,16,213,4,0,
588,16,214,4,0,
588,16,216,4,0,
588,16,218,4,0,
588,16,224,2,0,
588,16,228,2,0,
588,16,237,4,0,
588,16,240,4,0,
588,16,263,4,0,
588,16,282,2,0,
588,16,282,3,0,
588,16,290,4,0,
588,16,324,3,0,
588,16,332,4,0,
588,16,334,3,0,
588,16,398,4,0,
588,16,404,1,44,1
588,16,404,4,0,
588,16,405,1,28,1
588,16,412,4,0,
588,16,450,2,0,
588,16,450,3,0,
588,16,496,4,0,
588,16,522,4,0,
588,16,529,2,0,
588,16,529,3,0,
588,16,590,4,0,
588,16,611,4,0,
588,17,14,1,52,
588,17,14,4,0,
588,17,29,1,20,
588,17,30,2,0,
588,17,31,1,16,
588,17,36,1,37,
588,17,38,1,56,
588,17,43,1,4,
588,17,64,1,1,
588,17,68,2,0,
588,17,92,4,0,
588,17,103,2,0,
588,17,104,4,0,
588,17,156,4,0,
588,17,163,1,32,
588,17,164,4,0,
588,17,175,1,49,
588,17,182,4,0,
588,17,184,1,40,
588,17,185,2,0,
588,17,203,1,8,
588,17,206,1,25,
588,17,206,4,0,
588,17,207,4,0,
588,17,210,1,13,
588,17,213,4,0,
588,17,214,4,0,
588,17,216,4,0,
588,17,218,4,0,
588,17,224,2,0,
588,17,228,2,0,
588,17,237,4,0,
588,17,240,4,0,
588,17,263,4,0,
588,17,282,2,0,
588,17,332,4,0,
588,17,398,4,0,
588,17,404,1,44,
588,17,404,4,0,
588,17,405,1,28,
588,17,412,4,0,
588,17,450,2,0,
588,17,496,4,0,
588,17,529,2,0,
588,17,590,4,0,
588,17,611,4,0,
588,18,14,1,52,
588,18,14,4,0,
588,18,29,1,20,
588,18,30,2,0,
588,18,31,1,16,
588,18,36,1,37,
588,18,38,1,56,
588,18,43,1,4,
588,18,64,1,1,
588,18,68,2,0,
588,18,92,4,0,
588,18,103,2,0,
588,18,104,4,0,
588,18,156,4,0,
588,18,163,1,32,
588,18,164,4,0,
588,18,175,1,49,
588,18,182,4,0,
588,18,184,1,40,
588,18,185,2,0,
588,18,203,1,8,
588,18,206,1,25,
588,18,206,4,0,
588,18,207,4,0,
588,18,210,1,13,
588,18,213,4,0,
588,18,214,4,0,
588,18,216,4,0,
588,18,218,4,0,
588,18,224,2,0,
588,18,228,2,0,
588,18,237,4,0,
588,18,240,4,0,
588,18,263,4,0,
588,18,282,2,0,
588,18,332,4,0,
588,18,398,4,0,
588,18,404,1,44,
588,18,404,4,0,
588,18,405,1,28,
588,18,412,4,0,
588,18,450,2,0,
588,18,496,4,0,
588,18,529,2,0,
588,18,590,4,0,
588,18,611,4,0,
589,11,14,1,52,
589,11,14,4,0,
589,11,15,4,0,
589,11,29,1,20,
589,11,31,1,16,
589,11,41,1,1,4
589,11,41,1,13,
589,11,43,1,1,2
589,11,43,1,4,
589,11,63,4,0,
589,11,64,1,1,1
589,11,92,4,0,
589,11,104,4,0,
589,11,156,4,0,
589,11,163,1,32,
589,11,164,4,0,
589,11,179,1,49,
589,11,182,4,0,
589,11,206,1,25,
589,11,206,4,0,
589,11,207,4,0,
589,11,213,4,0,
589,11,216,4,0,
589,11,218,4,0,
589,11,237,4,0,
589,11,240,4,0,
589,11,249,4,0,
589,11,263,4,0,
589,11,332,4,0,
589,11,334,1,40,
589,11,398,4,0,
589,11,404,1,44,
589,11,404,4,0,
589,11,405,1,28,
589,11,411,4,0,
589,11,412,4,0,
589,11,416,1,56,
589,11,416,4,0,
589,11,442,1,37,
589,11,496,4,0,
589,11,501,1,1,3
589,11,501,1,8,
589,11,522,4,0,
589,14,14,1,52,
589,14,14,4,0,
589,14,15,4,0,
589,14,29,1,20,
589,14,31,1,16,
589,14,41,1,1,4
589,14,41,1,13,
589,14,43,1,1,2
589,14,43,1,4,
589,14,63,4,0,
589,14,64,1,1,1
589,14,92,4,0,
589,14,104,4,0,
589,14,156,4,0,
589,14,163,1,32,
589,14,164,4,0,
589,14,173,3,0,
589,14,179,1,49,
589,14,182,4,0,
589,14,202,3,0,
589,14,206,1,25,
589,14,206,4,0,
589,14,207,4,0,
589,14,213,4,0,
589,14,214,3,0,
589,14,216,4,0,
589,14,218,4,0,
589,14,237,4,0,
589,14,240,4,0,
589,14,249,4,0,
589,14,263,4,0,
589,14,282,3,0,
589,14,332,4,0,
589,14,334,1,40,
589,14,334,3,0,
589,14,398,4,0,
589,14,404,1,44,
589,14,404,4,0,
589,14,405,1,28,
589,14,411,4,0,
589,14,412,4,0,
589,14,416,1,56,
589,14,416,4,0,
589,14,442,1,37,
589,14,442,3,0,
589,14,450,3,0,
589,14,496,4,0,
589,14,501,1,1,3
589,14,501,1,8,
589,14,522,4,0,
589,15,14,1,52,
589,15,14,4,0,
589,15,15,4,0,
589,15,29,1,20,
589,15,31,1,16,
589,15,38,1,1,1
589,15,41,1,1,6
589,15,41,1,13,
589,15,43,1,1,4
589,15,43,1,4,
589,15,63,4,0,
589,15,64,1,1,3
589,15,92,4,0,
589,15,104,4,0,
589,15,156,4,0,
589,15,163,1,32,
589,15,164,4,0,
589,15,179,1,49,
589,15,182,4,0,
589,15,206,1,25,
589,15,206,4,0,
589,15,207,4,0,
589,15,213,4,0,
589,15,214,4,0,
589,15,216,4,0,
589,15,218,4,0,
589,15,237,4,0,
589,15,240,4,0,
589,15,249,4,0,
589,15,263,4,0,
589,15,332,4,0,
589,15,334,1,40,
589,15,398,4,0,
589,15,404,1,44,
589,15,404,4,0,
589,15,405,1,28,
589,15,411,4,0,
589,15,412,4,0,
589,15,416,1,56,
589,15,416,4,0,
589,15,442,1,37,
589,15,496,4,0,
589,15,501,1,1,5
589,15,501,1,8,
589,15,522,4,0,
589,15,565,1,1,2
589,15,565,1,60,
589,15,590,4,0,
589,15,611,4,0,
589,16,14,1,52,1
589,16,14,4,0,
589,16,15,4,0,
589,16,29,1,20,1
589,16,31,1,16,1
589,16,38,1,1,1
589,16,41,1,1,6
589,16,41,1,13,1
589,16,43,1,1,4
589,16,43,1,4,1
589,16,63,4,0,
589,16,64,1,1,3
589,16,92,4,0,
589,16,104,4,0,
589,16,156,4,0,
589,16,163,1,32,1
589,16,164,4,0,
589,16,173,3,0,
589,16,179,1,49,1
589,16,182,4,0,
589,16,202,3,0,
589,16,206,1,25,1
589,16,206,4,0,
589,16,207,4,0,
589,16,213,4,0,
589,16,214,4,0,
589,16,216,4,0,
589,16,218,4,0,
589,16,237,4,0,
589,16,240,4,0,
589,16,249,4,0,
589,16,263,4,0,
589,16,282,3,0,
589,16,290,4,0,
589,16,324,3,0,
589,16,332,4,0,
589,16,334,1,40,1
589,16,334,3,0,
589,16,398,4,0,
589,16,404,1,44,1
589,16,404,4,0,
589,16,405,1,28,1
589,16,411,4,0,
589,16,412,4,0,
589,16,416,1,56,1
589,16,416,4,0,
589,16,442,1,37,1
589,16,442,3,0,
589,16,450,3,0,
589,16,496,4,0,
589,16,501,1,1,5
589,16,501,1,8,1
589,16,522,4,0,
589,16,529,3,0,
589,16,565,1,1,2
589,16,565,1,60,1
589,16,590,4,0,
589,16,611,4,0,
589,17,14,1,52,
589,17,14,4,0,
589,17,29,1,20,
589,17,31,1,16,
589,17,38,1,1,1
589,17,41,1,1,6
589,17,41,1,13,
589,17,43,1,1,4
589,17,43,1,4,
589,17,63,4,0,
589,17,64,1,1,3
589,17,92,4,0,
589,17,104,4,0,
589,17,156,4,0,
589,17,163,1,32,
589,17,164,4,0,
589,17,179,1,49,
589,17,182,4,0,
589,17,206,1,25,
589,17,206,4,0,
589,17,207,4,0,
589,17,213,4,0,
589,17,214,4,0,
589,17,216,4,0,
589,17,218,4,0,
589,17,237,4,0,
589,17,240,4,0,
589,17,263,4,0,
589,17,332,4,0,
589,17,334,1,40,
589,17,398,4,0,
589,17,404,1,44,
589,17,404,4,0,
589,17,405,1,28,
589,17,411,4,0,
589,17,412,4,0,
589,17,416,1,56,
589,17,416,4,0,
589,17,442,1,37,
589,17,496,4,0,
589,17,501,1,1,5
589,17,501,1,8,
589,17,565,1,1,2
589,17,565,1,60,
589,17,590,4,0,
589,17,611,4,0,
589,17,684,4,0,
589,17,693,4,0,
589,18,14,1,52,
589,18,14,4,0,
589,18,29,1,20,
589,18,31,1,16,
589,18,38,1,1,1
589,18,41,1,1,6
589,18,41,1,13,
589,18,43,1,1,4
589,18,43,1,4,
589,18,63,4,0,
589,18,64,1,1,3
589,18,92,4,0,
589,18,104,4,0,
589,18,156,4,0,
589,18,163,1,32,
589,18,164,4,0,
589,18,179,1,49,
589,18,182,4,0,
589,18,206,1,25,
589,18,206,4,0,
589,18,207,4,0,
589,18,213,4,0,
589,18,214,4,0,
589,18,216,4,0,
589,18,218,4,0,
589,18,237,4,0,
589,18,240,4,0,
589,18,263,4,0,
589,18,332,4,0,
589,18,334,1,40,
589,18,398,4,0,
589,18,404,1,44,
589,18,404,4,0,
589,18,405,1,28,
589,18,411,4,0,
589,18,412,4,0,
589,18,416,1,56,
589,18,416,4,0,
589,18,442,1,37,
589,18,496,4,0,
589,18,501,1,1,5
589,18,501,1,8,
589,18,565,1,1,2
589,18,565,1,60,
589,18,590,4,0,
589,18,611,4,0,
589,18,684,4,0,
589,18,693,4,0,
590,11,34,2,0,
590,11,71,1,1,
590,11,72,1,15,
590,11,74,1,6,
590,11,74,2,0,
590,11,76,1,43,
590,11,76,4,0,
590,11,77,2,0,
590,11,78,2,0,
590,11,92,1,32,
590,11,92,4,0,
590,11,104,4,0,
590,11,111,2,0,
590,11,117,1,12,
590,11,147,1,50,
590,11,148,4,0,
590,11,156,4,0,
590,11,164,4,0,
590,11,182,4,0,
590,11,185,1,20,
590,11,188,4,0,
590,11,202,1,28,
590,11,203,2,0,
590,11,205,2,0,
590,11,207,4,0,
590,11,213,4,0,
590,11,216,4,0,
590,11,218,4,0,
590,11,230,1,24,
590,11,235,1,35,
590,11,237,4,0,
590,11,240,4,0,
590,11,241,4,0,
590,11,263,4,0,
590,11,275,1,18,
590,11,310,1,8,
590,11,371,4,0,
590,11,380,2,0,
590,11,412,4,0,
590,11,447,4,0,
590,11,474,4,0,
590,11,476,1,45,
590,11,496,4,0,
590,11,499,1,39,
590,14,34,2,0,
590,14,71,1,1,
590,14,72,1,15,
590,14,74,1,6,
590,14,74,2,0,
590,14,76,1,43,
590,14,76,4,0,
590,14,77,2,0,
590,14,78,2,0,
590,14,92,1,32,
590,14,92,4,0,
590,14,104,4,0,
590,14,111,2,0,
590,14,117,1,12,
590,14,147,1,50,
590,14,148,4,0,
590,14,156,4,0,
590,14,164,4,0,
590,14,173,3,0,
590,14,182,4,0,
590,14,185,1,20,
590,14,188,4,0,
590,14,202,1,28,
590,14,202,3,0,
590,14,203,2,0,
590,14,205,2,0,
590,14,207,4,0,
590,14,213,4,0,
590,14,214,3,0,
590,14,216,4,0,
590,14,218,4,0,
590,14,230,1,24,
590,14,235,1,35,
590,14,235,3,0,
590,14,237,4,0,
590,14,240,4,0,
590,14,241,4,0,
590,14,263,4,0,
590,14,275,1,18,
590,14,310,1,8,
590,14,371,4,0,
590,14,380,2,0,
590,14,380,3,0,
590,14,388,3,0,
590,14,402,3,0,
590,14,412,4,0,
590,14,447,4,0,
590,14,474,4,0,
590,14,476,1,45,
590,14,492,3,0,
590,14,495,3,0,
590,14,496,4,0,
590,14,499,1,39,
590,15,34,2,0,
590,15,71,1,1,
590,15,72,1,15,
590,15,74,1,6,
590,15,74,2,0,
590,15,76,1,43,
590,15,76,4,0,
590,15,77,2,0,
590,15,78,2,0,
590,15,92,1,32,
590,15,92,4,0,
590,15,104,4,0,
590,15,111,2,0,
590,15,117,1,12,
590,15,147,1,50,
590,15,148,4,0,
590,15,156,4,0,
590,15,164,4,0,
590,15,182,4,0,
590,15,185,1,20,
590,15,188,4,0,
590,15,202,1,28,
590,15,203,2,0,
590,15,205,2,0,
590,15,207,4,0,
590,15,213,4,0,
590,15,214,4,0,
590,15,216,4,0,
590,15,218,4,0,
590,15,230,1,24,
590,15,235,1,35,
590,15,237,4,0,
590,15,240,4,0,
590,15,241,4,0,
590,15,263,4,0,
590,15,267,4,0,
590,15,275,1,18,
590,15,310,1,8,
590,15,371,4,0,
590,15,380,2,0,
590,15,412,4,0,
590,15,447,4,0,
590,15,474,4,0,
590,15,476,1,45,
590,15,496,4,0,
590,15,499,1,39,
590,15,590,4,0,
590,16,34,2,0,
590,16,71,1,1,1
590,16,72,1,15,1
590,16,74,1,6,1
590,16,74,2,0,
590,16,76,1,43,1
590,16,76,4,0,
590,16,77,2,0,
590,16,78,2,0,
590,16,92,1,32,1
590,16,92,4,0,
590,16,104,4,0,
590,16,111,2,0,
590,16,117,1,12,1
590,16,147,1,50,1
590,16,148,4,0,
590,16,156,4,0,
590,16,164,4,0,
590,16,173,3,0,
590,16,182,4,0,
590,16,185,1,20,1
590,16,188,4,0,
590,16,202,1,28,1
590,16,202,3,0,
590,16,203,2,0,
590,16,205,2,0,
590,16,207,4,0,
590,16,213,4,0,
590,16,214,4,0,
590,16,216,4,0,
590,16,218,4,0,
590,16,230,1,24,1
590,16,235,1,35,1
590,16,235,3,0,
590,16,237,4,0,
590,16,240,4,0,
590,16,241,4,0,
590,16,263,4,0,
590,16,267,4,0,
590,16,275,1,18,1
590,16,290,4,0,
590,16,310,1,8,1
590,16,371,4,0,
590,16,380,2,0,
590,16,380,3,0,
590,16,388,3,0,
590,16,402,3,0,
590,16,412,4,0,
590,16,447,4,0,
590,16,474,4,0,
590,16,476,1,45,1
590,16,492,3,0,
590,16,495,3,0,
590,16,496,4,0,
590,16,499,1,39,1
590,16,590,4,0,
590,17,34,2,0,
590,17,71,1,1,
590,17,72,1,15,
590,17,74,1,6,
590,17,74,2,0,
590,17,76,1,43,
590,17,76,4,0,
590,17,77,2,0,
590,17,78,2,0,
590,17,92,1,32,
590,17,92,4,0,
590,17,104,4,0,
590,17,111,2,0,
590,17,117,1,12,
590,17,147,1,50,
590,17,156,4,0,
590,17,164,4,0,
590,17,182,4,0,
590,17,185,1,20,
590,17,188,4,0,
590,17,202,1,28,
590,17,203,2,0,
590,17,205,2,0,
590,17,207,4,0,
590,17,213,4,0,
590,17,214,4,0,
590,17,216,4,0,
590,17,218,4,0,
590,17,230,1,24,
590,17,235,1,35,
590,17,237,4,0,
590,17,240,4,0,
590,17,241,4,0,
590,17,263,4,0,
590,17,267,4,0,
590,17,275,1,18,
590,17,310,1,8,
590,17,371,4,0,
590,17,380,2,0,
590,17,412,4,0,
590,17,447,4,0,
590,17,474,4,0,
590,17,476,1,45,
590,17,496,4,0,
590,17,499,1,39,
590,17,590,4,0,
590,18,34,2,0,
590,18,71,1,1,
590,18,72,1,15,
590,18,74,1,6,
590,18,74,2,0,
590,18,76,1,43,
590,18,76,4,0,
590,18,77,2,0,
590,18,78,2,0,
590,18,92,1,32,
590,18,92,4,0,
590,18,104,4,0,
590,18,111,2,0,
590,18,117,1,12,
590,18,147,1,50,
590,18,156,4,0,
590,18,164,4,0,
590,18,182,4,0,
590,18,185,1,20,
590,18,188,4,0,
590,18,202,1,28,
590,18,203,2,0,
590,18,205,2,0,
590,18,207,4,0,
590,18,213,4,0,
590,18,214,4,0,
590,18,216,4,0,
590,18,218,4,0,
590,18,230,1,24,
590,18,235,1,35,
590,18,237,4,0,
590,18,240,4,0,
590,18,241,4,0,
590,18,263,4,0,
590,18,267,4,0,
590,18,275,1,18,
590,18,310,1,8,
590,18,371,4,0,
590,18,380,2,0,
590,18,412,4,0,
590,18,447,4,0,
590,18,474,4,0,
590,18,476,1,45,
590,18,496,4,0,
590,18,499,1,39,
590,18,590,4,0,
591,11,63,4,0,
591,11,71,1,1,1
591,11,72,1,15,
591,11,74,1,1,2
591,11,74,1,6,
591,11,76,1,49,
591,11,76,4,0,
591,11,92,1,32,
591,11,92,4,0,
591,11,104,4,0,
591,11,117,1,1,4
591,11,117,1,12,
591,11,147,1,62,
591,11,148,4,0,
591,11,156,4,0,
591,11,164,4,0,
591,11,182,4,0,
591,11,185,1,20,
591,11,188,4,0,
591,11,202,1,28,
591,11,207,4,0,
591,11,213,4,0,
591,11,216,4,0,
591,11,218,4,0,
591,11,230,1,24,
591,11,235,1,35,
591,11,237,4,0,
591,11,240,4,0,
591,11,241,4,0,
591,11,263,4,0,
591,11,275,1,18,
591,11,310,1,1,3
591,11,310,1,8,
591,11,371,4,0,
591,11,412,4,0,
591,11,416,4,0,
591,11,447,4,0,
591,11,474,4,0,
591,11,476,1,54,
591,11,496,4,0,
591,11,499,1,43,
591,14,63,4,0,
591,14,71,1,1,1
591,14,72,1,15,
591,14,74,1,1,2
591,14,74,1,6,
591,14,76,1,49,
591,14,76,4,0,
591,14,92,1,32,
591,14,92,4,0,
591,14,104,4,0,
591,14,117,1,1,4
591,14,117,1,12,
591,14,147,1,62,
591,14,148,4,0,
591,14,156,4,0,
591,14,164,4,0,
591,14,173,3,0,
591,14,182,4,0,
591,14,185,1,20,
591,14,188,4,0,
591,14,202,1,28,
591,14,202,3,0,
591,14,207,4,0,
591,14,213,4,0,
591,14,214,3,0,
591,14,216,4,0,
591,14,218,4,0,
591,14,230,1,24,
591,14,235,1,35,
591,14,235,3,0,
591,14,237,4,0,
591,14,240,4,0,
591,14,241,4,0,
591,14,263,4,0,
591,14,275,1,18,
591,14,310,1,1,3
591,14,310,1,8,
591,14,371,4,0,
591,14,380,3,0,
591,14,388,3,0,
591,14,402,3,0,
591,14,412,4,0,
591,14,416,4,0,
591,14,447,4,0,
591,14,474,4,0,
591,14,476,1,54,
591,14,492,3,0,
591,14,495,3,0,
591,14,496,4,0,
591,14,499,1,43,
591,15,63,4,0,
591,15,71,1,1,1
591,15,72,1,15,
591,15,74,1,1,2
591,15,74,1,6,
591,15,76,1,49,
591,15,76,4,0,
591,15,92,1,32,
591,15,92,4,0,
591,15,104,4,0,
591,15,117,1,1,4
591,15,117,1,12,
591,15,147,1,62,
591,15,148,4,0,
591,15,156,4,0,
591,15,164,4,0,
591,15,182,4,0,
591,15,185,1,20,
591,15,188,4,0,
591,15,202,1,28,
591,15,207,4,0,
591,15,213,4,0,
591,15,214,4,0,
591,15,216,4,0,
591,15,218,4,0,
591,15,230,1,24,
591,15,235,1,35,
591,15,237,4,0,
591,15,240,4,0,
591,15,241,4,0,
591,15,263,4,0,
591,15,267,4,0,
591,15,275,1,18,
591,15,310,1,1,3
591,15,310,1,8,
591,15,371,4,0,
591,15,412,4,0,
591,15,416,4,0,
591,15,447,4,0,
591,15,474,4,0,
591,15,476,1,54,
591,15,496,4,0,
591,15,499,1,43,
591,15,590,4,0,
591,16,63,4,0,
591,16,71,1,1,1
591,16,72,1,15,1
591,16,74,1,1,2
591,16,74,1,6,1
591,16,76,1,49,1
591,16,76,4,0,
591,16,92,1,32,1
591,16,92,4,0,
591,16,104,4,0,
591,16,117,1,1,4
591,16,117,1,12,1
591,16,147,1,62,1
591,16,148,4,0,
591,16,156,4,0,
591,16,164,4,0,
591,16,173,3,0,
591,16,182,4,0,
591,16,185,1,20,1
591,16,188,4,0,
591,16,202,1,28,1
591,16,202,3,0,
591,16,207,4,0,
591,16,213,4,0,
591,16,214,4,0,
591,16,216,4,0,
591,16,218,4,0,
591,16,230,1,24,1
591,16,235,1,35,1
591,16,235,3,0,
591,16,237,4,0,
591,16,240,4,0,
591,16,241,4,0,
591,16,263,4,0,
591,16,267,4,0,
591,16,275,1,18,1
591,16,290,4,0,
591,16,310,1,1,3
591,16,310,1,8,1
591,16,371,4,0,
591,16,380,3,0,
591,16,388,3,0,
591,16,402,3,0,
591,16,412,4,0,
591,16,416,4,0,
591,16,447,4,0,
591,16,474,4,0,
591,16,476,1,54,1
591,16,492,3,0,
591,16,495,3,0,
591,16,496,4,0,
591,16,499,1,43,1
591,16,590,4,0,
591,17,63,4,0,
591,17,71,1,1,1
591,17,72,1,15,
591,17,74,1,1,2
591,17,74,1,6,
591,17,76,1,49,
591,17,76,4,0,
591,17,92,1,32,
591,17,92,4,0,
591,17,104,4,0,
591,17,117,1,1,4
591,17,117,1,12,
591,17,147,1,62,
591,17,156,4,0,
591,17,164,4,0,
591,17,182,4,0,
591,17,185,1,20,
591,17,188,4,0,
591,17,202,1,28,
591,17,207,4,0,
591,17,213,4,0,
591,17,214,4,0,
591,17,216,4,0,
591,17,218,4,0,
591,17,230,1,24,
591,17,235,1,35,
591,17,237,4,0,
591,17,240,4,0,
591,17,241,4,0,
591,17,263,4,0,
591,17,267,4,0,
591,17,275,1,18,
591,17,310,1,1,3
591,17,310,1,8,
591,17,371,4,0,
591,17,412,4,0,
591,17,416,4,0,
591,17,447,4,0,
591,17,474,4,0,
591,17,476,1,54,
591,17,496,4,0,
591,17,499,1,43,
591,17,590,4,0,
591,18,63,4,0,
591,18,71,1,1,1
591,18,72,1,15,
591,18,74,1,1,2
591,18,74,1,6,
591,18,76,1,49,
591,18,76,4,0,
591,18,92,1,32,
591,18,92,4,0,
591,18,104,4,0,
591,18,117,1,1,4
591,18,117,1,12,
591,18,147,1,62,
591,18,156,4,0,
591,18,164,4,0,
591,18,182,4,0,
591,18,185,1,20,
591,18,188,4,0,
591,18,202,1,28,
591,18,207,4,0,
591,18,213,4,0,
591,18,214,4,0,
591,18,216,4,0,
591,18,218,4,0,
591,18,230,1,24,
591,18,235,1,35,
591,18,237,4,0,
591,18,240,4,0,
591,18,241,4,0,
591,18,263,4,0,
591,18,267,4,0,
591,18,275,1,18,
591,18,310,1,1,3
591,18,310,1,8,
591,18,371,4,0,
591,18,412,4,0,
591,18,416,4,0,
591,18,447,4,0,
591,18,474,4,0,
591,18,476,1,54,
591,18,496,4,0,
591,18,499,1,43,
591,18,590,4,0,
592,11,54,2,0,
592,11,56,1,49,
592,11,57,4,0,
592,11,58,4,0,
592,11,59,4,0,
592,11,61,1,13,
592,11,71,1,5,
592,11,92,4,0,
592,11,94,4,0,
592,11,101,1,9,
592,11,104,4,0,
592,11,105,1,17,
592,11,105,2,0,
592,11,109,2,0,
592,11,127,4,0,
592,11,132,2,0,
592,11,138,4,0,
592,11,145,1,1,1
592,11,148,4,0,
592,11,151,2,0,
592,11,156,4,0,
592,11,164,4,0,
592,11,182,4,0,
592,11,188,4,0,
592,11,207,4,0,
592,11,213,4,0,
592,11,216,4,0,
592,11,218,4,0,
592,11,219,4,0,
592,11,220,2,0,
592,11,237,4,0,
592,11,240,1,37,
592,11,240,4,0,
592,11,244,4,0,
592,11,247,4,0,
592,11,258,4,0,
592,11,261,4,0,
592,11,263,4,0,
592,11,269,4,0,
592,11,291,4,0,
592,11,323,1,61,
592,11,346,1,1,2
592,11,352,1,22,
592,11,362,1,32,
592,11,378,1,55,
592,11,412,4,0,
592,11,433,4,0,
592,11,466,1,27,
592,11,482,4,0,
592,11,496,4,0,
592,11,503,4,0,
592,11,506,1,43,
592,14,20,3,0,
592,14,54,2,0,
592,14,56,1,49,
592,14,57,4,0,
592,14,58,4,0,
592,14,59,4,0,
592,14,61,1,13,
592,14,71,1,5,
592,14,92,4,0,
592,14,94,4,0,
592,14,101,1,9,
592,14,104,4,0,
592,14,105,1,17,
592,14,105,2,0,
592,14,109,2,0,
592,14,127,4,0,
592,14,132,2,0,
592,14,138,4,0,
592,14,145,1,1,1
592,14,148,4,0,
592,14,151,2,0,
592,14,156,4,0,
592,14,164,4,0,
592,14,173,3,0,
592,14,180,3,0,
592,14,182,4,0,
592,14,188,4,0,
592,14,196,3,0,
592,14,202,3,0,
592,14,207,4,0,
592,14,213,4,0,
592,14,214,3,0,
592,14,216,4,0,
592,14,218,4,0,
592,14,219,4,0,
592,14,220,2,0,
592,14,220,3,0,
592,14,237,4,0,
592,14,240,1,37,
592,14,240,4,0,
592,14,244,4,0,
592,14,247,4,0,
592,14,258,4,0,
592,14,261,4,0,
592,14,263,4,0,
592,14,269,4,0,
592,14,271,3,0,
592,14,277,3,0,
592,14,291,4,0,
592,14,323,1,61,
592,14,346,1,1,2
592,14,352,1,22,
592,14,362,1,32,
592,14,378,1,55,
592,14,399,3,0,
592,14,412,4,0,
592,14,433,4,0,
592,14,466,1,27,
592,14,482,4,0,
592,14,496,4,0,
592,14,503,4,0,
592,14,506,1,43,
592,15,54,2,0,
592,15,56,1,49,
592,15,57,4,0,
592,15,58,4,0,
592,15,59,4,0,
592,15,61,1,13,
592,15,71,1,5,
592,15,92,4,0,
592,15,94,4,0,
592,15,101,1,9,
592,15,104,4,0,
592,15,105,1,17,
592,15,105,2,0,
592,15,109,2,0,
592,15,127,4,0,
592,15,132,2,0,
592,15,138,4,0,
592,15,145,1,1,1
592,15,148,4,0,
592,15,151,2,0,
592,15,156,4,0,
592,15,164,4,0,
592,15,182,4,0,
592,15,188,4,0,
592,15,207,4,0,
592,15,213,4,0,
592,15,214,4,0,
592,15,216,4,0,
592,15,218,4,0,
592,15,219,4,0,
592,15,220,2,0,
592,15,237,4,0,
592,15,240,1,37,
592,15,240,4,0,
592,15,244,4,0,
592,15,247,4,0,
592,15,258,4,0,
592,15,261,4,0,
592,15,263,4,0,
592,15,269,4,0,
592,15,323,1,61,
592,15,346,1,1,2
592,15,352,1,22,
592,15,362,1,32,
592,15,378,1,55,
592,15,399,4,0,
592,15,412,4,0,
592,15,433,4,0,
592,15,466,1,27,
592,15,482,4,0,
592,15,496,4,0,
592,15,503,4,0,
592,15,506,1,43,
592,15,590,4,0,
592,15,605,4,0,
592,16,20,3,0,
592,16,54,2,0,
592,16,56,1,49,1
592,16,57,4,0,
592,16,58,4,0,
592,16,59,4,0,
592,16,61,1,13,1
592,16,71,1,5,1
592,16,92,4,0,
592,16,94,4,0,
592,16,101,1,9,1
592,16,104,4,0,
592,16,105,1,17,1
592,16,105,2,0,
592,16,109,2,0,
592,16,127,4,0,
592,16,132,2,0,
592,16,138,4,0,
592,16,145,1,1,1
592,16,148,4,0,
592,16,151,2,0,
592,16,156,4,0,
592,16,164,4,0,
592,16,173,3,0,
592,16,180,3,0,
592,16,182,4,0,
592,16,188,4,0,
592,16,196,3,0,
592,16,202,3,0,
592,16,207,4,0,
592,16,213,4,0,
592,16,214,4,0,
592,16,216,4,0,
592,16,218,4,0,
592,16,219,4,0,
592,16,220,2,0,
592,16,220,3,0,
592,16,237,4,0,
592,16,240,1,37,1
592,16,240,4,0,
592,16,244,4,0,
592,16,247,4,0,
592,16,258,4,0,
592,16,261,4,0,
592,16,263,4,0,
592,16,269,4,0,
592,16,271,3,0,
592,16,277,3,0,
592,16,290,4,0,
592,16,291,4,0,
592,16,323,1,61,1
592,16,346,1,1,2
592,16,351,3,0,
592,16,352,1,22,1
592,16,352,3,0,
592,16,362,1,32,1
592,16,378,1,55,1
592,16,399,4,0,
592,16,412,4,0,
592,16,433,4,0,
592,16,466,1,27,1
592,16,482,4,0,
592,16,496,4,0,
592,16,503,4,0,
592,16,506,1,43,1
592,16,590,4,0,
592,16,605,4,0,
592,17,54,2,0,
592,17,56,1,49,
592,17,57,4,0,
592,17,58,4,0,
592,17,59,4,0,
592,17,61,1,13,
592,17,71,1,5,
592,17,92,4,0,
592,17,94,4,0,
592,17,101,1,9,
592,17,104,4,0,
592,17,105,1,17,
592,17,105,2,0,
592,17,109,2,0,
592,17,127,4,0,
592,17,132,2,0,
592,17,138,4,0,
592,17,145,1,1,1
592,17,151,2,0,
592,17,156,4,0,
592,17,164,4,0,
592,17,182,4,0,
592,17,188,4,0,
592,17,207,4,0,
592,17,213,4,0,
592,17,214,4,0,
592,17,216,4,0,
592,17,218,4,0,
592,17,219,4,0,
592,17,220,2,0,
592,17,237,4,0,
592,17,240,1,37,
592,17,240,4,0,
592,17,244,4,0,
592,17,247,4,0,
592,17,258,4,0,
592,17,261,4,0,
592,17,263,4,0,
592,17,269,4,0,
592,17,323,1,61,
592,17,346,1,1,2
592,17,352,1,22,
592,17,362,1,32,
592,17,378,1,55,
592,17,399,4,0,
592,17,412,4,0,
592,17,433,4,0,
592,17,466,1,27,
592,17,482,4,0,
592,17,496,4,0,
592,17,503,4,0,
592,17,506,1,43,
592,17,590,4,0,
592,17,605,4,0,
592,18,54,2,0,
592,18,56,1,49,
592,18,57,4,0,
592,18,58,4,0,
592,18,59,4,0,
592,18,61,1,13,
592,18,71,1,5,
592,18,92,4,0,
592,18,94,4,0,
592,18,101,1,9,
592,18,104,4,0,
592,18,105,1,17,
592,18,105,2,0,
592,18,109,2,0,
592,18,127,4,0,
592,18,132,2,0,
592,18,138,4,0,
592,18,145,1,1,1
592,18,151,2,0,
592,18,156,4,0,
592,18,164,4,0,
592,18,182,4,0,
592,18,188,4,0,
592,18,207,4,0,
592,18,213,4,0,
592,18,214,4,0,
592,18,216,4,0,
592,18,218,4,0,
592,18,219,4,0,
592,18,220,2,0,
592,18,237,4,0,
592,18,240,1,37,
592,18,240,4,0,
592,18,244,4,0,
592,18,247,4,0,
592,18,258,4,0,
592,18,261,4,0,
592,18,263,4,0,
592,18,269,4,0,
592,18,323,1,61,
592,18,346,1,1,2
592,18,352,1,22,
592,18,362,1,32,
592,18,378,1,55,
592,18,399,4,0,
592,18,412,4,0,
592,18,433,4,0,
592,18,466,1,27,
592,18,482,4,0,
592,18,496,4,0,
592,18,503,4,0,
592,18,506,1,43,
592,18,590,4,0,
592,18,605,4,0,
593,11,56,1,53,
593,11,57,4,0,
593,11,58,4,0,
593,11,59,4,0,
593,11,61,1,13,
593,11,63,4,0,
593,11,71,1,1,3
593,11,71,1,5,
593,11,92,4,0,
593,11,94,4,0,
593,11,101,1,1,4
593,11,101,1,9,
593,11,104,4,0,
593,11,105,1,17,
593,11,127,4,0,
593,11,138,4,0,
593,11,145,1,1,1
593,11,148,4,0,
593,11,156,4,0,
593,11,164,4,0,
593,11,182,4,0,
593,11,188,4,0,
593,11,207,4,0,
593,11,213,4,0,
593,11,216,4,0,
593,11,218,4,0,
593,11,219,4,0,
593,11,237,4,0,
593,11,240,1,37,
593,11,240,4,0,
593,11,244,4,0,
593,11,247,4,0,
593,11,258,4,0,
593,11,261,4,0,
593,11,263,4,0,
593,11,269,4,0,
593,11,291,4,0,
593,11,323,1,69,
593,11,346,1,1,2
593,11,352,1,22,
593,11,362,1,32,
593,11,378,1,61,
593,11,412,4,0,
593,11,416,4,0,
593,11,433,4,0,
593,11,466,1,27,
593,11,482,4,0,
593,11,496,4,0,
593,11,503,4,0,
593,11,506,1,45,
593,14,20,3,0,
593,14,56,1,53,
593,14,57,4,0,
593,14,58,4,0,
593,14,59,4,0,
593,14,61,1,13,
593,14,63,4,0,
593,14,71,1,1,3
593,14,71,1,5,
593,14,92,4,0,
593,14,94,4,0,
593,14,101,1,1,4
593,14,101,1,9,
593,14,104,4,0,
593,14,105,1,17,
593,14,127,4,0,
593,14,138,4,0,
593,14,145,1,1,1
593,14,148,4,0,
593,14,156,4,0,
593,14,164,4,0,
593,14,173,3,0,
593,14,180,3,0,
593,14,182,4,0,
593,14,188,4,0,
593,14,196,3,0,
593,14,202,3,0,
593,14,207,4,0,
593,14,213,4,0,
593,14,214,3,0,
593,14,216,4,0,
593,14,218,4,0,
593,14,219,4,0,
593,14,220,3,0,
593,14,237,4,0,
593,14,240,1,37,
593,14,240,4,0,
593,14,244,4,0,
593,14,247,4,0,
593,14,258,4,0,
593,14,261,4,0,
593,14,263,4,0,
593,14,269,4,0,
593,14,271,3,0,
593,14,277,3,0,
593,14,291,4,0,
593,14,323,1,69,
593,14,346,1,1,2
593,14,352,1,22,
593,14,362,1,32,
593,14,378,1,61,
593,14,399,3,0,
593,14,412,4,0,
593,14,416,4,0,
593,14,433,4,0,
593,14,466,1,27,
593,14,482,4,0,
593,14,496,4,0,
593,14,503,4,0,
593,14,506,1,45,
593,15,56,1,53,
593,15,57,4,0,
593,15,58,4,0,
593,15,59,4,0,
593,15,61,1,13,
593,15,63,4,0,
593,15,71,1,1,5
593,15,71,1,5,
593,15,92,4,0,
593,15,94,4,0,
593,15,101,1,1,6
593,15,101,1,9,
593,15,104,4,0,
593,15,105,1,17,
593,15,127,4,0,
593,15,138,4,0,
593,15,145,1,1,3
593,15,148,4,0,
593,15,156,4,0,
593,15,164,4,0,
593,15,182,4,0,
593,15,188,4,0,
593,15,207,4,0,
593,15,213,4,0,
593,15,214,4,0,
593,15,216,4,0,
593,15,218,4,0,
593,15,219,4,0,
593,15,237,4,0,
593,15,240,1,37,
593,15,240,4,0,
593,15,244,4,0,
593,15,247,4,0,
593,15,258,4,0,
593,15,261,4,0,
593,15,263,4,0,
593,15,269,4,0,
593,15,323,1,1,1
593,15,323,1,69,
593,15,346,1,1,4
593,15,352,1,22,
593,15,362,1,32,
593,15,378,1,1,2
593,15,378,1,61,
593,15,399,4,0,
593,15,412,4,0,
593,15,416,4,0,
593,15,433,4,0,
593,15,466,1,27,
593,15,482,4,0,
593,15,496,4,0,
593,15,503,4,0,
593,15,506,1,45,
593,15,590,4,0,
593,15,605,4,0,
593,16,20,3,0,
593,16,56,1,53,1
593,16,57,4,0,
593,16,58,4,0,
593,16,59,4,0,
593,16,61,1,13,1
593,16,63,4,0,
593,16,71,1,1,5
593,16,71,1,5,1
593,16,92,4,0,
593,16,94,4,0,
593,16,101,1,1,6
593,16,101,1,9,1
593,16,104,4,0,
593,16,105,1,17,1
593,16,127,4,0,
593,16,138,4,0,
593,16,145,1,1,3
593,16,148,4,0,
593,16,156,4,0,
593,16,164,4,0,
593,16,173,3,0,
593,16,180,3,0,
593,16,182,4,0,
593,16,188,4,0,
593,16,196,3,0,
593,16,202,3,0,
593,16,207,4,0,
593,16,213,4,0,
593,16,214,4,0,
593,16,216,4,0,
593,16,218,4,0,
593,16,219,4,0,
593,16,220,3,0,
593,16,237,4,0,
593,16,240,1,37,1
593,16,240,4,0,
593,16,244,4,0,
593,16,247,4,0,
593,16,258,4,0,
593,16,261,4,0,
593,16,263,4,0,
593,16,269,4,0,
593,16,271,3,0,
593,16,277,3,0,
593,16,290,4,0,
593,16,291,4,0,
593,16,323,1,1,1
593,16,323,1,69,1
593,16,346,1,1,4
593,16,351,3,0,
593,16,352,1,22,1
593,16,352,3,0,
593,16,362,1,32,1
593,16,378,1,1,2
593,16,378,1,61,1
593,16,399,4,0,
593,16,412,4,0,
593,16,416,4,0,
593,16,433,4,0,
593,16,466,1,27,1
593,16,482,4,0,
593,16,496,4,0,
593,16,503,4,0,
593,16,506,1,45,1
593,16,590,4,0,
593,16,605,4,0,
593,17,56,1,53,
593,17,57,4,0,
593,17,58,4,0,
593,17,59,4,0,
593,17,61,1,13,
593,17,63,4,0,
593,17,71,1,1,5
593,17,71,1,5,
593,17,92,4,0,
593,17,94,4,0,
593,17,101,1,1,6
593,17,101,1,9,
593,17,104,4,0,
593,17,105,1,17,
593,17,127,4,0,
593,17,138,4,0,
593,17,145,1,1,3
593,17,156,4,0,
593,17,164,4,0,
593,17,182,4,0,
593,17,188,4,0,
593,17,207,4,0,
593,17,213,4,0,
593,17,214,4,0,
593,17,216,4,0,
593,17,218,4,0,
593,17,219,4,0,
593,17,237,4,0,
593,17,240,1,37,
593,17,240,4,0,
593,17,244,4,0,
593,17,247,4,0,
593,17,258,4,0,
593,17,261,4,0,
593,17,263,4,0,
593,17,269,4,0,
593,17,323,1,1,1
593,17,323,1,69,
593,17,346,1,1,4
593,17,352,1,22,
593,17,362,1,32,
593,17,378,1,1,2
593,17,378,1,61,
593,17,399,4,0,
593,17,412,4,0,
593,17,416,4,0,
593,17,433,4,0,
593,17,466,1,27,
593,17,482,4,0,
593,17,496,4,0,
593,17,503,4,0,
593,17,506,1,45,
593,17,590,4,0,
593,17,605,4,0,
593,18,56,1,53,
593,18,57,4,0,
593,18,58,4,0,
593,18,59,4,0,
593,18,61,1,13,
593,18,63,4,0,
593,18,71,1,1,5
593,18,71,1,5,
593,18,92,4,0,
593,18,94,4,0,
593,18,101,1,1,6
593,18,101,1,9,
593,18,104,4,0,
593,18,105,1,17,
593,18,127,4,0,
593,18,138,4,0,
593,18,145,1,1,3
593,18,156,4,0,
593,18,164,4,0,
593,18,182,4,0,
593,18,188,4,0,
593,18,207,4,0,
593,18,213,4,0,
593,18,214,4,0,
593,18,216,4,0,
593,18,218,4,0,
593,18,219,4,0,
593,18,237,4,0,
593,18,240,1,37,
593,18,240,4,0,
593,18,244,4,0,
593,18,247,4,0,
593,18,258,4,0,
593,18,261,4,0,
593,18,263,4,0,
593,18,269,4,0,
593,18,323,1,1,1
593,18,323,1,69,
593,18,346,1,1,4
593,18,352,1,22,
593,18,362,1,32,
593,18,378,1,1,2
593,18,378,1,61,
593,18,399,4,0,
593,18,412,4,0,
593,18,416,4,0,
593,18,433,4,0,
593,18,466,1,27,
593,18,482,4,0,
593,18,496,4,0,
593,18,503,4,0,
593,18,506,1,45,
593,18,590,4,0,
593,18,605,4,0,
594,11,1,1,1,1
594,11,3,1,13,
594,11,54,2,0,
594,11,56,1,61,
594,11,57,4,0,
594,11,58,4,0,
594,11,59,4,0,
594,11,92,4,0,
594,11,94,4,0,
594,11,104,4,0,
594,11,113,4,0,
594,11,127,4,0,
594,11,156,4,0,
594,11,164,4,0,
594,11,182,1,21,
594,11,182,4,0,
594,11,203,2,0,
594,11,207,4,0,
594,11,213,4,0,
594,11,216,4,0,
594,11,218,4,0,
594,11,219,1,45,
594,11,219,4,0,
594,11,220,2,0,
594,11,237,4,0,
594,11,240,4,0,
594,11,243,2,0,
594,11,244,4,0,
594,11,247,4,0,
594,11,258,4,0,
594,11,263,4,0,
594,11,270,1,49,
594,11,273,1,37,
594,11,287,2,0,
594,11,291,4,0,
594,11,321,2,0,
594,11,346,1,1,2
594,11,347,4,0,
594,11,352,1,25,
594,11,358,1,29,
594,11,361,1,57,
594,11,362,1,41,
594,11,392,1,5,
594,11,453,1,9,
594,11,469,1,53,
594,11,487,1,33,
594,11,496,4,0,
594,11,503,4,0,
594,11,505,1,17,
594,14,1,1,1,1
594,14,3,1,13,
594,14,54,2,0,
594,14,56,1,61,
594,14,57,4,0,
594,14,58,4,0,
594,14,59,4,0,
594,14,92,4,0,
594,14,94,4,0,
594,14,104,4,0,
594,14,113,4,0,
594,14,127,4,0,
594,14,156,4,0,
594,14,164,4,0,
594,14,173,3,0,
594,14,182,1,21,
594,14,182,4,0,
594,14,196,3,0,
594,14,203,2,0,
594,14,207,4,0,
594,14,213,4,0,
594,14,214,3,0,
594,14,216,4,0,
594,14,218,4,0,
594,14,219,1,45,
594,14,219,4,0,
594,14,220,2,0,
594,14,220,3,0,
594,14,237,4,0,
594,14,240,4,0,
594,14,243,2,0,
594,14,244,4,0,
594,14,247,4,0,
594,14,258,4,0,
594,14,263,4,0,
594,14,270,1,49,
594,14,270,3,0,
594,14,273,1,37,
594,14,277,3,0,
594,14,282,3,0,
594,14,287,2,0,
594,14,291,4,0,
594,14,321,2,0,
594,14,340,3,0,
594,14,346,1,1,2
594,14,347,4,0,
594,14,352,1,25,
594,14,358,1,29,
594,14,361,1,57,
594,14,362,1,41,
594,14,392,1,5,
594,14,453,1,9,
594,14,469,1,53,
594,14,487,1,33,
594,14,496,4,0,
594,14,503,4,0,
594,14,505,1,17,
594,15,1,1,1,4
594,15,3,1,13,
594,15,54,2,0,
594,15,56,1,1,1
594,15,56,1,61,
594,15,57,4,0,
594,15,58,4,0,
594,15,59,4,0,
594,15,92,4,0,
594,15,94,4,0,
594,15,104,4,0,
594,15,113,4,0,
594,15,127,4,0,
594,15,156,4,0,
594,15,164,4,0,
594,15,182,1,21,
594,15,182,4,0,
594,15,203,2,0,
594,15,207,4,0,
594,15,213,4,0,
594,15,214,4,0,
594,15,216,4,0,
594,15,218,4,0,
594,15,219,1,45,
594,15,219,4,0,
594,15,220,2,0,
594,15,237,4,0,
594,15,240,4,0,
594,15,243,2,0,
594,15,244,4,0,
594,15,247,4,0,
594,15,258,4,0,
594,15,263,4,0,
594,15,270,1,49,
594,15,273,1,37,
594,15,287,2,0,
594,15,321,2,0,
594,15,346,1,1,5
594,15,347,4,0,
594,15,352,1,25,
594,15,358,1,29,
594,15,361,1,1,3
594,15,361,1,57,
594,15,362,1,41,
594,15,392,1,5,
594,15,453,1,9,
594,15,469,1,1,2
594,15,469,1,53,
594,15,487,1,33,
594,15,496,4,0,
594,15,503,4,0,
594,15,505,1,17,
594,15,590,4,0,
594,16,1,1,1,4
594,16,3,1,13,1
594,16,54,2,0,
594,16,56,1,1,1
594,16,56,1,61,1
594,16,57,4,0,
594,16,58,4,0,
594,16,59,4,0,
594,16,92,4,0,
594,16,94,4,0,
594,16,104,4,0,
594,16,113,4,0,
594,16,127,4,0,
594,16,156,4,0,
594,16,164,4,0,
594,16,173,3,0,
594,16,182,1,21,1
594,16,182,4,0,
594,16,196,3,0,
594,16,203,2,0,
594,16,207,4,0,
594,16,213,4,0,
594,16,214,4,0,
594,16,216,4,0,
594,16,218,4,0,
594,16,219,1,45,1
594,16,219,4,0,
594,16,220,2,0,
594,16,220,3,0,
594,16,237,4,0,
594,16,240,4,0,
594,16,243,2,0,
594,16,244,4,0,
594,16,247,4,0,
594,16,258,4,0,
594,16,263,4,0,
594,16,270,1,49,1
594,16,270,3,0,
594,16,273,1,37,1
594,16,277,3,0,
594,16,282,3,0,
594,16,287,2,0,
594,16,290,4,0,
594,16,291,4,0,
594,16,321,2,0,
594,16,340,3,0,
594,16,346,1,1,5
594,16,347,4,0,
594,16,352,1,25,1
594,16,352,3,0,
594,16,358,1,29,1
594,16,361,1,1,3
594,16,361,1,57,1
594,16,362,1,41,1
594,16,392,1,5,1
594,16,453,1,9,1
594,16,469,1,1,2
594,16,469,1,53,1
594,16,487,1,33,1
594,16,496,4,0,
594,16,503,4,0,
594,16,505,1,17,1
594,16,590,4,0,
594,17,1,1,1,6
594,17,3,1,13,
594,17,54,2,0,
594,17,56,1,1,2
594,17,56,1,65,
594,17,57,4,0,
594,17,58,4,0,
594,17,59,4,0,
594,17,92,4,0,
594,17,94,4,0,
594,17,104,4,0,
594,17,113,4,0,
594,17,127,4,0,
594,17,156,4,0,
594,17,164,4,0,
594,17,182,1,21,
594,17,182,4,0,
594,17,203,2,0,
594,17,207,4,0,
594,17,213,4,0,
594,17,214,4,0,
594,17,216,4,0,
594,17,218,4,0,
594,17,219,1,45,
594,17,219,4,0,
594,17,220,2,0,
594,17,237,4,0,
594,17,240,4,0,
594,17,243,2,0,
594,17,244,4,0,
594,17,247,4,0,
594,17,250,1,49,
594,17,258,4,0,
594,17,263,4,0,
594,17,270,1,1,5
594,17,270,1,53,
594,17,273,1,37,
594,17,287,2,0,
594,17,321,2,0,
594,17,346,1,1,7
594,17,347,4,0,
594,17,352,1,25,
594,17,358,1,29,
594,17,361,1,1,4
594,17,361,1,57,
594,17,362,1,41,
594,17,392,1,5,
594,17,453,1,9,
594,17,469,1,1,3
594,17,469,1,61,
594,17,487,1,33,
594,17,496,4,0,
594,17,503,4,0,
594,17,505,1,17,
594,17,589,1,1,1
594,17,590,4,0,
594,18,1,1,1,6
594,18,3,1,13,
594,18,54,2,0,
594,18,56,1,1,2
594,18,56,1,65,
594,18,57,4,0,
594,18,58,4,0,
594,18,59,4,0,
594,18,92,4,0,
594,18,94,4,0,
594,18,104,4,0,
594,18,113,4,0,
594,18,127,4,0,
594,18,156,4,0,
594,18,164,4,0,
594,18,182,1,21,
594,18,182,4,0,
594,18,203,2,0,
594,18,207,4,0,
594,18,213,4,0,
594,18,214,4,0,
594,18,216,4,0,
594,18,218,4,0,
594,18,219,1,45,
594,18,219,4,0,
594,18,220,2,0,
594,18,237,4,0,
594,18,240,4,0,
594,18,243,2,0,
594,18,244,4,0,
594,18,247,4,0,
594,18,250,1,49,
594,18,258,4,0,
594,18,263,4,0,
594,18,270,1,1,5
594,18,270,1,53,
594,18,273,1,37,
594,18,287,2,0,
594,18,321,2,0,
594,18,346,1,1,7
594,18,347,4,0,
594,18,352,1,25,
594,18,358,1,29,
594,18,361,1,1,4
594,18,361,1,57,
594,18,362,1,41,
594,18,392,1,5,
594,18,453,1,9,
594,18,469,1,1,3
594,18,469,1,61,
594,18,487,1,33,
594,18,496,4,0,
594,18,503,4,0,
594,18,505,1,17,
594,18,589,1,1,1
594,18,590,4,0,
595,11,15,4,0,
595,11,40,2,0,
595,11,42,2,0,
595,11,50,2,0,
595,11,81,1,1,1
595,11,85,4,0,
595,11,86,1,4,
595,11,86,4,0,
595,11,92,4,0,
595,11,97,1,37,
595,11,103,1,7,
595,11,104,4,0,
595,11,113,4,0,
595,11,141,1,1,2
595,11,148,4,0,
595,11,156,4,0,
595,11,163,1,26,
595,11,164,4,0,
595,11,168,4,0,
595,11,169,1,1,3
595,11,182,4,0,
595,11,185,2,0,
595,11,207,4,0,
595,11,210,1,12,
595,11,213,4,0,
595,11,216,4,0,
595,11,218,4,0,
595,11,228,2,0,
595,11,237,4,0,
595,11,240,4,0,
595,11,263,4,0,
595,11,324,1,34,
595,11,380,1,23,
595,11,389,1,40,
595,11,398,4,0,
595,11,404,4,0,
595,11,405,1,48,
595,11,412,4,0,
595,11,431,2,0,
595,11,435,1,45,
595,11,440,2,0,
595,11,450,1,18,
595,11,451,4,0,
595,11,486,1,29,
595,11,496,4,0,
595,11,521,4,0,
595,11,522,4,0,
595,11,527,1,15,
595,11,528,4,0,
595,14,15,4,0,
595,14,40,2,0,
595,14,42,2,0,
595,14,50,2,0,
595,14,81,1,1,1
595,14,85,4,0,
595,14,86,1,4,
595,14,86,4,0,
595,14,92,4,0,
595,14,97,1,37,
595,14,103,1,7,
595,14,104,4,0,
595,14,113,4,0,
595,14,141,1,1,2
595,14,148,4,0,
595,14,156,4,0,
595,14,163,1,26,
595,14,164,4,0,
595,14,168,4,0,
595,14,169,1,1,3
595,14,173,3,0,
595,14,182,4,0,
595,14,185,2,0,
595,14,202,3,0,
595,14,207,4,0,
595,14,210,1,12,
595,14,213,4,0,
595,14,214,3,0,
595,14,216,4,0,
595,14,218,4,0,
595,14,228,2,0,
595,14,237,4,0,
595,14,240,4,0,
595,14,263,4,0,
595,14,324,1,34,
595,14,324,3,0,
595,14,340,3,0,
595,14,380,1,23,
595,14,380,3,0,
595,14,389,1,40,
595,14,393,3,0,
595,14,398,4,0,
595,14,404,4,0,
595,14,405,1,48,
595,14,412,4,0,
595,14,431,2,0,
595,14,435,1,45,
595,14,440,2,0,
595,14,450,1,18,
595,14,450,3,0,
595,14,451,4,0,
595,14,486,1,29,
595,14,496,4,0,
595,14,521,4,0,
595,14,522,4,0,
595,14,527,1,15,
595,14,527,3,0,
595,14,528,4,0,
595,15,15,4,0,
595,15,40,2,0,
595,15,42,2,0,
595,15,50,2,0,
595,15,81,1,1,1
595,15,85,4,0,
595,15,86,1,4,
595,15,86,4,0,
595,15,92,4,0,
595,15,97,1,37,
595,15,103,1,7,
595,15,104,4,0,
595,15,113,4,0,
595,15,141,1,1,2
595,15,148,4,0,
595,15,156,4,0,
595,15,163,1,26,
595,15,164,4,0,
595,15,168,4,0,
595,15,169,1,1,3
595,15,182,4,0,
595,15,185,2,0,
595,15,207,4,0,
595,15,210,1,12,
595,15,213,4,0,
595,15,214,4,0,
595,15,216,4,0,
595,15,218,4,0,
595,15,228,2,0,
595,15,237,4,0,
595,15,240,4,0,
595,15,263,4,0,
595,15,293,2,0,
595,15,324,1,34,
595,15,380,1,23,
595,15,389,1,40,
595,15,398,4,0,
595,15,404,4,0,
595,15,405,1,48,
595,15,412,4,0,
595,15,431,2,0,
595,15,435,1,45,
595,15,440,2,0,
595,15,450,1,18,
595,15,451,4,0,
595,15,486,1,29,
595,15,496,4,0,
595,15,521,4,0,
595,15,522,4,0,
595,15,527,1,15,
595,15,528,4,0,
595,15,590,4,0,
595,15,611,4,0,
595,16,15,4,0,
595,16,40,2,0,
595,16,42,2,0,
595,16,50,2,0,
595,16,81,1,1,1
595,16,85,4,0,
595,16,86,1,4,1
595,16,86,4,0,
595,16,92,4,0,
595,16,97,1,37,1
595,16,103,1,7,1
595,16,104,4,0,
595,16,113,4,0,
595,16,141,1,1,2
595,16,148,4,0,
595,16,156,4,0,
595,16,163,1,26,1
595,16,164,4,0,
595,16,168,4,0,
595,16,169,1,1,3
595,16,173,3,0,
595,16,182,4,0,
595,16,185,2,0,
595,16,202,3,0,
595,16,207,4,0,
595,16,210,1,12,1
595,16,213,4,0,
595,16,214,4,0,
595,16,216,4,0,
595,16,218,4,0,
595,16,228,2,0,
595,16,237,4,0,
595,16,240,4,0,
595,16,263,4,0,
595,16,290,4,0,
595,16,293,2,0,
595,16,324,1,34,1
595,16,324,3,0,
595,16,340,3,0,
595,16,351,3,0,
595,16,380,1,23,1
595,16,380,3,0,
595,16,389,1,40,1
595,16,393,3,0,
595,16,398,4,0,
595,16,404,4,0,
595,16,405,1,48,1
595,16,412,4,0,
595,16,431,2,0,
595,16,435,1,45,1
595,16,440,2,0,
595,16,450,1,18,1
595,16,450,3,0,
595,16,451,4,0,
595,16,486,1,29,1
595,16,496,4,0,
595,16,521,4,0,
595,16,522,4,0,
595,16,527,1,15,1
595,16,527,3,0,
595,16,528,4,0,
595,16,590,4,0,
595,16,611,4,0,
595,17,40,2,0,
595,17,42,2,0,
595,17,50,2,0,
595,17,71,1,1,2
595,17,81,1,1,1
595,17,85,4,0,
595,17,86,1,4,
595,17,86,4,0,
595,17,92,4,0,
595,17,97,1,37,
595,17,103,1,7,
595,17,104,4,0,
595,17,113,4,0,
595,17,141,4,0,
595,17,156,4,0,
595,17,163,1,26,
595,17,164,4,0,
595,17,168,4,0,
595,17,169,1,1,3
595,17,182,4,0,
595,17,185,2,0,
595,17,207,4,0,
595,17,210,1,12,
595,17,213,4,0,
595,17,214,4,0,
595,17,216,4,0,
595,17,218,4,0,
595,17,228,2,0,
595,17,237,4,0,
595,17,240,4,0,
595,17,263,4,0,
595,17,293,2,0,
595,17,324,1,34,
595,17,380,1,23,
595,17,389,1,40,
595,17,398,4,0,
595,17,404,4,0,
595,17,405,1,48,
595,17,412,4,0,
595,17,431,2,0,
595,17,435,1,45,
595,17,440,2,0,
595,17,450,1,18,
595,17,451,4,0,
595,17,486,1,29,
595,17,496,4,0,
595,17,521,4,0,
595,17,527,1,15,
595,17,528,4,0,
595,17,590,4,0,
595,17,611,4,0,
595,18,40,2,0,
595,18,42,2,0,
595,18,50,2,0,
595,18,71,1,1,2
595,18,81,1,1,1
595,18,85,4,0,
595,18,86,1,4,
595,18,86,4,0,
595,18,92,4,0,
595,18,97,1,37,
595,18,103,1,7,
595,18,104,4,0,
595,18,113,4,0,
595,18,141,4,0,
595,18,156,4,0,
595,18,163,1,26,
595,18,164,4,0,
595,18,168,4,0,
595,18,169,1,1,3
595,18,182,4,0,
595,18,185,2,0,
595,18,207,4,0,
595,18,210,1,12,
595,18,213,4,0,
595,18,214,4,0,
595,18,216,4,0,
595,18,218,4,0,
595,18,228,2,0,
595,18,237,4,0,
595,18,240,4,0,
595,18,263,4,0,
595,18,293,2,0,
595,18,324,1,34,
595,18,380,1,23,
595,18,389,1,40,
595,18,398,4,0,
595,18,404,4,0,
595,18,405,1,48,
595,18,412,4,0,
595,18,431,2,0,
595,18,435,1,45,
595,18,440,2,0,
595,18,450,1,18,
595,18,451,4,0,
595,18,486,1,29,
595,18,496,4,0,
595,18,521,4,0,
595,18,527,1,15,
595,18,528,4,0,
595,18,590,4,0,
595,18,611,4,0,
595,18,679,2,0,
596,11,15,4,0,
596,11,63,4,0,
596,11,81,1,1,1
596,11,85,4,0,
596,11,86,1,1,4
596,11,86,1,4,
596,11,86,4,0,
596,11,87,4,0,
596,11,92,4,0,
596,11,97,1,40,
596,11,103,1,7,
596,11,104,4,0,
596,11,113,4,0,
596,11,141,1,1,2
596,11,148,4,0,
596,11,156,4,0,
596,11,163,1,26,
596,11,164,4,0,
596,11,168,4,0,
596,11,169,1,1,3
596,11,182,4,0,
596,11,207,4,0,
596,11,210,1,12,
596,11,213,4,0,
596,11,216,4,0,
596,11,218,4,0,
596,11,237,4,0,
596,11,240,4,0,
596,11,263,4,0,
596,11,324,1,34,
596,11,380,1,23,
596,11,389,1,46,
596,11,398,4,0,
596,11,404,4,0,
596,11,405,1,60,
596,11,412,4,0,
596,11,416,4,0,
596,11,435,1,54,
596,11,450,1,18,
596,11,451,4,0,
596,11,486,1,29,
596,11,496,4,0,
596,11,521,4,0,
596,11,522,4,0,
596,11,527,1,15,
596,11,528,4,0,
596,14,15,4,0,
596,14,63,4,0,
596,14,81,1,1,1
596,14,85,4,0,
596,14,86,1,1,4
596,14,86,1,4,
596,14,86,4,0,
596,14,87,4,0,
596,14,92,4,0,
596,14,97,1,40,
596,14,103,1,7,
596,14,104,4,0,
596,14,113,4,0,
596,14,141,1,1,2
596,14,148,4,0,
596,14,156,4,0,
596,14,163,1,26,
596,14,164,4,0,
596,14,168,4,0,
596,14,169,1,1,3
596,14,173,3,0,
596,14,182,4,0,
596,14,202,3,0,
596,14,207,4,0,
596,14,210,1,12,
596,14,213,4,0,
596,14,214,3,0,
596,14,216,4,0,
596,14,218,4,0,
596,14,237,4,0,
596,14,240,4,0,
596,14,263,4,0,
596,14,324,1,34,
596,14,324,3,0,
596,14,340,3,0,
596,14,380,1,23,
596,14,380,3,0,
596,14,389,1,46,
596,14,393,3,0,
596,14,398,4,0,
596,14,404,4,0,
596,14,405,1,60,
596,14,412,4,0,
596,14,416,4,0,
596,14,435,1,54,
596,14,450,1,18,
596,14,450,3,0,
596,14,451,4,0,
596,14,486,1,29,
596,14,496,4,0,
596,14,521,4,0,
596,14,522,4,0,
596,14,527,1,15,
596,14,527,3,0,
596,14,528,4,0,
596,15,15,4,0,
596,15,63,4,0,
596,15,81,1,1,2
596,15,85,4,0,
596,15,86,1,1,5
596,15,86,1,4,
596,15,86,4,0,
596,15,87,4,0,
596,15,92,4,0,
596,15,97,1,40,
596,15,103,1,7,
596,15,104,4,0,
596,15,113,4,0,
596,15,141,1,1,3
596,15,148,4,0,
596,15,156,4,0,
596,15,163,1,26,
596,15,164,4,0,
596,15,168,4,0,
596,15,169,1,1,4
596,15,182,4,0,
596,15,207,4,0,
596,15,210,1,12,
596,15,213,4,0,
596,15,214,4,0,
596,15,216,4,0,
596,15,218,4,0,
596,15,237,4,0,
596,15,240,4,0,
596,15,263,4,0,
596,15,324,1,34,
596,15,380,1,23,
596,15,389,1,46,
596,15,398,4,0,
596,15,404,4,0,
596,15,405,1,60,
596,15,412,4,0,
596,15,416,4,0,
596,15,435,1,54,
596,15,450,1,18,
596,15,451,4,0,
596,15,486,1,29,
596,15,496,4,0,
596,15,521,4,0,
596,15,522,4,0,
596,15,527,1,15,
596,15,528,4,0,
596,15,564,1,1,1
596,15,564,1,65,
596,15,590,4,0,
596,15,611,4,0,
596,16,15,4,0,
596,16,63,4,0,
596,16,81,1,1,2
596,16,85,4,0,
596,16,86,1,1,5
596,16,86,1,4,1
596,16,86,4,0,
596,16,87,4,0,
596,16,92,4,0,
596,16,97,1,40,1
596,16,103,1,7,1
596,16,104,4,0,
596,16,113,4,0,
596,16,141,1,1,3
596,16,148,4,0,
596,16,156,4,0,
596,16,163,1,26,1
596,16,164,4,0,
596,16,168,4,0,
596,16,169,1,1,4
596,16,173,3,0,
596,16,182,4,0,
596,16,202,3,0,
596,16,207,4,0,
596,16,210,1,12,1
596,16,213,4,0,
596,16,214,4,0,
596,16,216,4,0,
596,16,218,4,0,
596,16,237,4,0,
596,16,240,4,0,
596,16,263,4,0,
596,16,290,4,0,
596,16,324,1,34,1
596,16,324,3,0,
596,16,340,3,0,
596,16,351,3,0,
596,16,380,1,23,1
596,16,380,3,0,
596,16,389,1,46,1
596,16,393,3,0,
596,16,398,4,0,
596,16,404,4,0,
596,16,405,1,60,1
596,16,412,4,0,
596,16,416,4,0,
596,16,435,1,54,1
596,16,450,1,18,1
596,16,450,3,0,
596,16,451,4,0,
596,16,486,1,29,1
596,16,496,4,0,
596,16,521,4,0,
596,16,522,4,0,
596,16,527,1,15,1
596,16,527,3,0,
596,16,528,4,0,
596,16,564,1,1,1
596,16,564,1,65,1
596,16,590,4,0,
596,16,611,4,0,
596,17,63,4,0,
596,17,71,1,1,3
596,17,81,1,1,2
596,17,85,4,0,
596,17,86,1,1,5
596,17,86,1,4,
596,17,86,4,0,
596,17,87,4,0,
596,17,92,4,0,
596,17,97,1,40,
596,17,103,1,7,
596,17,104,4,0,
596,17,113,4,0,
596,17,141,4,0,
596,17,156,4,0,
596,17,163,1,26,
596,17,164,4,0,
596,17,168,4,0,
596,17,169,1,1,4
596,17,182,4,0,
596,17,207,4,0,
596,17,210,1,12,
596,17,213,4,0,
596,17,214,4,0,
596,17,216,4,0,
596,17,218,4,0,
596,17,237,4,0,
596,17,240,4,0,
596,17,263,4,0,
596,17,324,1,34,
596,17,380,1,23,
596,17,389,1,46,
596,17,398,4,0,
596,17,404,4,0,
596,17,405,1,60,
596,17,412,4,0,
596,17,416,4,0,
596,17,435,1,54,
596,17,450,1,18,
596,17,451,4,0,
596,17,486,1,29,
596,17,496,4,0,
596,17,521,4,0,
596,17,527,1,15,
596,17,528,4,0,
596,17,564,1,0,
596,17,564,1,1,1
596,17,590,4,0,
596,17,611,4,0,
596,18,63,4,0,
596,18,71,1,1,3
596,18,81,1,1,2
596,18,85,4,0,
596,18,86,1,1,5
596,18,86,1,4,
596,18,86,4,0,
596,18,87,4,0,
596,18,92,4,0,
596,18,97,1,40,
596,18,103,1,7,
596,18,104,4,0,
596,18,113,4,0,
596,18,141,4,0,
596,18,156,4,0,
596,18,163,1,26,
596,18,164,4,0,
596,18,168,4,0,
596,18,169,1,1,4
596,18,182,4,0,
596,18,207,4,0,
596,18,210,1,12,
596,18,213,4,0,
596,18,214,4,0,
596,18,216,4,0,
596,18,218,4,0,
596,18,237,4,0,
596,18,240,4,0,
596,18,263,4,0,
596,18,324,1,34,
596,18,380,1,23,
596,18,389,1,46,
596,18,398,4,0,
596,18,404,4,0,
596,18,405,1,60,
596,18,412,4,0,
596,18,416,4,0,
596,18,435,1,54,
596,18,450,1,18,
596,18,451,4,0,
596,18,486,1,29,
596,18,496,4,0,
596,18,521,4,0,
596,18,527,1,15,
596,18,528,4,0,
596,18,564,1,0,
596,18,564,1,1,1
596,18,590,4,0,
596,18,611,4,0,
597,11,33,1,1,1
597,11,42,1,18,
597,11,73,2,0,
597,11,76,4,0,
597,11,85,4,0,
597,11,86,4,0,
597,11,92,4,0,
597,11,104,4,0,
597,11,106,1,1,2
597,11,120,1,38,
597,11,148,4,0,
597,11,153,1,55,
597,11,153,4,0,
597,11,156,4,0,
597,11,164,4,0,
597,11,174,1,9,
597,11,182,4,0,
597,11,191,2,0,
597,11,205,1,6,
597,11,207,4,0,
597,11,216,4,0,
597,11,218,4,0,
597,11,232,1,14,
597,11,237,4,0,
597,11,241,4,0,
597,11,249,4,0,
597,11,263,4,0,
597,11,275,1,35,
597,11,331,2,0,
597,11,334,1,26,
597,11,356,2,0,
597,11,360,1,21,
597,11,360,4,0,
597,11,371,1,47,
597,11,371,4,0,
597,11,388,2,0,
597,11,397,4,0,
597,11,398,4,0,
597,11,402,2,0,
597,11,412,4,0,
597,11,429,1,30,
597,11,430,1,52,
597,11,430,4,0,
597,11,431,2,0,
597,11,442,1,43,
597,11,446,2,0,
597,11,468,4,0,
597,11,496,4,0,
597,14,33,1,1,1
597,14,42,1,18,
597,14,73,2,0,
597,14,76,4,0,
597,14,85,4,0,
597,14,86,4,0,
597,14,92,4,0,
597,14,104,4,0,
597,14,106,1,1,2
597,14,120,1,38,
597,14,148,4,0,
597,14,153,1,55,
597,14,153,4,0,
597,14,156,4,0,
597,14,164,4,0,
597,14,173,3,0,
597,14,174,1,9,
597,14,182,4,0,
597,14,191,2,0,
597,14,202,3,0,
597,14,205,1,6,
597,14,207,4,0,
597,14,214,3,0,
597,14,216,4,0,
597,14,218,4,0,
597,14,232,1,14,
597,14,237,4,0,
597,14,241,4,0,
597,14,249,4,0,
597,14,263,4,0,
597,14,275,1,35,
597,14,283,3,0,
597,14,331,2,0,
597,14,334,1,26,
597,14,334,3,0,
597,14,356,2,0,
597,14,356,3,0,
597,14,360,1,21,
597,14,360,4,0,
597,14,371,1,47,
597,14,371,4,0,
597,14,388,2,0,
597,14,388,3,0,
597,14,393,3,0,
597,14,397,4,0,
597,14,398,4,0,
597,14,402,2,0,
597,14,402,3,0,
597,14,412,4,0,
597,14,429,1,30,
597,14,430,1,52,
597,14,430,4,0,
597,14,431,2,0,
597,14,442,1,43,
597,14,442,3,0,
597,14,446,2,0,
597,14,446,3,0,
597,14,468,4,0,
597,14,496,4,0,
597,15,33,1,1,1
597,15,42,1,18,
597,15,73,2,0,
597,15,76,4,0,
597,15,85,4,0,
597,15,86,4,0,
597,15,92,4,0,
597,15,104,4,0,
597,15,106,1,1,2
597,15,120,1,38,
597,15,148,4,0,
597,15,153,1,55,
597,15,153,4,0,
597,15,156,4,0,
597,15,164,4,0,
597,15,174,1,9,
597,15,182,4,0,
597,15,191,2,0,
597,15,205,1,6,
597,15,207,4,0,
597,15,214,4,0,
597,15,216,4,0,
597,15,218,4,0,
597,15,232,1,14,
597,15,237,4,0,
597,15,241,4,0,
597,15,249,4,0,
597,15,263,4,0,
597,15,267,4,0,
597,15,275,1,35,
597,15,331,2,0,
597,15,334,1,26,
597,15,356,2,0,
597,15,360,1,21,
597,15,360,4,0,
597,15,371,1,47,
597,15,371,4,0,
597,15,388,2,0,
597,15,397,4,0,
597,15,398,4,0,
597,15,402,2,0,
597,15,412,4,0,
597,15,429,1,30,
597,15,430,1,52,
597,15,430,4,0,
597,15,431,2,0,
597,15,442,1,43,
597,15,446,2,0,
597,15,468,4,0,
597,15,491,2,0,
597,15,496,4,0,
597,15,590,4,0,
597,16,33,1,1,1
597,16,42,1,18,1
597,16,73,2,0,
597,16,76,4,0,
597,16,85,4,0,
597,16,86,4,0,
597,16,92,4,0,
597,16,104,4,0,
597,16,106,1,1,2
597,16,120,1,38,1
597,16,148,4,0,
597,16,153,1,55,1
597,16,153,4,0,
597,16,156,4,0,
597,16,164,4,0,
597,16,173,3,0,
597,16,174,1,9,1
597,16,182,4,0,
597,16,191,2,0,
597,16,202,3,0,
597,16,205,1,6,1
597,16,207,4,0,
597,16,214,4,0,
597,16,216,4,0,
597,16,218,4,0,
597,16,232,1,14,1
597,16,237,4,0,
597,16,241,4,0,
597,16,249,4,0,
597,16,263,4,0,
597,16,267,4,0,
597,16,275,1,35,1
597,16,282,3,0,
597,16,283,3,0,
597,16,290,4,0,
597,16,331,2,0,
597,16,334,1,26,1
597,16,334,3,0,
597,16,356,2,0,
597,16,356,3,0,
597,16,360,1,21,1
597,16,360,4,0,
597,16,371,1,47,1
597,16,371,4,0,
597,16,388,2,0,
597,16,388,3,0,
597,16,393,3,0,
597,16,397,4,0,
597,16,398,4,0,
597,16,402,2,0,
597,16,402,3,0,
597,16,412,4,0,
597,16,429,1,30,1
597,16,430,1,52,1
597,16,430,4,0,
597,16,431,2,0,
597,16,442,1,43,1
597,16,442,3,0,
597,16,446,2,0,
597,16,446,3,0,
597,16,468,4,0,
597,16,491,2,0,
597,16,496,4,0,
597,16,590,4,0,
597,17,33,1,1,1
597,17,42,1,18,
597,17,73,2,0,
597,17,76,4,0,
597,17,85,4,0,
597,17,86,4,0,
597,17,92,4,0,
597,17,104,4,0,
597,17,106,1,1,2
597,17,120,1,38,
597,17,153,1,55,
597,17,153,4,0,
597,17,156,4,0,
597,17,164,4,0,
597,17,174,1,9,
597,17,182,4,0,
597,17,191,2,0,
597,17,205,1,6,
597,17,207,4,0,
597,17,214,4,0,
597,17,216,4,0,
597,17,218,4,0,
597,17,232,1,14,
597,17,237,4,0,
597,17,241,4,0,
597,17,263,4,0,
597,17,267,4,0,
597,17,275,1,35,
597,17,331,2,0,
597,17,334,1,26,
597,17,356,2,0,
597,17,360,1,21,
597,17,360,4,0,
597,17,371,1,47,
597,17,371,4,0,
597,17,388,2,0,
597,17,397,4,0,
597,17,398,4,0,
597,17,402,2,0,
597,17,412,4,0,
597,17,429,1,30,
597,17,430,1,52,
597,17,430,4,0,
597,17,431,2,0,
597,17,442,1,43,
597,17,446,2,0,
597,17,491,2,0,
597,17,496,4,0,
597,17,590,4,0,
597,18,33,1,1,1
597,18,42,1,18,
597,18,73,2,0,
597,18,76,4,0,
597,18,85,4,0,
597,18,86,4,0,
597,18,92,4,0,
597,18,104,4,0,
597,18,106,1,1,2
597,18,120,1,38,
597,18,153,1,55,
597,18,153,4,0,
597,18,156,4,0,
597,18,164,4,0,
597,18,174,1,9,
597,18,182,4,0,
597,18,191,2,0,
597,18,205,1,6,
597,18,207,4,0,
597,18,214,4,0,
597,18,216,4,0,
597,18,218,4,0,
597,18,232,1,14,
597,18,237,4,0,
597,18,241,4,0,
597,18,263,4,0,
597,18,267,4,0,
597,18,275,1,35,
597,18,331,2,0,
597,18,334,1,26,
597,18,356,2,0,
597,18,360,1,21,
597,18,360,4,0,
597,18,371,1,47,
597,18,371,4,0,
597,18,388,2,0,
597,18,397,4,0,
597,18,398,4,0,
597,18,402,2,0,
597,18,412,4,0,
597,18,429,1,30,
597,18,430,1,52,
597,18,430,4,0,
597,18,431,2,0,
597,18,442,1,43,
597,18,446,2,0,
597,18,491,2,0,
597,18,496,4,0,
597,18,590,4,0,
598,11,14,4,0,
598,11,15,4,0,
598,11,33,1,1,2
598,11,42,1,18,
598,11,63,4,0,
598,11,70,4,0,
598,11,76,4,0,
598,11,85,4,0,
598,11,86,4,0,
598,11,87,4,0,
598,11,92,4,0,
598,11,104,4,0,
598,11,106,1,1,3
598,11,120,1,38,
598,11,148,4,0,
598,11,153,1,67,
598,11,153,4,0,
598,11,156,4,0,
598,11,164,4,0,
598,11,174,1,1,5
598,11,174,1,9,
598,11,182,4,0,
598,11,201,4,0,
598,11,205,1,1,4
598,11,205,1,6,
598,11,207,4,0,
598,11,216,4,0,
598,11,218,4,0,
598,11,232,1,14,
598,11,237,4,0,
598,11,241,4,0,
598,11,249,4,0,
598,11,263,4,0,
598,11,275,1,35,
598,11,332,4,0,
598,11,334,1,26,
598,11,360,1,21,
598,11,360,4,0,
598,11,371,1,53,
598,11,371,4,0,
598,11,397,4,0,
598,11,398,4,0,
598,11,412,4,0,
598,11,416,4,0,
598,11,421,4,0,
598,11,429,1,30,
598,11,430,1,61,
598,11,430,4,0,
598,11,431,1,1,1
598,11,438,1,40,
598,11,442,1,46,
598,11,447,4,0,
598,11,468,4,0,
598,11,496,4,0,
598,11,523,4,0,
598,14,14,4,0,
598,14,15,4,0,
598,14,33,1,1,2
598,14,42,1,18,
598,14,63,4,0,
598,14,70,4,0,
598,14,76,4,0,
598,14,85,4,0,
598,14,86,4,0,
598,14,87,4,0,
598,14,92,4,0,
598,14,104,4,0,
598,14,106,1,1,3
598,14,120,1,38,
598,14,148,4,0,
598,14,153,1,67,
598,14,153,4,0,
598,14,156,4,0,
598,14,164,4,0,
598,14,173,3,0,
598,14,174,1,1,5
598,14,174,1,9,
598,14,182,4,0,
598,14,201,4,0,
598,14,202,3,0,
598,14,205,1,1,4
598,14,205,1,6,
598,14,207,4,0,
598,14,214,3,0,
598,14,216,4,0,
598,14,218,4,0,
598,14,232,1,14,
598,14,237,4,0,
598,14,241,4,0,
598,14,249,4,0,
598,14,263,4,0,
598,14,275,1,35,
598,14,282,3,0,
598,14,283,3,0,
598,14,332,4,0,
598,14,334,1,26,
598,14,334,3,0,
598,14,356,3,0,
598,14,360,1,21,
598,14,360,4,0,
598,14,371,1,53,
598,14,371,4,0,
598,14,388,3,0,
598,14,393,3,0,
598,14,397,4,0,
598,14,398,4,0,
598,14,402,3,0,
598,14,412,4,0,
598,14,416,4,0,
598,14,421,4,0,
598,14,429,1,30,
598,14,430,1,61,
598,14,430,4,0,
598,14,431,1,1,1
598,14,438,1,40,
598,14,442,1,46,
598,14,442,3,0,
598,14,446,3,0,
598,14,447,4,0,
598,14,468,4,0,
598,14,496,4,0,
598,14,523,4,0,
598,15,14,4,0,
598,15,15,4,0,
598,15,33,1,1,2
598,15,42,1,18,
598,15,63,4,0,
598,15,70,4,0,
598,15,76,4,0,
598,15,85,4,0,
598,15,86,4,0,
598,15,87,4,0,
598,15,92,4,0,
598,15,104,4,0,
598,15,106,1,1,3
598,15,120,1,38,
598,15,148,4,0,
598,15,153,1,67,
598,15,153,4,0,
598,15,156,4,0,
598,15,164,4,0,
598,15,174,1,1,5
598,15,174,1,9,
598,15,182,4,0,
598,15,201,4,0,
598,15,205,1,1,4
598,15,205,1,6,
598,15,207,4,0,
598,15,214,4,0,
598,15,216,4,0,
598,15,218,4,0,
598,15,232,1,14,
598,15,237,4,0,
598,15,241,4,0,
598,15,249,4,0,
598,15,263,4,0,
598,15,267,4,0,
598,15,275,1,35,
598,15,332,4,0,
598,15,334,1,26,
598,15,360,1,21,
598,15,360,4,0,
598,15,371,1,53,
598,15,371,4,0,
598,15,397,4,0,
598,15,398,4,0,
598,15,412,4,0,
598,15,416,4,0,
598,15,421,4,0,
598,15,429,1,30,
598,15,430,1,61,
598,15,430,4,0,
598,15,431,1,1,1
598,15,438,1,40,
598,15,442,1,46,
598,15,447,4,0,
598,15,468,4,0,
598,15,496,4,0,
598,15,523,4,0,
598,15,590,4,0,
598,16,14,4,0,
598,16,15,4,0,
598,16,33,1,1,2
598,16,42,1,18,1
598,16,63,4,0,
598,16,70,4,0,
598,16,76,4,0,
598,16,85,4,0,
598,16,86,4,0,
598,16,87,4,0,
598,16,92,4,0,
598,16,104,4,0,
598,16,106,1,1,3
598,16,120,1,38,1
598,16,148,4,0,
598,16,153,1,67,1
598,16,153,4,0,
598,16,156,4,0,
598,16,164,4,0,
598,16,173,3,0,
598,16,174,1,1,5
598,16,174,1,9,1
598,16,182,4,0,
598,16,201,4,0,
598,16,202,3,0,
598,16,205,1,1,4
598,16,205,1,6,1
598,16,207,4,0,
598,16,214,4,0,
598,16,216,4,0,
598,16,218,4,0,
598,16,232,1,14,1
598,16,237,4,0,
598,16,241,4,0,
598,16,249,4,0,
598,16,263,4,0,
598,16,267,4,0,
598,16,275,1,35,1
598,16,282,3,0,
598,16,283,3,0,
598,16,290,4,0,
598,16,332,4,0,
598,16,334,1,26,1
598,16,334,3,0,
598,16,335,3,0,
598,16,356,3,0,
598,16,360,1,21,1
598,16,360,4,0,
598,16,371,1,53,1
598,16,371,4,0,
598,16,388,3,0,
598,16,393,3,0,
598,16,397,4,0,
598,16,398,4,0,
598,16,402,3,0,
598,16,412,4,0,
598,16,416,4,0,
598,16,421,4,0,
598,16,429,1,30,1
598,16,430,1,61,1
598,16,430,4,0,
598,16,431,1,1,1
598,16,438,1,40,1
598,16,442,1,46,1
598,16,442,3,0,
598,16,446,3,0,
598,16,447,4,0,
598,16,468,4,0,
598,16,496,4,0,
598,16,523,4,0,
598,16,590,4,0,
598,17,14,4,0,
598,17,33,1,1,3
598,17,42,1,18,
598,17,63,4,0,
598,17,76,4,0,
598,17,85,4,0,
598,17,86,4,0,
598,17,87,4,0,
598,17,92,4,0,
598,17,104,4,0,
598,17,106,1,1,4
598,17,120,1,38,
598,17,153,1,67,
598,17,153,4,0,
598,17,156,4,0,
598,17,164,4,0,
598,17,174,1,1,6
598,17,174,1,9,
598,17,182,4,0,
598,17,201,4,0,
598,17,205,1,1,5
598,17,205,1,6,
598,17,207,4,0,
598,17,214,4,0,
598,17,216,4,0,
598,17,218,4,0,
598,17,232,1,14,
598,17,237,4,0,
598,17,241,4,0,
598,17,263,4,0,
598,17,267,4,0,
598,17,275,1,35,
598,17,332,4,0,
598,17,334,1,26,
598,17,360,1,21,
598,17,360,4,0,
598,17,371,1,53,
598,17,371,4,0,
598,17,397,4,0,
598,17,398,4,0,
598,17,412,4,0,
598,17,416,4,0,
598,17,421,4,0,
598,17,429,1,30,
598,17,430,1,61,
598,17,430,4,0,
598,17,431,1,1,2
598,17,438,1,0,
598,17,438,1,1,1
598,17,442,1,46,
598,17,447,4,0,
598,17,496,4,0,
598,17,523,4,0,
598,17,590,4,0,
598,18,14,4,0,
598,18,33,1,1,3
598,18,42,1,18,
598,18,63,4,0,
598,18,76,4,0,
598,18,85,4,0,
598,18,86,4,0,
598,18,87,4,0,
598,18,92,4,0,
598,18,104,4,0,
598,18,106,1,1,4
598,18,120,1,38,
598,18,153,1,67,
598,18,153,4,0,
598,18,156,4,0,
598,18,164,4,0,
598,18,174,1,1,6
598,18,174,1,9,
598,18,182,4,0,
598,18,201,4,0,
598,18,205,1,1,5
598,18,205,1,6,
598,18,207,4,0,
598,18,214,4,0,
598,18,216,4,0,
598,18,218,4,0,
598,18,232,1,14,
598,18,237,4,0,
598,18,241,4,0,
598,18,263,4,0,
598,18,267,4,0,
598,18,275,1,35,
598,18,332,4,0,
598,18,334,1,26,
598,18,360,1,21,
598,18,360,4,0,
598,18,371,1,53,
598,18,371,4,0,
598,18,397,4,0,
598,18,398,4,0,
598,18,412,4,0,
598,18,416,4,0,
598,18,421,4,0,
598,18,429,1,30,
598,18,430,1,61,
598,18,430,4,0,
598,18,431,1,1,2
598,18,438,1,0,
598,18,438,1,1,1
598,18,442,1,46,
598,18,447,4,0,
598,18,496,4,0,
598,18,523,4,0,
598,18,590,4,0,
599,11,11,1,1,
599,11,20,1,21,
599,11,63,1,57,
599,11,63,4,0,
599,11,84,1,11,
599,11,85,4,0,
599,11,86,4,0,
599,11,92,4,0,
599,11,103,1,39,
599,11,104,4,0,
599,11,156,4,0,
599,11,164,4,0,
599,11,182,4,0,
599,11,192,1,54,
599,11,199,1,51,
599,11,201,4,0,
599,11,207,4,0,
599,11,216,4,0,
599,11,218,4,0,
599,11,237,4,0,
599,11,249,4,0,
599,11,263,4,0,
599,11,268,1,6,
599,11,319,1,45,
599,11,397,4,0,
599,11,429,1,36,
599,11,430,4,0,
599,11,435,1,42,
599,11,451,1,26,
599,11,451,4,0,
599,11,475,1,31,
599,11,496,4,0,
599,11,508,1,48,
599,11,521,4,0,
599,11,544,1,16,
599,14,11,1,1,
599,14,20,1,21,
599,14,20,3,0,
599,14,63,1,57,
599,14,63,4,0,
599,14,84,1,11,
599,14,85,4,0,
599,14,86,4,0,
599,14,92,4,0,
599,14,103,1,39,
599,14,104,4,0,
599,14,156,4,0,
599,14,164,4,0,
599,14,173,3,0,
599,14,182,4,0,
599,14,192,1,54,
599,14,199,1,51,
599,14,201,4,0,
599,14,207,4,0,
599,14,214,3,0,
599,14,216,4,0,
599,14,218,4,0,
599,14,237,4,0,
599,14,249,4,0,
599,14,253,3,0,
599,14,263,4,0,
599,14,268,1,6,
599,14,277,3,0,
599,14,278,3,0,
599,14,319,1,45,
599,14,324,3,0,
599,14,334,3,0,
599,14,356,3,0,
599,14,393,3,0,
599,14,397,4,0,
599,14,429,1,36,
599,14,430,4,0,
599,14,435,1,42,
599,14,451,1,26,
599,14,451,4,0,
599,14,475,1,31,
599,14,496,4,0,
599,14,508,1,48,
599,14,521,4,0,
599,14,528,4,0,
599,14,544,1,16,
599,15,11,1,1,
599,15,20,1,21,
599,15,63,1,57,
599,15,63,4,0,
599,15,84,1,11,
599,15,85,4,0,
599,15,86,4,0,
599,15,92,4,0,
599,15,103,1,39,
599,15,104,4,0,
599,15,156,4,0,
599,15,164,4,0,
599,15,182,4,0,
599,15,192,1,54,
599,15,199,1,50,
599,15,201,4,0,
599,15,207,4,0,
599,15,214,4,0,
599,15,216,4,0,
599,15,218,4,0,
599,15,237,4,0,
599,15,249,4,0,
599,15,263,4,0,
599,15,268,1,6,
599,15,319,1,45,
599,15,397,4,0,
599,15,429,1,36,
599,15,430,4,0,
599,15,435,1,42,
599,15,451,1,26,
599,15,451,4,0,
599,15,475,1,31,
599,15,496,4,0,
599,15,508,1,48,
599,15,521,4,0,
599,15,528,4,0,
599,15,544,1,16,
599,15,590,4,0,
599,16,11,1,1,1
599,16,20,1,21,1
599,16,20,3,0,
599,16,63,1,57,1
599,16,63,4,0,
599,16,84,1,11,1
599,16,85,4,0,
599,16,86,4,0,
599,16,92,4,0,
599,16,103,1,39,1
599,16,104,4,0,
599,16,156,4,0,
599,16,164,4,0,
599,16,173,3,0,
599,16,182,4,0,
599,16,192,1,54,1
599,16,199,1,50,1
599,16,201,4,0,
599,16,207,4,0,
599,16,214,4,0,
599,16,216,4,0,
599,16,218,4,0,
599,16,237,4,0,
599,16,249,4,0,
599,16,253,3,0,
599,16,263,4,0,
599,16,268,1,6,1
599,16,277,3,0,
599,16,278,3,0,
599,16,290,4,0,
599,16,319,1,45,1
599,16,324,3,0,
599,16,334,3,0,
599,16,351,3,0,
599,16,356,3,0,
599,16,393,3,0,
599,16,397,4,0,
599,16,429,1,36,1
599,16,430,4,0,
599,16,435,1,42,1
599,16,451,1,26,1
599,16,451,4,0,
599,16,475,1,31,1
599,16,496,4,0,
599,16,508,1,48,1
599,16,521,4,0,
599,16,528,4,0,
599,16,544,1,16,1
599,16,590,4,0,
599,17,11,1,1,
599,17,20,1,21,
599,17,63,1,57,
599,17,63,4,0,
599,17,84,1,11,
599,17,85,4,0,
599,17,86,4,0,
599,17,92,4,0,
599,17,103,1,39,
599,17,104,4,0,
599,17,156,4,0,
599,17,164,4,0,
599,17,182,4,0,
599,17,192,1,54,
599,17,199,1,50,
599,17,201,4,0,
599,17,207,4,0,
599,17,214,4,0,
599,17,216,4,0,
599,17,218,4,0,
599,17,237,4,0,
599,17,263,4,0,
599,17,268,1,6,
599,17,319,1,45,
599,17,397,4,0,
599,17,429,1,36,
599,17,430,4,0,
599,17,435,1,42,
599,17,451,1,26,
599,17,451,4,0,
599,17,475,1,31,
599,17,496,4,0,
599,17,508,1,48,
599,17,521,4,0,
599,17,528,4,0,
599,17,544,1,16,
599,17,590,4,0,
599,18,11,1,1,
599,18,20,1,21,
599,18,63,1,57,
599,18,63,4,0,
599,18,84,1,11,
599,18,85,4,0,
599,18,86,4,0,
599,18,92,4,0,
599,18,103,1,39,
599,18,104,4,0,
599,18,156,4,0,
599,18,164,4,0,
599,18,182,4,0,
599,18,192,1,54,
599,18,199,1,50,
599,18,201,4,0,
599,18,207,4,0,
599,18,214,4,0,
599,18,216,4,0,
599,18,218,4,0,
599,18,237,4,0,
599,18,263,4,0,
599,18,268,1,6,
599,18,319,1,45,
599,18,397,4,0,
599,18,429,1,36,
599,18,430,4,0,
599,18,435,1,42,
599,18,451,1,26,
599,18,451,4,0,
599,18,475,1,31,
599,18,496,4,0,
599,18,508,1,48,
599,18,521,4,0,
599,18,528,4,0,
599,18,544,1,16,
599,18,590,4,0,
600,11,11,1,1,1
600,11,20,1,21,
600,11,63,1,64,
600,11,63,4,0,
600,11,84,1,1,3
600,11,84,1,11,
600,11,85,4,0,
600,11,86,4,0,
600,11,92,4,0,
600,11,103,1,40,
600,11,104,4,0,
600,11,156,4,0,
600,11,164,4,0,
600,11,182,4,0,
600,11,192,1,60,
600,11,199,1,56,
600,11,201,4,0,
600,11,207,4,0,
600,11,216,4,0,
600,11,218,4,0,
600,11,237,4,0,
600,11,249,4,0,
600,11,263,4,0,
600,11,268,1,1,2
600,11,268,1,6,
600,11,319,1,48,
600,11,397,4,0,
600,11,429,1,36,
600,11,430,4,0,
600,11,435,1,44,
600,11,451,1,26,
600,11,451,4,0,
600,11,475,1,31,
600,11,496,4,0,
600,11,508,1,52,
600,11,521,4,0,
600,11,544,1,1,4
600,11,544,1,16,
600,14,11,1,1,1
600,14,20,1,21,
600,14,20,3,0,
600,14,63,1,64,
600,14,63,4,0,
600,14,84,1,1,3
600,14,84,1,11,
600,14,85,4,0,
600,14,86,4,0,
600,14,92,4,0,
600,14,103,1,40,
600,14,104,4,0,
600,14,156,4,0,
600,14,164,4,0,
600,14,173,3,0,
600,14,182,4,0,
600,14,192,1,60,
600,14,199,1,56,
600,14,201,4,0,
600,14,207,4,0,
600,14,214,3,0,
600,14,216,4,0,
600,14,218,4,0,
600,14,237,4,0,
600,14,249,4,0,
600,14,253,3,0,
600,14,263,4,0,
600,14,268,1,1,2
600,14,268,1,6,
600,14,277,3,0,
600,14,278,3,0,
600,14,319,1,48,
600,14,324,3,0,
600,14,334,3,0,
600,14,356,3,0,
600,14,393,3,0,
600,14,397,4,0,
600,14,429,1,36,
600,14,430,4,0,
600,14,435,1,44,
600,14,451,1,26,
600,14,451,4,0,
600,14,475,1,31,
600,14,496,4,0,
600,14,508,1,52,
600,14,521,4,0,
600,14,528,4,0,
600,14,544,1,1,4
600,14,544,1,16,
600,15,11,1,1,1
600,15,20,1,21,
600,15,63,1,64,
600,15,63,4,0,
600,15,84,1,1,3
600,15,84,1,11,
600,15,85,4,0,
600,15,86,4,0,
600,15,92,4,0,
600,15,103,1,40,
600,15,104,4,0,
600,15,156,4,0,
600,15,164,4,0,
600,15,182,4,0,
600,15,192,1,60,
600,15,199,1,56,
600,15,201,4,0,
600,15,207,4,0,
600,15,214,4,0,
600,15,216,4,0,
600,15,218,4,0,
600,15,237,4,0,
600,15,249,4,0,
600,15,263,4,0,
600,15,268,1,1,2
600,15,268,1,6,
600,15,319,1,48,
600,15,397,4,0,
600,15,429,1,36,
600,15,430,4,0,
600,15,435,1,44,
600,15,451,1,26,
600,15,451,4,0,
600,15,475,1,31,
600,15,496,4,0,
600,15,508,1,52,
600,15,521,4,0,
600,15,528,4,0,
600,15,544,1,1,4
600,15,544,1,16,
600,15,590,4,0,
600,16,11,1,1,1
600,16,20,1,21,1
600,16,20,3,0,
600,16,63,1,64,1
600,16,63,4,0,
600,16,84,1,1,3
600,16,84,1,11,1
600,16,85,4,0,
600,16,86,4,0,
600,16,92,4,0,
600,16,103,1,40,1
600,16,104,4,0,
600,16,156,4,0,
600,16,164,4,0,
600,16,173,3,0,
600,16,182,4,0,
600,16,192,1,60,1
600,16,199,1,56,1
600,16,201,4,0,
600,16,207,4,0,
600,16,214,4,0,
600,16,216,4,0,
600,16,218,4,0,
600,16,237,4,0,
600,16,249,4,0,
600,16,253,3,0,
600,16,263,4,0,
600,16,268,1,1,2
600,16,268,1,6,1
600,16,277,3,0,
600,16,278,3,0,
600,16,290,4,0,
600,16,319,1,48,1
600,16,324,3,0,
600,16,334,3,0,
600,16,351,3,0,
600,16,356,3,0,
600,16,393,3,0,
600,16,397,4,0,
600,16,429,1,36,1
600,16,430,4,0,
600,16,435,1,44,1
600,16,451,1,26,1
600,16,451,4,0,
600,16,475,1,31,1
600,16,496,4,0,
600,16,508,1,52,1
600,16,521,4,0,
600,16,528,4,0,
600,16,544,1,1,4
600,16,544,1,16,1
600,16,590,4,0,
600,17,11,1,1,1
600,17,20,1,21,
600,17,63,1,64,
600,17,63,4,0,
600,17,84,1,1,3
600,17,84,1,11,
600,17,85,4,0,
600,17,86,4,0,
600,17,92,4,0,
600,17,103,1,40,
600,17,104,4,0,
600,17,156,4,0,
600,17,164,4,0,
600,17,182,4,0,
600,17,192,1,60,
600,17,199,1,56,
600,17,201,4,0,
600,17,207,4,0,
600,17,214,4,0,
600,17,216,4,0,
600,17,218,4,0,
600,17,237,4,0,
600,17,263,4,0,
600,17,268,1,1,2
600,17,268,1,6,
600,17,319,1,48,
600,17,397,4,0,
600,17,429,1,36,
600,17,430,4,0,
600,17,435,1,44,
600,17,451,1,26,
600,17,451,4,0,
600,17,475,1,31,
600,17,496,4,0,
600,17,508,1,52,
600,17,521,4,0,
600,17,528,4,0,
600,17,544,1,1,4
600,17,544,1,16,
600,17,590,4,0,
600,18,11,1,1,1
600,18,20,1,21,
600,18,63,1,64,
600,18,63,4,0,
600,18,84,1,1,3
600,18,84,1,11,
600,18,85,4,0,
600,18,86,4,0,
600,18,92,4,0,
600,18,103,1,40,
600,18,104,4,0,
600,18,156,4,0,
600,18,164,4,0,
600,18,182,4,0,
600,18,192,1,60,
600,18,199,1,56,
600,18,201,4,0,
600,18,207,4,0,
600,18,214,4,0,
600,18,216,4,0,
600,18,218,4,0,
600,18,237,4,0,
600,18,263,4,0,
600,18,268,1,1,2
600,18,268,1,6,
600,18,319,1,48,
600,18,397,4,0,
600,18,429,1,36,
600,18,430,4,0,
600,18,435,1,44,
600,18,451,1,26,
600,18,451,4,0,
600,18,475,1,31,
600,18,496,4,0,
600,18,508,1,52,
600,18,521,4,0,
600,18,528,4,0,
600,18,544,1,1,4
600,18,544,1,16,
600,18,590,4,0,
601,11,11,1,1,1
601,11,20,1,21,
601,11,63,1,72,
601,11,63,4,0,
601,11,84,1,1,3
601,11,84,1,11,
601,11,85,4,0,
601,11,86,4,0,
601,11,87,4,0,
601,11,92,4,0,
601,11,103,1,40,
601,11,104,4,0,
601,11,156,4,0,
601,11,164,4,0,
601,11,182,4,0,
601,11,192,1,66,
601,11,199,1,60,
601,11,201,4,0,
601,11,207,4,0,
601,11,216,4,0,
601,11,218,4,0,
601,11,237,4,0,
601,11,249,4,0,
601,11,263,4,0,
601,11,268,1,1,2
601,11,268,1,6,
601,11,319,1,48,
601,11,397,4,0,
601,11,416,4,0,
601,11,429,1,36,
601,11,430,4,0,
601,11,433,4,0,
601,11,435,1,44,
601,11,451,1,25,
601,11,451,4,0,
601,11,475,1,31,
601,11,496,4,0,
601,11,508,1,54,
601,11,521,4,0,
601,11,544,1,1,4
601,11,544,1,16,
601,14,11,1,1,1
601,14,20,1,21,
601,14,20,3,0,
601,14,63,1,72,
601,14,63,4,0,
601,14,84,1,1,3
601,14,84,1,11,
601,14,85,4,0,
601,14,86,4,0,
601,14,87,4,0,
601,14,92,4,0,
601,14,103,1,40,
601,14,104,4,0,
601,14,156,4,0,
601,14,164,4,0,
601,14,173,3,0,
601,14,182,4,0,
601,14,192,1,66,
601,14,199,1,60,
601,14,201,4,0,
601,14,207,4,0,
601,14,214,3,0,
601,14,216,4,0,
601,14,218,4,0,
601,14,237,4,0,
601,14,249,4,0,
601,14,253,3,0,
601,14,263,4,0,
601,14,268,1,1,2
601,14,268,1,6,
601,14,277,3,0,
601,14,278,3,0,
601,14,319,1,48,
601,14,324,3,0,
601,14,334,3,0,
601,14,356,3,0,
601,14,393,3,0,
601,14,397,4,0,
601,14,416,4,0,
601,14,429,1,36,
601,14,430,4,0,
601,14,433,4,0,
601,14,435,1,44,
601,14,451,1,25,
601,14,451,4,0,
601,14,475,1,31,
601,14,496,4,0,
601,14,508,1,54,
601,14,521,4,0,
601,14,528,4,0,
601,14,544,1,1,4
601,14,544,1,16,
601,15,11,1,1,3
601,15,20,1,21,
601,15,63,1,72,
601,15,63,4,0,
601,15,84,1,1,5
601,15,84,1,11,
601,15,85,4,0,
601,15,86,4,0,
601,15,87,4,0,
601,15,92,4,0,
601,15,103,1,40,
601,15,104,4,0,
601,15,156,4,0,
601,15,164,4,0,
601,15,182,4,0,
601,15,192,1,1,2
601,15,192,1,66,
601,15,199,1,60,
601,15,201,4,0,
601,15,207,4,0,
601,15,214,4,0,
601,15,216,4,0,
601,15,218,4,0,
601,15,237,4,0,
601,15,249,4,0,
601,15,263,4,0,
601,15,268,1,1,4
601,15,268,1,6,
601,15,319,1,48,
601,15,397,4,0,
601,15,416,4,0,
601,15,429,1,36,
601,15,430,4,0,
601,15,433,4,0,
601,15,435,1,44,
601,15,451,1,25,
601,15,451,4,0,
601,15,475,1,31,
601,15,496,4,0,
601,15,508,1,54,
601,15,521,4,0,
601,15,528,4,0,
601,15,544,1,1,6
601,15,544,1,16,
601,15,590,4,0,
601,15,602,1,1,1
601,15,602,1,76,
601,16,11,1,1,3
601,16,20,1,21,1
601,16,20,3,0,
601,16,63,1,72,1
601,16,63,4,0,
601,16,84,1,1,5
601,16,84,1,11,1
601,16,85,4,0,
601,16,86,4,0,
601,16,87,4,0,
601,16,92,4,0,
601,16,103,1,40,1
601,16,104,4,0,
601,16,156,4,0,
601,16,164,4,0,
601,16,173,3,0,
601,16,182,4,0,
601,16,192,1,1,2
601,16,192,1,66,1
601,16,199,1,60,1
601,16,201,4,0,
601,16,207,4,0,
601,16,214,4,0,
601,16,216,4,0,
601,16,218,4,0,
601,16,237,4,0,
601,16,249,4,0,
601,16,253,3,0,
601,16,263,4,0,
601,16,268,1,1,4
601,16,268,1,6,1
601,16,277,3,0,
601,16,278,3,0,
601,16,290,4,0,
601,16,319,1,48,1
601,16,324,3,0,
601,16,334,3,0,
601,16,351,3,0,
601,16,356,3,0,
601,16,393,3,0,
601,16,397,4,0,
601,16,416,4,0,
601,16,429,1,36,1
601,16,430,4,0,
601,16,433,4,0,
601,16,435,1,44,1
601,16,451,1,25,1
601,16,451,4,0,
601,16,475,1,31,1
601,16,496,4,0,
601,16,508,1,54,1
601,16,521,4,0,
601,16,528,4,0,
601,16,544,1,1,6
601,16,544,1,16,1
601,16,590,4,0,
601,16,602,1,1,1
601,16,602,1,76,1
601,17,11,1,1,3
601,17,20,1,21,
601,17,63,1,72,
601,17,63,4,0,
601,17,84,1,1,5
601,17,84,1,11,
601,17,85,4,0,
601,17,86,4,0,
601,17,87,4,0,
601,17,92,4,0,
601,17,103,1,40,
601,17,104,4,0,
601,17,156,4,0,
601,17,164,4,0,
601,17,182,4,0,
601,17,192,1,66,
601,17,199,1,60,
601,17,201,4,0,
601,17,207,4,0,
601,17,214,4,0,
601,17,216,4,0,
601,17,218,4,0,
601,17,237,4,0,
601,17,263,4,0,
601,17,268,1,1,4
601,17,268,1,6,
601,17,319,1,48,
601,17,397,4,0,
601,17,416,4,0,
601,17,429,1,36,
601,17,430,4,0,
601,17,433,4,0,
601,17,435,1,44,
601,17,451,1,25,
601,17,451,4,0,
601,17,475,1,31,
601,17,496,4,0,
601,17,508,1,54,
601,17,521,4,0,
601,17,528,4,0,
601,17,544,1,1,6
601,17,544,1,16,
601,17,590,4,0,
601,17,602,1,0,
601,17,602,1,1,1
601,17,674,1,1,2
601,18,11,1,1,3
601,18,20,1,21,
601,18,63,1,72,
601,18,63,4,0,
601,18,84,1,1,5
601,18,84,1,11,
601,18,85,4,0,
601,18,86,4,0,
601,18,87,4,0,
601,18,92,4,0,
601,18,103,1,40,
601,18,104,4,0,
601,18,156,4,0,
601,18,164,4,0,
601,18,182,4,0,
601,18,192,1,66,
601,18,199,1,60,
601,18,201,4,0,
601,18,207,4,0,
601,18,214,4,0,
601,18,216,4,0,
601,18,218,4,0,
601,18,237,4,0,
601,18,263,4,0,
601,18,268,1,1,4
601,18,268,1,6,
601,18,319,1,48,
601,18,397,4,0,
601,18,416,4,0,
601,18,429,1,36,
601,18,430,4,0,
601,18,433,4,0,
601,18,435,1,44,
601,18,451,1,25,
601,18,451,4,0,
601,18,475,1,31,
601,18,496,4,0,
601,18,508,1,54,
601,18,521,4,0,
601,18,528,4,0,
601,18,544,1,1,6
601,18,544,1,16,
601,18,590,4,0,
601,18,602,1,0,
601,18,602,1,1,1
601,18,674,1,1,2
602,11,33,1,1,1
602,11,86,1,1,2
602,11,209,1,1,3
602,11,451,1,1,4
602,14,33,1,1,1
602,14,86,1,1,2
602,14,209,1,1,3
602,14,393,3,0,
602,14,451,1,1,4
602,15,33,1,1,1
602,15,86,1,1,2
602,15,86,4,0,
602,15,209,1,1,3
602,15,451,1,1,4
602,15,451,4,0,
602,16,33,1,1,1
602,16,86,1,1,2
602,16,86,4,0,
602,16,209,1,1,3
602,16,393,3,0,
602,16,451,1,1,4
602,16,451,4,0,
602,17,33,1,1,1
602,17,86,1,1,2
602,17,86,4,0,
602,17,209,1,1,3
602,17,451,1,1,4
602,17,451,4,0,
602,18,33,1,1,1
602,18,86,1,1,2
602,18,86,4,0,
602,18,209,1,1,3
602,18,451,1,1,4
602,18,451,4,0,
603,11,20,1,9,
603,11,29,1,1,1
603,11,37,1,74,
603,11,51,1,19,
603,11,85,1,44,
603,11,85,4,0,
603,11,86,1,1,2
603,11,86,4,0,
603,11,87,4,0,
603,11,92,4,0,
603,11,104,4,0,
603,11,113,4,0,
603,11,148,4,0,
603,11,156,4,0,
603,11,164,4,0,
603,11,182,4,0,
603,11,192,1,69,
603,11,207,4,0,
603,11,209,1,1,3
603,11,213,4,0,
603,11,216,4,0,
603,11,218,4,0,
603,11,237,4,0,
603,11,240,4,0,
603,11,242,1,39,
603,11,263,4,0,
603,11,369,4,0,
603,11,380,1,64,
603,11,430,4,0,
603,11,435,1,29,
603,11,451,1,1,4
603,11,451,4,0,
603,11,489,1,54,
603,11,491,1,49,
603,11,496,4,0,
603,11,512,4,0,
603,11,521,4,0,
603,11,528,1,59,
603,11,528,4,0,
603,14,20,1,9,
603,14,20,3,0,
603,14,29,1,1,1
603,14,37,1,74,
603,14,51,1,19,
603,14,85,1,44,
603,14,85,4,0,
603,14,86,1,1,2
603,14,86,4,0,
603,14,87,4,0,
603,14,92,4,0,
603,14,104,4,0,
603,14,113,4,0,
603,14,148,4,0,
603,14,156,4,0,
603,14,162,3,0,
603,14,164,4,0,
603,14,173,3,0,
603,14,182,4,0,
603,14,192,1,69,
603,14,202,3,0,
603,14,207,4,0,
603,14,209,1,1,3
603,14,213,4,0,
603,14,214,3,0,
603,14,216,4,0,
603,14,218,4,0,
603,14,231,3,0,
603,14,237,4,0,
603,14,240,4,0,
603,14,242,1,39,
603,14,263,4,0,
603,14,282,3,0,
603,14,324,3,0,
603,14,340,3,0,
603,14,369,4,0,
603,14,380,1,64,
603,14,380,3,0,
603,14,393,3,0,
603,14,401,3,0,
603,14,430,4,0,
603,14,435,1,29,
603,14,451,1,1,4
603,14,451,4,0,
603,14,489,1,54,
603,14,491,1,49,
603,14,496,4,0,
603,14,512,4,0,
603,14,521,4,0,
603,14,528,1,59,
603,14,528,4,0,
603,15,20,1,9,
603,15,29,1,1,1
603,15,37,1,74,
603,15,51,1,19,
603,15,85,1,44,
603,15,85,4,0,
603,15,86,1,1,2
603,15,86,4,0,
603,15,87,4,0,
603,15,92,4,0,
603,15,104,4,0,
603,15,113,4,0,
603,15,148,4,0,
603,15,156,4,0,
603,15,164,4,0,
603,15,182,4,0,
603,15,192,1,69,
603,15,207,4,0,
603,15,209,1,1,3
603,15,213,4,0,
603,15,214,4,0,
603,15,216,4,0,
603,15,218,4,0,
603,15,237,4,0,
603,15,240,4,0,
603,15,242,1,39,
603,15,263,4,0,
603,15,369,4,0,
603,15,380,1,64,
603,15,430,4,0,
603,15,435,1,29,
603,15,451,1,1,4
603,15,451,4,0,
603,15,489,1,54,
603,15,491,1,49,
603,15,496,4,0,
603,15,512,4,0,
603,15,521,4,0,
603,15,528,1,59,
603,15,528,4,0,
603,15,590,4,0,
603,16,20,1,9,1
603,16,20,3,0,
603,16,29,1,1,1
603,16,37,1,74,1
603,16,51,1,19,1
603,16,85,1,44,1
603,16,85,4,0,
603,16,86,1,1,2
603,16,86,4,0,
603,16,87,4,0,
603,16,92,4,0,
603,16,104,4,0,
603,16,113,4,0,
603,16,148,4,0,
603,16,156,4,0,
603,16,162,3,0,
603,16,164,4,0,
603,16,173,3,0,
603,16,182,4,0,
603,16,192,1,69,1
603,16,202,3,0,
603,16,207,4,0,
603,16,209,1,1,3
603,16,213,4,0,
603,16,214,4,0,
603,16,216,4,0,
603,16,218,4,0,
603,16,231,3,0,
603,16,237,4,0,
603,16,240,4,0,
603,16,242,1,39,1
603,16,263,4,0,
603,16,282,3,0,
603,16,290,4,0,
603,16,324,3,0,
603,16,340,3,0,
603,16,351,3,0,
603,16,369,4,0,
603,16,380,1,64,1
603,16,380,3,0,
603,16,393,3,0,
603,16,401,3,0,
603,16,430,4,0,
603,16,435,1,29,1
603,16,451,1,1,4
603,16,451,4,0,
603,16,489,1,54,1
603,16,491,1,49,1
603,16,496,4,0,
603,16,512,4,0,
603,16,521,4,0,
603,16,528,1,59,1
603,16,528,4,0,
603,16,590,4,0,
603,17,20,1,9,
603,17,29,1,1,2
603,17,37,1,74,
603,17,51,1,19,
603,17,85,1,44,
603,17,85,4,0,
603,17,86,1,1,3
603,17,86,4,0,
603,17,87,4,0,
603,17,92,4,0,
603,17,104,4,0,
603,17,113,4,0,
603,17,156,4,0,
603,17,164,4,0,
603,17,182,4,0,
603,17,192,1,69,
603,17,207,4,0,
603,17,209,1,1,4
603,17,213,4,0,
603,17,214,4,0,
603,17,216,4,0,
603,17,218,4,0,
603,17,237,4,0,
603,17,240,4,0,
603,17,242,1,0,
603,17,242,1,1,1
603,17,263,4,0,
603,17,369,4,0,
603,17,380,1,64,
603,17,430,4,0,
603,17,435,1,29,
603,17,451,1,1,5
603,17,451,4,0,
603,17,489,1,54,
603,17,491,1,49,
603,17,496,4,0,
603,17,512,4,0,
603,17,521,4,0,
603,17,528,1,59,
603,17,528,4,0,
603,17,590,4,0,
603,18,20,1,9,
603,18,29,1,1,2
603,18,37,1,74,
603,18,51,1,19,
603,18,85,1,44,
603,18,85,4,0,
603,18,86,1,1,3
603,18,86,4,0,
603,18,87,4,0,
603,18,92,4,0,
603,18,104,4,0,
603,18,113,4,0,
603,18,156,4,0,
603,18,164,4,0,
603,18,182,4,0,
603,18,192,1,69,
603,18,207,4,0,
603,18,209,1,1,4
603,18,213,4,0,
603,18,214,4,0,
603,18,216,4,0,
603,18,218,4,0,
603,18,237,4,0,
603,18,240,4,0,
603,18,242,1,0,
603,18,242,1,1,1
603,18,263,4,0,
603,18,369,4,0,
603,18,380,1,64,
603,18,430,4,0,
603,18,435,1,29,
603,18,451,1,1,5
603,18,451,4,0,
603,18,489,1,54,
603,18,491,1,49,
603,18,496,4,0,
603,18,512,4,0,
603,18,521,4,0,
603,18,528,1,59,
603,18,528,4,0,
603,18,590,4,0,
604,11,15,4,0,
604,11,29,1,1,2
604,11,46,4,0,
604,11,51,1,1,3
604,11,53,4,0,
604,11,63,4,0,
604,11,70,4,0,
604,11,85,4,0,
604,11,86,4,0,
604,11,87,4,0,
604,11,92,4,0,
604,11,104,4,0,
604,11,113,4,0,
604,11,148,4,0,
604,11,156,4,0,
604,11,157,4,0,
604,11,164,4,0,
604,11,182,4,0,
604,11,207,4,0,
604,11,213,4,0,
604,11,216,4,0,
604,11,218,4,0,
604,11,237,4,0,
604,11,240,4,0,
604,11,242,1,1,5
604,11,249,4,0,
604,11,263,4,0,
604,11,280,4,0,
604,11,306,1,1,1
604,11,317,4,0,
604,11,337,4,0,
604,11,369,4,0,
604,11,416,4,0,
604,11,430,4,0,
604,11,435,1,1,4
604,11,447,4,0,
604,11,451,4,0,
604,11,468,4,0,
604,11,496,4,0,
604,11,512,4,0,
604,11,521,4,0,
604,11,525,4,0,
604,11,528,4,0,
604,14,7,3,0,
604,14,9,3,0,
604,14,15,4,0,
604,14,20,3,0,
604,14,29,1,1,2
604,14,46,4,0,
604,14,51,1,1,3
604,14,53,4,0,
604,14,63,4,0,
604,14,70,4,0,
604,14,85,4,0,
604,14,86,4,0,
604,14,87,4,0,
604,14,92,4,0,
604,14,104,4,0,
604,14,113,4,0,
604,14,148,4,0,
604,14,156,4,0,
604,14,157,4,0,
604,14,162,3,0,
604,14,164,4,0,
604,14,173,3,0,
604,14,182,4,0,
604,14,202,3,0,
604,14,207,4,0,
604,14,213,4,0,
604,14,214,3,0,
604,14,216,4,0,
604,14,218,4,0,
604,14,231,3,0,
604,14,237,4,0,
604,14,240,4,0,
604,14,242,1,1,5
604,14,249,4,0,
604,14,263,4,0,
604,14,276,3,0,
604,14,280,4,0,
604,14,282,3,0,
604,14,306,1,1,1
604,14,317,4,0,
604,14,324,3,0,
604,14,337,4,0,
604,14,340,3,0,
604,14,369,4,0,
604,14,380,3,0,
604,14,393,3,0,
604,14,401,3,0,
604,14,409,3,0,
604,14,416,4,0,
604,14,430,4,0,
604,14,435,1,1,4
604,14,447,4,0,
604,14,451,4,0,
604,14,468,4,0,
604,14,496,4,0,
604,14,512,4,0,
604,14,521,4,0,
604,14,525,4,0,
604,14,528,4,0,
604,15,15,4,0,
604,15,29,1,1,7
604,15,37,1,1,1
604,15,46,4,0,
604,15,51,1,1,8
604,15,53,4,0,
604,15,63,4,0,
604,15,70,4,0,
604,15,85,4,0,
604,15,86,4,0,
604,15,87,4,0,
604,15,92,4,0,
604,15,104,4,0,
604,15,113,4,0,
604,15,148,4,0,
604,15,156,4,0,
604,15,157,4,0,
604,15,164,4,0,
604,15,182,4,0,
604,15,192,1,1,2
604,15,207,4,0,
604,15,213,4,0,
604,15,214,4,0,
604,15,216,4,0,
604,15,218,4,0,
604,15,237,4,0,
604,15,240,4,0,
604,15,242,1,1,10
604,15,249,4,0,
604,15,263,4,0,
604,15,280,4,0,
604,15,306,1,1,6
604,15,317,4,0,
604,15,337,4,0,
604,15,369,4,0,
604,15,380,1,1,3
604,15,416,4,0,
604,15,430,4,0,
604,15,435,1,1,9
604,15,447,4,0,
604,15,451,4,0,
604,15,468,4,0,
604,15,489,1,1,4
604,15,496,4,0,
604,15,512,4,0,
604,15,521,4,0,
604,15,525,4,0,
604,15,528,4,0,
604,15,569,1,1,5
604,15,590,4,0,
604,15,612,4,0,
604,16,7,3,0,
604,16,9,3,0,
604,16,15,4,0,
604,16,20,3,0,
604,16,29,1,1,7
604,16,37,1,1,1
604,16,46,4,0,
604,16,51,1,1,8
604,16,53,4,0,
604,16,63,4,0,
604,16,70,4,0,
604,16,85,4,0,
604,16,86,4,0,
604,16,87,4,0,
604,16,92,4,0,
604,16,104,4,0,
604,16,113,4,0,
604,16,148,4,0,
604,16,156,4,0,
604,16,157,4,0,
604,16,162,3,0,
604,16,164,4,0,
604,16,173,3,0,
604,16,182,4,0,
604,16,192,1,1,2
604,16,200,3,0,
604,16,202,3,0,
604,16,207,4,0,
604,16,213,4,0,
604,16,214,4,0,
604,16,216,4,0,
604,16,218,4,0,
604,16,231,3,0,
604,16,237,4,0,
604,16,240,4,0,
604,16,242,1,1,10
604,16,249,4,0,
604,16,263,4,0,
604,16,264,3,0,
604,16,276,3,0,
604,16,280,4,0,
604,16,282,3,0,
604,16,290,4,0,
604,16,306,1,1,6
604,16,317,4,0,
604,16,324,3,0,
604,16,337,4,0,
604,16,340,3,0,
604,16,351,3,0,
604,16,369,4,0,
604,16,380,1,1,3
604,16,380,3,0,
604,16,393,3,0,
604,16,401,3,0,
604,16,406,3,0,
604,16,409,3,0,
604,16,416,4,0,
604,16,430,4,0,
604,16,435,1,1,9
604,16,447,4,0,
604,16,451,4,0,
604,16,468,4,0,
604,16,489,1,1,4
604,16,496,4,0,
604,16,512,4,0,
604,16,521,4,0,
604,16,525,4,0,
604,16,528,4,0,
604,16,569,1,1,5
604,16,590,4,0,
604,16,612,4,0,
604,17,29,1,1,8
604,17,37,1,1,2
604,17,46,4,0,
604,17,51,1,1,9
604,17,53,4,0,
604,17,63,4,0,
604,17,85,4,0,
604,17,86,4,0,
604,17,87,4,0,
604,17,92,4,0,
604,17,104,4,0,
604,17,113,4,0,
604,17,156,4,0,
604,17,157,4,0,
604,17,164,4,0,
604,17,182,4,0,
604,17,192,1,1,3
604,17,207,4,0,
604,17,213,4,0,
604,17,214,4,0,
604,17,216,4,0,
604,17,218,4,0,
604,17,237,4,0,
604,17,240,4,0,
604,17,242,1,1,1
604,17,263,4,0,
604,17,280,4,0,
604,17,306,1,1,7
604,17,317,4,0,
604,17,337,4,0,
604,17,369,4,0,
604,17,380,1,1,4
604,17,416,4,0,
604,17,430,4,0,
604,17,435,1,1,10
604,17,447,4,0,
604,17,451,4,0,
604,17,489,1,1,5
604,17,496,4,0,
604,17,512,4,0,
604,17,521,4,0,
604,17,525,4,0,
604,17,528,4,0,
604,17,569,1,1,6
604,17,590,4,0,
604,18,29,1,1,8
604,18,37,1,1,2
604,18,46,4,0,
604,18,51,1,1,9
604,18,53,4,0,
604,18,63,4,0,
604,18,85,4,0,
604,18,86,4,0,
604,18,87,4,0,
604,18,92,4,0,
604,18,104,4,0,
604,18,113,4,0,
604,18,156,4,0,
604,18,157,4,0,
604,18,164,4,0,
604,18,182,4,0,
604,18,192,1,1,3
604,18,207,4,0,
604,18,213,4,0,
604,18,214,4,0,
604,18,216,4,0,
604,18,218,4,0,
604,18,237,4,0,
604,18,240,4,0,
604,18,242,1,1,1
604,18,263,4,0,
604,18,280,4,0,
604,18,306,1,1,7
604,18,317,4,0,
604,18,337,4,0,
604,18,369,4,0,
604,18,380,1,1,4
604,18,416,4,0,
604,18,430,4,0,
604,18,435,1,1,10
604,18,447,4,0,
604,18,451,4,0,
604,18,489,1,1,5
604,18,496,4,0,
604,18,512,4,0,
604,18,521,4,0,
604,18,525,4,0,
604,18,528,4,0,
604,18,569,1,1,6
604,18,590,4,0,
605,11,29,1,18,
605,11,45,1,4,
605,11,50,2,0,
605,11,60,1,15,
605,11,85,4,0,
605,11,86,4,0,
605,11,92,4,0,
605,11,93,1,1,
605,11,94,1,39,
605,11,94,4,0,
605,11,100,2,0,
605,11,104,4,0,
605,11,105,1,46,
605,11,112,2,0,
605,11,113,4,0,
605,11,115,4,0,
605,11,138,4,0,
605,11,148,4,0,
605,11,156,4,0,
605,11,157,4,0,
605,11,164,4,0,
605,11,168,4,0,
605,11,182,4,0,
605,11,207,4,0,
605,11,213,4,0,
605,11,216,4,0,
605,11,218,4,0,
605,11,219,4,0,
605,11,237,1,22,
605,11,237,4,0,
605,11,240,4,0,
605,11,244,1,36,
605,11,244,4,0,
605,11,247,4,0,
605,11,263,4,0,
605,11,285,2,0,
605,11,286,1,25,
605,11,310,2,0,
605,11,317,4,0,
605,11,347,1,43,
605,11,347,4,0,
605,11,357,1,11,
605,11,373,4,0,
605,11,377,1,8,
605,11,384,2,0,
605,11,385,2,0,
605,11,412,4,0,
605,11,417,2,0,
605,11,428,1,32,
605,11,433,4,0,
605,11,451,4,0,
605,11,470,1,50,1
605,11,471,1,50,2
605,11,472,1,56,
605,11,473,4,0,
605,11,477,4,0,
605,11,485,1,53,
605,11,493,1,29,
605,11,496,4,0,
605,11,497,4,0,
605,11,502,4,0,
605,14,29,1,18,
605,14,45,1,4,
605,14,50,2,0,
605,14,60,1,15,
605,14,85,4,0,
605,14,86,4,0,
605,14,92,4,0,
605,14,93,1,1,
605,14,94,1,39,
605,14,94,4,0,
605,14,100,2,0,
605,14,104,4,0,
605,14,105,1,46,
605,14,112,2,0,
605,14,113,4,0,
605,14,115,4,0,
605,14,138,4,0,
605,14,148,4,0,
605,14,156,4,0,
605,14,157,4,0,
605,14,164,4,0,
605,14,168,4,0,
605,14,173,3,0,
605,14,182,4,0,
605,14,207,4,0,
605,14,213,4,0,
605,14,214,3,0,
605,14,216,4,0,
605,14,218,4,0,
605,14,219,4,0,
605,14,220,3,0,
605,14,237,1,22,
605,14,237,4,0,
605,14,240,4,0,
605,14,244,1,36,
605,14,244,4,0,
605,14,247,4,0,
605,14,253,3,0,
605,14,263,4,0,
605,14,271,3,0,
605,14,272,3,0,
605,14,277,3,0,
605,14,278,3,0,
605,14,285,2,0,
605,14,285,3,0,
605,14,286,1,25,
605,14,289,3,0,
605,14,310,2,0,
605,14,317,4,0,
605,14,324,3,0,
605,14,347,1,43,
605,14,347,4,0,
605,14,356,3,0,
605,14,357,1,11,
605,14,373,4,0,
605,14,377,1,8,
605,14,384,2,0,
605,14,385,2,0,
605,14,399,3,0,
605,14,412,4,0,
605,14,417,2,0,
605,14,428,1,32,
605,14,428,3,0,
605,14,433,4,0,
605,14,451,4,0,
605,14,470,1,50,1
605,14,471,1,50,2
605,14,472,1,56,
605,14,472,3,0,
605,14,473,4,0,
605,14,477,4,0,
605,14,485,1,53,
605,14,493,1,29,
605,14,495,3,0,
605,14,496,4,0,
605,14,497,4,0,
605,14,502,4,0,
605,15,29,1,18,
605,15,45,1,4,
605,15,50,2,0,
605,15,60,1,15,
605,15,85,4,0,
605,15,86,4,0,
605,15,92,4,0,
605,15,93,1,1,
605,15,94,1,39,
605,15,94,4,0,
605,15,100,2,0,
605,15,104,4,0,
605,15,105,1,46,
605,15,112,2,0,
605,15,113,4,0,
605,15,115,4,0,
605,15,138,4,0,
605,15,148,4,0,
605,15,156,4,0,
605,15,157,4,0,
605,15,164,4,0,
605,15,168,4,0,
605,15,182,4,0,
605,15,207,4,0,
605,15,211,4,0,
605,15,213,4,0,
605,15,214,4,0,
605,15,216,4,0,
605,15,218,4,0,
605,15,219,4,0,
605,15,237,1,22,
605,15,237,4,0,
605,15,240,4,0,
605,15,244,1,36,
605,15,244,4,0,
605,15,247,4,0,
605,15,263,4,0,
605,15,285,2,0,
605,15,286,1,25,
605,15,310,2,0,
605,15,317,4,0,
605,15,322,2,0,
605,15,347,1,43,
605,15,347,4,0,
605,15,357,1,11,
605,15,373,4,0,
605,15,377,1,8,
605,15,384,2,0,
605,15,385,2,0,
605,15,399,4,0,
605,15,412,4,0,
605,15,417,2,0,
605,15,428,1,32,
605,15,433,4,0,
605,15,451,4,0,
605,15,470,1,50,1
605,15,471,1,50,2
605,15,472,1,56,
605,15,473,4,0,
605,15,485,1,53,
605,15,493,1,29,
605,15,496,4,0,
605,15,497,4,0,
605,15,502,2,0,
605,15,590,4,0,
605,16,29,1,18,1
605,16,45,1,4,1
605,16,50,2,0,
605,16,60,1,15,1
605,16,85,4,0,
605,16,86,4,0,
605,16,92,4,0,
605,16,93,1,1,1
605,16,94,1,39,1
605,16,94,4,0,
605,16,100,2,0,
605,16,104,4,0,
605,16,105,1,46,1
605,16,112,2,0,
605,16,113,4,0,
605,16,115,4,0,
605,16,138,4,0,
605,16,148,4,0,
605,16,156,4,0,
605,16,157,4,0,
605,16,164,4,0,
605,16,168,4,0,
605,16,173,3,0,
605,16,182,4,0,
605,16,207,4,0,
605,16,211,4,0,
605,16,213,4,0,
605,16,214,4,0,
605,16,216,4,0,
605,16,218,4,0,
605,16,219,4,0,
605,16,220,3,0,
605,16,237,1,22,1
605,16,237,4,0,
605,16,240,4,0,
605,16,244,1,36,1
605,16,244,4,0,
605,16,247,4,0,
605,16,253,3,0,
605,16,263,4,0,
605,16,271,3,0,
605,16,272,3,0,
605,16,277,3,0,
605,16,278,3,0,
605,16,285,2,0,
605,16,285,3,0,
605,16,286,1,25,1
605,16,289,3,0,
605,16,290,4,0,
605,16,310,2,0,
605,16,317,4,0,
605,16,322,2,0,
605,16,324,3,0,
605,16,347,1,43,1
605,16,347,4,0,
605,16,351,3,0,
605,16,356,3,0,
605,16,357,1,11,1
605,16,373,4,0,
605,16,377,1,8,1
605,16,384,2,0,
605,16,385,2,0,
605,16,399,4,0,
605,16,412,4,0,
605,16,417,2,0,
605,16,428,1,32,1
605,16,428,3,0,
605,16,433,4,0,
605,16,451,4,0,
605,16,470,1,50,1
605,16,471,1,50,2
605,16,472,1,56,1
605,16,472,3,0,
605,16,473,4,0,
605,16,485,1,53,1
605,16,493,1,29,1
605,16,495,3,0,
605,16,496,4,0,
605,16,497,4,0,
605,16,502,2,0,
605,16,590,4,0,
605,17,29,1,18,
605,17,45,1,4,
605,17,50,2,0,
605,17,60,1,15,
605,17,85,4,0,
605,17,86,4,0,
605,17,92,4,0,
605,17,93,1,1,
605,17,94,1,39,
605,17,94,4,0,
605,17,100,2,0,
605,17,104,4,0,
605,17,105,1,46,
605,17,112,2,0,
605,17,113,4,0,
605,17,115,4,0,
605,17,138,4,0,
605,17,156,4,0,
605,17,157,4,0,
605,17,164,4,0,
605,17,168,4,0,
605,17,182,4,0,
605,17,207,4,0,
605,17,211,4,0,
605,17,213,4,0,
605,17,214,4,0,
605,17,216,4,0,
605,17,218,4,0,
605,17,219,4,0,
605,17,237,1,22,
605,17,237,4,0,
605,17,240,4,0,
605,17,244,1,36,
605,17,244,4,0,
605,17,247,4,0,
605,17,263,4,0,
605,17,285,2,0,
605,17,286,1,25,
605,17,310,2,0,
605,17,317,4,0,
605,17,322,2,0,
605,17,347,1,43,
605,17,347,4,0,
605,17,357,1,11,
605,17,373,4,0,
605,17,377,1,8,
605,17,384,2,0,
605,17,385,2,0,
605,17,399,4,0,
605,17,412,4,0,
605,17,417,2,0,
605,17,428,1,32,
605,17,433,4,0,
605,17,451,4,0,
605,17,470,1,50,1
605,17,471,1,50,2
605,17,472,1,56,
605,17,473,4,0,
605,17,485,1,53,
605,17,493,1,29,
605,17,496,4,0,
605,17,497,4,0,
605,17,502,2,0,
605,17,590,4,0,
605,18,29,1,18,
605,18,45,1,4,
605,18,50,2,0,
605,18,60,1,15,
605,18,85,4,0,
605,18,86,4,0,
605,18,92,4,0,
605,18,93,1,1,
605,18,94,1,39,
605,18,94,4,0,
605,18,100,2,0,
605,18,104,4,0,
605,18,105,1,46,
605,18,112,2,0,
605,18,113,4,0,
605,18,115,4,0,
605,18,138,4,0,
605,18,156,4,0,
605,18,157,4,0,
605,18,164,4,0,
605,18,168,4,0,
605,18,182,4,0,
605,18,207,4,0,
605,18,211,4,0,
605,18,213,4,0,
605,18,214,4,0,
605,18,216,4,0,
605,18,218,4,0,
605,18,219,4,0,
605,18,237,1,22,
605,18,237,4,0,
605,18,240,4,0,
605,18,244,1,36,
605,18,244,4,0,
605,18,247,4,0,
605,18,263,4,0,
605,18,285,2,0,
605,18,286,1,25,
605,18,310,2,0,
605,18,317,4,0,
605,18,322,2,0,
605,18,347,1,43,
605,18,347,4,0,
605,18,357,1,11,
605,18,373,4,0,
605,18,377,1,8,
605,18,384,2,0,
605,18,385,2,0,
605,18,399,4,0,
605,18,412,4,0,
605,18,417,2,0,
605,18,428,1,32,
605,18,433,4,0,
605,18,451,4,0,
605,18,470,1,50,1
605,18,471,1,50,2
605,18,472,1,56,
605,18,473,4,0,
605,18,485,1,53,
605,18,493,1,29,
605,18,496,4,0,
605,18,497,4,0,
605,18,502,2,0,
605,18,590,4,0,
606,11,29,1,18,
606,11,45,1,1,2
606,11,45,1,4,
606,11,60,1,15,
606,11,63,4,0,
606,11,85,4,0,
606,11,86,4,0,
606,11,92,4,0,
606,11,93,1,1,1
606,11,94,1,39,
606,11,94,4,0,
606,11,104,4,0,
606,11,105,1,50,
606,11,113,4,0,
606,11,115,4,0,
606,11,138,4,0,
606,11,148,4,0,
606,11,156,4,0,
606,11,157,4,0,
606,11,164,4,0,
606,11,168,4,0,
606,11,182,4,0,
606,11,207,4,0,
606,11,213,4,0,
606,11,216,4,0,
606,11,218,4,0,
606,11,219,4,0,
606,11,237,1,22,
606,11,237,4,0,
606,11,240,4,0,
606,11,244,1,36,
606,11,244,4,0,
606,11,247,4,0,
606,11,263,4,0,
606,11,286,1,25,
606,11,317,4,0,
606,11,347,1,45,
606,11,347,4,0,
606,11,357,1,1,4
606,11,357,1,11,
606,11,373,4,0,
606,11,377,1,1,3
606,11,377,1,8,
606,11,412,4,0,
606,11,416,4,0,
606,11,428,1,32,
606,11,433,4,0,
606,11,451,4,0,
606,11,470,1,56,
606,11,471,1,58,
606,11,472,1,68,
606,11,473,4,0,
606,11,477,4,0,
606,11,485,1,63,
606,11,493,1,29,
606,11,496,4,0,
606,11,497,4,0,
606,11,502,4,0,
606,14,29,1,18,
606,14,45,1,1,2
606,14,45,1,4,
606,14,60,1,15,
606,14,63,4,0,
606,14,85,4,0,
606,14,86,4,0,
606,14,92,4,0,
606,14,93,1,1,1
606,14,94,1,39,
606,14,94,4,0,
606,14,104,4,0,
606,14,105,1,50,
606,14,113,4,0,
606,14,115,4,0,
606,14,138,4,0,
606,14,148,4,0,
606,14,156,4,0,
606,14,157,4,0,
606,14,164,4,0,
606,14,168,4,0,
606,14,173,3,0,
606,14,182,4,0,
606,14,207,4,0,
606,14,213,4,0,
606,14,214,3,0,
606,14,216,4,0,
606,14,218,4,0,
606,14,219,4,0,
606,14,220,3,0,
606,14,237,1,22,
606,14,237,4,0,
606,14,240,4,0,
606,14,244,1,36,
606,14,244,4,0,
606,14,247,4,0,
606,14,253,3,0,
606,14,263,4,0,
606,14,271,3,0,
606,14,272,3,0,
606,14,277,3,0,
606,14,278,3,0,
606,14,285,3,0,
606,14,286,1,25,
606,14,289,3,0,
606,14,317,4,0,
606,14,324,3,0,
606,14,347,1,45,
606,14,347,4,0,
606,14,356,3,0,
606,14,357,1,1,4
606,14,357,1,11,
606,14,373,4,0,
606,14,377,1,1,3
606,14,377,1,8,
606,14,399,3,0,
606,14,412,4,0,
606,14,416,4,0,
606,14,428,1,32,
606,14,428,3,0,
606,14,433,4,0,
606,14,451,4,0,
606,14,470,1,56,
606,14,471,1,58,
606,14,472,1,68,
606,14,472,3,0,
606,14,473,4,0,
606,14,477,4,0,
606,14,485,1,63,
606,14,493,1,29,
606,14,495,3,0,
606,14,496,4,0,
606,14,497,4,0,
606,14,502,4,0,
606,15,29,1,18,
606,15,45,1,1,4
606,15,45,1,4,
606,15,60,1,15,
606,15,63,4,0,
606,15,85,4,0,
606,15,86,4,0,
606,15,92,4,0,
606,15,93,1,1,3
606,15,94,1,39,
606,15,94,4,0,
606,15,104,4,0,
606,15,105,1,50,
606,15,113,4,0,
606,15,115,4,0,
606,15,138,4,0,
606,15,148,4,0,
606,15,156,4,0,
606,15,157,4,0,
606,15,164,4,0,
606,15,168,4,0,
606,15,182,4,0,
606,15,207,4,0,
606,15,211,4,0,
606,15,213,4,0,
606,15,214,4,0,
606,15,216,4,0,
606,15,218,4,0,
606,15,219,4,0,
606,15,237,1,22,
606,15,237,4,0,
606,15,240,4,0,
606,15,244,1,36,
606,15,244,4,0,
606,15,247,4,0,
606,15,263,4,0,
606,15,286,1,25,
606,15,317,4,0,
606,15,347,1,45,
606,15,347,4,0,
606,15,357,1,1,6
606,15,357,1,11,
606,15,373,4,0,
606,15,377,1,1,5
606,15,377,1,8,
606,15,399,4,0,
606,15,412,4,0,
606,15,416,4,0,
606,15,428,1,32,
606,15,433,4,0,
606,15,451,4,0,
606,15,470,1,56,
606,15,471,1,58,
606,15,472,1,1,1
606,15,472,1,68,
606,15,473,4,0,
606,15,485,1,1,2
606,15,485,1,63,
606,15,493,1,29,
606,15,496,4,0,
606,15,497,4,0,
606,15,590,4,0,
606,16,29,1,18,1
606,16,45,1,1,4
606,16,45,1,4,1
606,16,60,1,15,1
606,16,63,4,0,
606,16,85,4,0,
606,16,86,4,0,
606,16,92,4,0,
606,16,93,1,1,3
606,16,94,1,39,1
606,16,94,4,0,
606,16,104,4,0,
606,16,105,1,50,1
606,16,113,4,0,
606,16,115,4,0,
606,16,138,4,0,
606,16,148,4,0,
606,16,156,4,0,
606,16,157,4,0,
606,16,164,4,0,
606,16,168,4,0,
606,16,173,3,0,
606,16,182,4,0,
606,16,207,4,0,
606,16,211,4,0,
606,16,213,4,0,
606,16,214,4,0,
606,16,216,4,0,
606,16,218,4,0,
606,16,219,4,0,
606,16,220,3,0,
606,16,237,1,22,1
606,16,237,4,0,
606,16,240,4,0,
606,16,244,1,36,1
606,16,244,4,0,
606,16,247,4,0,
606,16,253,3,0,
606,16,263,4,0,
606,16,271,3,0,
606,16,272,3,0,
606,16,277,3,0,
606,16,278,3,0,
606,16,285,3,0,
606,16,286,1,25,1
606,16,289,3,0,
606,16,290,4,0,
606,16,317,4,0,
606,16,324,3,0,
606,16,347,1,45,1
606,16,347,4,0,
606,16,351,3,0,
606,16,356,3,0,
606,16,357,1,1,6
606,16,357,1,11,1
606,16,373,4,0,
606,16,377,1,1,5
606,16,377,1,8,1
606,16,399,4,0,
606,16,412,4,0,
606,16,416,4,0,
606,16,428,1,32,1
606,16,428,3,0,
606,16,433,4,0,
606,16,451,4,0,
606,16,470,1,56,1
606,16,471,1,58,1
606,16,472,1,1,1
606,16,472,1,68,1
606,16,472,3,0,
606,16,473,4,0,
606,16,485,1,1,2
606,16,485,1,63,1
606,16,493,1,29,1
606,16,495,3,0,
606,16,496,4,0,
606,16,497,4,0,
606,16,590,4,0,
606,17,29,1,18,
606,17,45,1,1,5
606,17,45,1,4,
606,17,60,1,15,
606,17,63,4,0,
606,17,85,4,0,
606,17,86,4,0,
606,17,92,4,0,
606,17,93,1,1,4
606,17,94,1,39,
606,17,94,4,0,
606,17,104,4,0,
606,17,105,1,50,
606,17,113,4,0,
606,17,115,4,0,
606,17,138,4,0,
606,17,156,4,0,
606,17,157,4,0,
606,17,164,4,0,
606,17,168,4,0,
606,17,182,4,0,
606,17,207,4,0,
606,17,211,4,0,
606,17,213,4,0,
606,17,214,4,0,
606,17,216,4,0,
606,17,218,4,0,
606,17,219,4,0,
606,17,237,1,22,
606,17,237,4,0,
606,17,240,4,0,
606,17,244,1,36,
606,17,244,4,0,
606,17,247,4,0,
606,17,263,4,0,
606,17,286,1,25,
606,17,317,4,0,
606,17,347,1,45,
606,17,347,4,0,
606,17,357,1,1,7
606,17,357,1,11,
606,17,373,4,0,
606,17,377,1,1,6
606,17,377,1,8,
606,17,399,4,0,
606,17,412,4,0,
606,17,416,4,0,
606,17,428,1,32,
606,17,433,4,0,
606,17,451,4,0,
606,17,470,1,56,
606,17,471,1,58,
606,17,472,1,1,2
606,17,472,1,68,
606,17,473,4,0,
606,17,485,1,1,3
606,17,485,1,63,
606,17,493,1,29,
606,17,496,4,0,
606,17,497,4,0,
606,17,590,4,0,
606,17,678,1,1,1
606,18,29,1,18,
606,18,45,1,1,5
606,18,45,1,4,
606,18,60,1,15,
606,18,63,4,0,
606,18,85,4,0,
606,18,86,4,0,
606,18,92,4,0,
606,18,93,1,1,4
606,18,94,1,39,
606,18,94,4,0,
606,18,104,4,0,
606,18,105,1,50,
606,18,113,4,0,
606,18,115,4,0,
606,18,138,4,0,
606,18,156,4,0,
606,18,157,4,0,
606,18,164,4,0,
606,18,168,4,0,
606,18,182,4,0,
606,18,207,4,0,
606,18,211,4,0,
606,18,213,4,0,
606,18,214,4,0,
606,18,216,4,0,
606,18,218,4,0,
606,18,219,4,0,
606,18,237,1,22,
606,18,237,4,0,
606,18,240,4,0,
606,18,244,1,36,
606,18,244,4,0,
606,18,247,4,0,
606,18,263,4,0,
606,18,286,1,25,
606,18,317,4,0,
606,18,347,1,45,
606,18,347,4,0,
606,18,357,1,1,7
606,18,357,1,11,
606,18,373,4,0,
606,18,377,1,1,6
606,18,377,1,8,
606,18,399,4,0,
606,18,412,4,0,
606,18,416,4,0,
606,18,428,1,32,
606,18,433,4,0,
606,18,451,4,0,
606,18,470,1,56,
606,18,471,1,58,
606,18,472,1,1,2
606,18,472,1,68,
606,18,473,4,0,
606,18,485,1,1,3
606,18,485,1,63,
606,18,493,1,29,
606,18,496,4,0,
606,18,497,4,0,
606,18,590,4,0,
606,18,678,1,1,1
607,11,51,2,0,
607,11,52,1,1,1
607,11,53,4,0,
607,11,76,4,0,
607,11,83,1,7,
607,11,92,4,0,
607,11,94,4,0,
607,11,101,1,13,
607,11,104,4,0,
607,11,107,1,3,
607,11,109,1,10,
607,11,114,2,0,
607,11,123,1,5,
607,11,126,4,0,
607,11,138,4,0,
607,11,148,4,0,
607,11,151,2,0,
607,11,156,4,0,
607,11,164,4,0,
607,11,168,4,0,
607,11,174,1,43,
607,11,182,4,0,
607,11,203,2,0,
607,11,207,4,0,
607,11,213,4,0,
607,11,216,4,0,
607,11,218,4,0,
607,11,219,4,0,
607,11,220,1,55,
607,11,237,4,0,
607,11,241,4,0,
607,11,244,4,0,
607,11,247,1,49,
607,11,247,4,0,
607,11,257,2,0,
607,11,261,1,16,
607,11,261,4,0,
607,11,262,1,33,
607,11,263,4,0,
607,11,269,4,0,
607,11,286,1,24,
607,11,310,1,1,2
607,11,315,1,61,
607,11,315,4,0,
607,11,347,4,0,
607,11,371,4,0,
607,11,373,4,0,
607,11,412,4,0,
607,11,433,4,0,
607,11,445,2,0,
607,11,477,4,0,
607,11,481,1,20,
607,11,488,4,0,
607,11,496,4,0,
607,11,499,2,0,
607,11,506,1,28,
607,11,510,4,0,
607,11,517,1,38,
607,14,51,2,0,
607,14,52,1,1,1
607,14,53,4,0,
607,14,76,4,0,
607,14,83,1,7,
607,14,92,4,0,
607,14,94,4,0,
607,14,101,1,13,
607,14,104,4,0,
607,14,107,1,3,
607,14,109,1,10,
607,14,114,2,0,
607,14,123,1,5,
607,14,126,4,0,
607,14,138,4,0,
607,14,148,4,0,
607,14,151,2,0,
607,14,156,4,0,
607,14,164,4,0,
607,14,168,4,0,
607,14,173,3,0,
607,14,174,1,43,
607,14,180,3,0,
607,14,182,4,0,
607,14,203,2,0,
607,14,207,4,0,
607,14,213,4,0,
607,14,214,3,0,
607,14,216,4,0,
607,14,218,4,0,
607,14,219,4,0,
607,14,220,1,55,
607,14,220,3,0,
607,14,237,4,0,
607,14,241,4,0,
607,14,244,4,0,
607,14,247,1,49,
607,14,247,4,0,
607,14,257,2,0,
607,14,257,3,0,
607,14,261,1,16,
607,14,261,4,0,
607,14,262,1,33,
607,14,263,4,0,
607,14,269,4,0,
607,14,271,3,0,
607,14,286,1,24,
607,14,310,1,1,2
607,14,315,1,61,
607,14,315,4,0,
607,14,347,4,0,
607,14,371,4,0,
607,14,373,4,0,
607,14,399,3,0,
607,14,412,4,0,
607,14,433,4,0,
607,14,445,2,0,
607,14,477,4,0,
607,14,481,1,20,
607,14,488,4,0,
607,14,496,4,0,
607,14,499,2,0,
607,14,506,1,28,
607,14,510,4,0,
607,14,517,1,38,
607,15,51,2,0,
607,15,52,1,1,1
607,15,53,4,0,
607,15,76,4,0,
607,15,83,1,7,
607,15,92,4,0,
607,15,94,4,0,
607,15,101,1,13,
607,15,104,4,0,
607,15,107,1,3,
607,15,109,1,10,
607,15,114,2,0,
607,15,123,1,5,
607,15,126,4,0,
607,15,138,4,0,
607,15,148,4,0,
607,15,151,2,0,
607,15,156,4,0,
607,15,164,4,0,
607,15,168,4,0,
607,15,174,1,43,
607,15,182,4,0,
607,15,203,2,0,
607,15,207,4,0,
607,15,213,4,0,
607,15,214,4,0,
607,15,216,4,0,
607,15,218,4,0,
607,15,219,4,0,
607,15,220,1,55,
607,15,237,4,0,
607,15,241,4,0,
607,15,244,4,0,
607,15,247,1,49,
607,15,247,4,0,
607,15,257,2,0,
607,15,261,1,16,
607,15,261,4,0,
607,15,262,1,33,
607,15,263,4,0,
607,15,269,4,0,
607,15,286,1,24,
607,15,310,1,1,2
607,15,315,1,61,
607,15,315,4,0,
607,15,347,4,0,
607,15,371,4,0,
607,15,373,4,0,
607,15,399,4,0,
607,15,412,4,0,
607,15,433,4,0,
607,15,445,2,0,
607,15,471,2,0,
607,15,481,1,20,
607,15,488,4,0,
607,15,496,4,0,
607,15,499,2,0,
607,15,506,1,28,
607,15,510,4,0,
607,15,517,1,38,
607,15,590,4,0,
607,16,51,2,0,
607,16,52,1,1,1
607,16,53,4,0,
607,16,76,4,0,
607,16,83,1,7,1
607,16,92,4,0,
607,16,94,4,0,
607,16,101,1,13,1
607,16,104,4,0,
607,16,107,1,3,1
607,16,109,1,10,1
607,16,114,2,0,
607,16,123,1,5,1
607,16,126,4,0,
607,16,138,4,0,
607,16,148,4,0,
607,16,151,2,0,
607,16,156,4,0,
607,16,164,4,0,
607,16,168,4,0,
607,16,173,3,0,
607,16,174,1,43,1
607,16,180,3,0,
607,16,182,4,0,
607,16,203,2,0,
607,16,207,4,0,
607,16,213,4,0,
607,16,214,4,0,
607,16,216,4,0,
607,16,218,4,0,
607,16,219,4,0,
607,16,220,1,55,1
607,16,220,3,0,
607,16,237,4,0,
607,16,241,4,0,
607,16,244,4,0,
607,16,247,1,49,1
607,16,247,4,0,
607,16,257,2,0,
607,16,257,3,0,
607,16,261,1,16,1
607,16,261,4,0,
607,16,262,1,33,1
607,16,263,4,0,
607,16,269,4,0,
607,16,271,3,0,
607,16,286,1,24,1
607,16,290,4,0,
607,16,310,1,1,2
607,16,315,1,61,1
607,16,315,4,0,
607,16,347,4,0,
607,16,351,3,0,
607,16,371,4,0,
607,16,373,4,0,
607,16,399,4,0,
607,16,412,4,0,
607,16,433,4,0,
607,16,445,2,0,
607,16,471,2,0,
607,16,481,1,20,1
607,16,488,4,0,
607,16,496,4,0,
607,16,499,2,0,
607,16,506,1,28,1
607,16,510,4,0,
607,16,517,1,38,1
607,16,590,4,0,
607,17,51,2,0,
607,17,52,1,1,1
607,17,53,4,0,
607,17,76,4,0,
607,17,83,1,7,
607,17,92,4,0,
607,17,94,4,0,
607,17,101,1,13,
607,17,104,4,0,
607,17,107,1,3,
607,17,109,1,10,
607,17,114,2,0,
607,17,123,1,5,
607,17,126,4,0,
607,17,138,4,0,
607,17,151,2,0,
607,17,156,4,0,
607,17,164,4,0,
607,17,168,4,0,
607,17,174,1,43,
607,17,182,4,0,
607,17,203,2,0,
607,17,207,4,0,
607,17,213,4,0,
607,17,214,4,0,
607,17,216,4,0,
607,17,218,4,0,
607,17,219,4,0,
607,17,220,1,55,
607,17,237,4,0,
607,17,241,4,0,
607,17,244,4,0,
607,17,247,1,49,
607,17,247,4,0,
607,17,257,2,0,
607,17,261,1,16,
607,17,261,4,0,
607,17,262,1,33,
607,17,263,4,0,
607,17,269,4,0,
607,17,286,1,24,
607,17,310,1,1,2
607,17,315,1,61,
607,17,315,4,0,
607,17,347,4,0,
607,17,371,4,0,
607,17,373,4,0,
607,17,399,4,0,
607,17,412,4,0,
607,17,433,4,0,
607,17,445,2,0,
607,17,471,2,0,
607,17,481,1,20,
607,17,488,4,0,
607,17,496,4,0,
607,17,499,2,0,
607,17,506,1,28,
607,17,517,1,38,
607,17,590,4,0,
607,18,51,2,0,
607,18,52,1,1,1
607,18,53,4,0,
607,18,76,4,0,
607,18,83,1,7,
607,18,92,4,0,
607,18,94,4,0,
607,18,101,1,13,
607,18,104,4,0,
607,18,107,1,3,
607,18,109,1,10,
607,18,114,2,0,
607,18,123,1,5,
607,18,126,4,0,
607,18,138,4,0,
607,18,151,2,0,
607,18,156,4,0,
607,18,164,4,0,
607,18,168,4,0,
607,18,174,1,43,
607,18,182,4,0,
607,18,203,2,0,
607,18,207,4,0,
607,18,213,4,0,
607,18,214,4,0,
607,18,216,4,0,
607,18,218,4,0,
607,18,219,4,0,
607,18,220,1,55,
607,18,237,4,0,
607,18,241,4,0,
607,18,244,4,0,
607,18,247,1,49,
607,18,247,4,0,
607,18,257,2,0,
607,18,261,1,16,
607,18,261,4,0,
607,18,262,1,33,
607,18,263,4,0,
607,18,269,4,0,
607,18,286,1,24,
607,18,310,1,1,2
607,18,315,1,61,
607,18,315,4,0,
607,18,347,4,0,
607,18,371,4,0,
607,18,373,4,0,
607,18,399,4,0,
607,18,412,4,0,
607,18,433,4,0,
607,18,445,2,0,
607,18,471,2,0,
607,18,481,1,20,
607,18,488,4,0,
607,18,496,4,0,
607,18,499,2,0,
607,18,506,1,28,
607,18,517,1,38,
607,18,590,4,0,
608,11,52,1,1,1
608,11,53,4,0,
608,11,76,4,0,
608,11,83,1,7,
608,11,92,4,0,
608,11,94,4,0,
608,11,101,1,13,
608,11,104,4,0,
608,11,107,1,1,3
608,11,107,1,3,
608,11,109,1,10,
608,11,123,1,1,4
608,11,123,1,5,
608,11,126,4,0,
608,11,138,4,0,
608,11,148,4,0,
608,11,156,4,0,
608,11,164,4,0,
608,11,168,4,0,
608,11,174,1,45,
608,11,182,4,0,
608,11,207,4,0,
608,11,213,4,0,
608,11,216,4,0,
608,11,218,4,0,
608,11,219,4,0,
608,11,220,1,61,
608,11,237,4,0,
608,11,241,4,0,
608,11,244,4,0,
608,11,247,1,53,
608,11,247,4,0,
608,11,261,1,16,
608,11,261,4,0,
608,11,262,1,33,
608,11,263,4,0,
608,11,269,4,0,
608,11,286,1,24,
608,11,310,1,1,2
608,11,315,1,69,
608,11,315,4,0,
608,11,347,4,0,
608,11,371,4,0,
608,11,373,4,0,
608,11,412,4,0,
608,11,433,4,0,
608,11,477,4,0,
608,11,481,1,20,
608,11,488,4,0,
608,11,496,4,0,
608,11,506,1,28,
608,11,510,4,0,
608,11,517,1,38,
608,14,52,1,1,1
608,14,53,4,0,
608,14,76,4,0,
608,14,83,1,7,
608,14,92,4,0,
608,14,94,4,0,
608,14,101,1,13,
608,14,104,4,0,
608,14,107,1,1,3
608,14,107,1,3,
608,14,109,1,10,
608,14,123,1,1,4
608,14,123,1,5,
608,14,126,4,0,
608,14,138,4,0,
608,14,148,4,0,
608,14,156,4,0,
608,14,164,4,0,
608,14,168,4,0,
608,14,173,3,0,
608,14,174,1,45,
608,14,180,3,0,
608,14,182,4,0,
608,14,207,4,0,
608,14,213,4,0,
608,14,214,3,0,
608,14,216,4,0,
608,14,218,4,0,
608,14,219,4,0,
608,14,220,1,61,
608,14,220,3,0,
608,14,237,4,0,
608,14,241,4,0,
608,14,244,4,0,
608,14,247,1,53,
608,14,247,4,0,
608,14,257,3,0,
608,14,261,1,16,
608,14,261,4,0,
608,14,262,1,33,
608,14,263,4,0,
608,14,269,4,0,
608,14,271,3,0,
608,14,286,1,24,
608,14,310,1,1,2
608,14,315,1,69,
608,14,315,4,0,
608,14,347,4,0,
608,14,371,4,0,
608,14,373,4,0,
608,14,399,3,0,
608,14,412,4,0,
608,14,433,4,0,
608,14,477,4,0,
608,14,481,1,20,
608,14,488,4,0,
608,14,496,4,0,
608,14,506,1,28,
608,14,510,4,0,
608,14,517,1,38,
608,15,52,1,1,1
608,15,53,4,0,
608,15,76,4,0,
608,15,83,1,7,
608,15,92,4,0,
608,15,94,4,0,
608,15,101,1,13,
608,15,104,4,0,
608,15,107,1,1,3
608,15,107,1,3,
608,15,109,1,10,
608,15,123,1,1,4
608,15,123,1,5,
608,15,126,4,0,
608,15,138,4,0,
608,15,148,4,0,
608,15,156,4,0,
608,15,164,4,0,
608,15,168,4,0,
608,15,174,1,45,
608,15,182,4,0,
608,15,207,4,0,
608,15,213,4,0,
608,15,214,4,0,
608,15,216,4,0,
608,15,218,4,0,
608,15,219,4,0,
608,15,220,1,61,
608,15,237,4,0,
608,15,241,4,0,
608,15,244,4,0,
608,15,247,1,53,
608,15,247,4,0,
608,15,261,1,16,
608,15,261,4,0,
608,15,262,1,33,
608,15,263,4,0,
608,15,269,4,0,
608,15,286,1,24,
608,15,310,1,1,2
608,15,315,1,69,
608,15,315,4,0,
608,15,347,4,0,
608,15,371,4,0,
608,15,373,4,0,
608,15,399,4,0,
608,15,412,4,0,
608,15,433,4,0,
608,15,481,1,20,
608,15,488,4,0,
608,15,496,4,0,
608,15,506,1,28,
608,15,510,4,0,
608,15,517,1,38,
608,15,590,4,0,
608,16,52,1,1,1
608,16,53,4,0,
608,16,76,4,0,
608,16,83,1,7,1
608,16,92,4,0,
608,16,94,4,0,
608,16,101,1,13,1
608,16,104,4,0,
608,16,107,1,1,3
608,16,107,1,3,1
608,16,109,1,10,1
608,16,123,1,1,4
608,16,123,1,5,1
608,16,126,4,0,
608,16,138,4,0,
608,16,148,4,0,
608,16,156,4,0,
608,16,164,4,0,
608,16,168,4,0,
608,16,173,3,0,
608,16,174,1,45,1
608,16,180,3,0,
608,16,182,4,0,
608,16,207,4,0,
608,16,213,4,0,
608,16,214,4,0,
608,16,216,4,0,
608,16,218,4,0,
608,16,219,4,0,
608,16,220,1,61,1
608,16,220,3,0,
608,16,237,4,0,
608,16,241,4,0,
608,16,244,4,0,
608,16,247,1,53,1
608,16,247,4,0,
608,16,257,3,0,
608,16,261,1,16,1
608,16,261,4,0,
608,16,262,1,33,1
608,16,263,4,0,
608,16,269,4,0,
608,16,271,3,0,
608,16,286,1,24,1
608,16,290,4,0,
608,16,310,1,1,2
608,16,315,1,69,1
608,16,315,4,0,
608,16,347,4,0,
608,16,351,3,0,
608,16,371,4,0,
608,16,373,4,0,
608,16,399,4,0,
608,16,412,4,0,
608,16,433,4,0,
608,16,481,1,20,1
608,16,488,4,0,
608,16,496,4,0,
608,16,506,1,28,1
608,16,510,4,0,
608,16,517,1,38,1
608,16,590,4,0,
608,17,52,1,1,1
608,17,53,4,0,
608,17,76,4,0,
608,17,83,1,7,
608,17,92,4,0,
608,17,94,4,0,
608,17,101,1,13,
608,17,104,4,0,
608,17,107,1,1,3
608,17,107,1,3,
608,17,109,1,10,
608,17,123,1,1,4
608,17,123,1,5,
608,17,126,4,0,
608,17,138,4,0,
608,17,156,4,0,
608,17,164,4,0,
608,17,168,4,0,
608,17,174,1,45,
608,17,182,4,0,
608,17,207,4,0,
608,17,213,4,0,
608,17,214,4,0,
608,17,216,4,0,
608,17,218,4,0,
608,17,219,4,0,
608,17,220,1,61,
608,17,237,4,0,
608,17,241,4,0,
608,17,244,4,0,
608,17,247,1,53,
608,17,247,4,0,
608,17,261,1,16,
608,17,261,4,0,
608,17,262,1,33,
608,17,263,4,0,
608,17,269,4,0,
608,17,286,1,24,
608,17,310,1,1,2
608,17,315,1,69,
608,17,315,4,0,
608,17,347,4,0,
608,17,371,4,0,
608,17,373,4,0,
608,17,399,4,0,
608,17,412,4,0,
608,17,433,4,0,
608,17,481,1,20,
608,17,488,4,0,
608,17,496,4,0,
608,17,506,1,28,
608,17,517,1,38,
608,17,590,4,0,
608,18,52,1,1,1
608,18,53,4,0,
608,18,76,4,0,
608,18,83,1,7,
608,18,92,4,0,
608,18,94,4,0,
608,18,101,1,13,
608,18,104,4,0,
608,18,107,1,1,3
608,18,107,1,3,
608,18,109,1,10,
608,18,123,1,1,4
608,18,123,1,5,
608,18,126,4,0,
608,18,138,4,0,
608,18,156,4,0,
608,18,164,4,0,
608,18,168,4,0,
608,18,174,1,45,
608,18,182,4,0,
608,18,207,4,0,
608,18,213,4,0,
608,18,214,4,0,
608,18,216,4,0,
608,18,218,4,0,
608,18,219,4,0,
608,18,220,1,61,
608,18,237,4,0,
608,18,241,4,0,
608,18,244,4,0,
608,18,247,1,53,
608,18,247,4,0,
608,18,261,1,16,
608,18,261,4,0,
608,18,262,1,33,
608,18,263,4,0,
608,18,269,4,0,
608,18,286,1,24,
608,18,310,1,1,2
608,18,315,1,69,
608,18,315,4,0,
608,18,347,4,0,
608,18,371,4,0,
608,18,373,4,0,
608,18,399,4,0,
608,18,412,4,0,
608,18,433,4,0,
608,18,481,1,20,
608,18,488,4,0,
608,18,496,4,0,
608,18,506,1,28,
608,18,517,1,38,
608,18,590,4,0,
609,11,53,4,0,
609,11,63,4,0,
609,11,76,4,0,
609,11,92,4,0,
609,11,94,4,0,
609,11,104,4,0,
609,11,109,1,1,2
609,11,123,1,1,1
609,11,126,4,0,
609,11,138,4,0,
609,11,148,4,0,
609,11,156,4,0,
609,11,164,4,0,
609,11,168,4,0,
609,11,182,4,0,
609,11,207,4,0,
609,11,213,4,0,
609,11,216,4,0,
609,11,218,4,0,
609,11,219,4,0,
609,11,237,4,0,
609,11,241,4,0,
609,11,244,4,0,
609,11,247,4,0,
609,11,261,4,0,
609,11,263,4,0,
609,11,269,4,0,
609,11,315,4,0,
609,11,347,4,0,
609,11,371,4,0,
609,11,373,4,0,
609,11,412,4,0,
609,11,416,4,0,
609,11,433,4,0,
609,11,477,4,0,
609,11,481,1,1,3
609,11,488,4,0,
609,11,496,4,0,
609,11,506,1,1,4
609,11,510,4,0,
609,14,53,4,0,
609,14,63,4,0,
609,14,76,4,0,
609,14,92,4,0,
609,14,94,4,0,
609,14,104,4,0,
609,14,109,1,1,2
609,14,123,1,1,1
609,14,126,4,0,
609,14,138,4,0,
609,14,148,4,0,
609,14,156,4,0,
609,14,164,4,0,
609,14,168,4,0,
609,14,173,3,0,
609,14,180,3,0,
609,14,182,4,0,
609,14,207,4,0,
609,14,213,4,0,
609,14,214,3,0,
609,14,216,4,0,
609,14,218,4,0,
609,14,219,4,0,
609,14,220,3,0,
609,14,237,4,0,
609,14,241,4,0,
609,14,244,4,0,
609,14,247,4,0,
609,14,257,3,0,
609,14,261,4,0,
609,14,263,4,0,
609,14,269,4,0,
609,14,271,3,0,
609,14,315,4,0,
609,14,347,4,0,
609,14,371,4,0,
609,14,373,4,0,
609,14,399,3,0,
609,14,412,4,0,
609,14,416,4,0,
609,14,433,4,0,
609,14,477,4,0,
609,14,481,1,1,3
609,14,488,4,0,
609,14,496,4,0,
609,14,506,1,1,4
609,14,510,4,0,
609,15,53,4,0,
609,15,63,4,0,
609,15,76,4,0,
609,15,92,4,0,
609,15,94,4,0,
609,15,104,4,0,
609,15,109,1,1,3
609,15,123,1,1,2
609,15,126,4,0,
609,15,138,4,0,
609,15,148,4,0,
609,15,156,4,0,
609,15,164,4,0,
609,15,168,4,0,
609,15,182,4,0,
609,15,207,4,0,
609,15,213,4,0,
609,15,214,4,0,
609,15,216,4,0,
609,15,218,4,0,
609,15,219,4,0,
609,15,220,1,1,1
609,15,237,4,0,
609,15,241,4,0,
609,15,244,4,0,
609,15,247,4,0,
609,15,261,4,0,
609,15,263,4,0,
609,15,269,4,0,
609,15,315,4,0,
609,15,347,4,0,
609,15,371,4,0,
609,15,373,4,0,
609,15,399,4,0,
609,15,412,4,0,
609,15,416,4,0,
609,15,433,4,0,
609,15,481,1,1,4
609,15,488,4,0,
609,15,496,4,0,
609,15,506,1,1,5
609,15,510,4,0,
609,15,590,4,0,
609,16,53,4,0,
609,16,63,4,0,
609,16,76,4,0,
609,16,92,4,0,
609,16,94,4,0,
609,16,104,4,0,
609,16,109,1,1,3
609,16,123,1,1,2
609,16,126,4,0,
609,16,138,4,0,
609,16,148,4,0,
609,16,156,4,0,
609,16,164,4,0,
609,16,168,4,0,
609,16,173,3,0,
609,16,180,3,0,
609,16,182,4,0,
609,16,207,4,0,
609,16,213,4,0,
609,16,214,4,0,
609,16,216,4,0,
609,16,218,4,0,
609,16,219,4,0,
609,16,220,1,1,1
609,16,220,3,0,
609,16,237,4,0,
609,16,241,4,0,
609,16,244,4,0,
609,16,247,4,0,
609,16,257,3,0,
609,16,261,4,0,
609,16,263,4,0,
609,16,269,4,0,
609,16,271,3,0,
609,16,290,4,0,
609,16,315,4,0,
609,16,347,4,0,
609,16,351,3,0,
609,16,371,4,0,
609,16,373,4,0,
609,16,399,4,0,
609,16,412,4,0,
609,16,416,4,0,
609,16,433,4,0,
609,16,481,1,1,4
609,16,488,4,0,
609,16,496,4,0,
609,16,506,1,1,5
609,16,510,4,0,
609,16,590,4,0,
609,17,53,4,0,
609,17,63,4,0,
609,17,76,4,0,
609,17,92,4,0,
609,17,94,4,0,
609,17,104,4,0,
609,17,109,1,1,3
609,17,123,1,1,2
609,17,126,4,0,
609,17,138,4,0,
609,17,156,4,0,
609,17,164,4,0,
609,17,168,4,0,
609,17,182,4,0,
609,17,207,4,0,
609,17,213,4,0,
609,17,214,4,0,
609,17,216,4,0,
609,17,218,4,0,
609,17,219,4,0,
609,17,220,1,1,1
609,17,237,4,0,
609,17,241,4,0,
609,17,244,4,0,
609,17,247,4,0,
609,17,261,4,0,
609,17,263,4,0,
609,17,269,4,0,
609,17,315,4,0,
609,17,347,4,0,
609,17,371,4,0,
609,17,373,4,0,
609,17,399,4,0,
609,17,412,4,0,
609,17,416,4,0,
609,17,433,4,0,
609,17,481,1,1,4
609,17,488,4,0,
609,17,496,4,0,
609,17,506,1,1,5
609,17,590,4,0,
609,18,53,4,0,
609,18,63,4,0,
609,18,76,4,0,
609,18,92,4,0,
609,18,94,4,0,
609,18,104,4,0,
609,18,109,1,1,3
609,18,123,1,1,2
609,18,126,4,0,
609,18,138,4,0,
609,18,156,4,0,
609,18,164,4,0,
609,18,168,4,0,
609,18,182,4,0,
609,18,207,4,0,
609,18,213,4,0,
609,18,214,4,0,
609,18,216,4,0,
609,18,218,4,0,
609,18,219,4,0,
609,18,220,1,1,1
609,18,237,4,0,
609,18,241,4,0,
609,18,244,4,0,
609,18,247,4,0,
609,18,261,4,0,
609,18,263,4,0,
609,18,269,4,0,
609,18,315,4,0,
609,18,347,4,0,
609,18,371,4,0,
609,18,373,4,0,
609,18,399,4,0,
609,18,412,4,0,
609,18,416,4,0,
609,18,433,4,0,
609,18,481,1,1,4
609,18,488,4,0,
609,18,496,4,0,
609,18,506,1,1,5
609,18,590,4,0,
610,11,10,1,1,
610,11,12,1,51,
610,11,13,2,0,
610,11,14,1,46,
610,11,14,4,0,
610,11,15,4,0,
610,11,43,1,4,
610,11,46,4,0,
610,11,68,2,0,
610,11,70,4,0,
610,11,82,1,10,
610,11,91,4,0,
610,11,92,4,0,
610,11,104,4,0,
610,11,106,2,0,
610,11,116,2,0,
610,11,156,4,0,
610,11,163,1,20,
610,11,164,4,0,
610,11,179,2,0,
610,11,182,4,0,
610,11,184,1,16,
610,11,200,1,56,
610,11,203,2,0,
610,11,206,1,24,
610,11,206,4,0,
610,11,207,4,0,
610,11,213,4,0,
610,11,216,4,0,
610,11,218,4,0,
610,11,231,2,0,
610,11,237,4,0,
610,11,240,4,0,
610,11,241,4,0,
610,11,249,4,0,
610,11,263,4,0,
610,11,269,1,36,
610,11,269,4,0,
610,11,283,2,0,
610,11,317,4,0,
610,11,332,4,0,
610,11,337,1,28,
610,11,337,4,0,
610,11,349,1,32,
610,11,371,4,0,
610,11,372,1,7,
610,11,374,4,0,
610,11,398,4,0,
610,11,400,2,0,
610,11,404,4,0,
610,11,406,1,41,
610,11,406,2,0,
610,11,416,1,61,
610,11,416,4,0,
610,11,434,3,0,
610,11,468,4,0,
610,11,496,4,0,
610,11,510,4,0,
610,11,530,1,13,
610,14,10,1,1,
610,14,12,1,51,
610,14,13,2,0,
610,14,14,1,46,
610,14,14,4,0,
610,14,15,4,0,
610,14,43,1,4,
610,14,46,4,0,
610,14,68,2,0,
610,14,70,4,0,
610,14,82,1,10,
610,14,91,4,0,
610,14,92,4,0,
610,14,104,4,0,
610,14,106,2,0,
610,14,116,2,0,
610,14,156,4,0,
610,14,163,1,20,
610,14,164,4,0,
610,14,173,3,0,
610,14,179,2,0,
610,14,182,4,0,
610,14,184,1,16,
610,14,200,1,56,
610,14,200,3,0,
610,14,203,2,0,
610,14,206,1,24,
610,14,206,4,0,
610,14,207,4,0,
610,14,213,4,0,
610,14,214,3,0,
610,14,216,4,0,
610,14,218,4,0,
610,14,231,2,0,
610,14,231,3,0,
610,14,237,4,0,
610,14,240,4,0,
610,14,241,4,0,
610,14,249,4,0,
610,14,263,4,0,
610,14,269,1,36,
610,14,269,4,0,
610,14,276,3,0,
610,14,283,2,0,
610,14,283,3,0,
610,14,317,4,0,
610,14,332,4,0,
610,14,337,1,28,
610,14,337,4,0,
610,14,349,1,32,
610,14,371,4,0,
610,14,372,1,7,
610,14,374,4,0,
610,14,398,4,0,
610,14,400,2,0,
610,14,401,3,0,
610,14,404,4,0,
610,14,406,1,41,
610,14,406,2,0,
610,14,406,3,0,
610,14,416,1,61,
610,14,416,4,0,
610,14,434,3,0,
610,14,468,4,0,
610,14,496,4,0,
610,14,510,4,0,
610,14,530,1,13,
610,14,530,3,0,
610,15,10,1,1,
610,15,12,1,50,
610,15,13,2,0,
610,15,14,1,46,
610,15,14,4,0,
610,15,15,4,0,
610,15,43,1,4,
610,15,46,4,0,
610,15,68,2,0,
610,15,70,4,0,
610,15,82,1,10,
610,15,91,4,0,
610,15,92,4,0,
610,15,104,4,0,
610,15,106,2,0,
610,15,116,2,0,
610,15,156,4,0,
610,15,163,1,20,
610,15,164,4,0,
610,15,179,2,0,
610,15,182,4,0,
610,15,184,1,16,
610,15,200,1,56,
610,15,203,2,0,
610,15,206,1,24,
610,15,206,4,0,
610,15,207,4,0,
610,15,213,4,0,
610,15,214,4,0,
610,15,216,4,0,
610,15,218,4,0,
610,15,231,2,0,
610,15,237,4,0,
610,15,240,4,0,
610,15,241,4,0,
610,15,249,4,0,
610,15,263,4,0,
610,15,269,1,36,
610,15,269,4,0,
610,15,283,2,0,
610,15,317,4,0,
610,15,332,4,0,
610,15,337,1,28,
610,15,337,4,0,
610,15,349,1,32,
610,15,371,4,0,
610,15,372,1,7,
610,15,374,4,0,
610,15,398,4,0,
610,15,400,2,0,
610,15,404,4,0,
610,15,406,1,41,
610,15,406,2,0,
610,15,416,1,61,
610,15,416,4,0,
610,15,434,3,0,
610,15,468,4,0,
610,15,496,4,0,
610,15,510,4,0,
610,15,530,1,13,
610,15,590,4,0,
610,16,10,1,1,1
610,16,12,1,50,1
610,16,13,2,0,
610,16,14,1,46,1
610,16,14,4,0,
610,16,15,4,0,
610,16,43,1,4,1
610,16,46,4,0,
610,16,68,2,0,
610,16,70,4,0,
610,16,82,1,10,1
610,16,91,4,0,
610,16,92,4,0,
610,16,104,4,0,
610,16,106,2,0,
610,16,116,2,0,
610,16,156,4,0,
610,16,163,1,20,1
610,16,164,4,0,
610,16,173,3,0,
610,16,179,2,0,
610,16,182,4,0,
610,16,184,1,16,1
610,16,200,1,56,1
610,16,200,3,0,
610,16,203,2,0,
610,16,206,1,24,1
610,16,206,4,0,
610,16,207,4,0,
610,16,213,4,0,
610,16,214,4,0,
610,16,216,4,0,
610,16,218,4,0,
610,16,231,2,0,
610,16,231,3,0,
610,16,237,4,0,
610,16,240,4,0,
610,16,241,4,0,
610,16,249,4,0,
610,16,263,4,0,
610,16,269,1,36,1
610,16,269,4,0,
610,16,276,3,0,
610,16,283,2,0,
610,16,283,3,0,
610,16,290,4,0,
610,16,317,4,0,
610,16,332,4,0,
610,16,337,1,28,1
610,16,337,4,0,
610,16,349,1,32,1
610,16,351,3,0,
610,16,371,4,0,
610,16,372,1,7,1
610,16,374,4,0,
610,16,398,4,0,
610,16,400,2,0,
610,16,401,3,0,
610,16,404,4,0,
610,16,406,1,41,1
610,16,406,2,0,
610,16,406,3,0,
610,16,416,1,61,1
610,16,416,4,0,
610,16,434,3,0,
610,16,468,4,0,
610,16,496,4,0,
610,16,510,4,0,
610,16,530,1,13,1
610,16,530,3,0,
610,16,590,4,0,
610,17,10,1,1,
610,17,12,1,50,
610,17,13,2,0,
610,17,14,1,46,
610,17,14,4,0,
610,17,43,1,4,
610,17,46,4,0,
610,17,68,2,0,
610,17,82,1,10,
610,17,92,4,0,
610,17,104,4,0,
610,17,106,2,0,
610,17,116,2,0,
610,17,156,4,0,
610,17,163,1,20,
610,17,164,4,0,
610,17,179,2,0,
610,17,182,4,0,
610,17,184,1,16,
610,17,200,1,56,
610,17,203,2,0,
610,17,206,1,24,
610,17,206,4,0,
610,17,207,4,0,
610,17,213,4,0,
610,17,214,4,0,
610,17,216,4,0,
610,17,218,4,0,
610,17,231,2,0,
610,17,237,4,0,
610,17,240,4,0,
610,17,241,4,0,
610,17,263,4,0,
610,17,269,1,36,
610,17,269,4,0,
610,17,283,2,0,
610,17,317,4,0,
610,17,332,4,0,
610,17,337,1,28,
610,17,337,4,0,
610,17,349,1,32,
610,17,371,4,0,
610,17,372,1,7,
610,17,374,4,0,
610,17,398,4,0,
610,17,400,2,0,
610,17,404,4,0,
610,17,406,1,41,
610,17,406,2,0,
610,17,416,1,61,
610,17,416,4,0,
610,17,434,3,0,
610,17,496,4,0,
610,17,530,1,13,
610,17,590,4,0,
610,18,10,1,1,
610,18,12,1,50,
610,18,13,2,0,
610,18,14,1,46,
610,18,14,4,0,
610,18,43,1,4,
610,18,46,4,0,
610,18,68,2,0,
610,18,82,1,10,
610,18,92,4,0,
610,18,104,4,0,
610,18,106,2,0,
610,18,116,2,0,
610,18,156,4,0,
610,18,163,1,20,
610,18,164,4,0,
610,18,179,2,0,
610,18,182,4,0,
610,18,184,1,16,
610,18,200,1,56,
610,18,203,2,0,
610,18,206,1,24,
610,18,206,4,0,
610,18,207,4,0,
610,18,213,4,0,
610,18,214,4,0,
610,18,216,4,0,
610,18,218,4,0,
610,18,231,2,0,
610,18,237,4,0,
610,18,240,4,0,
610,18,241,4,0,
610,18,263,4,0,
610,18,269,1,36,
610,18,269,4,0,
610,18,283,2,0,
610,18,317,4,0,
610,18,332,4,0,
610,18,337,1,28,
610,18,337,4,0,
610,18,349,1,32,
610,18,371,4,0,
610,18,372,1,7,
610,18,374,4,0,
610,18,398,4,0,
610,18,400,2,0,
610,18,404,4,0,
610,18,406,1,41,
610,18,406,2,0,
610,18,416,1,61,
610,18,416,4,0,
610,18,434,3,0,
610,18,496,4,0,
610,18,530,1,13,
610,18,590,4,0,
611,11,10,1,1,1
611,11,12,1,54,
611,11,14,1,48,
611,11,14,4,0,
611,11,15,4,0,
611,11,43,1,1,2
611,11,43,1,4,
611,11,46,4,0,
611,11,70,4,0,
611,11,82,1,1,4
611,11,82,1,10,
611,11,91,4,0,
611,11,92,4,0,
611,11,104,4,0,
611,11,156,4,0,
611,11,163,1,20,
611,11,164,4,0,
611,11,182,4,0,
611,11,184,1,16,
611,11,200,1,60,
611,11,206,1,24,
611,11,206,4,0,
611,11,207,4,0,
611,11,213,4,0,
611,11,216,4,0,
611,11,218,4,0,
611,11,237,4,0,
611,11,240,4,0,
611,11,241,4,0,
611,11,249,4,0,
611,11,263,4,0,
611,11,269,1,36,
611,11,269,4,0,
611,11,317,4,0,
611,11,332,4,0,
611,11,337,1,28,
611,11,337,4,0,
611,11,349,1,32,
611,11,371,4,0,
611,11,372,1,1,3
611,11,372,1,7,
611,11,374,4,0,
611,11,398,4,0,
611,11,404,4,0,
611,11,406,1,42,
611,11,416,1,66,
611,11,416,4,0,
611,11,421,4,0,
611,11,434,3,0,
611,11,468,4,0,
611,11,496,4,0,
611,11,510,4,0,
611,11,525,4,0,
611,11,530,1,13,
611,14,10,1,1,1
611,14,12,1,54,
611,14,14,1,48,
611,14,14,4,0,
611,14,15,4,0,
611,14,43,1,1,2
611,14,43,1,4,
611,14,46,4,0,
611,14,67,3,0,
611,14,70,4,0,
611,14,82,1,1,4
611,14,82,1,10,
611,14,91,4,0,
611,14,92,4,0,
611,14,104,4,0,
611,14,156,4,0,
611,14,163,1,20,
611,14,164,4,0,
611,14,173,3,0,
611,14,182,4,0,
611,14,184,1,16,
611,14,200,1,60,
611,14,200,3,0,
611,14,206,1,24,
611,14,206,4,0,
611,14,207,4,0,
611,14,213,4,0,
611,14,214,3,0,
611,14,216,4,0,
611,14,218,4,0,
611,14,231,3,0,
611,14,237,4,0,
611,14,240,4,0,
611,14,241,4,0,
611,14,249,4,0,
611,14,263,4,0,
611,14,269,1,36,
611,14,269,4,0,
611,14,276,3,0,
611,14,283,3,0,
611,14,317,4,0,
611,14,332,4,0,
611,14,337,1,28,
611,14,337,4,0,
611,14,349,1,32,
611,14,371,4,0,
611,14,372,1,1,3
611,14,372,1,7,
611,14,374,4,0,
611,14,398,4,0,
611,14,401,3,0,
611,14,404,4,0,
611,14,406,1,42,
611,14,406,3,0,
611,14,416,1,66,
611,14,416,4,0,
611,14,421,4,0,
611,14,434,3,0,
611,14,468,4,0,
611,14,496,4,0,
611,14,510,4,0,
611,14,525,4,0,
611,14,530,1,13,
611,14,530,3,0,
611,15,10,1,1,1
611,15,12,1,54,
611,15,14,1,48,
611,15,14,4,0,
611,15,15,4,0,
611,15,43,1,1,2
611,15,43,1,4,
611,15,46,4,0,
611,15,70,4,0,
611,15,82,1,1,4
611,15,82,1,10,
611,15,91,4,0,
611,15,92,4,0,
611,15,104,4,0,
611,15,156,4,0,
611,15,163,1,20,
611,15,164,4,0,
611,15,182,4,0,
611,15,184,1,16,
611,15,200,1,60,
611,15,206,1,24,
611,15,206,4,0,
611,15,207,4,0,
611,15,213,4,0,
611,15,214,4,0,
611,15,216,4,0,
611,15,218,4,0,
611,15,237,4,0,
611,15,240,4,0,
611,15,241,4,0,
611,15,249,4,0,
611,15,263,4,0,
611,15,269,1,36,
611,15,269,4,0,
611,15,317,4,0,
611,15,332,4,0,
611,15,337,1,28,
611,15,337,4,0,
611,15,349,1,32,
611,15,371,4,0,
611,15,372,1,1,3
611,15,372,1,7,
611,15,374,4,0,
611,15,398,4,0,
611,15,404,4,0,
611,15,406,1,42,
611,15,416,1,66,
611,15,416,4,0,
611,15,421,4,0,
611,15,434,3,0,
611,15,468,4,0,
611,15,496,4,0,
611,15,510,4,0,
611,15,525,4,0,
611,15,530,1,13,
611,15,590,4,0,
611,16,10,1,1,1
611,16,12,1,54,1
611,16,14,1,48,1
611,16,14,4,0,
611,16,15,4,0,
611,16,43,1,1,2
611,16,43,1,4,1
611,16,46,4,0,
611,16,67,3,0,
611,16,70,4,0,
611,16,82,1,1,4
611,16,82,1,10,1
611,16,91,4,0,
611,16,92,4,0,
611,16,104,4,0,
611,16,156,4,0,
611,16,163,1,20,1
611,16,164,4,0,
611,16,173,3,0,
611,16,182,4,0,
611,16,184,1,16,1
611,16,200,1,60,1
611,16,200,3,0,
611,16,206,1,24,1
611,16,206,4,0,
611,16,207,4,0,
611,16,213,4,0,
611,16,214,4,0,
611,16,216,4,0,
611,16,218,4,0,
611,16,231,3,0,
611,16,237,4,0,
611,16,240,4,0,
611,16,241,4,0,
611,16,249,4,0,
611,16,263,4,0,
611,16,269,1,36,1
611,16,269,4,0,
611,16,276,3,0,
611,16,283,3,0,
611,16,290,4,0,
611,16,317,4,0,
611,16,332,4,0,
611,16,337,1,28,1
611,16,337,4,0,
611,16,349,1,32,1
611,16,351,3,0,
611,16,371,4,0,
611,16,372,1,1,3
611,16,372,1,7,1
611,16,374,4,0,
611,16,398,4,0,
611,16,401,3,0,
611,16,404,4,0,
611,16,406,1,42,1
611,16,406,3,0,
611,16,416,1,66,1
611,16,416,4,0,
611,16,421,4,0,
611,16,434,3,0,
611,16,468,4,0,
611,16,496,4,0,
611,16,510,4,0,
611,16,525,4,0,
611,16,530,1,13,1
611,16,530,3,0,
611,16,590,4,0,
611,17,10,1,1,1
611,17,12,1,54,
611,17,14,1,48,
611,17,14,4,0,
611,17,43,1,1,2
611,17,43,1,4,
611,17,46,4,0,
611,17,82,1,1,4
611,17,82,1,10,
611,17,92,4,0,
611,17,104,4,0,
611,17,156,4,0,
611,17,163,1,20,
611,17,164,4,0,
611,17,182,4,0,
611,17,184,1,16,
611,17,200,1,60,
611,17,206,1,24,
611,17,206,4,0,
611,17,207,4,0,
611,17,213,4,0,
611,17,214,4,0,
611,17,216,4,0,
611,17,218,4,0,
611,17,237,4,0,
611,17,240,4,0,
611,17,241,4,0,
611,17,263,4,0,
611,17,269,1,36,
611,17,269,4,0,
611,17,317,4,0,
611,17,332,4,0,
611,17,337,1,28,
611,17,337,4,0,
611,17,349,1,32,
611,17,371,4,0,
611,17,372,1,1,3
611,17,372,1,7,
611,17,374,4,0,
611,17,398,4,0,
611,17,404,4,0,
611,17,406,1,42,
611,17,416,1,66,
611,17,416,4,0,
611,17,421,4,0,
611,17,434,3,0,
611,17,496,4,0,
611,17,525,4,0,
611,17,530,1,13,
611,17,590,4,0,
611,18,10,1,1,1
611,18,12,1,54,
611,18,14,1,48,
611,18,14,4,0,
611,18,43,1,1,2
611,18,43,1,4,
611,18,46,4,0,
611,18,82,1,1,4
611,18,82,1,10,
611,18,92,4,0,
611,18,104,4,0,
611,18,156,4,0,
611,18,163,1,20,
611,18,164,4,0,
611,18,182,4,0,
611,18,184,1,16,
611,18,200,1,60,
611,18,206,1,24,
611,18,206,4,0,
611,18,207,4,0,
611,18,213,4,0,
611,18,214,4,0,
611,18,216,4,0,
611,18,218,4,0,
611,18,237,4,0,
611,18,240,4,0,
611,18,241,4,0,
611,18,263,4,0,
611,18,269,1,36,
611,18,269,4,0,
611,18,317,4,0,
611,18,332,4,0,
611,18,337,1,28,
611,18,337,4,0,
611,18,349,1,32,
611,18,371,4,0,
611,18,372,1,1,3
611,18,372,1,7,
611,18,374,4,0,
611,18,398,4,0,
611,18,404,4,0,
611,18,406,1,42,
611,18,416,1,66,
611,18,416,4,0,
611,18,421,4,0,
611,18,434,3,0,
611,18,496,4,0,
611,18,525,4,0,
611,18,530,1,13,
611,18,590,4,0,
612,11,10,1,1,1
612,11,12,1,58,
612,11,14,1,50,
612,11,14,4,0,
612,11,15,4,0,
612,11,43,1,1,2
612,11,43,1,4,
612,11,46,4,0,
612,11,57,4,0,
612,11,63,4,0,
612,11,70,4,0,
612,11,82,1,1,4
612,11,82,1,10,
612,11,89,4,0,
612,11,91,4,0,
612,11,92,4,0,
612,11,104,4,0,
612,11,156,4,0,
612,11,157,4,0,
612,11,163,1,20,
612,11,164,4,0,
612,11,182,4,0,
612,11,184,1,16,
612,11,200,1,66,
612,11,206,1,24,
612,11,206,4,0,
612,11,207,4,0,
612,11,213,4,0,
612,11,216,4,0,
612,11,218,4,0,
612,11,237,4,0,
612,11,240,4,0,
612,11,241,4,0,
612,11,249,4,0,
612,11,263,4,0,
612,11,269,1,36,
612,11,269,4,0,
612,11,280,4,0,
612,11,317,4,0,
612,11,332,4,0,
612,11,337,1,28,
612,11,337,4,0,
612,11,349,1,32,
612,11,371,4,0,
612,11,372,1,1,3
612,11,372,1,7,
612,11,374,4,0,
612,11,398,4,0,
612,11,404,4,0,
612,11,406,1,42,
612,11,411,4,0,
612,11,416,1,74,
612,11,416,4,0,
612,11,421,4,0,
612,11,434,3,0,
612,11,447,4,0,
612,11,468,4,0,
612,11,496,4,0,
612,11,510,4,0,
612,11,523,4,0,
612,11,525,4,0,
612,11,530,1,13,
612,14,10,1,1,1
612,14,12,1,58,
612,14,14,1,50,
612,14,14,4,0,
612,14,15,4,0,
612,14,43,1,1,2
612,14,43,1,4,
612,14,46,4,0,
612,14,57,4,0,
612,14,63,4,0,
612,14,67,3,0,
612,14,70,4,0,
612,14,82,1,1,4
612,14,82,1,10,
612,14,89,4,0,
612,14,91,4,0,
612,14,92,4,0,
612,14,104,4,0,
612,14,156,4,0,
612,14,157,4,0,
612,14,163,1,20,
612,14,164,4,0,
612,14,173,3,0,
612,14,182,4,0,
612,14,184,1,16,
612,14,200,1,66,
612,14,200,3,0,
612,14,206,1,24,
612,14,206,4,0,
612,14,207,4,0,
612,14,213,4,0,
612,14,214,3,0,
612,14,216,4,0,
612,14,218,4,0,
612,14,231,3,0,
612,14,237,4,0,
612,14,240,4,0,
612,14,241,4,0,
612,14,249,4,0,
612,14,263,4,0,
612,14,269,1,36,
612,14,269,4,0,
612,14,276,3,0,
612,14,280,4,0,
612,14,283,3,0,
612,14,317,4,0,
612,14,332,4,0,
612,14,337,1,28,
612,14,337,4,0,
612,14,349,1,32,
612,14,371,4,0,
612,14,372,1,1,3
612,14,372,1,7,
612,14,374,4,0,
612,14,398,4,0,
612,14,401,3,0,
612,14,404,4,0,
612,14,406,1,42,
612,14,406,3,0,
612,14,411,4,0,
612,14,416,1,74,
612,14,416,4,0,
612,14,421,4,0,
612,14,434,3,0,
612,14,447,4,0,
612,14,468,4,0,
612,14,496,4,0,
612,14,510,4,0,
612,14,523,4,0,
612,14,525,4,0,
612,14,530,1,13,
612,14,530,3,0,
612,15,10,1,1,2
612,15,12,1,58,
612,15,14,1,50,
612,15,14,4,0,
612,15,15,4,0,
612,15,43,1,1,3
612,15,43,1,4,
612,15,46,4,0,
612,15,57,4,0,
612,15,63,4,0,
612,15,70,4,0,
612,15,82,1,1,5
612,15,82,1,10,
612,15,89,4,0,
612,15,91,4,0,
612,15,92,4,0,
612,15,104,4,0,
612,15,156,4,0,
612,15,157,4,0,
612,15,163,1,20,
612,15,164,4,0,
612,15,182,4,0,
612,15,184,1,16,
612,15,200,1,1,1
612,15,200,1,66,
612,15,206,1,24,
612,15,206,4,0,
612,15,207,4,0,
612,15,213,4,0,
612,15,214,4,0,
612,15,216,4,0,
612,15,218,4,0,
612,15,237,4,0,
612,15,240,4,0,
612,15,241,4,0,
612,15,249,4,0,
612,15,263,4,0,
612,15,269,1,36,
612,15,269,4,0,
612,15,280,4,0,
612,15,317,4,0,
612,15,332,4,0,
612,15,337,1,28,
612,15,337,4,0,
612,15,349,1,32,
612,15,371,4,0,
612,15,372,1,1,4
612,15,372,1,7,
612,15,374,4,0,
612,15,398,4,0,
612,15,404,4,0,
612,15,406,1,42,
612,15,411,4,0,
612,15,416,1,74,
612,15,416,4,0,
612,15,421,4,0,
612,15,434,3,0,
612,15,447,4,0,
612,15,468,4,0,
612,15,496,4,0,
612,15,510,4,0,
612,15,523,4,0,
612,15,525,4,0,
612,15,530,1,13,
612,15,590,4,0,
612,16,10,1,1,2
612,16,12,1,58,1
612,16,14,1,50,1
612,16,14,4,0,
612,16,15,4,0,
612,16,43,1,1,3
612,16,43,1,4,1
612,16,46,4,0,
612,16,57,4,0,
612,16,63,4,0,
612,16,67,3,0,
612,16,70,4,0,
612,16,82,1,1,5
612,16,82,1,10,1
612,16,89,4,0,
612,16,91,4,0,
612,16,92,4,0,
612,16,104,4,0,
612,16,156,4,0,
612,16,157,4,0,
612,16,163,1,20,1
612,16,164,4,0,
612,16,173,3,0,
612,16,182,4,0,
612,16,184,1,16,1
612,16,200,1,1,1
612,16,200,1,66,1
612,16,200,3,0,
612,16,206,1,24,1
612,16,206,4,0,
612,16,207,4,0,
612,16,213,4,0,
612,16,214,4,0,
612,16,216,4,0,
612,16,218,4,0,
612,16,231,3,0,
612,16,237,4,0,
612,16,240,4,0,
612,16,241,4,0,
612,16,249,4,0,
612,16,263,4,0,
612,16,269,1,36,1
612,16,269,4,0,
612,16,276,3,0,
612,16,280,4,0,
612,16,283,3,0,
612,16,290,4,0,
612,16,317,4,0,
612,16,332,4,0,
612,16,337,1,28,1
612,16,337,4,0,
612,16,349,1,32,1
612,16,351,3,0,
612,16,371,4,0,
612,16,372,1,1,4
612,16,372,1,7,1
612,16,374,4,0,
612,16,398,4,0,
612,16,401,3,0,
612,16,404,4,0,
612,16,406,1,42,1
612,16,406,3,0,
612,16,411,4,0,
612,16,416,1,74,1
612,16,416,4,0,
612,16,421,4,0,
612,16,434,3,0,
612,16,447,4,0,
612,16,468,4,0,
612,16,496,4,0,
612,16,510,4,0,
612,16,523,4,0,
612,16,525,4,0,
612,16,530,1,13,1
612,16,530,3,0,
612,16,590,4,0,
612,17,10,1,1,2
612,17,12,1,58,
612,17,14,1,50,
612,17,14,4,0,
612,17,43,1,1,3
612,17,43,1,4,
612,17,46,4,0,
612,17,57,4,0,
612,17,63,4,0,
612,17,82,1,1,5
612,17,82,1,10,
612,17,89,4,0,
612,17,92,4,0,
612,17,104,4,0,
612,17,156,4,0,
612,17,157,4,0,
612,17,163,1,20,
612,17,164,4,0,
612,17,182,4,0,
612,17,184,1,16,
612,17,200,1,1,1
612,17,200,1,66,
612,17,206,1,24,
612,17,206,4,0,
612,17,207,4,0,
612,17,213,4,0,
612,17,214,4,0,
612,17,216,4,0,
612,17,218,4,0,
612,17,237,4,0,
612,17,240,4,0,
612,17,241,4,0,
612,17,263,4,0,
612,17,269,1,36,
612,17,269,4,0,
612,17,280,4,0,
612,17,317,4,0,
612,17,332,4,0,
612,17,337,1,28,
612,17,337,4,0,
612,17,349,1,32,
612,17,371,4,0,
612,17,372,1,1,4
612,17,372,1,7,
612,17,374,4,0,
612,17,398,4,0,
612,17,404,4,0,
612,17,406,1,42,
612,17,411,4,0,
612,17,416,1,74,
612,17,416,4,0,
612,17,421,4,0,
612,17,434,3,0,
612,17,447,4,0,
612,17,496,4,0,
612,17,523,4,0,
612,17,525,4,0,
612,17,530,1,13,
612,17,590,4,0,
612,17,693,4,0,
612,18,10,1,1,2
612,18,12,1,58,
612,18,14,1,50,
612,18,14,4,0,
612,18,43,1,1,3
612,18,43,1,4,
612,18,46,4,0,
612,18,57,4,0,
612,18,63,4,0,
612,18,82,1,1,5
612,18,82,1,10,
612,18,89,4,0,
612,18,92,4,0,
612,18,104,4,0,
612,18,156,4,0,
612,18,157,4,0,
612,18,163,1,20,
612,18,164,4,0,
612,18,182,4,0,
612,18,184,1,16,
612,18,200,1,1,1
612,18,200,1,66,
612,18,206,1,24,
612,18,206,4,0,
612,18,207,4,0,
612,18,213,4,0,
612,18,214,4,0,
612,18,216,4,0,
612,18,218,4,0,
612,18,237,4,0,
612,18,240,4,0,
612,18,241,4,0,
612,18,263,4,0,
612,18,269,1,36,
612,18,269,4,0,
612,18,280,4,0,
612,18,317,4,0,
612,18,332,4,0,
612,18,337,1,28,
612,18,337,4,0,
612,18,349,1,32,
612,18,371,4,0,
612,18,372,1,1,4
612,18,372,1,7,
612,18,374,4,0,
612,18,398,4,0,
612,18,404,4,0,
612,18,406,1,42,
612,18,411,4,0,
612,18,416,1,74,
612,18,416,4,0,
612,18,421,4,0,
612,18,434,3,0,
612,18,447,4,0,
612,18,496,4,0,
612,18,523,4,0,
612,18,525,4,0,
612,18,530,1,13,
612,18,590,4,0,
612,18,693,4,0,
613,11,8,2,0,
613,11,15,4,0,
613,11,37,1,53,
613,11,45,1,5,
613,11,57,4,0,
613,11,58,4,0,
613,11,59,1,45,
613,11,59,4,0,
613,11,70,4,0,
613,11,91,4,0,
613,11,92,4,0,
613,11,104,4,0,
613,11,117,1,9,
613,11,154,1,17,
613,11,156,1,41,
613,11,156,4,0,
613,11,163,1,33,
613,11,164,4,0,
613,11,175,1,36,
613,11,181,1,1,
613,11,182,4,0,
613,11,196,1,13,
613,11,203,1,25,
613,11,204,1,29,
613,11,207,4,0,
613,11,213,4,0,
613,11,214,2,0,
613,11,216,4,0,
613,11,218,4,0,
613,11,227,2,0,
613,11,237,4,0,
613,11,240,4,0,
613,11,249,4,0,
613,11,258,1,49,
613,11,258,4,0,
613,11,263,4,0,
613,11,264,2,0,
613,11,281,2,0,
613,11,317,4,0,
613,11,329,1,57,
613,11,332,4,0,
613,11,362,1,21,
613,11,372,2,0,
613,11,374,4,0,
613,11,400,2,0,
613,11,419,2,0,
613,11,421,4,0,
613,11,447,4,0,
613,11,468,4,0,
613,11,496,4,0,
613,11,497,4,0,
613,11,524,4,0,
613,14,8,2,0,
613,14,8,3,0,
613,14,15,4,0,
613,14,37,1,53,
613,14,45,1,5,
613,14,57,4,0,
613,14,58,4,0,
613,14,59,1,45,
613,14,59,4,0,
613,14,67,3,0,
613,14,70,4,0,
613,14,91,4,0,
613,14,92,4,0,
613,14,104,4,0,
613,14,117,1,9,
613,14,154,1,17,
613,14,156,1,41,
613,14,156,4,0,
613,14,163,1,33,
613,14,164,4,0,
613,14,173,3,0,
613,14,175,1,36,
613,14,181,1,1,
613,14,182,4,0,
613,14,196,1,13,
613,14,196,3,0,
613,14,203,1,25,
613,14,204,1,29,
613,14,207,4,0,
613,14,213,4,0,
613,14,214,2,0,
613,14,214,3,0,
613,14,216,4,0,
613,14,218,4,0,
613,14,227,2,0,
613,14,237,4,0,
613,14,240,4,0,
613,14,249,4,0,
613,14,258,1,49,
613,14,258,4,0,
613,14,263,4,0,
613,14,264,2,0,
613,14,276,3,0,
613,14,281,2,0,
613,14,317,4,0,
613,14,329,1,57,
613,14,332,4,0,
613,14,343,3,0,
613,14,362,1,21,
613,14,372,2,0,
613,14,374,4,0,
613,14,400,2,0,
613,14,419,2,0,
613,14,421,4,0,
613,14,447,4,0,
613,14,468,4,0,
613,14,496,4,0,
613,14,497,4,0,
613,14,524,4,0,
613,15,8,2,0,
613,15,15,4,0,
613,15,37,1,53,
613,15,45,1,1,
613,15,57,4,0,
613,15,58,4,0,
613,15,59,1,45,
613,15,59,4,0,
613,15,70,4,0,
613,15,91,4,0,
613,15,92,4,0,
613,15,104,4,0,
613,15,117,1,9,
613,15,154,1,17,
613,15,156,1,41,
613,15,156,4,0,
613,15,163,1,33,
613,15,164,4,0,
613,15,175,1,36,
613,15,181,1,5,
613,15,182,4,0,
613,15,196,1,13,
613,15,203,1,25,
613,15,204,1,29,
613,15,207,4,0,
613,15,213,4,0,
613,15,214,2,0,
613,15,214,4,0,
613,15,216,4,0,
613,15,218,4,0,
613,15,227,2,0,
613,15,237,4,0,
613,15,240,4,0,
613,15,249,4,0,
613,15,258,1,49,
613,15,258,4,0,
613,15,263,4,0,
613,15,264,2,0,
613,15,281,2,0,
613,15,317,4,0,
613,15,329,1,57,
613,15,332,4,0,
613,15,362,1,21,
613,15,372,2,0,
613,15,374,4,0,
613,15,400,2,0,
613,15,419,2,0,
613,15,421,4,0,
613,15,447,4,0,
613,15,468,4,0,
613,15,496,4,0,
613,15,497,4,0,
613,15,524,4,0,
613,15,583,2,0,
613,15,589,1,15,
613,15,590,4,0,
613,15,612,4,0,
613,16,8,2,0,
613,16,8,3,0,
613,16,15,4,0,
613,16,37,1,53,1
613,16,45,1,1,1
613,16,57,4,0,
613,16,58,4,0,
613,16,59,1,45,1
613,16,59,4,0,
613,16,67,3,0,
613,16,70,4,0,
613,16,91,4,0,
613,16,92,4,0,
613,16,104,4,0,
613,16,117,1,9,1
613,16,154,1,17,1
613,16,156,1,41,1
613,16,156,4,0,
613,16,163,1,33,1
613,16,164,4,0,
613,16,173,3,0,
613,16,175,1,36,1
613,16,181,1,5,1
613,16,182,4,0,
613,16,196,1,13,1
613,16,196,3,0,
613,16,203,1,25,1
613,16,204,1,29,1
613,16,207,4,0,
613,16,213,4,0,
613,16,214,2,0,
613,16,214,4,0,
613,16,216,4,0,
613,16,218,4,0,
613,16,227,2,0,
613,16,237,4,0,
613,16,240,4,0,
613,16,249,4,0,
613,16,258,1,49,1
613,16,258,4,0,
613,16,263,4,0,
613,16,264,2,0,
613,16,264,3,0,
613,16,276,3,0,
613,16,281,2,0,
613,16,290,4,0,
613,16,317,4,0,
613,16,329,1,57,1
613,16,332,4,0,
613,16,343,3,0,
613,16,352,3,0,
613,16,362,1,21,1
613,16,372,2,0,
613,16,374,4,0,
613,16,400,2,0,
613,16,419,2,0,
613,16,421,4,0,
613,16,447,4,0,
613,16,468,4,0,
613,16,496,4,0,
613,16,497,4,0,
613,16,524,4,0,
613,16,583,2,0,
613,16,589,1,15,1
613,16,590,4,0,
613,16,612,4,0,
613,17,8,2,0,
613,17,37,1,53,
613,17,45,1,1,
613,17,57,4,0,
613,17,58,4,0,
613,17,59,1,45,
613,17,59,4,0,
613,17,92,4,0,
613,17,104,4,0,
613,17,117,1,9,
613,17,154,1,17,
613,17,156,1,41,
613,17,156,4,0,
613,17,163,1,33,
613,17,164,4,0,
613,17,175,1,36,
613,17,181,1,5,
613,17,182,4,0,
613,17,196,1,13,
613,17,203,1,25,
613,17,204,1,29,
613,17,207,4,0,
613,17,213,4,0,
613,17,214,2,0,
613,17,214,4,0,
613,17,216,4,0,
613,17,218,4,0,
613,17,227,2,0,
613,17,237,4,0,
613,17,240,4,0,
613,17,258,1,49,
613,17,258,4,0,
613,17,263,4,0,
613,17,264,2,0,
613,17,281,2,0,
613,17,317,4,0,
613,17,329,1,57,
613,17,332,4,0,
613,17,362,1,21,
613,17,372,2,0,
613,17,374,4,0,
613,17,400,2,0,
613,17,419,2,0,
613,17,421,4,0,
613,17,447,4,0,
613,17,496,4,0,
613,17,497,4,0,
613,17,524,4,0,
613,17,583,2,0,
613,17,589,1,15,
613,17,590,4,0,
613,18,8,2,0,
613,18,37,1,53,
613,18,45,1,1,
613,18,57,4,0,
613,18,58,4,0,
613,18,59,1,45,
613,18,59,4,0,
613,18,92,4,0,
613,18,104,4,0,
613,18,117,1,9,
613,18,154,1,17,
613,18,156,1,41,
613,18,156,4,0,
613,18,163,1,33,
613,18,164,4,0,
613,18,175,1,36,
613,18,181,1,5,
613,18,182,4,0,
613,18,196,1,13,
613,18,203,1,25,
613,18,204,1,29,
613,18,207,4,0,
613,18,213,4,0,
613,18,214,2,0,
613,18,214,4,0,
613,18,216,4,0,
613,18,218,4,0,
613,18,227,2,0,
613,18,237,4,0,
613,18,240,4,0,
613,18,258,1,49,
613,18,258,4,0,
613,18,263,4,0,
613,18,264,2,0,
613,18,281,2,0,
613,18,317,4,0,
613,18,329,1,57,
613,18,332,4,0,
613,18,362,1,21,
613,18,372,2,0,
613,18,374,4,0,
613,18,400,2,0,
613,18,419,2,0,
613,18,421,4,0,
613,18,447,4,0,
613,18,496,4,0,
613,18,497,4,0,
613,18,524,4,0,
613,18,583,2,0,
613,18,589,1,15,
613,18,590,4,0,
614,11,14,4,0,
614,11,15,4,0,
614,11,37,1,59,
614,11,45,1,1,3
614,11,45,1,5,
614,11,46,4,0,
614,11,57,4,0,
614,11,58,4,0,
614,11,59,1,45,
614,11,59,4,0,
614,11,63,4,0,
614,11,70,4,0,
614,11,91,4,0,
614,11,92,4,0,
614,11,104,4,0,
614,11,117,1,1,4
614,11,117,1,9,
614,11,154,1,17,
614,11,156,1,41,
614,11,156,4,0,
614,11,157,4,0,
614,11,163,1,33,
614,11,164,4,0,
614,11,175,1,36,
614,11,181,1,1,2
614,11,182,4,0,
614,11,196,1,1,5
614,11,196,1,13,
614,11,203,1,25,
614,11,207,1,29,
614,11,207,4,0,
614,11,213,4,0,
614,11,216,4,0,
614,11,218,4,0,
614,11,237,4,0,
614,11,240,4,0,
614,11,249,4,0,
614,11,258,1,53,
614,11,258,4,0,
614,11,263,4,0,
614,11,269,4,0,
614,11,276,1,1,1
614,11,280,4,0,
614,11,291,4,0,
614,11,317,4,0,
614,11,329,1,66,
614,11,332,4,0,
614,11,339,4,0,
614,11,362,1,21,
614,11,374,4,0,
614,11,411,4,0,
614,11,416,4,0,
614,11,421,4,0,
614,11,444,4,0,
614,11,447,4,0,
614,11,468,4,0,
614,11,496,4,0,
614,11,497,4,0,
614,11,523,4,0,
614,11,524,4,0,
614,11,556,1,37,
614,14,8,3,0,
614,14,14,4,0,
614,14,15,4,0,
614,14,37,1,59,
614,14,45,1,1,4
614,14,45,1,5,
614,14,46,4,0,
614,14,57,4,0,
614,14,58,4,0,
614,14,59,1,45,
614,14,59,4,0,
614,14,63,4,0,
614,14,67,3,0,
614,14,70,4,0,
614,14,91,4,0,
614,14,92,4,0,
614,14,104,4,0,
614,14,117,1,1,5
614,14,117,1,9,
614,14,154,1,17,
614,14,156,1,41,
614,14,156,4,0,
614,14,157,4,0,
614,14,163,1,33,
614,14,164,4,0,
614,14,173,3,0,
614,14,175,1,36,
614,14,181,1,1,3
614,14,182,4,0,
614,14,196,1,1,6
614,14,196,1,13,
614,14,196,3,0,
614,14,203,1,25,
614,14,207,1,29,
614,14,207,4,0,
614,14,213,4,0,
614,14,214,3,0,
614,14,216,4,0,
614,14,218,4,0,
614,14,237,4,0,
614,14,240,4,0,
614,14,249,4,0,
614,14,258,1,53,
614,14,258,4,0,
614,14,263,4,0,
614,14,269,4,0,
614,14,276,1,1,1
614,14,276,3,0,
614,14,280,4,0,
614,14,291,4,0,
614,14,317,4,0,
614,14,329,1,66,
614,14,332,4,0,
614,14,339,4,0,
614,14,343,3,0,
614,14,362,1,21,
614,14,374,4,0,
614,14,411,4,0,
614,14,416,4,0,
614,14,421,4,0,
614,14,444,4,0,
614,14,447,4,0,
614,14,453,1,1,2
614,14,468,4,0,
614,14,496,4,0,
614,14,497,4,0,
614,14,523,4,0,
614,14,524,4,0,
614,14,556,1,37,
614,15,14,4,0,
614,15,15,4,0,
614,15,37,1,1,2
614,15,37,1,59,
614,15,45,1,1,5
614,15,46,4,0,
614,15,57,4,0,
614,15,58,4,0,
614,15,59,1,45,
614,15,59,4,0,
614,15,63,4,0,
614,15,70,4,0,
614,15,91,4,0,
614,15,92,4,0,
614,15,104,4,0,
614,15,117,1,1,7
614,15,154,1,17,
614,15,156,1,41,
614,15,156,4,0,
614,15,157,4,0,
614,15,163,1,33,
614,15,164,4,0,
614,15,175,1,36,
614,15,181,1,1,6
614,15,182,4,0,
614,15,196,1,1,8
614,15,196,1,13,
614,15,203,1,25,
614,15,207,1,29,
614,15,207,4,0,
614,15,213,4,0,
614,15,214,4,0,
614,15,216,4,0,
614,15,218,4,0,
614,15,237,4,0,
614,15,240,4,0,
614,15,249,4,0,
614,15,258,1,53,
614,15,258,4,0,
614,15,263,4,0,
614,15,269,4,0,
614,15,276,1,1,3
614,15,280,4,0,
614,15,317,4,0,
614,15,329,1,1,1
614,15,329,1,66,
614,15,332,4,0,
614,15,339,4,0,
614,15,362,1,21,
614,15,374,4,0,
614,15,411,4,0,
614,15,416,4,0,
614,15,421,4,0,
614,15,444,4,0,
614,15,447,4,0,
614,15,453,1,1,4
614,15,468,4,0,
614,15,496,4,0,
614,15,497,4,0,
614,15,523,4,0,
614,15,524,4,0,
614,15,556,1,37,
614,15,589,1,9,
614,15,590,4,0,
614,15,612,4,0,
614,16,8,3,0,
614,16,14,4,0,
614,16,15,4,0,
614,16,37,1,1,2
614,16,37,1,59,1
614,16,45,1,1,5
614,16,46,4,0,
614,16,57,4,0,
614,16,58,4,0,
614,16,59,1,45,1
614,16,59,4,0,
614,16,63,4,0,
614,16,67,3,0,
614,16,70,4,0,
614,16,91,4,0,
614,16,92,4,0,
614,16,104,4,0,
614,16,117,1,1,7
614,16,154,1,17,1
614,16,156,1,41,1
614,16,156,4,0,
614,16,157,4,0,
614,16,163,1,33,1
614,16,164,4,0,
614,16,173,3,0,
614,16,175,1,36,1
614,16,181,1,1,6
614,16,182,4,0,
614,16,196,1,1,8
614,16,196,1,13,1
614,16,196,3,0,
614,16,203,1,25,1
614,16,207,1,29,1
614,16,207,4,0,
614,16,213,4,0,
614,16,214,4,0,
614,16,216,4,0,
614,16,218,4,0,
614,16,237,4,0,
614,16,240,4,0,
614,16,249,4,0,
614,16,258,1,53,1
614,16,258,4,0,
614,16,263,4,0,
614,16,264,3,0,
614,16,269,4,0,
614,16,276,1,1,3
614,16,276,3,0,
614,16,280,4,0,
614,16,290,4,0,
614,16,291,4,0,
614,16,317,4,0,
614,16,329,1,1,1
614,16,329,1,66,1
614,16,332,4,0,
614,16,339,4,0,
614,16,343,3,0,
614,16,352,3,0,
614,16,362,1,21,1
614,16,374,4,0,
614,16,411,4,0,
614,16,416,4,0,
614,16,421,4,0,
614,16,444,4,0,
614,16,447,4,0,
614,16,453,1,1,4
614,16,468,4,0,
614,16,496,4,0,
614,16,497,4,0,
614,16,523,4,0,
614,16,524,4,0,
614,16,556,1,37,1
614,16,589,1,9,1
614,16,590,4,0,
614,16,612,4,0,
614,17,14,4,0,
614,17,37,1,1,3
614,17,37,1,59,
614,17,45,1,1,6
614,17,46,4,0,
614,17,57,4,0,
614,17,58,4,0,
614,17,59,1,45,
614,17,59,4,0,
614,17,63,4,0,
614,17,92,4,0,
614,17,104,4,0,
614,17,117,1,1,8
614,17,117,1,9,
614,17,154,1,17,
614,17,156,1,41,
614,17,156,4,0,
614,17,157,4,0,
614,17,163,1,33,
614,17,164,4,0,
614,17,175,1,36,
614,17,181,1,1,7
614,17,181,1,5,
614,17,182,4,0,
614,17,196,1,1,9
614,17,196,1,13,
614,17,203,1,25,
614,17,207,1,29,
614,17,207,4,0,
614,17,213,4,0,
614,17,214,4,0,
614,17,216,4,0,
614,17,218,4,0,
614,17,237,4,0,
614,17,240,4,0,
614,17,258,1,53,
614,17,258,4,0,
614,17,263,4,0,
614,17,269,4,0,
614,17,276,1,1,4
614,17,280,4,0,
614,17,317,4,0,
614,17,329,1,1,2
614,17,329,1,66,
614,17,332,4,0,
614,17,339,4,0,
614,17,362,1,21,
614,17,374,4,0,
614,17,411,4,0,
614,17,416,4,0,
614,17,421,4,0,
614,17,444,4,0,
614,17,447,4,0,
614,17,453,1,1,5
614,17,496,4,0,
614,17,497,4,0,
614,17,523,4,0,
614,17,524,4,0,
614,17,556,1,0,
614,17,556,1,1,1
614,17,589,1,15,
614,17,590,4,0,
614,18,14,4,0,
614,18,37,1,1,3
614,18,37,1,59,
614,18,45,1,1,6
614,18,46,4,0,
614,18,57,4,0,
614,18,58,4,0,
614,18,59,1,45,
614,18,59,4,0,
614,18,63,4,0,
614,18,92,4,0,
614,18,104,4,0,
614,18,117,1,1,8
614,18,117,1,9,
614,18,154,1,17,
614,18,156,1,41,
614,18,156,4,0,
614,18,157,4,0,
614,18,163,1,33,
614,18,164,4,0,
614,18,175,1,36,
614,18,181,1,1,7
614,18,181,1,5,
614,18,182,4,0,
614,18,196,1,1,9
614,18,196,1,13,
614,18,203,1,25,
614,18,207,1,29,
614,18,207,4,0,
614,18,213,4,0,
614,18,214,4,0,
614,18,216,4,0,
614,18,218,4,0,
614,18,237,4,0,
614,18,240,4,0,
614,18,258,1,53,
614,18,258,4,0,
614,18,263,4,0,
614,18,269,4,0,
614,18,276,1,1,4
614,18,280,4,0,
614,18,317,4,0,
614,18,329,1,1,2
614,18,329,1,66,
614,18,332,4,0,
614,18,339,4,0,
614,18,362,1,21,
614,18,374,4,0,
614,18,411,4,0,
614,18,416,4,0,
614,18,421,4,0,
614,18,444,4,0,
614,18,447,4,0,
614,18,453,1,1,5
614,18,496,4,0,
614,18,497,4,0,
614,18,523,4,0,
614,18,524,4,0,
614,18,556,1,0,
614,18,556,1,1,1
614,18,589,1,15,
614,18,590,4,0,
615,11,20,1,1,
615,11,54,1,21,1
615,11,58,1,33,
615,11,58,4,0,
615,11,59,4,0,
615,11,62,1,25,
615,11,63,4,0,
615,11,76,1,53,
615,11,76,4,0,
615,11,92,4,0,
615,11,104,4,0,
615,11,105,1,49,
615,11,109,1,45,
615,11,113,1,37,1
615,11,113,4,0,
615,11,114,1,21,2
615,11,115,1,37,2
615,11,115,4,0,
615,11,151,1,29,
615,11,153,4,0,
615,11,156,4,0,
615,11,159,1,9,
615,11,163,1,41,
615,11,164,4,0,
615,11,182,4,0,
615,11,196,1,17,
615,11,207,4,0,
615,11,213,4,0,
615,11,216,4,0,
615,11,218,4,0,
615,11,229,1,13,
615,11,237,4,0,
615,11,240,4,0,
615,11,258,4,0,
615,11,263,4,0,
615,11,329,1,61,
615,11,398,4,0,
615,11,400,1,57,
615,11,420,1,5,
615,11,430,4,0,
615,11,496,4,0,
615,11,512,4,0,
615,11,524,4,0,
615,14,20,1,1,
615,14,20,3,0,
615,14,54,1,21,1
615,14,58,1,33,
615,14,58,4,0,
615,14,59,4,0,
615,14,62,1,25,
615,14,63,4,0,
615,14,76,1,53,
615,14,76,4,0,
615,14,92,4,0,
615,14,104,4,0,
615,14,105,1,49,
615,14,109,1,45,
615,14,113,1,37,1
615,14,113,4,0,
615,14,114,1,21,2
615,14,115,1,37,2
615,14,115,4,0,
615,14,151,1,29,
615,14,153,4,0,
615,14,156,4,0,
615,14,159,1,9,
615,14,163,1,41,
615,14,164,4,0,
615,14,173,3,0,
615,14,182,4,0,
615,14,196,1,17,
615,14,196,3,0,
615,14,207,4,0,
615,14,213,4,0,
615,14,214,3,0,
615,14,216,4,0,
615,14,218,4,0,
615,14,229,1,13,
615,14,237,4,0,
615,14,240,4,0,
615,14,258,4,0,
615,14,263,4,0,
615,14,277,3,0,
615,14,282,3,0,
615,14,324,3,0,
615,14,329,1,61,
615,14,334,3,0,
615,14,398,4,0,
615,14,400,1,57,
615,14,420,1,5,
615,14,430,4,0,
615,14,496,4,0,
615,14,512,4,0,
615,14,524,4,0,
615,15,20,1,1,6
615,15,54,1,1,4
615,15,54,1,21,1
615,15,58,1,33,
615,15,58,4,0,
615,15,59,4,0,
615,15,62,1,25,
615,15,63,4,0,
615,15,76,1,53,
615,15,76,4,0,
615,15,92,4,0,
615,15,104,4,0,
615,15,105,1,49,
615,15,109,1,45,
615,15,113,1,37,1
615,15,113,4,0,
615,15,114,1,1,5
615,15,114,1,21,2
615,15,115,1,37,2
615,15,115,4,0,
615,15,151,1,29,
615,15,153,4,0,
615,15,156,4,0,
615,15,159,1,9,
615,15,163,1,41,
615,15,164,4,0,
615,15,182,4,0,
615,15,196,1,17,
615,15,207,4,0,
615,15,213,4,0,
615,15,214,4,0,
615,15,216,4,0,
615,15,218,4,0,
615,15,229,1,13,
615,15,237,4,0,
615,15,240,4,0,
615,15,258,4,0,
615,15,263,4,0,
615,15,329,1,1,1
615,15,329,1,61,
615,15,398,4,0,
615,15,400,1,1,2
615,15,400,1,57,
615,15,420,1,1,3
615,15,420,1,5,
615,15,430,4,0,
615,15,496,4,0,
615,15,512,4,0,
615,15,524,4,0,
615,15,573,1,50,
615,15,590,4,0,
615,16,20,1,1,6
615,16,20,3,0,
615,16,54,1,1,4
615,16,54,1,21,1
615,16,58,1,33,1
615,16,58,4,0,
615,16,59,4,0,
615,16,62,1,25,1
615,16,63,4,0,
615,16,76,1,53,1
615,16,76,4,0,
615,16,92,4,0,
615,16,104,4,0,
615,16,105,1,49,1
615,16,109,1,45,1
615,16,113,1,37,1
615,16,113,4,0,
615,16,114,1,1,5
615,16,114,1,21,2
615,16,115,1,37,2
615,16,115,4,0,
615,16,151,1,29,1
615,16,153,4,0,
615,16,156,4,0,
615,16,159,1,9,1
615,16,163,1,41,1
615,16,164,4,0,
615,16,173,3,0,
615,16,182,4,0,
615,16,196,1,17,1
615,16,196,3,0,
615,16,207,4,0,
615,16,213,4,0,
615,16,214,4,0,
615,16,216,4,0,
615,16,218,4,0,
615,16,229,1,13,1
615,16,237,4,0,
615,16,240,4,0,
615,16,258,4,0,
615,16,263,4,0,
615,16,277,3,0,
615,16,282,3,0,
615,16,290,4,0,
615,16,324,3,0,
615,16,329,1,1,1
615,16,329,1,61,1
615,16,334,3,0,
615,16,352,3,0,
615,16,398,4,0,
615,16,400,1,1,2
615,16,400,1,57,1
615,16,420,1,1,3
615,16,420,1,5,1
615,16,430,4,0,
615,16,496,4,0,
615,16,512,4,0,
615,16,524,4,0,
615,16,573,1,50,1
615,16,590,4,0,
615,17,20,1,1,3
615,17,54,1,9,1
615,17,58,1,25,
615,17,58,4,0,
615,17,59,4,0,
615,17,62,1,13,
615,17,63,4,0,
615,17,76,1,50,
615,17,76,4,0,
615,17,92,4,0,
615,17,104,4,0,
615,17,105,1,45,
615,17,109,1,41,
615,17,113,1,29,
615,17,113,4,0,
615,17,114,1,9,2
615,17,115,1,33,
615,17,115,4,0,
615,17,151,1,17,
615,17,153,4,0,
615,17,156,4,0,
615,17,159,1,1,5
615,17,163,1,37,
615,17,164,4,0,
615,17,182,4,0,
615,17,196,1,5,
615,17,207,4,0,
615,17,213,4,0,
615,17,214,4,0,
615,17,216,4,0,
615,17,218,4,0,
615,17,229,1,1,6
615,17,237,4,0,
615,17,240,4,0,
615,17,246,1,21,
615,17,258,4,0,
615,17,263,4,0,
615,17,329,1,1,1
615,17,329,1,57,
615,17,398,4,0,
615,17,400,1,1,2
615,17,400,1,53,
615,17,420,1,1,4
615,17,430,4,0,
615,17,496,4,0,
615,17,512,4,0,
615,17,524,4,0,
615,17,573,1,49,
615,17,590,4,0,
615,17,694,4,0,
615,18,20,1,1,3
615,18,54,1,9,1
615,18,58,1,25,
615,18,58,4,0,
615,18,59,4,0,
615,18,62,1,13,
615,18,63,4,0,
615,18,76,1,50,
615,18,76,4,0,
615,18,92,4,0,
615,18,104,4,0,
615,18,105,1,45,
615,18,109,1,41,
615,18,113,1,29,
615,18,113,4,0,
615,18,114,1,9,2
615,18,115,1,33,
615,18,115,4,0,
615,18,151,1,17,
615,18,153,4,0,
615,18,156,4,0,
615,18,159,1,1,5
615,18,163,1,37,
615,18,164,4,0,
615,18,182,4,0,
615,18,196,1,5,
615,18,207,4,0,
615,18,213,4,0,
615,18,214,4,0,
615,18,216,4,0,
615,18,218,4,0,
615,18,229,1,1,6
615,18,237,4,0,
615,18,240,4,0,
615,18,246,1,21,
615,18,258,4,0,
615,18,263,4,0,
615,18,329,1,1,1
615,18,329,1,57,
615,18,398,4,0,
615,18,400,1,1,2
615,18,400,1,53,
615,18,420,1,1,4
615,18,430,4,0,
615,18,496,4,0,
615,18,512,4,0,
615,18,524,4,0,
615,18,573,1,49,
615,18,590,4,0,
615,18,694,4,0,
616,11,34,1,40,
616,11,38,2,0,
616,11,51,1,4,
616,11,72,1,20,
616,11,92,4,0,
616,11,104,4,0,
616,11,105,1,49,
616,11,117,1,8,
616,11,141,1,1,
616,11,151,1,32,
616,11,156,4,0,
616,11,164,4,0,
616,11,170,2,0,
616,11,174,1,13,
616,11,182,1,28,
616,11,182,4,0,
616,11,188,4,0,
616,11,189,2,0,
616,11,191,2,0,
616,11,202,1,37,
616,11,203,2,0,
616,11,207,4,0,
616,11,213,4,0,
616,11,216,4,0,
616,11,218,4,0,
616,11,226,2,0,
616,11,227,2,0,
616,11,228,2,0,
616,11,237,4,0,
616,11,240,4,0,
616,11,263,4,0,
616,11,281,1,25,
616,11,364,2,0,
616,11,385,1,52,
616,11,405,1,44,
616,11,412,4,0,
616,11,470,2,0,
616,11,474,4,0,
616,11,496,4,0,
616,11,515,1,56,
616,11,522,1,16,
616,11,522,4,0,
616,14,34,1,40,
616,14,38,2,0,
616,14,51,1,4,
616,14,72,1,20,
616,14,92,4,0,
616,14,104,4,0,
616,14,105,1,49,
616,14,117,1,8,
616,14,141,1,1,
616,14,151,1,32,
616,14,156,4,0,
616,14,164,4,0,
616,14,170,2,0,
616,14,173,3,0,
616,14,174,1,13,
616,14,182,1,28,
616,14,182,4,0,
616,14,188,4,0,
616,14,189,2,0,
616,14,191,2,0,
616,14,202,1,37,
616,14,202,3,0,
616,14,203,2,0,
616,14,207,4,0,
616,14,213,4,0,
616,14,214,3,0,
616,14,216,4,0,
616,14,218,4,0,
616,14,226,2,0,
616,14,227,2,0,
616,14,228,2,0,
616,14,237,4,0,
616,14,240,4,0,
616,14,263,4,0,
616,14,281,1,25,
616,14,324,3,0,
616,14,364,2,0,
616,14,380,3,0,
616,14,385,1,52,
616,14,405,1,44,
616,14,412,4,0,
616,14,450,3,0,
616,14,470,2,0,
616,14,474,4,0,
616,14,496,4,0,
616,14,515,1,56,
616,14,522,1,16,
616,14,522,4,0,
616,15,34,1,40,
616,15,38,2,0,
616,15,51,1,4,
616,15,72,1,20,
616,15,92,4,0,
616,15,104,4,0,
616,15,105,1,49,
616,15,117,1,8,
616,15,141,1,1,
616,15,151,1,32,
616,15,156,4,0,
616,15,164,4,0,
616,15,170,2,0,
616,15,174,1,13,
616,15,182,1,28,
616,15,182,4,0,
616,15,188,4,0,
616,15,189,2,0,
616,15,191,2,0,
616,15,202,1,37,
616,15,203,2,0,
616,15,207,4,0,
616,15,213,4,0,
616,15,214,4,0,
616,15,216,4,0,
616,15,218,4,0,
616,15,226,2,0,
616,15,227,2,0,
616,15,228,2,0,
616,15,237,4,0,
616,15,240,4,0,
616,15,263,4,0,
616,15,281,1,25,
616,15,364,2,0,
616,15,385,1,50,
616,15,405,1,44,
616,15,412,4,0,
616,15,470,2,0,
616,15,474,4,0,
616,15,496,4,0,
616,15,515,1,56,
616,15,522,1,16,
616,15,522,4,0,
616,15,590,4,0,
616,15,611,4,0,
616,16,34,1,40,1
616,16,38,2,0,
616,16,51,1,4,1
616,16,72,1,20,1
616,16,92,4,0,
616,16,104,4,0,
616,16,105,1,49,1
616,16,117,1,8,1
616,16,141,1,1,1
616,16,151,1,32,1
616,16,156,4,0,
616,16,164,4,0,
616,16,170,2,0,
616,16,173,3,0,
616,16,174,1,13,1
616,16,182,1,28,1
616,16,182,4,0,
616,16,188,4,0,
616,16,189,2,0,
616,16,191,2,0,
616,16,202,1,37,1
616,16,202,3,0,
616,16,203,2,0,
616,16,207,4,0,
616,16,213,4,0,
616,16,214,4,0,
616,16,216,4,0,
616,16,218,4,0,
616,16,226,2,0,
616,16,227,2,0,
616,16,228,2,0,
616,16,237,4,0,
616,16,240,4,0,
616,16,263,4,0,
616,16,281,1,25,1
616,16,290,4,0,
616,16,324,3,0,
616,16,364,2,0,
616,16,380,3,0,
616,16,385,1,50,1
616,16,405,1,44,1
616,16,412,4,0,
616,16,450,3,0,
616,16,470,2,0,
616,16,474,4,0,
616,16,496,4,0,
616,16,515,1,56,1
616,16,522,1,16,1
616,16,522,4,0,
616,16,590,4,0,
616,16,611,4,0,
616,17,34,1,40,
616,17,38,2,0,
616,17,51,1,4,
616,17,71,1,1,
616,17,72,1,20,
616,17,92,4,0,
616,17,104,4,0,
616,17,105,1,49,
616,17,117,1,8,
616,17,141,4,0,
616,17,151,1,32,
616,17,156,4,0,
616,17,164,4,0,
616,17,170,2,0,
616,17,174,1,13,
616,17,182,1,28,
616,17,182,4,0,
616,17,188,4,0,
616,17,189,2,0,
616,17,191,2,0,
616,17,202,1,37,
616,17,203,2,0,
616,17,207,4,0,
616,17,213,4,0,
616,17,214,4,0,
616,17,216,4,0,
616,17,218,4,0,
616,17,226,2,0,
616,17,227,2,0,
616,17,228,2,0,
616,17,237,4,0,
616,17,240,4,0,
616,17,263,4,0,
616,17,281,1,25,
616,17,364,2,0,
616,17,385,1,50,
616,17,405,1,44,
616,17,412,4,0,
616,17,470,2,0,
616,17,474,4,0,
616,17,496,4,0,
616,17,515,1,56,
616,17,522,1,16,
616,17,590,4,0,
616,17,611,4,0,
616,18,34,1,40,
616,18,38,2,0,
616,18,51,1,4,
616,18,71,1,1,
616,18,72,1,20,
616,18,92,4,0,
616,18,104,4,0,
616,18,105,1,49,
616,18,117,1,8,
616,18,141,4,0,
616,18,151,1,32,
616,18,156,4,0,
616,18,164,4,0,
616,18,170,2,0,
616,18,174,1,13,
616,18,182,1,28,
616,18,182,4,0,
616,18,188,4,0,
616,18,189,2,0,
616,18,191,2,0,
616,18,202,1,37,
616,18,203,2,0,
616,18,207,4,0,
616,18,213,4,0,
616,18,214,4,0,
616,18,216,4,0,
616,18,218,4,0,
616,18,226,2,0,
616,18,227,2,0,
616,18,228,2,0,
616,18,237,4,0,
616,18,240,4,0,
616,18,263,4,0,
616,18,281,1,25,
616,18,364,2,0,
616,18,385,1,50,
616,18,390,2,0,
616,18,405,1,44,
616,18,412,4,0,
616,18,470,2,0,
616,18,474,4,0,
616,18,496,4,0,
616,18,515,1,56,
616,18,522,1,16,
616,18,590,4,0,
616,18,611,4,0,
617,11,63,4,0,
617,11,72,1,20,
617,11,92,4,0,
617,11,97,1,32,
617,11,98,1,1,4
617,11,98,1,13,
617,11,104,1,1,3
617,11,104,1,8,
617,11,104,4,0,
617,11,105,1,49,
617,11,129,1,25,
617,11,141,1,1,1
617,11,156,4,0,
617,11,164,4,0,
617,11,182,4,0,
617,11,188,4,0,
617,11,201,4,0,
617,11,202,1,37,
617,11,207,4,0,
617,11,213,4,0,
617,11,216,4,0,
617,11,218,4,0,
617,11,237,4,0,
617,11,240,4,0,
617,11,263,4,0,
617,11,369,1,40,
617,11,369,4,0,
617,11,382,1,28,
617,11,384,1,52,
617,11,405,1,44,
617,11,411,4,0,
617,11,412,4,0,
617,11,416,4,0,
617,11,474,4,0,
617,11,491,1,1,2
617,11,491,1,4,
617,11,496,4,0,
617,11,515,1,56,
617,11,522,1,16,
617,11,522,4,0,
617,14,63,4,0,
617,14,72,1,20,
617,14,92,4,0,
617,14,97,1,32,
617,14,98,1,1,4
617,14,98,1,13,
617,14,104,1,1,3
617,14,104,1,8,
617,14,104,4,0,
617,14,105,1,49,
617,14,129,1,25,
617,14,141,1,1,1
617,14,156,4,0,
617,14,164,4,0,
617,14,173,3,0,
617,14,182,4,0,
617,14,188,4,0,
617,14,201,4,0,
617,14,202,1,37,
617,14,202,3,0,
617,14,207,4,0,
617,14,213,4,0,
617,14,214,3,0,
617,14,216,4,0,
617,14,218,4,0,
617,14,237,4,0,
617,14,240,4,0,
617,14,263,4,0,
617,14,282,3,0,
617,14,324,3,0,
617,14,369,1,40,
617,14,369,4,0,
617,14,380,3,0,
617,14,382,1,28,
617,14,384,1,52,
617,14,405,1,44,
617,14,411,4,0,
617,14,412,4,0,
617,14,416,4,0,
617,14,450,3,0,
617,14,474,4,0,
617,14,491,1,1,2
617,14,491,1,4,
617,14,496,4,0,
617,14,515,1,56,
617,14,522,1,16,
617,14,522,4,0,
617,15,63,4,0,
617,15,72,1,20,
617,15,92,4,0,
617,15,97,1,32,
617,15,98,1,1,6
617,15,98,1,13,
617,15,104,1,1,5
617,15,104,1,8,
617,15,104,4,0,
617,15,105,1,49,
617,15,129,1,25,
617,15,141,1,1,3
617,15,156,4,0,
617,15,164,4,0,
617,15,182,4,0,
617,15,188,4,0,
617,15,201,4,0,
617,15,202,1,37,
617,15,207,4,0,
617,15,213,4,0,
617,15,214,4,0,
617,15,216,4,0,
617,15,218,4,0,
617,15,237,4,0,
617,15,240,4,0,
617,15,263,4,0,
617,15,369,1,40,
617,15,369,4,0,
617,15,382,1,28,
617,15,384,1,1,2
617,15,384,1,52,
617,15,405,1,44,
617,15,411,4,0,
617,15,412,4,0,
617,15,416,4,0,
617,15,474,4,0,
617,15,491,1,1,4
617,15,491,1,4,
617,15,496,4,0,
617,15,515,1,1,1
617,15,515,1,56,
617,15,522,1,16,
617,15,522,4,0,
617,15,590,4,0,
617,15,611,4,0,
617,16,63,4,0,
617,16,72,1,20,1
617,16,92,4,0,
617,16,97,1,32,1
617,16,98,1,1,7
617,16,98,1,13,1
617,16,104,1,1,6
617,16,104,1,8,1
617,16,104,4,0,
617,16,105,1,49,1
617,16,129,1,25,1
617,16,141,1,1,4
617,16,156,4,0,
617,16,164,4,0,
617,16,173,3,0,
617,16,182,4,0,
617,16,188,4,0,
617,16,201,4,0,
617,16,202,1,37,1
617,16,202,3,0,
617,16,207,4,0,
617,16,213,4,0,
617,16,214,4,0,
617,16,216,4,0,
617,16,218,4,0,
617,16,237,4,0,
617,16,240,4,0,
617,16,263,4,0,
617,16,282,3,0,
617,16,290,4,0,
617,16,324,3,0,
617,16,369,1,40,1
617,16,369,4,0,
617,16,380,3,0,
617,16,382,1,28,1
617,16,384,1,1,3
617,16,384,1,52,1
617,16,405,1,44,1
617,16,411,4,0,
617,16,412,4,0,
617,16,416,4,0,
617,16,450,3,0,
617,16,474,4,0,
617,16,491,1,1,5
617,16,491,1,4,1
617,16,496,4,0,
617,16,515,1,1,2
617,16,515,1,56,1
617,16,522,1,16,1
617,16,522,4,0,
617,16,590,4,0,
617,16,594,1,1,1
617,16,611,4,0,
617,17,63,4,0,
617,17,71,1,1,4
617,17,72,1,20,
617,17,92,4,0,
617,17,97,1,32,
617,17,98,1,1,7
617,17,98,1,13,
617,17,104,1,1,6
617,17,104,1,8,
617,17,104,4,0,
617,17,105,1,49,
617,17,129,1,25,
617,17,141,4,0,
617,17,156,4,0,
617,17,164,4,0,
617,17,182,4,0,
617,17,188,4,0,
617,17,201,4,0,
617,17,202,1,37,
617,17,207,4,0,
617,17,213,4,0,
617,17,214,4,0,
617,17,216,4,0,
617,17,218,4,0,
617,17,237,4,0,
617,17,240,4,0,
617,17,263,4,0,
617,17,369,1,40,
617,17,369,4,0,
617,17,382,1,28,
617,17,384,1,1,3
617,17,384,1,52,
617,17,405,1,44,
617,17,411,4,0,
617,17,412,4,0,
617,17,416,4,0,
617,17,474,4,0,
617,17,491,1,1,5
617,17,491,1,4,
617,17,496,4,0,
617,17,515,1,1,2
617,17,515,1,56,
617,17,522,1,16,
617,17,590,4,0,
617,17,594,1,1,1
617,17,611,4,0,
617,18,63,4,0,
617,18,71,1,1,4
617,18,72,1,20,
617,18,92,4,0,
617,18,97,1,32,
617,18,98,1,1,7
617,18,98,1,13,
617,18,104,1,1,6
617,18,104,1,8,
617,18,104,4,0,
617,18,105,1,49,
617,18,129,1,25,
617,18,141,4,0,
617,18,156,4,0,
617,18,164,4,0,
617,18,182,4,0,
617,18,188,4,0,
617,18,201,4,0,
617,18,202,1,37,
617,18,207,4,0,
617,18,213,4,0,
617,18,214,4,0,
617,18,216,4,0,
617,18,218,4,0,
617,18,237,4,0,
617,18,240,4,0,
617,18,263,4,0,
617,18,369,1,40,
617,18,369,4,0,
617,18,382,1,28,
617,18,384,1,1,3
617,18,384,1,52,
617,18,405,1,44,
617,18,411,4,0,
617,18,412,4,0,
617,18,416,4,0,
617,18,474,4,0,
617,18,491,1,1,5
617,18,491,1,4,
617,18,496,4,0,
617,18,515,1,1,2
617,18,515,1,56,
617,18,522,1,16,
617,18,590,4,0,
617,18,594,1,1,1
617,18,611,4,0,
618,11,57,4,0,
618,11,84,1,9,
618,11,85,1,45,
618,11,85,4,0,
618,11,86,4,0,
618,11,87,4,0,
618,11,89,4,0,
618,11,90,1,61,
618,11,91,4,0,
618,11,92,4,0,
618,11,104,4,0,
618,11,117,1,5,
618,11,148,4,0,
618,11,156,4,0,
618,11,157,4,0,
618,11,164,4,0,
618,11,174,2,0,
618,11,175,1,55,
618,11,180,2,0,
618,11,182,4,0,
618,11,188,4,0,
618,11,189,1,1,1
618,11,201,4,0,
618,11,203,1,30,
618,11,207,4,0,
618,11,209,2,0,
618,11,213,4,0,
618,11,214,2,0,
618,11,216,4,0,
618,11,218,4,0,
618,11,220,2,0,
618,11,237,4,0,
618,11,240,4,0,
618,11,263,4,0,
618,11,279,1,50,
618,11,281,2,0,
618,11,293,1,17,
618,11,300,1,1,2
618,11,310,2,0,
618,11,317,4,0,
618,11,330,1,40,
618,11,340,1,35,
618,11,341,1,13,
618,11,351,2,0,
618,11,371,4,0,
618,11,414,2,0,
618,11,426,1,21,
618,11,435,1,25,
618,11,444,4,0,
618,11,482,4,0,
618,11,496,4,0,
618,11,503,4,0,
618,11,523,4,0,
618,14,57,4,0,
618,14,84,1,9,
618,14,85,1,45,
618,14,85,4,0,
618,14,86,4,0,
618,14,87,4,0,
618,14,89,4,0,
618,14,90,1,61,
618,14,91,4,0,
618,14,92,4,0,
618,14,104,4,0,
618,14,117,1,5,
618,14,148,4,0,
618,14,156,4,0,
618,14,157,4,0,
618,14,164,4,0,
618,14,173,3,0,
618,14,174,2,0,
618,14,175,1,55,
618,14,180,2,0,
618,14,180,3,0,
618,14,182,4,0,
618,14,188,4,0,
618,14,189,1,1,1
618,14,201,4,0,
618,14,203,1,30,
618,14,207,4,0,
618,14,209,2,0,
618,14,213,4,0,
618,14,214,2,0,
618,14,214,3,0,
618,14,216,4,0,
618,14,218,4,0,
618,14,220,2,0,
618,14,220,3,0,
618,14,237,4,0,
618,14,240,4,0,
618,14,253,3,0,
618,14,263,4,0,
618,14,279,1,50,
618,14,281,2,0,
618,14,283,3,0,
618,14,293,1,17,
618,14,300,1,1,2
618,14,310,2,0,
618,14,317,4,0,
618,14,330,1,40,
618,14,340,1,35,
618,14,340,3,0,
618,14,341,1,13,
618,14,351,2,0,
618,14,371,4,0,
618,14,393,3,0,
618,14,401,3,0,
618,14,414,2,0,
618,14,414,3,0,
618,14,426,1,21,
618,14,435,1,25,
618,14,444,4,0,
618,14,446,3,0,
618,14,482,4,0,
618,14,492,3,0,
618,14,496,4,0,
618,14,503,4,0,
618,14,523,4,0,
618,14,527,3,0,
618,15,33,1,1,3
618,15,55,1,1,4
618,15,57,4,0,
618,15,84,1,9,
618,15,85,1,45,
618,15,85,4,0,
618,15,86,4,0,
618,15,87,4,0,
618,15,89,4,0,
618,15,90,1,1,1
618,15,90,1,61,
618,15,91,4,0,
618,15,92,4,0,
618,15,104,4,0,
618,15,117,1,5,
618,15,148,4,0,
618,15,156,4,0,
618,15,157,4,0,
618,15,164,4,0,
618,15,174,2,0,
618,15,175,1,1,2
618,15,175,1,55,
618,15,180,2,0,
618,15,182,4,0,
618,15,188,4,0,
618,15,189,1,1,5
618,15,201,4,0,
618,15,203,1,30,
618,15,207,4,0,
618,15,209,2,0,
618,15,213,4,0,
618,15,214,2,0,
618,15,214,4,0,
618,15,216,4,0,
618,15,218,4,0,
618,15,220,2,0,
618,15,237,4,0,
618,15,240,4,0,
618,15,263,4,0,
618,15,279,1,50,
618,15,281,2,0,
618,15,293,1,17,
618,15,300,1,1,6
618,15,310,2,0,
618,15,317,4,0,
618,15,330,1,40,
618,15,340,1,35,
618,15,341,1,13,
618,15,351,2,0,
618,15,371,4,0,
618,15,382,2,0,
618,15,414,2,0,
618,15,426,1,21,
618,15,435,1,25,
618,15,444,4,0,
618,15,482,4,0,
618,15,496,4,0,
618,15,503,4,0,
618,15,513,2,0,
618,15,523,4,0,
618,15,590,4,0,
618,15,598,2,0,
618,15,611,4,0,
618,16,33,1,1,3
618,16,55,1,1,4
618,16,57,4,0,
618,16,84,1,9,1
618,16,85,1,45,1
618,16,85,4,0,
618,16,86,4,0,
618,16,87,4,0,
618,16,89,4,0,
618,16,90,1,1,1
618,16,90,1,61,1
618,16,91,4,0,
618,16,92,4,0,
618,16,104,4,0,
618,16,117,1,5,1
618,16,148,4,0,
618,16,156,4,0,
618,16,157,4,0,
618,16,164,4,0,
618,16,173,3,0,
618,16,174,2,0,
618,16,175,1,1,2
618,16,175,1,55,1
618,16,180,2,0,
618,16,180,3,0,
618,16,182,4,0,
618,16,188,4,0,
618,16,189,1,1,5
618,16,201,4,0,
618,16,203,1,30,1
618,16,207,4,0,
618,16,209,2,0,
618,16,213,4,0,
618,16,214,2,0,
618,16,214,4,0,
618,16,216,4,0,
618,16,218,4,0,
618,16,220,2,0,
618,16,220,3,0,
618,16,237,4,0,
618,16,240,4,0,
618,16,253,3,0,
618,16,263,4,0,
618,16,279,1,50,1
618,16,281,2,0,
618,16,283,3,0,
618,16,290,4,0,
618,16,293,1,17,1
618,16,300,1,1,6
618,16,310,2,0,
618,16,317,4,0,
618,16,330,1,40,1
618,16,340,1,35,1
618,16,340,3,0,
618,16,341,1,13,1
618,16,351,2,0,
618,16,351,3,0,
618,16,352,3,0,
618,16,371,4,0,
618,16,382,2,0,
618,16,393,3,0,
618,16,401,3,0,
618,16,414,2,0,
618,16,414,3,0,
618,16,426,1,21,1
618,16,435,1,25,1
618,16,444,4,0,
618,16,446,3,0,
618,16,482,4,0,
618,16,492,3,0,
618,16,496,4,0,
618,16,503,4,0,
618,16,513,2,0,
618,16,523,4,0,
618,16,527,3,0,
618,16,590,4,0,
618,16,598,2,0,
618,16,611,4,0,
618,17,33,1,1,3
618,17,55,1,1,4
618,17,57,4,0,
618,17,84,1,9,
618,17,85,1,45,
618,17,85,4,0,
618,17,86,4,0,
618,17,87,4,0,
618,17,89,4,0,
618,17,90,1,1,1
618,17,90,1,61,
618,17,92,4,0,
618,17,104,4,0,
618,17,117,1,5,
618,17,156,4,0,
618,17,157,4,0,
618,17,164,4,0,
618,17,174,2,0,
618,17,175,1,1,2
618,17,175,1,55,
618,17,180,2,0,
618,17,182,4,0,
618,17,188,4,0,
618,17,189,1,1,5
618,17,201,4,0,
618,17,203,1,30,
618,17,207,4,0,
618,17,209,2,0,
618,17,213,4,0,
618,17,214,2,0,
618,17,214,4,0,
618,17,216,4,0,
618,17,218,4,0,
618,17,220,2,0,
618,17,237,4,0,
618,17,240,4,0,
618,17,263,4,0,
618,17,279,1,50,
618,17,281,2,0,
618,17,293,1,17,
618,17,300,1,1,6
618,17,310,2,0,
618,17,317,4,0,
618,17,330,1,40,
618,17,340,1,35,
618,17,341,1,13,
618,17,351,2,0,
618,17,371,4,0,
618,17,382,2,0,
618,17,414,2,0,
618,17,426,1,21,
618,17,435,1,25,
618,17,444,4,0,
618,17,482,4,0,
618,17,496,4,0,
618,17,503,4,0,
618,17,513,2,0,
618,17,523,4,0,
618,17,590,4,0,
618,17,598,2,0,
618,17,611,4,0,
618,18,33,1,1,3
618,18,55,1,1,4
618,18,57,4,0,
618,18,84,1,9,
618,18,85,1,45,
618,18,85,4,0,
618,18,86,4,0,
618,18,87,4,0,
618,18,89,4,0,
618,18,90,1,1,1
618,18,90,1,61,
618,18,92,4,0,
618,18,104,4,0,
618,18,117,1,5,
618,18,156,4,0,
618,18,157,4,0,
618,18,164,4,0,
618,18,174,2,0,
618,18,175,1,1,2
618,18,175,1,55,
618,18,180,2,0,
618,18,182,4,0,
618,18,188,4,0,
618,18,189,1,1,5
618,18,201,4,0,
618,18,203,1,30,
618,18,207,4,0,
618,18,209,2,0,
618,18,213,4,0,
618,18,214,2,0,
618,18,214,4,0,
618,18,216,4,0,
618,18,218,4,0,
618,18,220,2,0,
618,18,237,4,0,
618,18,240,4,0,
618,18,263,4,0,
618,18,279,1,50,
618,18,281,2,0,
618,18,293,1,17,
618,18,300,1,1,6
618,18,310,2,0,
618,18,317,4,0,
618,18,330,1,40,
618,18,340,1,35,
618,18,341,1,13,
618,18,351,2,0,
618,18,371,4,0,
618,18,382,2,0,
618,18,414,2,0,
618,18,426,1,21,
618,18,435,1,25,
618,18,444,4,0,
618,18,482,4,0,
618,18,496,4,0,
618,18,503,4,0,
618,18,513,2,0,
618,18,523,4,0,
618,18,590,4,0,
618,18,598,2,0,
618,18,611,4,0,
619,11,1,1,1,
619,11,3,1,17,
619,11,14,4,0,
619,11,26,1,37,
619,11,67,2,0,
619,11,70,4,0,
619,11,91,4,0,
619,11,92,4,0,
619,11,96,1,5,
619,11,104,4,0,
619,11,115,4,0,
619,11,129,1,21,
619,11,136,1,53,
619,11,156,4,0,
619,11,157,4,0,
619,11,164,4,0,
619,11,179,1,57,
619,11,182,4,0,
619,11,197,1,9,
619,11,203,2,0,
619,11,207,4,0,
619,11,213,4,0,
619,11,216,4,0,
619,11,218,4,0,
619,11,226,2,0,
619,11,233,2,0,
619,11,237,4,0,
619,11,240,4,0,
619,11,241,4,0,
619,11,244,4,0,
619,11,249,4,0,
619,11,252,1,13,
619,11,263,4,0,
619,11,265,2,0,
619,11,269,4,0,
619,11,280,4,0,
619,11,282,2,0,
619,11,317,4,0,
619,11,332,4,0,
619,11,339,4,0,
619,11,340,1,49,
619,11,347,1,25,
619,11,347,4,0,
619,11,364,2,0,
619,11,369,1,41,
619,11,369,4,0,
619,11,371,4,0,
619,11,374,4,0,
619,11,382,2,0,
619,11,395,1,29,
619,11,396,1,61,
619,11,398,4,0,
619,11,409,1,33,
619,11,411,4,0,
619,11,444,4,0,
619,11,447,4,0,
619,11,490,4,0,
619,11,496,4,0,
619,11,501,1,45,
619,11,512,4,0,
619,11,514,4,0,
619,11,526,4,0,
619,14,1,1,1,
619,14,3,1,17,
619,14,14,4,0,
619,14,26,1,37,
619,14,67,2,0,
619,14,67,3,0,
619,14,70,4,0,
619,14,91,4,0,
619,14,92,4,0,
619,14,96,1,5,
619,14,104,4,0,
619,14,115,4,0,
619,14,129,1,21,
619,14,136,1,53,
619,14,156,4,0,
619,14,157,4,0,
619,14,164,4,0,
619,14,173,3,0,
619,14,179,1,57,
619,14,182,4,0,
619,14,197,1,9,
619,14,203,2,0,
619,14,207,4,0,
619,14,213,4,0,
619,14,214,3,0,
619,14,216,4,0,
619,14,218,4,0,
619,14,226,2,0,
619,14,233,2,0,
619,14,237,4,0,
619,14,240,4,0,
619,14,241,4,0,
619,14,244,4,0,
619,14,249,4,0,
619,14,252,1,13,
619,14,263,4,0,
619,14,265,2,0,
619,14,269,4,0,
619,14,270,3,0,
619,14,272,3,0,
619,14,280,4,0,
619,14,282,2,0,
619,14,282,3,0,
619,14,317,4,0,
619,14,332,4,0,
619,14,339,4,0,
619,14,340,1,49,
619,14,340,3,0,
619,14,347,1,25,
619,14,347,4,0,
619,14,364,2,0,
619,14,369,1,41,
619,14,369,4,0,
619,14,371,4,0,
619,14,374,4,0,
619,14,382,2,0,
619,14,395,1,29,
619,14,396,1,61,
619,14,398,4,0,
619,14,409,1,33,
619,14,409,3,0,
619,14,411,4,0,
619,14,444,4,0,
619,14,447,4,0,
619,14,490,4,0,
619,14,496,4,0,
619,14,501,1,45,
619,14,512,4,0,
619,14,514,4,0,
619,14,526,4,0,
619,14,530,3,0,
619,15,1,1,1,
619,15,3,1,17,
619,15,14,4,0,
619,15,26,1,37,
619,15,67,2,0,
619,15,70,4,0,
619,15,91,4,0,
619,15,92,4,0,
619,15,96,1,5,
619,15,104,4,0,
619,15,115,4,0,
619,15,129,1,21,
619,15,136,1,50,
619,15,156,4,0,
619,15,157,4,0,
619,15,164,4,0,
619,15,179,1,57,
619,15,182,4,0,
619,15,197,1,9,
619,15,203,2,0,
619,15,207,4,0,
619,15,213,4,0,
619,15,214,4,0,
619,15,216,4,0,
619,15,218,4,0,
619,15,226,2,0,
619,15,233,2,0,
619,15,237,4,0,
619,15,240,4,0,
619,15,241,4,0,
619,15,244,4,0,
619,15,249,4,0,
619,15,252,1,13,
619,15,263,4,0,
619,15,265,2,0,
619,15,269,4,0,
619,15,280,4,0,
619,15,282,2,0,
619,15,317,4,0,
619,15,332,4,0,
619,15,339,4,0,
619,15,340,1,49,
619,15,347,1,25,
619,15,347,4,0,
619,15,364,2,0,
619,15,369,1,41,
619,15,369,4,0,
619,15,371,4,0,
619,15,374,4,0,
619,15,382,2,0,
619,15,395,1,29,
619,15,396,1,61,
619,15,398,4,0,
619,15,409,1,33,
619,15,411,4,0,
619,15,444,4,0,
619,15,447,4,0,
619,15,490,4,0,
619,15,496,4,0,
619,15,501,1,45,
619,15,502,2,0,
619,15,512,4,0,
619,15,514,4,0,
619,15,590,4,0,
619,15,612,4,0,
619,16,1,1,1,1
619,16,3,1,17,1
619,16,14,4,0,
619,16,26,1,37,1
619,16,67,2,0,
619,16,67,3,0,
619,16,70,4,0,
619,16,91,4,0,
619,16,92,4,0,
619,16,96,1,5,1
619,16,104,4,0,
619,16,115,4,0,
619,16,129,1,21,1
619,16,136,1,50,1
619,16,156,4,0,
619,16,157,4,0,
619,16,164,4,0,
619,16,173,3,0,
619,16,179,1,57,1
619,16,182,4,0,
619,16,197,1,9,1
619,16,203,2,0,
619,16,207,4,0,
619,16,213,4,0,
619,16,214,4,0,
619,16,216,4,0,
619,16,218,4,0,
619,16,226,2,0,
619,16,233,2,0,
619,16,237,4,0,
619,16,240,4,0,
619,16,241,4,0,
619,16,244,4,0,
619,16,249,4,0,
619,16,252,1,13,1
619,16,263,4,0,
619,16,264,3,0,
619,16,265,2,0,
619,16,269,4,0,
619,16,270,3,0,
619,16,272,3,0,
619,16,280,4,0,
619,16,282,2,0,
619,16,282,3,0,
619,16,290,4,0,
619,16,317,4,0,
619,16,332,4,0,
619,16,339,4,0,
619,16,340,1,49,1
619,16,340,3,0,
619,16,347,1,25,1
619,16,347,4,0,
619,16,364,2,0,
619,16,369,1,41,1
619,16,369,4,0,
619,16,371,4,0,
619,16,374,4,0,
619,16,382,2,0,
619,16,395,1,29,1
619,16,396,1,61,1
619,16,398,4,0,
619,16,409,1,33,1
619,16,409,3,0,
619,16,411,4,0,
619,16,444,4,0,
619,16,447,4,0,
619,16,490,4,0,
619,16,496,4,0,
619,16,501,1,45,1
619,16,502,2,0,
619,16,512,4,0,
619,16,514,4,0,
619,16,530,3,0,
619,16,590,4,0,
619,16,612,4,0,
619,17,1,1,1,
619,17,3,1,17,
619,17,14,4,0,
619,17,26,1,37,
619,17,67,2,0,
619,17,92,4,0,
619,17,96,1,5,
619,17,104,4,0,
619,17,115,4,0,
619,17,129,1,21,
619,17,136,1,50,
619,17,156,4,0,
619,17,157,4,0,
619,17,164,4,0,
619,17,179,1,57,
619,17,182,4,0,
619,17,197,1,9,
619,17,203,2,0,
619,17,207,4,0,
619,17,213,4,0,
619,17,214,4,0,
619,17,216,4,0,
619,17,218,4,0,
619,17,226,2,0,
619,17,233,2,0,
619,17,237,4,0,
619,17,240,4,0,
619,17,241,4,0,
619,17,244,4,0,
619,17,252,1,13,
619,17,263,4,0,
619,17,265,2,0,
619,17,269,4,0,
619,17,280,4,0,
619,17,282,2,0,
619,17,317,4,0,
619,17,332,4,0,
619,17,339,4,0,
619,17,340,1,49,
619,17,347,1,25,
619,17,347,4,0,
619,17,364,2,0,
619,17,369,1,41,
619,17,369,4,0,
619,17,371,4,0,
619,17,374,4,0,
619,17,382,2,0,
619,17,395,1,29,
619,17,396,1,61,
619,17,398,4,0,
619,17,409,1,33,
619,17,411,4,0,
619,17,444,4,0,
619,17,447,4,0,
619,17,490,4,0,
619,17,496,4,0,
619,17,501,1,45,
619,17,502,2,0,
619,17,512,4,0,
619,17,526,4,0,
619,17,590,4,0,
619,18,1,1,1,
619,18,3,1,17,
619,18,14,4,0,
619,18,26,1,37,
619,18,67,2,0,
619,18,92,4,0,
619,18,96,1,5,
619,18,104,4,0,
619,18,115,4,0,
619,18,129,1,21,
619,18,136,1,50,
619,18,156,4,0,
619,18,157,4,0,
619,18,164,4,0,
619,18,179,1,57,
619,18,182,4,0,
619,18,197,1,9,
619,18,203,2,0,
619,18,207,4,0,
619,18,213,4,0,
619,18,214,4,0,
619,18,216,4,0,
619,18,218,4,0,
619,18,226,2,0,
619,18,233,2,0,
619,18,237,4,0,
619,18,240,4,0,
619,18,241,4,0,
619,18,244,4,0,
619,18,252,1,13,
619,18,263,4,0,
619,18,265,2,0,
619,18,269,4,0,
619,18,280,4,0,
619,18,282,2,0,
619,18,317,4,0,
619,18,332,4,0,
619,18,339,4,0,
619,18,340,1,49,
619,18,347,1,25,
619,18,347,4,0,
619,18,364,2,0,
619,18,369,1,41,
619,18,369,4,0,
619,18,371,4,0,
619,18,374,4,0,
619,18,382,2,0,
619,18,395,1,29,
619,18,396,1,61,
619,18,398,4,0,
619,18,409,1,33,
619,18,411,4,0,
619,18,444,4,0,
619,18,447,4,0,
619,18,490,4,0,
619,18,496,4,0,
619,18,501,1,45,
619,18,502,2,0,
619,18,512,4,0,
619,18,526,4,0,
619,18,590,4,0,
620,11,1,1,1,1
620,11,3,1,17,
620,11,14,4,0,
620,11,26,1,37,
620,11,63,4,0,
620,11,70,4,0,
620,11,91,4,0,
620,11,92,4,0,
620,11,96,1,1,2
620,11,96,1,5,
620,11,104,4,0,
620,11,115,4,0,
620,11,129,1,21,
620,11,136,1,56,
620,11,156,4,0,
620,11,157,4,0,
620,11,164,4,0,
620,11,179,1,63,
620,11,182,4,0,
620,11,197,1,1,3
620,11,197,1,9,
620,11,207,4,0,
620,11,213,4,0,
620,11,216,4,0,
620,11,218,4,0,
620,11,237,4,0,
620,11,240,4,0,
620,11,241,4,0,
620,11,244,4,0,
620,11,249,4,0,
620,11,252,1,1,4
620,11,252,1,13,
620,11,263,4,0,
620,11,269,4,0,
620,11,280,4,0,
620,11,317,4,0,
620,11,332,4,0,
620,11,339,4,0,
620,11,340,1,49,
620,11,347,1,25,
620,11,347,4,0,
620,11,369,1,41,
620,11,369,4,0,
620,11,371,4,0,
620,11,374,4,0,
620,11,395,1,29,
620,11,396,1,70,
620,11,398,4,0,
620,11,409,1,33,
620,11,411,4,0,
620,11,416,4,0,
620,11,444,4,0,
620,11,447,4,0,
620,11,469,1,45,
620,11,490,4,0,
620,11,496,4,0,
620,11,512,4,0,
620,11,514,4,0,
620,11,526,4,0,
620,14,1,1,1,1
620,14,3,1,17,
620,14,14,4,0,
620,14,26,1,37,
620,14,63,4,0,
620,14,67,3,0,
620,14,70,4,0,
620,14,91,4,0,
620,14,92,4,0,
620,14,96,1,1,2
620,14,96,1,5,
620,14,104,4,0,
620,14,115,4,0,
620,14,129,1,21,
620,14,136,1,56,
620,14,156,4,0,
620,14,157,4,0,
620,14,164,4,0,
620,14,173,3,0,
620,14,179,1,63,
620,14,182,4,0,
620,14,197,1,1,3
620,14,197,1,9,
620,14,207,4,0,
620,14,213,4,0,
620,14,214,3,0,
620,14,216,4,0,
620,14,218,4,0,
620,14,237,4,0,
620,14,240,4,0,
620,14,241,4,0,
620,14,244,4,0,
620,14,249,4,0,
620,14,252,1,1,4
620,14,252,1,13,
620,14,263,4,0,
620,14,269,4,0,
620,14,270,3,0,
620,14,272,3,0,
620,14,280,4,0,
620,14,282,3,0,
620,14,317,4,0,
620,14,332,4,0,
620,14,339,4,0,
620,14,340,1,49,
620,14,340,3,0,
620,14,347,1,25,
620,14,347,4,0,
620,14,369,1,41,
620,14,369,4,0,
620,14,371,4,0,
620,14,374,4,0,
620,14,395,1,29,
620,14,396,1,70,
620,14,398,4,0,
620,14,409,1,33,
620,14,409,3,0,
620,14,411,4,0,
620,14,416,4,0,
620,14,444,4,0,
620,14,447,4,0,
620,14,469,1,45,
620,14,490,4,0,
620,14,496,4,0,
620,14,512,4,0,
620,14,514,4,0,
620,14,526,4,0,
620,14,530,3,0,
620,15,1,1,1,3
620,15,3,1,17,
620,15,14,4,0,
620,15,26,1,37,
620,15,63,4,0,
620,15,70,4,0,
620,15,91,4,0,
620,15,92,4,0,
620,15,96,1,1,4
620,15,96,1,5,
620,15,104,4,0,
620,15,115,4,0,
620,15,129,1,21,
620,15,136,1,56,
620,15,156,4,0,
620,15,157,4,0,
620,15,164,4,0,
620,15,179,1,1,2
620,15,179,1,63,
620,15,182,4,0,
620,15,197,1,1,5
620,15,197,1,9,
620,15,207,4,0,
620,15,213,4,0,
620,15,214,4,0,
620,15,216,4,0,
620,15,218,4,0,
620,15,237,4,0,
620,15,240,4,0,
620,15,241,4,0,
620,15,244,4,0,
620,15,249,4,0,
620,15,252,1,1,6
620,15,252,1,13,
620,15,263,4,0,
620,15,269,4,0,
620,15,280,4,0,
620,15,317,4,0,
620,15,332,4,0,
620,15,339,4,0,
620,15,340,1,49,
620,15,347,1,25,
620,15,347,4,0,
620,15,369,1,41,
620,15,369,4,0,
620,15,371,4,0,
620,15,374,4,0,
620,15,395,1,29,
620,15,396,1,1,1
620,15,396,1,70,
620,15,398,4,0,
620,15,409,1,33,
620,15,411,4,0,
620,15,416,4,0,
620,15,444,4,0,
620,15,447,4,0,
620,15,469,1,45,
620,15,490,4,0,
620,15,496,4,0,
620,15,512,4,0,
620,15,514,4,0,
620,15,590,4,0,
620,15,612,4,0,
620,16,1,1,1,3
620,16,3,1,17,1
620,16,14,4,0,
620,16,26,1,37,1
620,16,63,4,0,
620,16,67,3,0,
620,16,70,4,0,
620,16,91,4,0,
620,16,92,4,0,
620,16,96,1,1,4
620,16,96,1,5,1
620,16,104,4,0,
620,16,115,4,0,
620,16,129,1,21,1
620,16,136,1,56,1
620,16,156,4,0,
620,16,157,4,0,
620,16,164,4,0,
620,16,173,3,0,
620,16,179,1,1,2
620,16,179,1,63,1
620,16,182,4,0,
620,16,197,1,1,5
620,16,197,1,9,1
620,16,207,4,0,
620,16,213,4,0,
620,16,214,4,0,
620,16,216,4,0,
620,16,218,4,0,
620,16,237,4,0,
620,16,240,4,0,
620,16,241,4,0,
620,16,244,4,0,
620,16,249,4,0,
620,16,252,1,1,6
620,16,252,1,13,1
620,16,263,4,0,
620,16,264,3,0,
620,16,269,4,0,
620,16,270,3,0,
620,16,272,3,0,
620,16,280,4,0,
620,16,282,3,0,
620,16,290,4,0,
620,16,317,4,0,
620,16,332,4,0,
620,16,339,4,0,
620,16,340,1,49,1
620,16,340,3,0,
620,16,347,1,25,1
620,16,347,4,0,
620,16,369,1,41,1
620,16,369,4,0,
620,16,371,4,0,
620,16,374,4,0,
620,16,395,1,29,1
620,16,396,1,1,1
620,16,396,1,70,1
620,16,398,4,0,
620,16,409,1,33,1
620,16,409,3,0,
620,16,411,4,0,
620,16,416,4,0,
620,16,444,4,0,
620,16,447,4,0,
620,16,469,1,45,1
620,16,490,4,0,
620,16,496,4,0,
620,16,512,4,0,
620,16,514,4,0,
620,16,530,3,0,
620,16,590,4,0,
620,16,612,4,0,
620,17,1,1,1,3
620,17,3,1,17,
620,17,14,4,0,
620,17,26,1,37,
620,17,63,4,0,
620,17,92,4,0,
620,17,96,1,1,4
620,17,96,1,5,
620,17,104,4,0,
620,17,115,4,0,
620,17,129,1,21,
620,17,136,1,56,
620,17,156,4,0,
620,17,157,4,0,
620,17,164,4,0,
620,17,179,1,1,2
620,17,179,1,63,
620,17,182,4,0,
620,17,197,1,1,5
620,17,197,1,9,
620,17,207,4,0,
620,17,213,4,0,
620,17,214,4,0,
620,17,216,4,0,
620,17,218,4,0,
620,17,237,4,0,
620,17,240,4,0,
620,17,241,4,0,
620,17,244,4,0,
620,17,252,1,1,6
620,17,252,1,13,
620,17,263,4,0,
620,17,269,4,0,
620,17,280,4,0,
620,17,317,4,0,
620,17,332,4,0,
620,17,339,4,0,
620,17,340,1,49,
620,17,347,1,25,
620,17,347,4,0,
620,17,369,1,41,
620,17,369,4,0,
620,17,371,4,0,
620,17,374,4,0,
620,17,395,1,29,
620,17,396,1,1,1
620,17,396,1,70,
620,17,398,4,0,
620,17,409,1,33,
620,17,411,4,0,
620,17,416,4,0,
620,17,444,4,0,
620,17,447,4,0,
620,17,469,1,45,
620,17,490,4,0,
620,17,496,4,0,
620,17,512,4,0,
620,17,526,4,0,
620,17,590,4,0,
620,18,1,1,1,3
620,18,3,1,17,
620,18,14,4,0,
620,18,26,1,37,
620,18,63,4,0,
620,18,92,4,0,
620,18,96,1,1,4
620,18,96,1,5,
620,18,104,4,0,
620,18,115,4,0,
620,18,129,1,21,
620,18,136,1,56,
620,18,156,4,0,
620,18,157,4,0,
620,18,164,4,0,
620,18,179,1,1,2
620,18,179,1,63,
620,18,182,4,0,
620,18,197,1,1,5
620,18,197,1,9,
620,18,207,4,0,
620,18,213,4,0,
620,18,214,4,0,
620,18,216,4,0,
620,18,218,4,0,
620,18,237,4,0,
620,18,240,4,0,
620,18,241,4,0,
620,18,244,4,0,
620,18,252,1,1,6
620,18,252,1,13,
620,18,263,4,0,
620,18,269,4,0,
620,18,280,4,0,
620,18,317,4,0,
620,18,332,4,0,
620,18,339,4,0,
620,18,340,1,49,
620,18,347,1,25,
620,18,347,4,0,
620,18,369,1,41,
620,18,369,4,0,
620,18,371,4,0,
620,18,374,4,0,
620,18,395,1,29,
620,18,396,1,1,1
620,18,396,1,70,
620,18,398,4,0,
620,18,409,1,33,
620,18,411,4,0,
620,18,416,4,0,
620,18,444,4,0,
620,18,447,4,0,
620,18,469,1,45,
620,18,490,4,0,
620,18,496,4,0,
620,18,512,4,0,
620,18,526,4,0,
620,18,590,4,0,
621,11,10,1,1,2
621,11,15,4,0,
621,11,43,1,1,1
621,11,44,1,9,
621,11,46,4,0,
621,11,53,4,0,
621,11,57,4,0,
621,11,63,4,0,
621,11,70,4,0,
621,11,82,1,18,
621,11,89,4,0,
621,11,91,4,0,
621,11,92,4,0,
621,11,104,4,0,
621,11,137,2,0,
621,11,156,4,0,
621,11,157,4,0,
621,11,163,1,21,
621,11,164,4,0,
621,11,182,4,0,
621,11,184,1,13,
621,11,185,2,0,
621,11,188,4,0,
621,11,200,1,62,
621,11,207,4,0,
621,11,213,4,0,
621,11,216,4,0,
621,11,218,4,0,
621,11,228,2,0,
621,11,231,2,0,
621,11,232,2,0,
621,11,237,4,0,
621,11,240,4,0,
621,11,241,4,0,
621,11,242,1,25,
621,11,249,4,0,
621,11,259,4,0,
621,11,263,4,0,
621,11,269,4,0,
621,11,276,1,55,
621,11,279,1,35,
621,11,289,2,0,
621,11,306,2,0,
621,11,317,4,0,
621,11,332,4,0,
621,11,337,1,27,
621,11,337,4,0,
621,11,342,2,0,
621,11,371,4,0,
621,11,374,4,0,
621,11,389,2,0,
621,11,400,1,40,
621,11,411,4,0,
621,11,416,4,0,
621,11,421,4,0,
621,11,422,2,0,
621,11,424,2,0,
621,11,430,4,0,
621,11,431,1,49,
621,11,434,3,0,
621,11,451,4,0,
621,11,468,1,5,
621,11,468,4,0,
621,11,479,4,0,
621,11,496,4,0,
621,11,498,1,31,
621,11,510,4,0,
621,11,514,4,0,
621,11,523,4,0,
621,11,525,1,45,
621,11,525,4,0,
621,11,555,4,0,
621,14,7,3,0,
621,14,9,3,0,
621,14,10,1,1,2
621,14,15,4,0,
621,14,43,1,1,1
621,14,44,1,9,
621,14,46,4,0,
621,14,53,4,0,
621,14,57,4,0,
621,14,63,4,0,
621,14,70,4,0,
621,14,82,1,18,
621,14,89,4,0,
621,14,91,4,0,
621,14,92,4,0,
621,14,104,4,0,
621,14,137,2,0,
621,14,156,4,0,
621,14,157,4,0,
621,14,163,1,21,
621,14,164,4,0,
621,14,173,3,0,
621,14,182,4,0,
621,14,184,1,13,
621,14,185,2,0,
621,14,188,4,0,
621,14,200,1,62,
621,14,200,3,0,
621,14,207,4,0,
621,14,213,4,0,
621,14,214,3,0,
621,14,216,4,0,
621,14,218,4,0,
621,14,228,2,0,
621,14,231,2,0,
621,14,231,3,0,
621,14,232,2,0,
621,14,237,4,0,
621,14,240,4,0,
621,14,241,4,0,
621,14,242,1,25,
621,14,249,4,0,
621,14,257,3,0,
621,14,259,4,0,
621,14,263,4,0,
621,14,269,4,0,
621,14,276,1,55,
621,14,276,3,0,
621,14,279,1,35,
621,14,289,2,0,
621,14,289,3,0,
621,14,306,2,0,
621,14,317,4,0,
621,14,332,4,0,
621,14,337,1,27,
621,14,337,4,0,
621,14,342,2,0,
621,14,371,4,0,
621,14,374,4,0,
621,14,389,2,0,
621,14,399,3,0,
621,14,400,1,40,
621,14,401,3,0,
621,14,406,3,0,
621,14,411,4,0,
621,14,416,4,0,
621,14,421,4,0,
621,14,422,2,0,
621,14,424,2,0,
621,14,430,4,0,
621,14,431,1,49,
621,14,434,3,0,
621,14,441,3,0,
621,14,442,3,0,
621,14,446,3,0,
621,14,451,4,0,
621,14,468,1,5,
621,14,468,4,0,
621,14,479,4,0,
621,14,496,4,0,
621,14,498,1,31,
621,14,510,4,0,
621,14,514,4,0,
621,14,523,4,0,
621,14,525,1,45,
621,14,525,4,0,
621,14,555,4,0,
621,15,10,1,1,2
621,15,15,4,0,
621,15,43,1,1,1
621,15,44,1,9,
621,15,46,4,0,
621,15,53,4,0,
621,15,57,4,0,
621,15,63,4,0,
621,15,70,4,0,
621,15,82,1,18,
621,15,89,4,0,
621,15,91,4,0,
621,15,92,4,0,
621,15,104,4,0,
621,15,137,2,0,
621,15,156,4,0,
621,15,157,4,0,
621,15,163,1,21,
621,15,164,4,0,
621,15,182,4,0,
621,15,184,1,13,
621,15,185,2,0,
621,15,188,4,0,
621,15,200,1,62,
621,15,207,4,0,
621,15,213,4,0,
621,15,214,4,0,
621,15,216,4,0,
621,15,218,4,0,
621,15,228,2,0,
621,15,231,2,0,
621,15,232,2,0,
621,15,237,4,0,
621,15,240,4,0,
621,15,241,4,0,
621,15,242,1,25,
621,15,249,4,0,
621,15,259,4,0,
621,15,263,4,0,
621,15,269,4,0,
621,15,276,1,55,
621,15,279,1,35,
621,15,289,2,0,
621,15,306,2,0,
621,15,317,4,0,
621,15,332,4,0,
621,15,337,1,27,
621,15,337,4,0,
621,15,342,2,0,
621,15,371,4,0,
621,15,374,4,0,
621,15,389,2,0,
621,15,399,4,0,
621,15,400,1,40,
621,15,411,4,0,
621,15,416,4,0,
621,15,421,4,0,
621,15,422,2,0,
621,15,424,2,0,
621,15,430,4,0,
621,15,431,1,49,
621,15,434,3,0,
621,15,451,4,0,
621,15,468,1,5,
621,15,468,4,0,
621,15,479,4,0,
621,15,496,4,0,
621,15,498,1,31,
621,15,510,4,0,
621,15,514,4,0,
621,15,523,4,0,
621,15,525,1,45,
621,15,525,4,0,
621,15,555,4,0,
621,15,590,4,0,
621,15,612,4,0,
621,16,7,3,0,
621,16,9,3,0,
621,16,10,1,1,2
621,16,15,4,0,
621,16,43,1,1,1
621,16,44,1,9,1
621,16,46,4,0,
621,16,53,4,0,
621,16,57,4,0,
621,16,63,4,0,
621,16,70,4,0,
621,16,82,1,18,1
621,16,89,4,0,
621,16,91,4,0,
621,16,92,4,0,
621,16,104,4,0,
621,16,137,2,0,
621,16,156,4,0,
621,16,157,4,0,
621,16,163,1,21,1
621,16,164,4,0,
621,16,173,3,0,
621,16,182,4,0,
621,16,184,1,13,1
621,16,185,2,0,
621,16,188,4,0,
621,16,200,1,62,1
621,16,200,3,0,
621,16,207,4,0,
621,16,213,4,0,
621,16,214,4,0,
621,16,216,4,0,
621,16,218,4,0,
621,16,228,2,0,
621,16,231,2,0,
621,16,231,3,0,
621,16,232,2,0,
621,16,237,4,0,
621,16,240,4,0,
621,16,241,4,0,
621,16,242,1,25,1
621,16,249,4,0,
621,16,257,3,0,
621,16,259,4,0,
621,16,263,4,0,
621,16,269,4,0,
621,16,276,1,55,1
621,16,276,3,0,
621,16,279,1,35,1
621,16,289,2,0,
621,16,289,3,0,
621,16,290,4,0,
621,16,306,2,0,
621,16,317,4,0,
621,16,332,4,0,
621,16,337,1,27,1
621,16,337,4,0,
621,16,342,2,0,
621,16,351,3,0,
621,16,371,4,0,
621,16,374,4,0,
621,16,389,2,0,
621,16,399,4,0,
621,16,400,1,40,1
621,16,401,3,0,
621,16,406,3,0,
621,16,411,4,0,
621,16,416,4,0,
621,16,421,4,0,
621,16,422,2,0,
621,16,424,2,0,
621,16,430,4,0,
621,16,431,1,49,1
621,16,434,3,0,
621,16,441,3,0,
621,16,442,3,0,
621,16,446,3,0,
621,16,451,4,0,
621,16,468,1,5,1
621,16,468,4,0,
621,16,479,4,0,
621,16,496,4,0,
621,16,498,1,31,1
621,16,510,4,0,
621,16,514,4,0,
621,16,523,4,0,
621,16,525,1,45,1
621,16,525,4,0,
621,16,555,4,0,
621,16,590,4,0,
621,16,612,4,0,
621,17,10,1,1,2
621,17,43,1,1,1
621,17,44,1,9,
621,17,46,4,0,
621,17,53,4,0,
621,17,57,4,0,
621,17,63,4,0,
621,17,82,1,18,
621,17,89,4,0,
621,17,92,4,0,
621,17,104,4,0,
621,17,137,2,0,
621,17,156,4,0,
621,17,157,4,0,
621,17,163,1,21,
621,17,164,4,0,
621,17,182,4,0,
621,17,184,1,13,
621,17,185,2,0,
621,17,188,4,0,
621,17,200,1,62,
621,17,207,4,0,
621,17,213,4,0,
621,17,214,4,0,
621,17,216,4,0,
621,17,218,4,0,
621,17,228,2,0,
621,17,231,2,0,
621,17,232,2,0,
621,17,237,4,0,
621,17,240,4,0,
621,17,241,4,0,
621,17,242,1,25,
621,17,259,4,0,
621,17,263,4,0,
621,17,269,4,0,
621,17,276,1,55,
621,17,279,1,35,
621,17,289,2,0,
621,17,306,2,0,
621,17,317,4,0,
621,17,332,4,0,
621,17,337,1,27,
621,17,337,4,0,
621,17,342,2,0,
621,17,371,4,0,
621,17,374,4,0,
621,17,389,2,0,
621,17,399,4,0,
621,17,400,1,40,
621,17,411,4,0,
621,17,416,4,0,
621,17,421,4,0,
621,17,422,2,0,
621,17,424,2,0,
621,17,430,4,0,
621,17,431,1,49,
621,17,434,3,0,
621,17,451,4,0,
621,17,468,1,5,
621,17,479,4,0,
621,17,496,4,0,
621,17,498,1,31,
621,17,523,4,0,
621,17,525,1,45,
621,17,525,4,0,
621,17,555,4,0,
621,17,590,4,0,
621,18,10,1,1,2
621,18,43,1,1,1
621,18,44,1,9,
621,18,46,4,0,
621,18,53,4,0,
621,18,57,4,0,
621,18,63,4,0,
621,18,82,1,18,
621,18,89,4,0,
621,18,92,4,0,
621,18,104,4,0,
621,18,137,2,0,
621,18,156,4,0,
621,18,157,4,0,
621,18,163,1,21,
621,18,164,4,0,
621,18,182,4,0,
621,18,184,1,13,
621,18,185,2,0,
621,18,188,4,0,
621,18,200,1,62,
621,18,207,4,0,
621,18,213,4,0,
621,18,214,4,0,
621,18,216,4,0,
621,18,218,4,0,
621,18,228,2,0,
621,18,231,2,0,
621,18,232,2,0,
621,18,237,4,0,
621,18,240,4,0,
621,18,241,4,0,
621,18,242,1,25,
621,18,259,4,0,
621,18,263,4,0,
621,18,269,4,0,
621,18,276,1,55,
621,18,279,1,35,
621,18,289,2,0,
621,18,306,2,0,
621,18,317,4,0,
621,18,332,4,0,
621,18,337,1,27,
621,18,337,4,0,
621,18,342,2,0,
621,18,371,4,0,
621,18,374,4,0,
621,18,389,2,0,
621,18,399,4,0,
621,18,400,1,40,
621,18,411,4,0,
621,18,416,4,0,
621,18,421,4,0,
621,18,422,2,0,
621,18,424,2,0,
621,18,430,4,0,
621,18,431,1,49,
621,18,434,3,0,
621,18,451,4,0,
621,18,468,1,5,
621,18,479,4,0,
621,18,496,4,0,
621,18,498,1,31,
621,18,523,4,0,
621,18,525,1,45,
621,18,525,4,0,
621,18,555,4,0,
621,18,590,4,0,
622,11,1,1,1,1
622,11,5,1,21,
622,11,58,4,0,
622,11,70,4,0,
622,11,89,1,45,
622,11,89,4,0,
622,11,92,4,0,
622,11,94,4,0,
622,11,101,1,35,
622,11,104,4,0,
622,11,111,1,1,3
622,11,148,4,0,
622,11,156,4,0,
622,11,157,4,0,
622,11,164,4,0,
622,11,168,4,0,
622,11,174,1,40,
622,11,182,4,0,
622,11,189,1,5,
622,11,205,1,9,
622,11,207,4,0,
622,11,216,4,0,
622,11,218,4,0,
622,11,219,4,0,
622,11,222,1,25,
622,11,223,1,30,
622,11,237,4,0,
622,11,240,4,0,
622,11,247,4,0,
622,11,249,4,0,
622,11,263,4,0,
622,11,264,1,55,
622,11,280,4,0,
622,11,310,1,1,2
622,11,317,4,0,
622,11,325,1,13,
622,11,334,1,17,
622,11,359,1,50,
622,11,360,4,0,
622,11,374,4,0,
622,11,397,4,0,
622,11,411,4,0,
622,11,447,4,0,
622,11,477,4,0,
622,11,490,4,0,
622,11,496,4,0,
622,11,523,4,0,
622,14,1,1,1,1
622,14,5,1,21,
622,14,7,3,0,
622,14,8,3,0,
622,14,9,3,0,
622,14,58,4,0,
622,14,67,3,0,
622,14,70,4,0,
622,14,89,1,45,
622,14,89,4,0,
622,14,92,4,0,
622,14,94,4,0,
622,14,101,1,35,
622,14,104,4,0,
622,14,111,1,1,3
622,14,148,4,0,
622,14,156,4,0,
622,14,157,4,0,
622,14,164,4,0,
622,14,168,4,0,
622,14,173,3,0,
622,14,174,1,40,
622,14,182,4,0,
622,14,189,1,5,
622,14,196,3,0,
622,14,205,1,9,
622,14,207,4,0,
622,14,214,3,0,
622,14,216,4,0,
622,14,218,4,0,
622,14,219,4,0,
622,14,222,1,25,
622,14,223,1,30,
622,14,237,4,0,
622,14,240,4,0,
622,14,247,4,0,
622,14,249,4,0,
622,14,263,4,0,
622,14,264,1,55,
622,14,276,3,0,
622,14,277,3,0,
622,14,280,4,0,
622,14,310,1,1,2
622,14,317,4,0,
622,14,324,3,0,
622,14,325,1,13,
622,14,334,1,17,
622,14,334,3,0,
622,14,335,3,0,
622,14,356,3,0,
622,14,359,1,50,
622,14,360,4,0,
622,14,374,4,0,
622,14,397,4,0,
622,14,409,3,0,
622,14,411,4,0,
622,14,414,3,0,
622,14,446,3,0,
622,14,447,4,0,
622,14,477,4,0,
622,14,490,4,0,
622,14,496,4,0,
622,14,523,4,0,
622,15,1,1,1,1
622,15,5,1,21,
622,15,58,4,0,
622,15,70,4,0,
622,15,89,1,45,
622,15,89,4,0,
622,15,92,4,0,
622,15,94,4,0,
622,15,101,1,35,
622,15,104,4,0,
622,15,111,1,1,3
622,15,148,4,0,
622,15,156,4,0,
622,15,157,4,0,
622,15,164,4,0,
622,15,168,4,0,
622,15,174,1,40,
622,15,182,4,0,
622,15,189,1,5,
622,15,205,1,9,
622,15,207,4,0,
622,15,214,4,0,
622,15,216,4,0,
622,15,218,4,0,
622,15,219,4,0,
622,15,222,1,25,
622,15,223,1,30,
622,15,237,4,0,
622,15,240,4,0,
622,15,247,4,0,
622,15,249,4,0,
622,15,263,4,0,
622,15,264,1,55,
622,15,280,4,0,
622,15,310,1,1,2
622,15,317,4,0,
622,15,325,1,13,
622,15,334,1,17,
622,15,359,1,50,
622,15,360,4,0,
622,15,374,4,0,
622,15,397,4,0,
622,15,411,4,0,
622,15,447,4,0,
622,15,490,4,0,
622,15,496,4,0,
622,15,523,4,0,
622,15,590,4,0,
622,15,612,4,0,
622,16,1,1,1,1
622,16,5,1,21,1
622,16,7,3,0,
622,16,8,3,0,
622,16,9,3,0,
622,16,58,4,0,
622,16,67,3,0,
622,16,70,4,0,
622,16,89,1,45,1
622,16,89,4,0,
622,16,92,4,0,
622,16,94,4,0,
622,16,101,1,35,1
622,16,104,4,0,
622,16,111,1,1,3
622,16,148,4,0,
622,16,156,4,0,
622,16,157,4,0,
622,16,164,4,0,
622,16,168,4,0,
622,16,173,3,0,
622,16,174,1,40,1
622,16,182,4,0,
622,16,189,1,5,1
622,16,196,3,0,
622,16,205,1,9,1
622,16,207,4,0,
622,16,214,4,0,
622,16,216,4,0,
622,16,218,4,0,
622,16,219,4,0,
622,16,222,1,25,1
622,16,223,1,30,1
622,16,237,4,0,
622,16,240,4,0,
622,16,247,4,0,
622,16,249,4,0,
622,16,263,4,0,
622,16,264,1,55,1
622,16,264,3,0,
622,16,276,3,0,
622,16,277,3,0,
622,16,280,4,0,
622,16,290,4,0,
622,16,310,1,1,2
622,16,317,4,0,
622,16,324,3,0,
622,16,325,1,13,1
622,16,334,1,17,1
622,16,334,3,0,
622,16,335,3,0,
622,16,351,3,0,
622,16,356,3,0,
622,16,359,1,50,1
622,16,360,4,0,
622,16,374,4,0,
622,16,397,4,0,
622,16,409,3,0,
622,16,411,4,0,
622,16,414,3,0,
622,16,446,3,0,
622,16,447,4,0,
622,16,490,4,0,
622,16,496,4,0,
622,16,523,4,0,
622,16,590,4,0,
622,16,612,4,0,
622,17,1,1,1,1
622,17,5,1,25,
622,17,58,4,0,
622,17,89,1,50,
622,17,89,4,0,
622,17,92,4,0,
622,17,94,4,0,
622,17,101,1,40,
622,17,104,4,0,
622,17,111,1,1,3
622,17,156,4,0,
622,17,157,4,0,
622,17,164,4,0,
622,17,168,4,0,
622,17,174,1,45,
622,17,182,4,0,
622,17,189,1,5,
622,17,205,1,9,
622,17,207,4,0,
622,17,214,4,0,
622,17,216,4,0,
622,17,218,4,0,
622,17,219,4,0,
622,17,222,1,30,
622,17,223,1,35,
622,17,237,4,0,
622,17,240,4,0,
622,17,247,4,0,
622,17,263,4,0,
622,17,264,1,61,
622,17,280,4,0,
622,17,310,1,1,2
622,17,317,4,0,
622,17,325,1,13,
622,17,334,1,17,
622,17,359,1,55,
622,17,360,4,0,
622,17,374,4,0,
622,17,397,4,0,
622,17,411,4,0,
622,17,447,4,0,
622,17,490,4,0,
622,17,496,4,0,
622,17,523,4,0,
622,17,590,4,0,
622,17,707,1,21,
622,18,1,1,1,1
622,18,5,1,25,
622,18,58,4,0,
622,18,89,1,50,
622,18,89,4,0,
622,18,92,4,0,
622,18,94,4,0,
622,18,101,1,40,
622,18,104,4,0,
622,18,111,1,1,3
622,18,156,4,0,
622,18,157,4,0,
622,18,164,4,0,
622,18,168,4,0,
622,18,174,1,45,
622,18,182,4,0,
622,18,189,1,5,
622,18,205,1,9,
622,18,207,4,0,
622,18,214,4,0,
622,18,216,4,0,
622,18,218,4,0,
622,18,219,4,0,
622,18,222,1,30,
622,18,223,1,35,
622,18,237,4,0,
622,18,240,4,0,
622,18,247,4,0,
622,18,263,4,0,
622,18,264,1,61,
622,18,280,4,0,
622,18,310,1,1,2
622,18,317,4,0,
622,18,325,1,13,
622,18,334,1,17,
622,18,359,1,55,
622,18,360,4,0,
622,18,374,4,0,
622,18,397,4,0,
622,18,411,4,0,
622,18,447,4,0,
622,18,490,4,0,
622,18,496,4,0,
622,18,523,4,0,
622,18,590,4,0,
622,18,707,1,21,
623,11,1,1,1,1
623,11,5,1,21,
623,11,19,4,0,
623,11,58,4,0,
623,11,63,4,0,
623,11,70,4,0,
623,11,76,4,0,
623,11,85,4,0,
623,11,89,1,50,
623,11,89,4,0,
623,11,92,4,0,
623,11,94,4,0,
623,11,101,1,35,
623,11,104,4,0,
623,11,111,1,1,3
623,11,148,4,0,
623,11,156,4,0,
623,11,157,4,0,
623,11,164,4,0,
623,11,168,4,0,
623,11,174,1,40,
623,11,182,4,0,
623,11,189,1,1,4
623,11,189,1,5,
623,11,205,1,9,
623,11,207,4,0,
623,11,216,4,0,
623,11,218,4,0,
623,11,219,4,0,
623,11,222,1,25,
623,11,223,1,30,
623,11,237,4,0,
623,11,240,4,0,
623,11,247,4,0,
623,11,249,4,0,
623,11,263,4,0,
623,11,264,1,70,
623,11,280,4,0,
623,11,310,1,1,2
623,11,317,4,0,
623,11,325,1,13,
623,11,334,1,17,
623,11,359,1,60,
623,11,360,4,0,
623,11,374,4,0,
623,11,397,4,0,
623,11,411,4,0,
623,11,416,4,0,
623,11,430,4,0,
623,11,444,4,0,
623,11,447,4,0,
623,11,451,4,0,
623,11,477,4,0,
623,11,484,1,43,
623,11,490,4,0,
623,11,496,4,0,
623,11,523,4,0,
623,14,1,1,1,1
623,14,5,1,21,
623,14,7,3,0,
623,14,8,3,0,
623,14,9,3,0,
623,14,19,4,0,
623,14,58,4,0,
623,14,63,4,0,
623,14,67,3,0,
623,14,70,4,0,
623,14,76,4,0,
623,14,85,4,0,
623,14,89,1,50,
623,14,89,4,0,
623,14,92,4,0,
623,14,94,4,0,
623,14,101,1,35,
623,14,104,4,0,
623,14,111,1,1,3
623,14,148,4,0,
623,14,156,4,0,
623,14,157,4,0,
623,14,164,4,0,
623,14,168,4,0,
623,14,173,3,0,
623,14,174,1,40,
623,14,182,4,0,
623,14,189,1,1,4
623,14,189,1,5,
623,14,196,3,0,
623,14,205,1,9,
623,14,207,4,0,
623,14,214,3,0,
623,14,216,4,0,
623,14,218,4,0,
623,14,219,4,0,
623,14,222,1,25,
623,14,223,1,30,
623,14,237,4,0,
623,14,240,4,0,
623,14,247,4,0,
623,14,249,4,0,
623,14,263,4,0,
623,14,264,1,70,
623,14,276,3,0,
623,14,277,3,0,
623,14,280,4,0,
623,14,310,1,1,2
623,14,317,4,0,
623,14,324,3,0,
623,14,325,1,13,
623,14,334,1,17,
623,14,334,3,0,
623,14,335,3,0,
623,14,356,3,0,
623,14,359,1,60,
623,14,360,4,0,
623,14,374,4,0,
623,14,397,4,0,
623,14,409,3,0,
623,14,411,4,0,
623,14,414,3,0,
623,14,416,4,0,
623,14,428,3,0,
623,14,430,4,0,
623,14,444,4,0,
623,14,446,3,0,
623,14,447,4,0,
623,14,451,4,0,
623,14,477,4,0,
623,14,484,1,43,
623,14,490,4,0,
623,14,496,4,0,
623,14,523,4,0,
623,15,1,1,1,3
623,15,5,1,21,
623,15,19,4,0,
623,15,58,4,0,
623,15,63,4,0,
623,15,70,4,0,
623,15,76,4,0,
623,15,85,4,0,
623,15,89,1,50,
623,15,89,4,0,
623,15,92,4,0,
623,15,94,4,0,
623,15,101,1,35,
623,15,104,4,0,
623,15,111,1,1,5
623,15,148,4,0,
623,15,156,4,0,
623,15,157,4,0,
623,15,164,4,0,
623,15,168,4,0,
623,15,174,1,40,
623,15,182,4,0,
623,15,189,1,1,6
623,15,189,1,5,
623,15,205,1,9,
623,15,207,4,0,
623,15,214,4,0,
623,15,216,4,0,
623,15,218,4,0,
623,15,219,4,0,
623,15,222,1,25,
623,15,223,1,30,
623,15,237,4,0,
623,15,240,4,0,
623,15,247,4,0,
623,15,249,4,0,
623,15,263,4,0,
623,15,264,1,1,2
623,15,264,1,70,
623,15,280,4,0,
623,15,310,1,1,4
623,15,317,4,0,
623,15,325,1,13,
623,15,334,1,17,
623,15,359,1,60,
623,15,360,4,0,
623,15,374,4,0,
623,15,397,4,0,
623,15,411,4,0,
623,15,416,4,0,
623,15,430,4,0,
623,15,444,4,0,
623,15,447,4,0,
623,15,451,4,0,
623,15,484,1,43,
623,15,490,4,0,
623,15,496,4,0,
623,15,523,4,0,
623,15,566,1,1,1
623,15,566,1,75,
623,15,590,4,0,
623,15,612,4,0,
623,16,1,1,1,3
623,16,5,1,21,1
623,16,7,3,0,
623,16,8,3,0,
623,16,9,3,0,
623,16,19,4,0,
623,16,58,4,0,
623,16,63,4,0,
623,16,67,3,0,
623,16,70,4,0,
623,16,76,4,0,
623,16,85,4,0,
623,16,89,1,50,1
623,16,89,4,0,
623,16,92,4,0,
623,16,94,4,0,
623,16,101,1,35,1
623,16,104,4,0,
623,16,111,1,1,5
623,16,148,4,0,
623,16,156,4,0,
623,16,157,4,0,
623,16,164,4,0,
623,16,168,4,0,
623,16,173,3,0,
623,16,174,1,40,1
623,16,182,4,0,
623,16,189,1,1,6
623,16,189,1,5,1
623,16,196,3,0,
623,16,205,1,9,1
623,16,207,4,0,
623,16,214,4,0,
623,16,216,4,0,
623,16,218,4,0,
623,16,219,4,0,
623,16,222,1,25,1
623,16,223,1,30,1
623,16,237,4,0,
623,16,240,4,0,
623,16,247,4,0,
623,16,249,4,0,
623,16,263,4,0,
623,16,264,1,1,2
623,16,264,1,70,1
623,16,264,3,0,
623,16,276,3,0,
623,16,277,3,0,
623,16,280,4,0,
623,16,290,4,0,
623,16,310,1,1,4
623,16,317,4,0,
623,16,324,3,0,
623,16,325,1,13,1
623,16,334,1,17,1
623,16,334,3,0,
623,16,335,3,0,
623,16,351,3,0,
623,16,356,3,0,
623,16,359,1,60,1
623,16,360,4,0,
623,16,374,4,0,
623,16,397,4,0,
623,16,409,3,0,
623,16,411,4,0,
623,16,414,3,0,
623,16,416,4,0,
623,16,428,3,0,
623,16,430,4,0,
623,16,444,4,0,
623,16,446,3,0,
623,16,447,4,0,
623,16,451,4,0,
623,16,484,1,43,1
623,16,490,4,0,
623,16,496,4,0,
623,16,523,4,0,
623,16,566,1,1,1
623,16,566,1,75,1
623,16,590,4,0,
623,16,612,4,0,
623,17,1,1,1,3
623,17,5,1,25,
623,17,19,4,0,
623,17,58,4,0,
623,17,63,4,0,
623,17,76,4,0,
623,17,85,4,0,
623,17,89,1,54,
623,17,89,4,0,
623,17,92,4,0,
623,17,94,4,0,
623,17,101,1,40,
623,17,104,4,0,
623,17,111,1,1,5
623,17,156,4,0,
623,17,157,4,0,
623,17,164,4,0,
623,17,168,4,0,
623,17,174,1,47,
623,17,182,4,0,
623,17,189,1,1,6
623,17,189,1,5,
623,17,205,1,9,
623,17,207,4,0,
623,17,214,4,0,
623,17,216,4,0,
623,17,218,4,0,
623,17,219,4,0,
623,17,222,1,30,
623,17,223,1,35,
623,17,237,4,0,
623,17,240,4,0,
623,17,247,4,0,
623,17,263,4,0,
623,17,264,1,69,
623,17,280,4,0,
623,17,310,1,1,4
623,17,317,4,0,
623,17,325,1,13,
623,17,334,1,17,
623,17,359,1,61,
623,17,360,4,0,
623,17,374,4,0,
623,17,397,4,0,
623,17,411,4,0,
623,17,416,4,0,
623,17,430,4,0,
623,17,444,4,0,
623,17,447,4,0,
623,17,451,4,0,
623,17,484,1,0,
623,17,484,1,1,1
623,17,490,4,0,
623,17,496,4,0,
623,17,523,4,0,
623,17,566,1,76,
623,17,590,4,0,
623,17,667,1,1,2
623,17,707,1,21,
623,18,1,1,1,3
623,18,5,1,25,
623,18,19,4,0,
623,18,58,4,0,
623,18,63,4,0,
623,18,76,4,0,
623,18,85,4,0,
623,18,89,1,54,
623,18,89,4,0,
623,18,92,4,0,
623,18,94,4,0,
623,18,101,1,40,
623,18,104,4,0,
623,18,111,1,1,5
623,18,156,4,0,
623,18,157,4,0,
623,18,164,4,0,
623,18,168,4,0,
623,18,174,1,47,
623,18,182,4,0,
623,18,189,1,1,6
623,18,189,1,5,
623,18,205,1,9,
623,18,207,4,0,
623,18,214,4,0,
623,18,216,4,0,
623,18,218,4,0,
623,18,219,4,0,
623,18,222,1,30,
623,18,223,1,35,
623,18,237,4,0,
623,18,240,4,0,
623,18,247,4,0,
623,18,263,4,0,
623,18,264,1,69,
623,18,280,4,0,
623,18,310,1,1,4
623,18,317,4,0,
623,18,325,1,13,
623,18,334,1,17,
623,18,359,1,61,
623,18,360,4,0,
623,18,374,4,0,
623,18,397,4,0,
623,18,411,4,0,
623,18,416,4,0,
623,18,430,4,0,
623,18,444,4,0,
623,18,447,4,0,
623,18,451,4,0,
623,18,484,1,0,
623,18,484,1,1,1
623,18,490,4,0,
623,18,496,4,0,
623,18,523,4,0,
623,18,566,1,76,
623,18,590,4,0,
623,18,667,1,1,2
623,18,707,1,21,
624,11,10,1,1,
624,11,12,1,62,
624,11,14,1,57,
624,11,14,4,0,
624,11,15,4,0,
624,11,29,2,0,
624,11,43,1,6,
624,11,86,4,0,
624,11,91,4,0,
624,11,92,4,0,
624,11,104,4,0,
624,11,156,4,0,
624,11,163,1,30,
624,11,164,4,0,
624,11,168,4,0,
624,11,182,4,0,
624,11,184,1,22,
624,11,185,1,17,
624,11,201,4,0,
624,11,206,4,0,
624,11,207,4,0,
624,11,210,1,9,
624,11,212,2,0,
624,11,213,4,0,
624,11,216,4,0,
624,11,218,4,0,
624,11,228,2,0,
624,11,232,1,25,
624,11,237,4,0,
624,11,240,4,0,
624,11,249,4,0,
624,11,259,1,14,
624,11,259,4,0,
624,11,263,4,0,
624,11,269,4,0,
624,11,279,2,0,
624,11,280,4,0,
624,11,317,4,0,
624,11,319,1,38,
624,11,332,4,0,
624,11,334,1,46,
624,11,371,4,0,
624,11,372,1,33,
624,11,373,1,41,
624,11,373,4,0,
624,11,374,4,0,
624,11,389,2,0,
624,11,397,4,0,
624,11,398,4,0,
624,11,400,1,49,
624,11,404,4,0,
624,11,421,4,0,
624,11,427,2,0,
624,11,442,1,54,
624,11,446,2,0,
624,11,447,4,0,
624,11,468,4,0,
624,11,490,4,0,
624,11,496,4,0,
624,11,514,4,0,
624,11,555,4,0,
624,14,10,1,1,
624,14,12,1,62,
624,14,14,1,57,
624,14,14,4,0,
624,14,15,4,0,
624,14,29,2,0,
624,14,43,1,6,
624,14,67,3,0,
624,14,86,4,0,
624,14,91,4,0,
624,14,92,4,0,
624,14,104,4,0,
624,14,156,4,0,
624,14,163,1,30,
624,14,164,4,0,
624,14,168,4,0,
624,14,173,3,0,
624,14,180,3,0,
624,14,182,4,0,
624,14,184,1,22,
624,14,185,1,17,
624,14,201,4,0,
624,14,206,4,0,
624,14,207,4,0,
624,14,210,1,9,
624,14,212,2,0,
624,14,213,4,0,
624,14,214,3,0,
624,14,216,4,0,
624,14,218,4,0,
624,14,228,2,0,
624,14,232,1,25,
624,14,237,4,0,
624,14,240,4,0,
624,14,249,4,0,
624,14,259,1,14,
624,14,259,4,0,
624,14,263,4,0,
624,14,269,4,0,
624,14,272,3,0,
624,14,279,2,0,
624,14,280,4,0,
624,14,282,3,0,
624,14,289,3,0,
624,14,317,4,0,
624,14,319,1,38,
624,14,332,4,0,
624,14,334,1,46,
624,14,334,3,0,
624,14,371,4,0,
624,14,372,1,33,
624,14,373,1,41,
624,14,373,4,0,
624,14,374,4,0,
624,14,389,2,0,
624,14,393,3,0,
624,14,397,4,0,
624,14,398,4,0,
624,14,399,3,0,
624,14,400,1,49,
624,14,404,4,0,
624,14,421,4,0,
624,14,427,2,0,
624,14,442,1,54,
624,14,442,3,0,
624,14,446,2,0,
624,14,446,3,0,
624,14,447,4,0,
624,14,468,4,0,
624,14,490,4,0,
624,14,492,3,0,
624,14,496,4,0,
624,14,514,4,0,
624,14,530,3,0,
624,14,555,4,0,
624,15,10,1,1,
624,15,12,1,62,
624,15,14,1,57,
624,15,14,4,0,
624,15,15,4,0,
624,15,29,2,0,
624,15,43,1,6,
624,15,86,4,0,
624,15,91,4,0,
624,15,92,4,0,
624,15,104,4,0,
624,15,156,4,0,
624,15,163,1,30,
624,15,164,4,0,
624,15,168,4,0,
624,15,182,4,0,
624,15,184,1,22,
624,15,185,1,17,
624,15,201,4,0,
624,15,206,4,0,
624,15,207,4,0,
624,15,210,1,9,
624,15,212,2,0,
624,15,213,4,0,
624,15,214,4,0,
624,15,216,4,0,
624,15,218,4,0,
624,15,228,2,0,
624,15,232,1,25,
624,15,237,4,0,
624,15,240,4,0,
624,15,249,4,0,
624,15,259,1,14,
624,15,259,4,0,
624,15,263,4,0,
624,15,269,4,0,
624,15,279,2,0,
624,15,280,4,0,
624,15,317,4,0,
624,15,319,1,38,
624,15,332,4,0,
624,15,334,1,46,
624,15,371,4,0,
624,15,372,1,33,
624,15,373,1,41,
624,15,373,4,0,
624,15,374,4,0,
624,15,389,2,0,
624,15,397,4,0,
624,15,398,4,0,
624,15,399,4,0,
624,15,400,1,49,
624,15,404,4,0,
624,15,421,4,0,
624,15,427,2,0,
624,15,442,1,54,
624,15,446,2,0,
624,15,447,4,0,
624,15,468,4,0,
624,15,490,4,0,
624,15,496,4,0,
624,15,501,2,0,
624,15,514,4,0,
624,15,555,4,0,
624,15,590,4,0,
624,15,612,4,0,
624,16,10,1,1,1
624,16,12,1,62,1
624,16,14,1,57,1
624,16,14,4,0,
624,16,15,4,0,
624,16,29,2,0,
624,16,43,1,6,1
624,16,67,3,0,
624,16,86,4,0,
624,16,91,4,0,
624,16,92,4,0,
624,16,104,4,0,
624,16,156,4,0,
624,16,163,1,30,1
624,16,164,4,0,
624,16,168,4,0,
624,16,173,3,0,
624,16,180,3,0,
624,16,182,4,0,
624,16,184,1,22,1
624,16,185,1,17,1
624,16,201,4,0,
624,16,206,4,0,
624,16,207,4,0,
624,16,210,1,9,1
624,16,212,2,0,
624,16,213,4,0,
624,16,214,4,0,
624,16,216,4,0,
624,16,218,4,0,
624,16,228,2,0,
624,16,232,1,25,1
624,16,237,4,0,
624,16,240,4,0,
624,16,249,4,0,
624,16,259,1,14,1
624,16,259,4,0,
624,16,263,4,0,
624,16,269,4,0,
624,16,272,3,0,
624,16,279,2,0,
624,16,280,4,0,
624,16,282,3,0,
624,16,289,3,0,
624,16,290,4,0,
624,16,317,4,0,
624,16,319,1,38,1
624,16,332,4,0,
624,16,334,1,46,1
624,16,334,3,0,
624,16,371,4,0,
624,16,372,1,33,1
624,16,373,1,41,1
624,16,373,4,0,
624,16,374,4,0,
624,16,389,2,0,
624,16,393,3,0,
624,16,397,4,0,
624,16,398,4,0,
624,16,399,4,0,
624,16,400,1,49,1
624,16,404,4,0,
624,16,421,4,0,
624,16,427,2,0,
624,16,442,1,54,1
624,16,442,3,0,
624,16,446,2,0,
624,16,446,3,0,
624,16,447,4,0,
624,16,468,4,0,
624,16,490,4,0,
624,16,492,3,0,
624,16,496,4,0,
624,16,501,2,0,
624,16,514,4,0,
624,16,530,3,0,
624,16,555,4,0,
624,16,590,4,0,
624,16,612,4,0,
624,17,10,1,1,
624,17,12,1,62,
624,17,14,1,57,
624,17,14,4,0,
624,17,29,2,0,
624,17,43,1,6,
624,17,86,4,0,
624,17,92,4,0,
624,17,104,4,0,
624,17,156,4,0,
624,17,163,1,30,
624,17,164,4,0,
624,17,168,4,0,
624,17,182,4,0,
624,17,184,1,22,
624,17,185,1,17,
624,17,201,4,0,
624,17,206,4,0,
624,17,207,4,0,
624,17,210,1,9,
624,17,212,2,0,
624,17,213,4,0,
624,17,214,4,0,
624,17,216,4,0,
624,17,218,4,0,
624,17,228,2,0,
624,17,232,1,25,
624,17,237,4,0,
624,17,240,4,0,
624,17,259,1,14,
624,17,259,4,0,
624,17,263,4,0,
624,17,269,4,0,
624,17,279,2,0,
624,17,280,4,0,
624,17,317,4,0,
624,17,319,1,38,
624,17,332,4,0,
624,17,334,1,46,
624,17,371,4,0,
624,17,372,1,33,
624,17,373,1,41,
624,17,373,4,0,
624,17,374,4,0,
624,17,389,2,0,
624,17,397,4,0,
624,17,398,4,0,
624,17,399,4,0,
624,17,400,1,49,
624,17,404,4,0,
624,17,421,4,0,
624,17,427,2,0,
624,17,442,1,54,
624,17,446,2,0,
624,17,447,4,0,
624,17,490,4,0,
624,17,496,4,0,
624,17,501,2,0,
624,17,555,4,0,
624,17,590,4,0,
624,18,10,1,1,
624,18,12,1,62,
624,18,14,1,57,
624,18,14,4,0,
624,18,29,2,0,
624,18,43,1,6,
624,18,86,4,0,
624,18,92,4,0,
624,18,104,4,0,
624,18,156,4,0,
624,18,163,1,30,
624,18,164,4,0,
624,18,168,4,0,
624,18,182,4,0,
624,18,184,1,22,
624,18,185,1,17,
624,18,201,4,0,
624,18,206,4,0,
624,18,207,4,0,
624,18,210,1,9,
624,18,212,2,0,
624,18,213,4,0,
624,18,214,4,0,
624,18,216,4,0,
624,18,218,4,0,
624,18,228,2,0,
624,18,232,1,25,
624,18,237,4,0,
624,18,240,4,0,
624,18,259,1,14,
624,18,259,4,0,
624,18,263,4,0,
624,18,269,4,0,
624,18,279,2,0,
624,18,280,4,0,
624,18,317,4,0,
624,18,319,1,38,
624,18,332,4,0,
624,18,334,1,46,
624,18,371,4,0,
624,18,372,1,33,
624,18,373,1,41,
624,18,373,4,0,
624,18,374,4,0,
624,18,389,2,0,
624,18,397,4,0,
624,18,398,4,0,
624,18,399,4,0,
624,18,400,1,49,
624,18,404,4,0,
624,18,421,4,0,
624,18,427,2,0,
624,18,442,1,54,
624,18,446,2,0,
624,18,447,4,0,
624,18,490,4,0,
624,18,496,4,0,
624,18,501,2,0,
624,18,555,4,0,
624,18,590,4,0,
625,11,10,1,1,2
625,11,12,1,71,
625,11,14,1,63,
625,11,14,4,0,
625,11,15,4,0,
625,11,43,1,1,3
625,11,43,1,6,
625,11,63,4,0,
625,11,86,4,0,
625,11,91,4,0,
625,11,92,4,0,
625,11,104,4,0,
625,11,156,4,0,
625,11,163,1,30,
625,11,164,4,0,
625,11,168,4,0,
625,11,182,4,0,
625,11,184,1,22,
625,11,185,1,17,
625,11,201,4,0,
625,11,206,4,0,
625,11,207,4,0,
625,11,210,1,1,4
625,11,210,1,9,
625,11,213,4,0,
625,11,216,4,0,
625,11,218,4,0,
625,11,232,1,25,
625,11,237,4,0,
625,11,240,4,0,
625,11,249,4,0,
625,11,259,1,1,5
625,11,259,1,14,
625,11,259,4,0,
625,11,263,4,0,
625,11,269,4,0,
625,11,280,4,0,
625,11,317,4,0,
625,11,319,1,38,
625,11,332,4,0,
625,11,334,1,46,
625,11,368,1,1,1
625,11,371,4,0,
625,11,372,1,33,
625,11,373,1,41,
625,11,373,4,0,
625,11,374,4,0,
625,11,397,4,0,
625,11,398,4,0,
625,11,400,1,49,
625,11,404,4,0,
625,11,411,4,0,
625,11,416,4,0,
625,11,421,4,0,
625,11,442,1,57,
625,11,444,4,0,
625,11,447,4,0,
625,11,468,4,0,
625,11,490,4,0,
625,11,496,4,0,
625,11,514,4,0,
625,11,555,4,0,
625,14,10,1,1,2
625,14,12,1,71,
625,14,14,1,63,
625,14,14,4,0,
625,14,15,4,0,
625,14,43,1,1,3
625,14,43,1,6,
625,14,63,4,0,
625,14,67,3,0,
625,14,86,4,0,
625,14,91,4,0,
625,14,92,4,0,
625,14,104,4,0,
625,14,156,4,0,
625,14,163,1,30,
625,14,164,4,0,
625,14,168,4,0,
625,14,173,3,0,
625,14,180,3,0,
625,14,182,4,0,
625,14,184,1,22,
625,14,185,1,17,
625,14,201,4,0,
625,14,206,4,0,
625,14,207,4,0,
625,14,210,1,1,4
625,14,210,1,9,
625,14,213,4,0,
625,14,214,3,0,
625,14,216,4,0,
625,14,218,4,0,
625,14,232,1,25,
625,14,237,4,0,
625,14,240,4,0,
625,14,249,4,0,
625,14,259,1,1,5
625,14,259,1,14,
625,14,259,4,0,
625,14,263,4,0,
625,14,269,4,0,
625,14,272,3,0,
625,14,280,4,0,
625,14,282,3,0,
625,14,289,3,0,
625,14,317,4,0,
625,14,319,1,38,
625,14,332,4,0,
625,14,334,1,46,
625,14,334,3,0,
625,14,368,1,1,1
625,14,371,4,0,
625,14,372,1,33,
625,14,373,1,41,
625,14,373,4,0,
625,14,374,4,0,
625,14,393,3,0,
625,14,397,4,0,
625,14,398,4,0,
625,14,399,3,0,
625,14,400,1,49,
625,14,404,4,0,
625,14,411,4,0,
625,14,416,4,0,
625,14,421,4,0,
625,14,442,1,57,
625,14,442,3,0,
625,14,444,4,0,
625,14,446,3,0,
625,14,447,4,0,
625,14,468,4,0,
625,14,490,4,0,
625,14,492,3,0,
625,14,496,4,0,
625,14,514,4,0,
625,14,530,3,0,
625,14,555,4,0,
625,15,10,1,1,4
625,15,12,1,1,1
625,15,12,1,71,
625,15,14,1,63,
625,15,14,4,0,
625,15,15,4,0,
625,15,43,1,1,5
625,15,43,1,6,
625,15,63,4,0,
625,15,86,4,0,
625,15,91,4,0,
625,15,92,4,0,
625,15,104,4,0,
625,15,156,4,0,
625,15,163,1,30,
625,15,164,4,0,
625,15,168,4,0,
625,15,182,4,0,
625,15,184,1,22,
625,15,185,1,17,
625,15,201,4,0,
625,15,206,4,0,
625,15,207,4,0,
625,15,210,1,1,6
625,15,210,1,9,
625,15,213,4,0,
625,15,214,4,0,
625,15,216,4,0,
625,15,218,4,0,
625,15,232,1,25,
625,15,237,4,0,
625,15,240,4,0,
625,15,249,4,0,
625,15,259,1,1,7
625,15,259,1,14,
625,15,259,4,0,
625,15,263,4,0,
625,15,269,4,0,
625,15,280,4,0,
625,15,317,4,0,
625,15,319,1,38,
625,15,332,4,0,
625,15,334,1,46,
625,15,368,1,1,3
625,15,371,4,0,
625,15,372,1,33,
625,15,373,1,41,
625,15,373,4,0,
625,15,374,4,0,
625,15,397,4,0,
625,15,398,4,0,
625,15,399,4,0,
625,15,400,1,49,
625,15,404,4,0,
625,15,411,4,0,
625,15,416,4,0,
625,15,421,4,0,
625,15,442,1,1,2
625,15,442,1,57,
625,15,444,4,0,
625,15,447,4,0,
625,15,468,4,0,
625,15,490,4,0,
625,15,496,4,0,
625,15,514,4,0,
625,15,555,4,0,
625,15,590,4,0,
625,15,612,4,0,
625,16,10,1,1,4
625,16,12,1,1,1
625,16,12,1,71,1
625,16,14,1,63,1
625,16,14,4,0,
625,16,15,4,0,
625,16,43,1,1,5
625,16,43,1,6,1
625,16,63,4,0,
625,16,67,3,0,
625,16,86,4,0,
625,16,91,4,0,
625,16,92,4,0,
625,16,104,4,0,
625,16,156,4,0,
625,16,163,1,30,1
625,16,164,4,0,
625,16,168,4,0,
625,16,173,3,0,
625,16,180,3,0,
625,16,182,4,0,
625,16,184,1,22,1
625,16,185,1,17,1
625,16,201,4,0,
625,16,206,4,0,
625,16,207,4,0,
625,16,210,1,1,6
625,16,210,1,9,1
625,16,213,4,0,
625,16,214,4,0,
625,16,216,4,0,
625,16,218,4,0,
625,16,232,1,25,1
625,16,237,4,0,
625,16,240,4,0,
625,16,249,4,0,
625,16,259,1,1,7
625,16,259,1,14,1
625,16,259,4,0,
625,16,263,4,0,
625,16,269,4,0,
625,16,272,3,0,
625,16,280,4,0,
625,16,282,3,0,
625,16,289,3,0,
625,16,290,4,0,
625,16,317,4,0,
625,16,319,1,38,1
625,16,332,4,0,
625,16,334,1,46,1
625,16,334,3,0,
625,16,368,1,1,3
625,16,371,4,0,
625,16,372,1,33,1
625,16,373,1,41,1
625,16,373,4,0,
625,16,374,4,0,
625,16,393,3,0,
625,16,397,4,0,
625,16,398,4,0,
625,16,399,4,0,
625,16,400,1,49,1
625,16,404,4,0,
625,16,411,4,0,
625,16,416,4,0,
625,16,421,4,0,
625,16,442,1,1,2
625,16,442,1,57,1
625,16,442,3,0,
625,16,444,4,0,
625,16,446,3,0,
625,16,447,4,0,
625,16,468,4,0,
625,16,490,4,0,
625,16,492,3,0,
625,16,496,4,0,
625,16,514,4,0,
625,16,530,3,0,
625,16,555,4,0,
625,16,590,4,0,
625,16,612,4,0,
625,17,10,1,1,4
625,17,12,1,1,1
625,17,12,1,71,
625,17,14,1,63,
625,17,14,4,0,
625,17,43,1,1,5
625,17,43,1,6,
625,17,63,4,0,
625,17,86,4,0,
625,17,92,4,0,
625,17,104,4,0,
625,17,156,4,0,
625,17,163,1,30,
625,17,164,4,0,
625,17,168,4,0,
625,17,182,4,0,
625,17,184,1,22,
625,17,185,1,17,
625,17,201,4,0,
625,17,206,4,0,
625,17,207,4,0,
625,17,210,1,1,6
625,17,210,1,9,
625,17,213,4,0,
625,17,214,4,0,
625,17,216,4,0,
625,17,218,4,0,
625,17,232,1,25,
625,17,237,4,0,
625,17,240,4,0,
625,17,259,1,1,7
625,17,259,1,14,
625,17,259,4,0,
625,17,263,4,0,
625,17,269,4,0,
625,17,280,4,0,
625,17,317,4,0,
625,17,319,1,38,
625,17,332,4,0,
625,17,334,1,46,
625,17,368,1,1,3
625,17,371,4,0,
625,17,372,1,33,
625,17,373,1,41,
625,17,373,4,0,
625,17,374,4,0,
625,17,397,4,0,
625,17,398,4,0,
625,17,399,4,0,
625,17,400,1,49,
625,17,404,4,0,
625,17,411,4,0,
625,17,416,4,0,
625,17,421,4,0,
625,17,442,1,1,2
625,17,442,1,57,
625,17,444,4,0,
625,17,447,4,0,
625,17,490,4,0,
625,17,496,4,0,
625,17,555,4,0,
625,17,590,4,0,
625,18,10,1,1,4
625,18,12,1,1,1
625,18,12,1,71,
625,18,14,1,63,
625,18,14,4,0,
625,18,43,1,1,5
625,18,43,1,6,
625,18,63,4,0,
625,18,86,4,0,
625,18,92,4,0,
625,18,104,4,0,
625,18,156,4,0,
625,18,163,1,30,
625,18,164,4,0,
625,18,168,4,0,
625,18,182,4,0,
625,18,184,1,22,
625,18,185,1,17,
625,18,201,4,0,
625,18,206,4,0,
625,18,207,4,0,
625,18,210,1,1,6
625,18,210,1,9,
625,18,213,4,0,
625,18,214,4,0,
625,18,216,4,0,
625,18,218,4,0,
625,18,232,1,25,
625,18,237,4,0,
625,18,240,4,0,
625,18,259,1,1,7
625,18,259,1,14,
625,18,259,4,0,
625,18,263,4,0,
625,18,269,4,0,
625,18,280,4,0,
625,18,317,4,0,
625,18,319,1,38,
625,18,332,4,0,
625,18,334,1,46,
625,18,368,1,1,3
625,18,371,4,0,
625,18,372,1,33,
625,18,373,1,41,
625,18,373,4,0,
625,18,374,4,0,
625,18,397,4,0,
625,18,398,4,0,
625,18,399,4,0,
625,18,400,1,49,
625,18,404,4,0,
625,18,411,4,0,
625,18,416,4,0,
625,18,421,4,0,
625,18,442,1,1,2
625,18,442,1,57,
625,18,444,4,0,
625,18,447,4,0,
625,18,490,4,0,
625,18,496,4,0,
625,18,555,4,0,
625,18,590,4,0,
626,11,14,1,56,
626,11,14,4,0,
626,11,15,4,0,
626,11,23,2,0,
626,11,29,2,0,
626,11,30,1,16,
626,11,31,1,11,
626,11,37,1,51,
626,11,43,1,1,2
626,11,57,4,0,
626,11,70,4,0,
626,11,89,4,0,
626,11,92,4,0,
626,11,99,1,6,
626,11,104,4,0,
626,11,116,1,36,
626,11,130,2,0,
626,11,133,2,0,
626,11,156,4,0,
626,11,157,4,0,
626,11,164,4,0,
626,11,179,1,46,
626,11,182,4,0,
626,11,184,1,21,
626,11,189,2,0,
626,11,207,4,0,
626,11,213,4,0,
626,11,216,4,0,
626,11,218,4,0,
626,11,224,1,41,
626,11,228,1,1,1
626,11,237,4,0,
626,11,240,4,0,
626,11,241,4,0,
626,11,249,4,0,
626,11,263,4,0,
626,11,269,4,0,
626,11,279,1,26,
626,11,317,4,0,
626,11,332,4,0,
626,11,341,2,0,
626,11,371,4,0,
626,11,398,4,0,
626,11,416,1,61,
626,11,416,4,0,
626,11,431,2,0,
626,11,442,2,0,
626,11,444,4,0,
626,11,496,4,0,
626,11,514,4,0,
626,11,523,4,0,
626,11,526,4,0,
626,11,528,4,0,
626,11,543,1,31,
626,14,14,1,56,
626,14,14,4,0,
626,14,15,4,0,
626,14,23,2,0,
626,14,29,2,0,
626,14,30,1,16,
626,14,31,1,11,
626,14,37,1,51,
626,14,43,1,1,2
626,14,57,4,0,
626,14,70,4,0,
626,14,89,4,0,
626,14,92,4,0,
626,14,99,1,6,
626,14,104,4,0,
626,14,116,1,36,
626,14,130,2,0,
626,14,133,2,0,
626,14,156,4,0,
626,14,157,4,0,
626,14,164,4,0,
626,14,173,3,0,
626,14,179,1,46,
626,14,182,4,0,
626,14,184,1,21,
626,14,189,2,0,
626,14,200,3,0,
626,14,207,4,0,
626,14,213,4,0,
626,14,214,3,0,
626,14,216,4,0,
626,14,218,4,0,
626,14,224,1,41,
626,14,228,1,1,1
626,14,237,4,0,
626,14,240,4,0,
626,14,241,4,0,
626,14,249,4,0,
626,14,253,3,0,
626,14,263,4,0,
626,14,269,4,0,
626,14,276,3,0,
626,14,279,1,26,
626,14,283,3,0,
626,14,317,4,0,
626,14,332,4,0,
626,14,341,2,0,
626,14,371,4,0,
626,14,398,4,0,
626,14,416,1,61,
626,14,416,4,0,
626,14,428,3,0,
626,14,431,2,0,
626,14,442,2,0,
626,14,442,3,0,
626,14,444,4,0,
626,14,496,4,0,
626,14,514,4,0,
626,14,523,4,0,
626,14,526,4,0,
626,14,528,4,0,
626,14,543,1,31,
626,15,14,1,56,
626,15,14,4,0,
626,15,15,4,0,
626,15,23,2,0,
626,15,29,2,0,
626,15,30,1,16,
626,15,31,1,11,
626,15,37,1,50,
626,15,43,1,1,2
626,15,57,4,0,
626,15,70,4,0,
626,15,89,4,0,
626,15,92,4,0,
626,15,99,1,6,
626,15,104,4,0,
626,15,116,1,36,
626,15,130,2,0,
626,15,133,2,0,
626,15,156,4,0,
626,15,157,4,0,
626,15,164,4,0,
626,15,179,1,46,
626,15,182,4,0,
626,15,184,1,21,
626,15,189,2,0,
626,15,207,4,0,
626,15,213,4,0,
626,15,214,4,0,
626,15,216,4,0,
626,15,218,4,0,
626,15,224,1,41,
626,15,228,1,1,1
626,15,237,4,0,
626,15,240,4,0,
626,15,241,4,0,
626,15,249,4,0,
626,15,263,4,0,
626,15,269,4,0,
626,15,279,1,26,
626,15,317,4,0,
626,15,332,4,0,
626,15,341,2,0,
626,15,371,4,0,
626,15,398,4,0,
626,15,416,1,61,
626,15,416,4,0,
626,15,431,2,0,
626,15,442,2,0,
626,15,444,4,0,
626,15,496,4,0,
626,15,514,4,0,
626,15,523,4,0,
626,15,528,4,0,
626,15,543,1,31,
626,15,562,2,0,
626,15,590,4,0,
626,16,14,1,56,1
626,16,14,4,0,
626,16,15,4,0,
626,16,23,2,0,
626,16,29,2,0,
626,16,30,1,16,1
626,16,31,1,11,1
626,16,37,1,50,1
626,16,43,1,1,2
626,16,57,4,0,
626,16,70,4,0,
626,16,89,4,0,
626,16,92,4,0,
626,16,99,1,6,1
626,16,104,4,0,
626,16,116,1,36,1
626,16,130,2,0,
626,16,133,2,0,
626,16,156,4,0,
626,16,157,4,0,
626,16,164,4,0,
626,16,173,3,0,
626,16,179,1,46,1
626,16,182,4,0,
626,16,184,1,21,1
626,16,189,2,0,
626,16,200,3,0,
626,16,207,4,0,
626,16,213,4,0,
626,16,214,4,0,
626,16,216,4,0,
626,16,218,4,0,
626,16,224,1,41,1
626,16,228,1,1,1
626,16,237,4,0,
626,16,240,4,0,
626,16,241,4,0,
626,16,249,4,0,
626,16,253,3,0,
626,16,263,4,0,
626,16,269,4,0,
626,16,276,3,0,
626,16,279,1,26,1
626,16,283,3,0,
626,16,290,4,0,
626,16,317,4,0,
626,16,332,4,0,
626,16,341,2,0,
626,16,371,4,0,
626,16,398,4,0,
626,16,416,1,61,1
626,16,416,4,0,
626,16,428,3,0,
626,16,431,2,0,
626,16,442,2,0,
626,16,442,3,0,
626,16,444,4,0,
626,16,496,4,0,
626,16,514,4,0,
626,16,523,4,0,
626,16,528,4,0,
626,16,543,1,31,1
626,16,562,2,0,
626,16,590,4,0,
626,17,14,1,56,
626,17,14,4,0,
626,17,23,2,0,
626,17,29,2,0,
626,17,30,1,16,
626,17,31,1,11,
626,17,37,1,50,
626,17,43,1,1,2
626,17,57,4,0,
626,17,89,4,0,
626,17,92,4,0,
626,17,99,1,6,
626,17,104,4,0,
626,17,116,1,36,
626,17,130,2,0,
626,17,133,2,0,
626,17,156,4,0,
626,17,157,4,0,
626,17,164,4,0,
626,17,179,1,46,
626,17,182,4,0,
626,17,184,1,21,
626,17,189,2,0,
626,17,207,4,0,
626,17,213,4,0,
626,17,214,4,0,
626,17,216,4,0,
626,17,218,4,0,
626,17,224,1,41,
626,17,228,1,1,1
626,17,237,4,0,
626,17,240,4,0,
626,17,241,4,0,
626,17,263,4,0,
626,17,269,4,0,
626,17,279,1,26,
626,17,317,4,0,
626,17,332,4,0,
626,17,341,2,0,
626,17,371,4,0,
626,17,398,4,0,
626,17,416,1,61,
626,17,416,4,0,
626,17,431,2,0,
626,17,442,2,0,
626,17,444,4,0,
626,17,496,4,0,
626,17,523,4,0,
626,17,526,4,0,
626,17,528,4,0,
626,17,543,1,31,
626,17,562,2,0,
626,17,590,4,0,
626,17,684,4,0,
626,18,14,1,56,
626,18,14,4,0,
626,18,23,2,0,
626,18,29,2,0,
626,18,30,1,16,
626,18,31,1,11,
626,18,37,1,50,
626,18,43,1,1,2
626,18,57,4,0,
626,18,89,4,0,
626,18,92,4,0,
626,18,99,1,6,
626,18,104,4,0,
626,18,116,1,36,
626,18,130,2,0,
626,18,133,2,0,
626,18,156,4,0,
626,18,157,4,0,
626,18,164,4,0,
626,18,179,1,46,
626,18,182,4,0,
626,18,184,1,21,
626,18,189,2,0,
626,18,207,4,0,
626,18,213,4,0,
626,18,214,4,0,
626,18,216,4,0,
626,18,218,4,0,
626,18,224,1,41,
626,18,228,1,1,1
626,18,237,4,0,
626,18,240,4,0,
626,18,241,4,0,
626,18,263,4,0,
626,18,269,4,0,
626,18,279,1,26,
626,18,317,4,0,
626,18,332,4,0,
626,18,341,2,0,
626,18,371,4,0,
626,18,398,4,0,
626,18,416,1,61,
626,18,416,4,0,
626,18,431,2,0,
626,18,442,2,0,
626,18,444,4,0,
626,18,496,4,0,
626,18,523,4,0,
626,18,526,4,0,
626,18,528,4,0,
626,18,538,2,0,
626,18,543,1,31,
626,18,562,2,0,
626,18,590,4,0,
626,18,684,4,0,
627,11,15,4,0,
627,11,17,1,10,
627,11,18,1,55,
627,11,19,4,0,
627,11,31,1,5,
627,11,37,1,64,
627,11,43,1,1,2
627,11,64,1,1,1
627,11,70,4,0,
627,11,92,4,0,
627,11,104,4,0,
627,11,156,4,0,
627,11,157,4,0,
627,11,163,1,28,
627,11,164,4,0,
627,11,182,4,0,
627,11,184,1,19,
627,11,207,4,0,
627,11,213,4,0,
627,11,216,4,0,
627,11,218,4,0,
627,11,237,4,0,
627,11,240,4,0,
627,11,241,4,0,
627,11,249,4,0,
627,11,263,4,0,
627,11,306,1,46,
627,11,317,4,0,
627,11,332,1,23,
627,11,332,4,0,
627,11,339,4,0,
627,11,365,4,0,
627,11,366,1,37,
627,11,369,4,0,
627,11,403,1,41,
627,11,413,1,59,
627,11,421,4,0,
627,11,432,1,32,
627,11,468,1,14,
627,11,468,4,0,
627,11,496,4,0,
627,11,507,1,50,
627,11,507,4,0,
627,11,514,4,0,
627,11,526,4,0,
627,14,15,4,0,
627,14,17,1,10,
627,14,18,1,55,
627,14,19,4,0,
627,14,31,1,5,
627,14,37,1,64,
627,14,43,1,1,2
627,14,64,1,1,1
627,14,70,4,0,
627,14,92,4,0,
627,14,104,4,0,
627,14,156,4,0,
627,14,157,4,0,
627,14,163,1,28,
627,14,164,4,0,
627,14,173,3,0,
627,14,182,4,0,
627,14,184,1,19,
627,14,207,4,0,
627,14,213,4,0,
627,14,214,3,0,
627,14,216,4,0,
627,14,218,4,0,
627,14,237,4,0,
627,14,240,4,0,
627,14,241,4,0,
627,14,249,4,0,
627,14,257,3,0,
627,14,263,4,0,
627,14,276,3,0,
627,14,306,1,46,
627,14,317,4,0,
627,14,332,1,23,
627,14,332,4,0,
627,14,339,4,0,
627,14,355,3,0,
627,14,365,4,0,
627,14,366,1,37,
627,14,366,3,0,
627,14,369,4,0,
627,14,403,1,41,
627,14,413,1,59,
627,14,421,4,0,
627,14,432,1,32,
627,14,468,1,14,
627,14,468,4,0,
627,14,496,4,0,
627,14,507,1,50,
627,14,507,4,0,
627,14,514,4,0,
627,14,526,4,0,
627,15,15,4,0,
627,15,17,1,10,
627,15,18,1,55,
627,15,19,4,0,
627,15,31,1,5,
627,15,37,1,64,
627,15,43,1,1,2
627,15,64,1,1,1
627,15,70,4,0,
627,15,92,4,0,
627,15,104,4,0,
627,15,156,4,0,
627,15,157,4,0,
627,15,163,1,28,
627,15,164,4,0,
627,15,182,4,0,
627,15,184,1,19,
627,15,207,4,0,
627,15,211,4,0,
627,15,213,4,0,
627,15,214,4,0,
627,15,216,4,0,
627,15,218,4,0,
627,15,237,4,0,
627,15,240,4,0,
627,15,241,4,0,
627,15,249,4,0,
627,15,263,4,0,
627,15,306,1,46,
627,15,317,4,0,
627,15,332,1,23,
627,15,332,4,0,
627,15,339,4,0,
627,15,355,4,0,
627,15,366,1,37,
627,15,369,4,0,
627,15,403,1,41,
627,15,413,1,59,
627,15,421,4,0,
627,15,432,1,32,
627,15,468,1,14,
627,15,468,4,0,
627,15,496,4,0,
627,15,507,1,50,
627,15,507,4,0,
627,15,514,4,0,
627,15,590,4,0,
627,16,15,4,0,
627,16,17,1,10,1
627,16,18,1,55,1
627,16,19,4,0,
627,16,31,1,5,1
627,16,37,1,64,1
627,16,43,1,1,2
627,16,64,1,1,1
627,16,70,4,0,
627,16,92,4,0,
627,16,104,4,0,
627,16,156,4,0,
627,16,157,4,0,
627,16,163,1,28,1
627,16,164,4,0,
627,16,173,3,0,
627,16,182,4,0,
627,16,184,1,19,1
627,16,207,4,0,
627,16,211,4,0,
627,16,213,4,0,
627,16,214,4,0,
627,16,216,4,0,
627,16,218,4,0,
627,16,237,4,0,
627,16,240,4,0,
627,16,241,4,0,
627,16,249,4,0,
627,16,257,3,0,
627,16,263,4,0,
627,16,276,3,0,
627,16,290,4,0,
627,16,306,1,46,1
627,16,317,4,0,
627,16,332,1,23,1
627,16,332,4,0,
627,16,339,4,0,
627,16,355,4,0,
627,16,366,1,37,1
627,16,366,3,0,
627,16,369,4,0,
627,16,403,1,41,1
627,16,413,1,59,1
627,16,421,4,0,
627,16,432,1,32,1
627,16,468,1,14,1
627,16,468,4,0,
627,16,496,4,0,
627,16,507,1,50,1
627,16,507,4,0,
627,16,514,4,0,
627,16,590,4,0,
627,17,17,1,10,
627,17,18,1,55,
627,17,19,4,0,
627,17,31,1,5,
627,17,37,1,64,
627,17,43,1,1,2
627,17,64,1,1,1
627,17,92,4,0,
627,17,104,4,0,
627,17,156,4,0,
627,17,157,4,0,
627,17,163,1,28,
627,17,164,4,0,
627,17,182,4,0,
627,17,184,1,19,
627,17,207,4,0,
627,17,211,4,0,
627,17,213,4,0,
627,17,214,4,0,
627,17,216,4,0,
627,17,218,4,0,
627,17,237,4,0,
627,17,240,4,0,
627,17,241,4,0,
627,17,263,4,0,
627,17,306,1,46,
627,17,317,4,0,
627,17,332,1,23,
627,17,332,4,0,
627,17,339,4,0,
627,17,355,4,0,
627,17,366,1,37,
627,17,369,4,0,
627,17,403,1,41,
627,17,413,1,59,
627,17,421,4,0,
627,17,432,1,32,
627,17,468,1,14,
627,17,496,4,0,
627,17,507,1,50,
627,17,507,4,0,
627,17,526,4,0,
627,17,590,4,0,
627,18,17,1,10,
627,18,18,1,55,
627,18,19,4,0,
627,18,31,1,5,
627,18,37,1,64,
627,18,43,1,1,2
627,18,64,1,1,1
627,18,92,4,0,
627,18,104,4,0,
627,18,156,4,0,
627,18,157,4,0,
627,18,163,1,28,
627,18,164,4,0,
627,18,182,4,0,
627,18,184,1,19,
627,18,207,4,0,
627,18,211,4,0,
627,18,213,4,0,
627,18,214,4,0,
627,18,216,4,0,
627,18,218,4,0,
627,18,237,4,0,
627,18,240,4,0,
627,18,241,4,0,
627,18,263,4,0,
627,18,306,1,46,
627,18,317,4,0,
627,18,332,1,23,
627,18,332,4,0,
627,18,339,4,0,
627,18,355,4,0,
627,18,366,1,37,
627,18,369,4,0,
627,18,403,1,41,
627,18,413,1,59,
627,18,421,4,0,
627,18,432,1,32,
627,18,468,1,14,
627,18,496,4,0,
627,18,507,1,50,
627,18,507,4,0,
627,18,526,4,0,
627,18,590,4,0,
628,11,15,4,0,
628,11,17,1,1,4
628,11,17,1,10,
628,11,18,1,57,
628,11,19,4,0,
628,11,31,1,1,3
628,11,31,1,5,
628,11,37,1,70,
628,11,43,1,1,2
628,11,63,4,0,
628,11,64,1,1,1
628,11,70,4,0,
628,11,92,4,0,
628,11,104,4,0,
628,11,156,4,0,
628,11,157,4,0,
628,11,163,1,28,
628,11,164,4,0,
628,11,182,4,0,
628,11,184,1,19,
628,11,207,4,0,
628,11,213,4,0,
628,11,216,4,0,
628,11,218,4,0,
628,11,237,4,0,
628,11,240,4,0,
628,11,241,4,0,
628,11,249,4,0,
628,11,263,4,0,
628,11,276,1,51,
628,11,306,1,46,
628,11,317,4,0,
628,11,332,1,23,
628,11,332,4,0,
628,11,339,4,0,
628,11,365,4,0,
628,11,366,1,37,
628,11,369,4,0,
628,11,403,1,41,
628,11,413,1,63,
628,11,416,4,0,
628,11,421,4,0,
628,11,432,1,32,
628,11,468,1,14,
628,11,468,4,0,
628,11,496,4,0,
628,11,507,1,50,
628,11,507,4,0,
628,11,514,4,0,
628,11,526,4,0,
628,14,15,4,0,
628,14,17,1,1,4
628,14,17,1,10,
628,14,18,1,57,
628,14,19,4,0,
628,14,31,1,1,3
628,14,31,1,5,
628,14,37,1,70,
628,14,43,1,1,2
628,14,63,4,0,
628,14,64,1,1,1
628,14,70,4,0,
628,14,92,4,0,
628,14,104,4,0,
628,14,143,3,0,
628,14,156,4,0,
628,14,157,4,0,
628,14,163,1,28,
628,14,164,4,0,
628,14,173,3,0,
628,14,182,4,0,
628,14,184,1,19,
628,14,207,4,0,
628,14,213,4,0,
628,14,214,3,0,
628,14,216,4,0,
628,14,218,4,0,
628,14,237,4,0,
628,14,240,4,0,
628,14,241,4,0,
628,14,249,4,0,
628,14,257,3,0,
628,14,263,4,0,
628,14,276,1,51,
628,14,276,3,0,
628,14,306,1,46,
628,14,317,4,0,
628,14,332,1,23,
628,14,332,4,0,
628,14,339,4,0,
628,14,355,3,0,
628,14,365,4,0,
628,14,366,1,37,
628,14,366,3,0,
628,14,369,4,0,
628,14,403,1,41,
628,14,413,1,63,
628,14,416,4,0,
628,14,421,4,0,
628,14,432,1,32,
628,14,468,1,14,
628,14,468,4,0,
628,14,496,4,0,
628,14,507,1,50,
628,14,507,4,0,
628,14,514,4,0,
628,14,526,4,0,
628,15,15,4,0,
628,15,17,1,1,8
628,15,17,1,10,
628,15,18,1,1,3
628,15,18,1,57,
628,15,19,4,0,
628,15,31,1,1,7
628,15,31,1,5,
628,15,37,1,1,1
628,15,37,1,70,
628,15,43,1,1,6
628,15,63,4,0,
628,15,64,1,1,5
628,15,70,4,0,
628,15,92,4,0,
628,15,104,4,0,
628,15,156,4,0,
628,15,157,4,0,
628,15,163,1,28,
628,15,164,4,0,
628,15,182,4,0,
628,15,184,1,19,
628,15,207,4,0,
628,15,211,4,0,
628,15,213,4,0,
628,15,214,4,0,
628,15,216,4,0,
628,15,218,4,0,
628,15,237,4,0,
628,15,240,4,0,
628,15,241,4,0,
628,15,249,4,0,
628,15,263,4,0,
628,15,276,1,1,4
628,15,276,1,51,
628,15,306,1,46,
628,15,317,4,0,
628,15,332,1,23,
628,15,332,4,0,
628,15,339,4,0,
628,15,355,4,0,
628,15,366,1,37,
628,15,369,4,0,
628,15,403,1,41,
628,15,413,1,1,2
628,15,413,1,63,
628,15,416,4,0,
628,15,421,4,0,
628,15,432,1,32,
628,15,468,1,14,
628,15,468,4,0,
628,15,496,4,0,
628,15,507,1,50,
628,15,507,4,0,
628,15,514,4,0,
628,15,590,4,0,
628,16,15,4,0,
628,16,17,1,1,8
628,16,17,1,10,1
628,16,18,1,1,3
628,16,18,1,57,1
628,16,19,4,0,
628,16,31,1,1,7
628,16,31,1,5,1
628,16,37,1,1,1
628,16,37,1,70,1
628,16,43,1,1,6
628,16,63,4,0,
628,16,64,1,1,5
628,16,70,4,0,
628,16,92,4,0,
628,16,104,4,0,
628,16,143,3,0,
628,16,156,4,0,
628,16,157,4,0,
628,16,163,1,28,1
628,16,164,4,0,
628,16,173,3,0,
628,16,182,4,0,
628,16,184,1,19,1
628,16,207,4,0,
628,16,211,4,0,
628,16,213,4,0,
628,16,214,4,0,
628,16,216,4,0,
628,16,218,4,0,
628,16,237,4,0,
628,16,240,4,0,
628,16,241,4,0,
628,16,249,4,0,
628,16,257,3,0,
628,16,263,4,0,
628,16,276,1,1,4
628,16,276,1,51,1
628,16,276,3,0,
628,16,290,4,0,
628,16,306,1,46,1
628,16,317,4,0,
628,16,332,1,23,1
628,16,332,4,0,
628,16,339,4,0,
628,16,355,4,0,
628,16,366,1,37,1
628,16,366,3,0,
628,16,369,4,0,
628,16,403,1,41,1
628,16,413,1,1,2
628,16,413,1,63,1
628,16,416,4,0,
628,16,421,4,0,
628,16,428,3,0,
628,16,432,1,32,1
628,16,468,1,14,1
628,16,468,4,0,
628,16,496,4,0,
628,16,507,1,50,1
628,16,507,4,0,
628,16,514,4,0,
628,16,590,4,0,
628,17,17,1,1,9
628,17,17,1,10,
628,17,18,1,1,4
628,17,18,1,57,
628,17,19,4,0,
628,17,31,1,1,8
628,17,31,1,5,
628,17,37,1,1,2
628,17,37,1,70,
628,17,43,1,1,7
628,17,63,4,0,
628,17,64,1,1,6
628,17,92,4,0,
628,17,104,4,0,
628,17,156,4,0,
628,17,157,4,0,
628,17,163,1,28,
628,17,164,4,0,
628,17,182,4,0,
628,17,184,1,19,
628,17,207,4,0,
628,17,211,4,0,
628,17,213,4,0,
628,17,214,4,0,
628,17,216,4,0,
628,17,218,4,0,
628,17,237,4,0,
628,17,240,4,0,
628,17,241,4,0,
628,17,263,4,0,
628,17,276,1,0,
628,17,276,1,1,1
628,17,306,1,46,
628,17,317,4,0,
628,17,332,1,23,
628,17,332,4,0,
628,17,339,4,0,
628,17,355,4,0,
628,17,366,1,37,
628,17,369,4,0,
628,17,403,1,41,
628,17,413,1,1,3
628,17,413,1,63,
628,17,416,4,0,
628,17,421,4,0,
628,17,432,1,32,
628,17,468,1,14,
628,17,496,4,0,
628,17,507,1,50,
628,17,507,4,0,
628,17,526,4,0,
628,17,590,4,0,
628,18,17,1,1,8
628,18,17,1,10,
628,18,18,1,1,4
628,18,18,1,57,
628,18,19,4,0,
628,18,31,1,1,7
628,18,31,1,5,
628,18,37,1,1,2
628,18,37,1,70,
628,18,43,1,1,6
628,18,63,4,0,
628,18,64,1,1,5
628,18,92,4,0,
628,18,104,4,0,
628,18,156,4,0,
628,18,157,4,0,
628,18,163,1,28,
628,18,164,4,0,
628,18,182,4,0,
628,18,184,1,19,
628,18,207,4,0,
628,18,211,4,0,
628,18,213,4,0,
628,18,214,4,0,
628,18,216,4,0,
628,18,218,4,0,
628,18,237,4,0,
628,18,240,4,0,
628,18,241,4,0,
628,18,263,4,0,
628,18,276,1,0,
628,18,276,1,1,1
628,18,306,1,46,
628,18,317,4,0,
628,18,332,1,23,
628,18,332,4,0,
628,18,339,4,0,
628,18,355,4,0,
628,18,366,1,37,
628,18,369,4,0,
628,18,403,1,41,
628,18,413,1,1,3
628,18,413,1,63,
628,18,416,4,0,
628,18,421,4,0,
628,18,432,1,32,
628,18,468,1,14,
628,18,496,4,0,
628,18,507,1,50,
628,18,507,4,0,
628,18,526,4,0,
628,18,590,4,0,
629,11,15,4,0,
629,11,16,1,1,1
629,11,18,1,55,
629,11,19,4,0,
629,11,31,1,5,
629,11,43,1,1,2
629,11,92,4,0,
629,11,104,4,0,
629,11,119,1,64,
629,11,156,4,0,
629,11,164,4,0,
629,11,168,4,0,
629,11,182,4,0,
629,11,184,2,0,
629,11,185,1,23,
629,11,207,4,0,
629,11,211,2,0,
629,11,212,2,0,
629,11,213,4,0,
629,11,216,4,0,
629,11,218,4,0,
629,11,237,4,0,
629,11,240,4,0,
629,11,241,4,0,
629,11,244,4,0,
629,11,247,4,0,
629,11,249,4,0,
629,11,259,4,0,
629,11,260,1,19,
629,11,263,4,0,
629,11,269,4,0,
629,11,282,2,0,
629,11,313,2,0,
629,11,317,4,0,
629,11,332,4,0,
629,11,355,2,0,
629,11,365,1,10,
629,11,365,4,0,
629,11,366,1,37,
629,11,369,4,0,
629,11,371,4,0,
629,11,373,1,50,
629,11,373,4,0,
629,11,386,1,28,
629,11,399,1,46,
629,11,403,1,41,
629,11,413,1,59,
629,11,417,1,14,
629,11,432,1,32,
629,11,496,4,0,
629,11,510,4,0,
629,11,514,4,0,
629,11,555,4,0,
629,14,15,4,0,
629,14,16,1,1,1
629,14,18,1,55,
629,14,19,4,0,
629,14,31,1,5,
629,14,43,1,1,2
629,14,92,4,0,
629,14,104,4,0,
629,14,119,1,64,
629,14,156,4,0,
629,14,164,4,0,
629,14,168,4,0,
629,14,173,3,0,
629,14,182,4,0,
629,14,184,2,0,
629,14,185,1,23,
629,14,207,4,0,
629,14,211,2,0,
629,14,212,2,0,
629,14,213,4,0,
629,14,214,3,0,
629,14,216,4,0,
629,14,218,4,0,
629,14,237,4,0,
629,14,240,4,0,
629,14,241,4,0,
629,14,244,4,0,
629,14,247,4,0,
629,14,249,4,0,
629,14,257,3,0,
629,14,259,4,0,
629,14,260,1,19,
629,14,263,4,0,
629,14,269,4,0,
629,14,282,2,0,
629,14,282,3,0,
629,14,289,3,0,
629,14,313,2,0,
629,14,317,4,0,
629,14,332,4,0,
629,14,334,3,0,
629,14,335,3,0,
629,14,355,2,0,
629,14,355,3,0,
629,14,365,1,10,
629,14,365,4,0,
629,14,366,1,37,
629,14,366,3,0,
629,14,369,4,0,
629,14,371,4,0,
629,14,373,1,50,
629,14,373,4,0,
629,14,386,1,28,
629,14,399,1,46,
629,14,399,3,0,
629,14,403,1,41,
629,14,413,1,59,
629,14,417,1,14,
629,14,432,1,32,
629,14,492,3,0,
629,14,496,4,0,
629,14,510,4,0,
629,14,514,4,0,
629,14,555,4,0,
629,15,15,4,0,
629,15,16,1,1,1
629,15,18,1,55,
629,15,19,4,0,
629,15,31,1,5,
629,15,43,1,1,2
629,15,92,4,0,
629,15,104,4,0,
629,15,119,1,64,
629,15,156,4,0,
629,15,164,4,0,
629,15,168,4,0,
629,15,182,4,0,
629,15,184,2,0,
629,15,185,1,23,
629,15,207,4,0,
629,15,211,2,0,
629,15,211,4,0,
629,15,212,2,0,
629,15,213,4,0,
629,15,214,4,0,
629,15,216,4,0,
629,15,218,4,0,
629,15,237,4,0,
629,15,240,4,0,
629,15,241,4,0,
629,15,244,4,0,
629,15,247,4,0,
629,15,249,4,0,
629,15,259,4,0,
629,15,260,1,19,
629,15,263,4,0,
629,15,269,4,0,
629,15,282,2,0,
629,15,313,2,0,
629,15,317,4,0,
629,15,332,4,0,
629,15,355,2,0,
629,15,355,4,0,
629,15,365,1,10,
629,15,366,1,37,
629,15,369,4,0,
629,15,371,4,0,
629,15,373,1,50,
629,15,373,4,0,
629,15,386,1,28,
629,15,399,1,46,
629,15,399,4,0,
629,15,403,1,41,
629,15,413,1,59,
629,15,417,1,14,
629,15,432,1,32,
629,15,492,2,0,
629,15,496,4,0,
629,15,510,4,0,
629,15,514,4,0,
629,15,555,4,0,
629,15,590,4,0,
629,16,15,4,0,
629,16,16,1,1,1
629,16,18,1,55,1
629,16,19,4,0,
629,16,31,1,5,1
629,16,43,1,1,2
629,16,92,4,0,
629,16,104,4,0,
629,16,119,1,64,1
629,16,156,4,0,
629,16,164,4,0,
629,16,168,4,0,
629,16,173,3,0,
629,16,182,4,0,
629,16,184,2,0,
629,16,185,1,23,1
629,16,207,4,0,
629,16,211,2,0,
629,16,211,4,0,
629,16,212,2,0,
629,16,213,4,0,
629,16,214,4,0,
629,16,216,4,0,
629,16,218,4,0,
629,16,237,4,0,
629,16,240,4,0,
629,16,241,4,0,
629,16,244,4,0,
629,16,247,4,0,
629,16,249,4,0,
629,16,257,3,0,
629,16,259,4,0,
629,16,260,1,19,1
629,16,263,4,0,
629,16,269,4,0,
629,16,282,2,0,
629,16,282,3,0,
629,16,289,3,0,
629,16,290,4,0,
629,16,313,2,0,
629,16,317,4,0,
629,16,332,4,0,
629,16,335,3,0,
629,16,355,2,0,
629,16,355,4,0,
629,16,365,1,10,1
629,16,366,1,37,1
629,16,366,3,0,
629,16,369,4,0,
629,16,371,4,0,
629,16,373,1,50,1
629,16,373,4,0,
629,16,386,1,28,1
629,16,399,1,46,1
629,16,399,4,0,
629,16,403,1,41,1
629,16,413,1,59,1
629,16,417,1,14,1
629,16,432,1,32,1
629,16,492,2,0,
629,16,492,3,0,
629,16,496,4,0,
629,16,510,4,0,
629,16,514,4,0,
629,16,555,4,0,
629,16,590,4,0,
629,17,16,1,1,1
629,17,18,1,55,
629,17,19,4,0,
629,17,31,1,5,
629,17,43,1,1,2
629,17,92,4,0,
629,17,104,4,0,
629,17,119,1,64,
629,17,156,4,0,
629,17,164,4,0,
629,17,168,4,0,
629,17,182,4,0,
629,17,184,2,0,
629,17,185,1,23,
629,17,207,4,0,
629,17,211,2,0,
629,17,211,4,0,
629,17,212,2,0,
629,17,213,4,0,
629,17,214,4,0,
629,17,216,4,0,
629,17,218,4,0,
629,17,237,4,0,
629,17,240,4,0,
629,17,241,4,0,
629,17,244,4,0,
629,17,247,4,0,
629,17,259,4,0,
629,17,260,1,19,
629,17,263,4,0,
629,17,269,4,0,
629,17,282,2,0,
629,17,313,2,0,
629,17,317,4,0,
629,17,332,4,0,
629,17,355,2,0,
629,17,355,4,0,
629,17,365,1,10,
629,17,366,1,37,
629,17,369,4,0,
629,17,371,4,0,
629,17,373,1,50,
629,17,373,4,0,
629,17,386,1,28,
629,17,399,1,46,
629,17,399,4,0,
629,17,403,1,41,
629,17,413,1,59,
629,17,417,1,14,
629,17,432,1,32,
629,17,492,2,0,
629,17,496,4,0,
629,17,555,4,0,
629,17,590,4,0,
629,18,16,1,1,1
629,18,18,1,55,
629,18,19,4,0,
629,18,31,1,5,
629,18,43,1,1,2
629,18,92,4,0,
629,18,104,4,0,
629,18,119,1,64,
629,18,156,4,0,
629,18,164,4,0,
629,18,168,4,0,
629,18,182,4,0,
629,18,184,2,0,
629,18,185,1,23,
629,18,207,4,0,
629,18,211,2,0,
629,18,211,4,0,
629,18,212,2,0,
629,18,213,4,0,
629,18,214,4,0,
629,18,216,4,0,
629,18,218,4,0,
629,18,237,4,0,
629,18,240,4,0,
629,18,241,4,0,
629,18,244,4,0,
629,18,247,4,0,
629,18,259,4,0,
629,18,260,1,19,
629,18,263,4,0,
629,18,269,4,0,
629,18,282,2,0,
629,18,313,2,0,
629,18,317,4,0,
629,18,332,4,0,
629,18,355,2,0,
629,18,355,4,0,
629,18,365,1,10,
629,18,366,1,37,
629,18,369,4,0,
629,18,371,4,0,
629,18,373,1,50,
629,18,373,4,0,
629,18,386,1,28,
629,18,399,1,46,
629,18,399,4,0,
629,18,403,1,41,
629,18,413,1,59,
629,18,417,1,14,
629,18,432,1,32,
629,18,492,2,0,
629,18,496,4,0,
629,18,555,4,0,
629,18,590,4,0,
630,11,15,4,0,
630,11,16,1,1,1
630,11,18,1,57,
630,11,19,4,0,
630,11,31,1,1,3
630,11,31,1,5,
630,11,43,1,1,2
630,11,63,4,0,
630,11,92,4,0,
630,11,104,4,0,
630,11,119,1,70,
630,11,156,4,0,
630,11,164,4,0,
630,11,168,4,0,
630,11,182,4,0,
630,11,185,1,23,
630,11,198,1,51,
630,11,207,4,0,
630,11,213,4,0,
630,11,216,4,0,
630,11,218,4,0,
630,11,237,4,0,
630,11,240,4,0,
630,11,241,4,0,
630,11,244,4,0,
630,11,247,4,0,
630,11,249,4,0,
630,11,259,4,0,
630,11,260,1,19,
630,11,263,4,0,
630,11,269,4,0,
630,11,317,4,0,
630,11,332,4,0,
630,11,365,1,1,4
630,11,365,1,10,
630,11,365,4,0,
630,11,366,1,37,
630,11,369,4,0,
630,11,371,4,0,
630,11,373,1,50,
630,11,373,4,0,
630,11,386,1,28,
630,11,399,1,46,
630,11,403,1,41,
630,11,413,1,63,
630,11,416,4,0,
630,11,417,1,14,
630,11,432,1,32,
630,11,496,4,0,
630,11,510,4,0,
630,11,514,4,0,
630,11,555,4,0,
630,14,15,4,0,
630,14,16,1,1,1
630,14,18,1,57,
630,14,19,4,0,
630,14,31,1,1,3
630,14,31,1,5,
630,14,43,1,1,2
630,14,63,4,0,
630,14,92,4,0,
630,14,104,4,0,
630,14,119,1,70,
630,14,143,3,0,
630,14,156,4,0,
630,14,164,4,0,
630,14,168,4,0,
630,14,173,3,0,
630,14,182,4,0,
630,14,185,1,23,
630,14,198,1,51,
630,14,207,4,0,
630,14,213,4,0,
630,14,214,3,0,
630,14,216,4,0,
630,14,218,4,0,
630,14,237,4,0,
630,14,240,4,0,
630,14,241,4,0,
630,14,244,4,0,
630,14,247,4,0,
630,14,249,4,0,
630,14,257,3,0,
630,14,259,4,0,
630,14,260,1,19,
630,14,263,4,0,
630,14,269,4,0,
630,14,282,3,0,
630,14,289,3,0,
630,14,317,4,0,
630,14,332,4,0,
630,14,334,3,0,
630,14,335,3,0,
630,14,355,3,0,
630,14,365,1,1,4
630,14,365,1,10,
630,14,365,4,0,
630,14,366,1,37,
630,14,366,3,0,
630,14,369,4,0,
630,14,371,4,0,
630,14,373,1,50,
630,14,373,4,0,
630,14,386,1,28,
630,14,399,1,46,
630,14,399,3,0,
630,14,403,1,41,
630,14,413,1,63,
630,14,416,4,0,
630,14,417,1,14,
630,14,432,1,32,
630,14,492,3,0,
630,14,496,4,0,
630,14,510,4,0,
630,14,514,4,0,
630,14,555,4,0,
630,15,15,4,0,
630,15,16,1,1,5
630,15,18,1,1,3
630,15,18,1,57,
630,15,19,4,0,
630,15,31,1,1,7
630,15,31,1,5,
630,15,43,1,1,6
630,15,63,4,0,
630,15,92,4,0,
630,15,104,4,0,
630,15,119,1,1,1
630,15,119,1,70,
630,15,156,4,0,
630,15,164,4,0,
630,15,168,4,0,
630,15,182,4,0,
630,15,185,1,23,
630,15,198,1,1,4
630,15,198,1,51,
630,15,207,4,0,
630,15,211,4,0,
630,15,213,4,0,
630,15,214,4,0,
630,15,216,4,0,
630,15,218,4,0,
630,15,237,4,0,
630,15,240,4,0,
630,15,241,4,0,
630,15,244,4,0,
630,15,247,4,0,
630,15,249,4,0,
630,15,259,4,0,
630,15,260,1,19,
630,15,263,4,0,
630,15,269,4,0,
630,15,317,4,0,
630,15,332,4,0,
630,15,355,4,0,
630,15,365,1,1,8
630,15,365,1,10,
630,15,366,1,37,
630,15,369,4,0,
630,15,371,4,0,
630,15,373,1,50,
630,15,373,4,0,
630,15,386,1,28,
630,15,399,1,46,
630,15,399,4,0,
630,15,403,1,41,
630,15,413,1,1,2
630,15,413,1,63,
630,15,416,4,0,
630,15,417,1,14,
630,15,432,1,32,
630,15,496,4,0,
630,15,510,4,0,
630,15,514,4,0,
630,15,555,4,0,
630,15,590,4,0,
630,16,15,4,0,
630,16,16,1,1,5
630,16,18,1,1,3
630,16,18,1,57,1
630,16,19,4,0,
630,16,31,1,1,7
630,16,31,1,5,1
630,16,43,1,1,6
630,16,63,4,0,
630,16,92,4,0,
630,16,104,4,0,
630,16,119,1,1,1
630,16,119,1,70,1
630,16,143,3,0,
630,16,156,4,0,
630,16,164,4,0,
630,16,168,4,0,
630,16,173,3,0,
630,16,182,4,0,
630,16,185,1,23,1
630,16,198,1,1,4
630,16,198,1,51,1
630,16,207,4,0,
630,16,211,4,0,
630,16,213,4,0,
630,16,214,4,0,
630,16,216,4,0,
630,16,218,4,0,
630,16,237,4,0,
630,16,240,4,0,
630,16,241,4,0,
630,16,244,4,0,
630,16,247,4,0,
630,16,249,4,0,
630,16,257,3,0,
630,16,259,4,0,
630,16,260,1,19,1
630,16,263,4,0,
630,16,269,4,0,
630,16,282,3,0,
630,16,289,3,0,
630,16,290,4,0,
630,16,317,4,0,
630,16,332,4,0,
630,16,335,3,0,
630,16,355,4,0,
630,16,365,1,1,8
630,16,365,1,10,1
630,16,366,1,37,1
630,16,366,3,0,
630,16,369,4,0,
630,16,371,4,0,
630,16,373,1,50,1
630,16,373,4,0,
630,16,386,1,28,1
630,16,399,1,46,1
630,16,399,4,0,
630,16,403,1,41,1
630,16,413,1,1,2
630,16,413,1,63,1
630,16,416,4,0,
630,16,417,1,14,1
630,16,432,1,32,1
630,16,492,3,0,
630,16,496,4,0,
630,16,510,4,0,
630,16,514,4,0,
630,16,555,4,0,
630,16,590,4,0,
630,17,16,1,1,6
630,17,18,1,1,4
630,17,18,1,57,
630,17,19,4,0,
630,17,31,1,1,8
630,17,31,1,5,
630,17,43,1,1,7
630,17,63,4,0,
630,17,92,4,0,
630,17,104,4,0,
630,17,119,1,1,2
630,17,119,1,70,
630,17,156,4,0,
630,17,164,4,0,
630,17,168,4,0,
630,17,182,4,0,
630,17,185,1,23,
630,17,198,1,0,
630,17,198,1,1,1
630,17,207,4,0,
630,17,211,4,0,
630,17,213,4,0,
630,17,214,4,0,
630,17,216,4,0,
630,17,218,4,0,
630,17,237,4,0,
630,17,240,4,0,
630,17,241,4,0,
630,17,244,4,0,
630,17,247,4,0,
630,17,259,4,0,
630,17,260,1,19,
630,17,263,4,0,
630,17,269,4,0,
630,17,317,4,0,
630,17,332,4,0,
630,17,355,4,0,
630,17,365,1,1,9
630,17,365,1,10,
630,17,366,1,37,
630,17,369,4,0,
630,17,371,4,0,
630,17,373,1,50,
630,17,373,4,0,
630,17,386,1,28,
630,17,399,1,46,
630,17,399,4,0,
630,17,403,1,41,
630,17,413,1,1,3
630,17,413,1,63,
630,17,416,4,0,
630,17,417,1,14,
630,17,432,1,32,
630,17,496,4,0,
630,17,555,4,0,
630,17,590,4,0,
630,18,16,1,1,5
630,18,18,1,1,4
630,18,18,1,57,
630,18,19,4,0,
630,18,31,1,1,7
630,18,31,1,5,
630,18,43,1,1,6
630,18,63,4,0,
630,18,92,4,0,
630,18,104,4,0,
630,18,119,1,1,2
630,18,119,1,70,
630,18,156,4,0,
630,18,164,4,0,
630,18,168,4,0,
630,18,182,4,0,
630,18,185,1,23,
630,18,198,1,0,
630,18,198,1,1,1
630,18,207,4,0,
630,18,211,4,0,
630,18,213,4,0,
630,18,214,4,0,
630,18,216,4,0,
630,18,218,4,0,
630,18,237,4,0,
630,18,240,4,0,
630,18,241,4,0,
630,18,244,4,0,
630,18,247,4,0,
630,18,259,4,0,
630,18,260,1,19,
630,18,263,4,0,
630,18,269,4,0,
630,18,317,4,0,
630,18,332,4,0,
630,18,355,4,0,
630,18,365,1,1,8
630,18,365,1,10,
630,18,366,1,37,
630,18,369,4,0,
630,18,371,4,0,
630,18,373,1,50,
630,18,373,4,0,
630,18,386,1,28,
630,18,399,1,46,
630,18,399,4,0,
630,18,403,1,41,
630,18,413,1,1,3
630,18,413,1,63,
630,18,416,4,0,
630,18,417,1,14,
630,18,432,1,32,
630,18,496,4,0,
630,18,555,4,0,
630,18,590,4,0,
631,11,15,4,0,
631,11,20,1,11,
631,11,34,2,0,
631,11,35,2,0,
631,11,53,1,51,
631,11,53,4,0,
631,11,76,4,0,
631,11,83,1,16,
631,11,91,4,0,
631,11,92,4,0,
631,11,104,4,0,
631,11,122,1,1,2
631,11,126,4,0,
631,11,133,1,46,
631,11,154,1,21,
631,11,156,4,0,
631,11,163,1,41,
631,11,164,4,0,
631,11,168,4,0,
631,11,174,2,0,
631,11,182,4,0,
631,11,185,2,0,
631,11,207,4,0,
631,11,213,4,0,
631,11,214,2,0,
631,11,216,4,0,
631,11,218,4,0,
631,11,228,2,0,
631,11,237,4,0,
631,11,240,4,0,
631,11,241,4,0,
631,11,249,4,0,
631,11,254,1,56,1
631,11,255,1,56,2
631,11,256,1,56,3
631,11,257,2,0,
631,11,261,4,0,
631,11,263,4,0,
631,11,269,4,0,
631,11,289,1,26,
631,11,316,1,6,
631,11,317,4,0,
631,11,321,2,0,
631,11,332,4,0,
631,11,374,4,0,
631,11,389,2,0,
631,11,400,2,0,
631,11,411,4,0,
631,11,416,4,0,
631,11,421,4,0,
631,11,450,1,36,
631,11,468,4,0,
631,11,481,1,31,
631,11,496,4,0,
631,11,510,1,1,1
631,11,510,4,0,
631,11,517,1,61,
631,14,7,3,0,
631,14,9,3,0,
631,14,15,4,0,
631,14,20,1,11,
631,14,20,3,0,
631,14,34,2,0,
631,14,35,2,0,
631,14,53,1,51,
631,14,53,4,0,
631,14,67,3,0,
631,14,76,4,0,
631,14,83,1,16,
631,14,91,4,0,
631,14,92,4,0,
631,14,104,4,0,
631,14,122,1,1,2
631,14,126,4,0,
631,14,133,1,46,
631,14,154,1,21,
631,14,156,4,0,
631,14,163,1,41,
631,14,164,4,0,
631,14,168,4,0,
631,14,173,3,0,
631,14,174,2,0,
631,14,182,4,0,
631,14,185,2,0,
631,14,202,3,0,
631,14,207,4,0,
631,14,213,4,0,
631,14,214,2,0,
631,14,214,3,0,
631,14,216,4,0,
631,14,218,4,0,
631,14,228,2,0,
631,14,237,4,0,
631,14,240,4,0,
631,14,241,4,0,
631,14,249,4,0,
631,14,254,1,56,1
631,14,255,1,56,2
631,14,256,1,56,3
631,14,257,2,0,
631,14,257,3,0,
631,14,261,4,0,
631,14,263,4,0,
631,14,269,4,0,
631,14,276,3,0,
631,14,278,3,0,
631,14,282,3,0,
631,14,289,1,26,
631,14,289,3,0,
631,14,316,1,6,
631,14,317,4,0,
631,14,321,2,0,
631,14,332,4,0,
631,14,374,4,0,
631,14,380,3,0,
631,14,389,2,0,
631,14,400,2,0,
631,14,411,4,0,
631,14,416,4,0,
631,14,421,4,0,
631,14,450,1,36,
631,14,450,3,0,
631,14,468,4,0,
631,14,481,1,31,
631,14,496,4,0,
631,14,510,1,1,1
631,14,510,4,0,
631,14,517,1,61,
631,15,15,4,0,
631,15,20,1,11,
631,15,33,1,1,3
631,15,34,2,0,
631,15,35,2,0,
631,15,53,1,47,
631,15,53,4,0,
631,15,76,4,0,
631,15,83,1,16,
631,15,91,4,0,
631,15,92,4,0,
631,15,104,4,0,
631,15,122,1,1,5
631,15,126,4,0,
631,15,133,1,44,
631,15,154,1,21,
631,15,156,4,0,
631,15,163,1,41,
631,15,164,4,0,
631,15,168,4,0,
631,15,174,2,0,
631,15,182,4,0,
631,15,185,2,0,
631,15,207,4,0,
631,15,213,4,0,
631,15,214,2,0,
631,15,214,4,0,
631,15,216,4,0,
631,15,218,4,0,
631,15,228,2,0,
631,15,237,4,0,
631,15,240,4,0,
631,15,241,4,0,
631,15,249,4,0,
631,15,254,1,50,1
631,15,255,1,50,2
631,15,256,1,50,3
631,15,257,2,0,
631,15,261,4,0,
631,15,263,4,0,
631,15,269,4,0,
631,15,289,1,26,
631,15,316,1,6,
631,15,317,4,0,
631,15,321,2,0,
631,15,332,4,0,
631,15,374,4,0,
631,15,389,2,0,
631,15,400,2,0,
631,15,411,4,0,
631,15,416,4,0,
631,15,421,4,0,
631,15,450,1,36,
631,15,468,1,1,2
631,15,468,4,0,
631,15,481,1,31,
631,15,496,4,0,
631,15,510,1,1,4
631,15,510,4,0,
631,15,517,1,1,1
631,15,517,1,61,
631,15,562,2,0,
631,15,590,4,0,
631,15,612,4,0,
631,16,7,3,0,
631,16,9,3,0,
631,16,15,4,0,
631,16,20,1,11,1
631,16,20,3,0,
631,16,33,1,1,3
631,16,34,2,0,
631,16,35,2,0,
631,16,53,1,47,1
631,16,53,4,0,
631,16,67,3,0,
631,16,76,4,0,
631,16,83,1,16,1
631,16,91,4,0,
631,16,92,4,0,
631,16,104,4,0,
631,16,122,1,1,5
631,16,126,4,0,
631,16,133,1,44,1
631,16,154,1,21,1
631,16,156,4,0,
631,16,163,1,41,1
631,16,164,4,0,
631,16,168,4,0,
631,16,173,3,0,
631,16,174,2,0,
631,16,182,4,0,
631,16,185,2,0,
631,16,202,3,0,
631,16,207,4,0,
631,16,213,4,0,
631,16,214,2,0,
631,16,214,4,0,
631,16,216,4,0,
631,16,218,4,0,
631,16,228,2,0,
631,16,237,4,0,
631,16,240,4,0,
631,16,241,4,0,
631,16,249,4,0,
631,16,254,1,50,1
631,16,255,1,50,2
631,16,256,1,50,3
631,16,257,2,0,
631,16,257,3,0,
631,16,261,4,0,
631,16,263,4,0,
631,16,264,3,0,
631,16,269,4,0,
631,16,276,3,0,
631,16,278,3,0,
631,16,282,3,0,
631,16,289,1,26,1
631,16,289,3,0,
631,16,290,4,0,
631,16,316,1,6,1
631,16,317,4,0,
631,16,321,2,0,
631,16,332,4,0,
631,16,374,4,0,
631,16,380,3,0,
631,16,389,2,0,
631,16,400,2,0,
631,16,411,4,0,
631,16,416,4,0,
631,16,421,4,0,
631,16,450,1,36,1
631,16,450,3,0,
631,16,468,1,1,2
631,16,468,4,0,
631,16,481,1,31,1
631,16,496,4,0,
631,16,510,1,1,4
631,16,510,4,0,
631,16,517,1,1,1
631,16,517,1,61,1
631,16,562,2,0,
631,16,590,4,0,
631,16,612,4,0,
631,17,20,1,11,
631,17,33,1,1,2
631,17,34,2,0,
631,17,35,2,0,
631,17,53,1,50,
631,17,53,4,0,
631,17,76,4,0,
631,17,83,1,16,
631,17,92,4,0,
631,17,104,4,0,
631,17,122,1,1,4
631,17,126,4,0,
631,17,133,1,47,
631,17,154,1,21,
631,17,156,4,0,
631,17,163,1,41,
631,17,164,4,0,
631,17,168,4,0,
631,17,174,2,0,
631,17,182,4,0,
631,17,185,2,0,
631,17,207,4,0,
631,17,213,4,0,
631,17,214,2,0,
631,17,214,4,0,
631,17,216,4,0,
631,17,218,4,0,
631,17,228,2,0,
631,17,237,4,0,
631,17,240,4,0,
631,17,241,4,0,
631,17,254,1,56,1
631,17,255,1,56,2
631,17,256,1,56,3
631,17,257,2,0,
631,17,261,4,0,
631,17,263,4,0,
631,17,269,4,0,
631,17,289,1,26,
631,17,315,4,0,
631,17,316,1,6,
631,17,317,4,0,
631,17,321,2,0,
631,17,332,4,0,
631,17,374,4,0,
631,17,389,2,0,
631,17,394,1,61,
631,17,400,2,0,
631,17,411,4,0,
631,17,416,4,0,
631,17,421,4,0,
631,17,450,1,36,
631,17,468,1,1,1
631,17,481,1,31,
631,17,496,4,0,
631,17,510,1,1,3
631,17,517,1,66,
631,17,562,2,0,
631,17,590,4,0,
631,17,680,1,44,
631,18,20,1,11,
631,18,33,1,1,2
631,18,34,2,0,
631,18,35,2,0,
631,18,53,1,50,
631,18,53,4,0,
631,18,76,4,0,
631,18,83,1,16,
631,18,92,4,0,
631,18,104,4,0,
631,18,122,1,1,4
631,18,126,4,0,
631,18,133,1,47,
631,18,154,1,21,
631,18,156,4,0,
631,18,163,1,41,
631,18,164,4,0,
631,18,168,4,0,
631,18,174,2,0,
631,18,182,4,0,
631,18,185,2,0,
631,18,207,4,0,
631,18,213,4,0,
631,18,214,2,0,
631,18,214,4,0,
631,18,216,4,0,
631,18,218,4,0,
631,18,228,2,0,
631,18,237,4,0,
631,18,240,4,0,
631,18,241,4,0,
631,18,254,1,56,1
631,18,255,1,56,2
631,18,256,1,56,3
631,18,257,2,0,
631,18,261,4,0,
631,18,263,4,0,
631,18,269,4,0,
631,18,289,1,26,
631,18,315,4,0,
631,18,316,1,6,
631,18,317,4,0,
631,18,321,2,0,
631,18,332,4,0,
631,18,374,4,0,
631,18,389,2,0,
631,18,394,1,61,
631,18,400,2,0,
631,18,411,4,0,
631,18,416,4,0,
631,18,421,4,0,
631,18,450,1,36,
631,18,468,1,1,1
631,18,481,1,31,
631,18,496,4,0,
631,18,510,1,1,3
631,18,517,1,66,
631,18,562,2,0,
631,18,590,4,0,
631,18,680,1,44,
632,11,11,1,1,1
632,11,12,1,61,
632,11,15,4,0,
632,11,28,1,1,2
632,11,44,1,11,
632,11,70,4,0,
632,11,86,4,0,
632,11,91,1,41,
632,11,91,4,0,
632,11,92,4,0,
632,11,97,1,16,
632,11,103,2,0,
632,11,104,4,0,
632,11,156,4,0,
632,11,157,4,0,
632,11,164,4,0,
632,11,182,4,0,
632,11,185,2,0,
632,11,201,4,0,
632,11,203,2,0,
632,11,207,4,0,
632,11,210,1,6,
632,11,213,4,0,
632,11,216,4,0,
632,11,218,4,0,
632,11,226,2,0,
632,11,232,1,21,
632,11,237,4,0,
632,11,242,1,31,
632,11,249,4,0,
632,11,263,4,0,
632,11,317,4,0,
632,11,319,1,66,
632,11,332,4,0,
632,11,334,1,56,
632,11,397,4,0,
632,11,404,1,51,
632,11,404,4,0,
632,11,412,4,0,
632,11,416,4,0,
632,11,421,4,0,
632,11,422,2,0,
632,11,430,4,0,
632,11,431,2,0,
632,11,442,1,36,
632,11,444,4,0,
632,11,450,1,26,
632,11,468,4,0,
632,11,494,1,46,
632,11,496,4,0,
632,11,514,4,0,
632,11,522,4,0,
632,14,11,1,1,1
632,14,12,1,61,
632,14,15,4,0,
632,14,28,1,1,2
632,14,44,1,11,
632,14,70,4,0,
632,14,86,4,0,
632,14,91,1,41,
632,14,91,4,0,
632,14,92,4,0,
632,14,97,1,16,
632,14,103,2,0,
632,14,104,4,0,
632,14,156,4,0,
632,14,157,4,0,
632,14,164,4,0,
632,14,173,3,0,
632,14,182,4,0,
632,14,185,2,0,
632,14,201,4,0,
632,14,203,2,0,
632,14,207,4,0,
632,14,210,1,6,
632,14,213,4,0,
632,14,214,3,0,
632,14,216,4,0,
632,14,218,4,0,
632,14,226,2,0,
632,14,232,1,21,
632,14,237,4,0,
632,14,242,1,31,
632,14,249,4,0,
632,14,263,4,0,
632,14,276,3,0,
632,14,283,3,0,
632,14,317,4,0,
632,14,319,1,66,
632,14,332,4,0,
632,14,334,1,56,
632,14,334,3,0,
632,14,397,4,0,
632,14,404,1,51,
632,14,404,4,0,
632,14,412,4,0,
632,14,416,4,0,
632,14,421,4,0,
632,14,422,2,0,
632,14,430,4,0,
632,14,431,2,0,
632,14,442,1,36,
632,14,442,3,0,
632,14,444,4,0,
632,14,450,1,26,
632,14,450,3,0,
632,14,468,4,0,
632,14,494,1,46,
632,14,496,4,0,
632,14,514,4,0,
632,14,522,4,0,
632,15,11,1,1,4
632,15,12,1,1,1
632,15,12,1,61,
632,15,15,4,0,
632,15,28,1,1,5
632,15,44,1,11,
632,15,70,4,0,
632,15,86,4,0,
632,15,91,1,41,
632,15,91,4,0,
632,15,92,4,0,
632,15,97,1,16,
632,15,103,2,0,
632,15,104,4,0,
632,15,156,4,0,
632,15,157,4,0,
632,15,164,4,0,
632,15,182,4,0,
632,15,185,2,0,
632,15,201,4,0,
632,15,203,2,0,
632,15,207,4,0,
632,15,210,1,6,
632,15,213,4,0,
632,15,214,4,0,
632,15,216,4,0,
632,15,218,4,0,
632,15,226,2,0,
632,15,232,1,21,
632,15,237,4,0,
632,15,242,1,31,
632,15,249,4,0,
632,15,263,4,0,
632,15,317,4,0,
632,15,319,1,1,3
632,15,319,1,66,
632,15,332,4,0,
632,15,334,1,1,2
632,15,334,1,56,
632,15,397,4,0,
632,15,404,1,51,
632,15,404,4,0,
632,15,412,4,0,
632,15,416,4,0,
632,15,421,4,0,
632,15,422,2,0,
632,15,430,4,0,
632,15,431,2,0,
632,15,442,1,36,
632,15,444,4,0,
632,15,450,1,26,
632,15,468,4,0,
632,15,494,1,46,
632,15,496,4,0,
632,15,514,4,0,
632,15,522,4,0,
632,15,590,4,0,
632,16,11,1,1,4
632,16,12,1,1,1
632,16,12,1,61,1
632,16,15,4,0,
632,16,28,1,1,5
632,16,44,1,11,1
632,16,70,4,0,
632,16,86,4,0,
632,16,91,1,41,1
632,16,91,4,0,
632,16,92,4,0,
632,16,97,1,16,1
632,16,103,2,0,
632,16,104,4,0,
632,16,156,4,0,
632,16,157,4,0,
632,16,164,4,0,
632,16,173,3,0,
632,16,182,4,0,
632,16,185,2,0,
632,16,201,4,0,
632,16,203,2,0,
632,16,207,4,0,
632,16,210,1,6,1
632,16,213,4,0,
632,16,214,4,0,
632,16,216,4,0,
632,16,218,4,0,
632,16,226,2,0,
632,16,232,1,21,1
632,16,237,4,0,
632,16,242,1,31,1
632,16,249,4,0,
632,16,263,4,0,
632,16,276,3,0,
632,16,283,3,0,
632,16,290,4,0,
632,16,317,4,0,
632,16,319,1,1,3
632,16,319,1,66,1
632,16,332,4,0,
632,16,334,1,1,2
632,16,334,1,56,1
632,16,334,3,0,
632,16,397,4,0,
632,16,404,1,51,1
632,16,404,4,0,
632,16,412,4,0,
632,16,416,4,0,
632,16,421,4,0,
632,16,422,2,0,
632,16,430,4,0,
632,16,431,2,0,
632,16,442,1,36,1
632,16,442,3,0,
632,16,444,4,0,
632,16,450,1,26,1
632,16,450,3,0,
632,16,468,4,0,
632,16,494,1,46,1
632,16,496,4,0,
632,16,514,4,0,
632,16,522,4,0,
632,16,590,4,0,
632,17,11,1,1,3
632,17,12,1,1,2
632,17,12,1,51,
632,17,28,1,1,4
632,17,44,1,1,6
632,17,86,4,0,
632,17,91,1,31,
632,17,92,4,0,
632,17,97,1,6,
632,17,103,2,0,
632,17,104,4,0,
632,17,156,4,0,
632,17,157,4,0,
632,17,164,4,0,
632,17,182,4,0,
632,17,185,2,0,
632,17,201,4,0,
632,17,203,2,0,
632,17,207,4,0,
632,17,210,1,1,5
632,17,213,4,0,
632,17,214,4,0,
632,17,216,4,0,
632,17,218,4,0,
632,17,226,2,0,
632,17,232,1,11,
632,17,237,4,0,
632,17,242,1,21,
632,17,263,4,0,
632,17,317,4,0,
632,17,319,1,1,1
632,17,319,1,56,
632,17,332,4,0,
632,17,334,1,46,
632,17,397,4,0,
632,17,404,1,41,
632,17,404,4,0,
632,17,412,4,0,
632,17,416,4,0,
632,17,421,4,0,
632,17,422,2,0,
632,17,430,4,0,
632,17,431,2,0,
632,17,442,1,26,
632,17,444,4,0,
632,17,450,1,16,
632,17,494,1,36,
632,17,496,4,0,
632,17,590,4,0,
632,18,11,1,1,3
632,18,12,1,1,2
632,18,12,1,51,
632,18,28,1,1,4
632,18,44,1,1,6
632,18,86,4,0,
632,18,91,1,31,
632,18,92,4,0,
632,18,97,1,6,
632,18,103,2,0,
632,18,104,4,0,
632,18,156,4,0,
632,18,157,4,0,
632,18,164,4,0,
632,18,182,4,0,
632,18,185,2,0,
632,18,201,4,0,
632,18,203,2,0,
632,18,207,4,0,
632,18,210,1,1,5
632,18,213,4,0,
632,18,214,4,0,
632,18,216,4,0,
632,18,218,4,0,
632,18,226,2,0,
632,18,232,1,11,
632,18,237,4,0,
632,18,242,1,21,
632,18,263,4,0,
632,18,317,4,0,
632,18,319,1,1,1
632,18,319,1,56,
632,18,332,4,0,
632,18,334,1,46,
632,18,397,4,0,
632,18,404,1,41,
632,18,404,4,0,
632,18,412,4,0,
632,18,416,4,0,
632,18,421,4,0,
632,18,422,2,0,
632,18,430,4,0,
632,18,431,2,0,
632,18,442,1,26,
632,18,444,4,0,
632,18,450,1,16,
632,18,494,1,36,
632,18,496,4,0,
632,18,590,4,0,
633,11,21,1,28,
633,11,29,1,12,
633,11,33,1,1,1
633,11,34,1,48,
633,11,44,1,9,
633,11,46,1,20,
633,11,46,4,0,
633,11,70,4,0,
633,11,82,1,1,2
633,11,86,4,0,
633,11,92,4,0,
633,11,103,2,0,
633,11,104,4,0,
633,11,116,1,4,
633,11,156,4,0,
633,11,164,4,0,
633,11,168,4,0,
633,11,182,4,0,
633,11,184,1,52,
633,11,200,1,62,
633,11,207,4,0,
633,11,213,4,0,
633,11,216,4,0,
633,11,218,4,0,
633,11,225,1,17,
633,11,237,4,0,
633,11,240,4,0,
633,11,241,4,0,
633,11,242,1,25,
633,11,244,4,0,
633,11,249,4,0,
633,11,259,4,0,
633,11,263,4,0,
633,11,269,4,0,
633,11,304,1,58,
633,11,310,2,0,
633,11,372,2,0,
633,11,399,2,0,
633,11,406,1,32,
633,11,407,1,42,
633,11,414,2,0,
633,11,422,2,0,
633,11,423,2,0,
633,11,424,2,0,
633,11,434,3,0,
633,11,457,2,0,
633,11,458,2,0,
633,11,496,4,0,
633,11,510,4,0,
633,11,525,4,0,
633,11,526,1,38,
633,11,526,4,0,
633,14,21,1,28,
633,14,29,1,12,
633,14,33,1,1,1
633,14,34,1,48,
633,14,44,1,9,
633,14,46,1,20,
633,14,46,4,0,
633,14,70,4,0,
633,14,82,1,1,2
633,14,86,4,0,
633,14,92,4,0,
633,14,103,2,0,
633,14,104,4,0,
633,14,116,1,4,
633,14,156,4,0,
633,14,164,4,0,
633,14,168,4,0,
633,14,173,3,0,
633,14,180,3,0,
633,14,182,4,0,
633,14,184,1,52,
633,14,200,1,62,
633,14,200,3,0,
633,14,207,4,0,
633,14,213,4,0,
633,14,214,3,0,
633,14,216,4,0,
633,14,218,4,0,
633,14,225,1,17,
633,14,237,4,0,
633,14,240,4,0,
633,14,241,4,0,
633,14,242,1,25,
633,14,244,4,0,
633,14,249,4,0,
633,14,253,3,0,
633,14,259,4,0,
633,14,263,4,0,
633,14,269,4,0,
633,14,276,3,0,
633,14,304,1,58,
633,14,304,3,0,
633,14,310,2,0,
633,14,372,2,0,
633,14,399,2,0,
633,14,399,3,0,
633,14,401,3,0,
633,14,406,1,32,
633,14,406,3,0,
633,14,407,1,42,
633,14,414,2,0,
633,14,414,3,0,
633,14,422,2,0,
633,14,423,2,0,
633,14,424,2,0,
633,14,428,3,0,
633,14,434,3,0,
633,14,457,2,0,
633,14,458,2,0,
633,14,496,4,0,
633,14,510,4,0,
633,14,525,4,0,
633,14,526,1,38,
633,14,526,4,0,
633,15,21,1,28,
633,15,29,1,12,
633,15,33,1,1,1
633,15,34,1,48,
633,15,44,1,9,
633,15,46,1,20,
633,15,46,4,0,
633,15,70,4,0,
633,15,82,1,1,2
633,15,86,4,0,
633,15,92,4,0,
633,15,103,2,0,
633,15,104,4,0,
633,15,116,1,4,
633,15,156,4,0,
633,15,164,4,0,
633,15,168,4,0,
633,15,182,4,0,
633,15,184,1,50,
633,15,200,1,62,
633,15,207,4,0,
633,15,213,4,0,
633,15,214,4,0,
633,15,216,4,0,
633,15,218,4,0,
633,15,225,1,17,
633,15,237,4,0,
633,15,240,4,0,
633,15,241,4,0,
633,15,242,1,25,
633,15,244,4,0,
633,15,249,4,0,
633,15,259,4,0,
633,15,263,4,0,
633,15,269,4,0,
633,15,304,1,58,
633,15,310,2,0,
633,15,372,2,0,
633,15,399,2,0,
633,15,399,4,0,
633,15,406,1,32,
633,15,407,1,42,
633,15,414,2,0,
633,15,422,2,0,
633,15,423,2,0,
633,15,424,2,0,
633,15,434,3,0,
633,15,457,2,0,
633,15,458,2,0,
633,15,496,4,0,
633,15,510,4,0,
633,15,525,4,0,
633,15,526,1,38,
633,15,590,4,0,
633,16,21,1,28,1
633,16,29,1,12,1
633,16,33,1,1,1
633,16,34,1,48,1
633,16,44,1,9,1
633,16,46,1,20,1
633,16,46,4,0,
633,16,70,4,0,
633,16,82,1,1,2
633,16,86,4,0,
633,16,92,4,0,
633,16,103,2,0,
633,16,104,4,0,
633,16,116,1,4,1
633,16,156,4,0,
633,16,164,4,0,
633,16,168,4,0,
633,16,173,3,0,
633,16,180,3,0,
633,16,182,4,0,
633,16,184,1,50,1
633,16,200,1,62,1
633,16,200,3,0,
633,16,207,4,0,
633,16,213,4,0,
633,16,214,4,0,
633,16,216,4,0,
633,16,218,4,0,
633,16,225,1,17,1
633,16,237,4,0,
633,16,240,4,0,
633,16,241,4,0,
633,16,242,1,25,1
633,16,244,4,0,
633,16,249,4,0,
633,16,253,3,0,
633,16,259,4,0,
633,16,263,4,0,
633,16,269,4,0,
633,16,276,3,0,
633,16,290,4,0,
633,16,304,1,58,1
633,16,304,3,0,
633,16,310,2,0,
633,16,351,3,0,
633,16,372,2,0,
633,16,399,2,0,
633,16,399,4,0,
633,16,401,3,0,
633,16,406,1,32,1
633,16,406,3,0,
633,16,407,1,42,1
633,16,414,2,0,
633,16,414,3,0,
633,16,422,2,0,
633,16,423,2,0,
633,16,424,2,0,
633,16,428,3,0,
633,16,434,3,0,
633,16,457,2,0,
633,16,458,2,0,
633,16,496,4,0,
633,16,510,4,0,
633,16,525,4,0,
633,16,526,1,38,1
633,16,590,4,0,
633,17,21,1,28,
633,17,29,1,12,
633,17,33,1,1,1
633,17,34,1,48,
633,17,44,1,9,
633,17,46,1,20,
633,17,46,4,0,
633,17,82,1,1,2
633,17,86,4,0,
633,17,92,4,0,
633,17,103,2,0,
633,17,104,4,0,
633,17,116,1,4,
633,17,156,4,0,
633,17,164,4,0,
633,17,168,4,0,
633,17,182,4,0,
633,17,184,1,50,
633,17,200,1,62,
633,17,207,4,0,
633,17,213,4,0,
633,17,214,4,0,
633,17,216,4,0,
633,17,218,4,0,
633,17,225,1,17,
633,17,237,4,0,
633,17,240,4,0,
633,17,241,4,0,
633,17,242,1,25,
633,17,244,4,0,
633,17,259,4,0,
633,17,263,4,0,
633,17,269,4,0,
633,17,304,1,58,
633,17,310,2,0,
633,17,372,2,0,
633,17,399,2,0,
633,17,399,4,0,
633,17,406,1,32,
633,17,407,1,42,
633,17,414,2,0,
633,17,422,2,0,
633,17,423,2,0,
633,17,424,2,0,
633,17,434,3,0,
633,17,457,2,0,
633,17,458,2,0,
633,17,496,4,0,
633,17,525,4,0,
633,17,526,1,38,
633,17,526,4,0,
633,17,590,4,0,
633,18,21,1,28,
633,18,29,1,12,
633,18,33,1,1,1
633,18,34,1,48,
633,18,44,1,9,
633,18,46,1,20,
633,18,46,4,0,
633,18,82,1,1,2
633,18,86,4,0,
633,18,92,4,0,
633,18,103,2,0,
633,18,104,4,0,
633,18,116,1,4,
633,18,156,4,0,
633,18,164,4,0,
633,18,168,4,0,
633,18,182,4,0,
633,18,184,1,50,
633,18,200,1,62,
633,18,207,4,0,
633,18,213,4,0,
633,18,214,4,0,
633,18,216,4,0,
633,18,218,4,0,
633,18,225,1,17,
633,18,237,4,0,
633,18,240,4,0,
633,18,241,4,0,
633,18,242,1,25,
633,18,244,4,0,
633,18,259,4,0,
633,18,263,4,0,
633,18,269,4,0,
633,18,304,1,58,
633,18,310,2,0,
633,18,372,2,0,
633,18,399,2,0,
633,18,399,4,0,
633,18,406,1,32,
633,18,407,1,42,
633,18,414,2,0,
633,18,422,2,0,
633,18,423,2,0,
633,18,424,2,0,
633,18,434,3,0,
633,18,457,2,0,
633,18,458,2,0,
633,18,496,4,0,
633,18,525,4,0,
633,18,526,1,38,
633,18,526,4,0,
633,18,562,2,0,
633,18,590,4,0,
634,11,21,1,28,
634,11,29,1,12,
634,11,34,1,48,
634,11,44,1,1,4
634,11,44,1,9,
634,11,46,1,20,
634,11,46,4,0,
634,11,70,4,0,
634,11,82,1,1,2
634,11,86,4,0,
634,11,92,4,0,
634,11,104,4,0,
634,11,116,1,1,3
634,11,116,1,4,
634,11,156,4,0,
634,11,164,4,0,
634,11,168,4,0,
634,11,182,4,0,
634,11,184,1,55,
634,11,200,1,71,
634,11,207,4,0,
634,11,213,4,0,
634,11,216,4,0,
634,11,218,4,0,
634,11,225,1,17,
634,11,237,4,0,
634,11,240,4,0,
634,11,241,4,0,
634,11,242,1,25,
634,11,244,4,0,
634,11,249,4,0,
634,11,259,4,0,
634,11,263,4,0,
634,11,269,4,0,
634,11,304,1,64,
634,11,406,1,32,
634,11,407,1,42,
634,11,434,3,0,
634,11,458,1,1,1
634,11,496,4,0,
634,11,510,4,0,
634,11,525,4,0,
634,11,526,1,38,
634,11,526,4,0,
634,14,21,1,28,
634,14,29,1,12,
634,14,34,1,48,
634,14,44,1,1,4
634,14,44,1,9,
634,14,46,1,20,
634,14,46,4,0,
634,14,70,4,0,
634,14,82,1,1,2
634,14,86,4,0,
634,14,92,4,0,
634,14,104,4,0,
634,14,116,1,1,3
634,14,116,1,4,
634,14,156,4,0,
634,14,164,4,0,
634,14,168,4,0,
634,14,173,3,0,
634,14,180,3,0,
634,14,182,4,0,
634,14,184,1,55,
634,14,200,1,71,
634,14,200,3,0,
634,14,207,4,0,
634,14,213,4,0,
634,14,214,3,0,
634,14,216,4,0,
634,14,218,4,0,
634,14,225,1,17,
634,14,237,4,0,
634,14,240,4,0,
634,14,241,4,0,
634,14,242,1,25,
634,14,244,4,0,
634,14,249,4,0,
634,14,253,3,0,
634,14,259,4,0,
634,14,263,4,0,
634,14,269,4,0,
634,14,276,3,0,
634,14,304,1,64,
634,14,304,3,0,
634,14,399,3,0,
634,14,401,3,0,
634,14,406,1,32,
634,14,406,3,0,
634,14,407,1,42,
634,14,414,3,0,
634,14,428,3,0,
634,14,434,3,0,
634,14,458,1,1,1
634,14,496,4,0,
634,14,510,4,0,
634,14,525,4,0,
634,14,526,1,38,
634,14,526,4,0,
634,15,21,1,28,
634,15,29,1,12,
634,15,34,1,48,
634,15,44,1,1,4
634,15,44,1,9,
634,15,46,1,20,
634,15,46,4,0,
634,15,70,4,0,
634,15,82,1,1,2
634,15,86,4,0,
634,15,92,4,0,
634,15,104,4,0,
634,15,116,1,1,3
634,15,116,1,4,
634,15,156,4,0,
634,15,164,4,0,
634,15,168,4,0,
634,15,182,4,0,
634,15,184,1,55,
634,15,200,1,71,
634,15,207,4,0,
634,15,213,4,0,
634,15,214,4,0,
634,15,216,4,0,
634,15,218,4,0,
634,15,225,1,17,
634,15,237,4,0,
634,15,240,4,0,
634,15,241,4,0,
634,15,242,1,25,
634,15,244,4,0,
634,15,249,4,0,
634,15,259,4,0,
634,15,263,4,0,
634,15,269,4,0,
634,15,304,1,64,
634,15,399,4,0,
634,15,406,1,32,
634,15,407,1,42,
634,15,434,3,0,
634,15,458,1,1,1
634,15,496,4,0,
634,15,510,4,0,
634,15,525,4,0,
634,15,526,1,38,
634,15,590,4,0,
634,16,21,1,28,1
634,16,29,1,12,1
634,16,34,1,48,1
634,16,44,1,1,4
634,16,44,1,9,1
634,16,46,1,20,1
634,16,46,4,0,
634,16,70,4,0,
634,16,82,1,1,2
634,16,86,4,0,
634,16,92,4,0,
634,16,104,4,0,
634,16,116,1,1,3
634,16,116,1,4,1
634,16,156,4,0,
634,16,164,4,0,
634,16,168,4,0,
634,16,173,3,0,
634,16,180,3,0,
634,16,182,4,0,
634,16,184,1,55,1
634,16,200,1,71,1
634,16,200,3,0,
634,16,207,4,0,
634,16,213,4,0,
634,16,214,4,0,
634,16,216,4,0,
634,16,218,4,0,
634,16,225,1,17,1
634,16,237,4,0,
634,16,240,4,0,
634,16,241,4,0,
634,16,242,1,25,1
634,16,244,4,0,
634,16,249,4,0,
634,16,253,3,0,
634,16,259,4,0,
634,16,263,4,0,
634,16,269,4,0,
634,16,276,3,0,
634,16,290,4,0,
634,16,304,1,64,1
634,16,304,3,0,
634,16,351,3,0,
634,16,399,4,0,
634,16,401,3,0,
634,16,406,1,32,1
634,16,406,3,0,
634,16,407,1,42,1
634,16,414,3,0,
634,16,428,3,0,
634,16,434,3,0,
634,16,458,1,1,1
634,16,496,4,0,
634,16,510,4,0,
634,16,525,4,0,
634,16,526,1,38,1
634,16,590,4,0,
634,17,21,1,28,
634,17,29,1,12,
634,17,34,1,48,
634,17,44,1,1,4
634,17,44,1,9,
634,17,46,1,20,
634,17,46,4,0,
634,17,82,1,1,2
634,17,86,4,0,
634,17,92,4,0,
634,17,104,4,0,
634,17,116,1,1,3
634,17,116,1,4,
634,17,156,4,0,
634,17,164,4,0,
634,17,168,4,0,
634,17,182,4,0,
634,17,184,1,55,
634,17,200,1,71,
634,17,207,4,0,
634,17,213,4,0,
634,17,214,4,0,
634,17,216,4,0,
634,17,218,4,0,
634,17,225,1,17,
634,17,237,4,0,
634,17,240,4,0,
634,17,241,4,0,
634,17,242,1,25,
634,17,244,4,0,
634,17,259,4,0,
634,17,263,4,0,
634,17,269,4,0,
634,17,304,1,64,
634,17,399,4,0,
634,17,406,1,32,
634,17,407,1,42,
634,17,434,3,0,
634,17,458,1,1,1
634,17,496,4,0,
634,17,525,4,0,
634,17,526,1,38,
634,17,526,4,0,
634,17,590,4,0,
634,18,21,1,28,
634,18,29,1,12,
634,18,34,1,48,
634,18,44,1,1,4
634,18,44,1,9,
634,18,46,1,20,
634,18,46,4,0,
634,18,82,1,1,2
634,18,86,4,0,
634,18,92,4,0,
634,18,104,4,0,
634,18,116,1,1,3
634,18,116,1,4,
634,18,156,4,0,
634,18,164,4,0,
634,18,168,4,0,
634,18,182,4,0,
634,18,184,1,55,
634,18,200,1,71,
634,18,207,4,0,
634,18,213,4,0,
634,18,214,4,0,
634,18,216,4,0,
634,18,218,4,0,
634,18,225,1,17,
634,18,237,4,0,
634,18,240,4,0,
634,18,241,4,0,
634,18,242,1,25,
634,18,244,4,0,
634,18,259,4,0,
634,18,263,4,0,
634,18,269,4,0,
634,18,304,1,64,
634,18,399,4,0,
634,18,406,1,32,
634,18,407,1,42,
634,18,434,3,0,
634,18,458,1,1,1
634,18,496,4,0,
634,18,525,4,0,
634,18,526,1,38,
634,18,526,4,0,
634,18,590,4,0,
635,11,19,4,0,
635,11,21,1,28,
635,11,29,1,12,
635,11,34,1,48,
635,11,44,1,1,4
635,11,44,1,9,
635,11,46,1,20,
635,11,46,4,0,
635,11,53,4,0,
635,11,57,4,0,
635,11,63,4,0,
635,11,70,4,0,
635,11,82,1,1,2
635,11,86,4,0,
635,11,89,4,0,
635,11,92,4,0,
635,11,104,4,0,
635,11,115,4,0,
635,11,116,1,1,3
635,11,116,1,4,
635,11,126,4,0,
635,11,156,4,0,
635,11,157,4,0,
635,11,161,1,1,1
635,11,164,4,0,
635,11,168,4,0,
635,11,182,4,0,
635,11,184,1,55,
635,11,200,1,79,
635,11,207,4,0,
635,11,213,4,0,
635,11,216,4,0,
635,11,218,4,0,
635,11,225,1,17,
635,11,237,4,0,
635,11,240,4,0,
635,11,241,4,0,
635,11,242,1,25,
635,11,244,4,0,
635,11,249,4,0,
635,11,259,4,0,
635,11,263,4,0,
635,11,269,4,0,
635,11,304,1,68,
635,11,317,4,0,
635,11,369,4,0,
635,11,371,4,0,
635,11,406,1,32,
635,11,407,1,42,
635,11,411,4,0,
635,11,416,4,0,
635,11,430,4,0,
635,11,434,3,0,
635,11,444,4,0,
635,11,451,4,0,
635,11,496,4,0,
635,11,497,4,0,
635,11,510,4,0,
635,11,512,4,0,
635,11,523,4,0,
635,11,525,4,0,
635,11,526,1,38,
635,11,526,4,0,
635,14,19,4,0,
635,14,21,1,28,
635,14,29,1,12,
635,14,34,1,48,
635,14,44,1,1,4
635,14,44,1,9,
635,14,46,1,20,
635,14,46,4,0,
635,14,53,4,0,
635,14,57,4,0,
635,14,63,4,0,
635,14,70,4,0,
635,14,82,1,1,2
635,14,86,4,0,
635,14,89,4,0,
635,14,92,4,0,
635,14,104,4,0,
635,14,115,4,0,
635,14,116,1,1,3
635,14,116,1,4,
635,14,126,4,0,
635,14,156,4,0,
635,14,157,4,0,
635,14,161,1,1,1
635,14,164,4,0,
635,14,168,4,0,
635,14,173,3,0,
635,14,180,3,0,
635,14,182,4,0,
635,14,184,1,55,
635,14,200,1,79,
635,14,200,3,0,
635,14,207,4,0,
635,14,213,4,0,
635,14,214,3,0,
635,14,216,4,0,
635,14,218,4,0,
635,14,225,1,17,
635,14,231,3,0,
635,14,237,4,0,
635,14,240,4,0,
635,14,241,4,0,
635,14,242,1,25,
635,14,244,4,0,
635,14,249,4,0,
635,14,253,3,0,
635,14,257,3,0,
635,14,259,4,0,
635,14,263,4,0,
635,14,269,4,0,
635,14,276,3,0,
635,14,304,1,68,
635,14,304,3,0,
635,14,317,4,0,
635,14,324,3,0,
635,14,355,3,0,
635,14,366,3,0,
635,14,369,4,0,
635,14,371,4,0,
635,14,399,3,0,
635,14,401,3,0,
635,14,406,1,32,
635,14,406,3,0,
635,14,407,1,42,
635,14,411,4,0,
635,14,414,3,0,
635,14,416,4,0,
635,14,428,3,0,
635,14,430,4,0,
635,14,434,3,0,
635,14,444,4,0,
635,14,451,4,0,
635,14,496,4,0,
635,14,497,4,0,
635,14,510,4,0,
635,14,512,4,0,
635,14,523,4,0,
635,14,525,4,0,
635,14,526,1,38,
635,14,526,4,0,
635,15,19,4,0,
635,15,21,1,28,
635,15,29,1,12,
635,15,34,1,48,
635,15,44,1,1,6
635,15,44,1,9,
635,15,46,1,20,
635,15,46,4,0,
635,15,53,4,0,
635,15,57,4,0,
635,15,63,4,0,
635,15,70,4,0,
635,15,82,1,1,4
635,15,86,4,0,
635,15,89,4,0,
635,15,92,4,0,
635,15,104,4,0,
635,15,115,4,0,
635,15,116,1,1,5
635,15,116,1,4,
635,15,126,4,0,
635,15,156,4,0,
635,15,157,4,0,
635,15,161,1,1,3
635,15,164,4,0,
635,15,168,4,0,
635,15,182,4,0,
635,15,184,1,55,
635,15,200,1,1,1
635,15,200,1,79,
635,15,207,4,0,
635,15,211,4,0,
635,15,213,4,0,
635,15,214,4,0,
635,15,216,4,0,
635,15,218,4,0,
635,15,225,1,17,
635,15,237,4,0,
635,15,240,4,0,
635,15,241,4,0,
635,15,242,1,25,
635,15,244,4,0,
635,15,249,4,0,
635,15,259,4,0,
635,15,263,4,0,
635,15,269,4,0,
635,15,304,1,1,2
635,15,304,1,68,
635,15,317,4,0,
635,15,355,4,0,
635,15,369,4,0,
635,15,371,4,0,
635,15,399,4,0,
635,15,406,1,32,
635,15,407,1,42,
635,15,411,4,0,
635,15,416,4,0,
635,15,430,4,0,
635,15,434,3,0,
635,15,444,4,0,
635,15,451,4,0,
635,15,496,4,0,
635,15,497,4,0,
635,15,510,4,0,
635,15,512,4,0,
635,15,523,4,0,
635,15,525,4,0,
635,15,526,1,38,
635,15,590,4,0,
635,16,19,4,0,
635,16,21,1,28,1
635,16,29,1,12,1
635,16,34,1,48,1
635,16,44,1,1,6
635,16,44,1,9,1
635,16,46,1,20,1
635,16,46,4,0,
635,16,53,4,0,
635,16,57,4,0,
635,16,63,4,0,
635,16,70,4,0,
635,16,82,1,1,4
635,16,86,4,0,
635,16,89,4,0,
635,16,92,4,0,
635,16,104,4,0,
635,16,115,4,0,
635,16,116,1,1,5
635,16,116,1,4,1
635,16,126,4,0,
635,16,156,4,0,
635,16,157,4,0,
635,16,161,1,1,3
635,16,164,4,0,
635,16,168,4,0,
635,16,173,3,0,
635,16,180,3,0,
635,16,182,4,0,
635,16,184,1,55,1
635,16,200,1,1,1
635,16,200,1,79,1
635,16,200,3,0,
635,16,207,4,0,
635,16,211,4,0,
635,16,213,4,0,
635,16,214,4,0,
635,16,216,4,0,
635,16,218,4,0,
635,16,225,1,17,1
635,16,231,3,0,
635,16,237,4,0,
635,16,240,4,0,
635,16,241,4,0,
635,16,242,1,25,1
635,16,244,4,0,
635,16,249,4,0,
635,16,253,3,0,
635,16,257,3,0,
635,16,259,4,0,
635,16,263,4,0,
635,16,269,4,0,
635,16,276,3,0,
635,16,290,4,0,
635,16,304,1,1,2
635,16,304,1,68,1
635,16,304,3,0,
635,16,317,4,0,
635,16,324,3,0,
635,16,351,3,0,
635,16,355,4,0,
635,16,366,3,0,
635,16,369,4,0,
635,16,371,4,0,
635,16,399,4,0,
635,16,401,3,0,
635,16,406,1,32,1
635,16,406,3,0,
635,16,407,1,42,1
635,16,411,4,0,
635,16,414,3,0,
635,16,416,4,0,
635,16,428,3,0,
635,16,430,4,0,
635,16,434,3,0,
635,16,444,4,0,
635,16,451,4,0,
635,16,496,4,0,
635,16,497,4,0,
635,16,510,4,0,
635,16,512,4,0,
635,16,523,4,0,
635,16,525,4,0,
635,16,526,1,38,1
635,16,590,4,0,
635,17,19,4,0,
635,17,21,1,28,
635,17,29,1,12,
635,17,34,1,48,
635,17,44,1,1,6
635,17,44,1,9,
635,17,46,1,20,
635,17,46,4,0,
635,17,53,4,0,
635,17,57,4,0,
635,17,63,4,0,
635,17,82,1,1,4
635,17,86,4,0,
635,17,89,4,0,
635,17,92,4,0,
635,17,104,4,0,
635,17,115,4,0,
635,17,116,1,1,5
635,17,116,1,4,
635,17,126,4,0,
635,17,156,4,0,
635,17,157,4,0,
635,17,161,1,1,3
635,17,164,4,0,
635,17,168,4,0,
635,17,182,4,0,
635,17,184,1,55,
635,17,200,1,1,1
635,17,200,1,79,
635,17,207,4,0,
635,17,211,4,0,
635,17,213,4,0,
635,17,214,4,0,
635,17,216,4,0,
635,17,218,4,0,
635,17,225,1,17,
635,17,237,4,0,
635,17,240,4,0,
635,17,241,4,0,
635,17,242,1,25,
635,17,244,4,0,
635,17,259,4,0,
635,17,263,4,0,
635,17,269,4,0,
635,17,304,1,1,2
635,17,304,1,68,
635,17,317,4,0,
635,17,355,4,0,
635,17,369,4,0,
635,17,371,4,0,
635,17,399,4,0,
635,17,406,1,32,
635,17,407,1,42,
635,17,411,4,0,
635,17,416,4,0,
635,17,430,4,0,
635,17,434,3,0,
635,17,444,4,0,
635,17,451,4,0,
635,17,496,4,0,
635,17,497,4,0,
635,17,512,4,0,
635,17,523,4,0,
635,17,525,4,0,
635,17,526,1,38,
635,17,526,4,0,
635,17,590,4,0,
635,17,693,4,0,
635,18,19,4,0,
635,18,21,1,28,
635,18,29,1,12,
635,18,34,1,48,
635,18,44,1,1,6
635,18,44,1,9,
635,18,46,1,20,
635,18,46,4,0,
635,18,53,4,0,
635,18,57,4,0,
635,18,63,4,0,
635,18,82,1,1,4
635,18,86,4,0,
635,18,89,4,0,
635,18,92,4,0,
635,18,104,4,0,
635,18,115,4,0,
635,18,116,1,1,5
635,18,116,1,4,
635,18,126,4,0,
635,18,156,4,0,
635,18,157,4,0,
635,18,161,1,1,3
635,18,164,4,0,
635,18,168,4,0,
635,18,182,4,0,
635,18,184,1,55,
635,18,200,1,1,1
635,18,200,1,79,
635,18,207,4,0,
635,18,211,4,0,
635,18,213,4,0,
635,18,214,4,0,
635,18,216,4,0,
635,18,218,4,0,
635,18,225,1,17,
635,18,237,4,0,
635,18,240,4,0,
635,18,241,4,0,
635,18,242,1,25,
635,18,244,4,0,
635,18,259,4,0,
635,18,263,4,0,
635,18,269,4,0,
635,18,304,1,1,2
635,18,304,1,68,
635,18,317,4,0,
635,18,355,4,0,
635,18,369,4,0,
635,18,371,4,0,
635,18,399,4,0,
635,18,406,1,32,
635,18,407,1,42,
635,18,411,4,0,
635,18,416,4,0,
635,18,430,4,0,
635,18,434,3,0,
635,18,444,4,0,
635,18,451,4,0,
635,18,496,4,0,
635,18,497,4,0,
635,18,512,4,0,
635,18,523,4,0,
635,18,525,4,0,
635,18,526,1,38,
635,18,526,4,0,
635,18,590,4,0,
635,18,693,4,0,
636,11,36,1,20,
636,11,37,1,90,
636,11,38,1,50,
636,11,52,1,1,1
636,11,53,4,0,
636,11,76,4,0,
636,11,81,1,1,2
636,11,81,2,0,
636,11,92,4,0,
636,11,94,4,0,
636,11,104,4,0,
636,11,106,2,0,
636,11,113,4,0,
636,11,126,4,0,
636,11,133,1,80,
636,11,141,1,10,
636,11,156,4,0,
636,11,164,4,0,
636,11,172,1,60,
636,11,182,4,0,
636,11,193,2,0,
636,11,203,2,0,
636,11,207,4,0,
636,11,216,4,0,
636,11,218,4,0,
636,11,219,4,0,
636,11,234,2,0,
636,11,237,4,0,
636,11,241,4,0,
636,11,261,4,0,
636,11,263,4,0,
636,11,315,4,0,
636,11,347,4,0,
636,11,369,4,0,
636,11,393,2,0,
636,11,394,1,100,
636,11,405,1,70,
636,11,428,2,0,
636,11,450,1,40,
636,11,488,1,30,
636,11,488,4,0,
636,11,496,4,0,
636,11,510,4,0,
636,11,512,4,0,
636,11,522,4,0,
636,11,528,4,0,
636,14,36,1,20,
636,14,37,1,90,
636,14,38,1,50,
636,14,52,1,1,1
636,14,53,4,0,
636,14,76,4,0,
636,14,81,1,1,2
636,14,81,2,0,
636,14,92,4,0,
636,14,94,4,0,
636,14,104,4,0,
636,14,106,2,0,
636,14,113,4,0,
636,14,126,4,0,
636,14,133,1,80,
636,14,141,1,10,
636,14,156,4,0,
636,14,164,4,0,
636,14,172,1,60,
636,14,173,3,0,
636,14,182,4,0,
636,14,193,2,0,
636,14,202,3,0,
636,14,203,2,0,
636,14,207,4,0,
636,14,214,3,0,
636,14,216,4,0,
636,14,218,4,0,
636,14,219,4,0,
636,14,234,2,0,
636,14,237,4,0,
636,14,241,4,0,
636,14,257,3,0,
636,14,261,4,0,
636,14,263,4,0,
636,14,315,4,0,
636,14,324,3,0,
636,14,347,4,0,
636,14,369,4,0,
636,14,393,2,0,
636,14,393,3,0,
636,14,394,1,100,
636,14,405,1,70,
636,14,428,2,0,
636,14,428,3,0,
636,14,450,1,40,
636,14,450,3,0,
636,14,488,1,30,
636,14,488,4,0,
636,14,496,4,0,
636,14,510,4,0,
636,14,512,4,0,
636,14,522,4,0,
636,14,528,4,0,
636,15,36,1,20,
636,15,37,1,90,
636,15,38,1,50,
636,15,52,1,1,1
636,15,53,4,0,
636,15,76,4,0,
636,15,81,1,1,2
636,15,81,2,0,
636,15,92,4,0,
636,15,94,4,0,
636,15,104,4,0,
636,15,106,2,0,
636,15,113,4,0,
636,15,126,4,0,
636,15,133,1,80,
636,15,141,1,10,
636,15,156,4,0,
636,15,164,4,0,
636,15,172,1,60,
636,15,182,4,0,
636,15,193,2,0,
636,15,203,2,0,
636,15,207,4,0,
636,15,214,4,0,
636,15,216,4,0,
636,15,218,4,0,
636,15,219,4,0,
636,15,234,2,0,
636,15,237,4,0,
636,15,241,4,0,
636,15,261,4,0,
636,15,263,4,0,
636,15,315,4,0,
636,15,347,4,0,
636,15,369,4,0,
636,15,393,2,0,
636,15,394,1,100,
636,15,405,1,70,
636,15,428,2,0,
636,15,450,1,40,
636,15,488,1,30,
636,15,488,4,0,
636,15,496,4,0,
636,15,510,4,0,
636,15,512,4,0,
636,15,522,4,0,
636,15,528,4,0,
636,15,590,4,0,
636,16,36,1,20,1
636,16,37,1,90,1
636,16,38,1,50,1
636,16,52,1,1,1
636,16,53,4,0,
636,16,76,4,0,
636,16,81,1,1,2
636,16,81,2,0,
636,16,92,4,0,
636,16,94,4,0,
636,16,104,4,0,
636,16,106,2,0,
636,16,113,4,0,
636,16,126,4,0,
636,16,133,1,80,1
636,16,141,1,10,1
636,16,156,4,0,
636,16,164,4,0,
636,16,172,1,60,1
636,16,173,3,0,
636,16,182,4,0,
636,16,193,2,0,
636,16,202,3,0,
636,16,203,2,0,
636,16,207,4,0,
636,16,214,4,0,
636,16,216,4,0,
636,16,218,4,0,
636,16,219,4,0,
636,16,234,2,0,
636,16,237,4,0,
636,16,241,4,0,
636,16,257,3,0,
636,16,261,4,0,
636,16,263,4,0,
636,16,290,4,0,
636,16,315,4,0,
636,16,324,3,0,
636,16,347,4,0,
636,16,369,4,0,
636,16,393,2,0,
636,16,393,3,0,
636,16,394,1,100,1
636,16,405,1,70,1
636,16,428,2,0,
636,16,428,3,0,
636,16,450,1,40,1
636,16,450,3,0,
636,16,488,1,30,1
636,16,488,4,0,
636,16,496,4,0,
636,16,510,4,0,
636,16,512,4,0,
636,16,522,4,0,
636,16,528,4,0,
636,16,590,4,0,
636,17,36,1,20,
636,17,37,1,90,
636,17,38,1,50,
636,17,52,1,1,1
636,17,53,4,0,
636,17,71,1,10,
636,17,76,4,0,
636,17,81,1,1,2
636,17,81,2,0,
636,17,92,4,0,
636,17,94,4,0,
636,17,104,4,0,
636,17,106,2,0,
636,17,113,4,0,
636,17,126,4,0,
636,17,133,1,80,
636,17,141,4,0,
636,17,156,4,0,
636,17,164,4,0,
636,17,172,1,60,
636,17,182,4,0,
636,17,193,2,0,
636,17,203,2,0,
636,17,207,4,0,
636,17,214,4,0,
636,17,216,4,0,
636,17,218,4,0,
636,17,219,4,0,
636,17,234,2,0,
636,17,237,4,0,
636,17,241,4,0,
636,17,261,4,0,
636,17,263,4,0,
636,17,315,4,0,
636,17,347,4,0,
636,17,369,4,0,
636,17,393,2,0,
636,17,394,1,100,
636,17,405,1,70,
636,17,428,2,0,
636,17,450,1,40,
636,17,488,1,30,
636,17,488,4,0,
636,17,496,4,0,
636,17,512,4,0,
636,17,528,4,0,
636,17,590,4,0,
636,18,36,1,20,
636,18,37,1,90,
636,18,38,1,50,
636,18,52,1,1,1
636,18,53,4,0,
636,18,71,1,10,
636,18,76,4,0,
636,18,81,1,1,2
636,18,81,2,0,
636,18,92,4,0,
636,18,94,4,0,
636,18,104,4,0,
636,18,106,2,0,
636,18,113,4,0,
636,18,126,4,0,
636,18,133,1,80,
636,18,141,4,0,
636,18,156,4,0,
636,18,164,4,0,
636,18,172,1,60,
636,18,182,4,0,
636,18,193,2,0,
636,18,203,2,0,
636,18,207,4,0,
636,18,214,4,0,
636,18,216,4,0,
636,18,218,4,0,
636,18,219,4,0,
636,18,234,2,0,
636,18,237,4,0,
636,18,241,4,0,
636,18,261,4,0,
636,18,263,4,0,
636,18,315,4,0,
636,18,347,4,0,
636,18,369,4,0,
636,18,393,2,0,
636,18,394,1,100,
636,18,405,1,70,
636,18,428,2,0,
636,18,450,1,40,
636,18,488,1,30,
636,18,488,4,0,
636,18,496,4,0,
636,18,512,4,0,
636,18,528,4,0,
636,18,590,4,0,
637,11,16,1,1,4
637,11,16,1,20,
637,11,18,1,40,
637,11,19,4,0,
637,11,52,1,1,1
637,11,53,4,0,
637,11,63,4,0,
637,11,76,4,0,
637,11,81,1,1,2
637,11,83,1,30,
637,11,92,4,0,
637,11,94,4,0,
637,11,104,4,0,
637,11,113,4,0,
637,11,126,4,0,
637,11,141,1,1,3
637,11,141,1,10,
637,11,156,4,0,
637,11,164,4,0,
637,11,182,4,0,
637,11,207,4,0,
637,11,216,4,0,
637,11,218,4,0,
637,11,219,4,0,
637,11,237,4,0,
637,11,241,4,0,
637,11,257,1,60,
637,11,261,4,0,
637,11,263,4,0,
637,11,315,4,0,
637,11,318,1,50,
637,11,332,4,0,
637,11,347,4,0,
637,11,369,4,0,
637,11,398,4,0,
637,11,405,1,70,
637,11,416,4,0,
637,11,476,1,80,
637,11,483,1,59,
637,11,488,4,0,
637,11,496,4,0,
637,11,510,4,0,
637,11,512,4,0,
637,11,522,4,0,
637,11,528,4,0,
637,11,542,1,90,
637,11,552,1,100,
637,14,16,1,1,4
637,14,16,1,20,
637,14,18,1,40,
637,14,19,4,0,
637,14,52,1,1,1
637,14,53,4,0,
637,14,63,4,0,
637,14,76,4,0,
637,14,81,1,1,2
637,14,83,1,30,
637,14,92,4,0,
637,14,94,4,0,
637,14,104,4,0,
637,14,113,4,0,
637,14,126,4,0,
637,14,141,1,1,3
637,14,141,1,10,
637,14,156,4,0,
637,14,164,4,0,
637,14,173,3,0,
637,14,182,4,0,
637,14,202,3,0,
637,14,207,4,0,
637,14,214,3,0,
637,14,216,4,0,
637,14,218,4,0,
637,14,219,4,0,
637,14,237,4,0,
637,14,241,4,0,
637,14,257,1,60,
637,14,257,3,0,
637,14,261,4,0,
637,14,263,4,0,
637,14,315,4,0,
637,14,318,1,50,
637,14,324,3,0,
637,14,332,4,0,
637,14,347,4,0,
637,14,355,3,0,
637,14,366,3,0,
637,14,369,4,0,
637,14,393,3,0,
637,14,398,4,0,
637,14,405,1,70,
637,14,416,4,0,
637,14,428,3,0,
637,14,450,3,0,
637,14,476,1,80,
637,14,483,1,59,
637,14,488,4,0,
637,14,496,4,0,
637,14,510,4,0,
637,14,512,4,0,
637,14,522,4,0,
637,14,528,4,0,
637,14,542,1,90,
637,14,552,1,100,
637,15,16,1,1,14
637,15,16,1,20,
637,15,18,1,40,
637,15,19,4,0,
637,15,37,1,1,7
637,15,52,1,1,11
637,15,53,4,0,
637,15,63,4,0,
637,15,76,4,0,
637,15,81,1,1,12
637,15,83,1,30,
637,15,92,4,0,
637,15,94,4,0,
637,15,104,4,0,
637,15,113,4,0,
637,15,126,4,0,
637,15,133,1,1,8
637,15,141,1,1,13
637,15,141,1,10,
637,15,156,4,0,
637,15,164,4,0,
637,15,172,1,1,10
637,15,182,4,0,
637,15,207,4,0,
637,15,214,4,0,
637,15,216,4,0,
637,15,218,4,0,
637,15,219,4,0,
637,15,237,4,0,
637,15,241,4,0,
637,15,257,1,1,4
637,15,257,1,60,
637,15,261,4,0,
637,15,263,4,0,
637,15,315,4,0,
637,15,318,1,50,
637,15,332,4,0,
637,15,347,4,0,
637,15,355,4,0,
637,15,369,4,0,
637,15,394,1,1,6
637,15,398,4,0,
637,15,405,1,1,9
637,15,405,1,70,
637,15,416,4,0,
637,15,476,1,1,3
637,15,476,1,80,
637,15,483,1,1,5
637,15,483,1,59,
637,15,488,4,0,
637,15,496,4,0,
637,15,510,4,0,
637,15,512,4,0,
637,15,522,4,0,
637,15,528,4,0,
637,15,542,1,1,2
637,15,542,1,90,
637,15,552,1,1,1
637,15,552,1,100,
637,15,590,4,0,
637,16,16,1,1,14
637,16,16,1,20,1
637,16,18,1,40,1
637,16,19,4,0,
637,16,37,1,1,7
637,16,52,1,1,11
637,16,53,4,0,
637,16,63,4,0,
637,16,76,4,0,
637,16,81,1,1,12
637,16,83,1,30,1
637,16,92,4,0,
637,16,94,4,0,
637,16,104,4,0,
637,16,113,4,0,
637,16,126,4,0,
637,16,133,1,1,8
637,16,141,1,1,13
637,16,141,1,10,1
637,16,156,4,0,
637,16,164,4,0,
637,16,172,1,1,10
637,16,173,3,0,
637,16,182,4,0,
637,16,202,3,0,
637,16,207,4,0,
637,16,214,4,0,
637,16,216,4,0,
637,16,218,4,0,
637,16,219,4,0,
637,16,237,4,0,
637,16,241,4,0,
637,16,257,1,1,4
637,16,257,1,60,1
637,16,257,3,0,
637,16,261,4,0,
637,16,263,4,0,
637,16,290,4,0,
637,16,315,4,0,
637,16,318,1,50,1
637,16,324,3,0,
637,16,332,4,0,
637,16,347,4,0,
637,16,355,4,0,
637,16,366,3,0,
637,16,369,4,0,
637,16,393,3,0,
637,16,394,1,1,6
637,16,398,4,0,
637,16,405,1,1,9
637,16,405,1,70,1
637,16,416,4,0,
637,16,428,3,0,
637,16,450,3,0,
637,16,476,1,1,3
637,16,476,1,80,1
637,16,483,1,1,5
637,16,483,1,59,1
637,16,488,4,0,
637,16,496,4,0,
637,16,510,4,0,
637,16,512,4,0,
637,16,522,4,0,
637,16,528,4,0,
637,16,542,1,1,2
637,16,542,1,90,1
637,16,552,1,1,1
637,16,552,1,100,1
637,16,590,4,0,
637,17,16,1,1,15
637,17,16,1,20,
637,17,18,1,40,
637,17,19,4,0,
637,17,37,1,1,8
637,17,52,1,1,12
637,17,53,4,0,
637,17,63,4,0,
637,17,71,1,1,14
637,17,71,1,10,
637,17,76,4,0,
637,17,81,1,1,13
637,17,83,1,30,
637,17,92,4,0,
637,17,94,4,0,
637,17,104,4,0,
637,17,113,4,0,
637,17,126,4,0,
637,17,133,1,1,9
637,17,141,4,0,
637,17,156,4,0,
637,17,164,4,0,
637,17,172,1,1,11
637,17,182,4,0,
637,17,207,4,0,
637,17,214,4,0,
637,17,216,4,0,
637,17,218,4,0,
637,17,219,4,0,
637,17,237,4,0,
637,17,241,4,0,
637,17,257,1,1,5
637,17,257,1,60,
637,17,261,4,0,
637,17,263,4,0,
637,17,315,4,0,
637,17,318,1,50,
637,17,332,4,0,
637,17,347,4,0,
637,17,355,4,0,
637,17,369,4,0,
637,17,394,1,1,7
637,17,398,4,0,
637,17,405,1,1,10
637,17,405,1,70,
637,17,416,4,0,
637,17,476,1,1,4
637,17,476,1,80,
637,17,483,1,0,
637,17,483,1,1,1
637,17,488,4,0,
637,17,496,4,0,
637,17,512,4,0,
637,17,528,4,0,
637,17,542,1,1,3
637,17,542,1,90,
637,17,552,1,1,2
637,17,552,1,100,
637,17,590,4,0,
637,18,16,1,1,14
637,18,16,1,20,
637,18,18,1,40,
637,18,19,4,0,
637,18,37,1,1,7
637,18,52,1,1,11
637,18,53,4,0,
637,18,63,4,0,
637,18,71,1,1,13
637,18,71,1,10,
637,18,76,4,0,
637,18,81,1,1,12
637,18,83,1,30,
637,18,92,4,0,
637,18,94,4,0,
637,18,104,4,0,
637,18,113,4,0,
637,18,126,4,0,
637,18,133,1,1,8
637,18,141,4,0,
637,18,156,4,0,
637,18,164,4,0,
637,18,172,1,1,10
637,18,182,4,0,
637,18,207,4,0,
637,18,214,4,0,
637,18,216,4,0,
637,18,218,4,0,
637,18,219,4,0,
637,18,237,4,0,
637,18,241,4,0,
637,18,257,1,1,5
637,18,257,1,60,
637,18,261,4,0,
637,18,263,4,0,
637,18,315,4,0,
637,18,318,1,50,
637,18,332,4,0,
637,18,347,4,0,
637,18,355,4,0,
637,18,369,4,0,
637,18,394,1,1,6
637,18,398,4,0,
637,18,405,1,1,9
637,18,405,1,70,
637,18,416,4,0,
637,18,476,1,1,4
637,18,476,1,80,
637,18,483,1,0,
637,18,483,1,1,1
637,18,488,4,0,
637,18,496,4,0,
637,18,512,4,0,
637,18,528,4,0,
637,18,542,1,1,3
637,18,542,1,90,
637,18,552,1,1,2
637,18,552,1,100,
637,18,590,4,0,
638,11,14,1,49,
638,11,14,4,0,
638,11,15,4,0,
638,11,24,1,7,
638,11,36,1,19,
638,11,43,1,1,2
638,11,46,4,0,
638,11,63,4,0,
638,11,70,4,0,
638,11,86,4,0,
638,11,92,4,0,
638,11,98,1,1,1
638,11,104,4,0,
638,11,115,4,0,
638,11,156,4,0,
638,11,164,4,0,
638,11,182,4,0,
638,11,201,4,0,
638,11,206,4,0,
638,11,207,4,0,
638,11,216,4,0,
638,11,218,4,0,
638,11,219,4,0,
638,11,232,1,13,
638,11,237,4,0,
638,11,244,4,0,
638,11,249,4,0,
638,11,263,4,0,
638,11,269,4,0,
638,11,270,1,25,
638,11,332,4,0,
638,11,347,4,0,
638,11,368,1,67,
638,11,370,1,73,
638,11,397,4,0,
638,11,398,4,0,
638,11,404,4,0,
638,11,411,4,0,
638,11,416,4,0,
638,11,430,4,0,
638,11,442,1,37,
638,11,444,4,0,
638,11,468,4,0,
638,11,496,4,0,
638,11,501,1,55,
638,11,514,1,31,
638,11,514,4,0,
638,11,521,4,0,
638,11,526,1,61,
638,11,526,4,0,
638,11,533,1,42,
638,14,14,1,49,
638,14,14,4,0,
638,14,15,4,0,
638,14,24,1,7,
638,14,36,1,19,
638,14,43,1,1,2
638,14,46,4,0,
638,14,63,4,0,
638,14,70,4,0,
638,14,86,4,0,
638,14,92,4,0,
638,14,98,1,1,1
638,14,104,4,0,
638,14,115,4,0,
638,14,156,4,0,
638,14,164,4,0,
638,14,173,3,0,
638,14,182,4,0,
638,14,201,4,0,
638,14,206,4,0,
638,14,207,4,0,
638,14,214,3,0,
638,14,216,4,0,
638,14,218,4,0,
638,14,219,4,0,
638,14,232,1,13,
638,14,237,4,0,
638,14,244,4,0,
638,14,249,4,0,
638,14,263,4,0,
638,14,269,4,0,
638,14,270,1,25,
638,14,270,3,0,
638,14,276,3,0,
638,14,332,4,0,
638,14,334,3,0,
638,14,335,3,0,
638,14,340,3,0,
638,14,347,4,0,
638,14,368,1,67,
638,14,370,1,73,
638,14,393,3,0,
638,14,397,4,0,
638,14,398,4,0,
638,14,404,4,0,
638,14,411,4,0,
638,14,416,4,0,
638,14,428,3,0,
638,14,430,4,0,
638,14,442,1,37,
638,14,442,3,0,
638,14,444,4,0,
638,14,446,3,0,
638,14,468,4,0,
638,14,496,4,0,
638,14,501,1,55,
638,14,514,1,31,
638,14,514,4,0,
638,14,521,4,0,
638,14,526,1,61,
638,14,526,4,0,
638,14,533,1,42,
638,15,14,1,49,
638,15,14,4,0,
638,15,15,4,0,
638,15,24,1,7,
638,15,36,1,19,
638,15,43,1,1,6
638,15,46,4,0,
638,15,63,4,0,
638,15,70,4,0,
638,15,86,4,0,
638,15,92,4,0,
638,15,98,1,1,5
638,15,104,4,0,
638,15,115,4,0,
638,15,156,4,0,
638,15,164,4,0,
638,15,182,4,0,
638,15,201,4,0,
638,15,206,4,0,
638,15,207,4,0,
638,15,214,4,0,
638,15,216,4,0,
638,15,218,4,0,
638,15,219,4,0,
638,15,232,1,13,
638,15,237,4,0,
638,15,244,4,0,
638,15,249,4,0,
638,15,263,4,0,
638,15,269,4,0,
638,15,270,1,25,
638,15,332,4,0,
638,15,347,4,0,
638,15,368,1,1,2
638,15,368,1,67,
638,15,370,1,1,1
638,15,370,1,73,
638,15,397,4,0,
638,15,398,4,0,
638,15,404,4,0,
638,15,411,4,0,
638,15,416,4,0,
638,15,430,4,0,
638,15,442,1,37,
638,15,444,4,0,
638,15,468,4,0,
638,15,496,4,0,
638,15,501,1,1,4
638,15,501,1,55,
638,15,514,1,31,
638,15,514,4,0,
638,15,521,4,0,
638,15,526,1,1,3
638,15,526,1,61,
638,15,533,1,42,
638,15,590,4,0,
638,16,14,1,49,1
638,16,14,4,0,
638,16,15,4,0,
638,16,24,1,7,1
638,16,36,1,19,1
638,16,43,1,1,6
638,16,46,4,0,
638,16,63,4,0,
638,16,70,4,0,
638,16,86,4,0,
638,16,92,4,0,
638,16,98,1,1,5
638,16,104,4,0,
638,16,115,4,0,
638,16,156,4,0,
638,16,164,4,0,
638,16,173,3,0,
638,16,182,4,0,
638,16,201,4,0,
638,16,206,4,0,
638,16,207,4,0,
638,16,214,4,0,
638,16,216,4,0,
638,16,218,4,0,
638,16,219,4,0,
638,16,232,1,13,1
638,16,237,4,0,
638,16,244,4,0,
638,16,249,4,0,
638,16,263,4,0,
638,16,269,4,0,
638,16,270,1,25,1
638,16,270,3,0,
638,16,276,3,0,
638,16,290,4,0,
638,16,332,4,0,
638,16,334,3,0,
638,16,335,3,0,
638,16,340,3,0,
638,16,347,4,0,
638,16,368,1,1,2
638,16,368,1,67,1
638,16,370,1,1,1
638,16,370,1,73,1
638,16,393,3,0,
638,16,397,4,0,
638,16,398,4,0,
638,16,404,4,0,
638,16,411,4,0,
638,16,416,4,0,
638,16,428,3,0,
638,16,430,4,0,
638,16,442,1,37,1
638,16,442,3,0,
638,16,444,4,0,
638,16,446,3,0,
638,16,468,4,0,
638,16,496,4,0,
638,16,501,1,1,4
638,16,501,1,55,1
638,16,514,1,31,1
638,16,514,4,0,
638,16,521,4,0,
638,16,526,1,1,3
638,16,526,1,61,1
638,16,533,1,42,1
638,16,590,4,0,
638,17,14,1,37,
638,17,14,4,0,
638,17,24,1,1,5
638,17,36,1,7,
638,17,43,1,1,4
638,17,46,4,0,
638,17,63,4,0,
638,17,86,4,0,
638,17,92,4,0,
638,17,98,1,1,3
638,17,104,4,0,
638,17,115,4,0,
638,17,156,4,0,
638,17,164,4,0,
638,17,182,4,0,
638,17,201,4,0,
638,17,206,4,0,
638,17,207,4,0,
638,17,214,4,0,
638,17,216,4,0,
638,17,218,4,0,
638,17,219,4,0,
638,17,232,1,1,6
638,17,237,4,0,
638,17,244,4,0,
638,17,263,4,0,
638,17,269,4,0,
638,17,270,1,13,
638,17,332,4,0,
638,17,347,4,0,
638,17,368,1,1,2
638,17,368,1,55,
638,17,370,1,1,1
638,17,370,1,61,
638,17,397,4,0,
638,17,398,4,0,
638,17,404,4,0,
638,17,411,4,0,
638,17,416,4,0,
638,17,430,4,0,
638,17,442,1,25,
638,17,444,4,0,
638,17,496,4,0,
638,17,501,1,42,
638,17,514,1,19,
638,17,521,4,0,
638,17,526,1,49,
638,17,526,4,0,
638,17,533,1,31,
638,17,590,4,0,
638,18,14,1,37,
638,18,14,4,0,
638,18,24,1,1,5
638,18,36,1,7,
638,18,43,1,1,4
638,18,46,4,0,
638,18,63,4,0,
638,18,86,4,0,
638,18,92,4,0,
638,18,98,1,1,3
638,18,104,4,0,
638,18,115,4,0,
638,18,156,4,0,
638,18,164,4,0,
638,18,182,4,0,
638,18,201,4,0,
638,18,206,4,0,
638,18,207,4,0,
638,18,214,4,0,
638,18,216,4,0,
638,18,218,4,0,
638,18,219,4,0,
638,18,232,1,1,6
638,18,237,4,0,
638,18,244,4,0,
638,18,263,4,0,
638,18,269,4,0,
638,18,270,1,13,
638,18,332,4,0,
638,18,347,4,0,
638,18,368,1,1,2
638,18,368,1,55,
638,18,370,1,1,1
638,18,370,1,61,
638,18,397,4,0,
638,18,398,4,0,
638,18,404,4,0,
638,18,411,4,0,
638,18,416,4,0,
638,18,430,4,0,
638,18,442,1,25,
638,18,444,4,0,
638,18,496,4,0,
638,18,501,1,42,
638,18,514,1,19,
638,18,521,4,0,
638,18,526,1,49,
638,18,526,4,0,
638,18,533,1,31,
638,18,590,4,0,
639,11,14,1,49,
639,11,14,4,0,
639,11,15,4,0,
639,11,24,1,7,
639,11,36,1,19,
639,11,43,1,1,2
639,11,46,4,0,
639,11,63,4,0,
639,11,70,4,0,
639,11,89,4,0,
639,11,92,4,0,
639,11,98,1,1,1
639,11,104,4,0,
639,11,115,4,0,
639,11,156,4,0,
639,11,157,1,37,
639,11,157,4,0,
639,11,164,4,0,
639,11,182,4,0,
639,11,201,4,0,
639,11,206,4,0,
639,11,207,4,0,
639,11,216,4,0,
639,11,218,4,0,
639,11,219,4,0,
639,11,237,4,0,
639,11,244,4,0,
639,11,249,4,0,
639,11,263,4,0,
639,11,269,4,0,
639,11,270,1,25,
639,11,317,4,0,
639,11,332,4,0,
639,11,347,4,0,
639,11,370,1,73,
639,11,397,4,0,
639,11,398,4,0,
639,11,404,4,0,
639,11,411,4,0,
639,11,416,4,0,
639,11,444,1,67,
639,11,444,4,0,
639,11,479,1,13,
639,11,479,4,0,
639,11,496,4,0,
639,11,501,1,55,
639,11,514,1,31,
639,11,514,4,0,
639,11,523,4,0,
639,11,526,1,61,
639,11,526,4,0,
639,11,533,1,42,
639,14,14,1,49,
639,14,14,4,0,
639,14,15,4,0,
639,14,24,1,7,
639,14,36,1,19,
639,14,43,1,1,2
639,14,46,4,0,
639,14,63,4,0,
639,14,70,4,0,
639,14,89,4,0,
639,14,92,4,0,
639,14,98,1,1,1
639,14,104,4,0,
639,14,115,4,0,
639,14,156,4,0,
639,14,157,1,37,
639,14,157,4,0,
639,14,164,4,0,
639,14,173,3,0,
639,14,182,4,0,
639,14,201,4,0,
639,14,206,4,0,
639,14,207,4,0,
639,14,214,3,0,
639,14,216,4,0,
639,14,218,4,0,
639,14,219,4,0,
639,14,237,4,0,
639,14,244,4,0,
639,14,249,4,0,
639,14,263,4,0,
639,14,269,4,0,
639,14,270,1,25,
639,14,270,3,0,
639,14,276,3,0,
639,14,317,4,0,
639,14,332,4,0,
639,14,335,3,0,
639,14,347,4,0,
639,14,370,1,73,
639,14,397,4,0,
639,14,398,4,0,
639,14,404,4,0,
639,14,411,4,0,
639,14,414,3,0,
639,14,416,4,0,
639,14,428,3,0,
639,14,442,3,0,
639,14,444,1,67,
639,14,444,4,0,
639,14,446,3,0,
639,14,479,1,13,
639,14,479,4,0,
639,14,496,4,0,
639,14,501,1,55,
639,14,514,1,31,
639,14,514,4,0,
639,14,523,4,0,
639,14,526,1,61,
639,14,526,4,0,
639,14,533,1,42,
639,15,14,1,49,
639,15,14,4,0,
639,15,15,4,0,
639,15,24,1,7,
639,15,36,1,19,
639,15,43,1,1,5
639,15,46,4,0,
639,15,63,4,0,
639,15,70,4,0,
639,15,89,4,0,
639,15,92,4,0,
639,15,98,1,1,4
639,15,104,4,0,
639,15,115,4,0,
639,15,156,4,0,
639,15,157,1,37,
639,15,157,4,0,
639,15,164,4,0,
639,15,182,4,0,
639,15,201,4,0,
639,15,206,4,0,
639,15,207,4,0,
639,15,214,4,0,
639,15,216,4,0,
639,15,218,4,0,
639,15,219,4,0,
639,15,237,4,0,
639,15,244,4,0,
639,15,249,4,0,
639,15,263,4,0,
639,15,269,4,0,
639,15,270,1,25,
639,15,317,4,0,
639,15,332,4,0,
639,15,347,4,0,
639,15,370,1,1,1
639,15,370,1,73,
639,15,397,4,0,
639,15,398,4,0,
639,15,404,4,0,
639,15,411,4,0,
639,15,416,4,0,
639,15,444,1,67,
639,15,444,4,0,
639,15,479,1,13,
639,15,479,4,0,
639,15,496,4,0,
639,15,501,1,1,3
639,15,501,1,55,
639,15,514,1,31,
639,15,514,4,0,
639,15,523,4,0,
639,15,526,1,1,2
639,15,526,1,61,
639,15,533,1,42,
639,15,590,4,0,
639,16,14,1,49,1
639,16,14,4,0,
639,16,15,4,0,
639,16,24,1,7,1
639,16,36,1,19,1
639,16,43,1,1,5
639,16,46,4,0,
639,16,63,4,0,
639,16,70,4,0,
639,16,89,4,0,
639,16,92,4,0,
639,16,98,1,1,4
639,16,104,4,0,
639,16,115,4,0,
639,16,156,4,0,
639,16,157,1,37,1
639,16,157,4,0,
639,16,164,4,0,
639,16,173,3,0,
639,16,182,4,0,
639,16,201,4,0,
639,16,206,4,0,
639,16,207,4,0,
639,16,214,4,0,
639,16,216,4,0,
639,16,218,4,0,
639,16,219,4,0,
639,16,237,4,0,
639,16,244,4,0,
639,16,249,4,0,
639,16,263,4,0,
639,16,269,4,0,
639,16,270,1,25,1
639,16,270,3,0,
639,16,276,3,0,
639,16,290,4,0,
639,16,317,4,0,
639,16,332,4,0,
639,16,335,3,0,
639,16,347,4,0,
639,16,370,1,1,1
639,16,370,1,73,1
639,16,397,4,0,
639,16,398,4,0,
639,16,404,4,0,
639,16,411,4,0,
639,16,414,3,0,
639,16,416,4,0,
639,16,428,3,0,
639,16,442,3,0,
639,16,444,1,67,1
639,16,444,4,0,
639,16,446,3,0,
639,16,479,1,13,1
639,16,479,4,0,
639,16,496,4,0,
639,16,501,1,1,3
639,16,501,1,55,1
639,16,514,1,31,1
639,16,514,4,0,
639,16,523,4,0,
639,16,526,1,1,2
639,16,526,1,61,1
639,16,533,1,42,1
639,16,590,4,0,
639,17,14,1,37,
639,17,14,4,0,
639,17,24,1,1,4
639,17,36,1,7,
639,17,43,1,1,3
639,17,46,4,0,
639,17,63,4,0,
639,17,89,4,0,
639,17,92,4,0,
639,17,98,1,1,2
639,17,104,4,0,
639,17,115,4,0,
639,17,156,4,0,
639,17,157,1,25,
639,17,157,4,0,
639,17,164,4,0,
639,17,182,4,0,
639,17,201,4,0,
639,17,206,4,0,
639,17,207,4,0,
639,17,214,4,0,
639,17,216,4,0,
639,17,218,4,0,
639,17,219,4,0,
639,17,237,4,0,
639,17,244,4,0,
639,17,263,4,0,
639,17,269,4,0,
639,17,270,1,13,
639,17,317,4,0,
639,17,332,4,0,
639,17,347,4,0,
639,17,370,1,1,1
639,17,370,1,61,
639,17,397,4,0,
639,17,398,4,0,
639,17,404,4,0,
639,17,411,4,0,
639,17,416,4,0,
639,17,444,1,55,
639,17,444,4,0,
639,17,479,1,1,5
639,17,479,4,0,
639,17,496,4,0,
639,17,501,1,42,
639,17,514,1,19,
639,17,523,4,0,
639,17,526,1,49,
639,17,526,4,0,
639,17,533,1,31,
639,17,590,4,0,
639,18,14,1,37,
639,18,14,4,0,
639,18,24,1,1,4
639,18,36,1,7,
639,18,43,1,1,3
639,18,46,4,0,
639,18,63,4,0,
639,18,89,4,0,
639,18,92,4,0,
639,18,98,1,1,2
639,18,104,4,0,
639,18,115,4,0,
639,18,156,4,0,
639,18,157,1,25,
639,18,157,4,0,
639,18,164,4,0,
639,18,182,4,0,
639,18,201,4,0,
639,18,206,4,0,
639,18,207,4,0,
639,18,214,4,0,
639,18,216,4,0,
639,18,218,4,0,
639,18,219,4,0,
639,18,237,4,0,
639,18,244,4,0,
639,18,263,4,0,
639,18,269,4,0,
639,18,270,1,13,
639,18,317,4,0,
639,18,332,4,0,
639,18,347,4,0,
639,18,370,1,1,1
639,18,370,1,61,
639,18,397,4,0,
639,18,398,4,0,
639,18,404,4,0,
639,18,411,4,0,
639,18,416,4,0,
639,18,444,1,55,
639,18,444,4,0,
639,18,479,1,1,5
639,18,479,4,0,
639,18,496,4,0,
639,18,501,1,42,
639,18,514,1,19,
639,18,523,4,0,
639,18,526,1,49,
639,18,526,4,0,
639,18,533,1,31,
639,18,590,4,0,
640,11,14,1,49,
640,11,14,4,0,
640,11,15,4,0,
640,11,24,1,7,
640,11,36,1,19,
640,11,43,1,1,2
640,11,46,4,0,
640,11,63,4,0,
640,11,70,4,0,
640,11,76,4,0,
640,11,92,4,0,
640,11,98,1,1,1
640,11,104,4,0,
640,11,113,4,0,
640,11,115,4,0,
640,11,148,4,0,
640,11,156,4,0,
640,11,164,4,0,
640,11,182,4,0,
640,11,202,1,37,
640,11,206,4,0,
640,11,207,4,0,
640,11,216,4,0,
640,11,218,4,0,
640,11,219,4,0,
640,11,237,4,0,
640,11,241,4,0,
640,11,244,4,0,
640,11,249,4,0,
640,11,263,4,0,
640,11,269,4,0,
640,11,270,1,25,
640,11,332,4,0,
640,11,345,1,13,
640,11,347,4,0,
640,11,348,1,67,
640,11,370,1,73,
640,11,404,4,0,
640,11,411,4,0,
640,11,412,4,0,
640,11,416,4,0,
640,11,444,4,0,
640,11,447,4,0,
640,11,496,4,0,
640,11,501,1,55,
640,11,514,1,31,
640,11,514,4,0,
640,11,526,1,61,
640,11,526,4,0,
640,11,533,1,42,
640,14,14,1,49,
640,14,14,4,0,
640,14,15,4,0,
640,14,24,1,7,
640,14,36,1,19,
640,14,43,1,1,2
640,14,46,4,0,
640,14,63,4,0,
640,14,70,4,0,
640,14,76,4,0,
640,14,92,4,0,
640,14,98,1,1,1
640,14,104,4,0,
640,14,113,4,0,
640,14,115,4,0,
640,14,148,4,0,
640,14,156,4,0,
640,14,164,4,0,
640,14,173,3,0,
640,14,182,4,0,
640,14,202,1,37,
640,14,202,3,0,
640,14,206,4,0,
640,14,207,4,0,
640,14,214,3,0,
640,14,216,4,0,
640,14,218,4,0,
640,14,219,4,0,
640,14,235,3,0,
640,14,237,4,0,
640,14,241,4,0,
640,14,244,4,0,
640,14,249,4,0,
640,14,263,4,0,
640,14,269,4,0,
640,14,270,1,25,
640,14,270,3,0,
640,14,276,3,0,
640,14,332,4,0,
640,14,335,3,0,
640,14,340,3,0,
640,14,345,1,13,
640,14,347,4,0,
640,14,348,1,67,
640,14,370,1,73,
640,14,388,3,0,
640,14,402,3,0,
640,14,404,4,0,
640,14,411,4,0,
640,14,412,4,0,
640,14,416,4,0,
640,14,428,3,0,
640,14,444,4,0,
640,14,447,4,0,
640,14,496,4,0,
640,14,501,1,55,
640,14,514,1,31,
640,14,514,4,0,
640,14,526,1,61,
640,14,526,4,0,
640,14,533,1,42,
640,15,14,1,49,
640,15,14,4,0,
640,15,15,4,0,
640,15,24,1,7,
640,15,36,1,19,
640,15,43,1,1,6
640,15,46,4,0,
640,15,63,4,0,
640,15,70,4,0,
640,15,76,4,0,
640,15,92,4,0,
640,15,98,1,1,5
640,15,104,4,0,
640,15,113,4,0,
640,15,115,4,0,
640,15,148,4,0,
640,15,156,4,0,
640,15,164,4,0,
640,15,182,4,0,
640,15,202,1,37,
640,15,206,4,0,
640,15,207,4,0,
640,15,214,4,0,
640,15,216,4,0,
640,15,218,4,0,
640,15,219,4,0,
640,15,237,4,0,
640,15,241,4,0,
640,15,244,4,0,
640,15,249,4,0,
640,15,263,4,0,
640,15,267,4,0,
640,15,269,4,0,
640,15,270,1,25,
640,15,332,4,0,
640,15,345,1,13,
640,15,347,4,0,
640,15,348,1,1,2
640,15,348,1,67,
640,15,370,1,1,1
640,15,370,1,73,
640,15,404,4,0,
640,15,411,4,0,
640,15,412,4,0,
640,15,416,4,0,
640,15,444,4,0,
640,15,447,4,0,
640,15,496,4,0,
640,15,501,1,1,4
640,15,501,1,55,
640,15,514,1,31,
640,15,514,4,0,
640,15,526,1,1,3
640,15,526,1,61,
640,15,533,1,42,
640,15,590,4,0,
640,16,14,1,49,1
640,16,14,4,0,
640,16,15,4,0,
640,16,24,1,7,1
640,16,36,1,19,1
640,16,43,1,1,6
640,16,46,4,0,
640,16,63,4,0,
640,16,70,4,0,
640,16,76,4,0,
640,16,92,4,0,
640,16,98,1,1,5
640,16,104,4,0,
640,16,113,4,0,
640,16,115,4,0,
640,16,148,4,0,
640,16,156,4,0,
640,16,164,4,0,
640,16,173,3,0,
640,16,182,4,0,
640,16,202,1,37,1
640,16,202,3,0,
640,16,206,4,0,
640,16,207,4,0,
640,16,214,4,0,
640,16,216,4,0,
640,16,218,4,0,
640,16,219,4,0,
640,16,235,3,0,
640,16,237,4,0,
640,16,241,4,0,
640,16,244,4,0,
640,16,249,4,0,
640,16,263,4,0,
640,16,267,4,0,
640,16,269,4,0,
640,16,270,1,25,1
640,16,270,3,0,
640,16,276,3,0,
640,16,290,4,0,
640,16,332,4,0,
640,16,335,3,0,
640,16,340,3,0,
640,16,345,1,13,1
640,16,347,4,0,
640,16,348,1,1,2
640,16,348,1,67,1
640,16,370,1,1,1
640,16,370,1,73,1
640,16,388,3,0,
640,16,402,3,0,
640,16,404,4,0,
640,16,411,4,0,
640,16,412,4,0,
640,16,416,4,0,
640,16,428,3,0,
640,16,444,4,0,
640,16,447,4,0,
640,16,496,4,0,
640,16,501,1,1,4
640,16,501,1,55,1
640,16,514,1,31,1
640,16,514,4,0,
640,16,526,1,1,3
640,16,526,1,61,1
640,16,533,1,42,1
640,16,590,4,0,
640,17,14,1,37,
640,17,14,4,0,
640,17,24,1,1,5
640,17,36,1,7,
640,17,43,1,1,4
640,17,46,4,0,
640,17,63,4,0,
640,17,76,4,0,
640,17,92,4,0,
640,17,98,1,1,3
640,17,104,4,0,
640,17,113,4,0,
640,17,115,4,0,
640,17,156,4,0,
640,17,164,4,0,
640,17,182,4,0,
640,17,202,1,25,
640,17,206,4,0,
640,17,207,4,0,
640,17,214,4,0,
640,17,216,4,0,
640,17,218,4,0,
640,17,219,4,0,
640,17,237,4,0,
640,17,241,4,0,
640,17,244,4,0,
640,17,263,4,0,
640,17,267,4,0,
640,17,269,4,0,
640,17,270,1,13,
640,17,332,4,0,
640,17,345,1,1,6
640,17,347,4,0,
640,17,348,1,1,2
640,17,348,1,55,
640,17,370,1,1,1
640,17,370,1,61,
640,17,404,4,0,
640,17,411,4,0,
640,17,412,4,0,
640,17,416,4,0,
640,17,444,4,0,
640,17,447,4,0,
640,17,496,4,0,
640,17,501,1,42,
640,17,514,1,19,
640,17,526,1,49,
640,17,526,4,0,
640,17,533,1,31,
640,17,590,4,0,
640,18,14,1,37,
640,18,14,4,0,
640,18,24,1,1,5
640,18,36,1,7,
640,18,43,1,1,4
640,18,46,4,0,
640,18,63,4,0,
640,18,76,4,0,
640,18,92,4,0,
640,18,98,1,1,3
640,18,104,4,0,
640,18,113,4,0,
640,18,115,4,0,
640,18,156,4,0,
640,18,164,4,0,
640,18,182,4,0,
640,18,202,1,25,
640,18,206,4,0,
640,18,207,4,0,
640,18,214,4,0,
640,18,216,4,0,
640,18,218,4,0,
640,18,219,4,0,
640,18,237,4,0,
640,18,241,4,0,
640,18,244,4,0,
640,18,263,4,0,
640,18,267,4,0,
640,18,269,4,0,
640,18,270,1,13,
640,18,332,4,0,
640,18,345,1,1,6
640,18,347,4,0,
640,18,348,1,1,2
640,18,348,1,55,
640,18,370,1,1,1
640,18,370,1,61,
640,18,404,4,0,
640,18,411,4,0,
640,18,412,4,0,
640,18,416,4,0,
640,18,444,4,0,
640,18,447,4,0,
640,18,496,4,0,
640,18,501,1,42,
640,18,514,1,19,
640,18,526,1,49,
640,18,526,4,0,
640,18,533,1,31,
640,18,590,4,0,
641,11,16,1,1,3
641,11,19,4,0,
641,11,37,1,85,
641,11,44,1,13,
641,11,63,4,0,
641,11,70,4,0,
641,11,92,4,0,
641,11,94,4,0,
641,11,97,1,37,
641,11,104,4,0,
641,11,156,4,0,
641,11,164,4,0,
641,11,168,4,0,
641,11,182,4,0,
641,11,188,4,0,
641,11,207,1,7,
641,11,207,4,0,
641,11,213,4,0,
641,11,216,4,0,
641,11,218,4,0,
641,11,237,4,0,
641,11,240,1,61,
641,11,240,4,0,
641,11,242,1,49,
641,11,249,4,0,
641,11,253,1,1,1
641,11,259,4,0,
641,11,263,4,0,
641,11,269,4,0,
641,11,279,1,19,
641,11,280,4,0,
641,11,310,1,1,2
641,11,314,1,25,
641,11,326,1,31,
641,11,332,4,0,
641,11,339,4,0,
641,11,359,1,79,
641,11,366,1,55,
641,11,369,4,0,
641,11,371,4,0,
641,11,373,4,0,
641,11,374,4,0,
641,11,399,1,73,
641,11,403,1,43,
641,11,411,4,0,
641,11,416,4,0,
641,11,447,4,0,
641,11,479,4,0,
641,11,482,4,0,
641,11,496,4,0,
641,11,507,4,0,
641,11,510,4,0,
641,11,512,4,0,
641,11,542,1,67,
641,14,16,1,1,3
641,14,19,4,0,
641,14,37,1,85,
641,14,44,1,13,
641,14,63,4,0,
641,14,70,4,0,
641,14,92,4,0,
641,14,94,4,0,
641,14,97,1,37,
641,14,104,4,0,
641,14,156,4,0,
641,14,164,4,0,
641,14,168,4,0,
641,14,173,3,0,
641,14,182,4,0,
641,14,188,4,0,
641,14,196,3,0,
641,14,207,1,7,
641,14,207,4,0,
641,14,213,4,0,
641,14,214,3,0,
641,14,216,4,0,
641,14,218,4,0,
641,14,231,3,0,
641,14,237,4,0,
641,14,240,1,61,
641,14,240,4,0,
641,14,242,1,49,
641,14,249,4,0,
641,14,253,1,1,1
641,14,253,3,0,
641,14,257,3,0,
641,14,259,4,0,
641,14,263,4,0,
641,14,269,4,0,
641,14,272,3,0,
641,14,276,3,0,
641,14,279,1,19,
641,14,280,4,0,
641,14,282,3,0,
641,14,310,1,1,2
641,14,314,1,25,
641,14,326,1,31,
641,14,332,4,0,
641,14,339,4,0,
641,14,359,1,79,
641,14,366,1,55,
641,14,366,3,0,
641,14,369,4,0,
641,14,371,4,0,
641,14,373,4,0,
641,14,374,4,0,
641,14,399,1,73,
641,14,399,3,0,
641,14,403,1,43,
641,14,411,4,0,
641,14,416,4,0,
641,14,447,4,0,
641,14,479,4,0,
641,14,482,4,0,
641,14,492,3,0,
641,14,496,4,0,
641,14,507,4,0,
641,14,510,4,0,
641,14,512,4,0,
641,14,542,1,67,
641,15,16,1,1,7
641,15,19,4,0,
641,15,37,1,1,1
641,15,37,1,85,
641,15,44,1,13,
641,15,63,4,0,
641,15,70,4,0,
641,15,92,4,0,
641,15,94,4,0,
641,15,97,1,37,
641,15,104,4,0,
641,15,156,4,0,
641,15,164,4,0,
641,15,168,4,0,
641,15,182,4,0,
641,15,188,4,0,
641,15,207,1,7,
641,15,207,4,0,
641,15,213,4,0,
641,15,214,4,0,
641,15,216,4,0,
641,15,218,4,0,
641,15,237,4,0,
641,15,240,1,61,
641,15,240,4,0,
641,15,242,1,49,
641,15,249,4,0,
641,15,253,1,1,5
641,15,259,4,0,
641,15,263,4,0,
641,15,269,4,0,
641,15,279,1,19,
641,15,280,4,0,
641,15,310,1,1,6
641,15,314,1,25,
641,15,326,1,31,
641,15,332,4,0,
641,15,339,4,0,
641,15,359,1,1,2
641,15,359,1,79,
641,15,366,1,1,4
641,15,366,1,55,
641,15,369,4,0,
641,15,371,4,0,
641,15,373,4,0,
641,15,374,4,0,
641,15,399,1,73,
641,15,399,4,0,
641,15,403,1,43,
641,15,411,4,0,
641,15,416,4,0,
641,15,447,4,0,
641,15,479,4,0,
641,15,482,4,0,
641,15,496,4,0,
641,15,507,4,0,
641,15,510,4,0,
641,15,512,4,0,
641,15,542,1,1,3
641,15,542,1,67,
641,15,590,4,0,
641,16,16,1,1,7
641,16,19,4,0,
641,16,37,1,1,1
641,16,37,1,85,1
641,16,44,1,13,1
641,16,63,4,0,
641,16,70,4,0,
641,16,92,4,0,
641,16,94,4,0,
641,16,97,1,37,1
641,16,104,4,0,
641,16,156,4,0,
641,16,164,4,0,
641,16,168,4,0,
641,16,173,3,0,
641,16,182,4,0,
641,16,188,4,0,
641,16,196,3,0,
641,16,207,1,7,1
641,16,207,4,0,
641,16,213,4,0,
641,16,214,4,0,
641,16,216,4,0,
641,16,218,4,0,
641,16,231,3,0,
641,16,237,4,0,
641,16,240,1,61,1
641,16,240,4,0,
641,16,242,1,49,1
641,16,249,4,0,
641,16,253,1,1,5
641,16,253,3,0,
641,16,257,3,0,
641,16,259,4,0,
641,16,263,4,0,
641,16,269,4,0,
641,16,272,3,0,
641,16,276,3,0,
641,16,279,1,19,1
641,16,280,4,0,
641,16,282,3,0,
641,16,290,4,0,
641,16,310,1,1,6
641,16,314,1,25,1
641,16,326,1,31,1
641,16,332,4,0,
641,16,339,4,0,
641,16,359,1,1,2
641,16,359,1,79,1
641,16,366,1,1,4
641,16,366,1,55,1
641,16,366,3,0,
641,16,369,4,0,
641,16,371,4,0,
641,16,373,4,0,
641,16,374,4,0,
641,16,399,1,73,1
641,16,399,4,0,
641,16,403,1,43,1
641,16,411,4,0,
641,16,416,4,0,
641,16,447,4,0,
641,16,479,4,0,
641,16,482,4,0,
641,16,492,3,0,
641,16,496,4,0,
641,16,507,4,0,
641,16,510,4,0,
641,16,512,4,0,
641,16,542,1,1,3
641,16,542,1,67,1
641,16,590,4,0,
641,17,16,1,1,6
641,17,19,4,0,
641,17,37,1,1,1
641,17,37,1,79,
641,17,44,1,7,
641,17,63,4,0,
641,17,92,4,0,
641,17,94,4,0,
641,17,97,1,31,
641,17,104,4,0,
641,17,156,4,0,
641,17,164,4,0,
641,17,168,4,0,
641,17,182,4,0,
641,17,188,4,0,
641,17,207,1,1,7
641,17,207,4,0,
641,17,213,4,0,
641,17,214,4,0,
641,17,216,4,0,
641,17,218,4,0,
641,17,237,4,0,
641,17,240,1,55,
641,17,240,4,0,
641,17,242,1,43,
641,17,253,1,1,4
641,17,259,4,0,
641,17,263,4,0,
641,17,269,4,0,
641,17,279,1,13,
641,17,280,4,0,
641,17,310,1,1,5
641,17,314,1,19,
641,17,326,1,25,
641,17,332,4,0,
641,17,339,4,0,
641,17,359,1,1,2
641,17,359,1,73,
641,17,366,1,49,
641,17,369,4,0,
641,17,371,4,0,
641,17,373,4,0,
641,17,374,4,0,
641,17,399,1,67,
641,17,399,4,0,
641,17,403,1,37,
641,17,411,4,0,
641,17,416,4,0,
641,17,447,4,0,
641,17,479,4,0,
641,17,482,4,0,
641,17,496,4,0,
641,17,507,4,0,
641,17,512,4,0,
641,17,542,1,1,3
641,17,542,1,61,
641,17,590,4,0,
641,17,693,4,0,
641,18,16,1,1,6
641,18,19,4,0,
641,18,37,1,1,1
641,18,37,1,79,
641,18,44,1,7,
641,18,63,4,0,
641,18,92,4,0,
641,18,94,4,0,
641,18,97,1,31,
641,18,104,4,0,
641,18,156,4,0,
641,18,164,4,0,
641,18,168,4,0,
641,18,182,4,0,
641,18,188,4,0,
641,18,207,1,1,7
641,18,207,4,0,
641,18,213,4,0,
641,18,214,4,0,
641,18,216,4,0,
641,18,218,4,0,
641,18,237,4,0,
641,18,240,1,55,
641,18,240,4,0,
641,18,242,1,43,
641,18,253,1,1,4
641,18,259,4,0,
641,18,263,4,0,
641,18,269,4,0,
641,18,279,1,13,
641,18,280,4,0,
641,18,310,1,1,5
641,18,314,1,19,
641,18,326,1,25,
641,18,332,4,0,
641,18,339,4,0,
641,18,359,1,1,2
641,18,359,1,73,
641,18,366,1,49,
641,18,369,4,0,
641,18,371,4,0,
641,18,373,4,0,
641,18,374,4,0,
641,18,399,1,67,
641,18,399,4,0,
641,18,403,1,37,
641,18,411,4,0,
641,18,416,4,0,
641,18,447,4,0,
641,18,479,4,0,
641,18,482,4,0,
641,18,496,4,0,
641,18,507,4,0,
641,18,512,4,0,
641,18,542,1,1,3
641,18,542,1,61,
641,18,590,4,0,
641,18,693,4,0,
642,11,19,4,0,
642,11,37,1,85,
642,11,44,1,13,
642,11,63,4,0,
642,11,70,4,0,
642,11,84,1,1,3
642,11,85,4,0,
642,11,86,4,0,
642,11,87,1,67,
642,11,87,4,0,
642,11,92,4,0,
642,11,94,4,0,
642,11,97,1,37,
642,11,104,4,0,
642,11,156,4,0,
642,11,164,4,0,
642,11,168,4,0,
642,11,182,4,0,
642,11,188,4,0,
642,11,207,1,7,
642,11,207,4,0,
642,11,213,4,0,
642,11,216,4,0,
642,11,218,4,0,
642,11,237,4,0,
642,11,240,4,0,
642,11,242,1,49,
642,11,249,4,0,
642,11,253,1,1,1
642,11,259,4,0,
642,11,263,4,0,
642,11,268,1,55,
642,11,269,4,0,
642,11,279,1,19,
642,11,280,4,0,
642,11,310,1,1,2
642,11,339,4,0,
642,11,351,1,25,
642,11,359,1,79,
642,11,369,4,0,
642,11,371,4,0,
642,11,373,4,0,
642,11,374,4,0,
642,11,377,1,31,
642,11,399,1,73,
642,11,411,4,0,
642,11,416,4,0,
642,11,417,1,61,
642,11,430,4,0,
642,11,435,1,43,
642,11,447,4,0,
642,11,451,4,0,
642,11,479,4,0,
642,11,482,4,0,
642,11,496,4,0,
642,11,507,4,0,
642,11,510,4,0,
642,11,521,4,0,
642,11,528,4,0,
642,14,9,3,0,
642,14,19,4,0,
642,14,37,1,85,
642,14,44,1,13,
642,14,63,4,0,
642,14,70,4,0,
642,14,84,1,1,3
642,14,85,4,0,
642,14,86,4,0,
642,14,87,1,67,
642,14,87,4,0,
642,14,92,4,0,
642,14,94,4,0,
642,14,97,1,37,
642,14,104,4,0,
642,14,156,4,0,
642,14,164,4,0,
642,14,168,4,0,
642,14,173,3,0,
642,14,182,4,0,
642,14,188,4,0,
642,14,207,1,7,
642,14,207,4,0,
642,14,213,4,0,
642,14,214,3,0,
642,14,216,4,0,
642,14,218,4,0,
642,14,231,3,0,
642,14,237,4,0,
642,14,240,4,0,
642,14,242,1,49,
642,14,249,4,0,
642,14,253,1,1,1
642,14,253,3,0,
642,14,259,4,0,
642,14,263,4,0,
642,14,268,1,55,
642,14,269,4,0,
642,14,272,3,0,
642,14,276,3,0,
642,14,279,1,19,
642,14,280,4,0,
642,14,282,3,0,
642,14,310,1,1,2
642,14,339,4,0,
642,14,351,1,25,
642,14,359,1,79,
642,14,369,4,0,
642,14,371,4,0,
642,14,373,4,0,
642,14,374,4,0,
642,14,377,1,31,
642,14,399,1,73,
642,14,399,3,0,
642,14,411,4,0,
642,14,416,4,0,
642,14,417,1,61,
642,14,430,4,0,
642,14,435,1,43,
642,14,447,4,0,
642,14,451,4,0,
642,14,479,4,0,
642,14,482,4,0,
642,14,492,3,0,
642,14,496,4,0,
642,14,507,4,0,
642,14,510,4,0,
642,14,521,4,0,
642,14,528,4,0,
642,15,19,4,0,
642,15,37,1,1,1
642,15,37,1,85,
642,15,44,1,13,
642,15,63,4,0,
642,15,70,4,0,
642,15,84,1,1,7
642,15,85,4,0,
642,15,86,4,0,
642,15,87,1,67,
642,15,87,4,0,
642,15,92,4,0,
642,15,94,4,0,
642,15,97,1,37,
642,15,104,4,0,
642,15,156,4,0,
642,15,164,4,0,
642,15,168,4,0,
642,15,182,4,0,
642,15,188,4,0,
642,15,207,1,7,
642,15,207,4,0,
642,15,213,4,0,
642,15,214,4,0,
642,15,216,4,0,
642,15,218,4,0,
642,15,237,4,0,
642,15,240,4,0,
642,15,242,1,49,
642,15,249,4,0,
642,15,253,1,1,5
642,15,259,4,0,
642,15,263,4,0,
642,15,268,1,1,4
642,15,268,1,55,
642,15,269,4,0,
642,15,279,1,19,
642,15,280,4,0,
642,15,310,1,1,6
642,15,339,4,0,
642,15,351,1,25,
642,15,359,1,1,2
642,15,359,1,79,
642,15,369,4,0,
642,15,371,4,0,
642,15,373,4,0,
642,15,374,4,0,
642,15,377,1,31,
642,15,399,1,73,
642,15,399,4,0,
642,15,411,4,0,
642,15,416,4,0,
642,15,417,1,1,3
642,15,417,1,61,
642,15,430,4,0,
642,15,435,1,43,
642,15,447,4,0,
642,15,451,4,0,
642,15,479,4,0,
642,15,482,4,0,
642,15,496,4,0,
642,15,507,4,0,
642,15,510,4,0,
642,15,521,4,0,
642,15,528,4,0,
642,15,590,4,0,
642,16,9,3,0,
642,16,19,4,0,
642,16,37,1,1,1
642,16,37,1,85,1
642,16,44,1,13,1
642,16,63,4,0,
642,16,70,4,0,
642,16,84,1,1,7
642,16,85,4,0,
642,16,86,4,0,
642,16,87,1,67,1
642,16,87,4,0,
642,16,92,4,0,
642,16,94,4,0,
642,16,97,1,37,1
642,16,104,4,0,
642,16,156,4,0,
642,16,164,4,0,
642,16,168,4,0,
642,16,173,3,0,
642,16,182,4,0,
642,16,188,4,0,
642,16,207,1,7,1
642,16,207,4,0,
642,16,213,4,0,
642,16,214,4,0,
642,16,216,4,0,
642,16,218,4,0,
642,16,231,3,0,
642,16,237,4,0,
642,16,240,4,0,
642,16,242,1,49,1
642,16,249,4,0,
642,16,253,1,1,5
642,16,253,3,0,
642,16,259,4,0,
642,16,263,4,0,
642,16,268,1,1,4
642,16,268,1,55,1
642,16,269,4,0,
642,16,272,3,0,
642,16,276,3,0,
642,16,279,1,19,1
642,16,280,4,0,
642,16,282,3,0,
642,16,290,4,0,
642,16,310,1,1,6
642,16,339,4,0,
642,16,351,1,25,1
642,16,351,3,0,
642,16,359,1,1,2
642,16,359,1,79,1
642,16,369,4,0,
642,16,371,4,0,
642,16,373,4,0,
642,16,374,4,0,
642,16,377,1,31,1
642,16,399,1,73,1
642,16,399,4,0,
642,16,411,4,0,
642,16,416,4,0,
642,16,417,1,1,3
642,16,417,1,61,1
642,16,430,4,0,
642,16,435,1,43,1
642,16,447,4,0,
642,16,451,4,0,
642,16,479,4,0,
642,16,482,4,0,
642,16,492,3,0,
642,16,496,4,0,
642,16,507,4,0,
642,16,510,4,0,
642,16,521,4,0,
642,16,527,3,0,
642,16,528,4,0,
642,16,590,4,0,
642,17,19,4,0,
642,17,37,1,1,1
642,17,37,1,79,
642,17,44,1,7,
642,17,63,4,0,
642,17,84,1,1,6
642,17,85,4,0,
642,17,86,4,0,
642,17,87,1,61,
642,17,87,4,0,
642,17,92,4,0,
642,17,94,4,0,
642,17,97,1,31,
642,17,104,4,0,
642,17,156,4,0,
642,17,164,4,0,
642,17,168,4,0,
642,17,182,4,0,
642,17,188,4,0,
642,17,207,1,1,7
642,17,207,4,0,
642,17,213,4,0,
642,17,214,4,0,
642,17,216,4,0,
642,17,218,4,0,
642,17,237,4,0,
642,17,240,4,0,
642,17,242,1,43,
642,17,253,1,1,4
642,17,259,4,0,
642,17,263,4,0,
642,17,268,1,49,
642,17,269,4,0,
642,17,279,1,13,
642,17,280,4,0,
642,17,310,1,1,5
642,17,339,4,0,
642,17,351,1,19,
642,17,359,1,1,2
642,17,359,1,73,
642,17,369,4,0,
642,17,371,4,0,
642,17,373,4,0,
642,17,374,4,0,
642,17,377,1,25,
642,17,399,1,67,
642,17,399,4,0,
642,17,411,4,0,
642,17,416,4,0,
642,17,417,1,1,3
642,17,417,1,55,
642,17,430,4,0,
642,17,435,1,37,
642,17,447,4,0,
642,17,451,4,0,
642,17,479,4,0,
642,17,482,4,0,
642,17,496,4,0,
642,17,507,4,0,
642,17,521,4,0,
642,17,528,4,0,
642,17,590,4,0,
642,17,684,4,0,
642,17,693,4,0,
642,18,19,4,0,
642,18,37,1,1,1
642,18,37,1,79,
642,18,44,1,7,
642,18,63,4,0,
642,18,84,1,1,6
642,18,85,4,0,
642,18,86,4,0,
642,18,87,1,61,
642,18,87,4,0,
642,18,92,4,0,
642,18,94,4,0,
642,18,97,1,31,
642,18,104,4,0,
642,18,156,4,0,
642,18,164,4,0,
642,18,168,4,0,
642,18,182,4,0,
642,18,188,4,0,
642,18,207,1,1,7
642,18,207,4,0,
642,18,213,4,0,
642,18,214,4,0,
642,18,216,4,0,
642,18,218,4,0,
642,18,237,4,0,
642,18,240,4,0,
642,18,242,1,43,
642,18,253,1,1,4
642,18,259,4,0,
642,18,263,4,0,
642,18,268,1,49,
642,18,269,4,0,
642,18,279,1,13,
642,18,280,4,0,
642,18,310,1,1,5
642,18,339,4,0,
642,18,351,1,19,
642,18,359,1,1,2
642,18,359,1,73,
642,18,369,4,0,
642,18,371,4,0,
642,18,373,4,0,
642,18,374,4,0,
642,18,377,1,25,
642,18,399,1,67,
642,18,399,4,0,
642,18,411,4,0,
642,18,416,4,0,
642,18,417,1,1,3
642,18,417,1,55,
642,18,430,4,0,
642,18,435,1,37,
642,18,447,4,0,
642,18,451,4,0,
642,18,479,4,0,
642,18,482,4,0,
642,18,496,4,0,
642,18,507,4,0,
642,18,521,4,0,
642,18,528,4,0,
642,18,590,4,0,
642,18,684,4,0,
642,18,693,4,0,
643,11,15,4,0,
643,11,19,4,0,
643,11,53,1,22,
643,11,53,4,0,
643,11,63,4,0,
643,11,70,4,0,
643,11,76,4,0,
643,11,82,1,1,2
643,11,92,4,0,
643,11,94,4,0,
643,11,104,4,0,
643,11,113,4,0,
643,11,115,4,0,
643,11,126,1,78,
643,11,126,4,0,
643,11,156,4,0,
643,11,157,4,0,
643,11,163,1,36,
643,11,164,4,0,
643,11,182,4,0,
643,11,200,1,85,
643,11,207,4,0,
643,11,216,4,0,
643,11,218,4,0,
643,11,219,4,0,
643,11,225,1,29,
643,11,237,4,0,
643,11,241,4,0,
643,11,242,1,71,
643,11,246,1,15,
643,11,247,4,0,
643,11,249,4,0,
643,11,261,4,0,
643,11,263,4,0,
643,11,286,1,8,
643,11,286,1,64,
643,11,304,1,92,
643,11,315,4,0,
643,11,317,4,0,
643,11,326,1,43,
643,11,337,4,0,
643,11,371,4,0,
643,11,374,4,0,
643,11,406,1,54,
643,11,411,4,0,
643,11,416,4,0,
643,11,421,4,0,
643,11,424,1,1,1
643,11,434,3,0,
643,11,444,4,0,
643,11,468,4,0,
643,11,488,4,0,
643,11,496,4,0,
643,11,497,4,0,
643,11,510,4,0,
643,11,525,4,0,
643,11,551,1,100,
643,11,558,1,50,
643,14,15,4,0,
643,14,19,4,0,
643,14,53,1,22,
643,14,53,4,0,
643,14,63,4,0,
643,14,70,4,0,
643,14,76,4,0,
643,14,82,1,1,2
643,14,92,4,0,
643,14,94,4,0,
643,14,104,4,0,
643,14,113,4,0,
643,14,115,4,0,
643,14,126,1,78,
643,14,126,4,0,
643,14,156,4,0,
643,14,157,4,0,
643,14,163,1,36,
643,14,164,4,0,
643,14,173,3,0,
643,14,182,4,0,
643,14,200,1,85,
643,14,200,3,0,
643,14,207,4,0,
643,14,214,3,0,
643,14,216,4,0,
643,14,218,4,0,
643,14,219,4,0,
643,14,225,1,29,
643,14,237,4,0,
643,14,241,4,0,
643,14,242,1,71,
643,14,246,1,15,
643,14,247,4,0,
643,14,249,4,0,
643,14,257,3,0,
643,14,261,4,0,
643,14,263,4,0,
643,14,286,1,8,
643,14,286,1,64,
643,14,304,1,92,
643,14,304,3,0,
643,14,315,4,0,
643,14,317,4,0,
643,14,326,1,43,
643,14,337,4,0,
643,14,355,3,0,
643,14,366,3,0,
643,14,371,4,0,
643,14,374,4,0,
643,14,406,1,54,
643,14,406,3,0,
643,14,411,4,0,
643,14,414,3,0,
643,14,416,4,0,
643,14,421,4,0,
643,14,424,1,1,1
643,14,428,3,0,
643,14,434,3,0,
643,14,444,4,0,
643,14,468,4,0,
643,14,488,4,0,
643,14,496,4,0,
643,14,497,4,0,
643,14,510,4,0,
643,14,525,4,0,
643,14,551,1,100,
643,14,558,1,50,
643,15,15,4,0,
643,15,19,4,0,
643,15,53,1,22,
643,15,53,4,0,
643,15,63,4,0,
643,15,70,4,0,
643,15,76,4,0,
643,15,82,1,1,2
643,15,92,4,0,
643,15,94,4,0,
643,15,104,4,0,
643,15,113,4,0,
643,15,115,4,0,
643,15,126,1,78,
643,15,126,4,0,
643,15,156,4,0,
643,15,157,4,0,
643,15,163,1,36,
643,15,164,4,0,
643,15,182,4,0,
643,15,200,1,85,
643,15,207,4,0,
643,15,211,4,0,
643,15,214,4,0,
643,15,216,4,0,
643,15,218,4,0,
643,15,219,4,0,
643,15,225,1,29,
643,15,237,4,0,
643,15,241,4,0,
643,15,242,1,71,
643,15,246,1,15,
643,15,247,4,0,
643,15,249,4,0,
643,15,261,4,0,
643,15,263,4,0,
643,15,286,1,8,
643,15,286,1,64,
643,15,304,1,92,
643,15,315,4,0,
643,15,317,4,0,
643,15,326,1,43,
643,15,337,4,0,
643,15,355,4,0,
643,15,371,4,0,
643,15,374,4,0,
643,15,406,1,54,
643,15,411,4,0,
643,15,416,4,0,
643,15,421,4,0,
643,15,424,1,1,1
643,15,434,3,0,
643,15,444,4,0,
643,15,468,4,0,
643,15,488,4,0,
643,15,496,4,0,
643,15,497,4,0,
643,15,510,4,0,
643,15,525,4,0,
643,15,551,1,100,
643,15,558,1,50,
643,15,590,4,0,
643,16,15,4,0,
643,16,19,4,0,
643,16,53,1,22,1
643,16,53,4,0,
643,16,63,4,0,
643,16,70,4,0,
643,16,76,4,0,
643,16,82,1,1,2
643,16,92,4,0,
643,16,94,4,0,
643,16,104,4,0,
643,16,113,4,0,
643,16,115,4,0,
643,16,126,1,78,1
643,16,126,4,0,
643,16,156,4,0,
643,16,157,4,0,
643,16,163,1,36,1
643,16,164,4,0,
643,16,173,3,0,
643,16,182,4,0,
643,16,200,1,85,1
643,16,200,3,0,
643,16,207,4,0,
643,16,211,4,0,
643,16,214,4,0,
643,16,216,4,0,
643,16,218,4,0,
643,16,219,4,0,
643,16,225,1,29,1
643,16,237,4,0,
643,16,241,4,0,
643,16,242,1,71,1
643,16,246,1,15,1
643,16,247,4,0,
643,16,249,4,0,
643,16,257,3,0,
643,16,261,4,0,
643,16,263,4,0,
643,16,286,1,8,1
643,16,286,1,64,1
643,16,290,4,0,
643,16,304,1,92,1
643,16,304,3,0,
643,16,315,4,0,
643,16,317,4,0,
643,16,326,1,43,1
643,16,337,4,0,
643,16,355,4,0,
643,16,366,3,0,
643,16,371,4,0,
643,16,374,4,0,
643,16,406,1,54,1
643,16,406,3,0,
643,16,411,4,0,
643,16,414,3,0,
643,16,416,4,0,
643,16,421,4,0,
643,16,424,1,1,1
643,16,428,3,0,
643,16,434,3,0,
643,16,444,4,0,
643,16,468,4,0,
643,16,488,4,0,
643,16,496,4,0,
643,16,497,4,0,
643,16,510,4,0,
643,16,525,4,0,
643,16,551,1,100,1
643,16,558,1,50,1
643,16,590,4,0,
643,17,19,4,0,
643,17,53,1,22,
643,17,53,4,0,
643,17,63,4,0,
643,17,76,4,0,
643,17,82,1,1,2
643,17,92,4,0,
643,17,94,4,0,
643,17,104,4,0,
643,17,113,4,0,
643,17,115,4,0,
643,17,126,1,78,
643,17,126,4,0,
643,17,156,4,0,
643,17,157,4,0,
643,17,163,1,36,
643,17,164,4,0,
643,17,182,4,0,
643,17,200,1,85,
643,17,207,4,0,
643,17,211,4,0,
643,17,214,4,0,
643,17,216,4,0,
643,17,218,4,0,
643,17,219,4,0,
643,17,225,1,29,
643,17,237,4,0,
643,17,241,4,0,
643,17,242,1,71,
643,17,246,1,15,
643,17,247,4,0,
643,17,261,4,0,
643,17,263,4,0,
643,17,286,1,8,
643,17,286,1,64,
643,17,304,1,92,
643,17,315,4,0,
643,17,317,4,0,
643,17,326,1,43,
643,17,337,4,0,
643,17,355,4,0,
643,17,371,4,0,
643,17,374,4,0,
643,17,406,1,54,
643,17,411,4,0,
643,17,416,4,0,
643,17,421,4,0,
643,17,424,1,1,1
643,17,434,3,0,
643,17,444,4,0,
643,17,488,4,0,
643,17,496,4,0,
643,17,497,4,0,
643,17,525,4,0,
643,17,551,1,100,
643,17,558,1,50,
643,17,590,4,0,
643,17,693,4,0,
643,18,19,4,0,
643,18,53,1,22,
643,18,53,4,0,
643,18,63,4,0,
643,18,76,4,0,
643,18,82,1,1,2
643,18,92,4,0,
643,18,94,4,0,
643,18,104,4,0,
643,18,113,4,0,
643,18,115,4,0,
643,18,126,1,78,
643,18,126,4,0,
643,18,156,4,0,
643,18,157,4,0,
643,18,163,1,36,
643,18,164,4,0,
643,18,182,4,0,
643,18,200,1,85,
643,18,207,4,0,
643,18,211,4,0,
643,18,214,4,0,
643,18,216,4,0,
643,18,218,4,0,
643,18,219,4,0,
643,18,225,1,29,
643,18,237,4,0,
643,18,241,4,0,
643,18,242,1,71,
643,18,246,1,15,
643,18,247,4,0,
643,18,261,4,0,
643,18,263,4,0,
643,18,286,1,8,
643,18,304,1,92,
643,18,315,4,0,
643,18,317,4,0,
643,18,326,1,43,
643,18,337,4,0,
643,18,355,4,0,
643,18,371,4,0,
643,18,374,4,0,
643,18,406,1,54,
643,18,411,4,0,
643,18,416,4,0,
643,18,421,4,0,
643,18,424,1,1,1
643,18,434,3,0,
643,18,444,4,0,
643,18,488,4,0,
643,18,496,4,0,
643,18,497,4,0,
643,18,525,4,0,
643,18,551,1,100,
643,18,558,1,50,
643,18,568,1,64,
643,18,590,4,0,
643,18,693,4,0,
644,11,15,4,0,
644,11,19,4,0,
644,11,63,4,0,
644,11,70,4,0,
644,11,82,1,1,2
644,11,85,1,22,
644,11,85,4,0,
644,11,86,4,0,
644,11,87,1,78,
644,11,87,4,0,
644,11,92,4,0,
644,11,94,4,0,
644,11,104,4,0,
644,11,113,4,0,
644,11,115,4,0,
644,11,148,4,0,
644,11,156,4,0,
644,11,157,4,0,
644,11,163,1,36,
644,11,164,4,0,
644,11,182,4,0,
644,11,200,1,85,
644,11,207,4,0,
644,11,216,4,0,
644,11,218,4,0,
644,11,219,4,0,
644,11,225,1,29,
644,11,237,4,0,
644,11,240,4,0,
644,11,242,1,71,
644,11,246,1,15,
644,11,247,4,0,
644,11,249,4,0,
644,11,263,4,0,
644,11,286,1,8,
644,11,286,1,64,
644,11,304,1,92,
644,11,317,4,0,
644,11,337,1,54,
644,11,337,4,0,
644,11,371,4,0,
644,11,374,4,0,
644,11,411,4,0,
644,11,416,4,0,
644,11,421,4,0,
644,11,422,1,1,1
644,11,428,1,43,
644,11,430,4,0,
644,11,434,3,0,
644,11,444,4,0,
644,11,451,4,0,
644,11,468,4,0,
644,11,496,4,0,
644,11,497,4,0,
644,11,521,4,0,
644,11,525,4,0,
644,11,528,4,0,
644,11,550,1,100,
644,11,559,1,50,
644,14,9,3,0,
644,14,15,4,0,
644,14,19,4,0,
644,14,63,4,0,
644,14,70,4,0,
644,14,82,1,1,2
644,14,85,1,22,
644,14,85,4,0,
644,14,86,4,0,
644,14,87,1,78,
644,14,87,4,0,
644,14,92,4,0,
644,14,94,4,0,
644,14,104,4,0,
644,14,113,4,0,
644,14,115,4,0,
644,14,148,4,0,
644,14,156,4,0,
644,14,157,4,0,
644,14,163,1,36,
644,14,164,4,0,
644,14,173,3,0,
644,14,182,4,0,
644,14,200,1,85,
644,14,200,3,0,
644,14,207,4,0,
644,14,214,3,0,
644,14,216,4,0,
644,14,218,4,0,
644,14,219,4,0,
644,14,225,1,29,
644,14,237,4,0,
644,14,240,4,0,
644,14,242,1,71,
644,14,246,1,15,
644,14,247,4,0,
644,14,249,4,0,
644,14,263,4,0,
644,14,286,1,8,
644,14,286,1,64,
644,14,304,1,92,
644,14,304,3,0,
644,14,317,4,0,
644,14,324,3,0,
644,14,337,1,54,
644,14,337,4,0,
644,14,355,3,0,
644,14,366,3,0,
644,14,371,4,0,
644,14,374,4,0,
644,14,393,3,0,
644,14,406,3,0,
644,14,411,4,0,
644,14,414,3,0,
644,14,416,4,0,
644,14,421,4,0,
644,14,422,1,1,1
644,14,428,1,43,
644,14,428,3,0,
644,14,430,4,0,
644,14,434,3,0,
644,14,444,4,0,
644,14,451,4,0,
644,14,468,4,0,
644,14,496,4,0,
644,14,497,4,0,
644,14,521,4,0,
644,14,525,4,0,
644,14,528,4,0,
644,14,550,1,100,
644,14,559,1,50,
644,15,15,4,0,
644,15,19,4,0,
644,15,63,4,0,
644,15,70,4,0,
644,15,82,1,1,2
644,15,85,1,22,
644,15,85,4,0,
644,15,86,4,0,
644,15,87,1,78,
644,15,87,4,0,
644,15,92,4,0,
644,15,94,4,0,
644,15,104,4,0,
644,15,113,4,0,
644,15,115,4,0,
644,15,148,4,0,
644,15,156,4,0,
644,15,157,4,0,
644,15,163,1,36,
644,15,164,4,0,
644,15,182,4,0,
644,15,200,1,85,
644,15,207,4,0,
644,15,211,4,0,
644,15,214,4,0,
644,15,216,4,0,
644,15,218,4,0,
644,15,219,4,0,
644,15,225,1,29,
644,15,237,4,0,
644,15,240,4,0,
644,15,242,1,71,
644,15,246,1,15,
644,15,247,4,0,
644,15,249,4,0,
644,15,263,4,0,
644,15,286,1,8,
644,15,286,1,64,
644,15,304,1,92,
644,15,317,4,0,
644,15,337,1,54,
644,15,337,4,0,
644,15,355,4,0,
644,15,371,4,0,
644,15,374,4,0,
644,15,411,4,0,
644,15,416,4,0,
644,15,421,4,0,
644,15,422,1,1,1
644,15,428,1,43,
644,15,430,4,0,
644,15,434,3,0,
644,15,444,4,0,
644,15,451,4,0,
644,15,468,4,0,
644,15,496,4,0,
644,15,497,4,0,
644,15,521,4,0,
644,15,525,4,0,
644,15,528,4,0,
644,15,550,1,100,
644,15,559,1,50,
644,15,590,4,0,
644,16,9,3,0,
644,16,15,4,0,
644,16,19,4,0,
644,16,63,4,0,
644,16,70,4,0,
644,16,82,1,1,2
644,16,85,1,22,1
644,16,85,4,0,
644,16,86,4,0,
644,16,87,1,78,1
644,16,87,4,0,
644,16,92,4,0,
644,16,94,4,0,
644,16,104,4,0,
644,16,113,4,0,
644,16,115,4,0,
644,16,148,4,0,
644,16,156,4,0,
644,16,157,4,0,
644,16,163,1,36,1
644,16,164,4,0,
644,16,173,3,0,
644,16,182,4,0,
644,16,200,1,85,1
644,16,200,3,0,
644,16,207,4,0,
644,16,211,4,0,
644,16,214,4,0,
644,16,216,4,0,
644,16,218,4,0,
644,16,219,4,0,
644,16,225,1,29,1
644,16,237,4,0,
644,16,240,4,0,
644,16,242,1,71,1
644,16,246,1,15,1
644,16,247,4,0,
644,16,249,4,0,
644,16,263,4,0,
644,16,286,1,8,1
644,16,286,1,64,1
644,16,290,4,0,
644,16,304,1,92,1
644,16,304,3,0,
644,16,317,4,0,
644,16,324,3,0,
644,16,337,1,54,1
644,16,337,4,0,
644,16,351,3,0,
644,16,355,4,0,
644,16,366,3,0,
644,16,371,4,0,
644,16,374,4,0,
644,16,393,3,0,
644,16,406,3,0,
644,16,411,4,0,
644,16,414,3,0,
644,16,416,4,0,
644,16,421,4,0,
644,16,422,1,1,1
644,16,428,1,43,1
644,16,428,3,0,
644,16,430,4,0,
644,16,434,3,0,
644,16,444,4,0,
644,16,451,4,0,
644,16,468,4,0,
644,16,496,4,0,
644,16,497,4,0,
644,16,521,4,0,
644,16,525,4,0,
644,16,528,4,0,
644,16,550,1,100,1
644,16,559,1,50,1
644,16,590,4,0,
644,17,19,4,0,
644,17,63,4,0,
644,17,82,1,1,2
644,17,85,1,22,
644,17,85,4,0,
644,17,86,4,0,
644,17,87,1,78,
644,17,87,4,0,
644,17,92,4,0,
644,17,94,4,0,
644,17,104,4,0,
644,17,113,4,0,
644,17,115,4,0,
644,17,156,4,0,
644,17,157,4,0,
644,17,163,1,36,
644,17,164,4,0,
644,17,182,4,0,
644,17,200,1,85,
644,17,207,4,0,
644,17,211,4,0,
644,17,214,4,0,
644,17,216,4,0,
644,17,218,4,0,
644,17,219,4,0,
644,17,225,1,29,
644,17,237,4,0,
644,17,240,4,0,
644,17,242,1,71,
644,17,246,1,15,
644,17,247,4,0,
644,17,263,4,0,
644,17,286,1,8,
644,17,286,1,64,
644,17,304,1,92,
644,17,317,4,0,
644,17,337,1,54,
644,17,337,4,0,
644,17,355,4,0,
644,17,371,4,0,
644,17,374,4,0,
644,17,411,4,0,
644,17,416,4,0,
644,17,421,4,0,
644,17,422,1,1,1
644,17,428,1,43,
644,17,430,4,0,
644,17,434,3,0,
644,17,444,4,0,
644,17,451,4,0,
644,17,496,4,0,
644,17,497,4,0,
644,17,521,4,0,
644,17,525,4,0,
644,17,528,4,0,
644,17,550,1,100,
644,17,559,1,50,
644,17,590,4,0,
644,17,693,4,0,
644,18,19,4,0,
644,18,63,4,0,
644,18,82,1,1,2
644,18,85,1,22,
644,18,85,4,0,
644,18,86,4,0,
644,18,87,1,78,
644,18,87,4,0,
644,18,92,4,0,
644,18,94,4,0,
644,18,104,4,0,
644,18,113,4,0,
644,18,115,4,0,
644,18,156,4,0,
644,18,157,4,0,
644,18,163,1,36,
644,18,164,4,0,
644,18,182,4,0,
644,18,200,1,85,
644,18,207,4,0,
644,18,211,4,0,
644,18,214,4,0,
644,18,216,4,0,
644,18,218,4,0,
644,18,219,4,0,
644,18,225,1,29,
644,18,237,4,0,
644,18,240,4,0,
644,18,242,1,71,
644,18,246,1,15,
644,18,247,4,0,
644,18,263,4,0,
644,18,286,1,8,
644,18,304,1,92,
644,18,317,4,0,
644,18,337,1,54,
644,18,337,4,0,
644,18,355,4,0,
644,18,371,4,0,
644,18,374,4,0,
644,18,411,4,0,
644,18,416,4,0,
644,18,421,4,0,
644,18,422,1,1,1
644,18,428,1,43,
644,18,430,4,0,
644,18,434,3,0,
644,18,444,4,0,
644,18,451,4,0,
644,18,496,4,0,
644,18,497,4,0,
644,18,521,4,0,
644,18,525,4,0,
644,18,528,4,0,
644,18,550,1,100,
644,18,559,1,50,
644,18,568,1,64,
644,18,590,4,0,
644,18,693,4,0,
645,11,14,1,37,
645,11,14,4,0,
645,11,19,4,0,
645,11,63,4,0,
645,11,70,4,0,
645,11,88,1,25,
645,11,89,1,55,
645,11,89,4,0,
645,11,90,1,67,
645,11,91,4,0,
645,11,92,4,0,
645,11,94,4,0,
645,11,104,4,0,
645,11,153,4,0,
645,11,156,4,0,
645,11,157,1,49,
645,11,157,4,0,
645,11,164,4,0,
645,11,182,4,0,
645,11,188,4,0,
645,11,200,1,85,
645,11,201,1,61,
645,11,201,4,0,
645,11,207,4,0,
645,11,213,4,0,
645,11,216,4,0,
645,11,218,4,0,
645,11,237,4,0,
645,11,249,4,0,
645,11,263,4,0,
645,11,280,4,0,
645,11,286,1,7,
645,11,317,1,1,3
645,11,317,4,0,
645,11,326,1,31,
645,11,335,1,1,1
645,11,339,4,0,
645,11,341,1,1,2
645,11,347,4,0,
645,11,359,1,79,
645,11,369,4,0,
645,11,371,4,0,
645,11,374,4,0,
645,11,386,1,13,
645,11,397,4,0,
645,11,411,4,0,
645,11,414,1,43,
645,11,416,4,0,
645,11,444,1,73,
645,11,444,4,0,
645,11,447,4,0,
645,11,479,4,0,
645,11,482,4,0,
645,11,496,4,0,
645,11,523,1,19,
645,11,523,4,0,
645,14,14,1,37,
645,14,14,4,0,
645,14,19,4,0,
645,14,63,4,0,
645,14,70,4,0,
645,14,88,1,25,
645,14,89,1,55,
645,14,89,4,0,
645,14,90,1,67,
645,14,91,4,0,
645,14,92,4,0,
645,14,94,4,0,
645,14,104,4,0,
645,14,153,4,0,
645,14,156,4,0,
645,14,157,1,49,
645,14,157,4,0,
645,14,164,4,0,
645,14,173,3,0,
645,14,182,4,0,
645,14,188,4,0,
645,14,200,1,85,
645,14,200,3,0,
645,14,201,1,61,
645,14,201,4,0,
645,14,207,4,0,
645,14,213,4,0,
645,14,214,3,0,
645,14,216,4,0,
645,14,218,4,0,
645,14,231,3,0,
645,14,237,4,0,
645,14,249,4,0,
645,14,263,4,0,
645,14,272,3,0,
645,14,276,3,0,
645,14,280,4,0,
645,14,282,3,0,
645,14,286,1,7,
645,14,317,1,1,3
645,14,317,4,0,
645,14,326,1,31,
645,14,335,1,1,1
645,14,335,3,0,
645,14,339,4,0,
645,14,341,1,1,2
645,14,347,4,0,
645,14,356,3,0,
645,14,359,1,79,
645,14,369,4,0,
645,14,371,4,0,
645,14,374,4,0,
645,14,386,1,13,
645,14,397,4,0,
645,14,411,4,0,
645,14,414,1,43,
645,14,414,3,0,
645,14,416,4,0,
645,14,444,1,73,
645,14,444,4,0,
645,14,446,3,0,
645,14,447,4,0,
645,14,479,4,0,
645,14,482,4,0,
645,14,496,4,0,
645,14,523,1,19,
645,14,523,4,0,
645,15,14,1,37,
645,15,14,4,0,
645,15,19,4,0,
645,15,63,4,0,
645,15,70,4,0,
645,15,88,1,25,
645,15,89,1,55,
645,15,89,4,0,
645,15,90,1,1,3
645,15,90,1,67,
645,15,91,4,0,
645,15,92,4,0,
645,15,94,4,0,
645,15,104,4,0,
645,15,153,4,0,
645,15,156,4,0,
645,15,157,1,49,
645,15,157,4,0,
645,15,164,4,0,
645,15,182,4,0,
645,15,188,4,0,
645,15,200,1,1,1
645,15,200,1,85,
645,15,201,1,61,
645,15,201,4,0,
645,15,207,4,0,
645,15,213,4,0,
645,15,214,4,0,
645,15,216,4,0,
645,15,218,4,0,
645,15,237,4,0,
645,15,249,4,0,
645,15,263,4,0,
645,15,280,4,0,
645,15,286,1,7,
645,15,317,1,1,6
645,15,317,4,0,
645,15,326,1,31,
645,15,335,1,1,4
645,15,339,4,0,
645,15,341,1,1,5
645,15,347,4,0,
645,15,359,1,1,2
645,15,359,1,79,
645,15,369,4,0,
645,15,371,4,0,
645,15,374,4,0,
645,15,386,1,13,
645,15,397,4,0,
645,15,411,4,0,
645,15,414,1,43,
645,15,416,4,0,
645,15,444,1,73,
645,15,444,4,0,
645,15,447,4,0,
645,15,479,4,0,
645,15,482,4,0,
645,15,496,4,0,
645,15,523,1,19,
645,15,523,4,0,
645,15,590,4,0,
645,16,14,1,37,1
645,16,14,4,0,
645,16,19,4,0,
645,16,63,4,0,
645,16,70,4,0,
645,16,88,1,25,1
645,16,89,1,55,1
645,16,89,4,0,
645,16,90,1,1,3
645,16,90,1,67,1
645,16,91,4,0,
645,16,92,4,0,
645,16,94,4,0,
645,16,104,4,0,
645,16,153,4,0,
645,16,156,4,0,
645,16,157,1,49,1
645,16,157,4,0,
645,16,164,4,0,
645,16,173,3,0,
645,16,182,4,0,
645,16,188,4,0,
645,16,200,1,1,1
645,16,200,1,85,1
645,16,200,3,0,
645,16,201,1,61,1
645,16,201,4,0,
645,16,207,4,0,
645,16,213,4,0,
645,16,214,4,0,
645,16,216,4,0,
645,16,218,4,0,
645,16,231,3,0,
645,16,237,4,0,
645,16,249,4,0,
645,16,263,4,0,
645,16,272,3,0,
645,16,276,3,0,
645,16,280,4,0,
645,16,282,3,0,
645,16,286,1,7,1
645,16,290,4,0,
645,16,317,1,1,6
645,16,317,4,0,
645,16,326,1,31,1
645,16,335,1,1,4
645,16,335,3,0,
645,16,339,4,0,
645,16,341,1,1,5
645,16,347,4,0,
645,16,356,3,0,
645,16,359,1,1,2
645,16,359,1,79,1
645,16,369,4,0,
645,16,371,4,0,
645,16,374,4,0,
645,16,386,1,13,1
645,16,397,4,0,
645,16,411,4,0,
645,16,414,1,43,1
645,16,414,3,0,
645,16,416,4,0,
645,16,444,1,73,1
645,16,444,4,0,
645,16,446,3,0,
645,16,447,4,0,
645,16,479,4,0,
645,16,482,4,0,
645,16,496,4,0,
645,16,523,1,19,1
645,16,523,4,0,
645,16,590,4,0,
645,17,14,1,31,
645,17,14,4,0,
645,17,19,4,0,
645,17,63,4,0,
645,17,88,1,19,
645,17,89,1,49,
645,17,89,4,0,
645,17,90,1,1,3
645,17,90,1,61,
645,17,92,4,0,
645,17,94,4,0,
645,17,104,4,0,
645,17,153,4,0,
645,17,156,4,0,
645,17,157,1,43,
645,17,157,4,0,
645,17,164,4,0,
645,17,182,4,0,
645,17,188,4,0,
645,17,200,1,1,1
645,17,200,1,79,
645,17,201,1,55,
645,17,201,4,0,
645,17,207,4,0,
645,17,213,4,0,
645,17,214,4,0,
645,17,216,4,0,
645,17,218,4,0,
645,17,237,4,0,
645,17,263,4,0,
645,17,280,4,0,
645,17,286,1,1,7
645,17,317,1,1,6
645,17,317,4,0,
645,17,326,1,25,
645,17,335,1,1,4
645,17,339,4,0,
645,17,341,1,1,5
645,17,347,4,0,
645,17,359,1,1,2
645,17,359,1,73,
645,17,369,4,0,
645,17,371,4,0,
645,17,374,4,0,
645,17,386,1,7,
645,17,397,4,0,
645,17,411,4,0,
645,17,414,1,37,
645,17,416,4,0,
645,17,444,1,67,
645,17,444,4,0,
645,17,447,4,0,
645,17,479,4,0,
645,17,482,4,0,
645,17,496,4,0,
645,17,523,1,13,
645,17,523,4,0,
645,17,590,4,0,
645,17,693,4,0,
645,18,14,1,31,
645,18,14,4,0,
645,18,19,4,0,
645,18,63,4,0,
645,18,88,1,19,
645,18,89,1,49,
645,18,89,4,0,
645,18,90,1,1,3
645,18,90,1,61,
645,18,92,4,0,
645,18,94,4,0,
645,18,104,4,0,
645,18,153,4,0,
645,18,156,4,0,
645,18,157,1,43,
645,18,157,4,0,
645,18,164,4,0,
645,18,182,4,0,
645,18,188,4,0,
645,18,200,1,1,1
645,18,200,1,79,
645,18,201,1,55,
645,18,201,4,0,
645,18,207,4,0,
645,18,213,4,0,
645,18,214,4,0,
645,18,216,4,0,
645,18,218,4,0,
645,18,237,4,0,
645,18,263,4,0,
645,18,280,4,0,
645,18,286,1,1,7
645,18,317,1,1,6
645,18,317,4,0,
645,18,326,1,25,
645,18,335,1,1,4
645,18,339,4,0,
645,18,341,1,1,5
645,18,347,4,0,
645,18,359,1,1,2
645,18,359,1,73,
645,18,369,4,0,
645,18,371,4,0,
645,18,374,4,0,
645,18,386,1,7,
645,18,397,4,0,
645,18,411,4,0,
645,18,414,1,37,
645,18,416,4,0,
645,18,444,1,67,
645,18,444,4,0,
645,18,447,4,0,
645,18,479,4,0,
645,18,482,4,0,
645,18,496,4,0,
645,18,523,1,13,
645,18,523,4,0,
645,18,590,4,0,
645,18,693,4,0,
646,11,15,4,0,
646,11,19,4,0,
646,11,58,1,22,
646,11,58,4,0,
646,11,59,1,78,
646,11,59,4,0,
646,11,63,4,0,
646,11,70,4,0,
646,11,82,1,1,2
646,11,92,4,0,
646,11,94,4,0,
646,11,104,4,0,
646,11,113,4,0,
646,11,115,4,0,
646,11,156,4,0,
646,11,157,4,0,
646,11,163,1,36,
646,11,164,4,0,
646,11,182,4,0,
646,11,184,1,43,
646,11,196,1,1,1
646,11,200,1,85,
646,11,207,4,0,
646,11,216,4,0,
646,11,218,4,0,
646,11,219,4,0,
646,11,225,1,29,
646,11,237,4,0,
646,11,240,4,0,
646,11,241,4,0,
646,11,246,1,15,
646,11,247,4,0,
646,11,249,4,0,
646,11,258,4,0,
646,11,263,4,0,
646,11,283,1,71,
646,11,286,1,8,
646,11,286,1,64,
646,11,304,1,92,
646,11,317,4,0,
646,11,337,4,0,
646,11,371,4,0,
646,11,374,4,0,
646,11,406,1,57,
646,11,411,4,0,
646,11,416,4,0,
646,11,421,4,0,
646,11,430,4,0,
646,11,434,3,0,
646,11,444,4,0,
646,11,468,4,0,
646,11,496,4,0,
646,11,497,4,0,
646,11,525,4,0,
646,11,549,1,50,
646,14,15,4,0,
646,14,19,4,0,
646,14,58,1,22,
646,14,58,4,0,
646,14,59,1,78,
646,14,59,4,0,
646,14,63,4,0,
646,14,70,4,0,
646,14,82,1,1,2
646,14,92,4,0,
646,14,94,4,0,
646,14,104,4,0,
646,14,113,4,0,
646,14,115,4,0,
646,14,156,4,0,
646,14,157,4,0,
646,14,163,1,36,
646,14,164,4,0,
646,14,173,3,0,
646,14,182,4,0,
646,14,184,1,43,
646,14,196,1,1,1
646,14,196,3,0,
646,14,200,1,85,
646,14,200,3,0,
646,14,207,4,0,
646,14,214,3,0,
646,14,216,4,0,
646,14,218,4,0,
646,14,219,4,0,
646,14,225,1,29,
646,14,237,4,0,
646,14,240,4,0,
646,14,241,4,0,
646,14,246,1,15,
646,14,247,4,0,
646,14,249,4,0,
646,14,258,4,0,
646,14,263,4,0,
646,14,283,1,71,
646,14,283,3,0,
646,14,286,1,8,
646,14,286,1,64,
646,14,304,1,92,
646,14,304,3,0,
646,14,317,4,0,
646,14,324,3,0,
646,14,337,4,0,
646,14,355,3,0,
646,14,371,4,0,
646,14,374,4,0,
646,14,406,1,57,
646,14,406,3,0,
646,14,411,4,0,
646,14,414,3,0,
646,14,416,4,0,
646,14,421,4,0,
646,14,428,3,0,
646,14,430,4,0,
646,14,434,3,0,
646,14,442,3,0,
646,14,444,4,0,
646,14,468,4,0,
646,14,496,4,0,
646,14,497,4,0,
646,14,525,4,0,
646,14,549,1,50,
646,15,15,4,0,
646,15,19,4,0,
646,15,58,1,22,
646,15,58,4,0,
646,15,59,1,78,
646,15,59,4,0,
646,15,63,4,0,
646,15,70,4,0,
646,15,82,1,1,2
646,15,92,4,0,
646,15,94,4,0,
646,15,104,4,0,
646,15,113,4,0,
646,15,115,4,0,
646,15,156,4,0,
646,15,157,4,0,
646,15,163,1,36,
646,15,164,4,0,
646,15,182,4,0,
646,15,184,1,43,
646,15,196,1,1,1
646,15,200,1,85,
646,15,207,4,0,
646,15,211,4,0,
646,15,214,4,0,
646,15,216,4,0,
646,15,218,4,0,
646,15,219,4,0,
646,15,225,1,29,
646,15,237,4,0,
646,15,240,4,0,
646,15,241,4,0,
646,15,246,1,15,
646,15,247,4,0,
646,15,249,4,0,
646,15,258,4,0,
646,15,263,4,0,
646,15,283,1,71,
646,15,286,1,8,
646,15,286,1,64,
646,15,304,1,92,
646,15,317,4,0,
646,15,337,4,0,
646,15,355,4,0,
646,15,371,4,0,
646,15,374,4,0,
646,15,406,1,57,
646,15,411,4,0,
646,15,416,4,0,
646,15,421,4,0,
646,15,430,4,0,
646,15,434,3,0,
646,15,444,4,0,
646,15,468,4,0,
646,15,496,4,0,
646,15,497,4,0,
646,15,525,4,0,
646,15,549,1,50,
646,15,590,4,0,
646,16,15,4,0,
646,16,19,4,0,
646,16,58,1,22,1
646,16,58,4,0,
646,16,59,1,78,1
646,16,59,4,0,
646,16,63,4,0,
646,16,70,4,0,
646,16,82,1,1,2
646,16,92,4,0,
646,16,94,4,0,
646,16,104,4,0,
646,16,113,4,0,
646,16,115,4,0,
646,16,156,4,0,
646,16,157,4,0,
646,16,163,1,36,1
646,16,164,4,0,
646,16,173,3,0,
646,16,182,4,0,
646,16,184,1,43,1
646,16,196,1,1,1
646,16,196,3,0,
646,16,200,1,85,1
646,16,200,3,0,
646,16,207,4,0,
646,16,211,4,0,
646,16,214,4,0,
646,16,216,4,0,
646,16,218,4,0,
646,16,219,4,0,
646,16,225,1,29,1
646,16,237,4,0,
646,16,240,4,0,
646,16,241,4,0,
646,16,246,1,15,1
646,16,247,4,0,
646,16,249,4,0,
646,16,258,4,0,
646,16,263,4,0,
646,16,283,1,71,1
646,16,283,3,0,
646,16,286,1,8,1
646,16,286,1,64,1
646,16,290,4,0,
646,16,304,1,92,1
646,16,304,3,0,
646,16,317,4,0,
646,16,324,3,0,
646,16,337,4,0,
646,16,355,4,0,
646,16,371,4,0,
646,16,374,4,0,
646,16,406,1,57,1
646,16,406,3,0,
646,16,411,4,0,
646,16,414,3,0,
646,16,416,4,0,
646,16,421,4,0,
646,16,428,3,0,
646,16,430,4,0,
646,16,434,3,0,
646,16,442,3,0,
646,16,444,4,0,
646,16,468,4,0,
646,16,496,4,0,
646,16,497,4,0,
646,16,525,4,0,
646,16,549,1,50,1
646,16,590,4,0,
646,17,19,4,0,
646,17,58,1,22,
646,17,58,4,0,
646,17,59,1,78,
646,17,59,4,0,
646,17,63,4,0,
646,17,82,1,1,2
646,17,92,4,0,
646,17,94,4,0,
646,17,104,4,0,
646,17,113,4,0,
646,17,115,4,0,
646,17,156,4,0,
646,17,157,4,0,
646,17,163,1,36,
646,17,164,4,0,
646,17,182,4,0,
646,17,184,1,43,
646,17,196,1,1,1
646,17,200,1,85,
646,17,207,4,0,
646,17,211,4,0,
646,17,214,4,0,
646,17,216,4,0,
646,17,218,4,0,
646,17,219,4,0,
646,17,225,1,29,
646,17,237,4,0,
646,17,240,4,0,
646,17,241,4,0,
646,17,246,1,15,
646,17,247,4,0,
646,17,258,4,0,
646,17,263,4,0,
646,17,283,1,71,
646,17,286,1,8,
646,17,286,1,64,
646,17,304,1,92,
646,17,317,4,0,
646,17,337,4,0,
646,17,355,4,0,
646,17,371,4,0,
646,17,374,4,0,
646,17,406,1,57,
646,17,411,4,0,
646,17,416,4,0,
646,17,421,4,0,
646,17,430,4,0,
646,17,434,3,0,
646,17,444,4,0,
646,17,496,4,0,
646,17,497,4,0,
646,17,525,4,0,
646,17,549,1,50,
646,17,590,4,0,
646,17,693,4,0,
646,18,19,4,0,
646,18,58,1,22,
646,18,58,4,0,
646,18,59,1,78,
646,18,59,4,0,
646,18,63,4,0,
646,18,82,1,1,2
646,18,92,4,0,
646,18,94,4,0,
646,18,104,4,0,
646,18,113,4,0,
646,18,115,4,0,
646,18,156,4,0,
646,18,157,4,0,
646,18,163,1,36,
646,18,164,4,0,
646,18,182,4,0,
646,18,184,1,43,
646,18,196,1,1,1
646,18,200,1,85,
646,18,207,4,0,
646,18,211,4,0,
646,18,214,4,0,
646,18,216,4,0,
646,18,218,4,0,
646,18,219,4,0,
646,18,225,1,29,
646,18,237,4,0,
646,18,240,4,0,
646,18,241,4,0,
646,18,246,1,15,
646,18,247,4,0,
646,18,258,4,0,
646,18,263,4,0,
646,18,283,1,71,
646,18,286,1,8,
646,18,304,1,92,
646,18,317,4,0,
646,18,337,4,0,
646,18,355,4,0,
646,18,371,4,0,
646,18,374,4,0,
646,18,406,1,57,
646,18,411,4,0,
646,18,416,4,0,
646,18,421,4,0,
646,18,430,4,0,
646,18,434,3,0,
646,18,444,4,0,
646,18,496,4,0,
646,18,497,4,0,
646,18,525,4,0,
646,18,549,1,50,
646,18,568,1,64,
646,18,590,4,0,
646,18,693,4,0,
647,11,14,1,49,
647,11,14,4,0,
647,11,15,4,0,
647,11,24,1,7,
647,11,36,1,19,
647,11,43,1,1,2
647,11,46,4,0,
647,11,56,1,67,
647,11,57,4,0,
647,11,61,1,13,
647,11,63,4,0,
647,11,70,4,0,
647,11,92,4,0,
647,11,104,4,0,
647,11,115,4,0,
647,11,156,4,0,
647,11,164,4,0,
647,11,182,4,0,
647,11,206,4,0,
647,11,207,4,0,
647,11,216,4,0,
647,11,218,4,0,
647,11,219,4,0,
647,11,237,4,0,
647,11,240,4,0,
647,11,244,4,0,
647,11,249,4,0,
647,11,258,4,0,
647,11,263,4,0,
647,11,269,4,0,
647,11,270,1,25,
647,11,332,4,0,
647,11,347,4,0,
647,11,370,1,73,
647,11,398,4,0,
647,11,401,1,37,
647,11,404,4,0,
647,11,411,4,0,
647,11,416,4,0,
647,11,444,4,0,
647,11,453,1,1,1
647,11,496,4,0,
647,11,501,1,55,
647,11,503,4,0,
647,11,514,1,31,
647,11,514,4,0,
647,11,526,1,61,
647,11,526,4,0,
647,11,533,1,43,
647,11,548,3,0,
647,14,14,1,49,
647,14,14,4,0,
647,14,15,4,0,
647,14,24,1,7,
647,14,36,1,19,
647,14,43,1,1,2
647,14,46,4,0,
647,14,56,1,67,
647,14,57,4,0,
647,14,61,1,13,
647,14,63,4,0,
647,14,70,4,0,
647,14,92,4,0,
647,14,104,4,0,
647,14,115,4,0,
647,14,156,4,0,
647,14,164,4,0,
647,14,173,3,0,
647,14,182,4,0,
647,14,196,3,0,
647,14,206,4,0,
647,14,207,4,0,
647,14,214,3,0,
647,14,216,4,0,
647,14,218,4,0,
647,14,219,4,0,
647,14,237,4,0,
647,14,240,4,0,
647,14,244,4,0,
647,14,249,4,0,
647,14,258,4,0,
647,14,263,4,0,
647,14,269,4,0,
647,14,270,1,25,
647,14,270,3,0,
647,14,276,3,0,
647,14,283,3,0,
647,14,332,4,0,
647,14,340,3,0,
647,14,343,3,0,
647,14,347,4,0,
647,14,370,1,73,
647,14,387,3,0,
647,14,398,4,0,
647,14,401,1,37,
647,14,401,3,0,
647,14,404,4,0,
647,14,411,4,0,
647,14,416,4,0,
647,14,444,4,0,
647,14,453,1,1,1
647,14,496,4,0,
647,14,501,1,55,
647,14,503,4,0,
647,14,514,1,31,
647,14,514,4,0,
647,14,526,1,61,
647,14,526,4,0,
647,14,533,1,43,
647,14,548,3,0,
647,15,14,1,49,
647,15,14,4,0,
647,15,15,4,0,
647,15,24,1,7,
647,15,36,1,19,
647,15,43,1,1,2
647,15,46,4,0,
647,15,56,1,67,
647,15,57,4,0,
647,15,61,1,13,
647,15,63,4,0,
647,15,70,4,0,
647,15,92,4,0,
647,15,104,4,0,
647,15,115,4,0,
647,15,156,4,0,
647,15,164,4,0,
647,15,182,4,0,
647,15,206,4,0,
647,15,207,4,0,
647,15,214,4,0,
647,15,216,4,0,
647,15,218,4,0,
647,15,219,4,0,
647,15,237,4,0,
647,15,240,4,0,
647,15,244,4,0,
647,15,249,4,0,
647,15,258,4,0,
647,15,263,4,0,
647,15,269,4,0,
647,15,270,1,25,
647,15,332,4,0,
647,15,347,4,0,
647,15,370,1,73,
647,15,398,4,0,
647,15,401,1,37,
647,15,404,4,0,
647,15,411,4,0,
647,15,416,4,0,
647,15,444,4,0,
647,15,453,1,1,1
647,15,496,4,0,
647,15,501,1,55,
647,15,503,4,0,
647,15,514,1,31,
647,15,514,4,0,
647,15,526,1,61,
647,15,533,1,43,
647,15,548,3,0,
647,15,590,4,0,
647,16,14,1,49,1
647,16,14,4,0,
647,16,15,4,0,
647,16,24,1,7,1
647,16,36,1,19,1
647,16,43,1,1,2
647,16,46,4,0,
647,16,56,1,67,1
647,16,57,4,0,
647,16,61,1,13,1
647,16,63,4,0,
647,16,70,4,0,
647,16,92,4,0,
647,16,104,4,0,
647,16,115,4,0,
647,16,156,4,0,
647,16,164,4,0,
647,16,173,3,0,
647,16,182,4,0,
647,16,196,3,0,
647,16,206,4,0,
647,16,207,4,0,
647,16,214,4,0,
647,16,216,4,0,
647,16,218,4,0,
647,16,219,4,0,
647,16,237,4,0,
647,16,240,4,0,
647,16,244,4,0,
647,16,249,4,0,
647,16,258,4,0,
647,16,263,4,0,
647,16,269,4,0,
647,16,270,1,25,1
647,16,270,3,0,
647,16,276,3,0,
647,16,283,3,0,
647,16,290,4,0,
647,16,332,4,0,
647,16,340,3,0,
647,16,343,3,0,
647,16,347,4,0,
647,16,352,3,0,
647,16,370,1,73,1
647,16,387,3,0,
647,16,398,4,0,
647,16,401,1,37,1
647,16,401,3,0,
647,16,404,4,0,
647,16,411,4,0,
647,16,416,4,0,
647,16,444,4,0,
647,16,453,1,1,1
647,16,496,4,0,
647,16,501,1,55,1
647,16,503,4,0,
647,16,514,1,31,1
647,16,514,4,0,
647,16,526,1,61,1
647,16,533,1,43,1
647,16,590,4,0,
647,17,14,1,49,
647,17,14,4,0,
647,17,24,1,1,3
647,17,24,1,7,
647,17,36,1,19,
647,17,43,1,1,2
647,17,46,4,0,
647,17,56,1,67,
647,17,57,4,0,
647,17,61,1,1,4
647,17,61,1,13,
647,17,63,4,0,
647,17,92,4,0,
647,17,104,4,0,
647,17,115,4,0,
647,17,156,4,0,
647,17,164,4,0,
647,17,182,4,0,
647,17,206,4,0,
647,17,207,4,0,
647,17,214,4,0,
647,17,216,4,0,
647,17,218,4,0,
647,17,219,4,0,
647,17,237,4,0,
647,17,240,4,0,
647,17,244,4,0,
647,17,258,4,0,
647,17,263,4,0,
647,17,269,4,0,
647,17,270,1,25,
647,17,332,4,0,
647,17,347,4,0,
647,17,370,1,73,
647,17,398,4,0,
647,17,401,1,37,
647,17,404,4,0,
647,17,411,4,0,
647,17,416,4,0,
647,17,444,4,0,
647,17,453,1,1,1
647,17,496,4,0,
647,17,501,1,55,
647,17,503,4,0,
647,17,514,1,31,
647,17,526,1,61,
647,17,526,4,0,
647,17,533,1,43,
647,17,590,4,0,
647,18,14,1,49,
647,18,14,4,0,
647,18,24,1,1,3
647,18,24,1,7,
647,18,36,1,19,
647,18,43,1,1,2
647,18,46,4,0,
647,18,56,1,67,
647,18,57,4,0,
647,18,61,1,1,4
647,18,61,1,13,
647,18,63,4,0,
647,18,92,4,0,
647,18,104,4,0,
647,18,115,4,0,
647,18,156,4,0,
647,18,164,4,0,
647,18,182,4,0,
647,18,206,4,0,
647,18,207,4,0,
647,18,214,4,0,
647,18,216,4,0,
647,18,218,4,0,
647,18,219,4,0,
647,18,237,4,0,
647,18,240,4,0,
647,18,244,4,0,
647,18,258,4,0,
647,18,263,4,0,
647,18,269,4,0,
647,18,270,1,25,
647,18,332,4,0,
647,18,347,4,0,
647,18,370,1,73,
647,18,398,4,0,
647,18,401,1,37,
647,18,404,4,0,
647,18,411,4,0,
647,18,416,4,0,
647,18,444,4,0,
647,18,453,1,1,1
647,18,496,4,0,
647,18,501,1,55,
647,18,503,4,0,
647,18,514,1,31,
647,18,526,1,61,
647,18,526,4,0,
647,18,533,1,43,
647,18,590,4,0,
648,11,47,1,16,
648,11,60,1,31,
648,11,63,4,0,
648,11,70,4,0,
648,11,85,4,0,
648,11,86,4,0,
648,11,87,4,0,
648,11,92,4,0,
648,11,93,1,11,
648,11,94,1,57,
648,11,94,4,0,
648,11,98,1,6,
648,11,104,4,0,
648,11,113,4,0,
648,11,138,4,0,
648,11,148,4,0,
648,11,156,4,0,
648,11,164,4,0,
648,11,182,4,0,
648,11,195,1,85,
648,11,207,4,0,
648,11,216,4,0,
648,11,218,4,0,
648,11,219,4,0,
648,11,237,4,0,
648,11,240,4,0,
648,11,241,4,0,
648,11,244,4,0,
648,11,247,4,0,
648,11,249,4,0,
648,11,263,4,0,
648,11,272,1,71,
648,11,280,4,0,
648,11,298,1,21,
648,11,304,1,64,
648,11,347,4,0,
648,11,358,1,50,
648,11,369,1,43,
648,11,369,4,0,
648,11,370,1,78,
648,11,371,4,0,
648,11,373,4,0,
648,11,374,4,0,
648,11,411,4,0,
648,11,412,4,0,
648,11,416,4,0,
648,11,421,4,0,
648,11,433,4,0,
648,11,444,4,0,
648,11,447,4,0,
648,11,451,4,0,
648,11,468,4,0,
648,11,473,4,0,
648,11,477,4,0,
648,11,490,4,0,
648,11,496,1,1,
648,11,496,4,0,
648,11,497,1,36,
648,11,497,4,0,
648,11,512,1,26,
648,11,512,4,0,
648,11,514,4,0,
648,11,526,4,0,
648,11,547,3,0,
648,14,7,3,0,
648,14,8,3,0,
648,14,9,3,0,
648,14,47,1,16,
648,14,60,1,31,
648,14,63,4,0,
648,14,67,3,0,
648,14,70,4,0,
648,14,85,4,0,
648,14,86,4,0,
648,14,87,4,0,
648,14,92,4,0,
648,14,93,1,11,
648,14,94,1,57,
648,14,94,4,0,
648,14,98,1,6,
648,14,104,4,0,
648,14,113,4,0,
648,14,138,4,0,
648,14,148,4,0,
648,14,156,4,0,
648,14,164,4,0,
648,14,173,3,0,
648,14,182,4,0,
648,14,195,1,85,
648,14,207,4,0,
648,14,214,3,0,
648,14,215,3,0,
648,14,216,4,0,
648,14,218,4,0,
648,14,219,4,0,
648,14,237,4,0,
648,14,240,4,0,
648,14,241,4,0,
648,14,244,4,0,
648,14,247,4,0,
648,14,249,4,0,
648,14,253,3,0,
648,14,263,4,0,
648,14,270,3,0,
648,14,271,3,0,
648,14,272,1,71,
648,14,272,3,0,
648,14,277,3,0,
648,14,278,3,0,
648,14,280,4,0,
648,14,282,3,0,
648,14,285,3,0,
648,14,289,3,0,
648,14,298,1,21,
648,14,304,1,64,
648,14,304,3,0,
648,14,324,3,0,
648,14,343,3,0,
648,14,347,4,0,
648,14,356,3,0,
648,14,358,1,50,
648,14,369,1,43,
648,14,369,4,0,
648,14,370,1,78,
648,14,371,4,0,
648,14,373,4,0,
648,14,374,4,0,
648,14,387,3,0,
648,14,409,3,0,
648,14,411,4,0,
648,14,412,4,0,
648,14,416,4,0,
648,14,421,4,0,
648,14,428,3,0,
648,14,433,4,0,
648,14,444,4,0,
648,14,447,4,0,
648,14,451,4,0,
648,14,468,4,0,
648,14,472,3,0,
648,14,473,4,0,
648,14,477,4,0,
648,14,478,3,0,
648,14,490,4,0,
648,14,496,1,1,
648,14,496,4,0,
648,14,497,1,36,
648,14,497,4,0,
648,14,512,1,26,
648,14,512,4,0,
648,14,514,4,0,
648,14,526,4,0,
648,14,530,3,0,
648,14,547,3,0,
648,15,47,1,16,
648,15,60,1,31,
648,15,63,4,0,
648,15,70,4,0,
648,15,85,4,0,
648,15,86,4,0,
648,15,87,4,0,
648,15,92,4,0,
648,15,93,1,11,
648,15,94,1,57,
648,15,94,4,0,
648,15,98,1,6,
648,15,104,4,0,
648,15,113,4,0,
648,15,138,4,0,
648,15,148,4,0,
648,15,156,4,0,
648,15,164,4,0,
648,15,182,4,0,
648,15,195,1,85,
648,15,207,4,0,
648,15,214,4,0,
648,15,216,4,0,
648,15,218,4,0,
648,15,219,4,0,
648,15,237,4,0,
648,15,240,4,0,
648,15,241,4,0,
648,15,244,4,0,
648,15,247,4,0,
648,15,249,4,0,
648,15,263,4,0,
648,15,272,1,71,
648,15,280,4,0,
648,15,298,1,21,
648,15,304,1,64,
648,15,347,4,0,
648,15,358,1,50,
648,15,369,1,43,
648,15,369,4,0,
648,15,370,1,78,
648,15,371,4,0,
648,15,373,4,0,
648,15,374,4,0,
648,15,411,4,0,
648,15,412,4,0,
648,15,416,4,0,
648,15,421,4,0,
648,15,433,4,0,
648,15,444,4,0,
648,15,447,4,0,
648,15,451,4,0,
648,15,468,4,0,
648,15,473,4,0,
648,15,490,4,0,
648,15,496,1,1,
648,15,496,4,0,
648,15,497,1,36,
648,15,497,4,0,
648,15,512,1,26,
648,15,512,4,0,
648,15,514,4,0,
648,15,547,3,0,
648,15,590,4,0,
648,15,605,4,0,
648,15,612,4,0,
648,16,7,3,0,
648,16,8,3,0,
648,16,9,3,0,
648,16,47,1,16,1
648,16,60,1,31,1
648,16,63,4,0,
648,16,67,3,0,
648,16,70,4,0,
648,16,85,4,0,
648,16,86,4,0,
648,16,87,4,0,
648,16,92,4,0,
648,16,93,1,11,1
648,16,94,1,57,1
648,16,94,4,0,
648,16,98,1,6,1
648,16,104,4,0,
648,16,113,4,0,
648,16,138,4,0,
648,16,148,4,0,
648,16,156,4,0,
648,16,164,4,0,
648,16,173,3,0,
648,16,182,4,0,
648,16,195,1,85,1
648,16,207,4,0,
648,16,214,4,0,
648,16,215,3,0,
648,16,216,4,0,
648,16,218,4,0,
648,16,219,4,0,
648,16,237,4,0,
648,16,240,4,0,
648,16,241,4,0,
648,16,244,4,0,
648,16,247,4,0,
648,16,249,4,0,
648,16,253,3,0,
648,16,263,4,0,
648,16,264,3,0,
648,16,270,3,0,
648,16,271,3,0,
648,16,272,1,71,1
648,16,272,3,0,
648,16,277,3,0,
648,16,278,3,0,
648,16,280,4,0,
648,16,282,3,0,
648,16,285,3,0,
648,16,289,3,0,
648,16,290,4,0,
648,16,298,1,21,1
648,16,304,1,64,1
648,16,304,3,0,
648,16,324,3,0,
648,16,343,3,0,
648,16,347,4,0,
648,16,351,3,0,
648,16,356,3,0,
648,16,358,1,50,1
648,16,369,1,43,1
648,16,369,4,0,
648,16,370,1,78,1
648,16,371,4,0,
648,16,373,4,0,
648,16,374,4,0,
648,16,387,3,0,
648,16,409,3,0,
648,16,411,4,0,
648,16,412,4,0,
648,16,416,4,0,
648,16,421,4,0,
648,16,428,3,0,
648,16,433,4,0,
648,16,444,4,0,
648,16,447,4,0,
648,16,451,4,0,
648,16,468,4,0,
648,16,472,3,0,
648,16,473,4,0,
648,16,478,3,0,
648,16,490,4,0,
648,16,496,1,1,1
648,16,496,4,0,
648,16,497,1,36,1
648,16,497,4,0,
648,16,512,1,26,1
648,16,512,4,0,
648,16,514,4,0,
648,16,530,3,0,
648,16,590,4,0,
648,16,605,4,0,
648,16,612,4,0,
648,17,47,1,1,4
648,17,47,1,16,
648,17,60,1,31,
648,17,63,4,0,
648,17,85,4,0,
648,17,86,4,0,
648,17,87,4,0,
648,17,92,4,0,
648,17,93,1,1,3
648,17,93,1,11,
648,17,94,1,57,
648,17,94,4,0,
648,17,98,1,1,2
648,17,98,1,6,
648,17,104,4,0,
648,17,113,4,0,
648,17,138,4,0,
648,17,156,4,0,
648,17,164,4,0,
648,17,182,4,0,
648,17,195,1,85,
648,17,207,4,0,
648,17,214,4,0,
648,17,216,4,0,
648,17,218,4,0,
648,17,219,4,0,
648,17,237,4,0,
648,17,240,4,0,
648,17,241,4,0,
648,17,244,4,0,
648,17,247,4,0,
648,17,263,4,0,
648,17,272,1,71,
648,17,280,4,0,
648,17,298,1,21,
648,17,304,1,64,
648,17,347,4,0,
648,17,358,1,50,
648,17,369,1,43,
648,17,369,4,0,
648,17,370,1,78,
648,17,371,4,0,
648,17,373,4,0,
648,17,374,4,0,
648,17,411,4,0,
648,17,412,4,0,
648,17,416,4,0,
648,17,421,4,0,
648,17,433,4,0,
648,17,444,4,0,
648,17,447,4,0,
648,17,451,4,0,
648,17,473,4,0,
648,17,490,4,0,
648,17,496,1,1,1
648,17,496,4,0,
648,17,497,1,36,
648,17,497,4,0,
648,17,512,1,26,
648,17,512,4,0,
648,17,526,4,0,
648,17,590,4,0,
648,17,605,4,0,
648,18,47,1,1,4
648,18,47,1,16,
648,18,60,1,31,
648,18,63,4,0,
648,18,85,4,0,
648,18,86,4,0,
648,18,87,4,0,
648,18,92,4,0,
648,18,93,1,1,3
648,18,93,1,11,
648,18,94,1,57,
648,18,94,4,0,
648,18,98,1,1,2
648,18,98,1,6,
648,18,104,4,0,
648,18,113,4,0,
648,18,138,4,0,
648,18,156,4,0,
648,18,164,4,0,
648,18,182,4,0,
648,18,195,1,85,
648,18,207,4,0,
648,18,214,4,0,
648,18,216,4,0,
648,18,218,4,0,
648,18,219,4,0,
648,18,237,4,0,
648,18,240,4,0,
648,18,241,4,0,
648,18,244,4,0,
648,18,247,4,0,
648,18,263,4,0,
648,18,272,1,71,
648,18,280,4,0,
648,18,298,1,21,
648,18,304,1,64,
648,18,347,4,0,
648,18,358,1,50,
648,18,369,1,43,
648,18,369,4,0,
648,18,370,1,78,
648,18,371,4,0,
648,18,373,4,0,
648,18,374,4,0,
648,18,411,4,0,
648,18,412,4,0,
648,18,416,4,0,
648,18,421,4,0,
648,18,433,4,0,
648,18,444,4,0,
648,18,447,4,0,
648,18,451,4,0,
648,18,473,4,0,
648,18,490,4,0,
648,18,496,1,1,1
648,18,496,4,0,
648,18,497,1,36,
648,18,497,4,0,
648,18,512,1,26,
648,18,512,4,0,
648,18,526,4,0,
648,18,590,4,0,
648,18,605,4,0,
649,11,19,4,0,
649,11,53,4,0,
649,11,58,4,0,
649,11,59,4,0,
649,11,63,1,73,
649,11,63,4,0,
649,11,76,4,0,
649,11,85,4,0,
649,11,86,4,0,
649,11,87,4,0,
649,11,92,4,0,
649,11,94,4,0,
649,11,98,1,1,2
649,11,103,1,1,5
649,11,104,4,0,
649,11,113,4,0,
649,11,115,4,0,
649,11,120,1,77,
649,11,148,4,0,
649,11,153,4,0,
649,11,156,4,0,
649,11,161,1,44,
649,11,163,1,29,
649,11,164,4,0,
649,11,182,4,0,
649,11,192,1,66,
649,11,199,1,11,
649,11,207,4,0,
649,11,210,1,7,
649,11,216,4,0,
649,11,218,4,0,
649,11,232,1,1,4
649,11,237,4,0,
649,11,263,4,0,
649,11,319,1,33,
649,11,324,1,40,
649,11,332,4,0,
649,11,369,4,0,
649,11,393,1,1,3
649,11,397,4,0,
649,11,404,1,51,
649,11,404,4,0,
649,11,405,1,55,
649,11,412,4,0,
649,11,416,4,0,
649,11,421,4,0,
649,11,430,4,0,
649,11,443,1,22,
649,11,451,4,0,
649,11,468,4,0,
649,11,488,1,18,
649,11,488,4,0,
649,11,493,1,62,
649,11,496,4,0,
649,11,522,4,0,
649,11,546,1,1,1
649,14,19,4,0,
649,14,53,4,0,
649,14,58,4,0,
649,14,59,4,0,
649,14,63,1,73,
649,14,63,4,0,
649,14,76,4,0,
649,14,85,4,0,
649,14,86,4,0,
649,14,87,4,0,
649,14,92,4,0,
649,14,94,4,0,
649,14,98,1,1,2
649,14,103,1,1,5
649,14,104,4,0,
649,14,113,4,0,
649,14,115,4,0,
649,14,120,1,77,
649,14,148,4,0,
649,14,153,4,0,
649,14,156,4,0,
649,14,161,1,44,
649,14,163,1,29,
649,14,164,4,0,
649,14,173,3,0,
649,14,182,4,0,
649,14,192,1,66,
649,14,199,1,11,
649,14,202,3,0,
649,14,207,4,0,
649,14,210,1,7,
649,14,214,3,0,
649,14,216,4,0,
649,14,218,4,0,
649,14,232,1,1,4
649,14,237,4,0,
649,14,263,4,0,
649,14,277,3,0,
649,14,278,3,0,
649,14,319,1,33,
649,14,324,1,40,
649,14,324,3,0,
649,14,332,4,0,
649,14,334,3,0,
649,14,356,3,0,
649,14,369,4,0,
649,14,387,3,0,
649,14,393,1,1,3
649,14,393,3,0,
649,14,397,4,0,
649,14,399,3,0,
649,14,404,1,51,
649,14,404,4,0,
649,14,405,1,55,
649,14,412,4,0,
649,14,416,4,0,
649,14,421,4,0,
649,14,428,3,0,
649,14,430,4,0,
649,14,441,3,0,
649,14,442,3,0,
649,14,443,1,22,
649,14,450,3,0,
649,14,451,4,0,
649,14,468,4,0,
649,14,488,1,18,
649,14,488,4,0,
649,14,493,1,62,
649,14,496,4,0,
649,14,522,4,0,
649,14,527,3,0,
649,14,546,1,1,1
649,15,19,4,0,
649,15,53,4,0,
649,15,58,4,0,
649,15,59,4,0,
649,15,63,1,73,
649,15,63,4,0,
649,15,76,4,0,
649,15,85,4,0,
649,15,86,4,0,
649,15,87,4,0,
649,15,92,4,0,
649,15,94,4,0,
649,15,98,1,1,3
649,15,103,1,1,6
649,15,104,4,0,
649,15,113,4,0,
649,15,115,4,0,
649,15,120,1,77,
649,15,148,4,0,
649,15,153,4,0,
649,15,156,4,0,
649,15,161,1,44,
649,15,163,1,29,
649,15,164,4,0,
649,15,182,4,0,
649,15,192,1,66,
649,15,199,1,11,
649,15,207,4,0,
649,15,210,1,7,
649,15,214,4,0,
649,15,216,4,0,
649,15,218,4,0,
649,15,232,1,1,5
649,15,237,4,0,
649,15,263,4,0,
649,15,319,1,33,
649,15,324,1,40,
649,15,332,4,0,
649,15,369,4,0,
649,15,393,1,1,4
649,15,397,4,0,
649,15,399,4,0,
649,15,404,1,51,
649,15,404,4,0,
649,15,405,1,55,
649,15,412,4,0,
649,15,416,4,0,
649,15,421,4,0,
649,15,430,4,0,
649,15,443,1,22,
649,15,451,4,0,
649,15,468,4,0,
649,15,488,1,18,
649,15,488,4,0,
649,15,493,1,62,
649,15,496,4,0,
649,15,522,4,0,
649,15,546,1,1,2
649,15,565,1,1,1
649,15,590,4,0,
649,15,611,4,0,
649,16,19,4,0,
649,16,53,4,0,
649,16,58,4,0,
649,16,59,4,0,
649,16,63,1,73,1
649,16,63,4,0,
649,16,76,4,0,
649,16,85,4,0,
649,16,86,4,0,
649,16,87,4,0,
649,16,92,4,0,
649,16,94,4,0,
649,16,98,1,1,3
649,16,103,1,1,6
649,16,104,4,0,
649,16,113,4,0,
649,16,115,4,0,
649,16,120,1,77,1
649,16,148,4,0,
649,16,153,4,0,
649,16,156,4,0,
649,16,161,1,44,1
649,16,163,1,29,1
649,16,164,4,0,
649,16,173,3,0,
649,16,182,4,0,
649,16,192,1,66,1
649,16,199,1,11,1
649,16,202,3,0,
649,16,207,4,0,
649,16,210,1,7,1
649,16,214,4,0,
649,16,216,4,0,
649,16,218,4,0,
649,16,232,1,1,5
649,16,237,4,0,
649,16,263,4,0,
649,16,277,3,0,
649,16,278,3,0,
649,16,290,4,0,
649,16,319,1,33,1
649,16,324,1,40,1
649,16,324,3,0,
649,16,332,4,0,
649,16,334,3,0,
649,16,351,3,0,
649,16,356,3,0,
649,16,369,4,0,
649,16,387,3,0,
649,16,393,1,1,4
649,16,393,3,0,
649,16,397,4,0,
649,16,399,4,0,
649,16,404,1,51,1
649,16,404,4,0,
649,16,405,1,55,1
649,16,412,4,0,
649,16,416,4,0,
649,16,421,4,0,
649,16,428,3,0,
649,16,430,4,0,
649,16,441,3,0,
649,16,442,3,0,
649,16,443,1,22,1
649,16,450,3,0,
649,16,451,4,0,
649,16,468,4,0,
649,16,488,1,18,1
649,16,488,4,0,
649,16,493,1,62,1
649,16,496,4,0,
649,16,522,4,0,
649,16,527,3,0,
649,16,546,1,1,2
649,16,565,1,1,1
649,16,590,4,0,
649,16,611,4,0,
649,17,19,4,0,
649,17,53,4,0,
649,17,58,4,0,
649,17,59,4,0,
649,17,63,1,73,
649,17,63,4,0,
649,17,76,4,0,
649,17,85,4,0,
649,17,86,4,0,
649,17,87,4,0,
649,17,92,4,0,
649,17,94,4,0,
649,17,98,1,1,3
649,17,103,1,1,6
649,17,104,4,0,
649,17,113,4,0,
649,17,115,4,0,
649,17,120,1,77,
649,17,153,4,0,
649,17,156,4,0,
649,17,161,1,44,
649,17,163,1,29,
649,17,164,4,0,
649,17,182,4,0,
649,17,192,1,66,
649,17,199,1,11,
649,17,207,4,0,
649,17,210,1,7,
649,17,214,4,0,
649,17,216,4,0,
649,17,218,4,0,
649,17,232,1,1,5
649,17,237,4,0,
649,17,263,4,0,
649,17,319,1,33,
649,17,324,1,40,
649,17,332,4,0,
649,17,369,4,0,
649,17,393,1,1,4
649,17,397,4,0,
649,17,399,4,0,
649,17,404,1,51,
649,17,404,4,0,
649,17,405,1,55,
649,17,412,4,0,
649,17,416,4,0,
649,17,421,4,0,
649,17,430,4,0,
649,17,443,1,22,
649,17,451,4,0,
649,17,488,1,18,
649,17,488,4,0,
649,17,493,1,62,
649,17,496,4,0,
649,17,546,1,1,2
649,17,565,1,1,1
649,17,590,4,0,
649,17,611,4,0,
649,18,19,4,0,
649,18,53,4,0,
649,18,58,4,0,
649,18,59,4,0,
649,18,63,1,73,
649,18,63,4,0,
649,18,76,4,0,
649,18,85,4,0,
649,18,86,4,0,
649,18,87,4,0,
649,18,92,4,0,
649,18,94,4,0,
649,18,98,1,1,3
649,18,103,1,1,6
649,18,104,4,0,
649,18,113,4,0,
649,18,115,4,0,
649,18,120,1,77,
649,18,153,4,0,
649,18,156,4,0,
649,18,161,1,44,
649,18,163,1,29,
649,18,164,4,0,
649,18,182,4,0,
649,18,192,1,66,
649,18,199,1,11,
649,18,207,4,0,
649,18,210,1,7,
649,18,214,4,0,
649,18,216,4,0,
649,18,218,4,0,
649,18,232,1,1,5
649,18,237,4,0,
649,18,263,4,0,
649,18,319,1,33,
649,18,324,1,40,
649,18,332,4,0,
649,18,369,4,0,
649,18,393,1,1,4
649,18,397,4,0,
649,18,399,4,0,
649,18,404,1,51,
649,18,404,4,0,
649,18,405,1,55,
649,18,412,4,0,
649,18,416,4,0,
649,18,421,4,0,
649,18,430,4,0,
649,18,443,1,22,
649,18,451,4,0,
649,18,488,1,18,
649,18,488,4,0,
649,18,493,1,62,
649,18,496,4,0,
649,18,546,1,1,2
649,18,565,1,1,1
649,18,590,4,0,
649,18,611,4,0,
650,15,14,4,0,
650,15,15,4,0,
650,15,22,1,5,
650,15,33,1,1,1
650,15,34,1,42,
650,15,36,1,27,
650,15,42,1,18,
650,15,44,1,11,
650,15,45,1,1,2
650,15,46,4,0,
650,15,70,4,0,
650,15,73,1,15,
650,15,76,4,0,
650,15,91,4,0,
650,15,92,4,0,
650,15,104,4,0,
650,15,111,2,0,
650,15,115,4,0,
650,15,148,4,0,
650,15,156,4,0,
650,15,157,4,0,
650,15,164,4,0,
650,15,174,2,0,
650,15,182,4,0,
650,15,187,2,0,
650,15,188,4,0,
650,15,191,2,0,
650,15,205,1,8,
650,15,205,2,0,
650,15,207,4,0,
650,15,213,4,0,
650,15,214,4,0,
650,15,216,4,0,
650,15,218,4,0,
650,15,220,1,45,
650,15,235,2,0,
650,15,237,4,0,
650,15,241,4,0,
650,15,249,4,0,
650,15,263,4,0,
650,15,267,4,0,
650,15,269,4,0,
650,15,280,4,0,
650,15,317,4,0,
650,15,332,4,0,
650,15,339,1,39,
650,15,339,4,0,
650,15,341,1,35,
650,15,360,4,0,
650,15,371,4,0,
650,15,374,4,0,
650,15,398,4,0,
650,15,402,1,32,
650,15,412,4,0,
650,15,421,4,0,
650,15,444,4,0,
650,15,447,4,0,
650,15,452,1,48,
650,15,479,4,0,
650,15,490,4,0,
650,15,496,4,0,
650,15,501,2,0,
650,15,514,4,0,
650,15,520,3,0,
650,15,523,4,0,
650,15,590,4,0,
650,15,612,4,0,
650,16,9,3,0,
650,16,14,4,0,
650,16,15,4,0,
650,16,22,1,5,1
650,16,33,1,1,1
650,16,34,1,42,1
650,16,36,1,27,1
650,16,42,1,18,1
650,16,44,1,11,1
650,16,45,1,1,2
650,16,46,4,0,
650,16,67,3,0,
650,16,70,4,0,
650,16,73,1,15,1
650,16,76,4,0,
650,16,91,4,0,
650,16,92,4,0,
650,16,104,4,0,
650,16,111,2,0,
650,16,115,4,0,
650,16,148,4,0,
650,16,156,4,0,
650,16,157,4,0,
650,16,162,3,0,
650,16,164,4,0,
650,16,173,3,0,
650,16,174,2,0,
650,16,182,4,0,
650,16,187,2,0,
650,16,188,4,0,
650,16,191,2,0,
650,16,202,3,0,
650,16,205,1,8,1
650,16,205,2,0,
650,16,207,4,0,
650,16,213,4,0,
650,16,214,4,0,
650,16,216,4,0,
650,16,218,4,0,
650,16,220,1,45,1
650,16,220,3,0,
650,16,231,3,0,
650,16,235,2,0,
650,16,235,3,0,
650,16,237,4,0,
650,16,241,4,0,
650,16,249,4,0,
650,16,263,4,0,
650,16,264,3,0,
650,16,267,4,0,
650,16,269,4,0,
650,16,270,3,0,
650,16,276,3,0,
650,16,280,4,0,
650,16,283,3,0,
650,16,290,4,0,
650,16,317,4,0,
650,16,332,4,0,
650,16,334,3,0,
650,16,339,1,39,1
650,16,339,4,0,
650,16,341,1,35,1
650,16,360,4,0,
650,16,371,4,0,
650,16,374,4,0,
650,16,388,3,0,
650,16,398,4,0,
650,16,402,1,32,1
650,16,402,3,0,
650,16,409,3,0,
650,16,412,4,0,
650,16,421,4,0,
650,16,428,3,0,
650,16,442,3,0,
650,16,444,4,0,
650,16,447,4,0,
650,16,452,1,48,1
650,16,479,4,0,
650,16,490,4,0,
650,16,496,4,0,
650,16,501,2,0,
650,16,514,4,0,
650,16,520,3,0,
650,16,523,4,0,
650,16,530,3,0,
650,16,590,4,0,
650,16,612,4,0,
650,17,14,4,0,
650,17,22,1,1,2
650,17,22,1,5,
650,17,34,1,42,
650,17,36,1,27,
650,17,42,1,18,
650,17,44,1,11,
650,17,45,1,1,1
650,17,46,4,0,
650,17,73,1,15,
650,17,76,4,0,
650,17,92,4,0,
650,17,104,4,0,
650,17,111,2,0,
650,17,115,4,0,
650,17,156,4,0,
650,17,157,4,0,
650,17,164,4,0,
650,17,174,2,0,
650,17,182,4,0,
650,17,187,2,0,
650,17,188,4,0,
650,17,191,2,0,
650,17,205,1,8,
650,17,205,2,0,
650,17,207,4,0,
650,17,213,4,0,
650,17,214,4,0,
650,17,216,4,0,
650,17,218,4,0,
650,17,220,1,45,
650,17,235,2,0,
650,17,237,4,0,
650,17,241,4,0,
650,17,263,4,0,
650,17,267,4,0,
650,17,269,4,0,
650,17,280,4,0,
650,17,317,4,0,
650,17,332,4,0,
650,17,339,1,39,
650,17,339,4,0,
650,17,341,1,35,
650,17,360,4,0,
650,17,371,4,0,
650,17,374,4,0,
650,17,398,4,0,
650,17,402,1,32,
650,17,412,4,0,
650,17,421,4,0,
650,17,444,4,0,
650,17,447,4,0,
650,17,452,1,48,
650,17,479,4,0,
650,17,490,4,0,
650,17,496,4,0,
650,17,501,2,0,
650,17,520,3,0,
650,17,523,4,0,
650,17,526,4,0,
650,17,590,4,0,
650,18,14,4,0,
650,18,22,1,1,2
650,18,22,1,5,
650,18,34,1,42,
650,18,36,1,27,
650,18,42,1,18,
650,18,44,1,11,
650,18,45,1,1,1
650,18,46,4,0,
650,18,73,1,15,
650,18,76,4,0,
650,18,92,4,0,
650,18,104,4,0,
650,18,111,2,0,
650,18,115,4,0,
650,18,156,4,0,
650,18,157,4,0,
650,18,164,4,0,
650,18,174,2,0,
650,18,182,4,0,
650,18,187,2,0,
650,18,188,4,0,
650,18,191,2,0,
650,18,205,1,8,
650,18,205,2,0,
650,18,207,4,0,
650,18,213,4,0,
650,18,214,4,0,
650,18,216,4,0,
650,18,218,4,0,
650,18,220,1,45,
650,18,235,2,0,
650,18,237,4,0,
650,18,241,4,0,
650,18,263,4,0,
650,18,267,4,0,
650,18,269,4,0,
650,18,280,4,0,
650,18,317,4,0,
650,18,332,4,0,
650,18,339,1,39,
650,18,339,4,0,
650,18,341,1,35,
650,18,360,4,0,
650,18,371,4,0,
650,18,374,4,0,
650,18,398,4,0,
650,18,402,1,32,
650,18,412,4,0,
650,18,421,4,0,
650,18,444,4,0,
650,18,447,4,0,
650,18,452,1,48,
650,18,479,4,0,
650,18,490,4,0,
650,18,496,4,0,
650,18,501,2,0,
650,18,520,3,0,
650,18,523,4,0,
650,18,526,4,0,
650,18,590,4,0,
650,18,612,2,0,
651,15,14,4,0,
651,15,15,4,0,
651,15,22,1,5,
651,15,33,1,1,1
651,15,34,1,48,
651,15,36,1,30,
651,15,42,1,20,
651,15,44,1,11,
651,15,45,1,1,2
651,15,46,4,0,
651,15,70,4,0,
651,15,73,1,15,
651,15,76,4,0,
651,15,91,4,0,
651,15,92,4,0,
651,15,104,4,0,
651,15,115,4,0,
651,15,148,4,0,
651,15,156,4,0,
651,15,157,4,0,
651,15,164,4,0,
651,15,182,4,0,
651,15,188,4,0,
651,15,205,1,8,
651,15,207,4,0,
651,15,213,4,0,
651,15,214,4,0,
651,15,216,4,0,
651,15,218,4,0,
651,15,220,1,52,
651,15,237,4,0,
651,15,241,4,0,
651,15,249,4,0,
651,15,263,4,0,
651,15,267,4,0,
651,15,269,4,0,
651,15,280,4,0,
651,15,302,1,26,
651,15,317,4,0,
651,15,332,4,0,
651,15,339,1,44,
651,15,339,4,0,
651,15,341,1,39,
651,15,360,4,0,
651,15,371,4,0,
651,15,374,4,0,
651,15,398,4,0,
651,15,402,1,35,
651,15,412,4,0,
651,15,421,4,0,
651,15,444,4,0,
651,15,447,4,0,
651,15,452,1,55,
651,15,468,4,0,
651,15,479,4,0,
651,15,490,4,0,
651,15,496,4,0,
651,15,514,4,0,
651,15,520,3,0,
651,15,523,4,0,
651,15,590,4,0,
651,15,612,4,0,
651,16,9,3,0,
651,16,14,4,0,
651,16,15,4,0,
651,16,22,1,5,1
651,16,33,1,1,1
651,16,34,1,48,1
651,16,36,1,30,1
651,16,42,1,20,1
651,16,44,1,11,1
651,16,45,1,1,2
651,16,46,4,0,
651,16,67,3,0,
651,16,70,4,0,
651,16,73,1,15,1
651,16,76,4,0,
651,16,91,4,0,
651,16,92,4,0,
651,16,104,4,0,
651,16,115,4,0,
651,16,148,4,0,
651,16,156,4,0,
651,16,157,4,0,
651,16,162,3,0,
651,16,164,4,0,
651,16,173,3,0,
651,16,182,4,0,
651,16,188,4,0,
651,16,202,3,0,
651,16,205,1,8,1
651,16,207,4,0,
651,16,213,4,0,
651,16,214,4,0,
651,16,216,4,0,
651,16,218,4,0,
651,16,220,1,52,1
651,16,220,3,0,
651,16,231,3,0,
651,16,235,3,0,
651,16,237,4,0,
651,16,241,4,0,
651,16,249,4,0,
651,16,263,4,0,
651,16,264,3,0,
651,16,267,4,0,
651,16,269,4,0,
651,16,270,3,0,
651,16,276,3,0,
651,16,280,4,0,
651,16,283,3,0,
651,16,290,4,0,
651,16,302,1,26,1
651,16,317,4,0,
651,16,332,4,0,
651,16,334,3,0,
651,16,339,1,44,1
651,16,339,4,0,
651,16,341,1,39,1
651,16,360,4,0,
651,16,371,4,0,
651,16,374,4,0,
651,16,388,3,0,
651,16,398,4,0,
651,16,402,1,35,1
651,16,402,3,0,
651,16,409,3,0,
651,16,412,4,0,
651,16,421,4,0,
651,16,428,3,0,
651,16,442,3,0,
651,16,444,4,0,
651,16,447,4,0,
651,16,452,1,55,1
651,16,468,4,0,
651,16,479,4,0,
651,16,490,4,0,
651,16,496,4,0,
651,16,514,4,0,
651,16,520,3,0,
651,16,523,4,0,
651,16,530,3,0,
651,16,590,4,0,
651,16,612,4,0,
651,17,14,4,0,
651,17,22,1,1,3
651,17,22,1,5,
651,17,34,1,48,
651,17,36,1,29,
651,17,42,1,19,
651,17,44,1,11,
651,17,45,1,1,2
651,17,46,4,0,
651,17,73,1,15,
651,17,76,4,0,
651,17,92,4,0,
651,17,104,4,0,
651,17,115,4,0,
651,17,156,4,0,
651,17,157,4,0,
651,17,164,4,0,
651,17,182,4,0,
651,17,188,4,0,
651,17,205,1,8,
651,17,207,4,0,
651,17,213,4,0,
651,17,214,4,0,
651,17,216,4,0,
651,17,218,4,0,
651,17,220,1,52,
651,17,237,4,0,
651,17,241,4,0,
651,17,263,4,0,
651,17,267,4,0,
651,17,269,4,0,
651,17,280,4,0,
651,17,302,1,0,
651,17,302,1,1,1
651,17,317,4,0,
651,17,332,4,0,
651,17,339,1,44,
651,17,339,4,0,
651,17,341,1,39,
651,17,360,4,0,
651,17,371,4,0,
651,17,374,4,0,
651,17,398,4,0,
651,17,402,1,35,
651,17,412,4,0,
651,17,421,4,0,
651,17,444,4,0,
651,17,447,4,0,
651,17,452,1,56,
651,17,479,4,0,
651,17,490,4,0,
651,17,496,4,0,
651,17,520,3,0,
651,17,523,4,0,
651,17,526,4,0,
651,17,590,4,0,
651,18,14,4,0,
651,18,22,1,1,3
651,18,22,1,5,
651,18,34,1,48,
651,18,36,1,29,
651,18,42,1,19,
651,18,44,1,11,
651,18,45,1,1,2
651,18,46,4,0,
651,18,73,1,15,
651,18,76,4,0,
651,18,92,4,0,
651,18,104,4,0,
651,18,115,4,0,
651,18,156,4,0,
651,18,157,4,0,
651,18,164,4,0,
651,18,182,4,0,
651,18,188,4,0,
651,18,205,1,8,
651,18,207,4,0,
651,18,213,4,0,
651,18,214,4,0,
651,18,216,4,0,
651,18,218,4,0,
651,18,220,1,52,
651,18,237,4,0,
651,18,241,4,0,
651,18,263,4,0,
651,18,267,4,0,
651,18,269,4,0,
651,18,280,4,0,
651,18,302,1,0,
651,18,302,1,1,1
651,18,317,4,0,
651,18,332,4,0,
651,18,339,1,44,
651,18,339,4,0,
651,18,341,1,39,
651,18,360,4,0,
651,18,371,4,0,
651,18,374,4,0,
651,18,398,4,0,
651,18,402,1,35,
651,18,412,4,0,
651,18,421,4,0,
651,18,444,4,0,
651,18,447,4,0,
651,18,452,1,56,
651,18,479,4,0,
651,18,490,4,0,
651,18,496,4,0,
651,18,520,3,0,
651,18,523,4,0,
651,18,526,4,0,
651,18,590,4,0,
652,15,14,4,0,
652,15,15,4,0,
652,15,22,1,5,
652,15,33,1,1,4
652,15,34,1,48,
652,15,36,1,30,
652,15,42,1,20,
652,15,44,1,11,
652,15,45,1,1,5
652,15,46,4,0,
652,15,63,4,0,
652,15,70,4,0,
652,15,73,1,15,
652,15,76,4,0,
652,15,89,4,0,
652,15,91,4,0,
652,15,92,4,0,
652,15,104,4,0,
652,15,115,4,0,
652,15,148,4,0,
652,15,156,4,0,
652,15,157,4,0,
652,15,164,4,0,
652,15,182,4,0,
652,15,187,1,1,3
652,15,188,4,0,
652,15,205,1,8,
652,15,207,4,0,
652,15,213,4,0,
652,15,214,4,0,
652,15,216,4,0,
652,15,218,4,0,
652,15,220,1,52,
652,15,237,4,0,
652,15,241,4,0,
652,15,249,4,0,
652,15,263,4,0,
652,15,267,4,0,
652,15,269,4,0,
652,15,280,4,0,
652,15,302,1,26,
652,15,317,4,0,
652,15,332,4,0,
652,15,337,4,0,
652,15,338,3,0,
652,15,339,1,44,
652,15,339,4,0,
652,15,341,1,41,
652,15,359,1,1,2
652,15,359,1,60,
652,15,360,4,0,
652,15,364,1,1,1
652,15,371,4,0,
652,15,374,4,0,
652,15,398,4,0,
652,15,402,1,35,
652,15,411,4,0,
652,15,412,4,0,
652,15,416,1,70,
652,15,416,4,0,
652,15,421,4,0,
652,15,444,4,0,
652,15,447,4,0,
652,15,452,1,55,
652,15,468,4,0,
652,15,479,4,0,
652,15,490,4,0,
652,15,496,4,0,
652,15,514,4,0,
652,15,520,3,0,
652,15,523,4,0,
652,15,590,4,0,
652,15,596,1,36,
652,15,596,1,75,
652,15,612,4,0,
652,16,9,3,0,
652,16,14,4,0,
652,16,15,4,0,
652,16,22,1,5,1
652,16,33,1,1,4
652,16,34,1,48,1
652,16,36,1,30,1
652,16,42,1,20,1
652,16,44,1,11,1
652,16,45,1,1,5
652,16,46,4,0,
652,16,63,4,0,
652,16,67,3,0,
652,16,70,4,0,
652,16,73,1,15,1
652,16,76,4,0,
652,16,89,4,0,
652,16,91,4,0,
652,16,92,4,0,
652,16,104,4,0,
652,16,115,4,0,
652,16,148,4,0,
652,16,156,4,0,
652,16,157,4,0,
652,16,162,3,0,
652,16,164,4,0,
652,16,173,3,0,
652,16,182,4,0,
652,16,187,1,1,3
652,16,188,4,0,
652,16,202,3,0,
652,16,205,1,8,1
652,16,207,4,0,
652,16,213,4,0,
652,16,214,4,0,
652,16,216,4,0,
652,16,218,4,0,
652,16,220,1,52,1
652,16,220,3,0,
652,16,231,3,0,
652,16,235,3,0,
652,16,237,4,0,
652,16,241,4,0,
652,16,249,4,0,
652,16,263,4,0,
652,16,264,3,0,
652,16,267,4,0,
652,16,269,4,0,
652,16,270,3,0,
652,16,276,3,0,
652,16,280,4,0,
652,16,283,3,0,
652,16,290,4,0,
652,16,302,1,26,1
652,16,317,4,0,
652,16,332,4,0,
652,16,334,3,0,
652,16,335,3,0,
652,16,337,4,0,
652,16,338,3,0,
652,16,339,1,44,1
652,16,339,4,0,
652,16,341,1,41,1
652,16,359,1,1,2
652,16,359,1,60,1
652,16,360,4,0,
652,16,364,1,1,1
652,16,371,4,0,
652,16,374,4,0,
652,16,388,3,0,
652,16,398,4,0,
652,16,402,1,35,1
652,16,402,3,0,
652,16,409,3,0,
652,16,411,4,0,
652,16,412,4,0,
652,16,416,1,70,1
652,16,416,4,0,
652,16,421,4,0,
652,16,428,3,0,
652,16,442,3,0,
652,16,444,4,0,
652,16,447,4,0,
652,16,452,1,55,1
652,16,468,4,0,
652,16,479,4,0,
652,16,490,4,0,
652,16,496,4,0,
652,16,514,4,0,
652,16,520,3,0,
652,16,523,4,0,
652,16,530,3,0,
652,16,590,4,0,
652,16,596,1,36,1
652,16,596,1,75,1
652,16,612,4,0,
652,17,14,4,0,
652,17,22,1,1,8
652,17,22,1,5,
652,17,33,1,1,6
652,17,34,1,54,
652,17,36,1,29,
652,17,42,1,19,
652,17,44,1,11,
652,17,45,1,1,7
652,17,46,4,0,
652,17,63,4,0,
652,17,73,1,15,
652,17,76,4,0,
652,17,89,4,0,
652,17,92,4,0,
652,17,104,4,0,
652,17,115,4,0,
652,17,156,4,0,
652,17,157,4,0,
652,17,164,4,0,
652,17,182,4,0,
652,17,187,1,1,5
652,17,188,4,0,
652,17,205,1,1,9
652,17,205,1,8,
652,17,207,4,0,
652,17,213,4,0,
652,17,214,4,0,
652,17,216,4,0,
652,17,218,4,0,
652,17,220,1,60,
652,17,237,4,0,
652,17,241,4,0,
652,17,263,4,0,
652,17,267,4,0,
652,17,269,4,0,
652,17,280,4,0,
652,17,302,1,1,2
652,17,317,4,0,
652,17,332,4,0,
652,17,337,4,0,
652,17,338,3,0,
652,17,339,1,48,
652,17,339,4,0,
652,17,341,1,41,
652,17,359,1,1,3
652,17,359,1,72,
652,17,360,4,0,
652,17,364,1,1,4
652,17,371,4,0,
652,17,374,4,0,
652,17,398,4,0,
652,17,402,1,35,
652,17,411,4,0,
652,17,412,4,0,
652,17,416,1,78,
652,17,416,4,0,
652,17,421,4,0,
652,17,444,4,0,
652,17,447,4,0,
652,17,452,1,66,
652,17,479,4,0,
652,17,490,4,0,
652,17,496,4,0,
652,17,520,3,0,
652,17,523,4,0,
652,17,526,4,0,
652,17,590,4,0,
652,17,596,1,0,
652,17,596,1,1,1
652,18,14,4,0,
652,18,22,1,1,8
652,18,22,1,5,
652,18,33,1,1,6
652,18,34,1,54,
652,18,36,1,29,
652,18,42,1,19,
652,18,44,1,11,
652,18,45,1,1,7
652,18,46,4,0,
652,18,63,4,0,
652,18,73,1,15,
652,18,76,4,0,
652,18,89,4,0,
652,18,92,4,0,
652,18,104,4,0,
652,18,115,4,0,
652,18,156,4,0,
652,18,157,4,0,
652,18,164,4,0,
652,18,182,4,0,
652,18,187,1,1,5
652,18,188,4,0,
652,18,205,1,1,9
652,18,205,1,8,
652,18,207,4,0,
652,18,213,4,0,
652,18,214,4,0,
652,18,216,4,0,
652,18,218,4,0,
652,18,220,1,60,
652,18,237,4,0,
652,18,241,4,0,
652,18,263,4,0,
652,18,267,4,0,
652,18,269,4,0,
652,18,280,4,0,
652,18,302,1,1,2
652,18,317,4,0,
652,18,332,4,0,
652,18,337,4,0,
652,18,338,3,0,
652,18,339,1,48,
652,18,339,4,0,
652,18,341,1,41,
652,18,359,1,1,3
652,18,359,1,72,
652,18,360,4,0,
652,18,364,1,1,4
652,18,371,4,0,
652,18,374,4,0,
652,18,398,4,0,
652,18,402,1,35,
652,18,411,4,0,
652,18,412,4,0,
652,18,416,1,78,
652,18,416,4,0,
652,18,421,4,0,
652,18,444,4,0,
652,18,447,4,0,
652,18,452,1,66,
652,18,479,4,0,
652,18,490,4,0,
652,18,496,4,0,
652,18,520,3,0,
652,18,523,4,0,
652,18,526,4,0,
652,18,590,4,0,
652,18,596,1,0,
652,18,596,1,1,1
653,15,10,1,1,1
653,15,15,4,0,
653,15,39,1,1,2
653,15,52,1,5,
653,15,53,1,35,
653,15,53,4,0,
653,15,60,1,17,
653,15,76,4,0,
653,15,83,1,20,
653,15,92,4,0,
653,15,94,1,41,
653,15,94,4,0,
653,15,95,2,0,
653,15,104,4,0,
653,15,113,1,27,
653,15,113,4,0,
653,15,126,1,48,
653,15,126,4,0,
653,15,138,4,0,
653,15,156,4,0,
653,15,164,4,0,
653,15,168,4,0,
653,15,182,4,0,
653,15,207,4,0,
653,15,213,4,0,
653,15,214,4,0,
653,15,216,4,0,
653,15,218,4,0,
653,15,219,4,0,
653,15,237,4,0,
653,15,240,4,0,
653,15,241,1,43,
653,15,241,4,0,
653,15,244,4,0,
653,15,257,2,0,
653,15,261,1,38,
653,15,261,4,0,
653,15,263,4,0,
653,15,273,2,0,
653,15,277,2,0,
653,15,315,4,0,
653,15,336,1,11,
653,15,373,4,0,
653,15,381,1,25,
653,15,447,4,0,
653,15,473,1,31,
653,15,473,4,0,
653,15,478,1,46,
653,15,488,1,14,
653,15,488,4,0,
653,15,496,4,0,
653,15,497,4,0,
653,15,510,4,0,
653,15,519,3,0,
653,15,590,4,0,
653,15,612,4,0,
653,16,10,1,1,1
653,16,15,4,0,
653,16,39,1,1,2
653,16,52,1,5,1
653,16,53,1,35,1
653,16,53,4,0,
653,16,60,1,17,1
653,16,76,4,0,
653,16,83,1,20,1
653,16,92,4,0,
653,16,94,1,41,1
653,16,94,4,0,
653,16,95,2,0,
653,16,104,4,0,
653,16,113,1,27,1
653,16,113,4,0,
653,16,126,1,48,1
653,16,126,4,0,
653,16,138,4,0,
653,16,156,4,0,
653,16,164,4,0,
653,16,168,4,0,
653,16,173,3,0,
653,16,182,4,0,
653,16,207,4,0,
653,16,213,4,0,
653,16,214,4,0,
653,16,216,4,0,
653,16,218,4,0,
653,16,219,4,0,
653,16,231,3,0,
653,16,237,4,0,
653,16,240,4,0,
653,16,241,1,43,1
653,16,241,4,0,
653,16,244,4,0,
653,16,257,2,0,
653,16,257,3,0,
653,16,261,1,38,1
653,16,261,4,0,
653,16,263,4,0,
653,16,273,2,0,
653,16,277,2,0,
653,16,277,3,0,
653,16,290,4,0,
653,16,315,4,0,
653,16,336,1,11,1
653,16,343,3,0,
653,16,373,4,0,
653,16,381,1,25,1
653,16,447,4,0,
653,16,473,1,31,1
653,16,473,4,0,
653,16,478,1,46,1
653,16,478,3,0,
653,16,488,1,14,1
653,16,488,4,0,
653,16,492,3,0,
653,16,496,4,0,
653,16,497,4,0,
653,16,510,4,0,
653,16,519,3,0,
653,16,590,4,0,
653,16,612,4,0,
653,17,10,1,1,1
653,17,39,1,1,2
653,17,52,1,5,
653,17,53,1,35,
653,17,53,4,0,
653,17,60,1,17,
653,17,76,4,0,
653,17,83,1,20,
653,17,92,4,0,
653,17,94,1,41,
653,17,94,4,0,
653,17,95,2,0,
653,17,104,4,0,
653,17,113,1,27,
653,17,113,4,0,
653,17,126,1,48,
653,17,126,4,0,
653,17,138,4,0,
653,17,156,4,0,
653,17,164,4,0,
653,17,168,4,0,
653,17,182,4,0,
653,17,207,4,0,
653,17,213,4,0,
653,17,214,4,0,
653,17,216,4,0,
653,17,218,4,0,
653,17,219,4,0,
653,17,237,4,0,
653,17,240,4,0,
653,17,241,1,43,
653,17,241,4,0,
653,17,244,4,0,
653,17,257,2,0,
653,17,261,1,38,
653,17,261,4,0,
653,17,263,4,0,
653,17,273,2,0,
653,17,277,2,0,
653,17,315,4,0,
653,17,336,1,11,
653,17,373,4,0,
653,17,381,1,25,
653,17,447,4,0,
653,17,473,1,31,
653,17,473,4,0,
653,17,478,1,46,
653,17,488,1,14,
653,17,488,4,0,
653,17,496,4,0,
653,17,497,4,0,
653,17,519,3,0,
653,17,526,4,0,
653,17,590,4,0,
653,18,10,1,1,1
653,18,39,1,1,2
653,18,52,1,5,
653,18,53,1,35,
653,18,53,4,0,
653,18,60,1,17,
653,18,76,4,0,
653,18,83,1,20,
653,18,92,4,0,
653,18,94,1,41,
653,18,94,4,0,
653,18,95,2,0,
653,18,104,4,0,
653,18,113,1,27,
653,18,113,4,0,
653,18,126,1,48,
653,18,126,4,0,
653,18,138,4,0,
653,18,156,4,0,
653,18,164,4,0,
653,18,168,4,0,
653,18,182,4,0,
653,18,207,4,0,
653,18,213,4,0,
653,18,214,4,0,
653,18,216,4,0,
653,18,218,4,0,
653,18,219,4,0,
653,18,237,4,0,
653,18,240,4,0,
653,18,241,1,43,
653,18,241,4,0,
653,18,244,4,0,
653,18,257,2,0,
653,18,261,1,38,
653,18,261,4,0,
653,18,263,4,0,
653,18,273,2,0,
653,18,277,2,0,
653,18,315,4,0,
653,18,336,1,11,
653,18,373,4,0,
653,18,381,1,25,
653,18,447,4,0,
653,18,473,1,31,
653,18,473,4,0,
653,18,478,1,46,
653,18,488,1,14,
653,18,488,4,0,
653,18,496,4,0,
653,18,497,4,0,
653,18,519,3,0,
653,18,526,4,0,
653,18,590,4,0,
653,18,678,2,0,
654,15,10,1,1,1
654,15,15,4,0,
654,15,39,1,1,2
654,15,52,1,5,
654,15,53,1,41,
654,15,53,4,0,
654,15,60,1,18,
654,15,76,4,0,
654,15,83,1,22,
654,15,92,4,0,
654,15,94,1,48,
654,15,94,4,0,
654,15,104,4,0,
654,15,113,1,30,
654,15,113,4,0,
654,15,126,1,55,
654,15,126,4,0,
654,15,138,4,0,
654,15,156,4,0,
654,15,164,4,0,
654,15,168,4,0,
654,15,182,4,0,
654,15,207,4,0,
654,15,213,4,0,
654,15,214,4,0,
654,15,216,4,0,
654,15,218,4,0,
654,15,219,4,0,
654,15,237,4,0,
654,15,240,4,0,
654,15,241,1,51,
654,15,241,4,0,
654,15,244,4,0,
654,15,261,1,45,
654,15,261,4,0,
654,15,263,4,0,
654,15,315,4,0,
654,15,336,1,11,
654,15,373,4,0,
654,15,381,1,27,
654,15,447,4,0,
654,15,473,1,34,
654,15,473,4,0,
654,15,478,1,53,
654,15,488,1,14,
654,15,488,4,0,
654,15,496,4,0,
654,15,497,4,0,
654,15,510,4,0,
654,15,519,3,0,
654,15,590,4,0,
654,15,612,4,0,
654,16,7,3,0,
654,16,9,3,0,
654,16,10,1,1,1
654,16,15,4,0,
654,16,39,1,1,2
654,16,52,1,5,1
654,16,53,1,41,1
654,16,53,4,0,
654,16,60,1,18,1
654,16,67,3,0,
654,16,76,4,0,
654,16,83,1,22,1
654,16,92,4,0,
654,16,94,1,48,1
654,16,94,4,0,
654,16,104,4,0,
654,16,113,1,30,1
654,16,113,4,0,
654,16,126,1,55,1
654,16,126,4,0,
654,16,138,4,0,
654,16,156,4,0,
654,16,164,4,0,
654,16,168,4,0,
654,16,173,3,0,
654,16,182,4,0,
654,16,207,4,0,
654,16,213,4,0,
654,16,214,4,0,
654,16,216,4,0,
654,16,218,4,0,
654,16,219,4,0,
654,16,231,3,0,
654,16,237,4,0,
654,16,240,4,0,
654,16,241,1,51,1
654,16,241,4,0,
654,16,244,4,0,
654,16,257,3,0,
654,16,261,1,45,1
654,16,261,4,0,
654,16,263,4,0,
654,16,271,3,0,
654,16,277,3,0,
654,16,278,3,0,
654,16,285,3,0,
654,16,289,3,0,
654,16,290,4,0,
654,16,315,4,0,
654,16,336,1,11,1
654,16,343,3,0,
654,16,351,3,0,
654,16,373,4,0,
654,16,381,1,27,1
654,16,428,3,0,
654,16,447,4,0,
654,16,472,3,0,
654,16,473,1,34,1
654,16,473,4,0,
654,16,478,1,53,1
654,16,478,3,0,
654,16,488,1,14,1
654,16,488,4,0,
654,16,492,3,0,
654,16,496,4,0,
654,16,497,4,0,
654,16,510,4,0,
654,16,519,3,0,
654,16,590,4,0,
654,16,612,4,0,
654,17,10,1,1,1
654,17,39,1,1,2
654,17,52,1,1,3
654,17,52,1,5,
654,17,53,1,41,
654,17,53,4,0,
654,17,60,1,18,
654,17,76,4,0,
654,17,83,1,22,
654,17,92,4,0,
654,17,94,1,49,
654,17,94,4,0,
654,17,104,4,0,
654,17,113,1,31,
654,17,113,4,0,
654,17,126,1,59,
654,17,126,4,0,
654,17,138,4,0,
654,17,156,4,0,
654,17,164,4,0,
654,17,168,4,0,
654,17,182,4,0,
654,17,207,4,0,
654,17,213,4,0,
654,17,214,4,0,
654,17,216,4,0,
654,17,218,4,0,
654,17,219,4,0,
654,17,237,4,0,
654,17,240,4,0,
654,17,241,1,52,
654,17,241,4,0,
654,17,244,4,0,
654,17,261,1,45,
654,17,261,4,0,
654,17,263,4,0,
654,17,315,4,0,
654,17,336,1,11,
654,17,373,4,0,
654,17,381,1,28,
654,17,447,4,0,
654,17,473,1,36,
654,17,473,4,0,
654,17,478,1,56,
654,17,488,1,14,
654,17,488,4,0,
654,17,496,4,0,
654,17,497,4,0,
654,17,519,3,0,
654,17,526,4,0,
654,17,590,4,0,
654,18,10,1,1,1
654,18,39,1,1,2
654,18,52,1,1,3
654,18,52,1,5,
654,18,53,1,41,
654,18,53,4,0,
654,18,60,1,18,
654,18,76,4,0,
654,18,83,1,22,
654,18,92,4,0,
654,18,94,1,49,
654,18,94,4,0,
654,18,104,4,0,
654,18,113,1,31,
654,18,113,4,0,
654,18,126,1,59,
654,18,126,4,0,
654,18,138,4,0,
654,18,156,4,0,
654,18,164,4,0,
654,18,168,4,0,
654,18,182,4,0,
654,18,207,4,0,
654,18,213,4,0,
654,18,214,4,0,
654,18,216,4,0,
654,18,218,4,0,
654,18,219,4,0,
654,18,237,4,0,
654,18,240,4,0,
654,18,241,1,52,
654,18,241,4,0,
654,18,244,4,0,
654,18,261,1,45,
654,18,261,4,0,
654,18,263,4,0,
654,18,315,4,0,
654,18,336,1,11,
654,18,373,4,0,
654,18,381,1,28,
654,18,447,4,0,
654,18,473,1,36,
654,18,473,4,0,
654,18,478,1,56,
654,18,488,1,14,
654,18,488,4,0,
654,18,496,4,0,
654,18,497,4,0,
654,18,519,3,0,
654,18,526,4,0,
654,18,590,4,0,
655,15,10,1,1,5
655,15,15,4,0,
655,15,39,1,1,6
655,15,52,1,5,
655,15,53,1,42,
655,15,53,4,0,
655,15,60,1,18,
655,15,63,4,0,
655,15,76,4,0,
655,15,83,1,22,
655,15,92,4,0,
655,15,94,1,51,
655,15,94,4,0,
655,15,104,4,0,
655,15,113,1,30,
655,15,113,4,0,
655,15,126,1,61,
655,15,126,4,0,
655,15,138,4,0,
655,15,156,4,0,
655,15,164,4,0,
655,15,168,4,0,
655,15,182,4,0,
655,15,207,4,0,
655,15,213,4,0,
655,15,214,4,0,
655,15,216,4,0,
655,15,218,4,0,
655,15,219,4,0,
655,15,237,4,0,
655,15,240,4,0,
655,15,241,1,55,
655,15,241,4,0,
655,15,244,4,0,
655,15,247,1,1,4
655,15,247,4,0,
655,15,248,1,1,1
655,15,248,1,69,
655,15,261,1,47,
655,15,261,4,0,
655,15,263,4,0,
655,15,272,1,1,2
655,15,307,3,0,
655,15,315,4,0,
655,15,336,1,11,
655,15,347,4,0,
655,15,373,4,0,
655,15,381,1,27,
655,15,415,1,1,3
655,15,416,4,0,
655,15,433,4,0,
655,15,447,4,0,
655,15,473,1,34,
655,15,473,4,0,
655,15,478,1,58,
655,15,488,1,14,
655,15,488,4,0,
655,15,496,4,0,
655,15,497,4,0,
655,15,510,4,0,
655,15,519,3,0,
655,15,590,4,0,
655,15,595,1,36,
655,15,595,1,75,
655,15,612,4,0,
655,16,7,3,0,
655,16,9,3,0,
655,16,10,1,1,5
655,16,15,4,0,
655,16,39,1,1,6
655,16,52,1,5,1
655,16,53,1,42,1
655,16,53,4,0,
655,16,60,1,18,1
655,16,63,4,0,
655,16,67,3,0,
655,16,76,4,0,
655,16,83,1,22,1
655,16,92,4,0,
655,16,94,1,51,1
655,16,94,4,0,
655,16,104,4,0,
655,16,113,1,30,1
655,16,113,4,0,
655,16,126,1,61,1
655,16,126,4,0,
655,16,138,4,0,
655,16,156,4,0,
655,16,164,4,0,
655,16,168,4,0,
655,16,173,3,0,
655,16,182,4,0,
655,16,207,4,0,
655,16,213,4,0,
655,16,214,4,0,
655,16,216,4,0,
655,16,218,4,0,
655,16,219,4,0,
655,16,231,3,0,
655,16,237,4,0,
655,16,240,4,0,
655,16,241,1,55,1
655,16,241,4,0,
655,16,244,4,0,
655,16,247,1,1,4
655,16,247,4,0,
655,16,248,1,1,1
655,16,248,1,69,1
655,16,257,3,0,
655,16,261,1,47,1
655,16,261,4,0,
655,16,263,4,0,
655,16,271,3,0,
655,16,272,1,1,2
655,16,272,3,0,
655,16,277,3,0,
655,16,278,3,0,
655,16,285,3,0,
655,16,289,3,0,
655,16,290,4,0,
655,16,307,3,0,
655,16,315,4,0,
655,16,324,3,0,
655,16,336,1,11,1
655,16,343,3,0,
655,16,347,4,0,
655,16,351,3,0,
655,16,373,4,0,
655,16,381,1,27,1
655,16,415,1,1,3
655,16,416,4,0,
655,16,428,3,0,
655,16,433,4,0,
655,16,447,4,0,
655,16,472,3,0,
655,16,473,1,34,1
655,16,473,4,0,
655,16,478,1,58,1
655,16,478,3,0,
655,16,488,1,14,1
655,16,488,4,0,
655,16,492,3,0,
655,16,496,4,0,
655,16,497,4,0,
655,16,510,4,0,
655,16,519,3,0,
655,16,590,4,0,
655,16,595,1,36,1
655,16,595,1,75,1
655,16,605,4,0,
655,16,612,4,0,
655,17,10,1,1,6
655,17,39,1,1,7
655,17,52,1,1,8
655,17,52,1,5,
655,17,53,1,45,
655,17,53,4,0,
655,17,60,1,18,
655,17,63,4,0,
655,17,76,4,0,
655,17,83,1,22,
655,17,92,4,0,
655,17,94,1,57,
655,17,94,4,0,
655,17,104,4,0,
655,17,113,1,31,
655,17,113,4,0,
655,17,126,1,74,
655,17,126,4,0,
655,17,138,4,0,
655,17,156,4,0,
655,17,164,4,0,
655,17,168,4,0,
655,17,182,4,0,
655,17,207,4,0,
655,17,213,4,0,
655,17,214,4,0,
655,17,216,4,0,
655,17,218,4,0,
655,17,219,4,0,
655,17,237,4,0,
655,17,240,4,0,
655,17,241,1,62,
655,17,241,4,0,
655,17,244,4,0,
655,17,247,1,1,5
655,17,247,4,0,
655,17,248,1,1,2
655,17,248,1,80,
655,17,261,1,51,
655,17,261,4,0,
655,17,263,4,0,
655,17,272,1,1,3
655,17,307,3,0,
655,17,315,4,0,
655,17,336,1,1,9
655,17,336,1,11,
655,17,347,4,0,
655,17,373,4,0,
655,17,381,1,28,
655,17,415,1,1,4
655,17,416,4,0,
655,17,433,4,0,
655,17,447,4,0,
655,17,473,1,38,
655,17,473,4,0,
655,17,478,1,68,
655,17,488,1,14,
655,17,488,4,0,
655,17,496,4,0,
655,17,497,4,0,
655,17,519,3,0,
655,17,526,4,0,
655,17,590,4,0,
655,17,595,1,0,
655,17,595,1,1,1
655,17,605,4,0,
655,18,10,1,1,6
655,18,39,1,1,7
655,18,52,1,1,8
655,18,52,1,5,
655,18,53,1,45,
655,18,53,4,0,
655,18,60,1,18,
655,18,63,4,0,
655,18,76,4,0,
655,18,83,1,22,
655,18,92,4,0,
655,18,94,1,57,
655,18,94,4,0,
655,18,104,4,0,
655,18,113,1,31,
655,18,113,4,0,
655,18,126,1,74,
655,18,126,4,0,
655,18,138,4,0,
655,18,156,4,0,
655,18,164,4,0,
655,18,168,4,0,
655,18,182,4,0,
655,18,207,4,0,
655,18,213,4,0,
655,18,214,4,0,
655,18,216,4,0,
655,18,218,4,0,
655,18,219,4,0,
655,18,237,4,0,
655,18,240,4,0,
655,18,241,1,62,
655,18,241,4,0,
655,18,244,4,0,
655,18,247,1,1,5
655,18,247,4,0,
655,18,248,1,1,2
655,18,248,1,80,
655,18,261,1,51,
655,18,261,4,0,
655,18,263,4,0,
655,18,272,1,1,3
655,18,307,3,0,
655,18,315,4,0,
655,18,336,1,1,9
655,18,336,1,11,
655,18,347,4,0,
655,18,373,4,0,
655,18,381,1,28,
655,18,415,1,1,4
655,18,416,4,0,
655,18,433,4,0,
655,18,447,4,0,
655,18,473,1,38,
655,18,473,4,0,
655,18,478,1,68,
655,18,488,1,14,
655,18,488,4,0,
655,18,496,4,0,
655,18,497,4,0,
655,18,519,3,0,
655,18,526,4,0,
655,18,590,4,0,
655,18,595,1,0,
655,18,595,1,1,1
655,18,605,4,0,
656,15,1,1,1,1
656,15,15,4,0,
656,15,45,1,1,2
656,15,56,1,48,
656,15,57,4,0,
656,15,58,4,0,
656,15,59,4,0,
656,15,70,4,0,
656,15,91,4,0,
656,15,92,4,0,
656,15,98,1,8,
656,15,104,1,43,
656,15,104,4,0,
656,15,108,1,18,
656,15,122,1,10,
656,15,127,4,0,
656,15,145,1,5,
656,15,156,4,0,
656,15,157,4,0,
656,15,164,1,35,
656,15,164,4,0,
656,15,168,4,0,
656,15,170,2,0,
656,15,182,4,0,
656,15,207,4,0,
656,15,213,4,0,
656,15,214,4,0,
656,15,216,4,0,
656,15,218,4,0,
656,15,237,4,0,
656,15,240,4,0,
656,15,249,4,0,
656,15,263,4,0,
656,15,269,4,0,
656,15,293,2,0,
656,15,300,2,0,
656,15,317,4,0,
656,15,332,4,0,
656,15,340,1,39,
656,15,346,2,0,
656,15,352,1,14,
656,15,369,4,0,
656,15,374,1,25,
656,15,374,4,0,
656,15,390,2,0,
656,15,447,4,0,
656,15,479,1,29,
656,15,479,4,0,
656,15,496,1,21,
656,15,496,4,0,
656,15,497,4,0,
656,15,503,4,0,
656,15,512,4,0,
656,15,516,2,0,
656,15,518,3,0,
656,15,590,4,0,
656,15,612,4,0,
656,16,1,1,1,1
656,16,15,4,0,
656,16,45,1,1,2
656,16,56,1,48,1
656,16,57,4,0,
656,16,58,4,0,
656,16,59,4,0,
656,16,70,4,0,
656,16,91,4,0,
656,16,92,4,0,
656,16,98,1,8,1
656,16,104,1,43,1
656,16,104,4,0,
656,16,108,1,18,1
656,16,122,1,10,1
656,16,127,4,0,
656,16,145,1,5,1
656,16,156,4,0,
656,16,157,4,0,
656,16,164,1,35,1
656,16,164,4,0,
656,16,168,4,0,
656,16,170,2,0,
656,16,173,3,0,
656,16,180,3,0,
656,16,182,4,0,
656,16,196,3,0,
656,16,207,4,0,
656,16,213,4,0,
656,16,214,4,0,
656,16,216,4,0,
656,16,218,4,0,
656,16,237,4,0,
656,16,240,4,0,
656,16,249,4,0,
656,16,263,4,0,
656,16,269,4,0,
656,16,272,3,0,
656,16,289,3,0,
656,16,290,4,0,
656,16,291,4,0,
656,16,293,2,0,
656,16,300,2,0,
656,16,317,4,0,
656,16,332,4,0,
656,16,340,1,39,1
656,16,340,3,0,
656,16,346,2,0,
656,16,352,1,14,1
656,16,352,3,0,
656,16,369,4,0,
656,16,374,1,25,1
656,16,374,4,0,
656,16,390,2,0,
656,16,447,4,0,
656,16,479,1,29,1
656,16,479,4,0,
656,16,496,1,21,1
656,16,496,4,0,
656,16,497,4,0,
656,16,503,4,0,
656,16,512,4,0,
656,16,516,2,0,
656,16,518,3,0,
656,16,590,4,0,
656,16,612,4,0,
656,17,1,1,1,1
656,17,45,1,1,2
656,17,56,1,48,
656,17,57,4,0,
656,17,58,4,0,
656,17,59,4,0,
656,17,92,4,0,
656,17,98,1,8,
656,17,104,1,43,
656,17,104,4,0,
656,17,108,1,18,
656,17,122,1,10,
656,17,127,4,0,
656,17,145,1,5,
656,17,156,4,0,
656,17,157,4,0,
656,17,164,1,35,
656,17,164,4,0,
656,17,168,4,0,
656,17,170,2,0,
656,17,182,4,0,
656,17,207,4,0,
656,17,213,4,0,
656,17,214,4,0,
656,17,216,4,0,
656,17,218,4,0,
656,17,237,4,0,
656,17,240,4,0,
656,17,263,4,0,
656,17,269,4,0,
656,17,293,2,0,
656,17,300,2,0,
656,17,317,4,0,
656,17,332,4,0,
656,17,340,1,39,
656,17,346,2,0,
656,17,352,1,14,
656,17,369,4,0,
656,17,374,1,25,
656,17,374,4,0,
656,17,390,2,0,
656,17,447,4,0,
656,17,479,1,29,
656,17,479,4,0,
656,17,496,1,21,
656,17,496,4,0,
656,17,497,4,0,
656,17,503,4,0,
656,17,512,4,0,
656,17,516,2,0,
656,17,518,3,0,
656,17,526,4,0,
656,17,590,4,0,
656,18,1,1,1,1
656,18,45,1,1,2
656,18,56,1,48,
656,18,57,4,0,
656,18,58,4,0,
656,18,59,4,0,
656,18,92,4,0,
656,18,98,1,8,
656,18,104,1,43,
656,18,104,4,0,
656,18,108,1,18,
656,18,122,1,10,
656,18,127,4,0,
656,18,145,1,5,
656,18,156,4,0,
656,18,157,4,0,
656,18,164,1,35,
656,18,164,4,0,
656,18,168,4,0,
656,18,170,2,0,
656,18,182,4,0,
656,18,207,4,0,
656,18,213,4,0,
656,18,214,4,0,
656,18,216,4,0,
656,18,218,4,0,
656,18,237,4,0,
656,18,240,4,0,
656,18,263,4,0,
656,18,269,4,0,
656,18,293,2,0,
656,18,300,2,0,
656,18,317,4,0,
656,18,332,4,0,
656,18,340,1,39,
656,18,346,2,0,
656,18,352,1,14,
656,18,369,4,0,
656,18,374,1,25,
656,18,374,4,0,
656,18,390,2,0,
656,18,447,4,0,
656,18,479,1,29,
656,18,479,4,0,
656,18,496,1,21,
656,18,496,4,0,
656,18,497,4,0,
656,18,503,4,0,
656,18,512,4,0,
656,18,516,2,0,
656,18,518,3,0,
656,18,526,4,0,
656,18,590,4,0,
656,18,612,2,0,
657,15,1,1,1,1
657,15,15,4,0,
657,15,45,1,1,2
657,15,56,1,55,
657,15,57,4,0,
657,15,58,4,0,
657,15,59,4,0,
657,15,70,4,0,
657,15,91,4,0,
657,15,92,4,0,
657,15,98,1,8,
657,15,104,1,48,
657,15,104,4,0,
657,15,108,1,20,
657,15,122,1,10,
657,15,127,4,0,
657,15,145,1,5,
657,15,156,4,0,
657,15,157,4,0,
657,15,164,1,38,
657,15,164,4,0,
657,15,168,4,0,
657,15,182,4,0,
657,15,207,4,0,
657,15,213,4,0,
657,15,214,4,0,
657,15,216,4,0,
657,15,218,4,0,
657,15,237,4,0,
657,15,240,4,0,
657,15,249,4,0,
657,15,263,4,0,
657,15,269,4,0,
657,15,317,4,0,
657,15,332,4,0,
657,15,340,1,44,
657,15,352,1,14,
657,15,369,4,0,
657,15,374,1,28,
657,15,374,4,0,
657,15,399,4,0,
657,15,447,4,0,
657,15,479,1,33,
657,15,479,4,0,
657,15,496,1,23,
657,15,496,4,0,
657,15,497,4,0,
657,15,503,4,0,
657,15,512,4,0,
657,15,518,3,0,
657,15,590,4,0,
657,15,612,4,0,
657,16,1,1,1,1
657,16,8,3,0,
657,16,15,4,0,
657,16,45,1,1,2
657,16,56,1,55,1
657,16,57,4,0,
657,16,58,4,0,
657,16,59,4,0,
657,16,67,3,0,
657,16,70,4,0,
657,16,91,4,0,
657,16,92,4,0,
657,16,98,1,8,1
657,16,104,1,48,1
657,16,104,4,0,
657,16,108,1,20,1
657,16,122,1,10,1
657,16,127,4,0,
657,16,145,1,5,1
657,16,156,4,0,
657,16,157,4,0,
657,16,164,1,38,1
657,16,164,4,0,
657,16,168,4,0,
657,16,173,3,0,
657,16,180,3,0,
657,16,182,4,0,
657,16,196,3,0,
657,16,207,4,0,
657,16,213,4,0,
657,16,214,4,0,
657,16,216,4,0,
657,16,218,4,0,
657,16,237,4,0,
657,16,240,4,0,
657,16,249,4,0,
657,16,263,4,0,
657,16,269,4,0,
657,16,272,3,0,
657,16,289,3,0,
657,16,290,4,0,
657,16,291,4,0,
657,16,317,4,0,
657,16,332,4,0,
657,16,340,1,44,1
657,16,340,3,0,
657,16,352,1,14,1
657,16,352,3,0,
657,16,369,4,0,
657,16,374,1,28,1
657,16,374,4,0,
657,16,399,4,0,
657,16,441,3,0,
657,16,447,4,0,
657,16,479,1,33,1
657,16,479,4,0,
657,16,496,1,23,1
657,16,496,4,0,
657,16,497,4,0,
657,16,503,4,0,
657,16,512,4,0,
657,16,518,3,0,
657,16,590,4,0,
657,16,612,4,0,
657,17,1,1,1,1
657,17,45,1,1,2
657,17,56,1,56,
657,17,57,4,0,
657,17,58,4,0,
657,17,59,4,0,
657,17,92,4,0,
657,17,98,1,8,
657,17,104,1,50,
657,17,104,4,0,
657,17,108,1,19,
657,17,122,1,10,
657,17,127,4,0,
657,17,145,1,1,3
657,17,145,1,5,
657,17,156,4,0,
657,17,157,4,0,
657,17,164,1,40,
657,17,164,4,0,
657,17,168,4,0,
657,17,182,4,0,
657,17,207,4,0,
657,17,213,4,0,
657,17,214,4,0,
657,17,216,4,0,
657,17,218,4,0,
657,17,237,4,0,
657,17,240,4,0,
657,17,263,4,0,
657,17,269,4,0,
657,17,317,4,0,
657,17,332,4,0,
657,17,340,1,45,
657,17,352,1,14,
657,17,369,4,0,
657,17,374,1,28,
657,17,374,4,0,
657,17,399,4,0,
657,17,447,4,0,
657,17,479,1,33,
657,17,479,4,0,
657,17,496,1,23,
657,17,496,4,0,
657,17,497,4,0,
657,17,503,4,0,
657,17,512,4,0,
657,17,518,3,0,
657,17,526,4,0,
657,17,590,4,0,
657,18,1,1,1,1
657,18,45,1,1,2
657,18,56,1,56,
657,18,57,4,0,
657,18,58,4,0,
657,18,59,4,0,
657,18,92,4,0,
657,18,98,1,8,
657,18,104,1,50,
657,18,104,4,0,
657,18,108,1,19,
657,18,122,1,10,
657,18,127,4,0,
657,18,145,1,1,3
657,18,145,1,5,
657,18,156,4,0,
657,18,157,4,0,
657,18,164,1,40,
657,18,164,4,0,
657,18,168,4,0,
657,18,182,4,0,
657,18,207,4,0,
657,18,213,4,0,
657,18,214,4,0,
657,18,216,4,0,
657,18,218,4,0,
657,18,237,4,0,
657,18,240,4,0,
657,18,263,4,0,
657,18,269,4,0,
657,18,317,4,0,
657,18,332,4,0,
657,18,340,1,45,
657,18,352,1,14,
657,18,369,4,0,
657,18,374,1,28,
657,18,374,4,0,
657,18,399,4,0,
657,18,447,4,0,
657,18,479,1,33,
657,18,479,4,0,
657,18,496,1,23,
657,18,496,4,0,
657,18,497,4,0,
657,18,503,4,0,
657,18,512,4,0,
657,18,518,3,0,
657,18,526,4,0,
657,18,590,4,0,
658,15,1,1,1,4
658,15,15,4,0,
658,15,45,1,1,5
658,15,56,1,60,
658,15,57,4,0,
658,15,58,4,0,
658,15,59,4,0,
658,15,63,4,0,
658,15,70,4,0,
658,15,91,4,0,
658,15,92,4,0,
658,15,98,1,8,
658,15,104,1,52,
658,15,104,4,0,
658,15,108,1,20,
658,15,114,1,56,
658,15,122,1,10,
658,15,127,4,0,
658,15,145,1,5,
658,15,156,4,0,
658,15,157,4,0,
658,15,164,1,43,
658,15,164,4,0,
658,15,168,4,0,
658,15,182,4,0,
658,15,185,1,33,
658,15,191,1,28,
658,15,207,4,0,
658,15,213,4,0,
658,15,214,4,0,
658,15,216,4,0,
658,15,218,4,0,
658,15,237,4,0,
658,15,240,4,0,
658,15,249,4,0,
658,15,263,4,0,
658,15,269,4,0,
658,15,272,1,1,2
658,15,308,3,0,
658,15,317,4,0,
658,15,326,1,49,
658,15,332,4,0,
658,15,352,1,14,
658,15,369,4,0,
658,15,374,4,0,
658,15,399,4,0,
658,15,400,1,1,1
658,15,400,1,70,
658,15,416,4,0,
658,15,425,1,23,
658,15,447,4,0,
658,15,479,4,0,
658,15,496,4,0,
658,15,497,4,0,
658,15,503,4,0,
658,15,512,4,0,
658,15,518,3,0,
658,15,561,1,1,3
658,15,590,4,0,
658,15,594,1,36,
658,15,594,1,75,
658,15,612,4,0,
658,16,1,1,1,4
658,16,8,3,0,
658,16,15,4,0,
658,16,45,1,1,5
658,16,56,1,60,1
658,16,57,4,0,
658,16,58,4,0,
658,16,59,4,0,
658,16,63,4,0,
658,16,67,3,0,
658,16,70,4,0,
658,16,91,4,0,
658,16,92,4,0,
658,16,98,1,8,1
658,16,104,1,52,1
658,16,104,4,0,
658,16,108,1,20,1
658,16,114,1,56,1
658,16,122,1,10,1
658,16,127,4,0,
658,16,145,1,5,1
658,16,156,4,0,
658,16,157,4,0,
658,16,164,1,43,1
658,16,164,4,0,
658,16,168,4,0,
658,16,173,3,0,
658,16,180,3,0,
658,16,182,4,0,
658,16,185,1,33,1
658,16,191,1,28,1
658,16,196,3,0,
658,16,207,4,0,
658,16,213,4,0,
658,16,214,4,0,
658,16,216,4,0,
658,16,218,4,0,
658,16,237,4,0,
658,16,240,4,0,
658,16,249,4,0,
658,16,263,4,0,
658,16,269,4,0,
658,16,272,1,1,2
658,16,272,3,0,
658,16,289,3,0,
658,16,290,4,0,
658,16,291,4,0,
658,16,308,3,0,
658,16,317,4,0,
658,16,326,1,49,1
658,16,332,4,0,
658,16,340,3,0,
658,16,352,1,14,1
658,16,352,3,0,
658,16,369,4,0,
658,16,374,4,0,
658,16,399,4,0,
658,16,400,1,1,1
658,16,400,1,70,1
658,16,416,4,0,
658,16,425,1,23,1
658,16,441,3,0,
658,16,447,4,0,
658,16,479,4,0,
658,16,496,4,0,
658,16,497,4,0,
658,16,503,4,0,
658,16,512,4,0,
658,16,518,3,0,
658,16,561,1,1,3
658,16,590,4,0,
658,16,594,1,36,1
658,16,594,1,75,1
658,16,612,4,0,
658,17,1,1,1,6
658,17,45,1,1,7
658,17,56,1,68,
658,17,57,4,0,
658,17,58,4,0,
658,17,59,4,0,
658,17,63,4,0,
658,17,92,4,0,
658,17,98,1,1,9
658,17,98,1,8,
658,17,104,1,56,
658,17,104,4,0,
658,17,108,1,19,
658,17,114,1,1,3
658,17,114,1,70,
658,17,122,1,10,
658,17,127,4,0,
658,17,145,1,1,8
658,17,145,1,5,
658,17,156,4,0,
658,17,157,4,0,
658,17,164,1,42,
658,17,164,4,0,
658,17,168,4,0,
658,17,182,4,0,
658,17,185,1,33,
658,17,191,1,28,
658,17,207,4,0,
658,17,213,4,0,
658,17,214,4,0,
658,17,216,4,0,
658,17,218,4,0,
658,17,237,4,0,
658,17,240,4,0,
658,17,263,4,0,
658,17,269,4,0,
658,17,272,1,1,4
658,17,308,3,0,
658,17,317,4,0,
658,17,326,1,49,
658,17,332,4,0,
658,17,352,1,14,
658,17,369,4,0,
658,17,374,4,0,
658,17,399,4,0,
658,17,400,1,1,2
658,17,400,1,77,
658,17,416,4,0,
658,17,425,1,23,
658,17,447,4,0,
658,17,479,4,0,
658,17,496,4,0,
658,17,497,4,0,
658,17,503,4,0,
658,17,512,4,0,
658,17,518,3,0,
658,17,526,4,0,
658,17,561,1,1,5
658,17,590,4,0,
658,17,594,1,0,
658,17,594,1,1,1
658,17,693,4,0,
658,18,1,1,1,6
658,18,45,1,1,7
658,18,56,1,68,
658,18,57,4,0,
658,18,58,4,0,
658,18,59,4,0,
658,18,63,4,0,
658,18,92,4,0,
658,18,98,1,1,9
658,18,98,1,8,
658,18,104,1,56,
658,18,104,4,0,
658,18,108,1,19,
658,18,114,1,1,3
658,18,114,1,70,
658,18,122,1,10,
658,18,127,4,0,
658,18,145,1,1,8
658,18,145,1,5,
658,18,156,4,0,
658,18,157,4,0,
658,18,164,1,42,
658,18,164,4,0,
658,18,168,4,0,
658,18,182,4,0,
658,18,185,1,33,
658,18,191,1,28,
658,18,207,4,0,
658,18,213,4,0,
658,18,214,4,0,
658,18,216,4,0,
658,18,218,4,0,
658,18,237,4,0,
658,18,240,4,0,
658,18,263,4,0,
658,18,269,4,0,
658,18,272,1,1,4
658,18,308,3,0,
658,18,317,4,0,
658,18,326,1,49,
658,18,332,4,0,
658,18,352,1,14,
658,18,369,4,0,
658,18,374,4,0,
658,18,399,4,0,
658,18,400,1,1,2
658,18,400,1,77,
658,18,416,4,0,
658,18,425,1,23,
658,18,447,4,0,
658,18,479,4,0,
658,18,496,4,0,
658,18,497,4,0,
658,18,503,4,0,
658,18,512,4,0,
658,18,518,3,0,
658,18,526,4,0,
658,18,561,1,1,5
658,18,590,4,0,
658,18,594,1,0,
658,18,594,1,1,1
658,18,693,4,0,
659,15,3,1,10,
659,15,15,4,0,
659,15,24,1,20,
659,15,33,1,1,1
659,15,36,1,15,
659,15,43,1,1,3
659,15,57,4,0,
659,15,70,4,0,
659,15,89,1,49,
659,15,89,4,0,
659,15,91,1,33,
659,15,91,4,0,
659,15,92,4,0,
659,15,97,1,1,2
659,15,98,1,7,
659,15,104,4,0,
659,15,111,2,0,
659,15,156,4,0,
659,15,157,4,0,
659,15,162,1,42,
659,15,164,4,0,
659,15,168,4,0,
659,15,175,1,29,
659,15,182,4,0,
659,15,188,4,0,
659,15,189,1,13,
659,15,191,2,0,
659,15,201,4,0,
659,15,205,2,0,
659,15,207,4,0,
659,15,213,4,0,
659,15,214,4,0,
659,15,216,4,0,
659,15,218,4,0,
659,15,237,4,0,
659,15,249,4,0,
659,15,259,4,0,
659,15,263,1,47,
659,15,263,4,0,
659,15,267,4,0,
659,15,280,4,0,
659,15,316,1,25,
659,15,317,4,0,
659,15,339,4,0,
659,15,340,1,38,
659,15,341,1,18,
659,15,369,4,0,
659,15,371,4,0,
659,15,374,4,0,
659,15,444,4,0,
659,15,447,4,0,
659,15,479,4,0,
659,15,496,4,0,
659,15,523,4,0,
659,15,528,4,0,
659,15,590,4,0,
659,15,612,4,0,
659,16,3,1,10,1
659,16,15,4,0,
659,16,24,1,20,1
659,16,33,1,1,1
659,16,36,1,15,1
659,16,43,1,1,3
659,16,57,4,0,
659,16,70,4,0,
659,16,89,1,49,1
659,16,89,4,0,
659,16,91,1,33,1
659,16,91,4,0,
659,16,92,4,0,
659,16,97,1,1,2
659,16,98,1,7,1
659,16,104,4,0,
659,16,111,2,0,
659,16,156,4,0,
659,16,157,4,0,
659,16,162,1,42,1
659,16,162,3,0,
659,16,164,4,0,
659,16,168,4,0,
659,16,173,3,0,
659,16,175,1,29,1
659,16,182,4,0,
659,16,188,4,0,
659,16,189,1,13,1
659,16,191,2,0,
659,16,201,4,0,
659,16,205,2,0,
659,16,207,4,0,
659,16,213,4,0,
659,16,214,4,0,
659,16,216,4,0,
659,16,218,4,0,
659,16,231,3,0,
659,16,237,4,0,
659,16,249,4,0,
659,16,259,4,0,
659,16,263,1,47,1
659,16,263,4,0,
659,16,267,4,0,
659,16,278,3,0,
659,16,280,4,0,
659,16,283,3,0,
659,16,290,4,0,
659,16,316,1,25,1
659,16,317,4,0,
659,16,339,4,0,
659,16,340,1,38,1
659,16,340,3,0,
659,16,341,1,18,1
659,16,369,4,0,
659,16,371,4,0,
659,16,374,4,0,
659,16,387,3,0,
659,16,442,3,0,
659,16,444,4,0,
659,16,447,4,0,
659,16,479,4,0,
659,16,496,4,0,
659,16,523,4,0,
659,16,528,4,0,
659,16,590,4,0,
659,16,612,4,0,
659,17,3,1,10,
659,17,24,1,20,
659,17,33,1,1,1
659,17,36,1,15,
659,17,43,1,1,3
659,17,57,4,0,
659,17,89,1,49,
659,17,89,4,0,
659,17,91,1,33,
659,17,92,4,0,
659,17,97,1,1,2
659,17,98,1,7,
659,17,104,4,0,
659,17,111,2,0,
659,17,156,4,0,
659,17,157,4,0,
659,17,162,1,42,
659,17,164,4,0,
659,17,168,4,0,
659,17,175,1,29,
659,17,182,4,0,
659,17,188,4,0,
659,17,189,1,13,
659,17,191,2,0,
659,17,201,4,0,
659,17,205,2,0,
659,17,207,4,0,
659,17,213,4,0,
659,17,214,4,0,
659,17,216,4,0,
659,17,218,4,0,
659,17,237,4,0,
659,17,259,4,0,
659,17,263,1,47,
659,17,263,4,0,
659,17,267,4,0,
659,17,280,4,0,
659,17,316,1,25,
659,17,317,4,0,
659,17,339,4,0,
659,17,340,1,38,
659,17,341,1,18,
659,17,369,4,0,
659,17,371,4,0,
659,17,374,4,0,
659,17,444,4,0,
659,17,447,4,0,
659,17,479,4,0,
659,17,496,4,0,
659,17,523,4,0,
659,17,526,4,0,
659,17,528,4,0,
659,17,590,4,0,
659,18,3,1,10,
659,18,24,1,20,
659,18,33,1,1,1
659,18,36,1,15,
659,18,43,1,1,3
659,18,57,4,0,
659,18,89,1,49,
659,18,89,4,0,
659,18,91,1,33,
659,18,92,4,0,
659,18,97,1,1,2
659,18,98,1,7,
659,18,104,4,0,
659,18,111,2,0,
659,18,156,4,0,
659,18,157,4,0,
659,18,162,1,42,
659,18,164,4,0,
659,18,168,4,0,
659,18,175,1,29,
659,18,182,4,0,
659,18,188,4,0,
659,18,189,1,13,
659,18,191,2,0,
659,18,201,4,0,
659,18,205,2,0,
659,18,207,4,0,
659,18,213,4,0,
659,18,214,4,0,
659,18,216,4,0,
659,18,218,4,0,
659,18,237,4,0,
659,18,259,4,0,
659,18,263,1,47,
659,18,263,4,0,
659,18,267,4,0,
659,18,280,4,0,
659,18,316,1,25,
659,18,317,4,0,
659,18,339,4,0,
659,18,340,1,38,
659,18,341,1,18,
659,18,369,4,0,
659,18,371,4,0,
659,18,374,4,0,
659,18,444,4,0,
659,18,447,4,0,
659,18,479,4,0,
659,18,496,4,0,
659,18,523,4,0,
659,18,526,4,0,
659,18,528,4,0,
659,18,590,4,0,
660,15,14,1,1,4
660,15,14,4,0,
660,15,15,4,0,
660,15,24,1,20,
660,15,33,1,1,5
660,15,36,1,15,
660,15,43,1,1,7
660,15,57,4,0,
660,15,63,4,0,
660,15,70,4,0,
660,15,89,1,57,
660,15,89,4,0,
660,15,91,1,37,
660,15,91,4,0,
660,15,92,4,0,
660,15,97,1,1,6
660,15,98,1,7,
660,15,104,4,0,
660,15,156,4,0,
660,15,157,4,0,
660,15,162,1,48,
660,15,164,4,0,
660,15,168,4,0,
660,15,175,1,31,
660,15,182,4,0,
660,15,188,4,0,
660,15,189,1,13,
660,15,201,4,0,
660,15,207,4,0,
660,15,213,4,0,
660,15,214,4,0,
660,15,216,4,0,
660,15,218,4,0,
660,15,237,4,0,
660,15,249,4,0,
660,15,259,4,0,
660,15,263,1,53,
660,15,263,4,0,
660,15,267,4,0,
660,15,280,4,0,
660,15,316,1,26,
660,15,317,4,0,
660,15,339,4,0,
660,15,340,1,42,
660,15,341,1,18,
660,15,359,1,1,1
660,15,359,1,60,
660,15,369,4,0,
660,15,371,4,0,
660,15,374,4,0,
660,15,416,4,0,
660,15,444,4,0,
660,15,447,4,0,
660,15,479,4,0,
660,15,496,4,0,
660,15,523,1,1,3
660,15,523,4,0,
660,15,528,4,0,
660,15,563,1,1,2
660,15,590,4,0,
660,15,612,4,0,
660,16,7,3,0,
660,16,8,3,0,
660,16,9,3,0,
660,16,14,1,1,4
660,16,14,4,0,
660,16,15,4,0,
660,16,24,1,20,1
660,16,33,1,1,5
660,16,36,1,15,1
660,16,43,1,1,7
660,16,57,4,0,
660,16,63,4,0,
660,16,67,3,0,
660,16,70,4,0,
660,16,89,1,57,1
660,16,89,4,0,
660,16,91,1,37,1
660,16,91,4,0,
660,16,92,4,0,
660,16,97,1,1,6
660,16,98,1,7,1
660,16,104,4,0,
660,16,156,4,0,
660,16,157,4,0,
660,16,162,1,48,1
660,16,162,3,0,
660,16,164,4,0,
660,16,168,4,0,
660,16,173,3,0,
660,16,175,1,31,1
660,16,182,4,0,
660,16,188,4,0,
660,16,189,1,13,1
660,16,201,4,0,
660,16,207,4,0,
660,16,213,4,0,
660,16,214,4,0,
660,16,216,4,0,
660,16,218,4,0,
660,16,231,3,0,
660,16,237,4,0,
660,16,249,4,0,
660,16,253,3,0,
660,16,259,4,0,
660,16,263,1,53,1
660,16,263,4,0,
660,16,264,3,0,
660,16,267,4,0,
660,16,276,3,0,
660,16,278,3,0,
660,16,280,4,0,
660,16,282,3,0,
660,16,283,3,0,
660,16,289,3,0,
660,16,290,4,0,
660,16,316,1,26,1
660,16,317,4,0,
660,16,339,4,0,
660,16,340,1,42,1
660,16,340,3,0,
660,16,341,1,18,1
660,16,359,1,1,1
660,16,359,1,60,1
660,16,369,4,0,
660,16,371,4,0,
660,16,374,4,0,
660,16,380,3,0,
660,16,387,3,0,
660,16,414,3,0,
660,16,416,4,0,
660,16,441,3,0,
660,16,442,3,0,
660,16,444,4,0,
660,16,447,4,0,
660,16,479,4,0,
660,16,492,3,0,
660,16,496,4,0,
660,16,523,1,1,3
660,16,523,4,0,
660,16,528,4,0,
660,16,563,1,1,2
660,16,590,4,0,
660,16,612,4,0,
660,17,3,1,13,1
660,17,14,1,1,4
660,17,14,4,0,
660,17,24,1,21,
660,17,33,1,1,5
660,17,36,1,15,
660,17,43,1,1,7
660,17,57,4,0,
660,17,63,4,0,
660,17,89,1,57,
660,17,89,4,0,
660,17,91,1,37,
660,17,92,4,0,
660,17,97,1,1,6
660,17,98,1,7,
660,17,104,4,0,
660,17,156,4,0,
660,17,157,4,0,
660,17,162,1,48,
660,17,164,4,0,
660,17,168,4,0,
660,17,175,1,32,
660,17,182,4,0,
660,17,188,4,0,
660,17,189,1,13,2
660,17,201,4,0,
660,17,207,4,0,
660,17,213,4,0,
660,17,214,4,0,
660,17,216,4,0,
660,17,218,4,0,
660,17,237,4,0,
660,17,259,4,0,
660,17,263,1,54,
660,17,263,4,0,
660,17,267,4,0,
660,17,280,4,0,
660,17,316,1,27,
660,17,317,4,0,
660,17,339,4,0,
660,17,340,1,43,
660,17,341,1,18,
660,17,359,1,1,1
660,17,359,1,60,
660,17,369,4,0,
660,17,371,4,0,
660,17,374,4,0,
660,17,416,4,0,
660,17,444,4,0,
660,17,447,4,0,
660,17,479,4,0,
660,17,496,4,0,
660,17,523,1,1,3
660,17,523,4,0,
660,17,526,4,0,
660,17,528,4,0,
660,17,563,1,1,2
660,17,590,4,0,
660,17,693,4,0,
660,18,3,1,13,1
660,18,14,1,1,4
660,18,14,4,0,
660,18,24,1,21,
660,18,33,1,1,5
660,18,36,1,15,
660,18,43,1,1,7
660,18,57,4,0,
660,18,63,4,0,
660,18,89,1,57,
660,18,89,4,0,
660,18,91,1,37,
660,18,92,4,0,
660,18,97,1,1,6
660,18,98,1,7,
660,18,104,4,0,
660,18,156,4,0,
660,18,157,4,0,
660,18,162,1,48,
660,18,164,4,0,
660,18,168,4,0,
660,18,175,1,32,
660,18,182,4,0,
660,18,188,4,0,
660,18,189,1,13,2
660,18,201,4,0,
660,18,207,4,0,
660,18,213,4,0,
660,18,214,4,0,
660,18,216,4,0,
660,18,218,4,0,
660,18,237,4,0,
660,18,259,4,0,
660,18,263,1,54,
660,18,263,4,0,
660,18,267,4,0,
660,18,280,4,0,
660,18,316,1,27,
660,18,317,4,0,
660,18,339,4,0,
660,18,340,1,43,
660,18,341,1,18,
660,18,359,1,1,1
660,18,359,1,60,
660,18,369,4,0,
660,18,371,4,0,
660,18,374,4,0,
660,18,416,4,0,
660,18,444,4,0,
660,18,447,4,0,
660,18,479,4,0,
660,18,496,4,0,
660,18,523,1,1,3
660,18,523,4,0,
660,18,526,4,0,
660,18,528,4,0,
660,18,563,1,1,2
660,18,590,4,0,
660,18,693,4,0,
661,15,13,1,25,
661,15,14,4,0,
661,15,19,4,0,
661,15,33,1,1,1
661,15,45,1,1,2
661,15,64,1,10,
661,15,92,4,0,
661,15,97,1,13,
661,15,98,1,6,
661,15,104,4,0,
661,15,156,4,0,
661,15,164,4,0,
661,15,168,4,0,
661,15,175,1,16,
661,15,182,4,0,
661,15,207,4,0,
661,15,211,1,48,
661,15,211,4,0,
661,15,213,4,0,
661,15,214,4,0,
661,15,216,4,0,
661,15,218,4,0,
661,15,237,4,0,
661,15,241,4,0,
661,15,263,4,0,
661,15,269,4,0,
661,15,289,2,0,
661,15,315,4,0,
661,15,332,4,0,
661,15,355,1,21,
661,15,355,4,0,
661,15,363,1,29,
661,15,366,1,45,
661,15,366,2,0,
661,15,369,4,0,
661,15,382,1,41,
661,15,488,1,34,
661,15,488,4,0,
661,15,496,4,0,
661,15,501,2,0,
661,15,512,1,39,
661,15,512,4,0,
661,15,590,4,0,
661,16,13,1,25,1
661,16,14,4,0,
661,16,19,4,0,
661,16,33,1,1,1
661,16,45,1,1,2
661,16,64,1,10,1
661,16,92,4,0,
661,16,97,1,13,1
661,16,98,1,6,1
661,16,104,4,0,
661,16,156,4,0,
661,16,164,4,0,
661,16,168,4,0,
661,16,173,3,0,
661,16,175,1,16,1
661,16,182,4,0,
661,16,207,4,0,
661,16,211,1,48,1
661,16,211,4,0,
661,16,213,4,0,
661,16,214,4,0,
661,16,216,4,0,
661,16,218,4,0,
661,16,237,4,0,
661,16,241,4,0,
661,16,257,3,0,
661,16,263,4,0,
661,16,269,4,0,
661,16,289,2,0,
661,16,289,3,0,
661,16,290,4,0,
661,16,315,4,0,
661,16,332,4,0,
661,16,355,1,21,1
661,16,355,4,0,
661,16,363,1,29,1
661,16,366,1,45,1
661,16,366,2,0,
661,16,366,3,0,
661,16,369,4,0,
661,16,382,1,41,1
661,16,488,1,34,1
661,16,488,4,0,
661,16,496,4,0,
661,16,501,2,0,
661,16,512,1,39,1
661,16,512,4,0,
661,16,590,4,0,
661,17,13,1,25,
661,17,14,4,0,
661,17,19,4,0,
661,17,33,1,1,1
661,17,45,1,1,2
661,17,64,1,10,
661,17,92,4,0,
661,17,97,1,13,
661,17,98,1,6,
661,17,104,4,0,
661,17,156,4,0,
661,17,164,4,0,
661,17,168,4,0,
661,17,175,1,16,
661,17,182,4,0,
661,17,207,4,0,
661,17,211,1,48,
661,17,211,4,0,
661,17,213,4,0,
661,17,214,4,0,
661,17,216,4,0,
661,17,218,4,0,
661,17,237,4,0,
661,17,241,4,0,
661,17,263,4,0,
661,17,269,4,0,
661,17,289,2,0,
661,17,315,4,0,
661,17,332,4,0,
661,17,355,1,21,
661,17,355,4,0,
661,17,363,1,29,
661,17,366,1,45,
661,17,366,2,0,
661,17,369,4,0,
661,17,382,1,41,
661,17,488,1,34,
661,17,488,4,0,
661,17,496,4,0,
661,17,501,2,0,
661,17,512,1,39,
661,17,512,4,0,
661,17,526,4,0,
661,17,590,4,0,
661,18,13,1,25,
661,18,14,4,0,
661,18,19,4,0,
661,18,33,1,1,1
661,18,45,1,1,2
661,18,64,1,10,
661,18,92,4,0,
661,18,97,1,13,
661,18,98,1,6,
661,18,104,4,0,
661,18,156,4,0,
661,18,164,4,0,
661,18,168,4,0,
661,18,175,1,16,
661,18,182,4,0,
661,18,207,4,0,
661,18,211,1,48,
661,18,211,4,0,
661,18,213,4,0,
661,18,214,4,0,
661,18,216,4,0,
661,18,218,4,0,
661,18,237,4,0,
661,18,241,4,0,
661,18,263,4,0,
661,18,269,4,0,
661,18,289,2,0,
661,18,315,4,0,
661,18,332,4,0,
661,18,355,1,21,
661,18,355,4,0,
661,18,363,1,29,
661,18,366,1,45,
661,18,366,2,0,
661,18,369,4,0,
661,18,382,1,41,
661,18,488,1,34,
661,18,488,4,0,
661,18,496,4,0,
661,18,501,2,0,
661,18,512,1,39,
661,18,512,4,0,
661,18,526,4,0,
661,18,590,4,0,
662,15,13,1,27,
662,15,14,4,0,
662,15,19,4,0,
662,15,33,1,1,1
662,15,45,1,1,2
662,15,52,1,17,
662,15,53,4,0,
662,15,64,1,10,
662,15,92,4,0,
662,15,97,1,13,
662,15,98,1,6,
662,15,104,4,0,
662,15,126,4,0,
662,15,156,4,0,
662,15,164,4,0,
662,15,168,4,0,
662,15,175,1,16,
662,15,182,4,0,
662,15,207,4,0,
662,15,211,1,55,
662,15,211,4,0,
662,15,213,4,0,
662,15,214,4,0,
662,15,216,4,0,
662,15,218,4,0,
662,15,237,4,0,
662,15,241,4,0,
662,15,261,4,0,
662,15,263,4,0,
662,15,269,4,0,
662,15,315,4,0,
662,15,332,4,0,
662,15,355,1,25,
662,15,355,4,0,
662,15,363,1,31,
662,15,366,1,51,
662,15,369,4,0,
662,15,382,1,46,
662,15,488,1,38,
662,15,488,4,0,
662,15,496,4,0,
662,15,510,4,0,
662,15,512,1,42,
662,15,512,4,0,
662,15,590,4,0,
662,16,13,1,27,1
662,16,14,4,0,
662,16,19,4,0,
662,16,33,1,1,1
662,16,45,1,1,2
662,16,52,1,17,1
662,16,53,4,0,
662,16,64,1,10,1
662,16,92,4,0,
662,16,97,1,13,1
662,16,98,1,6,1
662,16,104,4,0,
662,16,126,4,0,
662,16,156,4,0,
662,16,164,4,0,
662,16,168,4,0,
662,16,173,3,0,
662,16,175,1,16,1
662,16,182,4,0,
662,16,207,4,0,
662,16,211,1,55,1
662,16,211,4,0,
662,16,213,4,0,
662,16,214,4,0,
662,16,216,4,0,
662,16,218,4,0,
662,16,237,4,0,
662,16,241,4,0,
662,16,257,3,0,
662,16,261,4,0,
662,16,263,4,0,
662,16,269,4,0,
662,16,289,3,0,
662,16,290,4,0,
662,16,315,4,0,
662,16,332,4,0,
662,16,355,1,25,1
662,16,355,4,0,
662,16,363,1,31,1
662,16,366,1,51,1
662,16,366,3,0,
662,16,369,4,0,
662,16,382,1,46,1
662,16,488,1,38,1
662,16,488,4,0,
662,16,496,4,0,
662,16,510,4,0,
662,16,512,1,42,1
662,16,512,4,0,
662,16,590,4,0,
662,17,13,1,27,
662,17,14,4,0,
662,17,19,4,0,
662,17,33,1,1,2
662,17,45,1,1,3
662,17,52,1,0,
662,17,52,1,1,1
662,17,53,4,0,
662,17,64,1,10,
662,17,92,4,0,
662,17,97,1,13,
662,17,98,1,1,4
662,17,98,1,6,
662,17,104,4,0,
662,17,126,4,0,
662,17,156,4,0,
662,17,164,4,0,
662,17,168,4,0,
662,17,175,1,16,
662,17,182,4,0,
662,17,207,4,0,
662,17,211,1,55,
662,17,211,4,0,
662,17,213,4,0,
662,17,214,4,0,
662,17,216,4,0,
662,17,218,4,0,
662,17,237,4,0,
662,17,241,4,0,
662,17,261,4,0,
662,17,263,4,0,
662,17,269,4,0,
662,17,315,4,0,
662,17,332,4,0,
662,17,355,1,25,
662,17,355,4,0,
662,17,363,1,31,
662,17,366,1,51,
662,17,369,4,0,
662,17,382,1,46,
662,17,488,1,38,
662,17,488,4,0,
662,17,496,4,0,
662,17,512,1,42,
662,17,512,4,0,
662,17,526,4,0,
662,17,590,4,0,
662,18,13,1,27,
662,18,14,4,0,
662,18,19,4,0,
662,18,33,1,1,2
662,18,45,1,1,3
662,18,52,1,0,
662,18,52,1,1,1
662,18,53,4,0,
662,18,64,1,10,
662,18,92,4,0,
662,18,97,1,13,
662,18,98,1,1,4
662,18,98,1,6,
662,18,104,4,0,
662,18,126,4,0,
662,18,156,4,0,
662,18,164,4,0,
662,18,168,4,0,
662,18,175,1,16,
662,18,182,4,0,
662,18,207,4,0,
662,18,211,1,55,
662,18,211,4,0,
662,18,213,4,0,
662,18,214,4,0,
662,18,216,4,0,
662,18,218,4,0,
662,18,237,4,0,
662,18,241,4,0,
662,18,261,4,0,
662,18,263,4,0,
662,18,269,4,0,
662,18,315,4,0,
662,18,332,4,0,
662,18,355,1,25,
662,18,355,4,0,
662,18,363,1,31,
662,18,366,1,51,
662,18,369,4,0,
662,18,382,1,46,
662,18,488,1,38,
662,18,488,4,0,
662,18,496,4,0,
662,18,512,1,42,
662,18,512,4,0,
662,18,526,4,0,
662,18,590,4,0,
663,15,13,1,27,
663,15,14,4,0,
663,15,19,4,0,
663,15,33,1,1,3
663,15,45,1,1,4
663,15,52,1,17,
663,15,53,4,0,
663,15,63,4,0,
663,15,64,1,10,
663,15,76,4,0,
663,15,92,4,0,
663,15,97,1,13,
663,15,98,1,6,
663,15,104,4,0,
663,15,126,4,0,
663,15,156,4,0,
663,15,164,4,0,
663,15,168,4,0,
663,15,175,1,16,
663,15,182,4,0,
663,15,207,4,0,
663,15,211,1,60,
663,15,211,4,0,
663,15,213,4,0,
663,15,214,4,0,
663,15,216,4,0,
663,15,218,4,0,
663,15,237,4,0,
663,15,241,4,0,
663,15,261,4,0,
663,15,263,4,0,
663,15,269,4,0,
663,15,315,4,0,
663,15,332,4,0,
663,15,339,4,0,
663,15,355,1,25,
663,15,355,4,0,
663,15,363,1,31,
663,15,366,1,55,
663,15,369,4,0,
663,15,382,1,49,
663,15,394,1,1,2
663,15,413,1,1,1
663,15,413,1,64,
663,15,416,4,0,
663,15,468,4,0,
663,15,488,1,39,
663,15,488,4,0,
663,15,496,4,0,
663,15,510,4,0,
663,15,512,1,44,
663,15,512,4,0,
663,15,590,4,0,
663,16,13,1,27,1
663,16,14,4,0,
663,16,19,4,0,
663,16,33,1,1,3
663,16,45,1,1,4
663,16,52,1,17,1
663,16,53,4,0,
663,16,63,4,0,
663,16,64,1,10,1
663,16,76,4,0,
663,16,92,4,0,
663,16,97,1,13,1
663,16,98,1,6,1
663,16,104,4,0,
663,16,126,4,0,
663,16,156,4,0,
663,16,164,4,0,
663,16,168,4,0,
663,16,173,3,0,
663,16,175,1,16,1
663,16,182,4,0,
663,16,207,4,0,
663,16,211,1,60,1
663,16,211,4,0,
663,16,213,4,0,
663,16,214,4,0,
663,16,216,4,0,
663,16,218,4,0,
663,16,237,4,0,
663,16,241,4,0,
663,16,257,3,0,
663,16,261,4,0,
663,16,263,4,0,
663,16,269,4,0,
663,16,289,3,0,
663,16,290,4,0,
663,16,315,4,0,
663,16,332,4,0,
663,16,339,4,0,
663,16,355,1,25,1
663,16,355,4,0,
663,16,363,1,31,1
663,16,366,1,55,1
663,16,366,3,0,
663,16,369,4,0,
663,16,382,1,49,1
663,16,394,1,1,2
663,16,413,1,1,1
663,16,413,1,64,1
663,16,416,4,0,
663,16,468,4,0,
663,16,488,1,39,1
663,16,488,4,0,
663,16,496,4,0,
663,16,510,4,0,
663,16,512,1,44,1
663,16,512,4,0,
663,16,590,4,0,
663,17,13,1,27,
663,17,14,4,0,
663,17,19,4,0,
663,17,33,1,1,4
663,17,45,1,1,5
663,17,52,1,1,1
663,17,53,4,0,
663,17,63,4,0,
663,17,64,1,1,7
663,17,64,1,10,
663,17,76,4,0,
663,17,92,4,0,
663,17,97,1,13,
663,17,98,1,1,6
663,17,98,1,6,
663,17,104,4,0,
663,17,126,4,0,
663,17,156,4,0,
663,17,164,4,0,
663,17,168,4,0,
663,17,175,1,16,
663,17,182,4,0,
663,17,207,4,0,
663,17,211,1,60,
663,17,211,4,0,
663,17,213,4,0,
663,17,214,4,0,
663,17,216,4,0,
663,17,218,4,0,
663,17,237,4,0,
663,17,241,4,0,
663,17,261,4,0,
663,17,263,4,0,
663,17,269,4,0,
663,17,315,4,0,
663,17,332,4,0,
663,17,339,4,0,
663,17,355,1,25,
663,17,355,4,0,
663,17,363,1,31,
663,17,366,1,55,
663,17,369,4,0,
663,17,382,1,49,
663,17,394,1,1,3
663,17,413,1,1,2
663,17,413,1,64,
663,17,416,4,0,
663,17,488,1,39,
663,17,488,4,0,
663,17,496,4,0,
663,17,512,1,44,
663,17,512,4,0,
663,17,526,4,0,
663,17,590,4,0,
663,18,13,1,27,
663,18,14,4,0,
663,18,19,4,0,
663,18,33,1,1,4
663,18,45,1,1,5
663,18,52,1,1,1
663,18,53,4,0,
663,18,63,4,0,
663,18,64,1,1,7
663,18,64,1,10,
663,18,76,4,0,
663,18,92,4,0,
663,18,97,1,13,
663,18,98,1,1,6
663,18,98,1,6,
663,18,104,4,0,
663,18,126,4,0,
663,18,156,4,0,
663,18,164,4,0,
663,18,168,4,0,
663,18,175,1,16,
663,18,182,4,0,
663,18,207,4,0,
663,18,211,1,60,
663,18,211,4,0,
663,18,213,4,0,
663,18,214,4,0,
663,18,216,4,0,
663,18,218,4,0,
663,18,237,4,0,
663,18,241,4,0,
663,18,261,4,0,
663,18,263,4,0,
663,18,269,4,0,
663,18,315,4,0,
663,18,332,4,0,
663,18,339,4,0,
663,18,355,1,25,
663,18,355,4,0,
663,18,363,1,31,
663,18,366,1,55,
663,18,369,4,0,
663,18,382,1,49,
663,18,394,1,1,3
663,18,413,1,1,2
663,18,413,1,64,
663,18,416,4,0,
663,18,488,1,39,
663,18,488,4,0,
663,18,496,4,0,
663,18,512,1,44,
663,18,512,4,0,
663,18,526,4,0,
663,18,590,4,0,
664,15,33,1,1,1
664,15,77,2,0,
664,15,78,1,6,
664,15,78,2,0,
664,15,81,1,1,2
664,15,450,1,15,
664,15,476,2,0,
664,16,33,1,1,1
664,16,77,2,0,
664,16,78,1,6,1
664,16,78,2,0,
664,16,81,1,1,2
664,16,450,1,15,1
664,16,450,3,0,
664,16,476,2,0,
664,17,33,1,1,1
664,17,77,2,0,
664,17,78,1,6,
664,17,78,2,0,
664,17,81,1,1,2
664,17,450,1,15,
664,17,476,2,0,
664,18,33,1,1,1
664,18,77,2,0,
664,18,78,1,6,
664,18,78,2,0,
664,18,81,1,1,2
664,18,450,1,15,
664,18,476,2,0,
665,15,106,1,1,
665,15,182,1,9,
665,15,182,4,0,
665,16,106,1,1,1
665,16,182,1,9,1
665,16,182,4,0,
665,16,334,3,0,
665,16,450,3,0,
665,16,527,3,0,
665,17,106,1,1,2
665,17,182,1,0,
665,17,182,1,1,1
665,17,182,4,0,
665,18,106,1,1,2
665,18,182,1,0,
665,18,182,1,1,1
665,18,182,4,0,
666,15,16,1,1,5
666,15,48,1,21,
666,15,60,1,17,
666,15,63,4,0,
666,15,76,4,0,
666,15,77,1,1,3
666,15,78,1,1,4
666,15,79,1,1,2
666,15,92,4,0,
666,15,94,4,0,
666,15,104,4,0,
666,15,113,1,1,6
666,15,113,4,0,
666,15,138,4,0,
666,15,148,4,0,
666,15,156,4,0,
666,15,164,4,0,
666,15,168,4,0,
666,15,182,4,0,
666,15,207,4,0,
666,15,213,4,0,
666,15,214,4,0,
666,15,216,4,0,
666,15,218,4,0,
666,15,219,1,41,
666,15,219,4,0,
666,15,237,4,0,
666,15,240,4,0,
666,15,241,4,0,
666,15,244,4,0,
666,15,263,4,0,
666,15,312,1,31,
666,15,332,4,0,
666,15,347,4,0,
666,15,355,4,0,
666,15,369,4,0,
666,15,405,1,35,
666,15,412,4,0,
666,15,416,4,0,
666,15,483,1,45,
666,15,496,4,0,
666,15,512,4,0,
666,15,522,1,12,
666,15,522,4,0,
666,15,542,1,50,
666,15,577,1,25,
666,15,590,4,0,
666,15,600,1,1,1
666,15,600,1,55,
666,15,611,4,0,
666,16,16,1,1,5
666,16,48,1,21,1
666,16,60,1,17,1
666,16,63,4,0,
666,16,76,4,0,
666,16,77,1,1,3
666,16,78,1,1,4
666,16,79,1,1,2
666,16,92,4,0,
666,16,94,4,0,
666,16,104,4,0,
666,16,113,1,1,6
666,16,113,4,0,
666,16,138,4,0,
666,16,148,4,0,
666,16,156,4,0,
666,16,164,4,0,
666,16,168,4,0,
666,16,173,3,0,
666,16,182,4,0,
666,16,202,3,0,
666,16,207,4,0,
666,16,213,4,0,
666,16,214,4,0,
666,16,216,4,0,
666,16,218,4,0,
666,16,219,1,41,1
666,16,219,4,0,
666,16,237,4,0,
666,16,240,4,0,
666,16,241,4,0,
666,16,244,4,0,
666,16,263,4,0,
666,16,283,3,0,
666,16,290,4,0,
666,16,312,1,31,1
666,16,324,3,0,
666,16,332,4,0,
666,16,347,4,0,
666,16,355,4,0,
666,16,366,3,0,
666,16,369,4,0,
666,16,405,1,35,1
666,16,412,4,0,
666,16,416,4,0,
666,16,450,3,0,
666,16,483,1,45,1
666,16,496,4,0,
666,16,512,4,0,
666,16,522,1,12,1
666,16,522,4,0,
666,16,527,3,0,
666,16,542,1,50,1
666,16,577,1,25,1
666,16,590,4,0,
666,16,600,1,1,1
666,16,600,1,55,1
666,16,611,4,0,
666,17,16,1,0,
666,17,16,1,1,1
666,17,48,1,21,
666,17,60,1,17,
666,17,63,4,0,
666,17,76,4,0,
666,17,77,1,1,4
666,17,78,1,1,5
666,17,79,1,1,3
666,17,92,4,0,
666,17,94,4,0,
666,17,104,4,0,
666,17,113,1,12,
666,17,113,4,0,
666,17,138,4,0,
666,17,156,4,0,
666,17,164,4,0,
666,17,168,4,0,
666,17,182,4,0,
666,17,207,4,0,
666,17,213,4,0,
666,17,214,4,0,
666,17,216,4,0,
666,17,218,4,0,
666,17,219,1,41,
666,17,219,4,0,
666,17,237,4,0,
666,17,240,4,0,
666,17,241,4,0,
666,17,244,4,0,
666,17,263,4,0,
666,17,312,1,31,
666,17,332,4,0,
666,17,347,4,0,
666,17,355,4,0,
666,17,369,4,0,
666,17,405,1,35,
666,17,412,4,0,
666,17,416,4,0,
666,17,483,1,45,
666,17,496,4,0,
666,17,512,4,0,
666,17,522,1,1,6
666,17,542,1,50,
666,17,577,1,25,
666,17,590,4,0,
666,17,600,1,1,2
666,17,600,1,55,
666,17,611,4,0,
666,18,16,1,0,
666,18,16,1,1,1
666,18,48,1,21,
666,18,60,1,17,
666,18,63,4,0,
666,18,76,4,0,
666,18,77,1,1,4
666,18,78,1,1,5
666,18,79,1,1,3
666,18,92,4,0,
666,18,94,4,0,
666,18,104,4,0,
666,18,113,1,12,
666,18,113,4,0,
666,18,138,4,0,
666,18,156,4,0,
666,18,164,4,0,
666,18,168,4,0,
666,18,182,4,0,
666,18,207,4,0,
666,18,213,4,0,
666,18,214,4,0,
666,18,216,4,0,
666,18,218,4,0,
666,18,219,1,41,
666,18,219,4,0,
666,18,237,4,0,
666,18,240,4,0,
666,18,241,4,0,
666,18,244,4,0,
666,18,263,4,0,
666,18,312,1,31,
666,18,332,4,0,
666,18,347,4,0,
666,18,355,4,0,
666,18,369,4,0,
666,18,405,1,35,
666,18,412,4,0,
666,18,416,4,0,
666,18,483,1,45,
666,18,496,4,0,
666,18,512,4,0,
666,18,522,1,1,6
666,18,542,1,50,
666,18,577,1,25,
666,18,590,4,0,
666,18,600,1,1,2
666,18,600,1,55,
666,18,611,4,0,
667,15,29,1,11,
667,15,33,1,1,1
667,15,36,1,20,
667,15,43,1,1,2
667,15,46,4,0,
667,15,52,1,5,
667,15,53,1,36,
667,15,53,4,0,
667,15,70,4,0,
667,15,76,4,0,
667,15,83,2,0,
667,15,91,4,0,
667,15,92,4,0,
667,15,104,4,0,
667,15,126,4,0,
667,15,156,4,0,
667,15,164,4,0,
667,15,168,4,0,
667,15,182,4,0,
667,15,207,4,0,
667,15,213,4,0,
667,15,214,4,0,
667,15,216,4,0,
667,15,218,4,0,
667,15,237,4,0,
667,15,240,4,0,
667,15,241,4,0,
667,15,242,1,39,
667,15,249,4,0,
667,15,261,4,0,
667,15,263,4,0,
667,15,269,4,0,
667,15,281,2,0,
667,15,283,1,28,
667,15,289,2,0,
667,15,304,1,43,
667,15,315,1,50,
667,15,315,4,0,
667,15,371,4,0,
667,15,399,4,0,
667,15,424,1,23,
667,15,488,4,0,
667,15,494,2,0,
667,15,496,4,0,
667,15,497,1,33,
667,15,497,4,0,
667,15,510,1,46,
667,15,510,4,0,
667,15,514,4,0,
667,15,523,4,0,
667,15,526,1,8,
667,15,528,4,0,
667,15,555,4,0,
667,15,568,1,15,
667,15,590,4,0,
667,16,29,1,11,1
667,16,33,1,1,1
667,16,36,1,20,1
667,16,43,1,1,2
667,16,46,4,0,
667,16,52,1,5,1
667,16,53,1,36,1
667,16,53,4,0,
667,16,70,4,0,
667,16,76,4,0,
667,16,83,2,0,
667,16,91,4,0,
667,16,92,4,0,
667,16,104,4,0,
667,16,126,4,0,
667,16,156,4,0,
667,16,164,4,0,
667,16,168,4,0,
667,16,173,3,0,
667,16,182,4,0,
667,16,207,4,0,
667,16,213,4,0,
667,16,214,4,0,
667,16,216,4,0,
667,16,218,4,0,
667,16,231,3,0,
667,16,237,4,0,
667,16,240,4,0,
667,16,241,4,0,
667,16,242,1,39,1
667,16,249,4,0,
667,16,257,3,0,
667,16,261,4,0,
667,16,263,4,0,
667,16,269,4,0,
667,16,270,3,0,
667,16,281,2,0,
667,16,283,1,28,1
667,16,283,3,0,
667,16,289,2,0,
667,16,289,3,0,
667,16,290,4,0,
667,16,304,1,43,1
667,16,304,3,0,
667,16,315,1,50,1
667,16,315,4,0,
667,16,371,4,0,
667,16,399,4,0,
667,16,424,1,23,1
667,16,488,4,0,
667,16,494,2,0,
667,16,496,4,0,
667,16,497,1,33,1
667,16,497,4,0,
667,16,510,1,46,1
667,16,510,4,0,
667,16,514,4,0,
667,16,523,4,0,
667,16,526,1,8,1
667,16,528,4,0,
667,16,555,4,0,
667,16,568,1,15,1
667,16,590,4,0,
667,17,29,1,11,
667,17,33,1,1,1
667,17,36,1,20,
667,17,43,1,1,2
667,17,46,4,0,
667,17,52,1,5,
667,17,53,1,36,
667,17,53,4,0,
667,17,76,4,0,
667,17,83,2,0,
667,17,92,4,0,
667,17,104,4,0,
667,17,126,4,0,
667,17,156,4,0,
667,17,164,4,0,
667,17,168,4,0,
667,17,182,4,0,
667,17,207,4,0,
667,17,213,4,0,
667,17,214,4,0,
667,17,216,4,0,
667,17,218,4,0,
667,17,237,4,0,
667,17,240,4,0,
667,17,241,4,0,
667,17,242,1,39,
667,17,261,4,0,
667,17,263,4,0,
667,17,269,4,0,
667,17,281,2,0,
667,17,283,1,28,
667,17,289,2,0,
667,17,304,1,43,
667,17,315,1,50,
667,17,315,4,0,
667,17,371,4,0,
667,17,394,2,0,
667,17,399,4,0,
667,17,424,1,23,
667,17,488,4,0,
667,17,494,2,0,
667,17,496,4,0,
667,17,497,1,33,
667,17,497,4,0,
667,17,510,1,46,
667,17,523,4,0,
667,17,526,1,8,
667,17,526,4,0,
667,17,528,4,0,
667,17,555,4,0,
667,17,568,1,15,
667,17,590,4,0,
667,18,29,1,11,
667,18,33,1,1,1
667,18,36,1,20,
667,18,43,1,1,2
667,18,46,4,0,
667,18,52,1,5,
667,18,53,1,36,
667,18,53,4,0,
667,18,76,4,0,
667,18,83,2,0,
667,18,92,4,0,
667,18,104,4,0,
667,18,126,4,0,
667,18,156,4,0,
667,18,164,4,0,
667,18,168,4,0,
667,18,182,4,0,
667,18,207,4,0,
667,18,213,4,0,
667,18,214,4,0,
667,18,216,4,0,
667,18,218,4,0,
667,18,237,4,0,
667,18,240,4,0,
667,18,241,4,0,
667,18,242,1,39,
667,18,261,4,0,
667,18,263,4,0,
667,18,269,4,0,
667,18,281,2,0,
667,18,283,1,28,
667,18,289,2,0,
667,18,304,1,43,
667,18,315,1,50,
667,18,315,4,0,
667,18,371,4,0,
667,18,394,2,0,
667,18,399,4,0,
667,18,424,1,23,
667,18,488,4,0,
667,18,494,2,0,
667,18,496,4,0,
667,18,497,1,33,
667,18,497,4,0,
667,18,510,1,46,
667,18,523,4,0,
667,18,526,1,8,
667,18,526,4,0,
667,18,528,4,0,
667,18,555,4,0,
667,18,568,1,15,
667,18,590,4,0,
668,15,29,1,11,
668,15,33,1,1,2
668,15,36,1,20,
668,15,43,1,1,3
668,15,46,4,0,
668,15,52,1,5,
668,15,53,1,38,
668,15,53,4,0,
668,15,63,1,1,1
668,15,63,4,0,
668,15,70,4,0,
668,15,76,4,0,
668,15,91,4,0,
668,15,92,4,0,
668,15,104,4,0,
668,15,126,4,0,
668,15,156,4,0,
668,15,164,4,0,
668,15,168,4,0,
668,15,182,4,0,
668,15,207,4,0,
668,15,213,4,0,
668,15,214,4,0,
668,15,216,4,0,
668,15,218,4,0,
668,15,237,4,0,
668,15,240,4,0,
668,15,241,4,0,
668,15,242,1,42,
668,15,249,4,0,
668,15,261,4,0,
668,15,263,4,0,
668,15,269,4,0,
668,15,283,1,28,
668,15,304,1,48,
668,15,315,1,57,
668,15,315,4,0,
668,15,371,4,0,
668,15,399,4,0,
668,15,416,4,0,
668,15,424,1,23,
668,15,488,4,0,
668,15,496,4,0,
668,15,497,1,33,
668,15,497,4,0,
668,15,510,1,51,
668,15,510,4,0,
668,15,514,4,0,
668,15,523,4,0,
668,15,526,1,8,
668,15,528,4,0,
668,15,555,4,0,
668,15,568,1,15,
668,15,590,4,0,
668,16,29,1,11,1
668,16,33,1,1,2
668,16,36,1,20,1
668,16,43,1,1,3
668,16,46,4,0,
668,16,52,1,5,1
668,16,53,1,38,1
668,16,53,4,0,
668,16,63,1,1,1
668,16,63,4,0,
668,16,70,4,0,
668,16,76,4,0,
668,16,91,4,0,
668,16,92,4,0,
668,16,104,4,0,
668,16,126,4,0,
668,16,156,4,0,
668,16,164,4,0,
668,16,168,4,0,
668,16,173,3,0,
668,16,182,4,0,
668,16,207,4,0,
668,16,213,4,0,
668,16,214,4,0,
668,16,216,4,0,
668,16,218,4,0,
668,16,231,3,0,
668,16,237,4,0,
668,16,240,4,0,
668,16,241,4,0,
668,16,242,1,42,1
668,16,249,4,0,
668,16,257,3,0,
668,16,261,4,0,
668,16,263,4,0,
668,16,269,4,0,
668,16,270,3,0,
668,16,283,1,28,1
668,16,283,3,0,
668,16,289,3,0,
668,16,290,4,0,
668,16,304,1,48,1
668,16,304,3,0,
668,16,315,1,57,1
668,16,315,4,0,
668,16,340,3,0,
668,16,371,4,0,
668,16,399,4,0,
668,16,416,4,0,
668,16,424,1,23,1
668,16,488,4,0,
668,16,496,4,0,
668,16,497,1,33,1
668,16,497,4,0,
668,16,510,1,51,1
668,16,510,4,0,
668,16,514,4,0,
668,16,523,4,0,
668,16,526,1,8,1
668,16,528,4,0,
668,16,555,4,0,
668,16,568,1,15,1
668,16,590,4,0,
668,17,29,1,11,
668,17,33,1,1,2
668,17,36,1,20,
668,17,43,1,1,3
668,17,46,4,0,
668,17,52,1,1,4
668,17,52,1,5,
668,17,53,1,38,
668,17,53,4,0,
668,17,63,1,1,1
668,17,63,4,0,
668,17,76,4,0,
668,17,92,4,0,
668,17,104,4,0,
668,17,126,4,0,
668,17,156,4,0,
668,17,164,4,0,
668,17,168,4,0,
668,17,182,4,0,
668,17,207,4,0,
668,17,213,4,0,
668,17,214,4,0,
668,17,216,4,0,
668,17,218,4,0,
668,17,237,4,0,
668,17,240,4,0,
668,17,241,4,0,
668,17,242,1,42,
668,17,261,4,0,
668,17,263,4,0,
668,17,269,4,0,
668,17,283,1,28,
668,17,304,1,48,
668,17,315,1,57,
668,17,315,4,0,
668,17,371,4,0,
668,17,399,4,0,
668,17,416,4,0,
668,17,424,1,23,
668,17,488,4,0,
668,17,496,4,0,
668,17,497,1,33,
668,17,497,4,0,
668,17,510,1,51,
668,17,523,4,0,
668,17,526,1,1,5
668,17,526,1,8,
668,17,526,4,0,
668,17,528,4,0,
668,17,555,4,0,
668,17,568,1,15,
668,17,590,4,0,
668,18,29,1,11,
668,18,33,1,1,2
668,18,36,1,20,
668,18,43,1,1,3
668,18,46,4,0,
668,18,52,1,1,4
668,18,52,1,5,
668,18,53,1,38,
668,18,53,4,0,
668,18,63,1,1,1
668,18,63,4,0,
668,18,76,4,0,
668,18,92,4,0,
668,18,104,4,0,
668,18,126,4,0,
668,18,156,4,0,
668,18,164,4,0,
668,18,168,4,0,
668,18,182,4,0,
668,18,207,4,0,
668,18,213,4,0,
668,18,214,4,0,
668,18,216,4,0,
668,18,218,4,0,
668,18,237,4,0,
668,18,240,4,0,
668,18,241,4,0,
668,18,242,1,42,
668,18,261,4,0,
668,18,263,4,0,
668,18,269,4,0,
668,18,283,1,28,
668,18,304,1,48,
668,18,315,1,57,
668,18,315,4,0,
668,18,371,4,0,
668,18,399,4,0,
668,18,416,4,0,
668,18,424,1,23,
668,18,488,4,0,
668,18,496,4,0,
668,18,497,1,33,
668,18,497,4,0,
668,18,510,1,51,
668,18,523,4,0,
668,18,526,1,1,5
668,18,526,1,8,
668,18,526,4,0,
668,18,528,4,0,
668,18,555,4,0,
668,18,568,1,15,
668,18,590,4,0,
669,15,22,1,1,2
669,15,33,1,1,1
669,15,75,1,15,
669,15,76,1,48,
669,15,76,4,0,
669,15,80,1,45,
669,15,92,4,0,
669,15,94,4,0,
669,15,104,4,0,
669,15,148,4,0,
669,15,156,4,0,
669,15,164,4,0,
669,15,182,4,0,
669,15,207,4,0,
669,15,213,4,0,
669,15,214,4,0,
669,15,216,4,0,
669,15,218,4,0,
669,15,219,4,0,
669,15,237,4,0,
669,15,240,4,0,
669,15,241,4,0,
669,15,263,4,0,
669,15,267,4,0,
669,15,273,1,20,
669,15,293,2,0,
669,15,312,1,33,
669,15,345,1,22,
669,15,347,4,0,
669,15,381,1,10,
669,15,383,2,0,
669,15,412,4,0,
669,15,445,2,0,
669,15,447,4,0,
669,15,496,4,0,
669,15,497,4,0,
669,15,572,1,28,
669,15,580,1,24,
669,15,581,1,37,
669,15,584,1,6,
669,15,585,1,41,
669,15,590,4,0,
669,15,605,4,0,
669,16,22,1,1,2
669,16,33,1,1,1
669,16,75,1,15,1
669,16,76,1,48,1
669,16,76,4,0,
669,16,80,1,45,1
669,16,92,4,0,
669,16,94,4,0,
669,16,104,4,0,
669,16,148,4,0,
669,16,156,4,0,
669,16,164,4,0,
669,16,173,3,0,
669,16,182,4,0,
669,16,202,3,0,
669,16,207,4,0,
669,16,213,4,0,
669,16,214,4,0,
669,16,215,3,0,
669,16,216,4,0,
669,16,218,4,0,
669,16,219,4,0,
669,16,235,3,0,
669,16,237,4,0,
669,16,240,4,0,
669,16,241,4,0,
669,16,263,4,0,
669,16,267,4,0,
669,16,270,3,0,
669,16,273,1,20,1
669,16,277,3,0,
669,16,283,3,0,
669,16,290,4,0,
669,16,293,2,0,
669,16,312,1,33,1
669,16,343,3,0,
669,16,345,1,22,1
669,16,347,4,0,
669,16,381,1,10,1
669,16,383,2,0,
669,16,388,3,0,
669,16,402,3,0,
669,16,412,4,0,
669,16,445,2,0,
669,16,447,4,0,
669,16,495,3,0,
669,16,496,4,0,
669,16,497,4,0,
669,16,572,1,28,1
669,16,580,1,24,1
669,16,581,1,37,1
669,16,584,1,6,1
669,16,585,1,41,1
669,16,590,4,0,
669,16,605,4,0,
669,17,22,1,1,2
669,17,33,1,1,1
669,17,75,1,15,
669,17,76,1,48,
669,17,76,4,0,
669,17,80,1,45,
669,17,92,4,0,
669,17,94,4,0,
669,17,104,4,0,
669,17,156,4,0,
669,17,164,4,0,
669,17,182,4,0,
669,17,207,4,0,
669,17,213,4,0,
669,17,214,4,0,
669,17,216,4,0,
669,17,218,4,0,
669,17,219,4,0,
669,17,237,4,0,
669,17,240,4,0,
669,17,241,4,0,
669,17,263,4,0,
669,17,267,4,0,
669,17,273,1,20,
669,17,293,2,0,
669,17,312,1,33,
669,17,345,1,22,
669,17,347,4,0,
669,17,381,1,10,
669,17,383,2,0,
669,17,412,4,0,
669,17,445,2,0,
669,17,447,4,0,
669,17,496,4,0,
669,17,497,4,0,
669,17,572,1,28,
669,17,580,1,24,
669,17,581,1,37,
669,17,584,1,6,
669,17,585,1,41,
669,17,590,4,0,
669,17,605,4,0,
669,18,22,1,1,2
669,18,33,1,1,1
669,18,75,1,15,
669,18,76,1,48,
669,18,76,4,0,
669,18,80,1,45,
669,18,92,4,0,
669,18,94,4,0,
669,18,104,4,0,
669,18,156,4,0,
669,18,164,4,0,
669,18,182,4,0,
669,18,207,4,0,
669,18,213,4,0,
669,18,214,4,0,
669,18,216,4,0,
669,18,218,4,0,
669,18,219,4,0,
669,18,237,4,0,
669,18,240,4,0,
669,18,241,4,0,
669,18,263,4,0,
669,18,267,4,0,
669,18,273,1,20,
669,18,293,2,0,
669,18,312,1,33,
669,18,345,1,22,
669,18,347,4,0,
669,18,381,1,10,
669,18,383,2,0,
669,18,412,4,0,
669,18,445,2,0,
669,18,447,4,0,
669,18,496,4,0,
669,18,497,4,0,
669,18,572,1,28,
669,18,580,1,24,
669,18,581,1,37,
669,18,584,1,6,
669,18,585,1,41,
669,18,590,4,0,
669,18,605,4,0,
669,18,715,2,0,
670,15,22,1,1,2
670,15,33,1,1,1
670,15,75,1,15,
670,15,76,1,58,
670,15,76,4,0,
670,15,80,1,51,
670,15,92,4,0,
670,15,94,4,0,
670,15,104,4,0,
670,15,148,4,0,
670,15,156,4,0,
670,15,164,4,0,
670,15,182,4,0,
670,15,207,4,0,
670,15,213,4,0,
670,15,214,4,0,
670,15,216,4,0,
670,15,218,4,0,
670,15,219,4,0,
670,15,237,4,0,
670,15,240,4,0,
670,15,241,4,0,
670,15,263,4,0,
670,15,267,4,0,
670,15,273,1,20,
670,15,312,1,38,
670,15,345,1,25,
670,15,347,4,0,
670,15,381,1,10,
670,15,412,4,0,
670,15,447,4,0,
670,15,496,4,0,
670,15,497,4,0,
670,15,572,1,33,
670,15,580,1,27,
670,15,581,1,43,
670,15,584,1,6,
670,15,585,1,46,
670,15,590,4,0,
670,15,605,4,0,
670,16,22,1,1,2
670,16,33,1,1,1
670,16,75,1,15,1
670,16,76,1,58,1
670,16,76,4,0,
670,16,80,1,51,1
670,16,92,4,0,
670,16,94,4,0,
670,16,104,4,0,
670,16,148,4,0,
670,16,156,4,0,
670,16,164,4,0,
670,16,173,3,0,
670,16,182,4,0,
670,16,202,3,0,
670,16,207,4,0,
670,16,213,4,0,
670,16,214,4,0,
670,16,215,3,0,
670,16,216,4,0,
670,16,218,4,0,
670,16,219,4,0,
670,16,235,3,0,
670,16,237,4,0,
670,16,240,4,0,
670,16,241,4,0,
670,16,263,4,0,
670,16,267,4,0,
670,16,270,3,0,
670,16,273,1,20,1
670,16,277,3,0,
670,16,283,3,0,
670,16,290,4,0,
670,16,312,1,38,1
670,16,343,3,0,
670,16,345,1,25,1
670,16,347,4,0,
670,16,381,1,10,1
670,16,388,3,0,
670,16,402,3,0,
670,16,412,4,0,
670,16,447,4,0,
670,16,495,3,0,
670,16,496,4,0,
670,16,497,4,0,
670,16,572,1,33,1
670,16,580,1,27,1
670,16,581,1,43,1
670,16,584,1,6,1
670,16,585,1,46,1
670,16,590,4,0,
670,16,605,4,0,
670,17,22,1,1,2
670,17,33,1,1,1
670,17,75,1,15,
670,17,76,1,58,
670,17,76,4,0,
670,17,80,1,51,
670,17,92,4,0,
670,17,94,4,0,
670,17,104,4,0,
670,17,156,4,0,
670,17,164,4,0,
670,17,182,4,0,
670,17,207,4,0,
670,17,213,4,0,
670,17,214,4,0,
670,17,216,4,0,
670,17,218,4,0,
670,17,219,4,0,
670,17,237,4,0,
670,17,240,4,0,
670,17,241,4,0,
670,17,263,4,0,
670,17,267,4,0,
670,17,273,1,20,
670,17,312,1,38,
670,17,345,1,25,
670,17,347,4,0,
670,17,381,1,10,
670,17,412,4,0,
670,17,447,4,0,
670,17,496,4,0,
670,17,497,4,0,
670,17,572,1,33,
670,17,580,1,27,
670,17,581,1,43,
670,17,584,1,1,3
670,17,584,1,6,
670,17,585,1,46,
670,17,590,4,0,
670,17,605,4,0,
670,18,22,1,1,2
670,18,33,1,1,1
670,18,75,1,15,
670,18,76,1,58,
670,18,76,4,0,
670,18,80,1,51,
670,18,92,4,0,
670,18,94,4,0,
670,18,104,4,0,
670,18,156,4,0,
670,18,164,4,0,
670,18,182,4,0,
670,18,207,4,0,
670,18,213,4,0,
670,18,214,4,0,
670,18,216,4,0,
670,18,218,4,0,
670,18,219,4,0,
670,18,237,4,0,
670,18,240,4,0,
670,18,241,4,0,
670,18,263,4,0,
670,18,267,4,0,
670,18,273,1,20,
670,18,312,1,38,
670,18,345,1,25,
670,18,347,4,0,
670,18,381,1,10,
670,18,412,4,0,
670,18,447,4,0,
670,18,496,4,0,
670,18,497,4,0,
670,18,572,1,33,
670,18,580,1,27,
670,18,581,1,43,
670,18,584,1,1,3
670,18,584,1,6,
670,18,585,1,46,
670,18,590,4,0,
670,18,605,4,0,
671,15,63,4,0,
671,15,76,4,0,
671,15,80,1,1,11
671,15,92,4,0,
671,15,94,4,0,
671,15,104,4,0,
671,15,113,4,0,
671,15,148,4,0,
671,15,156,4,0,
671,15,164,4,0,
671,15,182,4,0,
671,15,207,4,0,
671,15,213,4,0,
671,15,214,4,0,
671,15,216,4,0,
671,15,218,4,0,
671,15,219,4,0,
671,15,237,4,0,
671,15,240,4,0,
671,15,241,4,0,
671,15,263,4,0,
671,15,267,4,0,
671,15,273,1,1,3
671,15,312,1,1,12
671,15,345,1,1,4
671,15,347,4,0,
671,15,381,1,1,2
671,15,412,4,0,
671,15,416,4,0,
671,15,447,1,1,6
671,15,447,4,0,
671,15,496,4,0,
671,15,497,4,0,
671,15,572,1,1,8
671,15,574,1,1,1
671,15,579,1,1,5
671,15,580,1,1,7
671,15,581,1,1,9
671,15,585,1,1,10
671,15,590,4,0,
671,15,605,4,0,
671,16,63,4,0,
671,16,76,4,0,
671,16,80,1,1,11
671,16,92,4,0,
671,16,94,4,0,
671,16,104,4,0,
671,16,113,4,0,
671,16,148,4,0,
671,16,156,4,0,
671,16,164,4,0,
671,16,173,3,0,
671,16,182,4,0,
671,16,202,3,0,
671,16,207,4,0,
671,16,213,4,0,
671,16,214,4,0,
671,16,215,3,0,
671,16,216,4,0,
671,16,218,4,0,
671,16,219,4,0,
671,16,235,3,0,
671,16,237,4,0,
671,16,240,4,0,
671,16,241,4,0,
671,16,263,4,0,
671,16,267,4,0,
671,16,270,3,0,
671,16,273,1,1,3
671,16,277,3,0,
671,16,283,3,0,
671,16,290,4,0,
671,16,312,1,1,12
671,16,343,3,0,
671,16,345,1,1,4
671,16,347,4,0,
671,16,381,1,1,2
671,16,388,3,0,
671,16,402,3,0,
671,16,412,4,0,
671,16,416,4,0,
671,16,447,1,1,6
671,16,447,4,0,
671,16,495,3,0,
671,16,496,4,0,
671,16,497,4,0,
671,16,572,1,1,8
671,16,574,1,1,1
671,16,579,1,1,5
671,16,580,1,1,7
671,16,581,1,1,9
671,16,585,1,1,10
671,16,590,4,0,
671,16,605,4,0,
671,17,63,4,0,
671,17,76,4,0,
671,17,80,1,1,11
671,17,92,4,0,
671,17,94,4,0,
671,17,104,4,0,
671,17,113,4,0,
671,17,156,4,0,
671,17,164,4,0,
671,17,182,4,0,
671,17,207,4,0,
671,17,213,4,0,
671,17,214,4,0,
671,17,216,4,0,
671,17,218,4,0,
671,17,219,4,0,
671,17,237,4,0,
671,17,240,4,0,
671,17,241,4,0,
671,17,263,4,0,
671,17,267,4,0,
671,17,273,1,1,3
671,17,312,1,1,12
671,17,345,1,1,4
671,17,347,4,0,
671,17,381,1,1,2
671,17,412,4,0,
671,17,416,4,0,
671,17,447,1,1,6
671,17,447,4,0,
671,17,496,4,0,
671,17,497,4,0,
671,17,572,1,1,8
671,17,574,1,1,1
671,17,579,1,1,5
671,17,580,1,1,7
671,17,581,1,1,9
671,17,585,1,1,10
671,17,590,4,0,
671,17,605,4,0,
671,18,63,4,0,
671,18,76,4,0,
671,18,80,1,1,11
671,18,92,4,0,
671,18,94,4,0,
671,18,104,4,0,
671,18,113,4,0,
671,18,156,4,0,
671,18,164,4,0,
671,18,182,4,0,
671,18,207,4,0,
671,18,213,4,0,
671,18,214,4,0,
671,18,216,4,0,
671,18,218,4,0,
671,18,219,4,0,
671,18,237,4,0,
671,18,240,4,0,
671,18,241,4,0,
671,18,263,4,0,
671,18,267,4,0,
671,18,273,1,1,3
671,18,312,1,1,12
671,18,345,1,1,4
671,18,347,4,0,
671,18,381,1,1,2
671,18,412,4,0,
671,18,416,4,0,
671,18,447,1,1,6
671,18,447,4,0,
671,18,496,4,0,
671,18,497,4,0,
671,18,572,1,1,8
671,18,574,1,1,1
671,18,579,1,1,5
671,18,580,1,1,7
671,18,581,1,1,9
671,18,585,1,1,10
671,18,590,4,0,
671,18,605,4,0,
672,15,22,1,7,
672,15,33,1,1,1
672,15,36,1,22,
672,15,38,1,38,
672,15,39,1,9,
672,15,46,4,0,
672,15,57,4,0,
672,15,70,4,0,
672,15,73,1,12,
672,15,74,1,1,2
672,15,75,1,13,
672,15,76,4,0,
672,15,91,4,0,
672,15,92,4,0,
672,15,104,4,0,
672,15,111,2,0,
672,15,156,4,0,
672,15,157,4,0,
672,15,164,4,0,
672,15,182,4,0,
672,15,205,2,0,
672,15,207,4,0,
672,15,208,1,50,
672,15,208,2,0,
672,15,213,4,0,
672,15,214,4,0,
672,15,216,4,0,
672,15,218,4,0,
672,15,235,1,20,
672,15,237,4,0,
672,15,240,4,0,
672,15,241,4,0,
672,15,249,4,0,
672,15,263,4,0,
672,15,267,4,0,
672,15,280,4,0,
672,15,339,1,34,
672,15,339,4,0,
672,15,348,1,45,
672,15,371,4,0,
672,15,388,1,16,
672,15,402,1,30,
672,15,412,4,0,
672,15,447,4,0,
672,15,496,4,0,
672,15,514,4,0,
672,15,523,1,26,
672,15,523,4,0,
672,15,528,4,0,
672,15,532,1,42,
672,15,590,4,0,
672,16,22,1,7,1
672,16,33,1,1,1
672,16,36,1,22,1
672,16,38,1,38,1
672,16,39,1,9,1
672,16,46,4,0,
672,16,57,4,0,
672,16,70,4,0,
672,16,73,1,12,1
672,16,74,1,1,2
672,16,75,1,13,1
672,16,76,4,0,
672,16,91,4,0,
672,16,92,4,0,
672,16,104,4,0,
672,16,111,2,0,
672,16,156,4,0,
672,16,157,4,0,
672,16,164,4,0,
672,16,173,3,0,
672,16,182,4,0,
672,16,202,3,0,
672,16,205,2,0,
672,16,207,4,0,
672,16,208,1,50,1
672,16,208,2,0,
672,16,213,4,0,
672,16,214,4,0,
672,16,216,4,0,
672,16,218,4,0,
672,16,231,3,0,
672,16,235,1,20,1
672,16,235,3,0,
672,16,237,4,0,
672,16,240,4,0,
672,16,241,4,0,
672,16,249,4,0,
672,16,263,4,0,
672,16,267,4,0,
672,16,280,4,0,
672,16,290,4,0,
672,16,339,1,34,1
672,16,339,4,0,
672,16,348,1,45,1
672,16,371,4,0,
672,16,388,1,16,1
672,16,388,3,0,
672,16,402,1,30,1
672,16,402,3,0,
672,16,412,4,0,
672,16,428,3,0,
672,16,447,4,0,
672,16,496,4,0,
672,16,514,4,0,
672,16,523,1,26,1
672,16,523,4,0,
672,16,528,4,0,
672,16,532,1,42,1
672,16,590,4,0,
672,17,22,1,7,
672,17,33,1,1,1
672,17,36,1,22,
672,17,38,1,38,
672,17,39,1,9,
672,17,46,4,0,
672,17,57,4,0,
672,17,73,1,12,
672,17,74,1,1,2
672,17,75,1,13,
672,17,76,4,0,
672,17,92,4,0,
672,17,104,4,0,
672,17,111,2,0,
672,17,156,4,0,
672,17,157,4,0,
672,17,164,4,0,
672,17,182,4,0,
672,17,205,2,0,
672,17,207,4,0,
672,17,208,1,50,
672,17,208,2,0,
672,17,213,4,0,
672,17,214,4,0,
672,17,216,4,0,
672,17,218,4,0,
672,17,235,1,20,
672,17,237,4,0,
672,17,240,4,0,
672,17,241,4,0,
672,17,263,4,0,
672,17,267,4,0,
672,17,280,4,0,
672,17,339,1,34,
672,17,339,4,0,
672,17,348,1,45,
672,17,371,4,0,
672,17,388,1,16,
672,17,402,1,30,
672,17,412,4,0,
672,17,447,4,0,
672,17,496,4,0,
672,17,523,1,26,
672,17,523,4,0,
672,17,526,4,0,
672,17,528,4,0,
672,17,532,1,42,
672,17,590,4,0,
672,18,22,1,7,
672,18,33,1,1,1
672,18,36,1,22,
672,18,38,1,38,
672,18,39,1,9,
672,18,46,4,0,
672,18,57,4,0,
672,18,73,1,12,
672,18,74,1,1,2
672,18,75,1,13,
672,18,76,4,0,
672,18,92,4,0,
672,18,104,4,0,
672,18,111,2,0,
672,18,156,4,0,
672,18,157,4,0,
672,18,164,4,0,
672,18,182,4,0,
672,18,205,2,0,
672,18,207,4,0,
672,18,208,1,50,
672,18,208,2,0,
672,18,213,4,0,
672,18,214,4,0,
672,18,216,4,0,
672,18,218,4,0,
672,18,235,1,20,
672,18,237,4,0,
672,18,240,4,0,
672,18,241,4,0,
672,18,263,4,0,
672,18,267,4,0,
672,18,280,4,0,
672,18,339,1,34,
672,18,339,4,0,
672,18,348,1,45,
672,18,371,4,0,
672,18,388,1,16,
672,18,402,1,30,
672,18,412,4,0,
672,18,447,4,0,
672,18,496,4,0,
672,18,523,1,26,
672,18,523,4,0,
672,18,526,4,0,
672,18,528,4,0,
672,18,532,1,42,
672,18,580,2,0,
672,18,590,4,0,
673,15,22,1,7,
673,15,33,1,1,2
673,15,36,1,22,
673,15,38,1,40,
673,15,39,1,9,
673,15,46,4,0,
673,15,57,4,0,
673,15,63,4,0,
673,15,70,4,0,
673,15,73,1,12,
673,15,74,1,1,3
673,15,75,1,13,
673,15,76,4,0,
673,15,89,1,60,
673,15,89,4,0,
673,15,91,4,0,
673,15,92,4,0,
673,15,104,4,0,
673,15,156,4,0,
673,15,157,4,0,
673,15,164,4,0,
673,15,182,4,0,
673,15,207,4,0,
673,15,208,1,58,
673,15,213,4,0,
673,15,214,4,0,
673,15,216,4,0,
673,15,218,4,0,
673,15,235,1,20,
673,15,237,4,0,
673,15,240,4,0,
673,15,241,4,0,
673,15,249,4,0,
673,15,263,4,0,
673,15,267,4,0,
673,15,280,4,0,
673,15,332,1,1,1
673,15,332,1,65,
673,15,332,4,0,
673,15,339,1,34,
673,15,339,4,0,
673,15,348,1,55,
673,15,371,4,0,
673,15,388,1,16,
673,15,402,1,30,
673,15,412,4,0,
673,15,416,4,0,
673,15,447,4,0,
673,15,496,4,0,
673,15,514,4,0,
673,15,523,1,26,
673,15,523,4,0,
673,15,528,4,0,
673,15,532,1,47,
673,15,590,4,0,
673,16,22,1,7,1
673,16,33,1,1,2
673,16,36,1,22,1
673,16,38,1,40,1
673,16,39,1,9,1
673,16,46,4,0,
673,16,57,4,0,
673,16,63,4,0,
673,16,70,4,0,
673,16,73,1,12,1
673,16,74,1,1,3
673,16,75,1,13,1
673,16,76,4,0,
673,16,89,1,60,1
673,16,89,4,0,
673,16,91,4,0,
673,16,92,4,0,
673,16,104,4,0,
673,16,156,4,0,
673,16,157,4,0,
673,16,164,4,0,
673,16,173,3,0,
673,16,182,4,0,
673,16,202,3,0,
673,16,207,4,0,
673,16,208,1,58,1
673,16,213,4,0,
673,16,214,4,0,
673,16,216,4,0,
673,16,218,4,0,
673,16,231,3,0,
673,16,235,1,20,1
673,16,235,3,0,
673,16,237,4,0,
673,16,240,4,0,
673,16,241,4,0,
673,16,249,4,0,
673,16,263,4,0,
673,16,267,4,0,
673,16,276,3,0,
673,16,280,4,0,
673,16,290,4,0,
673,16,332,1,1,1
673,16,332,1,65,1
673,16,332,4,0,
673,16,339,1,34,1
673,16,339,4,0,
673,16,340,3,0,
673,16,348,1,55,1
673,16,371,4,0,
673,16,388,1,16,1
673,16,388,3,0,
673,16,402,1,30,1
673,16,402,3,0,
673,16,412,4,0,
673,16,416,4,0,
673,16,428,3,0,
673,16,447,4,0,
673,16,496,4,0,
673,16,514,4,0,
673,16,523,1,26,1
673,16,523,4,0,
673,16,528,4,0,
673,16,532,1,47,1
673,16,590,4,0,
673,17,22,1,1,5
673,17,22,1,7,
673,17,33,1,1,3
673,17,36,1,22,
673,17,38,1,40,
673,17,39,1,1,6
673,17,39,1,9,
673,17,46,4,0,
673,17,57,4,0,
673,17,63,4,0,
673,17,73,1,12,
673,17,74,1,1,4
673,17,75,1,13,
673,17,76,4,0,
673,17,89,1,1,2
673,17,89,4,0,
673,17,92,4,0,
673,17,104,4,0,
673,17,156,4,0,
673,17,157,4,0,
673,17,164,4,0,
673,17,182,4,0,
673,17,207,4,0,
673,17,208,1,58,
673,17,213,4,0,
673,17,214,4,0,
673,17,216,4,0,
673,17,218,4,0,
673,17,235,1,20,
673,17,237,4,0,
673,17,240,4,0,
673,17,241,4,0,
673,17,263,4,0,
673,17,267,4,0,
673,17,280,4,0,
673,17,332,1,0,
673,17,332,1,1,1
673,17,332,4,0,
673,17,339,1,34,
673,17,339,4,0,
673,17,348,1,55,
673,17,371,4,0,
673,17,388,1,16,
673,17,402,1,30,
673,17,412,4,0,
673,17,416,4,0,
673,17,447,4,0,
673,17,496,4,0,
673,17,523,1,26,
673,17,523,4,0,
673,17,526,4,0,
673,17,528,4,0,
673,17,532,1,47,
673,17,590,4,0,
673,18,22,1,1,5
673,18,22,1,7,
673,18,33,1,1,3
673,18,36,1,22,
673,18,38,1,40,
673,18,39,1,1,6
673,18,39,1,9,
673,18,46,4,0,
673,18,57,4,0,
673,18,63,4,0,
673,18,73,1,12,
673,18,74,1,1,4
673,18,75,1,13,
673,18,76,4,0,
673,18,89,1,1,2
673,18,89,4,0,
673,18,92,4,0,
673,18,104,4,0,
673,18,156,4,0,
673,18,157,4,0,
673,18,164,4,0,
673,18,182,4,0,
673,18,207,4,0,
673,18,208,1,58,
673,18,213,4,0,
673,18,214,4,0,
673,18,216,4,0,
673,18,218,4,0,
673,18,235,1,20,
673,18,237,4,0,
673,18,240,4,0,
673,18,241,4,0,
673,18,263,4,0,
673,18,267,4,0,
673,18,280,4,0,
673,18,332,1,0,
673,18,332,1,1,1
673,18,332,4,0,
673,18,339,1,34,
673,18,339,4,0,
673,18,348,1,55,
673,18,371,4,0,
673,18,388,1,16,
673,18,402,1,30,
673,18,412,4,0,
673,18,416,4,0,
673,18,447,4,0,
673,18,496,4,0,
673,18,523,1,26,
673,18,523,4,0,
673,18,526,4,0,
673,18,528,4,0,
673,18,532,1,47,
673,18,590,4,0,
674,15,2,1,12,
674,15,4,1,15,
674,15,14,4,0,
674,15,15,4,0,
674,15,33,1,1,1
674,15,34,1,33,
674,15,43,1,1,2
674,15,46,4,0,
674,15,57,4,0,
674,15,70,4,0,
674,15,91,4,0,
674,15,92,4,0,
674,15,104,4,0,
674,15,156,4,0,
674,15,157,4,0,
674,15,163,1,20,
674,15,164,4,0,
674,15,182,4,0,
674,15,188,4,0,
674,15,206,4,0,
674,15,207,4,0,
674,15,213,4,0,
674,15,214,4,0,
674,15,216,4,0,
674,15,218,4,0,
674,15,233,1,27,
674,15,237,4,0,
674,15,240,4,0,
674,15,241,4,0,
674,15,242,1,39,
674,15,249,4,0,
674,15,259,4,0,
674,15,263,4,0,
674,15,280,4,0,
674,15,292,1,7,
674,15,317,4,0,
674,15,327,1,48,
674,15,332,4,0,
674,15,339,4,0,
674,15,371,4,0,
674,15,374,4,0,
674,15,382,2,0,
674,15,399,4,0,
674,15,421,4,0,
674,15,444,4,0,
674,15,447,4,0,
674,15,480,2,0,
674,15,490,4,0,
674,15,492,2,0,
674,15,494,1,42,
674,15,496,4,0,
674,15,497,4,0,
674,15,501,2,0,
674,15,509,1,25,
674,15,511,2,0,
674,15,514,4,0,
674,15,523,4,0,
674,15,526,1,10,
674,15,575,1,45,
674,15,590,4,0,
674,15,612,4,0,
674,16,2,1,12,1
674,16,4,1,15,1
674,16,7,3,0,
674,16,8,3,0,
674,16,9,3,0,
674,16,14,4,0,
674,16,15,4,0,
674,16,33,1,1,1
674,16,34,1,33,1
674,16,43,1,1,2
674,16,46,4,0,
674,16,57,4,0,
674,16,67,3,0,
674,16,70,4,0,
674,16,91,4,0,
674,16,92,4,0,
674,16,104,4,0,
674,16,156,4,0,
674,16,157,4,0,
674,16,163,1,20,1
674,16,164,4,0,
674,16,173,3,0,
674,16,180,3,0,
674,16,182,4,0,
674,16,188,4,0,
674,16,206,4,0,
674,16,207,4,0,
674,16,213,4,0,
674,16,214,4,0,
674,16,216,4,0,
674,16,218,4,0,
674,16,233,1,27,1
674,16,237,4,0,
674,16,240,4,0,
674,16,241,4,0,
674,16,242,1,39,1
674,16,249,4,0,
674,16,253,3,0,
674,16,259,4,0,
674,16,263,4,0,
674,16,264,3,0,
674,16,270,3,0,
674,16,276,3,0,
674,16,280,4,0,
674,16,282,3,0,
674,16,283,3,0,
674,16,289,3,0,
674,16,290,4,0,
674,16,292,1,7,1
674,16,304,3,0,
674,16,317,4,0,
674,16,327,1,48,1
674,16,332,4,0,
674,16,335,3,0,
674,16,339,4,0,
674,16,343,3,0,
674,16,371,4,0,
674,16,374,4,0,
674,16,382,2,0,
674,16,399,4,0,
674,16,409,3,0,
674,16,421,4,0,
674,16,428,3,0,
674,16,441,3,0,
674,16,442,3,0,
674,16,444,4,0,
674,16,447,4,0,
674,16,480,2,0,
674,16,490,4,0,
674,16,492,2,0,
674,16,492,3,0,
674,16,494,1,42,1
674,16,496,4,0,
674,16,497,4,0,
674,16,501,2,0,
674,16,509,1,25,1
674,16,511,2,0,
674,16,514,4,0,
674,16,523,4,0,
674,16,526,1,10,1
674,16,530,3,0,
674,16,575,1,45,1
674,16,590,4,0,
674,16,612,4,0,
674,17,2,1,12,
674,17,4,1,15,
674,17,14,4,0,
674,17,33,1,1,1
674,17,34,1,33,
674,17,43,1,1,2
674,17,46,4,0,
674,17,57,4,0,
674,17,92,4,0,
674,17,104,4,0,
674,17,156,4,0,
674,17,157,4,0,
674,17,163,1,20,
674,17,164,4,0,
674,17,182,4,0,
674,17,188,4,0,
674,17,206,4,0,
674,17,207,4,0,
674,17,213,4,0,
674,17,214,4,0,
674,17,216,4,0,
674,17,218,4,0,
674,17,233,1,27,
674,17,237,4,0,
674,17,240,4,0,
674,17,241,4,0,
674,17,242,1,39,
674,17,259,4,0,
674,17,263,4,0,
674,17,280,4,0,
674,17,292,1,7,
674,17,317,4,0,
674,17,327,1,48,
674,17,332,4,0,
674,17,339,4,0,
674,17,371,4,0,
674,17,374,4,0,
674,17,382,2,0,
674,17,399,4,0,
674,17,421,4,0,
674,17,444,4,0,
674,17,447,4,0,
674,17,480,2,0,
674,17,490,4,0,
674,17,492,2,0,
674,17,494,1,42,
674,17,496,4,0,
674,17,497,4,0,
674,17,501,2,0,
674,17,509,1,25,
674,17,511,2,0,
674,17,523,4,0,
674,17,526,1,10,
674,17,526,4,0,
674,17,575,1,45,
674,17,590,4,0,
674,17,681,2,0,
674,18,2,1,12,
674,18,4,1,15,
674,18,14,4,0,
674,18,33,1,1,1
674,18,34,1,33,
674,18,43,1,1,2
674,18,46,4,0,
674,18,57,4,0,
674,18,92,4,0,
674,18,104,4,0,
674,18,156,4,0,
674,18,157,4,0,
674,18,163,1,20,
674,18,164,4,0,
674,18,182,4,0,
674,18,188,4,0,
674,18,206,4,0,
674,18,207,4,0,
674,18,213,4,0,
674,18,214,4,0,
674,18,216,4,0,
674,18,218,4,0,
674,18,233,1,27,
674,18,237,4,0,
674,18,240,4,0,
674,18,241,4,0,
674,18,242,1,39,
674,18,259,4,0,
674,18,263,4,0,
674,18,280,4,0,
674,18,292,1,7,
674,18,317,4,0,
674,18,327,1,48,
674,18,332,4,0,
674,18,339,4,0,
674,18,371,4,0,
674,18,374,4,0,
674,18,382,2,0,
674,18,399,4,0,
674,18,421,4,0,
674,18,444,4,0,
674,18,447,4,0,
674,18,480,2,0,
674,18,490,4,0,
674,18,492,2,0,
674,18,494,1,42,
674,18,496,4,0,
674,18,497,4,0,
674,18,501,2,0,
674,18,509,1,25,
674,18,511,2,0,
674,18,523,4,0,
674,18,526,1,10,
674,18,526,4,0,
674,18,575,1,45,
674,18,590,4,0,
674,18,681,2,0,
675,15,2,1,12,
675,15,4,1,15,
675,15,14,4,0,
675,15,15,4,0,
675,15,33,1,1,3
675,15,34,1,35,
675,15,43,1,1,4
675,15,46,4,0,
675,15,57,4,0,
675,15,63,4,0,
675,15,70,4,0,
675,15,89,4,0,
675,15,91,4,0,
675,15,92,4,0,
675,15,104,4,0,
675,15,156,4,0,
675,15,157,4,0,
675,15,163,1,20,
675,15,164,4,0,
675,15,168,4,0,
675,15,182,4,0,
675,15,188,4,0,
675,15,206,4,0,
675,15,207,4,0,
675,15,213,4,0,
675,15,214,4,0,
675,15,216,4,0,
675,15,218,4,0,
675,15,233,1,27,
675,15,237,4,0,
675,15,240,4,0,
675,15,241,4,0,
675,15,242,1,42,
675,15,249,4,0,
675,15,259,4,0,
675,15,263,4,0,
675,15,269,1,65,
675,15,269,4,0,
675,15,280,4,0,
675,15,292,1,7,
675,15,317,4,0,
675,15,327,1,52,
675,15,332,4,0,
675,15,337,4,0,
675,15,339,4,0,
675,15,359,1,1,2
675,15,359,1,57,
675,15,371,4,0,
675,15,373,4,0,
675,15,374,4,0,
675,15,398,4,0,
675,15,399,4,0,
675,15,404,4,0,
675,15,411,4,0,
675,15,416,4,0,
675,15,421,4,0,
675,15,444,4,0,
675,15,447,4,0,
675,15,468,4,0,
675,15,490,1,70,
675,15,490,4,0,
675,15,494,1,1,1
675,15,494,1,45,
675,15,496,4,0,
675,15,497,4,0,
675,15,509,1,25,
675,15,511,4,0,
675,15,514,4,0,
675,15,523,4,0,
675,15,526,1,10,
675,15,555,4,0,
675,15,575,1,48,
675,15,590,4,0,
675,15,611,4,0,
675,15,612,4,0,
675,16,2,1,12,1
675,16,4,1,15,1
675,16,7,3,0,
675,16,8,3,0,
675,16,9,3,0,
675,16,14,4,0,
675,16,15,4,0,
675,16,33,1,1,3
675,16,34,1,35,1
675,16,43,1,1,4
675,16,46,4,0,
675,16,57,4,0,
675,16,63,4,0,
675,16,67,3,0,
675,16,70,4,0,
675,16,89,4,0,
675,16,91,4,0,
675,16,92,4,0,
675,16,104,4,0,
675,16,156,4,0,
675,16,157,4,0,
675,16,163,1,20,1
675,16,164,4,0,
675,16,168,4,0,
675,16,173,3,0,
675,16,180,3,0,
675,16,182,4,0,
675,16,188,4,0,
675,16,200,3,0,
675,16,206,4,0,
675,16,207,4,0,
675,16,213,4,0,
675,16,214,4,0,
675,16,216,4,0,
675,16,218,4,0,
675,16,233,1,27,1
675,16,237,4,0,
675,16,240,4,0,
675,16,241,4,0,
675,16,242,1,42,1
675,16,249,4,0,
675,16,253,3,0,
675,16,259,4,0,
675,16,263,4,0,
675,16,264,3,0,
675,16,269,1,65,1
675,16,269,4,0,
675,16,270,3,0,
675,16,276,3,0,
675,16,280,4,0,
675,16,282,3,0,
675,16,283,3,0,
675,16,289,3,0,
675,16,290,4,0,
675,16,292,1,7,1
675,16,304,3,0,
675,16,317,4,0,
675,16,327,1,52,1
675,16,332,4,0,
675,16,335,3,0,
675,16,337,4,0,
675,16,339,4,0,
675,16,343,3,0,
675,16,359,1,1,2
675,16,359,1,57,1
675,16,371,4,0,
675,16,373,4,0,
675,16,374,4,0,
675,16,398,4,0,
675,16,399,4,0,
675,16,404,4,0,
675,16,409,3,0,
675,16,411,4,0,
675,16,416,4,0,
675,16,421,4,0,
675,16,428,3,0,
675,16,441,3,0,
675,16,442,3,0,
675,16,444,4,0,
675,16,447,4,0,
675,16,468,4,0,
675,16,490,1,70,1
675,16,490,4,0,
675,16,492,3,0,
675,16,494,1,1,1
675,16,494,1,45,1
675,16,496,4,0,
675,16,497,4,0,
675,16,509,1,25,1
675,16,511,4,0,
675,16,514,4,0,
675,16,523,4,0,
675,16,526,1,10,1
675,16,530,3,0,
675,16,555,4,0,
675,16,575,1,48,1
675,16,590,4,0,
675,16,611,4,0,
675,16,612,4,0,
675,17,2,1,12,
675,17,4,1,15,
675,17,14,4,0,
675,17,33,1,1,5
675,17,34,1,35,
675,17,43,1,1,6
675,17,46,4,0,
675,17,57,4,0,
675,17,63,4,0,
675,17,89,4,0,
675,17,92,4,0,
675,17,104,4,0,
675,17,156,4,0,
675,17,157,4,0,
675,17,163,1,20,
675,17,164,4,0,
675,17,168,4,0,
675,17,182,4,0,
675,17,188,4,0,
675,17,206,4,0,
675,17,207,4,0,
675,17,213,4,0,
675,17,214,4,0,
675,17,216,4,0,
675,17,218,4,0,
675,17,233,1,27,
675,17,237,4,0,
675,17,240,4,0,
675,17,241,4,0,
675,17,242,1,42,
675,17,259,4,0,
675,17,263,4,0,
675,17,269,1,65,
675,17,269,4,0,
675,17,280,4,0,
675,17,292,1,1,7
675,17,292,1,7,
675,17,317,4,0,
675,17,327,1,52,
675,17,332,4,0,
675,17,337,4,0,
675,17,339,4,0,
675,17,359,1,1,2
675,17,359,1,70,
675,17,371,4,0,
675,17,373,4,0,
675,17,374,4,0,
675,17,398,4,0,
675,17,399,4,0,
675,17,404,4,0,
675,17,411,4,0,
675,17,416,4,0,
675,17,418,1,0,
675,17,418,1,1,1
675,17,421,4,0,
675,17,444,4,0,
675,17,447,4,0,
675,17,490,1,1,3
675,17,490,1,57,
675,17,490,4,0,
675,17,494,1,1,4
675,17,494,1,45,
675,17,496,4,0,
675,17,497,4,0,
675,17,509,1,25,
675,17,511,4,0,
675,17,523,4,0,
675,17,526,1,1,8
675,17,526,1,10,
675,17,526,4,0,
675,17,555,4,0,
675,17,575,1,48,
675,17,590,4,0,
675,17,611,4,0,
675,18,2,1,12,
675,18,4,1,15,
675,18,14,4,0,
675,18,33,1,1,5
675,18,34,1,35,
675,18,43,1,1,6
675,18,46,4,0,
675,18,57,4,0,
675,18,63,4,0,
675,18,89,4,0,
675,18,92,4,0,
675,18,104,4,0,
675,18,156,4,0,
675,18,157,4,0,
675,18,163,1,20,
675,18,164,4,0,
675,18,168,4,0,
675,18,182,4,0,
675,18,188,4,0,
675,18,206,4,0,
675,18,207,4,0,
675,18,213,4,0,
675,18,214,4,0,
675,18,216,4,0,
675,18,218,4,0,
675,18,233,1,27,
675,18,237,4,0,
675,18,240,4,0,
675,18,241,4,0,
675,18,242,1,42,
675,18,259,4,0,
675,18,263,4,0,
675,18,269,1,65,
675,18,269,4,0,
675,18,280,4,0,
675,18,292,1,1,7
675,18,292,1,7,
675,18,317,4,0,
675,18,327,1,52,
675,18,332,4,0,
675,18,337,4,0,
675,18,339,4,0,
675,18,359,1,1,2
675,18,359,1,70,
675,18,371,4,0,
675,18,373,4,0,
675,18,374,4,0,
675,18,398,4,0,
675,18,399,4,0,
675,18,404,4,0,
675,18,411,4,0,
675,18,416,4,0,
675,18,418,1,0,
675,18,418,1,1,1
675,18,421,4,0,
675,18,444,4,0,
675,18,447,4,0,
675,18,490,1,1,3
675,18,490,1,57,
675,18,490,4,0,
675,18,494,1,1,4
675,18,494,1,45,
675,18,496,4,0,
675,18,497,4,0,
675,18,509,1,25,
675,18,511,4,0,
675,18,523,4,0,
675,18,526,1,1,8
675,18,526,1,10,
675,18,526,4,0,
675,18,555,4,0,
675,18,575,1,48,
675,18,590,4,0,
675,18,611,4,0,
676,15,28,1,5,
676,15,29,1,12,
676,15,33,1,1,1
676,15,36,1,35,
676,15,39,1,15,
676,15,44,1,22,
676,15,45,1,1,2
676,15,46,4,0,
676,15,57,4,0,
676,15,86,4,0,
676,15,91,4,0,
676,15,92,4,0,
676,15,102,2,0,
676,15,104,4,0,
676,15,148,4,0,
676,15,156,4,0,
676,15,164,4,0,
676,15,182,4,0,
676,15,204,1,38,
676,15,207,4,0,
676,15,213,4,0,
676,15,214,4,0,
676,15,216,4,0,
676,15,218,4,0,
676,15,237,4,0,
676,15,240,4,0,
676,15,241,4,0,
676,15,249,4,0,
676,15,263,4,0,
676,15,272,2,0,
676,15,287,2,0,
676,15,316,1,27,
676,15,369,4,0,
676,15,389,1,42,
676,15,399,4,0,
676,15,416,4,0,
676,15,445,2,0,
676,15,447,4,0,
676,15,451,4,0,
676,15,496,4,0,
676,15,497,4,0,
676,15,514,1,33,
676,15,514,4,0,
676,15,526,2,0,
676,15,528,4,0,
676,15,538,1,48,
676,15,555,4,0,
676,15,590,4,0,
676,15,608,1,9,
676,16,28,1,5,1
676,16,29,1,12,1
676,16,33,1,1,1
676,16,36,1,35,1
676,16,39,1,15,1
676,16,44,1,22,1
676,16,45,1,1,2
676,16,46,4,0,
676,16,57,4,0,
676,16,86,4,0,
676,16,91,4,0,
676,16,92,4,0,
676,16,102,2,0,
676,16,104,4,0,
676,16,148,4,0,
676,16,156,4,0,
676,16,164,4,0,
676,16,173,3,0,
676,16,182,4,0,
676,16,204,1,38,1
676,16,207,4,0,
676,16,213,4,0,
676,16,214,4,0,
676,16,216,4,0,
676,16,218,4,0,
676,16,231,3,0,
676,16,237,4,0,
676,16,240,4,0,
676,16,241,4,0,
676,16,249,4,0,
676,16,253,3,0,
676,16,263,4,0,
676,16,270,3,0,
676,16,272,2,0,
676,16,272,3,0,
676,16,283,3,0,
676,16,287,2,0,
676,16,290,4,0,
676,16,304,3,0,
676,16,316,1,27,1
676,16,369,4,0,
676,16,387,3,0,
676,16,389,1,42,1
676,16,399,4,0,
676,16,416,4,0,
676,16,428,3,0,
676,16,445,2,0,
676,16,447,4,0,
676,16,451,4,0,
676,16,496,4,0,
676,16,497,4,0,
676,16,514,1,33,1
676,16,514,4,0,
676,16,526,2,0,
676,16,528,4,0,
676,16,538,1,48,1
676,16,555,4,0,
676,16,590,4,0,
676,16,608,1,9,1
676,17,28,1,5,
676,17,29,1,12,
676,17,33,1,1,1
676,17,36,1,35,
676,17,39,1,15,
676,17,44,1,22,
676,17,45,1,1,2
676,17,46,4,0,
676,17,57,4,0,
676,17,86,4,0,
676,17,92,4,0,
676,17,102,2,0,
676,17,104,4,0,
676,17,156,4,0,
676,17,164,4,0,
676,17,182,4,0,
676,17,204,1,38,
676,17,207,4,0,
676,17,213,4,0,
676,17,214,4,0,
676,17,216,4,0,
676,17,218,4,0,
676,17,237,4,0,
676,17,240,4,0,
676,17,241,4,0,
676,17,263,4,0,
676,17,272,2,0,
676,17,287,2,0,
676,17,316,1,27,
676,17,369,4,0,
676,17,389,1,42,
676,17,399,4,0,
676,17,416,4,0,
676,17,445,2,0,
676,17,447,4,0,
676,17,451,4,0,
676,17,496,4,0,
676,17,497,4,0,
676,17,514,1,33,
676,17,526,2,0,
676,17,526,4,0,
676,17,528,4,0,
676,17,538,1,48,
676,17,555,4,0,
676,17,590,4,0,
676,17,608,1,9,
676,18,28,1,5,
676,18,29,1,12,
676,18,33,1,1,1
676,18,36,1,35,
676,18,39,1,15,
676,18,44,1,22,
676,18,45,1,1,2
676,18,46,4,0,
676,18,57,4,0,
676,18,86,4,0,
676,18,92,4,0,
676,18,102,2,0,
676,18,104,4,0,
676,18,156,4,0,
676,18,164,4,0,
676,18,182,4,0,
676,18,204,1,38,
676,18,207,4,0,
676,18,213,4,0,
676,18,214,4,0,
676,18,216,4,0,
676,18,218,4,0,
676,18,237,4,0,
676,18,240,4,0,
676,18,241,4,0,
676,18,263,4,0,
676,18,272,2,0,
676,18,287,2,0,
676,18,316,1,27,
676,18,369,4,0,
676,18,389,1,42,
676,18,399,4,0,
676,18,416,4,0,
676,18,445,2,0,
676,18,447,4,0,
676,18,451,4,0,
676,18,496,4,0,
676,18,497,4,0,
676,18,514,1,33,
676,18,526,2,0,
676,18,526,4,0,
676,18,528,4,0,
676,18,538,1,48,
676,18,555,4,0,
676,18,590,4,0,
676,18,608,1,9,
677,15,10,1,1,1
677,15,15,4,0,
677,15,43,1,1,2
677,15,60,1,17,
677,15,85,4,0,
677,15,86,4,0,
677,15,92,4,0,
677,15,93,1,9,
677,15,94,4,0,
677,15,104,4,0,
677,15,112,2,0,
677,15,113,1,13,
677,15,113,4,0,
677,15,115,4,0,
677,15,138,4,0,
677,15,148,4,0,
677,15,156,4,0,
677,15,164,4,0,
677,15,182,4,0,
677,15,207,4,0,
677,15,213,4,0,
677,15,214,4,0,
677,15,216,4,0,
677,15,218,4,0,
677,15,219,4,0,
677,15,237,4,0,
677,15,240,4,0,
677,15,241,4,0,
677,15,244,4,0,
677,15,252,1,19,
677,15,259,4,0,
677,15,263,4,0,
677,15,271,2,0,
677,15,274,2,0,
677,15,281,2,0,
677,15,343,1,5,
677,15,347,4,0,
677,15,371,4,0,
677,15,399,4,0,
677,15,412,4,0,
677,15,433,4,0,
677,15,451,4,0,
677,15,473,1,25,
677,15,473,4,0,
677,15,496,4,0,
677,15,497,4,0,
677,15,574,1,22,
677,15,590,4,0,
677,16,10,1,1,1
677,16,15,4,0,
677,16,43,1,1,2
677,16,60,1,17,1
677,16,85,4,0,
677,16,86,4,0,
677,16,92,4,0,
677,16,93,1,9,1
677,16,94,4,0,
677,16,104,4,0,
677,16,112,2,0,
677,16,113,1,13,1
677,16,113,4,0,
677,16,115,4,0,
677,16,138,4,0,
677,16,148,4,0,
677,16,156,4,0,
677,16,164,4,0,
677,16,173,3,0,
677,16,182,4,0,
677,16,207,4,0,
677,16,213,4,0,
677,16,214,4,0,
677,16,215,3,0,
677,16,216,4,0,
677,16,218,4,0,
677,16,219,4,0,
677,16,231,3,0,
677,16,237,4,0,
677,16,240,4,0,
677,16,241,4,0,
677,16,244,4,0,
677,16,252,1,19,1
677,16,259,4,0,
677,16,263,4,0,
677,16,270,3,0,
677,16,271,2,0,
677,16,271,3,0,
677,16,272,3,0,
677,16,274,2,0,
677,16,277,3,0,
677,16,278,3,0,
677,16,281,2,0,
677,16,289,3,0,
677,16,290,4,0,
677,16,324,3,0,
677,16,343,1,5,1
677,16,343,3,0,
677,16,347,4,0,
677,16,351,3,0,
677,16,356,3,0,
677,16,371,4,0,
677,16,399,4,0,
677,16,412,4,0,
677,16,428,3,0,
677,16,433,4,0,
677,16,451,4,0,
677,16,472,3,0,
677,16,473,1,25,1
677,16,473,4,0,
677,16,478,3,0,
677,16,496,4,0,
677,16,497,4,0,
677,16,574,1,22,1
677,16,590,4,0,
677,17,10,1,1,1
677,17,43,1,1,2
677,17,60,1,17,
677,17,85,4,0,
677,17,86,4,0,
677,17,92,4,0,
677,17,93,1,9,
677,17,94,4,0,
677,17,104,4,0,
677,17,112,2,0,
677,17,113,1,13,
677,17,113,4,0,
677,17,115,4,0,
677,17,138,4,0,
677,17,156,4,0,
677,17,164,4,0,
677,17,182,4,0,
677,17,207,4,0,
677,17,213,4,0,
677,17,214,4,0,
677,17,216,4,0,
677,17,218,4,0,
677,17,219,4,0,
677,17,237,4,0,
677,17,240,4,0,
677,17,241,4,0,
677,17,244,4,0,
677,17,252,1,19,
677,17,259,4,0,
677,17,263,4,0,
677,17,271,2,0,
677,17,274,2,0,
677,17,281,2,0,
677,17,343,1,5,
677,17,347,4,0,
677,17,371,4,0,
677,17,399,4,0,
677,17,412,4,0,
677,17,433,4,0,
677,17,451,4,0,
677,17,473,1,25,
677,17,473,4,0,
677,17,496,4,0,
677,17,497,4,0,
677,17,526,4,0,
677,17,574,1,22,
677,17,590,4,0,
677,18,10,1,1,1
677,18,43,1,1,2
677,18,60,1,17,
677,18,85,4,0,
677,18,86,4,0,
677,18,92,4,0,
677,18,93,1,9,
677,18,94,4,0,
677,18,104,4,0,
677,18,112,2,0,
677,18,113,1,13,
677,18,113,4,0,
677,18,115,4,0,
677,18,138,4,0,
677,18,156,4,0,
677,18,164,4,0,
677,18,182,4,0,
677,18,207,4,0,
677,18,213,4,0,
677,18,214,4,0,
677,18,216,4,0,
677,18,218,4,0,
677,18,219,4,0,
677,18,237,4,0,
677,18,240,4,0,
677,18,241,4,0,
677,18,244,4,0,
677,18,252,1,19,
677,18,259,4,0,
677,18,263,4,0,
677,18,271,2,0,
677,18,274,2,0,
677,18,281,2,0,
677,18,343,1,5,
677,18,347,4,0,
677,18,371,4,0,
677,18,399,4,0,
677,18,412,4,0,
677,18,433,4,0,
677,18,451,4,0,
677,18,473,1,25,
677,18,473,4,0,
677,18,496,4,0,
677,18,497,4,0,
677,18,526,4,0,
677,18,574,1,22,
677,18,590,4,0,
678,15,10,1,1,4
678,15,15,4,0,
678,15,43,1,1,5
678,15,60,1,17,
678,15,63,4,0,
678,15,85,4,0,
678,15,86,4,0,
678,15,91,4,0,
678,15,92,4,0,
678,15,93,1,9,
678,15,94,1,40,
678,15,94,4,0,
678,15,104,4,0,
678,15,113,1,13,
678,15,113,4,0,
678,15,115,1,35,
678,15,115,4,0,
678,15,138,4,0,
678,15,148,4,0,
678,15,156,4,0,
678,15,164,4,0,
678,15,182,4,0,
678,15,204,1,28,
678,15,207,4,0,
678,15,212,1,1,2
678,15,213,4,0,
678,15,214,4,0,
678,15,216,4,0,
678,15,218,4,0,
678,15,219,4,0,
678,15,237,4,0,
678,15,240,4,0,
678,15,241,4,0,
678,15,244,4,0,
678,15,247,4,0,
678,15,252,1,19,
678,15,259,4,0,
678,15,263,4,0,
678,15,270,1,1,3
678,15,272,1,43,
678,15,286,1,45,
678,15,343,1,5,
678,15,347,4,0,
678,15,357,1,31,
678,15,371,4,0,
678,15,389,1,48,
678,15,399,4,0,
678,15,412,4,0,
678,15,416,4,0,
678,15,433,4,0,
678,15,451,4,0,
678,15,473,1,25,
678,15,473,4,0,
678,15,496,4,0,
678,15,497,4,0,
678,15,501,1,1,1
678,15,501,1,53,
678,15,574,1,22,
678,15,581,1,50,
678,15,590,4,0,
678,15,612,4,0,
678,16,10,1,1,4
678,16,15,4,0,
678,16,43,1,1,5
678,16,60,1,17,1
678,16,63,4,0,
678,16,85,4,0,
678,16,86,4,0,
678,16,91,4,0,
678,16,92,4,0,
678,16,93,1,9,1
678,16,94,1,40,1
678,16,94,4,0,
678,16,104,4,0,
678,16,113,1,13,1
678,16,113,4,0,
678,16,115,1,35,1
678,16,115,4,0,
678,16,138,4,0,
678,16,148,4,0,
678,16,156,4,0,
678,16,164,4,0,
678,16,173,3,0,
678,16,182,4,0,
678,16,204,1,28,1
678,16,207,4,0,
678,16,212,1,1,2
678,16,213,4,0,
678,16,214,4,0,
678,16,215,3,0,
678,16,216,4,0,
678,16,218,4,0,
678,16,219,4,0,
678,16,231,3,0,
678,16,237,4,0,
678,16,240,4,0,
678,16,241,4,0,
678,16,244,4,0,
678,16,247,4,0,
678,16,252,1,19,1
678,16,259,4,0,
678,16,263,4,0,
678,16,270,1,1,3
678,16,270,3,0,
678,16,271,3,0,
678,16,272,1,43,1
678,16,272,3,0,
678,16,277,3,0,
678,16,278,3,0,
678,16,286,1,45,1
678,16,289,3,0,
678,16,290,4,0,
678,16,324,3,0,
678,16,343,1,5,1
678,16,343,3,0,
678,16,347,4,0,
678,16,351,3,0,
678,16,356,3,0,
678,16,357,1,31,1
678,16,371,4,0,
678,16,389,1,48,1
678,16,399,4,0,
678,16,412,4,0,
678,16,416,4,0,
678,16,428,3,0,
678,16,433,4,0,
678,16,451,4,0,
678,16,472,3,0,
678,16,473,1,25,1
678,16,473,4,0,
678,16,478,3,0,
678,16,496,4,0,
678,16,497,4,0,
678,16,501,1,1,1
678,16,501,1,53,1
678,16,574,1,22,1
678,16,581,1,50,1
678,16,590,4,0,
678,16,612,4,0,
678,17,10,1,1,4
678,17,43,1,1,5
678,17,60,1,17,
678,17,63,4,0,
678,17,85,4,0,
678,17,86,4,0,
678,17,92,4,0,
678,17,93,1,1,7
678,17,93,1,9,
678,17,94,1,40,
678,17,94,4,0,
678,17,104,4,0,
678,17,113,1,13,
678,17,113,4,0,
678,17,115,1,35,
678,17,115,4,0,
678,17,138,4,0,
678,17,156,4,0,
678,17,164,4,0,
678,17,182,4,0,
678,17,204,1,28,
678,17,207,4,0,
678,17,212,1,1,2
678,17,213,4,0,
678,17,214,4,0,
678,17,216,4,0,
678,17,218,4,0,
678,17,219,4,0,
678,17,237,4,0,
678,17,240,4,0,
678,17,241,4,0,
678,17,244,4,0,
678,17,247,4,0,
678,17,252,1,19,
678,17,259,4,0,
678,17,263,4,0,
678,17,270,1,1,3
678,17,272,1,43,
678,17,286,1,45,
678,17,343,1,1,6
678,17,343,1,5,
678,17,347,4,0,
678,17,357,1,31,
678,17,371,4,0,
678,17,389,1,48,
678,17,399,4,0,
678,17,412,4,0,
678,17,416,4,0,
678,17,433,4,0,
678,17,451,4,0,
678,17,473,1,25,
678,17,473,4,0,
678,17,496,4,0,
678,17,497,4,0,
678,17,501,1,1,1
678,17,501,1,53,
678,17,526,4,0,
678,17,574,1,22,
678,17,581,1,50,
678,17,590,4,0,
678,18,10,1,1,4
678,18,43,1,1,5
678,18,60,1,17,
678,18,63,4,0,
678,18,85,4,0,
678,18,86,4,0,
678,18,92,4,0,
678,18,93,1,1,7
678,18,93,1,9,
678,18,94,1,40,
678,18,94,4,0,
678,18,104,4,0,
678,18,113,1,13,
678,18,113,4,0,
678,18,115,1,35,
678,18,115,4,0,
678,18,138,4,0,
678,18,156,4,0,
678,18,164,4,0,
678,18,182,4,0,
678,18,204,1,28,
678,18,207,4,0,
678,18,212,1,1,2
678,18,213,4,0,
678,18,214,4,0,
678,18,216,4,0,
678,18,218,4,0,
678,18,219,4,0,
678,18,237,4,0,
678,18,240,4,0,
678,18,241,4,0,
678,18,244,4,0,
678,18,247,4,0,
678,18,252,1,19,
678,18,259,4,0,
678,18,263,4,0,
678,18,270,1,1,3
678,18,272,1,43,
678,18,286,1,45,
678,18,343,1,1,6
678,18,343,1,5,
678,18,347,4,0,
678,18,357,1,31,
678,18,371,4,0,
678,18,389,1,48,
678,18,399,4,0,
678,18,412,4,0,
678,18,416,4,0,
678,18,433,4,0,
678,18,451,4,0,
678,18,473,1,25,
678,18,473,4,0,
678,18,496,4,0,
678,18,497,4,0,
678,18,501,1,1,1
678,18,501,1,53,
678,18,526,4,0,
678,18,574,1,22,
678,18,581,1,50,
678,18,590,4,0,
679,15,14,1,1,2
679,15,14,4,0,
679,15,15,4,0,
679,15,33,1,1,1
679,15,92,4,0,
679,15,104,4,0,
679,15,115,4,0,
679,15,156,4,0,
679,15,157,4,0,
679,15,163,1,29,
679,15,164,4,0,
679,15,182,4,0,
679,15,194,2,0,
679,15,206,4,0,
679,15,207,4,0,
679,15,210,1,5,
679,15,213,4,0,
679,15,214,4,0,
679,15,216,4,0,
679,15,218,4,0,
679,15,228,1,13,
679,15,237,4,0,
679,15,240,4,0,
679,15,249,4,0,
679,15,263,4,0,
679,15,280,4,0,
679,15,319,1,8,
679,15,319,2,0,
679,15,332,1,22,
679,15,332,4,0,
679,15,334,1,32,
679,15,360,4,0,
679,15,379,1,39,
679,15,400,1,35,
679,15,421,4,0,
679,15,425,1,20,
679,15,425,2,0,
679,15,430,4,0,
679,15,442,1,42,
679,15,469,2,0,
679,15,475,1,18,
679,15,514,1,26,
679,15,514,4,0,
679,15,533,1,47,
679,15,590,4,0,
679,16,14,1,1,2
679,16,14,4,0,
679,16,15,4,0,
679,16,33,1,1,1
679,16,92,4,0,
679,16,104,4,0,
679,16,115,4,0,
679,16,156,4,0,
679,16,157,4,0,
679,16,163,1,29,1
679,16,164,4,0,
679,16,173,3,0,
679,16,180,3,0,
679,16,182,4,0,
679,16,194,2,0,
679,16,206,4,0,
679,16,207,4,0,
679,16,210,1,5,1
679,16,213,4,0,
679,16,214,4,0,
679,16,216,4,0,
679,16,218,4,0,
679,16,228,1,13,1
679,16,237,4,0,
679,16,240,4,0,
679,16,249,4,0,
679,16,263,4,0,
679,16,280,4,0,
679,16,290,4,0,
679,16,319,1,8,1
679,16,319,2,0,
679,16,332,1,22,1
679,16,332,4,0,
679,16,334,1,32,1
679,16,334,3,0,
679,16,351,3,0,
679,16,360,4,0,
679,16,379,1,39,1
679,16,393,3,0,
679,16,400,1,35,1
679,16,421,4,0,
679,16,425,1,20,1
679,16,425,2,0,
679,16,430,4,0,
679,16,442,1,42,1
679,16,442,3,0,
679,16,469,2,0,
679,16,475,1,18,1
679,16,495,3,0,
679,16,514,1,26,1
679,16,514,4,0,
679,16,533,1,47,1
679,16,590,4,0,
679,17,14,1,1,2
679,17,14,4,0,
679,17,33,1,1,1
679,17,92,4,0,
679,17,104,4,0,
679,17,115,4,0,
679,17,156,4,0,
679,17,157,4,0,
679,17,163,1,29,
679,17,164,4,0,
679,17,182,4,0,
679,17,194,2,0,
679,17,206,4,0,
679,17,207,4,0,
679,17,210,1,5,
679,17,213,4,0,
679,17,214,4,0,
679,17,216,4,0,
679,17,218,4,0,
679,17,228,1,13,
679,17,237,4,0,
679,17,240,4,0,
679,17,263,4,0,
679,17,280,4,0,
679,17,319,1,8,
679,17,319,2,0,
679,17,332,1,22,
679,17,332,4,0,
679,17,334,1,32,
679,17,360,4,0,
679,17,379,1,39,
679,17,400,1,35,
679,17,421,4,0,
679,17,425,1,20,
679,17,425,2,0,
679,17,430,4,0,
679,17,442,1,42,
679,17,469,2,0,
679,17,475,1,18,
679,17,514,1,26,
679,17,533,1,47,
679,17,590,4,0,
679,17,693,4,0,
679,18,14,1,1,2
679,18,14,4,0,
679,18,33,1,1,1
679,18,92,4,0,
679,18,104,4,0,
679,18,115,4,0,
679,18,156,4,0,
679,18,157,4,0,
679,18,163,1,29,
679,18,164,4,0,
679,18,182,4,0,
679,18,194,2,0,
679,18,206,4,0,
679,18,207,4,0,
679,18,210,1,5,
679,18,213,4,0,
679,18,214,4,0,
679,18,216,4,0,
679,18,218,4,0,
679,18,228,1,13,
679,18,237,4,0,
679,18,240,4,0,
679,18,263,4,0,
679,18,280,4,0,
679,18,319,1,8,
679,18,319,2,0,
679,18,332,1,22,
679,18,332,4,0,
679,18,334,1,32,
679,18,360,4,0,
679,18,379,1,39,
679,18,400,1,35,
679,18,421,4,0,
679,18,425,1,20,
679,18,425,2,0,
679,18,430,4,0,
679,18,442,1,42,
679,18,469,2,0,
679,18,475,1,18,
679,18,514,1,26,
679,18,533,1,47,
679,18,590,4,0,
679,18,693,4,0,
680,15,14,1,1,2
680,15,14,4,0,
680,15,15,4,0,
680,15,33,1,1,1
680,15,92,4,0,
680,15,104,4,0,
680,15,115,4,0,
680,15,156,4,0,
680,15,157,4,0,
680,15,163,1,29,
680,15,164,4,0,
680,15,182,4,0,
680,15,206,4,0,
680,15,207,4,0,
680,15,210,1,5,
680,15,213,4,0,
680,15,214,4,0,
680,15,216,4,0,
680,15,218,4,0,
680,15,228,1,13,
680,15,237,4,0,
680,15,240,4,0,
680,15,249,4,0,
680,15,263,4,0,
680,15,280,4,0,
680,15,319,1,8,
680,15,332,1,22,
680,15,332,4,0,
680,15,334,1,32,
680,15,360,4,0,
680,15,379,1,41,
680,15,400,1,36,
680,15,421,4,0,
680,15,425,1,20,
680,15,430,4,0,
680,15,442,1,45,
680,15,475,1,18,
680,15,514,1,26,
680,15,514,4,0,
680,15,533,1,51,
680,15,590,4,0,
680,16,14,1,1,2
680,16,14,4,0,
680,16,15,4,0,
680,16,33,1,1,1
680,16,92,4,0,
680,16,104,4,0,
680,16,115,4,0,
680,16,156,4,0,
680,16,157,4,0,
680,16,163,1,29,1
680,16,164,4,0,
680,16,173,3,0,
680,16,180,3,0,
680,16,182,4,0,
680,16,206,4,0,
680,16,207,4,0,
680,16,210,1,5,1
680,16,213,4,0,
680,16,214,4,0,
680,16,216,4,0,
680,16,218,4,0,
680,16,228,1,13,1
680,16,237,4,0,
680,16,240,4,0,
680,16,249,4,0,
680,16,263,4,0,
680,16,280,4,0,
680,16,290,4,0,
680,16,319,1,8,1
680,16,332,1,22,1
680,16,332,4,0,
680,16,334,1,32,1
680,16,334,3,0,
680,16,351,3,0,
680,16,360,4,0,
680,16,379,1,41,1
680,16,393,3,0,
680,16,400,1,36,1
680,16,421,4,0,
680,16,425,1,20,1
680,16,430,4,0,
680,16,442,1,45,1
680,16,442,3,0,
680,16,475,1,18,1
680,16,495,3,0,
680,16,514,1,26,1
680,16,514,4,0,
680,16,533,1,51,1
680,16,590,4,0,
680,17,14,1,1,2
680,17,14,4,0,
680,17,33,1,1,1
680,17,92,4,0,
680,17,104,4,0,
680,17,115,4,0,
680,17,156,4,0,
680,17,157,4,0,
680,17,163,1,29,
680,17,164,4,0,
680,17,182,4,0,
680,17,206,4,0,
680,17,207,4,0,
680,17,210,1,1,3
680,17,210,1,5,
680,17,213,4,0,
680,17,214,4,0,
680,17,216,4,0,
680,17,218,4,0,
680,17,228,1,13,
680,17,237,4,0,
680,17,240,4,0,
680,17,263,4,0,
680,17,280,4,0,
680,17,319,1,8,
680,17,332,1,22,
680,17,332,4,0,
680,17,334,1,32,
680,17,360,4,0,
680,17,379,1,41,
680,17,400,1,36,
680,17,421,4,0,
680,17,425,1,20,
680,17,430,4,0,
680,17,442,1,45,
680,17,475,1,18,
680,17,514,1,26,
680,17,533,1,51,
680,17,590,4,0,
680,17,693,4,0,
680,18,14,1,1,2
680,18,14,4,0,
680,18,33,1,1,1
680,18,92,4,0,
680,18,104,4,0,
680,18,115,4,0,
680,18,156,4,0,
680,18,157,4,0,
680,18,163,1,29,
680,18,164,4,0,
680,18,182,4,0,
680,18,206,4,0,
680,18,207,4,0,
680,18,210,1,1,3
680,18,210,1,5,
680,18,213,4,0,
680,18,214,4,0,
680,18,216,4,0,
680,18,218,4,0,
680,18,228,1,13,
680,18,237,4,0,
680,18,240,4,0,
680,18,263,4,0,
680,18,280,4,0,
680,18,319,1,8,
680,18,332,1,22,
680,18,332,4,0,
680,18,334,1,32,
680,18,360,4,0,
680,18,379,1,41,
680,18,400,1,36,
680,18,421,4,0,
680,18,425,1,20,
680,18,430,4,0,
680,18,442,1,45,
680,18,475,1,18,
680,18,514,1,26,
680,18,533,1,51,
680,18,590,4,0,
680,18,693,4,0,
681,15,14,1,1,11
681,15,14,4,0,
681,15,15,4,0,
681,15,63,4,0,
681,15,92,4,0,
681,15,104,4,0,
681,15,115,4,0,
681,15,156,4,0,
681,15,157,4,0,
681,15,163,1,1,5
681,15,164,4,0,
681,15,182,4,0,
681,15,206,4,0,
681,15,207,4,0,
681,15,210,1,1,1
681,15,213,4,0,
681,15,214,4,0,
681,15,216,4,0,
681,15,218,4,0,
681,15,228,1,1,2
681,15,237,4,0,
681,15,240,4,0,
681,15,241,4,0,
681,15,247,4,0,
681,15,249,4,0,
681,15,263,4,0,
681,15,280,4,0,
681,15,332,1,1,12
681,15,332,4,0,
681,15,334,1,1,6
681,15,360,4,0,
681,15,379,1,1,8
681,15,400,1,1,7
681,15,416,4,0,
681,15,421,4,0,
681,15,425,1,1,4
681,15,430,4,0,
681,15,442,1,1,9
681,15,457,1,1,10
681,15,475,1,1,3
681,15,496,4,0,
681,15,514,4,0,
681,15,533,1,1,14
681,15,588,1,1,13
681,15,590,4,0,
681,16,14,1,1,11
681,16,14,4,0,
681,16,15,4,0,
681,16,63,4,0,
681,16,92,4,0,
681,16,104,4,0,
681,16,115,4,0,
681,16,156,4,0,
681,16,157,4,0,
681,16,163,1,1,5
681,16,164,4,0,
681,16,173,3,0,
681,16,180,3,0,
681,16,182,4,0,
681,16,206,4,0,
681,16,207,4,0,
681,16,210,1,1,1
681,16,213,4,0,
681,16,214,4,0,
681,16,216,4,0,
681,16,218,4,0,
681,16,228,1,1,2
681,16,237,4,0,
681,16,240,4,0,
681,16,241,4,0,
681,16,247,4,0,
681,16,249,4,0,
681,16,263,4,0,
681,16,280,4,0,
681,16,290,4,0,
681,16,332,1,1,12
681,16,332,4,0,
681,16,334,1,1,6
681,16,334,3,0,
681,16,335,3,0,
681,16,351,3,0,
681,16,360,4,0,
681,16,379,1,1,8
681,16,393,3,0,
681,16,400,1,1,7
681,16,416,4,0,
681,16,421,4,0,
681,16,425,1,1,4
681,16,430,4,0,
681,16,442,1,1,9
681,16,442,3,0,
681,16,457,1,1,10
681,16,475,1,1,3
681,16,495,3,0,
681,16,496,4,0,
681,16,514,4,0,
681,16,533,1,1,14
681,16,588,1,1,13
681,16,590,4,0,
681,17,14,1,1,11
681,17,14,4,0,
681,17,63,4,0,
681,17,92,4,0,
681,17,104,4,0,
681,17,115,4,0,
681,17,156,4,0,
681,17,157,4,0,
681,17,163,1,1,5
681,17,164,4,0,
681,17,182,4,0,
681,17,206,4,0,
681,17,207,4,0,
681,17,210,1,1,1
681,17,213,4,0,
681,17,214,4,0,
681,17,216,4,0,
681,17,218,4,0,
681,17,228,1,1,2
681,17,237,4,0,
681,17,240,4,0,
681,17,241,4,0,
681,17,247,4,0,
681,17,263,4,0,
681,17,280,4,0,
681,17,332,1,1,12
681,17,332,4,0,
681,17,334,1,1,6
681,17,360,4,0,
681,17,379,1,1,8
681,17,400,1,1,7
681,17,416,4,0,
681,17,421,4,0,
681,17,425,1,1,4
681,17,430,4,0,
681,17,442,1,1,9
681,17,457,1,1,10
681,17,475,1,1,3
681,17,496,4,0,
681,17,533,1,1,14
681,17,588,1,1,13
681,17,590,4,0,
681,17,693,4,0,
681,18,14,1,1,11
681,18,14,4,0,
681,18,63,4,0,
681,18,92,4,0,
681,18,104,4,0,
681,18,115,4,0,
681,18,156,4,0,
681,18,157,4,0,
681,18,163,1,1,5
681,18,164,4,0,
681,18,182,4,0,
681,18,206,4,0,
681,18,207,4,0,
681,18,210,1,1,1
681,18,213,4,0,
681,18,214,4,0,
681,18,216,4,0,
681,18,218,4,0,
681,18,228,1,1,2
681,18,237,4,0,
681,18,240,4,0,
681,18,241,4,0,
681,18,247,4,0,
681,18,263,4,0,
681,18,280,4,0,
681,18,332,1,1,12
681,18,332,4,0,
681,18,334,1,1,6
681,18,360,4,0,
681,18,379,1,1,8
681,18,400,1,1,7
681,18,416,4,0,
681,18,421,4,0,
681,18,425,1,1,4
681,18,430,4,0,
681,18,442,1,1,9
681,18,457,1,1,10
681,18,475,1,1,3
681,18,496,4,0,
681,18,533,1,1,14
681,18,588,1,1,13
681,18,590,4,0,
681,18,693,4,0,
682,15,50,2,0,
682,15,85,4,0,
682,15,92,4,0,
682,15,94,1,48,
682,15,94,4,0,
682,15,104,4,0,
682,15,113,4,0,
682,15,115,4,0,
682,15,138,4,0,
682,15,148,4,0,
682,15,156,4,0,
682,15,164,4,0,
682,15,175,1,38,
682,15,182,4,0,
682,15,186,1,6,
682,15,204,1,35,
682,15,207,4,0,
682,15,213,1,29,
682,15,213,4,0,
682,15,214,4,0,
682,15,216,4,0,
682,15,218,4,0,
682,15,230,1,1,1
682,15,237,4,0,
682,15,240,4,0,
682,15,241,4,0,
682,15,244,4,0,
682,15,259,4,0,
682,15,263,4,0,
682,15,273,2,0,
682,15,285,1,44,
682,15,287,2,0,
682,15,312,1,25,
682,15,316,1,8,
682,15,347,1,17,
682,15,347,4,0,
682,15,360,4,0,
682,15,412,4,0,
682,15,430,4,0,
682,15,433,4,0,
682,15,445,2,0,
682,15,451,4,0,
682,15,496,4,0,
682,15,497,1,13,
682,15,497,4,0,
682,15,574,1,50,
682,15,577,1,21,
682,15,581,1,42,
682,15,584,1,1,2
682,15,585,1,31,
682,15,590,4,0,
682,15,605,4,0,
682,16,50,2,0,
682,16,85,4,0,
682,16,92,4,0,
682,16,94,1,48,1
682,16,94,4,0,
682,16,104,4,0,
682,16,113,4,0,
682,16,115,4,0,
682,16,138,4,0,
682,16,148,4,0,
682,16,156,4,0,
682,16,164,4,0,
682,16,173,3,0,
682,16,175,1,38,1
682,16,182,4,0,
682,16,186,1,6,1
682,16,204,1,35,1
682,16,207,4,0,
682,16,213,1,29,1
682,16,213,4,0,
682,16,214,4,0,
682,16,215,3,0,
682,16,216,4,0,
682,16,218,4,0,
682,16,230,1,1,1
682,16,237,4,0,
682,16,240,4,0,
682,16,241,4,0,
682,16,244,4,0,
682,16,259,4,0,
682,16,263,4,0,
682,16,270,3,0,
682,16,273,2,0,
682,16,277,3,0,
682,16,283,3,0,
682,16,285,1,44,1
682,16,285,3,0,
682,16,287,2,0,
682,16,290,4,0,
682,16,312,1,25,1
682,16,316,1,8,1
682,16,343,3,0,
682,16,347,1,17,1
682,16,347,4,0,
682,16,360,4,0,
682,16,412,4,0,
682,16,430,4,0,
682,16,433,4,0,
682,16,445,2,0,
682,16,451,4,0,
682,16,495,3,0,
682,16,496,4,0,
682,16,497,1,13,1
682,16,497,4,0,
682,16,574,1,50,1
682,16,577,1,21,1
682,16,581,1,42,1
682,16,584,1,1,2
682,16,585,1,31,1
682,16,590,4,0,
682,16,605,4,0,
682,17,50,2,0,
682,17,85,4,0,
682,17,92,4,0,
682,17,94,1,48,
682,17,94,4,0,
682,17,104,4,0,
682,17,113,4,0,
682,17,115,4,0,
682,17,138,4,0,
682,17,156,4,0,
682,17,164,4,0,
682,17,175,1,38,
682,17,182,4,0,
682,17,186,1,6,
682,17,204,1,35,
682,17,207,4,0,
682,17,213,1,29,
682,17,213,4,0,
682,17,214,4,0,
682,17,216,4,0,
682,17,218,4,0,
682,17,230,1,1,1
682,17,237,4,0,
682,17,240,4,0,
682,17,241,4,0,
682,17,244,4,0,
682,17,259,4,0,
682,17,263,4,0,
682,17,273,2,0,
682,17,285,1,44,
682,17,287,2,0,
682,17,312,1,25,
682,17,316,1,8,
682,17,347,1,17,
682,17,347,4,0,
682,17,360,4,0,
682,17,412,4,0,
682,17,430,4,0,
682,17,433,4,0,
682,17,445,2,0,
682,17,451,4,0,
682,17,496,4,0,
682,17,497,1,13,
682,17,497,4,0,
682,17,574,1,50,
682,17,577,1,21,
682,17,581,1,42,
682,17,584,1,1,2
682,17,585,1,31,
682,17,590,4,0,
682,17,605,4,0,
682,18,50,2,0,
682,18,85,4,0,
682,18,92,4,0,
682,18,94,1,48,
682,18,94,4,0,
682,18,104,4,0,
682,18,113,4,0,
682,18,115,4,0,
682,18,138,4,0,
682,18,156,4,0,
682,18,164,4,0,
682,18,175,1,38,
682,18,182,4,0,
682,18,186,1,6,
682,18,204,1,35,
682,18,207,4,0,
682,18,213,1,29,
682,18,213,4,0,
682,18,214,4,0,
682,18,216,4,0,
682,18,218,4,0,
682,18,230,1,1,1
682,18,237,4,0,
682,18,240,4,0,
682,18,241,4,0,
682,18,244,4,0,
682,18,259,4,0,
682,18,263,4,0,
682,18,273,2,0,
682,18,285,1,44,
682,18,287,2,0,
682,18,312,1,25,
682,18,316,1,8,
682,18,347,1,17,
682,18,347,4,0,
682,18,360,4,0,
682,18,412,4,0,
682,18,417,2,0,
682,18,430,4,0,
682,18,433,4,0,
682,18,445,2,0,
682,18,451,4,0,
682,18,496,4,0,
682,18,497,1,13,
682,18,497,4,0,
682,18,574,1,50,
682,18,577,1,21,
682,18,581,1,42,
682,18,584,1,1,2
682,18,585,1,31,
682,18,590,4,0,
682,18,605,4,0,
683,15,63,4,0,
683,15,85,4,0,
683,15,87,4,0,
683,15,92,4,0,
683,15,94,1,48,
683,15,94,4,0,
683,15,104,4,0,
683,15,113,4,0,
683,15,115,1,57,
683,15,115,4,0,
683,15,138,4,0,
683,15,148,4,0,
683,15,156,4,0,
683,15,164,4,0,
683,15,175,1,38,
683,15,182,4,0,
683,15,186,1,6,
683,15,204,1,35,
683,15,207,4,0,
683,15,213,1,29,
683,15,213,4,0,
683,15,214,4,0,
683,15,216,4,0,
683,15,218,4,0,
683,15,230,1,1,3
683,15,237,4,0,
683,15,240,4,0,
683,15,241,4,0,
683,15,244,1,64,
683,15,244,4,0,
683,15,259,4,0,
683,15,263,4,0,
683,15,285,1,44,
683,15,312,1,25,
683,15,316,1,8,
683,15,347,1,17,
683,15,347,4,0,
683,15,360,4,0,
683,15,412,4,0,
683,15,416,4,0,
683,15,430,4,0,
683,15,433,4,0,
683,15,451,4,0,
683,15,473,4,0,
683,15,496,4,0,
683,15,497,1,13,
683,15,497,4,0,
683,15,505,1,1,2
683,15,574,1,53,
683,15,577,1,21,
683,15,581,1,42,
683,15,584,1,1,4
683,15,585,1,31,
683,15,590,4,0,
683,15,597,1,1,1
683,15,605,4,0,
683,16,63,4,0,
683,16,85,4,0,
683,16,87,4,0,
683,16,92,4,0,
683,16,94,1,48,1
683,16,94,4,0,
683,16,104,4,0,
683,16,113,4,0,
683,16,115,1,57,1
683,16,115,4,0,
683,16,138,4,0,
683,16,148,4,0,
683,16,156,4,0,
683,16,164,4,0,
683,16,173,3,0,
683,16,175,1,38,1
683,16,182,4,0,
683,16,186,1,6,1
683,16,204,1,35,1
683,16,207,4,0,
683,16,213,1,29,1
683,16,213,4,0,
683,16,214,4,0,
683,16,215,3,0,
683,16,216,4,0,
683,16,218,4,0,
683,16,230,1,1,3
683,16,237,4,0,
683,16,240,4,0,
683,16,241,4,0,
683,16,244,1,64,1
683,16,244,4,0,
683,16,259,4,0,
683,16,263,4,0,
683,16,270,3,0,
683,16,277,3,0,
683,16,283,3,0,
683,16,285,1,44,1
683,16,285,3,0,
683,16,290,4,0,
683,16,312,1,25,1
683,16,316,1,8,1
683,16,343,3,0,
683,16,347,1,17,1
683,16,347,4,0,
683,16,360,4,0,
683,16,409,3,0,
683,16,412,4,0,
683,16,416,4,0,
683,16,430,4,0,
683,16,433,4,0,
683,16,451,4,0,
683,16,473,4,0,
683,16,495,3,0,
683,16,496,4,0,
683,16,497,1,13,1
683,16,497,4,0,
683,16,505,1,1,2
683,16,574,1,53,1
683,16,577,1,21,1
683,16,581,1,42,1
683,16,584,1,1,4
683,16,585,1,31,1
683,16,590,4,0,
683,16,597,1,1,1
683,16,605,4,0,
683,17,63,4,0,
683,17,85,4,0,
683,17,87,4,0,
683,17,92,4,0,
683,17,94,1,48,
683,17,94,4,0,
683,17,104,4,0,
683,17,113,4,0,
683,17,115,1,57,
683,17,115,4,0,
683,17,138,4,0,
683,17,156,4,0,
683,17,164,4,0,
683,17,175,1,38,
683,17,182,4,0,
683,17,186,1,1,5
683,17,186,1,6,
683,17,204,1,35,
683,17,207,4,0,
683,17,213,1,29,
683,17,213,4,0,
683,17,214,4,0,
683,17,216,4,0,
683,17,218,4,0,
683,17,230,1,1,3
683,17,237,4,0,
683,17,240,4,0,
683,17,241,4,0,
683,17,244,1,64,
683,17,244,4,0,
683,17,259,4,0,
683,17,263,4,0,
683,17,285,1,44,
683,17,312,1,25,
683,17,316,1,1,6
683,17,316,1,8,
683,17,347,1,17,
683,17,347,4,0,
683,17,360,4,0,
683,17,412,4,0,
683,17,416,4,0,
683,17,430,4,0,
683,17,433,4,0,
683,17,451,4,0,
683,17,473,4,0,
683,17,496,4,0,
683,17,497,1,13,
683,17,497,4,0,
683,17,505,1,1,2
683,17,574,1,53,
683,17,577,1,21,
683,17,581,1,42,
683,17,584,1,1,4
683,17,585,1,31,
683,17,590,4,0,
683,17,597,1,1,1
683,17,605,4,0,
683,18,63,4,0,
683,18,85,4,0,
683,18,87,4,0,
683,18,92,4,0,
683,18,94,1,48,
683,18,94,4,0,
683,18,104,4,0,
683,18,113,4,0,
683,18,115,1,57,
683,18,115,4,0,
683,18,138,4,0,
683,18,156,4,0,
683,18,164,4,0,
683,18,175,1,38,
683,18,182,4,0,
683,18,186,1,1,5
683,18,186,1,6,
683,18,204,1,35,
683,18,207,4,0,
683,18,213,1,29,
683,18,213,4,0,
683,18,214,4,0,
683,18,216,4,0,
683,18,218,4,0,
683,18,230,1,1,3
683,18,237,4,0,
683,18,240,4,0,
683,18,241,4,0,
683,18,244,1,64,
683,18,244,4,0,
683,18,259,4,0,
683,18,263,4,0,
683,18,285,1,44,
683,18,312,1,25,
683,18,316,1,1,6
683,18,316,1,8,
683,18,347,1,17,
683,18,347,4,0,
683,18,360,4,0,
683,18,412,4,0,
683,18,416,4,0,
683,18,430,4,0,
683,18,433,4,0,
683,18,451,4,0,
683,18,473,4,0,
683,18,496,4,0,
683,18,497,1,13,
683,18,497,4,0,
683,18,505,1,1,2
683,18,574,1,53,
683,18,577,1,21,
683,18,581,1,42,
683,18,584,1,1,4
683,18,585,1,31,
683,18,590,4,0,
683,18,597,1,1,1
683,18,605,4,0,
684,15,33,1,1,2
684,15,53,4,0,
684,15,57,4,0,
684,15,85,4,0,
684,15,92,4,0,
684,15,94,4,0,
684,15,104,4,0,
684,15,113,1,58,
684,15,113,4,0,
684,15,138,4,0,
684,15,148,4,0,
684,15,156,4,0,
684,15,164,4,0,
684,15,168,4,0,
684,15,178,1,17,
684,15,182,4,0,
684,15,187,2,0,
684,15,207,4,0,
684,15,213,4,0,
684,15,214,4,0,
684,15,216,4,0,
684,15,218,4,0,
684,15,219,1,67,
684,15,219,4,0,
684,15,230,1,1,1
684,15,237,4,0,
684,15,240,4,0,
684,15,241,4,0,
684,15,244,4,0,
684,15,263,4,0,
684,15,273,1,45,
684,15,281,2,0,
684,15,283,1,21,
684,15,312,1,26,
684,15,313,1,10,
684,15,347,4,0,
684,15,383,2,0,
684,15,412,1,36,
684,15,412,4,0,
684,15,495,2,0,
684,15,496,1,13,
684,15,496,4,0,
684,15,538,1,41,
684,15,577,1,31,
684,15,583,1,49,
684,15,584,1,5,
684,15,589,1,8,
684,15,590,4,0,
684,15,605,4,0,
684,16,33,1,1,2
684,16,53,4,0,
684,16,57,4,0,
684,16,85,4,0,
684,16,92,4,0,
684,16,94,4,0,
684,16,104,4,0,
684,16,113,1,58,1
684,16,113,4,0,
684,16,138,4,0,
684,16,148,4,0,
684,16,156,4,0,
684,16,164,4,0,
684,16,168,4,0,
684,16,173,3,0,
684,16,178,1,17,1
684,16,182,4,0,
684,16,187,2,0,
684,16,207,4,0,
684,16,213,4,0,
684,16,214,4,0,
684,16,215,3,0,
684,16,216,4,0,
684,16,218,4,0,
684,16,219,1,67,1
684,16,219,4,0,
684,16,230,1,1,1
684,16,237,4,0,
684,16,240,4,0,
684,16,241,4,0,
684,16,244,4,0,
684,16,263,4,0,
684,16,270,3,0,
684,16,273,1,45,1
684,16,277,3,0,
684,16,281,2,0,
684,16,283,1,21,1
684,16,283,3,0,
684,16,290,4,0,
684,16,312,1,26,1
684,16,313,1,10,1
684,16,343,3,0,
684,16,347,4,0,
684,16,380,3,0,
684,16,383,2,0,
684,16,412,1,36,1
684,16,412,4,0,
684,16,495,2,0,
684,16,495,3,0,
684,16,496,1,13,1
684,16,496,4,0,
684,16,538,1,41,1
684,16,577,1,31,1
684,16,583,1,49,1
684,16,584,1,5,1
684,16,589,1,8,1
684,16,590,4,0,
684,16,605,4,0,
684,17,33,1,1,2
684,17,53,4,0,
684,17,57,4,0,
684,17,85,4,0,
684,17,92,4,0,
684,17,94,4,0,
684,17,104,4,0,
684,17,113,1,58,
684,17,113,4,0,
684,17,138,4,0,
684,17,156,4,0,
684,17,164,4,0,
684,17,168,4,0,
684,17,178,1,17,
684,17,182,4,0,
684,17,187,2,0,
684,17,207,4,0,
684,17,213,4,0,
684,17,214,4,0,
684,17,216,4,0,
684,17,218,4,0,
684,17,219,1,67,
684,17,219,4,0,
684,17,230,1,1,1
684,17,237,4,0,
684,17,240,4,0,
684,17,241,4,0,
684,17,244,4,0,
684,17,263,4,0,
684,17,273,1,45,
684,17,281,2,0,
684,17,283,1,21,
684,17,312,1,26,
684,17,313,1,10,
684,17,347,4,0,
684,17,383,2,0,
684,17,412,1,36,
684,17,412,4,0,
684,17,495,2,0,
684,17,496,1,13,
684,17,496,4,0,
684,17,538,1,41,
684,17,577,1,31,
684,17,583,1,49,
684,17,584,1,5,
684,17,589,1,8,
684,17,590,4,0,
684,17,605,4,0,
684,18,33,1,1,2
684,18,53,4,0,
684,18,57,4,0,
684,18,85,4,0,
684,18,92,4,0,
684,18,94,4,0,
684,18,104,4,0,
684,18,113,1,58,
684,18,113,4,0,
684,18,138,4,0,
684,18,156,4,0,
684,18,164,4,0,
684,18,168,4,0,
684,18,178,1,17,
684,18,182,4,0,
684,18,187,2,0,
684,18,207,4,0,
684,18,213,4,0,
684,18,214,4,0,
684,18,216,4,0,
684,18,218,4,0,
684,18,219,1,67,
684,18,219,4,0,
684,18,230,1,1,1
684,18,237,4,0,
684,18,240,4,0,
684,18,241,4,0,
684,18,244,4,0,
684,18,263,4,0,
684,18,273,1,45,
684,18,281,2,0,
684,18,283,1,21,
684,18,312,1,26,
684,18,313,1,10,
684,18,347,4,0,
684,18,383,2,0,
684,18,412,1,36,
684,18,412,4,0,
684,18,495,2,0,
684,18,496,1,13,
684,18,496,4,0,
684,18,538,1,41,
684,18,564,2,0,
684,18,577,1,31,
684,18,583,1,49,
684,18,584,1,5,
684,18,589,1,8,
684,18,590,4,0,
684,18,605,4,0,
685,15,33,1,1,2
685,15,53,4,0,
685,15,57,4,0,
685,15,63,4,0,
685,15,85,4,0,
685,15,92,4,0,
685,15,94,4,0,
685,15,104,4,0,
685,15,113,1,58,
685,15,113,4,0,
685,15,138,4,0,
685,15,148,4,0,
685,15,156,4,0,
685,15,164,4,0,
685,15,168,4,0,
685,15,178,1,17,
685,15,182,4,0,
685,15,207,4,0,
685,15,213,4,0,
685,15,214,4,0,
685,15,216,4,0,
685,15,218,4,0,
685,15,219,1,67,
685,15,219,4,0,
685,15,230,1,1,1
685,15,237,4,0,
685,15,240,4,0,
685,15,241,4,0,
685,15,244,4,0,
685,15,263,4,0,
685,15,273,1,45,
685,15,283,1,21,
685,15,312,1,26,
685,15,313,1,10,
685,15,347,4,0,
685,15,412,1,36,
685,15,412,4,0,
685,15,416,4,0,
685,15,496,1,13,
685,15,496,4,0,
685,15,538,1,41,
685,15,577,1,31,
685,15,583,1,49,
685,15,584,1,5,
685,15,589,1,8,
685,15,590,4,0,
685,15,605,4,0,
685,16,33,1,1,2
685,16,53,4,0,
685,16,57,4,0,
685,16,63,4,0,
685,16,85,4,0,
685,16,92,4,0,
685,16,94,4,0,
685,16,104,4,0,
685,16,113,1,58,1
685,16,113,4,0,
685,16,138,4,0,
685,16,148,4,0,
685,16,156,4,0,
685,16,164,4,0,
685,16,168,4,0,
685,16,173,3,0,
685,16,178,1,17,1
685,16,182,4,0,
685,16,207,4,0,
685,16,213,4,0,
685,16,214,4,0,
685,16,215,3,0,
685,16,216,4,0,
685,16,218,4,0,
685,16,219,1,67,1
685,16,219,4,0,
685,16,230,1,1,1
685,16,237,4,0,
685,16,240,4,0,
685,16,241,4,0,
685,16,244,4,0,
685,16,263,4,0,
685,16,270,3,0,
685,16,273,1,45,1
685,16,277,3,0,
685,16,283,1,21,1
685,16,283,3,0,
685,16,290,4,0,
685,16,312,1,26,1
685,16,313,1,10,1
685,16,343,3,0,
685,16,347,4,0,
685,16,380,3,0,
685,16,409,3,0,
685,16,412,1,36,1
685,16,412,4,0,
685,16,416,4,0,
685,16,495,3,0,
685,16,496,1,13,1
685,16,496,4,0,
685,16,538,1,41,1
685,16,577,1,31,1
685,16,583,1,49,1
685,16,584,1,5,1
685,16,589,1,8,1
685,16,590,4,0,
685,16,605,4,0,
685,17,33,1,1,2
685,17,53,4,0,
685,17,57,4,0,
685,17,63,4,0,
685,17,85,4,0,
685,17,92,4,0,
685,17,94,4,0,
685,17,104,4,0,
685,17,113,1,58,
685,17,113,4,0,
685,17,138,4,0,
685,17,156,4,0,
685,17,164,4,0,
685,17,168,4,0,
685,17,178,1,17,
685,17,182,4,0,
685,17,207,4,0,
685,17,213,4,0,
685,17,214,4,0,
685,17,216,4,0,
685,17,218,4,0,
685,17,219,1,67,
685,17,219,4,0,
685,17,230,1,1,1
685,17,237,4,0,
685,17,240,4,0,
685,17,241,4,0,
685,17,244,4,0,
685,17,263,4,0,
685,17,273,1,45,
685,17,283,1,21,
685,17,312,1,26,
685,17,313,1,10,
685,17,347,4,0,
685,17,412,1,36,
685,17,412,4,0,
685,17,416,4,0,
685,17,496,1,13,
685,17,496,4,0,
685,17,538,1,41,
685,17,577,1,31,
685,17,583,1,49,
685,17,584,1,1,3
685,17,584,1,5,
685,17,589,1,1,4
685,17,589,1,8,
685,17,590,4,0,
685,17,605,4,0,
685,18,33,1,1,2
685,18,53,4,0,
685,18,57,4,0,
685,18,63,4,0,
685,18,85,4,0,
685,18,92,4,0,
685,18,94,4,0,
685,18,104,4,0,
685,18,113,1,58,
685,18,113,4,0,
685,18,138,4,0,
685,18,156,4,0,
685,18,164,4,0,
685,18,168,4,0,
685,18,178,1,17,
685,18,182,4,0,
685,18,207,4,0,
685,18,213,4,0,
685,18,214,4,0,
685,18,216,4,0,
685,18,218,4,0,
685,18,219,1,67,
685,18,219,4,0,
685,18,230,1,1,1
685,18,237,4,0,
685,18,240,4,0,
685,18,241,4,0,
685,18,244,4,0,
685,18,263,4,0,
685,18,273,1,45,
685,18,283,1,21,
685,18,312,1,26,
685,18,313,1,10,
685,18,347,4,0,
685,18,412,1,36,
685,18,412,4,0,
685,18,416,4,0,
685,18,496,1,13,
685,18,496,4,0,
685,18,538,1,41,
685,18,577,1,31,
685,18,583,1,49,
685,18,584,1,1,3
685,18,584,1,5,
685,18,589,1,1,4
685,18,589,1,8,
685,18,590,4,0,
685,18,605,4,0,
686,15,15,4,0,
686,15,33,1,1,1
686,15,53,4,0,
686,15,60,1,21,
686,15,64,1,1,2
686,15,85,4,0,
686,15,92,4,0,
686,15,94,4,0,
686,15,95,1,18,
686,15,104,4,0,
686,15,113,1,31,
686,15,113,4,0,
686,15,115,1,4,
686,15,115,4,0,
686,15,132,1,1,3
686,15,148,4,0,
686,15,149,1,13,
686,15,156,4,0,
686,15,157,4,0,
686,15,163,1,43,
686,15,164,4,0,
686,15,168,4,0,
686,15,182,4,0,
686,15,194,2,0,
686,15,207,1,12,
686,15,207,4,0,
686,15,213,4,0,
686,15,214,4,0,
686,15,216,4,0,
686,15,218,4,0,
686,15,237,4,0,
686,15,240,4,0,
686,15,241,4,0,
686,15,244,4,0,
686,15,259,4,0,
686,15,260,2,0,
686,15,263,4,0,
686,15,269,4,0,
686,15,276,1,48,
686,15,293,2,0,
686,15,332,4,0,
686,15,347,4,0,
686,15,365,1,35,
686,15,371,1,27,
686,15,371,4,0,
686,15,373,4,0,
686,15,374,4,0,
686,15,399,4,0,
686,15,400,1,46,
686,15,415,1,23,
686,15,427,1,39,
686,15,433,4,0,
686,15,471,2,0,
686,15,492,1,8,
686,15,493,2,0,
686,15,496,4,0,
686,15,514,4,0,
686,15,576,1,15,
686,15,590,4,0,
686,16,15,4,0,
686,16,20,3,0,
686,16,33,1,1,1
686,16,53,4,0,
686,16,60,1,21,1
686,16,64,1,1,2
686,16,85,4,0,
686,16,92,4,0,
686,16,94,4,0,
686,16,95,1,18,1
686,16,104,4,0,
686,16,113,1,31,1
686,16,113,4,0,
686,16,115,1,4,1
686,16,115,4,0,
686,16,132,1,1,3
686,16,148,4,0,
686,16,149,1,13,1
686,16,156,4,0,
686,16,157,4,0,
686,16,163,1,43,1
686,16,164,4,0,
686,16,168,4,0,
686,16,173,3,0,
686,16,180,3,0,
686,16,182,4,0,
686,16,194,2,0,
686,16,207,1,12,1
686,16,207,4,0,
686,16,213,4,0,
686,16,214,4,0,
686,16,216,4,0,
686,16,218,4,0,
686,16,237,4,0,
686,16,240,4,0,
686,16,241,4,0,
686,16,244,4,0,
686,16,259,4,0,
686,16,260,2,0,
686,16,263,4,0,
686,16,269,4,0,
686,16,272,3,0,
686,16,276,1,48,1
686,16,276,3,0,
686,16,282,3,0,
686,16,289,3,0,
686,16,290,4,0,
686,16,293,2,0,
686,16,332,4,0,
686,16,347,4,0,
686,16,365,1,35,1
686,16,371,1,27,1
686,16,371,4,0,
686,16,373,4,0,
686,16,374,4,0,
686,16,399,4,0,
686,16,400,1,46,1
686,16,415,1,23,1
686,16,427,1,39,1
686,16,433,4,0,
686,16,471,2,0,
686,16,492,1,8,1
686,16,492,3,0,
686,16,493,2,0,
686,16,496,4,0,
686,16,514,4,0,
686,16,576,1,15,1
686,16,590,4,0,
686,17,33,1,1,1
686,17,53,4,0,
686,17,60,1,21,
686,17,64,1,1,2
686,17,85,4,0,
686,17,92,4,0,
686,17,94,4,0,
686,17,95,1,18,
686,17,104,4,0,
686,17,113,1,31,
686,17,113,4,0,
686,17,115,1,4,
686,17,115,4,0,
686,17,132,1,1,3
686,17,149,1,13,
686,17,156,4,0,
686,17,157,4,0,
686,17,163,1,43,
686,17,164,4,0,
686,17,168,4,0,
686,17,182,4,0,
686,17,194,2,0,
686,17,207,1,12,
686,17,207,4,0,
686,17,213,4,0,
686,17,214,4,0,
686,17,216,4,0,
686,17,218,4,0,
686,17,237,4,0,
686,17,240,4,0,
686,17,241,4,0,
686,17,244,4,0,
686,17,259,4,0,
686,17,260,2,0,
686,17,263,4,0,
686,17,269,4,0,
686,17,276,1,48,
686,17,293,2,0,
686,17,332,4,0,
686,17,347,4,0,
686,17,365,1,35,
686,17,371,1,27,
686,17,371,4,0,
686,17,373,4,0,
686,17,374,4,0,
686,17,399,4,0,
686,17,400,1,46,
686,17,415,1,23,
686,17,427,1,39,
686,17,433,4,0,
686,17,471,2,0,
686,17,492,1,8,
686,17,493,2,0,
686,17,496,4,0,
686,17,576,1,15,
686,17,590,4,0,
686,18,33,1,1,1
686,18,53,4,0,
686,18,60,1,21,
686,18,64,1,1,2
686,18,85,4,0,
686,18,92,4,0,
686,18,94,4,0,
686,18,95,1,18,
686,18,104,4,0,
686,18,113,1,31,
686,18,113,4,0,
686,18,115,1,4,
686,18,115,4,0,
686,18,132,1,1,3
686,18,149,1,13,
686,18,156,4,0,
686,18,157,4,0,
686,18,163,1,43,
686,18,164,4,0,
686,18,168,4,0,
686,18,182,4,0,
686,18,194,2,0,
686,18,207,1,12,
686,18,207,4,0,
686,18,213,4,0,
686,18,214,4,0,
686,18,216,4,0,
686,18,218,4,0,
686,18,237,4,0,
686,18,240,4,0,
686,18,241,4,0,
686,18,244,4,0,
686,18,259,4,0,
686,18,260,2,0,
686,18,263,4,0,
686,18,269,4,0,
686,18,276,1,48,
686,18,293,2,0,
686,18,332,4,0,
686,18,347,4,0,
686,18,365,1,35,
686,18,371,1,27,
686,18,371,4,0,
686,18,373,4,0,
686,18,374,4,0,
686,18,385,2,0,
686,18,399,4,0,
686,18,400,1,46,
686,18,415,1,23,
686,18,427,1,39,
686,18,433,4,0,
686,18,471,2,0,
686,18,492,1,8,
686,18,493,2,0,
686,18,496,4,0,
686,18,576,1,15,
686,18,590,4,0,
687,15,15,4,0,
687,15,33,1,1,3
687,15,53,4,0,
687,15,60,1,21,
687,15,63,4,0,
687,15,64,1,1,4
687,15,85,4,0,
687,15,92,4,0,
687,15,94,4,0,
687,15,95,1,18,
687,15,104,4,0,
687,15,113,1,31,
687,15,113,4,0,
687,15,115,1,4,
687,15,115,4,0,
687,15,132,1,1,5
687,15,148,4,0,
687,15,149,1,13,
687,15,156,4,0,
687,15,157,4,0,
687,15,163,1,43,
687,15,164,4,0,
687,15,168,4,0,
687,15,179,1,1,2
687,15,182,4,0,
687,15,207,1,12,
687,15,207,4,0,
687,15,213,4,0,
687,15,214,4,0,
687,15,216,4,0,
687,15,218,4,0,
687,15,237,4,0,
687,15,240,4,0,
687,15,241,4,0,
687,15,244,4,0,
687,15,259,4,0,
687,15,263,4,0,
687,15,269,4,0,
687,15,276,1,1,1
687,15,276,1,48,
687,15,332,4,0,
687,15,347,4,0,
687,15,365,1,35,
687,15,371,1,27,
687,15,371,4,0,
687,15,373,4,0,
687,15,374,4,0,
687,15,399,4,0,
687,15,400,1,46,
687,15,415,1,23,
687,15,416,4,0,
687,15,427,1,39,
687,15,433,4,0,
687,15,473,4,0,
687,15,492,1,8,
687,15,496,4,0,
687,15,514,4,0,
687,15,576,1,15,
687,15,590,4,0,
687,16,15,4,0,
687,16,20,3,0,
687,16,33,1,1,3
687,16,53,4,0,
687,16,60,1,21,1
687,16,63,4,0,
687,16,64,1,1,4
687,16,85,4,0,
687,16,92,4,0,
687,16,94,4,0,
687,16,95,1,18,1
687,16,104,4,0,
687,16,113,1,31,1
687,16,113,4,0,
687,16,115,1,4,1
687,16,115,4,0,
687,16,132,1,1,5
687,16,148,4,0,
687,16,149,1,13,1
687,16,156,4,0,
687,16,157,4,0,
687,16,163,1,43,1
687,16,164,4,0,
687,16,168,4,0,
687,16,173,3,0,
687,16,179,1,1,2
687,16,180,3,0,
687,16,182,4,0,
687,16,207,1,12,1
687,16,207,4,0,
687,16,213,4,0,
687,16,214,4,0,
687,16,216,4,0,
687,16,218,4,0,
687,16,237,4,0,
687,16,240,4,0,
687,16,241,4,0,
687,16,244,4,0,
687,16,259,4,0,
687,16,263,4,0,
687,16,269,4,0,
687,16,272,3,0,
687,16,276,1,1,1
687,16,276,1,48,1
687,16,276,3,0,
687,16,282,3,0,
687,16,289,3,0,
687,16,290,4,0,
687,16,324,3,0,
687,16,332,4,0,
687,16,335,3,0,
687,16,347,4,0,
687,16,365,1,35,1
687,16,371,1,27,1
687,16,371,4,0,
687,16,373,4,0,
687,16,374,4,0,
687,16,399,4,0,
687,16,400,1,46,1
687,16,415,1,23,1
687,16,416,4,0,
687,16,427,1,39,1
687,16,433,4,0,
687,16,473,4,0,
687,16,492,1,8,1
687,16,492,3,0,
687,16,496,4,0,
687,16,514,4,0,
687,16,576,1,15,1
687,16,590,4,0,
687,17,33,1,1,2
687,17,53,4,0,
687,17,60,1,21,
687,17,63,4,0,
687,17,64,1,1,3
687,17,85,4,0,
687,17,92,4,0,
687,17,94,4,0,
687,17,95,1,18,
687,17,104,4,0,
687,17,113,1,31,
687,17,113,4,0,
687,17,115,1,1,5
687,17,115,1,4,
687,17,115,4,0,
687,17,132,1,1,4
687,17,149,1,13,
687,17,156,4,0,
687,17,157,4,0,
687,17,163,1,43,
687,17,164,4,0,
687,17,168,4,0,
687,17,179,1,1,1
687,17,182,4,0,
687,17,207,1,12,
687,17,207,4,0,
687,17,213,4,0,
687,17,214,4,0,
687,17,216,4,0,
687,17,218,4,0,
687,17,237,4,0,
687,17,240,4,0,
687,17,241,4,0,
687,17,244,4,0,
687,17,259,4,0,
687,17,263,4,0,
687,17,269,4,0,
687,17,276,1,48,
687,17,332,4,0,
687,17,347,4,0,
687,17,365,1,35,
687,17,371,1,27,
687,17,371,4,0,
687,17,373,4,0,
687,17,374,4,0,
687,17,399,4,0,
687,17,400,1,46,
687,17,415,1,23,
687,17,416,4,0,
687,17,427,1,39,
687,17,433,4,0,
687,17,473,4,0,
687,17,492,1,8,
687,17,496,4,0,
687,17,576,1,15,
687,17,590,4,0,
687,17,693,4,0,
687,18,33,1,1,2
687,18,53,4,0,
687,18,60,1,21,
687,18,63,4,0,
687,18,64,1,1,3
687,18,85,4,0,
687,18,92,4,0,
687,18,94,4,0,
687,18,95,1,18,
687,18,104,4,0,
687,18,113,1,31,
687,18,113,4,0,
687,18,115,1,1,5
687,18,115,1,4,
687,18,115,4,0,
687,18,132,1,1,4
687,18,149,1,13,
687,18,156,4,0,
687,18,157,4,0,
687,18,163,1,43,
687,18,164,4,0,
687,18,168,4,0,
687,18,179,1,1,1
687,18,182,4,0,
687,18,207,1,12,
687,18,207,4,0,
687,18,213,4,0,
687,18,214,4,0,
687,18,216,4,0,
687,18,218,4,0,
687,18,237,4,0,
687,18,240,4,0,
687,18,241,4,0,
687,18,244,4,0,
687,18,259,4,0,
687,18,263,4,0,
687,18,269,4,0,
687,18,276,1,48,
687,18,332,4,0,
687,18,347,4,0,
687,18,365,1,35,
687,18,371,1,27,
687,18,371,4,0,
687,18,373,4,0,
687,18,374,4,0,
687,18,399,4,0,
687,18,400,1,46,
687,18,415,1,23,
687,18,416,4,0,
687,18,427,1,39,
687,18,433,4,0,
687,18,473,4,0,
687,18,492,1,8,
687,18,496,4,0,
687,18,576,1,15,
687,18,590,4,0,
687,18,693,4,0,
688,15,10,1,1,2
688,15,14,4,0,
688,15,15,4,0,
688,15,28,1,1,3
688,15,55,1,4,
688,15,57,4,0,
688,15,58,4,0,
688,15,59,4,0,
688,15,70,4,0,
688,15,89,4,0,
688,15,91,4,0,
688,15,92,4,0,
688,15,104,4,0,
688,15,110,1,7,
688,15,128,1,20,
688,15,154,1,10,
688,15,156,4,0,
688,15,157,4,0,
688,15,163,1,13,
688,15,164,4,0,
688,15,168,4,0,
688,15,182,4,0,
688,15,188,4,0,
688,15,189,1,18,
688,15,201,4,0,
688,15,206,4,0,
688,15,207,4,0,
688,15,210,1,37,
688,15,213,4,0,
688,15,214,4,0,
688,15,216,4,0,
688,15,218,4,0,
688,15,219,4,0,
688,15,237,4,0,
688,15,238,1,49,
688,15,240,4,0,
688,15,246,1,28,
688,15,249,4,0,
688,15,259,4,0,
688,15,263,4,0,
688,15,267,4,0,
688,15,269,4,0,
688,15,270,2,0,
688,15,280,4,0,
688,15,317,4,0,
688,15,321,2,0,
688,15,332,4,0,
688,15,346,2,0,
688,15,371,4,0,
688,15,373,4,0,
688,15,374,4,0,
688,15,397,1,24,
688,15,397,4,0,
688,15,398,4,0,
688,15,400,1,41,
688,15,404,4,0,
688,15,415,2,0,
688,15,421,4,0,
688,15,444,4,0,
688,15,447,4,0,
688,15,468,1,32,
688,15,468,4,0,
688,15,479,4,0,
688,15,482,4,0,
688,15,496,4,0,
688,15,503,4,0,
688,15,504,1,1,1
688,15,523,4,0,
688,15,534,1,45,
688,15,590,4,0,
688,15,611,4,0,
688,15,612,4,0,
688,16,10,1,1,2
688,16,14,4,0,
688,16,15,4,0,
688,16,28,1,1,3
688,16,55,1,4,1
688,16,57,4,0,
688,16,58,4,0,
688,16,59,4,0,
688,16,70,4,0,
688,16,89,4,0,
688,16,91,4,0,
688,16,92,4,0,
688,16,104,4,0,
688,16,110,1,7,1
688,16,128,1,20,1
688,16,154,1,10,1
688,16,156,4,0,
688,16,157,4,0,
688,16,163,1,13,1
688,16,164,4,0,
688,16,168,4,0,
688,16,173,3,0,
688,16,182,4,0,
688,16,188,4,0,
688,16,189,1,18,1
688,16,196,3,0,
688,16,201,4,0,
688,16,206,4,0,
688,16,207,4,0,
688,16,210,1,37,1
688,16,213,4,0,
688,16,214,4,0,
688,16,216,4,0,
688,16,218,4,0,
688,16,219,4,0,
688,16,237,4,0,
688,16,238,1,49,1
688,16,240,4,0,
688,16,246,1,28,1
688,16,249,4,0,
688,16,259,4,0,
688,16,263,4,0,
688,16,267,4,0,
688,16,269,4,0,
688,16,270,2,0,
688,16,270,3,0,
688,16,280,4,0,
688,16,283,3,0,
688,16,290,4,0,
688,16,317,4,0,
688,16,321,2,0,
688,16,332,4,0,
688,16,334,3,0,
688,16,346,2,0,
688,16,352,3,0,
688,16,371,4,0,
688,16,373,4,0,
688,16,374,4,0,
688,16,397,1,24,1
688,16,397,4,0,
688,16,398,4,0,
688,16,400,1,41,1
688,16,404,4,0,
688,16,415,2,0,
688,16,421,4,0,
688,16,444,4,0,
688,16,446,3,0,
688,16,447,4,0,
688,16,468,1,32,1
688,16,468,4,0,
688,16,479,4,0,
688,16,482,4,0,
688,16,496,4,0,
688,16,503,4,0,
688,16,504,1,1,1
688,16,523,4,0,
688,16,530,3,0,
688,16,534,1,45,1
688,16,590,4,0,
688,16,611,4,0,
688,16,612,4,0,
688,17,10,1,1,2
688,17,14,4,0,
688,17,28,1,1,3
688,17,55,1,4,
688,17,57,4,0,
688,17,58,4,0,
688,17,59,4,0,
688,17,89,4,0,
688,17,92,4,0,
688,17,104,4,0,
688,17,110,1,7,
688,17,128,1,20,
688,17,154,1,10,
688,17,156,4,0,
688,17,157,4,0,
688,17,163,1,13,
688,17,164,4,0,
688,17,168,4,0,
688,17,182,4,0,
688,17,188,4,0,
688,17,189,1,18,
688,17,201,4,0,
688,17,206,4,0,
688,17,207,4,0,
688,17,210,1,37,
688,17,213,4,0,
688,17,214,4,0,
688,17,216,4,0,
688,17,218,4,0,
688,17,219,4,0,
688,17,237,4,0,
688,17,238,1,49,
688,17,240,4,0,
688,17,246,1,28,
688,17,259,4,0,
688,17,263,4,0,
688,17,267,4,0,
688,17,269,4,0,
688,17,270,2,0,
688,17,280,4,0,
688,17,317,4,0,
688,17,321,2,0,
688,17,332,4,0,
688,17,346,2,0,
688,17,371,4,0,
688,17,373,4,0,
688,17,374,4,0,
688,17,397,1,24,
688,17,397,4,0,
688,17,398,4,0,
688,17,400,1,41,
688,17,404,4,0,
688,17,415,2,0,
688,17,421,4,0,
688,17,444,4,0,
688,17,447,4,0,
688,17,468,1,32,
688,17,479,4,0,
688,17,482,4,0,
688,17,496,4,0,
688,17,503,4,0,
688,17,504,1,1,1
688,17,523,4,0,
688,17,534,1,45,
688,17,590,4,0,
688,17,611,4,0,
688,18,10,1,1,2
688,18,14,4,0,
688,18,28,1,1,3
688,18,55,1,4,
688,18,57,4,0,
688,18,58,4,0,
688,18,59,4,0,
688,18,89,4,0,
688,18,92,4,0,
688,18,104,4,0,
688,18,110,1,7,
688,18,128,1,20,
688,18,154,1,10,
688,18,156,4,0,
688,18,157,4,0,
688,18,163,1,13,
688,18,164,4,0,
688,18,168,4,0,
688,18,182,4,0,
688,18,188,4,0,
688,18,189,1,18,
688,18,201,4,0,
688,18,206,4,0,
688,18,207,4,0,
688,18,210,1,37,
688,18,213,4,0,
688,18,214,4,0,
688,18,216,4,0,
688,18,218,4,0,
688,18,219,4,0,
688,18,237,4,0,
688,18,238,1,49,
688,18,240,4,0,
688,18,246,1,28,
688,18,259,4,0,
688,18,263,4,0,
688,18,267,4,0,
688,18,269,4,0,
688,18,270,2,0,
688,18,280,4,0,
688,18,317,4,0,
688,18,321,2,0,
688,18,332,4,0,
688,18,346,2,0,
688,18,371,4,0,
688,18,373,4,0,
688,18,374,4,0,
688,18,397,1,24,
688,18,397,4,0,
688,18,398,4,0,
688,18,400,1,41,
688,18,404,4,0,
688,18,415,2,0,
688,18,421,4,0,
688,18,444,4,0,
688,18,447,4,0,
688,18,468,1,32,
688,18,479,4,0,
688,18,482,4,0,
688,18,496,4,0,
688,18,503,4,0,
688,18,504,1,1,1
688,18,523,4,0,
688,18,534,1,45,
688,18,590,4,0,
688,18,611,4,0,
689,15,10,1,1,4
689,15,14,4,0,
689,15,15,4,0,
689,15,28,1,1,5
689,15,55,1,4,
689,15,57,4,0,
689,15,58,4,0,
689,15,59,4,0,
689,15,63,4,0,
689,15,70,4,0,
689,15,89,4,0,
689,15,91,4,0,
689,15,92,4,0,
689,15,104,4,0,
689,15,110,1,7,
689,15,128,1,20,
689,15,130,1,1,2
689,15,130,1,65,
689,15,154,1,10,
689,15,156,4,0,
689,15,157,4,0,
689,15,163,1,13,
689,15,164,4,0,
689,15,168,4,0,
689,15,182,4,0,
689,15,188,4,0,
689,15,189,1,18,
689,15,201,4,0,
689,15,206,4,0,
689,15,207,4,0,
689,15,210,1,37,
689,15,213,4,0,
689,15,214,4,0,
689,15,216,4,0,
689,15,218,4,0,
689,15,219,4,0,
689,15,237,4,0,
689,15,238,1,55,
689,15,240,4,0,
689,15,246,1,28,
689,15,249,4,0,
689,15,259,4,0,
689,15,263,4,0,
689,15,267,4,0,
689,15,269,4,0,
689,15,280,4,0,
689,15,317,4,0,
689,15,332,4,0,
689,15,337,4,0,
689,15,339,4,0,
689,15,371,4,0,
689,15,373,4,0,
689,15,374,4,0,
689,15,397,1,24,
689,15,397,4,0,
689,15,398,4,0,
689,15,400,1,44,
689,15,404,4,0,
689,15,411,4,0,
689,15,416,4,0,
689,15,421,4,0,
689,15,444,1,1,1
689,15,444,1,60,
689,15,444,4,0,
689,15,447,4,0,
689,15,468,1,32,
689,15,468,4,0,
689,15,479,4,0,
689,15,482,4,0,
689,15,496,4,0,
689,15,503,4,0,
689,15,504,1,1,3
689,15,523,4,0,
689,15,534,1,48,
689,15,590,4,0,
689,15,611,4,0,
689,15,612,4,0,
689,16,10,1,1,4
689,16,14,4,0,
689,16,15,4,0,
689,16,28,1,1,5
689,16,55,1,4,1
689,16,57,4,0,
689,16,58,4,0,
689,16,59,4,0,
689,16,63,4,0,
689,16,67,3,0,
689,16,70,4,0,
689,16,89,4,0,
689,16,91,4,0,
689,16,92,4,0,
689,16,104,4,0,
689,16,110,1,7,1
689,16,128,1,20,1
689,16,130,1,1,2
689,16,130,1,65,1
689,16,154,1,10,1
689,16,156,4,0,
689,16,157,4,0,
689,16,163,1,13,1
689,16,164,4,0,
689,16,168,4,0,
689,16,173,3,0,
689,16,182,4,0,
689,16,188,4,0,
689,16,189,1,18,1
689,16,196,3,0,
689,16,201,4,0,
689,16,206,4,0,
689,16,207,4,0,
689,16,210,1,37,1
689,16,213,4,0,
689,16,214,4,0,
689,16,216,4,0,
689,16,218,4,0,
689,16,219,4,0,
689,16,237,4,0,
689,16,238,1,55,1
689,16,240,4,0,
689,16,246,1,28,1
689,16,249,4,0,
689,16,259,4,0,
689,16,263,4,0,
689,16,267,4,0,
689,16,269,4,0,
689,16,270,3,0,
689,16,276,3,0,
689,16,280,4,0,
689,16,283,3,0,
689,16,290,4,0,
689,16,317,4,0,
689,16,332,4,0,
689,16,334,3,0,
689,16,337,4,0,
689,16,339,4,0,
689,16,352,3,0,
689,16,371,4,0,
689,16,373,4,0,
689,16,374,4,0,
689,16,397,1,24,1
689,16,397,4,0,
689,16,398,4,0,
689,16,400,1,44,1
689,16,404,4,0,
689,16,411,4,0,
689,16,414,3,0,
689,16,416,4,0,
689,16,421,4,0,
689,16,444,1,1,1
689,16,444,1,60,1
689,16,444,4,0,
689,16,446,3,0,
689,16,447,4,0,
689,16,468,1,32,1
689,16,468,4,0,
689,16,479,4,0,
689,16,482,4,0,
689,16,496,4,0,
689,16,503,4,0,
689,16,504,1,1,3
689,16,523,4,0,
689,16,530,3,0,
689,16,534,1,48,1
689,16,590,4,0,
689,16,611,4,0,
689,16,612,4,0,
689,17,10,1,1,4
689,17,14,4,0,
689,17,28,1,1,5
689,17,55,1,1,6
689,17,55,1,4,
689,17,57,4,0,
689,17,58,4,0,
689,17,59,4,0,
689,17,63,4,0,
689,17,89,4,0,
689,17,92,4,0,
689,17,104,4,0,
689,17,110,1,7,
689,17,128,1,20,
689,17,130,1,1,1
689,17,130,1,65,
689,17,154,1,10,
689,17,156,4,0,
689,17,157,4,0,
689,17,163,1,13,
689,17,164,4,0,
689,17,168,4,0,
689,17,182,4,0,
689,17,188,4,0,
689,17,189,1,18,
689,17,201,4,0,
689,17,206,4,0,
689,17,207,4,0,
689,17,210,1,37,
689,17,213,4,0,
689,17,214,4,0,
689,17,216,4,0,
689,17,218,4,0,
689,17,219,4,0,
689,17,237,4,0,
689,17,238,1,55,
689,17,240,4,0,
689,17,246,1,28,
689,17,259,4,0,
689,17,263,4,0,
689,17,267,4,0,
689,17,269,4,0,
689,17,280,4,0,
689,17,317,4,0,
689,17,332,4,0,
689,17,337,4,0,
689,17,339,4,0,
689,17,371,4,0,
689,17,373,4,0,
689,17,374,4,0,
689,17,397,1,24,
689,17,397,4,0,
689,17,398,4,0,
689,17,400,1,44,
689,17,404,4,0,
689,17,411,4,0,
689,17,416,4,0,
689,17,421,4,0,
689,17,444,1,1,2
689,17,444,1,60,
689,17,444,4,0,
689,17,447,4,0,
689,17,468,1,32,
689,17,479,4,0,
689,17,482,4,0,
689,17,496,4,0,
689,17,503,4,0,
689,17,504,1,1,3
689,17,523,4,0,
689,17,534,1,48,
689,17,590,4,0,
689,17,611,4,0,
689,17,693,4,0,
689,18,10,1,1,4
689,18,14,4,0,
689,18,28,1,1,5
689,18,55,1,1,6
689,18,55,1,4,
689,18,57,4,0,
689,18,58,4,0,
689,18,59,4,0,
689,18,63,4,0,
689,18,89,4,0,
689,18,92,4,0,
689,18,104,4,0,
689,18,110,1,7,
689,18,128,1,20,
689,18,130,1,1,1
689,18,130,1,65,
689,18,154,1,10,
689,18,156,4,0,
689,18,157,4,0,
689,18,163,1,13,
689,18,164,4,0,
689,18,168,4,0,
689,18,182,4,0,
689,18,188,4,0,
689,18,189,1,18,
689,18,201,4,0,
689,18,206,4,0,
689,18,207,4,0,
689,18,210,1,37,
689,18,213,4,0,
689,18,214,4,0,
689,18,216,4,0,
689,18,218,4,0,
689,18,219,4,0,
689,18,237,4,0,
689,18,238,1,55,
689,18,240,4,0,
689,18,246,1,28,
689,18,259,4,0,
689,18,263,4,0,
689,18,267,4,0,
689,18,269,4,0,
689,18,280,4,0,
689,18,317,4,0,
689,18,332,4,0,
689,18,337,4,0,
689,18,339,4,0,
689,18,371,4,0,
689,18,373,4,0,
689,18,374,4,0,
689,18,397,1,24,
689,18,397,4,0,
689,18,398,4,0,
689,18,400,1,44,
689,18,404,4,0,
689,18,411,4,0,
689,18,416,4,0,
689,18,421,4,0,
689,18,444,1,1,2
689,18,444,1,60,
689,18,444,4,0,
689,18,447,4,0,
689,18,468,1,32,
689,18,479,4,0,
689,18,482,4,0,
689,18,496,4,0,
689,18,503,4,0,
689,18,504,1,1,3
689,18,523,4,0,
689,18,534,1,48,
689,18,590,4,0,
689,18,611,4,0,
689,18,693,4,0,
690,15,33,1,1,1
690,15,39,1,9,
690,15,51,1,15,
690,15,55,1,1,3
690,15,56,1,42,
690,15,57,4,0,
690,15,85,4,0,
690,15,92,1,32,
690,15,92,4,0,
690,15,104,1,28,
690,15,104,4,0,
690,15,108,1,1,2
690,15,114,2,0,
690,15,127,4,0,
690,15,145,1,12,
690,15,151,2,0,
690,15,156,4,0,
690,15,164,4,0,
690,15,182,4,0,
690,15,185,1,5,
690,15,188,1,38,
690,15,188,4,0,
690,15,207,4,0,
690,15,213,4,0,
690,15,214,4,0,
690,15,216,4,0,
690,15,218,4,0,
690,15,237,4,0,
690,15,240,4,0,
690,15,247,4,0,
690,15,258,4,0,
690,15,263,4,0,
690,15,293,1,19,
690,15,342,1,23,
690,15,352,1,25,
690,15,390,2,0,
690,15,401,1,35,
690,15,406,1,49,
690,15,474,4,0,
690,15,482,4,0,
690,15,496,4,0,
690,15,503,4,0,
690,15,583,2,0,
690,15,590,4,0,
690,15,599,2,0,
690,16,33,1,1,1
690,16,39,1,9,1
690,16,51,1,15,1
690,16,55,1,1,3
690,16,56,1,42,1
690,16,57,4,0,
690,16,85,4,0,
690,16,92,1,32,1
690,16,92,4,0,
690,16,104,1,28,1
690,16,104,4,0,
690,16,108,1,1,2
690,16,114,2,0,
690,16,127,4,0,
690,16,145,1,12,1
690,16,151,2,0,
690,16,156,4,0,
690,16,164,4,0,
690,16,173,3,0,
690,16,182,4,0,
690,16,185,1,5,1
690,16,188,1,38,1
690,16,188,4,0,
690,16,196,3,0,
690,16,200,3,0,
690,16,207,4,0,
690,16,213,4,0,
690,16,214,4,0,
690,16,216,4,0,
690,16,218,4,0,
690,16,231,3,0,
690,16,237,4,0,
690,16,240,4,0,
690,16,247,4,0,
690,16,258,4,0,
690,16,263,4,0,
690,16,290,4,0,
690,16,291,4,0,
690,16,293,1,19,1
690,16,340,3,0,
690,16,342,1,23,1
690,16,351,3,0,
690,16,352,1,25,1
690,16,352,3,0,
690,16,390,2,0,
690,16,401,1,35,1
690,16,401,3,0,
690,16,406,1,49,1
690,16,406,3,0,
690,16,441,3,0,
690,16,474,4,0,
690,16,482,4,0,
690,16,496,4,0,
690,16,503,4,0,
690,16,583,2,0,
690,16,590,4,0,
690,16,599,2,0,
690,17,33,1,1,1
690,17,39,1,9,
690,17,51,1,15,
690,17,55,1,1,3
690,17,56,1,42,
690,17,57,4,0,
690,17,85,4,0,
690,17,92,1,32,
690,17,92,4,0,
690,17,104,1,28,
690,17,104,4,0,
690,17,108,1,1,2
690,17,114,2,0,
690,17,127,4,0,
690,17,145,1,12,
690,17,151,2,0,
690,17,156,4,0,
690,17,164,4,0,
690,17,182,4,0,
690,17,185,1,5,
690,17,188,1,38,
690,17,188,4,0,
690,17,207,4,0,
690,17,213,4,0,
690,17,214,4,0,
690,17,216,4,0,
690,17,218,4,0,
690,17,237,4,0,
690,17,240,4,0,
690,17,247,4,0,
690,17,258,4,0,
690,17,263,4,0,
690,17,293,1,19,
690,17,342,1,23,
690,17,352,1,25,
690,17,390,2,0,
690,17,401,1,35,
690,17,406,1,49,
690,17,474,4,0,
690,17,482,4,0,
690,17,496,4,0,
690,17,503,4,0,
690,17,583,2,0,
690,17,590,4,0,
690,17,599,2,0,
690,18,33,1,1,1
690,18,39,1,9,
690,18,51,1,15,
690,18,55,1,1,3
690,18,56,1,42,
690,18,57,4,0,
690,18,85,4,0,
690,18,92,1,32,
690,18,92,4,0,
690,18,104,1,28,
690,18,104,4,0,
690,18,108,1,1,2
690,18,114,2,0,
690,18,127,4,0,
690,18,145,1,12,
690,18,151,2,0,
690,18,156,4,0,
690,18,164,4,0,
690,18,182,4,0,
690,18,185,1,5,
690,18,188,1,38,
690,18,188,4,0,
690,18,207,4,0,
690,18,213,4,0,
690,18,214,4,0,
690,18,216,4,0,
690,18,218,4,0,
690,18,237,4,0,
690,18,240,4,0,
690,18,247,4,0,
690,18,258,4,0,
690,18,263,4,0,
690,18,293,1,19,
690,18,342,1,23,
690,18,352,1,25,
690,18,390,2,0,
690,18,401,1,35,
690,18,406,1,49,
690,18,474,4,0,
690,18,482,4,0,
690,18,496,4,0,
690,18,503,4,0,
690,18,583,2,0,
690,18,590,4,0,
690,18,599,2,0,
691,15,33,1,1,3
691,15,39,1,9,
691,15,51,1,15,
691,15,55,1,1,5
691,15,56,1,42,
691,15,57,4,0,
691,15,63,4,0,
691,15,85,4,0,
691,15,87,4,0,
691,15,92,1,32,
691,15,92,4,0,
691,15,104,1,28,
691,15,104,4,0,
691,15,108,1,1,4
691,15,127,4,0,
691,15,145,1,12,
691,15,156,4,0,
691,15,164,4,0,
691,15,182,4,0,
691,15,185,1,5,
691,15,188,1,38,
691,15,188,4,0,
691,15,207,4,0,
691,15,213,4,0,
691,15,214,4,0,
691,15,216,4,0,
691,15,218,4,0,
691,15,237,4,0,
691,15,239,1,1,2
691,15,239,1,67,
691,15,240,4,0,
691,15,247,4,0,
691,15,258,4,0,
691,15,263,4,0,
691,15,293,1,19,
691,15,342,1,23,
691,15,352,1,25,
691,15,401,1,35,
691,15,406,1,53,
691,15,411,4,0,
691,15,416,4,0,
691,15,434,3,0,
691,15,474,4,0,
691,15,482,4,0,
691,15,496,4,0,
691,15,503,4,0,
691,15,525,1,1,1
691,15,525,1,59,
691,15,525,4,0,
691,15,590,4,0,
691,16,33,1,1,3
691,16,39,1,9,1
691,16,51,1,15,1
691,16,55,1,1,5
691,16,56,1,42,1
691,16,57,4,0,
691,16,63,4,0,
691,16,85,4,0,
691,16,87,4,0,
691,16,92,1,32,1
691,16,92,4,0,
691,16,104,1,28,1
691,16,104,4,0,
691,16,108,1,1,4
691,16,127,4,0,
691,16,145,1,12,1
691,16,156,4,0,
691,16,164,4,0,
691,16,173,3,0,
691,16,182,4,0,
691,16,185,1,5,1
691,16,188,1,38,1
691,16,188,4,0,
691,16,196,3,0,
691,16,200,3,0,
691,16,207,4,0,
691,16,213,4,0,
691,16,214,4,0,
691,16,216,4,0,
691,16,218,4,0,
691,16,231,3,0,
691,16,237,4,0,
691,16,239,1,1,2
691,16,239,1,67,1
691,16,240,4,0,
691,16,247,4,0,
691,16,258,4,0,
691,16,263,4,0,
691,16,290,4,0,
691,16,291,4,0,
691,16,293,1,19,1
691,16,340,3,0,
691,16,342,1,23,1
691,16,351,3,0,
691,16,352,1,25,1
691,16,352,3,0,
691,16,401,1,35,1
691,16,401,3,0,
691,16,406,1,53,1
691,16,406,3,0,
691,16,411,4,0,
691,16,416,4,0,
691,16,434,3,0,
691,16,441,3,0,
691,16,474,4,0,
691,16,482,4,0,
691,16,496,4,0,
691,16,503,4,0,
691,16,525,1,1,1
691,16,525,1,59,1
691,16,525,4,0,
691,16,590,4,0,
691,17,33,1,1,3
691,17,39,1,9,
691,17,51,1,15,
691,17,55,1,1,5
691,17,56,1,42,
691,17,57,4,0,
691,17,63,4,0,
691,17,85,4,0,
691,17,87,4,0,
691,17,92,1,32,
691,17,92,4,0,
691,17,104,1,28,
691,17,104,4,0,
691,17,108,1,1,4
691,17,127,4,0,
691,17,145,1,12,
691,17,156,4,0,
691,17,164,4,0,
691,17,182,4,0,
691,17,185,1,1,6
691,17,185,1,5,
691,17,188,1,38,
691,17,188,4,0,
691,17,207,4,0,
691,17,213,4,0,
691,17,214,4,0,
691,17,216,4,0,
691,17,218,4,0,
691,17,237,4,0,
691,17,239,1,0,
691,17,239,1,1,1
691,17,240,4,0,
691,17,247,4,0,
691,17,258,4,0,
691,17,263,4,0,
691,17,293,1,19,
691,17,342,1,23,
691,17,352,1,25,
691,17,401,1,35,
691,17,406,1,53,
691,17,411,4,0,
691,17,416,4,0,
691,17,434,3,0,
691,17,474,4,0,
691,17,482,4,0,
691,17,496,4,0,
691,17,503,4,0,
691,17,525,1,1,2
691,17,525,1,59,
691,17,525,4,0,
691,17,590,4,0,
691,18,33,1,1,3
691,18,39,1,9,
691,18,51,1,15,
691,18,55,1,1,5
691,18,56,1,42,
691,18,57,4,0,
691,18,63,4,0,
691,18,85,4,0,
691,18,87,4,0,
691,18,92,1,32,
691,18,92,4,0,
691,18,104,1,28,
691,18,104,4,0,
691,18,108,1,1,4
691,18,127,4,0,
691,18,145,1,12,
691,18,156,4,0,
691,18,164,4,0,
691,18,182,4,0,
691,18,185,1,1,6
691,18,185,1,5,
691,18,188,1,38,
691,18,188,4,0,
691,18,207,4,0,
691,18,213,4,0,
691,18,214,4,0,
691,18,216,4,0,
691,18,218,4,0,
691,18,237,4,0,
691,18,239,1,0,
691,18,239,1,1,1
691,18,240,4,0,
691,18,247,4,0,
691,18,258,4,0,
691,18,263,4,0,
691,18,293,1,19,
691,18,342,1,23,
691,18,352,1,25,
691,18,401,1,35,
691,18,406,1,53,
691,18,411,4,0,
691,18,416,4,0,
691,18,434,3,0,
691,18,474,4,0,
691,18,482,4,0,
691,18,496,4,0,
691,18,503,4,0,
691,18,525,1,1,2
691,18,525,1,59,
691,18,525,4,0,
691,18,590,4,0,
692,15,11,1,9,
692,15,14,1,25,
692,15,14,4,0,
692,15,15,4,0,
692,15,55,1,1,2
692,15,57,4,0,
692,15,58,4,0,
692,15,61,1,20,
692,15,92,4,0,
692,15,104,4,0,
692,15,127,4,0,
692,15,145,1,12,
692,15,150,1,1,1
692,15,152,1,30,
692,15,152,2,0,
692,15,156,4,0,
692,15,157,4,0,
692,15,164,4,0,
692,15,175,1,16,
692,15,182,4,0,
692,15,188,4,0,
692,15,203,2,0,
692,15,207,4,0,
692,15,213,4,0,
692,15,214,4,0,
692,15,216,4,0,
692,15,218,4,0,
692,15,237,4,0,
692,15,240,4,0,
692,15,263,4,0,
692,15,270,2,0,
692,15,330,1,48,
692,15,346,1,7,
692,15,352,1,34,
692,15,369,4,0,
692,15,430,4,0,
692,15,453,1,43,
692,15,453,2,0,
692,15,474,4,0,
692,15,479,1,39,
692,15,479,4,0,
692,15,482,4,0,
692,15,494,2,0,
692,15,496,4,0,
692,15,503,4,0,
692,15,590,4,0,
692,16,11,1,9,1
692,16,14,1,25,1
692,16,14,4,0,
692,16,15,4,0,
692,16,55,1,1,2
692,16,57,4,0,
692,16,58,4,0,
692,16,61,1,20,1
692,16,92,4,0,
692,16,104,4,0,
692,16,127,4,0,
692,16,145,1,12,1
692,16,150,1,1,1
692,16,152,1,30,1
692,16,152,2,0,
692,16,156,4,0,
692,16,157,4,0,
692,16,164,4,0,
692,16,173,3,0,
692,16,175,1,16,1
692,16,182,4,0,
692,16,188,4,0,
692,16,196,3,0,
692,16,203,2,0,
692,16,207,4,0,
692,16,213,4,0,
692,16,214,4,0,
692,16,216,4,0,
692,16,218,4,0,
692,16,231,3,0,
692,16,237,4,0,
692,16,240,4,0,
692,16,263,4,0,
692,16,270,2,0,
692,16,270,3,0,
692,16,290,4,0,
692,16,291,4,0,
692,16,330,1,48,1
692,16,340,3,0,
692,16,346,1,7,1
692,16,352,1,34,1
692,16,352,3,0,
692,16,369,4,0,
692,16,401,3,0,
692,16,406,3,0,
692,16,430,4,0,
692,16,453,1,43,1
692,16,453,2,0,
692,16,474,4,0,
692,16,479,1,39,1
692,16,479,4,0,
692,16,482,4,0,
692,16,494,2,0,
692,16,496,4,0,
692,16,503,4,0,
692,16,590,4,0,
692,17,11,1,9,
692,17,14,1,25,
692,17,14,4,0,
692,17,55,1,1,2
692,17,57,4,0,
692,17,58,4,0,
692,17,61,1,20,
692,17,92,4,0,
692,17,104,4,0,
692,17,127,4,0,
692,17,145,1,12,
692,17,150,1,1,1
692,17,152,1,30,
692,17,152,2,0,
692,17,156,4,0,
692,17,157,4,0,
692,17,164,4,0,
692,17,175,1,16,
692,17,182,4,0,
692,17,188,4,0,
692,17,203,2,0,
692,17,207,4,0,
692,17,213,4,0,
692,17,214,4,0,
692,17,216,4,0,
692,17,218,4,0,
692,17,237,4,0,
692,17,240,4,0,
692,17,263,4,0,
692,17,270,2,0,
692,17,330,1,48,
692,17,346,1,7,
692,17,352,1,34,
692,17,369,4,0,
692,17,430,4,0,
692,17,453,1,43,
692,17,453,2,0,
692,17,474,4,0,
692,17,479,1,39,
692,17,479,4,0,
692,17,482,4,0,
692,17,494,2,0,
692,17,496,4,0,
692,17,503,4,0,
692,17,590,4,0,
692,18,11,1,9,
692,18,14,1,25,
692,18,14,4,0,
692,18,55,1,1,2
692,18,57,4,0,
692,18,58,4,0,
692,18,61,1,20,
692,18,92,4,0,
692,18,104,4,0,
692,18,127,4,0,
692,18,145,1,12,
692,18,150,1,1,1
692,18,152,1,30,
692,18,152,2,0,
692,18,156,4,0,
692,18,157,4,0,
692,18,164,4,0,
692,18,175,1,16,
692,18,182,4,0,
692,18,188,4,0,
692,18,203,2,0,
692,18,207,4,0,
692,18,213,4,0,
692,18,214,4,0,
692,18,216,4,0,
692,18,218,4,0,
692,18,237,4,0,
692,18,240,4,0,
692,18,263,4,0,
692,18,270,2,0,
692,18,330,1,48,
692,18,346,1,7,
692,18,352,1,34,
692,18,369,4,0,
692,18,430,4,0,
692,18,453,1,43,
692,18,453,2,0,
692,18,474,4,0,
692,18,479,1,39,
692,18,479,4,0,
692,18,482,4,0,
692,18,494,2,0,
692,18,496,4,0,
692,18,503,4,0,
692,18,590,4,0,
693,15,11,1,9,
693,15,14,1,25,
693,15,14,4,0,
693,15,15,4,0,
693,15,55,1,1,6
693,15,57,4,0,
693,15,58,4,0,
693,15,61,1,20,
693,15,63,4,0,
693,15,92,4,0,
693,15,104,4,0,
693,15,127,4,0,
693,15,145,1,12,
693,15,150,1,1,5
693,15,152,1,30,
693,15,156,4,0,
693,15,157,4,0,
693,15,164,4,0,
693,15,175,1,16,
693,15,182,4,0,
693,15,188,4,0,
693,15,207,4,0,
693,15,213,4,0,
693,15,214,4,0,
693,15,216,4,0,
693,15,218,4,0,
693,15,237,4,0,
693,15,240,4,0,
693,15,247,4,0,
693,15,263,4,0,
693,15,330,1,53,
693,15,346,1,7,
693,15,352,1,34,
693,15,369,4,0,
693,15,396,1,1,4
693,15,396,1,67,
693,15,399,1,1,2
693,15,399,1,57,
693,15,399,4,0,
693,15,406,1,1,3
693,15,406,1,63,
693,15,411,4,0,
693,15,416,4,0,
693,15,430,4,0,
693,15,453,1,47,
693,15,474,4,0,
693,15,479,1,42,
693,15,479,4,0,
693,15,482,4,0,
693,15,496,4,0,
693,15,503,4,0,
693,15,505,1,1,1
693,15,590,4,0,
693,16,11,1,9,1
693,16,14,1,25,1
693,16,14,4,0,
693,16,15,4,0,
693,16,55,1,1,6
693,16,57,4,0,
693,16,58,4,0,
693,16,61,1,20,1
693,16,63,4,0,
693,16,92,4,0,
693,16,104,4,0,
693,16,127,4,0,
693,16,145,1,12,1
693,16,150,1,1,5
693,16,152,1,30,1
693,16,156,4,0,
693,16,157,4,0,
693,16,164,4,0,
693,16,173,3,0,
693,16,175,1,16,1
693,16,182,4,0,
693,16,188,4,0,
693,16,196,3,0,
693,16,207,4,0,
693,16,213,4,0,
693,16,214,4,0,
693,16,216,4,0,
693,16,218,4,0,
693,16,231,3,0,
693,16,237,4,0,
693,16,240,4,0,
693,16,247,4,0,
693,16,263,4,0,
693,16,270,3,0,
693,16,290,4,0,
693,16,291,4,0,
693,16,330,1,53,1
693,16,340,3,0,
693,16,346,1,7,1
693,16,352,1,34,1
693,16,352,3,0,
693,16,369,4,0,
693,16,396,1,1,4
693,16,396,1,67,1
693,16,399,1,1,2
693,16,399,1,57,1
693,16,399,4,0,
693,16,401,3,0,
693,16,406,1,1,3
693,16,406,1,63,1
693,16,406,3,0,
693,16,411,4,0,
693,16,416,4,0,
693,16,430,4,0,
693,16,453,1,47,1
693,16,474,4,0,
693,16,479,1,42,1
693,16,479,4,0,
693,16,482,4,0,
693,16,496,4,0,
693,16,503,4,0,
693,16,505,1,1,1
693,16,590,4,0,
693,17,11,1,1,8
693,17,11,1,9,
693,17,14,1,25,
693,17,14,4,0,
693,17,55,1,1,6
693,17,57,4,0,
693,17,58,4,0,
693,17,61,1,20,
693,17,63,4,0,
693,17,92,4,0,
693,17,104,4,0,
693,17,127,4,0,
693,17,145,1,12,
693,17,150,1,1,5
693,17,152,1,30,
693,17,156,4,0,
693,17,157,4,0,
693,17,164,4,0,
693,17,175,1,16,
693,17,182,4,0,
693,17,188,4,0,
693,17,207,4,0,
693,17,213,4,0,
693,17,214,4,0,
693,17,216,4,0,
693,17,218,4,0,
693,17,237,4,0,
693,17,240,4,0,
693,17,247,4,0,
693,17,263,4,0,
693,17,330,1,57,
693,17,346,1,1,7
693,17,346,1,7,
693,17,352,1,34,
693,17,369,4,0,
693,17,396,1,0,
693,17,396,1,1,1
693,17,399,1,1,2
693,17,399,4,0,
693,17,406,1,1,3
693,17,411,4,0,
693,17,416,4,0,
693,17,430,4,0,
693,17,453,1,49,
693,17,474,4,0,
693,17,479,1,42,
693,17,479,4,0,
693,17,482,4,0,
693,17,496,4,0,
693,17,503,4,0,
693,17,505,1,1,4
693,17,590,4,0,
693,18,11,1,1,8
693,18,11,1,9,
693,18,14,1,25,
693,18,14,4,0,
693,18,55,1,1,6
693,18,57,4,0,
693,18,58,4,0,
693,18,61,1,20,
693,18,63,4,0,
693,18,92,4,0,
693,18,104,4,0,
693,18,127,4,0,
693,18,145,1,12,
693,18,150,1,1,5
693,18,152,1,30,
693,18,156,4,0,
693,18,157,4,0,
693,18,164,4,0,
693,18,175,1,16,
693,18,182,4,0,
693,18,188,4,0,
693,18,207,4,0,
693,18,213,4,0,
693,18,214,4,0,
693,18,216,4,0,
693,18,218,4,0,
693,18,237,4,0,
693,18,240,4,0,
693,18,247,4,0,
693,18,263,4,0,
693,18,330,1,57,
693,18,346,1,1,7
693,18,346,1,7,
693,18,352,1,34,
693,18,369,4,0,
693,18,396,1,0,
693,18,396,1,1,1
693,18,399,1,1,2
693,18,399,4,0,
693,18,406,1,1,3
693,18,411,4,0,
693,18,416,4,0,
693,18,430,4,0,
693,18,453,1,49,
693,18,474,4,0,
693,18,479,1,42,
693,18,479,4,0,
693,18,482,4,0,
693,18,496,4,0,
693,18,503,4,0,
693,18,505,1,1,4
693,18,590,4,0,
694,15,1,1,1,1
694,15,13,1,22,
694,15,15,4,0,
694,15,39,1,1,2
694,15,57,4,0,
694,15,84,1,6,
694,15,85,1,49,
694,15,85,4,0,
694,15,86,1,31,
694,15,86,4,0,
694,15,87,4,0,
694,15,91,4,0,
694,15,92,4,0,
694,15,97,2,0,
694,15,98,1,17,
694,15,104,4,0,
694,15,113,4,0,
694,15,137,2,0,
694,15,148,4,0,
694,15,156,4,0,
694,15,157,4,0,
694,15,164,4,0,
694,15,182,4,0,
694,15,189,1,13,
694,15,201,4,0,
694,15,207,4,0,
694,15,213,4,0,
694,15,214,4,0,
694,15,216,4,0,
694,15,218,4,0,
694,15,237,4,0,
694,15,240,4,0,
694,15,244,4,0,
694,15,263,4,0,
694,15,268,1,11,
694,15,293,2,0,
694,15,317,4,0,
694,15,369,4,0,
694,15,399,4,0,
694,15,447,4,0,
694,15,451,4,0,
694,15,490,4,0,
694,15,496,4,0,
694,15,521,1,40,
694,15,521,4,0,
694,15,523,1,35,
694,15,523,4,0,
694,15,525,4,0,
694,15,528,4,0,
694,15,570,1,25,
694,15,582,1,45,
694,15,590,4,0,
694,15,604,2,0,
694,16,1,1,1,1
694,16,13,1,22,1
694,16,15,4,0,
694,16,39,1,1,2
694,16,57,4,0,
694,16,84,1,6,1
694,16,85,1,49,1
694,16,85,4,0,
694,16,86,1,31,1
694,16,86,4,0,
694,16,87,4,0,
694,16,91,4,0,
694,16,92,4,0,
694,16,97,2,0,
694,16,98,1,17,1
694,16,104,4,0,
694,16,113,4,0,
694,16,137,2,0,
694,16,148,4,0,
694,16,156,4,0,
694,16,157,4,0,
694,16,164,4,0,
694,16,173,3,0,
694,16,182,4,0,
694,16,189,1,13,1
694,16,201,4,0,
694,16,207,4,0,
694,16,213,4,0,
694,16,214,4,0,
694,16,216,4,0,
694,16,218,4,0,
694,16,231,3,0,
694,16,237,4,0,
694,16,240,4,0,
694,16,244,4,0,
694,16,263,4,0,
694,16,268,1,11,1
694,16,290,4,0,
694,16,293,2,0,
694,16,317,4,0,
694,16,324,3,0,
694,16,351,3,0,
694,16,369,4,0,
694,16,393,3,0,
694,16,399,4,0,
694,16,447,4,0,
694,16,451,4,0,
694,16,490,4,0,
694,16,496,4,0,
694,16,521,1,40,1
694,16,521,4,0,
694,16,523,1,35,1
694,16,523,4,0,
694,16,525,4,0,
694,16,527,3,0,
694,16,528,4,0,
694,16,570,1,25,1
694,16,582,1,45,1
694,16,590,4,0,
694,16,604,2,0,
694,17,1,1,1,1
694,17,13,1,22,
694,17,39,1,1,2
694,17,57,4,0,
694,17,84,1,6,
694,17,85,1,49,
694,17,85,4,0,
694,17,86,1,31,
694,17,86,4,0,
694,17,87,4,0,
694,17,92,4,0,
694,17,97,2,0,
694,17,98,1,17,
694,17,104,4,0,
694,17,113,4,0,
694,17,137,2,0,
694,17,156,4,0,
694,17,157,4,0,
694,17,164,4,0,
694,17,182,4,0,
694,17,189,1,13,
694,17,201,4,0,
694,17,207,4,0,
694,17,213,4,0,
694,17,214,4,0,
694,17,216,4,0,
694,17,218,4,0,
694,17,237,4,0,
694,17,240,4,0,
694,17,244,4,0,
694,17,263,4,0,
694,17,268,1,11,
694,17,293,2,0,
694,17,317,4,0,
694,17,369,4,0,
694,17,399,4,0,
694,17,447,4,0,
694,17,451,4,0,
694,17,490,4,0,
694,17,496,4,0,
694,17,521,1,40,
694,17,521,4,0,
694,17,523,1,35,
694,17,523,4,0,
694,17,525,4,0,
694,17,528,4,0,
694,17,570,1,25,
694,17,582,1,45,
694,17,590,4,0,
694,17,604,2,0,
694,18,1,1,1,1
694,18,13,1,22,
694,18,39,1,1,2
694,18,57,4,0,
694,18,84,1,6,
694,18,85,1,49,
694,18,85,4,0,
694,18,86,1,31,
694,18,86,4,0,
694,18,87,4,0,
694,18,92,4,0,
694,18,97,2,0,
694,18,98,1,17,
694,18,104,4,0,
694,18,113,4,0,
694,18,137,2,0,
694,18,156,4,0,
694,18,157,4,0,
694,18,164,4,0,
694,18,182,4,0,
694,18,189,1,13,
694,18,201,4,0,
694,18,207,4,0,
694,18,213,4,0,
694,18,214,4,0,
694,18,216,4,0,
694,18,218,4,0,
694,18,237,4,0,
694,18,240,4,0,
694,18,244,4,0,
694,18,263,4,0,
694,18,268,1,11,
694,18,293,2,0,
694,18,317,4,0,
694,18,369,4,0,
694,18,399,4,0,
694,18,447,4,0,
694,18,451,4,0,
694,18,490,4,0,
694,18,496,4,0,
694,18,521,1,40,
694,18,521,4,0,
694,18,523,1,35,
694,18,523,4,0,
694,18,525,4,0,
694,18,528,4,0,
694,18,570,1,25,
694,18,582,1,45,
694,18,590,4,0,
694,18,604,2,0,
695,15,13,1,1,3
695,15,15,4,0,
695,15,57,4,0,
695,15,63,4,0,
695,15,85,4,0,
695,15,86,4,0,
695,15,87,1,1,5
695,15,87,4,0,
695,15,91,4,0,
695,15,92,4,0,
695,15,98,1,1,4
695,15,104,4,0,
695,15,113,4,0,
695,15,148,4,0,
695,15,156,4,0,
695,15,157,4,0,
695,15,164,4,0,
695,15,182,4,0,
695,15,201,4,0,
695,15,207,4,0,
695,15,213,4,0,
695,15,214,4,0,
695,15,216,4,0,
695,15,218,4,0,
695,15,237,4,0,
695,15,240,4,0,
695,15,244,4,0,
695,15,263,4,0,
695,15,268,1,1,6
695,15,317,4,0,
695,15,369,4,0,
695,15,399,4,0,
695,15,411,4,0,
695,15,416,4,0,
695,15,447,4,0,
695,15,451,4,0,
695,15,490,4,0,
695,15,496,4,0,
695,15,521,4,0,
695,15,523,4,0,
695,15,525,4,0,
695,15,528,4,0,
695,15,570,1,1,7
695,15,582,1,1,2
695,15,590,4,0,
695,15,598,1,1,1
695,16,7,3,0,
695,16,9,3,0,
695,16,13,1,1,3
695,16,15,4,0,
695,16,57,4,0,
695,16,63,4,0,
695,16,67,3,0,
695,16,85,4,0,
695,16,86,4,0,
695,16,87,1,1,5
695,16,87,4,0,
695,16,91,4,0,
695,16,92,4,0,
695,16,98,1,1,4
695,16,104,4,0,
695,16,113,4,0,
695,16,148,4,0,
695,16,156,4,0,
695,16,157,4,0,
695,16,164,4,0,
695,16,173,3,0,
695,16,182,4,0,
695,16,201,4,0,
695,16,207,4,0,
695,16,213,4,0,
695,16,214,4,0,
695,16,216,4,0,
695,16,218,4,0,
695,16,231,3,0,
695,16,237,4,0,
695,16,240,4,0,
695,16,244,4,0,
695,16,263,4,0,
695,16,268,1,1,6
695,16,290,4,0,
695,16,304,3,0,
695,16,317,4,0,
695,16,324,3,0,
695,16,351,3,0,
695,16,369,4,0,
695,16,393,3,0,
695,16,399,4,0,
695,16,406,3,0,
695,16,411,4,0,
695,16,416,4,0,
695,16,447,4,0,
695,16,451,4,0,
695,16,490,4,0,
695,16,496,4,0,
695,16,521,4,0,
695,16,523,4,0,
695,16,525,4,0,
695,16,527,3,0,
695,16,528,4,0,
695,16,570,1,1,7
695,16,582,1,1,2
695,16,590,4,0,
695,16,598,1,1,1
695,17,13,1,1,3
695,17,57,4,0,
695,17,63,4,0,
695,17,85,4,0,
695,17,86,4,0,
695,17,87,1,1,5
695,17,87,4,0,
695,17,92,4,0,
695,17,98,1,1,4
695,17,104,4,0,
695,17,113,4,0,
695,17,156,4,0,
695,17,157,4,0,
695,17,164,4,0,
695,17,182,4,0,
695,17,201,4,0,
695,17,207,4,0,
695,17,213,4,0,
695,17,214,4,0,
695,17,216,4,0,
695,17,218,4,0,
695,17,237,4,0,
695,17,240,4,0,
695,17,244,4,0,
695,17,263,4,0,
695,17,268,1,1,6
695,17,317,4,0,
695,17,369,4,0,
695,17,399,4,0,
695,17,411,4,0,
695,17,416,4,0,
695,17,447,4,0,
695,17,451,4,0,
695,17,490,4,0,
695,17,496,4,0,
695,17,521,4,0,
695,17,523,4,0,
695,17,525,4,0,
695,17,528,4,0,
695,17,570,1,1,7
695,17,582,1,1,2
695,17,590,4,0,
695,17,598,1,1,1
695,17,693,4,0,
695,18,13,1,1,3
695,18,57,4,0,
695,18,63,4,0,
695,18,85,4,0,
695,18,86,4,0,
695,18,87,1,1,5
695,18,87,4,0,
695,18,92,4,0,
695,18,98,1,1,4
695,18,104,4,0,
695,18,113,4,0,
695,18,156,4,0,
695,18,157,4,0,
695,18,164,4,0,
695,18,182,4,0,
695,18,201,4,0,
695,18,207,4,0,
695,18,213,4,0,
695,18,214,4,0,
695,18,216,4,0,
695,18,218,4,0,
695,18,237,4,0,
695,18,240,4,0,
695,18,244,4,0,
695,18,263,4,0,
695,18,268,1,1,6
695,18,317,4,0,
695,18,369,4,0,
695,18,399,4,0,
695,18,411,4,0,
695,18,416,4,0,
695,18,447,4,0,
695,18,451,4,0,
695,18,490,4,0,
695,18,496,4,0,
695,18,521,4,0,
695,18,523,4,0,
695,18,525,4,0,
695,18,528,4,0,
695,18,570,1,1,7
695,18,582,1,1,2
695,18,590,4,0,
695,18,598,1,1,1
695,18,693,4,0,
696,15,23,1,10,
696,15,32,1,49,
696,15,33,1,1,2
696,15,37,1,40,
696,15,39,1,1,1
696,15,44,1,17,
696,15,46,1,6,
696,15,46,4,0,
696,15,70,4,0,
696,15,89,1,44,
696,15,89,4,0,
696,15,91,4,0,
696,15,92,4,0,
696,15,104,4,0,
696,15,117,1,12,
696,15,156,4,0,
696,15,157,4,0,
696,15,164,4,0,
696,15,174,2,0,
696,15,182,4,0,
696,15,201,4,0,
696,15,204,1,20,
696,15,207,4,0,
696,15,213,4,0,
696,15,214,4,0,
696,15,216,4,0,
696,15,218,4,0,
696,15,237,4,0,
696,15,241,4,0,
696,15,242,1,34,
696,15,246,1,26,
696,15,249,4,0,
696,15,263,4,0,
696,15,280,4,0,
696,15,305,2,0,
696,15,317,4,0,
696,15,332,4,0,
696,15,337,1,37,
696,15,337,4,0,
696,15,349,2,0,
696,15,397,2,0,
696,15,397,4,0,
696,15,399,4,0,
696,15,422,2,0,
696,15,423,2,0,
696,15,424,2,0,
696,15,434,3,0,
696,15,444,4,0,
696,15,446,1,15,
696,15,468,4,0,
696,15,496,4,0,
696,15,523,4,0,
696,15,525,1,30,
696,15,525,4,0,
696,15,590,4,0,
696,16,23,1,10,1
696,16,32,1,49,1
696,16,33,1,1,2
696,16,37,1,40,1
696,16,39,1,1,1
696,16,44,1,17,1
696,16,46,1,6,1
696,16,46,4,0,
696,16,70,4,0,
696,16,89,1,44,1
696,16,89,4,0,
696,16,91,4,0,
696,16,92,4,0,
696,16,104,4,0,
696,16,117,1,12,1
696,16,156,4,0,
696,16,157,4,0,
696,16,164,4,0,
696,16,173,3,0,
696,16,174,2,0,
696,16,182,4,0,
696,16,200,3,0,
696,16,201,4,0,
696,16,204,1,20,1
696,16,207,4,0,
696,16,213,4,0,
696,16,214,4,0,
696,16,216,4,0,
696,16,218,4,0,
696,16,231,3,0,
696,16,237,4,0,
696,16,241,4,0,
696,16,242,1,34,1
696,16,246,1,26,1
696,16,249,4,0,
696,16,263,4,0,
696,16,276,3,0,
696,16,280,4,0,
696,16,290,4,0,
696,16,304,3,0,
696,16,305,2,0,
696,16,317,4,0,
696,16,332,4,0,
696,16,334,3,0,
696,16,335,3,0,
696,16,337,1,37,1
696,16,337,4,0,
696,16,349,2,0,
696,16,397,2,0,
696,16,397,4,0,
696,16,399,4,0,
696,16,406,3,0,
696,16,414,3,0,
696,16,422,2,0,
696,16,423,2,0,
696,16,424,2,0,
696,16,428,3,0,
696,16,434,3,0,
696,16,442,3,0,
696,16,444,4,0,
696,16,446,1,15,1
696,16,446,3,0,
696,16,468,4,0,
696,16,496,4,0,
696,16,523,4,0,
696,16,525,1,30,1
696,16,525,4,0,
696,16,590,4,0,
696,17,23,1,10,
696,17,32,1,49,
696,17,33,1,1,2
696,17,37,1,40,
696,17,39,1,1,1
696,17,44,1,17,
696,17,46,1,6,
696,17,46,4,0,
696,17,89,1,44,
696,17,89,4,0,
696,17,92,4,0,
696,17,104,4,0,
696,17,117,1,12,
696,17,156,4,0,
696,17,157,4,0,
696,17,164,4,0,
696,17,174,2,0,
696,17,182,4,0,
696,17,201,4,0,
696,17,204,1,20,
696,17,207,4,0,
696,17,213,4,0,
696,17,214,4,0,
696,17,216,4,0,
696,17,218,4,0,
696,17,237,4,0,
696,17,241,4,0,
696,17,242,1,34,
696,17,246,1,26,
696,17,263,4,0,
696,17,280,4,0,
696,17,305,2,0,
696,17,317,4,0,
696,17,332,4,0,
696,17,337,1,37,
696,17,337,4,0,
696,17,349,2,0,
696,17,397,2,0,
696,17,397,4,0,
696,17,399,4,0,
696,17,422,2,0,
696,17,423,2,0,
696,17,424,2,0,
696,17,434,3,0,
696,17,444,4,0,
696,17,446,1,15,
696,17,496,4,0,
696,17,523,4,0,
696,17,525,1,30,
696,17,525,4,0,
696,17,590,4,0,
696,18,23,1,10,
696,18,32,1,49,
696,18,33,1,1,2
696,18,37,1,40,
696,18,39,1,1,1
696,18,44,1,17,
696,18,46,1,6,
696,18,46,4,0,
696,18,89,1,44,
696,18,89,4,0,
696,18,92,4,0,
696,18,104,4,0,
696,18,117,1,12,
696,18,156,4,0,
696,18,157,4,0,
696,18,164,4,0,
696,18,174,2,0,
696,18,182,4,0,
696,18,201,4,0,
696,18,204,1,20,
696,18,207,4,0,
696,18,213,4,0,
696,18,214,4,0,
696,18,216,4,0,
696,18,218,4,0,
696,18,237,4,0,
696,18,241,4,0,
696,18,242,1,34,
696,18,246,1,26,
696,18,263,4,0,
696,18,280,4,0,
696,18,305,2,0,
696,18,317,4,0,
696,18,332,4,0,
696,18,337,1,37,
696,18,337,4,0,
696,18,349,2,0,
696,18,397,2,0,
696,18,397,4,0,
696,18,399,4,0,
696,18,422,2,0,
696,18,423,2,0,
696,18,424,2,0,
696,18,434,3,0,
696,18,444,4,0,
696,18,446,1,15,
696,18,496,4,0,
696,18,523,4,0,
696,18,525,1,30,
696,18,525,4,0,
696,18,590,4,0,
697,15,23,1,10,
697,15,32,1,53,
697,15,33,1,1,3
697,15,37,1,42,
697,15,39,1,1,2
697,15,44,1,17,
697,15,46,1,6,
697,15,46,4,0,
697,15,63,4,0,
697,15,70,4,0,
697,15,89,1,47,
697,15,89,4,0,
697,15,91,4,0,
697,15,92,4,0,
697,15,104,4,0,
697,15,117,1,12,
697,15,156,4,0,
697,15,157,1,68,
697,15,157,4,0,
697,15,164,4,0,
697,15,182,4,0,
697,15,201,4,0,
697,15,204,1,20,
697,15,207,4,0,
697,15,213,4,0,
697,15,214,4,0,
697,15,216,4,0,
697,15,218,4,0,
697,15,237,4,0,
697,15,241,4,0,
697,15,242,1,34,
697,15,246,1,26,
697,15,249,4,0,
697,15,263,4,0,
697,15,280,4,0,
697,15,317,4,0,
697,15,332,4,0,
697,15,337,1,37,
697,15,337,4,0,
697,15,397,4,0,
697,15,399,4,0,
697,15,416,1,75,
697,15,416,4,0,
697,15,434,3,0,
697,15,444,4,0,
697,15,446,1,15,
697,15,457,1,1,1
697,15,457,1,58,
697,15,468,4,0,
697,15,496,4,0,
697,15,523,4,0,
697,15,525,1,30,
697,15,525,4,0,
697,15,590,4,0,
697,16,23,1,10,1
697,16,32,1,53,1
697,16,33,1,1,3
697,16,37,1,42,1
697,16,39,1,1,2
697,16,44,1,17,1
697,16,46,1,6,1
697,16,46,4,0,
697,16,63,4,0,
697,16,70,4,0,
697,16,89,1,47,1
697,16,89,4,0,
697,16,91,4,0,
697,16,92,4,0,
697,16,104,4,0,
697,16,117,1,12,1
697,16,156,4,0,
697,16,157,1,68,1
697,16,157,4,0,
697,16,164,4,0,
697,16,173,3,0,
697,16,182,4,0,
697,16,200,3,0,
697,16,201,4,0,
697,16,204,1,20,1
697,16,207,4,0,
697,16,213,4,0,
697,16,214,4,0,
697,16,216,4,0,
697,16,218,4,0,
697,16,231,3,0,
697,16,237,4,0,
697,16,241,4,0,
697,16,242,1,34,1
697,16,246,1,26,1
697,16,249,4,0,
697,16,263,4,0,
697,16,276,3,0,
697,16,280,4,0,
697,16,290,4,0,
697,16,304,3,0,
697,16,317,4,0,
697,16,332,4,0,
697,16,334,3,0,
697,16,335,3,0,
697,16,337,1,37,1
697,16,337,4,0,
697,16,397,4,0,
697,16,399,4,0,
697,16,406,3,0,
697,16,414,3,0,
697,16,416,1,75,1
697,16,416,4,0,
697,16,428,3,0,
697,16,434,3,0,
697,16,442,3,0,
697,16,444,4,0,
697,16,446,1,15,1
697,16,446,3,0,
697,16,457,1,1,1
697,16,457,1,58,1
697,16,468,4,0,
697,16,496,4,0,
697,16,523,4,0,
697,16,525,1,30,1
697,16,525,4,0,
697,16,590,4,0,
697,17,23,1,1,6
697,17,23,1,10,
697,17,32,1,53,
697,17,33,1,1,4
697,17,37,1,42,
697,17,39,1,1,3
697,17,44,1,17,
697,17,46,1,1,5
697,17,46,1,6,
697,17,46,4,0,
697,17,63,4,0,
697,17,89,1,47,
697,17,89,4,0,
697,17,92,4,0,
697,17,104,4,0,
697,17,117,1,12,
697,17,156,4,0,
697,17,157,1,0,
697,17,157,1,1,1
697,17,157,4,0,
697,17,164,4,0,
697,17,182,4,0,
697,17,201,4,0,
697,17,204,1,20,
697,17,207,4,0,
697,17,213,4,0,
697,17,214,4,0,
697,17,216,4,0,
697,17,218,4,0,
697,17,237,4,0,
697,17,241,4,0,
697,17,242,1,34,
697,17,246,1,26,
697,17,263,4,0,
697,17,280,4,0,
697,17,317,4,0,
697,17,332,4,0,
697,17,337,1,37,
697,17,337,4,0,
697,17,397,4,0,
697,17,399,4,0,
697,17,416,1,68,
697,17,416,4,0,
697,17,434,3,0,
697,17,444,4,0,
697,17,446,1,15,
697,17,457,1,1,2
697,17,457,1,58,
697,17,496,4,0,
697,17,523,4,0,
697,17,525,1,30,
697,17,525,4,0,
697,17,590,4,0,
697,17,693,4,0,
697,18,23,1,1,6
697,18,23,1,10,
697,18,32,1,53,
697,18,33,1,1,4
697,18,37,1,42,
697,18,39,1,1,3
697,18,44,1,17,
697,18,46,1,1,5
697,18,46,1,6,
697,18,46,4,0,
697,18,63,4,0,
697,18,89,1,47,
697,18,89,4,0,
697,18,92,4,0,
697,18,104,4,0,
697,18,117,1,12,
697,18,156,4,0,
697,18,157,1,0,
697,18,157,1,1,1
697,18,157,4,0,
697,18,164,4,0,
697,18,182,4,0,
697,18,201,4,0,
697,18,204,1,20,
697,18,207,4,0,
697,18,213,4,0,
697,18,214,4,0,
697,18,216,4,0,
697,18,218,4,0,
697,18,237,4,0,
697,18,241,4,0,
697,18,242,1,34,
697,18,246,1,26,
697,18,263,4,0,
697,18,280,4,0,
697,18,317,4,0,
697,18,332,4,0,
697,18,337,1,37,
697,18,337,4,0,
697,18,397,4,0,
697,18,399,4,0,
697,18,416,1,68,
697,18,416,4,0,
697,18,434,3,0,
697,18,444,4,0,
697,18,446,1,15,
697,18,457,1,1,2
697,18,457,1,58,
697,18,496,4,0,
697,18,523,4,0,
697,18,525,1,30,
697,18,525,4,0,
697,18,590,4,0,
697,18,693,4,0,
698,15,36,1,15,
698,15,45,1,1,1
698,15,46,4,0,
698,15,54,1,18,
698,15,58,1,50,
698,15,58,4,0,
698,15,59,1,65,
698,15,59,4,0,
698,15,62,1,20,
698,15,63,1,57,
698,15,63,4,0,
698,15,85,4,0,
698,15,86,1,5,
698,15,86,4,0,
698,15,88,1,10,
698,15,92,4,0,
698,15,104,4,0,
698,15,112,2,0,
698,15,113,1,47,
698,15,113,4,0,
698,15,114,2,0,
698,15,115,4,0,
698,15,138,4,0,
698,15,148,4,0,
698,15,156,4,0,
698,15,157,4,0,
698,15,164,4,0,
698,15,181,1,1,2
698,15,182,4,0,
698,15,196,1,13,
698,15,201,4,0,
698,15,207,4,0,
698,15,213,4,0,
698,15,214,4,0,
698,15,216,4,0,
698,15,218,4,0,
698,15,219,4,0,
698,15,227,1,44,
698,15,237,4,0,
698,15,240,4,0,
698,15,243,2,0,
698,15,244,4,0,
698,15,246,1,26,
698,15,249,4,0,
698,15,258,1,38,
698,15,258,4,0,
698,15,263,4,0,
698,15,267,1,41,
698,15,267,4,0,
698,15,317,4,0,
698,15,347,4,0,
698,15,393,2,0,
698,15,397,4,0,
698,15,399,4,0,
698,15,419,1,34,
698,15,430,4,0,
698,15,435,2,0,
698,15,444,4,0,
698,15,451,4,0,
698,15,496,1,30,
698,15,496,4,0,
698,15,497,4,0,
698,15,523,4,0,
698,15,524,4,0,
698,15,525,4,0,
698,15,590,4,0,
698,16,36,1,15,1
698,16,45,1,1,1
698,16,46,4,0,
698,16,54,1,18,1
698,16,58,1,50,1
698,16,58,4,0,
698,16,59,1,65,1
698,16,59,4,0,
698,16,62,1,20,1
698,16,63,1,57,1
698,16,63,4,0,
698,16,85,4,0,
698,16,86,1,5,1
698,16,86,4,0,
698,16,88,1,10,1
698,16,92,4,0,
698,16,104,4,0,
698,16,112,2,0,
698,16,113,1,47,1
698,16,113,4,0,
698,16,114,2,0,
698,16,115,4,0,
698,16,138,4,0,
698,16,148,4,0,
698,16,156,4,0,
698,16,157,4,0,
698,16,164,4,0,
698,16,173,3,0,
698,16,181,1,1,2
698,16,182,4,0,
698,16,196,1,13,1
698,16,196,3,0,
698,16,200,3,0,
698,16,201,4,0,
698,16,207,4,0,
698,16,213,4,0,
698,16,214,4,0,
698,16,216,4,0,
698,16,218,4,0,
698,16,219,4,0,
698,16,227,1,44,1
698,16,231,3,0,
698,16,237,4,0,
698,16,240,4,0,
698,16,243,2,0,
698,16,244,4,0,
698,16,246,1,26,1
698,16,249,4,0,
698,16,258,1,38,1
698,16,258,4,0,
698,16,263,4,0,
698,16,267,1,41,1
698,16,267,4,0,
698,16,290,4,0,
698,16,304,3,0,
698,16,317,4,0,
698,16,334,3,0,
698,16,347,4,0,
698,16,352,3,0,
698,16,393,2,0,
698,16,393,3,0,
698,16,397,4,0,
698,16,399,4,0,
698,16,401,3,0,
698,16,414,3,0,
698,16,419,1,34,1
698,16,428,3,0,
698,16,430,4,0,
698,16,435,2,0,
698,16,442,3,0,
698,16,444,4,0,
698,16,446,3,0,
698,16,451,4,0,
698,16,496,1,30,1
698,16,496,4,0,
698,16,497,4,0,
698,16,523,4,0,
698,16,524,4,0,
698,16,525,4,0,
698,16,590,4,0,
698,17,36,1,15,
698,17,45,1,1,1
698,17,46,4,0,
698,17,54,1,18,
698,17,58,1,50,
698,17,58,4,0,
698,17,59,1,65,
698,17,59,4,0,
698,17,62,1,20,
698,17,63,1,57,
698,17,63,4,0,
698,17,85,4,0,
698,17,86,1,5,
698,17,86,4,0,
698,17,88,1,10,
698,17,92,4,0,
698,17,104,4,0,
698,17,112,2,0,
698,17,113,1,47,
698,17,113,4,0,
698,17,114,2,0,
698,17,115,4,0,
698,17,138,4,0,
698,17,156,4,0,
698,17,157,4,0,
698,17,164,4,0,
698,17,181,1,1,2
698,17,182,4,0,
698,17,196,1,13,
698,17,201,4,0,
698,17,207,4,0,
698,17,213,4,0,
698,17,214,4,0,
698,17,216,4,0,
698,17,218,4,0,
698,17,219,4,0,
698,17,227,1,44,
698,17,237,4,0,
698,17,240,4,0,
698,17,243,2,0,
698,17,244,4,0,
698,17,246,1,26,
698,17,258,1,38,
698,17,258,4,0,
698,17,263,4,0,
698,17,267,1,41,
698,17,267,4,0,
698,17,317,4,0,
698,17,347,4,0,
698,17,393,2,0,
698,17,397,4,0,
698,17,399,4,0,
698,17,419,1,34,
698,17,430,4,0,
698,17,435,2,0,
698,17,444,4,0,
698,17,451,4,0,
698,17,496,1,30,
698,17,496,4,0,
698,17,497,4,0,
698,17,523,4,0,
698,17,524,4,0,
698,17,525,4,0,
698,17,590,4,0,
698,18,36,1,15,
698,18,45,1,1,1
698,18,46,4,0,
698,18,54,1,18,
698,18,58,1,50,
698,18,58,4,0,
698,18,59,1,65,
698,18,59,4,0,
698,18,62,1,20,
698,18,63,1,57,
698,18,63,4,0,
698,18,85,4,0,
698,18,86,1,5,
698,18,86,4,0,
698,18,88,1,10,
698,18,92,4,0,
698,18,104,4,0,
698,18,112,2,0,
698,18,113,1,47,
698,18,113,4,0,
698,18,114,2,0,
698,18,115,4,0,
698,18,138,4,0,
698,18,156,4,0,
698,18,157,4,0,
698,18,164,4,0,
698,18,181,1,1,2
698,18,182,4,0,
698,18,196,1,13,
698,18,201,4,0,
698,18,207,4,0,
698,18,213,4,0,
698,18,214,4,0,
698,18,216,4,0,
698,18,218,4,0,
698,18,219,4,0,
698,18,227,1,44,
698,18,237,4,0,
698,18,240,4,0,
698,18,243,2,0,
698,18,244,4,0,
698,18,246,1,26,
698,18,258,1,38,
698,18,258,4,0,
698,18,263,4,0,
698,18,267,1,41,
698,18,267,4,0,
698,18,317,4,0,
698,18,347,4,0,
698,18,393,2,0,
698,18,397,4,0,
698,18,399,4,0,
698,18,419,1,34,
698,18,430,4,0,
698,18,435,2,0,
698,18,444,4,0,
698,18,451,4,0,
698,18,496,1,30,
698,18,496,4,0,
698,18,497,4,0,
698,18,523,4,0,
698,18,524,4,0,
698,18,525,4,0,
698,18,590,4,0,
699,15,36,1,15,
699,15,45,1,1,2
699,15,46,4,0,
699,15,54,1,18,
699,15,58,1,56,
699,15,58,4,0,
699,15,59,1,74,
699,15,59,4,0,
699,15,62,1,20,
699,15,63,1,63,
699,15,63,4,0,
699,15,85,4,0,
699,15,86,1,5,
699,15,86,4,0,
699,15,87,4,0,
699,15,88,1,10,
699,15,89,4,0,
699,15,92,4,0,
699,15,94,4,0,
699,15,104,4,0,
699,15,113,1,50,
699,15,113,4,0,
699,15,115,4,0,
699,15,138,4,0,
699,15,148,4,0,
699,15,156,4,0,
699,15,157,4,0,
699,15,164,4,0,
699,15,181,1,1,3
699,15,182,4,0,
699,15,196,1,13,
699,15,201,4,0,
699,15,207,4,0,
699,15,213,4,0,
699,15,214,4,0,
699,15,216,4,0,
699,15,218,4,0,
699,15,219,4,0,
699,15,227,1,46,
699,15,237,4,0,
699,15,240,4,0,
699,15,244,4,0,
699,15,246,1,26,
699,15,249,4,0,
699,15,258,1,38,
699,15,258,4,0,
699,15,263,4,0,
699,15,267,1,43,
699,15,267,4,0,
699,15,317,4,0,
699,15,347,4,0,
699,15,397,4,0,
699,15,399,4,0,
699,15,416,4,0,
699,15,419,1,34,
699,15,430,4,0,
699,15,444,4,0,
699,15,451,4,0,
699,15,496,1,30,
699,15,496,4,0,
699,15,497,4,0,
699,15,523,4,0,
699,15,524,4,0,
699,15,525,4,0,
699,15,573,1,1,1
699,15,573,1,77,
699,15,590,4,0,
699,16,36,1,15,1
699,16,45,1,1,2
699,16,46,4,0,
699,16,54,1,18,1
699,16,58,1,56,1
699,16,58,4,0,
699,16,59,1,74,1
699,16,59,4,0,
699,16,62,1,20,1
699,16,63,1,63,1
699,16,63,4,0,
699,16,85,4,0,
699,16,86,1,5,1
699,16,86,4,0,
699,16,87,4,0,
699,16,88,1,10,1
699,16,89,4,0,
699,16,92,4,0,
699,16,94,4,0,
699,16,104,4,0,
699,16,113,1,50,1
699,16,113,4,0,
699,16,115,4,0,
699,16,138,4,0,
699,16,148,4,0,
699,16,156,4,0,
699,16,157,4,0,
699,16,164,4,0,
699,16,173,3,0,
699,16,181,1,1,3
699,16,182,4,0,
699,16,196,1,13,1
699,16,196,3,0,
699,16,200,3,0,
699,16,201,4,0,
699,16,207,4,0,
699,16,213,4,0,
699,16,214,4,0,
699,16,216,4,0,
699,16,218,4,0,
699,16,219,4,0,
699,16,227,1,46,1
699,16,231,3,0,
699,16,237,4,0,
699,16,240,4,0,
699,16,244,4,0,
699,16,246,1,26,1
699,16,249,4,0,
699,16,258,1,38,1
699,16,258,4,0,
699,16,263,4,0,
699,16,267,1,43,1
699,16,267,4,0,
699,16,290,4,0,
699,16,304,3,0,
699,16,317,4,0,
699,16,334,3,0,
699,16,347,4,0,
699,16,352,3,0,
699,16,393,3,0,
699,16,397,4,0,
699,16,399,4,0,
699,16,401,3,0,
699,16,414,3,0,
699,16,416,4,0,
699,16,419,1,34,1
699,16,428,3,0,
699,16,430,4,0,
699,16,442,3,0,
699,16,444,4,0,
699,16,446,3,0,
699,16,451,4,0,
699,16,496,1,30,1
699,16,496,4,0,
699,16,497,4,0,
699,16,523,4,0,
699,16,524,4,0,
699,16,525,4,0,
699,16,573,1,1,1
699,16,573,1,77,1
699,16,590,4,0,
699,17,36,1,15,
699,17,45,1,1,2
699,17,46,4,0,
699,17,54,1,18,
699,17,58,1,56,
699,17,58,4,0,
699,17,59,1,74,
699,17,59,4,0,
699,17,62,1,20,
699,17,63,1,63,
699,17,63,4,0,
699,17,85,4,0,
699,17,86,1,1,4
699,17,86,1,5,
699,17,86,4,0,
699,17,87,4,0,
699,17,88,1,1,5
699,17,88,1,10,
699,17,89,4,0,
699,17,92,4,0,
699,17,94,4,0,
699,17,104,4,0,
699,17,113,1,50,
699,17,113,4,0,
699,17,115,4,0,
699,17,138,4,0,
699,17,156,4,0,
699,17,157,4,0,
699,17,164,4,0,
699,17,181,1,1,3
699,17,182,4,0,
699,17,196,1,13,
699,17,201,4,0,
699,17,207,4,0,
699,17,213,4,0,
699,17,214,4,0,
699,17,216,4,0,
699,17,218,4,0,
699,17,219,4,0,
699,17,227,1,46,
699,17,237,4,0,
699,17,240,4,0,
699,17,244,4,0,
699,17,246,1,26,
699,17,258,1,38,
699,17,258,4,0,
699,17,263,4,0,
699,17,267,1,43,
699,17,267,4,0,
699,17,317,4,0,
699,17,347,4,0,
699,17,397,4,0,
699,17,399,4,0,
699,17,416,4,0,
699,17,419,1,34,
699,17,430,4,0,
699,17,444,4,0,
699,17,451,4,0,
699,17,496,1,30,
699,17,496,4,0,
699,17,497,4,0,
699,17,523,4,0,
699,17,524,4,0,
699,17,525,4,0,
699,17,573,1,0,
699,17,573,1,1,1
699,17,590,4,0,
699,18,36,1,15,
699,18,45,1,1,2
699,18,46,4,0,
699,18,54,1,18,
699,18,58,1,56,
699,18,58,4,0,
699,18,59,1,74,
699,18,59,4,0,
699,18,62,1,20,
699,18,63,1,63,
699,18,63,4,0,
699,18,85,4,0,
699,18,86,1,1,4
699,18,86,1,5,
699,18,86,4,0,
699,18,87,4,0,
699,18,88,1,1,5
699,18,88,1,10,
699,18,89,4,0,
699,18,92,4,0,
699,18,94,4,0,
699,18,104,4,0,
699,18,113,1,50,
699,18,113,4,0,
699,18,115,4,0,
699,18,138,4,0,
699,18,156,4,0,
699,18,157,4,0,
699,18,164,4,0,
699,18,181,1,1,3
699,18,182,4,0,
699,18,196,1,13,
699,18,201,4,0,
699,18,207,4,0,
699,18,213,4,0,
699,18,214,4,0,
699,18,216,4,0,
699,18,218,4,0,
699,18,219,4,0,
699,18,227,1,46,
699,18,237,4,0,
699,18,240,4,0,
699,18,244,4,0,
699,18,246,1,26,
699,18,258,1,38,
699,18,258,4,0,
699,18,263,4,0,
699,18,267,1,43,
699,18,267,4,0,
699,18,317,4,0,
699,18,347,4,0,
699,18,397,4,0,
699,18,399,4,0,
699,18,416,4,0,
699,18,419,1,34,
699,18,430,4,0,
699,18,444,4,0,
699,18,451,4,0,
699,18,496,1,30,
699,18,496,4,0,
699,18,497,4,0,
699,18,523,4,0,
699,18,524,4,0,
699,18,525,4,0,
699,18,573,1,0,
699,18,573,1,1,1
699,18,590,4,0,
700,15,15,4,0,
700,15,28,1,5,
700,15,33,1,1,3
700,15,39,1,1,2
700,15,63,4,0,
700,15,91,4,0,
700,15,92,4,0,
700,15,98,1,13,
700,15,104,4,0,
700,15,113,1,33,
700,15,113,4,0,
700,15,115,4,0,
700,15,129,1,17,
700,15,148,4,0,
700,15,156,4,0,
700,15,164,4,0,
700,15,182,4,0,
700,15,207,4,0,
700,15,213,4,0,
700,15,214,4,0,
700,15,216,4,0,
700,15,218,4,0,
700,15,219,4,0,
700,15,237,4,0,
700,15,240,4,0,
700,15,241,4,0,
700,15,244,1,45,
700,15,244,4,0,
700,15,247,4,0,
700,15,263,4,0,
700,15,270,1,1,4
700,15,285,1,25,
700,15,347,4,0,
700,15,387,1,41,
700,15,416,4,0,
700,15,473,4,0,
700,15,496,4,0,
700,15,497,4,0,
700,15,514,4,0,
700,15,574,1,1,1
700,15,577,1,20,
700,15,581,1,29,
700,15,584,1,9,
700,15,585,1,37,
700,15,590,4,0,
700,15,605,4,0,
700,16,15,4,0,
700,16,28,1,5,1
700,16,33,1,1,3
700,16,39,1,1,2
700,16,63,4,0,
700,16,91,4,0,
700,16,92,4,0,
700,16,98,1,13,1
700,16,104,4,0,
700,16,113,1,33,1
700,16,113,4,0,
700,16,115,4,0,
700,16,129,1,17,1
700,16,148,4,0,
700,16,156,4,0,
700,16,164,4,0,
700,16,173,3,0,
700,16,182,4,0,
700,16,207,4,0,
700,16,213,4,0,
700,16,214,4,0,
700,16,215,3,0,
700,16,216,4,0,
700,16,218,4,0,
700,16,219,4,0,
700,16,231,3,0,
700,16,237,4,0,
700,16,240,4,0,
700,16,241,4,0,
700,16,244,1,45,1
700,16,244,4,0,
700,16,247,4,0,
700,16,263,4,0,
700,16,270,1,1,4
700,16,270,3,0,
700,16,277,3,0,
700,16,285,1,25,1
700,16,285,3,0,
700,16,290,4,0,
700,16,304,3,0,
700,16,343,3,0,
700,16,347,4,0,
700,16,387,1,41,1
700,16,387,3,0,
700,16,416,4,0,
700,16,473,4,0,
700,16,496,4,0,
700,16,497,4,0,
700,16,514,4,0,
700,16,574,1,1,1
700,16,577,1,20,1
700,16,581,1,29,1
700,16,584,1,9,1
700,16,585,1,37,1
700,16,590,4,0,
700,16,605,4,0,
700,17,28,1,5,
700,17,33,1,1,4
700,17,39,1,1,5
700,17,63,4,0,
700,17,92,4,0,
700,17,98,1,13,
700,17,104,4,0,
700,17,113,1,33,
700,17,113,4,0,
700,17,115,4,0,
700,17,129,1,17,
700,17,156,4,0,
700,17,164,4,0,
700,17,182,4,0,
700,17,207,4,0,
700,17,213,4,0,
700,17,214,4,0,
700,17,216,4,0,
700,17,218,4,0,
700,17,219,4,0,
700,17,237,4,0,
700,17,240,4,0,
700,17,241,4,0,
700,17,244,1,45,
700,17,244,4,0,
700,17,247,4,0,
700,17,263,4,0,
700,17,270,1,1,3
700,17,285,1,25,
700,17,347,4,0,
700,17,387,1,41,
700,17,416,4,0,
700,17,473,4,0,
700,17,496,4,0,
700,17,497,4,0,
700,17,526,4,0,
700,17,574,1,1,2
700,17,577,1,20,
700,17,581,1,29,
700,17,584,1,0,
700,17,584,1,1,1
700,17,585,1,37,
700,17,590,4,0,
700,17,605,4,0,
700,17,608,1,9,
700,18,28,1,5,
700,18,33,1,1,4
700,18,39,1,1,5
700,18,63,4,0,
700,18,92,4,0,
700,18,98,1,13,
700,18,104,4,0,
700,18,113,1,33,
700,18,113,4,0,
700,18,115,4,0,
700,18,129,1,17,
700,18,156,4,0,
700,18,164,4,0,
700,18,182,4,0,
700,18,207,4,0,
700,18,213,4,0,
700,18,214,4,0,
700,18,216,4,0,
700,18,218,4,0,
700,18,219,4,0,
700,18,237,4,0,
700,18,240,4,0,
700,18,241,4,0,
700,18,244,1,45,
700,18,244,4,0,
700,18,247,4,0,
700,18,263,4,0,
700,18,270,1,1,3
700,18,285,1,25,
700,18,347,4,0,
700,18,387,1,41,
700,18,416,4,0,
700,18,473,4,0,
700,18,496,4,0,
700,18,497,4,0,
700,18,526,4,0,
700,18,574,1,1,2
700,18,577,1,20,
700,18,581,1,29,
700,18,584,1,0,
700,18,584,1,1,1
700,18,585,1,37,
700,18,590,4,0,
700,18,605,4,0,
700,18,608,1,9,
701,15,2,1,4,
701,15,14,1,60,
701,15,14,4,0,
701,15,15,4,0,
701,15,17,1,8,
701,15,19,4,0,
701,15,33,1,1,2
701,15,70,4,0,
701,15,91,4,0,
701,15,92,4,0,
701,15,97,2,0,
701,15,104,4,0,
701,15,136,1,44,
701,15,143,1,48,
701,15,156,4,0,
701,15,157,4,0,
701,15,164,4,0,
701,15,182,4,0,
701,15,197,1,1,1
701,15,206,4,0,
701,15,207,4,0,
701,15,211,4,0,
701,15,213,4,0,
701,15,214,4,0,
701,15,216,4,0,
701,15,218,4,0,
701,15,226,2,0,
701,15,227,1,20,
701,15,237,4,0,
701,15,240,4,0,
701,15,241,4,0,
701,15,249,4,0,
701,15,259,4,0,
701,15,263,4,0,
701,15,269,4,0,
701,15,280,4,0,
701,15,283,1,36,
701,15,297,1,40,
701,15,300,2,0,
701,15,317,4,0,
701,15,332,1,16,
701,15,332,4,0,
701,15,339,4,0,
701,15,340,1,32,
701,15,355,1,12,
701,15,355,4,0,
701,15,369,4,0,
701,15,371,4,0,
701,15,374,1,24,
701,15,374,4,0,
701,15,382,2,0,
701,15,398,4,0,
701,15,404,4,0,
701,15,411,4,0,
701,15,416,4,0,
701,15,444,4,0,
701,15,447,4,0,
701,15,468,1,1,3
701,15,468,4,0,
701,15,490,4,0,
701,15,494,2,0,
701,15,496,4,0,
701,15,501,2,0,
701,15,502,2,0,
701,15,507,1,55,
701,15,507,4,0,
701,15,512,4,0,
701,15,514,4,0,
701,15,560,1,28,
701,15,590,4,0,
701,15,612,4,0,
701,16,2,1,4,1
701,16,7,3,0,
701,16,9,3,0,
701,16,14,1,60,1
701,16,14,4,0,
701,16,15,4,0,
701,16,17,1,8,1
701,16,19,4,0,
701,16,33,1,1,2
701,16,67,3,0,
701,16,70,4,0,
701,16,91,4,0,
701,16,92,4,0,
701,16,97,2,0,
701,16,104,4,0,
701,16,136,1,44,1
701,16,143,1,48,1
701,16,143,3,0,
701,16,156,4,0,
701,16,157,4,0,
701,16,164,4,0,
701,16,173,3,0,
701,16,182,4,0,
701,16,197,1,1,1
701,16,206,4,0,
701,16,207,4,0,
701,16,211,4,0,
701,16,213,4,0,
701,16,214,4,0,
701,16,216,4,0,
701,16,218,4,0,
701,16,226,2,0,
701,16,227,1,20,1
701,16,237,4,0,
701,16,240,4,0,
701,16,241,4,0,
701,16,249,4,0,
701,16,259,4,0,
701,16,263,4,0,
701,16,264,3,0,
701,16,269,4,0,
701,16,270,3,0,
701,16,276,3,0,
701,16,280,4,0,
701,16,283,1,36,1
701,16,283,3,0,
701,16,290,4,0,
701,16,297,1,40,1
701,16,300,2,0,
701,16,317,4,0,
701,16,332,1,16,1
701,16,332,4,0,
701,16,339,4,0,
701,16,340,1,32,1
701,16,340,3,0,
701,16,355,1,12,1
701,16,355,4,0,
701,16,366,3,0,
701,16,369,4,0,
701,16,371,4,0,
701,16,374,1,24,1
701,16,374,4,0,
701,16,382,2,0,
701,16,387,3,0,
701,16,398,4,0,
701,16,404,4,0,
701,16,409,3,0,
701,16,411,4,0,
701,16,416,4,0,
701,16,428,3,0,
701,16,442,3,0,
701,16,444,4,0,
701,16,447,4,0,
701,16,468,1,1,3
701,16,468,4,0,
701,16,490,4,0,
701,16,494,2,0,
701,16,496,4,0,
701,16,501,2,0,
701,16,502,2,0,
701,16,507,1,55,1
701,16,507,4,0,
701,16,512,4,0,
701,16,514,4,0,
701,16,530,3,0,
701,16,560,1,28,1
701,16,590,4,0,
701,16,612,4,0,
701,17,2,1,4,
701,17,14,1,60,
701,17,14,4,0,
701,17,17,1,8,
701,17,19,4,0,
701,17,33,1,1,2
701,17,92,4,0,
701,17,97,2,0,
701,17,104,4,0,
701,17,136,1,44,
701,17,143,1,48,
701,17,156,4,0,
701,17,157,4,0,
701,17,164,4,0,
701,17,182,4,0,
701,17,197,1,1,1
701,17,206,4,0,
701,17,207,4,0,
701,17,211,4,0,
701,17,213,4,0,
701,17,214,4,0,
701,17,216,4,0,
701,17,218,4,0,
701,17,226,2,0,
701,17,227,1,20,
701,17,237,4,0,
701,17,240,4,0,
701,17,241,4,0,
701,17,259,4,0,
701,17,263,4,0,
701,17,269,4,0,
701,17,280,4,0,
701,17,283,1,36,
701,17,297,1,40,
701,17,300,2,0,
701,17,317,4,0,
701,17,332,1,16,
701,17,332,4,0,
701,17,339,4,0,
701,17,340,1,32,
701,17,355,1,12,
701,17,355,4,0,
701,17,369,4,0,
701,17,371,4,0,
701,17,374,1,24,
701,17,374,4,0,
701,17,382,2,0,
701,17,398,4,0,
701,17,404,4,0,
701,17,411,4,0,
701,17,416,4,0,
701,17,444,4,0,
701,17,447,4,0,
701,17,468,1,1,3
701,17,490,4,0,
701,17,494,2,0,
701,17,496,4,0,
701,17,501,2,0,
701,17,502,2,0,
701,17,507,1,55,
701,17,507,4,0,
701,17,512,4,0,
701,17,526,4,0,
701,17,560,1,28,
701,17,590,4,0,
701,18,2,1,4,
701,18,14,1,60,
701,18,14,4,0,
701,18,17,1,8,
701,18,19,4,0,
701,18,33,1,1,2
701,18,92,4,0,
701,18,97,2,0,
701,18,104,4,0,
701,18,136,1,44,
701,18,143,1,48,
701,18,156,4,0,
701,18,157,4,0,
701,18,164,4,0,
701,18,182,4,0,
701,18,197,1,1,1
701,18,206,4,0,
701,18,207,4,0,
701,18,211,4,0,
701,18,213,4,0,
701,18,214,4,0,
701,18,216,4,0,
701,18,218,4,0,
701,18,226,2,0,
701,18,227,1,20,
701,18,237,4,0,
701,18,240,4,0,
701,18,241,4,0,
701,18,259,4,0,
701,18,263,4,0,
701,18,269,4,0,
701,18,280,4,0,
701,18,283,1,36,
701,18,297,1,40,
701,18,300,2,0,
701,18,317,4,0,
701,18,332,1,16,
701,18,332,4,0,
701,18,339,4,0,
701,18,340,1,32,
701,18,355,1,12,
701,18,355,4,0,
701,18,364,2,0,
701,18,369,4,0,
701,18,371,4,0,
701,18,374,1,24,
701,18,374,4,0,
701,18,382,2,0,
701,18,398,4,0,
701,18,404,4,0,
701,18,411,4,0,
701,18,416,4,0,
701,18,444,4,0,
701,18,447,4,0,
701,18,468,1,1,3
701,18,490,4,0,
701,18,494,2,0,
701,18,496,4,0,
701,18,501,2,0,
701,18,502,2,0,
701,18,507,1,55,
701,18,507,4,0,
701,18,512,4,0,
701,18,526,4,0,
701,18,560,1,28,
701,18,590,4,0,
702,15,15,4,0,
702,15,33,1,1,1
702,15,39,1,1,2
702,15,84,1,7,
702,15,85,4,0,
702,15,86,1,23,
702,15,86,4,0,
702,15,87,1,45,
702,15,87,4,0,
702,15,91,4,0,
702,15,92,4,0,
702,15,104,4,0,
702,15,148,4,0,
702,15,156,1,30,
702,15,156,4,0,
702,15,164,4,0,
702,15,168,4,0,
702,15,173,1,31,
702,15,182,4,0,
702,15,204,1,14,
702,15,207,4,0,
702,15,213,4,0,
702,15,214,4,0,
702,15,216,4,0,
702,15,218,4,0,
702,15,237,4,0,
702,15,240,4,0,
702,15,241,4,0,
702,15,263,4,0,
702,15,268,1,11,
702,15,270,2,0,
702,15,332,4,0,
702,15,343,2,0,
702,15,363,2,0,
702,15,369,4,0,
702,15,374,4,0,
702,15,416,4,0,
702,15,435,1,50,
702,15,447,4,0,
702,15,451,1,34,
702,15,451,4,0,
702,15,494,1,39,
702,15,496,4,0,
702,15,514,4,0,
702,15,521,1,26,
702,15,521,4,0,
702,15,528,4,0,
702,15,570,1,17,
702,15,583,1,42,
702,15,590,4,0,
702,15,598,2,0,
702,15,609,1,20,
702,16,9,3,0,
702,16,15,4,0,
702,16,33,1,1,1
702,16,39,1,1,2
702,16,84,1,7,1
702,16,85,4,0,
702,16,86,1,23,1
702,16,86,4,0,
702,16,87,1,45,1
702,16,87,4,0,
702,16,91,4,0,
702,16,92,4,0,
702,16,104,4,0,
702,16,148,4,0,
702,16,156,1,30,1
702,16,156,4,0,
702,16,162,3,0,
702,16,164,4,0,
702,16,168,4,0,
702,16,173,1,31,1
702,16,173,3,0,
702,16,182,4,0,
702,16,204,1,14,1
702,16,207,4,0,
702,16,213,4,0,
702,16,214,4,0,
702,16,216,4,0,
702,16,218,4,0,
702,16,231,3,0,
702,16,237,4,0,
702,16,240,4,0,
702,16,241,4,0,
702,16,263,4,0,
702,16,268,1,11,1
702,16,270,2,0,
702,16,270,3,0,
702,16,278,3,0,
702,16,290,4,0,
702,16,324,3,0,
702,16,332,4,0,
702,16,343,2,0,
702,16,343,3,0,
702,16,351,3,0,
702,16,363,2,0,
702,16,369,4,0,
702,16,374,4,0,
702,16,387,3,0,
702,16,393,3,0,
702,16,416,4,0,
702,16,435,1,50,1
702,16,447,4,0,
702,16,451,1,34,1
702,16,451,4,0,
702,16,494,1,39,1
702,16,496,4,0,
702,16,514,4,0,
702,16,521,1,26,1
702,16,521,4,0,
702,16,527,3,0,
702,16,528,4,0,
702,16,570,1,17,1
702,16,583,1,42,1
702,16,590,4,0,
702,16,598,2,0,
702,16,609,1,20,1
702,17,33,1,1,1
702,17,39,1,1,2
702,17,84,1,7,
702,17,85,4,0,
702,17,86,1,23,
702,17,86,4,0,
702,17,87,1,45,
702,17,87,4,0,
702,17,92,4,0,
702,17,104,4,0,
702,17,156,1,30,
702,17,156,4,0,
702,17,164,4,0,
702,17,168,4,0,
702,17,173,1,31,
702,17,182,4,0,
702,17,204,1,14,
702,17,207,4,0,
702,17,213,4,0,
702,17,214,4,0,
702,17,216,4,0,
702,17,218,4,0,
702,17,237,4,0,
702,17,240,4,0,
702,17,241,4,0,
702,17,263,4,0,
702,17,268,1,11,
702,17,270,2,0,
702,17,332,4,0,
702,17,343,2,0,
702,17,363,2,0,
702,17,369,4,0,
702,17,374,4,0,
702,17,416,4,0,
702,17,435,1,50,
702,17,447,4,0,
702,17,451,1,34,
702,17,451,4,0,
702,17,494,1,39,
702,17,496,4,0,
702,17,521,1,26,
702,17,521,4,0,
702,17,528,4,0,
702,17,570,1,17,
702,17,583,1,42,
702,17,590,4,0,
702,17,598,2,0,
702,17,609,1,20,
702,18,33,1,1,1
702,18,39,1,1,2
702,18,84,1,7,
702,18,85,4,0,
702,18,86,1,23,
702,18,86,4,0,
702,18,87,1,45,
702,18,87,4,0,
702,18,92,4,0,
702,18,104,4,0,
702,18,156,1,30,
702,18,156,4,0,
702,18,164,4,0,
702,18,168,4,0,
702,18,173,1,31,
702,18,182,4,0,
702,18,204,1,14,
702,18,207,4,0,
702,18,213,4,0,
702,18,214,4,0,
702,18,216,4,0,
702,18,218,4,0,
702,18,237,4,0,
702,18,240,4,0,
702,18,241,4,0,
702,18,263,4,0,
702,18,268,1,11,
702,18,270,2,0,
702,18,332,4,0,
702,18,343,2,0,
702,18,363,2,0,
702,18,369,4,0,
702,18,374,4,0,
702,18,416,4,0,
702,18,435,1,50,
702,18,447,4,0,
702,18,451,1,34,
702,18,451,4,0,
702,18,494,1,39,
702,18,496,4,0,
702,18,521,1,26,
702,18,521,4,0,
702,18,528,4,0,
702,18,570,1,17,
702,18,583,1,42,
702,18,590,4,0,
702,18,598,2,0,
702,18,609,1,20,
702,18,715,2,0,
703,15,33,1,1,1
703,15,88,1,5,
703,15,92,4,0,
703,15,94,4,0,
703,15,104,4,0,
703,15,106,1,1,2
703,15,113,1,60,
703,15,113,4,0,
703,15,115,1,18,
703,15,115,4,0,
703,15,148,4,0,
703,15,153,4,0,
703,15,156,4,0,
703,15,157,4,0,
703,15,159,1,8,
703,15,164,4,0,
703,15,175,1,35,
703,15,182,4,0,
703,15,201,4,0,
703,15,207,4,0,
703,15,214,4,0,
703,15,216,4,0,
703,15,218,4,0,
703,15,219,1,70,
703,15,219,4,0,
703,15,237,4,0,
703,15,241,4,0,
703,15,244,4,0,
703,15,246,1,31,
703,15,258,4,0,
703,15,263,4,0,
703,15,267,4,0,
703,15,285,1,40,
703,15,317,4,0,
703,15,347,4,0,
703,15,360,4,0,
703,15,397,4,0,
703,15,408,1,46,
703,15,433,4,0,
703,15,444,1,49,
703,15,444,4,0,
703,15,446,1,21,
703,15,470,1,27,
703,15,479,1,12,
703,15,479,4,0,
703,15,496,4,0,
703,15,585,1,50,
703,15,590,4,0,
703,15,605,4,0,
703,16,33,1,1,1
703,16,88,1,5,1
703,16,92,4,0,
703,16,94,4,0,
703,16,104,4,0,
703,16,106,1,1,2
703,16,113,1,60,1
703,16,113,4,0,
703,16,115,1,18,1
703,16,115,4,0,
703,16,148,4,0,
703,16,153,4,0,
703,16,156,4,0,
703,16,157,4,0,
703,16,159,1,8,1
703,16,164,4,0,
703,16,173,3,0,
703,16,175,1,35,1
703,16,182,4,0,
703,16,201,4,0,
703,16,207,4,0,
703,16,214,4,0,
703,16,216,4,0,
703,16,218,4,0,
703,16,219,1,70,1
703,16,219,4,0,
703,16,237,4,0,
703,16,241,4,0,
703,16,244,4,0,
703,16,246,1,31,1
703,16,258,4,0,
703,16,263,4,0,
703,16,267,4,0,
703,16,277,3,0,
703,16,285,1,40,1
703,16,285,3,0,
703,16,290,4,0,
703,16,317,4,0,
703,16,334,3,0,
703,16,343,3,0,
703,16,347,4,0,
703,16,356,3,0,
703,16,360,4,0,
703,16,393,3,0,
703,16,397,4,0,
703,16,408,1,46,1
703,16,414,3,0,
703,16,433,4,0,
703,16,444,1,49,1
703,16,444,4,0,
703,16,446,1,21,1
703,16,446,3,0,
703,16,470,1,27,1
703,16,472,3,0,
703,16,479,1,12,1
703,16,479,4,0,
703,16,495,3,0,
703,16,496,4,0,
703,16,585,1,50,1
703,16,590,4,0,
703,16,605,4,0,
703,17,33,1,1,1
703,17,88,1,5,
703,17,92,4,0,
703,17,94,4,0,
703,17,104,4,0,
703,17,106,1,1,2
703,17,113,1,60,
703,17,113,4,0,
703,17,115,1,18,
703,17,115,4,0,
703,17,153,4,0,
703,17,156,4,0,
703,17,157,4,0,
703,17,159,1,8,
703,17,164,4,0,
703,17,175,1,35,
703,17,182,4,0,
703,17,201,4,0,
703,17,207,4,0,
703,17,214,4,0,
703,17,216,4,0,
703,17,218,4,0,
703,17,219,1,70,
703,17,219,4,0,
703,17,237,4,0,
703,17,241,4,0,
703,17,244,4,0,
703,17,246,1,31,
703,17,258,4,0,
703,17,263,4,0,
703,17,267,4,0,
703,17,285,1,40,
703,17,317,4,0,
703,17,347,4,0,
703,17,360,4,0,
703,17,397,4,0,
703,17,408,1,46,
703,17,433,4,0,
703,17,444,1,49,
703,17,444,4,0,
703,17,446,1,21,
703,17,470,1,27,
703,17,479,1,12,
703,17,479,4,0,
703,17,496,4,0,
703,17,585,1,50,
703,17,590,4,0,
703,17,605,4,0,
703,18,33,1,1,1
703,18,88,1,5,
703,18,92,4,0,
703,18,94,4,0,
703,18,104,4,0,
703,18,106,1,1,2
703,18,113,1,60,
703,18,113,4,0,
703,18,115,1,18,
703,18,115,4,0,
703,18,153,4,0,
703,18,156,4,0,
703,18,157,4,0,
703,18,159,1,8,
703,18,164,4,0,
703,18,175,1,35,
703,18,182,4,0,
703,18,201,4,0,
703,18,207,4,0,
703,18,214,4,0,
703,18,216,4,0,
703,18,218,4,0,
703,18,219,1,70,
703,18,219,4,0,
703,18,237,4,0,
703,18,241,4,0,
703,18,244,4,0,
703,18,246,1,31,
703,18,258,4,0,
703,18,263,4,0,
703,18,267,4,0,
703,18,285,1,40,
703,18,317,4,0,
703,18,347,4,0,
703,18,360,4,0,
703,18,397,4,0,
703,18,408,1,46,
703,18,433,4,0,
703,18,444,1,49,
703,18,444,4,0,
703,18,446,1,21,
703,18,470,1,27,
703,18,479,1,12,
703,18,479,4,0,
703,18,496,4,0,
703,18,585,1,50,
703,18,590,4,0,
703,18,605,4,0,
704,15,33,1,1,1
704,15,34,1,32,
704,15,68,2,0,
704,15,71,1,5,
704,15,85,4,0,
704,15,92,4,0,
704,15,104,4,0,
704,15,117,1,13,
704,15,145,1,1,2
704,15,151,2,0,
704,15,156,4,0,
704,15,157,4,0,
704,15,164,4,0,
704,15,174,2,0,
704,15,175,1,28,
704,15,182,1,9,
704,15,182,4,0,
704,15,188,4,0,
704,15,203,2,0,
704,15,207,4,0,
704,15,213,4,0,
704,15,214,4,0,
704,15,216,4,0,
704,15,218,4,0,
704,15,225,1,18,
704,15,231,2,0,
704,15,237,4,0,
704,15,240,1,25,
704,15,240,4,0,
704,15,241,4,0,
704,15,263,4,0,
704,15,330,1,38,
704,15,342,2,0,
704,15,406,1,42,
704,15,434,3,0,
704,15,482,4,0,
704,15,496,4,0,
704,15,590,4,0,
704,15,611,4,0,
704,16,33,1,1,1
704,16,34,1,32,1
704,16,68,2,0,
704,16,71,1,5,1
704,16,85,4,0,
704,16,92,4,0,
704,16,104,4,0,
704,16,117,1,13,1
704,16,145,1,1,2
704,16,151,2,0,
704,16,156,4,0,
704,16,157,4,0,
704,16,164,4,0,
704,16,173,3,0,
704,16,174,2,0,
704,16,175,1,28,1
704,16,182,1,9,1
704,16,182,4,0,
704,16,188,4,0,
704,16,200,3,0,
704,16,203,2,0,
704,16,207,4,0,
704,16,213,4,0,
704,16,214,4,0,
704,16,216,4,0,
704,16,218,4,0,
704,16,225,1,18,1
704,16,231,2,0,
704,16,231,3,0,
704,16,237,4,0,
704,16,240,1,25,1
704,16,240,4,0,
704,16,241,4,0,
704,16,263,4,0,
704,16,290,4,0,
704,16,330,1,38,1
704,16,342,2,0,
704,16,351,3,0,
704,16,352,3,0,
704,16,406,1,42,1
704,16,406,3,0,
704,16,434,3,0,
704,16,482,4,0,
704,16,496,4,0,
704,16,590,4,0,
704,16,611,4,0,
704,17,33,1,1,1
704,17,34,1,32,
704,17,68,2,0,
704,17,71,1,5,
704,17,85,4,0,
704,17,92,4,0,
704,17,104,4,0,
704,17,117,1,13,
704,17,145,1,1,2
704,17,151,2,0,
704,17,156,4,0,
704,17,157,4,0,
704,17,164,4,0,
704,17,174,2,0,
704,17,175,1,28,
704,17,182,1,9,
704,17,182,4,0,
704,17,188,4,0,
704,17,203,2,0,
704,17,207,4,0,
704,17,213,4,0,
704,17,214,4,0,
704,17,216,4,0,
704,17,218,4,0,
704,17,225,1,18,
704,17,231,2,0,
704,17,237,4,0,
704,17,240,1,25,
704,17,240,4,0,
704,17,241,4,0,
704,17,263,4,0,
704,17,330,1,38,
704,17,342,2,0,
704,17,406,1,42,
704,17,434,3,0,
704,17,482,4,0,
704,17,496,4,0,
704,17,590,4,0,
704,17,611,4,0,
704,18,33,1,1,1
704,18,34,1,32,
704,18,68,2,0,
704,18,71,1,5,
704,18,85,4,0,
704,18,92,4,0,
704,18,104,4,0,
704,18,117,1,13,
704,18,145,1,1,2
704,18,151,2,0,
704,18,156,4,0,
704,18,157,4,0,
704,18,164,4,0,
704,18,174,2,0,
704,18,175,1,28,
704,18,182,1,9,
704,18,182,4,0,
704,18,188,4,0,
704,18,203,2,0,
704,18,207,4,0,
704,18,213,4,0,
704,18,214,4,0,
704,18,216,4,0,
704,18,218,4,0,
704,18,225,1,18,
704,18,231,2,0,
704,18,237,4,0,
704,18,240,1,25,
704,18,240,4,0,
704,18,241,4,0,
704,18,263,4,0,
704,18,330,1,38,
704,18,342,2,0,
704,18,406,1,42,
704,18,434,3,0,
704,18,482,4,0,
704,18,496,4,0,
704,18,590,4,0,
704,18,611,4,0,
705,15,33,1,1,1
705,15,34,1,32,
705,15,58,4,0,
705,15,59,4,0,
705,15,71,1,5,
705,15,85,4,0,
705,15,92,4,0,
705,15,104,4,0,
705,15,117,1,13,
705,15,145,1,1,2
705,15,156,4,0,
705,15,157,4,0,
705,15,164,4,0,
705,15,175,1,28,
705,15,182,1,9,
705,15,182,4,0,
705,15,188,4,0,
705,15,207,4,0,
705,15,213,4,0,
705,15,214,4,0,
705,15,216,4,0,
705,15,218,4,0,
705,15,225,1,18,
705,15,237,4,0,
705,15,240,1,25,
705,15,240,4,0,
705,15,241,4,0,
705,15,263,4,0,
705,15,330,1,38,
705,15,406,1,47,
705,15,434,3,0,
705,15,482,4,0,
705,15,496,4,0,
705,15,590,4,0,
705,15,611,4,0,
705,16,33,1,1,1
705,16,34,1,32,1
705,16,58,4,0,
705,16,59,4,0,
705,16,71,1,5,1
705,16,85,4,0,
705,16,92,4,0,
705,16,104,4,0,
705,16,117,1,13,1
705,16,145,1,1,2
705,16,156,4,0,
705,16,157,4,0,
705,16,164,4,0,
705,16,173,3,0,
705,16,175,1,28,1
705,16,182,1,9,1
705,16,182,4,0,
705,16,188,4,0,
705,16,200,3,0,
705,16,207,4,0,
705,16,213,4,0,
705,16,214,4,0,
705,16,216,4,0,
705,16,218,4,0,
705,16,225,1,18,1
705,16,231,3,0,
705,16,237,4,0,
705,16,240,1,25,1
705,16,240,4,0,
705,16,241,4,0,
705,16,263,4,0,
705,16,290,4,0,
705,16,330,1,38,1
705,16,351,3,0,
705,16,352,3,0,
705,16,406,1,47,1
705,16,406,3,0,
705,16,434,3,0,
705,16,482,4,0,
705,16,496,4,0,
705,16,590,4,0,
705,16,611,4,0,
705,17,33,1,1,1
705,17,34,1,32,
705,17,58,4,0,
705,17,59,4,0,
705,17,71,1,1,3
705,17,71,1,5,
705,17,85,4,0,
705,17,92,4,0,
705,17,104,4,0,
705,17,117,1,13,
705,17,145,1,1,2
705,17,156,4,0,
705,17,157,4,0,
705,17,164,4,0,
705,17,175,1,28,
705,17,182,1,9,
705,17,182,4,0,
705,17,188,4,0,
705,17,207,4,0,
705,17,213,4,0,
705,17,214,4,0,
705,17,216,4,0,
705,17,218,4,0,
705,17,225,1,18,
705,17,237,4,0,
705,17,240,1,25,
705,17,240,4,0,
705,17,241,4,0,
705,17,263,4,0,
705,17,330,1,38,
705,17,406,1,47,
705,17,434,3,0,
705,17,482,4,0,
705,17,496,4,0,
705,17,590,4,0,
705,17,611,4,0,
705,18,33,1,1,1
705,18,34,1,32,
705,18,58,4,0,
705,18,59,4,0,
705,18,71,1,1,3
705,18,71,1,5,
705,18,85,4,0,
705,18,92,4,0,
705,18,104,4,0,
705,18,117,1,13,
705,18,145,1,1,2
705,18,156,4,0,
705,18,157,4,0,
705,18,164,4,0,
705,18,175,1,28,
705,18,182,1,9,
705,18,182,4,0,
705,18,188,4,0,
705,18,207,4,0,
705,18,213,4,0,
705,18,214,4,0,
705,18,216,4,0,
705,18,218,4,0,
705,18,225,1,18,
705,18,237,4,0,
705,18,240,1,25,
705,18,240,4,0,
705,18,241,4,0,
705,18,263,4,0,
705,18,330,1,38,
705,18,406,1,47,
705,18,434,3,0,
705,18,482,4,0,
705,18,496,4,0,
705,18,590,4,0,
705,18,611,4,0,
706,15,33,1,1,3
706,15,34,1,32,
706,15,53,4,0,
706,15,58,4,0,
706,15,59,4,0,
706,15,63,4,0,
706,15,70,4,0,
706,15,71,1,5,
706,15,85,4,0,
706,15,87,4,0,
706,15,89,4,0,
706,15,92,4,0,
706,15,104,4,0,
706,15,117,1,13,
706,15,126,4,0,
706,15,145,1,1,4
706,15,156,4,0,
706,15,157,4,0,
706,15,164,4,0,
706,15,175,1,28,
706,15,182,1,9,
706,15,182,4,0,
706,15,188,4,0,
706,15,200,1,1,1
706,15,200,1,63,
706,15,207,4,0,
706,15,213,4,0,
706,15,214,4,0,
706,15,216,4,0,
706,15,218,4,0,
706,15,225,1,18,
706,15,237,4,0,
706,15,240,1,25,
706,15,240,4,0,
706,15,241,4,0,
706,15,249,4,0,
706,15,258,4,0,
706,15,263,4,0,
706,15,330,1,38,
706,15,364,1,1,2
706,15,401,1,50,
706,15,406,1,47,
706,15,411,4,0,
706,15,416,4,0,
706,15,434,3,0,
706,15,438,1,55,
706,15,482,4,0,
706,15,496,4,0,
706,15,510,4,0,
706,15,523,4,0,
706,15,525,4,0,
706,15,590,4,0,
706,15,611,4,0,
706,16,7,3,0,
706,16,9,3,0,
706,16,33,1,1,3
706,16,34,1,32,1
706,16,53,4,0,
706,16,58,4,0,
706,16,59,4,0,
706,16,63,4,0,
706,16,70,4,0,
706,16,71,1,5,1
706,16,85,4,0,
706,16,87,4,0,
706,16,89,4,0,
706,16,92,4,0,
706,16,104,4,0,
706,16,117,1,13,1
706,16,126,4,0,
706,16,145,1,1,4
706,16,156,4,0,
706,16,157,4,0,
706,16,164,4,0,
706,16,173,3,0,
706,16,175,1,28,1
706,16,182,1,9,1
706,16,182,4,0,
706,16,188,4,0,
706,16,200,1,1,1
706,16,200,1,63,1
706,16,200,3,0,
706,16,207,4,0,
706,16,213,4,0,
706,16,214,4,0,
706,16,216,4,0,
706,16,218,4,0,
706,16,225,1,18,1
706,16,231,3,0,
706,16,237,4,0,
706,16,240,1,25,1
706,16,240,4,0,
706,16,241,4,0,
706,16,249,4,0,
706,16,258,4,0,
706,16,263,4,0,
706,16,264,3,0,
706,16,276,3,0,
706,16,290,4,0,
706,16,330,1,38,1
706,16,351,3,0,
706,16,352,3,0,
706,16,364,1,1,2
706,16,401,1,50,1
706,16,401,3,0,
706,16,406,1,47,1
706,16,406,3,0,
706,16,411,4,0,
706,16,416,4,0,
706,16,434,3,0,
706,16,438,1,55,1
706,16,482,4,0,
706,16,496,4,0,
706,16,510,4,0,
706,16,523,4,0,
706,16,525,4,0,
706,16,590,4,0,
706,16,611,4,0,
706,17,33,1,1,4
706,17,34,1,32,
706,17,53,4,0,
706,17,58,4,0,
706,17,59,4,0,
706,17,63,4,0,
706,17,71,1,1,6
706,17,71,1,5,
706,17,85,4,0,
706,17,87,4,0,
706,17,89,4,0,
706,17,92,4,0,
706,17,104,4,0,
706,17,117,1,13,
706,17,126,4,0,
706,17,145,1,1,5
706,17,156,4,0,
706,17,157,4,0,
706,17,164,4,0,
706,17,175,1,28,
706,17,182,1,1,7
706,17,182,1,9,
706,17,182,4,0,
706,17,188,4,0,
706,17,200,1,1,2
706,17,200,1,55,
706,17,207,4,0,
706,17,213,4,0,
706,17,214,4,0,
706,17,216,4,0,
706,17,218,4,0,
706,17,225,1,18,
706,17,237,4,0,
706,17,240,1,25,
706,17,240,4,0,
706,17,241,4,0,
706,17,258,4,0,
706,17,263,4,0,
706,17,330,1,38,
706,17,364,1,1,3
706,17,401,1,0,
706,17,401,1,1,1
706,17,406,1,47,
706,17,411,4,0,
706,17,416,4,0,
706,17,434,3,0,
706,17,438,1,50,
706,17,482,4,0,
706,17,496,4,0,
706,17,523,4,0,
706,17,525,4,0,
706,17,590,4,0,
706,17,611,4,0,
706,17,693,4,0,
706,18,33,1,1,4
706,18,34,1,32,
706,18,53,4,0,
706,18,58,4,0,
706,18,59,4,0,
706,18,63,4,0,
706,18,71,1,1,6
706,18,71,1,5,
706,18,85,4,0,
706,18,87,4,0,
706,18,89,4,0,
706,18,92,4,0,
706,18,104,4,0,
706,18,117,1,13,
706,18,126,4,0,
706,18,145,1,1,5
706,18,156,4,0,
706,18,157,4,0,
706,18,164,4,0,
706,18,175,1,28,
706,18,182,1,1,7
706,18,182,1,9,
706,18,182,4,0,
706,18,188,4,0,
706,18,200,1,1,2
706,18,200,1,55,
706,18,207,4,0,
706,18,213,4,0,
706,18,214,4,0,
706,18,216,4,0,
706,18,218,4,0,
706,18,225,1,18,
706,18,237,4,0,
706,18,240,1,25,
706,18,240,4,0,
706,18,241,4,0,
706,18,258,4,0,
706,18,263,4,0,
706,18,330,1,38,
706,18,364,1,1,3
706,18,401,1,0,
706,18,401,1,1,1
706,18,406,1,47,
706,18,411,4,0,
706,18,416,4,0,
706,18,434,3,0,
706,18,438,1,50,
706,18,482,4,0,
706,18,496,4,0,
706,18,523,4,0,
706,18,525,4,0,
706,18,590,4,0,
706,18,611,4,0,
706,18,693,4,0,
707,15,15,4,0,
707,15,33,1,1,2
707,15,63,4,0,
707,15,86,4,0,
707,15,92,4,0,
707,15,94,4,0,
707,15,104,4,0,
707,15,113,4,0,
707,15,115,4,0,
707,15,156,4,0,
707,15,164,4,0,
707,15,168,2,0,
707,15,168,4,0,
707,15,182,4,0,
707,15,191,1,15,
707,15,199,2,0,
707,15,207,4,0,
707,15,213,4,0,
707,15,214,4,0,
707,15,216,4,0,
707,15,218,4,0,
707,15,219,4,0,
707,15,237,4,0,
707,15,240,4,0,
707,15,241,4,0,
707,15,244,4,0,
707,15,259,1,32,
707,15,259,4,0,
707,15,263,4,0,
707,15,278,1,40,
707,15,286,1,36,
707,15,310,1,8,
707,15,319,1,12,
707,15,334,2,0,
707,15,347,4,0,
707,15,377,1,50,
707,15,415,2,0,
707,15,416,4,0,
707,15,429,1,34,
707,15,430,4,0,
707,15,473,4,0,
707,15,478,1,44,
707,15,492,1,27,
707,15,496,4,0,
707,15,577,1,18,
707,15,578,1,23,
707,15,583,1,43,
707,15,584,1,5,
707,15,587,1,1,1
707,15,590,4,0,
707,15,605,4,0,
707,16,15,4,0,
707,16,33,1,1,2
707,16,63,4,0,
707,16,86,4,0,
707,16,92,4,0,
707,16,94,4,0,
707,16,104,4,0,
707,16,113,4,0,
707,16,115,4,0,
707,16,156,4,0,
707,16,164,4,0,
707,16,168,2,0,
707,16,168,4,0,
707,16,173,3,0,
707,16,182,4,0,
707,16,191,1,15,1
707,16,199,2,0,
707,16,207,4,0,
707,16,213,4,0,
707,16,214,4,0,
707,16,216,4,0,
707,16,218,4,0,
707,16,219,4,0,
707,16,237,4,0,
707,16,240,4,0,
707,16,241,4,0,
707,16,244,4,0,
707,16,259,1,32,1
707,16,259,4,0,
707,16,263,4,0,
707,16,277,3,0,
707,16,278,1,40,1
707,16,278,3,0,
707,16,286,1,36,1
707,16,290,4,0,
707,16,310,1,8,1
707,16,319,1,12,1
707,16,334,2,0,
707,16,334,3,0,
707,16,343,3,0,
707,16,347,4,0,
707,16,377,1,50,1
707,16,387,3,0,
707,16,393,3,0,
707,16,415,2,0,
707,16,416,4,0,
707,16,429,1,34,1
707,16,430,4,0,
707,16,473,4,0,
707,16,478,1,44,1
707,16,478,3,0,
707,16,492,1,27,1
707,16,492,3,0,
707,16,496,4,0,
707,16,577,1,18,1
707,16,578,1,23,1
707,16,583,1,43,1
707,16,584,1,5,1
707,16,587,1,1,1
707,16,590,4,0,
707,16,605,4,0,
707,17,33,1,1,2
707,17,63,4,0,
707,17,86,4,0,
707,17,92,4,0,
707,17,94,4,0,
707,17,104,4,0,
707,17,113,4,0,
707,17,115,4,0,
707,17,156,4,0,
707,17,164,4,0,
707,17,168,2,0,
707,17,168,4,0,
707,17,182,4,0,
707,17,191,1,15,
707,17,199,2,0,
707,17,207,4,0,
707,17,213,4,0,
707,17,214,4,0,
707,17,216,4,0,
707,17,218,4,0,
707,17,219,4,0,
707,17,237,4,0,
707,17,240,4,0,
707,17,241,4,0,
707,17,244,4,0,
707,17,259,1,32,
707,17,259,4,0,
707,17,263,4,0,
707,17,278,1,40,
707,17,286,1,36,
707,17,310,1,8,
707,17,319,1,12,
707,17,334,2,0,
707,17,347,4,0,
707,17,377,1,50,
707,17,415,2,0,
707,17,416,4,0,
707,17,429,1,34,
707,17,430,4,0,
707,17,473,4,0,
707,17,478,1,44,
707,17,492,1,27,
707,17,496,4,0,
707,17,577,1,18,
707,17,578,1,23,
707,17,583,1,43,
707,17,584,1,5,
707,17,587,1,1,1
707,17,590,4,0,
707,17,605,4,0,
707,18,33,1,1,2
707,18,63,4,0,
707,18,86,4,0,
707,18,92,4,0,
707,18,94,4,0,
707,18,104,4,0,
707,18,113,4,0,
707,18,115,4,0,
707,18,156,4,0,
707,18,164,4,0,
707,18,168,2,0,
707,18,168,4,0,
707,18,182,4,0,
707,18,191,1,15,
707,18,199,2,0,
707,18,207,4,0,
707,18,213,4,0,
707,18,214,4,0,
707,18,216,4,0,
707,18,218,4,0,
707,18,219,4,0,
707,18,237,4,0,
707,18,240,4,0,
707,18,241,4,0,
707,18,244,4,0,
707,18,259,1,32,
707,18,259,4,0,
707,18,263,4,0,
707,18,278,1,40,
707,18,286,1,36,
707,18,310,1,8,
707,18,319,1,12,
707,18,334,2,0,
707,18,347,4,0,
707,18,377,1,50,
707,18,415,2,0,
707,18,416,4,0,
707,18,429,1,34,
707,18,430,4,0,
707,18,473,4,0,
707,18,478,1,44,
707,18,492,1,27,
707,18,496,4,0,
707,18,577,1,18,
707,18,578,1,23,
707,18,583,1,43,
707,18,584,1,5,
707,18,587,1,1,1
707,18,590,4,0,
707,18,605,4,0,
708,15,15,4,0,
708,15,33,1,1,1
708,15,70,4,0,
708,15,73,1,23,
708,15,74,1,8,
708,15,76,4,0,
708,15,91,4,0,
708,15,92,4,0,
708,15,94,4,0,
708,15,104,4,0,
708,15,109,1,1,2
708,15,115,4,0,
708,15,138,4,0,
708,15,156,4,0,
708,15,157,4,0,
708,15,164,4,0,
708,15,168,4,0,
708,15,174,1,28,
708,15,182,4,0,
708,15,185,1,19,
708,15,194,1,39,
708,15,207,4,0,
708,15,213,4,0,
708,15,214,4,0,
708,15,216,4,0,
708,15,218,4,0,
708,15,219,4,0,
708,15,237,4,0,
708,15,241,4,0,
708,15,247,4,0,
708,15,249,4,0,
708,15,261,1,31,
708,15,261,4,0,
708,15,263,4,0,
708,15,267,4,0,
708,15,275,1,13,
708,15,286,2,0,
708,15,288,2,0,
708,15,310,1,5,
708,15,398,4,0,
708,15,399,4,0,
708,15,412,4,0,
708,15,421,4,0,
708,15,433,4,0,
708,15,447,4,0,
708,15,452,1,49,
708,15,496,4,0,
708,15,516,2,0,
708,15,523,4,0,
708,15,532,1,54,
708,15,566,1,45,
708,15,571,1,35,
708,15,590,4,0,
708,15,599,2,0,
708,16,15,4,0,
708,16,33,1,1,1
708,16,70,4,0,
708,16,73,1,23,1
708,16,74,1,8,1
708,16,76,4,0,
708,16,91,4,0,
708,16,92,4,0,
708,16,94,4,0,
708,16,104,4,0,
708,16,109,1,1,2
708,16,115,4,0,
708,16,138,4,0,
708,16,156,4,0,
708,16,157,4,0,
708,16,164,4,0,
708,16,168,4,0,
708,16,173,3,0,
708,16,174,1,28,1
708,16,180,3,0,
708,16,182,4,0,
708,16,185,1,19,1
708,16,194,1,39,1
708,16,202,3,0,
708,16,207,4,0,
708,16,213,4,0,
708,16,214,4,0,
708,16,216,4,0,
708,16,218,4,0,
708,16,219,4,0,
708,16,220,3,0,
708,16,237,4,0,
708,16,241,4,0,
708,16,247,4,0,
708,16,249,4,0,
708,16,261,1,31,1
708,16,261,4,0,
708,16,263,4,0,
708,16,267,4,0,
708,16,271,3,0,
708,16,272,3,0,
708,16,275,1,13,1
708,16,277,3,0,
708,16,285,3,0,
708,16,286,2,0,
708,16,288,2,0,
708,16,290,4,0,
708,16,310,1,5,1
708,16,388,3,0,
708,16,398,4,0,
708,16,399,4,0,
708,16,402,3,0,
708,16,412,4,0,
708,16,421,4,0,
708,16,433,4,0,
708,16,447,4,0,
708,16,452,1,49,1
708,16,492,3,0,
708,16,496,4,0,
708,16,516,2,0,
708,16,523,4,0,
708,16,532,1,54,1
708,16,566,1,45,1
708,16,571,1,35,1
708,16,590,4,0,
708,16,599,2,0,
708,17,33,1,1,1
708,17,73,1,23,
708,17,74,1,8,
708,17,76,4,0,
708,17,92,4,0,
708,17,94,4,0,
708,17,104,4,0,
708,17,109,1,1,2
708,17,115,4,0,
708,17,138,4,0,
708,17,156,4,0,
708,17,157,4,0,
708,17,164,4,0,
708,17,168,4,0,
708,17,174,1,28,
708,17,182,4,0,
708,17,185,1,19,
708,17,194,1,39,
708,17,207,4,0,
708,17,213,4,0,
708,17,214,4,0,
708,17,216,4,0,
708,17,218,4,0,
708,17,219,4,0,
708,17,237,4,0,
708,17,241,4,0,
708,17,247,4,0,
708,17,261,1,31,
708,17,261,4,0,
708,17,263,4,0,
708,17,267,4,0,
708,17,275,1,13,
708,17,286,2,0,
708,17,288,2,0,
708,17,310,1,5,
708,17,398,4,0,
708,17,399,4,0,
708,17,412,4,0,
708,17,421,4,0,
708,17,433,4,0,
708,17,447,4,0,
708,17,452,1,49,
708,17,496,4,0,
708,17,516,2,0,
708,17,523,4,0,
708,17,532,1,54,
708,17,566,1,45,
708,17,571,1,35,
708,17,590,4,0,
708,17,599,2,0,
708,18,33,1,1,1
708,18,73,1,23,
708,18,74,1,8,
708,18,76,4,0,
708,18,92,4,0,
708,18,94,4,0,
708,18,104,4,0,
708,18,109,1,1,2
708,18,115,4,0,
708,18,138,4,0,
708,18,156,4,0,
708,18,157,4,0,
708,18,164,4,0,
708,18,168,4,0,
708,18,174,1,28,
708,18,182,4,0,
708,18,185,1,19,
708,18,194,1,39,
708,18,207,4,0,
708,18,213,4,0,
708,18,214,4,0,
708,18,216,4,0,
708,18,218,4,0,
708,18,219,4,0,
708,18,237,4,0,
708,18,241,4,0,
708,18,247,4,0,
708,18,261,1,31,
708,18,261,4,0,
708,18,263,4,0,
708,18,267,4,0,
708,18,275,1,13,
708,18,286,2,0,
708,18,288,2,0,
708,18,310,1,5,
708,18,398,4,0,
708,18,399,4,0,
708,18,412,4,0,
708,18,421,4,0,
708,18,433,4,0,
708,18,447,4,0,
708,18,452,1,49,
708,18,496,4,0,
708,18,516,2,0,
708,18,523,4,0,
708,18,532,1,54,
708,18,566,1,45,
708,18,571,1,35,
708,18,590,4,0,
708,18,599,2,0,
708,18,612,2,0,
709,15,15,4,0,
709,15,33,1,1,2
709,15,63,4,0,
709,15,70,4,0,
709,15,73,1,23,
709,15,74,1,8,
709,15,76,4,0,
709,15,89,4,0,
709,15,91,4,0,
709,15,92,4,0,
709,15,94,4,0,
709,15,104,4,0,
709,15,109,1,1,3
709,15,115,4,0,
709,15,138,4,0,
709,15,156,4,0,
709,15,157,4,0,
709,15,164,4,0,
709,15,168,4,0,
709,15,174,1,28,
709,15,182,4,0,
709,15,185,1,19,
709,15,194,1,39,
709,15,207,4,0,
709,15,213,4,0,
709,15,214,4,0,
709,15,216,4,0,
709,15,218,4,0,
709,15,219,4,0,
709,15,237,4,0,
709,15,241,4,0,
709,15,247,4,0,
709,15,249,4,0,
709,15,261,1,31,
709,15,261,4,0,
709,15,263,4,0,
709,15,267,4,0,
709,15,275,1,13,
709,15,310,1,5,
709,15,347,4,0,
709,15,398,4,0,
709,15,399,4,0,
709,15,404,4,0,
709,15,411,4,0,
709,15,412,4,0,
709,15,416,4,0,
709,15,421,1,55,
709,15,421,4,0,
709,15,433,4,0,
709,15,447,4,0,
709,15,452,1,49,
709,15,468,4,0,
709,15,496,4,0,
709,15,523,4,0,
709,15,532,1,1,1
709,15,532,1,62,
709,15,566,1,45,
709,15,571,1,35,
709,15,590,4,0,
709,15,612,4,0,
709,16,15,4,0,
709,16,33,1,1,2
709,16,63,4,0,
709,16,70,4,0,
709,16,73,1,23,1
709,16,74,1,8,1
709,16,76,4,0,
709,16,89,4,0,
709,16,91,4,0,
709,16,92,4,0,
709,16,94,4,0,
709,16,104,4,0,
709,16,109,1,1,3
709,16,115,4,0,
709,16,138,4,0,
709,16,156,4,0,
709,16,157,4,0,
709,16,164,4,0,
709,16,168,4,0,
709,16,173,3,0,
709,16,174,1,28,1
709,16,180,3,0,
709,16,182,4,0,
709,16,185,1,19,1
709,16,194,1,39,1
709,16,202,3,0,
709,16,207,4,0,
709,16,213,4,0,
709,16,214,4,0,
709,16,216,4,0,
709,16,218,4,0,
709,16,219,4,0,
709,16,220,3,0,
709,16,237,4,0,
709,16,241,4,0,
709,16,247,4,0,
709,16,249,4,0,
709,16,261,1,31,1
709,16,261,4,0,
709,16,263,4,0,
709,16,267,4,0,
709,16,271,3,0,
709,16,272,3,0,
709,16,275,1,13,1
709,16,277,3,0,
709,16,285,3,0,
709,16,290,4,0,
709,16,310,1,5,1
709,16,335,3,0,
709,16,347,4,0,
709,16,388,3,0,
709,16,398,4,0,
709,16,399,4,0,
709,16,402,3,0,
709,16,404,4,0,
709,16,409,3,0,
709,16,411,4,0,
709,16,412,4,0,
709,16,416,4,0,
709,16,421,1,55,1
709,16,421,4,0,
709,16,433,4,0,
709,16,447,4,0,
709,16,452,1,49,1
709,16,468,4,0,
709,16,492,3,0,
709,16,496,4,0,
709,16,523,4,0,
709,16,532,1,1,1
709,16,532,1,62,1
709,16,566,1,45,1
709,16,571,1,35,1
709,16,590,4,0,
709,16,612,4,0,
709,17,33,1,1,3
709,17,63,4,0,
709,17,73,1,23,
709,17,74,1,1,6
709,17,74,1,8,
709,17,76,4,0,
709,17,89,4,0,
709,17,92,4,0,
709,17,94,4,0,
709,17,104,4,0,
709,17,109,1,1,4
709,17,115,4,0,
709,17,138,4,0,
709,17,156,4,0,
709,17,157,4,0,
709,17,164,4,0,
709,17,168,4,0,
709,17,174,1,28,
709,17,182,4,0,
709,17,185,1,19,
709,17,194,1,39,
709,17,207,4,0,
709,17,213,4,0,
709,17,214,4,0,
709,17,216,4,0,
709,17,218,4,0,
709,17,219,4,0,
709,17,237,4,0,
709,17,241,4,0,
709,17,247,4,0,
709,17,261,1,31,
709,17,261,4,0,
709,17,263,4,0,
709,17,267,4,0,
709,17,275,1,13,
709,17,310,1,1,5
709,17,310,1,5,
709,17,347,4,0,
709,17,398,4,0,
709,17,399,4,0,
709,17,404,4,0,
709,17,411,4,0,
709,17,412,4,0,
709,17,416,4,0,
709,17,421,1,0,
709,17,421,1,1,1
709,17,421,4,0,
709,17,433,4,0,
709,17,447,4,0,
709,17,452,1,49,
709,17,496,4,0,
709,17,523,4,0,
709,17,532,1,1,2
709,17,532,1,54,
709,17,566,1,45,
709,17,571,1,35,
709,17,590,4,0,
709,17,693,4,0,
709,18,33,1,1,3
709,18,63,4,0,
709,18,73,1,23,
709,18,74,1,1,6
709,18,74,1,8,
709,18,76,4,0,
709,18,89,4,0,
709,18,92,4,0,
709,18,94,4,0,
709,18,104,4,0,
709,18,109,1,1,4
709,18,115,4,0,
709,18,138,4,0,
709,18,156,4,0,
709,18,157,4,0,
709,18,164,4,0,
709,18,168,4,0,
709,18,174,1,28,
709,18,182,4,0,
709,18,185,1,19,
709,18,194,1,39,
709,18,207,4,0,
709,18,213,4,0,
709,18,214,4,0,
709,18,216,4,0,
709,18,218,4,0,
709,18,219,4,0,
709,18,237,4,0,
709,18,241,4,0,
709,18,247,4,0,
709,18,261,1,31,
709,18,261,4,0,
709,18,263,4,0,
709,18,267,4,0,
709,18,275,1,13,
709,18,310,1,1,5
709,18,310,1,5,
709,18,347,4,0,
709,18,398,4,0,
709,18,399,4,0,
709,18,404,4,0,
709,18,411,4,0,
709,18,412,4,0,
709,18,416,4,0,
709,18,421,1,0,
709,18,421,1,1,1
709,18,421,4,0,
709,18,433,4,0,
709,18,447,4,0,
709,18,452,1,49,
709,18,496,4,0,
709,18,523,4,0,
709,18,532,1,1,2
709,18,532,1,54,
709,18,566,1,45,
709,18,571,1,35,
709,18,590,4,0,
709,18,693,4,0,
710,15,50,2,0,
710,15,53,4,0,
710,15,73,1,20,
710,15,75,1,16,
710,15,76,4,0,
710,15,92,4,0,
710,15,94,4,0,
710,15,104,4,0,
710,15,109,1,1,3
710,15,113,4,0,
710,15,126,4,0,
710,15,138,4,0,
710,15,148,4,0,
710,15,153,4,0,
710,15,156,4,0,
710,15,157,4,0,
710,15,164,4,0,
710,15,168,4,0,
710,15,182,4,0,
710,15,184,1,4,
710,15,188,4,0,
710,15,194,2,0,
710,15,207,4,0,
710,15,213,4,0,
710,15,214,4,0,
710,15,216,4,0,
710,15,218,4,0,
710,15,219,4,0,
710,15,220,1,42,
710,15,237,4,0,
710,15,241,4,0,
710,15,247,1,36,
710,15,247,4,0,
710,15,249,4,0,
710,15,261,4,0,
710,15,263,4,0,
710,15,267,4,0,
710,15,271,1,1,1
710,15,310,1,1,2
710,15,331,1,26,
710,15,360,4,0,
710,15,388,1,11,
710,15,399,4,0,
710,15,402,1,48,
710,15,412,4,0,
710,15,425,1,30,
710,15,433,4,0,
710,15,447,4,0,
710,15,451,4,0,
710,15,488,4,0,
710,15,496,4,0,
710,15,510,4,0,
710,15,516,2,0,
710,15,567,1,6,
710,15,567,1,23,
710,15,567,1,40,
710,15,590,4,0,
710,16,50,2,0,
710,16,53,4,0,
710,16,73,1,20,1
710,16,75,1,16,1
710,16,76,4,0,
710,16,92,4,0,
710,16,94,4,0,
710,16,104,4,0,
710,16,109,1,1,3
710,16,113,4,0,
710,16,126,4,0,
710,16,138,4,0,
710,16,148,4,0,
710,16,153,4,0,
710,16,156,4,0,
710,16,157,4,0,
710,16,164,4,0,
710,16,168,4,0,
710,16,180,3,0,
710,16,182,4,0,
710,16,184,1,4,1
710,16,188,4,0,
710,16,194,2,0,
710,16,202,3,0,
710,16,207,4,0,
710,16,213,4,0,
710,16,214,4,0,
710,16,216,4,0,
710,16,218,4,0,
710,16,219,4,0,
710,16,220,1,42,1
710,16,220,3,0,
710,16,235,3,0,
710,16,237,4,0,
710,16,241,4,0,
710,16,247,1,36,1
710,16,247,4,0,
710,16,249,4,0,
710,16,261,4,0,
710,16,263,4,0,
710,16,267,4,0,
710,16,271,1,1,1
710,16,271,3,0,
710,16,272,3,0,
710,16,277,3,0,
710,16,285,3,0,
710,16,290,4,0,
710,16,310,1,1,2
710,16,331,1,26,1
710,16,360,4,0,
710,16,388,1,11,1
710,16,388,3,0,
710,16,399,4,0,
710,16,402,1,48,1
710,16,402,3,0,
710,16,412,4,0,
710,16,425,1,30,1
710,16,433,4,0,
710,16,447,4,0,
710,16,451,4,0,
710,16,488,4,0,
710,16,492,3,0,
710,16,496,4,0,
710,16,510,4,0,
710,16,516,2,0,
710,16,567,1,6,1
710,16,567,1,23,1
710,16,567,1,40,1
710,16,590,4,0,
710,17,50,2,0,
710,17,53,4,0,
710,17,73,1,20,
710,17,75,1,16,
710,17,76,4,0,
710,17,92,4,0,
710,17,94,4,0,
710,17,104,4,0,
710,17,109,1,1,3
710,17,113,4,0,
710,17,126,4,0,
710,17,138,4,0,
710,17,153,4,0,
710,17,156,4,0,
710,17,157,4,0,
710,17,164,4,0,
710,17,168,4,0,
710,17,182,4,0,
710,17,184,1,4,
710,17,188,4,0,
710,17,194,2,0,
710,17,207,4,0,
710,17,213,4,0,
710,17,214,4,0,
710,17,216,4,0,
710,17,218,4,0,
710,17,219,4,0,
710,17,220,1,42,
710,17,237,4,0,
710,17,241,4,0,
710,17,247,1,36,
710,17,247,4,0,
710,17,261,4,0,
710,17,263,4,0,
710,17,267,4,0,
710,17,271,1,1,1
710,17,310,1,1,2
710,17,331,1,26,
710,17,360,4,0,
710,17,388,1,11,
710,17,399,4,0,
710,17,402,1,48,
710,17,412,4,0,
710,17,425,1,30,
710,17,433,4,0,
710,17,447,4,0,
710,17,451,4,0,
710,17,488,4,0,
710,17,496,4,0,
710,17,516,2,0,
710,17,567,1,6,
710,17,567,1,23,
710,17,567,1,40,
710,17,590,4,0,
710,18,50,2,0,
710,18,53,4,0,
710,18,73,1,20,
710,18,75,1,16,
710,18,76,4,0,
710,18,92,4,0,
710,18,94,4,0,
710,18,104,4,0,
710,18,109,1,1,3
710,18,113,4,0,
710,18,126,4,0,
710,18,138,4,0,
710,18,153,4,0,
710,18,156,4,0,
710,18,157,4,0,
710,18,164,4,0,
710,18,168,4,0,
710,18,174,2,0,
710,18,182,4,0,
710,18,184,1,4,
710,18,188,4,0,
710,18,194,2,0,
710,18,207,4,0,
710,18,213,4,0,
710,18,214,4,0,
710,18,216,4,0,
710,18,218,4,0,
710,18,219,4,0,
710,18,220,1,42,
710,18,237,4,0,
710,18,241,4,0,
710,18,247,1,36,
710,18,247,4,0,
710,18,261,4,0,
710,18,263,4,0,
710,18,267,4,0,
710,18,271,1,1,1
710,18,310,1,1,2
710,18,331,1,26,
710,18,360,4,0,
710,18,388,1,11,
710,18,399,4,0,
710,18,402,1,48,
710,18,412,4,0,
710,18,425,1,30,
710,18,433,4,0,
710,18,447,4,0,
710,18,451,4,0,
710,18,488,4,0,
710,18,496,4,0,
710,18,516,2,0,
710,18,567,1,6,
710,18,567,1,23,
710,18,567,1,40,
710,18,590,4,0,
711,15,53,4,0,
711,15,63,4,0,
711,15,73,1,20,
711,15,75,1,16,
711,15,76,4,0,
711,15,92,4,0,
711,15,94,4,0,
711,15,104,4,0,
711,15,109,1,1,5
711,15,113,4,0,
711,15,126,4,0,
711,15,138,4,0,
711,15,148,4,0,
711,15,153,1,1,1
711,15,153,1,75,
711,15,153,4,0,
711,15,156,4,0,
711,15,157,4,0,
711,15,164,4,0,
711,15,168,4,0,
711,15,182,4,0,
711,15,184,1,4,
711,15,188,4,0,
711,15,207,4,0,
711,15,213,4,0,
711,15,214,4,0,
711,15,216,4,0,
711,15,218,4,0,
711,15,219,4,0,
711,15,220,1,42,
711,15,237,4,0,
711,15,241,4,0,
711,15,247,1,36,
711,15,247,1,70,
711,15,247,4,0,
711,15,249,4,0,
711,15,261,4,0,
711,15,263,4,0,
711,15,267,4,0,
711,15,271,1,1,3
711,15,310,1,1,4
711,15,331,1,26,
711,15,360,4,0,
711,15,388,1,11,
711,15,399,4,0,
711,15,402,1,48,
711,15,411,4,0,
711,15,412,4,0,
711,15,416,4,0,
711,15,425,1,30,
711,15,433,4,0,
711,15,447,4,0,
711,15,451,4,0,
711,15,488,4,0,
711,15,496,4,0,
711,15,510,4,0,
711,15,566,1,1,2
711,15,566,1,57,
711,15,567,1,6,
711,15,567,1,23,
711,15,567,1,40,
711,15,567,1,63,
711,15,590,4,0,
711,16,53,4,0,
711,16,63,4,0,
711,16,73,1,20,1
711,16,75,1,16,1
711,16,76,4,0,
711,16,92,4,0,
711,16,94,4,0,
711,16,104,4,0,
711,16,109,1,1,5
711,16,113,4,0,
711,16,126,4,0,
711,16,138,4,0,
711,16,148,4,0,
711,16,153,1,1,1
711,16,153,1,75,1
711,16,153,4,0,
711,16,156,4,0,
711,16,157,4,0,
711,16,164,4,0,
711,16,168,4,0,
711,16,180,3,0,
711,16,182,4,0,
711,16,184,1,4,1
711,16,188,4,0,
711,16,202,3,0,
711,16,207,4,0,
711,16,213,4,0,
711,16,214,4,0,
711,16,216,4,0,
711,16,218,4,0,
711,16,219,4,0,
711,16,220,1,42,1
711,16,220,3,0,
711,16,235,3,0,
711,16,237,4,0,
711,16,241,4,0,
711,16,247,1,36,1
711,16,247,1,70,1
711,16,247,4,0,
711,16,249,4,0,
711,16,261,4,0,
711,16,263,4,0,
711,16,267,4,0,
711,16,271,1,1,3
711,16,271,3,0,
711,16,272,3,0,
711,16,277,3,0,
711,16,285,3,0,
711,16,290,4,0,
711,16,310,1,1,4
711,16,331,1,26,1
711,16,360,4,0,
711,16,388,1,11,1
711,16,388,3,0,
711,16,399,4,0,
711,16,402,1,48,1
711,16,402,3,0,
711,16,411,4,0,
711,16,412,4,0,
711,16,416,4,0,
711,16,425,1,30,1
711,16,433,4,0,
711,16,447,4,0,
711,16,451,4,0,
711,16,488,4,0,
711,16,492,3,0,
711,16,496,4,0,
711,16,510,4,0,
711,16,566,1,1,2
711,16,566,1,57,1
711,16,567,1,6,1
711,16,567,1,23,1
711,16,567,1,40,1
711,16,567,1,63,1
711,16,590,4,0,
711,17,53,4,0,
711,17,63,4,0,
711,17,73,1,20,
711,17,75,1,16,
711,17,76,4,0,
711,17,92,4,0,
711,17,94,4,0,
711,17,104,4,0,
711,17,109,1,1,5
711,17,113,4,0,
711,17,126,4,0,
711,17,138,4,0,
711,17,153,1,1,1
711,17,153,1,70,
711,17,153,4,0,
711,17,156,4,0,
711,17,157,4,0,
711,17,164,4,0,
711,17,168,4,0,
711,17,182,4,0,
711,17,184,1,1,6
711,17,184,1,4,
711,17,188,4,0,
711,17,207,4,0,
711,17,213,4,0,
711,17,214,4,0,
711,17,216,4,0,
711,17,218,4,0,
711,17,219,4,0,
711,17,220,1,42,
711,17,237,4,0,
711,17,241,4,0,
711,17,247,1,36,
711,17,247,4,0,
711,17,261,4,0,
711,17,263,4,0,
711,17,267,4,0,
711,17,271,1,1,3
711,17,310,1,1,4
711,17,331,1,26,
711,17,360,4,0,
711,17,388,1,11,
711,17,399,4,0,
711,17,402,1,48,
711,17,411,4,0,
711,17,412,4,0,
711,17,416,4,0,
711,17,425,1,30,
711,17,433,4,0,
711,17,447,4,0,
711,17,451,4,0,
711,17,488,4,0,
711,17,496,4,0,
711,17,566,1,1,2
711,17,566,1,57,
711,17,567,1,6,
711,17,567,1,23,
711,17,567,1,40,
711,17,567,1,63,
711,17,590,4,0,
711,18,53,4,0,
711,18,63,4,0,
711,18,73,1,20,
711,18,75,1,16,
711,18,76,4,0,
711,18,92,4,0,
711,18,94,4,0,
711,18,104,4,0,
711,18,109,1,1,5
711,18,113,4,0,
711,18,126,4,0,
711,18,138,4,0,
711,18,153,1,1,1
711,18,153,1,70,
711,18,153,4,0,
711,18,156,4,0,
711,18,157,4,0,
711,18,164,4,0,
711,18,168,4,0,
711,18,182,4,0,
711,18,184,1,1,6
711,18,184,1,4,
711,18,188,4,0,
711,18,207,4,0,
711,18,213,4,0,
711,18,214,4,0,
711,18,216,4,0,
711,18,218,4,0,
711,18,219,4,0,
711,18,220,1,42,
711,18,237,4,0,
711,18,241,4,0,
711,18,247,1,36,
711,18,247,4,0,
711,18,261,4,0,
711,18,263,4,0,
711,18,267,4,0,
711,18,271,1,1,3
711,18,310,1,1,4
711,18,331,1,26,
711,18,360,4,0,
711,18,388,1,11,
711,18,399,4,0,
711,18,402,1,48,
711,18,411,4,0,
711,18,412,4,0,
711,18,416,4,0,
711,18,425,1,30,
711,18,433,4,0,
711,18,447,4,0,
711,18,451,4,0,
711,18,488,4,0,
711,18,496,4,0,
711,18,566,1,1,2
711,18,566,1,57,
711,18,567,1,6,
711,18,567,1,23,
711,18,567,1,40,
711,18,567,1,63,
711,18,590,4,0,
712,15,33,1,1,1
712,15,36,1,15,
712,15,38,1,49,
712,15,44,1,1,2
712,15,54,2,0,
712,15,57,4,0,
712,15,58,4,0,
712,15,59,1,43,
712,15,59,4,0,
712,15,70,4,0,
712,15,92,4,0,
712,15,104,4,0,
712,15,105,1,47,
712,15,105,2,0,
712,15,106,1,1,3
712,15,112,2,0,
712,15,148,4,0,
712,15,156,4,0,
712,15,157,4,0,
712,15,159,1,20,
712,15,164,4,0,
712,15,174,1,22,
712,15,181,1,5,
712,15,182,4,0,
712,15,196,1,10,
712,15,207,4,0,
712,15,213,4,0,
712,15,214,4,0,
712,15,216,4,0,
712,15,218,4,0,
712,15,219,4,0,
712,15,229,1,35,
712,15,237,4,0,
712,15,240,4,0,
712,15,243,2,0,
712,15,249,4,0,
712,15,258,4,0,
712,15,263,4,0,
712,15,301,1,30,
712,15,317,4,0,
712,15,360,4,0,
712,15,397,4,0,
712,15,419,1,39,
712,15,423,1,26,
712,15,430,4,0,
712,15,444,4,0,
712,15,496,4,0,
712,15,524,4,0,
712,15,590,4,0,
712,16,33,1,1,1
712,16,36,1,15,1
712,16,38,1,49,1
712,16,44,1,1,2
712,16,54,2,0,
712,16,57,4,0,
712,16,58,4,0,
712,16,59,1,43,1
712,16,59,4,0,
712,16,70,4,0,
712,16,92,4,0,
712,16,104,4,0,
712,16,105,1,47,1
712,16,105,2,0,
712,16,106,1,1,3
712,16,112,2,0,
712,16,148,4,0,
712,16,156,4,0,
712,16,157,4,0,
712,16,159,1,20,1
712,16,164,4,0,
712,16,173,3,0,
712,16,174,1,22,1
712,16,181,1,5,1
712,16,182,4,0,
712,16,196,1,10,1
712,16,196,3,0,
712,16,207,4,0,
712,16,213,4,0,
712,16,214,4,0,
712,16,216,4,0,
712,16,218,4,0,
712,16,219,4,0,
712,16,229,1,35,1
712,16,237,4,0,
712,16,240,4,0,
712,16,243,2,0,
712,16,249,4,0,
712,16,258,4,0,
712,16,263,4,0,
712,16,290,4,0,
712,16,301,1,30,1
712,16,317,4,0,
712,16,334,3,0,
712,16,352,3,0,
712,16,360,4,0,
712,16,397,4,0,
712,16,419,1,39,1
712,16,423,1,26,1
712,16,430,4,0,
712,16,444,4,0,
712,16,495,3,0,
712,16,496,4,0,
712,16,524,4,0,
712,16,590,4,0,
712,17,33,1,1,1
712,17,36,1,15,
712,17,38,1,49,
712,17,44,1,1,2
712,17,54,2,0,
712,17,57,4,0,
712,17,58,4,0,
712,17,59,1,43,
712,17,59,4,0,
712,17,92,4,0,
712,17,104,4,0,
712,17,105,1,47,
712,17,105,2,0,
712,17,106,1,1,3
712,17,112,2,0,
712,17,156,4,0,
712,17,157,4,0,
712,17,159,1,20,
712,17,164,4,0,
712,17,174,1,22,
712,17,181,1,5,
712,17,182,4,0,
712,17,196,1,10,
712,17,207,4,0,
712,17,213,4,0,
712,17,214,4,0,
712,17,216,4,0,
712,17,218,4,0,
712,17,219,4,0,
712,17,229,1,35,
712,17,237,4,0,
712,17,240,4,0,
712,17,243,2,0,
712,17,258,4,0,
712,17,263,4,0,
712,17,301,1,30,
712,17,317,4,0,
712,17,360,4,0,
712,17,397,4,0,
712,17,419,1,39,
712,17,423,1,26,
712,17,430,4,0,
712,17,444,4,0,
712,17,496,4,0,
712,17,524,4,0,
712,17,590,4,0,
712,18,33,1,1,1
712,18,36,1,15,
712,18,38,1,49,
712,18,44,1,1,2
712,18,54,2,0,
712,18,57,4,0,
712,18,58,4,0,
712,18,59,1,43,
712,18,59,4,0,
712,18,92,4,0,
712,18,104,4,0,
712,18,105,1,47,
712,18,105,2,0,
712,18,106,1,1,3
712,18,112,2,0,
712,18,156,4,0,
712,18,157,4,0,
712,18,159,1,20,
712,18,164,4,0,
712,18,174,1,22,
712,18,181,1,5,
712,18,182,4,0,
712,18,196,1,10,
712,18,207,4,0,
712,18,213,4,0,
712,18,214,4,0,
712,18,216,4,0,
712,18,218,4,0,
712,18,219,4,0,
712,18,229,1,35,
712,18,237,4,0,
712,18,240,4,0,
712,18,243,2,0,
712,18,258,4,0,
712,18,263,4,0,
712,18,301,1,30,
712,18,317,4,0,
712,18,360,4,0,
712,18,397,4,0,
712,18,419,1,39,
712,18,423,1,26,
712,18,430,4,0,
712,18,444,4,0,
712,18,496,4,0,
712,18,524,4,0,
712,18,590,4,0,
713,15,33,1,1,4
713,15,36,1,15,
713,15,38,1,56,
713,15,44,1,1,5
713,15,46,4,0,
713,15,57,4,0,
713,15,58,4,0,
713,15,59,1,46,
713,15,59,4,0,
713,15,63,4,0,
713,15,70,4,0,
713,15,89,4,0,
713,15,92,4,0,
713,15,104,4,0,
713,15,105,1,51,
713,15,106,1,1,6
713,15,130,1,1,3
713,15,130,1,60,
713,15,148,4,0,
713,15,156,4,0,
713,15,157,4,0,
713,15,159,1,20,
713,15,164,4,0,
713,15,174,1,22,
713,15,181,1,5,
713,15,182,4,0,
713,15,196,1,10,
713,15,207,4,0,
713,15,213,4,0,
713,15,214,4,0,
713,15,216,4,0,
713,15,218,4,0,
713,15,219,4,0,
713,15,229,1,35,
713,15,237,4,0,
713,15,240,4,0,
713,15,242,1,1,2
713,15,242,1,65,
713,15,249,4,0,
713,15,258,4,0,
713,15,263,4,0,
713,15,301,1,30,
713,15,317,4,0,
713,15,334,1,1,1
713,15,360,4,0,
713,15,397,4,0,
713,15,416,4,0,
713,15,419,1,42,
713,15,423,1,26,
713,15,430,4,0,
713,15,444,4,0,
713,15,496,4,0,
713,15,523,4,0,
713,15,524,4,0,
713,15,590,4,0,
713,16,33,1,1,4
713,16,36,1,15,1
713,16,38,1,56,1
713,16,44,1,1,5
713,16,46,4,0,
713,16,57,4,0,
713,16,58,4,0,
713,16,59,1,46,1
713,16,59,4,0,
713,16,63,4,0,
713,16,70,4,0,
713,16,89,4,0,
713,16,92,4,0,
713,16,104,4,0,
713,16,105,1,51,1
713,16,106,1,1,6
713,16,130,1,1,3
713,16,130,1,60,1
713,16,148,4,0,
713,16,156,4,0,
713,16,157,4,0,
713,16,159,1,20,1
713,16,164,4,0,
713,16,173,3,0,
713,16,174,1,22,1
713,16,181,1,5,1
713,16,182,4,0,
713,16,196,1,10,1
713,16,196,3,0,
713,16,207,4,0,
713,16,213,4,0,
713,16,214,4,0,
713,16,216,4,0,
713,16,218,4,0,
713,16,219,4,0,
713,16,229,1,35,1
713,16,237,4,0,
713,16,240,4,0,
713,16,242,1,1,2
713,16,242,1,65,1
713,16,249,4,0,
713,16,258,4,0,
713,16,263,4,0,
713,16,276,3,0,
713,16,290,4,0,
713,16,301,1,30,1
713,16,317,4,0,
713,16,334,1,1,1
713,16,334,3,0,
713,16,335,3,0,
713,16,352,3,0,
713,16,360,4,0,
713,16,397,4,0,
713,16,416,4,0,
713,16,419,1,42,1
713,16,423,1,26,1
713,16,430,4,0,
713,16,442,3,0,
713,16,444,4,0,
713,16,495,3,0,
713,16,496,4,0,
713,16,523,4,0,
713,16,524,4,0,
713,16,590,4,0,
713,17,33,1,1,5
713,17,34,1,0,
713,17,34,1,1,1
713,17,36,1,15,
713,17,38,1,56,
713,17,44,1,1,6
713,17,46,4,0,
713,17,57,4,0,
713,17,58,4,0,
713,17,59,1,46,
713,17,59,4,0,
713,17,63,4,0,
713,17,89,4,0,
713,17,92,4,0,
713,17,104,4,0,
713,17,105,1,51,
713,17,106,1,1,7
713,17,130,1,1,3
713,17,130,1,60,
713,17,156,4,0,
713,17,157,4,0,
713,17,159,1,20,
713,17,164,4,0,
713,17,174,1,22,
713,17,181,1,1,8
713,17,181,1,5,
713,17,182,4,0,
713,17,196,1,10,
713,17,207,4,0,
713,17,213,4,0,
713,17,214,4,0,
713,17,216,4,0,
713,17,218,4,0,
713,17,219,4,0,
713,17,229,1,35,
713,17,237,4,0,
713,17,240,4,0,
713,17,242,1,1,2
713,17,242,1,65,
713,17,258,4,0,
713,17,263,4,0,
713,17,301,1,30,
713,17,317,4,0,
713,17,334,1,1,4
713,17,360,4,0,
713,17,397,4,0,
713,17,416,4,0,
713,17,419,1,42,
713,17,423,1,26,
713,17,430,4,0,
713,17,444,4,0,
713,17,496,4,0,
713,17,523,4,0,
713,17,524,4,0,
713,17,590,4,0,
713,18,33,1,1,6
713,18,34,1,0,
713,18,34,1,1,1
713,18,36,1,15,
713,18,38,1,56,
713,18,44,1,1,7
713,18,46,4,0,
713,18,57,4,0,
713,18,58,4,0,
713,18,59,1,46,
713,18,59,4,0,
713,18,63,4,0,
713,18,89,4,0,
713,18,92,4,0,
713,18,104,4,0,
713,18,105,1,51,
713,18,106,1,1,8
713,18,130,1,1,4
713,18,130,1,60,
713,18,156,4,0,
713,18,157,4,0,
713,18,159,1,20,
713,18,164,4,0,
713,18,174,1,22,
713,18,181,1,1,9
713,18,181,1,5,
713,18,182,4,0,
713,18,196,1,10,
713,18,207,4,0,
713,18,213,4,0,
713,18,214,4,0,
713,18,216,4,0,
713,18,218,4,0,
713,18,219,4,0,
713,18,229,1,35,
713,18,237,4,0,
713,18,240,4,0,
713,18,242,1,1,3
713,18,242,1,65,
713,18,258,4,0,
713,18,263,4,0,
713,18,301,1,30,
713,18,317,4,0,
713,18,334,1,1,5
713,18,360,4,0,
713,18,397,4,0,
713,18,416,4,0,
713,18,419,1,42,
713,18,423,1,26,
713,18,430,4,0,
713,18,444,4,0,
713,18,469,1,1,2
713,18,496,4,0,
713,18,523,4,0,
713,18,524,4,0,
713,18,590,4,0,
714,15,13,1,31,
714,15,15,4,0,
714,15,16,1,11,
714,15,17,1,16,
714,15,18,1,40,
714,15,19,4,0,
714,15,33,1,1,3
714,15,44,1,13,
714,15,48,1,1,2
714,15,76,4,0,
714,15,92,4,0,
714,15,94,4,0,
714,15,97,1,18,
714,15,103,1,1,1
714,15,104,4,0,
714,15,138,4,0,
714,15,141,1,5,
714,15,156,4,0,
714,15,162,1,43,
714,15,164,4,0,
714,15,168,4,0,
714,15,182,4,0,
714,15,200,2,0,
714,15,207,4,0,
714,15,211,4,0,
714,15,213,4,0,
714,15,214,4,0,
714,15,216,4,0,
714,15,218,4,0,
714,15,237,4,0,
714,15,241,4,0,
714,15,247,4,0,
714,15,259,4,0,
714,15,263,4,0,
714,15,269,4,0,
714,15,280,4,0,
714,15,289,2,0,
714,15,314,1,23,
714,15,332,4,0,
714,15,355,1,27,
714,15,355,4,0,
714,15,366,1,35,
714,15,366,2,0,
714,15,369,4,0,
714,15,399,4,0,
714,15,403,1,48,
714,15,404,4,0,
714,15,415,2,0,
714,15,421,4,0,
714,15,434,3,0,
714,15,496,4,0,
714,15,497,4,0,
714,15,512,4,0,
714,15,528,4,0,
714,15,542,1,58,
714,15,590,4,0,
714,16,13,1,31,1
714,16,15,4,0,
714,16,16,1,11,1
714,16,17,1,16,1
714,16,18,1,40,1
714,16,19,4,0,
714,16,33,1,1,3
714,16,44,1,13,1
714,16,48,1,1,2
714,16,76,4,0,
714,16,92,4,0,
714,16,94,4,0,
714,16,97,1,18,1
714,16,103,1,1,1
714,16,104,4,0,
714,16,138,4,0,
714,16,141,1,5,1
714,16,143,3,0,
714,16,156,4,0,
714,16,162,1,43,1
714,16,162,3,0,
714,16,164,4,0,
714,16,168,4,0,
714,16,173,3,0,
714,16,182,4,0,
714,16,200,2,0,
714,16,200,3,0,
714,16,207,4,0,
714,16,211,4,0,
714,16,213,4,0,
714,16,214,4,0,
714,16,216,4,0,
714,16,218,4,0,
714,16,231,3,0,
714,16,237,4,0,
714,16,241,4,0,
714,16,247,4,0,
714,16,253,3,0,
714,16,257,3,0,
714,16,259,4,0,
714,16,263,4,0,
714,16,269,4,0,
714,16,280,4,0,
714,16,289,2,0,
714,16,289,3,0,
714,16,290,4,0,
714,16,304,3,0,
714,16,314,1,23,1
714,16,332,4,0,
714,16,352,3,0,
714,16,355,1,27,1
714,16,355,4,0,
714,16,366,1,35,1
714,16,366,2,0,
714,16,366,3,0,
714,16,369,4,0,
714,16,399,4,0,
714,16,403,1,48,1
714,16,404,4,0,
714,16,406,3,0,
714,16,415,2,0,
714,16,421,4,0,
714,16,434,3,0,
714,16,496,4,0,
714,16,497,4,0,
714,16,512,4,0,
714,16,528,4,0,
714,16,542,1,58,1
714,16,590,4,0,
714,17,13,1,31,
714,17,16,1,11,
714,17,17,1,16,
714,17,18,1,40,
714,17,19,4,0,
714,17,33,1,1,3
714,17,44,1,13,
714,17,48,1,1,2
714,17,71,1,5,
714,17,76,4,0,
714,17,92,4,0,
714,17,94,4,0,
714,17,97,1,18,
714,17,103,1,1,1
714,17,104,4,0,
714,17,138,4,0,
714,17,141,4,0,
714,17,156,4,0,
714,17,162,1,43,
714,17,164,4,0,
714,17,168,4,0,
714,17,182,4,0,
714,17,200,2,0,
714,17,207,4,0,
714,17,211,4,0,
714,17,213,4,0,
714,17,214,4,0,
714,17,216,4,0,
714,17,218,4,0,
714,17,237,4,0,
714,17,241,4,0,
714,17,247,4,0,
714,17,259,4,0,
714,17,263,4,0,
714,17,269,4,0,
714,17,280,4,0,
714,17,289,2,0,
714,17,314,1,23,
714,17,332,4,0,
714,17,355,1,27,
714,17,355,4,0,
714,17,366,1,35,
714,17,366,2,0,
714,17,369,4,0,
714,17,399,4,0,
714,17,403,1,48,
714,17,404,4,0,
714,17,415,2,0,
714,17,421,4,0,
714,17,434,3,0,
714,17,496,4,0,
714,17,497,4,0,
714,17,512,4,0,
714,17,528,4,0,
714,17,542,1,58,
714,17,590,4,0,
714,18,13,1,31,
714,18,16,1,11,
714,18,17,1,16,
714,18,18,1,40,
714,18,19,4,0,
714,18,33,1,1,3
714,18,44,1,13,
714,18,48,1,1,2
714,18,71,1,5,
714,18,76,4,0,
714,18,92,4,0,
714,18,94,4,0,
714,18,97,1,18,
714,18,103,1,1,1
714,18,104,4,0,
714,18,138,4,0,
714,18,141,4,0,
714,18,156,4,0,
714,18,162,1,43,
714,18,164,4,0,
714,18,168,4,0,
714,18,182,4,0,
714,18,200,2,0,
714,18,207,4,0,
714,18,211,4,0,
714,18,213,4,0,
714,18,214,4,0,
714,18,216,4,0,
714,18,218,4,0,
714,18,237,4,0,
714,18,241,4,0,
714,18,247,4,0,
714,18,259,4,0,
714,18,263,4,0,
714,18,269,4,0,
714,18,280,4,0,
714,18,289,2,0,
714,18,314,1,23,
714,18,332,4,0,
714,18,355,1,27,
714,18,355,4,0,
714,18,366,1,35,
714,18,366,2,0,
714,18,369,4,0,
714,18,399,4,0,
714,18,403,1,48,
714,18,404,4,0,
714,18,415,2,0,
714,18,421,4,0,
714,18,434,3,0,
714,18,496,4,0,
714,18,497,4,0,
714,18,512,4,0,
714,18,528,4,0,
714,18,542,1,58,
714,18,590,4,0,
715,15,13,1,31,
715,15,15,4,0,
715,15,16,1,11,
715,15,17,1,16,
715,15,18,1,40,
715,15,19,4,0,
715,15,33,1,1,7
715,15,44,1,13,
715,15,48,1,1,6
715,15,53,4,0,
715,15,63,4,0,
715,15,76,4,0,
715,15,92,4,0,
715,15,94,4,0,
715,15,97,1,18,
715,15,103,1,1,5
715,15,104,4,0,
715,15,138,4,0,
715,15,141,1,5,
715,15,156,4,0,
715,15,162,1,43,
715,15,164,4,0,
715,15,168,4,0,
715,15,182,4,0,
715,15,207,4,0,
715,15,211,4,0,
715,15,213,4,0,
715,15,214,4,0,
715,15,216,4,0,
715,15,218,4,0,
715,15,236,1,1,1
715,15,237,4,0,
715,15,241,4,0,
715,15,247,4,0,
715,15,259,4,0,
715,15,263,4,0,
715,15,269,4,0,
715,15,280,4,0,
715,15,314,1,23,
715,15,332,4,0,
715,15,337,4,0,
715,15,355,1,27,
715,15,355,4,0,
715,15,366,1,35,
715,15,369,4,0,
715,15,399,4,0,
715,15,403,1,53,
715,15,404,4,0,
715,15,406,1,1,3
715,15,406,1,70,
715,15,411,4,0,
715,15,416,4,0,
715,15,421,4,0,
715,15,434,3,0,
715,15,468,4,0,
715,15,496,4,0,
715,15,497,4,0,
715,15,512,4,0,
715,15,528,4,0,
715,15,542,1,1,4
715,15,542,1,62,
715,15,586,1,1,2
715,15,586,1,75,
715,15,590,4,0,
715,16,13,1,31,1
715,16,15,4,0,
715,16,16,1,11,1
715,16,17,1,16,1
715,16,18,1,40,1
715,16,19,4,0,
715,16,33,1,1,7
715,16,44,1,13,1
715,16,48,1,1,6
715,16,53,4,0,
715,16,63,4,0,
715,16,76,4,0,
715,16,92,4,0,
715,16,94,4,0,
715,16,97,1,18,1
715,16,103,1,1,5
715,16,104,4,0,
715,16,138,4,0,
715,16,141,1,5,1
715,16,143,3,0,
715,16,156,4,0,
715,16,162,1,43,1
715,16,162,3,0,
715,16,164,4,0,
715,16,168,4,0,
715,16,173,3,0,
715,16,182,4,0,
715,16,200,3,0,
715,16,207,4,0,
715,16,211,4,0,
715,16,213,4,0,
715,16,214,4,0,
715,16,216,4,0,
715,16,218,4,0,
715,16,231,3,0,
715,16,236,1,1,1
715,16,237,4,0,
715,16,241,4,0,
715,16,247,4,0,
715,16,253,3,0,
715,16,257,3,0,
715,16,259,4,0,
715,16,263,4,0,
715,16,269,4,0,
715,16,280,4,0,
715,16,289,3,0,
715,16,290,4,0,
715,16,304,3,0,
715,16,314,1,23,1
715,16,332,4,0,
715,16,337,4,0,
715,16,352,3,0,
715,16,355,1,27,1
715,16,355,4,0,
715,16,366,1,35,1
715,16,366,3,0,
715,16,369,4,0,
715,16,399,4,0,
715,16,403,1,53,1
715,16,404,4,0,
715,16,406,1,1,3
715,16,406,1,70,1
715,16,406,3,0,
715,16,411,4,0,
715,16,416,4,0,
715,16,421,4,0,
715,16,434,3,0,
715,16,468,4,0,
715,16,496,4,0,
715,16,497,4,0,
715,16,512,4,0,
715,16,528,4,0,
715,16,542,1,1,4
715,16,542,1,62,1
715,16,586,1,1,2
715,16,586,1,75,1
715,16,590,4,0,
715,17,13,1,31,
715,17,16,1,11,
715,17,17,1,16,
715,17,18,1,40,
715,17,19,4,0,
715,17,33,1,1,7
715,17,44,1,13,
715,17,48,1,1,6
715,17,53,4,0,
715,17,63,4,0,
715,17,71,1,1,8
715,17,71,1,5,
715,17,76,4,0,
715,17,92,4,0,
715,17,94,4,0,
715,17,97,1,18,
715,17,103,1,1,5
715,17,104,4,0,
715,17,138,4,0,
715,17,141,4,0,
715,17,156,4,0,
715,17,162,1,43,
715,17,164,4,0,
715,17,168,4,0,
715,17,182,4,0,
715,17,207,4,0,
715,17,211,4,0,
715,17,213,4,0,
715,17,214,4,0,
715,17,216,4,0,
715,17,218,4,0,
715,17,236,1,1,4
715,17,237,4,0,
715,17,241,4,0,
715,17,247,4,0,
715,17,259,4,0,
715,17,263,4,0,
715,17,269,4,0,
715,17,280,4,0,
715,17,314,1,23,
715,17,332,4,0,
715,17,337,4,0,
715,17,355,1,27,
715,17,355,4,0,
715,17,366,1,35,
715,17,369,4,0,
715,17,399,4,0,
715,17,403,1,53,
715,17,404,4,0,
715,17,406,1,1,2
715,17,406,1,70,
715,17,411,4,0,
715,17,416,4,0,
715,17,421,4,0,
715,17,434,3,0,
715,17,496,4,0,
715,17,497,4,0,
715,17,512,4,0,
715,17,528,4,0,
715,17,542,1,1,3
715,17,542,1,62,
715,17,586,1,1,1
715,17,586,1,75,
715,17,590,4,0,
715,18,13,1,31,
715,18,16,1,11,
715,18,17,1,16,
715,18,18,1,40,
715,18,19,4,0,
715,18,33,1,1,7
715,18,44,1,13,
715,18,48,1,1,6
715,18,53,4,0,
715,18,63,4,0,
715,18,71,1,1,8
715,18,71,1,5,
715,18,76,4,0,
715,18,92,4,0,
715,18,94,4,0,
715,18,97,1,18,
715,18,103,1,1,5
715,18,104,4,0,
715,18,138,4,0,
715,18,141,4,0,
715,18,156,4,0,
715,18,162,1,43,
715,18,164,4,0,
715,18,168,4,0,
715,18,182,4,0,
715,18,207,4,0,
715,18,211,4,0,
715,18,213,4,0,
715,18,214,4,0,
715,18,216,4,0,
715,18,218,4,0,
715,18,236,1,1,4
715,18,237,4,0,
715,18,241,4,0,
715,18,247,4,0,
715,18,259,4,0,
715,18,263,4,0,
715,18,269,4,0,
715,18,280,4,0,
715,18,314,1,23,
715,18,332,4,0,
715,18,337,4,0,
715,18,355,1,27,
715,18,355,4,0,
715,18,366,1,35,
715,18,369,4,0,
715,18,399,4,0,
715,18,403,1,53,
715,18,404,4,0,
715,18,406,1,1,2
715,18,406,1,70,
715,18,411,4,0,
715,18,416,4,0,
715,18,421,4,0,
715,18,434,3,0,
715,18,496,4,0,
715,18,497,4,0,
715,18,512,4,0,
715,18,528,4,0,
715,18,542,1,1,3
715,18,542,1,62,
715,18,586,1,1,1
715,18,586,1,75,
715,18,590,4,0,
716,15,15,4,0,
716,15,36,1,1,4
716,15,46,4,0,
716,15,62,1,10,
716,15,63,4,0,
716,15,85,4,0,
716,15,86,4,0,
716,15,87,4,0,
716,15,92,4,0,
716,15,94,4,0,
716,15,104,4,0,
716,15,113,1,5,
716,15,113,4,0,
716,15,115,4,0,
716,15,148,4,0,
716,15,156,4,0,
716,15,157,4,0,
716,15,164,4,0,
716,15,182,4,0,
716,15,200,1,93,
716,15,207,4,0,
716,15,214,4,0,
716,15,216,4,0,
716,15,218,4,0,
716,15,224,1,44,
716,15,237,4,0,
716,15,240,4,0,
716,15,241,4,0,
716,15,244,1,59,
716,15,244,4,0,
716,15,258,4,0,
716,15,263,4,0,
716,15,267,1,72,
716,15,267,4,0,
716,15,275,1,1,3
716,15,312,1,1,2
716,15,347,4,0,
716,15,356,1,18,
716,15,370,1,80,
716,15,400,1,51,
716,15,411,4,0,
716,15,416,1,88,
716,15,416,4,0,
716,15,430,4,0,
716,15,447,4,0,
716,15,473,4,0,
716,15,496,4,0,
716,15,497,4,0,
716,15,505,1,1,1
716,15,532,1,55,
716,15,581,1,63,
716,15,585,1,35,
716,15,590,4,0,
716,15,601,1,26,
716,15,605,4,0,
716,16,15,4,0,
716,16,36,1,1,4
716,16,46,4,0,
716,16,62,1,10,1
716,16,63,4,0,
716,16,85,4,0,
716,16,86,4,0,
716,16,87,4,0,
716,16,92,4,0,
716,16,94,4,0,
716,16,104,4,0,
716,16,113,1,5,1
716,16,113,4,0,
716,16,115,4,0,
716,16,148,4,0,
716,16,156,4,0,
716,16,157,4,0,
716,16,164,4,0,
716,16,173,3,0,
716,16,182,4,0,
716,16,200,1,93,1
716,16,200,3,0,
716,16,207,4,0,
716,16,214,4,0,
716,16,216,4,0,
716,16,218,4,0,
716,16,224,1,44,1
716,16,237,4,0,
716,16,240,4,0,
716,16,241,4,0,
716,16,244,1,59,1
716,16,244,4,0,
716,16,258,4,0,
716,16,263,4,0,
716,16,267,1,72,1
716,16,267,4,0,
716,16,275,1,1,3
716,16,283,3,0,
716,16,290,4,0,
716,16,304,3,0,
716,16,312,1,1,2
716,16,335,3,0,
716,16,347,4,0,
716,16,356,1,18,1
716,16,356,3,0,
716,16,370,1,80,1
716,16,400,1,51,1
716,16,411,4,0,
716,16,416,1,88,1
716,16,416,4,0,
716,16,428,3,0,
716,16,430,4,0,
716,16,447,4,0,
716,16,472,3,0,
716,16,473,4,0,
716,16,496,4,0,
716,16,497,4,0,
716,16,505,1,1,1
716,16,532,1,55,1
716,16,581,1,63,1
716,16,585,1,35,1
716,16,590,4,0,
716,16,601,1,26,1
716,16,605,4,0,
716,17,36,1,1,4
716,17,46,4,0,
716,17,62,1,10,
716,17,63,4,0,
716,17,85,4,0,
716,17,86,4,0,
716,17,87,4,0,
716,17,92,4,0,
716,17,94,4,0,
716,17,104,4,0,
716,17,113,1,5,
716,17,113,4,0,
716,17,115,4,0,
716,17,156,4,0,
716,17,157,4,0,
716,17,164,4,0,
716,17,182,4,0,
716,17,200,1,93,
716,17,207,4,0,
716,17,214,4,0,
716,17,216,4,0,
716,17,218,4,0,
716,17,224,1,44,
716,17,237,4,0,
716,17,240,4,0,
716,17,241,4,0,
716,17,244,1,59,
716,17,244,4,0,
716,17,258,4,0,
716,17,263,4,0,
716,17,267,1,72,
716,17,267,4,0,
716,17,275,1,1,3
716,17,312,1,1,2
716,17,347,4,0,
716,17,356,1,18,
716,17,370,1,80,
716,17,400,1,51,
716,17,411,4,0,
716,17,416,1,88,
716,17,416,4,0,
716,17,430,4,0,
716,17,447,4,0,
716,17,473,4,0,
716,17,496,4,0,
716,17,497,4,0,
716,17,505,1,1,1
716,17,532,1,55,
716,17,581,1,63,
716,17,585,1,35,
716,17,590,4,0,
716,17,601,1,26,
716,17,605,4,0,
716,18,36,1,1,4
716,18,46,4,0,
716,18,62,1,10,
716,18,63,4,0,
716,18,85,4,0,
716,18,86,4,0,
716,18,87,4,0,
716,18,92,4,0,
716,18,94,4,0,
716,18,104,4,0,
716,18,113,1,5,
716,18,113,4,0,
716,18,115,4,0,
716,18,156,4,0,
716,18,157,4,0,
716,18,164,4,0,
716,18,182,4,0,
716,18,200,1,93,
716,18,207,4,0,
716,18,214,4,0,
716,18,216,4,0,
716,18,218,4,0,
716,18,224,1,44,
716,18,237,4,0,
716,18,240,4,0,
716,18,241,4,0,
716,18,244,1,59,
716,18,244,4,0,
716,18,258,4,0,
716,18,263,4,0,
716,18,267,1,72,
716,18,267,4,0,
716,18,275,1,1,3
716,18,312,1,1,2
716,18,347,4,0,
716,18,356,1,18,
716,18,370,1,80,
716,18,400,1,51,
716,18,411,4,0,
716,18,416,1,88,
716,18,416,4,0,
716,18,430,4,0,
716,18,447,4,0,
716,18,473,4,0,
716,18,496,4,0,
716,18,497,4,0,
716,18,505,1,1,1
716,18,532,1,55,
716,18,581,1,63,
716,18,585,1,35,
716,18,590,4,0,
716,18,601,1,26,
716,18,605,4,0,
717,15,13,1,1,2
717,15,15,4,0,
717,15,19,4,0,
717,15,50,1,35,
717,15,63,1,88,
717,15,63,4,0,
717,15,92,4,0,
717,15,94,1,59,
717,15,94,4,0,
717,15,104,1,5,
717,15,104,4,0,
717,15,138,4,0,
717,15,143,1,93,
717,15,156,4,0,
717,15,157,4,0,
717,15,164,4,0,
717,15,168,4,0,
717,15,182,4,0,
717,15,207,4,0,
717,15,211,4,0,
717,15,214,4,0,
717,15,216,4,0,
717,15,218,4,0,
717,15,237,4,0,
717,15,240,4,0,
717,15,241,4,0,
717,15,247,4,0,
717,15,259,4,0,
717,15,263,4,0,
717,15,269,1,1,3
717,15,269,4,0,
717,15,332,4,0,
717,15,337,4,0,
717,15,355,1,1,4
717,15,355,4,0,
717,15,369,4,0,
717,15,373,4,0,
717,15,389,1,80,
717,15,399,1,44,
717,15,399,4,0,
717,15,403,1,10,
717,15,407,1,63,
717,15,411,1,72,
717,15,411,4,0,
717,15,416,4,0,
717,15,421,4,0,
717,15,468,4,0,
717,15,492,1,51,
717,15,496,4,0,
717,15,507,4,0,
717,15,512,4,0,
717,15,542,1,1,1
717,15,555,1,18,
717,15,555,4,0,
717,15,566,1,55,
717,15,590,4,0,
717,15,613,1,26,
717,16,13,1,1,2
717,16,15,4,0,
717,16,19,4,0,
717,16,50,1,35,1
717,16,63,1,88,1
717,16,63,4,0,
717,16,92,4,0,
717,16,94,1,59,1
717,16,94,4,0,
717,16,104,1,5,1
717,16,104,4,0,
717,16,138,4,0,
717,16,143,1,93,1
717,16,143,3,0,
717,16,156,4,0,
717,16,157,4,0,
717,16,164,4,0,
717,16,168,4,0,
717,16,173,3,0,
717,16,182,4,0,
717,16,207,4,0,
717,16,211,4,0,
717,16,214,4,0,
717,16,216,4,0,
717,16,218,4,0,
717,16,237,4,0,
717,16,240,4,0,
717,16,241,4,0,
717,16,247,4,0,
717,16,257,3,0,
717,16,259,4,0,
717,16,263,4,0,
717,16,269,1,1,3
717,16,269,4,0,
717,16,282,3,0,
717,16,290,4,0,
717,16,304,3,0,
717,16,332,4,0,
717,16,335,3,0,
717,16,337,4,0,
717,16,355,1,1,4
717,16,355,4,0,
717,16,366,3,0,
717,16,369,4,0,
717,16,373,4,0,
717,16,389,1,80,1
717,16,399,1,44,1
717,16,399,4,0,
717,16,403,1,10,1
717,16,407,1,63,1
717,16,411,1,72,1
717,16,411,4,0,
717,16,416,4,0,
717,16,421,4,0,
717,16,428,3,0,
717,16,468,4,0,
717,16,492,1,51,1
717,16,492,3,0,
717,16,496,4,0,
717,16,507,4,0,
717,16,512,4,0,
717,16,542,1,1,1
717,16,555,1,18,1
717,16,555,4,0,
717,16,566,1,55,1
717,16,590,4,0,
717,16,613,1,26,1
717,17,13,1,1,2
717,17,19,4,0,
717,17,50,1,35,
717,17,63,1,88,
717,17,63,4,0,
717,17,92,4,0,
717,17,94,1,59,
717,17,94,4,0,
717,17,104,1,5,
717,17,104,4,0,
717,17,138,4,0,
717,17,143,1,93,
717,17,156,4,0,
717,17,157,4,0,
717,17,164,4,0,
717,17,168,4,0,
717,17,182,4,0,
717,17,207,4,0,
717,17,211,4,0,
717,17,214,4,0,
717,17,216,4,0,
717,17,218,4,0,
717,17,237,4,0,
717,17,240,4,0,
717,17,241,4,0,
717,17,247,4,0,
717,17,259,4,0,
717,17,263,4,0,
717,17,269,1,1,3
717,17,269,4,0,
717,17,332,4,0,
717,17,337,4,0,
717,17,355,1,1,4
717,17,355,4,0,
717,17,369,4,0,
717,17,373,4,0,
717,17,389,1,80,
717,17,399,1,44,
717,17,399,4,0,
717,17,403,1,10,
717,17,407,1,63,
717,17,411,1,72,
717,17,411,4,0,
717,17,416,4,0,
717,17,421,4,0,
717,17,492,1,51,
717,17,496,4,0,
717,17,507,4,0,
717,17,512,4,0,
717,17,542,1,1,1
717,17,555,1,18,
717,17,555,4,0,
717,17,566,1,55,
717,17,590,4,0,
717,17,613,1,26,
717,18,13,1,1,2
717,18,19,4,0,
717,18,50,1,35,
717,18,63,1,88,
717,18,63,4,0,
717,18,92,4,0,
717,18,94,1,59,
717,18,94,4,0,
717,18,104,1,5,
717,18,104,4,0,
717,18,138,4,0,
717,18,143,1,93,
717,18,156,4,0,
717,18,157,4,0,
717,18,164,4,0,
717,18,168,4,0,
717,18,182,4,0,
717,18,207,4,0,
717,18,211,4,0,
717,18,214,4,0,
717,18,216,4,0,
717,18,218,4,0,
717,18,237,4,0,
717,18,240,4,0,
717,18,241,4,0,
717,18,247,4,0,
717,18,259,4,0,
717,18,263,4,0,
717,18,269,1,1,3
717,18,269,4,0,
717,18,332,4,0,
717,18,337,4,0,
717,18,355,1,1,4
717,18,355,4,0,
717,18,369,4,0,
717,18,373,4,0,
717,18,389,1,80,
717,18,399,1,44,
717,18,399,4,0,
717,18,403,1,10,
717,18,407,1,63,
717,18,411,1,72,
717,18,411,4,0,
717,18,416,4,0,
717,18,421,4,0,
717,18,492,1,51,
717,18,496,4,0,
717,18,507,4,0,
717,18,512,4,0,
717,18,542,1,1,1
717,18,555,1,18,
717,18,555,4,0,
717,18,566,1,55,
717,18,590,4,0,
717,18,613,1,26,
718,15,20,1,18,
718,15,44,1,1,4
718,15,63,4,0,
718,15,70,4,0,
718,15,89,1,55,
718,15,89,4,0,
718,15,91,1,10,
718,15,91,4,0,
718,15,92,4,0,
718,15,104,4,0,
718,15,114,1,44,
718,15,137,1,1,1
718,15,156,4,0,
718,15,157,4,0,
718,15,164,4,0,
718,15,182,4,0,
718,15,200,1,93,
718,15,201,1,35,
718,15,201,4,0,
718,15,207,4,0,
718,15,214,4,0,
718,15,216,4,0,
718,15,218,4,0,
718,15,219,1,5,
718,15,219,4,0,
718,15,225,1,1,3
718,15,237,4,0,
718,15,241,4,0,
718,15,242,1,51,
718,15,245,1,88,
718,15,249,4,0,
718,15,263,4,0,
718,15,280,4,0,
718,15,293,1,59,
718,15,349,1,72,
718,15,406,1,63,
718,15,411,4,0,
718,15,416,4,0,
718,15,434,3,0,
718,15,444,4,0,
718,15,447,4,0,
718,15,482,4,0,
718,15,489,1,80,
718,15,496,4,0,
718,15,523,1,1,2
718,15,523,4,0,
718,15,525,4,0,
718,15,590,4,0,
718,15,616,1,26,
718,16,20,1,18,1
718,16,20,3,0,
718,16,44,1,1,4
718,16,63,4,0,
718,16,70,4,0,
718,16,89,1,55,1
718,16,89,4,0,
718,16,91,1,10,1
718,16,91,4,0,
718,16,92,4,0,
718,16,104,4,0,
718,16,114,1,44,1
718,16,137,1,1,1
718,16,156,4,0,
718,16,157,4,0,
718,16,164,4,0,
718,16,173,3,0,
718,16,180,3,0,
718,16,182,4,0,
718,16,200,1,93,1
718,16,200,3,0,
718,16,201,1,35,1
718,16,201,4,0,
718,16,207,4,0,
718,16,214,4,0,
718,16,216,4,0,
718,16,218,4,0,
718,16,219,1,5,1
718,16,219,4,0,
718,16,220,3,0,
718,16,225,1,1,3
718,16,231,3,0,
718,16,237,4,0,
718,16,241,4,0,
718,16,242,1,51,1
718,16,245,1,88,1
718,16,249,4,0,
718,16,263,4,0,
718,16,276,3,0,
718,16,280,4,0,
718,16,290,4,0,
718,16,293,1,59,1
718,16,304,3,0,
718,16,335,3,0,
718,16,349,1,72,1
718,16,351,3,0,
718,16,406,1,63,1
718,16,406,3,0,
718,16,411,4,0,
718,16,414,3,0,
718,16,416,4,0,
718,16,428,3,0,
718,16,434,3,0,
718,16,444,4,0,
718,16,447,4,0,
718,16,482,4,0,
718,16,489,1,80,1
718,16,496,4,0,
718,16,523,1,1,2
718,16,523,4,0,
718,16,525,4,0,
718,16,590,4,0,
718,16,616,1,26,1
718,17,20,1,18,
718,17,44,1,1,4
718,17,63,4,0,
718,17,89,1,55,
718,17,89,4,0,
718,17,91,1,10,
718,17,92,4,0,
718,17,104,4,0,
718,17,114,1,44,
718,17,137,1,1,1
718,17,156,4,0,
718,17,157,4,0,
718,17,164,4,0,
718,17,182,4,0,
718,17,200,1,80,
718,17,201,1,35,
718,17,201,4,0,
718,17,207,4,0,
718,17,214,4,0,
718,17,216,4,0,
718,17,218,4,0,
718,17,219,1,5,
718,17,219,4,0,
718,17,225,1,1,3
718,17,237,4,0,
718,17,241,4,0,
718,17,242,1,51,
718,17,263,4,0,
718,17,280,4,0,
718,17,293,1,59,
718,17,406,1,63,
718,17,411,4,0,
718,17,416,4,0,
718,17,434,3,0,
718,17,444,4,0,
718,17,447,4,0,
718,17,482,4,0,
718,17,489,1,72,
718,17,496,4,0,
718,17,523,1,1,2
718,17,523,4,0,
718,17,525,4,0,
718,17,590,4,0,
718,17,616,1,26,
718,18,20,1,18,
718,18,44,1,1,4
718,18,63,4,0,
718,18,89,1,55,
718,18,89,4,0,
718,18,91,1,10,
718,18,92,4,0,
718,18,104,4,0,
718,18,114,1,44,
718,18,137,1,1,1
718,18,156,4,0,
718,18,157,4,0,
718,18,164,4,0,
718,18,182,4,0,
718,18,200,1,80,
718,18,201,1,35,
718,18,201,4,0,
718,18,207,4,0,
718,18,214,4,0,
718,18,216,4,0,
718,18,218,4,0,
718,18,219,1,5,
718,18,219,4,0,
718,18,225,1,1,3
718,18,237,4,0,
718,18,241,4,0,
718,18,242,1,51,
718,18,263,4,0,
718,18,280,4,0,
718,18,293,1,59,
718,18,406,1,63,
718,18,411,4,0,
718,18,416,4,0,
718,18,434,3,0,
718,18,444,4,0,
718,18,447,4,0,
718,18,482,4,0,
718,18,489,1,72,
718,18,496,4,0,
718,18,523,1,1,2
718,18,523,4,0,
718,18,525,4,0,
718,18,590,4,0,
718,18,616,1,26,
719,15,33,1,1,1
719,15,63,4,0,
719,15,88,1,5,
719,15,92,4,0,
719,15,94,4,0,
719,15,104,4,0,
719,15,106,1,1,2
719,15,113,1,60,
719,15,113,4,0,
719,15,115,1,18,
719,15,115,4,0,
719,15,148,4,0,
719,15,153,4,0,
719,15,156,4,0,
719,15,157,4,0,
719,15,159,1,8,
719,15,164,4,0,
719,15,175,1,35,
719,15,182,4,0,
719,15,201,4,0,
719,15,207,4,0,
719,15,214,4,0,
719,15,216,4,0,
719,15,218,4,0,
719,15,219,1,70,
719,15,219,4,0,
719,15,237,4,0,
719,15,241,4,0,
719,15,244,4,0,
719,15,246,1,31,
719,15,258,4,0,
719,15,263,4,0,
719,15,267,4,0,
719,15,285,1,40,
719,15,317,4,0,
719,15,347,4,0,
719,15,360,4,0,
719,15,397,4,0,
719,15,416,4,0,
719,15,433,1,46,
719,15,433,4,0,
719,15,444,1,49,
719,15,444,4,0,
719,15,446,1,21,
719,15,470,1,27,
719,15,473,4,0,
719,15,479,1,12,
719,15,479,4,0,
719,15,496,4,0,
719,15,585,1,50,1
719,15,590,4,0,
719,15,591,1,50,2
719,15,605,4,0,
719,16,33,1,1,1
719,16,63,4,0,
719,16,88,1,5,1
719,16,92,4,0,
719,16,94,4,0,
719,16,104,4,0,
719,16,106,1,1,2
719,16,113,1,60,1
719,16,113,4,0,
719,16,115,1,18,1
719,16,115,4,0,
719,16,148,4,0,
719,16,153,4,0,
719,16,156,4,0,
719,16,157,4,0,
719,16,159,1,8,1
719,16,164,4,0,
719,16,173,3,0,
719,16,175,1,35,1
719,16,182,4,0,
719,16,201,4,0,
719,16,207,4,0,
719,16,214,4,0,
719,16,215,3,0,
719,16,216,4,0,
719,16,218,4,0,
719,16,219,1,70,1
719,16,219,4,0,
719,16,237,4,0,
719,16,241,4,0,
719,16,244,4,0,
719,16,246,1,31,1
719,16,258,4,0,
719,16,263,4,0,
719,16,267,4,0,
719,16,270,3,0,
719,16,283,3,0,
719,16,285,1,40,1
719,16,285,3,0,
719,16,290,4,0,
719,16,317,4,0,
719,16,334,3,0,
719,16,343,3,0,
719,16,347,4,0,
719,16,356,3,0,
719,16,360,4,0,
719,16,387,3,0,
719,16,393,3,0,
719,16,397,4,0,
719,16,414,3,0,
719,16,416,4,0,
719,16,433,1,46,1
719,16,433,4,0,
719,16,444,1,49,1
719,16,444,4,0,
719,16,446,1,21,1
719,16,446,3,0,
719,16,470,1,27,1
719,16,472,3,0,
719,16,473,4,0,
719,16,479,1,12,1
719,16,479,4,0,
719,16,495,3,0,
719,16,496,4,0,
719,16,585,1,50,1
719,16,590,4,0,
719,16,591,1,50,2
719,16,605,4,0,
719,17,33,1,1,1
719,17,63,4,0,
719,17,88,1,1,3
719,17,92,4,0,
719,17,94,4,0,
719,17,104,4,0,
719,17,106,1,1,2
719,17,113,1,60,
719,17,113,4,0,
719,17,115,1,12,
719,17,115,4,0,
719,17,153,4,0,
719,17,156,4,0,
719,17,157,4,0,
719,17,159,1,5,
719,17,164,4,0,
719,17,175,1,31,
719,17,182,4,0,
719,17,201,4,0,
719,17,207,4,0,
719,17,214,4,0,
719,17,216,4,0,
719,17,218,4,0,
719,17,219,1,70,
719,17,219,4,0,
719,17,237,4,0,
719,17,241,4,0,
719,17,244,4,0,
719,17,246,1,27,
719,17,258,4,0,
719,17,263,4,0,
719,17,267,4,0,
719,17,285,1,35,
719,17,317,4,0,
719,17,347,4,0,
719,17,360,4,0,
719,17,397,4,0,
719,17,408,1,40,
719,17,416,4,0,
719,17,433,1,46,
719,17,433,4,0,
719,17,444,1,49,
719,17,444,4,0,
719,17,446,1,18,
719,17,470,1,21,
719,17,473,4,0,
719,17,479,1,8,
719,17,479,4,0,
719,17,496,4,0,
719,17,585,1,50,1
719,17,590,4,0,
719,17,591,1,50,2
719,17,605,4,0,
719,18,33,1,1,1
719,18,63,4,0,
719,18,88,1,1,3
719,18,92,4,0,
719,18,94,4,0,
719,18,104,4,0,
719,18,106,1,1,2
719,18,113,1,60,
719,18,113,4,0,
719,18,115,1,12,
719,18,115,4,0,
719,18,153,4,0,
719,18,156,4,0,
719,18,157,4,0,
719,18,159,1,5,
719,18,164,4,0,
719,18,175,1,31,
719,18,182,4,0,
719,18,201,4,0,
719,18,207,4,0,
719,18,214,4,0,
719,18,216,4,0,
719,18,218,4,0,
719,18,219,1,70,
719,18,219,4,0,
719,18,237,4,0,
719,18,241,4,0,
719,18,244,4,0,
719,18,246,1,27,
719,18,258,4,0,
719,18,263,4,0,
719,18,267,4,0,
719,18,285,1,35,
719,18,317,4,0,
719,18,347,4,0,
719,18,360,4,0,
719,18,397,4,0,
719,18,408,1,40,
719,18,416,4,0,
719,18,433,1,46,
719,18,433,4,0,
719,18,444,1,49,
719,18,444,4,0,
719,18,446,1,18,
719,18,470,1,21,
719,18,473,4,0,
719,18,479,1,8,
719,18,479,4,0,
719,18,496,4,0,
719,18,585,1,50,1
719,18,590,4,0,
719,18,591,1,50,2
719,18,605,4,0,
720,15,60,1,15,
720,15,63,4,0,
720,15,85,4,0,
720,15,86,4,0,
720,15,92,4,0,
720,15,93,1,1,5
720,15,94,1,75,
720,15,94,4,0,
720,15,104,4,0,
720,15,113,1,19,
720,15,113,4,0,
720,15,115,4,0,
720,15,138,4,0,
720,15,148,4,0,
720,15,156,4,0,
720,15,164,4,0,
720,15,168,4,0,
720,15,182,4,0,
720,15,194,1,1,2
720,15,207,4,0,
720,15,214,4,0,
720,15,216,4,0,
720,15,218,4,0,
720,15,219,4,0,
720,15,237,4,0,
720,15,240,4,0,
720,15,241,4,0,
720,15,244,4,0,
720,15,247,1,68,
720,15,247,4,0,
720,15,259,4,0,
720,15,263,4,0,
720,15,269,4,0,
720,15,271,1,1,1
720,15,277,1,10,
720,15,280,4,0,
720,15,285,1,25,
720,15,310,1,6,
720,15,347,4,0,
720,15,373,4,0,
720,15,374,4,0,
720,15,411,4,0,
720,15,412,4,0,
720,15,416,4,0,
720,15,433,1,55,
720,15,433,4,0,
720,15,447,4,0,
720,15,451,4,0,
720,15,470,1,35,
720,15,471,1,29,
720,15,472,1,50,
720,15,473,4,0,
720,15,496,4,0,
720,15,502,1,1,3
720,15,511,4,0,
720,15,566,1,46,
720,15,590,4,0,
720,15,593,1,1,4
720,15,593,1,85,
720,15,612,4,0,
720,16,7,3,0,
720,16,8,3,0,
720,16,9,3,0,
720,16,60,1,19,1
720,16,63,4,0,
720,16,85,4,0,
720,16,86,4,0,
720,16,92,4,0,
720,16,93,1,1,5
720,16,94,1,75,1
720,16,94,4,0,
720,16,104,4,0,
720,16,113,1,15,1
720,16,113,4,0,
720,16,115,4,0,
720,16,138,4,0,
720,16,148,4,0,
720,16,156,4,0,
720,16,164,4,0,
720,16,168,4,0,
720,16,173,3,0,
720,16,182,4,0,
720,16,194,1,1,3
720,16,207,4,0,
720,16,214,4,0,
720,16,216,4,0,
720,16,218,4,0,
720,16,219,4,0,
720,16,237,4,0,
720,16,240,4,0,
720,16,241,4,0,
720,16,244,4,0,
720,16,247,1,55,1
720,16,247,4,0,
720,16,253,3,0,
720,16,259,4,0,
720,16,263,4,0,
720,16,264,3,0,
720,16,269,4,0,
720,16,271,1,1,2
720,16,271,3,0,
720,16,272,3,0,
720,16,277,1,10,1
720,16,277,3,0,
720,16,278,3,0,
720,16,280,4,0,
720,16,282,3,0,
720,16,285,1,25,1
720,16,285,3,0,
720,16,289,3,0,
720,16,290,4,0,
720,16,310,1,6,1
720,16,324,3,0,
720,16,335,3,0,
720,16,343,3,0,
720,16,347,4,0,
720,16,351,3,0,
720,16,356,3,0,
720,16,373,4,0,
720,16,374,4,0,
720,16,387,3,0,
720,16,399,4,0,
720,16,409,3,0,
720,16,411,4,0,
720,16,412,4,0,
720,16,416,4,0,
720,16,417,1,68,1
720,16,428,1,46,1
720,16,428,3,0,
720,16,433,1,50,2
720,16,433,4,0,
720,16,441,3,0,
720,16,447,4,0,
720,16,451,4,0,
720,16,470,1,29,2
720,16,471,1,29,1
720,16,472,1,50,1
720,16,472,3,0,
720,16,473,4,0,
720,16,478,3,0,
720,16,492,3,0,
720,16,496,4,0,
720,16,502,1,1,4
720,16,511,4,0,
720,16,530,3,0,
720,16,566,1,35,1
720,16,590,4,0,
720,16,593,1,1,1
720,16,593,1,85,1
720,16,612,4,0,
720,17,60,1,19,
720,17,63,4,0,
720,17,85,4,0,
720,17,86,4,0,
720,17,92,4,0,
720,17,93,1,1,5
720,17,94,1,75,
720,17,94,4,0,
720,17,104,4,0,
720,17,113,1,15,
720,17,113,4,0,
720,17,115,4,0,
720,17,138,4,0,
720,17,156,4,0,
720,17,164,4,0,
720,17,168,4,0,
720,17,182,4,0,
720,17,194,1,1,3
720,17,207,4,0,
720,17,214,4,0,
720,17,216,4,0,
720,17,218,4,0,
720,17,219,4,0,
720,17,237,4,0,
720,17,240,4,0,
720,17,241,4,0,
720,17,244,4,0,
720,17,247,1,55,
720,17,247,4,0,
720,17,259,4,0,
720,17,263,4,0,
720,17,269,4,0,
720,17,271,1,1,2
720,17,277,1,10,
720,17,280,4,0,
720,17,285,1,25,
720,17,310,1,6,
720,17,347,4,0,
720,17,373,4,0,
720,17,374,4,0,
720,17,399,4,0,
720,17,411,4,0,
720,17,412,4,0,
720,17,416,4,0,
720,17,417,1,68,
720,17,428,1,46,
720,17,433,1,50,2
720,17,433,4,0,
720,17,447,4,0,
720,17,451,4,0,
720,17,470,1,29,2
720,17,471,1,29,1
720,17,472,1,50,1
720,17,473,4,0,
720,17,496,4,0,
720,17,502,1,1,4
720,17,511,4,0,
720,17,566,1,35,
720,17,590,4,0,
720,17,593,1,1,1
720,17,593,1,85,
720,18,60,1,19,
720,18,63,4,0,
720,18,85,4,0,
720,18,86,4,0,
720,18,92,4,0,
720,18,93,1,1,5
720,18,94,1,75,
720,18,94,4,0,
720,18,104,4,0,
720,18,113,1,15,
720,18,113,4,0,
720,18,115,4,0,
720,18,138,4,0,
720,18,156,4,0,
720,18,164,4,0,
720,18,168,4,0,
720,18,182,4,0,
720,18,194,1,1,3
720,18,207,4,0,
720,18,214,4,0,
720,18,216,4,0,
720,18,218,4,0,
720,18,219,4,0,
720,18,237,4,0,
720,18,240,4,0,
720,18,241,4,0,
720,18,244,4,0,
720,18,247,1,55,
720,18,247,4,0,
720,18,259,4,0,
720,18,263,4,0,
720,18,269,4,0,
720,18,271,1,1,2
720,18,277,1,10,
720,18,280,4,0,
720,18,285,1,25,
720,18,310,1,6,
720,18,347,4,0,
720,18,373,4,0,
720,18,374,4,0,
720,18,399,4,0,
720,18,411,4,0,
720,18,412,4,0,
720,18,416,4,0,
720,18,417,1,68,
720,18,428,1,46,
720,18,433,1,50,2
720,18,433,4,0,
720,18,447,4,0,
720,18,451,4,0,
720,18,470,1,29,2
720,18,471,1,29,1
720,18,472,1,50,1
720,18,473,4,0,
720,18,496,4,0,
720,18,502,1,1,4
720,18,511,4,0,
720,18,566,1,35,
720,18,590,4,0,
720,18,593,1,1,1
720,18,593,1,85,
721,15,15,4,0,
721,15,23,1,28,
721,15,34,1,46,
721,15,36,1,1,3
721,15,46,4,0,
721,15,53,4,0,
721,15,54,1,8,
721,15,56,1,50,
721,15,63,4,0,
721,15,70,4,0,
721,15,76,4,0,
721,15,89,4,0,
721,15,92,4,0,
721,15,104,4,0,
721,15,114,1,11,
721,15,126,4,0,
721,15,153,1,76,
721,15,153,4,0,
721,15,156,4,0,
721,15,157,4,0,
721,15,164,4,0,
721,15,182,4,0,
721,15,188,4,0,
721,15,201,4,0,
721,15,207,4,0,
721,15,214,4,0,
721,15,216,4,0,
721,15,218,4,0,
721,15,237,4,0,
721,15,241,4,0,
721,15,249,4,0,
721,15,261,4,0,
721,15,263,4,0,
721,15,280,4,0,
721,15,311,1,40,
721,15,315,1,65,
721,15,315,4,0,
721,15,352,1,21,
721,15,360,4,0,
721,15,374,4,0,
721,15,394,1,1,2
721,15,394,1,58,
721,15,411,4,0,
721,15,416,4,0,
721,15,430,4,0,
721,15,444,4,0,
721,15,479,4,0,
721,15,482,4,0,
721,15,488,1,15,
721,15,488,4,0,
721,15,496,4,0,
721,15,503,1,32,
721,15,503,4,0,
721,15,510,4,0,
721,15,523,4,0,
721,15,590,4,0,
721,15,592,1,1,1
721,15,592,1,85,
721,16,15,4,0,
721,16,23,1,28,1
721,16,34,1,46,1
721,16,36,1,1,3
721,16,46,4,0,
721,16,53,4,0,
721,16,54,1,8,1
721,16,56,1,50,1
721,16,63,4,0,
721,16,70,4,0,
721,16,76,4,0,
721,16,89,4,0,
721,16,92,4,0,
721,16,104,4,0,
721,16,114,1,11,1
721,16,126,4,0,
721,16,153,1,76,1
721,16,153,4,0,
721,16,156,4,0,
721,16,157,4,0,
721,16,164,4,0,
721,16,173,3,0,
721,16,182,4,0,
721,16,188,4,0,
721,16,201,4,0,
721,16,207,4,0,
721,16,214,4,0,
721,16,216,4,0,
721,16,218,4,0,
721,16,237,4,0,
721,16,241,4,0,
721,16,249,4,0,
721,16,257,3,0,
721,16,261,4,0,
721,16,263,4,0,
721,16,276,3,0,
721,16,280,4,0,
721,16,290,4,0,
721,16,311,1,40,1
721,16,315,1,65,1
721,16,315,4,0,
721,16,352,1,21,1
721,16,352,3,0,
721,16,360,4,0,
721,16,374,4,0,
721,16,394,1,1,2
721,16,394,1,58,1
721,16,411,4,0,
721,16,414,3,0,
721,16,416,4,0,
721,16,430,4,0,
721,16,444,4,0,
721,16,479,4,0,
721,16,482,4,0,
721,16,488,1,15,1
721,16,488,4,0,
721,16,496,4,0,
721,16,503,1,32,1
721,16,503,4,0,
721,16,510,4,0,
721,16,523,4,0,
721,16,590,4,0,
721,16,592,1,1,1
721,16,592,1,85,1
721,17,23,1,28,
721,17,34,1,46,
721,17,36,1,1,3
721,17,46,4,0,
721,17,53,4,0,
721,17,54,1,8,
721,17,56,1,50,
721,17,63,4,0,
721,17,76,4,0,
721,17,89,4,0,
721,17,92,4,0,
721,17,104,4,0,
721,17,114,1,11,
721,17,126,4,0,
721,17,153,1,76,
721,17,153,4,0,
721,17,156,4,0,
721,17,157,4,0,
721,17,164,4,0,
721,17,182,4,0,
721,17,188,4,0,
721,17,201,4,0,
721,17,207,4,0,
721,17,214,4,0,
721,17,216,4,0,
721,17,218,4,0,
721,17,237,4,0,
721,17,241,4,0,
721,17,261,4,0,
721,17,263,4,0,
721,17,280,4,0,
721,17,311,1,40,
721,17,315,1,65,
721,17,315,4,0,
721,17,352,1,21,
721,17,360,4,0,
721,17,374,4,0,
721,17,394,1,1,2
721,17,394,1,58,
721,17,411,4,0,
721,17,416,4,0,
721,17,430,4,0,
721,17,444,4,0,
721,17,479,4,0,
721,17,482,4,0,
721,17,488,1,15,
721,17,488,4,0,
721,17,496,4,0,
721,17,503,1,32,
721,17,503,4,0,
721,17,523,4,0,
721,17,590,4,0,
721,17,592,1,1,1
721,17,592,1,85,
721,18,23,1,28,
721,18,34,1,46,
721,18,36,1,1,3
721,18,46,4,0,
721,18,53,4,0,
721,18,54,1,8,
721,18,56,1,50,
721,18,63,4,0,
721,18,76,4,0,
721,18,89,4,0,
721,18,92,4,0,
721,18,104,4,0,
721,18,114,1,11,
721,18,126,4,0,
721,18,153,1,76,
721,18,153,4,0,
721,18,156,4,0,
721,18,157,4,0,
721,18,164,4,0,
721,18,182,4,0,
721,18,188,4,0,
721,18,201,4,0,
721,18,207,4,0,
721,18,214,4,0,
721,18,216,4,0,
721,18,218,4,0,
721,18,237,4,0,
721,18,241,4,0,
721,18,261,4,0,
721,18,263,4,0,
721,18,280,4,0,
721,18,311,1,40,
721,18,315,1,65,
721,18,315,4,0,
721,18,352,1,21,
721,18,360,4,0,
721,18,374,4,0,
721,18,394,1,1,2
721,18,394,1,58,
721,18,411,4,0,
721,18,416,4,0,
721,18,430,4,0,
721,18,444,4,0,
721,18,479,4,0,
721,18,482,4,0,
721,18,488,1,15,
721,18,488,4,0,
721,18,496,4,0,
721,18,503,1,32,
721,18,503,4,0,
721,18,523,4,0,
721,18,590,4,0,
721,18,592,1,1,1
721,18,592,1,85,
722,17,14,4,0,
722,17,31,1,29,
722,17,33,1,1,1
722,17,45,1,4,
722,17,64,1,8,
722,17,75,1,15,
722,17,76,4,0,
722,17,92,4,0,
722,17,104,4,0,
722,17,109,2,0,
722,17,113,4,0,
722,17,114,2,0,
722,17,156,4,0,
722,17,164,4,0,
722,17,174,2,0,
722,17,182,4,0,
722,17,193,1,18,
722,17,206,4,0,
722,17,207,4,0,
722,17,211,4,0,
722,17,213,4,0,
722,17,214,4,0,
722,17,216,4,0,
722,17,218,4,0,
722,17,219,4,0,
722,17,226,2,0,
722,17,235,1,25,
722,17,237,4,0,
722,17,241,4,0,
722,17,263,4,0,
722,17,267,4,0,
722,17,297,1,39,
722,17,310,1,11,
722,17,348,1,36,
722,17,355,4,0,
722,17,365,1,22,
722,17,389,1,32,
722,17,412,4,0,
722,17,413,1,43,
722,17,417,1,46,
722,17,421,4,0,
722,17,432,2,0,
722,17,447,4,0,
722,17,466,2,0,
722,17,496,4,0,
722,17,497,4,0,
722,17,520,3,0,
722,17,526,4,0,
722,17,590,4,0,
722,17,670,1,1,2
722,18,14,4,0,
722,18,31,1,29,
722,18,33,1,1,1
722,18,45,1,4,
722,18,64,1,8,
722,18,75,1,14,
722,18,76,4,0,
722,18,92,4,0,
722,18,104,4,0,
722,18,109,2,0,
722,18,113,4,0,
722,18,114,2,0,
722,18,156,4,0,
722,18,164,4,0,
722,18,174,2,0,
722,18,182,4,0,
722,18,193,1,18,
722,18,206,4,0,
722,18,207,4,0,
722,18,211,4,0,
722,18,213,4,0,
722,18,214,4,0,
722,18,216,4,0,
722,18,218,4,0,
722,18,219,4,0,
722,18,226,2,0,
722,18,235,1,25,
722,18,237,4,0,
722,18,241,4,0,
722,18,263,4,0,
722,18,267,4,0,
722,18,297,1,39,
722,18,310,1,11,
722,18,348,1,36,
722,18,355,4,0,
722,18,365,1,22,
722,18,389,1,32,
722,18,412,4,0,
722,18,413,1,43,
722,18,417,1,46,
722,18,421,4,0,
722,18,432,2,0,
722,18,447,4,0,
722,18,466,1,16,
722,18,466,2,0,
722,18,496,4,0,
722,18,497,4,0,
722,18,520,3,0,
722,18,526,4,0,
722,18,590,4,0,
722,18,670,1,1,2
723,17,14,4,0,
723,17,31,1,33,
723,17,33,1,1,1
723,17,45,1,1,3
723,17,45,1,4,
723,17,64,1,1,4
723,17,64,1,8,
723,17,75,1,15,
723,17,76,4,0,
723,17,92,4,0,
723,17,104,4,0,
723,17,113,4,0,
723,17,156,4,0,
723,17,164,4,0,
723,17,182,4,0,
723,17,193,1,19,
723,17,206,4,0,
723,17,207,4,0,
723,17,211,4,0,
723,17,213,4,0,
723,17,214,4,0,
723,17,216,4,0,
723,17,218,4,0,
723,17,219,4,0,
723,17,235,1,28,
723,17,237,4,0,
723,17,241,4,0,
723,17,263,4,0,
723,17,267,4,0,
723,17,297,1,46,
723,17,310,1,11,
723,17,348,1,42,
723,17,355,4,0,
723,17,365,1,24,
723,17,389,1,37,
723,17,412,4,0,
723,17,413,1,51,
723,17,417,1,55,
723,17,421,4,0,
723,17,447,4,0,
723,17,496,4,0,
723,17,497,4,0,
723,17,520,3,0,
723,17,526,4,0,
723,17,590,4,0,
723,17,670,1,1,2
723,18,14,4,0,
723,18,31,1,33,
723,18,33,1,1,1
723,18,45,1,1,3
723,18,45,1,4,
723,18,64,1,1,4
723,18,64,1,8,
723,18,75,1,14,
723,18,76,4,0,
723,18,92,4,0,
723,18,104,4,0,
723,18,113,4,0,
723,18,156,4,0,
723,18,164,4,0,
723,18,182,4,0,
723,18,193,1,19,
723,18,206,4,0,
723,18,207,4,0,
723,18,211,4,0,
723,18,213,4,0,
723,18,214,4,0,
723,18,216,4,0,
723,18,218,4,0,
723,18,219,4,0,
723,18,235,1,28,
723,18,237,4,0,
723,18,241,4,0,
723,18,263,4,0,
723,18,267,4,0,
723,18,297,1,46,
723,18,310,1,11,
723,18,348,1,42,
723,18,355,4,0,
723,18,365,1,24,
723,18,389,1,37,
723,18,412,4,0,
723,18,413,1,51,
723,18,417,1,55,
723,18,421,4,0,
723,18,447,4,0,
723,18,466,1,16,
723,18,496,4,0,
723,18,497,4,0,
723,18,520,3,0,
723,18,526,4,0,
723,18,590,4,0,
723,18,670,1,1,2
724,17,14,4,0,
724,17,31,1,33,
724,17,33,1,1,3
724,17,45,1,1,5
724,17,45,1,4,
724,17,64,1,1,6
724,17,64,1,8,
724,17,75,1,15,
724,17,76,4,0,
724,17,92,4,0,
724,17,104,4,0,
724,17,113,4,0,
724,17,156,4,0,
724,17,164,4,0,
724,17,182,4,0,
724,17,193,1,19,
724,17,206,4,0,
724,17,207,4,0,
724,17,211,4,0,
724,17,213,4,0,
724,17,214,4,0,
724,17,216,4,0,
724,17,218,4,0,
724,17,219,4,0,
724,17,235,1,28,
724,17,237,4,0,
724,17,241,4,0,
724,17,247,4,0,
724,17,263,4,0,
724,17,267,4,0,
724,17,297,1,49,
724,17,310,1,11,
724,17,338,3,0,
724,17,348,1,44,
724,17,355,4,0,
724,17,365,1,24,
724,17,369,1,1,2
724,17,369,4,0,
724,17,389,1,38,
724,17,412,4,0,
724,17,413,1,55,
724,17,416,4,0,
724,17,417,1,60,
724,17,421,4,0,
724,17,447,4,0,
724,17,479,4,0,
724,17,490,4,0,
724,17,496,4,0,
724,17,497,4,0,
724,17,512,4,0,
724,17,520,3,0,
724,17,526,4,0,
724,17,590,4,0,
724,17,662,1,0,
724,17,662,1,1,1
724,17,670,1,1,4
724,18,14,4,0,
724,18,31,1,33,
724,18,33,1,1,6
724,18,45,1,1,8
724,18,45,1,4,
724,18,64,1,1,9
724,18,64,1,8,
724,18,75,1,14,
724,18,76,4,0,
724,18,92,4,0,
724,18,104,4,0,
724,18,113,4,0,
724,18,156,4,0,
724,18,164,4,0,
724,18,182,4,0,
724,18,193,1,19,
724,18,206,4,0,
724,18,207,4,0,
724,18,211,4,0,
724,18,213,4,0,
724,18,214,4,0,
724,18,216,4,0,
724,18,218,4,0,
724,18,219,4,0,
724,18,235,1,28,
724,18,237,4,0,
724,18,241,4,0,
724,18,247,4,0,
724,18,263,4,0,
724,18,267,4,0,
724,18,297,1,49,
724,18,310,1,11,
724,18,338,3,0,
724,18,348,1,44,
724,18,355,4,0,
724,18,365,1,24,
724,18,369,1,1,4
724,18,369,4,0,
724,18,389,1,38,
724,18,412,4,0,
724,18,413,1,55,
724,18,416,4,0,
724,18,417,1,60,
724,18,421,4,0,
724,18,425,1,1,5
724,18,437,1,1,3
724,18,447,4,0,
724,18,466,1,16,
724,18,479,4,0,
724,18,490,4,0,
724,18,496,4,0,
724,18,497,4,0,
724,18,512,4,0,
724,18,520,3,0,
724,18,526,4,0,
724,18,566,1,1,2
724,18,590,4,0,
724,18,662,1,0,
724,18,662,1,1,1
724,18,670,1,1,7
725,17,10,1,1,1
725,17,14,4,0,
725,17,34,2,0,
725,17,37,1,32,
725,17,43,1,11,
725,17,44,1,22,
725,17,45,1,4,
725,17,46,1,18,
725,17,46,4,0,
725,17,52,1,1,2
725,17,53,1,36,
725,17,53,4,0,
725,17,92,4,0,
725,17,104,4,0,
725,17,122,1,8,
725,17,126,4,0,
725,17,141,4,0,
725,17,154,1,29,
725,17,156,4,0,
725,17,164,4,0,
725,17,182,4,0,
725,17,184,1,39,
725,17,200,1,46,
725,17,207,1,25,
725,17,207,4,0,
725,17,213,4,0,
725,17,214,4,0,
725,17,216,4,0,
725,17,218,4,0,
725,17,237,4,0,
725,17,241,4,0,
725,17,242,2,0,
725,17,252,2,0,
725,17,257,2,0,
725,17,259,4,0,
725,17,261,4,0,
725,17,263,4,0,
725,17,269,4,0,
725,17,279,2,0,
725,17,315,4,0,
725,17,339,4,0,
725,17,369,4,0,
725,17,394,1,43,
725,17,417,2,0,
725,17,421,4,0,
725,17,424,1,15,
725,17,488,4,0,
725,17,496,4,0,
725,17,512,4,0,
725,17,519,3,0,
725,17,526,4,0,
725,17,590,4,0,
725,18,10,1,1,1
725,18,14,4,0,
725,18,24,1,16,
725,18,34,2,0,
725,18,37,1,32,
725,18,43,1,11,
725,18,44,1,22,
725,18,45,1,4,
725,18,46,1,18,
725,18,46,4,0,
725,18,52,1,1,2
725,18,53,1,36,
725,18,53,4,0,
725,18,92,4,0,
725,18,104,4,0,
725,18,122,1,8,
725,18,126,4,0,
725,18,141,4,0,
725,18,154,1,29,
725,18,156,4,0,
725,18,164,4,0,
725,18,182,4,0,
725,18,184,1,39,
725,18,200,1,46,
725,18,207,1,25,
725,18,207,4,0,
725,18,213,4,0,
725,18,214,4,0,
725,18,216,4,0,
725,18,218,4,0,
725,18,237,4,0,
725,18,241,4,0,
725,18,242,2,0,
725,18,252,2,0,
725,18,257,2,0,
725,18,259,4,0,
725,18,261,4,0,
725,18,263,4,0,
725,18,269,4,0,
725,18,279,2,0,
725,18,315,4,0,
725,18,339,4,0,
725,18,369,4,0,
725,18,394,1,43,
725,18,417,2,0,
725,18,421,4,0,
725,18,424,1,14,
725,18,488,4,0,
725,18,496,4,0,
725,18,512,4,0,
725,18,519,3,0,
725,18,526,4,0,
725,18,590,4,0,
725,18,681,2,0,
726,17,10,1,1,1
726,17,14,4,0,
726,17,37,1,37,
726,17,43,1,11,
726,17,44,1,24,
726,17,45,1,1,3
726,17,45,1,4,
726,17,46,1,19,
726,17,46,4,0,
726,17,52,1,1,2
726,17,53,1,42,
726,17,53,4,0,
726,17,92,4,0,
726,17,104,4,0,
726,17,122,1,1,4
726,17,122,1,8,
726,17,126,4,0,
726,17,141,4,0,
726,17,154,1,33,
726,17,156,4,0,
726,17,164,4,0,
726,17,182,4,0,
726,17,184,1,46,
726,17,200,1,55,
726,17,207,1,28,
726,17,207,4,0,
726,17,213,4,0,
726,17,214,4,0,
726,17,216,4,0,
726,17,218,4,0,
726,17,237,4,0,
726,17,241,4,0,
726,17,259,4,0,
726,17,261,4,0,
726,17,263,4,0,
726,17,269,4,0,
726,17,315,4,0,
726,17,339,4,0,
726,17,369,4,0,
726,17,394,1,51,
726,17,421,4,0,
726,17,424,1,15,
726,17,488,4,0,
726,17,496,4,0,
726,17,512,4,0,
726,17,519,3,0,
726,17,526,4,0,
726,17,590,4,0,
726,18,10,1,1,1
726,18,14,4,0,
726,18,24,1,16,
726,18,37,1,37,
726,18,43,1,11,
726,18,44,1,24,
726,18,45,1,1,3
726,18,45,1,4,
726,18,46,1,19,
726,18,46,4,0,
726,18,52,1,1,2
726,18,53,1,42,
726,18,53,4,0,
726,18,92,4,0,
726,18,104,4,0,
726,18,122,1,1,4
726,18,122,1,8,
726,18,126,4,0,
726,18,141,4,0,
726,18,154,1,33,
726,18,156,4,0,
726,18,164,4,0,
726,18,182,4,0,
726,18,184,1,46,
726,18,200,1,55,
726,18,207,1,28,
726,18,207,4,0,
726,18,213,4,0,
726,18,214,4,0,
726,18,216,4,0,
726,18,218,4,0,
726,18,237,4,0,
726,18,241,4,0,
726,18,259,4,0,
726,18,261,4,0,
726,18,263,4,0,
726,18,269,4,0,
726,18,315,4,0,
726,18,339,4,0,
726,18,369,4,0,
726,18,394,1,51,
726,18,421,4,0,
726,18,424,1,14,
726,18,488,4,0,
726,18,496,4,0,
726,18,512,4,0,
726,18,519,3,0,
726,18,526,4,0,
726,18,590,4,0,
727,17,10,1,1,4
727,17,14,4,0,
727,17,37,1,38,
727,17,43,1,11,
727,17,44,1,24,
727,17,45,1,1,6
727,17,45,1,4,
727,17,46,1,19,
727,17,46,4,0,
727,17,52,1,1,5
727,17,53,1,44,
727,17,53,4,0,
727,17,63,4,0,
727,17,89,4,0,
727,17,92,4,0,
727,17,104,4,0,
727,17,122,1,1,7
727,17,122,1,8,
727,17,126,4,0,
727,17,141,4,0,
727,17,154,1,33,
727,17,156,4,0,
727,17,164,4,0,
727,17,182,4,0,
727,17,184,1,49,
727,17,200,1,60,
727,17,207,1,28,
727,17,207,4,0,
727,17,213,4,0,
727,17,214,4,0,
727,17,216,4,0,
727,17,218,4,0,
727,17,237,4,0,
727,17,238,1,66,
727,17,241,4,0,
727,17,259,4,0,
727,17,261,4,0,
727,17,263,4,0,
727,17,269,4,0,
727,17,280,4,0,
727,17,307,3,0,
727,17,315,4,0,
727,17,339,1,1,2
727,17,339,4,0,
727,17,369,4,0,
727,17,373,4,0,
727,17,374,4,0,
727,17,394,1,55,
727,17,399,4,0,
727,17,411,4,0,
727,17,416,4,0,
727,17,421,4,0,
727,17,424,1,15,
727,17,488,4,0,
727,17,490,4,0,
727,17,496,4,0,
727,17,511,4,0,
727,17,512,4,0,
727,17,519,3,0,
727,17,523,4,0,
727,17,526,4,0,
727,17,555,4,0,
727,17,590,4,0,
727,17,663,1,0,
727,17,663,1,1,1
727,17,675,1,1,3
727,17,693,4,0,
727,18,10,1,1,4
727,18,14,4,0,
727,18,24,1,16,
727,18,37,1,38,
727,18,43,1,11,
727,18,44,1,24,
727,18,45,1,1,6
727,18,45,1,4,
727,18,46,1,19,
727,18,46,4,0,
727,18,52,1,1,5
727,18,53,1,44,
727,18,53,4,0,
727,18,63,4,0,
727,18,89,4,0,
727,18,92,4,0,
727,18,104,4,0,
727,18,122,1,1,7
727,18,122,1,8,
727,18,126,4,0,
727,18,141,4,0,
727,18,154,1,33,
727,18,156,4,0,
727,18,164,4,0,
727,18,182,4,0,
727,18,184,1,49,
727,18,200,1,60,
727,18,207,1,28,
727,18,207,4,0,
727,18,213,4,0,
727,18,214,4,0,
727,18,216,4,0,
727,18,218,4,0,
727,18,237,4,0,
727,18,238,1,66,
727,18,241,4,0,
727,18,259,4,0,
727,18,261,4,0,
727,18,263,4,0,
727,18,269,4,0,
727,18,280,4,0,
727,18,307,3,0,
727,18,315,4,0,
727,18,339,1,1,2
727,18,339,4,0,
727,18,369,4,0,
727,18,373,4,0,
727,18,374,4,0,
727,18,394,1,55,
727,18,399,4,0,
727,18,411,4,0,
727,18,416,4,0,
727,18,421,4,0,
727,18,424,1,14,
727,18,488,4,0,
727,18,490,4,0,
727,18,496,4,0,
727,18,511,4,0,
727,18,512,4,0,
727,18,519,3,0,
727,18,523,4,0,
727,18,526,4,0,
727,18,555,4,0,
727,18,590,4,0,
727,18,663,1,0,
727,18,663,1,1,1
727,18,675,1,1,3
727,18,693,4,0,
728,17,1,1,1,1
728,17,3,1,29,
728,17,45,1,4,
728,17,47,1,25,
728,17,55,1,1,2
728,17,56,1,43,
728,17,57,4,0,
728,17,58,4,0,
728,17,59,4,0,
728,17,61,1,22,
728,17,92,4,0,
728,17,104,4,0,
728,17,127,4,0,
728,17,133,2,0,
728,17,156,4,0,
728,17,164,4,0,
728,17,182,4,0,
728,17,195,2,0,
728,17,204,2,0,
728,17,207,4,0,
728,17,213,4,0,
728,17,214,4,0,
728,17,216,4,0,
728,17,218,4,0,
728,17,227,1,18,
728,17,237,4,0,
728,17,240,4,0,
728,17,258,4,0,
728,17,263,4,0,
728,17,304,1,32,
728,17,392,2,0,
728,17,445,1,39,
728,17,453,1,15,
728,17,472,2,0,
728,17,496,4,0,
728,17,497,4,0,
728,17,503,4,0,
728,17,512,4,0,
728,17,518,3,0,
728,17,526,4,0,
728,17,574,1,8,
728,17,581,1,46,
728,17,585,1,36,
728,17,590,4,0,
728,17,597,2,0,
728,17,608,1,11,
728,18,1,1,1,1
728,18,3,1,29,
728,18,45,1,4,
728,18,47,1,25,
728,18,55,1,1,2
728,18,56,1,43,
728,18,57,4,0,
728,18,58,4,0,
728,18,59,4,0,
728,18,61,1,22,
728,18,92,4,0,
728,18,104,4,0,
728,18,127,4,0,
728,18,133,2,0,
728,18,156,4,0,
728,18,164,4,0,
728,18,182,4,0,
728,18,195,2,0,
728,18,196,1,16,
728,18,204,2,0,
728,18,207,4,0,
728,18,213,4,0,
728,18,214,4,0,
728,18,216,4,0,
728,18,218,4,0,
728,18,227,1,18,
728,18,237,4,0,
728,18,240,4,0,
728,18,258,4,0,
728,18,263,4,0,
728,18,304,1,32,
728,18,392,2,0,
728,18,445,1,39,
728,18,453,1,14,
728,18,472,2,0,
728,18,496,4,0,
728,18,497,4,0,
728,18,503,4,0,
728,18,512,4,0,
728,18,518,3,0,
728,18,526,4,0,
728,18,574,1,8,
728,18,581,1,46,
728,18,585,1,36,
728,18,590,4,0,
728,18,597,2,0,
728,18,608,1,11,
729,17,1,1,1,1
729,17,3,1,33,
729,17,45,1,1,3
729,17,45,1,4,
729,17,47,1,28,
729,17,55,1,1,2
729,17,56,1,51,
729,17,57,4,0,
729,17,58,4,0,
729,17,59,4,0,
729,17,61,1,24,
729,17,92,4,0,
729,17,104,4,0,
729,17,127,4,0,
729,17,156,4,0,
729,17,164,4,0,
729,17,182,4,0,
729,17,207,4,0,
729,17,213,4,0,
729,17,214,4,0,
729,17,216,4,0,
729,17,218,4,0,
729,17,227,1,19,
729,17,237,4,0,
729,17,240,4,0,
729,17,258,4,0,
729,17,263,4,0,
729,17,304,1,37,
729,17,445,1,46,
729,17,453,1,15,
729,17,496,4,0,
729,17,497,4,0,
729,17,503,4,0,
729,17,512,4,0,
729,17,518,3,0,
729,17,526,4,0,
729,17,574,1,1,4
729,17,574,1,8,
729,17,581,1,55,
729,17,585,1,42,
729,17,590,4,0,
729,17,608,1,11,
729,18,1,1,1,1
729,18,3,1,33,
729,18,45,1,1,3
729,18,45,1,4,
729,18,47,1,28,
729,18,55,1,1,2
729,18,56,1,51,
729,18,57,4,0,
729,18,58,4,0,
729,18,59,4,0,
729,18,61,1,24,
729,18,92,4,0,
729,18,104,4,0,
729,18,127,4,0,
729,18,156,4,0,
729,18,164,4,0,
729,18,182,4,0,
729,18,196,1,16,
729,18,207,4,0,
729,18,213,4,0,
729,18,214,4,0,
729,18,216,4,0,
729,18,218,4,0,
729,18,227,1,19,
729,18,237,4,0,
729,18,240,4,0,
729,18,258,4,0,
729,18,263,4,0,
729,18,304,1,37,
729,18,445,1,46,
729,18,453,1,14,
729,18,496,4,0,
729,18,497,4,0,
729,18,503,4,0,
729,18,512,4,0,
729,18,518,3,0,
729,18,526,4,0,
729,18,574,1,1,4
729,18,574,1,8,
729,18,581,1,55,
729,18,585,1,42,
729,18,590,4,0,
729,18,608,1,11,
730,17,1,1,1,2
730,17,3,1,33,
730,17,45,1,1,4
730,17,45,1,4,
730,17,47,1,28,
730,17,55,1,1,3
730,17,56,1,55,
730,17,57,4,0,
730,17,58,4,0,
730,17,59,4,0,
730,17,61,1,24,
730,17,92,4,0,
730,17,94,4,0,
730,17,104,4,0,
730,17,113,4,0,
730,17,115,4,0,
730,17,127,4,0,
730,17,156,4,0,
730,17,164,4,0,
730,17,182,4,0,
730,17,207,4,0,
730,17,213,4,0,
730,17,214,4,0,
730,17,216,4,0,
730,17,218,4,0,
730,17,227,1,19,
730,17,237,4,0,
730,17,240,4,0,
730,17,244,4,0,
730,17,247,4,0,
730,17,258,4,0,
730,17,263,4,0,
730,17,304,1,38,
730,17,308,3,0,
730,17,412,4,0,
730,17,416,4,0,
730,17,445,1,49,
730,17,453,1,15,
730,17,496,4,0,
730,17,497,4,0,
730,17,503,4,0,
730,17,512,4,0,
730,17,518,3,0,
730,17,526,4,0,
730,17,574,1,1,5
730,17,574,1,9,
730,17,581,1,60,
730,17,585,1,44,
730,17,590,4,0,
730,17,605,4,0,
730,17,608,1,11,
730,17,664,1,0,
730,17,664,1,1,1
730,18,1,1,1,2
730,18,3,1,33,
730,18,45,1,1,4
730,18,45,1,4,
730,18,47,1,28,
730,18,55,1,1,3
730,18,56,1,55,
730,18,57,4,0,
730,18,58,4,0,
730,18,59,4,0,
730,18,61,1,24,
730,18,92,4,0,
730,18,94,4,0,
730,18,104,4,0,
730,18,113,4,0,
730,18,115,4,0,
730,18,127,4,0,
730,18,156,4,0,
730,18,164,4,0,
730,18,182,4,0,
730,18,196,1,16,
730,18,207,4,0,
730,18,213,4,0,
730,18,214,4,0,
730,18,216,4,0,
730,18,218,4,0,
730,18,227,1,19,
730,18,237,4,0,
730,18,240,4,0,
730,18,244,4,0,
730,18,247,4,0,
730,18,258,4,0,
730,18,263,4,0,
730,18,304,1,38,
730,18,308,3,0,
730,18,412,4,0,
730,18,416,4,0,
730,18,445,1,49,
730,18,453,1,14,
730,18,496,4,0,
730,18,497,4,0,
730,18,503,4,0,
730,18,512,4,0,
730,18,518,3,0,
730,18,526,4,0,
730,18,574,1,1,5
730,18,574,1,9,
730,18,581,1,60,
730,18,585,1,44,
730,18,590,4,0,
730,18,605,4,0,
730,18,608,1,11,
730,18,664,1,0,
730,18,664,1,1,1
731,17,14,4,0,
731,17,19,4,0,
731,17,31,1,21,
731,17,45,1,3,
731,17,48,1,13,
731,17,64,1,1,
731,17,65,1,27,
731,17,92,4,0,
731,17,103,1,25,
731,17,104,4,0,
731,17,119,2,0,
731,17,156,4,0,
731,17,164,4,0,
731,17,168,4,0,
731,17,182,4,0,
731,17,207,4,0,
731,17,211,4,0,
731,17,213,4,0,
731,17,214,4,0,
731,17,216,4,0,
731,17,218,4,0,
731,17,237,4,0,
731,17,241,4,0,
731,17,249,1,9,
731,17,253,2,0,
731,17,280,4,0,
731,17,297,1,33,
731,17,304,1,37,
731,17,331,1,31,
731,17,332,4,0,
731,17,355,1,19,
731,17,355,4,0,
731,17,365,1,15,
731,17,366,2,0,
731,17,369,4,0,
731,17,413,2,0,
731,17,479,4,0,
731,17,488,4,0,
731,17,496,4,0,
731,17,497,1,7,
731,17,497,4,0,
731,17,526,4,0,
731,17,586,2,0,
731,17,590,4,0,
731,18,14,4,0,
731,18,19,4,0,
731,18,31,1,21,
731,18,45,1,3,
731,18,48,1,13,
731,18,64,1,1,
731,18,65,1,27,
731,18,92,4,0,
731,18,103,1,25,
731,18,104,4,0,
731,18,119,2,0,
731,18,156,4,0,
731,18,164,4,0,
731,18,168,4,0,
731,18,182,4,0,
731,18,207,4,0,
731,18,211,4,0,
731,18,213,4,0,
731,18,214,4,0,
731,18,216,4,0,
731,18,218,4,0,
731,18,237,4,0,
731,18,241,4,0,
731,18,249,1,9,
731,18,253,2,0,
731,18,280,4,0,
731,18,297,1,33,
731,18,304,1,37,
731,18,331,1,31,
731,18,332,4,0,
731,18,355,1,19,
731,18,355,4,0,
731,18,365,1,15,
731,18,366,2,0,
731,18,369,4,0,
731,18,413,2,0,
731,18,479,4,0,
731,18,488,4,0,
731,18,496,4,0,
731,18,497,1,7,
731,18,497,4,0,
731,18,526,4,0,
731,18,586,2,0,
731,18,590,4,0,
732,17,14,4,0,
732,17,19,4,0,
732,17,31,1,24,
732,17,45,1,1,3
732,17,45,1,3,
732,17,48,1,13,
732,17,64,1,1,2
732,17,65,1,32,
732,17,92,4,0,
732,17,103,1,29,
732,17,104,4,0,
732,17,156,4,0,
732,17,164,4,0,
732,17,168,4,0,
732,17,182,4,0,
732,17,207,4,0,
732,17,211,4,0,
732,17,213,4,0,
732,17,214,4,0,
732,17,216,4,0,
732,17,218,4,0,
732,17,237,4,0,
732,17,241,4,0,
732,17,249,1,1,5
732,17,249,1,9,
732,17,280,4,0,
732,17,297,1,40,
732,17,304,1,45,
732,17,331,1,37,
732,17,332,4,0,
732,17,350,1,1,1
732,17,355,1,21,
732,17,355,4,0,
732,17,365,1,16,
732,17,369,4,0,
732,17,479,4,0,
732,17,488,4,0,
732,17,496,4,0,
732,17,497,1,1,4
732,17,497,1,7,
732,17,497,4,0,
732,17,526,4,0,
732,17,590,4,0,
732,18,14,4,0,
732,18,19,4,0,
732,18,31,1,24,
732,18,45,1,1,3
732,18,45,1,3,
732,18,48,1,13,
732,18,64,1,1,2
732,18,65,1,32,
732,18,92,4,0,
732,18,103,1,29,
732,18,104,4,0,
732,18,156,4,0,
732,18,164,4,0,
732,18,168,4,0,
732,18,182,4,0,
732,18,207,4,0,
732,18,211,4,0,
732,18,213,4,0,
732,18,214,4,0,
732,18,216,4,0,
732,18,218,4,0,
732,18,237,4,0,
732,18,241,4,0,
732,18,249,1,1,5
732,18,249,1,9,
732,18,280,4,0,
732,18,297,1,40,
732,18,304,1,45,
732,18,331,1,37,
732,18,332,4,0,
732,18,350,1,1,1
732,18,355,1,21,
732,18,355,4,0,
732,18,365,1,16,
732,18,369,4,0,
732,18,479,4,0,
732,18,488,4,0,
732,18,496,4,0,
732,18,497,1,1,4
732,18,497,1,7,
732,18,497,4,0,
732,18,526,4,0,
732,18,590,4,0,
733,17,14,4,0,
733,17,19,4,0,
733,17,31,1,24,
733,17,45,1,1,4
733,17,45,1,3,
733,17,48,1,13,
733,17,64,1,1,3
733,17,65,1,34,
733,17,92,4,0,
733,17,103,1,30,
733,17,104,4,0,
733,17,156,4,0,
733,17,164,4,0,
733,17,168,4,0,
733,17,182,4,0,
733,17,207,4,0,
733,17,211,4,0,
733,17,213,4,0,
733,17,214,4,0,
733,17,216,4,0,
733,17,218,4,0,
733,17,237,4,0,
733,17,241,4,0,
733,17,249,1,1,6
733,17,249,1,9,
733,17,280,4,0,
733,17,297,1,44,
733,17,304,1,50,
733,17,315,4,0,
733,17,331,1,40,
733,17,332,4,0,
733,17,350,1,1,2
733,17,355,1,21,
733,17,355,4,0,
733,17,365,1,16,
733,17,369,4,0,
733,17,430,4,0,
733,17,479,4,0,
733,17,488,4,0,
733,17,496,4,0,
733,17,497,1,1,5
733,17,497,1,7,
733,17,497,4,0,
733,17,526,4,0,
733,17,590,4,0,
733,17,690,1,0,
733,17,690,1,1,1
733,18,14,4,0,
733,18,19,4,0,
733,18,31,1,24,
733,18,45,1,1,4
733,18,45,1,3,
733,18,48,1,13,
733,18,64,1,1,3
733,18,65,1,34,
733,18,92,4,0,
733,18,103,1,30,
733,18,104,4,0,
733,18,156,4,0,
733,18,164,4,0,
733,18,168,4,0,
733,18,182,4,0,
733,18,207,4,0,
733,18,211,4,0,
733,18,213,4,0,
733,18,214,4,0,
733,18,216,4,0,
733,18,218,4,0,
733,18,237,4,0,
733,18,241,4,0,
733,18,249,1,1,6
733,18,249,1,9,
733,18,280,4,0,
733,18,297,1,44,
733,18,304,1,50,
733,18,315,4,0,
733,18,331,1,40,
733,18,332,4,0,
733,18,350,1,1,2
733,18,355,1,21,
733,18,355,4,0,
733,18,365,1,16,
733,18,369,4,0,
733,18,430,4,0,
733,18,479,4,0,
733,18,488,4,0,
733,18,496,4,0,
733,18,497,1,1,5
733,18,497,1,7,
733,18,497,4,0,
733,18,526,4,0,
733,18,590,4,0,
733,18,690,1,0,
733,18,690,1,1,1
734,17,28,1,10,
734,17,33,1,1,
734,17,36,1,28,
734,17,37,1,43,
734,17,43,1,3,
734,17,44,1,19,
734,17,89,4,0,
734,17,92,4,0,
734,17,104,4,0,
734,17,117,1,16,
734,17,156,1,46,
734,17,156,4,0,
734,17,158,1,37,
734,17,162,1,25,
734,17,164,4,0,
734,17,168,4,0,
734,17,182,4,0,
734,17,184,1,31,
734,17,189,1,22,
734,17,201,4,0,
734,17,207,4,0,
734,17,213,4,0,
734,17,214,4,0,
734,17,216,4,0,
734,17,218,4,0,
734,17,228,1,7,
734,17,237,4,0,
734,17,242,1,34,
734,17,259,4,0,
734,17,263,4,0,
734,17,269,4,0,
734,17,279,2,0,
734,17,281,1,40,
734,17,316,1,13,
734,17,317,4,0,
734,17,369,4,0,
734,17,371,4,0,
734,17,387,2,0,
734,17,496,4,0,
734,17,497,4,0,
734,17,526,4,0,
734,17,590,4,0,
734,18,28,1,10,
734,18,33,1,1,
734,18,36,1,28,
734,18,37,1,43,
734,18,43,1,3,
734,18,44,1,19,
734,18,89,4,0,
734,18,92,4,0,
734,18,104,4,0,
734,18,117,1,16,
734,18,156,1,46,
734,18,156,4,0,
734,18,158,1,37,
734,18,162,1,25,
734,18,164,4,0,
734,18,168,4,0,
734,18,182,4,0,
734,18,184,1,31,
734,18,189,1,22,
734,18,201,4,0,
734,18,207,4,0,
734,18,213,4,0,
734,18,214,4,0,
734,18,216,4,0,
734,18,218,4,0,
734,18,228,1,7,
734,18,237,4,0,
734,18,242,1,34,
734,18,259,4,0,
734,18,263,4,0,
734,18,269,4,0,
734,18,279,2,0,
734,18,281,1,40,
734,18,316,1,13,
734,18,317,4,0,
734,18,369,4,0,
734,18,371,4,0,
734,18,387,2,0,
734,18,422,2,0,
734,18,423,2,0,
734,18,424,2,0,
734,18,496,4,0,
734,18,497,4,0,
734,18,526,4,0,
734,18,590,4,0,
735,17,28,1,1,4
735,17,28,1,10,
735,17,33,1,1,1
735,17,36,1,31,
735,17,37,1,51,
735,17,43,1,1,2
735,17,43,1,3,
735,17,44,1,19,
735,17,46,4,0,
735,17,89,4,0,
735,17,92,4,0,
735,17,104,4,0,
735,17,117,1,16,
735,17,156,1,55,
735,17,156,4,0,
735,17,158,1,43,
735,17,162,1,27,
735,17,164,4,0,
735,17,168,4,0,
735,17,182,4,0,
735,17,184,1,35,
735,17,189,1,23,
735,17,201,4,0,
735,17,207,4,0,
735,17,213,4,0,
735,17,214,4,0,
735,17,216,4,0,
735,17,218,4,0,
735,17,228,1,1,3
735,17,228,1,7,
735,17,237,4,0,
735,17,242,1,39,
735,17,259,4,0,
735,17,263,4,0,
735,17,269,4,0,
735,17,281,1,47,
735,17,316,1,13,
735,17,317,4,0,
735,17,369,4,0,
735,17,371,4,0,
735,17,374,4,0,
735,17,496,4,0,
735,17,497,4,0,
735,17,523,4,0,
735,17,526,4,0,
735,17,590,4,0,
735,18,28,1,1,4
735,18,28,1,10,
735,18,33,1,1,1
735,18,36,1,31,
735,18,37,1,51,
735,18,43,1,1,2
735,18,43,1,3,
735,18,44,1,19,
735,18,46,4,0,
735,18,89,4,0,
735,18,92,4,0,
735,18,104,4,0,
735,18,117,1,16,
735,18,156,1,55,
735,18,156,4,0,
735,18,158,1,43,
735,18,162,1,27,
735,18,164,4,0,
735,18,168,4,0,
735,18,182,4,0,
735,18,184,1,35,
735,18,189,1,23,
735,18,201,4,0,
735,18,207,4,0,
735,18,213,4,0,
735,18,214,4,0,
735,18,216,4,0,
735,18,218,4,0,
735,18,228,1,1,3
735,18,228,1,7,
735,18,237,4,0,
735,18,242,1,39,
735,18,259,4,0,
735,18,263,4,0,
735,18,269,4,0,
735,18,281,1,47,
735,18,316,1,13,
735,18,317,4,0,
735,18,369,4,0,
735,18,371,4,0,
735,18,374,4,0,
735,18,496,4,0,
735,18,497,4,0,
735,18,523,4,0,
735,18,526,4,0,
735,18,590,4,0,
736,17,11,1,1,
736,17,44,1,10,
736,17,81,1,4,
736,17,85,4,0,
736,17,86,4,0,
736,17,91,1,28,
736,17,92,4,0,
736,17,104,4,0,
736,17,106,2,0,
736,17,113,4,0,
736,17,156,4,0,
736,17,164,4,0,
736,17,182,4,0,
736,17,189,1,7,
736,17,203,2,0,
736,17,207,4,0,
736,17,209,1,16,
736,17,213,4,0,
736,17,214,4,0,
736,17,216,4,0,
736,17,218,4,0,
736,17,237,4,0,
736,17,240,4,0,
736,17,242,1,22,
736,17,263,4,0,
736,17,341,2,0,
736,17,398,4,0,
736,17,404,1,25,
736,17,404,4,0,
736,17,450,1,13,
736,17,451,4,0,
736,17,496,4,0,
736,17,512,1,19,
736,17,512,4,0,
736,17,521,4,0,
736,17,527,2,0,
736,17,528,4,0,
736,17,590,4,0,
736,18,11,1,1,
736,18,44,1,10,
736,18,81,1,4,
736,18,85,4,0,
736,18,86,4,0,
736,18,91,1,28,
736,18,92,4,0,
736,18,104,4,0,
736,18,106,2,0,
736,18,113,4,0,
736,18,156,4,0,
736,18,164,4,0,
736,18,182,4,0,
736,18,189,1,7,
736,18,203,2,0,
736,18,207,4,0,
736,18,209,1,16,
736,18,213,4,0,
736,18,214,4,0,
736,18,216,4,0,
736,18,218,4,0,
736,18,237,4,0,
736,18,240,4,0,
736,18,242,1,22,
736,18,263,4,0,
736,18,341,2,0,
736,18,398,4,0,
736,18,404,1,25,
736,18,404,4,0,
736,18,450,1,13,
736,18,451,4,0,
736,18,496,4,0,
736,18,512,1,19,
736,18,512,4,0,
736,18,521,4,0,
736,18,527,2,0,
736,18,528,4,0,
736,18,590,4,0,
737,17,11,1,1,2
737,17,44,1,1,5
737,17,44,1,10,
737,17,81,1,1,3
737,17,81,1,4,
737,17,85,4,0,
737,17,86,4,0,
737,17,91,1,37,
737,17,92,4,0,
737,17,104,4,0,
737,17,113,4,0,
737,17,156,4,0,
737,17,164,4,0,
737,17,182,4,0,
737,17,189,1,1,4
737,17,189,1,7,
737,17,207,4,0,
737,17,209,1,16,
737,17,213,4,0,
737,17,214,4,0,
737,17,216,4,0,
737,17,218,4,0,
737,17,237,4,0,
737,17,240,4,0,
737,17,242,1,25,
737,17,263,4,0,
737,17,268,1,0,
737,17,268,1,1,1
737,17,334,1,49,
737,17,398,4,0,
737,17,404,1,31,
737,17,404,4,0,
737,17,435,1,43,
737,17,450,1,13,
737,17,451,4,0,
737,17,496,4,0,
737,17,512,1,19,
737,17,512,4,0,
737,17,521,4,0,
737,17,528,4,0,
737,17,590,4,0,
737,18,11,1,1,2
737,18,44,1,1,5
737,18,44,1,10,
737,18,81,1,1,3
737,18,81,1,4,
737,18,85,4,0,
737,18,86,4,0,
737,18,91,1,37,
737,18,92,4,0,
737,18,104,4,0,
737,18,113,4,0,
737,18,156,4,0,
737,18,164,4,0,
737,18,182,4,0,
737,18,189,1,1,4
737,18,189,1,7,
737,18,207,4,0,
737,18,209,1,16,
737,18,213,4,0,
737,18,214,4,0,
737,18,216,4,0,
737,18,218,4,0,
737,18,237,4,0,
737,18,240,4,0,
737,18,242,1,25,
737,18,263,4,0,
737,18,268,1,0,
737,18,268,1,1,1
737,18,334,1,49,
737,18,398,4,0,
737,18,404,1,31,
737,18,404,4,0,
737,18,435,1,43,
737,18,450,1,13,
737,18,451,4,0,
737,18,496,4,0,
737,18,512,1,19,
737,18,512,4,0,
737,18,521,4,0,
737,18,528,4,0,
737,18,590,4,0,
738,17,11,1,1,4
738,17,12,1,25,
738,17,44,1,1,7
738,17,44,1,10,
738,17,63,4,0,
738,17,76,4,0,
738,17,81,1,1,5
738,17,81,1,4,
738,17,85,1,0,
738,17,85,1,1,1
738,17,85,4,0,
738,17,86,4,0,
738,17,87,4,0,
738,17,91,1,37,
738,17,92,4,0,
738,17,97,1,49,
738,17,104,4,0,
738,17,113,4,0,
738,17,156,4,0,
738,17,164,4,0,
738,17,182,4,0,
738,17,189,1,1,6
738,17,189,1,7,
738,17,192,1,41,
738,17,207,4,0,
738,17,209,1,16,
738,17,213,4,0,
738,17,214,4,0,
738,17,216,4,0,
738,17,218,4,0,
738,17,237,4,0,
738,17,240,4,0,
738,17,263,4,0,
738,17,268,1,1,3
738,17,355,4,0,
738,17,398,4,0,
738,17,403,1,1,2
738,17,404,4,0,
738,17,405,1,31,
738,17,412,4,0,
738,17,416,4,0,
738,17,430,4,0,
738,17,450,1,13,
738,17,451,4,0,
738,17,496,4,0,
738,17,507,4,0,
738,17,512,1,19,
738,17,512,4,0,
738,17,521,4,0,
738,17,528,4,0,
738,17,590,4,0,
738,18,11,1,1,4
738,18,12,1,25,
738,18,44,1,1,7
738,18,44,1,10,
738,18,63,4,0,
738,18,76,4,0,
738,18,81,1,1,5
738,18,81,1,4,
738,18,85,1,0,
738,18,85,1,1,1
738,18,85,4,0,
738,18,86,4,0,
738,18,87,4,0,
738,18,91,1,37,
738,18,92,4,0,
738,18,97,1,49,
738,18,104,4,0,
738,18,113,4,0,
738,18,156,4,0,
738,18,164,4,0,
738,18,182,4,0,
738,18,189,1,1,6
738,18,189,1,7,
738,18,192,1,41,
738,18,207,4,0,
738,18,209,1,16,
738,18,213,4,0,
738,18,214,4,0,
738,18,216,4,0,
738,18,218,4,0,
738,18,237,4,0,
738,18,240,4,0,
738,18,263,4,0,
738,18,268,1,1,3
738,18,355,4,0,
738,18,398,4,0,
738,18,403,1,1,2
738,18,404,4,0,
738,18,405,1,31,
738,18,412,4,0,
738,18,416,4,0,
738,18,430,4,0,
738,18,450,1,13,
738,18,451,4,0,
738,18,496,4,0,
738,18,507,4,0,
738,18,512,1,19,
738,18,512,4,0,
738,18,521,4,0,
738,18,528,4,0,
738,18,590,4,0,
739,17,43,1,9,
739,17,61,1,17,
739,17,89,4,0,
739,17,92,4,0,
739,17,104,4,0,
739,17,133,2,0,
739,17,145,1,1,
739,17,146,1,25,
739,17,152,1,37,
739,17,156,4,0,
739,17,157,4,0,
739,17,164,4,0,
739,17,168,4,0,
739,17,179,1,33,
739,17,182,4,0,
739,17,207,4,0,
739,17,213,4,0,
739,17,214,4,0,
739,17,216,4,0,
739,17,218,4,0,
739,17,223,1,45,
739,17,228,1,13,
739,17,237,4,0,
739,17,240,4,0,
739,17,241,4,0,
739,17,249,1,5,
739,17,263,4,0,
739,17,276,2,0,
739,17,280,4,0,
739,17,283,2,0,
739,17,317,4,0,
739,17,334,1,42,
739,17,339,4,0,
739,17,370,1,49,
739,17,371,1,29,
739,17,371,4,0,
739,17,374,4,0,
739,17,411,4,0,
739,17,444,4,0,
739,17,469,2,0,
739,17,496,4,0,
739,17,503,4,0,
739,17,523,4,0,
739,17,524,4,0,
739,17,526,4,0,
739,17,590,4,0,
739,17,612,1,22,
739,17,693,4,0,
739,18,43,1,9,
739,18,61,1,17,
739,18,89,4,0,
739,18,92,4,0,
739,18,104,4,0,
739,18,133,2,0,
739,18,145,1,1,
739,18,146,1,25,
739,18,152,1,37,
739,18,156,4,0,
739,18,157,4,0,
739,18,164,4,0,
739,18,168,4,0,
739,18,179,1,33,
739,18,182,4,0,
739,18,207,4,0,
739,18,213,4,0,
739,18,214,4,0,
739,18,216,4,0,
739,18,218,4,0,
739,18,223,1,45,
739,18,228,1,13,
739,18,237,4,0,
739,18,240,4,0,
739,18,241,4,0,
739,18,249,1,5,
739,18,263,4,0,
739,18,276,2,0,
739,18,280,4,0,
739,18,283,2,0,
739,18,317,4,0,
739,18,334,1,42,
739,18,339,4,0,
739,18,370,1,49,
739,18,371,1,29,
739,18,371,4,0,
739,18,374,4,0,
739,18,411,4,0,
739,18,444,4,0,
739,18,469,2,0,
739,18,496,4,0,
739,18,503,4,0,
739,18,523,4,0,
739,18,524,4,0,
739,18,526,4,0,
739,18,590,4,0,
739,18,612,1,22,
739,18,693,4,0,
740,17,8,1,0,
740,17,8,1,1,1
740,17,43,1,1,4
740,17,43,1,9,
740,17,58,4,0,
740,17,59,4,0,
740,17,61,1,17,
740,17,89,4,0,
740,17,92,4,0,
740,17,104,4,0,
740,17,145,1,1,2
740,17,146,1,25,
740,17,156,4,0,
740,17,157,4,0,
740,17,164,4,0,
740,17,168,4,0,
740,17,179,1,33,
740,17,182,4,0,
740,17,207,4,0,
740,17,213,4,0,
740,17,214,4,0,
740,17,216,4,0,
740,17,218,4,0,
740,17,223,1,45,
740,17,228,1,1,5
740,17,228,1,13,
740,17,237,4,0,
740,17,240,4,0,
740,17,241,4,0,
740,17,249,1,1,3
740,17,249,1,5,
740,17,258,4,0,
740,17,263,4,0,
740,17,280,4,0,
740,17,317,4,0,
740,17,334,1,42,
740,17,339,4,0,
740,17,370,1,49,
740,17,371,4,0,
740,17,374,4,0,
740,17,411,4,0,
740,17,416,4,0,
740,17,419,1,29,
740,17,444,4,0,
740,17,496,4,0,
740,17,503,4,0,
740,17,523,4,0,
740,17,524,4,0,
740,17,526,4,0,
740,17,590,4,0,
740,17,612,1,22,
740,17,665,1,37,
740,17,693,4,0,
740,18,8,1,0,
740,18,8,1,1,1
740,18,43,1,1,4
740,18,43,1,9,
740,18,58,4,0,
740,18,59,4,0,
740,18,61,1,17,
740,18,89,4,0,
740,18,92,4,0,
740,18,104,4,0,
740,18,145,1,1,2
740,18,146,1,25,
740,18,156,4,0,
740,18,157,4,0,
740,18,164,4,0,
740,18,168,4,0,
740,18,179,1,33,
740,18,182,4,0,
740,18,207,4,0,
740,18,213,4,0,
740,18,214,4,0,
740,18,216,4,0,
740,18,218,4,0,
740,18,223,1,45,
740,18,228,1,1,5
740,18,228,1,13,
740,18,237,4,0,
740,18,240,4,0,
740,18,241,4,0,
740,18,249,1,1,3
740,18,249,1,5,
740,18,258,4,0,
740,18,263,4,0,
740,18,280,4,0,
740,18,317,4,0,
740,18,334,1,42,
740,18,339,4,0,
740,18,370,1,49,
740,18,371,4,0,
740,18,374,4,0,
740,18,411,4,0,
740,18,416,4,0,
740,18,419,1,29,
740,18,444,4,0,
740,18,496,4,0,
740,18,503,4,0,
740,18,523,4,0,
740,18,524,4,0,
740,18,526,4,0,
740,18,590,4,0,
740,18,612,1,22,
740,18,665,1,37,
740,18,693,4,0,
741,17,1,1,1,
741,17,3,1,23,
741,17,14,4,0,
741,17,19,4,0,
741,17,45,1,4,
741,17,64,1,6,
741,17,92,4,0,
741,17,97,1,46,
741,17,104,4,0,
741,17,119,1,43,
741,17,156,4,0,
741,17,164,4,0,
741,17,182,4,0,
741,17,201,4,0,
741,17,207,4,0,
741,17,211,4,0,
741,17,213,4,0,
741,17,214,4,0,
741,17,216,4,0,
741,17,218,4,0,
741,17,219,2,0,
741,17,219,4,0,
741,17,226,1,16,
741,17,237,4,0,
741,17,263,4,0,
741,17,269,4,0,
741,17,270,1,10,
741,17,297,1,20,
741,17,298,1,26,
741,17,314,1,13,
741,17,332,4,0,
741,17,347,4,0,
741,17,355,1,30,
741,17,355,4,0,
741,17,365,2,0,
741,17,366,2,0,
741,17,369,4,0,
741,17,373,4,0,
741,17,403,1,36,
741,17,445,1,33,
741,17,445,2,0,
741,17,496,4,0,
741,17,511,4,0,
741,17,512,4,0,
741,17,526,4,0,
741,17,542,1,50,
741,17,590,4,0,
741,17,686,1,40,
741,18,1,1,1,
741,18,3,1,23,
741,18,14,4,0,
741,18,19,4,0,
741,18,45,1,4,
741,18,64,1,6,
741,18,92,4,0,
741,18,97,1,46,
741,18,104,4,0,
741,18,119,1,43,
741,18,156,4,0,
741,18,164,4,0,
741,18,182,4,0,
741,18,201,4,0,
741,18,207,4,0,
741,18,211,4,0,
741,18,213,4,0,
741,18,214,4,0,
741,18,216,4,0,
741,18,218,4,0,
741,18,219,2,0,
741,18,219,4,0,
741,18,226,1,16,
741,18,237,4,0,
741,18,263,4,0,
741,18,269,4,0,
741,18,270,1,10,
741,18,297,1,20,
741,18,298,1,26,
741,18,314,1,13,
741,18,332,4,0,
741,18,347,4,0,
741,18,355,1,30,
741,18,355,4,0,
741,18,365,2,0,
741,18,366,2,0,
741,18,369,4,0,
741,18,373,4,0,
741,18,403,1,36,
741,18,445,1,33,
741,18,445,2,0,
741,18,496,4,0,
741,18,511,4,0,
741,18,512,4,0,
741,18,526,4,0,
741,18,542,1,50,
741,18,590,4,0,
741,18,686,1,40,
742,17,71,1,1,
742,17,78,1,7,
742,17,92,4,0,
742,17,94,4,0,
742,17,104,4,0,
742,17,113,4,0,
742,17,115,4,0,
742,17,138,4,0,
742,17,141,4,0,
742,17,156,4,0,
742,17,164,4,0,
742,17,168,4,0,
742,17,182,4,0,
742,17,207,4,0,
742,17,213,4,0,
742,17,214,4,0,
742,17,216,4,0,
742,17,218,4,0,
742,17,219,4,0,
742,17,226,2,0,
742,17,230,1,21,
742,17,237,4,0,
742,17,241,4,0,
742,17,244,4,0,
742,17,263,4,0,
742,17,285,2,0,
742,17,312,1,36,
742,17,318,1,13,
742,17,332,4,0,
742,17,347,4,0,
742,17,355,4,0,
742,17,369,4,0,
742,17,405,1,26,
742,17,412,4,0,
742,17,483,1,41,
742,17,496,4,0,
742,17,512,4,0,
742,17,516,2,0,
742,17,522,1,10,
742,17,577,1,16,
742,17,584,1,4,
742,17,585,2,0,
742,17,590,4,0,
742,17,605,1,31,
742,17,605,4,0,
742,17,611,4,0,
742,17,683,2,0,
742,18,71,1,1,
742,18,78,1,7,
742,18,92,4,0,
742,18,94,4,0,
742,18,104,4,0,
742,18,113,4,0,
742,18,115,4,0,
742,18,138,4,0,
742,18,141,4,0,
742,18,156,4,0,
742,18,164,4,0,
742,18,168,4,0,
742,18,182,4,0,
742,18,207,4,0,
742,18,213,4,0,
742,18,214,4,0,
742,18,216,4,0,
742,18,218,4,0,
742,18,219,4,0,
742,18,226,2,0,
742,18,230,1,21,
742,18,237,4,0,
742,18,241,4,0,
742,18,244,4,0,
742,18,263,4,0,
742,18,285,2,0,
742,18,312,1,36,
742,18,318,1,13,
742,18,332,4,0,
742,18,347,4,0,
742,18,355,4,0,
742,18,369,4,0,
742,18,405,1,26,
742,18,412,4,0,
742,18,483,1,41,
742,18,496,4,0,
742,18,512,4,0,
742,18,516,2,0,
742,18,522,1,10,
742,18,564,2,0,
742,18,577,1,16,
742,18,584,1,4,
742,18,585,2,0,
742,18,590,4,0,
742,18,600,2,0,
742,18,605,1,31,
742,18,605,4,0,
742,18,611,4,0,
742,18,683,2,0,
743,17,71,1,1,2
743,17,76,4,0,
743,17,78,1,1,4
743,17,78,1,7,
743,17,92,4,0,
743,17,94,4,0,
743,17,104,4,0,
743,17,113,4,0,
743,17,115,4,0,
743,17,138,4,0,
743,17,141,4,0,
743,17,156,4,0,
743,17,164,4,0,
743,17,168,4,0,
743,17,182,4,0,
743,17,207,4,0,
743,17,213,4,0,
743,17,214,4,0,
743,17,216,4,0,
743,17,218,4,0,
743,17,219,4,0,
743,17,230,1,21,
743,17,237,4,0,
743,17,241,4,0,
743,17,244,4,0,
743,17,263,4,0,
743,17,267,4,0,
743,17,312,1,42,
743,17,318,1,13,
743,17,332,4,0,
743,17,347,4,0,
743,17,355,4,0,
743,17,369,4,0,
743,17,405,1,28,
743,17,412,4,0,
743,17,483,1,49,
743,17,496,4,0,
743,17,512,4,0,
743,17,522,1,1,5
743,17,522,1,10,
743,17,577,1,16,
743,17,584,1,1,3
743,17,584,1,4,
743,17,590,4,0,
743,17,605,1,35,
743,17,605,4,0,
743,17,611,4,0,
743,17,676,1,0,
743,17,676,1,1,1
743,18,71,1,1,2
743,18,76,4,0,
743,18,78,1,1,4
743,18,78,1,7,
743,18,92,4,0,
743,18,94,4,0,
743,18,104,4,0,
743,18,113,4,0,
743,18,115,4,0,
743,18,138,4,0,
743,18,141,4,0,
743,18,156,4,0,
743,18,164,4,0,
743,18,168,4,0,
743,18,182,4,0,
743,18,207,4,0,
743,18,213,4,0,
743,18,214,4,0,
743,18,216,4,0,
743,18,218,4,0,
743,18,219,4,0,
743,18,230,1,21,
743,18,237,4,0,
743,18,241,4,0,
743,18,244,4,0,
743,18,263,4,0,
743,18,267,4,0,
743,18,312,1,42,
743,18,318,1,13,
743,18,332,4,0,
743,18,347,4,0,
743,18,355,4,0,
743,18,369,4,0,
743,18,405,1,28,
743,18,412,4,0,
743,18,483,1,49,
743,18,496,4,0,
743,18,512,4,0,
743,18,522,1,1,5
743,18,522,1,10,
743,18,577,1,16,
743,18,584,1,1,3
743,18,584,1,4,
743,18,590,4,0,
743,18,605,1,35,
743,18,605,4,0,
743,18,611,4,0,
743,18,676,1,0,
743,18,676,1,1,1
744,17,28,1,4,
744,17,33,1,1,1
744,17,37,2,0,
744,17,43,1,1,2
744,17,44,1,7,
744,17,46,1,26,
744,17,46,4,0,
744,17,88,1,15,
744,17,92,4,0,
744,17,104,4,0,
744,17,156,4,0,
744,17,157,1,34,
744,17,157,4,0,
744,17,164,4,0,
744,17,182,4,0,
744,17,184,1,37,
744,17,207,4,0,
744,17,213,4,0,
744,17,214,4,0,
744,17,216,4,0,
744,17,218,4,0,
744,17,237,4,0,
744,17,242,1,40,
744,17,263,4,0,
744,17,269,4,0,
744,17,306,2,0,
744,17,316,1,18,
744,17,317,1,23,
744,17,317,4,0,
744,17,336,1,12,
744,17,389,2,0,
744,17,397,4,0,
744,17,422,2,0,
744,17,424,2,0,
744,17,431,1,45,
744,17,444,1,48,
744,17,444,4,0,
744,17,446,1,29,
744,17,496,4,0,
744,17,497,4,0,
744,17,555,4,0,
744,17,590,4,0,
744,18,28,1,4,
744,18,33,1,1,1
744,18,37,2,0,
744,18,43,1,1,2
744,18,44,1,7,
744,18,46,1,26,
744,18,46,4,0,
744,18,88,1,15,
744,18,92,4,0,
744,18,104,4,0,
744,18,156,4,0,
744,18,157,1,34,
744,18,157,4,0,
744,18,164,4,0,
744,18,182,4,0,
744,18,184,1,37,
744,18,207,4,0,
744,18,213,4,0,
744,18,214,4,0,
744,18,216,4,0,
744,18,218,4,0,
744,18,237,4,0,
744,18,242,1,40,
744,18,263,4,0,
744,18,269,4,0,
744,18,306,2,0,
744,18,316,1,18,
744,18,317,1,23,
744,18,317,4,0,
744,18,336,1,12,
744,18,389,2,0,
744,18,397,4,0,
744,18,422,2,0,
744,18,424,2,0,
744,18,431,1,45,
744,18,444,1,48,
744,18,444,4,0,
744,18,446,1,29,
744,18,496,4,0,
744,18,497,4,0,
744,18,555,4,0,
744,18,590,4,0,
745,17,14,4,0,
745,17,28,1,1,6
745,17,28,1,4,
745,17,33,1,1,4
745,17,43,1,1,5
745,17,44,1,1,7
745,17,44,1,7,
745,17,46,1,26,
745,17,46,4,0,
745,17,88,1,15,
745,17,92,4,0,
745,17,98,1,1,3
745,17,104,4,0,
745,17,156,4,0,
745,17,157,1,34,
745,17,157,4,0,
745,17,164,4,0,
745,17,182,4,0,
745,17,184,1,37,
745,17,207,4,0,
745,17,213,4,0,
745,17,214,4,0,
745,17,216,4,0,
745,17,218,4,0,
745,17,237,4,0,
745,17,242,1,40,
745,17,263,4,0,
745,17,269,4,0,
745,17,280,4,0,
745,17,316,1,18,
745,17,317,1,23,
745,17,317,4,0,
745,17,336,1,12,
745,17,339,4,0,
745,17,397,4,0,
745,17,431,1,45,
745,17,444,1,48,
745,17,444,4,0,
745,17,446,1,29,
745,17,496,4,0,
745,17,497,4,0,
745,17,501,1,1,2
745,17,555,4,0,
745,17,590,4,0,
745,17,709,1,0,
745,17,709,1,1,1
745,18,14,4,0,
745,18,28,1,1,6
745,18,28,1,4,
745,18,33,1,1,4
745,18,43,1,1,5
745,18,44,1,1,7
745,18,44,1,7,
745,18,46,1,26,
745,18,46,4,0,
745,18,88,1,15,
745,18,92,4,0,
745,18,98,1,1,3
745,18,104,4,0,
745,18,156,4,0,
745,18,157,1,34,
745,18,157,4,0,
745,18,164,4,0,
745,18,182,4,0,
745,18,184,1,37,
745,18,207,4,0,
745,18,213,4,0,
745,18,214,4,0,
745,18,216,4,0,
745,18,218,4,0,
745,18,237,4,0,
745,18,242,1,40,
745,18,263,4,0,
745,18,269,4,0,
745,18,280,4,0,
745,18,316,1,18,
745,18,317,1,23,
745,18,317,4,0,
745,18,336,1,12,
745,18,339,4,0,
745,18,397,4,0,
745,18,431,1,45,
745,18,444,1,48,
745,18,444,4,0,
745,18,446,1,29,
745,18,496,4,0,
745,18,497,4,0,
745,18,501,1,1,2
745,18,555,4,0,
745,18,590,4,0,
745,18,709,1,0,
745,18,709,1,1,1
746,17,36,1,25,
746,17,38,1,41,
746,17,45,1,1,2
746,17,54,2,0,
746,17,55,1,1,1
746,17,56,1,54,
746,17,57,4,0,
746,17,58,4,0,
746,17,89,4,0,
746,17,92,4,0,
746,17,104,4,0,
746,17,127,4,0,
746,17,156,4,0,
746,17,164,4,0,
746,17,182,4,0,
746,17,185,1,9,
746,17,207,4,0,
746,17,213,4,0,
746,17,214,4,0,
746,17,216,4,0,
746,17,218,4,0,
746,17,237,4,0,
746,17,240,4,0,
746,17,251,1,33,
746,17,258,4,0,
746,17,263,4,0,
746,17,270,1,6,
746,17,283,1,49,
746,17,291,1,30,
746,17,330,2,0,
746,17,346,2,0,
746,17,352,2,0,
746,17,362,1,14,
746,17,369,4,0,
746,17,392,1,17,
746,17,401,1,38,
746,17,487,1,46,
746,17,496,4,0,
746,17,503,4,0,
746,17,523,4,0,
746,17,590,4,0,
746,17,715,1,22,
746,18,36,1,25,
746,18,38,1,41,
746,18,45,1,1,2
746,18,54,2,0,
746,18,55,1,1,1
746,18,56,1,54,
746,18,57,4,0,
746,18,58,4,0,
746,18,89,4,0,
746,18,92,4,0,
746,18,104,4,0,
746,18,127,4,0,
746,18,156,4,0,
746,18,164,4,0,
746,18,182,4,0,
746,18,185,1,9,
746,18,207,4,0,
746,18,213,4,0,
746,18,214,4,0,
746,18,216,4,0,
746,18,218,4,0,
746,18,237,4,0,
746,18,240,4,0,
746,18,250,2,0,
746,18,251,1,33,
746,18,258,4,0,
746,18,263,4,0,
746,18,270,1,6,
746,18,283,1,49,
746,18,291,1,30,
746,18,330,2,0,
746,18,346,2,0,
746,18,352,2,0,
746,18,362,1,14,
746,18,369,4,0,
746,18,392,1,17,
746,18,401,1,38,
746,18,487,1,46,
746,18,496,4,0,
746,18,503,4,0,
746,18,523,4,0,
746,18,590,4,0,
746,18,715,1,22,
747,17,40,1,1,
747,17,42,1,45,
747,17,44,1,9,
747,17,57,4,0,
747,17,58,4,0,
747,17,59,4,0,
747,17,64,1,5,
747,17,92,1,21,
747,17,92,4,0,
747,17,104,4,0,
747,17,105,1,33,
747,17,114,2,0,
747,17,131,1,29,
747,17,156,4,0,
747,17,164,4,0,
747,17,182,4,0,
747,17,188,4,0,
747,17,207,4,0,
747,17,213,4,0,
747,17,214,4,0,
747,17,216,4,0,
747,17,218,4,0,
747,17,219,4,0,
747,17,237,4,0,
747,17,240,4,0,
747,17,254,2,0,
747,17,255,2,0,
747,17,256,2,0,
747,17,258,4,0,
747,17,263,4,0,
747,17,371,4,0,
747,17,390,1,13,
747,17,398,1,37,
747,17,398,4,0,
747,17,469,1,17,
747,17,474,1,25,
747,17,474,4,0,
747,17,482,4,0,
747,17,496,4,0,
747,17,503,4,0,
747,17,524,4,0,
747,17,590,4,0,
747,17,599,1,41,
747,17,611,4,0,
747,17,710,1,49,
747,18,40,1,1,
747,18,42,1,45,
747,18,44,1,9,
747,18,57,4,0,
747,18,58,4,0,
747,18,59,4,0,
747,18,64,1,5,
747,18,92,1,21,
747,18,92,4,0,
747,18,104,4,0,
747,18,105,1,33,
747,18,114,2,0,
747,18,131,1,29,
747,18,156,4,0,
747,18,164,4,0,
747,18,182,4,0,
747,18,188,4,0,
747,18,207,4,0,
747,18,213,4,0,
747,18,214,4,0,
747,18,216,4,0,
747,18,218,4,0,
747,18,219,4,0,
747,18,237,4,0,
747,18,240,4,0,
747,18,254,2,0,
747,18,255,2,0,
747,18,256,2,0,
747,18,258,4,0,
747,18,263,4,0,
747,18,371,4,0,
747,18,390,1,13,
747,18,398,1,37,
747,18,398,4,0,
747,18,469,1,17,
747,18,474,1,25,
747,18,474,4,0,
747,18,482,4,0,
747,18,496,4,0,
747,18,503,4,0,
747,18,524,4,0,
747,18,590,4,0,
747,18,599,1,41,
747,18,611,4,0,
747,18,710,1,49,
748,17,40,1,1,2
748,17,42,1,51,
748,17,44,1,1,4
748,17,44,1,9,
748,17,57,4,0,
748,17,58,4,0,
748,17,59,4,0,
748,17,64,1,1,3
748,17,64,1,5,
748,17,92,1,21,
748,17,92,4,0,
748,17,104,4,0,
748,17,105,1,33,
748,17,113,4,0,
748,17,131,1,29,
748,17,156,4,0,
748,17,164,4,0,
748,17,182,4,0,
748,17,188,4,0,
748,17,207,4,0,
748,17,213,4,0,
748,17,214,4,0,
748,17,216,4,0,
748,17,218,4,0,
748,17,219,4,0,
748,17,237,4,0,
748,17,240,4,0,
748,17,258,4,0,
748,17,263,4,0,
748,17,371,4,0,
748,17,390,1,1,5
748,17,390,1,13,
748,17,398,1,37,
748,17,398,4,0,
748,17,469,1,17,
748,17,474,1,25,
748,17,474,4,0,
748,17,479,4,0,
748,17,482,4,0,
748,17,496,4,0,
748,17,503,4,0,
748,17,524,4,0,
748,17,590,4,0,
748,17,599,1,44,
748,17,611,4,0,
748,17,661,1,0,
748,17,661,1,1,1
748,17,710,1,58,
748,18,40,1,1,2
748,18,42,1,51,
748,18,44,1,1,4
748,18,44,1,9,
748,18,57,4,0,
748,18,58,4,0,
748,18,59,4,0,
748,18,64,1,1,3
748,18,64,1,5,
748,18,92,1,21,
748,18,92,4,0,
748,18,104,4,0,
748,18,105,1,33,
748,18,113,4,0,
748,18,131,1,29,
748,18,156,4,0,
748,18,164,4,0,
748,18,182,4,0,
748,18,188,4,0,
748,18,207,4,0,
748,18,213,4,0,
748,18,214,4,0,
748,18,216,4,0,
748,18,218,4,0,
748,18,219,4,0,
748,18,237,4,0,
748,18,240,4,0,
748,18,258,4,0,
748,18,263,4,0,
748,18,371,4,0,
748,18,390,1,1,5
748,18,390,1,13,
748,18,398,1,37,
748,18,398,4,0,
748,18,469,1,17,
748,18,474,1,25,
748,18,474,4,0,
748,18,479,4,0,
748,18,482,4,0,
748,18,496,4,0,
748,18,503,4,0,
748,18,524,4,0,
748,18,590,4,0,
748,18,599,1,44,
748,18,611,4,0,
748,18,661,1,0,
748,18,661,1,1,1
748,18,710,1,58,
749,17,23,1,17,
749,17,24,1,15,
749,17,25,1,43,
749,17,34,2,0,
749,17,38,2,0,
749,17,46,4,0,
749,17,68,1,36,
749,17,89,1,38,
749,17,89,4,0,
749,17,92,4,0,
749,17,104,4,0,
749,17,117,1,22,
749,17,156,4,0,
749,17,157,4,0,
749,17,164,4,0,
749,17,182,4,0,
749,17,189,1,1,
749,17,201,4,0,
749,17,207,4,0,
749,17,213,4,0,
749,17,214,4,0,
749,17,216,4,0,
749,17,218,4,0,
749,17,222,2,0,
749,17,237,4,0,
749,17,263,4,0,
749,17,276,1,45,
749,17,300,1,3,
749,17,317,4,0,
749,17,334,1,29,
749,17,370,2,0,
749,17,371,4,0,
749,17,426,2,0,
749,17,484,1,31,
749,17,490,4,0,
749,17,496,4,0,
749,17,523,1,10,
749,17,523,4,0,
749,17,563,1,8,
749,17,590,4,0,
749,17,667,1,24,
749,18,23,1,17,
749,18,24,1,15,
749,18,25,1,43,
749,18,34,2,0,
749,18,38,2,0,
749,18,46,4,0,
749,18,68,1,36,
749,18,89,1,38,
749,18,89,4,0,
749,18,92,4,0,
749,18,104,4,0,
749,18,117,1,22,
749,18,156,4,0,
749,18,157,4,0,
749,18,164,4,0,
749,18,182,4,0,
749,18,189,1,1,
749,18,201,4,0,
749,18,207,4,0,
749,18,213,4,0,
749,18,214,4,0,
749,18,216,4,0,
749,18,218,4,0,
749,18,222,2,0,
749,18,237,4,0,
749,18,263,4,0,
749,18,276,1,45,
749,18,300,1,3,
749,18,317,4,0,
749,18,334,1,29,
749,18,370,2,0,
749,18,371,4,0,
749,18,426,2,0,
749,18,484,1,31,
749,18,490,4,0,
749,18,496,4,0,
749,18,523,1,10,
749,18,523,4,0,
749,18,563,1,8,
749,18,590,4,0,
749,18,667,1,24,
750,17,23,1,17,
750,17,24,1,15,
750,17,25,1,55,
750,17,46,4,0,
750,17,68,1,42,
750,17,89,1,47,
750,17,89,4,0,
750,17,92,4,0,
750,17,104,4,0,
750,17,117,1,22,
750,17,156,4,0,
750,17,157,4,0,
750,17,164,4,0,
750,17,182,4,0,
750,17,189,1,1,1
750,17,201,4,0,
750,17,207,4,0,
750,17,213,4,0,
750,17,214,4,0,
750,17,216,4,0,
750,17,218,4,0,
750,17,237,4,0,
750,17,263,4,0,
750,17,276,1,60,
750,17,300,1,1,2
750,17,300,1,3,
750,17,317,4,0,
750,17,334,1,29,
750,17,371,4,0,
750,17,411,4,0,
750,17,416,4,0,
750,17,484,1,34,
750,17,490,4,0,
750,17,496,4,0,
750,17,523,1,1,4
750,17,523,1,10,
750,17,523,4,0,
750,17,563,1,1,3
750,17,563,1,8,
750,17,590,4,0,
750,17,667,1,24,
750,18,23,1,17,
750,18,24,1,15,
750,18,25,1,55,
750,18,46,4,0,
750,18,68,1,42,
750,18,89,1,47,
750,18,89,4,0,
750,18,92,4,0,
750,18,104,4,0,
750,18,117,1,22,
750,18,156,4,0,
750,18,157,4,0,
750,18,164,4,0,
750,18,182,4,0,
750,18,189,1,1,1
750,18,201,4,0,
750,18,207,4,0,
750,18,213,4,0,
750,18,214,4,0,
750,18,216,4,0,
750,18,218,4,0,
750,18,237,4,0,
750,18,263,4,0,
750,18,276,1,60,
750,18,300,1,1,2
750,18,300,1,3,
750,18,317,4,0,
750,18,334,1,29,
750,18,371,4,0,
750,18,411,4,0,
750,18,416,4,0,
750,18,484,1,34,
750,18,490,4,0,
750,18,496,4,0,
750,18,523,1,1,4
750,18,523,1,10,
750,18,523,4,0,
750,18,563,1,1,3
750,18,563,1,8,
750,18,590,4,0,
750,18,667,1,24,
751,17,44,1,21,
751,17,57,4,0,
751,17,58,4,0,
751,17,59,4,0,
751,17,61,1,16,
751,17,62,2,0,
751,17,92,4,0,
751,17,104,4,0,
751,17,127,4,0,
751,17,141,1,29,
751,17,141,4,0,
751,17,145,1,1,2
751,17,156,4,0,
751,17,164,4,0,
751,17,169,1,8,
751,17,182,4,0,
751,17,207,4,0,
751,17,213,4,0,
751,17,214,4,0,
751,17,216,4,0,
751,17,218,4,0,
751,17,237,4,0,
751,17,240,4,0,
751,17,242,1,32,
751,17,243,1,40,
751,17,254,2,0,
751,17,255,2,0,
751,17,263,4,0,
751,17,346,1,1,1
751,17,392,1,24,
751,17,398,4,0,
751,17,404,4,0,
751,17,450,1,13,
751,17,471,2,0,
751,17,494,1,48,
751,17,496,4,0,
751,17,503,4,0,
751,17,524,4,0,
751,17,590,4,0,
751,17,611,1,5,
751,17,611,4,0,
751,17,679,1,37,
751,17,710,1,45,
751,18,44,1,21,
751,18,57,4,0,
751,18,58,4,0,
751,18,59,4,0,
751,18,61,1,16,
751,18,62,2,0,
751,18,92,4,0,
751,18,104,4,0,
751,18,127,4,0,
751,18,141,1,29,
751,18,141,4,0,
751,18,145,1,1,2
751,18,156,4,0,
751,18,164,4,0,
751,18,169,1,8,
751,18,182,4,0,
751,18,207,4,0,
751,18,213,4,0,
751,18,214,4,0,
751,18,216,4,0,
751,18,218,4,0,
751,18,237,4,0,
751,18,240,4,0,
751,18,242,1,32,
751,18,243,1,40,
751,18,254,2,0,
751,18,255,2,0,
751,18,263,4,0,
751,18,346,1,1,1
751,18,392,1,24,
751,18,398,4,0,
751,18,404,4,0,
751,18,450,1,13,
751,18,471,2,0,
751,18,494,1,48,
751,18,496,4,0,
751,18,503,4,0,
751,18,524,4,0,
751,18,564,2,0,
751,18,590,4,0,
751,18,611,1,5,
751,18,611,4,0,
751,18,679,1,37,
751,18,710,1,45,
752,17,44,1,21,
752,17,57,4,0,
752,17,58,4,0,
752,17,59,4,0,
752,17,61,1,16,
752,17,92,4,0,
752,17,104,4,0,
752,17,115,4,0,
752,17,127,4,0,
752,17,141,1,33,
752,17,141,4,0,
752,17,145,1,1,3
752,17,156,4,0,
752,17,164,4,0,
752,17,169,1,1,5
752,17,169,1,8,
752,17,182,4,0,
752,17,207,4,0,
752,17,213,4,0,
752,17,214,4,0,
752,17,216,4,0,
752,17,218,4,0,
752,17,219,4,0,
752,17,237,4,0,
752,17,240,4,0,
752,17,242,1,38,
752,17,243,1,50,
752,17,263,4,0,
752,17,392,1,26,
752,17,398,4,0,
752,17,404,4,0,
752,17,450,1,1,6
752,17,450,1,13,
752,17,469,1,1,1
752,17,487,1,1,2
752,17,494,1,62,
752,17,496,4,0,
752,17,503,4,0,
752,17,524,4,0,
752,17,590,4,0,
752,17,611,1,1,4
752,17,611,1,5,
752,17,611,4,0,
752,17,679,1,45,
752,17,710,1,57,
752,18,44,1,21,
752,18,57,4,0,
752,18,58,4,0,
752,18,59,4,0,
752,18,61,1,16,
752,18,92,4,0,
752,18,104,4,0,
752,18,115,4,0,
752,18,127,4,0,
752,18,141,1,33,
752,18,141,4,0,
752,18,145,1,1,3
752,18,156,4,0,
752,18,164,4,0,
752,18,169,1,1,5
752,18,169,1,8,
752,18,182,4,0,
752,18,207,4,0,
752,18,213,4,0,
752,18,214,4,0,
752,18,216,4,0,
752,18,218,4,0,
752,18,219,4,0,
752,18,237,4,0,
752,18,240,4,0,
752,18,242,1,38,
752,18,243,1,50,
752,18,263,4,0,
752,18,392,1,26,
752,18,398,4,0,
752,18,404,4,0,
752,18,450,1,1,6
752,18,450,1,13,
752,18,469,1,1,1
752,18,487,1,1,2
752,18,494,1,62,
752,18,496,4,0,
752,18,503,4,0,
752,18,524,4,0,
752,18,590,4,0,
752,18,611,1,1,4
752,18,611,1,5,
752,18,611,4,0,
752,18,679,1,45,
752,18,710,1,57,
753,17,14,4,0,
753,17,74,1,14,
753,17,75,1,10,
753,17,76,1,41,
753,17,76,4,0,
753,17,92,4,0,
753,17,104,4,0,
753,17,141,4,0,
753,17,156,4,0,
753,17,163,1,32,
753,17,164,4,0,
753,17,182,4,0,
753,17,202,2,0,
753,17,206,4,0,
753,17,207,4,0,
753,17,210,1,1,
753,17,213,4,0,
753,17,214,4,0,
753,17,216,4,0,
753,17,218,4,0,
753,17,219,4,0,
753,17,230,1,37,
753,17,235,1,28,
753,17,237,4,0,
753,17,241,1,46,
753,17,241,4,0,
753,17,263,4,0,
753,17,267,4,0,
753,17,275,1,19,
753,17,311,2,0,
753,17,312,2,0,
753,17,348,1,23,
753,17,371,4,0,
753,17,374,4,0,
753,17,398,4,0,
753,17,404,4,0,
753,17,412,4,0,
753,17,432,2,0,
753,17,437,2,0,
753,17,447,4,0,
753,17,496,4,0,
753,17,590,4,0,
753,17,670,1,5,
753,18,14,4,0,
753,18,74,1,14,
753,18,75,1,10,
753,18,76,1,41,
753,18,76,4,0,
753,18,92,4,0,
753,18,104,4,0,
753,18,141,4,0,
753,18,156,4,0,
753,18,163,1,32,
753,18,164,4,0,
753,18,182,4,0,
753,18,202,2,0,
753,18,206,4,0,
753,18,207,4,0,
753,18,210,1,1,
753,18,213,4,0,
753,18,214,4,0,
753,18,216,4,0,
753,18,218,4,0,
753,18,219,4,0,
753,18,230,1,37,
753,18,235,1,28,
753,18,237,4,0,
753,18,241,1,46,
753,18,241,4,0,
753,18,263,4,0,
753,18,267,4,0,
753,18,275,1,19,
753,18,311,2,0,
753,18,312,2,0,
753,18,348,1,23,
753,18,371,4,0,
753,18,374,4,0,
753,18,398,4,0,
753,18,404,4,0,
753,18,412,4,0,
753,18,432,2,0,
753,18,437,2,0,
753,18,447,4,0,
753,18,496,4,0,
753,18,590,4,0,
753,18,670,1,5,
754,17,14,4,0,
754,17,63,4,0,
754,17,74,1,1,6
754,17,74,1,14,
754,17,75,1,1,5
754,17,75,1,10,
754,17,76,4,0,
754,17,92,4,0,
754,17,104,4,0,
754,17,141,4,0,
754,17,156,4,0,
754,17,163,1,32,
754,17,164,4,0,
754,17,182,4,0,
754,17,206,4,0,
754,17,207,4,0,
754,17,210,1,1,3
754,17,213,4,0,
754,17,214,4,0,
754,17,216,4,0,
754,17,218,4,0,
754,17,219,4,0,
754,17,230,1,40,
754,17,235,1,28,
754,17,237,4,0,
754,17,241,1,55,
754,17,241,4,0,
754,17,263,4,0,
754,17,267,4,0,
754,17,275,1,19,
754,17,280,4,0,
754,17,332,4,0,
754,17,348,1,23,
754,17,371,4,0,
754,17,374,4,0,
754,17,398,4,0,
754,17,404,1,1,2
754,17,404,4,0,
754,17,412,4,0,
754,17,416,4,0,
754,17,447,4,0,
754,17,490,4,0,
754,17,496,4,0,
754,17,572,1,0,
754,17,572,1,1,1
754,17,590,4,0,
754,17,669,1,47,
754,17,670,1,1,4
754,17,670,1,5,
754,18,14,4,0,
754,18,63,4,0,
754,18,74,1,1,7
754,18,74,1,14,
754,18,75,1,1,6
754,18,75,1,10,
754,18,76,4,0,
754,18,92,4,0,
754,18,104,4,0,
754,18,141,4,0,
754,18,156,4,0,
754,18,163,1,32,
754,18,164,4,0,
754,18,182,4,0,
754,18,206,4,0,
754,18,207,4,0,
754,18,210,1,1,4
754,18,213,4,0,
754,18,214,4,0,
754,18,216,4,0,
754,18,218,4,0,
754,18,219,4,0,
754,18,230,1,40,
754,18,235,1,28,
754,18,237,4,0,
754,18,241,1,55,
754,18,241,4,0,
754,18,263,4,0,
754,18,267,4,0,
754,18,275,1,19,
754,18,280,4,0,
754,18,332,4,0,
754,18,348,1,23,
754,18,371,4,0,
754,18,374,4,0,
754,18,398,4,0,
754,18,400,1,1,3
754,18,404,1,1,2
754,18,404,4,0,
754,18,412,4,0,
754,18,416,4,0,
754,18,447,4,0,
754,18,490,4,0,
754,18,496,4,0,
754,18,572,1,0,
754,18,572,1,1,1
754,18,590,4,0,
754,18,669,1,47,
754,18,670,1,1,5
754,18,670,1,5,
755,17,71,1,1,
755,17,72,1,15,
755,17,73,2,0,
755,17,74,2,0,
755,17,76,4,0,
755,17,77,2,0,
755,17,78,2,0,
755,17,79,1,18,
755,17,86,4,0,
755,17,92,4,0,
755,17,104,4,0,
755,17,109,1,25,
755,17,113,4,0,
755,17,133,2,0,
755,17,138,1,43,
755,17,138,4,0,
755,17,147,1,36,
755,17,148,1,8,
755,17,156,4,0,
755,17,164,4,0,
755,17,182,4,0,
755,17,188,4,0,
755,17,202,1,29,
755,17,207,4,0,
755,17,213,4,0,
755,17,214,4,0,
755,17,216,4,0,
755,17,218,4,0,
755,17,219,4,0,
755,17,236,1,11,
755,17,237,4,0,
755,17,241,4,0,
755,17,267,4,0,
755,17,275,1,22,
755,17,310,1,4,
755,17,412,4,0,
755,17,447,4,0,
755,17,496,4,0,
755,17,585,1,39,
755,17,590,4,0,
755,17,605,4,0,
755,17,668,1,32,
755,17,671,1,46,
755,18,71,1,1,
755,18,72,1,15,
755,18,73,2,0,
755,18,74,2,0,
755,18,76,4,0,
755,18,77,2,0,
755,18,78,2,0,
755,18,79,1,18,
755,18,86,4,0,
755,18,92,4,0,
755,18,104,4,0,
755,18,109,1,25,
755,18,113,4,0,
755,18,133,2,0,
755,18,138,1,43,
755,18,138,4,0,
755,18,147,1,36,
755,18,148,1,8,
755,18,156,4,0,
755,18,164,4,0,
755,18,182,4,0,
755,18,188,4,0,
755,18,202,1,29,
755,18,207,4,0,
755,18,213,4,0,
755,18,214,4,0,
755,18,216,4,0,
755,18,218,4,0,
755,18,219,4,0,
755,18,236,1,11,
755,18,237,4,0,
755,18,241,4,0,
755,18,267,4,0,
755,18,275,1,22,
755,18,310,1,4,
755,18,412,4,0,
755,18,447,4,0,
755,18,496,4,0,
755,18,585,1,39,
755,18,590,4,0,
755,18,605,4,0,
755,18,668,1,32,
755,18,671,1,46,
756,17,71,1,1,1
756,17,72,1,15,
756,17,76,4,0,
756,17,79,1,18,
756,17,86,4,0,
756,17,92,4,0,
756,17,104,4,0,
756,17,109,1,26,
756,17,113,4,0,
756,17,138,1,49,
756,17,138,4,0,
756,17,147,1,40,
756,17,148,1,1,4
756,17,148,1,8,
756,17,156,4,0,
756,17,164,4,0,
756,17,182,4,0,
756,17,188,4,0,
756,17,202,1,31,
756,17,207,4,0,
756,17,213,4,0,
756,17,214,4,0,
756,17,216,4,0,
756,17,218,4,0,
756,17,219,4,0,
756,17,236,1,11,
756,17,237,4,0,
756,17,267,4,0,
756,17,275,1,1,3
756,17,275,1,22,
756,17,310,1,1,2
756,17,310,1,4,
756,17,412,4,0,
756,17,416,4,0,
756,17,447,4,0,
756,17,451,4,0,
756,17,496,4,0,
756,17,585,1,44,
756,17,590,4,0,
756,17,605,4,0,
756,17,668,1,35,
756,17,671,1,53,
756,18,71,1,1,1
756,18,72,1,15,
756,18,76,4,0,
756,18,79,1,18,
756,18,86,4,0,
756,18,92,4,0,
756,18,104,4,0,
756,18,109,1,26,
756,18,113,4,0,
756,18,138,1,49,
756,18,138,4,0,
756,18,147,1,40,
756,18,148,1,1,4
756,18,148,1,8,
756,18,156,4,0,
756,18,164,4,0,
756,18,182,4,0,
756,18,188,4,0,
756,18,202,1,31,
756,18,207,4,0,
756,18,213,4,0,
756,18,214,4,0,
756,18,216,4,0,
756,18,218,4,0,
756,18,219,4,0,
756,18,236,1,11,
756,18,237,4,0,
756,18,241,4,0,
756,18,267,4,0,
756,18,275,1,1,3
756,18,275,1,22,
756,18,310,1,1,2
756,18,310,1,4,
756,18,412,4,0,
756,18,416,4,0,
756,18,447,4,0,
756,18,451,4,0,
756,18,496,4,0,
756,18,585,1,44,
756,18,590,4,0,
756,18,605,4,0,
756,18,668,1,35,
756,18,671,1,53,
757,17,3,1,21,
757,17,10,1,1,1
757,17,28,2,0,
757,17,52,1,5,
757,17,53,1,40,
757,17,53,4,0,
757,17,82,1,13,
757,17,92,1,29,
757,17,92,4,0,
757,17,104,4,0,
757,17,123,1,16,
757,17,126,4,0,
757,17,139,1,1,2
757,17,141,4,0,
757,17,156,4,0,
757,17,164,4,0,
757,17,168,4,0,
757,17,182,4,0,
757,17,188,4,0,
757,17,207,4,0,
757,17,213,4,0,
757,17,214,4,0,
757,17,216,4,0,
757,17,218,4,0,
757,17,230,1,8,
757,17,237,4,0,
757,17,252,2,0,
757,17,259,4,0,
757,17,261,4,0,
757,17,263,4,0,
757,17,269,4,0,
757,17,282,2,0,
757,17,289,2,0,
757,17,315,4,0,
757,17,337,4,0,
757,17,371,4,0,
757,17,374,4,0,
757,17,398,4,0,
757,17,406,1,48,
757,17,417,1,32,
757,17,421,4,0,
757,17,474,1,37,
757,17,474,4,0,
757,17,481,1,24,
757,17,482,4,0,
757,17,488,4,0,
757,17,496,4,0,
757,17,562,2,0,
757,17,590,4,0,
757,17,599,1,45,
757,18,3,1,21,
757,18,10,1,1,1
757,18,28,2,0,
757,18,52,1,5,
757,18,53,1,40,
757,18,53,4,0,
757,18,82,1,13,
757,18,92,1,29,
757,18,92,4,0,
757,18,104,4,0,
757,18,123,1,16,
757,18,126,4,0,
757,18,139,1,1,2
757,18,141,4,0,
757,18,156,4,0,
757,18,164,4,0,
757,18,168,4,0,
757,18,182,4,0,
757,18,188,4,0,
757,18,207,4,0,
757,18,213,4,0,
757,18,214,4,0,
757,18,216,4,0,
757,18,218,4,0,
757,18,230,1,8,
757,18,237,4,0,
757,18,252,2,0,
757,18,259,4,0,
757,18,261,4,0,
757,18,263,4,0,
757,18,269,4,0,
757,18,282,2,0,
757,18,289,2,0,
757,18,315,4,0,
757,18,337,4,0,
757,18,371,4,0,
757,18,374,4,0,
757,18,398,4,0,
757,18,406,1,48,
757,18,417,1,32,
757,18,421,4,0,
757,18,474,1,37,
757,18,474,4,0,
757,18,481,1,24,
757,18,482,4,0,
757,18,488,4,0,
757,18,496,4,0,
757,18,562,2,0,
757,18,590,4,0,
757,18,599,1,45,
758,17,1,1,1,6
758,17,3,1,21,
758,17,50,1,1,2
758,17,52,1,1,8
758,17,52,1,5,
758,17,53,1,44,
758,17,53,4,0,
758,17,82,1,13,
758,17,92,1,29,
758,17,92,4,0,
758,17,104,4,0,
758,17,123,1,16,
758,17,126,4,0,
758,17,139,1,1,7
758,17,141,4,0,
758,17,156,4,0,
758,17,164,4,0,
758,17,168,4,0,
758,17,182,4,0,
758,17,188,4,0,
758,17,207,1,1,5
758,17,207,4,0,
758,17,213,4,0,
758,17,214,4,0,
758,17,216,4,0,
758,17,218,4,0,
758,17,227,1,1,3
758,17,230,1,1,9
758,17,230,1,8,
758,17,237,4,0,
758,17,259,1,1,4
758,17,259,4,0,
758,17,261,4,0,
758,17,263,4,0,
758,17,269,4,0,
758,17,315,4,0,
758,17,337,4,0,
758,17,371,4,0,
758,17,374,4,0,
758,17,398,4,0,
758,17,406,1,56,
758,17,417,1,32,
758,17,421,4,0,
758,17,445,1,0,
758,17,445,1,1,1
758,17,474,1,39,
758,17,474,4,0,
758,17,481,1,24,
758,17,482,4,0,
758,17,488,4,0,
758,17,496,4,0,
758,17,512,4,0,
758,17,525,4,0,
758,17,590,4,0,
758,17,599,1,51,
758,18,1,1,1,6
758,18,3,1,21,
758,18,50,1,1,2
758,18,52,1,1,8
758,18,52,1,5,
758,18,53,1,44,
758,18,53,4,0,
758,18,82,1,13,
758,18,92,1,29,
758,18,92,4,0,
758,18,104,4,0,
758,18,123,1,16,
758,18,126,4,0,
758,18,139,1,1,7
758,18,141,4,0,
758,18,156,4,0,
758,18,164,4,0,
758,18,168,4,0,
758,18,182,4,0,
758,18,188,4,0,
758,18,207,1,1,5
758,18,207,4,0,
758,18,213,4,0,
758,18,214,4,0,
758,18,216,4,0,
758,18,218,4,0,
758,18,227,1,1,3
758,18,230,1,1,9
758,18,230,1,8,
758,18,237,4,0,
758,18,259,1,1,4
758,18,259,4,0,
758,18,261,4,0,
758,18,263,4,0,
758,18,269,4,0,
758,18,315,4,0,
758,18,337,4,0,
758,18,371,4,0,
758,18,374,4,0,
758,18,398,4,0,
758,18,406,1,56,
758,18,417,1,32,
758,18,421,4,0,
758,18,445,1,0,
758,18,445,1,1,1
758,18,474,1,39,
758,18,474,4,0,
758,18,481,1,24,
758,18,482,4,0,
758,18,488,4,0,
758,18,496,4,0,
758,18,512,4,0,
758,18,525,4,0,
758,18,590,4,0,
758,18,599,1,51,
759,17,8,2,0,
759,17,9,2,0,
759,17,14,4,0,
759,17,25,2,0,
759,17,33,1,1,1
759,17,36,1,28,
759,17,37,1,37,
759,17,38,1,46,
759,17,43,1,1,2
759,17,46,4,0,
759,17,89,4,0,
759,17,92,4,0,
759,17,104,4,0,
759,17,117,1,5,
759,17,156,4,0,
759,17,157,4,0,
759,17,164,4,0,
759,17,175,1,19,
759,17,182,4,0,
759,17,203,2,0,
759,17,207,4,0,
759,17,213,4,0,
759,17,214,4,0,
759,17,216,4,0,
759,17,218,4,0,
759,17,220,1,41,
759,17,237,4,0,
759,17,263,4,0,
759,17,269,4,0,
759,17,276,1,50,
759,17,280,4,0,
759,17,317,4,0,
759,17,332,4,0,
759,17,339,4,0,
759,17,359,1,32,
759,17,371,1,23,
759,17,371,4,0,
759,17,374,4,0,
759,17,395,2,0,
759,17,411,4,0,
759,17,469,2,0,
759,17,490,4,0,
759,17,496,4,0,
759,17,523,4,0,
759,17,526,4,0,
759,17,590,4,0,
759,17,608,1,10,
759,17,693,1,14,
759,17,693,4,0,
759,17,707,2,0,
759,18,8,2,0,
759,18,9,2,0,
759,18,14,4,0,
759,18,25,2,0,
759,18,33,1,1,1
759,18,36,1,28,
759,18,37,1,37,
759,18,38,1,46,
759,18,43,1,1,2
759,18,46,4,0,
759,18,89,4,0,
759,18,92,4,0,
759,18,104,4,0,
759,18,117,1,5,
759,18,156,4,0,
759,18,157,4,0,
759,18,164,4,0,
759,18,175,1,19,
759,18,182,4,0,
759,18,203,2,0,
759,18,207,4,0,
759,18,213,4,0,
759,18,214,4,0,
759,18,216,4,0,
759,18,218,4,0,
759,18,220,1,41,
759,18,237,4,0,
759,18,263,4,0,
759,18,269,4,0,
759,18,276,1,50,
759,18,280,4,0,
759,18,317,4,0,
759,18,332,4,0,
759,18,339,4,0,
759,18,359,1,32,
759,18,371,1,23,
759,18,371,4,0,
759,18,374,4,0,
759,18,395,2,0,
759,18,411,4,0,
759,18,469,2,0,
759,18,490,4,0,
759,18,496,4,0,
759,18,523,4,0,
759,18,526,4,0,
759,18,590,4,0,
759,18,608,1,10,
759,18,693,1,14,
759,18,693,4,0,
759,18,707,2,0,
760,17,14,4,0,
760,17,20,1,0,
760,17,33,1,1,1
760,17,36,1,30,
760,17,37,1,43,
760,17,38,1,56,
760,17,43,1,1,2
760,17,46,4,0,
760,17,63,4,0,
760,17,89,4,0,
760,17,92,4,0,
760,17,104,4,0,
760,17,117,1,5,
760,17,156,4,0,
760,17,157,4,0,
760,17,164,4,0,
760,17,175,1,19,
760,17,182,4,0,
760,17,207,4,0,
760,17,213,4,0,
760,17,214,4,0,
760,17,216,4,0,
760,17,218,4,0,
760,17,220,1,49,
760,17,237,4,0,
760,17,263,4,0,
760,17,269,4,0,
760,17,276,1,62,
760,17,280,4,0,
760,17,317,4,0,
760,17,332,4,0,
760,17,337,4,0,
760,17,339,4,0,
760,17,359,1,36,
760,17,371,1,23,
760,17,371,4,0,
760,17,374,4,0,
760,17,411,4,0,
760,17,416,4,0,
760,17,421,4,0,
760,17,490,4,0,
760,17,496,4,0,
760,17,523,4,0,
760,17,526,4,0,
760,17,590,4,0,
760,17,608,1,10,
760,17,693,1,14,
760,17,693,4,0,
760,18,14,4,0,
760,18,20,1,0,
760,18,20,1,1,1
760,18,33,1,1,2
760,18,36,1,30,
760,18,37,1,43,
760,18,38,1,56,
760,18,43,1,1,3
760,18,46,4,0,
760,18,63,4,0,
760,18,89,4,0,
760,18,92,4,0,
760,18,104,4,0,
760,18,117,1,5,
760,18,156,4,0,
760,18,157,4,0,
760,18,164,4,0,
760,18,175,1,19,
760,18,182,4,0,
760,18,207,4,0,
760,18,213,4,0,
760,18,214,4,0,
760,18,216,4,0,
760,18,218,4,0,
760,18,220,1,49,
760,18,237,4,0,
760,18,263,4,0,
760,18,269,4,0,
760,18,276,1,62,
760,18,280,4,0,
760,18,317,4,0,
760,18,332,4,0,
760,18,337,4,0,
760,18,339,4,0,
760,18,359,1,36,
760,18,371,1,23,
760,18,371,4,0,
760,18,374,4,0,
760,18,411,4,0,
760,18,416,4,0,
760,18,421,4,0,
760,18,490,4,0,
760,18,496,4,0,
760,18,523,4,0,
760,18,526,4,0,
760,18,590,4,0,
760,18,608,1,10,
760,18,693,1,14,
760,18,693,4,0,
761,17,75,1,13,
761,17,76,4,0,
761,17,92,4,0,
761,17,104,4,0,
761,17,113,4,0,
761,17,115,4,0,
761,17,150,1,1,
761,17,156,4,0,
761,17,164,4,0,
761,17,175,1,29,
761,17,182,4,0,
761,17,204,2,0,
761,17,207,4,0,
761,17,213,4,0,
761,17,214,4,0,
761,17,216,4,0,
761,17,218,4,0,
761,17,219,4,0,
761,17,229,1,9,
761,17,230,1,17,
761,17,235,2,0,
761,17,237,4,0,
761,17,241,4,0,
761,17,263,4,0,
761,17,267,4,0,
761,17,298,1,25,
761,17,320,2,0,
761,17,345,1,21,
761,17,364,2,0,
761,17,367,2,0,
761,17,412,4,0,
761,17,447,4,0,
761,17,496,4,0,
761,17,583,2,0,
761,17,589,1,5,
761,17,590,4,0,
761,17,597,1,33,
761,17,605,4,0,
761,18,75,1,13,
761,18,76,4,0,
761,18,92,4,0,
761,18,104,4,0,
761,18,113,4,0,
761,18,115,4,0,
761,18,150,1,1,
761,18,156,4,0,
761,18,164,4,0,
761,18,175,1,29,
761,18,182,4,0,
761,18,204,2,0,
761,18,207,4,0,
761,18,213,4,0,
761,18,214,4,0,
761,18,216,4,0,
761,18,218,4,0,
761,18,219,4,0,
761,18,229,1,9,
761,18,230,1,17,
761,18,235,2,0,
761,18,237,4,0,
761,18,241,4,0,
761,18,263,4,0,
761,18,267,4,0,
761,18,298,1,25,
761,18,320,2,0,
761,18,345,1,21,
761,18,364,2,0,
761,18,367,2,0,
761,18,412,4,0,
761,18,447,4,0,
761,18,496,4,0,
761,18,583,2,0,
761,18,589,1,5,
761,18,590,4,0,
761,18,597,1,33,
761,18,605,4,0,
762,17,3,1,0,
762,17,3,1,1,1
762,17,23,1,29,
762,17,75,1,1,5
762,17,75,1,13,
762,17,76,4,0,
762,17,92,4,0,
762,17,104,4,0,
762,17,113,4,0,
762,17,115,4,0,
762,17,150,1,1,2
762,17,156,4,0,
762,17,164,4,0,
762,17,182,4,0,
762,17,207,4,0,
762,17,213,4,0,
762,17,214,4,0,
762,17,216,4,0,
762,17,218,4,0,
762,17,219,4,0,
762,17,229,1,1,4
762,17,229,1,9,
762,17,230,1,17,
762,17,237,4,0,
762,17,241,4,0,
762,17,263,4,0,
762,17,267,4,0,
762,17,298,1,25,
762,17,312,1,41,
762,17,345,1,21,
762,17,371,4,0,
762,17,374,4,0,
762,17,412,4,0,
762,17,437,1,45,
762,17,445,1,37,
762,17,447,4,0,
762,17,490,4,0,
762,17,496,4,0,
762,17,589,1,1,3
762,17,589,1,5,
762,17,590,4,0,
762,17,597,1,33,
762,17,605,4,0,
762,18,3,1,0,
762,18,3,1,1,1
762,18,23,1,29,
762,18,75,1,1,5
762,18,75,1,13,
762,18,76,4,0,
762,18,92,4,0,
762,18,104,4,0,
762,18,113,4,0,
762,18,115,4,0,
762,18,150,1,1,2
762,18,156,4,0,
762,18,164,4,0,
762,18,182,4,0,
762,18,207,4,0,
762,18,213,4,0,
762,18,214,4,0,
762,18,216,4,0,
762,18,218,4,0,
762,18,219,4,0,
762,18,229,1,1,4
762,18,229,1,9,
762,18,230,1,17,
762,18,237,4,0,
762,18,241,4,0,
762,18,263,4,0,
762,18,267,4,0,
762,18,298,1,25,
762,18,312,1,41,
762,18,345,1,21,
762,18,371,4,0,
762,18,374,4,0,
762,18,412,4,0,
762,18,437,1,45,
762,18,445,1,37,
762,18,447,4,0,
762,18,490,4,0,
762,18,496,4,0,
762,18,589,1,1,3
762,18,589,1,5,
762,18,590,4,0,
762,18,597,1,33,
762,18,605,4,0,
763,17,3,1,1,2
763,17,23,1,29,
763,17,75,1,1,6
763,17,75,1,13,
763,17,76,4,0,
763,17,92,4,0,
763,17,104,4,0,
763,17,113,4,0,
763,17,115,4,0,
763,17,136,1,49,
763,17,150,1,1,3
763,17,156,4,0,
763,17,164,4,0,
763,17,182,4,0,
763,17,207,1,1,4
763,17,207,1,5,
763,17,207,4,0,
763,17,213,4,0,
763,17,214,4,0,
763,17,216,4,0,
763,17,218,4,0,
763,17,219,4,0,
763,17,229,1,1,5
763,17,229,1,9,
763,17,230,1,17,
763,17,237,4,0,
763,17,241,4,0,
763,17,263,4,0,
763,17,267,4,0,
763,17,298,1,25,
763,17,312,1,41,
763,17,345,1,21,
763,17,369,4,0,
763,17,371,4,0,
763,17,374,4,0,
763,17,412,4,0,
763,17,416,4,0,
763,17,437,1,45,
763,17,445,1,37,
763,17,447,4,0,
763,17,490,4,0,
763,17,496,4,0,
763,17,512,4,0,
763,17,590,4,0,
763,17,597,1,33,
763,17,605,4,0,
763,17,688,1,0,
763,17,688,1,1,1
763,18,3,1,1,3
763,18,23,1,29,
763,18,75,1,1,7
763,18,75,1,13,
763,18,76,4,0,
763,18,92,4,0,
763,18,104,4,0,
763,18,113,4,0,
763,18,115,4,0,
763,18,136,1,49,
763,18,150,1,1,4
763,18,156,4,0,
763,18,164,4,0,
763,18,182,4,0,
763,18,207,1,1,5
763,18,207,1,5,
763,18,207,4,0,
763,18,213,4,0,
763,18,214,4,0,
763,18,216,4,0,
763,18,218,4,0,
763,18,219,4,0,
763,18,229,1,1,6
763,18,229,1,9,
763,18,230,1,17,
763,18,237,4,0,
763,18,241,4,0,
763,18,263,4,0,
763,18,267,4,0,
763,18,298,1,25,
763,18,312,1,41,
763,18,345,1,21,
763,18,369,4,0,
763,18,371,4,0,
763,18,374,4,0,
763,18,386,1,1,2
763,18,412,4,0,
763,18,416,4,0,
763,18,437,1,45,
763,18,438,1,53,
763,18,445,1,37,
763,18,447,4,0,
763,18,490,4,0,
763,18,496,4,0,
763,18,512,4,0,
763,18,590,4,0,
763,18,597,1,33,
763,18,605,4,0,
763,18,688,1,0,
763,18,688,1,1,1
764,17,22,1,1,2
764,17,35,1,16,
764,17,63,4,0,
764,17,73,1,4,
764,17,74,1,13,
764,17,76,4,0,
764,17,80,1,40,
764,17,92,4,0,
764,17,104,4,0,
764,17,113,4,0,
764,17,133,2,0,
764,17,156,4,0,
764,17,164,4,0,
764,17,168,4,0,
764,17,182,4,0,
764,17,186,1,19,
764,17,203,2,0,
764,17,207,4,0,
764,17,213,4,0,
764,17,214,4,0,
764,17,216,4,0,
764,17,218,4,0,
764,17,219,4,0,
764,17,230,1,31,
764,17,235,1,28,
764,17,237,4,0,
764,17,241,4,0,
764,17,244,4,0,
764,17,263,4,0,
764,17,267,4,0,
764,17,269,4,0,
764,17,270,1,1,1
764,17,312,1,43,
764,17,345,1,10,
764,17,347,4,0,
764,17,363,1,22,
764,17,369,4,0,
764,17,374,4,0,
764,17,381,2,0,
764,17,412,4,0,
764,17,433,4,0,
764,17,447,1,34,
764,17,447,4,0,
764,17,495,2,0,
764,17,496,4,0,
764,17,497,4,0,
764,17,512,4,0,
764,17,572,1,25,
764,17,577,1,7,
764,17,579,1,1,3
764,17,580,1,46,
764,17,583,1,49,
764,17,590,4,0,
764,17,605,4,0,
764,17,666,1,37,
764,18,22,1,1,2
764,18,35,1,16,
764,18,63,4,0,
764,18,73,1,4,
764,18,74,1,13,
764,18,76,4,0,
764,18,80,1,40,
764,18,92,4,0,
764,18,104,4,0,
764,18,113,4,0,
764,18,133,2,0,
764,18,156,4,0,
764,18,164,4,0,
764,18,168,4,0,
764,18,182,4,0,
764,18,186,1,19,
764,18,203,2,0,
764,18,207,4,0,
764,18,213,4,0,
764,18,214,4,0,
764,18,216,4,0,
764,18,218,4,0,
764,18,219,4,0,
764,18,230,1,31,
764,18,235,1,28,
764,18,237,4,0,
764,18,241,4,0,
764,18,244,4,0,
764,18,263,4,0,
764,18,267,4,0,
764,18,269,4,0,
764,18,270,1,1,1
764,18,312,1,43,
764,18,345,1,10,
764,18,347,4,0,
764,18,363,1,22,
764,18,369,4,0,
764,18,374,4,0,
764,18,381,2,0,
764,18,412,4,0,
764,18,433,4,0,
764,18,447,1,34,
764,18,447,4,0,
764,18,495,2,0,
764,18,496,4,0,
764,18,497,4,0,
764,18,512,4,0,
764,18,572,1,25,
764,18,577,1,7,
764,18,579,1,1,3
764,18,580,1,46,
764,18,583,1,49,
764,18,590,4,0,
764,18,605,4,0,
764,18,666,1,37,
765,17,85,4,0,
765,17,87,4,0,
765,17,89,4,0,
765,17,92,4,0,
765,17,93,1,1,
765,17,94,1,43,
765,17,94,4,0,
765,17,104,4,0,
765,17,113,4,0,
765,17,115,4,0,
765,17,138,4,0,
765,17,156,4,0,
765,17,157,4,0,
765,17,164,4,0,
765,17,182,4,0,
765,17,185,1,22,
765,17,207,4,0,
765,17,214,4,0,
765,17,216,4,0,
765,17,218,4,0,
765,17,219,4,0,
765,17,237,4,0,
765,17,240,4,0,
765,17,241,4,0,
765,17,244,1,18,
765,17,244,4,0,
765,17,247,4,0,
765,17,248,1,46,
765,17,263,4,0,
765,17,267,4,0,
765,17,269,1,8,
765,17,269,4,0,
765,17,280,4,0,
765,17,326,2,0,
765,17,347,1,39,
765,17,347,4,0,
765,17,371,4,0,
765,17,373,4,0,
765,17,374,4,0,
765,17,411,4,0,
765,17,412,4,0,
765,17,416,4,0,
765,17,417,1,25,
765,17,428,1,29,
765,17,433,1,50,
765,17,433,4,0,
765,17,451,4,0,
765,17,472,2,0,
765,17,473,4,0,
765,17,492,1,36,
765,17,495,1,4,
765,17,496,4,0,
765,17,500,1,15,
765,17,511,1,11,
765,17,511,4,0,
765,17,523,4,0,
765,17,526,4,0,
765,17,590,4,0,
765,17,678,2,0,
765,17,689,1,32,
765,17,693,4,0,
765,18,85,4,0,
765,18,87,4,0,
765,18,89,4,0,
765,18,92,4,0,
765,18,93,1,1,
765,18,94,1,43,
765,18,94,4,0,
765,18,104,4,0,
765,18,113,4,0,
765,18,115,4,0,
765,18,138,4,0,
765,18,156,4,0,
765,18,157,4,0,
765,18,164,4,0,
765,18,182,4,0,
765,18,185,1,22,
765,18,207,4,0,
765,18,214,4,0,
765,18,216,4,0,
765,18,218,4,0,
765,18,219,4,0,
765,18,237,4,0,
765,18,240,4,0,
765,18,241,4,0,
765,18,244,1,18,
765,18,244,4,0,
765,18,247,4,0,
765,18,248,1,46,
765,18,263,4,0,
765,18,267,4,0,
765,18,269,1,8,
765,18,269,4,0,
765,18,280,4,0,
765,18,326,2,0,
765,18,347,1,39,
765,18,347,4,0,
765,18,371,4,0,
765,18,373,4,0,
765,18,374,4,0,
765,18,411,4,0,
765,18,412,4,0,
765,18,416,4,0,
765,18,417,1,25,
765,18,428,1,29,
765,18,433,1,50,
765,18,433,4,0,
765,18,451,4,0,
765,18,472,2,0,
765,18,473,4,0,
765,18,492,1,36,
765,18,495,1,4,
765,18,496,4,0,
765,18,500,1,15,
765,18,511,1,11,
765,18,511,4,0,
765,18,523,4,0,
765,18,526,4,0,
765,18,590,4,0,
765,18,678,2,0,
765,18,689,1,32,
765,18,693,4,0,
766,17,33,1,1,
766,17,36,1,22,
766,17,37,1,29,
766,17,38,1,36,
766,17,43,1,4,
766,17,63,4,0,
766,17,69,2,0,
766,17,89,4,0,
766,17,92,4,0,
766,17,98,2,0,
766,17,104,4,0,
766,17,116,1,11,
766,17,156,4,0,
766,17,157,4,0,
766,17,164,4,0,
766,17,168,4,0,
766,17,179,1,46,
766,17,182,4,0,
766,17,184,1,18,
766,17,207,4,0,
766,17,213,4,0,
766,17,214,4,0,
766,17,216,4,0,
766,17,218,4,0,
766,17,233,2,0,
766,17,237,4,0,
766,17,240,4,0,
766,17,241,4,0,
766,17,247,4,0,
766,17,249,1,8,
766,17,251,1,15,
766,17,263,4,0,
766,17,269,4,0,
766,17,280,4,0,
766,17,317,4,0,
766,17,332,4,0,
766,17,339,1,32,
766,17,339,4,0,
766,17,360,4,0,
766,17,364,2,0,
766,17,369,4,0,
766,17,370,1,43,
766,17,371,4,0,
766,17,374,1,39,
766,17,374,4,0,
766,17,411,4,0,
766,17,412,4,0,
766,17,416,1,50,
766,17,416,4,0,
766,17,442,2,0,
766,17,447,4,0,
766,17,479,4,0,
766,17,490,4,0,
766,17,496,4,0,
766,17,501,2,0,
766,17,512,4,0,
766,17,516,1,25,
766,17,523,4,0,
766,17,526,4,0,
766,17,590,4,0,
766,17,693,4,0,
766,18,33,1,1,
766,18,36,1,22,
766,18,37,1,29,
766,18,38,1,36,
766,18,43,1,4,
766,18,63,4,0,
766,18,69,2,0,
766,18,89,4,0,
766,18,92,4,0,
766,18,98,2,0,
766,18,104,4,0,
766,18,116,1,11,
766,18,156,4,0,
766,18,157,4,0,
766,18,164,4,0,
766,18,168,4,0,
766,18,179,1,46,
766,18,182,4,0,
766,18,184,1,18,
766,18,207,4,0,
766,18,213,4,0,
766,18,214,4,0,
766,18,216,4,0,
766,18,218,4,0,
766,18,233,2,0,
766,18,237,4,0,
766,18,240,4,0,
766,18,241,4,0,
766,18,247,4,0,
766,18,249,1,8,
766,18,251,1,15,
766,18,263,4,0,
766,18,269,4,0,
766,18,280,4,0,
766,18,317,4,0,
766,18,332,4,0,
766,18,339,1,32,
766,18,339,4,0,
766,18,360,4,0,
766,18,364,2,0,
766,18,369,4,0,
766,18,370,1,43,
766,18,371,4,0,
766,18,374,1,39,
766,18,374,4,0,
766,18,411,4,0,
766,18,412,4,0,
766,18,416,1,50,
766,18,416,4,0,
766,18,442,2,0,
766,18,447,4,0,
766,18,479,4,0,
766,18,490,4,0,
766,18,496,4,0,
766,18,501,2,0,
766,18,512,4,0,
766,18,516,1,25,
766,18,523,4,0,
766,18,526,4,0,
766,18,590,4,0,
766,18,693,4,0,
767,17,28,1,1,2
767,17,57,4,0,
767,17,92,4,0,
767,17,104,4,0,
767,17,106,2,0,
767,17,127,4,0,
767,17,141,4,0,
767,17,156,4,0,
767,17,164,4,0,
767,17,182,4,0,
767,17,191,2,0,
767,17,207,4,0,
767,17,213,4,0,
767,17,214,4,0,
767,17,216,4,0,
767,17,218,4,0,
767,17,232,2,0,
767,17,237,4,0,
767,17,240,4,0,
767,17,258,4,0,
767,17,263,4,0,
767,17,269,4,0,
767,17,453,2,0,
767,17,469,2,0,
767,17,496,4,0,
767,17,503,4,0,
767,17,522,1,1,1
767,17,590,4,0,
767,18,28,1,1,2
767,18,57,4,0,
767,18,92,4,0,
767,18,104,4,0,
767,18,106,2,0,
767,18,127,4,0,
767,18,141,4,0,
767,18,156,4,0,
767,18,164,4,0,
767,18,182,4,0,
767,18,191,2,0,
767,18,207,4,0,
767,18,213,4,0,
767,18,214,4,0,
767,18,216,4,0,
767,18,218,4,0,
767,18,232,2,0,
767,18,237,4,0,
767,18,240,4,0,
767,18,258,4,0,
767,18,263,4,0,
767,18,269,4,0,
767,18,453,2,0,
767,18,469,2,0,
767,18,496,4,0,
767,18,503,4,0,
767,18,522,1,1,1
767,18,590,4,0,
768,17,14,1,16,
768,17,14,4,0,
768,17,28,1,1,3
768,17,42,1,41,
768,17,57,4,0,
768,17,58,4,0,
768,17,59,4,0,
768,17,92,4,0,
768,17,104,4,0,
768,17,127,4,0,
768,17,141,4,0,
768,17,156,4,0,
768,17,157,4,0,
768,17,163,1,21,
768,17,164,4,0,
768,17,180,1,13,
768,17,182,4,0,
768,17,188,4,0,
768,17,206,4,0,
768,17,207,4,0,
768,17,210,1,1,4
768,17,210,1,4,
768,17,213,4,0,
768,17,214,4,0,
768,17,216,4,0,
768,17,218,4,0,
768,17,237,4,0,
768,17,240,4,0,
768,17,244,4,0,
768,17,249,1,1,5
768,17,249,1,7,
768,17,258,4,0,
768,17,263,4,0,
768,17,269,4,0,
768,17,280,4,0,
768,17,317,4,0,
768,17,332,4,0,
768,17,334,1,36,
768,17,339,4,0,
768,17,371,4,0,
768,17,374,4,0,
768,17,389,1,31,
768,17,398,4,0,
768,17,399,4,0,
768,17,404,4,0,
768,17,411,4,0,
768,17,416,4,0,
768,17,421,4,0,
768,17,450,1,10,
768,17,474,4,0,
768,17,482,4,0,
768,17,496,4,0,
768,17,503,4,0,
768,17,522,1,1,2
768,17,524,4,0,
768,17,534,1,26,
768,17,555,4,0,
768,17,590,4,0,
768,17,660,1,0,
768,17,660,1,1,1
768,17,710,1,48,
768,18,14,1,16,
768,18,14,4,0,
768,18,28,1,1,3
768,18,42,1,41,
768,18,57,4,0,
768,18,58,4,0,
768,18,59,4,0,
768,18,92,4,0,
768,18,104,4,0,
768,18,127,4,0,
768,18,141,4,0,
768,18,156,4,0,
768,18,157,4,0,
768,18,163,1,21,
768,18,164,4,0,
768,18,180,1,13,
768,18,182,4,0,
768,18,188,4,0,
768,18,206,4,0,
768,18,207,4,0,
768,18,210,1,1,4
768,18,210,1,4,
768,18,213,4,0,
768,18,214,4,0,
768,18,216,4,0,
768,18,218,4,0,
768,18,237,4,0,
768,18,240,4,0,
768,18,244,4,0,
768,18,249,1,1,5
768,18,249,1,7,
768,18,258,4,0,
768,18,263,4,0,
768,18,269,4,0,
768,18,280,4,0,
768,18,317,4,0,
768,18,332,4,0,
768,18,334,1,36,
768,18,339,4,0,
768,18,371,4,0,
768,18,374,4,0,
768,18,389,1,31,
768,18,398,4,0,
768,18,399,4,0,
768,18,404,4,0,
768,18,411,4,0,
768,18,416,4,0,
768,18,421,4,0,
768,18,450,1,10,
768,18,474,4,0,
768,18,482,4,0,
768,18,496,4,0,
768,18,503,4,0,
768,18,522,1,1,2
768,18,524,4,0,
768,18,534,1,26,
768,18,555,4,0,
768,18,590,4,0,
768,18,660,1,0,
768,18,660,1,1,1
768,18,710,1,48,
769,17,28,1,9,
769,17,71,1,1,2
769,17,72,1,18,
769,17,89,4,0,
769,17,92,4,0,
769,17,94,4,0,
769,17,95,1,27,
769,17,104,4,0,
769,17,106,1,1,1
769,17,133,2,0,
769,17,156,4,0,
769,17,157,4,0,
769,17,164,4,0,
769,17,182,4,0,
769,17,194,2,0,
769,17,201,1,54,
769,17,201,4,0,
769,17,202,1,36,
769,17,207,4,0,
769,17,213,4,0,
769,17,214,4,0,
769,17,216,4,0,
769,17,218,4,0,
769,17,237,4,0,
769,17,246,2,0,
769,17,247,1,41,
769,17,247,4,0,
769,17,254,2,0,
769,17,255,2,0,
769,17,256,2,0,
769,17,263,4,0,
769,17,310,1,5,
769,17,317,4,0,
769,17,328,1,14,
769,17,334,1,32,
769,17,397,4,0,
769,17,412,4,0,
769,17,414,1,45,
769,17,444,4,0,
769,17,496,4,0,
769,17,523,1,23,
769,17,523,4,0,
769,17,590,4,0,
769,17,611,4,0,
769,17,659,1,50,
769,18,28,1,9,
769,18,71,1,1,2
769,18,72,1,18,
769,18,89,4,0,
769,18,92,4,0,
769,18,94,4,0,
769,18,95,1,27,
769,18,104,4,0,
769,18,106,1,1,1
769,18,133,2,0,
769,18,156,4,0,
769,18,157,4,0,
769,18,164,4,0,
769,18,174,2,0,
769,18,182,4,0,
769,18,194,2,0,
769,18,201,1,54,
769,18,201,4,0,
769,18,202,1,36,
769,18,207,4,0,
769,18,213,4,0,
769,18,214,4,0,
769,18,216,4,0,
769,18,218,4,0,
769,18,237,4,0,
769,18,246,2,0,
769,18,247,1,41,
769,18,247,4,0,
769,18,254,2,0,
769,18,255,2,0,
769,18,256,2,0,
769,18,263,4,0,
769,18,310,1,5,
769,18,317,4,0,
769,18,328,1,14,
769,18,334,1,32,
769,18,397,4,0,
769,18,412,4,0,
769,18,414,1,45,
769,18,444,4,0,
769,18,496,4,0,
769,18,523,1,23,
769,18,523,4,0,
769,18,590,4,0,
769,18,611,4,0,
769,18,659,1,50,
770,17,28,1,1,4
770,17,28,1,9,
770,17,71,1,1,2
770,17,72,1,18,
770,17,89,4,0,
770,17,92,4,0,
770,17,94,4,0,
770,17,95,1,27,
770,17,104,4,0,
770,17,106,1,1,1
770,17,156,4,0,
770,17,157,4,0,
770,17,164,4,0,
770,17,182,4,0,
770,17,188,4,0,
770,17,201,1,60,
770,17,201,4,0,
770,17,202,1,36,
770,17,207,4,0,
770,17,213,4,0,
770,17,214,4,0,
770,17,216,4,0,
770,17,218,4,0,
770,17,237,4,0,
770,17,247,1,41,
770,17,247,4,0,
770,17,263,4,0,
770,17,310,1,1,3
770,17,310,1,5,
770,17,317,4,0,
770,17,328,1,14,
770,17,334,1,32,
770,17,373,4,0,
770,17,374,4,0,
770,17,397,4,0,
770,17,412,4,0,
770,17,414,1,47,
770,17,416,4,0,
770,17,444,4,0,
770,17,496,4,0,
770,17,511,4,0,
770,17,523,1,23,
770,17,523,4,0,
770,17,590,4,0,
770,17,611,4,0,
770,17,659,1,54,
770,18,28,1,1,4
770,18,28,1,9,
770,18,71,1,1,2
770,18,72,1,18,
770,18,89,4,0,
770,18,92,4,0,
770,18,94,4,0,
770,18,95,1,27,
770,18,104,4,0,
770,18,106,1,1,1
770,18,156,4,0,
770,18,157,4,0,
770,18,164,4,0,
770,18,182,4,0,
770,18,188,4,0,
770,18,201,1,60,
770,18,201,4,0,
770,18,202,1,36,
770,18,207,4,0,
770,18,213,4,0,
770,18,214,4,0,
770,18,216,4,0,
770,18,218,4,0,
770,18,237,4,0,
770,18,247,1,41,
770,18,247,4,0,
770,18,263,4,0,
770,18,310,1,1,3
770,18,310,1,5,
770,18,317,4,0,
770,18,328,1,14,
770,18,334,1,32,
770,18,373,4,0,
770,18,374,4,0,
770,18,397,4,0,
770,18,412,4,0,
770,18,414,1,47,
770,18,416,4,0,
770,18,444,4,0,
770,18,496,4,0,
770,18,511,4,0,
770,18,523,1,23,
770,18,523,4,0,
770,18,590,4,0,
770,18,611,4,0,
770,18,659,1,54,
771,17,68,1,17,
771,17,92,1,45,
771,17,92,4,0,
771,17,104,4,0,
771,17,105,1,37,
771,17,106,1,1,4
771,17,113,4,0,
771,17,115,4,0,
771,17,117,1,1,5
771,17,156,4,0,
771,17,164,4,0,
771,17,174,1,25,
771,17,182,4,0,
771,17,203,2,0,
771,17,207,4,0,
771,17,213,4,0,
771,17,214,4,0,
771,17,219,1,13,
771,17,219,4,0,
771,17,220,1,33,
771,17,226,1,1,1
771,17,240,4,0,
771,17,244,4,0,
771,17,258,4,0,
771,17,262,1,49,
771,17,269,1,9,
771,17,269,4,0,
771,17,270,1,5,
771,17,300,1,1,3
771,17,321,2,0,
771,17,346,1,1,2
771,17,380,1,29,
771,17,487,1,41,
771,17,511,4,0,
771,17,516,2,0,
771,17,590,4,0,
771,17,599,2,0,
771,17,685,1,21,
771,18,68,1,17,
771,18,92,1,45,
771,18,92,4,0,
771,18,104,4,0,
771,18,105,1,37,
771,18,106,1,1,4
771,18,113,4,0,
771,18,115,4,0,
771,18,117,1,1,5
771,18,156,4,0,
771,18,164,4,0,
771,18,174,1,25,
771,18,180,2,0,
771,18,182,4,0,
771,18,203,2,0,
771,18,207,4,0,
771,18,213,4,0,
771,18,214,4,0,
771,18,219,1,13,
771,18,219,4,0,
771,18,220,1,33,
771,18,226,1,1,1
771,18,240,4,0,
771,18,244,4,0,
771,18,258,4,0,
771,18,262,1,49,
771,18,269,1,9,
771,18,269,4,0,
771,18,270,1,5,
771,18,300,1,1,3
771,18,321,2,0,
771,18,346,1,1,2
771,18,380,1,29,
771,18,487,1,41,
771,18,511,4,0,
771,18,516,2,0,
771,18,590,4,0,
771,18,599,2,0,
771,18,685,1,21,
772,17,13,1,70,
772,17,14,4,0,
772,17,33,1,1,
772,17,36,1,40,
772,17,38,1,80,
772,17,46,4,0,
772,17,86,4,0,
772,17,92,4,0,
772,17,99,1,5,
772,17,104,4,0,
772,17,156,4,0,
772,17,157,4,0,
772,17,161,1,75,
772,17,164,4,0,
772,17,182,4,0,
772,17,184,1,30,
772,17,201,4,0,
772,17,207,4,0,
772,17,214,4,0,
772,17,216,4,0,
772,17,218,4,0,
772,17,228,1,10,
772,17,237,4,0,
772,17,240,4,0,
772,17,241,4,0,
772,17,258,4,0,
772,17,263,4,0,
772,17,286,1,15,
772,17,306,1,25,
772,17,319,1,45,
772,17,332,1,20,
772,17,332,4,0,
772,17,337,4,0,
772,17,369,4,0,
772,17,371,4,0,
772,17,377,1,85,
772,17,386,1,65,
772,17,403,1,60,
772,17,404,1,35,
772,17,404,4,0,
772,17,416,4,0,
772,17,421,4,0,
772,17,442,1,50,
772,17,458,1,55,
772,17,488,4,0,
772,17,496,4,0,
772,17,526,4,0,
772,17,590,4,0,
772,18,13,1,70,
772,18,14,4,0,
772,18,33,1,1,
772,18,36,1,40,
772,18,38,1,80,
772,18,46,4,0,
772,18,86,4,0,
772,18,92,4,0,
772,18,99,1,5,
772,18,104,4,0,
772,18,156,4,0,
772,18,157,4,0,
772,18,161,1,75,
772,18,164,4,0,
772,18,182,4,0,
772,18,184,1,30,
772,18,201,4,0,
772,18,207,4,0,
772,18,214,4,0,
772,18,216,4,0,
772,18,218,4,0,
772,18,228,1,10,
772,18,237,4,0,
772,18,240,4,0,
772,18,241,4,0,
772,18,258,4,0,
772,18,263,4,0,
772,18,286,1,15,
772,18,306,1,25,
772,18,319,1,45,
772,18,332,1,20,
772,18,332,4,0,
772,18,337,4,0,
772,18,369,4,0,
772,18,371,4,0,
772,18,377,1,85,
772,18,386,1,65,
772,18,403,1,60,
772,18,404,1,35,
772,18,404,4,0,
772,18,416,4,0,
772,18,421,4,0,
772,18,442,1,50,
772,18,458,1,55,
772,18,488,4,0,
772,18,496,4,0,
772,18,526,4,0,
772,18,590,4,0,
773,17,13,1,70,
773,17,14,4,0,
773,17,33,1,1,9
773,17,36,1,40,
773,17,38,1,80,
773,17,44,1,15,
773,17,46,4,0,
773,17,53,4,0,
773,17,57,4,0,
773,17,58,4,0,
773,17,63,4,0,
773,17,85,4,0,
773,17,86,4,0,
773,17,92,4,0,
773,17,99,1,5,
773,17,104,4,0,
773,17,153,4,0,
773,17,156,4,0,
773,17,157,4,0,
773,17,161,1,75,
773,17,164,4,0,
773,17,182,4,0,
773,17,184,1,30,
773,17,201,4,0,
773,17,207,4,0,
773,17,211,4,0,
773,17,214,4,0,
773,17,216,4,0,
773,17,218,4,0,
773,17,228,1,10,
773,17,237,4,0,
773,17,240,4,0,
773,17,241,4,0,
773,17,242,1,50,
773,17,247,4,0,
773,17,258,4,0,
773,17,263,4,0,
773,17,286,1,1,3
773,17,305,1,1,5
773,17,306,1,25,
773,17,319,1,45,
773,17,332,1,20,
773,17,332,4,0,
773,17,337,4,0,
773,17,369,4,0,
773,17,371,4,0,
773,17,377,1,1,2
773,17,386,1,65,
773,17,403,1,60,
773,17,404,1,35,
773,17,404,4,0,
773,17,416,4,0,
773,17,421,4,0,
773,17,422,1,1,8
773,17,423,1,1,7
773,17,424,1,1,6
773,17,430,4,0,
773,17,434,3,0,
773,17,442,1,1,4
773,17,458,1,55,
773,17,488,4,0,
773,17,496,4,0,
773,17,526,4,0,
773,17,555,4,0,
773,17,575,1,85,
773,17,590,4,0,
773,17,718,1,0,
773,17,718,1,1,1
773,18,13,1,70,
773,18,14,4,0,
773,18,33,1,1,9
773,18,36,1,40,
773,18,38,1,80,
773,18,44,1,15,
773,18,46,4,0,
773,18,53,4,0,
773,18,57,4,0,
773,18,58,4,0,
773,18,63,4,0,
773,18,85,4,0,
773,18,86,4,0,
773,18,92,4,0,
773,18,99,1,5,
773,18,104,4,0,
773,18,153,4,0,
773,18,156,4,0,
773,18,157,4,0,
773,18,161,1,75,
773,18,164,4,0,
773,18,182,4,0,
773,18,184,1,30,
773,18,201,4,0,
773,18,207,4,0,
773,18,211,4,0,
773,18,214,4,0,
773,18,216,4,0,
773,18,218,4,0,
773,18,228,1,10,
773,18,237,4,0,
773,18,240,4,0,
773,18,241,4,0,
773,18,242,1,50,
773,18,247,4,0,
773,18,258,4,0,
773,18,263,4,0,
773,18,286,1,1,3
773,18,305,1,1,5
773,18,306,1,25,
773,18,319,1,45,
773,18,332,1,20,
773,18,332,4,0,
773,18,337,4,0,
773,18,369,4,0,
773,18,371,4,0,
773,18,377,1,1,2
773,18,386,1,65,
773,18,403,1,60,
773,18,404,1,35,
773,18,404,4,0,
773,18,416,4,0,
773,18,421,4,0,
773,18,422,1,1,8
773,18,423,1,1,7
773,18,424,1,1,6
773,18,430,4,0,
773,18,434,3,0,
773,18,442,1,1,4
773,18,458,1,55,
773,18,488,4,0,
773,18,496,4,0,
773,18,518,3,0,
773,18,519,3,0,
773,18,520,3,0,
773,18,526,4,0,
773,18,555,4,0,
773,18,575,1,85,
773,18,590,4,0,
773,18,718,1,0,
773,18,718,1,1,1
774,17,33,1,1,
774,17,36,1,29,
774,17,38,1,43,
774,17,63,4,0,
774,17,76,4,0,
774,17,89,4,0,
774,17,92,4,0,
774,17,94,4,0,
774,17,104,4,0,
774,17,109,1,10,
774,17,111,1,3,
774,17,113,4,0,
774,17,115,4,0,
774,17,120,1,22,
774,17,129,1,15,
774,17,153,1,50,
774,17,153,4,0,
774,17,156,4,0,
774,17,157,4,0,
774,17,164,4,0,
774,17,182,4,0,
774,17,201,4,0,
774,17,205,1,8,
774,17,207,4,0,
774,17,213,4,0,
774,17,214,4,0,
774,17,216,4,0,
774,17,218,4,0,
774,17,219,4,0,
774,17,237,4,0,
774,17,244,4,0,
774,17,246,1,17,
774,17,263,4,0,
774,17,317,4,0,
774,17,322,1,36,
774,17,347,4,0,
774,17,360,4,0,
774,17,369,4,0,
774,17,397,4,0,
774,17,408,1,38,
774,17,416,4,0,
774,17,444,4,0,
774,17,446,1,24,
774,17,451,4,0,
774,17,475,1,31,
774,17,496,4,0,
774,17,504,1,45,
774,17,512,4,0,
774,17,523,4,0,
774,17,590,4,0,
774,17,605,4,0,
774,18,33,1,1,
774,18,36,1,29,
774,18,38,1,43,
774,18,63,4,0,
774,18,76,4,0,
774,18,89,4,0,
774,18,92,4,0,
774,18,94,4,0,
774,18,104,4,0,
774,18,109,1,10,
774,18,111,1,3,
774,18,113,4,0,
774,18,115,4,0,
774,18,120,1,22,
774,18,129,1,15,
774,18,153,1,50,
774,18,153,4,0,
774,18,156,4,0,
774,18,157,4,0,
774,18,164,4,0,
774,18,182,4,0,
774,18,201,4,0,
774,18,205,1,8,
774,18,207,4,0,
774,18,213,4,0,
774,18,214,4,0,
774,18,216,4,0,
774,18,218,4,0,
774,18,219,4,0,
774,18,237,4,0,
774,18,244,4,0,
774,18,246,1,17,
774,18,263,4,0,
774,18,317,4,0,
774,18,322,1,36,
774,18,347,4,0,
774,18,360,4,0,
774,18,369,4,0,
774,18,397,4,0,
774,18,408,1,38,
774,18,416,4,0,
774,18,444,4,0,
774,18,446,1,24,
774,18,451,4,0,
774,18,475,1,31,
774,18,496,4,0,
774,18,504,1,45,
774,18,512,4,0,
774,18,523,4,0,
774,18,590,4,0,
774,18,605,4,0,
775,17,14,4,0,
775,17,21,1,21,
775,17,37,1,46,
775,17,47,2,0,
775,17,89,4,0,
775,17,92,4,0,
775,17,104,4,0,
775,17,111,1,1,1
775,17,157,4,0,
775,17,164,4,0,
775,17,175,1,26,
775,17,182,4,0,
775,17,204,2,0,
775,17,205,1,1,2
775,17,207,4,0,
775,17,213,4,0,
775,17,214,4,0,
775,17,216,4,0,
775,17,218,4,0,
775,17,229,1,11,
775,17,237,4,0,
775,17,241,4,0,
775,17,244,1,36,
775,17,244,4,0,
775,17,254,1,6,1
775,17,255,1,6,2
775,17,256,1,6,3
775,17,263,4,0,
775,17,273,2,0,
775,17,280,4,0,
775,17,281,1,16,
775,17,339,4,0,
775,17,347,4,0,
775,17,369,4,0,
775,17,371,4,0,
775,17,389,1,31,
775,17,421,4,0,
775,17,452,1,41,
775,17,490,4,0,
775,17,496,4,0,
775,17,511,4,0,
775,17,512,4,0,
775,17,523,4,0,
775,17,526,4,0,
775,17,583,2,0,
775,17,590,4,0,
775,18,14,4,0,
775,18,21,1,21,
775,18,37,1,46,
775,18,47,2,0,
775,18,89,4,0,
775,18,92,4,0,
775,18,104,4,0,
775,18,111,1,1,1
775,18,157,4,0,
775,18,164,4,0,
775,18,175,1,26,
775,18,182,4,0,
775,18,204,2,0,
775,18,205,1,1,2
775,18,207,4,0,
775,18,213,4,0,
775,18,214,4,0,
775,18,216,4,0,
775,18,218,4,0,
775,18,229,1,11,
775,18,237,4,0,
775,18,241,4,0,
775,18,244,1,36,
775,18,244,4,0,
775,18,254,1,6,1
775,18,255,1,6,2
775,18,256,1,6,3
775,18,263,4,0,
775,18,273,2,0,
775,18,280,4,0,
775,18,281,1,16,
775,18,339,4,0,
775,18,347,4,0,
775,18,369,4,0,
775,18,371,4,0,
775,18,389,1,31,
775,18,421,4,0,
775,18,452,1,41,
775,18,490,4,0,
775,18,496,4,0,
775,18,511,4,0,
775,18,512,4,0,
775,18,523,4,0,
775,18,526,4,0,
775,18,583,2,0,
775,18,590,4,0,
776,17,33,1,1,2
776,17,34,1,33,
776,17,46,4,0,
776,17,52,1,1,1
776,17,53,1,29,
776,17,53,4,0,
776,17,63,4,0,
776,17,76,4,0,
776,17,83,2,0,
776,17,89,4,0,
776,17,92,4,0,
776,17,104,4,0,
776,17,123,1,5,
776,17,126,4,0,
776,17,153,1,53,
776,17,153,4,0,
776,17,156,4,0,
776,17,164,4,0,
776,17,175,1,17,
776,17,182,1,9,
776,17,182,4,0,
776,17,203,1,21,
776,17,207,4,0,
776,17,213,4,0,
776,17,214,4,0,
776,17,216,4,0,
776,17,218,4,0,
776,17,237,4,0,
776,17,241,4,0,
776,17,261,4,0,
776,17,263,4,0,
776,17,269,4,0,
776,17,279,2,0,
776,17,315,1,49,
776,17,315,4,0,
776,17,317,4,0,
776,17,334,1,25,
776,17,337,4,0,
776,17,339,4,0,
776,17,371,4,0,
776,17,374,4,0,
776,17,406,1,41,
776,17,411,4,0,
776,17,416,4,0,
776,17,430,4,0,
776,17,434,3,0,
776,17,444,4,0,
776,17,451,4,0,
776,17,457,2,0,
776,17,469,2,0,
776,17,474,4,0,
776,17,479,4,0,
776,17,488,4,0,
776,17,496,4,0,
776,17,504,1,37,
776,17,510,1,13,
776,17,523,4,0,
776,17,525,4,0,
776,17,526,4,0,
776,17,590,4,0,
776,17,693,4,0,
776,17,704,1,45,
776,18,33,1,1,2
776,18,34,1,33,
776,18,46,4,0,
776,18,52,1,1,1
776,18,53,1,29,
776,18,53,4,0,
776,18,63,4,0,
776,18,76,4,0,
776,18,83,2,0,
776,18,89,4,0,
776,18,92,4,0,
776,18,104,4,0,
776,18,123,1,5,
776,18,126,4,0,
776,18,153,1,53,
776,18,153,4,0,
776,18,156,4,0,
776,18,164,4,0,
776,18,175,1,17,
776,18,182,1,9,
776,18,182,4,0,
776,18,203,1,21,
776,18,207,4,0,
776,18,213,4,0,
776,18,214,4,0,
776,18,216,4,0,
776,18,218,4,0,
776,18,237,4,0,
776,18,241,4,0,
776,18,261,4,0,
776,18,263,4,0,
776,18,269,4,0,
776,18,279,2,0,
776,18,315,1,49,
776,18,315,4,0,
776,18,317,4,0,
776,18,334,1,25,
776,18,337,4,0,
776,18,339,4,0,
776,18,371,4,0,
776,18,374,4,0,
776,18,406,1,41,
776,18,411,4,0,
776,18,416,4,0,
776,18,430,4,0,
776,18,434,3,0,
776,18,444,4,0,
776,18,451,4,0,
776,18,457,2,0,
776,18,469,2,0,
776,18,474,4,0,
776,18,479,4,0,
776,18,488,4,0,
776,18,496,4,0,
776,18,504,1,37,
776,18,510,1,13,
776,18,523,4,0,
776,18,525,4,0,
776,18,526,4,0,
776,18,590,4,0,
776,18,693,4,0,
776,18,704,1,45,
777,17,33,1,1,1
777,17,41,2,0,
777,17,42,1,45,
777,17,84,1,1,2
777,17,85,4,0,
777,17,86,4,0,
777,17,87,4,0,
777,17,92,4,0,
777,17,104,4,0,
777,17,111,1,5,
777,17,115,4,0,
777,17,156,4,0,
777,17,164,4,0,
777,17,168,4,0,
777,17,175,2,0,
777,17,179,2,0,
777,17,182,4,0,
777,17,205,1,9,
777,17,207,4,0,
777,17,209,1,17,
777,17,213,4,0,
777,17,214,4,0,
777,17,216,4,0,
777,17,217,2,0,
777,17,218,4,0,
777,17,227,2,0,
777,17,237,4,0,
777,17,252,2,0,
777,17,263,4,0,
777,17,268,1,13,
777,17,273,2,0,
777,17,321,2,0,
777,17,360,4,0,
777,17,369,4,0,
777,17,371,4,0,
777,17,374,4,0,
777,17,393,1,25,
777,17,398,4,0,
777,17,416,4,0,
777,17,435,1,29,
777,17,447,4,0,
777,17,451,4,0,
777,17,496,4,0,
777,17,521,4,0,
777,17,526,4,0,
777,17,528,1,41,
777,17,528,4,0,
777,17,565,1,53,
777,17,574,2,0,
777,17,590,4,0,
777,17,596,1,49,
777,17,604,1,37,
777,17,609,1,21,
777,17,716,1,33,
777,18,33,1,1,1
777,18,41,2,0,
777,18,42,1,45,
777,18,84,1,1,2
777,18,85,4,0,
777,18,86,4,0,
777,18,87,4,0,
777,18,92,4,0,
777,18,104,4,0,
777,18,111,1,5,
777,18,115,4,0,
777,18,156,4,0,
777,18,164,4,0,
777,18,168,4,0,
777,18,175,2,0,
777,18,179,2,0,
777,18,182,4,0,
777,18,205,1,9,
777,18,207,4,0,
777,18,209,1,17,
777,18,213,4,0,
777,18,214,4,0,
777,18,216,4,0,
777,18,217,2,0,
777,18,218,4,0,
777,18,227,2,0,
777,18,237,4,0,
777,18,252,2,0,
777,18,263,4,0,
777,18,268,1,13,
777,18,273,2,0,
777,18,321,2,0,
777,18,360,4,0,
777,18,369,4,0,
777,18,371,4,0,
777,18,374,4,0,
777,18,393,1,25,
777,18,398,4,0,
777,18,416,4,0,
777,18,435,1,29,
777,18,447,4,0,
777,18,451,4,0,
777,18,496,4,0,
777,18,521,4,0,
777,18,526,4,0,
777,18,528,1,41,
777,18,528,4,0,
777,18,565,1,53,
777,18,574,2,0,
777,18,590,4,0,
777,18,596,1,49,
777,18,604,1,37,
777,18,609,1,21,
777,18,716,1,33,
778,17,10,1,1,3
778,17,14,4,0,
778,17,63,4,0,
778,17,85,4,0,
778,17,86,4,0,
778,17,87,4,0,
778,17,92,4,0,
778,17,94,4,0,
778,17,102,1,19,
778,17,104,1,5,
778,17,104,4,0,
778,17,113,4,0,
778,17,138,4,0,
778,17,141,4,0,
778,17,150,1,1,2
778,17,156,4,0,
778,17,163,1,32,
778,17,164,4,0,
778,17,168,4,0,
778,17,171,2,0,
778,17,174,2,0,
778,17,182,4,0,
778,17,185,1,23,
778,17,194,2,0,
778,17,204,1,28,
778,17,207,4,0,
778,17,213,4,0,
778,17,214,4,0,
778,17,216,4,0,
778,17,218,4,0,
778,17,219,4,0,
778,17,220,1,50,
778,17,237,4,0,
778,17,244,4,0,
778,17,247,4,0,
778,17,261,4,0,
778,17,263,4,0,
778,17,269,4,0,
778,17,288,2,0,
778,17,310,1,1,4
778,17,339,4,0,
778,17,371,4,0,
778,17,373,4,0,
778,17,374,4,0,
778,17,383,1,1,5
778,17,399,4,0,
778,17,404,4,0,
778,17,421,1,37,
778,17,421,4,0,
778,17,425,1,14,
778,17,433,4,0,
778,17,451,4,0,
778,17,452,1,1,1
778,17,468,1,41,
778,17,496,4,0,
778,17,526,4,0,
778,17,583,1,46,
778,17,590,4,0,
778,17,605,4,0,
778,17,608,1,10,
778,17,611,4,0,
778,18,10,1,1,3
778,18,14,4,0,
778,18,63,4,0,
778,18,85,4,0,
778,18,86,4,0,
778,18,87,4,0,
778,18,92,4,0,
778,18,94,4,0,
778,18,102,1,19,
778,18,104,1,5,
778,18,104,4,0,
778,18,113,4,0,
778,18,138,4,0,
778,18,141,4,0,
778,18,150,1,1,2
778,18,156,4,0,
778,18,163,1,32,
778,18,164,4,0,
778,18,168,4,0,
778,18,171,2,0,
778,18,174,2,0,
778,18,182,4,0,
778,18,185,1,23,
778,18,194,2,0,
778,18,204,1,28,
778,18,207,4,0,
778,18,213,4,0,
778,18,214,4,0,
778,18,216,4,0,
778,18,218,4,0,
778,18,219,4,0,
778,18,220,1,50,
778,18,237,4,0,
778,18,244,4,0,
778,18,247,4,0,
778,18,261,4,0,
778,18,263,4,0,
778,18,269,4,0,
778,18,288,2,0,
778,18,310,1,1,4
778,18,339,4,0,
778,18,371,4,0,
778,18,373,4,0,
778,18,374,4,0,
778,18,383,1,1,5
778,18,399,4,0,
778,18,404,4,0,
778,18,421,1,37,
778,18,421,4,0,
778,18,425,1,14,
778,18,433,4,0,
778,18,451,4,0,
778,18,452,1,1,1
778,18,468,1,41,
778,18,496,4,0,
778,18,526,4,0,
778,18,583,1,46,
778,18,590,4,0,
778,18,605,4,0,
778,18,608,1,10,
778,18,611,4,0,
779,17,14,4,0,
779,17,44,1,12,
779,17,50,1,20,
779,17,55,1,1,
779,17,57,4,0,
779,17,58,4,0,
779,17,59,4,0,
779,17,92,4,0,
779,17,93,1,9,
779,17,94,4,0,
779,17,99,2,0,
779,17,103,1,36,
779,17,104,4,0,
779,17,113,4,0,
779,17,115,4,0,
779,17,127,4,0,
779,17,138,4,0,
779,17,149,1,25,
779,17,156,4,0,
779,17,164,4,0,
779,17,182,4,0,
779,17,207,4,0,
779,17,213,4,0,
779,17,214,4,0,
779,17,216,4,0,
779,17,218,4,0,
779,17,219,4,0,
779,17,237,4,0,
779,17,240,4,0,
779,17,242,1,28,
779,17,259,4,0,
779,17,263,4,0,
779,17,269,4,0,
779,17,305,2,0,
779,17,310,1,4,
779,17,332,4,0,
779,17,339,4,0,
779,17,347,4,0,
779,17,352,2,0,
779,17,371,4,0,
779,17,373,4,0,
779,17,374,4,0,
779,17,401,1,33,
779,17,416,4,0,
779,17,423,2,0,
779,17,433,4,0,
779,17,453,1,17,
779,17,474,4,0,
779,17,485,1,44,
779,17,496,4,0,
779,17,503,4,0,
779,17,524,4,0,
779,17,590,4,0,
779,17,706,1,41,
779,18,14,4,0,
779,18,44,1,12,
779,18,50,1,20,
779,18,55,1,1,
779,18,57,4,0,
779,18,58,4,0,
779,18,59,4,0,
779,18,92,4,0,
779,18,93,1,9,
779,18,94,4,0,
779,18,99,2,0,
779,18,103,1,36,
779,18,104,4,0,
779,18,113,4,0,
779,18,115,4,0,
779,18,127,4,0,
779,18,138,4,0,
779,18,149,1,25,
779,18,156,4,0,
779,18,164,4,0,
779,18,182,4,0,
779,18,207,4,0,
779,18,213,4,0,
779,18,214,4,0,
779,18,216,4,0,
779,18,218,4,0,
779,18,219,4,0,
779,18,237,4,0,
779,18,240,4,0,
779,18,242,1,28,
779,18,259,4,0,
779,18,263,4,0,
779,18,269,4,0,
779,18,305,2,0,
779,18,310,1,4,
779,18,332,4,0,
779,18,339,4,0,
779,18,347,4,0,
779,18,352,2,0,
779,18,371,4,0,
779,18,373,4,0,
779,18,374,4,0,
779,18,401,1,33,
779,18,416,4,0,
779,18,423,2,0,
779,18,433,4,0,
779,18,453,1,17,
779,18,474,4,0,
779,18,485,1,44,
779,18,496,4,0,
779,18,503,4,0,
779,18,524,4,0,
779,18,590,4,0,
779,18,706,1,41,
780,17,13,2,0,
780,17,19,1,45,
780,17,19,4,0,
780,17,46,4,0,
780,17,53,4,0,
780,17,54,2,0,
780,17,57,4,0,
780,17,58,4,0,
780,17,59,4,0,
780,17,63,4,0,
780,17,76,4,0,
780,17,82,1,21,
780,17,85,4,0,
780,17,86,4,0,
780,17,87,4,0,
780,17,89,4,0,
780,17,92,4,0,
780,17,104,4,0,
780,17,113,1,17,
780,17,113,4,0,
780,17,126,4,0,
780,17,137,1,13,
780,17,156,4,0,
780,17,157,4,0,
780,17,164,4,0,
780,17,182,1,9,
780,17,182,4,0,
780,17,200,1,53,
780,17,211,4,0,
780,17,213,4,0,
780,17,214,4,0,
780,17,216,4,0,
780,17,218,4,0,
780,17,219,1,33,
780,17,219,4,0,
780,17,225,1,29,
780,17,237,4,0,
780,17,239,1,5,
780,17,240,4,0,
780,17,241,4,0,
780,17,244,4,0,
780,17,247,4,0,
780,17,263,4,0,
780,17,267,4,0,
780,17,304,1,49,
780,17,326,1,37,
780,17,337,4,0,
780,17,347,4,0,
780,17,355,4,0,
780,17,363,1,25,
780,17,374,4,0,
780,17,406,1,41,
780,17,407,2,0,
780,17,411,4,0,
780,17,412,4,0,
780,17,416,4,0,
780,17,421,4,0,
780,17,434,3,0,
780,17,447,4,0,
780,17,496,4,0,
780,17,497,1,1,2
780,17,497,4,0,
780,17,523,4,0,
780,17,525,4,0,
780,17,526,4,0,
780,17,542,2,0,
780,17,555,4,0,
780,17,583,2,0,
780,17,589,1,1,1
780,17,590,4,0,
780,18,13,2,0,
780,18,19,1,45,
780,18,19,4,0,
780,18,46,4,0,
780,18,53,4,0,
780,18,54,2,0,
780,18,57,4,0,
780,18,58,4,0,
780,18,59,4,0,
780,18,63,4,0,
780,18,76,4,0,
780,18,82,1,21,
780,18,85,4,0,
780,18,86,4,0,
780,18,87,4,0,
780,18,89,4,0,
780,18,92,4,0,
780,18,104,4,0,
780,18,113,1,17,
780,18,113,4,0,
780,18,126,4,0,
780,18,137,1,13,
780,18,156,4,0,
780,18,157,4,0,
780,18,164,4,0,
780,18,182,1,9,
780,18,182,4,0,
780,18,200,1,53,
780,18,211,4,0,
780,18,213,4,0,
780,18,214,4,0,
780,18,216,4,0,
780,18,218,4,0,
780,18,219,1,33,
780,18,219,4,0,
780,18,225,1,29,
780,18,237,4,0,
780,18,239,1,5,
780,18,240,4,0,
780,18,241,4,0,
780,18,244,4,0,
780,18,247,4,0,
780,18,263,4,0,
780,18,267,4,0,
780,18,304,1,49,
780,18,326,1,37,
780,18,337,4,0,
780,18,347,4,0,
780,18,355,4,0,
780,18,363,1,25,
780,18,374,4,0,
780,18,406,1,41,
780,18,407,2,0,
780,18,411,4,0,
780,18,412,4,0,
780,18,416,4,0,
780,18,421,4,0,
780,18,434,3,0,
780,18,447,4,0,
780,18,496,4,0,
780,18,497,1,1,2
780,18,497,4,0,
780,18,523,4,0,
780,18,525,4,0,
780,18,526,4,0,
780,18,542,2,0,
780,18,555,4,0,
780,18,583,2,0,
780,18,589,1,1,1
780,18,590,4,0,
781,17,14,4,0,
781,17,21,1,45,
781,17,35,1,9,
781,17,57,4,0,
781,17,63,4,0,
781,17,71,1,1,2
781,17,72,1,5,
781,17,74,1,1,3
781,17,76,4,0,
781,17,89,4,0,
781,17,92,4,0,
781,17,104,4,0,
781,17,156,4,0,
781,17,157,4,0,
781,17,164,4,0,
781,17,168,4,0,
781,17,182,4,0,
781,17,202,1,23,
781,17,207,4,0,
781,17,213,4,0,
781,17,214,4,0,
781,17,216,4,0,
781,17,218,4,0,
781,17,229,1,1,4
781,17,237,4,0,
781,17,240,4,0,
781,17,241,4,0,
781,17,247,1,36,
781,17,247,4,0,
781,17,250,1,27,
781,17,263,4,0,
781,17,280,4,0,
781,17,310,1,1,5
781,17,319,1,18,
781,17,332,4,0,
781,17,360,1,14,
781,17,360,4,0,
781,17,371,4,0,
781,17,373,4,0,
781,17,412,1,41,
781,17,412,4,0,
781,17,415,1,1,1
781,17,416,4,0,
781,17,421,4,0,
781,17,430,4,0,
781,17,438,1,59,
781,17,447,4,0,
781,17,482,4,0,
781,17,484,1,50,
781,17,496,4,0,
781,17,523,4,0,
781,17,566,1,54,
781,17,590,4,0,
781,17,677,1,32,
781,17,693,4,0,
781,18,14,4,0,
781,18,21,1,45,
781,18,35,1,9,
781,18,57,4,0,
781,18,63,4,0,
781,18,71,1,1,2
781,18,72,1,5,
781,18,74,1,1,3
781,18,76,4,0,
781,18,89,4,0,
781,18,92,4,0,
781,18,104,4,0,
781,18,156,4,0,
781,18,157,4,0,
781,18,164,4,0,
781,18,168,4,0,
781,18,182,4,0,
781,18,202,1,23,
781,18,207,4,0,
781,18,213,4,0,
781,18,214,4,0,
781,18,216,4,0,
781,18,218,4,0,
781,18,229,1,1,4
781,18,237,4,0,
781,18,240,4,0,
781,18,241,4,0,
781,18,247,1,36,
781,18,247,4,0,
781,18,250,1,27,
781,18,263,4,0,
781,18,280,4,0,
781,18,310,1,1,5
781,18,319,1,18,
781,18,332,4,0,
781,18,360,1,14,
781,18,360,4,0,
781,18,371,4,0,
781,18,373,4,0,
781,18,412,1,41,
781,18,412,4,0,
781,18,415,1,1,1
781,18,416,4,0,
781,18,421,4,0,
781,18,430,4,0,
781,18,438,1,59,
781,18,447,4,0,
781,18,482,4,0,
781,18,484,1,50,
781,18,496,4,0,
781,18,523,4,0,
781,18,566,1,54,
781,18,590,4,0,
781,18,677,1,32,
781,18,693,4,0,
782,17,14,4,0,
782,17,29,1,25,
782,17,33,1,1,
782,17,43,1,5,
782,17,46,4,0,
782,17,68,2,0,
782,17,89,4,0,
782,17,92,4,0,
782,17,103,1,33,
782,17,104,4,0,
782,17,117,1,9,
782,17,156,4,0,
782,17,157,4,0,
782,17,164,4,0,
782,17,179,2,0,
782,17,182,1,13,
782,17,182,4,0,
782,17,184,1,21,
782,17,200,1,53,
782,17,201,4,0,
782,17,206,4,0,
782,17,207,4,0,
782,17,213,4,0,
782,17,214,4,0,
782,17,216,4,0,
782,17,218,4,0,
782,17,219,4,0,
782,17,225,2,0,
782,17,237,4,0,
782,17,263,4,0,
782,17,269,4,0,
782,17,280,4,0,
782,17,317,4,0,
782,17,332,4,0,
782,17,334,1,37,
782,17,337,1,41,
782,17,337,4,0,
782,17,339,4,0,
782,17,349,1,49,
782,17,371,4,0,
782,17,404,4,0,
782,17,411,4,0,
782,17,421,4,0,
782,17,434,3,0,
782,17,496,4,0,
782,17,497,4,0,
782,17,523,4,0,
782,17,525,1,17,
782,17,525,4,0,
782,17,526,1,29,
782,17,526,4,0,
782,17,568,1,45,
782,17,590,4,0,
782,18,14,4,0,
782,18,29,1,25,
782,18,33,1,1,
782,18,43,1,5,
782,18,46,4,0,
782,18,68,2,0,
782,18,89,4,0,
782,18,92,4,0,
782,18,103,1,33,
782,18,104,4,0,
782,18,117,1,9,
782,18,156,4,0,
782,18,157,4,0,
782,18,164,4,0,
782,18,179,2,0,
782,18,182,1,13,
782,18,182,4,0,
782,18,184,1,21,
782,18,200,1,53,
782,18,201,4,0,
782,18,206,4,0,
782,18,207,4,0,
782,18,213,4,0,
782,18,214,4,0,
782,18,216,4,0,
782,18,218,4,0,
782,18,219,4,0,
782,18,225,2,0,
782,18,237,4,0,
782,18,263,4,0,
782,18,269,4,0,
782,18,280,4,0,
782,18,317,4,0,
782,18,332,4,0,
782,18,334,1,37,
782,18,337,1,41,
782,18,337,4,0,
782,18,339,4,0,
782,18,349,1,49,
782,18,371,4,0,
782,18,404,4,0,
782,18,411,4,0,
782,18,421,4,0,
782,18,434,3,0,
782,18,496,4,0,
782,18,497,4,0,
782,18,523,4,0,
782,18,525,1,17,
782,18,525,4,0,
782,18,526,1,29,
782,18,526,4,0,
782,18,568,1,45,
782,18,590,4,0,
783,17,14,4,0,
783,17,29,1,25,
783,17,33,1,1,3
783,17,43,1,1,4
783,17,43,1,5,
783,17,46,4,0,
783,17,89,4,0,
783,17,92,4,0,
783,17,103,1,33,
783,17,104,4,0,
783,17,117,1,1,5
783,17,117,1,9,
783,17,156,4,0,
783,17,157,4,0,
783,17,164,4,0,
783,17,182,1,1,6
783,17,182,1,13,
783,17,182,4,0,
783,17,184,1,21,
783,17,200,1,58,
783,17,201,4,0,
783,17,206,4,0,
783,17,207,4,0,
783,17,213,4,0,
783,17,214,4,0,
783,17,216,4,0,
783,17,218,4,0,
783,17,219,4,0,
783,17,237,4,0,
783,17,263,4,0,
783,17,269,4,0,
783,17,280,4,0,
783,17,317,4,0,
783,17,327,1,0,
783,17,327,1,1,1
783,17,332,4,0,
783,17,334,1,38,
783,17,337,1,43,
783,17,337,4,0,
783,17,339,4,0,
783,17,349,1,53,
783,17,371,4,0,
783,17,374,4,0,
783,17,404,4,0,
783,17,411,4,0,
783,17,421,4,0,
783,17,434,3,0,
783,17,475,1,1,2
783,17,496,4,0,
783,17,497,4,0,
783,17,523,4,0,
783,17,525,1,17,
783,17,525,4,0,
783,17,526,1,29,
783,17,526,4,0,
783,17,568,1,48,
783,17,590,4,0,
783,17,693,4,0,
783,18,14,4,0,
783,18,29,1,25,
783,18,33,1,1,3
783,18,43,1,1,4
783,18,43,1,5,
783,18,46,4,0,
783,18,89,4,0,
783,18,92,4,0,
783,18,103,1,33,
783,18,104,4,0,
783,18,117,1,1,5
783,18,117,1,9,
783,18,156,4,0,
783,18,157,4,0,
783,18,164,4,0,
783,18,182,1,1,6
783,18,182,1,13,
783,18,182,4,0,
783,18,184,1,21,
783,18,200,1,58,
783,18,201,4,0,
783,18,206,4,0,
783,18,207,4,0,
783,18,213,4,0,
783,18,214,4,0,
783,18,216,4,0,
783,18,218,4,0,
783,18,219,4,0,
783,18,237,4,0,
783,18,263,4,0,
783,18,269,4,0,
783,18,280,4,0,
783,18,317,4,0,
783,18,327,1,0,
783,18,327,1,1,1
783,18,332,4,0,
783,18,334,1,38,
783,18,337,1,43,
783,18,337,4,0,
783,18,339,4,0,
783,18,349,1,53,
783,18,370,1,63,
783,18,371,4,0,
783,18,374,4,0,
783,18,404,4,0,
783,18,411,4,0,
783,18,421,4,0,
783,18,434,3,0,
783,18,475,1,1,2
783,18,496,4,0,
783,18,497,4,0,
783,18,523,4,0,
783,18,525,1,17,
783,18,525,4,0,
783,18,526,1,29,
783,18,526,4,0,
783,18,568,1,48,
783,18,590,4,0,
783,18,693,4,0,
784,17,14,4,0,
784,17,29,1,25,
784,17,33,1,1,5
784,17,43,1,1,6
784,17,43,1,5,
784,17,46,4,0,
784,17,53,4,0,
784,17,63,4,0,
784,17,89,4,0,
784,17,92,4,0,
784,17,103,1,33,
784,17,104,4,0,
784,17,117,1,1,7
784,17,117,1,9,
784,17,156,4,0,
784,17,157,4,0,
784,17,164,4,0,
784,17,182,1,1,8
784,17,182,1,13,
784,17,182,4,0,
784,17,184,1,21,
784,17,187,1,1,3
784,17,200,1,67,
784,17,201,4,0,
784,17,206,4,0,
784,17,207,4,0,
784,17,213,4,0,
784,17,214,4,0,
784,17,216,4,0,
784,17,218,4,0,
784,17,219,4,0,
784,17,237,4,0,
784,17,263,4,0,
784,17,269,4,0,
784,17,280,4,0,
784,17,317,4,0,
784,17,327,1,1,2
784,17,332,4,0,
784,17,334,1,38,
784,17,337,1,43,
784,17,337,4,0,
784,17,339,4,0,
784,17,349,1,59,
784,17,371,4,0,
784,17,374,4,0,
784,17,397,4,0,
784,17,398,4,0,
784,17,404,4,0,
784,17,411,4,0,
784,17,416,4,0,
784,17,421,4,0,
784,17,430,4,0,
784,17,434,3,0,
784,17,475,1,1,4
784,17,496,4,0,
784,17,497,4,0,
784,17,523,4,0,
784,17,525,1,17,
784,17,525,4,0,
784,17,526,1,29,
784,17,526,4,0,
784,17,568,1,51,
784,17,590,4,0,
784,17,691,1,0,
784,17,691,1,1,1
784,17,693,4,0,
784,18,14,4,0,
784,18,29,1,25,
784,18,33,1,1,5
784,18,43,1,1,6
784,18,43,1,5,
784,18,46,4,0,
784,18,53,4,0,
784,18,63,4,0,
784,18,89,4,0,
784,18,92,4,0,
784,18,103,1,33,
784,18,104,4,0,
784,18,117,1,1,7
784,18,117,1,9,
784,18,156,4,0,
784,18,157,4,0,
784,18,164,4,0,
784,18,182,1,1,8
784,18,182,1,13,
784,18,182,4,0,
784,18,184,1,21,
784,18,187,1,1,3
784,18,200,1,67,
784,18,201,4,0,
784,18,206,4,0,
784,18,207,4,0,
784,18,213,4,0,
784,18,214,4,0,
784,18,216,4,0,
784,18,218,4,0,
784,18,219,4,0,
784,18,237,4,0,
784,18,263,4,0,
784,18,269,4,0,
784,18,280,4,0,
784,18,317,4,0,
784,18,327,1,1,2
784,18,332,4,0,
784,18,334,1,38,
784,18,337,1,43,
784,18,337,4,0,
784,18,339,4,0,
784,18,349,1,59,
784,18,370,1,75,
784,18,371,4,0,
784,18,374,4,0,
784,18,397,4,0,
784,18,398,4,0,
784,18,404,4,0,
784,18,411,4,0,
784,18,416,4,0,
784,18,421,4,0,
784,18,430,4,0,
784,18,434,3,0,
784,18,475,1,1,4
784,18,496,4,0,
784,18,497,4,0,
784,18,523,4,0,
784,18,525,1,17,
784,18,525,4,0,
784,18,526,1,29,
784,18,526,4,0,
784,18,568,1,51,
784,18,590,4,0,
784,18,691,1,0,
784,18,691,1,1,1
784,18,693,4,0,
785,17,19,4,0,
785,17,46,4,0,
785,17,63,4,0,
785,17,84,1,1,8
785,17,85,4,0,
785,17,86,4,0,
785,17,87,4,0,
785,17,92,4,0,
785,17,97,1,53,
785,17,98,1,1,5
785,17,103,1,20,
785,17,104,4,0,
785,17,110,1,1,7
785,17,113,4,0,
785,17,115,4,0,
785,17,119,1,38,
785,17,164,4,0,
785,17,168,4,0,
785,17,182,4,0,
785,17,206,1,1,6
785,17,206,4,0,
785,17,207,4,0,
785,17,209,1,8,
785,17,211,4,0,
785,17,212,1,1,4
785,17,214,4,0,
785,17,216,4,0,
785,17,218,4,0,
785,17,219,4,0,
785,17,237,4,0,
785,17,240,4,0,
785,17,244,4,0,
785,17,259,4,0,
785,17,263,4,0,
785,17,267,4,0,
785,17,268,1,26,
785,17,269,4,0,
785,17,332,4,0,
785,17,347,4,0,
785,17,351,1,14,
785,17,355,4,0,
785,17,369,4,0,
785,17,384,1,1,3
785,17,413,1,1,2
785,17,416,4,0,
785,17,435,1,48,
785,17,447,4,0,
785,17,486,1,58,
785,17,496,4,0,
785,17,497,4,0,
785,17,507,4,0,
785,17,512,4,0,
785,17,521,4,0,
785,17,526,4,0,
785,17,528,1,32,
785,17,528,4,0,
785,17,590,4,0,
785,17,604,1,1,1
785,17,605,4,0,
785,17,717,1,43,
785,18,19,4,0,
785,18,46,4,0,
785,18,63,4,0,
785,18,84,1,1,8
785,18,85,4,0,
785,18,86,4,0,
785,18,87,4,0,
785,18,92,4,0,
785,18,97,1,53,
785,18,98,1,1,5
785,18,103,1,20,
785,18,104,4,0,
785,18,110,1,1,7
785,18,113,4,0,
785,18,115,4,0,
785,18,119,1,38,
785,18,164,4,0,
785,18,168,4,0,
785,18,182,4,0,
785,18,206,1,1,6
785,18,206,4,0,
785,18,207,4,0,
785,18,209,1,8,
785,18,211,4,0,
785,18,212,1,1,4
785,18,214,4,0,
785,18,216,4,0,
785,18,218,4,0,
785,18,219,4,0,
785,18,237,4,0,
785,18,240,4,0,
785,18,244,4,0,
785,18,259,4,0,
785,18,263,4,0,
785,18,267,4,0,
785,18,268,1,26,
785,18,269,4,0,
785,18,332,4,0,
785,18,347,4,0,
785,18,351,1,14,
785,18,355,4,0,
785,18,369,4,0,
785,18,384,1,1,3
785,18,413,1,1,2
785,18,416,4,0,
785,18,435,1,48,
785,18,447,4,0,
785,18,486,1,58,
785,18,496,4,0,
785,18,497,4,0,
785,18,507,4,0,
785,18,512,4,0,
785,18,521,4,0,
785,18,526,4,0,
785,18,528,1,32,
785,18,528,4,0,
785,18,590,4,0,
785,18,604,1,1,1
785,18,605,4,0,
785,18,717,1,43,
786,17,60,1,14,
786,17,63,4,0,
786,17,85,4,0,
786,17,87,4,0,
786,17,92,4,0,
786,17,93,1,1,8
786,17,94,4,0,
786,17,104,4,0,
786,17,110,1,1,7
786,17,113,4,0,
786,17,115,4,0,
786,17,149,1,8,
786,17,164,4,0,
786,17,168,4,0,
786,17,182,4,0,
786,17,207,4,0,
786,17,212,1,1,4
786,17,214,4,0,
786,17,216,4,0,
786,17,218,4,0,
786,17,219,4,0,
786,17,230,1,20,
786,17,237,4,0,
786,17,241,4,0,
786,17,244,4,0,
786,17,247,4,0,
786,17,259,4,0,
786,17,260,1,53,
786,17,263,4,0,
786,17,267,4,0,
786,17,269,4,0,
786,17,285,1,26,
786,17,310,1,1,6
786,17,312,1,1,3
786,17,321,1,38,
786,17,326,1,48,
786,17,347,4,0,
786,17,374,4,0,
786,17,411,4,0,
786,17,412,4,0,
786,17,416,4,0,
786,17,447,4,0,
786,17,451,4,0,
786,17,473,1,32,
786,17,473,4,0,
786,17,496,4,0,
786,17,497,4,0,
786,17,577,1,1,5
786,17,585,1,58,
786,17,590,4,0,
786,17,597,1,1,2
786,17,605,4,0,
786,17,678,1,1,1
786,17,717,1,43,
786,18,60,1,14,
786,18,63,4,0,
786,18,85,4,0,
786,18,87,4,0,
786,18,92,4,0,
786,18,93,1,1,8
786,18,94,4,0,
786,18,104,4,0,
786,18,110,1,1,7
786,18,113,4,0,
786,18,115,4,0,
786,18,149,1,8,
786,18,164,4,0,
786,18,168,4,0,
786,18,182,4,0,
786,18,207,4,0,
786,18,212,1,1,4
786,18,214,4,0,
786,18,216,4,0,
786,18,218,4,0,
786,18,219,4,0,
786,18,230,1,20,
786,18,237,4,0,
786,18,241,4,0,
786,18,244,4,0,
786,18,247,4,0,
786,18,259,4,0,
786,18,260,1,53,
786,18,263,4,0,
786,18,267,4,0,
786,18,269,4,0,
786,18,285,1,26,
786,18,310,1,1,6
786,18,312,1,1,3
786,18,321,1,38,
786,18,326,1,48,
786,18,347,4,0,
786,18,374,4,0,
786,18,411,4,0,
786,18,412,4,0,
786,18,416,4,0,
786,18,447,4,0,
786,18,451,4,0,
786,18,473,1,32,
786,18,473,4,0,
786,18,496,4,0,
786,18,497,4,0,
786,18,577,1,1,5
786,18,585,1,58,
786,18,590,4,0,
786,18,597,1,1,2
786,18,605,4,0,
786,18,678,1,1,1
786,18,717,1,43,
787,17,14,4,0,
787,17,18,1,1,6
787,17,30,1,8,
787,17,46,4,0,
787,17,50,1,1,5
787,17,63,4,0,
787,17,73,1,26,
787,17,76,4,0,
787,17,92,4,0,
787,17,110,1,1,7
787,17,113,4,0,
787,17,115,4,0,
787,17,130,1,58,
787,17,157,4,0,
787,17,164,4,0,
787,17,182,4,0,
787,17,184,1,20,
787,17,202,1,14,
787,17,206,4,0,
787,17,207,4,0,
787,17,212,1,1,4
787,17,214,4,0,
787,17,216,4,0,
787,17,218,4,0,
787,17,219,4,0,
787,17,224,1,53,
787,17,237,4,0,
787,17,241,4,0,
787,17,244,4,0,
787,17,259,4,0,
787,17,263,4,0,
787,17,267,4,0,
787,17,269,4,0,
787,17,276,1,1,3
787,17,280,4,0,
787,17,317,4,0,
787,17,339,4,0,
787,17,347,4,0,
787,17,371,4,0,
787,17,374,4,0,
787,17,411,4,0,
787,17,412,4,0,
787,17,416,4,0,
787,17,428,1,48,
787,17,444,4,0,
787,17,447,4,0,
787,17,452,1,1,2
787,17,496,4,0,
787,17,497,4,0,
787,17,526,4,0,
787,17,532,1,32,
787,17,555,4,0,
787,17,563,1,38,
787,17,580,1,1,1
787,17,590,4,0,
787,17,605,4,0,
787,17,670,1,1,8
787,17,684,4,0,
787,17,693,4,0,
787,17,717,1,43,
787,18,14,4,0,
787,18,18,1,1,6
787,18,30,1,8,
787,18,46,4,0,
787,18,50,1,1,5
787,18,63,4,0,
787,18,73,1,26,
787,18,76,4,0,
787,18,92,4,0,
787,18,110,1,1,7
787,18,113,4,0,
787,18,115,4,0,
787,18,130,1,58,
787,18,157,4,0,
787,18,164,4,0,
787,18,182,4,0,
787,18,184,1,20,
787,18,202,1,14,
787,18,206,4,0,
787,18,207,4,0,
787,18,212,1,1,4
787,18,214,4,0,
787,18,216,4,0,
787,18,218,4,0,
787,18,219,4,0,
787,18,224,1,53,
787,18,237,4,0,
787,18,241,4,0,
787,18,244,4,0,
787,18,259,4,0,
787,18,263,4,0,
787,18,267,4,0,
787,18,269,4,0,
787,18,276,1,1,3
787,18,280,4,0,
787,18,317,4,0,
787,18,339,4,0,
787,18,347,4,0,
787,18,371,4,0,
787,18,374,4,0,
787,18,411,4,0,
787,18,412,4,0,
787,18,416,4,0,
787,18,428,1,48,
787,18,444,4,0,
787,18,447,4,0,
787,18,452,1,1,2
787,18,496,4,0,
787,18,497,4,0,
787,18,526,4,0,
787,18,532,1,32,
787,18,555,4,0,
787,18,563,1,38,
787,18,580,1,1,1
787,18,590,4,0,
787,18,605,4,0,
787,18,670,1,1,8
787,18,684,4,0,
787,18,693,4,0,
787,18,717,1,43,
788,17,54,1,1,6
788,17,55,1,1,8
788,17,56,1,58,
788,17,57,4,0,
788,17,58,4,0,
788,17,59,4,0,
788,17,63,4,0,
788,17,92,4,0,
788,17,104,4,0,
788,17,110,1,1,7
788,17,113,4,0,
788,17,114,1,1,5
788,17,115,4,0,
788,17,127,4,0,
788,17,164,4,0,
788,17,182,4,0,
788,17,207,4,0,
788,17,212,1,1,4
788,17,214,4,0,
788,17,216,4,0,
788,17,218,4,0,
788,17,219,4,0,
788,17,237,4,0,
788,17,240,4,0,
788,17,244,4,0,
788,17,247,4,0,
788,17,250,1,14,
788,17,259,4,0,
788,17,263,4,0,
788,17,267,4,0,
788,17,269,4,0,
788,17,287,1,26,
788,17,330,1,48,
788,17,347,4,0,
788,17,352,1,8,
788,17,362,1,32,
788,17,374,4,0,
788,17,392,1,53,
788,17,416,4,0,
788,17,432,1,38,
788,17,447,4,0,
788,17,487,1,20,
788,17,496,4,0,
788,17,497,4,0,
788,17,503,4,0,
788,17,505,1,1,3
788,17,581,1,1,1
788,17,585,1,1,2
788,17,590,4,0,
788,17,605,4,0,
788,17,684,4,0,
788,17,717,1,43,
788,18,54,1,1,6
788,18,55,1,1,8
788,18,56,1,58,
788,18,57,4,0,
788,18,58,4,0,
788,18,59,4,0,
788,18,63,4,0,
788,18,92,4,0,
788,18,104,4,0,
788,18,110,1,1,7
788,18,113,4,0,
788,18,114,1,1,5
788,18,115,4,0,
788,18,127,4,0,
788,18,164,4,0,
788,18,182,4,0,
788,18,207,4,0,
788,18,212,1,1,4
788,18,214,4,0,
788,18,216,4,0,
788,18,218,4,0,
788,18,219,4,0,
788,18,237,4,0,
788,18,240,4,0,
788,18,244,4,0,
788,18,247,4,0,
788,18,250,1,14,
788,18,259,4,0,
788,18,263,4,0,
788,18,267,4,0,
788,18,269,4,0,
788,18,287,1,26,
788,18,330,1,48,
788,18,347,4,0,
788,18,352,1,8,
788,18,362,1,32,
788,18,374,4,0,
788,18,392,1,53,
788,18,416,4,0,
788,18,432,1,38,
788,18,447,4,0,
788,18,487,1,20,
788,18,496,4,0,
788,18,497,4,0,
788,18,503,4,0,
788,18,505,1,1,3
788,18,581,1,1,1
788,18,585,1,1,2
788,18,590,4,0,
788,18,605,4,0,
788,18,684,4,0,
788,18,717,1,43,
789,17,100,1,23,
789,17,150,1,1,
789,18,100,1,23,
789,18,150,1,1,
790,17,100,1,1,2
790,17,322,1,0,
790,17,322,1,1,1
790,18,100,1,1,2
790,18,322,1,0,
790,18,322,1,1,1
791,17,46,4,0,
791,17,53,4,0,
791,17,63,4,0,
791,17,76,1,47,
791,17,76,4,0,
791,17,85,4,0,
791,17,86,4,0,
791,17,87,4,0,
791,17,89,4,0,
791,17,92,4,0,
791,17,94,4,0,
791,17,100,1,1,4
791,17,104,4,0,
791,17,113,4,0,
791,17,115,4,0,
791,17,126,4,0,
791,17,156,4,0,
791,17,157,4,0,
791,17,164,4,0,
791,17,182,4,0,
791,17,207,4,0,
791,17,214,4,0,
791,17,216,4,0,
791,17,218,4,0,
791,17,219,4,0,
791,17,232,1,1,5
791,17,234,1,31,
791,17,237,4,0,
791,17,241,4,0,
791,17,242,1,37,
791,17,244,4,0,
791,17,263,4,0,
791,17,317,4,0,
791,17,319,1,13,
791,17,322,1,1,2
791,17,347,4,0,
791,17,358,1,1,3
791,17,360,4,0,
791,17,368,1,43,
791,17,394,1,61,
791,17,411,4,0,
791,17,416,1,73,
791,17,416,4,0,
791,17,428,1,19,
791,17,430,1,23,
791,17,430,4,0,
791,17,433,4,0,
791,17,442,1,7,
791,17,444,4,0,
791,17,469,1,67,
791,17,473,4,0,
791,17,488,4,0,
791,17,496,4,0,
791,17,523,4,0,
791,17,526,4,0,
791,17,528,4,0,
791,17,555,4,0,
791,17,568,1,59,
791,17,590,4,0,
791,17,713,1,0,
791,17,713,1,1,1
791,18,46,4,0,
791,18,53,4,0,
791,18,63,4,0,
791,18,76,1,47,
791,18,76,4,0,
791,18,85,4,0,
791,18,86,4,0,
791,18,87,4,0,
791,18,89,4,0,
791,18,92,4,0,
791,18,94,4,0,
791,18,100,1,1,4
791,18,104,4,0,
791,18,113,4,0,
791,18,115,4,0,
791,18,126,4,0,
791,18,156,4,0,
791,18,157,4,0,
791,18,164,4,0,
791,18,182,4,0,
791,18,207,4,0,
791,18,214,4,0,
791,18,216,4,0,
791,18,218,4,0,
791,18,219,4,0,
791,18,232,1,1,5
791,18,234,1,31,
791,18,237,4,0,
791,18,241,4,0,
791,18,242,1,37,
791,18,244,4,0,
791,18,263,4,0,
791,18,317,4,0,
791,18,319,1,13,
791,18,322,1,1,2
791,18,347,4,0,
791,18,358,1,1,3
791,18,360,4,0,
791,18,368,1,43,
791,18,394,1,61,
791,18,411,4,0,
791,18,416,1,73,
791,18,416,4,0,
791,18,428,1,19,
791,18,430,1,23,
791,18,430,4,0,
791,18,433,4,0,
791,18,442,1,7,
791,18,444,4,0,
791,18,469,1,67,
791,18,473,4,0,
791,18,488,4,0,
791,18,496,4,0,
791,18,523,4,0,
791,18,526,4,0,
791,18,528,4,0,
791,18,555,4,0,
791,18,568,1,59,
791,18,590,4,0,
791,18,713,1,0,
791,18,713,1,1,1
792,17,19,4,0,
792,17,46,4,0,
792,17,58,4,0,
792,17,59,4,0,
792,17,63,1,73,
792,17,63,4,0,
792,17,76,4,0,
792,17,85,4,0,
792,17,86,4,0,
792,17,87,4,0,
792,17,92,4,0,
792,17,93,1,1,5
792,17,94,4,0,
792,17,95,1,1,3
792,17,100,1,1,4
792,17,101,1,7,
792,17,104,4,0,
792,17,109,1,13,
792,17,113,4,0,
792,17,115,4,0,
792,17,138,1,59,
792,17,138,4,0,
792,17,156,4,0,
792,17,164,4,0,
792,17,182,4,0,
792,17,207,4,0,
792,17,214,4,0,
792,17,216,4,0,
792,17,218,4,0,
792,17,219,4,0,
792,17,236,1,31,
792,17,237,4,0,
792,17,241,4,0,
792,17,244,4,0,
792,17,247,1,23,
792,17,247,4,0,
792,17,261,4,0,
792,17,263,4,0,
792,17,277,1,43,
792,17,322,1,1,2
792,17,332,4,0,
792,17,347,4,0,
792,17,355,4,0,
792,17,403,1,19,
792,17,411,4,0,
792,17,416,4,0,
792,17,421,4,0,
792,17,433,4,0,
792,17,451,4,0,
792,17,469,1,67,
792,17,473,4,0,
792,17,496,4,0,
792,17,507,4,0,
792,17,512,4,0,
792,17,526,4,0,
792,17,539,1,37,
792,17,566,1,61,
792,17,585,1,47,
792,17,590,4,0,
792,17,605,4,0,
792,17,714,1,0,
792,17,714,1,1,1
792,18,19,4,0,
792,18,46,4,0,
792,18,58,4,0,
792,18,59,4,0,
792,18,63,1,73,
792,18,63,4,0,
792,18,76,4,0,
792,18,85,4,0,
792,18,86,4,0,
792,18,87,4,0,
792,18,92,4,0,
792,18,93,1,1,5
792,18,94,4,0,
792,18,95,1,1,3
792,18,100,1,1,4
792,18,101,1,7,
792,18,104,4,0,
792,18,109,1,13,
792,18,113,4,0,
792,18,115,4,0,
792,18,138,1,59,
792,18,138,4,0,
792,18,156,4,0,
792,18,164,4,0,
792,18,182,4,0,
792,18,207,4,0,
792,18,214,4,0,
792,18,216,4,0,
792,18,218,4,0,
792,18,219,4,0,
792,18,236,1,31,
792,18,237,4,0,
792,18,241,4,0,
792,18,244,4,0,
792,18,247,1,23,
792,18,247,4,0,
792,18,261,4,0,
792,18,263,4,0,
792,18,277,1,43,
792,18,322,1,1,2
792,18,332,4,0,
792,18,347,4,0,
792,18,355,4,0,
792,18,403,1,19,
792,18,411,4,0,
792,18,416,4,0,
792,18,421,4,0,
792,18,433,4,0,
792,18,451,4,0,
792,18,469,1,67,
792,18,473,4,0,
792,18,496,4,0,
792,18,507,4,0,
792,18,512,4,0,
792,18,526,4,0,
792,18,539,1,37,
792,18,566,1,61,
792,18,585,1,47,
792,18,590,4,0,
792,18,605,4,0,
792,18,714,1,0,
792,18,714,1,1,1
793,17,1,1,1,6
793,17,29,1,19,
793,17,51,1,1,4
793,17,85,4,0,
793,17,86,4,0,
793,17,87,4,0,
793,17,92,4,0,
793,17,94,4,0,
793,17,104,4,0,
793,17,113,4,0,
793,17,115,4,0,
793,17,132,1,1,5
793,17,149,1,13,
793,17,156,4,0,
793,17,164,4,0,
793,17,182,4,0,
793,17,188,4,0,
793,17,201,4,0,
793,17,207,4,0,
793,17,214,4,0,
793,17,216,4,0,
793,17,218,4,0,
793,17,219,1,31,
793,17,219,4,0,
793,17,237,4,0,
793,17,243,1,43,
793,17,263,4,0,
793,17,321,1,1,3
793,17,390,1,29,
793,17,398,4,0,
793,17,408,1,37,
793,17,433,4,0,
793,17,446,1,59,
793,17,447,4,0,
793,17,451,4,0,
793,17,457,1,73,
793,17,470,1,1,2
793,17,471,1,1,1
793,17,472,1,67,
793,17,473,4,0,
793,17,474,1,23,
793,17,474,4,0,
793,17,482,4,0,
793,17,491,1,47,
793,17,496,4,0,
793,17,497,4,0,
793,17,499,1,7,
793,17,590,4,0,
793,17,599,1,53,
793,17,605,4,0,
793,17,693,4,0,
793,18,1,1,1,6
793,18,29,1,19,
793,18,51,1,1,4
793,18,85,4,0,
793,18,86,4,0,
793,18,87,4,0,
793,18,92,4,0,
793,18,94,4,0,
793,18,104,4,0,
793,18,113,4,0,
793,18,115,4,0,
793,18,132,1,1,5
793,18,149,1,13,
793,18,156,4,0,
793,18,164,4,0,
793,18,182,4,0,
793,18,188,4,0,
793,18,201,4,0,
793,18,207,4,0,
793,18,214,4,0,
793,18,216,4,0,
793,18,218,4,0,
793,18,219,1,31,
793,18,219,4,0,
793,18,237,4,0,
793,18,243,1,43,
793,18,263,4,0,
793,18,321,1,1,3
793,18,390,1,29,
793,18,398,4,0,
793,18,408,1,37,
793,18,433,4,0,
793,18,446,1,59,
793,18,447,4,0,
793,18,451,4,0,
793,18,457,1,73,
793,18,470,1,1,2
793,18,471,1,1,1
793,18,472,1,67,
793,18,473,4,0,
793,18,474,1,23,
793,18,474,4,0,
793,18,482,4,0,
793,18,491,1,47,
793,18,496,4,0,
793,18,497,4,0,
793,18,499,1,7,
793,18,590,4,0,
793,18,599,1,53,
793,18,605,4,0,
793,18,693,4,0,
794,17,4,1,7,
794,17,5,1,37,
794,17,8,1,1,3
794,17,9,1,1,2
794,17,68,1,43,
794,17,89,4,0,
794,17,92,4,0,
794,17,104,4,0,
794,17,106,1,1,5
794,17,116,1,1,7
794,17,141,1,29,
794,17,141,4,0,
794,17,156,4,0,
794,17,157,4,0,
794,17,164,4,0,
794,17,179,1,1,4
794,17,182,4,0,
794,17,203,1,23,
794,17,206,4,0,
794,17,207,4,0,
794,17,214,4,0,
794,17,216,4,0,
794,17,218,4,0,
794,17,223,1,59,
794,17,233,1,19,
794,17,237,4,0,
794,17,263,4,0,
794,17,264,1,73,
794,17,269,1,31,
794,17,269,4,0,
794,17,276,1,67,
794,17,280,4,0,
794,17,317,4,0,
794,17,339,1,13,
794,17,339,4,0,
794,17,355,4,0,
794,17,359,1,47,
794,17,360,4,0,
794,17,371,4,0,
794,17,374,4,0,
794,17,398,4,0,
794,17,416,4,0,
794,17,444,4,0,
794,17,479,4,0,
794,17,490,4,0,
794,17,496,4,0,
794,17,523,4,0,
794,17,526,4,0,
794,17,565,1,1,1
794,17,590,4,0,
794,17,612,1,1,6
794,17,679,1,53,
794,18,4,1,7,
794,18,5,1,37,
794,18,8,1,1,3
794,18,9,1,1,2
794,18,68,1,43,
794,18,89,4,0,
794,18,92,4,0,
794,18,104,4,0,
794,18,106,1,1,5
794,18,116,1,1,7
794,18,141,1,29,
794,18,141,4,0,
794,18,156,4,0,
794,18,157,4,0,
794,18,164,4,0,
794,18,179,1,1,4
794,18,182,4,0,
794,18,203,1,23,
794,18,206,4,0,
794,18,207,4,0,
794,18,214,4,0,
794,18,216,4,0,
794,18,218,4,0,
794,18,223,1,59,
794,18,233,1,19,
794,18,237,4,0,
794,18,263,4,0,
794,18,264,1,73,
794,18,269,1,31,
794,18,269,4,0,
794,18,276,1,67,
794,18,280,4,0,
794,18,317,4,0,
794,18,339,1,13,
794,18,339,4,0,
794,18,355,4,0,
794,18,359,1,47,
794,18,360,4,0,
794,18,371,4,0,
794,18,374,4,0,
794,18,398,4,0,
794,18,416,4,0,
794,18,444,4,0,
794,18,479,4,0,
794,18,490,4,0,
794,18,496,4,0,
794,18,523,4,0,
794,18,526,4,0,
794,18,565,1,1,1
794,18,590,4,0,
794,18,612,1,1,6
794,18,679,1,53,
795,17,23,1,13,
795,17,24,1,1,6
795,17,26,1,31,
795,17,43,1,1,5
795,17,58,4,0,
795,17,59,4,0,
795,17,63,4,0,
795,17,67,1,1,3
795,17,92,4,0,
795,17,97,1,37,
795,17,104,4,0,
795,17,129,1,7,
795,17,136,1,67,
795,17,156,4,0,
795,17,164,4,0,
795,17,167,1,43,
795,17,182,4,0,
795,17,206,4,0,
795,17,207,4,0,
795,17,214,4,0,
795,17,216,4,0,
795,17,218,4,0,
795,17,229,1,1,4
795,17,237,4,0,
795,17,259,4,0,
795,17,263,4,0,
795,17,269,4,0,
795,17,280,4,0,
795,17,318,1,23,
795,17,340,1,29,
795,17,355,4,0,
795,17,364,1,19,
795,17,369,4,0,
795,17,374,4,0,
795,17,382,1,59,
795,17,398,4,0,
795,17,405,1,53,
795,17,411,4,0,
795,17,416,4,0,
795,17,483,1,1,1
795,17,490,4,0,
795,17,496,4,0,
795,17,497,4,0,
795,17,501,1,1,2
795,17,590,4,0,
795,17,679,1,47,
795,17,683,1,73,
795,18,23,1,13,
795,18,24,1,1,6
795,18,26,1,31,
795,18,43,1,1,5
795,18,58,4,0,
795,18,59,4,0,
795,18,63,4,0,
795,18,67,1,1,3
795,18,92,4,0,
795,18,97,1,37,
795,18,104,4,0,
795,18,129,1,7,
795,18,136,1,67,
795,18,156,4,0,
795,18,164,4,0,
795,18,167,1,43,
795,18,182,4,0,
795,18,206,4,0,
795,18,207,4,0,
795,18,214,4,0,
795,18,216,4,0,
795,18,218,4,0,
795,18,229,1,1,4
795,18,237,4,0,
795,18,259,4,0,
795,18,263,4,0,
795,18,269,4,0,
795,18,280,4,0,
795,18,318,1,23,
795,18,340,1,29,
795,18,355,4,0,
795,18,364,1,19,
795,18,369,4,0,
795,18,374,4,0,
795,18,382,1,59,
795,18,398,4,0,
795,18,405,1,53,
795,18,411,4,0,
795,18,416,4,0,
795,18,483,1,1,1
795,18,490,4,0,
795,18,496,4,0,
795,18,497,4,0,
795,18,501,1,1,2
795,18,590,4,0,
795,18,679,1,47,
795,18,683,1,73,
796,17,9,1,23,
796,17,35,1,1,4
796,17,63,4,0,
796,17,76,4,0,
796,17,84,1,1,5
796,17,85,1,37,
796,17,85,4,0,
796,17,86,1,7,
796,17,86,4,0,
796,17,87,4,0,
796,17,92,4,0,
796,17,95,1,43,
796,17,104,4,0,
796,17,113,4,0,
796,17,115,4,0,
796,17,156,4,0,
796,17,164,4,0,
796,17,182,4,0,
796,17,192,1,73,
796,17,207,4,0,
796,17,209,1,1,2
796,17,214,4,0,
796,17,216,4,0,
796,17,218,4,0,
796,17,237,4,0,
796,17,240,4,0,
796,17,241,4,0,
796,17,263,4,0,
796,17,267,4,0,
796,17,268,1,1,3
796,17,275,1,19,
796,17,294,1,1,1
796,17,324,1,31,
796,17,347,4,0,
796,17,351,1,13,
796,17,374,4,0,
796,17,412,4,0,
796,17,416,4,0,
796,17,435,1,47,
796,17,438,1,59,
796,17,447,4,0,
796,17,451,4,0,
796,17,496,4,0,
796,17,521,4,0,
796,17,528,4,0,
796,17,569,1,67,
796,17,590,4,0,
796,17,598,1,29,
796,17,604,1,53,
796,17,605,4,0,
796,17,693,4,0,
796,18,9,1,23,
796,18,35,1,1,4
796,18,63,4,0,
796,18,76,4,0,
796,18,84,1,1,5
796,18,85,1,37,
796,18,85,4,0,
796,18,86,1,7,
796,18,86,4,0,
796,18,87,4,0,
796,18,92,4,0,
796,18,95,1,43,
796,18,104,4,0,
796,18,113,4,0,
796,18,115,4,0,
796,18,156,4,0,
796,18,164,4,0,
796,18,182,4,0,
796,18,192,1,73,
796,18,207,4,0,
796,18,209,1,1,2
796,18,214,4,0,
796,18,216,4,0,
796,18,218,4,0,
796,18,237,4,0,
796,18,240,4,0,
796,18,241,4,0,
796,18,263,4,0,
796,18,267,4,0,
796,18,268,1,1,3
796,18,275,1,19,
796,18,294,1,1,1
796,18,324,1,31,
796,18,347,4,0,
796,18,351,1,13,
796,18,374,4,0,
796,18,412,4,0,
796,18,416,4,0,
796,18,435,1,47,
796,18,438,1,59,
796,18,447,4,0,
796,18,451,4,0,
796,18,496,4,0,
796,18,521,4,0,
796,18,528,4,0,
796,18,569,1,67,
796,18,590,4,0,
796,18,598,1,29,
796,18,604,1,53,
796,18,605,4,0,
796,18,693,4,0,
797,17,19,4,0,
797,17,33,1,1,6
797,17,38,1,73,
797,17,53,4,0,
797,17,63,4,0,
797,17,71,1,1,4
797,17,72,1,13,
797,17,73,1,19,
797,17,76,4,0,
797,17,89,4,0,
797,17,92,4,0,
797,17,104,4,0,
797,17,106,1,1,5
797,17,126,4,0,
797,17,130,1,53,
797,17,153,4,0,
797,17,156,4,0,
797,17,157,4,0,
797,17,164,4,0,
797,17,182,4,0,
797,17,202,1,31,
797,17,207,4,0,
797,17,214,4,0,
797,17,216,4,0,
797,17,218,4,0,
797,17,237,4,0,
797,17,263,4,0,
797,17,275,1,1,3
797,17,319,1,23,
797,17,334,1,59,
797,17,360,4,0,
797,17,402,1,47,
797,17,403,1,1,2
797,17,412,4,0,
797,17,416,4,0,
797,17,430,1,37,
797,17,430,4,0,
797,17,442,1,29,
797,17,444,4,0,
797,17,447,4,0,
797,17,469,1,1,1
797,17,475,1,43,
797,17,479,1,7,
797,17,479,4,0,
797,17,484,1,67,
797,17,488,4,0,
797,17,496,4,0,
797,17,512,4,0,
797,17,523,4,0,
797,17,590,4,0,
797,17,684,4,0,
797,17,693,4,0,
797,18,19,4,0,
797,18,33,1,1,6
797,18,38,1,73,
797,18,53,4,0,
797,18,63,4,0,
797,18,71,1,1,4
797,18,72,1,13,
797,18,73,1,19,
797,18,76,4,0,
797,18,89,4,0,
797,18,92,4,0,
797,18,104,4,0,
797,18,106,1,1,5
797,18,126,4,0,
797,18,130,1,53,
797,18,153,4,0,
797,18,156,4,0,
797,18,157,4,0,
797,18,164,4,0,
797,18,182,4,0,
797,18,202,1,31,
797,18,207,4,0,
797,18,214,4,0,
797,18,216,4,0,
797,18,218,4,0,
797,18,237,4,0,
797,18,263,4,0,
797,18,275,1,1,3
797,18,319,1,23,
797,18,334,1,59,
797,18,360,4,0,
797,18,402,1,47,
797,18,403,1,1,2
797,18,412,4,0,
797,18,416,4,0,
797,18,430,1,37,
797,18,430,4,0,
797,18,442,1,29,
797,18,444,4,0,
797,18,447,4,0,
797,18,469,1,1,1
797,18,475,1,43,
797,18,479,1,7,
797,18,479,4,0,
797,18,484,1,67,
797,18,488,4,0,
797,18,496,4,0,
797,18,512,4,0,
797,18,523,4,0,
797,18,590,4,0,
797,18,684,4,0,
797,18,693,4,0,
798,17,12,1,73,
798,17,14,1,37,
798,17,14,4,0,
798,17,15,1,1,6
798,17,75,1,13,
798,17,92,4,0,
798,17,104,4,0,
798,17,156,4,0,
798,17,164,4,0,
798,17,182,4,0,
798,17,197,1,53,
798,17,206,1,7,
798,17,206,4,0,
798,17,207,4,0,
798,17,210,1,1,5
798,17,214,4,0,
798,17,216,4,0,
798,17,218,4,0,
798,17,235,1,19,
798,17,237,4,0,
798,17,280,4,0,
798,17,314,1,1,4
798,17,332,1,23,
798,17,332,4,0,
798,17,347,4,0,
798,17,348,1,43,
798,17,400,1,31,
798,17,403,1,59,
798,17,404,1,47,
798,17,404,4,0,
798,17,410,1,1,3
798,17,416,4,0,
798,17,427,1,67,
798,17,432,1,1,2
798,17,496,4,0,
798,17,533,1,1,1
798,17,590,4,0,
798,17,673,1,29,
798,17,684,4,0,
798,18,12,1,73,
798,18,14,1,37,
798,18,14,4,0,
798,18,15,1,1,6
798,18,75,1,13,
798,18,92,4,0,
798,18,104,4,0,
798,18,156,4,0,
798,18,164,4,0,
798,18,182,4,0,
798,18,197,1,53,
798,18,206,1,7,
798,18,206,4,0,
798,18,207,4,0,
798,18,210,1,1,5
798,18,214,4,0,
798,18,216,4,0,
798,18,218,4,0,
798,18,235,1,19,
798,18,237,4,0,
798,18,280,4,0,
798,18,314,1,1,4
798,18,332,1,23,
798,18,332,4,0,
798,18,347,4,0,
798,18,348,1,43,
798,18,400,1,31,
798,18,403,1,59,
798,18,404,1,47,
798,18,404,4,0,
798,18,410,1,1,3
798,18,416,4,0,
798,18,427,1,67,
798,18,432,1,1,2
798,18,496,4,0,
798,18,533,1,1,1
798,18,590,4,0,
798,18,673,1,29,
798,18,684,4,0,
799,17,23,1,7,
799,17,37,1,47,
799,17,44,1,1,6
799,17,53,4,0,
799,17,63,4,0,
799,17,82,1,1,5
799,17,89,4,0,
799,17,92,4,0,
799,17,104,4,0,
799,17,126,4,0,
799,17,156,4,0,
799,17,157,4,0,
799,17,164,4,0,
799,17,168,4,0,
799,17,182,4,0,
799,17,188,4,0,
799,17,214,4,0,
799,17,216,4,0,
799,17,218,4,0,
799,17,231,1,29,
799,17,237,4,0,
799,17,242,1,37,
799,17,254,1,1,4
799,17,256,1,1,3
799,17,263,4,0,
799,17,280,4,0,
799,17,317,4,0,
799,17,337,4,0,
799,17,359,1,43,
799,17,360,4,0,
799,17,371,4,0,
799,17,374,4,0,
799,17,378,1,67,
799,17,380,1,53,
799,17,398,4,0,
799,17,399,4,0,
799,17,407,1,73,
799,17,416,4,0,
799,17,421,4,0,
799,17,434,3,0,
799,17,444,4,0,
799,17,469,1,1,2
799,17,479,4,0,
799,17,482,4,0,
799,17,484,1,59,
799,17,496,4,0,
799,17,523,4,0,
799,17,525,1,23,
799,17,525,4,0,
799,17,537,1,19,
799,17,555,4,0,
799,17,562,1,1,1
799,17,693,1,13,
799,17,693,4,0,
799,17,707,1,31,
799,18,23,1,7,
799,18,37,1,47,
799,18,44,1,1,6
799,18,53,4,0,
799,18,63,4,0,
799,18,82,1,1,5
799,18,89,4,0,
799,18,92,4,0,
799,18,104,4,0,
799,18,126,4,0,
799,18,156,4,0,
799,18,157,4,0,
799,18,164,4,0,
799,18,168,4,0,
799,18,182,4,0,
799,18,188,4,0,
799,18,214,4,0,
799,18,216,4,0,
799,18,218,4,0,
799,18,231,1,29,
799,18,237,4,0,
799,18,242,1,37,
799,18,254,1,1,4
799,18,256,1,1,3
799,18,263,4,0,
799,18,280,4,0,
799,18,317,4,0,
799,18,337,4,0,
799,18,359,1,43,
799,18,360,4,0,
799,18,371,4,0,
799,18,374,4,0,
799,18,378,1,67,
799,18,380,1,53,
799,18,398,4,0,
799,18,399,4,0,
799,18,407,1,73,
799,18,416,4,0,
799,18,421,4,0,
799,18,434,3,0,
799,18,444,4,0,
799,18,469,1,1,2
799,18,479,4,0,
799,18,482,4,0,
799,18,484,1,59,
799,18,496,4,0,
799,18,523,4,0,
799,18,525,1,23,
799,18,525,4,0,
799,18,537,1,19,
799,18,555,4,0,
799,18,562,1,1,1
799,18,693,1,13,
799,18,693,4,0,
799,18,707,1,31,
800,17,14,4,0,
800,17,63,4,0,
800,17,76,4,0,
800,17,86,4,0,
800,17,89,4,0,
800,17,92,4,0,
800,17,93,1,1,6
800,17,94,4,0,
800,17,104,4,0,
800,17,113,4,0,
800,17,115,4,0,
800,17,156,4,0,
800,17,157,4,0,
800,17,163,1,7,
800,17,164,4,0,
800,17,168,4,0,
800,17,182,4,0,
800,17,207,4,0,
800,17,214,4,0,
800,17,216,4,0,
800,17,218,4,0,
800,17,232,1,1,5
800,17,234,1,1,2
800,17,236,1,1,1
800,17,237,4,0,
800,17,263,4,0,
800,17,280,4,0,
800,17,317,4,0,
800,17,332,4,0,
800,17,334,1,59,
800,17,347,4,0,
800,17,350,1,19,
800,17,356,1,31,
800,17,360,4,0,
800,17,373,4,0,
800,17,374,4,0,
800,17,378,1,67,
800,17,397,4,0,
800,17,399,4,0,
800,17,400,1,23,
800,17,404,4,0,
800,17,408,1,43,
800,17,416,4,0,
800,17,421,4,0,
800,17,427,1,37,
800,17,429,1,1,4
800,17,430,4,0,
800,17,433,4,0,
800,17,444,4,0,
800,17,446,1,53,
800,17,451,1,1,3
800,17,451,4,0,
800,17,473,4,0,
800,17,475,1,47,
800,17,496,4,0,
800,17,500,1,13,
800,17,523,4,0,
800,17,590,4,0,
800,17,684,4,0,
800,17,693,4,0,
800,17,711,1,73,
800,18,14,4,0,
800,18,63,4,0,
800,18,76,4,0,
800,18,86,4,0,
800,18,89,4,0,
800,18,92,4,0,
800,18,93,1,1,6
800,18,94,4,0,
800,18,104,4,0,
800,18,113,4,0,
800,18,115,4,0,
800,18,156,4,0,
800,18,157,4,0,
800,18,163,1,7,
800,18,164,4,0,
800,18,168,4,0,
800,18,182,4,0,
800,18,207,4,0,
800,18,214,4,0,
800,18,216,4,0,
800,18,218,4,0,
800,18,232,1,1,5
800,18,234,1,1,2
800,18,236,1,1,1
800,18,237,4,0,
800,18,263,4,0,
800,18,280,4,0,
800,18,317,4,0,
800,18,332,4,0,
800,18,334,1,59,
800,18,347,4,0,
800,18,350,1,19,
800,18,356,1,31,
800,18,360,4,0,
800,18,373,4,0,
800,18,374,4,0,
800,18,378,1,67,
800,18,397,4,0,
800,18,399,4,0,
800,18,400,1,23,
800,18,404,4,0,
800,18,408,1,43,
800,18,416,4,0,
800,18,421,4,0,
800,18,427,1,37,
800,18,429,1,1,4
800,18,430,4,0,
800,18,433,4,0,
800,18,444,4,0,
800,18,446,1,53,
800,18,451,1,1,3
800,18,451,4,0,
800,18,473,4,0,
800,18,475,1,47,
800,18,496,4,0,
800,18,500,1,13,
800,18,523,4,0,
800,18,590,4,0,
800,18,684,4,0,
800,18,693,4,0,
800,18,711,1,73,
800,18,722,1,50,
801,17,49,1,1,6
801,17,58,4,0,
801,17,60,1,1,8
801,17,62,1,17,
801,17,63,4,0,
801,17,76,4,0,
801,17,85,4,0,
801,17,86,4,0,
801,17,104,4,0,
801,17,111,1,1,7
801,17,113,4,0,
801,17,115,4,0,
801,17,153,4,0,
801,17,164,4,0,
801,17,170,1,33,
801,17,182,4,0,
801,17,206,4,0,
801,17,207,4,0,
801,17,216,4,0,
801,17,218,4,0,
801,17,220,1,65,
801,17,237,4,0,
801,17,247,4,0,
801,17,270,1,1,5
801,17,280,4,0,
801,17,334,1,57,
801,17,347,4,0,
801,17,360,4,0,
801,17,373,4,0,
801,17,376,1,97,
801,17,381,1,9,
801,17,391,1,89,
801,17,396,1,81,
801,17,411,4,0,
801,17,412,4,0,
801,17,416,4,0,
801,17,429,1,25,
801,17,430,1,41,
801,17,430,4,0,
801,17,433,4,0,
801,17,442,1,1,4
801,17,447,4,0,
801,17,451,4,0,
801,17,485,1,73,
801,17,496,4,0,
801,17,497,4,0,
801,17,508,1,1,3
801,17,521,4,0,
801,17,578,1,1,1
801,17,590,4,0,
801,17,605,4,0,
801,17,674,1,1,2
801,17,705,1,49,
801,18,49,1,1,6
801,18,58,4,0,
801,18,60,1,1,8
801,18,62,1,17,
801,18,63,4,0,
801,18,76,4,0,
801,18,85,4,0,
801,18,86,4,0,
801,18,104,4,0,
801,18,111,1,1,7
801,18,113,4,0,
801,18,115,4,0,
801,18,153,4,0,
801,18,164,4,0,
801,18,170,1,33,
801,18,182,4,0,
801,18,206,4,0,
801,18,207,4,0,
801,18,216,4,0,
801,18,218,4,0,
801,18,220,1,65,
801,18,237,4,0,
801,18,247,4,0,
801,18,270,1,1,5
801,18,280,4,0,
801,18,334,1,57,
801,18,347,4,0,
801,18,360,4,0,
801,18,373,4,0,
801,18,376,1,97,
801,18,381,1,9,
801,18,391,1,89,
801,18,396,1,81,
801,18,411,4,0,
801,18,412,4,0,
801,18,416,4,0,
801,18,429,1,25,
801,18,430,1,41,
801,18,430,4,0,
801,18,433,4,0,
801,18,442,1,1,4
801,18,447,4,0,
801,18,451,4,0,
801,18,485,1,73,
801,18,496,4,0,
801,18,497,4,0,
801,18,508,1,1,3
801,18,521,4,0,
801,18,578,1,1,1
801,18,590,4,0,
801,18,605,4,0,
801,18,674,1,1,2
801,18,705,1,49,
802,17,7,1,1,3
802,17,8,1,1,5
802,17,9,1,1,4
802,17,26,1,35,
802,17,27,1,15,
802,17,68,1,1,7
802,17,92,4,0,
802,17,104,4,0,
802,17,156,4,0,
802,17,157,4,0,
802,17,164,4,0,
802,17,168,4,0,
802,17,182,4,0,
802,17,206,4,0,
802,17,207,4,0,
802,17,214,4,0,
802,17,216,4,0,
802,17,218,4,0,
802,17,228,1,1,8
802,17,237,4,0,
802,17,244,1,41,
802,17,244,4,0,
802,17,247,4,0,
802,17,261,4,0,
802,17,263,4,0,
802,17,272,1,30,
802,17,280,4,0,
802,17,283,1,60,
802,17,317,4,0,
802,17,325,1,26,
802,17,339,4,0,
802,17,347,4,0,
802,17,364,1,11,
802,17,370,1,50,
802,17,371,4,0,
802,17,372,1,1,2
802,17,374,4,0,
802,17,383,1,20,
802,17,389,1,56,
802,17,395,1,5,
802,17,398,4,0,
802,17,409,1,1,6
802,17,411,4,0,
802,17,416,4,0,
802,17,421,4,0,
802,17,425,1,1,9
802,17,444,4,0,
802,17,447,4,0,
802,17,479,4,0,
802,17,490,4,0,
802,17,496,4,0,
802,17,497,4,0,
802,17,512,4,0,
802,17,526,4,0,
802,17,590,4,0,
802,17,673,1,1,1
802,17,712,1,45,
802,18,7,1,1,3
802,18,8,1,1,5
802,18,9,1,1,4
802,18,26,1,35,
802,18,27,1,15,
802,18,68,1,1,7
802,18,92,4,0,
802,18,104,4,0,
802,18,156,4,0,
802,18,157,4,0,
802,18,164,4,0,
802,18,168,4,0,
802,18,182,4,0,
802,18,206,4,0,
802,18,207,4,0,
802,18,214,4,0,
802,18,216,4,0,
802,18,218,4,0,
802,18,228,1,1,8
802,18,237,4,0,
802,18,244,1,41,
802,18,244,4,0,
802,18,247,4,0,
802,18,261,4,0,
802,18,263,4,0,
802,18,272,1,30,
802,18,280,4,0,
802,18,283,1,60,
802,18,317,4,0,
802,18,325,1,26,
802,18,339,4,0,
802,18,347,4,0,
802,18,364,1,11,
802,18,370,1,50,
802,18,371,4,0,
802,18,372,1,1,2
802,18,374,4,0,
802,18,383,1,20,
802,18,389,1,56,
802,18,395,1,5,
802,18,398,4,0,
802,18,409,1,1,6
802,18,411,4,0,
802,18,416,4,0,
802,18,421,4,0,
802,18,425,1,1,9
802,18,444,4,0,
802,18,447,4,0,
802,18,479,4,0,
802,18,490,4,0,
802,18,496,4,0,
802,18,497,4,0,
802,18,512,4,0,
802,18,526,4,0,
802,18,590,4,0,
802,18,673,1,1,1
802,18,712,1,45,
803,18,31,1,7,
803,18,45,1,1,3
803,18,51,1,1,5
803,18,64,1,1,2
803,18,92,1,41,
803,18,92,4,0,
803,18,156,4,0,
803,18,164,4,0,
803,18,182,4,0,
803,18,188,4,0,
803,18,204,1,19,
803,18,214,4,0,
803,18,216,4,0,
803,18,218,4,0,
803,18,237,4,0,
803,18,263,4,0,
803,18,270,1,1,4
803,18,398,1,37,
803,18,398,4,0,
803,18,406,1,1,1
803,18,417,1,31,
803,18,474,1,13,
803,18,474,4,0,
803,18,482,4,0,
803,18,496,4,0,
803,18,497,4,0,
803,18,565,1,47,
803,18,590,4,0,
803,18,599,1,23,
804,18,19,4,0,
804,18,31,1,7,
804,18,45,1,1,4
804,18,51,1,1,6
804,18,53,4,0,
804,18,63,4,0,
804,18,64,1,1,3
804,18,85,4,0,
804,18,92,1,41,
804,18,92,4,0,
804,18,104,4,0,
804,18,126,4,0,
804,18,141,4,0,
804,18,156,4,0,
804,18,164,4,0,
804,18,168,4,0,
804,18,182,4,0,
804,18,188,4,0,
804,18,204,1,19,
804,18,214,4,0,
804,18,216,4,0,
804,18,218,4,0,
804,18,237,4,0,
804,18,263,4,0,
804,18,270,1,1,5
804,18,314,1,0,
804,18,314,1,1,1
804,18,332,4,0,
804,18,337,4,0,
804,18,369,4,0,
804,18,398,1,37,
804,18,398,4,0,
804,18,399,4,0,
804,18,403,1,53,
804,18,404,4,0,
804,18,406,1,1,2
804,18,406,1,61,
804,18,417,1,31,
804,18,421,4,0,
804,18,434,3,0,
804,18,474,1,13,
804,18,474,4,0,
804,18,482,4,0,
804,18,496,4,0,
804,18,497,4,0,
804,18,507,4,0,
804,18,512,4,0,
804,18,525,4,0,
804,18,555,4,0,
804,18,565,1,47,
804,18,590,4,0,
804,18,599,1,23,
804,18,684,4,0,
805,18,33,1,1,2
805,18,36,1,19,
805,18,38,1,61,
805,18,88,1,23,
805,18,89,4,0,
805,18,92,4,0,
805,18,113,4,0,
805,18,115,4,0,
805,18,117,1,17,
805,18,156,4,0,
805,18,157,1,5,
805,18,157,4,0,
805,18,164,4,0,
805,18,182,1,1,1
805,18,182,4,0,
805,18,201,4,0,
805,18,214,4,0,
805,18,216,4,0,
805,18,218,4,0,
805,18,219,4,0,
805,18,237,4,0,
805,18,263,4,0,
805,18,317,4,0,
805,18,334,1,37,
805,18,350,1,47,
805,18,360,4,0,
805,18,397,4,0,
805,18,416,4,0,
805,18,430,4,0,
805,18,433,4,0,
805,18,442,1,43,
805,18,444,4,0,
805,18,446,1,11,
805,18,469,1,53,
805,18,475,1,31,
805,18,479,4,0,
805,18,496,4,0,
805,18,523,4,0,
805,18,611,4,0,
805,18,693,4,0,
806,18,52,1,1,1
806,18,53,4,0,
806,18,63,4,0,
806,18,92,4,0,
806,18,94,4,0,
806,18,101,1,23,
806,18,104,4,0,
806,18,113,1,29,
806,18,113,4,0,
806,18,126,1,37,
806,18,126,4,0,
806,18,153,4,0,
806,18,156,4,0,
806,18,164,4,0,
806,18,168,4,0,
806,18,182,4,0,
806,18,207,4,0,
806,18,214,4,0,
806,18,216,4,0,
806,18,218,4,0,
806,18,237,4,0,
806,18,241,4,0,
806,18,247,1,41,
806,18,247,4,0,
806,18,259,4,0,
806,18,261,4,0,
806,18,263,4,0,
806,18,269,4,0,
806,18,271,1,47,
806,18,277,1,7,
806,18,310,1,1,2
806,18,315,4,0,
806,18,347,1,31,
806,18,347,4,0,
806,18,371,4,0,
806,18,374,4,0,
806,18,399,4,0,
806,18,421,4,0,
806,18,473,4,0,
806,18,479,4,0,
806,18,481,1,17,
806,18,488,4,0,
806,18,496,4,0,
806,18,500,1,13,
806,18,511,4,0,
806,18,590,4,0,
806,18,720,1,59,
807,18,9,1,29,
807,18,10,1,1,1
807,18,85,4,0,
807,18,86,4,0,
807,18,87,4,0,
807,18,92,4,0,
807,18,98,1,8,
807,18,104,4,0,
807,18,154,1,12,
807,18,156,4,0,
807,18,163,1,33,
807,18,164,4,0,
807,18,182,4,0,
807,18,206,4,0,
807,18,209,1,1,2
807,18,214,4,0,
807,18,216,4,0,
807,18,218,4,0,
807,18,237,4,0,
807,18,252,1,22,
807,18,263,4,0,
807,18,268,1,26,
807,18,269,4,0,
807,18,280,4,0,
807,18,332,4,0,
807,18,339,4,0,
807,18,347,4,0,
807,18,370,1,47,
807,18,374,4,0,
807,18,411,4,0,
807,18,416,4,0,
807,18,435,1,50,
807,18,447,4,0,
807,18,468,1,5,
807,18,490,4,0,
807,18,496,4,0,
807,18,497,4,0,
807,18,501,1,40,
807,18,512,4,0,
807,18,521,1,15,
807,18,521,4,0,
807,18,526,4,0,
807,18,528,1,36,
807,18,528,4,0,
807,18,555,1,19,
807,18,555,4,0,
807,18,590,4,0,
807,18,693,4,0,
807,18,721,1,43,
10001,7,5,3,0,
10001,7,15,4,0,
10001,7,25,3,0,
10001,7,34,3,0,
10001,7,35,1,1,2
10001,7,38,3,0,
10001,7,43,1,1,1
10001,7,58,4,0,
10001,7,63,1,50,
10001,7,63,4,0,
10001,7,68,3,0,
10001,7,69,3,0,
10001,7,70,4,0,
10001,7,76,4,0,
10001,7,85,4,0,
10001,7,86,3,0,
10001,7,87,4,0,
10001,7,92,4,0,
10001,7,94,1,25,
10001,7,94,4,0,
10001,7,100,1,10,
10001,7,101,1,5,
10001,7,102,3,0,
10001,7,104,4,0,
10001,7,113,4,0,
10001,7,115,4,0,
10001,7,138,3,0,
10001,7,148,4,0,
10001,7,156,4,0,
10001,7,157,3,0,
10001,7,164,3,0,
10001,7,182,4,0,
10001,7,192,1,40,
10001,7,216,4,0,
10001,7,218,4,0,
10001,7,219,4,0,
10001,7,228,1,20,
10001,7,237,4,0,
10001,7,240,4,0,
10001,7,241,4,0,
10001,7,247,4,0,
10001,7,249,4,0,
10001,7,259,4,0,
10001,7,263,4,0,
10001,7,264,4,0,
10001,7,269,1,15,
10001,7,269,4,0,
10001,7,276,1,30,
10001,7,280,4,0,
10001,7,285,4,0,
10001,7,289,4,0,
10001,7,290,4,0,
10001,7,317,4,0,
10001,7,322,1,35,
10001,7,332,4,0,
10001,7,347,4,0,
10001,7,351,4,0,
10001,7,352,4,0,
10001,7,354,1,45,
10001,8,15,4,0,
10001,8,35,1,1,2
10001,8,43,1,1,1
10001,8,58,4,0,
10001,8,63,1,97,
10001,8,63,4,0,
10001,8,70,4,0,
10001,8,76,4,0,
10001,8,85,4,0,
10001,8,86,4,0,
10001,8,87,4,0,
10001,8,92,4,0,
10001,8,94,1,41,
10001,8,94,4,0,
10001,8,100,1,17,
10001,8,101,1,9,
10001,8,104,4,0,
10001,8,113,4,0,
10001,8,115,4,0,
10001,8,138,4,0,
10001,8,148,4,0,
10001,8,156,4,0,
10001,8,157,4,0,
10001,8,164,4,0,
10001,8,182,4,0,
10001,8,192,1,81,
10001,8,203,4,0,
10001,8,207,4,0,
10001,8,214,4,0,
10001,8,216,4,0,
10001,8,218,4,0,
10001,8,219,4,0,
10001,8,228,1,33,
10001,8,237,4,0,
10001,8,240,4,0,
10001,8,241,4,0,
10001,8,244,4,0,
10001,8,247,4,0,
10001,8,249,4,0,
10001,8,259,4,0,
10001,8,263,4,0,
10001,8,264,4,0,
10001,8,269,1,25,
10001,8,269,4,0,
10001,8,276,1,49,
10001,8,278,4,0,
10001,8,280,4,0,
10001,8,285,4,0,
10001,8,289,4,0,
10001,8,290,4,0,
10001,8,317,4,0,
10001,8,322,1,73,
10001,8,332,4,0,
10001,8,347,4,0,
10001,8,351,4,0,
10001,8,352,4,0,
10001,8,354,1,89,
10001,8,363,4,0,
10001,8,374,4,0,
10001,8,375,1,57,
10001,8,398,4,0,
10001,8,409,4,0,
10001,8,411,4,0,
10001,8,412,4,0,
10001,8,416,4,0,
10001,8,419,4,0,
10001,8,428,1,65,
10001,8,430,4,0,
10001,8,433,4,0,
10001,8,446,4,0,
10001,8,447,4,0,
10001,8,451,4,0,
10001,9,15,4,0,
10001,9,35,1,1,2
10001,9,43,1,1,1
10001,9,58,4,0,
10001,9,63,1,97,
10001,9,63,4,0,
10001,9,70,4,0,
10001,9,76,4,0,
10001,9,85,4,0,
10001,9,86,4,0,
10001,9,87,4,0,
10001,9,92,4,0,
10001,9,94,1,41,
10001,9,94,4,0,
10001,9,100,1,17,
10001,9,101,1,9,
10001,9,104,4,0,
10001,9,113,4,0,
10001,9,115,4,0,
10001,9,138,4,0,
10001,9,148,4,0,
10001,9,156,4,0,
10001,9,157,4,0,
10001,9,164,4,0,
10001,9,173,3,0,
10001,9,182,4,0,
10001,9,189,3,0,
10001,9,192,1,81,
10001,9,203,4,0,
10001,9,207,4,0,
10001,9,214,4,0,
10001,9,216,4,0,
10001,9,218,4,0,
10001,9,219,4,0,
10001,9,228,1,33,
10001,9,237,4,0,
10001,9,240,4,0,
10001,9,241,4,0,
10001,9,244,4,0,
10001,9,247,4,0,
10001,9,249,4,0,
10001,9,259,4,0,
10001,9,263,4,0,
10001,9,264,4,0,
10001,9,269,1,25,
10001,9,269,4,0,
10001,9,271,3,0,
10001,9,276,1,49,
10001,9,276,3,0,
10001,9,278,4,0,
10001,9,280,4,0,
10001,9,285,4,0,
10001,9,289,4,0,
10001,9,290,4,0,
10001,9,317,4,0,
10001,9,322,1,73,
10001,9,324,3,0,
10001,9,332,4,0,
10001,9,347,4,0,
10001,9,351,4,0,
10001,9,352,4,0,
10001,9,354,1,89,
10001,9,363,4,0,
10001,9,374,4,0,
10001,9,375,1,57,
10001,9,398,4,0,
10001,9,409,4,0,
10001,9,411,4,0,
10001,9,412,4,0,
10001,9,416,4,0,
10001,9,419,4,0,
10001,9,428,1,65,
10001,9,428,3,0,
10001,9,430,4,0,
10001,9,433,4,0,
10001,9,446,4,0,
10001,9,447,4,0,
10001,9,451,4,0,
10001,10,15,4,0,
10001,10,29,3,0,
10001,10,35,1,1,2
10001,10,43,1,1,1
10001,10,58,4,0,
10001,10,63,1,97,
10001,10,63,4,0,
10001,10,67,3,0,
10001,10,70,4,0,
10001,10,76,4,0,
10001,10,85,4,0,
10001,10,86,4,0,
10001,10,87,4,0,
10001,10,92,4,0,
10001,10,94,1,41,
10001,10,94,4,0,
10001,10,100,1,17,
10001,10,101,1,9,
10001,10,104,4,0,
10001,10,113,4,0,
10001,10,115,4,0,
10001,10,138,4,0,
10001,10,148,4,0,
10001,10,156,4,0,
10001,10,157,4,0,
10001,10,164,4,0,
10001,10,173,3,0,
10001,10,182,4,0,
10001,10,189,3,0,
10001,10,192,1,81,
10001,10,203,4,0,
10001,10,207,4,0,
10001,10,214,4,0,
10001,10,216,4,0,
10001,10,218,4,0,
10001,10,219,4,0,
10001,10,228,1,33,
10001,10,237,4,0,
10001,10,240,4,0,
10001,10,241,4,0,
10001,10,244,4,0,
10001,10,247,4,0,
10001,10,249,4,0,
10001,10,259,4,0,
10001,10,263,4,0,
10001,10,264,4,0,
10001,10,269,1,25,
10001,10,269,4,0,
10001,10,271,3,0,
10001,10,272,3,0,
10001,10,276,1,49,
10001,10,276,3,0,
10001,10,277,3,0,
10001,10,278,4,0,
10001,10,280,4,0,
10001,10,285,4,0,
10001,10,289,4,0,
10001,10,290,4,0,
10001,10,317,4,0,
10001,10,322,1,73,
10001,10,324,3,0,
10001,10,332,4,0,
10001,10,347,4,0,
10001,10,351,4,0,
10001,10,352,4,0,
10001,10,354,1,89,
10001,10,356,3,0,
10001,10,363,4,0,
10001,10,374,4,0,
10001,10,375,1,57,
10001,10,398,4,0,
10001,10,409,4,0,
10001,10,411,4,0,
10001,10,412,4,0,
10001,10,416,4,0,
10001,10,419,4,0,
10001,10,428,1,65,
10001,10,428,3,0,
10001,10,430,4,0,
10001,10,433,4,0,
10001,10,446,4,0,
10001,10,447,4,0,
10001,10,451,4,0,
10001,11,15,4,0,
10001,11,35,1,1,2
10001,11,43,1,1,1
10001,11,58,4,0,
10001,11,63,1,97,
10001,11,63,4,0,
10001,11,70,4,0,
10001,11,76,4,0,
10001,11,85,4,0,
10001,11,86,4,0,
10001,11,87,4,0,
10001,11,92,4,0,
10001,11,94,1,41,
10001,11,94,4,0,
10001,11,100,1,17,
10001,11,101,1,9,
10001,11,104,4,0,
10001,11,113,4,0,
10001,11,115,4,0,
10001,11,138,4,0,
10001,11,148,4,0,
10001,11,156,4,0,
10001,11,157,4,0,
10001,11,164,4,0,
10001,11,182,4,0,
10001,11,192,1,81,
10001,11,207,4,0,
10001,11,216,4,0,
10001,11,218,4,0,
10001,11,219,4,0,
10001,11,228,1,33,
10001,11,237,4,0,
10001,11,240,4,0,
10001,11,241,4,0,
10001,11,244,4,0,
10001,11,247,4,0,
10001,11,249,4,0,
10001,11,259,4,0,
10001,11,263,4,0,
10001,11,269,1,25,
10001,11,269,4,0,
10001,11,276,1,49,
10001,11,280,4,0,
10001,11,317,4,0,
10001,11,322,1,73,
10001,11,332,4,0,
10001,11,347,4,0,
10001,11,354,1,89,
10001,11,374,4,0,
10001,11,375,1,57,
10001,11,398,4,0,
10001,11,411,4,0,
10001,11,412,4,0,
10001,11,416,4,0,
10001,11,428,1,65,
10001,11,430,4,0,
10001,11,433,4,0,
10001,11,447,4,0,
10001,11,451,4,0,
10001,11,473,4,0,
10001,11,477,4,0,
10001,11,490,4,0,
10001,11,496,4,0,
10001,11,502,4,0,
10001,14,15,4,0,
10001,14,20,3,0,
10001,14,35,1,1,2
10001,14,43,1,1,1
10001,14,58,4,0,
10001,14,63,1,97,
10001,14,63,4,0,
10001,14,67,3,0,
10001,14,70,4,0,
10001,14,76,4,0,
10001,14,85,4,0,
10001,14,86,4,0,
10001,14,87,4,0,
10001,14,92,4,0,
10001,14,94,1,41,
10001,14,94,4,0,
10001,14,100,1,17,
10001,14,101,1,9,
10001,14,104,4,0,
10001,14,113,4,0,
10001,14,115,4,0,
10001,14,138,4,0,
10001,14,148,4,0,
10001,14,156,4,0,
10001,14,157,4,0,
10001,14,164,4,0,
10001,14,173,3,0,
10001,14,182,4,0,
10001,14,192,1,81,
10001,14,207,4,0,
10001,14,214,3,0,
10001,14,216,4,0,
10001,14,218,4,0,
10001,14,219,4,0,
10001,14,228,1,33,
10001,14,237,4,0,
10001,14,240,4,0,
10001,14,241,4,0,
10001,14,244,4,0,
10001,14,247,4,0,
10001,14,249,4,0,
10001,14,259,4,0,
10001,14,263,4,0,
10001,14,269,1,25,
10001,14,269,4,0,
10001,14,271,3,0,
10001,14,272,3,0,
10001,14,276,1,49,
10001,14,276,3,0,
10001,14,277,3,0,
10001,14,278,3,0,
10001,14,280,4,0,
10001,14,285,3,0,
10001,14,289,3,0,
10001,14,317,4,0,
10001,14,322,1,73,
10001,14,324,3,0,
10001,14,332,4,0,
10001,14,347,4,0,
10001,14,354,1,89,
10001,14,356,3,0,
10001,14,374,4,0,
10001,14,375,1,57,
10001,14,398,4,0,
10001,14,409,3,0,
10001,14,411,4,0,
10001,14,412,4,0,
10001,14,416,4,0,
10001,14,428,1,65,
10001,14,428,3,0,
10001,14,430,4,0,
10001,14,433,4,0,
10001,14,446,3,0,
10001,14,447,4,0,
10001,14,451,4,0,
10001,14,472,3,0,
10001,14,473,4,0,
10001,14,477,4,0,
10001,14,490,4,0,
10001,14,496,4,0,
10001,14,502,4,0,
10001,15,15,4,0,
10001,15,35,1,1,2
10001,15,43,1,1,1
10001,15,58,4,0,
10001,15,63,1,97,
10001,15,63,4,0,
10001,15,70,4,0,
10001,15,76,4,0,
10001,15,85,4,0,
10001,15,86,4,0,
10001,15,87,4,0,
10001,15,92,4,0,
10001,15,94,1,41,
10001,15,94,4,0,
10001,15,100,1,17,
10001,15,101,1,9,
10001,15,104,4,0,
10001,15,113,4,0,
10001,15,115,4,0,
10001,15,138,4,0,
10001,15,148,4,0,
10001,15,156,4,0,
10001,15,157,4,0,
10001,15,164,4,0,
10001,15,182,4,0,
10001,15,192,1,81,
10001,15,207,4,0,
10001,15,214,4,0,
10001,15,216,4,0,
10001,15,218,4,0,
10001,15,219,4,0,
10001,15,228,1,33,
10001,15,237,4,0,
10001,15,240,4,0,
10001,15,241,4,0,
10001,15,244,4,0,
10001,15,247,4,0,
10001,15,249,4,0,
10001,15,259,4,0,
10001,15,263,4,0,
10001,15,269,1,25,
10001,15,269,4,0,
10001,15,276,1,49,
10001,15,280,4,0,
10001,15,317,4,0,
10001,15,322,1,73,
10001,15,332,4,0,
10001,15,347,4,0,
10001,15,354,1,89,
10001,15,374,4,0,
10001,15,375,1,57,
10001,15,398,4,0,
10001,15,399,4,0,
10001,15,411,4,0,
10001,15,412,4,0,
10001,15,416,4,0,
10001,15,428,1,65,
10001,15,430,4,0,
10001,15,433,4,0,
10001,15,447,4,0,
10001,15,451,4,0,
10001,15,473,4,0,
10001,15,490,4,0,
10001,15,496,4,0,
10001,15,590,4,0,
10001,15,612,4,0,
10001,16,15,4,0,
10001,16,20,3,0,
10001,16,35,1,1,2
10001,16,43,1,1,1
10001,16,58,4,0,
10001,16,63,1,73,1
10001,16,63,4,0,
10001,16,67,3,0,
10001,16,70,4,0,
10001,16,76,4,0,
10001,16,85,4,0,
10001,16,86,4,0,
10001,16,87,4,0,
10001,16,92,4,0,
10001,16,94,1,31,1
10001,16,94,4,0,
10001,16,100,1,13,1
10001,16,101,1,7,1
10001,16,104,4,0,
10001,16,113,4,0,
10001,16,115,4,0,
10001,16,138,4,0,
10001,16,148,4,0,
10001,16,156,4,0,
10001,16,157,4,0,
10001,16,164,4,0,
10001,16,173,3,0,
10001,16,182,4,0,
10001,16,192,1,61,1
10001,16,207,4,0,
10001,16,214,4,0,
10001,16,216,4,0,
10001,16,218,4,0,
10001,16,219,4,0,
10001,16,228,1,25,1
10001,16,237,4,0,
10001,16,240,4,0,
10001,16,241,4,0,
10001,16,244,4,0,
10001,16,247,4,0,
10001,16,249,4,0,
10001,16,259,4,0,
10001,16,263,4,0,
10001,16,264,3,0,
10001,16,269,1,19,1
10001,16,269,4,0,
10001,16,271,3,0,
10001,16,272,3,0,
10001,16,276,1,37,1
10001,16,276,3,0,
10001,16,277,3,0,
10001,16,278,3,0,
10001,16,280,4,0,
10001,16,285,3,0,
10001,16,289,3,0,
10001,16,290,4,0,
10001,16,317,4,0,
10001,16,322,1,55,1
10001,16,324,3,0,
10001,16,332,4,0,
10001,16,347,4,0,
10001,16,351,3,0,
10001,16,352,3,0,
10001,16,354,1,67,1
10001,16,356,3,0,
10001,16,374,4,0,
10001,16,375,1,43,1
10001,16,398,4,0,
10001,16,399,4,0,
10001,16,409,3,0,
10001,16,411,4,0,
10001,16,412,4,0,
10001,16,416,4,0,
10001,16,428,1,49,1
10001,16,428,3,0,
10001,16,430,4,0,
10001,16,433,4,0,
10001,16,446,3,0,
10001,16,447,4,0,
10001,16,451,4,0,
10001,16,472,3,0,
10001,16,473,4,0,
10001,16,490,4,0,
10001,16,496,4,0,
10001,16,590,4,0,
10001,16,612,4,0,
10001,17,35,1,1,2
10001,17,43,1,1,1
10001,17,58,4,0,
10001,17,63,1,73,
10001,17,63,4,0,
10001,17,76,4,0,
10001,17,85,4,0,
10001,17,86,4,0,
10001,17,87,4,0,
10001,17,92,4,0,
10001,17,94,1,31,
10001,17,94,4,0,
10001,17,100,1,13,
10001,17,101,1,7,
10001,17,104,4,0,
10001,17,113,4,0,
10001,17,115,4,0,
10001,17,138,4,0,
10001,17,156,4,0,
10001,17,157,4,0,
10001,17,164,4,0,
10001,17,182,4,0,
10001,17,192,1,61,
10001,17,207,4,0,
10001,17,214,4,0,
10001,17,216,4,0,
10001,17,218,4,0,
10001,17,219,4,0,
10001,17,228,1,25,
10001,17,237,4,0,
10001,17,240,4,0,
10001,17,241,4,0,
10001,17,244,4,0,
10001,17,247,4,0,
10001,17,259,4,0,
10001,17,263,4,0,
10001,17,269,1,19,
10001,17,269,4,0,
10001,17,276,1,37,
10001,17,280,4,0,
10001,17,317,4,0,
10001,17,322,1,55,
10001,17,332,4,0,
10001,17,347,4,0,
10001,17,354,1,67,
10001,17,374,4,0,
10001,17,375,1,43,
10001,17,398,4,0,
10001,17,399,4,0,
10001,17,411,4,0,
10001,17,412,4,0,
10001,17,416,4,0,
10001,17,428,1,49,
10001,17,430,4,0,
10001,17,433,4,0,
10001,17,447,4,0,
10001,17,451,4,0,
10001,17,473,4,0,
10001,17,490,4,0,
10001,17,496,4,0,
10001,17,590,4,0,
10001,17,693,4,0,
10001,18,35,1,1,2
10001,18,43,1,1,1
10001,18,58,4,0,
10001,18,63,1,73,
10001,18,63,4,0,
10001,18,76,4,0,
10001,18,85,4,0,
10001,18,86,4,0,
10001,18,87,4,0,
10001,18,92,4,0,
10001,18,94,1,31,
10001,18,94,4,0,
10001,18,100,1,13,
10001,18,101,1,7,
10001,18,104,4,0,
10001,18,113,4,0,
10001,18,115,4,0,
10001,18,138,4,0,
10001,18,156,4,0,
10001,18,157,4,0,
10001,18,164,4,0,
10001,18,182,4,0,
10001,18,192,1,61,
10001,18,207,4,0,
10001,18,214,4,0,
10001,18,216,4,0,
10001,18,218,4,0,
10001,18,219,4,0,
10001,18,228,1,25,
10001,18,237,4,0,
10001,18,240,4,0,
10001,18,241,4,0,
10001,18,244,4,0,
10001,18,247,4,0,
10001,18,259,4,0,
10001,18,263,4,0,
10001,18,269,1,19,
10001,18,269,4,0,
10001,18,276,1,37,
10001,18,280,4,0,
10001,18,317,4,0,
10001,18,322,1,55,
10001,18,332,4,0,
10001,18,347,4,0,
10001,18,354,1,67,
10001,18,374,4,0,
10001,18,375,1,43,
10001,18,398,4,0,
10001,18,399,4,0,
10001,18,411,4,0,
10001,18,412,4,0,
10001,18,416,4,0,
10001,18,428,1,49,
10001,18,430,4,0,
10001,18,433,4,0,
10001,18,447,4,0,
10001,18,451,4,0,
10001,18,473,4,0,
10001,18,490,4,0,
10001,18,496,4,0,
10001,18,590,4,0,
10001,18,693,4,0,
10002,7,5,3,0,
10002,7,15,4,0,
10002,7,25,3,0,
10002,7,34,3,0,
10002,7,35,1,1,2
10002,7,38,3,0,
10002,7,43,1,1,1
10002,7,58,4,0,
10002,7,63,4,0,
10002,7,68,1,50,1
10002,7,68,3,0,
10002,7,69,3,0,
10002,7,70,4,0,
10002,7,76,4,0,
10002,7,85,4,0,
10002,7,86,3,0,
10002,7,87,4,0,
10002,7,92,4,0,
10002,7,94,1,25,
10002,7,94,4,0,
10002,7,100,1,10,
10002,7,101,1,5,
10002,7,102,3,0,
10002,7,104,4,0,
10002,7,105,1,40,
10002,7,113,4,0,
10002,7,115,4,0,
10002,7,133,1,35,2
10002,7,138,3,0,
10002,7,148,4,0,
10002,7,156,4,0,
10002,7,157,3,0,
10002,7,164,3,0,
10002,7,182,4,0,
10002,7,191,1,20,
10002,7,216,4,0,
10002,7,218,4,0,
10002,7,219,4,0,
10002,7,237,4,0,
10002,7,240,4,0,
10002,7,241,4,0,
10002,7,243,1,50,2
10002,7,247,4,0,
10002,7,249,4,0,
10002,7,259,4,0,
10002,7,263,4,0,
10002,7,264,4,0,
10002,7,269,4,0,
10002,7,280,4,0,
10002,7,282,1,15,
10002,7,285,4,0,
10002,7,289,1,30,
10002,7,289,4,0,
10002,7,290,4,0,
10002,7,317,4,0,
10002,7,332,4,0,
10002,7,334,1,35,1
10002,7,347,4,0,
10002,7,351,4,0,
10002,7,352,4,0,
10002,7,354,1,45,
10002,8,15,4,0,
10002,8,35,1,1,2
10002,8,43,1,1,1
10002,8,58,4,0,
10002,8,63,4,0,
10002,8,68,1,97,1
10002,8,70,4,0,
10002,8,76,4,0,
10002,8,85,4,0,
10002,8,86,4,0,
10002,8,87,4,0,
10002,8,92,4,0,
10002,8,94,1,41,
10002,8,94,4,0,
10002,8,100,1,17,
10002,8,101,1,9,
10002,8,104,4,0,
10002,8,105,1,81,
10002,8,113,4,0,
10002,8,115,4,0,
10002,8,133,1,73,2
10002,8,138,4,0,
10002,8,148,4,0,
10002,8,156,4,0,
10002,8,157,4,0,
10002,8,164,4,0,
10002,8,182,4,0,
10002,8,191,1,33,
10002,8,203,4,0,
10002,8,207,4,0,
10002,8,214,4,0,
10002,8,216,4,0,
10002,8,218,4,0,
10002,8,219,4,0,
10002,8,237,4,0,
10002,8,240,4,0,
10002,8,241,4,0,
10002,8,243,1,97,2
10002,8,244,4,0,
10002,8,247,4,0,
10002,8,249,4,0,
10002,8,259,4,0,
10002,8,263,4,0,
10002,8,264,4,0,
10002,8,269,4,0,
10002,8,278,4,0,
10002,8,280,4,0,
10002,8,282,1,25,
10002,8,285,4,0,
10002,8,289,1,49,
10002,8,289,4,0,
10002,8,290,4,0,
10002,8,317,4,0,
10002,8,332,4,0,
10002,8,334,1,73,1
10002,8,347,4,0,
10002,8,351,4,0,
10002,8,352,4,0,
10002,8,354,1,89,
10002,8,363,4,0,
10002,8,374,4,0,
10002,8,375,1,57,
10002,8,398,4,0,
10002,8,409,4,0,
10002,8,411,4,0,
10002,8,412,4,0,
10002,8,416,4,0,
10002,8,419,4,0,
10002,8,428,1,65,
10002,8,430,4,0,
10002,8,433,4,0,
10002,8,446,4,0,
10002,8,447,4,0,
10002,8,451,4,0,
10002,9,15,4,0,
10002,9,35,1,1,2
10002,9,43,1,1,1
10002,9,58,4,0,
10002,9,63,4,0,
10002,9,68,1,97,1
10002,9,70,4,0,
10002,9,76,4,0,
10002,9,85,4,0,
10002,9,86,4,0,
10002,9,87,4,0,
10002,9,92,4,0,
10002,9,94,1,41,
10002,9,94,4,0,
10002,9,100,1,17,
10002,9,101,1,9,
10002,9,104,4,0,
10002,9,105,1,81,
10002,9,113,4,0,
10002,9,115,4,0,
10002,9,133,1,73,2
10002,9,138,4,0,
10002,9,148,4,0,
10002,9,156,4,0,
10002,9,157,4,0,
10002,9,164,4,0,
10002,9,173,3,0,
10002,9,182,4,0,
10002,9,189,3,0,
10002,9,191,1,33,
10002,9,203,4,0,
10002,9,207,4,0,
10002,9,214,4,0,
10002,9,216,4,0,
10002,9,218,4,0,
10002,9,219,4,0,
10002,9,237,4,0,
10002,9,240,4,0,
10002,9,241,4,0,
10002,9,243,1,97,2
10002,9,244,4,0,
10002,9,247,4,0,
10002,9,249,4,0,
10002,9,259,4,0,
10002,9,263,4,0,
10002,9,264,4,0,
10002,9,269,4,0,
10002,9,271,3,0,
10002,9,278,4,0,
10002,9,280,4,0,
10002,9,282,1,25,
10002,9,282,3,0,
10002,9,285,4,0,
10002,9,289,1,49,
10002,9,289,4,0,
10002,9,290,4,0,
10002,9,317,4,0,
10002,9,324,3,0,
10002,9,332,4,0,
10002,9,334,1,73,1
10002,9,334,3,0,
10002,9,347,4,0,
10002,9,351,4,0,
10002,9,352,4,0,
10002,9,354,1,89,
10002,9,363,4,0,
10002,9,374,4,0,
10002,9,375,1,57,
10002,9,398,4,0,
10002,9,409,4,0,
10002,9,411,4,0,
10002,9,412,4,0,
10002,9,416,4,0,
10002,9,419,4,0,
10002,9,428,1,65,
10002,9,428,3,0,
10002,9,430,4,0,
10002,9,433,4,0,
10002,9,446,4,0,
10002,9,447,4,0,
10002,9,451,4,0,
10002,10,15,4,0,
10002,10,29,3,0,
10002,10,35,1,1,2
10002,10,43,1,1,1
10002,10,58,4,0,
10002,10,63,4,0,
10002,10,67,3,0,
10002,10,68,1,97,1
10002,10,70,4,0,
10002,10,76,4,0,
10002,10,85,4,0,
10002,10,86,4,0,
10002,10,87,4,0,
10002,10,92,4,0,
10002,10,94,1,41,
10002,10,94,4,0,
10002,10,100,1,17,
10002,10,101,1,9,
10002,10,104,4,0,
10002,10,105,1,81,
10002,10,113,4,0,
10002,10,115,4,0,
10002,10,133,1,73,2
10002,10,138,4,0,
10002,10,148,4,0,
10002,10,156,4,0,
10002,10,157,4,0,
10002,10,164,4,0,
10002,10,173,3,0,
10002,10,182,4,0,
10002,10,189,3,0,
10002,10,191,1,33,
10002,10,203,4,0,
10002,10,207,4,0,
10002,10,214,4,0,
10002,10,216,4,0,
10002,10,218,4,0,
10002,10,219,4,0,
10002,10,237,4,0,
10002,10,240,4,0,
10002,10,241,4,0,
10002,10,243,1,97,2
10002,10,244,4,0,
10002,10,247,4,0,
10002,10,249,4,0,
10002,10,259,4,0,
10002,10,263,4,0,
10002,10,264,4,0,
10002,10,269,4,0,
10002,10,271,3,0,
10002,10,272,3,0,
10002,10,277,3,0,
10002,10,278,4,0,
10002,10,280,4,0,
10002,10,282,1,25,
10002,10,282,3,0,
10002,10,285,4,0,
10002,10,289,1,49,
10002,10,289,4,0,
10002,10,290,4,0,
10002,10,317,4,0,
10002,10,324,3,0,
10002,10,332,4,0,
10002,10,334,1,73,1
10002,10,334,3,0,
10002,10,347,4,0,
10002,10,351,4,0,
10002,10,352,4,0,
10002,10,354,1,89,
10002,10,356,3,0,
10002,10,363,4,0,
10002,10,374,4,0,
10002,10,375,1,57,
10002,10,398,4,0,
10002,10,409,4,0,
10002,10,411,4,0,
10002,10,412,4,0,
10002,10,416,4,0,
10002,10,419,4,0,
10002,10,428,1,65,
10002,10,428,3,0,
10002,10,430,4,0,
10002,10,433,4,0,
10002,10,446,4,0,
10002,10,447,4,0,
10002,10,451,4,0,
10002,11,15,4,0,
10002,11,35,1,1,2
10002,11,43,1,1,1
10002,11,58,4,0,
10002,11,63,4,0,
10002,11,68,1,97,1
10002,11,70,4,0,
10002,11,76,4,0,
10002,11,85,4,0,
10002,11,86,4,0,
10002,11,87,4,0,
10002,11,92,4,0,
10002,11,94,1,41,
10002,11,94,4,0,
10002,11,100,1,17,
10002,11,101,1,9,
10002,11,104,4,0,
10002,11,105,1,81,
10002,11,113,4,0,
10002,11,115,4,0,
10002,11,133,1,73,2
10002,11,138,4,0,
10002,11,148,4,0,
10002,11,156,4,0,
10002,11,157,4,0,
10002,11,164,4,0,
10002,11,182,4,0,
10002,11,191,1,33,
10002,11,207,4,0,
10002,11,216,4,0,
10002,11,218,4,0,
10002,11,219,4,0,
10002,11,237,4,0,
10002,11,240,4,0,
10002,11,241,4,0,
10002,11,243,1,97,2
10002,11,244,4,0,
10002,11,247,4,0,
10002,11,249,4,0,
10002,11,259,4,0,
10002,11,263,4,0,
10002,11,269,4,0,
10002,11,280,4,0,
10002,11,282,1,25,
10002,11,289,1,49,
10002,11,317,4,0,
10002,11,332,4,0,
10002,11,334,1,73,1
10002,11,347,4,0,
10002,11,354,1,89,
10002,11,374,4,0,
10002,11,375,1,57,
10002,11,398,4,0,
10002,11,411,4,0,
10002,11,412,4,0,
10002,11,416,4,0,
10002,11,428,1,65,
10002,11,430,4,0,
10002,11,433,4,0,
10002,11,447,4,0,
10002,11,451,4,0,
10002,11,473,4,0,
10002,11,477,4,0,
10002,11,490,4,0,
10002,11,496,4,0,
10002,11,502,4,0,
10002,14,15,4,0,
10002,14,20,3,0,
10002,14,35,1,1,2
10002,14,43,1,1,1
10002,14,58,4,0,
10002,14,63,4,0,
10002,14,67,3,0,
10002,14,68,1,97,1
10002,14,70,4,0,
10002,14,76,4,0,
10002,14,85,4,0,
10002,14,86,4,0,
10002,14,87,4,0,
10002,14,92,4,0,
10002,14,94,1,41,
10002,14,94,4,0,
10002,14,100,1,17,
10002,14,101,1,9,
10002,14,104,4,0,
10002,14,105,1,81,
10002,14,113,4,0,
10002,14,115,4,0,
10002,14,133,1,73,2
10002,14,138,4,0,
10002,14,148,4,0,
10002,14,156,4,0,
10002,14,157,4,0,
10002,14,164,4,0,
10002,14,173,3,0,
10002,14,182,4,0,
10002,14,191,1,33,
10002,14,207,4,0,
10002,14,214,3,0,
10002,14,216,4,0,
10002,14,218,4,0,
10002,14,219,4,0,
10002,14,237,4,0,
10002,14,240,4,0,
10002,14,241,4,0,
10002,14,243,1,97,2
10002,14,244,4,0,
10002,14,247,4,0,
10002,14,249,4,0,
10002,14,259,4,0,
10002,14,263,4,0,
10002,14,269,4,0,
10002,14,271,3,0,
10002,14,272,3,0,
10002,14,277,3,0,
10002,14,278,3,0,
10002,14,280,4,0,
10002,14,282,1,25,
10002,14,282,3,0,
10002,14,285,3,0,
10002,14,289,1,49,
10002,14,289,3,0,
10002,14,317,4,0,
10002,14,324,3,0,
10002,14,332,4,0,
10002,14,334,1,73,1
10002,14,334,3,0,
10002,14,347,4,0,
10002,14,354,1,89,
10002,14,356,3,0,
10002,14,374,4,0,
10002,14,375,1,57,
10002,14,398,4,0,
10002,14,409,3,0,
10002,14,411,4,0,
10002,14,412,4,0,
10002,14,416,4,0,
10002,14,428,1,65,
10002,14,428,3,0,
10002,14,430,4,0,
10002,14,433,4,0,
10002,14,446,3,0,
10002,14,447,4,0,
10002,14,451,4,0,
10002,14,472,3,0,
10002,14,473,4,0,
10002,14,477,4,0,
10002,14,490,4,0,
10002,14,496,4,0,
10002,14,502,4,0,
10002,15,15,4,0,
10002,15,35,1,1,2
10002,15,43,1,1,1
10002,15,58,4,0,
10002,15,63,4,0,
10002,15,68,1,97,1
10002,15,70,4,0,
10002,15,76,4,0,
10002,15,85,4,0,
10002,15,86,4,0,
10002,15,87,4,0,
10002,15,92,4,0,
10002,15,94,1,41,
10002,15,94,4,0,
10002,15,100,1,17,
10002,15,101,1,9,
10002,15,104,4,0,
10002,15,105,1,81,
10002,15,113,4,0,
10002,15,115,4,0,
10002,15,133,1,73,2
10002,15,138,4,0,
10002,15,148,4,0,
10002,15,156,4,0,
10002,15,157,4,0,
10002,15,164,4,0,
10002,15,182,4,0,
10002,15,191,1,33,
10002,15,207,4,0,
10002,15,214,4,0,
10002,15,216,4,0,
10002,15,218,4,0,
10002,15,219,4,0,
10002,15,237,4,0,
10002,15,240,4,0,
10002,15,241,4,0,
10002,15,243,1,97,2
10002,15,244,4,0,
10002,15,247,4,0,
10002,15,249,4,0,
10002,15,259,4,0,
10002,15,263,4,0,
10002,15,269,4,0,
10002,15,280,4,0,
10002,15,282,1,25,
10002,15,289,1,49,
10002,15,317,4,0,
10002,15,332,4,0,
10002,15,334,1,73,1
10002,15,347,4,0,
10002,15,354,1,89,
10002,15,374,4,0,
10002,15,375,1,57,
10002,15,398,4,0,
10002,15,399,4,0,
10002,15,411,4,0,
10002,15,412,4,0,
10002,15,416,4,0,
10002,15,428,1,65,
10002,15,430,4,0,
10002,15,433,4,0,
10002,15,447,4,0,
10002,15,451,4,0,
10002,15,473,4,0,
10002,15,490,4,0,
10002,15,496,4,0,
10002,15,590,4,0,
10002,15,612,4,0,
10002,16,15,4,0,
10002,16,20,3,0,
10002,16,35,1,1,2
10002,16,43,1,1,1
10002,16,58,4,0,
10002,16,63,4,0,
10002,16,67,3,0,
10002,16,68,1,73,1
10002,16,70,4,0,
10002,16,76,4,0,
10002,16,85,4,0,
10002,16,86,4,0,
10002,16,87,4,0,
10002,16,92,4,0,
10002,16,94,1,31,1
10002,16,94,4,0,
10002,16,100,1,13,1
10002,16,101,1,7,1
10002,16,104,4,0,
10002,16,105,1,61,1
10002,16,113,4,0,
10002,16,115,4,0,
10002,16,133,1,55,2
10002,16,138,4,0,
10002,16,148,4,0,
10002,16,156,4,0,
10002,16,157,4,0,
10002,16,164,4,0,
10002,16,173,3,0,
10002,16,182,4,0,
10002,16,191,1,25,1
10002,16,207,4,0,
10002,16,214,4,0,
10002,16,216,4,0,
10002,16,218,4,0,
10002,16,219,4,0,
10002,16,237,4,0,
10002,16,240,4,0,
10002,16,241,4,0,
10002,16,243,1,73,2
10002,16,244,4,0,
10002,16,247,4,0,
10002,16,249,4,0,
10002,16,259,4,0,
10002,16,263,4,0,
10002,16,264,3,0,
10002,16,269,4,0,
10002,16,271,3,0,
10002,16,272,3,0,
10002,16,277,3,0,
10002,16,278,3,0,
10002,16,280,4,0,
10002,16,282,1,19,1
10002,16,282,3,0,
10002,16,285,3,0,
10002,16,289,1,37,1
10002,16,289,3,0,
10002,16,290,4,0,
10002,16,317,4,0,
10002,16,324,3,0,
10002,16,332,4,0,
10002,16,334,1,55,1
10002,16,334,3,0,
10002,16,347,4,0,
10002,16,351,3,0,
10002,16,352,3,0,
10002,16,354,1,67,1
10002,16,356,3,0,
10002,16,374,4,0,
10002,16,375,1,43,1
10002,16,398,4,0,
10002,16,399,4,0,
10002,16,409,3,0,
10002,16,411,4,0,
10002,16,412,4,0,
10002,16,416,4,0,
10002,16,428,1,49,1
10002,16,428,3,0,
10002,16,430,4,0,
10002,16,433,4,0,
10002,16,446,3,0,
10002,16,447,4,0,
10002,16,451,4,0,
10002,16,472,3,0,
10002,16,473,4,0,
10002,16,490,4,0,
10002,16,496,4,0,
10002,16,590,4,0,
10002,16,612,4,0,
10002,17,35,1,1,2
10002,17,43,1,1,1
10002,17,58,4,0,
10002,17,63,4,0,
10002,17,68,1,73,1
10002,17,76,4,0,
10002,17,85,4,0,
10002,17,86,4,0,
10002,17,87,4,0,
10002,17,92,4,0,
10002,17,94,1,31,
10002,17,94,4,0,
10002,17,100,1,13,
10002,17,101,1,7,
10002,17,104,4,0,
10002,17,105,1,61,
10002,17,113,4,0,
10002,17,115,4,0,
10002,17,133,1,55,2
10002,17,138,4,0,
10002,17,156,4,0,
10002,17,157,4,0,
10002,17,164,4,0,
10002,17,182,4,0,
10002,17,191,1,25,
10002,17,207,4,0,
10002,17,214,4,0,
10002,17,216,4,0,
10002,17,218,4,0,
10002,17,219,4,0,
10002,17,237,4,0,
10002,17,240,4,0,
10002,17,241,4,0,
10002,17,243,1,73,2
10002,17,244,4,0,
10002,17,247,4,0,
10002,17,259,4,0,
10002,17,263,4,0,
10002,17,269,4,0,
10002,17,280,4,0,
10002,17,282,1,19,
10002,17,289,1,37,
10002,17,317,4,0,
10002,17,332,4,0,
10002,17,334,1,55,1
10002,17,347,4,0,
10002,17,354,1,67,
10002,17,374,4,0,
10002,17,375,1,43,
10002,17,398,4,0,
10002,17,399,4,0,
10002,17,411,4,0,
10002,17,412,4,0,
10002,17,416,4,0,
10002,17,428,1,49,
10002,17,430,4,0,
10002,17,433,4,0,
10002,17,447,4,0,
10002,17,451,4,0,
10002,17,473,4,0,
10002,17,490,4,0,
10002,17,496,4,0,
10002,17,590,4,0,
10002,17,693,4,0,
10002,18,35,1,1,2
10002,18,43,1,1,1
10002,18,58,4,0,
10002,18,63,4,0,
10002,18,68,1,73,1
10002,18,76,4,0,
10002,18,85,4,0,
10002,18,86,4,0,
10002,18,87,4,0,
10002,18,92,4,0,
10002,18,94,1,31,
10002,18,94,4,0,
10002,18,100,1,13,
10002,18,101,1,7,
10002,18,104,4,0,
10002,18,105,1,61,
10002,18,113,4,0,
10002,18,115,4,0,
10002,18,133,1,55,2
10002,18,138,4,0,
10002,18,156,4,0,
10002,18,157,4,0,
10002,18,164,4,0,
10002,18,182,4,0,
10002,18,191,1,25,
10002,18,207,4,0,
10002,18,214,4,0,
10002,18,216,4,0,
10002,18,218,4,0,
10002,18,219,4,0,
10002,18,237,4,0,
10002,18,240,4,0,
10002,18,241,4,0,
10002,18,243,1,73,2
10002,18,244,4,0,
10002,18,247,4,0,
10002,18,259,4,0,
10002,18,263,4,0,
10002,18,269,4,0,
10002,18,280,4,0,
10002,18,282,1,19,
10002,18,289,1,37,
10002,18,317,4,0,
10002,18,332,4,0,
10002,18,334,1,55,1
10002,18,347,4,0,
10002,18,354,1,67,
10002,18,374,4,0,
10002,18,375,1,43,
10002,18,398,4,0,
10002,18,399,4,0,
10002,18,411,4,0,
10002,18,412,4,0,
10002,18,416,4,0,
10002,18,428,1,49,
10002,18,430,4,0,
10002,18,433,4,0,
10002,18,447,4,0,
10002,18,451,4,0,
10002,18,473,4,0,
10002,18,490,4,0,
10002,18,496,4,0,
10002,18,590,4,0,
10002,18,693,4,0,
10003,6,5,3,0,
10003,6,7,3,0,
10003,6,8,3,0,
10003,6,9,3,0,
10003,6,15,4,0,
10003,6,25,3,0,
10003,6,34,3,0,
10003,6,35,1,1,2
10003,6,38,3,0,
10003,6,43,1,1,1
10003,6,58,4,0,
10003,6,63,4,0,
10003,6,68,3,0,
10003,6,69,3,0,
10003,6,70,4,0,
10003,6,76,4,0,
10003,6,85,4,0,
10003,6,86,3,0,
10003,6,87,4,0,
10003,6,92,4,0,
10003,6,94,1,25,
10003,6,94,4,0,
10003,6,97,1,35,
10003,6,101,1,5,
10003,6,102,3,0,
10003,6,104,1,10,
10003,6,104,4,0,
10003,6,105,1,40,
10003,6,113,4,0,
10003,6,115,4,0,
10003,6,129,1,30,
10003,6,129,3,0,
10003,6,138,3,0,
10003,6,148,4,0,
10003,6,156,4,0,
10003,6,157,3,0,
10003,6,164,3,0,
10003,6,173,3,0,
10003,6,182,4,0,
10003,6,189,3,0,
10003,6,196,3,0,
10003,6,203,3,0,
10003,6,207,3,0,
10003,6,214,3,0,
10003,6,216,4,0,
10003,6,218,4,0,
10003,6,219,4,0,
10003,6,223,3,0,
10003,6,228,1,20,
10003,6,237,4,0,
10003,6,240,4,0,
10003,6,241,4,0,
10003,6,244,3,0,
10003,6,245,1,50,
10003,6,247,4,0,
10003,6,249,4,0,
10003,6,259,4,0,
10003,6,263,4,0,
10003,6,264,4,0,
10003,6,269,4,0,
10003,6,280,4,0,
10003,6,282,1,15,
10003,6,285,4,0,
10003,6,289,4,0,
10003,6,290,4,0,
10003,6,317,4,0,
10003,6,332,4,0,
10003,6,347,4,0,
10003,6,351,4,0,
10003,6,352,4,0,
10003,6,354,1,45,
10003,8,15,4,0,
10003,8,35,1,1,2
10003,8,43,1,1,1
10003,8,58,4,0,
10003,8,63,4,0,
10003,8,70,4,0,
10003,8,76,4,0,
10003,8,85,4,0,
10003,8,86,4,0,
10003,8,87,4,0,
10003,8,92,4,0,
10003,8,94,1,41,
10003,8,94,4,0,
10003,8,97,1,73,
10003,8,101,1,9,
10003,8,104,1,17,
10003,8,104,4,0,
10003,8,105,1,81,
10003,8,113,4,0,
10003,8,115,4,0,
10003,8,129,1,49,
10003,8,138,4,0,
10003,8,148,4,0,
10003,8,156,4,0,
10003,8,157,4,0,
10003,8,164,4,0,
10003,8,182,4,0,
10003,8,203,4,0,
10003,8,207,4,0,
10003,8,214,4,0,
10003,8,216,4,0,
10003,8,218,4,0,
10003,8,219,4,0,
10003,8,228,1,33,
10003,8,237,4,0,
10003,8,240,4,0,
10003,8,241,4,0,
10003,8,244,4,0,
10003,8,245,1,97,
10003,8,247,4,0,
10003,8,249,4,0,
10003,8,259,4,0,
10003,8,263,4,0,
10003,8,264,4,0,
10003,8,269,4,0,
10003,8,278,4,0,
10003,8,280,4,0,
10003,8,282,1,25,
10003,8,285,4,0,
10003,8,289,4,0,
10003,8,290,4,0,
10003,8,317,4,0,
10003,8,332,4,0,
10003,8,347,4,0,
10003,8,351,4,0,
10003,8,352,4,0,
10003,8,354,1,89,
10003,8,363,4,0,
10003,8,374,4,0,
10003,8,375,1,57,
10003,8,398,4,0,
10003,8,409,4,0,
10003,8,411,4,0,
10003,8,412,4,0,
10003,8,416,4,0,
10003,8,419,4,0,
10003,8,428,1,65,
10003,8,430,4,0,
10003,8,433,4,0,
10003,8,446,4,0,
10003,8,447,4,0,
10003,8,451,4,0,
10003,9,15,4,0,
10003,9,35,1,1,2
10003,9,43,1,1,1
10003,9,58,4,0,
10003,9,63,4,0,
10003,9,70,4,0,
10003,9,76,4,0,
10003,9,85,4,0,
10003,9,86,4,0,
10003,9,87,4,0,
10003,9,92,4,0,
10003,9,94,1,41,
10003,9,94,4,0,
10003,9,97,1,73,
10003,9,101,1,9,
10003,9,104,1,17,
10003,9,104,4,0,
10003,9,105,1,81,
10003,9,113,4,0,
10003,9,115,4,0,
10003,9,129,1,49,
10003,9,129,3,0,
10003,9,138,4,0,
10003,9,148,4,0,
10003,9,156,4,0,
10003,9,157,4,0,
10003,9,164,4,0,
10003,9,173,3,0,
10003,9,182,4,0,
10003,9,189,3,0,
10003,9,203,4,0,
10003,9,207,4,0,
10003,9,214,4,0,
10003,9,216,4,0,
10003,9,218,4,0,
10003,9,219,4,0,
10003,9,228,1,33,
10003,9,237,4,0,
10003,9,240,4,0,
10003,9,241,4,0,
10003,9,244,4,0,
10003,9,245,1,97,
10003,9,247,4,0,
10003,9,249,4,0,
10003,9,259,4,0,
10003,9,263,4,0,
10003,9,264,4,0,
10003,9,269,4,0,
10003,9,271,3,0,
10003,9,278,4,0,
10003,9,280,4,0,
10003,9,282,1,25,
10003,9,282,3,0,
10003,9,285,4,0,
10003,9,289,4,0,
10003,9,290,4,0,
10003,9,317,4,0,
10003,9,324,3,0,
10003,9,332,4,0,
10003,9,347,4,0,
10003,9,351,4,0,
10003,9,352,4,0,
10003,9,354,1,89,
10003,9,363,4,0,
10003,9,374,4,0,
10003,9,375,1,57,
10003,9,398,4,0,
10003,9,409,4,0,
10003,9,411,4,0,
10003,9,412,4,0,
10003,9,416,4,0,
10003,9,419,4,0,
10003,9,428,1,65,
10003,9,428,3,0,
10003,9,430,4,0,
10003,9,433,4,0,
10003,9,446,4,0,
10003,9,447,4,0,
10003,9,451,4,0,
10003,10,15,4,0,
10003,10,29,3,0,
10003,10,35,1,1,2
10003,10,43,1,1,1
10003,10,58,4,0,
10003,10,63,4,0,
10003,10,67,3,0,
10003,10,70,4,0,
10003,10,76,4,0,
10003,10,85,4,0,
10003,10,86,4,0,
10003,10,87,4,0,
10003,10,92,4,0,
10003,10,94,1,41,
10003,10,94,4,0,
10003,10,97,1,73,
10003,10,101,1,9,
10003,10,104,1,17,
10003,10,104,4,0,
10003,10,105,1,81,
10003,10,113,4,0,
10003,10,115,4,0,
10003,10,129,1,49,
10003,10,129,3,0,
10003,10,138,4,0,
10003,10,148,4,0,
10003,10,156,4,0,
10003,10,157,4,0,
10003,10,164,4,0,
10003,10,173,3,0,
10003,10,182,4,0,
10003,10,189,3,0,
10003,10,203,4,0,
10003,10,207,4,0,
10003,10,214,4,0,
10003,10,216,4,0,
10003,10,218,4,0,
10003,10,219,4,0,
10003,10,228,1,33,
10003,10,237,4,0,
10003,10,240,4,0,
10003,10,241,4,0,
10003,10,244,4,0,
10003,10,245,1,97,
10003,10,247,4,0,
10003,10,249,4,0,
10003,10,259,4,0,
10003,10,263,4,0,
10003,10,264,4,0,
10003,10,269,4,0,
10003,10,271,3,0,
10003,10,272,3,0,
10003,10,277,3,0,
10003,10,278,4,0,
10003,10,280,4,0,
10003,10,282,1,25,
10003,10,282,3,0,
10003,10,285,4,0,
10003,10,289,4,0,
10003,10,290,4,0,
10003,10,317,4,0,
10003,10,324,3,0,
10003,10,332,4,0,
10003,10,347,4,0,
10003,10,351,4,0,
10003,10,352,4,0,
10003,10,354,1,89,
10003,10,356,3,0,
10003,10,363,4,0,
10003,10,374,4,0,
10003,10,375,1,57,
10003,10,398,4,0,
10003,10,409,4,0,
10003,10,411,4,0,
10003,10,412,4,0,
10003,10,416,4,0,
10003,10,419,4,0,
10003,10,428,1,65,
10003,10,428,3,0,
10003,10,430,4,0,
10003,10,433,4,0,
10003,10,446,4,0,
10003,10,447,4,0,
10003,10,451,4,0,
10003,11,15,4,0,
10003,11,35,1,1,2
10003,11,43,1,1,1
10003,11,58,4,0,
10003,11,63,4,0,
10003,11,70,4,0,
10003,11,76,4,0,
10003,11,85,4,0,
10003,11,86,4,0,
10003,11,87,4,0,
10003,11,92,4,0,
10003,11,94,1,41,
10003,11,94,4,0,
10003,11,97,1,73,
10003,11,101,1,9,
10003,11,104,1,17,
10003,11,104,4,0,
10003,11,105,1,81,
10003,11,113,4,0,
10003,11,115,4,0,
10003,11,129,1,49,
10003,11,138,4,0,
10003,11,148,4,0,
10003,11,156,4,0,
10003,11,157,4,0,
10003,11,164,4,0,
10003,11,182,4,0,
10003,11,207,4,0,
10003,11,216,4,0,
10003,11,218,4,0,
10003,11,219,4,0,
10003,11,228,1,33,
10003,11,237,4,0,
10003,11,240,4,0,
10003,11,241,4,0,
10003,11,244,4,0,
10003,11,245,1,97,
10003,11,247,4,0,
10003,11,249,4,0,
10003,11,259,4,0,
10003,11,263,4,0,
10003,11,269,4,0,
10003,11,280,4,0,
10003,11,282,1,25,
10003,11,317,4,0,
10003,11,332,4,0,
10003,11,347,4,0,
10003,11,354,1,89,
10003,11,374,4,0,
10003,11,375,1,57,
10003,11,398,4,0,
10003,11,411,4,0,
10003,11,412,4,0,
10003,11,416,4,0,
10003,11,428,1,65,
10003,11,430,4,0,
10003,11,433,4,0,
10003,11,447,4,0,
10003,11,451,4,0,
10003,11,473,4,0,
10003,11,477,4,0,
10003,11,490,4,0,
10003,11,496,4,0,
10003,11,502,4,0,
10003,14,7,3,0,
10003,14,8,3,0,
10003,14,9,3,0,
10003,14,15,4,0,
10003,14,20,3,0,
10003,14,35,1,1,2
10003,14,43,1,1,1
10003,14,58,4,0,
10003,14,63,4,0,
10003,14,67,3,0,
10003,14,70,4,0,
10003,14,76,4,0,
10003,14,85,4,0,
10003,14,86,4,0,
10003,14,87,4,0,
10003,14,92,4,0,
10003,14,94,1,41,
10003,14,94,4,0,
10003,14,97,1,73,
10003,14,101,1,9,
10003,14,104,1,17,
10003,14,104,4,0,
10003,14,105,1,81,
10003,14,113,4,0,
10003,14,115,4,0,
10003,14,129,1,49,
10003,14,138,4,0,
10003,14,148,4,0,
10003,14,156,4,0,
10003,14,157,4,0,
10003,14,164,4,0,
10003,14,173,3,0,
10003,14,182,4,0,
10003,14,207,4,0,
10003,14,214,3,0,
10003,14,216,4,0,
10003,14,218,4,0,
10003,14,219,4,0,
10003,14,228,1,33,
10003,14,237,4,0,
10003,14,240,4,0,
10003,14,241,4,0,
10003,14,244,4,0,
10003,14,245,1,97,
10003,14,247,4,0,
10003,14,249,4,0,
10003,14,259,4,0,
10003,14,263,4,0,
10003,14,269,4,0,
10003,14,271,3,0,
10003,14,272,3,0,
10003,14,277,3,0,
10003,14,278,3,0,
10003,14,280,4,0,
10003,14,282,1,25,
10003,14,282,3,0,
10003,14,285,3,0,
10003,14,289,3,0,
10003,14,317,4,0,
10003,14,324,3,0,
10003,14,332,4,0,
10003,14,347,4,0,
10003,14,354,1,89,
10003,14,356,3,0,
10003,14,374,4,0,
10003,14,375,1,57,
10003,14,398,4,0,
10003,14,409,3,0,
10003,14,411,4,0,
10003,14,412,4,0,
10003,14,416,4,0,
10003,14,428,1,65,
10003,14,428,3,0,
10003,14,430,4,0,
10003,14,433,4,0,
10003,14,446,3,0,
10003,14,447,4,0,
10003,14,451,4,0,
10003,14,472,3,0,
10003,14,473,4,0,
10003,14,477,4,0,
10003,14,490,4,0,
10003,14,496,4,0,
10003,14,502,4,0,
10003,15,15,4,0,
10003,15,35,1,1,2
10003,15,43,1,1,1
10003,15,58,4,0,
10003,15,63,4,0,
10003,15,70,4,0,
10003,15,76,4,0,
10003,15,85,4,0,
10003,15,86,4,0,
10003,15,87,4,0,
10003,15,92,4,0,
10003,15,94,1,41,
10003,15,94,4,0,
10003,15,97,1,73,
10003,15,101,1,9,
10003,15,104,1,17,
10003,15,104,4,0,
10003,15,105,1,81,
10003,15,113,4,0,
10003,15,115,4,0,
10003,15,129,1,49,
10003,15,138,4,0,
10003,15,148,4,0,
10003,15,156,4,0,
10003,15,157,4,0,
10003,15,164,4,0,
10003,15,182,4,0,
10003,15,207,4,0,
10003,15,214,4,0,
10003,15,216,4,0,
10003,15,218,4,0,
10003,15,219,4,0,
10003,15,228,1,33,
10003,15,237,4,0,
10003,15,240,4,0,
10003,15,241,4,0,
10003,15,244,4,0,
10003,15,245,1,97,
10003,15,247,4,0,
10003,15,249,4,0,
10003,15,259,4,0,
10003,15,263,4,0,
10003,15,269,4,0,
10003,15,280,4,0,
10003,15,282,1,25,
10003,15,317,4,0,
10003,15,332,4,0,
10003,15,347,4,0,
10003,15,354,1,89,
10003,15,374,4,0,
10003,15,375,1,57,
10003,15,398,4,0,
10003,15,399,4,0,
10003,15,411,4,0,
10003,15,412,4,0,
10003,15,416,4,0,
10003,15,428,1,65,
10003,15,430,4,0,
10003,15,433,4,0,
10003,15,447,4,0,
10003,15,451,4,0,
10003,15,473,4,0,
10003,15,490,4,0,
10003,15,496,4,0,
10003,15,590,4,0,
10003,15,612,4,0,
10003,16,7,3,0,
10003,16,8,3,0,
10003,16,9,3,0,
10003,16,15,4,0,
10003,16,20,3,0,
10003,16,35,1,1,2
10003,16,43,1,1,1
10003,16,58,4,0,
10003,16,63,4,0,
10003,16,67,3,0,
10003,16,70,4,0,
10003,16,76,4,0,
10003,16,85,4,0,
10003,16,86,4,0,
10003,16,87,4,0,
10003,16,92,4,0,
10003,16,94,1,31,1
10003,16,94,4,0,
10003,16,97,1,55,1
10003,16,101,1,7,1
10003,16,104,1,13,1
10003,16,104,4,0,
10003,16,105,1,61,1
10003,16,113,4,0,
10003,16,115,4,0,
10003,16,129,1,37,1
10003,16,138,4,0,
10003,16,148,4,0,
10003,16,156,4,0,
10003,16,157,4,0,
10003,16,164,4,0,
10003,16,173,3,0,
10003,16,182,4,0,
10003,16,207,4,0,
10003,16,214,4,0,
10003,16,216,4,0,
10003,16,218,4,0,
10003,16,219,4,0,
10003,16,228,1,25,1
10003,16,237,4,0,
10003,16,240,4,0,
10003,16,241,4,0,
10003,16,244,4,0,
10003,16,245,1,73,1
10003,16,247,4,0,
10003,16,249,4,0,
10003,16,259,4,0,
10003,16,263,4,0,
10003,16,264,3,0,
10003,16,269,4,0,
10003,16,271,3,0,
10003,16,272,3,0,
10003,16,277,3,0,
10003,16,278,3,0,
10003,16,280,4,0,
10003,16,282,1,19,1
10003,16,282,3,0,
10003,16,285,3,0,
10003,16,289,3,0,
10003,16,290,4,0,
10003,16,317,4,0,
10003,16,324,3,0,
10003,16,332,4,0,
10003,16,347,4,0,
10003,16,351,3,0,
10003,16,352,3,0,
10003,16,354,1,67,1
10003,16,356,3,0,
10003,16,374,4,0,
10003,16,375,1,43,1
10003,16,398,4,0,
10003,16,399,4,0,
10003,16,409,3,0,
10003,16,411,4,0,
10003,16,412,4,0,
10003,16,416,4,0,
10003,16,428,1,49,1
10003,16,428,3,0,
10003,16,430,4,0,
10003,16,433,4,0,
10003,16,446,3,0,
10003,16,447,4,0,
10003,16,451,4,0,
10003,16,472,3,0,
10003,16,473,4,0,
10003,16,490,4,0,
10003,16,496,4,0,
10003,16,590,4,0,
10003,16,612,4,0,
10003,17,35,1,1,2
10003,17,43,1,1,1
10003,17,58,4,0,
10003,17,63,4,0,
10003,17,76,4,0,
10003,17,85,4,0,
10003,17,86,4,0,
10003,17,87,4,0,
10003,17,92,4,0,
10003,17,94,1,31,
10003,17,94,4,0,
10003,17,97,1,55,
10003,17,101,1,7,
10003,17,104,1,13,
10003,17,104,4,0,
10003,17,105,1,61,
10003,17,113,4,0,
10003,17,115,4,0,
10003,17,129,1,37,
10003,17,138,4,0,
10003,17,156,4,0,
10003,17,157,4,0,
10003,17,164,4,0,
10003,17,182,4,0,
10003,17,207,4,0,
10003,17,214,4,0,
10003,17,216,4,0,
10003,17,218,4,0,
10003,17,219,4,0,
10003,17,228,1,25,
10003,17,237,4,0,
10003,17,240,4,0,
10003,17,241,4,0,
10003,17,244,4,0,
10003,17,245,1,73,
10003,17,247,4,0,
10003,17,259,4,0,
10003,17,263,4,0,
10003,17,269,4,0,
10003,17,280,4,0,
10003,17,282,1,19,
10003,17,317,4,0,
10003,17,332,4,0,
10003,17,347,4,0,
10003,17,354,1,67,
10003,17,374,4,0,
10003,17,375,1,43,
10003,17,398,4,0,
10003,17,399,4,0,
10003,17,411,4,0,
10003,17,412,4,0,
10003,17,416,4,0,
10003,17,428,1,49,
10003,17,430,4,0,
10003,17,433,4,0,
10003,17,447,4,0,
10003,17,451,4,0,
10003,17,473,4,0,
10003,17,490,4,0,
10003,17,496,4,0,
10003,17,590,4,0,
10003,17,693,4,0,
10003,18,35,1,1,2
10003,18,43,1,1,1
10003,18,58,4,0,
10003,18,63,4,0,
10003,18,76,4,0,
10003,18,85,4,0,
10003,18,86,4,0,
10003,18,87,4,0,
10003,18,92,4,0,
10003,18,94,1,31,
10003,18,94,4,0,
10003,18,97,1,55,
10003,18,101,1,7,
10003,18,104,1,13,
10003,18,104,4,0,
10003,18,105,1,61,
10003,18,113,4,0,
10003,18,115,4,0,
10003,18,129,1,37,
10003,18,138,4,0,
10003,18,156,4,0,
10003,18,157,4,0,
10003,18,164,4,0,
10003,18,182,4,0,
10003,18,207,4,0,
10003,18,214,4,0,
10003,18,216,4,0,
10003,18,218,4,0,
10003,18,219,4,0,
10003,18,228,1,25,
10003,18,237,4,0,
10003,18,240,4,0,
10003,18,241,4,0,
10003,18,244,4,0,
10003,18,245,1,73,
10003,18,247,4,0,
10003,18,259,4,0,
10003,18,263,4,0,
10003,18,269,4,0,
10003,18,280,4,0,
10003,18,282,1,19,
10003,18,317,4,0,
10003,18,332,4,0,
10003,18,347,4,0,
10003,18,354,1,67,
10003,18,374,4,0,
10003,18,375,1,43,
10003,18,398,4,0,
10003,18,399,4,0,
10003,18,411,4,0,
10003,18,412,4,0,
10003,18,416,4,0,
10003,18,428,1,49,
10003,18,430,4,0,
10003,18,433,4,0,
10003,18,447,4,0,
10003,18,451,4,0,
10003,18,473,4,0,
10003,18,490,4,0,
10003,18,496,4,0,
10003,18,590,4,0,
10003,18,693,4,0,
10004,8,33,1,1,
10004,8,60,1,32,
10004,8,63,4,0,
10004,8,89,4,0,
10004,8,90,1,47,
10004,8,91,4,0,
10004,8,92,4,0,
10004,8,93,1,23,
10004,8,94,1,44,
10004,8,94,4,0,
10004,8,104,4,0,
10004,8,106,1,29,
10004,8,138,4,0,
10004,8,148,4,0,
10004,8,156,4,0,
10004,8,164,4,0,
10004,8,168,4,0,
10004,8,175,1,38,
10004,8,182,1,10,
10004,8,182,4,0,
10004,8,201,4,0,
10004,8,203,4,0,
10004,8,207,4,0,
10004,8,213,1,41,
10004,8,213,4,0,
10004,8,214,4,0,
10004,8,216,4,0,
10004,8,218,4,0,
10004,8,219,4,0,
10004,8,237,1,20,
10004,8,237,4,0,
10004,8,240,4,0,
10004,8,241,4,0,
10004,8,244,4,0,
10004,8,247,4,0,
10004,8,263,4,0,
10004,8,285,4,0,
10004,8,290,4,0,
10004,8,317,4,0,
10004,8,350,1,26,
10004,8,363,4,0,
10004,8,416,4,0,
10004,8,445,1,35,
10004,8,445,4,0,
10004,9,33,1,1,
10004,9,60,1,32,
10004,9,63,4,0,
10004,9,89,4,0,
10004,9,90,1,47,
10004,9,91,4,0,
10004,9,92,4,0,
10004,9,93,1,23,
10004,9,94,1,44,
10004,9,94,4,0,
10004,9,104,4,0,
10004,9,106,1,29,
10004,9,138,4,0,
10004,9,148,4,0,
10004,9,156,4,0,
10004,9,164,4,0,
10004,9,168,4,0,
10004,9,173,3,0,
10004,9,175,1,38,
10004,9,182,1,10,
10004,9,182,4,0,
10004,9,189,3,0,
10004,9,201,4,0,
10004,9,203,4,0,
10004,9,205,3,0,
10004,9,207,4,0,
10004,9,213,1,41,
10004,9,213,4,0,
10004,9,214,4,0,
10004,9,216,4,0,
10004,9,218,4,0,
10004,9,219,4,0,
10004,9,237,1,20,
10004,9,237,4,0,
10004,9,240,4,0,
10004,9,241,4,0,
10004,9,244,4,0,
10004,9,247,4,0,
10004,9,253,3,0,
10004,9,263,4,0,
10004,9,283,3,0,
10004,9,285,4,0,
10004,9,290,4,0,
10004,9,317,4,0,
10004,9,324,3,0,
10004,9,350,1,26,
10004,9,363,4,0,
10004,9,389,3,0,
10004,9,414,3,0,
10004,9,416,4,0,
10004,9,445,1,35,
10004,9,445,4,0,
10004,9,450,1,15,
10004,10,33,1,1,
10004,10,60,1,32,
10004,10,63,4,0,
10004,10,81,3,0,
10004,10,89,4,0,
10004,10,90,1,47,
10004,10,91,4,0,
10004,10,92,4,0,
10004,10,93,1,23,
10004,10,94,1,44,
10004,10,94,4,0,
10004,10,104,4,0,
10004,10,106,1,29,
10004,10,138,4,0,
10004,10,148,4,0,
10004,10,156,4,0,
10004,10,164,4,0,
10004,10,168,4,0,
10004,10,173,3,0,
10004,10,175,1,38,
10004,10,182,1,10,
10004,10,182,4,0,
10004,10,189,3,0,
10004,10,201,4,0,
10004,10,203,4,0,
10004,10,205,3,0,
10004,10,207,4,0,
10004,10,213,1,41,
10004,10,213,4,0,
10004,10,214,4,0,
10004,10,216,4,0,
10004,10,218,4,0,
10004,10,219,4,0,
10004,10,237,1,20,
10004,10,237,4,0,
10004,10,240,4,0,
10004,10,241,4,0,
10004,10,244,4,0,
10004,10,247,4,0,
10004,10,253,3,0,
10004,10,263,4,0,
10004,10,283,3,0,
10004,10,285,4,0,
10004,10,290,4,0,
10004,10,317,4,0,
10004,10,324,3,0,
10004,10,350,1,26,
10004,10,363,4,0,
10004,10,389,3,0,
10004,10,414,3,0,
10004,10,416,4,0,
10004,10,445,1,35,
10004,10,445,4,0,
10004,10,450,1,15,
10004,10,450,3,0,
10004,11,33,1,1,
10004,11,60,1,32,
10004,11,63,4,0,
10004,11,89,4,0,
10004,11,90,1,47,
10004,11,91,4,0,
10004,11,92,4,0,
10004,11,93,1,23,
10004,11,94,1,44,
10004,11,94,4,0,
10004,11,104,4,0,
10004,11,106,1,29,
10004,11,138,4,0,
10004,11,148,4,0,
10004,11,156,4,0,
10004,11,164,4,0,
10004,11,168,4,0,
10004,11,175,1,38,
10004,11,182,1,10,
10004,11,182,4,0,
10004,11,201,4,0,
10004,11,207,4,0,
10004,11,213,1,41,
10004,11,213,4,0,
10004,11,216,4,0,
10004,11,218,4,0,
10004,11,219,4,0,
10004,11,237,1,20,
10004,11,237,4,0,
10004,11,240,4,0,
10004,11,241,4,0,
10004,11,244,4,0,
10004,11,247,4,0,
10004,11,263,4,0,
10004,11,317,4,0,
10004,11,350,1,26,
10004,11,416,4,0,
10004,11,445,1,35,
10004,11,450,1,15,
10004,11,474,4,0,
10004,11,496,4,0,
10004,11,522,4,0,
10004,11,523,4,0,
10004,14,33,1,1,
10004,14,60,1,32,
10004,14,63,4,0,
10004,14,89,4,0,
10004,14,90,1,47,
10004,14,91,4,0,
10004,14,92,4,0,
10004,14,93,1,23,
10004,14,94,1,44,
10004,14,94,4,0,
10004,14,104,4,0,
10004,14,106,1,29,
10004,14,138,4,0,
10004,14,148,4,0,
10004,14,156,4,0,
10004,14,164,4,0,
10004,14,168,4,0,
10004,14,173,3,0,
10004,14,175,1,38,
10004,14,182,1,10,
10004,14,182,4,0,
10004,14,201,4,0,
10004,14,207,4,0,
10004,14,213,1,41,
10004,14,213,4,0,
10004,14,214,3,0,
10004,14,216,4,0,
10004,14,218,4,0,
10004,14,219,4,0,
10004,14,237,1,20,
10004,14,237,4,0,
10004,14,240,4,0,
10004,14,241,4,0,
10004,14,244,4,0,
10004,14,247,4,0,
10004,14,253,3,0,
10004,14,263,4,0,
10004,14,283,3,0,
10004,14,285,3,0,
10004,14,317,4,0,
10004,14,324,3,0,
10004,14,350,1,26,
10004,14,414,3,0,
10004,14,416,4,0,
10004,14,445,1,35,
10004,14,446,3,0,
10004,14,450,1,15,
10004,14,450,3,0,
10004,14,474,4,0,
10004,14,496,4,0,
10004,14,522,4,0,
10004,14,523,4,0,
10004,14,527,3,0,
10004,15,33,1,1,
10004,15,60,1,32,
10004,15,63,4,0,
10004,15,89,4,0,
10004,15,90,1,47,
10004,15,91,4,0,
10004,15,92,4,0,
10004,15,93,1,23,
10004,15,94,1,44,
10004,15,94,4,0,
10004,15,104,4,0,
10004,15,106,1,29,
10004,15,138,4,0,
10004,15,148,4,0,
10004,15,156,4,0,
10004,15,164,4,0,
10004,15,168,4,0,
10004,15,175,1,38,
10004,15,182,1,10,
10004,15,182,4,0,
10004,15,201,4,0,
10004,15,207,4,0,
10004,15,213,1,41,
10004,15,213,4,0,
10004,15,214,4,0,
10004,15,216,4,0,
10004,15,218,4,0,
10004,15,219,4,0,
10004,15,237,1,20,
10004,15,237,4,0,
10004,15,240,4,0,
10004,15,241,4,0,
10004,15,244,4,0,
10004,15,247,4,0,
10004,15,263,4,0,
10004,15,317,4,0,
10004,15,350,1,26,
10004,15,416,4,0,
10004,15,445,1,35,
10004,15,450,1,15,
10004,15,474,4,0,
10004,15,496,4,0,
10004,15,522,4,0,
10004,15,523,4,0,
10004,15,590,4,0,
10004,15,611,4,0,
10004,16,33,1,1,1
10004,16,60,1,32,1
10004,16,63,4,0,
10004,16,89,4,0,
10004,16,90,1,47,1
10004,16,91,4,0,
10004,16,92,4,0,
10004,16,93,1,23,1
10004,16,94,1,44,1
10004,16,94,4,0,
10004,16,104,4,0,
10004,16,106,1,29,1
10004,16,138,4,0,
10004,16,148,4,0,
10004,16,156,4,0,
10004,16,164,4,0,
10004,16,168,4,0,
10004,16,173,3,0,
10004,16,175,1,38,1
10004,16,182,1,10,1
10004,16,182,4,0,
10004,16,201,4,0,
10004,16,207,4,0,
10004,16,213,1,41,1
10004,16,213,4,0,
10004,16,214,4,0,
10004,16,216,4,0,
10004,16,218,4,0,
10004,16,219,4,0,
10004,16,237,1,20,1
10004,16,237,4,0,
10004,16,240,4,0,
10004,16,241,4,0,
10004,16,244,4,0,
10004,16,247,4,0,
10004,16,253,3,0,
10004,16,263,4,0,
10004,16,283,3,0,
10004,16,285,3,0,
10004,16,290,4,0,
10004,16,317,4,0,
10004,16,324,3,0,
10004,16,350,1,26,1
10004,16,414,3,0,
10004,16,416,4,0,
10004,16,445,1,35,1
10004,16,446,3,0,
10004,16,450,1,15,1
10004,16,450,3,0,
10004,16,474,4,0,
10004,16,496,4,0,
10004,16,522,4,0,
10004,16,523,4,0,
10004,16,527,3,0,
10004,16,590,4,0,
10004,16,611,4,0,
10004,17,33,1,1,3
10004,17,60,1,32,
10004,17,63,4,0,
10004,17,89,4,0,
10004,17,90,1,47,
10004,17,92,4,0,
10004,17,93,1,23,
10004,17,94,1,44,
10004,17,94,4,0,
10004,17,104,4,0,
10004,17,106,1,29,
10004,17,138,4,0,
10004,17,156,4,0,
10004,17,164,4,0,
10004,17,168,4,0,
10004,17,175,1,38,
10004,17,182,1,1,4
10004,17,182,1,10,
10004,17,182,4,0,
10004,17,201,4,0,
10004,17,207,4,0,
10004,17,213,1,41,
10004,17,213,4,0,
10004,17,214,4,0,
10004,17,216,4,0,
10004,17,218,4,0,
10004,17,219,4,0,
10004,17,237,1,20,
10004,17,237,4,0,
10004,17,240,4,0,
10004,17,241,4,0,
10004,17,244,4,0,
10004,17,247,4,0,
10004,17,263,4,0,
10004,17,317,4,0,
10004,17,350,1,26,
10004,17,389,1,1,2
10004,17,405,1,50,
10004,17,416,4,0,
10004,17,445,1,35,
10004,17,450,1,1,5
10004,17,450,1,15,
10004,17,474,4,0,
10004,17,483,1,0,
10004,17,483,1,1,1
10004,17,496,4,0,
10004,17,523,4,0,
10004,17,590,4,0,
10004,17,611,4,0,
10004,18,33,1,1,3
10004,18,60,1,32,
10004,18,63,4,0,
10004,18,89,4,0,
10004,18,90,1,47,
10004,18,92,4,0,
10004,18,93,1,23,
10004,18,94,1,44,
10004,18,94,4,0,
10004,18,104,4,0,
10004,18,106,1,29,
10004,18,138,4,0,
10004,18,156,4,0,
10004,18,164,4,0,
10004,18,168,4,0,
10004,18,175,1,38,
10004,18,182,1,1,4
10004,18,182,1,10,
10004,18,182,4,0,
10004,18,201,4,0,
10004,18,207,4,0,
10004,18,213,1,41,
10004,18,213,4,0,
10004,18,214,4,0,
10004,18,216,4,0,
10004,18,218,4,0,
10004,18,219,4,0,
10004,18,237,1,20,
10004,18,237,4,0,
10004,18,240,4,0,
10004,18,241,4,0,
10004,18,244,4,0,
10004,18,247,4,0,
10004,18,263,4,0,
10004,18,317,4,0,
10004,18,350,1,26,
10004,18,389,1,1,2
10004,18,405,1,50,
10004,18,416,4,0,
10004,18,445,1,35,
10004,18,450,1,1,5
10004,18,450,1,15,
10004,18,474,4,0,
10004,18,483,1,0,
10004,18,483,1,1,1
10004,18,496,4,0,
10004,18,523,4,0,
10004,18,590,4,0,
10004,18,611,4,0,
10005,8,33,1,1,
10005,8,60,1,32,
10005,8,63,4,0,
10005,8,92,4,0,
10005,8,93,1,23,
10005,8,94,1,44,
10005,8,94,4,0,
10005,8,104,4,0,
10005,8,138,4,0,
10005,8,148,4,0,
10005,8,156,4,0,
10005,8,164,4,0,
10005,8,168,4,0,
10005,8,175,1,38,
10005,8,182,1,10,
10005,8,182,4,0,
10005,8,203,4,0,
10005,8,207,4,0,
10005,8,213,1,41,
10005,8,213,4,0,
10005,8,214,4,0,
10005,8,216,4,0,
10005,8,218,4,0,
10005,8,219,4,0,
10005,8,237,1,20,
10005,8,237,4,0,
10005,8,240,4,0,
10005,8,241,4,0,
10005,8,244,4,0,
10005,8,247,4,0,
10005,8,263,4,0,
10005,8,285,4,0,
10005,8,290,4,0,
10005,8,319,1,29,
10005,8,360,4,0,
10005,8,363,4,0,
10005,8,416,4,0,
10005,8,429,1,26,
10005,8,430,4,0,
10005,8,442,1,47,
10005,8,445,1,35,
10005,8,445,4,0,
10005,8,446,4,0,
10005,9,33,1,1,
10005,9,60,1,32,
10005,9,63,4,0,
10005,9,92,4,0,
10005,9,93,1,23,
10005,9,94,1,44,
10005,9,94,4,0,
10005,9,104,4,0,
10005,9,138,4,0,
10005,9,148,4,0,
10005,9,156,4,0,
10005,9,164,4,0,
10005,9,168,4,0,
10005,9,173,3,0,
10005,9,175,1,38,
10005,9,182,1,10,
10005,9,182,4,0,
10005,9,203,4,0,
10005,9,207,4,0,
10005,9,213,1,41,
10005,9,213,4,0,
10005,9,214,4,0,
10005,9,216,4,0,
10005,9,218,4,0,
10005,9,219,4,0,
10005,9,237,1,20,
10005,9,237,4,0,
10005,9,240,4,0,
10005,9,241,4,0,
10005,9,244,4,0,
10005,9,247,4,0,
10005,9,253,3,0,
10005,9,263,4,0,
10005,9,283,3,0,
10005,9,285,4,0,
10005,9,290,4,0,
10005,9,319,1,29,
10005,9,324,3,0,
10005,9,334,3,0,
10005,9,360,4,0,
10005,9,363,4,0,
10005,9,389,3,0,
10005,9,393,3,0,
10005,9,416,4,0,
10005,9,429,1,26,
10005,9,430,4,0,
10005,9,441,3,0,
10005,9,442,1,47,
10005,9,442,3,0,
10005,9,445,1,35,
10005,9,445,4,0,
10005,9,446,4,0,
10005,9,450,1,15,
10005,10,33,1,1,
10005,10,60,1,32,
10005,10,63,4,0,
10005,10,81,3,0,
10005,10,92,4,0,
10005,10,93,1,23,
10005,10,94,1,44,
10005,10,94,4,0,
10005,10,104,4,0,
10005,10,138,4,0,
10005,10,148,4,0,
10005,10,156,4,0,
10005,10,164,4,0,
10005,10,168,4,0,
10005,10,173,3,0,
10005,10,175,1,38,
10005,10,182,1,10,
10005,10,182,4,0,
10005,10,203,4,0,
10005,10,207,4,0,
10005,10,213,1,41,
10005,10,213,4,0,
10005,10,214,4,0,
10005,10,216,4,0,
10005,10,218,4,0,
10005,10,219,4,0,
10005,10,237,1,20,
10005,10,237,4,0,
10005,10,240,4,0,
10005,10,241,4,0,
10005,10,244,4,0,
10005,10,247,4,0,
10005,10,253,3,0,
10005,10,263,4,0,
10005,10,283,3,0,
10005,10,285,4,0,
10005,10,290,4,0,
10005,10,319,1,29,
10005,10,324,3,0,
10005,10,334,3,0,
10005,10,360,4,0,
10005,10,363,4,0,
10005,10,389,3,0,
10005,10,393,3,0,
10005,10,416,4,0,
10005,10,429,1,26,
10005,10,430,4,0,
10005,10,441,3,0,
10005,10,442,1,47,
10005,10,442,3,0,
10005,10,445,1,35,
10005,10,445,4,0,
10005,10,446,4,0,
10005,10,450,1,15,
10005,10,450,3,0,
10005,11,33,1,1,
10005,11,60,1,32,
10005,11,63,4,0,
10005,11,92,4,0,
10005,11,93,1,23,
10005,11,94,1,44,
10005,11,94,4,0,
10005,11,104,4,0,
10005,11,138,4,0,
10005,11,148,4,0,
10005,11,156,4,0,
10005,11,164,4,0,
10005,11,168,4,0,
10005,11,175,1,38,
10005,11,182,1,10,
10005,11,182,4,0,
10005,11,207,4,0,
10005,11,213,1,41,
10005,11,213,4,0,
10005,11,216,4,0,
10005,11,218,4,0,
10005,11,219,4,0,
10005,11,237,1,20,
10005,11,237,4,0,
10005,11,240,4,0,
10005,11,241,4,0,
10005,11,244,4,0,
10005,11,247,4,0,
10005,11,263,4,0,
10005,11,319,1,29,
10005,11,360,4,0,
10005,11,416,4,0,
10005,11,429,1,26,
10005,11,430,4,0,
10005,11,442,1,47,
10005,11,445,1,35,
10005,11,450,1,15,
10005,11,474,4,0,
10005,11,496,4,0,
10005,11,522,4,0,
10005,14,33,1,1,
10005,14,60,1,32,
10005,14,63,4,0,
10005,14,92,4,0,
10005,14,93,1,23,
10005,14,94,1,44,
10005,14,94,4,0,
10005,14,104,4,0,
10005,14,138,4,0,
10005,14,148,4,0,
10005,14,156,4,0,
10005,14,164,4,0,
10005,14,168,4,0,
10005,14,173,3,0,
10005,14,175,1,38,
10005,14,182,1,10,
10005,14,182,4,0,
10005,14,207,4,0,
10005,14,213,1,41,
10005,14,213,4,0,
10005,14,214,3,0,
10005,14,216,4,0,
10005,14,218,4,0,
10005,14,219,4,0,
10005,14,237,1,20,
10005,14,237,4,0,
10005,14,240,4,0,
10005,14,241,4,0,
10005,14,244,4,0,
10005,14,247,4,0,
10005,14,253,3,0,
10005,14,263,4,0,
10005,14,283,3,0,
10005,14,285,3,0,
10005,14,319,1,29,
10005,14,324,3,0,
10005,14,334,3,0,
10005,14,360,4,0,
10005,14,393,3,0,
10005,14,416,4,0,
10005,14,429,1,26,
10005,14,430,4,0,
10005,14,441,3,0,
10005,14,442,1,47,
10005,14,442,3,0,
10005,14,445,1,35,
10005,14,446,3,0,
10005,14,450,1,15,
10005,14,450,3,0,
10005,14,474,4,0,
10005,14,496,4,0,
10005,14,522,4,0,
10005,14,527,3,0,
10005,15,33,1,1,
10005,15,60,1,32,
10005,15,63,4,0,
10005,15,92,4,0,
10005,15,93,1,23,
10005,15,94,1,44,
10005,15,94,4,0,
10005,15,104,4,0,
10005,15,138,4,0,
10005,15,148,4,0,
10005,15,156,4,0,
10005,15,164,4,0,
10005,15,168,4,0,
10005,15,175,1,38,
10005,15,182,1,10,
10005,15,182,4,0,
10005,15,207,4,0,
10005,15,213,1,41,
10005,15,213,4,0,
10005,15,214,4,0,
10005,15,216,4,0,
10005,15,218,4,0,
10005,15,219,4,0,
10005,15,237,1,20,
10005,15,237,4,0,
10005,15,240,4,0,
10005,15,241,4,0,
10005,15,244,4,0,
10005,15,247,4,0,
10005,15,263,4,0,
10005,15,319,1,29,
10005,15,360,4,0,
10005,15,416,4,0,
10005,15,429,1,26,
10005,15,430,4,0,
10005,15,442,1,47,
10005,15,445,1,35,
10005,15,450,1,15,
10005,15,474,4,0,
10005,15,496,4,0,
10005,15,522,4,0,
10005,15,590,4,0,
10005,15,611,4,0,
10005,16,33,1,1,1
10005,16,60,1,32,1
10005,16,63,4,0,
10005,16,92,4,0,
10005,16,93,1,23,1
10005,16,94,1,44,1
10005,16,94,4,0,
10005,16,104,4,0,
10005,16,138,4,0,
10005,16,148,4,0,
10005,16,156,4,0,
10005,16,164,4,0,
10005,16,168,4,0,
10005,16,173,3,0,
10005,16,175,1,38,1
10005,16,182,1,10,1
10005,16,182,4,0,
10005,16,207,4,0,
10005,16,213,1,41,1
10005,16,213,4,0,
10005,16,214,4,0,
10005,16,216,4,0,
10005,16,218,4,0,
10005,16,219,4,0,
10005,16,237,1,20,1
10005,16,237,4,0,
10005,16,240,4,0,
10005,16,241,4,0,
10005,16,244,4,0,
10005,16,247,4,0,
10005,16,253,3,0,
10005,16,263,4,0,
10005,16,283,3,0,
10005,16,285,3,0,
10005,16,290,4,0,
10005,16,319,1,29,1
10005,16,324,3,0,
10005,16,334,3,0,
10005,16,360,4,0,
10005,16,393,3,0,
10005,16,416,4,0,
10005,16,429,1,26,1
10005,16,430,4,0,
10005,16,441,3,0,
10005,16,442,1,47,1
10005,16,442,3,0,
10005,16,445,1,35,1
10005,16,446,3,0,
10005,16,450,1,15,1
10005,16,450,3,0,
10005,16,474,4,0,
10005,16,496,4,0,
10005,16,522,4,0,
10005,16,527,3,0,
10005,16,590,4,0,
10005,16,611,4,0,
10005,17,33,1,1,4
10005,17,60,1,32,
10005,17,63,4,0,
10005,17,92,4,0,
10005,17,93,1,23,
10005,17,94,1,44,
10005,17,94,4,0,
10005,17,104,4,0,
10005,17,138,4,0,
10005,17,156,4,0,
10005,17,164,4,0,
10005,17,168,4,0,
10005,17,175,1,38,
10005,17,182,1,1,5
10005,17,182,1,10,
10005,17,182,4,0,
10005,17,207,4,0,
10005,17,213,1,41,
10005,17,213,4,0,
10005,17,214,4,0,
10005,17,216,4,0,
10005,17,218,4,0,
10005,17,219,4,0,
10005,17,237,1,20,
10005,17,237,4,0,
10005,17,240,4,0,
10005,17,241,4,0,
10005,17,244,4,0,
10005,17,247,4,0,
10005,17,263,4,0,
10005,17,319,1,29,
10005,17,360,4,0,
10005,17,368,1,1,2
10005,17,389,1,1,3
10005,17,405,1,50,
10005,17,416,4,0,
10005,17,429,1,26,
10005,17,430,4,0,
10005,17,442,1,47,
10005,17,445,1,35,
10005,17,450,1,1,6
10005,17,450,1,15,
10005,17,474,4,0,
10005,17,483,1,0,
10005,17,483,1,1,1
10005,17,496,4,0,
10005,17,590,4,0,
10005,17,611,4,0,
10005,18,33,1,1,4
10005,18,60,1,32,
10005,18,63,4,0,
10005,18,92,4,0,
10005,18,93,1,23,
10005,18,94,1,44,
10005,18,94,4,0,
10005,18,104,4,0,
10005,18,138,4,0,
10005,18,156,4,0,
10005,18,164,4,0,
10005,18,168,4,0,
10005,18,175,1,38,
10005,18,182,1,1,5
10005,18,182,1,10,
10005,18,182,4,0,
10005,18,207,4,0,
10005,18,213,1,41,
10005,18,213,4,0,
10005,18,214,4,0,
10005,18,216,4,0,
10005,18,218,4,0,
10005,18,219,4,0,
10005,18,237,1,20,
10005,18,237,4,0,
10005,18,240,4,0,
10005,18,241,4,0,
10005,18,244,4,0,
10005,18,247,4,0,
10005,18,263,4,0,
10005,18,319,1,29,
10005,18,360,4,0,
10005,18,368,1,1,2
10005,18,389,1,1,3
10005,18,405,1,50,
10005,18,416,4,0,
10005,18,429,1,26,
10005,18,430,4,0,
10005,18,442,1,47,
10005,18,445,1,35,
10005,18,450,1,1,6
10005,18,450,1,15,
10005,18,474,4,0,
10005,18,483,1,0,
10005,18,483,1,1,1
10005,18,496,4,0,
10005,18,590,4,0,
10005,18,611,4,0,
10006,9,14,4,0,
10006,9,63,4,0,
10006,9,73,1,19,
10006,9,74,1,1,
10006,9,76,4,0,
10006,9,92,4,0,
10006,9,94,4,0,
10006,9,98,1,28,
10006,9,104,4,0,
10006,9,129,3,0,
10006,9,148,4,0,
10006,9,156,4,0,
10006,9,164,4,0,
10006,9,173,3,0,
10006,9,182,4,0,
10006,9,186,1,82,
10006,9,189,3,0,
10006,9,202,4,0,
10006,9,203,4,0,
10006,9,207,4,0,
10006,9,214,4,0,
10006,9,216,4,0,
10006,9,218,4,0,
10006,9,219,4,0,
10006,9,230,1,37,
10006,9,235,3,0,
10006,9,237,4,0,
10006,9,241,4,0,
10006,9,244,4,0,
10006,9,263,4,0,
10006,9,290,4,0,
10006,9,314,3,0,
10006,9,331,4,0,
10006,9,345,1,10,
10006,9,363,1,46,
10006,9,363,4,0,
10006,9,387,3,0,
10006,9,388,1,55,
10006,9,402,3,0,
10006,9,403,1,64,
10006,9,412,1,73,
10006,9,412,4,0,
10006,9,416,4,0,
10006,9,428,3,0,
10006,9,437,1,91,
10006,9,447,4,0,
10006,9,465,1,100,
10006,9,466,3,0,
10006,10,14,4,0,
10006,10,29,3,0,
10006,10,63,4,0,
10006,10,73,1,19,
10006,10,74,1,1,
10006,10,76,4,0,
10006,10,92,4,0,
10006,10,94,4,0,
10006,10,98,1,28,
10006,10,104,4,0,
10006,10,129,3,0,
10006,10,148,4,0,
10006,10,156,4,0,
10006,10,164,4,0,
10006,10,173,3,0,
10006,10,182,4,0,
10006,10,186,1,82,
10006,10,189,3,0,
10006,10,202,4,0,
10006,10,203,4,0,
10006,10,207,4,0,
10006,10,214,4,0,
10006,10,216,4,0,
10006,10,218,4,0,
10006,10,219,4,0,
10006,10,230,1,37,
10006,10,235,3,0,
10006,10,237,4,0,
10006,10,241,4,0,
10006,10,244,4,0,
10006,10,263,4,0,
10006,10,290,4,0,
10006,10,314,3,0,
10006,10,331,4,0,
10006,10,345,1,10,
10006,10,363,1,46,
10006,10,363,4,0,
10006,10,366,3,0,
10006,10,387,3,0,
10006,10,388,1,55,
10006,10,388,3,0,
10006,10,402,3,0,
10006,10,403,1,64,
10006,10,412,1,73,
10006,10,412,4,0,
10006,10,416,4,0,
10006,10,428,3,0,
10006,10,437,1,91,
10006,10,447,4,0,
10006,10,465,1,100,
10006,10,466,3,0,
10006,11,14,4,0,
10006,11,63,4,0,
10006,11,73,1,19,
10006,11,74,1,1,
10006,11,76,4,0,
10006,11,92,4,0,
10006,11,94,4,0,
10006,11,98,1,28,
10006,11,104,4,0,
10006,11,148,4,0,
10006,11,156,4,0,
10006,11,164,4,0,
10006,11,182,4,0,
10006,11,186,1,82,
10006,11,207,4,0,
10006,11,216,4,0,
10006,11,218,4,0,
10006,11,219,4,0,
10006,11,230,1,37,
10006,11,237,4,0,
10006,11,241,4,0,
10006,11,244,4,0,
10006,11,263,4,0,
10006,11,345,1,10,
10006,11,363,1,46,
10006,11,388,1,55,
10006,11,403,1,64,
10006,11,412,1,73,
10006,11,412,4,0,
10006,11,416,4,0,
10006,11,437,1,91,
10006,11,447,4,0,
10006,11,465,1,100,
10006,11,496,4,0,
10006,14,14,4,0,
10006,14,63,4,0,
10006,14,73,1,19,
10006,14,74,1,1,
10006,14,76,4,0,
10006,14,92,4,0,
10006,14,94,4,0,
10006,14,98,1,28,
10006,14,104,4,0,
10006,14,148,4,0,
10006,14,156,4,0,
10006,14,164,4,0,
10006,14,173,3,0,
10006,14,182,4,0,
10006,14,186,1,82,
10006,14,202,3,0,
10006,14,207,4,0,
10006,14,214,3,0,
10006,14,216,4,0,
10006,14,218,4,0,
10006,14,219,4,0,
10006,14,230,1,37,
10006,14,235,3,0,
10006,14,237,4,0,
10006,14,241,4,0,
10006,14,244,4,0,
10006,14,263,4,0,
10006,14,343,3,0,
10006,14,345,1,10,
10006,14,363,1,46,
10006,14,366,3,0,
10006,14,387,3,0,
10006,14,388,1,55,
10006,14,388,3,0,
10006,14,402,3,0,
10006,14,403,1,64,
10006,14,412,1,73,
10006,14,412,4,0,
10006,14,416,4,0,
10006,14,428,3,0,
10006,14,437,1,91,
10006,14,447,4,0,
10006,14,465,1,100,
10006,14,496,4,0,
10006,15,14,4,0,
10006,15,63,4,0,
10006,15,73,1,19,
10006,15,74,1,1,
10006,15,76,4,0,
10006,15,92,4,0,
10006,15,94,4,0,
10006,15,98,1,28,
10006,15,104,4,0,
10006,15,148,4,0,
10006,15,156,4,0,
10006,15,164,4,0,
10006,15,182,4,0,
10006,15,186,1,82,
10006,15,207,4,0,
10006,15,214,4,0,
10006,15,216,4,0,
10006,15,218,4,0,
10006,15,219,4,0,
10006,15,230,1,37,
10006,15,237,4,0,
10006,15,241,4,0,
10006,15,244,4,0,
10006,15,263,4,0,
10006,15,267,4,0,
10006,15,345,1,10,
10006,15,363,1,46,
10006,15,388,1,55,
10006,15,403,1,64,
10006,15,412,1,73,
10006,15,412,4,0,
10006,15,416,4,0,
10006,15,437,1,91,
10006,15,447,4,0,
10006,15,465,1,100,
10006,15,496,4,0,
10006,15,590,4,0,
10006,15,605,4,0,
10006,16,14,4,0,
10006,16,63,4,0,
10006,16,73,1,19,1
10006,16,74,1,1,1
10006,16,76,4,0,
10006,16,92,4,0,
10006,16,94,4,0,
10006,16,98,1,28,1
10006,16,104,4,0,
10006,16,148,4,0,
10006,16,156,4,0,
10006,16,164,4,0,
10006,16,173,3,0,
10006,16,182,4,0,
10006,16,186,1,82,1
10006,16,202,3,0,
10006,16,207,4,0,
10006,16,214,4,0,
10006,16,216,4,0,
10006,16,218,4,0,
10006,16,219,4,0,
10006,16,230,1,37,1
10006,16,235,3,0,
10006,16,237,4,0,
10006,16,241,4,0,
10006,16,244,4,0,
10006,16,263,4,0,
10006,16,267,4,0,
10006,16,290,4,0,
10006,16,343,3,0,
10006,16,345,1,10,1
10006,16,363,1,46,1
10006,16,366,3,0,
10006,16,387,3,0,
10006,16,388,1,55,1
10006,16,388,3,0,
10006,16,402,3,0,
10006,16,403,1,64,1
10006,16,412,1,73,1
10006,16,412,4,0,
10006,16,416,4,0,
10006,16,428,3,0,
10006,16,437,1,91,1
10006,16,447,4,0,
10006,16,465,1,100,1
10006,16,496,4,0,
10006,16,590,4,0,
10006,16,605,4,0,
10006,17,14,4,0,
10006,17,63,4,0,
10006,17,73,1,19,
10006,17,74,1,1,
10006,17,76,4,0,
10006,17,92,4,0,
10006,17,94,4,0,
10006,17,98,1,28,
10006,17,104,4,0,
10006,17,156,4,0,
10006,17,164,4,0,
10006,17,182,4,0,
10006,17,186,1,82,
10006,17,207,4,0,
10006,17,214,4,0,
10006,17,216,4,0,
10006,17,218,4,0,
10006,17,219,4,0,
10006,17,230,1,37,
10006,17,237,4,0,
10006,17,241,4,0,
10006,17,244,4,0,
10006,17,263,4,0,
10006,17,267,4,0,
10006,17,345,1,10,
10006,17,363,1,46,
10006,17,388,1,55,
10006,17,403,1,64,
10006,17,412,1,73,
10006,17,412,4,0,
10006,17,416,4,0,
10006,17,437,1,91,
10006,17,447,4,0,
10006,17,465,1,100,
10006,17,496,4,0,
10006,17,590,4,0,
10006,17,605,4,0,
10006,18,14,4,0,
10006,18,63,4,0,
10006,18,73,1,19,
10006,18,74,1,1,
10006,18,76,4,0,
10006,18,92,4,0,
10006,18,94,4,0,
10006,18,98,1,28,
10006,18,104,4,0,
10006,18,156,4,0,
10006,18,164,4,0,
10006,18,182,4,0,
10006,18,186,1,82,
10006,18,207,4,0,
10006,18,214,4,0,
10006,18,216,4,0,
10006,18,218,4,0,
10006,18,219,4,0,
10006,18,230,1,37,
10006,18,237,4,0,
10006,18,241,4,0,
10006,18,244,4,0,
10006,18,263,4,0,
10006,18,267,4,0,
10006,18,345,1,10,
10006,18,363,1,46,
10006,18,388,1,55,
10006,18,403,1,64,
10006,18,412,1,73,
10006,18,412,4,0,
10006,18,416,4,0,
10006,18,437,1,91,
10006,18,447,4,0,
10006,18,465,1,100,
10006,18,496,4,0,
10006,18,590,4,0,
10006,18,605,4,0,
10007,9,15,4,0,
10007,9,19,4,0,
10007,9,46,4,0,
10007,9,63,4,0,
10007,9,70,4,0,
10007,9,85,4,0,
10007,9,86,4,0,
10007,9,87,4,0,
10007,9,89,4,0,
10007,9,92,4,0,
10007,9,94,4,0,
10007,9,104,4,0,
10007,9,129,3,0,
10007,9,138,4,0,
10007,9,156,4,0,
10007,9,163,1,70,
10007,9,164,4,0,
10007,9,173,3,0,
10007,9,180,3,0,
10007,9,182,4,0,
10007,9,184,1,1,2
10007,9,189,3,0,
10007,9,196,3,0,
10007,9,200,3,0,
10007,9,203,4,0,
10007,9,207,4,0,
10007,9,210,3,0,
10007,9,211,4,0,
10007,9,214,4,0,
10007,9,216,4,0,
10007,9,218,4,0,
10007,9,219,4,0,
10007,9,225,1,1,1
10007,9,231,4,0,
10007,9,237,4,0,
10007,9,239,3,0,
10007,9,240,4,0,
10007,9,241,4,0,
10007,9,244,4,0,
10007,9,246,1,20,
10007,9,246,3,0,
10007,9,247,4,0,
10007,9,249,4,0,
10007,9,261,4,0,
10007,9,263,4,0,
10007,9,290,4,0,
10007,9,314,3,0,
10007,9,318,4,0,
10007,9,332,4,0,
10007,9,337,1,30,
10007,9,337,4,0,
10007,9,347,4,0,
10007,9,351,4,0,
10007,9,363,4,0,
10007,9,371,4,0,
10007,9,377,1,50,
10007,9,396,1,90,
10007,9,399,4,0,
10007,9,401,3,0,
10007,9,406,4,0,
10007,9,412,4,0,
10007,9,414,1,60,
10007,9,414,3,0,
10007,9,416,4,0,
10007,9,421,1,80,
10007,9,421,4,0,
10007,9,431,4,0,
10007,9,432,4,0,
10007,9,434,3,0,
10007,9,442,3,0,
10007,9,444,4,0,
10007,9,451,4,0,
10007,9,466,1,10,
10007,9,466,3,0,
10007,9,467,1,40,
10007,10,15,4,0,
10007,10,19,4,0,
10007,10,29,3,0,
10007,10,46,4,0,
10007,10,63,4,0,
10007,10,70,4,0,
10007,10,85,4,0,
10007,10,86,4,0,
10007,10,87,4,0,
10007,10,89,4,0,
10007,10,92,4,0,
10007,10,94,4,0,
10007,10,104,4,0,
10007,10,129,3,0,
10007,10,138,4,0,
10007,10,156,4,0,
10007,10,163,1,15,
10007,10,164,4,0,
10007,10,173,3,0,
10007,10,180,3,0,
10007,10,182,4,0,
10007,10,184,1,1,2
10007,10,189,3,0,
10007,10,194,1,24,
10007,10,196,3,0,
10007,10,200,3,0,
10007,10,203,4,0,
10007,10,207,4,0,
10007,10,210,3,0,
10007,10,211,4,0,
10007,10,214,4,0,
10007,10,216,4,0,
10007,10,218,4,0,
10007,10,219,4,0,
10007,10,225,1,1,1
10007,10,231,4,0,
10007,10,237,4,0,
10007,10,239,3,0,
10007,10,240,4,0,
10007,10,241,4,0,
10007,10,244,4,0,
10007,10,246,1,10,
10007,10,246,3,0,
10007,10,247,4,0,
10007,10,249,4,0,
10007,10,261,4,0,
10007,10,263,4,0,
10007,10,277,3,0,
10007,10,290,4,0,
10007,10,314,3,0,
10007,10,318,4,0,
10007,10,332,4,0,
10007,10,337,1,28,
10007,10,337,4,0,
10007,10,347,4,0,
10007,10,351,4,0,
10007,10,356,3,0,
10007,10,363,4,0,
10007,10,366,3,0,
10007,10,371,4,0,
10007,10,396,1,37,
10007,10,399,4,0,
10007,10,401,3,0,
10007,10,406,4,0,
10007,10,412,4,0,
10007,10,414,1,33,
10007,10,414,3,0,
10007,10,416,4,0,
10007,10,421,1,42,
10007,10,421,4,0,
10007,10,425,1,19,
10007,10,431,4,0,
10007,10,434,3,0,
10007,10,442,3,0,
10007,10,444,4,0,
10007,10,451,4,0,
10007,10,466,1,6,
10007,10,466,3,0,
10007,10,467,1,46,
10007,11,15,4,0,
10007,11,19,4,0,
10007,11,46,4,0,
10007,11,63,4,0,
10007,11,70,4,0,
10007,11,85,4,0,
10007,11,86,4,0,
10007,11,87,4,0,
10007,11,89,4,0,
10007,11,92,4,0,
10007,11,94,4,0,
10007,11,104,4,0,
10007,11,138,4,0,
10007,11,156,4,0,
10007,11,163,1,15,
10007,11,164,4,0,
10007,11,182,4,0,
10007,11,184,1,1,2
10007,11,194,1,24,
10007,11,207,4,0,
10007,11,216,4,0,
10007,11,218,4,0,
10007,11,219,4,0,
10007,11,225,1,1,1
10007,11,237,4,0,
10007,11,240,4,0,
10007,11,241,4,0,
10007,11,244,4,0,
10007,11,246,1,10,
10007,11,247,4,0,
10007,11,249,4,0,
10007,11,261,4,0,
10007,11,263,4,0,
10007,11,332,4,0,
10007,11,337,1,28,
10007,11,337,4,0,
10007,11,347,4,0,
10007,11,371,4,0,
10007,11,396,1,37,
10007,11,412,4,0,
10007,11,414,1,33,
10007,11,416,4,0,
10007,11,421,1,42,
10007,11,421,4,0,
10007,11,425,1,19,
10007,11,434,3,0,
10007,11,444,4,0,
10007,11,451,4,0,
10007,11,466,1,6,
10007,11,467,1,46,
10007,11,468,4,0,
10007,11,477,4,0,
10007,11,496,4,0,
10007,11,497,4,0,
10007,11,506,1,50,
10007,11,523,4,0,
10007,11,525,4,0,
10007,14,15,4,0,
10007,14,19,4,0,
10007,14,46,4,0,
10007,14,63,4,0,
10007,14,70,4,0,
10007,14,85,4,0,
10007,14,86,4,0,
10007,14,87,4,0,
10007,14,89,4,0,
10007,14,92,4,0,
10007,14,94,4,0,
10007,14,104,4,0,
10007,14,138,4,0,
10007,14,156,4,0,
10007,14,163,1,15,
10007,14,164,4,0,
10007,14,173,3,0,
10007,14,180,3,0,
10007,14,182,4,0,
10007,14,184,1,1,2
10007,14,194,1,24,
10007,14,196,3,0,
10007,14,200,3,0,
10007,14,207,4,0,
10007,14,214,3,0,
10007,14,216,4,0,
10007,14,218,4,0,
10007,14,219,4,0,
10007,14,225,1,1,1
10007,14,231,3,0,
10007,14,237,4,0,
10007,14,240,4,0,
10007,14,241,4,0,
10007,14,244,4,0,
10007,14,246,1,10,
10007,14,247,4,0,
10007,14,249,4,0,
10007,14,261,4,0,
10007,14,263,4,0,
10007,14,277,3,0,
10007,14,304,3,0,
10007,14,332,4,0,
10007,14,337,1,28,
10007,14,337,4,0,
10007,14,347,4,0,
10007,14,356,3,0,
10007,14,366,3,0,
10007,14,371,4,0,
10007,14,396,1,37,
10007,14,399,3,0,
10007,14,401,3,0,
10007,14,406,3,0,
10007,14,412,4,0,
10007,14,414,1,33,
10007,14,414,3,0,
10007,14,416,4,0,
10007,14,421,1,42,
10007,14,421,4,0,
10007,14,425,1,19,
10007,14,434,3,0,
10007,14,442,3,0,
10007,14,444,4,0,
10007,14,451,4,0,
10007,14,466,1,6,
10007,14,467,1,46,
10007,14,468,4,0,
10007,14,477,4,0,
10007,14,496,4,0,
10007,14,497,4,0,
10007,14,506,1,50,
10007,14,523,4,0,
10007,14,525,4,0,
10007,15,15,4,0,
10007,15,19,4,0,
10007,15,46,4,0,
10007,15,63,4,0,
10007,15,70,4,0,
10007,15,85,4,0,
10007,15,86,4,0,
10007,15,87,4,0,
10007,15,89,4,0,
10007,15,92,4,0,
10007,15,94,4,0,
10007,15,104,4,0,
10007,15,138,4,0,
10007,15,156,4,0,
10007,15,163,1,15,
10007,15,164,4,0,
10007,15,182,4,0,
10007,15,184,1,1,2
10007,15,194,1,24,
10007,15,207,4,0,
10007,15,211,4,0,
10007,15,214,4,0,
10007,15,216,4,0,
10007,15,218,4,0,
10007,15,219,4,0,
10007,15,225,1,1,1
10007,15,237,4,0,
10007,15,240,4,0,
10007,15,241,4,0,
10007,15,244,4,0,
10007,15,246,1,10,
10007,15,247,4,0,
10007,15,249,4,0,
10007,15,261,4,0,
10007,15,263,4,0,
10007,15,332,4,0,
10007,15,337,1,28,
10007,15,337,4,0,
10007,15,347,4,0,
10007,15,371,4,0,
10007,15,396,1,37,
10007,15,399,4,0,
10007,15,412,4,0,
10007,15,414,1,33,
10007,15,416,4,0,
10007,15,421,1,42,
10007,15,421,4,0,
10007,15,425,1,19,
10007,15,434,3,0,
10007,15,444,4,0,
10007,15,451,4,0,
10007,15,466,1,6,
10007,15,467,1,46,
10007,15,468,4,0,
10007,15,496,4,0,
10007,15,497,4,0,
10007,15,506,1,50,
10007,15,523,4,0,
10007,15,525,4,0,
10007,15,590,4,0,
10007,16,15,4,0,
10007,16,19,4,0,
10007,16,46,4,0,
10007,16,63,4,0,
10007,16,70,4,0,
10007,16,85,4,0,
10007,16,86,4,0,
10007,16,87,4,0,
10007,16,89,4,0,
10007,16,92,4,0,
10007,16,94,4,0,
10007,16,104,4,0,
10007,16,138,4,0,
10007,16,156,4,0,
10007,16,163,1,15,1
10007,16,164,4,0,
10007,16,173,3,0,
10007,16,180,3,0,
10007,16,182,4,0,
10007,16,184,1,1,2
10007,16,194,1,24,1
10007,16,196,3,0,
10007,16,200,3,0,
10007,16,207,4,0,
10007,16,211,4,0,
10007,16,214,4,0,
10007,16,216,4,0,
10007,16,218,4,0,
10007,16,219,4,0,
10007,16,225,1,1,1
10007,16,231,3,0,
10007,16,237,4,0,
10007,16,240,4,0,
10007,16,241,4,0,
10007,16,244,4,0,
10007,16,246,1,10,1
10007,16,247,4,0,
10007,16,249,4,0,
10007,16,261,4,0,
10007,16,263,4,0,
10007,16,277,3,0,
10007,16,290,4,0,
10007,16,304,3,0,
10007,16,332,4,0,
10007,16,337,1,28,1
10007,16,337,4,0,
10007,16,347,4,0,
10007,16,351,3,0,
10007,16,356,3,0,
10007,16,366,3,0,
10007,16,371,4,0,
10007,16,396,1,37,1
10007,16,399,4,0,
10007,16,401,3,0,
10007,16,406,3,0,
10007,16,412,4,0,
10007,16,414,1,33,1
10007,16,414,3,0,
10007,16,416,4,0,
10007,16,421,1,42,1
10007,16,421,4,0,
10007,16,425,1,19,1
10007,16,434,3,0,
10007,16,442,3,0,
10007,16,444,4,0,
10007,16,451,4,0,
10007,16,466,1,6,1
10007,16,467,1,46,1
10007,16,468,4,0,
10007,16,496,4,0,
10007,16,497,4,0,
10007,16,506,1,50,1
10007,16,523,4,0,
10007,16,525,4,0,
10007,16,590,4,0,
10007,17,19,4,0,
10007,17,46,4,0,
10007,17,63,4,0,
10007,17,85,4,0,
10007,17,86,4,0,
10007,17,87,4,0,
10007,17,89,4,0,
10007,17,92,4,0,
10007,17,94,4,0,
10007,17,104,4,0,
10007,17,138,4,0,
10007,17,156,4,0,
10007,17,163,1,15,
10007,17,164,4,0,
10007,17,182,4,0,
10007,17,184,1,1,2
10007,17,194,1,24,
10007,17,207,4,0,
10007,17,211,4,0,
10007,17,214,4,0,
10007,17,216,4,0,
10007,17,218,4,0,
10007,17,219,4,0,
10007,17,225,1,1,1
10007,17,237,4,0,
10007,17,240,4,0,
10007,17,241,4,0,
10007,17,244,4,0,
10007,17,246,1,10,
10007,17,247,4,0,
10007,17,261,4,0,
10007,17,263,4,0,
10007,17,332,4,0,
10007,17,337,1,28,
10007,17,337,4,0,
10007,17,347,4,0,
10007,17,371,4,0,
10007,17,396,1,37,
10007,17,399,4,0,
10007,17,412,4,0,
10007,17,414,1,33,
10007,17,416,4,0,
10007,17,421,1,42,
10007,17,421,4,0,
10007,17,425,1,19,
10007,17,434,3,0,
10007,17,444,4,0,
10007,17,451,4,0,
10007,17,466,1,6,
10007,17,467,1,46,
10007,17,496,4,0,
10007,17,497,4,0,
10007,17,506,1,50,
10007,17,523,4,0,
10007,17,525,4,0,
10007,17,590,4,0,
10007,17,693,4,0,
10007,18,19,4,0,
10007,18,46,4,0,
10007,18,63,4,0,
10007,18,85,4,0,
10007,18,86,4,0,
10007,18,87,4,0,
10007,18,89,4,0,
10007,18,92,4,0,
10007,18,94,4,0,
10007,18,104,4,0,
10007,18,138,4,0,
10007,18,156,4,0,
10007,18,163,1,15,
10007,18,164,4,0,
10007,18,182,4,0,
10007,18,184,1,1,2
10007,18,194,1,24,
10007,18,207,4,0,
10007,18,211,4,0,
10007,18,214,4,0,
10007,18,216,4,0,
10007,18,218,4,0,
10007,18,219,4,0,
10007,18,225,1,1,1
10007,18,237,4,0,
10007,18,240,4,0,
10007,18,241,4,0,
10007,18,244,4,0,
10007,18,246,1,10,
10007,18,247,4,0,
10007,18,261,4,0,
10007,18,263,4,0,
10007,18,332,4,0,
10007,18,337,1,28,
10007,18,337,4,0,
10007,18,347,4,0,
10007,18,371,4,0,
10007,18,396,1,37,
10007,18,399,4,0,
10007,18,412,4,0,
10007,18,414,1,33,
10007,18,416,4,0,
10007,18,421,1,42,
10007,18,421,4,0,
10007,18,425,1,19,
10007,18,434,3,0,
10007,18,444,4,0,
10007,18,451,4,0,
10007,18,466,1,6,
10007,18,467,1,46,
10007,18,496,4,0,
10007,18,497,4,0,
10007,18,506,1,50,
10007,18,523,4,0,
10007,18,525,4,0,
10007,18,590,4,0,
10007,18,693,4,0,
10008,9,84,1,1,4
10008,9,85,4,0,
10008,9,86,1,1,3
10008,9,86,4,0,
10008,9,87,4,0,
10008,9,92,4,0,
10008,9,104,1,15,
10008,9,104,4,0,
10008,9,109,1,1,5
10008,9,113,4,0,
10008,9,115,4,0,
10008,9,129,3,0,
10008,9,138,4,0,
10008,9,148,4,0,
10008,9,156,4,0,
10008,9,164,1,36,
10008,9,164,4,0,
10008,9,168,4,0,
10008,9,173,3,0,
10008,9,180,3,0,
10008,9,182,4,0,
10008,9,189,3,0,
10008,9,203,4,0,
10008,9,207,4,0,
10008,9,214,4,0,
10008,9,216,4,0,
10008,9,218,4,0,
10008,9,237,4,0,
10008,9,240,4,0,
10008,9,241,4,0,
10008,9,244,4,0,
10008,9,247,4,0,
10008,9,253,1,8,
10008,9,253,3,0,
10008,9,261,4,0,
10008,9,263,4,0,
10008,9,268,1,43,
10008,9,271,1,1,1
10008,9,271,3,0,
10008,9,289,4,0,
10008,9,290,4,0,
10008,9,310,1,1,2
10008,9,315,10,0,
10008,9,324,3,0,
10008,9,351,1,22,
10008,9,351,4,0,
10008,9,363,4,0,
10008,9,389,3,0,
10008,9,399,4,0,
10008,9,435,1,50,
10008,9,451,4,0,
10008,9,466,1,29,
10008,9,466,3,0,
10008,10,84,1,1,4
10008,10,85,4,0,
10008,10,86,1,1,3
10008,10,86,4,0,
10008,10,87,4,0,
10008,10,92,4,0,
10008,10,104,1,15,
10008,10,104,4,0,
10008,10,109,1,1,5
10008,10,113,4,0,
10008,10,115,4,0,
10008,10,129,3,0,
10008,10,138,4,0,
10008,10,148,4,0,
10008,10,156,4,0,
10008,10,164,1,36,
10008,10,164,4,0,
10008,10,168,4,0,
10008,10,173,3,0,
10008,10,180,3,0,
10008,10,182,4,0,
10008,10,189,3,0,
10008,10,203,4,0,
10008,10,207,4,0,
10008,10,214,4,0,
10008,10,216,4,0,
10008,10,218,4,0,
10008,10,220,3,0,
10008,10,237,4,0,
10008,10,240,4,0,
10008,10,241,4,0,
10008,10,244,4,0,
10008,10,247,4,0,
10008,10,253,1,8,
10008,10,253,3,0,
10008,10,261,4,0,
10008,10,263,4,0,
10008,10,268,1,43,
10008,10,271,1,1,1
10008,10,271,3,0,
10008,10,289,4,0,
10008,10,290,4,0,
10008,10,310,1,1,2
10008,10,315,10,0,
10008,10,324,3,0,
10008,10,351,1,22,
10008,10,351,4,0,
10008,10,363,4,0,
10008,10,389,3,0,
10008,10,399,4,0,
10008,10,435,1,50,
10008,10,451,4,0,
10008,10,466,1,29,
10008,10,466,3,0,
10008,11,84,1,1,4
10008,11,85,4,0,
10008,11,86,1,1,3
10008,11,86,4,0,
10008,11,87,4,0,
10008,11,92,4,0,
10008,11,104,1,15,
10008,11,104,4,0,
10008,11,109,1,1,5
10008,11,113,4,0,
10008,11,115,4,0,
10008,11,138,4,0,
10008,11,148,4,0,
10008,11,156,4,0,
10008,11,164,1,36,
10008,11,164,4,0,
10008,11,168,4,0,
10008,11,182,4,0,
10008,11,207,4,0,
10008,11,216,4,0,
10008,11,218,4,0,
10008,11,237,4,0,
10008,11,240,4,0,
10008,11,241,4,0,
10008,11,244,4,0,
10008,11,247,4,0,
10008,11,253,1,8,
10008,11,261,4,0,
10008,11,263,4,0,
10008,11,268,1,57,
10008,11,271,1,1,1
10008,11,310,1,1,2
10008,11,315,10,0,
10008,11,351,1,22,
10008,11,435,1,64,
10008,11,451,4,0,
10008,11,466,1,29,
10008,11,477,4,0,
10008,11,486,1,43,
10008,11,496,4,0,
10008,11,506,1,50,
10008,11,521,4,0,
10008,14,84,1,1,4
10008,14,85,4,0,
10008,14,86,1,1,3
10008,14,86,4,0,
10008,14,87,4,0,
10008,14,92,4,0,
10008,14,104,1,15,
10008,14,104,4,0,
10008,14,109,1,1,5
10008,14,113,4,0,
10008,14,115,4,0,
10008,14,138,4,0,
10008,14,148,4,0,
10008,14,156,4,0,
10008,14,164,1,36,
10008,14,164,4,0,
10008,14,168,4,0,
10008,14,173,3,0,
10008,14,180,3,0,
10008,14,182,4,0,
10008,14,207,4,0,
10008,14,214,3,0,
10008,14,216,4,0,
10008,14,218,4,0,
10008,14,220,3,0,
10008,14,237,4,0,
10008,14,240,4,0,
10008,14,241,4,0,
10008,14,244,4,0,
10008,14,247,4,0,
10008,14,253,1,8,
10008,14,253,3,0,
10008,14,261,4,0,
10008,14,263,4,0,
10008,14,268,1,57,
10008,14,271,1,1,1
10008,14,271,3,0,
10008,14,289,3,0,
10008,14,310,1,1,2
10008,14,315,10,0,
10008,14,324,3,0,
10008,14,351,1,22,
10008,14,399,3,0,
10008,14,435,1,64,
10008,14,451,4,0,
10008,14,466,1,29,
10008,14,477,4,0,
10008,14,486,1,43,
10008,14,496,4,0,
10008,14,506,1,50,
10008,14,521,4,0,
10008,14,527,3,0,
10008,15,84,1,1,6
10008,15,85,4,0,
10008,15,86,1,1,5
10008,15,86,4,0,
10008,15,87,4,0,
10008,15,92,4,0,
10008,15,104,1,15,
10008,15,104,4,0,
10008,15,109,1,1,7
10008,15,113,4,0,
10008,15,115,4,0,
10008,15,138,4,0,
10008,15,148,4,0,
10008,15,156,4,0,
10008,15,164,1,36,
10008,15,164,4,0,
10008,15,168,4,0,
10008,15,182,4,0,
10008,15,207,4,0,
10008,15,214,4,0,
10008,15,216,4,0,
10008,15,218,4,0,
10008,15,237,4,0,
10008,15,240,4,0,
10008,15,241,4,0,
10008,15,244,4,0,
10008,15,247,4,0,
10008,15,253,1,8,
10008,15,261,4,0,
10008,15,263,4,0,
10008,15,268,1,1,2
10008,15,268,1,57,
10008,15,271,1,1,3
10008,15,310,1,1,4
10008,15,315,10,0,
10008,15,351,1,22,
10008,15,399,4,0,
10008,15,435,1,1,1
10008,15,435,1,64,
10008,15,451,4,0,
10008,15,466,1,29,
10008,15,486,1,43,
10008,15,496,4,0,
10008,15,506,1,50,
10008,15,521,4,0,
10008,15,590,4,0,
10008,16,84,1,1,6
10008,16,85,4,0,
10008,16,86,1,1,5
10008,16,86,4,0,
10008,16,87,4,0,
10008,16,92,4,0,
10008,16,104,1,15,1
10008,16,104,4,0,
10008,16,109,1,1,7
10008,16,113,4,0,
10008,16,115,4,0,
10008,16,138,4,0,
10008,16,148,4,0,
10008,16,156,4,0,
10008,16,164,1,36,1
10008,16,164,4,0,
10008,16,168,4,0,
10008,16,173,3,0,
10008,16,180,3,0,
10008,16,182,4,0,
10008,16,207,4,0,
10008,16,214,4,0,
10008,16,216,4,0,
10008,16,218,4,0,
10008,16,220,3,0,
10008,16,237,4,0,
10008,16,240,4,0,
10008,16,241,4,0,
10008,16,244,4,0,
10008,16,247,4,0,
10008,16,253,1,8,1
10008,16,253,3,0,
10008,16,261,4,0,
10008,16,263,4,0,
10008,16,268,1,1,2
10008,16,268,1,57,1
10008,16,271,1,1,3
10008,16,271,3,0,
10008,16,289,3,0,
10008,16,290,4,0,
10008,16,310,1,1,4
10008,16,315,10,0,
10008,16,324,3,0,
10008,16,351,1,22,1
10008,16,351,3,0,
10008,16,399,4,0,
10008,16,435,1,1,1
10008,16,435,1,64,1
10008,16,451,4,0,
10008,16,466,1,29,1
10008,16,486,1,43,1
10008,16,492,3,0,
10008,16,496,4,0,
10008,16,506,1,50,1
10008,16,521,4,0,
10008,16,527,3,0,
10008,16,590,4,0,
10008,17,84,1,1,6
10008,17,85,4,0,
10008,17,86,1,1,5
10008,17,86,4,0,
10008,17,87,4,0,
10008,17,92,4,0,
10008,17,104,1,15,
10008,17,104,4,0,
10008,17,109,1,1,7
10008,17,113,4,0,
10008,17,115,4,0,
10008,17,138,4,0,
10008,17,156,4,0,
10008,17,164,1,36,
10008,17,164,4,0,
10008,17,168,4,0,
10008,17,182,4,0,
10008,17,207,4,0,
10008,17,214,4,0,
10008,17,216,4,0,
10008,17,218,4,0,
10008,17,237,4,0,
10008,17,240,4,0,
10008,17,241,4,0,
10008,17,244,4,0,
10008,17,247,4,0,
10008,17,253,1,8,
10008,17,261,4,0,
10008,17,263,4,0,
10008,17,268,1,1,2
10008,17,268,1,57,
10008,17,271,1,1,3
10008,17,310,1,1,4
10008,17,315,10,0,
10008,17,351,1,22,
10008,17,399,4,0,
10008,17,435,1,1,1
10008,17,435,1,64,
10008,17,451,4,0,
10008,17,466,1,29,
10008,17,486,1,43,
10008,17,496,4,0,
10008,17,506,1,50,
10008,17,521,4,0,
10008,17,590,4,0,
10008,18,84,1,1,6
10008,18,85,4,0,
10008,18,86,1,1,5
10008,18,86,4,0,
10008,18,87,4,0,
10008,18,92,4,0,
10008,18,104,1,15,
10008,18,104,4,0,
10008,18,109,1,1,7
10008,18,113,4,0,
10008,18,115,4,0,
10008,18,138,4,0,
10008,18,156,4,0,
10008,18,164,1,36,
10008,18,164,4,0,
10008,18,168,4,0,
10008,18,182,4,0,
10008,18,207,4,0,
10008,18,214,4,0,
10008,18,216,4,0,
10008,18,218,4,0,
10008,18,237,4,0,
10008,18,240,4,0,
10008,18,241,4,0,
10008,18,244,4,0,
10008,18,247,4,0,
10008,18,253,1,8,
10008,18,261,4,0,
10008,18,263,4,0,
10008,18,268,1,1,2
10008,18,268,1,57,
10008,18,271,1,1,3
10008,18,310,1,1,4
10008,18,315,10,0,
10008,18,351,1,22,
10008,18,399,4,0,
10008,18,435,1,1,1
10008,18,435,1,64,
10008,18,451,4,0,
10008,18,466,1,29,
10008,18,486,1,43,
10008,18,496,4,0,
10008,18,506,1,50,
10008,18,521,4,0,
10008,18,590,4,0,
10009,9,56,10,0,
10009,9,84,1,1,4
10009,9,85,4,0,
10009,9,86,1,1,3
10009,9,86,4,0,
10009,9,87,4,0,
10009,9,92,4,0,
10009,9,104,1,15,
10009,9,104,4,0,
10009,9,109,1,1,5
10009,9,113,4,0,
10009,9,115,4,0,
10009,9,129,3,0,
10009,9,138,4,0,
10009,9,148,4,0,
10009,9,156,4,0,
10009,9,164,1,36,
10009,9,164,4,0,
10009,9,168,4,0,
10009,9,173,3,0,
10009,9,180,3,0,
10009,9,182,4,0,
10009,9,189,3,0,
10009,9,203,4,0,
10009,9,207,4,0,
10009,9,214,4,0,
10009,9,216,4,0,
10009,9,218,4,0,
10009,9,237,4,0,
10009,9,240,4,0,
10009,9,241,4,0,
10009,9,244,4,0,
10009,9,247,4,0,
10009,9,253,1,8,
10009,9,253,3,0,
10009,9,261,4,0,
10009,9,263,4,0,
10009,9,268,1,43,
10009,9,271,1,1,1
10009,9,271,3,0,
10009,9,289,4,0,
10009,9,290,4,0,
10009,9,310,1,1,2
10009,9,324,3,0,
10009,9,351,1,22,
10009,9,351,4,0,
10009,9,363,4,0,
10009,9,389,3,0,
10009,9,399,4,0,
10009,9,435,1,50,
10009,9,451,4,0,
10009,9,466,1,29,
10009,9,466,3,0,
10009,10,56,10,0,
10009,10,84,1,1,4
10009,10,85,4,0,
10009,10,86,1,1,3
10009,10,86,4,0,
10009,10,87,4,0,
10009,10,92,4,0,
10009,10,104,1,15,
10009,10,104,4,0,
10009,10,109,1,1,5
10009,10,113,4,0,
10009,10,115,4,0,
10009,10,129,3,0,
10009,10,138,4,0,
10009,10,148,4,0,
10009,10,156,4,0,
10009,10,164,1,36,
10009,10,164,4,0,
10009,10,168,4,0,
10009,10,173,3,0,
10009,10,180,3,0,
10009,10,182,4,0,
10009,10,189,3,0,
10009,10,203,4,0,
10009,10,207,4,0,
10009,10,214,4,0,
10009,10,216,4,0,
10009,10,218,4,0,
10009,10,220,3,0,
10009,10,237,4,0,
10009,10,240,4,0,
10009,10,241,4,0,
10009,10,244,4,0,
10009,10,247,4,0,
10009,10,253,1,8,
10009,10,253,3,0,
10009,10,261,4,0,
10009,10,263,4,0,
10009,10,268,1,43,
10009,10,271,1,1,1
10009,10,271,3,0,
10009,10,289,4,0,
10009,10,290,4,0,
10009,10,310,1,1,2
10009,10,324,3,0,
10009,10,351,1,22,
10009,10,351,4,0,
10009,10,363,4,0,
10009,10,389,3,0,
10009,10,399,4,0,
10009,10,435,1,50,
10009,10,451,4,0,
10009,10,466,1,29,
10009,10,466,3,0,
10009,11,56,10,0,
10009,11,84,1,1,4
10009,11,85,4,0,
10009,11,86,1,1,3
10009,11,86,4,0,
10009,11,87,4,0,
10009,11,92,4,0,
10009,11,104,1,15,
10009,11,104,4,0,
10009,11,109,1,1,5
10009,11,113,4,0,
10009,11,115,4,0,
10009,11,138,4,0,
10009,11,148,4,0,
10009,11,156,4,0,
10009,11,164,1,36,
10009,11,164,4,0,
10009,11,168,4,0,
10009,11,182,4,0,
10009,11,207,4,0,
10009,11,216,4,0,
10009,11,218,4,0,
10009,11,237,4,0,
10009,11,240,4,0,
10009,11,241,4,0,
10009,11,244,4,0,
10009,11,247,4,0,
10009,11,253,1,8,
10009,11,261,4,0,
10009,11,263,4,0,
10009,11,268,1,57,
10009,11,271,1,1,1
10009,11,310,1,1,2
10009,11,351,1,22,
10009,11,435,1,64,
10009,11,451,4,0,
10009,11,466,1,29,
10009,11,477,4,0,
10009,11,486,1,43,
10009,11,496,4,0,
10009,11,506,1,50,
10009,11,521,4,0,
10009,14,56,10,0,
10009,14,84,1,1,4
10009,14,85,4,0,
10009,14,86,1,1,3
10009,14,86,4,0,
10009,14,87,4,0,
10009,14,92,4,0,
10009,14,104,1,15,
10009,14,104,4,0,
10009,14,109,1,1,5
10009,14,113,4,0,
10009,14,115,4,0,
10009,14,138,4,0,
10009,14,148,4,0,
10009,14,156,4,0,
10009,14,164,1,36,
10009,14,164,4,0,
10009,14,168,4,0,
10009,14,173,3,0,
10009,14,180,3,0,
10009,14,182,4,0,
10009,14,207,4,0,
10009,14,214,3,0,
10009,14,216,4,0,
10009,14,218,4,0,
10009,14,220,3,0,
10009,14,237,4,0,
10009,14,240,4,0,
10009,14,241,4,0,
10009,14,244,4,0,
10009,14,247,4,0,
10009,14,253,1,8,
10009,14,253,3,0,
10009,14,261,4,0,
10009,14,263,4,0,
10009,14,268,1,57,
10009,14,271,1,1,1
10009,14,271,3,0,
10009,14,289,3,0,
10009,14,310,1,1,2
10009,14,324,3,0,
10009,14,351,1,22,
10009,14,399,3,0,
10009,14,435,1,64,
10009,14,451,4,0,
10009,14,466,1,29,
10009,14,477,4,0,
10009,14,486,1,43,
10009,14,496,4,0,
10009,14,506,1,50,
10009,14,521,4,0,
10009,14,527,3,0,
10009,15,56,10,0,
10009,15,84,1,1,6
10009,15,85,4,0,
10009,15,86,1,1,5
10009,15,86,4,0,
10009,15,87,4,0,
10009,15,92,4,0,
10009,15,104,1,15,
10009,15,104,4,0,
10009,15,109,1,1,7
10009,15,113,4,0,
10009,15,115,4,0,
10009,15,138,4,0,
10009,15,148,4,0,
10009,15,156,4,0,
10009,15,164,1,36,
10009,15,164,4,0,
10009,15,168,4,0,
10009,15,182,4,0,
10009,15,207,4,0,
10009,15,214,4,0,
10009,15,216,4,0,
10009,15,218,4,0,
10009,15,237,4,0,
10009,15,240,4,0,
10009,15,241,4,0,
10009,15,244,4,0,
10009,15,247,4,0,
10009,15,253,1,8,
10009,15,261,4,0,
10009,15,263,4,0,
10009,15,268,1,1,2
10009,15,268,1,57,
10009,15,271,1,1,3
10009,15,310,1,1,4
10009,15,351,1,22,
10009,15,399,4,0,
10009,15,435,1,1,1
10009,15,435,1,64,
10009,15,451,4,0,
10009,15,466,1,29,
10009,15,486,1,43,
10009,15,496,4,0,
10009,15,506,1,50,
10009,15,521,4,0,
10009,15,590,4,0,
10009,16,56,10,0,
10009,16,84,1,1,6
10009,16,85,4,0,
10009,16,86,1,1,5
10009,16,86,4,0,
10009,16,87,4,0,
10009,16,92,4,0,
10009,16,104,1,15,1
10009,16,104,4,0,
10009,16,109,1,1,7
10009,16,113,4,0,
10009,16,115,4,0,
10009,16,138,4,0,
10009,16,148,4,0,
10009,16,156,4,0,
10009,16,164,1,36,1
10009,16,164,4,0,
10009,16,168,4,0,
10009,16,173,3,0,
10009,16,180,3,0,
10009,16,182,4,0,
10009,16,207,4,0,
10009,16,214,4,0,
10009,16,216,4,0,
10009,16,218,4,0,
10009,16,220,3,0,
10009,16,237,4,0,
10009,16,240,4,0,
10009,16,241,4,0,
10009,16,244,4,0,
10009,16,247,4,0,
10009,16,253,1,8,1
10009,16,253,3,0,
10009,16,261,4,0,
10009,16,263,4,0,
10009,16,268,1,1,2
10009,16,268,1,57,1
10009,16,271,1,1,3
10009,16,271,3,0,
10009,16,289,3,0,
10009,16,290,4,0,
10009,16,310,1,1,4
10009,16,324,3,0,
10009,16,351,1,22,1
10009,16,351,3,0,
10009,16,399,4,0,
10009,16,435,1,1,1
10009,16,435,1,64,1
10009,16,451,4,0,
10009,16,466,1,29,1
10009,16,486,1,43,1
10009,16,492,3,0,
10009,16,496,4,0,
10009,16,506,1,50,1
10009,16,521,4,0,
10009,16,527,3,0,
10009,16,590,4,0,
10009,17,56,10,0,
10009,17,84,1,1,6
10009,17,85,4,0,
10009,17,86,1,1,5
10009,17,86,4,0,
10009,17,87,4,0,
10009,17,92,4,0,
10009,17,104,1,15,
10009,17,104,4,0,
10009,17,109,1,1,7
10009,17,113,4,0,
10009,17,115,4,0,
10009,17,138,4,0,
10009,17,156,4,0,
10009,17,164,1,36,
10009,17,164,4,0,
10009,17,168,4,0,
10009,17,182,4,0,
10009,17,207,4,0,
10009,17,214,4,0,
10009,17,216,4,0,
10009,17,218,4,0,
10009,17,237,4,0,
10009,17,240,4,0,
10009,17,241,4,0,
10009,17,244,4,0,
10009,17,247,4,0,
10009,17,253,1,8,
10009,17,261,4,0,
10009,17,263,4,0,
10009,17,268,1,1,2
10009,17,268,1,57,
10009,17,271,1,1,3
10009,17,310,1,1,4
10009,17,351,1,22,
10009,17,399,4,0,
10009,17,435,1,1,1
10009,17,435,1,64,
10009,17,451,4,0,
10009,17,466,1,29,
10009,17,486,1,43,
10009,17,496,4,0,
10009,17,506,1,50,
10009,17,521,4,0,
10009,17,590,4,0,
10009,18,56,10,0,
10009,18,84,1,1,6
10009,18,85,4,0,
10009,18,86,1,1,5
10009,18,86,4,0,
10009,18,87,4,0,
10009,18,92,4,0,
10009,18,104,1,15,
10009,18,104,4,0,
10009,18,109,1,1,7
10009,18,113,4,0,
10009,18,115,4,0,
10009,18,138,4,0,
10009,18,156,4,0,
10009,18,164,1,36,
10009,18,164,4,0,
10009,18,168,4,0,
10009,18,182,4,0,
10009,18,207,4,0,
10009,18,214,4,0,
10009,18,216,4,0,
10009,18,218,4,0,
10009,18,237,4,0,
10009,18,240,4,0,
10009,18,241,4,0,
10009,18,244,4,0,
10009,18,247,4,0,
10009,18,253,1,8,
10009,18,261,4,0,
10009,18,263,4,0,
10009,18,268,1,1,2
10009,18,268,1,57,
10009,18,271,1,1,3
10009,18,310,1,1,4
10009,18,351,1,22,
10009,18,399,4,0,
10009,18,435,1,1,1
10009,18,435,1,64,
10009,18,451,4,0,
10009,18,466,1,29,
10009,18,486,1,43,
10009,18,496,4,0,
10009,18,506,1,50,
10009,18,521,4,0,
10009,18,590,4,0,
10010,9,59,10,0,
10010,9,84,1,1,4
10010,9,85,4,0,
10010,9,86,1,1,3
10010,9,86,4,0,
10010,9,87,4,0,
10010,9,92,4,0,
10010,9,104,1,15,
10010,9,104,4,0,
10010,9,109,1,1,5
10010,9,113,4,0,
10010,9,115,4,0,
10010,9,129,3,0,
10010,9,138,4,0,
10010,9,148,4,0,
10010,9,156,4,0,
10010,9,164,1,36,
10010,9,164,4,0,
10010,9,168,4,0,
10010,9,173,3,0,
10010,9,180,3,0,
10010,9,182,4,0,
10010,9,189,3,0,
10010,9,203,4,0,
10010,9,207,4,0,
10010,9,214,4,0,
10010,9,216,4,0,
10010,9,218,4,0,
10010,9,237,4,0,
10010,9,240,4,0,
10010,9,241,4,0,
10010,9,244,4,0,
10010,9,247,4,0,
10010,9,253,1,8,
10010,9,253,3,0,
10010,9,261,4,0,
10010,9,263,4,0,
10010,9,268,1,43,
10010,9,271,1,1,1
10010,9,271,3,0,
10010,9,289,4,0,
10010,9,290,4,0,
10010,9,310,1,1,2
10010,9,324,3,0,
10010,9,351,1,22,
10010,9,351,4,0,
10010,9,363,4,0,
10010,9,389,3,0,
10010,9,399,4,0,
10010,9,435,1,50,
10010,9,451,4,0,
10010,9,466,1,29,
10010,9,466,3,0,
10010,10,59,10,0,
10010,10,84,1,1,4
10010,10,85,4,0,
10010,10,86,1,1,3
10010,10,86,4,0,
10010,10,87,4,0,
10010,10,92,4,0,
10010,10,104,1,15,
10010,10,104,4,0,
10010,10,109,1,1,5
10010,10,113,4,0,
10010,10,115,4,0,
10010,10,129,3,0,
10010,10,138,4,0,
10010,10,148,4,0,
10010,10,156,4,0,
10010,10,164,1,36,
10010,10,164,4,0,
10010,10,168,4,0,
10010,10,173,3,0,
10010,10,180,3,0,
10010,10,182,4,0,
10010,10,189,3,0,
10010,10,203,4,0,
10010,10,207,4,0,
10010,10,214,4,0,
10010,10,216,4,0,
10010,10,218,4,0,
10010,10,220,3,0,
10010,10,237,4,0,
10010,10,240,4,0,
10010,10,241,4,0,
10010,10,244,4,0,
10010,10,247,4,0,
10010,10,253,1,8,
10010,10,253,3,0,
10010,10,261,4,0,
10010,10,263,4,0,
10010,10,268,1,43,
10010,10,271,1,1,1
10010,10,271,3,0,
10010,10,289,4,0,
10010,10,290,4,0,
10010,10,310,1,1,2
10010,10,324,3,0,
10010,10,351,1,22,
10010,10,351,4,0,
10010,10,363,4,0,
10010,10,389,3,0,
10010,10,399,4,0,
10010,10,435,1,50,
10010,10,451,4,0,
10010,10,466,1,29,
10010,10,466,3,0,
10010,11,59,10,0,
10010,11,84,1,1,4
10010,11,85,4,0,
10010,11,86,1,1,3
10010,11,86,4,0,
10010,11,87,4,0,
10010,11,92,4,0,
10010,11,104,1,15,
10010,11,104,4,0,
10010,11,109,1,1,5
10010,11,113,4,0,
10010,11,115,4,0,
10010,11,138,4,0,
10010,11,148,4,0,
10010,11,156,4,0,
10010,11,164,1,36,
10010,11,164,4,0,
10010,11,168,4,0,
10010,11,182,4,0,
10010,11,207,4,0,
10010,11,216,4,0,
10010,11,218,4,0,
10010,11,237,4,0,
10010,11,240,4,0,
10010,11,241,4,0,
10010,11,244,4,0,
10010,11,247,4,0,
10010,11,253,1,8,
10010,11,261,4,0,
10010,11,263,4,0,
10010,11,268,1,57,
10010,11,271,1,1,1
10010,11,310,1,1,2
10010,11,351,1,22,
10010,11,435,1,64,
10010,11,451,4,0,
10010,11,466,1,29,
10010,11,477,4,0,
10010,11,486,1,43,
10010,11,496,4,0,
10010,11,506,1,50,
10010,11,521,4,0,
10010,14,59,10,0,
10010,14,84,1,1,4
10010,14,85,4,0,
10010,14,86,1,1,3
10010,14,86,4,0,
10010,14,87,4,0,
10010,14,92,4,0,
10010,14,104,1,15,
10010,14,104,4,0,
10010,14,109,1,1,5
10010,14,113,4,0,
10010,14,115,4,0,
10010,14,138,4,0,
10010,14,148,4,0,
10010,14,156,4,0,
10010,14,164,1,36,
10010,14,164,4,0,
10010,14,168,4,0,
10010,14,173,3,0,
10010,14,180,3,0,
10010,14,182,4,0,
10010,14,207,4,0,
10010,14,214,3,0,
10010,14,216,4,0,
10010,14,218,4,0,
10010,14,220,3,0,
10010,14,237,4,0,
10010,14,240,4,0,
10010,14,241,4,0,
10010,14,244,4,0,
10010,14,247,4,0,
10010,14,253,1,8,
10010,14,253,3,0,
10010,14,261,4,0,
10010,14,263,4,0,
10010,14,268,1,57,
10010,14,271,1,1,1
10010,14,271,3,0,
10010,14,289,3,0,
10010,14,310,1,1,2
10010,14,324,3,0,
10010,14,351,1,22,
10010,14,399,3,0,
10010,14,435,1,64,
10010,14,451,4,0,
10010,14,466,1,29,
10010,14,477,4,0,
10010,14,486,1,43,
10010,14,496,4,0,
10010,14,506,1,50,
10010,14,521,4,0,
10010,14,527,3,0,
10010,15,59,10,0,
10010,15,84,1,1,6
10010,15,85,4,0,
10010,15,86,1,1,5
10010,15,86,4,0,
10010,15,87,4,0,
10010,15,92,4,0,
10010,15,104,1,15,
10010,15,104,4,0,
10010,15,109,1,1,7
10010,15,113,4,0,
10010,15,115,4,0,
10010,15,138,4,0,
10010,15,148,4,0,
10010,15,156,4,0,
10010,15,164,1,36,
10010,15,164,4,0,
10010,15,168,4,0,
10010,15,182,4,0,
10010,15,207,4,0,
10010,15,214,4,0,
10010,15,216,4,0,
10010,15,218,4,0,
10010,15,237,4,0,
10010,15,240,4,0,
10010,15,241,4,0,
10010,15,244,4,0,
10010,15,247,4,0,
10010,15,253,1,8,
10010,15,261,4,0,
10010,15,263,4,0,
10010,15,268,1,1,2
10010,15,268,1,57,
10010,15,271,1,1,3
10010,15,310,1,1,4
10010,15,351,1,22,
10010,15,399,4,0,
10010,15,435,1,1,1
10010,15,435,1,64,
10010,15,451,4,0,
10010,15,466,1,29,
10010,15,486,1,43,
10010,15,496,4,0,
10010,15,506,1,50,
10010,15,521,4,0,
10010,15,590,4,0,
10010,16,59,10,0,
10010,16,84,1,1,6
10010,16,85,4,0,
10010,16,86,1,1,5
10010,16,86,4,0,
10010,16,87,4,0,
10010,16,92,4,0,
10010,16,104,1,15,1
10010,16,104,4,0,
10010,16,109,1,1,7
10010,16,113,4,0,
10010,16,115,4,0,
10010,16,138,4,0,
10010,16,148,4,0,
10010,16,156,4,0,
10010,16,164,1,36,1
10010,16,164,4,0,
10010,16,168,4,0,
10010,16,173,3,0,
10010,16,180,3,0,
10010,16,182,4,0,
10010,16,207,4,0,
10010,16,214,4,0,
10010,16,216,4,0,
10010,16,218,4,0,
10010,16,220,3,0,
10010,16,237,4,0,
10010,16,240,4,0,
10010,16,241,4,0,
10010,16,244,4,0,
10010,16,247,4,0,
10010,16,253,1,8,1
10010,16,253,3,0,
10010,16,261,4,0,
10010,16,263,4,0,
10010,16,268,1,1,2
10010,16,268,1,57,1
10010,16,271,1,1,3
10010,16,271,3,0,
10010,16,289,3,0,
10010,16,290,4,0,
10010,16,310,1,1,4
10010,16,324,3,0,
10010,16,351,1,22,1
10010,16,351,3,0,
10010,16,399,4,0,
10010,16,435,1,1,1
10010,16,435,1,64,1
10010,16,451,4,0,
10010,16,466,1,29,1
10010,16,486,1,43,1
10010,16,492,3,0,
10010,16,496,4,0,
10010,16,506,1,50,1
10010,16,521,4,0,
10010,16,527,3,0,
10010,16,590,4,0,
10010,17,59,10,0,
10010,17,84,1,1,6
10010,17,85,4,0,
10010,17,86,1,1,5
10010,17,86,4,0,
10010,17,87,4,0,
10010,17,92,4,0,
10010,17,104,1,15,
10010,17,104,4,0,
10010,17,109,1,1,7
10010,17,113,4,0,
10010,17,115,4,0,
10010,17,138,4,0,
10010,17,156,4,0,
10010,17,164,1,36,
10010,17,164,4,0,
10010,17,168,4,0,
10010,17,182,4,0,
10010,17,207,4,0,
10010,17,214,4,0,
10010,17,216,4,0,
10010,17,218,4,0,
10010,17,237,4,0,
10010,17,240,4,0,
10010,17,241,4,0,
10010,17,244,4,0,
10010,17,247,4,0,
10010,17,253,1,8,
10010,17,261,4,0,
10010,17,263,4,0,
10010,17,268,1,1,2
10010,17,268,1,57,
10010,17,271,1,1,3
10010,17,310,1,1,4
10010,17,351,1,22,
10010,17,399,4,0,
10010,17,435,1,1,1
10010,17,435,1,64,
10010,17,451,4,0,
10010,17,466,1,29,
10010,17,486,1,43,
10010,17,496,4,0,
10010,17,506,1,50,
10010,17,521,4,0,
10010,17,590,4,0,
10010,18,59,10,0,
10010,18,84,1,1,6
10010,18,85,4,0,
10010,18,86,1,1,5
10010,18,86,4,0,
10010,18,87,4,0,
10010,18,92,4,0,
10010,18,104,1,15,
10010,18,104,4,0,
10010,18,109,1,1,7
10010,18,113,4,0,
10010,18,115,4,0,
10010,18,138,4,0,
10010,18,156,4,0,
10010,18,164,1,36,
10010,18,164,4,0,
10010,18,168,4,0,
10010,18,182,4,0,
10010,18,207,4,0,
10010,18,214,4,0,
10010,18,216,4,0,
10010,18,218,4,0,
10010,18,237,4,0,
10010,18,240,4,0,
10010,18,241,4,0,
10010,18,244,4,0,
10010,18,247,4,0,
10010,18,253,1,8,
10010,18,261,4,0,
10010,18,263,4,0,
10010,18,268,1,1,2
10010,18,268,1,57,
10010,18,271,1,1,3
10010,18,310,1,1,4
10010,18,351,1,22,
10010,18,399,4,0,
10010,18,435,1,1,1
10010,18,435,1,64,
10010,18,451,4,0,
10010,18,466,1,29,
10010,18,486,1,43,
10010,18,496,4,0,
10010,18,506,1,50,
10010,18,521,4,0,
10010,18,590,4,0,
10011,9,84,1,1,4
10011,9,85,4,0,
10011,9,86,1,1,3
10011,9,86,4,0,
10011,9,87,4,0,
10011,9,92,4,0,
10011,9,104,1,15,
10011,9,104,4,0,
10011,9,109,1,1,5
10011,9,113,4,0,
10011,9,115,4,0,
10011,9,129,3,0,
10011,9,138,4,0,
10011,9,148,4,0,
10011,9,156,4,0,
10011,9,164,1,36,
10011,9,164,4,0,
10011,9,168,4,0,
10011,9,173,3,0,
10011,9,180,3,0,
10011,9,182,4,0,
10011,9,189,3,0,
10011,9,203,4,0,
10011,9,207,4,0,
10011,9,214,4,0,
10011,9,216,4,0,
10011,9,218,4,0,
10011,9,237,4,0,
10011,9,240,4,0,
10011,9,241,4,0,
10011,9,244,4,0,
10011,9,247,4,0,
10011,9,253,1,8,
10011,9,253,3,0,
10011,9,261,4,0,
10011,9,263,4,0,
10011,9,268,1,43,
10011,9,271,1,1,1
10011,9,271,3,0,
10011,9,289,4,0,
10011,9,290,4,0,
10011,9,310,1,1,2
10011,9,324,3,0,
10011,9,351,1,22,
10011,9,351,4,0,
10011,9,363,4,0,
10011,9,389,3,0,
10011,9,399,4,0,
10011,9,403,10,0,
10011,9,435,1,50,
10011,9,451,4,0,
10011,9,466,1,29,
10011,9,466,3,0,
10011,10,84,1,1,4
10011,10,85,4,0,
10011,10,86,1,1,3
10011,10,86,4,0,
10011,10,87,4,0,
10011,10,92,4,0,
10011,10,104,1,15,
10011,10,104,4,0,
10011,10,109,1,1,5
10011,10,113,4,0,
10011,10,115,4,0,
10011,10,129,3,0,
10011,10,138,4,0,
10011,10,148,4,0,
10011,10,156,4,0,
10011,10,164,1,36,
10011,10,164,4,0,
10011,10,168,4,0,
10011,10,173,3,0,
10011,10,180,3,0,
10011,10,182,4,0,
10011,10,189,3,0,
10011,10,203,4,0,
10011,10,207,4,0,
10011,10,214,4,0,
10011,10,216,4,0,
10011,10,218,4,0,
10011,10,220,3,0,
10011,10,237,4,0,
10011,10,240,4,0,
10011,10,241,4,0,
10011,10,244,4,0,
10011,10,247,4,0,
10011,10,253,1,8,
10011,10,253,3,0,
10011,10,261,4,0,
10011,10,263,4,0,
10011,10,268,1,43,
10011,10,271,1,1,1
10011,10,271,3,0,
10011,10,289,4,0,
10011,10,290,4,0,
10011,10,310,1,1,2
10011,10,324,3,0,
10011,10,351,1,22,
10011,10,351,4,0,
10011,10,363,4,0,
10011,10,389,3,0,
10011,10,399,4,0,
10011,10,403,10,0,
10011,10,435,1,50,
10011,10,451,4,0,
10011,10,466,1,29,
10011,10,466,3,0,
10011,11,84,1,1,4
10011,11,85,4,0,
10011,11,86,1,1,3
10011,11,86,4,0,
10011,11,87,4,0,
10011,11,92,4,0,
10011,11,104,1,15,
10011,11,104,4,0,
10011,11,109,1,1,5
10011,11,113,4,0,
10011,11,115,4,0,
10011,11,138,4,0,
10011,11,148,4,0,
10011,11,156,4,0,
10011,11,164,1,36,
10011,11,164,4,0,
10011,11,168,4,0,
10011,11,182,4,0,
10011,11,207,4,0,
10011,11,216,4,0,
10011,11,218,4,0,
10011,11,237,4,0,
10011,11,240,4,0,
10011,11,241,4,0,
10011,11,244,4,0,
10011,11,247,4,0,
10011,11,253,1,8,
10011,11,261,4,0,
10011,11,263,4,0,
10011,11,268,1,57,
10011,11,271,1,1,1
10011,11,310,1,1,2
10011,11,351,1,22,
10011,11,403,10,0,
10011,11,435,1,64,
10011,11,451,4,0,
10011,11,466,1,29,
10011,11,477,4,0,
10011,11,486,1,43,
10011,11,496,4,0,
10011,11,506,1,50,
10011,11,521,4,0,
10011,14,84,1,1,4
10011,14,85,4,0,
10011,14,86,1,1,3
10011,14,86,4,0,
10011,14,87,4,0,
10011,14,92,4,0,
10011,14,104,1,15,
10011,14,104,4,0,
10011,14,109,1,1,5
10011,14,113,4,0,
10011,14,115,4,0,
10011,14,138,4,0,
10011,14,148,4,0,
10011,14,156,4,0,
10011,14,164,1,36,
10011,14,164,4,0,
10011,14,168,4,0,
10011,14,173,3,0,
10011,14,180,3,0,
10011,14,182,4,0,
10011,14,207,4,0,
10011,14,214,3,0,
10011,14,216,4,0,
10011,14,218,4,0,
10011,14,220,3,0,
10011,14,237,4,0,
10011,14,240,4,0,
10011,14,241,4,0,
10011,14,244,4,0,
10011,14,247,4,0,
10011,14,253,1,8,
10011,14,253,3,0,
10011,14,261,4,0,
10011,14,263,4,0,
10011,14,268,1,57,
10011,14,271,1,1,1
10011,14,271,3,0,
10011,14,289,3,0,
10011,14,310,1,1,2
10011,14,324,3,0,
10011,14,351,1,22,
10011,14,399,3,0,
10011,14,403,10,0,
10011,14,435,1,64,
10011,14,451,4,0,
10011,14,466,1,29,
10011,14,477,4,0,
10011,14,486,1,43,
10011,14,496,4,0,
10011,14,506,1,50,
10011,14,521,4,0,
10011,14,527,3,0,
10011,15,84,1,1,6
10011,15,85,4,0,
10011,15,86,1,1,5
10011,15,86,4,0,
10011,15,87,4,0,
10011,15,92,4,0,
10011,15,104,1,15,
10011,15,104,4,0,
10011,15,109,1,1,7
10011,15,113,4,0,
10011,15,115,4,0,
10011,15,138,4,0,
10011,15,148,4,0,
10011,15,156,4,0,
10011,15,164,1,36,
10011,15,164,4,0,
10011,15,168,4,0,
10011,15,182,4,0,
10011,15,207,4,0,
10011,15,214,4,0,
10011,15,216,4,0,
10011,15,218,4,0,
10011,15,237,4,0,
10011,15,240,4,0,
10011,15,241,4,0,
10011,15,244,4,0,
10011,15,247,4,0,
10011,15,253,1,8,
10011,15,261,4,0,
10011,15,263,4,0,
10011,15,268,1,1,2
10011,15,268,1,57,
10011,15,271,1,1,3
10011,15,310,1,1,4
10011,15,351,1,22,
10011,15,399,4,0,
10011,15,403,10,0,
10011,15,435,1,1,1
10011,15,435,1,64,
10011,15,451,4,0,
10011,15,466,1,29,
10011,15,486,1,43,
10011,15,496,4,0,
10011,15,506,1,50,
10011,15,521,4,0,
10011,15,590,4,0,
10011,16,84,1,1,6
10011,16,85,4,0,
10011,16,86,1,1,5
10011,16,86,4,0,
10011,16,87,4,0,
10011,16,92,4,0,
10011,16,104,1,15,1
10011,16,104,4,0,
10011,16,109,1,1,7
10011,16,113,4,0,
10011,16,115,4,0,
10011,16,138,4,0,
10011,16,148,4,0,
10011,16,156,4,0,
10011,16,164,1,36,1
10011,16,164,4,0,
10011,16,168,4,0,
10011,16,173,3,0,
10011,16,180,3,0,
10011,16,182,4,0,
10011,16,207,4,0,
10011,16,214,4,0,
10011,16,216,4,0,
10011,16,218,4,0,
10011,16,220,3,0,
10011,16,237,4,0,
10011,16,240,4,0,
10011,16,241,4,0,
10011,16,244,4,0,
10011,16,247,4,0,
10011,16,253,1,8,1
10011,16,253,3,0,
10011,16,261,4,0,
10011,16,263,4,0,
10011,16,268,1,1,2
10011,16,268,1,57,1
10011,16,271,1,1,3
10011,16,271,3,0,
10011,16,289,3,0,
10011,16,290,4,0,
10011,16,310,1,1,4
10011,16,324,3,0,
10011,16,351,1,22,1
10011,16,351,3,0,
10011,16,399,4,0,
10011,16,403,10,0,
10011,16,435,1,1,1
10011,16,435,1,64,1
10011,16,451,4,0,
10011,16,466,1,29,1
10011,16,486,1,43,1
10011,16,492,3,0,
10011,16,496,4,0,
10011,16,506,1,50,1
10011,16,521,4,0,
10011,16,527,3,0,
10011,16,590,4,0,
10011,17,84,1,1,6
10011,17,85,4,0,
10011,17,86,1,1,5
10011,17,86,4,0,
10011,17,87,4,0,
10011,17,92,4,0,
10011,17,104,1,15,
10011,17,104,4,0,
10011,17,109,1,1,7
10011,17,113,4,0,
10011,17,115,4,0,
10011,17,138,4,0,
10011,17,156,4,0,
10011,17,164,1,36,
10011,17,164,4,0,
10011,17,168,4,0,
10011,17,182,4,0,
10011,17,207,4,0,
10011,17,214,4,0,
10011,17,216,4,0,
10011,17,218,4,0,
10011,17,237,4,0,
10011,17,240,4,0,
10011,17,241,4,0,
10011,17,244,4,0,
10011,17,247,4,0,
10011,17,253,1,8,
10011,17,261,4,0,
10011,17,263,4,0,
10011,17,268,1,1,2
10011,17,268,1,57,
10011,17,271,1,1,3
10011,17,310,1,1,4
10011,17,351,1,22,
10011,17,399,4,0,
10011,17,403,10,0,
10011,17,435,1,1,1
10011,17,435,1,64,
10011,17,451,4,0,
10011,17,466,1,29,
10011,17,486,1,43,
10011,17,496,4,0,
10011,17,506,1,50,
10011,17,521,4,0,
10011,17,590,4,0,
10011,18,84,1,1,6
10011,18,85,4,0,
10011,18,86,1,1,5
10011,18,86,4,0,
10011,18,87,4,0,
10011,18,92,4,0,
10011,18,104,1,15,
10011,18,104,4,0,
10011,18,109,1,1,7
10011,18,113,4,0,
10011,18,115,4,0,
10011,18,138,4,0,
10011,18,156,4,0,
10011,18,164,1,36,
10011,18,164,4,0,
10011,18,168,4,0,
10011,18,182,4,0,
10011,18,207,4,0,
10011,18,214,4,0,
10011,18,216,4,0,
10011,18,218,4,0,
10011,18,237,4,0,
10011,18,240,4,0,
10011,18,241,4,0,
10011,18,244,4,0,
10011,18,247,4,0,
10011,18,253,1,8,
10011,18,261,4,0,
10011,18,263,4,0,
10011,18,268,1,1,2
10011,18,268,1,57,
10011,18,271,1,1,3
10011,18,310,1,1,4
10011,18,351,1,22,
10011,18,399,4,0,
10011,18,403,10,0,
10011,18,435,1,1,1
10011,18,435,1,64,
10011,18,451,4,0,
10011,18,466,1,29,
10011,18,486,1,43,
10011,18,496,4,0,
10011,18,506,1,50,
10011,18,521,4,0,
10011,18,590,4,0,
10012,9,84,1,1,4
10012,9,85,4,0,
10012,9,86,1,1,3
10012,9,86,4,0,
10012,9,87,4,0,
10012,9,92,4,0,
10012,9,104,1,15,
10012,9,104,4,0,
10012,9,109,1,1,5
10012,9,113,4,0,
10012,9,115,4,0,
10012,9,129,3,0,
10012,9,138,4,0,
10012,9,148,4,0,
10012,9,156,4,0,
10012,9,164,1,36,
10012,9,164,4,0,
10012,9,168,4,0,
10012,9,173,3,0,
10012,9,180,3,0,
10012,9,182,4,0,
10012,9,189,3,0,
10012,9,203,4,0,
10012,9,207,4,0,
10012,9,214,4,0,
10012,9,216,4,0,
10012,9,218,4,0,
10012,9,237,4,0,
10012,9,240,4,0,
10012,9,241,4,0,
10012,9,244,4,0,
10012,9,247,4,0,
10012,9,253,1,8,
10012,9,253,3,0,
10012,9,261,4,0,
10012,9,263,4,0,
10012,9,268,1,43,
10012,9,271,1,1,1
10012,9,271,3,0,
10012,9,289,4,0,
10012,9,290,4,0,
10012,9,310,1,1,2
10012,9,324,3,0,
10012,9,351,1,22,
10012,9,351,4,0,
10012,9,363,4,0,
10012,9,389,3,0,
10012,9,399,4,0,
10012,9,435,1,50,
10012,9,437,10,0,
10012,9,451,4,0,
10012,9,466,1,29,
10012,9,466,3,0,
10012,10,84,1,1,4
10012,10,85,4,0,
10012,10,86,1,1,3
10012,10,86,4,0,
10012,10,87,4,0,
10012,10,92,4,0,
10012,10,104,1,15,
10012,10,104,4,0,
10012,10,109,1,1,5
10012,10,113,4,0,
10012,10,115,4,0,
10012,10,129,3,0,
10012,10,138,4,0,
10012,10,148,4,0,
10012,10,156,4,0,
10012,10,164,1,36,
10012,10,164,4,0,
10012,10,168,4,0,
10012,10,173,3,0,
10012,10,180,3,0,
10012,10,182,4,0,
10012,10,189,3,0,
10012,10,203,4,0,
10012,10,207,4,0,
10012,10,214,4,0,
10012,10,216,4,0,
10012,10,218,4,0,
10012,10,220,3,0,
10012,10,237,4,0,
10012,10,240,4,0,
10012,10,241,4,0,
10012,10,244,4,0,
10012,10,247,4,0,
10012,10,253,1,8,
10012,10,253,3,0,
10012,10,261,4,0,
10012,10,263,4,0,
10012,10,268,1,43,
10012,10,271,1,1,1
10012,10,271,3,0,
10012,10,289,4,0,
10012,10,290,4,0,
10012,10,310,1,1,2
10012,10,324,3,0,
10012,10,351,1,22,
10012,10,351,4,0,
10012,10,363,4,0,
10012,10,389,3,0,
10012,10,399,4,0,
10012,10,435,1,50,
10012,10,437,10,0,
10012,10,451,4,0,
10012,10,466,1,29,
10012,10,466,3,0,
10012,11,84,1,1,4
10012,11,85,4,0,
10012,11,86,1,1,3
10012,11,86,4,0,
10012,11,87,4,0,
10012,11,92,4,0,
10012,11,104,1,15,
10012,11,104,4,0,
10012,11,109,1,1,5
10012,11,113,4,0,
10012,11,115,4,0,
10012,11,138,4,0,
10012,11,148,4,0,
10012,11,156,4,0,
10012,11,164,1,36,
10012,11,164,4,0,
10012,11,168,4,0,
10012,11,182,4,0,
10012,11,207,4,0,
10012,11,216,4,0,
10012,11,218,4,0,
10012,11,237,4,0,
10012,11,240,4,0,
10012,11,241,4,0,
10012,11,244,4,0,
10012,11,247,4,0,
10012,11,253,1,8,
10012,11,261,4,0,
10012,11,263,4,0,
10012,11,268,1,57,
10012,11,271,1,1,1
10012,11,310,1,1,2
10012,11,351,1,22,
10012,11,435,1,64,
10012,11,437,10,0,
10012,11,451,4,0,
10012,11,466,1,29,
10012,11,477,4,0,
10012,11,486,1,43,
10012,11,496,4,0,
10012,11,506,1,50,
10012,11,521,4,0,
10012,14,84,1,1,4
10012,14,85,4,0,
10012,14,86,1,1,3
10012,14,86,4,0,
10012,14,87,4,0,
10012,14,92,4,0,
10012,14,104,1,15,
10012,14,104,4,0,
10012,14,109,1,1,5
10012,14,113,4,0,
10012,14,115,4,0,
10012,14,138,4,0,
10012,14,148,4,0,
10012,14,156,4,0,
10012,14,164,1,36,
10012,14,164,4,0,
10012,14,168,4,0,
10012,14,173,3,0,
10012,14,180,3,0,
10012,14,182,4,0,
10012,14,207,4,0,
10012,14,214,3,0,
10012,14,216,4,0,
10012,14,218,4,0,
10012,14,220,3,0,
10012,14,237,4,0,
10012,14,240,4,0,
10012,14,241,4,0,
10012,14,244,4,0,
10012,14,247,4,0,
10012,14,253,1,8,
10012,14,253,3,0,
10012,14,261,4,0,
10012,14,263,4,0,
10012,14,268,1,57,
10012,14,271,1,1,1
10012,14,271,3,0,
10012,14,289,3,0,
10012,14,310,1,1,2
10012,14,324,3,0,
10012,14,351,1,22,
10012,14,399,3,0,
10012,14,435,1,64,
10012,14,437,10,0,
10012,14,451,4,0,
10012,14,466,1,29,
10012,14,477,4,0,
10012,14,486,1,43,
10012,14,496,4,0,
10012,14,506,1,50,
10012,14,521,4,0,
10012,14,527,3,0,
10012,15,84,1,1,6
10012,15,85,4,0,
10012,15,86,1,1,5
10012,15,86,4,0,
10012,15,87,4,0,
10012,15,92,4,0,
10012,15,104,1,15,
10012,15,104,4,0,
10012,15,109,1,1,7
10012,15,113,4,0,
10012,15,115,4,0,
10012,15,138,4,0,
10012,15,148,4,0,
10012,15,156,4,0,
10012,15,164,1,36,
10012,15,164,4,0,
10012,15,168,4,0,
10012,15,182,4,0,
10012,15,207,4,0,
10012,15,214,4,0,
10012,15,216,4,0,
10012,15,218,4,0,
10012,15,237,4,0,
10012,15,240,4,0,
10012,15,241,4,0,
10012,15,244,4,0,
10012,15,247,4,0,
10012,15,253,1,8,
10012,15,261,4,0,
10012,15,263,4,0,
10012,15,268,1,1,2
10012,15,268,1,57,
10012,15,271,1,1,3
10012,15,310,1,1,4
10012,15,351,1,22,
10012,15,399,4,0,
10012,15,435,1,1,1
10012,15,435,1,64,
10012,15,437,10,0,
10012,15,451,4,0,
10012,15,466,1,29,
10012,15,486,1,43,
10012,15,496,4,0,
10012,15,506,1,50,
10012,15,521,4,0,
10012,15,590,4,0,
10012,16,84,1,1,6
10012,16,85,4,0,
10012,16,86,1,1,5
10012,16,86,4,0,
10012,16,87,4,0,
10012,16,92,4,0,
10012,16,104,1,15,1
10012,16,104,4,0,
10012,16,109,1,1,7
10012,16,113,4,0,
10012,16,115,4,0,
10012,16,138,4,0,
10012,16,148,4,0,
10012,16,156,4,0,
10012,16,164,1,36,1
10012,16,164,4,0,
10012,16,168,4,0,
10012,16,173,3,0,
10012,16,180,3,0,
10012,16,182,4,0,
10012,16,207,4,0,
10012,16,214,4,0,
10012,16,216,4,0,
10012,16,218,4,0,
10012,16,220,3,0,
10012,16,237,4,0,
10012,16,240,4,0,
10012,16,241,4,0,
10012,16,244,4,0,
10012,16,247,4,0,
10012,16,253,1,8,1
10012,16,253,3,0,
10012,16,261,4,0,
10012,16,263,4,0,
10012,16,268,1,1,2
10012,16,268,1,57,1
10012,16,271,1,1,3
10012,16,271,3,0,
10012,16,289,3,0,
10012,16,290,4,0,
10012,16,310,1,1,4
10012,16,324,3,0,
10012,16,351,1,22,1
10012,16,351,3,0,
10012,16,399,4,0,
10012,16,435,1,1,1
10012,16,435,1,64,1
10012,16,437,10,0,
10012,16,451,4,0,
10012,16,466,1,29,1
10012,16,486,1,43,1
10012,16,492,3,0,
10012,16,496,4,0,
10012,16,506,1,50,1
10012,16,521,4,0,
10012,16,527,3,0,
10012,16,590,4,0,
10012,17,84,1,1,6
10012,17,85,4,0,
10012,17,86,1,1,5
10012,17,86,4,0,
10012,17,87,4,0,
10012,17,92,4,0,
10012,17,104,1,15,
10012,17,104,4,0,
10012,17,109,1,1,7
10012,17,113,4,0,
10012,17,115,4,0,
10012,17,138,4,0,
10012,17,156,4,0,
10012,17,164,1,36,
10012,17,164,4,0,
10012,17,168,4,0,
10012,17,182,4,0,
10012,17,207,4,0,
10012,17,214,4,0,
10012,17,216,4,0,
10012,17,218,4,0,
10012,17,237,4,0,
10012,17,240,4,0,
10012,17,241,4,0,
10012,17,244,4,0,
10012,17,247,4,0,
10012,17,253,1,8,
10012,17,261,4,0,
10012,17,263,4,0,
10012,17,268,1,1,2
10012,17,268,1,57,
10012,17,271,1,1,3
10012,17,310,1,1,4
10012,17,351,1,22,
10012,17,399,4,0,
10012,17,435,1,1,1
10012,17,435,1,64,
10012,17,437,10,0,
10012,17,451,4,0,
10012,17,466,1,29,
10012,17,486,1,43,
10012,17,496,4,0,
10012,17,506,1,50,
10012,17,521,4,0,
10012,17,590,4,0,
10012,18,84,1,1,6
10012,18,85,4,0,
10012,18,86,1,1,5
10012,18,86,4,0,
10012,18,87,4,0,
10012,18,92,4,0,
10012,18,104,1,15,
10012,18,104,4,0,
10012,18,109,1,1,7
10012,18,113,4,0,
10012,18,115,4,0,
10012,18,138,4,0,
10012,18,156,4,0,
10012,18,164,1,36,
10012,18,164,4,0,
10012,18,168,4,0,
10012,18,182,4,0,
10012,18,207,4,0,
10012,18,214,4,0,
10012,18,216,4,0,
10012,18,218,4,0,
10012,18,237,4,0,
10012,18,240,4,0,
10012,18,241,4,0,
10012,18,244,4,0,
10012,18,247,4,0,
10012,18,253,1,8,
10012,18,261,4,0,
10012,18,263,4,0,
10012,18,268,1,1,2
10012,18,268,1,57,
10012,18,271,1,1,3
10012,18,310,1,1,4
10012,18,351,1,22,
10012,18,399,4,0,
10012,18,435,1,1,1
10012,18,435,1,64,
10012,18,437,10,0,
10012,18,451,4,0,
10012,18,466,1,29,
10012,18,486,1,43,
10012,18,496,4,0,
10012,18,506,1,50,
10012,18,521,4,0,
10012,18,590,4,0,
10013,5,33,1,1,
10013,5,52,1,10,2
10013,5,53,4,0,
10013,5,55,1,10,1
10013,5,58,4,0,
10013,5,59,4,0,
10013,5,76,4,0,
10013,5,85,4,0,
10013,5,87,4,0,
10013,5,92,4,0,
10013,5,104,4,0,
10013,5,126,4,0,
10013,5,148,4,0,
10013,5,156,4,0,
10013,5,168,4,0,
10013,5,181,1,10,3
10013,5,182,4,0,
10013,5,201,4,0,
10013,5,213,4,0,
10013,5,216,4,0,
10013,5,218,4,0,
10013,5,237,4,0,
10013,5,240,1,20,
10013,5,240,4,0,
10013,5,241,1,20,4
10013,5,241,4,0,
10013,5,244,2,0,
10013,5,247,4,0,
10013,5,248,2,0,
10013,5,258,1,20,5
10013,5,258,4,0,
10013,5,263,4,0,
10013,5,290,4,0,
10013,5,311,1,30,
10013,5,351,4,0,
10013,5,352,4,0,
10013,6,33,1,1,
10013,6,34,3,0,
10013,6,38,3,0,
10013,6,52,1,10,2
10013,6,53,4,0,
10013,6,55,1,10,1
10013,6,58,4,0,
10013,6,59,4,0,
10013,6,76,4,0,
10013,6,85,4,0,
10013,6,86,3,0,
10013,6,87,4,0,
10013,6,92,4,0,
10013,6,102,3,0,
10013,6,104,4,0,
10013,6,111,3,0,
10013,6,126,4,0,
10013,6,129,3,0,
10013,6,148,4,0,
10013,6,156,4,0,
10013,6,164,3,0,
10013,6,168,4,0,
10013,6,173,3,0,
10013,6,181,1,10,3
10013,6,182,4,0,
10013,6,196,3,0,
10013,6,201,4,0,
10013,6,203,3,0,
10013,6,207,3,0,
10013,6,213,4,0,
10013,6,214,3,0,
10013,6,216,4,0,
10013,6,218,4,0,
10013,6,237,4,0,
10013,6,240,1,20,
10013,6,240,4,0,
10013,6,241,1,20,4
10013,6,241,4,0,
10013,6,244,2,0,
10013,6,244,3,0,
10013,6,247,4,0,
10013,6,248,2,0,
10013,6,258,1,20,5
10013,6,258,4,0,
10013,6,263,4,0,
10013,6,290,4,0,
10013,6,311,1,30,
10013,6,351,4,0,
10013,6,352,4,0,
10013,7,33,1,1,
10013,7,34,3,0,
10013,7,38,3,0,
10013,7,52,1,10,2
10013,7,53,4,0,
10013,7,55,1,10,1
10013,7,58,4,0,
10013,7,59,4,0,
10013,7,76,4,0,
10013,7,85,4,0,
10013,7,86,3,0,
10013,7,87,4,0,
10013,7,92,4,0,
10013,7,102,3,0,
10013,7,104,4,0,
10013,7,126,4,0,
10013,7,148,4,0,
10013,7,156,4,0,
10013,7,164,3,0,
10013,7,168,4,0,
10013,7,181,1,10,3
10013,7,182,4,0,
10013,7,201,4,0,
10013,7,213,4,0,
10013,7,216,4,0,
10013,7,218,4,0,
10013,7,237,4,0,
10013,7,240,1,20,
10013,7,240,4,0,
10013,7,241,1,20,4
10013,7,241,4,0,
10013,7,244,2,0,
10013,7,247,4,0,
10013,7,248,2,0,
10013,7,258,1,20,5
10013,7,258,4,0,
10013,7,263,4,0,
10013,7,290,4,0,
10013,7,311,1,30,
10013,7,351,4,0,
10013,7,352,4,0,
10013,8,33,1,1,
10013,8,50,2,0,
10013,8,52,1,10,2
10013,8,53,4,0,
10013,8,55,1,10,1
10013,8,58,4,0,
10013,8,59,4,0,
10013,8,76,4,0,
10013,8,85,4,0,
10013,8,86,4,0,
10013,8,87,4,0,
10013,8,92,4,0,
10013,8,104,4,0,
10013,8,126,4,0,
10013,8,133,2,0,
10013,8,148,4,0,
10013,8,156,4,0,
10013,8,164,4,0,
10013,8,168,4,0,
10013,8,181,1,10,3
10013,8,182,4,0,
10013,8,201,4,0,
10013,8,203,4,0,
10013,8,207,4,0,
10013,8,213,4,0,
10013,8,214,4,0,
10013,8,216,4,0,
10013,8,218,4,0,
10013,8,237,4,0,
10013,8,240,1,20,1
10013,8,240,4,0,
10013,8,241,1,20,2
10013,8,241,4,0,
10013,8,244,2,0,
10013,8,244,4,0,
10013,8,247,4,0,
10013,8,248,2,0,
10013,8,258,1,20,3
10013,8,258,4,0,
10013,8,263,4,0,
10013,8,290,4,0,
10013,8,311,1,30,
10013,8,351,4,0,
10013,8,352,4,0,
10013,8,363,4,0,
10013,8,381,2,0,
10013,8,412,4,0,
10013,8,419,4,0,
10013,8,445,4,0,
10013,8,466,2,0,
10013,9,33,1,1,
10013,9,50,2,0,
10013,9,52,1,10,2
10013,9,53,4,0,
10013,9,55,1,10,1
10013,9,58,4,0,
10013,9,59,4,0,
10013,9,76,4,0,
10013,9,85,4,0,
10013,9,86,4,0,
10013,9,87,4,0,
10013,9,92,4,0,
10013,9,104,4,0,
10013,9,126,4,0,
10013,9,129,3,0,
10013,9,133,2,0,
10013,9,148,4,0,
10013,9,156,4,0,
10013,9,164,4,0,
10013,9,168,4,0,
10013,9,173,3,0,
10013,9,181,1,10,3
10013,9,182,4,0,
10013,9,196,3,0,
10013,9,201,4,0,
10013,9,203,4,0,
10013,9,207,4,0,
10013,9,213,4,0,
10013,9,214,4,0,
10013,9,216,4,0,
10013,9,218,4,0,
10013,9,237,4,0,
10013,9,240,1,20,1
10013,9,240,4,0,
10013,9,241,1,20,2
10013,9,241,4,0,
10013,9,244,2,0,
10013,9,244,4,0,
10013,9,247,4,0,
10013,9,248,2,0,
10013,9,258,1,20,3
10013,9,258,4,0,
10013,9,263,4,0,
10013,9,290,4,0,
10013,9,311,1,30,
10013,9,351,4,0,
10013,9,352,4,0,
10013,9,363,4,0,
10013,9,381,2,0,
10013,9,387,3,0,
10013,9,412,4,0,
10013,9,419,4,0,
10013,9,445,4,0,
10013,9,466,2,0,
10013,9,466,3,0,
10013,10,33,1,1,
10013,10,50,2,0,
10013,10,52,1,10,2
10013,10,53,4,0,
10013,10,55,1,10,1
10013,10,58,4,0,
10013,10,59,4,0,
10013,10,76,4,0,
10013,10,85,4,0,
10013,10,86,4,0,
10013,10,87,4,0,
10013,10,92,4,0,
10013,10,104,4,0,
10013,10,126,4,0,
10013,10,129,3,0,
10013,10,133,2,0,
10013,10,148,4,0,
10013,10,156,4,0,
10013,10,164,4,0,
10013,10,168,4,0,
10013,10,173,3,0,
10013,10,181,1,10,3
10013,10,182,4,0,
10013,10,196,3,0,
10013,10,201,4,0,
10013,10,203,4,0,
10013,10,207,4,0,
10013,10,213,4,0,
10013,10,214,4,0,
10013,10,216,4,0,
10013,10,218,4,0,
10013,10,237,4,0,
10013,10,240,1,20,1
10013,10,240,4,0,
10013,10,241,1,20,2
10013,10,241,4,0,
10013,10,244,2,0,
10013,10,244,4,0,
10013,10,247,4,0,
10013,10,248,2,0,
10013,10,258,1,20,3
10013,10,258,4,0,
10013,10,263,4,0,
10013,10,290,4,0,
10013,10,311,1,30,
10013,10,351,4,0,
10013,10,352,4,0,
10013,10,363,4,0,
10013,10,366,3,0,
10013,10,381,2,0,
10013,10,387,3,0,
10013,10,412,4,0,
10013,10,419,4,0,
10013,10,445,4,0,
10013,10,466,2,0,
10013,10,466,3,0,
10013,11,29,1,20,
10013,11,33,1,1,
10013,11,50,2,0,
10013,11,52,1,10,2
10013,11,53,4,0,
10013,11,55,1,10,1
10013,11,56,1,50,1
10013,11,58,4,0,
10013,11,59,1,50,3
10013,11,59,4,0,
10013,11,76,4,0,
10013,11,85,4,0,
10013,11,86,4,0,
10013,11,87,4,0,
10013,11,92,4,0,
10013,11,104,4,0,
10013,11,126,1,50,2
10013,11,126,4,0,
10013,11,133,2,0,
10013,11,148,4,0,
10013,11,156,4,0,
10013,11,164,4,0,
10013,11,168,4,0,
10013,11,181,1,10,3
10013,11,182,4,0,
10013,11,201,4,0,
10013,11,207,4,0,
10013,11,213,4,0,
10013,11,216,4,0,
10013,11,218,4,0,
10013,11,237,4,0,
10013,11,240,1,30,1
10013,11,240,4,0,
10013,11,241,1,30,2
10013,11,241,4,0,
10013,11,244,4,0,
10013,11,247,4,0,
10013,11,248,2,0,
10013,11,258,1,30,3
10013,11,258,4,0,
10013,11,263,4,0,
10013,11,311,1,40,
10013,11,381,2,0,
10013,11,412,4,0,
10013,11,466,2,0,
10013,11,496,4,0,
10013,11,499,2,0,
10013,11,503,4,0,
10013,11,506,2,0,
10013,11,510,4,0,
10013,11,514,4,0,
10013,11,526,4,0,
10013,12,33,1,1,
10013,12,52,1,10,2
10013,12,53,4,0,
10013,12,55,1,10,1
10013,12,58,4,0,
10013,12,59,4,0,
10013,12,76,4,0,
10013,12,85,4,0,
10013,12,87,4,0,
10013,12,92,4,0,
10013,12,104,4,0,
10013,12,126,4,0,
10013,12,148,4,0,
10013,12,156,4,0,
10013,12,168,4,0,
10013,12,181,1,10,3
10013,12,182,4,0,
10013,12,201,4,0,
10013,12,213,4,0,
10013,12,216,4,0,
10013,12,218,4,0,
10013,12,237,4,0,
10013,12,240,1,20,
10013,12,240,4,0,
10013,12,241,1,20,4
10013,12,241,4,0,
10013,12,247,4,0,
10013,12,258,1,20,5
10013,12,258,4,0,
10013,12,263,4,0,
10013,12,290,4,0,
10013,12,311,1,30,
10013,12,351,4,0,
10013,12,352,4,0,
10013,13,33,1,1,
10013,13,34,3,0,
10013,13,38,3,0,
10013,13,52,1,10,2
10013,13,53,4,0,
10013,13,55,1,10,1
10013,13,58,4,0,
10013,13,59,4,0,
10013,13,76,4,0,
10013,13,85,4,0,
10013,13,86,3,0,
10013,13,87,4,0,
10013,13,92,4,0,
10013,13,102,3,0,
10013,13,104,4,0,
10013,13,126,4,0,
10013,13,148,4,0,
10013,13,156,4,0,
10013,13,164,3,0,
10013,13,168,4,0,
10013,13,181,1,10,3
10013,13,182,4,0,
10013,13,196,3,0,
10013,13,201,4,0,
10013,13,207,3,0,
10013,13,213,4,0,
10013,13,216,4,0,
10013,13,218,4,0,
10013,13,237,4,0,
10013,13,240,1,20,
10013,13,240,4,0,
10013,13,241,1,20,4
10013,13,241,4,0,
10013,13,247,4,0,
10013,13,258,1,20,5
10013,13,258,4,0,
10013,13,263,4,0,
10013,13,290,4,0,
10013,13,311,1,30,
10013,13,351,4,0,
10013,13,352,4,0,
10013,14,29,1,20,
10013,14,33,1,1,
10013,14,50,2,0,
10013,14,52,1,10,2
10013,14,53,4,0,
10013,14,55,1,10,1
10013,14,56,1,50,1
10013,14,58,4,0,
10013,14,59,1,50,3
10013,14,59,4,0,
10013,14,76,4,0,
10013,14,85,4,0,
10013,14,86,4,0,
10013,14,87,4,0,
10013,14,92,4,0,
10013,14,104,4,0,
10013,14,126,1,50,2
10013,14,126,4,0,
10013,14,133,2,0,
10013,14,148,4,0,
10013,14,156,4,0,
10013,14,164,4,0,
10013,14,168,4,0,
10013,14,173,3,0,
10013,14,181,1,10,3
10013,14,182,4,0,
10013,14,196,3,0,
10013,14,201,4,0,
10013,14,207,4,0,
10013,14,213,4,0,
10013,14,214,3,0,
10013,14,216,4,0,
10013,14,218,4,0,
10013,14,237,4,0,
10013,14,240,1,30,1
10013,14,240,4,0,
10013,14,241,1,30,2
10013,14,241,4,0,
10013,14,244,4,0,
10013,14,247,4,0,
10013,14,248,2,0,
10013,14,258,1,30,3
10013,14,258,4,0,
10013,14,263,4,0,
10013,14,311,1,40,
10013,14,366,3,0,
10013,14,381,2,0,
10013,14,387,3,0,
10013,14,412,4,0,
10013,14,466,2,0,
10013,14,496,4,0,
10013,14,499,2,0,
10013,14,503,4,0,
10013,14,506,2,0,
10013,14,510,4,0,
10013,14,514,4,0,
10013,14,526,4,0,
10013,15,29,1,15,
10013,15,33,1,1,
10013,15,50,2,0,
10013,15,52,1,10,2
10013,15,53,4,0,
10013,15,55,1,10,1
10013,15,56,1,40,1
10013,15,58,4,0,
10013,15,59,1,40,3
10013,15,59,4,0,
10013,15,76,4,0,
10013,15,85,4,0,
10013,15,86,4,0,
10013,15,87,4,0,
10013,15,92,4,0,
10013,15,104,4,0,
10013,15,126,1,40,2
10013,15,126,4,0,
10013,15,133,2,0,
10013,15,148,4,0,
10013,15,156,4,0,
10013,15,164,4,0,
10013,15,168,4,0,
10013,15,181,1,10,3
10013,15,182,4,0,
10013,15,201,4,0,
10013,15,207,4,0,
10013,15,213,4,0,
10013,15,214,4,0,
10013,15,216,4,0,
10013,15,218,4,0,
10013,15,237,4,0,
10013,15,240,1,20,1
10013,15,240,4,0,
10013,15,241,1,20,2
10013,15,241,4,0,
10013,15,244,4,0,
10013,15,247,4,0,
10013,15,248,2,0,
10013,15,258,1,20,3
10013,15,258,4,0,
10013,15,263,4,0,
10013,15,311,1,30,
10013,15,322,2,0,
10013,15,381,2,0,
10013,15,385,2,0,
10013,15,412,4,0,
10013,15,466,2,0,
10013,15,496,4,0,
10013,15,499,2,0,
10013,15,503,4,0,
10013,15,506,2,0,
10013,15,510,4,0,
10013,15,513,2,0,
10013,15,514,4,0,
10013,15,590,4,0,
10013,16,29,1,15,1
10013,16,33,1,1,1
10013,16,52,1,10,2
10013,16,53,4,0,
10013,16,55,1,10,1
10013,16,56,1,35,1
10013,16,58,4,0,
10013,16,59,1,35,3
10013,16,59,4,0,
10013,16,76,4,0,
10013,16,85,4,0,
10013,16,86,4,0,
10013,16,87,4,0,
10013,16,92,4,0,
10013,16,104,4,0,
10013,16,126,1,35,2
10013,16,126,4,0,
10013,16,148,4,0,
10013,16,156,4,0,
10013,16,164,4,0,
10013,16,168,4,0,
10013,16,173,3,0,
10013,16,181,1,10,3
10013,16,182,4,0,
10013,16,196,3,0,
10013,16,201,4,0,
10013,16,207,4,0,
10013,16,213,4,0,
10013,16,214,4,0,
10013,16,216,4,0,
10013,16,218,4,0,
10013,16,237,4,0,
10013,16,240,1,20,1
10013,16,240,4,0,
10013,16,241,1,20,2
10013,16,241,4,0,
10013,16,244,4,0,
10013,16,247,4,0,
10013,16,258,1,20,3
10013,16,258,4,0,
10013,16,263,4,0,
10013,16,290,4,0,
10013,16,311,1,25,1
10013,16,351,3,0,
10013,16,352,3,0,
10013,16,366,3,0,
10013,16,387,3,0,
10013,16,412,4,0,
10013,16,496,4,0,
10013,16,503,4,0,
10013,16,510,4,0,
10013,16,514,4,0,
10013,16,542,1,45,1
10013,16,590,4,0,
10013,17,29,1,15,
10013,17,33,1,1,
10013,17,52,1,10,2
10013,17,53,4,0,
10013,17,55,1,10,1
10013,17,56,1,35,1
10013,17,58,4,0,
10013,17,59,1,35,3
10013,17,59,4,0,
10013,17,76,4,0,
10013,17,85,4,0,
10013,17,86,4,0,
10013,17,87,4,0,
10013,17,92,4,0,
10013,17,104,4,0,
10013,17,126,1,35,2
10013,17,126,4,0,
10013,17,156,4,0,
10013,17,164,4,0,
10013,17,168,4,0,
10013,17,181,1,10,3
10013,17,182,4,0,
10013,17,201,4,0,
10013,17,207,4,0,
10013,17,213,4,0,
10013,17,214,4,0,
10013,17,216,4,0,
10013,17,218,4,0,
10013,17,237,4,0,
10013,17,240,1,20,1
10013,17,240,4,0,
10013,17,241,1,20,2
10013,17,241,4,0,
10013,17,244,4,0,
10013,17,247,4,0,
10013,17,258,1,20,3
10013,17,258,4,0,
10013,17,263,4,0,
10013,17,311,1,25,
10013,17,412,4,0,
10013,17,496,4,0,
10013,17,503,4,0,
10013,17,542,1,45,
10013,17,590,4,0,
10013,18,29,1,15,
10013,18,33,1,1,
10013,18,52,1,10,2
10013,18,53,4,0,
10013,18,55,1,10,1
10013,18,56,1,35,1
10013,18,58,4,0,
10013,18,59,1,35,3
10013,18,59,4,0,
10013,18,76,4,0,
10013,18,85,4,0,
10013,18,86,4,0,
10013,18,87,4,0,
10013,18,92,4,0,
10013,18,104,4,0,
10013,18,126,1,35,2
10013,18,126,4,0,
10013,18,156,4,0,
10013,18,164,4,0,
10013,18,168,4,0,
10013,18,181,1,10,3
10013,18,182,4,0,
10013,18,201,4,0,
10013,18,207,4,0,
10013,18,213,4,0,
10013,18,214,4,0,
10013,18,216,4,0,
10013,18,218,4,0,
10013,18,237,4,0,
10013,18,240,1,20,1
10013,18,240,4,0,
10013,18,241,1,20,2
10013,18,241,4,0,
10013,18,244,4,0,
10013,18,247,4,0,
10013,18,258,1,20,3
10013,18,258,4,0,
10013,18,263,4,0,
10013,18,311,1,25,
10013,18,412,4,0,
10013,18,496,4,0,
10013,18,503,4,0,
10013,18,526,4,0,
10013,18,542,1,45,
10013,18,590,4,0,
10014,5,33,1,1,
10014,5,52,1,10,2
10014,5,53,4,0,
10014,5,55,1,10,1
10014,5,58,4,0,
10014,5,59,4,0,
10014,5,76,4,0,
10014,5,85,4,0,
10014,5,87,4,0,
10014,5,92,4,0,
10014,5,104,4,0,
10014,5,126,4,0,
10014,5,148,4,0,
10014,5,156,4,0,
10014,5,168,4,0,
10014,5,181,1,10,3
10014,5,182,4,0,
10014,5,201,4,0,
10014,5,213,4,0,
10014,5,216,4,0,
10014,5,218,4,0,
10014,5,237,4,0,
10014,5,240,1,20,
10014,5,240,4,0,
10014,5,241,1,20,4
10014,5,241,4,0,
10014,5,244,2,0,
10014,5,247,4,0,
10014,5,248,2,0,
10014,5,258,1,20,5
10014,5,258,4,0,
10014,5,263,4,0,
10014,5,290,4,0,
10014,5,311,1,30,
10014,5,351,4,0,
10014,5,352,4,0,
10014,6,33,1,1,
10014,6,34,3,0,
10014,6,38,3,0,
10014,6,52,1,10,2
10014,6,53,4,0,
10014,6,55,1,10,1
10014,6,58,4,0,
10014,6,59,4,0,
10014,6,76,4,0,
10014,6,85,4,0,
10014,6,86,3,0,
10014,6,87,4,0,
10014,6,92,4,0,
10014,6,102,3,0,
10014,6,104,4,0,
10014,6,111,3,0,
10014,6,126,4,0,
10014,6,129,3,0,
10014,6,148,4,0,
10014,6,156,4,0,
10014,6,164,3,0,
10014,6,168,4,0,
10014,6,173,3,0,
10014,6,181,1,10,3
10014,6,182,4,0,
10014,6,196,3,0,
10014,6,201,4,0,
10014,6,203,3,0,
10014,6,207,3,0,
10014,6,213,4,0,
10014,6,214,3,0,
10014,6,216,4,0,
10014,6,218,4,0,
10014,6,237,4,0,
10014,6,240,1,20,
10014,6,240,4,0,
10014,6,241,1,20,4
10014,6,241,4,0,
10014,6,244,2,0,
10014,6,244,3,0,
10014,6,247,4,0,
10014,6,248,2,0,
10014,6,258,1,20,5
10014,6,258,4,0,
10014,6,263,4,0,
10014,6,290,4,0,
10014,6,311,1,30,
10014,6,351,4,0,
10014,6,352,4,0,
10014,7,33,1,1,
10014,7,34,3,0,
10014,7,38,3,0,
10014,7,52,1,10,2
10014,7,53,4,0,
10014,7,55,1,10,1
10014,7,58,4,0,
10014,7,59,4,0,
10014,7,76,4,0,
10014,7,85,4,0,
10014,7,86,3,0,
10014,7,87,4,0,
10014,7,92,4,0,
10014,7,102,3,0,
10014,7,104,4,0,
10014,7,126,4,0,
10014,7,148,4,0,
10014,7,156,4,0,
10014,7,164,3,0,
10014,7,168,4,0,
10014,7,181,1,10,3
10014,7,182,4,0,
10014,7,201,4,0,
10014,7,213,4,0,
10014,7,216,4,0,
10014,7,218,4,0,
10014,7,237,4,0,
10014,7,240,1,20,
10014,7,240,4,0,
10014,7,241,1,20,4
10014,7,241,4,0,
10014,7,244,2,0,
10014,7,247,4,0,
10014,7,248,2,0,
10014,7,258,1,20,5
10014,7,258,4,0,
10014,7,263,4,0,
10014,7,290,4,0,
10014,7,311,1,30,
10014,7,351,4,0,
10014,7,352,4,0,
10014,8,33,1,1,
10014,8,50,2,0,
10014,8,52,1,10,2
10014,8,53,4,0,
10014,8,55,1,10,1
10014,8,58,4,0,
10014,8,59,4,0,
10014,8,76,4,0,
10014,8,85,4,0,
10014,8,86,4,0,
10014,8,87,4,0,
10014,8,92,4,0,
10014,8,104,4,0,
10014,8,126,4,0,
10014,8,133,2,0,
10014,8,148,4,0,
10014,8,156,4,0,
10014,8,164,4,0,
10014,8,168,4,0,
10014,8,181,1,10,3
10014,8,182,4,0,
10014,8,201,4,0,
10014,8,203,4,0,
10014,8,207,4,0,
10014,8,213,4,0,
10014,8,214,4,0,
10014,8,216,4,0,
10014,8,218,4,0,
10014,8,237,4,0,
10014,8,240,1,20,1
10014,8,240,4,0,
10014,8,241,1,20,2
10014,8,241,4,0,
10014,8,244,2,0,
10014,8,244,4,0,
10014,8,247,4,0,
10014,8,248,2,0,
10014,8,258,1,20,3
10014,8,258,4,0,
10014,8,263,4,0,
10014,8,290,4,0,
10014,8,311,1,30,
10014,8,351,4,0,
10014,8,352,4,0,
10014,8,363,4,0,
10014,8,381,2,0,
10014,8,412,4,0,
10014,8,419,4,0,
10014,8,445,4,0,
10014,8,466,2,0,
10014,9,33,1,1,
10014,9,50,2,0,
10014,9,52,1,10,2
10014,9,53,4,0,
10014,9,55,1,10,1
10014,9,58,4,0,
10014,9,59,4,0,
10014,9,76,4,0,
10014,9,85,4,0,
10014,9,86,4,0,
10014,9,87,4,0,
10014,9,92,4,0,
10014,9,104,4,0,
10014,9,126,4,0,
10014,9,129,3,0,
10014,9,133,2,0,
10014,9,148,4,0,
10014,9,156,4,0,
10014,9,164,4,0,
10014,9,168,4,0,
10014,9,173,3,0,
10014,9,181,1,10,3
10014,9,182,4,0,
10014,9,196,3,0,
10014,9,201,4,0,
10014,9,203,4,0,
10014,9,207,4,0,
10014,9,213,4,0,
10014,9,214,4,0,
10014,9,216,4,0,
10014,9,218,4,0,
10014,9,237,4,0,
10014,9,240,1,20,1
10014,9,240,4,0,
10014,9,241,1,20,2
10014,9,241,4,0,
10014,9,244,2,0,
10014,9,244,4,0,
10014,9,247,4,0,
10014,9,248,2,0,
10014,9,258,1,20,3
10014,9,258,4,0,
10014,9,263,4,0,
10014,9,290,4,0,
10014,9,311,1,30,
10014,9,351,4,0,
10014,9,352,4,0,
10014,9,363,4,0,
10014,9,381,2,0,
10014,9,387,3,0,
10014,9,412,4,0,
10014,9,419,4,0,
10014,9,445,4,0,
10014,9,466,2,0,
10014,9,466,3,0,
10014,10,33,1,1,
10014,10,50,2,0,
10014,10,52,1,10,2
10014,10,53,4,0,
10014,10,55,1,10,1
10014,10,58,4,0,
10014,10,59,4,0,
10014,10,76,4,0,
10014,10,85,4,0,
10014,10,86,4,0,
10014,10,87,4,0,
10014,10,92,4,0,
10014,10,104,4,0,
10014,10,126,4,0,
10014,10,129,3,0,
10014,10,133,2,0,
10014,10,148,4,0,
10014,10,156,4,0,
10014,10,164,4,0,
10014,10,168,4,0,
10014,10,173,3,0,
10014,10,181,1,10,3
10014,10,182,4,0,
10014,10,196,3,0,
10014,10,201,4,0,
10014,10,203,4,0,
10014,10,207,4,0,
10014,10,213,4,0,
10014,10,214,4,0,
10014,10,216,4,0,
10014,10,218,4,0,
10014,10,237,4,0,
10014,10,240,1,20,1
10014,10,240,4,0,
10014,10,241,1,20,2
10014,10,241,4,0,
10014,10,244,2,0,
10014,10,244,4,0,
10014,10,247,4,0,
10014,10,248,2,0,
10014,10,258,1,20,3
10014,10,258,4,0,
10014,10,263,4,0,
10014,10,290,4,0,
10014,10,311,1,30,
10014,10,351,4,0,
10014,10,352,4,0,
10014,10,363,4,0,
10014,10,366,3,0,
10014,10,381,2,0,
10014,10,387,3,0,
10014,10,412,4,0,
10014,10,419,4,0,
10014,10,445,4,0,
10014,10,466,2,0,
10014,10,466,3,0,
10014,11,29,1,20,
10014,11,33,1,1,
10014,11,50,2,0,
10014,11,52,1,10,2
10014,11,53,4,0,
10014,11,55,1,10,1
10014,11,56,1,50,1
10014,11,58,4,0,
10014,11,59,1,50,3
10014,11,59,4,0,
10014,11,76,4,0,
10014,11,85,4,0,
10014,11,86,4,0,
10014,11,87,4,0,
10014,11,92,4,0,
10014,11,104,4,0,
10014,11,126,1,50,2
10014,11,126,4,0,
10014,11,133,2,0,
10014,11,148,4,0,
10014,11,156,4,0,
10014,11,164,4,0,
10014,11,168,4,0,
10014,11,181,1,10,3
10014,11,182,4,0,
10014,11,201,4,0,
10014,11,207,4,0,
10014,11,213,4,0,
10014,11,216,4,0,
10014,11,218,4,0,
10014,11,237,4,0,
10014,11,240,1,30,1
10014,11,240,4,0,
10014,11,241,1,30,2
10014,11,241,4,0,
10014,11,244,4,0,
10014,11,247,4,0,
10014,11,248,2,0,
10014,11,258,1,30,3
10014,11,258,4,0,
10014,11,263,4,0,
10014,11,311,1,40,
10014,11,381,2,0,
10014,11,412,4,0,
10014,11,466,2,0,
10014,11,496,4,0,
10014,11,499,2,0,
10014,11,503,4,0,
10014,11,506,2,0,
10014,11,510,4,0,
10014,11,514,4,0,
10014,11,526,4,0,
10014,12,33,1,1,
10014,12,52,1,10,2
10014,12,53,4,0,
10014,12,55,1,10,1
10014,12,58,4,0,
10014,12,59,4,0,
10014,12,76,4,0,
10014,12,85,4,0,
10014,12,87,4,0,
10014,12,92,4,0,
10014,12,104,4,0,
10014,12,126,4,0,
10014,12,148,4,0,
10014,12,156,4,0,
10014,12,168,4,0,
10014,12,181,1,10,3
10014,12,182,4,0,
10014,12,201,4,0,
10014,12,213,4,0,
10014,12,216,4,0,
10014,12,218,4,0,
10014,12,237,4,0,
10014,12,240,1,20,
10014,12,240,4,0,
10014,12,241,1,20,4
10014,12,241,4,0,
10014,12,247,4,0,
10014,12,258,1,20,5
10014,12,258,4,0,
10014,12,263,4,0,
10014,12,290,4,0,
10014,12,311,1,30,
10014,12,351,4,0,
10014,12,352,4,0,
10014,13,33,1,1,
10014,13,34,3,0,
10014,13,38,3,0,
10014,13,52,1,10,2
10014,13,53,4,0,
10014,13,55,1,10,1
10014,13,58,4,0,
10014,13,59,4,0,
10014,13,76,4,0,
10014,13,85,4,0,
10014,13,86,3,0,
10014,13,87,4,0,
10014,13,92,4,0,
10014,13,102,3,0,
10014,13,104,4,0,
10014,13,126,4,0,
10014,13,148,4,0,
10014,13,156,4,0,
10014,13,164,3,0,
10014,13,168,4,0,
10014,13,181,1,10,3
10014,13,182,4,0,
10014,13,196,3,0,
10014,13,201,4,0,
10014,13,207,3,0,
10014,13,213,4,0,
10014,13,216,4,0,
10014,13,218,4,0,
10014,13,237,4,0,
10014,13,240,1,20,
10014,13,240,4,0,
10014,13,241,1,20,4
10014,13,241,4,0,
10014,13,247,4,0,
10014,13,258,1,20,5
10014,13,258,4,0,
10014,13,263,4,0,
10014,13,290,4,0,
10014,13,311,1,30,
10014,13,351,4,0,
10014,13,352,4,0,
10014,14,29,1,20,
10014,14,33,1,1,
10014,14,50,2,0,
10014,14,52,1,10,2
10014,14,53,4,0,
10014,14,55,1,10,1
10014,14,56,1,50,1
10014,14,58,4,0,
10014,14,59,1,50,3
10014,14,59,4,0,
10014,14,76,4,0,
10014,14,85,4,0,
10014,14,86,4,0,
10014,14,87,4,0,
10014,14,92,4,0,
10014,14,104,4,0,
10014,14,126,1,50,2
10014,14,126,4,0,
10014,14,133,2,0,
10014,14,148,4,0,
10014,14,156,4,0,
10014,14,164,4,0,
10014,14,168,4,0,
10014,14,173,3,0,
10014,14,181,1,10,3
10014,14,182,4,0,
10014,14,196,3,0,
10014,14,201,4,0,
10014,14,207,4,0,
10014,14,213,4,0,
10014,14,214,3,0,
10014,14,216,4,0,
10014,14,218,4,0,
10014,14,237,4,0,
10014,14,240,1,30,1
10014,14,240,4,0,
10014,14,241,1,30,2
10014,14,241,4,0,
10014,14,244,4,0,
10014,14,247,4,0,
10014,14,248,2,0,
10014,14,258,1,30,3
10014,14,258,4,0,
10014,14,263,4,0,
10014,14,311,1,40,
10014,14,366,3,0,
10014,14,381,2,0,
10014,14,387,3,0,
10014,14,412,4,0,
10014,14,466,2,0,
10014,14,496,4,0,
10014,14,499,2,0,
10014,14,503,4,0,
10014,14,506,2,0,
10014,14,510,4,0,
10014,14,514,4,0,
10014,14,526,4,0,
10014,15,29,1,15,
10014,15,33,1,1,
10014,15,50,2,0,
10014,15,52,1,10,2
10014,15,53,4,0,
10014,15,55,1,10,1
10014,15,56,1,40,1
10014,15,58,4,0,
10014,15,59,1,40,3
10014,15,59,4,0,
10014,15,76,4,0,
10014,15,85,4,0,
10014,15,86,4,0,
10014,15,87,4,0,
10014,15,92,4,0,
10014,15,104,4,0,
10014,15,126,1,40,2
10014,15,126,4,0,
10014,15,133,2,0,
10014,15,148,4,0,
10014,15,156,4,0,
10014,15,164,4,0,
10014,15,168,4,0,
10014,15,181,1,10,3
10014,15,182,4,0,
10014,15,201,4,0,
10014,15,207,4,0,
10014,15,213,4,0,
10014,15,214,4,0,
10014,15,216,4,0,
10014,15,218,4,0,
10014,15,237,4,0,
10014,15,240,1,20,1
10014,15,240,4,0,
10014,15,241,1,20,2
10014,15,241,4,0,
10014,15,244,4,0,
10014,15,247,4,0,
10014,15,248,2,0,
10014,15,258,1,20,3
10014,15,258,4,0,
10014,15,263,4,0,
10014,15,311,1,30,
10014,15,322,2,0,
10014,15,381,2,0,
10014,15,385,2,0,
10014,15,412,4,0,
10014,15,466,2,0,
10014,15,496,4,0,
10014,15,499,2,0,
10014,15,503,4,0,
10014,15,506,2,0,
10014,15,510,4,0,
10014,15,513,2,0,
10014,15,514,4,0,
10014,15,590,4,0,
10014,16,29,1,15,1
10014,16,33,1,1,1
10014,16,52,1,10,2
10014,16,53,4,0,
10014,16,55,1,10,1
10014,16,56,1,35,1
10014,16,58,4,0,
10014,16,59,1,35,3
10014,16,59,4,0,
10014,16,76,4,0,
10014,16,85,4,0,
10014,16,86,4,0,
10014,16,87,4,0,
10014,16,92,4,0,
10014,16,104,4,0,
10014,16,126,1,35,2
10014,16,126,4,0,
10014,16,148,4,0,
10014,16,156,4,0,
10014,16,164,4,0,
10014,16,168,4,0,
10014,16,173,3,0,
10014,16,181,1,10,3
10014,16,182,4,0,
10014,16,196,3,0,
10014,16,201,4,0,
10014,16,207,4,0,
10014,16,213,4,0,
10014,16,214,4,0,
10014,16,216,4,0,
10014,16,218,4,0,
10014,16,237,4,0,
10014,16,240,1,20,1
10014,16,240,4,0,
10014,16,241,1,20,2
10014,16,241,4,0,
10014,16,244,4,0,
10014,16,247,4,0,
10014,16,258,1,20,3
10014,16,258,4,0,
10014,16,263,4,0,
10014,16,290,4,0,
10014,16,311,1,25,1
10014,16,351,3,0,
10014,16,352,3,0,
10014,16,366,3,0,
10014,16,387,3,0,
10014,16,412,4,0,
10014,16,496,4,0,
10014,16,503,4,0,
10014,16,510,4,0,
10014,16,514,4,0,
10014,16,542,1,45,1
10014,16,590,4,0,
10014,17,29,1,15,
10014,17,33,1,1,
10014,17,52,1,10,2
10014,17,53,4,0,
10014,17,55,1,10,1
10014,17,56,1,35,1
10014,17,58,4,0,
10014,17,59,1,35,3
10014,17,59,4,0,
10014,17,76,4,0,
10014,17,85,4,0,
10014,17,86,4,0,
10014,17,87,4,0,
10014,17,92,4,0,
10014,17,104,4,0,
10014,17,126,1,35,2
10014,17,126,4,0,
10014,17,156,4,0,
10014,17,164,4,0,
10014,17,168,4,0,
10014,17,181,1,10,3
10014,17,182,4,0,
10014,17,201,4,0,
10014,17,207,4,0,
10014,17,213,4,0,
10014,17,214,4,0,
10014,17,216,4,0,
10014,17,218,4,0,
10014,17,237,4,0,
10014,17,240,1,20,1
10014,17,240,4,0,
10014,17,241,1,20,2
10014,17,241,4,0,
10014,17,244,4,0,
10014,17,247,4,0,
10014,17,258,1,20,3
10014,17,258,4,0,
10014,17,263,4,0,
10014,17,311,1,25,
10014,17,412,4,0,
10014,17,496,4,0,
10014,17,503,4,0,
10014,17,542,1,45,
10014,17,590,4,0,
10014,18,29,1,15,
10014,18,33,1,1,
10014,18,52,1,10,2
10014,18,53,4,0,
10014,18,55,1,10,1
10014,18,56,1,35,1
10014,18,58,4,0,
10014,18,59,1,35,3
10014,18,59,4,0,
10014,18,76,4,0,
10014,18,85,4,0,
10014,18,86,4,0,
10014,18,87,4,0,
10014,18,92,4,0,
10014,18,104,4,0,
10014,18,126,1,35,2
10014,18,126,4,0,
10014,18,156,4,0,
10014,18,164,4,0,
10014,18,168,4,0,
10014,18,181,1,10,3
10014,18,182,4,0,
10014,18,201,4,0,
10014,18,207,4,0,
10014,18,213,4,0,
10014,18,214,4,0,
10014,18,216,4,0,
10014,18,218,4,0,
10014,18,237,4,0,
10014,18,240,1,20,1
10014,18,240,4,0,
10014,18,241,1,20,2
10014,18,241,4,0,
10014,18,244,4,0,
10014,18,247,4,0,
10014,18,258,1,20,3
10014,18,258,4,0,
10014,18,263,4,0,
10014,18,311,1,25,
10014,18,412,4,0,
10014,18,496,4,0,
10014,18,503,4,0,
10014,18,526,4,0,
10014,18,542,1,45,
10014,18,590,4,0,
10015,5,33,1,1,
10015,5,52,1,10,2
10015,5,53,4,0,
10015,5,55,1,10,1
10015,5,58,4,0,
10015,5,59,4,0,
10015,5,76,4,0,
10015,5,85,4,0,
10015,5,87,4,0,
10015,5,92,4,0,
10015,5,104,4,0,
10015,5,126,4,0,
10015,5,148,4,0,
10015,5,156,4,0,
10015,5,168,4,0,
10015,5,181,1,10,3
10015,5,182,4,0,
10015,5,201,4,0,
10015,5,213,4,0,
10015,5,216,4,0,
10015,5,218,4,0,
10015,5,237,4,0,
10015,5,240,1,20,
10015,5,240,4,0,
10015,5,241,1,20,4
10015,5,241,4,0,
10015,5,244,2,0,
10015,5,247,4,0,
10015,5,248,2,0,
10015,5,258,1,20,5
10015,5,258,4,0,
10015,5,263,4,0,
10015,5,290,4,0,
10015,5,311,1,30,
10015,5,351,4,0,
10015,5,352,4,0,
10015,6,33,1,1,
10015,6,34,3,0,
10015,6,38,3,0,
10015,6,52,1,10,2
10015,6,53,4,0,
10015,6,55,1,10,1
10015,6,58,4,0,
10015,6,59,4,0,
10015,6,76,4,0,
10015,6,85,4,0,
10015,6,86,3,0,
10015,6,87,4,0,
10015,6,92,4,0,
10015,6,102,3,0,
10015,6,104,4,0,
10015,6,111,3,0,
10015,6,126,4,0,
10015,6,129,3,0,
10015,6,148,4,0,
10015,6,156,4,0,
10015,6,164,3,0,
10015,6,168,4,0,
10015,6,173,3,0,
10015,6,181,1,10,3
10015,6,182,4,0,
10015,6,196,3,0,
10015,6,201,4,0,
10015,6,203,3,0,
10015,6,207,3,0,
10015,6,213,4,0,
10015,6,214,3,0,
10015,6,216,4,0,
10015,6,218,4,0,
10015,6,237,4,0,
10015,6,240,1,20,
10015,6,240,4,0,
10015,6,241,1,20,4
10015,6,241,4,0,
10015,6,244,2,0,
10015,6,244,3,0,
10015,6,247,4,0,
10015,6,248,2,0,
10015,6,258,1,20,5
10015,6,258,4,0,
10015,6,263,4,0,
10015,6,290,4,0,
10015,6,311,1,30,
10015,6,351,4,0,
10015,6,352,4,0,
10015,7,33,1,1,
10015,7,34,3,0,
10015,7,38,3,0,
10015,7,52,1,10,2
10015,7,53,4,0,
10015,7,55,1,10,1
10015,7,58,4,0,
10015,7,59,4,0,
10015,7,76,4,0,
10015,7,85,4,0,
10015,7,86,3,0,
10015,7,87,4,0,
10015,7,92,4,0,
10015,7,102,3,0,
10015,7,104,4,0,
10015,7,126,4,0,
10015,7,148,4,0,
10015,7,156,4,0,
10015,7,164,3,0,
10015,7,168,4,0,
10015,7,181,1,10,3
10015,7,182,4,0,
10015,7,201,4,0,
10015,7,213,4,0,
10015,7,216,4,0,
10015,7,218,4,0,
10015,7,237,4,0,
10015,7,240,1,20,
10015,7,240,4,0,
10015,7,241,1,20,4
10015,7,241,4,0,
10015,7,244,2,0,
10015,7,247,4,0,
10015,7,248,2,0,
10015,7,258,1,20,5
10015,7,258,4,0,
10015,7,263,4,0,
10015,7,290,4,0,
10015,7,311,1,30,
10015,7,351,4,0,
10015,7,352,4,0,
10015,8,33,1,1,
10015,8,50,2,0,
10015,8,52,1,10,2
10015,8,53,4,0,
10015,8,55,1,10,1
10015,8,58,4,0,
10015,8,59,4,0,
10015,8,76,4,0,
10015,8,85,4,0,
10015,8,86,4,0,
10015,8,87,4,0,
10015,8,92,4,0,
10015,8,104,4,0,
10015,8,126,4,0,
10015,8,133,2,0,
10015,8,148,4,0,
10015,8,156,4,0,
10015,8,164,4,0,
10015,8,168,4,0,
10015,8,181,1,10,3
10015,8,182,4,0,
10015,8,201,4,0,
10015,8,203,4,0,
10015,8,207,4,0,
10015,8,213,4,0,
10015,8,214,4,0,
10015,8,216,4,0,
10015,8,218,4,0,
10015,8,237,4,0,
10015,8,240,1,20,1
10015,8,240,4,0,
10015,8,241,1,20,2
10015,8,241,4,0,
10015,8,244,2,0,
10015,8,244,4,0,
10015,8,247,4,0,
10015,8,248,2,0,
10015,8,258,1,20,3
10015,8,258,4,0,
10015,8,263,4,0,
10015,8,290,4,0,
10015,8,311,1,30,
10015,8,351,4,0,
10015,8,352,4,0,
10015,8,363,4,0,
10015,8,381,2,0,
10015,8,412,4,0,
10015,8,419,4,0,
10015,8,445,4,0,
10015,8,466,2,0,
10015,9,33,1,1,
10015,9,50,2,0,
10015,9,52,1,10,2
10015,9,53,4,0,
10015,9,55,1,10,1
10015,9,58,4,0,
10015,9,59,4,0,
10015,9,76,4,0,
10015,9,85,4,0,
10015,9,86,4,0,
10015,9,87,4,0,
10015,9,92,4,0,
10015,9,104,4,0,
10015,9,126,4,0,
10015,9,129,3,0,
10015,9,133,2,0,
10015,9,148,4,0,
10015,9,156,4,0,
10015,9,164,4,0,
10015,9,168,4,0,
10015,9,173,3,0,
10015,9,181,1,10,3
10015,9,182,4,0,
10015,9,196,3,0,
10015,9,201,4,0,
10015,9,203,4,0,
10015,9,207,4,0,
10015,9,213,4,0,
10015,9,214,4,0,
10015,9,216,4,0,
10015,9,218,4,0,
10015,9,237,4,0,
10015,9,240,1,20,1
10015,9,240,4,0,
10015,9,241,1,20,2
10015,9,241,4,0,
10015,9,244,2,0,
10015,9,244,4,0,
10015,9,247,4,0,
10015,9,248,2,0,
10015,9,258,1,20,3
10015,9,258,4,0,
10015,9,263,4,0,
10015,9,290,4,0,
10015,9,311,1,30,
10015,9,351,4,0,
10015,9,352,4,0,
10015,9,363,4,0,
10015,9,381,2,0,
10015,9,387,3,0,
10015,9,412,4,0,
10015,9,419,4,0,
10015,9,445,4,0,
10015,9,466,2,0,
10015,9,466,3,0,
10015,10,33,1,1,
10015,10,50,2,0,
10015,10,52,1,10,2
10015,10,53,4,0,
10015,10,55,1,10,1
10015,10,58,4,0,
10015,10,59,4,0,
10015,10,76,4,0,
10015,10,85,4,0,
10015,10,86,4,0,
10015,10,87,4,0,
10015,10,92,4,0,
10015,10,104,4,0,
10015,10,126,4,0,
10015,10,129,3,0,
10015,10,133,2,0,
10015,10,148,4,0,
10015,10,156,4,0,
10015,10,164,4,0,
10015,10,168,4,0,
10015,10,173,3,0,
10015,10,181,1,10,3
10015,10,182,4,0,
10015,10,196,3,0,
10015,10,201,4,0,
10015,10,203,4,0,
10015,10,207,4,0,
10015,10,213,4,0,
10015,10,214,4,0,
10015,10,216,4,0,
10015,10,218,4,0,
10015,10,237,4,0,
10015,10,240,1,20,1
10015,10,240,4,0,
10015,10,241,1,20,2
10015,10,241,4,0,
10015,10,244,2,0,
10015,10,244,4,0,
10015,10,247,4,0,
10015,10,248,2,0,
10015,10,258,1,20,3
10015,10,258,4,0,
10015,10,263,4,0,
10015,10,290,4,0,
10015,10,311,1,30,
10015,10,351,4,0,
10015,10,352,4,0,
10015,10,363,4,0,
10015,10,366,3,0,
10015,10,381,2,0,
10015,10,387,3,0,
10015,10,412,4,0,
10015,10,419,4,0,
10015,10,445,4,0,
10015,10,466,2,0,
10015,10,466,3,0,
10015,11,29,1,20,
10015,11,33,1,1,
10015,11,50,2,0,
10015,11,52,1,10,2
10015,11,53,4,0,
10015,11,55,1,10,1
10015,11,56,1,50,1
10015,11,58,4,0,
10015,11,59,1,50,3
10015,11,59,4,0,
10015,11,76,4,0,
10015,11,85,4,0,
10015,11,86,4,0,
10015,11,87,4,0,
10015,11,92,4,0,
10015,11,104,4,0,
10015,11,126,1,50,2
10015,11,126,4,0,
10015,11,133,2,0,
10015,11,148,4,0,
10015,11,156,4,0,
10015,11,164,4,0,
10015,11,168,4,0,
10015,11,181,1,10,3
10015,11,182,4,0,
10015,11,201,4,0,
10015,11,207,4,0,
10015,11,213,4,0,
10015,11,216,4,0,
10015,11,218,4,0,
10015,11,237,4,0,
10015,11,240,1,30,1
10015,11,240,4,0,
10015,11,241,1,30,2
10015,11,241,4,0,
10015,11,244,4,0,
10015,11,247,4,0,
10015,11,248,2,0,
10015,11,258,1,30,3
10015,11,258,4,0,
10015,11,263,4,0,
10015,11,311,1,40,
10015,11,381,2,0,
10015,11,412,4,0,
10015,11,466,2,0,
10015,11,496,4,0,
10015,11,499,2,0,
10015,11,503,4,0,
10015,11,506,2,0,
10015,11,510,4,0,
10015,11,514,4,0,
10015,11,526,4,0,
10015,12,33,1,1,
10015,12,52,1,10,2
10015,12,53,4,0,
10015,12,55,1,10,1
10015,12,58,4,0,
10015,12,59,4,0,
10015,12,76,4,0,
10015,12,85,4,0,
10015,12,87,4,0,
10015,12,92,4,0,
10015,12,104,4,0,
10015,12,126,4,0,
10015,12,148,4,0,
10015,12,156,4,0,
10015,12,168,4,0,
10015,12,181,1,10,3
10015,12,182,4,0,
10015,12,201,4,0,
10015,12,213,4,0,
10015,12,216,4,0,
10015,12,218,4,0,
10015,12,237,4,0,
10015,12,240,1,20,
10015,12,240,4,0,
10015,12,241,1,20,4
10015,12,241,4,0,
10015,12,247,4,0,
10015,12,258,1,20,5
10015,12,258,4,0,
10015,12,263,4,0,
10015,12,290,4,0,
10015,12,311,1,30,
10015,12,351,4,0,
10015,12,352,4,0,
10015,13,33,1,1,
10015,13,34,3,0,
10015,13,38,3,0,
10015,13,52,1,10,2
10015,13,53,4,0,
10015,13,55,1,10,1
10015,13,58,4,0,
10015,13,59,4,0,
10015,13,76,4,0,
10015,13,85,4,0,
10015,13,86,3,0,
10015,13,87,4,0,
10015,13,92,4,0,
10015,13,102,3,0,
10015,13,104,4,0,
10015,13,126,4,0,
10015,13,148,4,0,
10015,13,156,4,0,
10015,13,164,3,0,
10015,13,168,4,0,
10015,13,181,1,10,3
10015,13,182,4,0,
10015,13,196,3,0,
10015,13,201,4,0,
10015,13,207,3,0,
10015,13,213,4,0,
10015,13,216,4,0,
10015,13,218,4,0,
10015,13,237,4,0,
10015,13,240,1,20,
10015,13,240,4,0,
10015,13,241,1,20,4
10015,13,241,4,0,
10015,13,247,4,0,
10015,13,258,1,20,5
10015,13,258,4,0,
10015,13,263,4,0,
10015,13,290,4,0,
10015,13,311,1,30,
10015,13,351,4,0,
10015,13,352,4,0,
10015,14,29,1,20,
10015,14,33,1,1,
10015,14,50,2,0,
10015,14,52,1,10,2
10015,14,53,4,0,
10015,14,55,1,10,1
10015,14,56,1,50,1
10015,14,58,4,0,
10015,14,59,1,50,3
10015,14,59,4,0,
10015,14,76,4,0,
10015,14,85,4,0,
10015,14,86,4,0,
10015,14,87,4,0,
10015,14,92,4,0,
10015,14,104,4,0,
10015,14,126,1,50,2
10015,14,126,4,0,
10015,14,133,2,0,
10015,14,148,4,0,
10015,14,156,4,0,
10015,14,164,4,0,
10015,14,168,4,0,
10015,14,173,3,0,
10015,14,181,1,10,3
10015,14,182,4,0,
10015,14,196,3,0,
10015,14,201,4,0,
10015,14,207,4,0,
10015,14,213,4,0,
10015,14,214,3,0,
10015,14,216,4,0,
10015,14,218,4,0,
10015,14,237,4,0,
10015,14,240,1,30,1
10015,14,240,4,0,
10015,14,241,1,30,2
10015,14,241,4,0,
10015,14,244,4,0,
10015,14,247,4,0,
10015,14,248,2,0,
10015,14,258,1,30,3
10015,14,258,4,0,
10015,14,263,4,0,
10015,14,311,1,40,
10015,14,366,3,0,
10015,14,381,2,0,
10015,14,387,3,0,
10015,14,412,4,0,
10015,14,466,2,0,
10015,14,496,4,0,
10015,14,499,2,0,
10015,14,503,4,0,
10015,14,506,2,0,
10015,14,510,4,0,
10015,14,514,4,0,
10015,14,526,4,0,
10015,15,29,1,15,
10015,15,33,1,1,
10015,15,50,2,0,
10015,15,52,1,10,2
10015,15,53,4,0,
10015,15,55,1,10,1
10015,15,56,1,40,1
10015,15,58,4,0,
10015,15,59,1,40,3
10015,15,59,4,0,
10015,15,76,4,0,
10015,15,85,4,0,
10015,15,86,4,0,
10015,15,87,4,0,
10015,15,92,4,0,
10015,15,104,4,0,
10015,15,126,1,40,2
10015,15,126,4,0,
10015,15,133,2,0,
10015,15,148,4,0,
10015,15,156,4,0,
10015,15,164,4,0,
10015,15,168,4,0,
10015,15,181,1,10,3
10015,15,182,4,0,
10015,15,201,4,0,
10015,15,207,4,0,
10015,15,213,4,0,
10015,15,214,4,0,
10015,15,216,4,0,
10015,15,218,4,0,
10015,15,237,4,0,
10015,15,240,1,20,1
10015,15,240,4,0,
10015,15,241,1,20,2
10015,15,241,4,0,
10015,15,244,4,0,
10015,15,247,4,0,
10015,15,248,2,0,
10015,15,258,1,20,3
10015,15,258,4,0,
10015,15,263,4,0,
10015,15,311,1,30,
10015,15,322,2,0,
10015,15,381,2,0,
10015,15,385,2,0,
10015,15,412,4,0,
10015,15,466,2,0,
10015,15,496,4,0,
10015,15,499,2,0,
10015,15,503,4,0,
10015,15,506,2,0,
10015,15,510,4,0,
10015,15,513,2,0,
10015,15,514,4,0,
10015,15,590,4,0,
10015,16,29,1,15,1
10015,16,33,1,1,1
10015,16,52,1,10,2
10015,16,53,4,0,
10015,16,55,1,10,1
10015,16,56,1,35,1
10015,16,58,4,0,
10015,16,59,1,35,3
10015,16,59,4,0,
10015,16,76,4,0,
10015,16,85,4,0,
10015,16,86,4,0,
10015,16,87,4,0,
10015,16,92,4,0,
10015,16,104,4,0,
10015,16,126,1,35,2
10015,16,126,4,0,
10015,16,148,4,0,
10015,16,156,4,0,
10015,16,164,4,0,
10015,16,168,4,0,
10015,16,173,3,0,
10015,16,181,1,10,3
10015,16,182,4,0,
10015,16,196,3,0,
10015,16,201,4,0,
10015,16,207,4,0,
10015,16,213,4,0,
10015,16,214,4,0,
10015,16,216,4,0,
10015,16,218,4,0,
10015,16,237,4,0,
10015,16,240,1,20,1
10015,16,240,4,0,
10015,16,241,1,20,2
10015,16,241,4,0,
10015,16,244,4,0,
10015,16,247,4,0,
10015,16,258,1,20,3
10015,16,258,4,0,
10015,16,263,4,0,
10015,16,290,4,0,
10015,16,311,1,25,1
10015,16,351,3,0,
10015,16,352,3,0,
10015,16,366,3,0,
10015,16,387,3,0,
10015,16,412,4,0,
10015,16,496,4,0,
10015,16,503,4,0,
10015,16,510,4,0,
10015,16,514,4,0,
10015,16,542,1,45,1
10015,16,590,4,0,
10015,17,29,1,15,
10015,17,33,1,1,
10015,17,52,1,10,2
10015,17,53,4,0,
10015,17,55,1,10,1
10015,17,56,1,35,1
10015,17,58,4,0,
10015,17,59,1,35,3
10015,17,59,4,0,
10015,17,76,4,0,
10015,17,85,4,0,
10015,17,86,4,0,
10015,17,87,4,0,
10015,17,92,4,0,
10015,17,104,4,0,
10015,17,126,1,35,2
10015,17,126,4,0,
10015,17,156,4,0,
10015,17,164,4,0,
10015,17,168,4,0,
10015,17,181,1,10,3
10015,17,182,4,0,
10015,17,201,4,0,
10015,17,207,4,0,
10015,17,213,4,0,
10015,17,214,4,0,
10015,17,216,4,0,
10015,17,218,4,0,
10015,17,237,4,0,
10015,17,240,1,20,1
10015,17,240,4,0,
10015,17,241,1,20,2
10015,17,241,4,0,
10015,17,244,4,0,
10015,17,247,4,0,
10015,17,258,1,20,3
10015,17,258,4,0,
10015,17,263,4,0,
10015,17,311,1,25,
10015,17,412,4,0,
10015,17,496,4,0,
10015,17,503,4,0,
10015,17,542,1,45,
10015,17,590,4,0,
10015,18,29,1,15,
10015,18,33,1,1,
10015,18,52,1,10,2
10015,18,53,4,0,
10015,18,55,1,10,1
10015,18,56,1,35,1
10015,18,58,4,0,
10015,18,59,1,35,3
10015,18,59,4,0,
10015,18,76,4,0,
10015,18,85,4,0,
10015,18,86,4,0,
10015,18,87,4,0,
10015,18,92,4,0,
10015,18,104,4,0,
10015,18,126,1,35,2
10015,18,126,4,0,
10015,18,156,4,0,
10015,18,164,4,0,
10015,18,168,4,0,
10015,18,181,1,10,3
10015,18,182,4,0,
10015,18,201,4,0,
10015,18,207,4,0,
10015,18,213,4,0,
10015,18,214,4,0,
10015,18,216,4,0,
10015,18,218,4,0,
10015,18,237,4,0,
10015,18,240,1,20,1
10015,18,240,4,0,
10015,18,241,1,20,2
10015,18,241,4,0,
10015,18,244,4,0,
10015,18,247,4,0,
10015,18,258,1,20,3
10015,18,258,4,0,
10015,18,263,4,0,
10015,18,311,1,25,
10015,18,412,4,0,
10015,18,496,4,0,
10015,18,503,4,0,
10015,18,526,4,0,
10015,18,542,1,45,
10015,18,590,4,0,
10016,11,15,4,0,
10016,11,29,1,7,
10016,11,33,1,1,1
10016,11,36,1,20,
10016,11,37,1,56,
10016,11,38,1,36,
10016,11,44,1,10,
10016,11,55,1,1,2
10016,11,57,4,0,
10016,11,58,4,0,
10016,11,61,2,0,
10016,11,92,4,0,
10016,11,97,2,0,
10016,11,99,2,0,
10016,11,104,4,0,
10016,11,127,4,0,
10016,11,129,2,0,
10016,11,156,4,0,
10016,11,164,4,0,
10016,11,175,1,46,
10016,11,182,4,0,
10016,11,184,1,41,
10016,11,207,4,0,
10016,11,213,4,0,
10016,11,216,4,0,
10016,11,218,4,0,
10016,11,237,4,0,
10016,11,240,4,0,
10016,11,242,1,24,
10016,11,250,2,0,
10016,11,253,1,4,
10016,11,258,4,0,
10016,11,263,4,0,
10016,11,269,4,0,
10016,11,279,2,0,
10016,11,291,4,0,
10016,11,330,2,0,
10016,11,341,2,0,
10016,11,362,2,0,
10016,11,401,1,28,
10016,11,453,1,13,
10016,11,487,1,32,
10016,11,496,4,0,
10016,11,498,1,16,
10016,11,503,4,0,
10016,11,515,1,51,
10016,14,15,4,0,
10016,14,29,1,7,
10016,14,33,1,1,1
10016,14,36,1,20,
10016,14,37,1,56,
10016,14,38,1,36,
10016,14,44,1,10,
10016,14,55,1,1,2
10016,14,57,4,0,
10016,14,58,4,0,
10016,14,61,2,0,
10016,14,92,4,0,
10016,14,97,2,0,
10016,14,99,2,0,
10016,14,104,4,0,
10016,14,127,4,0,
10016,14,129,2,0,
10016,14,156,4,0,
10016,14,164,4,0,
10016,14,173,3,0,
10016,14,175,1,46,
10016,14,182,4,0,
10016,14,184,1,41,
10016,14,196,3,0,
10016,14,207,4,0,
10016,14,213,4,0,
10016,14,214,3,0,
10016,14,216,4,0,
10016,14,218,4,0,
10016,14,237,4,0,
10016,14,240,4,0,
10016,14,242,1,24,
10016,14,250,2,0,
10016,14,253,1,4,
10016,14,253,3,0,
10016,14,258,4,0,
10016,14,263,4,0,
10016,14,269,4,0,
10016,14,276,3,0,
10016,14,279,2,0,
10016,14,283,3,0,
10016,14,291,4,0,
10016,14,330,2,0,
10016,14,340,3,0,
10016,14,341,2,0,
10016,14,362,2,0,
10016,14,401,1,28,
10016,14,401,3,0,
10016,14,428,3,0,
10016,14,453,1,13,
10016,14,487,1,32,
10016,14,496,4,0,
10016,14,498,1,16,
10016,14,503,4,0,
10016,14,515,1,51,
10016,15,15,4,0,
10016,15,29,1,7,
10016,15,33,1,1,4
10016,15,36,1,20,
10016,15,37,1,1,1
10016,15,37,1,56,
10016,15,38,1,36,
10016,15,39,1,1,3
10016,15,44,1,10,
10016,15,55,1,1,5
10016,15,57,4,0,
10016,15,58,4,0,
10016,15,61,2,0,
10016,15,92,4,0,
10016,15,97,2,0,
10016,15,99,2,0,
10016,15,104,4,0,
10016,15,127,4,0,
10016,15,129,2,0,
10016,15,156,4,0,
10016,15,164,4,0,
10016,15,175,1,1,2
10016,15,175,1,46,
10016,15,182,4,0,
10016,15,184,1,41,
10016,15,207,4,0,
10016,15,213,4,0,
10016,15,214,4,0,
10016,15,216,4,0,
10016,15,218,4,0,
10016,15,237,4,0,
10016,15,240,4,0,
10016,15,242,1,24,
10016,15,250,2,0,
10016,15,253,1,4,
10016,15,258,4,0,
10016,15,263,4,0,
10016,15,269,4,0,
10016,15,279,2,0,
10016,15,330,2,0,
10016,15,341,2,0,
10016,15,362,2,0,
10016,15,401,1,28,
10016,15,453,1,13,
10016,15,487,1,32,
10016,15,496,4,0,
10016,15,498,1,16,
10016,15,503,4,0,
10016,15,515,1,50,
10016,15,590,4,0,
10016,16,15,4,0,
10016,16,29,1,7,1
10016,16,33,1,1,4
10016,16,36,1,20,1
10016,16,37,1,1,1
10016,16,37,1,56,1
10016,16,38,1,36,1
10016,16,39,1,1,3
10016,16,44,1,10,1
10016,16,55,1,1,5
10016,16,57,4,0,
10016,16,58,4,0,
10016,16,92,4,0,
10016,16,104,4,0,
10016,16,127,4,0,
10016,16,156,4,0,
10016,16,164,4,0,
10016,16,173,3,0,
10016,16,175,1,1,2
10016,16,175,1,46,1
10016,16,182,4,0,
10016,16,184,1,41,1
10016,16,196,3,0,
10016,16,207,4,0,
10016,16,213,4,0,
10016,16,214,4,0,
10016,16,216,4,0,
10016,16,218,4,0,
10016,16,237,4,0,
10016,16,240,4,0,
10016,16,242,1,24,1
10016,16,253,1,4,1
10016,16,253,3,0,
10016,16,258,4,0,
10016,16,263,4,0,
10016,16,269,4,0,
10016,16,276,3,0,
10016,16,283,3,0,
10016,16,290,4,0,
10016,16,291,4,0,
10016,16,340,3,0,
10016,16,401,1,28,1
10016,16,401,3,0,
10016,16,428,3,0,
10016,16,453,1,13,1
10016,16,487,1,32,1
10016,16,496,4,0,
10016,16,498,1,16,1
10016,16,503,4,0,
10016,16,515,1,50,1
10016,16,590,4,0,
10016,17,29,1,5,
10016,17,33,1,1,2
10016,17,36,1,14,
10016,17,37,1,42,
10016,17,38,1,26,
10016,17,39,1,1,1
10016,17,44,1,7,
10016,17,55,1,1,3
10016,17,57,4,0,
10016,17,58,4,0,
10016,17,92,4,0,
10016,17,104,4,0,
10016,17,127,4,0,
10016,17,156,4,0,
10016,17,164,4,0,
10016,17,175,1,34,
10016,17,182,4,0,
10016,17,184,1,30,
10016,17,207,4,0,
10016,17,213,4,0,
10016,17,214,4,0,
10016,17,216,4,0,
10016,17,218,4,0,
10016,17,237,4,0,
10016,17,240,4,0,
10016,17,242,1,17,
10016,17,253,1,3,
10016,17,258,4,0,
10016,17,263,4,0,
10016,17,269,4,0,
10016,17,401,1,20,
10016,17,453,1,9,
10016,17,457,1,46,
10016,17,487,1,23,
10016,17,496,4,0,
10016,17,498,1,11,
10016,17,503,4,0,
10016,17,515,1,38,
10016,17,590,4,0,
10016,18,29,1,5,
10016,18,33,1,1,2
10016,18,36,1,14,
10016,18,37,1,42,
10016,18,38,1,26,
10016,18,39,1,1,1
10016,18,44,1,7,
10016,18,55,1,1,3
10016,18,57,4,0,
10016,18,58,4,0,
10016,18,92,4,0,
10016,18,104,4,0,
10016,18,127,4,0,
10016,18,156,4,0,
10016,18,164,4,0,
10016,18,175,1,34,
10016,18,182,4,0,
10016,18,184,1,30,
10016,18,207,4,0,
10016,18,213,4,0,
10016,18,214,4,0,
10016,18,216,4,0,
10016,18,218,4,0,
10016,18,237,4,0,
10016,18,240,4,0,
10016,18,242,1,17,
10016,18,253,1,3,
10016,18,258,4,0,
10016,18,263,4,0,
10016,18,269,4,0,
10016,18,401,1,20,
10016,18,453,1,9,
10016,18,457,1,46,
10016,18,487,1,23,
10016,18,496,4,0,
10016,18,498,1,11,
10016,18,503,4,0,
10016,18,515,1,38,
10016,18,590,4,0,
10017,11,7,1,22,
10017,11,29,1,14,
10017,11,33,1,1,1
10017,11,37,1,27,
10017,11,46,4,0,
10017,11,53,4,0,
10017,11,63,4,0,
10017,11,70,4,0,
10017,11,76,4,0,
10017,11,89,4,0,
10017,11,91,4,0,
10017,11,92,4,0,
10017,11,94,4,0,
10017,11,99,1,1,4
10017,11,99,1,9,
10017,11,104,4,0,
10017,11,126,4,0,
10017,11,156,4,0,
10017,11,157,4,0,
10017,11,164,4,0,
10017,11,168,4,0,
10017,11,182,4,0,
10017,11,187,1,30,
10017,11,205,1,1,2
10017,11,205,1,3,
10017,11,207,1,17,
10017,11,207,4,0,
10017,11,213,4,0,
10017,11,216,4,0,
10017,11,218,4,0,
10017,11,237,4,0,
10017,11,241,4,0,
10017,11,249,4,0,
10017,11,259,4,0,
10017,11,261,4,0,
10017,11,263,1,19,
10017,11,263,4,0,
10017,11,269,1,39,
10017,11,269,4,0,
10017,11,276,1,47,
10017,11,280,4,0,
10017,11,315,1,54,
10017,11,315,4,0,
10017,11,317,4,0,
10017,11,339,4,0,
10017,11,359,1,35,
10017,11,360,4,0,
10017,11,369,4,0,
10017,11,371,4,0,
10017,11,374,4,0,
10017,11,394,1,33,
10017,11,411,4,0,
10017,11,416,4,0,
10017,11,424,1,11,
10017,11,444,4,0,
10017,11,447,4,0,
10017,11,479,4,0,
10017,11,488,4,0,
10017,11,496,4,0,
10017,11,510,1,1,3
10017,11,510,1,6,
10017,11,510,4,0,
10017,11,523,4,0,
10017,11,526,1,25,
10017,11,526,4,0,
10017,14,7,1,22,
10017,14,7,3,0,
10017,14,29,1,14,
10017,14,33,1,1,1
10017,14,37,1,27,
10017,14,46,4,0,
10017,14,53,4,0,
10017,14,63,4,0,
10017,14,70,4,0,
10017,14,76,4,0,
10017,14,89,4,0,
10017,14,91,4,0,
10017,14,92,4,0,
10017,14,94,4,0,
10017,14,99,1,1,4
10017,14,99,1,9,
10017,14,104,4,0,
10017,14,126,4,0,
10017,14,156,4,0,
10017,14,157,4,0,
10017,14,164,4,0,
10017,14,168,4,0,
10017,14,173,3,0,
10017,14,182,4,0,
10017,14,187,1,30,
10017,14,205,1,1,2
10017,14,205,1,3,
10017,14,207,1,17,
10017,14,207,4,0,
10017,14,213,4,0,
10017,14,214,3,0,
10017,14,216,4,0,
10017,14,218,4,0,
10017,14,237,4,0,
10017,14,241,4,0,
10017,14,249,4,0,
10017,14,253,3,0,
10017,14,257,3,0,
10017,14,259,4,0,
10017,14,261,4,0,
10017,14,263,1,19,
10017,14,263,4,0,
10017,14,269,1,39,
10017,14,269,4,0,
10017,14,276,1,47,
10017,14,276,3,0,
10017,14,280,4,0,
10017,14,283,3,0,
10017,14,315,1,54,
10017,14,315,4,0,
10017,14,317,4,0,
10017,14,339,4,0,
10017,14,359,1,35,
10017,14,360,4,0,
10017,14,369,4,0,
10017,14,371,4,0,
10017,14,374,4,0,
10017,14,394,1,33,
10017,14,411,4,0,
10017,14,416,4,0,
10017,14,424,1,11,
10017,14,428,3,0,
10017,14,444,4,0,
10017,14,447,4,0,
10017,14,479,4,0,
10017,14,488,4,0,
10017,14,496,4,0,
10017,14,510,1,1,3
10017,14,510,1,6,
10017,14,510,4,0,
10017,14,523,4,0,
10017,14,526,1,25,
10017,14,526,4,0,
10017,15,7,1,22,
10017,15,29,1,14,
10017,15,33,1,1,1
10017,15,37,1,27,
10017,15,46,4,0,
10017,15,53,4,0,
10017,15,63,4,0,
10017,15,70,4,0,
10017,15,76,4,0,
10017,15,89,4,0,
10017,15,91,4,0,
10017,15,92,4,0,
10017,15,94,4,0,
10017,15,99,1,1,4
10017,15,99,1,9,
10017,15,104,4,0,
10017,15,126,4,0,
10017,15,156,4,0,
10017,15,157,4,0,
10017,15,164,4,0,
10017,15,168,4,0,
10017,15,182,4,0,
10017,15,187,1,30,
10017,15,205,1,1,2
10017,15,205,1,3,
10017,15,207,1,17,
10017,15,207,4,0,
10017,15,213,4,0,
10017,15,214,4,0,
10017,15,216,4,0,
10017,15,218,4,0,
10017,15,237,4,0,
10017,15,241,4,0,
10017,15,249,4,0,
10017,15,259,4,0,
10017,15,261,4,0,
10017,15,263,1,19,
10017,15,263,4,0,
10017,15,269,1,39,
10017,15,269,4,0,
10017,15,276,1,47,
10017,15,280,4,0,
10017,15,315,1,54,
10017,15,315,4,0,
10017,15,317,4,0,
10017,15,339,4,0,
10017,15,359,1,35,
10017,15,360,4,0,
10017,15,369,4,0,
10017,15,371,4,0,
10017,15,374,4,0,
10017,15,394,1,33,
10017,15,411,4,0,
10017,15,416,4,0,
10017,15,424,1,11,
10017,15,444,4,0,
10017,15,447,4,0,
10017,15,479,4,0,
10017,15,488,4,0,
10017,15,496,4,0,
10017,15,510,1,1,3
10017,15,510,1,6,
10017,15,510,4,0,
10017,15,523,4,0,
10017,15,526,1,25,
10017,15,590,4,0,
10017,15,612,4,0,
10017,16,7,1,22,1
10017,16,7,3,0,
10017,16,29,1,14,1
10017,16,33,1,1,1
10017,16,37,1,27,1
10017,16,46,4,0,
10017,16,53,4,0,
10017,16,63,4,0,
10017,16,70,4,0,
10017,16,76,4,0,
10017,16,89,4,0,
10017,16,91,4,0,
10017,16,92,4,0,
10017,16,94,4,0,
10017,16,99,1,1,4
10017,16,99,1,9,1
10017,16,104,4,0,
10017,16,126,4,0,
10017,16,156,4,0,
10017,16,157,4,0,
10017,16,164,4,0,
10017,16,168,4,0,
10017,16,173,3,0,
10017,16,182,4,0,
10017,16,187,1,30,1
10017,16,205,1,1,2
10017,16,205,1,3,1
10017,16,207,1,17,1
10017,16,207,4,0,
10017,16,213,4,0,
10017,16,214,4,0,
10017,16,216,4,0,
10017,16,218,4,0,
10017,16,237,4,0,
10017,16,241,4,0,
10017,16,249,4,0,
10017,16,253,3,0,
10017,16,257,3,0,
10017,16,259,4,0,
10017,16,261,4,0,
10017,16,263,1,19,1
10017,16,263,4,0,
10017,16,264,3,0,
10017,16,269,1,39,1
10017,16,269,4,0,
10017,16,276,1,47,1
10017,16,276,3,0,
10017,16,280,4,0,
10017,16,283,3,0,
10017,16,289,3,0,
10017,16,290,4,0,
10017,16,315,1,54,1
10017,16,315,4,0,
10017,16,317,4,0,
10017,16,339,4,0,
10017,16,359,1,35,1
10017,16,360,4,0,
10017,16,369,4,0,
10017,16,371,4,0,
10017,16,374,4,0,
10017,16,394,1,33,1
10017,16,411,4,0,
10017,16,416,4,0,
10017,16,424,1,11,1
10017,16,428,3,0,
10017,16,444,4,0,
10017,16,447,4,0,
10017,16,479,4,0,
10017,16,488,4,0,
10017,16,496,4,0,
10017,16,510,1,1,3
10017,16,510,1,6,1
10017,16,510,4,0,
10017,16,523,4,0,
10017,16,526,1,25,1
10017,16,590,4,0,
10017,16,612,4,0,
10017,17,7,1,22,
10017,17,29,1,14,
10017,17,33,1,1,2
10017,17,37,1,27,
10017,17,46,4,0,
10017,17,53,4,0,
10017,17,63,4,0,
10017,17,76,4,0,
10017,17,89,4,0,
10017,17,92,4,0,
10017,17,94,4,0,
10017,17,99,1,1,5
10017,17,99,1,9,
10017,17,104,4,0,
10017,17,126,4,0,
10017,17,156,4,0,
10017,17,157,4,0,
10017,17,164,4,0,
10017,17,168,4,0,
10017,17,182,4,0,
10017,17,187,1,30,
10017,17,205,1,1,3
10017,17,205,1,3,
10017,17,207,1,17,
10017,17,207,4,0,
10017,17,213,4,0,
10017,17,214,4,0,
10017,17,216,4,0,
10017,17,218,4,0,
10017,17,237,4,0,
10017,17,241,4,0,
10017,17,259,4,0,
10017,17,261,4,0,
10017,17,263,1,19,
10017,17,263,4,0,
10017,17,269,1,39,
10017,17,269,4,0,
10017,17,276,1,47,
10017,17,280,4,0,
10017,17,315,1,54,
10017,17,315,4,0,
10017,17,317,4,0,
10017,17,339,4,0,
10017,17,359,1,0,
10017,17,359,1,1,1
10017,17,360,4,0,
10017,17,369,4,0,
10017,17,371,4,0,
10017,17,374,4,0,
10017,17,394,1,33,
10017,17,411,4,0,
10017,17,416,4,0,
10017,17,424,1,11,
10017,17,444,4,0,
10017,17,447,4,0,
10017,17,479,4,0,
10017,17,488,4,0,
10017,17,496,4,0,
10017,17,510,1,1,4
10017,17,510,1,6,
10017,17,523,4,0,
10017,17,526,1,25,
10017,17,526,4,0,
10017,17,590,4,0,
10017,18,7,1,22,
10017,18,29,1,14,
10017,18,33,1,1,2
10017,18,37,1,27,
10017,18,46,4,0,
10017,18,53,4,0,
10017,18,63,4,0,
10017,18,76,4,0,
10017,18,89,4,0,
10017,18,92,4,0,
10017,18,94,4,0,
10017,18,99,1,1,5
10017,18,99,1,9,
10017,18,104,4,0,
10017,18,126,4,0,
10017,18,156,4,0,
10017,18,157,4,0,
10017,18,164,4,0,
10017,18,168,4,0,
10017,18,182,4,0,
10017,18,187,1,30,
10017,18,205,1,1,3
10017,18,205,1,3,
10017,18,207,1,17,
10017,18,207,4,0,
10017,18,213,4,0,
10017,18,214,4,0,
10017,18,216,4,0,
10017,18,218,4,0,
10017,18,237,4,0,
10017,18,241,4,0,
10017,18,259,4,0,
10017,18,261,4,0,
10017,18,263,1,19,
10017,18,263,4,0,
10017,18,269,1,39,
10017,18,269,4,0,
10017,18,276,1,47,
10017,18,280,4,0,
10017,18,315,1,54,
10017,18,315,4,0,
10017,18,317,4,0,
10017,18,339,4,0,
10017,18,359,1,0,
10017,18,359,1,1,1
10017,18,360,4,0,
10017,18,369,4,0,
10017,18,371,4,0,
10017,18,374,4,0,
10017,18,394,1,33,
10017,18,411,4,0,
10017,18,416,4,0,
10017,18,424,1,11,
10017,18,444,4,0,
10017,18,447,4,0,
10017,18,479,4,0,
10017,18,488,4,0,
10017,18,496,4,0,
10017,18,510,1,1,4
10017,18,510,1,6,
10017,18,523,4,0,
10017,18,526,1,25,
10017,18,526,4,0,
10017,18,590,4,0,
10018,11,47,1,16,
10018,11,60,1,31,
10018,11,63,4,0,
10018,11,70,4,0,
10018,11,85,4,0,
10018,11,86,4,0,
10018,11,87,4,0,
10018,11,92,4,0,
10018,11,93,1,11,
10018,11,94,1,57,
10018,11,94,4,0,
10018,11,98,1,6,
10018,11,104,4,0,
10018,11,113,4,0,
10018,11,138,4,0,
10018,11,148,4,0,
10018,11,156,4,0,
10018,11,164,4,0,
10018,11,182,4,0,
10018,11,195,1,85,
10018,11,207,4,0,
10018,11,216,4,0,
10018,11,218,4,0,
10018,11,219,4,0,
10018,11,237,4,0,
10018,11,240,4,0,
10018,11,241,4,0,
10018,11,244,4,0,
10018,11,247,4,0,
10018,11,249,4,0,
10018,11,263,4,0,
10018,11,272,1,71,
10018,11,280,4,0,
10018,11,298,1,21,
10018,11,304,1,64,
10018,11,347,4,0,
10018,11,358,1,50,
10018,11,369,1,43,
10018,11,369,4,0,
10018,11,370,1,78,
10018,11,371,4,0,
10018,11,373,4,0,
10018,11,374,4,0,
10018,11,411,4,0,
10018,11,412,4,0,
10018,11,416,4,0,
10018,11,421,4,0,
10018,11,433,4,0,
10018,11,444,4,0,
10018,11,447,4,0,
10018,11,451,4,0,
10018,11,468,4,0,
10018,11,473,4,0,
10018,11,477,4,0,
10018,11,490,4,0,
10018,11,496,1,1,
10018,11,496,4,0,
10018,11,497,1,36,
10018,11,497,4,0,
10018,11,512,1,26,
10018,11,512,4,0,
10018,11,514,4,0,
10018,11,526,4,0,
10018,11,547,3,0,
10018,14,7,3,0,
10018,14,8,3,0,
10018,14,9,3,0,
10018,14,47,1,16,
10018,14,60,1,31,
10018,14,63,4,0,
10018,14,67,3,0,
10018,14,70,4,0,
10018,14,85,4,0,
10018,14,86,4,0,
10018,14,87,4,0,
10018,14,92,4,0,
10018,14,93,1,11,
10018,14,94,1,57,
10018,14,94,4,0,
10018,14,98,1,6,
10018,14,104,4,0,
10018,14,113,4,0,
10018,14,138,4,0,
10018,14,148,4,0,
10018,14,156,4,0,
10018,14,164,4,0,
10018,14,173,3,0,
10018,14,182,4,0,
10018,14,195,1,85,
10018,14,207,4,0,
10018,14,214,3,0,
10018,14,215,3,0,
10018,14,216,4,0,
10018,14,218,4,0,
10018,14,219,4,0,
10018,14,237,4,0,
10018,14,240,4,0,
10018,14,241,4,0,
10018,14,244,4,0,
10018,14,247,4,0,
10018,14,249,4,0,
10018,14,253,3,0,
10018,14,263,4,0,
10018,14,270,3,0,
10018,14,271,3,0,
10018,14,272,1,71,
10018,14,272,3,0,
10018,14,277,3,0,
10018,14,278,3,0,
10018,14,280,4,0,
10018,14,282,3,0,
10018,14,285,3,0,
10018,14,289,3,0,
10018,14,298,1,21,
10018,14,304,1,64,
10018,14,304,3,0,
10018,14,324,3,0,
10018,14,343,3,0,
10018,14,347,4,0,
10018,14,356,3,0,
10018,14,358,1,50,
10018,14,369,1,43,
10018,14,369,4,0,
10018,14,370,1,78,
10018,14,371,4,0,
10018,14,373,4,0,
10018,14,374,4,0,
10018,14,387,3,0,
10018,14,409,3,0,
10018,14,411,4,0,
10018,14,412,4,0,
10018,14,416,4,0,
10018,14,421,4,0,
10018,14,428,3,0,
10018,14,433,4,0,
10018,14,444,4,0,
10018,14,447,4,0,
10018,14,451,4,0,
10018,14,468,4,0,
10018,14,472,3,0,
10018,14,473,4,0,
10018,14,477,4,0,
10018,14,478,3,0,
10018,14,490,4,0,
10018,14,496,1,1,
10018,14,496,4,0,
10018,14,497,1,36,
10018,14,497,4,0,
10018,14,512,1,26,
10018,14,512,4,0,
10018,14,514,4,0,
10018,14,526,4,0,
10018,14,530,3,0,
10018,14,547,3,0,
10018,15,47,1,16,
10018,15,60,1,31,
10018,15,63,4,0,
10018,15,70,4,0,
10018,15,85,4,0,
10018,15,86,4,0,
10018,15,87,4,0,
10018,15,92,4,0,
10018,15,93,1,11,
10018,15,94,1,57,
10018,15,94,4,0,
10018,15,98,1,6,
10018,15,104,4,0,
10018,15,113,4,0,
10018,15,138,4,0,
10018,15,148,4,0,
10018,15,156,4,0,
10018,15,164,4,0,
10018,15,182,4,0,
10018,15,195,1,85,
10018,15,207,4,0,
10018,15,214,4,0,
10018,15,216,4,0,
10018,15,218,4,0,
10018,15,219,4,0,
10018,15,237,4,0,
10018,15,240,4,0,
10018,15,241,4,0,
10018,15,244,4,0,
10018,15,247,4,0,
10018,15,249,4,0,
10018,15,263,4,0,
10018,15,272,1,71,
10018,15,280,4,0,
10018,15,298,1,21,
10018,15,304,1,64,
10018,15,347,4,0,
10018,15,358,1,50,
10018,15,369,1,43,
10018,15,369,4,0,
10018,15,370,1,78,
10018,15,371,4,0,
10018,15,373,4,0,
10018,15,374,4,0,
10018,15,411,4,0,
10018,15,412,4,0,
10018,15,416,4,0,
10018,15,421,4,0,
10018,15,433,4,0,
10018,15,444,4,0,
10018,15,447,4,0,
10018,15,451,4,0,
10018,15,468,4,0,
10018,15,473,4,0,
10018,15,490,4,0,
10018,15,496,1,1,
10018,15,496,4,0,
10018,15,497,1,36,
10018,15,497,4,0,
10018,15,512,1,26,
10018,15,512,4,0,
10018,15,514,4,0,
10018,15,547,3,0,
10018,15,590,4,0,
10018,15,605,4,0,
10018,15,612,4,0,
10018,16,7,3,0,
10018,16,8,3,0,
10018,16,9,3,0,
10018,16,47,1,16,1
10018,16,60,1,31,1
10018,16,63,4,0,
10018,16,67,3,0,
10018,16,70,4,0,
10018,16,85,4,0,
10018,16,86,4,0,
10018,16,87,4,0,
10018,16,92,4,0,
10018,16,93,1,11,1
10018,16,94,1,57,1
10018,16,94,4,0,
10018,16,98,1,6,1
10018,16,104,4,0,
10018,16,113,4,0,
10018,16,138,4,0,
10018,16,148,4,0,
10018,16,156,4,0,
10018,16,164,4,0,
10018,16,173,3,0,
10018,16,182,4,0,
10018,16,195,1,85,1
10018,16,207,4,0,
10018,16,214,4,0,
10018,16,215,3,0,
10018,16,216,4,0,
10018,16,218,4,0,
10018,16,219,4,0,
10018,16,237,4,0,
10018,16,240,4,0,
10018,16,241,4,0,
10018,16,244,4,0,
10018,16,247,4,0,
10018,16,249,4,0,
10018,16,253,3,0,
10018,16,263,4,0,
10018,16,264,3,0,
10018,16,270,3,0,
10018,16,271,3,0,
10018,16,272,1,71,1
10018,16,272,3,0,
10018,16,277,3,0,
10018,16,278,3,0,
10018,16,280,4,0,
10018,16,282,3,0,
10018,16,285,3,0,
10018,16,289,3,0,
10018,16,290,4,0,
10018,16,298,1,21,1
10018,16,304,1,64,1
10018,16,304,3,0,
10018,16,324,3,0,
10018,16,343,3,0,
10018,16,347,4,0,
10018,16,351,3,0,
10018,16,356,3,0,
10018,16,358,1,50,1
10018,16,369,1,43,1
10018,16,369,4,0,
10018,16,370,1,78,1
10018,16,371,4,0,
10018,16,373,4,0,
10018,16,374,4,0,
10018,16,387,3,0,
10018,16,409,3,0,
10018,16,411,4,0,
10018,16,412,4,0,
10018,16,416,4,0,
10018,16,421,4,0,
10018,16,428,3,0,
10018,16,433,4,0,
10018,16,444,4,0,
10018,16,447,4,0,
10018,16,451,4,0,
10018,16,468,4,0,
10018,16,472,3,0,
10018,16,473,4,0,
10018,16,478,3,0,
10018,16,490,4,0,
10018,16,496,1,1,1
10018,16,496,4,0,
10018,16,497,1,36,1
10018,16,497,4,0,
10018,16,512,1,26,1
10018,16,512,4,0,
10018,16,514,4,0,
10018,16,530,3,0,
10018,16,590,4,0,
10018,16,605,4,0,
10018,16,612,4,0,
10018,17,47,1,1,4
10018,17,47,1,16,
10018,17,60,1,31,
10018,17,63,4,0,
10018,17,85,4,0,
10018,17,86,4,0,
10018,17,87,4,0,
10018,17,92,4,0,
10018,17,93,1,1,3
10018,17,93,1,11,
10018,17,94,1,57,
10018,17,94,4,0,
10018,17,98,1,1,2
10018,17,98,1,6,
10018,17,104,4,0,
10018,17,113,4,0,
10018,17,138,4,0,
10018,17,156,4,0,
10018,17,164,4,0,
10018,17,182,4,0,
10018,17,195,1,85,
10018,17,207,4,0,
10018,17,214,4,0,
10018,17,216,4,0,
10018,17,218,4,0,
10018,17,219,4,0,
10018,17,237,4,0,
10018,17,240,4,0,
10018,17,241,4,0,
10018,17,244,4,0,
10018,17,247,4,0,
10018,17,263,4,0,
10018,17,272,1,71,
10018,17,280,4,0,
10018,17,298,1,21,
10018,17,304,1,64,
10018,17,347,4,0,
10018,17,358,1,50,
10018,17,369,1,43,
10018,17,369,4,0,
10018,17,370,1,78,
10018,17,371,4,0,
10018,17,373,4,0,
10018,17,374,4,0,
10018,17,411,4,0,
10018,17,412,4,0,
10018,17,416,4,0,
10018,17,421,4,0,
10018,17,433,4,0,
10018,17,444,4,0,
10018,17,447,4,0,
10018,17,451,4,0,
10018,17,473,4,0,
10018,17,490,4,0,
10018,17,496,1,1,1
10018,17,496,4,0,
10018,17,497,1,36,
10018,17,497,4,0,
10018,17,512,1,26,
10018,17,512,4,0,
10018,17,526,4,0,
10018,17,590,4,0,
10018,17,605,4,0,
10018,18,47,1,1,4
10018,18,47,1,16,
10018,18,60,1,31,
10018,18,63,4,0,
10018,18,85,4,0,
10018,18,86,4,0,
10018,18,87,4,0,
10018,18,92,4,0,
10018,18,93,1,1,3
10018,18,93,1,11,
10018,18,94,1,57,
10018,18,94,4,0,
10018,18,98,1,1,2
10018,18,98,1,6,
10018,18,104,4,0,
10018,18,113,4,0,
10018,18,138,4,0,
10018,18,156,4,0,
10018,18,164,4,0,
10018,18,182,4,0,
10018,18,195,1,85,
10018,18,207,4,0,
10018,18,214,4,0,
10018,18,216,4,0,
10018,18,218,4,0,
10018,18,219,4,0,
10018,18,237,4,0,
10018,18,240,4,0,
10018,18,241,4,0,
10018,18,244,4,0,
10018,18,247,4,0,
10018,18,263,4,0,
10018,18,272,1,71,
10018,18,280,4,0,
10018,18,298,1,21,
10018,18,304,1,64,
10018,18,347,4,0,
10018,18,358,1,50,
10018,18,369,1,43,
10018,18,369,4,0,
10018,18,370,1,78,
10018,18,371,4,0,
10018,18,373,4,0,
10018,18,374,4,0,
10018,18,411,4,0,
10018,18,412,4,0,
10018,18,416,4,0,
10018,18,421,4,0,
10018,18,433,4,0,
10018,18,444,4,0,
10018,18,447,4,0,
10018,18,451,4,0,
10018,18,473,4,0,
10018,18,490,4,0,
10018,18,496,1,1,1
10018,18,496,4,0,
10018,18,497,1,36,
10018,18,497,4,0,
10018,18,512,1,26,
10018,18,512,4,0,
10018,18,526,4,0,
10018,18,590,4,0,
10018,18,605,4,0,
10019,14,16,1,1,3
10019,14,19,4,0,
10019,14,37,1,85,
10019,14,44,1,13,
10019,14,63,4,0,
10019,14,70,4,0,
10019,14,92,4,0,
10019,14,94,4,0,
10019,14,97,1,37,
10019,14,104,4,0,
10019,14,156,4,0,
10019,14,164,4,0,
10019,14,168,4,0,
10019,14,173,3,0,
10019,14,182,4,0,
10019,14,188,4,0,
10019,14,196,3,0,
10019,14,207,1,7,
10019,14,207,4,0,
10019,14,213,4,0,
10019,14,214,3,0,
10019,14,216,4,0,
10019,14,218,4,0,
10019,14,231,3,0,
10019,14,237,4,0,
10019,14,240,1,61,
10019,14,240,4,0,
10019,14,242,1,49,
10019,14,249,4,0,
10019,14,253,1,1,1
10019,14,253,3,0,
10019,14,257,3,0,
10019,14,259,4,0,
10019,14,263,4,0,
10019,14,269,4,0,
10019,14,272,3,0,
10019,14,276,3,0,
10019,14,279,1,19,
10019,14,280,4,0,
10019,14,282,3,0,
10019,14,310,1,1,2
10019,14,314,1,25,
10019,14,326,1,31,
10019,14,332,4,0,
10019,14,339,4,0,
10019,14,359,1,79,
10019,14,366,1,55,
10019,14,366,3,0,
10019,14,369,4,0,
10019,14,371,4,0,
10019,14,373,4,0,
10019,14,374,4,0,
10019,14,399,1,73,
10019,14,399,3,0,
10019,14,403,1,43,
10019,14,411,4,0,
10019,14,416,4,0,
10019,14,447,4,0,
10019,14,479,4,0,
10019,14,482,4,0,
10019,14,492,3,0,
10019,14,496,4,0,
10019,14,507,4,0,
10019,14,510,4,0,
10019,14,512,4,0,
10019,14,542,1,67,
10019,15,16,1,1,7
10019,15,19,4,0,
10019,15,37,1,1,1
10019,15,37,1,85,
10019,15,44,1,13,
10019,15,63,4,0,
10019,15,70,4,0,
10019,15,92,4,0,
10019,15,94,4,0,
10019,15,97,1,37,
10019,15,104,4,0,
10019,15,156,4,0,
10019,15,164,4,0,
10019,15,168,4,0,
10019,15,182,4,0,
10019,15,188,4,0,
10019,15,207,1,7,
10019,15,207,4,0,
10019,15,213,4,0,
10019,15,214,4,0,
10019,15,216,4,0,
10019,15,218,4,0,
10019,15,237,4,0,
10019,15,240,1,61,
10019,15,240,4,0,
10019,15,242,1,49,
10019,15,249,4,0,
10019,15,253,1,1,5
10019,15,259,4,0,
10019,15,263,4,0,
10019,15,269,4,0,
10019,15,279,1,19,
10019,15,280,4,0,
10019,15,310,1,1,6
10019,15,314,1,25,
10019,15,326,1,31,
10019,15,332,4,0,
10019,15,339,4,0,
10019,15,359,1,1,2
10019,15,359,1,79,
10019,15,366,1,1,4
10019,15,366,1,55,
10019,15,369,4,0,
10019,15,371,4,0,
10019,15,373,4,0,
10019,15,374,4,0,
10019,15,399,1,73,
10019,15,399,4,0,
10019,15,403,1,43,
10019,15,411,4,0,
10019,15,416,4,0,
10019,15,447,4,0,
10019,15,479,4,0,
10019,15,482,4,0,
10019,15,496,4,0,
10019,15,507,4,0,
10019,15,510,4,0,
10019,15,512,4,0,
10019,15,542,1,1,3
10019,15,542,1,67,
10019,15,590,4,0,
10019,16,16,1,1,7
10019,16,19,4,0,
10019,16,37,1,1,1
10019,16,37,1,85,1
10019,16,44,1,13,1
10019,16,63,4,0,
10019,16,70,4,0,
10019,16,92,4,0,
10019,16,94,4,0,
10019,16,97,1,37,1
10019,16,104,4,0,
10019,16,156,4,0,
10019,16,164,4,0,
10019,16,168,4,0,
10019,16,173,3,0,
10019,16,182,4,0,
10019,16,188,4,0,
10019,16,196,3,0,
10019,16,207,1,7,1
10019,16,207,4,0,
10019,16,213,4,0,
10019,16,214,4,0,
10019,16,216,4,0,
10019,16,218,4,0,
10019,16,231,3,0,
10019,16,237,4,0,
10019,16,240,1,61,1
10019,16,240,4,0,
10019,16,242,1,49,1
10019,16,249,4,0,
10019,16,253,1,1,5
10019,16,253,3,0,
10019,16,257,3,0,
10019,16,259,4,0,
10019,16,263,4,0,
10019,16,269,4,0,
10019,16,272,3,0,
10019,16,276,3,0,
10019,16,279,1,19,1
10019,16,280,4,0,
10019,16,282,3,0,
10019,16,290,4,0,
10019,16,310,1,1,6
10019,16,314,1,25,1
10019,16,326,1,31,1
10019,16,332,4,0,
10019,16,339,4,0,
10019,16,359,1,1,2
10019,16,359,1,79,1
10019,16,366,1,1,4
10019,16,366,1,55,1
10019,16,366,3,0,
10019,16,369,4,0,
10019,16,371,4,0,
10019,16,373,4,0,
10019,16,374,4,0,
10019,16,399,1,73,1
10019,16,399,4,0,
10019,16,403,1,43,1
10019,16,411,4,0,
10019,16,416,4,0,
10019,16,447,4,0,
10019,16,479,4,0,
10019,16,482,4,0,
10019,16,492,3,0,
10019,16,496,4,0,
10019,16,507,4,0,
10019,16,510,4,0,
10019,16,512,4,0,
10019,16,542,1,1,3
10019,16,542,1,67,1
10019,16,590,4,0,
10019,17,16,1,1,6
10019,17,19,4,0,
10019,17,37,1,1,1
10019,17,37,1,79,
10019,17,44,1,7,
10019,17,63,4,0,
10019,17,92,4,0,
10019,17,94,4,0,
10019,17,97,1,31,
10019,17,104,4,0,
10019,17,156,4,0,
10019,17,164,4,0,
10019,17,168,4,0,
10019,17,182,4,0,
10019,17,188,4,0,
10019,17,207,1,1,7
10019,17,207,4,0,
10019,17,213,4,0,
10019,17,214,4,0,
10019,17,216,4,0,
10019,17,218,4,0,
10019,17,237,4,0,
10019,17,240,1,55,
10019,17,240,4,0,
10019,17,242,1,43,
10019,17,253,1,1,4
10019,17,259,4,0,
10019,17,263,4,0,
10019,17,269,4,0,
10019,17,279,1,13,
10019,17,280,4,0,
10019,17,310,1,1,5
10019,17,314,1,19,
10019,17,326,1,25,
10019,17,332,4,0,
10019,17,339,4,0,
10019,17,359,1,1,2
10019,17,359,1,73,
10019,17,366,1,49,
10019,17,369,4,0,
10019,17,371,4,0,
10019,17,373,4,0,
10019,17,374,4,0,
10019,17,399,1,67,
10019,17,399,4,0,
10019,17,403,1,37,
10019,17,411,4,0,
10019,17,416,4,0,
10019,17,447,4,0,
10019,17,479,4,0,
10019,17,482,4,0,
10019,17,496,4,0,
10019,17,507,4,0,
10019,17,512,4,0,
10019,17,542,1,1,3
10019,17,542,1,61,
10019,17,590,4,0,
10019,17,693,4,0,
10019,18,16,1,1,6
10019,18,19,4,0,
10019,18,37,1,1,1
10019,18,37,1,79,
10019,18,44,1,7,
10019,18,63,4,0,
10019,18,92,4,0,
10019,18,94,4,0,
10019,18,97,1,31,
10019,18,104,4,0,
10019,18,156,4,0,
10019,18,164,4,0,
10019,18,168,4,0,
10019,18,182,4,0,
10019,18,188,4,0,
10019,18,207,1,1,7
10019,18,207,4,0,
10019,18,213,4,0,
10019,18,214,4,0,
10019,18,216,4,0,
10019,18,218,4,0,
10019,18,237,4,0,
10019,18,240,1,55,
10019,18,240,4,0,
10019,18,242,1,43,
10019,18,253,1,1,4
10019,18,259,4,0,
10019,18,263,4,0,
10019,18,269,4,0,
10019,18,279,1,13,
10019,18,280,4,0,
10019,18,310,1,1,5
10019,18,314,1,19,
10019,18,326,1,25,
10019,18,332,4,0,
10019,18,339,4,0,
10019,18,359,1,1,2
10019,18,359,1,73,
10019,18,366,1,49,
10019,18,369,4,0,
10019,18,371,4,0,
10019,18,373,4,0,
10019,18,374,4,0,
10019,18,399,1,67,
10019,18,399,4,0,
10019,18,403,1,37,
10019,18,411,4,0,
10019,18,416,4,0,
10019,18,447,4,0,
10019,18,479,4,0,
10019,18,482,4,0,
10019,18,496,4,0,
10019,18,507,4,0,
10019,18,512,4,0,
10019,18,542,1,1,3
10019,18,542,1,61,
10019,18,590,4,0,
10019,18,693,4,0,
10020,14,9,3,0,
10020,14,19,4,0,
10020,14,37,1,85,
10020,14,44,1,13,
10020,14,63,4,0,
10020,14,70,4,0,
10020,14,84,1,1,3
10020,14,85,4,0,
10020,14,86,4,0,
10020,14,87,1,67,
10020,14,87,4,0,
10020,14,92,4,0,
10020,14,94,4,0,
10020,14,97,1,37,
10020,14,104,4,0,
10020,14,156,4,0,
10020,14,164,4,0,
10020,14,168,4,0,
10020,14,173,3,0,
10020,14,182,4,0,
10020,14,188,4,0,
10020,14,207,1,7,
10020,14,207,4,0,
10020,14,213,4,0,
10020,14,214,3,0,
10020,14,216,4,0,
10020,14,218,4,0,
10020,14,231,3,0,
10020,14,237,4,0,
10020,14,240,4,0,
10020,14,242,1,49,
10020,14,249,4,0,
10020,14,253,1,1,1
10020,14,253,3,0,
10020,14,259,4,0,
10020,14,263,4,0,
10020,14,268,1,55,
10020,14,269,4,0,
10020,14,272,3,0,
10020,14,276,3,0,
10020,14,279,1,19,
10020,14,280,4,0,
10020,14,282,3,0,
10020,14,310,1,1,2
10020,14,339,4,0,
10020,14,351,1,25,
10020,14,359,1,79,
10020,14,369,4,0,
10020,14,371,4,0,
10020,14,373,4,0,
10020,14,374,4,0,
10020,14,377,1,31,
10020,14,399,1,73,
10020,14,399,3,0,
10020,14,411,4,0,
10020,14,416,4,0,
10020,14,417,1,61,
10020,14,430,4,0,
10020,14,435,1,43,
10020,14,447,4,0,
10020,14,451,4,0,
10020,14,479,4,0,
10020,14,482,4,0,
10020,14,492,3,0,
10020,14,496,4,0,
10020,14,507,4,0,
10020,14,510,4,0,
10020,14,521,4,0,
10020,14,528,4,0,
10020,15,19,4,0,
10020,15,37,1,1,1
10020,15,37,1,85,
10020,15,44,1,13,
10020,15,63,4,0,
10020,15,70,4,0,
10020,15,84,1,1,7
10020,15,85,4,0,
10020,15,86,4,0,
10020,15,87,1,67,
10020,15,87,4,0,
10020,15,92,4,0,
10020,15,94,4,0,
10020,15,97,1,37,
10020,15,104,4,0,
10020,15,156,4,0,
10020,15,164,4,0,
10020,15,168,4,0,
10020,15,182,4,0,
10020,15,188,4,0,
10020,15,207,1,7,
10020,15,207,4,0,
10020,15,213,4,0,
10020,15,214,4,0,
10020,15,216,4,0,
10020,15,218,4,0,
10020,15,237,4,0,
10020,15,240,4,0,
10020,15,242,1,49,
10020,15,249,4,0,
10020,15,253,1,1,5
10020,15,259,4,0,
10020,15,263,4,0,
10020,15,268,1,1,4
10020,15,268,1,55,
10020,15,269,4,0,
10020,15,279,1,19,
10020,15,280,4,0,
10020,15,310,1,1,6
10020,15,339,4,0,
10020,15,351,1,25,
10020,15,359,1,1,2
10020,15,359,1,79,
10020,15,369,4,0,
10020,15,371,4,0,
10020,15,373,4,0,
10020,15,374,4,0,
10020,15,377,1,31,
10020,15,399,1,73,
10020,15,399,4,0,
10020,15,411,4,0,
10020,15,416,4,0,
10020,15,417,1,1,3
10020,15,417,1,61,
10020,15,430,4,0,
10020,15,435,1,43,
10020,15,447,4,0,
10020,15,451,4,0,
10020,15,479,4,0,
10020,15,482,4,0,
10020,15,496,4,0,
10020,15,507,4,0,
10020,15,510,4,0,
10020,15,521,4,0,
10020,15,528,4,0,
10020,15,590,4,0,
10020,16,9,3,0,
10020,16,19,4,0,
10020,16,37,1,1,1
10020,16,37,1,85,1
10020,16,44,1,13,1
10020,16,63,4,0,
10020,16,70,4,0,
10020,16,84,1,1,7
10020,16,85,4,0,
10020,16,86,4,0,
10020,16,87,1,67,1
10020,16,87,4,0,
10020,16,92,4,0,
10020,16,94,4,0,
10020,16,97,1,37,1
10020,16,104,4,0,
10020,16,156,4,0,
10020,16,164,4,0,
10020,16,168,4,0,
10020,16,173,3,0,
10020,16,182,4,0,
10020,16,188,4,0,
10020,16,207,1,7,1
10020,16,207,4,0,
10020,16,213,4,0,
10020,16,214,4,0,
10020,16,216,4,0,
10020,16,218,4,0,
10020,16,231,3,0,
10020,16,237,4,0,
10020,16,240,4,0,
10020,16,242,1,49,1
10020,16,249,4,0,
10020,16,253,1,1,5
10020,16,253,3,0,
10020,16,259,4,0,
10020,16,263,4,0,
10020,16,268,1,1,4
10020,16,268,1,55,1
10020,16,269,4,0,
10020,16,272,3,0,
10020,16,276,3,0,
10020,16,279,1,19,1
10020,16,280,4,0,
10020,16,282,3,0,
10020,16,290,4,0,
10020,16,310,1,1,6
10020,16,339,4,0,
10020,16,351,1,25,1
10020,16,351,3,0,
10020,16,359,1,1,2
10020,16,359,1,79,1
10020,16,369,4,0,
10020,16,371,4,0,
10020,16,373,4,0,
10020,16,374,4,0,
10020,16,377,1,31,1
10020,16,399,1,73,1
10020,16,399,4,0,
10020,16,411,4,0,
10020,16,416,4,0,
10020,16,417,1,1,3
10020,16,417,1,61,1
10020,16,430,4,0,
10020,16,435,1,43,1
10020,16,447,4,0,
10020,16,451,4,0,
10020,16,479,4,0,
10020,16,482,4,0,
10020,16,492,3,0,
10020,16,496,4,0,
10020,16,507,4,0,
10020,16,510,4,0,
10020,16,521,4,0,
10020,16,527,3,0,
10020,16,528,4,0,
10020,16,590,4,0,
10020,17,19,4,0,
10020,17,37,1,1,1
10020,17,37,1,79,
10020,17,44,1,7,
10020,17,63,4,0,
10020,17,84,1,1,6
10020,17,85,4,0,
10020,17,86,4,0,
10020,17,87,1,61,
10020,17,87,4,0,
10020,17,92,4,0,
10020,17,94,4,0,
10020,17,97,1,31,
10020,17,104,4,0,
10020,17,156,4,0,
10020,17,164,4,0,
10020,17,168,4,0,
10020,17,182,4,0,
10020,17,188,4,0,
10020,17,207,1,1,7
10020,17,207,4,0,
10020,17,213,4,0,
10020,17,214,4,0,
10020,17,216,4,0,
10020,17,218,4,0,
10020,17,237,4,0,
10020,17,240,4,0,
10020,17,242,1,43,
10020,17,253,1,1,4
10020,17,259,4,0,
10020,17,263,4,0,
10020,17,268,1,49,
10020,17,269,4,0,
10020,17,279,1,13,
10020,17,280,4,0,
10020,17,310,1,1,5
10020,17,339,4,0,
10020,17,351,1,19,
10020,17,359,1,1,2
10020,17,359,1,73,
10020,17,369,4,0,
10020,17,371,4,0,
10020,17,373,4,0,
10020,17,374,4,0,
10020,17,377,1,25,
10020,17,399,1,67,
10020,17,399,4,0,
10020,17,411,4,0,
10020,17,416,4,0,
10020,17,417,1,1,3
10020,17,417,1,55,
10020,17,430,4,0,
10020,17,435,1,37,
10020,17,447,4,0,
10020,17,451,4,0,
10020,17,479,4,0,
10020,17,482,4,0,
10020,17,496,4,0,
10020,17,507,4,0,
10020,17,521,4,0,
10020,17,528,4,0,
10020,17,590,4,0,
10020,17,693,4,0,
10020,18,19,4,0,
10020,18,37,1,1,1
10020,18,37,1,79,
10020,18,44,1,7,
10020,18,63,4,0,
10020,18,84,1,1,6
10020,18,85,4,0,
10020,18,86,4,0,
10020,18,87,1,61,
10020,18,87,4,0,
10020,18,92,4,0,
10020,18,94,4,0,
10020,18,97,1,31,
10020,18,104,4,0,
10020,18,156,4,0,
10020,18,164,4,0,
10020,18,168,4,0,
10020,18,182,4,0,
10020,18,188,4,0,
10020,18,207,1,1,7
10020,18,207,4,0,
10020,18,213,4,0,
10020,18,214,4,0,
10020,18,216,4,0,
10020,18,218,4,0,
10020,18,237,4,0,
10020,18,240,4,0,
10020,18,242,1,43,
10020,18,253,1,1,4
10020,18,259,4,0,
10020,18,263,4,0,
10020,18,268,1,49,
10020,18,269,4,0,
10020,18,279,1,13,
10020,18,280,4,0,
10020,18,310,1,1,5
10020,18,339,4,0,
10020,18,351,1,19,
10020,18,359,1,1,2
10020,18,359,1,73,
10020,18,369,4,0,
10020,18,371,4,0,
10020,18,373,4,0,
10020,18,374,4,0,
10020,18,377,1,25,
10020,18,399,1,67,
10020,18,399,4,0,
10020,18,411,4,0,
10020,18,416,4,0,
10020,18,417,1,1,3
10020,18,417,1,55,
10020,18,430,4,0,
10020,18,435,1,37,
10020,18,447,4,0,
10020,18,451,4,0,
10020,18,479,4,0,
10020,18,482,4,0,
10020,18,496,4,0,
10020,18,507,4,0,
10020,18,521,4,0,
10020,18,528,4,0,
10020,18,590,4,0,
10020,18,693,4,0,
10021,14,14,1,37,
10021,14,14,4,0,
10021,14,19,4,0,
10021,14,63,4,0,
10021,14,70,4,0,
10021,14,88,1,25,
10021,14,89,1,55,
10021,14,89,4,0,
10021,14,90,1,67,
10021,14,91,4,0,
10021,14,92,4,0,
10021,14,94,4,0,
10021,14,104,4,0,
10021,14,153,4,0,
10021,14,156,4,0,
10021,14,157,1,49,
10021,14,157,4,0,
10021,14,164,4,0,
10021,14,173,3,0,
10021,14,182,4,0,
10021,14,188,4,0,
10021,14,200,1,85,
10021,14,200,3,0,
10021,14,201,1,61,
10021,14,201,4,0,
10021,14,207,4,0,
10021,14,213,4,0,
10021,14,214,3,0,
10021,14,216,4,0,
10021,14,218,4,0,
10021,14,231,3,0,
10021,14,237,4,0,
10021,14,249,4,0,
10021,14,263,4,0,
10021,14,272,3,0,
10021,14,276,3,0,
10021,14,280,4,0,
10021,14,282,3,0,
10021,14,286,1,7,
10021,14,317,1,1,3
10021,14,317,4,0,
10021,14,326,1,31,
10021,14,335,1,1,1
10021,14,335,3,0,
10021,14,339,4,0,
10021,14,341,1,1,2
10021,14,347,4,0,
10021,14,356,3,0,
10021,14,359,1,79,
10021,14,369,4,0,
10021,14,371,4,0,
10021,14,374,4,0,
10021,14,386,1,13,
10021,14,397,4,0,
10021,14,411,4,0,
10021,14,414,1,43,
10021,14,414,3,0,
10021,14,416,4,0,
10021,14,444,1,73,
10021,14,444,4,0,
10021,14,446,3,0,
10021,14,447,4,0,
10021,14,479,4,0,
10021,14,482,4,0,
10021,14,496,4,0,
10021,14,523,1,19,
10021,14,523,4,0,
10021,15,14,1,37,
10021,15,14,4,0,
10021,15,19,4,0,
10021,15,63,4,0,
10021,15,70,4,0,
10021,15,88,1,25,
10021,15,89,1,55,
10021,15,89,4,0,
10021,15,90,1,1,3
10021,15,90,1,67,
10021,15,91,4,0,
10021,15,92,4,0,
10021,15,94,4,0,
10021,15,104,4,0,
10021,15,153,4,0,
10021,15,156,4,0,
10021,15,157,1,49,
10021,15,157,4,0,
10021,15,164,4,0,
10021,15,182,4,0,
10021,15,188,4,0,
10021,15,200,1,1,1
10021,15,200,1,85,
10021,15,201,1,61,
10021,15,201,4,0,
10021,15,207,4,0,
10021,15,213,4,0,
10021,15,214,4,0,
10021,15,216,4,0,
10021,15,218,4,0,
10021,15,237,4,0,
10021,15,249,4,0,
10021,15,263,4,0,
10021,15,280,4,0,
10021,15,286,1,7,
10021,15,317,1,1,6
10021,15,317,4,0,
10021,15,326,1,31,
10021,15,335,1,1,4
10021,15,339,4,0,
10021,15,341,1,1,5
10021,15,347,4,0,
10021,15,359,1,1,2
10021,15,359,1,79,
10021,15,369,4,0,
10021,15,371,4,0,
10021,15,374,4,0,
10021,15,386,1,13,
10021,15,397,4,0,
10021,15,411,4,0,
10021,15,414,1,43,
10021,15,416,4,0,
10021,15,444,1,73,
10021,15,444,4,0,
10021,15,447,4,0,
10021,15,479,4,0,
10021,15,482,4,0,
10021,15,496,4,0,
10021,15,523,1,19,
10021,15,523,4,0,
10021,15,590,4,0,
10021,16,14,1,37,1
10021,16,14,4,0,
10021,16,19,4,0,
10021,16,63,4,0,
10021,16,70,4,0,
10021,16,88,1,25,1
10021,16,89,1,55,1
10021,16,89,4,0,
10021,16,90,1,1,3
10021,16,90,1,67,1
10021,16,91,4,0,
10021,16,92,4,0,
10021,16,94,4,0,
10021,16,104,4,0,
10021,16,153,4,0,
10021,16,156,4,0,
10021,16,157,1,49,1
10021,16,157,4,0,
10021,16,164,4,0,
10021,16,173,3,0,
10021,16,182,4,0,
10021,16,188,4,0,
10021,16,200,1,1,1
10021,16,200,1,85,1
10021,16,200,3,0,
10021,16,201,1,61,1
10021,16,201,4,0,
10021,16,207,4,0,
10021,16,213,4,0,
10021,16,214,4,0,
10021,16,216,4,0,
10021,16,218,4,0,
10021,16,231,3,0,
10021,16,237,4,0,
10021,16,249,4,0,
10021,16,263,4,0,
10021,16,272,3,0,
10021,16,276,3,0,
10021,16,280,4,0,
10021,16,282,3,0,
10021,16,286,1,7,1
10021,16,290,4,0,
10021,16,317,1,1,6
10021,16,317,4,0,
10021,16,326,1,31,1
10021,16,335,1,1,4
10021,16,335,3,0,
10021,16,339,4,0,
10021,16,341,1,1,5
10021,16,347,4,0,
10021,16,356,3,0,
10021,16,359,1,1,2
10021,16,359,1,79,1
10021,16,369,4,0,
10021,16,371,4,0,
10021,16,374,4,0,
10021,16,386,1,13,1
10021,16,397,4,0,
10021,16,411,4,0,
10021,16,414,1,43,1
10021,16,414,3,0,
10021,16,416,4,0,
10021,16,444,1,73,1
10021,16,444,4,0,
10021,16,446,3,0,
10021,16,447,4,0,
10021,16,479,4,0,
10021,16,482,4,0,
10021,16,496,4,0,
10021,16,523,1,19,1
10021,16,523,4,0,
10021,16,590,4,0,
10021,17,14,1,31,
10021,17,14,4,0,
10021,17,19,4,0,
10021,17,63,4,0,
10021,17,88,1,19,
10021,17,89,1,49,
10021,17,89,4,0,
10021,17,90,1,1,3
10021,17,90,1,61,
10021,17,92,4,0,
10021,17,94,4,0,
10021,17,104,4,0,
10021,17,153,4,0,
10021,17,156,4,0,
10021,17,157,1,43,
10021,17,157,4,0,
10021,17,164,4,0,
10021,17,182,4,0,
10021,17,188,4,0,
10021,17,200,1,1,1
10021,17,200,1,79,
10021,17,201,1,55,
10021,17,201,4,0,
10021,17,207,4,0,
10021,17,213,4,0,
10021,17,214,4,0,
10021,17,216,4,0,
10021,17,218,4,0,
10021,17,237,4,0,
10021,17,263,4,0,
10021,17,280,4,0,
10021,17,286,1,1,7
10021,17,317,1,1,6
10021,17,317,4,0,
10021,17,326,1,25,
10021,17,335,1,1,4
10021,17,339,4,0,
10021,17,341,1,1,5
10021,17,347,4,0,
10021,17,359,1,1,2
10021,17,359,1,73,
10021,17,369,4,0,
10021,17,371,4,0,
10021,17,374,4,0,
10021,17,386,1,7,
10021,17,397,4,0,
10021,17,411,4,0,
10021,17,414,1,37,
10021,17,416,4,0,
10021,17,444,1,67,
10021,17,444,4,0,
10021,17,447,4,0,
10021,17,479,4,0,
10021,17,482,4,0,
10021,17,496,4,0,
10021,17,523,1,13,
10021,17,523,4,0,
10021,17,590,4,0,
10021,17,693,4,0,
10021,18,14,1,31,
10021,18,14,4,0,
10021,18,19,4,0,
10021,18,63,4,0,
10021,18,88,1,19,
10021,18,89,1,49,
10021,18,89,4,0,
10021,18,90,1,1,3
10021,18,90,1,61,
10021,18,92,4,0,
10021,18,94,4,0,
10021,18,104,4,0,
10021,18,153,4,0,
10021,18,156,4,0,
10021,18,157,1,43,
10021,18,157,4,0,
10021,18,164,4,0,
10021,18,182,4,0,
10021,18,188,4,0,
10021,18,200,1,1,1
10021,18,200,1,79,
10021,18,201,1,55,
10021,18,201,4,0,
10021,18,207,4,0,
10021,18,213,4,0,
10021,18,214,4,0,
10021,18,216,4,0,
10021,18,218,4,0,
10021,18,237,4,0,
10021,18,263,4,0,
10021,18,280,4,0,
10021,18,286,1,1,7
10021,18,317,1,1,6
10021,18,317,4,0,
10021,18,326,1,25,
10021,18,335,1,1,4
10021,18,339,4,0,
10021,18,341,1,1,5
10021,18,347,4,0,
10021,18,359,1,1,2
10021,18,359,1,73,
10021,18,369,4,0,
10021,18,371,4,0,
10021,18,374,4,0,
10021,18,386,1,7,
10021,18,397,4,0,
10021,18,411,4,0,
10021,18,414,1,37,
10021,18,416,4,0,
10021,18,444,1,67,
10021,18,444,4,0,
10021,18,447,4,0,
10021,18,479,4,0,
10021,18,482,4,0,
10021,18,496,4,0,
10021,18,523,1,13,
10021,18,523,4,0,
10021,18,590,4,0,
10021,18,693,4,0,
10022,14,15,4,0,
10022,14,19,4,0,
10022,14,58,1,22,
10022,14,58,4,0,
10022,14,59,1,78,
10022,14,59,4,0,
10022,14,63,4,0,
10022,14,70,4,0,
10022,14,82,1,1,2
10022,14,92,4,0,
10022,14,94,4,0,
10022,14,104,4,0,
10022,14,113,4,0,
10022,14,115,4,0,
10022,14,156,4,0,
10022,14,157,4,0,
10022,14,163,1,36,
10022,14,164,4,0,
10022,14,173,3,0,
10022,14,182,4,0,
10022,14,196,1,1,1
10022,14,196,3,0,
10022,14,200,1,85,
10022,14,200,3,0,
10022,14,207,4,0,
10022,14,214,3,0,
10022,14,216,4,0,
10022,14,218,4,0,
10022,14,219,4,0,
10022,14,225,1,29,
10022,14,237,4,0,
10022,14,240,4,0,
10022,14,241,4,0,
10022,14,246,1,15,
10022,14,247,4,0,
10022,14,249,4,0,
10022,14,258,4,0,
10022,14,263,4,0,
10022,14,283,1,71,
10022,14,283,3,0,
10022,14,286,1,8,
10022,14,286,1,64,
10022,14,304,1,92,
10022,14,304,3,0,
10022,14,317,4,0,
10022,14,324,3,0,
10022,14,337,4,0,
10022,14,355,3,0,
10022,14,371,4,0,
10022,14,374,4,0,
10022,14,406,1,57,
10022,14,406,3,0,
10022,14,411,4,0,
10022,14,414,3,0,
10022,14,416,4,0,
10022,14,421,4,0,
10022,14,428,3,0,
10022,14,430,4,0,
10022,14,434,3,0,
10022,14,442,3,0,
10022,14,444,4,0,
10022,14,468,4,0,
10022,14,496,4,0,
10022,14,497,4,0,
10022,14,525,4,0,
10022,14,553,1,50,
10022,14,559,1,43,
10022,15,15,4,0,
10022,15,19,4,0,
10022,15,58,1,22,
10022,15,58,4,0,
10022,15,59,1,78,
10022,15,59,4,0,
10022,15,63,4,0,
10022,15,70,4,0,
10022,15,82,1,1,2
10022,15,92,4,0,
10022,15,94,4,0,
10022,15,104,4,0,
10022,15,113,4,0,
10022,15,115,4,0,
10022,15,156,4,0,
10022,15,157,4,0,
10022,15,163,1,36,
10022,15,164,4,0,
10022,15,182,4,0,
10022,15,196,1,1,1
10022,15,200,1,85,
10022,15,207,4,0,
10022,15,211,4,0,
10022,15,214,4,0,
10022,15,216,4,0,
10022,15,218,4,0,
10022,15,219,4,0,
10022,15,225,1,29,
10022,15,237,4,0,
10022,15,240,4,0,
10022,15,241,4,0,
10022,15,246,1,15,
10022,15,247,4,0,
10022,15,249,4,0,
10022,15,258,4,0,
10022,15,263,4,0,
10022,15,283,1,71,
10022,15,286,1,8,
10022,15,286,1,64,
10022,15,304,1,92,
10022,15,317,4,0,
10022,15,337,4,0,
10022,15,355,4,0,
10022,15,371,4,0,
10022,15,374,4,0,
10022,15,406,1,57,
10022,15,411,4,0,
10022,15,416,4,0,
10022,15,421,4,0,
10022,15,430,4,0,
10022,15,434,3,0,
10022,15,444,4,0,
10022,15,468,4,0,
10022,15,496,4,0,
10022,15,497,4,0,
10022,15,525,4,0,
10022,15,553,1,50,
10022,15,559,1,43,
10022,15,590,4,0,
10022,16,15,4,0,
10022,16,19,4,0,
10022,16,58,1,22,1
10022,16,58,4,0,
10022,16,59,1,78,1
10022,16,59,4,0,
10022,16,63,4,0,
10022,16,70,4,0,
10022,16,82,1,1,2
10022,16,92,4,0,
10022,16,94,4,0,
10022,16,104,4,0,
10022,16,113,4,0,
10022,16,115,4,0,
10022,16,156,4,0,
10022,16,157,4,0,
10022,16,163,1,36,1
10022,16,164,4,0,
10022,16,173,3,0,
10022,16,182,4,0,
10022,16,196,1,1,1
10022,16,196,3,0,
10022,16,200,1,85,1
10022,16,200,3,0,
10022,16,207,4,0,
10022,16,211,4,0,
10022,16,214,4,0,
10022,16,216,4,0,
10022,16,218,4,0,
10022,16,219,4,0,
10022,16,225,1,29,1
10022,16,237,4,0,
10022,16,240,4,0,
10022,16,241,4,0,
10022,16,246,1,15,1
10022,16,247,4,0,
10022,16,249,4,0,
10022,16,258,4,0,
10022,16,263,4,0,
10022,16,283,1,71,1
10022,16,283,3,0,
10022,16,286,1,8,1
10022,16,286,1,64,1
10022,16,290,4,0,
10022,16,304,1,92,1
10022,16,304,3,0,
10022,16,317,4,0,
10022,16,324,3,0,
10022,16,337,4,0,
10022,16,355,4,0,
10022,16,371,4,0,
10022,16,374,4,0,
10022,16,406,1,57,1
10022,16,406,3,0,
10022,16,411,4,0,
10022,16,414,3,0,
10022,16,416,4,0,
10022,16,421,4,0,
10022,16,428,3,0,
10022,16,430,4,0,
10022,16,434,3,0,
10022,16,442,3,0,
10022,16,444,4,0,
10022,16,468,4,0,
10022,16,496,4,0,
10022,16,497,4,0,
10022,16,525,4,0,
10022,16,553,1,50,1
10022,16,559,1,43,1
10022,16,590,4,0,
10022,17,19,4,0,
10022,17,58,1,22,
10022,17,58,4,0,
10022,17,59,1,78,
10022,17,59,4,0,
10022,17,63,4,0,
10022,17,82,1,1,2
10022,17,92,4,0,
10022,17,94,4,0,
10022,17,104,4,0,
10022,17,113,4,0,
10022,17,115,4,0,
10022,17,156,4,0,
10022,17,157,4,0,
10022,17,163,1,36,
10022,17,164,4,0,
10022,17,182,4,0,
10022,17,196,1,1,1
10022,17,200,1,85,
10022,17,207,4,0,
10022,17,211,4,0,
10022,17,214,4,0,
10022,17,216,4,0,
10022,17,218,4,0,
10022,17,219,4,0,
10022,17,225,1,29,
10022,17,237,4,0,
10022,17,240,4,0,
10022,17,241,4,0,
10022,17,246,1,15,
10022,17,247,4,0,
10022,17,258,4,0,
10022,17,263,4,0,
10022,17,283,1,71,
10022,17,286,1,8,
10022,17,286,1,64,
10022,17,304,1,92,
10022,17,317,4,0,
10022,17,337,4,0,
10022,17,355,4,0,
10022,17,371,4,0,
10022,17,374,4,0,
10022,17,406,1,57,
10022,17,411,4,0,
10022,17,416,4,0,
10022,17,421,4,0,
10022,17,430,4,0,
10022,17,434,3,0,
10022,17,444,4,0,
10022,17,496,4,0,
10022,17,497,4,0,
10022,17,525,4,0,
10022,17,553,1,50,
10022,17,559,1,43,
10022,17,590,4,0,
10022,17,693,4,0,
10022,18,19,4,0,
10022,18,58,1,22,
10022,18,58,4,0,
10022,18,59,1,78,
10022,18,59,4,0,
10022,18,63,4,0,
10022,18,82,1,1,2
10022,18,92,4,0,
10022,18,94,4,0,
10022,18,104,4,0,
10022,18,113,4,0,
10022,18,115,4,0,
10022,18,156,4,0,
10022,18,157,4,0,
10022,18,163,1,36,
10022,18,164,4,0,
10022,18,182,4,0,
10022,18,196,1,1,1
10022,18,200,1,85,
10022,18,207,4,0,
10022,18,211,4,0,
10022,18,214,4,0,
10022,18,216,4,0,
10022,18,218,4,0,
10022,18,219,4,0,
10022,18,225,1,29,
10022,18,237,4,0,
10022,18,240,4,0,
10022,18,241,4,0,
10022,18,246,1,15,
10022,18,247,4,0,
10022,18,258,4,0,
10022,18,263,4,0,
10022,18,283,1,71,
10022,18,286,1,8,
10022,18,304,1,92,
10022,18,317,4,0,
10022,18,337,4,0,
10022,18,355,4,0,
10022,18,371,4,0,
10022,18,374,4,0,
10022,18,406,1,57,
10022,18,411,4,0,
10022,18,416,4,0,
10022,18,421,4,0,
10022,18,430,4,0,
10022,18,434,3,0,
10022,18,444,4,0,
10022,18,496,4,0,
10022,18,497,4,0,
10022,18,525,4,0,
10022,18,553,1,50,
10022,18,559,1,43,
10022,18,568,1,64,
10022,18,590,4,0,
10022,18,693,4,0,
10023,14,15,4,0,
10023,14,19,4,0,
10023,14,58,1,22,
10023,14,58,4,0,
10023,14,59,1,78,
10023,14,59,4,0,
10023,14,63,4,0,
10023,14,70,4,0,
10023,14,82,1,1,2
10023,14,92,4,0,
10023,14,94,4,0,
10023,14,104,4,0,
10023,14,113,4,0,
10023,14,115,4,0,
10023,14,156,4,0,
10023,14,157,4,0,
10023,14,163,1,36,
10023,14,164,4,0,
10023,14,173,3,0,
10023,14,182,4,0,
10023,14,196,1,1,1
10023,14,196,3,0,
10023,14,200,1,85,
10023,14,200,3,0,
10023,14,207,4,0,
10023,14,214,3,0,
10023,14,216,4,0,
10023,14,218,4,0,
10023,14,219,4,0,
10023,14,225,1,29,
10023,14,237,4,0,
10023,14,240,4,0,
10023,14,241,4,0,
10023,14,246,1,15,
10023,14,247,4,0,
10023,14,249,4,0,
10023,14,258,4,0,
10023,14,263,4,0,
10023,14,283,1,71,
10023,14,283,3,0,
10023,14,286,1,8,
10023,14,286,1,64,
10023,14,304,1,92,
10023,14,304,3,0,
10023,14,317,4,0,
10023,14,324,3,0,
10023,14,337,4,0,
10023,14,355,3,0,
10023,14,371,4,0,
10023,14,374,4,0,
10023,14,406,1,57,
10023,14,406,3,0,
10023,14,411,4,0,
10023,14,414,3,0,
10023,14,416,4,0,
10023,14,421,4,0,
10023,14,428,3,0,
10023,14,430,4,0,
10023,14,434,3,0,
10023,14,442,3,0,
10023,14,444,4,0,
10023,14,468,4,0,
10023,14,496,4,0,
10023,14,497,4,0,
10023,14,525,4,0,
10023,14,554,1,50,
10023,14,558,1,43,
10023,15,15,4,0,
10023,15,19,4,0,
10023,15,58,1,22,
10023,15,58,4,0,
10023,15,59,1,78,
10023,15,59,4,0,
10023,15,63,4,0,
10023,15,70,4,0,
10023,15,82,1,1,2
10023,15,92,4,0,
10023,15,94,4,0,
10023,15,104,4,0,
10023,15,113,4,0,
10023,15,115,4,0,
10023,15,156,4,0,
10023,15,157,4,0,
10023,15,163,1,36,
10023,15,164,4,0,
10023,15,182,4,0,
10023,15,196,1,1,1
10023,15,200,1,85,
10023,15,207,4,0,
10023,15,211,4,0,
10023,15,214,4,0,
10023,15,216,4,0,
10023,15,218,4,0,
10023,15,219,4,0,
10023,15,225,1,29,
10023,15,237,4,0,
10023,15,240,4,0,
10023,15,241,4,0,
10023,15,246,1,15,
10023,15,247,4,0,
10023,15,249,4,0,
10023,15,258,4,0,
10023,15,263,4,0,
10023,15,283,1,71,
10023,15,286,1,8,
10023,15,286,1,64,
10023,15,304,1,92,
10023,15,317,4,0,
10023,15,337,4,0,
10023,15,355,4,0,
10023,15,371,4,0,
10023,15,374,4,0,
10023,15,406,1,57,
10023,15,411,4,0,
10023,15,416,4,0,
10023,15,421,4,0,
10023,15,430,4,0,
10023,15,434,3,0,
10023,15,444,4,0,
10023,15,468,4,0,
10023,15,496,4,0,
10023,15,497,4,0,
10023,15,525,4,0,
10023,15,554,1,50,
10023,15,558,1,43,
10023,15,590,4,0,
10023,16,15,4,0,
10023,16,19,4,0,
10023,16,58,1,22,1
10023,16,58,4,0,
10023,16,59,1,78,1
10023,16,59,4,0,
10023,16,63,4,0,
10023,16,70,4,0,
10023,16,82,1,1,2
10023,16,92,4,0,
10023,16,94,4,0,
10023,16,104,4,0,
10023,16,113,4,0,
10023,16,115,4,0,
10023,16,156,4,0,
10023,16,157,4,0,
10023,16,163,1,36,1
10023,16,164,4,0,
10023,16,173,3,0,
10023,16,182,4,0,
10023,16,196,1,1,1
10023,16,196,3,0,
10023,16,200,1,85,1
10023,16,200,3,0,
10023,16,207,4,0,
10023,16,211,4,0,
10023,16,214,4,0,
10023,16,216,4,0,
10023,16,218,4,0,
10023,16,219,4,0,
10023,16,225,1,29,1
10023,16,237,4,0,
10023,16,240,4,0,
10023,16,241,4,0,
10023,16,246,1,15,1
10023,16,247,4,0,
10023,16,249,4,0,
10023,16,258,4,0,
10023,16,263,4,0,
10023,16,283,1,71,1
10023,16,283,3,0,
10023,16,286,1,8,1
10023,16,286,1,64,1
10023,16,290,4,0,
10023,16,304,1,92,1
10023,16,304,3,0,
10023,16,317,4,0,
10023,16,324,3,0,
10023,16,337,4,0,
10023,16,355,4,0,
10023,16,371,4,0,
10023,16,374,4,0,
10023,16,406,1,57,1
10023,16,406,3,0,
10023,16,411,4,0,
10023,16,414,3,0,
10023,16,416,4,0,
10023,16,421,4,0,
10023,16,428,3,0,
10023,16,430,4,0,
10023,16,434,3,0,
10023,16,442,3,0,
10023,16,444,4,0,
10023,16,468,4,0,
10023,16,496,4,0,
10023,16,497,4,0,
10023,16,525,4,0,
10023,16,554,1,50,1
10023,16,558,1,43,1
10023,16,590,4,0,
10023,17,19,4,0,
10023,17,58,1,22,
10023,17,58,4,0,
10023,17,59,1,78,
10023,17,59,4,0,
10023,17,63,4,0,
10023,17,82,1,1,2
10023,17,92,4,0,
10023,17,94,4,0,
10023,17,104,4,0,
10023,17,113,4,0,
10023,17,115,4,0,
10023,17,156,4,0,
10023,17,157,4,0,
10023,17,163,1,36,
10023,17,164,4,0,
10023,17,182,4,0,
10023,17,196,1,1,1
10023,17,200,1,85,
10023,17,207,4,0,
10023,17,211,4,0,
10023,17,214,4,0,
10023,17,216,4,0,
10023,17,218,4,0,
10023,17,219,4,0,
10023,17,225,1,29,
10023,17,237,4,0,
10023,17,240,4,0,
10023,17,241,4,0,
10023,17,246,1,15,
10023,17,247,4,0,
10023,17,258,4,0,
10023,17,263,4,0,
10023,17,283,1,71,
10023,17,286,1,8,
10023,17,286,1,64,
10023,17,304,1,92,
10023,17,317,4,0,
10023,17,337,4,0,
10023,17,355,4,0,
10023,17,371,4,0,
10023,17,374,4,0,
10023,17,406,1,57,
10023,17,411,4,0,
10023,17,416,4,0,
10023,17,421,4,0,
10023,17,430,4,0,
10023,17,434,3,0,
10023,17,444,4,0,
10023,17,496,4,0,
10023,17,497,4,0,
10023,17,525,4,0,
10023,17,554,1,50,
10023,17,558,1,43,
10023,17,590,4,0,
10023,17,693,4,0,
10023,18,19,4,0,
10023,18,58,1,22,
10023,18,58,4,0,
10023,18,59,1,78,
10023,18,59,4,0,
10023,18,63,4,0,
10023,18,82,1,1,2
10023,18,92,4,0,
10023,18,94,4,0,
10023,18,104,4,0,
10023,18,113,4,0,
10023,18,115,4,0,
10023,18,156,4,0,
10023,18,157,4,0,
10023,18,163,1,36,
10023,18,164,4,0,
10023,18,182,4,0,
10023,18,196,1,1,1
10023,18,200,1,85,
10023,18,207,4,0,
10023,18,211,4,0,
10023,18,214,4,0,
10023,18,216,4,0,
10023,18,218,4,0,
10023,18,219,4,0,
10023,18,225,1,29,
10023,18,237,4,0,
10023,18,240,4,0,
10023,18,241,4,0,
10023,18,246,1,15,
10023,18,247,4,0,
10023,18,258,4,0,
10023,18,263,4,0,
10023,18,283,1,71,
10023,18,286,1,8,
10023,18,304,1,92,
10023,18,317,4,0,
10023,18,337,4,0,
10023,18,355,4,0,
10023,18,371,4,0,
10023,18,374,4,0,
10023,18,406,1,57,
10023,18,411,4,0,
10023,18,416,4,0,
10023,18,421,4,0,
10023,18,430,4,0,
10023,18,434,3,0,
10023,18,444,4,0,
10023,18,496,4,0,
10023,18,497,4,0,
10023,18,525,4,0,
10023,18,554,1,50,
10023,18,558,1,43,
10023,18,568,1,64,
10023,18,590,4,0,
10023,18,693,4,0,
10024,14,14,1,49,
10024,14,14,4,0,
10024,14,15,4,0,
10024,14,24,1,7,
10024,14,36,1,19,
10024,14,43,1,1,2
10024,14,46,4,0,
10024,14,56,1,67,
10024,14,57,4,0,
10024,14,61,1,13,
10024,14,63,4,0,
10024,14,70,4,0,
10024,14,92,4,0,
10024,14,104,4,0,
10024,14,115,4,0,
10024,14,156,4,0,
10024,14,164,4,0,
10024,14,173,3,0,
10024,14,182,4,0,
10024,14,196,3,0,
10024,14,206,4,0,
10024,14,207,4,0,
10024,14,214,3,0,
10024,14,216,4,0,
10024,14,218,4,0,
10024,14,219,4,0,
10024,14,237,4,0,
10024,14,240,4,0,
10024,14,244,4,0,
10024,14,249,4,0,
10024,14,258,4,0,
10024,14,263,4,0,
10024,14,269,4,0,
10024,14,270,1,25,
10024,14,270,3,0,
10024,14,276,3,0,
10024,14,283,3,0,
10024,14,332,4,0,
10024,14,340,3,0,
10024,14,343,3,0,
10024,14,347,4,0,
10024,14,370,1,73,
10024,14,387,3,0,
10024,14,398,4,0,
10024,14,401,1,37,
10024,14,401,3,0,
10024,14,404,4,0,
10024,14,411,4,0,
10024,14,416,4,0,
10024,14,444,4,0,
10024,14,453,1,1,1
10024,14,496,4,0,
10024,14,501,1,55,
10024,14,503,4,0,
10024,14,514,1,31,
10024,14,514,4,0,
10024,14,526,1,61,
10024,14,526,4,0,
10024,14,533,1,43,
10024,14,548,3,0,
10024,15,14,1,49,
10024,15,14,4,0,
10024,15,15,4,0,
10024,15,24,1,7,
10024,15,36,1,19,
10024,15,43,1,1,2
10024,15,46,4,0,
10024,15,56,1,67,
10024,15,57,4,0,
10024,15,61,1,13,
10024,15,63,4,0,
10024,15,70,4,0,
10024,15,92,4,0,
10024,15,104,4,0,
10024,15,115,4,0,
10024,15,156,4,0,
10024,15,164,4,0,
10024,15,182,4,0,
10024,15,206,4,0,
10024,15,207,4,0,
10024,15,214,4,0,
10024,15,216,4,0,
10024,15,218,4,0,
10024,15,219,4,0,
10024,15,237,4,0,
10024,15,240,4,0,
10024,15,244,4,0,
10024,15,249,4,0,
10024,15,258,4,0,
10024,15,263,4,0,
10024,15,269,4,0,
10024,15,270,1,25,
10024,15,332,4,0,
10024,15,347,4,0,
10024,15,370,1,73,
10024,15,398,4,0,
10024,15,401,1,37,
10024,15,404,4,0,
10024,15,411,4,0,
10024,15,416,4,0,
10024,15,444,4,0,
10024,15,453,1,1,1
10024,15,496,4,0,
10024,15,501,1,55,
10024,15,503,4,0,
10024,15,514,1,31,
10024,15,514,4,0,
10024,15,526,1,61,
10024,15,533,1,43,
10024,15,548,3,0,
10024,15,590,4,0,
10024,16,14,1,49,1
10024,16,14,4,0,
10024,16,15,4,0,
10024,16,24,1,7,1
10024,16,36,1,19,1
10024,16,43,1,1,2
10024,16,46,4,0,
10024,16,56,1,67,1
10024,16,57,4,0,
10024,16,61,1,13,1
10024,16,63,4,0,
10024,16,70,4,0,
10024,16,92,4,0,
10024,16,104,4,0,
10024,16,115,4,0,
10024,16,156,4,0,
10024,16,164,4,0,
10024,16,173,3,0,
10024,16,182,4,0,
10024,16,196,3,0,
10024,16,206,4,0,
10024,16,207,4,0,
10024,16,214,4,0,
10024,16,216,4,0,
10024,16,218,4,0,
10024,16,219,4,0,
10024,16,237,4,0,
10024,16,240,4,0,
10024,16,244,4,0,
10024,16,249,4,0,
10024,16,258,4,0,
10024,16,263,4,0,
10024,16,269,4,0,
10024,16,270,1,25,1
10024,16,270,3,0,
10024,16,276,3,0,
10024,16,283,3,0,
10024,16,290,4,0,
10024,16,332,4,0,
10024,16,340,3,0,
10024,16,343,3,0,
10024,16,347,4,0,
10024,16,352,3,0,
10024,16,370,1,73,1
10024,16,387,3,0,
10024,16,398,4,0,
10024,16,401,1,37,1
10024,16,401,3,0,
10024,16,404,4,0,
10024,16,411,4,0,
10024,16,416,4,0,
10024,16,444,4,0,
10024,16,453,1,1,1
10024,16,496,4,0,
10024,16,501,1,55,1
10024,16,503,4,0,
10024,16,514,1,31,1
10024,16,514,4,0,
10024,16,526,1,61,1
10024,16,533,1,43,1
10024,16,590,4,0,
10024,17,14,1,49,
10024,17,14,4,0,
10024,17,24,1,1,3
10024,17,24,1,7,
10024,17,36,1,19,
10024,17,43,1,1,2
10024,17,46,4,0,
10024,17,56,1,67,
10024,17,57,4,0,
10024,17,61,1,1,4
10024,17,61,1,13,
10024,17,63,4,0,
10024,17,92,4,0,
10024,17,104,4,0,
10024,17,115,4,0,
10024,17,156,4,0,
10024,17,164,4,0,
10024,17,182,4,0,
10024,17,206,4,0,
10024,17,207,4,0,
10024,17,214,4,0,
10024,17,216,4,0,
10024,17,218,4,0,
10024,17,219,4,0,
10024,17,237,4,0,
10024,17,240,4,0,
10024,17,244,4,0,
10024,17,258,4,0,
10024,17,263,4,0,
10024,17,269,4,0,
10024,17,270,1,25,
10024,17,332,4,0,
10024,17,347,4,0,
10024,17,370,1,73,
10024,17,398,4,0,
10024,17,401,1,37,
10024,17,404,4,0,
10024,17,411,4,0,
10024,17,416,4,0,
10024,17,444,4,0,
10024,17,453,1,1,1
10024,17,496,4,0,
10024,17,501,1,55,
10024,17,503,4,0,
10024,17,514,1,31,
10024,17,526,1,61,
10024,17,526,4,0,
10024,17,533,1,43,
10024,17,590,4,0,
10024,18,14,1,49,
10024,18,14,4,0,
10024,18,24,1,1,3
10024,18,24,1,7,
10024,18,36,1,19,
10024,18,43,1,1,2
10024,18,46,4,0,
10024,18,56,1,67,
10024,18,57,4,0,
10024,18,61,1,1,4
10024,18,61,1,13,
10024,18,63,4,0,
10024,18,92,4,0,
10024,18,104,4,0,
10024,18,115,4,0,
10024,18,156,4,0,
10024,18,164,4,0,
10024,18,182,4,0,
10024,18,206,4,0,
10024,18,207,4,0,
10024,18,214,4,0,
10024,18,216,4,0,
10024,18,218,4,0,
10024,18,219,4,0,
10024,18,237,4,0,
10024,18,240,4,0,
10024,18,244,4,0,
10024,18,258,4,0,
10024,18,263,4,0,
10024,18,269,4,0,
10024,18,270,1,25,
10024,18,332,4,0,
10024,18,347,4,0,
10024,18,370,1,73,
10024,18,398,4,0,
10024,18,401,1,37,
10024,18,404,4,0,
10024,18,411,4,0,
10024,18,416,4,0,
10024,18,444,4,0,
10024,18,453,1,1,1
10024,18,496,4,0,
10024,18,501,1,55,
10024,18,503,4,0,
10024,18,514,1,31,
10024,18,526,1,61,
10024,18,526,4,0,
10024,18,533,1,43,
10024,18,590,4,0,
10025,15,10,1,1,4
10025,15,15,4,0,
10025,15,43,1,1,5
10025,15,60,1,17,
10025,15,63,4,0,
10025,15,85,4,0,
10025,15,86,4,0,
10025,15,91,4,0,
10025,15,92,4,0,
10025,15,93,1,9,
10025,15,94,1,40,
10025,15,94,4,0,
10025,15,104,4,0,
10025,15,113,1,13,
10025,15,113,4,0,
10025,15,115,4,0,
10025,15,138,4,0,
10025,15,148,4,0,
10025,15,156,4,0,
10025,15,164,4,0,
10025,15,182,4,0,
10025,15,207,4,0,
10025,15,213,4,0,
10025,15,214,4,0,
10025,15,216,4,0,
10025,15,218,4,0,
10025,15,219,4,0,
10025,15,237,4,0,
10025,15,240,4,0,
10025,15,241,4,0,
10025,15,244,4,0,
10025,15,247,1,31,
10025,15,247,4,0,
10025,15,248,1,50,
10025,15,252,1,19,
10025,15,259,4,0,
10025,15,263,4,0,
10025,15,272,1,43,
10025,15,324,1,45,
10025,15,326,1,35,
10025,15,343,1,5,
10025,15,345,1,1,3
10025,15,347,4,0,
10025,15,371,4,0,
10025,15,382,1,1,2
10025,15,389,1,48,
10025,15,399,4,0,
10025,15,412,4,0,
10025,15,416,4,0,
10025,15,433,4,0,
10025,15,451,1,28,
10025,15,451,4,0,
10025,15,473,1,25,
10025,15,473,4,0,
10025,15,496,4,0,
10025,15,497,4,0,
10025,15,500,1,1,1
10025,15,500,1,53,
10025,15,574,1,22,
10025,15,590,4,0,
10025,15,612,4,0,
10025,16,10,1,1,4
10025,16,15,4,0,
10025,16,43,1,1,5
10025,16,60,1,17,1
10025,16,63,4,0,
10025,16,85,4,0,
10025,16,86,4,0,
10025,16,91,4,0,
10025,16,92,4,0,
10025,16,93,1,9,1
10025,16,94,1,40,1
10025,16,94,4,0,
10025,16,104,4,0,
10025,16,113,1,13,1
10025,16,113,4,0,
10025,16,115,4,0,
10025,16,138,4,0,
10025,16,148,4,0,
10025,16,156,4,0,
10025,16,164,4,0,
10025,16,173,3,0,
10025,16,182,4,0,
10025,16,207,4,0,
10025,16,213,4,0,
10025,16,214,4,0,
10025,16,215,3,0,
10025,16,216,4,0,
10025,16,218,4,0,
10025,16,219,4,0,
10025,16,231,3,0,
10025,16,237,4,0,
10025,16,240,4,0,
10025,16,241,4,0,
10025,16,244,4,0,
10025,16,247,1,31,1
10025,16,247,4,0,
10025,16,248,1,50,1
10025,16,252,1,19,1
10025,16,259,4,0,
10025,16,263,4,0,
10025,16,271,3,0,
10025,16,272,1,43,1
10025,16,272,3,0,
10025,16,277,3,0,
10025,16,278,3,0,
10025,16,289,3,0,
10025,16,290,4,0,
10025,16,324,1,45,1
10025,16,324,3,0,
10025,16,326,1,35,1
10025,16,343,1,5,1
10025,16,343,3,0,
10025,16,345,1,1,3
10025,16,347,4,0,
10025,16,351,3,0,
10025,16,356,3,0,
10025,16,371,4,0,
10025,16,382,1,1,2
10025,16,389,1,48,1
10025,16,399,4,0,
10025,16,412,4,0,
10025,16,416,4,0,
10025,16,428,3,0,
10025,16,433,4,0,
10025,16,451,1,28,1
10025,16,451,4,0,
10025,16,472,3,0,
10025,16,473,1,25,1
10025,16,473,4,0,
10025,16,478,3,0,
10025,16,496,4,0,
10025,16,497,4,0,
10025,16,500,1,1,1
10025,16,500,1,53,1
10025,16,574,1,22,1
10025,16,590,4,0,
10025,16,612,4,0,
10025,17,10,1,1,4
10025,17,43,1,1,5
10025,17,60,1,17,
10025,17,63,4,0,
10025,17,85,4,0,
10025,17,86,4,0,
10025,17,92,4,0,
10025,17,93,1,1,7
10025,17,93,1,9,
10025,17,94,1,40,
10025,17,94,4,0,
10025,17,104,4,0,
10025,17,113,1,13,
10025,17,113,4,0,
10025,17,115,4,0,
10025,17,138,4,0,
10025,17,156,4,0,
10025,17,164,4,0,
10025,17,182,4,0,
10025,17,207,4,0,
10025,17,213,4,0,
10025,17,214,4,0,
10025,17,216,4,0,
10025,17,218,4,0,
10025,17,219,4,0,
10025,17,237,4,0,
10025,17,240,4,0,
10025,17,241,4,0,
10025,17,244,4,0,
10025,17,247,1,31,
10025,17,247,4,0,
10025,17,248,1,50,
10025,17,252,1,19,
10025,17,259,4,0,
10025,17,263,4,0,
10025,17,272,1,43,
10025,17,324,1,45,
10025,17,326,1,35,
10025,17,343,1,1,6
10025,17,343,1,5,
10025,17,345,1,1,3
10025,17,347,4,0,
10025,17,371,4,0,
10025,17,382,1,1,2
10025,17,389,1,48,
10025,17,399,4,0,
10025,17,412,4,0,
10025,17,416,4,0,
10025,17,433,4,0,
10025,17,451,1,28,
10025,17,451,4,0,
10025,17,473,1,25,
10025,17,473,4,0,
10025,17,496,4,0,
10025,17,497,4,0,
10025,17,500,1,1,1
10025,17,500,1,53,
10025,17,526,4,0,
10025,17,574,1,22,
10025,17,590,4,0,
10025,18,10,1,1,4
10025,18,43,1,1,5
10025,18,60,1,17,
10025,18,63,4,0,
10025,18,85,4,0,
10025,18,86,4,0,
10025,18,92,4,0,
10025,18,93,1,1,7
10025,18,93,1,9,
10025,18,94,1,40,
10025,18,94,4,0,
10025,18,104,4,0,
10025,18,113,1,13,
10025,18,113,4,0,
10025,18,115,4,0,
10025,18,138,4,0,
10025,18,156,4,0,
10025,18,164,4,0,
10025,18,182,4,0,
10025,18,207,4,0,
10025,18,213,4,0,
10025,18,214,4,0,
10025,18,216,4,0,
10025,18,218,4,0,
10025,18,219,4,0,
10025,18,237,4,0,
10025,18,240,4,0,
10025,18,241,4,0,
10025,18,244,4,0,
10025,18,247,1,31,
10025,18,247,4,0,
10025,18,248,1,50,
10025,18,252,1,19,
10025,18,259,4,0,
10025,18,263,4,0,
10025,18,272,1,43,
10025,18,324,1,45,
10025,18,326,1,35,
10025,18,343,1,1,6
10025,18,343,1,5,
10025,18,345,1,1,3
10025,18,347,4,0,
10025,18,371,4,0,
10025,18,382,1,1,2
10025,18,389,1,48,
10025,18,399,4,0,
10025,18,412,4,0,
10025,18,416,4,0,
10025,18,433,4,0,
10025,18,451,1,28,
10025,18,451,4,0,
10025,18,473,1,25,
10025,18,473,4,0,
10025,18,496,4,0,
10025,18,497,4,0,
10025,18,500,1,1,1
10025,18,500,1,53,
10025,18,526,4,0,
10025,18,574,1,22,
10025,18,590,4,0,
10026,15,14,1,1,11
10026,15,14,4,0,
10026,15,15,4,0,
10026,15,63,4,0,
10026,15,92,4,0,
10026,15,104,4,0,
10026,15,115,4,0,
10026,15,156,4,0,
10026,15,157,4,0,
10026,15,163,1,1,5
10026,15,164,4,0,
10026,15,182,4,0,
10026,15,206,4,0,
10026,15,207,4,0,
10026,15,210,1,1,1
10026,15,213,4,0,
10026,15,214,4,0,
10026,15,216,4,0,
10026,15,218,4,0,
10026,15,228,1,1,2
10026,15,237,4,0,
10026,15,240,4,0,
10026,15,241,4,0,
10026,15,247,4,0,
10026,15,249,4,0,
10026,15,263,4,0,
10026,15,280,4,0,
10026,15,332,1,1,12
10026,15,332,4,0,
10026,15,334,1,1,6
10026,15,360,4,0,
10026,15,379,1,1,8
10026,15,400,1,1,7
10026,15,416,4,0,
10026,15,421,4,0,
10026,15,425,1,1,4
10026,15,430,4,0,
10026,15,442,1,1,9
10026,15,457,1,1,10
10026,15,475,1,1,3
10026,15,496,4,0,
10026,15,514,4,0,
10026,15,533,1,1,14
10026,15,588,1,1,13
10026,15,590,4,0,
10026,16,14,1,1,11
10026,16,14,4,0,
10026,16,15,4,0,
10026,16,63,4,0,
10026,16,92,4,0,
10026,16,104,4,0,
10026,16,115,4,0,
10026,16,156,4,0,
10026,16,157,4,0,
10026,16,163,1,1,5
10026,16,164,4,0,
10026,16,173,3,0,
10026,16,180,3,0,
10026,16,182,4,0,
10026,16,206,4,0,
10026,16,207,4,0,
10026,16,210,1,1,1
10026,16,213,4,0,
10026,16,214,4,0,
10026,16,216,4,0,
10026,16,218,4,0,
10026,16,228,1,1,2
10026,16,237,4,0,
10026,16,240,4,0,
10026,16,241,4,0,
10026,16,247,4,0,
10026,16,249,4,0,
10026,16,263,4,0,
10026,16,280,4,0,
10026,16,290,4,0,
10026,16,332,1,1,12
10026,16,332,4,0,
10026,16,334,1,1,6
10026,16,334,3,0,
10026,16,335,3,0,
10026,16,351,3,0,
10026,16,360,4,0,
10026,16,379,1,1,8
10026,16,393,3,0,
10026,16,400,1,1,7
10026,16,416,4,0,
10026,16,421,4,0,
10026,16,425,1,1,4
10026,16,430,4,0,
10026,16,442,1,1,9
10026,16,442,3,0,
10026,16,457,1,1,10
10026,16,475,1,1,3
10026,16,495,3,0,
10026,16,496,4,0,
10026,16,514,4,0,
10026,16,533,1,1,14
10026,16,588,1,1,13
10026,16,590,4,0,
10026,17,14,1,1,11
10026,17,14,4,0,
10026,17,63,4,0,
10026,17,92,4,0,
10026,17,104,4,0,
10026,17,115,4,0,
10026,17,156,4,0,
10026,17,157,4,0,
10026,17,163,1,1,5
10026,17,164,4,0,
10026,17,182,4,0,
10026,17,206,4,0,
10026,17,207,4,0,
10026,17,210,1,1,1
10026,17,213,4,0,
10026,17,214,4,0,
10026,17,216,4,0,
10026,17,218,4,0,
10026,17,228,1,1,2
10026,17,237,4,0,
10026,17,240,4,0,
10026,17,241,4,0,
10026,17,247,4,0,
10026,17,263,4,0,
10026,17,280,4,0,
10026,17,332,1,1,12
10026,17,332,4,0,
10026,17,334,1,1,6
10026,17,360,4,0,
10026,17,379,1,1,8
10026,17,400,1,1,7
10026,17,416,4,0,
10026,17,421,4,0,
10026,17,425,1,1,4
10026,17,430,4,0,
10026,17,442,1,1,9
10026,17,457,1,1,10
10026,17,475,1,1,3
10026,17,496,4,0,
10026,17,533,1,1,14
10026,17,588,1,1,13
10026,17,590,4,0,
10026,17,693,4,0,
10026,18,14,1,1,11
10026,18,14,4,0,
10026,18,63,4,0,
10026,18,92,4,0,
10026,18,104,4,0,
10026,18,115,4,0,
10026,18,156,4,0,
10026,18,157,4,0,
10026,18,163,1,1,5
10026,18,164,4,0,
10026,18,182,4,0,
10026,18,206,4,0,
10026,18,207,4,0,
10026,18,210,1,1,1
10026,18,213,4,0,
10026,18,214,4,0,
10026,18,216,4,0,
10026,18,218,4,0,
10026,18,228,1,1,2
10026,18,237,4,0,
10026,18,240,4,0,
10026,18,241,4,0,
10026,18,247,4,0,
10026,18,263,4,0,
10026,18,280,4,0,
10026,18,332,1,1,12
10026,18,332,4,0,
10026,18,334,1,1,6
10026,18,360,4,0,
10026,18,379,1,1,8
10026,18,400,1,1,7
10026,18,416,4,0,
10026,18,421,4,0,
10026,18,425,1,1,4
10026,18,430,4,0,
10026,18,442,1,1,9
10026,18,457,1,1,10
10026,18,475,1,1,3
10026,18,496,4,0,
10026,18,533,1,1,14
10026,18,588,1,1,13
10026,18,590,4,0,
10026,18,693,4,0,
10027,15,50,2,0,
10027,15,53,4,0,
10027,15,73,1,20,
10027,15,75,1,16,
10027,15,76,4,0,
10027,15,92,4,0,
10027,15,94,4,0,
10027,15,104,4,0,
10027,15,109,1,1,3
10027,15,113,4,0,
10027,15,126,4,0,
10027,15,138,4,0,
10027,15,148,4,0,
10027,15,153,4,0,
10027,15,156,4,0,
10027,15,157,4,0,
10027,15,164,4,0,
10027,15,168,4,0,
10027,15,182,4,0,
10027,15,184,1,4,
10027,15,188,4,0,
10027,15,194,2,0,
10027,15,207,4,0,
10027,15,213,4,0,
10027,15,214,4,0,
10027,15,216,4,0,
10027,15,218,4,0,
10027,15,219,4,0,
10027,15,220,1,42,
10027,15,237,4,0,
10027,15,241,4,0,
10027,15,247,1,36,
10027,15,247,4,0,
10027,15,249,4,0,
10027,15,261,4,0,
10027,15,263,4,0,
10027,15,267,4,0,
10027,15,271,1,1,1
10027,15,310,1,1,2
10027,15,331,1,26,
10027,15,360,4,0,
10027,15,388,1,11,
10027,15,399,4,0,
10027,15,402,1,48,
10027,15,412,4,0,
10027,15,425,1,30,
10027,15,433,4,0,
10027,15,447,4,0,
10027,15,451,4,0,
10027,15,488,4,0,
10027,15,496,4,0,
10027,15,510,4,0,
10027,15,516,2,0,
10027,15,567,1,6,
10027,15,567,1,23,
10027,15,567,1,40,
10027,15,590,4,0,
10027,16,53,4,0,
10027,16,73,1,20,1
10027,16,75,1,16,1
10027,16,76,4,0,
10027,16,92,4,0,
10027,16,94,4,0,
10027,16,104,4,0,
10027,16,109,1,1,3
10027,16,113,4,0,
10027,16,126,4,0,
10027,16,138,4,0,
10027,16,148,4,0,
10027,16,153,4,0,
10027,16,156,4,0,
10027,16,157,4,0,
10027,16,164,4,0,
10027,16,168,4,0,
10027,16,180,3,0,
10027,16,182,4,0,
10027,16,184,1,4,1
10027,16,188,4,0,
10027,16,202,3,0,
10027,16,207,4,0,
10027,16,213,4,0,
10027,16,214,4,0,
10027,16,216,4,0,
10027,16,218,4,0,
10027,16,219,4,0,
10027,16,220,1,42,1
10027,16,220,3,0,
10027,16,235,3,0,
10027,16,237,4,0,
10027,16,241,4,0,
10027,16,247,1,36,1
10027,16,247,4,0,
10027,16,249,4,0,
10027,16,261,4,0,
10027,16,263,4,0,
10027,16,267,4,0,
10027,16,271,1,1,1
10027,16,271,3,0,
10027,16,272,3,0,
10027,16,277,3,0,
10027,16,285,3,0,
10027,16,290,4,0,
10027,16,310,1,1,2
10027,16,331,1,26,1
10027,16,360,4,0,
10027,16,388,1,11,1
10027,16,388,3,0,
10027,16,399,4,0,
10027,16,402,1,48,1
10027,16,402,3,0,
10027,16,412,4,0,
10027,16,425,1,30,1
10027,16,433,4,0,
10027,16,447,4,0,
10027,16,451,4,0,
10027,16,488,4,0,
10027,16,492,3,0,
10027,16,496,4,0,
10027,16,510,4,0,
10027,16,567,1,6,1
10027,16,567,1,23,1
10027,16,567,1,40,1
10027,16,590,4,0,
10027,17,53,4,0,
10027,17,73,1,20,
10027,17,75,1,16,
10027,17,76,4,0,
10027,17,92,4,0,
10027,17,94,4,0,
10027,17,104,4,0,
10027,17,109,1,1,3
10027,17,113,4,0,
10027,17,126,4,0,
10027,17,138,4,0,
10027,17,153,4,0,
10027,17,156,4,0,
10027,17,157,4,0,
10027,17,164,4,0,
10027,17,168,4,0,
10027,17,182,4,0,
10027,17,184,1,4,
10027,17,188,4,0,
10027,17,207,4,0,
10027,17,213,4,0,
10027,17,214,4,0,
10027,17,216,4,0,
10027,17,218,4,0,
10027,17,219,4,0,
10027,17,220,1,42,
10027,17,237,4,0,
10027,17,241,4,0,
10027,17,247,1,36,
10027,17,247,4,0,
10027,17,261,4,0,
10027,17,263,4,0,
10027,17,267,4,0,
10027,17,271,1,1,1
10027,17,310,1,1,2
10027,17,331,1,26,
10027,17,360,4,0,
10027,17,388,1,11,
10027,17,399,4,0,
10027,17,402,1,48,
10027,17,412,4,0,
10027,17,425,1,30,
10027,17,433,4,0,
10027,17,447,4,0,
10027,17,451,4,0,
10027,17,488,4,0,
10027,17,496,4,0,
10027,17,567,1,6,
10027,17,567,1,23,
10027,17,567,1,40,
10027,17,590,4,0,
10027,18,53,4,0,
10027,18,73,1,20,
10027,18,75,1,16,
10027,18,76,4,0,
10027,18,92,4,0,
10027,18,94,4,0,
10027,18,104,4,0,
10027,18,109,1,1,3
10027,18,113,4,0,
10027,18,126,4,0,
10027,18,138,4,0,
10027,18,153,4,0,
10027,18,156,4,0,
10027,18,157,4,0,
10027,18,164,4,0,
10027,18,168,4,0,
10027,18,182,4,0,
10027,18,184,1,4,
10027,18,188,4,0,
10027,18,207,4,0,
10027,18,213,4,0,
10027,18,214,4,0,
10027,18,216,4,0,
10027,18,218,4,0,
10027,18,219,4,0,
10027,18,220,1,42,
10027,18,237,4,0,
10027,18,241,4,0,
10027,18,247,1,36,
10027,18,247,4,0,
10027,18,261,4,0,
10027,18,263,4,0,
10027,18,267,4,0,
10027,18,271,1,1,1
10027,18,310,1,1,2
10027,18,331,1,26,
10027,18,360,4,0,
10027,18,388,1,11,
10027,18,399,4,0,
10027,18,402,1,48,
10027,18,412,4,0,
10027,18,425,1,30,
10027,18,433,4,0,
10027,18,447,4,0,
10027,18,451,4,0,
10027,18,488,4,0,
10027,18,496,4,0,
10027,18,567,1,6,
10027,18,567,1,23,
10027,18,567,1,40,
10027,18,590,4,0,
10028,15,50,2,0,
10028,15,53,4,0,
10028,15,73,1,20,
10028,15,75,1,16,
10028,15,76,4,0,
10028,15,92,4,0,
10028,15,94,4,0,
10028,15,104,4,0,
10028,15,109,1,1,3
10028,15,113,4,0,
10028,15,126,4,0,
10028,15,138,4,0,
10028,15,148,4,0,
10028,15,153,4,0,
10028,15,156,4,0,
10028,15,157,4,0,
10028,15,164,4,0,
10028,15,168,4,0,
10028,15,182,4,0,
10028,15,184,1,4,
10028,15,188,4,0,
10028,15,194,2,0,
10028,15,207,4,0,
10028,15,213,4,0,
10028,15,214,4,0,
10028,15,216,4,0,
10028,15,218,4,0,
10028,15,219,4,0,
10028,15,220,1,42,
10028,15,237,4,0,
10028,15,241,4,0,
10028,15,247,1,36,
10028,15,247,4,0,
10028,15,249,4,0,
10028,15,261,4,0,
10028,15,263,4,0,
10028,15,267,4,0,
10028,15,271,1,1,1
10028,15,310,1,1,2
10028,15,331,1,26,
10028,15,360,4,0,
10028,15,388,1,11,
10028,15,399,4,0,
10028,15,402,1,48,
10028,15,412,4,0,
10028,15,425,1,30,
10028,15,433,4,0,
10028,15,447,4,0,
10028,15,451,4,0,
10028,15,488,4,0,
10028,15,496,4,0,
10028,15,510,4,0,
10028,15,516,2,0,
10028,15,567,1,6,
10028,15,567,1,23,
10028,15,567,1,40,
10028,15,590,4,0,
10028,16,53,4,0,
10028,16,73,1,20,1
10028,16,75,1,16,1
10028,16,76,4,0,
10028,16,92,4,0,
10028,16,94,4,0,
10028,16,104,4,0,
10028,16,109,1,1,3
10028,16,113,4,0,
10028,16,126,4,0,
10028,16,138,4,0,
10028,16,148,4,0,
10028,16,153,4,0,
10028,16,156,4,0,
10028,16,157,4,0,
10028,16,164,4,0,
10028,16,168,4,0,
10028,16,180,3,0,
10028,16,182,4,0,
10028,16,184,1,4,1
10028,16,188,4,0,
10028,16,202,3,0,
10028,16,207,4,0,
10028,16,213,4,0,
10028,16,214,4,0,
10028,16,216,4,0,
10028,16,218,4,0,
10028,16,219,4,0,
10028,16,220,1,42,1
10028,16,220,3,0,
10028,16,235,3,0,
10028,16,237,4,0,
10028,16,241,4,0,
10028,16,247,1,36,1
10028,16,247,4,0,
10028,16,249,4,0,
10028,16,261,4,0,
10028,16,263,4,0,
10028,16,267,4,0,
10028,16,271,1,1,1
10028,16,271,3,0,
10028,16,272,3,0,
10028,16,277,3,0,
10028,16,285,3,0,
10028,16,290,4,0,
10028,16,310,1,1,2
10028,16,331,1,26,1
10028,16,360,4,0,
10028,16,388,1,11,1
10028,16,388,3,0,
10028,16,399,4,0,
10028,16,402,1,48,1
10028,16,402,3,0,
10028,16,412,4,0,
10028,16,425,1,30,1
10028,16,433,4,0,
10028,16,447,4,0,
10028,16,451,4,0,
10028,16,488,4,0,
10028,16,492,3,0,
10028,16,496,4,0,
10028,16,510,4,0,
10028,16,567,1,6,1
10028,16,567,1,23,1
10028,16,567,1,40,1
10028,16,590,4,0,
10028,17,53,4,0,
10028,17,73,1,20,
10028,17,75,1,16,
10028,17,76,4,0,
10028,17,92,4,0,
10028,17,94,4,0,
10028,17,104,4,0,
10028,17,109,1,1,3
10028,17,113,4,0,
10028,17,126,4,0,
10028,17,138,4,0,
10028,17,153,4,0,
10028,17,156,4,0,
10028,17,157,4,0,
10028,17,164,4,0,
10028,17,168,4,0,
10028,17,182,4,0,
10028,17,184,1,4,
10028,17,188,4,0,
10028,17,207,4,0,
10028,17,213,4,0,
10028,17,214,4,0,
10028,17,216,4,0,
10028,17,218,4,0,
10028,17,219,4,0,
10028,17,220,1,42,
10028,17,237,4,0,
10028,17,241,4,0,
10028,17,247,1,36,
10028,17,247,4,0,
10028,17,261,4,0,
10028,17,263,4,0,
10028,17,267,4,0,
10028,17,271,1,1,1
10028,17,310,1,1,2
10028,17,331,1,26,
10028,17,360,4,0,
10028,17,388,1,11,
10028,17,399,4,0,
10028,17,402,1,48,
10028,17,412,4,0,
10028,17,425,1,30,
10028,17,433,4,0,
10028,17,447,4,0,
10028,17,451,4,0,
10028,17,488,4,0,
10028,17,496,4,0,
10028,17,567,1,6,
10028,17,567,1,23,
10028,17,567,1,40,
10028,17,590,4,0,
10028,18,53,4,0,
10028,18,73,1,20,
10028,18,75,1,16,
10028,18,76,4,0,
10028,18,92,4,0,
10028,18,94,4,0,
10028,18,104,4,0,
10028,18,109,1,1,3
10028,18,113,4,0,
10028,18,126,4,0,
10028,18,138,4,0,
10028,18,153,4,0,
10028,18,156,4,0,
10028,18,157,4,0,
10028,18,164,4,0,
10028,18,168,4,0,
10028,18,182,4,0,
10028,18,184,1,4,
10028,18,188,4,0,
10028,18,207,4,0,
10028,18,213,4,0,
10028,18,214,4,0,
10028,18,216,4,0,
10028,18,218,4,0,
10028,18,219,4,0,
10028,18,220,1,42,
10028,18,237,4,0,
10028,18,241,4,0,
10028,18,247,1,36,
10028,18,247,4,0,
10028,18,261,4,0,
10028,18,263,4,0,
10028,18,267,4,0,
10028,18,271,1,1,1
10028,18,310,1,1,2
10028,18,331,1,26,
10028,18,360,4,0,
10028,18,388,1,11,
10028,18,399,4,0,
10028,18,402,1,48,
10028,18,412,4,0,
10028,18,425,1,30,
10028,18,433,4,0,
10028,18,447,4,0,
10028,18,451,4,0,
10028,18,488,4,0,
10028,18,496,4,0,
10028,18,567,1,6,
10028,18,567,1,23,
10028,18,567,1,40,
10028,18,590,4,0,
10029,15,50,2,0,
10029,15,53,4,0,
10029,15,73,1,20,
10029,15,75,1,16,
10029,15,76,4,0,
10029,15,92,4,0,
10029,15,94,4,0,
10029,15,104,4,0,
10029,15,109,1,1,3
10029,15,113,4,0,
10029,15,126,4,0,
10029,15,138,4,0,
10029,15,148,4,0,
10029,15,153,4,0,
10029,15,156,4,0,
10029,15,157,4,0,
10029,15,164,4,0,
10029,15,168,4,0,
10029,15,182,4,0,
10029,15,184,1,4,
10029,15,188,4,0,
10029,15,194,2,0,
10029,15,207,4,0,
10029,15,213,4,0,
10029,15,214,4,0,
10029,15,216,4,0,
10029,15,218,4,0,
10029,15,219,4,0,
10029,15,220,1,42,
10029,15,237,4,0,
10029,15,241,4,0,
10029,15,247,1,36,
10029,15,247,4,0,
10029,15,249,4,0,
10029,15,261,4,0,
10029,15,263,4,0,
10029,15,267,4,0,
10029,15,271,1,1,1
10029,15,310,1,1,2
10029,15,331,1,26,
10029,15,360,4,0,
10029,15,388,1,11,
10029,15,399,4,0,
10029,15,402,1,48,
10029,15,412,4,0,
10029,15,425,1,30,
10029,15,433,4,0,
10029,15,447,4,0,
10029,15,451,4,0,
10029,15,488,4,0,
10029,15,496,4,0,
10029,15,510,4,0,
10029,15,516,2,0,
10029,15,567,1,6,
10029,15,567,1,23,
10029,15,567,1,40,
10029,15,590,4,0,
10029,16,53,4,0,
10029,16,73,1,20,1
10029,16,75,1,16,1
10029,16,76,4,0,
10029,16,92,4,0,
10029,16,94,4,0,
10029,16,104,4,0,
10029,16,109,1,1,3
10029,16,113,4,0,
10029,16,126,4,0,
10029,16,138,4,0,
10029,16,148,4,0,
10029,16,153,4,0,
10029,16,156,4,0,
10029,16,157,4,0,
10029,16,164,4,0,
10029,16,168,4,0,
10029,16,180,3,0,
10029,16,182,4,0,
10029,16,184,1,4,1
10029,16,188,4,0,
10029,16,202,3,0,
10029,16,207,4,0,
10029,16,213,4,0,
10029,16,214,4,0,
10029,16,216,4,0,
10029,16,218,4,0,
10029,16,219,4,0,
10029,16,220,1,42,1
10029,16,220,3,0,
10029,16,235,3,0,
10029,16,237,4,0,
10029,16,241,4,0,
10029,16,247,1,36,1
10029,16,247,4,0,
10029,16,249,4,0,
10029,16,261,4,0,
10029,16,263,4,0,
10029,16,267,4,0,
10029,16,271,1,1,1
10029,16,271,3,0,
10029,16,272,3,0,
10029,16,277,3,0,
10029,16,285,3,0,
10029,16,290,4,0,
10029,16,310,1,1,2
10029,16,331,1,26,1
10029,16,360,4,0,
10029,16,388,1,11,1
10029,16,388,3,0,
10029,16,399,4,0,
10029,16,402,1,48,1
10029,16,402,3,0,
10029,16,412,4,0,
10029,16,425,1,30,1
10029,16,433,4,0,
10029,16,447,4,0,
10029,16,451,4,0,
10029,16,488,4,0,
10029,16,492,3,0,
10029,16,496,4,0,
10029,16,510,4,0,
10029,16,567,1,6,1
10029,16,567,1,23,1
10029,16,567,1,40,1
10029,16,590,4,0,
10029,17,53,4,0,
10029,17,73,1,20,
10029,17,75,1,16,
10029,17,76,4,0,
10029,17,92,4,0,
10029,17,94,4,0,
10029,17,104,4,0,
10029,17,109,1,1,3
10029,17,113,4,0,
10029,17,126,4,0,
10029,17,138,4,0,
10029,17,153,4,0,
10029,17,156,4,0,
10029,17,157,4,0,
10029,17,164,4,0,
10029,17,168,4,0,
10029,17,182,4,0,
10029,17,184,1,4,
10029,17,188,4,0,
10029,17,207,4,0,
10029,17,213,4,0,
10029,17,214,4,0,
10029,17,216,4,0,
10029,17,218,4,0,
10029,17,219,4,0,
10029,17,220,1,42,
10029,17,237,4,0,
10029,17,241,4,0,
10029,17,247,1,36,
10029,17,247,4,0,
10029,17,261,4,0,
10029,17,263,4,0,
10029,17,267,4,0,
10029,17,271,1,1,1
10029,17,310,1,1,2
10029,17,331,1,26,
10029,17,360,4,0,
10029,17,388,1,11,
10029,17,399,4,0,
10029,17,402,1,48,
10029,17,412,4,0,
10029,17,425,1,30,
10029,17,433,4,0,
10029,17,447,4,0,
10029,17,451,4,0,
10029,17,488,4,0,
10029,17,496,4,0,
10029,17,567,1,6,
10029,17,567,1,23,
10029,17,567,1,40,
10029,17,590,4,0,
10029,18,53,4,0,
10029,18,73,1,20,
10029,18,75,1,16,
10029,18,76,4,0,
10029,18,92,4,0,
10029,18,94,4,0,
10029,18,104,4,0,
10029,18,109,1,1,3
10029,18,113,4,0,
10029,18,126,4,0,
10029,18,138,4,0,
10029,18,153,4,0,
10029,18,156,4,0,
10029,18,157,4,0,
10029,18,164,4,0,
10029,18,168,4,0,
10029,18,182,4,0,
10029,18,184,1,4,
10029,18,188,4,0,
10029,18,207,4,0,
10029,18,213,4,0,
10029,18,214,4,0,
10029,18,216,4,0,
10029,18,218,4,0,
10029,18,219,4,0,
10029,18,220,1,42,
10029,18,237,4,0,
10029,18,241,4,0,
10029,18,247,1,36,
10029,18,247,4,0,
10029,18,261,4,0,
10029,18,263,4,0,
10029,18,267,4,0,
10029,18,271,1,1,1
10029,18,310,1,1,2
10029,18,331,1,26,
10029,18,360,4,0,
10029,18,388,1,11,
10029,18,399,4,0,
10029,18,402,1,48,
10029,18,412,4,0,
10029,18,425,1,30,
10029,18,433,4,0,
10029,18,447,4,0,
10029,18,451,4,0,
10029,18,488,4,0,
10029,18,496,4,0,
10029,18,567,1,6,
10029,18,567,1,23,
10029,18,567,1,40,
10029,18,590,4,0,
10030,15,53,4,0,
10030,15,63,4,0,
10030,15,73,1,20,
10030,15,75,1,16,
10030,15,76,4,0,
10030,15,92,4,0,
10030,15,94,4,0,
10030,15,104,4,0,
10030,15,109,1,1,5
10030,15,113,4,0,
10030,15,126,4,0,
10030,15,138,4,0,
10030,15,148,4,0,
10030,15,153,1,1,1
10030,15,153,1,75,
10030,15,153,4,0,
10030,15,156,4,0,
10030,15,157,4,0,
10030,15,164,4,0,
10030,15,168,4,0,
10030,15,182,4,0,
10030,15,184,1,4,
10030,15,188,4,0,
10030,15,207,4,0,
10030,15,213,4,0,
10030,15,214,4,0,
10030,15,216,4,0,
10030,15,218,4,0,
10030,15,219,4,0,
10030,15,220,1,42,
10030,15,237,4,0,
10030,15,241,4,0,
10030,15,247,1,36,
10030,15,247,1,70,
10030,15,247,4,0,
10030,15,249,4,0,
10030,15,261,4,0,
10030,15,263,4,0,
10030,15,267,4,0,
10030,15,271,1,1,3
10030,15,310,1,1,4
10030,15,331,1,26,
10030,15,360,4,0,
10030,15,388,1,11,
10030,15,399,4,0,
10030,15,402,1,48,
10030,15,411,4,0,
10030,15,412,4,0,
10030,15,416,4,0,
10030,15,425,1,30,
10030,15,433,4,0,
10030,15,447,4,0,
10030,15,451,4,0,
10030,15,488,4,0,
10030,15,496,4,0,
10030,15,510,4,0,
10030,15,566,1,1,2
10030,15,566,1,57,
10030,15,567,1,6,
10030,15,567,1,23,
10030,15,567,1,40,
10030,15,567,1,63,
10030,15,590,4,0,
10030,16,53,4,0,
10030,16,63,4,0,
10030,16,73,1,20,1
10030,16,75,1,16,1
10030,16,76,4,0,
10030,16,92,4,0,
10030,16,94,4,0,
10030,16,104,4,0,
10030,16,109,1,1,5
10030,16,113,4,0,
10030,16,126,4,0,
10030,16,138,4,0,
10030,16,148,4,0,
10030,16,153,1,1,1
10030,16,153,1,75,1
10030,16,153,4,0,
10030,16,156,4,0,
10030,16,157,4,0,
10030,16,164,4,0,
10030,16,168,4,0,
10030,16,180,3,0,
10030,16,182,4,0,
10030,16,184,1,4,1
10030,16,188,4,0,
10030,16,202,3,0,
10030,16,207,4,0,
10030,16,213,4,0,
10030,16,214,4,0,
10030,16,216,4,0,
10030,16,218,4,0,
10030,16,219,4,0,
10030,16,220,1,42,1
10030,16,220,3,0,
10030,16,235,3,0,
10030,16,237,4,0,
10030,16,241,4,0,
10030,16,247,1,36,1
10030,16,247,1,70,1
10030,16,247,4,0,
10030,16,249,4,0,
10030,16,261,4,0,
10030,16,263,4,0,
10030,16,267,4,0,
10030,16,271,1,1,3
10030,16,271,3,0,
10030,16,272,3,0,
10030,16,277,3,0,
10030,16,285,3,0,
10030,16,290,4,0,
10030,16,310,1,1,4
10030,16,331,1,26,1
10030,16,360,4,0,
10030,16,388,1,11,1
10030,16,388,3,0,
10030,16,399,4,0,
10030,16,402,1,48,1
10030,16,402,3,0,
10030,16,411,4,0,
10030,16,412,4,0,
10030,16,416,4,0,
10030,16,425,1,30,1
10030,16,433,4,0,
10030,16,447,4,0,
10030,16,451,4,0,
10030,16,488,4,0,
10030,16,492,3,0,
10030,16,496,4,0,
10030,16,510,4,0,
10030,16,566,1,1,2
10030,16,566,1,57,1
10030,16,567,1,6,1
10030,16,567,1,23,1
10030,16,567,1,40,1
10030,16,567,1,63,1
10030,16,590,4,0,
10030,17,53,4,0,
10030,17,63,4,0,
10030,17,73,1,20,
10030,17,75,1,16,
10030,17,76,4,0,
10030,17,92,4,0,
10030,17,94,4,0,
10030,17,104,4,0,
10030,17,109,1,1,5
10030,17,113,4,0,
10030,17,126,4,0,
10030,17,138,4,0,
10030,17,153,1,1,1
10030,17,153,1,70,
10030,17,153,4,0,
10030,17,156,4,0,
10030,17,157,4,0,
10030,17,164,4,0,
10030,17,168,4,0,
10030,17,182,4,0,
10030,17,184,1,1,6
10030,17,184,1,4,
10030,17,188,4,0,
10030,17,207,4,0,
10030,17,213,4,0,
10030,17,214,4,0,
10030,17,216,4,0,
10030,17,218,4,0,
10030,17,219,4,0,
10030,17,220,1,42,
10030,17,237,4,0,
10030,17,241,4,0,
10030,17,247,1,36,
10030,17,247,4,0,
10030,17,261,4,0,
10030,17,263,4,0,
10030,17,267,4,0,
10030,17,271,1,1,3
10030,17,310,1,1,4
10030,17,331,1,26,
10030,17,360,4,0,
10030,17,388,1,11,
10030,17,399,4,0,
10030,17,402,1,48,
10030,17,411,4,0,
10030,17,412,4,0,
10030,17,416,4,0,
10030,17,425,1,30,
10030,17,433,4,0,
10030,17,447,4,0,
10030,17,451,4,0,
10030,17,488,4,0,
10030,17,496,4,0,
10030,17,566,1,1,2
10030,17,566,1,57,
10030,17,567,1,6,
10030,17,567,1,23,
10030,17,567,1,40,
10030,17,567,1,63,
10030,17,590,4,0,
10030,18,53,4,0,
10030,18,63,4,0,
10030,18,73,1,20,
10030,18,75,1,16,
10030,18,76,4,0,
10030,18,92,4,0,
10030,18,94,4,0,
10030,18,104,4,0,
10030,18,109,1,1,5
10030,18,113,4,0,
10030,18,126,4,0,
10030,18,138,4,0,
10030,18,153,1,1,1
10030,18,153,1,70,
10030,18,153,4,0,
10030,18,156,4,0,
10030,18,157,4,0,
10030,18,164,4,0,
10030,18,168,4,0,
10030,18,182,4,0,
10030,18,184,1,1,6
10030,18,184,1,4,
10030,18,188,4,0,
10030,18,207,4,0,
10030,18,213,4,0,
10030,18,214,4,0,
10030,18,216,4,0,
10030,18,218,4,0,
10030,18,219,4,0,
10030,18,220,1,42,
10030,18,237,4,0,
10030,18,241,4,0,
10030,18,247,1,36,
10030,18,247,4,0,
10030,18,261,4,0,
10030,18,263,4,0,
10030,18,267,4,0,
10030,18,271,1,1,3
10030,18,310,1,1,4
10030,18,331,1,26,
10030,18,360,4,0,
10030,18,388,1,11,
10030,18,399,4,0,
10030,18,402,1,48,
10030,18,411,4,0,
10030,18,412,4,0,
10030,18,416,4,0,
10030,18,425,1,30,
10030,18,433,4,0,
10030,18,447,4,0,
10030,18,451,4,0,
10030,18,488,4,0,
10030,18,496,4,0,
10030,18,566,1,1,2
10030,18,566,1,57,
10030,18,567,1,6,
10030,18,567,1,23,
10030,18,567,1,40,
10030,18,567,1,63,
10030,18,590,4,0,
10031,15,53,4,0,
10031,15,63,4,0,
10031,15,73,1,20,
10031,15,75,1,16,
10031,15,76,4,0,
10031,15,92,4,0,
10031,15,94,4,0,
10031,15,104,4,0,
10031,15,109,1,1,5
10031,15,113,4,0,
10031,15,126,4,0,
10031,15,138,4,0,
10031,15,148,4,0,
10031,15,153,1,1,1
10031,15,153,1,75,
10031,15,153,4,0,
10031,15,156,4,0,
10031,15,157,4,0,
10031,15,164,4,0,
10031,15,168,4,0,
10031,15,182,4,0,
10031,15,184,1,4,
10031,15,188,4,0,
10031,15,207,4,0,
10031,15,213,4,0,
10031,15,214,4,0,
10031,15,216,4,0,
10031,15,218,4,0,
10031,15,219,4,0,
10031,15,220,1,42,
10031,15,237,4,0,
10031,15,241,4,0,
10031,15,247,1,36,
10031,15,247,1,70,
10031,15,247,4,0,
10031,15,249,4,0,
10031,15,261,4,0,
10031,15,263,4,0,
10031,15,267,4,0,
10031,15,271,1,1,3
10031,15,310,1,1,4
10031,15,331,1,26,
10031,15,360,4,0,
10031,15,388,1,11,
10031,15,399,4,0,
10031,15,402,1,48,
10031,15,411,4,0,
10031,15,412,4,0,
10031,15,416,4,0,
10031,15,425,1,30,
10031,15,433,4,0,
10031,15,447,4,0,
10031,15,451,4,0,
10031,15,488,4,0,
10031,15,496,4,0,
10031,15,510,4,0,
10031,15,566,1,1,2
10031,15,566,1,57,
10031,15,567,1,6,
10031,15,567,1,23,
10031,15,567,1,40,
10031,15,567,1,63,
10031,15,590,4,0,
10031,16,53,4,0,
10031,16,63,4,0,
10031,16,73,1,20,1
10031,16,75,1,16,1
10031,16,76,4,0,
10031,16,92,4,0,
10031,16,94,4,0,
10031,16,104,4,0,
10031,16,109,1,1,5
10031,16,113,4,0,
10031,16,126,4,0,
10031,16,138,4,0,
10031,16,148,4,0,
10031,16,153,1,1,1
10031,16,153,1,75,1
10031,16,153,4,0,
10031,16,156,4,0,
10031,16,157,4,0,
10031,16,164,4,0,
10031,16,168,4,0,
10031,16,180,3,0,
10031,16,182,4,0,
10031,16,184,1,4,1
10031,16,188,4,0,
10031,16,202,3,0,
10031,16,207,4,0,
10031,16,213,4,0,
10031,16,214,4,0,
10031,16,216,4,0,
10031,16,218,4,0,
10031,16,219,4,0,
10031,16,220,1,42,1
10031,16,220,3,0,
10031,16,235,3,0,
10031,16,237,4,0,
10031,16,241,4,0,
10031,16,247,1,36,1
10031,16,247,1,70,1
10031,16,247,4,0,
10031,16,249,4,0,
10031,16,261,4,0,
10031,16,263,4,0,
10031,16,267,4,0,
10031,16,271,1,1,3
10031,16,271,3,0,
10031,16,272,3,0,
10031,16,277,3,0,
10031,16,285,3,0,
10031,16,290,4,0,
10031,16,310,1,1,4
10031,16,331,1,26,1
10031,16,360,4,0,
10031,16,388,1,11,1
10031,16,388,3,0,
10031,16,399,4,0,
10031,16,402,1,48,1
10031,16,402,3,0,
10031,16,411,4,0,
10031,16,412,4,0,
10031,16,416,4,0,
10031,16,425,1,30,1
10031,16,433,4,0,
10031,16,447,4,0,
10031,16,451,4,0,
10031,16,488,4,0,
10031,16,492,3,0,
10031,16,496,4,0,
10031,16,510,4,0,
10031,16,566,1,1,2
10031,16,566,1,57,1
10031,16,567,1,6,1
10031,16,567,1,23,1
10031,16,567,1,40,1
10031,16,567,1,63,1
10031,16,590,4,0,
10031,17,53,4,0,
10031,17,63,4,0,
10031,17,73,1,20,
10031,17,75,1,16,
10031,17,76,4,0,
10031,17,92,4,0,
10031,17,94,4,0,
10031,17,104,4,0,
10031,17,109,1,1,5
10031,17,113,4,0,
10031,17,126,4,0,
10031,17,138,4,0,
10031,17,153,1,1,1
10031,17,153,1,70,
10031,17,153,4,0,
10031,17,156,4,0,
10031,17,157,4,0,
10031,17,164,4,0,
10031,17,168,4,0,
10031,17,182,4,0,
10031,17,184,1,1,6
10031,17,184,1,4,
10031,17,188,4,0,
10031,17,207,4,0,
10031,17,213,4,0,
10031,17,214,4,0,
10031,17,216,4,0,
10031,17,218,4,0,
10031,17,219,4,0,
10031,17,220,1,42,
10031,17,237,4,0,
10031,17,241,4,0,
10031,17,247,1,36,
10031,17,247,4,0,
10031,17,261,4,0,
10031,17,263,4,0,
10031,17,267,4,0,
10031,17,271,1,1,3
10031,17,310,1,1,4
10031,17,331,1,26,
10031,17,360,4,0,
10031,17,388,1,11,
10031,17,399,4,0,
10031,17,402,1,48,
10031,17,411,4,0,
10031,17,412,4,0,
10031,17,416,4,0,
10031,17,425,1,30,
10031,17,433,4,0,
10031,17,447,4,0,
10031,17,451,4,0,
10031,17,488,4,0,
10031,17,496,4,0,
10031,17,566,1,1,2
10031,17,566,1,57,
10031,17,567,1,6,
10031,17,567,1,23,
10031,17,567,1,40,
10031,17,567,1,63,
10031,17,590,4,0,
10031,18,53,4,0,
10031,18,63,4,0,
10031,18,73,1,20,
10031,18,75,1,16,
10031,18,76,4,0,
10031,18,92,4,0,
10031,18,94,4,0,
10031,18,104,4,0,
10031,18,109,1,1,5
10031,18,113,4,0,
10031,18,126,4,0,
10031,18,138,4,0,
10031,18,153,1,1,1
10031,18,153,1,70,
10031,18,153,4,0,
10031,18,156,4,0,
10031,18,157,4,0,
10031,18,164,4,0,
10031,18,168,4,0,
10031,18,182,4,0,
10031,18,184,1,1,6
10031,18,184,1,4,
10031,18,188,4,0,
10031,18,207,4,0,
10031,18,213,4,0,
10031,18,214,4,0,
10031,18,216,4,0,
10031,18,218,4,0,
10031,18,219,4,0,
10031,18,220,1,42,
10031,18,237,4,0,
10031,18,241,4,0,
10031,18,247,1,36,
10031,18,247,4,0,
10031,18,261,4,0,
10031,18,263,4,0,
10031,18,267,4,0,
10031,18,271,1,1,3
10031,18,310,1,1,4
10031,18,331,1,26,
10031,18,360,4,0,
10031,18,388,1,11,
10031,18,399,4,0,
10031,18,402,1,48,
10031,18,411,4,0,
10031,18,412,4,0,
10031,18,416,4,0,
10031,18,425,1,30,
10031,18,433,4,0,
10031,18,447,4,0,
10031,18,451,4,0,
10031,18,488,4,0,
10031,18,496,4,0,
10031,18,566,1,1,2
10031,18,566,1,57,
10031,18,567,1,6,
10031,18,567,1,23,
10031,18,567,1,40,
10031,18,567,1,63,
10031,18,590,4,0,
10032,15,53,4,0,
10032,15,63,4,0,
10032,15,73,1,20,
10032,15,75,1,16,
10032,15,76,4,0,
10032,15,92,4,0,
10032,15,94,4,0,
10032,15,104,4,0,
10032,15,109,1,1,5
10032,15,113,4,0,
10032,15,126,4,0,
10032,15,138,4,0,
10032,15,148,4,0,
10032,15,153,1,1,1
10032,15,153,1,75,
10032,15,153,4,0,
10032,15,156,4,0,
10032,15,157,4,0,
10032,15,164,4,0,
10032,15,168,4,0,
10032,15,182,4,0,
10032,15,184,1,4,
10032,15,188,4,0,
10032,15,207,4,0,
10032,15,213,4,0,
10032,15,214,4,0,
10032,15,216,4,0,
10032,15,218,4,0,
10032,15,219,4,0,
10032,15,220,1,42,
10032,15,237,4,0,
10032,15,241,4,0,
10032,15,247,1,36,
10032,15,247,1,70,
10032,15,247,4,0,
10032,15,249,4,0,
10032,15,261,4,0,
10032,15,263,4,0,
10032,15,267,4,0,
10032,15,271,1,1,3
10032,15,310,1,1,4
10032,15,331,1,26,
10032,15,360,4,0,
10032,15,388,1,11,
10032,15,399,4,0,
10032,15,402,1,48,
10032,15,411,4,0,
10032,15,412,4,0,
10032,15,416,4,0,
10032,15,425,1,30,
10032,15,433,4,0,
10032,15,447,4,0,
10032,15,451,4,0,
10032,15,488,4,0,
10032,15,496,4,0,
10032,15,510,4,0,
10032,15,566,1,1,2
10032,15,566,1,57,
10032,15,567,1,6,
10032,15,567,1,23,
10032,15,567,1,40,
10032,15,567,1,63,
10032,15,590,4,0,
10032,16,53,4,0,
10032,16,63,4,0,
10032,16,73,1,20,1
10032,16,75,1,16,1
10032,16,76,4,0,
10032,16,92,4,0,
10032,16,94,4,0,
10032,16,104,4,0,
10032,16,109,1,1,5
10032,16,113,4,0,
10032,16,126,4,0,
10032,16,138,4,0,
10032,16,148,4,0,
10032,16,153,1,1,1
10032,16,153,1,75,1
10032,16,153,4,0,
10032,16,156,4,0,
10032,16,157,4,0,
10032,16,164,4,0,
10032,16,168,4,0,
10032,16,180,3,0,
10032,16,182,4,0,
10032,16,184,1,4,1
10032,16,188,4,0,
10032,16,202,3,0,
10032,16,207,4,0,
10032,16,213,4,0,
10032,16,214,4,0,
10032,16,216,4,0,
10032,16,218,4,0,
10032,16,219,4,0,
10032,16,220,1,42,1
10032,16,220,3,0,
10032,16,235,3,0,
10032,16,237,4,0,
10032,16,241,4,0,
10032,16,247,1,36,1
10032,16,247,1,70,1
10032,16,247,4,0,
10032,16,249,4,0,
10032,16,261,4,0,
10032,16,263,4,0,
10032,16,267,4,0,
10032,16,271,1,1,3
10032,16,271,3,0,
10032,16,272,3,0,
10032,16,277,3,0,
10032,16,285,3,0,
10032,16,290,4,0,
10032,16,310,1,1,4
10032,16,331,1,26,1
10032,16,360,4,0,
10032,16,388,1,11,1
10032,16,388,3,0,
10032,16,399,4,0,
10032,16,402,1,48,1
10032,16,402,3,0,
10032,16,411,4,0,
10032,16,412,4,0,
10032,16,416,4,0,
10032,16,425,1,30,1
10032,16,433,4,0,
10032,16,447,4,0,
10032,16,451,4,0,
10032,16,488,4,0,
10032,16,492,3,0,
10032,16,496,4,0,
10032,16,510,4,0,
10032,16,566,1,1,2
10032,16,566,1,57,1
10032,16,567,1,6,1
10032,16,567,1,23,1
10032,16,567,1,40,1
10032,16,567,1,63,1
10032,16,590,4,0,
10032,17,53,4,0,
10032,17,63,4,0,
10032,17,73,1,20,
10032,17,75,1,16,
10032,17,76,4,0,
10032,17,92,4,0,
10032,17,94,4,0,
10032,17,104,4,0,
10032,17,109,1,1,5
10032,17,113,4,0,
10032,17,126,4,0,
10032,17,138,4,0,
10032,17,153,1,1,1
10032,17,153,1,70,
10032,17,153,4,0,
10032,17,156,4,0,
10032,17,157,4,0,
10032,17,164,4,0,
10032,17,168,4,0,
10032,17,182,4,0,
10032,17,184,1,1,6
10032,17,184,1,4,
10032,17,188,4,0,
10032,17,207,4,0,
10032,17,213,4,0,
10032,17,214,4,0,
10032,17,216,4,0,
10032,17,218,4,0,
10032,17,219,4,0,
10032,17,220,1,42,
10032,17,237,4,0,
10032,17,241,4,0,
10032,17,247,1,36,
10032,17,247,4,0,
10032,17,261,4,0,
10032,17,263,4,0,
10032,17,267,4,0,
10032,17,271,1,1,3
10032,17,310,1,1,4
10032,17,331,1,26,
10032,17,360,4,0,
10032,17,388,1,11,
10032,17,399,4,0,
10032,17,402,1,48,
10032,17,411,4,0,
10032,17,412,4,0,
10032,17,416,4,0,
10032,17,425,1,30,
10032,17,433,4,0,
10032,17,447,4,0,
10032,17,451,4,0,
10032,17,488,4,0,
10032,17,496,4,0,
10032,17,566,1,1,2
10032,17,566,1,57,
10032,17,567,1,6,
10032,17,567,1,23,
10032,17,567,1,40,
10032,17,567,1,63,
10032,17,590,4,0,
10032,18,53,4,0,
10032,18,63,4,0,
10032,18,73,1,20,
10032,18,75,1,16,
10032,18,76,4,0,
10032,18,92,4,0,
10032,18,94,4,0,
10032,18,104,4,0,
10032,18,109,1,1,5
10032,18,113,4,0,
10032,18,126,4,0,
10032,18,138,4,0,
10032,18,153,1,1,1
10032,18,153,1,70,
10032,18,153,4,0,
10032,18,156,4,0,
10032,18,157,4,0,
10032,18,164,4,0,
10032,18,168,4,0,
10032,18,182,4,0,
10032,18,184,1,1,6
10032,18,184,1,4,
10032,18,188,4,0,
10032,18,207,4,0,
10032,18,213,4,0,
10032,18,214,4,0,
10032,18,216,4,0,
10032,18,218,4,0,
10032,18,219,4,0,
10032,18,220,1,42,
10032,18,237,4,0,
10032,18,241,4,0,
10032,18,247,1,36,
10032,18,247,4,0,
10032,18,261,4,0,
10032,18,263,4,0,
10032,18,267,4,0,
10032,18,271,1,1,3
10032,18,310,1,1,4
10032,18,331,1,26,
10032,18,360,4,0,
10032,18,388,1,11,
10032,18,399,4,0,
10032,18,402,1,48,
10032,18,411,4,0,
10032,18,412,4,0,
10032,18,416,4,0,
10032,18,425,1,30,
10032,18,433,4,0,
10032,18,447,4,0,
10032,18,451,4,0,
10032,18,488,4,0,
10032,18,496,4,0,
10032,18,566,1,1,2
10032,18,566,1,57,
10032,18,567,1,6,
10032,18,567,1,23,
10032,18,567,1,40,
10032,18,567,1,63,
10032,18,590,4,0,
10033,15,14,4,0,
10033,15,15,4,0,
10033,15,22,1,1,4
10033,15,22,1,9,
10033,15,33,1,1,1
10033,15,36,1,15,
10033,15,38,1,31,
10033,15,45,1,1,2
10033,15,45,1,3,
10033,15,46,4,0,
10033,15,63,4,0,
10033,15,70,4,0,
10033,15,73,1,1,3
10033,15,73,1,7,
10033,15,74,1,28,
10033,15,75,1,20,
10033,15,76,1,53,
10033,15,76,4,0,
10033,15,77,1,13,1
10033,15,79,1,13,2
10033,15,80,1,32,
10033,15,89,4,0,
10033,15,92,4,0,
10033,15,104,4,0,
10033,15,113,4,0,
10033,15,148,4,0,
10033,15,156,4,0,
10033,15,164,4,0,
10033,15,182,4,0,
10033,15,188,4,0,
10033,15,207,4,0,
10033,15,213,4,0,
10033,15,214,4,0,
10033,15,216,4,0,
10033,15,218,4,0,
10033,15,219,4,0,
10033,15,230,1,23,
10033,15,235,1,45,
10033,15,237,4,0,
10033,15,241,4,0,
10033,15,249,4,0,
10033,15,263,4,0,
10033,15,267,4,0,
10033,15,338,3,0,
10033,15,388,1,39,
10033,15,412,4,0,
10033,15,416,4,0,
10033,15,447,4,0,
10033,15,474,4,0,
10033,15,496,4,0,
10033,15,497,4,0,
10033,15,520,3,0,
10033,15,523,4,0,
10033,15,572,1,50,
10033,15,590,4,0,
10033,16,14,4,0,
10033,16,15,4,0,
10033,16,20,3,0,
10033,16,22,1,1,4
10033,16,22,1,9,1
10033,16,33,1,1,1
10033,16,36,1,15,1
10033,16,38,1,31,1
10033,16,45,1,1,2
10033,16,45,1,3,1
10033,16,46,4,0,
10033,16,63,4,0,
10033,16,70,4,0,
10033,16,73,1,1,3
10033,16,73,1,7,1
10033,16,74,1,28,1
10033,16,75,1,20,1
10033,16,76,1,53,1
10033,16,76,4,0,
10033,16,77,1,13,1
10033,16,79,1,13,2
10033,16,80,1,32,1
10033,16,89,4,0,
10033,16,92,4,0,
10033,16,104,4,0,
10033,16,113,4,0,
10033,16,148,4,0,
10033,16,156,4,0,
10033,16,164,4,0,
10033,16,173,3,0,
10033,16,182,4,0,
10033,16,188,4,0,
10033,16,200,3,0,
10033,16,202,3,0,
10033,16,207,4,0,
10033,16,213,4,0,
10033,16,214,4,0,
10033,16,216,4,0,
10033,16,218,4,0,
10033,16,219,4,0,
10033,16,230,1,23,1
10033,16,235,1,45,1
10033,16,235,3,0,
10033,16,237,4,0,
10033,16,241,4,0,
10033,16,249,4,0,
10033,16,263,4,0,
10033,16,267,4,0,
10033,16,282,3,0,
10033,16,290,4,0,
10033,16,335,3,0,
10033,16,338,3,0,
10033,16,388,1,39,1
10033,16,388,3,0,
10033,16,402,3,0,
10033,16,412,4,0,
10033,16,416,4,0,
10033,16,447,4,0,
10033,16,474,4,0,
10033,16,496,4,0,
10033,16,497,4,0,
10033,16,520,3,0,
10033,16,523,4,0,
10033,16,572,1,50,1
10033,16,590,4,0,
10033,17,14,4,0,
10033,17,22,1,1,5
10033,17,22,1,9,
10033,17,33,1,1,2
10033,17,36,1,15,
10033,17,38,1,31,
10033,17,45,1,1,3
10033,17,45,1,3,
10033,17,46,4,0,
10033,17,63,4,0,
10033,17,73,1,1,4
10033,17,73,1,7,
10033,17,74,1,28,
10033,17,75,1,20,
10033,17,76,1,53,
10033,17,76,4,0,
10033,17,77,1,13,1
10033,17,79,1,13,2
10033,17,80,1,0,
10033,17,80,1,1,1
10033,17,89,4,0,
10033,17,92,4,0,
10033,17,104,4,0,
10033,17,113,4,0,
10033,17,156,4,0,
10033,17,164,4,0,
10033,17,182,4,0,
10033,17,188,4,0,
10033,17,207,4,0,
10033,17,213,4,0,
10033,17,214,4,0,
10033,17,216,4,0,
10033,17,218,4,0,
10033,17,219,4,0,
10033,17,230,1,23,
10033,17,235,1,45,
10033,17,237,4,0,
10033,17,241,4,0,
10033,17,263,4,0,
10033,17,267,4,0,
10033,17,338,3,0,
10033,17,388,1,39,
10033,17,412,4,0,
10033,17,416,4,0,
10033,17,447,4,0,
10033,17,474,4,0,
10033,17,496,4,0,
10033,17,497,4,0,
10033,17,520,3,0,
10033,17,523,4,0,
10033,17,526,4,0,
10033,17,572,1,50,
10033,17,590,4,0,
10033,18,14,4,0,
10033,18,22,1,1,5
10033,18,22,1,9,
10033,18,33,1,1,2
10033,18,36,1,15,
10033,18,38,1,31,
10033,18,45,1,1,3
10033,18,45,1,3,
10033,18,46,4,0,
10033,18,63,4,0,
10033,18,73,1,1,4
10033,18,73,1,7,
10033,18,74,1,28,
10033,18,75,1,20,
10033,18,76,1,53,
10033,18,76,4,0,
10033,18,77,1,13,1
10033,18,79,1,13,2
10033,18,80,1,0,
10033,18,80,1,1,1
10033,18,89,4,0,
10033,18,92,4,0,
10033,18,104,4,0,
10033,18,113,4,0,
10033,18,156,4,0,
10033,18,164,4,0,
10033,18,182,4,0,
10033,18,188,4,0,
10033,18,207,4,0,
10033,18,213,4,0,
10033,18,214,4,0,
10033,18,216,4,0,
10033,18,218,4,0,
10033,18,219,4,0,
10033,18,230,1,23,
10033,18,235,1,45,
10033,18,237,4,0,
10033,18,241,4,0,
10033,18,263,4,0,
10033,18,267,4,0,
10033,18,338,3,0,
10033,18,388,1,39,
10033,18,412,4,0,
10033,18,416,4,0,
10033,18,447,4,0,
10033,18,474,4,0,
10033,18,496,4,0,
10033,18,497,4,0,
10033,18,520,3,0,
10033,18,523,4,0,
10033,18,526,4,0,
10033,18,572,1,50,
10033,18,590,4,0,
10034,15,10,1,1,6
10034,15,14,4,0,
10034,15,15,4,0,
10034,15,17,1,36,
10034,15,19,4,0,
10034,15,45,1,1,7
10034,15,46,4,0,
10034,15,52,1,1,8
10034,15,52,1,7,
10034,15,53,1,47,
10034,15,53,4,0,
10034,15,63,4,0,
10034,15,70,4,0,
10034,15,76,4,0,
10034,15,82,1,17,
10034,15,83,1,56,
10034,15,89,4,0,
10034,15,91,4,0,
10034,15,92,4,0,
10034,15,104,4,0,
10034,15,108,1,1,9
10034,15,108,1,10,
10034,15,126,4,0,
10034,15,156,4,0,
10034,15,157,4,0,
10034,15,163,1,41,
10034,15,164,4,0,
10034,15,182,4,0,
10034,15,184,1,21,
10034,15,207,4,0,
10034,15,211,4,0,
10034,15,213,4,0,
10034,15,214,4,0,
10034,15,216,4,0,
10034,15,218,4,0,
10034,15,237,4,0,
10034,15,241,4,0,
10034,15,249,4,0,
10034,15,257,1,1,2
10034,15,257,1,71,
10034,15,261,4,0,
10034,15,263,4,0,
10034,15,280,4,0,
10034,15,307,3,0,
10034,15,315,4,0,
10034,15,317,4,0,
10034,15,332,4,0,
10034,15,337,1,1,3
10034,15,337,4,0,
10034,15,355,4,0,
10034,15,374,4,0,
10034,15,394,1,1,1
10034,15,394,1,77,
10034,15,403,1,1,5
10034,15,411,4,0,
10034,15,416,4,0,
10034,15,421,1,1,4
10034,15,421,4,0,
10034,15,424,1,28,
10034,15,468,4,0,
10034,15,481,1,32,
10034,15,488,4,0,
10034,15,496,4,0,
10034,15,497,4,0,
10034,15,507,4,0,
10034,15,510,4,0,
10034,15,517,1,62,
10034,15,519,3,0,
10034,15,523,4,0,
10034,15,525,4,0,
10034,15,590,4,0,
10034,15,612,4,0,
10034,16,7,3,0,
10034,16,9,3,0,
10034,16,10,1,1,6
10034,16,14,4,0,
10034,16,15,4,0,
10034,16,17,1,36,1
10034,16,19,4,0,
10034,16,45,1,1,7
10034,16,46,4,0,
10034,16,52,1,1,8
10034,16,52,1,7,1
10034,16,53,1,47,1
10034,16,53,4,0,
10034,16,63,4,0,
10034,16,70,4,0,
10034,16,76,4,0,
10034,16,82,1,17,1
10034,16,83,1,56,1
10034,16,89,4,0,
10034,16,91,4,0,
10034,16,92,4,0,
10034,16,104,4,0,
10034,16,108,1,1,9
10034,16,108,1,10,1
10034,16,126,4,0,
10034,16,156,4,0,
10034,16,157,4,0,
10034,16,163,1,41,1
10034,16,164,4,0,
10034,16,173,3,0,
10034,16,182,4,0,
10034,16,184,1,21,1
10034,16,200,3,0,
10034,16,207,4,0,
10034,16,211,4,0,
10034,16,213,4,0,
10034,16,214,4,0,
10034,16,216,4,0,
10034,16,218,4,0,
10034,16,231,3,0,
10034,16,237,4,0,
10034,16,241,4,0,
10034,16,249,4,0,
10034,16,257,1,1,2
10034,16,257,1,71,1
10034,16,257,3,0,
10034,16,261,4,0,
10034,16,263,4,0,
10034,16,264,3,0,
10034,16,280,4,0,
10034,16,290,4,0,
10034,16,307,3,0,
10034,16,315,4,0,
10034,16,317,4,0,
10034,16,332,4,0,
10034,16,337,1,1,3
10034,16,337,4,0,
10034,16,355,4,0,
10034,16,366,3,0,
10034,16,374,4,0,
10034,16,394,1,1,1
10034,16,394,1,77,1
10034,16,403,1,1,5
10034,16,406,3,0,
10034,16,411,4,0,
10034,16,416,4,0,
10034,16,421,1,1,4
10034,16,421,4,0,
10034,16,424,1,28,1
10034,16,468,4,0,
10034,16,481,1,32,1
10034,16,488,4,0,
10034,16,496,4,0,
10034,16,497,4,0,
10034,16,507,4,0,
10034,16,510,4,0,
10034,16,517,1,62,1
10034,16,519,3,0,
10034,16,523,4,0,
10034,16,525,4,0,
10034,16,590,4,0,
10034,16,612,4,0,
10034,17,10,1,1,7
10034,17,14,4,0,
10034,17,17,1,0,
10034,17,17,1,1,1
10034,17,19,4,0,
10034,17,45,1,1,8
10034,17,46,4,0,
10034,17,52,1,1,9
10034,17,52,1,7,
10034,17,53,1,47,
10034,17,53,4,0,
10034,17,63,4,0,
10034,17,76,4,0,
10034,17,82,1,17,
10034,17,83,1,56,
10034,17,89,4,0,
10034,17,92,4,0,
10034,17,104,4,0,
10034,17,108,1,10,
10034,17,126,4,0,
10034,17,156,4,0,
10034,17,157,4,0,
10034,17,163,1,41,
10034,17,164,4,0,
10034,17,182,4,0,
10034,17,184,1,21,
10034,17,207,4,0,
10034,17,211,4,0,
10034,17,213,4,0,
10034,17,214,4,0,
10034,17,216,4,0,
10034,17,218,4,0,
10034,17,237,4,0,
10034,17,241,4,0,
10034,17,257,1,1,3
10034,17,257,1,71,
10034,17,261,4,0,
10034,17,263,4,0,
10034,17,280,4,0,
10034,17,307,3,0,
10034,17,315,4,0,
10034,17,317,4,0,
10034,17,332,4,0,
10034,17,337,1,1,4
10034,17,337,4,0,
10034,17,355,4,0,
10034,17,374,4,0,
10034,17,394,1,1,2
10034,17,394,1,77,
10034,17,403,1,1,6
10034,17,411,4,0,
10034,17,416,4,0,
10034,17,421,1,1,5
10034,17,421,4,0,
10034,17,424,1,28,
10034,17,481,1,32,
10034,17,488,4,0,
10034,17,496,4,0,
10034,17,497,4,0,
10034,17,507,4,0,
10034,17,517,1,62,
10034,17,519,3,0,
10034,17,523,4,0,
10034,17,525,4,0,
10034,17,526,4,0,
10034,17,590,4,0,
10034,17,693,4,0,
10034,18,10,1,1,7
10034,18,14,4,0,
10034,18,17,1,0,
10034,18,17,1,1,1
10034,18,19,4,0,
10034,18,45,1,1,8
10034,18,46,4,0,
10034,18,52,1,1,9
10034,18,52,1,7,
10034,18,53,1,47,
10034,18,53,4,0,
10034,18,63,4,0,
10034,18,76,4,0,
10034,18,82,1,17,
10034,18,83,1,56,
10034,18,89,4,0,
10034,18,92,4,0,
10034,18,104,4,0,
10034,18,108,1,10,
10034,18,126,4,0,
10034,18,156,4,0,
10034,18,157,4,0,
10034,18,163,1,41,
10034,18,164,4,0,
10034,18,182,4,0,
10034,18,184,1,21,
10034,18,207,4,0,
10034,18,211,4,0,
10034,18,213,4,0,
10034,18,214,4,0,
10034,18,216,4,0,
10034,18,218,4,0,
10034,18,237,4,0,
10034,18,241,4,0,
10034,18,257,1,1,3
10034,18,257,1,71,
10034,18,261,4,0,
10034,18,263,4,0,
10034,18,280,4,0,
10034,18,307,3,0,
10034,18,315,4,0,
10034,18,317,4,0,
10034,18,332,4,0,
10034,18,337,1,1,4
10034,18,337,4,0,
10034,18,355,4,0,
10034,18,374,4,0,
10034,18,394,1,1,2
10034,18,394,1,77,
10034,18,403,1,1,6
10034,18,411,4,0,
10034,18,416,4,0,
10034,18,421,1,1,5
10034,18,421,4,0,
10034,18,424,1,28,
10034,18,481,1,32,
10034,18,488,4,0,
10034,18,496,4,0,
10034,18,497,4,0,
10034,18,507,4,0,
10034,18,517,1,62,
10034,18,519,3,0,
10034,18,523,4,0,
10034,18,525,4,0,
10034,18,526,4,0,
10034,18,590,4,0,
10034,18,693,4,0,
10035,15,10,1,1,6
10035,15,14,4,0,
10035,15,15,4,0,
10035,15,17,1,36,
10035,15,19,4,0,
10035,15,45,1,1,7
10035,15,46,4,0,
10035,15,52,1,1,8
10035,15,52,1,7,
10035,15,53,1,47,
10035,15,53,4,0,
10035,15,63,4,0,
10035,15,70,4,0,
10035,15,76,4,0,
10035,15,82,1,17,
10035,15,83,1,56,
10035,15,89,4,0,
10035,15,91,4,0,
10035,15,92,4,0,
10035,15,104,4,0,
10035,15,108,1,1,9
10035,15,108,1,10,
10035,15,126,4,0,
10035,15,156,4,0,
10035,15,157,4,0,
10035,15,163,1,41,
10035,15,164,4,0,
10035,15,182,4,0,
10035,15,184,1,21,
10035,15,207,4,0,
10035,15,211,4,0,
10035,15,213,4,0,
10035,15,214,4,0,
10035,15,216,4,0,
10035,15,218,4,0,
10035,15,237,4,0,
10035,15,241,4,0,
10035,15,249,4,0,
10035,15,257,1,1,2
10035,15,257,1,71,
10035,15,261,4,0,
10035,15,263,4,0,
10035,15,280,4,0,
10035,15,307,3,0,
10035,15,315,4,0,
10035,15,317,4,0,
10035,15,332,4,0,
10035,15,337,1,1,3
10035,15,337,4,0,
10035,15,355,4,0,
10035,15,374,4,0,
10035,15,394,1,1,1
10035,15,394,1,77,
10035,15,403,1,1,5
10035,15,411,4,0,
10035,15,416,4,0,
10035,15,421,1,1,4
10035,15,421,4,0,
10035,15,424,1,28,
10035,15,468,4,0,
10035,15,481,1,32,
10035,15,488,4,0,
10035,15,496,4,0,
10035,15,497,4,0,
10035,15,507,4,0,
10035,15,510,4,0,
10035,15,517,1,62,
10035,15,519,3,0,
10035,15,523,4,0,
10035,15,525,4,0,
10035,15,590,4,0,
10035,15,612,4,0,
10035,16,7,3,0,
10035,16,9,3,0,
10035,16,10,1,1,6
10035,16,14,4,0,
10035,16,15,4,0,
10035,16,17,1,36,1
10035,16,19,4,0,
10035,16,45,1,1,7
10035,16,46,4,0,
10035,16,52,1,1,8
10035,16,52,1,7,1
10035,16,53,1,47,1
10035,16,53,4,0,
10035,16,63,4,0,
10035,16,70,4,0,
10035,16,76,4,0,
10035,16,82,1,17,1
10035,16,83,1,56,1
10035,16,89,4,0,
10035,16,91,4,0,
10035,16,92,4,0,
10035,16,104,4,0,
10035,16,108,1,1,9
10035,16,108,1,10,1
10035,16,126,4,0,
10035,16,156,4,0,
10035,16,157,4,0,
10035,16,163,1,41,1
10035,16,164,4,0,
10035,16,173,3,0,
10035,16,182,4,0,
10035,16,184,1,21,1
10035,16,200,3,0,
10035,16,207,4,0,
10035,16,211,4,0,
10035,16,213,4,0,
10035,16,214,4,0,
10035,16,216,4,0,
10035,16,218,4,0,
10035,16,231,3,0,
10035,16,237,4,0,
10035,16,241,4,0,
10035,16,249,4,0,
10035,16,257,1,1,2
10035,16,257,1,71,1
10035,16,257,3,0,
10035,16,261,4,0,
10035,16,263,4,0,
10035,16,264,3,0,
10035,16,280,4,0,
10035,16,290,4,0,
10035,16,307,3,0,
10035,16,315,4,0,
10035,16,317,4,0,
10035,16,332,4,0,
10035,16,337,1,1,3
10035,16,337,4,0,
10035,16,355,4,0,
10035,16,366,3,0,
10035,16,374,4,0,
10035,16,394,1,1,1
10035,16,394,1,77,1
10035,16,403,1,1,5
10035,16,406,3,0,
10035,16,411,4,0,
10035,16,416,4,0,
10035,16,421,1,1,4
10035,16,421,4,0,
10035,16,424,1,28,1
10035,16,468,4,0,
10035,16,481,1,32,1
10035,16,488,4,0,
10035,16,496,4,0,
10035,16,497,4,0,
10035,16,507,4,0,
10035,16,510,4,0,
10035,16,517,1,62,1
10035,16,519,3,0,
10035,16,523,4,0,
10035,16,525,4,0,
10035,16,590,4,0,
10035,16,612,4,0,
10035,17,10,1,1,7
10035,17,14,4,0,
10035,17,17,1,0,
10035,17,17,1,1,1
10035,17,19,4,0,
10035,17,45,1,1,8
10035,17,46,4,0,
10035,17,52,1,1,9
10035,17,52,1,7,
10035,17,53,1,47,
10035,17,53,4,0,
10035,17,63,4,0,
10035,17,76,4,0,
10035,17,82,1,17,
10035,17,83,1,56,
10035,17,89,4,0,
10035,17,92,4,0,
10035,17,104,4,0,
10035,17,108,1,10,
10035,17,126,4,0,
10035,17,156,4,0,
10035,17,157,4,0,
10035,17,163,1,41,
10035,17,164,4,0,
10035,17,182,4,0,
10035,17,184,1,21,
10035,17,207,4,0,
10035,17,211,4,0,
10035,17,213,4,0,
10035,17,214,4,0,
10035,17,216,4,0,
10035,17,218,4,0,
10035,17,237,4,0,
10035,17,241,4,0,
10035,17,257,1,1,3
10035,17,257,1,71,
10035,17,261,4,0,
10035,17,263,4,0,
10035,17,280,4,0,
10035,17,307,3,0,
10035,17,315,4,0,
10035,17,317,4,0,
10035,17,332,4,0,
10035,17,337,1,1,4
10035,17,337,4,0,
10035,17,355,4,0,
10035,17,374,4,0,
10035,17,394,1,1,2
10035,17,394,1,77,
10035,17,403,1,1,6
10035,17,411,4,0,
10035,17,416,4,0,
10035,17,421,1,1,5
10035,17,421,4,0,
10035,17,424,1,28,
10035,17,481,1,32,
10035,17,488,4,0,
10035,17,496,4,0,
10035,17,497,4,0,
10035,17,507,4,0,
10035,17,517,1,62,
10035,17,519,3,0,
10035,17,523,4,0,
10035,17,525,4,0,
10035,17,526,4,0,
10035,17,590,4,0,
10035,17,693,4,0,
10035,18,10,1,1,7
10035,18,14,4,0,
10035,18,17,1,0,
10035,18,17,1,1,1
10035,18,19,4,0,
10035,18,45,1,1,8
10035,18,46,4,0,
10035,18,52,1,1,9
10035,18,52,1,7,
10035,18,53,1,47,
10035,18,53,4,0,
10035,18,63,4,0,
10035,18,76,4,0,
10035,18,82,1,17,
10035,18,83,1,56,
10035,18,89,4,0,
10035,18,92,4,0,
10035,18,104,4,0,
10035,18,108,1,10,
10035,18,126,4,0,
10035,18,156,4,0,
10035,18,157,4,0,
10035,18,163,1,41,
10035,18,164,4,0,
10035,18,182,4,0,
10035,18,184,1,21,
10035,18,207,4,0,
10035,18,211,4,0,
10035,18,213,4,0,
10035,18,214,4,0,
10035,18,216,4,0,
10035,18,218,4,0,
10035,18,237,4,0,
10035,18,241,4,0,
10035,18,257,1,1,3
10035,18,257,1,71,
10035,18,261,4,0,
10035,18,263,4,0,
10035,18,280,4,0,
10035,18,307,3,0,
10035,18,315,4,0,
10035,18,317,4,0,
10035,18,332,4,0,
10035,18,337,1,1,4
10035,18,337,4,0,
10035,18,355,4,0,
10035,18,374,4,0,
10035,18,394,1,1,2
10035,18,394,1,77,
10035,18,403,1,1,6
10035,18,411,4,0,
10035,18,416,4,0,
10035,18,421,1,1,5
10035,18,421,4,0,
10035,18,424,1,28,
10035,18,481,1,32,
10035,18,488,4,0,
10035,18,496,4,0,
10035,18,497,4,0,
10035,18,507,4,0,
10035,18,517,1,62,
10035,18,519,3,0,
10035,18,523,4,0,
10035,18,525,4,0,
10035,18,526,4,0,
10035,18,590,4,0,
10035,18,693,4,0,
10036,15,33,1,1,2
10036,15,39,1,1,3
10036,15,39,1,4,
10036,15,44,1,16,
10036,15,46,4,0,
10036,15,55,1,1,4
10036,15,55,1,7,
10036,15,56,1,60,
10036,15,57,4,0,
10036,15,58,4,0,
10036,15,59,4,0,
10036,15,63,4,0,
10036,15,70,4,0,
10036,15,89,4,0,
10036,15,91,4,0,
10036,15,92,4,0,
10036,15,104,4,0,
10036,15,110,1,1,5
10036,15,110,1,10,
10036,15,127,4,0,
10036,15,130,1,39,
10036,15,145,1,13,
10036,15,156,4,0,
10036,15,157,4,0,
10036,15,164,4,0,
10036,15,182,1,24,
10036,15,182,4,0,
10036,15,207,4,0,
10036,15,213,4,0,
10036,15,214,4,0,
10036,15,216,4,0,
10036,15,218,4,0,
10036,15,229,1,20,
10036,15,237,4,0,
10036,15,240,1,53,
10036,15,240,4,0,
10036,15,249,4,0,
10036,15,258,4,0,
10036,15,263,4,0,
10036,15,280,4,0,
10036,15,308,3,0,
10036,15,317,4,0,
10036,15,334,1,46,
10036,15,352,1,28,
10036,15,360,4,0,
10036,15,374,4,0,
10036,15,399,4,0,
10036,15,401,1,32,
10036,15,411,4,0,
10036,15,416,4,0,
10036,15,430,1,1,1
10036,15,430,4,0,
10036,15,479,4,0,
10036,15,496,4,0,
10036,15,503,4,0,
10036,15,518,3,0,
10036,15,523,4,0,
10036,15,525,4,0,
10036,15,590,4,0,
10036,15,612,4,0,
10036,16,8,3,0,
10036,16,33,1,1,2
10036,16,39,1,1,3
10036,16,39,1,4,1
10036,16,44,1,16,1
10036,16,46,4,0,
10036,16,55,1,1,4
10036,16,55,1,7,1
10036,16,56,1,60,1
10036,16,57,4,0,
10036,16,58,4,0,
10036,16,59,4,0,
10036,16,63,4,0,
10036,16,70,4,0,
10036,16,89,4,0,
10036,16,91,4,0,
10036,16,92,4,0,
10036,16,104,4,0,
10036,16,110,1,1,5
10036,16,110,1,10,1
10036,16,127,4,0,
10036,16,130,1,39,1
10036,16,145,1,13,1
10036,16,156,4,0,
10036,16,157,4,0,
10036,16,164,4,0,
10036,16,173,3,0,
10036,16,182,1,24,1
10036,16,182,4,0,
10036,16,196,3,0,
10036,16,200,3,0,
10036,16,207,4,0,
10036,16,213,4,0,
10036,16,214,4,0,
10036,16,216,4,0,
10036,16,218,4,0,
10036,16,229,1,20,1
10036,16,231,3,0,
10036,16,237,4,0,
10036,16,240,1,53,1
10036,16,240,4,0,
10036,16,249,4,0,
10036,16,258,4,0,
10036,16,263,4,0,
10036,16,264,3,0,
10036,16,280,4,0,
10036,16,290,4,0,
10036,16,291,4,0,
10036,16,308,3,0,
10036,16,317,4,0,
10036,16,324,3,0,
10036,16,334,1,46,1
10036,16,334,3,0,
10036,16,352,1,28,1
10036,16,352,3,0,
10036,16,360,4,0,
10036,16,374,4,0,
10036,16,399,4,0,
10036,16,401,1,32,1
10036,16,401,3,0,
10036,16,406,3,0,
10036,16,411,4,0,
10036,16,416,4,0,
10036,16,428,3,0,
10036,16,430,1,1,1
10036,16,430,4,0,
10036,16,479,4,0,
10036,16,496,4,0,
10036,16,503,4,0,
10036,16,518,3,0,
10036,16,523,4,0,
10036,16,525,4,0,
10036,16,590,4,0,
10036,16,612,4,0,
10036,17,33,1,1,2
10036,17,39,1,1,3
10036,17,39,1,4,
10036,17,44,1,17,
10036,17,46,4,0,
10036,17,55,1,1,4
10036,17,55,1,7,
10036,17,56,1,60,
10036,17,57,4,0,
10036,17,58,4,0,
10036,17,59,4,0,
10036,17,63,4,0,
10036,17,89,4,0,
10036,17,92,4,0,
10036,17,104,4,0,
10036,17,110,1,1,5
10036,17,110,1,10,
10036,17,127,4,0,
10036,17,130,1,40,
10036,17,145,1,13,
10036,17,156,4,0,
10036,17,157,4,0,
10036,17,164,4,0,
10036,17,182,1,25,
10036,17,182,4,0,
10036,17,207,4,0,
10036,17,213,4,0,
10036,17,214,4,0,
10036,17,216,4,0,
10036,17,218,4,0,
10036,17,229,1,21,
10036,17,237,4,0,
10036,17,240,1,54,
10036,17,240,4,0,
10036,17,258,4,0,
10036,17,263,4,0,
10036,17,280,4,0,
10036,17,308,3,0,
10036,17,317,4,0,
10036,17,334,1,47,
10036,17,352,1,29,
10036,17,360,4,0,
10036,17,374,4,0,
10036,17,399,4,0,
10036,17,401,1,33,
10036,17,411,4,0,
10036,17,416,4,0,
10036,17,430,1,1,1
10036,17,430,4,0,
10036,17,479,4,0,
10036,17,496,4,0,
10036,17,503,4,0,
10036,17,518,3,0,
10036,17,523,4,0,
10036,17,525,4,0,
10036,17,526,4,0,
10036,17,590,4,0,
10036,18,33,1,1,2
10036,18,39,1,1,3
10036,18,39,1,4,
10036,18,44,1,17,
10036,18,46,4,0,
10036,18,55,1,1,4
10036,18,55,1,7,
10036,18,56,1,60,
10036,18,57,4,0,
10036,18,58,4,0,
10036,18,59,4,0,
10036,18,63,4,0,
10036,18,89,4,0,
10036,18,92,4,0,
10036,18,104,4,0,
10036,18,110,1,1,5
10036,18,110,1,10,
10036,18,127,4,0,
10036,18,130,1,40,
10036,18,145,1,13,
10036,18,156,4,0,
10036,18,157,4,0,
10036,18,164,4,0,
10036,18,182,1,25,
10036,18,182,4,0,
10036,18,207,4,0,
10036,18,213,4,0,
10036,18,214,4,0,
10036,18,216,4,0,
10036,18,218,4,0,
10036,18,229,1,21,
10036,18,237,4,0,
10036,18,240,1,54,
10036,18,240,4,0,
10036,18,258,4,0,
10036,18,263,4,0,
10036,18,280,4,0,
10036,18,308,3,0,
10036,18,317,4,0,
10036,18,334,1,47,
10036,18,352,1,29,
10036,18,360,4,0,
10036,18,374,4,0,
10036,18,399,4,0,
10036,18,401,1,33,
10036,18,411,4,0,
10036,18,416,4,0,
10036,18,430,1,1,1
10036,18,430,4,0,
10036,18,479,4,0,
10036,18,496,4,0,
10036,18,503,4,0,
10036,18,518,3,0,
10036,18,523,4,0,
10036,18,525,4,0,
10036,18,526,4,0,
10036,18,590,4,0,
10037,15,50,1,18,
10037,15,60,1,28,
10037,15,63,4,0,
10037,15,86,4,0,
10037,15,92,4,0,
10037,15,93,1,1,3
10037,15,93,1,16,
10037,15,94,1,46,
10037,15,94,4,0,
10037,15,100,1,1,1
10037,15,104,4,0,
10037,15,105,1,36,
10037,15,113,4,0,
10037,15,115,1,30,
10037,15,115,4,0,
10037,15,134,1,1,2
10037,15,138,4,0,
10037,15,148,4,0,
10037,15,156,4,0,
10037,15,164,4,0,
10037,15,168,4,0,
10037,15,182,4,0,
10037,15,207,4,0,
10037,15,213,4,0,
10037,15,214,4,0,
10037,15,216,4,0,
10037,15,218,4,0,
10037,15,219,4,0,
10037,15,237,4,0,
10037,15,240,4,0,
10037,15,241,4,0,
10037,15,244,4,0,
10037,15,247,4,0,
10037,15,248,1,48,
10037,15,259,4,0,
10037,15,263,4,0,
10037,15,269,4,0,
10037,15,271,1,50,
10037,15,347,1,42,
10037,15,347,4,0,
10037,15,357,1,22,
10037,15,373,4,0,
10037,15,374,4,0,
10037,15,411,4,0,
10037,15,412,4,0,
10037,15,416,4,0,
10037,15,427,1,40,
10037,15,433,4,0,
10037,15,447,4,0,
10037,15,451,4,0,
10037,15,473,4,0,
10037,15,477,1,34,
10037,15,496,4,0,
10037,15,502,1,24,
10037,15,590,4,0,
10037,15,605,4,0,
10037,16,7,3,0,
10037,16,8,3,0,
10037,16,9,3,0,
10037,16,50,1,18,1
10037,16,60,1,21,1
10037,16,63,4,0,
10037,16,86,4,0,
10037,16,92,4,0,
10037,16,93,1,1,3
10037,16,93,1,16,1
10037,16,94,1,38,1
10037,16,94,4,0,
10037,16,100,1,1,1
10037,16,104,4,0,
10037,16,105,1,31,1
10037,16,113,4,0,
10037,16,115,1,26,1
10037,16,115,4,0,
10037,16,134,1,1,2
10037,16,138,4,0,
10037,16,148,4,0,
10037,16,156,4,0,
10037,16,164,4,0,
10037,16,168,4,0,
10037,16,173,3,0,
10037,16,182,4,0,
10037,16,207,4,0,
10037,16,213,4,0,
10037,16,214,4,0,
10037,16,216,4,0,
10037,16,218,4,0,
10037,16,219,4,0,
10037,16,231,3,0,
10037,16,237,4,0,
10037,16,240,4,0,
10037,16,241,4,0,
10037,16,244,4,0,
10037,16,247,4,0,
10037,16,248,1,43,1
10037,16,259,4,0,
10037,16,263,4,0,
10037,16,264,3,0,
10037,16,269,4,0,
10037,16,271,1,46,1
10037,16,271,3,0,
10037,16,272,3,0,
10037,16,277,3,0,
10037,16,278,3,0,
10037,16,282,3,0,
10037,16,285,3,0,
10037,16,289,3,0,
10037,16,290,4,0,
10037,16,324,3,0,
10037,16,347,1,41,1
10037,16,347,4,0,
10037,16,351,3,0,
10037,16,356,3,0,
10037,16,357,1,23,1
10037,16,373,4,0,
10037,16,374,4,0,
10037,16,409,3,0,
10037,16,411,4,0,
10037,16,412,4,0,
10037,16,416,4,0,
10037,16,427,1,28,1
10037,16,428,3,0,
10037,16,433,4,0,
10037,16,447,4,0,
10037,16,451,4,0,
10037,16,472,3,0,
10037,16,473,4,0,
10037,16,477,1,33,1
10037,16,478,3,0,
10037,16,492,3,0,
10037,16,496,4,0,
10037,16,502,1,36,1
10037,16,590,4,0,
10037,16,605,4,0,
10037,17,50,1,18,
10037,17,60,1,21,
10037,17,63,4,0,
10037,17,86,4,0,
10037,17,92,4,0,
10037,17,93,1,1,3
10037,17,93,1,16,
10037,17,94,1,38,
10037,17,94,4,0,
10037,17,100,1,1,2
10037,17,104,4,0,
10037,17,105,1,31,
10037,17,113,4,0,
10037,17,115,1,26,
10037,17,115,4,0,
10037,17,134,1,0,
10037,17,134,1,1,1
10037,17,138,4,0,
10037,17,156,4,0,
10037,17,164,4,0,
10037,17,168,4,0,
10037,17,182,4,0,
10037,17,207,4,0,
10037,17,213,4,0,
10037,17,214,4,0,
10037,17,216,4,0,
10037,17,218,4,0,
10037,17,219,4,0,
10037,17,237,4,0,
10037,17,240,4,0,
10037,17,241,4,0,
10037,17,244,4,0,
10037,17,247,4,0,
10037,17,248,1,43,
10037,17,259,4,0,
10037,17,263,4,0,
10037,17,269,4,0,
10037,17,271,1,46,
10037,17,347,1,41,
10037,17,347,4,0,
10037,17,357,1,23,
10037,17,373,4,0,
10037,17,374,4,0,
10037,17,411,4,0,
10037,17,412,4,0,
10037,17,416,4,0,
10037,17,427,1,28,
10037,17,433,4,0,
10037,17,447,4,0,
10037,17,451,4,0,
10037,17,473,4,0,
10037,17,477,1,33,
10037,17,496,4,0,
10037,17,502,1,36,
10037,17,590,4,0,
10037,17,605,4,0,
10037,18,50,1,18,
10037,18,60,1,21,
10037,18,63,4,0,
10037,18,86,4,0,
10037,18,92,4,0,
10037,18,93,1,1,3
10037,18,93,1,16,
10037,18,94,1,38,
10037,18,94,4,0,
10037,18,100,1,1,2
10037,18,104,4,0,
10037,18,105,1,31,
10037,18,113,4,0,
10037,18,115,1,26,
10037,18,115,4,0,
10037,18,134,1,0,
10037,18,134,1,1,1
10037,18,138,4,0,
10037,18,156,4,0,
10037,18,164,4,0,
10037,18,168,4,0,
10037,18,182,4,0,
10037,18,207,4,0,
10037,18,213,4,0,
10037,18,214,4,0,
10037,18,216,4,0,
10037,18,218,4,0,
10037,18,219,4,0,
10037,18,237,4,0,
10037,18,240,4,0,
10037,18,241,4,0,
10037,18,244,4,0,
10037,18,247,4,0,
10037,18,248,1,43,
10037,18,259,4,0,
10037,18,263,4,0,
10037,18,269,4,0,
10037,18,271,1,46,
10037,18,347,1,41,
10037,18,347,4,0,
10037,18,357,1,23,
10037,18,373,4,0,
10037,18,374,4,0,
10037,18,411,4,0,
10037,18,412,4,0,
10037,18,416,4,0,
10037,18,427,1,28,
10037,18,433,4,0,
10037,18,447,4,0,
10037,18,451,4,0,
10037,18,473,4,0,
10037,18,477,1,33,
10037,18,496,4,0,
10037,18,502,1,36,
10037,18,590,4,0,
10037,18,605,4,0,
10038,15,63,4,0,
10038,15,70,4,0,
10038,15,85,4,0,
10038,15,87,4,0,
10038,15,92,4,0,
10038,15,94,4,0,
10038,15,95,1,1,1
10038,15,101,1,15,
10038,15,104,4,0,
10038,15,109,1,19,
10038,15,122,1,1,2
10038,15,138,1,39,
10038,15,138,4,0,
10038,15,153,4,0,
10038,15,156,4,0,
10038,15,164,4,0,
10038,15,168,4,0,
10038,15,171,1,61,
10038,15,174,1,12,
10038,15,180,1,1,3
10038,15,180,1,5,
10038,15,182,4,0,
10038,15,188,4,0,
10038,15,194,1,50,
10038,15,207,4,0,
10038,15,212,1,8,
10038,15,213,4,0,
10038,15,214,4,0,
10038,15,216,4,0,
10038,15,218,4,0,
10038,15,237,4,0,
10038,15,240,4,0,
10038,15,241,4,0,
10038,15,244,4,0,
10038,15,247,1,33,
10038,15,247,4,0,
10038,15,249,4,0,
10038,15,259,4,0,
10038,15,261,4,0,
10038,15,263,4,0,
10038,15,269,4,0,
10038,15,280,4,0,
10038,15,325,1,25,
10038,15,371,1,28,
10038,15,371,4,0,
10038,15,373,4,0,
10038,15,374,4,0,
10038,15,389,1,22,
10038,15,398,4,0,
10038,15,399,1,44,
10038,15,399,4,0,
10038,15,411,4,0,
10038,15,412,4,0,
10038,15,416,4,0,
10038,15,421,4,0,
10038,15,433,4,0,
10038,15,474,4,0,
10038,15,496,4,0,
10038,15,506,1,55,
10038,15,590,4,0,
10038,15,605,4,0,
10038,15,611,4,0,
10038,15,612,4,0,
10038,16,7,3,0,
10038,16,8,3,0,
10038,16,9,3,0,
10038,16,63,4,0,
10038,16,70,4,0,
10038,16,85,4,0,
10038,16,87,4,0,
10038,16,92,4,0,
10038,16,94,4,0,
10038,16,95,1,1,1
10038,16,101,1,15,1
10038,16,104,4,0,
10038,16,109,1,19,1
10038,16,122,1,1,2
10038,16,138,1,39,1
10038,16,138,4,0,
10038,16,153,4,0,
10038,16,156,4,0,
10038,16,164,4,0,
10038,16,168,4,0,
10038,16,171,1,61,1
10038,16,173,3,0,
10038,16,174,1,12,1
10038,16,180,1,1,3
10038,16,180,1,5,1
10038,16,180,3,0,
10038,16,182,4,0,
10038,16,188,4,0,
10038,16,194,1,50,1
10038,16,196,3,0,
10038,16,202,3,0,
10038,16,207,4,0,
10038,16,212,1,8,1
10038,16,213,4,0,
10038,16,214,4,0,
10038,16,216,4,0,
10038,16,218,4,0,
10038,16,220,3,0,
10038,16,237,4,0,
10038,16,240,4,0,
10038,16,241,4,0,
10038,16,244,4,0,
10038,16,247,1,33,1
10038,16,247,4,0,
10038,16,249,4,0,
10038,16,253,3,0,
10038,16,259,4,0,
10038,16,261,4,0,
10038,16,263,4,0,
10038,16,264,3,0,
10038,16,269,4,0,
10038,16,271,3,0,
10038,16,272,3,0,
10038,16,280,4,0,
10038,16,282,3,0,
10038,16,285,3,0,
10038,16,289,3,0,
10038,16,290,4,0,
10038,16,325,1,25,1
10038,16,371,1,28,1
10038,16,371,4,0,
10038,16,373,4,0,
10038,16,374,4,0,
10038,16,389,1,22,1
10038,16,398,4,0,
10038,16,399,1,44,1
10038,16,399,4,0,
10038,16,409,3,0,
10038,16,411,4,0,
10038,16,412,4,0,
10038,16,416,4,0,
10038,16,421,4,0,
10038,16,433,4,0,
10038,16,472,3,0,
10038,16,474,4,0,
10038,16,492,3,0,
10038,16,496,4,0,
10038,16,506,1,55,1
10038,16,590,4,0,
10038,16,605,4,0,
10038,16,611,4,0,
10038,16,612,4,0,
10038,17,63,4,0,
10038,17,85,4,0,
10038,17,87,4,0,
10038,17,92,4,0,
10038,17,94,4,0,
10038,17,95,1,1,2
10038,17,101,1,15,
10038,17,104,4,0,
10038,17,109,1,19,
10038,17,122,1,1,3
10038,17,138,1,39,
10038,17,138,4,0,
10038,17,153,4,0,
10038,17,156,4,0,
10038,17,164,4,0,
10038,17,168,4,0,
10038,17,171,1,61,
10038,17,174,1,12,
10038,17,180,1,1,4
10038,17,180,1,5,
10038,17,182,4,0,
10038,17,188,4,0,
10038,17,194,1,50,
10038,17,207,4,0,
10038,17,212,1,8,
10038,17,213,4,0,
10038,17,214,4,0,
10038,17,216,4,0,
10038,17,218,4,0,
10038,17,237,4,0,
10038,17,240,4,0,
10038,17,241,4,0,
10038,17,244,4,0,
10038,17,247,1,33,
10038,17,247,4,0,
10038,17,259,4,0,
10038,17,261,4,0,
10038,17,263,4,0,
10038,17,269,4,0,
10038,17,280,4,0,
10038,17,325,1,0,
10038,17,325,1,1,1
10038,17,371,1,28,
10038,17,371,4,0,
10038,17,373,4,0,
10038,17,374,4,0,
10038,17,389,1,22,
10038,17,398,4,0,
10038,17,399,1,44,
10038,17,399,4,0,
10038,17,411,4,0,
10038,17,412,4,0,
10038,17,416,4,0,
10038,17,421,4,0,
10038,17,433,4,0,
10038,17,474,4,0,
10038,17,496,4,0,
10038,17,506,1,55,
10038,17,590,4,0,
10038,17,605,4,0,
10038,17,611,4,0,
10038,18,63,4,0,
10038,18,85,4,0,
10038,18,87,4,0,
10038,18,92,4,0,
10038,18,94,4,0,
10038,18,95,1,1,2
10038,18,101,1,15,
10038,18,104,4,0,
10038,18,109,1,19,
10038,18,122,1,1,3
10038,18,138,1,39,
10038,18,138,4,0,
10038,18,153,4,0,
10038,18,156,4,0,
10038,18,164,4,0,
10038,18,168,4,0,
10038,18,171,1,61,
10038,18,174,1,12,
10038,18,180,1,1,4
10038,18,180,1,5,
10038,18,182,4,0,
10038,18,188,4,0,
10038,18,194,1,50,
10038,18,207,4,0,
10038,18,212,1,8,
10038,18,213,4,0,
10038,18,214,4,0,
10038,18,216,4,0,
10038,18,218,4,0,
10038,18,237,4,0,
10038,18,240,4,0,
10038,18,241,4,0,
10038,18,244,4,0,
10038,18,247,1,33,
10038,18,247,4,0,
10038,18,259,4,0,
10038,18,261,4,0,
10038,18,263,4,0,
10038,18,269,4,0,
10038,18,280,4,0,
10038,18,325,1,0,
10038,18,325,1,1,1
10038,18,371,1,28,
10038,18,371,4,0,
10038,18,373,4,0,
10038,18,374,4,0,
10038,18,389,1,22,
10038,18,398,4,0,
10038,18,399,1,44,
10038,18,399,4,0,
10038,18,411,4,0,
10038,18,412,4,0,
10038,18,416,4,0,
10038,18,421,4,0,
10038,18,433,4,0,
10038,18,474,4,0,
10038,18,496,4,0,
10038,18,506,1,55,
10038,18,590,4,0,
10038,18,605,4,0,
10038,18,611,4,0,
10039,15,4,1,1,1
10039,15,5,1,25,
10039,15,15,4,0,
10039,15,23,2,0,
10039,15,38,2,0,
10039,15,39,1,10,
10039,15,43,1,1,2
10039,15,44,1,13,
10039,15,46,4,0,
10039,15,50,2,0,
10039,15,53,4,0,
10039,15,57,4,0,
10039,15,58,4,0,
10039,15,59,4,0,
10039,15,63,4,0,
10039,15,68,2,0,
10039,15,70,4,0,
10039,15,76,4,0,
10039,15,85,4,0,
10039,15,87,4,0,
10039,15,89,4,0,
10039,15,91,4,0,
10039,15,92,4,0,
10039,15,99,1,22,
10039,15,104,4,0,
10039,15,116,2,0,
10039,15,126,4,0,
10039,15,146,1,34,
10039,15,156,4,0,
10039,15,157,4,0,
10039,15,164,4,0,
10039,15,168,4,0,
10039,15,179,1,50,
10039,15,182,4,0,
10039,15,193,2,0,
10039,15,200,1,46,
10039,15,201,4,0,
10039,15,203,1,43,
10039,15,207,4,0,
10039,15,213,4,0,
10039,15,214,4,0,
10039,15,216,4,0,
10039,15,218,4,0,
10039,15,219,4,0,
10039,15,237,4,0,
10039,15,240,4,0,
10039,15,241,4,0,
10039,15,242,1,37,
10039,15,247,4,0,
10039,15,249,4,0,
10039,15,252,1,7,
10039,15,253,2,0,
10039,15,258,4,0,
10039,15,263,4,0,
10039,15,264,2,0,
10039,15,280,4,0,
10039,15,283,2,0,
10039,15,306,2,0,
10039,15,317,4,0,
10039,15,332,4,0,
10039,15,359,2,0,
10039,15,374,4,0,
10039,15,376,2,0,
10039,15,389,1,49,
10039,15,411,4,0,
10039,15,416,4,0,
10039,15,421,4,0,
10039,15,458,1,19,
10039,15,496,4,0,
10039,15,498,1,31,
10039,15,509,2,0,
10039,15,510,4,0,
10039,15,514,4,0,
10039,15,523,4,0,
10039,15,590,4,0,
10039,15,612,4,0,
10039,16,4,1,1,1
10039,16,5,1,25,1
10039,16,7,3,0,
10039,16,8,3,0,
10039,16,9,3,0,
10039,16,15,4,0,
10039,16,39,1,10,1
10039,16,43,1,1,2
10039,16,44,1,13,1
10039,16,46,4,0,
10039,16,53,4,0,
10039,16,57,4,0,
10039,16,58,4,0,
10039,16,59,4,0,
10039,16,63,4,0,
10039,16,67,3,0,
10039,16,70,4,0,
10039,16,76,4,0,
10039,16,85,4,0,
10039,16,87,4,0,
10039,16,89,4,0,
10039,16,91,4,0,
10039,16,92,4,0,
10039,16,99,1,22,1
10039,16,104,4,0,
10039,16,126,4,0,
10039,16,146,1,34,1
10039,16,156,4,0,
10039,16,157,4,0,
10039,16,164,4,0,
10039,16,168,4,0,
10039,16,173,3,0,
10039,16,179,1,50,1
10039,16,180,3,0,
10039,16,182,4,0,
10039,16,196,3,0,
10039,16,200,1,46,1
10039,16,200,3,0,
10039,16,201,4,0,
10039,16,203,1,43,1
10039,16,207,4,0,
10039,16,213,4,0,
10039,16,214,4,0,
10039,16,216,4,0,
10039,16,218,4,0,
10039,16,219,4,0,
10039,16,231,3,0,
10039,16,237,4,0,
10039,16,240,4,0,
10039,16,241,4,0,
10039,16,242,1,37,1
10039,16,247,4,0,
10039,16,249,4,0,
10039,16,252,1,7,1
10039,16,253,3,0,
10039,16,258,4,0,
10039,16,263,4,0,
10039,16,264,3,0,
10039,16,270,3,0,
10039,16,280,4,0,
10039,16,283,3,0,
10039,16,290,4,0,
10039,16,317,4,0,
10039,16,332,4,0,
10039,16,343,3,0,
10039,16,351,3,0,
10039,16,352,3,0,
10039,16,374,4,0,
10039,16,389,1,49,1
10039,16,401,3,0,
10039,16,409,3,0,
10039,16,411,4,0,
10039,16,416,4,0,
10039,16,421,4,0,
10039,16,458,1,19,1
10039,16,496,4,0,
10039,16,498,1,31,1
10039,16,510,4,0,
10039,16,514,4,0,
10039,16,523,4,0,
10039,16,590,4,0,
10039,16,612,4,0,
10039,17,4,1,1,1
10039,17,5,1,25,
10039,17,39,1,10,
10039,17,43,1,1,2
10039,17,44,1,13,
10039,17,46,4,0,
10039,17,53,4,0,
10039,17,57,4,0,
10039,17,58,4,0,
10039,17,59,4,0,
10039,17,63,4,0,
10039,17,76,4,0,
10039,17,85,4,0,
10039,17,87,4,0,
10039,17,89,4,0,
10039,17,92,4,0,
10039,17,99,1,22,
10039,17,104,4,0,
10039,17,126,4,0,
10039,17,146,1,34,
10039,17,156,4,0,
10039,17,157,4,0,
10039,17,164,4,0,
10039,17,168,4,0,
10039,17,179,1,50,
10039,17,182,4,0,
10039,17,200,1,46,
10039,17,201,4,0,
10039,17,203,1,43,
10039,17,207,4,0,
10039,17,213,4,0,
10039,17,214,4,0,
10039,17,216,4,0,
10039,17,218,4,0,
10039,17,219,4,0,
10039,17,237,4,0,
10039,17,240,4,0,
10039,17,241,4,0,
10039,17,242,1,37,
10039,17,247,4,0,
10039,17,252,1,7,
10039,17,258,4,0,
10039,17,263,4,0,
10039,17,280,4,0,
10039,17,317,4,0,
10039,17,332,4,0,
10039,17,374,4,0,
10039,17,389,1,49,
10039,17,411,4,0,
10039,17,416,4,0,
10039,17,421,4,0,
10039,17,458,1,19,
10039,17,496,4,0,
10039,17,498,1,31,
10039,17,523,4,0,
10039,17,526,4,0,
10039,17,590,4,0,
10039,18,4,1,1,1
10039,18,5,1,25,
10039,18,39,1,10,
10039,18,43,1,1,2
10039,18,44,1,13,
10039,18,46,4,0,
10039,18,53,4,0,
10039,18,57,4,0,
10039,18,58,4,0,
10039,18,59,4,0,
10039,18,63,4,0,
10039,18,76,4,0,
10039,18,85,4,0,
10039,18,87,4,0,
10039,18,89,4,0,
10039,18,92,4,0,
10039,18,99,1,22,
10039,18,104,4,0,
10039,18,126,4,0,
10039,18,146,1,34,
10039,18,156,4,0,
10039,18,157,4,0,
10039,18,164,4,0,
10039,18,168,4,0,
10039,18,179,1,50,
10039,18,182,4,0,
10039,18,200,1,46,
10039,18,201,4,0,
10039,18,203,1,43,
10039,18,207,4,0,
10039,18,213,4,0,
10039,18,214,4,0,
10039,18,216,4,0,
10039,18,218,4,0,
10039,18,219,4,0,
10039,18,237,4,0,
10039,18,240,4,0,
10039,18,241,4,0,
10039,18,242,1,37,
10039,18,247,4,0,
10039,18,252,1,7,
10039,18,258,4,0,
10039,18,263,4,0,
10039,18,280,4,0,
10039,18,317,4,0,
10039,18,332,4,0,
10039,18,374,4,0,
10039,18,389,1,49,
10039,18,411,4,0,
10039,18,416,4,0,
10039,18,421,4,0,
10039,18,458,1,19,
10039,18,496,4,0,
10039,18,498,1,31,
10039,18,523,4,0,
10039,18,526,4,0,
10039,18,590,4,0,
10040,15,11,1,1,1
10040,15,12,1,47,
10040,15,14,1,40,
10040,15,14,4,0,
10040,15,15,4,0,
10040,15,20,1,4,
10040,15,31,2,0,
10040,15,37,1,36,
10040,15,63,4,0,
10040,15,66,1,26,
10040,15,69,1,8,
10040,15,70,4,0,
10040,15,89,4,0,
10040,15,91,4,0,
10040,15,92,4,0,
10040,15,98,2,0,
10040,15,104,4,0,
10040,15,106,1,11,
10040,15,116,1,1,2
10040,15,156,4,0,
10040,15,157,4,0,
10040,15,164,4,0,
10040,15,168,4,0,
10040,15,175,2,0,
10040,15,182,4,0,
10040,15,185,2,0,
10040,15,206,4,0,
10040,15,207,4,0,
10040,15,213,4,0,
10040,15,214,4,0,
10040,15,216,4,0,
10040,15,218,4,0,
10040,15,233,1,22,
10040,15,237,4,0,
10040,15,240,4,0,
10040,15,241,4,0,
10040,15,249,4,0,
10040,15,263,4,0,
10040,15,276,1,43,
10040,15,276,2,0,
10040,15,279,1,15,
10040,15,280,1,18,
10040,15,280,4,0,
10040,15,317,4,0,
10040,15,339,4,0,
10040,15,364,2,0,
10040,15,370,2,0,
10040,15,374,4,0,
10040,15,382,2,0,
10040,15,404,1,29,
10040,15,404,4,0,
10040,15,411,4,0,
10040,15,416,4,0,
10040,15,444,4,0,
10040,15,450,2,0,
10040,15,479,4,0,
10040,15,480,1,33,
10040,15,496,4,0,
10040,15,522,4,0,
10040,15,523,4,0,
10040,15,590,4,0,
10040,16,11,1,1,1
10040,16,12,1,50,1
10040,16,14,1,40,1
10040,16,14,4,0,
10040,16,15,4,0,
10040,16,20,1,4,1
10040,16,20,3,0,
10040,16,37,1,43,1
10040,16,63,4,0,
10040,16,66,1,29,1
10040,16,69,1,8,1
10040,16,70,4,0,
10040,16,89,4,0,
10040,16,91,4,0,
10040,16,92,4,0,
10040,16,104,4,0,
10040,16,106,1,11,1
10040,16,116,1,1,2
10040,16,156,4,0,
10040,16,157,4,0,
10040,16,164,4,0,
10040,16,168,4,0,
10040,16,173,3,0,
10040,16,182,4,0,
10040,16,206,4,0,
10040,16,207,4,0,
10040,16,213,4,0,
10040,16,214,4,0,
10040,16,216,4,0,
10040,16,218,4,0,
10040,16,233,1,18,1
10040,16,237,4,0,
10040,16,240,4,0,
10040,16,241,4,0,
10040,16,249,4,0,
10040,16,263,4,0,
10040,16,264,3,0,
10040,16,276,1,47,1
10040,16,276,3,0,
10040,16,279,1,15,1
10040,16,280,1,26,1
10040,16,280,4,0,
10040,16,282,3,0,
10040,16,290,4,0,
10040,16,317,4,0,
10040,16,334,3,0,
10040,16,339,4,0,
10040,16,374,4,0,
10040,16,404,1,33,1
10040,16,404,4,0,
10040,16,411,4,0,
10040,16,416,4,0,
10040,16,444,4,0,
10040,16,446,3,0,
10040,16,450,3,0,
10040,16,458,1,22,1
10040,16,479,4,0,
10040,16,480,1,36,1
10040,16,496,4,0,
10040,16,522,4,0,
10040,16,523,4,0,
10040,16,590,4,0,
10040,17,11,1,1,1
10040,17,12,1,50,
10040,17,14,1,40,
10040,17,14,4,0,
10040,17,20,1,4,
10040,17,37,1,43,
10040,17,63,4,0,
10040,17,66,1,33,
10040,17,69,1,8,
10040,17,89,4,0,
10040,17,92,4,0,
10040,17,104,4,0,
10040,17,106,1,11,
10040,17,116,1,1,2
10040,17,156,4,0,
10040,17,157,4,0,
10040,17,164,4,0,
10040,17,168,4,0,
10040,17,182,4,0,
10040,17,206,4,0,
10040,17,207,4,0,
10040,17,213,4,0,
10040,17,214,4,0,
10040,17,216,4,0,
10040,17,218,4,0,
10040,17,233,1,18,
10040,17,237,4,0,
10040,17,240,4,0,
10040,17,241,4,0,
10040,17,263,4,0,
10040,17,276,1,47,
10040,17,279,1,15,
10040,17,280,1,26,
10040,17,280,4,0,
10040,17,317,4,0,
10040,17,339,4,0,
10040,17,374,4,0,
10040,17,404,1,29,
10040,17,404,4,0,
10040,17,411,4,0,
10040,17,416,4,0,
10040,17,444,4,0,
10040,17,458,1,22,
10040,17,479,4,0,
10040,17,480,1,36,
10040,17,496,4,0,
10040,17,523,4,0,
10040,17,590,4,0,
10040,17,693,4,0,
10040,18,11,1,1,1
10040,18,12,1,50,
10040,18,14,1,40,
10040,18,14,4,0,
10040,18,20,1,4,
10040,18,37,1,43,
10040,18,63,4,0,
10040,18,66,1,33,
10040,18,69,1,8,
10040,18,89,4,0,
10040,18,92,4,0,
10040,18,104,4,0,
10040,18,106,1,11,
10040,18,116,1,1,2
10040,18,156,4,0,
10040,18,157,4,0,
10040,18,164,4,0,
10040,18,168,4,0,
10040,18,182,4,0,
10040,18,206,4,0,
10040,18,207,4,0,
10040,18,213,4,0,
10040,18,214,4,0,
10040,18,216,4,0,
10040,18,218,4,0,
10040,18,233,1,18,
10040,18,237,4,0,
10040,18,240,4,0,
10040,18,241,4,0,
10040,18,263,4,0,
10040,18,276,1,47,
10040,18,279,1,15,
10040,18,280,1,26,
10040,18,280,4,0,
10040,18,317,4,0,
10040,18,339,4,0,
10040,18,374,4,0,
10040,18,404,1,29,
10040,18,404,4,0,
10040,18,411,4,0,
10040,18,416,4,0,
10040,18,444,4,0,
10040,18,458,1,22,
10040,18,479,4,0,
10040,18,480,1,36,
10040,18,496,4,0,
10040,18,523,4,0,
10040,18,590,4,0,
10040,18,693,4,0,
10041,15,37,1,1,
10041,15,43,1,26,
10041,15,44,1,20,
10041,15,46,4,0,
10041,15,53,4,0,
10041,15,56,1,41,
10041,15,57,4,0,
10041,15,58,4,0,
10041,15,59,4,0,
10041,15,63,1,47,
10041,15,63,4,0,
10041,15,70,4,0,
10041,15,82,1,23,
10041,15,85,4,0,
10041,15,86,4,0,
10041,15,87,4,0,
10041,15,89,4,0,
10041,15,92,4,0,
10041,15,104,4,0,
10041,15,126,4,0,
10041,15,127,4,0,
10041,15,156,4,0,
10041,15,164,4,0,
10041,15,182,4,0,
10041,15,201,4,0,
10041,15,207,4,0,
10041,15,213,4,0,
10041,15,214,4,0,
10041,15,216,4,0,
10041,15,218,4,0,
10041,15,237,4,0,
10041,15,239,1,29,
10041,15,240,1,38,
10041,15,240,4,0,
10041,15,249,4,0,
10041,15,258,4,0,
10041,15,259,4,0,
10041,15,263,4,0,
10041,15,269,4,0,
10041,15,349,1,44,
10041,15,371,4,0,
10041,15,399,4,0,
10041,15,401,1,35,
10041,15,416,4,0,
10041,15,423,1,32,
10041,15,444,4,0,
10041,15,496,4,0,
10041,15,503,4,0,
10041,15,510,4,0,
10041,15,523,4,0,
10041,15,525,4,0,
10041,15,590,4,0,
10041,16,37,1,1,1
10041,16,43,1,26,1
10041,16,44,1,20,1
10041,16,46,4,0,
10041,16,53,4,0,
10041,16,56,1,44,1
10041,16,57,4,0,
10041,16,58,4,0,
10041,16,59,4,0,
10041,16,63,1,50,1
10041,16,63,4,0,
10041,16,70,4,0,
10041,16,82,1,23,1
10041,16,85,4,0,
10041,16,86,4,0,
10041,16,87,4,0,
10041,16,89,4,0,
10041,16,92,4,0,
10041,16,104,4,0,
10041,16,126,4,0,
10041,16,127,4,0,
10041,16,156,4,0,
10041,16,164,4,0,
10041,16,173,3,0,
10041,16,180,3,0,
10041,16,182,4,0,
10041,16,196,3,0,
10041,16,200,3,0,
10041,16,201,4,0,
10041,16,207,4,0,
10041,16,213,4,0,
10041,16,214,4,0,
10041,16,216,4,0,
10041,16,218,4,0,
10041,16,231,3,0,
10041,16,237,4,0,
10041,16,239,1,29,1
10041,16,240,1,38,1
10041,16,240,4,0,
10041,16,242,1,41,1
10041,16,249,4,0,
10041,16,253,3,0,
10041,16,258,4,0,
10041,16,259,4,0,
10041,16,263,4,0,
10041,16,269,4,0,
10041,16,290,4,0,
10041,16,291,4,0,
10041,16,340,3,0,
10041,16,349,1,47,1
10041,16,352,3,0,
10041,16,371,4,0,
10041,16,399,4,0,
10041,16,401,1,35,1
10041,16,401,3,0,
10041,16,406,3,0,
10041,16,416,4,0,
10041,16,423,1,32,1
10041,16,442,3,0,
10041,16,444,4,0,
10041,16,496,4,0,
10041,16,503,4,0,
10041,16,510,4,0,
10041,16,523,4,0,
10041,16,525,4,0,
10041,16,590,4,0,
10041,17,37,1,1,2
10041,17,43,1,21,
10041,17,44,1,0,
10041,17,44,1,1,1
10041,17,46,4,0,
10041,17,53,4,0,
10041,17,56,1,42,
10041,17,57,4,0,
10041,17,58,4,0,
10041,17,59,4,0,
10041,17,63,1,54,
10041,17,63,4,0,
10041,17,82,1,36,
10041,17,85,4,0,
10041,17,86,4,0,
10041,17,87,4,0,
10041,17,89,4,0,
10041,17,92,4,0,
10041,17,104,4,0,
10041,17,126,4,0,
10041,17,127,4,0,
10041,17,156,4,0,
10041,17,164,4,0,
10041,17,182,4,0,
10041,17,184,1,33,
10041,17,201,4,0,
10041,17,207,4,0,
10041,17,213,4,0,
10041,17,214,4,0,
10041,17,216,4,0,
10041,17,218,4,0,
10041,17,237,4,0,
10041,17,239,1,24,
10041,17,240,1,51,
10041,17,240,4,0,
10041,17,242,1,39,
10041,17,258,4,0,
10041,17,259,4,0,
10041,17,263,4,0,
10041,17,269,4,0,
10041,17,349,1,45,
10041,17,371,4,0,
10041,17,399,4,0,
10041,17,401,1,30,
10041,17,416,4,0,
10041,17,423,1,27,
10041,17,444,4,0,
10041,17,496,4,0,
10041,17,503,4,0,
10041,17,523,4,0,
10041,17,525,4,0,
10041,17,542,1,48,
10041,17,590,4,0,
10041,17,693,4,0,
10041,18,37,1,1,2
10041,18,43,1,21,
10041,18,44,1,0,
10041,18,44,1,1,1
10041,18,46,4,0,
10041,18,53,4,0,
10041,18,56,1,42,
10041,18,57,4,0,
10041,18,58,4,0,
10041,18,59,4,0,
10041,18,63,1,54,
10041,18,63,4,0,
10041,18,82,1,36,
10041,18,85,4,0,
10041,18,86,4,0,
10041,18,87,4,0,
10041,18,89,4,0,
10041,18,92,4,0,
10041,18,104,4,0,
10041,18,126,4,0,
10041,18,127,4,0,
10041,18,156,4,0,
10041,18,164,4,0,
10041,18,182,4,0,
10041,18,184,1,33,
10041,18,201,4,0,
10041,18,207,4,0,
10041,18,213,4,0,
10041,18,214,4,0,
10041,18,216,4,0,
10041,18,218,4,0,
10041,18,237,4,0,
10041,18,239,1,24,
10041,18,240,1,51,
10041,18,240,4,0,
10041,18,242,1,39,
10041,18,258,4,0,
10041,18,259,4,0,
10041,18,263,4,0,
10041,18,269,4,0,
10041,18,349,1,45,
10041,18,371,4,0,
10041,18,399,4,0,
10041,18,401,1,30,
10041,18,416,4,0,
10041,18,423,1,27,
10041,18,444,4,0,
10041,18,496,4,0,
10041,18,503,4,0,
10041,18,523,4,0,
10041,18,525,4,0,
10041,18,542,1,48,
10041,18,590,4,0,
10041,18,693,4,0,
10042,15,17,1,1,5
10042,15,18,2,0,
10042,15,19,4,0,
10042,15,36,1,41,
10042,15,44,1,1,7
10042,15,46,1,9,
10042,15,46,4,0,
10042,15,48,1,1,6
10042,15,53,4,0,
10042,15,63,1,65,
10042,15,63,4,0,
10042,15,70,4,0,
10042,15,89,4,0,
10042,15,92,4,0,
10042,15,97,1,17,
10042,15,104,4,0,
10042,15,126,4,0,
10042,15,156,4,0,
10042,15,157,1,73,
10042,15,157,4,0,
10042,15,164,4,0,
10042,15,168,4,0,
10042,15,174,2,0,
10042,15,182,4,0,
10042,15,184,1,1,8
10042,15,193,2,0,
10042,15,201,4,0,
10042,15,207,4,0,
10042,15,211,2,0,
10042,15,211,4,0,
10042,15,213,4,0,
10042,15,214,4,0,
10042,15,216,4,0,
10042,15,218,4,0,
10042,15,225,2,0,
10042,15,228,2,0,
10042,15,237,4,0,
10042,15,240,4,0,
10042,15,241,4,0,
10042,15,242,1,33,
10042,15,246,1,25,
10042,15,249,4,0,
10042,15,259,4,0,
10042,15,263,4,0,
10042,15,269,4,0,
10042,15,317,4,0,
10042,15,332,4,0,
10042,15,337,4,0,
10042,15,355,2,0,
10042,15,355,4,0,
10042,15,366,2,0,
10042,15,371,4,0,
10042,15,372,2,0,
10042,15,397,4,0,
10042,15,416,1,81,
10042,15,416,4,0,
10042,15,422,1,1,4
10042,15,423,1,1,2
10042,15,424,1,1,3
10042,15,442,1,1,1
10042,15,442,1,57,
10042,15,444,4,0,
10042,15,468,4,0,
10042,15,469,2,0,
10042,15,479,4,0,
10042,15,496,4,0,
10042,15,507,1,49,
10042,15,507,4,0,
10042,15,510,4,0,
10042,15,523,4,0,
10042,15,590,4,0,
10042,16,17,1,1,5
10042,16,19,4,0,
10042,16,36,1,41,1
10042,16,44,1,1,7
10042,16,46,1,9,1
10042,16,46,4,0,
10042,16,48,1,1,6
10042,16,53,4,0,
10042,16,63,1,65,1
10042,16,63,4,0,
10042,16,70,4,0,
10042,16,89,4,0,
10042,16,92,4,0,
10042,16,97,1,17,1
10042,16,104,4,0,
10042,16,126,4,0,
10042,16,143,3,0,
10042,16,156,4,0,
10042,16,157,1,73,1
10042,16,157,4,0,
10042,16,164,4,0,
10042,16,168,4,0,
10042,16,173,3,0,
10042,16,182,4,0,
10042,16,184,1,1,8
10042,16,201,4,0,
10042,16,207,4,0,
10042,16,211,4,0,
10042,16,213,4,0,
10042,16,214,4,0,
10042,16,216,4,0,
10042,16,218,4,0,
10042,16,231,3,0,
10042,16,237,4,0,
10042,16,240,4,0,
10042,16,241,4,0,
10042,16,242,1,33,1
10042,16,246,1,25,1
10042,16,249,4,0,
10042,16,257,3,0,
10042,16,259,4,0,
10042,16,263,4,0,
10042,16,269,4,0,
10042,16,290,4,0,
10042,16,317,4,0,
10042,16,332,4,0,
10042,16,337,4,0,
10042,16,355,4,0,
10042,16,366,3,0,
10042,16,371,4,0,
10042,16,397,4,0,
10042,16,401,3,0,
10042,16,406,3,0,
10042,16,414,3,0,
10042,16,416,1,81,1
10042,16,416,4,0,
10042,16,422,1,1,4
10042,16,423,1,1,2
10042,16,424,1,1,3
10042,16,442,1,1,1
10042,16,442,1,57,1
10042,16,442,3,0,
10042,16,444,4,0,
10042,16,446,3,0,
10042,16,468,4,0,
10042,16,479,4,0,
10042,16,496,4,0,
10042,16,507,1,49,1
10042,16,507,4,0,
10042,16,510,4,0,
10042,16,523,4,0,
10042,16,590,4,0,
10042,17,17,1,1,5
10042,17,19,4,0,
10042,17,36,1,41,
10042,17,44,1,1,7
10042,17,46,1,9,
10042,17,46,4,0,
10042,17,48,1,1,6
10042,17,53,4,0,
10042,17,63,1,65,
10042,17,63,4,0,
10042,17,89,4,0,
10042,17,92,4,0,
10042,17,97,1,17,
10042,17,104,4,0,
10042,17,126,4,0,
10042,17,156,4,0,
10042,17,157,1,73,
10042,17,157,4,0,
10042,17,164,4,0,
10042,17,168,4,0,
10042,17,182,4,0,
10042,17,184,1,1,8
10042,17,201,4,0,
10042,17,207,4,0,
10042,17,211,4,0,
10042,17,213,4,0,
10042,17,214,4,0,
10042,17,216,4,0,
10042,17,218,4,0,
10042,17,237,4,0,
10042,17,240,4,0,
10042,17,241,4,0,
10042,17,242,1,33,
10042,17,246,1,25,
10042,17,259,4,0,
10042,17,263,4,0,
10042,17,269,4,0,
10042,17,317,4,0,
10042,17,332,4,0,
10042,17,337,4,0,
10042,17,355,4,0,
10042,17,371,4,0,
10042,17,397,4,0,
10042,17,416,1,81,
10042,17,416,4,0,
10042,17,422,1,1,4
10042,17,423,1,1,2
10042,17,424,1,1,3
10042,17,442,1,1,1
10042,17,442,1,57,
10042,17,444,4,0,
10042,17,479,4,0,
10042,17,496,4,0,
10042,17,507,1,49,
10042,17,507,4,0,
10042,17,523,4,0,
10042,17,590,4,0,
10042,17,693,4,0,
10042,18,17,1,1,5
10042,18,19,4,0,
10042,18,36,1,41,
10042,18,44,1,1,7
10042,18,46,1,9,
10042,18,46,4,0,
10042,18,48,1,1,6
10042,18,53,4,0,
10042,18,63,1,65,
10042,18,63,4,0,
10042,18,89,4,0,
10042,18,92,4,0,
10042,18,97,1,17,
10042,18,104,4,0,
10042,18,126,4,0,
10042,18,156,4,0,
10042,18,157,1,73,
10042,18,157,4,0,
10042,18,164,4,0,
10042,18,168,4,0,
10042,18,182,4,0,
10042,18,184,1,1,8
10042,18,201,4,0,
10042,18,207,4,0,
10042,18,211,4,0,
10042,18,213,4,0,
10042,18,214,4,0,
10042,18,216,4,0,
10042,18,218,4,0,
10042,18,237,4,0,
10042,18,240,4,0,
10042,18,241,4,0,
10042,18,242,1,33,
10042,18,246,1,25,
10042,18,259,4,0,
10042,18,263,4,0,
10042,18,269,4,0,
10042,18,317,4,0,
10042,18,332,4,0,
10042,18,337,4,0,
10042,18,355,4,0,
10042,18,371,4,0,
10042,18,397,4,0,
10042,18,416,1,81,
10042,18,416,4,0,
10042,18,422,1,1,4
10042,18,423,1,1,2
10042,18,424,1,1,3
10042,18,442,1,1,1
10042,18,442,1,57,
10042,18,444,4,0,
10042,18,479,4,0,
10042,18,496,4,0,
10042,18,507,1,49,
10042,18,507,4,0,
10042,18,523,4,0,
10042,18,590,4,0,
10042,18,693,4,0,
10043,15,50,1,1,2
10043,15,53,4,0,
10043,15,54,1,86,
10043,15,58,4,0,
10043,15,59,4,0,
10043,15,63,4,0,
10043,15,70,4,0,
10043,15,76,4,0,
10043,15,85,4,0,
10043,15,86,4,0,
10043,15,87,4,0,
10043,15,89,4,0,
10043,15,92,4,0,
10043,15,93,1,1,1
10043,15,94,1,57,
10043,15,94,4,0,
10043,15,104,4,0,
10043,15,105,1,50,
10043,15,112,1,64,
10043,15,113,4,0,
10043,15,115,4,0,
10043,15,126,4,0,
10043,15,129,1,8,
10043,15,133,1,79,
10043,15,138,4,0,
10043,15,148,4,0,
10043,15,156,4,0,
10043,15,157,4,0,
10043,15,164,4,0,
10043,15,182,4,0,
10043,15,201,4,0,
10043,15,207,4,0,
10043,15,214,4,0,
10043,15,216,4,0,
10043,15,218,4,0,
10043,15,219,1,1,3
10043,15,219,4,0,
10043,15,237,4,0,
10043,15,240,4,0,
10043,15,241,4,0,
10043,15,244,1,22,
10043,15,244,4,0,
10043,15,247,4,0,
10043,15,248,1,15,
10043,15,249,4,0,
10043,15,258,4,0,
10043,15,259,4,0,
10043,15,261,4,0,
10043,15,263,4,0,
10043,15,269,4,0,
10043,15,280,4,0,
10043,15,317,4,0,
10043,15,332,4,0,
10043,15,339,4,0,
10043,15,347,4,0,
10043,15,357,1,29,
10043,15,373,4,0,
10043,15,374,4,0,
10043,15,382,1,93,
10043,15,384,1,43,1
10043,15,385,1,43,2
10043,15,396,1,70,
10043,15,398,4,0,
10043,15,411,4,0,
10043,15,412,4,0,
10043,15,416,4,0,
10043,15,427,1,36,
10043,15,433,4,0,
10043,15,444,4,0,
10043,15,447,4,0,
10043,15,451,4,0,
10043,15,473,4,0,
10043,15,490,4,0,
10043,15,496,4,0,
10043,15,510,4,0,
10043,15,523,4,0,
10043,15,540,1,100,
10043,15,590,4,0,
10043,15,612,4,0,
10043,16,7,3,0,
10043,16,8,3,0,
10043,16,9,3,0,
10043,16,50,1,1,2
10043,16,53,4,0,
10043,16,54,1,86,1
10043,16,58,4,0,
10043,16,59,4,0,
10043,16,63,4,0,
10043,16,67,3,0,
10043,16,70,4,0,
10043,16,76,4,0,
10043,16,85,4,0,
10043,16,86,4,0,
10043,16,87,4,0,
10043,16,89,4,0,
10043,16,92,4,0,
10043,16,93,1,1,1
10043,16,94,1,57,1
10043,16,94,4,0,
10043,16,104,4,0,
10043,16,105,1,50,1
10043,16,112,1,64,1
10043,16,113,4,0,
10043,16,115,4,0,
10043,16,126,4,0,
10043,16,129,1,8,1
10043,16,133,1,79,1
10043,16,138,4,0,
10043,16,148,4,0,
10043,16,156,4,0,
10043,16,157,4,0,
10043,16,164,4,0,
10043,16,173,3,0,
10043,16,182,4,0,
10043,16,196,3,0,
10043,16,201,4,0,
10043,16,207,4,0,
10043,16,214,4,0,
10043,16,216,4,0,
10043,16,218,4,0,
10043,16,219,1,1,3
10043,16,219,4,0,
10043,16,231,3,0,
10043,16,237,4,0,
10043,16,240,4,0,
10043,16,241,4,0,
10043,16,244,1,22,1
10043,16,244,4,0,
10043,16,247,4,0,
10043,16,248,1,15,1
10043,16,249,4,0,
10043,16,258,4,0,
10043,16,259,4,0,
10043,16,261,4,0,
10043,16,263,4,0,
10043,16,264,3,0,
10043,16,269,4,0,
10043,16,271,3,0,
10043,16,272,3,0,
10043,16,277,3,0,
10043,16,278,3,0,
10043,16,280,4,0,
10043,16,285,3,0,
10043,16,289,3,0,
10043,16,290,4,0,
10043,16,291,4,0,
10043,16,317,4,0,
10043,16,324,3,0,
10043,16,332,4,0,
10043,16,339,4,0,
10043,16,347,4,0,
10043,16,351,3,0,
10043,16,352,3,0,
10043,16,356,3,0,
10043,16,357,1,29,1
10043,16,373,4,0,
10043,16,374,4,0,
10043,16,382,1,93,1
10043,16,384,1,43,1
10043,16,385,1,43,2
10043,16,396,1,70,1
10043,16,398,4,0,
10043,16,401,3,0,
10043,16,409,3,0,
10043,16,411,4,0,
10043,16,412,4,0,
10043,16,416,4,0,
10043,16,427,1,36,1
10043,16,428,3,0,
10043,16,433,4,0,
10043,16,444,4,0,
10043,16,447,4,0,
10043,16,451,4,0,
10043,16,472,3,0,
10043,16,473,4,0,
10043,16,478,3,0,
10043,16,490,4,0,
10043,16,492,3,0,
10043,16,496,4,0,
10043,16,510,4,0,
10043,16,523,4,0,
10043,16,540,1,100,1
10043,16,590,4,0,
10043,16,612,4,0,
10043,17,50,1,1,4
10043,17,53,4,0,
10043,17,54,1,86,
10043,17,58,4,0,
10043,17,59,4,0,
10043,17,63,4,0,
10043,17,76,4,0,
10043,17,85,4,0,
10043,17,86,4,0,
10043,17,87,4,0,
10043,17,89,4,0,
10043,17,92,4,0,
10043,17,93,1,1,3
10043,17,94,1,57,
10043,17,94,4,0,
10043,17,104,4,0,
10043,17,105,1,50,
10043,17,112,1,64,
10043,17,113,4,0,
10043,17,115,4,0,
10043,17,126,4,0,
10043,17,129,1,8,
10043,17,133,1,79,
10043,17,138,4,0,
10043,17,149,1,1,2
10043,17,156,4,0,
10043,17,157,4,0,
10043,17,164,4,0,
10043,17,182,4,0,
10043,17,201,4,0,
10043,17,207,4,0,
10043,17,214,4,0,
10043,17,216,4,0,
10043,17,218,4,0,
10043,17,219,1,1,5
10043,17,219,4,0,
10043,17,237,4,0,
10043,17,240,4,0,
10043,17,241,4,0,
10043,17,244,1,22,
10043,17,244,4,0,
10043,17,247,4,0,
10043,17,248,1,15,
10043,17,258,4,0,
10043,17,259,4,0,
10043,17,261,4,0,
10043,17,263,4,0,
10043,17,269,4,0,
10043,17,280,4,0,
10043,17,317,4,0,
10043,17,332,4,0,
10043,17,339,4,0,
10043,17,347,4,0,
10043,17,357,1,29,
10043,17,373,4,0,
10043,17,374,4,0,
10043,17,382,1,93,
10043,17,384,1,43,1
10043,17,385,1,43,2
10043,17,396,1,70,
10043,17,398,4,0,
10043,17,411,4,0,
10043,17,412,4,0,
10043,17,416,4,0,
10043,17,427,1,36,
10043,17,433,4,0,
10043,17,444,4,0,
10043,17,447,4,0,
10043,17,451,4,0,
10043,17,473,4,0,
10043,17,490,4,0,
10043,17,496,4,0,
10043,17,523,4,0,
10043,17,540,1,100,
10043,17,590,4,0,
10043,17,673,1,1,1
10043,17,693,4,0,
10043,18,50,1,1,4
10043,18,53,4,0,
10043,18,54,1,86,
10043,18,58,4,0,
10043,18,59,4,0,
10043,18,63,4,0,
10043,18,76,4,0,
10043,18,85,4,0,
10043,18,86,4,0,
10043,18,87,4,0,
10043,18,89,4,0,
10043,18,92,4,0,
10043,18,93,1,1,3
10043,18,94,1,57,
10043,18,94,4,0,
10043,18,104,4,0,
10043,18,105,1,50,
10043,18,112,1,64,
10043,18,113,4,0,
10043,18,115,4,0,
10043,18,126,4,0,
10043,18,129,1,8,
10043,18,133,1,79,
10043,18,138,4,0,
10043,18,149,1,1,2
10043,18,156,4,0,
10043,18,157,4,0,
10043,18,164,4,0,
10043,18,182,4,0,
10043,18,201,4,0,
10043,18,207,4,0,
10043,18,214,4,0,
10043,18,216,4,0,
10043,18,218,4,0,
10043,18,219,1,1,5
10043,18,219,4,0,
10043,18,237,4,0,
10043,18,240,4,0,
10043,18,241,4,0,
10043,18,244,1,22,
10043,18,244,4,0,
10043,18,247,4,0,
10043,18,248,1,15,
10043,18,258,4,0,
10043,18,259,4,0,
10043,18,261,4,0,
10043,18,263,4,0,
10043,18,269,4,0,
10043,18,280,4,0,
10043,18,317,4,0,
10043,18,332,4,0,
10043,18,339,4,0,
10043,18,347,4,0,
10043,18,357,1,29,
10043,18,373,4,0,
10043,18,374,4,0,
10043,18,382,1,93,
10043,18,384,1,43,1
10043,18,385,1,43,2
10043,18,396,1,70,
10043,18,398,4,0,
10043,18,411,4,0,
10043,18,412,4,0,
10043,18,416,4,0,
10043,18,427,1,36,
10043,18,433,4,0,
10043,18,444,4,0,
10043,18,447,4,0,
10043,18,451,4,0,
10043,18,473,4,0,
10043,18,490,4,0,
10043,18,496,4,0,
10043,18,523,4,0,
10043,18,540,1,100,
10043,18,590,4,0,
10043,18,673,1,1,1
10043,18,693,4,0,
10044,15,50,1,1,2
10044,15,53,4,0,
10044,15,54,1,86,
10044,15,58,4,0,
10044,15,59,4,0,
10044,15,63,4,0,
10044,15,70,4,0,
10044,15,76,4,0,
10044,15,85,4,0,
10044,15,86,4,0,
10044,15,87,4,0,
10044,15,89,4,0,
10044,15,92,4,0,
10044,15,93,1,1,1
10044,15,94,1,57,
10044,15,94,4,0,
10044,15,104,4,0,
10044,15,105,1,50,
10044,15,112,1,64,
10044,15,113,4,0,
10044,15,115,4,0,
10044,15,126,4,0,
10044,15,129,1,8,
10044,15,133,1,79,
10044,15,138,4,0,
10044,15,148,4,0,
10044,15,156,4,0,
10044,15,157,4,0,
10044,15,164,4,0,
10044,15,182,4,0,
10044,15,201,4,0,
10044,15,207,4,0,
10044,15,214,4,0,
10044,15,216,4,0,
10044,15,218,4,0,
10044,15,219,1,1,3
10044,15,219,4,0,
10044,15,237,4,0,
10044,15,240,4,0,
10044,15,241,4,0,
10044,15,244,1,22,
10044,15,244,4,0,
10044,15,247,4,0,
10044,15,248,1,15,
10044,15,249,4,0,
10044,15,258,4,0,
10044,15,259,4,0,
10044,15,261,4,0,
10044,15,263,4,0,
10044,15,269,4,0,
10044,15,280,4,0,
10044,15,317,4,0,
10044,15,332,4,0,
10044,15,339,4,0,
10044,15,347,4,0,
10044,15,357,1,29,
10044,15,373,4,0,
10044,15,374,4,0,
10044,15,382,1,93,
10044,15,384,1,43,1
10044,15,385,1,43,2
10044,15,396,1,70,
10044,15,398,4,0,
10044,15,411,4,0,
10044,15,412,4,0,
10044,15,416,4,0,
10044,15,427,1,36,
10044,15,433,4,0,
10044,15,444,4,0,
10044,15,447,4,0,
10044,15,451,4,0,
10044,15,473,4,0,
10044,15,490,4,0,
10044,15,496,4,0,
10044,15,510,4,0,
10044,15,523,4,0,
10044,15,540,1,100,
10044,15,590,4,0,
10044,15,612,4,0,
10044,16,7,3,0,
10044,16,8,3,0,
10044,16,9,3,0,
10044,16,50,1,1,2
10044,16,53,4,0,
10044,16,54,1,86,1
10044,16,58,4,0,
10044,16,59,4,0,
10044,16,63,4,0,
10044,16,67,3,0,
10044,16,70,4,0,
10044,16,76,4,0,
10044,16,85,4,0,
10044,16,86,4,0,
10044,16,87,4,0,
10044,16,89,4,0,
10044,16,92,4,0,
10044,16,93,1,1,1
10044,16,94,1,57,1
10044,16,94,4,0,
10044,16,104,4,0,
10044,16,105,1,50,1
10044,16,112,1,64,1
10044,16,113,4,0,
10044,16,115,4,0,
10044,16,126,4,0,
10044,16,129,1,8,1
10044,16,133,1,79,1
10044,16,138,4,0,
10044,16,148,4,0,
10044,16,156,4,0,
10044,16,157,4,0,
10044,16,164,4,0,
10044,16,173,3,0,
10044,16,182,4,0,
10044,16,196,3,0,
10044,16,201,4,0,
10044,16,207,4,0,
10044,16,214,4,0,
10044,16,216,4,0,
10044,16,218,4,0,
10044,16,219,1,1,3
10044,16,219,4,0,
10044,16,231,3,0,
10044,16,237,4,0,
10044,16,240,4,0,
10044,16,241,4,0,
10044,16,244,1,22,1
10044,16,244,4,0,
10044,16,247,4,0,
10044,16,248,1,15,1
10044,16,249,4,0,
10044,16,258,4,0,
10044,16,259,4,0,
10044,16,261,4,0,
10044,16,263,4,0,
10044,16,264,3,0,
10044,16,269,4,0,
10044,16,271,3,0,
10044,16,272,3,0,
10044,16,277,3,0,
10044,16,278,3,0,
10044,16,280,4,0,
10044,16,285,3,0,
10044,16,289,3,0,
10044,16,290,4,0,
10044,16,291,4,0,
10044,16,317,4,0,
10044,16,324,3,0,
10044,16,332,4,0,
10044,16,339,4,0,
10044,16,347,4,0,
10044,16,351,3,0,
10044,16,352,3,0,
10044,16,356,3,0,
10044,16,357,1,29,1
10044,16,373,4,0,
10044,16,374,4,0,
10044,16,382,1,93,1
10044,16,384,1,43,1
10044,16,385,1,43,2
10044,16,396,1,70,1
10044,16,398,4,0,
10044,16,401,3,0,
10044,16,409,3,0,
10044,16,411,4,0,
10044,16,412,4,0,
10044,16,416,4,0,
10044,16,427,1,36,1
10044,16,428,3,0,
10044,16,433,4,0,
10044,16,444,4,0,
10044,16,447,4,0,
10044,16,451,4,0,
10044,16,472,3,0,
10044,16,473,4,0,
10044,16,478,3,0,
10044,16,490,4,0,
10044,16,492,3,0,
10044,16,496,4,0,
10044,16,510,4,0,
10044,16,523,4,0,
10044,16,540,1,100,1
10044,16,590,4,0,
10044,16,612,4,0,
10044,17,50,1,1,4
10044,17,53,4,0,
10044,17,54,1,86,
10044,17,58,4,0,
10044,17,59,4,0,
10044,17,63,4,0,
10044,17,76,4,0,
10044,17,85,4,0,
10044,17,86,4,0,
10044,17,87,4,0,
10044,17,89,4,0,
10044,17,92,4,0,
10044,17,93,1,1,3
10044,17,94,1,57,
10044,17,94,4,0,
10044,17,104,4,0,
10044,17,105,1,50,
10044,17,112,1,64,
10044,17,113,4,0,
10044,17,115,4,0,
10044,17,126,4,0,
10044,17,129,1,8,
10044,17,133,1,79,
10044,17,138,4,0,
10044,17,149,1,1,2
10044,17,156,4,0,
10044,17,157,4,0,
10044,17,164,4,0,
10044,17,182,4,0,
10044,17,201,4,0,
10044,17,207,4,0,
10044,17,214,4,0,
10044,17,216,4,0,
10044,17,218,4,0,
10044,17,219,1,1,5
10044,17,219,4,0,
10044,17,237,4,0,
10044,17,240,4,0,
10044,17,241,4,0,
10044,17,244,1,22,
10044,17,244,4,0,
10044,17,247,4,0,
10044,17,248,1,15,
10044,17,258,4,0,
10044,17,259,4,0,
10044,17,261,4,0,
10044,17,263,4,0,
10044,17,269,4,0,
10044,17,280,4,0,
10044,17,317,4,0,
10044,17,332,4,0,
10044,17,339,4,0,
10044,17,347,4,0,
10044,17,357,1,29,
10044,17,373,4,0,
10044,17,374,4,0,
10044,17,382,1,93,
10044,17,384,1,43,1
10044,17,385,1,43,2
10044,17,396,1,70,
10044,17,398,4,0,
10044,17,411,4,0,
10044,17,412,4,0,
10044,17,416,4,0,
10044,17,427,1,36,
10044,17,433,4,0,
10044,17,444,4,0,
10044,17,447,4,0,
10044,17,451,4,0,
10044,17,473,4,0,
10044,17,490,4,0,
10044,17,496,4,0,
10044,17,523,4,0,
10044,17,540,1,100,
10044,17,590,4,0,
10044,17,673,1,1,1
10044,17,693,4,0,
10044,18,50,1,1,4
10044,18,53,4,0,
10044,18,54,1,86,
10044,18,58,4,0,
10044,18,59,4,0,
10044,18,63,4,0,
10044,18,76,4,0,
10044,18,85,4,0,
10044,18,86,4,0,
10044,18,87,4,0,
10044,18,89,4,0,
10044,18,92,4,0,
10044,18,93,1,1,3
10044,18,94,1,57,
10044,18,94,4,0,
10044,18,104,4,0,
10044,18,105,1,50,
10044,18,112,1,64,
10044,18,113,4,0,
10044,18,115,4,0,
10044,18,126,4,0,
10044,18,129,1,8,
10044,18,133,1,79,
10044,18,138,4,0,
10044,18,149,1,1,2
10044,18,156,4,0,
10044,18,157,4,0,
10044,18,164,4,0,
10044,18,182,4,0,
10044,18,201,4,0,
10044,18,207,4,0,
10044,18,214,4,0,
10044,18,216,4,0,
10044,18,218,4,0,
10044,18,219,1,1,5
10044,18,219,4,0,
10044,18,237,4,0,
10044,18,240,4,0,
10044,18,241,4,0,
10044,18,244,1,22,
10044,18,244,4,0,
10044,18,247,4,0,
10044,18,248,1,15,
10044,18,258,4,0,
10044,18,259,4,0,
10044,18,261,4,0,
10044,18,263,4,0,
10044,18,269,4,0,
10044,18,280,4,0,
10044,18,317,4,0,
10044,18,332,4,0,
10044,18,339,4,0,
10044,18,347,4,0,
10044,18,357,1,29,
10044,18,373,4,0,
10044,18,374,4,0,
10044,18,382,1,93,
10044,18,384,1,43,1
10044,18,385,1,43,2
10044,18,396,1,70,
10044,18,398,4,0,
10044,18,411,4,0,
10044,18,412,4,0,
10044,18,416,4,0,
10044,18,427,1,36,
10044,18,433,4,0,
10044,18,444,4,0,
10044,18,447,4,0,
10044,18,451,4,0,
10044,18,473,4,0,
10044,18,490,4,0,
10044,18,496,4,0,
10044,18,523,4,0,
10044,18,540,1,100,
10044,18,590,4,0,
10044,18,673,1,1,1
10044,18,693,4,0,
10045,15,7,1,1,5
10045,15,9,1,30,
10045,15,33,1,1,6
10045,15,36,1,20,
10045,15,45,1,1,7
10045,15,63,4,0,
10045,15,70,4,0,
10045,15,84,1,1,9
10045,15,84,1,8,
10045,15,85,4,0,
10045,15,86,1,1,8
10045,15,86,1,4,
10045,15,86,4,0,
10045,15,87,1,62,
10045,15,87,4,0,
10045,15,92,4,0,
10045,15,104,4,0,
10045,15,109,1,29,
10045,15,113,1,57,
10045,15,113,4,0,
10045,15,148,4,0,
10045,15,156,4,0,
10045,15,164,4,0,
10045,15,178,1,11,
10045,15,182,4,0,
10045,15,192,1,1,1
10045,15,207,4,0,
10045,15,213,4,0,
10045,15,214,4,0,
10045,15,216,4,0,
10045,15,218,4,0,
10045,15,219,4,0,
10045,15,237,4,0,
10045,15,240,4,0,
10045,15,249,4,0,
10045,15,263,4,0,
10045,15,268,1,16,
10045,15,280,4,0,
10045,15,324,1,51,
10045,15,374,4,0,
10045,15,406,1,1,4
10045,15,406,1,65,
10045,15,408,1,35,
10045,15,411,4,0,
10045,15,416,4,0,
10045,15,435,1,40,
10045,15,451,4,0,
10045,15,486,1,25,
10045,15,496,4,0,
10045,15,497,4,0,
10045,15,521,4,0,
10045,15,523,4,0,
10045,15,528,4,0,
10045,15,538,1,46,
10045,15,569,1,1,3
10045,15,590,4,0,
10045,15,602,1,1,2
10045,15,612,4,0,
10045,16,7,1,1,5
10045,16,7,3,0,
10045,16,9,1,30,1
10045,16,9,3,0,
10045,16,33,1,1,6
10045,16,36,1,20,1
10045,16,45,1,1,7
10045,16,63,4,0,
10045,16,70,4,0,
10045,16,84,1,1,9
10045,16,84,1,8,1
10045,16,85,4,0,
10045,16,86,1,1,8
10045,16,86,1,4,1
10045,16,86,4,0,
10045,16,87,1,62,1
10045,16,87,4,0,
10045,16,92,4,0,
10045,16,104,4,0,
10045,16,109,1,29,1
10045,16,113,1,57,1
10045,16,113,4,0,
10045,16,148,4,0,
10045,16,156,4,0,
10045,16,164,4,0,
10045,16,173,3,0,
10045,16,178,1,11,1
10045,16,182,4,0,
10045,16,192,1,1,1
10045,16,200,3,0,
10045,16,207,4,0,
10045,16,213,4,0,
10045,16,214,4,0,
10045,16,215,3,0,
10045,16,216,4,0,
10045,16,218,4,0,
10045,16,219,4,0,
10045,16,231,3,0,
10045,16,237,4,0,
10045,16,240,4,0,
10045,16,249,4,0,
10045,16,263,4,0,
10045,16,264,3,0,
10045,16,268,1,16,1
10045,16,280,4,0,
10045,16,290,4,0,
10045,16,324,1,51,1
10045,16,324,3,0,
10045,16,351,3,0,
10045,16,374,4,0,
10045,16,393,3,0,
10045,16,406,1,1,4
10045,16,406,1,65,1
10045,16,406,3,0,
10045,16,408,1,35,1
10045,16,411,4,0,
10045,16,416,4,0,
10045,16,435,1,40,1
10045,16,451,4,0,
10045,16,486,1,25,1
10045,16,495,3,0,
10045,16,496,4,0,
10045,16,497,4,0,
10045,16,521,4,0,
10045,16,523,4,0,
10045,16,527,3,0,
10045,16,528,4,0,
10045,16,538,1,46,1
10045,16,569,1,1,3
10045,16,590,4,0,
10045,16,602,1,1,2
10045,16,612,4,0,
10045,17,7,1,1,6
10045,17,9,1,0,
10045,17,9,1,1,1
10045,17,33,1,1,7
10045,17,36,1,20,
10045,17,45,1,1,8
10045,17,63,4,0,
10045,17,84,1,1,10
10045,17,84,1,8,
10045,17,85,4,0,
10045,17,86,1,1,9
10045,17,86,1,4,
10045,17,86,4,0,
10045,17,87,1,62,
10045,17,87,4,0,
10045,17,92,4,0,
10045,17,104,4,0,
10045,17,109,1,29,
10045,17,113,1,57,
10045,17,113,4,0,
10045,17,156,4,0,
10045,17,164,4,0,
10045,17,178,1,11,
10045,17,182,4,0,
10045,17,192,1,1,2
10045,17,207,4,0,
10045,17,213,4,0,
10045,17,214,4,0,
10045,17,216,4,0,
10045,17,218,4,0,
10045,17,219,4,0,
10045,17,237,4,0,
10045,17,240,4,0,
10045,17,263,4,0,
10045,17,268,1,16,
10045,17,280,4,0,
10045,17,324,1,51,
10045,17,374,4,0,
10045,17,406,1,1,5
10045,17,406,1,65,
10045,17,408,1,35,
10045,17,411,4,0,
10045,17,416,4,0,
10045,17,435,1,40,
10045,17,451,4,0,
10045,17,486,1,25,
10045,17,496,4,0,
10045,17,497,4,0,
10045,17,521,4,0,
10045,17,523,4,0,
10045,17,528,4,0,
10045,17,538,1,46,
10045,17,569,1,1,4
10045,17,590,4,0,
10045,17,602,1,1,3
10045,17,693,4,0,
10045,18,7,1,1,6
10045,18,9,1,0,
10045,18,9,1,1,1
10045,18,33,1,1,7
10045,18,36,1,20,
10045,18,45,1,1,8
10045,18,63,4,0,
10045,18,84,1,1,10
10045,18,84,1,8,
10045,18,85,4,0,
10045,18,86,1,1,9
10045,18,86,1,4,
10045,18,86,4,0,
10045,18,87,1,62,
10045,18,87,4,0,
10045,18,92,4,0,
10045,18,104,4,0,
10045,18,109,1,29,
10045,18,113,1,57,
10045,18,113,4,0,
10045,18,156,4,0,
10045,18,164,4,0,
10045,18,178,1,11,
10045,18,182,4,0,
10045,18,192,1,1,2
10045,18,207,4,0,
10045,18,213,4,0,
10045,18,214,4,0,
10045,18,216,4,0,
10045,18,218,4,0,
10045,18,219,4,0,
10045,18,237,4,0,
10045,18,240,4,0,
10045,18,263,4,0,
10045,18,268,1,16,
10045,18,280,4,0,
10045,18,324,1,51,
10045,18,374,4,0,
10045,18,406,1,1,5
10045,18,406,1,65,
10045,18,408,1,35,
10045,18,411,4,0,
10045,18,416,4,0,
10045,18,435,1,40,
10045,18,451,4,0,
10045,18,486,1,25,
10045,18,496,4,0,
10045,18,497,4,0,
10045,18,521,4,0,
10045,18,523,4,0,
10045,18,528,4,0,
10045,18,538,1,46,
10045,18,569,1,1,4
10045,18,590,4,0,
10045,18,602,1,1,3
10045,18,693,4,0,
10046,15,13,1,33,
10046,15,14,1,57,
10046,15,14,4,0,
10046,15,15,4,0,
10046,15,43,1,1,4
10046,15,63,4,0,
10046,15,70,4,0,
10046,15,92,4,0,
10046,15,97,1,17,
10046,15,98,1,1,3
10046,15,104,4,0,
10046,15,113,4,0,
10046,15,116,1,5,
10046,15,156,4,0,
10046,15,163,1,29,
10046,15,164,4,0,
10046,15,168,4,0,
10046,15,182,4,0,
10046,15,201,4,0,
10046,15,206,1,13,
10046,15,206,4,0,
10046,15,207,4,0,
10046,15,210,1,25,
10046,15,211,4,0,
10046,15,213,4,0,
10046,15,214,4,0,
10046,15,216,4,0,
10046,15,218,4,0,
10046,15,219,4,0,
10046,15,228,1,9,
10046,15,232,1,21,
10046,15,237,4,0,
10046,15,240,4,0,
10046,15,241,4,0,
10046,15,249,4,0,
10046,15,263,4,0,
10046,15,280,4,0,
10046,15,332,4,0,
10046,15,334,1,37,
10046,15,355,4,0,
10046,15,364,1,1,1
10046,15,364,1,61,
10046,15,369,4,0,
10046,15,374,4,0,
10046,15,400,1,45,
10046,15,404,1,41,
10046,15,404,4,0,
10046,15,416,4,0,
10046,15,418,1,1,2
10046,15,430,4,0,
10046,15,442,1,50,
10046,15,458,1,49,
10046,15,474,4,0,
10046,15,496,4,0,
10046,15,512,4,0,
10046,15,522,4,0,
10046,15,590,4,0,
10046,16,13,1,33,1
10046,16,14,1,57,1
10046,16,14,4,0,
10046,16,15,4,0,
10046,16,43,1,1,4
10046,16,63,4,0,
10046,16,70,4,0,
10046,16,92,4,0,
10046,16,97,1,17,1
10046,16,98,1,1,3
10046,16,104,4,0,
10046,16,113,4,0,
10046,16,116,1,5,1
10046,16,156,4,0,
10046,16,163,1,29,1
10046,16,164,4,0,
10046,16,168,4,0,
10046,16,173,3,0,
10046,16,182,4,0,
10046,16,201,4,0,
10046,16,206,1,13,1
10046,16,206,4,0,
10046,16,207,4,0,
10046,16,210,1,25,1
10046,16,211,4,0,
10046,16,213,4,0,
10046,16,214,4,0,
10046,16,216,4,0,
10046,16,218,4,0,
10046,16,219,4,0,
10046,16,228,1,9,1
10046,16,232,1,21,1
10046,16,237,4,0,
10046,16,240,4,0,
10046,16,241,4,0,
10046,16,249,4,0,
10046,16,263,4,0,
10046,16,276,3,0,
10046,16,280,4,0,
10046,16,282,3,0,
10046,16,290,4,0,
10046,16,332,4,0,
10046,16,334,1,37,1
10046,16,334,3,0,
10046,16,355,4,0,
10046,16,364,1,1,1
10046,16,364,1,61,1
10046,16,366,3,0,
10046,16,369,4,0,
10046,16,374,4,0,
10046,16,400,1,45,1
10046,16,404,1,41,1
10046,16,404,4,0,
10046,16,416,4,0,
10046,16,418,1,1,2
10046,16,430,4,0,
10046,16,442,1,50,1
10046,16,442,3,0,
10046,16,450,3,0,
10046,16,458,1,49,1
10046,16,474,4,0,
10046,16,496,4,0,
10046,16,512,4,0,
10046,16,522,4,0,
10046,16,590,4,0,
10046,17,13,1,33,
10046,17,14,1,57,
10046,17,14,4,0,
10046,17,43,1,1,4
10046,17,63,4,0,
10046,17,92,4,0,
10046,17,97,1,17,
10046,17,98,1,1,3
10046,17,104,4,0,
10046,17,113,4,0,
10046,17,116,1,5,
10046,17,156,4,0,
10046,17,163,1,29,
10046,17,164,4,0,
10046,17,168,4,0,
10046,17,182,4,0,
10046,17,201,4,0,
10046,17,206,1,13,
10046,17,206,4,0,
10046,17,207,4,0,
10046,17,210,1,25,
10046,17,211,4,0,
10046,17,213,4,0,
10046,17,214,4,0,
10046,17,216,4,0,
10046,17,218,4,0,
10046,17,219,4,0,
10046,17,228,1,9,
10046,17,232,1,21,
10046,17,237,4,0,
10046,17,240,4,0,
10046,17,241,4,0,
10046,17,263,4,0,
10046,17,280,4,0,
10046,17,332,4,0,
10046,17,334,1,37,
10046,17,355,4,0,
10046,17,364,1,1,1
10046,17,364,1,61,
10046,17,369,4,0,
10046,17,374,4,0,
10046,17,400,1,45,
10046,17,404,1,41,
10046,17,404,4,0,
10046,17,416,4,0,
10046,17,418,1,1,2
10046,17,430,4,0,
10046,17,442,1,50,
10046,17,458,1,49,
10046,17,474,4,0,
10046,17,496,4,0,
10046,17,512,4,0,
10046,17,590,4,0,
10046,17,693,4,0,
10046,18,13,1,33,
10046,18,14,1,57,
10046,18,14,4,0,
10046,18,43,1,1,4
10046,18,63,4,0,
10046,18,92,4,0,
10046,18,97,1,17,
10046,18,98,1,1,3
10046,18,104,4,0,
10046,18,113,4,0,
10046,18,116,1,5,
10046,18,156,4,0,
10046,18,163,1,29,
10046,18,164,4,0,
10046,18,168,4,0,
10046,18,182,4,0,
10046,18,201,4,0,
10046,18,206,1,13,
10046,18,206,4,0,
10046,18,207,4,0,
10046,18,210,1,25,
10046,18,211,4,0,
10046,18,213,4,0,
10046,18,214,4,0,
10046,18,216,4,0,
10046,18,218,4,0,
10046,18,219,4,0,
10046,18,228,1,9,
10046,18,232,1,21,
10046,18,237,4,0,
10046,18,240,4,0,
10046,18,241,4,0,
10046,18,263,4,0,
10046,18,280,4,0,
10046,18,332,4,0,
10046,18,334,1,37,
10046,18,355,4,0,
10046,18,364,1,1,1
10046,18,364,1,61,
10046,18,369,4,0,
10046,18,374,4,0,
10046,18,400,1,45,
10046,18,404,1,41,
10046,18,404,4,0,
10046,18,416,4,0,
10046,18,418,1,1,2
10046,18,430,4,0,
10046,18,442,1,50,
10046,18,458,1,49,
10046,18,474,4,0,
10046,18,496,4,0,
10046,18,512,4,0,
10046,18,590,4,0,
10046,18,693,4,0,
10047,15,14,4,0,
10047,15,15,4,0,
10047,15,30,1,1,6
10047,15,31,1,7,
10047,15,33,1,1,4
10047,15,36,1,28,
10047,15,38,2,0,
10047,15,42,1,31,
10047,15,43,1,1,5
10047,15,63,4,0,
10047,15,68,1,19,
10047,15,69,2,0,
10047,15,70,4,0,
10047,15,89,4,0,
10047,15,91,4,0,
10047,15,92,4,0,
10047,15,104,4,0,
10047,15,106,2,0,
10047,15,117,2,0,
10047,15,156,4,0,
10047,15,157,4,0,
10047,15,164,4,0,
10047,15,168,4,0,
10047,15,175,2,0,
10047,15,179,1,43,
10047,15,182,4,0,
10047,15,203,1,1,7
10047,15,206,4,0,
10047,15,207,4,0,
10047,15,213,4,0,
10047,15,214,4,0,
10047,15,216,4,0,
10047,15,218,4,0,
10047,15,224,1,46,
10047,15,224,2,0,
10047,15,228,2,0,
10047,15,237,4,0,
10047,15,240,4,0,
10047,15,241,4,0,
10047,15,249,4,0,
10047,15,263,4,0,
10047,15,264,2,0,
10047,15,279,2,0,
10047,15,280,1,25,
10047,15,280,4,0,
10047,15,292,1,1,1
10047,15,317,4,0,
10047,15,331,1,1,2
10047,15,332,1,10,
10047,15,332,4,0,
10047,15,339,4,0,
10047,15,350,2,0,
10047,15,364,1,37,
10047,15,370,1,34,
10047,15,374,4,0,
10047,15,400,1,1,3
10047,15,411,4,0,
10047,15,416,4,0,
10047,15,421,4,0,
10047,15,444,4,0,
10047,15,474,4,0,
10047,15,479,4,0,
10047,15,496,4,0,
10047,15,498,1,16,
10047,15,514,4,0,
10047,15,522,4,0,
10047,15,523,4,0,
10047,15,590,4,0,
10047,16,14,4,0,
10047,16,15,4,0,
10047,16,30,1,1,6
10047,16,31,1,25,1
10047,16,33,1,1,4
10047,16,36,1,34,1
10047,16,42,1,31,1
10047,16,43,1,1,5
10047,16,63,4,0,
10047,16,67,3,0,
10047,16,68,1,19,1
10047,16,70,4,0,
10047,16,89,4,0,
10047,16,91,4,0,
10047,16,92,4,0,
10047,16,104,4,0,
10047,16,156,4,0,
10047,16,157,4,0,
10047,16,164,4,0,
10047,16,168,4,0,
10047,16,173,3,0,
10047,16,179,1,46,1
10047,16,182,4,0,
10047,16,203,1,1,7
10047,16,206,4,0,
10047,16,207,4,0,
10047,16,213,4,0,
10047,16,214,4,0,
10047,16,216,4,0,
10047,16,218,4,0,
10047,16,224,1,37,1
10047,16,237,4,0,
10047,16,240,4,0,
10047,16,241,4,0,
10047,16,249,4,0,
10047,16,263,4,0,
10047,16,264,3,0,
10047,16,270,3,0,
10047,16,280,1,28,1
10047,16,280,4,0,
10047,16,282,3,0,
10047,16,290,4,0,
10047,16,292,1,1,1
10047,16,317,4,0,
10047,16,331,1,1,2
10047,16,332,1,10,1
10047,16,332,4,0,
10047,16,334,3,0,
10047,16,339,4,0,
10047,16,364,1,7,1
10047,16,370,1,43,1
10047,16,374,4,0,
10047,16,400,1,1,3
10047,16,411,4,0,
10047,16,416,4,0,
10047,16,421,4,0,
10047,16,444,4,0,
10047,16,450,3,0,
10047,16,474,4,0,
10047,16,479,4,0,
10047,16,496,4,0,
10047,16,498,1,16,1
10047,16,514,4,0,
10047,16,522,4,0,
10047,16,523,4,0,
10047,16,590,4,0,
10047,17,14,4,0,
10047,17,30,1,1,6
10047,17,31,1,25,
10047,17,33,1,1,4
10047,17,36,1,34,
10047,17,42,1,31,
10047,17,43,1,1,5
10047,17,63,4,0,
10047,17,68,1,19,
10047,17,89,4,0,
10047,17,92,4,0,
10047,17,104,4,0,
10047,17,156,4,0,
10047,17,157,4,0,
10047,17,164,4,0,
10047,17,168,4,0,
10047,17,179,1,46,
10047,17,182,4,0,
10047,17,203,1,1,7
10047,17,206,4,0,
10047,17,207,4,0,
10047,17,213,4,0,
10047,17,214,4,0,
10047,17,216,4,0,
10047,17,218,4,0,
10047,17,224,1,37,
10047,17,237,4,0,
10047,17,240,4,0,
10047,17,241,4,0,
10047,17,263,4,0,
10047,17,280,1,28,
10047,17,280,4,0,
10047,17,292,1,1,1
10047,17,317,4,0,
10047,17,331,1,1,2
10047,17,332,1,10,
10047,17,332,4,0,
10047,17,339,4,0,
10047,17,364,1,7,
10047,17,370,1,43,
10047,17,374,4,0,
10047,17,400,1,1,3
10047,17,411,4,0,
10047,17,416,4,0,
10047,17,421,4,0,
10047,17,444,4,0,
10047,17,474,4,0,
10047,17,479,4,0,
10047,17,496,4,0,
10047,17,498,1,16,
10047,17,523,4,0,
10047,17,526,4,0,
10047,17,590,4,0,
10047,17,693,4,0,
10047,18,14,4,0,
10047,18,30,1,1,6
10047,18,31,1,25,
10047,18,33,1,1,4
10047,18,36,1,34,
10047,18,42,1,31,
10047,18,43,1,1,5
10047,18,63,4,0,
10047,18,68,1,19,
10047,18,89,4,0,
10047,18,92,4,0,
10047,18,104,4,0,
10047,18,156,4,0,
10047,18,157,4,0,
10047,18,164,4,0,
10047,18,168,4,0,
10047,18,179,1,46,
10047,18,182,4,0,
10047,18,203,1,1,7
10047,18,206,4,0,
10047,18,207,4,0,
10047,18,213,4,0,
10047,18,214,4,0,
10047,18,216,4,0,
10047,18,218,4,0,
10047,18,224,1,37,
10047,18,237,4,0,
10047,18,240,4,0,
10047,18,241,4,0,
10047,18,263,4,0,
10047,18,280,1,28,
10047,18,280,4,0,
10047,18,292,1,1,1
10047,18,317,4,0,
10047,18,331,1,1,2
10047,18,332,1,10,
10047,18,332,4,0,
10047,18,339,4,0,
10047,18,364,1,7,
10047,18,370,1,43,
10047,18,374,4,0,
10047,18,400,1,1,3
10047,18,411,4,0,
10047,18,416,4,0,
10047,18,421,4,0,
10047,18,444,4,0,
10047,18,474,4,0,
10047,18,479,4,0,
10047,18,496,4,0,
10047,18,498,1,16,
10047,18,523,4,0,
10047,18,526,4,0,
10047,18,590,4,0,
10047,18,693,4,0,
10048,15,43,1,1,4
10048,15,44,1,16,
10048,15,46,1,13,
10048,15,46,4,0,
10048,15,52,1,1,5
10048,15,53,1,50,
10048,15,53,4,0,
10048,15,63,4,0,
10048,15,70,4,0,
10048,15,76,4,0,
10048,15,92,4,0,
10048,15,104,4,0,
10048,15,123,1,1,7
10048,15,123,1,8,
10048,15,126,4,0,
10048,15,138,4,0,
10048,15,156,4,0,
10048,15,164,4,0,
10048,15,168,4,0,
10048,15,182,4,0,
10048,15,185,1,35,
10048,15,188,4,0,
10048,15,207,4,0,
10048,15,213,4,0,
10048,15,214,4,0,
10048,15,216,4,0,
10048,15,218,4,0,
10048,15,237,4,0,
10048,15,241,4,0,
10048,15,242,1,56,
10048,15,247,4,0,
10048,15,249,4,0,
10048,15,251,1,26,
10048,15,259,4,0,
10048,15,261,4,0,
10048,15,263,4,0,
10048,15,269,4,0,
10048,15,315,4,0,
10048,15,316,1,20,
10048,15,336,1,1,6
10048,15,336,1,4,
10048,15,371,4,0,
10048,15,373,1,41,
10048,15,373,4,0,
10048,15,399,4,0,
10048,15,416,4,0,
10048,15,417,1,1,2
10048,15,417,1,60,
10048,15,422,1,1,3
10048,15,424,1,30,
10048,15,488,4,0,
10048,15,492,1,45,
10048,15,496,4,0,
10048,15,510,4,0,
10048,15,514,4,0,
10048,15,517,1,1,1
10048,15,517,1,65,
10048,15,555,4,0,
10048,15,590,4,0,
10048,16,43,1,1,4
10048,16,44,1,16,1
10048,16,46,1,13,1
10048,16,46,4,0,
10048,16,52,1,1,5
10048,16,53,1,50,1
10048,16,53,4,0,
10048,16,63,4,0,
10048,16,70,4,0,
10048,16,76,4,0,
10048,16,92,4,0,
10048,16,104,4,0,
10048,16,123,1,1,7
10048,16,123,1,8,1
10048,16,126,4,0,
10048,16,138,4,0,
10048,16,156,4,0,
10048,16,162,3,0,
10048,16,164,4,0,
10048,16,168,4,0,
10048,16,173,3,0,
10048,16,180,3,0,
10048,16,182,4,0,
10048,16,185,1,35,1
10048,16,188,4,0,
10048,16,207,4,0,
10048,16,213,4,0,
10048,16,214,4,0,
10048,16,216,4,0,
10048,16,218,4,0,
10048,16,231,3,0,
10048,16,237,4,0,
10048,16,241,4,0,
10048,16,242,1,56,1
10048,16,247,4,0,
10048,16,249,4,0,
10048,16,251,1,26,1
10048,16,253,3,0,
10048,16,257,3,0,
10048,16,259,4,0,
10048,16,261,4,0,
10048,16,263,4,0,
10048,16,269,4,0,
10048,16,272,3,0,
10048,16,289,3,0,
10048,16,290,4,0,
10048,16,304,3,0,
10048,16,315,4,0,
10048,16,316,1,20,1
10048,16,336,1,1,6
10048,16,336,1,4,1
10048,16,371,4,0,
10048,16,373,1,41,1
10048,16,373,4,0,
10048,16,399,4,0,
10048,16,416,4,0,
10048,16,417,1,1,2
10048,16,417,1,60,1
10048,16,422,1,1,3
10048,16,424,1,30,1
10048,16,488,4,0,
10048,16,492,1,45,1
10048,16,492,3,0,
10048,16,496,4,0,
10048,16,510,4,0,
10048,16,514,4,0,
10048,16,517,1,1,1
10048,16,517,1,65,1
10048,16,555,4,0,
10048,16,590,4,0,
10048,17,43,1,1,4
10048,17,44,1,16,
10048,17,46,1,13,
10048,17,46,4,0,
10048,17,52,1,1,5
10048,17,53,1,50,
10048,17,53,4,0,
10048,17,63,4,0,
10048,17,76,4,0,
10048,17,92,4,0,
10048,17,104,4,0,
10048,17,123,1,1,7
10048,17,123,1,8,
10048,17,126,4,0,
10048,17,138,4,0,
10048,17,156,4,0,
10048,17,164,4,0,
10048,17,168,4,0,
10048,17,182,4,0,
10048,17,185,1,35,
10048,17,188,4,0,
10048,17,207,4,0,
10048,17,213,4,0,
10048,17,214,4,0,
10048,17,216,4,0,
10048,17,218,4,0,
10048,17,237,4,0,
10048,17,241,4,0,
10048,17,242,1,56,
10048,17,247,4,0,
10048,17,251,1,26,
10048,17,259,4,0,
10048,17,261,4,0,
10048,17,263,4,0,
10048,17,269,4,0,
10048,17,315,4,0,
10048,17,316,1,20,
10048,17,336,1,1,6
10048,17,336,1,4,
10048,17,371,4,0,
10048,17,373,1,41,
10048,17,373,4,0,
10048,17,399,4,0,
10048,17,416,4,0,
10048,17,417,1,1,2
10048,17,417,1,60,
10048,17,422,1,1,3
10048,17,424,1,30,
10048,17,488,4,0,
10048,17,492,1,45,
10048,17,496,4,0,
10048,17,517,1,1,1
10048,17,517,1,65,
10048,17,555,4,0,
10048,17,590,4,0,
10048,18,43,1,1,4
10048,18,44,1,16,
10048,18,46,1,13,
10048,18,46,4,0,
10048,18,52,1,1,5
10048,18,53,1,50,
10048,18,53,4,0,
10048,18,63,4,0,
10048,18,76,4,0,
10048,18,92,4,0,
10048,18,104,4,0,
10048,18,123,1,1,7
10048,18,123,1,8,
10048,18,126,4,0,
10048,18,138,4,0,
10048,18,156,4,0,
10048,18,164,4,0,
10048,18,168,4,0,
10048,18,182,4,0,
10048,18,185,1,35,
10048,18,188,4,0,
10048,18,207,4,0,
10048,18,213,4,0,
10048,18,214,4,0,
10048,18,216,4,0,
10048,18,218,4,0,
10048,18,237,4,0,
10048,18,241,4,0,
10048,18,242,1,56,
10048,18,247,4,0,
10048,18,251,1,26,
10048,18,259,4,0,
10048,18,261,4,0,
10048,18,263,4,0,
10048,18,269,4,0,
10048,18,315,4,0,
10048,18,316,1,20,
10048,18,336,1,1,6
10048,18,336,1,4,
10048,18,371,4,0,
10048,18,373,1,41,
10048,18,373,4,0,
10048,18,399,4,0,
10048,18,416,4,0,
10048,18,417,1,1,2
10048,18,417,1,60,
10048,18,422,1,1,3
10048,18,424,1,30,
10048,18,488,4,0,
10048,18,492,1,45,
10048,18,496,4,0,
10048,18,517,1,1,1
10048,18,517,1,65,
10048,18,555,4,0,
10048,18,590,4,0,
10049,15,15,4,0,
10049,15,37,1,28,
10049,15,43,1,1,5
10049,15,44,1,1,4
10049,15,46,4,0,
10049,15,53,4,0,
10049,15,57,4,0,
10049,15,58,4,0,
10049,15,59,4,0,
10049,15,63,1,73,
10049,15,63,4,0,
10049,15,70,4,0,
10049,15,85,4,0,
10049,15,86,4,0,
10049,15,87,4,0,
10049,15,89,1,54,
10049,15,89,4,0,
10049,15,91,4,0,
10049,15,92,4,0,
10049,15,103,1,1,7
10049,15,103,1,10,
10049,15,104,4,0,
10049,15,126,4,0,
10049,15,156,4,0,
10049,15,157,1,19,
10049,15,157,4,0,
10049,15,164,4,0,
10049,15,182,4,0,
10049,15,184,1,23,
10049,15,201,1,1,6
10049,15,201,1,5,
10049,15,201,4,0,
10049,15,207,4,0,
10049,15,213,4,0,
10049,15,214,4,0,
10049,15,216,4,0,
10049,15,218,4,0,
10049,15,237,4,0,
10049,15,240,4,0,
10049,15,241,4,0,
10049,15,242,1,47,
10049,15,249,4,0,
10049,15,259,4,0,
10049,15,263,4,0,
10049,15,269,4,0,
10049,15,280,4,0,
10049,15,317,4,0,
10049,15,332,4,0,
10049,15,337,4,0,
10049,15,371,1,41,
10049,15,371,4,0,
10049,15,374,4,0,
10049,15,397,4,0,
10049,15,399,1,34,
10049,15,399,4,0,
10049,15,411,4,0,
10049,15,416,1,82,
10049,15,416,4,0,
10049,15,421,4,0,
10049,15,422,1,1,1
10049,15,423,1,1,2
10049,15,424,1,1,3
10049,15,444,1,63,
10049,15,444,4,0,
10049,15,468,4,0,
10049,15,479,4,0,
10049,15,496,4,0,
10049,15,498,1,14,
10049,15,510,4,0,
10049,15,514,4,0,
10049,15,523,4,0,
10049,15,525,4,0,
10049,15,555,4,0,
10049,15,590,4,0,
10049,15,612,4,0,
10049,16,7,3,0,
10049,16,8,3,0,
10049,16,9,3,0,
10049,16,15,4,0,
10049,16,37,1,28,1
10049,16,43,1,1,5
10049,16,44,1,1,4
10049,16,46,4,0,
10049,16,53,4,0,
10049,16,57,4,0,
10049,16,58,4,0,
10049,16,59,4,0,
10049,16,63,1,73,1
10049,16,63,4,0,
10049,16,67,3,0,
10049,16,70,4,0,
10049,16,85,4,0,
10049,16,86,4,0,
10049,16,87,4,0,
10049,16,89,1,54,1
10049,16,89,4,0,
10049,16,91,4,0,
10049,16,92,4,0,
10049,16,103,1,1,7
10049,16,103,1,10,1
10049,16,104,4,0,
10049,16,126,4,0,
10049,16,156,4,0,
10049,16,157,1,19,1
10049,16,157,4,0,
10049,16,164,4,0,
10049,16,173,3,0,
10049,16,180,3,0,
10049,16,182,4,0,
10049,16,184,1,23,1
10049,16,200,3,0,
10049,16,201,1,1,6
10049,16,201,1,5,1
10049,16,201,4,0,
10049,16,207,4,0,
10049,16,213,4,0,
10049,16,214,4,0,
10049,16,216,4,0,
10049,16,218,4,0,
10049,16,231,3,0,
10049,16,237,4,0,
10049,16,240,4,0,
10049,16,241,4,0,
10049,16,242,1,47,1
10049,16,249,4,0,
10049,16,253,3,0,
10049,16,259,4,0,
10049,16,263,4,0,
10049,16,264,3,0,
10049,16,269,4,0,
10049,16,276,3,0,
10049,16,280,4,0,
10049,16,290,4,0,
10049,16,317,4,0,
10049,16,332,4,0,
10049,16,334,3,0,
10049,16,335,3,0,
10049,16,337,4,0,
10049,16,351,3,0,
10049,16,352,3,0,
10049,16,371,1,41,1
10049,16,371,4,0,
10049,16,374,4,0,
10049,16,397,4,0,
10049,16,399,1,34,1
10049,16,399,4,0,
10049,16,401,3,0,
10049,16,406,3,0,
10049,16,411,4,0,
10049,16,414,3,0,
10049,16,416,1,82,1
10049,16,416,4,0,
10049,16,421,4,0,
10049,16,422,1,1,1
10049,16,423,1,1,2
10049,16,424,1,1,3
10049,16,442,3,0,
10049,16,444,1,63,1
10049,16,444,4,0,
10049,16,446,3,0,
10049,16,468,4,0,
10049,16,479,4,0,
10049,16,492,3,0,
10049,16,496,4,0,
10049,16,498,1,14,1
10049,16,510,4,0,
10049,16,514,4,0,
10049,16,523,4,0,
10049,16,525,4,0,
10049,16,555,4,0,
10049,16,590,4,0,
10049,16,612,4,0,
10049,17,37,1,28,
10049,17,43,1,1,5
10049,17,44,1,1,4
10049,17,46,4,0,
10049,17,53,4,0,
10049,17,57,4,0,
10049,17,58,4,0,
10049,17,59,4,0,
10049,17,63,1,73,
10049,17,63,4,0,
10049,17,85,4,0,
10049,17,86,4,0,
10049,17,87,4,0,
10049,17,89,1,54,
10049,17,89,4,0,
10049,17,92,4,0,
10049,17,103,1,1,7
10049,17,103,1,10,
10049,17,104,4,0,
10049,17,126,4,0,
10049,17,156,4,0,
10049,17,157,1,19,
10049,17,157,4,0,
10049,17,164,4,0,
10049,17,182,4,0,
10049,17,184,1,23,
10049,17,201,1,1,6
10049,17,201,1,5,
10049,17,201,4,0,
10049,17,207,4,0,
10049,17,213,4,0,
10049,17,214,4,0,
10049,17,216,4,0,
10049,17,218,4,0,
10049,17,237,4,0,
10049,17,240,4,0,
10049,17,241,4,0,
10049,17,242,1,47,
10049,17,259,4,0,
10049,17,263,4,0,
10049,17,269,4,0,
10049,17,280,4,0,
10049,17,317,4,0,
10049,17,332,4,0,
10049,17,337,4,0,
10049,17,371,1,41,
10049,17,371,4,0,
10049,17,374,4,0,
10049,17,397,4,0,
10049,17,399,1,34,
10049,17,399,4,0,
10049,17,411,4,0,
10049,17,416,1,82,
10049,17,416,4,0,
10049,17,421,4,0,
10049,17,422,1,1,1
10049,17,423,1,1,2
10049,17,424,1,1,3
10049,17,444,1,63,
10049,17,444,4,0,
10049,17,479,4,0,
10049,17,496,4,0,
10049,17,498,1,14,
10049,17,523,4,0,
10049,17,525,4,0,
10049,17,555,4,0,
10049,17,590,4,0,
10049,17,693,4,0,
10049,18,37,1,28,
10049,18,43,1,1,5
10049,18,44,1,1,4
10049,18,46,4,0,
10049,18,53,4,0,
10049,18,57,4,0,
10049,18,58,4,0,
10049,18,59,4,0,
10049,18,63,1,73,
10049,18,63,4,0,
10049,18,85,4,0,
10049,18,86,4,0,
10049,18,87,4,0,
10049,18,89,1,54,
10049,18,89,4,0,
10049,18,92,4,0,
10049,18,103,1,1,7
10049,18,103,1,10,
10049,18,104,4,0,
10049,18,126,4,0,
10049,18,156,4,0,
10049,18,157,1,19,
10049,18,157,4,0,
10049,18,164,4,0,
10049,18,182,4,0,
10049,18,184,1,23,
10049,18,201,1,1,6
10049,18,201,1,5,
10049,18,201,4,0,
10049,18,207,4,0,
10049,18,213,4,0,
10049,18,214,4,0,
10049,18,216,4,0,
10049,18,218,4,0,
10049,18,237,4,0,
10049,18,240,4,0,
10049,18,241,4,0,
10049,18,242,1,47,
10049,18,259,4,0,
10049,18,263,4,0,
10049,18,269,4,0,
10049,18,280,4,0,
10049,18,317,4,0,
10049,18,332,4,0,
10049,18,337,4,0,
10049,18,371,1,41,
10049,18,371,4,0,
10049,18,374,4,0,
10049,18,397,4,0,
10049,18,399,1,34,
10049,18,399,4,0,
10049,18,411,4,0,
10049,18,416,1,82,
10049,18,416,4,0,
10049,18,421,4,0,
10049,18,422,1,1,1
10049,18,423,1,1,2
10049,18,424,1,1,3
10049,18,444,1,63,
10049,18,444,4,0,
10049,18,479,4,0,
10049,18,496,4,0,
10049,18,498,1,14,
10049,18,523,4,0,
10049,18,525,4,0,
10049,18,555,4,0,
10049,18,590,4,0,
10049,18,693,4,0,
10050,15,7,1,1,2
10050,15,10,1,1,4
10050,15,14,4,0,
10050,15,15,4,0,
10050,15,24,1,16,
10050,15,28,1,21,
10050,15,45,1,1,5
10050,15,46,4,0,
10050,15,52,1,1,7
10050,15,52,1,13,
10050,15,53,4,0,
10050,15,63,4,0,
10050,15,64,1,17,
10050,15,70,4,0,
10050,15,76,4,0,
10050,15,89,4,0,
10050,15,91,4,0,
10050,15,92,4,0,
10050,15,98,1,32,
10050,15,104,4,0,
10050,15,116,1,1,6
10050,15,116,1,7,
10050,15,126,4,0,
10050,15,136,1,1,3
10050,15,156,4,0,
10050,15,157,4,0,
10050,15,163,1,42,
10050,15,164,4,0,
10050,15,182,4,0,
10050,15,207,4,0,
10050,15,213,4,0,
10050,15,214,4,0,
10050,15,216,4,0,
10050,15,218,4,0,
10050,15,237,4,0,
10050,15,241,4,0,
10050,15,249,4,0,
10050,15,261,4,0,
10050,15,263,4,0,
10050,15,280,4,0,
10050,15,299,1,36,
10050,15,307,3,0,
10050,15,315,4,0,
10050,15,317,4,0,
10050,15,327,1,59,
10050,15,332,4,0,
10050,15,339,1,28,
10050,15,339,4,0,
10050,15,374,4,0,
10050,15,394,1,1,1
10050,15,394,1,66,
10050,15,398,4,0,
10050,15,411,4,0,
10050,15,413,1,49,
10050,15,416,4,0,
10050,15,421,4,0,
10050,15,444,4,0,
10050,15,468,4,0,
10050,15,488,4,0,
10050,15,490,4,0,
10050,15,496,4,0,
10050,15,497,4,0,
10050,15,510,4,0,
10050,15,512,4,0,
10050,15,519,3,0,
10050,15,523,4,0,
10050,15,590,4,0,
10050,15,612,4,0,
10050,16,7,1,1,2
10050,16,7,3,0,
10050,16,9,3,0,
10050,16,10,1,1,4
10050,16,14,4,0,
10050,16,15,4,0,
10050,16,24,1,16,1
10050,16,28,1,1,7
10050,16,28,1,10,1
10050,16,45,1,1,5
10050,16,46,4,0,
10050,16,52,1,1,6
10050,16,52,1,5,1
10050,16,53,4,0,
10050,16,63,4,0,
10050,16,64,1,14,1
10050,16,67,3,0,
10050,16,70,4,0,
10050,16,76,4,0,
10050,16,89,4,0,
10050,16,91,4,0,
10050,16,92,4,0,
10050,16,98,1,25,1
10050,16,104,4,0,
10050,16,116,1,37,1
10050,16,126,4,0,
10050,16,136,1,1,3
10050,16,156,4,0,
10050,16,157,4,0,
10050,16,163,1,44,1
10050,16,164,4,0,
10050,16,173,3,0,
10050,16,182,4,0,
10050,16,207,4,0,
10050,16,213,4,0,
10050,16,214,4,0,
10050,16,216,4,0,
10050,16,218,4,0,
10050,16,237,4,0,
10050,16,241,4,0,
10050,16,249,4,0,
10050,16,257,3,0,
10050,16,261,4,0,
10050,16,263,4,0,
10050,16,264,3,0,
10050,16,270,3,0,
10050,16,272,3,0,
10050,16,276,3,0,
10050,16,280,4,0,
10050,16,282,3,0,
10050,16,290,4,0,
10050,16,299,1,36,1
10050,16,307,3,0,
10050,16,315,4,0,
10050,16,317,4,0,
10050,16,327,1,57,1
10050,16,332,4,0,
10050,16,339,1,31,1
10050,16,339,4,0,
10050,16,340,3,0,
10050,16,374,4,0,
10050,16,387,3,0,
10050,16,394,1,1,1
10050,16,394,1,63,1
10050,16,398,4,0,
10050,16,411,4,0,
10050,16,413,1,50,1
10050,16,416,4,0,
10050,16,421,4,0,
10050,16,444,4,0,
10050,16,468,4,0,
10050,16,488,1,20,1
10050,16,488,4,0,
10050,16,490,4,0,
10050,16,496,4,0,
10050,16,497,4,0,
10050,16,510,4,0,
10050,16,512,4,0,
10050,16,519,3,0,
10050,16,523,4,0,
10050,16,530,3,0,
10050,16,590,4,0,
10050,16,612,4,0,
10050,17,7,1,1,4
10050,17,10,1,1,6
10050,17,14,4,0,
10050,17,24,1,1,2
10050,17,28,1,1,9
10050,17,28,1,10,
10050,17,45,1,1,7
10050,17,46,4,0,
10050,17,52,1,1,8
10050,17,52,1,5,
10050,17,53,4,0,
10050,17,63,4,0,
10050,17,64,1,14,
10050,17,76,4,0,
10050,17,89,4,0,
10050,17,92,4,0,
10050,17,98,1,25,
10050,17,104,4,0,
10050,17,116,1,37,
10050,17,126,4,0,
10050,17,136,1,1,5
10050,17,156,4,0,
10050,17,157,4,0,
10050,17,163,1,44,
10050,17,164,4,0,
10050,17,182,4,0,
10050,17,207,4,0,
10050,17,213,4,0,
10050,17,214,4,0,
10050,17,216,4,0,
10050,17,218,4,0,
10050,17,237,4,0,
10050,17,241,4,0,
10050,17,261,4,0,
10050,17,263,4,0,
10050,17,280,4,0,
10050,17,299,1,0,
10050,17,299,1,1,1
10050,17,307,3,0,
10050,17,315,4,0,
10050,17,317,4,0,
10050,17,327,1,57,
10050,17,332,4,0,
10050,17,339,1,31,
10050,17,339,4,0,
10050,17,374,4,0,
10050,17,394,1,1,3
10050,17,394,1,63,
10050,17,398,4,0,
10050,17,411,4,0,
10050,17,413,1,50,
10050,17,416,4,0,
10050,17,421,4,0,
10050,17,444,4,0,
10050,17,488,1,20,
10050,17,488,4,0,
10050,17,490,4,0,
10050,17,496,4,0,
10050,17,497,4,0,
10050,17,512,4,0,
10050,17,519,3,0,
10050,17,523,4,0,
10050,17,526,4,0,
10050,17,590,4,0,
10050,18,7,1,1,4
10050,18,10,1,1,6
10050,18,14,4,0,
10050,18,24,1,1,2
10050,18,28,1,1,9
10050,18,28,1,10,
10050,18,45,1,1,7
10050,18,46,4,0,
10050,18,52,1,1,8
10050,18,52,1,5,
10050,18,53,4,0,
10050,18,63,4,0,
10050,18,64,1,14,
10050,18,76,4,0,
10050,18,89,4,0,
10050,18,92,4,0,
10050,18,98,1,25,
10050,18,104,4,0,
10050,18,116,1,37,
10050,18,126,4,0,
10050,18,136,1,1,5
10050,18,156,4,0,
10050,18,157,4,0,
10050,18,163,1,44,
10050,18,164,4,0,
10050,18,182,4,0,
10050,18,207,4,0,
10050,18,213,4,0,
10050,18,214,4,0,
10050,18,216,4,0,
10050,18,218,4,0,
10050,18,237,4,0,
10050,18,241,4,0,
10050,18,261,4,0,
10050,18,263,4,0,
10050,18,280,4,0,
10050,18,299,1,0,
10050,18,299,1,1,1
10050,18,307,3,0,
10050,18,315,4,0,
10050,18,317,4,0,
10050,18,327,1,57,
10050,18,332,4,0,
10050,18,339,1,31,
10050,18,339,4,0,
10050,18,374,4,0,
10050,18,394,1,1,3
10050,18,394,1,63,
10050,18,398,4,0,
10050,18,411,4,0,
10050,18,413,1,50,
10050,18,416,4,0,
10050,18,421,4,0,
10050,18,444,4,0,
10050,18,488,1,20,
10050,18,488,4,0,
10050,18,490,4,0,
10050,18,496,4,0,
10050,18,497,4,0,
10050,18,512,4,0,
10050,18,519,3,0,
10050,18,523,4,0,
10050,18,526,4,0,
10050,18,590,4,0,
10051,15,45,1,1,5
10051,15,63,4,0,
10051,15,85,4,0,
10051,15,86,4,0,
10051,15,92,4,0,
10051,15,93,1,1,6
10051,15,93,1,6,
10051,15,94,1,40,
10051,15,94,4,0,
10051,15,95,1,65,
10051,15,100,1,1,8
10051,15,100,1,12,
10051,15,104,1,1,7
10051,15,104,1,10,
10051,15,104,4,0,
10051,15,113,4,0,
10051,15,115,4,0,
10051,15,138,1,73,
10051,15,138,4,0,
10051,15,148,4,0,
10051,15,156,4,0,
10051,15,164,4,0,
10051,15,168,4,0,
10051,15,182,4,0,
10051,15,207,4,0,
10051,15,213,4,0,
10051,15,214,4,0,
10051,15,216,4,0,
10051,15,218,4,0,
10051,15,219,4,0,
10051,15,237,4,0,
10051,15,240,4,0,
10051,15,241,4,0,
10051,15,244,4,0,
10051,15,247,4,0,
10051,15,248,1,53,
10051,15,259,4,0,
10051,15,261,4,0,
10051,15,263,4,0,
10051,15,269,4,0,
10051,15,273,1,17,
10051,15,286,1,45,
10051,15,345,1,22,
10051,15,347,1,33,
10051,15,347,4,0,
10051,15,361,1,1,4
10051,15,374,4,0,
10051,15,411,4,0,
10051,15,412,4,0,
10051,15,416,4,0,
10051,15,433,4,0,
10051,15,445,1,60,
10051,15,447,4,0,
10051,15,451,4,0,
10051,15,473,4,0,
10051,15,496,4,0,
10051,15,497,4,0,
10051,15,500,1,1,2
10051,15,500,1,80,
10051,15,505,1,25,
10051,15,581,1,1,3
10051,15,585,1,1,1
10051,15,585,1,85,
10051,15,590,4,0,
10051,15,605,4,0,
10051,16,7,3,0,
10051,16,8,3,0,
10051,16,9,3,0,
10051,16,45,1,1,5
10051,16,63,4,0,
10051,16,85,4,0,
10051,16,86,4,0,
10051,16,92,4,0,
10051,16,93,1,1,6
10051,16,93,1,4,1
10051,16,94,1,31,1
10051,16,94,4,0,
10051,16,95,1,49,1
10051,16,100,1,1,8
10051,16,100,1,9,1
10051,16,104,1,1,7
10051,16,104,1,6,1
10051,16,104,4,0,
10051,16,113,4,0,
10051,16,115,4,0,
10051,16,138,1,53,1
10051,16,138,4,0,
10051,16,148,4,0,
10051,16,156,4,0,
10051,16,164,4,0,
10051,16,168,4,0,
10051,16,173,3,0,
10051,16,182,4,0,
10051,16,196,3,0,
10051,16,207,4,0,
10051,16,213,4,0,
10051,16,214,4,0,
10051,16,215,3,0,
10051,16,216,4,0,
10051,16,218,4,0,
10051,16,219,4,0,
10051,16,220,3,0,
10051,16,237,4,0,
10051,16,240,4,0,
10051,16,241,4,0,
10051,16,244,4,0,
10051,16,247,4,0,
10051,16,248,1,40,1
10051,16,259,4,0,
10051,16,261,4,0,
10051,16,263,4,0,
10051,16,269,4,0,
10051,16,270,3,0,
10051,16,271,3,0,
10051,16,273,1,14,1
10051,16,277,3,0,
10051,16,278,3,0,
10051,16,285,3,0,
10051,16,286,1,35,1
10051,16,289,3,0,
10051,16,290,4,0,
10051,16,304,3,0,
10051,16,324,3,0,
10051,16,345,1,17,1
10051,16,347,1,26,1
10051,16,347,4,0,
10051,16,351,3,0,
10051,16,361,1,1,4
10051,16,374,4,0,
10051,16,411,4,0,
10051,16,412,4,0,
10051,16,416,4,0,
10051,16,428,3,0,
10051,16,433,4,0,
10051,16,445,1,44,1
10051,16,447,4,0,
10051,16,451,4,0,
10051,16,472,3,0,
10051,16,473,4,0,
10051,16,478,3,0,
10051,16,496,4,0,
10051,16,497,4,0,
10051,16,500,1,1,2
10051,16,500,1,58,1
10051,16,505,1,19,1
10051,16,574,1,11,1
10051,16,577,1,23,1
10051,16,581,1,1,3
10051,16,585,1,1,1
10051,16,585,1,62,1
10051,16,590,4,0,
10051,16,605,4,0,
10051,17,45,1,1,5
10051,17,63,4,0,
10051,17,85,4,0,
10051,17,86,4,0,
10051,17,92,4,0,
10051,17,93,1,1,6
10051,17,93,1,4,
10051,17,94,1,31,
10051,17,94,4,0,
10051,17,95,1,49,
10051,17,100,1,1,8
10051,17,100,1,9,
10051,17,104,1,1,7
10051,17,104,1,6,
10051,17,104,4,0,
10051,17,113,4,0,
10051,17,115,4,0,
10051,17,138,1,53,
10051,17,138,4,0,
10051,17,156,4,0,
10051,17,164,4,0,
10051,17,168,4,0,
10051,17,182,4,0,
10051,17,207,4,0,
10051,17,213,4,0,
10051,17,214,4,0,
10051,17,216,4,0,
10051,17,218,4,0,
10051,17,219,4,0,
10051,17,237,4,0,
10051,17,240,4,0,
10051,17,241,4,0,
10051,17,244,4,0,
10051,17,247,4,0,
10051,17,248,1,40,
10051,17,259,4,0,
10051,17,261,4,0,
10051,17,263,4,0,
10051,17,269,4,0,
10051,17,273,1,14,
10051,17,286,1,35,
10051,17,345,1,17,
10051,17,347,1,26,
10051,17,347,4,0,
10051,17,361,1,1,4
10051,17,374,4,0,
10051,17,411,4,0,
10051,17,412,4,0,
10051,17,416,4,0,
10051,17,433,4,0,
10051,17,445,1,44,
10051,17,447,4,0,
10051,17,451,4,0,
10051,17,473,4,0,
10051,17,496,4,0,
10051,17,497,4,0,
10051,17,500,1,1,2
10051,17,500,1,58,
10051,17,505,1,19,
10051,17,574,1,11,
10051,17,577,1,23,
10051,17,581,1,1,3
10051,17,585,1,1,1
10051,17,585,1,62,
10051,17,590,4,0,
10051,17,605,4,0,
10051,18,45,1,1,5
10051,18,63,4,0,
10051,18,85,4,0,
10051,18,86,4,0,
10051,18,92,4,0,
10051,18,93,1,1,6
10051,18,93,1,4,
10051,18,94,1,31,
10051,18,94,4,0,
10051,18,95,1,49,
10051,18,100,1,1,8
10051,18,100,1,9,
10051,18,104,1,1,7
10051,18,104,1,6,
10051,18,104,4,0,
10051,18,113,4,0,
10051,18,115,4,0,
10051,18,138,1,53,
10051,18,138,4,0,
10051,18,156,4,0,
10051,18,164,4,0,
10051,18,168,4,0,
10051,18,182,4,0,
10051,18,207,4,0,
10051,18,213,4,0,
10051,18,214,4,0,
10051,18,216,4,0,
10051,18,218,4,0,
10051,18,219,4,0,
10051,18,237,4,0,
10051,18,240,4,0,
10051,18,241,4,0,
10051,18,244,4,0,
10051,18,247,4,0,
10051,18,248,1,40,
10051,18,259,4,0,
10051,18,261,4,0,
10051,18,263,4,0,
10051,18,269,4,0,
10051,18,273,1,14,
10051,18,286,1,35,
10051,18,345,1,17,
10051,18,347,1,26,
10051,18,347,4,0,
10051,18,361,1,1,4
10051,18,374,4,0,
10051,18,411,4,0,
10051,18,412,4,0,
10051,18,416,4,0,
10051,18,433,4,0,
10051,18,445,1,44,
10051,18,447,4,0,
10051,18,451,4,0,
10051,18,473,4,0,
10051,18,496,4,0,
10051,18,497,4,0,
10051,18,500,1,1,2
10051,18,500,1,58,
10051,18,505,1,19,
10051,18,574,1,11,
10051,18,577,1,23,
10051,18,581,1,1,3
10051,18,585,1,1,1
10051,18,585,1,62,
10051,18,590,4,0,
10051,18,605,4,0,
10052,15,11,1,21,
10052,15,14,4,0,
10052,15,21,2,0,
10052,15,44,1,11,
10052,15,45,1,1,4
10052,15,53,4,0,
10052,15,58,4,0,
10052,15,63,4,0,
10052,15,69,2,0,
10052,15,70,4,0,
10052,15,76,4,0,
10052,15,92,4,0,
10052,15,104,4,0,
10052,15,126,4,0,
10052,15,156,4,0,
10052,15,157,4,0,
10052,15,164,4,0,
10052,15,182,4,0,
10052,15,185,1,26,
10052,15,188,4,0,
10052,15,201,4,0,
10052,15,206,4,0,
10052,15,207,4,0,
10052,15,213,4,0,
10052,15,214,4,0,
10052,15,216,4,0,
10052,15,218,4,0,
10052,15,226,1,31,
10052,15,230,1,16,
10052,15,237,4,0,
10052,15,240,4,0,
10052,15,241,4,0,
10052,15,242,1,36,
10052,15,244,4,0,
10052,15,246,2,0,
10052,15,247,4,0,
10052,15,249,4,0,
10052,15,254,1,50,1
10052,15,255,1,50,3
10052,15,256,1,50,2
10052,15,259,4,0,
10052,15,263,4,0,
10052,15,269,1,1,3
10052,15,269,4,0,
10052,15,280,4,0,
10052,15,305,2,0,
10052,15,310,1,1,6
10052,15,313,1,6,
10052,15,317,4,0,
10052,15,321,2,0,
10052,15,334,1,41,
10052,15,368,2,0,
10052,15,371,4,0,
10052,15,373,4,0,
10052,15,374,4,0,
10052,15,385,2,0,
10052,15,386,2,0,
10052,15,389,1,46,
10052,15,389,2,0,
10052,15,399,4,0,
10052,15,411,4,0,
10052,15,416,4,0,
10052,15,422,2,0,
10052,15,423,2,0,
10052,15,424,2,0,
10052,15,430,4,0,
10052,15,442,1,1,2
10052,15,442,1,56,
10052,15,444,4,0,
10052,15,445,2,0,
10052,15,447,4,0,
10052,15,451,4,0,
10052,15,496,4,0,
10052,15,510,4,0,
10052,15,581,2,0,
10052,15,583,1,1,1
10052,15,583,1,60,
10052,15,584,1,1,5
10052,15,590,4,0,
10052,15,612,4,0,
10052,16,8,3,0,
10052,16,9,3,0,
10052,16,11,1,17,1
10052,16,14,4,0,
10052,16,44,1,9,1
10052,16,45,1,1,4
10052,16,53,4,0,
10052,16,58,4,0,
10052,16,63,4,0,
10052,16,70,4,0,
10052,16,76,4,0,
10052,16,92,4,0,
10052,16,104,4,0,
10052,16,126,4,0,
10052,16,156,4,0,
10052,16,157,4,0,
10052,16,162,3,0,
10052,16,164,4,0,
10052,16,173,3,0,
10052,16,182,4,0,
10052,16,185,1,21,1
10052,16,188,4,0,
10052,16,196,3,0,
10052,16,201,4,0,
10052,16,206,4,0,
10052,16,207,4,0,
10052,16,213,4,0,
10052,16,214,4,0,
10052,16,216,4,0,
10052,16,218,4,0,
10052,16,220,3,0,
10052,16,226,1,25,1
10052,16,230,1,13,1
10052,16,237,4,0,
10052,16,240,4,0,
10052,16,241,4,0,
10052,16,242,1,29,1
10052,16,244,4,0,
10052,16,247,4,0,
10052,16,249,4,0,
10052,16,254,1,41,1
10052,16,255,1,41,3
10052,16,256,1,41,2
10052,16,259,4,0,
10052,16,263,4,0,
10052,16,264,3,0,
10052,16,269,1,1,3
10052,16,269,4,0,
10052,16,280,4,0,
10052,16,282,3,0,
10052,16,289,3,0,
10052,16,290,4,0,
10052,16,310,1,1,6
10052,16,313,1,5,1
10052,16,317,4,0,
10052,16,334,1,33,1
10052,16,334,3,0,
10052,16,371,4,0,
10052,16,373,4,0,
10052,16,374,4,0,
10052,16,387,3,0,
10052,16,389,1,37,1
10052,16,393,3,0,
10052,16,399,4,0,
10052,16,411,4,0,
10052,16,416,4,0,
10052,16,430,4,0,
10052,16,442,1,1,2
10052,16,442,1,45,1
10052,16,442,3,0,
10052,16,444,4,0,
10052,16,446,3,0,
10052,16,447,4,0,
10052,16,451,4,0,
10052,16,492,3,0,
10052,16,496,4,0,
10052,16,510,4,0,
10052,16,583,1,1,1
10052,16,583,1,49,1
10052,16,584,1,1,5
10052,16,590,4,0,
10052,16,612,4,0,
10052,17,11,1,17,
10052,17,14,4,0,
10052,17,44,1,9,
10052,17,45,1,1,4
10052,17,53,4,0,
10052,17,58,4,0,
10052,17,63,4,0,
10052,17,76,4,0,
10052,17,92,4,0,
10052,17,104,4,0,
10052,17,126,4,0,
10052,17,156,4,0,
10052,17,157,4,0,
10052,17,164,4,0,
10052,17,182,4,0,
10052,17,185,1,21,
10052,17,188,4,0,
10052,17,201,4,0,
10052,17,206,4,0,
10052,17,207,4,0,
10052,17,213,4,0,
10052,17,214,4,0,
10052,17,216,4,0,
10052,17,218,4,0,
10052,17,226,1,25,
10052,17,230,1,13,
10052,17,237,4,0,
10052,17,240,4,0,
10052,17,241,4,0,
10052,17,242,1,29,
10052,17,244,4,0,
10052,17,247,4,0,
10052,17,254,1,41,1
10052,17,255,1,41,3
10052,17,256,1,41,2
10052,17,259,4,0,
10052,17,263,4,0,
10052,17,269,1,1,3
10052,17,269,4,0,
10052,17,280,4,0,
10052,17,310,1,1,6
10052,17,313,1,5,
10052,17,317,4,0,
10052,17,334,1,33,
10052,17,371,4,0,
10052,17,373,4,0,
10052,17,374,4,0,
10052,17,389,1,37,
10052,17,399,4,0,
10052,17,411,4,0,
10052,17,416,4,0,
10052,17,430,4,0,
10052,17,442,1,1,2
10052,17,442,1,45,
10052,17,444,4,0,
10052,17,447,4,0,
10052,17,451,4,0,
10052,17,496,4,0,
10052,17,583,1,1,1
10052,17,583,1,49,
10052,17,584,1,1,5
10052,17,590,4,0,
10052,18,11,1,17,
10052,18,14,4,0,
10052,18,44,1,9,
10052,18,45,1,1,4
10052,18,53,4,0,
10052,18,58,4,0,
10052,18,63,4,0,
10052,18,76,4,0,
10052,18,92,4,0,
10052,18,104,4,0,
10052,18,126,4,0,
10052,18,156,4,0,
10052,18,157,4,0,
10052,18,164,4,0,
10052,18,182,4,0,
10052,18,185,1,21,
10052,18,188,4,0,
10052,18,201,4,0,
10052,18,206,4,0,
10052,18,207,4,0,
10052,18,213,4,0,
10052,18,214,4,0,
10052,18,216,4,0,
10052,18,218,4,0,
10052,18,226,1,25,
10052,18,230,1,13,
10052,18,237,4,0,
10052,18,240,4,0,
10052,18,241,4,0,
10052,18,242,1,29,
10052,18,244,4,0,
10052,18,247,4,0,
10052,18,254,1,41,1
10052,18,255,1,41,3
10052,18,256,1,41,2
10052,18,259,4,0,
10052,18,263,4,0,
10052,18,269,1,1,3
10052,18,269,4,0,
10052,18,280,4,0,
10052,18,310,1,1,6
10052,18,313,1,5,
10052,18,317,4,0,
10052,18,334,1,33,
10052,18,371,4,0,
10052,18,373,4,0,
10052,18,374,4,0,
10052,18,389,1,37,
10052,18,399,4,0,
10052,18,411,4,0,
10052,18,416,4,0,
10052,18,430,4,0,
10052,18,442,1,1,2
10052,18,442,1,45,
10052,18,444,4,0,
10052,18,447,4,0,
10052,18,451,4,0,
10052,18,496,4,0,
10052,18,583,1,1,1
10052,18,583,1,49,
10052,18,584,1,1,5
10052,18,590,4,0,
10053,15,15,4,0,
10053,15,29,1,1,4
10053,15,29,1,8,
10053,15,33,1,1,1
10053,15,36,1,22,
10053,15,38,1,65,
10053,15,46,1,18,
10053,15,46,4,0,
10053,15,53,4,0,
10053,15,57,4,0,
10053,15,58,4,0,
10053,15,59,4,0,
10053,15,63,4,0,
10053,15,70,4,0,
10053,15,76,4,0,
10053,15,85,4,0,
10053,15,86,4,0,
10053,15,87,4,0,
10053,15,89,4,0,
10053,15,91,4,0,
10053,15,92,4,0,
10053,15,104,4,0,
10053,15,106,1,1,2
10053,15,126,4,0,
10053,15,156,4,0,
10053,15,157,4,0,
10053,15,164,4,0,
10053,15,182,1,29,
10053,15,182,4,0,
10053,15,189,1,1,3
10053,15,189,1,4,
10053,15,201,4,0,
10053,15,207,4,0,
10053,15,213,4,0,
10053,15,214,4,0,
10053,15,216,4,0,
10053,15,218,4,0,
10053,15,231,1,40,
10053,15,232,1,11,
10053,15,237,4,0,
10053,15,240,4,0,
10053,15,241,4,0,
10053,15,249,4,0,
10053,15,263,4,0,
10053,15,269,4,0,
10053,15,280,4,0,
10053,15,317,4,0,
10053,15,319,1,34,
10053,15,332,4,0,
10053,15,334,1,15,
10053,15,337,4,0,
10053,15,368,1,74,
10053,15,371,4,0,
10053,15,374,4,0,
10053,15,397,4,0,
10053,15,399,4,0,
10053,15,411,4,0,
10053,15,416,4,0,
10053,15,421,4,0,
10053,15,430,4,0,
10053,15,442,1,25,
10053,15,444,4,0,
10053,15,468,4,0,
10053,15,475,1,48,
10053,15,479,4,0,
10053,15,484,1,57,
10053,15,496,4,0,
10053,15,510,4,0,
10053,15,523,4,0,
10053,15,525,4,0,
10053,15,590,4,0,
10053,15,612,4,0,
10053,16,7,3,0,
10053,16,8,3,0,
10053,16,9,3,0,
10053,16,15,4,0,
10053,16,29,1,1,4
10053,16,29,1,7,1
10053,16,33,1,1,1
10053,16,36,1,28,1
10053,16,38,1,45,1
10053,16,46,1,19,1
10053,16,46,4,0,
10053,16,53,4,0,
10053,16,57,4,0,
10053,16,58,4,0,
10053,16,59,4,0,
10053,16,63,4,0,
10053,16,67,3,0,
10053,16,70,4,0,
10053,16,76,4,0,
10053,16,85,4,0,
10053,16,86,4,0,
10053,16,87,4,0,
10053,16,89,4,0,
10053,16,91,4,0,
10053,16,92,4,0,
10053,16,104,4,0,
10053,16,106,1,1,2
10053,16,126,4,0,
10053,16,156,4,0,
10053,16,157,1,25,1
10053,16,157,4,0,
10053,16,164,4,0,
10053,16,173,3,0,
10053,16,180,3,0,
10053,16,182,1,16,1
10053,16,182,4,0,
10053,16,189,1,1,3
10053,16,189,1,4,1
10053,16,196,3,0,
10053,16,200,3,0,
10053,16,201,4,0,
10053,16,207,4,0,
10053,16,213,4,0,
10053,16,214,4,0,
10053,16,216,4,0,
10053,16,218,4,0,
10053,16,231,1,35,1
10053,16,231,3,0,
10053,16,232,1,10,1
10053,16,237,4,0,
10053,16,240,4,0,
10053,16,241,4,0,
10053,16,249,4,0,
10053,16,253,3,0,
10053,16,263,4,0,
10053,16,264,3,0,
10053,16,269,4,0,
10053,16,276,3,0,
10053,16,280,4,0,
10053,16,283,3,0,
10053,16,290,4,0,
10053,16,317,1,13,1
10053,16,317,4,0,
10053,16,319,1,31,1
10053,16,332,4,0,
10053,16,334,1,39,1
10053,16,334,3,0,
10053,16,335,3,0,
10053,16,337,4,0,
10053,16,351,3,0,
10053,16,352,3,0,
10053,16,368,1,63,1
10053,16,371,4,0,
10053,16,374,4,0,
10053,16,393,3,0,
10053,16,397,4,0,
10053,16,399,4,0,
10053,16,401,3,0,
10053,16,406,3,0,
10053,16,411,4,0,
10053,16,414,3,0,
10053,16,416,4,0,
10053,16,421,4,0,
10053,16,430,4,0,
10053,16,442,1,22,1
10053,16,442,3,0,
10053,16,444,4,0,
10053,16,446,3,0,
10053,16,468,4,0,
10053,16,475,1,51,1
10053,16,479,4,0,
10053,16,484,1,57,1
10053,16,496,4,0,
10053,16,510,4,0,
10053,16,523,4,0,
10053,16,525,4,0,
10053,16,590,4,0,
10053,16,612,4,0,
10053,17,29,1,1,4
10053,17,29,1,7,
10053,17,33,1,1,1
10053,17,36,1,28,
10053,17,38,1,45,
10053,17,46,1,19,
10053,17,46,4,0,
10053,17,53,4,0,
10053,17,57,4,0,
10053,17,58,4,0,
10053,17,59,4,0,
10053,17,63,4,0,
10053,17,76,4,0,
10053,17,85,4,0,
10053,17,86,4,0,
10053,17,87,4,0,
10053,17,89,4,0,
10053,17,92,4,0,
10053,17,104,4,0,
10053,17,106,1,1,2
10053,17,126,4,0,
10053,17,156,4,0,
10053,17,157,1,25,
10053,17,157,4,0,
10053,17,164,4,0,
10053,17,182,1,16,
10053,17,182,4,0,
10053,17,189,1,1,3
10053,17,189,1,4,
10053,17,201,4,0,
10053,17,207,4,0,
10053,17,213,4,0,
10053,17,214,4,0,
10053,17,216,4,0,
10053,17,218,4,0,
10053,17,231,1,35,
10053,17,232,1,10,
10053,17,237,4,0,
10053,17,240,4,0,
10053,17,241,4,0,
10053,17,263,4,0,
10053,17,269,4,0,
10053,17,280,4,0,
10053,17,317,1,13,
10053,17,317,4,0,
10053,17,319,1,31,
10053,17,332,4,0,
10053,17,334,1,39,
10053,17,337,4,0,
10053,17,368,1,63,
10053,17,371,4,0,
10053,17,374,4,0,
10053,17,397,4,0,
10053,17,399,4,0,
10053,17,411,4,0,
10053,17,416,4,0,
10053,17,421,4,0,
10053,17,430,4,0,
10053,17,442,1,22,
10053,17,444,4,0,
10053,17,475,1,51,
10053,17,479,4,0,
10053,17,484,1,57,
10053,17,496,4,0,
10053,17,523,4,0,
10053,17,525,4,0,
10053,17,590,4,0,
10053,17,684,4,0,
10053,17,693,4,0,
10053,18,29,1,1,4
10053,18,29,1,7,
10053,18,33,1,1,1
10053,18,36,1,28,
10053,18,38,1,45,
10053,18,46,1,19,
10053,18,46,4,0,
10053,18,53,4,0,
10053,18,57,4,0,
10053,18,58,4,0,
10053,18,59,4,0,
10053,18,63,4,0,
10053,18,76,4,0,
10053,18,85,4,0,
10053,18,86,4,0,
10053,18,87,4,0,
10053,18,89,4,0,
10053,18,92,4,0,
10053,18,104,4,0,
10053,18,106,1,1,2
10053,18,126,4,0,
10053,18,156,4,0,
10053,18,157,1,25,
10053,18,157,4,0,
10053,18,164,4,0,
10053,18,182,1,16,
10053,18,182,4,0,
10053,18,189,1,1,3
10053,18,189,1,4,
10053,18,201,4,0,
10053,18,207,4,0,
10053,18,213,4,0,
10053,18,214,4,0,
10053,18,216,4,0,
10053,18,218,4,0,
10053,18,231,1,35,
10053,18,232,1,10,
10053,18,237,4,0,
10053,18,240,4,0,
10053,18,241,4,0,
10053,18,263,4,0,
10053,18,269,4,0,
10053,18,280,4,0,
10053,18,317,1,13,
10053,18,317,4,0,
10053,18,319,1,31,
10053,18,332,4,0,
10053,18,334,1,39,
10053,18,337,4,0,
10053,18,368,1,63,
10053,18,371,4,0,
10053,18,374,4,0,
10053,18,397,4,0,
10053,18,399,4,0,
10053,18,411,4,0,
10053,18,416,4,0,
10053,18,421,4,0,
10053,18,430,4,0,
10053,18,442,1,22,
10053,18,444,4,0,
10053,18,475,1,51,
10053,18,479,4,0,
10053,18,484,1,57,
10053,18,496,4,0,
10053,18,523,4,0,
10053,18,525,4,0,
10053,18,590,4,0,
10053,18,684,4,0,
10053,18,693,4,0,
10054,15,7,1,1,2
10054,15,8,1,1,4
10054,15,9,1,1,3
10054,15,63,4,0,
10054,15,70,4,0,
10054,15,92,4,0,
10054,15,93,1,1,7
10054,15,93,1,8,
10054,15,94,4,0,
10054,15,96,1,1,6
10054,15,96,1,4,
10054,15,104,4,0,
10054,15,105,1,62,
10054,15,113,4,0,
10054,15,115,4,0,
10054,15,117,1,1,5
10054,15,136,1,32,
10054,15,138,4,0,
10054,15,148,4,0,
10054,15,156,4,0,
10054,15,157,4,0,
10054,15,164,4,0,
10054,15,170,1,18,
10054,15,179,1,55,
10054,15,182,4,0,
10054,15,197,1,1,8
10054,15,197,1,11,
10054,15,207,4,0,
10054,15,213,4,0,
10054,15,214,4,0,
10054,15,216,4,0,
10054,15,218,4,0,
10054,15,237,1,15,
10054,15,237,4,0,
10054,15,240,4,0,
10054,15,241,4,0,
10054,15,244,1,36,
10054,15,244,4,0,
10054,15,247,4,0,
10054,15,249,4,0,
10054,15,263,4,0,
10054,15,280,4,0,
10054,15,317,4,0,
10054,15,339,4,0,
10054,15,347,1,25,
10054,15,347,4,0,
10054,15,364,1,22,
10054,15,367,1,42,
10054,15,374,4,0,
10054,15,379,1,49,
10054,15,395,1,29,
10054,15,398,4,0,
10054,15,411,4,0,
10054,15,412,4,0,
10054,15,416,4,0,
10054,15,428,1,1,1
10054,15,447,4,0,
10054,15,473,4,0,
10054,15,490,4,0,
10054,15,496,4,0,
10054,15,514,4,0,
10054,15,590,4,0,
10054,15,612,4,0,
10054,16,7,1,1,2
10054,16,7,3,0,
10054,16,8,1,1,4
10054,16,8,3,0,
10054,16,9,1,1,3
10054,16,9,3,0,
10054,16,63,4,0,
10054,16,67,3,0,
10054,16,68,1,53,1
10054,16,70,4,0,
10054,16,92,4,0,
10054,16,93,1,1,7
10054,16,93,1,7,1
10054,16,94,4,0,
10054,16,96,1,1,6
10054,16,96,1,4,1
10054,16,104,4,0,
10054,16,105,1,47,1
10054,16,113,4,0,
10054,16,115,4,0,
10054,16,117,1,1,5
10054,16,136,1,28,1
10054,16,138,4,0,
10054,16,148,4,0,
10054,16,156,4,0,
10054,16,157,4,0,
10054,16,164,4,0,
10054,16,170,1,25,1
10054,16,173,3,0,
10054,16,179,1,42,1
10054,16,182,4,0,
10054,16,197,1,1,8
10054,16,197,1,9,1
10054,16,203,1,12,1
10054,16,207,4,0,
10054,16,213,4,0,
10054,16,214,4,0,
10054,16,216,4,0,
10054,16,218,4,0,
10054,16,220,3,0,
10054,16,237,1,20,1
10054,16,237,4,0,
10054,16,240,4,0,
10054,16,241,4,0,
10054,16,244,1,31,1
10054,16,244,4,0,
10054,16,247,4,0,
10054,16,249,4,0,
10054,16,263,4,0,
10054,16,264,3,0,
10054,16,270,3,0,
10054,16,271,3,0,
10054,16,272,3,0,
10054,16,277,3,0,
10054,16,278,3,0,
10054,16,280,4,0,
10054,16,290,4,0,
10054,16,317,4,0,
10054,16,324,3,0,
10054,16,339,4,0,
10054,16,347,1,23,1
10054,16,347,4,0,
10054,16,356,3,0,
10054,16,364,1,15,1
10054,16,367,1,33,1
10054,16,374,4,0,
10054,16,379,1,36,1
10054,16,395,1,17,1
10054,16,398,4,0,
10054,16,409,3,0,
10054,16,411,4,0,
10054,16,412,4,0,
10054,16,416,4,0,
10054,16,428,1,1,1
10054,16,428,3,0,
10054,16,447,4,0,
10054,16,473,4,0,
10054,16,490,4,0,
10054,16,496,4,0,
10054,16,514,4,0,
10054,16,590,4,0,
10054,16,612,4,0,
10054,17,7,1,1,2
10054,17,8,1,1,4
10054,17,9,1,1,3
10054,17,63,4,0,
10054,17,68,1,53,
10054,17,92,4,0,
10054,17,93,1,1,7
10054,17,93,1,7,
10054,17,94,4,0,
10054,17,96,1,1,6
10054,17,96,1,4,
10054,17,104,4,0,
10054,17,105,1,47,
10054,17,113,4,0,
10054,17,115,4,0,
10054,17,117,1,1,5
10054,17,136,1,28,
10054,17,138,4,0,
10054,17,156,4,0,
10054,17,157,4,0,
10054,17,164,4,0,
10054,17,170,1,25,
10054,17,179,1,42,
10054,17,182,4,0,
10054,17,197,1,1,8
10054,17,197,1,9,
10054,17,203,1,12,
10054,17,207,4,0,
10054,17,213,4,0,
10054,17,214,4,0,
10054,17,216,4,0,
10054,17,218,4,0,
10054,17,237,1,20,
10054,17,237,4,0,
10054,17,240,4,0,
10054,17,241,4,0,
10054,17,244,1,31,
10054,17,244,4,0,
10054,17,247,4,0,
10054,17,263,4,0,
10054,17,280,4,0,
10054,17,317,4,0,
10054,17,339,4,0,
10054,17,347,1,23,
10054,17,347,4,0,
10054,17,364,1,15,
10054,17,367,1,33,
10054,17,374,4,0,
10054,17,379,1,36,
10054,17,395,1,17,
10054,17,398,4,0,
10054,17,411,4,0,
10054,17,412,4,0,
10054,17,416,4,0,
10054,17,428,1,1,1
10054,17,447,4,0,
10054,17,473,4,0,
10054,17,490,4,0,
10054,17,496,4,0,
10054,17,526,4,0,
10054,17,590,4,0,
10054,18,7,1,1,2
10054,18,8,1,1,4
10054,18,9,1,1,3
10054,18,63,4,0,
10054,18,68,1,53,
10054,18,92,4,0,
10054,18,93,1,1,7
10054,18,93,1,7,
10054,18,94,4,0,
10054,18,96,1,1,6
10054,18,96,1,4,
10054,18,104,4,0,
10054,18,105,1,47,
10054,18,113,4,0,
10054,18,115,4,0,
10054,18,117,1,1,5
10054,18,136,1,28,
10054,18,138,4,0,
10054,18,156,4,0,
10054,18,157,4,0,
10054,18,164,4,0,
10054,18,170,1,25,
10054,18,179,1,42,
10054,18,182,4,0,
10054,18,197,1,1,8
10054,18,197,1,9,
10054,18,203,1,12,
10054,18,207,4,0,
10054,18,213,4,0,
10054,18,214,4,0,
10054,18,216,4,0,
10054,18,218,4,0,
10054,18,237,1,20,
10054,18,237,4,0,
10054,18,240,4,0,
10054,18,241,4,0,
10054,18,244,1,31,
10054,18,244,4,0,
10054,18,247,4,0,
10054,18,263,4,0,
10054,18,280,4,0,
10054,18,317,4,0,
10054,18,339,4,0,
10054,18,347,1,23,
10054,18,347,4,0,
10054,18,364,1,15,
10054,18,367,1,33,
10054,18,374,4,0,
10054,18,379,1,36,
10054,18,395,1,17,
10054,18,398,4,0,
10054,18,411,4,0,
10054,18,412,4,0,
10054,18,416,4,0,
10054,18,428,1,1,1
10054,18,447,4,0,
10054,18,473,4,0,
10054,18,490,4,0,
10054,18,496,4,0,
10054,18,526,4,0,
10054,18,590,4,0,
10055,15,33,1,1,3
10055,15,43,1,1,5
10055,15,43,1,9,
10055,15,44,1,30,
10055,15,46,1,42,
10055,15,46,4,0,
10055,15,53,4,0,
10055,15,63,4,0,
10055,15,70,4,0,
10055,15,85,4,0,
10055,15,86,1,1,4
10055,15,86,1,4,
10055,15,86,4,0,
10055,15,87,1,66,
10055,15,87,4,0,
10055,15,92,4,0,
10055,15,98,1,17,
10055,15,104,4,0,
10055,15,113,4,0,
10055,15,148,4,0,
10055,15,156,4,0,
10055,15,164,4,0,
10055,15,168,4,0,
10055,15,182,4,0,
10055,15,207,4,0,
10055,15,209,1,20,
10055,15,213,4,0,
10055,15,214,4,0,
10055,15,216,4,0,
10055,15,218,4,0,
10055,15,237,4,0,
10055,15,240,4,0,
10055,15,263,4,0,
10055,15,268,1,54,
10055,15,315,4,0,
10055,15,316,1,25,
10055,15,336,1,1,6
10055,15,336,1,12,
10055,15,416,4,0,
10055,15,422,1,37,
10055,15,424,1,1,2
10055,15,435,1,49,
10055,15,451,4,0,
10055,15,496,4,0,
10055,15,521,4,0,
10055,15,528,1,61,
10055,15,528,4,0,
10055,15,555,4,0,
10055,15,590,4,0,
10055,15,604,1,1,1
10055,15,604,1,70,
10055,16,33,1,1,3
10055,16,43,1,1,5
10055,16,43,1,4,1
10055,16,44,1,24,1
10055,16,46,1,36,1
10055,16,46,4,0,
10055,16,53,4,0,
10055,16,63,4,0,
10055,16,70,4,0,
10055,16,85,4,0,
10055,16,86,1,1,4
10055,16,86,4,0,
10055,16,87,1,54,1
10055,16,87,4,0,
10055,16,92,4,0,
10055,16,98,1,10,1
10055,16,104,4,0,
10055,16,113,4,0,
10055,16,148,4,0,
10055,16,156,4,0,
10055,16,164,4,0,
10055,16,168,4,0,
10055,16,173,3,0,
10055,16,182,4,0,
10055,16,207,4,0,
10055,16,209,1,13,1
10055,16,213,4,0,
10055,16,214,4,0,
10055,16,216,4,0,
10055,16,218,4,0,
10055,16,231,3,0,
10055,16,237,4,0,
10055,16,240,4,0,
10055,16,253,3,0,
10055,16,263,4,0,
10055,16,268,1,48,1
10055,16,290,4,0,
10055,16,315,4,0,
10055,16,316,1,16,1
10055,16,324,3,0,
10055,16,336,1,1,6
10055,16,336,1,7,1
10055,16,351,3,0,
10055,16,393,3,0,
10055,16,416,4,0,
10055,16,422,1,19,1
10055,16,424,1,1,2
10055,16,435,1,30,1
10055,16,451,4,0,
10055,16,496,4,0,
10055,16,521,4,0,
10055,16,528,1,42,1
10055,16,528,4,0,
10055,16,555,4,0,
10055,16,590,4,0,
10055,16,604,1,1,1
10055,16,604,1,60,1
10055,17,33,1,1,3
10055,17,43,1,1,5
10055,17,43,1,4,
10055,17,44,1,24,
10055,17,46,1,36,
10055,17,46,4,0,
10055,17,53,4,0,
10055,17,63,4,0,
10055,17,85,4,0,
10055,17,86,1,1,4
10055,17,86,4,0,
10055,17,87,1,54,
10055,17,87,4,0,
10055,17,92,4,0,
10055,17,98,1,10,
10055,17,104,4,0,
10055,17,113,4,0,
10055,17,156,4,0,
10055,17,164,4,0,
10055,17,168,4,0,
10055,17,182,4,0,
10055,17,207,4,0,
10055,17,209,1,13,
10055,17,213,4,0,
10055,17,214,4,0,
10055,17,216,4,0,
10055,17,218,4,0,
10055,17,237,4,0,
10055,17,240,4,0,
10055,17,263,4,0,
10055,17,268,1,48,
10055,17,315,4,0,
10055,17,316,1,16,
10055,17,336,1,1,6
10055,17,336,1,7,
10055,17,416,4,0,
10055,17,422,1,19,
10055,17,424,1,1,2
10055,17,435,1,30,
10055,17,451,4,0,
10055,17,496,4,0,
10055,17,521,4,0,
10055,17,528,1,42,
10055,17,528,4,0,
10055,17,555,4,0,
10055,17,590,4,0,
10055,17,604,1,1,1
10055,17,604,1,60,
10055,18,33,1,1,3
10055,18,43,1,1,5
10055,18,43,1,4,
10055,18,44,1,24,
10055,18,46,1,36,
10055,18,46,4,0,
10055,18,53,4,0,
10055,18,63,4,0,
10055,18,85,4,0,
10055,18,86,1,1,4
10055,18,86,4,0,
10055,18,87,1,54,
10055,18,87,4,0,
10055,18,92,4,0,
10055,18,98,1,10,
10055,18,104,4,0,
10055,18,113,4,0,
10055,18,156,4,0,
10055,18,164,4,0,
10055,18,168,4,0,
10055,18,182,4,0,
10055,18,207,4,0,
10055,18,209,1,13,
10055,18,213,4,0,
10055,18,214,4,0,
10055,18,216,4,0,
10055,18,218,4,0,
10055,18,237,4,0,
10055,18,240,4,0,
10055,18,263,4,0,
10055,18,268,1,48,
10055,18,315,4,0,
10055,18,316,1,16,
10055,18,336,1,1,6
10055,18,336,1,7,
10055,18,416,4,0,
10055,18,422,1,19,
10055,18,424,1,1,2
10055,18,435,1,30,
10055,18,451,4,0,
10055,18,496,4,0,
10055,18,521,4,0,
10055,18,528,1,42,
10055,18,528,4,0,
10055,18,555,4,0,
10055,18,590,4,0,
10055,18,604,1,1,1
10055,18,604,1,60,
10056,15,63,4,0,
10056,15,85,4,0,
10056,15,86,4,0,
10056,15,87,4,0,
10056,15,92,4,0,
10056,15,94,4,0,
10056,15,101,1,1,3
10056,15,101,1,7,
10056,15,103,1,1,2
10056,15,103,1,4,
10056,15,104,4,0,
10056,15,138,4,0,
10056,15,148,4,0,
10056,15,156,4,0,
10056,15,164,4,0,
10056,15,168,4,0,
10056,15,174,1,1,4
10056,15,174,1,19,
10056,15,180,1,10,
10056,15,182,4,0,
10056,15,185,1,22,
10056,15,207,4,0,
10056,15,213,4,0,
10056,15,214,4,0,
10056,15,216,4,0,
10056,15,218,4,0,
10056,15,237,4,0,
10056,15,240,4,0,
10056,15,241,4,0,
10056,15,244,4,0,
10056,15,247,1,30,
10056,15,247,4,0,
10056,15,259,4,0,
10056,15,261,1,13,
10056,15,261,4,0,
10056,15,263,4,0,
10056,15,269,4,0,
10056,15,271,1,58,
10056,15,282,1,1,1
10056,15,288,1,52,
10056,15,289,1,46,
10056,15,347,4,0,
10056,15,371,4,0,
10056,15,373,1,40,
10056,15,373,4,0,
10056,15,374,4,0,
10056,15,389,1,34,
10056,15,399,4,0,
10056,15,416,4,0,
10056,15,421,4,0,
10056,15,425,1,16,
10056,15,433,4,0,
10056,15,451,4,0,
10056,15,496,4,0,
10056,15,506,1,26,
10056,15,590,4,0,
10056,15,605,4,0,
10056,15,611,4,0,
10056,16,63,4,0,
10056,16,85,4,0,
10056,16,86,4,0,
10056,16,87,4,0,
10056,16,92,4,0,
10056,16,94,4,0,
10056,16,101,1,1,4
10056,16,101,1,7,1
10056,16,103,1,1,3
10056,16,103,1,4,1
10056,16,104,4,0,
10056,16,138,4,0,
10056,16,148,4,0,
10056,16,156,4,0,
10056,16,164,4,0,
10056,16,168,4,0,
10056,16,173,3,0,
10056,16,174,1,26,1
10056,16,180,1,1,5
10056,16,180,1,10,1
10056,16,180,3,0,
10056,16,182,4,0,
10056,16,185,1,19,1
10056,16,196,3,0,
10056,16,207,4,0,
10056,16,213,4,0,
10056,16,214,4,0,
10056,16,216,4,0,
10056,16,218,4,0,
10056,16,220,3,0,
10056,16,237,4,0,
10056,16,240,4,0,
10056,16,241,4,0,
10056,16,244,4,0,
10056,16,247,1,30,1
10056,16,247,4,0,
10056,16,259,4,0,
10056,16,261,1,16,1
10056,16,261,4,0,
10056,16,263,4,0,
10056,16,269,4,0,
10056,16,271,1,58,1
10056,16,271,3,0,
10056,16,272,3,0,
10056,16,277,3,0,
10056,16,282,1,1,2
10056,16,282,3,0,
10056,16,285,3,0,
10056,16,288,1,52,1
10056,16,289,1,46,1
10056,16,289,3,0,
10056,16,290,4,0,
10056,16,347,4,0,
10056,16,351,3,0,
10056,16,371,4,0,
10056,16,373,1,34,1
10056,16,373,4,0,
10056,16,374,4,0,
10056,16,389,1,40,1
10056,16,399,4,0,
10056,16,416,4,0,
10056,16,421,4,0,
10056,16,425,1,13,1
10056,16,433,4,0,
10056,16,451,4,0,
10056,16,478,3,0,
10056,16,492,3,0,
10056,16,496,4,0,
10056,16,506,1,22,1
10056,16,566,1,1,1
10056,16,566,1,64,1
10056,16,590,4,0,
10056,16,605,4,0,
10056,16,611,4,0,
10056,17,63,4,0,
10056,17,85,4,0,
10056,17,86,4,0,
10056,17,87,4,0,
10056,17,92,4,0,
10056,17,94,4,0,
10056,17,101,1,1,4
10056,17,101,1,7,
10056,17,103,1,1,3
10056,17,103,1,4,
10056,17,104,4,0,
10056,17,138,4,0,
10056,17,156,4,0,
10056,17,164,4,0,
10056,17,168,4,0,
10056,17,174,1,26,
10056,17,180,1,1,5
10056,17,180,1,10,
10056,17,182,4,0,
10056,17,185,1,19,
10056,17,207,4,0,
10056,17,213,4,0,
10056,17,214,4,0,
10056,17,216,4,0,
10056,17,218,4,0,
10056,17,237,4,0,
10056,17,240,4,0,
10056,17,241,4,0,
10056,17,244,4,0,
10056,17,247,1,30,
10056,17,247,4,0,
10056,17,259,4,0,
10056,17,261,1,16,
10056,17,261,4,0,
10056,17,263,4,0,
10056,17,269,4,0,
10056,17,271,1,58,
10056,17,282,1,1,2
10056,17,288,1,52,
10056,17,289,1,46,
10056,17,347,4,0,
10056,17,371,4,0,
10056,17,373,1,34,
10056,17,373,4,0,
10056,17,374,4,0,
10056,17,389,1,40,
10056,17,399,4,0,
10056,17,416,4,0,
10056,17,421,4,0,
10056,17,425,1,13,
10056,17,433,4,0,
10056,17,451,4,0,
10056,17,496,4,0,
10056,17,506,1,22,
10056,17,566,1,1,1
10056,17,566,1,64,
10056,17,590,4,0,
10056,17,605,4,0,
10056,17,611,4,0,
10056,18,63,4,0,
10056,18,85,4,0,
10056,18,86,4,0,
10056,18,87,4,0,
10056,18,92,4,0,
10056,18,94,4,0,
10056,18,101,1,1,4
10056,18,101,1,7,
10056,18,103,1,1,3
10056,18,103,1,4,
10056,18,104,4,0,
10056,18,138,4,0,
10056,18,156,4,0,
10056,18,164,4,0,
10056,18,168,4,0,
10056,18,174,1,26,
10056,18,180,1,1,5
10056,18,180,1,10,
10056,18,182,4,0,
10056,18,185,1,19,
10056,18,207,4,0,
10056,18,213,4,0,
10056,18,214,4,0,
10056,18,216,4,0,
10056,18,218,4,0,
10056,18,237,4,0,
10056,18,240,4,0,
10056,18,241,4,0,
10056,18,244,4,0,
10056,18,247,1,30,
10056,18,247,4,0,
10056,18,259,4,0,
10056,18,261,1,16,
10056,18,261,4,0,
10056,18,263,4,0,
10056,18,269,4,0,
10056,18,271,1,58,
10056,18,282,1,1,2
10056,18,288,1,52,
10056,18,289,1,46,
10056,18,347,4,0,
10056,18,371,4,0,
10056,18,373,1,34,
10056,18,373,4,0,
10056,18,374,4,0,
10056,18,389,1,40,
10056,18,399,4,0,
10056,18,416,4,0,
10056,18,421,4,0,
10056,18,425,1,13,
10056,18,433,4,0,
10056,18,451,4,0,
10056,18,496,4,0,
10056,18,506,1,22,
10056,18,566,1,1,1
10056,18,566,1,64,
10056,18,590,4,0,
10056,18,605,4,0,
10056,18,611,4,0,
10057,15,10,1,1,6
10057,15,13,1,1,3
10057,15,13,1,57,
10057,15,14,1,33,
10057,15,14,4,0,
10057,15,15,4,0,
10057,15,38,2,0,
10057,15,43,1,4,
10057,15,44,1,20,
10057,15,53,4,0,
10057,15,58,4,0,
10057,15,59,4,0,
10057,15,63,4,0,
10057,15,70,4,0,
10057,15,85,4,0,
10057,15,86,4,0,
10057,15,87,4,0,
10057,15,92,4,0,
10057,15,98,1,9,
10057,15,104,1,25,
10057,15,104,4,0,
10057,15,126,4,0,
10057,15,138,4,0,
10057,15,148,4,0,
10057,15,156,4,0,
10057,15,157,4,0,
10057,15,163,1,28,
10057,15,164,4,0,
10057,15,168,4,0,
10057,15,174,2,0,
10057,15,182,4,0,
10057,15,185,2,0,
10057,15,195,1,1,1
10057,15,195,1,65,
10057,15,195,2,0,
10057,15,197,1,1,4
10057,15,197,1,44,
10057,15,201,4,0,
10057,15,206,4,0,
10057,15,207,4,0,
10057,15,212,2,0,
10057,15,213,4,0,
10057,15,214,4,0,
10057,15,216,4,0,
10057,15,218,4,0,
10057,15,224,2,0,
10057,15,226,2,0,
10057,15,228,1,12,
10057,15,237,4,0,
10057,15,240,4,0,
10057,15,241,4,0,
10057,15,244,4,0,
10057,15,247,4,0,
10057,15,248,1,36,
10057,15,249,4,0,
10057,15,258,4,0,
10057,15,259,4,0,
10057,15,261,4,0,
10057,15,263,4,0,
10057,15,269,1,1,5
10057,15,269,1,17,
10057,15,269,4,0,
10057,15,277,2,0,
10057,15,317,4,0,
10057,15,332,4,0,
10057,15,347,4,0,
10057,15,364,1,1,7
10057,15,371,4,0,
10057,15,372,2,0,
10057,15,382,1,1,2
10057,15,382,1,60,
10057,15,382,2,0,
10057,15,386,2,0,
10057,15,389,1,50,
10057,15,389,2,0,
10057,15,399,4,0,
10057,15,400,1,41,
10057,15,404,4,0,
10057,15,416,4,0,
10057,15,421,4,0,
10057,15,427,1,47,
10057,15,428,2,0,
10057,15,444,4,0,
10057,15,451,4,0,
10057,15,468,4,0,
10057,15,496,4,0,
10057,15,497,4,0,
10057,15,506,2,0,
10057,15,510,4,0,
10057,15,514,4,0,
10057,15,555,4,0,
10057,15,583,2,0,
10057,15,590,4,0,
10057,16,10,1,1,3
10057,16,13,1,49,1
10057,16,14,1,25,1
10057,16,14,4,0,
10057,16,15,4,0,
10057,16,43,1,1,5
10057,16,43,1,4,1
10057,16,44,1,16,1
10057,16,53,4,0,
10057,16,58,4,0,
10057,16,59,4,0,
10057,16,63,4,0,
10057,16,70,4,0,
10057,16,85,4,0,
10057,16,86,4,0,
10057,16,87,4,0,
10057,16,92,4,0,
10057,16,98,1,1,6
10057,16,98,1,7,1
10057,16,104,1,19,1
10057,16,104,4,0,
10057,16,126,4,0,
10057,16,138,4,0,
10057,16,148,4,0,
10057,16,156,4,0,
10057,16,157,4,0,
10057,16,163,1,22,1
10057,16,164,4,0,
10057,16,168,4,0,
10057,16,173,3,0,
10057,16,180,3,0,
10057,16,182,4,0,
10057,16,195,1,1,1
10057,16,195,1,57,1
10057,16,196,3,0,
10057,16,197,1,33,1
10057,16,201,4,0,
10057,16,206,4,0,
10057,16,207,4,0,
10057,16,213,4,0,
10057,16,214,4,0,
10057,16,216,4,0,
10057,16,218,4,0,
10057,16,228,1,10,1
10057,16,231,3,0,
10057,16,237,4,0,
10057,16,240,4,0,
10057,16,241,4,0,
10057,16,244,4,0,
10057,16,247,4,0,
10057,16,248,1,1,2
10057,16,248,1,53,1
10057,16,249,4,0,
10057,16,258,4,0,
10057,16,259,4,0,
10057,16,261,4,0,
10057,16,263,4,0,
10057,16,269,1,13,1
10057,16,269,4,0,
10057,16,272,3,0,
10057,16,276,3,0,
10057,16,277,3,0,
10057,16,282,3,0,
10057,16,289,3,0,
10057,16,290,4,0,
10057,16,317,4,0,
10057,16,332,4,0,
10057,16,340,3,0,
10057,16,347,4,0,
10057,16,351,3,0,
10057,16,352,3,0,
10057,16,364,1,1,4
10057,16,371,4,0,
10057,16,382,1,41,1
10057,16,389,1,45,1
10057,16,399,4,0,
10057,16,400,1,29,1
10057,16,404,4,0,
10057,16,416,4,0,
10057,16,421,4,0,
10057,16,427,1,37,1
10057,16,428,3,0,
10057,16,444,4,0,
10057,16,451,4,0,
10057,16,468,4,0,
10057,16,492,3,0,
10057,16,496,4,0,
10057,16,497,4,0,
10057,16,510,4,0,
10057,16,514,4,0,
10057,16,555,4,0,
10057,16,590,4,0,
10057,17,10,1,1,3
10057,17,13,1,49,
10057,17,14,1,25,
10057,17,14,4,0,
10057,17,43,1,1,5
10057,17,43,1,4,
10057,17,44,1,16,
10057,17,53,4,0,
10057,17,58,4,0,
10057,17,59,4,0,
10057,17,63,4,0,
10057,17,85,4,0,
10057,17,86,4,0,
10057,17,87,4,0,
10057,17,92,4,0,
10057,17,98,1,1,6
10057,17,98,1,7,
10057,17,104,1,19,
10057,17,104,4,0,
10057,17,126,4,0,
10057,17,138,4,0,
10057,17,156,4,0,
10057,17,157,4,0,
10057,17,163,1,22,
10057,17,164,4,0,
10057,17,168,4,0,
10057,17,182,4,0,
10057,17,195,1,1,1
10057,17,195,1,57,
10057,17,197,1,33,
10057,17,201,4,0,
10057,17,206,4,0,
10057,17,207,4,0,
10057,17,213,4,0,
10057,17,214,4,0,
10057,17,216,4,0,
10057,17,218,4,0,
10057,17,228,1,10,
10057,17,237,4,0,
10057,17,240,4,0,
10057,17,241,4,0,
10057,17,244,4,0,
10057,17,247,4,0,
10057,17,248,1,1,2
10057,17,248,1,53,
10057,17,258,4,0,
10057,17,259,4,0,
10057,17,261,4,0,
10057,17,263,4,0,
10057,17,269,1,13,
10057,17,269,4,0,
10057,17,317,4,0,
10057,17,332,4,0,
10057,17,347,4,0,
10057,17,364,1,1,4
10057,17,371,4,0,
10057,17,382,1,41,
10057,17,389,1,45,
10057,17,399,4,0,
10057,17,400,1,29,
10057,17,404,4,0,
10057,17,416,4,0,
10057,17,421,4,0,
10057,17,427,1,37,
10057,17,444,4,0,
10057,17,451,4,0,
10057,17,496,4,0,
10057,17,497,4,0,
10057,17,555,4,0,
10057,17,590,4,0,
10057,17,693,4,0,
10057,18,10,1,1,3
10057,18,13,1,49,
10057,18,14,1,25,
10057,18,14,4,0,
10057,18,43,1,1,5
10057,18,43,1,4,
10057,18,44,1,16,
10057,18,53,4,0,
10057,18,58,4,0,
10057,18,59,4,0,
10057,18,63,4,0,
10057,18,85,4,0,
10057,18,86,4,0,
10057,18,87,4,0,
10057,18,92,4,0,
10057,18,98,1,1,6
10057,18,98,1,7,
10057,18,104,1,19,
10057,18,104,4,0,
10057,18,126,4,0,
10057,18,138,4,0,
10057,18,156,4,0,
10057,18,157,4,0,
10057,18,163,1,22,
10057,18,164,4,0,
10057,18,168,4,0,
10057,18,182,4,0,
10057,18,195,1,1,1
10057,18,195,1,57,
10057,18,197,1,33,
10057,18,201,4,0,
10057,18,206,4,0,
10057,18,207,4,0,
10057,18,213,4,0,
10057,18,214,4,0,
10057,18,216,4,0,
10057,18,218,4,0,
10057,18,228,1,10,
10057,18,237,4,0,
10057,18,240,4,0,
10057,18,241,4,0,
10057,18,244,4,0,
10057,18,247,4,0,
10057,18,248,1,1,2
10057,18,248,1,53,
10057,18,258,4,0,
10057,18,259,4,0,
10057,18,261,4,0,
10057,18,263,4,0,
10057,18,269,1,13,
10057,18,269,4,0,
10057,18,317,4,0,
10057,18,332,4,0,
10057,18,347,4,0,
10057,18,364,1,1,4
10057,18,371,4,0,
10057,18,382,1,41,
10057,18,389,1,45,
10057,18,399,4,0,
10057,18,400,1,29,
10057,18,404,4,0,
10057,18,416,4,0,
10057,18,421,4,0,
10057,18,427,1,37,
10057,18,444,4,0,
10057,18,451,4,0,
10057,18,496,4,0,
10057,18,497,4,0,
10057,18,555,4,0,
10057,18,590,4,0,
10057,18,693,4,0,
10058,15,14,4,0,
10058,15,15,4,0,
10058,15,28,1,1,3
10058,15,28,1,3,
10058,15,33,1,1,2
10058,15,36,1,15,
10058,15,46,4,0,
10058,15,53,4,0,
10058,15,57,4,0,
10058,15,63,4,0,
10058,15,70,4,0,
10058,15,82,1,1,4
10058,15,82,1,7,
10058,15,89,4,0,
10058,15,91,1,40,
10058,15,91,4,0,
10058,15,92,4,0,
10058,15,104,4,0,
10058,15,126,4,0,
10058,15,156,4,0,
10058,15,157,4,0,
10058,15,163,1,28,
10058,15,164,4,0,
10058,15,182,4,0,
10058,15,201,1,1,5
10058,15,201,1,13,
10058,15,201,4,0,
10058,15,206,4,0,
10058,15,207,4,0,
10058,15,213,4,0,
10058,15,214,4,0,
10058,15,216,4,0,
10058,15,218,4,0,
10058,15,237,4,0,
10058,15,240,4,0,
10058,15,241,4,0,
10058,15,242,1,48,
10058,15,249,4,0,
10058,15,263,4,0,
10058,15,280,4,0,
10058,15,317,4,0,
10058,15,328,1,19,
10058,15,332,4,0,
10058,15,337,1,33,
10058,15,337,4,0,
10058,15,374,4,0,
10058,15,398,4,0,
10058,15,407,1,55,
10058,15,416,4,0,
10058,15,421,4,0,
10058,15,424,1,1,1
10058,15,434,3,0,
10058,15,444,4,0,
10058,15,468,4,0,
10058,15,496,4,0,
10058,15,510,4,0,
10058,15,523,4,0,
10058,15,525,4,0,
10058,15,530,1,24,
10058,15,590,4,0,
10058,16,14,4,0,
10058,16,15,4,0,
10058,16,28,1,1,3
10058,16,28,1,3,1
10058,16,33,1,1,2
10058,16,36,1,15,1
10058,16,46,4,0,
10058,16,53,4,0,
10058,16,57,4,0,
10058,16,63,4,0,
10058,16,70,4,0,
10058,16,82,1,1,4
10058,16,82,1,7,1
10058,16,89,4,0,
10058,16,91,1,40,1
10058,16,91,4,0,
10058,16,92,4,0,
10058,16,104,4,0,
10058,16,126,4,0,
10058,16,156,4,0,
10058,16,157,4,0,
10058,16,163,1,28,1
10058,16,164,4,0,
10058,16,173,3,0,
10058,16,182,4,0,
10058,16,200,3,0,
10058,16,201,1,1,5
10058,16,201,1,13,1
10058,16,201,4,0,
10058,16,206,4,0,
10058,16,207,4,0,
10058,16,213,4,0,
10058,16,214,4,0,
10058,16,216,4,0,
10058,16,218,4,0,
10058,16,231,3,0,
10058,16,237,4,0,
10058,16,240,4,0,
10058,16,241,4,0,
10058,16,242,1,48,1
10058,16,249,4,0,
10058,16,263,4,0,
10058,16,280,4,0,
10058,16,290,4,0,
10058,16,317,4,0,
10058,16,328,1,19,1
10058,16,332,4,0,
10058,16,337,1,33,1
10058,16,337,4,0,
10058,16,374,4,0,
10058,16,398,4,0,
10058,16,401,3,0,
10058,16,406,3,0,
10058,16,407,1,55,1
10058,16,414,3,0,
10058,16,416,4,0,
10058,16,421,4,0,
10058,16,424,1,1,1
10058,16,434,3,0,
10058,16,442,3,0,
10058,16,444,4,0,
10058,16,446,3,0,
10058,16,468,4,0,
10058,16,496,4,0,
10058,16,510,4,0,
10058,16,523,4,0,
10058,16,525,4,0,
10058,16,530,1,24,1
10058,16,530,3,0,
10058,16,590,4,0,
10058,17,14,4,0,
10058,17,28,1,1,5
10058,17,28,1,3,
10058,17,33,1,1,4
10058,17,36,1,15,
10058,17,46,4,0,
10058,17,53,4,0,
10058,17,57,4,0,
10058,17,63,4,0,
10058,17,82,1,1,6
10058,17,82,1,7,
10058,17,89,4,0,
10058,17,91,1,40,
10058,17,92,4,0,
10058,17,104,4,0,
10058,17,126,4,0,
10058,17,156,4,0,
10058,17,157,4,0,
10058,17,163,1,28,
10058,17,164,4,0,
10058,17,182,4,0,
10058,17,201,1,1,7
10058,17,201,1,13,
10058,17,201,4,0,
10058,17,206,4,0,
10058,17,207,4,0,
10058,17,213,4,0,
10058,17,214,4,0,
10058,17,216,4,0,
10058,17,218,4,0,
10058,17,237,4,0,
10058,17,240,4,0,
10058,17,241,4,0,
10058,17,242,1,0,
10058,17,242,1,1,1
10058,17,263,4,0,
10058,17,280,4,0,
10058,17,317,4,0,
10058,17,328,1,19,
10058,17,332,4,0,
10058,17,337,1,33,
10058,17,337,4,0,
10058,17,374,4,0,
10058,17,398,4,0,
10058,17,407,1,55,
10058,17,416,4,0,
10058,17,421,4,0,
10058,17,424,1,1,3
10058,17,434,3,0,
10058,17,444,4,0,
10058,17,496,4,0,
10058,17,523,4,0,
10058,17,525,4,0,
10058,17,530,1,1,2
10058,17,590,4,0,
10058,17,693,4,0,
10058,18,14,4,0,
10058,18,28,1,1,5
10058,18,28,1,3,
10058,18,33,1,1,4
10058,18,36,1,15,
10058,18,46,4,0,
10058,18,53,4,0,
10058,18,57,4,0,
10058,18,63,4,0,
10058,18,82,1,1,6
10058,18,82,1,7,
10058,18,89,4,0,
10058,18,91,1,40,
10058,18,92,4,0,
10058,18,104,4,0,
10058,18,126,4,0,
10058,18,156,4,0,
10058,18,157,4,0,
10058,18,163,1,28,
10058,18,164,4,0,
10058,18,182,4,0,
10058,18,201,1,1,7
10058,18,201,1,13,
10058,18,201,4,0,
10058,18,206,4,0,
10058,18,207,4,0,
10058,18,213,4,0,
10058,18,214,4,0,
10058,18,216,4,0,
10058,18,218,4,0,
10058,18,237,4,0,
10058,18,240,4,0,
10058,18,241,4,0,
10058,18,242,1,0,
10058,18,242,1,1,1
10058,18,263,4,0,
10058,18,280,4,0,
10058,18,317,4,0,
10058,18,328,1,19,
10058,18,332,4,0,
10058,18,337,1,33,
10058,18,337,4,0,
10058,18,374,4,0,
10058,18,398,4,0,
10058,18,407,1,55,
10058,18,416,4,0,
10058,18,421,4,0,
10058,18,424,1,1,3
10058,18,434,3,0,
10058,18,444,4,0,
10058,18,496,4,0,
10058,18,523,4,0,
10058,18,525,4,0,
10058,18,530,1,1,2
10058,18,590,4,0,
10058,18,693,4,0,
10059,15,14,1,19,
10059,15,14,4,0,
10059,15,46,4,0,
10059,15,63,4,0,
10059,15,68,1,6,
10059,15,70,4,0,
10059,15,89,4,0,
10059,15,91,4,0,
10059,15,92,4,0,
10059,15,94,4,0,
10059,15,98,1,1,6
10059,15,104,4,0,
10059,15,156,4,0,
10059,15,157,4,0,
10059,15,164,4,0,
10059,15,182,4,0,
10059,15,193,1,1,5
10059,15,197,1,1,7
10059,15,198,1,29,
10059,15,207,4,0,
10059,15,213,4,0,
10059,15,214,4,0,
10059,15,216,4,0,
10059,15,218,4,0,
10059,15,232,1,1,8
10059,15,237,4,0,
10059,15,240,4,0,
10059,15,241,4,0,
10059,15,245,1,1,1
10059,15,245,1,65,
10059,15,247,4,0,
10059,15,249,4,0,
10059,15,263,4,0,
10059,15,280,4,0,
10059,15,317,4,0,
10059,15,319,1,24,
10059,15,339,4,0,
10059,15,347,1,47,
10059,15,347,4,0,
10059,15,364,1,11,
10059,15,370,1,1,3
10059,15,370,1,55,
10059,15,371,4,0,
10059,15,374,4,0,
10059,15,382,1,37,
10059,15,396,1,1,4
10059,15,396,1,42,
10059,15,398,4,0,
10059,15,399,4,0,
10059,15,406,1,1,2
10059,15,406,1,60,
10059,15,411,4,0,
10059,15,416,4,0,
10059,15,421,4,0,
10059,15,430,4,0,
10059,15,444,4,0,
10059,15,468,4,0,
10059,15,490,4,0,
10059,15,496,4,0,
10059,15,501,1,33,
10059,15,505,1,51,
10059,15,514,4,0,
10059,15,523,4,0,
10059,15,590,4,0,
10059,15,612,1,15,
10059,15,612,4,0,
10059,16,8,3,0,
10059,16,9,3,0,
10059,16,14,1,19,1
10059,16,14,4,0,
10059,16,46,4,0,
10059,16,63,4,0,
10059,16,67,3,0,
10059,16,68,1,6,1
10059,16,70,4,0,
10059,16,89,4,0,
10059,16,91,4,0,
10059,16,92,4,0,
10059,16,94,4,0,
10059,16,98,1,1,6
10059,16,104,4,0,
10059,16,156,4,0,
10059,16,157,4,0,
10059,16,164,4,0,
10059,16,173,3,0,
10059,16,182,4,0,
10059,16,193,1,1,5
10059,16,197,1,1,7
10059,16,198,1,29,1
10059,16,207,4,0,
10059,16,213,4,0,
10059,16,214,4,0,
10059,16,216,4,0,
10059,16,218,4,0,
10059,16,231,3,0,
10059,16,232,1,1,8
10059,16,237,4,0,
10059,16,240,4,0,
10059,16,241,4,0,
10059,16,245,1,1,1
10059,16,245,1,65,1
10059,16,247,4,0,
10059,16,249,4,0,
10059,16,263,4,0,
10059,16,264,3,0,
10059,16,270,3,0,
10059,16,272,3,0,
10059,16,280,4,0,
10059,16,290,4,0,
10059,16,317,4,0,
10059,16,319,1,24,1
10059,16,334,3,0,
10059,16,339,4,0,
10059,16,347,1,47,1
10059,16,347,4,0,
10059,16,352,3,0,
10059,16,364,1,11,1
10059,16,370,1,1,3
10059,16,370,1,55,1
10059,16,371,4,0,
10059,16,374,4,0,
10059,16,382,1,37,1
10059,16,393,3,0,
10059,16,396,1,1,4
10059,16,396,1,42,1
10059,16,398,4,0,
10059,16,399,4,0,
10059,16,406,1,1,2
10059,16,406,1,60,1
10059,16,406,3,0,
10059,16,409,3,0,
10059,16,411,4,0,
10059,16,416,4,0,
10059,16,421,4,0,
10059,16,428,3,0,
10059,16,430,4,0,
10059,16,444,4,0,
10059,16,468,4,0,
10059,16,490,4,0,
10059,16,496,4,0,
10059,16,501,1,33,1
10059,16,505,1,51,1
10059,16,514,4,0,
10059,16,523,4,0,
10059,16,530,3,0,
10059,16,590,4,0,
10059,16,612,1,15,1
10059,16,612,4,0,
10059,17,14,1,19,
10059,17,14,4,0,
10059,17,46,4,0,
10059,17,63,4,0,
10059,17,68,1,6,
10059,17,89,4,0,
10059,17,92,4,0,
10059,17,94,4,0,
10059,17,98,1,1,4
10059,17,104,4,0,
10059,17,156,4,0,
10059,17,157,4,0,
10059,17,164,4,0,
10059,17,182,4,0,
10059,17,193,1,1,3
10059,17,197,1,1,5
10059,17,198,1,29,
10059,17,207,4,0,
10059,17,213,4,0,
10059,17,214,4,0,
10059,17,216,4,0,
10059,17,218,4,0,
10059,17,232,1,1,6
10059,17,237,4,0,
10059,17,240,4,0,
10059,17,241,4,0,
10059,17,245,1,65,
10059,17,247,4,0,
10059,17,263,4,0,
10059,17,280,4,0,
10059,17,317,4,0,
10059,17,319,1,24,
10059,17,339,4,0,
10059,17,347,1,47,
10059,17,347,4,0,
10059,17,364,1,11,
10059,17,370,1,55,
10059,17,371,4,0,
10059,17,374,4,0,
10059,17,382,1,37,
10059,17,396,1,0,
10059,17,396,1,1,1
10059,17,398,4,0,
10059,17,399,4,0,
10059,17,406,1,60,
10059,17,411,4,0,
10059,17,416,4,0,
10059,17,421,4,0,
10059,17,430,4,0,
10059,17,444,4,0,
10059,17,490,4,0,
10059,17,496,4,0,
10059,17,501,1,33,
10059,17,505,1,51,
10059,17,523,4,0,
10059,17,526,1,42,
10059,17,526,4,0,
10059,17,590,4,0,
10059,17,612,1,15,
10059,17,673,1,1,2
10059,18,14,1,19,
10059,18,14,4,0,
10059,18,46,4,0,
10059,18,63,4,0,
10059,18,68,1,6,
10059,18,89,4,0,
10059,18,92,4,0,
10059,18,94,4,0,
10059,18,98,1,1,4
10059,18,104,4,0,
10059,18,156,4,0,
10059,18,157,4,0,
10059,18,164,4,0,
10059,18,182,4,0,
10059,18,193,1,1,3
10059,18,197,1,1,5
10059,18,198,1,29,
10059,18,207,4,0,
10059,18,213,4,0,
10059,18,214,4,0,
10059,18,216,4,0,
10059,18,218,4,0,
10059,18,232,1,1,6
10059,18,237,4,0,
10059,18,240,4,0,
10059,18,241,4,0,
10059,18,245,1,65,
10059,18,247,4,0,
10059,18,263,4,0,
10059,18,280,4,0,
10059,18,317,4,0,
10059,18,319,1,24,
10059,18,339,4,0,
10059,18,347,1,47,
10059,18,347,4,0,
10059,18,364,1,11,
10059,18,370,1,55,
10059,18,371,4,0,
10059,18,374,4,0,
10059,18,382,1,37,
10059,18,396,1,0,
10059,18,396,1,1,1
10059,18,398,4,0,
10059,18,399,4,0,
10059,18,406,1,60,
10059,18,411,4,0,
10059,18,416,4,0,
10059,18,421,4,0,
10059,18,430,4,0,
10059,18,444,4,0,
10059,18,490,4,0,
10059,18,496,4,0,
10059,18,501,1,33,
10059,18,505,1,51,
10059,18,523,4,0,
10059,18,526,1,42,
10059,18,526,4,0,
10059,18,590,4,0,
10059,18,612,1,15,
10059,18,673,1,1,2
10060,15,8,1,1,1
10060,15,14,4,0,
10060,15,43,1,1,3
10060,15,54,1,21,
10060,15,58,4,0,
10060,15,59,1,47,
10060,15,59,4,0,
10060,15,63,4,0,
10060,15,70,4,0,
10060,15,75,1,1,4
10060,15,75,1,5,
10060,15,76,4,0,
10060,15,89,4,0,
10060,15,92,4,0,
10060,15,104,4,0,
10060,15,113,4,0,
10060,15,148,4,0,
10060,15,156,4,0,
10060,15,157,4,0,
10060,15,164,4,0,
10060,15,181,1,1,2
10060,15,182,4,0,
10060,15,196,1,1,5
10060,15,196,1,9,
10060,15,207,1,17,
10060,15,207,4,0,
10060,15,213,4,0,
10060,15,214,4,0,
10060,15,216,4,0,
10060,15,218,4,0,
10060,15,219,4,0,
10060,15,237,4,0,
10060,15,240,4,0,
10060,15,247,4,0,
10060,15,249,4,0,
10060,15,258,4,0,
10060,15,263,4,0,
10060,15,275,1,31,
10060,15,280,4,0,
10060,15,317,4,0,
10060,15,320,1,13,
10060,15,329,1,58,
10060,15,374,4,0,
10060,15,411,4,0,
10060,15,412,4,0,
10060,15,416,4,0,
10060,15,420,1,26,
10060,15,447,4,0,
10060,15,452,1,36,
10060,15,496,4,0,
10060,15,523,4,0,
10060,15,524,4,0,
10060,15,590,4,0,
10060,16,8,1,1,1
10060,16,8,3,0,
10060,16,14,4,0,
10060,16,43,1,1,3
10060,16,54,1,21,1
10060,16,58,4,0,
10060,16,59,1,47,1
10060,16,59,4,0,
10060,16,63,4,0,
10060,16,70,4,0,
10060,16,75,1,1,4
10060,16,75,1,5,1
10060,16,76,4,0,
10060,16,89,4,0,
10060,16,92,4,0,
10060,16,104,4,0,
10060,16,113,4,0,
10060,16,148,4,0,
10060,16,156,4,0,
10060,16,157,4,0,
10060,16,164,4,0,
10060,16,173,3,0,
10060,16,181,1,1,2
10060,16,182,4,0,
10060,16,196,1,1,5
10060,16,196,1,9,1
10060,16,196,3,0,
10060,16,200,3,0,
10060,16,202,3,0,
10060,16,207,1,17,1
10060,16,207,4,0,
10060,16,213,4,0,
10060,16,214,4,0,
10060,16,216,4,0,
10060,16,218,4,0,
10060,16,219,4,0,
10060,16,231,3,0,
10060,16,235,3,0,
10060,16,237,4,0,
10060,16,240,4,0,
10060,16,247,4,0,
10060,16,249,4,0,
10060,16,258,4,0,
10060,16,263,4,0,
10060,16,264,3,0,
10060,16,272,3,0,
10060,16,275,1,31,1
10060,16,280,4,0,
10060,16,290,4,0,
10060,16,317,4,0,
10060,16,320,1,13,1
10060,16,329,1,58,1
10060,16,335,3,0,
10060,16,352,3,0,
10060,16,374,4,0,
10060,16,388,3,0,
10060,16,402,3,0,
10060,16,411,4,0,
10060,16,412,4,0,
10060,16,416,4,0,
10060,16,420,1,26,1
10060,16,447,4,0,
10060,16,452,1,36,1
10060,16,496,4,0,
10060,16,523,4,0,
10060,16,524,4,0,
10060,16,590,4,0,
10060,17,8,1,1,1
10060,17,14,4,0,
10060,17,43,1,1,3
10060,17,54,1,21,
10060,17,58,4,0,
10060,17,59,1,47,
10060,17,59,4,0,
10060,17,63,4,0,
10060,17,75,1,1,4
10060,17,75,1,5,
10060,17,76,4,0,
10060,17,89,4,0,
10060,17,92,4,0,
10060,17,104,4,0,
10060,17,113,4,0,
10060,17,156,4,0,
10060,17,157,4,0,
10060,17,164,4,0,
10060,17,181,1,1,2
10060,17,182,4,0,
10060,17,196,1,1,5
10060,17,196,1,9,
10060,17,207,1,17,
10060,17,207,4,0,
10060,17,213,4,0,
10060,17,214,4,0,
10060,17,216,4,0,
10060,17,218,4,0,
10060,17,219,4,0,
10060,17,237,4,0,
10060,17,240,4,0,
10060,17,247,4,0,
10060,17,258,4,0,
10060,17,263,4,0,
10060,17,275,1,31,
10060,17,280,4,0,
10060,17,317,4,0,
10060,17,320,1,13,
10060,17,329,1,58,
10060,17,374,4,0,
10060,17,411,4,0,
10060,17,412,4,0,
10060,17,416,4,0,
10060,17,420,1,26,
10060,17,447,4,0,
10060,17,452,1,36,
10060,17,496,4,0,
10060,17,523,4,0,
10060,17,524,4,0,
10060,17,590,4,0,
10060,18,8,1,1,1
10060,18,14,4,0,
10060,18,43,1,1,3
10060,18,54,1,21,
10060,18,58,4,0,
10060,18,59,1,47,
10060,18,59,4,0,
10060,18,63,4,0,
10060,18,75,1,1,4
10060,18,75,1,5,
10060,18,76,4,0,
10060,18,89,4,0,
10060,18,92,4,0,
10060,18,104,4,0,
10060,18,113,4,0,
10060,18,156,4,0,
10060,18,157,4,0,
10060,18,164,4,0,
10060,18,181,1,1,2
10060,18,182,4,0,
10060,18,196,1,1,5
10060,18,196,1,9,
10060,18,207,1,17,
10060,18,207,4,0,
10060,18,213,4,0,
10060,18,214,4,0,
10060,18,216,4,0,
10060,18,218,4,0,
10060,18,219,4,0,
10060,18,237,4,0,
10060,18,240,4,0,
10060,18,247,4,0,
10060,18,258,4,0,
10060,18,263,4,0,
10060,18,275,1,31,
10060,18,280,4,0,
10060,18,317,4,0,
10060,18,320,1,13,
10060,18,329,1,58,
10060,18,374,4,0,
10060,18,411,4,0,
10060,18,412,4,0,
10060,18,416,4,0,
10060,18,420,1,26,
10060,18,447,4,0,
10060,18,452,1,36,
10060,18,496,4,0,
10060,18,523,4,0,
10060,18,524,4,0,
10060,18,590,4,0,
10061,15,22,1,1,2
10061,15,33,1,1,1
10061,15,75,1,15,
10061,15,76,1,58,
10061,15,76,4,0,
10061,15,80,1,51,
10061,15,92,4,0,
10061,15,94,4,0,
10061,15,104,4,0,
10061,15,148,4,0,
10061,15,156,4,0,
10061,15,164,4,0,
10061,15,182,4,0,
10061,15,207,4,0,
10061,15,213,4,0,
10061,15,214,4,0,
10061,15,216,4,0,
10061,15,218,4,0,
10061,15,219,4,0,
10061,15,237,4,0,
10061,15,240,4,0,
10061,15,241,4,0,
10061,15,263,4,0,
10061,15,267,4,0,
10061,15,273,1,20,
10061,15,312,1,38,
10061,15,345,1,25,
10061,15,347,4,0,
10061,15,381,1,10,
10061,15,412,4,0,
10061,15,447,4,0,
10061,15,496,4,0,
10061,15,497,4,0,
10061,15,572,1,33,
10061,15,580,1,27,
10061,15,581,1,43,
10061,15,584,1,6,
10061,15,585,1,46,
10061,15,590,4,0,
10061,15,605,4,0,
10061,15,617,1,50,
10061,16,22,1,1,2
10061,16,33,1,1,1
10061,16,75,1,15,1
10061,16,76,1,58,1
10061,16,76,4,0,
10061,16,80,1,51,1
10061,16,92,4,0,
10061,16,94,4,0,
10061,16,104,4,0,
10061,16,148,4,0,
10061,16,156,4,0,
10061,16,164,4,0,
10061,16,173,3,0,
10061,16,182,4,0,
10061,16,202,3,0,
10061,16,207,4,0,
10061,16,213,4,0,
10061,16,214,4,0,
10061,16,215,3,0,
10061,16,216,4,0,
10061,16,218,4,0,
10061,16,219,4,0,
10061,16,235,3,0,
10061,16,237,4,0,
10061,16,240,4,0,
10061,16,241,4,0,
10061,16,263,4,0,
10061,16,267,4,0,
10061,16,270,3,0,
10061,16,273,1,20,1
10061,16,277,3,0,
10061,16,283,3,0,
10061,16,290,4,0,
10061,16,312,1,38,1
10061,16,343,3,0,
10061,16,345,1,25,1
10061,16,347,4,0,
10061,16,381,1,10,1
10061,16,388,3,0,
10061,16,402,3,0,
10061,16,412,4,0,
10061,16,447,4,0,
10061,16,495,3,0,
10061,16,496,4,0,
10061,16,497,4,0,
10061,16,572,1,33,1
10061,16,580,1,27,1
10061,16,581,1,43,1
10061,16,584,1,6,1
10061,16,585,1,46,1
10061,16,590,4,0,
10061,16,605,4,0,
10061,16,617,1,50,1
10061,17,22,1,1,2
10061,17,33,1,1,1
10061,17,75,1,15,
10061,17,76,1,58,
10061,17,76,4,0,
10061,17,80,1,51,
10061,17,92,4,0,
10061,17,94,4,0,
10061,17,104,4,0,
10061,17,156,4,0,
10061,17,164,4,0,
10061,17,182,4,0,
10061,17,207,4,0,
10061,17,213,4,0,
10061,17,214,4,0,
10061,17,216,4,0,
10061,17,218,4,0,
10061,17,219,4,0,
10061,17,237,4,0,
10061,17,240,4,0,
10061,17,241,4,0,
10061,17,263,4,0,
10061,17,267,4,0,
10061,17,273,1,20,
10061,17,312,1,38,
10061,17,345,1,25,
10061,17,347,4,0,
10061,17,381,1,10,
10061,17,412,4,0,
10061,17,447,4,0,
10061,17,496,4,0,
10061,17,497,4,0,
10061,17,572,1,33,
10061,17,580,1,27,
10061,17,581,1,43,
10061,17,584,1,1,3
10061,17,584,1,6,
10061,17,585,1,46,
10061,17,590,4,0,
10061,17,605,4,0,
10061,17,617,1,50,
10061,18,22,1,1,2
10061,18,33,1,1,1
10061,18,75,1,15,
10061,18,76,1,58,
10061,18,76,4,0,
10061,18,80,1,51,
10061,18,92,4,0,
10061,18,94,4,0,
10061,18,104,4,0,
10061,18,156,4,0,
10061,18,164,4,0,
10061,18,182,4,0,
10061,18,207,4,0,
10061,18,213,4,0,
10061,18,214,4,0,
10061,18,216,4,0,
10061,18,218,4,0,
10061,18,219,4,0,
10061,18,237,4,0,
10061,18,240,4,0,
10061,18,241,4,0,
10061,18,263,4,0,
10061,18,267,4,0,
10061,18,273,1,20,
10061,18,312,1,38,
10061,18,345,1,25,
10061,18,347,4,0,
10061,18,381,1,10,
10061,18,412,4,0,
10061,18,447,4,0,
10061,18,496,4,0,
10061,18,497,4,0,
10061,18,572,1,33,
10061,18,580,1,27,
10061,18,581,1,43,
10061,18,584,1,1,3
10061,18,584,1,6,
10061,18,585,1,46,
10061,18,590,4,0,
10061,18,605,4,0,
10061,18,617,1,50,
10062,15,15,4,0,
10062,15,19,4,0,
10062,15,46,4,0,
10062,15,57,4,0,
10062,15,58,4,0,
10062,15,63,4,0,
10062,15,76,4,0,
10062,15,85,4,0,
10062,15,86,4,0,
10062,15,87,4,0,
10062,15,89,4,0,
10062,15,92,4,0,
10062,15,94,1,60,
10062,15,94,4,0,
10062,15,104,4,0,
10062,15,105,1,45,
10062,15,113,4,0,
10062,15,115,4,0,
10062,15,127,4,0,
10062,15,138,4,0,
10062,15,148,4,0,
10062,15,149,1,1,7
10062,15,156,4,0,
10062,15,164,4,0,
10062,15,182,4,0,
10062,15,201,4,0,
10062,15,204,1,1,6
10062,15,204,1,55,
10062,15,207,4,0,
10062,15,211,4,0,
10062,15,213,4,0,
10062,15,214,4,0,
10062,15,216,4,0,
10062,15,218,4,0,
10062,15,219,1,15,
10062,15,219,4,0,
10062,15,225,1,20,
10062,15,237,4,0,
10062,15,240,4,0,
10062,15,241,4,0,
10062,15,244,4,0,
10062,15,247,4,0,
10062,15,263,4,0,
10062,15,270,1,10,
10062,15,273,1,5,
10062,15,287,1,30,
10062,15,296,1,35,
10062,15,332,4,0,
10062,15,337,4,0,
10062,15,346,1,25,
10062,15,347,4,0,
10062,15,355,4,0,
10062,15,361,1,1,1
10062,15,361,1,85,
10062,15,375,1,50,
10062,15,406,1,1,2
10062,15,406,1,80,
10062,15,412,4,0,
10062,15,416,4,0,
10062,15,421,4,0,
10062,15,428,1,40,
10062,15,434,3,0,
10062,15,447,4,0,
10062,15,451,4,0,
10062,15,468,4,0,
10062,15,470,1,1,3
10062,15,470,1,75,
10062,15,473,4,0,
10062,15,496,4,0,
10062,15,505,1,1,5
10062,15,505,1,65,
10062,15,513,1,1,4
10062,15,513,1,70,
10062,15,514,4,0,
10062,15,523,4,0,
10062,15,590,4,0,
10062,16,15,4,0,
10062,16,19,4,0,
10062,16,46,4,0,
10062,16,57,4,0,
10062,16,58,4,0,
10062,16,63,4,0,
10062,16,76,4,0,
10062,16,85,4,0,
10062,16,86,4,0,
10062,16,87,4,0,
10062,16,89,4,0,
10062,16,92,4,0,
10062,16,94,1,51,1
10062,16,94,4,0,
10062,16,104,4,0,
10062,16,105,1,32,1
10062,16,113,4,0,
10062,16,115,4,0,
10062,16,127,4,0,
10062,16,138,4,0,
10062,16,148,4,0,
10062,16,149,1,1,4
10062,16,156,4,0,
10062,16,164,4,0,
10062,16,173,3,0,
10062,16,182,4,0,
10062,16,196,3,0,
10062,16,200,3,0,
10062,16,201,4,0,
10062,16,204,1,7,1
10062,16,207,4,0,
10062,16,211,4,0,
10062,16,213,4,0,
10062,16,214,4,0,
10062,16,216,4,0,
10062,16,218,4,0,
10062,16,219,1,1,5
10062,16,219,4,0,
10062,16,225,1,20,1
10062,16,237,4,0,
10062,16,240,4,0,
10062,16,241,4,0,
10062,16,244,4,0,
10062,16,247,4,0,
10062,16,263,4,0,
10062,16,270,1,1,2
10062,16,270,3,0,
10062,16,271,3,0,
10062,16,272,3,0,
10062,16,273,1,1,3
10062,16,277,3,0,
10062,16,287,1,13,1
10062,16,290,4,0,
10062,16,291,4,0,
10062,16,296,1,24,1
10062,16,332,4,0,
10062,16,337,4,0,
10062,16,343,3,0,
10062,16,346,1,4,1
10062,16,347,4,0,
10062,16,351,3,0,
10062,16,352,3,0,
10062,16,355,4,0,
10062,16,361,1,1,1
10062,16,361,1,61,1
10062,16,366,3,0,
10062,16,375,1,28,1
10062,16,387,3,0,
10062,16,406,1,56,1
10062,16,406,3,0,
10062,16,412,4,0,
10062,16,416,4,0,
10062,16,421,4,0,
10062,16,428,1,41,1
10062,16,428,3,0,
10062,16,434,3,0,
10062,16,447,4,0,
10062,16,451,4,0,
10062,16,468,4,0,
10062,16,470,1,46,1
10062,16,473,4,0,
10062,16,478,3,0,
10062,16,496,4,0,
10062,16,500,1,10,1
10062,16,505,1,16,1
10062,16,513,1,36,1
10062,16,514,4,0,
10062,16,523,4,0,
10062,16,590,4,0,
10062,17,19,4,0,
10062,17,46,4,0,
10062,17,57,4,0,
10062,17,58,4,0,
10062,17,63,4,0,
10062,17,76,4,0,
10062,17,85,4,0,
10062,17,86,4,0,
10062,17,87,4,0,
10062,17,89,4,0,
10062,17,92,4,0,
10062,17,94,1,51,
10062,17,94,4,0,
10062,17,104,4,0,
10062,17,105,1,32,
10062,17,113,4,0,
10062,17,115,4,0,
10062,17,127,4,0,
10062,17,138,4,0,
10062,17,149,1,1,4
10062,17,156,4,0,
10062,17,164,4,0,
10062,17,182,4,0,
10062,17,201,4,0,
10062,17,204,1,7,
10062,17,207,4,0,
10062,17,211,4,0,
10062,17,213,4,0,
10062,17,214,4,0,
10062,17,216,4,0,
10062,17,218,4,0,
10062,17,219,1,1,5
10062,17,219,4,0,
10062,17,225,1,20,
10062,17,237,4,0,
10062,17,240,4,0,
10062,17,241,4,0,
10062,17,244,4,0,
10062,17,247,4,0,
10062,17,263,4,0,
10062,17,270,1,1,2
10062,17,273,1,1,3
10062,17,287,1,13,
10062,17,296,1,24,
10062,17,332,4,0,
10062,17,337,4,0,
10062,17,346,1,4,
10062,17,347,4,0,
10062,17,355,4,0,
10062,17,361,1,1,1
10062,17,361,1,61,
10062,17,375,1,28,
10062,17,406,1,56,
10062,17,412,4,0,
10062,17,416,4,0,
10062,17,421,4,0,
10062,17,428,1,41,
10062,17,434,3,0,
10062,17,447,4,0,
10062,17,451,4,0,
10062,17,470,1,46,
10062,17,473,4,0,
10062,17,496,4,0,
10062,17,500,1,10,
10062,17,505,1,16,
10062,17,513,1,36,
10062,17,523,4,0,
10062,17,590,4,0,
10062,18,19,4,0,
10062,18,46,4,0,
10062,18,57,4,0,
10062,18,58,4,0,
10062,18,63,4,0,
10062,18,76,4,0,
10062,18,85,4,0,
10062,18,86,4,0,
10062,18,87,4,0,
10062,18,89,4,0,
10062,18,92,4,0,
10062,18,94,1,51,
10062,18,94,4,0,
10062,18,104,4,0,
10062,18,105,1,32,
10062,18,113,4,0,
10062,18,115,4,0,
10062,18,127,4,0,
10062,18,138,4,0,
10062,18,149,1,1,4
10062,18,156,4,0,
10062,18,164,4,0,
10062,18,182,4,0,
10062,18,201,4,0,
10062,18,204,1,7,
10062,18,207,4,0,
10062,18,211,4,0,
10062,18,213,4,0,
10062,18,214,4,0,
10062,18,216,4,0,
10062,18,218,4,0,
10062,18,219,1,1,5
10062,18,219,4,0,
10062,18,225,1,20,
10062,18,237,4,0,
10062,18,240,4,0,
10062,18,241,4,0,
10062,18,244,4,0,
10062,18,247,4,0,
10062,18,263,4,0,
10062,18,270,1,1,2
10062,18,273,1,1,3
10062,18,287,1,13,
10062,18,296,1,24,
10062,18,332,4,0,
10062,18,337,4,0,
10062,18,346,1,4,
10062,18,347,4,0,
10062,18,355,4,0,
10062,18,361,1,1,1
10062,18,361,1,61,
10062,18,375,1,28,
10062,18,406,1,56,
10062,18,412,4,0,
10062,18,416,4,0,
10062,18,421,4,0,
10062,18,428,1,41,
10062,18,434,3,0,
10062,18,447,4,0,
10062,18,451,4,0,
10062,18,470,1,46,
10062,18,473,4,0,
10062,18,496,4,0,
10062,18,500,1,10,
10062,18,505,1,16,
10062,18,513,1,36,
10062,18,523,4,0,
10062,18,590,4,0,
10063,15,15,4,0,
10063,15,19,4,0,
10063,15,46,4,0,
10063,15,57,4,0,
10063,15,58,4,0,
10063,15,63,4,0,
10063,15,76,4,0,
10063,15,85,4,0,
10063,15,86,4,0,
10063,15,87,4,0,
10063,15,89,4,0,
10063,15,92,4,0,
10063,15,94,1,60,
10063,15,94,4,0,
10063,15,104,4,0,
10063,15,105,1,45,
10063,15,113,4,0,
10063,15,115,4,0,
10063,15,127,4,0,
10063,15,138,4,0,
10063,15,148,4,0,
10063,15,149,1,1,7
10063,15,156,4,0,
10063,15,164,4,0,
10063,15,182,1,25,
10063,15,182,4,0,
10063,15,201,4,0,
10063,15,207,4,0,
10063,15,211,4,0,
10063,15,213,4,0,
10063,15,214,4,0,
10063,15,216,4,0,
10063,15,218,4,0,
10063,15,219,1,15,
10063,15,219,4,0,
10063,15,225,1,20,
10063,15,237,4,0,
10063,15,240,4,0,
10063,15,241,4,0,
10063,15,244,4,0,
10063,15,247,4,0,
10063,15,262,1,1,1
10063,15,262,1,85,
10063,15,263,4,0,
10063,15,270,1,10,
10063,15,287,1,30,
10063,15,295,1,35,
10063,15,332,4,0,
10063,15,337,4,0,
10063,15,347,4,0,
10063,15,349,1,1,6
10063,15,349,1,55,
10063,15,355,4,0,
10063,15,375,1,50,
10063,15,377,1,5,
10063,15,406,1,1,2
10063,15,406,1,80,
10063,15,412,4,0,
10063,15,416,4,0,
10063,15,421,4,0,
10063,15,428,1,40,
10063,15,434,3,0,
10063,15,447,4,0,
10063,15,451,4,0,
10063,15,468,4,0,
10063,15,471,1,1,3
10063,15,471,1,75,
10063,15,473,4,0,
10063,15,477,1,1,4
10063,15,477,1,70,
10063,15,496,4,0,
10063,15,505,1,1,5
10063,15,505,1,65,
10063,15,514,4,0,
10063,15,523,4,0,
10063,15,590,4,0,
10063,16,15,4,0,
10063,16,19,4,0,
10063,16,46,4,0,
10063,16,57,4,0,
10063,16,58,4,0,
10063,16,63,4,0,
10063,16,76,4,0,
10063,16,85,4,0,
10063,16,86,4,0,
10063,16,87,4,0,
10063,16,89,4,0,
10063,16,92,4,0,
10063,16,94,1,51,1
10063,16,94,4,0,
10063,16,104,4,0,
10063,16,105,1,32,1
10063,16,113,4,0,
10063,16,115,4,0,
10063,16,127,4,0,
10063,16,138,4,0,
10063,16,148,4,0,
10063,16,149,1,1,4
10063,16,156,4,0,
10063,16,164,4,0,
10063,16,173,3,0,
10063,16,182,1,4,1
10063,16,182,4,0,
10063,16,196,3,0,
10063,16,200,3,0,
10063,16,201,4,0,
10063,16,207,4,0,
10063,16,211,4,0,
10063,16,213,4,0,
10063,16,214,4,0,
10063,16,216,4,0,
10063,16,218,4,0,
10063,16,219,1,1,5
10063,16,219,4,0,
10063,16,225,1,20,1
10063,16,237,4,0,
10063,16,240,4,0,
10063,16,241,4,0,
10063,16,244,4,0,
10063,16,247,4,0,
10063,16,262,1,1,1
10063,16,262,1,61,1
10063,16,263,4,0,
10063,16,270,1,1,2
10063,16,270,3,0,
10063,16,271,3,0,
10063,16,277,3,0,
10063,16,287,1,13,1
10063,16,290,4,0,
10063,16,291,4,0,
10063,16,295,1,24,1
10063,16,332,4,0,
10063,16,337,4,0,
10063,16,347,4,0,
10063,16,349,1,7,1
10063,16,351,3,0,
10063,16,352,3,0,
10063,16,355,4,0,
10063,16,366,3,0,
10063,16,375,1,28,1
10063,16,377,1,1,3
10063,16,387,3,0,
10063,16,406,1,56,1
10063,16,406,3,0,
10063,16,412,4,0,
10063,16,416,4,0,
10063,16,421,4,0,
10063,16,428,1,41,1
10063,16,428,3,0,
10063,16,434,3,0,
10063,16,447,4,0,
10063,16,451,4,0,
10063,16,468,4,0,
10063,16,471,1,46,1
10063,16,472,3,0,
10063,16,473,4,0,
10063,16,477,1,36,1
10063,16,496,4,0,
10063,16,500,1,10,1
10063,16,505,1,16,1
10063,16,514,4,0,
10063,16,523,4,0,
10063,16,590,4,0,
10063,17,19,4,0,
10063,17,46,4,0,
10063,17,57,4,0,
10063,17,58,4,0,
10063,17,63,4,0,
10063,17,76,4,0,
10063,17,85,4,0,
10063,17,86,4,0,
10063,17,87,4,0,
10063,17,89,4,0,
10063,17,92,4,0,
10063,17,94,1,51,
10063,17,94,4,0,
10063,17,104,4,0,
10063,17,105,1,32,
10063,17,113,4,0,
10063,17,115,4,0,
10063,17,127,4,0,
10063,17,138,4,0,
10063,17,149,1,1,4
10063,17,156,4,0,
10063,17,164,4,0,
10063,17,182,1,4,
10063,17,182,4,0,
10063,17,201,4,0,
10063,17,207,4,0,
10063,17,211,4,0,
10063,17,213,4,0,
10063,17,214,4,0,
10063,17,216,4,0,
10063,17,218,4,0,
10063,17,219,1,1,5
10063,17,219,4,0,
10063,17,225,1,20,
10063,17,237,4,0,
10063,17,240,4,0,
10063,17,241,4,0,
10063,17,244,4,0,
10063,17,247,4,0,
10063,17,262,1,1,1
10063,17,262,1,61,
10063,17,263,4,0,
10063,17,270,1,1,2
10063,17,287,1,13,
10063,17,295,1,24,
10063,17,332,4,0,
10063,17,337,4,0,
10063,17,347,4,0,
10063,17,349,1,7,
10063,17,355,4,0,
10063,17,375,1,28,
10063,17,377,1,1,3
10063,17,406,1,56,
10063,17,412,4,0,
10063,17,416,4,0,
10063,17,421,4,0,
10063,17,428,1,41,
10063,17,434,3,0,
10063,17,447,4,0,
10063,17,451,4,0,
10063,17,471,1,46,
10063,17,473,4,0,
10063,17,477,1,36,
10063,17,496,4,0,
10063,17,500,1,10,
10063,17,505,1,16,
10063,17,523,4,0,
10063,17,590,4,0,
10063,18,19,4,0,
10063,18,46,4,0,
10063,18,57,4,0,
10063,18,58,4,0,
10063,18,63,4,0,
10063,18,76,4,0,
10063,18,85,4,0,
10063,18,86,4,0,
10063,18,87,4,0,
10063,18,89,4,0,
10063,18,92,4,0,
10063,18,94,1,51,
10063,18,94,4,0,
10063,18,104,4,0,
10063,18,105,1,32,
10063,18,113,4,0,
10063,18,115,4,0,
10063,18,127,4,0,
10063,18,138,4,0,
10063,18,149,1,1,4
10063,18,156,4,0,
10063,18,164,4,0,
10063,18,182,1,4,
10063,18,182,4,0,
10063,18,201,4,0,
10063,18,207,4,0,
10063,18,211,4,0,
10063,18,213,4,0,
10063,18,214,4,0,
10063,18,216,4,0,
10063,18,218,4,0,
10063,18,219,1,1,5
10063,18,219,4,0,
10063,18,225,1,20,
10063,18,237,4,0,
10063,18,240,4,0,
10063,18,241,4,0,
10063,18,244,4,0,
10063,18,247,4,0,
10063,18,262,1,1,1
10063,18,262,1,61,
10063,18,263,4,0,
10063,18,270,1,1,2
10063,18,287,1,13,
10063,18,295,1,24,
10063,18,332,4,0,
10063,18,337,4,0,
10063,18,347,4,0,
10063,18,349,1,7,
10063,18,355,4,0,
10063,18,375,1,28,
10063,18,377,1,1,3
10063,18,406,1,56,
10063,18,412,4,0,
10063,18,416,4,0,
10063,18,421,4,0,
10063,18,428,1,41,
10063,18,434,3,0,
10063,18,447,4,0,
10063,18,451,4,0,
10063,18,471,1,46,
10063,18,473,4,0,
10063,18,477,1,36,
10063,18,496,4,0,
10063,18,500,1,10,
10063,18,505,1,16,
10063,18,523,4,0,
10063,18,590,4,0,
10064,16,8,3,0,
10064,16,33,1,1,2
10064,16,36,1,44,1
10064,16,45,1,1,3
10064,16,46,4,0,
10064,16,55,1,1,4
10064,16,55,1,4,1
10064,16,57,4,0,
10064,16,58,4,0,
10064,16,59,4,0,
10064,16,63,4,0,
10064,16,67,3,0,
10064,16,70,4,0,
10064,16,89,1,51,1
10064,16,89,4,0,
10064,16,91,4,0,
10064,16,92,4,0,
10064,16,104,4,0,
10064,16,117,1,18,1
10064,16,127,4,0,
10064,16,156,4,0,
10064,16,157,1,28,1
10064,16,157,4,0,
10064,16,164,4,0,
10064,16,173,3,0,
10064,16,182,1,32,1
10064,16,182,4,0,
10064,16,189,1,1,5
10064,16,189,1,9,1
10064,16,193,1,12,1
10064,16,196,3,0,
10064,16,200,3,0,
10064,16,207,4,0,
10064,16,213,4,0,
10064,16,214,4,0,
10064,16,216,4,0,
10064,16,218,4,0,
10064,16,231,3,0,
10064,16,237,4,0,
10064,16,240,4,0,
10064,16,249,4,0,
10064,16,253,3,0,
10064,16,258,4,0,
10064,16,263,4,0,
10064,16,264,3,0,
10064,16,276,3,0,
10064,16,280,4,0,
10064,16,283,1,56,1
10064,16,283,3,0,
10064,16,290,4,0,
10064,16,291,4,0,
10064,16,308,3,0,
10064,16,317,4,0,
10064,16,330,1,39,1
10064,16,341,1,16,1
10064,16,352,3,0,
10064,16,359,1,1,1
10064,16,359,1,63,1
10064,16,374,4,0,
10064,16,401,3,0,
10064,16,411,4,0,
10064,16,414,3,0,
10064,16,416,4,0,
10064,16,426,1,22,1
10064,16,444,4,0,
10064,16,446,3,0,
10064,16,482,4,0,
10064,16,496,4,0,
10064,16,497,4,0,
10064,16,503,4,0,
10064,16,518,3,0,
10064,16,523,4,0,
10064,16,590,4,0,
10064,16,612,4,0,
10064,17,33,1,1,3
10064,17,36,1,44,
10064,17,45,1,1,4
10064,17,46,4,0,
10064,17,55,1,1,5
10064,17,55,1,4,
10064,17,57,4,0,
10064,17,58,4,0,
10064,17,59,4,0,
10064,17,63,4,0,
10064,17,89,1,51,
10064,17,89,4,0,
10064,17,92,4,0,
10064,17,104,4,0,
10064,17,117,1,18,
10064,17,127,4,0,
10064,17,156,4,0,
10064,17,157,1,28,
10064,17,157,4,0,
10064,17,164,4,0,
10064,17,182,1,32,
10064,17,182,4,0,
10064,17,189,1,1,6
10064,17,189,1,9,
10064,17,193,1,12,
10064,17,207,4,0,
10064,17,213,4,0,
10064,17,214,4,0,
10064,17,216,4,0,
10064,17,218,4,0,
10064,17,237,4,0,
10064,17,240,4,0,
10064,17,258,4,0,
10064,17,263,4,0,
10064,17,280,4,0,
10064,17,283,1,56,
10064,17,308,3,0,
10064,17,317,4,0,
10064,17,330,1,39,
10064,17,341,1,1,1
10064,17,359,1,1,2
10064,17,359,1,63,
10064,17,374,4,0,
10064,17,411,4,0,
10064,17,416,4,0,
10064,17,426,1,22,
10064,17,444,4,0,
10064,17,482,4,0,
10064,17,496,4,0,
10064,17,497,4,0,
10064,17,503,4,0,
10064,17,518,3,0,
10064,17,523,4,0,
10064,17,526,4,0,
10064,17,590,4,0,
10064,18,33,1,1,3
10064,18,36,1,44,
10064,18,45,1,1,4
10064,18,46,4,0,
10064,18,55,1,1,5
10064,18,55,1,4,
10064,18,57,4,0,
10064,18,58,4,0,
10064,18,59,4,0,
10064,18,63,4,0,
10064,18,89,1,51,
10064,18,89,4,0,
10064,18,92,4,0,
10064,18,104,4,0,
10064,18,117,1,18,
10064,18,127,4,0,
10064,18,156,4,0,
10064,18,157,1,28,
10064,18,157,4,0,
10064,18,164,4,0,
10064,18,182,1,32,
10064,18,182,4,0,
10064,18,189,1,1,6
10064,18,189,1,9,
10064,18,193,1,12,
10064,18,207,4,0,
10064,18,213,4,0,
10064,18,214,4,0,
10064,18,216,4,0,
10064,18,218,4,0,
10064,18,237,4,0,
10064,18,240,4,0,
10064,18,258,4,0,
10064,18,263,4,0,
10064,18,280,4,0,
10064,18,283,1,56,
10064,18,308,3,0,
10064,18,317,4,0,
10064,18,330,1,39,
10064,18,341,1,1,1
10064,18,359,1,1,2
10064,18,359,1,63,
10064,18,374,4,0,
10064,18,411,4,0,
10064,18,416,4,0,
10064,18,426,1,22,
10064,18,444,4,0,
10064,18,482,4,0,
10064,18,496,4,0,
10064,18,497,4,0,
10064,18,503,4,0,
10064,18,518,3,0,
10064,18,523,4,0,
10064,18,526,4,0,
10064,18,590,4,0,
10065,16,1,1,1,3
10065,16,9,3,0,
10065,16,14,4,0,
10065,16,15,4,0,
10065,16,21,1,33,1
10065,16,43,1,1,4
10065,16,46,4,0,
10065,16,63,4,0,
10065,16,67,3,0,
10065,16,70,4,0,
10065,16,71,1,1,5
10065,16,71,1,5,1
10065,16,72,1,13,1
10065,16,76,4,0,
10065,16,89,4,0,
10065,16,91,4,0,
10065,16,92,4,0,
10065,16,97,1,28,1
10065,16,98,1,1,6
10065,16,98,1,9,1
10065,16,103,1,69,1
10065,16,104,4,0,
10065,16,148,4,0,
10065,16,156,4,0,
10065,16,157,4,0,
10065,16,164,4,0,
10065,16,173,3,0,
10065,16,182,4,0,
10065,16,197,1,39,1
10065,16,200,3,0,
10065,16,202,3,0,
10065,16,206,1,51,1
10065,16,206,4,0,
10065,16,207,4,0,
10065,16,210,1,16,1
10065,16,213,4,0,
10065,16,214,4,0,
10065,16,216,4,0,
10065,16,218,4,0,
10065,16,219,4,0,
10065,16,228,1,18,1
10065,16,231,3,0,
10065,16,235,3,0,
10065,16,237,4,0,
10065,16,241,4,0,
10065,16,249,4,0,
10065,16,263,4,0,
10065,16,264,3,0,
10065,16,267,4,0,
10065,16,280,4,0,
10065,16,283,3,0,
10065,16,290,4,0,
10065,16,317,4,0,
10065,16,332,4,0,
10065,16,337,4,0,
10065,16,338,3,0,
10065,16,348,1,23,1
10065,16,374,4,0,
10065,16,388,3,0,
10065,16,400,1,1,2
10065,16,402,3,0,
10065,16,404,1,45,1
10065,16,404,4,0,
10065,16,406,3,0,
10065,16,409,3,0,
10065,16,411,4,0,
10065,16,412,4,0,
10065,16,416,4,0,
10065,16,437,1,1,1
10065,16,437,1,63,1
10065,16,447,4,0,
10065,16,468,4,0,
10065,16,490,4,0,
10065,16,496,4,0,
10065,16,501,1,57,1
10065,16,512,4,0,
10065,16,520,3,0,
10065,16,523,4,0,
10065,16,530,1,36,1
10065,16,530,3,0,
10065,16,590,4,0,
10065,16,612,4,0,
10065,17,1,1,1,5
10065,17,14,4,0,
10065,17,21,1,33,
10065,17,43,1,1,6
10065,17,46,4,0,
10065,17,63,4,0,
10065,17,71,1,1,7
10065,17,71,1,5,
10065,17,72,1,13,
10065,17,76,4,0,
10065,17,89,4,0,
10065,17,92,4,0,
10065,17,97,1,28,
10065,17,98,1,1,8
10065,17,98,1,9,
10065,17,103,1,69,
10065,17,104,4,0,
10065,17,156,4,0,
10065,17,157,4,0,
10065,17,164,4,0,
10065,17,182,4,0,
10065,17,197,1,39,
10065,17,206,1,51,
10065,17,206,4,0,
10065,17,207,4,0,
10065,17,210,1,1,2
10065,17,213,4,0,
10065,17,214,4,0,
10065,17,216,4,0,
10065,17,218,4,0,
10065,17,219,4,0,
10065,17,228,1,18,
10065,17,237,4,0,
10065,17,241,4,0,
10065,17,263,4,0,
10065,17,267,4,0,
10065,17,280,4,0,
10065,17,317,4,0,
10065,17,332,4,0,
10065,17,337,4,0,
10065,17,338,3,0,
10065,17,348,1,23,
10065,17,374,4,0,
10065,17,400,1,1,4
10065,17,404,1,45,
10065,17,404,4,0,
10065,17,411,4,0,
10065,17,412,4,0,
10065,17,416,4,0,
10065,17,437,1,1,3
10065,17,437,1,63,
10065,17,447,4,0,
10065,17,490,4,0,
10065,17,496,4,0,
10065,17,501,1,57,
10065,17,512,4,0,
10065,17,520,3,0,
10065,17,523,4,0,
10065,17,526,4,0,
10065,17,530,1,0,
10065,17,530,1,1,1
10065,17,590,4,0,
10065,17,693,4,0,
10065,18,1,1,1,5
10065,18,14,4,0,
10065,18,21,1,33,
10065,18,43,1,1,6
10065,18,46,4,0,
10065,18,63,4,0,
10065,18,71,1,1,7
10065,18,71,1,5,
10065,18,72,1,13,
10065,18,76,4,0,
10065,18,89,4,0,
10065,18,92,4,0,
10065,18,97,1,28,
10065,18,98,1,1,8
10065,18,98,1,9,
10065,18,103,1,69,
10065,18,104,4,0,
10065,18,156,4,0,
10065,18,157,4,0,
10065,18,164,4,0,
10065,18,182,4,0,
10065,18,197,1,39,
10065,18,206,1,51,
10065,18,206,4,0,
10065,18,207,4,0,
10065,18,210,1,1,2
10065,18,213,4,0,
10065,18,214,4,0,
10065,18,216,4,0,
10065,18,218,4,0,
10065,18,219,4,0,
10065,18,228,1,18,
10065,18,237,4,0,
10065,18,241,4,0,
10065,18,263,4,0,
10065,18,267,4,0,
10065,18,280,4,0,
10065,18,317,4,0,
10065,18,332,4,0,
10065,18,337,4,0,
10065,18,338,3,0,
10065,18,348,1,23,
10065,18,374,4,0,
10065,18,400,1,1,4
10065,18,404,1,45,
10065,18,404,4,0,
10065,18,411,4,0,
10065,18,412,4,0,
10065,18,416,4,0,
10065,18,437,1,1,3
10065,18,437,1,63,
10065,18,447,4,0,
10065,18,490,4,0,
10065,18,496,4,0,
10065,18,501,1,57,
10065,18,512,4,0,
10065,18,520,3,0,
10065,18,523,4,0,
10065,18,526,4,0,
10065,18,530,1,0,
10065,18,530,1,1,1
10065,18,590,4,0,
10065,18,693,4,0,
10066,16,7,3,0,
10066,16,8,3,0,
10066,16,9,3,0,
10066,16,10,1,1,2
10066,16,15,4,0,
10066,16,43,1,1,1
10066,16,67,3,0,
10066,16,91,4,0,
10066,16,92,4,0,
10066,16,94,4,0,
10066,16,101,1,6,1
10066,16,104,4,0,
10066,16,109,1,31,1
10066,16,138,4,0,
10066,16,148,4,0,
10066,16,154,1,11,1
10066,16,156,4,0,
10066,16,164,4,0,
10066,16,168,4,0,
10066,16,173,3,0,
10066,16,180,3,0,
10066,16,182,4,0,
10066,16,185,1,19,1
10066,16,193,1,4,1
10066,16,196,3,0,
10066,16,197,1,14,1
10066,16,207,4,0,
10066,16,212,1,46,1
10066,16,213,4,0,
10066,16,214,4,0,
10066,16,216,4,0,
10066,16,218,4,0,
10066,16,220,3,0,
10066,16,237,4,0,
10066,16,240,4,0,
10066,16,241,4,0,
10066,16,244,4,0,
10066,16,247,1,39,1
10066,16,247,4,0,
10066,16,249,4,0,
10066,16,252,1,21,1
10066,16,259,4,0,
10066,16,261,4,0,
10066,16,263,4,0,
10066,16,264,3,0,
10066,16,269,4,0,
10066,16,271,3,0,
10066,16,272,3,0,
10066,16,277,3,0,
10066,16,280,4,0,
10066,16,282,1,26,1
10066,16,282,3,0,
10066,16,289,3,0,
10066,16,290,4,0,
10066,16,310,1,9,1
10066,16,317,4,0,
10066,16,324,3,0,
10066,16,332,4,0,
10066,16,347,4,0,
10066,16,351,3,0,
10066,16,352,3,0,
10066,16,356,3,0,
10066,16,371,4,0,
10066,16,373,4,0,
10066,16,374,4,0,
10066,16,386,1,24,1
10066,16,398,4,0,
10066,16,399,4,0,
10066,16,408,1,36,1
10066,16,421,1,29,1
10066,16,421,4,0,
10066,16,425,1,16,1
10066,16,428,1,34,1
10066,16,428,3,0,
10066,16,468,4,0,
10066,16,472,3,0,
10066,16,490,4,0,
10066,16,492,1,41,1
10066,16,492,3,0,
10066,16,496,4,0,
10066,16,510,4,0,
10066,16,511,1,44,1
10066,16,511,4,0,
10066,16,514,4,0,
10066,16,555,4,0,
10066,16,590,4,0,
10066,16,605,4,0,
10066,16,612,4,0,
10066,17,10,1,1,2
10066,17,43,1,1,1
10066,17,92,4,0,
10066,17,94,4,0,
10066,17,101,1,6,
10066,17,104,4,0,
10066,17,109,1,31,
10066,17,138,4,0,
10066,17,154,1,11,
10066,17,156,4,0,
10066,17,164,4,0,
10066,17,168,4,0,
10066,17,182,4,0,
10066,17,185,1,19,
10066,17,193,1,4,
10066,17,197,1,14,
10066,17,207,4,0,
10066,17,212,1,46,
10066,17,213,4,0,
10066,17,214,4,0,
10066,17,216,4,0,
10066,17,218,4,0,
10066,17,237,4,0,
10066,17,240,4,0,
10066,17,241,4,0,
10066,17,244,4,0,
10066,17,247,1,39,
10066,17,247,4,0,
10066,17,252,1,21,
10066,17,259,4,0,
10066,17,261,4,0,
10066,17,263,4,0,
10066,17,269,4,0,
10066,17,280,4,0,
10066,17,282,1,26,
10066,17,310,1,9,
10066,17,317,4,0,
10066,17,332,4,0,
10066,17,347,4,0,
10066,17,371,4,0,
10066,17,373,4,0,
10066,17,374,4,0,
10066,17,386,1,24,
10066,17,398,4,0,
10066,17,399,4,0,
10066,17,408,1,36,
10066,17,421,1,29,
10066,17,421,4,0,
10066,17,425,1,16,
10066,17,428,1,34,
10066,17,490,4,0,
10066,17,492,1,41,
10066,17,496,4,0,
10066,17,511,1,44,
10066,17,511,4,0,
10066,17,555,4,0,
10066,17,590,4,0,
10066,17,605,4,0,
10066,18,10,1,1,2
10066,18,43,1,1,1
10066,18,92,4,0,
10066,18,94,4,0,
10066,18,101,1,6,
10066,18,104,4,0,
10066,18,109,1,31,
10066,18,138,4,0,
10066,18,154,1,11,
10066,18,156,4,0,
10066,18,164,4,0,
10066,18,168,4,0,
10066,18,182,4,0,
10066,18,185,1,19,
10066,18,193,1,4,
10066,18,197,1,14,
10066,18,207,4,0,
10066,18,212,1,46,
10066,18,213,4,0,
10066,18,214,4,0,
10066,18,216,4,0,
10066,18,218,4,0,
10066,18,237,4,0,
10066,18,240,4,0,
10066,18,241,4,0,
10066,18,244,4,0,
10066,18,247,1,39,
10066,18,247,4,0,
10066,18,252,1,21,
10066,18,259,4,0,
10066,18,261,4,0,
10066,18,263,4,0,
10066,18,269,4,0,
10066,18,280,4,0,
10066,18,282,1,26,
10066,18,310,1,9,
10066,18,317,4,0,
10066,18,332,4,0,
10066,18,347,4,0,
10066,18,371,4,0,
10066,18,373,4,0,
10066,18,374,4,0,
10066,18,386,1,24,
10066,18,398,4,0,
10066,18,399,4,0,
10066,18,408,1,36,
10066,18,421,1,29,
10066,18,421,4,0,
10066,18,425,1,16,
10066,18,428,1,34,
10066,18,490,4,0,
10066,18,492,1,41,
10066,18,496,4,0,
10066,18,511,1,44,
10066,18,511,4,0,
10066,18,555,4,0,
10066,18,590,4,0,
10066,18,605,4,0,
10067,16,19,4,0,
10067,16,31,1,7,1
10067,16,36,1,23,1
10067,16,45,1,1,4
10067,16,46,4,0,
10067,16,47,1,1,6
10067,16,47,1,5,1
10067,16,53,4,0,
10067,16,54,1,14,1
10067,16,58,4,0,
10067,16,63,4,0,
10067,16,64,1,1,3
10067,16,76,4,0,
10067,16,89,4,0,
10067,16,92,4,0,
10067,16,104,4,0,
10067,16,126,4,0,
10067,16,138,4,0,
10067,16,143,1,1,1
10067,16,143,1,59,1
10067,16,143,3,0,
10067,16,156,4,0,
10067,16,164,4,0,
10067,16,168,4,0,
10067,16,173,3,0,
10067,16,182,4,0,
10067,16,195,1,46,1
10067,16,200,3,0,
10067,16,207,4,0,
10067,16,211,4,0,
10067,16,213,4,0,
10067,16,214,4,0,
10067,16,215,3,0,
10067,16,216,4,0,
10067,16,218,4,0,
10067,16,219,1,9,1
10067,16,219,4,0,
10067,16,225,1,35,1
10067,16,231,3,0,
10067,16,237,4,0,
10067,16,240,4,0,
10067,16,241,4,0,
10067,16,244,4,0,
10067,16,249,4,0,
10067,16,253,3,0,
10067,16,257,3,0,
10067,16,263,4,0,
10067,16,287,1,26,1
10067,16,290,4,0,
10067,16,304,3,0,
10067,16,310,1,1,5
10067,16,310,1,3,1
10067,16,332,4,0,
10067,16,337,4,0,
10067,16,349,1,30,1
10067,16,355,4,0,
10067,16,363,1,20,1
10067,16,365,1,1,2
10067,16,366,3,0,
10067,16,406,1,40,1
10067,16,406,3,0,
10067,16,416,4,0,
10067,16,434,3,0,
10067,16,468,4,0,
10067,16,472,3,0,
10067,16,496,1,17,1
10067,16,496,4,0,
10067,16,497,4,0,
10067,16,510,4,0,
10067,16,523,4,0,
10067,16,538,1,34,1
10067,16,574,1,11,1
10067,16,585,1,52,1
10067,16,590,4,0,
10067,16,605,4,0,
10067,17,19,4,0,
10067,17,31,1,7,
10067,17,36,1,23,
10067,17,45,1,1,5
10067,17,46,4,0,
10067,17,47,1,1,7
10067,17,47,1,5,
10067,17,53,4,0,
10067,17,54,1,14,
10067,17,58,4,0,
10067,17,63,4,0,
10067,17,64,1,1,4
10067,17,76,4,0,
10067,17,89,4,0,
10067,17,92,4,0,
10067,17,104,4,0,
10067,17,126,4,0,
10067,17,138,4,0,
10067,17,143,1,1,2
10067,17,143,1,59,
10067,17,156,4,0,
10067,17,164,4,0,
10067,17,168,4,0,
10067,17,182,4,0,
10067,17,195,1,46,
10067,17,207,4,0,
10067,17,211,4,0,
10067,17,213,4,0,
10067,17,214,4,0,
10067,17,216,4,0,
10067,17,218,4,0,
10067,17,219,1,9,
10067,17,219,4,0,
10067,17,225,1,0,
10067,17,225,1,1,1
10067,17,237,4,0,
10067,17,240,4,0,
10067,17,241,4,0,
10067,17,244,4,0,
10067,17,263,4,0,
10067,17,287,1,26,
10067,17,310,1,1,6
10067,17,310,1,3,
10067,17,332,4,0,
10067,17,337,4,0,
10067,17,349,1,30,
10067,17,355,4,0,
10067,17,363,1,20,
10067,17,365,1,1,3
10067,17,406,1,40,
10067,17,416,4,0,
10067,17,434,3,0,
10067,17,496,1,17,
10067,17,496,4,0,
10067,17,497,4,0,
10067,17,523,4,0,
10067,17,538,1,34,
10067,17,574,1,11,
10067,17,585,1,52,
10067,17,590,4,0,
10067,17,605,4,0,
10067,18,19,4,0,
10067,18,31,1,7,
10067,18,36,1,23,
10067,18,45,1,1,5
10067,18,46,4,0,
10067,18,47,1,1,7
10067,18,47,1,5,
10067,18,53,4,0,
10067,18,54,1,14,
10067,18,58,4,0,
10067,18,63,4,0,
10067,18,64,1,1,4
10067,18,76,4,0,
10067,18,89,4,0,
10067,18,92,4,0,
10067,18,104,4,0,
10067,18,126,4,0,
10067,18,138,4,0,
10067,18,143,1,1,2
10067,18,143,1,59,
10067,18,156,4,0,
10067,18,164,4,0,
10067,18,168,4,0,
10067,18,182,4,0,
10067,18,195,1,46,
10067,18,207,4,0,
10067,18,211,4,0,
10067,18,213,4,0,
10067,18,214,4,0,
10067,18,216,4,0,
10067,18,218,4,0,
10067,18,219,1,9,
10067,18,219,4,0,
10067,18,225,1,0,
10067,18,225,1,1,1
10067,18,237,4,0,
10067,18,240,4,0,
10067,18,241,4,0,
10067,18,244,4,0,
10067,18,263,4,0,
10067,18,287,1,26,
10067,18,310,1,1,6
10067,18,310,1,3,
10067,18,332,4,0,
10067,18,337,4,0,
10067,18,349,1,30,
10067,18,355,4,0,
10067,18,363,1,20,
10067,18,365,1,1,3
10067,18,406,1,40,
10067,18,416,4,0,
10067,18,434,3,0,
10067,18,496,1,17,
10067,18,496,4,0,
10067,18,497,4,0,
10067,18,523,4,0,
10067,18,538,1,34,
10067,18,574,1,11,
10067,18,585,1,52,
10067,18,590,4,0,
10067,18,605,4,0,
10068,16,7,3,0,
10068,16,8,3,0,
10068,16,9,3,0,
10068,16,14,1,26,1
10068,16,14,4,0,
10068,16,15,4,0,
10068,16,43,1,1,5
10068,16,63,4,0,
10068,16,67,3,0,
10068,16,70,4,0,
10068,16,85,4,0,
10068,16,86,4,0,
10068,16,89,4,0,
10068,16,92,4,0,
10068,16,93,1,1,6
10068,16,93,1,4,1
10068,16,94,4,0,
10068,16,100,1,1,8
10068,16,100,1,9,1
10068,16,104,1,1,7
10068,16,104,1,6,1
10068,16,104,4,0,
10068,16,113,4,0,
10068,16,115,4,0,
10068,16,138,4,0,
10068,16,148,4,0,
10068,16,156,4,0,
10068,16,157,4,0,
10068,16,163,1,17,1
10068,16,164,4,0,
10068,16,168,4,0,
10068,16,173,3,0,
10068,16,182,1,49,1
10068,16,182,4,0,
10068,16,206,1,44,1
10068,16,206,4,0,
10068,16,207,4,0,
10068,16,210,1,14,1
10068,16,213,4,0,
10068,16,214,4,0,
10068,16,216,4,0,
10068,16,218,4,0,
10068,16,219,4,0,
10068,16,220,3,0,
10068,16,237,4,0,
10068,16,240,4,0,
10068,16,241,4,0,
10068,16,244,4,0,
10068,16,247,4,0,
10068,16,249,4,0,
10068,16,259,4,0,
10068,16,261,4,0,
10068,16,263,4,0,
10068,16,264,3,0,
10068,16,269,4,0,
10068,16,270,1,35,1
10068,16,270,3,0,
10068,16,271,3,0,
10068,16,277,3,0,
10068,16,278,3,0,
10068,16,280,4,0,
10068,16,282,3,0,
10068,16,285,3,0,
10068,16,289,3,0,
10068,16,290,4,0,
10068,16,304,3,0,
10068,16,317,4,0,
10068,16,324,3,0,
10068,16,332,4,0,
10068,16,339,4,0,
10068,16,347,4,0,
10068,16,348,1,1,3
10068,16,351,3,0,
10068,16,364,1,40,1
10068,16,370,1,1,2
10068,16,370,1,53,1
10068,16,374,4,0,
10068,16,398,4,0,
10068,16,400,1,1,4
10068,16,404,4,0,
10068,16,409,3,0,
10068,16,411,4,0,
10068,16,416,4,0,
10068,16,427,1,31,1
10068,16,428,3,0,
10068,16,433,4,0,
10068,16,444,4,0,
10068,16,447,4,0,
10068,16,451,4,0,
10068,16,469,1,23,1
10068,16,472,3,0,
10068,16,473,4,0,
10068,16,478,3,0,
10068,16,490,4,0,
10068,16,496,4,0,
10068,16,497,4,0,
10068,16,500,1,1,1
10068,16,500,1,58,1
10068,16,501,1,11,1
10068,16,505,1,19,1
10068,16,514,4,0,
10068,16,523,4,0,
10068,16,530,3,0,
10068,16,590,4,0,
10068,16,605,4,0,
10068,16,612,4,0,
10068,17,14,1,26,
10068,17,14,4,0,
10068,17,43,1,1,6
10068,17,63,4,0,
10068,17,85,4,0,
10068,17,86,4,0,
10068,17,89,4,0,
10068,17,92,4,0,
10068,17,93,1,1,7
10068,17,93,1,4,
10068,17,94,4,0,
10068,17,100,1,1,9
10068,17,100,1,9,
10068,17,104,1,1,8
10068,17,104,1,6,
10068,17,104,4,0,
10068,17,113,4,0,
10068,17,115,4,0,
10068,17,138,4,0,
10068,17,156,4,0,
10068,17,157,4,0,
10068,17,163,1,0,
10068,17,163,1,1,1
10068,17,164,4,0,
10068,17,168,4,0,
10068,17,182,1,49,
10068,17,182,4,0,
10068,17,206,1,44,
10068,17,206,4,0,
10068,17,207,4,0,
10068,17,210,1,14,
10068,17,213,4,0,
10068,17,214,4,0,
10068,17,216,4,0,
10068,17,218,4,0,
10068,17,219,4,0,
10068,17,237,4,0,
10068,17,240,4,0,
10068,17,241,4,0,
10068,17,244,4,0,
10068,17,247,4,0,
10068,17,259,4,0,
10068,17,261,4,0,
10068,17,263,4,0,
10068,17,269,4,0,
10068,17,270,1,35,
10068,17,280,4,0,
10068,17,317,4,0,
10068,17,332,1,17,
10068,17,332,4,0,
10068,17,339,4,0,
10068,17,347,4,0,
10068,17,348,1,1,4
10068,17,364,1,40,
10068,17,370,1,1,3
10068,17,370,1,53,
10068,17,374,4,0,
10068,17,398,4,0,
10068,17,400,1,1,5
10068,17,404,4,0,
10068,17,411,4,0,
10068,17,416,4,0,
10068,17,427,1,31,
10068,17,433,4,0,
10068,17,444,4,0,
10068,17,447,4,0,
10068,17,451,4,0,
10068,17,469,1,23,
10068,17,473,4,0,
10068,17,490,4,0,
10068,17,496,4,0,
10068,17,497,4,0,
10068,17,500,1,1,2
10068,17,500,1,58,
10068,17,501,1,11,
10068,17,505,1,19,
10068,17,523,4,0,
10068,17,526,4,0,
10068,17,590,4,0,
10068,17,605,4,0,
10068,18,14,1,26,
10068,18,14,4,0,
10068,18,43,1,1,6
10068,18,63,4,0,
10068,18,85,4,0,
10068,18,86,4,0,
10068,18,89,4,0,
10068,18,92,4,0,
10068,18,93,1,1,7
10068,18,93,1,4,
10068,18,94,4,0,
10068,18,100,1,1,9
10068,18,100,1,9,
10068,18,104,1,1,8
10068,18,104,1,6,
10068,18,104,4,0,
10068,18,113,4,0,
10068,18,115,4,0,
10068,18,138,4,0,
10068,18,156,4,0,
10068,18,157,4,0,
10068,18,163,1,0,
10068,18,163,1,1,1
10068,18,164,4,0,
10068,18,168,4,0,
10068,18,182,1,49,
10068,18,182,4,0,
10068,18,206,1,44,
10068,18,206,4,0,
10068,18,207,4,0,
10068,18,210,1,14,
10068,18,213,4,0,
10068,18,214,4,0,
10068,18,216,4,0,
10068,18,218,4,0,
10068,18,219,4,0,
10068,18,237,4,0,
10068,18,240,4,0,
10068,18,241,4,0,
10068,18,244,4,0,
10068,18,247,4,0,
10068,18,259,4,0,
10068,18,261,4,0,
10068,18,263,4,0,
10068,18,269,4,0,
10068,18,270,1,35,
10068,18,280,4,0,
10068,18,317,4,0,
10068,18,332,1,17,
10068,18,332,4,0,
10068,18,339,4,0,
10068,18,347,4,0,
10068,18,348,1,1,4
10068,18,364,1,40,
10068,18,370,1,1,3
10068,18,370,1,53,
10068,18,374,4,0,
10068,18,398,4,0,
10068,18,400,1,1,5
10068,18,404,4,0,
10068,18,411,4,0,
10068,18,416,4,0,
10068,18,427,1,31,
10068,18,433,4,0,
10068,18,444,4,0,
10068,18,447,4,0,
10068,18,451,4,0,
10068,18,469,1,23,
10068,18,473,4,0,
10068,18,490,4,0,
10068,18,496,4,0,
10068,18,497,4,0,
10068,18,500,1,1,2
10068,18,500,1,58,
10068,18,501,1,11,
10068,18,505,1,19,
10068,18,523,4,0,
10068,18,526,4,0,
10068,18,590,4,0,
10068,18,605,4,0,
10069,16,1,1,1,4
10069,16,3,1,17,1
10069,16,7,3,0,
10069,16,8,3,0,
10069,16,9,3,0,
10069,16,36,1,33,1
10069,16,38,1,49,1
10069,16,45,1,1,5
10069,16,53,4,0,
10069,16,57,4,0,
10069,16,58,4,0,
10069,16,59,4,0,
10069,16,63,4,0,
10069,16,67,3,0,
10069,16,76,4,0,
10069,16,85,4,0,
10069,16,86,4,0,
10069,16,87,4,0,
10069,16,91,4,0,
10069,16,92,4,0,
10069,16,94,4,0,
10069,16,104,4,0,
10069,16,113,4,0,
10069,16,115,4,0,
10069,16,126,4,0,
10069,16,138,4,0,
10069,16,148,4,0,
10069,16,156,4,0,
10069,16,164,4,0,
10069,16,173,3,0,
10069,16,182,4,0,
10069,16,196,3,0,
10069,16,207,4,0,
10069,16,213,1,21,1
10069,16,213,4,0,
10069,16,214,4,0,
10069,16,215,3,0,
10069,16,216,4,0,
10069,16,218,4,0,
10069,16,219,4,0,
10069,16,220,3,0,
10069,16,231,3,0,
10069,16,237,4,0,
10069,16,240,4,0,
10069,16,241,4,0,
10069,16,244,4,0,
10069,16,247,4,0,
10069,16,253,3,0,
10069,16,263,4,0,
10069,16,264,3,0,
10069,16,270,1,1,6
10069,16,270,3,0,
10069,16,272,3,0,
10069,16,277,3,0,
10069,16,282,3,0,
10069,16,285,3,0,
10069,16,287,1,9,1
10069,16,289,3,0,
10069,16,290,1,25,1
10069,16,290,4,0,
10069,16,304,3,0,
10069,16,324,3,0,
10069,16,343,3,0,
10069,16,347,4,0,
10069,16,356,3,0,
10069,16,374,4,0,
10069,16,387,1,1,1
10069,16,387,1,53,1
10069,16,387,3,0,
10069,16,409,3,0,
10069,16,428,3,0,
10069,16,433,4,0,
10069,16,447,4,0,
10069,16,451,4,0,
10069,16,473,4,0,
10069,16,493,1,45,1
10069,16,494,1,29,1
10069,16,495,1,41,1
10069,16,495,3,0,
10069,16,496,4,0,
10069,16,497,4,0,
10069,16,505,1,37,1
10069,16,510,4,0,
10069,16,514,4,0,
10069,16,528,4,0,
10069,16,574,1,13,1
10069,16,581,1,1,2
10069,16,589,1,1,3
10069,16,590,4,0,
10069,16,605,4,0,
10069,16,608,1,5,1
10069,16,612,4,0,
10069,17,1,1,1,5
10069,17,3,1,17,
10069,17,36,1,33,
10069,17,38,1,49,
10069,17,45,1,1,6
10069,17,53,4,0,
10069,17,57,4,0,
10069,17,58,4,0,
10069,17,59,4,0,
10069,17,63,4,0,
10069,17,76,4,0,
10069,17,85,4,0,
10069,17,86,4,0,
10069,17,87,4,0,
10069,17,92,4,0,
10069,17,94,4,0,
10069,17,104,4,0,
10069,17,113,4,0,
10069,17,115,4,0,
10069,17,126,4,0,
10069,17,138,4,0,
10069,17,156,4,0,
10069,17,164,4,0,
10069,17,182,4,0,
10069,17,207,4,0,
10069,17,213,1,21,
10069,17,213,4,0,
10069,17,214,4,0,
10069,17,216,4,0,
10069,17,218,4,0,
10069,17,219,4,0,
10069,17,237,4,0,
10069,17,240,4,0,
10069,17,241,4,0,
10069,17,244,4,0,
10069,17,247,4,0,
10069,17,263,4,0,
10069,17,270,1,1,7
10069,17,287,1,9,
10069,17,290,1,25,
10069,17,304,1,1,2
10069,17,304,1,53,
10069,17,347,4,0,
10069,17,374,4,0,
10069,17,387,1,1,1
10069,17,387,1,57,
10069,17,433,4,0,
10069,17,447,4,0,
10069,17,451,4,0,
10069,17,473,4,0,
10069,17,493,1,45,
10069,17,494,1,29,
10069,17,495,1,41,
10069,17,496,4,0,
10069,17,497,4,0,
10069,17,505,1,37,
10069,17,526,4,0,
10069,17,528,4,0,
10069,17,574,1,13,
10069,17,581,1,1,3
10069,17,589,1,1,4
10069,17,590,4,0,
10069,17,605,4,0,
10069,17,608,1,5,
10069,18,1,1,1,5
10069,18,3,1,17,
10069,18,36,1,33,
10069,18,38,1,49,
10069,18,45,1,1,6
10069,18,53,4,0,
10069,18,57,4,0,
10069,18,58,4,0,
10069,18,59,4,0,
10069,18,63,4,0,
10069,18,76,4,0,
10069,18,85,4,0,
10069,18,86,4,0,
10069,18,87,4,0,
10069,18,92,4,0,
10069,18,94,4,0,
10069,18,104,4,0,
10069,18,113,4,0,
10069,18,115,4,0,
10069,18,126,4,0,
10069,18,138,4,0,
10069,18,156,4,0,
10069,18,164,4,0,
10069,18,182,4,0,
10069,18,207,4,0,
10069,18,213,1,21,
10069,18,213,4,0,
10069,18,214,4,0,
10069,18,216,4,0,
10069,18,218,4,0,
10069,18,219,4,0,
10069,18,237,4,0,
10069,18,240,4,0,
10069,18,241,4,0,
10069,18,244,4,0,
10069,18,247,4,0,
10069,18,263,4,0,
10069,18,270,1,1,7
10069,18,287,1,9,
10069,18,290,1,25,
10069,18,304,1,1,2
10069,18,304,1,53,
10069,18,347,4,0,
10069,18,374,4,0,
10069,18,387,1,1,1
10069,18,387,1,57,
10069,18,433,4,0,
10069,18,447,4,0,
10069,18,451,4,0,
10069,18,473,4,0,
10069,18,493,1,45,
10069,18,494,1,29,
10069,18,495,1,41,
10069,18,496,4,0,
10069,18,497,4,0,
10069,18,505,1,37,
10069,18,526,4,0,
10069,18,528,4,0,
10069,18,574,1,13,
10069,18,581,1,1,3
10069,18,589,1,1,4
10069,18,590,4,0,
10069,18,605,4,0,
10069,18,608,1,5,
10070,16,43,1,1,3
10070,16,44,1,1,4
10070,16,46,4,0,
10070,16,57,4,0,
10070,16,58,4,0,
10070,16,59,4,0,
10070,16,63,4,0,
10070,16,70,4,0,
10070,16,89,4,0,
10070,16,92,4,0,
10070,16,97,1,45,1
10070,16,99,1,1,5
10070,16,99,1,4,1
10070,16,103,1,18,1
10070,16,104,4,0,
10070,16,116,1,1,6
10070,16,116,1,8,1
10070,16,127,4,0,
10070,16,130,1,51,1
10070,16,156,4,0,
10070,16,162,3,0,
10070,16,163,1,30,1
10070,16,164,4,0,
10070,16,168,4,0,
10070,16,173,3,0,
10070,16,180,3,0,
10070,16,182,4,0,
10070,16,184,1,29,1
10070,16,196,3,0,
10070,16,207,1,22,1
10070,16,207,4,0,
10070,16,213,4,0,
10070,16,214,4,0,
10070,16,216,4,0,
10070,16,218,4,0,
10070,16,237,4,0,
10070,16,240,4,0,
10070,16,242,1,40,1
10070,16,249,4,0,
10070,16,253,3,0,
10070,16,258,4,0,
10070,16,259,4,0,
10070,16,263,4,0,
10070,16,269,1,56,1
10070,16,269,4,0,
10070,16,290,4,0,
10070,16,291,4,0,
10070,16,305,1,34,1
10070,16,317,4,0,
10070,16,340,3,0,
10070,16,352,3,0,
10070,16,364,1,1,2
10070,16,371,4,0,
10070,16,372,1,15,1
10070,16,398,4,0,
10070,16,399,4,0,
10070,16,400,1,1,1
10070,16,400,1,62,1
10070,16,416,4,0,
10070,16,423,1,25,1
10070,16,428,3,0,
10070,16,453,1,11,1
10070,16,496,4,0,
10070,16,503,4,0,
10070,16,514,4,0,
10070,16,523,4,0,
10070,16,555,4,0,
10070,16,590,4,0,
10070,17,43,1,1,4
10070,17,44,1,1,5
10070,17,46,4,0,
10070,17,57,4,0,
10070,17,58,4,0,
10070,17,59,4,0,
10070,17,63,4,0,
10070,17,89,4,0,
10070,17,92,4,0,
10070,17,97,1,45,
10070,17,99,1,1,6
10070,17,99,1,4,
10070,17,103,1,18,
10070,17,104,4,0,
10070,17,116,1,1,7
10070,17,116,1,8,
10070,17,127,4,0,
10070,17,130,1,51,
10070,17,156,4,0,
10070,17,163,1,0,
10070,17,163,1,1,1
10070,17,164,4,0,
10070,17,168,4,0,
10070,17,182,4,0,
10070,17,184,1,29,
10070,17,207,1,22,
10070,17,207,4,0,
10070,17,213,4,0,
10070,17,214,4,0,
10070,17,216,4,0,
10070,17,218,4,0,
10070,17,237,4,0,
10070,17,240,4,0,
10070,17,242,1,40,
10070,17,258,4,0,
10070,17,259,4,0,
10070,17,263,4,0,
10070,17,269,1,56,
10070,17,269,4,0,
10070,17,305,1,34,
10070,17,317,4,0,
10070,17,364,1,1,3
10070,17,371,4,0,
10070,17,372,1,15,
10070,17,398,4,0,
10070,17,399,4,0,
10070,17,400,1,1,2
10070,17,400,1,62,
10070,17,416,4,0,
10070,17,423,1,25,
10070,17,453,1,11,
10070,17,496,4,0,
10070,17,503,4,0,
10070,17,523,4,0,
10070,17,555,4,0,
10070,17,590,4,0,
10070,18,43,1,1,4
10070,18,44,1,1,5
10070,18,46,4,0,
10070,18,57,4,0,
10070,18,58,4,0,
10070,18,59,4,0,
10070,18,63,4,0,
10070,18,89,4,0,
10070,18,92,4,0,
10070,18,97,1,45,
10070,18,99,1,1,6
10070,18,99,1,4,
10070,18,103,1,18,
10070,18,104,4,0,
10070,18,116,1,1,7
10070,18,116,1,8,
10070,18,127,4,0,
10070,18,130,1,51,
10070,18,156,4,0,
10070,18,163,1,0,
10070,18,163,1,1,1
10070,18,164,4,0,
10070,18,168,4,0,
10070,18,182,4,0,
10070,18,184,1,29,
10070,18,207,1,22,
10070,18,207,4,0,
10070,18,213,4,0,
10070,18,214,4,0,
10070,18,216,4,0,
10070,18,218,4,0,
10070,18,237,4,0,
10070,18,240,4,0,
10070,18,242,1,40,
10070,18,258,4,0,
10070,18,259,4,0,
10070,18,263,4,0,
10070,18,269,1,56,
10070,18,269,4,0,
10070,18,305,1,34,
10070,18,317,4,0,
10070,18,364,1,1,3
10070,18,371,4,0,
10070,18,372,1,15,
10070,18,398,4,0,
10070,18,399,4,0,
10070,18,400,1,1,2
10070,18,400,1,62,
10070,18,416,4,0,
10070,18,423,1,25,
10070,18,453,1,11,
10070,18,496,4,0,
10070,18,503,4,0,
10070,18,523,4,0,
10070,18,555,4,0,
10070,18,590,4,0,
10071,16,8,3,0,
10071,16,29,1,23,1
10071,16,33,1,1,4
10071,16,45,1,1,5
10071,16,45,1,5,1
10071,16,50,1,19,1
10071,16,53,4,0,
10071,16,55,1,9,1
10071,16,57,4,0,
10071,16,58,4,0,
10071,16,59,4,0,
10071,16,63,4,0,
10071,16,70,4,0,
10071,16,86,4,0,
10071,16,89,4,0,
10071,16,91,4,0,
10071,16,92,4,0,
10071,16,93,1,14,1
10071,16,94,1,49,1
10071,16,94,4,0,
10071,16,104,4,0,
10071,16,110,1,37,1
10071,16,113,4,0,
10071,16,126,4,0,
10071,16,133,1,43,1
10071,16,138,4,0,
10071,16,148,4,0,
10071,16,156,4,0,
10071,16,164,4,0,
10071,16,173,3,0,
10071,16,174,1,1,2
10071,16,182,4,0,
10071,16,196,3,0,
10071,16,207,4,0,
10071,16,213,4,0,
10071,16,214,4,0,
10071,16,216,4,0,
10071,16,218,4,0,
10071,16,219,4,0,
10071,16,231,3,0,
10071,16,237,4,0,
10071,16,240,1,55,1
10071,16,240,4,0,
10071,16,241,4,0,
10071,16,244,1,62,1
10071,16,244,4,0,
10071,16,247,4,0,
10071,16,249,4,0,
10071,16,258,4,0,
10071,16,263,4,0,
10071,16,264,3,0,
10071,16,271,3,0,
10071,16,277,3,0,
10071,16,278,3,0,
10071,16,280,4,0,
10071,16,281,1,1,3
10071,16,285,3,0,
10071,16,290,4,0,
10071,16,291,4,0,
10071,16,303,1,36,1
10071,16,324,3,0,
10071,16,332,4,0,
10071,16,334,3,0,
10071,16,335,3,0,
10071,16,347,4,0,
10071,16,352,1,28,1
10071,16,352,3,0,
10071,16,374,4,0,
10071,16,401,3,0,
10071,16,409,3,0,
10071,16,411,4,0,
10071,16,416,4,0,
10071,16,428,1,32,1
10071,16,428,3,0,
10071,16,433,4,0,
10071,16,447,4,0,
10071,16,472,3,0,
10071,16,473,4,0,
10071,16,492,3,0,
10071,16,495,3,0,
10071,16,496,4,0,
10071,16,497,4,0,
10071,16,503,4,0,
10071,16,505,1,1,1
10071,16,505,1,68,1
10071,16,510,4,0,
10071,16,523,4,0,
10071,16,590,4,0,
10071,17,29,1,23,
10071,17,33,1,1,5
10071,17,45,1,1,6
10071,17,45,1,5,
10071,17,50,1,19,
10071,17,53,4,0,
10071,17,55,1,9,
10071,17,57,4,0,
10071,17,58,4,0,
10071,17,59,4,0,
10071,17,63,4,0,
10071,17,86,4,0,
10071,17,89,4,0,
10071,17,92,4,0,
10071,17,93,1,14,
10071,17,94,1,49,
10071,17,94,4,0,
10071,17,104,4,0,
10071,17,110,1,0,
10071,17,110,1,1,1
10071,17,113,4,0,
10071,17,126,4,0,
10071,17,133,1,43,
10071,17,138,4,0,
10071,17,156,4,0,
10071,17,164,4,0,
10071,17,174,1,1,3
10071,17,182,4,0,
10071,17,207,4,0,
10071,17,213,4,0,
10071,17,214,4,0,
10071,17,216,4,0,
10071,17,218,4,0,
10071,17,219,4,0,
10071,17,237,4,0,
10071,17,240,1,55,
10071,17,240,4,0,
10071,17,241,4,0,
10071,17,244,1,62,
10071,17,244,4,0,
10071,17,247,4,0,
10071,17,258,4,0,
10071,17,263,4,0,
10071,17,280,4,0,
10071,17,281,1,1,4
10071,17,303,1,36,
10071,17,332,4,0,
10071,17,347,4,0,
10071,17,352,1,28,
10071,17,374,4,0,
10071,17,411,4,0,
10071,17,416,4,0,
10071,17,428,1,32,
10071,17,433,4,0,
10071,17,447,4,0,
10071,17,473,4,0,
10071,17,496,4,0,
10071,17,497,4,0,
10071,17,503,4,0,
10071,17,505,1,1,2
10071,17,505,1,68,
10071,17,523,4,0,
10071,17,590,4,0,
10071,18,29,1,23,
10071,18,33,1,1,5
10071,18,45,1,1,6
10071,18,45,1,5,
10071,18,50,1,19,
10071,18,53,4,0,
10071,18,55,1,9,
10071,18,57,4,0,
10071,18,58,4,0,
10071,18,59,4,0,
10071,18,63,4,0,
10071,18,86,4,0,
10071,18,89,4,0,
10071,18,92,4,0,
10071,18,93,1,14,
10071,18,94,1,49,
10071,18,94,4,0,
10071,18,104,4,0,
10071,18,110,1,0,
10071,18,110,1,1,1
10071,18,113,4,0,
10071,18,126,4,0,
10071,18,133,1,43,
10071,18,138,4,0,
10071,18,156,4,0,
10071,18,164,4,0,
10071,18,174,1,1,3
10071,18,182,4,0,
10071,18,207,4,0,
10071,18,213,4,0,
10071,18,214,4,0,
10071,18,216,4,0,
10071,18,218,4,0,
10071,18,219,4,0,
10071,18,237,4,0,
10071,18,240,1,55,
10071,18,240,4,0,
10071,18,241,4,0,
10071,18,244,1,62,
10071,18,244,4,0,
10071,18,247,4,0,
10071,18,258,4,0,
10071,18,263,4,0,
10071,18,280,4,0,
10071,18,281,1,1,4
10071,18,303,1,36,
10071,18,332,4,0,
10071,18,347,4,0,
10071,18,352,1,28,
10071,18,374,4,0,
10071,18,411,4,0,
10071,18,416,4,0,
10071,18,428,1,32,
10071,18,433,4,0,
10071,18,447,4,0,
10071,18,473,4,0,
10071,18,496,4,0,
10071,18,497,4,0,
10071,18,503,4,0,
10071,18,505,1,1,2
10071,18,505,1,68,
10071,18,523,4,0,
10071,18,590,4,0,
10072,16,15,4,0,
10072,16,20,1,1,7
10072,16,20,3,0,
10072,16,21,1,28,1
10072,16,33,1,1,5
10072,16,38,1,49,1
10072,16,46,4,0,
10072,16,63,4,0,
10072,16,70,4,0,
10072,16,88,1,7,1
10072,16,89,4,0,
10072,16,91,1,43,1
10072,16,91,4,0,
10072,16,92,4,0,
10072,16,99,1,13,1
10072,16,103,1,31,1
10072,16,104,4,0,
10072,16,106,1,1,6
10072,16,153,4,0,
10072,16,156,4,0,
10072,16,157,1,34,1
10072,16,157,4,0,
10072,16,164,4,0,
10072,16,173,3,0,
10072,16,174,1,4,1
10072,16,182,4,0,
10072,16,201,1,52,1
10072,16,201,4,0,
10072,16,207,4,0,
10072,16,213,4,0,
10072,16,214,4,0,
10072,16,216,4,0,
10072,16,218,4,0,
10072,16,225,1,25,1
10072,16,231,1,40,1
10072,16,231,3,0,
10072,16,237,4,0,
10072,16,241,4,0,
10072,16,242,1,37,1
10072,16,244,4,0,
10072,16,249,4,0,
10072,16,259,4,0,
10072,16,263,4,0,
10072,16,267,4,0,
10072,16,269,4,0,
10072,16,290,4,0,
10072,16,300,1,1,4
10072,16,317,1,10,1
10072,16,317,4,0,
10072,16,335,3,0,
10072,16,360,1,20,1
10072,16,360,4,0,
10072,16,371,4,0,
10072,16,393,3,0,
10072,16,397,4,0,
10072,16,399,4,0,
10072,16,401,3,0,
10072,16,406,3,0,
10072,16,414,3,0,
10072,16,416,4,0,
10072,16,422,1,1,1
10072,16,423,1,1,2
10072,16,424,1,1,3
10072,16,430,4,0,
10072,16,442,3,0,
10072,16,444,1,46,1
10072,16,444,4,0,
10072,16,446,1,16,1
10072,16,446,3,0,
10072,16,475,1,19,1
10072,16,479,1,22,1
10072,16,479,4,0,
10072,16,496,4,0,
10072,16,523,4,0,
10072,16,525,4,0,
10072,16,590,4,0,
10072,17,20,1,1,7
10072,17,21,1,28,
10072,17,33,1,1,5
10072,17,38,1,49,
10072,17,46,4,0,
10072,17,63,4,0,
10072,17,88,1,7,
10072,17,89,4,0,
10072,17,91,1,43,
10072,17,92,4,0,
10072,17,99,1,13,
10072,17,103,1,31,
10072,17,104,4,0,
10072,17,106,1,1,6
10072,17,153,4,0,
10072,17,156,4,0,
10072,17,157,1,34,
10072,17,157,4,0,
10072,17,164,4,0,
10072,17,174,1,4,
10072,17,182,4,0,
10072,17,201,1,52,
10072,17,201,4,0,
10072,17,207,4,0,
10072,17,213,4,0,
10072,17,214,4,0,
10072,17,216,4,0,
10072,17,218,4,0,
10072,17,225,1,25,
10072,17,231,1,40,
10072,17,237,4,0,
10072,17,241,4,0,
10072,17,242,1,37,
10072,17,244,4,0,
10072,17,259,4,0,
10072,17,263,4,0,
10072,17,267,4,0,
10072,17,269,4,0,
10072,17,300,1,1,4
10072,17,317,1,10,
10072,17,317,4,0,
10072,17,360,1,20,
10072,17,360,4,0,
10072,17,371,4,0,
10072,17,397,4,0,
10072,17,399,4,0,
10072,17,416,4,0,
10072,17,422,1,1,1
10072,17,423,1,1,2
10072,17,424,1,1,3
10072,17,430,4,0,
10072,17,444,1,46,
10072,17,444,4,0,
10072,17,446,1,16,
10072,17,475,1,19,
10072,17,479,1,22,
10072,17,479,4,0,
10072,17,496,4,0,
10072,17,523,4,0,
10072,17,525,4,0,
10072,17,590,4,0,
10072,17,693,4,0,
10072,18,20,1,1,7
10072,18,21,1,28,
10072,18,33,1,1,5
10072,18,38,1,49,
10072,18,46,4,0,
10072,18,63,4,0,
10072,18,88,1,7,
10072,18,89,4,0,
10072,18,91,1,43,
10072,18,92,4,0,
10072,18,99,1,13,
10072,18,103,1,31,
10072,18,104,4,0,
10072,18,106,1,1,6
10072,18,153,4,0,
10072,18,156,4,0,
10072,18,157,1,34,
10072,18,157,4,0,
10072,18,164,4,0,
10072,18,174,1,4,
10072,18,182,4,0,
10072,18,201,1,52,
10072,18,201,4,0,
10072,18,207,4,0,
10072,18,213,4,0,
10072,18,214,4,0,
10072,18,216,4,0,
10072,18,218,4,0,
10072,18,225,1,25,
10072,18,231,1,40,
10072,18,237,4,0,
10072,18,241,4,0,
10072,18,242,1,37,
10072,18,244,4,0,
10072,18,259,4,0,
10072,18,263,4,0,
10072,18,267,4,0,
10072,18,269,4,0,
10072,18,300,1,1,4
10072,18,317,1,10,
10072,18,317,4,0,
10072,18,360,1,20,
10072,18,360,4,0,
10072,18,371,4,0,
10072,18,397,4,0,
10072,18,399,4,0,
10072,18,416,4,0,
10072,18,422,1,1,1
10072,18,423,1,1,2
10072,18,424,1,1,3
10072,18,430,4,0,
10072,18,444,1,46,
10072,18,444,4,0,
10072,18,446,1,16,
10072,18,475,1,19,
10072,18,479,1,22,
10072,18,479,4,0,
10072,18,496,4,0,
10072,18,523,4,0,
10072,18,525,4,0,
10072,18,590,4,0,
10072,18,693,4,0,
10073,16,16,1,1,4
10073,16,16,1,9,1
10073,16,17,1,38,1
10073,16,18,1,17,1
10073,16,19,4,0,
10073,16,28,1,1,3
10073,16,28,1,5,1
10073,16,33,1,1,2
10073,16,63,4,0,
10073,16,92,4,0,
10073,16,97,1,32,1
10073,16,98,1,1,5
10073,16,98,1,13,1
10073,16,104,4,0,
10073,16,119,1,56,1
10073,16,143,3,0,
10073,16,156,4,0,
10073,16,164,4,0,
10073,16,168,4,0,
10073,16,173,3,0,
10073,16,182,4,0,
10073,16,207,4,0,
10073,16,211,4,0,
10073,16,213,4,0,
10073,16,214,4,0,
10073,16,216,4,0,
10073,16,218,4,0,
10073,16,237,4,0,
10073,16,239,1,22,1
10073,16,240,4,0,
10073,16,241,4,0,
10073,16,253,3,0,
10073,16,257,3,0,
10073,16,263,4,0,
10073,16,290,4,0,
10073,16,297,1,27,1
10073,16,332,4,0,
10073,16,355,1,44,1
10073,16,355,4,0,
10073,16,366,1,50,1
10073,16,366,3,0,
10073,16,369,4,0,
10073,16,403,1,62,1
10073,16,416,4,0,
10073,16,496,4,0,
10073,16,542,1,1,1
10073,16,542,1,68,1
10073,16,590,4,0,
10073,17,16,1,1,4
10073,17,16,1,9,
10073,17,17,1,38,
10073,17,18,1,17,
10073,17,19,4,0,
10073,17,28,1,1,3
10073,17,28,1,5,
10073,17,33,1,1,2
10073,17,63,4,0,
10073,17,92,4,0,
10073,17,97,1,32,
10073,17,98,1,1,5
10073,17,98,1,13,
10073,17,104,4,0,
10073,17,119,1,56,
10073,17,156,4,0,
10073,17,164,4,0,
10073,17,168,4,0,
10073,17,182,4,0,
10073,17,207,4,0,
10073,17,211,4,0,
10073,17,213,4,0,
10073,17,214,4,0,
10073,17,216,4,0,
10073,17,218,4,0,
10073,17,237,4,0,
10073,17,239,1,22,
10073,17,240,4,0,
10073,17,241,4,0,
10073,17,263,4,0,
10073,17,297,1,27,
10073,17,332,4,0,
10073,17,355,1,44,
10073,17,355,4,0,
10073,17,366,1,50,
10073,17,369,4,0,
10073,17,403,1,62,
10073,17,416,4,0,
10073,17,496,4,0,
10073,17,526,4,0,
10073,17,542,1,1,1
10073,17,542,1,68,
10073,17,590,4,0,
10073,18,16,1,1,4
10073,18,16,1,9,
10073,18,17,1,38,
10073,18,18,1,17,
10073,18,19,4,0,
10073,18,28,1,1,3
10073,18,28,1,5,
10073,18,33,1,1,2
10073,18,63,4,0,
10073,18,92,4,0,
10073,18,97,1,32,
10073,18,98,1,1,5
10073,18,98,1,13,
10073,18,104,4,0,
10073,18,119,1,56,
10073,18,156,4,0,
10073,18,164,4,0,
10073,18,168,4,0,
10073,18,182,4,0,
10073,18,207,4,0,
10073,18,211,4,0,
10073,18,213,4,0,
10073,18,214,4,0,
10073,18,216,4,0,
10073,18,218,4,0,
10073,18,237,4,0,
10073,18,239,1,22,
10073,18,240,4,0,
10073,18,241,4,0,
10073,18,263,4,0,
10073,18,297,1,27,
10073,18,332,4,0,
10073,18,355,1,44,
10073,18,355,4,0,
10073,18,366,1,50,
10073,18,369,4,0,
10073,18,403,1,62,
10073,18,416,4,0,
10073,18,496,4,0,
10073,18,526,4,0,
10073,18,542,1,1,1
10073,18,542,1,68,
10073,18,590,4,0,
10074,16,29,1,28,1
10074,16,43,1,1,3
10074,16,44,1,19,1
10074,16,58,4,0,
10074,16,59,1,48,1
10074,16,59,4,0,
10074,16,63,4,0,
10074,16,89,4,0,
10074,16,92,4,0,
10074,16,104,1,1,4
10074,16,104,1,5,1
10074,16,104,4,0,
10074,16,113,4,0,
10074,16,148,4,0,
10074,16,153,4,0,
10074,16,156,4,0,
10074,16,162,3,0,
10074,16,164,4,0,
10074,16,173,3,0,
10074,16,180,3,0,
10074,16,181,1,1,2
10074,16,182,1,32,1
10074,16,182,4,0,
10074,16,196,1,14,1
10074,16,196,3,0,
10074,16,207,4,0,
10074,16,213,4,0,
10074,16,214,4,0,
10074,16,216,4,0,
10074,16,218,4,0,
10074,16,219,4,0,
10074,16,237,4,0,
10074,16,240,4,0,
10074,16,242,1,41,1
10074,16,247,4,0,
10074,16,258,1,54,1
10074,16,258,4,0,
10074,16,259,4,0,
10074,16,263,4,0,
10074,16,269,4,0,
10074,16,290,4,0,
10074,16,324,3,0,
10074,16,329,1,1,1
10074,16,329,1,61,1
10074,16,335,3,0,
10074,16,352,3,0,
10074,16,360,4,0,
10074,16,371,4,0,
10074,16,399,4,0,
10074,16,416,4,0,
10074,16,420,1,1,5
10074,16,420,1,10,1
10074,16,423,1,23,1
10074,16,442,3,0,
10074,16,496,4,0,
10074,16,523,4,0,
10074,16,524,1,37,1
10074,16,524,4,0,
10074,16,573,1,42,1
10074,16,590,4,0,
10074,17,29,1,28,
10074,17,43,1,1,4
10074,17,44,1,19,
10074,17,58,4,0,
10074,17,59,1,48,
10074,17,59,4,0,
10074,17,63,4,0,
10074,17,89,4,0,
10074,17,92,4,0,
10074,17,104,1,1,5
10074,17,104,1,5,
10074,17,104,4,0,
10074,17,113,4,0,
10074,17,153,4,0,
10074,17,156,4,0,
10074,17,164,4,0,
10074,17,181,1,1,3
10074,17,182,1,32,
10074,17,182,4,0,
10074,17,196,1,14,
10074,17,207,4,0,
10074,17,213,4,0,
10074,17,214,4,0,
10074,17,216,4,0,
10074,17,218,4,0,
10074,17,219,4,0,
10074,17,237,4,0,
10074,17,240,4,0,
10074,17,242,1,41,
10074,17,247,4,0,
10074,17,258,1,54,
10074,17,258,4,0,
10074,17,259,4,0,
10074,17,263,4,0,
10074,17,269,4,0,
10074,17,329,1,1,2
10074,17,329,1,61,
10074,17,360,4,0,
10074,17,371,4,0,
10074,17,399,4,0,
10074,17,416,4,0,
10074,17,420,1,1,6
10074,17,420,1,10,
10074,17,423,1,23,
10074,17,496,4,0,
10074,17,523,4,0,
10074,17,524,1,37,
10074,17,524,4,0,
10074,17,573,1,0,
10074,17,573,1,1,1
10074,17,590,4,0,
10074,18,29,1,28,
10074,18,43,1,1,4
10074,18,44,1,19,
10074,18,58,4,0,
10074,18,59,1,48,
10074,18,59,4,0,
10074,18,63,4,0,
10074,18,89,4,0,
10074,18,92,4,0,
10074,18,104,1,1,5
10074,18,104,1,5,
10074,18,104,4,0,
10074,18,113,4,0,
10074,18,153,4,0,
10074,18,156,4,0,
10074,18,164,4,0,
10074,18,181,1,1,3
10074,18,182,1,32,
10074,18,182,4,0,
10074,18,196,1,14,
10074,18,207,4,0,
10074,18,213,4,0,
10074,18,214,4,0,
10074,18,216,4,0,
10074,18,218,4,0,
10074,18,219,4,0,
10074,18,237,4,0,
10074,18,240,4,0,
10074,18,242,1,41,
10074,18,247,4,0,
10074,18,258,1,54,
10074,18,258,4,0,
10074,18,259,4,0,
10074,18,263,4,0,
10074,18,269,4,0,
10074,18,329,1,1,2
10074,18,329,1,61,
10074,18,360,4,0,
10074,18,371,4,0,
10074,18,399,4,0,
10074,18,416,4,0,
10074,18,420,1,1,6
10074,18,420,1,10,
10074,18,423,1,23,
10074,18,496,4,0,
10074,18,523,4,0,
10074,18,524,1,37,
10074,18,524,4,0,
10074,18,573,1,0,
10074,18,573,1,1,1
10074,18,590,4,0,
10075,16,33,1,1,1
10075,16,63,4,0,
10075,16,88,1,5,1
10075,16,92,4,0,
10075,16,94,4,0,
10075,16,104,4,0,
10075,16,106,1,1,2
10075,16,113,1,60,1
10075,16,113,4,0,
10075,16,115,1,18,1
10075,16,115,4,0,
10075,16,148,4,0,
10075,16,153,4,0,
10075,16,156,4,0,
10075,16,157,4,0,
10075,16,159,1,8,1
10075,16,164,4,0,
10075,16,173,3,0,
10075,16,175,1,35,1
10075,16,182,4,0,
10075,16,201,4,0,
10075,16,207,4,0,
10075,16,214,4,0,
10075,16,215,3,0,
10075,16,216,4,0,
10075,16,218,4,0,
10075,16,219,1,70,1
10075,16,219,4,0,
10075,16,237,4,0,
10075,16,241,4,0,
10075,16,244,4,0,
10075,16,246,1,31,1
10075,16,258,4,0,
10075,16,263,4,0,
10075,16,267,4,0,
10075,16,270,3,0,
10075,16,283,3,0,
10075,16,285,1,40,1
10075,16,285,3,0,
10075,16,290,4,0,
10075,16,317,4,0,
10075,16,334,3,0,
10075,16,343,3,0,
10075,16,347,4,0,
10075,16,356,3,0,
10075,16,360,4,0,
10075,16,387,3,0,
10075,16,393,3,0,
10075,16,397,4,0,
10075,16,414,3,0,
10075,16,416,4,0,
10075,16,433,1,46,1
10075,16,433,4,0,
10075,16,444,1,49,1
10075,16,444,4,0,
10075,16,446,1,21,1
10075,16,446,3,0,
10075,16,470,1,27,1
10075,16,472,3,0,
10075,16,473,4,0,
10075,16,479,1,12,1
10075,16,479,4,0,
10075,16,495,3,0,
10075,16,496,4,0,
10075,16,585,1,50,1
10075,16,590,4,0,
10075,16,591,1,50,2
10075,16,605,4,0,
10075,17,33,1,1,1
10075,17,63,4,0,
10075,17,88,1,1,3
10075,17,92,4,0,
10075,17,94,4,0,
10075,17,104,4,0,
10075,17,106,1,1,2
10075,17,113,1,60,
10075,17,113,4,0,
10075,17,115,1,12,
10075,17,115,4,0,
10075,17,153,4,0,
10075,17,156,4,0,
10075,17,157,4,0,
10075,17,159,1,5,
10075,17,164,4,0,
10075,17,175,1,31,
10075,17,182,4,0,
10075,17,201,4,0,
10075,17,207,4,0,
10075,17,214,4,0,
10075,17,216,4,0,
10075,17,218,4,0,
10075,17,219,1,70,
10075,17,219,4,0,
10075,17,237,4,0,
10075,17,241,4,0,
10075,17,244,4,0,
10075,17,246,1,27,
10075,17,258,4,0,
10075,17,263,4,0,
10075,17,267,4,0,
10075,17,285,1,35,
10075,17,317,4,0,
10075,17,347,4,0,
10075,17,360,4,0,
10075,17,397,4,0,
10075,17,408,1,40,
10075,17,416,4,0,
10075,17,433,1,46,
10075,17,433,4,0,
10075,17,444,1,49,
10075,17,444,4,0,
10075,17,446,1,18,
10075,17,470,1,21,
10075,17,473,4,0,
10075,17,479,1,8,
10075,17,479,4,0,
10075,17,496,4,0,
10075,17,585,1,50,1
10075,17,590,4,0,
10075,17,591,1,50,2
10075,17,605,4,0,
10075,18,33,1,1,1
10075,18,63,4,0,
10075,18,88,1,1,3
10075,18,92,4,0,
10075,18,94,4,0,
10075,18,104,4,0,
10075,18,106,1,1,2
10075,18,113,1,60,
10075,18,113,4,0,
10075,18,115,1,12,
10075,18,115,4,0,
10075,18,153,4,0,
10075,18,156,4,0,
10075,18,157,4,0,
10075,18,159,1,5,
10075,18,164,4,0,
10075,18,175,1,31,
10075,18,182,4,0,
10075,18,201,4,0,
10075,18,207,4,0,
10075,18,214,4,0,
10075,18,216,4,0,
10075,18,218,4,0,
10075,18,219,1,70,
10075,18,219,4,0,
10075,18,237,4,0,
10075,18,241,4,0,
10075,18,244,4,0,
10075,18,246,1,27,
10075,18,258,4,0,
10075,18,263,4,0,
10075,18,267,4,0,
10075,18,285,1,35,
10075,18,317,4,0,
10075,18,347,4,0,
10075,18,360,4,0,
10075,18,397,4,0,
10075,18,408,1,40,
10075,18,416,4,0,
10075,18,433,1,46,
10075,18,433,4,0,
10075,18,444,1,49,
10075,18,444,4,0,
10075,18,446,1,18,
10075,18,470,1,21,
10075,18,473,4,0,
10075,18,479,1,8,
10075,18,479,4,0,
10075,18,496,4,0,
10075,18,585,1,50,1
10075,18,590,4,0,
10075,18,591,1,50,2
10075,18,605,4,0,
10076,16,8,3,0,
10076,16,9,3,0,
10076,16,15,4,0,
10076,16,36,1,1,2
10076,16,63,1,60,1
10076,16,63,4,0,
10076,16,70,4,0,
10076,16,89,4,0,
10076,16,92,4,0,
10076,16,93,1,1,3
10076,16,93,1,20,1
10076,16,94,1,38,1
10076,16,94,4,0,
10076,16,97,1,41,1
10076,16,104,4,0,
10076,16,113,4,0,
10076,16,115,4,0,
10076,16,148,4,0,
10076,16,153,4,0,
10076,16,156,4,0,
10076,16,157,4,0,
10076,16,164,4,0,
10076,16,173,3,0,
10076,16,182,4,0,
10076,16,184,1,35,1
10076,16,188,4,0,
10076,16,196,3,0,
10076,16,201,4,0,
10076,16,207,4,0,
10076,16,214,4,0,
10076,16,216,4,0,
10076,16,218,4,0,
10076,16,228,1,23,1
10076,16,232,1,1,4
10076,16,232,1,20,2
10076,16,237,4,0,
10076,16,240,4,0,
10076,16,241,4,0,
10076,16,244,4,0,
10076,16,247,4,0,
10076,16,249,4,0,
10076,16,263,4,0,
10076,16,271,3,0,
10076,16,280,4,0,
10076,16,290,4,0,
10076,16,309,1,44,1
10076,16,317,4,0,
10076,16,324,3,0,
10076,16,332,4,0,
10076,16,334,1,52,1
10076,16,334,3,0,
10076,16,335,3,0,
10076,16,356,3,0,
10076,16,357,1,29,1
10076,16,359,1,45,1
10076,16,360,4,0,
10076,16,393,1,1,1
10076,16,393,3,0,
10076,16,397,4,0,
10076,16,416,4,0,
10076,16,418,1,26,1
10076,16,428,1,32,1
10076,16,428,3,0,
10076,16,430,4,0,
10076,16,442,3,0,
10076,16,446,3,0,
10076,16,447,4,0,
10076,16,468,4,0,
10076,16,473,4,0,
10076,16,496,4,0,
10076,16,523,4,0,
10076,16,590,4,0,
10076,16,612,4,0,
10076,17,36,1,1,5
10076,17,63,1,60,
10076,17,63,4,0,
10076,17,89,4,0,
10076,17,92,4,0,
10076,17,93,1,1,2
10076,17,94,1,38,
10076,17,94,4,0,
10076,17,97,1,41,
10076,17,104,4,0,
10076,17,113,4,0,
10076,17,115,4,0,
10076,17,153,4,0,
10076,17,156,4,0,
10076,17,157,4,0,
10076,17,164,4,0,
10076,17,182,4,0,
10076,17,184,1,35,
10076,17,188,4,0,
10076,17,201,4,0,
10076,17,207,4,0,
10076,17,214,4,0,
10076,17,216,4,0,
10076,17,218,4,0,
10076,17,228,1,23,
10076,17,232,1,1,3
10076,17,237,4,0,
10076,17,240,4,0,
10076,17,241,4,0,
10076,17,244,4,0,
10076,17,247,4,0,
10076,17,263,4,0,
10076,17,280,4,0,
10076,17,309,1,44,
10076,17,317,4,0,
10076,17,332,4,0,
10076,17,334,1,52,
10076,17,357,1,29,
10076,17,359,1,0,
10076,17,359,1,1,1
10076,17,360,4,0,
10076,17,393,1,1,4
10076,17,397,4,0,
10076,17,416,4,0,
10076,17,418,1,26,
10076,17,428,1,32,
10076,17,430,4,0,
10076,17,447,4,0,
10076,17,473,4,0,
10076,17,496,4,0,
10076,17,523,4,0,
10076,17,590,4,0,
10076,18,36,1,1,5
10076,18,63,1,60,
10076,18,63,4,0,
10076,18,89,4,0,
10076,18,92,4,0,
10076,18,93,1,1,2
10076,18,94,1,38,
10076,18,94,4,0,
10076,18,97,1,41,
10076,18,104,4,0,
10076,18,113,4,0,
10076,18,115,4,0,
10076,18,153,4,0,
10076,18,156,4,0,
10076,18,157,4,0,
10076,18,164,4,0,
10076,18,182,4,0,
10076,18,184,1,35,
10076,18,188,4,0,
10076,18,201,4,0,
10076,18,207,4,0,
10076,18,214,4,0,
10076,18,216,4,0,
10076,18,218,4,0,
10076,18,228,1,23,
10076,18,232,1,1,3
10076,18,237,4,0,
10076,18,240,4,0,
10076,18,241,4,0,
10076,18,244,4,0,
10076,18,247,4,0,
10076,18,263,4,0,
10076,18,280,4,0,
10076,18,309,1,44,
10076,18,317,4,0,
10076,18,332,4,0,
10076,18,334,1,52,
10076,18,357,1,29,
10076,18,359,1,0,
10076,18,359,1,1,1
10076,18,360,4,0,
10076,18,393,1,1,4
10076,18,397,4,0,
10076,18,416,4,0,
10076,18,418,1,26,
10076,18,428,1,32,
10076,18,430,4,0,
10076,18,447,4,0,
10076,18,473,4,0,
10076,18,496,4,0,
10076,18,523,4,0,
10076,18,590,4,0,
10077,16,34,1,20,1
10077,16,38,1,80,1
10077,16,46,4,0,
10077,16,56,1,75,1
10077,16,57,4,0,
10077,16,58,1,35,1
10077,16,58,4,0,
10077,16,59,4,0,
10077,16,63,4,0,
10077,16,70,4,0,
10077,16,85,4,0,
10077,16,86,4,0,
10077,16,87,4,0,
10077,16,89,4,0,
10077,16,92,4,0,
10077,16,104,4,0,
10077,16,127,4,0,
10077,16,156,4,0,
10077,16,157,4,0,
10077,16,164,4,0,
10077,16,173,3,0,
10077,16,182,4,0,
10077,16,184,1,5,1
10077,16,196,3,0,
10077,16,207,4,0,
10077,16,214,4,0,
10077,16,216,4,0,
10077,16,218,4,0,
10077,16,219,4,0,
10077,16,237,4,0,
10077,16,240,4,0,
10077,16,244,4,0,
10077,16,246,1,1,1
10077,16,249,4,0,
10077,16,253,3,0,
10077,16,258,4,0,
10077,16,263,4,0,
10077,16,280,4,0,
10077,16,290,4,0,
10077,16,291,4,0,
10077,16,317,4,0,
10077,16,323,1,90,1
10077,16,324,3,0,
10077,16,329,1,65,1
10077,16,330,1,60,1
10077,16,335,3,0,
10077,16,347,1,50,1
10077,16,347,4,0,
10077,16,351,3,0,
10077,16,352,1,1,2
10077,16,352,3,0,
10077,16,392,1,30,1
10077,16,401,1,15,1
10077,16,401,3,0,
10077,16,416,4,0,
10077,16,442,3,0,
10077,16,496,4,0,
10077,16,503,4,0,
10077,16,523,4,0,
10077,16,590,4,0,
10077,16,618,1,45,1
10077,17,34,1,20,
10077,17,38,1,80,
10077,17,46,4,0,
10077,17,56,1,75,
10077,17,57,4,0,
10077,17,58,1,35,
10077,17,58,4,0,
10077,17,59,4,0,
10077,17,63,4,0,
10077,17,85,4,0,
10077,17,86,4,0,
10077,17,87,4,0,
10077,17,89,4,0,
10077,17,92,4,0,
10077,17,104,4,0,
10077,17,127,4,0,
10077,17,156,4,0,
10077,17,157,4,0,
10077,17,164,4,0,
10077,17,182,4,0,
10077,17,184,1,5,
10077,17,207,4,0,
10077,17,214,4,0,
10077,17,216,4,0,
10077,17,218,4,0,
10077,17,219,4,0,
10077,17,237,4,0,
10077,17,240,4,0,
10077,17,244,4,0,
10077,17,246,1,1,1
10077,17,258,4,0,
10077,17,263,4,0,
10077,17,280,4,0,
10077,17,317,4,0,
10077,17,323,1,90,
10077,17,329,1,65,
10077,17,330,1,60,
10077,17,347,1,50,
10077,17,347,4,0,
10077,17,352,1,1,2
10077,17,392,1,30,
10077,17,401,1,15,
10077,17,416,4,0,
10077,17,496,4,0,
10077,17,503,4,0,
10077,17,523,4,0,
10077,17,590,4,0,
10077,17,618,1,45,
10077,18,34,1,20,
10077,18,38,1,80,
10077,18,46,4,0,
10077,18,56,1,75,
10077,18,57,4,0,
10077,18,58,1,35,
10077,18,58,4,0,
10077,18,59,4,0,
10077,18,63,4,0,
10077,18,85,4,0,
10077,18,86,4,0,
10077,18,87,4,0,
10077,18,89,4,0,
10077,18,92,4,0,
10077,18,104,4,0,
10077,18,127,4,0,
10077,18,156,4,0,
10077,18,157,4,0,
10077,18,164,4,0,
10077,18,182,4,0,
10077,18,184,1,5,
10077,18,207,4,0,
10077,18,214,4,0,
10077,18,216,4,0,
10077,18,218,4,0,
10077,18,219,4,0,
10077,18,237,4,0,
10077,18,240,4,0,
10077,18,244,4,0,
10077,18,246,1,1,1
10077,18,258,4,0,
10077,18,263,4,0,
10077,18,280,4,0,
10077,18,317,4,0,
10077,18,323,1,90,
10077,18,329,1,65,
10077,18,330,1,60,
10077,18,347,1,50,
10077,18,347,4,0,
10077,18,352,1,1,2
10077,18,392,1,30,
10077,18,401,1,15,
10077,18,416,4,0,
10077,18,496,4,0,
10077,18,503,4,0,
10077,18,523,4,0,
10077,18,590,4,0,
10077,18,618,1,45,
10078,16,7,3,0,
10078,16,9,3,0,
10078,16,14,4,0,
10078,16,15,4,0,
10078,16,46,4,0,
10078,16,53,4,0,
10078,16,63,4,0,
10078,16,70,4,0,
10078,16,76,1,60,1
10078,16,76,4,0,
10078,16,85,4,0,
10078,16,86,4,0,
10078,16,87,4,0,
10078,16,89,1,35,1
10078,16,89,4,0,
10078,16,90,1,65,1
10078,16,91,4,0,
10078,16,92,4,0,
10078,16,104,4,0,
10078,16,126,1,75,1
10078,16,126,4,0,
10078,16,156,1,30,1
10078,16,156,4,0,
10078,16,157,4,0,
10078,16,164,4,0,
10078,16,173,3,0,
10078,16,182,4,0,
10078,16,184,1,5,1
10078,16,201,4,0,
10078,16,207,4,0,
10078,16,214,4,0,
10078,16,216,4,0,
10078,16,218,4,0,
10078,16,219,4,0,
10078,16,231,3,0,
10078,16,237,4,0,
10078,16,241,4,0,
10078,16,244,4,0,
10078,16,246,1,1,1
10078,16,249,4,0,
10078,16,253,3,0,
10078,16,263,4,0,
10078,16,280,4,0,
10078,16,284,1,90,1
10078,16,290,4,0,
10078,16,315,4,0,
10078,16,317,4,0,
10078,16,332,4,0,
10078,16,335,3,0,
10078,16,337,4,0,
10078,16,339,1,50,1
10078,16,339,4,0,
10078,16,341,1,1,2
10078,16,351,3,0,
10078,16,359,1,80,1
10078,16,374,4,0,
10078,16,397,4,0,
10078,16,406,3,0,
10078,16,411,4,0,
10078,16,414,1,15,1
10078,16,414,3,0,
10078,16,416,4,0,
10078,16,421,4,0,
10078,16,436,1,20,1
10078,16,442,3,0,
10078,16,444,4,0,
10078,16,446,3,0,
10078,16,468,4,0,
10078,16,479,4,0,
10078,16,496,4,0,
10078,16,510,4,0,
10078,16,523,4,0,
10078,16,525,4,0,
10078,16,590,4,0,
10078,16,612,4,0,
10078,16,619,1,45,1
10078,17,14,4,0,
10078,17,46,4,0,
10078,17,53,4,0,
10078,17,63,4,0,
10078,17,76,1,60,
10078,17,76,4,0,
10078,17,85,4,0,
10078,17,86,4,0,
10078,17,87,4,0,
10078,17,89,1,35,
10078,17,89,4,0,
10078,17,90,1,65,
10078,17,92,4,0,
10078,17,104,4,0,
10078,17,126,1,75,
10078,17,126,4,0,
10078,17,156,1,30,
10078,17,156,4,0,
10078,17,157,4,0,
10078,17,164,4,0,
10078,17,182,4,0,
10078,17,184,1,5,
10078,17,201,4,0,
10078,17,207,4,0,
10078,17,214,4,0,
10078,17,216,4,0,
10078,17,218,4,0,
10078,17,219,4,0,
10078,17,237,4,0,
10078,17,241,4,0,
10078,17,244,4,0,
10078,17,246,1,1,1
10078,17,263,4,0,
10078,17,280,4,0,
10078,17,284,1,90,
10078,17,315,4,0,
10078,17,317,4,0,
10078,17,332,4,0,
10078,17,337,4,0,
10078,17,339,1,50,
10078,17,339,4,0,
10078,17,341,1,1,2
10078,17,359,1,80,
10078,17,374,4,0,
10078,17,397,4,0,
10078,17,411,4,0,
10078,17,414,1,15,
10078,17,416,4,0,
10078,17,421,4,0,
10078,17,436,1,20,
10078,17,444,4,0,
10078,17,479,4,0,
10078,17,496,4,0,
10078,17,523,4,0,
10078,17,525,4,0,
10078,17,590,4,0,
10078,17,619,1,45,
10078,17,693,4,0,
10078,18,14,4,0,
10078,18,46,4,0,
10078,18,53,4,0,
10078,18,63,4,0,
10078,18,76,1,60,
10078,18,76,4,0,
10078,18,85,4,0,
10078,18,86,4,0,
10078,18,87,4,0,
10078,18,89,1,35,
10078,18,89,4,0,
10078,18,90,1,65,
10078,18,92,4,0,
10078,18,104,4,0,
10078,18,126,1,75,
10078,18,126,4,0,
10078,18,156,1,30,
10078,18,156,4,0,
10078,18,157,4,0,
10078,18,164,4,0,
10078,18,182,4,0,
10078,18,184,1,5,
10078,18,201,4,0,
10078,18,207,4,0,
10078,18,214,4,0,
10078,18,216,4,0,
10078,18,218,4,0,
10078,18,219,4,0,
10078,18,237,4,0,
10078,18,241,4,0,
10078,18,244,4,0,
10078,18,246,1,1,1
10078,18,263,4,0,
10078,18,280,4,0,
10078,18,284,1,90,
10078,18,315,4,0,
10078,18,317,4,0,
10078,18,332,4,0,
10078,18,337,4,0,
10078,18,339,1,50,
10078,18,339,4,0,
10078,18,341,1,1,2
10078,18,359,1,80,
10078,18,374,4,0,
10078,18,397,4,0,
10078,18,411,4,0,
10078,18,414,1,15,
10078,18,416,4,0,
10078,18,421,4,0,
10078,18,436,1,20,
10078,18,444,4,0,
10078,18,479,4,0,
10078,18,496,4,0,
10078,18,523,4,0,
10078,18,525,4,0,
10078,18,590,4,0,
10078,18,619,1,45,
10078,18,693,4,0,
10079,16,14,4,0,
10079,16,19,1,65,1
10079,16,19,4,0,
10079,16,20,3,0,
10079,16,46,4,0,
10079,16,53,4,0,
10079,16,57,4,0,
10079,16,58,4,0,
10079,16,59,4,0,
10079,16,63,1,90,1
10079,16,63,4,0,
10079,16,70,4,0,
10079,16,76,4,0,
10079,16,85,4,0,
10079,16,86,4,0,
10079,16,87,4,0,
10079,16,89,4,0,
10079,16,92,4,0,
10079,16,104,4,0,
10079,16,126,4,0,
10079,16,127,4,0,
10079,16,156,1,35,1
10079,16,156,4,0,
10079,16,157,4,0,
10079,16,164,4,0,
10079,16,173,3,0,
10079,16,182,4,0,
10079,16,184,1,5,1
10079,16,196,3,0,
10079,16,200,1,80,1
10079,16,200,3,0,
10079,16,201,4,0,
10079,16,207,4,0,
10079,16,214,4,0,
10079,16,216,4,0,
10079,16,218,4,0,
10079,16,231,3,0,
10079,16,237,4,0,
10079,16,239,1,1,1
10079,16,240,4,0,
10079,16,241,4,0,
10079,16,242,1,20,1
10079,16,244,4,0,
10079,16,245,1,45,1
10079,16,246,1,15,1
10079,16,249,4,0,
10079,16,253,3,0,
10079,16,263,4,0,
10079,16,280,4,0,
10079,16,290,4,0,
10079,16,291,4,0,
10079,16,304,1,75,1
10079,16,304,3,0,
10079,16,315,4,0,
10079,16,317,4,0,
10079,16,332,4,0,
10079,16,337,4,0,
10079,16,339,4,0,
10079,16,349,1,60,1
10079,16,351,3,0,
10079,16,352,3,0,
10079,16,360,4,0,
10079,16,366,3,0,
10079,16,374,4,0,
10079,16,401,3,0,
10079,16,403,1,30,1
10079,16,406,1,50,1
10079,16,406,3,0,
10079,16,411,4,0,
10079,16,412,4,0,
10079,16,414,3,0,
10079,16,416,4,0,
10079,16,421,4,0,
10079,16,434,3,0,
10079,16,442,3,0,
10079,16,444,4,0,
10079,16,468,4,0,
10079,16,496,4,0,
10079,16,497,4,0,
10079,16,507,4,0,
10079,16,510,4,0,
10079,16,523,4,0,
10079,16,525,4,0,
10079,16,590,4,0,
10079,16,620,3,0,
10079,17,14,4,0,
10079,17,19,1,65,
10079,17,19,4,0,
10079,17,46,4,0,
10079,17,53,4,0,
10079,17,57,4,0,
10079,17,58,4,0,
10079,17,59,4,0,
10079,17,63,1,90,
10079,17,63,4,0,
10079,17,76,4,0,
10079,17,85,4,0,
10079,17,86,4,0,
10079,17,87,4,0,
10079,17,89,4,0,
10079,17,92,4,0,
10079,17,104,4,0,
10079,17,126,4,0,
10079,17,127,4,0,
10079,17,156,1,35,
10079,17,156,4,0,
10079,17,157,4,0,
10079,17,164,4,0,
10079,17,182,4,0,
10079,17,184,1,5,
10079,17,200,1,80,
10079,17,201,4,0,
10079,17,207,4,0,
10079,17,214,4,0,
10079,17,216,4,0,
10079,17,218,4,0,
10079,17,237,4,0,
10079,17,239,1,1,
10079,17,240,4,0,
10079,17,241,4,0,
10079,17,242,1,20,
10079,17,244,4,0,
10079,17,245,1,45,
10079,17,246,1,15,
10079,17,263,4,0,
10079,17,280,4,0,
10079,17,304,1,75,
10079,17,315,4,0,
10079,17,317,4,0,
10079,17,332,4,0,
10079,17,337,4,0,
10079,17,339,4,0,
10079,17,349,1,60,
10079,17,360,4,0,
10079,17,374,4,0,
10079,17,403,1,30,
10079,17,406,1,50,
10079,17,411,4,0,
10079,17,412,4,0,
10079,17,416,4,0,
10079,17,421,4,0,
10079,17,434,3,0,
10079,17,444,4,0,
10079,17,496,4,0,
10079,17,497,4,0,
10079,17,507,4,0,
10079,17,523,4,0,
10079,17,525,4,0,
10079,17,590,4,0,
10079,17,620,3,0,
10079,17,693,4,0,
10079,18,14,4,0,
10079,18,19,1,65,
10079,18,19,4,0,
10079,18,46,4,0,
10079,18,53,4,0,
10079,18,57,4,0,
10079,18,58,4,0,
10079,18,59,4,0,
10079,18,63,1,90,
10079,18,63,4,0,
10079,18,76,4,0,
10079,18,85,4,0,
10079,18,86,4,0,
10079,18,87,4,0,
10079,18,89,4,0,
10079,18,92,4,0,
10079,18,104,4,0,
10079,18,126,4,0,
10079,18,127,4,0,
10079,18,156,1,35,
10079,18,156,4,0,
10079,18,157,4,0,
10079,18,164,4,0,
10079,18,182,4,0,
10079,18,184,1,5,
10079,18,200,1,80,
10079,18,201,4,0,
10079,18,207,4,0,
10079,18,214,4,0,
10079,18,216,4,0,
10079,18,218,4,0,
10079,18,237,4,0,
10079,18,239,1,1,
10079,18,240,4,0,
10079,18,241,4,0,
10079,18,242,1,20,
10079,18,244,4,0,
10079,18,245,1,45,
10079,18,246,1,15,
10079,18,263,4,0,
10079,18,280,4,0,
10079,18,304,1,75,
10079,18,315,4,0,
10079,18,317,4,0,
10079,18,332,4,0,
10079,18,337,4,0,
10079,18,339,4,0,
10079,18,349,1,60,
10079,18,360,4,0,
10079,18,374,4,0,
10079,18,403,1,30,
10079,18,406,1,50,
10079,18,411,4,0,
10079,18,412,4,0,
10079,18,416,4,0,
10079,18,421,4,0,
10079,18,434,3,0,
10079,18,444,4,0,
10079,18,496,4,0,
10079,18,497,4,0,
10079,18,507,4,0,
10079,18,523,4,0,
10079,18,525,4,0,
10079,18,590,4,0,
10079,18,620,3,0,
10079,18,693,4,0,
10080,16,9,3,0,
10080,16,21,1,37,1
10080,16,39,1,1,1
10080,16,45,1,5,1
10080,16,70,4,0,
10080,16,84,1,1,2
10080,16,85,1,42,1
10080,16,85,4,0,
10080,16,86,1,18,1
10080,16,86,4,0,
10080,16,87,1,58,1
10080,16,87,4,0,
10080,16,91,4,0,
10080,16,92,4,0,
10080,16,97,1,45,1
10080,16,98,1,10,1
10080,16,104,1,23,1
10080,16,104,4,0,
10080,16,113,1,53,1
10080,16,113,4,0,
10080,16,148,4,0,
10080,16,156,4,0,
10080,16,164,4,0,
10080,16,173,3,0,
10080,16,182,4,0,
10080,16,207,4,0,
10080,16,209,1,26,1
10080,16,213,4,0,
10080,16,214,4,0,
10080,16,216,4,0,
10080,16,218,4,0,
10080,16,231,3,0,
10080,16,237,4,0,
10080,16,240,4,0,
10080,16,249,4,0,
10080,16,263,4,0,
10080,16,264,3,0,
10080,16,270,3,0,
10080,16,280,4,0,
10080,16,282,3,0,
10080,16,290,4,0,
10080,16,309,10,0,
10080,16,324,3,0,
10080,16,343,3,0,
10080,16,351,3,0,
10080,16,364,1,21,1
10080,16,374,4,0,
10080,16,393,3,0,
10080,16,435,1,34,1
10080,16,447,4,0,
10080,16,451,4,0,
10080,16,486,1,13,1
10080,16,496,4,0,
10080,16,497,4,0,
10080,16,521,4,0,
10080,16,527,3,0,
10080,16,528,1,50,1
10080,16,528,4,0,
10080,16,589,1,7,1
10080,16,590,4,0,
10080,16,609,1,29,1
10081,16,9,3,0,
10081,16,21,1,37,1
10081,16,39,1,1,1
10081,16,45,1,5,1
10081,16,70,4,0,
10081,16,84,1,1,2
10081,16,85,1,42,1
10081,16,85,4,0,
10081,16,86,1,18,1
10081,16,86,4,0,
10081,16,87,1,58,1
10081,16,87,4,0,
10081,16,91,4,0,
10081,16,92,4,0,
10081,16,97,1,45,1
10081,16,98,1,10,1
10081,16,104,1,23,1
10081,16,104,4,0,
10081,16,113,1,53,1
10081,16,113,4,0,
10081,16,148,4,0,
10081,16,156,4,0,
10081,16,164,4,0,
10081,16,173,3,0,
10081,16,182,4,0,
10081,16,207,4,0,
10081,16,209,1,26,1
10081,16,213,4,0,
10081,16,214,4,0,
10081,16,216,4,0,
10081,16,218,4,0,
10081,16,231,3,0,
10081,16,237,4,0,
10081,16,240,4,0,
10081,16,249,4,0,
10081,16,263,4,0,
10081,16,264,3,0,
10081,16,270,3,0,
10081,16,280,4,0,
10081,16,282,3,0,
10081,16,290,4,0,
10081,16,324,3,0,
10081,16,343,3,0,
10081,16,351,3,0,
10081,16,364,1,21,1
10081,16,374,4,0,
10081,16,393,3,0,
10081,16,435,1,34,1
10081,16,447,4,0,
10081,16,451,4,0,
10081,16,486,1,13,1
10081,16,496,4,0,
10081,16,497,4,0,
10081,16,521,4,0,
10081,16,527,3,0,
10081,16,528,1,50,1
10081,16,528,4,0,
10081,16,556,10,0,
10081,16,589,1,7,1
10081,16,590,4,0,
10081,16,609,1,29,1
10082,16,9,3,0,
10082,16,21,1,37,1
10082,16,39,1,1,1
10082,16,45,1,5,1
10082,16,70,4,0,
10082,16,84,1,1,2
10082,16,85,1,42,1
10082,16,85,4,0,
10082,16,86,1,18,1
10082,16,86,4,0,
10082,16,87,1,58,1
10082,16,87,4,0,
10082,16,91,4,0,
10082,16,92,4,0,
10082,16,97,1,45,1
10082,16,98,1,10,1
10082,16,104,1,23,1
10082,16,104,4,0,
10082,16,113,1,53,1
10082,16,113,4,0,
10082,16,148,4,0,
10082,16,156,4,0,
10082,16,164,4,0,
10082,16,173,3,0,
10082,16,182,4,0,
10082,16,207,4,0,
10082,16,209,1,26,1
10082,16,213,4,0,
10082,16,214,4,0,
10082,16,216,4,0,
10082,16,218,4,0,
10082,16,231,3,0,
10082,16,237,4,0,
10082,16,240,4,0,
10082,16,249,4,0,
10082,16,263,4,0,
10082,16,264,3,0,
10082,16,270,3,0,
10082,16,280,4,0,
10082,16,282,3,0,
10082,16,290,4,0,
10082,16,324,3,0,
10082,16,343,3,0,
10082,16,351,3,0,
10082,16,364,1,21,1
10082,16,374,4,0,
10082,16,393,3,0,
10082,16,435,1,34,1
10082,16,447,4,0,
10082,16,451,4,0,
10082,16,486,1,13,1
10082,16,496,4,0,
10082,16,497,4,0,
10082,16,521,4,0,
10082,16,527,3,0,
10082,16,528,1,50,1
10082,16,528,4,0,
10082,16,577,10,0,
10082,16,589,1,7,1
10082,16,590,4,0,
10082,16,609,1,29,1
10083,16,9,3,0,
10083,16,21,1,37,1
10083,16,39,1,1,1
10083,16,45,1,5,1
10083,16,70,4,0,
10083,16,84,1,1,2
10083,16,85,1,42,1
10083,16,85,4,0,
10083,16,86,1,18,1
10083,16,86,4,0,
10083,16,87,1,58,1
10083,16,87,4,0,
10083,16,91,4,0,
10083,16,92,4,0,
10083,16,97,1,45,1
10083,16,98,1,10,1
10083,16,104,1,23,1
10083,16,104,4,0,
10083,16,113,1,53,1
10083,16,113,4,0,
10083,16,148,4,0,
10083,16,156,4,0,
10083,16,164,4,0,
10083,16,173,3,0,
10083,16,182,4,0,
10083,16,207,4,0,
10083,16,209,1,26,1
10083,16,213,4,0,
10083,16,214,4,0,
10083,16,216,4,0,
10083,16,218,4,0,
10083,16,231,3,0,
10083,16,237,4,0,
10083,16,240,4,0,
10083,16,249,4,0,
10083,16,263,4,0,
10083,16,264,3,0,
10083,16,270,3,0,
10083,16,280,4,0,
10083,16,282,3,0,
10083,16,290,4,0,
10083,16,324,3,0,
10083,16,343,3,0,
10083,16,351,3,0,
10083,16,364,1,21,1
10083,16,374,4,0,
10083,16,393,3,0,
10083,16,435,1,34,1
10083,16,447,4,0,
10083,16,451,4,0,
10083,16,486,1,13,1
10083,16,496,4,0,
10083,16,497,4,0,
10083,16,521,4,0,
10083,16,527,3,0,
10083,16,528,1,50,1
10083,16,528,4,0,
10083,16,589,1,7,1
10083,16,590,4,0,
10083,16,604,10,0,
10083,16,609,1,29,1
10084,16,9,3,0,
10084,16,21,1,37,1
10084,16,39,1,1,1
10084,16,45,1,5,1
10084,16,70,4,0,
10084,16,84,1,1,2
10084,16,85,1,42,1
10084,16,85,4,0,
10084,16,86,1,18,1
10084,16,86,4,0,
10084,16,87,1,58,1
10084,16,87,4,0,
10084,16,91,4,0,
10084,16,92,4,0,
10084,16,97,1,45,1
10084,16,98,1,10,1
10084,16,104,1,23,1
10084,16,104,4,0,
10084,16,113,1,53,1
10084,16,113,4,0,
10084,16,148,4,0,
10084,16,156,4,0,
10084,16,164,4,0,
10084,16,173,3,0,
10084,16,182,4,0,
10084,16,207,4,0,
10084,16,209,1,26,1
10084,16,213,4,0,
10084,16,214,4,0,
10084,16,216,4,0,
10084,16,218,4,0,
10084,16,231,3,0,
10084,16,237,4,0,
10084,16,240,4,0,
10084,16,249,4,0,
10084,16,263,4,0,
10084,16,264,3,0,
10084,16,270,3,0,
10084,16,280,4,0,
10084,16,282,3,0,
10084,16,290,4,0,
10084,16,324,3,0,
10084,16,343,3,0,
10084,16,351,3,0,
10084,16,364,1,21,1
10084,16,374,4,0,
10084,16,393,3,0,
10084,16,435,1,34,1
10084,16,447,4,0,
10084,16,451,4,0,
10084,16,486,1,13,1
10084,16,496,4,0,
10084,16,497,4,0,
10084,16,521,4,0,
10084,16,527,3,0,
10084,16,528,1,50,1
10084,16,528,4,0,
10084,16,560,10,0,
10084,16,589,1,7,1
10084,16,590,4,0,
10084,16,609,1,29,1
10085,16,9,3,0,
10085,16,21,1,37,1
10085,16,39,1,1,1
10085,16,45,1,5,1
10085,16,70,4,0,
10085,16,84,1,1,2
10085,16,85,1,42,1
10085,16,85,4,0,
10085,16,86,1,18,1
10085,16,86,4,0,
10085,16,87,1,58,1
10085,16,87,4,0,
10085,16,91,4,0,
10085,16,92,4,0,
10085,16,97,1,45,1
10085,16,98,1,10,1
10085,16,104,1,23,1
10085,16,104,4,0,
10085,16,113,1,53,1
10085,16,113,4,0,
10085,16,148,4,0,
10085,16,156,4,0,
10085,16,164,4,0,
10085,16,173,3,0,
10085,16,182,4,0,
10085,16,207,4,0,
10085,16,209,1,26,1
10085,16,213,4,0,
10085,16,214,4,0,
10085,16,216,4,0,
10085,16,218,4,0,
10085,16,231,3,0,
10085,16,237,4,0,
10085,16,240,4,0,
10085,16,249,4,0,
10085,16,263,4,0,
10085,16,264,3,0,
10085,16,270,3,0,
10085,16,280,4,0,
10085,16,282,3,0,
10085,16,290,4,0,
10085,16,324,3,0,
10085,16,343,3,0,
10085,16,351,3,0,
10085,16,364,1,21,1
10085,16,374,4,0,
10085,16,393,3,0,
10085,16,435,1,34,1
10085,16,447,4,0,
10085,16,451,4,0,
10085,16,486,1,13,1
10085,16,496,4,0,
10085,16,497,4,0,
10085,16,521,4,0,
10085,16,527,3,0,
10085,16,528,1,50,1
10085,16,528,4,0,
10085,16,589,1,7,1
10085,16,590,4,0,
10085,16,609,1,29,1
10086,16,7,3,0,
10086,16,8,3,0,
10086,16,9,3,0,
10086,16,60,1,19,1
10086,16,63,4,0,
10086,16,85,4,0,
10086,16,86,4,0,
10086,16,92,4,0,
10086,16,93,1,1,5
10086,16,94,1,75,1
10086,16,94,4,0,
10086,16,104,4,0,
10086,16,113,1,15,1
10086,16,113,4,0,
10086,16,115,4,0,
10086,16,138,4,0,
10086,16,148,4,0,
10086,16,156,4,0,
10086,16,164,4,0,
10086,16,168,4,0,
10086,16,173,3,0,
10086,16,182,4,0,
10086,16,194,1,1,3
10086,16,207,4,0,
10086,16,214,4,0,
10086,16,216,4,0,
10086,16,218,4,0,
10086,16,219,4,0,
10086,16,237,4,0,
10086,16,240,4,0,
10086,16,241,4,0,
10086,16,244,4,0,
10086,16,247,4,0,
10086,16,253,3,0,
10086,16,259,4,0,
10086,16,263,4,0,
10086,16,264,3,0,
10086,16,269,4,0,
10086,16,271,1,1,2
10086,16,271,3,0,
10086,16,272,3,0,
10086,16,277,1,10,1
10086,16,277,3,0,
10086,16,278,3,0,
10086,16,280,4,0,
10086,16,282,1,46,1
10086,16,282,3,0,
10086,16,285,1,25,1
10086,16,285,3,0,
10086,16,289,3,0,
10086,16,290,4,0,
10086,16,310,1,6,1
10086,16,324,3,0,
10086,16,335,3,0,
10086,16,343,3,0,
10086,16,347,4,0,
10086,16,351,3,0,
10086,16,356,3,0,
10086,16,373,4,0,
10086,16,374,4,0,
10086,16,387,3,0,
10086,16,399,1,55,1
10086,16,399,4,0,
10086,16,409,3,0,
10086,16,411,4,0,
10086,16,412,4,0,
10086,16,416,4,0,
10086,16,428,3,0,
10086,16,433,1,50,2
10086,16,433,4,0,
10086,16,441,3,0,
10086,16,447,4,0,
10086,16,451,4,0,
10086,16,470,1,29,2
10086,16,471,1,29,1
10086,16,472,1,50,1
10086,16,472,3,0,
10086,16,473,4,0,
10086,16,478,3,0,
10086,16,492,3,0,
10086,16,496,4,0,
10086,16,502,1,1,4
10086,16,511,4,0,
10086,16,530,3,0,
10086,16,590,4,0,
10086,16,612,4,0,
10086,16,621,1,1,1
10086,16,621,1,85,1
10086,17,60,1,19,
10086,17,63,4,0,
10086,17,85,4,0,
10086,17,86,4,0,
10086,17,92,4,0,
10086,17,93,1,1,5
10086,17,94,1,75,
10086,17,94,4,0,
10086,17,104,4,0,
10086,17,113,1,15,
10086,17,113,4,0,
10086,17,115,4,0,
10086,17,138,4,0,
10086,17,156,4,0,
10086,17,164,4,0,
10086,17,168,4,0,
10086,17,182,4,0,
10086,17,194,1,1,3
10086,17,207,4,0,
10086,17,214,4,0,
10086,17,216,4,0,
10086,17,218,4,0,
10086,17,219,4,0,
10086,17,237,4,0,
10086,17,240,4,0,
10086,17,241,4,0,
10086,17,244,4,0,
10086,17,247,4,0,
10086,17,259,4,0,
10086,17,263,4,0,
10086,17,269,4,0,
10086,17,271,1,1,2
10086,17,277,1,10,
10086,17,280,4,0,
10086,17,282,1,46,
10086,17,285,1,25,
10086,17,310,1,6,
10086,17,347,4,0,
10086,17,373,4,0,
10086,17,374,4,0,
10086,17,399,1,55,
10086,17,399,4,0,
10086,17,411,4,0,
10086,17,412,4,0,
10086,17,416,4,0,
10086,17,433,1,50,2
10086,17,433,4,0,
10086,17,447,4,0,
10086,17,451,4,0,
10086,17,470,1,29,2
10086,17,471,1,29,1
10086,17,472,1,50,1
10086,17,473,4,0,
10086,17,496,4,0,
10086,17,502,1,1,4
10086,17,511,4,0,
10086,17,590,4,0,
10086,17,621,1,1,1
10086,17,621,1,85,
10086,18,60,1,19,
10086,18,63,4,0,
10086,18,85,4,0,
10086,18,86,4,0,
10086,18,92,4,0,
10086,18,93,1,1,5
10086,18,94,1,75,
10086,18,94,4,0,
10086,18,104,4,0,
10086,18,113,1,15,
10086,18,113,4,0,
10086,18,115,4,0,
10086,18,138,4,0,
10086,18,156,4,0,
10086,18,164,4,0,
10086,18,168,4,0,
10086,18,182,4,0,
10086,18,194,1,1,3
10086,18,207,4,0,
10086,18,214,4,0,
10086,18,216,4,0,
10086,18,218,4,0,
10086,18,219,4,0,
10086,18,237,4,0,
10086,18,240,4,0,
10086,18,241,4,0,
10086,18,244,4,0,
10086,18,247,4,0,
10086,18,259,4,0,
10086,18,263,4,0,
10086,18,269,4,0,
10086,18,271,1,1,2
10086,18,277,1,10,
10086,18,280,4,0,
10086,18,282,1,46,
10086,18,285,1,25,
10086,18,310,1,6,
10086,18,347,4,0,
10086,18,373,4,0,
10086,18,374,4,0,
10086,18,399,1,55,
10086,18,399,4,0,
10086,18,411,4,0,
10086,18,412,4,0,
10086,18,416,4,0,
10086,18,433,1,50,2
10086,18,433,4,0,
10086,18,447,4,0,
10086,18,451,4,0,
10086,18,470,1,29,2
10086,18,471,1,29,1
10086,18,472,1,50,1
10086,18,473,4,0,
10086,18,496,4,0,
10086,18,502,1,1,4
10086,18,511,4,0,
10086,18,590,4,0,
10086,18,621,1,1,1
10086,18,621,1,85,
10087,16,33,1,1,4
10087,16,36,1,31,1
10087,16,45,1,1,3
10087,16,46,4,0,
10087,16,52,1,1,5
10087,16,52,1,5,1
10087,16,53,4,0,
10087,16,63,4,0,
10087,16,70,4,0,
10087,16,76,4,0,
10087,16,89,1,46,1
10087,16,89,4,0,
10087,16,90,1,1,1
10087,16,90,1,59,1
10087,16,91,4,0,
10087,16,92,4,0,
10087,16,104,4,0,
10087,16,116,1,1,6
10087,16,116,1,8,1
10087,16,126,4,0,
10087,16,133,1,19,1
10087,16,153,4,0,
10087,16,156,4,0,
10087,16,157,1,33,1
10087,16,157,4,0,
10087,16,164,4,0,
10087,16,173,3,0,
10087,16,174,1,29,1
10087,16,182,4,0,
10087,16,201,4,0,
10087,16,207,4,0,
10087,16,213,4,0,
10087,16,214,4,0,
10087,16,216,4,0,
10087,16,218,4,0,
10087,16,222,1,12,1
10087,16,237,4,0,
10087,16,241,4,0,
10087,16,249,4,0,
10087,16,257,3,0,
10087,16,261,4,0,
10087,16,263,4,0,
10087,16,267,4,0,
10087,16,281,1,39,1
10087,16,284,1,1,2
10087,16,284,1,52,1
10087,16,290,4,0,
10087,16,315,4,0,
10087,16,317,4,0,
10087,16,397,4,0,
10087,16,414,1,26,1
10087,16,414,3,0,
10087,16,416,4,0,
10087,16,430,4,0,
10087,16,436,1,22,1
10087,16,442,3,0,
10087,16,444,4,0,
10087,16,446,3,0,
10087,16,481,1,15,1
10087,16,488,4,0,
10087,16,495,3,0,
10087,16,496,4,0,
10087,16,497,4,0,
10087,16,510,4,0,
10087,16,523,4,0,
10087,16,590,4,0,
10087,17,33,1,1,5
10087,17,36,1,31,
10087,17,45,1,1,4
10087,17,46,4,0,
10087,17,52,1,1,6
10087,17,52,1,5,
10087,17,53,4,0,
10087,17,63,4,0,
10087,17,76,4,0,
10087,17,89,1,46,
10087,17,89,4,0,
10087,17,90,1,1,2
10087,17,90,1,59,
10087,17,92,4,0,
10087,17,104,4,0,
10087,17,116,1,1,7
10087,17,116,1,8,
10087,17,126,4,0,
10087,17,133,1,19,
10087,17,153,4,0,
10087,17,156,4,0,
10087,17,157,1,0,
10087,17,157,1,1,1
10087,17,157,4,0,
10087,17,164,4,0,
10087,17,174,1,29,
10087,17,182,4,0,
10087,17,201,4,0,
10087,17,207,4,0,
10087,17,213,4,0,
10087,17,214,4,0,
10087,17,216,4,0,
10087,17,218,4,0,
10087,17,222,1,12,
10087,17,237,4,0,
10087,17,241,4,0,
10087,17,261,4,0,
10087,17,263,4,0,
10087,17,267,4,0,
10087,17,281,1,39,
10087,17,284,1,1,3
10087,17,284,1,52,
10087,17,315,4,0,
10087,17,317,4,0,
10087,17,397,4,0,
10087,17,414,1,26,
10087,17,416,4,0,
10087,17,430,4,0,
10087,17,436,1,22,
10087,17,444,4,0,
10087,17,481,1,15,
10087,17,488,4,0,
10087,17,496,4,0,
10087,17,497,4,0,
10087,17,523,4,0,
10087,17,590,4,0,
10087,18,33,1,1,5
10087,18,36,1,31,
10087,18,45,1,1,4
10087,18,46,4,0,
10087,18,52,1,1,6
10087,18,52,1,5,
10087,18,53,4,0,
10087,18,63,4,0,
10087,18,76,4,0,
10087,18,89,1,46,
10087,18,89,4,0,
10087,18,90,1,1,2
10087,18,90,1,59,
10087,18,92,4,0,
10087,18,104,4,0,
10087,18,116,1,1,7
10087,18,116,1,8,
10087,18,126,4,0,
10087,18,133,1,19,
10087,18,153,4,0,
10087,18,156,4,0,
10087,18,157,1,0,
10087,18,157,1,1,1
10087,18,157,4,0,
10087,18,164,4,0,
10087,18,174,1,29,
10087,18,182,4,0,
10087,18,201,4,0,
10087,18,207,4,0,
10087,18,213,4,0,
10087,18,214,4,0,
10087,18,216,4,0,
10087,18,218,4,0,
10087,18,222,1,12,
10087,18,237,4,0,
10087,18,241,4,0,
10087,18,261,4,0,
10087,18,263,4,0,
10087,18,267,4,0,
10087,18,281,1,39,
10087,18,284,1,1,3
10087,18,284,1,52,
10087,18,315,4,0,
10087,18,317,4,0,
10087,18,397,4,0,
10087,18,414,1,26,
10087,18,416,4,0,
10087,18,430,4,0,
10087,18,436,1,22,
10087,18,444,4,0,
10087,18,481,1,15,
10087,18,488,4,0,
10087,18,496,4,0,
10087,18,497,4,0,
10087,18,523,4,0,
10087,18,590,4,0,
10088,16,1,1,1,8
10088,16,7,3,0,
10088,16,8,3,0,
10088,16,9,3,0,
10088,16,15,4,0,
10088,16,26,1,23,1
10088,16,58,4,0,
10088,16,59,4,0,
10088,16,63,4,0,
10088,16,67,3,0,
10088,16,70,4,0,
10088,16,76,4,0,
10088,16,85,4,0,
10088,16,86,4,0,
10088,16,87,4,0,
10088,16,91,4,0,
10088,16,92,4,0,
10088,16,97,1,33,1
10088,16,98,1,16,1
10088,16,104,4,0,
10088,16,111,1,1,6
10088,16,136,1,66,1
10088,16,146,1,36,1
10088,16,150,1,1,7
10088,16,156,4,0,
10088,16,164,4,0,
10088,16,173,3,0,
10088,16,182,4,0,
10088,16,193,1,1,9
10088,16,203,1,6,1
10088,16,204,1,46,1
10088,16,207,4,0,
10088,16,213,4,0,
10088,16,214,4,0,
10088,16,215,3,0,
10088,16,216,1,13,1
10088,16,216,4,0,
10088,16,218,4,0,
10088,16,226,1,26,1
10088,16,231,3,0,
10088,16,237,4,0,
10088,16,240,4,0,
10088,16,241,4,0,
10088,16,243,1,1,4
10088,16,247,4,0,
10088,16,249,4,0,
10088,16,253,3,0,
10088,16,263,4,0,
10088,16,264,3,0,
10088,16,270,3,0,
10088,16,277,1,1,5
10088,16,277,3,0,
10088,16,283,3,0,
10088,16,290,4,0,
10088,16,304,3,0,
10088,16,340,1,1,2
10088,16,340,1,56,1
10088,16,340,3,0,
10088,16,343,3,0,
10088,16,351,3,0,
10088,16,352,3,0,
10088,16,361,1,1,1
10088,16,361,1,63,1
10088,16,374,4,0,
10088,16,387,3,0,
10088,16,409,3,0,
10088,16,411,4,0,
10088,16,416,4,0,
10088,16,447,4,0,
10088,16,451,4,0,
10088,16,490,4,0,
10088,16,494,1,53,1
10088,16,495,1,43,1
10088,16,495,3,0,
10088,16,496,4,0,
10088,16,514,4,0,
10088,16,563,1,1,3
10088,16,590,4,0,
10088,16,612,4,0,
10088,17,1,1,1,9
10088,17,26,1,23,
10088,17,58,4,0,
10088,17,59,4,0,
10088,17,63,4,0,
10088,17,76,4,0,
10088,17,85,4,0,
10088,17,86,4,0,
10088,17,87,4,0,
10088,17,92,4,0,
10088,17,97,1,33,
10088,17,98,1,16,
10088,17,104,4,0,
10088,17,111,1,1,7
10088,17,136,1,66,
10088,17,146,1,36,
10088,17,150,1,1,8
10088,17,156,4,0,
10088,17,164,4,0,
10088,17,182,4,0,
10088,17,193,1,1,10
10088,17,203,1,6,
10088,17,204,1,46,
10088,17,207,4,0,
10088,17,213,4,0,
10088,17,214,4,0,
10088,17,216,1,0,
10088,17,216,1,1,1
10088,17,216,4,0,
10088,17,218,4,0,
10088,17,226,1,26,
10088,17,237,4,0,
10088,17,240,4,0,
10088,17,241,4,0,
10088,17,243,1,1,5
10088,17,247,4,0,
10088,17,263,4,0,
10088,17,277,1,1,6
10088,17,340,1,1,3
10088,17,340,1,56,
10088,17,361,1,1,2
10088,17,361,1,63,
10088,17,374,4,0,
10088,17,411,4,0,
10088,17,416,4,0,
10088,17,447,4,0,
10088,17,451,4,0,
10088,17,490,4,0,
10088,17,494,1,53,
10088,17,495,1,43,
10088,17,496,4,0,
10088,17,526,4,0,
10088,17,563,1,1,4
10088,17,590,4,0,
10088,17,608,1,13,
10088,17,693,4,0,
10088,18,1,1,1,9
10088,18,26,1,23,
10088,18,58,4,0,
10088,18,59,4,0,
10088,18,63,4,0,
10088,18,76,4,0,
10088,18,85,4,0,
10088,18,86,4,0,
10088,18,87,4,0,
10088,18,92,4,0,
10088,18,97,1,33,
10088,18,98,1,16,
10088,18,104,4,0,
10088,18,111,1,1,7
10088,18,136,1,66,
10088,18,146,1,36,
10088,18,150,1,1,8
10088,18,156,4,0,
10088,18,164,4,0,
10088,18,182,4,0,
10088,18,193,1,1,10
10088,18,203,1,6,
10088,18,204,1,46,
10088,18,207,4,0,
10088,18,213,4,0,
10088,18,214,4,0,
10088,18,216,1,0,
10088,18,216,1,1,1
10088,18,216,4,0,
10088,18,218,4,0,
10088,18,226,1,26,
10088,18,237,4,0,
10088,18,240,4,0,
10088,18,241,4,0,
10088,18,243,1,1,5
10088,18,247,4,0,
10088,18,263,4,0,
10088,18,277,1,1,6
10088,18,340,1,1,3
10088,18,340,1,56,
10088,18,361,1,1,2
10088,18,361,1,63,
10088,18,374,4,0,
10088,18,411,4,0,
10088,18,416,4,0,
10088,18,447,4,0,
10088,18,451,4,0,
10088,18,490,4,0,
10088,18,494,1,53,
10088,18,495,1,43,
10088,18,496,4,0,
10088,18,526,4,0,
10088,18,563,1,1,4
10088,18,590,4,0,
10088,18,608,1,13,
10088,18,693,4,0,
10089,16,15,4,0,
10089,16,19,1,50,1
10089,16,19,4,0,
10089,16,29,1,17,1
10089,16,38,1,63,1
10089,16,43,1,1,6
10089,16,43,1,7,1
10089,16,44,1,1,7
10089,16,44,1,10,1
10089,16,46,4,0,
10089,16,52,1,1,5
10089,16,52,1,4,1
10089,16,53,1,49,1
10089,16,53,4,0,
10089,16,63,4,0,
10089,16,70,4,0,
10089,16,89,4,0,
10089,16,92,4,0,
10089,16,99,1,1,4
10089,16,104,4,0,
10089,16,116,1,21,1
10089,16,126,4,0,
10089,16,156,4,0,
10089,16,157,4,0,
10089,16,164,4,0,
10089,16,173,3,0,
10089,16,182,1,30,1
10089,16,182,4,0,
10089,16,184,1,42,1
10089,16,200,3,0,
10089,16,207,4,0,
10089,16,211,4,0,
10089,16,213,4,0,
10089,16,214,4,0,
10089,16,216,4,0,
10089,16,218,4,0,
10089,16,225,1,13,1
10089,16,231,3,0,
10089,16,237,4,0,
10089,16,240,4,0,
10089,16,241,4,0,
10089,16,242,1,25,1
10089,16,249,4,0,
10089,16,257,3,0,
10089,16,263,4,0,
10089,16,280,4,0,
10089,16,290,4,0,
10089,16,304,3,0,
10089,16,317,4,0,
10089,16,332,4,0,
10089,16,337,1,29,1
10089,16,337,4,0,
10089,16,355,4,0,
10089,16,366,3,0,
10089,16,401,3,0,
10089,16,406,3,0,
10089,16,416,4,0,
10089,16,421,4,0,
10089,16,422,1,1,3
10089,16,424,1,1,2
10089,16,428,1,35,1
10089,16,428,3,0,
10089,16,434,3,0,
10089,16,444,4,0,
10089,16,468,4,0,
10089,16,496,4,0,
10089,16,510,4,0,
10089,16,523,4,0,
10089,16,525,1,1,1
10089,16,525,4,0,
10089,16,590,4,0,
10089,17,19,1,0,
10089,17,19,1,1,1
10089,17,19,4,0,
10089,17,29,1,17,
10089,17,38,1,63,
10089,17,43,1,1,8
10089,17,43,1,7,
10089,17,44,1,1,9
10089,17,44,1,10,
10089,17,46,4,0,
10089,17,52,1,1,7
10089,17,52,1,4,
10089,17,53,1,49,
10089,17,53,4,0,
10089,17,63,4,0,
10089,17,89,4,0,
10089,17,92,4,0,
10089,17,99,1,1,6
10089,17,104,4,0,
10089,17,116,1,21,
10089,17,126,4,0,
10089,17,156,4,0,
10089,17,157,4,0,
10089,17,164,4,0,
10089,17,182,1,1,2
10089,17,182,4,0,
10089,17,184,1,42,
10089,17,207,4,0,
10089,17,211,4,0,
10089,17,213,4,0,
10089,17,214,4,0,
10089,17,216,4,0,
10089,17,218,4,0,
10089,17,225,1,13,
10089,17,237,4,0,
10089,17,240,4,0,
10089,17,241,4,0,
10089,17,242,1,25,
10089,17,263,4,0,
10089,17,280,4,0,
10089,17,317,4,0,
10089,17,332,4,0,
10089,17,337,1,29,
10089,17,337,4,0,
10089,17,355,4,0,
10089,17,416,4,0,
10089,17,421,4,0,
10089,17,422,1,1,5
10089,17,424,1,1,4
10089,17,428,1,35,
10089,17,434,3,0,
10089,17,444,4,0,
10089,17,496,4,0,
10089,17,523,4,0,
10089,17,525,1,1,3
10089,17,525,4,0,
10089,17,590,4,0,
10089,17,693,4,0,
10089,18,19,1,0,
10089,18,19,1,1,1
10089,18,19,4,0,
10089,18,29,1,17,
10089,18,38,1,63,
10089,18,43,1,1,8
10089,18,43,1,7,
10089,18,44,1,1,9
10089,18,44,1,10,
10089,18,46,4,0,
10089,18,52,1,1,7
10089,18,52,1,4,
10089,18,53,1,49,
10089,18,53,4,0,
10089,18,63,4,0,
10089,18,89,4,0,
10089,18,92,4,0,
10089,18,99,1,1,6
10089,18,104,4,0,
10089,18,116,1,21,
10089,18,126,4,0,
10089,18,156,4,0,
10089,18,157,4,0,
10089,18,164,4,0,
10089,18,182,1,1,2
10089,18,182,4,0,
10089,18,184,1,42,
10089,18,207,4,0,
10089,18,211,4,0,
10089,18,213,4,0,
10089,18,214,4,0,
10089,18,216,4,0,
10089,18,218,4,0,
10089,18,225,1,13,
10089,18,237,4,0,
10089,18,240,4,0,
10089,18,241,4,0,
10089,18,242,1,25,
10089,18,263,4,0,
10089,18,280,4,0,
10089,18,317,4,0,
10089,18,332,4,0,
10089,18,337,1,29,
10089,18,337,4,0,
10089,18,355,4,0,
10089,18,416,4,0,
10089,18,421,4,0,
10089,18,422,1,1,5
10089,18,424,1,1,4
10089,18,428,1,35,
10089,18,434,3,0,
10089,18,444,4,0,
10089,18,496,4,0,
10089,18,523,4,0,
10089,18,525,1,1,3
10089,18,525,4,0,
10089,18,590,4,0,
10089,18,693,4,0,
10090,16,14,4,0,
10090,16,15,4,0,
10090,16,31,1,1,1
10090,16,31,1,10,1
10090,16,41,1,16,1
10090,16,42,1,28,1
10090,16,63,4,0,
10090,16,76,4,0,
10090,16,92,4,0,
10090,16,97,1,31,1
10090,16,99,1,19,1
10090,16,104,4,0,
10090,16,116,1,13,1
10090,16,148,4,0,
10090,16,156,4,0,
10090,16,164,4,0,
10090,16,168,4,0,
10090,16,173,3,0,
10090,16,182,4,0,
10090,16,188,4,0,
10090,16,202,3,0,
10090,16,206,4,0,
10090,16,207,4,0,
10090,16,213,4,0,
10090,16,214,4,0,
10090,16,216,4,0,
10090,16,218,4,0,
10090,16,228,1,22,1
10090,16,237,4,0,
10090,16,241,4,0,
10090,16,249,4,0,
10090,16,263,4,0,
10090,16,280,4,0,
10090,16,282,3,0,
10090,16,283,1,40,1
10090,16,283,3,0,
10090,16,290,4,0,
10090,16,332,4,0,
10090,16,355,4,0,
10090,16,366,3,0,
10090,16,369,4,0,
10090,16,371,4,0,
10090,16,372,1,34,1
10090,16,390,1,25,1
10090,16,398,1,37,1
10090,16,398,4,0,
10090,16,404,4,0,
10090,16,416,4,0,
10090,16,450,3,0,
10090,16,474,4,0,
10090,16,496,4,0,
10090,16,512,4,0,
10090,16,522,4,0,
10090,16,527,3,0,
10090,16,529,3,0,
10090,16,565,1,45,1
10090,16,590,4,0,
10090,16,611,4,0,
10090,17,14,4,0,
10090,17,31,1,1,2
10090,17,31,1,11,
10090,17,41,1,0,
10090,17,41,1,1,1
10090,17,42,1,32,
10090,17,63,4,0,
10090,17,76,4,0,
10090,17,92,4,0,
10090,17,97,1,38,
10090,17,99,1,14,
10090,17,104,4,0,
10090,17,116,1,20,
10090,17,156,4,0,
10090,17,164,4,0,
10090,17,168,4,0,
10090,17,182,4,0,
10090,17,188,4,0,
10090,17,206,4,0,
10090,17,207,4,0,
10090,17,213,4,0,
10090,17,214,4,0,
10090,17,216,4,0,
10090,17,218,4,0,
10090,17,228,1,17,
10090,17,237,4,0,
10090,17,241,4,0,
10090,17,263,4,0,
10090,17,280,4,0,
10090,17,283,1,41,
10090,17,332,4,0,
10090,17,355,4,0,
10090,17,369,4,0,
10090,17,371,4,0,
10090,17,372,1,26,
10090,17,390,1,29,
10090,17,398,1,35,
10090,17,398,4,0,
10090,17,404,4,0,
10090,17,416,4,0,
10090,17,474,1,23,
10090,17,474,4,0,
10090,17,496,4,0,
10090,17,512,4,0,
10090,17,565,1,44,
10090,17,590,4,0,
10090,17,611,4,0,
10090,17,693,4,0,
10090,18,14,4,0,
10090,18,31,1,1,2
10090,18,31,1,11,
10090,18,41,1,0,
10090,18,41,1,1,1
10090,18,42,1,32,
10090,18,63,4,0,
10090,18,76,4,0,
10090,18,92,4,0,
10090,18,97,1,38,
10090,18,99,1,14,
10090,18,104,4,0,
10090,18,116,1,20,
10090,18,156,4,0,
10090,18,164,4,0,
10090,18,168,4,0,
10090,18,182,4,0,
10090,18,188,4,0,
10090,18,206,4,0,
10090,18,207,4,0,
10090,18,213,4,0,
10090,18,214,4,0,
10090,18,216,4,0,
10090,18,218,4,0,
10090,18,228,1,17,
10090,18,237,4,0,
10090,18,241,4,0,
10090,18,263,4,0,
10090,18,280,4,0,
10090,18,283,1,41,
10090,18,332,4,0,
10090,18,355,4,0,
10090,18,369,4,0,
10090,18,371,4,0,
10090,18,372,1,26,
10090,18,390,1,29,
10090,18,398,1,35,
10090,18,398,4,0,
10090,18,404,4,0,
10090,18,416,4,0,
10090,18,474,1,23,
10090,18,474,4,0,
10090,18,496,4,0,
10090,18,512,4,0,
10090,18,565,1,44,
10090,18,590,4,0,
10090,18,611,4,0,
10090,18,693,4,0,
10091,17,33,1,1,1
10091,17,38,1,31,
10091,17,39,1,1,2
10091,17,44,1,10,
10091,17,58,4,0,
10091,17,59,4,0,
10091,17,92,4,0,
10091,17,98,1,4,
10091,17,104,4,0,
10091,17,116,1,7,
10091,17,156,4,0,
10091,17,158,1,16,
10091,17,162,1,28,
10091,17,164,4,0,
10091,17,168,4,0,
10091,17,182,4,0,
10091,17,188,4,0,
10091,17,207,4,0,
10091,17,213,4,0,
10091,17,214,4,0,
10091,17,216,4,0,
10091,17,218,4,0,
10091,17,228,1,13,
10091,17,237,4,0,
10091,17,240,4,0,
10091,17,241,4,0,
10091,17,242,1,22,
10091,17,247,4,0,
10091,17,259,4,0,
10091,17,263,4,0,
10091,17,269,4,0,
10091,17,283,1,34,
10091,17,369,4,0,
10091,17,372,1,19,
10091,17,373,4,0,
10091,17,389,1,25,
10091,17,399,4,0,
10091,17,421,4,0,
10091,17,447,4,0,
10091,17,496,4,0,
10091,17,511,4,0,
10091,17,555,4,0,
10091,17,590,4,0,
10091,18,33,1,1,1
10091,18,38,1,31,
10091,18,39,1,1,2
10091,18,44,1,10,
10091,18,58,4,0,
10091,18,59,4,0,
10091,18,92,4,0,
10091,18,98,1,4,
10091,18,104,4,0,
10091,18,116,1,7,
10091,18,156,4,0,
10091,18,158,1,16,
10091,18,162,1,28,
10091,18,164,4,0,
10091,18,168,4,0,
10091,18,182,4,0,
10091,18,188,4,0,
10091,18,207,4,0,
10091,18,213,4,0,
10091,18,214,4,0,
10091,18,216,4,0,
10091,18,218,4,0,
10091,18,228,1,13,
10091,18,237,4,0,
10091,18,240,4,0,
10091,18,241,4,0,
10091,18,242,1,22,
10091,18,247,4,0,
10091,18,259,4,0,
10091,18,263,4,0,
10091,18,269,4,0,
10091,18,283,1,34,
10091,18,369,4,0,
10091,18,372,1,19,
10091,18,373,4,0,
10091,18,389,1,25,
10091,18,399,4,0,
10091,18,421,4,0,
10091,18,447,4,0,
10091,18,496,4,0,
10091,18,511,4,0,
10091,18,555,4,0,
10091,18,590,4,0,
10092,17,14,1,1,2
10092,17,14,4,0,
10092,17,33,1,1,3
10092,17,38,1,39,
10092,17,39,1,1,4
10092,17,44,1,10,
10092,17,46,4,0,
10092,17,58,4,0,
10092,17,59,4,0,
10092,17,63,4,0,
10092,17,92,4,0,
10092,17,98,1,1,5
10092,17,98,1,4,
10092,17,104,4,0,
10092,17,116,1,1,6
10092,17,116,1,7,
10092,17,156,4,0,
10092,17,158,1,16,
10092,17,162,1,34,
10092,17,164,4,0,
10092,17,168,4,0,
10092,17,182,4,0,
10092,17,184,1,0,
10092,17,184,1,1,1
10092,17,188,4,0,
10092,17,207,4,0,
10092,17,213,4,0,
10092,17,214,4,0,
10092,17,216,4,0,
10092,17,218,4,0,
10092,17,228,1,13,
10092,17,237,4,0,
10092,17,240,4,0,
10092,17,241,4,0,
10092,17,242,1,24,
10092,17,247,4,0,
10092,17,259,4,0,
10092,17,263,4,0,
10092,17,269,4,0,
10092,17,283,1,44,
10092,17,339,4,0,
10092,17,369,4,0,
10092,17,372,1,19,
10092,17,373,4,0,
10092,17,389,1,29,
10092,17,399,4,0,
10092,17,416,4,0,
10092,17,421,4,0,
10092,17,447,4,0,
10092,17,474,4,0,
10092,17,482,4,0,
10092,17,496,4,0,
10092,17,511,4,0,
10092,17,555,4,0,
10092,17,590,4,0,
10092,18,14,1,1,2
10092,18,14,4,0,
10092,18,33,1,1,3
10092,18,38,1,39,
10092,18,39,1,1,4
10092,18,44,1,10,
10092,18,46,4,0,
10092,18,58,4,0,
10092,18,59,4,0,
10092,18,63,4,0,
10092,18,92,4,0,
10092,18,98,1,1,5
10092,18,98,1,4,
10092,18,104,4,0,
10092,18,116,1,1,6
10092,18,116,1,7,
10092,18,156,4,0,
10092,18,158,1,16,
10092,18,162,1,34,
10092,18,164,4,0,
10092,18,168,4,0,
10092,18,182,4,0,
10092,18,184,1,0,
10092,18,184,1,1,1
10092,18,188,4,0,
10092,18,207,4,0,
10092,18,213,4,0,
10092,18,214,4,0,
10092,18,216,4,0,
10092,18,218,4,0,
10092,18,228,1,13,
10092,18,237,4,0,
10092,18,240,4,0,
10092,18,241,4,0,
10092,18,242,1,24,
10092,18,247,4,0,
10092,18,259,4,0,
10092,18,263,4,0,
10092,18,269,4,0,
10092,18,283,1,44,
10092,18,339,4,0,
10092,18,369,4,0,
10092,18,372,1,19,
10092,18,373,4,0,
10092,18,389,1,29,
10092,18,399,4,0,
10092,18,416,4,0,
10092,18,421,4,0,
10092,18,447,4,0,
10092,18,474,4,0,
10092,18,482,4,0,
10092,18,496,4,0,
10092,18,511,4,0,
10092,18,555,4,0,
10092,18,590,4,0,
10093,17,14,1,1,2
10093,17,14,4,0,
10093,17,33,1,1,3
10093,17,38,1,39,
10093,17,39,1,1,4
10093,17,44,1,10,
10093,17,46,4,0,
10093,17,58,4,0,
10093,17,59,4,0,
10093,17,63,4,0,
10093,17,92,4,0,
10093,17,98,1,1,5
10093,17,98,1,4,
10093,17,104,4,0,
10093,17,116,1,1,6
10093,17,116,1,7,
10093,17,156,4,0,
10093,17,158,1,16,
10093,17,162,1,34,
10093,17,164,4,0,
10093,17,168,4,0,
10093,17,182,4,0,
10093,17,184,1,0,
10093,17,184,1,1,1
10093,17,188,4,0,
10093,17,207,4,0,
10093,17,213,4,0,
10093,17,214,4,0,
10093,17,216,4,0,
10093,17,218,4,0,
10093,17,228,1,13,
10093,17,237,4,0,
10093,17,240,4,0,
10093,17,241,4,0,
10093,17,242,1,24,
10093,17,247,4,0,
10093,17,259,4,0,
10093,17,263,4,0,
10093,17,269,4,0,
10093,17,283,1,44,
10093,17,339,4,0,
10093,17,369,4,0,
10093,17,372,1,19,
10093,17,373,4,0,
10093,17,389,1,29,
10093,17,399,4,0,
10093,17,416,4,0,
10093,17,421,4,0,
10093,17,447,4,0,
10093,17,474,4,0,
10093,17,482,4,0,
10093,17,496,4,0,
10093,17,511,4,0,
10093,17,555,4,0,
10093,17,590,4,0,
10093,18,14,1,1,2
10093,18,14,4,0,
10093,18,33,1,1,3
10093,18,38,1,39,
10093,18,39,1,1,4
10093,18,44,1,10,
10093,18,46,4,0,
10093,18,58,4,0,
10093,18,59,4,0,
10093,18,63,4,0,
10093,18,92,4,0,
10093,18,98,1,1,5
10093,18,98,1,4,
10093,18,104,4,0,
10093,18,116,1,1,6
10093,18,116,1,7,
10093,18,156,4,0,
10093,18,158,1,16,
10093,18,162,1,34,
10093,18,164,4,0,
10093,18,168,4,0,
10093,18,182,4,0,
10093,18,184,1,0,
10093,18,184,1,1,1
10093,18,188,4,0,
10093,18,207,4,0,
10093,18,213,4,0,
10093,18,214,4,0,
10093,18,216,4,0,
10093,18,218,4,0,
10093,18,228,1,13,
10093,18,237,4,0,
10093,18,240,4,0,
10093,18,241,4,0,
10093,18,242,1,24,
10093,18,247,4,0,
10093,18,259,4,0,
10093,18,263,4,0,
10093,18,269,4,0,
10093,18,283,1,44,
10093,18,339,4,0,
10093,18,369,4,0,
10093,18,372,1,19,
10093,18,373,4,0,
10093,18,389,1,29,
10093,18,399,4,0,
10093,18,416,4,0,
10093,18,421,4,0,
10093,18,447,4,0,
10093,18,474,4,0,
10093,18,482,4,0,
10093,18,496,4,0,
10093,18,511,4,0,
10093,18,555,4,0,
10093,18,590,4,0,
10094,17,21,1,37,
10094,17,39,1,1,1
10094,17,45,1,5,
10094,17,84,1,1,2
10094,17,85,1,42,
10094,17,85,4,0,
10094,17,86,1,18,
10094,17,86,4,0,
10094,17,87,1,58,
10094,17,87,4,0,
10094,17,92,4,0,
10094,17,97,1,45,
10094,17,98,1,10,
10094,17,104,1,23,
10094,17,104,4,0,
10094,17,113,1,53,
10094,17,113,4,0,
10094,17,156,4,0,
10094,17,164,4,0,
10094,17,182,4,0,
10094,17,207,4,0,
10094,17,209,1,26,
10094,17,213,4,0,
10094,17,214,4,0,
10094,17,216,4,0,
10094,17,218,4,0,
10094,17,237,4,0,
10094,17,240,4,0,
10094,17,263,4,0,
10094,17,280,4,0,
10094,17,364,1,21,
10094,17,374,4,0,
10094,17,435,1,34,
10094,17,447,4,0,
10094,17,451,4,0,
10094,17,486,1,13,
10094,17,496,4,0,
10094,17,497,4,0,
10094,17,521,4,0,
10094,17,528,1,50,
10094,17,528,4,0,
10094,17,589,1,7,
10094,17,590,4,0,
10094,17,609,1,29,
10094,18,21,1,37,
10094,18,39,1,1,1
10094,18,45,1,5,
10094,18,84,1,1,2
10094,18,85,1,42,
10094,18,85,4,0,
10094,18,86,1,18,
10094,18,86,4,0,
10094,18,87,1,58,
10094,18,87,4,0,
10094,18,92,4,0,
10094,18,97,1,45,
10094,18,98,1,10,
10094,18,104,1,23,
10094,18,104,4,0,
10094,18,113,1,53,
10094,18,113,4,0,
10094,18,156,4,0,
10094,18,164,4,0,
10094,18,182,4,0,
10094,18,207,4,0,
10094,18,209,1,26,
10094,18,213,4,0,
10094,18,214,4,0,
10094,18,216,4,0,
10094,18,218,4,0,
10094,18,237,4,0,
10094,18,240,4,0,
10094,18,263,4,0,
10094,18,280,4,0,
10094,18,364,1,21,
10094,18,374,4,0,
10094,18,435,1,34,
10094,18,447,4,0,
10094,18,451,4,0,
10094,18,486,1,13,
10094,18,496,4,0,
10094,18,497,4,0,
10094,18,521,4,0,
10094,18,528,1,50,
10094,18,528,4,0,
10094,18,589,1,7,
10094,18,590,4,0,
10094,18,609,1,29,
10095,17,21,1,37,
10095,17,39,1,1,1
10095,17,45,1,5,
10095,17,84,1,1,2
10095,17,85,1,42,
10095,17,85,4,0,
10095,17,86,1,18,
10095,17,86,4,0,
10095,17,87,1,58,
10095,17,87,4,0,
10095,17,92,4,0,
10095,17,97,1,45,
10095,17,98,1,10,
10095,17,104,1,23,
10095,17,104,4,0,
10095,17,113,1,53,
10095,17,113,4,0,
10095,17,156,4,0,
10095,17,164,4,0,
10095,17,182,4,0,
10095,17,207,4,0,
10095,17,209,1,26,
10095,17,213,4,0,
10095,17,214,4,0,
10095,17,216,4,0,
10095,17,218,4,0,
10095,17,237,4,0,
10095,17,240,4,0,
10095,17,263,4,0,
10095,17,280,4,0,
10095,17,364,1,21,
10095,17,374,4,0,
10095,17,435,1,34,
10095,17,447,4,0,
10095,17,451,4,0,
10095,17,486,1,13,
10095,17,496,4,0,
10095,17,497,4,0,
10095,17,521,4,0,
10095,17,528,1,50,
10095,17,528,4,0,
10095,17,589,1,7,
10095,17,590,4,0,
10095,17,609,1,29,
10095,18,21,1,37,
10095,18,39,1,1,1
10095,18,45,1,5,
10095,18,84,1,1,2
10095,18,85,1,42,
10095,18,85,4,0,
10095,18,86,1,18,
10095,18,86,4,0,
10095,18,87,1,58,
10095,18,87,4,0,
10095,18,92,4,0,
10095,18,97,1,45,
10095,18,98,1,10,
10095,18,104,1,23,
10095,18,104,4,0,
10095,18,113,1,53,
10095,18,113,4,0,
10095,18,156,4,0,
10095,18,164,4,0,
10095,18,182,4,0,
10095,18,207,4,0,
10095,18,209,1,26,
10095,18,213,4,0,
10095,18,214,4,0,
10095,18,216,4,0,
10095,18,218,4,0,
10095,18,237,4,0,
10095,18,240,4,0,
10095,18,263,4,0,
10095,18,280,4,0,
10095,18,364,1,21,
10095,18,374,4,0,
10095,18,435,1,34,
10095,18,447,4,0,
10095,18,451,4,0,
10095,18,486,1,13,
10095,18,496,4,0,
10095,18,497,4,0,
10095,18,521,4,0,
10095,18,528,1,50,
10095,18,528,4,0,
10095,18,589,1,7,
10095,18,590,4,0,
10095,18,609,1,29,
10096,17,21,1,37,
10096,17,39,1,1,1
10096,17,45,1,5,
10096,17,84,1,1,2
10096,17,85,1,42,
10096,17,85,4,0,
10096,17,86,1,18,
10096,17,86,4,0,
10096,17,87,1,58,
10096,17,87,4,0,
10096,17,92,4,0,
10096,17,97,1,45,
10096,17,98,1,10,
10096,17,104,1,23,
10096,17,104,4,0,
10096,17,113,1,53,
10096,17,113,4,0,
10096,17,156,4,0,
10096,17,164,4,0,
10096,17,182,4,0,
10096,17,207,4,0,
10096,17,209,1,26,
10096,17,213,4,0,
10096,17,214,4,0,
10096,17,216,4,0,
10096,17,218,4,0,
10096,17,237,4,0,
10096,17,240,4,0,
10096,17,263,4,0,
10096,17,280,4,0,
10096,17,364,1,21,
10096,17,374,4,0,
10096,17,435,1,34,
10096,17,447,4,0,
10096,17,451,4,0,
10096,17,486,1,13,
10096,17,496,4,0,
10096,17,497,4,0,
10096,17,521,4,0,
10096,17,528,1,50,
10096,17,528,4,0,
10096,17,589,1,7,
10096,17,590,4,0,
10096,17,609,1,29,
10096,18,21,1,37,
10096,18,39,1,1,1
10096,18,45,1,5,
10096,18,84,1,1,2
10096,18,85,1,42,
10096,18,85,4,0,
10096,18,86,1,18,
10096,18,86,4,0,
10096,18,87,1,58,
10096,18,87,4,0,
10096,18,92,4,0,
10096,18,97,1,45,
10096,18,98,1,10,
10096,18,104,1,23,
10096,18,104,4,0,
10096,18,113,1,53,
10096,18,113,4,0,
10096,18,156,4,0,
10096,18,164,4,0,
10096,18,182,4,0,
10096,18,207,4,0,
10096,18,209,1,26,
10096,18,213,4,0,
10096,18,214,4,0,
10096,18,216,4,0,
10096,18,218,4,0,
10096,18,237,4,0,
10096,18,240,4,0,
10096,18,263,4,0,
10096,18,280,4,0,
10096,18,364,1,21,
10096,18,374,4,0,
10096,18,435,1,34,
10096,18,447,4,0,
10096,18,451,4,0,
10096,18,486,1,13,
10096,18,496,4,0,
10096,18,497,4,0,
10096,18,521,4,0,
10096,18,528,1,50,
10096,18,528,4,0,
10096,18,589,1,7,
10096,18,590,4,0,
10096,18,609,1,29,
10097,17,21,1,37,
10097,17,39,1,1,1
10097,17,45,1,5,
10097,17,84,1,1,2
10097,17,85,1,42,
10097,17,85,4,0,
10097,17,86,1,18,
10097,17,86,4,0,
10097,17,87,1,58,
10097,17,87,4,0,
10097,17,92,4,0,
10097,17,97,1,45,
10097,17,98,1,10,
10097,17,104,1,23,
10097,17,104,4,0,
10097,17,113,1,53,
10097,17,113,4,0,
10097,17,156,4,0,
10097,17,164,4,0,
10097,17,182,4,0,
10097,17,207,4,0,
10097,17,209,1,26,
10097,17,213,4,0,
10097,17,214,4,0,
10097,17,216,4,0,
10097,17,218,4,0,
10097,17,237,4,0,
10097,17,240,4,0,
10097,17,263,4,0,
10097,17,280,4,0,
10097,17,364,1,21,
10097,17,374,4,0,
10097,17,435,1,34,
10097,17,447,4,0,
10097,17,451,4,0,
10097,17,486,1,13,
10097,17,496,4,0,
10097,17,497,4,0,
10097,17,521,4,0,
10097,17,528,1,50,
10097,17,528,4,0,
10097,17,589,1,7,
10097,17,590,4,0,
10097,17,609,1,29,
10097,18,21,1,37,
10097,18,39,1,1,1
10097,18,45,1,5,
10097,18,84,1,1,2
10097,18,85,1,42,
10097,18,85,4,0,
10097,18,86,1,18,
10097,18,86,4,0,
10097,18,87,1,58,
10097,18,87,4,0,
10097,18,92,4,0,
10097,18,97,1,45,
10097,18,98,1,10,
10097,18,104,1,23,
10097,18,104,4,0,
10097,18,113,1,53,
10097,18,113,4,0,
10097,18,156,4,0,
10097,18,164,4,0,
10097,18,182,4,0,
10097,18,207,4,0,
10097,18,209,1,26,
10097,18,213,4,0,
10097,18,214,4,0,
10097,18,216,4,0,
10097,18,218,4,0,
10097,18,237,4,0,
10097,18,240,4,0,
10097,18,263,4,0,
10097,18,280,4,0,
10097,18,364,1,21,
10097,18,374,4,0,
10097,18,435,1,34,
10097,18,447,4,0,
10097,18,451,4,0,
10097,18,486,1,13,
10097,18,496,4,0,
10097,18,497,4,0,
10097,18,521,4,0,
10097,18,528,1,50,
10097,18,528,4,0,
10097,18,589,1,7,
10097,18,590,4,0,
10097,18,609,1,29,
10098,17,21,1,37,
10098,17,39,1,1,1
10098,17,45,1,5,
10098,17,84,1,1,2
10098,17,85,1,42,
10098,17,85,4,0,
10098,17,86,1,18,
10098,17,86,4,0,
10098,17,87,1,58,
10098,17,87,4,0,
10098,17,92,4,0,
10098,17,97,1,45,
10098,17,98,1,10,
10098,17,104,1,23,
10098,17,104,4,0,
10098,17,113,1,53,
10098,17,113,4,0,
10098,17,156,4,0,
10098,17,164,4,0,
10098,17,182,4,0,
10098,17,207,4,0,
10098,17,209,1,26,
10098,17,213,4,0,
10098,17,214,4,0,
10098,17,216,4,0,
10098,17,218,4,0,
10098,17,237,4,0,
10098,17,240,4,0,
10098,17,263,4,0,
10098,17,280,4,0,
10098,17,364,1,21,
10098,17,374,4,0,
10098,17,435,1,34,
10098,17,447,4,0,
10098,17,451,4,0,
10098,17,486,1,13,
10098,17,496,4,0,
10098,17,497,4,0,
10098,17,521,4,0,
10098,17,528,1,50,
10098,17,528,4,0,
10098,17,589,1,7,
10098,17,590,4,0,
10098,17,609,1,29,
10098,18,21,1,37,
10098,18,39,1,1,1
10098,18,45,1,5,
10098,18,84,1,1,2
10098,18,85,1,42,
10098,18,85,4,0,
10098,18,86,1,18,
10098,18,86,4,0,
10098,18,87,1,58,
10098,18,87,4,0,
10098,18,92,4,0,
10098,18,97,1,45,
10098,18,98,1,10,
10098,18,104,1,23,
10098,18,104,4,0,
10098,18,113,1,53,
10098,18,113,4,0,
10098,18,156,4,0,
10098,18,164,4,0,
10098,18,182,4,0,
10098,18,207,4,0,
10098,18,209,1,26,
10098,18,213,4,0,
10098,18,214,4,0,
10098,18,216,4,0,
10098,18,218,4,0,
10098,18,237,4,0,
10098,18,240,4,0,
10098,18,263,4,0,
10098,18,280,4,0,
10098,18,364,1,21,
10098,18,374,4,0,
10098,18,435,1,34,
10098,18,447,4,0,
10098,18,451,4,0,
10098,18,486,1,13,
10098,18,496,4,0,
10098,18,497,4,0,
10098,18,521,4,0,
10098,18,528,1,50,
10098,18,528,4,0,
10098,18,589,1,7,
10098,18,590,4,0,
10098,18,609,1,29,
10099,17,21,1,37,
10099,17,39,1,1,1
10099,17,45,1,5,
10099,17,84,1,1,2
10099,17,85,1,42,
10099,17,85,4,0,
10099,17,86,1,18,
10099,17,86,4,0,
10099,17,87,1,58,
10099,17,87,4,0,
10099,17,92,4,0,
10099,17,97,1,45,
10099,17,98,1,10,
10099,17,104,1,23,
10099,17,104,4,0,
10099,17,113,1,53,
10099,17,113,4,0,
10099,17,156,4,0,
10099,17,164,4,0,
10099,17,182,4,0,
10099,17,207,4,0,
10099,17,209,1,26,
10099,17,213,4,0,
10099,17,214,4,0,
10099,17,216,4,0,
10099,17,218,4,0,
10099,17,237,4,0,
10099,17,240,4,0,
10099,17,263,4,0,
10099,17,280,4,0,
10099,17,364,1,21,
10099,17,374,4,0,
10099,17,435,1,34,
10099,17,447,4,0,
10099,17,451,4,0,
10099,17,486,1,13,
10099,17,496,4,0,
10099,17,497,4,0,
10099,17,521,4,0,
10099,17,528,1,50,
10099,17,528,4,0,
10099,17,589,1,7,
10099,17,590,4,0,
10099,17,609,1,29,
10099,18,21,1,37,
10099,18,39,1,1,1
10099,18,45,1,5,
10099,18,84,1,1,2
10099,18,85,1,42,
10099,18,85,4,0,
10099,18,86,1,18,
10099,18,86,4,0,
10099,18,87,1,58,
10099,18,87,4,0,
10099,18,92,4,0,
10099,18,97,1,45,
10099,18,98,1,10,
10099,18,104,1,23,
10099,18,104,4,0,
10099,18,113,1,53,
10099,18,113,4,0,
10099,18,156,4,0,
10099,18,164,4,0,
10099,18,182,4,0,
10099,18,207,4,0,
10099,18,209,1,26,
10099,18,213,4,0,
10099,18,214,4,0,
10099,18,216,4,0,
10099,18,218,4,0,
10099,18,237,4,0,
10099,18,240,4,0,
10099,18,263,4,0,
10099,18,280,4,0,
10099,18,364,1,21,
10099,18,374,4,0,
10099,18,435,1,34,
10099,18,447,4,0,
10099,18,451,4,0,
10099,18,486,1,13,
10099,18,496,4,0,
10099,18,497,4,0,
10099,18,521,4,0,
10099,18,528,1,50,
10099,18,528,4,0,
10099,18,589,1,7,
10099,18,590,4,0,
10099,18,609,1,29,
10100,17,39,1,1,4
10100,17,63,4,0,
10100,17,84,1,1,3
10100,17,85,1,1,6
10100,17,85,4,0,
10100,17,86,4,0,
10100,17,87,4,0,
10100,17,92,4,0,
10100,17,94,1,0,
10100,17,94,1,1,1
10100,17,94,4,0,
10100,17,98,1,1,5
10100,17,104,4,0,
10100,17,113,4,0,
10100,17,115,4,0,
10100,17,156,4,0,
10100,17,164,4,0,
10100,17,168,4,0,
10100,17,182,4,0,
10100,17,207,4,0,
10100,17,213,4,0,
10100,17,214,4,0,
10100,17,216,4,0,
10100,17,218,4,0,
10100,17,219,4,0,
10100,17,237,4,0,
10100,17,240,4,0,
10100,17,263,4,0,
10100,17,280,4,0,
10100,17,347,4,0,
10100,17,374,4,0,
10100,17,411,4,0,
10100,17,416,4,0,
10100,17,447,4,0,
10100,17,451,4,0,
10100,17,473,4,0,
10100,17,496,4,0,
10100,17,497,4,0,
10100,17,521,4,0,
10100,17,528,4,0,
10100,17,590,4,0,
10100,17,683,1,1,2
10100,18,39,1,1,4
10100,18,63,4,0,
10100,18,84,1,1,3
10100,18,85,1,1,6
10100,18,85,4,0,
10100,18,86,4,0,
10100,18,87,4,0,
10100,18,92,4,0,
10100,18,94,1,0,
10100,18,94,1,1,1
10100,18,94,4,0,
10100,18,98,1,1,5
10100,18,104,4,0,
10100,18,113,4,0,
10100,18,115,4,0,
10100,18,156,4,0,
10100,18,164,4,0,
10100,18,168,4,0,
10100,18,182,4,0,
10100,18,207,4,0,
10100,18,213,4,0,
10100,18,214,4,0,
10100,18,216,4,0,
10100,18,218,4,0,
10100,18,219,4,0,
10100,18,237,4,0,
10100,18,240,4,0,
10100,18,263,4,0,
10100,18,280,4,0,
10100,18,347,4,0,
10100,18,374,4,0,
10100,18,411,4,0,
10100,18,416,4,0,
10100,18,447,4,0,
10100,18,451,4,0,
10100,18,473,4,0,
10100,18,496,4,0,
10100,18,497,4,0,
10100,18,521,4,0,
10100,18,528,4,0,
10100,18,590,4,0,
10100,18,683,1,1,2
10101,17,10,1,1,1
10101,17,14,1,38,
10101,17,14,4,0,
10101,17,59,1,46,
10101,17,59,4,0,
10101,17,89,4,0,
10101,17,92,4,0,
10101,17,104,4,0,
10101,17,111,1,1,2
10101,17,117,1,3,
10101,17,129,1,17,
10101,17,141,4,0,
10101,17,154,1,20,
10101,17,156,4,0,
10101,17,157,4,0,
10101,17,163,1,26,
10101,17,164,4,0,
10101,17,168,4,0,
10101,17,181,1,5,
10101,17,182,4,0,
10101,17,207,4,0,
10101,17,210,1,11,
10101,17,213,4,0,
10101,17,214,4,0,
10101,17,216,4,0,
10101,17,218,4,0,
10101,17,219,4,0,
10101,17,229,1,9,
10101,17,232,1,14,
10101,17,237,4,0,
10101,17,241,4,0,
10101,17,258,1,42,
10101,17,258,4,0,
10101,17,263,4,0,
10101,17,280,4,0,
10101,17,301,1,7,
10101,17,332,4,0,
10101,17,334,1,23,
10101,17,360,1,34,
10101,17,360,4,0,
10101,17,374,4,0,
10101,17,398,4,0,
10101,17,404,4,0,
10101,17,421,4,0,
10101,17,442,1,30,
10101,17,496,4,0,
10101,17,523,4,0,
10101,17,524,4,0,
10101,17,526,4,0,
10101,17,590,4,0,
10101,17,694,4,0,
10101,18,10,1,1,1
10101,18,14,1,38,
10101,18,14,4,0,
10101,18,59,1,46,
10101,18,59,4,0,
10101,18,89,4,0,
10101,18,92,4,0,
10101,18,104,4,0,
10101,18,111,1,1,2
10101,18,117,1,3,
10101,18,129,1,17,
10101,18,141,4,0,
10101,18,154,1,20,
10101,18,156,4,0,
10101,18,157,4,0,
10101,18,163,1,26,
10101,18,164,4,0,
10101,18,168,4,0,
10101,18,181,1,5,
10101,18,182,4,0,
10101,18,207,4,0,
10101,18,210,1,11,
10101,18,213,4,0,
10101,18,214,4,0,
10101,18,216,4,0,
10101,18,218,4,0,
10101,18,219,4,0,
10101,18,229,1,9,
10101,18,232,1,14,
10101,18,237,4,0,
10101,18,241,4,0,
10101,18,258,1,42,
10101,18,258,4,0,
10101,18,263,4,0,
10101,18,280,4,0,
10101,18,301,1,7,
10101,18,332,4,0,
10101,18,334,1,23,
10101,18,360,1,34,
10101,18,360,4,0,
10101,18,374,4,0,
10101,18,398,4,0,
10101,18,404,4,0,
10101,18,421,4,0,
10101,18,442,1,30,
10101,18,496,4,0,
10101,18,523,4,0,
10101,18,524,4,0,
10101,18,526,4,0,
10101,18,590,4,0,
10101,18,694,4,0,
10102,17,14,4,0,
10102,17,59,4,0,
10102,17,63,4,0,
10102,17,89,4,0,
10102,17,92,4,0,
10102,17,104,4,0,
10102,17,111,1,1,5
10102,17,141,4,0,
10102,17,156,4,0,
10102,17,157,4,0,
10102,17,163,1,1,4
10102,17,164,4,0,
10102,17,168,4,0,
10102,17,182,4,0,
10102,17,207,4,0,
10102,17,213,4,0,
10102,17,214,4,0,
10102,17,216,4,0,
10102,17,218,4,0,
10102,17,219,4,0,
10102,17,232,1,1,7
10102,17,237,4,0,
10102,17,241,4,0,
10102,17,258,4,0,
10102,17,263,4,0,
10102,17,280,4,0,
10102,17,301,1,1,6
10102,17,332,4,0,
10102,17,333,1,0,
10102,17,333,1,1,1
10102,17,360,4,0,
10102,17,368,1,1,2
10102,17,374,4,0,
10102,17,398,4,0,
10102,17,404,4,0,
10102,17,411,4,0,
10102,17,416,4,0,
10102,17,421,4,0,
10102,17,496,4,0,
10102,17,523,4,0,
10102,17,524,4,0,
10102,17,526,4,0,
10102,17,556,1,1,3
10102,17,590,4,0,
10102,17,694,4,0,
10102,18,14,4,0,
10102,18,59,4,0,
10102,18,63,4,0,
10102,18,89,4,0,
10102,18,92,4,0,
10102,18,104,4,0,
10102,18,111,1,1,5
10102,18,141,4,0,
10102,18,156,4,0,
10102,18,157,4,0,
10102,18,163,1,1,4
10102,18,164,4,0,
10102,18,168,4,0,
10102,18,182,4,0,
10102,18,207,4,0,
10102,18,213,4,0,
10102,18,214,4,0,
10102,18,216,4,0,
10102,18,218,4,0,
10102,18,219,4,0,
10102,18,232,1,1,7
10102,18,237,4,0,
10102,18,241,4,0,
10102,18,258,4,0,
10102,18,263,4,0,
10102,18,280,4,0,
10102,18,301,1,1,6
10102,18,332,4,0,
10102,18,333,1,0,
10102,18,333,1,1,1
10102,18,360,4,0,
10102,18,368,1,1,2
10102,18,374,4,0,
10102,18,398,4,0,
10102,18,404,4,0,
10102,18,411,4,0,
10102,18,416,4,0,
10102,18,421,4,0,
10102,18,496,4,0,
10102,18,523,4,0,
10102,18,524,4,0,
10102,18,526,4,0,
10102,18,556,1,1,3
10102,18,590,4,0,
10102,18,694,4,0,
10103,17,39,1,4,
10103,17,46,1,7,
10103,17,46,4,0,
10103,17,54,1,20,
10103,17,58,1,36,
10103,17,58,4,0,
10103,17,59,1,42,
10103,17,59,4,0,
10103,17,62,1,28,
10103,17,92,4,0,
10103,17,104,4,0,
10103,17,109,1,12,
10103,17,156,4,0,
10103,17,164,4,0,
10103,17,181,1,1,
10103,17,182,4,0,
10103,17,185,1,23,
10103,17,196,1,15,
10103,17,207,4,0,
10103,17,213,4,0,
10103,17,214,4,0,
10103,17,216,4,0,
10103,17,218,4,0,
10103,17,219,1,34,
10103,17,219,4,0,
10103,17,237,4,0,
10103,17,240,4,0,
10103,17,244,4,0,
10103,17,258,4,0,
10103,17,263,4,0,
10103,17,286,1,39,
10103,17,288,1,44,
10103,17,326,1,31,
10103,17,329,1,50,
10103,17,371,1,18,
10103,17,371,4,0,
10103,17,399,4,0,
10103,17,420,1,10,
10103,17,445,1,47,
10103,17,496,4,0,
10103,17,506,1,26,
10103,17,524,4,0,
10103,17,590,4,0,
10103,17,608,1,9,
10103,17,694,4,0,
10103,18,39,1,4,
10103,18,46,1,7,
10103,18,46,4,0,
10103,18,54,1,20,
10103,18,58,1,36,
10103,18,58,4,0,
10103,18,59,1,42,
10103,18,59,4,0,
10103,18,62,1,28,
10103,18,92,4,0,
10103,18,104,4,0,
10103,18,109,1,12,
10103,18,156,4,0,
10103,18,164,4,0,
10103,18,181,1,1,
10103,18,182,4,0,
10103,18,185,1,23,
10103,18,196,1,15,
10103,18,207,4,0,
10103,18,213,4,0,
10103,18,214,4,0,
10103,18,216,4,0,
10103,18,218,4,0,
10103,18,219,1,34,
10103,18,219,4,0,
10103,18,237,4,0,
10103,18,240,4,0,
10103,18,244,4,0,
10103,18,258,4,0,
10103,18,263,4,0,
10103,18,286,1,39,
10103,18,288,1,44,
10103,18,326,1,31,
10103,18,329,1,50,
10103,18,371,1,18,
10103,18,371,4,0,
10103,18,399,4,0,
10103,18,420,1,10,
10103,18,445,1,47,
10103,18,496,4,0,
10103,18,506,1,26,
10103,18,524,4,0,
10103,18,590,4,0,
10103,18,608,1,9,
10103,18,694,4,0,
10104,17,46,4,0,
10104,17,58,1,1,4
10104,17,58,4,0,
10104,17,59,4,0,
10104,17,63,4,0,
10104,17,92,4,0,
10104,17,104,4,0,
10104,17,109,1,1,6
10104,17,138,4,0,
10104,17,156,4,0,
10104,17,164,4,0,
10104,17,182,4,0,
10104,17,207,4,0,
10104,17,213,4,0,
10104,17,214,4,0,
10104,17,216,4,0,
10104,17,218,4,0,
10104,17,219,1,1,7
10104,17,219,4,0,
10104,17,237,4,0,
10104,17,240,4,0,
10104,17,244,4,0,
10104,17,258,4,0,
10104,17,263,4,0,
10104,17,286,1,1,2
10104,17,347,4,0,
10104,17,371,4,0,
10104,17,399,4,0,
10104,17,416,4,0,
10104,17,417,1,1,3
10104,17,420,1,1,5
10104,17,473,4,0,
10104,17,496,4,0,
10104,17,524,4,0,
10104,17,590,4,0,
10104,17,605,1,0,
10104,17,605,1,1,1
10104,17,605,4,0,
10104,17,694,4,0,
10104,18,46,4,0,
10104,18,58,1,1,4
10104,18,58,4,0,
10104,18,59,4,0,
10104,18,63,4,0,
10104,18,92,4,0,
10104,18,104,4,0,
10104,18,109,1,1,6
10104,18,138,4,0,
10104,18,156,4,0,
10104,18,164,4,0,
10104,18,182,4,0,
10104,18,207,4,0,
10104,18,213,4,0,
10104,18,214,4,0,
10104,18,216,4,0,
10104,18,218,4,0,
10104,18,219,1,1,7
10104,18,219,4,0,
10104,18,237,4,0,
10104,18,240,4,0,
10104,18,244,4,0,
10104,18,258,4,0,
10104,18,263,4,0,
10104,18,286,1,1,2
10104,18,347,4,0,
10104,18,371,4,0,
10104,18,399,4,0,
10104,18,416,4,0,
10104,18,417,1,1,3
10104,18,420,1,1,5
10104,18,473,4,0,
10104,18,496,4,0,
10104,18,524,4,0,
10104,18,590,4,0,
10104,18,605,1,0,
10104,18,605,1,1,1
10104,18,605,4,0,
10104,18,694,4,0,
10105,17,28,1,1,1
10105,17,45,1,4,
10105,17,89,1,39,
10105,17,89,4,0,
10105,17,90,1,43,
10105,17,91,1,31,
10105,17,92,4,0,
10105,17,104,4,0,
10105,17,156,4,0,
10105,17,157,4,0,
10105,17,164,4,0,
10105,17,168,4,0,
10105,17,182,4,0,
10105,17,188,4,0,
10105,17,189,1,10,
10105,17,201,4,0,
10105,17,207,4,0,
10105,17,213,4,0,
10105,17,214,4,0,
10105,17,216,4,0,
10105,17,218,4,0,
10105,17,222,1,14,
10105,17,232,1,1,2
10105,17,237,4,0,
10105,17,241,4,0,
10105,17,263,4,0,
10105,17,310,1,7,
10105,17,317,4,0,
10105,17,332,4,0,
10105,17,389,1,22,
10105,17,414,1,28,
10105,17,421,4,0,
10105,17,426,1,25,
10105,17,430,4,0,
10105,17,442,1,35,
10105,17,496,4,0,
10105,17,497,4,0,
10105,17,523,1,18,
10105,17,523,4,0,
10105,17,526,4,0,
10105,17,590,4,0,
10105,18,28,1,1,1
10105,18,45,1,4,
10105,18,89,1,39,
10105,18,89,4,0,
10105,18,90,1,43,
10105,18,91,1,31,
10105,18,92,4,0,
10105,18,104,4,0,
10105,18,156,4,0,
10105,18,157,4,0,
10105,18,164,4,0,
10105,18,168,4,0,
10105,18,182,4,0,
10105,18,188,4,0,
10105,18,189,1,10,
10105,18,201,4,0,
10105,18,207,4,0,
10105,18,213,4,0,
10105,18,214,4,0,
10105,18,216,4,0,
10105,18,218,4,0,
10105,18,222,1,14,
10105,18,232,1,1,2
10105,18,237,4,0,
10105,18,241,4,0,
10105,18,263,4,0,
10105,18,310,1,7,
10105,18,317,4,0,
10105,18,332,4,0,
10105,18,389,1,22,
10105,18,414,1,28,
10105,18,421,4,0,
10105,18,426,1,25,
10105,18,430,4,0,
10105,18,442,1,35,
10105,18,496,4,0,
10105,18,497,4,0,
10105,18,523,1,18,
10105,18,523,4,0,
10105,18,526,4,0,
10105,18,590,4,0,
10106,17,28,1,1,5
10106,17,45,1,1,7
10106,17,45,1,4,
10106,17,63,4,0,
10106,17,89,1,47,
10106,17,89,4,0,
10106,17,90,1,53,
10106,17,91,1,35,
10106,17,92,4,0,
10106,17,104,4,0,
10106,17,156,4,0,
10106,17,157,4,0,
10106,17,161,1,1,4
10106,17,164,4,0,
10106,17,168,4,0,
10106,17,182,4,0,
10106,17,188,4,0,
10106,17,189,1,10,
10106,17,201,4,0,
10106,17,207,4,0,
10106,17,213,4,0,
10106,17,214,4,0,
10106,17,216,4,0,
10106,17,218,4,0,
10106,17,222,1,14,
10106,17,232,1,1,6
10106,17,237,4,0,
10106,17,241,4,0,
10106,17,263,4,0,
10106,17,310,1,7,
10106,17,317,4,0,
10106,17,328,1,0,
10106,17,328,1,1,1
10106,17,332,4,0,
10106,17,389,1,22,
10106,17,400,1,1,3
10106,17,414,1,30,
10106,17,416,4,0,
10106,17,421,4,0,
10106,17,426,1,25,
10106,17,430,4,0,
10106,17,442,1,41,
10106,17,444,4,0,
10106,17,482,4,0,
10106,17,496,4,0,
10106,17,497,4,0,
10106,17,523,1,18,
10106,17,523,4,0,
10106,17,526,4,0,
10106,17,563,1,1,2
10106,17,590,4,0,
10106,18,28,1,1,5
10106,18,45,1,1,7
10106,18,45,1,4,
10106,18,63,4,0,
10106,18,89,1,47,
10106,18,89,4,0,
10106,18,90,1,53,
10106,18,91,1,35,
10106,18,92,4,0,
10106,18,104,4,0,
10106,18,156,4,0,
10106,18,157,4,0,
10106,18,161,1,1,4
10106,18,164,4,0,
10106,18,168,4,0,
10106,18,182,4,0,
10106,18,188,4,0,
10106,18,189,1,10,
10106,18,201,4,0,
10106,18,207,4,0,
10106,18,213,4,0,
10106,18,214,4,0,
10106,18,216,4,0,
10106,18,218,4,0,
10106,18,222,1,14,
10106,18,232,1,1,6
10106,18,237,4,0,
10106,18,241,4,0,
10106,18,263,4,0,
10106,18,310,1,7,
10106,18,317,4,0,
10106,18,328,1,0,
10106,18,328,1,1,1
10106,18,332,4,0,
10106,18,389,1,22,
10106,18,400,1,1,3
10106,18,414,1,30,
10106,18,416,4,0,
10106,18,421,4,0,
10106,18,426,1,25,
10106,18,430,4,0,
10106,18,442,1,41,
10106,18,444,4,0,
10106,18,482,4,0,
10106,18,496,4,0,
10106,18,497,4,0,
10106,18,523,1,18,
10106,18,523,4,0,
10106,18,526,4,0,
10106,18,563,1,1,2
10106,18,590,4,0,
10107,17,6,1,30,
10107,17,10,1,1,1
10107,17,44,1,6,
10107,17,45,1,1,2
10107,17,85,4,0,
10107,17,87,4,0,
10107,17,92,4,0,
10107,17,103,1,17,
10107,17,104,4,0,
10107,17,138,4,0,
10107,17,154,1,14,
10107,17,156,4,0,
10107,17,163,1,33,
10107,17,164,4,0,
10107,17,168,4,0,
10107,17,182,4,0,
10107,17,185,1,22,
10107,17,207,4,0,
10107,17,213,4,0,
10107,17,214,4,0,
10107,17,216,4,0,
10107,17,218,4,0,
10107,17,237,4,0,
10107,17,240,4,0,
10107,17,241,4,0,
10107,17,244,4,0,
10107,17,247,4,0,
10107,17,252,1,9,
10107,17,259,4,0,
10107,17,263,4,0,
10107,17,269,1,25,
10107,17,269,4,0,
10107,17,332,4,0,
10107,17,364,1,50,
10107,17,369,4,0,
10107,17,371,4,0,
10107,17,372,1,41,
10107,17,373,4,0,
10107,17,399,1,55,
10107,17,399,4,0,
10107,17,400,1,49,
10107,17,417,1,38,
10107,17,421,4,0,
10107,17,445,1,46,
10107,17,496,4,0,
10107,17,497,4,0,
10107,17,511,4,0,
10107,17,526,4,0,
10107,17,590,4,0,
10107,18,6,1,30,
10107,18,10,1,1,1
10107,18,44,1,6,
10107,18,45,1,1,2
10107,18,85,4,0,
10107,18,87,4,0,
10107,18,92,4,0,
10107,18,103,1,17,
10107,18,104,4,0,
10107,18,138,4,0,
10107,18,154,1,14,
10107,18,156,4,0,
10107,18,163,1,33,
10107,18,164,4,0,
10107,18,168,4,0,
10107,18,182,4,0,
10107,18,185,1,22,
10107,18,207,4,0,
10107,18,213,4,0,
10107,18,214,4,0,
10107,18,216,4,0,
10107,18,218,4,0,
10107,18,237,4,0,
10107,18,240,4,0,
10107,18,241,4,0,
10107,18,244,4,0,
10107,18,247,4,0,
10107,18,252,1,9,
10107,18,259,4,0,
10107,18,263,4,0,
10107,18,269,1,25,
10107,18,269,4,0,
10107,18,332,4,0,
10107,18,364,1,50,
10107,18,369,4,0,
10107,18,371,4,0,
10107,18,372,1,41,
10107,18,373,4,0,
10107,18,399,1,55,
10107,18,399,4,0,
10107,18,400,1,49,
10107,18,417,1,38,
10107,18,421,4,0,
10107,18,445,1,46,
10107,18,496,4,0,
10107,18,497,4,0,
10107,18,511,4,0,
10107,18,526,4,0,
10107,18,590,4,0,
10108,17,10,1,1,5
10108,17,44,1,1,7
10108,17,44,1,6,
10108,17,45,1,1,6
10108,17,46,4,0,
10108,17,63,4,0,
10108,17,85,4,0,
10108,17,87,4,0,
10108,17,92,4,0,
10108,17,103,1,17,
10108,17,104,4,0,
10108,17,129,1,0,
10108,17,129,1,1,1
10108,17,138,4,0,
10108,17,154,1,14,
10108,17,156,4,0,
10108,17,163,1,37,
10108,17,164,4,0,
10108,17,168,4,0,
10108,17,182,4,0,
10108,17,185,1,22,
10108,17,207,4,0,
10108,17,213,4,0,
10108,17,214,4,0,
10108,17,216,4,0,
10108,17,218,4,0,
10108,17,237,4,0,
10108,17,240,4,0,
10108,17,241,4,0,
10108,17,244,4,0,
10108,17,247,4,0,
10108,17,252,1,1,8
10108,17,252,1,9,
10108,17,259,4,0,
10108,17,263,4,0,
10108,17,269,1,25,
10108,17,269,4,0,
10108,17,332,4,0,
10108,17,364,1,65,
10108,17,369,4,0,
10108,17,371,4,0,
10108,17,372,1,49,
10108,17,373,4,0,
10108,17,399,1,69,
10108,17,399,4,0,
10108,17,400,1,61,
10108,17,408,1,32,
10108,17,415,1,1,4
10108,17,416,4,0,
10108,17,417,1,44,
10108,17,421,4,0,
10108,17,445,1,56,
10108,17,496,4,0,
10108,17,497,4,0,
10108,17,511,1,1,2
10108,17,511,4,0,
10108,17,526,4,0,
10108,17,555,4,0,
10108,17,583,1,1,3
10108,17,590,4,0,
10108,18,10,1,1,5
10108,18,44,1,1,7
10108,18,44,1,6,
10108,18,45,1,1,6
10108,18,46,4,0,
10108,18,63,4,0,
10108,18,85,4,0,
10108,18,87,4,0,
10108,18,92,4,0,
10108,18,103,1,17,
10108,18,104,4,0,
10108,18,129,1,0,
10108,18,129,1,1,1
10108,18,138,4,0,
10108,18,154,1,14,
10108,18,156,4,0,
10108,18,163,1,37,
10108,18,164,4,0,
10108,18,168,4,0,
10108,18,182,4,0,
10108,18,185,1,22,
10108,18,207,4,0,
10108,18,213,4,0,
10108,18,214,4,0,
10108,18,216,4,0,
10108,18,218,4,0,
10108,18,237,4,0,
10108,18,240,4,0,
10108,18,241,4,0,
10108,18,244,4,0,
10108,18,247,4,0,
10108,18,252,1,1,8
10108,18,252,1,9,
10108,18,259,4,0,
10108,18,263,4,0,
10108,18,269,1,25,
10108,18,269,4,0,
10108,18,332,4,0,
10108,18,364,1,65,
10108,18,369,4,0,
10108,18,371,4,0,
10108,18,372,1,49,
10108,18,373,4,0,
10108,18,399,1,69,
10108,18,399,4,0,
10108,18,400,1,61,
10108,18,408,1,32,
10108,18,415,1,1,4
10108,18,416,4,0,
10108,18,417,1,44,
10108,18,421,4,0,
10108,18,445,1,56,
10108,18,496,4,0,
10108,18,497,4,0,
10108,18,511,1,1,2
10108,18,511,4,0,
10108,18,526,4,0,
10108,18,555,4,0,
10108,18,583,1,1,3
10108,18,590,4,0,
10109,17,9,1,22,
10109,17,33,1,1,1
10109,17,38,1,40,
10109,17,53,4,0,
10109,17,85,4,0,
10109,17,87,4,0,
10109,17,88,1,16,
10109,17,89,4,0,
10109,17,92,4,0,
10109,17,104,4,0,
10109,17,111,1,1,2
10109,17,120,1,24,
10109,17,126,4,0,
10109,17,153,1,36,
10109,17,153,4,0,
10109,17,156,4,0,
10109,17,157,4,0,
10109,17,164,4,0,
10109,17,182,4,0,
10109,17,201,4,0,
10109,17,205,1,10,
10109,17,207,4,0,
10109,17,209,1,12,
10109,17,213,4,0,
10109,17,214,4,0,
10109,17,216,4,0,
10109,17,218,4,0,
10109,17,237,4,0,
10109,17,241,4,0,
10109,17,263,4,0,
10109,17,267,4,0,
10109,17,268,1,4,
10109,17,280,4,0,
10109,17,317,4,0,
10109,17,350,1,30,
10109,17,360,4,0,
10109,17,374,4,0,
10109,17,397,1,6,
10109,17,397,4,0,
10109,17,435,1,34,
10109,17,444,1,42,
10109,17,444,4,0,
10109,17,446,1,28,
10109,17,451,4,0,
10109,17,479,1,18,
10109,17,479,4,0,
10109,17,496,4,0,
10109,17,521,4,0,
10109,17,523,4,0,
10109,17,590,4,0,
10109,17,693,4,0,
10109,18,9,1,22,
10109,18,33,1,1,1
10109,18,38,1,40,
10109,18,53,4,0,
10109,18,85,4,0,
10109,18,87,4,0,
10109,18,88,1,16,
10109,18,89,4,0,
10109,18,92,4,0,
10109,18,104,4,0,
10109,18,111,1,1,2
10109,18,120,1,24,
10109,18,126,4,0,
10109,18,153,1,36,
10109,18,153,4,0,
10109,18,156,4,0,
10109,18,157,4,0,
10109,18,164,4,0,
10109,18,182,4,0,
10109,18,201,4,0,
10109,18,205,1,10,
10109,18,207,4,0,
10109,18,209,1,12,
10109,18,213,4,0,
10109,18,214,4,0,
10109,18,216,4,0,
10109,18,218,4,0,
10109,18,237,4,0,
10109,18,241,4,0,
10109,18,263,4,0,
10109,18,267,4,0,
10109,18,268,1,4,
10109,18,280,4,0,
10109,18,317,4,0,
10109,18,350,1,30,
10109,18,360,4,0,
10109,18,374,4,0,
10109,18,397,1,6,
10109,18,397,4,0,
10109,18,435,1,34,
10109,18,444,1,42,
10109,18,444,4,0,
10109,18,446,1,28,
10109,18,451,4,0,
10109,18,479,1,18,
10109,18,479,4,0,
10109,18,496,4,0,
10109,18,521,4,0,
10109,18,523,4,0,
10109,18,590,4,0,
10109,18,693,4,0,
10110,17,9,1,22,
10110,17,33,1,1,1
10110,17,38,1,50,
10110,17,53,4,0,
10110,17,85,4,0,
10110,17,87,4,0,
10110,17,88,1,16,
10110,17,89,4,0,
10110,17,92,4,0,
10110,17,104,4,0,
10110,17,111,1,1,2
10110,17,120,1,24,
10110,17,126,4,0,
10110,17,153,1,44,
10110,17,153,4,0,
10110,17,156,4,0,
10110,17,157,4,0,
10110,17,164,4,0,
10110,17,182,4,0,
10110,17,201,4,0,
10110,17,205,1,10,
10110,17,207,4,0,
10110,17,209,1,12,
10110,17,213,4,0,
10110,17,214,4,0,
10110,17,216,4,0,
10110,17,218,4,0,
10110,17,237,4,0,
10110,17,241,4,0,
10110,17,263,4,0,
10110,17,267,4,0,
10110,17,268,1,1,3
10110,17,268,1,4,
10110,17,280,4,0,
10110,17,317,4,0,
10110,17,350,1,34,
10110,17,360,4,0,
10110,17,374,4,0,
10110,17,397,1,1,4
10110,17,397,1,6,
10110,17,397,4,0,
10110,17,435,1,40,
10110,17,444,1,54,
10110,17,444,4,0,
10110,17,446,1,30,
10110,17,451,4,0,
10110,17,479,1,18,
10110,17,479,4,0,
10110,17,496,4,0,
10110,17,521,4,0,
10110,17,523,4,0,
10110,17,590,4,0,
10110,17,693,4,0,
10110,18,9,1,22,
10110,18,33,1,1,1
10110,18,38,1,50,
10110,18,53,4,0,
10110,18,85,4,0,
10110,18,87,4,0,
10110,18,88,1,16,
10110,18,89,4,0,
10110,18,92,4,0,
10110,18,104,4,0,
10110,18,111,1,1,2
10110,18,120,1,24,
10110,18,126,4,0,
10110,18,153,1,44,
10110,18,153,4,0,
10110,18,156,4,0,
10110,18,157,4,0,
10110,18,164,4,0,
10110,18,182,4,0,
10110,18,201,4,0,
10110,18,205,1,10,
10110,18,207,4,0,
10110,18,209,1,12,
10110,18,213,4,0,
10110,18,214,4,0,
10110,18,216,4,0,
10110,18,218,4,0,
10110,18,237,4,0,
10110,18,241,4,0,
10110,18,263,4,0,
10110,18,267,4,0,
10110,18,268,1,1,3
10110,18,268,1,4,
10110,18,280,4,0,
10110,18,317,4,0,
10110,18,350,1,34,
10110,18,360,4,0,
10110,18,374,4,0,
10110,18,397,1,1,4
10110,18,397,1,6,
10110,18,397,4,0,
10110,18,435,1,40,
10110,18,444,1,54,
10110,18,444,4,0,
10110,18,446,1,30,
10110,18,451,4,0,
10110,18,479,1,18,
10110,18,479,4,0,
10110,18,496,4,0,
10110,18,521,4,0,
10110,18,523,4,0,
10110,18,590,4,0,
10110,18,693,4,0,
10111,17,9,1,22,
10111,17,33,1,1,2
10111,17,38,1,50,
10111,17,46,4,0,
10111,17,53,4,0,
10111,17,63,4,0,
10111,17,85,4,0,
10111,17,87,4,0,
10111,17,88,1,16,
10111,17,89,4,0,
10111,17,92,4,0,
10111,17,104,4,0,
10111,17,111,1,1,3
10111,17,120,1,24,
10111,17,126,4,0,
10111,17,153,1,44,
10111,17,153,4,0,
10111,17,156,4,0,
10111,17,157,4,0,
10111,17,164,4,0,
10111,17,182,4,0,
10111,17,201,4,0,
10111,17,207,4,0,
10111,17,209,1,12,
10111,17,213,4,0,
10111,17,214,4,0,
10111,17,216,4,0,
10111,17,218,4,0,
10111,17,237,4,0,
10111,17,241,4,0,
10111,17,263,4,0,
10111,17,267,4,0,
10111,17,268,1,1,4
10111,17,268,1,4,
10111,17,280,4,0,
10111,17,317,4,0,
10111,17,350,1,34,
10111,17,360,4,0,
10111,17,374,4,0,
10111,17,397,1,1,5
10111,17,397,1,6,
10111,17,397,4,0,
10111,17,411,4,0,
10111,17,416,4,0,
10111,17,435,1,40,
10111,17,444,1,54,
10111,17,444,4,0,
10111,17,446,1,30,
10111,17,451,4,0,
10111,17,479,1,18,
10111,17,479,4,0,
10111,17,484,1,1,1
10111,17,484,1,60,
10111,17,496,4,0,
10111,17,497,4,0,
10111,17,521,4,0,
10111,17,523,4,0,
10111,17,528,4,0,
10111,17,537,1,10,
10111,17,590,4,0,
10111,17,693,4,0,
10111,18,9,1,22,
10111,18,33,1,1,2
10111,18,38,1,50,
10111,18,46,4,0,
10111,18,53,4,0,
10111,18,63,4,0,
10111,18,85,4,0,
10111,18,87,4,0,
10111,18,88,1,16,
10111,18,89,4,0,
10111,18,92,4,0,
10111,18,104,4,0,
10111,18,111,1,1,3
10111,18,120,1,24,
10111,18,126,4,0,
10111,18,153,1,44,
10111,18,153,4,0,
10111,18,156,4,0,
10111,18,157,4,0,
10111,18,164,4,0,
10111,18,182,4,0,
10111,18,201,4,0,
10111,18,207,4,0,
10111,18,209,1,12,
10111,18,213,4,0,
10111,18,214,4,0,
10111,18,216,4,0,
10111,18,218,4,0,
10111,18,237,4,0,
10111,18,241,4,0,
10111,18,263,4,0,
10111,18,267,4,0,
10111,18,268,1,1,4
10111,18,268,1,4,
10111,18,280,4,0,
10111,18,317,4,0,
10111,18,350,1,34,
10111,18,360,4,0,
10111,18,374,4,0,
10111,18,397,1,1,5
10111,18,397,1,6,
10111,18,397,4,0,
10111,18,411,4,0,
10111,18,416,4,0,
10111,18,435,1,40,
10111,18,444,1,54,
10111,18,444,4,0,
10111,18,446,1,30,
10111,18,451,4,0,
10111,18,479,1,18,
10111,18,479,4,0,
10111,18,484,1,1,1
10111,18,484,1,60,
10111,18,496,4,0,
10111,18,497,4,0,
10111,18,521,4,0,
10111,18,523,4,0,
10111,18,528,4,0,
10111,18,537,1,10,
10111,18,590,4,0,
10111,18,693,4,0,
10112,17,1,1,1,1
10112,17,44,1,7,
10112,17,50,1,12,
10112,17,53,4,0,
10112,17,92,4,0,
10112,17,103,1,37,
10112,17,104,4,0,
10112,17,106,1,4,
10112,17,107,1,21,
10112,17,126,4,0,
10112,17,139,1,1,2
10112,17,151,1,43,
10112,17,153,4,0,
10112,17,156,4,0,
10112,17,157,4,0,
10112,17,164,4,0,
10112,17,168,4,0,
10112,17,182,4,0,
10112,17,188,4,0,
10112,17,207,4,0,
10112,17,213,4,0,
10112,17,214,4,0,
10112,17,216,4,0,
10112,17,218,4,0,
10112,17,237,4,0,
10112,17,240,4,0,
10112,17,241,4,0,
10112,17,242,1,32,
10112,17,247,4,0,
10112,17,259,4,0,
10112,17,262,1,48,
10112,17,263,4,0,
10112,17,269,4,0,
10112,17,282,1,29,
10112,17,305,1,18,
10112,17,317,4,0,
10112,17,371,4,0,
10112,17,373,4,0,
10112,17,374,1,26,
10112,17,374,4,0,
10112,17,397,4,0,
10112,17,398,4,0,
10112,17,441,1,40,
10112,17,444,4,0,
10112,17,474,4,0,
10112,17,482,4,0,
10112,17,491,1,15,
10112,17,496,4,0,
10112,17,511,4,0,
10112,17,555,4,0,
10112,17,562,1,46,
10112,17,590,4,0,
10112,17,611,4,0,
10112,17,693,4,0,
10112,18,1,1,1,1
10112,18,44,1,7,
10112,18,50,1,12,
10112,18,53,4,0,
10112,18,92,4,0,
10112,18,103,1,37,
10112,18,104,4,0,
10112,18,106,1,4,
10112,18,107,1,21,
10112,18,126,4,0,
10112,18,139,1,1,2
10112,18,151,1,43,
10112,18,153,4,0,
10112,18,156,4,0,
10112,18,157,4,0,
10112,18,164,4,0,
10112,18,168,4,0,
10112,18,182,4,0,
10112,18,188,4,0,
10112,18,207,4,0,
10112,18,213,4,0,
10112,18,214,4,0,
10112,18,216,4,0,
10112,18,218,4,0,
10112,18,237,4,0,
10112,18,240,4,0,
10112,18,241,4,0,
10112,18,242,1,32,
10112,18,247,4,0,
10112,18,259,4,0,
10112,18,262,1,48,
10112,18,263,4,0,
10112,18,269,4,0,
10112,18,282,1,29,
10112,18,305,1,18,
10112,18,317,4,0,
10112,18,371,4,0,
10112,18,373,4,0,
10112,18,374,1,26,
10112,18,374,4,0,
10112,18,397,4,0,
10112,18,398,4,0,
10112,18,441,1,40,
10112,18,444,4,0,
10112,18,474,4,0,
10112,18,482,4,0,
10112,18,491,1,15,
10112,18,496,4,0,
10112,18,511,4,0,
10112,18,555,4,0,
10112,18,562,1,46,
10112,18,590,4,0,
10112,18,611,4,0,
10112,18,693,4,0,
10113,17,1,1,1,2
10113,17,44,1,1,5
10113,17,44,1,7,
10113,17,50,1,12,
10113,17,53,4,0,
10113,17,63,4,0,
10113,17,92,4,0,
10113,17,103,1,37,
10113,17,104,4,0,
10113,17,106,1,1,4
10113,17,106,1,4,
10113,17,107,1,21,
10113,17,126,4,0,
10113,17,139,1,1,3
10113,17,151,1,46,
10113,17,153,4,0,
10113,17,156,4,0,
10113,17,157,4,0,
10113,17,164,4,0,
10113,17,168,4,0,
10113,17,182,4,0,
10113,17,188,4,0,
10113,17,207,4,0,
10113,17,213,4,0,
10113,17,214,4,0,
10113,17,216,4,0,
10113,17,218,4,0,
10113,17,237,4,0,
10113,17,240,4,0,
10113,17,241,4,0,
10113,17,242,1,32,
10113,17,247,4,0,
10113,17,259,4,0,
10113,17,262,1,57,
10113,17,263,4,0,
10113,17,269,4,0,
10113,17,280,4,0,
10113,17,282,1,29,
10113,17,305,1,18,
10113,17,317,4,0,
10113,17,371,4,0,
10113,17,373,4,0,
10113,17,374,1,26,
10113,17,374,4,0,
10113,17,397,4,0,
10113,17,398,4,0,
10113,17,399,4,0,
10113,17,411,4,0,
10113,17,416,4,0,
10113,17,441,1,40,
10113,17,444,4,0,
10113,17,474,4,0,
10113,17,482,4,0,
10113,17,491,1,15,
10113,17,496,4,0,
10113,17,511,4,0,
10113,17,555,4,0,
10113,17,562,1,52,
10113,17,590,4,0,
10113,17,599,1,0,
10113,17,599,1,1,1
10113,17,611,4,0,
10113,17,693,4,0,
10113,18,1,1,1,2
10113,18,44,1,1,5
10113,18,44,1,7,
10113,18,50,1,12,
10113,18,53,4,0,
10113,18,63,4,0,
10113,18,92,4,0,
10113,18,103,1,37,
10113,18,104,4,0,
10113,18,106,1,1,4
10113,18,106,1,4,
10113,18,107,1,21,
10113,18,126,4,0,
10113,18,139,1,1,3
10113,18,151,1,46,
10113,18,153,4,0,
10113,18,156,4,0,
10113,18,157,4,0,
10113,18,164,4,0,
10113,18,168,4,0,
10113,18,182,4,0,
10113,18,188,4,0,
10113,18,207,4,0,
10113,18,213,4,0,
10113,18,214,4,0,
10113,18,216,4,0,
10113,18,218,4,0,
10113,18,237,4,0,
10113,18,240,4,0,
10113,18,241,4,0,
10113,18,242,1,32,
10113,18,247,4,0,
10113,18,259,4,0,
10113,18,262,1,57,
10113,18,263,4,0,
10113,18,269,4,0,
10113,18,280,4,0,
10113,18,282,1,29,
10113,18,305,1,18,
10113,18,317,4,0,
10113,18,371,4,0,
10113,18,373,4,0,
10113,18,374,1,26,
10113,18,374,4,0,
10113,18,397,4,0,
10113,18,398,4,0,
10113,18,399,4,0,
10113,18,411,4,0,
10113,18,416,4,0,
10113,18,441,1,40,
10113,18,444,4,0,
10113,18,474,4,0,
10113,18,482,4,0,
10113,18,491,1,15,
10113,18,496,4,0,
10113,18,511,4,0,
10113,18,555,4,0,
10113,18,562,1,52,
10113,18,590,4,0,
10113,18,599,1,0,
10113,18,599,1,1,1
10113,18,611,4,0,
10113,18,693,4,0,
10114,17,14,4,0,
10114,17,53,4,0,
10114,17,63,4,0,
10114,17,76,4,0,
10114,17,89,4,0,
10114,17,92,4,0,
10114,17,93,1,1,5
10114,17,94,4,0,
10114,17,95,1,1,4
10114,17,104,4,0,
10114,17,113,4,0,
10114,17,115,4,0,
10114,17,121,1,27,
10114,17,138,4,0,
10114,17,140,1,1,3
10114,17,153,4,0,
10114,17,156,4,0,
10114,17,164,4,0,
10114,17,168,4,0,
10114,17,182,4,0,
10114,17,188,4,0,
10114,17,207,4,0,
10114,17,213,4,0,
10114,17,214,4,0,
10114,17,216,4,0,
10114,17,218,4,0,
10114,17,237,4,0,
10114,17,241,4,0,
10114,17,244,4,0,
10114,17,263,4,0,
10114,17,267,4,0,
10114,17,280,4,0,
10114,17,402,1,1,2
10114,17,412,4,0,
10114,17,416,4,0,
10114,17,433,4,0,
10114,17,434,3,0,
10114,17,437,1,47,
10114,17,447,4,0,
10114,17,452,1,37,
10114,17,473,1,17,
10114,17,473,4,0,
10114,17,496,4,0,
10114,17,523,4,0,
10114,17,525,4,0,
10114,17,590,4,0,
10114,17,611,4,0,
10114,17,692,1,0,
10114,17,692,1,1,1
10114,17,693,4,0,
10114,18,14,4,0,
10114,18,53,4,0,
10114,18,63,4,0,
10114,18,76,4,0,
10114,18,89,4,0,
10114,18,92,4,0,
10114,18,93,1,1,5
10114,18,94,4,0,
10114,18,95,1,1,4
10114,18,104,4,0,
10114,18,113,4,0,
10114,18,115,4,0,
10114,18,121,1,27,
10114,18,138,4,0,
10114,18,140,1,1,3
10114,18,153,4,0,
10114,18,156,4,0,
10114,18,164,4,0,
10114,18,168,4,0,
10114,18,182,4,0,
10114,18,188,4,0,
10114,18,207,4,0,
10114,18,213,4,0,
10114,18,214,4,0,
10114,18,216,4,0,
10114,18,218,4,0,
10114,18,237,4,0,
10114,18,241,4,0,
10114,18,244,4,0,
10114,18,263,4,0,
10114,18,267,4,0,
10114,18,280,4,0,
10114,18,402,1,1,2
10114,18,412,4,0,
10114,18,416,4,0,
10114,18,433,4,0,
10114,18,434,3,0,
10114,18,437,1,47,
10114,18,447,4,0,
10114,18,452,1,37,
10114,18,473,1,17,
10114,18,473,4,0,
10114,18,496,4,0,
10114,18,523,4,0,
10114,18,525,4,0,
10114,18,590,4,0,
10114,18,611,4,0,
10114,18,692,1,0,
10114,18,692,1,1,1
10114,18,693,4,0,
10115,17,14,4,0,
10115,17,37,1,33,
10115,17,39,1,1,2
10115,17,39,1,3,
10115,17,43,1,13,
10115,17,45,1,1,1
10115,17,53,4,0,
10115,17,58,4,0,
10115,17,59,4,0,
10115,17,63,4,0,
10115,17,85,4,0,
10115,17,87,4,0,
10115,17,89,4,0,
10115,17,92,4,0,
10115,17,104,4,0,
10115,17,125,1,1,3
10115,17,125,1,7,
10115,17,126,4,0,
10115,17,138,4,0,
10115,17,155,1,21,
10115,17,156,4,0,
10115,17,157,4,0,
10115,17,164,4,0,
10115,17,168,4,0,
10115,17,172,1,1,4
10115,17,172,1,11,
10115,17,182,4,0,
10115,17,198,1,65,
10115,17,201,4,0,
10115,17,206,4,0,
10115,17,207,4,0,
10115,17,213,4,0,
10115,17,214,4,0,
10115,17,216,4,0,
10115,17,218,4,0,
10115,17,237,4,0,
10115,17,240,4,0,
10115,17,241,4,0,
10115,17,247,4,0,
10115,17,261,1,23,
10115,17,261,4,0,
10115,17,263,4,0,
10115,17,280,4,0,
10115,17,283,1,49,
10115,17,317,4,0,
10115,17,332,4,0,
10115,17,374,1,37,
10115,17,374,4,0,
10115,17,394,1,53,
10115,17,399,4,0,
10115,17,411,4,0,
10115,17,416,4,0,
10115,17,444,4,0,
10115,17,479,4,0,
10115,17,488,4,0,
10115,17,496,4,0,
10115,17,497,4,0,
10115,17,506,1,17,
10115,17,514,1,59,
10115,17,523,4,0,
10115,17,590,4,0,
10115,17,693,4,0,
10115,17,707,1,43,
10115,17,708,1,27,
10115,18,14,4,0,
10115,18,37,1,33,
10115,18,39,1,1,2
10115,18,39,1,3,
10115,18,43,1,13,
10115,18,45,1,1,1
10115,18,53,4,0,
10115,18,58,4,0,
10115,18,59,4,0,
10115,18,63,4,0,
10115,18,85,4,0,
10115,18,87,4,0,
10115,18,89,4,0,
10115,18,92,4,0,
10115,18,104,4,0,
10115,18,125,1,1,3
10115,18,125,1,7,
10115,18,126,4,0,
10115,18,138,4,0,
10115,18,155,1,21,
10115,18,156,4,0,
10115,18,157,4,0,
10115,18,164,4,0,
10115,18,168,4,0,
10115,18,172,1,1,4
10115,18,172,1,11,
10115,18,182,4,0,
10115,18,198,1,65,
10115,18,201,4,0,
10115,18,206,4,0,
10115,18,207,4,0,
10115,18,213,4,0,
10115,18,214,4,0,
10115,18,216,4,0,
10115,18,218,4,0,
10115,18,237,4,0,
10115,18,240,4,0,
10115,18,241,4,0,
10115,18,247,4,0,
10115,18,261,1,23,
10115,18,261,4,0,
10115,18,263,4,0,
10115,18,280,4,0,
10115,18,283,1,49,
10115,18,317,4,0,
10115,18,332,4,0,
10115,18,374,1,37,
10115,18,374,4,0,
10115,18,394,1,53,
10115,18,399,4,0,
10115,18,411,4,0,
10115,18,416,4,0,
10115,18,444,4,0,
10115,18,479,4,0,
10115,18,488,4,0,
10115,18,496,4,0,
10115,18,497,4,0,
10115,18,506,1,17,
10115,18,514,1,59,
10115,18,523,4,0,
10115,18,590,4,0,
10115,18,693,4,0,
10115,18,707,1,43,
10115,18,708,1,27,
10116,17,1,1,1,6
10116,17,45,1,1,7
10116,17,56,1,68,
10116,17,57,4,0,
10116,17,58,4,0,
10116,17,59,4,0,
10116,17,63,4,0,
10116,17,92,4,0,
10116,17,98,1,1,9
10116,17,98,1,8,
10116,17,104,1,56,
10116,17,104,4,0,
10116,17,108,1,19,
10116,17,114,1,1,3
10116,17,114,1,70,
10116,17,122,1,10,
10116,17,127,4,0,
10116,17,145,1,1,8
10116,17,145,1,5,
10116,17,156,4,0,
10116,17,157,4,0,
10116,17,164,1,42,
10116,17,164,4,0,
10116,17,168,4,0,
10116,17,182,4,0,
10116,17,185,1,33,
10116,17,191,1,28,
10116,17,207,4,0,
10116,17,213,4,0,
10116,17,214,4,0,
10116,17,216,4,0,
10116,17,218,4,0,
10116,17,237,4,0,
10116,17,240,4,0,
10116,17,263,4,0,
10116,17,269,4,0,
10116,17,272,1,1,4
10116,17,308,3,0,
10116,17,317,4,0,
10116,17,326,1,49,
10116,17,332,4,0,
10116,17,352,1,14,
10116,17,369,4,0,
10116,17,374,4,0,
10116,17,399,4,0,
10116,17,400,1,1,2
10116,17,400,1,77,
10116,17,416,4,0,
10116,17,425,1,23,
10116,17,447,4,0,
10116,17,479,4,0,
10116,17,496,4,0,
10116,17,497,4,0,
10116,17,503,4,0,
10116,17,512,4,0,
10116,17,518,3,0,
10116,17,526,4,0,
10116,17,561,1,1,5
10116,17,590,4,0,
10116,17,594,1,0,
10116,17,594,1,1,1
10116,17,693,4,0,
10116,18,1,1,1,6
10116,18,45,1,1,7
10116,18,56,1,68,
10116,18,57,4,0,
10116,18,58,4,0,
10116,18,59,4,0,
10116,18,63,4,0,
10116,18,92,4,0,
10116,18,98,1,1,9
10116,18,98,1,8,
10116,18,104,1,56,
10116,18,104,4,0,
10116,18,108,1,19,
10116,18,114,1,1,3
10116,18,114,1,70,
10116,18,122,1,10,
10116,18,127,4,0,
10116,18,145,1,1,8
10116,18,145,1,5,
10116,18,156,4,0,
10116,18,157,4,0,
10116,18,164,1,42,
10116,18,164,4,0,
10116,18,168,4,0,
10116,18,182,4,0,
10116,18,185,1,33,
10116,18,191,1,28,
10116,18,207,4,0,
10116,18,213,4,0,
10116,18,214,4,0,
10116,18,216,4,0,
10116,18,218,4,0,
10116,18,237,4,0,
10116,18,240,4,0,
10116,18,263,4,0,
10116,18,269,4,0,
10116,18,272,1,1,4
10116,18,308,3,0,
10116,18,317,4,0,
10116,18,326,1,49,
10116,18,332,4,0,
10116,18,352,1,14,
10116,18,369,4,0,
10116,18,374,4,0,
10116,18,399,4,0,
10116,18,400,1,1,2
10116,18,400,1,77,
10116,18,416,4,0,
10116,18,425,1,23,
10116,18,447,4,0,
10116,18,479,4,0,
10116,18,496,4,0,
10116,18,497,4,0,
10116,18,503,4,0,
10116,18,512,4,0,
10116,18,518,3,0,
10116,18,526,4,0,
10116,18,561,1,1,5
10116,18,590,4,0,
10116,18,594,1,0,
10116,18,594,1,1,1
10116,18,693,4,0,
10117,17,1,1,1,6
10117,17,45,1,1,7
10117,17,56,1,68,
10117,17,57,4,0,
10117,17,58,4,0,
10117,17,59,4,0,
10117,17,63,4,0,
10117,17,92,4,0,
10117,17,98,1,1,9
10117,17,98,1,8,
10117,17,104,1,56,
10117,17,104,4,0,
10117,17,108,1,19,
10117,17,114,1,1,3
10117,17,114,1,70,
10117,17,122,1,10,
10117,17,127,4,0,
10117,17,145,1,1,8
10117,17,145,1,5,
10117,17,156,4,0,
10117,17,157,4,0,
10117,17,164,1,42,
10117,17,164,4,0,
10117,17,168,4,0,
10117,17,182,4,0,
10117,17,185,1,33,
10117,17,191,1,28,
10117,17,207,4,0,
10117,17,213,4,0,
10117,17,214,4,0,
10117,17,216,4,0,
10117,17,218,4,0,
10117,17,237,4,0,
10117,17,240,4,0,
10117,17,263,4,0,
10117,17,269,4,0,
10117,17,272,1,1,4
10117,17,308,3,0,
10117,17,317,4,0,
10117,17,326,1,49,
10117,17,332,4,0,
10117,17,352,1,14,
10117,17,369,4,0,
10117,17,374,4,0,
10117,17,399,4,0,
10117,17,400,1,1,2
10117,17,400,1,77,
10117,17,416,4,0,
10117,17,425,1,23,
10117,17,447,4,0,
10117,17,479,4,0,
10117,17,496,4,0,
10117,17,497,4,0,
10117,17,503,4,0,
10117,17,512,4,0,
10117,17,518,3,0,
10117,17,526,4,0,
10117,17,561,1,1,5
10117,17,590,4,0,
10117,17,594,1,0,
10117,17,594,1,1,1
10117,17,693,4,0,
10117,18,1,1,1,6
10117,18,45,1,1,7
10117,18,56,1,68,
10117,18,57,4,0,
10117,18,58,4,0,
10117,18,59,4,0,
10117,18,63,4,0,
10117,18,92,4,0,
10117,18,98,1,1,9
10117,18,98,1,8,
10117,18,104,1,56,
10117,18,104,4,0,
10117,18,108,1,19,
10117,18,114,1,1,3
10117,18,114,1,70,
10117,18,122,1,10,
10117,18,127,4,0,
10117,18,145,1,1,8
10117,18,145,1,5,
10117,18,156,4,0,
10117,18,157,4,0,
10117,18,164,1,42,
10117,18,164,4,0,
10117,18,168,4,0,
10117,18,182,4,0,
10117,18,185,1,33,
10117,18,191,1,28,
10117,18,207,4,0,
10117,18,213,4,0,
10117,18,214,4,0,
10117,18,216,4,0,
10117,18,218,4,0,
10117,18,237,4,0,
10117,18,240,4,0,
10117,18,263,4,0,
10117,18,269,4,0,
10117,18,272,1,1,4
10117,18,308,3,0,
10117,18,317,4,0,
10117,18,326,1,49,
10117,18,332,4,0,
10117,18,352,1,14,
10117,18,369,4,0,
10117,18,374,4,0,
10117,18,399,4,0,
10117,18,400,1,1,2
10117,18,400,1,77,
10117,18,416,4,0,
10117,18,425,1,23,
10117,18,447,4,0,
10117,18,479,4,0,
10117,18,496,4,0,
10117,18,497,4,0,
10117,18,503,4,0,
10117,18,512,4,0,
10117,18,518,3,0,
10117,18,526,4,0,
10117,18,561,1,1,5
10117,18,590,4,0,
10117,18,594,1,0,
10117,18,594,1,1,1
10117,18,693,4,0,
10118,17,20,1,18,
10118,17,44,1,1,4
10118,17,63,4,0,
10118,17,89,1,55,
10118,17,89,4,0,
10118,17,91,1,10,
10118,17,92,4,0,
10118,17,104,4,0,
10118,17,114,1,44,
10118,17,137,1,1,1
10118,17,156,4,0,
10118,17,157,4,0,
10118,17,164,4,0,
10118,17,182,4,0,
10118,17,200,1,80,
10118,17,201,1,35,
10118,17,201,4,0,
10118,17,207,4,0,
10118,17,214,4,0,
10118,17,216,4,0,
10118,17,218,4,0,
10118,17,219,1,5,
10118,17,219,4,0,
10118,17,225,1,1,3
10118,17,237,4,0,
10118,17,241,4,0,
10118,17,242,1,51,
10118,17,263,4,0,
10118,17,280,4,0,
10118,17,293,1,59,
10118,17,406,1,63,
10118,17,411,4,0,
10118,17,416,4,0,
10118,17,434,3,0,
10118,17,444,4,0,
10118,17,447,4,0,
10118,17,482,4,0,
10118,17,489,1,72,
10118,17,496,4,0,
10118,17,523,1,1,2
10118,17,523,4,0,
10118,17,525,4,0,
10118,17,590,4,0,
10118,17,616,1,26,
10118,18,20,1,18,
10118,18,44,1,1,4
10118,18,63,4,0,
10118,18,89,1,55,
10118,18,89,4,0,
10118,18,91,1,10,
10118,18,92,4,0,
10118,18,104,4,0,
10118,18,114,1,44,
10118,18,137,1,1,1
10118,18,156,4,0,
10118,18,157,4,0,
10118,18,164,4,0,
10118,18,182,4,0,
10118,18,200,1,80,
10118,18,201,1,35,
10118,18,201,4,0,
10118,18,207,4,0,
10118,18,214,4,0,
10118,18,216,4,0,
10118,18,218,4,0,
10118,18,219,1,5,
10118,18,219,4,0,
10118,18,225,1,1,3
10118,18,237,4,0,
10118,18,241,4,0,
10118,18,242,1,51,
10118,18,263,4,0,
10118,18,280,4,0,
10118,18,293,1,59,
10118,18,406,1,63,
10118,18,411,4,0,
10118,18,416,4,0,
10118,18,434,3,0,
10118,18,444,4,0,
10118,18,447,4,0,
10118,18,482,4,0,
10118,18,489,1,72,
10118,18,496,4,0,
10118,18,523,1,1,2
10118,18,523,4,0,
10118,18,525,4,0,
10118,18,590,4,0,
10118,18,616,1,26,
10119,17,20,1,18,
10119,17,44,1,1,4
10119,17,63,4,0,
10119,17,89,1,55,
10119,17,89,4,0,
10119,17,91,1,10,
10119,17,92,4,0,
10119,17,104,4,0,
10119,17,114,1,44,
10119,17,137,1,1,1
10119,17,156,4,0,
10119,17,157,4,0,
10119,17,164,4,0,
10119,17,182,4,0,
10119,17,200,1,80,
10119,17,201,1,35,
10119,17,201,4,0,
10119,17,207,4,0,
10119,17,214,4,0,
10119,17,216,4,0,
10119,17,218,4,0,
10119,17,219,1,5,
10119,17,219,4,0,
10119,17,225,1,1,3
10119,17,237,4,0,
10119,17,241,4,0,
10119,17,242,1,51,
10119,17,263,4,0,
10119,17,280,4,0,
10119,17,293,1,59,
10119,17,406,1,63,
10119,17,411,4,0,
10119,17,416,4,0,
10119,17,434,3,0,
10119,17,444,4,0,
10119,17,447,4,0,
10119,17,482,4,0,
10119,17,489,1,72,
10119,17,496,4,0,
10119,17,523,1,1,2
10119,17,523,4,0,
10119,17,525,4,0,
10119,17,590,4,0,
10119,17,616,1,26,
10119,18,20,1,18,
10119,18,44,1,1,4
10119,18,63,4,0,
10119,18,89,1,55,
10119,18,89,4,0,
10119,18,91,1,10,
10119,18,92,4,0,
10119,18,104,4,0,
10119,18,114,1,44,
10119,18,137,1,1,1
10119,18,156,4,0,
10119,18,157,4,0,
10119,18,164,4,0,
10119,18,182,4,0,
10119,18,200,1,80,
10119,18,201,1,35,
10119,18,201,4,0,
10119,18,207,4,0,
10119,18,214,4,0,
10119,18,216,4,0,
10119,18,218,4,0,
10119,18,219,1,5,
10119,18,219,4,0,
10119,18,225,1,1,3
10119,18,237,4,0,
10119,18,241,4,0,
10119,18,242,1,51,
10119,18,263,4,0,
10119,18,280,4,0,
10119,18,293,1,59,
10119,18,406,1,63,
10119,18,411,4,0,
10119,18,416,4,0,
10119,18,434,3,0,
10119,18,444,4,0,
10119,18,447,4,0,
10119,18,482,4,0,
10119,18,489,1,72,
10119,18,496,4,0,
10119,18,523,1,1,2
10119,18,523,4,0,
10119,18,525,4,0,
10119,18,590,4,0,
10119,18,616,1,26,
10120,17,20,1,18,
10120,17,44,1,1,4
10120,17,63,4,0,
10120,17,89,1,55,
10120,17,89,4,0,
10120,17,91,1,10,
10120,17,92,4,0,
10120,17,104,4,0,
10120,17,114,1,44,
10120,17,137,1,1,1
10120,17,156,4,0,
10120,17,157,4,0,
10120,17,164,4,0,
10120,17,182,4,0,
10120,17,200,1,80,
10120,17,201,1,35,
10120,17,201,4,0,
10120,17,207,4,0,
10120,17,214,4,0,
10120,17,216,4,0,
10120,17,218,4,0,
10120,17,219,1,5,
10120,17,219,4,0,
10120,17,225,1,1,3
10120,17,237,4,0,
10120,17,241,4,0,
10120,17,242,1,51,
10120,17,263,4,0,
10120,17,280,4,0,
10120,17,293,1,59,
10120,17,406,1,63,
10120,17,411,4,0,
10120,17,416,4,0,
10120,17,434,3,0,
10120,17,444,4,0,
10120,17,447,4,0,
10120,17,482,4,0,
10120,17,489,1,72,
10120,17,496,4,0,
10120,17,523,1,1,2
10120,17,523,4,0,
10120,17,525,4,0,
10120,17,590,4,0,
10120,17,616,1,26,
10120,18,20,1,18,
10120,18,44,1,1,4
10120,18,63,4,0,
10120,18,89,1,55,
10120,18,89,4,0,
10120,18,91,1,10,
10120,18,92,4,0,
10120,18,104,4,0,
10120,18,114,1,44,
10120,18,137,1,1,1
10120,18,156,4,0,
10120,18,157,4,0,
10120,18,164,4,0,
10120,18,182,4,0,
10120,18,200,1,80,
10120,18,201,1,35,
10120,18,201,4,0,
10120,18,207,4,0,
10120,18,214,4,0,
10120,18,216,4,0,
10120,18,218,4,0,
10120,18,219,1,5,
10120,18,219,4,0,
10120,18,225,1,1,3
10120,18,237,4,0,
10120,18,241,4,0,
10120,18,242,1,51,
10120,18,263,4,0,
10120,18,280,4,0,
10120,18,293,1,59,
10120,18,406,1,63,
10120,18,411,4,0,
10120,18,416,4,0,
10120,18,434,3,0,
10120,18,444,4,0,
10120,18,447,4,0,
10120,18,482,4,0,
10120,18,489,1,72,
10120,18,496,4,0,
10120,18,523,1,1,2
10120,18,523,4,0,
10120,18,525,4,0,
10120,18,590,4,0,
10120,18,616,1,26,
10121,17,28,1,1,4
10121,17,28,1,10,
10121,17,33,1,1,1
10121,17,36,1,31,
10121,17,37,1,51,
10121,17,43,1,1,2
10121,17,43,1,3,
10121,17,44,1,19,
10121,17,46,4,0,
10121,17,89,4,0,
10121,17,92,4,0,
10121,17,104,4,0,
10121,17,117,1,16,
10121,17,156,1,55,
10121,17,156,4,0,
10121,17,158,1,43,
10121,17,162,1,27,
10121,17,164,4,0,
10121,17,168,4,0,
10121,17,182,4,0,
10121,17,184,1,35,
10121,17,189,1,23,
10121,17,201,4,0,
10121,17,207,4,0,
10121,17,213,4,0,
10121,17,214,4,0,
10121,17,216,4,0,
10121,17,218,4,0,
10121,17,228,1,1,3
10121,17,228,1,7,
10121,17,237,4,0,
10121,17,242,1,39,
10121,17,259,4,0,
10121,17,263,4,0,
10121,17,269,4,0,
10121,17,281,1,47,
10121,17,316,1,13,
10121,17,317,4,0,
10121,17,369,4,0,
10121,17,371,4,0,
10121,17,374,4,0,
10121,17,496,4,0,
10121,17,497,4,0,
10121,17,523,4,0,
10121,17,526,4,0,
10121,17,590,4,0,
10121,18,28,1,1,4
10121,18,28,1,10,
10121,18,33,1,1,1
10121,18,36,1,31,
10121,18,37,1,51,
10121,18,43,1,1,2
10121,18,43,1,3,
10121,18,44,1,19,
10121,18,46,4,0,
10121,18,89,4,0,
10121,18,92,4,0,
10121,18,104,4,0,
10121,18,117,1,16,
10121,18,156,1,55,
10121,18,156,4,0,
10121,18,158,1,43,
10121,18,162,1,27,
10121,18,164,4,0,
10121,18,168,4,0,
10121,18,182,4,0,
10121,18,184,1,35,
10121,18,189,1,23,
10121,18,201,4,0,
10121,18,207,4,0,
10121,18,213,4,0,
10121,18,214,4,0,
10121,18,216,4,0,
10121,18,218,4,0,
10121,18,228,1,1,3
10121,18,228,1,7,
10121,18,237,4,0,
10121,18,242,1,39,
10121,18,259,4,0,
10121,18,263,4,0,
10121,18,269,4,0,
10121,18,281,1,47,
10121,18,316,1,13,
10121,18,317,4,0,
10121,18,369,4,0,
10121,18,371,4,0,
10121,18,374,4,0,
10121,18,496,4,0,
10121,18,497,4,0,
10121,18,523,4,0,
10121,18,526,4,0,
10121,18,590,4,0,
10122,17,11,1,1,4
10122,17,12,1,25,
10122,17,44,1,1,7
10122,17,44,1,10,
10122,17,63,4,0,
10122,17,76,4,0,
10122,17,81,1,1,5
10122,17,81,1,4,
10122,17,85,1,0,
10122,17,85,1,1,1
10122,17,85,4,0,
10122,17,86,4,0,
10122,17,87,4,0,
10122,17,91,1,37,
10122,17,92,4,0,
10122,17,97,1,49,
10122,17,104,4,0,
10122,17,113,4,0,
10122,17,156,4,0,
10122,17,164,4,0,
10122,17,182,4,0,
10122,17,189,1,1,6
10122,17,189,1,7,
10122,17,192,1,41,
10122,17,207,4,0,
10122,17,209,1,16,
10122,17,213,4,0,
10122,17,214,4,0,
10122,17,216,4,0,
10122,17,218,4,0,
10122,17,237,4,0,
10122,17,240,4,0,
10122,17,263,4,0,
10122,17,268,1,1,3
10122,17,355,4,0,
10122,17,398,4,0,
10122,17,403,1,1,2
10122,17,404,4,0,
10122,17,405,1,31,
10122,17,412,4,0,
10122,17,416,4,0,
10122,17,430,4,0,
10122,17,450,1,13,
10122,17,451,4,0,
10122,17,496,4,0,
10122,17,507,4,0,
10122,17,512,1,19,
10122,17,512,4,0,
10122,17,521,4,0,
10122,17,528,4,0,
10122,17,590,4,0,
10122,18,11,1,1,4
10122,18,12,1,25,
10122,18,44,1,1,7
10122,18,44,1,10,
10122,18,63,4,0,
10122,18,76,4,0,
10122,18,81,1,1,5
10122,18,81,1,4,
10122,18,85,1,0,
10122,18,85,1,1,1
10122,18,85,4,0,
10122,18,86,4,0,
10122,18,87,4,0,
10122,18,91,1,37,
10122,18,92,4,0,
10122,18,97,1,49,
10122,18,104,4,0,
10122,18,113,4,0,
10122,18,156,4,0,
10122,18,164,4,0,
10122,18,182,4,0,
10122,18,189,1,1,6
10122,18,189,1,7,
10122,18,192,1,41,
10122,18,207,4,0,
10122,18,209,1,16,
10122,18,213,4,0,
10122,18,214,4,0,
10122,18,216,4,0,
10122,18,218,4,0,
10122,18,237,4,0,
10122,18,240,4,0,
10122,18,263,4,0,
10122,18,268,1,1,3
10122,18,355,4,0,
10122,18,398,4,0,
10122,18,403,1,1,2
10122,18,404,4,0,
10122,18,405,1,31,
10122,18,412,4,0,
10122,18,416,4,0,
10122,18,430,4,0,
10122,18,450,1,13,
10122,18,451,4,0,
10122,18,496,4,0,
10122,18,507,4,0,
10122,18,512,1,19,
10122,18,512,4,0,
10122,18,521,4,0,
10122,18,528,4,0,
10122,18,590,4,0,
10123,17,1,1,1,
10123,17,3,1,23,
10123,17,14,4,0,
10123,17,19,4,0,
10123,17,45,1,4,
10123,17,64,1,6,
10123,17,92,4,0,
10123,17,97,1,46,
10123,17,104,4,0,
10123,17,119,1,43,
10123,17,156,4,0,
10123,17,164,4,0,
10123,17,182,4,0,
10123,17,201,4,0,
10123,17,207,4,0,
10123,17,211,4,0,
10123,17,213,4,0,
10123,17,214,4,0,
10123,17,216,4,0,
10123,17,218,4,0,
10123,17,219,4,0,
10123,17,226,1,16,
10123,17,237,4,0,
10123,17,263,4,0,
10123,17,269,4,0,
10123,17,270,1,10,
10123,17,297,1,20,
10123,17,298,1,26,
10123,17,314,1,13,
10123,17,332,4,0,
10123,17,347,4,0,
10123,17,355,1,30,
10123,17,355,4,0,
10123,17,369,4,0,
10123,17,373,4,0,
10123,17,403,1,36,
10123,17,445,1,33,
10123,17,496,4,0,
10123,17,511,4,0,
10123,17,512,4,0,
10123,17,526,4,0,
10123,17,542,1,50,
10123,17,590,4,0,
10123,17,686,1,40,
10123,18,1,1,1,
10123,18,3,1,23,
10123,18,14,4,0,
10123,18,19,4,0,
10123,18,45,1,4,
10123,18,64,1,6,
10123,18,92,4,0,
10123,18,97,1,46,
10123,18,104,4,0,
10123,18,119,1,43,
10123,18,156,4,0,
10123,18,164,4,0,
10123,18,182,4,0,
10123,18,201,4,0,
10123,18,207,4,0,
10123,18,211,4,0,
10123,18,213,4,0,
10123,18,214,4,0,
10123,18,216,4,0,
10123,18,218,4,0,
10123,18,219,4,0,
10123,18,226,1,16,
10123,18,237,4,0,
10123,18,263,4,0,
10123,18,269,4,0,
10123,18,270,1,10,
10123,18,297,1,20,
10123,18,298,1,26,
10123,18,314,1,13,
10123,18,332,4,0,
10123,18,347,4,0,
10123,18,355,1,30,
10123,18,355,4,0,
10123,18,369,4,0,
10123,18,373,4,0,
10123,18,403,1,36,
10123,18,445,1,33,
10123,18,496,4,0,
10123,18,511,4,0,
10123,18,512,4,0,
10123,18,526,4,0,
10123,18,542,1,50,
10123,18,590,4,0,
10123,18,686,1,40,
10124,17,1,1,1,
10124,17,3,1,23,
10124,17,14,4,0,
10124,17,19,4,0,
10124,17,45,1,4,
10124,17,64,1,6,
10124,17,92,4,0,
10124,17,97,1,46,
10124,17,104,4,0,
10124,17,119,1,43,
10124,17,156,4,0,
10124,17,164,4,0,
10124,17,182,4,0,
10124,17,201,4,0,
10124,17,207,4,0,
10124,17,211,4,0,
10124,17,213,4,0,
10124,17,214,4,0,
10124,17,216,4,0,
10124,17,218,4,0,
10124,17,219,4,0,
10124,17,226,1,16,
10124,17,237,4,0,
10124,17,263,4,0,
10124,17,269,4,0,
10124,17,270,1,10,
10124,17,297,1,20,
10124,17,298,1,26,
10124,17,314,1,13,
10124,17,332,4,0,
10124,17,347,4,0,
10124,17,355,1,30,
10124,17,355,4,0,
10124,17,369,4,0,
10124,17,373,4,0,
10124,17,403,1,36,
10124,17,445,1,33,
10124,17,496,4,0,
10124,17,511,4,0,
10124,17,512,4,0,
10124,17,526,4,0,
10124,17,542,1,50,
10124,17,590,4,0,
10124,17,686,1,40,
10124,18,1,1,1,
10124,18,3,1,23,
10124,18,14,4,0,
10124,18,19,4,0,
10124,18,45,1,4,
10124,18,64,1,6,
10124,18,92,4,0,
10124,18,97,1,46,
10124,18,104,4,0,
10124,18,119,1,43,
10124,18,156,4,0,
10124,18,164,4,0,
10124,18,182,4,0,
10124,18,201,4,0,
10124,18,207,4,0,
10124,18,211,4,0,
10124,18,213,4,0,
10124,18,214,4,0,
10124,18,216,4,0,
10124,18,218,4,0,
10124,18,219,4,0,
10124,18,226,1,16,
10124,18,237,4,0,
10124,18,263,4,0,
10124,18,269,4,0,
10124,18,270,1,10,
10124,18,297,1,20,
10124,18,298,1,26,
10124,18,314,1,13,
10124,18,332,4,0,
10124,18,347,4,0,
10124,18,355,1,30,
10124,18,355,4,0,
10124,18,369,4,0,
10124,18,373,4,0,
10124,18,403,1,36,
10124,18,445,1,33,
10124,18,496,4,0,
10124,18,511,4,0,
10124,18,512,4,0,
10124,18,526,4,0,
10124,18,542,1,50,
10124,18,590,4,0,
10124,18,686,1,40,
10125,17,1,1,1,
10125,17,3,1,23,
10125,17,14,4,0,
10125,17,19,4,0,
10125,17,45,1,4,
10125,17,64,1,6,
10125,17,92,4,0,
10125,17,97,1,46,
10125,17,104,4,0,
10125,17,119,1,43,
10125,17,156,4,0,
10125,17,164,4,0,
10125,17,182,4,0,
10125,17,201,4,0,
10125,17,207,4,0,
10125,17,211,4,0,
10125,17,213,4,0,
10125,17,214,4,0,
10125,17,216,4,0,
10125,17,218,4,0,
10125,17,219,4,0,
10125,17,226,1,16,
10125,17,237,4,0,
10125,17,263,4,0,
10125,17,269,4,0,
10125,17,270,1,10,
10125,17,297,1,20,
10125,17,298,1,26,
10125,17,314,1,13,
10125,17,332,4,0,
10125,17,347,4,0,
10125,17,355,1,30,
10125,17,355,4,0,
10125,17,369,4,0,
10125,17,373,4,0,
10125,17,403,1,36,
10125,17,445,1,33,
10125,17,496,4,0,
10125,17,511,4,0,
10125,17,512,4,0,
10125,17,526,4,0,
10125,17,542,1,50,
10125,17,590,4,0,
10125,17,686,1,40,
10125,18,1,1,1,
10125,18,3,1,23,
10125,18,14,4,0,
10125,18,19,4,0,
10125,18,45,1,4,
10125,18,64,1,6,
10125,18,92,4,0,
10125,18,97,1,46,
10125,18,104,4,0,
10125,18,119,1,43,
10125,18,156,4,0,
10125,18,164,4,0,
10125,18,182,4,0,
10125,18,201,4,0,
10125,18,207,4,0,
10125,18,211,4,0,
10125,18,213,4,0,
10125,18,214,4,0,
10125,18,216,4,0,
10125,18,218,4,0,
10125,18,219,4,0,
10125,18,226,1,16,
10125,18,237,4,0,
10125,18,263,4,0,
10125,18,269,4,0,
10125,18,270,1,10,
10125,18,297,1,20,
10125,18,298,1,26,
10125,18,314,1,13,
10125,18,332,4,0,
10125,18,347,4,0,
10125,18,355,1,30,
10125,18,355,4,0,
10125,18,369,4,0,
10125,18,373,4,0,
10125,18,403,1,36,
10125,18,445,1,33,
10125,18,496,4,0,
10125,18,511,4,0,
10125,18,512,4,0,
10125,18,526,4,0,
10125,18,542,1,50,
10125,18,590,4,0,
10125,18,686,1,40,
10126,17,14,4,0,
10126,17,28,1,1,6
10126,17,28,1,4,
10126,17,33,1,1,4
10126,17,43,1,1,5
10126,17,44,1,1,7
10126,17,44,1,7,
10126,17,46,1,26,
10126,17,46,4,0,
10126,17,68,1,0,
10126,17,68,1,1,1
10126,17,88,1,15,
10126,17,92,4,0,
10126,17,104,4,0,
10126,17,156,4,0,
10126,17,157,1,34,
10126,17,157,4,0,
10126,17,164,4,0,
10126,17,179,1,1,2
10126,17,182,4,0,
10126,17,184,1,37,
10126,17,207,4,0,
10126,17,213,4,0,
10126,17,214,4,0,
10126,17,216,4,0,
10126,17,218,4,0,
10126,17,237,4,0,
10126,17,242,1,40,
10126,17,263,4,0,
10126,17,269,1,1,3
10126,17,269,4,0,
10126,17,280,4,0,
10126,17,316,1,18,
10126,17,317,1,23,
10126,17,317,4,0,
10126,17,336,1,12,
10126,17,339,4,0,
10126,17,397,4,0,
10126,17,431,1,45,
10126,17,444,1,48,
10126,17,444,4,0,
10126,17,446,1,29,
10126,17,496,4,0,
10126,17,497,4,0,
10126,17,555,4,0,
10126,17,590,4,0,
10126,18,14,4,0,
10126,18,28,1,1,6
10126,18,28,1,4,
10126,18,33,1,1,4
10126,18,43,1,1,5
10126,18,44,1,1,7
10126,18,44,1,7,
10126,18,46,1,26,
10126,18,46,4,0,
10126,18,68,1,0,
10126,18,68,1,1,1
10126,18,88,1,15,
10126,18,92,4,0,
10126,18,104,4,0,
10126,18,156,4,0,
10126,18,157,1,34,
10126,18,157,4,0,
10126,18,164,4,0,
10126,18,179,1,1,2
10126,18,182,4,0,
10126,18,184,1,37,
10126,18,207,4,0,
10126,18,213,4,0,
10126,18,214,4,0,
10126,18,216,4,0,
10126,18,218,4,0,
10126,18,237,4,0,
10126,18,242,1,40,
10126,18,263,4,0,
10126,18,269,1,1,3
10126,18,269,4,0,
10126,18,280,4,0,
10126,18,316,1,18,
10126,18,317,1,23,
10126,18,317,4,0,
10126,18,336,1,12,
10126,18,339,4,0,
10126,18,397,4,0,
10126,18,431,1,45,
10126,18,444,1,48,
10126,18,444,4,0,
10126,18,446,1,29,
10126,18,496,4,0,
10126,18,497,4,0,
10126,18,555,4,0,
10126,18,590,4,0,
10127,17,36,1,25,
10127,17,38,1,41,
10127,17,45,1,1,2
10127,17,55,1,1,1
10127,17,56,1,54,
10127,17,57,4,0,
10127,17,58,4,0,
10127,17,89,4,0,
10127,17,92,4,0,
10127,17,104,4,0,
10127,17,127,4,0,
10127,17,156,4,0,
10127,17,164,4,0,
10127,17,182,4,0,
10127,17,185,1,9,
10127,17,207,4,0,
10127,17,213,4,0,
10127,17,214,4,0,
10127,17,216,4,0,
10127,17,218,4,0,
10127,17,237,4,0,
10127,17,240,4,0,
10127,17,251,1,33,
10127,17,258,4,0,
10127,17,263,4,0,
10127,17,270,1,6,
10127,17,283,1,49,
10127,17,291,1,30,
10127,17,362,1,14,
10127,17,369,4,0,
10127,17,392,1,17,
10127,17,401,1,38,
10127,17,487,1,46,
10127,17,496,4,0,
10127,17,503,4,0,
10127,17,523,4,0,
10127,17,590,4,0,
10127,17,715,1,22,
10127,18,36,1,25,
10127,18,38,1,41,
10127,18,45,1,1,2
10127,18,55,1,1,1
10127,18,56,1,54,
10127,18,57,4,0,
10127,18,58,4,0,
10127,18,89,4,0,
10127,18,92,4,0,
10127,18,104,4,0,
10127,18,127,4,0,
10127,18,156,4,0,
10127,18,164,4,0,
10127,18,182,4,0,
10127,18,185,1,9,
10127,18,207,4,0,
10127,18,213,4,0,
10127,18,214,4,0,
10127,18,216,4,0,
10127,18,218,4,0,
10127,18,237,4,0,
10127,18,240,4,0,
10127,18,251,1,33,
10127,18,258,4,0,
10127,18,263,4,0,
10127,18,270,1,6,
10127,18,283,1,49,
10127,18,291,1,30,
10127,18,362,1,14,
10127,18,369,4,0,
10127,18,392,1,17,
10127,18,401,1,38,
10127,18,487,1,46,
10127,18,496,4,0,
10127,18,503,4,0,
10127,18,523,4,0,
10127,18,590,4,0,
10127,18,715,1,22,
10128,17,14,4,0,
10128,17,63,4,0,
10128,17,74,1,1,6
10128,17,74,1,14,
10128,17,75,1,1,5
10128,17,75,1,10,
10128,17,76,4,0,
10128,17,92,4,0,
10128,17,104,4,0,
10128,17,141,4,0,
10128,17,156,4,0,
10128,17,163,1,32,
10128,17,164,4,0,
10128,17,182,4,0,
10128,17,206,4,0,
10128,17,207,4,0,
10128,17,210,1,1,3
10128,17,213,4,0,
10128,17,214,4,0,
10128,17,216,4,0,
10128,17,218,4,0,
10128,17,219,4,0,
10128,17,230,1,40,
10128,17,235,1,28,
10128,17,237,4,0,
10128,17,241,1,55,
10128,17,241,4,0,
10128,17,263,4,0,
10128,17,267,4,0,
10128,17,275,1,19,
10128,17,280,4,0,
10128,17,332,4,0,
10128,17,348,1,23,
10128,17,371,4,0,
10128,17,374,4,0,
10128,17,398,4,0,
10128,17,404,1,1,2
10128,17,404,4,0,
10128,17,412,4,0,
10128,17,416,4,0,
10128,17,447,4,0,
10128,17,490,4,0,
10128,17,496,4,0,
10128,17,572,1,0,
10128,17,572,1,1,1
10128,17,590,4,0,
10128,17,669,1,47,
10128,17,670,1,1,4
10128,17,670,1,5,
10128,18,14,4,0,
10128,18,63,4,0,
10128,18,74,1,1,7
10128,18,74,1,14,
10128,18,75,1,1,6
10128,18,75,1,10,
10128,18,76,4,0,
10128,18,92,4,0,
10128,18,104,4,0,
10128,18,141,4,0,
10128,18,156,4,0,
10128,18,163,1,32,
10128,18,164,4,0,
10128,18,182,4,0,
10128,18,206,4,0,
10128,18,207,4,0,
10128,18,210,1,1,4
10128,18,213,4,0,
10128,18,214,4,0,
10128,18,216,4,0,
10128,18,218,4,0,
10128,18,219,4,0,
10128,18,230,1,40,
10128,18,235,1,28,
10128,18,237,4,0,
10128,18,241,1,55,
10128,18,241,4,0,
10128,18,263,4,0,
10128,18,267,4,0,
10128,18,275,1,19,
10128,18,280,4,0,
10128,18,332,4,0,
10128,18,348,1,23,
10128,18,371,4,0,
10128,18,374,4,0,
10128,18,398,4,0,
10128,18,400,1,1,3
10128,18,404,1,1,2
10128,18,404,4,0,
10128,18,412,4,0,
10128,18,416,4,0,
10128,18,447,4,0,
10128,18,490,4,0,
10128,18,496,4,0,
10128,18,572,1,0,
10128,18,572,1,1,1
10128,18,590,4,0,
10128,18,669,1,47,
10128,18,670,1,1,5
10128,18,670,1,5,
10129,17,1,1,1,6
10129,17,3,1,21,
10129,17,50,1,1,2
10129,17,52,1,1,8
10129,17,52,1,5,
10129,17,53,1,44,
10129,17,53,4,0,
10129,17,82,1,13,
10129,17,92,1,29,
10129,17,92,4,0,
10129,17,104,4,0,
10129,17,123,1,16,
10129,17,126,4,0,
10129,17,139,1,1,7
10129,17,141,4,0,
10129,17,156,4,0,
10129,17,164,4,0,
10129,17,168,4,0,
10129,17,182,4,0,
10129,17,188,4,0,
10129,17,207,1,1,5
10129,17,207,4,0,
10129,17,213,4,0,
10129,17,214,4,0,
10129,17,216,4,0,
10129,17,218,4,0,
10129,17,227,1,1,3
10129,17,230,1,1,9
10129,17,230,1,8,
10129,17,237,4,0,
10129,17,259,1,1,4
10129,17,259,4,0,
10129,17,261,4,0,
10129,17,263,4,0,
10129,17,269,4,0,
10129,17,315,4,0,
10129,17,337,4,0,
10129,17,371,4,0,
10129,17,374,4,0,
10129,17,398,4,0,
10129,17,406,1,56,
10129,17,417,1,32,
10129,17,421,4,0,
10129,17,445,1,0,
10129,17,445,1,1,1
10129,17,474,1,39,
10129,17,474,4,0,
10129,17,481,1,24,
10129,17,482,4,0,
10129,17,488,4,0,
10129,17,496,4,0,
10129,17,512,4,0,
10129,17,525,4,0,
10129,17,590,4,0,
10129,17,599,1,51,
10129,18,1,1,1,6
10129,18,3,1,21,
10129,18,50,1,1,2
10129,18,52,1,1,8
10129,18,52,1,5,
10129,18,53,1,44,
10129,18,53,4,0,
10129,18,82,1,13,
10129,18,92,1,29,
10129,18,92,4,0,
10129,18,104,4,0,
10129,18,123,1,16,
10129,18,126,4,0,
10129,18,139,1,1,7
10129,18,141,4,0,
10129,18,156,4,0,
10129,18,164,4,0,
10129,18,168,4,0,
10129,18,182,4,0,
10129,18,188,4,0,
10129,18,207,1,1,5
10129,18,207,4,0,
10129,18,213,4,0,
10129,18,214,4,0,
10129,18,216,4,0,
10129,18,218,4,0,
10129,18,227,1,1,3
10129,18,230,1,1,9
10129,18,230,1,8,
10129,18,237,4,0,
10129,18,259,1,1,4
10129,18,259,4,0,
10129,18,261,4,0,
10129,18,263,4,0,
10129,18,269,4,0,
10129,18,315,4,0,
10129,18,337,4,0,
10129,18,371,4,0,
10129,18,374,4,0,
10129,18,398,4,0,
10129,18,406,1,56,
10129,18,417,1,32,
10129,18,421,4,0,
10129,18,445,1,0,
10129,18,445,1,1,1
10129,18,474,1,39,
10129,18,474,4,0,
10129,18,481,1,24,
10129,18,482,4,0,
10129,18,488,4,0,
10129,18,496,4,0,
10129,18,512,4,0,
10129,18,525,4,0,
10129,18,590,4,0,
10129,18,599,1,51,
10130,17,33,1,1,
10130,17,36,1,29,
10130,17,38,1,43,
10130,17,63,4,0,
10130,17,76,4,0,
10130,17,89,4,0,
10130,17,92,4,0,
10130,17,94,4,0,
10130,17,104,4,0,
10130,17,109,1,10,
10130,17,111,1,3,
10130,17,113,4,0,
10130,17,115,4,0,
10130,17,120,1,22,
10130,17,129,1,15,
10130,17,153,1,50,
10130,17,153,4,0,
10130,17,156,4,0,
10130,17,157,4,0,
10130,17,164,4,0,
10130,17,182,4,0,
10130,17,201,4,0,
10130,17,205,1,8,
10130,17,207,4,0,
10130,17,213,4,0,
10130,17,214,4,0,
10130,17,216,4,0,
10130,17,218,4,0,
10130,17,219,4,0,
10130,17,237,4,0,
10130,17,244,4,0,
10130,17,246,1,17,
10130,17,263,4,0,
10130,17,317,4,0,
10130,17,322,1,36,
10130,17,347,4,0,
10130,17,360,4,0,
10130,17,369,4,0,
10130,17,397,4,0,
10130,17,408,1,38,
10130,17,416,4,0,
10130,17,444,4,0,
10130,17,446,1,24,
10130,17,451,4,0,
10130,17,475,1,31,
10130,17,496,4,0,
10130,17,504,1,45,
10130,17,512,4,0,
10130,17,523,4,0,
10130,17,590,4,0,
10130,17,605,4,0,
10130,18,33,1,1,
10130,18,36,1,29,
10130,18,38,1,43,
10130,18,63,4,0,
10130,18,76,4,0,
10130,18,89,4,0,
10130,18,92,4,0,
10130,18,94,4,0,
10130,18,104,4,0,
10130,18,109,1,10,
10130,18,111,1,3,
10130,18,113,4,0,
10130,18,115,4,0,
10130,18,120,1,22,
10130,18,129,1,15,
10130,18,153,1,50,
10130,18,153,4,0,
10130,18,156,4,0,
10130,18,157,4,0,
10130,18,164,4,0,
10130,18,182,4,0,
10130,18,201,4,0,
10130,18,205,1,8,
10130,18,207,4,0,
10130,18,213,4,0,
10130,18,214,4,0,
10130,18,216,4,0,
10130,18,218,4,0,
10130,18,219,4,0,
10130,18,237,4,0,
10130,18,244,4,0,
10130,18,246,1,17,
10130,18,263,4,0,
10130,18,317,4,0,
10130,18,322,1,36,
10130,18,347,4,0,
10130,18,360,4,0,
10130,18,369,4,0,
10130,18,397,4,0,
10130,18,408,1,38,
10130,18,416,4,0,
10130,18,444,4,0,
10130,18,446,1,24,
10130,18,451,4,0,
10130,18,475,1,31,
10130,18,496,4,0,
10130,18,504,1,45,
10130,18,512,4,0,
10130,18,523,4,0,
10130,18,590,4,0,
10130,18,605,4,0,
10131,17,33,1,1,
10131,17,36,1,29,
10131,17,38,1,43,
10131,17,63,4,0,
10131,17,76,4,0,
10131,17,89,4,0,
10131,17,92,4,0,
10131,17,94,4,0,
10131,17,104,4,0,
10131,17,109,1,10,
10131,17,111,1,3,
10131,17,113,4,0,
10131,17,115,4,0,
10131,17,120,1,22,
10131,17,129,1,15,
10131,17,153,1,50,
10131,17,153,4,0,
10131,17,156,4,0,
10131,17,157,4,0,
10131,17,164,4,0,
10131,17,182,4,0,
10131,17,201,4,0,
10131,17,205,1,8,
10131,17,207,4,0,
10131,17,213,4,0,
10131,17,214,4,0,
10131,17,216,4,0,
10131,17,218,4,0,
10131,17,219,4,0,
10131,17,237,4,0,
10131,17,244,4,0,
10131,17,246,1,17,
10131,17,263,4,0,
10131,17,317,4,0,
10131,17,322,1,36,
10131,17,347,4,0,
10131,17,360,4,0,
10131,17,369,4,0,
10131,17,397,4,0,
10131,17,408,1,38,
10131,17,416,4,0,
10131,17,444,4,0,
10131,17,446,1,24,
10131,17,451,4,0,
10131,17,475,1,31,
10131,17,496,4,0,
10131,17,504,1,45,
10131,17,512,4,0,
10131,17,523,4,0,
10131,17,590,4,0,
10131,17,605,4,0,
10131,18,33,1,1,
10131,18,36,1,29,
10131,18,38,1,43,
10131,18,63,4,0,
10131,18,76,4,0,
10131,18,89,4,0,
10131,18,92,4,0,
10131,18,94,4,0,
10131,18,104,4,0,
10131,18,109,1,10,
10131,18,111,1,3,
10131,18,113,4,0,
10131,18,115,4,0,
10131,18,120,1,22,
10131,18,129,1,15,
10131,18,153,1,50,
10131,18,153,4,0,
10131,18,156,4,0,
10131,18,157,4,0,
10131,18,164,4,0,
10131,18,182,4,0,
10131,18,201,4,0,
10131,18,205,1,8,
10131,18,207,4,0,
10131,18,213,4,0,
10131,18,214,4,0,
10131,18,216,4,0,
10131,18,218,4,0,
10131,18,219,4,0,
10131,18,237,4,0,
10131,18,244,4,0,
10131,18,246,1,17,
10131,18,263,4,0,
10131,18,317,4,0,
10131,18,322,1,36,
10131,18,347,4,0,
10131,18,360,4,0,
10131,18,369,4,0,
10131,18,397,4,0,
10131,18,408,1,38,
10131,18,416,4,0,
10131,18,444,4,0,
10131,18,446,1,24,
10131,18,451,4,0,
10131,18,475,1,31,
10131,18,496,4,0,
10131,18,504,1,45,
10131,18,512,4,0,
10131,18,523,4,0,
10131,18,590,4,0,
10131,18,605,4,0,
10132,17,33,1,1,
10132,17,36,1,29,
10132,17,38,1,43,
10132,17,63,4,0,
10132,17,76,4,0,
10132,17,89,4,0,
10132,17,92,4,0,
10132,17,94,4,0,
10132,17,104,4,0,
10132,17,109,1,10,
10132,17,111,1,3,
10132,17,113,4,0,
10132,17,115,4,0,
10132,17,120,1,22,
10132,17,129,1,15,
10132,17,153,1,50,
10132,17,153,4,0,
10132,17,156,4,0,
10132,17,157,4,0,
10132,17,164,4,0,
10132,17,182,4,0,
10132,17,201,4,0,
10132,17,205,1,8,
10132,17,207,4,0,
10132,17,213,4,0,
10132,17,214,4,0,
10132,17,216,4,0,
10132,17,218,4,0,
10132,17,219,4,0,
10132,17,237,4,0,
10132,17,244,4,0,
10132,17,246,1,17,
10132,17,263,4,0,
10132,17,317,4,0,
10132,17,322,1,36,
10132,17,347,4,0,
10132,17,360,4,0,
10132,17,369,4,0,
10132,17,397,4,0,
10132,17,408,1,38,
10132,17,416,4,0,
10132,17,444,4,0,
10132,17,446,1,24,
10132,17,451,4,0,
10132,17,475,1,31,
10132,17,496,4,0,
10132,17,504,1,45,
10132,17,512,4,0,
10132,17,523,4,0,
10132,17,590,4,0,
10132,17,605,4,0,
10132,18,33,1,1,
10132,18,36,1,29,
10132,18,38,1,43,
10132,18,63,4,0,
10132,18,76,4,0,
10132,18,89,4,0,
10132,18,92,4,0,
10132,18,94,4,0,
10132,18,104,4,0,
10132,18,109,1,10,
10132,18,111,1,3,
10132,18,113,4,0,
10132,18,115,4,0,
10132,18,120,1,22,
10132,18,129,1,15,
10132,18,153,1,50,
10132,18,153,4,0,
10132,18,156,4,0,
10132,18,157,4,0,
10132,18,164,4,0,
10132,18,182,4,0,
10132,18,201,4,0,
10132,18,205,1,8,
10132,18,207,4,0,
10132,18,213,4,0,
10132,18,214,4,0,
10132,18,216,4,0,
10132,18,218,4,0,
10132,18,219,4,0,
10132,18,237,4,0,
10132,18,244,4,0,
10132,18,246,1,17,
10132,18,263,4,0,
10132,18,317,4,0,
10132,18,322,1,36,
10132,18,347,4,0,
10132,18,360,4,0,
10132,18,369,4,0,
10132,18,397,4,0,
10132,18,408,1,38,
10132,18,416,4,0,
10132,18,444,4,0,
10132,18,446,1,24,
10132,18,451,4,0,
10132,18,475,1,31,
10132,18,496,4,0,
10132,18,504,1,45,
10132,18,512,4,0,
10132,18,523,4,0,
10132,18,590,4,0,
10132,18,605,4,0,
10133,17,33,1,1,
10133,17,36,1,29,
10133,17,38,1,43,
10133,17,63,4,0,
10133,17,76,4,0,
10133,17,89,4,0,
10133,17,92,4,0,
10133,17,94,4,0,
10133,17,104,4,0,
10133,17,109,1,10,
10133,17,111,1,3,
10133,17,113,4,0,
10133,17,115,4,0,
10133,17,120,1,22,
10133,17,129,1,15,
10133,17,153,1,50,
10133,17,153,4,0,
10133,17,156,4,0,
10133,17,157,4,0,
10133,17,164,4,0,
10133,17,182,4,0,
10133,17,201,4,0,
10133,17,205,1,8,
10133,17,207,4,0,
10133,17,213,4,0,
10133,17,214,4,0,
10133,17,216,4,0,
10133,17,218,4,0,
10133,17,219,4,0,
10133,17,237,4,0,
10133,17,244,4,0,
10133,17,246,1,17,
10133,17,263,4,0,
10133,17,317,4,0,
10133,17,322,1,36,
10133,17,347,4,0,
10133,17,360,4,0,
10133,17,369,4,0,
10133,17,397,4,0,
10133,17,408,1,38,
10133,17,416,4,0,
10133,17,444,4,0,
10133,17,446,1,24,
10133,17,451,4,0,
10133,17,475,1,31,
10133,17,496,4,0,
10133,17,504,1,45,
10133,17,512,4,0,
10133,17,523,4,0,
10133,17,590,4,0,
10133,17,605,4,0,
10133,18,33,1,1,
10133,18,36,1,29,
10133,18,38,1,43,
10133,18,63,4,0,
10133,18,76,4,0,
10133,18,89,4,0,
10133,18,92,4,0,
10133,18,94,4,0,
10133,18,104,4,0,
10133,18,109,1,10,
10133,18,111,1,3,
10133,18,113,4,0,
10133,18,115,4,0,
10133,18,120,1,22,
10133,18,129,1,15,
10133,18,153,1,50,
10133,18,153,4,0,
10133,18,156,4,0,
10133,18,157,4,0,
10133,18,164,4,0,
10133,18,182,4,0,
10133,18,201,4,0,
10133,18,205,1,8,
10133,18,207,4,0,
10133,18,213,4,0,
10133,18,214,4,0,
10133,18,216,4,0,
10133,18,218,4,0,
10133,18,219,4,0,
10133,18,237,4,0,
10133,18,244,4,0,
10133,18,246,1,17,
10133,18,263,4,0,
10133,18,317,4,0,
10133,18,322,1,36,
10133,18,347,4,0,
10133,18,360,4,0,
10133,18,369,4,0,
10133,18,397,4,0,
10133,18,408,1,38,
10133,18,416,4,0,
10133,18,444,4,0,
10133,18,446,1,24,
10133,18,451,4,0,
10133,18,475,1,31,
10133,18,496,4,0,
10133,18,504,1,45,
10133,18,512,4,0,
10133,18,523,4,0,
10133,18,590,4,0,
10133,18,605,4,0,
10134,17,33,1,1,
10134,17,36,1,29,
10134,17,38,1,43,
10134,17,63,4,0,
10134,17,76,4,0,
10134,17,89,4,0,
10134,17,92,4,0,
10134,17,94,4,0,
10134,17,104,4,0,
10134,17,109,1,10,
10134,17,111,1,3,
10134,17,113,4,0,
10134,17,115,4,0,
10134,17,120,1,22,
10134,17,129,1,15,
10134,17,153,1,50,
10134,17,153,4,0,
10134,17,156,4,0,
10134,17,157,4,0,
10134,17,164,4,0,
10134,17,182,4,0,
10134,17,201,4,0,
10134,17,205,1,8,
10134,17,207,4,0,
10134,17,213,4,0,
10134,17,214,4,0,
10134,17,216,4,0,
10134,17,218,4,0,
10134,17,219,4,0,
10134,17,237,4,0,
10134,17,244,4,0,
10134,17,246,1,17,
10134,17,263,4,0,
10134,17,317,4,0,
10134,17,322,1,36,
10134,17,347,4,0,
10134,17,360,4,0,
10134,17,369,4,0,
10134,17,397,4,0,
10134,17,408,1,38,
10134,17,416,4,0,
10134,17,444,4,0,
10134,17,446,1,24,
10134,17,451,4,0,
10134,17,475,1,31,
10134,17,496,4,0,
10134,17,504,1,45,
10134,17,512,4,0,
10134,17,523,4,0,
10134,17,590,4,0,
10134,17,605,4,0,
10134,18,33,1,1,
10134,18,36,1,29,
10134,18,38,1,43,
10134,18,63,4,0,
10134,18,76,4,0,
10134,18,89,4,0,
10134,18,92,4,0,
10134,18,94,4,0,
10134,18,104,4,0,
10134,18,109,1,10,
10134,18,111,1,3,
10134,18,113,4,0,
10134,18,115,4,0,
10134,18,120,1,22,
10134,18,129,1,15,
10134,18,153,1,50,
10134,18,153,4,0,
10134,18,156,4,0,
10134,18,157,4,0,
10134,18,164,4,0,
10134,18,182,4,0,
10134,18,201,4,0,
10134,18,205,1,8,
10134,18,207,4,0,
10134,18,213,4,0,
10134,18,214,4,0,
10134,18,216,4,0,
10134,18,218,4,0,
10134,18,219,4,0,
10134,18,237,4,0,
10134,18,244,4,0,
10134,18,246,1,17,
10134,18,263,4,0,
10134,18,317,4,0,
10134,18,322,1,36,
10134,18,347,4,0,
10134,18,360,4,0,
10134,18,369,4,0,
10134,18,397,4,0,
10134,18,408,1,38,
10134,18,416,4,0,
10134,18,444,4,0,
10134,18,446,1,24,
10134,18,451,4,0,
10134,18,475,1,31,
10134,18,496,4,0,
10134,18,504,1,45,
10134,18,512,4,0,
10134,18,523,4,0,
10134,18,590,4,0,
10134,18,605,4,0,
10135,17,33,1,1,
10135,17,36,1,29,
10135,17,38,1,43,
10135,17,63,4,0,
10135,17,76,4,0,
10135,17,89,4,0,
10135,17,92,4,0,
10135,17,94,4,0,
10135,17,104,4,0,
10135,17,109,1,10,
10135,17,111,1,3,
10135,17,113,4,0,
10135,17,115,4,0,
10135,17,120,1,22,
10135,17,129,1,15,
10135,17,153,1,50,
10135,17,153,4,0,
10135,17,156,4,0,
10135,17,157,4,0,
10135,17,164,4,0,
10135,17,182,4,0,
10135,17,201,4,0,
10135,17,205,1,8,
10135,17,207,4,0,
10135,17,213,4,0,
10135,17,214,4,0,
10135,17,216,4,0,
10135,17,218,4,0,
10135,17,219,4,0,
10135,17,237,4,0,
10135,17,244,4,0,
10135,17,246,1,17,
10135,17,263,4,0,
10135,17,317,4,0,
10135,17,322,1,36,
10135,17,347,4,0,
10135,17,360,4,0,
10135,17,369,4,0,
10135,17,397,4,0,
10135,17,408,1,38,
10135,17,416,4,0,
10135,17,444,4,0,
10135,17,446,1,24,
10135,17,451,4,0,
10135,17,475,1,31,
10135,17,496,4,0,
10135,17,504,1,45,
10135,17,512,4,0,
10135,17,523,4,0,
10135,17,590,4,0,
10135,17,605,4,0,
10135,18,33,1,1,
10135,18,36,1,29,
10135,18,38,1,43,
10135,18,63,4,0,
10135,18,76,4,0,
10135,18,89,4,0,
10135,18,92,4,0,
10135,18,94,4,0,
10135,18,104,4,0,
10135,18,109,1,10,
10135,18,111,1,3,
10135,18,113,4,0,
10135,18,115,4,0,
10135,18,120,1,22,
10135,18,129,1,15,
10135,18,153,1,50,
10135,18,153,4,0,
10135,18,156,4,0,
10135,18,157,4,0,
10135,18,164,4,0,
10135,18,182,4,0,
10135,18,201,4,0,
10135,18,205,1,8,
10135,18,207,4,0,
10135,18,213,4,0,
10135,18,214,4,0,
10135,18,216,4,0,
10135,18,218,4,0,
10135,18,219,4,0,
10135,18,237,4,0,
10135,18,244,4,0,
10135,18,246,1,17,
10135,18,263,4,0,
10135,18,317,4,0,
10135,18,322,1,36,
10135,18,347,4,0,
10135,18,360,4,0,
10135,18,369,4,0,
10135,18,397,4,0,
10135,18,408,1,38,
10135,18,416,4,0,
10135,18,444,4,0,
10135,18,446,1,24,
10135,18,451,4,0,
10135,18,475,1,31,
10135,18,496,4,0,
10135,18,504,1,45,
10135,18,512,4,0,
10135,18,523,4,0,
10135,18,590,4,0,
10135,18,605,4,0,
10136,17,33,1,1,
10136,17,36,1,29,
10136,17,38,1,43,
10136,17,63,4,0,
10136,17,76,4,0,
10136,17,89,4,0,
10136,17,92,4,0,
10136,17,94,4,0,
10136,17,104,4,0,
10136,17,109,1,10,
10136,17,111,1,3,
10136,17,113,4,0,
10136,17,115,4,0,
10136,17,120,1,22,
10136,17,129,1,15,
10136,17,153,1,50,
10136,17,153,4,0,
10136,17,156,4,0,
10136,17,157,4,0,
10136,17,164,4,0,
10136,17,182,4,0,
10136,17,201,4,0,
10136,17,205,1,8,
10136,17,207,4,0,
10136,17,213,4,0,
10136,17,214,4,0,
10136,17,216,4,0,
10136,17,218,4,0,
10136,17,219,4,0,
10136,17,237,4,0,
10136,17,244,4,0,
10136,17,246,1,17,
10136,17,263,4,0,
10136,17,317,4,0,
10136,17,322,1,36,
10136,17,347,4,0,
10136,17,360,4,0,
10136,17,369,4,0,
10136,17,397,4,0,
10136,17,408,1,38,
10136,17,416,4,0,
10136,17,444,4,0,
10136,17,446,1,24,
10136,17,451,4,0,
10136,17,475,1,31,
10136,17,496,4,0,
10136,17,504,1,45,
10136,17,512,4,0,
10136,17,523,4,0,
10136,17,590,4,0,
10136,17,605,4,0,
10136,18,33,1,1,
10136,18,36,1,29,
10136,18,38,1,43,
10136,18,63,4,0,
10136,18,76,4,0,
10136,18,89,4,0,
10136,18,92,4,0,
10136,18,94,4,0,
10136,18,104,4,0,
10136,18,109,1,10,
10136,18,111,1,3,
10136,18,113,4,0,
10136,18,115,4,0,
10136,18,120,1,22,
10136,18,129,1,15,
10136,18,153,1,50,
10136,18,153,4,0,
10136,18,156,4,0,
10136,18,157,4,0,
10136,18,164,4,0,
10136,18,182,4,0,
10136,18,201,4,0,
10136,18,205,1,8,
10136,18,207,4,0,
10136,18,213,4,0,
10136,18,214,4,0,
10136,18,216,4,0,
10136,18,218,4,0,
10136,18,219,4,0,
10136,18,237,4,0,
10136,18,244,4,0,
10136,18,246,1,17,
10136,18,263,4,0,
10136,18,317,4,0,
10136,18,322,1,36,
10136,18,347,4,0,
10136,18,360,4,0,
10136,18,369,4,0,
10136,18,397,4,0,
10136,18,408,1,38,
10136,18,416,4,0,
10136,18,444,4,0,
10136,18,446,1,24,
10136,18,451,4,0,
10136,18,475,1,31,
10136,18,496,4,0,
10136,18,504,1,45,
10136,18,512,4,0,
10136,18,523,4,0,
10136,18,590,4,0,
10136,18,605,4,0,
10137,17,33,1,1,
10137,17,36,1,29,
10137,17,38,1,43,
10137,17,63,4,0,
10137,17,76,4,0,
10137,17,89,4,0,
10137,17,92,4,0,
10137,17,94,4,0,
10137,17,104,4,0,
10137,17,109,1,10,
10137,17,111,1,3,
10137,17,113,4,0,
10137,17,115,4,0,
10137,17,120,1,22,
10137,17,129,1,15,
10137,17,153,1,50,
10137,17,153,4,0,
10137,17,156,4,0,
10137,17,157,4,0,
10137,17,164,4,0,
10137,17,182,4,0,
10137,17,201,4,0,
10137,17,205,1,8,
10137,17,207,4,0,
10137,17,213,4,0,
10137,17,214,4,0,
10137,17,216,4,0,
10137,17,218,4,0,
10137,17,219,4,0,
10137,17,237,4,0,
10137,17,244,4,0,
10137,17,246,1,17,
10137,17,263,4,0,
10137,17,317,4,0,
10137,17,322,1,36,
10137,17,347,4,0,
10137,17,360,4,0,
10137,17,369,4,0,
10137,17,397,4,0,
10137,17,408,1,38,
10137,17,416,4,0,
10137,17,444,4,0,
10137,17,446,1,24,
10137,17,451,4,0,
10137,17,475,1,31,
10137,17,496,4,0,
10137,17,504,1,45,
10137,17,512,4,0,
10137,17,523,4,0,
10137,17,590,4,0,
10137,17,605,4,0,
10137,18,33,1,1,
10137,18,36,1,29,
10137,18,38,1,43,
10137,18,63,4,0,
10137,18,76,4,0,
10137,18,89,4,0,
10137,18,92,4,0,
10137,18,94,4,0,
10137,18,104,4,0,
10137,18,109,1,10,
10137,18,111,1,3,
10137,18,113,4,0,
10137,18,115,4,0,
10137,18,120,1,22,
10137,18,129,1,15,
10137,18,153,1,50,
10137,18,153,4,0,
10137,18,156,4,0,
10137,18,157,4,0,
10137,18,164,4,0,
10137,18,182,4,0,
10137,18,201,4,0,
10137,18,205,1,8,
10137,18,207,4,0,
10137,18,213,4,0,
10137,18,214,4,0,
10137,18,216,4,0,
10137,18,218,4,0,
10137,18,219,4,0,
10137,18,237,4,0,
10137,18,244,4,0,
10137,18,246,1,17,
10137,18,263,4,0,
10137,18,317,4,0,
10137,18,322,1,36,
10137,18,347,4,0,
10137,18,360,4,0,
10137,18,369,4,0,
10137,18,397,4,0,
10137,18,408,1,38,
10137,18,416,4,0,
10137,18,444,4,0,
10137,18,446,1,24,
10137,18,451,4,0,
10137,18,475,1,31,
10137,18,496,4,0,
10137,18,504,1,45,
10137,18,512,4,0,
10137,18,523,4,0,
10137,18,590,4,0,
10137,18,605,4,0,
10138,17,33,1,1,
10138,17,36,1,29,
10138,17,38,1,43,
10138,17,63,4,0,
10138,17,76,4,0,
10138,17,89,4,0,
10138,17,92,4,0,
10138,17,94,4,0,
10138,17,104,4,0,
10138,17,109,1,10,
10138,17,111,1,3,
10138,17,113,4,0,
10138,17,115,4,0,
10138,17,120,1,22,
10138,17,129,1,15,
10138,17,153,1,50,
10138,17,153,4,0,
10138,17,156,4,0,
10138,17,157,4,0,
10138,17,164,4,0,
10138,17,182,4,0,
10138,17,201,4,0,
10138,17,205,1,8,
10138,17,207,4,0,
10138,17,213,4,0,
10138,17,214,4,0,
10138,17,216,4,0,
10138,17,218,4,0,
10138,17,219,4,0,
10138,17,237,4,0,
10138,17,244,4,0,
10138,17,246,1,17,
10138,17,263,4,0,
10138,17,317,4,0,
10138,17,322,1,36,
10138,17,347,4,0,
10138,17,360,4,0,
10138,17,369,4,0,
10138,17,397,4,0,
10138,17,408,1,38,
10138,17,416,4,0,
10138,17,444,4,0,
10138,17,446,1,24,
10138,17,451,4,0,
10138,17,475,1,31,
10138,17,496,4,0,
10138,17,504,1,45,
10138,17,512,4,0,
10138,17,523,4,0,
10138,17,590,4,0,
10138,17,605,4,0,
10138,18,33,1,1,
10138,18,36,1,29,
10138,18,38,1,43,
10138,18,63,4,0,
10138,18,76,4,0,
10138,18,89,4,0,
10138,18,92,4,0,
10138,18,94,4,0,
10138,18,104,4,0,
10138,18,109,1,10,
10138,18,111,1,3,
10138,18,113,4,0,
10138,18,115,4,0,
10138,18,120,1,22,
10138,18,129,1,15,
10138,18,153,1,50,
10138,18,153,4,0,
10138,18,156,4,0,
10138,18,157,4,0,
10138,18,164,4,0,
10138,18,182,4,0,
10138,18,201,4,0,
10138,18,205,1,8,
10138,18,207,4,0,
10138,18,213,4,0,
10138,18,214,4,0,
10138,18,216,4,0,
10138,18,218,4,0,
10138,18,219,4,0,
10138,18,237,4,0,
10138,18,244,4,0,
10138,18,246,1,17,
10138,18,263,4,0,
10138,18,317,4,0,
10138,18,322,1,36,
10138,18,347,4,0,
10138,18,360,4,0,
10138,18,369,4,0,
10138,18,397,4,0,
10138,18,408,1,38,
10138,18,416,4,0,
10138,18,444,4,0,
10138,18,446,1,24,
10138,18,451,4,0,
10138,18,475,1,31,
10138,18,496,4,0,
10138,18,504,1,45,
10138,18,512,4,0,
10138,18,523,4,0,
10138,18,590,4,0,
10138,18,605,4,0,
10139,17,33,1,1,
10139,17,36,1,29,
10139,17,38,1,43,
10139,17,63,4,0,
10139,17,76,4,0,
10139,17,89,4,0,
10139,17,92,4,0,
10139,17,94,4,0,
10139,17,104,4,0,
10139,17,109,1,10,
10139,17,111,1,3,
10139,17,113,4,0,
10139,17,115,4,0,
10139,17,120,1,22,
10139,17,129,1,15,
10139,17,153,1,50,
10139,17,153,4,0,
10139,17,156,4,0,
10139,17,157,4,0,
10139,17,164,4,0,
10139,17,182,4,0,
10139,17,201,4,0,
10139,17,205,1,8,
10139,17,207,4,0,
10139,17,213,4,0,
10139,17,214,4,0,
10139,17,216,4,0,
10139,17,218,4,0,
10139,17,219,4,0,
10139,17,237,4,0,
10139,17,244,4,0,
10139,17,246,1,17,
10139,17,263,4,0,
10139,17,317,4,0,
10139,17,322,1,36,
10139,17,347,4,0,
10139,17,360,4,0,
10139,17,369,4,0,
10139,17,397,4,0,
10139,17,408,1,38,
10139,17,416,4,0,
10139,17,444,4,0,
10139,17,446,1,24,
10139,17,451,4,0,
10139,17,475,1,31,
10139,17,496,4,0,
10139,17,504,1,45,
10139,17,512,4,0,
10139,17,523,4,0,
10139,17,590,4,0,
10139,17,605,4,0,
10139,18,33,1,1,
10139,18,36,1,29,
10139,18,38,1,43,
10139,18,63,4,0,
10139,18,76,4,0,
10139,18,89,4,0,
10139,18,92,4,0,
10139,18,94,4,0,
10139,18,104,4,0,
10139,18,109,1,10,
10139,18,111,1,3,
10139,18,113,4,0,
10139,18,115,4,0,
10139,18,120,1,22,
10139,18,129,1,15,
10139,18,153,1,50,
10139,18,153,4,0,
10139,18,156,4,0,
10139,18,157,4,0,
10139,18,164,4,0,
10139,18,182,4,0,
10139,18,201,4,0,
10139,18,205,1,8,
10139,18,207,4,0,
10139,18,213,4,0,
10139,18,214,4,0,
10139,18,216,4,0,
10139,18,218,4,0,
10139,18,219,4,0,
10139,18,237,4,0,
10139,18,244,4,0,
10139,18,246,1,17,
10139,18,263,4,0,
10139,18,317,4,0,
10139,18,322,1,36,
10139,18,347,4,0,
10139,18,360,4,0,
10139,18,369,4,0,
10139,18,397,4,0,
10139,18,408,1,38,
10139,18,416,4,0,
10139,18,444,4,0,
10139,18,446,1,24,
10139,18,451,4,0,
10139,18,475,1,31,
10139,18,496,4,0,
10139,18,504,1,45,
10139,18,512,4,0,
10139,18,523,4,0,
10139,18,590,4,0,
10139,18,605,4,0,
10140,17,33,1,1,
10140,17,36,1,29,
10140,17,38,1,43,
10140,17,63,4,0,
10140,17,76,4,0,
10140,17,89,4,0,
10140,17,92,4,0,
10140,17,94,4,0,
10140,17,104,4,0,
10140,17,109,1,10,
10140,17,111,1,3,
10140,17,113,4,0,
10140,17,115,4,0,
10140,17,120,1,22,
10140,17,129,1,15,
10140,17,153,1,50,
10140,17,153,4,0,
10140,17,156,4,0,
10140,17,157,4,0,
10140,17,164,4,0,
10140,17,182,4,0,
10140,17,201,4,0,
10140,17,205,1,8,
10140,17,207,4,0,
10140,17,213,4,0,
10140,17,214,4,0,
10140,17,216,4,0,
10140,17,218,4,0,
10140,17,219,4,0,
10140,17,237,4,0,
10140,17,244,4,0,
10140,17,246,1,17,
10140,17,263,4,0,
10140,17,317,4,0,
10140,17,322,1,36,
10140,17,347,4,0,
10140,17,360,4,0,
10140,17,369,4,0,
10140,17,397,4,0,
10140,17,408,1,38,
10140,17,416,4,0,
10140,17,444,4,0,
10140,17,446,1,24,
10140,17,451,4,0,
10140,17,475,1,31,
10140,17,496,4,0,
10140,17,504,1,45,
10140,17,512,4,0,
10140,17,523,4,0,
10140,17,590,4,0,
10140,17,605,4,0,
10140,18,33,1,1,
10140,18,36,1,29,
10140,18,38,1,43,
10140,18,63,4,0,
10140,18,76,4,0,
10140,18,89,4,0,
10140,18,92,4,0,
10140,18,94,4,0,
10140,18,104,4,0,
10140,18,109,1,10,
10140,18,111,1,3,
10140,18,113,4,0,
10140,18,115,4,0,
10140,18,120,1,22,
10140,18,129,1,15,
10140,18,153,1,50,
10140,18,153,4,0,
10140,18,156,4,0,
10140,18,157,4,0,
10140,18,164,4,0,
10140,18,182,4,0,
10140,18,201,4,0,
10140,18,205,1,8,
10140,18,207,4,0,
10140,18,213,4,0,
10140,18,214,4,0,
10140,18,216,4,0,
10140,18,218,4,0,
10140,18,219,4,0,
10140,18,237,4,0,
10140,18,244,4,0,
10140,18,246,1,17,
10140,18,263,4,0,
10140,18,317,4,0,
10140,18,322,1,36,
10140,18,347,4,0,
10140,18,360,4,0,
10140,18,369,4,0,
10140,18,397,4,0,
10140,18,408,1,38,
10140,18,416,4,0,
10140,18,444,4,0,
10140,18,446,1,24,
10140,18,451,4,0,
10140,18,475,1,31,
10140,18,496,4,0,
10140,18,504,1,45,
10140,18,512,4,0,
10140,18,523,4,0,
10140,18,590,4,0,
10140,18,605,4,0,
10141,17,33,1,1,
10141,17,36,1,29,
10141,17,38,1,43,
10141,17,63,4,0,
10141,17,76,4,0,
10141,17,89,4,0,
10141,17,92,4,0,
10141,17,94,4,0,
10141,17,104,4,0,
10141,17,109,1,10,
10141,17,111,1,3,
10141,17,113,4,0,
10141,17,115,4,0,
10141,17,120,1,22,
10141,17,129,1,15,
10141,17,153,1,50,
10141,17,153,4,0,
10141,17,156,4,0,
10141,17,157,4,0,
10141,17,164,4,0,
10141,17,182,4,0,
10141,17,201,4,0,
10141,17,205,1,8,
10141,17,207,4,0,
10141,17,213,4,0,
10141,17,214,4,0,
10141,17,216,4,0,
10141,17,218,4,0,
10141,17,219,4,0,
10141,17,237,4,0,
10141,17,244,4,0,
10141,17,246,1,17,
10141,17,263,4,0,
10141,17,317,4,0,
10141,17,322,1,36,
10141,17,347,4,0,
10141,17,360,4,0,
10141,17,369,4,0,
10141,17,397,4,0,
10141,17,408,1,38,
10141,17,416,4,0,
10141,17,444,4,0,
10141,17,446,1,24,
10141,17,451,4,0,
10141,17,475,1,31,
10141,17,496,4,0,
10141,17,504,1,45,
10141,17,512,4,0,
10141,17,523,4,0,
10141,17,590,4,0,
10141,17,605,4,0,
10141,18,33,1,1,
10141,18,36,1,29,
10141,18,38,1,43,
10141,18,63,4,0,
10141,18,76,4,0,
10141,18,89,4,0,
10141,18,92,4,0,
10141,18,94,4,0,
10141,18,104,4,0,
10141,18,109,1,10,
10141,18,111,1,3,
10141,18,113,4,0,
10141,18,115,4,0,
10141,18,120,1,22,
10141,18,129,1,15,
10141,18,153,1,50,
10141,18,153,4,0,
10141,18,156,4,0,
10141,18,157,4,0,
10141,18,164,4,0,
10141,18,182,4,0,
10141,18,201,4,0,
10141,18,205,1,8,
10141,18,207,4,0,
10141,18,213,4,0,
10141,18,214,4,0,
10141,18,216,4,0,
10141,18,218,4,0,
10141,18,219,4,0,
10141,18,237,4,0,
10141,18,244,4,0,
10141,18,246,1,17,
10141,18,263,4,0,
10141,18,317,4,0,
10141,18,322,1,36,
10141,18,347,4,0,
10141,18,360,4,0,
10141,18,369,4,0,
10141,18,397,4,0,
10141,18,408,1,38,
10141,18,416,4,0,
10141,18,444,4,0,
10141,18,446,1,24,
10141,18,451,4,0,
10141,18,475,1,31,
10141,18,496,4,0,
10141,18,504,1,45,
10141,18,512,4,0,
10141,18,523,4,0,
10141,18,590,4,0,
10141,18,605,4,0,
10142,17,33,1,1,
10142,17,36,1,29,
10142,17,38,1,43,
10142,17,63,4,0,
10142,17,76,4,0,
10142,17,89,4,0,
10142,17,92,4,0,
10142,17,94,4,0,
10142,17,104,4,0,
10142,17,109,1,10,
10142,17,111,1,3,
10142,17,113,4,0,
10142,17,115,4,0,
10142,17,120,1,22,
10142,17,129,1,15,
10142,17,153,1,50,
10142,17,153,4,0,
10142,17,156,4,0,
10142,17,157,4,0,
10142,17,164,4,0,
10142,17,182,4,0,
10142,17,201,4,0,
10142,17,205,1,8,
10142,17,207,4,0,
10142,17,213,4,0,
10142,17,214,4,0,
10142,17,216,4,0,
10142,17,218,4,0,
10142,17,219,4,0,
10142,17,237,4,0,
10142,17,244,4,0,
10142,17,246,1,17,
10142,17,263,4,0,
10142,17,317,4,0,
10142,17,322,1,36,
10142,17,347,4,0,
10142,17,360,4,0,
10142,17,369,4,0,
10142,17,397,4,0,
10142,17,408,1,38,
10142,17,416,4,0,
10142,17,444,4,0,
10142,17,446,1,24,
10142,17,451,4,0,
10142,17,475,1,31,
10142,17,496,4,0,
10142,17,504,1,45,
10142,17,512,4,0,
10142,17,523,4,0,
10142,17,590,4,0,
10142,17,605,4,0,
10142,18,33,1,1,
10142,18,36,1,29,
10142,18,38,1,43,
10142,18,63,4,0,
10142,18,76,4,0,
10142,18,89,4,0,
10142,18,92,4,0,
10142,18,94,4,0,
10142,18,104,4,0,
10142,18,109,1,10,
10142,18,111,1,3,
10142,18,113,4,0,
10142,18,115,4,0,
10142,18,120,1,22,
10142,18,129,1,15,
10142,18,153,1,50,
10142,18,153,4,0,
10142,18,156,4,0,
10142,18,157,4,0,
10142,18,164,4,0,
10142,18,182,4,0,
10142,18,201,4,0,
10142,18,205,1,8,
10142,18,207,4,0,
10142,18,213,4,0,
10142,18,214,4,0,
10142,18,216,4,0,
10142,18,218,4,0,
10142,18,219,4,0,
10142,18,237,4,0,
10142,18,244,4,0,
10142,18,246,1,17,
10142,18,263,4,0,
10142,18,317,4,0,
10142,18,322,1,36,
10142,18,347,4,0,
10142,18,360,4,0,
10142,18,369,4,0,
10142,18,397,4,0,
10142,18,408,1,38,
10142,18,416,4,0,
10142,18,444,4,0,
10142,18,446,1,24,
10142,18,451,4,0,
10142,18,475,1,31,
10142,18,496,4,0,
10142,18,504,1,45,
10142,18,512,4,0,
10142,18,523,4,0,
10142,18,590,4,0,
10142,18,605,4,0,
10143,17,10,1,1,3
10143,17,14,4,0,
10143,17,57,4,0,
10143,17,63,4,0,
10143,17,85,4,0,
10143,17,86,4,0,
10143,17,87,4,0,
10143,17,92,4,0,
10143,17,94,4,0,
10143,17,102,1,19,
10143,17,104,1,5,
10143,17,104,4,0,
10143,17,113,4,0,
10143,17,138,4,0,
10143,17,141,4,0,
10143,17,150,1,1,2
10143,17,156,4,0,
10143,17,163,1,32,
10143,17,164,4,0,
10143,17,168,4,0,
10143,17,182,4,0,
10143,17,185,1,23,
10143,17,204,1,28,
10143,17,207,4,0,
10143,17,213,4,0,
10143,17,214,4,0,
10143,17,216,4,0,
10143,17,218,4,0,
10143,17,219,4,0,
10143,17,220,1,50,
10143,17,237,4,0,
10143,17,244,4,0,
10143,17,247,4,0,
10143,17,261,4,0,
10143,17,263,4,0,
10143,17,269,4,0,
10143,17,310,1,1,4
10143,17,339,4,0,
10143,17,371,4,0,
10143,17,373,4,0,
10143,17,374,4,0,
10143,17,383,1,1,5
10143,17,399,4,0,
10143,17,404,4,0,
10143,17,421,1,37,
10143,17,421,4,0,
10143,17,425,1,14,
10143,17,433,4,0,
10143,17,451,4,0,
10143,17,452,1,1,1
10143,17,468,1,41,
10143,17,496,4,0,
10143,17,526,4,0,
10143,17,583,1,46,
10143,17,590,4,0,
10143,17,605,4,0,
10143,17,608,1,10,
10143,17,611,4,0,
10143,18,10,1,1,3
10143,18,14,4,0,
10143,18,63,4,0,
10143,18,85,4,0,
10143,18,86,4,0,
10143,18,87,4,0,
10143,18,92,4,0,
10143,18,94,4,0,
10143,18,102,1,19,
10143,18,104,1,5,
10143,18,104,4,0,
10143,18,113,4,0,
10143,18,138,4,0,
10143,18,141,4,0,
10143,18,150,1,1,2
10143,18,156,4,0,
10143,18,163,1,32,
10143,18,164,4,0,
10143,18,168,4,0,
10143,18,182,4,0,
10143,18,185,1,23,
10143,18,204,1,28,
10143,18,207,4,0,
10143,18,213,4,0,
10143,18,214,4,0,
10143,18,216,4,0,
10143,18,218,4,0,
10143,18,219,4,0,
10143,18,220,1,50,
10143,18,237,4,0,
10143,18,244,4,0,
10143,18,247,4,0,
10143,18,261,4,0,
10143,18,263,4,0,
10143,18,269,4,0,
10143,18,310,1,1,4
10143,18,339,4,0,
10143,18,371,4,0,
10143,18,373,4,0,
10143,18,374,4,0,
10143,18,383,1,1,5
10143,18,399,4,0,
10143,18,404,4,0,
10143,18,421,1,37,
10143,18,421,4,0,
10143,18,425,1,14,
10143,18,433,4,0,
10143,18,451,4,0,
10143,18,452,1,1,1
10143,18,468,1,41,
10143,18,496,4,0,
10143,18,526,4,0,
10143,18,583,1,46,
10143,18,590,4,0,
10143,18,605,4,0,
10143,18,608,1,10,
10143,18,611,4,0,
10144,17,10,1,1,3
10144,17,14,4,0,
10144,17,63,4,0,
10144,17,85,4,0,
10144,17,86,4,0,
10144,17,87,4,0,
10144,17,92,4,0,
10144,17,94,4,0,
10144,17,102,1,19,
10144,17,104,1,5,
10144,17,104,4,0,
10144,17,113,4,0,
10144,17,138,4,0,
10144,17,141,4,0,
10144,17,150,1,1,2
10144,17,156,4,0,
10144,17,163,1,32,
10144,17,164,4,0,
10144,17,168,4,0,
10144,17,182,4,0,
10144,17,185,1,23,
10144,17,204,1,28,
10144,17,207,4,0,
10144,17,213,4,0,
10144,17,214,4,0,
10144,17,216,4,0,
10144,17,218,4,0,
10144,17,219,4,0,
10144,17,220,1,50,
10144,17,237,4,0,
10144,17,244,4,0,
10144,17,247,4,0,
10144,17,261,4,0,
10144,17,263,4,0,
10144,17,269,4,0,
10144,17,310,1,1,4
10144,17,339,4,0,
10144,17,371,4,0,
10144,17,373,4,0,
10144,17,374,4,0,
10144,17,383,1,1,5
10144,17,399,4,0,
10144,17,404,4,0,
10144,17,421,1,37,
10144,17,421,4,0,
10144,17,425,1,14,
10144,17,433,4,0,
10144,17,451,4,0,
10144,17,452,1,1,1
10144,17,468,1,41,
10144,17,496,4,0,
10144,17,526,4,0,
10144,17,583,1,46,
10144,17,590,4,0,
10144,17,605,4,0,
10144,17,608,1,10,
10144,17,611,4,0,
10144,18,10,1,1,3
10144,18,14,4,0,
10144,18,63,4,0,
10144,18,85,4,0,
10144,18,86,4,0,
10144,18,87,4,0,
10144,18,92,4,0,
10144,18,94,4,0,
10144,18,102,1,19,
10144,18,104,1,5,
10144,18,104,4,0,
10144,18,113,4,0,
10144,18,138,4,0,
10144,18,141,4,0,
10144,18,150,1,1,2
10144,18,156,4,0,
10144,18,163,1,32,
10144,18,164,4,0,
10144,18,168,4,0,
10144,18,182,4,0,
10144,18,185,1,23,
10144,18,204,1,28,
10144,18,207,4,0,
10144,18,213,4,0,
10144,18,214,4,0,
10144,18,216,4,0,
10144,18,218,4,0,
10144,18,219,4,0,
10144,18,220,1,50,
10144,18,237,4,0,
10144,18,244,4,0,
10144,18,247,4,0,
10144,18,261,4,0,
10144,18,263,4,0,
10144,18,269,4,0,
10144,18,310,1,1,4
10144,18,339,4,0,
10144,18,371,4,0,
10144,18,373,4,0,
10144,18,374,4,0,
10144,18,383,1,1,5
10144,18,399,4,0,
10144,18,404,4,0,
10144,18,421,1,37,
10144,18,421,4,0,
10144,18,425,1,14,
10144,18,433,4,0,
10144,18,451,4,0,
10144,18,452,1,1,1
10144,18,468,1,41,
10144,18,496,4,0,
10144,18,526,4,0,
10144,18,583,1,46,
10144,18,590,4,0,
10144,18,605,4,0,
10144,18,608,1,10,
10144,18,611,4,0,
10145,17,10,1,1,3
10145,17,14,4,0,
10145,17,57,4,0,
10145,17,63,4,0,
10145,17,85,4,0,
10145,17,86,4,0,
10145,17,87,4,0,
10145,17,92,4,0,
10145,17,94,4,0,
10145,17,102,1,19,
10145,17,104,1,5,
10145,17,104,4,0,
10145,17,113,4,0,
10145,17,138,4,0,
10145,17,141,4,0,
10145,17,150,1,1,2
10145,17,156,4,0,
10145,17,163,1,32,
10145,17,164,4,0,
10145,17,168,4,0,
10145,17,182,4,0,
10145,17,185,1,23,
10145,17,204,1,28,
10145,17,207,4,0,
10145,17,213,4,0,
10145,17,214,4,0,
10145,17,216,4,0,
10145,17,218,4,0,
10145,17,219,4,0,
10145,17,220,1,50,
10145,17,237,4,0,
10145,17,244,4,0,
10145,17,247,4,0,
10145,17,261,4,0,
10145,17,263,4,0,
10145,17,269,4,0,
10145,17,310,1,1,4
10145,17,339,4,0,
10145,17,371,4,0,
10145,17,373,4,0,
10145,17,374,4,0,
10145,17,383,1,1,5
10145,17,399,4,0,
10145,17,404,4,0,
10145,17,421,1,37,
10145,17,421,4,0,
10145,17,425,1,14,
10145,17,433,4,0,
10145,17,451,4,0,
10145,17,452,1,1,1
10145,17,468,1,41,
10145,17,496,4,0,
10145,17,526,4,0,
10145,17,583,1,46,
10145,17,590,4,0,
10145,17,605,4,0,
10145,17,608,1,10,
10145,17,611,4,0,
10145,18,10,1,1,3
10145,18,14,4,0,
10145,18,63,4,0,
10145,18,85,4,0,
10145,18,86,4,0,
10145,18,87,4,0,
10145,18,92,4,0,
10145,18,94,4,0,
10145,18,102,1,19,
10145,18,104,1,5,
10145,18,104,4,0,
10145,18,113,4,0,
10145,18,138,4,0,
10145,18,141,4,0,
10145,18,150,1,1,2
10145,18,156,4,0,
10145,18,163,1,32,
10145,18,164,4,0,
10145,18,168,4,0,
10145,18,182,4,0,
10145,18,185,1,23,
10145,18,204,1,28,
10145,18,207,4,0,
10145,18,213,4,0,
10145,18,214,4,0,
10145,18,216,4,0,
10145,18,218,4,0,
10145,18,219,4,0,
10145,18,220,1,50,
10145,18,237,4,0,
10145,18,244,4,0,
10145,18,247,4,0,
10145,18,261,4,0,
10145,18,263,4,0,
10145,18,269,4,0,
10145,18,310,1,1,4
10145,18,339,4,0,
10145,18,371,4,0,
10145,18,373,4,0,
10145,18,374,4,0,
10145,18,383,1,1,5
10145,18,399,4,0,
10145,18,404,4,0,
10145,18,421,1,37,
10145,18,421,4,0,
10145,18,425,1,14,
10145,18,433,4,0,
10145,18,451,4,0,
10145,18,452,1,1,1
10145,18,468,1,41,
10145,18,496,4,0,
10145,18,526,4,0,
10145,18,583,1,46,
10145,18,590,4,0,
10145,18,605,4,0,
10145,18,608,1,10,
10145,18,611,4,0,
10146,17,14,4,0,
10146,17,29,1,25,
10146,17,33,1,1,5
10146,17,43,1,1,6
10146,17,43,1,5,
10146,17,46,4,0,
10146,17,53,4,0,
10146,17,63,4,0,
10146,17,89,4,0,
10146,17,92,4,0,
10146,17,103,1,33,
10146,17,104,4,0,
10146,17,117,1,1,7
10146,17,117,1,9,
10146,17,156,4,0,
10146,17,157,4,0,
10146,17,164,4,0,
10146,17,182,1,1,8
10146,17,182,1,13,
10146,17,182,4,0,
10146,17,184,1,21,
10146,17,187,1,1,3
10146,17,200,1,67,
10146,17,201,4,0,
10146,17,206,4,0,
10146,17,207,4,0,
10146,17,213,4,0,
10146,17,214,4,0,
10146,17,216,4,0,
10146,17,218,4,0,
10146,17,219,4,0,
10146,17,237,4,0,
10146,17,263,4,0,
10146,17,269,4,0,
10146,17,280,4,0,
10146,17,317,4,0,
10146,17,327,1,1,2
10146,17,332,4,0,
10146,17,334,1,38,
10146,17,337,1,43,
10146,17,337,4,0,
10146,17,339,4,0,
10146,17,349,1,59,
10146,17,371,4,0,
10146,17,374,4,0,
10146,17,397,4,0,
10146,17,398,4,0,
10146,17,404,4,0,
10146,17,411,4,0,
10146,17,416,4,0,
10146,17,421,4,0,
10146,17,430,4,0,
10146,17,434,3,0,
10146,17,475,1,1,4
10146,17,496,4,0,
10146,17,497,4,0,
10146,17,523,4,0,
10146,17,525,1,17,
10146,17,525,4,0,
10146,17,526,1,29,
10146,17,526,4,0,
10146,17,568,1,51,
10146,17,590,4,0,
10146,17,691,1,0,
10146,17,691,1,1,1
10146,17,693,4,0,
10146,18,14,4,0,
10146,18,29,1,25,
10146,18,33,1,1,5
10146,18,43,1,1,6
10146,18,43,1,5,
10146,18,46,4,0,
10146,18,53,4,0,
10146,18,63,4,0,
10146,18,89,4,0,
10146,18,92,4,0,
10146,18,103,1,33,
10146,18,104,4,0,
10146,18,117,1,1,7
10146,18,117,1,9,
10146,18,156,4,0,
10146,18,157,4,0,
10146,18,164,4,0,
10146,18,182,1,1,8
10146,18,182,1,13,
10146,18,182,4,0,
10146,18,184,1,21,
10146,18,187,1,1,3
10146,18,200,1,67,
10146,18,201,4,0,
10146,18,206,4,0,
10146,18,207,4,0,
10146,18,213,4,0,
10146,18,214,4,0,
10146,18,216,4,0,
10146,18,218,4,0,
10146,18,219,4,0,
10146,18,237,4,0,
10146,18,263,4,0,
10146,18,269,4,0,
10146,18,280,4,0,
10146,18,317,4,0,
10146,18,327,1,1,2
10146,18,332,4,0,
10146,18,334,1,38,
10146,18,337,1,43,
10146,18,337,4,0,
10146,18,339,4,0,
10146,18,349,1,59,
10146,18,370,1,75,
10146,18,371,4,0,
10146,18,374,4,0,
10146,18,397,4,0,
10146,18,398,4,0,
10146,18,404,4,0,
10146,18,411,4,0,
10146,18,416,4,0,
10146,18,421,4,0,
10146,18,430,4,0,
10146,18,434,3,0,
10146,18,475,1,1,4
10146,18,496,4,0,
10146,18,497,4,0,
10146,18,523,4,0,
10146,18,525,1,17,
10146,18,525,4,0,
10146,18,526,1,29,
10146,18,526,4,0,
10146,18,568,1,51,
10146,18,590,4,0,
10146,18,691,1,0,
10146,18,691,1,1,1
10146,18,693,4,0,
10147,17,49,1,1,6
10147,17,58,4,0,
10147,17,60,1,1,8
10147,17,62,1,17,
10147,17,63,4,0,
10147,17,76,4,0,
10147,17,85,4,0,
10147,17,86,4,0,
10147,17,104,4,0,
10147,17,111,1,1,7
10147,17,113,4,0,
10147,17,115,4,0,
10147,17,153,4,0,
10147,17,164,4,0,
10147,17,170,1,33,
10147,17,182,4,0,
10147,17,206,4,0,
10147,17,207,4,0,
10147,17,216,4,0,
10147,17,218,4,0,
10147,17,220,1,65,
10147,17,237,4,0,
10147,17,247,4,0,
10147,17,270,1,1,5
10147,17,280,4,0,
10147,17,334,1,57,
10147,17,347,4,0,
10147,17,360,4,0,
10147,17,373,4,0,
10147,17,376,1,97,
10147,17,381,1,9,
10147,17,391,1,89,
10147,17,396,1,81,
10147,17,411,4,0,
10147,17,412,4,0,
10147,17,416,4,0,
10147,17,429,1,25,
10147,17,430,1,41,
10147,17,430,4,0,
10147,17,433,4,0,
10147,17,442,1,1,4
10147,17,447,4,0,
10147,17,451,4,0,
10147,17,485,1,73,
10147,17,496,4,0,
10147,17,497,4,0,
10147,17,508,1,1,3
10147,17,521,4,0,
10147,17,578,1,1,1
10147,17,590,4,0,
10147,17,605,4,0,
10147,17,674,1,1,2
10147,17,705,1,49,
10147,18,49,1,1,6
10147,18,58,4,0,
10147,18,60,1,1,8
10147,18,62,1,17,
10147,18,63,4,0,
10147,18,76,4,0,
10147,18,85,4,0,
10147,18,86,4,0,
10147,18,104,4,0,
10147,18,111,1,1,7
10147,18,113,4,0,
10147,18,115,4,0,
10147,18,153,4,0,
10147,18,164,4,0,
10147,18,170,1,33,
10147,18,182,4,0,
10147,18,206,4,0,
10147,18,207,4,0,
10147,18,216,4,0,
10147,18,218,4,0,
10147,18,220,1,65,
10147,18,237,4,0,
10147,18,247,4,0,
10147,18,270,1,1,5
10147,18,280,4,0,
10147,18,334,1,57,
10147,18,347,4,0,
10147,18,360,4,0,
10147,18,373,4,0,
10147,18,376,1,97,
10147,18,381,1,9,
10147,18,391,1,89,
10147,18,396,1,81,
10147,18,411,4,0,
10147,18,412,4,0,
10147,18,416,4,0,
10147,18,429,1,25,
10147,18,430,1,41,
10147,18,430,4,0,
10147,18,433,4,0,
10147,18,442,1,1,4
10147,18,447,4,0,
10147,18,451,4,0,
10147,18,485,1,73,
10147,18,496,4,0,
10147,18,497,4,0,
10147,18,508,1,1,3
10147,18,521,4,0,
10147,18,578,1,1,1
10147,18,590,4,0,
10147,18,605,4,0,
10147,18,674,1,1,2
10147,18,705,1,49,
10148,18,21,1,37,
10148,18,39,1,1,1
10148,18,45,1,5,
10148,18,84,1,1,2
10148,18,85,1,42,
10148,18,85,4,0,
10148,18,86,1,18,
10148,18,86,4,0,
10148,18,87,1,58,
10148,18,87,4,0,
10148,18,92,4,0,
10148,18,97,1,45,
10148,18,98,1,10,
10148,18,104,1,23,
10148,18,104,4,0,
10148,18,113,1,53,
10148,18,113,4,0,
10148,18,156,4,0,
10148,18,164,4,0,
10148,18,182,4,0,
10148,18,207,4,0,
10148,18,209,1,26,
10148,18,213,4,0,
10148,18,214,4,0,
10148,18,216,4,0,
10148,18,218,4,0,
10148,18,237,4,0,
10148,18,240,4,0,
10148,18,263,4,0,
10148,18,280,4,0,
10148,18,364,1,21,
10148,18,374,4,0,
10148,18,435,1,34,
10148,18,447,4,0,
10148,18,451,4,0,
10148,18,486,1,13,
10148,18,496,4,0,
10148,18,497,4,0,
10148,18,521,4,0,
10148,18,528,1,50,
10148,18,528,4,0,
10148,18,589,1,7,
10148,18,590,4,0,
10148,18,609,1,29,
10149,18,14,4,0,
10149,18,37,1,33,
10149,18,39,1,1,2
10149,18,39,1,3,
10149,18,43,1,13,
10149,18,45,1,1,1
10149,18,53,4,0,
10149,18,58,4,0,
10149,18,59,4,0,
10149,18,63,4,0,
10149,18,85,4,0,
10149,18,87,4,0,
10149,18,89,4,0,
10149,18,92,4,0,
10149,18,104,4,0,
10149,18,125,1,1,3
10149,18,125,1,7,
10149,18,126,4,0,
10149,18,138,4,0,
10149,18,155,1,21,
10149,18,156,4,0,
10149,18,157,4,0,
10149,18,164,4,0,
10149,18,168,4,0,
10149,18,172,1,1,4
10149,18,172,1,11,
10149,18,182,4,0,
10149,18,198,1,65,
10149,18,201,4,0,
10149,18,206,4,0,
10149,18,207,4,0,
10149,18,213,4,0,
10149,18,214,4,0,
10149,18,216,4,0,
10149,18,218,4,0,
10149,18,237,4,0,
10149,18,240,4,0,
10149,18,241,4,0,
10149,18,247,4,0,
10149,18,261,1,23,
10149,18,261,4,0,
10149,18,263,4,0,
10149,18,280,4,0,
10149,18,283,1,49,
10149,18,317,4,0,
10149,18,332,4,0,
10149,18,374,1,37,
10149,18,374,4,0,
10149,18,394,1,53,
10149,18,399,4,0,
10149,18,411,4,0,
10149,18,416,4,0,
10149,18,444,4,0,
10149,18,479,4,0,
10149,18,488,4,0,
10149,18,496,4,0,
10149,18,497,4,0,
10149,18,506,1,17,
10149,18,514,1,59,
10149,18,523,4,0,
10149,18,590,4,0,
10149,18,693,4,0,
10149,18,707,1,43,
10149,18,708,1,27,
10150,18,71,1,1,2
10150,18,76,4,0,
10150,18,78,1,1,4
10150,18,78,1,7,
10150,18,92,4,0,
10150,18,94,4,0,
10150,18,104,4,0,
10150,18,113,4,0,
10150,18,115,4,0,
10150,18,138,4,0,
10150,18,141,4,0,
10150,18,156,4,0,
10150,18,164,4,0,
10150,18,168,4,0,
10150,18,182,4,0,
10150,18,207,4,0,
10150,18,213,4,0,
10150,18,214,4,0,
10150,18,216,4,0,
10150,18,218,4,0,
10150,18,219,4,0,
10150,18,230,1,21,
10150,18,237,4,0,
10150,18,241,4,0,
10150,18,244,4,0,
10150,18,263,4,0,
10150,18,267,4,0,
10150,18,312,1,42,
10150,18,318,1,13,
10150,18,332,4,0,
10150,18,347,4,0,
10150,18,355,4,0,
10150,18,369,4,0,
10150,18,405,1,28,
10150,18,412,4,0,
10150,18,483,1,49,
10150,18,496,4,0,
10150,18,512,4,0,
10150,18,522,1,1,5
10150,18,522,1,10,
10150,18,577,1,16,
10150,18,584,1,1,3
10150,18,584,1,4,
10150,18,590,4,0,
10150,18,605,1,35,
10150,18,605,4,0,
10150,18,611,4,0,
10150,18,676,1,0,
10150,18,676,1,1,1
10151,18,28,1,4,
10151,18,33,1,1,1
10151,18,43,1,1,2
10151,18,44,1,7,
10151,18,46,1,26,
10151,18,46,4,0,
10151,18,88,1,15,
10151,18,92,4,0,
10151,18,104,4,0,
10151,18,156,4,0,
10151,18,157,1,34,
10151,18,157,4,0,
10151,18,164,4,0,
10151,18,182,4,0,
10151,18,184,1,37,
10151,18,207,4,0,
10151,18,213,4,0,
10151,18,214,4,0,
10151,18,216,4,0,
10151,18,218,4,0,
10151,18,237,4,0,
10151,18,242,1,40,
10151,18,263,4,0,
10151,18,269,4,0,
10151,18,316,1,18,
10151,18,317,1,23,
10151,18,317,4,0,
10151,18,336,1,12,
10151,18,397,4,0,
10151,18,431,1,45,
10151,18,444,1,48,
10151,18,444,4,0,
10151,18,446,1,29,
10151,18,496,4,0,
10151,18,497,4,0,
10151,18,555,4,0,
10151,18,590,4,0,
10152,18,14,4,0,
10152,18,28,1,1,6
10152,18,28,1,4,
10152,18,33,1,1,4
10152,18,37,1,0,
10152,18,37,1,1,1
10152,18,43,1,1,5
10152,18,44,1,1,7
10152,18,44,1,7,
10152,18,46,1,26,
10152,18,46,4,0,
10152,18,68,1,1,3
10152,18,88,1,15,
10152,18,92,4,0,
10152,18,104,4,0,
10152,18,156,4,0,
10152,18,157,1,34,
10152,18,157,4,0,
10152,18,164,4,0,
10152,18,182,4,0,
10152,18,184,1,37,
10152,18,207,4,0,
10152,18,213,4,0,
10152,18,214,4,0,
10152,18,216,4,0,
10152,18,218,4,0,
10152,18,237,4,0,
10152,18,242,1,40,
10152,18,263,4,0,
10152,18,269,4,0,
10152,18,280,4,0,
10152,18,316,1,18,
10152,18,317,1,23,
10152,18,317,4,0,
10152,18,336,1,12,
10152,18,339,4,0,
10152,18,397,4,0,
10152,18,431,1,45,
10152,18,444,1,48,
10152,18,444,4,0,
10152,18,446,1,29,
10152,18,496,4,0,
10152,18,497,4,0,
10152,18,555,4,0,
10152,18,590,4,0,
10152,18,709,1,1,2
10153,18,44,1,21,
10153,18,57,4,0,
10153,18,58,4,0,
10153,18,59,4,0,
10153,18,61,1,16,
10153,18,92,4,0,
10153,18,104,4,0,
10153,18,115,4,0,
10153,18,127,4,0,
10153,18,141,1,33,
10153,18,141,4,0,
10153,18,145,1,1,3
10153,18,156,4,0,
10153,18,164,4,0,
10153,18,169,1,1,5
10153,18,169,1,8,
10153,18,182,4,0,
10153,18,207,4,0,
10153,18,213,4,0,
10153,18,214,4,0,
10153,18,216,4,0,
10153,18,218,4,0,
10153,18,219,4,0,
10153,18,237,4,0,
10153,18,240,4,0,
10153,18,242,1,38,
10153,18,243,1,50,
10153,18,263,4,0,
10153,18,392,1,26,
10153,18,398,4,0,
10153,18,404,4,0,
10153,18,450,1,1,6
10153,18,450,1,13,
10153,18,469,1,1,1
10153,18,487,1,1,2
10153,18,494,1,62,
10153,18,496,4,0,
10153,18,503,4,0,
10153,18,524,4,0,
10153,18,590,4,0,
10153,18,611,1,1,4
10153,18,611,1,5,
10153,18,611,4,0,
10153,18,679,1,45,
10153,18,710,1,57,
10154,18,33,1,1,1
10154,18,42,1,45,
10154,18,84,1,1,2
10154,18,85,4,0,
10154,18,86,4,0,
10154,18,87,4,0,
10154,18,92,4,0,
10154,18,104,4,0,
10154,18,111,1,5,
10154,18,115,4,0,
10154,18,156,4,0,
10154,18,164,4,0,
10154,18,168,4,0,
10154,18,182,4,0,
10154,18,205,1,9,
10154,18,207,4,0,
10154,18,209,1,17,
10154,18,213,4,0,
10154,18,214,4,0,
10154,18,216,4,0,
10154,18,218,4,0,
10154,18,237,4,0,
10154,18,263,4,0,
10154,18,268,1,13,
10154,18,360,4,0,
10154,18,369,4,0,
10154,18,371,4,0,
10154,18,374,4,0,
10154,18,393,1,25,
10154,18,398,4,0,
10154,18,416,4,0,
10154,18,435,1,29,
10154,18,447,4,0,
10154,18,451,4,0,
10154,18,496,4,0,
10154,18,521,4,0,
10154,18,526,4,0,
10154,18,528,1,41,
10154,18,528,4,0,
10154,18,565,1,53,
10154,18,590,4,0,
10154,18,596,1,49,
10154,18,604,1,37,
10154,18,609,1,21,
10154,18,716,1,33,
10155,18,14,4,0,
10155,18,63,4,0,
10155,18,76,4,0,
10155,18,86,4,0,
10155,18,89,4,0,
10155,18,92,4,0,
10155,18,93,1,1,6
10155,18,94,4,0,
10155,18,104,4,0,
10155,18,113,4,0,
10155,18,115,4,0,
10155,18,156,4,0,
10155,18,157,4,0,
10155,18,163,1,7,
10155,18,164,4,0,
10155,18,168,4,0,
10155,18,182,4,0,
10155,18,207,4,0,
10155,18,214,4,0,
10155,18,216,4,0,
10155,18,218,4,0,
10155,18,232,1,1,5
10155,18,234,1,1,2
10155,18,236,1,1,1
10155,18,237,4,0,
10155,18,263,4,0,
10155,18,280,4,0,
10155,18,317,4,0,
10155,18,332,4,0,
10155,18,334,1,59,
10155,18,347,4,0,
10155,18,350,1,19,
10155,18,356,1,31,
10155,18,360,4,0,
10155,18,373,4,0,
10155,18,374,4,0,
10155,18,378,1,67,
10155,18,397,4,0,
10155,18,399,4,0,
10155,18,400,1,23,
10155,18,404,4,0,
10155,18,408,1,43,
10155,18,416,4,0,
10155,18,421,4,0,
10155,18,427,1,37,
10155,18,429,1,1,4
10155,18,430,4,0,
10155,18,433,4,0,
10155,18,444,4,0,
10155,18,446,1,53,
10155,18,451,1,1,3
10155,18,451,4,0,
10155,18,473,4,0,
10155,18,475,1,47,
10155,18,496,4,0,
10155,18,500,1,13,
10155,18,523,4,0,
10155,18,590,4,0,
10155,18,684,4,0,
10155,18,693,4,0,
10155,18,711,1,73,
10155,18,722,1,50,
10156,18,14,4,0,
10156,18,63,4,0,
10156,18,76,4,0,
10156,18,86,4,0,
10156,18,89,4,0,
10156,18,92,4,0,
10156,18,93,1,1,6
10156,18,94,4,0,
10156,18,104,4,0,
10156,18,113,4,0,
10156,18,115,4,0,
10156,18,156,4,0,
10156,18,157,4,0,
10156,18,163,1,7,
10156,18,164,4,0,
10156,18,168,4,0,
10156,18,182,4,0,
10156,18,207,4,0,
10156,18,214,4,0,
10156,18,216,4,0,
10156,18,218,4,0,
10156,18,232,1,1,5
10156,18,234,1,1,2
10156,18,236,1,1,1
10156,18,237,4,0,
10156,18,263,4,0,
10156,18,280,4,0,
10156,18,317,4,0,
10156,18,332,4,0,
10156,18,334,1,59,
10156,18,347,4,0,
10156,18,350,1,19,
10156,18,356,1,31,
10156,18,360,4,0,
10156,18,373,4,0,
10156,18,374,4,0,
10156,18,378,1,67,
10156,18,397,4,0,
10156,18,399,4,0,
10156,18,400,1,23,
10156,18,404,4,0,
10156,18,408,1,43,
10156,18,416,4,0,
10156,18,421,4,0,
10156,18,427,1,37,
10156,18,429,1,1,4
10156,18,430,4,0,
10156,18,433,4,0,
10156,18,444,4,0,
10156,18,446,1,53,
10156,18,451,1,1,3
10156,18,451,4,0,
10156,18,473,4,0,
10156,18,475,1,47,
10156,18,496,4,0,
10156,18,500,1,13,
10156,18,523,4,0,
10156,18,590,4,0,
10156,18,684,4,0,
10156,18,693,4,0,
10156,18,711,1,73,
10156,18,722,1,50,
10157,18,14,4,0,
10157,18,63,4,0,
10157,18,76,4,0,
10157,18,86,4,0,
10157,18,89,4,0,
10157,18,92,4,0,
10157,18,93,1,1,6
10157,18,94,4,0,
10157,18,104,4,0,
10157,18,113,4,0,
10157,18,115,4,0,
10157,18,156,4,0,
10157,18,157,4,0,
10157,18,163,1,7,
10157,18,164,4,0,
10157,18,168,4,0,
10157,18,182,4,0,
10157,18,207,4,0,
10157,18,214,4,0,
10157,18,216,4,0,
10157,18,218,4,0,
10157,18,232,1,1,5
10157,18,234,1,1,2
10157,18,236,1,1,1
10157,18,237,4,0,
10157,18,263,4,0,
10157,18,280,4,0,
10157,18,317,4,0,
10157,18,332,4,0,
10157,18,334,1,59,
10157,18,347,4,0,
10157,18,350,1,19,
10157,18,356,1,31,
10157,18,360,4,0,
10157,18,373,4,0,
10157,18,374,4,0,
10157,18,378,1,67,
10157,18,397,4,0,
10157,18,399,4,0,
10157,18,400,1,23,
10157,18,404,4,0,
10157,18,408,1,43,
10157,18,416,4,0,
10157,18,421,4,0,
10157,18,427,1,37,
10157,18,429,1,1,4
10157,18,430,4,0,
10157,18,433,4,0,
10157,18,444,4,0,
10157,18,446,1,53,
10157,18,451,1,1,3
10157,18,451,4,0,
10157,18,473,4,0,
10157,18,475,1,47,
10157,18,496,4,0,
10157,18,500,1,13,
10157,18,523,4,0,
10157,18,590,4,0,
10157,18,684,4,0,
10157,18,693,4,0,
10157,18,711,1,73,
10157,18,722,1,50,`