export const pokemondata = 
`id,identifier,species_id,order,hp,attack,defense,specialattack,specialdefense,speed,typeOne,typeTwo
1,bulbasaur,1,1,45,49,49,65,65,45,grass,poison
2,ivysaur,2,2,60,62,63,80,80,60,grass,poison
3,venusaur,3,3,80,82,83,100,100,80,grass,poison
4,charmander,4,5,39,52,43,60,50,65,fire,
5,charmeleon,5,6,58,64,58,80,65,80,fire,
6,charizard,6,7,78,84,78,109,85,100,fire,flying
7,squirtle,7,10,44,48,65,50,64,43,water,
8,wartortle,8,11,59,63,80,65,80,58,water,
9,blastoise,9,12,79,83,100,85,105,78,water,
10,caterpie,10,14,45,30,35,20,20,45,bug,
11,metapod,11,15,50,20,55,25,25,30,bug,
12,butterfree,12,16,60,45,50,90,80,70,bug,flying
13,weedle,13,17,40,35,30,20,20,50,bug,poison
14,kakuna,14,18,45,25,50,25,25,35,bug,poison
15,beedrill,15,19,65,90,40,45,80,75,bug,poison
16,pidgey,16,21,40,45,40,35,35,56,normal,flying
17,pidgeotto,17,22,63,60,55,50,50,71,normal,flying
18,pidgeot,18,23,83,80,75,70,70,101,normal,flying
19,rattata,19,25,30,56,35,25,35,72,normal,
20,raticate,20,27,55,81,60,50,70,97,normal,
21,spearow,21,30,40,60,30,31,31,70,normal,flying
22,fearow,22,31,65,90,65,61,61,100,normal,flying
23,ekans,23,32,35,60,44,40,54,55,poison,
24,arbok,24,33,60,95,69,65,79,80,poison,
25,pikachu,25,35,35,55,40,50,50,90,electric,
26,raichu,26,43,60,90,55,90,80,110,electric,
27,sandshrew,27,45,50,75,85,20,30,40,ground,
28,sandslash,28,47,75,100,110,45,55,65,ground,
29,nidoran-f,29,49,55,47,52,40,40,41,poison,
30,nidorina,30,50,70,62,67,55,55,56,poison,
31,nidoqueen,31,51,90,92,87,75,85,76,poison,ground
32,nidoran-m,32,52,46,57,40,40,40,50,poison,
33,nidorino,33,53,61,72,57,55,55,65,poison,
34,nidoking,34,54,81,102,77,85,75,85,poison,ground
35,clefairy,35,56,70,45,48,60,65,35,fairy,
36,clefable,36,57,95,70,73,95,90,60,fairy,
37,vulpix,37,58,38,41,40,50,65,65,fire,
38,ninetales,38,60,73,76,75,81,100,100,fire,
39,jigglypuff,39,63,115,45,20,45,25,20,normal,fairy
40,wigglytuff,40,64,140,70,45,85,50,45,normal,fairy
41,zubat,41,65,40,45,35,30,40,55,poison,flying
42,golbat,42,66,75,80,70,65,75,90,poison,flying
43,oddish,43,68,45,50,55,75,65,30,grass,poison
44,gloom,44,69,60,65,70,85,75,40,grass,poison
45,vileplume,45,70,75,80,85,110,90,50,grass,poison
46,paras,46,72,35,70,55,45,55,25,bug,grass
47,parasect,47,73,60,95,80,60,80,30,bug,grass
48,venonat,48,74,60,55,50,40,55,45,bug,poison
49,venomoth,49,75,70,65,60,90,75,90,bug,poison
50,diglett,50,76,10,55,25,35,45,95,ground,
51,dugtrio,51,78,35,100,50,50,70,120,ground,
52,meowth,52,80,40,45,35,40,40,90,normal,
53,persian,53,82,65,70,60,65,65,115,normal,
54,psyduck,54,84,50,52,48,65,50,55,water,
55,golduck,55,85,80,82,78,95,80,85,water,
56,mankey,56,86,40,80,35,35,45,70,fighting,
57,primeape,57,87,65,105,60,60,70,95,fighting,
58,growlithe,58,88,55,70,45,70,50,60,fire,
59,arcanine,59,89,90,110,80,100,80,95,fire,
60,poliwag,60,90,40,50,40,40,40,90,water,
61,poliwhirl,61,91,65,65,65,50,50,90,water,
62,poliwrath,62,92,90,95,95,70,90,70,water,fighting
63,abra,63,94,25,20,15,105,55,90,psychic,
64,kadabra,64,95,40,35,30,120,70,105,psychic,
65,alakazam,65,96,55,50,45,135,95,120,psychic,
66,machop,66,98,70,80,50,35,35,35,fighting,
67,machoke,67,99,80,100,70,50,60,45,fighting,
68,machamp,68,100,90,130,80,65,85,55,fighting,
69,bellsprout,69,101,50,75,35,70,30,40,grass,poison
70,weepinbell,70,102,65,90,50,85,45,55,grass,poison
71,victreebel,71,103,80,105,65,100,70,70,grass,poison
72,tentacool,72,104,40,40,35,50,100,70,water,poison
73,tentacruel,73,105,80,70,65,80,120,100,water,poison
74,geodude,74,106,40,80,100,30,30,20,rock,ground
75,graveler,75,108,55,95,115,45,45,35,rock,ground
76,golem,76,110,80,120,130,55,65,45,rock,ground
77,ponyta,77,112,50,85,55,65,65,90,fire,
78,rapidash,78,113,65,100,70,80,80,105,fire,
79,slowpoke,79,114,90,65,65,40,40,15,water,psychic
80,slowbro,80,115,95,75,110,100,80,30,water,psychic
81,magnemite,81,118,25,35,70,95,55,45,electric,steel
82,magneton,82,119,50,60,95,120,70,70,electric,steel
83,farfetchd,83,121,52,90,55,58,62,60,normal,flying
84,doduo,84,122,35,85,45,35,35,75,normal,flying
85,dodrio,85,123,60,110,70,60,60,110,normal,flying
86,seel,86,124,65,45,55,45,70,45,water,
87,dewgong,87,125,90,70,80,70,95,70,water,ice
88,grimer,88,126,80,80,50,40,50,25,poison,
89,muk,89,128,105,105,75,65,100,50,poison,
90,shellder,90,130,30,65,100,45,25,40,water,
91,cloyster,91,131,50,95,180,85,45,70,water,ice
92,gastly,92,132,30,35,30,100,35,80,ghost,poison
93,haunter,93,133,45,50,45,115,55,95,ghost,poison
94,gengar,94,134,60,65,60,130,75,110,ghost,poison
95,onix,95,136,35,45,160,30,45,70,rock,ground
96,drowzee,96,139,60,48,45,43,90,42,psychic,
97,hypno,97,140,85,73,70,73,115,67,psychic,
98,krabby,98,141,30,105,90,25,25,50,water,
99,kingler,99,142,55,130,115,50,50,75,water,
100,voltorb,100,143,40,30,50,55,55,100,electric,
101,electrode,101,144,60,50,70,80,80,150,electric,
102,exeggcute,102,145,60,40,80,60,45,40,grass,psychic
103,exeggutor,103,146,95,95,85,125,75,55,grass,psychic
104,cubone,104,148,50,50,95,40,50,35,ground,
105,marowak,105,149,60,80,110,50,80,45,ground,
106,hitmonlee,106,153,50,120,53,35,110,87,fighting,
107,hitmonchan,107,154,50,105,79,35,110,76,fighting,
108,lickitung,108,156,90,55,75,60,75,30,normal,
109,koffing,109,158,40,65,95,60,45,35,poison,
110,weezing,110,159,65,90,120,85,70,60,poison,
111,rhyhorn,111,160,80,85,95,30,30,25,ground,rock
112,rhydon,112,161,105,130,120,45,45,40,ground,rock
113,chansey,113,164,250,5,5,35,105,50,normal,
114,tangela,114,166,65,55,115,100,40,60,grass,
115,kangaskhan,115,168,105,95,80,40,80,90,normal,
116,horsea,116,170,30,40,70,70,25,60,water,
117,seadra,117,171,55,65,95,95,45,85,water,
118,goldeen,118,173,45,67,60,35,50,63,water,
119,seaking,119,174,80,92,65,65,80,68,water,
120,staryu,120,175,30,45,55,70,55,85,water,
121,starmie,121,176,60,75,85,100,85,115,water,psychic
122,mr-mime,122,178,40,45,65,100,120,90,psychic,fairy
123,scyther,123,179,70,110,80,55,80,105,bug,flying
124,jynx,124,183,65,50,35,115,95,95,ice,psychic
125,electabuzz,125,185,65,83,57,95,85,105,electric,
126,magmar,126,188,65,95,57,100,85,93,fire,
127,pinsir,127,190,65,125,100,55,70,85,bug,
128,tauros,128,192,75,100,95,40,70,110,normal,
129,magikarp,129,193,20,10,55,15,20,80,water,
130,gyarados,130,194,95,125,79,60,100,81,water,flying
131,lapras,131,196,130,85,80,85,95,60,water,ice
132,ditto,132,197,48,48,48,48,48,48,normal,
133,eevee,133,198,55,55,50,45,65,55,normal,
134,vaporeon,134,199,130,65,60,110,95,65,water,
135,jolteon,135,200,65,65,60,110,95,130,electric,
136,flareon,136,201,65,130,60,95,110,65,fire,
137,porygon,137,207,65,60,70,85,75,40,normal,
138,omanyte,138,210,35,40,100,90,55,35,rock,water
139,omastar,139,211,70,60,125,115,70,55,rock,water
140,kabuto,140,212,30,80,90,55,45,55,rock,water
141,kabutops,141,213,60,115,105,65,70,80,rock,water
142,aerodactyl,142,214,80,105,65,60,75,130,rock,flying
143,snorlax,143,217,160,110,65,65,110,30,normal,
144,articuno,144,218,90,85,100,95,125,85,ice,flying
145,zapdos,145,219,90,90,85,125,90,100,electric,flying
146,moltres,146,220,90,100,90,125,85,90,fire,flying
147,dratini,147,221,41,64,45,50,50,50,dragon,
148,dragonair,148,222,61,84,65,70,70,70,dragon,
149,dragonite,149,223,91,134,95,100,100,80,dragon,flying
150,mewtwo,150,224,106,110,90,154,90,130,psychic,
151,mew,151,227,100,100,100,100,100,100,psychic,
152,chikorita,152,228,45,49,65,49,65,45,grass,
153,bayleef,153,229,60,62,80,63,80,60,grass,
154,meganium,154,230,80,82,100,83,100,80,grass,
155,cyndaquil,155,231,39,52,43,60,50,65,fire,
156,quilava,156,232,58,64,58,80,65,80,fire,
157,typhlosion,157,233,78,84,78,109,85,100,fire,
158,totodile,158,234,50,65,64,44,48,43,water,
159,croconaw,159,235,65,80,80,59,63,58,water,
160,feraligatr,160,236,85,105,100,79,83,78,water,
161,sentret,161,237,35,46,34,35,45,20,normal,
162,furret,162,238,85,76,64,45,55,90,normal,
163,hoothoot,163,239,60,30,30,36,56,50,normal,flying
164,noctowl,164,240,100,50,50,86,96,70,normal,flying
165,ledyba,165,241,40,20,30,40,80,55,bug,flying
166,ledian,166,242,55,35,50,55,110,85,bug,flying
167,spinarak,167,243,40,60,40,40,40,30,bug,poison
168,ariados,168,244,70,90,70,60,70,40,bug,poison
169,crobat,169,67,85,90,80,70,80,130,poison,flying
170,chinchou,170,245,75,38,38,56,56,67,water,electric
171,lanturn,171,246,125,58,58,76,76,67,water,electric
172,pichu,172,34,20,40,15,35,35,60,electric,
173,cleffa,173,55,50,25,28,45,55,15,fairy,
174,igglybuff,174,62,90,30,15,40,20,15,normal,fairy
175,togepi,175,247,35,20,65,40,65,20,fairy,
176,togetic,176,248,55,40,85,80,105,40,fairy,flying
177,natu,177,250,40,50,45,70,45,70,psychic,flying
178,xatu,178,251,65,75,70,95,70,95,psychic,flying
179,mareep,179,252,55,40,40,65,45,35,electric,
180,flaaffy,180,253,70,55,55,80,60,45,electric,
181,ampharos,181,254,90,75,85,115,90,55,electric,
182,bellossom,182,71,75,80,95,90,100,50,grass,
183,marill,183,257,70,20,50,20,50,40,water,fairy
184,azumarill,184,258,100,50,80,60,80,50,water,fairy
185,sudowoodo,185,260,70,100,115,30,65,30,rock,
186,politoed,186,93,90,75,75,90,100,70,water,
187,hoppip,187,261,35,35,40,35,55,50,grass,flying
188,skiploom,188,262,55,45,50,45,65,80,grass,flying
189,jumpluff,189,263,75,55,70,55,95,110,grass,flying
190,aipom,190,264,55,70,55,40,55,85,normal,
191,sunkern,191,266,30,30,30,30,30,30,grass,
192,sunflora,192,267,75,75,55,105,85,30,grass,
193,yanma,193,268,65,65,45,75,45,95,bug,flying
194,wooper,194,270,55,45,45,25,25,15,water,ground
195,quagsire,195,271,95,85,85,65,65,35,water,ground
196,espeon,196,202,65,65,60,130,95,110,psychic,
197,umbreon,197,203,95,65,110,60,130,65,dark,
198,murkrow,198,272,60,85,42,85,42,91,dark,flying
199,slowking,199,117,95,75,80,100,110,30,water,psychic
200,misdreavus,200,274,60,60,60,85,85,85,ghost,
201,unown,201,276,48,72,48,72,48,48,psychic,
202,wobbuffet,202,278,190,33,58,33,58,33,psychic,
203,girafarig,203,279,70,80,65,90,65,85,normal,psychic
204,pineco,204,280,50,65,90,35,35,15,bug,
205,forretress,205,281,75,90,140,60,60,40,bug,steel
206,dunsparce,206,282,100,70,70,65,65,45,normal,
207,gligar,207,283,65,75,105,35,65,85,ground,flying
208,steelix,208,137,75,85,200,55,65,30,steel,ground
209,snubbull,209,285,60,80,50,40,40,30,fairy,
210,granbull,210,286,90,120,75,60,60,45,fairy,
211,qwilfish,211,287,65,95,85,55,55,85,water,poison
212,scizor,212,180,70,130,100,55,80,65,bug,steel
213,shuckle,213,288,20,10,230,10,230,5,bug,rock
214,heracross,214,289,80,125,75,40,95,85,bug,fighting
215,sneasel,215,291,55,95,55,35,75,115,dark,ice
216,teddiursa,216,293,60,80,50,50,50,40,normal,
217,ursaring,217,294,90,130,75,75,75,55,normal,
218,slugma,218,295,40,40,40,70,40,20,fire,
219,magcargo,219,296,60,50,120,90,80,30,fire,rock
220,swinub,220,297,50,50,40,30,30,50,ice,ground
221,piloswine,221,298,100,100,80,60,60,50,ice,ground
222,corsola,222,300,65,55,95,65,95,35,water,rock
223,remoraid,223,301,35,65,35,65,35,65,water,
224,octillery,224,302,75,105,75,105,75,45,water,
225,delibird,225,303,45,55,45,65,45,75,ice,flying
226,mantine,226,305,85,40,70,80,140,70,water,flying
227,skarmory,227,306,65,80,140,40,70,70,steel,flying
228,houndour,228,307,45,60,30,80,50,65,dark,fire
229,houndoom,229,308,75,90,50,110,80,95,dark,fire
230,kingdra,230,172,75,95,95,95,95,85,water,dragon
231,phanpy,231,310,90,60,60,40,40,40,ground,
232,donphan,232,311,90,120,120,60,60,50,ground,
233,porygon2,233,208,85,80,90,105,95,60,normal,
234,stantler,234,312,73,95,62,85,65,85,normal,
235,smeargle,235,313,55,20,35,20,45,75,normal,
236,tyrogue,236,152,35,35,35,35,35,35,fighting,
237,hitmontop,237,155,50,95,95,35,110,70,fighting,
238,smoochum,238,182,45,30,15,85,65,65,ice,psychic
239,elekid,239,184,45,63,37,65,55,95,electric,
240,magby,240,187,45,75,37,70,55,83,fire,
241,miltank,241,314,95,80,105,40,70,100,normal,
242,blissey,242,165,255,10,10,75,135,55,normal,
243,raikou,243,315,90,85,75,115,100,115,electric,
244,entei,244,316,115,115,85,90,75,100,fire,
245,suicune,245,317,100,75,115,90,115,85,water,
246,larvitar,246,318,50,64,50,45,50,41,rock,ground
247,pupitar,247,319,70,84,70,65,70,51,rock,ground
248,tyranitar,248,320,100,134,110,95,100,61,rock,dark
249,lugia,249,322,106,90,130,90,154,110,psychic,flying
250,ho-oh,250,323,106,130,90,110,154,90,fire,flying
251,celebi,251,324,100,100,100,100,100,100,psychic,grass
252,treecko,252,325,40,45,35,65,55,70,grass,
253,grovyle,253,326,50,65,45,85,65,95,grass,
254,sceptile,254,327,70,85,65,105,85,120,grass,
255,torchic,255,329,45,60,40,70,50,45,fire,
256,combusken,256,330,60,85,60,85,60,55,fire,fighting
257,blaziken,257,331,80,120,70,110,70,80,fire,fighting
258,mudkip,258,333,50,70,50,50,50,40,water,
259,marshtomp,259,334,70,85,70,60,70,50,water,ground
260,swampert,260,335,100,110,90,85,90,60,water,ground
261,poochyena,261,337,35,55,35,30,30,35,dark,
262,mightyena,262,338,70,90,70,60,60,70,dark,
263,zigzagoon,263,339,38,30,41,30,41,60,normal,
264,linoone,264,340,78,70,61,50,61,100,normal,
265,wurmple,265,341,45,45,35,20,30,20,bug,
266,silcoon,266,342,50,35,55,25,25,15,bug,
267,beautifly,267,343,60,70,50,100,50,65,bug,flying
268,cascoon,268,344,50,35,55,25,25,15,bug,
269,dustox,269,345,60,50,70,50,90,65,bug,poison
270,lotad,270,346,40,30,30,40,50,30,water,grass
271,lombre,271,347,60,50,50,60,70,50,water,grass
272,ludicolo,272,348,80,70,70,90,100,70,water,grass
273,seedot,273,349,40,40,50,30,30,30,grass,
274,nuzleaf,274,350,70,70,40,60,40,60,grass,dark
275,shiftry,275,351,90,100,60,90,60,80,grass,dark
276,taillow,276,352,40,55,30,30,30,85,normal,flying
277,swellow,277,353,60,85,60,75,50,125,normal,flying
278,wingull,278,354,40,30,30,55,30,85,water,flying
279,pelipper,279,355,60,50,100,95,70,65,water,flying
280,ralts,280,356,28,25,25,45,35,40,psychic,fairy
281,kirlia,281,357,38,35,35,65,55,50,psychic,fairy
282,gardevoir,282,358,68,65,65,125,115,80,psychic,fairy
283,surskit,283,362,40,30,32,50,52,65,bug,water
284,masquerain,284,363,70,60,62,100,82,80,bug,flying
285,shroomish,285,364,60,40,60,40,60,35,grass,
286,breloom,286,365,60,130,80,60,60,70,grass,fighting
287,slakoth,287,366,60,60,60,35,35,30,normal,
288,vigoroth,288,367,80,80,80,55,55,90,normal,
289,slaking,289,368,150,160,100,95,65,100,normal,
290,nincada,290,369,31,45,90,30,30,40,bug,ground
291,ninjask,291,370,61,90,45,50,50,160,bug,flying
292,shedinja,292,371,1,90,45,30,30,40,bug,ghost
293,whismur,293,372,64,51,23,51,23,28,normal,
294,loudred,294,373,84,71,43,71,43,48,normal,
295,exploud,295,374,104,91,63,91,73,68,normal,
296,makuhita,296,375,72,60,30,20,30,25,fighting,
297,hariyama,297,376,144,120,60,40,60,50,fighting,
298,azurill,298,256,50,20,40,20,40,20,normal,fairy
299,nosepass,299,377,30,45,135,45,90,30,rock,
300,skitty,300,379,50,45,45,35,35,50,normal,
301,delcatty,301,380,70,65,65,55,55,90,normal,
302,sableye,302,381,50,75,75,65,65,50,dark,ghost
303,mawile,303,383,50,85,85,55,55,50,steel,fairy
304,aron,304,385,50,70,100,40,40,30,steel,rock
305,lairon,305,386,60,90,140,50,50,40,steel,rock
306,aggron,306,387,70,110,180,60,60,50,steel,rock
307,meditite,307,389,30,40,55,40,55,60,fighting,psychic
308,medicham,308,390,60,60,75,60,75,80,fighting,psychic
309,electrike,309,392,40,45,40,65,40,65,electric,
310,manectric,310,393,70,75,60,105,60,105,electric,
311,plusle,311,395,60,50,40,85,75,95,electric,
312,minun,312,396,60,40,50,75,85,95,electric,
313,volbeat,313,397,65,73,75,47,85,85,bug,
314,illumise,314,398,65,47,75,73,85,85,bug,
315,roselia,315,400,50,60,45,100,80,65,grass,poison
316,gulpin,316,402,70,43,53,43,53,40,poison,
317,swalot,317,403,100,73,83,73,83,55,poison,
318,carvanha,318,404,45,90,20,65,20,65,water,dark
319,sharpedo,319,405,70,120,40,95,40,95,water,dark
320,wailmer,320,407,130,70,35,70,35,60,water,
321,wailord,321,408,170,90,45,90,45,60,water,
322,numel,322,409,60,60,40,65,45,35,fire,ground
323,camerupt,323,410,70,100,70,105,75,40,fire,ground
324,torkoal,324,412,70,85,140,85,70,20,fire,
325,spoink,325,413,60,25,35,70,80,60,psychic,
326,grumpig,326,414,80,45,65,90,110,80,psychic,
327,spinda,327,415,60,60,60,60,60,60,normal,
328,trapinch,328,416,45,100,45,45,45,10,ground,
329,vibrava,329,417,50,70,50,50,50,70,ground,dragon
330,flygon,330,418,80,100,80,80,80,100,ground,dragon
331,cacnea,331,419,50,85,40,85,40,35,grass,
332,cacturne,332,420,70,115,60,115,60,55,grass,dark
333,swablu,333,421,45,40,60,40,75,50,normal,flying
334,altaria,334,422,75,70,90,70,105,80,dragon,flying
335,zangoose,335,424,73,115,60,60,60,90,normal,
336,seviper,336,425,73,100,60,100,60,65,poison,
337,lunatone,337,426,90,55,65,95,85,70,rock,psychic
338,solrock,338,427,90,95,85,55,65,70,rock,psychic
339,barboach,339,428,50,48,43,46,41,60,water,ground
340,whiscash,340,429,110,78,73,76,71,60,water,ground
341,corphish,341,430,43,80,65,50,35,35,water,
342,crawdaunt,342,431,63,120,85,90,55,55,water,dark
343,baltoy,343,432,40,40,55,40,70,55,ground,psychic
344,claydol,344,433,60,70,105,70,120,75,ground,psychic
345,lileep,345,434,66,41,77,61,87,23,rock,grass
346,cradily,346,435,86,81,97,81,107,43,rock,grass
347,anorith,347,436,45,95,50,40,50,75,rock,bug
348,armaldo,348,437,75,125,100,70,80,45,rock,bug
349,feebas,349,438,20,15,20,10,55,80,water,
350,milotic,350,439,95,60,79,100,125,81,water,
351,castform,351,440,70,70,70,70,70,70,normal,
352,kecleon,352,444,60,90,70,60,120,40,normal,
353,shuppet,353,445,44,75,35,63,33,45,ghost,
354,banette,354,446,64,115,65,83,63,65,ghost,
355,duskull,355,448,20,40,90,30,90,25,ghost,
356,dusclops,356,449,40,70,130,60,130,25,ghost,
357,tropius,357,451,99,68,83,72,87,51,grass,flying
358,chimecho,358,453,75,50,80,95,90,65,psychic,
359,absol,359,454,65,130,60,75,60,75,dark,
360,wynaut,360,277,95,23,48,23,48,23,psychic,
361,snorunt,361,456,50,50,50,50,50,50,ice,
362,glalie,362,457,80,80,80,80,80,80,ice,
363,spheal,363,460,70,40,50,55,50,25,ice,water
364,sealeo,364,461,90,60,70,75,70,45,ice,water
365,walrein,365,462,110,80,90,95,90,65,ice,water
366,clamperl,366,463,35,64,85,74,55,32,water,
367,huntail,367,464,55,104,105,94,75,52,water,
368,gorebyss,368,465,55,84,105,114,75,52,water,
369,relicanth,369,466,100,90,130,45,65,55,water,rock
370,luvdisc,370,467,43,30,55,40,65,97,water,
371,bagon,371,468,45,75,60,40,30,50,dragon,
372,shelgon,372,469,65,95,100,60,50,50,dragon,
373,salamence,373,470,95,135,80,110,80,100,dragon,flying
374,beldum,374,472,40,55,80,35,60,30,steel,psychic
375,metang,375,473,60,75,100,55,80,50,steel,psychic
376,metagross,376,474,80,135,130,95,90,70,steel,psychic
377,regirock,377,476,80,100,200,50,100,50,rock,
378,regice,378,477,80,50,100,100,200,50,ice,
379,registeel,379,478,80,75,150,75,150,50,steel,
380,latias,380,479,80,80,90,110,130,110,dragon,psychic
381,latios,381,481,80,90,80,130,110,110,dragon,psychic
382,kyogre,382,483,100,100,90,150,140,90,water,
383,groudon,383,485,100,150,140,100,90,90,ground,
384,rayquaza,384,487,105,150,90,150,90,95,dragon,flying
385,jirachi,385,489,100,100,100,100,100,100,steel,psychic
386,deoxys-normal,386,490,50,150,50,150,50,150,psychic,
387,turtwig,387,494,55,68,64,45,55,31,grass,
388,grotle,388,495,75,89,85,55,65,36,grass,
389,torterra,389,496,95,109,105,75,85,56,grass,ground
390,chimchar,390,497,44,58,44,58,44,61,fire,
391,monferno,391,498,64,78,52,78,52,81,fire,fighting
392,infernape,392,499,76,104,71,104,71,108,fire,fighting
393,piplup,393,500,53,51,53,61,56,40,water,
394,prinplup,394,501,64,66,68,81,76,50,water,
395,empoleon,395,502,84,86,88,111,101,60,water,steel
396,starly,396,503,40,55,30,30,30,60,normal,flying
397,staravia,397,504,55,75,50,40,40,80,normal,flying
398,staraptor,398,505,85,120,70,50,60,100,normal,flying
399,bidoof,399,506,59,45,40,35,40,31,normal,
400,bibarel,400,507,79,85,60,55,60,71,normal,water
401,kricketot,401,508,37,25,41,25,41,25,bug,
402,kricketune,402,509,77,85,51,55,51,65,bug,
403,shinx,403,510,45,65,34,40,34,45,electric,
404,luxio,404,511,60,85,49,60,49,60,electric,
405,luxray,405,512,80,120,79,95,79,70,electric,
406,budew,406,399,40,30,35,50,70,55,grass,poison
407,roserade,407,401,60,70,65,125,105,90,grass,poison
408,cranidos,408,513,67,125,40,30,30,58,rock,
409,rampardos,409,514,97,165,60,65,50,58,rock,
410,shieldon,410,515,30,42,118,42,88,30,rock,steel
411,bastiodon,411,516,60,52,168,47,138,30,rock,steel
412,burmy,412,517,40,29,45,29,45,36,bug,
413,wormadam-plant,413,518,60,59,85,79,105,36,bug,grass
414,mothim,414,521,70,94,50,94,50,66,bug,flying
415,combee,415,522,30,30,42,30,42,70,bug,flying
416,vespiquen,416,523,70,80,102,80,102,40,bug,flying
417,pachirisu,417,524,60,45,70,45,90,95,electric,
418,buizel,418,525,55,65,35,60,30,85,water,
419,floatzel,419,526,85,105,55,85,50,115,water,
420,cherubi,420,527,45,35,45,62,53,35,grass,
421,cherrim,421,528,70,60,70,87,78,85,grass,
422,shellos,422,529,76,48,48,57,62,34,water,
423,gastrodon,423,530,111,83,68,92,82,39,water,ground
424,ambipom,424,265,75,100,66,60,66,115,normal,
425,drifloon,425,531,90,50,34,60,44,70,ghost,flying
426,drifblim,426,532,150,80,44,90,54,80,ghost,flying
427,buneary,427,533,55,66,44,44,56,85,normal,
428,lopunny,428,534,65,76,84,54,96,105,normal,
429,mismagius,429,275,60,60,60,105,105,105,ghost,
430,honchkrow,430,273,100,125,52,105,52,71,dark,flying
431,glameow,431,536,49,55,42,42,37,85,normal,
432,purugly,432,537,71,82,64,64,59,112,normal,
433,chingling,433,452,45,30,50,65,50,45,psychic,
434,stunky,434,538,63,63,47,41,41,74,poison,dark
435,skuntank,435,539,103,93,67,71,61,84,poison,dark
436,bronzor,436,540,57,24,86,24,86,23,steel,psychic
437,bronzong,437,541,67,89,116,79,116,33,steel,psychic
438,bonsly,438,259,50,80,95,10,45,10,rock,
439,mime-jr,439,177,20,25,45,70,90,60,psychic,fairy
440,happiny,440,163,100,5,5,15,65,30,normal,
441,chatot,441,542,76,65,45,92,42,91,normal,flying
442,spiritomb,442,543,50,92,108,92,108,35,ghost,dark
443,gible,443,544,58,70,45,40,45,42,dragon,ground
444,gabite,444,545,68,90,65,50,55,82,dragon,ground
445,garchomp,445,546,108,130,95,80,85,102,dragon,ground
446,munchlax,446,216,135,85,40,40,85,5,normal,
447,riolu,447,548,40,70,40,35,40,60,fighting,
448,lucario,448,549,70,110,70,115,70,90,fighting,steel
449,hippopotas,449,551,68,72,78,38,42,32,ground,
450,hippowdon,450,552,108,112,118,68,72,47,ground,
451,skorupi,451,553,40,50,90,30,55,65,poison,bug
452,drapion,452,554,70,90,110,60,75,95,poison,dark
453,croagunk,453,555,48,61,40,61,40,50,poison,fighting
454,toxicroak,454,556,83,106,65,86,65,85,poison,fighting
455,carnivine,455,557,74,100,72,90,72,46,grass,
456,finneon,456,558,49,49,56,49,61,66,water,
457,lumineon,457,559,69,69,76,69,86,91,water,
458,mantyke,458,304,45,20,50,60,120,50,water,flying
459,snover,459,560,60,62,50,62,60,40,grass,ice
460,abomasnow,460,561,90,92,75,92,85,60,grass,ice
461,weavile,461,292,70,120,65,45,85,125,dark,ice
462,magnezone,462,120,70,70,115,130,90,60,electric,steel
463,lickilicky,463,157,110,85,95,80,95,50,normal,
464,rhyperior,464,162,115,140,130,55,55,40,ground,rock
465,tangrowth,465,167,100,100,125,110,50,50,grass,
466,electivire,466,186,75,123,67,95,85,95,electric,
467,magmortar,467,189,75,95,67,125,95,83,fire,
468,togekiss,468,249,85,50,95,120,115,80,fairy,flying
469,yanmega,469,269,86,76,86,116,56,95,bug,flying
470,leafeon,470,204,65,110,130,60,65,95,grass,
471,glaceon,471,205,65,60,110,130,95,65,ice,
472,gliscor,472,284,75,95,125,45,75,95,ground,flying
473,mamoswine,473,299,110,130,80,70,60,80,ice,ground
474,porygon-z,474,209,85,80,70,135,75,90,normal,
475,gallade,475,360,68,125,65,65,115,80,psychic,fighting
476,probopass,476,378,60,55,145,75,150,40,rock,steel
477,dusknoir,477,450,45,100,135,65,135,45,ghost,
478,froslass,478,459,70,80,70,80,70,110,ice,ghost
479,rotom,479,563,50,50,77,95,77,91,electric,ghost
480,uxie,480,569,75,75,130,75,130,95,psychic,
481,mesprit,481,570,80,105,105,105,105,80,psychic,
482,azelf,482,571,75,125,70,125,70,115,psychic,
483,dialga,483,572,100,120,120,150,100,90,steel,dragon
484,palkia,484,573,90,120,100,150,120,100,water,dragon
485,heatran,485,574,91,90,106,130,106,77,fire,steel
486,regigigas,486,575,110,160,110,80,110,100,normal,
487,giratina-altered,487,576,150,100,120,100,120,90,ghost,dragon
488,cresselia,488,578,120,70,120,75,130,85,psychic,
489,phione,489,579,80,80,80,80,80,80,water,
490,manaphy,490,580,100,100,100,100,100,100,water,
491,darkrai,491,581,70,90,90,135,90,125,dark,
492,shaymin-land,492,582,100,100,100,100,100,100,grass,
493,arceus,493,584,120,120,120,120,120,120,normal,
494,victini,494,585,100,100,100,100,100,100,psychic,fire
495,snivy,495,586,45,45,55,45,55,63,grass,
496,servine,496,587,60,60,75,60,75,83,grass,
497,serperior,497,588,75,75,95,75,95,113,grass,
498,tepig,498,589,65,63,45,45,45,45,fire,
499,pignite,499,590,90,93,55,70,55,55,fire,fighting
500,emboar,500,591,110,123,65,100,65,65,fire,fighting
501,oshawott,501,592,55,55,45,63,45,45,water,
502,dewott,502,593,75,75,60,83,60,60,water,
503,samurott,503,594,95,100,85,108,70,70,water,
504,patrat,504,595,45,55,39,35,39,42,normal,
505,watchog,505,596,60,85,69,60,69,77,normal,
506,lillipup,506,597,45,60,45,25,45,55,normal,
507,herdier,507,598,65,80,65,35,65,60,normal,
508,stoutland,508,599,85,110,90,45,90,80,normal,
509,purrloin,509,600,41,50,37,50,37,66,dark,
510,liepard,510,601,64,88,50,88,50,106,dark,
511,pansage,511,602,50,53,48,53,48,64,grass,
512,simisage,512,603,75,98,63,98,63,101,grass,
513,pansear,513,604,50,53,48,53,48,64,fire,
514,simisear,514,605,75,98,63,98,63,101,fire,
515,panpour,515,606,50,53,48,53,48,64,water,
516,simipour,516,607,75,98,63,98,63,101,water,
517,munna,517,608,76,25,45,67,55,24,psychic,
518,musharna,518,609,116,55,85,107,95,29,psychic,
519,pidove,519,610,50,55,50,36,30,43,normal,flying
520,tranquill,520,611,62,77,62,50,42,65,normal,flying
521,unfezant,521,612,80,115,80,65,55,93,normal,flying
522,blitzle,522,613,45,60,32,50,32,76,electric,
523,zebstrika,523,614,75,100,63,80,63,116,electric,
524,roggenrola,524,615,55,75,85,25,25,15,rock,
525,boldore,525,616,70,105,105,50,40,20,rock,
526,gigalith,526,617,85,135,130,60,80,25,rock,
527,woobat,527,618,65,45,43,55,43,72,psychic,flying
528,swoobat,528,619,67,57,55,77,55,114,psychic,flying
529,drilbur,529,620,60,85,40,30,45,68,ground,
530,excadrill,530,621,110,135,60,50,65,88,ground,steel
531,audino,531,622,103,60,86,60,86,50,normal,
532,timburr,532,624,75,80,55,25,35,35,fighting,
533,gurdurr,533,625,85,105,85,40,50,40,fighting,
534,conkeldurr,534,626,105,140,95,55,65,45,fighting,
535,tympole,535,627,50,50,40,50,40,64,water,
536,palpitoad,536,628,75,65,55,65,55,69,water,ground
537,seismitoad,537,629,105,95,75,85,75,74,water,ground
538,throh,538,630,120,100,85,30,85,45,fighting,
539,sawk,539,631,75,125,75,30,75,85,fighting,
540,sewaddle,540,632,45,53,70,40,60,42,bug,grass
541,swadloon,541,633,55,63,90,50,80,42,bug,grass
542,leavanny,542,634,75,103,80,70,80,92,bug,grass
543,venipede,543,635,30,45,59,30,39,57,bug,poison
544,whirlipede,544,636,40,55,99,40,79,47,bug,poison
545,scolipede,545,637,60,100,89,55,69,112,bug,poison
546,cottonee,546,638,40,27,60,37,50,66,grass,fairy
547,whimsicott,547,639,60,67,85,77,75,116,grass,fairy
548,petilil,548,640,45,35,50,70,50,30,grass,
549,lilligant,549,641,70,60,75,110,75,90,grass,
550,basculin-red-striped,550,642,70,92,65,80,55,98,water,
551,sandile,551,644,50,72,35,35,35,65,ground,dark
552,krokorok,552,645,60,82,45,45,45,74,ground,dark
553,krookodile,553,646,95,117,80,65,70,92,ground,dark
554,darumaka,554,647,70,90,45,15,45,50,fire,
555,darmanitan-standard,555,648,105,140,55,30,55,95,fire,
556,maractus,556,650,75,86,67,106,67,60,grass,
557,dwebble,557,651,50,65,85,35,35,55,bug,rock
558,crustle,558,652,70,105,125,65,75,45,bug,rock
559,scraggy,559,653,50,75,70,35,70,48,dark,fighting
560,scrafty,560,654,65,90,115,45,115,58,dark,fighting
561,sigilyph,561,655,72,58,80,103,80,97,psychic,flying
562,yamask,562,656,38,30,85,55,65,30,ghost,
563,cofagrigus,563,657,58,50,145,95,105,30,ghost,
564,tirtouga,564,658,54,78,103,53,45,22,water,rock
565,carracosta,565,659,74,108,133,83,65,32,water,rock
566,archen,566,660,55,112,45,74,45,70,rock,flying
567,archeops,567,661,75,140,65,112,65,110,rock,flying
568,trubbish,568,662,50,50,62,40,62,65,poison,
569,garbodor,569,663,80,95,82,60,82,75,poison,
570,zorua,570,664,40,65,40,80,40,65,dark,
571,zoroark,571,665,60,105,60,120,60,105,dark,
572,minccino,572,666,55,50,40,40,40,75,normal,
573,cinccino,573,667,75,95,60,65,60,115,normal,
574,gothita,574,668,45,30,50,55,65,45,psychic,
575,gothorita,575,669,60,45,70,75,85,55,psychic,
576,gothitelle,576,670,70,55,95,95,110,65,psychic,
577,solosis,577,671,45,30,40,105,50,20,psychic,
578,duosion,578,672,65,40,50,125,60,30,psychic,
579,reuniclus,579,673,110,65,75,125,85,30,psychic,
580,ducklett,580,674,62,44,50,44,50,55,water,flying
581,swanna,581,675,75,87,63,87,63,98,water,flying
582,vanillite,582,676,36,50,50,65,60,44,ice,
583,vanillish,583,677,51,65,65,80,75,59,ice,
584,vanilluxe,584,678,71,95,85,110,95,79,ice,
585,deerling,585,679,60,60,50,40,50,75,normal,grass
586,sawsbuck,586,680,80,100,70,60,70,95,normal,grass
587,emolga,587,681,55,75,60,75,60,103,electric,flying
588,karrablast,588,682,50,75,45,40,45,60,bug,
589,escavalier,589,683,70,135,105,60,105,20,bug,steel
590,foongus,590,684,69,55,45,55,55,15,grass,poison
591,amoonguss,591,685,114,85,70,85,80,30,grass,poison
592,frillish,592,686,55,40,50,65,85,40,water,ghost
593,jellicent,593,687,100,60,70,85,105,60,water,ghost
594,alomomola,594,688,165,75,80,40,45,65,water,
595,joltik,595,689,50,47,50,57,50,65,bug,electric
596,galvantula,596,690,70,77,60,97,60,108,bug,electric
597,ferroseed,597,691,44,50,91,24,86,10,grass,steel
598,ferrothorn,598,692,74,94,131,54,116,20,grass,steel
599,klink,599,693,40,55,70,45,60,30,steel,
600,klang,600,694,60,80,95,70,85,50,steel,
601,klinklang,601,695,60,100,115,70,85,90,steel,
602,tynamo,602,696,35,55,40,45,40,60,electric,
603,eelektrik,603,697,65,85,70,75,70,40,electric,
604,eelektross,604,698,85,115,80,105,80,50,electric,
605,elgyem,605,699,55,55,55,85,55,30,psychic,
606,beheeyem,606,700,75,75,75,125,95,40,psychic,
607,litwick,607,701,50,30,55,65,55,20,ghost,fire
608,lampent,608,702,60,40,60,95,60,55,ghost,fire
609,chandelure,609,703,60,55,90,145,90,80,ghost,fire
610,axew,610,704,46,87,60,30,40,57,dragon,
611,fraxure,611,705,66,117,70,40,50,67,dragon,
612,haxorus,612,706,76,147,90,60,70,97,dragon,
613,cubchoo,613,707,55,70,40,60,40,40,ice,
614,beartic,614,708,95,130,80,70,80,50,ice,
615,cryogonal,615,709,80,50,50,95,135,105,ice,
616,shelmet,616,710,50,40,85,40,65,25,bug,
617,accelgor,617,711,80,70,40,100,60,145,bug,
618,stunfisk,618,712,109,66,84,81,99,32,ground,electric
619,mienfoo,619,713,45,85,50,55,50,65,fighting,
620,mienshao,620,714,65,125,60,95,60,105,fighting,
621,druddigon,621,715,77,120,90,60,90,48,dragon,
622,golett,622,716,59,74,50,35,50,35,ground,ghost
623,golurk,623,717,89,124,80,55,80,55,ground,ghost
624,pawniard,624,718,45,85,70,40,40,60,dark,steel
625,bisharp,625,719,65,125,100,60,70,70,dark,steel
626,bouffalant,626,720,95,110,95,40,95,55,normal,
627,rufflet,627,721,70,83,50,37,50,60,normal,flying
628,braviary,628,722,100,123,75,57,75,80,normal,flying
629,vullaby,629,723,70,55,75,45,65,60,dark,flying
630,mandibuzz,630,724,110,65,105,55,95,80,dark,flying
631,heatmor,631,725,85,97,66,105,66,65,fire,
632,durant,632,726,58,109,112,48,48,109,bug,steel
633,deino,633,727,52,65,50,45,50,38,dark,dragon
634,zweilous,634,728,72,85,70,65,70,58,dark,dragon
635,hydreigon,635,729,92,105,90,125,90,98,dark,dragon
636,larvesta,636,730,55,85,55,50,55,60,bug,fire
637,volcarona,637,731,85,60,65,135,105,100,bug,fire
638,cobalion,638,732,91,90,129,90,72,108,steel,fighting
639,terrakion,639,733,91,129,90,72,90,108,rock,fighting
640,virizion,640,734,91,90,72,90,129,108,grass,fighting
641,tornadus-incarnate,641,735,79,115,70,125,80,111,flying,
642,thundurus-incarnate,642,737,79,115,70,125,80,111,electric,flying
643,reshiram,643,739,100,120,100,150,120,90,dragon,fire
644,zekrom,644,740,100,150,120,120,100,90,dragon,electric
645,landorus-incarnate,645,741,89,125,90,115,80,101,ground,flying
646,kyurem,646,743,125,130,90,130,90,95,dragon,ice
647,keldeo-ordinary,647,746,91,72,90,129,90,108,water,fighting
648,meloetta-aria,648,748,100,77,77,128,128,90,normal,psychic
649,genesect,649,750,71,120,95,120,95,99,bug,steel
650,chespin,650,751,56,61,65,48,45,38,grass,
651,quilladin,651,752,61,78,95,56,58,57,grass,
652,chesnaught,652,753,88,107,122,74,75,64,grass,fighting
653,fennekin,653,754,40,45,40,62,60,60,fire,
654,braixen,654,755,59,59,58,90,70,73,fire,
655,delphox,655,756,75,69,72,114,100,104,fire,psychic
656,froakie,656,757,41,56,40,62,44,71,water,
657,frogadier,657,758,54,63,52,83,56,97,water,
658,greninja,658,759,72,95,67,103,71,122,water,dark
659,bunnelby,659,762,38,36,38,32,36,57,normal,
660,diggersby,660,763,85,56,77,50,77,78,normal,ground
661,fletchling,661,764,45,50,43,40,38,62,normal,flying
662,fletchinder,662,765,62,73,55,56,52,84,fire,flying
663,talonflame,663,766,78,81,71,74,69,126,fire,flying
664,scatterbug,664,767,38,35,40,27,25,35,bug,
665,spewpa,665,768,45,22,60,27,30,29,bug,
666,vivillon,666,769,80,52,50,90,50,89,bug,flying
667,litleo,667,770,62,50,58,73,54,72,fire,normal
668,pyroar,668,771,86,68,72,109,66,106,fire,normal
669,flabebe,669,772,44,38,39,61,79,42,fairy,
670,floette,670,773,54,45,47,75,98,52,fairy,
671,florges,671,775,78,65,68,112,154,75,fairy,
672,skiddo,672,776,66,65,48,62,57,52,grass,
673,gogoat,673,777,123,100,62,97,81,68,grass,
674,pancham,674,778,67,82,62,46,48,43,fighting,
675,pangoro,675,779,95,124,78,69,71,58,fighting,dark
676,furfrou,676,780,75,80,60,65,90,102,normal,
677,espurr,677,781,62,48,54,63,60,68,psychic,
678,meowstic-male,678,782,74,48,76,83,81,104,psychic,
679,honedge,679,784,45,80,100,35,37,28,steel,ghost
680,doublade,680,785,59,110,150,45,49,35,steel,ghost
681,aegislash-shield,681,786,60,50,150,50,150,60,steel,ghost
682,spritzee,682,788,78,52,60,63,65,23,fairy,
683,aromatisse,683,789,101,72,72,99,89,29,fairy,
684,swirlix,684,790,62,48,66,59,57,49,fairy,
685,slurpuff,685,791,82,80,86,85,75,72,fairy,
686,inkay,686,792,53,54,53,37,46,45,dark,psychic
687,malamar,687,793,86,92,88,68,75,73,dark,psychic
688,binacle,688,794,42,52,67,39,56,50,rock,water
689,barbaracle,689,795,72,105,115,54,86,68,rock,water
690,skrelp,690,796,50,60,60,60,60,30,poison,water
691,dragalge,691,797,65,75,90,97,123,44,poison,dragon
692,clauncher,692,798,50,53,62,58,63,44,water,
693,clawitzer,693,799,71,73,88,120,89,59,water,
694,helioptile,694,800,44,38,33,61,43,70,electric,normal
695,heliolisk,695,801,62,55,52,109,94,109,electric,normal
696,tyrunt,696,802,58,89,77,45,45,48,rock,dragon
697,tyrantrum,697,803,82,121,119,69,59,71,rock,dragon
698,amaura,698,804,77,59,50,67,63,46,rock,ice
699,aurorus,699,805,123,77,72,99,92,58,rock,ice
700,sylveon,700,206,95,65,65,110,130,60,fairy,
701,hawlucha,701,806,78,92,75,74,63,118,fighting,flying
702,dedenne,702,807,67,58,57,81,67,101,electric,fairy
703,carbink,703,808,50,50,150,50,150,50,rock,fairy
704,goomy,704,809,45,50,35,55,75,40,dragon,
705,sliggoo,705,810,68,75,53,83,113,60,dragon,
706,goodra,706,811,90,100,70,110,150,80,dragon,
707,klefki,707,812,57,80,91,80,87,75,steel,fairy
708,phantump,708,813,43,70,48,50,60,38,ghost,grass
709,trevenant,709,814,85,110,76,65,82,56,ghost,grass
710,pumpkaboo-average,710,815,49,66,70,44,55,51,ghost,grass
711,gourgeist-average,711,819,65,90,122,58,75,84,ghost,grass
712,bergmite,712,823,55,69,85,32,35,28,ice,
713,avalugg,713,824,95,117,184,44,46,28,ice,
714,noibat,714,825,40,30,35,45,40,55,flying,dragon
715,noivern,715,826,85,70,80,97,80,123,flying,dragon
716,xerneas,716,827,126,131,95,131,98,99,fairy,
717,yveltal,717,828,126,131,95,131,98,99,dark,flying
718,zygarde,718,829,108,100,121,81,95,95,dragon,ground
719,diancie,719,833,50,100,150,100,150,50,rock,fairy
720,hoopa,720,835,80,110,60,150,130,70,psychic,ghost
721,volcanion,721,837,80,110,120,130,90,70,fire,water
722,rowlet,722,838,68,55,55,50,50,42,grass,flying
723,dartrix,723,839,78,75,75,70,70,52,grass,flying
724,decidueye,724,840,78,107,75,100,100,70,grass,ghost
725,litten,725,841,45,65,40,60,40,70,fire,
726,torracat,726,842,65,85,50,80,50,90,fire,
727,incineroar,727,843,95,115,90,80,90,60,fire,dark
728,popplio,728,844,50,54,54,66,56,40,water,
729,brionne,729,845,60,69,69,91,81,50,water,
730,primarina,730,846,80,74,74,126,116,60,water,fairy
731,pikipek,731,847,35,75,30,30,30,65,normal,flying
732,trumbeak,732,848,55,85,50,40,50,75,normal,flying
733,toucannon,733,849,80,120,75,75,75,60,normal,flying
734,yungoos,734,850,48,70,30,30,30,45,normal,
735,gumshoos,735,851,88,110,60,55,60,45,normal,
736,grubbin,736,853,47,62,45,55,45,46,bug,
737,charjabug,737,854,57,82,95,55,75,36,bug,electric
738,vikavolt,738,855,77,70,90,145,75,43,bug,electric
739,crabrawler,739,857,47,82,57,42,47,63,fighting,
740,crabominable,740,858,97,132,77,62,67,43,fighting,ice
741,oricorio-baile,741,859,75,70,70,98,70,93,fire,flying
742,cutiefly,742,863,40,45,40,55,40,84,bug,fairy
743,ribombee,743,864,60,55,60,95,70,124,bug,fairy
744,rockruff,744,866,45,65,40,30,40,60,rock,
745,lycanroc-midday,745,868,75,115,65,55,65,112,rock,
746,wishiwashi-solo,746,871,45,20,20,25,25,40,water,
747,mareanie,747,873,50,53,62,43,52,45,poison,water
748,toxapex,748,874,50,63,152,53,142,35,poison,water
749,mudbray,749,875,70,100,70,45,55,45,ground,
750,mudsdale,750,876,100,125,100,55,85,35,ground,
751,dewpider,751,877,38,40,52,40,72,27,water,bug
752,araquanid,752,878,68,70,92,50,132,42,water,bug
753,fomantis,753,880,40,55,35,50,35,35,grass,
754,lurantis,754,881,70,105,90,80,90,45,grass,
755,morelull,755,883,40,35,55,65,75,15,grass,fairy
756,shiinotic,756,884,60,45,80,90,100,30,grass,fairy
757,salandit,757,885,48,44,40,71,40,77,poison,fire
758,salazzle,758,886,68,64,60,111,60,117,poison,fire
759,stufful,759,888,70,75,50,45,50,50,normal,fighting
760,bewear,760,889,120,125,80,55,60,60,normal,fighting
761,bounsweet,761,890,42,30,38,30,38,32,grass,
762,steenee,762,891,52,40,48,40,48,62,grass,
763,tsareena,763,892,72,120,98,50,98,72,grass,
764,comfey,764,893,51,52,90,82,110,100,fairy,
765,oranguru,765,894,90,60,80,90,110,60,normal,psychic
766,passimian,766,895,100,120,90,40,60,80,fighting,
767,wimpod,767,896,25,35,40,20,30,80,bug,water
768,golisopod,768,897,75,125,140,60,90,40,bug,water
769,sandygast,769,898,55,55,80,70,45,15,ghost,ground
770,palossand,770,899,85,75,110,100,75,35,ghost,ground
771,pyukumuku,771,900,55,60,130,30,130,5,water,
772,type-null,772,901,95,95,95,95,95,59,normal,
773,silvally,773,902,95,95,95,95,95,95,normal,
774,minior-red-meteor,774,903,60,60,100,60,100,60,rock,flying
775,komala,775,917,65,115,65,75,95,65,normal,
776,turtonator,776,918,60,78,135,91,85,36,fire,dragon
777,togedemaru,777,919,65,98,63,40,73,96,electric,steel
778,mimikyu-disguised,778,921,55,90,80,50,105,96,ghost,fairy
779,bruxish,779,925,68,105,70,70,70,92,water,psychic
780,drampa,780,926,78,60,85,135,91,36,normal,dragon
781,dhelmise,781,927,70,131,100,86,90,40,ghost,grass
782,jangmo-o,782,928,45,55,65,45,45,45,dragon,
783,hakamo-o,783,929,55,75,90,65,70,65,dragon,fighting
784,kommo-o,784,930,75,110,125,100,105,85,dragon,fighting
785,tapu-koko,785,932,70,115,85,95,75,130,electric,fairy
786,tapu-lele,786,933,70,85,75,130,115,95,psychic,fairy
787,tapu-bulu,787,934,70,130,115,85,95,75,grass,fairy
788,tapu-fini,788,935,70,75,115,95,130,85,water,fairy
789,cosmog,789,936,43,29,31,29,31,37,psychic,
790,cosmoem,790,937,43,29,131,29,131,37,psychic,
791,solgaleo,791,938,137,137,107,113,89,97,psychic,steel
792,lunala,792,939,137,113,89,137,107,97,psychic,ghost
793,nihilego,793,940,109,53,47,127,131,103,rock,poison
794,buzzwole,794,941,107,139,139,53,53,79,bug,fighting
795,pheromosa,795,942,71,137,37,137,37,151,bug,fighting
796,xurkitree,796,943,83,89,71,173,71,83,electric,
797,celesteela,797,944,97,101,103,107,101,61,steel,flying
798,kartana,798,945,59,181,131,59,31,109,grass,steel
799,guzzlord,799,946,223,101,53,97,53,43,dark,dragon
800,necrozma,800,947,97,107,101,127,89,79,psychic,
801,magearna,801,951,80,95,115,130,115,65,steel,fairy
802,marshadow,802,953,90,125,80,90,90,125,fighting,ghost
803,poipole,803,954,67,73,67,73,67,73,poison,
804,naganadel,804,955,73,73,73,127,73,121,poison,dragon
805,stakataka,805,956,61,131,211,53,101,13,rock,steel
806,blacephalon,806,957,53,127,53,151,79,107,fire,ghost
807,zeraora,807,958,88,112,75,102,80,143,electric,
10001,deoxys-attack,386,491,50,180,20,180,20,150,psychic,
10002,deoxys-defense,386,492,50,70,160,70,160,90,psychic,
10003,deoxys-speed,386,493,50,95,90,95,90,180,psychic,
10004,wormadam-sandy,413,519,60,79,105,59,85,36,bug,ground
10005,wormadam-trash,413,520,60,69,95,69,95,36,bug,steel
10006,shaymin-sky,492,583,100,103,75,120,75,127,grass,flying
10007,giratina-origin,487,577,150,120,100,120,100,90,ghost,dragon
10008,rotom-heat,479,564,50,65,107,105,107,86,electric,fire
10009,rotom-wash,479,565,50,65,107,105,107,86,electric,water
10010,rotom-frost,479,566,50,65,107,105,107,86,electric,ice
10011,rotom-fan,479,567,50,65,107,105,107,86,electric,flying
10012,rotom-mow,479,568,50,65,107,105,107,86,electric,grass
10013,castform-sunny,351,441,70,70,70,70,70,70,fire,
10014,castform-rainy,351,442,70,70,70,70,70,70,water,
10015,castform-snowy,351,443,70,70,70,70,70,70,ice,
10016,basculin-blue-striped,550,643,70,92,65,80,55,98,water,
10017,darmanitan-zen,555,649,105,30,105,140,105,55,fire,psychic
10018,meloetta-pirouette,648,749,100,128,90,77,77,128,normal,fighting
10019,tornadus-therian,641,736,79,100,80,110,90,121,flying,
10020,thundurus-therian,642,738,79,105,70,145,80,101,electric,flying
10021,landorus-therian,645,742,89,145,90,105,80,91,ground,flying
10022,kyurem-black,646,745,125,170,100,120,90,95,dragon,ice
10023,kyurem-white,646,744,125,120,90,170,100,95,dragon,ice
10024,keldeo-resolute,647,747,91,72,90,129,90,108,water,fighting
10025,meowstic-female,678,783,74,48,76,83,81,104,psychic,
10026,aegislash-blade,681,787,60,150,50,150,50,60,steel,ghost
10027,pumpkaboo-small,710,816,44,66,70,44,55,56,ghost,grass
10028,pumpkaboo-large,710,817,54,66,70,44,55,46,ghost,grass
10029,pumpkaboo-super,710,818,59,66,70,44,55,41,ghost,grass
10030,gourgeist-small,711,820,55,85,122,58,75,99,ghost,grass
10031,gourgeist-large,711,821,75,95,122,58,75,69,ghost,grass
10032,gourgeist-super,711,822,85,100,122,58,75,54,ghost,grass
10033,venusaur-mega,3,4,80,100,123,122,120,80,grass,poison
10034,charizard-mega-x,6,8,78,130,111,130,85,100,fire,dragon
10035,charizard-mega-y,6,9,78,104,78,159,115,100,fire,flying
10036,blastoise-mega,9,13,79,103,120,135,115,78,water,
10037,alakazam-mega,65,97,55,50,65,175,105,150,psychic,
10038,gengar-mega,94,135,60,65,80,170,95,130,ghost,poison
10039,kangaskhan-mega,115,169,105,125,100,60,100,100,normal,
10040,pinsir-mega,127,191,65,155,120,65,90,105,bug,flying
10041,gyarados-mega,130,195,95,155,109,70,130,81,water,dark
10042,aerodactyl-mega,142,215,80,135,85,70,95,150,rock,flying
10043,mewtwo-mega-x,150,225,106,190,100,154,100,130,psychic,fighting
10044,mewtwo-mega-y,150,226,106,150,70,194,120,140,psychic,
10045,ampharos-mega,181,255,90,95,105,165,110,45,electric,dragon
10046,scizor-mega,212,181,70,150,140,65,100,75,bug,steel
10047,heracross-mega,214,290,80,185,115,40,105,75,bug,fighting
10048,houndoom-mega,229,309,75,90,90,140,90,115,dark,fire
10049,tyranitar-mega,248,321,100,164,150,95,120,71,rock,dark
10050,blaziken-mega,257,332,80,160,80,130,80,100,fire,fighting
10051,gardevoir-mega,282,359,68,85,65,165,135,100,psychic,fairy
10052,mawile-mega,303,384,50,105,125,55,95,50,steel,fairy
10053,aggron-mega,306,388,70,140,230,60,80,50,steel,
10054,medicham-mega,308,391,60,100,85,80,85,100,fighting,psychic
10055,manectric-mega,310,394,70,75,80,135,80,135,electric,
10056,banette-mega,354,447,64,165,75,93,83,75,ghost,
10057,absol-mega,359,455,65,150,60,115,60,115,dark,
10058,garchomp-mega,445,547,108,170,115,120,95,92,dragon,ground
10059,lucario-mega,448,550,70,145,88,140,70,112,fighting,steel
10060,abomasnow-mega,460,562,90,132,105,132,105,30,grass,ice
10061,floette-eternal,670,774,74,65,67,125,128,92,fairy,
10062,latias-mega,380,480,80,100,120,140,150,110,dragon,psychic
10063,latios-mega,381,482,80,130,100,160,120,110,dragon,psychic
10064,swampert-mega,260,336,100,150,110,95,110,70,water,ground
10065,sceptile-mega,254,328,70,110,75,145,85,145,grass,dragon
10066,sableye-mega,302,382,50,85,125,85,115,20,dark,ghost
10067,altaria-mega,334,423,75,110,110,110,105,80,dragon,fairy
10068,gallade-mega,475,361,68,165,95,65,115,110,psychic,fighting
10069,audino-mega,531,623,103,60,126,80,126,50,normal,fairy
10070,sharpedo-mega,319,406,70,140,70,110,65,105,water,dark
10071,slowbro-mega,80,116,95,75,180,130,80,30,water,psychic
10072,steelix-mega,208,138,75,125,230,55,95,30,steel,ground
10073,pidgeot-mega,18,24,83,80,80,135,80,121,normal,flying
10074,glalie-mega,362,458,80,120,80,120,80,100,ice,
10075,diancie-mega,719,834,50,160,110,160,110,110,rock,fairy
10076,metagross-mega,376,475,80,145,150,105,110,110,steel,psychic
10077,kyogre-primal,382,484,100,150,90,180,160,90,water,
10078,groudon-primal,383,486,100,180,160,150,90,90,ground,fire
10079,rayquaza-mega,384,488,105,180,100,180,100,115,dragon,flying
10080,pikachu-rock-star,25,37,35,55,40,50,50,90,electric,
10081,pikachu-belle,25,38,35,55,40,50,50,90,electric,
10082,pikachu-pop-star,25,39,35,55,40,50,50,90,electric,
10083,pikachu-phd,25,40,35,55,40,50,50,90,electric,
10084,pikachu-libre,25,41,35,55,40,50,50,90,electric,
10085,pikachu-cosplay,25,36,35,55,40,50,50,90,electric,
10086,hoopa-unbound,720,836,80,160,60,170,130,80,psychic,dark
10087,camerupt-mega,323,411,70,120,100,145,105,20,fire,ground
10088,lopunny-mega,428,535,65,136,94,54,96,135,normal,fighting
10089,salamence-mega,373,471,95,145,130,120,90,120,dragon,flying
10090,beedrill-mega,15,20,65,150,40,15,80,145,bug,poison
10091,rattata-alola,19,26,30,56,35,25,35,72,dark,normal
10092,raticate-alola,20,28,75,71,70,40,80,77,dark,normal
10093,raticate-totem-alola,20,29,75,71,70,40,80,77,dark,normal
10094,pikachu-original-cap,25,36,35,55,40,50,50,90,electric,
10095,pikachu-hoenn-cap,25,37,35,55,40,50,50,90,electric,
10096,pikachu-sinnoh-cap,25,38,35,55,40,50,50,90,electric,
10097,pikachu-unova-cap,25,39,35,55,40,50,50,90,electric,
10098,pikachu-kalos-cap,25,40,35,55,40,50,50,90,electric,
10099,pikachu-alola-cap,25,41,35,55,40,50,50,90,electric,
10100,raichu-alola,26,44,60,85,50,95,85,110,electric,psychic
10101,sandshrew-alola,27,46,50,75,90,10,35,40,ice,steel
10102,sandslash-alola,28,48,75,100,120,25,65,65,ice,steel
10103,vulpix-alola,37,59,38,41,40,50,65,65,ice,
10104,ninetales-alola,38,61,73,67,75,81,100,109,ice,fairy
10105,diglett-alola,50,77,10,55,30,35,45,90,ground,steel
10106,dugtrio-alola,51,79,35,100,60,50,70,110,ground,steel
10107,meowth-alola,52,81,40,35,35,50,40,90,dark,
10108,persian-alola,53,83,65,60,60,75,65,115,dark,
10109,geodude-alola,74,107,40,80,100,30,30,20,rock,electric
10110,graveler-alola,75,109,55,95,115,45,45,35,rock,electric
10111,golem-alola,76,111,80,120,130,55,65,45,rock,electric
10112,grimer-alola,88,127,80,80,50,40,50,25,poison,dark
10113,muk-alola,89,129,105,105,75,65,100,50,poison,dark
10114,exeggutor-alola,103,147,95,105,85,125,75,45,grass,dragon
10115,marowak-alola,105,150,60,80,110,50,80,45,fire,ghost
10116,greninja-battle-bond,658,760,72,95,67,103,71,122,water,dark
10117,greninja-ash,658,761,72,145,67,153,71,132,water,dark
10118,zygarde-10,718,830,54,100,71,61,85,115,dragon,ground
10119,zygarde-50,718,831,108,100,121,81,95,95,dragon,ground
10120,zygarde-complete,718,832,216,100,121,91,95,85,dragon,ground
10121,gumshoos-totem,735,852,88,110,60,55,60,45,normal,
10122,vikavolt-totem,738,856,77,70,90,145,75,43,bug,electric
10123,oricorio-pom-pom,741,860,75,70,70,98,70,93,electric,flying
10124,oricorio-pau,741,861,75,70,70,98,70,93,psychic,flying
10125,oricorio-sensu,741,862,75,70,70,98,70,93,ghost,flying
10126,lycanroc-midnight,745,869,85,115,75,55,75,82,rock,
10127,wishiwashi-school,746,872,45,140,130,140,135,30,water,
10128,lurantis-totem,754,882,70,105,90,80,90,45,grass,
10129,salazzle-totem,758,887,68,64,60,111,60,117,poison,fire
10130,minior-orange-meteor,774,904,60,60,100,60,100,60,rock,flying
10131,minior-yellow-meteor,774,905,60,60,100,60,100,60,rock,flying
10132,minior-green-meteor,774,906,60,60,100,60,100,60,rock,flying
10133,minior-blue-meteor,774,907,60,60,100,60,100,60,rock,flying
10134,minior-indigo-meteor,774,908,60,60,100,60,100,60,rock,flying
10135,minior-violet-meteor,774,909,60,60,100,60,100,60,rock,flying
10136,minior-red,774,910,60,100,60,100,60,120,rock,flying
10137,minior-orange,774,911,60,100,60,100,60,120,rock,flying
10138,minior-yellow,774,912,60,100,60,100,60,120,rock,flying
10139,minior-green,774,913,60,100,60,100,60,120,rock,flying
10140,minior-blue,774,914,60,100,60,100,60,120,rock,flying
10141,minior-indigo,774,915,60,100,60,100,60,120,rock,flying
10142,minior-violet,774,916,60,100,60,100,60,120,rock,flying
10143,mimikyu-busted,778,922,55,90,80,50,105,96,ghost,fairy
10144,mimikyu-totem-disguised,778,923,55,90,80,50,105,96,ghost,fairy
10145,mimikyu-totem-busted,778,924,55,90,80,50,105,96,ghost,fairy
10146,kommo-o-totem,784,931,75,110,125,100,105,85,dragon,fighting
10147,magearna-original,801,952,80,95,115,130,115,65,steel,fairy
10148,pikachu-partner-cap,25,42,35,55,40,50,50,90,electric,
10149,marowak-totem,105,151,60,80,110,50,80,45,fire,ghost
10150,ribombee-totem,743,865,60,55,60,95,70,124,bug,fairy
10151,rockruff-own-tempo,744,867,45,65,40,30,40,60,rock,
10152,lycanroc-dusk,745,870,75,117,65,55,65,110,rock,
10153,araquanid-totem,752,879,68,70,92,50,132,42,water,bug
10154,togedemaru-totem,777,920,65,98,63,40,73,96,electric,steel
10155,necrozma-dusk,800,948,97,157,127,113,109,77,psychic,steel
10156,necrozma-dawn,800,949,97,113,109,157,127,77,psychic,ghost
10157,necrozma-ultra,800,950,97,167,97,167,97,129,psychic,dragon`