import React from "react";
import { Router, navigate } from "@reach/router";
import { pokemondata as pokemonstring } from "../data/PokemonData";
import { moveDatabase } from "../data/moveDatabase";
import { pokemonLearnset } from "../data/pokemonLearnset";
import Papa from "papaparse";
import { Table } from "../components/table";

export const Counters = () => {
  return <div>this is where you look up counters</div>;
};
