export const moveDatabase = 
`id,identifier,generation_id,type_id,power,pp,accuracy,priority,target_id,damage_class_id,effect_id,effect_chance,contest_type_id,contest_effect_id,super_contest_effect_id
1,pound,1,1,40,35,100,0,10,2,1,,5,1,5
2,karate-chop,1,2,50,25,100,0,10,2,44,,5,2,5
3,double-slap,1,1,15,10,85,0,10,2,30,,5,10,7
4,comet-punch,1,1,18,15,85,0,10,2,30,,5,12,7
5,mega-punch,1,1,80,20,85,0,10,2,1,,5,1,18
6,pay-day,1,1,40,20,100,0,10,2,35,,4,11,23
7,fire-punch,1,10,75,15,100,0,10,2,5,10,2,1,17
8,ice-punch,1,15,75,15,100,0,10,2,6,10,2,1,17
9,thunder-punch,1,13,75,15,100,0,10,2,7,10,1,1,17
10,scratch,1,1,40,35,100,0,10,2,1,,5,1,5
11,vice-grip,1,1,55,30,100,0,10,2,1,,5,1,5
12,guillotine,1,1,,5,30,0,10,2,39,,1,14,9
13,razor-wind,1,1,80,10,100,0,11,3,40,,1,2,6
14,swords-dance,1,1,,20,,0,7,1,51,,2,32,11
15,cut,1,1,50,30,95,0,10,2,1,,1,14,5
16,gust,1,3,40,35,100,0,10,3,150,,4,21,5
17,wing-attack,1,3,60,35,100,0,10,2,1,,1,12,5
18,whirlwind,1,1,,20,,-6,10,1,29,,4,21,9
19,fly,1,3,90,15,95,0,10,2,156,,4,15,6
20,bind,1,1,15,20,85,0,10,2,43,100,5,24,21
21,slam,1,1,80,20,75,0,10,2,1,,5,23,5
22,vine-whip,1,12,45,25,100,0,10,2,1,,1,1,5
23,stomp,1,1,65,20,100,0,10,2,151,30,5,4,5
24,double-kick,1,2,30,30,100,0,10,2,45,,1,12,7
25,mega-kick,1,1,120,5,75,0,10,2,1,,1,1,18
26,jump-kick,1,2,100,10,95,0,10,2,46,,1,3,5
27,rolling-kick,1,2,60,15,85,0,10,2,32,30,1,5,5
28,sand-attack,1,5,,15,100,0,10,1,24,,3,10,19
29,headbutt,1,1,70,15,100,0,10,2,32,30,5,9,5
30,horn-attack,1,1,65,25,100,0,10,2,1,,1,1,5
31,fury-attack,1,1,15,20,85,0,10,2,30,,1,10,7
32,horn-drill,1,1,,5,30,0,10,2,39,,1,14,9
33,tackle,1,1,40,35,100,0,10,2,1,,5,1,5
34,body-slam,1,1,85,15,100,0,10,2,7,30,5,4,5
35,wrap,1,1,15,20,90,0,10,2,43,100,5,24,21
36,take-down,1,1,90,20,85,0,10,2,49,,5,3,5
37,thrash,1,1,120,10,100,0,8,2,28,,5,6,7
38,double-edge,1,1,120,15,100,0,10,2,199,,5,3,9
39,tail-whip,1,1,,30,100,0,11,1,20,,3,28,19
40,poison-sting,1,4,15,35,100,0,10,2,3,30,4,9,19
41,twineedle,1,7,25,20,100,0,10,2,78,20,1,9,7
42,pin-missile,1,7,25,20,95,0,10,2,30,,1,10,7
43,leer,1,1,,30,100,0,11,1,20,100,1,24,19
44,bite,1,17,60,25,100,0,10,2,32,30,5,5,5
45,growl,1,1,,40,100,0,11,1,19,,3,28,19
46,roar,1,1,,20,,-6,10,1,29,,1,21,19
47,sing,1,1,,15,55,0,10,1,2,,3,18,19
48,supersonic,1,1,,20,55,0,10,1,50,,4,21,19
49,sonic-boom,1,1,,20,90,0,10,3,131,,1,12,5
50,disable,1,1,,20,100,0,10,1,87,,4,18,19
51,acid,1,4,40,30,100,0,11,3,73,10,4,4,5
52,ember,1,10,40,25,100,0,10,3,5,10,2,1,5
53,flamethrower,1,10,90,15,100,0,10,3,5,10,2,1,17
54,mist,1,15,,30,,0,4,1,47,,2,15,13
55,water-gun,1,11,40,25,100,0,10,3,1,,3,1,5
56,hydro-pump,1,11,110,5,80,0,10,3,1,,2,1,17
57,surf,1,11,90,15,100,0,9,3,258,,2,2,17
58,ice-beam,1,15,90,10,100,0,10,3,6,10,2,23,17
59,blizzard,1,15,110,5,70,0,11,3,261,10,2,1,17
60,psybeam,1,14,65,20,100,0,10,3,77,10,2,21,17
61,bubble-beam,1,11,65,20,100,0,10,3,71,10,2,5,2
62,aurora-beam,1,15,65,20,100,0,10,3,69,10,2,23,17
63,hyper-beam,1,1,150,5,90,0,10,3,81,,1,6,22
64,peck,1,3,35,35,100,0,10,2,1,,1,1,5
65,drill-peck,1,3,80,20,100,0,10,2,1,,1,1,5
66,submission,1,2,80,20,80,0,10,2,49,,1,3,5
67,low-kick,1,2,,20,100,0,10,2,197,,5,4,5
68,counter,1,2,,20,100,-5,1,2,90,,5,16,15
69,seismic-toss,1,2,,20,100,0,10,2,88,,5,23,5
70,strength,1,1,80,15,100,0,10,2,1,,5,23,5
71,absorb,1,12,20,25,100,0,10,3,4,,4,9,20
72,mega-drain,1,12,40,15,100,0,10,3,4,,4,4,20
73,leech-seed,1,12,,10,90,0,10,1,85,,4,8,21
74,growth,1,1,,20,,0,7,1,317,,2,32,11
75,razor-leaf,1,12,55,25,95,0,11,2,44,,1,2,5
76,solar-beam,1,12,120,10,100,0,10,3,152,,1,1,6
77,poison-powder,1,4,,35,75,0,10,1,67,,4,33,19
78,stun-spore,1,12,,30,75,0,10,1,68,,4,14,19
79,sleep-powder,1,12,,15,75,0,10,1,2,,4,5,19
80,petal-dance,1,12,120,10,100,0,8,3,28,,2,6,21
81,string-shot,1,7,,40,95,0,11,1,61,,4,9,19
82,dragon-rage,1,16,,10,100,0,10,3,42,,1,26,5
83,fire-spin,1,10,35,15,85,0,10,3,43,100,2,24,21
84,thunder-shock,1,13,40,30,100,0,10,3,7,10,1,1,5
85,thunderbolt,1,13,90,15,100,0,10,3,7,10,1,1,17
86,thunder-wave,1,13,,20,90,0,10,1,68,,1,14,19
87,thunder,1,13,110,10,70,0,10,3,153,30,1,8,17
88,rock-throw,1,6,50,15,90,0,10,2,1,,5,12,5
89,earthquake,1,5,100,10,100,0,9,2,148,,5,5,18
90,fissure,1,5,,5,30,0,10,2,39,,5,14,9
91,dig,1,5,80,10,100,0,10,2,257,,4,15,6
92,toxic,1,4,,10,90,0,10,1,34,,4,33,19
93,confusion,1,14,50,25,100,0,10,3,77,10,4,9,5
94,psychic,1,14,90,10,100,0,10,3,73,10,4,5,17
95,hypnosis,1,14,,20,60,0,10,1,2,,4,5,19
96,meditate,1,14,,40,,0,7,1,11,,2,32,11
97,agility,1,14,,30,,0,7,1,53,,1,30,1
98,quick-attack,1,1,40,30,100,1,10,2,104,,1,30,1
99,rage,1,1,20,20,100,0,10,2,82,,1,17,11
100,teleport,1,14,,20,,0,7,1,154,,1,15,1
101,night-shade,1,8,,15,100,0,10,3,88,,4,23,5
102,mimic,1,1,,10,,0,10,1,83,,3,19,12
103,screech,1,1,,40,85,0,10,1,60,,4,5,10
104,double-team,1,1,,15,,0,7,1,17,,1,16,1
105,recover,1,1,,10,,0,7,1,33,,4,23,8
106,harden,1,1,,30,,0,7,1,12,,5,16,13
107,minimize,1,1,,10,,0,7,1,109,,3,16,13
108,smokescreen,1,1,,20,100,0,10,1,24,,4,21,10
109,confuse-ray,1,8,,10,100,0,10,1,50,,4,21,10
110,withdraw,1,11,,40,,0,7,1,12,,3,15,13
111,defense-curl,1,1,,40,,0,7,1,157,,3,16,13
112,barrier,1,14,,20,,0,7,1,52,,1,15,13
113,light-screen,1,14,,30,,0,4,1,36,,2,15,13
114,haze,1,15,,30,,0,12,1,26,,2,33,13
115,reflect,1,14,,20,,0,4,1,66,,4,15,13
116,focus-energy,1,1,,30,,0,7,1,48,,1,5,11
117,bide,1,1,,10,,1,7,2,27,,5,15,15
118,metronome,1,1,,10,,0,7,1,84,,3,17,14
119,mirror-move,1,3,,20,,0,10,1,10,,4,19,15
120,self-destruct,1,1,200,5,100,0,9,2,8,,2,7,9
121,egg-bomb,1,1,100,10,75,0,10,2,1,,5,1,5
122,lick,1,8,30,30,100,0,10,2,7,30,5,4,16
123,smog,1,4,30,20,70,0,10,3,3,40,5,5,5
124,sludge,1,4,65,20,100,0,10,3,3,30,5,4,18
125,bone-club,1,5,65,20,85,0,10,2,32,10,5,10,5
126,fire-blast,1,10,110,5,85,0,10,3,5,10,2,1,17
127,waterfall,1,11,80,15,100,0,10,2,32,20,5,28,5
128,clamp,1,11,35,15,85,0,10,2,43,100,5,24,5
129,swift,1,1,60,20,,0,11,3,18,,1,27,17
130,skull-bash,1,1,130,10,100,0,10,2,146,100,5,4,6
131,spike-cannon,1,1,20,15,100,0,10,2,30,,1,10,7
132,constrict,1,1,10,35,100,0,10,2,71,10,5,9,21
133,amnesia,1,14,,20,,0,7,1,55,,3,32,11
134,kinesis,1,14,,15,80,0,10,1,24,,4,24,11
135,soft-boiled,1,1,,10,,0,7,1,33,,2,1,8
136,high-jump-kick,1,2,130,10,90,0,10,2,46,,1,3,5
137,glare,1,1,,30,100,0,10,1,68,,5,5,19
138,dream-eater,1,14,100,15,100,0,10,3,9,,4,8,20
139,poison-gas,1,4,,40,90,0,11,1,67,,4,33,5
140,barrage,1,1,15,20,85,0,10,2,30,,5,12,7
141,leech-life,1,7,80,10,100,0,10,2,4,,4,9,20
142,lovely-kiss,1,1,,10,75,0,10,1,2,,2,5,19
143,sky-attack,1,3,140,5,90,0,10,2,76,30,1,2,6
144,transform,1,1,,10,,0,10,1,58,,4,17,23
145,bubble,1,11,40,30,100,0,11,3,71,10,3,8,2
146,dizzy-punch,1,1,70,10,100,0,10,2,77,20,1,4,16
147,spore,1,12,,15,100,0,10,1,2,,2,5,10
148,flash,1,1,,20,100,0,10,1,24,,2,21,10
149,psywave,1,14,,15,100,0,10,3,89,,4,14,5
150,splash,1,1,,40,,0,7,1,86,,3,28,16
151,acid-armor,1,4,,20,,0,7,1,52,,5,32,11
152,crabhammer,1,11,100,10,90,0,10,2,44,,5,2,18
153,explosion,1,1,250,5,100,0,9,2,8,,2,7,9
154,fury-swipes,1,1,18,15,80,0,10,2,30,,5,10,7
155,bonemerang,1,5,50,10,90,0,10,2,45,,5,1,7
156,rest,1,14,,10,,0,7,1,38,,3,16,8
157,rock-slide,1,6,75,10,90,0,11,2,32,30,5,5,5
158,hyper-fang,1,1,80,15,90,0,10,2,32,10,1,4,18
159,sharpen,1,1,,30,,0,7,1,11,,3,32,11
160,conversion,1,1,,30,,0,7,1,31,,2,12,23
161,tri-attack,1,1,80,10,100,0,10,3,37,20,2,8,5
162,super-fang,1,1,,10,90,0,10,2,41,,5,14,5
163,slash,1,1,70,20,100,0,10,2,44,,1,2,5
164,substitute,1,1,,10,,0,7,1,80,,4,16,23
165,struggle,1,1,50,1,,0,8,2,255,,1,1,5
166,sketch,2,1,,1,,0,10,1,96,,4,19,23
167,triple-kick,2,2,10,10,90,0,10,2,105,,1,1,7
168,thief,2,17,60,25,100,0,10,2,106,,5,20,12
169,spider-web,2,7,,10,,0,10,1,107,,4,18,13
170,mind-reader,2,1,,5,,0,10,1,95,,4,24,20
171,nightmare,2,8,,15,100,0,10,1,108,,4,5,19
172,flame-wheel,2,10,60,25,100,0,10,2,126,10,2,1,7
173,snore,2,1,50,15,100,0,10,3,93,30,3,1,5
174,curse,2,8,,10,,0,1,1,110,,5,31,21
175,flail,2,1,,15,100,0,10,2,100,,3,26,15
176,conversion-2,2,1,,30,,0,10,1,94,,2,12,23
177,aeroblast,2,3,100,5,95,0,10,3,44,,1,2,22
178,cotton-spore,2,12,,40,100,0,11,1,61,,2,10,1
179,reversal,2,2,,15,100,0,10,2,100,,1,28,15
180,spite,2,8,,10,100,0,10,1,101,,5,28,19
181,powder-snow,2,15,40,25,100,0,11,3,6,10,2,1,5
182,protect,2,1,,10,,4,7,1,112,,3,15,16
183,mach-punch,2,2,40,30,100,1,10,2,104,,1,30,1
184,scary-face,2,1,,10,100,0,10,1,61,,5,10,2
185,feint-attack,2,17,60,20,,0,10,2,18,,4,27,18
186,sweet-kiss,2,18,,10,75,0,10,1,50,,3,18,19
187,belly-drum,2,1,,10,,0,7,1,143,,3,32,11
188,sludge-bomb,2,4,90,10,100,0,10,3,3,30,5,10,18
189,mud-slap,2,5,20,10,100,0,10,3,74,100,3,10,5
190,octazooka,2,11,65,10,85,0,10,3,74,50,5,10,4
191,spikes,2,5,,20,,0,6,1,113,,4,18,13
192,zap-cannon,2,13,120,5,50,0,10,3,7,100,1,1,4
193,foresight,2,1,,40,,0,10,1,114,,4,33,20
194,destiny-bond,2,8,,5,,0,7,1,99,,4,7,9
195,perish-song,2,1,,5,,0,14,1,115,,2,14,10
196,icy-wind,2,15,55,15,95,0,11,3,71,100,2,5,2
197,detect,2,2,,5,,4,7,1,112,,1,16,16
198,bone-rush,2,5,25,10,90,0,10,2,30,,5,1,7
199,lock-on,2,1,,5,,0,10,1,95,,4,24,20
200,outrage,2,16,120,10,100,0,8,2,28,,1,6,7
201,sandstorm,2,6,,10,,0,12,1,116,,5,21,13
202,giga-drain,2,12,75,10,100,0,10,3,4,,4,10,20
203,endure,2,1,,10,,4,7,1,117,,5,16,13
204,charm,2,18,,20,100,0,10,1,59,,3,23,19
205,rollout,2,6,30,20,90,0,10,2,118,,5,24,7
206,false-swipe,2,1,40,40,100,0,10,2,102,,1,5,16
207,swagger,2,1,,15,85,0,10,1,119,,3,27,19
208,milk-drink,2,1,,10,,0,7,1,33,,3,12,8
209,spark,2,13,65,20,100,0,10,2,7,30,1,4,5
210,fury-cutter,2,7,40,20,95,0,10,2,120,,1,17,7
211,steel-wing,2,9,70,25,90,0,10,2,139,10,1,12,5
212,mean-look,2,1,,5,,0,10,1,107,,2,18,10
213,attract,2,1,,15,100,0,10,1,121,,3,18,19
214,sleep-talk,2,1,,10,,0,7,1,98,,3,17,5
215,heal-bell,2,1,,5,,0,13,1,103,,2,28,8
216,return,2,1,,20,100,0,10,2,122,,3,13,17
217,present,2,1,,15,90,0,10,2,123,,3,17,16
218,frustration,2,1,,20,100,0,10,2,124,,3,13,18
219,safeguard,2,1,,25,,0,4,1,125,,2,15,13
220,pain-split,2,1,,20,,0,10,1,92,,4,4,10
221,sacred-fire,2,10,100,5,95,0,10,2,126,50,2,1,22
222,magnitude,2,5,,30,100,0,9,2,127,,5,11,18
223,dynamic-punch,2,2,100,5,50,0,10,2,77,100,1,10,18
224,megahorn,2,7,120,10,85,0,10,2,1,,1,12,18
225,dragon-breath,2,16,60,20,100,0,10,3,7,30,1,5,17
226,baton-pass,2,1,,40,,0,7,1,128,,3,18,16
227,encore,2,1,,5,100,0,10,1,91,,3,18,20
228,pursuit,2,17,40,20,100,0,10,2,129,,4,14,20
229,rapid-spin,2,1,20,40,100,0,10,2,130,,1,16,17
230,sweet-scent,2,1,,20,100,0,11,1,25,,3,5,19
231,iron-tail,2,9,100,15,75,0,10,2,70,30,1,4,18
232,metal-claw,2,9,50,35,95,0,10,2,140,10,1,1,18
233,vital-throw,2,2,70,10,,-1,10,2,79,,1,31,2
234,morning-sun,2,1,,5,,0,7,1,133,,2,25,8
235,synthesis,2,12,,5,,0,7,1,133,,4,25,8
236,moonlight,2,18,,5,,0,7,1,133,,2,25,8
237,hidden-power,2,1,60,15,100,0,10,3,136,,4,17,23
238,cross-chop,2,2,100,5,80,0,10,2,44,,1,2,18
239,twister,2,16,40,20,100,0,11,3,147,20,1,21,5
240,rain-dance,2,11,,5,,0,12,1,137,,5,11,13
241,sunny-day,2,10,,5,,0,12,1,138,,2,11,13
242,crunch,2,17,80,15,100,0,10,2,70,20,5,4,18
243,mirror-coat,2,14,,20,100,-5,1,3,145,,2,16,15
244,psych-up,2,1,,10,,0,10,1,144,,4,12,11
245,extreme-speed,2,1,80,5,100,2,10,2,104,,1,30,1
246,ancient-power,2,6,60,5,100,0,10,3,141,10,5,32,18
247,shadow-ball,2,8,80,15,100,0,10,3,73,20,4,21,17
248,future-sight,2,14,120,10,100,0,10,3,149,,4,24,17
249,rock-smash,2,2,40,15,100,0,10,2,70,50,5,29,18
250,whirlpool,2,11,35,15,85,0,10,3,262,100,2,24,21
251,beat-up,2,17,,10,100,0,10,2,155,,4,14,7
252,fake-out,3,1,40,10,100,3,10,2,159,100,3,23,17
253,uproar,3,1,90,10,100,0,8,3,160,,3,21,10
254,stockpile,3,1,,20,,0,7,1,161,,5,16,11
255,spit-up,3,1,,10,100,0,10,3,162,,5,1,18
256,swallow,3,1,,10,,0,7,1,163,,5,32,8
257,heat-wave,3,10,95,10,90,0,11,3,5,10,2,1,17
258,hail,3,15,,10,,0,12,1,165,,2,5,13
259,torment,3,17,,15,100,0,10,1,166,,5,18,16
260,flatter,3,17,,15,100,0,10,1,167,,4,18,19
261,will-o-wisp,3,10,,15,85,0,10,1,168,,2,4,17
262,memento,3,17,,10,100,0,10,1,169,,5,7,9
263,facade,3,1,70,20,100,0,10,2,170,,3,28,15
264,focus-punch,3,2,150,20,100,-3,10,2,171,,5,31,6
265,smelling-salts,3,1,70,10,100,0,10,2,172,,4,9,16
266,follow-me,3,1,,20,,2,7,1,173,,3,24,14
267,nature-power,3,1,,20,,0,10,1,174,,2,11,14
268,charge,3,13,,20,,0,7,1,175,,4,12,11
269,taunt,3,17,,20,100,0,10,1,176,,4,18,16
270,helping-hand,3,1,,20,,5,3,1,177,,4,18,20
271,trick,3,14,,10,100,0,10,1,178,,4,12,12
272,role-play,3,14,,10,,0,10,1,179,,3,20,23
273,wish,3,1,,10,,0,7,1,180,,3,24,8
274,assist,3,1,,20,,0,7,1,181,,3,25,14
275,ingrain,3,12,,20,,0,7,1,182,,4,15,21
276,superpower,3,2,120,5,100,0,10,2,183,100,5,3,18
277,magic-coat,3,14,,15,,4,7,1,184,,2,15,15
278,recycle,3,1,,10,,0,7,1,185,,4,17,12
279,revenge,3,2,60,10,100,-4,10,2,186,,5,31,15
280,brick-break,3,2,75,15,100,0,10,2,187,,1,4,5
281,yawn,3,1,,10,,0,10,1,188,,3,18,19
282,knock-off,3,17,65,20,100,0,10,2,189,,4,4,5
283,endeavor,3,1,,5,100,0,10,2,190,,5,28,15
284,eruption,3,10,150,5,100,0,11,3,191,,2,26,18
285,skill-swap,3,14,,10,,0,10,1,192,,4,20,12
286,imprison,3,14,,10,,0,7,1,193,,4,33,20
287,refresh,3,1,,20,,0,7,1,194,,3,32,8
288,grudge,3,8,,5,,0,7,1,195,,5,26,10
289,snatch,3,17,,10,,4,7,1,196,,4,14,12
290,secret-power,3,1,70,20,100,0,10,2,198,30,4,29,14
291,dive,3,11,80,10,100,0,10,2,256,,2,16,6
292,arm-thrust,3,2,15,20,100,0,10,2,30,,5,10,7
293,camouflage,3,1,,20,,0,7,1,214,,4,2,13
294,tail-glow,3,7,,20,,0,7,1,322,,2,32,11
295,luster-purge,3,14,70,5,100,0,10,3,73,50,4,9,22
296,mist-ball,3,14,70,5,100,0,10,3,72,50,4,4,22
297,feather-dance,3,3,,15,100,0,10,1,59,,2,28,19
298,teeter-dance,3,1,,20,100,0,9,1,200,,3,6,14
299,blaze-kick,3,10,85,10,90,0,10,2,201,10,2,1,17
300,mud-sport,3,5,,15,,0,12,1,202,,3,1,13
301,ice-ball,3,15,30,20,90,0,10,2,118,,2,24,7
302,needle-arm,3,12,60,15,100,0,10,2,32,30,4,4,5
303,slack-off,3,1,,10,,0,7,1,33,,3,26,8
304,hyper-voice,3,1,90,10,100,0,11,3,1,,1,5,5
305,poison-fang,3,4,50,15,100,0,10,2,203,50,4,33,4
306,crush-claw,3,1,75,10,95,0,10,2,70,50,1,4,5
307,blast-burn,3,10,150,5,90,0,10,3,81,,2,6,22
308,hydro-cannon,3,11,150,5,90,0,10,3,81,,2,6,22
309,meteor-mash,3,9,90,10,90,0,10,2,140,20,1,12,18
310,astonish,3,8,30,15,100,0,10,2,32,30,4,9,5
311,weather-ball,3,1,50,10,100,0,10,3,204,,4,1,4
312,aromatherapy,3,12,,5,,0,13,1,103,,4,28,8
313,fake-tears,3,17,,20,100,0,10,1,63,,4,28,19
314,air-cutter,3,3,60,25,95,0,11,3,44,,1,23,5
315,overheat,3,10,130,5,90,0,10,3,205,100,2,3,22
316,odor-sleuth,3,1,,40,,0,10,1,114,,4,33,20
317,rock-tomb,3,6,60,15,95,0,10,2,71,100,4,24,2
318,silver-wind,3,7,60,5,100,0,10,3,141,10,2,32,4
319,metal-sound,3,9,,40,85,0,10,1,63,,4,5,10
320,grass-whistle,3,12,,15,55,0,10,1,2,,4,5,19
321,tickle,3,1,,20,100,0,10,1,206,,3,33,19
322,cosmic-power,3,14,,20,,0,7,1,207,,1,32,11
323,water-spout,3,11,150,5,100,0,11,3,191,,2,26,22
324,signal-beam,3,7,75,15,100,0,10,3,77,10,2,21,4
325,shadow-punch,3,8,60,20,,0,10,2,18,,4,27,17
326,extrasensory,3,14,80,20,100,0,10,3,32,10,1,4,17
327,sky-uppercut,3,2,85,15,90,0,10,2,208,,1,23,17
328,sand-tomb,3,5,35,15,85,0,10,2,43,100,4,24,21
329,sheer-cold,3,15,,5,30,0,10,3,39,,2,14,9
330,muddy-water,3,11,90,10,85,0,11,3,74,30,5,10,18
331,bullet-seed,3,12,25,30,100,0,10,2,30,,1,14,7
332,aerial-ace,3,3,60,20,,0,10,2,18,,1,27,17
333,icicle-spear,3,15,25,30,100,0,10,2,30,,2,23,7
334,iron-defense,3,9,,15,,0,7,1,52,,5,15,13
335,block,3,1,,5,,0,10,1,107,,3,18,13
336,howl,3,1,,40,,0,7,1,11,,1,32,11
337,dragon-claw,3,16,80,15,100,0,10,2,1,,1,23,17
338,frenzy-plant,3,12,150,5,90,0,10,3,81,,1,6,22
339,bulk-up,3,2,,20,,0,7,1,209,,2,32,11
340,bounce,3,3,85,5,85,0,10,2,264,30,3,15,6
341,mud-shot,3,5,55,15,95,0,10,3,71,100,5,5,2
342,poison-tail,3,4,50,25,100,0,10,2,210,10,4,33,4
343,covet,3,1,60,25,100,0,10,2,106,,3,20,12
344,volt-tackle,3,13,120,15,100,0,10,2,263,10,1,3,22
345,magical-leaf,3,12,60,20,,0,10,3,18,,2,27,17
346,water-sport,3,11,,15,,0,12,1,211,,3,1,13
347,calm-mind,3,14,,20,,0,7,1,212,,4,16,11
348,leaf-blade,3,12,90,15,100,0,10,2,44,,1,2,17
349,dragon-dance,3,16,,20,,0,7,1,213,,1,32,11
350,rock-blast,3,6,25,10,90,0,10,2,30,,5,12,7
351,shock-wave,3,13,60,20,,0,10,3,18,,1,27,17
352,water-pulse,3,11,60,20,100,0,10,3,77,20,2,21,17
353,doom-desire,3,9,140,5,100,0,10,3,149,,1,24,17
354,psycho-boost,3,14,140,5,90,0,10,3,205,100,4,3,22
355,roost,4,3,,10,,0,7,1,215,,1,,8
356,gravity,4,14,,5,,0,12,1,216,,2,,13
357,miracle-eye,4,14,,40,,0,10,1,217,,3,,20
358,wake-up-slap,4,2,70,10,100,0,10,2,218,,4,,16
359,hammer-arm,4,2,100,10,90,0,10,2,219,100,1,,2
360,gyro-ball,4,9,,5,100,0,10,2,220,,2,,15
361,healing-wish,4,14,,10,,0,7,1,221,,3,,8
362,brine,4,11,65,10,100,0,10,3,222,,4,,4
363,natural-gift,4,1,,15,100,0,10,2,223,,1,,18
364,feint,4,1,30,10,100,2,10,2,224,,2,,16
365,pluck,4,3,60,20,100,0,10,2,225,,3,,12
366,tailwind,4,3,,15,,0,4,1,226,,4,,1
367,acupressure,4,1,,30,,0,5,1,227,,1,,11
368,metal-burst,4,9,,10,100,0,1,2,228,,2,,15
369,u-turn,4,7,70,20,100,0,10,2,229,,3,,16
370,close-combat,4,2,120,5,100,0,10,2,230,100,4,,22
371,payback,4,17,50,10,100,0,10,2,231,,1,,6
372,assurance,4,17,60,10,100,0,10,2,232,,2,,15
373,embargo,4,17,,15,100,0,10,1,233,,3,,13
374,fling,4,17,,10,100,0,10,2,234,,5,,23
375,psycho-shift,4,14,,10,100,0,10,1,235,,1,,16
376,trump-card,4,1,,5,,0,10,3,236,,1,,21
377,heal-block,4,14,,15,100,0,11,1,237,,3,,13
378,wring-out,4,1,,5,100,0,10,3,238,,4,,22
379,power-trick,4,14,,10,,0,7,1,239,,1,,16
380,gastro-acid,4,4,,10,100,0,10,1,240,,2,,13
381,lucky-chant,4,1,,30,,0,4,1,241,,3,,13
382,me-first,4,1,,20,,0,2,1,242,,3,,1
383,copycat,4,1,,20,,0,7,1,243,,1,,12
384,power-swap,4,14,,10,,0,10,1,244,,2,,16
385,guard-swap,4,14,,10,,0,10,1,245,,3,,16
386,punishment,4,17,,5,100,0,10,2,246,,4,,23
387,last-resort,4,1,140,5,100,0,10,2,247,,3,,21
388,worry-seed,4,12,,10,100,0,10,1,248,,2,,19
389,sucker-punch,4,17,70,5,100,1,10,2,249,,4,,1
390,toxic-spikes,4,4,,20,,0,6,1,250,,4,,13
391,heart-swap,4,14,,10,,0,10,1,251,,1,,16
392,aqua-ring,4,11,,20,,0,7,1,252,,2,,8
393,magnet-rise,4,13,,10,,0,7,1,253,,3,,13
394,flare-blitz,4,10,120,15,100,0,10,2,254,10,4,,22
395,force-palm,4,2,60,10,100,0,10,2,7,30,1,,18
396,aura-sphere,4,2,80,20,,0,10,3,18,,2,,17
397,rock-polish,4,6,,20,,0,7,1,53,,5,,1
398,poison-jab,4,4,80,20,100,0,10,2,3,30,4,,4
399,dark-pulse,4,17,80,15,100,0,10,3,32,20,1,,17
400,night-slash,4,17,70,15,100,0,10,2,44,,2,,5
401,aqua-tail,4,11,90,10,90,0,10,2,1,,3,,5
402,seed-bomb,4,12,80,15,100,0,10,2,1,,4,,5
403,air-slash,4,3,75,15,95,0,10,3,32,30,1,,17
404,x-scissor,4,7,80,15,100,0,10,2,1,,2,,17
405,bug-buzz,4,7,90,10,100,0,10,3,73,10,3,,4
406,dragon-pulse,4,16,85,10,100,0,10,3,1,,4,,4
407,dragon-rush,4,16,100,10,75,0,10,2,32,20,1,,18
408,power-gem,4,6,80,20,100,0,10,3,1,,2,,5
409,drain-punch,4,2,75,10,100,0,10,2,4,,2,,20
410,vacuum-wave,4,2,40,30,100,1,10,3,104,,4,,1
411,focus-blast,4,2,120,5,70,0,10,3,73,10,1,,17
412,energy-ball,4,12,90,10,100,0,10,3,73,10,2,,17
413,brave-bird,4,3,120,15,100,0,10,2,199,,3,,18
414,earth-power,4,5,90,10,100,0,10,3,73,10,4,,18
415,switcheroo,4,17,,10,100,0,10,1,178,,1,,12
416,giga-impact,4,1,150,5,90,0,10,2,81,,2,,22
417,nasty-plot,4,17,,20,,0,7,1,54,,3,,11
418,bullet-punch,4,9,40,30,100,1,10,2,104,,4,,1
419,avalanche,4,15,60,10,100,-4,10,2,186,,1,,15
420,ice-shard,4,15,40,30,100,1,10,2,104,,2,,1
421,shadow-claw,4,8,70,15,100,0,10,2,44,,3,,17
422,thunder-fang,4,13,65,15,95,0,10,2,276,10,4,,5
423,ice-fang,4,15,65,15,95,0,10,2,275,10,1,,5
424,fire-fang,4,10,65,15,95,0,10,2,274,10,2,,5
425,shadow-sneak,4,8,40,30,100,1,10,2,104,,4,,1
426,mud-bomb,4,5,65,10,85,0,10,3,74,30,4,,18
427,psycho-cut,4,14,70,20,100,0,10,2,44,,1,,17
428,zen-headbutt,4,14,80,15,90,0,10,2,32,20,2,,18
429,mirror-shot,4,9,65,10,85,0,10,3,74,30,3,,17
430,flash-cannon,4,9,80,10,100,0,10,3,73,10,4,,17
431,rock-climb,4,1,90,20,85,0,10,2,77,20,1,,18
432,defog,4,3,,15,,0,10,1,259,,2,,13
433,trick-room,4,14,,5,,-7,12,1,260,,3,,14
434,draco-meteor,4,16,130,5,90,0,10,3,205,100,4,,22
435,discharge,4,13,80,15,100,0,9,3,7,30,1,,17
436,lava-plume,4,10,80,15,100,0,9,3,5,30,5,,17
437,leaf-storm,4,12,130,5,90,0,10,3,205,100,3,,22
438,power-whip,4,12,120,10,85,0,10,2,1,,2,,5
439,rock-wrecker,4,6,150,5,90,0,10,2,81,,5,,22
440,cross-poison,4,4,70,20,100,0,10,2,210,10,1,,5
441,gunk-shot,4,4,120,5,80,0,10,2,3,30,1,,5
442,iron-head,4,9,80,15,100,0,10,2,32,30,5,,18
443,magnet-bomb,4,9,60,20,,0,10,2,18,,1,,5
444,stone-edge,4,6,100,5,80,0,10,2,44,,5,,4
445,captivate,4,1,,20,100,0,11,1,266,,2,,19
446,stealth-rock,4,6,,20,,0,6,1,267,,1,,13
447,grass-knot,4,12,,20,100,0,10,3,197,,4,,4
448,chatter,4,3,65,20,100,0,10,3,268,100,4,,23
449,judgment,4,1,100,10,100,0,10,3,269,,4,,14
450,bug-bite,4,7,60,20,100,0,10,2,225,,5,,12
451,charge-beam,4,13,50,10,90,0,10,3,277,70,2,,17
452,wood-hammer,4,12,120,15,100,0,10,2,199,,5,,18
453,aqua-jet,4,11,40,20,100,1,10,2,104,,2,,1
454,attack-order,4,7,90,15,100,0,10,2,44,,4,,4
455,defend-order,4,7,,10,,0,7,1,207,,4,,11
456,heal-order,4,7,,10,,0,7,1,33,,4,,8
457,head-smash,4,6,150,5,80,0,10,2,270,,5,,22
458,double-hit,4,1,35,10,90,0,10,2,45,,4,,7
459,roar-of-time,4,16,150,5,90,0,10,3,81,,1,,22
460,spacial-rend,4,16,100,5,95,0,10,3,44,,5,,4
461,lunar-dance,4,14,,10,,0,7,1,271,,2,,8
462,crush-grip,4,1,,5,100,0,10,2,238,,5,,15
463,magma-storm,4,10,100,5,75,0,10,3,43,100,5,,7
464,dark-void,4,17,,10,50,0,11,1,2,,4,,19
465,seed-flare,4,12,120,5,85,0,10,3,272,40,1,,22
466,ominous-wind,4,8,60,5,100,0,10,3,141,10,4,,11
467,shadow-force,4,8,120,5,100,0,10,2,273,,4,,22
468,hone-claws,5,17,,15,,0,7,1,278,,,,
469,wide-guard,5,6,,10,,3,4,1,279,,,,
470,guard-split,5,14,,10,,0,10,1,280,,,,
471,power-split,5,14,,10,,0,10,1,281,,,,
472,wonder-room,5,14,,10,,0,12,1,282,,,,
473,psyshock,5,14,80,10,100,0,10,3,283,,,,
474,venoshock,5,4,65,10,100,0,10,3,284,,,,
475,autotomize,5,9,,15,,0,7,1,285,,,,
476,rage-powder,5,7,,20,,2,7,1,173,,,,
477,telekinesis,5,14,,15,,0,10,1,286,,,,
478,magic-room,5,14,,10,,0,12,1,287,,,,
479,smack-down,5,6,50,15,100,0,10,2,288,100,,,
480,storm-throw,5,2,60,10,100,0,10,2,289,,,,
481,flame-burst,5,10,70,15,100,0,10,3,290,,,,
482,sludge-wave,5,4,95,10,100,0,9,3,3,10,,,
483,quiver-dance,5,7,,20,,0,7,1,291,,,,
484,heavy-slam,5,9,,10,100,0,10,2,292,,,,
485,synchronoise,5,14,120,10,100,0,9,3,293,,,,
486,electro-ball,5,13,,10,100,0,10,3,294,,,,
487,soak,5,11,,20,100,0,10,1,295,,,,
488,flame-charge,5,10,50,20,100,0,10,2,296,100,,,
489,coil,5,4,,20,,0,7,1,323,,,,
490,low-sweep,5,2,65,20,100,0,10,2,21,100,,,
491,acid-spray,5,4,40,20,100,0,10,3,297,100,,,
492,foul-play,5,17,95,15,100,0,10,2,298,,,,
493,simple-beam,5,1,,15,100,0,10,1,299,,,,
494,entrainment,5,1,,15,100,0,10,1,300,,,,
495,after-you,5,1,,15,,0,10,1,301,,,,
496,round,5,1,60,15,100,0,10,3,302,,,,
497,echoed-voice,5,1,40,15,100,0,10,3,303,,,,
498,chip-away,5,1,70,20,100,0,10,2,304,,,,
499,clear-smog,5,4,50,15,,0,10,3,305,,,,
500,stored-power,5,14,20,10,100,0,10,3,306,,,,
501,quick-guard,5,2,,15,,3,4,1,307,,,,
502,ally-switch,5,14,,15,,2,7,1,308,,,,
503,scald,5,11,80,15,100,0,10,3,5,30,,,
504,shell-smash,5,1,,15,,0,7,1,309,,,,
505,heal-pulse,5,14,,10,,0,10,1,310,,,,
506,hex,5,8,65,10,100,0,10,3,311,,,,
507,sky-drop,5,3,60,10,100,0,10,2,312,,,,
508,shift-gear,5,9,,10,,0,7,1,313,,,,
509,circle-throw,5,2,60,10,90,-6,10,2,314,,,,
510,incinerate,5,10,60,15,100,0,11,3,315,,,,
511,quash,5,17,,15,100,0,10,1,316,,,,
512,acrobatics,5,3,55,15,100,0,10,2,318,,,,
513,reflect-type,5,1,,15,,0,10,1,319,,,,
514,retaliate,5,1,70,5,100,0,10,2,320,,,,
515,final-gambit,5,2,,5,100,0,10,3,321,,,,
516,bestow,5,1,,15,,0,10,1,324,,,,
517,inferno,5,10,100,5,50,0,10,3,5,100,,,
518,water-pledge,5,11,80,10,100,0,10,3,325,,,,
519,fire-pledge,5,10,80,10,100,0,10,3,326,,,,
520,grass-pledge,5,12,80,10,100,0,10,3,327,,,,
521,volt-switch,5,13,70,20,100,0,10,3,229,,,,
522,struggle-bug,5,7,50,20,100,0,11,3,72,100,,,
523,bulldoze,5,5,60,20,100,0,9,2,71,100,,,
524,frost-breath,5,15,60,10,90,0,10,3,289,100,,,
525,dragon-tail,5,16,60,10,90,-6,10,2,314,,,,
526,work-up,5,1,,30,,0,7,1,328,,,,
527,electroweb,5,13,55,15,95,0,11,3,21,100,,,
528,wild-charge,5,13,90,15,100,0,10,2,49,,,,
529,drill-run,5,5,80,10,95,0,10,2,44,,,,
530,dual-chop,5,16,40,15,90,0,10,2,45,,,,
531,heart-stamp,5,14,60,25,100,0,10,2,32,30,,,
532,horn-leech,5,12,75,10,100,0,10,2,4,,,,
533,sacred-sword,5,2,90,15,100,0,10,2,304,,,,
534,razor-shell,5,11,75,10,95,0,10,2,70,50,,,
535,heat-crash,5,10,,10,100,0,10,2,292,,,,
536,leaf-tornado,5,12,65,10,90,0,10,3,74,50,,,
537,steamroller,5,7,65,20,100,0,10,2,151,30,,,
538,cotton-guard,5,12,,10,,0,7,1,329,,,,
539,night-daze,5,17,85,10,95,0,10,3,74,40,,,
540,psystrike,5,14,100,10,100,0,10,3,283,,,,
541,tail-slap,5,1,25,10,85,0,10,2,30,,,,
542,hurricane,5,3,110,10,70,0,10,3,334,30,,,
543,head-charge,5,1,120,15,100,0,10,2,49,,,,
544,gear-grind,5,9,50,15,85,0,10,2,45,,,,
545,searing-shot,5,10,100,5,100,0,9,3,5,30,,,
546,techno-blast,5,1,120,5,100,0,10,3,269,,,,
547,relic-song,5,1,75,10,100,0,11,3,330,10,,,
548,secret-sword,5,2,85,10,100,0,10,3,283,,,,
549,glaciate,5,15,65,10,95,0,11,3,331,100,,,
550,bolt-strike,5,13,130,5,85,0,10,2,7,20,,,
551,blue-flare,5,10,130,5,85,0,10,3,5,20,,,
552,fiery-dance,5,10,80,10,100,0,10,3,277,50,,,
553,freeze-shock,5,15,140,5,90,0,10,2,332,30,,,
554,ice-burn,5,15,140,5,90,0,10,3,333,30,,,
555,snarl,5,17,55,15,95,0,11,3,72,100,,,
556,icicle-crash,5,15,85,10,90,0,10,2,32,30,,,
557,v-create,5,10,180,5,95,0,10,2,335,100,,,
558,fusion-flare,5,10,100,5,100,0,10,3,336,,,,
559,fusion-bolt,5,13,100,5,100,0,10,2,337,,,,
560,flying-press,6,2,100,10,95,0,10,2,338,,,,
561,mat-block,6,2,,10,,0,4,1,377,,,,
562,belch,6,4,120,10,90,0,10,3,339,,,,
563,rototiller,6,5,,10,,0,14,1,340,100,,,
564,sticky-web,6,7,,20,,0,6,1,341,,,,
565,fell-stinger,6,7,50,25,100,0,10,2,342,,,,
566,phantom-force,6,8,90,10,100,0,10,2,273,,,,
567,trick-or-treat,6,8,,20,100,0,10,1,343,,,,
568,noble-roar,6,1,,30,100,0,10,1,344,100,,,
569,ion-deluge,6,13,,25,,1,12,1,345,,,,
570,parabolic-charge,6,13,65,20,100,0,9,3,346,,,,
571,forests-curse,6,12,,20,100,0,10,1,376,,,,
572,petal-blizzard,6,12,90,15,100,0,9,2,379,,,,
573,freeze-dry,6,15,70,20,100,0,10,3,380,10,,,
574,disarming-voice,6,18,40,15,,0,11,3,381,,,,
575,parting-shot,6,17,,20,100,0,10,1,347,100,,,
576,topsy-turvy,6,17,,20,,0,10,1,348,,,,
577,draining-kiss,6,18,50,10,100,0,10,3,349,,,,
578,crafty-shield,6,18,,10,,3,4,1,350,,,,
579,flower-shield,6,18,,10,,0,14,1,351,100,,,
580,grassy-terrain,6,12,,10,,0,12,1,352,,,,
581,misty-terrain,6,18,,10,,0,12,1,353,,,,
582,electrify,6,13,,20,,0,10,1,354,,,,
583,play-rough,6,18,90,10,90,0,10,2,69,10,,,
584,fairy-wind,6,18,40,30,100,0,10,3,1,,,,
585,moonblast,6,18,95,15,100,0,10,3,72,30,,,
586,boomburst,6,1,140,10,100,0,9,3,379,,,,
587,fairy-lock,6,18,,10,,0,12,1,355,,,,
588,kings-shield,6,9,,10,,4,7,1,356,,,,
589,play-nice,6,1,,20,,0,10,1,357,100,,,
590,confide,6,1,,20,,0,10,1,358,100,,,
591,diamond-storm,6,6,100,5,95,0,11,2,359,50,,,
592,steam-eruption,6,11,110,5,95,0,10,3,5,30,,,
593,hyperspace-hole,6,14,80,5,,0,10,3,360,,,,
594,water-shuriken,6,11,15,20,100,1,10,3,361,,,,
595,mystical-fire,6,10,75,10,100,0,10,3,72,100,,,
596,spiky-shield,6,12,,10,,4,7,1,362,,,,
597,aromatic-mist,6,18,,20,,0,3,1,363,,,,
598,eerie-impulse,6,13,,15,100,0,10,1,62,,,,
599,venom-drench,6,4,,20,100,0,11,1,364,100,,,
600,powder,6,7,,20,100,1,10,1,378,,,,
601,geomancy,6,18,,10,,0,7,1,366,,,,
602,magnetic-flux,6,13,,20,,0,13,1,367,,,,
603,happy-hour,6,1,,30,,0,4,1,368,,,,
604,electric-terrain,6,13,,10,,0,12,1,369,,,,
605,dazzling-gleam,6,18,80,10,100,0,11,3,1,,,,
606,celebrate,6,1,,40,,0,7,1,370,,,,
607,hold-hands,6,1,,40,,0,3,1,371,,,,
608,baby-doll-eyes,6,18,,30,100,1,10,1,365,,,,
609,nuzzle,6,13,20,20,100,0,10,2,372,100,,,
610,hold-back,6,1,40,40,100,0,10,2,102,,,,
611,infestation,6,7,20,20,100,0,10,3,43,100,,,
612,power-up-punch,6,2,40,20,100,0,10,2,375,100,,,
613,oblivion-wing,6,3,80,10,100,0,10,3,349,,,,
614,thousand-arrows,6,5,90,10,100,0,11,2,373,100,,,
615,thousand-waves,6,5,90,10,100,0,11,2,374,,,,
616,lands-wrath,6,5,90,10,100,0,11,2,1,,,,
617,light-of-ruin,6,18,140,5,90,0,10,3,270,,,,
618,origin-pulse,6,11,110,10,85,0,11,3,1,,,,
619,precipice-blades,6,5,120,10,85,0,11,2,1,,,,
620,dragon-ascent,6,3,120,5,100,0,10,2,230,100,,,
621,hyperspace-fury,6,17,100,5,,0,10,2,360,100,,,
622,breakneck-blitz--physical,7,1,,1,,0,10,2,1,,,,
623,breakneck-blitz--special,7,1,,1,,0,10,3,1,,,,
624,all-out-pummeling--physical,7,2,,1,,0,10,2,1,,,,
625,all-out-pummeling--special,7,2,,1,,0,10,3,1,,,,
626,supersonic-skystrike--physical,7,3,,1,,0,10,2,1,,,,
627,supersonic-skystrike--special,7,3,,1,,0,10,3,1,,,,
628,acid-downpour--physical,7,4,,1,,0,10,2,1,,,,
629,acid-downpour--special,7,4,,1,,0,10,3,1,,,,
630,tectonic-rage--physical,7,5,,1,,0,10,2,1,,,,
631,tectonic-rage--special,7,5,,1,,0,10,3,1,,,,
632,continental-crush--physical,7,6,,1,,0,10,2,1,,,,
633,continental-crush--special,7,6,,1,,0,10,3,1,,,,
634,savage-spin-out--physical,7,7,,1,,0,10,2,1,,,,
635,savage-spin-out--special,7,7,,1,,0,10,3,1,,,,
636,never-ending-nightmare--physical,7,8,,1,,0,10,2,1,,,,
637,never-ending-nightmare--special,7,8,,1,,0,10,3,1,,,,
638,corkscrew-crash--physical,7,9,,1,,0,10,2,1,,,,
639,corkscrew-crash--special,7,9,,1,,0,10,3,1,,,,
640,inferno-overdrive--physical,7,10,,1,,0,10,2,1,,,,
641,inferno-overdrive--special,7,10,,1,,0,10,3,1,,,,
642,hydro-vortex--physical,7,11,,1,,0,10,2,1,,,,
643,hydro-vortex--special,7,11,,1,,0,10,3,1,,,,
644,bloom-doom--physical,7,12,,1,,0,10,2,1,,,,
645,bloom-doom--special,7,12,,1,,0,10,3,1,,,,
646,gigavolt-havoc--physical,7,13,,1,,0,10,2,1,,,,
647,gigavolt-havoc--special,7,13,,1,,0,10,3,1,,,,
648,shattered-psyche--physical,7,14,,1,,0,10,2,1,,,,
649,shattered-psyche--special,7,14,,1,,0,10,3,1,,,,
650,subzero-slammer--physical,7,15,,1,,0,10,2,1,,,,
651,subzero-slammer--special,7,15,,1,,0,10,3,1,,,,
652,devastating-drake--physical,7,16,,1,,0,10,2,1,,,,
653,devastating-drake--special,7,16,,1,,0,10,3,1,,,,
654,black-hole-eclipse--physical,7,17,,1,,0,10,2,1,,,,
655,black-hole-eclipse--special,7,17,,1,,0,10,3,1,,,,
656,twinkle-tackle--physical,7,18,,1,,0,10,2,1,,,,
657,twinkle-tackle--special,7,18,,1,,0,10,3,1,,,,
658,catastropika,7,13,210,1,,0,10,2,1,,,,
659,shore-up,7,5,,10,,0,7,1,382,,,,
660,first-impression,7,7,90,10,100,2,10,2,383,,,,
661,baneful-bunker,7,4,,10,,4,7,1,384,,,,
662,spirit-shackle,7,8,80,10,100,0,10,2,385,,,,
663,darkest-lariat,7,17,85,10,100,0,10,2,304,,,,
664,sparkling-aria,7,11,90,10,100,0,9,3,386,,,,
665,ice-hammer,7,15,100,10,90,0,10,2,219,100,,,
666,floral-healing,7,18,,10,,0,10,1,387,,,,
667,high-horsepower,7,5,95,10,95,0,10,2,1,,,,
668,strength-sap,7,12,,10,100,0,10,1,388,100,,,
669,solar-blade,7,12,125,10,100,0,10,2,152,,,,
670,leafage,7,12,40,40,100,0,10,2,1,,,,
671,spotlight,7,1,,15,,3,10,1,389,,,,
672,toxic-thread,7,4,,20,100,0,10,1,390,100,,,
673,laser-focus,7,1,,30,,0,7,1,391,,,,
674,gear-up,7,9,,20,,0,13,1,392,,,,
675,throat-chop,7,17,80,15,100,0,10,2,393,100,,,
676,pollen-puff,7,7,90,15,100,0,10,3,394,,,,
677,anchor-shot,7,9,80,20,100,0,10,2,385,,,,
678,psychic-terrain,7,14,,10,,0,12,1,395,,,,
679,lunge,7,7,80,15,100,0,10,2,396,100,,,
680,fire-lash,7,10,80,15,100,0,10,2,397,100,,,
681,power-trip,7,17,20,10,100,0,10,2,306,,,,
682,burn-up,7,10,130,5,100,0,10,3,398,,,,
683,speed-swap,7,14,,10,,0,10,1,399,,,,
684,smart-strike,7,9,70,10,,0,10,2,18,,,,
685,purify,7,4,,20,,0,10,1,400,,,,
686,revelation-dance,7,1,90,15,100,0,10,3,401,,,,
687,core-enforcer,7,16,100,10,100,0,11,3,402,,,,
688,trop-kick,7,12,70,15,100,0,10,2,396,100,,,
689,instruct,7,14,,15,,0,10,1,403,,,,
690,beak-blast,7,3,100,15,100,-3,10,2,404,,,,
691,clanging-scales,7,16,110,5,100,0,11,3,405,100,,,
692,dragon-hammer,7,16,90,15,100,0,10,2,1,,,,
693,brutal-swing,7,17,60,20,100,0,9,2,406,,,,
694,aurora-veil,7,15,,20,,0,4,1,407,,,,
695,sinister-arrow-raid,7,8,180,1,,0,10,2,1,,,,
696,malicious-moonsault,7,17,180,1,,0,10,2,1,,,,
697,oceanic-operetta,7,11,195,1,,0,10,3,1,,,,
698,guardian-of-alola,7,18,,1,,0,10,3,413,,,,
699,soul-stealing-7-star-strike,7,8,195,1,,0,10,2,1,,,,
700,stoked-sparksurfer,7,13,175,1,,0,10,3,7,100,,,
701,pulverizing-pancake,7,1,210,1,,0,10,2,1,,,,
702,extreme-evoboost,7,1,,1,,0,7,1,414,100,,,
703,genesis-supernova,7,14,185,1,,0,10,3,415,,,,
704,shell-trap,7,10,150,5,100,-3,11,3,408,,,,
705,fleur-cannon,7,18,130,5,90,0,10,3,205,100,,,
706,psychic-fangs,7,14,85,10,100,0,10,2,187,,,,
707,stomping-tantrum,7,5,75,10,100,0,10,2,409,,,,
708,shadow-bone,7,8,85,10,100,0,10,2,70,20,,,
709,accelerock,7,6,40,20,100,1,10,2,104,,,,
710,liquidation,7,11,85,10,100,0,10,2,70,20,,,
711,prismatic-laser,7,14,160,10,100,0,10,3,81,,,,
712,spectral-thief,7,8,90,10,100,0,10,2,410,,,,
713,sunsteel-strike,7,9,100,5,100,0,10,2,411,,,,
714,moongeist-beam,7,8,100,5,100,0,10,3,411,,,,
715,tearful-look,7,1,,20,,0,10,1,412,100,,,
716,zing-zap,7,13,80,10,100,0,10,2,32,30,,,
717,natures-madness,7,18,,10,90,0,10,3,41,,,,
718,multi-attack,7,1,90,10,100,0,10,2,269,,,,
719,10-000-000-volt-thunderbolt,7,13,195,1,,0,10,3,1,,,,
720,mind-blown,7,10,150,5,100,0,9,3,420,,,,
721,plasma-fists,7,13,100,15,100,0,10,2,1,,,,
722,photon-geyser,7,14,100,5,100,0,10,3,1,,,,
723,light-that-burns-the-sky,7,14,200,1,,0,10,3,416,,,,
724,searing-sunraze-smash,7,9,200,1,,0,10,2,411,,,,
725,menacing-moonraze-maelstrom,7,8,200,1,,0,10,3,411,,,,
726,lets-snuggle-forever,7,18,190,1,,0,10,2,417,,,,
727,splintered-stormshards,7,6,190,1,,0,10,2,418,,,,
728,clangorous-soulblaze,7,16,185,1,,0,11,3,419,100,,,
10001,shadow-rush,3,10002,55,,100,0,10,2,10001,,,,
10002,shadow-blast,3,10002,80,,100,0,10,2,44,,,,
10003,shadow-blitz,3,10002,40,,100,0,10,2,1,,,,
10004,shadow-bolt,3,10002,75,,100,0,10,3,7,10,,,
10005,shadow-break,3,10002,75,,100,0,10,2,1,,,,
10006,shadow-chill,3,10002,75,,100,0,10,3,6,10,,,
10007,shadow-end,3,10002,120,,60,0,10,2,10002,,,,
10008,shadow-fire,3,10002,75,,100,0,10,3,5,10,,,
10009,shadow-rave,3,10002,70,,100,0,6,3,1,,,,
10010,shadow-storm,3,10002,95,,100,0,6,3,1,,,,
10011,shadow-wave,3,10002,50,,100,0,6,3,1,,,,
10012,shadow-down,3,10002,,,100,0,6,1,60,,,,
10013,shadow-half,3,10002,,,100,0,12,3,10003,,,,
10014,shadow-hold,3,10002,,,,0,6,1,107,,,,
10015,shadow-mist,3,10002,,,100,0,6,1,10004,,,,
10016,shadow-panic,3,10002,,,90,0,6,1,50,,,,
10017,shadow-shed,3,10002,,,,0,12,1,10005,,,,
10018,shadow-sky,3,10002,,,,0,12,1,10006,,,,`