import React from "react";
// import Table from "react-table"
import IconButton from "@material-ui/core/IconButton";
import { navigate } from "@reach/router";
import Add from "@material-ui/icons/Add";
import styled from "styled-components";
import "react-table/react-table.css";
import { pokemondata as pokemonstring } from "../data/PokemonData";
import Papa from "papaparse";
import { FilterRow } from "./filterRow.js";
import { Search } from "../components/search";
import { Table } from "../components/table";

const PokemonInventory = props => {
  const pokemon = Papa.parse(pokemonstring, {
    header: true,
    dynamicTyping: true
  }).data;
  const [filters, setFilters] = React.useState([]);
  const [filteredPokemons, setFilteredPokemons] = React.useState(pokemon);

  React.useEffect(() => {
    var before = pokemon;
    setFilteredPokemons(before);
    filters.forEach(filter => {
      before = before.filter(pokemon =>
        filter.comparison == "="
          ? pokemon[filter.stat] == filter.value
          : filter.comparison == ">"
          ? pokemon[filter.stat] > filter.value
          : pokemon[filter.stat] < filter.value
      );
      setFilteredPokemons(before);
    });
  }, [filters]);

  function filter() {
    var before = pokemon;
    setFilteredPokemons(before);
    filters.forEach(filter => {
      before = before.filter(pokemon =>
        filter.comparison == "="
          ? pokemon[filter.stat] == filter.value
          : filter.comparison == ">"
          ? pokemon[filter.stat] > filter.value
          : pokemon[filter.stat] < filter.value
      );
      setFilteredPokemons(before);
    });
  }

  return (
    <div style={{ height: "100%", maxHeight: "100%", width: "100%" }}>
      <Search
        onSearch={searchee => {
          setFilters([
            ...filters,
            {
              stat: "identifier",
              comparison: "=",
              value: searchee
            }
          ]);
          filter();
        }}
      ></Search>
      <SortBox filters={filters} setFilters={setFilters} />
      <button onClick={() => filter()}>Go</button>
      <div className="Table">
        <Table
          data={filteredPokemons}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onDoubleClick: (e, handleOriginal) => {
                navigate(`/pokemon/${rowInfo.original.identifier}`);
              },
              onClick: (e, handleOriginal) => {
                console.log("A pokemon was clicked!:", rowInfo.original);
                if (handleOriginal) {
                  handleOriginal();
                }
              }
            };
          }}
          columns={pokemonColumns}
        ></Table>
      </div>
      <button
        onClick={() => {
          console.log(`navigate("/pokemon/")`);
        }}
      >
        Pokemon
      </button>
    </div>
  );
};

const pokemonColumns = [
  {
    columns: [
      { Header: "Nat Dex Numb", accessor: "species_id" },
      { Header: "Name", accessor: "identifier" },
      { Header: "HP", accessor: "hp" },
      { Header: "Attack", accessor: "attack" },
      { Header: "Defense", accessor: "defense" },
      { Header: "Special Attack", accessor: "specialattack" },
      { Header: "Special Defense", accessor: "specialdefense" },
      { Header: "Speed", accessor: "speed" },
      { Header: "Type One", accessor: "typeOne" },
      { Header: "Type Two", accessor: "typeTwo" }
    ]
  }
];

function SortBox({ filters, setFilters }) {
  return (
    <Sorteroonie>
      <div style={{ flex: 1 }}>
        <p style={{ flex: 1 }}>There are {filters.length} filters</p>
        <IconButton
          style={{ color: "green", width: 40, height: 40 }}
          onClick={() =>
            setFilters([
              ...filters,
              {
                stat: filterOptions[0].value,
                comparison: comparisonOptions[0].value,
                value: 40
              }
            ])
          }
        >
          <Add style={{ height: 20, width: 20 }} />
        </IconButton>

        <div
          style={{
            flexDirection: "column"
          }}
        >
          {filters.map((f, i) => (
            <FilterRow
              comparisonOptions={comparisonOptions}
              filters={filters}
              setFilters={setFilters}
              filterOptions={filterOptions}
              i={i}
              f={f}
            />
          ))}
        </div>
      </div>
    </Sorteroonie>
  );
}

const Sorteroonie = styled.div`
  display: flex;
  flex-direction: row;
  font-size: calc(5px + 1vw);
`;
const filterOptions = [
  { value: "hp", label: "HP" },
  { value: "attack", label: "Attack" },
  { value: "defense", label: "Defense" },
  { value: "specialdefense", label: "Special Attack" },
  { value: "specialattack", label: "Special Defense" },
  { value: "speed", label: "Speed" },
  { value: "identifier", label: "Name" }
];
const comparisonOptions = [
  { value: "=", label: "=" },
  { label: ">", value: ">" },
  { label: "<", value: "<" }
];
export { PokemonInventory };
