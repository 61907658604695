import React from "react"
import styled from "styled-components"
import IconButton from "@material-ui/core/IconButton"
import Close from "@material-ui/icons/Close"

export const FilterRow = ({
  i,
  f,
  filters,
  filterOptions,
  comparisonOptions,
  setFilters
}) => {
  console.log({ i, f, filters, filterOptions, comparisonOptions, setFilters })
  return (
    <div
      key={i}
      style={{
        display: "flex",
        borderWidth: 1,
        padding: 0,
        margin: 0,
        borderColor: "#eee",
        borderStyle: "solid",
        flex: 1,
      }}
    >
      <div
        style={{
          borderColor: "#dddddd",
          flex: 1,
          borderBottomWidth: 1,
          flexDirection: "row",
          alignItems:"flex-start"
        }}
      >
        Filter:
        <Select
          style={{ marginLeft: 5 }}
          value={f.stat}
          onChange={(e) => {
            setFilters(
              filters.map((filter, index) => {
                if (index === i) {
                  return { ...filter, stat: e.target.value }
                } else {
                  return filter
                }
              })
            )
          }}
        >
          {filterOptions.map((option, oIndex) => (
            <option key={"f" + oIndex} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
        <Select
          value={f.comparison}
          onChange={(e) => {
            setFilters(
              filters.map((filter, index) => {
                if (index === i) {
                  return { ...filter, comparison: e.target.value }
                } else {
                  return filter
                }
              })
            )
          }}
        >
          {comparisonOptions.map((option, oIndex) => (
            <option key={"c" + oIndex} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
        <FilterInput
          value={f.value}
          onChange={(e) => {
            setFilters(
              filters.map((filter, index) => {
                if (index === i) {
                  return { ...filter, value: e.target.value }
                } else {
                  return filter
                }
              })
            )
          }}
        />
      </div>
      <IconButton
        style={{ color: "red", width: 35, height: 35 }}
        onClick={(e) =>
          setFilters([...filters.slice(0, i), ...filters.slice(i + 1)])
        }
        aria-label="Delete"
      >
        <Close style={{ height: 15, width: 15 }} />
      </IconButton>
    </div>
  )
}

const Select = styled.select`
  margin: 3px;
`

const FilterInput = styled.input`
  width: 25vw;
  height: 13px;
  margin: 3px;
`