import React, { useEffect } from "react"
import { navigate } from "@reach/router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled, { keyframes } from "styled-components"
import { useCompare, useWindowSize } from "../hooks" 
import { NavigationRoutes } from "./NavigationRoutes"

export const Sidebar = ({ options, onClick }) => {
  const [active, setActive] = React.useState(-1)
  const [lastCat, setLastCat] = React.useState({
    label: "",
    routes: [{}],
    index: -1
  })
  const [parentPos, setParentPos] = React.useState({})
  const someActive = () => active !== -1
  const someActiveChanged = useCompare(someActive())
  const categories = Object.keys(options)
  const measuredRef = React.useCallback(
    (node) => {
      node !== null && setParentPos(node.getBoundingClientRect())
      setLastCat({ label: "", routes: lastCat.routes, index: -1 })
    },
    [useWindowSize()]
  )
  const fullWidth = useWindowSize()[0] > 768
    console.log(fullWidth)
  const size = (parentPos.bottom - parentPos.top) / categories.length
  return (
    <div style={{ zIndex: 1,    fontSize: "15px",
    height:"90vh", 
    position: "relative",
    overflow: "visible",
    borderRightColor: "gray",
    borderRightStyle: "solid"}}>
      <div ref={measuredRef} style={styles.containerStyle(fullWidth)}>
        {categories.map((category, index) => (
          <Row
            fullWidth={fullWidth}
            setActive={(active) => {
              setActive(active)
              setLastCat({ ...options[category], index: index })
            }}
            top={size * index}
            size={size}
            index={index}
            icon={options[category].icon}
            active={active}
          ></Row>
        ))}
        {categories.map(
          (category, index) =>
            (fullWidth || active === index || lastCat.index === index) && (
              <DynamicRowLabel
                active={active}
                someActive={someActive()}
                someActiveChanged={someActiveChanged}
                lastCat={lastCat}
                fullWidth={fullWidth}
                index={index}
                topStart={size * index + size / 2 - 15}
              >
                {category}
              </DynamicRowLabel>
            )
        )}
        <div style={{ ...styles.movingContainerStyle(active, fullWidth) }}>
          {lastCat.routes.map((route, index) => (
            <RLabel
              someActive={someActive()}
              index={index}
              onClick={() => {
                onClick(route.label, route.route)
              }}
            >
              {route.label}
            </RLabel>
          ))}
        </div>
      </div>
    </div>
  )
}

const Row = ({
  icon = "question-circle",
  top,
  setActive,
  active,
  index,
  fullWidth,
  size
}) => {
  return (
    <NavRow
      style={{
        ...styles.navRowStyle(fullWidth),
        height: `${size}px`,
        background: active === index ? "#b39380" : "#b3ac80",
        top: top ? top : 0
      }}
      onClick={() => (active === index ? setActive(-1) : setActive(index))}
    >
      <FontAwesomeIcon
        icon={icon}
        onClick={() => {}}
        style={{
          fontSize: "2em",
          margin: ".2em",
          width: "1em",
          color: "black",
          background: "transparent"
        }}
      />
    </NavRow>
  )
}

const RLabel = styled.p`
${({ someActive }) => `
  overflow: hidden;
  border-bottom: 1px solid #fff;
  cursor: pointer;
  user-select: none;
  width:9.5em;
  margin-left: 100%;`}
  animation: ${({ someActive }) => {
    return someActive ? anims.SlideH(100, 5) : anims.SlideH(5, 100)
  }}
    ${({ someActive, index }) => {
      return someActive
        ? `0.5s ease ${(index - 1) / 50}s forwards`
        : `0.5s ease ${(index - 1) / 50}s backwards`
    }};
`

const DynamicRowLabel = styled.h2`
${({ active, index, topStart, lastCat, fullWidth, someActiveChanged }) => `
  ${!fullWidth && index !== active && "display:none"}
  cursor: default;
  pointer-events:none;
  user-select: none;
  font-size: 1.5em;
  whitespace: nowrap;
  overflow: hidden;
  white-space:nowrap;
  color: black; 
  padding:0;
  z-index: ${index === active || lastCat.index === index ? 5 : 1};
  position: absolute;
  left: 2em;
  top: ${
    (lastCat.label !== "" && !someActiveChanged && lastCat.index === index) ||
    (lastCat.label === "" && active === index)
      ? 0
      : topStart
  }px; 
  `}
  animation: ${({
    topStart,
    active,
    someActive,
    index,
    someActiveChanged,
    lastCat
  }) =>
    active === index && someActiveChanged
      ? anims.SlideV(topStart, 0)
      : someActive === false && lastCat.index === index
      ? anims.SlideV(0, topStart)
      : ""} 
    .25s ease-in .25s ${({ active, index }) =>
      active === index ? "forwards" : "backwards"}
      ;
`

const styles = {
  navRowStyle: (fullWidth) => {
    return fullWidth
      ? {
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          borderColor: "gray",
          justifyContent: "center",
          borderWidth: 1,
          borderBottomStyle: "solid",
          whitespace: "nowrap",
          transition: "background-color 0.5s ease"
        }
      : {}
  },
  buttonContainerStyle: {
    height: "100%",
    display: "inline",
    flexDirection: "column"
  },
  containerStyle: (fullWidth) => {
    return fullWidth
      ? {
          height: "100%",
          width: "13em",
          position: "relative",
          overflow: "hidden",
          borderRightColor: "gray",
          borderRightStyle: "solid"
        }
      : {
          height: "100%",
          width: "3em",
          position: "relative"
        }
  },
  movingContainerStyle: (active, fullWidth) => {
    return fullWidth
      ? {
          display: "inline-block",
          paddingTop: "3em",
          position: "absolute",
          height: "100%",
          top: 0,
          left: active === -1 ? "100%" : "2.5em",
          overflow: "hidden",
          transition:
            active !== -1 ? "all .25s ease-out" : "all .25s ease-out .5s",
          width: active === -1 ? "0%" : "100%",
          zIndex: 2,
          background: active === -1 ?  "#b3ac80" :  "#b39380" ,
        }
      : {
          paddingTop: "3em",
          position: "absolute",
          overflow: "hidden",
          height: "100%",
          left: "2.5em",
          top: 0,
          transition:
            active !== -1 ? "all .25s ease-out" : "all .25s ease-out .5s",
          width: active === -1 ? 0 : "11.5em",
          display: "inline-block",
          background: active === -1 ?"#b3ac80"  :  "#b39380",
        }
  }
}

const NavRow = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-color: gray;
  justify-content: center;
  border-width: 1;
  border-bottom-style: solid;
  whitespace: nowrap;
  transition: background-color 0.5s ease;
`

const anims = {
  SlideH: (leftStart, leftEnd) => {
    return keyframes`                  
    from {
      margin-left: ${leftStart}%;
    }
    to {
      margin-left: ${leftEnd}%;
    }
    `
  },
  SlideV: (topStart, topEnd) => {
    return keyframes`
    from {
      top: ${topStart}px; 
    }
    to {
      top: ${topEnd}px; 
    }
    `
  }
}