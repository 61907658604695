export const NavigationRoutes = {
  Search: {
    label: "Search",
    icon: "question-circle",
    routes: [
      { label: "Pokemon", route: "/pokemon" },
      { label: "Type Counters", route: "/counters" },
      { label: "Items", route: "/items" },
      { label: "Moves", route: "/moves" },
      { label: "Types", route: "/types" }
    ]
  },
  Formats: {
    label: "Tiers",
    icon: "tags",
    routes: [
      { label: "Ubers", route: "/u" },
      { label: "OverUsed", route: "/ou" },
      { label: "UnderUsed", route: "/uu" },
      { label: "Rarely Used", route: "/ru" }
    ]
  },
  Types: {
    label: "Types",
    icon: "user-friends",
    routes: [
      { label: "Fire", route: "/u" },
      { label: "Water", route: "/ou" },
      { label: "Ice", route: "/uu" },
      { label: "Dragon", route: "/ru" },
      { label: "Normal", route: "/u" },
      { label: "Bug", route: "/ou" },
      { label: "Fairy", route: "/uu" },
      { label: "Grass", route: "/ru" },
      { label: "Steel", route: "/u" },
      { label: "Poison", route: "/ou" },
      { label: "Flying", route: "/uu" },
      { label: "Ground", route: "/ru" },
      { label: "Rock", route: "/u" }
    ]
  },
  Moves: {
    label: "Moves",
    icon: "university",
    routes: [
      { label: "Fire", route: "/u" },
      { label: "Water", route: "/ou" },
      { label: "Ice", route: "/uu" },
      { label: "Dragon", route: "/ru" },
      { label: "Normal", route: "/u" },
      { label: "Bug", route: "/ou" },
      { label: "Fairy", route: "/uu" },
      { label: "Grass", route: "/ru" },
      { label: "Steel", route: "/u" }
    ]
  },
  Abilities: {
    label: "Abilities",
    icon: "chart-bar",
    routes: [
      { label: "Fire", route: "/u" },
      { label: "Water", route: "/ou" },
      { label: "Ice", route: "/uu" },
      { label: "Dragon", route: "/ru" },
      { label: "Normal", route: "/u" },
      { label: "Bug", route: "/ou" },
      { label: "Fairy", route: "/uu" },
      { label: "Grass", route: "/ru" },
      { label: "Steel", route: "/u" }
    ]
  },
  Items: {
    label: "Items",
    icon: "cog",
    routes: [
      { label: "Medicine", route: "/u" },
      { label: "TMs", route: "/ou" },
      { label: "Berries", route: "/uu" },
      { label: "Key Items", route: "/ru" },
      { label: "Mega Stones", route: "/ru" },
      { label: "Rotom Powers", route: "/ru" },
      { label: "Z-Crystals", route: "/ru" }
    ]
  }
};
