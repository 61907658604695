import React from "react"
import ReactTable from "react-table"
import "react-table/react-table.css" 

export const Table = ({data, columns, getTdProps} ) => {
    return <ReactTable
    className="-striped -highlight"
    defaultPageSize={10}
    data={data}
    columns={columns}
    style={{fontSize:30}}
    getTdProps={getTdProps}
  ></ReactTable>
}