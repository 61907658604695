import React from "react" 
import {Router, navigate} from "@reach/router"
import {pokemondata as pokemonstring} from "../data/PokemonData"
import {moveDatabase} from "../data/moveDatabase"
import {pokemonLearnset} from "../data/pokemonLearnset"
import Papa from "papaparse"  
import {Table} from "../components/table" 



export const Pokeman = ({identifier})=>{
    const [loadingMoves, setLoadingMoves]= React.useState(true)
    const pokemon = Papa.parse(pokemonstring,{header:true, dynamicTyping:true}).data.find((pokeman)=>pokeman.identifier=identifier)    
    const [moveset, setMoveset] = React.useState([])

    React.useEffect(()=>{
            loadMoves(pokemon.id).then((moves) => {
                setMoveset(moves)
                setLoadingMoves(false)
            }).catch(error=>console.log(error))
    },[pokemon.id])

    return (
        <div style={{width:"100%", fontSize:15, alignItems:"flex-start"}}>
            <button onClick={()=>navigate("/pokemon")}>Search Again</button>
            <div style={{height:"20vh"}}>
            Data:
            {Object.keys(pokemon).map((key, index)=><div key={index}>{key}:{pokemon[key]}</div>)}
            </div>     
            <div style={{overflow:"hidden", height:"67vh", borderStyle:"solid",borderWidth:2}}>
            <div style={{overflowY:"scroll", height:"67vh"}}>
            {loadingMoves ? 
            <div>Loading</div> : 
            <Table
            data={moveset}
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                onDoubleClick: (e, handleOriginal) => {
                  console.log(`clicked ${rowInfo.original.identifier}`)
  
                },
                onClick: (e, handleOriginal) => {
                  console.log(`clicked ${rowInfo.original.identifier}`)
                }
              }
            }}
            columns={pokemonColumns}
          ></Table>
}
            </div>
            </div>
        </div>)
    
}

const pokemonColumns = [
    {
      columns: [
        { Header: "Move", accessor: "identifier" },
        { Header: "Type", accessor: "type_id" },
        { Header: "Power", accessor: "power" },
        { Header: "PP", accessor: "pp" },
        { Header: "Accuracy", accessor: "accuracy" },
        { Header: "Priority", accessor: "priority" },
        { Header: "Damage Type", accessor: "damage_class_id" },
        { Header: "Effect", accessor: "effect_id" },
        { Header: "Chance", accessor: "effect_chance" }, 
      ]
    }
  ]

const loadMoves=(pokemonid)=>
    new Promise((resolve)=>{
     const learnset = Papa.parse(pokemonLearnset,{header:true, dynamicTyping:true}).data.filter((move)=>move.pokemon_id===pokemonid)
     const moves = Papa.parse(moveDatabase,{header:true, dynamicTyping:true}).data.filter((move)=>learnset.find((lerned)=>lerned.move_id===move.id))    
     resolve(moves)
    })
