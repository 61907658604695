import React from "react"
 

export const Search = ({onSearch}) => {
    const [search, setSearch] = React.useState()
    return <div>
        <input placeholder={"search for a pokemon"} onChange={(e)=>{setSearch(e.target.value)}}>
        </input>
        <button onClick={()=>{onSearch(search)}}>Go</button>
        </div>
}