import { useRef, useEffect } from "react";

const useCompare = val => {
  const prevVal = usePrevious(val);
  return prevVal !== val;
};

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export { useCompare, usePrevious };
